<div class="time-component">
  <div class="time-button"
    [slxTooltip]="iconTitle"
    tipPosition="bottom"
    (keyup)="onKeyup($event)"
    [popper]="popperContent"
    [popperDisabled]="loadError"
    [popperTrigger]="'click'"
    [popperPlacement]="popperPosition"
    [popperDisableStyle]="'true'"
    [popperModifiers]="popperModifiers"
    (popperOnShown)="onShown($event)"
    (popperOnHidden)="onHidden($event)"
  >
    <div class="time-desktop">
      <i class="icon" [ngClass]="iconClass" aria-hidden="true"></i>
      <span>{{ currentTime | amDateFormat: appConfig.dateMonthTextDayYearHourFormat2 }}</span>
      <i *ngIf="showExclamation" aria-hidden="true" [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'timeclock-error': true, 'error-exclamation': loadError}"></i>
    </div>
    <div class="time-mobile">
      <div class="only-time">
        <i class="icon" [ngClass]="iconClass" aria-hidden="true"></i>
        <span>{{ currentTime | amDateFormat: appConfig.timeFormat }}</span>
      </div>
      <div class="only-date">
        {{ currentTime | amDateFormat: appConfig.dateMonthTextDayYearFormat }}
      </div>
      <i *ngIf="showExclamation" aria-hidden="true" [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'timeclock-error': true, 'error-exclamation': loadError}"></i>
    </div>
  </div>

  <popper-content #popperContent>
    <div class="notification-popup-body">
      <slx-header-timeclocks-list [totalRecordsCount]="totalRecordsCount" [displayedRecords]="displayedRecords" (close)="onCloseFromList()"></slx-header-timeclocks-list>
    </div>
  </popper-content>
</div>
