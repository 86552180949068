import * as tslib_1 from "tslib";
import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import { OnInit, OnDestroy, Provider } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ModalService } from '../../../../common/services/index';
import { DialogOptions } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListRequest } from '../../../../organization/models/index';
import { IApplicationConfig } from '../../../../app.config';
import { GenericGridComponent } from '../../../../organization/components/index';
import { Subscription } from 'rxjs/Subscription';
import { OperationalConsoleDialogParameters } from '../../models/index';
import { genericGridConfig } from '../../../../organization/components/generic-list/generic-grid/generic-grid.config';
var OperationalConsoleDialogComponent = /** @class */ (function () {
    function OperationalConsoleDialogComponent(parameters, genericListManagementService, options, modalService) {
        this.parameters = parameters;
        this.genericListManagementService = genericListManagementService;
        this.options = options;
        this.modalService = modalService;
        this.isLoading = true;
        this.gridConfig = _.assign({}, genericGridConfig);
        this.gridConfig.excelExport.fileName = parameters.field.colName + ".xlsx";
        this.gridConfig.pdfExport.fileName = parameters.field.colName + ".pdf";
    }
    OperationalConsoleDialogComponent.openDialog = function (params, modalService) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: OperationalConsoleDialogParameters,
                useValue: params
            },
        ];
        var dialog = modalService.globalAnchor.openDialog2(OperationalConsoleDialogComponent, params.field.title, dialogOptions, resolvedProviders);
        return dialog;
    };
    OperationalConsoleDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$
            .subscribe(function (defs) { return _this.loadValues(); });
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$
            .subscribe(function (list) { return (_this.isLoading = false); });
        this.loadDefinitions();
    };
    OperationalConsoleDialogComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(OperationalConsoleDialogComponent.prototype, "isCustomMode", {
        get: function () {
            return !_.isNil(this.parameters.employeeLinkClick);
        },
        enumerable: true,
        configurable: true
    });
    OperationalConsoleDialogComponent.prototype.onExportToExcel = function () {
        this.grid.exportToExcel();
    };
    OperationalConsoleDialogComponent.prototype.onExportToPdf = function () {
        this.grid.exportToPdf();
    };
    OperationalConsoleDialogComponent.prototype.onEmployeeLinkClick = function (empId) {
        this.closeDialog();
        this.parameters.employeeLinkClick.emit(empId);
    };
    OperationalConsoleDialogComponent.prototype.closeDialog = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    OperationalConsoleDialogComponent.prototype.loadDefinitions = function () {
        this.isLoading = true;
        this.genericListManagementService.loadListDefinition(this.parameters.application.id, this.parameters.orgLevel.id, this.parameters.field.colName);
    };
    OperationalConsoleDialogComponent.prototype.loadValues = function () {
        this.isLoading = true;
        var req = new GenericListRequest();
        var startOfDay = moment(this.parameters.date).startOf('day');
        var posId = _.get(this.parameters, 'position.id');
        req.startDate = startOfDay.toDate();
        req.endDate = startOfDay.endOf('day').toDate();
        if (_.isNumber(posId)) {
            req.positionId = posId;
        }
        this.genericListManagementService.loadListValues(this.parameters.orgLevel.id, req);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleDialogComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleDialogComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleDialogComponent.prototype, "loadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleDialogComponent.prototype, "valuesLoadedSubscription", void 0);
    return OperationalConsoleDialogComponent;
}());
export { OperationalConsoleDialogComponent };
