<div>
  <div *ngIf="dataTypeName === 'int'">
    <slx-integer-report-parameter [parameter]="parameter"></slx-integer-report-parameter>
  </div>
  <div *ngIf="dataTypeName === 'date'">
    <slx-date-report-parameter [index]="index" [parameter]="parameter" [parameters]="parameters" ></slx-date-report-parameter>
  </div>
  <div *ngIf="dataTypeName === 'bit'">
    <slx-boolean-report-parameter [parameter]="parameter"></slx-boolean-report-parameter>
  </div>
  <div *ngIf="dataTypeName === 'decimal' && parameter.name !== 'consecutive_hrs'">
    <slx-decimal-report-parameter [parameter]="parameter" ></slx-decimal-report-parameter>
  </div>
  <div *ngIf="dataTypeName === 'decimal' && parameter.name === 'consecutive_hrs'">
    <slx-decimal-report-parameter *ngIf="IsPBJConsecutiveHrsEnabled" [parameter]="parameter" ></slx-decimal-report-parameter>
  </div>
<div *ngIf="dataTypeName === 'lookup' && !parameter.isMultiselect && !isPayCycle">
    <ng-container [ngSwitch]="parameter.dataType.lookupName">
        <slx-employee-list-report-parameter *ngSwitchCase="'employeeList'" [orgLevel]="orgLevel" [parameter]="parameter"></slx-employee-list-report-parameter>
        <slx-radio-parameter *ngSwitchCase="'retroReportOptions'"  [parameter]="parameter"></slx-radio-parameter>
        <slx-lookup-report-parameter *ngSwitchDefault [orgLevel]="orgLevel" [parameter]="parameter"></slx-lookup-report-parameter>
    </ng-container>
  </div>
  <div *ngIf="dataTypeName === 'lookup' && !parameter.isMultiselect && isPayCycle">
    <slx-pay-cycle-report-parameter [orgLevel]="orgLevel" [parameter]="parameter"></slx-pay-cycle-report-parameter>
  </div>
  <div *ngIf="dataTypeName === 'lookup' && parameter.isMultiselect">
    <slx-lookup-multiselect-parameter [orgLevel]="orgLevel" [parameter]="parameter"></slx-lookup-multiselect-parameter>
  </div>
</div>
