import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsReviews = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsReviews, _super);
    function EmployeeSectionsReviews() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsReviews;
}(EmployeeSectionsBase));
export { EmployeeSectionsReviews };
