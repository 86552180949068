import * as moment from 'moment';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../app.config';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';

import { DailyTimecardApiService } from './daily-timecard-api.service';
import { TimecardsApiService } from '../timecards/timecards-api.service';

import { DailyTimecardMapService } from './daily-timecard-map.service';
import { EmployeeDefinitionsApiService, LookupApiService } from '../../../organization/services/index';
import { EmployeeShortInfo, TaAbsenceCode, Shift } from '../../../organization/models/index';
import { ManagementBaseService } from '../../../core/services/index';
import { TimeCardModel, TimeCardDisplayOptions, DailyAbsence, EarningStatistic, DailyTimecardChangeStatus, DailyPayRule, IDailyAllAbsence } from '../../models/index';
import { StateManagementService, ComponentStateStorageService } from '../../../common/services/index';
 import { AppSettingsManageService } from '../../../app-settings/services/index';
import { IControlState, StateResetTypes } from '../../../core/models/index';
import {
  LinePunch, PunchStatus, PunchLogRequest, PunchDisplay, PunchSource, punchWrapper, DailyLinePunchActions,
  TimecardAddCommentsReq, } from '../../models/index';
//import { KendoGridStateHelper } from 'src/app/common';
import { KendoGridStateHelper, saveEvent, removeEvent, cancelEvent, DialogOptions } from '../../../common/models/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../common/index';
import { ColumnManagementService, ColumnsChangedEvent, ModalService } from '../../../common/services/index';

export type editAction = { source: 'punches' | 'absences' | 'paycodes' | 'schedule', lockOthers: boolean };
export type resetAction = { target: 'punches' | 'absences' | 'paycodes' | 'schedule', model: TimeCardModel };

@Injectable()
export class DailyTimecardManagementService extends ManagementBaseService<TimeCardModel, any> {
  public changedDate$: ReplaySubject<Date>;
  public canEditChanged$: ReplaySubject<boolean>;
  public hasOverlap$: ReplaySubject<boolean>;
  public setHasOverlapToTC$: ReplaySubject<boolean>;
  public overlapingPunchesAction$: ReplaySubject<boolean>;
  public overlapPunchesDesc$: ReplaySubject<boolean>;
  public sectionEditAction$: ReplaySubject<editAction>;
  public exportedTimecard$: ReplaySubject<any>;
  public resetSection$: ReplaySubject<resetAction>;
  public displayOptionSelected$: ReplaySubject<TimeCardDisplayOptions>;
  public navigateToUnApprovedPC$: Subject<any>;
  public navigateToUnApprovedOT$: Subject<number>;
  public abscenceCount$: Subject<number>;
  public isPaidRestBreakEnabled$:ReplaySubject<boolean>;

  public dateOn: Date;
  public employeeId: number;
  public cleanDatamodel: TimeCardModel;
  public dataModel: TimeCardModel;
  public dataModelBeforeRecalculation: TimeCardModel;
  public employeeShortInfo: EmployeeShortInfo;
  public displayOptionsControlKey: string = 'timecardDisplayOptions';
  public updateScheduleOptionControlKey: string = 'updateScheduleOption';
  public lastUpdateScheduleSelection: boolean;
  public absenceCodesLookup: TaAbsenceCode[];
  public shiftsLookup: Shift[];
  public canLock: boolean;
  public canUnlock: boolean;
  public preventOverlappingPunches: boolean;
  public currentOverlapDate: any;
  public prevFirstPunch: any = null;
  public prevLastPunch: any = null;
  public currentFirstPunch: any;
  public currentLastPunch: any;
  public nextFirstPunch: any = null;
  public nextLastPunch: any = null;
  public prevFirstPunchTime: string;
  public prevLastPunchTime: string;
  public currentFirstPunchTime: string;
  public currentLastPunchTime: string;
  public nextFirstPunchTime: string;
  public nextLastPunchTime: string;
  public editedPunchTime: any;
  public actualOverlapIndex: number;

  constructor(
    private api: DailyTimecardApiService, private timecardsApi: TimecardsApiService,
    private mapService: DailyTimecardMapService,
    private empDefApi: EmployeeDefinitionsApiService,
    private lookupService: LookupApiService,
    private stateManagement: StateManagementService, private storageService: ComponentStateStorageService,
    private modalService: ModalService
  ) {
    super();
    this.sectionEditAction$ = new ReplaySubject(1);
    this.exportedTimecard$ = new ReplaySubject(1);
    this.canEditChanged$ = new ReplaySubject(1);
    this.hasOverlap$ = new ReplaySubject(1);
    this.setHasOverlapToTC$ = new ReplaySubject(1);
    this.overlapingPunchesAction$ = new ReplaySubject(1);
    this.overlapPunchesDesc$ = new ReplaySubject(1);
    this.changedDate$ = new ReplaySubject(1);
    this.resetSection$ = new ReplaySubject(1);
    this.displayOptionSelected$ = new ReplaySubject(1);
    this.navigateToUnApprovedPC$ = new Subject();
    this.navigateToUnApprovedOT$ = new Subject();
    this.abscenceCount$ = new Subject();
    this.isPaidRestBreakEnabled$ = new ReplaySubject(1);
    this.stateManagement.init('DailyTimeCardComponent', true);
    this.restoreLastUpdateScheduleOption();
  }


  public setRouteData(empId: number, dateOn: Date): void {
    this.employeeId = empId;
    this.dateOn = dateOn;
    this.getTimecard(false);
  }

  public changeDate(date: Date): void {
    this.changedDate$.next(date);
  }

  public setHasOverlapToTC(hasOverlap: boolean): void {
    this.setHasOverlapToTC$.next(hasOverlap);
  }

  public onSaveTimecard(): void {
    this.saveTimecard();
  }
  public onExportTimecard(): void {
    this.exportTimecard();
  }
  public canEditChanged(canEdit: boolean): void {
    this.canEditChanged$.next(canEdit && this.dataModel.canEditOwnTimecard);
  }

  public setHasOverlap(hasOverlap: boolean): void {
    this.hasOverlap$.next(hasOverlap);
  }
  public overlapingPunchesAction(event: boolean): void {
    this.overlapingPunchesAction$.next(event);
  }
  public overlapPunchesDesc(event: boolean): void {
    this.overlapPunchesDesc$.next(event);
  }

  public onPunchesDiscardAction(): void {
    this.dataModel.dailyPunches = _.cloneDeep(this.cleanDatamodel.dailyPunches);
    this.resetSection$.next({ target: 'punches', model: this.dataModel });
    this.onPunchesEditAction(false);
  }

  public onAbsenceDiscardAction(): void {
    this.dataModel.absences = _.cloneDeep(this.cleanDatamodel.absences);
    this.resetSection$.next({ target: 'absences', model: this.dataModel });
    this.onAbsenceEditAction(false);
  }

  public onPayCodesDiscardAction(): void {
    this.dataModel.payRules = _.cloneDeep(this.cleanDatamodel.payRules);
    this.resetSection$.next({ target: 'paycodes', model: this.dataModel });
    this.onPayCodesEditAction(false);
  }

  public onPunchesEditAction(lockOthers: boolean): void {
    this.sectionEditAction$.next({ source: 'punches', lockOthers: lockOthers });
  }

  public onAbsenceEditAction(lockOthers: boolean): void {
    this.sectionEditAction$.next({ source: 'absences', lockOthers: lockOthers });
  }

  public onPayCodesEditAction(lockOthers: boolean): void {
    this.sectionEditAction$.next({ source: 'paycodes', lockOthers: lockOthers });
  }

  public unapproveTimecard(): void {
    this.dataModel.isApproved = false;
    this.dataModel.isLoadedApproved = false;
    this.canEditChanged(this.dataModel.canEditTimecard);
  }

  public approveTimecard(): void {
    this.dataModel.isApproved = true;
    this.dataModel.isLoadedApproved = false;
    this.canEditChanged(this.dataModel.canEditTimecard);
  }

  public selectDisplayOption(option: TimeCardDisplayOptions): void {
    this.saveDisplayOptions(option);
    this.displayOptionSelected$.next(option);
  }

  public discardPunches(): void {
    this.onLoadStatusChanged(true);
    this.api.discardPunches(this.dateOn, this.employeeId)
      .then((res: any) => {
        if (res && res.data && res.data.errorNumber == 1 && moment(res.data.punchDate).isValid()) {
          this.onLoadStatusChanged(false);
          this.preventOverlappingPunchesModal(res.data.punchDate);
        }
        else {
          this.getTimecard(false);
        }
      })
      .catch((reason: any) => {
          this.onError(reason);
      });
  }

  public discardPayRules(): void {
    this.onLoadStatusChanged(true);
    this.api.discardPayRules(this.dateOn, this.employeeId)
      .then((res: any) => {
        this.getTimecard(false);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }

  public saveLastUpdateScheduleOption(value: boolean): void {
    this.lastUpdateScheduleSelection = value;
    this.storageService.setControlState(this.stateManagement.componentKey, this.updateScheduleOptionControlKey,
      { value: value }, StateResetTypes.None);
  }

  public navigateToUnapprovedPaycodes(): void {
    this.navigateToUnApprovedPC$.next(null);
  }

  public navigateToUnapprovedOvertimes(homeOrgLevelId: number): void {
    this.navigateToUnApprovedOT$.next(homeOrgLevelId);
  }

  public updateAbscenseCount(count: number): void {
    this.abscenceCount$.next(count);
  }

  public getAbsencescount(absencesList: DailyAbsence[]): number {
    let count: number = 0;
    _.forEach(absencesList, ele => {
      if (ele.isDeleted === false) {
        count = count + 1;
      }
    });
    return count;
  }


  public recalculateTimecard(): void {
    this.cleanupModel();
    this.onLoadStatusChanged(true);
    this.dataModelBeforeRecalculation = _.cloneDeep(this.dataModel);
    this.timecardsApi.recalculateTimecardsByRange([this.employeeId], this.dateOn, this.dateOn)
      .then((param: any) => {
        this.onLoadStatusChanged(false);
        this.getTimecard(true);
      }).catch((reason: any) => {
        this.onError(reason);
        throw new Error(reason);
      });
  }

  public lockTimecard(model: TimeCardModel): Promise<boolean> {
    return this.callLockApi(model, true);
  }

  public unlockTimecard(model: TimeCardModel): Promise<boolean> {
    return this.callLockApi(model, false);
  }

  private callLockApi(model: TimeCardModel, lock: boolean): Promise<boolean> {
    this.onLoadStatusChanged(true);
    return this.api.lockTimecard(model.employee.id, model.date, lock)
      .then((value: boolean) => {
        this.onLoadStatusChanged(false);
        this.timecardsApi.clearIndividualTimecardsCacheData();
        if (value) {
          this.getTimecard(false);
        }
        return value;
      });
  }

  public getTimecardPunches(punchDate: Date, empId: number): Promise<TimeCardModel> {
    this.onLoadStatusChanged(true);
    return this.api.getDailyTimecardForOverlap(empId, punchDate)
      .then((model: TimeCardModel) => {
        this.onLoadStatusChanged(false);
        return model;
      }).catch((reason: any) => {
        this.onError(reason);
        this.onLoadStatusChanged(false);
        return null;
      });
  }
  public getAllAbsence(date: Date): Promise<void | IDailyAllAbsence[]> {
    this.onLoadStatusChanged(true);
    return this.api.getAllAbsence(this.employeeId, date)
      .then((model: IDailyAllAbsence[]) => {
        this.onLoadStatusChanged(false);
        return model;
      }).catch((reason: any) => {
        this.onError(reason);
        this.onLoadStatusChanged(false);
      });
  }
  private getTimecard(recalculate: boolean): void {
    this.onLoadStatusChanged(true);
    this.api.getDailyTimecard(this.employeeId, this.dateOn)
      .then((model: TimeCardModel) => {
        this.canLock = model.canLock;
        this.canUnlock = model.canUnlock;
        this.cleanDatamodel = model;
        this.prepareAdditionalInfo(model, recalculate);
        this.isPaidRestBreakEnabled$.next(model.isPaidRestBreakEnabled);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  private prepareAdditionalInfo(model: TimeCardModel, recalculate: boolean) {
    let clonedModel: TimeCardModel = this.mapService.mapTimcard(model.rawData);
    this.dataModel = clonedModel;
    if (recalculate) {
      this.recalculateDiff();
    }
    this.restoreDisplayOptions();
    this.loadEmployeeShortInfo(this.employeeId)
      .then((empInfo: EmployeeShortInfo) => {
        this.employeeShortInfo = empInfo;
        this.dataModel.activationDate = empInfo.activationDate;
        this.dataModel.terminationDate = empInfo.terminationDate;
        this.dataModel.transferDepartmentId = empInfo.transferDepartmentId;
        this.getAbsencesAndRestore(this.employeeId, this.dataModel.absences);
        this.getShifts(this.employeeId);
        this.onLoaded(this.dataModel);
        this.onLoadStatusChanged(false);
        this.canEditChanged(this.dataModel.canEditTimecard);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }
  private recalculateDiff(): void {
    if (!this.dataModelBeforeRecalculation) {
      return;
    }
    this.dataModel.recalculated = true;
    _.forEach(this.dataModel.earningStatistics, (earning: EarningStatistic) => {
      const prior = _.find(this.dataModelBeforeRecalculation.earningStatistics, (e: EarningStatistic) => {
        return earning.id === e.id;
      });
      if (prior) {
        if (earning.amount === prior.amount && earning.interval === prior.interval) {
          earning.changeStatus = DailyTimecardChangeStatus.nochanges;
        } else {
          earning.changeStatus = DailyTimecardChangeStatus.changed;
        }
        _.remove(this.dataModelBeforeRecalculation.earningStatistics, (e: EarningStatistic) => {
          return earning.id === e.id;
        });
      } else {
        earning.changeStatus = DailyTimecardChangeStatus.added;
      }
    });
    _.forEach(this.dataModelBeforeRecalculation.earningStatistics, (earning: EarningStatistic) => {
      earning.changeStatus = DailyTimecardChangeStatus.deleted;
      this.dataModel.earningStatistics.push(earning);
    });
    this.dataModelBeforeRecalculation = null;
  }
  private loadEmployeeShortInfo(employeeId: number): Promise<EmployeeShortInfo> {
    return this.empDefApi.getEmployeeShortInfoCached(employeeId);
  }
  private saveTimecard(): void {
    this.cleanupModel();
    this.dataModelBeforeRecalculation = _.cloneDeep(this.dataModel);
    let dateOn: string = moment(this.dataModel.date).format(appConfig.requestDate);
    this.onLoadStatusChanged(true);
    this.api.saveTimecard(this.dataModel, dateOn)
      .then((model: TimeCardModel) => {
        this.timecardsApi.clearIndividualTimecardsCacheData();
        this.cleanDatamodel = model;
        this.cleanDatamodel.canUnlock = this.canUnlock;
        this.cleanDatamodel.canLock = this.canLock;
        this.prepareAdditionalInfo(this.cleanDatamodel, true);
      }).catch((reason: any) => {
        this.onError(reason);
      });
  }

  private cleanupModel(): void {
    _.remove(this.dataModel.earningStatistics, (earning: EarningStatistic) => earning.changeStatus === DailyTimecardChangeStatus.deleted);
    _.remove(this.dataModel.payRules, (payCode: DailyPayRule) => payCode.changeStatus === DailyTimecardChangeStatus.deleted);
  }

  private exportTimecard(): void {
    this.cleanupModel();
    this.onLoadStatusChanged(true);
    let dateOn: string = moment(this.dataModel.date).format(appConfig.requestDate);
    this.api.exportTimecard(this.dataModel, dateOn)
      .then((file: FileBlobResponse) => {
        this.exportedTimecard$.next(file);
        this.onLoadStatusChanged(false);
      }).catch((reason: any) => {
        this.onError(reason);
        throw new Error(reason);
      });
  }

  private saveDisplayOptions(option: TimeCardDisplayOptions): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.displayOptionsControlKey,
      { value: option }, StateResetTypes.None);
  }

  private restoreDisplayOptions(): void {
    let option: TimeCardDisplayOptions;
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.displayOptionsControlKey);
    if (!state.value) {
      option = TimeCardDisplayOptions.showIntervalsInDecimal;
    } else {
      option = state.value;
    }
    this.displayOptionSelected$.next(option);
  }

  private restoreLastUpdateScheduleOption(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.updateScheduleOptionControlKey);
    this.lastUpdateScheduleSelection = !!state.value;
  }

  private async getShifts(employeeId: number): Promise<void> {
    if (this.shiftsLookup) {
      return;
    }
    this.shiftsLookup = await this.lookupService.getShifts(employeeId);
  }

  private async getAbsencesAndRestore(employeeId: number, records: DailyAbsence[]): Promise<TaAbsenceCode[]> {
    if (this.absenceCodesLookup) {
      this.restoreAbsenceCodes(records);
      return this.absenceCodesLookup;
    }
    this.absenceCodesLookup = await this.lookupService.getTaAbsenceCodes(employeeId);
    this.restoreAbsenceCodes(records);
    return this.absenceCodesLookup;
  }

  private restoreAbsenceCodes(records: DailyAbsence[]): void {
    _.forEach(records, (item: DailyAbsence) => {
      let code: TaAbsenceCode = _.find(this.absenceCodesLookup, (c: TaAbsenceCode) => {
        return c.id === item.id;
      });
      if (code) {
        item.absence = code;
        item.checkHasLinkedSa();
        if (!item.hasLinkedSa) {
          item.updateSchedule = false;
        } else {
          item.updateSchedule = this.lastUpdateScheduleSelection;
        }
      }
    });
  }

  public async hasOverlap(matchedPair: {inpunch: punchWrapper, outpunch: punchWrapper}, records: punchWrapper[], defaultPunchTime: Date, employeeId: number): Promise<{hasOverlapPunches: boolean, records: punchWrapper[]}> {
    if (!this.employeeId)
      this.employeeId = employeeId;
    return await this.getTimecardPunches(matchedPair.inpunch.punch.time, this.employeeId).then(data => {
      let _dailyPunches = _.filter(data.dailyPunches, (x: LinePunch) => moment(x.punchDate).format(appConfig.linkDateFormat) != moment(defaultPunchTime).format(appConfig.linkDateFormat));
      let hasOverlapPunches = false;
      var pairs: { inpunch: LinePunch, outpunch: LinePunch }[] = [];
      var inp = null, oup = null;
      _.forEach(_dailyPunches, x => {
        if (x.type.name == 'in' || x.type.name == 'in after lunch' || x.type.name == 'in after break' || x.type.name == 'training start') {
          inp = x;
        }
        else if (x.type.name == 'out' || x.type.name == 'out to lunch' || x.type.name == 'out to break' || x.type.name == 'training end') {
          oup = x;
          if (inp && oup) {
            pairs.push({ inpunch: inp, outpunch: oup });
          }
          inp = null;
          oup = null;
        }
      })

      let overlapIndex = -1;
      let _overlapType = 'inOverlap';
      _.forEach(pairs, existing => {
        let res: {result: boolean, overlapType: string} = this.containsOverlap(existing, matchedPair, defaultPunchTime);
        if (res.result) {
          hasOverlapPunches = true;
          _overlapType = res.overlapType;
          if (res.overlapType == "both") {

            let indiff = moment(existing.inpunch.punchDate).format(appConfig.linkDateFormat) ==  moment(matchedPair.inpunch.punch.time).format(appConfig.linkDateFormat);

            this.currentOverlapDate = indiff ? (matchedPair.inpunch.punch.time) : matchedPair.outpunch.punch.time;
            overlapIndex = _.findIndex(records, (x) => x.punch.time.toString() == matchedPair.inpunch.punch.time.toString() && x.id == matchedPair.inpunch.id);

            if (records[overlapIndex] && records[overlapIndex].punch) {
              records[overlapIndex].punch.isEditedAndValidated = true;
              records[overlapIndex].punch.punchStatus = PunchStatus.Overlap;
            }

            if (records[overlapIndex + 1] && records[overlapIndex + 1].punch && records[overlapIndex + 1].punch.punchDisplay != "Schedule" && records[overlapIndex + 1].punch.punchSource != "Schedule") {
              records[overlapIndex + 1].punch.isEditedAndValidated = true;
              records[overlapIndex + 1].punch.punchStatus = PunchStatus.Overlap;
            }
          } else
          if (res.overlapType == "inOverlap") {
            this.currentOverlapDate = (matchedPair.inpunch.punch.time);
            overlapIndex = _.findIndex(records, (x) => x.punch.time.toString() == matchedPair.inpunch.punch.time.toString() && x.id == matchedPair.inpunch.id);
            if (records[overlapIndex] && records[overlapIndex].punch) {
              records[overlapIndex].punch.isEditedAndValidated = true;
              records[overlapIndex].punch.punchStatus = PunchStatus.Overlap;
            }
            if (records[overlapIndex + 1] && records[overlapIndex + 1].punch && records[overlapIndex + 1].punch.punchStatus == PunchStatus.Overlap)
              records[overlapIndex + 1].punch.punchStatus = PunchStatus.Edited;
          } else {
            this.currentOverlapDate = matchedPair.outpunch.punch.time;
            overlapIndex = _.findIndex(records, x => x.punch.time.toString() == matchedPair.outpunch.punch.time.toString() && x.id == matchedPair.outpunch.id);
            if (records[overlapIndex] && records[overlapIndex].punch) {
              records[overlapIndex].punch.isEditedAndValidated = true;
              records[overlapIndex].punch.punchStatus = PunchStatus.Overlap;
            }
            if (records[overlapIndex - 1] && records[overlapIndex - 1].punch && records[overlapIndex - 1].punch.punchStatus == PunchStatus.Overlap)
              records[overlapIndex - 1].punch.punchStatus = PunchStatus.Edited;
          }
          return false;
        }
      })
      
      if (hasOverlapPunches) {
        this.setHasOverlap(true);
        this.preventOverlappingPunchesModal(this.currentOverlapDate);
        return { hasOverlapPunches, records };
      } else {
        this.setHasOverlap(false);
        return { hasOverlapPunches, records };
      }
    });
  }

  private containsOverlap(existing: { inpunch: LinePunch; outpunch: LinePunch; }, matchedPair: { inpunch: punchWrapper; outpunch: punchWrapper; }, defaultPunchTime: Date): {result: boolean, overlapType: string} {
    let isSameDay = (
      moment(matchedPair.inpunch.punch.punchDate).isSame(defaultPunchTime) && moment(matchedPair.outpunch.punch.punchDate).isSame(defaultPunchTime) && 
      moment(existing.inpunch.punchDate).isSame(defaultPunchTime) && moment(existing.outpunch.punchDate).isSame(defaultPunchTime)
    );
    if (isSameDay) {
      return { result: !isSameDay, overlapType: "inOverlap" };
    }

    let res = (
      moment(matchedPair.inpunch.punch.time).isSameOrBefore(existing.inpunch.time) && moment(existing.outpunch.time).isBefore(matchedPair.outpunch.punch.time) ||
      moment(existing.inpunch.time).isSame(matchedPair.inpunch.punch.time) && moment(matchedPair.outpunch.punch.time).isSame(existing.outpunch.time)
    );
    if (res) {
      return { result: res, overlapType: "both" };
    } else {
      res = (
        moment(existing.inpunch.time).isSameOrBefore(matchedPair.inpunch.punch.time) && moment(matchedPair.outpunch.punch.time).isBefore(existing.outpunch.time) ||
        moment(existing.inpunch.time).isBefore(matchedPair.inpunch.punch.time) && moment(existing.outpunch.time).isSameOrBefore(matchedPair.outpunch.punch.time) && moment(matchedPair.inpunch.punch.time).isBefore(existing.outpunch.time)
      );
      if (res) {
        return { result: res, overlapType: "inOverlap" };
      } else {
        res = (moment(matchedPair.inpunch.punch.time).isBefore(existing.inpunch.time) && moment(matchedPair.outpunch.punch.time).isSameOrBefore(existing.outpunch.time) && moment(existing.inpunch.time).isBefore(matchedPair.outpunch.punch.time));
        return { result: res, overlapType: "outOverlap" };
      }
    }
  }

  public async checkOverlap(
    defaultPunchTime: Date,
    records: punchWrapper[],
    hasOverlapPunches: boolean,
    overlapIndex: number,
    gridState: KendoGridStateHelper<punchWrapper>,
    hasChanges: boolean,
    employeeId: number,
    item?: punchWrapper): Promise<{hasOverlapPunches: boolean, records: punchWrapper[]}> {
      return await this.getTimecardPunches(defaultPunchTime, employeeId).then(data => {
        if(item != undefined){

         _.sortBy(data.dailyPunches, x => x.time);
          records = gridState.view.data;

          var ind = gridState.view.data.findIndex(x => x.id === item.id && x.punch.time.valueOf() === item.punch.time.valueOf());
          
          if(ind != 0){
            if((ind != records.length-1)){
              return { hasOverlapPunches, records };
            }
          }
        }
        var prevDate = moment(defaultPunchTime).subtract(1, 'day').toDate();
        //var currentDate = moment(defaultPunchTime).toDate();
        var nextDate = moment(defaultPunchTime).add(1, 'day').toDate();
        var prevDay = [];
        var nextDay = [];

        var prevDayInd = this.findPunchIndex(data, prevDate);        
        if (prevDayInd >= 0) {
          this.filterPunches(data, prevDate, prevDay);
        }
        else {
          prevDate = moment(prevDate).subtract(1, 'day').toDate();
          this.filterPunches(data, prevDate, prevDay);
        }

        var nextDayInd = this.findPunchIndex(data, nextDate);
        if(nextDayInd >= 0){
          this.filterPunches(data, nextDate, nextDay);
        }
        else{
          nextDate = moment(nextDate).add(1, 'day').toDate();
          this.filterPunches(data, nextDate, nextDay);
        }

        if(prevDay.length > 0) {
          this.prevFirstPunch = prevDay[0];
          this.prevLastPunch = prevDay.slice(-1).pop();
          this.prevFirstPunchTime = this.prevFirstPunch.time;
          this.prevLastPunchTime = this.prevLastPunch.time;
        }

        this.currentFirstPunch = gridState.view.data[0];
        this.currentLastPunch = gridState.view.data.slice(-1).pop();
        this.currentFirstPunchTime = this.currentFirstPunch.punch.time;
        this.currentLastPunchTime = this.currentLastPunch.punch.time;
        
        if(nextDay.length > 0) {
          this.nextFirstPunch = nextDay[0];
          this.nextLastPunch = nextDay.slice(-1).pop();
          this.nextFirstPunchTime = this.nextFirstPunch.time;
          this.nextLastPunchTime = this.nextLastPunch.time;
        }
          hasOverlapPunches = false;
          this.setHasOverlap(false);
          if(prevDay.length > 0) {
            if (moment(this.currentFirstPunchTime.valueOf()).isBefore(this.prevLastPunchTime.valueOf())) {
              hasOverlapPunches = true;
              this.actualOverlapIndex = 0;
              this.currentOverlapDate = prevDate;
            }
          }
          if(!hasOverlapPunches && ind != 0){
            if(nextDay.length > 0) {
              if (moment(this.nextFirstPunchTime.valueOf()).isBefore(this.currentLastPunchTime.valueOf())) {
                hasOverlapPunches = true;
                this.actualOverlapIndex = records.length -1;
                this.currentOverlapDate = nextDate;
              }
            }
          }
          if (records[ind] && records[ind].punch) {
            records[ind].punch.punchStatus = PunchStatus.Edited;
          }
          if (hasOverlapPunches) {
            var ind = this.actualOverlapIndex;
            overlapIndex = this.actualOverlapIndex;
            hasChanges = true;
            records[ind].punch.isEditedAndValidated = true;
            records[ind].punch.punchStatus = PunchStatus.Overlap;

            this.setHasOverlap(true);
            this.preventOverlappingPunchesModal(this.currentOverlapDate);
            return {hasOverlapPunches,records};
          }else{
            return {hasOverlapPunches,records};
          }
      });
  }

  public preventOverlappingPunchesModal(value): void {
    const newDate = moment(value).format(appConfig.dateFormat);
    let options: ConfirmOptions = new ConfirmOptions();
    options.showOK = true;
    options.buttonOKtext = 'OK';
    ConfirmDialogComponent.openDialog(
      'Overlapping Punches',
      `The punch edits on this timecard overlap with an existing entry on the timecard for - ${newDate}`,
      this.modalService,
      (result: boolean) => { }, options);
  }

  filterPunches(data: any, date: Date, filteredData: any){
    return data.dailyPunches.filter(x => {
      if (x.punchDate.valueOf() == date.valueOf()) {
        filteredData.push(x);
      }
    })
  }

  findPunchIndex(data: any, date: Date): number {
    let punchIndex = data.dailyPunches.findIndex(x=> x.punchDate.valueOf() == date.valueOf());
    return punchIndex;
  }
}
