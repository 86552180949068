import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, RouterStateSnapshot, Router, Resolve
} from '@angular/router';

import * as _ from 'lodash';

import { BaseResolver, LoaCategory, LoaActions } from '../../../../common/index';
import { ILoaFilter } from '../../models/loa-filter';

@Injectable()
export class LoaFilterResolver extends BaseResolver<ILoaFilter> implements Resolve<ILoaFilter> {
  constructor (
    router: Router
  ) {
    super(router);
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ILoaFilter> {
    let category: string = _.toString(route.queryParamMap.get('category'));
    let type: string = _.toString(route.queryParamMap.get('type'));
    let action: string = _.toString(route.queryParamMap.get('action'));
    let empId: number = _.toNumber(route.queryParamMap.get('empId'));

    if (!category && !type && !action && !empId) {
      return null;
    }
    const cat = category ? LoaCategory[category] : null;
    const act = action ? LoaActions[action] : null;

    const res: ILoaFilter = {
      category: cat,
      loaType: type,
      action: act,
      empId: empId
    };
    return Promise.resolve(res);
  }
}

