<div class="header-list-container">
  <div class="header-list-body">
    <div class="header-list-list">
      <div class="list-container">
        <div *ngFor="let timeclockDailySummary of displayedRecords" class="list-item">
          <slx-timeclock-item [timeclockDailySummary]="timeclockDailySummary"></slx-timeclock-item>
        </div>
        <div class="header-line">
          <button *ngIf="totalRecordsCount > displayedRecords?.length" type="button" slxTimeclockViewAll class="view-all-btn" (click)="onViewAllClick()">View All</button>
        </div>
      </div>
    </div>
  </div>
  <i x-arrow class="header-list-arrow" aria-hidden="true"></i>
</div>
