import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  HostListener,
  HostBinding,
  OnInit,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef
} from '@angular/core';
import * as _ from 'lodash';
import * as createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
import { CustomDomEvents } from '../../models/index';
export const MONEY_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MoneyInputComponent),
  multi: true
};

@Component({
  moduleId: module.id,
  selector: 'slx-money-input',
  templateUrl: 'money-input.component.html',
  styleUrls: ['money-input.component.scss'],
  providers: [MONEY_VALUE_ACCESSOR]
})
export class MoneyInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() public className: string;
  @Input() public placeholder: string = 'Money';
  @Input() public decimalLimit: number = 0;
  @Input() public requireDecimal: boolean = false;
  @Input() public allowNegative: boolean = false;
  @Input() public prefix: string = '$';
  @Input() public suffix: string = '';
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }
  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      let val: string = this.innerValue;
      if (val) {
        val = val.replace(/\$/g, '');
        val = val.replace(/,/g, '');
      }
      this.onChangeCallback(parseFloat (val));
    }
  }

  public moneyMaskConf: any;
  public inEdit: boolean;

  private elementRef: ElementRef;
  private changeDetector: ChangeDetectorRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: any = '';

  @HostBinding('class.edited') private hasFocus: boolean = false;
  constructor(changeDetector: ChangeDetectorRef,elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.changeDetector = changeDetector;
    this.hasFocus = false;
  }

  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }

  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }

  public ngOnInit(): void {
    this.initMask();
  }

  public writeValue(value?: any): void {
    this.innerValue = value;
    this.changeDetector.markForCheck();
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  private initMask(): void {
    let numberMask: any = createNumberMask.default(
      {
        prefix: this.prefix,
        suffix: this.suffix,
        includeThousandsSeparator: true,
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: this.decimalLimit,
        requireDecimal: this.requireDecimal,
        allowNegative: this.allowNegative
      });
    this.moneyMaskConf = {
      mask: numberMask,
      modelClean: true
    };
  }
}
