import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { ModalService } from '../../../../common/index';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { StateResetTypes } from '../../../../core/models/settings/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var SELECTED_EMP_ID = 'selectedEmpId';
var DailyUnitGridPartialshiftComponent = /** @class */ (function () {
    function DailyUnitGridPartialshiftComponent(stateManagement, storageService, modalService) {
        var _this = this;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.modalService = modalService;
        this.IsEnabled = false;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.onEmployeeCancel = new EventEmitter();
        this.onEmployeeDelete = new EventEmitter();
        this.onFillShiftPartial = new EventEmitter();
        this.onReplaceEmployee = new EventEmitter();
        this.onShiftOpen = new EventEmitter();
        this.onSwitchEmployeeShift = new EventEmitter();
        this.onOpenIndividual = new EventEmitter();
        this.onNeedScroll = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    }
    Object.defineProperty(DailyUnitGridPartialshiftComponent.prototype, "partials", {
        get: function () {
            return this.partialShifts;
        },
        set: function (value) {
            this.partialShifts = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitGridPartialshiftComponent.prototype.refreshGrid = function () {
        if (!this.partialShifts) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.take = 24;
        var partialRecords = _.orderBy(this.partialShifts, ['ShiftStart']);
        this.gridState.view = process(partialRecords, this.gridState.state);
    };
    DailyUnitGridPartialshiftComponent.prototype.selectEmployee = function (row) {
        var empId = +row.EmpId;
        this.storageService.setControlState(this.stateManagement.componentKey, SELECTED_EMP_ID, { value: empId }, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange);
    };
    DailyUnitGridPartialshiftComponent.prototype.isEmptyEmployee = function (row) {
        if (row.EmpId === '0')
            return true;
    };
    DailyUnitGridPartialshiftComponent.prototype.isInPar = function (row) {
        return row.InPar.toLowerCase() !== 'false';
    };
    DailyUnitGridPartialshiftComponent.prototype.checkPartnerData = function (row) {
        if (row.SlxpartnerdataId !== '0')
            return true;
    };
    DailyUnitGridPartialshiftComponent.prototype.switchEmployee = function (row) {
        this.selectEmployee(row);
        this.onSwitchEmployeeShift.emit(row);
    };
    DailyUnitGridPartialshiftComponent.prototype.isNoPatner = function (row) {
        if (row.SlxpartnerdataId === '0') {
            return true;
        }
        else {
            if (row.RequeststatusId === '0' || row.RequeststatusId === '3') {
                return true;
            }
            else {
                return false;
            }
        }
    };
    DailyUnitGridPartialshiftComponent.prototype.isOvertime = function (row) {
        return row.IsOvertime.toLowerCase() === 'true';
    };
    DailyUnitGridPartialshiftComponent.prototype.fillShift = function (row) {
        this.onFillShiftPartial.emit(row);
    };
    DailyUnitGridPartialshiftComponent.prototype.removeEmployee = function (row) {
        this.onEmployeeDelete.emit(row);
    };
    DailyUnitGridPartialshiftComponent.prototype.getRemoveTip = function (row) {
        return this.isOvertime(row) ? 'Projected for OT' : 'Remove Employee';
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridPartialshiftComponent.prototype, "gridRefreshSubscription", void 0);
    return DailyUnitGridPartialshiftComponent;
}());
export { DailyUnitGridPartialshiftComponent };
