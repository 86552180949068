<button class="btn btn-primary" class="agency-header" id="button_{{agencyId}}" type="button"
    (click)="changeExpandedIconState(agencyId)" data-toggle="collapse" [attr.data-target]="'#'+agencyId"
    aria-expanded="true" aria-controls="agencyHeader">
    <span class=" agency-console-name " >{{agencyHeader+' ('+ totalCount+ ')'}}</span> <i id="{{agencyId}}_icon"
        class="fas fa-angle-down rotate-icon rotate-icon"></i>
</button>

<div class="container-fluid slx-row-padding" [ngClass]="firstAgency ? 'collapse in' : 'collapse'" id="{{agencyId}}">

    <div class="row agency-console-body">
        <div class="col-lg-3">
            <div class="total-agency-staff">
                <span>Total Agency Staff: </span>
                <span class="total">{{totalCount}}</span>
            </div>
        </div>
        <div class="col-lg-9 container-fluid shift-status-col" >
            <div class="row">                
                <div class="col-lg-2 col-sm-2 shift-state-col">
                    <div class="agency-staff-box agency-staff-assigned" 
                        [ngClass]="assigned ? 'highlight assigned-filter' : ''">
                        <div class="assigned">Assigned</div>
                        <span class="slx-agency-number">{{assignedCount}}</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-2 shift-state-col" >
                    <div class="agency-staff-box agency-staff-pending" 
                        [ngClass]="pending ? 'highlight pending-filter' : ''">
                        <div class="pending">Pending </div>
                        <span class="slx-agency-number">{{pendingCount}}</span>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-2 shift-state-col">
                    <div class="agency-staff-box agency-staff-required" 
                        [ngClass]="requested ? 'highlight requested-filter' : ''">
                        <div class="requested">Pending Action</div>
                        <span class="slx-agency-number">{{pendingActionCount}}</span>

                    </div>
                </div>
                <div class="col-lg-2 col-sm-2 shift-state-col">
                    <div class="agency-staff-box agency-staff-cancelled"
                        [ngClass]="cancelled ? 'highlight cancelled-filter' : ''">
                        <div>
                            <div class="cancelled">Cancelled</div>
                            <span class="slx-agency-number">{{cancelledCount}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-2 shift-state-col">
                    <div class="agency-staff-box agency-staff-failed" 
                        [ngClass]="failed ? 'highlight failed-filter' : ''">
                        <div class="failed">Failed</div>
                        <span class="slx-agency-number">{{failedCount}}</span>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12">
              
            </div>
        </div>
        <div class="row container-fluid slx-row-padding">
            <div class="col-lg-12">
                <div class="pull-right filter">
                    <slx-actions-list>
                        <slx-actions-button className="low-height auto-width" [popperContent]="Actionsettings"
                            [popperPosition]="'bottom-end'">
                            <i class="fas fa-cog" aria-hidden="true"></i> <span style="margin-left:10px;">Actions</span>
                        </slx-actions-button>
                        <popper-content #Actionsettings>
                            <slx-actions-list-item (click)="onExcelExport(partnerId)">Export to Excel
                            </slx-actions-list-item>
                            <slx-actions-list-item (click)="captureScreen(partnerId)">Export to PDF</slx-actions-list-item>
                        </popper-content>
                    </slx-actions-list>
                </div>
            </div>
        </div>
        <div class="row container-fluid slx-row-padding" >    
            <div class="col-xs-12">
                <ag-grid-angular #agGrid class="ag-theme-balham agency-grid" [rowData]="rowData" [columnDefs]="columnDefs"
                [rowHeight]="rowHeight" id="agGrid" suppressSizeToFit="true"
                (gridSizeChanged)="onGridSizeChanged($event)" (gridReady)="onGridReady($event,partnerId)">
            </ag-grid-angular>
            </div>
        </div>


   

    </div>