import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import * as _ from 'lodash';
import { ServerCompositeFilterDescriptor, ServerFilterDescriptor, ServerQuery, IFilteredItems, PagingData, IFieldPolicy } from '../../../../../core/models/index';
import { ServerFilterService, addObjectToFilter, addFieldToFilter } from '../../../../../core/services/index';
import { LookupService, OrgLevelWatchService } from '../../../../../organization/services/index';
import { Lookup, LookupType, EmployeeDefinition } from '../../../../../organization/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { AuditTrailEmployeeAction, AuditTrailLimitedReq } from '../../../models/index';
import { AuditTrailApiService, AuditTrailMapService } from '../../../services/index';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';
import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'slx-audit-trail-limited',
  templateUrl: 'audit-trail-limited.component.html',
  styleUrls: ['audit-trail-limited.component.scss'],
  providers: [ServerFilterService]
})
export class AuditTrailLimitedComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public req: AuditTrailLimitedReq;

  public state: {
    isLoading: boolean;
  };
  public actionList: IFilteredItems<AuditTrailEmployeeAction>;
  public isreadonly: boolean;

  @unsubscribe()
  private filterChangeSubscription: Subscription;
  private apiService: AuditTrailApiService;
  private pagingData: PagingData;
  private selectedEmps: number[];


  constructor(apiService: AuditTrailApiService, private serverFilterService: ServerFilterService, private orgLevelWatchService: OrgLevelWatchService, private auditTrailMapService: AuditTrailMapService) {
    this.apiService = apiService;
    this.pagingData = { skip: 0, take: 50 };
    this.state = { isLoading: false };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.composeFilterAndRefresh();
  }

  public ngOnInit(): void {
    this.filterChangeSubscription = this.serverFilterService.changes$.subscribe((filter: ServerCompositeFilterDescriptor) => {
      this.refreshData();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public canFilter(field: string): boolean {
    return true;
  }

  public composeFilterAndRefresh(): void {
    if (this.req) {
      this.setFilters();
      this.refreshData();
    }
  }

  public refreshData(): void {
    this.state.isLoading = true;

    this.apiService.getEmployeeActionsForEffectiveDate(this.serverFilterService.createQuery(this.pagingData), this.req.orgLevelId, false, moment(this.req.date).startOf('day').toDate())
      .then((val: IFilteredItems<AuditTrailEmployeeAction>) => {
        this.state.isLoading = false;
        this.actionList = val;
      }).catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  public setFilters(): void {
    this.serverFilterService.composeFilter({ field: 'employeeId', operator: 'eq', value: this.req.employeeId });
    this.serverFilterService.composeFilter({ field: 'area', operator: 'eq', value: this.req.area });
  }

  public gridPageChanged(pagingData: PagingData): void {
    this.pagingData = pagingData;
    this.refreshData();
  }
}
