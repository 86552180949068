import * as tslib_1 from "tslib";
import { Provider } from '@angular/core';
import { Observable } from 'rxjs-compat';
import { DialogModeSize, DialogOptions, DialogOptions2, KendoGridStateHelper, ModalService } from '../../../../../app/common';
import { mutableSelect } from '../../../../../app/core/decorators';
import { appConfig } from '../../../../../app/app.config';
import { DailyOpenShiftDetailsData } from '../../../../../app/scheduler/models';
import { DetailScreenService } from '../../../../../app/scheduler/services';
import { ShiftRequestPopupComponent } from '../../shift-request-popup/shift-request-popup.component';
import * as _ from 'lodash';
import { PositionsConfigurationApiService, ShiftsApiService, UnitsApiService } from '../../../../../app/configuration/services';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { OpenShift, OpenShiftSchedule } from '../../../../../app/scheduler/models/daily-unit-assignment/add-open-shift';
import { ShiftRequestService } from '../../../../../app/scheduler/services/schedule/shift-request.service';
var AddOpenShiftsV1Component = /** @class */ (function () {
    function AddOpenShiftsV1Component(options, modalService, shiftApiService, positionApiService, detailScreenService, shiftRequestService, unitApiService) {
        this.shiftApiService = shiftApiService;
        this.positionApiService = positionApiService;
        this.detailScreenService = detailScreenService;
        this.shiftRequestService = shiftRequestService;
        this.unitApiService = unitApiService;
        this.storeSelectedDataArr = [];
        this.isAddDisabled = false;
        this.positionMappingError = true;
        this.shiftStarDateError = true;
        this.isLoading = false;
        this.isSelectedShift = false;
        this.selectedOpenShift = [];
        this.openShift = new OpenShift();
        this.displayId = 1;
        this.minDate = new Date();
        this.max = 25;
        this.min = 1;
        this.modalService = modalService;
        this.options = options;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.appConfig = appConfig;
        this.getShiftDetails();
        this.getUnits();
        this.getPositions();
        this.openShift.startDateRange = this.requestedDate;
        this.openShift.endDateRange = new Date(moment(this.openShift.startDateRange).add(7, 'days').calendar());
        this.maxDate = new Date(moment(this.openShift.startDateRange).add(30, 'days').calendar());
    }
    AddOpenShiftsV1Component.prototype.getMaxDate = function () {
        return new Date(moment(this.openShift.startDateRange).add(30, 'days').calendar());
    };
    AddOpenShiftsV1Component.prototype.updateEndDate = function (event) {
        this.openShift.endDateRange = new Date(moment(event).add(7, 'days').calendar());
    };
    AddOpenShiftsV1Component.openDialog = function (groups, modalService, orgLevel, requestDate, agencyData) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.minHegiht = 800;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions,
            },
            {
                provide: DailyOpenShiftDetailsData,
                useValue: groups,
            },
        ];
        AddOpenShiftsV1Component.requestedDate = requestDate;
        AddOpenShiftsV1Component.orgDetails = orgLevel;
        AddOpenShiftsV1Component.agencyDetailsData = agencyData;
        this.currentOffset = +_.get(orgLevel, 'location.timeZoneOffset') || 0;
        var header = "Add Open Shift for  " + orgLevel.name;
        modalService.globalAnchor.openDialog2(AddOpenShiftsV1Component, header, dialogOptions, resolvedProviders, function () { });
        return AddOpenShiftsV1Component;
    };
    Object.defineProperty(AddOpenShiftsV1Component.prototype, "requestedDate", {
        get: function () {
            return AddOpenShiftsV1Component.requestedDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV1Component.prototype, "orgdetailsData", {
        get: function () {
            return AddOpenShiftsV1Component.orgDetails;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV1Component.prototype, "agencyDetailsData", {
        get: function () {
            return AddOpenShiftsV1Component.agencyDetails;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV1Component.prototype, "getcurrentOffset", {
        get: function () {
            return ShiftRequestPopupComponent.currentOffset;
        },
        enumerable: true,
        configurable: true
    });
    AddOpenShiftsV1Component.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        //console.info('Destroy Add Employee popup');
    };
    AddOpenShiftsV1Component.prototype.getShiftDetails = function () {
        var _this = this;
        this.isLoading = true;
        this.shiftApiService.getShiftsList(this.orgdetailsData.id).
            then(function (result) {
            _this.shifts = result.records;
            _this.shiftsData = _this.shifts;
            _this.isLoading = false;
        }).catch(function () {
            console.log("error");
            _this.isLoading = false;
        });
    };
    AddOpenShiftsV1Component.prototype.getUnits = function () {
        var _this = this;
        this.unitApiService.getUnitsList(this.orgdetailsData.id).
            then(function (result) {
            _this.units = result.records;
            _this.unitData = _this.units;
        }).catch(function () {
            console.log("error Units");
        });
    };
    AddOpenShiftsV1Component.prototype.getPositions = function () {
        var _this = this;
        this.positionApiService.getPositions(this.orgdetailsData.id).
            then(function (result) {
            _this.positions = result.positions;
            _this.positionData = _this.positions;
        }).catch(function () {
        });
    };
    AddOpenShiftsV1Component.prototype.addOpenShifts = function () {
        this.openShift.startDate = this.openShift.startDateRange.toLocaleDateString();
        this.openShift.endDate = this.openShift.endDateRange.toLocaleDateString();
        var tempOpenShift = _.cloneDeep(this.openShift);
        if (this.selectedOpenShift.length == 0) {
            this.selectedOpenShift.push(tempOpenShift);
        }
        else {
            this.isUniqueShifts();
            if (!this.isMappedShift) {
                this.isMappedShift = true;
                this.selectedOpenShift.push(tempOpenShift);
            }
        }
        if (this.selectedOpenShift.length > 0) {
            this.isSelectedShift = true;
        }
        else {
            this.isSelectedShift = false;
        }
        this.resetOpenShift();
        this.refreshGrid();
    };
    AddOpenShiftsV1Component.prototype.isUniqueShifts = function () {
        var _this = this;
        this.isMappedShift = false;
        // Error if shift with same defination found 
        this.selectedOpenShift.forEach(function (element) {
            var position = [_this.openShift.position];
            var m_position = [element.position];
            if (m_position[0].id == position[0].id) {
                var unit = [_this.openShift.unit];
                var m_unit = [element.unit];
                if (m_unit[0].id == unit[0].id) {
                    var shifts = [_this.openShift.Shifts];
                    var m_shifts = [element.Shifts];
                    if (m_shifts[0].id == shifts[0].id) {
                        console.log("Ideal Schedule is already created");
                        _this.isMappedShift = true;
                    }
                }
            }
        });
    };
    AddOpenShiftsV1Component.prototype.resetOpenShift = function () {
        this.openShift = new OpenShift();
        this.openShift.startDateRange = this.requestedDate;
        this.openShift.endDateRange = new Date(moment(this.requestedDate).add(7, 'days').calendar());
        ;
    };
    AddOpenShiftsV1Component.prototype.cancelHandler = function (dataItem) {
        this.selectedOpenShift = this.selectedOpenShift.filter(function (el) { return el.displayId != dataItem.displayId; });
        this.refreshGrid();
    };
    AddOpenShiftsV1Component.prototype.refreshGrid = function () {
        if (this.selectedOpenShift.length == 0) {
            this.isSelectedShift = false;
            this.gridState.view = null;
            return;
        }
        var displayId = 1;
        this.selectedOpenShift.forEach(function (item) {
            item.displayId = displayId++;
        });
        this.pageSize = this.selectedOpenShift.length;
        this.gridState.view = process(this.selectedOpenShift, this.gridState.state);
    };
    AddOpenShiftsV1Component.prototype.onCancel = function () {
        this.dialogResult = false;
        this.shiftRequestService.openShiftRequest(true);
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    AddOpenShiftsV1Component.prototype.onAdd = function () {
        var _this = this;
        var idealSchedule = new OpenShiftSchedule();
        idealSchedule.openShifts = this.selectedOpenShift;
        idealSchedule.orgLevelId = this.orgdetailsData.id;
        this.isLoading = true;
        this.detailScreenService.saveIdealSchedule(this.orgdetailsData.id, idealSchedule).then(function (e) {
            _this.shiftRequestService.openShiftRequest(true);
            _this.isLoading = false;
            _this.dialogResult = false;
            _this.modalService.closeWindow(_this.options.windowUniqueId);
        }).catch(function (e) {
            _this.isLoading = false;
            _this.dialogResult = false;
            _this.modalService.closeWindow(_this.options.windowUniqueId);
        });
    };
    AddOpenShiftsV1Component.prototype.isSelected = function () {
        if (this.openShift.position == null || this.openShift.unit == null || this.openShift.Shifts == null || this.openShift.count < 0 || this.openShift.count == null || this.openShift.count > 25) {
            return true;
        }
        else {
            return false;
        }
    };
    AddOpenShiftsV1Component.prototype.getDateRange = function (dataItem) {
        var startDateRange = dataItem.startDateRange;
        var endDateRange = dataItem.endDateRange;
        return startDateRange.toLocaleDateString() + " - " + endDateRange.toLocaleDateString();
    };
    AddOpenShiftsV1Component.prototype.filterPosition = function (value) {
        this.positionData = this.positions.filter(function (s) { return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
    };
    AddOpenShiftsV1Component.prototype.filterUnits = function (value) {
        this.unitData = this.units.filter(function (s) { return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
    };
    AddOpenShiftsV1Component.prototype.filterShifts = function (value) {
        this.shiftsData = this.shifts.filter(function (s) { return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
    };
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], AddOpenShiftsV1Component.prototype, "user$", void 0);
    return AddOpenShiftsV1Component;
}());
export { AddOpenShiftsV1Component };
