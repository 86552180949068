export interface ILoaRequestCounters {
  onLeaveCount: number;
  returningCount: number;
  upcomingCount: number;
  pastDueCount: number;
  incompleteCount: number;
}

export class LoaRequestCounters {
  public onLeaveCount: number;
  public returningCount: number;
  public upcomingCount: number;
  public pastDueCount: number;
  public incompleteCount: number;
}

