<form novalidate #groupForm="ngForm" class="slx-full-height">
  <kendo-grid #kendoSupervisorGroupGrid kendoGridFixFreezeHeader class="slx-full-height"
    [data]="gridState.view"
    [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort"
    [filterable]="true"
    [filter]="gridState.state.filter"
    [skip]="gridState.state.skip"
    (pageChange)="gridState.pageChange($event)"
    (dataStateChange)="gridState.dataStateChange($event)"
    slxKendoGridState="SUPERVISER_GROUPS_GRID"
  >

    <kendo-grid-column title="Select" [sortable]="false" field="isSelected" media="sm" width="40" [filterable]="false">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange(dataItem, rowIndex)" name="selector{{rowIndex}}">
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Group Name" width="250" media="sm" field="name">
      <ng-template kendoGridHeaderTemplate>
        Group Name
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.name}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex" let-column="column" let-formGroup="formGroup">
        <slx-input-decorator className="slx-no-label">
          <input slx-input required="true" [formControl]="formGroup.get(column.field)" [slxUnique]="{idValue: dataItem.id, idPropertyName: 'id', propertyName: 'name', values: records}"
            name="name" placeholder="Group Name">
          <span errorMessage for="required"></span>
          <span errorMessage for="unique">Group Name should be unique</span>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column width="100" title="Supervisors" media="sm" field="supervisors.length" class="numeric-cell" footerClass="numeric-cell">
      <ng-template kendoGridHeaderTemplate>
        Supervisors
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.supervisors?.length}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column width="100" title="Assigned Employees" media="sm" field="employees.length" class="numeric-cell" footerClass="numeric-cell">
      <ng-template kendoGridHeaderTemplate>
        Assigned Employees
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.employees?.length}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-number-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column width="200" title="Org Level" media="sm" field="orgLevel.name">
      <ng-template kendoGridHeaderTemplate>
        Org Level
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.orgLevel?.type">{{dataItem?.orgLevel?.type}} - </span> {{dataItem?.orgLevel?.name}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <div *kendoGridDetailTemplate="let dataItem">
      <slx-supervisor-group-detail [group]="dataItem" [disableOperations]="addGroupMode||editGroupMode"></slx-supervisor-group-detail>
    </div>
  </kendo-grid>
</form>
