import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationExtras } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Assert, StringUtils } from '../../../framework/index';
import { authenticationConfig } from '../../authentication.config';
import 'rxjs/add/operator/map';
import { VersionSubscribeService } from '../../../common/index';
import { AuthenticationService } from '../../services/index';
import { SessionActions } from '../../actions/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { IApplicationConfig, appConfig } from '../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {

  public isMarketingExpanded: boolean = false;
  public appConfig: IApplicationConfig;
  public promoUrl: SafeResourceUrl;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private versionSubscriber: VersionSubscribeService,
    private authenticationService: AuthenticationService,
    private sessionActions: SessionActions,
    private userActivityService: UserActivityService,
    public sanitizer: DomSanitizer
  ) {
    Assert.isNotNull(router, 'router');
    Assert.isNotNull(route, 'route');
    this.appConfig = appConfig;
    this.promoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig.links.promoInfoLink);
  }

  public ngOnInit(): void {
    this.isMarketingExpanded = false;
    this.userActivityService.stop();
    const returnUrl: string = this.route.snapshot.queryParams[authenticationConfig.login.returnUrlQueryParameter];
    this.sessionActions.userLoggedOut(!!returnUrl);
  }

  public toggleMarketing(): void {
    this.isMarketingExpanded = !this.isMarketingExpanded;
  }

}
