import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../../app.config';
import { EmployeeWarningSections } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EmployeeSectionsWarningsCompanyRemarksComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsCompanyRemarksComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.subscriptions = {};
        this.appConfig = appConfig;
    }
    EmployeeSectionsWarningsCompanyRemarksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.remarks = this.manService.getCompanyRemarksSection();
        this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(function (warning) {
            _this.assignRemarks(warning);
            _this.assignRemarksData();
            _this.updateView();
        });
        this.subscriptions.statusChanges = this.form.statusChanges.subscribe(function () {
            _this.manService.changeSectionValidity(EmployeeWarningSections.StandardCompanyRemarks, _this.form.valid);
        });
    };
    EmployeeSectionsWarningsCompanyRemarksComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsWarningsCompanyRemarksComponent.prototype.onChangeRemarks = function () {
        this.remarks.data.companyRemarks = this.remarksText;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsCompanyRemarksComponent.prototype.updateView = function () {
        var _this = this;
        Observable.of(true)
            .delay(200)
            .subscribe(function () { return _this.changeDetector.detectChanges(); });
    };
    EmployeeSectionsWarningsCompanyRemarksComponent.prototype.assignRemarks = function (warning) {
        var remarks = _.find(warning.sections, function (s) { return s.isStandardCompanyRemarks; });
        if (_.isObjectLike(remarks) && _.isObjectLike(remarks.data)) {
            this.remarks = remarks;
        }
    };
    EmployeeSectionsWarningsCompanyRemarksComponent.prototype.assignRemarksData = function () {
        this.remarksText = this.remarks.data.companyRemarks;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsWarningsCompanyRemarksComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsWarningsCompanyRemarksComponent;
}());
export { EmployeeSectionsWarningsCompanyRemarksComponent };
