<form class="dialog-form" #dialogForm="ngForm">
  <div class="modal-content">
    <div class="modal-body">
      <div class="col-xs-12">
        <div class="col-sm-6">
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
              placeholder="Pay Cycle Effective Date"
              name="effectiveDate"
              [required]="true"
              [(ngModel)]="conf.effectiveDate"
              [minDate]="conf.minEffectiveDate"
            ></slx-date-picker-ngx>
            <span errorMessage for="required"></span>
            <span errorMessage for="date"></span>
          </slx-input-decorator>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <hr class="separator">
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-3" *ngFor="let type of payCycleTypes; let idx = index;">
            <slx-input-decorator className="slx-form-group slx-no-border small-font">
              <slx-radio-input slx-input name="payCycleType{{idx}}" fieldName="payCycleType{{idx}}" valueField="name" [option]="type" [caption]="type.description"
                [placeholder]="idx==0?'Pay Cycle Period':''" [(ngModel)]="conf.payCycle">
                </slx-radio-input>
            </slx-input-decorator>
          </div>
        </div>
      </div>
      <div class="col-xs-12" *ngIf="showStartDayInputs">
        <div class="col-sm-6">
          <slx-input-decorator>
            <slx-kendo-number slx-input
              [(ngModel)]="conf.startDay1"
              [min]="1"
              [slxMin]="1"
              [max]="31"
              [slxMax]="31"
              [autoCorrect]="true"
              [required]="true"
              placeholder="Start Day 1 (ex.1)"
              name="startDay1"
            ></slx-kendo-number>
            <span errorMessage for="required"></span>
            <span errorMessage for="min">Min value is 1</span>
            <span errorMessage for="max">Max value is 31</span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6">
          <slx-input-decorator>
            <slx-kendo-number slx-input
              [(ngModel)]="conf.startDay2"
              [min]="1"
              [slxMin]="1"
              [max]="31"
              [slxMax]="31"
              [autoCorrect]="true"
              [required]="true"
              placeholder="Start Day 2 (ex.16)"
              name="startDay2"
            ></slx-kendo-number>
            <span errorMessage for="required"></span>
            <span errorMessage for="min">Min value is 1</span>
            <span errorMessage for="max">Max value is 31</span>
          </slx-input-decorator>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
      <button type="button" (click)="onOk()"  [disabled]="!dialogForm.valid ||(conf.startDay1 == conf.startDay2)"  class="theme-button-apply">OK</button>
    </div>
  </div>
</form>