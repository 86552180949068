import * as _ from 'lodash';
import { EventEmitter, OnInit } from '@angular/core';
import { EmployeePayRateComponent } from '../../models/employee-pay-rate-component';
var PayRateComponentComponent = /** @class */ (function () {
    function PayRateComponentComponent() {
        this.payRateTotal = 0;
        this.currencyFormat = 'n4';
        this.onPayRateChange = new EventEmitter();
    }
    Object.defineProperty(PayRateComponentComponent.prototype, "state", {
        set: function (value) {
            this._isEditMode = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayRateComponentComponent.prototype, "payRatesRecords", {
        get: function () {
            return this._payRateRecord;
        },
        set: function (value) {
            if (!value) {
                value = [];
            }
            for (var i = value.length; i < value.length; i++) {
                value.push(new EmployeePayRateComponent());
            }
            this._payRateRecord = value;
        },
        enumerable: true,
        configurable: true
    });
    PayRateComponentComponent.prototype.ngOnInit = function () {
        this.calculatePayRate();
    };
    PayRateComponentComponent.prototype.showPayRateComponent = function () { };
    PayRateComponentComponent.prototype.calculatePayRate = function () {
        this.payRateTotal = _.sumBy(this.payRatesRecords, function (value) { return value.rate || 0; });
        this.onPayRateChange.emit(this.payRateTotal);
    };
    return PayRateComponentComponent;
}());
export { PayRateComponentComponent };
