import * as moment from 'moment';

import { IDatedObject } from './idated-object';
import { DailyData } from './daily-data';

export class WeeklyData<T extends IDatedObject> {
  public startOfWeek: moment.Moment;
  public data: T;
  public days: NumberMap<DailyData<T>>;
  public weekNumber: number;
}