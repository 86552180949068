<slx-spinner [show]="isLoading" class="response">
    <div class="slx-high-box ">
        <div class="row slx-high-box__body slx-main-content-indents res">

            <div class="row heading">
                <p>
                    The status of each WFM API transaction is registered below. Entries with Error must be resolved,
                    and the Error Description will provide oversight of why the transaction failed.
                </p>
                <p>
                    Upon resolution, you must perform the Employee Synchronization action against each Employee
                    Record that had errored.
                </p>
            </div>
            <div class="row ">
                <div class="col-xs-2 date">
                    <slx-input-decorator>
                        <slx-date-picker-ngx slx-input [ngModel]="startDateValue" [maxDate]="today"
                            (ngModelChange)="onChangeStartDate($event)" placeholder="Start Date" format="yyyy/MM/dd"
                            name="startDate" [acceptNullDate]="true"
                            (blur)="onChangeStartDate($event)">
                        </slx-date-picker-ngx>
                    </slx-input-decorator>
                    <div *ngIf="dateError" class="slx-error-block">
                        <span *ngIf="dateError" errorMessage for="endDate">{{startDateMessage}}</span>
                    </div>
                </div>
                <div class="col-xs-2">
                    <slx-input-decorator>
                        <slx-date-picker-ngx slx-input [ngModel]="endDateValue" [maxDate]="today"
                            (ngModelChange)="onEndDateChange($event)" placeholder="End Date" format="yyyy/MM/dd"
                            name="endDate" [acceptNullDate]="true"
                            (blur)="onEndDateChange($event)">
                        </slx-date-picker-ngx>
                    </slx-input-decorator>
                    <div *ngIf="dateError" class="slx-error-block">
                        <span *ngIf="dateError" errorMessage for="endDate">{{endDateMessage}}</span>
                    </div>
                </div>
                <div class="col-xs-2">
                    <button class="theme-button-apply apply" (click)="apply()"  [disabled]="disable">Get Report</button>
                </div>
            </div>

            <div class="row wrapper">
                <slx-checkbox-toggle
                class="active-only-field tog"
                className="toggle-right"
                [(ngModel)]="isActiveOnly"
                (ngModelChange)="onDisplaySettingsChanged($event)"
                caption="Display All"
                checkedText="Yes"
                uncheckedText="No"
              ></slx-checkbox-toggle>
                <button class="theme-button-apply view" (click)="exportToExcel(grid)">Download to Excel</button>
            </div>
            <div class="row">
                <kendo-grid 
                #grid="kendoGrid" 
                [data]="gridState.view"
                [pageable]="true"
                filterable="menu" 
                [filter]="gridState.state.filter"
                [sortable]="{ mode: 'single' }" 
                [sort]="gridState.state.sort"
                [pageSize]="pageSize"
                [skip]="gridState.state.skip"
                (dataStateChange)="gridState.dataStateChange($event)"
                (stateRestored)="gridState.dataStateChange($event)" class="grid slx-blue-grid">
                    <kendo-grid-column field="transactionId" title="Transaction ID" width="160">
                    </kendo-grid-column>
                    <kendo-grid-column title="Transaction Date" field="transactionDate" width="150" [filterable]="false">
                        <ng-template kendoGridHeaderTemplate>
                            Transaction Date
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{ dataItem.transactionDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="modifiedDate" title="Modified Date" [filterable]="false" width="160">
                        <ng-template kendoGridHeaderTemplate>
                            Modified Date
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{ dataItem.modifiedDate | amDateFormat: appConfig.dateTimeFormatUS }}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="transactionName" title="Action Type" width="140"></kendo-grid-column>
                    <kendo-grid-column field="transactionStatus" title="Status" width="160">
                    </kendo-grid-column>
                    <kendo-grid-column field="employeeId" title="Payroll No." width="160"></kendo-grid-column >
                    <kendo-grid-column field="employeeName" title="Employee Name" width="160"></kendo-grid-column>
                    <!-- <kendo-grid-column field="errorCode" title="Error Code" [filterable]="false" width="160"></kendo-grid-column> -->
                    <kendo-grid-column field="errorMessage" title="Error Description" [filterable]="false" width="180">
                    </kendo-grid-column>
                    <ng-container *ngIf="dataView.length > 0&& dataView[0].isEventSubscriptionEnabled==false && dataView[0].clinetType=='direct'">
                    <kendo-grid-column width="250" title="Actions" >
                        <ng-template kendoGridCellTemplate let-dataItem>
                           
                            <button class="theme-button-apply col-btn" *ngIf="dataItem.transactionStatus === 'FAILED'"  (click)="handleButtonClick(dataItem)">
                              Re-submit
                            </button>
                            <button class="theme-button-apply col-btn1" *ngIf="dataItem.transactionStatus === 'FAILED'" (click)="click(dataItem)">
                                Accept
                            </button>
                          </ng-template>
                    </kendo-grid-column>
                </ng-container>
                    <ng-template kendoGridNoRecordsTemplate>
                        <p>There is no data to display</p>
                    </ng-template>
                    <kendo-grid-excel fileName="DailyTransactionReport.xlsx" [fetchData]="allData"></kendo-grid-excel>
                </kendo-grid>
            </div>
        </div>
    </div>
</slx-spinner>