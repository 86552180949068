/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./parameters-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../report-parameter/report-parameter.component.ngfactory";
import * as i3 from "../report-parameter/report-parameter.component";
import * as i4 from "../../../services/reports-management.service";
import * as i5 from "../../../../app-settings/services/app-settings-manage.service";
import * as i6 from "@angular/common";
import * as i7 from "./parameters-list.component";
import * as i8 from "../../../services/reports-api.service";
var styles_ParametersListComponent = [i0.styles];
var RenderType_ParametersListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParametersListComponent, data: {} });
export { RenderType_ParametersListComponent as RenderType_ParametersListComponent };
function View_ParametersListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-report-parameter", [], null, null, null, i2.View_ReportParameterComponent_0, i2.RenderType_ReportParameterComponent)), i1.ɵdid(1, 114688, null, 0, i3.ReportParameterComponent, [i4.ReportsManagementService, i5.AppSettingsManageService], { index: [0, "index"], parameter: [1, "parameter"], parameters: [2, "parameters"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.index; var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.parameters; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ParametersListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "report-parameter"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParametersListComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.isVisible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ParametersListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "report-parameters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ParametersListComponent_1)), i1.ɵdid(2, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedParams; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ParametersListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-report-parameters-list", [], null, null, null, View_ParametersListComponent_0, RenderType_ParametersListComponent)), i1.ɵdid(1, 114688, null, 0, i7.ParametersListComponent, [i8.ReportsApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParametersListComponentNgFactory = i1.ɵccf("slx-report-parameters-list", i7.ParametersListComponent, View_ParametersListComponent_Host_0, { parameters: "parameters", reportDefinition: "reportDefinition" }, {}, []);
export { ParametersListComponentNgFactory as ParametersListComponentNgFactory };
