import { Injectable } from '@angular/core';
import { IAppState } from '../../../store';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class MasterScheduleFilterActions {

  public static SET_FILTERS: string = 'SET_FILTERS';

  private ngRedux: NgRedux<IAppState>;

  constructor(ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }


  public setFilters(filters: StringMap<any>): void {
    this.ngRedux.dispatch({
      type: MasterScheduleFilterActions.SET_FILTERS,
      payload: filters
    });
  }
}
