import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { ThrottlingService } from '../throttling/throttling.service';
import { unsubscribeInService } from '../../decorators/index';
import { TabMode } from '../../models/index';
import { appConfig } from '../../../app.config';
import { SessionService } from '../session/session.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
var TokenValidityService = /** @class */ (function () {
    function TokenValidityService(throttle, session) {
        var _this = this;
        this.throttle = throttle;
        this.session = session;
        this.onTokenExpired = new Subject();
        this.onTokenRenewed = new Subject();
        this.throttleSubscrtiption = this.throttle.subscribeToModeChange(function (e) { return _this.subscribeToModeChange(e); });
        this.renewSubscription = this.session.onTokenRenewed.subscribe(function () { return _this.subscribeToTokenRenewed(); });
        this.exprireSubscription = this.session.onTokenRemoved.subscribe(function () { return _this.subscribeToTokenRemoved(); });
    }
    TokenValidityService.prototype.subscribeToModeChange = function (e) {
        // if we coming back to front from background tab intervals possibly where paused and we should check if token exprired immediatelly
        if (e.mode === TabMode.FRONT) {
            if (this.session.isExpired()) {
                this.clearInterval();
                this.onTokenExpired.next();
            }
        }
    };
    TokenValidityService.prototype.subscribeToTokenRenewed = function () {
        var _this = this;
        this.clearInterval();
        var tokenExpDate = this.session.getTokenExpirationDate();
        var diff = moment(tokenExpDate).subtract(appConfig.session.startRenewUntilSessionExpiredSeconds / 2, 'seconds').diff(moment());
        if (diff > 0) {
            this.intervalSubscription = Observable.interval(diff)
                .subscribe(function () {
                _this.clearInterval();
                _this.onTokenExpired.next();
            });
            this.onTokenRenewed.next();
        }
    };
    TokenValidityService.prototype.subscribeToTokenRemoved = function () {
        this.clearInterval();
        this.onTokenExpired.next();
    };
    TokenValidityService.prototype.clearInterval = function () {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
            this.intervalSubscription = null;
        }
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TokenValidityService.prototype, "throttleSubscrtiption", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TokenValidityService.prototype, "renewSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TokenValidityService.prototype, "exprireSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TokenValidityService.prototype, "intervalSubscription", void 0);
    return TokenValidityService;
}());
export { TokenValidityService };
