import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, QueryList } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { State, SortDescriptor } from '@progress/kendo-data-query';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { ScheduleApiService } from '../../services/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { ActivatedRoute, Router } from '@angular/router';
import { process } from '@progress/kendo-data-query';
var SchedulesNotPostedComponent = /** @class */ (function () {
    function SchedulesNotPostedComponent(scheduleApiService, router, route) {
        var _this = this;
        this.pageSize = 30;
        this.orgTypeDepartment = false;
        Assert.isNotNull(scheduleApiService, 'scheduleApiService');
        this.scheduleApiService = scheduleApiService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'scheduleCycleStartDate', dir: 'asc' }];
        this.gridEventSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridState.state.skip = 0;
        this.skip = this.gridState.state.skip;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false
        };
        var today = new Date();
        this.startDate = new Date(new Date().setDate(today.getDate() - 30));
        this.endDate = new Date(new Date().setDate(today.getDate() + 30));
        this.router = router;
        this.route = route;
    }
    SchedulesNotPostedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        if (this.orgLevel$ != undefined && this.orgLevel$ != null)
            this.orglevelSubscription = this.orgLevel$
                .subscribe(function (orgLevel) {
                _this.currentOrgLevel = orgLevel;
                _this.doLoad();
                _this.orgTypeDepartment = orgLevel.type === 'Department' ? true : false;
            });
    };
    SchedulesNotPostedComponent.prototype.doLoad = function () {
        var _this = this;
        this.state.isLoading = true;
        this.scheduleApiService.getSchedulesNotPosted(this.currentOrgLevel.id, this.startDate, this.endDate)
            .then(function (scheduleNotPosted) {
            _this.scheduleNotPosted = scheduleNotPosted;
            _this.state.isLoading = false;
            _this.refreshGrid();
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    SchedulesNotPostedComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.doLoad();
    };
    SchedulesNotPostedComponent.prototype.ngOnDestroy = function () {
        if (this.gridEventSubscription) {
            this.gridEventSubscription.unsubscribe();
        }
    };
    SchedulesNotPostedComponent.prototype.refreshGrid = function () {
        if (!this.scheduleNotPosted) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.scheduleNotPosted, this.gridState.state);
    };
    SchedulesNotPostedComponent.prototype.navigateToOpenShiftManagement = function (date) {
        var dateParam = moment(date).format(appConfig.linkDateFormat);
        this.router.navigate(['open_shift_management'], { relativeTo: this.route.pathFromRoot[2], queryParams: { date: dateParam } });
    };
    SchedulesNotPostedComponent.prototype.filterChangeHandler = function (event) {
        this.gridState.state.filter = event;
        this.refreshGrid();
    };
    Object.defineProperty(SchedulesNotPostedComponent.prototype, "dateFormat", {
        get: function () {
            return '{0:MM/dd/yyyy}';
        },
        enumerable: true,
        configurable: true
    });
    SchedulesNotPostedComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    SchedulesNotPostedComponent.prototype.pageChangeHandler = function (event) {
        this.skip = event.skip;
        this.pageSize = event.take;
        this.refreshGrid();
    };
    SchedulesNotPostedComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.scheduleNotPosted, { sort: _this.gridState.state.sort }).data
        }); };
    };
    SchedulesNotPostedComponent.prototype.onClickExportExcel = function (position) {
        if (position.toLowerCase() === 'excel') {
            this.kendoGrid.forEach(function (e) {
                e.saveAsExcel();
            });
        }
    };
    SchedulesNotPostedComponent.prototype.getDateRange = function () {
        return 'Date Range: ' + moment(this.startDate).format('L') + ' - ' + moment(this.endDate).format('L');
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], SchedulesNotPostedComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SchedulesNotPostedComponent.prototype, "orglevelSubscription", void 0);
    return SchedulesNotPostedComponent;
}());
export { SchedulesNotPostedComponent };
