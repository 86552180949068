import {
  Component,
  Input,
  Output,
  EventEmitter, OnInit
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { OpenShiftShortDetailsWrapper } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-open-shift-management-schedule-cycle-details',
  templateUrl: 'open-shift-management-schedule-cycle-details.component.html',
  styleUrls: ['open-shift-management-schedule-cycle-details.component.scss']
})
export class OpenShiftManagementScheduleCycleDetailsComponent implements OnInit {
  @Input()
  public detailsWrapper: OpenShiftShortDetailsWrapper;
  public appConfig: IApplicationConfig;

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public get openShiftCount(): number {
    return this.detailsWrapper && this.detailsWrapper.details ? this.detailsWrapper.details.openShiftCount : 0;
  }

  public get messageCount(): number {
    return this.detailsWrapper && this.detailsWrapper.details ? this.detailsWrapper.details.messageCount : 0;
  }
  public get partnerShiftCount(): number {
    return this.detailsWrapper && this.detailsWrapper.details ? this.detailsWrapper.details.partnerShiftCount :0 ;
  }
}