<div class="modal-body">
    OSHA Form 300/300A CSV file will be created for the year indicated in your selected Start Date. Click OK to create
    the file.
</div>

<div class="modal-footer">
    <p class="flex justify-content__flex-end modal-buttons">
        <button type="button" (click)="create()" class="theme-button-apply  margin-r">Ok</button>
        <button type="button" class="theme-button-cancel" (click)="cancel()">Cancel</button>
    </p>
</div>