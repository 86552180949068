import { IEmployeeSectionsPosition } from './../../../employee/employee-sections/models/employee-sections-employment/employee-sections-positions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { LookupMapService } from '../../../organization/services/index';

import { IEmployeeShortInfo, AgencyDefinition } from '../../../organization/models/index';
import { IAgencyEmployee, AgencyEmployee, IAddAgencyEmployeeReq, AddAgencyEmployeeReq, IValidateAgencyEmployeeReq, ValidateAgencyEmployeeReq } from '../../models/index';

@Injectable()
export class AgencyEmployeeMapService {

  constructor(private lookupMapService: LookupMapService) {
  }

  public mapAgencyEmployeeFromShortInfo(agency: AgencyDefinition, dto: IEmployeeShortInfo): AgencyEmployee {
    let agencyEmployee: AgencyEmployee = new AgencyEmployee();
    agencyEmployee.agency = agency;
    agencyEmployee.employeeId = dto.id;
    agencyEmployee.employeeFirstName = dto.firstName;
    agencyEmployee.employeeLastName = dto.lastName;
    agencyEmployee.workDate = null;
    agencyEmployee.startDate = null;
    agencyEmployee.badgeId = null;
    agencyEmployee.prefix = null;
    agencyEmployee.email = null;
    agencyEmployee.phone = null;
    return agencyEmployee;
  }

  public mapAgencyEmployeeFromShortInfos(agency: AgencyDefinition, dto: IEmployeeShortInfo[]): AgencyEmployee[] {
    return _.map(dto, (dtoRec: IEmployeeShortInfo) => this.mapAgencyEmployeeFromShortInfo(agency, dtoRec));
  }

  public mapAgencyEmployee(dto: IAgencyEmployee): AgencyEmployee {
    let agencyEmployee: AgencyEmployee = new AgencyEmployee();
    agencyEmployee.agency = this.lookupMapService.mapAgencyDefinition(dto.agency);
    agencyEmployee.employeeId = dto.employeeId;
    agencyEmployee.employeeFirstName = dto.employeeFirstName;
    agencyEmployee.employeeLastName = dto.employeeLastName;
    agencyEmployee.workDate = dateTimeUtils.convertFromDtoString(dto.workDate);
    agencyEmployee.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    agencyEmployee.badgeId = dto.badgeId;
    agencyEmployee.prefix = dto.prefix;
    agencyEmployee.email = dto.email;
    agencyEmployee.phone = dto.phone;
    agencyEmployee.message = dto.message;
    let employeeFullName: any = "";
    if (dto.employeeName == null || dto.employeeName === "") {
      employeeFullName = dto.employeeLastName + ", " + dto.employeeFirstName;
    } else {
      employeeFullName = dto.employeeName;
    }
    
    agencyEmployee.employeeName = employeeFullName;
    return agencyEmployee;
  }

  public mapAgencyEmployees(dto: IAgencyEmployee[]): AgencyEmployee[] {
    return _.map(dto, (dtoRec: IAgencyEmployee) => this.mapAgencyEmployee(dtoRec));
  }

  public mapAgencyEmployeeDTO(model: AgencyEmployee): IAgencyEmployee {
    return {
      agency: this.lookupMapService.mapAgencyDefinitionDto(model.agency),
      employeeId: model.employeeId,
      employeeFirstName: model.employeeFirstName,
      employeeLastName: model.employeeLastName,
      workDate: dateTimeUtils.convertToDtoString(model.workDate),
      startDate: dateTimeUtils.convertToDtoString(model.startDate),
      badgeId: model.badgeId,
      prefix: model.prefix,
      email: model.email,
      phone: model.phone,
      employeeName: model.employeeName

    };
  }

  public mapAgencyEmployeeReqDto(req: AddAgencyEmployeeReq): IAddAgencyEmployeeReq {
    return {
      agencyEmployee: this.mapAgencyEmployeeDTO(req.agencyEmployee),
      organization: this.lookupMapService.mapOrganizationDto(req.organization),
      department: this.lookupMapService.mapDepartmentDto(req.department),
      position: this.lookupMapService.mapPositionDto(req.position),
      shift: req.shift ? this.lookupMapService.mapShiftDto(req.shift) : null,
      unit: req.unit ? this.lookupMapService.mapLocationUnitDto(req.unit) : null,
      createAgency: req.createAgency,
      createEmployee: req.createEmployee
    };
  }

  public mapValidateAgencyEmployeeReqDto(req: ValidateAgencyEmployeeReq): IValidateAgencyEmployeeReq {
    return {
      employeeId: req.employeeId ? req.employeeId : 0,
      shiftId: req.shiftId,
      orgLevelId: req.orgLevelId,
      date: dateTimeUtils.convertToDtoString(req.date),
    };
  }

}
