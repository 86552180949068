import * as _ from 'lodash';

import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-switcher',
  templateUrl: 'switcher.component.html',
  providers: [createValuAccessor(SwitcherComponent)]
})
export class SwitcherComponent implements ControlValueAccessor {
  @Input()
  public disabled: boolean;
  @Input()
  public label: string;
  @Input()
  public labelOn: string;
  @Input()
  public labelOff: string;
  @Input()
  public className: string;

  public checked: boolean;

  public get hasLabel(): boolean {
    return _.isString(this.label) && _.size(this.label) > 0;
  }

  private onChangeCallback: (val: any) => void = _.noop;
  private onTouchedCallback: () => void = _.noop;

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public writeValue(value?: any): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.checked = value;
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onValueChanged(value: boolean): void {
    this.onChangeCallback(value);
  }
}
