import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { NgModel, NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { KendoGridCustomSelectionHelper } from '../../../../common/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { RoleDefinition } from '../../../../organization/models/index';
import { RolesDefinitionManagementService } from '../../../services/index';
var RolesDefinitionGridComponent = /** @class */ (function () {
    function RolesDefinitionGridComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
    }
    RolesDefinitionGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (records) {
            _this.records = records;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.addCmdSubscription = this.managementService.addCmd$.subscribe(function (value) {
            _this.addHandler();
        });
        this.cloneCmdSubscription = this.managementService.cloneCmd$.subscribe(function (value) {
            _this.cloneHandler(value);
        });
        this.roleSavedSubscription = this.managementService.onRoleSaved$.subscribe(function (result) {
            if (result.index !== -1 && result.savedRole) {
                _this.records[result.index] = result.savedRole;
                _this.refreshGrid();
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            }
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.selectionSubscription = this.gridState.onSelectionChanged.subscribe(function (roles) {
            if (roles.length > 0) {
                _this.managementService.onSelectRecord(roles[0]);
            }
            else {
                _this.managementService.onSelectRecord(null);
            }
        });
        this.selectionHelper.clearSelection();
    };
    RolesDefinitionGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.selectionHelper.clearSelection();
        this.managementService.onSelectRecord(null);
    };
    RolesDefinitionGridComponent.prototype.addHandler = function () {
        this.closeEditor();
        this.refreshGrid();
        this.isNewMode = true;
        var newRole = new RoleDefinition();
        newRole.name = 'New Role';
        this.grid.addRow(newRole);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    RolesDefinitionGridComponent.prototype.cloneHandler = function (value) {
        this.closeEditor();
        this.refreshGrid();
        this.roleToClone = value;
        var newRole = new RoleDefinition();
        newRole.name = 'New Role';
        this.grid.addRow(newRole);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    RolesDefinitionGridComponent.prototype.editHandler = function (event) {
        this.closeEditor();
        this.editedRowIndex = event.rowIndex;
        this.editedRecord = event.dataItem;
        this.savedEditedRole = Object.assign({}, event.dataItem);
        this.grid.editRow(this.editedRowIndex);
        this.managementService.onEditItem(this.editedRecord);
    };
    RolesDefinitionGridComponent.prototype.cancelHandler = function () {
        if (this.editedRecord) {
            _.merge(this.editedRecord, this.savedEditedRole);
        }
        this.closeEditor();
        this.managementService.onCancelEdit(this.editedRecord);
    };
    RolesDefinitionGridComponent.prototype.removeHandler = function (event) {
        if (!event.dataItem) {
            return;
        }
        this.managementService.onRemoveRecord(event.dataItem);
        this.refreshGrid();
    };
    RolesDefinitionGridComponent.prototype.closeEditor = function () {
        this.grid.closeRow(this.editedRowIndex);
        this.isNewMode = false;
        this.roleToClone = undefined;
        this.editedRowIndex = undefined;
        this.editedRecord = undefined;
        this.savedEditedRole = undefined;
    };
    RolesDefinitionGridComponent.prototype.saveHandler = function (event) {
        if (this.roleToClone) {
            this.managementService.onCloneRecord(this.roleToClone, event.dataItem);
        }
        else if (this.isNewMode) {
            this.managementService.onAddRecord(event.dataItem);
        }
        else {
            this.managementService.onSaveRecord(event.dataItem);
        }
        this.closeEditor();
        this.refreshGrid();
    };
    RolesDefinitionGridComponent.prototype.onKeyName = function (event, dataItem, nameField) {
        if (!nameField) {
            return;
        }
        var name = nameField.value;
        var existRole = _.find(this.records, function (record) { return record.name === name && record.id !== dataItem.id; });
        if (existRole) {
            nameField.control.setErrors({ unique: true });
        }
    };
    RolesDefinitionGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        var filtered = _.filter(this.records, function (x) { return x.name != "System Administrator"; });
        this.gridState.view = process(filtered, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesDefinitionGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesDefinitionGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesDefinitionGridComponent.prototype, "addCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesDefinitionGridComponent.prototype, "cloneCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesDefinitionGridComponent.prototype, "roleSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesDefinitionGridComponent.prototype, "selectionSubscription", void 0);
    return RolesDefinitionGridComponent;
}());
export { RolesDefinitionGridComponent };
