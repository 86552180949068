export * from './arrivals-grid/arrivals-grid.component';
export * from './arrivals-widget/arrivals-widget.component';
export * from './departures-grid/departures-grid.component';
export * from './departures-widget/departures-widget.component';
export * from './arrivals-departures-details/arrivals-departures-details.component';

import { ArrivalsGridComponent } from './arrivals-grid/arrivals-grid.component';
import { ArrivalsWidgetComponent } from './arrivals-widget/arrivals-widget.component';
import { DeparturesGridComponent } from './departures-grid/departures-grid.component';
import { DeparturesWidgetComponent } from './departures-widget/departures-widget.component';
import { ArrivalsDeparturesDetailsComponent } from './arrivals-departures-details/arrivals-departures-details.component';

export const arrivalsDepaturesComponents: any = [
  ArrivalsGridComponent,
  ArrivalsWidgetComponent,
  DeparturesGridComponent,
  DeparturesWidgetComponent,
  ArrivalsDeparturesDetailsComponent
];
