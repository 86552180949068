import { EventEmitter, OnInit } from '@angular/core';
import { appConfig } from '../../../../app.config';
import { GridDataResult } from '@progress/kendo-angular-grid';
var EmployeeSearchResultsComponent = /** @class */ (function () {
    function EmployeeSearchResultsComponent() {
        this.backToQuery = new EventEmitter();
    }
    Object.defineProperty(EmployeeSearchResultsComponent.prototype, "searchResults", {
        set: function (value) {
            this.data = value;
            this.loadPage();
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSearchResultsComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.loadPage();
    };
    EmployeeSearchResultsComponent.prototype.onBackClick = function () {
        this.backToQuery.emit();
    };
    EmployeeSearchResultsComponent.prototype.loadPage = function () {
        if (!this.data) {
            this.gridView = null;
            return;
        }
        this.gridView = {
            data: this.data,
            total: this.data.length
        };
    };
    return EmployeeSearchResultsComponent;
}());
export { EmployeeSearchResultsComponent };
