import * as _ from 'lodash';

import { Injectable, Renderer2, RendererFactory2, OnDestroy  } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { appConfig, IApplicationConfig } from '../../../app.config';

import { Assert } from '../../../framework/index';

import { SupportDevices, DevicesDefinition } from '../../models/index';
import { WindowRef } from '../../../core/services/window/window-ref.model';

@Injectable()
export class DeviceDetectorService implements OnDestroy {

  public get isMobile(): boolean {
    return this.mobile;
  }

  public get isTablet(): boolean {
    return this.tablet;
  }

  public get isDesktop(): boolean {
    return this.desktop;
  }

  public get isLandscape(): boolean {
    return this.landscape;
  }

  public get isPortrait(): boolean {
    return this.portrait;
  }

  public get deviceType(): DevicesDefinition {
   return this.device;
  }

  public get deviceList(): DevicesDefinition[] {
   return [SupportDevices.mobile, SupportDevices.tablet, SupportDevices.desktop];
  }

  private detection$ = new Subject<void>();
  private renderer: Renderer2;
  private mobile: boolean;
  private tablet: boolean;
  private desktop: boolean;
  private device: DevicesDefinition;
  private landscape: boolean;
  private portrait: boolean;
  private orientationType: OrientationType;
  private appConfig: IApplicationConfig = appConfig;
  private timerId: any = null;
  private unsubscribe: () => void  = () => {};

  constructor(private win: WindowRef, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.runDetection();
    this.subscribeToEvents();
  }

  public ngOnDestroy(): void {
    this.detection$.complete();
  }

  public runDetection(): void {
    this.detectOrientation();
    this.detectDevice();
    this.assignDeviceType();
    this.detection$.next();
  }

  public subscribeToDeviceDetection(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.detection$.subscribe(callback);
  }

  private subscribeToEvents(): void {
    this.renderer.listen('window', 'orientationchange', () => this.runDetection());
    this.renderer.listen('window', 'resize', () => this.waitForEndResizing());
  }

  private detectOrientation(): void {
    this.orientationType = this.getOrientationType();
    this.landscape = this.orientationType === 'landscape-primary' || this.orientationType === 'landscape-secondary';
    this.portrait = this.orientationType === 'portrait-primary' || this.orientationType === 'portrait-secondary';
    if (!this.portrait && !this.landscape) {
      this.landscape = true;
      console.info(`Device orientation didn\'t recognized. orientation: ${this.orientationType}`);
    }
  }

  private getOrientationType(): OrientationType {
    const screen = this.win.nativeWindow.screen as any;
    const screenOrientation: ScreenOrientation = (screen.orientation || screen.msOrientation || screen.mozOrientation);

    return screenOrientation && screenOrientation.type || null;
  }

  private detectDevice(): void {
    const win = this.win.nativeWindow;
    const { mobileWidth, tabletWidth, desktopWidth } = this.appConfig.devices;
    const lengthOfSide = win.screen.width;
    this.mobile = lengthOfSide <= mobileWidth.max;
    this.tablet = lengthOfSide >= tabletWidth.min && lengthOfSide <= tabletWidth.max;
    this.desktop = lengthOfSide >= desktopWidth.min;

    if (!this.mobile && !this.tablet && !this.desktop) {
      this.desktop = true;
      console.info(`Device Detector: device type doesn\'t recognized. Screen: ${win.screen.width} x ${win.screen.height}`);
    }
  }

  private assignDeviceType(): void {
    switch(true) {
      case this.mobile:
        this.device = SupportDevices.mobile;
        break;
      case this.tablet:
          this.device = SupportDevices.tablet;
        break;
      case this.desktop:
          this.device = SupportDevices.desktop;
        break;
    }
  }

  private waitForEndResizing(): void {
    if (!_.isNull(this.timerId)) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }

    this.timerId = setTimeout(() => this.runDetection(), 200);
  }
}
