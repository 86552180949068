import * as _ from 'lodash';
var IndividualTimecardFlatRecord = /** @class */ (function () {
    function IndividualTimecardFlatRecord() {
    }
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "warningMessage", {
        get: function () {
            if (this.day) {
                return this.day.warningMessage;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "costCenterCode", {
        get: function () {
            if (!this.earning)
                return null;
            return this.earning.costCenterCode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "organizationName", {
        get: function () {
            if (this.earning && this.earning.organization) {
                return this.earning.organization.name;
            }
            return this.empOrganization;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "positionName", {
        get: function () {
            if (this.earning) {
                return this.earning.position.name;
            }
            if (this.day) {
                return this.day.positionName;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "shiftCode", {
        get: function () {
            if (this.earning) {
                return this.earning.shiftCode;
            }
            if (this.day) {
                return this.day.shiftCode;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "payRate", {
        get: function () {
            if (this.earning) {
                return this.earning.payRate;
            }
            if (this.day) {
                return this.day.standardPayRate;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "shiftDiffPolicyName", {
        get: function () {
            if (this.shiftDiffPolicy) {
                return this.shiftDiffPolicy.name;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardFlatRecord.prototype, "payPolicyName", {
        get: function () {
            if (this.payPolicy) {
                return this.payPolicy.name;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    IndividualTimecardFlatRecord.prototype.getRuleValue = function (name) {
        var rules = this.rulesMap[name];
        if (rules) {
            return _.sumBy(rules, function (r) { return r.value ? r.value : 0; });
        }
        return null;
    };
    IndividualTimecardFlatRecord.prototype.isMoneyRule = function (name) {
        var rules = this.rulesMap[name];
        return rules && _.first(rules).isMoneyAmountRule;
    };
    return IndividualTimecardFlatRecord;
}());
export { IndividualTimecardFlatRecord };
