import { ReplaySubject } from 'rxjs/ReplaySubject';
import * as _ from 'lodash';
import { UserApplication } from '../../../state-model/models/index';
import { StyledUserApplication, NavigationMenuType, ApplicationDashboardItem } from '../../../organization/models/index';
import { SessionService } from '../../../core/services/index';
import { Assert, StringUtils } from '../../../framework/index';
import { organizationConfig } from '../../../organization/organization.config';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { appConfig } from '../../../../app/app.config';
var ApplicationService = /** @class */ (function () {
    function ApplicationService(appStateBusService, sessionService) {
        var _this = this;
        this.appStateBusService = appStateBusService;
        this.sessionService = sessionService;
        this.applicationsUpdated$ = new ReplaySubject(1);
        appStateBusService.menuLoaded$.subscribe(function (menu) {
            var applications = menu.filter(function (item) { return item.type === NavigationMenuType.application; }).map(function (item) { return _this.mapToStyledApplications(item); });
            var benAdminItem = _.find(menu, function (m) { return m.link === appConfig.extraApplicationsInfo.benAdmin.name; });
            if (benAdminItem) {
                benAdminItem.applicationId = benAdminItem.id;
                applications.push(_this.mapToStyledApplications(benAdminItem));
            }
            var hasV5AppMenuItem = menu.some(function (menuItem) {
                return menuItem.name === appConfig.moreV5ItemsApplication.db_menu_name;
            });
            if (hasV5AppMenuItem) {
                var moreV5App = _this.createMoreV5Application();
                applications.push(moreV5App);
            }
            var fakeApp = _this.createFakeCommonStyledApplication();
            if (!applications.some(function (app) { return app.id === fakeApp.id; })) {
                applications.push(fakeApp);
            }
            _this.styledApplications = applications;
            _this.applicationsUpdated$.next(_this.styledApplications);
        });
    }
    ApplicationService.prototype.applicationSelected = function (app) {
        this.appStateBusService.selectApp(app);
    };
    ApplicationService.prototype.deselectApplication = function () {
        this.appStateBusService.deselectApp();
    };
    ApplicationService.prototype.getStyledApplications = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.applicationsUpdated$.first().subscribe(function (applications) {
                resolve(applications);
            });
        });
    };
    ApplicationService.prototype.getApplicationByName = function (name) {
        Assert.isNotNull(name, 'name');
        return this.getApplicationsFromSession().then(function (applications) {
            var application = _.find(applications, function (app) { return StringUtils.equalsIgnoreCase(app.name, name); });
            return application;
        });
    };
    ApplicationService.prototype.getApplicationsFromSession = function () {
        var applications = [];
        if (!this.sessionService.isExpired()) {
            var user = this.sessionService.getUser();
            applications = user.applications;
            var fakeApp_1 = this.createFakeCommonApplication();
            if (!applications.some(function (app) { return app.id === fakeApp_1.id; })) {
                applications.push(fakeApp_1);
            }
        }
        return Promise.resolve(applications);
    };
    ApplicationService.prototype.mapToStyledApplications = function (item) {
        var _a;
        var styledApplication = new StyledUserApplication();
        var styleMap = _.find(organizationConfig.applications, function (styleMapApp) { return styleMapApp.id === item.applicationId; });
        if (!styleMap) {
            styleMap = organizationConfig.unknownApplication;
        }
        styledApplication.icon = styleMap.icon;
        styledApplication.id = item.applicationId;
        styledApplication.link = item.link;
        styledApplication.name = item.name;
        styledApplication.title = item.displayName;
        styledApplication.loadCounters = styleMap.loadCounters;
        styledApplication.isAvailableOnMobile = item.isAvailableOnMobile;
        styledApplication.dashboards = [];
        if (item.childs) {
            var dashboardsLinks_1 = [
                appConfig.extraApplicationsInfo.hrms.dashboards.leaveManagement.link,
            ];
            var childs = _.reduce(item.childs, function (accum, m) {
                if (_.includes(dashboardsLinks_1, m.link)) {
                    var item_1 = new ApplicationDashboardItem();
                    item_1.id = m.id;
                    item_1.link = m.link;
                    item_1.title = m.displayName;
                    item_1.icon = m.menuItemMapping.icon;
                    accum.push(item_1);
                }
                return accum;
            }, []);
            (_a = styledApplication.dashboards).push.apply(_a, childs);
        }
        return styledApplication;
    };
    ApplicationService.prototype.createFakeCommonApplication = function () {
        var app = new UserApplication();
        app.id = appConfig.globalMenuItemsApplication.id;
        app.name = appConfig.globalMenuItemsApplication.name;
        app.title = appConfig.globalMenuItemsApplication.title;
        app.link = appConfig.globalMenuItemsApplication.link;
        return app;
    };
    ApplicationService.prototype.createMoreV5Application = function () {
        var styledApp = new StyledUserApplication();
        styledApp.id = appConfig.moreV5ItemsApplication.id;
        styledApp.name = appConfig.moreV5ItemsApplication.name;
        styledApp.title = appConfig.moreV5ItemsApplication.title;
        styledApp.link = appConfig.moreV5ItemsApplication.link;
        styledApp.isAvailableOnMobile = false;
        styledApp.loadCounters = organizationConfig.moreApplication.loadCounters;
        styledApp.icon = organizationConfig.moreApplication.icon;
        return styledApp;
    };
    ApplicationService.prototype.createFakeCommonStyledApplication = function () {
        var app = this.createFakeCommonApplication();
        var styledApp = new StyledUserApplication();
        styledApp.id = app.id;
        styledApp.link = app.link;
        styledApp.name = app.name;
        styledApp.title = app.title;
        styledApp.isAvailableOnMobile = false;
        styledApp.loadCounters = organizationConfig.unknownApplication.loadCounters;
        styledApp.icon = organizationConfig.unknownApplication.icon;
        return styledApp;
    };
    return ApplicationService;
}());
export { ApplicationService };
