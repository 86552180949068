export interface IPBJExportContainer {
  exportId: number;
  fileName: string;
  data: Blob;
}

export class PBJExportContainer {
  public exportId: number;
  public fileName: string;
  public data: Blob;
}
