import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs';
import { orderBy } from '@progress/kendo-data-query';
import { LicenseTypesApiService } from './license-types-api.service';
import { mutableSelect, unsubscribeInService } from '../../../../app/core/decorators';
import * as _ from 'lodash';
var LicenseTypesManagementService = /** @class */ (function () {
    function LicenseTypesManagementService(apiService) {
        this.apiService = apiService;
        this.sort = [];
    }
    LicenseTypesManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            _this.loadData();
        });
    };
    LicenseTypesManagementService.prototype.loadData = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.getList(this.orgLevelId).then(function (value) {
            _this.list = value;
            _this.refreshGrid();
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    LicenseTypesManagementService.prototype.setSort = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    LicenseTypesManagementService.prototype.save = function (type) {
        var _this = this;
        this.isLoading = true;
        return this.apiService.save(this.orgLevelId, type).then(function (value) {
            var editedEntry = _.find(_this.list, { 'licenseTypeID': type.licenseTypeID });
            _.assign(editedEntry, type);
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    LicenseTypesManagementService.prototype.add = function (type) {
        var _this = this;
        this.isLoading = true;
        return this.apiService.add(this.orgLevelId, type).then(function (value) {
            _this.list.push(value);
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    LicenseTypesManagementService.prototype.delete = function (typeId) {
        var _this = this;
        this.isLoading = true;
        return this.apiService.delete(this.orgLevelId, typeId).then(function () {
            var index = _.findIndex(_this.list, { 'licenseTypeID': typeId });
            _this.list.splice(index, 1);
            _this.refreshGrid();
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    LicenseTypesManagementService.prototype.refreshGrid = function () {
        if (!this.list) {
            this.gridData = null;
            return;
        }
        this.gridData = {
            data: orderBy(this.list, this.sort),
            total: this.list.length
        };
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], LicenseTypesManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], LicenseTypesManagementService.prototype, "orgLevelSubscription", void 0);
    return LicenseTypesManagementService;
}());
export { LicenseTypesManagementService };
