export class Assert {

  public static isNotNull(value: Object, name?: string, message?: string): void {
    /* tslint:disable no-null-keyword */
    if (value === null || value === void 0) {
      let errorMessage: string = message || (name
          ? '"{0}" cannot be null.'
          : 'Specified value cannot be null.');

      throw new Error(errorMessage.format(name));
    }
  }

  public static isTrue(value: boolean, name?: string, message?: string): void {
    if (!value) {
      let errorMessage: string = message || (name
          ? '"{0}" is not a "true".'
          : 'Specified value is not a "true".');

      throw new Error(errorMessage.format(name));
    }
  }

  public static isFalse(value: boolean, name?: string, message?: string): void {
    if (value) {
      let errorMessage: string = message || (name
          ? '"{0}" is not a "false".'
          : 'Specified value is not a "false".');

      throw new Error(errorMessage.format(name));
    }
  }

  public static isMatch(value: string, pattern: RegExp, name?: string, message?: string): void {
    if (!pattern.test(value)) {
      let errorMessage: string = message || (name
          ? '"{0}" is not match pattern.'
          : 'Specified value is not match pattern.');

      throw new Error(errorMessage.format(name));
    }
  }

  public static isInRange(value: Number, from: Number, to: Number, name?: string, message?: string): void {
    if (value < from || value > to) {
      let errorMessage: string = message || (name
          ? '"{0}" is out of range [{1}, {2}].'
          : 'Specified value out of range [{1}, {2}].');

      throw new Error(errorMessage.format(name || '', from, to));
    }
  }

  public static isArray(value: Object, name?: string, message?: string): void {
    if (Object.prototype.toString.call(value) !== '[object Array]') {
      let errorMessage: string = message || (name
          ? '"{0}" is not a array.'
          : 'Specified value is not a array.');

      throw new Error(errorMessage.format(name));
    }
  }

  public static isOwnProperty(value: Object, property: string | number, message?: string): void {
    if (!value || !value.hasOwnProperty(property)) {
      let errorMessage: string = message || (property
          ? '"{0}" property is not member of object.'
          : 'Specified property is not member of object.');
      throw new Error(errorMessage.format(property));
    }
  }

  public static isNotOwnProperty(value: Object, property:  string | number, message?: string): void {
    if (!value) return;
    if (value.hasOwnProperty(property)) {
      let errorMessage: string = message || (property
          ? 'Object cannot have own property {0}.'
          : 'Object cannot have own specified property.');
      throw new Error(errorMessage.format(property));
    }
  }
}
