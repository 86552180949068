<slx-spinner [show]="isLoading">
    <div class="modal-body-grid">
        <slx-midnight-to-midnight-report 
            (hasValueChange)="onValueChanged($event)" 
            [gridData]="reportDefinition.gridData"
            (modifiedIndex)="modifiedDataChanges($event)">
        </slx-midnight-to-midnight-report>
    </div>
    <div class="modal-footer wrapper">
        <div class="btn red pad-top-10" (click)="onCancel()"><i class="fas fa-times slx-button__icon"
                aria-hidden="true"></i>Cancel
        </div>
        <div class="btn green pad-top-10" (click)="onSave()" [ngClass]="{ 'disabled-content': !hasValueChange }"><i
                class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</div>
    </div>
</slx-spinner>
