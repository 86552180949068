import { ReadFile } from './read-file';
import { AttachmentFile } from './attachment-file';

export class UiAttachmentFile {

  public readonly isAttachment: boolean;
  public readonly currentUserName: string;


  constructor (public sourceItem: AttachmentFile | ReadFile, currentUserName: string = null) {
    this.currentUserName = currentUserName;
    this.isAttachment = this.sourceItem instanceof AttachmentFile;
  }

  public get id(): number {
    return this.isAttachment ? (this.sourceItem as AttachmentFile).id : null;
  }

  public get name(): string {
    return this.sourceItem.name;
  }

  public get fileName(): string {
    return this.sourceItem.fileName;
  }

  public get addedBy (): string {
    return this.isAttachment ? (this.sourceItem as AttachmentFile).addedBy : this.currentUserName;
}
}
