
<ng-content></ng-content>
<div *ngIf="shown" class="progressbar">
  <span class="progressbar__spinner" aria-hidden="true">
    <i class="progressbar__item progressbar__tl" aria-hidden="true"></i>
    <i class="progressbar__item progressbar__tr" aria-hidden="true"></i>
    <i class="progressbar__item progressbar__br" aria-hidden="true"></i>
    <i class="progressbar__item progressbar__bl" aria-hidden="true"></i>
  </span>
</div>
