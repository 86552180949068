import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import * as moment from 'moment';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { DateRange } from '../../../../../core/models/index';

import { dateTimeUtils } from '../../../../../common/utils/index';


@Component({
  moduleId: module.id,
  selector: 'slx-lm-custom-recurrence',
  templateUrl: 'lm-custom-recurrence.component.html',
  styleUrls: ['lm-custom-recurrence.component.scss']
})
export class LMCustomRecurrenceComponent implements OnInit, OnDestroy {
  public actStartDate: Date = null;
  public actEndDate: Date = null;
  public actAllDay: boolean = false;
  public customDates: DateRange[] = [];
  public hasOverlapping = false;
  public hasExceeded = false;
  public appConfig: IApplicationConfig = appConfig;
  public isValidActStartDate: boolean = true;
  public isValidActEndDate: boolean = true;

  public get canAddDate(): boolean {
    return this.isValidDate(this.actStartDate) && this.isValidDate(this.actEndDate) && !this.hasOverlapping && !this.hasExceeded;
  }
  public get hasDates(): boolean {
    return _.size(this.customDates) > 0;
  }
  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  private exceptionsDates: DateRange[] = [];
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: LMCreationAbsenceManagementService) { }

  public ngOnInit(): void {
    this.updatesDates();

    this.subscriptions.loaRequest = this.manService.subscribeToChangedRequest(() => this.updatesDates());
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onClickAddDate(): void {
    const range = new DateRange(this.actStartDate, this.actEndDate);
    this.customDates = [...this.customDates, range];
    this.actStartDate = null;
    this.actEndDate = null;
    this.isValidActStartDate = false;
    this.isValidActEndDate = false;
    this.manService.setCustomDates(this.customDates);
  }

  public onDeleteDate(index: number): void {
    this.customDates = _.filter(this.customDates, (d: DateRange, i: number) => i !== index);
    this.manService.setCustomDates(this.customDates);
  }

  public onChangeActStartDate(date: Date): void {
    this.validateOverlappedDates();
    this.validateExceptionsDates();
    if (date != null)
      this.isValidActStartDate = false;
    else
      this.isValidActStartDate = true;
  }

  public onChangeActEndDate(date: Date): void {
    this.validateOverlappedDates();
    this.validateExceptionsDates();
    if (date != null)
      this.isValidActEndDate = false;
    else
      this.isValidActEndDate = true;
  }

  public onChangeActAllDay(): void {
    if (this.actAllDay) {
      this.actStartDate = this.manService.getStartOfDay(this.actStartDate);
      this.actEndDate = this.manService.getEndOfDay(this.actEndDate);
      this.manService.setActualDates(this.actStartDate, this.actEndDate);
    } else {
      this.actEndDate = moment(this.actEndDate).hour(0).minute(0).second(0).toDate();

      this.manService.setActualDates(this.actStartDate, this.actEndDate);
    }
    this.manService.setActAllDay(this.actAllDay);
  }

  private updatesDates(): void {
    this.customDates = this.manService.getCustomDates();
    this.exceptionsDates = this.manService.getExceptions();
  }

  private validateOverlappedDates(): void {
    if (_.size(this.customDates) > 0 && _.isDate(this.actStartDate) && _.isDate(this.actEndDate)) {
      this.hasOverlapping = _.some(this.customDates, (d: DateRange) => {
        const noOverlapping = this.actEndDate.getTime() <= d.startDate.getTime() || this.actStartDate.getTime() >= d.endDate.getTime();
        return !noOverlapping;
      });
    }
  }

  private validateExceptionsDates(): void {
    if (_.size(this.exceptionsDates) > 0 && _.isDate(this.actStartDate) && _.isDate(this.actEndDate)) {
      this.hasExceeded = _.some(this.exceptionsDates, (e: DateRange) => {
        const withinCustomDates = this.actStartDate.getTime() <= e.startDate.getTime() && this.actEndDate.getTime() > e.endDate.getTime()
          || this.actStartDate.getTime() < e.startDate.getTime() && this.actEndDate.getTime() >= e.endDate.getTime();
        return !withinCustomDates;
      });
    }
  }

  
  private isValidDate(date: Date): boolean {
    return _.isDate(date) && dateTimeUtils.validateDate(date);
  }
}
