<form #form="ngForm">
  <div class="editor-container">

    <div class="field-container wrapped">
      <div class="col">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            [(ngModel)]="dates.startDate"
            [maxDate]="maxDateRestriction"
            [required]="true"
            placeholder="From"
            name="startDate"
            (valueChange)="startDateChanged($event)"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <span errorMessage for="date"></span>
          <span errorMessage for="maxDate">Start Date should be less than the End Date</span>
        </slx-input-decorator>

        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            [(ngModel)]="dates.endDate"
            [minDate]="minDateRestriction"
            [maxDate]="maxDateProperty"
            [required]="true"
            placeholder="To"
            name="endDate"
            (valueChange)="endDateChanged($event)"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <span errorMessage for="date"></span>
          <span errorMessage for="minDate">End Date should be greater than the Start Date</span>
        </slx-input-decorator>
      </div>
    </div>

    <div class="actions-bar">
      <div class="col"></div>
      <button type="button" class="theme-button-cancel margin-r" (click)="onCancel()">Cancel</button>
      <button type="button" class="theme-button-apply" [disabled]="!form.valid || !isValidFromDate || !isValidToDate"
              (click)="onOk()">Save</button>
    </div>

  </div>
</form>
