import { Component, OnInit, OnDestroy } from '@angular/core';
import { Aca1095c, AcaInsightFilter, AcaInsightSummaryRecords } from '../../../models';
import { Subscription } from 'rxjs';
import { unsubscribe, unsubscribeAll } from '../../../../core/decorators';
import { Aca1095cManagementService } from '../../../services';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-1095-c-insights',
  templateUrl: 'aca-1095-c-insights.component.html',
  styleUrls: ['aca-1095-c-insights.component.scss']
})

export class Aca1095cInsightsComponent implements OnInit, OnDestroy {

  public insights: AcaInsightSummaryRecords;
  public selectedFilter: string = 'All';
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private managementService: Aca1095cManagementService) {
   
  }
  ngOnDestroy(): void {
    
  }
  public ngOnInit() {
    this.subscriptions.onInsightsLoaded = this.managementService
      .subscribeToInsightsRecords((insightSummary: AcaInsightSummaryRecords) => {
        this.insights =insightSummary; 
        this.selectedFilter='All';     
      });
  }

  public applyInsightsFilter(filterCase: string): void {
    if (_.isEqual(this.selectedFilter, filterCase)) {
      return;    
    }
    this.selectedFilter = filterCase;
    let insightsFilter = new AcaInsightFilter();
    insightsFilter.filter=filterCase;
    switch (filterCase) {
      case 'FT_Enrolled_FullYear': {
        insightsFilter.allRecords = false;
        insightsFilter.acaType = 'FT';
        insightsFilter.enrolledInd = true;
        insightsFilter.enrolledFullYear = true;
        
        break;
      }
      case 'FT_Enrolled_PartYear': {
        insightsFilter.allRecords = false;
        insightsFilter.acaType = 'FT';
        insightsFilter.enrolledInd = true;
        insightsFilter.enrolledPartYear = true;
        break;
      }
      case 'FT_NotEnrolled': {
        insightsFilter.allRecords = false;
        insightsFilter.acaType = 'FT';
        insightsFilter.enrolledInd = false;
        insightsFilter.enrolledFullYear = true;
        break;
      }
      case 'PT_Enrolled': {
        insightsFilter.allRecords = false;
        insightsFilter.acaType = 'PT';
        insightsFilter.enrolledInd = true;
        
        break;
      }
      case 'NotConfirmed': {
        insightsFilter.allRecords = false;
        insightsFilter.confirmInd = false;
        
        break;
      }      
      default: {
        insightsFilter.allRecords = true;
        break;
      }       
    }
    this.managementService.setInsightsFilter(insightsFilter);
  }
}
