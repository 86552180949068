import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { Assert } from '../../../framework/index';
import { unsubscribeInService } from '../../decorators/index';
var PollingPeriodService = /** @class */ (function () {
    function PollingPeriodService(userActivityService, tokenValidityService) {
        var _this = this;
        this.userActivityService = userActivityService;
        this.tokenValidityService = tokenValidityService;
        this.state = serviceState.initial;
        this.onInactivityTimeoutSubscription = this.userActivityService.onInactivityTimedOut.subscribe(function () { return _this.subscribeToInactivityTimedOut(); });
        this.onInactivityResetedSubscription = this.userActivityService.onIncativityReseted.subscribe(function () { return _this.subscribeToIncativityReseted(); });
        if (this.tokenValidityService) {
            this.tokenValidityService.onTokenRenewed.subscribe(function () { return _this.subscribeToTokenRenewed(); });
            this.tokenValidityService.onTokenExpired.subscribe(function () { return _this.subscribeToTokenExpired(); });
        }
        this.onHeartbeat = new Subject();
    }
    PollingPeriodService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    PollingPeriodService.prototype.listen = function (each) {
        var _this = this;
        try {
            Assert.isTrue(!!each && each > 0, "You cant listen so! Set 'each' parameter greater than 0.");
            this.period = each;
            if (this.state === serviceState.started && this.intervalSubscription) {
                this.intervalSubscription.unsubscribe();
            }
            this.intervalSubscription = Observable.interval(each)
                .subscribe(function () {
                if (_this.state === serviceState.started) {
                    _this.makeBeat();
                }
            });
            this.state = serviceState.started;
        }
        catch (error) {
            this.state = serviceState.failed;
            throw error;
        }
    };
    PollingPeriodService.prototype.stop = function () {
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
        this.state = serviceState.stoped;
    };
    PollingPeriodService.prototype.makeBeat = function () {
        this.onHeartbeat.next(1);
    };
    PollingPeriodService.prototype.subscribeToInactivityTimedOut = function () {
        if (this.state === serviceState.started) {
            this.state = serviceState.freezed;
        }
    };
    PollingPeriodService.prototype.subscribeToIncativityReseted = function () {
        if (this.state === serviceState.freezed) {
            this.state = serviceState.started;
        }
    };
    PollingPeriodService.prototype.subscribeToTokenRenewed = function () {
        if (!this.userActivityService.isActivityTimeoutReached) {
            if (this.state === serviceState.freezed) {
                this.state = serviceState.started;
            }
        }
    };
    PollingPeriodService.prototype.subscribeToTokenExpired = function () {
        if (this.state === serviceState.started) {
            this.state = serviceState.freezed;
        }
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PollingPeriodService.prototype, "intervalSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PollingPeriodService.prototype, "onInactivityTimeoutSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PollingPeriodService.prototype, "onInactivityResetedSubscription", void 0);
    return PollingPeriodService;
}());
export { PollingPeriodService };
var serviceState;
(function (serviceState) {
    serviceState[serviceState["initial"] = 0] = "initial";
    serviceState[serviceState["started"] = 1] = "started";
    serviceState[serviceState["freezed"] = 3] = "freezed";
    serviceState[serviceState["stoped"] = 2] = "stoped";
    serviceState[serviceState["failed"] = -1] = "failed";
})(serviceState || (serviceState = {}));
