import { PayrollExportRelease } from './../../models/payroll-export/payroll-export-release';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { LookupMapService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';

import {
  IPayrollExportContainer, PayrollExportContainer,
  IPayrollExportGroup, PayrollExportGroup,
  IPayrollExportFacility, PayrollExportFacility,
} from '../../models/index';

@Injectable()
export class PayrollExportMapService {

  constructor(private lookupMapService: LookupMapService) {
  }

  public mapPayrollExportFacility(dto: IPayrollExportFacility, exportDate: Date): PayrollExportFacility {
    const data = new PayrollExportFacility();
    data.organization = this.lookupMapService.mapOrganization(dto.organization);
    data.submittedDate = dateTimeUtils.convertFromDtoString(dto.submittedDate);
    data.submittedBy = dto.submittedBy;
    data.isSubmitted = dto.isSubmitted;
    data.releaseDate = dateTimeUtils.convertFromDtoString(dto.releaseDate);
    data.releasedBy = dto.releasedBy;
    data.isReleased = dto.isReleased;
    data.exportDate = exportDate;
    return data;
  }

  public mapPayrollExportGroup(dto: IPayrollExportGroup): PayrollExportGroup {
    const data = new PayrollExportGroup();
    data.name = dto.name;
    data.requestStatus = dto.requestStatus;
    data.releaseStatus = dto.releaseStatus;
    data.isExported = dto.isExported;
    data.canCancelRelease = dto.canCancelRelease;
    data.canReleaseToPayroll = dto.canCancelRelease;
    data.exportDate = dateTimeUtils.convertFromDtoString(dto.exportDate);
    data.facilities = dto.facilities ? _.map(dto.facilities, (d: IPayrollExportFacility) => this.mapPayrollExportFacility(d, data.exportDate)) : [];
    data.isAllFacilitiesReleased = !_.find(data.facilities, (d: PayrollExportFacility) => !d.isReleased);
    data.isAllFacilitiesRequested = !_.find(data.facilities, (d: PayrollExportFacility) => !d.isSubmitted);
    data.groupOrgCount = dto.groupOrgCount;
    return data;
  }

  public mapPayrollExportContainer(dto: IPayrollExportContainer): PayrollExportContainer {
    const data = new PayrollExportContainer();
    data.groups = dto.groups ? _.map(dto.groups, (d: IPayrollExportGroup) => this.mapPayrollExportGroup(d)) : [];
    return data;
  }

  public mapPayrollExportRelease(orgLevelId: number, groupName: string, startDate: Date, endDate: Date): PayrollExportRelease {
    const data = new PayrollExportRelease();
    data.orgLevelId = orgLevelId;
    data.groupName = groupName;
    data.startDate = dateTimeUtils.convertToDtoString(startDate);
    data.endDate = dateTimeUtils.convertToDtoString(endDate);
    return data;
  }
}

