import * as moment from 'moment';
import { Injectable, Input, Provider } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { DateRange, IDateRange } from '../../../core/models/index';
import { Subscription } from 'rxjs';
import { Assert } from '../../../framework';
import { OpenPositionControlConfigurationComponent } from '../../components/open-position-control/open-position-control-configuration/open-position-control-configuration.component';
import { DialogOptions, ModalService } from './../../../../app/common';
import { OpenConfigOrgLevelType } from '../../models';
 


@Injectable()
export class OpenPositionControlManagementService {
  private dateRange$ = new Subject<DateRange>();
  private exportTo$ = new Subject<boolean>();

  public modalService: ModalService;

  constructor (modalService: ModalService) {
    this.modalService = modalService;
   }

  public getDefaultDateRange(): DateRange {
    let startDate = moment().startOf('week').toDate();
    let actualEndDate = moment(startDate).add(4, 'week').toDate();
    let endDate = moment(actualEndDate).subtract(1, 'day').toDate();
    return new DateRange(startDate, endDate);
  }

  public subscribeToDateRange(callback: (dateRange: IDateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.dateRange$.subscribe(callback);
  }

  public subscribeToExport(callback: (isPdf: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }

  public doExport(isPdf: boolean): void {
    this.exportTo$.next(isPdf);
  }

  public changeDateRange(dateRange: IDateRange): void {
    this.dateRange$.next(dateRange);
  }

}
