import { Component, Input } from '@angular/core';
import { Lookup } from '../../../../organization/models/index';
import { punchWrapper, TimeCardDisplayOptions } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-line-punch-mobile',
  templateUrl: 'daily-line-punch-mobile.component.html',
  styleUrls: ['daily-line-punch-mobile.component.scss']
})
export class DailyLinePunchMobileComponentComponent {
  @Input()
  public editable: boolean;
  @Input()
  public dataItem: punchWrapper;
  @Input()
  public punchTypesLookup: Lookup;
  @Input()
  public employeeId: number;
  @Input()
  public displayOptions: TimeCardDisplayOptions;

  public appConfig: IApplicationConfig;

  constructor() {
    this.appConfig = appConfig;
  }
}
