import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../../app.config';
import { EmployeeWarningSections } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EmployeeSectionsWarningsViolationsComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsViolationsComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.violationDate = null;
        this.violationsList = [];
        this.subscriptions = {};
        this.appConfig = appConfig;
    }
    EmployeeSectionsWarningsViolationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.violations = this.manService.getViolationsSection();
        this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(function (warning) {
            _this.assignViolations(warning);
            _this.assignViolationsData();
            _this.updateView();
        });
        this.subscriptions.statusChanges = this.form.statusChanges.subscribe(function () {
            _this.manService.changeSectionValidity(EmployeeWarningSections.StandardViolations, _this.form.valid);
        });
    };
    EmployeeSectionsWarningsViolationsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsWarningsViolationsComponent.prototype.onChangeDate = function () {
        this.violations.data.dateOfViolation = this.getDateCopy(this.violationDate);
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsViolationsComponent.prototype.onChangeViolation = function (vio) {
        var violation = _.find(this.violations.data.violations, { id: vio.id });
        if (_.isObjectLike(violation)) {
            violation.selected = vio.selected;
            this.manService.markWarningAsEdited(true);
        }
    };
    EmployeeSectionsWarningsViolationsComponent.prototype.updateView = function () {
        var _this = this;
        Observable.of(true)
            .delay(200)
            .subscribe(function () { return _this.changeDetector.detectChanges(); });
    };
    EmployeeSectionsWarningsViolationsComponent.prototype.assignViolations = function (warning) {
        var violations = _.find(warning.sections, function (s) { return s.isStandardViolations; });
        if (_.isObjectLike(violations) && _.isObjectLike(violations.data)) {
            this.violations = violations;
        }
    };
    EmployeeSectionsWarningsViolationsComponent.prototype.assignViolationsData = function () {
        this.violationDate = this.getDateCopy(this.violations.data.dateOfViolation);
        this.violationsList = _.cloneDeep(this.violations.data.violations);
    };
    EmployeeSectionsWarningsViolationsComponent.prototype.getDateCopy = function (d) {
        return _.isDate(d) ? new Date(d.getTime()) : null;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsWarningsViolationsComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsWarningsViolationsComponent;
}());
export { EmployeeSectionsWarningsViolationsComponent };
