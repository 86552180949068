import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, AfterViewInit, OnDestroy, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { mutableSelect } from '../../../core/decorators/index';
import { StateResetTypes } from '../../../core/models/settings/reset-types';
import { ControlStateKey, IControlState } from '../../../core/models/settings/component-states';
import { OrgLevel } from '../../../state-model/models/index';
import { TaConsoleNavigationService } from './../../../common/services/navigation/ta-console-navigation.service';
import { StateManagementService, ComponentStateStorageService, ExceptionConsoleNavigationService, DailyPunchesNavigationService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { ArrivalDeparturesManagementService, TaConsoleToolbarService } from '../../services/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { scheduleMicrotask } from '../../../core/utils/index';
import { ArrivalDeparturesContainer } from '../../models/index';
import {
  LookupService, LookupType, Lookup,
  TimeclockDailySummaryContainer, TimeclockDataService,
  PayCycle, PayCycleHelperService
} from '../../../organization/index';
import { screenUtils } from '../../../common/utils/index';
import { TOOLBAR_SERVICE } from '../../../core/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-ta-console',
  templateUrl: 'ta-console.component.html',
  styleUrls: ['ta-console.component.scss'],
  providers: [ArrivalDeparturesManagementService, StateManagementService,
    { provide: TOOLBAR_SERVICE, useClass: TaConsoleToolbarService }
  ]
})
export class TaConsoleComponent implements AfterViewInit, OnDestroy, OnInit {
  public selectedDate: Date;
  public timeclockDailySummaryContainer: TimeclockDailySummaryContainer;
  public arrivalDeparturesContainer: ArrivalDeparturesContainer;
  public dateNow: Date;
  public appConfig: IApplicationConfig;
  public orgLevelId: number;

  @mutableSelect('orgLevel')
  private orgLevel$: Observable<OrgLevel>;

  private taNavService: TaConsoleNavigationService;
  private exceptionNavService: ExceptionConsoleNavigationService;
  private punchesNavService: DailyPunchesNavigationService;

  @unsubscribe()
  private routeSubscription: Subscription;
  @unsubscribe()
  private timeclockLoadedSubscription: Subscription;
  @unsubscribe()
  private arrivalDeparturesLoadedSubscription: Subscription;

  private stateKey: ControlStateKey;
  private readonly m_componentId: string = 'TaConsoleComponent';
  private readonly m_dateFiltersControlId: string = 'DatePickerNgx';
  private readonly m_dateFiltersResetType: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;


  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private arrivalDeparturesManagementService: ArrivalDeparturesManagementService,
    private timeclockDataService: TimeclockDataService,
    private payCycleHelperService: PayCycleHelperService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService,
    private lookupService: LookupService,
    @Inject(TOOLBAR_SERVICE) private consoleToolbarService: TaConsoleToolbarService,
  ) {
    this.punchesNavService = new DailyPunchesNavigationService(this.router, this.activatedRoute);
    this.taNavService = new TaConsoleNavigationService(this.router, this.activatedRoute);
    this.exceptionNavService = new ExceptionConsoleNavigationService(this.router, this.activatedRoute);
    this.dateNow = moment().toDate();
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.stateManagement.init(this.m_componentId);
  }

  public ngAfterViewInit(): void {
    scheduleMicrotask(() => {
      this.timeclockDataService.loadTimeclocks();
      this.routeSubscription = this.activatedRoute.params
        .combineLatest(this.activatedRoute.queryParams, this.orgLevel$)
        .subscribe(([params, queryParams, orgLevel]: [Params, Params, OrgLevel]) => {
          if (!orgLevel || !orgLevel.id) return;

          if (this.orgLevelId !== orgLevel.id) {
            this.orgLevelId = orgLevel.id;
          }

          if (this.orgLevelId === +queryParams.orgLevelId) {
            let dateOn = queryParams['date'];
            if (!dateOn) {
              this.restoreFilters();
              if (this.selectedDate) {
                this.dataChanged(false);
                return;
              }
              dateOn = this.dateNow;
            }
            this.selectedDate = moment(dateOn, appConfig.linkDateFormat).toDate();
            this.dataChanged(true);
          }
        });

      this.timeclockLoadedSubscription = this.timeclockDataService.onLoaded$
        .subscribe((timeclocksContainer: TimeclockDailySummaryContainer) => {
          timeclocksContainer.records = _.orderBy(timeclocksContainer.records, ['isVirtual', 'communicationStatus', 'sortName']);
          this.timeclockDailySummaryContainer = timeclocksContainer;
        });

      this.arrivalDeparturesLoadedSubscription = this.arrivalDeparturesManagementService.onLoaded$
        .subscribe((container: ArrivalDeparturesContainer) => {
          this.arrivalDeparturesContainer = container;
        });
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onFilterDateChanged(date: Date): void {
    this.selectedDate = date;
    this.saveFilters();
    this.navigateToConsole(this.selectedDate);
  }

  public navigateToConsole(dateOn: Date): void {
    this.taNavService.navigateToTaConsole(dateOn);
  }

  public async navigateToPunchMissing(): Promise<void> {
    let dateRange: any = await this.payCycleHelperService.getEffectivePunchesDateRange(this.selectedDate, this.orgLevelId);
    dateRange.startDate = moment().subtract(14, 'days').toDate();
    this.punchesNavService.navigateToDailyPunchesDates(this.orgLevelId, dateRange.startDate, dateRange.endDate, true);
  }

  public async navigateToTimeException(): Promise<void> {
    let dateRange: any = await this.payCycleHelperService.getEffectivePunchesDateRange(this.selectedDate, this.orgLevelId);
    this.exceptionNavService.navigateToExceptionConsoleDates(this.orgLevelId, dateRange.startDate, dateRange.endDate, false);
  }

  public get missingPunchesCount(): number {
    return +_.get(this.arrivalDeparturesContainer, 'missingPunchesCount') || 0;
  }

  public get timecardExceptionCount(): number {
    return +_.get(this.arrivalDeparturesContainer, 'timecardExceptionCount') || 0;
  }

  public get dateFormat(): string {
    return screenUtils.isMobile ? 'MM/dd/yy' : 'MM/dd/yyyy';
  }

  private dataChanged(isSaveDate: boolean): void {
    if (_.isDate(this.selectedDate) && _.isNumber(this.orgLevelId)) {
      this.arrivalDeparturesManagementService.loadData(this.orgLevelId, this.selectedDate);
    }
    if (isSaveDate) {
      this.saveFilters();
    }
  }

  private saveFilters(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId,
      {
        value: { selectedDate: this.selectedDate }
      },
      this.m_dateFiltersResetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
    if (state && state.value) {
      if (state.value.selectedDate) this.selectedDate = moment(state.value.selectedDate).toDate();
    }
  }

}
