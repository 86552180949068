export * from './wc-roster-toolbar/wc-roster-toolbar.component';
export * from './wc-incident-roster/wc-incident-roster.component';
export * from './wc-incident-roster/wc-incident-roster-grid/wc-incident-roster-grid.component';

export * from './wc-comparisons/wc-comparisons.component';
export * from './wc-create-osha-rk-forms/wc-create-osha-rk-forms.component';
export * from './wc-incident-tracking/wc-incident-tracking.component';
export * from './wc-incident-tracking/wc-incident-tracking-grid/wc-incident-tracking-grid.component';
export * from './wc-osha-budget-tracker/wc-osha-budget-tracker.component';
export * from './wc-osha-budget-tracker/wc-osha-budget-tracker-grid/wc-osha-budget-tracker-grid.component';
export * from './wc-repeat-injury/wc-repeat-injury.component';
export * from './wc-repeat-injury/wc-repeat-injury-grid/wc-repeat-injury-grid.component';
export * from './wc-summary/wc-summary.component';
export * from './wc-summary/wc-summary-grid/wc-summary-grid.component';
export * from './wc-create-osha-rk-forms/wc-create-osha-grid/wc-create-osha-form-excel-dialog/wc-create-osha-form-excel-dialog.component';
export * from './wc-create-osha-rk-forms/wc-create-osha-grid/wc-create-establishment-size-dialog/wc-create-establishment-size-dialog.component';

import { WcRosterToolbarComponent } from './wc-roster-toolbar/wc-roster-toolbar.component';
import { WcIncidentRosterComponent } from './wc-incident-roster/wc-incident-roster.component';
import { WcIncidentRosterGridComponent } from './wc-incident-roster/wc-incident-roster-grid/wc-incident-roster-grid.component';

import { WcComparisonsComponent } from './wc-comparisons/wc-comparisons.component';
import { WcCreateOshaRkFormsComponent } from './wc-create-osha-rk-forms/wc-create-osha-rk-forms.component';
import { WcIncidentTrackingComponent } from './wc-incident-tracking/wc-incident-tracking.component';
import { WcIncidentTrackingGridComponent } from './wc-incident-tracking/wc-incident-tracking-grid/wc-incident-tracking-grid.component';
import { WcOshaBudgetTrackerComponent } from './wc-osha-budget-tracker/wc-osha-budget-tracker.component';
import { WcOshaBudgetTrackerGridComponent } from './wc-osha-budget-tracker/wc-osha-budget-tracker-grid/wc-osha-budget-tracker-grid.component';
import { WcRepeatInjuryComponent } from './wc-repeat-injury/wc-repeat-injury.component';
import { WcRepeatInjuryGridComponent } from './wc-repeat-injury/wc-repeat-injury-grid/wc-repeat-injury-grid.component';
import { WcSummaryComponent } from './wc-summary/wc-summary.component';
import { WcSummaryGridComponent } from './wc-summary/wc-summary-grid/wc-summary-grid.component';
import { WcComparisonsGridComponent } from './wc-comparisons/wc-comparisons-grid/wc-comparisons-grid.component';

import { WcCreateOshaGridComponent } from './wc-create-osha-rk-forms/wc-create-osha-grid/wc-create-osha-grid.component';
import { WcCreateOshaFormExcelDialogComponent } from './wc-create-osha-rk-forms/wc-create-osha-grid/wc-create-osha-form-excel-dialog/wc-create-osha-form-excel-dialog.component';
import { WcCreateEstablishmentSizeDialogComponent } from './wc-create-osha-rk-forms/wc-create-osha-grid/wc-create-establishment-size-dialog/wc-create-establishment-size-dialog.component';

export const rosterComponents: any[] = [
  WcRosterToolbarComponent,
  WcIncidentRosterComponent,
  WcIncidentRosterGridComponent,
  WcComparisonsComponent,
  WcCreateOshaRkFormsComponent,
  WcIncidentTrackingComponent,
  WcIncidentTrackingGridComponent,
  WcOshaBudgetTrackerComponent,
  WcOshaBudgetTrackerGridComponent,
  WcRepeatInjuryComponent,
  WcRepeatInjuryGridComponent,
  WcSummaryComponent,
  WcSummaryGridComponent,
  WcComparisonsGridComponent,
  WcCreateOshaGridComponent,
  WcCreateOshaFormExcelDialogComponent,
  WcCreateEstablishmentSizeDialogComponent
];
