import * as _ from 'lodash';
var TimecardsColumnState = /** @class */ (function () {
    function TimecardsColumnState() {
    }
    Object.defineProperty(TimecardsColumnState.prototype, "width", {
        get: function () {
            var w = 0;
            _.forEach(this.columns, function (col) {
                if (col.displayed) {
                    w += col.width;
                }
            });
            return w;
        },
        enumerable: true,
        configurable: true
    });
    TimecardsColumnState.prototype.mapColumns = function () {
        this.columnsMap = _.keyBy(this.columns, function (column) {
            return column.name;
        });
    };
    TimecardsColumnState.prototype.setState = function (initialState, defaultState) {
        _.forEach(this.columns, function (column) {
            if (initialState[column.name] === undefined) {
                column.displayed = defaultState;
            }
            else {
                column.displayed = initialState[column.name];
            }
        });
    };
    return TimecardsColumnState;
}());
export { TimecardsColumnState };
