import { DayOfWeek, WeekDays } from '../../../../common/models/index';
import { EmployeeRotation, WeeklyRotation, EmployeeRotationRecord } from '../../models/index';
import { Assert } from '../../../../framework/index';
import * as moment from 'moment';

export class WeekDayService {

  public getWeekDaysByRotation(rotation: EmployeeRotation): DayOfWeek[] {
    if (rotation) {
      let firstWeek: WeeklyRotation = rotation.firstWeek;
      if (firstWeek) {
        let firstDayName: string = moment(firstWeek.weekStartDate).format('dddd');
        let firstDayOfWeek: DayOfWeek = this.getWeekDayByName(firstDayName);
        return this.getWeekDays(firstDayOfWeek, 1);
      }
    }
    return WeekDays;
  }

  public prepareRotation(employeeRotation: EmployeeRotation): void {
    Assert.isNotNull(employeeRotation, 'employeeRotation');

    let weekDays: DayOfWeek[] = this.getWeekDaysByRotation(employeeRotation);

    let currentRotationsCount: number = employeeRotation.weeklyRotations ? employeeRotation.weeklyRotations.length : 0;
    if (currentRotationsCount > 0) {
      if (currentRotationsCount < employeeRotation.rotationsCount) {
        for (let i: number = 0; i < employeeRotation.rotationsCount - currentRotationsCount; i++) {
          let weeklyRotation: WeeklyRotation = new WeeklyRotation();
          let previousWeeklyRotation: WeeklyRotation = employeeRotation.weeklyRotations[currentRotationsCount - 1];
          weeklyRotation.weekStartDate = moment(previousWeeklyRotation.weekStartDate).add(7, 'days').toDate();
          weeklyRotation.isCurrent = new Date() >= weeklyRotation.weekStartDate && new Date() < moment(weeklyRotation.weekStartDate).add(7, 'days').toDate();
          weeklyRotation.weekNumber = previousWeeklyRotation.weekNumber + 1;
          employeeRotation.weeklyRotations.push(weeklyRotation);
        }
      }
    } else {
      let weeklyRotation: WeeklyRotation = new WeeklyRotation();
      weeklyRotation.weekStartDate = moment().startOf('week').toDate();
      weeklyRotation.isCurrent = true;
      weeklyRotation.weekNumber = 0;
      employeeRotation.weeklyRotations.push(weeklyRotation);
    }
    employeeRotation.weeklyRotations.forEach((w: WeeklyRotation) => this.prepareWeeklyRotation(w, weekDays));
  }

  public prepareWeeklyRotation(weeklyRotation: WeeklyRotation, weekDays: DayOfWeek[]): void {
    Assert.isNotNull(weeklyRotation, 'weeklyRotation');
    let dailyRecords: EmployeeRotationRecord[] = [];
    for (let dayOfWeekIndex: number = 0; dayOfWeekIndex < weekDays.length; dayOfWeekIndex++) {
      let dailyRecord: EmployeeRotationRecord = weeklyRotation.dailyRecords.find((d: EmployeeRotationRecord) => d.dayNumber === dayOfWeekIndex);
      if (dailyRecord === null || dailyRecord === undefined) {
        dailyRecord = new EmployeeRotationRecord(weeklyRotation);
        dailyRecord.dayNumber = dayOfWeekIndex;
        dailyRecord.dayOfWeek = weekDays[dayOfWeekIndex].name;
      }
      var dateOn = moment(dailyRecord.weeklyRotation.weekStartDate).add(dailyRecord.dayNumber, 'days').toDate();
      for (let shift: number = 0; shift < dailyRecord.shifts.length; shift++)
      {
        dailyRecord.shifts[shift].shift.startDate = this.copyTime(dailyRecord.shifts[shift].shift.startDate, dateOn);
        dailyRecord.shifts[shift].shift.endDate =  this.copyTime(dailyRecord.shifts[shift].shift.endDate, dateOn);
        
        if (moment(dailyRecord.shifts[shift].shift.endDate).isBefore(dailyRecord.shifts[shift].shift.startDate)) {
          dailyRecord.shifts[shift].shift.endDate = moment(dailyRecord.shifts[shift].shift.endDate).add(1, 'days').toDate();
        }
      }
      dailyRecords.push(dailyRecord);
    }
    weeklyRotation.dailyRecords = dailyRecords;
  }

  private copyTime(source: Date, target: Date): Date {
    return moment(target.setHours(source.getHours(), source.getMinutes(), source.getSeconds(), source.getMilliseconds())).toDate();
  }

  public getWeekDayByName(name: string): DayOfWeek {
    let dayIndex: number = WeekDays.findIndex((v: DayOfWeek) => v.name === name);
    return WeekDays[dayIndex];
  }

  public getWeekDays(dayOfWeek: DayOfWeek, dayNumber: number): DayOfWeek[] {
    Assert.isNotNull(dayOfWeek, 'dayOfWeek');
    dayNumber = dayNumber % 7;
    let dayIndex: number = WeekDays.findIndex((v: DayOfWeek) => v.name === dayOfWeek.name) + 1;

    if (dayIndex === undefined) {
      throw new SyntaxError(`Cant find the day of week with name: ${dayOfWeek.name}`);
    }

    dayIndex = (7 + dayIndex - dayNumber) % 7;

    let result: DayOfWeek[] = [];
    let resultTail: DayOfWeek[] = [];

    for (let dayOfWeekIndex: number = 0; dayOfWeekIndex < WeekDays.length; dayOfWeekIndex++) {
      if (dayOfWeekIndex < dayIndex) {
        resultTail.push(WeekDays[dayOfWeekIndex]);
      } else {
        result.push(WeekDays[dayOfWeekIndex]);
      }
    }

    return result.concat(resultTail);
  }
}
