import { DialogOptions2, DialogModeSize } from './../../../../../common/models/dialog-options';
import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { ConfirmDialog2Component, ConfirmOptions, DialogOptions, IDialog } from '../../../../../common/index';

import { IDateRange, DateRange } from '../../../../../core/models/index';
import { ModalService } from '../../../../../common/services/index';
import { BenefitDependentDroppedEnrollment } from '../../../../../app-modules/benefits/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-dependent-drop-enrollment-dialog',
  templateUrl: 'employee-dependent-drop-enrollment-dialog.component.html'
})
export class EmployeeDependentDropEnrollmentDialogComponent implements IDialog {

  public dialogOptions: DialogOptions2;
  public dialogResult: boolean;
  public options: DateRange;
  public dropEnrollment: BenefitDependentDroppedEnrollment;
  public get canDrop(): boolean {
    return _.isObjectLike(this.dropEnrollment)
      && _.isDate(this.dropEnrollment.eventDate)
      && _.isDate(this.dropEnrollment.coverageEndDate)
      && _.isObjectLike(this.dropEnrollment.reason);
  }

  private modalService: ModalService;

  public static openDialog(
    options: DateRange,
    modalService: ModalService,
    callback: (result: boolean) => void
  ): EmployeeDependentDropEnrollmentDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.height = 300;
    dialogOptions.width = 550;
    dialogOptions.modeSize = DialogModeSize.custom;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: DateRange,
        useValue: options
      }
    ];
    const title = 'Drop Benefit Coverage';

    return modalService.globalAnchor.openDialog2(
      EmployeeDependentDropEnrollmentDialogComponent,
      title,
      dialogOptions,
      resolvedProviders,
      (result: boolean) => {
        callback(result);
      }
    );
  }

  constructor(
    dialogOptions: DialogOptions2,
    modalService: ModalService,
    options: DateRange
  ) {
    this.modalService = modalService;
    this.dialogOptions = dialogOptions;
    this.options = options;
  }

  public onDropEnrollment(drop: BenefitDependentDroppedEnrollment): void {
    this.dropEnrollment = drop;
  }

  public onAdd(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public onCancel(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved selections.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.dialogResult = false;
          this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
        }
      },
      options);
  }
}
