import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';

import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData, Workbook, WorkbookSheet, WorkbookSheetRow } from '@progress/kendo-angular-excel-export';

import { unsubscribeAll } from '../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../common/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import {
  AccrualBalances,
  AccrualBalanceRecord,
  IAccrualsBalancesState
} from '../../models/index';
import { AccrualsBalancesManagementService } from '../../services/accruals-balances-management.service';
import { AccrualBalanceColumnsSettings } from '../../models/accruals-column-settings';
import { AppServerConfig } from './../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../app-settings/services/index';


@Component({
  selector: 'slx-accruals-balances-grid',
  templateUrl: './accruals-balances-grid.component.html',
  styleUrls: ['./accruals-balances-grid.component.scss']
})
export class AccrualsBalancesGridComponent implements OnInit, OnDestroy {

  public records: AccrualBalanceRecord[];
  public container: AccrualBalances;
  public state: IAccrualsBalancesState;
  public columnState:AccrualBalanceColumnsSettings;
  public gridState: KendoGridStateHelper<AccrualBalanceRecord[]>;
  public appConfig: IApplicationConfig;
  public orgLevel: OrgLevel;
  public pageSize: number = 50;
  public canEdit: boolean;
  public hideRates: boolean = true;
  public xlsxName = 'Accruals_Balances_';
  public pdfName = 'Accruals_Balances_';
  public columnsGroupName: string = 'AccrualsBalances';
  public isLocked: boolean = true; 
  public EnableRate4DecimalPlaces: boolean;
  public cells: any;
  public get isLockedColumn(): boolean {
    return this.devDetector.isDesktop && this.columnState && (this.columnState.columns.filter(record => record.displayed === true).length==0 ? false : true);
  }

  public get isReordable(): boolean {
    return this.devDetector.isDesktop;
  }

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private accrualManagementService: AccrualsBalancesManagementService, private devDetector: DeviceDetectorService,private appSettingsManageService: AppSettingsManageService, private router: Router, private route: ActivatedRoute) {
    this.gridState = new KendoGridStateHelper<AccrualBalanceRecord[]>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [
      { field: 'employee.fullName', dir: 'asc' }
    ];

    this.appConfig = appConfig;
  }

  public ngOnInit() {
    const date = new Date().toLocaleDateString();
    this.xlsxName += `${date}.xlsx`;
    this.pdfName += `${date}.pdf`;
    this.getSettings();

    this.subscriptions.state = this.accrualManagementService.subscribeToState((state: IAccrualsBalancesState) => {
      this.state = state;
      this.gridState.state.skip = 0;
      this.refreshGrid();
    });
    this.subscriptions.columnState = this.accrualManagementService.subscribeToColumnState((columnState: AccrualBalanceColumnsSettings) => {
      this.columnState = columnState;     
      
    });
    
    this.subscriptions.onLoaded = this.accrualManagementService
      .subscribeToLoadedRecords((container: AccrualBalances) => {
        this.container = container;
        this.assignRecords(container.records);
        this.hideRates = container.hideRates;
      });

    this.subscriptions.export = this.accrualManagementService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }

  public ngOnDestroy(): void { }

 
  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.getFilteredRecords(), { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  public onEmpNameClick(empId: number): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);

    const urlTree = navService.getUrlTreeFromRoot(empId, true);
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };

    this.router.navigateByUrl(urlTree, extras);
  }

  public getFieldPath(name: string, property: string): string {
    return `accrualTypesMap.${name}.${property}`;
  }

  public isVisible(field: string): boolean {
    return this.columnState && this.columnState.columnsMap[field] ? this.columnState.columnsMap[field].displayed : false;
  }

  public onExcelExport(e: { workbook: Workbook }): void {
    const sheets: WorkbookSheet = _.head(_.get(e, 'workbook.sheets'));

    _.forEach(sheets.rows, (row: WorkbookSheetRow) => {
      if (row.type === 'data') {
        _.forEach(row.cells, (cell) => {
          if (_.isBoolean(cell.value)) {
            cell.value = cell.value ? 'Yes' : 'No';
          }
        });
      }
    });
  }

  private assignRecords(records: AccrualBalanceRecord[]): void {
    this.records = records;
    this.refreshGrid();
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    this.gridState.view = process(this.getFilteredRecords(), this.gridState.state);
  }

  private getFilteredRecords(): AccrualBalanceRecord[] {
    let filteredRecords = this.records;

    if (this.state.isEmployeeActive === true) {
      filteredRecords = filteredRecords.filter(record => record.employee.status === 'Active');
    }

    if (this.state.isShowNegativeBalance === true) {
      filteredRecords = filteredRecords.filter(record =>
        _.some(record.accrualTypesMap, accrualType => accrualType.hourBalance < 0 || accrualType.dollarBalance < 0));
    }

    return filteredRecords;
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.EnableRate4DecimalPlaces =  config.EnableRate4DecimalPlaces;
    if(this.EnableRate4DecimalPlaces){
      this.cells = {
        format: '0.0000'
      }
    }
    else{
      this.cells = {
        format: '0.00'
      }
    }
  }

  public get isShowHighPrecision(): boolean {
    return this.EnableRate4DecimalPlaces;
  }
}
