import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { PMDialogOptions } from '../../../models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { PmReviewEntry } from '../../../models/index';
import { PmCreationManagementService } from '../../../services/index';
var PmCreateReviewContainerComponent = /** @class */ (function () {
    function PmCreateReviewContainerComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.action = new EventEmitter();
        this.employeeSelected = new EventEmitter();
        this.formValid = true;
        this.loadingReview = true;
        this.loadingEmployees = true;
        this.review = new PmReviewEntry();
        this.errorMessage = '';
        this.subscriptions = {};
    }
    Object.defineProperty(PmCreateReviewContainerComponent.prototype, "hasEmployee", {
        get: function () {
            return this.prevReview ? this.review.hasEmployee : this.isReviewMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmCreateReviewContainerComponent.prototype, "isNew", {
        get: function () {
            return this.review.isNew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmCreateReviewContainerComponent.prototype, "hasError", {
        get: function () {
            return _.size(this.errorMessage) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmCreateReviewContainerComponent.prototype, "canEdit", {
        get: function () {
            return this.review ? this.review.canEdit : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmCreateReviewContainerComponent.prototype, "isEditMode", {
        get: function () {
            return this.review.isEditMode || this.review.isNew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmCreateReviewContainerComponent.prototype, "isComplete", {
        get: function () {
            return this.review.status === 'Completed';
        },
        enumerable: true,
        configurable: true
    });
    PmCreateReviewContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (_.isObjectLike(_.get(this.options, 'employee'))) {
            this.isReviewMode = true;
            this.manService.setEmployeeWithoutProcessing(this.options.employee);
            this.employeeSelected.emit(this.options.employee.id);
        }
        else {
            this.isReviewMode = _.isObjectLike(_.get(this.options, 'review.employee'));
            if (this.isReviewMode) {
                this.manService.setEmployeeWithoutProcessing(_.get(this.options, 'review.employee'));
            }
        }
        this.manService.init(this.options.review);
        this.subscriptions.loading = this.manService.subscribeToLoading(function (isLoading) {
            _this.loadingReview = isLoading;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.subscriptions.review = this.manService.subscribeToReviewChanged(function (review) {
            _this.loadingEmployees = false;
            _this.review = review;
            if (!_this.prevReview) {
                _this.prevReview = _.cloneDeep(review);
            }
            // ignore changes in this prop
            _this.prevReview.isEditMode = review.isEditMode;
            _this.prevReview.employee = review.employee;
            _this.prevReview.templates = review.templates;
            _this.prevReview.id = review.id;
            _this.hasChanges = JSON.stringify(_this.prevReview) !== JSON.stringify(review);
            _this.isReviewMode = _.isObjectLike(review.employee);
        });
        this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel(function (o) {
            _this.orgLevel = o;
        });
        this.subscriptions.valid = this.manService.onValidityChanged(function (valid) {
            _this.formValid = valid;
        });
        this.subscriptions.close = this.manService.subscribeToClosePopup(function () {
            _this.onClose();
        });
    };
    PmCreateReviewContainerComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
    };
    PmCreateReviewContainerComponent.prototype.onSearchLoadStatusChange = function (isLoading) {
        this.loadingEmployees = isLoading;
    };
    PmCreateReviewContainerComponent.prototype.onEmployeeSelected = function (emp) {
        if (_.isObjectLike(emp) && _.isFinite(emp.id)) {
            this.isReviewMode = true;
            this.manService.setEmployee(emp);
            this.employeeSelected.emit(emp.id);
        }
    };
    PmCreateReviewContainerComponent.prototype.onEdit = function () {
        this.manService.toggleEditMode(true);
    };
    PmCreateReviewContainerComponent.prototype.onSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.prevReview = null;
                this.manService.saveReview();
                return [2 /*return*/];
            });
        });
    };
    PmCreateReviewContainerComponent.prototype.onCancel = function () {
        this.manService.cancelEditing();
    };
    PmCreateReviewContainerComponent.prototype.onClose = function () {
        this.action.emit(false);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmCreateReviewContainerComponent.prototype, "subscriptions", void 0);
    return PmCreateReviewContainerComponent;
}());
export { PmCreateReviewContainerComponent };
