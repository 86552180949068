import * as _ from 'lodash';
import { IWcEmployeeInfoDto, WcEmployeeInfo } from './wc-employee-info';
import { IWcInjuryReportDto, WcInjuryReport } from './wc-injury-report';
import { IWcOSHAReportDto, WcOSHAReport } from './wc-osha-report';
import { IWCCompensationReportDto, WCCompensationReport } from './wc-compensation-report';
import { ReadFile } from '../../../organization/models';
import { ReportCommonFields } from './wc-report-common-fields';

export interface IWcReportDto {
    id: number;
    employee: IWcEmployeeInfoDto;
    incidentDateTime: string;
    reportDateTime: string;
    loaRequestId: number;
    attachments: any[];
    addedDate: string;
    addedBy: string;
    updatedDate: string;
    updatedBy: string;
    injuryReport: IWcInjuryReportDto;
    oshaReport: IWcOSHAReportDto;
    compReport: IWCCompensationReportDto;

}

export class WcReport {

    public set editMode(value: boolean) {
        this.m_editMode = value;
    }

    public get editMode(): boolean {
        return this.m_editMode;
    }


    public get isNew(): boolean {
        return !_.isFinite(this.id);
    }

    public get isValid(): boolean {

        let valid: boolean = true;

        if (_.isNil(this.employee)) {
            valid = false;
        }

        if (_.isNil(this.incidentDateTime)) {
            valid = false;
        }

        if (_.isNil(this.reportDateTime)) {
            valid = false;
        }

        if (_.isNil(this.injuryReport)) {
            valid = false;
        }

        if (!_.isNil(this.injuryReport)) {
            if (this.injuryReport.isOSHARecordable && _.isNil(this.oshaReport)) {
                valid = false;
            }
            if (this.injuryReport.isWorkersCompCase && _.isNil(this.compReport)) {
                valid = false;
            }
        }

        return valid;
    }

    public filesToSave: ReadFile[];

    public id: number;
    public employee: WcEmployeeInfo;
    public incidentDateTime: Date;
    public reportDateTime: Date;
    public loaRequestId: number;
    public attachments: any[];
    public addedDate: Date;
    public updatedDate: Date;
    public addedBy: string;
    public updatedBy: string;
    public injuryReport: WcInjuryReport;
    public oshaReport: WcOSHAReport;
    public compReport: WCCompensationReport;

    public common: ReportCommonFields;

    public canAdd: boolean;
    public canEdit: boolean;
    public canDelete: boolean;

    private m_editMode: boolean;
    
}