<kendo-grid #kendoSupervisorGrid class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="true"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)"
>

  <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected" media="sm" width="40">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
    </ng-template>
    <ng-template kendoGridHeaderTemplate>
      <input type="checkbox" [ngModel]="selectionHelper.isAllSelected" (ngModelChange)="allSelectionChange()" name="allSelectionToggle">
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <input type="checkbox" [ngModel]="dataItem.isSelected" (ngModelChange)="selectionChange(dataItem)" name="selector{{rowIndex}}">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Username" field="userName" media="sm" width="150">
    <ng-template kendoGridHeaderTemplate>
      Username
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.userName}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Role" field="role" media="sm" width="150">
    <ng-template kendoGridHeaderTemplate>
      Role
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.role}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Employee" field="employeeName" media="sm" width="150">
    <ng-template kendoGridHeaderTemplate>
      Employee
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.employeeName}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Center" field="centerName" media="sm" width="150">
    <ng-template kendoGridHeaderTemplate>
      Center
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.centerName}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position" field="positionName" media="sm" width="150">
    <ng-template kendoGridHeaderTemplate>
      Position
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.positionName}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Groups" media="sm" width="200">
    <ng-template kendoGridHeaderTemplate>
      Groups
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.groups | slxJoin :'name' :','}}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
