import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { KendoGridStateHelper } from '../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { MessageNotificationModel } from '../../models/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { MessagesManagementService } from '../../../app-modules/message-center/services/messages-management.service';
import { OrgLevel } from '../../../state-model/models/index';
import { mutableSelect, unsubscribe, unsubscribeAll } from '../../../../app/core/decorators';
import { Observable, Subscription } from 'rxjs';
import { MessageNotificationService } from "../../services/notifications/msg-notifications.service";
import { NotificationsApiService } from '../../services';
import { IMessageList } from '../../../app-modules/message-center/models';
import { MessageCenterConfigService } from '../../../../../src/app/organization/services/index';
@Component({
  moduleId: module.id,
  selector: 'slx-message-center-notification-list',
  templateUrl: 'message-center-notification-list.component.html',
  styleUrls: [
    'message-center-notification-list.component.scss'
  ]
})

export class MessageCenterNotificationListComponent implements OnInit {
  public gridState: KendoGridStateHelper<MessageNotificationModel>;

  // public messages: MessageModel[];
  public isLoading: boolean;
  public records = [];
  @Output() closeModal = new EventEmitter<any>();
  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @Input() messageCount: any;
  public orgLevel: OrgLevel;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  orglevelId = 1;
  @unsubscribe()
  private messageSubscription: Subscription;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  @Input() isMyMessage: boolean;

  constructor(private route: Router, private messagesManagementService: MessagesManagementService,
    private msgNotificationService: MessageNotificationService,
    private messageCenterConfigService: MessageCenterConfigService,
    private notificationsApiService: NotificationsApiService) {
    this.gridState = new KendoGridStateHelper<MessageNotificationModel>();
    this.gridState.view = null
  }

  public ngOnInit(): void {
    this.isLoading = true;
     this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
       if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
         this.orgLevel = orgLevel;
         this.orglevelId = orgLevel.id;
       }
     });

     this.subscriptions.alert = this.notificationsApiService.subcribeToNewmessageAlert((res: IMessageList) => {
     if(!this.messagesManagementService.isMsgCenterLoaded) {
        this.messagesManagementService.getRecentMessageByEmployeeId(res.employeeId,this.isMyMessage);
      }     
     });

    this.messagesManagementService.getPopUpMessages(this.orglevelId, this.isMyMessage);

    this.messageSubscription = this.messagesManagementService.subscribeToPopUpMessages((res) => {
      this.records = res;
      this.refreshGrid();
      this.isLoading = false;
    })

    this.msgNotificationService.subscribeToMessageStatusUpdate((rowData => {
      this.isLoading = false;
      this.closeModal.emit(true);
      this.route.navigate([this.messageCenterConfigService.GetMessageCenterUrl()]);
      setTimeout(() => {
        this.messagesManagementService.changeActiveTab();
        this.messagesManagementService.updateMessageThread(rowData, this.orglevelId,true,this.isMyMessage);
        this.messagesManagementService.updateMessageCount(this.messageCount);
      }, 700);
      this.records.filter(x => x.id === rowData.id).map(y => y.read = true);
    }));

    this.subscriptions.msg = this.messagesManagementService.subscribeToRecentMessage((res => {
      const index = this.records.findIndex(x => x.employeeId === res.employeeId);
      if (index > -1) {
        this.records.splice(index, 1)
      }
      const obj = {
        id: res['id'],
        employeeId: res['employeeId'],
        created: res['created'],
        modified: res['modified'],
        body: res['body'],
        replyCount: res['replyCount'],
        mobilePhoneNumber: res['mobilePhoneNumber'],
        optIn: res['optIn'],
        orgLevelId: res['orgLevelId'],
        photo: res['photo'],
        status: res['status'],
        isOnLeave: res['isOnLeave'],
        fullName: res['employeeName'],
        employeeName: res['employeeName'],
      }
      this.records.unshift(obj);
      this.refreshGrid();
    }));
  }

  public refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  // API call to update status of Messages
  updateReadStatus(rowData) {
    this.isLoading = true;
    this.msgNotificationService.getMessageStatusUpdate(rowData);
  }

  redirectToMSGCenter() {
    this.closeModal.emit(true);
    this.route.navigate([this.messageCenterConfigService.GetMessageCenterUrl()]);
  }
}
