import {  } from './../../../organization/models/lookup/department';
import { IOrganization, Organization, IPayCycle, PayCycle, Department, IDepartment} from '../../../organization/models/index';

export interface IPunchesRollupContainer {
   entities: IPunchesRollupRecord[];
}

export interface IPunchesRollupRecord {
  workOrganization: IOrganization;
  organization: IOrganization;
  department: IDepartment;
  payCycle: IPayCycle;
  approvedTimecardsPercentage: number;
  validTimecardsCount: number;
  missingPunchesCount: number;
  pendingEmpRequestsCount: number;
  invalidPunchesCount: number;
  scheduledNoShowCount: number;
}

export class PunchesRollupRecord {
  public workOrganization: Organization;
  public organization: Organization;
  public department: Department;
  public payCycle: PayCycle;

  public approvedTimecards: number;
  public validTimecards: number;
  public missingPunches: number;
  public pendingEmpRequest: number;
  public invalidPunches: number;
  public scheduledPunches: number;

  public get workOrgName(): string {
    return this.workOrganization ? this.workOrganization.name : '';
  }

  public get orgName(): string {
    return this.organization ? this.organization.name : '';
  }

  public get startDate(): Date {
    return this.payCycle ? this.payCycle.startDate : null;
  }

  public get endDate(): Date {
    return this.payCycle ? this.payCycle.endDate : null;
  }
}
