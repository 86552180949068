import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';


export function unsubscribe(): PropertyDecorator {
  return function decorate(target: any, key: string | symbol): void {
    let ngOnDestroyFunction: Function = target['ngOnDestroy'];
    let ngOnDestroy: Function = function (): void {
      if (this) {
        let subscription: Subscription = this[key];
        if (subscription) {
          subscription.unsubscribe();
        }
        if (ngOnDestroyFunction) {
          ngOnDestroyFunction.call(this);
        }
      }
    };
    target['ngOnDestroy'] = ngOnDestroy;
  };
}
