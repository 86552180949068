import { FormulaTypeEnum } from './formula-type.enum';

export interface IBenefitEmpEnrollmentFormula {

    id: number;
    lineId: number;
    tierId: number;

    formulaType: number;
    empFormulaType: number;
    erFormulaType: number;
    maxCap: number;
    multipler: number;

    fixedAmount: number;
    formulaValue: number;

    empContribution: number;
    erContribution: number;
    empFormulaExpression: string;
    erFormulaExpression: string;
    covFormulaExpression: string;
}

export class BenefitEmpEnrollmentFormula {

    public id: number;
    public lineId: number;
    public tierId: number;

    public formulaType: FormulaTypeEnum;
    public empFormulaType: FormulaTypeEnum;
    public erFormulaType: FormulaTypeEnum;

    public maxCap: number;
    public multipler: number;

    public fixedAmount: number;
    public formulaValue: number;

    public erContribution: number;
    public empContribution: number;
    public calculatedCoverage?: number;
    public empFormulaExpression: string;
    public erFormulaExpression: string;
    public covFormulaExpression: string;

    public getCoverage(): number {
        let coverage = 0;
        switch (this.formulaType) {
            case FormulaTypeEnum.Fixed:
                coverage = this.fixedAmount;
                break;
            case FormulaTypeEnum.Formula:
            case FormulaTypeEnum.AnyWithMaxCap:
            case FormulaTypeEnum.MultiplerMaxCap:
                coverage = this.formulaValue;
                break;
        }
        return coverage;
    }
}