import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { ThrottlingChangeEvent, TabMode } from '../../models/index';
import { Assert } from '../../../framework/index';
import { OnPageVisibilityChange, AngularPageVisibilityStateEnum } from 'angular-page-visibility';
import { unsubscribeInService } from '../../decorators/index';
var ThrottlingService = /** @class */ (function () {
    function ThrottlingService() {
        this.toSubscription = new Subject();
        this.lastChange = moment();
    }
    ThrottlingService.prototype.pageVisibility = function (visibilityState) {
        this.onVisibilityChange(AngularPageVisibilityStateEnum[visibilityState]
            === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE]);
    };
    ThrottlingService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    ThrottlingService.prototype.subscribeToModeChange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.toSubscription.subscribe(callback);
    };
    ThrottlingService.prototype.unsubscribe = function (s) {
        Assert.isNotNull(s, 'subscription');
        s.unsubscribe();
    };
    ThrottlingService.prototype.reset = function () {
        this.lastChange = moment();
    };
    ThrottlingService.prototype.onVisibilityChange = function (visible) {
        if (!visible) {
            this.setBackgroundMode();
        }
        else {
            this.setFrontMode();
        }
    };
    ThrottlingService.prototype.setBackgroundMode = function () {
        var range = moment.range(this.lastChange, moment());
        var diff = range.diff();
        this.lastChange = moment();
        var event = new ThrottlingChangeEvent();
        event.mode = TabMode.BACKGROUND;
        event.timeFromChangeMode = diff;
        this.toSubscription.next(event);
    };
    ThrottlingService.prototype.setFrontMode = function () {
        var range = moment.range(this.lastChange, moment());
        var diff = range.diff();
        this.lastChange = moment();
        var event = new ThrottlingChangeEvent();
        event.mode = TabMode.FRONT;
        event.timeFromChangeMode = diff;
        this.toSubscription.next(event);
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ThrottlingService.prototype, "visiblityApiSubscription", void 0);
    tslib_1.__decorate([
        OnPageVisibilityChange(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Number]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ThrottlingService.prototype, "pageVisibility", null);
    return ThrottlingService;
}());
export { ThrottlingService };
