import * as tslib_1 from "tslib";
import { TimelineHelper } from './timeline-helper';
var TimelineFitHelper = /** @class */ (function (_super) {
    tslib_1.__extends(TimelineFitHelper, _super);
    function TimelineFitHelper(config) {
        return _super.call(this, config) || this;
    }
    TimelineFitHelper.prototype.updateSlide = function (timelineTotWidth, forward) {
        this.prevLinkInactive = true;
        this.nextLinkInactive = true;
    };
    TimelineFitHelper.prototype.updateFillingStartByDate = function (date) {
        var distanceNorm = this.calcDistanceNorm(this.startDate, date);
        var scaleValue = 0;
        if (distanceNorm > 0) {
            scaleValue = (distanceNorm + this.elementWidth / 2) / (this.eventsWrapperWidth);
        }
        this.setTransformValue(this.config.fillingLineStart.nativeElement, 'scaleX', scaleValue);
    };
    TimelineFitHelper.prototype.updateFillingEndByDate = function (date) {
        var distanceFromStartNorm = this.calcDistanceNorm(this.startDate, date);
        var distanceNorm = this.calcDistanceNorm(date, this.endDate);
        var scaleValue = 0;
        if (distanceNorm > 0) {
            scaleValue = (distanceNorm - this.elementWidth / 2) / this.eventsWrapperWidth;
        }
        this.setTransformValue(this.config.fillingLineEnd.nativeElement, 'scaleX', scaleValue);
    };
    TimelineFitHelper.prototype.initTimelineImpl = function (timeLines, rtConfig) {
        this.setTimelineWidth();
        this.setDatePosition(timeLines);
        this.setTransformValue(this.config.eventsWrapper.nativeElement, 'translateX', '0px');
        this.prevLinkInactive = true;
        this.nextLinkInactive = true;
        this.updateFillingEndByDate(this.endDate);
        this.updateFillingStartByDate(this.startDate);
    };
    TimelineFitHelper.prototype.setTimelineWidth = function () {
        this.eventsWrapperWidth = this.parentWidth;
        //this.config.renderer.setStyle(this.config.eventsWrapper.nativeElement, 'left', this.offset + 'px');
        var aHref = this.config.eventsWrapper.nativeElement.querySelectorAll('a.selected')[0];
        this.updateFilling(aHref);
        this.updateTimelinePosition(aHref);
        return this.eventsWrapperWidth;
    };
    TimelineFitHelper.prototype.setDatePosition = function (elements) {
        var timeSpan = this.dayDiff(this.startDate, this.endDate);
        var timelineEventsArray = this.config.timelineEvents.toArray();
        if (timelineEventsArray.length === 0) {
            return;
        }
        var i = 0;
        this.norm = (this.eventsWrapperWidth - this.offset * 2) / timeSpan;
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var component = elements_1[_i];
            var distanceNorm = this.calcDistanceNorm(this.startDate, component.date);
            var e = timelineEventsArray[i];
            if (e) {
                this.config.renderer.setStyle(e.nativeElement, 'left', distanceNorm + 'px');
                // span
                var span = e.nativeElement.parentElement.children[1];
                var spanWidth = this.getElementWidth(span);
                this.config.renderer.setStyle(span, 'left', distanceNorm + spanWidth / 2 + 'px');
                //span.style.left = distanceNorm * min + spanWidth / 2 + 'px';
            }
            i++;
        }
    };
    TimelineFitHelper.prototype.calcDistanceNorm = function (startDate, endDate) {
        var distance = this.dayDiff(startDate, endDate);
        var distanceNorm = Math.round(distance * this.norm);
        return distanceNorm;
    };
    return TimelineFitHelper;
}(TimelineHelper));
export { TimelineFitHelper };
