import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ConfirmDialogComponent } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ChangeManagementService } from '../../../common/services/change-management/change-management.service';

import { BudgetRecord, Budget, AddRecordReq } from '../../models/index';
import { BudgetDefinition } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { BudgetApiService } from './budget-api.service';
import { Subject } from 'rxjs/Subject';
import { IDestroyService } from '../../../core/models/index';

@Injectable()
export class BudgetManagementService implements IDestroyService {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public onLoadStatus$: ReplaySubject<boolean>;
  public onSelectionChanged$: ReplaySubject<BudgetRecord>;

  public onEditRecord$: ReplaySubject<boolean>;
  public onAddPosition$: ReplaySubject<AddRecordReq>;
  public onPositionAdded$: ReplaySubject<BudgetRecord>;

  public onGroupEdit$: ReplaySubject<boolean>;
  public onEditBudgetMode$: ReplaySubject<boolean>;
  public onAddBudgetMode$: ReplaySubject<boolean>;
  public onRestoreBudget$: ReplaySubject<boolean>;
  public onBudgetCensusAdjust$: ReplaySubject<boolean>;


  public onOrgLevelChanged$: ReplaySubject<number>;
  public onBudgetSelect$: ReplaySubject<Budget>;
  public onAdded$: Subject<Budget>;
  public onDeleted$: ReplaySubject<number>;
  public onLoaded$: ReplaySubject<Budget>;
  public onDummyLoaded$: ReplaySubject<Budget>;
  public overlapWarning$: Subject<{ budget: Budget, isNew: boolean }>;
  public onSaveBudget$: ReplaySubject<boolean>;
  public selectedRecord: BudgetRecord;
  public currentOrgLevel: OrgLevel;
  public currentBudget: Budget;
  public priorBudgetDefinition: BudgetDefinition;
  public currentBudgetDefinition: BudgetDefinition;
  public currentLoadRequest: BudgetDefinition;
  public selectedOrganizationOrgLevelId: number;
  public selectedOrgLevel: OrgLevel;

  public get addMode(): boolean {
    return this.m_addMode;
  }

  public get editMode(): boolean {
    return this.m_editMode;
  }

  private m_addMode: boolean;
  private m_editMode: boolean;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  private budgetApiService: BudgetApiService;

  private readonly componentId: string = 'BudgetManagement';

  constructor(budgetApiService: BudgetApiService, private changeService: ChangeManagementService) {
    this.budgetApiService = budgetApiService;
    this.onSelectionChanged$ = new ReplaySubject(1);
    this.onLoadStatus$ = new ReplaySubject(1);
    this.onDummyLoaded$ = new ReplaySubject(1);
    this.onAdded$ = new Subject();
    this.onDeleted$ = new ReplaySubject(1);
    this.onLoaded$ = new ReplaySubject(1);
    this.onOrgLevelChanged$ = new ReplaySubject(1);
    this.onAddPosition$ = new ReplaySubject(1);
    this.onEditRecord$ = new ReplaySubject(1);
    this.onPositionAdded$ = new ReplaySubject(1);
    this.onGroupEdit$ = new ReplaySubject(1);
    this.onRestoreBudget$ = new ReplaySubject(1);
    this.onBudgetCensusAdjust$ = new ReplaySubject(1);
    this.onBudgetSelect$ = new ReplaySubject(1);
    this.onEditBudgetMode$ = new ReplaySubject(1);
    this.onAddBudgetMode$ = new ReplaySubject(1);
    this.overlapWarning$ = new Subject();
    this.onSaveBudget$ = new ReplaySubject(1);

    this.changeService.setCurrentComponentId(this.componentId);

    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        this.onOrgLevelSelected(o);
      });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }
  public onSaveBudget(isSave: boolean): void {
    this.onSaveBudget$.next(isSave);
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public onAddPosition(event: AddRecordReq): void {
    event.orgLevelId = this.selectedOrganizationOrgLevelId;
    this.onAddPosition$.next(event);
  }

  public onEditRecord(isEditRecord: boolean): void {
    this.onEditRecord$.next(isEditRecord);
  }

  public onPositionAdded(event: BudgetRecord): void {
    this.onPositionAdded$.next(event);
  }

  public onEditBudgetMode(editMode: boolean): void {
    this.m_editMode = editMode;
    this.onEditBudgetMode$.next(editMode);
    if (editMode) {
      this.changeService.changeNotify();
    } else {
      this.changeService.clearChanges();
    }
  }

  public onAddBudgetMode(addMode: boolean): void {
    this.m_addMode = addMode;
    this.onAddBudgetMode$.next(addMode);
    if (addMode) {
      this.changeService.changeNotify();
    } else {
      this.changeService.clearChanges();
    }
  }

  public onGroupEdit(editMode: boolean): void {
    this.onGroupEdit$.next(editMode);
  }

  public onRestoreBudget(editMode: boolean): void {
    this.onRestoreBudget$.next(editMode);
  }

  public onBudgetCensusAdjust(editMode: boolean): void {
    this.onBudgetCensusAdjust$.next(editMode);
  }

  public onRecordSelected(event: BudgetRecord): void {
    this.selectedRecord = event;
    this.onSelectionChanged$.next(event);
  }

  public onBudgetSelect(req: Budget): void {
    this.currentBudget = req;
    this.onBudgetSelect$.next(req);
  }

  public getDummy(): void {
    this.onLoadStatusChanged(true);
    this.budgetApiService.getBudgetDummy(this.currentOrgLevel.id)
      .then((result: Budget) => {
        this.currentBudget = result;
        this.onDummyLoaded$.next(result);
        this.onEditBudgetMode(true);
        this.m_editMode = false;
        this.onLoadStatusChanged(false);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public onLoadRequest(req: BudgetDefinition): void {
    if (req) {
      this.onLoadStatusChanged(true);
      req.orgLevelId = this.currentOrgLevel.id;
      this.currentLoadRequest = req;
      this.budgetApiService.getBudget(req.orgLevelId, req.id)
        .then((result: Budget) => {
          this.currentBudget = result;
          this.onLoaded$.next(result);
          this.onLoadStatusChanged(false);
        })
        .catch((reason: any) => {
          this.onLoadStatusChanged(false);
        });
    } else {
      this.currentLoadRequest = null;
      this.currentBudget = null;
      this.onLoaded$.next(null);
    }
  }

  public onAddRequest(req: Budget, definitions: BudgetDefinition[], skipCheck: boolean = false): void {
    if (!skipCheck) {
      let overlaps: boolean;
      if (definitions) overlaps = this.checkOverlapping(req, definitions);
      if (overlaps) {
        this.warnAboutOverlap(req, true);
        return;
      }
    }
    this.doAdd(req);
  }

  public onSaveRequest(req: Budget, definitions: BudgetDefinition[], skipCheck: boolean = false): void {
    if (!skipCheck) {
      let overlaps: boolean;
      if (definitions) overlaps = this.checkOverlapping(req, definitions);
      if (overlaps) {
        this.warnAboutOverlap(req, false);
        return;
      }
    }
    this.doSave(req);
  }

  public onDeleteRequest(budgetId: number): void {
    this.onLoadStatusChanged(true);
    this.currentBudget = null;
    this.budgetApiService.deleteBudget(budgetId)
      .then((result: any) => {
        this.onLoadStatusChanged(false);
        this.onBudgetSelect(null);
        this.onDeleted$.next(budgetId);
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  public sendOverlapWaning(req: Budget, isNew: boolean): void {
    this.warnAboutOverlap(req, isNew);
  }

  public checkOverlapping(newBudget: Budget, definitions: BudgetDefinition[]): boolean {
    let hasOverlaps: boolean = false;
    let budgetStart: moment.Moment = moment(newBudget.startDate);
    let budgetEnd: moment.Moment = newBudget.endDate ? moment(newBudget.endDate) : null;
    _.each(definitions, (definition: BudgetDefinition) => {

      if (newBudget.id !== definition.id) {
        let defStart: moment.Moment = moment(definition.startDate);
        let defEnd: moment.Moment = definition.endDate ? moment(definition.endDate) : null;

        if (defEnd && budgetEnd) {
          if (budgetStart.isBetween(defStart, defEnd) || budgetEnd.isBetween(defStart, defEnd)) {
            hasOverlaps = true;
          } else if (budgetStart.isSame(defStart) || budgetEnd.isSame(defEnd)) {
            hasOverlaps = true;
          }
        } else if (defEnd) {
          // new budget has no end date
          if (budgetStart.isSameOrBefore(defStart)) {
            hasOverlaps = true;
          }
        } else if (budgetEnd) {
          // existing budget has no end date
          if (budgetEnd.isSameOrAfter(defStart)) {
            hasOverlaps = true;
          }
        }
      }
    });

    return hasOverlaps;
  }

  private warnAboutOverlap(req: Budget, isNew: boolean): void {
    this.overlapWarning$.next({ budget: req, isNew: isNew });
  }

  private doAdd(req: Budget): void {
    this.onLoadStatusChanged(true);
    this.currentBudget = req;
    this.budgetApiService.addBudget(req)
      .then((result: Budget) => {
        this.onAdded$.next(result);
        this.onAddBudgetMode(false);
        this.onEditBudgetMode(false);
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  private doSave(req: Budget): void {
    this.onLoadStatusChanged(true);
    this.currentBudget = req;
    this.budgetApiService.saveBudget(req)
      .then((result: Budget) => {
        this.onLoaded$.next(result);
        this.onSaveBudget(true);
        this.onLoadStatusChanged(false);
        this.changeService.clearChanges();
      })
      .catch((reason: any) => {
        this.onLoadStatusChanged(false);
      });
  }

  private onOrgLevelSelected(o: OrgLevel): void {
    this.m_addMode = false;
    this.m_editMode = false;
    this.setOrgLevel(o);
    this.onOrgLevelChanged$.next(this.selectedOrganizationOrgLevelId);
  }

  private setOrgLevel(o: OrgLevel): void {
    if (o.type === OrgLevelType.department) {
      this.selectedOrganizationOrgLevelId = 0; // o.parentId;
    } else if (o.type === OrgLevelType.organization) {
      this.selectedOrganizationOrgLevelId = o.id;
    } else {
      this.selectedOrganizationOrgLevelId = 0;
    }
    this.currentOrgLevel = o;
  }
}
