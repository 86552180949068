import * as tslib_1 from "tslib";
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { ModalService } from '../../../common/index';
import { ArrivalDeparturesContainer } from '../../models/index';
import { ArrivalsDeparturesApiService } from './arrivals-departures-api.service';
import { IDateRange } from '../../../core/models/index';
import { ManagementBaseService } from '../../../core/services/index';
import { ArrivalDeparturesFilter } from '../../models/index';
var ArrivalDeparturesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(ArrivalDeparturesManagementService, _super);
    function ArrivalDeparturesManagementService(arrivalsDeparturesApiService, modalService) {
        var _this = _super.call(this) || this;
        _this.arrivalsDeparturesApiService = arrivalsDeparturesApiService;
        _this.modalService = modalService;
        _this.arrivalsDeparturesApiService = arrivalsDeparturesApiService;
        _this.onFilterChanged$ = new Subject();
        _this.filter = new ArrivalDeparturesFilter();
        return _this;
    }
    ArrivalDeparturesManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    ArrivalDeparturesManagementService.prototype.loadData = function (orgLevelId, dateOn) {
        var _this = this;
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        Assert.isNotNull(dateOn, 'dateOn');
        this.onLoadStatusChanged(true);
        this.arrivalsDeparturesApiService.getArrivalsDepartures(orgLevelId, dateOn)
            .then(function (result) {
            _this.onLoaded$.next(result);
            _this.onLoadStatusChanged(false);
        });
    };
    ArrivalDeparturesManagementService.prototype.onArrivalRangeChanged = function (range) {
        this.filter.arrivalsRange = range;
        this.onFilterChanged$.next(this.filter);
    };
    ArrivalDeparturesManagementService.prototype.onDeparturesRangeChanged = function (range) {
        this.filter.departuresRange = range;
        this.onFilterChanged$.next(this.filter);
    };
    return ArrivalDeparturesManagementService;
}(ManagementBaseService));
export { ArrivalDeparturesManagementService };
