import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { process, aggregateBy, AggregateDescriptor } from '@progress/kendo-data-query';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';


import {  unsubscribe } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { KendoGridStateHelper, } from '../../../../../common/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { DateRange } from '../../../../../core/models/index';

import { PbjReconciliationManagementService } from '../../../services/index';
import { PBJRecEmpEntry, PBJRecColumns, PBJRecColumn, PbjReconciliationOrglevel } from '../../../models/index';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-reconciliation-emp-grid',
  templateUrl: 'pbj-reconciliation-emp-grid.component.html',
  styleUrls: ['pbj-reconciliation-emp-grid.component.scss']
})
export class PbjReconciliationEmpGridComponent implements OnInit, OnDestroy {
  public showDetails: boolean;
  public gridState: KendoGridStateHelper<PBJRecEmpEntry>;
  public appConfig: IApplicationConfig;
  public aggregates: AggregateDescriptor[];
  public columns: StringMap<PBJRecColumn>;
  public totals: StringMap<any>;

  @unsubscribe()
  private loadedEntriesSubscription: Subscription;
  @unsubscribe()
  private refreshGridSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private dateRangeSubscription: Subscription;
  @unsubscribe()
  private expandedDetailsSubscription: Subscription;
  @unsubscribe()
  private exportToPdfSubscription: Subscription;
  @unsubscribe()
  private exportToExcelSubscription: Subscription;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;

  private pbjRecEmps: PBJRecEmpEntry[];
  private orgLevel: OrgLevel;
  private dateRange: DateRange;

  constructor(private managementService: PbjReconciliationManagementService) {
    this.appConfig = appConfig;

    this.gridState = new KendoGridStateHelper<PBJRecEmpEntry>();
    this.gridState.state.sort = [{ field: 'empName', dir: 'asc' }];

    const rbjRecCols = new PBJRecColumns();
    this.columns = rbjRecCols.makeColumns();
    this.aggregates = rbjRecCols.makeAggregates();

    this.pbjRecEmps = null;
    this.totals = {};
  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.managementService.subscribeToOrgLevel((orgLevel: OrgLevel) => {
      this.orgLevel = orgLevel;
      this.loadData();
    });

    this.dateRangeSubscription = this.managementService.subscribeToReconOrglevel((data: PbjReconciliationOrglevel) => {
      this.dateRange = new DateRange(data.startDate, data.endDate);
      this.loadData();
    });

    this.expandedDetailsSubscription = this.managementService.subscribeToExpandedDetails((isOn: boolean) => {
      this.showDetails = isOn;
    });

    this.loadedEntriesSubscription = this.managementService.subscribeToLoadedEmpEntries((entries: PBJRecEmpEntry[]) => {
      this.pbjRecEmps = entries;
      this.refreshGrid();
    });

    this.exportToPdfSubscription = this.managementService.subscribeToExportToPdf(() => {
      this.grid.saveAsPDF();
    });

    this.exportToExcelSubscription = this.managementService.subscribeToExportToExcel(() => {
      this.grid.saveAsExcel();
    });

    this.refreshGridSubscription = this.gridState.onRefreshGrid.subscribe(() => {
      this.refreshGrid();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get fileName(): string {
    return `${_.get(this.orgLevel, 'name')} Employees Pbj Reconciliation.pdf`;
  }

  public get xlsxFileName(): string {
    return `${_.get(this.orgLevel, 'name')} Employees Pbj Reconciliation.xlsx`;
  }

  public defineRowClass(): (row: RowClassArgs) => string {
    return (row: RowClassArgs) => {
      return +row.dataItem.reconciliation.variance === 0 ? 'reconcil-row' : 'reconcil-row reconcil-highlighted';
    };
  }

  public onEmpClick(entry: PBJRecEmpEntry): void {
    this.managementService.navigateToReconciliationEmployee(entry.empId);
  }

  private loadData(): void {
    if (
      _.isNumber( _.get(this.orgLevel, 'id') ) &&
      _.isDate( _.get(this.dateRange, 'startDate') ) &&
      _.isDate( _.get(this.dateRange, 'endDate') )
    ) {
      this.managementService.loadEmpEntries(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate);
    }
  }

  private refreshGrid(): void {
    if (!this.pbjRecEmps) {
      this.gridState.view = null;

      return;
    }

    this.gridState.view = process(this.pbjRecEmps, this.gridState.state);
    this.totals = aggregateBy(this.gridState.view.data, this.aggregates);
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.gridState.view.data, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }
}

