/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-reminders-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/pipes/slx-time-from-now";
import * as i4 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i5 from "../../../common/components/spinner/spinner.component";
import * as i6 from "./header-reminders-list.component";
import * as i7 from "../../services/notifications/notifications-api.service";
import * as i8 from "../../../authentication/actions/session.actions";
var styles_HeaderRemindersListComponent = [i0.styles];
var RenderType_HeaderRemindersListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderRemindersListComponent, data: {} });
export { RenderType_HeaderRemindersListComponent as RenderType_HeaderRemindersListComponent };
function View_HeaderRemindersListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "list-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "subject-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(6, { "time-label": 0, "text-green": 1, "text-orange": 2, "text-red": 3 }), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "progress-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [], [[4, "width", "%"]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(13, { "progress-fill": 0, "progress-green": 1, "progress-orange": 2, "progress-red": 3 })], function (_ck, _v) { var currVal_1 = _ck(_v, 6, 0, true, _v.context.$implicit.isGreen, _v.context.$implicit.isOrange, _v.context.$implicit.isRed); _ck(_v, 5, 0, currVal_1); var currVal_4 = _ck(_v, 13, 0, true, _v.context.$implicit.isGreen, _v.context.$implicit.isOrange, _v.context.$implicit.isRed); _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.subject; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.dueDate)); _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.percent; _ck(_v, 10, 0, currVal_3); }); }
export function View_HeaderRemindersListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.TimeFromNowPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 9, "slx-spinner", [], null, null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i5.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "header-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "theme-exsm-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Subject"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "theme-exsm-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Due at"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderRemindersListComponent_1)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.reminders; _ck(_v, 10, 0, currVal_1); }, null); }
export function View_HeaderRemindersListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-header-reminders-list", [], null, null, null, View_HeaderRemindersListComponent_0, RenderType_HeaderRemindersListComponent)), i1.ɵdid(1, 114688, null, 0, i6.HeaderRemindersListComponent, [i7.NotificationsApiService, i8.SessionActions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderRemindersListComponentNgFactory = i1.ɵccf("slx-header-reminders-list", i6.HeaderRemindersListComponent, View_HeaderRemindersListComponent_Host_0, {}, {}, []);
export { HeaderRemindersListComponentNgFactory as HeaderRemindersListComponentNgFactory };
