import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { ConfigurationComponentHelper } from '../../../utils/configuration-component-helper';
import { CostCentersConfigurationManagementService } from '../../../services/index';
import { appConfig } from '../../../../app.config';
import { OrgLevelType } from '../../../../state-model/models/index';
import { NgForm } from '@angular/forms';
var CostCentersGridComponent = /** @class */ (function () {
    function CostCentersGridComponent(management) {
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
    }
    Object.defineProperty(CostCentersGridComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CostCentersGridComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    CostCentersGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: true,
            canAdd: true,
            canDelete: true
        };
        this.editSubscription = this.management.editItemCmd$.subscribe(function (item) {
            if (item) {
                _this.mainFormSubscription = _this.mainForm.statusChanges.subscribe(function () {
                    if (_this.mainForm.dirty) {
                        _this.management.markAsDirty();
                    }
                });
            }
            else {
                if (_this.mainFormSubscription) {
                    _this.mainFormSubscription.unsubscribe();
                }
            }
        });
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            _this.state = state;
            if (state.bulkEditMode) {
                if (_this.grid)
                    _this.gridState.closeEditor(_this.grid);
            }
            if (state.orgLevelChanged) {
                if (_this.grid)
                    _this.gridState.closeEditor(_this.grid);
                _this.currentOrgLevel = _this.management.currentOrgLevel;
                _this.notDepartment = _this.currentOrgLevel.type !== OrgLevelType.department;
            }
        });
        this.defaultsSubscription = this.management.defaultsInitialized$.subscribe(function () {
            _this.departments = _this.management.departments;
        });
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.gridState.editedRecord = null;
            _this.gridState.savedEditedRecord = null;
        });
        this.removeStartSubscription = this.management.removeItemsStartCmd$.subscribe(function () {
            if (_this.selectionHelper.selectionLength === 0) {
                _this.management.noSelectedItem();
            }
            else {
                _this.crudHelper.deleteSelected();
            }
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
    };
    CostCentersGridComponent.prototype.rowCallback = function (context) {
        if (context.dataItem.isDirty) {
            return { 'dirty-item': true };
        }
        return {};
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "removeStartSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "defaultsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "mainFormSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersGridComponent.prototype, "editSubscription", void 0);
    return CostCentersGridComponent;
}());
export { CostCentersGridComponent };
