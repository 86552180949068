<div class="dialog-modal-content">
  <div class="dialog-modal-body">
    <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height no-border" [data]="gridData">
      <kendo-grid-column title="Punch Type" field="punchType" width="120">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">{{ getPunchTypeName(dataItem.punchType)}}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Question" field="text" width="400" *ngIf="!isLegacy">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem"> {{ dataItem.textLine1 }}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Question 1" field="text" width="300" *ngIf="isLegacy">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem"> {{ dataItem.textLine1 }}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Question 2" field="text" width="300" *ngIf="isLegacy">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem"> {{ dataItem.textLine2 }}</ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Attestation Type" field="attestationTypeId" width="120">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">{{ getAttestationTypeName(dataItem.attestationTypeId) }} </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Enable" field="dataItem" width="80">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem"> {{ dataItem.enable === true
          ? 'Yes' : dataItem.enable === false ? 'No' :''}}</ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="dialog-modal-footer">
    <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
  </div>
</div>