import { Directive, Input, Output, EventEmitter, OnDestroy, Inject, Optional } from '@angular/core';
import { Router, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { Subscription } from 'rxjs/Subscription';

import { PageScrollService } from '../../services/page-scroll/page-scroll.service';
import { PageScrollInstance } from '../../services/page-scroll/page-scroll-instance';
import { PageScrollUtilService } from '../../services/page-scroll/page-scroll-util.service';
import { EasingLogic } from '../../models/page-scroll-config';
import { Assert } from '../../../framework/assert/assert';

@Directive({
  selector: '[pageScroll]',
  host: { // tslint:disable-line:use-host-property-decorator
    '(click)': 'handleClick($event)',
  }
})
export class PageScrollDirective implements OnDestroy {

  @Input()
  public routerLink: any;

  @Input()
  public href: string;

  @Input()
  public pageScrollOffset: number = null;

  @Input()
  public pageScrollDuration: number = null;

  @Input()
  public pageScrollEasing: EasingLogic = null;

  @Input()
  public pageScrollInterruptible: boolean;

  @Input()
  public pageScroll: string = null;

  @Output()
  public pageScrollFinish: EventEmitter<boolean> = new EventEmitter<boolean>();

  private pageScrollInstance: PageScrollInstance;
  private document: Document;
  private pageScrollService: PageScrollService;
  private router: Router;

  constructor(pageScrollService: PageScrollService, @Optional() router: Router, @Inject(DOCUMENT) document: any) {
    Assert.isNotNull(pageScrollService, 'pageScrollService');
    Assert.isNotNull(router, 'router');
    Assert.isNotNull(document, 'document');
    this.document = <Document> document;
    this.pageScrollService = pageScrollService;
    this.router = router;
  }

  public ngOnDestroy(): any {
    if (this.pageScrollInstance) {
      this.pageScrollService.stop(this.pageScrollInstance);
    }
    return undefined;
  }

  public handleClick(clickEvent: Event): boolean { // tslint:disable-line:no-unused-variable
    Assert.isNotNull(clickEvent, 'clickEvent');
    if (this.routerLink && this.router !== null && this.router !== undefined) {
      // We need to navigate their first.
      // Navigation is handled by the routerLink directive
      // so we only need to listen for route change
      // Note: the change event is also emitted when navigating to the current route again
      let subscription: Subscription = <Subscription>this.router.events.subscribe((routerEvent: any) => {
        if (routerEvent instanceof NavigationEnd) {
          subscription.unsubscribe();
          this.pageScrollService.start(this.generatePageScrollInstance());
        } else if (routerEvent instanceof NavigationError || routerEvent instanceof NavigationCancel) {
          subscription.unsubscribe();
        }
      });
    } else {
      this.pageScrollService.start(this.generatePageScrollInstance());
    }
    return false; // to preventDefault()
  }

  private generatePageScrollInstance(): PageScrollInstance {
    if (PageScrollUtilService.isUndefinedOrNull(this.pageScrollInstance)) {
      this.pageScrollInstance = PageScrollInstance.advancedInstance(
        this.document,
        this.href,
        null,
        this.pageScroll,
        this.pageScrollOffset,
        this.pageScrollInterruptible,
        this.pageScrollEasing,
        this.pageScrollDuration,
        this.pageScrollFinish
      );
    }
    return this.pageScrollInstance;
  }
}
