import * as _ from 'lodash';
import { OnInit, Provider } from '@angular/core';
import { DialogOptions, ModalService, DialogOptions2, DialogModeSize } from '../../../../../../common';
import { LookupType } from '../../../../../../organization/models';
import { LookupService } from '../../../../../../organization/services';
import { NewEnrollmentModel } from '../../../../models/employee-sections-benefits-management/new-enrollment.model';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../../services';
import { BenefitStatus } from '../../../../models';
var EnrollEmployeeSelectPlanPopupComponent = /** @class */ (function () {
    function EnrollEmployeeSelectPlanPopupComponent(model, apiService, options, modalService, lookupService) {
        this.model = model;
        this.apiService = apiService;
        this.options = options;
        this.modalService = modalService;
        this.lookupService = lookupService;
    }
    EnrollEmployeeSelectPlanPopupComponent.openDialog = function (model, apiService, modalService, lookupService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.width = 550;
        dialogOptions.height = 500;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: LookupService,
                useValue: lookupService
            },
            {
                provide: ModalService,
                useValue: modalService
            },
            {
                provide: NewEnrollmentModel,
                useValue: model
            },
            {
                provide: EmployeeSectionsBenefitsManagementApiService,
                useValue: apiService
            }
        ];
        var title = "Enroll Employee in Benefits";
        var component = modalService.globalAnchor.openDialog2(EnrollEmployeeSelectPlanPopupComponent, title, dialogOptions, resolvedProviders, callback);
        return component;
    };
    Object.defineProperty(EnrollEmployeeSelectPlanPopupComponent.prototype, "canEnrollInSelectedGroup", {
        //#region props
        get: function () {
            return !this.selectedGroup || (this.selectedGroup.allowMultipleEnrollment || this.hasNoActiveBenefitsFromSelectedGroup);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeSelectPlanPopupComponent.prototype, "hasNoActiveBenefitsFromSelectedGroup", {
        get: function () {
            if (this.selectedGroupPlans) {
                return !_.some(this.selectedGroupPlans, function (p) { return p.status === BenefitStatus.Enrolled; });
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeSelectPlanPopupComponent.prototype, "hasEnrolledOutOfEffectiveDate", {
        get: function () {
            if (this.selectedGroup && this.selectedGroup.allowMultipleEnrollment) {
                if (this.selectedPlan) {
                    return this.selectedPlan.enrolledOutEffectiveDate;
                }
                return false;
            }
            else {
                return _.some(this.selectedGroupPlans, function (p) { return p.enrolledOutEffectiveDate; });
            }
        },
        enumerable: true,
        configurable: true
    });
    EnrollEmployeeSelectPlanPopupComponent.prototype.planStatus = function (plan) {
        if (plan.enrolledOutEffectiveDate || (!this.selectedGroup.allowMultipleEnrollment && this.hasEnrolledOutOfEffectiveDate)) {
            return BenefitStatus.Ineligible;
        }
        return plan.status;
    };
    EnrollEmployeeSelectPlanPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.benefitGroups, employeeId: this.model.empId, isActive: true }).then(function (lookup) {
            _this.isLoading = false;
            _this.groups = lookup;
        });
    };
    EnrollEmployeeSelectPlanPopupComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EnrollEmployeeSelectPlanPopupComponent.prototype.onChangeGroup = function (g) {
        this.selectedGroup = g;
        this.selectedPlan = null;
        this.loadGroupPlans(this.model.empId, g.id);
    };
    EnrollEmployeeSelectPlanPopupComponent.prototype.onChangeSelectedPlan = function (plan) {
        this.selectedPlan = plan;
    };
    EnrollEmployeeSelectPlanPopupComponent.prototype.onEnroll = function () {
        this.model.group = this.selectedGroup;
        this.model.plan = this.selectedPlan;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EnrollEmployeeSelectPlanPopupComponent.prototype.loadGroupPlans = function (empId, groupId) {
        var _this = this;
        this.isLoading = true;
        this.selectedGroupPlans = [];
        this.apiService.getEmployeeActiveBenefitPlans(empId, groupId, this.model.effectiveDate)
            .then(function (plans) {
            _this.selectedGroupPlans = plans;
            _this.selectedGroupPlansToDisplay = _.filter(_this.selectedGroupPlans, function (p) {
                return p.status === BenefitStatus.Enrolled || p.enrolledOutEffectiveDate;
            });
            if (_this.selectedGroup.allowMultipleEnrollment || !_this.selectedGroupPlansToDisplay.length) {
                _this.selectedGroupPlansToDisplay = _.slice(_this.selectedGroupPlans, 0);
            }
            _this.isLoading = false;
        }).catch(function () {
            _this.isLoading = false;
        });
    };
    return EnrollEmployeeSelectPlanPopupComponent;
}());
export { EnrollEmployeeSelectPlanPopupComponent };
