import * as tslib_1 from "tslib";
import { Provider, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { process } from '@progress/kendo-data-query';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { DialogOptions2 } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { TimecardPredefinedComment } from '../../../../organization/models/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { TimecardAddCommentsReq } from '../../../models/daily-timecard/index';
import { appConfig } from '../../../../app.config';
var TimecardAddCommentsDialogComponent = /** @class */ (function () {
    function TimecardAddCommentsDialogComponent(options, modalService, req) {
        var _this = this;
        this.req = req;
        this.selectedMultipleComments = [];
        this.isLoading = true;
        this.subscriptions = {};
        this.modalService = modalService;
        this.options = options;
        this.timecardPredefinedComments = req.timecardPredefinedComments;
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.selectedMultipleComments = [];
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.selectedComment = _.first(records);
        });
        this.refreshGrid();
    }
    TimecardAddCommentsDialogComponent.openDialog = function (header, req, modalService, mode) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.height = 500;
        dialogOptions.width = 500;
        dialogOptions.fullHeightOnMobile = true;
        this.dialogMode = mode;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: TimecardAddCommentsReq,
                useValue: req
            }
        ];
        return new Promise(function (resolve) {
            var dialog = modalService.globalAnchor
                .openDialog2(TimecardAddCommentsDialogComponent, header, dialogOptions, resolvedProviders, function (result, uniqueId) {
                resolve({ comment: dialog.selectedComment ? dialog.selectedComment.description : '', isApply: result });
            });
        });
    };
    TimecardAddCommentsDialogComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimecardAddCommentsDialogComponent.prototype.refreshGrid = function () {
        if (!this.timecardPredefinedComments) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.timecardPredefinedComments.items, this.gridState.state);
        this.isLoading = false;
    };
    TimecardAddCommentsDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    TimecardAddCommentsDialogComponent.prototype.onOk = function () {
        if (this.selectedMultipleComments.length > 0) {
            var description = this.selectedMultipleComments.map(function (elem) {
                if (elem) {
                    return elem.description;
                }
            }).join("\n\n");
            this.selectedComment = new TimecardPredefinedComment();
            this.selectedComment.description = description;
        }
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    TimecardAddCommentsDialogComponent.prototype.selectionChange = function (event) {
        var _this = this;
        if (event.selectedRows.length > 0) {
            this.addedComment = event.selectedRows[0].dataItem;
            this.selectedMultipleComments.push(this.addedComment);
        }
        if (event.deselectedRows.length > 0) {
            this.addedComment = event.deselectedRows[0].dataItem;
            this.selectedMultipleComments = this.selectedMultipleComments.filter(function (item) { return item.id !== _this.addedComment.id; });
        }
    };
    Object.defineProperty(TimecardAddCommentsDialogComponent.prototype, "commentSelection", {
        get: function () {
            return TimecardAddCommentsDialogComponent.dialogMode;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], TimecardAddCommentsDialogComponent.prototype, "subscriptions", void 0);
    return TimecardAddCommentsDialogComponent;
}());
export { TimecardAddCommentsDialogComponent };
