<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
        <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
            <kendo-grid #kendoGrid class="slx-full-height"
              [data]="gridState?.view"
              [selectable]="{ mode: 'single' }"
              [filterable]="true"
              [filter]="gridState.state.filter"
              [sort]="gridState.state.sort"
              [sortable]="{ mode: 'multiple' }"
              (selectionChange)="gridState.selectionChange($event)"
              (dataStateChange)="gridState.dataStateChange($event)"
              (cancel)="gridState.cancelHandler($event)"
              (edit)="gridState.editHandler($event)"
              (remove)="gridState.removeHandler($event)"
              (save)="gridState.saveHandler($event)"
            >

                <!-- desktop view -->
                <kendo-grid-command-column *ngIf="!access.restrictedByOrgLevel" width="80" [locked]="true" media="(min-width: 450px)">
                    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
                        <div class="command-container">
                            <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
                                       <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                                      </button>
                            <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid" class="theme-icon-button theme-inline-apply-button">
                                    <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
                                   </button>
                            <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
                                    <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
                                   </button>
                        </div>
                    </ng-template>
                </kendo-grid-command-column>

                <kendo-grid-column title="Unit Description" field="description" [filterable]="true" width="250" media="(min-width: 450px)">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="left-align">{{ dataItem.description }}</span>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <slx-input-decorator>
                            <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.description" [required]="true" [placeholder]="" name="descriptionInput{{rowIndex}}"
                                [prohibitedValues]="prohibitedDescriptionValues">
                            <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                            <span errorMessage for="required"></span>
                        </slx-input-decorator>
                    </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                      </slx-kendo-grid-string-filter>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Unit Code" field="name" [filterable]="true" width="150" media="(min-width: 450px)">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="left-align">{{ dataItem.name }}</span>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <slx-input-decorator>
                            <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.name" [required]="true" [placeholder]="" [prohibitedValues]="prohibitedNameValues"
                                name="codeInput{{rowIndex}}" maxlength="20">
                            <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                            <span errorMessage for="required"></span>
                        </slx-input-decorator>
                    </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                      </slx-kendo-grid-string-filter>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Employees Count" field="employeesCount" [editable]="false" [filterable]="true" width="150" media="(min-width: 450px)">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="right-align">{{ dataItem.employeesCount }}</span>
                    </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
                      </slx-kendo-grid-number-filter>
                   </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Updated By" field="lastUpdateUsername" [editable]="false" [filterable]="true" width="200" media="(min-width: 450px)">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="left-align">{{ dataItem.lastUpdateUsername }}</span>
                    </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                      </slx-kendo-grid-string-filter>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column title="Last Updated" field="lastUpdateDate" filter="date" [editable]="false" [filterable]="true" width="200" media="(min-width: 450px)">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="left-align" *ngIf="dataItem.lastUpdateDate">{{ dataItem.lastUpdateDate | amDateFormat: appConfig.dateTimeFormat }}</span>
                    </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                      </slx-kendo-grid-date-filter>
                    </ng-template>
                </kendo-grid-column>

                <!-- mobile view -->
                <kendo-grid-column media="(max-width: 450px)" width="100%">
                    <ng-template kendoGridHeaderTemplate>Units</ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <slx-location-unit-renderer [rowIndex]="rowIndex" [item]="dataItem" (actionEmitter)="onMobileRendererEvent ($event, dataItem, rowIndex)"></slx-location-unit-renderer>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <slx-location-unit-editor [item]="dataItem" [rowIndex]="rowIndex" [prohibitedNameValues]="prohibitedNameValues" [prohibitedDescriptionValues]="prohibitedDescriptionValues"
                            (actionEmitter)="onMobileEditorEvent ($event, dataItem, rowIndex)">
                        </slx-location-unit-editor>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </form>

        <div class="actions-bar" [ngClass]="{'inactive': access.lockActions}">
            <button class="action-button" [slx-list-delete-operation]="onDeletedItems">Delete</button>
            <button class="action-button" [slx-list-add-operation]="onAddItem">Add</button>
        </div>
    </div>

</slx-spinner>
