import { Injectable } from '@angular/core';
import { configurationConfig } from '../../configuration.config';

import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Meta, ResponseBody } from '../../../core/models/index';
import { IPositionGroup, PositionGroup } from '../../../organization/models/index';
import { LookupMapService } from '../../../organization/services/index';

import { IPositionGroupView, PositionGroupView } from '../../models/index';

@Injectable()
export class ManageGroupsApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private lookupMapService: LookupMapService
  ) {}

  public assignGroupsToPositions(orgLevelId: number, groups: IPositionGroupView[]): Promise<any> {
    const url = `${this.getGroupsApiRoot()}/assign`;
    const request = this.urlParamsService.createPostRequest(url, groups, { orgLevelId });

    return this.apiUtilService.request(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
  }

  public createGroup(orgLevelId: number, group: PositionGroupView): Promise<PositionGroup> {
    const url = this.getGroupsApiRoot();
    const body = this.lookupMapService.mapPositionGroupDto(group);
    const request = this.urlParamsService.createPostRequest(url, body, { orgLevelId });

    return this.apiUtilService.request<IPositionGroup, Meta>(request)
      .then((response: ResponseBody<IPositionGroup, Meta>) => this.lookupMapService.mapPositionGroup(response.data));
  }

  public updateGroup(orgLevelId: number, group: PositionGroupView): Promise<PositionGroup> {
    const url = `${this.getGroupsApiRoot()}/${group.id}`;
    const body = this.lookupMapService.mapPositionGroupDto(group);
    const request = this.urlParamsService.createPutRequest(url, body, { orgLevelId });

    return this.apiUtilService.request<IPositionGroup, Meta>(request)
      .then((response: ResponseBody<IPositionGroup, Meta>) => this.lookupMapService.mapPositionGroup(response.data));
  }

  public deleteGroup(orgLevelId: number, groupId: number): Promise<any> {
    const url = `${this.getGroupsApiRoot()}/${groupId}`;
    const request = this.urlParamsService.createDeleteRequest(url, { orgLevelId });

    return this.apiUtilService.request(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
  }

  private getGroupsApiRoot(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.positions.positionsGroups}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}`;
  }
}
