import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { ColumnSettingsStorageService, ColumnManagementService, StateManagementService, ComponentStateStorageService } from '../../../../../common/services/index';

import { WcCommonManagementService, WcIncidentRosterManagementService, WcRosterToolbarService } from '../../../services/index';

@Component({
  selector: 'slx-wc-incident-roster',
  templateUrl: './wc-incident-roster.component.html',
  styleUrls: ['./wc-incident-roster.component.scss'],
  providers: [
    WcCommonManagementService,
    WcIncidentRosterManagementService,
    WcRosterToolbarService,
    ColumnManagementService,
    ColumnSettingsStorageService,
    StateManagementService,
    ComponentStateStorageService
  ]
})
export class WcIncidentRosterComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: WcIncidentRosterManagementService) {}

  public ngOnInit() {
    this.manService.init('WcIncidentRoster');

    this.subscriptions.onLoad = this.manService
      .subscribeToLoading((value: boolean) => (this.isLoading = value));
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }
}
