import { NavigationMenuItem } from '../../../../organization/models/navigation-menu-item';
import { Component, Input } from '@angular/core';
import { NavigationMenuRedirectService } from '../../../../organization';

@Component({
  moduleId: module.id,
  selector: 'slx-favorite-item',
  templateUrl: 'favorite-item.component.html',
  styleUrls: ['favorite-item.component.scss'],
})
export class FavoriteItemComponent {

  @Input()
  public menuItem: NavigationMenuItem;

  public get icon(): string {
    return this.menuItem && this.menuItem.menuItemMapping ? this.menuItem.menuItemMapping.icon : '';
  }

  constructor(private navService: NavigationMenuRedirectService) {
  }

  public open(): void {
    this.navService.open(this.menuItem);
  }
}
