import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { PageChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { screenUtils } from '../../../../../common/utils';
import { getAcaRelatedExportTypes, AcaExportType } from '../../../enums/aca-export-type';
import { ExportDataStatus } from '../../../enums/export-data-status';
import { InfoDialogComponent, ModalService, DialogOptions2, ConfirmDialog2Component } from '../../../../../common';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { AcaExportExecutionStatus } from '../../../models/aca-export/aca-export-execution-status';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { ConfirmOptions } from '../../../../../scheduler/components/individual-schedule/overlapping-schedule-popup/overlapping-schedule-popup.component';

@Component({
  selector: 'slx-aca-export-history',
  templateUrl: './aca-export-history.component.html',
  styleUrls: ['./aca-export-history.component.scss']
})
export class AcaExportHistoryComponent implements OnInit, OnDestroy {
  @Input() configuration: AcaExportConfiguration;

  public originalActions: AcaExportExecutionItem[];
  public pageSize: number = 10;
  public gridState: KendoGridStateHelper<AcaExportExecutionItem[]>;

  public appConfig: IApplicationConfig;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public messages: any = {
    isDownloadAvailable: 'Exports completed more than 1 years can not be downloaded'
  };

  private records: AcaExportExecutionItem[];

  public get exportDataStatus() { return ExportDataStatus; }
  public get acaExportType() {return AcaExportType;} 
  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  constructor(private manService: AcaExportManagementService,
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService
  ) {
    this.gridState = new KendoGridStateHelper<AcaExportExecutionItem[]>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.view = null;
    this.gridState.state.sort = [{ field: 'startedOn', dir: 'desc' }];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.subscriptions.loadedHistory = this.manService.subscribeToLoadedHistory((data: AcaExportExecutionItem[]) => {
      const relatedExports = getAcaRelatedExportTypes(this.configuration.exportType);

      data = _.filter(data, x => _.includes(relatedExports, x.type));

      if (!this.configuration.isCollapsed && this.configuration.history && this.configuration.history.length>0) {
        data = _.concat(data, this.configuration.history);
      }
      if (data.length === 0 || (this.configuration.isCollapsed && (!this.configuration.history || (this.configuration.history && this.configuration.history.length==0)))) {
        return;
      }

      this.configuration.history = data;
      if (this.configuration && this.gridState.state) {
        this.gridState.state.skip = this.configuration.pageSkip;
      }
      this.records = data;
      this.refreshGrid();
    });

    this.subscriptions.loadedStatuses = this.manService.subscribeToLoadedStatuses((data: AcaExportExecutionStatus[]) => {
      const relatedExports = getAcaRelatedExportTypes(this.configuration.exportType);

      data = _.filter(data, x => _.includes(relatedExports, x.exportType));

      _.each(data, x => {

        if (this.configuration.lastExecuted && this.configuration.lastExecuted.id === x.executionId) {
          this.configuration.lastExecuted.status = x.status;
          this.configuration.lastExecuted.reason = x.reason;
        }
        if (this.configuration.history) {
          const historyItem = _.find(this.configuration.history, y => y.id === x.executionId);
          if (historyItem) {
            historyItem.status = x.status;
            historyItem.reason = x.reason;
            if (x.status === ExportDataStatus.Completed || x.status === ExportDataStatus.Expired) {
              historyItem.completedOn = x.changedOn;
            }
          }
        }
      });
      if (this.configuration.history) {
        this.records = this.configuration.history;
        this.refreshGrid();
      }
    });
  }

  public ngOnDestroy(): void {
    this.records = null;
    this.configuration.history = null;
    this.changeDetector.detach();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public pageChange(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.configuration.pageSkip = event.skip;
    this.refreshGrid();
  }

  public filterChange(filter: any): void {
    _.forEach(this.records, (item) => {
      if (item.completedOn != null) {
        item.completedOn.setSeconds(0, 0);
      }
      if (item.startedOn != null) {
        item.startedOn.setSeconds(0, 0);
      }
    })
    this.gridState.state.filter = filter;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public isDownloadAvailable(data: AcaExportExecutionItem): boolean {
    if(ExportDataStatus.Completed)
    {
      return data.status === ExportDataStatus.Completed && data.completedOn < moment(data.completedOn).add(1, 'years').toDate();   
    }
    if(ExportDataStatus.Failed)
    {
      return data.status === ExportDataStatus.Failed && data.completedOn < moment(data.completedOn).add(1, 'years').toDate();   
    }
  }

  public downloadFile(data: AcaExportExecutionItem): void {  
    this.manService.downloadExportData(this.configuration.exportType, data.id); 
  }

  public showReasonInfoPopup(data: AcaExportExecutionItem): void {
    if (this.isMobile && data.reason) {
      InfoDialogComponent.OpenDialog('Information', data.reason, this.modalService);
    }
  }

  public showDownloadInfoPopup(data: AcaExportExecutionItem): void {
    if (this.isMobile && !this.isDownloadAvailable(data)) {
      InfoDialogComponent.OpenDialog('Information', this.messages.isDownloadAvailable, this.modalService);
    }
  }

  public showAcaExportCorrectionsPopup(data: AcaExportExecutionItem): void {
    if (data.type == AcaExportType.ExportXmlReplacement1095C) {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = true;
      options.showOK = true;
      ConfirmDialog2Component.openDialog(
        'Corrected Replacement XML',
        'You are attempting to correct an export while "Replacement?" is set to Yes. To export a Replacement filing, please instead use the Generate button from the Production XML (IRS Filing) section. Do you wish to continue correcting the selected export?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.openAcaCorrectionDialog(data);
          }
        }, options);
    }
    else {
      this.openAcaCorrectionDialog(data);
    }
  }

  private openAcaCorrectionDialog(data: AcaExportExecutionItem): void {
    let dataOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    dataOptions.dialogType = 1;
    dataOptions.acaExportExecutionItem = data;
    dataOptions.acaExportCorrectionType = new AcaExportCorrectionType();
    dataOptions.acaExportCorrectionType.receiptId = null;
    this.manService.openAcaExportDialog(dataOptions);
  }

}
