import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { appConfig } from '../../../app.config';
import { ReportDailyAttendanceConfigDialogComponent } from '../../components/report-configs-dialog/report-daily-attendance-config-dialog/report-daily-attendance-config-dialog.component';
import { ReportDailyTodayStaffConfigDialogComponent } from '../../components/report-configs-dialog/report-daily-today-staff-config-dialog/report-daily-today-staff-config-dialog.component';
import { ConfigForceLoad, ReportDefinition } from '../../models';
import { ReportConfigs } from '../../models/report-configs';
import { ModalService } from '../../../common/services/index';
import { ReportsManagementService } from '../../services';
import { NotificationsService } from '../../../core/components/index';

@Directive({
  selector: '[slxOpenReportConfig]',
})
export class ReportConfigsDialogDirective {
  @Input()
  public slxOpenReportConfig: ReportDefinition;
  public staffingForcedLoad: boolean = false;
  public attendanceForcedLoad: boolean = false;
  public configForceLoad: ConfigForceLoad = null;

  constructor(
    private modalService: ModalService,
    private reportsManagementService: ReportsManagementService,
    private notificationService: NotificationsService
  ) {
    this.configForceLoad = new ConfigForceLoad();
    this.configForceLoad.dailyAttendance = false;
    this.configForceLoad.todaysStaffing = false;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    switch (this.slxOpenReportConfig.reportConfigId) {
      case ReportConfigs.DailyAttendance: {
        const dialog = ReportDailyAttendanceConfigDialogComponent.openDialog(
          this.slxOpenReportConfig,
          this.configForceLoad,
          this.modalService,
          (isSave: boolean) => {
            if (isSave) {
              this.configForceLoad.dailyAttendance = true;
              this.reportsManagementService.saveAttendanceSettingsData(dialog.reportConfig);
              this.notificationService.success('Attendance Settings', 'Record saved successfully');
            } else {
              this.configForceLoad.dailyAttendance = false;
            }
          }
        );
        break;
      }
      case ReportConfigs.TodayStaff: {
        const dialog = ReportDailyTodayStaffConfigDialogComponent.openDialog(
          this.slxOpenReportConfig,
          this.configForceLoad,
          this.modalService,
          (isSave: boolean) => {
            if (isSave) {
              this.configForceLoad.todaysStaffing = true;
              this.reportsManagementService.saveStaffingSettingsData(dialog.reportConfig);
              this.notificationService.success('Staffing Settings', 'Record saved successfully');
            } else {
              this.configForceLoad.todaysStaffing = false;
            }
          }
        );
        break;
      }
      default: {
        throw new Error(`Report configuration has not been setup for  ${this.slxOpenReportConfig.displayName} .`);
      }
    }
  }
}
