import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { BenefitEligibilityRuleStatus } from '../../../models';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { BenefitDetailsEligibilityRuleDialogComponent } from '../benefit-details-eligibility-rule-dialog/benefit-details-eligibility-rule-dialog.component';
var BenefitDetailsEligibilityRulesListComponent = /** @class */ (function () {
    function BenefitDetailsEligibilityRulesListComponent(modalService) {
        this.modalService = modalService;
        this.benefitEligibilityRulesAdded = new EventEmitter();
        this.benefitEligibilityRulesUpdated = new EventEmitter();
        this.benefitEligibilityRulesDeleted = new EventEmitter();
    }
    Object.defineProperty(BenefitDetailsEligibilityRulesListComponent.prototype, "BenefitEligibilityRuleStatus", {
        get: function () {
            return BenefitEligibilityRuleStatus;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsEligibilityRulesListComponent.prototype.showUpdateEligibilityRuleDialog = function (ruleToUpdate) {
        var _this = this;
        if (!this.modalService || !ruleToUpdate) {
            return;
        }
        var rulesAlreadyUsedNamesWithoutCurrent = _.without(this.benefitRulesAlreadyUsedNames, ruleToUpdate.name);
        BenefitDetailsEligibilityRuleDialogComponent.openDialog(this.modalService, rulesAlreadyUsedNamesWithoutCurrent, this.orgLevelId, ruleToUpdate, !this.isEditMode, function (result, rule) {
            if (result) {
                if (rule.status === BenefitEligibilityRuleStatus.Added) {
                    _this.benefitEligibilityRulesAdded.next(rule);
                    return;
                }
                _this.benefitEligibilityRulesUpdated.next(rule);
            }
        });
    };
    BenefitDetailsEligibilityRulesListComponent.prototype.onDeleteRule = function (ruleId) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Delete Rule', 'Are you sure you want to delete the rule?', this.modalService, function (isDelete) {
            if (isDelete) {
                var rule = _.find(_this.benefitEligibilityRules, function (rule) { return rule.id === ruleId; });
                _this.benefitEligibilityRulesDeleted.next(rule);
            }
        }, options);
    };
    BenefitDetailsEligibilityRulesListComponent.prototype.trackByItem = function (index, item) {
        return item.id;
    };
    return BenefitDetailsEligibilityRulesListComponent;
}());
export { BenefitDetailsEligibilityRulesListComponent };
