import { IOpenShiftPositionDetails, OpenShiftPositionDetails } from "./open-shift-position-details";

export interface IOpenShiftPositionGroupDetails {
  positionGroup: string
  totalShifts:number;
  totalScheduled:number;
  totalHours:number;
  totalFTHead:number;
  totalPTHead:number;
  positionDetails: IOpenShiftPositionDetails[];
}

export class OpenShiftPositionGroupDetails {
  positionGroup: string
  totalShifts:number;
  totalScheduled:number;
  totalHours:number;
  totalFTHead:number;
  totalPTHead:number;
  positionDetails: OpenShiftPositionDetails[];
}
