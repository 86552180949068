import * as _ from 'lodash';
import { LinePunch, PunchDisplay, EmployeeDailyPunchesStatus, EmployeeDailyPunches, DailyPunchesShift, PunchesEventFilter } from '../../models/index';

export class PunchesFilterHelper {
  public filter(punches: LinePunch[], filter: PunchesEventFilter): void {
    _.forEach(punches, (punch: LinePunch) => {
      punch.show = true;
      if (!filter.empPunch && punch.punchDisplay === PunchDisplay.ValidPunch) {
        punch.show = false;
      }
      if (!filter.editPunch && punch.punchDisplay === PunchDisplay.ValidEdited) {
        punch.show = false;
      }
      if (!filter.invalidPunch && punch.punchDisplay === PunchDisplay.InvalidPunch) {
        punch.show = false;
      }
      if (!filter.schedule && punch.punchDisplay === PunchDisplay.Schedule) {
        punch.show = false;
      }
      if (!filter.essRequest && punch.punchDisplay === PunchDisplay.PunchRequest) {
        punch.show = false;
      }    
      if (!filter.invalidLogin && punch.punchDisplay === PunchDisplay.InvalidLogin) {
        punch.show = false;
      }  
    });
  }
}
