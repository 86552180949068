/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pm-review-attachment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../common/directives/popover/popover.directive";
import * as i4 from "../../../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i5 from "../../../../../common/components/popover-content/popover-content.component.ngfactory";
import * as i6 from "../../../../../common/components/popover-content/popover-content.component";
import * as i7 from "../../../services/pm-attachment-management.service";
import * as i8 from "../../../../../core/services/session/session.service";
import * as i9 from "./pm-review-attachment.component";
import * as i10 from "../../../services/pm-creation-management.service";
var styles_PmReviewAttachmentComponent = [i0.styles];
var RenderType_PmReviewAttachmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PmReviewAttachmentComponent, data: {} });
export { RenderType_PmReviewAttachmentComponent as RenderType_PmReviewAttachmentComponent };
function View_PmReviewAttachmentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "flex align-items__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "attachments__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload Files"])), (_l()(), i1.ɵeld(3, 0, null, null, 5, "span", [["class", "attachments__btn-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon"], ["type", "button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-paperclip slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Browse..."])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "input", [["class", "attachments__input"], ["multiple", ""], ["type", "file"]], [[8, "disabled", 0], [8, "accept", 0]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onResetErrors($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "flex__grow attachments__types"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["Allowed file types: ", ".", " max"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.disabled; var currVal_2 = _co.acceptedFileTypesInputFormat; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _co.acceptedFileTypesReadable; var currVal_4 = _co.maxFileSize; _ck(_v, 10, 0, currVal_3, currVal_4); }); }
function View_PmReviewAttachmentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["You selected more than ", " files"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxFiles; _ck(_v, 1, 0, currVal_0); }); }
function View_PmReviewAttachmentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Unable to upload file. Max size for files is ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxFileSize; _ck(_v, 1, 0, currVal_0); }); }
function View_PmReviewAttachmentComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to upload file. Allowed types: Word, Excel, PDF and image files."]))], null, null); }
function View_PmReviewAttachmentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "slx-error-block attachments__error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.maxFiles; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors.maxFileSize; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.errors.fileType; _ck(_v, 6, 0, currVal_2); }, null); }
function View_PmReviewAttachmentComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fal fa-trash-alt attachments__i-delete"], ["title", "Delete file immediately"]], null, [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOrHideOnClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showOnMouseover() !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).hideOnMouseout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.PopoverDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { content: [0, "content"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 9); _ck(_v, 1, 0, currVal_0); }, null); }
function View_PmReviewAttachmentComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "span", [["class", "flex flex__grow attachments__file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-file-download attachments__i-file"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadFile(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "tooltip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "flex__grow attachments__name download-link"], ["tipPosition", "top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadFile(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 4341760, null, 0, i4.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "popover-content", [["placement", "auto bottom"], ["title", "Are you sure you want to delete attachment?"]], null, [[null, "mouseenter"], [null, "mouseleave"], ["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).showOnMouseEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).hideOnMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).onDocumentMouseDown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_PopoverContentComponent_0, i5.RenderType_PopoverContentComponent)), i1.ɵdid(9, 4243456, [["confirmDeleting", 4]], 0, i6.PopoverContentComponent, [i1.ElementRef, i1.ChangeDetectorRef], { placement: [0, "placement"], title: [1, "title"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "button", [["class", "slx-button slx-blue slx-narrow slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete(true, i1.ɵnov(_v, 9), _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "button", [["class", "slx-button slx-narrow"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete(false, i1.ɵnov(_v, 9), _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.name; var currVal_1 = "top"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = (!_co.disabled && _co.canDelete); _ck(_v, 7, 0, currVal_3); var currVal_4 = "auto bottom"; var currVal_5 = "Are you sure you want to delete attachment?"; _ck(_v, 9, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.fileName; _ck(_v, 5, 0, currVal_2); }); }
function View_PmReviewAttachmentComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fal fa-trash-alt attachments__i-delete"], ["title", "Delete file immediately"]], null, [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOrHideOnClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showOnMouseover() !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).hideOnMouseout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.PopoverDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { content: [0, "content"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 9); _ck(_v, 1, 0, currVal_0); }, null); }
function View_PmReviewAttachmentComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "span", [["class", "flex flex__grow attachments__file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-file attachments__i-file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "tooltip-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "flex__grow attachments__name"], ["tipPosition", "top"]], null, null, null, null, null)), i1.ɵdid(4, 4341760, null, 0, i4.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_11)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "popover-content", [["placement", "auto bottom"], ["title", "Are you sure you want to delete attachment?"]], null, [[null, "mouseenter"], [null, "mouseleave"], ["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).showOnMouseEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).hideOnMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).onDocumentMouseDown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_PopoverContentComponent_0, i5.RenderType_PopoverContentComponent)), i1.ɵdid(9, 4243456, [["confirmDeletingFile", 4]], 0, i6.PopoverContentComponent, [i1.ElementRef, i1.ChangeDetectorRef], { placement: [0, "placement"], title: [1, "title"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 1, "button", [["class", "slx-button slx-blue slx-narrow slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete(true, i1.ɵnov(_v, 9), _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "button", [["class", "slx-button slx-narrow"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete(false, i1.ɵnov(_v, 9), _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.name; var currVal_1 = "top"; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_3 = (!_co.disabled && _co.canDelete); _ck(_v, 7, 0, currVal_3); var currVal_4 = "auto bottom"; var currVal_5 = "Are you sure you want to delete attachment?"; _ck(_v, 9, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.fileName; _ck(_v, 5, 0, currVal_2); }); }
function View_PmReviewAttachmentComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "attachments__file-wrapper flex align-items__center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_10)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "flex__shrink added-col added-by"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.allowDownload && _v.context.$implicit.isAttachment); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.allowDownload || !_v.context.$implicit.isAttachment); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.addedBy; _ck(_v, 6, 0, currVal_2); }); }
function View_PmReviewAttachmentComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "attachments__attached"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "flex__grow attachments__file-name-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File Name"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "flex__shrink added-col attachments__added-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Added By"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_7)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 8, 0, currVal_0); }, null); }
function View_PmReviewAttachmentComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "attachments__no-files"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No attached files"]))], null, null); }
export function View_PmReviewAttachmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "attachments"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmReviewAttachmentComponent_12)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allowUpload; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.hasFiles; _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.hasFiles; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_PmReviewAttachmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-pm-review-attachment", [], null, null, null, View_PmReviewAttachmentComponent_0, RenderType_PmReviewAttachmentComponent)), i1.ɵprd(512, null, i7.PmAttachmentManagementService, i7.PmAttachmentManagementService, [i8.SessionService]), i1.ɵdid(2, 245760, null, 0, i9.PmReviewAttachmentComponent, [i10.PmCreationManagementService, i7.PmAttachmentManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PmReviewAttachmentComponentNgFactory = i1.ɵccf("slx-pm-review-attachment", i9.PmReviewAttachmentComponent, View_PmReviewAttachmentComponent_Host_0, { allowUpload: "allowUpload", allowDownload: "allowDownload", disabled: "disabled", canDelete: "canDelete", attachments: "attachments" }, { addedFiles: "addedFiles", deletedFile: "deletedFile", deletedAttachment: "deletedAttachment", downloadAttachment: "downloadAttachment" }, []);
export { PmReviewAttachmentComponentNgFactory as PmReviewAttachmentComponentNgFactory };
