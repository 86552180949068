var EarningStatistic = /** @class */ (function () {
    function EarningStatistic() {
    }
    Object.defineProperty(EarningStatistic.prototype, "intervalMin", {
        get: function () {
            return this.interval / 60;
        },
        enumerable: true,
        configurable: true
    });
    return EarningStatistic;
}());
export { EarningStatistic };
