var IdealSchedulePositionDefinition = /** @class */ (function () {
    function IdealSchedulePositionDefinition() {
        // technical properties
        this.isEditableMode = false;
        this.isChecked = false;
        this.isInvalidConfigType = false;
        this.isInvalidCensusType = true;
        this.idealScheduleId = 0;
    }
    Object.defineProperty(IdealSchedulePositionDefinition.prototype, "hasChanges", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    IdealSchedulePositionDefinition.prototype.getTargetHours = function () {
        return this.targetHours;
    };
    IdealSchedulePositionDefinition.prototype.getTotalHours = function () {
        return this.totalHours;
    };
    return IdealSchedulePositionDefinition;
}());
export { IdealSchedulePositionDefinition };
