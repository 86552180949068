export interface IScheduleDailyDetails {
  workDate: string;
  orgLevelId: number;
  employeeId: number;
  employeeName: string;
  shiftGroupId: number;
  shiftGroupName: string;
  shiftId: number;
  shiftName: string;
  unitId: number;
  unitName: string;
  positionId: number;
  positionName: string;
  hours: number;
}

export class ScheduleDailyDetails {
  public workDate: Date;
  public orgLevelId: number;
  public employeeId: number;
  public employeeName: string;
  public shiftGroupId: number;
  public shiftGroupName: string;
  public shiftId: number;
  public shiftName: string;
  public unitId: number;
  public unitName: string;
  public positionId: number;
  public positionName: string;
  public hours: number;
}
