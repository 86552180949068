import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { EmpScrollSection } from '../models/index';

@Injectable()
export class EmpScrollService {
  private added$: Subject<EmpScrollSection>;
  private active$: Subject<EmpScrollSection>;
  private preactive$: Subject<EmpScrollSection>;
  private update$: Subject<null>;
  private reset$: Subject<null>;
  private suspended$: Subject<boolean>;
  private sections: EmpScrollSection[];

  constructor() {
    this.added$ = new Subject<EmpScrollSection>();
    this.active$ = new Subject<EmpScrollSection>();
    this.preactive$ = new Subject<EmpScrollSection>();
    this.update$ = new Subject<null>();
    this.reset$ = new Subject<null>();
    this.suspended$ = new Subject<boolean>();
    this.sections = [];
  }

  public addSection(section: EmpScrollSection): void {
    this.sections.push(section);
    this.added$.next(section);
  }

  public getSections(): EmpScrollSection[] {
    return this.sections.concat();
  }

  public changeActive(section: EmpScrollSection): void {
    if (section && section.id) {
      this.active$.next(section);
    }
  }

  public changePreactive(section: EmpScrollSection): void {
    if (section && section.id) {
      this.preactive$.next(section);
    }
  }

  public updateScroll(): void {
    this.update$.next();
  }

  public resetScroll(): void {
    this.sections.length = 0;
    this.reset$.next(null);
  }

  public suspended(isSuspended: boolean): void {
    this.suspended$.next(isSuspended);
  }

  public subscribeToAdded(callback: (sec: EmpScrollSection) => void): Subscription {
    return this.added$.subscribe(callback);
  }

  public subscribeToActive(callback: (sec: EmpScrollSection) => void): Subscription {
    return this.active$.subscribe(callback);
  }

  public subscribeToPreactive(callback: (sec: EmpScrollSection) => void): Subscription {
    return this.preactive$.subscribe(callback);
  }

  public subscribeToUpdateScroll(callback: (afterReset: boolean) => void): Subscription {
    return this.update$.subscribe(callback);
  }

  public subscribeToResetScroll(callback: () => void): Subscription {
    return this.reset$.subscribe(callback);
  }

  public subscribeToSuspendScroll(callback: (isPaused: boolean) => void): Subscription {
    return this.suspended$.subscribe(callback);
  }
}
