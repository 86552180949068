import { OnInit } from '@angular/core';
import { ReportParameter } from '../../../models/index';
import { ReportsManagementService } from '../../../services/index';
import { LookupType } from '../../../../organization/models/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
var ReportParameterComponent = /** @class */ (function () {
    function ReportParameterComponent(reportsManagementService, appSettingsManageService) {
        this.reportsManagementService = reportsManagementService;
        this.appSettingsManageService = appSettingsManageService;
        this.numberOfHrs = 8;
    }
    Object.defineProperty(ReportParameterComponent.prototype, "orgLevel", {
        get: function () {
            return this.reportsManagementService.selectedOrgLevel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportParameterComponent.prototype, "dataTypeName", {
        get: function () {
            if (this.parameter && this.parameter.dataType)
                return this.parameter.dataType.name;
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportParameterComponent.prototype, "isPayCycle", {
        get: function () {
            if (this.parameter && this.parameter.dataType) {
                return this.parameter.dataType.lookupName === LookupType.payCycles;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    ReportParameterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appSettingsManageService.getAppServerConfig().then(function (config) {
            _this.IsPBJConsecutiveHrsEnabled = config.IsPBJConsecutiveHrsEnabled;
        });
    };
    return ReportParameterComponent;
}());
export { ReportParameterComponent };
