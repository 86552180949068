<div class="groups-and-filters">
  <div class="legend">Groups and filters</div>
  <div>
    <div class="btn-group" *ngFor="let column of restrictedColumns | filter: getGroupingFilter()">
      <button type="button" class="btn btn-default">{{column}}</button>
      <div class="btn-group">
        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          [class.btn-danger]="isFilter(column)">
            <i class="fa fa-edit" aria-hidden="true"></i>
        </button>
        <ul class="dropdown-menu">
          <li *ngFor="let lookupEntity of filterLookups[column] | async">
            <a (click)="addFilter(column, lookupEntity.id)">
              <i class="fa fa-check" aria-hidden="true" *ngIf="isFilteredBy(column, lookupEntity.id)"></i> {{lookupEntity.name}}
            </a>
          </li>
        </ul>
      </div>
      <button type="button" class="btn btn-default" (click)="removeGroupAndFilter(column)">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>

  <div class="groups-and-filters-actions">
    <button type="button" class="btn btn-default" (click)="restoreDefaultFiltersAndGroupings()">
      <i class="fa fa-undo" aria-hidden="true"></i>
    </button>
    <div class="btn-group" *ngIf="(restrictedColumns | filter: getAddGroupFilter()).length">
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-plus" aria-hidden="true"></i>
      </button>
      <ul class="dropdown-menu">
        <li *ngFor="let column of restrictedColumns | filter: getAddGroupFilter()">
          <a (click)="addGroup(column)">{{column}}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
