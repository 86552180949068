import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { SessionService } from '../../../../app/core/services';
import { ScheduleEntryManagementService } from '../../services';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import { appConfig } from './../../../app.config';
import { ModalService } from './../../../common/services/index';
import { mutableSelect, unsubscribe } from './../../../core/decorators/index';
var ShiftRequestPopupComponent = /** @class */ (function () {
    function ShiftRequestPopupComponent(
    // options: DialogOptions,
    modalService, sessionService, management, shiftRequestService) {
        this.sessionService = sessionService;
        this.management = management;
        this.shiftRequestService = shiftRequestService;
        this.selectedShiftDetails = new EventEmitter();
        this.storeSelectedDataArr = [];
        this.isAddDisabled = false;
        this.positionMappingError = true;
        this.shiftStarDateError = true;
        this.loader = false;
        // this.orgdetailsData = this.or
        this.modalService = modalService;
        // this.options = options;
    }
    ShiftRequestPopupComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        var constUserDetails = this.sessionService.getUser();
        this.userName = constUserDetails.name;
        this.userId = constUserDetails.id;
        this.alias = this.sessionService.getAlias();
    };
    Object.defineProperty(ShiftRequestPopupComponent.prototype, "getcurrentOffset", {
        get: function () {
            return ShiftRequestPopupComponent.currentOffset;
        },
        enumerable: true,
        configurable: true
    });
    ShiftRequestPopupComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        //console.info('Destroy Add Employee popup');
    };
    ShiftRequestPopupComponent.prototype.ngOnChanges = function () {
    };
    ShiftRequestPopupComponent.prototype.getShiftDetails = function (data) {
        this.selectedShiftDetails.emit(data);
    };
    ShiftRequestPopupComponent.prototype.onAdd = function () {
        var _this = this;
        this.loader = true;
        this.isAddDisabled = false;
        this.dialogResult = true;
        var orgLevelId = this.orgLevelId;
        this.management.postRequestStaffing(this.storeSelectedDataArr, orgLevelId).then(function (status) {
            _this.shiftRequestService.sendclickEvent();
            _this.loader = false;
            // this.modalService.closeWindow(this.options.windowUniqueId);
        }).catch(function (err) {
            _this.loader = false;
        });
    };
    ShiftRequestPopupComponent.prototype.validateAddbutton = function (positionError, shiftStartDateError) {
        if (this.storeSelectedDataArr && this.storeSelectedDataArr.length > 0 && !positionError && !shiftStartDateError) {
            this.isAddDisabled = true;
        }
        else {
            this.isAddDisabled = false;
        }
    };
    ShiftRequestPopupComponent.prototype.gridSelectionChange = function (data) {
        var _this = this;
        this.storeSelectedDataArr = [];
        var orgdetailsId = this.orgLevelId;
        var parent_id = this.orgLevelId;
        data.forEach(function (elem, i) {
            var seletedAgency = data[i].SeletedAgency;
            var startDateTime = new Date(moment(_this.requestedDate).format('MM/DD/YYYY') + ' ' + data[i].ShiftStart);
            var endDateTime = new Date(moment(_this.requestedDate).format('MM/DD/YYYY') + ' ' + data[i].ShiftEnd);
            var storeSelectedData = {};
            storeSelectedData.Clientid = _this.alias;
            storeSelectedData.SlxshiftId = data[i].ShiftId;
            storeSelectedData.SlxshiftGroupId = data[i].ShiftGroupId;
            storeSelectedData.PositionGroupId = data[i].PositionGroupId;
            storeSelectedData.JobCode = data[i].JobCode;
            storeSelectedData.JobDesc = data[i].JobDescription;
            storeSelectedData.ShiftDate = moment(_this.requestedDate).format('MM/DD/YYYY');
            storeSelectedData.ShiftStart = moment(startDateTime).format('MM/DD/YYYY HH:mm:ss');
            storeSelectedData.ShiftEnd = moment(endDateTime).format('MM/DD/YYYY HH:mm:ss');
            storeSelectedData.OrgLevelId = orgdetailsId;
            storeSelectedData.UnitId = data[i].UnitId;
            storeSelectedData.DepartmentId = data[i].DepartmentId;
            storeSelectedData.Hours = data[i].Hours.toString();
            storeSelectedData.shiftRequestbyId = _this.userId;
            storeSelectedData.shiftRequestbyName = _this.userName;
            storeSelectedData.SubTypeId = seletedAgency.sub_type_id.toString();
            storeSelectedData.PartnerId = "0";
            storeSelectedData.SubscriptionName = seletedAgency.sub_type_name;
            storeSelectedData.parentId = parent_id;
            storeSelectedData.AgencyId = 0;
            storeSelectedData.PositionErrorMessage = data[i].PositionErrorMessage;
            storeSelectedData.ShiftStartDateErrorMessage = data[i].ShiftStartDateErrorMessage;
            if (data[i].AgencyList.length == 1) {
                storeSelectedData.AgencyList = data[i].AgencyList;
            }
            else {
                storeSelectedData.AgencyList = data[i].AgencyList.length > 0 && data[i].AgencyList.filter(function (e) { return e.isChecked; });
            }
            if (!moment(endDateTime).isAfter(startDateTime)) {
                var shitEndTime = data[i].ShiftEnd;
                var addDaytoWorkDate = moment(storeSelectedData.ShiftDate).add(1, 'days').format('MM/DD/YYYY');
                var nextday = new Date(addDaytoWorkDate + ' ' + shitEndTime);
                storeSelectedData.ShiftEnd = moment(nextday).format('MM/DD/YYYY HH:mm:ss');
            }
            if (storeSelectedData.AgencyList.length > 0) {
                _this.storeSelectedDataArr.push(storeSelectedData);
            }
        });
        if (this.storeSelectedDataArr.length > 0) {
            this.positionMappingError = this.storeSelectedDataArr.some(function (e) { return !e.PositionErrorMessage; });
            this.shiftStarDateError = this.storeSelectedDataArr.some(function (e) { return e.ShiftStartDateErrorMessage; });
        }
        this.validateAddbutton(this.positionMappingError, this.shiftStarDateError);
    };
    ShiftRequestPopupComponent.prototype.onPositionMappingList = function () {
        this.shiftRequestService.positionsClickEvent();
        // this.modalService.closeWindow(this.options.windowUniqueId);
    };
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftRequestPopupComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftRequestPopupComponent.prototype, "userSubscription", void 0);
    return ShiftRequestPopupComponent;
}());
export { ShiftRequestPopupComponent };
