import * as uuid from 'uuid';
import * as _ from 'lodash';
import { BenefitClassDefinition } from '../../../../organization/models/index';

export class BenefitEligibilityRuleModel {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly ruleStatements: BenefitEligibilityRuleStatementGroupItem[];

  constructor({ name, description, ruleStatements }: Partial<BenefitEligibilityRuleModel> = {}) {
    this.name = name || '';
    this.description = description || '';
    this.ruleStatements = ruleStatements || [];
  }
}

export class BenefitEligibilityRuleStatementGroupItem {
  readonly id: number;
  public sequence: number;
  readonly variable: BenefitEligibilityRuleVariable;
  readonly groups: BenefitEligibilityRuleStatementGroupItem[];

  conditionType: BenefitEligibilityRuleConditionType;
  itemSelected: boolean;

  readonly isGroupItem: boolean;

  constructor({
    id,
    sequence,
    conditionType,
    variable,
    groups,
    itemSelected,
  }: Partial<BenefitEligibilityRuleStatementGroupItem> = {}) {
    this.id = id || uuid.v4(); // TODO
    this.sequence = sequence || 0;
    this.conditionType = conditionType || null;
    this.variable = variable || null;
    this.groups = groups || null;
    this.isGroupItem = !_.isEmpty(groups);
    this.itemSelected = itemSelected || false;
  }
}

export class BenefitEligibilityRuleVariable {
  readonly variableId: string;
  readonly operatorType: string;
  readonly valueType: string;
  readonly value: string | string[];
  readonly dataType: BenefitEligibilityRuleDataType;
  readonly predicateEnum?: string;

  constructor({
    variableId,
    operatorType,
    valueType,
    value,
    dataType,
    predicateEnum,
  }: Partial<BenefitEligibilityRuleVariable> = {}) {
    this.variableId = variableId || null;
    this.operatorType = operatorType || null;
    this.valueType = valueType || null;
    this.value = value || null;
    this.dataType = dataType || null;
    this.predicateEnum = predicateEnum || null;
  }
}

export enum BenefitEligibilityRuleConditionType {
  AND = 'AND',
  OR = 'OR',
}

export const benefitEligibilityRuleConditions = [
  BenefitEligibilityRuleConditionType.AND,
  BenefitEligibilityRuleConditionType.OR,
];

export enum BenefitEligibilityRuleBooleanType {
  False = 'FALSE',
  True = 'TRUE',
}

export const benefitEligibilityRuleBooleanTypes = [
  BenefitEligibilityRuleBooleanType.False,
  BenefitEligibilityRuleBooleanType.True,
];

export class BenefitEligibilityRulePayType {
  readonly id: number;
  readonly name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export const benefitEligibilityRulePayTypes = [
  new BenefitEligibilityRulePayType(0, 'Hourly'),
  new BenefitEligibilityRulePayType(1, 'Salaried'),
];

export enum BenefitEligibilityRuleVariableEnum {
  BenefitClass = 'BenefitClass',
  PayType = 'PayType',
}

export enum BenefitEligibilityRulePredicateEnum {
  Between = 'Between',
  In = 'In',
  NotIn = 'NotIn',
  EqualTo = 'EqualTo',
  NotEqualTo = 'NotEqualTo',
  GreaterThan = 'GreaterThan',
  GreaterThanEqualTo = 'GreaterThanEqualTo',
  LesserThan = 'LesserThan',
  LesserThanEqaulTo = 'LesserThanEqaulTo',
}

export enum BenefitEligibilityRuleDataType {
  String = 'String',
  Integer = 'Integer',
  Decimal = 'Decimal',
  Boolean = 'Boolean',
  DateTime = 'DateTime',
}
