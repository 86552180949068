import { Injectable } from '@angular/core';

import { wcConfig } from '../../workers-compensation.config';
import { Meta } from '../../../../core/models/api/meta';
import { ApiUtilService } from '../../../../common/index';
import { UrlParamsService } from '../../../../common/services/url-params/url-params.service';
import { SlxHttpRequest } from '../../../../core/models/index';
import { ResponseBody } from '../../../../core/models/api/response-body';
import { WCTMapService } from './workers-compensation-table-map.service';
import { WorkCompTableTypeModel, WorkCompTableEditResult, IWorkCompTableEditResult, IWorkerCompTableModel} from '../../models/index';

@Injectable()
export class WorkersCompensationTableApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private mapService: WCTMapService,
    private urlParamsService: UrlParamsService
  ) {
  }

  public async getWrkrsCompTableModels(orgLevelId: number): Promise<WorkCompTableTypeModel[]> {
    const url: string = this.getWrkrsCompTableApi();
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { orgLevelId });

    const response = await this.apiUtilService.requestNew<IWorkerCompTableModel[], Meta>(request);
    return this.mapService.mapWCTModelContainer(response.data);

  } 

  public async createcodeDefinition(req: WorkCompTableTypeModel): Promise<WorkCompTableEditResult> {
    const url: string = this.getWrkrsCompTableApi();
    const body = this.mapService.mapWorkrCompTableModelToDto(req);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<IWorkCompTableEditResult, Meta>(request)
      .then((response: ResponseBody<IWorkCompTableEditResult, Meta>) => this.mapService.mapCodeDefinitionEditResult(response.data));
  }

  public async modifycodeDefinition(req: WorkCompTableTypeModel): Promise<WorkCompTableEditResult> {
    const url: string = this.getWrkrsCompTableApi();
    const body = this.mapService.mapWorkrCompTableModelToDto(req);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<IWorkCompTableEditResult, Meta>(request)
      .then((response: ResponseBody<IWorkCompTableEditResult, Meta>) => this.mapService.mapCodeDefinitionEditResult(response.data));
  }

  public async deleteCodeDefinition(id: number): Promise<WorkCompTableEditResult> {
    const url: string = this.getWrkrsCompTableApi() + '/' + id;
    const request = this.urlParamsService.createDeleteRequest(url);

    return this.apiUtilService
      .request<IWorkCompTableEditResult, Meta>(request)
      .then((response: ResponseBody<IWorkCompTableEditResult, Meta>) => this.mapService.mapCodeDefinitionEditResult(response.data));
  }

  public async getCodeTypes(): Promise<any[]> {
    const url: string = `${this.getWrkrsCompTableApi()}/${wcConfig.api.configuration.codeTypes.root}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<any[], Meta>(request);
    return this.mapService.mapCodeTypes(response.data);
  } 

  private getWrkrsCompTableApi(): string {
    return `${this.apiUtilService.getApiRoot()}/${wcConfig.api.configuration.root}/${wcConfig.api.configuration.wccode.root}`;
  }

}
