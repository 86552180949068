import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { PayrollExportManagementService } from '../../../services/index';
var PayrollExportHeaderComponent = /** @class */ (function () {
    function PayrollExportHeaderComponent(managementService) {
        this.managementService = managementService;
    }
    PayrollExportHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.load();
            }
        });
    };
    PayrollExportHeaderComponent.prototype.onPayCycleSelected = function (payCycle) {
        if (this.currentPayCycle
            && moment(this.currentPayCycle.startDate).isSame(payCycle.startDate)
            && moment(this.currentPayCycle.endDate).isSame(payCycle.endDate)) {
            return;
        }
        this.currentPayCycle = payCycle;
        this.load();
    };
    PayrollExportHeaderComponent.prototype.load = function () {
        if (!this.orgLevel.id || !this.currentPayCycle) {
            return;
        }
        this.managementService.loadPayrollExport(this.orgLevel, this.currentPayCycle.startDate, this.currentPayCycle.endDate);
    };
    PayrollExportHeaderComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PayrollExportHeaderComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportHeaderComponent.prototype, "orgLevelSubscription", void 0);
    return PayrollExportHeaderComponent;
}());
export { PayrollExportHeaderComponent };
