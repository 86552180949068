export interface IOpenShiftPositionDetails {
  position: string;
  shifts: number;
  scheduled: number;
  hoursGap: number;
  ftEsNeeded: number;
  ftHeadCount: number;
  ptHeadCount: number;
  shiftGroupDesc: string;
  shiftGroup: string;
}

export class OpenShiftPositionDetails {
  public position: string;
  public shifts: number;
  public scheduled: number;
  public hoursGap: number;
  public ftEsNeeded: number;
  public ftHeadCount: number;
  public ptHeadCount: number;
  public shiftGroupDesc: string;
  public shiftGroup: string;
}
