<form novalidate #form="ngForm">
  <div *ngIf="terminationEntries?.length==0">
    There are no Termination History records.
  </div>
  <div *ngIf="terminationEntries?.length>0">

    <div class="col-xs-12" *ngFor="let terminationEntry of terminationEntries; let i=index">
      <div class="col-xs-12">
        <div class="flex-container">
          <h4 class=" flex-child brcramp">Termination Date</h4>
          <span class="flex-child">
            {{terminationEntry?.terminationDate.fieldValue | date:'MM/dd/yyyy'}}
          </span>
        </div>
      </div>
        <div class="col-sm-6 col-lg-3">
          <slx-input-decorator>
            <slx-dropdown-input slx-input required 
            lookup="employeeTerminationReason2" 
            [lookup]="terminateReasons" 
            [readonly]="!state.isEditMode" 
            [inputPolicy]="terminationEntry?.reason.Policy" name="employeeTerminationReason{{i}}" placeholder="Reason"
             [(ngModel)]="terminationEntry?.reason.fieldValue">
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
      <div class="col-sm-4 col-lg-2">
        <slx-input-decorator className="slx-no-border">
          <slx-checkbox-input slx-input [readonly]="!state.isEditMode" name="doNotRehire{{i}}"
            fieldName="doNotRehire{{i}}" placeholder="Do Not Rehire" caption=""
            [inputPolicy]="terminationEntry?.doNotRehire.Policy" [(ngModel)]="terminationEntry?.doNotRehire.fieldValue">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-sm-4 col-lg-2">
        <slx-input-decorator className="slx-no-border">
          <slx-checkbox-input slx-input [readonly]="!state.isEditMode" name="voluntaryTerm{{i}}"
            fieldName="voluntaryTerm{{i}}" placeholder="Voluntary Term" caption=""
            [inputPolicy]="terminationEntry?.voluntaryTerm.Policy"
            [(ngModel)]="terminationEntry?.voluntaryTerm.fieldValue">
          </slx-checkbox-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-sm-6 col-lg-2">
        <slx-input-decorator>
          <input slx-input type="text" readonly="true" name="terminatedBy{{i}}" placeholder="Termination by"
            [(ngModel)]="terminationEntry?.terminatedBy.fieldValue">
        </slx-input-decorator>
      </div>
      <div class="col-xs-12" *ngIf="state.isEditMode">
        <slx-input-decorator>
          <input slx-input type="text" required="true" [readonly]="!state.isEditMode" name="notes{{i}}" placeholder="Notes" [inputPolicy]="terminationEntry?.notes.Policy"
            [(ngModel)]="terminationEntry?.notes.fieldValue">
            <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>

      <div class="col-xs-12" *ngIf="!state.isEditMode">
        <slx-input-decorator>
          <input slx-input type="text" required="true" [readonly]="!state.isEditMode" name="notes{{i}}" placeholder="Notes"
            [inputPolicy]="terminationEntry?.notes.Policy" [(ngModel)]="terminationEntry?.notes.fieldValue">
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>


      <div class="col-xs-12" *ngIf="i != terminationEntries.length -1">
        <hr>
      </div>
    </div>
  </div>
</form>