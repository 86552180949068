import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, RouterStateSnapshot, Router,
    Params, Resolve
} from '@angular/router';

import { BaseResolver } from '../base-resolver';
import { RangeDates } from '../../../models/range-dates';
import { appConfig } from '../../../../app.config';

@Injectable()
export class StartEndDateResolver extends BaseResolver<RangeDates> implements Resolve<RangeDates> {

    public disableNavigation: boolean;

    constructor(router: Router) {
        super(router);
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): RangeDates {

        let startDateParam: string = _.toString(route.queryParamMap.get('startDate'));
        let endDateParam: string = _.toString(route.queryParamMap.get('endDate'));

        let startDate: Date = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        let endDate: Date = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;

        if (startDate && endDate) {
            if (!moment(endDate).isAfter(startDate)) {
                endDate = moment(startDate).add(1, 'day').toDate();
            }
        } else if (startDate) {
            endDate = moment(startDate).add(1, 'day').toDate();
        } else if (endDate) {
            startDate = moment(endDate).subtract(1, 'day').toDate();
        } else {
            startDate = moment().startOf('day').toDate();
            endDate = moment(startDate).add(1, 'day').toDate();
        }

        let range: RangeDates = new RangeDates;
        range.startDate = startDate;
        range.endDate = endDate;

        if (!this.disableNavigation) {
            const params: Params = this.createQueryParams(route, range);
            this.navigateToPath(route, params);
        }

        return range;

    }

    public createQueryParams(route: ActivatedRouteSnapshot, range: RangeDates): Params {
        const params: Params = super.createQueryParams(route);
        params['startDate'] = moment(range.startDate).format(appConfig.linkDateFormat);
        params['endDate'] = moment(range.endDate).format(appConfig.linkDateFormat);
        return params;
    }

}
