import { FieldData, FieldsMeta } from '../../../../core/models/index';
import { State } from '../../../../organization/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsEmergencyContact {
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  name: string;
}

export interface IEmployeeSectionsEmergencyContacts {
  emergencyContacts: IEmployeeSectionsEmergencyContact[];
}

export class EmployeeEmergencyContact {
  public address: FieldData<string>;
  public city: FieldData<string>;
  public state: FieldData<State>;
  public zip: FieldData<string>;
  public phone: FieldData<string>;
  public name: FieldData<string>;
}

export class EmployeeSectionsEmergencyContacts extends EmployeeSectionsBase {
  public emergencyContacts: EmployeeEmergencyContact[];
  public fieldsMeta: FieldsMeta;
}
