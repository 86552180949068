import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GridComponent } from '@progress/kendo-angular-grid';

import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { UserApplication, OrgLevel } from '../../../../state-model/models/index';
import { OperationalConsoleEntry } from '../../../../employee/employee-list/models/index';

import { OperationalConsoleStateService } from '../../../services/operational-console/operational-console-state.service';

@Component({
  moduleId: module.id,
  selector: 'slx-operational-console',
  templateUrl: 'operational-console.component.html',
  styleUrls: ['operational-console.component.scss'],
})
export class OperationalConsoleComponent implements OnDestroy {
  public currentDate: Date;
  public orgLevel: OrgLevel;
  public application: UserApplication;
  public isLoading: boolean;
  public gridState: KendoGridStateHelper<OperationalConsoleEntry>;
  public entries: OperationalConsoleEntry[];
  @ViewChild('kendoGrid', {static: true})
  public grid: GridComponent;

  @mutableSelect('application')
  public application$: Observable<UserApplication>;
  @mutableSelect('orgLevel')
  private orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orglevelSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private employeeLinkClickSubscription: Subscription;

  constructor(
    public stateService: OperationalConsoleStateService
  ) {
  }


  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }
}

