import * as _ from 'lodash';
import { IColumnSettings } from '../../../core/models/index';


export class PunchesRollupState {
  public columnsMap: StringMap<IColumnSettings>;
  public columns: IColumnSettings[];
  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.columns, (column: IColumnSettings) => {
      return column.name;
    });
  }
  public createColumns(): void {
    this.columns = [
      {
        name: 'organization',
        description: 'Home Organization',
        readonly: false,
        displayed: true,
        width: 180,
        filter: 'string'
      },
      {
        name: 'workOrganization',
        description: 'Work Organization',
        readonly: false,
        displayed: true,
        width: 180,
        filter: 'string'
      },
      {
        name: 'paycycle',
        description: 'Payroll Cycle',
        readonly: false,
        displayed: true,
        width: 200,
        filter: null
      },

      {
        name: 'approvedTimecards',
        description: 'Approved Timecards',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      },
      {
        name: 'validTimecards',
        description: 'Valid Timecards',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      },
      {
        name: 'missingPunches',
        description: 'Missing Punches',
        readonly: false,
        displayed: true,
        width: 150,
        filter: null
      },
      {
        name: 'pendingEmpRequest',
        description: 'Pending Emp Requests',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      },
      {
        name: 'invalidPunches',
        description: 'Invalid Punches',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      },
      {
        name: 'scheduledPunches',
        description: 'Scheduled, No Show',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      }
    ];
  }
  public setState(initialState: StringMap<boolean>): void {
    this.createColumns();
    _.forEach(this.columns, (column: IColumnSettings) => {
      column.displayed = initialState[column.name];
    });
  }

}
