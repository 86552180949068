import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { EmployeePunchRequestManagementService } from './../../../../../app/time-and-attendance/services/punches/employee-punch-request-management.service';
import { unsubscribeAll } from '../../../../core/decorators/index';
var EmployeePunchRequestComponent = /** @class */ (function () {
    function EmployeePunchRequestComponent(manageService) {
        this.manageService = manageService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    EmployeePunchRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manageService.init();
        this.subscriptions.loading = this.manageService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    EmployeePunchRequestComponent.prototype.ngOnDestroy = function () {
        this.manageService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeePunchRequestComponent.prototype, "subscriptions", void 0);
    return EmployeePunchRequestComponent;
}());
export { EmployeePunchRequestComponent };
