import { IUserLoginSessionInfo, UserLoginSessionInfo } from "./user-session-info";

export interface IUserLoginManagement {
    refreshRate: number;
    sessions: IUserLoginSessionInfo[];
}

export class UserLoginManagement {
    public refreshRate: number = 60000;
    public sessions: UserLoginSessionInfo[] = [];
}