import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';
import { IConstraintDTO, ConstraintModel } from '../../models/constraints/constraint.model';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class ConstraintsConfigurationMapService {

    public mapConstraints(dtos: IConstraintDTO[]): ConstraintModel[] {

        Assert.isNotNull(dtos);
        let arr: ConstraintModel[] = [];

        _.each(dtos, (dto: IConstraintDTO) => {
            arr.push(this.mapConstraint(dto));
        });

        return arr;
    }

    public mapConstraint(dto: IConstraintDTO): ConstraintModel {

        let model: ConstraintModel = new ConstraintModel();
        model.id = dto.id;
        model.code = dto.code;
        model.name = dto.name;
        model.countAs = dto.countAs;
        model.usedCount = dto.usedCount;
        model.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
        model.lastUpdateUsername = dto.lastUpdateUsername;
        return model;
    }

}
