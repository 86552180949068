import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { PositionModel, EditableListActionKind } from '../../../../models/index';
import { CustomListActorBase } from '../../../editableList/custom-list-actor.base';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-copy-position-renderer',
  templateUrl: 'configure-copy-position-renderer.component.html',
  styleUrls: ['configure-copy-position-renderer.component.scss'],
   providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef( () => ConfigureCopyPositionRendererComponent)
    }
  ]
})
export class ConfigureCopyPositionRendererComponent extends CustomListActorBase implements OnInit  {

  @Input ()
  public set context (value: any) {
    this.item = value.item;
    this.rowIndex = value.rowIndex;
  }

  @Input ()
  public item: PositionModel;

  @Input()
  public rowIndex: number;

  private appConfig: IApplicationConfig;

  public ngOnInit (): void {
    this.appConfig = appConfig;
  }

  public onItemSelect (): void {
    this.actionEmitter.emit (EditableListActionKind.SELECTION_CHANGE);
  }

}
