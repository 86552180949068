import { DetailGroup } from './detail-group';
import { DetailColumn } from './detail-column';

export class Details {
  public totalHours: number;
  public dateOn: Date;
  public censusCount: number;
  public groups: DetailGroup[];
  public columns: DetailColumn[];
  public canReplaceEmployee: boolean;
  public canAgencyStaffingRequest: boolean;
  public canPartnerModule: boolean;
  public canAgencyShiftCancelRequest: boolean;
  public canEditScheduledApprovePayPeriod : boolean;
}