import { Component, OnInit, OnDestroy,ViewChildren ,QueryList} from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, State, SortDescriptor } from '@progress/kendo-data-query';

import { Assert } from '../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import {  ScheduleApiService } from '../../services/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

import {  IRangeDates } from '../../../common/models/range-dates';
import { ScheduleNotPosted } from '../../models/schedules-not-posted/schedule-not-posted';
import { OrgLevel } from '../../../state-model/models/org-level';
import { ActivatedRoute, Router } from '@angular/router';
import { PageChangeEvent,GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

@Component({
  moduleId: module.id,
  selector: 'slx-schedules-not-posted',
  templateUrl: './schedules-not-posted.component.html',
  styleUrls: ['./schedules-not-posted.component.scss']
})

export class SchedulesNotPostedComponent implements OnInit, OnDestroy {

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  private router: Router;
  private route: ActivatedRoute;
  public startDate: Date;
  public endDate: Date;
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<ScheduleNotPosted>;
  public state: {
    isLoading: boolean;
  };

  private scheduleApiService: ScheduleApiService;
  private gridEventSubscription: Subscription;
  private scheduleNotPosted: ScheduleNotPosted[];
  private currentOrgLevel: OrgLevel;
  public sort: SortDescriptor[];
  public skip: number;

  @ViewChildren('kendoGrid')
  public kendoGrid: QueryList<GridComponent>;

  @unsubscribe()
  private orglevelSubscription: Subscription;
  public pageSize: number = 30;
  public orgTypeDepartment: boolean = false;

  constructor(scheduleApiService: ScheduleApiService, router: Router, route: ActivatedRoute) {
    Assert.isNotNull(scheduleApiService, 'scheduleApiService');
    this.scheduleApiService = scheduleApiService;
    this.gridState = new KendoGridStateHelper<ScheduleNotPosted>();
    this.gridState.state.sort = [{ field: 'scheduleCycleStartDate', dir: 'asc' }];
    this.gridEventSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
    this.gridState.state.skip = 0;
    this.skip = this.gridState.state.skip;
    this.appConfig = appConfig;
    this.state = {
      isLoading: false
    };
    let today = new Date();
    this.startDate= new Date(new Date().setDate(today.getDate() - 30));
    this.endDate= new Date(new Date().setDate(today.getDate() + 30));
    this.router = router;
    this.route = route;
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    if (this.orgLevel$ != undefined && this.orgLevel$ != null) this.orglevelSubscription = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        this.currentOrgLevel = orgLevel;
        this.doLoad();
        this.orgTypeDepartment = orgLevel.type === 'Department' ? true : false;
      });
  }

  public doLoad(): void {
    this.state.isLoading = true;
    this.scheduleApiService.getSchedulesNotPosted(this.currentOrgLevel.id, this.startDate, this.endDate)
      .then((scheduleNotPosted: ScheduleNotPosted[]) => {
        this.scheduleNotPosted = scheduleNotPosted;
        this.state.isLoading = false;
        this.refreshGrid();
      })
      .catch(() => {
        this.state.isLoading = false;
      });
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.doLoad();
  }

  public ngOnDestroy(): void {
    if (this.gridEventSubscription) {
      this.gridEventSubscription.unsubscribe();
    }
  }

  public refreshGrid(): void {
    if (!this.scheduleNotPosted) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.scheduleNotPosted, this.gridState.state);
  }



  public navigateToOpenShiftManagement(date: Date): void {
    let dateParam: string = moment(date).format(appConfig.linkDateFormat);
    this.router.navigate(['open_shift_management'], { relativeTo: this.route.pathFromRoot[2], queryParams: { date: dateParam } });
  }
  public filterChangeHandler(event: any): void {
    this.gridState.state.filter = event;
    this.refreshGrid();
  }
  public get dateFormat(): string {
    return '{0:MM/dd/yyyy}';
  }


  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public pageChangeHandler(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.refreshGrid();
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.scheduleNotPosted, { sort: this.gridState.state.sort}).data
    }) as ExcelExportData;
  }

  public onClickExportExcel(position: string): void {
    if (position.toLowerCase() === 'excel') {
      this.kendoGrid.forEach( e => {
        e.saveAsExcel();
      }); 
    }
  }
  public getDateRange(): string {
    return 'Date Range: ' + moment(this.startDate).format('L') + ' - ' + moment(this.endDate).format('L');
  }
}

