import { StaffingRatingOrganization, IStaffingRatingOrganizationDTO, IFiveStarRatingOrganizationDTO, FiveStarRatingOrganization } from './staffing-rating-organization';
import { StaffingPointsTable, IStaffingPointsTableDto } from './staffing-points-table';
import { BoldPipe } from '../../accruals/pipes/text-bold.pipe';

export interface IStaffingRatingContainerDTO {
    organizations: IStaffingRatingOrganizationDTO[];
    maxRatingValue: number;
    startDate: string;
    endDate: string;
    pointTables: IStaffingPointsTableDto[];
}

export class StaffingRatingContainer {
    public startDate: Date;
    public endDate: Date;
    public organizations: StaffingRatingOrganization[];
    public ratingMaxValue: number;
    public pointTables: StaffingPointsTable[];
    public hasError: BoldPipe;
}

export interface IFiveStarRatingContainerDTO {
    organizations: IFiveStarRatingOrganizationDTO[];
    maxRatingValue: number;
    startDate: string;
    endDate: string;
}

export class FiveStarRatingContainer {
    public startDate: Date;
    public endDate: Date;
    public organizations: FiveStarRatingOrganization[];
    public ratingMaxValue: number;
    public hasError: boolean;
}
