import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, OnDestroy, ElementRef, SimpleChanges } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators';
import { MessagesManagementService } from '../../services/messages-management.service';
var MessagesGridComponent = /** @class */ (function () {
    function MessagesGridComponent(messageManService, elementRef) {
        this.messageManService = messageManService;
        this.elementRef = elementRef;
        this.messagesList = [];
        this.updateMsgListSelectedMessageIds = new EventEmitter();
        this.searchText = '';
        this.selectedId = '';
        this.orgLevelId = null;
        this.subscriptions = {};
        this.unSelectedArr = [];
        this.sendUnselectedEmployee = new EventEmitter();
        this.isArchive = false;
        this.isMyMessage = true;
        this.borderLeft = '';
    }
    MessagesGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.id = this.messageManService
            .subscribeToGetMessageId(function (v) {
            _this.selectedId = v;
            _this.scrollToSelectedMsg();
        });
    };
    MessagesGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes.selectedId && changes.selectedId.currentValue !== changes.selectedId.previousValue) {
            this.scrollToSelectedMsg();
        }
        if (this.isArchive) {
            this.borderLeft = '5px solid #b9422c';
        }
        else {
            this.borderLeft = '5px solid #637908';
        }
    };
    MessagesGridComponent.prototype.ngOnDestroy = function () { };
    MessagesGridComponent.prototype.selectionChange = function (item, event) {
        if (!event) {
            this.unSelectedArr.push(item);
        }
        else {
            this.unSelectedArr = this.unSelectedArr.filter(function (x) { return x.employeeId !== item.employeeId; });
        }
        this.sendUnselectedEmployee.emit(this.unSelectedArr);
        this.updateMsgListSelectedMessageIds.emit(item);
    };
    MessagesGridComponent.prototype.showMessage = function (item) {
        var _this = this;
        var isRead = item.read === true;
        this.selectedId = item.employeeId;
        if (!isRead) {
            this.messagesList
                .filter(function (x) { return x.employeeId === _this.selectedId; })
                .map(function (y) { return y.read = true; });
        }
        this.messageManService.updateMessageThread(item, this.orgLevelId, !isRead, this.isMyMessage);
    };
    MessagesGridComponent.prototype.getDisableStatusOfemplyee = function (item) {
        if (item.status === 'Terminated' || item.status === 'Transferred' || (item.optIn === 0) || (item.optIn === 2)) {
            return 'disable-color';
        }
        else {
            return '';
        }
    };
    MessagesGridComponent.prototype.scrollToSelectedMsg = function () {
        var _this = this;
        if (!this.elementRef.nativeElement) {
            return;
        }
        setTimeout(function () {
            var selectedMsg = _this.elementRef.nativeElement.querySelector("[data-message-id=\"" + _this.selectedId + "\"]");
            if (selectedMsg) {
                selectedMsg.scrollIntoView({ behavior: 'smooth' });
                _this.messagesList.filter(function (x) { return x.id === _this.selectedId; }).map(function (y) { return y.read = true; });
            }
        }, 100);
    };
    Object.defineProperty(MessagesGridComponent.prototype, "searchFormattedNumber", {
        get: function () {
            var phoneNumberString = this.searchText;
            if (phoneNumberString) {
                var formattedNumber = "";
                if (phoneNumberString.length > 6) {
                    formattedNumber = '(' + phoneNumberString.substr(0, 3) + ') ' + phoneNumberString.substr(3, 3) + '-' + phoneNumberString.substr(6);
                }
                else if (phoneNumberString.length > 3) {
                    formattedNumber = '(' + phoneNumberString.substr(0, 3) + ') ' + phoneNumberString.substr(3);
                }
                else {
                    formattedNumber = phoneNumberString;
                }
                return formattedNumber;
            }
            else {
                return phoneNumberString;
            }
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MessagesGridComponent.prototype, "subscriptions", void 0);
    return MessagesGridComponent;
}());
export { MessagesGridComponent };
