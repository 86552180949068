<form novalidate #form="ngForm" *ngIf="employeeSectionsSelfService">
  <div class="row">
    <div class="col-xs-12">
      <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="isEssEnabled" fieldName="isEssEnabled"
                            [readonly]="!state.isEditMode" [(ngModel)]="employeeSectionsSelfService.isEssEnabled"
                            caption="Enable Employee Access to ESS">
        </slx-checkbox-input>
      </slx-input-decorator>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4 col-lg-3">
      <slx-input-decorator class="element">
        <slx-dropdown-input slx-input
          [lookup]="{lookupType: 'essEnterTimeMethods'}"
          valueField="name" titleField="description"
          [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled" 
          [(ngModel)]="timeMethod"
          [emptyOption]="{ id: 'None', name: 'None' }"
          name="timeMethodInput" [required]="false" placeholder="Time Entry Method">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-4">
      <div class="slx-form-group">
        <label class="slx-label">Available Options</label>
      </div>

      <slx-input-decorator *ngIf="essLookupService.getOption('timecard')" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="timecard" fieldName="timecard"
                            [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
                            [(ngModel)]="employeeSectionsSelfService.timecard"
                            [caption]="essLookupService.getOptionLabel('timecard')">
        </slx-checkbox-input>
      </slx-input-decorator>

      <slx-input-decorator *ngIf="essLookupService.getOption('schedule')" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="schedule" fieldName="schedule"
                            [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
                            [(ngModel)]="employeeSectionsSelfService.schedule"
                            [caption]="essLookupService.getOptionLabel('schedule')">
        </slx-checkbox-input>
      </slx-input-decorator>

      <slx-input-decorator *ngIf="essLookupService.getOption('availability')" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="availability" fieldName="availability"
                            [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
                            [(ngModel)]="employeeSectionsSelfService.availability"
                            [caption]="essLookupService.getOptionLabel('availability')">
        </slx-checkbox-input>
      </slx-input-decorator>

      <slx-input-decorator *ngIf="essLookupService.getOption('inservice')" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="inservice" fieldName="inservice"
                            [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
                            [(ngModel)]="employeeSectionsSelfService.inservice"
                            [caption]="essLookupService.getOptionLabel('inservice')">
        </slx-checkbox-input>
      </slx-input-decorator>

      <slx-input-decorator *ngIf="essLookupService.getOption('payStubs')" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="paystubs" fieldName="paystubs"
                            [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
                            [(ngModel)]="employeeSectionsSelfService.payStubs"
                            [caption]="essLookupService.getOptionLabel('payStubs')">
        </slx-checkbox-input>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-4">
      <div class="slx-form-group">
        <label class="slx-label">User Security Options</label>
      </div>

      <slx-input-decorator *ngIf="essLookupService.getOption('changePassword')" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="changePassword" fieldName="changePassword"
                            [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
                            [(ngModel)]="employeeSectionsSelfService.changePassword"
                            [caption]="essLookupService.getOptionLabel('changePassword')">
        </slx-checkbox-input>
      </slx-input-decorator>

      <slx-input-decorator *ngIf="essLookupService.getOption('changeAddress')" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="changeAddress" fieldName="changeAddress"
                            [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
                            [(ngModel)]="employeeSectionsSelfService.changeAddress"
                            [caption]="essLookupService.getOptionLabel('changeAddress')">
        </slx-checkbox-input>
      </slx-input-decorator>

      <slx-input-decorator class="element">
        <slx-dropdown-input slx-input
          *ngIf="!employeeSectionsSelfService.hasEssAccount"
          [lookup]="{ lookupType: 'essPasswordTypes' }"
          valueField="id" titleField="name"
          [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled" 
          [(ngModel)]="passwordType"
          name="passInput" [required]="employeeSectionsSelfService.isEssEnabled" placeholder="Password">
        </slx-dropdown-input>

        <slx-dropdown-input slx-input
          *ngIf="employeeSectionsSelfService.hasEssAccount"
          [emptyOption]="{ id: 'NoChange', name: 'No Change' }"
          [lookup]="{lookupType: 'essPasswordTypes'}"
          valueField="id" titleField="name"
          [readonly]="!state.isEditMode || !employeeSectionsSelfService.isEssEnabled"
          [(ngModel)]="passwordType"
          name="passInput" placeholder="Password">
        </slx-dropdown-input>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
  </div>
</form>
