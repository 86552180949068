import { Injectable } from '@angular/core';
import { UserMenuPinAPIService } from './user-menu-pin-api.service';
import { UserMenuPinState } from '../../models';
import { ApplicationStateManagementService } from '../../../portal/services/application-state-management/application-state-management.service';

@Injectable({
  providedIn: 'root'
})
export class UserMenuPinService {

  constructor(private menuPinApiService: UserMenuPinAPIService, private appStateManagementService: ApplicationStateManagementService) { }

  public getUserMenuPinStatus(): void {
    this.menuPinApiService.getUserMenuPinStatus()
    .then((data: UserMenuPinState) => {
      this.appStateManagementService.isMenuPinned = data.isMenuPinned;
    });
  }

  public changeUserMenuPinStatus(isPinned: boolean): void {
    this.menuPinApiService.toggleUserMenuPinStatus(isPinned)
    .then((data: UserMenuPinState) => {
      this.appStateManagementService.isMenuPinned = data.isMenuPinned;
    });
  }
}
