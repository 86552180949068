<div class="slx-operational-console__header">
  <slx-input-decorator>
    <slx-date-picker-ngx slx-input
      [(ngModel)]="stateService.currentDate"
      (ngModelChange)="onDateChanged()"
      [acceptNullDate]="false"
      placeholder="Date"
      name="date"
    ></slx-date-picker-ngx>
    <span errorMessage for="date"></span>
  </slx-input-decorator>
  <slx-actions-list class="slx-operational-console__actions">
    <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
      Actions <span class="caret"></span>
    </slx-actions-button>
    <popper-content #popperContent>
      <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
      <slx-actions-list-item (onClick)="onExportToPdf()">Export to PDF</slx-actions-list-item>
    </popper-content>
  </slx-actions-list>
</div>
