import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ApplicationService, ScreenAvailabilityService } from '../../services/index';
import { ScreenAvailabilityState } from '../../models/index';
import { mutableSelect } from '../../../core/decorators/index';
var ApplicationContainerComponent = /** @class */ (function () {
    function ApplicationContainerComponent(router, route, applicationService, screenAvailabilityService) {
        this.router = router;
        this.route = route;
        this.applicationService = applicationService;
        this.screenAvailabilityService = screenAvailabilityService;
        this.availability = new ScreenAvailabilityState();
        this.applicationSelected = false;
    }
    ApplicationContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.availabilitySubscription = this.screenAvailabilityService.subscribeToAvailability(function (availability) {
            _this.availability = availability;
        });
    };
    ApplicationContainerComponent.prototype.ngOnDestroy = function () {
        if (this.availabilitySubscription) {
            this.availabilitySubscription.unsubscribe();
        }
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], ApplicationContainerComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isRightSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], ApplicationContainerComponent.prototype, "isRightSidebarOpen", void 0);
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], ApplicationContainerComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarHidden']),
        tslib_1.__metadata("design:type", Observable)
    ], ApplicationContainerComponent.prototype, "isLeftSidebarHidden", void 0);
    return ApplicationContainerComponent;
}());
export { ApplicationContainerComponent };
