import { EventEmitter } from '@angular/core';
import { ShiftDefinition } from '../../../../../organization/models/index';
import { ShiftPartialAbsence } from '../../../../models/index';
import { appConfig } from '../../../../../app.config';
var ScheduledShiftPartialAbsenceSectionComponent = /** @class */ (function () {
    function ScheduledShiftPartialAbsenceSectionComponent() {
        this.index = -1;
        this.appConfig = appConfig;
        this.onChange = new EventEmitter();
        this.onTimeChange = new EventEmitter();
        this.onAttachmentChange = new EventEmitter();
    }
    Object.defineProperty(ScheduledShiftPartialAbsenceSectionComponent.prototype, "partialAbsence", {
        get: function () {
            return this.m_partialAbsence;
        },
        set: function (value) {
            this.m_partialAbsence = value;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledShiftPartialAbsenceSectionComponent.prototype.onPartialAbsenceChanged = function () {
        this.onChange.next(this.m_partialAbsence);
    };
    ScheduledShiftPartialAbsenceSectionComponent.prototype.shiftPartialAbsenceTimeChanged = function () {
        this.onTimeChange.next(this.m_partialAbsence);
    };
    ScheduledShiftPartialAbsenceSectionComponent.prototype.onPartialAbsenceAttachmentChanged = function () {
        this.onAttachmentChange.next(this.partialAbsToEnd);
    };
    return ScheduledShiftPartialAbsenceSectionComponent;
}());
export { ScheduledShiftPartialAbsenceSectionComponent };
