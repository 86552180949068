import { PayCode, IPayCodeDTO } from './pay-code';
import { AccrualPolicy } from './pay-code-accrual-policies';

export class PayCodeModel {

    public get isCodeApplicableForPbj(): boolean {
        return this.payCode ? this.payCode.isPbjApplicable : false;
    }

    public payCode: PayCode;

    public lastUpdateUsername: string;
    public lastUpdateDate: Date;

    public isSelected: boolean;
    public selectable: boolean;
    public isDirty: boolean;
}

export interface IPayCodeModel extends IPayCodeDTO {
    lastUpdateUsername: string;
    lastUpdateDate: string;    
}
