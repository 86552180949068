import { Component } from '@angular/core';
import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsPayCycleCalcConf } from '../../../models/employee-sections-employment/employee-sections-pay-cycles';
import { LookupApiService } from '../../../../../organization/services/index';
import { PayCyclePeriod, PayCyclePeriodType } from '../../../../../organization/models/lookup/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-pay-cycle-conf',
  templateUrl: 'employee-sections-pay-cycle-conf.component.html',
  styleUrls: ['employee-sections-pay-cycle-conf.component.scss']
})
export class EmployeeSectionsPayCycleConfComponent {
  public options: DialogOptions;
  public dialogResult: boolean;

  public get showStartDayInputs(): boolean {
    if (!this.conf) return false;
    if (this.conf.payCycle.name !== PayCyclePeriodType.smonthly) {
      return false;
    }
    return true;
  }
  public conf: EmployeeSectionsPayCycleCalcConf;
  public payCycleTypes: PayCyclePeriod[];

  private modalService: ModalService;
  private lookupApiService: LookupApiService;

  constructor(options: DialogOptions,
    modalService: ModalService,
    lookupApiService: LookupApiService,
    conf: EmployeeSectionsPayCycleCalcConf) {
    this.options = options;
    this.modalService = modalService;
    this.lookupApiService = lookupApiService;
    this.dialogResult = false;
    this.conf = conf;
    lookupApiService.getPayCyclePeriods()
      .then((periods: PayCyclePeriod[]) => {
        this.payCycleTypes = periods;
      }
      );
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
