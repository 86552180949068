import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { UserService } from '../user/user.service';
import { UserSettings } from '../../models/index';
import { User } from '../../../state-model/models/index';
import { Assert } from '../../../framework/index';

@Injectable()
export class UserSettingsService {
  private localStorageService: LocalStorageService;
  private userService: UserService;

  constructor(userService: UserService, localStorageService: LocalStorageService) {
    Assert.isNotNull(userService, 'userService');
    Assert.isNotNull(localStorageService, 'localStorageService');
    this.userService = userService;
    this.localStorageService = localStorageService;
  }

  public get(): UserSettings {
    let user: User = this.userService.getUser();

    if (!user) {
      user = this.userService.getLastUser();
    }

    if (!user) {
      return new UserSettings();
    }
    let key: string = UserSettings.getKey(user.id);
    let settings: UserSettings;
    if (!this.localStorageService.has(key)) {
      settings = new UserSettings(user.id);
      this.set(settings);
    } else {
      settings = this.localStorageService.get<UserSettings>(key);
    }
    return settings;
  }

  public set(settings: UserSettings): void {
    if (!settings || !settings.id) {
      return;
    }
    let key: string = UserSettings.getKey(settings.id);
    this.localStorageService.set(key, settings);
  }
}

