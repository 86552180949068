import { AgencyEmployeeApiService } from './agency-employee-api.service';
var AgencyEmployeeValidatorAdapter = /** @class */ (function () {
    function AgencyEmployeeValidatorAdapter(apiService) {
        this.apiService = apiService;
    }
    AgencyEmployeeValidatorAdapter.prototype.validate = function (validationName, value) {
        var params = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            params[_i - 2] = arguments[_i];
        }
        var promise;
        var defPromise = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        switch (validationName) {
            case AgencyEmployeeValidatorAdapter.agencyEmployeeAddValidation:
                var req = value;
                promise = this.apiService.validateAddAgencyEmployee(req);
                break;
            default:
                promise = defPromise;
        }
        return promise;
    };
    AgencyEmployeeValidatorAdapter.agencyEmployeeAddValidation = 'agencyEmployeeAddValidation';
    return AgencyEmployeeValidatorAdapter;
}());
export { AgencyEmployeeValidatorAdapter };
