import * as _ from 'lodash';
import { IPerformanceReviewCode, PerformanceReviewCode, IEmployee, IOrganization, IDepartment, IPosition, PmAttachment, IPmAttachment, ReadFile } from '../../../organization/models/index';
import { IPmReviewRecord, PmReviewRecord } from './pm-review-records';
import { IPmTemplate, PmTemplate } from '../../../organization/models/lookup/pm-template';

export interface IPmReviewEntry {

  employee: IEmployee;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  hireDate: string;
  terminationDate: string;
  activationDate: string;
  
  reviewRecord:IPmReviewRecord;

  prevReviewDate: string;
  prevReviewId:number;

  reviewType: IPerformanceReviewCode;
  rating: IPerformanceReviewCode;
  attachments: IPmAttachment[];
  canEdit: boolean;
  canDelete: boolean;
}

export class PmReviewEntry extends PmReviewRecord {
  public canEdit: boolean;
  public canDelete: boolean;
  public lastReviewDate: Date;
  public rating: PerformanceReviewCode;
  public attachments: PmAttachment[] = [];
  public templates: PmTemplate[] = [];
  public isEditMode = false;
  public addedFiles: ReadFile[] = [];
    
  public get isNew(): boolean {
    return _.isNil(this.id);
  }

  public get hasEmployee(): boolean {
    return this.employee && _.isFinite(this.employee.id);
  }
}
