import { Provider } from '@angular/core';

export * from './benefit-details-management.service';
export * from './benefit-details-standart-management.service';
export * from './benefit-details-api.service';
export * from './benefit-details-map.service';
export * from './benefit-details-permission.service';
export * from './benefit-details-validator-adapter';

import { BenefitDetailsManagementService } from './benefit-details-management.service';
import { BenefitDetailsStandartManagementService } from './benefit-details-standart-management.service';
import { BenefitDetailsPermissionService } from './benefit-details-permission.service';
import { BenefitDetailsApiService } from './benefit-details-api.service';
import { BenefitDetailsMapService } from './benefit-details-map.service';
import { CalculationCommonService } from './calculation-common.service';
import { BenefitDetailsValidatorAdapter } from './benefit-details-validator-adapter';

export const benefitPlanServices: Provider[] = [
  BenefitDetailsApiService,
  BenefitDetailsMapService,
  CalculationCommonService,
  BenefitDetailsValidatorAdapter
];
