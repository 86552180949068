
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationMenuItem } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { UserApplication } from '../../../state-model/models/index';
import { Assert } from '../../../framework/index';
import { ScreenAvailabilityState } from '../../models/index';

@Injectable()
export class ScreenAvailabilityService {
  private orgLevel: OrgLevel;
  private menuItem: NavigationMenuItem;
  private availabilityChanged$: ReplaySubject<ScreenAvailabilityState>;
  constructor(private activatedRoute: ActivatedRoute) {
    this.availabilityChanged$ = new ReplaySubject<ScreenAvailabilityState>(1);
    this.resetAvailability();
  }

  public getAvailability(orgLevel: OrgLevel, menuItem: NavigationMenuItem): ScreenAvailabilityState {
    const availability: ScreenAvailabilityState = new ScreenAvailabilityState();
    const supportedTypes = _.get(menuItem, 'supportedOrgTypes') ? _.get(menuItem, 'supportedOrgTypes') : [];
    availability.isReady = _.isString(_.get(orgLevel, 'type')) && _.isArray(supportedTypes);
    if (availability.isReady) {
      let ignoreSupportedOrgTypes = this.activatedRoute.snapshot.queryParams['supportedOrgTypes'];
      availability.isAvailable = ignoreSupportedOrgTypes ? true : _.indexOf(supportedTypes, orgLevel.type) !== -1 || supportedTypes.length === 0;
      availability.supportedOrgTypes = ignoreSupportedOrgTypes ? [] : supportedTypes.slice(0);
    }
    return availability;
  }

  public changeAvailability(availability: ScreenAvailabilityState): void {
    this.availabilityChanged$.next(availability);
  }

  public resetAvailability(): void {
    const availability: ScreenAvailabilityState = this.getAvailability(null, null);
    this.changeAvailability(availability);
  }

  public subscribeToAvailability(callback: (availability: ScreenAvailabilityState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.availabilityChanged$.subscribe(callback);
  }
}
