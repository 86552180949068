import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/merge';
import { unsubscribe } from '../../../../core/decorators/index';
import { AGENCY_ORGANIZATION_ID } from '../../../configuration.config';
import { AgencyMapping } from '../../../models/index';
import { AgencyDetailsManagementService } from '../../../services/index';
var AgencyDetailsComponent = /** @class */ (function () {
    function AgencyDetailsComponent(managementService) {
        this.rowIndex = 0;
        this.managementService = managementService;
    }
    Object.defineProperty(AgencyDetailsComponent.prototype, "agencyMapping", {
        set: function (value) {
            this.currentAgencyMapping = value;
            if (this.currentAgencyMapping) {
                this.tempAgencyMapping = this.managementService.cloneAgencyMapping(this.currentAgencyMapping);
            }
            else {
                this.tempAgencyMapping = null;
            }
            this.editMode = false;
            if (this.fullOrganizationsList) {
                this.filterOrganizations();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsComponent.prototype, "organizations", {
        set: function (value) {
            this.fullOrganizationsList = value;
            if (this.currentAgencyMapping) {
                this.filterOrganizations();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AgencyDetailsComponent.prototype, "hasFreeOrganizationsForMapping", {
        get: function () {
            return this.filteredOrganizations && this.filteredOrganizations.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    AgencyDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.mappingSubscription = Observable.merge(this.managementService.onAdded$, this.managementService.onRemoved$).subscribe(function (result) {
            _this.organizationToAdd = null;
            _this.filterOrganizations();
        });
    };
    AgencyDetailsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    AgencyDetailsComponent.prototype.toggleEditMode = function () {
        this.editMode = !this.editMode;
    };
    AgencyDetailsComponent.prototype.addNewOrganization = function () {
        this.managementService.addOrganization(this.currentAgencyMapping, this.organizationToAdd);
    };
    AgencyDetailsComponent.prototype.removeHandler = function (event) {
        if (!event.dataItem) {
            return;
        }
        this.managementService.removeOrganization(this.currentAgencyMapping, event.dataItem);
    };
    AgencyDetailsComponent.prototype.saveAgencyInfo = function () {
        this.currentAgencyMapping = this.managementService.cloneAgencyMapping(this.tempAgencyMapping);
        this.managementService.saveAgencyInfo(this.orglevelId, this.tempAgencyMapping);
    };
    AgencyDetailsComponent.prototype.discardAgencyInfo = function () {
        this.tempAgencyMapping = this.managementService.cloneAgencyMapping(this.currentAgencyMapping);
    };
    Object.defineProperty(AgencyDetailsComponent.prototype, "isAnyOrganizationWithoutAgencies", {
        get: function () {
            return this.filteredOrganizations && this.filteredOrganizations.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    AgencyDetailsComponent.prototype.filterOrganizations = function () {
        if (this.fullOrganizationsList && this.currentAgencyMapping) {
            this.filteredOrganizations = _.differenceBy(this.fullOrganizationsList, this.currentAgencyMapping.organizations, 'id');
            if (this.filteredOrganizations && this.filteredOrganizations.length > 0) {
                this.filteredOrganizations = _.without(this.filteredOrganizations, _.find(this.filteredOrganizations, function (organization) {
                    return organization.id === AGENCY_ORGANIZATION_ID;
                }));
            }
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AgencyDetailsComponent.prototype, "mappingSubscription", void 0);
    return AgencyDetailsComponent;
}());
export { AgencyDetailsComponent };
