import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
var CensusEntriesPageComponent = /** @class */ (function () {
    function CensusEntriesPageComponent(activatedRoute) {
        var _this = this;
        this.activatedRoute = activatedRoute;
        this.routeSubscription = this.activatedRoute.params
            .combineLatest(this.activatedRoute.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var dateOn = queryParams['date'];
            if (!dateOn) {
                dateOn = new Date();
            }
            if (!_this.entryDate) {
                _this.entryDate = moment(dateOn, appConfig.linkDateFormat).toDate();
            }
        });
    }
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CensusEntriesPageComponent.prototype, "routeSubscription", void 0);
    return CensusEntriesPageComponent;
}());
export { CensusEntriesPageComponent };
