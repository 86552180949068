import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ZonedDate } from '@progress/kendo-date-math';
import '@progress/kendo-date-math/tz/America/New_York';
import { parseRule, serializeRule, RecurrenceRule } from '@progress/kendo-recurrence';

import { dateTimeUtils } from '../../../../common/utils/index';
import { LoaRequest, LoaMappedRequest, RecurrenceFrequencies, RecurrenceFrequencyDefinition } from '../../models/index';
import { IDateRange, DateRange } from '../../../../core/models/index';

@Injectable()
export class LMRequestMapperService {
  private firstDayOfWeek: number = 0;
  private dayMs: number = 1000 * 60 * 60 * 24;

  public mapRequest(req: LoaRequest): LoaMappedRequest {
    if (req.isContinuous) {
      return this.mapContinuous(req);
    } else if (req.isIntermittent) {
      if (req.isDaily || req.isWeekly || req.isMonthly) {
        return this.mapIntermittentRepeatable(req);
      } else {
        return this.mapIntermittentCustom(req);
      }
    } else {
      console.error('Cannot map request, LOA request has unknown type', req);

      return new LoaMappedRequest(req, {});
    }
  }

  public mapContinuous(req: LoaRequest): LoaMappedRequest {
    const { startDate, endDate } = this.getLoaDates(req);
    const exceptions = this.getExceptions(req);
    const exceptionsDays = this.createExceptionDays(exceptions);
    const absenceDays = this.createDays(startDate, endDate);
    const clearAbsenceDays = this.excludeExceptions(absenceDays, exceptionsDays);

    return new LoaMappedRequest(req, clearAbsenceDays);
  }

  public mapIntermittentCustom(req: LoaRequest): LoaMappedRequest {
    const customDates = this.getCustomDates(req);
    const exceptions = this.getExceptions(req);
    const exceptionsDays = this.createExceptionDays(exceptions);
    const absenceDays = this.createDaysFromRanges(customDates);
    const clearAbsenceDays = this.excludeExceptions(absenceDays, exceptionsDays);

    return new LoaMappedRequest(req, clearAbsenceDays);
  }

  public mapIntermittentRepeatable(req: LoaRequest): LoaMappedRequest {
    const absenceDays = this.buildAbsenceDays(req);
    const exceptions = this.getExceptions(req);
    const exceptionsDays = this.createExceptionDays(exceptions);
    const clearAbsenceDays = this.excludeExceptions(absenceDays, exceptionsDays);

    return new LoaMappedRequest(req, clearAbsenceDays);
  }

  public buildAbsenceDays(req: LoaRequest): IDateRange[] {
    const { startDate, endDate } = this.getLoaDates(req);
    const rrule = this.getRecurrenceRule(req);
    const absenceDays = this.buildAbsenceDaysFromRule(rrule, startDate);

    return absenceDays;
  }

  public evaluateClearAbsencePeriod(sDate: Date, rrule: string): IDateRange {
    const rule = this.parseRule(rrule, this.firstDayOfWeek);
    const absenceDays = this.buildAbsenceDaysFromRule(rule, sDate);
    let range = new DateRange(null, null);
    if (_.size(absenceDays) > 0) {
      const hasUntil = _.isObjectLike(rule.until);
      const hasCount = _.isFinite(rule.count) && rule.count > 0;
      range = new DateRange(_.head(absenceDays).startDate, _.last(absenceDays).endDate);
      if (!hasUntil && !hasCount) {
        range.endDate = null;
      }
    }

    return range;
  }

  private getRecurrenceRule(req: LoaRequest): RecurrenceRule {
    const rrule = req.loaRepeat.recurrenceRule;
    return this.parseRule(rrule, this.firstDayOfWeek);
  }

  private buildAbsenceDaysFromRule(rrule: RecurrenceRule, startDate: Date): IDateRange[] {
    const { freq } = rrule || {} as RecurrenceRule;
    switch(freq) {
      case RecurrenceFrequencies.daily:
        return this.getDailyAbsenceDays(rrule, startDate);
      case RecurrenceFrequencies.weekly:
        return this.getWeeklyAbsenceDays(rrule, startDate);
      default:
        return [];
    }
  }

  private getDailyAbsenceDays(rrule: RecurrenceRule, sDate: Date): IDateRange[] {
    const { interval, count, until } = rrule;
    const hasCount = _.isFinite(count) && count > 0;
    const absenceRanges: IDateRange[] = [];
    const { startDate, endDate } = this.evaluateAbsenceRange(sDate, interval, count, until);

    const days = this.createDays(startDate, endDate);
    for (let i = 0, iteration = 0, length = days.length; i < length; i++) {
      const dayIndex = i + 1;
      if (dayIndex % interval !== 0) continue;

      absenceRanges.push(days[i]);
      iteration++;

      if (hasCount && count === iteration) break;
    }

    return absenceRanges;
  }

  private getWeeklyAbsenceDays(rrule: RecurrenceRule, sDate: Date): IDateRange[] {
    const { interval, count, until, byWeekDay } = rrule;
    const hasCount = _.isFinite(count) && count > 0;
    const absenceRanges: IDateRange[] = [];
    const { startDate, endDate } = this.evaluateAbsenceRange(sDate, interval, count, until);
    const weekLength = 7;

    const daysRanges = this.createDays(startDate, endDate);
    for (let index = 0, day = 1, iteration = 0, week = 0, length = daysRanges.length; index < length; index++, day++) {
      const weekNumber = Math.max(Math.ceil(day / weekLength), 1);
      if (weekNumber % interval !== 0) continue;

      const dayRange = daysRanges[index];
      const startIndex = dayRange.startDate.getDay();
      const hasThisDayInConfig = _.some(byWeekDay, (d) => d.day === startIndex);
      if (hasThisDayInConfig) {
        absenceRanges.push(dayRange);
      }

      if (week !== weekNumber) {
        week = weekNumber;
        iteration++;
      }
      if (hasCount && iteration === count && (day / weekNumber) === weekLength) break;
    }

    return absenceRanges;
  }

  private evaluateAbsenceRange(sDate: Date, interval: number, count: number, until: ZonedDate): IDateRange {
    const hasUntil = _.isObjectLike(until);
    const hasCount = _.isFinite(count) && count > 0;
    const startDate = dateTimeUtils.copyDate(sDate);
    let endDate = null;

    switch(true) {
      case hasUntil:
        endDate = until.toLocalDate();
        break;
      case hasCount:
        const weekMs = this.dayMs * 7;
        const minTimeRange = weekMs * interval * count;
        endDate = new Date(startDate.getTime() + minTimeRange);
        break;
      default:
        endDate = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59, 999);
    }

    return new DateRange(startDate, endDate);
  }

  private excludeExceptions(absenceDays: IDateRange[], exceptionsDays: StringMap<IDateRange[]>): StringMap<IDateRange[]> {
    if (_.size(_.keys(exceptionsDays)) === 0) {
      return _.reduce(absenceDays, (accum: StringMap<IDateRange[]>, exc: IDateRange) => {
        const dateMs = dateTimeUtils.copyDate(exc.startDate).setHours(0, 0, 0, 0);
        (accum[dateMs] = accum[dateMs] || []).push(exc);

        return accum;
      }, {} as StringMap<IDateRange[]>);
    }

    const clearedAbsenceDays = _.reduce(absenceDays, (accum: StringMap<IDateRange[]>, absenceDay: IDateRange) => {
      const dateInMs = dateTimeUtils.copyDate(absenceDay.startDate).setHours(0, 0, 0, 0);
      const filteredExceptions = exceptionsDays[dateInMs];

      if (_.size(filteredExceptions) > 0) {
        const clearedAbsenceDay = this.clearAbsenceDay(absenceDay, filteredExceptions);
        if (_.keys(clearedAbsenceDay).length > 0) {
          const dayDateInMs = _.head(_.keys(clearedAbsenceDay));
          (accum[dayDateInMs] = accum[dayDateInMs] || []).push(...clearedAbsenceDay[dayDateInMs]);
        }
        return accum;
      }

      const dayDateInMs = dateTimeUtils.copyDate(absenceDay.startDate).setHours(0, 0, 0, 0);
      (accum[dayDateInMs] = accum[dayDateInMs] || []).push(absenceDay);
      return accum;
    }, {} as StringMap<IDateRange[]>);

    return clearedAbsenceDays;
  }

  private clearAbsenceDay(absenceDay: IDateRange, exceptionsForDay: IDateRange[]): StringMap<IDateRange[]> {
    const exceptionsLength = _.size(exceptionsForDay);

    return _.reduce(exceptionsForDay, (accum: StringMap<IDateRange[]>, excepDay: IDateRange, i: number) => {
      const absences: IDateRange[] = [];
      const nextExceptionStart = _.get(exceptionsForDay[i + 1], 'startDate');
      if (i === 0 && dateTimeUtils.getTime(absenceDay.startDate) !== dateTimeUtils.getTime(excepDay.startDate)) {
        absences.push(this.makeRange(absenceDay.startDate, excepDay.startDate));
      }

      if (_.isDate(nextExceptionStart)) {
        const startAbsence = dateTimeUtils.copyDate(excepDay.endDate);
        const endAbsence = dateTimeUtils.copyDate(nextExceptionStart);
        absences.push(this.makeRange(startAbsence, endAbsence));
      }

      if (i === exceptionsLength - 1 && dateTimeUtils.getTime(excepDay.endDate) !== dateTimeUtils.getTime(absenceDay.endDate)) {
        absences.push(this.makeRange(excepDay.endDate, absenceDay.endDate));
      }

      if (_.size(absences) > 0) {
        const dayDateInMs = dateTimeUtils.copyDate(absenceDay.startDate).setHours(0, 0, 0, 0);
        (accum[dayDateInMs] = accum[dayDateInMs] || []).push(...absences);
      }
      return accum;
    }, {} as StringMap<IDateRange[]>);
  }

  private createDaysFromRanges(ranges: IDateRange[]): IDateRange[] {
    return _.reduce(ranges, (accum: IDateRange[], r: IDateRange) => {
      accum.push(...this.createDays(r.startDate, r.endDate));

      return accum;
    }, []);
  }

  private createExceptionDays(exceptionsRanges: IDateRange[]): StringMap<IDateRange[]> {
    return _.reduce(exceptionsRanges, (accum: StringMap<IDateRange[]>, r: IDateRange) => {
      const groupedExceptions = this.createGroupedDays(r.startDate, r.endDate);
      _.forEach(groupedExceptions, (e: IDateRange, dateInMs: string) => {
        (accum[dateInMs] = accum[dateInMs] || []).push(e);
      });

      return accum;
    }, {} as StringMap<IDateRange[]>);
  }

  private createDays(sDate: Date, eDate: Date): IDateRange[] {
    const groupedDays = this.createGroupedDays(sDate, eDate);
    return _.values(groupedDays);
  }

  private createGroupedDays(sDate: Date, eDate: Date): StringMap<IDateRange> {
    const groupedDays: StringMap<IDateRange> = {};
    for (let index = 1, currentDay = dateTimeUtils.copyDate(sDate); currentDay < eDate; index++) {
      if (index > 1) {
        currentDay = new Date(currentDay.setDate(currentDay.getDate() + 1));
      }

      const day = this.getMinMaxOfDay(currentDay);
      const hasOverflow = day.endDate > eDate;
      if (day.startDate < sDate) {
        day.startDate = dateTimeUtils.copyDate(sDate);
      }
      if (hasOverflow) {
        day.endDate = dateTimeUtils.copyDate(eDate);
      }
      const endDateCopy = dateTimeUtils.copyDate(day.endDate);
      if (endDateCopy.setHours(23, 59, 0, 0) === dateTimeUtils.getTime(day.endDate)) {
        day.endDate = new Date(endDateCopy.setHours(23, 59, 59, 999));
      }
      if (dateTimeUtils.getTime(day.startDate) !== dateTimeUtils.getTime(day.endDate)) {
        const dateInMs = dateTimeUtils.copyDate(day.startDate).setHours(0, 0, 0, 0);
        groupedDays[dateInMs] = day;
      }

      if (hasOverflow) break;
    }

    return groupedDays;
  }

  private getLoaDates(req: LoaRequest): IDateRange {
    let sDate = req.estStartDate;
    let eDate = req.estEndDate;
    if (_.isDate(req.actStartDate)) {
      sDate = req.actStartDate;
    }
    if (_.isDate(req.actEndDate)) {
      eDate = req.actEndDate;
    }

    return new DateRange(sDate, eDate);
  }

  private getExceptions(req: LoaRequest): IDateRange[] {
    const exceptionDates = req.exceptionDates;
    return this.sortRange(exceptionDates);
  }

  private getCustomDates(req: LoaRequest): IDateRange[] {
    const customDates = req.loaRepeat.customDates;
    return this.sortRange(customDates);
  }

  private parseRule(rrule: string, firstDayOfWeek: number): RecurrenceRule {
    return parseRule({
      recurrenceRule: rrule,
      weekStart: firstDayOfWeek
    });
  }

  private sortRange(r: IDateRange[]): IDateRange[] {
    if (_.isArray(r) && _.size(r) > 0) {
      return _.sortBy(r, (range) => dateTimeUtils.getTime(range.startDate));
    }
    return [];
  }

  private getMinMaxOfDay(date: Date | number): IDateRange {
    const d = moment(dateTimeUtils.copyDate(date));

    return new DateRange(d.startOf('day').toDate(), d.endOf('day').toDate());
  }

  private makeRange(s: number | Date, e: number | Date): IDateRange {
    return new DateRange(dateTimeUtils.copyDate(s), dateTimeUtils.copyDate(e));
  }
}
