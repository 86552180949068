import { IScheduleCycleMessages, ScheduleCycleMessages } from '../../../../organization/models/index';

export interface IRotationModifyResponse {
  generateScheduleMessages: IScheduleCycleMessages;
  reGenerated: boolean;
}

export class RotationModifyResponse {
  generateScheduleMessages: ScheduleCycleMessages;
  reGenerated: boolean;
}
