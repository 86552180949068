import { ScheduledShiftDefinition, EmployeeDefinition } from '../../../../organization/models/index';
import { MasterScheduleEntryCell, MasterScheduleShiftInfo } from '../master-schedule-cell';

export type ScheduleEntryEditCommandDefinition = 'remove' | 'modify';

export class ScheduleEntryEditCommand {
  public static remove: ScheduleEntryEditCommandDefinition = 'remove';
  public static modify: ScheduleEntryEditCommandDefinition = 'modify';
}

export class ScheduleEntryEditShift {
  public scheduledShift: MasterScheduleShiftInfo;
  public startDate: Date;
  public endDate: Date;
  public duration: number;
}

export class ScheduleEntryEditItem {
  public employeeName: string;
  public employeeId: number;
  public date: Date;
  public shift: MasterScheduleShiftInfo;
  public command: ScheduleEntryEditCommand;
  public cellForUndo: MasterScheduleEntryCell;
  public shiftsToRemove: MasterScheduleShiftInfo[];
  public shiftsToModify: ScheduleEntryEditShift[];
}
