import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { StateManagementService, ColumnManagementService, ModalService, DialogOptions, ConfirmDialog2Component, ConfirmOptions } from '../../../../common';
import { Aca1095cManagementService } from '../../../services/aca-1095-c/aca-1095-c-management.service';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { EmployeeFilterRecord, EmployeeFilter } from '../../../models/index';
import { Aca1095cSettings, AcaMonthRecord, Aca1095cColumnsSettings, aca1095CPopupAction, Aca1095cActions, Aca1095cRecord, acaQuickPopupState } from '../../../models/aca-1095-c';
import { Aca1095CAddEmployeePopupComponent } from '../add-employee-popup/add-employee-popup.component';
import { Edit1095CPopup } from '../edit-1095-c-popup/edit-1095-c-popup.component';
import { AcaOfferOfCoverage, AcaSafeHarbor, AcaReason } from '../../../../organization/models/index';
import { ACA1095cConfig } from '../../../aca-1095.config';
var Aca1095cToolbarComponent = /** @class */ (function () {
    function Aca1095cToolbarComponent(acaManagementService, columnManagementService, stateManagement, modalService) {
        this.acaManagementService = acaManagementService;
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.modalService = modalService;
        this.defaultYear = moment().startOf('day').toDate().getFullYear();
        this.yearOptions = [];
        this.disabledEmployees = [];
        this.filter = [];
        this.reportClosed = "This reporting year has been closed out";
        this.isExpand = false;
        this.columnsStateName = 'Aca1095c';
        this.editItems = [];
        this.editResetItems = [];
        this.confirmItems = [];
        this.confirmResetItems = [];
        this.acaSelectedSafeHarbors = new AcaSafeHarbor();
        this.acaSelectedReasons = new AcaReason();
        this.acaSelectedOfferOfCoverage = new AcaOfferOfCoverage();
        this.isEditResetTabActive = false;
        this.isConfirmResetTabActive = false;
        this.quickEditWindowTop = 42;
        this.quickEditWindowLeft = 800;
        this.quickEditWidth = 450;
        this.quickEditHeight = 260;
        this.subscriptions = {};
    }
    Object.defineProperty(Aca1095cToolbarComponent.prototype, "closeOutTitle", {
        get: function () {
            if (this.isEditableYear) {
                return 'Not available until April.';
            }
            return this.reportClosed;
        },
        enumerable: true,
        configurable: true
    });
    Aca1095cToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createYearOptions();
        this.selectedYear = this.defaultYear;
        this.subscriptions.onMarkItemForQickEdit = this.acaManagementService.subscribeToShowPopupMessage(function (message) {
            _this.warningPopup(message);
        });
        this.subscriptions.onMarkItemForQickEdit = this.acaManagementService.subscribeToQuickEditRecords(function (items) {
            _this.editItems = items;
        });
        this.subscriptions.onMarkItemForQickConfirm = this.acaManagementService.subscribeToQuickConfirmRecords(function (items) {
            _this.confirmItems = items;
        });
        this.subscriptions.onMarkItemForQickEditReset = this.acaManagementService.subscribeToQuickEditResetRecords(function (items) {
            _this.editResetItems = items;
        });
        this.subscriptions.onMarkItemForQickConfirmReset = this.acaManagementService.subscribeToQuickConfirmResetRecords(function (items) {
            _this.confirmResetItems = items;
        });
        this.subscriptions.quickPopupstate = this.acaManagementService.subscribeToQuickPopupState(function (quickPopupState) {
            switch (quickPopupState) {
                case acaQuickPopupState.quickEditClose:
                    {
                        _this.editItems = [];
                        _this.editResetItems = [];
                        _this.closeQuickEdit();
                        break;
                    }
                case acaQuickPopupState.quickConfirmClose:
                    {
                        _this.confirmItems = [];
                        _this.confirmResetItems = [];
                        _this.closeQuickConfirm();
                        break;
                    }
                case acaQuickPopupState.quickEditResetActive:
                    {
                        _this.openQuickEditResetTab();
                        break;
                    }
                case acaQuickPopupState.quickEditActive:
                    {
                        _this.openQuickEditTab();
                        break;
                    }
                case acaQuickPopupState.quickConfirmResetActive:
                    {
                        _this.openQuickConfirmResetTab();
                        break;
                    }
                case acaQuickPopupState.quickConfirmActive:
                    {
                        _this.openQuickConfirmTab();
                        break;
                    }
            }
        });
        this.subscriptions.onOrgLevelChange = this.acaManagementService.subscribeToOrgLevel(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
        this.subscriptions.populateCellData = this.acaManagementService.subscribeToQuickEditInitData(function (data) {
            _this.quickEditInitData = data;
        });
        this.subscriptions.onAddRemoveEmployee = this.acaManagementService.subscribeToIsAddRemoveEmployee(function (aca1095cRecord) {
            if (_.isNil(aca1095cRecord)) {
                _this.openAddEmployee();
            }
            else {
                _this.confirmRemoveEmployee(aca1095cRecord);
            }
        });
        this.subscriptions.onEditOrConfirm = this.acaManagementService.subscribeToInit1095Popup(function (popupInitData) {
            if (_.isEqual(popupInitData.popupAction, aca1095CPopupAction.edit1095Record)) {
                if (!_this.isQuickEditActive && !_this.isQuickConfirmActive) {
                    _this.initPopupDropdowns();
                    _this.singleEditInitData = popupInitData;
                    _this.acaSelectedReasons.id = popupInitData.params.reason;
                    _this.acaSelectedReasons.name = popupInitData.params.reason;
                    _this.acaSelectedEnrolledInd = popupInitData.params.enrolledInd;
                    if (popupInitData.params.selectedCode || popupInitData.params.selectedHarbor || popupInitData.params.selectedCost) {
                        _this.acaSelectedOfferOfCoverage.id = popupInitData.params.selectedCode;
                        _this.acaSelectedOfferOfCoverage.name = popupInitData.params.selectedCode;
                        _this.acaSelectedSafeHarbors.id = popupInitData.params.selectedHarbor;
                        _this.acaSelectedSafeHarbors.name = popupInitData.params.selectedHarbor;
                        _this.acaSelectedCost = popupInitData.params.selectedCost;
                    }
                    else {
                        _this.acaSelectedOfferOfCoverage.id = popupInitData.params.suggestedCode;
                        _this.acaSelectedOfferOfCoverage.name = popupInitData.params.suggestedCode;
                        _this.acaSelectedSafeHarbors.id = popupInitData.params.suggestedHarbor;
                        _this.acaSelectedSafeHarbors.name = popupInitData.params.suggestedHarbor;
                        _this.acaSelectedCost = popupInitData.params.suggestedCost;
                    }
                    _this.openEditRecord();
                }
            }
            else if (_.isEqual(popupInitData.popupAction, aca1095CPopupAction.quickEditRecord)) {
                _this.openPopup(aca1095CPopupAction.quickEditRecord);
                if (popupInitData.params) {
                    _this.quickEditInitData = popupInitData.params;
                    _this.acaSafeHarbors = _this.acaManagementService.acaSafeHarbor;
                    _this.acaOfferOfCoverage = _this.acaManagementService.acaOfferOfCoverage;
                    _this.acaReasons = _this.acaManagementService.acaReason;
                }
            }
        });
        this.subscriptions.onLoadedActions = this.acaManagementService
            .subscribeToLoadedRecords(function (container) {
            if (!_this.actions) {
                _this.actions = new Aca1095cActions();
                _this.actions = container.actions;
                _this.initServices();
            }
            _this.disabledEmployees = container.records;
        });
        this.subscriptions.onLoaded = this.acaManagementService
            .subscribeToEmployeeFilter(function (records) {
            _this.filter = records.filter;
            _this.employeeFilterRecords = records.employeeFilterRecord;
        });
    };
    Aca1095cToolbarComponent.prototype.initPopupDropdowns = function () {
        this.acaSafeHarbors = this.acaManagementService.acaSafeHarbor;
        this.acaOfferOfCoverage = this.acaManagementService.acaOfferOfCoverage;
        this.acaReasons = this.acaManagementService.acaReason;
    };
    Aca1095cToolbarComponent.prototype.createYearOptions = function () {
        this.yearOptions = _.reverse(_.values(_.range(2015, this.defaultYear + 1)));
    };
    Aca1095cToolbarComponent.prototype.ngOnDestroy = function () { };
    Aca1095cToolbarComponent.prototype.onQuickWindowDragEnd = function () {
        var offset = 70;
        if (this.quickEditWindowTop < 0) {
            this.quickEditWindowTop = 0;
        }
        else if (this.quickEditWindowTop > window.innerHeight - this.quickEditHeight - offset) {
            this.quickEditWindowTop = window.innerHeight - this.quickEditHeight - offset;
        }
        if (this.quickEditWindowLeft < 0) {
            this.quickEditWindowLeft = 0;
        }
        else if (this.quickEditWindowLeft > window.innerWidth - this.quickEditWidth - offset) {
            this.quickEditWindowLeft = window.innerWidth - this.quickEditWidth - offset;
        }
    };
    Aca1095cToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.acaManagementService.exportTo(isPDF);
    };
    Aca1095cToolbarComponent.prototype.onClickExpand = function (isExpand) {
        this.isExpand = isExpand;
        this.acaManagementService.expandAll(isExpand);
    };
    Aca1095cToolbarComponent.prototype.confirmRemoveEmployee = function (aca1095cRecord) {
        var _this = this;
        var message = '';
        message = 'Removing this employee from the list means that they will not be receiving a 1095 C for this record and the selected year. Their information will also not be counted as part of any ACA reporting for the selected year. Are you sure you want to proceed?';
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Ok';
        options.buttonCanceltext = 'Cancel';
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            if (result) {
                _this.acaManagementService.removeEmployee(aca1095cRecord.employeeId, aca1095cRecord.employeeMasterId, aca1095cRecord.companyId);
            }
            else {
            }
        }, options);
    };
    Aca1095cToolbarComponent.prototype.confirmQuickClose = function (popupState) {
        var _this = this;
        var message = '';
        message = 'The changes you made will not be saved. Are you sure you want to proceed?';
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Ok';
        options.buttonCanceltext = 'Cancel';
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            if (result) {
                _this.acaManagementService.setQuickPopupState(popupState);
            }
            else {
            }
        }, options);
    };
    Aca1095cToolbarComponent.prototype.warningPopup = function (message) {
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        options.buttonOKtext = 'Ok';
        options.buttonCanceltext = 'Close';
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            if (result) {
            }
            else {
            }
        }, options);
    };
    Aca1095cToolbarComponent.prototype.openAddEmployee = function () {
        var _this = this;
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 550;
        dialogOptions.height = 300;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.hideTitleBar = true;
        var dialog = this.modalService.globalAnchor.openDialog(Aca1095CAddEmployeePopupComponent, 'Add Employee 1095C', dialogOptions, undefined, function (result) {
            if (result) {
                _this.employee = dialog.employee;
                _this.acaManagementService.addEmployee(_this.employee.id, _this.employee.masterId, 0, _this.employee.department.id, _this.employee.organization.id);
            }
        });
        if (dialog) {
            dialog.orgLevel = this.orgLevel;
            dialog.disabledEmployees = this.disabledEmployees;
            dialog.year = this.selectedYear;
            dialog.loadSubsection();
        }
    };
    Aca1095cToolbarComponent.prototype.openPopup = function (popupType) {
        if (_.isEqual(popupType, aca1095CPopupAction.edit1095Record)) {
            this.openEditRecord();
        }
        else if (_.isEqual(popupType, aca1095CPopupAction.quickEditRecord)) {
            this.openQuickEdit();
        }
        else if (_.isEqual(popupType, aca1095CPopupAction.quickEditRecord)) {
            this.openQuickConfirm();
        }
    };
    Aca1095cToolbarComponent.prototype.closePopup = function (popupType) {
        if (_.isEqual(popupType, aca1095CPopupAction.quickEditRecord)) {
            this.closeQuickEdit();
        }
        else if (_.isEqual(popupType, aca1095CPopupAction.quickConfirmRecord)) {
            this.closeQuickConfirm();
        }
    };
    Aca1095cToolbarComponent.prototype.openQuickEdit = function () {
        if (!this.isQuickEditActive) {
            this.initPopupDropdowns();
            this.isQuickEditActive = true;
            this.isEditResetTabActive = false;
        }
    };
    Aca1095cToolbarComponent.prototype.openQuickEditTab = function () {
        if (this.editResetItems && this.editResetItems.length > 0) {
            this.confirmQuickClose(acaQuickPopupState.quickEditResetClear);
        }
        else {
            if (this.isEditResetTabActive) {
                this.isEditResetTabActive = false;
                this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditActive);
            }
        }
    };
    Aca1095cToolbarComponent.prototype.openQuickEditResetTab = function () {
        if (this.editItems && this.editItems.length > 0) {
            this.confirmQuickClose(acaQuickPopupState.quickEditClear);
        }
        else {
            if (!this.isEditResetTabActive) {
                this.isEditResetTabActive = true;
                this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditResetActive);
            }
        }
    };
    Aca1095cToolbarComponent.prototype.openQuickConfirm = function () {
        if (!this.isQuickConfirmActive) {
            this.initPopupDropdowns();
            this.isQuickConfirmActive = true;
            this.isConfirmResetTabActive = false;
        }
    };
    Aca1095cToolbarComponent.prototype.openQuickConfirmTab = function () {
        if (this.confirmResetItems && this.confirmResetItems.length > 0) {
            this.confirmQuickClose(acaQuickPopupState.quickConfirmResetClear);
        }
        else {
            if (this.isConfirmResetTabActive) {
                this.isConfirmResetTabActive = false;
                this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmActive);
            }
        }
    };
    Aca1095cToolbarComponent.prototype.openQuickConfirmResetTab = function () {
        if (this.confirmItems && this.confirmItems.length > 0) {
            this.confirmQuickClose(acaQuickPopupState.quickConfirmClear);
        }
        else {
            if (!this.isConfirmResetTabActive) {
                this.isConfirmResetTabActive = true;
                this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmResetActive);
            }
        }
    };
    Aca1095cToolbarComponent.prototype.closeQuickEdit = function () {
        if (this.editResetItems && this.editResetItems.length > 0 && this.isEditResetTabActive) {
            this.confirmQuickClose(acaQuickPopupState.quickEditResetClearAndClose);
        }
        else if (this.editItems && this.editItems.length > 0 && !this.isEditResetTabActive) {
            this.confirmQuickClose(acaQuickPopupState.quickEditClearAndClose);
        }
        else {
            if (this.isQuickEditActive) {
                this.isQuickEditActive = false;
                this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditClose);
            }
        }
    };
    Aca1095cToolbarComponent.prototype.closeQuickConfirm = function () {
        if (this.confirmResetItems && this.confirmResetItems.length > 0 && this.isConfirmResetTabActive) {
            this.confirmQuickClose(acaQuickPopupState.quickConfirmResetClearAndClose);
        }
        else if (this.confirmItems && this.confirmItems.length > 0 && !this.isConfirmResetTabActive) {
            this.confirmQuickClose(acaQuickPopupState.quickConfirmClearAndClose);
        }
        else {
            if (this.isQuickConfirmActive) {
                this.isQuickConfirmActive = false;
                this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
            }
        }
    };
    Aca1095cToolbarComponent.prototype.openEditRecord = function () {
        var _this = this;
        var acaRecords = new Aca1095cRecord();
        acaRecords.employeeId = this.singleEditInitData.employeeId;
        acaRecords.employeeMasterId = this.singleEditInitData.employeeMasterId;
        acaRecords.companyId = this.singleEditInitData.companyId;
        var acaMonthData = new AcaMonthRecord();
        acaMonthData = this.singleEditInitData.params;
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 450;
        dialogOptions.height = 280;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.hideTitleBar = true;
        var dialog = this.modalService.globalAnchor.openDialog(Edit1095CPopup, 'Edit 1095 Records', dialogOptions, undefined, function (result) {
            if (result) {
                acaMonthData.selectedHarbor = dialog.selectedHarbor.id;
                acaMonthData.selectedCode = dialog.selectedCoverage.id;
                acaMonthData.selectedCost = dialog.selectedCost;
                acaMonthData.reason = dialog.selectedReason.id;
                acaMonthData.enrolledInd = dialog.isEnrolled;
                acaRecords.months = [acaMonthData];
                _this.acaManagementService.submitQuick1095cRecords([acaRecords], acaQuickPopupState.quickEditSave);
            }
        });
        if (dialog) {
            dialog.harborOptions = this.acaSafeHarbors;
            dialog.coverageOptions = this.acaOfferOfCoverage;
            dialog.reasonOptions = this.acaReasons;
            dialog.selectedHarbor = this.acaSelectedSafeHarbors;
            dialog.selectedCoverage = this.acaSelectedOfferOfCoverage;
            dialog.selectedReason = this.acaSelectedReasons;
            dialog.isEnrolled = this.acaSelectedEnrolledInd;
            dialog.selectedCost = this.acaSelectedCost;
        }
    };
    Aca1095cToolbarComponent.prototype.initServices = function () {
        this.restoreSettings();
        this.stateManagement.init('Aca1095cComponent');
        this.columnManagementService.init('Aca1095cComponent');
        this.columnManagementService.initGroup(this.columnsStateName, 6);
        this.columnManagementService.initializeGroupWithColumns(this.columnsStateName, this.settings.columns.columns);
    };
    Aca1095cToolbarComponent.prototype.restoreSettings = function () {
        this.settings = new Aca1095cSettings();
        this.settings.columns = new Aca1095cColumnsSettings();
        this.settings.columns.createColumns();
        this.settings.columns.applySecurity(this.actions);
    };
    Aca1095cToolbarComponent.prototype.getAcaWidth = function () {
        return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
    };
    Aca1095cToolbarComponent.prototype.onChangeYear = function (selYear) {
        this.selectedYear = selYear;
        this.isEditableYear = ACA1095cConfig.isEditableYear(selYear);
        this.acaManagementService.setSelectedYear(selYear);
    };
    Aca1095cToolbarComponent.prototype.onChangeFilter = function (filters) {
        this.acaManagementService.setEmployeeFilter(filters);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], Aca1095cToolbarComponent.prototype, "subscriptions", void 0);
    return Aca1095cToolbarComponent;
}());
export { Aca1095cToolbarComponent };
