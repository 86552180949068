/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./configure-agency.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./agency-mappings/agency-mappings.component.ngfactory";
import * as i3 from "./agency-mappings/agency-mappings.component";
import * as i4 from "../../services/agencies/agencies-map.service";
import * as i5 from "./agency-details/agency-details.component.ngfactory";
import * as i6 from "./agency-details/agency-details.component";
import * as i7 from "../../services/agencies/agency-details-management.service";
import * as i8 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i9 from "../../../common/components/spinner/spinner.component";
import * as i10 from "@angular/common";
import * as i11 from "../../services/accessManagement/access-management.service";
import * as i12 from "../../services/agencies/agencies-api.service";
import * as i13 from "../../../common/services/api/api-util.service";
import * as i14 from "../../../common/services/cache/cache.service";
import * as i15 from "./configure-agency.component";
import * as i16 from "../../../organization/services/lookup/lookup-api.service";
import * as i17 from "../../../organization/services/org-level/org-level-watch.service";
var styles_ConfigureAgencyComponent = [i0.styles];
var RenderType_ConfigureAgencyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfigureAgencyComponent, data: {} });
export { RenderType_ConfigureAgencyComponent as RenderType_ConfigureAgencyComponent };
function View_ConfigureAgencyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-agency-mappings", [["class", "slx-full-height"]], null, [[null, "onSaveAgency"], [null, "onRemoveAgency"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSaveAgency" === en)) {
        var pd_0 = (_co.onSaveAgency($event) !== false);
        ad = (pd_0 && ad);
    } if (("onRemoveAgency" === en)) {
        var pd_1 = (_co.onRemoveAgency($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_AgencyMappingsComponent_0, i2.RenderType_AgencyMappingsComponent)), i1.ɵdid(1, 114688, [[1, 4], ["agencyMappingsComponent", 4]], 0, i3.AgencyMappingsComponent, [i4.AgenciesMapService], { orglevelId: [0, "orglevelId"], isOrganization: [1, "isOrganization"], agencyMappings: [2, "agencyMappings"], organizations: [3, "organizations"] }, { onSaveAgency: "onSaveAgency", onRemoveAgency: "onRemoveAgency" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.effectiveOrgLevelId; var currVal_1 = (_co.state.organizationSelected || _co.state.departmentSelected); var currVal_2 = _co.agencyMappings; var currVal_3 = _co.organizations; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ConfigureAgencyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-agency-details", [], null, null, null, i5.View_AgencyDetailsComponent_0, i5.RenderType_AgencyDetailsComponent)), i1.ɵdid(1, 245760, null, 0, i6.AgencyDetailsComponent, [i7.AgencyDetailsManagementService], { orglevelId: [0, "orglevelId"], agencyMapping: [1, "agencyMapping"], organizations: [2, "organizations"], standaloneMode: [3, "standaloneMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.effectiveOrgLevelId; var currVal_1 = _co.agencyMapping; var currVal_2 = _co.organizations; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ConfigureAgencyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { mappingsComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "slx-spinner", [], null, null, null, i8.View_SpinnerComponent_0, i8.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i9.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfigureAgencyComponent_1)), i1.ɵdid(4, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ConfigureAgencyComponent_2)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.state.agencySelected; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.stateManagement.isDepartment && _co.state.agencySelected); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ConfigureAgencyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-configure-agency", [], null, null, null, View_ConfigureAgencyComponent_0, RenderType_ConfigureAgencyComponent)), i1.ɵprd(512, null, i11.AccessManagementService, i11.AccessManagementService, []), i1.ɵprd(512, null, i12.AgenciesApiService, i12.AgenciesApiService, [i13.ApiUtilService, i4.AgenciesMapService, i14.CacheUtilService]), i1.ɵprd(512, null, i7.AgencyDetailsManagementService, i7.AgencyDetailsManagementService, [i12.AgenciesApiService, i4.AgenciesMapService]), i1.ɵdid(4, 245760, null, 0, i15.ConfigureAgencyComponent, [i11.AccessManagementService, i12.AgenciesApiService, i16.LookupApiService, i7.AgencyDetailsManagementService, i17.OrgLevelWatchService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var ConfigureAgencyComponentNgFactory = i1.ɵccf("slx-configure-agency", i15.ConfigureAgencyComponent, View_ConfigureAgencyComponent_Host_0, {}, {}, []);
export { ConfigureAgencyComponentNgFactory as ConfigureAgencyComponentNgFactory };
