<div class="dates-tab">
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Actual Start Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        [(ngModel)]="actStartDate"
        (ngModelChange)="onChangeActStartDate($event)"
        [maxDateTime]="actEndDate"
        [readonly]="!canEditRequest"
        [readonlyOnlyTime]="actAllDay"
        [readonlyTimeWithoutDate]="true"
        #actualStartDate="ngModel"
        ></slx-datetimepicker>
      <slx-checkbox-button
        class="dates-tab__checkbox"
        [(ngModel)]="actAllDay"
        (ngModelChange)="onChangeActAllDay()"
        [readonly]="!canEditRequest || !actStartDate || !actEndDate"
        name="actAllDay"
        placeholder="All Day"
      ></slx-checkbox-button>
    </span>
  </p>
  <p class="date_error_message" *ngIf="actualStartDate.errors">
    <span class="slx-error-block error-box" *ngIf="actualStartDate.errors.date" errorMessage>Date is out of valid range</span>
    <span class="slx-error-block error-box" *ngIf="actualStartDate.errors.maxDateTime" errorMessage>Cannot be greater than {{ actEndDate | amDateFormat: appConfig.dateTimeFormatUS}}</span>
  </p>
  <p class="date_error_message" *ngIf="isValidActStartDate">
      <span *ngIf="isValidActStartDate" class="slx-error-block error-box">
          Required field
      </span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Actual End Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        [(ngModel)]="actEndDate"
        (ngModelChange)="onChangeActEndDate($event)"
        [minDateTime]="actStartDate"
        [readonly]="!canEditRequest"
        [readonlyOnlyTime]="actAllDay"
        [readonlyTimeWithoutDate]="true"
        #actualEndDate="ngModel"
      ></slx-datetimepicker>
      <span class="dates-tab__checkbox"></span>
    </span>
  </p>
  <p class="date_error_message" *ngIf="actualEndDate.errors">
    <span class="slx-error-block error-box" *ngIf="actualEndDate.errors.date" errorMessage>Date is out of valid range</span>
    <span  class="slx-error-block error-box" *ngIf="actualEndDate.errors.minDateTime" errorMessage>Cannot be less than {{ actStartDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
  </p>
  <p class="date_error_message" *ngIf="isValidActEndDate">
      <span *ngIf="isValidActEndDate" class="slx-error-block error-box">
          Required field
      </span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label"></label>
    <span class="flex__grow">
      <span class="slx-error-block dates-tab__error" *ngIf="hasOverlapping">Actual date ranges shouldn't overlap each other</span>
      <span class="slx-error-block dates-tab__error" *ngIf="hasExceeded">Actual date range overlaps with one or more exceptions. Change the actual date range or delete the exception(s) to continue.</span>
      <button class="slx-button slx-with-icon slx-margin-r" type="button" [disabled]="!canEditRequest || !canAddDate || !actualStartDate.valid || !actualStartDate.valid" (click)="onClickAddDate()">
        <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
        <span class="slx-button__text">Add Date(s)</span>
      </button>
    </span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label"></label>
    <span class="dates-tab__added-text">Date(s) added:</span>
  </p>
  <div class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label"></label>
    <div class="flex__grow">
      <ng-container *ngIf="hasDates">
        <p class="flex align-items__center dates-tab__date" *ngFor="let item of customDates; index as i">
          <i aria-hidden="true" class="fal fa-file dates-tab__i-file"></i>
          <span class="flex__grow dates-tab__name">
            {{ item.startDate | amDateFormat: appConfig.dateTimeFormatUS }} - {{ item.endDate | amDateFormat: appConfig.dateTimeFormatUS }}
          </span>
          <i aria-hidden="true"
            *ngIf="canEditRequest"
            (click)="onDeleteDate(i)"
            [class.disable-delete-btn]="!canEditRequest"
            class="fal fa-trash-alt dates-tab__i-delete"
            title="Delete dates"
          ></i>
        </p>
      </ng-container>
      <p class="dates-tab__no-files" *ngIf="!hasDates">No dates added...</p>
    </div>
  </div>
</div>
