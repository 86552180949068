import { VacationPlanEmployeeRecordDetails, IVacationPlanEmployeeRecordDetails } from './index';
import { EmployeeDefinition, IEmployeeDefinition } from '../../../organization/models/index';

export interface IVacationPlanEmployeeDetails {
  employee: IEmployeeDefinition;
  records: IVacationPlanEmployeeRecordDetails[];
}

export class VacationPlanEmployeeDetails {
  // public static mock: IVacationPlanEmployeeDetails = {
  //   employee: {
  //     id: 3,
  //     name: 'test3',
  //     dateHired: new Date(2003, 4, 1),
  //     payrollNumber: 'ttttt4',
  //     employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //     mobilePhoneNumber: '8 (911) 123 1234',
  //     phoneNumber: '8 (911) 123 1234'
  //   },
  //   records: [{
  //     date: new Date(2003, 4, 1),
  //     isScheduled: true,
  //     isUnpaidAbsence: false,
  //     isRotationDay: true,
  //     scheduleAbsence: {
  //       code: 'CODE1',
  //       description: 'dfds',
  //       color: 0,
  //       isPaid: true,
  //       isVacation: false
  //     }
  //   }, {
  //     date: new Date(2003, 4, 2),
  //     isScheduled: true,
  //     isUnpaidAbsence: false,
  //     isRotationDay: true,
  //     scheduleAbsence: {
  //       code: 'CODE1',
  //       description: 'dfds',
  //       color: 0,
  //       isPaid: true,
  //       isVacation: false
  //     }
  //   }
  //   ]
  // };
  public employee: EmployeeDefinition;
  public records: VacationPlanEmployeeRecordDetails[];
}
