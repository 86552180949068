import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/combineLatest';
import { Actions } from '../../../core/models/field/actions-type';
import { ManagementBaseService } from '../../../core/services/index';
import { LookupType } from '../../../organization/models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { LookupService } from '../../../organization/services/lookup/lookup.service';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { HolidaysContainer } from '../../models/holidays/holidays-container';
import { HolidaysApiService } from '../../services/holidays/holidays-api.service';
import { OrgLevelWatchService } from '../../../organization/services/index';
var HolidaysConfigurationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(HolidaysConfigurationManagementService, _super);
    function HolidaysConfigurationManagementService(access, changeService, api, lookup, orgLevelWatchService) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.lookup = lookup;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.m_container = new HolidaysContainer();
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.onItemSaved$ = new ReplaySubject();
        _this.onItemRemoved$ = new ReplaySubject();
        _this.onDataChanged$ = new Subject();
        _this.viewRefresh$ = new Subject();
        return _this;
    }
    Object.defineProperty(HolidaysConfigurationManagementService.prototype, "holidayContainer", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysConfigurationManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    HolidaysConfigurationManagementService.prototype.init = function () {
        var _this = this;
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = false;
        this.lookup.getLookup({ lookupType: LookupType.months })
            .then(function (months) {
            _this.months = months.items;
            _this.orgLevelSubscription = _this.orgLevel$
                .combineLatest(_this.orgLevelWatchService.orgLevelTreeLoaded$)
                .map(function (value) { return value[0]; })
                .subscribe(function (orgLevel) {
                if (!_this.currentOrgLevel || _this.currentOrgLevel.id !== orgLevel.id) {
                    _this.currentOrgLevel = _this.orgLevelWatchService.getOrgLevelById(orgLevel.id);
                    _this.access.orgLevelType = _this.currentOrgLevel.type;
                    _this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
                    if (_this.currentOrgLevel.type === OrgLevelType.organization) {
                        _this.fetchRecords();
                    }
                    _this.m_initialized = true;
                }
            });
        });
    };
    HolidaysConfigurationManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    HolidaysConfigurationManagementService.prototype.openCopyItems = function () {
        this.onStateChanged$.next({ configureMode: false, copyMode: true });
    };
    HolidaysConfigurationManagementService.prototype.closeCopyItems = function () {
        this.onStateChanged$.next({ configureMode: true, copyMode: false });
    };
    Object.defineProperty(HolidaysConfigurationManagementService.prototype, "year", {
        get: function () {
            return this.m_currentYear;
        },
        enumerable: true,
        configurable: true
    });
    HolidaysConfigurationManagementService.prototype.setYear = function (year) {
        this.m_currentYear = year;
        if (this.m_initialized && this.currentOrgLevel.type === OrgLevelType.organization) {
            this.fetchRecords();
        }
    };
    HolidaysConfigurationManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    HolidaysConfigurationManagementService.prototype.onAddItem = function (item) {
        this.markAsDirty();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    };
    HolidaysConfigurationManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    HolidaysConfigurationManagementService.prototype.onCancelEditItem = function () {
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    };
    HolidaysConfigurationManagementService.prototype.onRemoveItem = function (itemsToDelete) {
        this.removeItemsCmd$.next(itemsToDelete);
    };
    HolidaysConfigurationManagementService.prototype.doRemoveItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.removeHolidays(item.id)
            .then(function () {
            _this.access.lockActions = false;
            _this.onItemRemoved$.next(item);
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () { return _this.update(); });
    };
    HolidaysConfigurationManagementService.prototype.onSaveItem = function (info) {
        if (info.isNew) {
            this.addItem(info.dataItem);
        }
        else {
            this.updateItem(info.dataItem);
        }
    };
    HolidaysConfigurationManagementService.prototype.addItem = function (item) {
        var _this = this;
        _.each(this.m_container.records, function (p) {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.addHoliday(item, this.currentOrgLevel.relatedItemId, this.m_currentYear)
            .then(function (items) {
            _this.changeService.clearChanges();
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.onItemSaved$.next(item);
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () { return _this.update(); });
    };
    HolidaysConfigurationManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.editHoliday(item)
            .then(function (items) {
            _this.changeService.clearChanges();
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.onItemSaved$.next(item);
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () { return _this.update(); });
    };
    HolidaysConfigurationManagementService.prototype.fetchRecords = function () {
        var _this = this;
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getHolidays(this.currentOrgLevel.relatedItemId, this.m_currentYear)
            .then(function (records) {
            _this.changeService.clearChanges();
            _.each(records, function (holiday) {
                holiday.months = _this.months;
            });
            _this.m_container = new HolidaysContainer();
            _this.m_container.records = records;
            var actions = new Actions();
            actions.canAdd = actions.canEdit = actions.canDelete = true;
            _this.access.actions = actions;
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.onLoaded$.next(_this.holidayContainer);
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    HolidaysConfigurationManagementService.prototype.update = function () {
        this.access.lockActions = false;
        this.viewRefresh$.next(false);
        this.onStateChanged$.next({ isLoading: false });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], HolidaysConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysConfigurationManagementService.prototype, "orgLevelSubscription", void 0);
    return HolidaysConfigurationManagementService;
}(ManagementBaseService));
export { HolidaysConfigurationManagementService };
