import { ApiUtilService } from './../../../../common/services/api/api-util.service';
import { OpenWeatherMapApiService } from './open-weather-map/open-weather-map.api.service';
import { ICurrentWeather, IOpenWeatherMapCurrentWeatherResponse, IOpenWeatherMapLocationRequest } from './../../models/current-weather.model';
import { weatherConfig } from './../../angular-weather-widget.config';
import { ResponseBody } from '../../../../core/models/index';
import { Meta } from '../../../../core/models/api/meta';
import { Injectable } from '@angular/core';
import { HttpParams, HttpRequest } from '@angular/common/http';
import { WeatherQueryParams } from '../../weather.interfaces';
import { UrlParamsService } from './../../../../common/services/url-params/url-params.service';
import { CacheType } from '../../../../common/models/cache/cache-definition';

@Injectable()
export class WeatherApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: OpenWeatherMapApiService
  ) {}

  public getWeather(queryParams: WeatherQueryParams, updateCacheForced: boolean = false): Promise<ICurrentWeather> {
    const url: string = `${this.getWeatherApiRoot()}/${weatherConfig.api.current}`;
    let params: IOpenWeatherMapLocationRequest = this.mapService.mapQueryParams(queryParams);
    let request: HttpRequest<any> =  this.urlParamsService.createGetRequest(url, params);

    let promise: Promise<ICurrentWeather> =
      this.apiUtilService
        .cachedRequest<IOpenWeatherMapCurrentWeatherResponse, Meta>(request, CacheType.longTerm, updateCacheForced)
        .then((response: ResponseBody<IOpenWeatherMapCurrentWeatherResponse, Meta>) => this.mapService.mapCurrentWeatherResponse(response.data));
    return promise;
  }

  private getWeatherApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${weatherConfig.api.root}`;
  }

  private getQueryParams(obj: { [key: string]: any }): HttpParams {
    let queryParams = new HttpParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key]) {
          queryParams = queryParams.set(key.toString(), obj[key]);
        }
      }
    }
    return queryParams;
  }
}
