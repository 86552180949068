import { EventEmitter } from '@angular/core';
var DisableClickDirective = /** @class */ (function () {
    function DisableClickDirective() {
        this.managedClick = new EventEmitter();
    }
    Object.defineProperty(DisableClickDirective.prototype, "disabled", {
        get: function () {
            return this.disableClick;
        },
        enumerable: true,
        configurable: true
    });
    DisableClickDirective.prototype.onClick = function (event) {
        if (!this.disableClick) {
            this.managedClick.next(event);
        }
    };
    return DisableClickDirective;
}());
export { DisableClickDirective };
