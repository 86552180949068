import * as _ from 'lodash';
import { ScheduleCycle } from '../../../organization/models/lookup/index';

export interface IAutoSchedulingConfig {
  daysBeforeGenerating: number;
  daysBeforePosting: number;
  enabled?: boolean;
}

export class AutoSchedulingConfig {
  public daysBeforeGenerating: number;
  public daysBeforePosting: number;
  public enabled: boolean;

  public scheduleCycle?: ScheduleCycle;
  public generatingDate?: string;
  public postingDate?: string;
  public postingDateInPast?: boolean;
  public postingDateBeforeGenerating?: boolean;

  constructor(config: IAutoSchedulingConfig) {
    this.daysBeforeGenerating = config.daysBeforeGenerating || 0;
    this.daysBeforePosting = config.daysBeforePosting || 0;
    this.enabled = config.enabled || false;
  }

  public get hasConfiguredGeneratingDate(): boolean {
    return _.size(this.generatingDate) > 0;
  }

  public get hasConfiguredPostingDate(): boolean {
    return _.size(this.postingDate) > 0;
  }

  public get hasConfiguredDates(): boolean {
    return this.hasConfiguredGeneratingDate && this.hasConfiguredPostingDate;
  }
}
