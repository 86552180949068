import { Injectable } from '@angular/core';
import { UserService } from '../../../core/services/index';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
@Injectable()
export class TimecardsCheckRightsService {
    private canApprove: boolean = false;
    private canEdit: boolean = false;
    constructor(private appSettingsManageService: AppSettingsManageService,
                private userService: UserService) {
        this.appSettingsManageService.getAppServerConfig().then((value: AppServerConfig) => {
            this.canApprove = value.userPermissions.canApproveOwnTimecard;  
            this.canEdit = value.userPermissions.canEditOwnTimecard;
        });        
    }
    
    public userCanApproveOwnTimecard(empId: number): boolean {
        if (this.userService.isEmployeeLinkedToCurrentUser(empId)) return this.canApprove;
        return true;
    }

    public userCanEditOwnTimecard(empId: number): boolean {
        if (this.userService.isEmployeeLinkedToCurrentUser(empId)) return this.canEdit;
        return true;
    }
}
