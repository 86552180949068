import { Injectable } from '@angular/core';
import { IUserMenuPinState, UserMenuPinState } from '../../models/user-menu-pin/user-menu-pin-state';

@Injectable({
  providedIn: 'root'
})
export class UserMenuPinMapService {

  constructor() { }

  public mapToResponse(dto: IUserMenuPinState): UserMenuPinState {
    let response: UserMenuPinState = new UserMenuPinState();
    response.isMenuPinned = dto.isMenuPinned;
    return response;
  }
}
