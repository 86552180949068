/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wizard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./wizard.component";
var styles_WizardComponent = [i0.styles];
var RenderType_WizardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WizardComponent, data: {} });
export { RenderType_WizardComponent as RenderType_WizardComponent };
function View_WizardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0, "done": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "a", [["class", "step"], ["data-toggle", "tab"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "number"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_co.currentStep === _v.context.$implicit.step), (_co.currentStep > _v.context.$implicit.step)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.description; _ck(_v, 9, 0, currVal_2); }); }
export function View_WizardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "nav nav-pills nav-justified steps"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WizardComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "progress progress-striped"], ["id", "bar"], ["role", "progressbar"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "progress-bar progress-bar-success"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "width": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "tab-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepDefinitions; _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 7, 0, _co.progress); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_WizardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-wizard", [], null, null, null, View_WizardComponent_0, RenderType_WizardComponent)), i1.ɵdid(1, 2342912, null, 1, i3.WizardComponent, [], null, null), i1.ɵqud(335544320, 1, { stepSwitch: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WizardComponentNgFactory = i1.ɵccf("slx-wizard", i3.WizardComponent, View_WizardComponent_Host_0, { currentStep: "currentStep" }, {}, ["[stepSwitch]"]);
export { WizardComponentNgFactory as WizardComponentNgFactory };
