import { RolesComponentAccess } from './role-access-definition';
import { IRoleComponent, RoleComponent } from './role-component';

export interface IRoleComponentsModule {
  id: number;
  name: string;
  components: IRoleComponent[];
}

export class RoleComponentsModule {
  public id: number;
  public name: string;
  public components: RoleComponent[];
  public access: RolesComponentAccess;
}

export class RoleComponentsModuleGroup {
  public name: string;
  public modules: RoleComponentsModule[];
  public access: RolesComponentAccess;
}
