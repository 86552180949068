import { ResponseBody } from './../../../core/models/api/response-body';
import { Meta } from './../../../core/models/api/meta';
import { IShiftSwapInfo, IShiftSwapContainer } from './../../models/shift-swap/shift-swap-info';
import { ApiUtilService, UrlParamsService } from './../../../common/services/index';
import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShiftSwapInfo } from '../../models/shift-swap/shift-swap-info';
import { ShiftSwapMapService } from './shift-swap-map.service';
import { schedulerConfig } from '../../scheduler.config';
import { ShiftSwapType } from '../../models/shift-swap/shift-swap-type';
import { ShiftSwapStatus } from '../../models/shift-swap/shift-swap-status';
import { dateTimeUtils } from './../../../common/utils';
import { ShiftSwapDetails, IShiftSwapDetails } from '../../models/shift-swap/shift-swap-details';

@Injectable()
export class ShiftSwapApiService {
    constructor(
        private apiUtilService: ApiUtilService,
        private mapService: ShiftSwapMapService,
        private urlParamsService: UrlParamsService) {
    }

    public getList(orgLevelId: number, startDate: Date, endDate: Date, type: ShiftSwapType, status: ShiftSwapStatus): Promise<ShiftSwapInfo[]> {
        const url: string = this.getShiftSwapApiRoot(orgLevelId);
        let params: StringMap<string> = {
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate),
            type: type ? type.toString() : null,
            status: !status || status === ShiftSwapStatus.All ? null : status.toString()
        };

        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, params);

        let promise: Promise<ShiftSwapInfo[]> = this.apiUtilService.request<IShiftSwapContainer, Meta>(request)
        .then((response: ResponseBody<IShiftSwapContainer, Meta>) => this.mapService.mapShiftSwapInfos(response.data));
        return promise;
    }

    public getDetails(orgLevelId: number, shiftSwapId: number): Promise<ShiftSwapDetails> {
        const url: string = `${this.getShiftSwapApiRoot(orgLevelId)}/${shiftSwapId}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
        let promise: Promise<ShiftSwapDetails> = this.apiUtilService.request<IShiftSwapDetails, Meta>(request)
            .then((response: ResponseBody<IShiftSwapDetails, Meta>) => this.mapService.mapShiftSwapDetails(response.data));
        return promise;
    }

    public approve(orgLevelId: number, shiftSwapId: number, comment: string): Promise<any> {
        const url: string = `${this.getShiftSwapApiRoot(orgLevelId)}/${shiftSwapId}/${schedulerConfig.api.shiftSwap.approve}`;
        let body: any = {
            comments: comment
        };

        let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);

        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
        .then((response: ResponseBody<any, Meta>) => response.data);
        return promise;
    }

    public deny(orgLevelId: number, shiftSwapId: number, comment: string): Promise<any> {
        const url: string = `${this.getShiftSwapApiRoot(orgLevelId)}/${shiftSwapId}/${schedulerConfig.api.shiftSwap.deny}`;
        let body: any = {
            comments: comment
        };

        let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);

        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
        .then((response: ResponseBody<any, Meta>) => response.data);
        return promise;
    }

    private getShiftSwapApiRoot(orgLevelId: number): string {
        return `${this.getApiRoot()}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.shiftSwap.root}`;
      }

    private getApiRoot(): string {
        return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}`;
    }
}
