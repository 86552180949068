import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../../app.config';
import { IFilteredItems, PagingData } from '../../../../../core/models/index';
import { orderBy, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
var AuditTraiLimitedGridComponent = /** @class */ (function () {
    function AuditTraiLimitedGridComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.gridPageChanged = new EventEmitter();
        this.pageSize = 50;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
    }
    Object.defineProperty(AuditTraiLimitedGridComponent.prototype, "gridView", {
        set: function (value) {
            this.records = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    AuditTraiLimitedGridComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    AuditTraiLimitedGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    AuditTraiLimitedGridComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.gridPageChanged.emit({ take: event.take, skip: event.skip });
    };
    AuditTraiLimitedGridComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AuditTraiLimitedGridComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    AuditTraiLimitedGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = { data: null, total: null };
        var filtered = filterBy(this.records.items, this.gridState.state.filter);
        this.gridState.view = { data: orderBy(filtered, this.gridState.state.sort), total: this.records.count };
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTraiLimitedGridComponent.prototype, "gridRefreshSubscription", void 0);
    return AuditTraiLimitedGridComponent;
}());
export { AuditTraiLimitedGridComponent };
