import { RolesMenuAccess } from './role-access-definition';
import { IRoleMenu, RoleMenu } from './role-menu';

export interface IRoleMenuModule {
  id: number;
  name: string;
  menus: IRoleMenu[];
  displayOrder: number;
}

export class RoleMenuModule {
  public id: number;
  public name: string;
  public menus: RoleMenu[];
  public access: RolesMenuAccess;
  public displayOrder: number;
}

export class RoleMenuModuleGroup {
  public name: string;
  public modules: RoleMenuModule[];
  public access: RolesMenuAccess;
}
