/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./static-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./static-map.component";
import * as i4 from "@angular/platform-browser";
var styles_StaticMapComponent = [i0.styles];
var RenderType_StaticMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaticMapComponent, data: {} });
export { RenderType_StaticMapComponent as RenderType_StaticMapComponent };
function View_StaticMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Couldn't load the map"]], [[8, "src", 4], [8, "width", 0], [8, "height", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.onMapFailed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urlMap; var currVal_1 = _co.currentMapWidth; var currVal_2 = _co.currentMapHeight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_StaticMapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "error-box"]], [[4, "height", "px"]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-exclamation-triangle error-box__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "error-box__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Couldn't load the map"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentMapHeight; _ck(_v, 0, 0, currVal_0); }); }
export function View_StaticMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaticMapComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaticMapComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hasError; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.hasError; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StaticMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-static-map", [], null, null, null, View_StaticMapComponent_0, RenderType_StaticMapComponent)), i1.ɵdid(1, 245760, null, 0, i3.StaticMapComponent, [i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StaticMapComponentNgFactory = i1.ɵccf("slx-static-map", i3.StaticMapComponent, View_StaticMapComponent_Host_0, { latitude: "latitude", longitude: "longitude", mapWidth: "mapWidth", mapHeight: "mapHeight", mapCenterLatitude: "mapCenterLatitude", mapCenterLongitude: "mapCenterLongitude", mapZoom: "mapZoom", mapType: "mapType" }, {}, []);
export { StaticMapComponentNgFactory as StaticMapComponentNgFactory };
