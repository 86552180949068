import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { ChangeManagementService } from '../../../common/services/index';
import { ManagementBaseService } from '../../../core/services/index';
import { LookupApiService } from '../../../organization/services/index';
import { RolesApiService } from './roles-api.service';
var RolesDefinitionManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(RolesDefinitionManagementService, _super);
    function RolesDefinitionManagementService(apiService, lookupApiService, changeService) {
        var _this = _super.call(this) || this;
        _this.changeService = changeService;
        _this.selectRecord$ = new Subject();
        _this.addCmd$ = new Subject();
        _this.clearChanges$ = new Subject();
        _this.cloneCmd$ = new Subject();
        _this.onRoleSaved$ = new Subject();
        _this.apiService = apiService;
        _this.lookupApiService = lookupApiService;
        _this.loadRoles();
        return _this;
    }
    RolesDefinitionManagementService.prototype.onSelectRecord = function (role) {
        this.selectRecord$.next(role);
    };
    RolesDefinitionManagementService.prototype.loadRoles = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getRoles()
            .then(function (records) {
            _this.records = records;
            _this.onLoaded(records);
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        });
    };
    RolesDefinitionManagementService.prototype.onSaveRecord = function (role) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.saveRole(role)
            .then(function (savedRole) {
            var index = _.findIndex(_this.records, { id: role.id });
            if (index !== -1) {
                _this.records[index] = savedRole;
                _this.onRoleSaved$.next({ savedRole: savedRole, records: _this.records, index: index });
            }
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        });
    };
    RolesDefinitionManagementService.prototype.onRemoveRecord = function (role) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.deleteRole(role.id)
            .then(function (res) {
            _this.records = _.filter(_this.records, function (r) { return r.id !== role.id; });
            _this.onLoaded(_this.records);
            _this.onLoadStatusChanged(false);
            _this.clearChanges$.next(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.clearChanges$.next(false);
        });
    };
    RolesDefinitionManagementService.prototype.onCloneRecord = function (srcRole, role) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.cloneRole(role, srcRole.id)
            .then(function (res) {
            _this.loadRoles();
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        });
    };
    RolesDefinitionManagementService.prototype.onAddRecord = function (role) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.addRole(role)
            .then(function (res) {
            _this.loadRoles();
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
            _this.clearChanges$.next(false);
        });
    };
    RolesDefinitionManagementService.prototype.onEditItem = function (item) {
        this.changeService.changeNotify();
        this.changeNotify$.next();
    };
    RolesDefinitionManagementService.prototype.onCancelEdit = function (item) {
        this.changeService.clearChanges();
        this.clearChanges$.next(false);
    };
    RolesDefinitionManagementService.prototype.addCmd = function () {
        this.changeService.changeNotify();
        this.changeNotify$.next();
        this.addCmd$.next(null);
    };
    RolesDefinitionManagementService.prototype.cloneCmd = function (role) {
        this.changeService.changeNotify();
        this.changeNotify$.next();
        this.cloneCmd$.next(role);
    };
    return RolesDefinitionManagementService;
}(ManagementBaseService));
export { RolesDefinitionManagementService };
