import { OnInit } from '@angular/core';
import * as _ from 'lodash';
import { VacationPlanEmployeeRecordDetails } from '../../../models/index';
import { appConfig } from '../../../../app.config';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, LookupType } from '../../../../organization/models/index';
var VacationEmployeeDetailsComponent = /** @class */ (function () {
    function VacationEmployeeDetailsComponent(lookupService) {
        this.lookupService = lookupService;
    }
    Object.defineProperty(VacationEmployeeDetailsComponent.prototype, "scheduleRecords", {
        get: function () {
            return this.m_currentScheduleRecords;
        },
        set: function (value) {
            var _this = this;
            this.originalAbsenceCodesMap = new Map();
            _.forEach(value, function (record) {
                _this.originalAbsenceCodesMap[record.date.getTime()] = record.scheduleAbsence;
            });
            this.m_currentScheduleRecords = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationEmployeeDetailsComponent.prototype, "absenceCode", {
        get: function () {
            return this.m_absenceCode;
        },
        set: function (value) {
            this.m_absenceCode = value;
        },
        enumerable: true,
        configurable: true
    });
    VacationEmployeeDetailsComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.loadAbsences(this.orgLevelId);
    };
    VacationEmployeeDetailsComponent.prototype.selectAll = function () {
        var _this = this;
        if (this.absenceCode) {
            _.forEach(this.scheduleRecords, function (record) {
                if (record.isActive) {
                    record.scheduleAbsence = _this.absenceCode;
                }
            });
        }
    };
    VacationEmployeeDetailsComponent.prototype.clearAll = function () {
        _.forEach(this.scheduleRecords, function (record) {
            record.scheduleAbsence = undefined;
        });
    };
    VacationEmployeeDetailsComponent.prototype.discardChanges = function () {
        var _this = this;
        _.forEach(this.scheduleRecords, function (record) {
            record.scheduleAbsence = _this.originalAbsenceCodesMap[record.date.getTime()];
        });
    };
    VacationEmployeeDetailsComponent.prototype.onCheckbox = function (event, record) {
        if (event) {
            if (!this.absenceCode) {
                return;
            }
            record.scheduleAbsence = this.absenceCode;
        }
        else {
            record.scheduleAbsence = record.scheduleAbsenceStatic;
        }
    };
    VacationEmployeeDetailsComponent.prototype.onSelectCode = function (code) {
        this.absenceCode = code;
    };
    VacationEmployeeDetailsComponent.prototype.loadAbsences = function (orgLevelId) {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.ptoPlannerAbsenceCodes, orgLevelId: orgLevelId, employeeId: undefined, updateCacheForced: true })
            .then(function (absencesLookupValue) {
            _this.absencesLookup = absencesLookupValue;
        });
    };
    return VacationEmployeeDetailsComponent;
}());
export { VacationEmployeeDetailsComponent };
