import { Component, OnInit, Input, ViewEncapsulation, ContentChild, TemplateRef } from '@angular/core';
import { TreeModel } from '../models/tree.model';

@Component({
  moduleId: module.id,
  selector: 'slx-tree-component',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: 'tree.component.html'
})
export class TreeComponent implements OnInit {

  @Input()
  public treeModel: TreeModel;

  @ContentChild('treeRootTemplate', {static: true}) public treeRootTemplate: TemplateRef<any>;
  @ContentChild('treeNodeTemplate', {static: true}) public treeNodeTemplate: TemplateRef<any>;
  @ContentChild('treeNodeFullTemplate', {static: true}) public treeNodeFullTemplate: TemplateRef<any>;
  @ContentChild('treeNodeChildrenTemplate', {static: true}) public treeNodeChildrenTemplate: TemplateRef<any>;

  constructor() {
    this.treeModel = new TreeModel();
  }

  public ngOnInit(): void {
    console.debug('Init tree');
  }

}
