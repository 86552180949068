/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./attendance-points.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "../../../organization/components/generic-list/generic-grid/generic-grid.component.ngfactory";
import * as i5 from "../../../organization/components/generic-list/generic-grid/generic-grid.component";
import * as i6 from "../../../organization/services/generic-list/generic-list-management.service";
import * as i7 from "@angular/forms";
import * as i8 from "../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component.ngfactory";
import * as i9 from "../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component";
import * as i10 from "../../../common/components/actions-list/actions-list.component.ngfactory";
import * as i11 from "../../../common/components/actions-list/actions-list.component";
import * as i12 from "../../../common/components/actions-list/actions-button/actions-button.component.ngfactory";
import * as i13 from "../../../common/components/actions-list/actions-button/actions-button.component";
import * as i14 from "../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i15 from "ngx-popper";
import * as i16 from "../../../common/components/actions-list/actions-list-item/actions-list-item.component.ngfactory";
import * as i17 from "../../../common/components/actions-list/actions-list-item/actions-list-item.component";
import * as i18 from "../../../common/services/column-settings/column-management.service";
import * as i19 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i20 from "../../../organization/services/generic-list/generic-list-api.service";
import * as i21 from "./attendance-points.component";
var styles_AttendancePointsComponent = [i0.styles];
var RenderType_AttendancePointsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AttendancePointsComponent, data: {} });
export { RenderType_AttendancePointsComponent as RenderType_AttendancePointsComponent };
export function View_AttendancePointsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 25, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 23, "slx-generic-grid", [], null, null, null, i4.View_GenericGridComponent_0, i4.RenderType_GenericGridComponent)), i1.ɵdid(4, 245760, [[1, 4]], 0, i5.GenericGridComponent, [i6.GenericListManagementService, i1.ChangeDetectorRef], { genericGridConfig: [0, "genericGridConfig"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 21, "form", [["class", "attendance-points-header"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i7.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(7, 4210688, null, 0, i7.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.NgForm]), i1.ɵdid(9, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "slx-date-range-ngx", [["name", "startEndDate"]], null, [[null, "rangeDateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rangeDateChanged" === en)) {
        var pd_0 = (_co.onFilterDateChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_DateRangeNgxComponent_0, i8.RenderType_DateRangeNgxComponent)), i1.ɵdid(11, 573440, null, 0, i9.DateRangeNgxComponent, [], { startDate: [0, "startDate"], endDate: [1, "endDate"] }, { rangeDateChanged: "rangeDateChanged" }), (_l()(), i1.ɵeld(12, 0, null, null, 14, "div", [["class", "attendance-points-dropdown col-lg-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 13, "slx-actions-list", [], null, null, null, i10.View_ActionsListComponent_0, i10.RenderType_ActionsListComponent)), i1.ɵdid(14, 49152, null, 0, i11.ActionsListComponent, [], null, null), (_l()(), i1.ɵeld(15, 0, null, 1, 3, "slx-actions-button", [], null, null, null, i12.View_ActionsButtonComponent_0, i12.RenderType_ActionsButtonComponent)), i1.ɵdid(16, 49152, null, 0, i13.ActionsButtonComponent, [], { popperContent: [0, "popperContent"], popperPosition: [1, "popperPosition"] }, null), (_l()(), i1.ɵted(-1, 0, [" Actions "])), (_l()(), i1.ɵeld(18, 0, null, 0, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, 1, 7, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_PopperContent_0, i14.RenderType_PopperContent)), i1.ɵdid(20, 180224, [["popperContent", 4]], 0, i15.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(21, 0, null, 0, 2, "slx-actions-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onExportToExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_ActionsListItemComponent_0, i16.RenderType_ActionsListItemComponent)), i1.ɵdid(22, 49152, null, 0, i17.ActionsListItemComponent, [i15.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, ["Export to Excel"])), (_l()(), i1.ɵeld(24, 0, null, 0, 2, "slx-actions-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onExportToPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_ActionsListItemComponent_0, i16.RenderType_ActionsListItemComponent)), i1.ɵdid(25, 49152, null, 0, i17.ActionsListItemComponent, [i15.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, ["Export to Pdf"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.genericGridConfig; _ck(_v, 4, 0, currVal_1); var currVal_9 = _co.startDate; var currVal_10 = _co.endDate; _ck(_v, 11, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵnov(_v, 20); var currVal_12 = "bottom-end"; _ck(_v, 16, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 9).ngClassValid; var currVal_7 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_AttendancePointsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-attendance-points", [], null, null, null, View_AttendancePointsComponent_0, RenderType_AttendancePointsComponent)), i1.ɵprd(4608, null, i18.ColumnManagementService, i18.ColumnManagementService, [i19.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.GenericListManagementService, i6.GenericListManagementService, [i20.GenericListApiService]), i1.ɵdid(3, 245760, null, 0, i21.AttendancePointsComponent, [i6.GenericListManagementService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var AttendancePointsComponentNgFactory = i1.ɵccf("slx-attendance-points", i21.AttendancePointsComponent, View_AttendancePointsComponent_Host_0, {}, {}, []);
export { AttendancePointsComponentNgFactory as AttendancePointsComponentNgFactory };
