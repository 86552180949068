import { IScheduledShiftDefinition, ScheduledShiftDefinition } from './index';

export interface IScheduleEntryDefinition {
  dateOn: Date;
  shifts: IScheduledShiftDefinition[];
  isPosted: boolean;
  isReplacing: boolean;
  isReplaced: boolean;
  isPreScheduledOvertime: boolean;
  isOvertimeApproach: boolean;
  isAgency: boolean;
  hasAbsence: boolean;
  hasPartialAbsence: boolean;
  scheduledInDifferentShiftGroup: boolean;
}

export class ScheduleEntryDefinition {
  public dateOn: Date;
  public shifts: ScheduledShiftDefinition[];
  public isPosted: boolean;
  public isReplacing: boolean;
  public isReplaced: boolean;
  public isPreScheduledOvertime: boolean;
  public isOvertimeApproach: boolean;
  public isAgency: boolean;
  public hasAbsence: boolean;
  public hasPartialAbsence: boolean;
  public scheduledInDifferentShiftGroup: boolean;
}
