import { PopoverContentComponent } from './../../../../common/components/popover-content/popover-content.component';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { PostScheduleSettings } from './../../../models/open-shift-management/post-schedule-settings';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-post-schedule-form',
  templateUrl: 'post-schedule-form.component.html',
  styleUrls: ['post-schedule-form.component.scss']
})
export class PostScheduleFormComponent {
  public settings: PostScheduleSettings;
  @Output()
  public onCancel: EventEmitter<any>;
  @Output()
  public onPost: EventEmitter<any>;
  constructor(private openShiftManagementManagementService: OpenShiftManagementManagementService) {
    this.settings = this.openShiftManagementManagementService.postSettings ?
     this.openShiftManagementManagementService.postSettings : new PostScheduleSettings();
    this.onCancel = new EventEmitter();
    this.onPost = new EventEmitter();
  }

  public onPostHandler(): void {
    this.onPost.emit();
  }

  public onCancelHandler(): void {
    this.onCancel.emit();
  }
}
