import { Provider } from '@angular/core';
import { DialogOptions, ModalService } from './../../../../../../app/common';
import { CopyAccrualPolicy } from '../../../models/accruals-policy/copy-accruals-policy';
import * as _ from 'lodash';
var CopyAccrualPolicyComponent = /** @class */ (function () {
    function CopyAccrualPolicyComponent(modalService, request) {
        this.isAllPolicyCheck = false;
        this.isAllOrgsCheck = false;
        this.modalService = modalService;
        this.dialogData = request;
        this.isAllPolicyCheck = _.every(this.dialogData.currentPolicies, function (i) { return i.isChecked ? true : false; });
        this.isAllOrgsCheck = _.every(this.dialogData.orgLevels, function (i) { return i.isChecked ? true : false; });
    }
    CopyAccrualPolicyComponent.openDialog = function (request, modalService, callback) {
        this.resetCheckedItems(request);
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 700;
        dialogOptions.className = 'slx-copy-policy-dialog-custom';
        dialogOptions.showCloseButton = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: CopyAccrualPolicy,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(CopyAccrualPolicyComponent, request.header, dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, request);
        });
        return dialog;
    };
    CopyAccrualPolicyComponent.resetCheckedItems = function (request) {
        _.map(request.currentPolicies, function (item) { return item.isChecked = false; });
        _.map(request.orgLevels, function (item) { return item.isChecked = false; });
    };
    CopyAccrualPolicyComponent.prototype.selectAllPolicies = function () {
        this.isAllPolicyCheck = !this.isAllPolicyCheck;
        this.setAllCheck(this.dialogData.currentPolicies, this.isAllPolicyCheck);
    };
    CopyAccrualPolicyComponent.prototype.selectAllOrganizations = function () {
        this.isAllOrgsCheck = !this.isAllOrgsCheck;
        this.setAllCheck(this.dialogData.orgLevels, this.isAllOrgsCheck);
    };
    CopyAccrualPolicyComponent.prototype.onChangePolicyCheck = function (e, data) {
        data.isChecked = !data.isChecked;
        this.isAllPolicyCheck = _.every(this.dialogData.currentPolicies, function (i) { return i.isChecked ? true : false; });
    };
    CopyAccrualPolicyComponent.prototype.onChangeOrgLevelCheck = function (e, data) {
        data.isChecked = !data.isChecked;
        this.isAllOrgsCheck = _.every(this.dialogData.orgLevels, function (i) { return i.isChecked ? true : false; });
    };
    CopyAccrualPolicyComponent.prototype.setAllCheck = function (data, isChecked) {
        _.map(data, function (item) { return item.isChecked = isChecked; });
    };
    Object.defineProperty(CopyAccrualPolicyComponent.prototype, "saveDisabled", {
        get: function () {
            var currentPoliciesChecked = _.filter(this.dialogData.currentPolicies, function (i) { return i.isChecked; });
            var orgLevelsChecked = _.filter(this.dialogData.orgLevels, function (i) { return i.isChecked; });
            return currentPoliciesChecked.length > 0 && orgLevelsChecked.length > 0 ? false : true;
        },
        enumerable: true,
        configurable: true
    });
    CopyAccrualPolicyComponent.prototype.save = function () {
        this.dialogResult = true;
        this.modalService.closeAllWindows();
    };
    CopyAccrualPolicyComponent.prototype.discard = function () {
        this.dialogResult = false;
        this.modalService.closeAllWindows();
    };
    return CopyAccrualPolicyComponent;
}());
export { CopyAccrualPolicyComponent };
