<slx-spinner [show]="false">
    <!-- <div class="slx-top-header">
        <div>
            <slx-checkbox-toggle class="active-only-field" className="toggle-right" [(ngModel)]="isUnitIncluded" 
               (ngModelChange)="isUnitChanged()" caption="Include Units" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
        </div>
        <div>
            <slx-checkbox-toggle class="active-only-field" className="slx-toggle-bipa toggle-right" 
                [(ngModel)]="isShiftTimesIncluded" (ngModelChange)="isShiftChanged()" caption="Include Individual Shift Times" checkedText="Yes"
                uncheckedText="No"></slx-checkbox-toggle>
        </div>
    </div> -->

    <div class="shift-start-time-s">
        <strong>Shift start time(s)</strong>
    </div>
    <div class="basic-information" *ngIf="records">
        <div class="shift-item1">
            <span>Day Shift</span>
        </div>
        <div class="time-item">
            <span>
                <slx-input-decorator className="slx-no-background">
                    <input slx-time-picker slx-input name="day" [(ngModel)]="configData.shiftGroupTimingS[0]"
                        (onDateChanged)="onShiftDateChanged()" format="hh:mm tt" dateInput="true">
                </slx-input-decorator>
            </span>
        </div>
        <div class="shift-item2">
            <span>Evening Shift</span>
        </div>
        <div class="time-item">
            <span>
                <slx-input-decorator className="slx-no-background">
                    <input slx-time-picker slx-input name="evening" [(ngModel)]="configData.shiftGroupTimingS[1]"
                        (onDateChanged)="onShiftDateChanged()" format="hh:mm tt" dateInput="true">
                </slx-input-decorator>
            </span>
        </div>
        <div class="shift-item3">
            <span>Night Shift</span>
        </div>
        <div class="time-item">
            <span>
                <slx-input-decorator className="slx-no-background">
                    <input slx-time-picker slx-input name="night" [(ngModel)]="configData.shiftGroupTimingS[2]"
                        (onDateChanged)="onShiftDateChanged()" format="hh:mm tt" dateInput="true">
                </slx-input-decorator>
            </span>
        </div>

    </div>
    <div class="content-grid">
        <kendo-grid [data]="records">
            <kendo-grid-column title="Smartlinx Position" field="smartlinxPosition"></kendo-grid-column>
            <kendo-grid-column title="CMS Job Description" field="cmsJobDescription"></kendo-grid-column>
        </kendo-grid>
    </div>
</slx-spinner>