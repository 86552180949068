import { Directive, Input, OnInit, ElementRef } from '@angular/core';
import { ScrollWatchService } from '../../services/index';
import { ScrollWatchEvent } from '../../models/index';
import { Assert } from '../../../framework/index';

@Directive({
  selector: '[scrollWatchSource]',
  /* tslint:disable */
  host: {
    '(scroll)': 'onScroll($event)'
  }
  /* tslint:enable */
})

export class ScrollWatchSourceDirective implements OnInit {
  @Input('scrollWatchSource') private scrollWatchId: string;

  private scrollWatchService: ScrollWatchService;
  private elementRef: ElementRef;
  private firstEvent: boolean;
  //private scrollSubscription: Subscription;

  constructor(scrollWatchService: ScrollWatchService, elementRef: ElementRef) {
    this.scrollWatchService = scrollWatchService;
    this.elementRef = elementRef;
    this.firstEvent = true;

  }

  public ngOnInit(): void {
    Assert.isNotNull(this.scrollWatchId, 'scrollWatchId');
    setTimeout(() => {
      this.onScroll(null);
    }, 1000); //- because i can`t find more appropriate way to waiting when all childs will loaded
  }

  //@HostListener('window:scroll', ['$event'])
  private onScroll($event?: MouseEvent): void {
    let event: ScrollWatchEvent = new ScrollWatchEvent($event);
    event.scrollEvent = $event;
    event.sourceId = this.scrollWatchId;
    event.scrollTop = this.elementRef.nativeElement.scrollTop;
    event.scrollLeft = this.elementRef.nativeElement.scrollLeft;
    event.clientHeight = this.elementRef.nativeElement.clientHeight;
    event.clientWidth = this.elementRef.nativeElement.clientWidth;
    this.scrollWatchService.onScrollSource(event);
  }
}

