import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { IDestroyService } from '../../../../core/models/index';
import { AuditTrailEvent } from '../../models/audit-trail/audit-trail-event';
import { AccessibleApiService,  UserAction } from '../../../../organization';
import { Subscription } from 'rxjs';
import { Assert } from '../../../../framework';

@Injectable()
export class AuditTrailManagementService implements IDestroyService {
  public onActionCmd$: ReplaySubject<AuditTrailEvent>;
  private userActonsChanged$ = new ReplaySubject<UserAction[]>(1);

  constructor(private accessibleApiService: AccessibleApiService) {
    this.onActionCmd$ = new ReplaySubject(1);
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
    this.onActionCmd$.complete();
    this.userActonsChanged$.complete();
  }

  public subscribeToActions(callback: (cmd: AuditTrailEvent) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.onActionCmd$.subscribe(callback);
  }

  public onActionCmd(cmd: AuditTrailEvent): void {
    this.onActionCmd$.next(cmd);
  }

  public subscribeToUserActionsChanged(callback: (v: UserAction[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.userActonsChanged$.subscribe(callback);
  }

  public loadUserActions(orgLevelId: number): void {
    this.accessibleApiService.getUserActions(orgLevelId, ['Export to Excel'])
      .then((data: UserAction[]) => {
        this.userActonsChanged$.next(data);
      });
  }
}
