
export class EssTimeEntryMethod {
  public static none: EssTimeEntryMethodDefinition = 'None';
  public static webpunch: EssTimeEntryMethodDefinition = 'Webpunch';
  public static timesheet: EssTimeEntryMethodDefinition = 'Timesheet';
  public static getList(addNone: boolean): IEssTimeEntryMethodOption[] {
    const list = [
      { id: EssTimeEntryMethod.webpunch, name: 'Web Punch' },
      { id: EssTimeEntryMethod.timesheet, name: 'Timesheet' },
    ];
    if(addNone) {
      list.unshift({ id: EssTimeEntryMethod.none, name: 'None' });
    }
    return list;
  }
}

export type EssTimeEntryMethodDefinition = 'None' | 'Webpunch' | 'Timesheet';

export interface IEssTimeEntryMethodOption {
  id: EssTimeEntryMethodDefinition;
  name: string;
}
