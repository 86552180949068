import * as _ from 'lodash';
import { ControlValueAccessor, Validator, ValidationErrors, AbstractControl } from '@angular/forms';
import { dateTimeUtils } from '../../../common/utils/index';
var DateTimePickerInputComponent = /** @class */ (function () {
    function DateTimePickerInputComponent() {
        this.readonly = false;
        this.placeholder = '';
        this.dateFormat = 'MM/dd/yyyy';
        this.timeFormat = 'hh:mm a';
        this.readonlyOnlyTime = false;
        this.readonlyTimeWithoutDate = false;
        this.mainCssClass = true;
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.onValidationChange = _.noop;
    }
    Object.defineProperty(DateTimePickerInputComponent.prototype, "minDateTime", {
        set: function (v) {
            if (_.isDate(v) || _.isNull(v)) {
                this.validateMinDate(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePickerInputComponent.prototype, "maxDateTime", {
        set: function (v) {
            if (_.isDate(v) || _.isNull(v)) {
                this.validateMaxDate(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePickerInputComponent.prototype, "watermarkStyleUnlessTouched", {
        set: function (v) {
            this.watermarkClass = !!v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePickerInputComponent.prototype, "hasPlaceholder", {
        get: function () {
            return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimePickerInputComponent.prototype, "isReadonlyUntilDateInput", {
        get: function () {
            return this.readonlyTimeWithoutDate && !_.isDate(this.date);
        },
        enumerable: true,
        configurable: true
    });
    DateTimePickerInputComponent.prototype.onChangeDate = function (date) {
        this.date = date;
        var hasTime = _.isDate(this.time);
        var dateIsMinDate = this.isSameDay(this.date, this.limitMinDate);
        var dateIsMaxDate = this.isSameDay(this.date, this.limitMaxDate);
        if (dateIsMinDate || dateIsMaxDate) {
            if (dateIsMinDate) {
                this.minTime = this.cloneDate(this.limitMinDate);
            }
            if (dateIsMaxDate) {
                this.maxTime = this.cloneDate(this.limitMaxDate);
            }
            if (!hasTime) {
                this.time = this.cloneDate(this.date);
            }
        }
        else {
            this.minTime = this.setTimeToStartDay(this.limitMinDate);
            this.maxTime = this.setTimeToEndDay(this.limitMaxDate);
            if (!hasTime) {
                this.time = this.cloneDate(this.date);
            }
        }
        if (hasTime) {
            this.time = this.copyDate(this.date, this.time);
            if (this.isSourceLess(this.time, this.limitMinDate)) {
                this.time = this.cloneDate(this.limitMinDate);
                this.date = this.copyTime(this.time, this.date);
            }
            else if (this.isSourceGreater(this.time, this.limitMaxDate)) {
                this.time = this.cloneDate(this.limitMaxDate);
                this.date = this.copyTime(this.time, this.date);
            }
        }
        this.onValidationChange();
        this.onChangeCallback(this.date);
    };
    DateTimePickerInputComponent.prototype.onChangeTime = function (time) {
        this.time = time;
        this.date = this.copyTime(this.time, this.date);
        this.onValidationChange();
        this.onChangeCallback(this.date);
    };
    DateTimePickerInputComponent.prototype.writeValue = function (date) {
        if (_.isDate(date) || _.isNull(date)) {
            this.date = this.cloneDate(date);
            this.time = this.cloneDate(date);
        }
        this.onValidationChange();
        this.onChangeCallback(this.date);
    };
    DateTimePickerInputComponent.prototype.onBlur = function () {
        this.onTouchedCallback();
    };
    DateTimePickerInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    DateTimePickerInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DateTimePickerInputComponent.prototype.registerOnValidatorChange = function (fn) {
        this.onValidationChange = fn;
    };
    DateTimePickerInputComponent.prototype.validate = function (control) {
        var isValid = this.isValidDate(this.date);
        if (!isValid) {
            return { date: true };
        }
        if (this.isSourceLess(this.date, this.limitMinDate)) {
            return { minDateTime: true };
        }
        if (this.isSourceGreater(this.date, this.limitMaxDate)) {
            return { maxDateTime: true };
        }
        return null;
    };
    DateTimePickerInputComponent.prototype.validateMinDate = function (minDate) {
        this.limitMinDate = this.cloneDate(minDate);
        this.minDate = this.cloneDate(this.limitMinDate);
        this.onValidationChange();
    };
    DateTimePickerInputComponent.prototype.validateMaxDate = function (maxDate) {
        this.limitMaxDate = this.cloneDate(maxDate);
        this.maxDate = this.cloneDate(this.limitMaxDate);
        this.onValidationChange();
    };
    DateTimePickerInputComponent.prototype.cloneDate = function (d) {
        if (_.isDate(d)) {
            return new Date(d.getTime());
        }
        return null;
    };
    DateTimePickerInputComponent.prototype.isSameDay = function (s, t) {
        if (_.isDate(s) && _.isDate(t)) {
            return this.getDayStr(s) === this.getDayStr(t);
        }
        return false;
    };
    DateTimePickerInputComponent.prototype.setTimeToStartDay = function (d) {
        if (_.isDate(d)) {
            var date = this.cloneDate(d);
            date.setHours(0, 0, 0, 0);
            return date;
        }
        return null;
    };
    DateTimePickerInputComponent.prototype.setTimeToEndDay = function (d) {
        if (_.isDate(d)) {
            var date = this.cloneDate(d);
            date.setHours(23, 59, 59, 59);
            return date;
        }
        return null;
    };
    DateTimePickerInputComponent.prototype.isSourceLess = function (source, target) {
        if (_.isDate(source) && _.isDate(target)) {
            return source.getTime() < target.getTime();
        }
        return false;
    };
    DateTimePickerInputComponent.prototype.isSourceGreater = function (source, target) {
        if (_.isDate(source) && _.isDate(target)) {
            return source.getTime() > target.getTime();
        }
        return false;
    };
    DateTimePickerInputComponent.prototype.getDayStr = function (d) {
        if (_.isDate(d)) {
            return d.getDate() + "-" + d.getMonth() + "-" + d.getFullYear();
        }
        return '';
    };
    DateTimePickerInputComponent.prototype.copyDate = function (source, target) {
        if (_.isDate(source) && _.isDate(target)) {
            var tDate = this.cloneDate(target);
            tDate.setDate(source.getDate());
            tDate.setMonth(source.getMonth());
            tDate.setFullYear(source.getFullYear());
            return tDate;
        }
        return null;
    };
    DateTimePickerInputComponent.prototype.copyTime = function (source, target) {
        if (_.isDate(source) && _.isDate(target)) {
            var tDate = this.cloneDate(target);
            tDate.setHours(source.getHours(), source.getMinutes(), source.getSeconds(), 0);
            return tDate;
        }
        return null;
    };
    DateTimePickerInputComponent.prototype.isValidDate = function (currentValue) {
        return dateTimeUtils.validateDate(currentValue);
    };
    return DateTimePickerInputComponent;
}());
export { DateTimePickerInputComponent };
