/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-employees-unenrolled.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../benefit-employees-eligible/benefit-employees-eligible.component.ngfactory";
import * as i3 from "../benefit-employees-eligible/benefit-employees-eligible.component";
import * as i4 from "../../../services/benefit-employees/benefit-employees-api.service";
import * as i5 from "../benefit-eligible-employees-enrollment/benefit-eligible-employees-enrollment.component.ngfactory";
import * as i6 from "../../../../../common/services/state-management/state-management.service";
import * as i7 from "../../../../../common/services/component-state/component-state-storage.service";
import * as i8 from "../../../../../common/services/column-settings/column-settings-storage.service";
import * as i9 from "../benefit-eligible-employees-enrollment/benefit-eligible-employees-enrollment.component";
import * as i10 from "../../../../../common/services/modal/modal.service";
import * as i11 from "../../../services/benefit-employees/benefit-employees-management.service";
import * as i12 from "../../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i13 from "../../../services/benefit-employees/benefit-employees-map.service";
import * as i14 from "../../../../../app-settings/services/app-settings-manage.service";
import * as i15 from "@angular/common";
import * as i16 from "./benefit-employees-unenrolled.component";
var styles_BenefitEmployeesUnenrolledComponent = [i0.styles];
var RenderType_BenefitEmployeesUnenrolledComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitEmployeesUnenrolledComponent, data: {} });
export { RenderType_BenefitEmployeesUnenrolledComponent as RenderType_BenefitEmployeesUnenrolledComponent };
function View_BenefitEmployeesUnenrolledComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-benefit-employees-eligible", [], null, [[null, "selectEmployees"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectEmployees" === en)) {
        var pd_0 = (_co.selectEmployees($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BenefitEmployeesEligibleComponent_0, i2.RenderType_BenefitEmployeesEligibleComponent)), i1.ɵdid(2, 245760, null, 0, i3.BenefitEmployeesEligibleComponent, [i4.BenefitEmployeesApiService], { tierId: [0, "tierId"], lineId: [1, "lineId"], date: [2, "date"], canEnroll: [3, "canEnroll"], isUserCanEnroll: [4, "isUserCanEnroll"], planName: [5, "planName"], method: [6, "method"], tierDetails: [7, "tierDetails"], lowLevelOrgId: [8, "lowLevelOrgId"] }, { selectEmployees: "selectEmployees" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tierId; var currVal_1 = _co.lineId; var currVal_2 = _co.date; var currVal_3 = _co.canEnroll; var currVal_4 = _co.isUserCanEnroll; var currVal_5 = _co.planName; var currVal_6 = _co.method; var currVal_7 = _co.tierDetails; var currVal_8 = _co.lowLevelOrgId; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_BenefitEmployeesUnenrolledComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "slx-benefit-employees-enrollment", [], null, [[null, "onEnrollmentCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEnrollmentCancel" === en)) {
        var pd_0 = (_co.onEnrollmentCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_BenefitEmployeesEnrollmentComponent_0, i5.RenderType_BenefitEmployeesEnrollmentComponent)), i1.ɵprd(4608, null, i6.StateManagementService, i6.StateManagementService, [i7.ComponentStateStorageService, i8.ColumnSettingsStorageService]), i1.ɵdid(3, 245760, null, 0, i9.BenefitEmployeesEnrollmentComponent, [i10.ModalService, i4.BenefitEmployeesApiService, i11.BenefitEmployeeManagementService, i12.NotificationsService, i13.BenefitEmployeesMapService, i14.AppSettingsManageService], { tierId: [0, "tierId"], lineId: [1, "lineId"], date: [2, "date"], canEnroll: [3, "canEnroll"], planName: [4, "planName"], method: [5, "method"], tierDetails: [6, "tierDetails"], selectedEmployees: [7, "selectedEmployees"], benefitDetailsLine: [8, "benefitDetailsLine"], mappedDeduction: [9, "mappedDeduction"], canMapPayroll: [10, "canMapPayroll"] }, { onEnrollmentCancel: "onEnrollmentCancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tierId; var currVal_1 = _co.lineId; var currVal_2 = _co.date; var currVal_3 = _co.canEnroll; var currVal_4 = _co.planName; var currVal_5 = _co.method; var currVal_6 = _co.tierDetails; var currVal_7 = _co.selectedEmployees; var currVal_8 = _co.benefitDetailsLine; var currVal_9 = _co.mappedDeduction; var currVal_10 = _co.canMapPayroll; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10]); }, null); }
export function View_BenefitEmployeesUnenrolledComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitEmployeesUnenrolledComponent_1)), i1.ɵdid(1, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitEmployeesUnenrolledComponent_2)), i1.ɵdid(3, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.mode === "eligible"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.mode === "enrollment"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BenefitEmployeesUnenrolledComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-employees-unenrolled", [], null, null, null, View_BenefitEmployeesUnenrolledComponent_0, RenderType_BenefitEmployeesUnenrolledComponent)), i1.ɵdid(1, 114688, null, 0, i16.BenefitEmployeesUnenrolledComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitEmployeesUnenrolledComponentNgFactory = i1.ɵccf("slx-benefit-employees-unenrolled", i16.BenefitEmployeesUnenrolledComponent, View_BenefitEmployeesUnenrolledComponent_Host_0, { lineId: "lineId", tierId: "tierId", tierDetails: "tierDetails", planName: "planName", canEnroll: "canEnroll", isUserCanEnroll: "isUserCanEnroll", date: "date", lowLevelOrgId: "lowLevelOrgId", method: "method", benefitDetailsLine: "benefitDetailsLine", mappedDeduction: "mappedDeduction", canMapPayroll: "canMapPayroll" }, { modeChanged: "modeChanged" }, []);
export { BenefitEmployeesUnenrolledComponentNgFactory as BenefitEmployeesUnenrolledComponentNgFactory };
