import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras,
  CanActivateChild, Params
} from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { Assert } from '../../../framework/index';
import { authenticationConfig } from '../../authentication.config';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { AuthApiService } from '../../../core/services/auth-api/auth-api.service';
import { unsubscribe } from '../../../core/decorators/index';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

  @unsubscribe()
  private apiRequestStartedSubscription: Subscription;

  constructor(private router: Router, private authenticationService: AuthenticationService,
    private userActivityService: UserActivityService, private authApiService: AuthApiService) {
    Assert.isNotNull(router, 'router');
    Assert.isNotNull(authenticationService, 'authenticationService');
    Assert.isNotNull(userActivityService, 'userActivityService');
    Assert.isNotNull(authApiService, 'authApiService');

    if (this.authenticationService.isAuthenticated()) {
      this.userActivityService.start();
    }

    this.apiRequestStartedSubscription = this.authApiService.apiRequestStarted.subscribe((authenticationRequired: boolean) => {
      if (authenticationRequired) {
        this.checkAuthentication();
      }
    });
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isAuthenticated: boolean = this.checkAuthentication(state.url);
    return isAuthenticated;
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let isAuthenticated: boolean = this.checkAuthentication(state.url);
    return isAuthenticated;
  }

  private checkAuthentication(url?: string): boolean {
    let isAuthenticated: boolean = this.authenticationService.isAuthenticated();
    if (isAuthenticated) {
      return true;
    }
    this.authenticationService.navigateToLogout(this.router, url);
    return false;
  }
}
