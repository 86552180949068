import * as tslib_1 from "tslib";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { ManagementBaseService } from "./../../../../app/core/services";
import { ShiftGroup } from "../../models";
import { ShiftGroupMapService } from "./shift-group-map.service";
import { mutableSelect, unsubscribeInService } from "./../../../../app/core/decorators";
import * as _ from "lodash";
import { LookupEntity } from "./../../../../app/organization";
import { ShiftGroupApiService } from "./shift-group-api.service";
import { NotificationsService } from "./../../../../app/core/components";
var ShiftGroupManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(ShiftGroupManagementService, _super);
    function ShiftGroupManagementService(shiftGroupApi, mapService, notificationService) {
        var _this = _super.call(this) || this;
        _this.shiftGroupApi = shiftGroupApi;
        _this.mapService = mapService;
        _this.notificationService = notificationService;
        _this.m_container = {
            records: [],
            actions: []
        };
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.viewRefresh$ = new Subject();
        _this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: false,
            canEdit: false,
            canAdd: false,
            canDelete: false,
            orgLevelChanged: false
        };
        return _this;
    }
    Object.defineProperty(ShiftGroupManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupManagementService.prototype, "groups", {
        get: function () {
            return this.m_groups;
        },
        enumerable: true,
        configurable: true
    });
    ShiftGroupManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (_.isNumber(orgLevel.id)) {
                _this.currentOrgLevel = orgLevel;
                _this.viewRefresh$.next(false);
                _this.onLoadStatusChanged(false);
                _this.getItems();
            }
        });
    };
    ShiftGroupManagementService.prototype.onSaveItem = function (result) {
        // Functions to be done
        if (result.isNew) {
            this.addItem();
        }
        else {
            this.updateItem(result.dataItem);
        }
    };
    ;
    ShiftGroupManagementService.prototype.onRemoveItem = function (item) {
        // Functions to be done
        this.removeItem();
    };
    ;
    ShiftGroupManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
    };
    ;
    ShiftGroupManagementService.prototype.onCancelEditItem = function () {
        // Functions to be done
    };
    ;
    ShiftGroupManagementService.prototype.setSelectedCount = function (count) {
        // Functions to be done
    };
    ;
    ShiftGroupManagementService.prototype.onAddItem = function (item) {
        // Functions to be done
        this.addItem();
    };
    ;
    ShiftGroupManagementService.prototype.markAsDirty = function () {
        throw new Error("Method not implemented.");
    };
    ShiftGroupManagementService.prototype.getItems = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state.isLoading = true;
                        return [4 /*yield*/, this.shiftGroupApi.getShiftGroupList(this.currentOrgLevel.id).then(function (data) {
                                _this.m_container.records = data;
                                setTimeout(function () {
                                    _this.state.isLoading = false;
                                }, 2000);
                                _this.onLoaded$.next(_this.m_container);
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShiftGroupManagementService.prototype.addItem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, null];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShiftGroupManagementService.prototype.updateItem = function (dataItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedItem;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.state.isLoading = true;
                        updatedItem = this.mapService.reverseMap(dataItem);
                        return [4 /*yield*/, this.shiftGroupApi.updateShiftGroup(this.currentOrgLevel.id, updatedItem).then(function (data) {
                                _this.notificationService.success('Shift Group Updated', data.name + " Shift Group Updated Successfully");
                                _this.getItems();
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ShiftGroupManagementService.prototype.removeItem = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, null];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftGroupManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupManagementService.prototype, "orgLevelSubscription", void 0);
    return ShiftGroupManagementService;
}(ManagementBaseService));
export { ShiftGroupManagementService };
