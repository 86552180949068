<slx-collapsible-section *ngFor="let range of ranges; let i = index" mode="blue" 
    [title]="rangeName(range)" class="no-padding" [expanded]="true">
        <ng-container body>
            <slx-ideal-schedule-range-toolbar [range]="range" [rangeIndex]="i" 
                [canDelete]="ranges.length > 1"
                [showAcuitySelector]="false">
            </slx-ideal-schedule-range-toolbar>

            <div class="range-container">
                <slx-ideal-schedule-grid [range]="range"></slx-ideal-schedule-grid>
            </div>
        </ng-container>
</slx-collapsible-section>