import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Comparison, ChartMenuItem, ChartType, DisplayDefinition, DisplayType, ConsoleConfig } from '../../models/index';
import { ValuePairWidgetConfig, ValuePairChartInput, ValuePairChartDataService } from '../../../../common/index';
import { DetailGroupViewSettingsTypes } from './../../../models/index';
import { PairColorScheme } from '../../../../common/models/pair-color-scheme/pair-color-scheme';
import { ScheduleConsoleChartService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-graphic-comparison-week',
  templateUrl: 'schedule-console-graphic-comparison-week.component.html',
  styleUrls: ['schedule-console-graphic-comparison-week.component.scss']
})
export class ScheduleConsoleGraphicComparisonWeekComponent {

  @Input()
  public comparisons: Comparison[];

  @Input()
  public widgetConfig: ValuePairWidgetConfig;

  @Input()
  public chart: ChartMenuItem;

  @Input()
  public consoleConfig: ConsoleConfig;

  @Output()
  public onClickWidget: EventEmitter<any>;

  @Output()
  public onClickChart: EventEmitter<any>;

  @Output()
  public onClickPair1: EventEmitter<any>;

  @Output()
  public onClickPair2: EventEmitter<any>;

  @Output()
  public onClickBadgeMessages: EventEmitter<any>;

  @Output()
  public onClickBadgeOvertime: EventEmitter<any>;

  @Output()
  public onClickBadgePto: EventEmitter<any>;

  @Output()
  public onClickBadgeLate: EventEmitter<any>;

  public chartTypes: typeof ChartType = ChartType;

  constructor() {
    this.onClickWidget = new EventEmitter<any>();
    this.onClickChart = new EventEmitter<any>();
    this.onClickPair1 = new EventEmitter<any>();
    this.onClickPair2 = new EventEmitter<any>();
    this.onClickBadgeMessages = new EventEmitter<any>();
    this.onClickBadgeOvertime = new EventEmitter<any>();
    this.onClickBadgePto = new EventEmitter<any>();
    this.onClickBadgeLate = new EventEmitter<any>();
  }

  public handleClickChart(comparison: Comparison): void {
    this.onClickChart.emit(comparison);
  }

  public handleClickPair1(comparison: Comparison): void {
    this.onClickPair1.emit(comparison);
  }

  public handleClickPair2(comparison: Comparison): void {
    this.onClickPair2.emit(comparison);
  }

  public handleClickBadgeMessages(comparison: Comparison): void {
    this.onClickBadgeMessages.emit(comparison);
  }

  public handleClickBadgeOvertime(comparison: Comparison): void {
    this.onClickBadgeOvertime.emit(comparison);
  }

  public handleClickBadgePto(comparison: Comparison): void {
    this.onClickBadgePto.emit(comparison);
  }

  public handleClickBadgeLate(comparison: Comparison): void {
    this.onClickBadgeLate.emit(comparison);
  }

  public hanldeWidgetClick(comparison: Comparison): void {
    this.onClickWidget.emit(comparison);
  }

  public getChartInput(comparison: Comparison): ValuePairChartInput {
    const chartInput: ValuePairChartInput = ScheduleConsoleChartService.makeChartInput(comparison, this.consoleConfig);

    return chartInput;
  }
}
