import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as SignalR from '@microsoft/signalr';
import { EventEmitter } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Subject } from 'rxjs';
import { appConfig } from '../../../app.config';
import { SessionService } from '../../../core/services';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework';
import { configurationConfig } from '../../../configuration/configuration.config';
import { NotificationsMapService } from './notifications-map.service';
var NOTIFICATIONS_API_DEBOUNCE_TIME_MS = 2500;
var NotificationsApiService = /** @class */ (function () {
    function NotificationsApiService(apiUtilService, urlParamsService, mapService, sessionService) {
        var _this = this;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.mapService = mapService;
        this.sessionService = sessionService;
        this.messageReceived = new EventEmitter();
        this.connectionCount = 0;
        this.messageReceivedDebouncer = new Subject();
        this.buildConnection = function () {
            _this.hubConnection = new SignalR.HubConnectionBuilder()
                .withUrl(_this.apiUtilService.getSignalRApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.signalr + "/" + configurationConfig.api.configuration.messageCenter.communication + "?" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + _this.getCommunicationApiRootVersion() + "&" + configurationConfig.api.configuration.messageCenter.accesstToken + "=" + _this.sessionService.getToken())
                .build();
        };
        this.startConnection = function () {
            _this.connectionCount = _this.connectionCount + 1;
            _this.hubConnection
                .start()
                .then(function () {
                _this.registerMessageEvent();
            })
                .catch(function (err) {
                if (!_this.sessionService.isExpired() && _this.connectionCount <= 5) {
                    setTimeout(function () {
                        _this.startConnection();
                    }, 3000);
                }
            });
        };
        this.checkCloseConnection = function () {
            _this.hubConnection.onclose(function () {
                _this.buildConnection();
                _this.startConnection();
                _this.checkCloseConnection();
            });
        };
        this.startSignalR();
    }
    NotificationsApiService.prototype.getReminders = function () {
        var _this = this;
        var url = this.getRemindersApi();
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request).then(function (response) {
            return _this.mapService.mapToReminders(response.data);
        }).catch(function () {
            return [];
        });
    };
    NotificationsApiService.prototype.markRemindersAsRead = function (reminders) {
        var url = this.getRemindersApi();
        var request = this.urlParamsService.createPutRequest(url, {
            reminderIds: _.map(reminders, function (reminder) {
                return reminder.reminderId;
            })
        });
        this.apiUtilService
            .request(request)
            .catch(function () { });
    };
    NotificationsApiService.prototype.getMessages = function () {
        var _this = this;
        var url = this.getMessagesApi();
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request).then(function (response) {
            return _this.mapService.mapToMessages(response.data);
        }).catch(function () {
            return [];
        });
    };
    NotificationsApiService.prototype.getIconAccessCheck = function (orgLevelId) {
        var _this = this;
        if (orgLevelId === void 0) { orgLevelId = null; }
        var url = this.getCheckAccessApiRoot();
        if (orgLevelId) {
            url += "/" + orgLevelId;
        }
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request)
            .then(function (response) {
            return _this.mapService.mapToIconAccess(response.data);
        });
    };
    NotificationsApiService.prototype.getMessageStatusUpdate = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var obj, url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                obj = {
                    "employees": [id],
                    "excludeEmployees": [],
                    "all": false,
                    "orgLevelId": null,
                    "isArchived": null,
                    "allUnRead": false
                };
                url = this.getMessageUpdateByUserIdUrl() + "?" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
                request = new HttpRequest('PUT', url, obj);
                return [2 /*return*/, this.apiUtilService.request(request).then(function (response) { return _this.mapService.mapToUpdateReadStatus(response); })];
            });
        });
    };
    NotificationsApiService.prototype.getMessageUpdateByUserIdUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.read.configuration;
    };
    NotificationsApiService.prototype.getUnreadCount = function (orgLevelId, isMyMessage) {
        var _this = this;
        var url = this.getUnreadCountByUserIdUrl() + "?" + configurationConfig.api.configuration.messageCenter.isArchived + "=false&isMyMessage=" + isMyMessage + "&" + configurationConfig.api.configuration.messageCenter.orgLevelId + "=" + orgLevelId + "&" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request).then(function (response) { return _this.mapService.unreadMsgCount(response); });
    };
    NotificationsApiService.prototype.getUnreadCountByUserIdUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.unreadCount;
    };
    NotificationsApiService.prototype.startSignalR = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var access, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.getIconAccessCheck()];
                    case 1:
                        access = _a.sent();
                        if (!access.isMessageCenterEnabled) {
                            return [2 /*return*/];
                        }
                        this.buildConnection();
                        this.startConnection();
                        this.checkCloseConnection();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    NotificationsApiService.prototype.registerMessageEvent = function () {
        var _this = this;
        this.hubConnection.on('MessageReceived', function (data) {
            _this.messageReceived.emit(data);
        });
    };
    NotificationsApiService.prototype.subcribeToNewmessageAlert = function (callback, debounce) {
        if (debounce === void 0) { debounce = false; }
        Assert.isNotNull(callback, 'callback');
        if (debounce) {
            return this.messageReceived
                .debounceTime(NOTIFICATIONS_API_DEBOUNCE_TIME_MS)
                .subscribe(callback);
        }
        return this.messageReceived.subscribe(callback);
    };
    NotificationsApiService.prototype.getArchiveCount = function (orgLevelId, isMyMessage) {
        var _this = this;
        var url = this.getArchiveCountByUserIdUrl() + "?" + configurationConfig.api.configuration.messageCenter.isArchived + "=true&isMyMessage=" + isMyMessage + "&" + configurationConfig.api.configuration.messageCenter.orgLevelId + "=" + orgLevelId + "&" + configurationConfig.api.configuration.messageCenter.apiVersion + "=" + this.getCommunicationApiRootVersion();
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request).then(function (response) { return _this.mapService.unreadMsgCount(response); });
    };
    NotificationsApiService.prototype.getArchiveCountByUserIdUrl = function () {
        return this.apiUtilService.getCommunicationApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages + "/" + configurationConfig.api.configuration.messageCenter.unreadCount;
    };
    NotificationsApiService.prototype.getRemindersApi = function () {
        return this.getUserApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.reminders;
    };
    NotificationsApiService.prototype.getMessagesApi = function () {
        return this.getUserApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messages;
    };
    NotificationsApiService.prototype.getUserApiRoot = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.user;
    };
    NotificationsApiService.prototype.getCheckAccessApiRoot = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.configuration.messageCenter.messagecenter + "/" + configurationConfig.api.configuration.messageCenter.checkaccess;
    };
    NotificationsApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    NotificationsApiService.prototype.getCommunicationApiRootVersion = function () {
        return "" + this.apiUtilService.getCommunicationApiRootVersion();
    };
    return NotificationsApiService;
}());
export { NotificationsApiService };
