import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeRehireMapService } from './employee-rehire-map.service';
import { EmployeeRehire, IEmployeeRehire } from '../../models/index';


@Injectable()
export class EmployeeRehireApiService {
  constructor(
    private mapService: EmployeeRehireMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public rehireEmployee(req: EmployeeRehire, newRehireParam?: string): Promise<any> {
    Assert.isNotNull(req, 'req');
    let url: string;
    if(newRehireParam){
      url = `${this.getRehireApiRoot()}/${req.employeeId}/${employeeConfig.api.employees.rehire}/${newRehireParam}`;
    }
    else{
      url = `${this.getRehireApiRoot()}/${req.employeeId}/${employeeConfig.api.employees.rehire}`;
    }
    let modelDTO: IEmployeeRehire = this.mapService.mapToRehireEmployeeDto(req, newRehireParam);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
    return promise;
  }

  private getRehireApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.employees.root}`;
  }
}
