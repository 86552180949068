import { Component, ViewChild, ElementRef } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { IHeaderGroupParams } from 'ag-grid-community';

@Component({
  selector: 'slx-aca-c1095-custom-gh',
  template: `
  <div class="custom-group-header">
    <div class="custom-group-header-holder">
      <span class="main-header-text">{{params.displayName}}</span>
    <div>
  </div>`,
  styles: [`
  .main-header-text {
    color: #999;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    text-align: center;
  }
  .custom-group-header {
    height: 29px !important;
    width: 100%; 
  }
  .custom-group-header-holder {
    bottom: 5px;
  }
  `]
})
export class AcaC1095CustomGroupHeader implements IHeaderGroupAngularComp {
  public params: any;
  public agInit(params: IHeaderGroupParams ): void {
    this.params = params;
  }
}
