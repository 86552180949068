<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham"
                 [gridOptions]="gridOptions"
                 [columnDefs]="columnDefs"
                 [defaultColDef]="defaultColumnDefs"
                 [rowData]="rowData"
                 [pagination]="true"
                 [paginationPageSize]="50"
                 [suppressContextMenu]="true"
                 headerHeight="20"
                 groupHeaders
                 [getRowHeight]="getRowHeight"
                 (gridReady)="onGridReady($event)"
                 (componentStateChanged)="onComponentStateChanged($event)"
                 (cellClicked)="onCellClicked($event)"     
                 (rowGroupOpened) ="onRowGroupOpened($event)"
                 id="myAgGrid"
                 >
</ag-grid-angular>

<ag-grid-angular #agGrid
                 style="width: 100%; height: 100%;"
                 id="myGrid"
                 class="ag-theme-balham"
                 [hidden]="true"
                 [columnDefs]="gridHelper.columnDefs"
                 [rowData]="exportRow"
                 (gridReady)="onGridReadyExport($event)"></ag-grid-angular>
