<div class="date-range-div">
  <slx-daterange-list [listRanges]="scheduleCycles" [(ngModel)]="selectedScheduleCycle"
    (ngModelChange)="onScheduleCycleSelected($event)">
  </slx-daterange-list>
</div>

<div class="individual-schedule slx-main-content-indents">
  <div class="left-column" [ngClass]="{'hide-grid' : !individualScheduleManagementService.showEmployeeList }">
    <slx-individual-schedule-employee-list></slx-individual-schedule-employee-list>
  </div>
  <div class="right-column" [ngClass]="{'schedule-edit-section' : !individualScheduleManagementService.showEmployeeList }">
    <slx-spinner [show]="state.isEmployeeLoading || state.isNightInfoLoading">
      <slx-individual-schedule-entries-editor></slx-individual-schedule-entries-editor>
    </slx-spinner>
  </div>
</div>
