import { Component, Input } from "@angular/core";
import { BusyService } from "../../../../common/services";
import { NotificationsService } from '../../../../core/components';
import { dataCheck_emp, WFMOrgLevelChangeLogModel } from '../../../models/wfm-sync';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';

@Component({
  selector: 'slx-wfm-ngp-org-dept-pos-sync',
  templateUrl: './wfm-ngp-org-dept-pos-sync.component.html',
  styleUrls: ['./wfm-ngp-org-dept-pos-sync.component.scss'],
})
export class WfmNgpOrgDeptPosSyncComponent extends BaseSyncComponent<WFMOrgLevelChangeLogModel> {
  @Input() syncType: string;
  @Input() subType: string;

  constructor(
    private wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  protected syncDataCore(selectedItems: WFMOrgLevelChangeLogModel[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.SyncOrganizationLevelsChangeLogs(selectedItems, this.syncType)
    );
  }

  protected getSyncItemKey(item: WFMOrgLevelChangeLogModel): string {
    if (this.subType === 'position') {
      return '' + item.jobCode;
    }

    return '' + item.orgLevelId;
  }

  protected async fetchData(): Promise<WFMOrgLevelChangeLogModel[]> {
    const response = await this.busyService.busy(
      this.wfmSyncService.GetOrganizationLevelsChangeLogs(this.syncType, this.subType)
    );

    return this.processData(response.data);
  }

  private processData(data: WFMOrgLevelChangeLogModel[]): WFMOrgLevelChangeLogModel[] {
    return dataCheck_emp(data);
  }
}
