import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../app.config';
import { AccessibleApiService } from './accessible-api.service';
import { OrgLevelWatchService } from '../org-level/org-level-watch.service';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { IRouteInfo, isRouteInfo, IRouteDetails } from '../../../core/models/index';
import { IOrgRouteData } from '../../models/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../common/components/index';
import { ModalService } from '../../../common/services/index';
import { mutableSelect } from '../../../core/decorators/index';
import { PreviousRouteService } from '../../../core/services/previous-route/previous-route-service';
import { UserApplication } from '../../../state-model/models/index';
import { ComponentAccessInfo } from '../../../organization/models/index';
import { Subscription } from 'rxjs/Subscription';
import { ActivationStart, Router, Event, ActivatedRoute, Params } from '@angular/router';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { StateNavigationService, ChangeManagementService, RestoreQueryParamsService } from '../../../common/services/index';
import { DateTimeService } from './../../../common/services/date-time/date-time.service';


@Injectable()
export class AccessibleService {

  cachedCheckedByOrglevel = [];
  cachedCheckedByEmployee = [];

  @mutableSelect('application')
  public application$: Observable<UserApplication>;

  public get applicationId(): number {
    return this.m_applicationId ? this.m_applicationId : appConfig.globalMenuItemsApplication.id;
  }
  public positionOrglevel: number;
  private m_applicationId: number;
  private currentComponentId: string;
  private cachedCheckAccessiblePromise: Promise<boolean>;
  private cachedCheckAccessibleContext: string;
  private cachedCheckAccessibleComponentId: string;
  private cachedCheckAccessibleOrgLevelId: number;
  private cachedCheckAccessibleEmpId: number;
  private navigationService: StateNavigationService;

  constructor(
    private appStateBusService: ApplicationStateBusService,
    private orgLevelWatchService: OrgLevelWatchService,
    private accessibleApiService: AccessibleApiService,
    private modalService: ModalService,
    private previousRouteService: PreviousRouteService,
    private router: Router,
    private route: ActivatedRoute,
    private changeManagement: ChangeManagementService,
    private restoreQueryParamsService: RestoreQueryParamsService,
    private dateTimeService: DateTimeService) {
    this.navigationService = new StateNavigationService(this.router, this.route, this.changeManagement, this.restoreQueryParamsService);
    this.m_applicationId = appConfig.globalMenuItemsApplication.id;
    this.application$.subscribe((value: UserApplication) => {
      this.m_applicationId = value.id;
    });
  }

  public clearComponentId(): void {
    this.currentComponentId = null;
  }

  public isAccessible(info: IRouteInfo, orgRouteData: IOrgRouteData): boolean | Promise<boolean> {
    if (!info.accessContext) {
      return true;
    }
    return this.checkAccessible(info, orgRouteData);
  }

  public isAccessibleForChangeOrgLevel(orgLevelId: number): Promise<boolean> {

    if (!this.currentComponentId || !this.applicationId) {
      return Promise.resolve(true);
    }
    return this.checkByOrgLevel(this.applicationId, this.currentComponentId, orgLevelId);
  }

  private checkAccessible(info: IRouteInfo, orgRouteData: IOrgRouteData): boolean | Promise<boolean> {
    const currentComponentId = info.componentId;
    let checkCachedPromise = this.checkForDuplicateRequest(info, orgRouteData);
    if (!_.isNil(checkCachedPromise)) {
      return checkCachedPromise;
    }

    switch (info.accessContext) {
      case 'OrgLevel':
        const orgLevel = this.orgLevelWatchService.getCurrentOrgLevel();
        let orgLevelId = 0;
        if (!orgLevel) {
          orgLevelId = orgRouteData.orgLevelId;
        } else {
          orgLevelId = orgLevel.id;
        }
        if (!_.isFinite(orgLevelId)) {
          this.navigationService.navigateToHome();
          return false;
        }
        this.cachedCheckAccessiblePromise = this.checkByOrgLevel(this.applicationId, currentComponentId, orgLevelId);
        return this.cachedCheckAccessiblePromise;
      case 'Employee':
        let employeeId = orgRouteData.empId;
        if (!_.isFinite(employeeId)) {
          throw new Error('Expected employeeId parameter in route');
        }
        this.cachedCheckAccessiblePromise = this.checkByEmployee(this.applicationId, currentComponentId, employeeId);
        return this.cachedCheckAccessiblePromise;
      default:
        throw new Error('Unknown access context');
    }
  }

  private checkForDuplicateRequest(info: IRouteInfo, orgRouteData: IOrgRouteData): Promise<boolean> {
    switch (info.accessContext) {
      case 'OrgLevel':
        const orgLevel = this.orgLevelWatchService.getCurrentOrgLevel();
        let orgLevelId = 0;
        if (!orgLevel) {
          orgLevelId = orgRouteData.orgLevelId;
        } else {
          orgLevelId = orgLevel.id;
        }
        if (info.accessContext === this.cachedCheckAccessibleContext
          && info.componentId === this.cachedCheckAccessibleComponentId
          && orgLevelId === this.cachedCheckAccessibleOrgLevelId
          && !_.isNil(this.cachedCheckAccessiblePromise))
          return this.cachedCheckAccessiblePromise;
        else {
          this.cachedCheckAccessibleContext = info.accessContext;
          this.cachedCheckAccessibleComponentId = info.componentId;
          this.cachedCheckAccessibleOrgLevelId = orgLevelId;
          return null;
        }
      case 'Employee':
        let employeeId = +orgRouteData.empId;
        if (info.accessContext === this.cachedCheckAccessibleContext
          && info.componentId === this.cachedCheckAccessibleComponentId
          && employeeId === this.cachedCheckAccessibleEmpId
          && !_.isNil(this.cachedCheckAccessiblePromise))
          return this.cachedCheckAccessiblePromise;
        else {
          this.cachedCheckAccessibleContext = info.accessContext;
          this.cachedCheckAccessibleComponentId = info.componentId;
          this.cachedCheckAccessibleEmpId = employeeId;
          return null;
        }
    }
    return null;
  }

  clearCheckedByOrglevelCachedData() {
    this.cachedCheckedByOrglevel = [];
  }

  clearOldCheckedByOrglevelCachedData() {
    const currentTime = new Date();
    this.cachedCheckedByOrglevel = this.cachedCheckedByOrglevel.filter(item => {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
      if (seconds < appConfig.cacheInvalidateAccessibleTime) {
        return item;
      }
    });
  }

  private checkByOrgLevel(applicationId: number, componentId: string, orgLevelId: number): Promise<boolean> {

    let promise: Promise<boolean> = null;

    // Check if data already fetched for the selected cached Id within 60 seconds.
    // If yes than return the cached data 
    // Else fetch data from API, store it in cache and return it.
    let cacheId: string = "" + applicationId;
    if (componentId) {
      cacheId = cacheId + '_' + componentId;
    }
    if (orgLevelId) {
      cacheId = cacheId + '_' + orgLevelId;
    }
    this.clearOldCheckedByOrglevelCachedData();
    const cacheItemIndex = this.cachedCheckedByOrglevel.findIndex(item => item.cacheId === cacheId);
    if (cacheItemIndex > -1) {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedCheckedByOrglevel[cacheItemIndex].timestamp), new Date());
      if (seconds < appConfig.cacheInvalidateAccessibleTime) {
        const item = this.cachedCheckedByOrglevel[cacheItemIndex];
        // In here means data present in cache.
        promise = new Promise((resolve) => {
          this.resolveAccessibilityPromise(item.result, item.componentId, resolve, item.orgLevelId);
        });
        return promise;
      }
    }

    promise = new Promise((resolve, reject) => {
      this.accessibleApiService.getComponentAccessibleByOrgLevel(applicationId, componentId, orgLevelId)
        .then((result: ComponentAccessInfo) => {
          this.cachedCheckedByOrglevel.push({
            cacheId,
            result,
            componentId,
            orgLevelId,
            timestamp: new Date().getTime()
          });
          this.resolveAccessibilityPromise(result, componentId, resolve, orgLevelId);
        });
    });
    return promise;
  }

  clearCheckedByEmployeeCachedData() {
    this.cachedCheckedByEmployee = [];
  }

  clearOldCheckedByEmployeeCachedData() {
    const currentTime = new Date();
    this.cachedCheckedByEmployee = this.cachedCheckedByEmployee.filter(item => {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
      if (seconds < appConfig.cacheInvalidateAccessibleTime) {
        return item;
      }
    });
  }

  private checkByEmployee(applicationId: number, componentId: string, employeeId: number): Promise<boolean> {

    let promise: Promise<boolean> = null;

    // Check if data already fetched for the selected cached Id within 60 seconds.
    // If yes than return the cached data 
    // Else fetch data from API, store it in cache and return it.
    let cacheId: string = "" + applicationId;
    if (componentId) {
      cacheId = cacheId + '_' + componentId;
    }
    if (employeeId) {
      cacheId = cacheId + '_' + employeeId;
    }
    this.clearOldCheckedByEmployeeCachedData();
    const cacheItemIndex = this.cachedCheckedByEmployee.findIndex(item => item.cacheId === cacheId);
    if (cacheItemIndex > -1) {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedCheckedByEmployee[cacheItemIndex].timestamp), new Date());
      if (seconds < appConfig.cacheInvalidateAccessibleTime) {
        const item = this.cachedCheckedByEmployee[cacheItemIndex];
        // In here means data present in cache.
        promise = new Promise((resolve) => {
          this.resolveAccessibilityPromise(item.result, item.componentId, resolve);
        });
        return promise;
      }
    }

    promise = new Promise((resolve, reject) => {
      this.accessibleApiService.getComponentAccessibleByEmployee(applicationId, componentId, employeeId)
        .then((result: ComponentAccessInfo) => {
          this.cachedCheckedByEmployee.push({
            cacheId,
            result,
            componentId,
            timestamp: new Date().getTime()
          });
          this.resolveAccessibilityPromise(result, componentId, resolve);
        });
    });
    return promise;
  }

  private resolveAccessibilityPromise(result: ComponentAccessInfo, componentId: string,  resolve: (value?: boolean | PromiseLike<boolean>) => void, orgLevelId?: number): void {
    if (!(result.rightsRestriction || result.conversionRestriction)) {
      this.currentComponentId = componentId;
      resolve(true);
    } else {
      this.cachedCheckAccessiblePromise = null;
      let prevRoute = this.previousRouteService.getPreviousUrl();
      if (!prevRoute) {
        this.navigationService.navigateToHome(orgLevelId);
        resolve(false);
      } else {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showOK = false;
        options.showCancel = true;
        let message: string = 'Insufficient Access Rights. Contact your system administrator.';
        if (result.conversionRestriction) {
          message = 'Your organization has not yet been upgraded. Please continue to use WorkLinx5 until the upgrade occurs.';
        }
        ConfirmDialogComponent.openDialog('Warning', message, this.modalService, (result: boolean) => {
          resolve(false);
        }, options);
      }
    }
  }
}
