import { Component, OnInit, Inject } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { LookupApiService } from '../../../../organization/services/index';
import { ScheduleAbsence, EmployeeDefinition } from '../../../../organization/models/index';
import { VacationPlanEmployeeDetails, VacationPlanEmployeeRecordDetails, VacationPlanEmployeeSummary, VacationPlanEmployeeWeek } from '../../../models/index';
import { VacationPlannerApiService } from '../../../services/index';
import { ORG_LEVEL_ID_TOKEN } from '../../../../core/models/index';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-vacation-employee-dialog',
  templateUrl: 'vacation-employee-dialog.component.html',
  styleUrls: ['vacation-employee-dialog.component.scss']
})
export class VacationEmployeeDialogComponent implements OnInit, IDialog {

  public options: DialogOptions;
  public dialogResult: boolean;
  public employee: EmployeeDefinition;
  public week: VacationPlanEmployeeWeek;
  public originalRecords: VacationPlanEmployeeRecordDetails[];
  public employeeDetails: VacationPlanEmployeeDetails;
  public employeeSummary: VacationPlanEmployeeSummary;
  public get year(): number {
    return this.week.start.year();
  }
  public state: {
    isLoading: boolean;
    isSummary: boolean;
  };
  public orgLevelId: number;

  private modalService: ModalService;
  private lookupApiService: LookupApiService;
  private vacationPlannerApiService: VacationPlannerApiService;

  constructor(options: DialogOptions,
    modalService: ModalService,
    vacationPlannerApiService: VacationPlannerApiService,
    lookupApiService: LookupApiService,
    employee: EmployeeDefinition,
    week: VacationPlanEmployeeWeek,
    @Inject(ORG_LEVEL_ID_TOKEN) orgLevelId: Number
  ) {
    this.options = options;
    this.modalService = modalService;
    this.vacationPlannerApiService = vacationPlannerApiService;
    this.lookupApiService = lookupApiService;
    this.dialogResult = false;
    this.employee = employee;
    this.week = week;
    this.orgLevelId = <number>orgLevelId;
    this.state = {
      isLoading: false,
      isSummary: false
    };
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    this.vacationPlannerApiService.getVacationPlanEmployeeDetails(this.employee, this.week.start.toDate())
      .then((employeeDetails: VacationPlanEmployeeDetails) => {
        this.originalRecords = _.cloneDeep(employeeDetails.records);
        this.employeeDetails = employeeDetails;
        this.state.isLoading = false;
      })
      .catch(() => {
        this.state.isLoading = false;
      });
    $('.k-window-title').text(this.employee.name);
  }

  public toggleToSummary(): void {
    this.state.isSummary = true;
    if (!this.employeeSummary) {
      this.state.isLoading = true;
      this.vacationPlannerApiService.getVacationPlanEmployeeSummary(this.employee.id, this.year)
        .then((employeeSummary: VacationPlanEmployeeSummary) => {
          this.employeeSummary = employeeSummary;
          this.state.isLoading = false;
        })
        .catch(() => {
          this.state.isLoading = false;
        });
    }
  }

  public toggleToDetails(): void {
    this.state.isSummary = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
