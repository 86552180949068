import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsContacts } from '../../../models/index';
import { EmployeeSectionBridgeService, EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeDefinitionsApiService } from '../../../../../organization';
import { NotificationsService } from '../../../../../core/components';
import { NotificationsApiService } from '../../../../../portal/services';
import { unsubscribe } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { EmployeeValidatorAdapter } from '../../../../../organization/services/index';
import * as _ from 'lodash';
var EmployeeSectionsContactsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsContactsComponent, _super);
    function EmployeeSectionsContactsComponent(decorator, ngZone, employeeSectionsPersonalApiService, employeeDefinitionsApiService, notificationsService, notificationsApiService, employeeSectionBridgeService, employeeActivitiesValidatorAdapter) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
        _this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        _this.notificationsService = notificationsService;
        _this.notificationsApiService = notificationsApiService;
        _this.employeeSectionBridgeService = employeeSectionBridgeService;
        _this.enableEmailOptIn = false;
        _this.optInTemplateText = '';
        _this.isMessageCenterEnabled = false;
        _this.firstName = decorator.employeeShortInfo.firstName;
        _this.status = decorator.employeeShortInfo.status;
        _this.orgLevelId = decorator.employeeShortInfo.organization.orgLevelId;
        _this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsContactsComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsContactsComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsContacts;
    };
    Object.defineProperty(EmployeeSectionsContactsComponent.prototype, "emailValidation", {
        get: function () {
            return EmployeeValidatorAdapter.emailValidation;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsContactsComponent.prototype.ngOnInit = function () {
        this.loadSubsection();
        this.initSubscriptions();
    };
    EmployeeSectionsContactsComponent.prototype.initSubscriptions = function () {
        var _this = this;
        this.profileSubscriptions = this.employeeSectionBridgeService.subscribeTorUpdateContactSection(function (mobileNumber) {
            _this.employeeSectionsContacts.mobile.fieldValue = mobileNumber;
        });
    };
    EmployeeSectionsContactsComponent.prototype.checkMessageCenterStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var messageCenterStatus, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.notificationsApiService.getIconAccessCheck()];
                    case 1:
                        messageCenterStatus = _a.sent();
                        this.isMessageCenterEnabled = messageCenterStatus.isMessageCenterEnabled;
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsContactsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.getPersonalContacts(this.employeeId)
            .then(function (employeeSectionsContacts) {
            _this.employeeSectionsContacts = employeeSectionsContacts;
            _this.checkPrimaryEmail(employeeSectionsContacts);
            _this.stopProgress();
            if (_this.employeeSectionsContacts) {
                _this.onLoadBindControls();
            }
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsContactsComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.employeeSectionsPersonalApiService.setPersonalContacts(this.employeeId, this.employeeSectionsContacts, effectiveDate)
            .then(function (status) {
            _this.employeeSectionBridgeService.reloadProfileSection(_this.employeeSectionsContacts);
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsContactsComponent.prototype.checkTemporalDirty = function () {
        return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsContacts);
    };
    EmployeeSectionsContactsComponent.prototype.onLoadBindControls = function () {
        if (this.employeeSectionsContacts) {
            if (this.employeeShortInfo && this.employeeShortInfo.position) {
                this.orgLevelId = this.employeeShortInfo.position.orgLevelId;
            }
            this.getTemplate();
        }
    };
    EmployeeSectionsContactsComponent.prototype.resendOptInSMS = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.startProgress();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.employeeDefinitionsApiService.resendOptInSms([this.employeeId], this.orgLevelId)];
                    case 2:
                        _a.sent();
                        this.notificationsService.success('SMS message sent', 'SMS opt in message was sent successfully');
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.loadSubsection();
                        this.stopProgress();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsContactsComponent.prototype.resendOptInEmail = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.startProgress();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.employeeDefinitionsApiService.resendOptInEmail([this.employeeId])];
                    case 2:
                        _a.sent();
                        this.notificationsService.success('Email sent', 'Email opt in instructions were sent successfully');
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.loadSubsection();
                        this.stopProgress();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsContactsComponent.prototype.getTemplate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.checkMessageCenterStatus()];
                    case 1:
                        _c.sent();
                        if (!this.isMessageCenterEnabled) {
                            return [2 /*return*/];
                        }
                        if (!(this.employeeSectionsContacts.empOptIn === 0)) return [3 /*break*/, 5];
                        if (!_.isNull(this.employeeSectionsContacts.email1.rawValue)) return [3 /*break*/, 2];
                        this.optInTemplateText = "Employee doesn't have an email on their profile";
                        return [3 /*break*/, 4];
                    case 2:
                        _a = this;
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getEmailTemplate()];
                    case 3:
                        _a.optInTemplateText = _c.sent();
                        _c.label = 4;
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        if (!(this.employeeSectionsContacts.empOptIn === 2)) return [3 /*break*/, 7];
                        _b = this;
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getSmsTemplate(this.orgLevelId)];
                    case 6:
                        _b.optInTemplateText = _c.sent();
                        _c.label = 7;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsContactsComponent.prototype.checkPrimaryEmail = function (employeeSectionsContacts) {
        this.enableEmailOptIn = _.isNull(employeeSectionsContacts.email1.rawValue);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsContactsComponent.prototype, "profileSubscriptions", void 0);
    return EmployeeSectionsContactsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsContactsComponent };
