import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../../framework/index';
import { IColumnSettings } from '../../../../core/models/index';

import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';

import { OrgLevel } from '../../../../state-model/models/index';

import { DateRange, IDateRange } from '../../../../core/models/index';

import { ColumnSettingsStorageService, ColumnManagementService } from '../../../../common/services/index';
import { WCComparisonsState, WcComparisonsContainer, WcViewList, WcViewItem } from '../../models/index';
import { WcCommonManagementService } from './wc-common-management.service';
import { WcRosterToolbarService } from './wc-roster-toolbar.service';
import { WcRosterApiService } from './wc-roster-api.service';

@Injectable()
export class WcComparisonsManagementService {
  private componentId: string;
  private columnsVisibilityKey: string;
  private currentViewMode: WcViewItem;
  private yearRange: number[];

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private dateRange: IDateRange = null;
  private loading$ = new ReplaySubject<boolean>(1);
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private exportTo$ = new Subject<boolean>();
  private state$ = new ReplaySubject<WCComparisonsState>(1);


  private comparisonsLoaded$ = new ReplaySubject<WcComparisonsContainer>(1);
  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor (private manService: WcCommonManagementService, 
      private toolbarService: WcRosterToolbarService, 
      private apiService: WcRosterApiService,
      private columnSettingsStorageService: ColumnSettingsStorageService,
      private columnManagementService: ColumnManagementService) {}

  public init(componentId: string): void {
    this.componentId = componentId;
    this.columnsVisibilityKey = 'columnsVisibility';
    this.currentViewMode = new WcViewItem(WcViewList.Comparisons, '', '');
    this.subscribeToToolbarService();
    this.subscribeToOrgLevelChanges();
    this.restoreState();
  }

  public destroy(): void {
    this.manService.destroy();

    this.orgLevelChanged$.complete();
    this.comparisonsLoaded$.complete();
    this.loading$.complete();
    this.exportTo$.complete();
    this.state$.complete();
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    return this.manService.subscribeToOrgLevel(callback);
  }

  public subscribeToExport(callback: (isPDF: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportTo$.subscribe(callback);
  }

  public subscribeToChangeState(callback: (s: WCComparisonsState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.state$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    return this.subscribeToCompLoading(callback);
  }

  public subscribeToLoadedRecords(callback: (v: WcComparisonsContainer) => void): Subscription {
    return this.subscribeToLoadedComparisonsRecords(callback);
  }

  public subscritpionToSenseYearChange(callback: (o: number[]) => void): Subscription {
    return this.toolbarService.subscritpionToSenseYearChange(callback);
  }

  public openEditReportDialog(reportId: number): void {
    this.manService.openEditReportDialog(reportId);
  }

  public subscribeToCompLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToToolbarService(): void {
    this.subscriptions.export = this.toolbarService
      .subscribeToExport((isPDF: boolean) => this.exportTo$.next(isPDF));

    this.subscriptions.saveState = this.toolbarService
      .subscribeToSaveState((s: WCComparisonsState) => {
      this.columnSettingsStorageService.setColumnsState(this.componentId, this.columnsVisibilityKey, s.columns);
      this.state$.next(s);
    });

    this.subscriptions.defineState = this.toolbarService
      .subscribeToDefineState((s: WCComparisonsState) => {
      this.state$.next(s);
    });

    this.subscriptions.comparisons = this.toolbarService
      .subscritpionToSenseYearChange((selectedYearsList: number[]) => {
      this.yearRange = selectedYearsList;
      const sDate =new Date(selectedYearsList[0], 0, 1);
      const eDate = (selectedYearsList[selectedYearsList.length - 1] == moment().year()) ? new Date() : new Date(selectedYearsList[selectedYearsList.length -1], 11, 31);
      this.dateRange = new DateRange(sDate, eDate);
      this.loadComparisons();
      this.restoreState();
    });
  }

  public subscribeToLoadedComparisonsRecords(callback: (v: WcComparisonsContainer) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.comparisonsLoaded$.subscribe(callback);
  }

  public loadComparisons(): void {
    if (
      !_.isFinite(_.get(this.orgLevel, 'id'))
      || !_.isDate(_.get(this.dateRange, 'startDate'))
      || !_.isDate(_.get(this.dateRange, 'endDate'))
    ) return;

    this.loading$.next(true);
    this.apiService.getComparisons(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
      .then((container: WcComparisonsContainer) => {
        this.comparisonsLoaded$.next(container);
        this.loading$.next(false);
        this.toolbarService.permissionsChanged(container.canEdit);
      });
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscribeToOrgLevelChangesFromComparisons();
  }

  public subscribeToOrgLevelChangesFromComparisons(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe(async (orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;

        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        this.loadComparisons();
      });
  }

  private restoreState(): void {
    const state = new WCComparisonsState();
    state.dynamicMonthCount(this.yearRange);
    state.createColumns();
    state.dynamicColumn();
    this.restoreComparisonState(state);
  }

  public restoreComparisonState(state: { columns: IColumnSettings[], columnsMap: StringMap<IColumnSettings> }): void {
    this.columnManagementService.init(this.componentId);
    this.columnManagementService.initializeGroupWithColumns(this.columnsVisibilityKey, state.columns);
    this.subscriptions.state = this.columnManagementService.groupInitialized$
      .filter((event) => event.group === this.columnsVisibilityKey)
      .subscribe(() => {
        this.toolbarService.defineState(state);
      });
  }
}
