<slx-spinner [show]="state.isLoading">

  <div class="slx-high-box__body">
    <kendo-grid
      #kendoGrid
      class="slx-full-height position-grid"
      [data]="grid.data"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
      (remove)="removeHandler($event)"
    >
      <kendo-grid-command-column
        title=""
        [width]="100"
        [minResizableWidth]="100"
        [locked]="true"
        media="(min-width: 450px)"
      >
        <ng-template kendoGridCellTemplate let-isNew="!isEdit" *ngIf="!restrictedByOrgLevel">
          <button kendoGridRemoveCommand>
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>
          <button kendoGridSaveCommand type="button">
            <i class="far fa-check-circle" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand type="button">
            <i class="fa fa-ban" aria-hidden="true"></i>
          </button>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column
        title="Smartlinx Position Name"
        field="position_name"
        [filterable]="true"
        width="300"
        media="(min-width: 450px)"
      >
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <slx-dropdown-list
            class="slx-wide slx-short"
            name="name"
            valueField="id"
            titleField="name"
            [(ngModel)]="dataItem.position"
            [options]="filteredPartnerPositionList"
          >
          </slx-dropdown-list>
        </ng-template>

        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</slx-spinner>
