/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./export-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./export-data-settings/export-data-section.component.ngfactory";
import * as i3 from "./export-data-settings/export-data-section.component";
import * as i4 from "../../services/export-data-management.service";
import * as i5 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i6 from "../../../../common/components/spinner/spinner.component";
import * as i7 from "@angular/common";
import * as i8 from "../../../../common/services/state-management/state-management.service";
import * as i9 from "../../../../common/services/component-state/component-state-storage.service";
import * as i10 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i11 from "../../../../common/services/export-data/export-data-event.service";
import * as i12 from "../../../../core/services/throttling/throttling.service";
import * as i13 from "../../../../core/services/session/session.service";
import * as i14 from "../../services/export-data-api.service";
import * as i15 from "../../services/export-data-map.service";
import * as i16 from "../../../../common/services/file/file.service";
import * as i17 from "../../../../core/services/user-activity/user-activity.service";
import * as i18 from "../../../../core/services/token-validity/token-validity.service";
import * as i19 from "./export-data.component";
var styles_ExportDataComponent = [i0.styles];
var RenderType_ExportDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExportDataComponent, data: {} });
export { RenderType_ExportDataComponent as RenderType_ExportDataComponent };
function View_ExportDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "export-data-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-export-data-section", [["style", "width: 100%"]], null, null, null, i2.View_ExportDataSectionComponent_0, i2.RenderType_ExportDataSectionComponent)), i1.ɵdid(2, 245760, null, 0, i3.ExportDataSectionComponent, [i4.ExportDataManagementService, i1.ChangeDetectorRef], { configuration: [0, "configuration"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ExportDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "slx-spinner", [], null, null, null, i5.View_SpinnerComponent_0, i5.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i6.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 11, "div", [["class", "slx-high-box__body slx-main-content-indents export-data-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "export-data-content_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "header-item group-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-building"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "header-item__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Export Name"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "header-item request-status-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-thermometer-three-quarters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "header-item__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Status"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExportDataComponent_1)), i1.ɵdid(13, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.exportConfigurations; _ck(_v, 13, 0, currVal_1); }, null); }
export function View_ExportDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-export-data", [], null, null, null, View_ExportDataComponent_0, RenderType_ExportDataComponent)), i1.ɵprd(512, null, i8.StateManagementService, i8.StateManagementService, [i9.ComponentStateStorageService, i10.ColumnSettingsStorageService]), i1.ɵprd(131584, null, i11.ExportDataEventService, i11.ExportDataEventService, [i12.ThrottlingService, i13.SessionService]), i1.ɵprd(131584, null, i4.ExportDataManagementService, i4.ExportDataManagementService, [i14.ExportDataApiService, i15.ExportDataMapService, i16.FileService, i17.UserActivityService, i18.TokenValidityService, i11.ExportDataEventService]), i1.ɵdid(4, 245760, null, 0, i19.ExportDataComponent, [i8.StateManagementService, i4.ExportDataManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var ExportDataComponentNgFactory = i1.ɵccf("slx-export-data", i19.ExportDataComponent, View_ExportDataComponent_Host_0, {}, {}, []);
export { ExportDataComponentNgFactory as ExportDataComponentNgFactory };
