import * as _ from 'lodash';
import { Role, RoleComponent, RolesComponentAccess } from '../role-models/index';
import { RolesRowDefinition, IRolesRow } from './roles-row';
import { RolesSubmenuRow } from './roles-submenu-row';

export class RolesComponentRow implements IRolesRow<RoleComponent> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleComponent>;
  public dirtyByRole: NumberMap<boolean>;
  public type: RolesRowDefinition = 'RolesComponentRow';
  public description: string;

  public recalcStatus(roleId: number): void {
    let menu: RoleComponent = this.mapByRole[roleId];
    this.dirtyByRole[roleId] = menu.isDirty;
    let existEnabled: boolean = false;
    let existDisabled: boolean = false;
    if (existEnabled) {
      menu.isEnabled = true;
    }
    if (menu.isEnabled) {
      menu.access = RolesComponentAccess.enabled;
    } else {
      menu.access = RolesComponentAccess.disabled;
    }
  }

  public setNextStatus(roleId: number): void {
    let menu: RoleComponent = this.mapByRole[roleId];
    menu.isEnabled = !menu.isEnabled;
    this.setStatus(menu.isEnabled, roleId);
  }

  public setStatus(isEnabled: boolean, roleId: number): void {
    let item: RoleComponent = this.mapByRole[roleId];
    item.isEnabled = isEnabled;
    item.access = isEnabled ? RolesComponentAccess.enabled : RolesComponentAccess.disabled;
    this.dirtyByRole[roleId] = item.isDirty;
  }
}
