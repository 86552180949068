import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { OrganizationTreeContext } from '../../models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { OrgLevelMapService } from './org-level-map.service';
import { ApiUtilService } from '../../../common/services/index';
import { Meta } from '../../../core/models/api/meta';
import { organizationConfig } from '../../organization.config';
import { Assert } from '../../../framework/index';

@Injectable()
export class OrgLevelApiService {
  constructor(
    private orgLevelMapService: OrgLevelMapService,
    private apiUtilService: ApiUtilService) {
  }

  public getOrganizationTree(): Promise<OrgLevel[]> {
    const url: string = `${this.getApiRoot()}/${organizationConfig.api.orglevels}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<OrgLevel[]> = this.apiUtilService.request<OrgLevel[], Meta>(request)
      .then((response: ResponseBody<OrgLevel[], Meta>) => {
        let orgLevelData = JSON.stringify(response.data);
        sessionStorage.setItem('Organizations', orgLevelData);
        return this.orgLevelMapService.mapToOrgTree(response.data)
      });
    return promise;
  }
/*
  public getLastSelectedLevel(context: OrganizationTreeContext): Promise<OrgLevel> {
    Assert.isNotNull(context, 'context');
    Assert.isNotNull(context.applicationId, 'context.applicationId');

    const url: string = `${this.getContextApiRoot(context)}/${organizationConfig.api.getlastSelected}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<OrgLevel> = this.apiUtilService.request<OrgLevel, Meta>(request)
      .then((response: ResponseBody<OrgLevel, Meta>) => this.orgLevelMapService.mapToOrgLevelNonStrict(response.data));

    return promise;
  }*/
/*
  public selectOrgLevel(orgLevel: OrgLevel, context: OrganizationTreeContext): Promise<any> {
    Assert.isNotNull(context, 'context');
    Assert.isNotNull(context.applicationId, 'context.applicationId');

    const url: string = `${this.getContextApiRoot(context)}/${organizationConfig.api.putlastSelected}`;

    let body: StringMap<any> = {
      orgLevelId: orgLevel.id
    };

    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<OrgLevel> = this.apiUtilService.request<OrgLevel, Meta>(request)
      .then((response: ResponseBody<OrgLevel, Meta>) => this.orgLevelMapService.mapToOrgLevelNonStrict(response.data));

    return promise;
  }
  */

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.apiroot}`;
  }

  private getContextApiRoot(context: OrganizationTreeContext): string {
    return `${this.getApiRoot()}/${context.applicationId}`;
  }
}
