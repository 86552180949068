import { AccrualsBalancesComponent, AccrualsPolicyComponent } from '../app-modules/accruals/components/index';
import { AccrualsTransactionsComponent } from '../app-modules/accruals/components/index';
import { ApplicationContainerComponent } from './components/index';
import { OrgLevelResolver } from './services/index';
import { AccrualsBalancesResolver } from '../app-modules/accruals/services';
var ɵ0 = { componentId: 'accrual_transactions', accessContext: 'OrgLevel' }, ɵ1 = { componentId: 'accrual_transactions', accessContext: 'OrgLevel' }, ɵ2 = { componentId: 'accrual_balances', accessContext: 'OrgLevel' }, ɵ3 = { componentId: 'accrual_policy_definitions', accessContext: 'OrgLevel' };
export var accrualsRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                resolve: [OrgLevelResolver],
                redirectTo: 'accrual_transactions',
                pathMatch: 'full',
                data: ɵ0
            },
            {
                path: 'accrual_transactions',
                resolve: [OrgLevelResolver],
                component: AccrualsTransactionsComponent,
                data: ɵ1
            },
            {
                path: 'accrual_balances',
                resolve: [OrgLevelResolver, AccrualsBalancesResolver],
                component: AccrualsBalancesComponent,
                data: ɵ2
            },
            {
                path: 'accrual_policy_definitions',
                resolve: [OrgLevelResolver],
                component: AccrualsPolicyComponent,
                data: ɵ3
            }
        ]
    },
];
export { ɵ0, ɵ1, ɵ2, ɵ3 };
