/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./aca-c1095-custom-gh.component";
var styles_AcaC1095CustomGroupHeader = [".main-header-text[_ngcontent-%COMP%] {\n    color: #999;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: 13px;\n    font-weight: 700;\n    line-height: 13px;\n    text-align: center;\n  }\n  .custom-group-header[_ngcontent-%COMP%] {\n    height: 29px !important;\n    width: 100%; \n  }\n  .custom-group-header-holder[_ngcontent-%COMP%] {\n    bottom: 5px;\n  }"];
var RenderType_AcaC1095CustomGroupHeader = i0.ɵcrt({ encapsulation: 0, styles: styles_AcaC1095CustomGroupHeader, data: {} });
export { RenderType_AcaC1095CustomGroupHeader as RenderType_AcaC1095CustomGroupHeader };
export function View_AcaC1095CustomGroupHeader_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "custom-group-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "custom-group-header-holder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "main-header-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.displayName; _ck(_v, 3, 0, currVal_0); }); }
export function View_AcaC1095CustomGroupHeader_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-aca-c1095-custom-gh", [], null, null, null, View_AcaC1095CustomGroupHeader_0, RenderType_AcaC1095CustomGroupHeader)), i0.ɵdid(1, 49152, null, 0, i1.AcaC1095CustomGroupHeader, [], null, null)], null, null); }
var AcaC1095CustomGroupHeaderNgFactory = i0.ɵccf("slx-aca-c1095-custom-gh", i1.AcaC1095CustomGroupHeader, View_AcaC1095CustomGroupHeader_Host_0, {}, {}, []);
export { AcaC1095CustomGroupHeaderNgFactory as AcaC1095CustomGroupHeaderNgFactory };
