import { ModalService } from './../../../../../../common/services/modal/modal.service';
import { ConfirmDialog2Component } from './../../../../../../common/components/confirm-dialog2/confirm-dialog2.component';
import { ConfirmOptions } from './../../../../../../common/components/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { GridComponent, SelectableSettings } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { OrgLevel } from '../../../../../../state-model/models';
import { BenefitLineInfo, BenefitEnrolledEmployee, BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'benefit-option-401k-grid',
  templateUrl: 'benefit-option-401k-grid.component.html',
  styles: [`
    .font-size-13 {
      font-size: 13px !important;
    }
    .no-margin {
      margin: 0;
    }
  `]
})
export class BenefitOption401kGridComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<BenefitEnrolledEmployee>;
  public columnGroup = 'BenefitsOptionGrid';
  public appConfig: IApplicationConfig;
  public data: any[] = [];
  public isLoading: boolean = true;
  public editedRowsCount: number = 0;
  public selectableSettings: SelectableSettings;
  public minEmployeeContribution: number = 0;
  public maxEmployeeContribution: number;
  public currencyFormat = 'c2';
  public stepcurrency = 1;
  public readonly pageSize = 50;
  public minAmt: number = 0;
  public maxAmt: number = 999999999.99;

  @Input()
  public selectedEmployees: BenefitEnrolledEmployee[];

  @Input()
  public tierDetails: BenefitDetailsTier;

  @Output()
  public onEnrollmentCancel: EventEmitter<boolean> = new EventEmitter();

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private navService: BenefitsNavigationService;
  private orgLevel: OrgLevel;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('form', {static: true})
  private ngForm: NgForm;

  private isFormValid: boolean = true;

  constructor(private manService: BenefitEmployeeManagementService, private modalService: ModalService, private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<BenefitEnrolledEmployee>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.selectableSettings = {
      checkboxOnly: true
    };
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    if (this.tierDetails && _.size(this.tierDetails.options) > 0) {
      const option = _.head(this.tierDetails.options);
      this.maxEmployeeContribution = _.isFinite(option.maxEmpContribution) ? option.maxEmpContribution : 100;
      _.forEach(this.selectedEmployees, (item) => {
        item['maxEmpContribution'] = option.maxEmpContribution;
        item['maxMatchGrant'] = option.maxMatchGrant;
        item['limit'] = option.limitAmount;
      });
     this.maxAmt = ((this.maxAmt) && option.limitAmount > 0) ? option.limitAmount : this.maxAmt;
    } else {
      this.showNoOptionsDialog();
    }
    this.refreshGrid();
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(() => {
      this.isFormValid = this.ngForm.valid;
      this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
    });
  }

  public updateRowsData() {
    this.manService.updateEmployeesUpdatedInfo(this.selectedEmployees);
    if (_.size(this.selectedEmployees) > 0) {
      const employeePercentage =  _.some(this.selectedEmployees, employee => !_.isFinite(employee.employeeContributionPercentage >= 0)) ? false : true;
      const employeeContribution= _.some(this.selectedEmployees, employee => !_.isFinite(employee.employeeContributionAmount >= 0)) ? false : true;
      const hasNoContribution = !this.isFormValid || ( employeePercentage || employeeContribution);
      this.manService.benefitsOptionTabDetailsChanged(hasNoContribution);
    }
  }
 
  public empContrPercentageChange(dataItem: BenefitEnrolledEmployee) {
    if (
      _.isObjectLike(dataItem)
        && ((dataItem.employeeContributionPercentage < this.minEmployeeContribution || dataItem.employeeContributionPercentage > this.maxEmployeeContribution)
        || (dataItem.employeeContributionAmount < this.minAmt || dataItem.employeeContributionAmount > this.maxAmt))
      ) {
      this.manService.benefitsOptionTabDetailsChanged(true);
      return;
    }

    this.updateRowsData();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public hasValue(empContribution: number): boolean {
    return _.isFinite(empContribution);
  }

  public ngOnDestroy(): void { }

  private showNoOptionsDialog(): void {
    this.isLoading = true;
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Warning',
      'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.isLoading = false;
          this.onEnrollmentCancel.emit(false);
        }
      }, options);
  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.selectedEmployees) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.selectedEmployees, this.gridState.state);
  }

  public isEmpContribSelected(entry: BenefitEnrolledEmployee): boolean {
    return !!_.get(entry, 'employeeContributionPercentage', null) || (entry.employeeContributionPercentage === 0);
  }

  public isEmpPercentSelected(entry: BenefitEnrolledEmployee): boolean {
    return  !!_.get(entry, 'employeeContributionAmount', null) || (entry.employeeContributionAmount === 0);
  }

}
