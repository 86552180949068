<div class="filter-spacing">
  <slx-input-decorator class="auto-height">
    <slx-dropdown-multiselect slx-input [options]="duaOptions.positionGroupLookup?.items" valueField="id"
      titleField="name" (ngModelChange)="onFilterChange('positionGroups',$event)" placeholder="Position Group"
      name="positionGroupFilter" [(ngModel)]="duaOptions.positionGroupFilter">
    </slx-dropdown-multiselect>
  </slx-input-decorator>
</div>
<div class="filter-spacing">
  <slx-input-decorator class="auto-height">
    <slx-dropdown-multiselect slx-input [options]="duaOptions.positionLookup?.items" valueField="id" titleField="name"
      (ngModelChange)="onFilterChange('position',$event)" placeholder="Position" name="posFilter"
      [(ngModel)]="duaOptions.posFilter">
    </slx-dropdown-multiselect>
  </slx-input-decorator>
</div>
<div class="filter-spacing">
  <slx-input-decorator class="auto-height">
    <slx-dropdown-multiselect slx-input [options]="duaOptions.unitsLookup?.items"
      (ngModelChange)="onFilterChange('unit', $event)" placeholder="Unit" name="unitFilter"
      [(ngModel)]="duaOptions.unitFilter" valueField="id" titleField="name">
    </slx-dropdown-multiselect>
  </slx-input-decorator>
</div>
<div class="filter-spacing">
  <slx-input-decorator class="auto-height">
    <slx-dropdown-multiselect slx-input [options]="duaOptions.shiftsLookup?.items"
      (ngModelChange)="onFilterChange('shift',$event)" placeholder="Shift" name="shiftFilter"
      [(ngModel)]="duaOptions.shiftFilter" valueField="id" titleField="name">
    </slx-dropdown-multiselect>
  </slx-input-decorator>
</div>
<div class="btn-container">
  <button type="button" class="theme-button-apply filter-cancel-btn margin-r" (click)="onDiscard();">Cancel</button>
  <button type="button" class="theme-button-apply filter-apply-btn" (click)="onApply()">Apply</button>
</div>