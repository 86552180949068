var CensusCategoryConfigRecord = /** @class */ (function () {
    function CensusCategoryConfigRecord() {
    }
    Object.defineProperty(CensusCategoryConfigRecord.prototype, "excludeFromTotal", {
        get: function () {
            return !this.includedInTotal;
        },
        set: function (value) {
            this.includedInTotal = !value;
        },
        enumerable: true,
        configurable: true
    });
    return CensusCategoryConfigRecord;
}());
export { CensusCategoryConfigRecord };
var CensusCategoryConfig = /** @class */ (function () {
    function CensusCategoryConfig() {
    }
    return CensusCategoryConfig;
}());
export { CensusCategoryConfig };
var sendCensusCategoryWithCensus = /** @class */ (function () {
    function sendCensusCategoryWithCensus() {
    }
    return sendCensusCategoryWithCensus;
}());
export { sendCensusCategoryWithCensus };
