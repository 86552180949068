import * as tslib_1 from "tslib";
import { IndividualScheduleNightShiftService } from './../../../services/individual-schedule/individual-schedule-night-shift.service';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { IndividualQuickEditOvelapDecisionRequest, IndividualQuickEditOvelap, IndividualQuickEditOvelapDecision, IndividualQuickEditOvelapDecisionState, IndividualQuickEditOvelapState, IndividualQuickEditOvelapStatus, IndSchEntryWrapper } from '../../../models/index';
import { appConfig } from '../../../../app.config';
import { DailyData, DateRange } from '../../../../core/models/index';
import { ScheduleEntryDefinition, ScheduledShiftDefinition } from '../../../../organization/models/index';
import { DateTimeService } from '../../../../common/services/date-time/date-time.service';
import { ModalService } from '../../../../common/services';
import { EmployeeShift } from '../../../../organization/models/index';
import { OverlappingSchedulePopupComponent } from '../overlapping-schedule-popup/overlapping-schedule-popup.component';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { IndividualScheduleEmpManagementService } from '../../../services/index';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
var IndividualScheduleEntryEditorComponent = /** @class */ (function () {
    function IndividualScheduleEntryEditorComponent(dateTimeService, modalService, individualScheduleEmpManagementService, nightShiftService) {
        this.modalService = modalService;
        this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
        this.nightShiftService = nightShiftService;
        this.dayShiftLimit = 10;
        this.subscriptions = {};
        this.dateTimeService = dateTimeService;
        this.onEntryChanged = new EventEmitter();
    }
    Object.defineProperty(IndividualScheduleEntryEditorComponent.prototype, "isEmptyDay", {
        get: function () {
            return (!this.dailyData.data || !this.dailyData.data.entryDef.shifts || this.dailyData.data.entryDef.shifts.length === 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEntryEditorComponent.prototype, "isCanAddShift", {
        get: function () {
            return (!this.dailyData.data || !this.dailyData.data.entryDef.shifts || this.dailyData.data.entryDef.shifts.length < this.dayShiftLimit);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEntryEditorComponent.prototype, "isSingleShift", {
        get: function () {
            return (this.dailyData.data && this.dailyData.data.entryDef.shifts && this.dailyData.data.entryDef.shifts.length === 1);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEntryEditorComponent.prototype, "isOutOfPositionPeriod", {
        get: function () {
            return this.dailyData.startOfDay.isBefore(this.employeeShift.position.startDate) || this.dailyData.startOfDay.isAfter(this.employeeShift.position.endDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEntryEditorComponent.prototype, "employeeShiftIsFilled", {
        get: function () {
            return this.employeeShift && this.employeeShift.isValid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEntryEditorComponent.prototype, "canShowAddShift", {
        get: function () {
            return this.isCanAddShift || !this.readonly || !this.isOutOfPositionPeriod || this.employeeShiftIsFilled;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleEntryEditorComponent.prototype.getScreenSize = function (event) {
        this.screenWidth = window.innerWidth;
    };
    IndividualScheduleEntryEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.screenWidth = window.innerWidth;
        this.subscriptions.loading = this.individualScheduleEmpManagementService
            .subscribeToCellClicked(function (cellClicked) {
            _this.onShiftDefAdd(cellClicked);
        });
    };
    IndividualScheduleEntryEditorComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualScheduleEntryEditorComponent.prototype.getOverLapDescision = function (req) {
        var _this = this;
        req.isMobile = this.screenWidth <= this.appConfig.devices.mobileWidth.max ? true : false;
        var promise = new Promise(function (resolve, reject) {
            OverlappingSchedulePopupComponent.openDialog(req, 'Please select an option', _this.modalService, function (result) {
                _this.decision = result;
                resolve(_this.decision);
            });
        });
        return promise;
    };
    IndividualScheduleEntryEditorComponent.prototype.onShiftDefAdd = function (cellClicked) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var res_1, entry, req;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(cellClicked === this.dailyData.startOfDay.format(appConfig.dateFormat))) return [3 /*break*/, 2];
                        if (this.readonly || !this.isCanAddShift)
                            return [2 /*return*/];
                        if (!this.dailyData.data) {
                            this.dailyData.addData(new IndSchEntryWrapper());
                            this.dailyData.data.date = this.dailyData.startOfDay.toDate();
                            this.dailyData.data.entryDef = new ScheduleEntryDefinition();
                            this.dailyData.data.entryDef.dateOn = this.dailyData.data.date;
                            this.dailyData.data.entryDef.shifts = [];
                        }
                        res_1 = new IndividualQuickEditOvelapDecisionState();
                        this.dailyData.data.isDirty = true;
                        entry = new ScheduledShiftDefinition();
                        this.fillScheduledShiftDefinition(entry);
                        res_1.overlap = this.getOvelapState(this.dailyData.data.entryDef.shifts, entry);
                        if (_.size(res_1.overlap.messages) === 0 && _.size(this.dailyData.data.entryDef.shifts) === 0) {
                            this.makeReplace();
                            return [2 /*return*/, Promise.resolve(res_1)];
                        }
                        else if (_.size(res_1.overlap.messages) === 0 && res_1.overlap.currentDayState.status === IndividualQuickEditOvelapStatus.NoOverlap) {
                            this.makeAppend(res_1.overlap);
                            return [2 /*return*/, Promise.resolve(res_1)];
                        }
                        req = new IndividualQuickEditOvelapDecisionRequest();
                        req.overlap = res_1.overlap;
                        req.canCancel = true;
                        return [4 /*yield*/, this.getOverLapDescision(req).then(function (decision) {
                                switch (decision) {
                                    case IndividualQuickEditOvelapDecision.Replace:
                                        _this.makeReplace();
                                        break;
                                    case IndividualQuickEditOvelapDecision.Append:
                                        _this.makeAppend(res_1.overlap);
                                        break;
                                    case IndividualQuickEditOvelapDecision.Override:
                                        _this.makeOverride(res_1.overlap);
                                        break;
                                    case IndividualQuickEditOvelapDecision.Cancel:
                                        break;
                                    default:
                                        throw new Error('unknown overlap decision');
                                }
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    IndividualScheduleEntryEditorComponent.prototype.makeReplace = function () {
        this.dailyData.data.isDirty = true;
        var entry = new ScheduledShiftDefinition();
        this.fillScheduledShiftDefinition(entry);
        entry.shift.isDirty = true;
        _.pullAll(this.dailyData.data.entryDef.shifts, this.dailyData.data.entryDef.shifts);
        this.dailyData.data.entryDef.shifts.push(entry);
        this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, function (s) { return s.start; });
        this.onEntryChanged.emit(this.dailyData);
    };
    IndividualScheduleEntryEditorComponent.prototype.onShiftDefRemove = function (entry, index, e) {
        this.dailyData.data.isDirty = true;
        _.pullAt(this.dailyData.data.entryDef.shifts, index);
        this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, function (s) { return s.start; });
        this.onEntryChanged.emit(this.dailyData);
        e.stopPropagation();
    };
    IndividualScheduleEntryEditorComponent.prototype.fillScheduledShiftDefinition = function (entry) {
        entry.position = this.employeeShift.position;
        entry.shift = this.employeeShift.shift;
        entry.unit = this.employeeShift.unit;
        if (!this.employeeShift.constraint || this.employeeShift.constraint.id === 0) {
            entry.constraint = null;
        }
        else {
            entry.constraint = this.employeeShift.constraint;
        }
        if (!this.employeeShift.absence || this.employeeShift.absence.code === '0') {
            entry.absence = null;
        }
        else {
            entry.absence = this.employeeShift.absence;
        }
        if (entry.shift) {
            var range = this.dateTimeService.getDateRangeFromTimeRange(this.dailyData.startOfDay, entry.shift.start, entry.shift.end);
            entry.start = range.start.toDate();
            entry.end = range.end.toDate();
            this.nightShiftService.updateTimeByNightShiftSetting(entry);
        }
    };
    IndividualScheduleEntryEditorComponent.prototype.getOvelapState = function (shiftsInfo, shiftInfo) {
        var overlap = new IndividualQuickEditOvelap();
        overlap.canAppend = true;
        overlap.canOverride = true;
        overlap.canReplace = true;
        overlap.messages = [];
        var nextentryDef = null;
        var previousEntryDef = null;
        if (!_.isNil(this.previousDayData) && !_.isNil(this.previousDayData.data) && !_.isNil(this.previousDayData.data.entryDef))
            previousEntryDef = this.previousDayData.data.entryDef;
        if (!_.isNil(this.nextDayData) && !_.isNil(this.nextDayData.data) && !_.isNil(this.nextDayData.data.entryDef))
            nextentryDef = this.nextDayData.data.entryDef;
        if (!_.isNil(this.previousDayData) && !_.isNil(previousEntryDef))
            overlap.prevDayState = this.checkCellOvelap(previousEntryDef.shifts, shiftInfo);
        if (!_.isNil(this.nextDayData) && !_.isNil(nextentryDef))
            overlap.nextDayState = this.checkCellOvelap(this.nextDayData.data.entryDef.shifts, shiftInfo);
        overlap.currentDayState = this.checkCellOvelap(shiftsInfo, shiftInfo);
        if (!_.isNil(overlap.prevDayState) && overlap.prevDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
            overlap.canOverride = false;
            overlap.canReplace = false;
            overlap.messages.push('Has overlapping with previous day schedule');
            if (this.hasOvelap(overlap.prevDayState.rightOverlapShifts, overlap.currentDayState.leftOverlapShifts)) {
                overlap.canAppend = false;
                overlap.messages.push('No free space between previous day schedule and current day schedule');
            }
            if (overlap.prevDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleInside) {
                overlap.canAppend = false;
                overlap.messages.push('New shift inside previous day shift schedule');
            }
            if (overlap.prevDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleCover) {
                overlap.canAppend = false;
                overlap.messages.push('New shift cover previous day shift schedule');
            }
        }
        if (!_.isNil(overlap.nextDayState) && overlap.nextDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
            overlap.canOverride = false;
            overlap.canReplace = false;
            overlap.messages.push('Has overlapping with next day schedule');
            if (this.hasOvelap(overlap.nextDayState.leftOverlapShifts, overlap.currentDayState.rightOverlapShifts)) {
                overlap.canAppend = false;
                overlap.messages.push('No free space between next day schedule and current day schedule');
            }
            if (overlap.nextDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleInside) {
                overlap.canAppend = false;
                overlap.messages.push('New shift inside next day shift schedule');
            }
            if (overlap.nextDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleCover) {
                overlap.canAppend = false;
                overlap.messages.push('New shift cover next day shift schedule');
            }
        }
        if (overlap.currentDayState.leftOverlapShifts.length > 0 && overlap.currentDayState.rightOverlapShifts.length > 0) {
            var intervalduration = moment(overlap.currentDayState.leftOverlapShifts[0].end).diff(overlap.currentDayState.leftOverlapShifts[0].start)
                + moment(overlap.currentDayState.rightOverlapShifts[0].end).diff(overlap.currentDayState.rightOverlapShifts[0].start);
            var shiftinterval = moment(shiftInfo.end).diff(shiftInfo.start);
            if (intervalduration >= shiftinterval)
                overlap.canAppend = false;
        }
        if (!_.isNil(overlap.prevDayState) && overlap.prevDayState.leftOverlapShifts.length > 0 && overlap.currentDayState.rightOverlapShifts.length > 0) {
            overlap.canAppend = overlap.canOverride = overlap.canReplace = true;
        }
        if (!_.isNil(overlap.nextDayState) && overlap.nextDayState.rightOverlapShifts.length > 0 && overlap.currentDayState.leftOverlapShifts.length > 0) {
            overlap.canAppend = overlap.canOverride = overlap.canReplace = true;
        }
        if (overlap.currentDayState.status === IndividualQuickEditOvelapStatus.NoOverlap) {
            overlap.canOverride = false;
        }
        if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleInside) {
            overlap.canAppend = false;
            overlap.canOverride = false;
            overlap.messages.push('New shift inside current day shift schedule');
        }
        if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapMiddleCover) {
            overlap.canAppend = false;
            overlap.canOverride = false;
            overlap.messages.push('New shift cover current day shift schedule');
        }
        return overlap;
    };
    IndividualScheduleEntryEditorComponent.prototype.hasOvelap = function (list1, list2) {
        var hasOverlap = false;
        _.forEach(list1, function (s1) {
            _.forEach(list2, function (s2) {
                if (moment(s1.start).isSameOrBefore(s2.end) && moment(s1.end).isSameOrAfter(s2.start)) {
                    hasOverlap = true;
                }
                return !hasOverlap;
            });
            return !hasOverlap;
        });
        return hasOverlap;
    };
    IndividualScheduleEntryEditorComponent.prototype.makeOverride = function (overlap) {
        var _this = this;
        this.dailyData.data.isDirty = true;
        var shiftInfo = new ScheduledShiftDefinition();
        this.fillScheduledShiftDefinition(shiftInfo);
        if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapLeft) {
            _.forEach(overlap.currentDayState.leftOverlapShifts, function (s) {
                var index = _this.dailyData.data.entryDef.shifts.findIndex(function (p) { return p.end === s.end && p.start === s.start && p.shift === s.shift; });
                var shift = _this.dailyData.data.entryDef.shifts[index];
                _this.dailyData.data.entryDef.shifts[index].end = s.end = moment(shiftInfo.start).subtract(1, 's').toDate();
                _this.dailyData.data.entryDef.shifts[index].start = s.start;
                _this.dailyData.data.entryDef.shifts[index].duration = moment(shift.end).diff(shift.start);
            });
        }
        if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapRight) {
            _.forEach(overlap.currentDayState.rightOverlapShifts, function (s) {
                var index = _this.dailyData.data.entryDef.shifts.findIndex(function (p) { return p.end === s.end && p.start === s.start && p.shift === s.shift; });
                var shift = _this.dailyData.data.entryDef.shifts[index];
                _this.dailyData.data.entryDef.shifts[index].end = s.end;
                _this.dailyData.data.entryDef.shifts[index].start = s.start = moment(shiftInfo.end).subtract(1, 's').toDate();
                _this.dailyData.data.entryDef.shifts[index].duration = moment(shift.end).diff(shift.start);
            });
        }
        shiftInfo.shift.isDirty = true;
        this.dailyData.data.entryDef.shifts.push(shiftInfo);
        this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, function (s) { return s.start; });
        this.onEntryChanged.emit(this.dailyData);
    };
    IndividualScheduleEntryEditorComponent.prototype.makeAppend = function (overlap) {
        this.dailyData.data.isDirty = true;
        var shiftInfo = new ScheduledShiftDefinition();
        this.fillScheduledShiftDefinition(shiftInfo);
        if (overlap.prevDayState != null && overlap.prevDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
            var lastPrevSchedule = _.maxBy(overlap.prevDayState.leftOverlapShifts, function (s) { return moment(s.end).unix(); });
            shiftInfo.start = moment(lastPrevSchedule.end).add(1, 's').toDate();
        }
        if (overlap.nextDayState != null && overlap.nextDayState.status !== IndividualQuickEditOvelapStatus.NoOverlap) {
            var firstNextSchedule = _.minBy(overlap.nextDayState.rightOverlapShifts, function (s) { return moment(s.start).unix(); });
            shiftInfo.end = moment(firstNextSchedule.start).subtract(1, 's').toDate();
        }
        if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapLeft) {
            var lastLeftSchedule = _.maxBy(overlap.currentDayState.leftOverlapShifts, function (s) { return moment(s.end).unix(); });
            shiftInfo.start = moment(lastLeftSchedule.end).add(1, 's').toDate();
        }
        if (overlap.currentDayState.status & IndividualQuickEditOvelapStatus.OverlapRight) {
            var firstRightSchedule = _.minBy(overlap.currentDayState.rightOverlapShifts, function (s) { return moment(s.start).unix(); });
            shiftInfo.end = moment(firstRightSchedule.start).subtract(1, 's').toDate();
        }
        shiftInfo.duration = shiftInfo.shift ? moment(shiftInfo.end).diff(shiftInfo.start) - dateTimeUtils.convertFromDtoDurationStringToNumber(shiftInfo.shift.lunchDuration, 'ms') : moment(shiftInfo.end).diff(shiftInfo.start);
        //#152878 waiting for solution from product
        if (shiftInfo.duration <= 0) {
            console.log('You tried to add a shift with duration less than zero');
        }
        else {
            shiftInfo.shift.isDirty = true;
            this.dailyData.data.entryDef.shifts.push(shiftInfo);
            this.dailyData.data.entryDef.shifts = _.sortBy(this.dailyData.data.entryDef.shifts, function (s) { return s.start; });
            this.onEntryChanged.emit(this.dailyData);
        }
    };
    IndividualScheduleEntryEditorComponent.prototype.checkCellOvelap = function (shiftsInfo, shiftInfo) {
        var state = new IndividualQuickEditOvelapState();
        state.status = IndividualQuickEditOvelapStatus.NoOverlap;
        if (!shiftsInfo) {
            return state;
        }
        _.forEach(shiftsInfo, function (s) {
            if (moment(s.start).isSameOrBefore(shiftInfo.start) && moment(s.end).isBefore(shiftInfo.end) && moment(s.end).isAfter(shiftInfo.start)) {
                state.status = state.status | IndividualQuickEditOvelapStatus.OverlapLeft;
                state.leftOverlapShifts.push(s);
            }
            if (moment(s.start).isAfter(shiftInfo.start) && moment(s.end).isSameOrAfter(shiftInfo.end) && moment(s.start).isBefore(shiftInfo.end)) {
                state.status = state.status | IndividualQuickEditOvelapStatus.OverlapRight;
                state.rightOverlapShifts.push(s);
            }
            if (moment(s.start).isSameOrBefore(shiftInfo.start) && moment(s.end).isSameOrAfter(shiftInfo.end)) {
                state.status = state.status | IndividualQuickEditOvelapStatus.OverlapMiddleInside;
            }
            if (moment(s.start).isSameOrAfter(shiftInfo.start) && moment(s.end).isSameOrBefore(shiftInfo.end)) {
                state.status = state.status | IndividualQuickEditOvelapStatus.OverlapMiddleCover;
            }
        });
        return state;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], IndividualScheduleEntryEditorComponent.prototype, "subscriptions", void 0);
    return IndividualScheduleEntryEditorComponent;
}());
export { IndividualScheduleEntryEditorComponent };
