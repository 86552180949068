import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { KendoGridCustomSelectionHelper } from '../../../../common/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Department, Organization } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { LookupApiService } from '../../../../organization/services/index';
import { FormGroup } from '@angular/forms';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';

import { PositionModel, ConfOrganization } from '../../../models/index';
import { EditableListComponent } from '../../../components/index';
import { PositionsConfigurationApiService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-copy-positions',
  templateUrl: 'configure-copy-positions.component.html',
  styleUrls: ['configure-copy-positions.component.scss']
})
export class ConfigureCopyPositionsComponent implements OnInit, OnDestroy {

  @Input()
  public set positions(value: PositionModel[]) {
    this.m_positions = value;
    this.refreshGrid();
  }

  @Output('onDiscard')
  public discardEmitter: EventEmitter<any> = new EventEmitter<any>();

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public state: {
    isLoading: boolean;
    allowEdit: boolean;
    allowCopy: boolean;
    departmentLevel: boolean;
  };

  public copyToCenter: ConfOrganization;
  public copyToDepartment: Department;
  public copyFromDepartment: string;
  public copyFromCenter: string;

  public gridState: KendoGridStateHelper<PositionModel>;
  public selectionHelper: KendoGridCustomSelectionHelper<PositionModel>;

  public get organizations(): ConfOrganization[] {
    return this.m_organizations;
  }

  public get copyToDepartaments(): Department[] {
    return this.copyToCenter ? this.copyToCenter.departments : [];
  }

  private m_organizations: ConfOrganization[];
  private m_positions: PositionModel[];
  private m_targetPositions: PositionModel[];

  @ViewChild('copyActionsForm', {static: true}) private form: FormGroup;

  private currentOrgLevel: OrgLevel;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private formSubscription: Subscription;
  @unsubscribe()
  private gridStateSubscription: Subscription;
  @unsubscribe()
  private selectionHelperSubscription: Subscription;

  constructor(private api: PositionsConfigurationApiService, private lookup: LookupApiService) {
    this.gridState = new KendoGridStateHelper<PositionModel>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
  }

  public ngOnInit(): void {

    this.state = {
      isLoading: false,
      allowEdit: true,
      allowCopy: false,
      departmentLevel: false
    };

    this.gridStateSubscription = this.gridState.onRefreshGrid.subscribe((state: State) => {
      this.refreshGrid();
    });

    this.formSubscription = this.form.valueChanges.subscribe(() => {
      this.checkActionsConditions();
    });

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {

      if (orgLevel && orgLevel.id === null) return;

      this.currentOrgLevel = orgLevel;
      this.copyFromDepartment = this.currentOrgLevel.name;

      this.lookup.getOrganizations(undefined, this.currentOrgLevel.parentId).then((orgs: Organization[]) => {
        if (orgs && orgs.length > 0) {
          this.copyFromCenter = orgs[0].name;
        } else {
          this.copyFromCenter = this.currentOrgLevel.parentId.toString();
        }
      });

      this.checkActionsConditions();

      this.api.getCopyPositionsConfiguration(this.currentOrgLevel.id).then((org: ConfOrganization[]) => {
        this.m_organizations = org;
        this.copyToCenter = _.first(org);
        if (this.copyToCenter) {
          this.copyToDepartment = _.first(this.copyToCenter.departments);
          if (this.copyToDepartment) this.onTargetDepartmentChanged();
        }
      });
    });

    this.selectionHelperSubscription = this.selectionHelper.onSelectionChanged.subscribe(() => {

      if (this.selectionHelper.selectionLength > 0) {
        this.state.allowCopy = true;
      } else {
        this.state.allowCopy = false;
      }
      this.checkActionsConditions();
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onDoCopy(): void {

    this.state.isLoading = true;
    let selectedForCopyItems: PositionModel[] = this.selectionHelper.selection;
    let ids: number[] = [];
    _.each(selectedForCopyItems, (position: PositionModel) => {
      ids.push(position.id);
    });

    this.api.copyPositions(this.currentOrgLevel.relatedItemId, this.copyToDepartment.id, ids)
      .then((respone?: any) => {
        this.state.isLoading = false;
        this.selectionHelper.clearSelection();
        this.onTargetDepartmentChanged();
      })
      .catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  public onDiscardCopy(): void {
    this.discardEmitter.emit();
  }

  public onTargetCenterChanged():void{
    this.copyToDepartment = _.first(this.copyToCenter.departments);
    this.onTargetDepartmentChanged();
  }

  public onTargetDepartmentChanged(): void {
    if (!this.copyToDepartment || !this.copyToDepartment.orgLevelId) {
      return;
    }
    this.api.getPositions(this.copyToDepartment.orgLevelId).
      then((result: any) => {
        this.m_targetPositions = result.positions;
        _.each(this.m_positions, (item: PositionModel) => {
          item.selectable = !_.some(this.m_targetPositions, (target: PositionModel) => target.name === item.name);
        });
      });
  }

  protected refreshGrid(): void {

    if (!this.m_positions) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.m_positions, this.gridState.state);
    this.selectionHelper.view = this.gridState.view;
  }

  private checkActionsConditions(): void {

    let allowCopy: boolean = true;

    if (this.currentOrgLevel.type === OrgLevelType.department) {
      this.state.departmentLevel = true;
    } else {
      this.state.departmentLevel = false;
      allowCopy = false;
    }

    if (!this.form.valid) {
      allowCopy = false;
    }

    if (this.selectionHelper.selectionLength === 0) {
      allowCopy = false;
    }

    this.state.allowCopy = allowCopy;
  }

}
