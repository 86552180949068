import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ReportFormBase } from '../report-form-base';
import { FormGroup } from '@angular/forms';
import { wcConfig } from '../../../workers-compensation.config';
var WcOshaReportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WcOshaReportComponent, _super);
    function WcOshaReportComponent(management) {
        var _this = _super.call(this) || this;
        _this.management = management;
        _this.subscriptions = {};
        _this.commentsCount = 0;
        _this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
        return _this;
    }
    Object.defineProperty(WcOshaReportComponent.prototype, "osha", {
        get: function () {
            return this.report ? this.report.oshaReport : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcOshaReportComponent.prototype, "minimumDate", {
        get: function () {
            return this.m_minimumDate;
        },
        enumerable: true,
        configurable: true
    });
    WcOshaReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.load = this.management.onLoaded$
            .combineLatest(this.management.wcLookupsLoaded$)
            .subscribe(function (_a) {
            var report = _a[0], lookups = _a[1];
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_b) {
                    this.lookups = lookups;
                    this.report = report;
                    this.updateCommentsCount();
                    return [2 /*return*/];
                });
            });
        });
        this.subscriptions.value = this.oshaReportForm.statusChanges.subscribe(function (x) {
            if (_this.oshaReportForm.dirty && _this.oshaReportForm.touched) {
                _this.management.onChangeNotify();
            }
            _this.management.setOshaFormValidity(_this.oshaReportForm.valid);
            _this.updateCommentsCount();
        });
    };
    WcOshaReportComponent.prototype.ngOnDestroy = function () { };
    WcOshaReportComponent.prototype.onClickDisable = function (acceptPopover) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acceptPopover.hide();
                        this.management.changeLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.management.disableOshaReport()];
                    case 2:
                        _a.sent();
                        this.management.changeLoading(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WcOshaReportComponent.prototype.onClickCancel = function (acceptPopover) {
        acceptPopover.hide();
    };
    WcOshaReportComponent.prototype.onisRestrictedDutyChanged = function (enable) {
        if (this.osha) {
            this.osha.restrictedDutyDaysNum = 0;
        }
    };
    WcOshaReportComponent.prototype.onwasInHospitalChanged = function (enable) {
        if (this.osha) {
            this.osha.admittedHospitalDaysNum = 0;
        }
    };
    WcOshaReportComponent.prototype.onisLostDaysChanged = function (enable) {
        if (this.osha) {
            if (enable) {
                this.osha.lostDaysStartDate = moment().toDate();
                this.osha.lostDaysEndDate = null;
                this.updateDays();
            }
            else {
                this.osha.lostDaysStartDate = null;
                this.osha.lostDaysEndDate = null;
                this.osha.lostDaysCount = 0;
            }
        }
    };
    WcOshaReportComponent.prototype.updateDays = function () {
        this.updateDiffValue();
        this.osha.lostDaysCount = this.maxDiff;
    };
    WcOshaReportComponent.prototype.updateDiff = function (days) {
        if (this.osha) {
            if (this.osha.isLostDays) {
                if (!this.osha.lostDaysStartDate) {
                    this.osha.lostDaysStartDate = moment().toDate();
                }
                this.osha.lostDaysCount = days;
                this.updateDiffValue();
            }
        }
    };
    WcOshaReportComponent.prototype.updateDiffValue = function () {
        if (this.osha.lostDaysStartDate && this.osha.lostDaysEndDate) {
            var diff = Math.abs(moment(this.osha.lostDaysEndDate, 'YYYY-MM-DD').startOf('day').diff(moment(this.osha.lostDaysStartDate, 'YYYY-MM-DD').startOf('day'), 'days')) + 1;
            this.maxDiff = diff;
        }
        else {
            this.maxDiff = 0;
        }
    };
    WcOshaReportComponent.prototype.updateCommentsCount = function () {
        var commentsCount = !_.isNil(this.osha.otherComments) && _.size(this.osha.otherComments) > 0 ? 1 : 0;
        if (commentsCount !== this.commentsCount) {
            this.commentsCount = commentsCount;
        }
    };
    WcOshaReportComponent.prototype.onKeydown = function (value) {
        if (!((value.keyCode > 95 && value.keyCode < 106)
            || (value.keyCode > 47 && value.keyCode < 58)
            || value.keyCode == 8)) {
            return false;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcOshaReportComponent.prototype, "subscriptions", void 0);
    return WcOshaReportComponent;
}(ReportFormBase));
export { WcOshaReportComponent };
