import { FieldData, FieldRenderType } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsFutureUpdates {
  updates: IEmployeeSectionsFutureUpdate[];
}
export interface IEmployeeSectionsFutureUpdate {
  id: number;
  description: string;
  requestedOn: Date;
  scheduledFor: Date;
  processedOn: Date;
  requestedBy: string;
  updateSystem: string;
  updatedFields: IEmployeeSectionsFutureUpdateField[];
}

export interface IEmployeeSectionsFutureUpdateField {
  fieldName: string;
  displayName: string;
  value: any;
  fieldType: string;
  access: string;
}

export class EmployeeSectionsFutureUpdates extends EmployeeSectionsBase {
  public static dataMock: IEmployeeSectionsFutureUpdates = {
    updates: [
      {
        id: 124,
        description: 'Employee field change',
        requestedOn: new Date('2016-08-01T07:41:00'),
        scheduledFor: new Date('2016-09-01'),
        processedOn: new Date('2016-09-01'),
        requestedBy: 'S.User',
        updateSystem: 'HR',
        updatedFields: [
          {
            fieldName: 'payPolicy',
            displayName: 'Pay Policy',
            value: 'FT_DC',
            fieldType: 'string',
            access: 'full'
          },
          {
            fieldName: 'payPolicy1',
            displayName: 'Pay Policy2',
            value: new Date(),
            fieldType: 'datetime',
            access: 'full'
          },
          {
            fieldName: 'payPolicy2',
            displayName: 'Pay Policy2',
            value: 'FT_DC',
            fieldType: 'number',
            access: 'full'
          }
        ]
      },
      {
        id: 125,
        description: 'Employee field change3',
        requestedOn: new Date('2016-08-01T07:41:00'),
        scheduledFor: new Date('2016-09-01'),
        processedOn: new Date('2016-09-01'),
        requestedBy: 'S.User',
        updateSystem: 'HR',
        updatedFields: [
          {
            fieldName: 'payPolicy',
            displayName: 'Pay Policy',
            value: 'FT_DC',
            fieldType: 'string',
            access: 'full'
          },
          {
            fieldName: 'payPolicy1',
            displayName: 'Pay Policy2',
            value: new Date(),
            fieldType: 'datetime',
            access: 'full'
          },
          {
            fieldName: 'payPolicy2',
            displayName: 'Pay Policy2',
            value: 'FT_DC',
            fieldType: 'number',
            access: 'full'
          }
        ]
      },
    ]
  };
  public updates: EmployeeSectionsFutureUpdate[];

  constructor() {
    super();
    this.updates = [];
  }
}
export class EmployeeSectionsFutureUpdate {
  public id: number;
  public description: string;
  public requestedOn: Date;
  public scheduledFor: Date;
  public processedOn: Date;
  public requestedBy: string;
  public updateSystem: string;
  public updatedFields: EmployeeSectionsFutureUpdateField<any>[];

  constructor() {
    this.updatedFields = [];
  }
}
export class EmployeeSectionsFutureUpdateField<T> {
  public fieldName: string;
  public displayName: string;
  public fieldData: FieldData<T>;
  public fieldType: string;
  public fieldRender: FieldRenderType;
}

