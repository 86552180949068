export * from './selectors/index';
export * from './individual-schedule/index';
export * from './master-schedule/index';


import { IndividualScheduleActions } from './individual-schedule/index';

import { EmployeesSelector } from './selectors/index';

export const STORE_SELECTORS: any[] = [
  EmployeesSelector
];


export const INDIVIDUAL_SCHEDULE_ACTION_PROVIDERS: any[] = [
  IndividualScheduleActions
 ];




