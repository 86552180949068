import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeEmergencyContact = /** @class */ (function () {
    function EmployeeEmergencyContact() {
    }
    return EmployeeEmergencyContact;
}());
export { EmployeeEmergencyContact };
var EmployeeSectionsEmergencyContacts = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsEmergencyContacts, _super);
    function EmployeeSectionsEmergencyContacts() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsEmergencyContacts;
}(EmployeeSectionsBase));
export { EmployeeSectionsEmergencyContacts };
