export enum IdealScheduleConfigCensusDefinition {
  Fixed,
  Range,
  Total
}

export interface IdealScheduleConfigCensus {
  id: number;
  name: string;
}

export const IdealScheduleConfigCensusOptions: IdealScheduleConfigCensus[] = [
  { id: IdealScheduleConfigCensusDefinition.Fixed, name: 'Fixed' }
  , { id: IdealScheduleConfigCensusDefinition.Range, name: 'Range' }
  , { id: IdealScheduleConfigCensusDefinition.Total, name: 'Total' }
];
