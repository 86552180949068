/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@progress/kendo-angular-buttons/dist/es2015/index.ngfactory";
import * as i3 from "@progress/kendo-angular-buttons";
import * as i4 from "@progress/kendo-angular-l10n";
import * as i5 from "@progress/kendo-angular-popup";
import * as i6 from "@angular/common";
import * as i7 from "./dropdown-button.component";
var styles_DropdownButtonComponent = [i0.styles];
var RenderType_DropdownButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DropdownButtonComponent, data: {} });
export { RenderType_DropdownButtonComponent as RenderType_DropdownButtonComponent };
function View_DropdownButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "slx-dropdownbtn__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.icon; _ck(_v, 1, 0, currVal_0); }); }
function View_DropdownButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "dropdown-btn-item-width"]], [[4, "min-width", "px"]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropdownItemMinWidth; _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.valuePrimitive ? _v.context.$implicit : _v.context.$implicit[_co.textField]); _ck(_v, 1, 0, currVal_1); }); }
export function View_DropdownButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dropdownBtn: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["dropdownBtn", 1]], null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "kendo-dropdownbutton", [], [[2, "k-state-focused", null], [2, "k-widget", null], [2, "k-dropdown-button", null], [1, "dir", 0]], [[null, "itemClick"], [null, "keydown"], [null, "keypress"], [null, "keyup"], [null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).keydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).keypress($event) !== false);
        ad = (pd_1 && ad);
    } if (("keyup" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8).keyup($event) !== false);
        ad = (pd_2 && ad);
    } if (("mousedown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 8).mousedown($event) !== false);
        ad = (pd_3 && ad);
    } if (("itemClick" === en)) {
        var pd_4 = (_co.handleClick($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, i2.View_DropDownButtonComponent_0, i2.RenderType_DropDownButtonComponent)), i1.ɵprd(512, null, i3.FocusService, i3.FocusService, []), i1.ɵprd(256, null, i3.NAVIGATION_CONFIG, { useLeftRightArrows: true }, []), i1.ɵprd(512, null, i3.NavigationService, i3.NavigationService, [i3.NAVIGATION_CONFIG]), i1.ɵprd(256, null, i4.L10N_PREFIX, "kendo.dropdownbutton", []), i1.ɵprd(131584, null, i4.LocalizationService, i4.LocalizationService, [i4.L10N_PREFIX, [2, i4.MessageService], [2, i4.RTL]]), i1.ɵdid(8, 180224, null, 1, i3.DropDownButtonComponent, [i3.FocusService, i3.NavigationService, i1.ElementRef, i1.NgZone, i5.PopupService, i1.ElementRef, i4.LocalizationService], { popupSettings: [0, "popupSettings"], textField: [1, "textField"], disabled: [2, "disabled"], data: [3, "data"] }, { itemClick: "itemClick" }), i1.ɵqud(335544320, 2, { itemTemplate: 0 }), i1.ɵpod(10, { popupClass: 0, animate: 1 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DropdownButtonComponent_1)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(13, 0, [" ", " "])), (_l()(), i1.ɵand(0, null, 0, 1, null, View_DropdownButtonComponent_2)), i1.ɵdid(15, 16384, [[2, 4]], 0, i3.ButtonItemTemplateDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _ck(_v, 10, 0, "dropdown-btn-popup", true); var currVal_5 = _co.textField; var currVal_6 = _co.disabled; var currVal_7 = _co.data; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.icon; _ck(_v, 12, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 8).focused; var currVal_1 = i1.ɵnov(_v, 8).widgetClasses; var currVal_2 = i1.ɵnov(_v, 8).widgetClasses; var currVal_3 = i1.ɵnov(_v, 8).dir; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_9 = _co.title; _ck(_v, 13, 0, currVal_9); }); }
export function View_DropdownButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-dropdown-button", [], null, null, null, View_DropdownButtonComponent_0, RenderType_DropdownButtonComponent)), i1.ɵdid(1, 4767744, null, 0, i7.DropdownButtonComponent, [], null, null)], null, null); }
var DropdownButtonComponentNgFactory = i1.ɵccf("slx-dropdown-button", i7.DropdownButtonComponent, View_DropdownButtonComponent_Host_0, { data: "data", disabled: "disabled", icon: "icon", textField: "textField", title: "title", valuePrimitive: "valuePrimitive" }, { onItemClick: "onItemClick" }, []);
export { DropdownButtonComponentNgFactory as DropdownButtonComponentNgFactory };
