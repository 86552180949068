<div modalAnchor class="slx-local-anchor"></div>
<slx-spinner [show]="isLoading">
    <div class="slx-high-box benefit-employees-eligible ">
        <div class="slx-high-box__body">
            <form #form="ngForm" novalidate class="slx-full-height">
                <kendo-grid
                  class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows slx-full-height"
                  [data]="gridState?.view"
                  [filterable]="'menu'"
                  [sortable]="true"
                  [pageable]="true"
                  [pageSize]="pageSize"
                  [filter]="gridState.state.filter"
                  [sort]="gridState.state.sort"
                  [skip]="gridState.state.skip"
                  [selectable]="selectableSettings"
                  (dataStateChange)="gridState.dataStateChange($event)"
                >

                    <kendo-grid-column title="Employee" width="150" field="employeeName">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Employee</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <a [employeeSectionNewWindowLink]="dataItem.employeeId">
                                {{dataItem.employeeName}}
                            </a>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Position" width="110" field="position.name">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Position</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{dataItem.position.name}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Department" width="120" field="department.name" *slxHiddenOn="['tablet', 'mobile']">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Department</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{dataItem.department.name}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Type" width="110" field="type.name">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Type</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            {{dataItem.type.name}}
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="coverage" width="130" field="coverage" class="numeric-cell">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Coverage</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                          <ng-container *ngIf="tierDetails?.coverageFormula?.isFixedCalcType">
                            {{ coverage | number: ".2-2"| slxMoney }}
                          </ng-container>
                            <ng-container *ngIf="tierDetails?.coverageFormula?.isMultiplierCalcType">
                              <slx-autocomplete class="slx-wide"
                                #coverage="ngModel"
                                slxAutocompleteInvalidValue
                                [options]="coverageMultipllierOptions"
                                [(ngModel)]="dataItem.editedItemCoverage"
                                (ngModelChange)="onChangeCoverageWithMultiplier($event, dataItem, coverage.invalid)"
                                [strictSearch]="false"
                                [setNullAfterInit]="true"
                                rightIcon="fas fa-search"
                                name="coverageM_{{rowIndex}}"
                                [required]="true"
                              >
                                <ng-template slxAutocompleteItem let-item="item">
                                  {{item.name}}
                                </ng-template>
                              </slx-autocomplete>
                              <div *ngIf="coverage.errors" class="slx-error-block">
                                <span *ngIf="!coverage.errors.autocompleteInvalidValue && coverage.errors.required" errorMessage for="required" class="pull-left"></span>
                                <span *ngIf="coverage.errors.autocompleteInvalidValue" errorMessage class="slx-error-block pull-left">Invalid value</span>
                              </div>
                            </ng-container>
                            <ng-container *ngIf="tierDetails?.coverageFormula?.isAnyValueMaxCapCalcType">
                              <slx-number class="slx-wide slx-input-number"
                                          #coverageAnyValue="ngModel"
                                          [min]="0"
                                          [max]="tierDetails?.coverageFormula?.maxCap"
                                          [decimals]="2"
                                          [step]="stepcurrency"
                                          [format]="'c2'"
                                          [autoCorrect]='false'
                                          [(ngModel)]="dataItem.coverage"
                                          rightIcon="fas fa-search"
                                          [required]="true"
                                          (ngModelChange)="onCoverageValueChange($event, dataItem, coverageAnyValue.invalid)"
                                          name="coverageC_{{rowIndex}}"></slx-number>
                              <span *ngIf="coverageAnyValue.errors?.required" errorMessage for="required" class="slx-error-block pull-left"></span>
                              <span *ngIf="coverageAnyValue.errors?.min" class="slx-error-block pull-left" errorMessage>Must be greater than 0</span>
                              <span *ngIf="coverageAnyValue.errors?.max" class="slx-error-block pull-left" errorMessage>Must be less than {{ tierDetails?.coverageFormula?.maxCap }}</span>
                            </ng-container>
                            <ng-container *ngIf="tierDetails?.coverageFormula?.isFormulaCalcType">
                              <slx-number class="slx-wide slx-input-number"
                                          #coverageFormula="ngModel"
                                          [min]="0"
                                          [slxMin]="0"
                                          [decimals]="2"
                                          [max]="tierDetails?.coverageFormula?.maxCap"
                                          [step]="stepcurrency"
                                          [format]="'c2'"
                                          [autoCorrect]='true'
                                          [(ngModel)]="dataItem.calculatedCoverage"
                                          (ngModelChange)="onCoverageValueChange($event, dataItem, coverageFormula.invalid)"
                                          [required]="true"
                                          name="coverage{{rowIndex}}">
                              </slx-number>
                              <span *ngIf="coverageFormula.errors?.required" errorMessage for="required" class="slx-error-block pull-left"></span>
                              <span *ngIf="coverageFormula.errors?.min" errorMessage class="slx-error-block pull-left">Value should be greater or equal to 0</span>
                              <span *ngIf="coverageFormula.errors?.max" errorMessage class="slx-error-block pull-left">Incorrect Value</span>
                            </ng-container>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Employer Contribution" width="180" field="employerContribution" class="numeric-cell">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Employer Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                            *ngIf="tierDetails.erFormula.isFixedCalcType">
                            {{ employerContribution | number: ".2-2"| slxMoney }}
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                            *ngIf="!tierDetails.erFormula.isFixedCalcType">
                            {{ dataItem.employerContributionAmount | number: ".2-2"| slxMoney }}
                            <i *ngIf="dataItem.employerContributionAmount==-1"
                                title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                                aria-hidden="true"
                                [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Employee Contribution" width="180" field="employeeContribution" class="numeric-cell">
                        <ng-template kendoGridHeaderTemplate>
                            <span slxKendoGridHeaderTooltip>Employee Contribution</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                            *ngIf="tierDetails.empFormula.isFixedCalcType">
                            {{ employeeContribution | number: ".2-2"| slxMoney }}
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                            *ngIf="!tierDetails.empFormula.isFixedCalcType">
                            {{ dataItem.employeeContributionAmount | number: ".2-2"| slxMoney }}
                            <i *ngIf="dataItem.employeeContributionAmount==-1"
                                title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                                aria-hidden="true"
                                [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
                        </ng-template>
                    </kendo-grid-column>

                </kendo-grid>
            </form>
        </div>
    </div>
</slx-spinner>
