import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BreadcrumbStateService } from '../../services/index';
import { BreadcrumbItem } from '../../models/index';
import { AppSettingsManageService } from '../../../app-settings/services';
var BreadcrumbComponent = /** @class */ (function () {
    function BreadcrumbComponent(router, activatedRoute, breadcrumbService, appSettingManageService) {
        this.hrmsAppName = 'hrms';
        this.nextgenPhasedRollout = false;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.breadcrumbService = breadcrumbService;
        this.appSettingManageService = appSettingManageService;
        this.urls = [];
    }
    BreadcrumbComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.buildBreadcrumbs(this.router.url);
        this.routerSubscription = this.router.events
            .filter(function (event) { return event instanceof NavigationEnd; })
            .subscribe(function (event) {
            if (event.url) {
                _this.buildBreadcrumbs(event.urlAfterRedirects ? event.urlAfterRedirects : event.url);
            }
        });
    };
    BreadcrumbComponent.prototype.ngOnDestroy = function () {
        this.routerSubscription.unsubscribe();
    };
    BreadcrumbComponent.prototype.onBreadcrumbClick = function (item) {
        var currentUrls, splitUrls, currentUrl, empId = "d001"; //To compare URL against any employee, replaced employee id in URL with this value for comparing
        if (this.router.url.startsWith("/apps/common/employee/") && this.router.url.includes("employee_sections")) {
            currentUrls = this.router.url.split('?');
            if (currentUrls[0]) {
                splitUrls = currentUrls[0].split('/');
                if (splitUrls.length > 5) {
                    splitUrls[4] = empId;
                }
            }
            currentUrl = splitUrls.join('/');
            if (item.linkPath === "/apps/common/employee_list" && currentUrl === "/apps/common/employee/" + empId + "/employee_sections") {
                localStorage.setItem('hitFromEmpSection', 'yes');
            }
        }
        this.breadcrumbService.clickOnBreadcrumb(item);
    };
    // TODO: as per discussion decided to fix it in this way, after release will be refactored
    BreadcrumbComponent.prototype.isHrmsApp = function (appName) {
        return this.hrmsAppName === _.lowerCase(appName);
    };
    BreadcrumbComponent.prototype.buildBreadcrumbs = function (eventUrl) {
        var _this = this;
        this.breadcrumbService.buildBreadcrumbs(eventUrl)
            .then(function (result) {
            if (result.length > 4) {
                _this.hasMoreThanNecessary = true;
                _this.urls = result.slice(result.length - 4);
            }
            else {
                _this.hasMoreThanNecessary = false;
                if (_this.nextgenPhasedRollout == true) {
                    var index = result.map(function (e) { return e.linkPath; }).indexOf('/apps/ngp');
                    if (index >= 0) {
                        result[index].isNotLink = true;
                        result[index].title = 'Next Gen Payroll';
                        result.splice(index + 1, 0, _this.buildSyncBreadcrumb());
                    }
                }
                _this.urls = result;
            }
            var icon = _this.breadcrumbService.icon;
            if (icon instanceof Promise) {
                icon.then(function (v) {
                    _this.icon = v;
                });
            }
            else {
                _this.icon = icon;
            }
        });
    };
    BreadcrumbComponent.prototype.buildSyncBreadcrumb = function () {
        var breadcumb = new BreadcrumbItem();
        breadcumb.title = 'Sync',
            breadcumb.isNotLink = true;
        return breadcumb;
    };
    BreadcrumbComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
                        return [2 /*return*/];
                }
            });
        });
    };
    return BreadcrumbComponent;
}());
export { BreadcrumbComponent };
