import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-individual-timecards-grid-tooltip-header',
    templateUrl: 'individual-timecards-grid-tooltip-header.component.html',
    styleUrls: ['individual-timecards-grid-tooltip-header.component.scss']
})

export class IndividualTimecardGridTooltipHeaderComponent {

    @Input()
    headerText: string;

    @Input()
    tipPosition: string = 'bottom';
}
