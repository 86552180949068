import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ICollapssiblePanel } from '../../../../common/components/collapsible-panel/collapsible-panel.component';

import { UserProfileManagementService } from '../../../services/index';
import { UserProfileSectionBaseComponent } from '../user-profile-sections/user-profile-section-base.component';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { UserProfileModel } from '../../../models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-user-profile-panel-header',
    templateUrl: 'user-profile-panel-header.component.html',
    styleUrls: ['user-profile-panel-header.component.scss']
})
export class UserProfilePanelHeaderComponent implements OnInit, OnDestroy {

    @Input()
    public forRolesSection: boolean;

    @Input()
    public addNewUserMode: boolean;

    @Input()
    public disableActions: boolean;

    @Input()
    public panel: ICollapssiblePanel;

    @Input()
    public section: UserProfileSectionBaseComponent;

    public otherSectionEditing: boolean;

    public state: {
        isEditMode: boolean;
        addSelectedRole: boolean;
    };

    @unsubscribe()
    private sectionSavedSubscription: Subscription;

    @unsubscribe()
    private sectionDiscardedSubscription: Subscription;

    @unsubscribe()
    private onSectionEnteredEditState: Subscription;

    @unsubscribe()
    private addRolesModeSubscription: Subscription;

    @unsubscribe()
    private profileLoadedSubscription: Subscription;

    constructor(private management: UserProfileManagementService) { }

    public ngOnInit(): void {

        this.state = {
            isEditMode: false,
            addSelectedRole: false
        };

        this.onSectionEnteredEditState = this.management.onSectionEnteredEditState$.subscribe((section: UserProfileSectionBaseComponent) => {
            if (this.section === section) {
                this.state.isEditMode = true;
            } else {
                this.otherSectionEditing = true;
            }
        });

        this.sectionDiscardedSubscription = this.management.onSectionDiscarded$.subscribe((section: UserProfileSectionBaseComponent) => {
            if (this.section === section) {
                this.state.isEditMode = false;
            } else {
                this.otherSectionEditing = false;
            }
        });

        this.sectionSavedSubscription = this.management.onSectionSaved$.subscribe((section: UserProfileSectionBaseComponent) => {
            if (this.section === section) {
                this.state.isEditMode = false;
            } else {
                this.otherSectionEditing = false;
            }
        });

        this.addRolesModeSubscription = this.management.onAddRoleModeSwitch$.subscribe((state: boolean) => {
            this.state.addSelectedRole = state;
        });
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
    }

    public onEditClick(): void {
        this.management.editSection(this.section);
    }

    public onSaveClick(): void {
        this.management.saveSection(this.section);
    }

    public onDiscardClick(): void {
        this.management.discardSection(this.section);
    }

    public onAddRoleClick(): void {
        this.management.onResetRoles();
        this.management.onAddRole();
    }

    public onDeleteRoleClick(): void {
        this.management.onDeleteRole();
    }

    public onSaveAddedClick(): void {
        this.management.addSelectedRole();
    }

    public onCancelAddClick(): void {
        this.management.cancelAddSelectedRole();
    }
}
