import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StateResetTypes } from '../../core/models/index';
import { IPayloadAction } from '../../state-model/models/index';
import { SidebarActions } from '../actions/index';
import { Observable } from 'rxjs/Observable';
import { TimecardsActions } from '../../time-and-attendance/store/index';
import { ComponentStateStorageService } from '../../common/services/index';

@Injectable()
export class SidebarEpics {
  private router: Router;

  constructor(router: Router, private storageService: ComponentStateStorageService) {
    this.router = router;
  }
  /*
  public menuItemChanged = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.filter(({ type }: { type: string }) => type === SidebarActions.RIGHT_MENU_ITEM_CHANGE)
      .mergeMap((action: IPayloadAction) => {
        this.storageService.clearComponentStates(StateResetTypes.MenuChange);

        return Observable.of(
          {
            type: SidebarActions.RIGHT_MENU_ITEM_CHANGED
          });
      });
  }*/
}
