export interface IEssOption {
  id: number;
  parentId: number;
  name: string;
  description: string;
  defaultValue: boolean;
}

export class EssOption {
  public id: number;
  public parentId: number;
  public name: string;
  public description: string;
  public defaultValue: boolean;
}
