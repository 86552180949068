/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kendo-number.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@progress/kendo-angular-inputs/dist/es2015/index.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "@progress/kendo-angular-inputs";
import * as i5 from "@progress/kendo-angular-common";
import * as i6 from "@progress/kendo-angular-l10n";
import * as i7 from "@progress/kendo-angular-intl";
import * as i8 from "./kendo-number.component";
var styles_KendoNumberComponent = [i0.styles];
var RenderType_KendoNumberComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KendoNumberComponent, data: {} });
export { RenderType_KendoNumberComponent as RenderType_KendoNumberComponent };
export function View_KendoNumberComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { kendoNumber: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "kendo-numerictextbox", [], [[2, "active", null], [1, "dir", 0], [2, "k-widget", null], [2, "k-numerictextbox", null]], [[null, "valueChange"], [null, "blur"], [null, "onFocus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.onValueChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onBlur(_co.currentValue) !== false);
        ad = (pd_1 && ad);
    } if (("onFocus" === en)) {
        var pd_2 = (_co.onFocus(_co.currentValue) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_NumericTextBoxComponent_0, i2.RenderType_NumericTextBoxComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.NumericTextBoxComponent]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.NumericTextBoxComponent]), i1.ɵprd(6144, null, i5.KendoInput, null, [i4.NumericTextBoxComponent]), i1.ɵprd(256, null, i6.L10N_PREFIX, "kendo.numerictextbox", []), i1.ɵprd(131584, null, i6.LocalizationService, i6.LocalizationService, [i6.L10N_PREFIX, [2, i6.MessageService], [2, i6.RTL]]), i1.ɵdid(7, 770048, [[1, 4], ["textbox", 4]], 0, i4.NumericTextBoxComponent, [i7.IntlService, i1.Renderer2, i6.LocalizationService, i1.Injector, i1.NgZone, i1.ChangeDetectorRef, i1.ElementRef], { disabled: [0, "disabled"], autoCorrect: [1, "autoCorrect"], format: [2, "format"], max: [3, "max"], min: [4, "min"], decimals: [5, "decimals"], placeholder: [6, "placeholder"], step: [7, "step"], spinners: [8, "spinners"], value: [9, "value"] }, { valueChange: "valueChange", onBlur: "blur" })], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.readonly; var currVal_5 = _co.autoCorrect; var currVal_6 = _co.format; var currVal_7 = _co.max; var currVal_8 = _co.min; var currVal_9 = _co.decimals; var currVal_10 = _co.placeholder; var currVal_11 = _co.step; var currVal_12 = _co.spinners; var currVal_13 = _co.currentValue; _ck(_v, 7, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isInputActive; var currVal_1 = i1.ɵnov(_v, 7).direction; var currVal_2 = i1.ɵnov(_v, 7).widgetClasses; var currVal_3 = i1.ɵnov(_v, 7).widgetClasses; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_KendoNumberComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-kendo-number", [], null, null, null, View_KendoNumberComponent_0, RenderType_KendoNumberComponent)), i1.ɵdid(1, 8962048, null, 0, i8.KendoNumberComponent, [i3.NgControl], null, null)], null, null); }
var KendoNumberComponentNgFactory = i1.ɵccf("slx-kendo-number", i8.KendoNumberComponent, View_KendoNumberComponent_Host_0, { step: "step", min: "min", max: "max", decimals: "decimals", format: "format", autoCorrect: "autoCorrect", required: "required", readonly: "readonly", placeholder: "placeholder", autofocus: "autofocus", isInputActive: "isInputActive" }, { blur: "blur", focus: "focus" }, []);
export { KendoNumberComponentNgFactory as KendoNumberComponentNgFactory };
