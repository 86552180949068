<kendo-grid #kendoGrid [data]="gridView" scrollable="'scrollable'" [pageable]="false"
    class="height100">

   <kendo-grid-column field="code" [width]="70">
        <ng-template kendoGridHeaderTemplate>
            Code
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="circle" [style.background-color]="dataItem.color | DecHex">
                <span class="code-span">{{firstSymbol(dataItem.code)}}</span>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="name" [width]="100">
        <ng-template kendoGridHeaderTemplate>
            Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.name}}
        </ng-template>
    </kendo-grid-column>
    
    <kendo-grid-column [width]="70" [hidden]="!showActual" >
        <ng-template kendoGridHeaderTemplate>
            Occurrences
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.occurrences}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="70" [hidden]="showActual" >
        <ng-template kendoGridHeaderTemplate>
           Shifts
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
           {{dataItem.shiftCount}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="70" [hidden]="showActual" >
        <ng-template kendoGridHeaderTemplate>
           Partial Shifts
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
           {{dataItem.partialShiftCount}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="totalsHours" [width]="70">
        <ng-template kendoGridHeaderTemplate>
            Hours
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.totalsHours}}
        </ng-template>
    </kendo-grid-column>
</kendo-grid>
