export interface IEmployeeCostCenter {
  code: string;
  description: string;
}

export interface IEmployeeCostCenterAllocations {
  list: IEmployeeCostCenterAllocation[];
}

export interface IEmployeeCostCenterAllocation {
  costCenterCode: number;
  costCenterDescription: string;
  percentage: number;
}

export class EmployeeCostCenterAllocation {
  public costCenter: IEmployeeCostCenter;
  public percentage: number = 0;
}
