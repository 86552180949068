export * from './schedule-daily-details/schedule-daily-details.component';
export * from './schedule-daily-details-dialog/schedule-daily-details-dialog.component';
export * from './schedule-daily-details-grid/schedule-daily-details-grid.component';
export * from './schedule-daily-differences/schedule-daily-differences.component';
export * from './schedule-daily-differences-dialog/schedule-daily-differences-dialog.component';
export * from './schedule-daily-differences-grid/schedule-daily-differences-grid.component';

import { ScheduleDailyDetailsComponent } from './schedule-daily-details/schedule-daily-details.component';
import { ScheduleDailyDetailsDialogComponent } from './schedule-daily-details-dialog/schedule-daily-details-dialog.component';
import { ScheduleDailyDetailsGridComponent } from './schedule-daily-details-grid/schedule-daily-details-grid.component';

import { ScheduleDailyDifferencesComponent } from './schedule-daily-differences/schedule-daily-differences.component';
import { ScheduleDailyDifferencesDialogComponent } from './schedule-daily-differences-dialog/schedule-daily-differences-dialog.component';
import { ScheduleDailyDifferencesGridComponent } from './schedule-daily-differences-grid/schedule-daily-differences-grid.component';

export const scheduleDailyDetailsComponents: any = [
  ScheduleDailyDetailsComponent,
  ScheduleDailyDetailsDialogComponent,
  ScheduleDailyDetailsGridComponent,
  ScheduleDailyDifferencesComponent,
  ScheduleDailyDifferencesDialogComponent,
  ScheduleDailyDifferencesGridComponent
];

export const entryScheduleDailyDetailsComponents: any[] = [
  ScheduleDailyDetailsDialogComponent,
  ScheduleDailyDifferencesDialogComponent
];
