export * from './add-employee/add-employee.component';
export * from './add-employee-personal/add-employee-personal.component';
export * from './add-employee-payroll/add-employee-payroll.component';
export * from './add-employee-employment/add-employee-employment.component';
export * from './add-employee-additional/add-employee-additional.component';
export * from './add-employee-finish/add-employee-finish.component';


import { AddEmployeeComponent } from './add-employee/add-employee.component';
import { AddEmployeePersonalComponent } from './add-employee-personal/add-employee-personal.component';
import { AddEmployeePayrollComponent } from './add-employee-payroll/add-employee-payroll.component';
import { AddEmployeeEmploymentComponent } from './add-employee-employment/add-employee-employment.component';
import { AddEmployeeAdditionalComponent } from './add-employee-additional/add-employee-additional.component';
import { AddEmployeeFinishComponent } from './add-employee-finish/add-employee-finish.component';

export const employeeActivitiesComponents: any[] = [
  AddEmployeeComponent,
  AddEmployeePersonalComponent,
  AddEmployeePayrollComponent,
  AddEmployeeEmploymentComponent,
  AddEmployeeAdditionalComponent,
  AddEmployeeFinishComponent
];
