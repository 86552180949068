
import * as _ from 'lodash';
import { Injectable, EventEmitter } from '@angular/core';
import { OrganizationTreeContext, NavigationMenuItem, NavigationMenuType, INavigationMenuEvent, NavigationMenuEventType } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { ApplicationActions } from '../../actions/index';
import { SidebarActions } from '../../actions/index';
import { OrgLevelActions } from '../../../organization/actions/index';
import { EmployeeListActions } from '../../../employee/employee-list/store/index';
import { TimecardsActions } from '../../../time-and-attendance/store/index';
import { ApplicationService } from '../application/application.service';
import { UserApplication, User } from '../../../state-model/models/index';
import { mutableSelect } from '../../../core/decorators/index';
import { ISession, IUser } from '../../../authentication/store/index';
import { ILoginEvent } from '../../../authentication/models/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { Assert } from '../../../framework/index';
import { ScreenAvailabilityService } from '../screen-availability/screen-availability.service';
import { SideEffectsService } from '../side-effects/side-effects.service';
import { ScreenAvailabilityState } from '../../models/index';
import { ComponentStateStorageService } from '../../../common/services/index';
import { StateResetTypes } from '../../../core/models/index';

@Injectable()
export class ApplicationStateManagementService {

  private subscriptions: StringMap<Subscription>;
  private application: UserApplication;
  private orgLevel: OrgLevel;
  private isPendingOrgLevelSelect: boolean;
  public isMenuPinned: boolean = false;

  constructor(
    private applicationActions: ApplicationActions,
    private orgLevelActions: OrgLevelActions,
    private applicationService: ApplicationService,
    private authenticationService: AuthenticationService,
    private screenAvailabilityService: ScreenAvailabilityService,
    private appStateBusService: ApplicationStateBusService,
    private sideEffectsService: SideEffectsService,
    private sidebarActions: SidebarActions
  ) {
    this.orgLevel = null;
    this.subscriptions = {};
    this.subscribeToBusService();
    this.subscribeToAuthenticationService();
  }

  private subscribeToBusService(): void {

    this.subscriptions.initializeSubscription = this.appStateBusService.subscribeInitialize(() => {
      this.subscriptions.appSelected = this.appStateBusService.subscribeToSelectApp((app: UserApplication) => this.appSelected(app));
      this.subscriptions.appDeselected = this.appStateBusService.subscribeToDeselectApp(() => this.appDeselected());
      this.subscriptions.orgLevelsLoaded = this.appStateBusService.subscribeToOrgLevelsLoaded(this.orgLevelsLoaded.bind(this));
      this.subscriptions.orgLevelSelected = this.appStateBusService.subscribeToSelectOrgLevel((orgLevel: OrgLevel) => this.orgLevelSelected(orgLevel));
      this.subscriptions.menuSelected = this.appStateBusService.subscribeToSelectMenu((event: INavigationMenuEvent) => this.menuSelected(event));
      this.subscriptions.menuItemClicked = this.appStateBusService.menuItemClicked$.subscribe((event: NavigationMenuItem) => this.menuItemClicked(event));
    });
  }

  private subscribeToAuthenticationService(): void {
    this.subscriptions.logoutSubscription = this.authenticationService.onLogout.subscribe(() => {
      this.logout();
    });
    this.subscriptions.loginSubscription = this.authenticationService.login$.subscribe((event: ILoginEvent) => {
      this.appStateBusService.login(event.hasAliasChanged);
    });

  }

  private appSelected(app: UserApplication): void {
    this.application = app;
    this.screenAvailabilityService.resetAvailability();
    if (!app) {
      this.applicationActions.deselect();
    } else {
      this.applicationActions.select(this.application);
    }
  }

  private appDeselected(): void {
    this.application = null;
    this.screenAvailabilityService.resetAvailability();
    this.applicationActions.deselect();
  }

  private orgLevelsLoaded(orgLevelTree: OrgLevel[]): void {
    // todo
  }

  private orgLevelSelected(orgLevel: OrgLevel): void {
    if (!orgLevel) return;
    const isReselectOrgLevel = _.isObjectLike(orgLevel) && _.isFinite(orgLevel.id) && _.isObjectLike(this.orgLevel) && _.isFinite(this.orgLevel.id);
    this.orgLevel = orgLevel;
    this.isPendingOrgLevelSelect = true;
    if (isReselectOrgLevel) {
      this.sideEffectsService.orgLevelSelected();
    }
    this.appStateBusService.reloadMenu(this.application, this.orgLevel);
  }

  private menuSelected(event: INavigationMenuEvent): void {
    let menuItem = event.menuItem;

    /*Side effects*/
    if (event.type === NavigationMenuEventType.empty) {
      this.sideEffectsService.menuChanged();
      this.sidebarActions.setLeftSidebar(this.isMenuPinned);
    }
    if (event.type === NavigationMenuEventType.new) {
      this.sideEffectsService.menuChanged();
      this.sidebarActions.setLeftSidebar(this.isMenuPinned);
    }
    if (event.type === NavigationMenuEventType.reselect) {
      this.sideEffectsService.menuChanged();
      this.sidebarActions.setLeftSidebar(this.isMenuPinned);
    }
    /*Side effects*/

    const availability: ScreenAvailabilityState = this.screenAvailabilityService.getAvailability(this.orgLevel, menuItem);
    if (availability.isAvailable) {
      // Do not reset orgLevel here, when menu will be selected, we can't get orgLevel since it will be null
      if (this.isPendingOrgLevelSelect) {
        this.isPendingOrgLevelSelect = false;
        this.orgLevelActions.select(this.getContext(), this.orgLevel);
      }
    }
    this.screenAvailabilityService.changeAvailability(availability);
  }

  private menuItemClicked(event: NavigationMenuItem): void {
    this.sideEffectsService.menuItemClicked();
  }

  private logout(): void {
    this.appStateBusService.logout();
    this.applicationService.deselectApplication();
    this.sideEffectsService.logout();
  }

  private getContext(): OrganizationTreeContext {
    const context = new OrganizationTreeContext();
    context.applicationId = this.application ? this.application.id : 0;
    return context;
  }
}
