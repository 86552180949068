import { OnInit } from '@angular/core';
import { WfmSyncService } from '../../services/wfm/wfm-sync.service';
import { DeviceDetectorService } from '../../../common/services/index';
import { WfmSync } from '../../models/wfm-sync';
import { LookupApiService } from '../../../organization';
import * as moment from 'moment';
import * as _ from 'lodash';
import { NotificationsService } from '../../../../app/core/components';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { WfmResponseNavigationService } from '../../services/wfm-response-navigation.service';
var WfmSyncComponent = /** @class */ (function () {
    function WfmSyncComponent(wfmsyncService, lookup, devDetector, notificationService, router, route) {
        this.wfmsyncService = wfmsyncService;
        this.lookup = lookup;
        this.devDetector = devDetector;
        this.notificationService = notificationService;
        this.router = router;
        this.route = route;
        this.gridData = [];
        this.dataView = [];
        this.returnResponse = [];
        this.columns = [{ field: "totalCount", title: "Total Count" },
            { field: "activeCount", title: "Active Count" }, { field: "currentCount", title: "Current Count" },
            { field: "successCount", title: "Success Count" }, { field: "errorCount", title: "Error Count" }, { field: "addedEmpCount", title: "Added Count" },
            { field: "terminateCount", title: "Terminate Count" },];
        this.viewCol = [{ field: "syncId" }, { field: "errorMessage" }, { field: "status" }, { field: "employeeId" }, { field: "StartDate" },
            { field: "endDate" }, { field: "syncType" }, { field: "orgName" }];
        this.websiteList = ['facility1', 'facility2', 'facility3'];
        this.allEmployee = true;
        this.order = "all";
        this.employeeid = '';
        this.type = '';
        this.orgLevelId = 0;
        this.orgName = '';
        this.employeeIds = [];
        this.partnerId = '';
        this.customerId = '';
        this.companyCode = '';
        this.status = 'A';
        this.termination = false;
        this.active = true;
        this.previousYear = false;
        this.currentYear = false;
        this.syncNow = false;
        this.error = '';
        this.dateError = false;
        this.attributes = [];
        this.selectedLink = "AllEmployees";
        this.orders = [];
        this.effectiveDate = null;
        this.startDate = '';
        this.onChangeCallback = _.noop;
        this.endDate = '';
        this.dateNull = false;
        this.postEmployeeData = new WfmSync();
        this.year = new Date().getFullYear();
        this.refreshdisable = true;
    }
    WfmSyncComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
        };
        var orgLevelId = 1;
        this.lookup.getOrganizations(undefined, orgLevelId).then(function (orgs) {
            _this.facility = orgs;
        });
        this.response();
    };
    WfmSyncComponent.prototype.onChangeStartDate = function (startDate) {
        if (startDate != null) {
            this.checkStartDate = startDate;
            this.startDate = startDate ? dateTimeUtils.convertToDtoString(startDate) : '';
        }
        else
            this.startDate = '';
    };
    WfmSyncComponent.prototype.onChangeEndDate = function (endDate) {
        if (endDate != null) {
            if (moment(endDate).isBefore(moment(this.checkStartDate))) {
                this.endDate = '';
                this.dateError = true;
            }
            else {
                this.endDate = endDate ? dateTimeUtils.convertToDtoString(endDate) : '';
                this.dateError = false;
            }
        }
        else {
            this.endDate = '';
        }
    };
    WfmSyncComponent.prototype.validation = function () {
        if (this.order.toLowerCase() == 'organization') {
            if (this.orgLevelId == null || this.orgLevelId == 0) {
                return 'Select Facility';
            }
        }
        else if (this.order.toLowerCase() == 'ondemand') {
            if (this.employeeid == null || this.employeeid == '') {
                return 'Please enter employeeId"s';
            }
            else if (this.employeeIds.count <= 10)
                return 'Have to enter only 10 EmployeeIDs';
        }
        else if (this.startDate != null && this.startDate != '') {
            if (this.endDate == null || this.endDate == "") {
                this.dateNull = true;
                return 'Both Dates are to be there or Both to be null';
            }
            else {
                this.dateNull = false;
                return '';
            }
        }
        else if (this.endDate != null && this.endDate != '') {
            if (this.startDate == null || this.startDate == "") {
                this.dateNull = true;
                return 'Both Dates are to be there or Both to be null';
            }
            else {
                this.dateNull = false;
                return '';
            }
        }
        else {
            return null;
        }
    };
    WfmSyncComponent.prototype.allowNumericDigitsOnlyOnKeyUp = function (e) {
        var charCode = e.which ? e.which : e.keyCode;
        if (charCode != 44) {
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                e.preventDefault();
                this.msg = 'Only numeric values or digits allowed';
            }
            else {
                this.msg = "";
            }
        }
        else {
            this.msg = "";
        }
    };
    WfmSyncComponent.prototype.employeesyncabc = function (e) {
        var _this = this;
        this.msg = '';
        this.type = this.order;
        this.orgName = '';
        this.startDateVal = this.startDate;
        this.endtDateVal = this.endDate;
        this.partnerId = '';
        this.customerId = '';
        this.companyCode = '';
        this.type = this.order;
        if (this.termination == true) {
            this.status = 'T';
        }
        if (this.facilityid != null) {
            this.orgLevelId = this.facilityid.orgLevelId;
        }
        if (this.employeeid != null && this.employeeid != '') {
            this.employeeIds = this.employeeid.split(',');
        }
        else {
            this.employeeIds = [];
        }
        this.error = this.validation();
        if (this.error == null && !this.dateError && !this.dateNull) {
            this.wfmsyncService.employeeSync(this.policyData()).then(function (value) {
                _this.syncNow = true;
                _this.specificFacility = false;
                _this.allEmployee = false;
                _this.employeeOnDemand = false;
                _this.refreshdisable = false;
                _this.sycId = value.data.syncId;
                _this.notificationService.success('Successful');
                _this.response();
            }).catch(function (reason) {
                _this.state.isLoading = false;
            });
        }
    };
    WfmSyncComponent.prototype.policyData = function () {
        this.postEmployeeData.type = this.type;
        this.postEmployeeData.companyCode = this.companyCode;
        this.postEmployeeData.customerId = this.customerId;
        if (this.previousYear == true && this.currentYear == true) {
            this.strDate = new Date((this.year - 1), 0, 1);
            this.enDate = new Date(this.year, 11, 31);
            this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
            this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
        }
        else if (this.previousYear == false && this.currentYear == true) {
            this.strDate = new Date(this.year, 0, 1);
            this.enDate = new Date(this.year, 11, 31);
            this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
            this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
        }
        else if (this.previousYear == true && this.currentYear == false) {
            this.strDate = new Date((this.year - 1), 0, 1);
            this.enDate = new Date((this.year - 1), 11, 31);
            this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
            this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
        }
        else if (this.previousYear == false && this.currentYear == false) {
            this.strDate = null;
            this.enDate = null;
            this.postEmployeeData.startDate = this.strDate ? dateTimeUtils.convertToDtoString(this.strDate) : '';
            this.postEmployeeData.endDate = this.enDate ? dateTimeUtils.convertToDtoString(this.enDate) : '';
        }
        this.postEmployeeData.orgLevelId = this.orgLevelId;
        this.postEmployeeData.orgName = this.orgName;
        this.postEmployeeData.employeeIds = this.employeeIds;
        this.postEmployeeData.partnerId = this.partnerId;
        this.postEmployeeData.status = this.status;
        return this.postEmployeeData;
    };
    WfmSyncComponent.prototype.onDisplaySelectChanged = function (type, value) {
        var attrValues = new WfmSync();
        attrValues.type = value;
    };
    WfmSyncComponent.prototype.setradio = function (e) {
        this.selectedLink = e;
    };
    WfmSyncComponent.prototype.isSelected = function (name) {
        return !this.selectedLink ? false : (this.selectedLink === name);
    };
    WfmSyncComponent.prototype.getOrders = function () {
        return [
            { id: 1, name: 'All Employees' },
            { id: 2, name: 'Specific Facility' },
            { id: 3, name: 'Employee On Demand' },
        ];
    };
    WfmSyncComponent.prototype.onInputInActiveCheckChange = function (e) {
        if (this.termination == true) {
            this.currentYear = true;
            this.active = false;
        }
        if (this.termination == false) {
            this.currentYear = false;
            this.previousYear = false;
            this.active = true;
        }
    };
    WfmSyncComponent.prototype.onInputActiveCheckChange = function (e) {
        if (this.active == true) {
            this.termination = false;
            this.previousYear = false;
            this.currentYear = false;
        }
        if (this.active == false) {
            this.termination = true;
            this.previousYear = false;
            this.currentYear = true;
        }
    };
    WfmSyncComponent.prototype.onInputChange = function (e) {
        switch (this.order) {
            case "all":
                this.allEmployee = true;
                this.specificFacility = false;
                this.employeeOnDemand = false;
                this.syncNow = false;
                break;
            case "organization":
                this.specificFacility = true;
                this.allEmployee = false;
                this.employeeOnDemand = false;
                this.syncNow = false;
                break;
            case "onDemand":
                this.employeeOnDemand = true;
                this.allEmployee = false;
                this.specificFacility = false;
                this.syncNow = false;
                break;
        }
    };
    WfmSyncComponent.prototype.response = function () {
        var _this = this;
        this.wfmsyncService.employeeSyncResponse().then(function (value1) {
            _this.gridData = value1.data;
        });
    };
    Object.defineProperty(WfmSyncComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    WfmSyncComponent.prototype.employeeViewDetails = function (e) {
        var syncId = e.syncId;
        var navService = new WfmResponseNavigationService(this.router, this.route);
        navService.NavigateToWfmResponse(syncId);
    };
    WfmSyncComponent.prototype.rowSelectionKey = function (context) {
        return context.dataItem;
    };
    WfmSyncComponent.prototype.refreshync = function (e) {
        window.location.reload();
    };
    return WfmSyncComponent;
}());
export { WfmSyncComponent };
