    export interface IAcaSafeHarbor {
    id:string;
    name:string;
    }  
    export class AcaSafeHarbor {
    public id: string;
    public name: string;
    }

    export interface IAcaReason {
        id:string;
        name:string;
    }  
    export class AcaReason {
        public id: string;
        public name: string;
    }

    export interface IAcaOfferOfCoverage {
        id:string;
        name:string;
    }  
    export class AcaOfferOfCoverage {
        public id: string;
        public name: string;
    }