import { Injectable } from '@angular/core';
import { ResetPasswordCodes, PasswordResetState, ResetPassword } from '../../models/password-reset';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ResponseBody, Meta } from '../../../core/models/index';
import { LoggerService } from '../../../core/services/index';
import { UserPasswordSettings } from '../../../core/models/user-password-settings/user-password-settings';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class PasswordResetManagementService {

    public stateChange$: ReplaySubject<PasswordResetState>;
    public passwordPolicyLoaded$: ReplaySubject<UserPasswordSettings>;

    private state: PasswordResetState;

    private token: string;
    private alias: string;
    private username: string;

    constructor(private authService: AuthenticationService, private loggerService: LoggerService) {
        this.state = new PasswordResetState();
        this.stateChange$ = new ReplaySubject<PasswordResetState>();
        this.passwordPolicyLoaded$ = new ReplaySubject<UserPasswordSettings>();
    }

    public init(): void {

        this.state.hasSuccedeed = false;
        this.state.hasFailed = false;
        this.state.isUnknownError = false;
        this.state.error = null;
        this.stateChange$.next(this.state);
    }

    public setUserData(token: string, username: string, alias: string): void {
        this.token = token;
        this.username = username;
        this.alias = alias;
    }

    public resetPasswordByOldPassword(newPassword: string, oldPassword: string): void {

        this.state.isLoading = true;
        this.state.hasSuccedeed = false;
        this.state.hasFailed = false;
        this.state.isUnknownError = false;
        this.state.error = null;
        this.stateChange$.next(this.state);


        this.authService.resetPasswordByOldPassword(this.username, oldPassword, newPassword, this.alias)
            .then((response: ResetPassword) => this.handleResetPasswordSuccess(response))
            .catch((error: ResponseBody<any, Meta>) => this.handleApiServiceFail(error));

    }

    public forgotResetPassword(newPassword: string): void {


        this.state.isLoading = true;
        this.state.hasSuccedeed = false;
        this.state.hasFailed = false;
        this.state.isUnknownError = false;
        this.state.error = null;
        this.stateChange$.next(this.state);

        this.authService.resetPasswordByToken(
            this.token,
            this.username,
            newPassword,
            this.alias
        )
            .then((response: ResetPassword) => this.handleResetPasswordSuccess(response))
            .catch((error: ResponseBody<any, Meta>) => this.handleApiServiceFail(error));
    }

    public handleResetPasswordSuccess(resetPassword: ResetPassword): void {

        this.state.isLoading = false;

        if (resetPassword.code === ResetPasswordCodes.PasswordChanged) {
          this.state.hasSuccedeed = true;
        } else {
          this.state.hasFailed = true;
          this.state.error = resetPassword.code;
        }

        this.stateChange$.next(this.state);
    }

    public loadPasswordPolicy(): void {
        this.authService.loadPasswordPolicyUnsecure(this.alias).then((settings: UserPasswordSettings) => {
            this.passwordPolicyLoaded$.next(settings);
        });
    }

    private handleApiServiceFail(error: ResponseBody<any, Meta>): void {
        this.loggerService.error('Unexpected error when requesting a password reset', error);
        this.state.isLoading = false;
        this.state.isUnknownError = true;
        this.stateChange$.next(this.state);
    }

}
