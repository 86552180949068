import { AppServerConfig } from './../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { ReadFile, OrgLevelFlat } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { NotificationsService } from '../../../../core/components/index';
import { IControlState, StateResetTypes } from '../../../../core/models/index';
import {
  StateManagementService,
  ComponentStateStorageService,
  BenefitsNavigationService, ChangeManagementService
} from '../../../../common/services/index';
import {
  BenefitDetailsConfig,
  BenefitDetailsGroup,
  BenefitDetailsProvider,
  BenefitDetailsProviderEntity,
  BenefitDetailsLineStandart,
  BenefitDetailsProviderLineStandartEntity,
  BenefitDetailsLine,
  BenefitLineTypeEnum,
  ExtendExpireRenewModel,
  BenefitDetailsPageState,
} from '../../models/index';
import { BenefitDetailsPermissionService } from './benefit-details-permission.service';
import { BenefitDetailsApiService } from './benefit-details-api.service';
import { BenefitEligibilityRulesManagementService } from './../../services/benefit-eligibility-rules/benefit-eligibility-rules-management.service';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { AcaCategory} from '../../../../organization/models/index';
import { BenefitConsoleDetailsCommonService } from '../benefit-console/benefit-console-details-common.service';
import * as moment from 'moment';

@Injectable()
export class BenefitDetailsManagementService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private selectedGroup: BenefitDetailsGroup;
  private providers: BenefitDetailsProviderEntity[] = [];
  private loadExpriredPlans: boolean = false;
  private selectedProvider: BenefitDetailsProviderEntity;
  private selectedProviderLine: BenefitDetailsProviderLineStandartEntity;
  private lastSelected = {
    provider: null as BenefitDetailsProviderEntity,
    providerLine: null as BenefitDetailsProviderLineStandartEntity,
  };
  private toSelect = { groupId: null, providerId: null, providerLineId: null };
  private createNewProvider: boolean = false;
  private tierAttachmentsToDelete = new Map<number, number[]>();

  private loading$ = new Subject<boolean>();
  private loadingEligibleEmployees$ = new Subject<boolean>();
  private loadingEnrolledEmployees$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private configChanged$ = new ReplaySubject<BenefitDetailsConfig>(1);
  private loadProviders$ = new ReplaySubject<BenefitDetailsProviderEntity[]>(1);
  private selectGroup$ = new ReplaySubject<BenefitDetailsGroup>(1);
  private selectProvider$ = new ReplaySubject<BenefitDetailsProviderEntity>(1);
  private selectProviderLine$ = new ReplaySubject<BenefitDetailsProviderLineStandartEntity>(1);
  private expireExtendRenew$ = new Subject<ExtendExpireRenewModel>();
  private acaCategoryLookup$ = new Subject<AcaCategory[]>();
  private createNewProvider$ = new Subject<boolean>();
  private readonly defaultProviderName = 'New';
  private readonly defaultProviderLineName = 'New';

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};
  private pageState: BenefitDetailsPageState = null;
  private componentId: string;
  private controlId: string = 'pageState';
  private readonly resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private navService: BenefitsNavigationService;
  private isBenefitDeductionEnabled2  : boolean = false;

  constructor(
    private permissionService: BenefitDetailsPermissionService,
    private apiService: BenefitDetailsApiService,
    private stateService: StateManagementService,
    private storageService: ComponentStateStorageService,
    private notificationService: NotificationsService,
    private orgLevelWatchService: OrgLevelWatchService,
    private rulesManagementService: BenefitEligibilityRulesManagementService,
    private lookupApiService: LookupApiService,
    private commonService: BenefitConsoleDetailsCommonService,
    private changeManagement: ChangeManagementService,
    private appSettingsManageService: AppSettingsManageService
  ) { }

  public init(componentId: string, router: Router, activatedRoute: ActivatedRoute): void {
    this.componentId = componentId;

    this.changeManagement.setCurrentComponentId(this.componentId);

    this.storeQueryParams(activatedRoute);
    this.subscribeToConsoleService();

    this.navService = new BenefitsNavigationService(router, activatedRoute);
    this.stateService.init(componentId, true);

    this.subscribeToOrgLevelChanges();
  }

  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.loadingEligibleEmployees$.complete();
    this.loadingEnrolledEmployees$.complete();
    this.orgLevelChanged$.complete();
    this.configChanged$.complete();
    this.loadProviders$.complete();
    this.selectGroup$.complete();
    this.selectProvider$.complete();
    this.selectProviderLine$.complete();
    this.expireExtendRenew$.complete();
    this.acaCategoryLookup$.complete();
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToEligibleEmployeesLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadingEligibleEmployees$.subscribe(callback);
  }

  public subscribeToEnrolledEmployeesLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadingEnrolledEmployees$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (v: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToChangeConfig(callback: (v: BenefitDetailsConfig) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.configChanged$.subscribe(callback);
  }

  public subscribeToLoadProviders(callback: (v: BenefitDetailsProviderEntity[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadProviders$.subscribe(callback);
  }

  public subscribeToSelectGroup(callback: (v: BenefitDetailsGroup) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.selectGroup$.subscribe(callback);
  }

  public subscribeToSelectProvider(callback: (v: BenefitDetailsProviderEntity) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.selectProvider$.subscribe(callback);
  }

  public subscribeToSelectProviderLine(callback: (v: BenefitDetailsProviderLineStandartEntity) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.selectProviderLine$.subscribe(callback);
  }
  public subscribeToAcaCategoryLookup(callback: (v: AcaCategory[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.acaCategoryLookup$.subscribe(callback);
  }
  public subscribeToExpireExtendRenew(callback: (v: ExtendExpireRenewModel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.expireExtendRenew$.subscribe(callback);
  }

  public subscribeToCreateNewProvider(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.createNewProvider$.subscribe(callback);
  }

  public get isLoadingExpiredPlans(): boolean {
    return this.loadExpriredPlans;
  }

  public set isLoadingExpiredPlans(isLoading: boolean) {
    if (this.loadExpriredPlans !== isLoading) {
      this.loadExpriredPlans = isLoading;
      if (_.isObjectLike(this.selectedGroup)) {
        this.loadBenefitProviders(this.selectedGroup.id, isLoading);
      }
      this.commonService.saveIsShowingExpiredPlansState(isLoading);
    }
  }

  public isUniqueName(list: Array<{ id: number; name: string}>, id: number, name: string): boolean {
    const trimmedName = _.toLower(_.trim(name));
    if (_.isArray(list) && _.size(list) > 0) {
      const foundedItems = _.find(list, (p) => p.id !== id && _.toLower(p.name) === trimmedName);
      return _.isNil(foundedItems);
    }
    return true;
  }
  public isUniqueName1(list: Array<{ id: number; payrollDeductionCode: string}>, id: number, payrollDeductionCode: string): boolean {
    const trimmedName = _.toLower(_.trim(payrollDeductionCode));
    if (_.isArray(list) && _.size(list) > 0) {
      const foundedItems = _.find(list, (p) => p.id !== id && _.toLower(p.payrollDeductionCode) === trimmedName);
      return _.isNil(foundedItems);
    }
    return true;
  }

  public isEqualProviders(e1: BenefitDetailsProviderEntity, e2: BenefitDetailsProviderEntity): boolean {
    if (_.isObjectLike(e1) && _.isObjectLike(e2)) {
      const p1 = e1.current;
      const p2 = e2.current;
      if (_.isNumber(p1.id) && _.isNumber(p2.id)) {
        return p1.id === p2.id;
      }
      return p1.name === p2.name && p1.orgLevelId === p2.orgLevelId && p1.benefitGroupId === p2.benefitGroupId;
    }
    return e1 === e2;
  }

  public updateSpinner(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  public updateEligibleEmployeesSpinner(isLoading: boolean): void {
    this.loadingEligibleEmployees$.next(isLoading);
  }

  public updateEnrolledEmployeesSpinner(isLoading: boolean): void {
    this.loadingEnrolledEmployees$.next(isLoading);
  }

  public storeProviderAndLine(isDraft: boolean): void {
    this.markAsDirty();
    if (isDraft) {
      this.lastSelected.provider = this.selectedProvider;
      this.lastSelected.providerLine = this.selectedProviderLine;
    } else {
      this.selectedProvider.storeEntity();
      this.selectedProviderLine.storeEntity();
    }
  }

  public restoreProviderAndLine(isDraft: boolean): void {
    let provider: BenefitDetailsProviderEntity = null;
    let providerLine: BenefitDetailsProviderLineStandartEntity = null;
    if (isDraft) {
      provider = this.lastSelected.provider;
      providerLine = this.lastSelected.providerLine;
      this.lastSelected.provider = this.lastSelected.providerLine = null;
      this.selectedProvider = null;
      this.selectedProviderLine = null;
    } else {
      provider = this.selectedProvider;
      providerLine = this.selectedProviderLine;
      if (this.selectedProvider.isStored) {
        provider = this.selectedProvider.restoreEntity();
      }
      if (this.selectedProviderLine.isStored) {
        providerLine = this.selectedProviderLine.restoreEntity();
      }
    }
    this.clearChanges();
    this.tierAttachmentsToDelete.clear();
    this.selectProvider(provider, false, true);
    this.selectProviderLine(providerLine, true);
  }

  public selectDraftProviderAndLine(): void {
    const draftProvider = this.createBenefitProviderEntity();
    const draftProviderLine = this.createBenefitProviderLineEntity(BenefitLineTypeEnum.Standart);

    this.providers = this.providers.concat([draftProvider]);
    this.loadProviders$.next(this.providers);

    this.selectProvider(draftProvider, false);
    this.selectProviderLine(draftProviderLine);
  }

  public removeDraftProvider(): void {
    if (this.selectedProvider.isDraft) {
      this.rulesManagementService.clearExistingMappedRules();
      this.updateProvidersList(this.selectedProvider, null);
    }
  }

  public getProperOrglevelBasedOnSelectedOrglevel(): number {
    if(!this.selectedProvider){
      return;
    }
    const providerOrgLevelId = this.selectedProvider.current.orgLevelId;
    const { orglevelsNotFound, isSameAsProvider, isUnderProvider } = this.validateSelectedOrglevelAgainstProvider(this.orgLevel.id, providerOrgLevelId);
    if (orglevelsNotFound || isSameAsProvider || isUnderProvider) {
      return this.orgLevel.id;
    }
    return providerOrgLevelId;
  }

  public validateSelectedOrglevelAgainstProvider(
    selectedOrglevelId: number,
    providerOrglevelId: number
  ): { orglevelsNotFound: boolean, isSameAsProvider: boolean, isUnderProvider: boolean } {
    const orglevelsList = this.orgLevelWatchService.getFlatList();
    const providerOrglevel = _.find(orglevelsList, (o) => o.orgLevel.id === providerOrglevelId);
    const selectedOrglevel = _.find(orglevelsList, (o) => o.orgLevel.id === selectedOrglevelId);
    const notFound = !_.isObjectLike(providerOrglevel) || !_.isObjectLike(selectedOrglevel);

    return {
      orglevelsNotFound: notFound,
      isSameAsProvider: !notFound && providerOrglevel.orgLevel.id === selectedOrglevel.orgLevel.id,
      isUnderProvider: !notFound && _.includes(selectedOrglevel.orgLevel.treeViewPath, providerOrglevel.orgLevel.id)
    };
  }

  public selectProvider(p: BenefitDetailsProviderEntity, isRequireUpdate: boolean, byPass: boolean = false): void {
    if (_.isObjectLike(p)) {
      const { orglevelsNotFound, isSameAsProvider, isUnderProvider } = this.validateSelectedOrglevelAgainstProvider(this.orgLevel.id, p.current.orgLevelId);
      p.requireUpdate = isRequireUpdate;
      this.emitProvider(p, byPass);
      this.permissionService.setOrgLevelPermissions(
        !orglevelsNotFound && (isSameAsProvider || !isUnderProvider),
        null
      );
      if (_.size(p.current.benefitLines) === 0) {
        this.updateSpinner(false);
      }
    }
  }

  public emitProvider(p: BenefitDetailsProviderEntity, byPass: boolean = false): void {
    if (_.isObjectLike(p) && (!this.isEqualProviders(this.selectedProvider, p) || byPass)) {
      this.restoreSelectedProviderLine(p);
      this.selectedProvider = p;
      this.selectProvider$.next(this.selectedProvider);
      this.updateQueryParams(null, p);
    }
  }

  public selectProviderLine(pl: BenefitDetailsProviderLineStandartEntity, byPass: boolean = false): void {
    const sPL = this.selectedProviderLine;
    if (_.isObjectLike(pl) && (!_.isObjectLike(sPL) || sPL.current.id !== pl.current.id || byPass)) {
      this.restoreSelectedTier(sPL, pl);
      this.selectedProviderLine = pl;
      this.selectedProviderLine.nextLineDetails = this.getNextLine(pl.current.id);
      this.selectedProvider.current.name = pl.current.name;
      this.selectedProvider.current.storedName = pl.current.name;
      this.selectProviderLine$.next(this.selectedProviderLine);
      this.updateQueryParams(null, null, pl);
    }
  }

  public selectGroup(g: BenefitDetailsGroup, byPass: boolean = false): void {
    if (_.isObjectLike(g) && (!_.isObjectLike(this.selectedGroup) || this.selectedGroup.id !== g.id || byPass)) {
      this.selectedGroup = g;
      this.selectGroup$.next(g);
      if (_.isObjectLike(this.selectedProvider)) {
        this.selectedProvider.requireUpdate = false;
      }
      this.updateQueryParams(g);
      this.loadBenefitProviders(g.id, this.loadExpriredPlans);
    }
  }

  public saveSelectedTierId(ptId: number): void {
    if (_.isFinite(ptId) && this.pageState.selectedProviderTierId !== ptId) {
      this.pageState.selectedProviderTierId = ptId;
      this.storageService.setControlState(this.componentId, this.controlId, { value: this.pageState }, this.resetBy);
    }
  }

  public async saveBenefitProvider(callbackLoadProviderLine: (pl: BenefitDetailsLine) => void): Promise<void> {
    this.updateSpinner(true);
    this.clearChanges();
    try {
      const { provider, providerLine } = await this.apiService.saveBenefitProvider(
        this.selectedProvider.current,
        this.selectedProviderLine.current
      );
      await this.rulesManagementService.saveRules(this.orgLevel.id, providerLine.current.tiers);
      if (this.selectedProviderLine.hasTiersAttachments) {
        await this.apiService.saveTiersAttachments(providerLine.current.tiers, this.selectedProviderLine.current.tiers);
      }
      this.tierAttachmentsToDelete.clear();
      const needsToSelectProvider = this.loadExpriredPlans || !provider.current.allLinesExpired;
      this.updateProvidersList(this.selectedProvider, needsToSelectProvider ? provider : null);
      const firstProvider = _.head(this.providers);
      this.selectProvider(needsToSelectProvider ? provider : firstProvider, false);
      await callbackLoadProviderLine(needsToSelectProvider ? providerLine.current.line : _.head(firstProvider.current.benefitLines));
      this.notificationService.success('Provider Created', 'Benefit Provider created successfully');
    } catch (e) {
      console.error(e);
    } finally {
      this.updateSpinner(false);
    }
  }

  public async saveBenefitProviderLine(callbackLoadProviderLine: (pl: BenefitDetailsLine) => void, providerPlandate: Date): Promise<void> {
    this.updateSpinner(true);
    this.clearChanges();
    try {
      const providerLine = await this.apiService.saveBenefitDetailsLineStandart(
        this.selectedProvider.current.name,
        this.selectedProviderLine.current,
        providerPlandate
      );
      await this.rulesManagementService.saveRules(this.orgLevel.id, providerLine.current.tiers);
      if (this.tierAttachmentsToDelete.size > 0) {
        await this.apiService.deleteTiersAttachments(this.tierAttachmentsToDelete);
        this.tierAttachmentsToDelete.clear();
      }
      if (this.selectedProviderLine.hasTiersAttachments) {
        await this.apiService.saveTiersAttachments(providerLine.current.tiers, this.selectedProviderLine.current.tiers);
        this.selectedProviderLine.hasTiersAttachments = false;
      }
      this.selectedProvider.dropStored();
      await callbackLoadProviderLine(providerLine.current.line);
      this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
    } catch (e) {
      console.error(e);
    } finally {
      this.updateSpinner(false);
    }
  }

  public async deleteBenefitProvider(callbackLoadProviderLine: (pl: BenefitDetailsLine) => void): Promise<void> {
    this.updateSpinner(true);
    try {
      await this.apiService.deleteBenefitProvider(this.selectedProvider.current.id);
      this.updateProvidersList(this.selectedProvider, null);
      const firstProvider = _.head(this.providers);
      this.selectProvider(firstProvider, false);
      await callbackLoadProviderLine(_.head(firstProvider.current.benefitLines));
      this.notificationService.success('Provider Deleted', 'Benefit Provider deleted successfully');
    } catch (e) {
      console.error(e);
    } finally {
      this.updateSpinner(false);
    }
  }

  public async deleteTierAttachments(tierId: number, delAttachmentIds: number[]): Promise<void> {
    let attachmentsIds = this.tierAttachmentsToDelete.get(tierId) || [];
    this.tierAttachmentsToDelete.set(tierId, attachmentsIds.concat(delAttachmentIds));
  }

  public async loadProviderLine(id: number, byPass: boolean = false): Promise<void> {
    const pl = this.selectedProviderLine;
    if (_.isFinite(id) && (!_.isObjectLike(pl) || pl.current.id !== id || byPass)) {
      await this.loadBenefitProviderLine(id);
      this.emitCreateNewProvider();
    }
  }

  private async loadBenefitProviderLine(id: number): Promise<void> {
    this.updateSpinner(true);
    try {
      const pl = await this.apiService.getBenefitDetailsLineStandart(id);
      this.selectProviderLine(pl, true);
    } catch (e) {
      console.error(e);
    } finally {
      this.updateSpinner(false);
    }
  }

  private async loadBenefitProviders(groupId: number, isLoadingExprired: boolean): Promise<void> {
    this.updateSpinner(true);
    let providers: BenefitDetailsProviderEntity[] = [];
    try {
      providers = await this.apiService.getBenefitProviders(this.orgLevel.id, groupId, isLoadingExprired);
      this.applyProviders(providers);
    } catch (e) {
      console.error(e);
      this.updateSpinner(false);
    } finally {
      if (_.size(providers) === 0) {
        this.updateSpinner(false);
        this.emitCreateNewProvider();
      }
    }
  }

  private applyProviders(providers: BenefitDetailsProviderEntity[]): void {
    this.providers = providers;
    this.loadProviders$.next(this.providers);
    this.detectSelectedProvider(providers);
  }

  private detectSelectedProvider(providers: BenefitDetailsProviderEntity[]): void {
    let selectedProvider = _.head(providers);
    if (_.isFinite(this.toSelect.providerId)) {
      selectedProvider = _.find(providers, (p) => p.current.id === this.toSelect.providerId) || selectedProvider;
    }
    this.selectedProvider = this.selectedProviderLine = this.toSelect.providerId = null;
    this.selectProvider(selectedProvider, true);
  }

  private async loadConfig(): Promise<void> {
    this.updateSpinner(true);
    try {
      this.isBenefitDeductionEnabled2 = await this.getBenefitDeductionEnabled2();
      const config = await this.apiService.getBenefitDetailsConfig(this.orgLevel.id);
      this.applyConfig(config);
    } catch (e) {
      console.error(e);
      this.updateSpinner(false);
    }
  }

  private applyConfig(config: BenefitDetailsConfig): void {
    this.detectSelectedGroup(config); 
    this.configChanged$.next(config);
    const { canAdd, canEdit, canDelete, canEditFormula, canRenew, canExpire, canExtend, canEnroll ,canMapPayroll} = config;
    let benefitDeductionEnabled =  canMapPayroll && this.isBenefitDeductionEnabled2;
    this.permissionService.setExternalPermissions(
      canAdd,
      canEdit,
      canDelete,
      canEditFormula,
      canRenew,
      canExpire,
      canExtend,
      canEnroll,
      canMapPayroll,
      benefitDeductionEnabled
    );
  }

  private detectSelectedGroup(config: BenefitDetailsConfig): void {
    let selectedGroup = _.head(config.groups);
    if (_.isFinite(this.toSelect.groupId)) {
      selectedGroup = _.find<BenefitDetailsGroup>(config.groups, { id: this.toSelect.groupId }) || selectedGroup;
      this.toSelect.groupId = null;
    }
    this.selectGroup(selectedGroup, true);
  }

  public async expirePlan(newEndDate: Date): Promise<void> {
    this.updateSpinner(true);
    this.clearChanges();
    try {
      const providerLine = await this.apiService.expireBenefitPlan(this.selectedProviderLine.current.id, newEndDate);
      this.expireExtendRenew$.next(new ExtendExpireRenewModel(false, null, providerLine));
      this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
    } catch (e) {
      console.error(e);
      this.updateSpinner(false);
    }
  }

  public async extendPlan(newEndDate: Date): Promise<void> {
    this.updateSpinner(true);
    this.clearChanges();
    try {
      const providerLine = await this.apiService.extendBenefitPlan(this.selectedProviderLine.current.id, newEndDate);
      this.expireExtendRenew$.next(new ExtendExpireRenewModel(false, null, providerLine));
      this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
    } catch (e) {
      console.error(e);
      this.updateSpinner(false);
    }
  }

  public async renewPlan(newEndDate: Date): Promise<void> {
    this.updateSpinner(true);
    this.clearChanges();
    try {
      const providerLine = await this.apiService.renewBenefitPlan(this.selectedProviderLine.current.id, newEndDate);
      this.expireExtendRenew$.next(new ExtendExpireRenewModel(true, providerLine, null));
      this.notificationService.success('Provider Saved', 'Benefits Provider saved successfully');
    } catch (e) {
      console.error(e);
      this.updateSpinner(false);
    }
  }

  private createBenefitProviderEntity(): BenefitDetailsProviderEntity {
    const provider = new BenefitDetailsProvider();
    provider.id = -1;
    provider.name = this.defaultProviderName;
    provider.storedName = this.defaultProviderName;
    provider.allLinesExpired = false;
    provider.orgLevelId = this.orgLevel.id;
    provider.orgLevelName = this.orgLevel.name;
    provider.benefitGroupId = this.selectedGroup.id;

    const entity = new BenefitDetailsProviderEntity();
    entity.current = provider;
    entity.requireUpdate = false;
    entity.markAsDraft();

    return entity;
  }

  private createBenefitProviderLineEntity(
    benefitLineType: BenefitLineTypeEnum
  ): BenefitDetailsProviderLineStandartEntity {

    const line = new BenefitDetailsLineStandart();
    line.line.id = -1;
    line.line.name = this.defaultProviderLineName;
    line.line.startDate = moment().startOf('day').toDate();
    line.line.endDate = moment().add('year', 1).endOf('day').toDate();
    line.line.benefitLineType = benefitLineType;
    line.includeInReport = false;

    const entity = new BenefitDetailsProviderLineStandartEntity();
    entity.current = line;
    entity.markAsDraft();

    return entity;
  }

  private restoreSelectedProviderLine(p: BenefitDetailsProviderEntity): void {
    if (_.isFinite(this.toSelect.providerLineId)) {
      p.selectedLineId = this.toSelect.providerLineId;
      this.toSelect.providerLineId = null;
    }
  }

  private restoreSelectedTier(
    prevPL: BenefitDetailsProviderLineStandartEntity,
    newPL: BenefitDetailsProviderLineStandartEntity
  ): void {
    if (_.isObjectLike(newPL)) {
      if (_.isFinite(this.pageState.selectedProviderTierId)) {
        newPL.selectedTierId = this.pageState.selectedProviderTierId;
        this.pageState.selectedProviderTierId = null;
      } else if (_.isObjectLike(prevPL) && prevPL.current.id === newPL.current.id) {
        newPL.selectedTierId = prevPL.selectedTierId;
      }
    }
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.stateService.onInit$
      .combineLatest(this.orgLevel$.filter((o: OrgLevel) => _.isFinite(_.get(o, 'id'))))
      .subscribe(([s, orgLevel]: [any, OrgLevel]) => {
        if (!_.isObjectLike(this.pageState)) {
          const state = this.storageService.getControlState(this.componentId, this.controlId);
          this.pageState = (_.isObjectLike(state.value) && state.value) || new BenefitDetailsPageState();
        }

        if (!_.isObjectLike(this.orgLevel) || this.orgLevel.id !== orgLevel.id) {
          this.orgLevel = orgLevel;
          this.orgLevelChanged$.next(this.orgLevel);
          if (orgLevel.type === OrgLevelType.department) this.createNewProvider = false;
          this.permissionService.setOrgLevelPermissions(null, orgLevel.type !== OrgLevelType.department);
          this.loadConfig();
        }
      });
  }

  private subscribeToConsoleService(): void {
    this.commonService.restoreState();
    this.subscriptions.consoleService = this.commonService.subscribeToShowExpiredPlansState((isShow: boolean) => {
      this.loadExpriredPlans = isShow;
    });
  }

  private updateProvidersList(prevP: BenefitDetailsProviderEntity, newP: BenefitDetailsProviderEntity): void {
    const providers = this.providers.concat();
    const index = _.findIndex(providers, (pr) => this.isEqualProviders(pr, prevP));
    if (index !== -1) {
      providers.splice.apply(providers, _.isNil(newP) ? [index, 1] : [index, 1, newP]);
      this.providers = providers;
      this.loadProviders$.next(this.providers);
    }
  }

  private storeQueryParams(activatedRoute: ActivatedRoute): void {
    const queryParams = activatedRoute.snapshot.queryParams || {};
    const gId = (this.toSelect.groupId = parseInt(queryParams.groupId) || null);
    const pId = (this.toSelect.providerId = parseInt(queryParams.providerId) || null);
    const plId = (this.toSelect.providerLineId = parseInt(queryParams.providerLineId) || null);
    this.createNewProvider = _.isFinite(gId) && !_.isFinite(pId) && !_.isFinite(plId);
  }

  private updateQueryParams(
    g: BenefitDetailsGroup,
    p?: BenefitDetailsProviderEntity,
    pl?: BenefitDetailsProviderLineStandartEntity
  ): void {
    if (_.isObjectLike(g) && _.isFinite(g.id)) {
      this.toSelect.groupId = g.id;
      this.navService.updateQueryParams(g.id);
    }
    if (_.isObjectLike(p) && !p.isDraft && _.isFinite(p.current.id)) {
      this.toSelect.providerId = p.current.id;
      this.navService.updateQueryParams(null, p.current.id);
    }
    if (_.isObjectLike(pl) && !pl.isDraft && _.isFinite(pl.current.id)) {
      this.toSelect.providerLineId = pl.current.id;
      this.navService.updateQueryParams(null, null, pl.current.id);
    }
  }

  private emitCreateNewProvider(): void {
    const needCreateNewProvider = this.createNewProvider;
    this.createNewProvider = false;
    if (needCreateNewProvider && (!_.isObjectLike(this.selectedProvider) || !this.selectedProvider.isDraft)) {
      this.createNewProvider$.next(needCreateNewProvider);
    }
  }

  public loadAcaCategory(): void {
    this.lookupApiService.getAcaCategory()
     .then((container: AcaCategory[]) => {
       this.acaCategoryLookup$.next(container);
     });
  }

  private getNextLine(lineId: number): BenefitDetailsLine {
    if (this.selectedProvider && this.selectedProvider.current && this.selectedProvider.current.benefitLines) {
      const lines = this.selectedProvider.current.benefitLines;
      const currentLine = _.find(lines, x => x.id === lineId);
      if (_.isObjectLike(currentLine)) {
        const nextLines = _.filter(lines, x => x.startDate > currentLine.endDate);
        if (_.size(nextLines) > 0) {
          return _.minBy(nextLines, 'startDate');
        }
      }
    }
    return null;
  }

  private markAsDirty(): void {
    this.changeManagement.changeNotify();
  }

  private clearChanges(): void {
    this.changeManagement.clearChanges();
  }

  private async getBenefitDeductionEnabled2(): Promise<boolean> {
    return  (await this.appSettingsManageService.getAppServerConfig()).isBenefitDeductionEnabled2;
  }
}
