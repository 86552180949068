import { DialogModeSize } from './../../models/dialog-options';
import { Type, Provider, Renderer2 } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { ModalBehavior, DialogOptions } from '../../models/index';
import { Assert } from '../../../framework/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../components/confirm-dialog/confirm-dialog.component';
import { InfoDialogComponent } from '../../components/info-dialog/info-dialog.component';
import { commonConfig } from '../../common.config';
import { screenUtils } from '../../../common/utils/index';
import { appConfig } from '../../../app.config';
var ModalAnchorDirective = /** @class */ (function () {
    function ModalAnchorDirective(viewContainer, modalService, renderer) {
        Assert.isNotNull(viewContainer, 'viewContainer');
        Assert.isNotNull(modalService, 'modalService');
        this.viewContainer = viewContainer;
        this.modalService = modalService;
        this.m_renderer = renderer;
    }
    Object.defineProperty(ModalAnchorDirective.prototype, "isGlobal", {
        set: function (isGlobal) {
            if (isGlobal) {
                this.setAsGlobal();
            }
        },
        enumerable: true,
        configurable: true
    });
    ModalAnchorDirective.prototype.setAsGlobal = function () {
        this.modalService.setGlobalAnchor(this);
    };
    ModalAnchorDirective.prototype.setElementStyle = function (elt, style, value) {
        this.m_renderer.setStyle(elt, style, value);
    };
    ModalAnchorDirective.prototype.open = function (type, title, providers, uniqueId) {
        return this.modalService.open(type, this.viewContainer, title, providers, uniqueId);
    };
    ModalAnchorDirective.prototype.openConfirmDialog = function (title, message, callback, confirmOptions) {
        var dialogOptions = new DialogOptions();
        dialogOptions.message = message;
        dialogOptions.height = commonConfig.dialogs.confirmDialog.height;
        dialogOptions.width = commonConfig.dialogs.confirmDialog.width;
        if (!confirmOptions) {
            confirmOptions = new ConfirmOptions();
            confirmOptions.showCancel = true;
            confirmOptions.showOK = true;
        }
        var providers = [
            { provide: DialogOptions, useValue: dialogOptions },
            { provide: ConfirmOptions, useValue: confirmOptions }
        ];
        return this.openDialog(ConfirmDialogComponent, title, dialogOptions, providers, callback);
    };
    ModalAnchorDirective.prototype.openInfoDialog = function (title, message, callback) {
        var options = new DialogOptions();
        options.message = message;
        options.height = commonConfig.dialogs.confirmDialog.height;
        options.width = commonConfig.dialogs.confirmDialog.width;
        return this.openDialog(InfoDialogComponent, title, options, undefined, callback);
    };
    ModalAnchorDirective.prototype.openInfoDialogEx = function (title, options, callback) {
        return this.openDialog(InfoDialogComponent, title, options, undefined, callback);
    };
    ModalAnchorDirective.prototype.openDialog = function (type, title, options, providers, callback) {
        var _this = this;
        var modalSettings = this.modalService.createDefaultModalSettings();
        modalSettings.maximized = false;
        // add header height if we have height
        if (!options.hideTitleBar) {
            if (options.height !== undefined) {
                options.height += appConfig.popupHeaderHeight;
            }
        }
        if (screenUtils.isMobile && options.fullHeightOnMobile) {
            options.height = screen.height;
        }
        else {
            modalSettings.height = options.height;
        }
        if ((options.width !== undefined && screen.width < options.width) || (options.height !== undefined && screen.height < options.height)) {
            modalSettings.maximized = true;
        }
        modalSettings.maxHeight = screen.height;
        modalSettings.width = options.width;
        modalSettings.resizable = false;
        modalSettings.title = !options.hideTitleBar ? title : false;
        modalSettings.actions = [ModalBehavior.close];
        modalSettings.showCloseButton = options.showCloseButton;
        modalSettings.useWindowWrapper = !options.hideTitleBar;
        modalSettings.className = options.className;
        modalSettings.headerClassName = options.headerClassName;
        var rp;
        if (providers) {
            rp = providers;
        }
        else {
            rp = [{ provide: DialogOptions, useValue: options }];
        }
        var dialog;
        var ref;
        ref = this.modalService.openEx(type, this.viewContainer, modalSettings, rp);
        options.windowUniqueId = ref.uniqueId;
        dialog = ref.reference;
        if (modalSettings.modal) {
            this.m_renderer.addClass(this.viewContainer.element.nativeElement, 'modal-overlay');
        }
        this.modalService.subscribeOnClose(function (result) {
            var uniqueId = result.uniqId;
            var count = _this.modalService.getOpenedDialogsCount();
            if (count === 0 || _this.modalService.globalAnchor !== _this) {
                _this.m_renderer.removeClass(_this.viewContainer.element.nativeElement, 'modal-overlay');
            }
            if (callback && options.windowUniqueId === uniqueId) {
                callback(dialog.dialogResult, options.windowUniqueId);
            }
        });
        return dialog;
    };
    ModalAnchorDirective.prototype.openEx = function (type, settings, providers) {
        return this.modalService.openEx(type, this.viewContainer, settings, providers);
    };
    ModalAnchorDirective.prototype.closeWindow = function (uniqId) {
        this.modalService.closeWindow(uniqId);
    };
    ModalAnchorDirective.prototype.subscribeOnOpen = function (callback) {
        Assert.isNotNull(callback, 'callback');
        this.modalService.subscribeOnOpen(callback);
    };
    ModalAnchorDirective.prototype.subscribeOnClose = function (callback) {
        Assert.isNotNull(callback, 'callback');
        this.modalService.subscribeOnClose(callback);
    };
    ModalAnchorDirective.prototype.openDialog2 = function (type, title, options, providers, callback) {
        var _this = this;
        var modalSettings = this.modalService.createDefaultModalSettings();
        modalSettings.maximized = false;
        if (screenUtils.isMobile && options.fullHeightOnMobile) {
            options.height = screen.height;
            options.minWidth = null;
        }
        else {
            switch (options.modeSize) {
                case DialogModeSize.grid:
                    modalSettings.height = '70%';
                    modalSettings.width = '85%';
                    modalSettings.minHeight = options.minHegiht ? options.minHegiht : 400;
                    break;
                case DialogModeSize.dialog:
                    modalSettings.height = '30%';
                    modalSettings.width = '30%';
                    modalSettings.minHeight = options.minHegiht ? options.minHegiht : 220;
                    break;
                case DialogModeSize.vertical:
                    modalSettings.height = '70%';
                    modalSettings.width = '45%';
                    modalSettings.minHeight = 400;
                    break;
                default:
                    modalSettings.height = options.height;
                    modalSettings.width = options.width;
                    modalSettings.minHeight = options.minHegiht ? options.minHegiht : undefined;
            }
            modalSettings.minWidth = options.minWidth ? options.minWidth : 450;
        }
        modalSettings.topmost = options.topmost;
        modalSettings.zIndexForTopMost = options.zIndexForTopMost;
        if ((options.width !== undefined && screen.width < options.width) || (options.height !== undefined && screen.height < options.height)) {
            modalSettings.maximized = true;
        }
        modalSettings.maxHeight = screen.height;
        modalSettings.maxWidth = 1700;
        modalSettings.resizable = false;
        modalSettings.title = title;
        modalSettings.actions = [ModalBehavior.close];
        modalSettings.showCloseButton = true;
        modalSettings.className = options.className ? options.className : 'slx-blue-theme-2';
        var rp;
        if (providers) {
            rp = providers;
        }
        else {
            rp = [{ provide: DialogOptions, useValue: options }];
        }
        var dialog;
        var ref;
        ref = this.modalService.openEx(type, this.viewContainer, modalSettings, rp);
        options.windowUniqueId = ref.uniqueId;
        dialog = ref.reference;
        if (modalSettings.topmost) {
            this.m_renderer.addClass(this.viewContainer.element.nativeElement, 'topmost-modal-overlay');
        }
        else if (modalSettings.modal) {
            this.m_renderer.addClass(this.viewContainer.element.nativeElement, 'modal-overlay');
        }
        this.modalService.subscribeOnClose(function (result) {
            var uniqueId = result.uniqId;
            var count = _this.modalService.getOpenedDialogsCount();
            if (options.windowUniqueId === uniqueId) {
                if (modalSettings.topmost) {
                    _this.m_renderer.removeClass(_this.viewContainer.element.nativeElement, 'topmost-modal-overlay');
                }
                else {
                    _this.m_renderer.removeClass(_this.viewContainer.element.nativeElement, 'modal-overlay');
                }
                if (callback) {
                    callback(dialog.dialogResult, options.windowUniqueId);
                }
            }
        });
        return dialog;
    };
    return ModalAnchorDirective;
}());
export { ModalAnchorDirective };
