import { EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Credentials } from '../../models/index';
import { User, AuthResponse, AuthStatusCode } from '../../../state-model/models/index';
import { AuthenticationService } from '../../services/index';
import { LoggerService } from '../../../core/services/index';
import { Assert, StringUtils } from '../../../framework/index';
import { StatusCodes } from '../../../core/models/index';
import { authenticationConfig } from '../../authentication.config';
import { VersionSubscribeService } from '../../../common/index';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { UserMenuPinService } from '../../../core/services/user-menu-pin/user-menu-pin.service';
import { NotificationsApiService } from '../../../../app/portal/services/notifications/notifications-api.service';
var LoginFormComponent = /** @class */ (function () {
    function LoginFormComponent(authenticationService, loggerService, router, route, versionSubscriber, userActivityService, userMenuPinService, messageCenterNotification) {
        this.authenticationService = authenticationService;
        this.loggerService = loggerService;
        this.router = router;
        this.route = route;
        this.versionSubscriber = versionSubscriber;
        this.userActivityService = userActivityService;
        this.userMenuPinService = userMenuPinService;
        this.messageCenterNotification = messageCenterNotification;
        this.isLockedByUser = false;
        this.showLogoutButton = false;
        this.handleLoginRedirection = true;
        Assert.isNotNull(route, 'route');
        Assert.isNotNull(router, 'router');
        Assert.isNotNull(loggerService, 'loggerService');
        Assert.isNotNull(authenticationService, 'authenticationService');
        this.onAuthenticated = new EventEmitter();
        this.authenticationFailed = new EventEmitter();
        this.logout = new EventEmitter();
        this.credentials = new Credentials();
        this.state = {
            isLoading: false,
            isInvalidCredentials: false,
            isInvalidLogin: false,
            isConflictError: false,
            isUnknownError: false,
            showPassword: false,
            isInValidIPAddress: false
        };
    }
    Object.defineProperty(LoginFormComponent.prototype, "username", {
        set: function (value) {
            this.credentials.username = value;
        },
        enumerable: true,
        configurable: true
    });
    LoginFormComponent.prototype.onLogin = function () {
        var _this = this;
        this.state.isLoading = true;
        this.state.isInvalidCredentials = false;
        this.state.isInvalidLogin = false;
        this.state.isConflictError = false;
        this.state.isUnknownError = false;
        this.state.showPassword = false;
        var usernameAndAlias = this.credentials.username ? this.credentials.username.split(authenticationConfig.login.aliasSeparator) : [''];
        var username = usernameAndAlias[0];
        var alias = usernameAndAlias.length === 2 ? usernameAndAlias[1] : '';
        this.authenticationService.authenticate(username, this.credentials.password, alias, !this.isLockedByUser)
            .then(function (authResponse) {
            _this.onAuthenticationSucceeded(authResponse);
        })
            .catch(function (error) { return _this.onAuthenticationFailed(error); });
    };
    LoginFormComponent.prototype.onLogout = function () {
        this.logout.emit(this.credentials ? this.credentials.username : undefined);
    };
    LoginFormComponent.prototype.hideInvalidCredentialsErrorMessage = function () {
        this.state.isInvalidCredentials = false;
    };
    LoginFormComponent.prototype.hideInvalidLoginErrorMessage = function () {
        this.state.isInvalidLogin = false;
    };
    LoginFormComponent.prototype.hideConflictErrorMessage = function () {
        this.state.isConflictError = false;
    };
    LoginFormComponent.prototype.hideUnknownErrorMessage = function () {
        this.state.isUnknownError = false;
    };
    LoginFormComponent.prototype.togglePasswordVisibility = function () {
        this.state.showPassword = !this.state.showPassword;
    };
    LoginFormComponent.prototype.loginChanged = function () {
        if (!!this.credentials.password) {
            this.credentials.password = '';
        }
    };
    LoginFormComponent.prototype.onAuthenticationSucceeded = function (authResponse) {
        Assert.isNotNull(authResponse, 'authResponse');
        this.messageCenterNotification.buildConnection();
        this.messageCenterNotification.startConnection();
        if (authResponse.statusCode === AuthStatusCode.valid) {
            Assert.isNotNull(authResponse.session, 'session');
            this.onAuthenticated.emit(authResponse.session.user);
            if (this.handleLoginRedirection) {
                this.redirectLoggedInUser();
            }
        }
        else if (authResponse.statusCode === AuthStatusCode.resetRequested || authResponse.statusCode === AuthStatusCode.passwordExpired) {
            var usernameAndAlias = this.credentials.username ? this.credentials.username.split(authenticationConfig.login.aliasSeparator) : [''];
            var username = usernameAndAlias[0];
            var alias = usernameAndAlias.length === 2 ? usernameAndAlias[1] : '';
            this.authenticationService.navigateToResetPassword(username, alias);
        }
        else if (authResponse.statusCode === AuthStatusCode.invalidUserOrPassword) {
            this.state.isLoading = false;
            this.state.isInvalidCredentials = true;
        }
        //
        else if (authResponse.statusCode === AuthStatusCode.invalidIPAddress) {
            this.state.isLoading = false;
            this.state.isInvalidCredentials = true;
            this.redirectInavlidIPAddress();
        }
    };
    LoginFormComponent.prototype.redirectInavlidIPAddress = function () {
        // let username:null;let alias:null;
        this.authenticationService.navigateToInvalidIPAddress();
    };
    LoginFormComponent.prototype.redirectLoggedInUser = function () {
        this.userActivityService.start();
        this.userMenuPinService.getUserMenuPinStatus();
        if (this.authenticationService.isAliasChanged) {
            this.router.navigate(['apps']);
            return;
        }
        var returnUrl = this.route.snapshot.queryParams[authenticationConfig.login.returnUrlQueryParameter];
        var returnQs = this.route.snapshot.queryParams[authenticationConfig.login.returnUrlQueryStringParameter];
        var queryParams = {};
        if (!StringUtils.isNullOrEmpty(returnQs)) {
            var params_1 = new HttpParams({
                fromString: returnQs
            });
            params_1.keys().forEach(function (value) {
                queryParams[value] = params_1.get(value);
            });
        }
        var url = StringUtils.isNullOrEmpty(returnUrl) ? authenticationConfig.login.defaultReturnUrl : returnUrl;
        var navigationExtras = {
            queryParams: queryParams
        };
        this.versionSubscriber.get();
        this.router.navigate([url], navigationExtras);
    };
    LoginFormComponent.prototype.onAuthenticationFailed = function (error) {
        Assert.isNotNull(error, 'error');
        this.state.isLoading = false;
        switch (error.status) {
            case StatusCodes.unauthorized:
                this.state.isInvalidCredentials = true;
                break;
            case StatusCodes.notFound:
                this.state.isInvalidLogin = true;
                break;
            case StatusCodes.conflict:
                this.responseErrorMessage = error.meta.error;
                this.state.isConflictError = true;
                break;
            case StatusCodes.inValidIPAddress:
                this.responseErrorMessage = error.meta.error;
                this.state.isInValidIPAddress = true;
                break;
            default:
                this.state.isUnknownError = true;
                this.loggerService.error('Unexpected error during authentication', error);
                break;
        }
        this.authenticationFailed.emit();
    };
    return LoginFormComponent;
}());
export { LoginFormComponent };
