import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';
var KendoGridStringFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridStringFilterComponent, _super);
    function KendoGridStringFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        _this.localization = localization;
        var slxFilters = _.map([], function (filter) { return filter.key; });
        var slxFiltersKeysMap = _.keyBy(slxFilters, function (key) { return key; });
        var stringOperators = _this.localizeOperators(Object.assign(slxFiltersKeysMap, {
            'filterEqOperator': 'eq',
            'filterNotEqOperator': 'neq',
            'filterContainsOperator': 'contains',
            'filterNotContainsOperator': 'doesnotcontain',
            'filterStartsWithOperator': 'startswith',
            'filterEndsWithOperator': 'endswith',
            'filterIsEmptyOperator': 'isempty',
            'filterIsNotEmptyOperator': 'isnotempty'
        }));
        _this.showOperators = true;
        _this.operator = 'contains';
        _this.defaultOperators = stringOperators(_this.localization);
        return _this;
    }
    return KendoGridStringFilterComponent;
}(KendoGridBasicFilterComponent));
export { KendoGridStringFilterComponent };
