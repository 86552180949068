import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { appConfig } from '../../../../app.config';
import { TimeclockDailySummary } from '../../../../organization/models/index';
import { TimeClock } from './../../../../../app/time-and-attendance/models';
import { ModalService } from './../../../../../app/common';
import { TimeclockRebootDialogComponent } from './timeclock-reboot-dialog/timeclock-reboot-dialog.component';
import { TimeclockDataService, } from '../../../../organization/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { TaSignalrService } from '../../../../../app/time-and-attendance/services/signalR/ta-signalr-service';
import { SessionService } from '../../../../../app/core/services';
var TimeclockItemComponent = /** @class */ (function () {
    function TimeclockItemComponent(modalService, timeclockDataService, taSignalrService, sessionService) {
        this.taSignalrService = taSignalrService;
        this.sessionService = sessionService;
        this.expandable = true;
        this.modalService = modalService;
        this._timeclockDataService = timeclockDataService;
        this.rebootApiStatus = false;
    }
    Object.defineProperty(TimeclockItemComponent.prototype, "expanded", {
        get: function () {
            return !this.expandable || this.m_expanded;
        },
        enumerable: true,
        configurable: true
    });
    TimeclockItemComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    TimeclockItemComponent.prototype.toggleExpand = function () {
        this.m_expanded = !this.m_expanded;
    };
    TimeclockItemComponent.prototype.clockRebootDialog = function (clockId, clockName) {
        var _this = this;
        var timeClock = new TimeClock();
        timeClock.clockId = clockId;
        timeClock.clockName = clockName;
        timeClock.isClockRebootStarted = this.timeclockDailySummary.isTimeClockRebootStarted;
        TimeclockRebootDialogComponent.openDialog(timeClock, this.modalService, function (result, request) {
            if (result) {
                _this.rebootApiStatus = true;
                _this.timeclockDailySummary.isTimeClockRebootStarted = true;
                var responce = _this.rebootClockService(_this._timeclockDataService.orgLevel.id, clockId);
                _this._timeclockDataService.rebootApiStatus.subscribe(function (message) {
                    if (_this.timeclockDailySummary.id === clockId)
                        _this.timeclockDailySummary.isTimeClockRebootStarted = message;
                });
            }
        });
    };
    TimeclockItemComponent.prototype.rebootClockService = function (orgLevelId, clockId) {
        var response = this._timeclockDataService.rebootClock(orgLevelId, clockId);
        if (response) {
            return response;
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockItemComponent.prototype, "isClockRebooted", void 0);
    return TimeclockItemComponent;
}());
export { TimeclockItemComponent };
