export interface IPBJExportCSV {
  Error_ID: number;
  Error_Description: string;
  Location:string;

}

export class PBJExportCSV {
  public Error_ID: number;
  public Error_Description: string;
  public Location:string;
}
