import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../../common/index';
import { ModalService } from '../../../../../common/services/index';
import { ScheduleCycleHelperService } from '../../../../../organization/services/index';
import { ScheduleCycleScope, ScheduleCycle } from '../../../../../organization/models/index';
import { RotationModifyRequest } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-rotation-modify-dialog-dialog',
  templateUrl: 'rotation-modify-dialog.component.html',
  styleUrls: ['rotation-modify-dialog.component.scss']
})
export class RotationModifyDialogComponent implements IDialog {


  public dialogResult: boolean;
  public currentScheduleCycle: ScheduleCycleScope = ScheduleCycleScope.currentScheduleCycle;
  public nextScheduleCycle: ScheduleCycleScope = ScheduleCycleScope.nextScheduleCycle;
  public selectedScheduleCycle: ScheduleCycle;
  public appConfig: IApplicationConfig;



  public static openDialog(request: RotationModifyRequest, modalService: ModalService, callback: (result: boolean, req: RotationModifyRequest) => void): RotationModifyDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 300;
    dialogOptions.width = 600;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: RotationModifyRequest,
        useValue: request
      }
    ];

    let dialog: RotationModifyDialogComponent = modalService.globalAnchor
      .openDialog(RotationModifyDialogComponent,
        `Employee rotation has been modified`,
        dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
            callback(result, dialog.request);
        });
    return dialog;
  }

  constructor(private options: DialogOptions,
    private modalService: ModalService,
    private scheduleCycleHelperService: ScheduleCycleHelperService,
    public request: RotationModifyRequest
  ) {
    this.appConfig = appConfig;
    this.onSelectionChanged(request.regenerateScope);
  }

  public onSelectionChanged(scope: ScheduleCycleScope): void {
    this.scheduleCycleHelperService.getScheduleCycleByScope(scope, this.request.homePositionOrgLevelId)
      .then((cycle: ScheduleCycle) => {
        this.selectedScheduleCycle = cycle;
      });
  }

  public onYes(): void {
    this.request.reGenerateSchedule = true;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onNo(): void {
    this.request.reGenerateSchedule = false;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
