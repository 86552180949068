export interface IPayUnitDefinition {
  id: number;
  name: string;
  departmentId: number;
  organizationId: number;
}

export class PayUnitDefinition {
  id: number;
  name: string;
  departmentId: number;
  organizationId: number;

}

export function isNilPayUnit(p: PayUnitDefinition): boolean {
  return !p || p.id === 0;
}
