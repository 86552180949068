import * as tslib_1 from "tslib";
import { appConfig } from './../../../app.config';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ColumnSettingsDefinitions } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ColumnSettingsStorageService } from '../../../common/services/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { StateManagementService } from '../../../common/services/index';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { StateResetTypes } from '../../../core/models/settings/index';
import { ActivatedRoute } from '@angular/router';
import { PunchesRollupState, PunchesRollupRecord } from '../../models/index';
import { PunchesApiService } from './punches-api.service';
import { PunchUtils } from '../../utils/punch-utils';
var PunchesRollupManagementService = /** @class */ (function () {
    function PunchesRollupManagementService(apiService, columnSettingsStorageService, stateService, storageService, activatedRoute) {
        this.apiService = apiService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.stateService = stateService;
        this.storageService = storageService;
        this.activatedRoute = activatedRoute;
        this.m_resetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.m_dateFiltersControlId = 'DateFiltersNgx';
        this.initialize();
    }
    PunchesRollupManagementService.prototype.initialize = function () {
        var _this = this;
        this.stateService.init('PunchesRollupComponent', false);
        this.onLoadStatus$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
        this.onLoaded$ = new ReplaySubject(1);
        this.routeSubscripion = this.activatedRoute.queryParams
            .subscribe(function (queryParams) {
            var sd;
            var ed;
            if (queryParams['startDate'] && queryParams['endDate']) {
                _this.startDate = moment(queryParams['startDate'], appConfig.linkDateFormat).toDate();
                _this.endDate = moment(queryParams['endDate'], appConfig.linkDateFormat).toDate();
            }
            else {
                _this.restoreFilters();
            }
        });
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.currentOrgLevel || o && _this.currentOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            _this.onOrgLevelChanged(o);
        });
        this.state = new PunchesRollupState();
        this.state.createColumns();
        this.state.mapColumns();
        this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.PUNCHES_ROLLUP.toString(), undefined, this.state.columns);
        this.onStateChanged(this.state);
    };
    PunchesRollupManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchesRollupManagementService.prototype.onStateChanged = function (state) {
        this.onStateChanged$.next(state);
        this.columnSettingsStorageService.setColumnsState(ColumnSettingsDefinitions.PUNCHES_ROLLUP.toString(), undefined, this.state.columns);
    };
    PunchesRollupManagementService.prototype.onLoadStatusChanged = function (isLoading) {
        this.onLoadStatus$.next(isLoading);
    };
    PunchesRollupManagementService.prototype.loadPunchesRollupRecords = function (orgLevelId, startDate, endDate) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getPunchesRollupRecords(orgLevelId, startDate, endDate)
            .then(function (records) {
            var grouped = _this.needShowDepartments ? _this.group(records) : records;
            _this.onLoaded(grouped);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onLoaded([]);
            _this.onLoadStatusChanged(false);
        });
    };
    PunchesRollupManagementService.prototype.onLoaded = function (records) {
        this.onLoaded$.next(records);
    };
    PunchesRollupManagementService.prototype.setDates = function (startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadRecords();
        this.saveFilters();
    };
    PunchesRollupManagementService.prototype.getOrgLevelTitle = function () {
        if (this.needShowDepartments) {
            return 'Department';
        }
        else {
            return 'Home Organization';
        }
    };
    Object.defineProperty(PunchesRollupManagementService.prototype, "needShowDepartments", {
        get: function () {
            return this.currentOrgLevel.type === OrgLevelType.organization;
        },
        enumerable: true,
        configurable: true
    });
    PunchesRollupManagementService.prototype.onOrgLevelChanged = function (o) {
        if (!PunchUtils.isRollup(o)) {
            return;
        }
        this.currentOrgLevel = o;
        this.loadRecords();
    };
    PunchesRollupManagementService.prototype.loadRecords = function () {
        if (this.currentOrgLevel && this.startDate && this.endDate) {
            this.loadPunchesRollupRecords(this.currentOrgLevel.id, this.startDate, this.endDate);
        }
    };
    PunchesRollupManagementService.prototype.group = function (records) {
        var totals = [];
        var dict = _.groupBy(records, function (record) {
            return record.payCycle.description;
        });
        _.forEach(dict, function (items) {
            var rec = new PunchesRollupRecord();
            rec.payCycle = items[0].payCycle;
            rec.approvedTimecards = _.sumBy(items, function (item) { return item.approvedTimecards; }) / items.length;
            rec.validTimecards = _.sumBy(items, function (item) { return item.validTimecards; });
            rec.missingPunches = _.sumBy(items, function (item) { return item.missingPunches; });
            rec.pendingEmpRequest = _.sumBy(items, function (item) { return item.pendingEmpRequest; });
            rec.invalidPunches = _.sumBy(items, function (item) { return item.invalidPunches; });
            rec.scheduledPunches = _.sumBy(items, function (item) { return item.scheduledPunches; });
            totals.push(rec);
        });
        return totals;
    };
    PunchesRollupManagementService.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateService.componentKey, this.m_dateFiltersControlId, {
            value: { startDate: this.startDate, endDate: this.endDate }
        }, this.m_resetType, this.stateKey);
        this.stateService.controlValueChanged(this.m_dateFiltersControlId);
    };
    PunchesRollupManagementService.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateService.componentKey, this.m_dateFiltersControlId);
        if (state && state.value) {
            if (state.value.startDate)
                this.startDate = moment(state.value.startDate).toDate();
            if (state.value.endDate)
                this.endDate = moment(state.value.endDate).toDate();
        }
        else {
            this.endDate = new Date();
            this.endDate.setHours(0, 0, 0, 0);
            this.startDate = moment().subtract(14, 'days').toDate();
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PunchesRollupManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesRollupManagementService.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesRollupManagementService.prototype, "routeSubscripion", void 0);
    return PunchesRollupManagementService;
}());
export { PunchesRollupManagementService };
