import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TimeclockAssignmentManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
var TimeclockAssignmentComponent = /** @class */ (function () {
    function TimeclockAssignmentComponent(timeclockAssignmentManagementService) {
        this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
        this.state = {
            isLoading: false,
        };
    }
    TimeclockAssignmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadStatusSubscription = this.timeclockAssignmentManagementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
    };
    TimeclockAssignmentComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentComponent.prototype, "loadStatusSubscription", void 0);
    return TimeclockAssignmentComponent;
}());
export { TimeclockAssignmentComponent };
