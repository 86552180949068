
   <div class="restriction-block">
        <div class="row panels">
                <div class="col-md-4"></div>
                <div class="col-md-4 restriction-container">
                   <div class="row">
                        <p class="group">
                                <img  src="/assets/img/login/Group.png" alt="Smartlinx">
                              </p>
                              <p class="slx-logo-6-1-blue">
                                    <img  src="/assets/img/login/slx-logo-6-1-blue.png" alt="Smartlinx">
                              </p>
                              
                              <div class="invalidip-content">
                                  This device's IP Address has been restricted. <br/>
                                  Please contact your administrator for support.
                              </div>
                             
                            </div>
                            <div class="col-xs-12">
                                    <button type="button" class="theme-button-apply back-button"  (click)="navigateToLogin()" tabindex="4">
                                   
                                      Go Back
                                    </button>
                                  </div>

            </div>
                <div class="col-md-4"></div>
              </div>
     
</div>