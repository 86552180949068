<div class="payroll-export-test-section">
  <div class="payroll-export-section-header">
    <div class="payroll-export-section-header-button-left">
      <slx-collapse-button [(isCollapsed)]="isCollapsed"
        (isCollapsedChange)="collapsedChange($event)"
        [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
    <div class="payroll-export-section-header-content">
      <div class="header-item header-title">
        <i class="fal fa-building header-title__icon regular-icon" aria-hidden="true"></i>
        <i class="fas fa-check-circle header-title__icon submitted-icon" aria-hidden="true"></i>
        <span class="header-title__text">{{organization.organization.name}}</span>
      </div>
      <div class="header-item button-item" >
        <div class="button-item-bar-header">
          <button class="button slx-button slx-with-icon add-template-btn"
            (click)="runTests()" [disabled]="isLoading || organization.isExported">
            <i class="icon green slx-button__icon fas fa-play" aria-hidden="true"></i>
            Run Tests
          </button>
          <button class="button slx-button slx-with-icon add-template-btn"
            *ngIf="!manageService.isRequestExport"
            [disabled]="isLoading || !manageService.canExportToPayroll || !organization.canExportToPayroll || organization.isCheckingExportStatus" (click)="exportToPayroll()">
            <i class="icon slx-button__icon fas fa-file-export" aria-hidden="true"></i>
            Export to Payroll
          </button>
          <button class="button slx-button slx-with-icon add-template-btn"
            *ngIf="manageService.isRequestExport"
            [disabled]="isLoading || !manageService.canExportToPayroll || !organization.canExportToPayroll" (click)="requestExport()">
            <i class="icon slx-button__icon fas fa-file-export" aria-hidden="true"></i>
            Request Export
          </button>
          <button class="button slx-button slx-with-icon add-template-btn"
            *ngIf="!organization.isLocked"
            [disabled]="isLoading || !manageService.canLockTimecards" (click)="lockOrganizationPayroll()">
            <i class="icon orange slx-button__icon fas fa-lock" aria-hidden="true"></i>
            Lock
          </button>
          <button class="button slx-button slx-with-icon add-template-btn unlock-button"
            *ngIf="organization.isLocked"
            [disabled]="isLoading || organization.hasReleasedExports || !manageService.canUnlockTimecards || organization.isCheckingExportStatus" (click)="unlockOrganizationPayroll()">
            <i class="icon orange slx-button__icon fas fa-unlock" aria-hidden="true"></i>
            Unlock
            <span *ngIf="organization.hasReleasedExports" slxTooltip="An authorized user must first Cancel Release from Payroll Export Management screen" tipPosition="bottom"></span>
            <span *ngIf="organization.hasReleasedExports" class="unlock-hint">Export has been released</span>
          </button>
        </div>

      </div>
      <div class="header-item status-panel" *ngIf="!isTAExportNGPChanges()">
        <div class="status" *ngIf="organization.state === 0">
          <span class="status-label blue">
            <div><i class="icon fas fa-spinner" aria-hidden="true"></i></div>
            In Progress
          </span>
        </div>
        <div class="status" *ngIf="organization.state === 1">
          <span class="status-label orange  ">
            <div><i class="icon fas fa-edit" aria-hidden="true"></i></div>
            Re-opened
          </span>
        </div>
        <div class="status" *ngIf="organization.state === 2">
          <span class="status-label green">
            <i class="icon fas fa-check-circle" aria-hidden="true"></i>
            Exported
          </span>
          <span class="sub-label">
              {{ organization.runAt  | amDateFormat: appConfig.dateFormat }} by {{organization.runByUser}}
          </span>
        </div>
        <div class="status" *ngIf="organization.state === 5">
          <span class="status-label green">
            <i class="icon fas fa-hand-holding-usd" aria-hidden="true"></i>
            Ready for Payroll
          </span>
          <span class="sub-label">
              {{ organization.runAt  | amDateFormat: appConfig.dateFormat }} by {{organization.runByUser}}
          </span>
        </div>

        <div class="warning-icons">
            <span class="alert-panel" *ngIf="organization.failCount === 0 && organization.alertCount > 0">
              <span class="badge">{{organization.alertCount}}</span>
              <i class="fas fa-exclamation-circle icon" aria-hidden="true"></i>
            </span>
            <span class="fail-panel" *ngIf="organization.failCount > 0">
              <span class="badge">{{organization.failCount}}</span>
              <i class="fas fa-exclamation-triangle icon" aria-hidden="true"></i>
            </span>
        </div>
      </div>
      
      <div class="header-item status-panel" *ngIf="isTAExportNGPChanges()">
        <div class="status" *ngIf="organization.status === 0">
          <span class="status-label blue">
            <div><i class="icon fas fa-spinner" aria-hidden="true"></i></div>
            In Progress
          </span>
        </div>
        <div class="status" *ngIf="organization.status === 1">
          <span class="status-label green">
            <div><i class="icon fas fa-check-circle" aria-hidden="true"></i></div>
            Export Success
          </span>
          <span class="sub-label">
              {{ organization.runAt  | amDateFormat: appConfig.dateFormat }} by {{organization.runByUser}}
          </span>
        </div>
        <div class="status" *ngIf="organization.status === 2">
          <span class="status-label red">
            <i class="icon fas fa-info-circle" aria-hidden="true"></i>
            Export Failed
          </span>
          <span class="sub-label">
              {{ organization.runAt  | amDateFormat: appConfig.dateFormat }} by {{organization.runByUser}}
          </span>
        </div>
        <div class="status" *ngIf="organization.status === 3">
          <span class="status-label orange">
            <div><i class="icon fas fa-edit" aria-hidden="true"></i></div>
            Re-opened
          </span>
        </div>

        <div class="warning-icons">
            <span class="alert-panel" *ngIf="organization.failCount === 0 && organization.alertCount > 0">
              <span class="badge">{{organization.alertCount}}</span>
              <i class="fas fa-exclamation-circle icon" aria-hidden="true"></i>
            </span>
            <span class="fail-panel" *ngIf="organization.failCount > 0">
              <span class="badge">{{organization.failCount}}</span>
              <i class="fas fa-exclamation-triangle icon" aria-hidden="true"></i>
            </span>
        </div>
      </div>

    </div>
    <div class="payroll-export-section-header-button-right">
      <slx-collapse-button [(isCollapsed)]="isCollapsed" (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
  </div>
  <div #collapseContent class="payroll-export-section-content">
    <slx-payroll-export-test-grid [tests]="tests" [isAccrualModuleEnabled]="isAccrualModuleEnabled"  (testClicked)="onTestClicked($event)"></slx-payroll-export-test-grid>
  </div>
</div>
