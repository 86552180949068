import { appConfig } from './../../../../app.config';
import { ShiftSwapProfile } from '../../../models/shift-swap/shift-swap-info';
var ShiftSwapListItemProfileComponent = /** @class */ (function () {
    function ShiftSwapListItemProfileComponent() {
        this.miniProfile = false;
        this.appConfig = appConfig;
    }
    Object.defineProperty(ShiftSwapListItemProfileComponent.prototype, "profileExists", {
        get: function () {
            return this.profile && this.profile.id > 0;
        },
        enumerable: true,
        configurable: true
    });
    return ShiftSwapListItemProfileComponent;
}());
export { ShiftSwapListItemProfileComponent };
