import { ScheduleAbsence, IScheduleAbsence } from '../../../organization/models/index';

export interface IVacationPlanEmployeeSummaryRecord {
  date: string;
  scheduleAbsence: IScheduleAbsence;
  hours: number;
}

export class VacationPlanEmployeeSummaryRecord {
  public date: Date;
  public scheduleAbsence: ScheduleAbsence;
  public hours: number;
}
