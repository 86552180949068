import {
  IEmployeePositionDefinition, EmployeePositionDefinition,
  IUserProfileDefinition, UserProfileDefinition,
  ISupervisorGroupDefinition, SupervisorGroupDefinition

} from '../../../organization/index';

export interface ISupervisor {
  id: number;
  user: IUserProfileDefinition;
  role: string;
  approvePTO: boolean;
  punchOverride: boolean;
  isPrimary: boolean;
  employee: IEmployeePositionDefinition;
  groups: ISupervisorGroupDefinition[];
}

export class Supervisor {
  public id: number;
  public user: UserProfileDefinition;
  public role: string;
  public approvePTO: boolean;
  public punchOverride: boolean;
  public isPrimary: boolean;
  public employee: EmployeePositionDefinition;
  public groups: SupervisorGroupDefinition[];
  public isSelected: boolean;
  public selectable: boolean = true;

  public get hasEmployee(): boolean {
    return !!(this.employee && this.employee.employee && this.employee.employee.id > 0);
  }
}
