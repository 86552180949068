import * as moment from 'moment';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { Organization } from '../../../organization/models/index';

import { HandledError, ResponseBody, StatusCodes } from '../../../core/models/index';

import { AgencyMapping, AgencyModel, MappingRequest } from '../../models/index';

import { AgenciesApiService } from './agencies-api.service';
import { AgenciesMapService } from './agencies-map.service';

@Injectable()
export class AgencyDetailsManagementService {

    public get onRemove$(): ReplaySubject<MappingRequest> {
        return this.m_onRemove;
    }

    public get onRemoved$(): ReplaySubject<MappingRequest> {
        return this.m_onRemoved;
    }

    public get onRemoveError$(): ReplaySubject<MappingRequest> {
        return this.m_onRemoveError;
    }

    public get onAdd$(): ReplaySubject<MappingRequest> {
        return this.m_onAdd;
    }

    public get onAdded$(): ReplaySubject<MappingRequest> {
        return this.m_onAdded;
    }
    public get onAddError$(): ReplaySubject<MappingRequest> {
        return this.m_onAddError;
    }

    public get onUpdate$(): ReplaySubject<AgencyMapping> {
        return this.m_onUpdate;
    }

    public get onUpdated$(): ReplaySubject<AgencyMapping> {
        return this.m_onUpdated;
    }
    public get onUpdateError$(): ReplaySubject<AgencyMapping> {
        return this.m_onUpdateError;
    }

    private m_onRemove: ReplaySubject<MappingRequest>;
    private m_onRemoveError: ReplaySubject<MappingRequest>;
    private m_onRemoved: ReplaySubject<MappingRequest>;

    private m_onAdd: ReplaySubject<MappingRequest>;
    private m_onAddError: ReplaySubject<MappingRequest>;
    private m_onAdded: ReplaySubject<MappingRequest>;

    private m_onUpdate: ReplaySubject<AgencyMapping>;
    private m_onUpdateError: ReplaySubject<AgencyMapping>;
    private m_onUpdated: ReplaySubject<AgencyMapping>;

    constructor(private api: AgenciesApiService, private mapService: AgenciesMapService) {

        this.m_onAdd = new ReplaySubject<MappingRequest>();
        this.m_onAdded = new ReplaySubject<MappingRequest>();
        this.m_onAddError = new ReplaySubject<MappingRequest>();

        this.m_onRemove = new ReplaySubject<MappingRequest>();
        this.m_onRemoved = new ReplaySubject<MappingRequest>();
        this.m_onRemoveError = new ReplaySubject<MappingRequest>();

        this.m_onUpdate = new ReplaySubject<AgencyMapping>();
        this.m_onUpdated = new ReplaySubject<AgencyMapping>();
        this.m_onUpdateError = new ReplaySubject<AgencyMapping>();
    }

    public saveAgencyInfo(orglLevelId: number, mapping: AgencyMapping): void {

        this.m_onUpdate.next(mapping);

        this.api.updateAgency(orglLevelId, mapping.agency).then(() => {
            this.m_onUpdated.next(mapping);
        }).catch((result: any) => {
            this.m_onUpdateError.next(mapping);
        });
    }

    public cloneAgencyMapping(mapping: AgencyMapping): AgencyMapping {
        return this.mapService.cloneAgencyMapping(mapping);
    }

    public removeOrganization(agencyMapping: AgencyMapping, organization: Organization): void {

        let request: MappingRequest = new MappingRequest();
        request.isRemove = true;
        request.mapping = agencyMapping;
        request.organization = organization;

        this.m_onRemove.next(request);
        this.api.unassignOrganizations(agencyMapping.agency, [organization]).then((any) => {
            agencyMapping.organizations = _.without(agencyMapping.organizations, organization);
            this.onRemoved$.next(request);
        }).catch((result: any) => {
            this.onRemoveError$.next(request);
        });
    }

    public addOrganization(agencyMapping: AgencyMapping, organization: Organization): void {

        let request: MappingRequest = new MappingRequest();
        request.mapping = agencyMapping;
        request.organization = organization;
        this.m_onRemove.next(request);

        this.api.assignOrganizations(agencyMapping.agency, [organization]).then((any) => {
            agencyMapping.organizations.push(organization);
            this.onAdded$.next(request);
        }).catch((result: any) => {
            this.onAddError$.next(request);
        });
    }

}
