/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pm-config-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../common/components/columns-config-button/columns-config-button.component.ngfactory";
import * as i4 from "../../../../../common/components/columns-config-button/columns-config-button.component";
import * as i5 from "../../../../../common/services/column-settings/column-settings-storage.service";
import * as i6 from "../../../../../common/services/column-settings/column-management.service";
import * as i7 from "../../../../../app-settings/services/app-settings-manage.service";
import * as i8 from "../../../../../components-library/components/action-list/action-list/action-list.component.ngfactory";
import * as i9 from "../../../../../components-library/components/action-list/action-list/action-list.component";
import * as i10 from "../../../../../components-library/components/action-list/action-button/action-button.component.ngfactory";
import * as i11 from "../../../../../components-library/components/action-list/action-button/action-button.component";
import * as i12 from "../../../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i13 from "ngx-popper";
import * as i14 from "../../../../../components-library/components/action-list/action-list-item/action-list-item.component.ngfactory";
import * as i15 from "../../../../../components-library/components/action-list/action-list-item/action-list-item.component";
import * as i16 from "../../../../../common/components/toolbar/toolbar.component.ngfactory";
import * as i17 from "../../../../../common/components/toolbar/toolbar.component";
import * as i18 from "../../../../../core/services/toolbar/toolbar-base.service";
import * as i19 from "../../../../../common/directives/toolbar/toolbar-section-template.directive";
import * as i20 from "../../../services/pm-attachment-management.service";
import * as i21 from "../../../../../core/services/session/session.service";
import * as i22 from "./pm-config-toolbar.component";
import * as i23 from "../../../services/pm-configuration-management.service";
import * as i24 from "../../../../../common/services/device-detector/device-detector.service";
var styles_PmConfigToolbarComponent = [i0.styles];
var RenderType_PmConfigToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PmConfigToolbarComponent, data: {} });
export { RenderType_PmConfigToolbarComponent as RenderType_PmConfigToolbarComponent };
function View_PmConfigToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "pm-toolbar__control as-flex-start mt-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickCreate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-plus slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"]))], null, null); }
function View_PmConfigToolbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "pm-toolbar__control as-flex-start mt-15"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-plus slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "input", [["class", "attachments__input"], ["type", "file"]], [[8, "disabled", 0], [8, "accept", 0]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onResetErrors($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.acceptedFileTypesInputFormat; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
function View_PmConfigToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmConfigToolbarComponent_2)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmConfigToolbarComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isForCodes && _co.canCreateNew) && !_v.context.isCollapsed); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.isForTemplates && _co.canCreateNew) && !_v.context.isCollapsed); _ck(_v, 3, 0, currVal_1); }, null); }
function View_PmConfigToolbarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Unable to upload file. Max size for files is ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxFileSize; _ck(_v, 1, 0, currVal_0); }); }
function View_PmConfigToolbarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to upload file. Allowed types: Word, Excel, PDF and image files."]))], null, null); }
function View_PmConfigToolbarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [["class", "slx-error-block attachments__error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmConfigToolbarComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmConfigToolbarComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.maxFileSize; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors.fileType; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PmConfigToolbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmConfigToolbarComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PmConfigToolbarComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "pm-toolbar__control as-flex-start mt-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "slx-columns-config-button", [["title", "Columns settings"]], null, null, null, i3.View_ColumnsConfigButtonComponent_0, i3.RenderType_ColumnsConfigButtonComponent)), i1.ɵdid(2, 4374528, null, 0, i4.ColumnsConfigButtonComponent, [i5.ColumnSettingsStorageService, i6.ColumnManagementService, i1.ChangeDetectorRef, i7.AppSettingsManageService], { colGroupKey: [0, "colGroupKey"], title: [1, "title"], blueStyleConfig: [2, "blueStyleConfig"] }, null), i1.ɵpod(3, { btnClass: 0, iconClass: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnsStateName; var currVal_1 = "Columns settings"; var currVal_2 = _ck(_v, 3, 0, "slx-button slx-only-icon slx-toolbar", "fa fa-cog"); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PmConfigToolbarComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmConfigToolbarComponent_9)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.isCollapsed; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PmConfigToolbarComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "span", [["class", "pm-toolbar__control as-flex-start mt-16"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "slx-action-list", [], null, null, null, i8.View_ActionListComponent_0, i8.RenderType_ActionListComponent)), i1.ɵdid(2, 49152, null, 0, i9.ActionListComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 1, 2, "slx-action-button", [], null, null, null, i10.View_ActionButtonComponent_0, i10.RenderType_ActionButtonComponent)), i1.ɵdid(4, 49152, null, 0, i11.ActionButtonComponent, [], { popperContent: [0, "popperContent"], popperPosition: [1, "popperPosition"], iconName: [2, "iconName"] }, null), (_l()(), i1.ɵted(-1, 0, [" Actions"])), (_l()(), i1.ɵeld(6, 0, null, 1, 7, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_PopperContent_0, i12.RenderType_PopperContent)), i1.ɵdid(7, 180224, [["popperContent", 4]], 0, i13.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "slx-action-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onClickExport(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ActionListItemComponent_0, i14.RenderType_ActionListItemComponent)), i1.ɵdid(9, 49152, null, 0, i15.ActionListItemComponent, [i13.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, ["Export to Excel"])), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "slx-action-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onClickExport(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_ActionListItemComponent_0, i14.RenderType_ActionListItemComponent)), i1.ɵdid(12, 49152, null, 0, i15.ActionListItemComponent, [i13.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, ["Export to PDF"]))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 7); var currVal_1 = "bottom-end"; var currVal_2 = "fas fa-cog"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PmConfigToolbarComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PmConfigToolbarComponent_11)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.isCollapsed; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PmConfigToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "pm-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "slx-toolbar", [["class", "pm-toolbar__helper"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_ToolbarComponent_0, i16.RenderType_ToolbarComponent)), i1.ɵdid(2, 1228800, null, 1, i17.ToolbarComponent, [i1.ElementRef, [2, i18.TOOLBAR_SERVICE], i1.ChangeDetectorRef], { alignExpandButtonRight: [0, "alignExpandButtonRight"] }, null), i1.ɵqud(603979776, 1, { contentChildren: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_PmConfigToolbarComponent_1)), i1.ɵdid(5, 16384, [[1, 4]], 0, i19.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { alignMode: [0, "alignMode"], width: [1, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_PmConfigToolbarComponent_4)), i1.ɵdid(7, 16384, [[1, 4]], 0, i19.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { alignMode: [0, "alignMode"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_PmConfigToolbarComponent_8)), i1.ɵdid(9, 16384, [[1, 4]], 0, i19.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { alignMode: [0, "alignMode"], width: [1, "width"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_PmConfigToolbarComponent_10)), i1.ɵdid(11, 16384, [[1, 4]], 0, i19.ToolbarSectionTemplateDirective, [[2, i1.TemplateRef]], { alignMode: [0, "alignMode"], width: [1, "width"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 2, 0, currVal_0); var currVal_1 = "left"; var currVal_2 = _co.newBtnWidth; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = "left"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "rightIfNothingCollapsed"; var currVal_5 = "35"; _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_6 = "rightIfNothingCollapsed"; var currVal_7 = "95"; _ck(_v, 11, 0, currVal_6, currVal_7); }, null); }
export function View_PmConfigToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-pm-config-toolbar", [], null, null, null, View_PmConfigToolbarComponent_0, RenderType_PmConfigToolbarComponent)), i1.ɵprd(512, null, i20.PmAttachmentManagementService, i20.PmAttachmentManagementService, [i21.SessionService]), i1.ɵdid(2, 245760, null, 0, i22.PmConfigToolbarComponent, [i23.PmConfigurationManagementService, i20.PmAttachmentManagementService, i24.DeviceDetectorService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var PmConfigToolbarComponentNgFactory = i1.ɵccf("slx-pm-config-toolbar", i22.PmConfigToolbarComponent, View_PmConfigToolbarComponent_Host_0, { disabled: "disabled", itemClass: "itemClass", canCreateNew: "canCreateNew" }, {}, []);
export { PmConfigToolbarComponentNgFactory as PmConfigToolbarComponentNgFactory };
