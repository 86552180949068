import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { GeolocationManagementService } from '../../../services/index';
import { GeolocationEntity, GeolocationEvent, IGeolocationEvent } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-geolocation-toolbar',
  templateUrl: 'geolocation-toolbar.component.html',
  styleUrls: ['geolocation-toolbar.component.scss']
})
export class GeolocationToolbarComponent implements OnInit, OnDestroy {
  public canAdd: boolean;
  public canSave: boolean;
  public canUpdate: boolean;
  public canCancel: boolean;

  public actionsDisabled: boolean;

  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: GeolocationManagementService) {
    this.setDefaultSettings();
  }

  public ngOnInit(): void {
    this.subscriptions.entitySelected = this.manService.subscribeToEntitySelected((entity: GeolocationEntity) => {
      if (_.isObjectLike(entity)) {
        this.canAdd = false;
        this.canUpdate = true;
        this.canCancel = true;
      }
    });
    this.subscriptions.stateChanged = this.manService.subscribeToChangeToolbarMode((isActive: boolean) => {
      this.actionsDisabled = !isActive;
    });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onAdd(): void {
    this.canSave = this.canCancel = true;
    this.canAdd = this.canUpdate = false;

    this.manService.emitGeoEvent(GeolocationEvent.createAdd());
  }

  public onUpdate(): void {
    this.setDefaultSettings();
    this.manService.emitGeoEvent(GeolocationEvent.createUpdate());
  }

  public onSave(): void {
    this.setDefaultSettings();
    this.manService.emitGeoEvent(GeolocationEvent.createSave());
  }

  public onCancel(): void {
    this.setDefaultSettings();
    this.manService.emitGeoEvent(GeolocationEvent.createCancel());
  }

  public onExportToExcel(): void {
    this.manService.emitGeoEvent(GeolocationEvent.createExcel());
  }

  public onExportToPdf(): void {
    this.manService.emitGeoEvent(GeolocationEvent.createPDF());
  }

  private setDefaultSettings(): void {
    this.canAdd = true;
    this.canSave = false;
    this.canUpdate = false;
    this.canCancel = false;

    this.actionsDisabled = true;
  }
}
