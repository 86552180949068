
<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells slx-center-header"
[data]="gridState.view"
(dataStateChange)="gridState.dataStateChange($event)" [resizable]="true"
>
  <kendo-grid-column title="" field="employee.name" [width]="140">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip></span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="loa-console-emp" (click)="onClickEmployee(dataItem)">
        <img class="rounded-image loa-console-emp__img" [employeeThumbinalSrc]="dataItem.employee?.id" alt="Employee Photo" />
        <span class="loa-console-emp__text" [title]="dataItem.employee?.name">{{ dataItem.employee?.name }}</span>
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column-group title="Actual" [locked]="false">

        <kendo-grid-column
          title="Start"
          field="actStartDate"
          class="slx-text-center"
          classHeader="slx-text-center"
          [width]="65"
        >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Start</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.actStartDate | amDateFormat: appConfig.dateFormat">
            {{ dataItem.actStartDate | amDateFormat: appConfig.dateFormat }} </span>
          </ng-template>            
        </kendo-grid-column>
        
      <kendo-grid-column
          title="End"
          field="actEndDate"
          class="slx-text-center"
          classHeader="slx-text-center"
          [width]="65"
        >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>End</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [title]="dataItem.actEndDate | amDateFormat: appConfig.dateFormat">
            {{ dataItem.actEndDate | amDateFormat: appConfig.dateFormat }}</span>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="Estimated" [locked]="false">
        <kendo-grid-column
        title="Start Date"
        field="estStartDate"
        [width]="65"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Start</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.estStartDate | amDateFormat: appConfig.dateFormat">
          {{ dataItem.estStartDate | amDateFormat: appConfig.dateFormat }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="End"
        field="estEndDate"
        [width]="65"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>End</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [title]="dataItem.estEndDate | amDateFormat: appConfig.dateFormat">
          {{ dataItem.estEndDate | amDateFormat: appConfig.dateFormat }}</span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>

    <kendo-grid-column
      title="Type"
      field="type.name"
      class="lm-type-column slx-text-center"
      [width]="60"
        >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Type</span>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex justify-content__flex-start align-items__center request-type" [style.border-left-color]="dataItem.type.color">
              <i aria-hidden="true" class="request-type__icon" [style.color]="dataItem.type.color" [ngClass]="getIcon(dataItem)"></i>
              <span class="request-type__text" [title]="dataItem.type.name">{{ dataItem.type.name }}</span>
            </div>
          </ng-template>
    </kendo-grid-column>

  <kendo-grid-column-group title="Days" [locked]="false" [hidden]="mode==='upcoming'"  [media]="screenUtils.getKendoMediaForDesktop()" >
      <kendo-grid-column
        title="On leave"
        field="daysOnLeave"
        class="slx-text-center"
        [width]="60"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>On leave</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container *ngIf="!dataItem.isNegativeDaysBalance">{{ getDaysOnLeave(dataItem) }}</ng-container>
          <strong class="lm-console-warning" *ngIf="dataItem.isNegativeDaysBalance">{{ getDaysOnLeave(dataItem) }}</strong>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        title="Remaining"
        field="daysRemaining"
        class="slx-text-center"
        [width]="60"
      >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Remaining</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span [ngClass]="{ 'lm-console-warning': dataItem.isNegativeDaysBalance }">{{ getDaysRemaining(dataItem) }}</span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid-column-group>

    <kendo-grid-column [hidden]="mode!=='upcoming'"
    title=""
    field="daysOnLeave"
    class="slx-text-center"
    [width]="40"
  >
    <ng-template kendoGridHeaderTemplate>
        <i class="upcoming-title fas fa-exclamation-triangle" aria-hidden="true"></i>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <i *ngIf="!dataItem.actStartDate || !dataItem.actEndDate" class="upcoming-warning fas fa-exclamation-triangle" aria-hidden="true"></i>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
