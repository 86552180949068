import * as _ from 'lodash';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { StyledUserApplication } from '../../../organization/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-square-spinner',
  templateUrl: 'square-spinner.component.html',
  styleUrls: ['square-spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SquareSpinnerComponent {
  @Input()
  public shown: boolean = false;
}
