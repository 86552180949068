import * as tslib_1 from "tslib";
import { OrgLevelWatchService } from './../../../organization/services/org-level/org-level-watch.service';
import { IndividualScheduleNavigationService } from './../../../common/services/navigation/individual-schedule-navigation.service';
import { StateResetTypes } from './../../../core/models/settings/index';
import { ComponentStateStorageService } from './../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from './../../../common/services/state-management/state-management.service';
import { DailyUnitAssignmentNavigationService } from './../../../common/services/navigation/daily-unit-assignment-navigation.service';
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/observable/combineLatest';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/filter';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import * as _ from 'lodash';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { LookupService, ScheduleCycleHelperService } from '../../../organization/services/index';
import { Position, Lookup, LookupType, LocationUnit, Shift, PositionGroup } from '../../../organization/models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { ShiftReplacementRequest } from '../../models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { DetailScreenService, DetailScreenMapService, ShiftReplacementApiService, ScheduleApiService } from '../../services/index';
import { SoConsoleNavigationService } from '../../../common/index';
import { employeeListConfig } from '../../../employee/employee-list/employee-list.config';
import { appConfig } from '../../../app.config';
import { DetailGroupViewSettingsTypes } from '../../models/index';
import { EmployeeAddShiftOpenComponent, ShiftReplacementReplaceComponent } from '../shift-replacement/index';
import { ExportExcelGridComponent } from '../daily-unit-grid/export-excel-grid/export-excel-grid.component';
import { DailyUnitGridEmployee } from '../daily-unit-grid/daily-unit-grid-employees/daily-unit-grid-employees-model';
var imageCache = {};
var VIEW_MODE_KEY = 'view_mode';
var FILTERS = 'filters';
var DATE = 'date';
var DailyUnitComponent = /** @class */ (function () {
    function DailyUnitComponent(scheduleApiService, detailScreenService, detailScreenMapService, modalService, activatedRoute, router, shiftReplacementApiService, domSanitizer, changeDetector, scheduleCycleHelperService, lookupService, stateManagement, storageService, orgLevelService, elementRef) {
        this.lookupService = lookupService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.orgLevelService = orgLevelService;
        this.elementRef = elementRef;
        this.isEmptyExists = false;
        this.GroupedListArray = [];
        this.GroupedList = [];
        this.showGrid = false;
        this.IsEnabled = true;
        this.filtersApplied = false;
        this.scrollToValue = 0;
        this.scheduleApiService = scheduleApiService;
        this.detailScreenService = detailScreenService;
        this.detailScreenMapService = detailScreenMapService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.shiftReplacementApiService = shiftReplacementApiService;
        this.scheduleCycleHelperService = scheduleCycleHelperService;
        this.domSanitizer = domSanitizer;
        this.restrictedInfo = ['ShiftGroup'];
        this.selectedPositionGroupIds = [];
        this.selectedPositionIds = [];
        this.selectedShiftIds = [];
        this.selectedUnitIds = [];
        this.changeDetector = changeDetector;
        this.navigateService = new DailyUnitAssignmentNavigationService(this.router, this.activatedRoute);
        this.individualNavService = new IndividualScheduleNavigationService(this.router, this.activatedRoute);
        this.soNavService = new SoConsoleNavigationService(this.router, this.activatedRoute);
        this.state = {
            isLoading: false,
            isSelectPropagated: true,
            switchShiftMode: false
        };
    }
    Object.defineProperty(DailyUnitComponent.prototype, "dateOn", {
        get: function () {
            return this.m_dateOn;
        },
        set: function (value) {
            this.m_dateOn = value;
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('DailyUnitComponent', true);
        this.loadDetailsEvent = new Subject();
        this.state.isLoading = true;
        this.shiftGroup = true;
        var dateOnEvent$ = this.activatedRoute.params
            .map(function (_a) {
            var dateOn = _a.date;
            var d = moment(dateOn, appConfig.linkDateFormat).toDate();
            if (!dateOn) {
                return _this.getSavedDate();
            }
            else {
                _this.saveDate(d);
            }
            return d;
        });
        this.routeSubscripion = this.activatedRoute.params
            .combineLatest(this.activatedRoute.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var posGroupIds = queryParams['positionGroupId'];
            if (posGroupIds)
                _this.pathPositionGroupIds = _.map(posGroupIds.split(','), function (item) { return +item; });
            var posIds = queryParams['positionId'];
            if (posIds)
                _this.pathPositionIds = _.map(posIds.split(','), function (item) { return +item; });
            var unitIds = queryParams['unitId'];
            if (unitIds)
                _this.unitIds = _.map(unitIds.split(','), function (item) { return +item; });
            var shiftIds = queryParams['shiftId'];
            if (shiftIds)
                _this.shiftIds = _.map(shiftIds.split(','), function (item) { return +item; });
            _this.setViewMode(queryParams[VIEW_MODE_KEY]);
        });
        this.dateSubscription = dateOnEvent$.subscribe(function (dateOn) { return _this.dateOn = dateOn; });
        this.orgLevelSubscripion = this.orgLevel$.subscribe(function (selectedOrgLevel) {
            _this.orgLevel = selectedOrgLevel;
        });
        var orgLevelChangeEvent$ = this.orgLevel$
            .filter(function (selectedOrgLevel) { return selectedOrgLevel.type === OrgLevelType.department; });
        var detailsEvent$ = Observable.combineLatest(orgLevelChangeEvent$, dateOnEvent$, this.loadDetailsEvent)
            .do(function () { return _this.state.isLoading = true; });
        this.detailsSubscription = detailsEvent$.subscribe(function (_a) {
            var selectedOrgLevel = _a[0], dateOn = _a[1];
            if (!moment(dateOn).isValid()) {
                return;
            }
            _this.loadDetails(selectedOrgLevel, dateOn)
                .then(function (d) {
                _this.details = d;
                _this.previousGroups = _.cloneDeep(_this.details.groups);
                _this.previousDetails = _.cloneDeep(_this.details);
                _this.currentOrgLevel = selectedOrgLevel;
                _this.applyFilters();
                _this.restoreGroupsStates(_this.previousGroups); //always after filters!
            })
                .catch(function (error) { return console.log(error); })
                .then(function () { return _this.state.isLoading = false; });
            _this.loadDetails(selectedOrgLevel, moment(dateOn).subtract(1, 'day').toDate())
                .then(function (d) {
                _this.previousDayDetails = _.cloneDeep(d.groups);
            }).catch(function (error) { return console.log(error); });
            _this.loadDetails(selectedOrgLevel, moment(dateOn).add(1, 'day').toDate())
                .then(function (d) {
                _this.nextDayDetails = _.cloneDeep(d.groups);
            }).catch(function (error) { return console.log(error); });
            _this.loadFilterLookups(selectedOrgLevel.id);
        });
        this.notDepartmentSubscription = this.orgLevel$
            .filter(function (o) { return o.type !== OrgLevelType.department; })
            .subscribe(function () {
            _this.soNavService.navigateToSoConsole();
        });
        this.loadDetailsEvent.next();
        this.convertToDailyUnitEmployee();
        this.showGrid = true;
        this.getExportData();
    };
    DailyUnitComponent.prototype.convertToDailyUnitEmployee = function () {
        var _this = this;
        this.GroupedListArray = [];
        this.GroupedList = [];
        this.previousDetails && this.previousDetails.groups.forEach(function (currentDetailGroup) {
            currentDetailGroup && currentDetailGroup.rows.forEach(function (currentDetailRow) {
                var dailyUnitGridEmployee = new DailyUnitGridEmployee();
                currentDetailRow.fields.forEach(function (field) {
                    if ('InPar' === field.name) {
                        dailyUnitGridEmployee.InPar = field.value;
                    }
                    else if ('EmpId' === field.name) {
                        dailyUnitGridEmployee.EmpId = field.value;
                    }
                    else if ('EmpName' === field.name) {
                        dailyUnitGridEmployee.EmpName = field.value;
                    }
                    else if ('DepartmentId' === field.name) {
                        dailyUnitGridEmployee.DepartmentId = field.value;
                    }
                    else if ('PositionGroupId' === field.name) {
                        dailyUnitGridEmployee.PositionGroupId = field.value;
                    }
                    else if ('PositionGroupName' === field.name) {
                        dailyUnitGridEmployee.PositionGroupName = field.value;
                    }
                    else if ('JobCode' === field.name) {
                        dailyUnitGridEmployee.JobCode = field.value;
                    }
                    else if ('JobDescription' === field.name) {
                        dailyUnitGridEmployee.JobDescription = field.value;
                    }
                    else if ('ShiftGroupId' === field.name) {
                        dailyUnitGridEmployee.ShiftGroupId = field.value;
                    }
                    else if ('ShiftGroup' === field.name) {
                        dailyUnitGridEmployee.ShiftGroup = field.value;
                    }
                    else if ('ShiftId' === field.name) {
                        dailyUnitGridEmployee.ShiftId = field.value;
                    }
                    else if ('ShiftName' === field.name) {
                        dailyUnitGridEmployee.ShiftName = field.value;
                    }
                    else if ('ShiftStart' === field.name) {
                        dailyUnitGridEmployee.ShiftStart = field.value;
                    }
                    else if ('ShiftEnd' === field.name) {
                        dailyUnitGridEmployee.ShiftEnd = field.value;
                    }
                    else if ('UnitId' === field.name) {
                        dailyUnitGridEmployee.UnitId = field.value;
                    }
                    else if ('UnitName' === field.name) {
                        dailyUnitGridEmployee.UnitName = field.value;
                    }
                    else if ('SchedHours' === field.name) {
                        dailyUnitGridEmployee.SchedHours = field.value;
                    }
                    else if ('Hours' === field.name) {
                        dailyUnitGridEmployee.Hours = field.value;
                    }
                    else if ('IsOvertime' === field.name) {
                        dailyUnitGridEmployee.IsOvertime = field.value;
                    }
                    else if ('PPD' === field.name) {
                        dailyUnitGridEmployee.PPD = field.value;
                    }
                    else if ('SlxpartnerdataId' === field.name) {
                        dailyUnitGridEmployee.SlxpartnerdataId = field.value;
                    }
                    else if ('RequeststatusId' === field.name) {
                        dailyUnitGridEmployee.RequeststatusId = field.value;
                    }
                    else if ('MessageCount' === field.name) {
                        dailyUnitGridEmployee.MessageCount = field.value;
                    }
                    else if ('PartnerId' === field.name) {
                        dailyUnitGridEmployee.PartnerId = field.value;
                    }
                    else if ('ShiftRequestDate' === field.name) {
                        dailyUnitGridEmployee.ShiftRequestDate = field.value;
                    }
                    else if ('ActualShiftStart' === field.name) {
                        dailyUnitGridEmployee.actualShiftStart = field.value;
                    }
                    else if ('ActualShiftEnd' === field.name) {
                        dailyUnitGridEmployee.actualShiftEnd = field.value;
                    }
                });
                if (dailyUnitGridEmployee.RequeststatusId == "1" && dailyUnitGridEmployee.EmpName == "") {
                    dailyUnitGridEmployee.EmpName = employeeListConfig.pendingShiftPartnerStatus;
                    dailyUnitGridEmployee.AgencyName = "";
                }
                if (dailyUnitGridEmployee.RequeststatusId == "4" && dailyUnitGridEmployee.EmpName == "") {
                    dailyUnitGridEmployee.EmpName = employeeListConfig.preassignPendingStatus;
                    dailyUnitGridEmployee.AgencyName = "";
                }
                if (currentDetailRow.isEmpty && !currentDetailRow.isPendingShift) {
                    dailyUnitGridEmployee.IsOpenShift = currentDetailRow.isEmpty;
                }
                _this.GroupedList.push(dailyUnitGridEmployee);
            });
            //this.GroupedList.sort((a: { EmpName: { toUpperCase: () => number; }; }, b: { EmpName: { toUpperCase: () => number; }; }) => (a.EmpName.toUpperCase() > b.EmpName.toUpperCase()) ? 1 : -1);
            _this.GroupedListArray.push(_this.GroupedList);
        });
        this.getExportData();
    };
    DailyUnitComponent.prototype.getExportData = function () {
        try {
            this.GroupedListArray = this.GroupedListArray.length > 0 ? this.GroupedListArray[0] : this.GroupedListArray;
        }
        catch (e) { }
    };
    DailyUnitComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    DailyUnitComponent.prototype.setViewMode = function (routeMode) {
        if (routeMode && routeMode in DetailGroupViewSettingsTypes) {
            this.currentViewMode = routeMode;
        }
        else {
            this.restoreViewMode();
        }
    };
    DailyUnitComponent.prototype.loadFilterLookups = function (selectedOrgLevelId) {
        var _this = this;
        var positionGroupsPromise = this.lookupService.getLookup({ lookupType: LookupType.positionGroups, orgLevelId: selectedOrgLevelId });
        var positionsPromise = this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: selectedOrgLevelId });
        var unitsPromise = this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: selectedOrgLevelId });
        var shiftsPromise = this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: selectedOrgLevelId });
        Promise.all([positionGroupsPromise, positionsPromise, unitsPromise, shiftsPromise])
            .then(function (values) {
            _this.positionGroupLookup = values[0], _this.positionLookup = values[1], _this.unitsLookup = values[2], _this.shiftsLookup = values[3];
            _this.restoreFilters();
        });
    };
    DailyUnitComponent.prototype.loadDetails = function (orgLevel, dateOn) {
        return this.detailScreenService.getDailyUnits(orgLevel, dateOn);
    };
    DailyUnitComponent.prototype.shiftOpen = function (row) {
        var empIdField = _.find(row.fields, function (f) { return f.name === employeeListConfig.employeeIdentifierName; });
        if (!empIdField) {
            return;
        }
        this.navigateService.navigateToMasterScheduleOpenShifts(this.dateOn, empIdField.value);
    };
    DailyUnitComponent.prototype.onFillShift = function (row) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var shiftIdField, shiftNameField, positionIdField, positionNameField, unitIdField, unitNameField, orgLevel, request, data;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        shiftIdField = _.find(row.fields, function (f) { return f.name === 'ShiftId'; });
                        shiftNameField = _.find(row.fields, function (f) { return f.name === 'ShiftName'; });
                        positionIdField = _.find(row.fields, function (f) { return f.name === 'JobCode'; });
                        positionNameField = _.find(row.fields, function (f) { return f.name === 'JobDescription'; });
                        unitIdField = _.find(row.fields, function (f) { return f.name === 'UnitId'; });
                        unitNameField = _.find(row.fields, function (f) { return f.name === 'UnitName'; });
                        if (!shiftIdField || !positionIdField || !unitIdField) {
                            return [2 /*return*/];
                        }
                        if (!(this.orgLevel.parentId > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.orgLevelService.getOrgLevelByIdSafe(this.orgLevel.parentId)];
                    case 1:
                        orgLevel = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        orgLevel = this.orgLevel;
                        _a.label = 3;
                    case 3:
                        request = new ShiftReplacementRequest();
                        request.shiftId = +shiftIdField.value;
                        request.shiftName = shiftNameField.value;
                        request.positionId = +positionIdField.value;
                        request.positionName = positionNameField.value;
                        request.organizationName = orgLevel.name;
                        request.unitId = +unitIdField.value;
                        request.unitName = unitNameField.value;
                        request.date = this.dateOn;
                        request.showDayOffEmployees = true;
                        request.showSendSmsButton = true;
                        data = {
                            request: request,
                            groups: this.previousGroups,
                            previousDayGroups: this.previousDayDetails,
                            nextDayGroups: this.nextDayDetails
                        };
                        EmployeeAddShiftOpenComponent.openDialog(data, +unitIdField.value, this.modalService, function (result, cmd) {
                            if (result && cmd) {
                                _this.state.isLoading = true;
                                _this.shiftReplacementApiService.addToShift(cmd)
                                    .catch(function (error) {
                                    _this.state.isLoading = false;
                                    console.log(error);
                                })
                                    .then(function (status) {
                                    _this.state.isLoading = false;
                                    _this.loadDetailsEvent.next();
                                });
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyUnitComponent.prototype.onOpenIndividual = function (row) {
        var _this = this;
        var employeeId = _.find(row.fields, function (f) { return f.name === 'EmpId'; });
        var departamentId = _.find(row.fields, function (f) { return f.name === 'DepartmentId'; });
        var posOrgLevel = this.orgLevelService.getOrgLevelByRelatedItemId(+departamentId.value, OrgLevelType.department);
        if (!posOrgLevel)
            return;
        var orgLevelId = posOrgLevel.id;
        this.state.isLoading = true;
        this.scheduleCycleHelperService.getScheduleCycleByDate(this.dateOn, orgLevelId)
            .then(function (_a) {
            var startDate = _a.startDate, endDate = _a.endDate;
            _this.state.isLoading = false;
            _this.individualNavService.NavigateToIndividualScheduleEmp(+employeeId.value, startDate.toDate(), endDate.toDate(), _this.dateOn);
        });
    };
    DailyUnitComponent.prototype.onReplaceEmployee = function (row) {
        var _this = this;
        var employeeName = _.find(row.fields, function (f) { return f.name === 'EmpName'; });
        var employeeId = _.find(row.fields, function (f) { return f.name === 'EmpId'; });
        var shiftIdField = _.find(row.fields, function (f) { return f.name === 'ShiftId'; });
        var shiftStartField = _.find(row.fields, function (f) { return f.name === 'ShiftStart'; });
        var shiftEndField = _.find(row.fields, function (f) { return f.name === 'ShiftEnd'; });
        var shiftNameField = _.find(row.fields, function (f) { return f.name === 'ShiftName'; });
        var positionIdField = _.find(row.fields, function (f) { return f.name === 'JobCode'; });
        var unitIdField = _.find(row.fields, function (f) { return f.name === 'UnitId'; });
        var actualShiftStartField = _.find(row.fields, function (f) { return f.name === 'ActualShiftStart'; });
        var actualShiftEndField = _.find(row.fields, function (f) { return f.name === 'ActualShiftEnd'; });
        if (!shiftIdField || !positionIdField || !unitIdField) {
            return;
        }
        var request = new ShiftReplacementRequest();
        request.replacedEmployeeName = employeeName.value;
        request.replacedEmployeeId = +employeeId.value;
        request.shiftId = +shiftIdField.value;
        request.shiftName = shiftNameField.value;
        request.positionId = +positionIdField.value;
        request.date = this.dateOn;
        request.shiftStart = moment(shiftStartField.value).toDate();
        request.shiftEnd = moment(shiftEndField.value).toDate();
        request.momentActualShiftStart = moment(actualShiftStartField.value);
        request.momentActualShiftEnd = moment(actualShiftEndField.value);
        request.showDayOffEmployees = true;
        request.orgLevelId = this.currentOrgLevel.id;
        ShiftReplacementReplaceComponent.openDialog(request, this.modalService, function (result, cmd) {
            if (result && cmd) {
                _this.state.isLoading = true;
                _this.shiftReplacementApiService.replaceEmployee(cmd)
                    .catch(function (error) {
                    _this.state.isLoading = false;
                    console.log(error);
                })
                    .then(function (status) {
                    _this.state.isLoading = false;
                    _this.loadDetailsEvent.next();
                });
            }
        });
    };
    DailyUnitComponent.prototype.hideTable = function (groupTable) {
        $(groupTable).toggle();
    };
    DailyUnitComponent.prototype.changeDateOn = function (dateOn) {
        this.navigateService.navigateToDailyUnitAssignmentDate(dateOn, this.pathPositionIds, this.currentViewMode, false, this.unitIds, this.shiftIds);
    };
    DailyUnitComponent.prototype.onFilterChange = function (filterName, filter) {
        this.state.isSelectPropagated = false;
        var ids = _.map(filter, function (m) { return m.id; });
        if (filterName === 'unit') {
            this.selectedUnitIds = ids;
        }
        else if (filterName === LookupType.position) {
            this.selectedPositionIds = ids;
        }
        else if (filterName === LookupType.shift) {
            this.selectedShiftIds = ids;
        }
        else if (filterName === LookupType.positionGroups) {
            this.selectedPositionGroupIds = ids;
        }
    };
    DailyUnitComponent.prototype.calcAppliedFilters = function () {
        this.filtersApplied = Boolean(this.selectedUnitIds && this.selectedUnitIds.length) ||
            Boolean(this.selectedPositionIds && this.selectedPositionIds.length) ||
            Boolean(this.selectedShiftIds && this.selectedShiftIds.length) ||
            Boolean(this.selectedPositionGroupIds && this.selectedPositionGroupIds.length);
    };
    DailyUnitComponent.prototype.filterRows = function (rows) {
        var filtered = this.filterRowsBy(rows, 'UnitId', this.selectedUnitIds);
        filtered = this.filterRowsBy(filtered, 'ShiftId', this.selectedShiftIds);
        filtered = this.filterRowsBy(filtered, 'JobCode', this.selectedPositionIds);
        filtered = this.filterRowsBy(filtered, 'PositionGroupId', this.selectedPositionGroupIds);
        return filtered;
    };
    DailyUnitComponent.prototype.filterRowsBy = function (rows, fieldName, filteredItemIds) {
        if (!filteredItemIds || filteredItemIds.length === 0)
            return rows;
        var filtered = _.filter(rows, function (row) {
            var field = _.find(row.fields, function (f) { return f.name === fieldName; });
            var id = _.find(filteredItemIds, function (itemId) { return +itemId === +field.value; });
            return !!id;
        });
        return filtered;
    };
    DailyUnitComponent.prototype.stopPropagation = function ($event) {
        if (this.state.isSelectPropagated) {
            $event.stopPropagation();
        }
        else {
            this.state.isSelectPropagated = true;
        }
    };
    DailyUnitComponent.prototype.employeeDelete = function (row) {
        var _this = this;
        var shiftIdField = _.find(row.fields, function (f) { return f.name === 'ShiftId'; });
        var employeeId = _.find(row.fields, function (f) { return f.name === employeeListConfig.employeeIdentifierName; });
        this.detailScreenService.deleteDailyUnitEmployee(this.dateOn, +shiftIdField.value, +employeeId.value)
            .then(function () { return _this.loadDetailsEvent.next(); });
    };
    DailyUnitComponent.prototype.onSwitchEmployeeShift = function (row) {
        this.rowForShiftChange = row;
        this.state.switchShiftMode = true;
    };
    DailyUnitComponent.prototype.discardShiftSwitch = function () {
        this.rowForShiftChange = null;
        this.state.switchShiftMode = false;
    };
    DailyUnitComponent.prototype.performShiftSwitch = function (item) {
        var _this = this;
        var field;
        var empId;
        var originalId;
        var replaceId;
        var replaceUnitId;
        field = this.getField(this.rowForShiftChange.fields, 'EmpId');
        if (field)
            empId = parseInt(field.value);
        field = this.getField(this.rowForShiftChange.fields, 'ShiftId');
        if (field) {
            originalId = parseInt(field.value);
            field.value = item.shift.id.toString();
            replaceId = parseInt(field.value);
        }
        field = this.getField(this.rowForShiftChange.fields, 'ShiftName');
        if (field)
            field.value = item.shift.name;
        field = this.getField(this.rowForShiftChange.fields, 'UnitId');
        if (field) {
            field.value = item.unit.id.toString();
            replaceUnitId = parseInt(field.value);
        }
        field = this.getField(this.rowForShiftChange.fields, 'UnitName');
        if (field)
            field.value = item.unit.name;
        this.state.isLoading = true;
        this.scheduleApiService.changeOpenShift(this.dateOn, empId, originalId, replaceId, replaceUnitId).then(function () {
            _this.state.isLoading = false;
            _this.rowForShiftChange = null;
            _this.state.switchShiftMode = false;
            _this.loadDetailsEvent.next();
        }).catch(function (err) {
            _this.state.isLoading = false;
        });
    };
    Object.defineProperty(DailyUnitComponent.prototype, "censusCount", {
        get: function () {
            return this.details ? this.details.censusCount : 0;
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitComponent.prototype.onCensusEdit = function () {
        this.navigateService.navigateToCensusEditor(this.dateOn);
    };
    DailyUnitComponent.prototype.savePreviousFilters = function () {
        this.prePositionGroupFilter = this.positionGroupFilter ? this.positionGroupFilter.concat([]) : [];
        this.prevPosFilter = this.posFilter ? this.posFilter.concat([]) : [];
        this.prevUnitFilter = this.unitFilter ? this.unitFilter.concat([]) : [];
        this.prevShiftFilter = this.shiftFilter ? this.shiftFilter.concat([]) : [];
    };
    DailyUnitComponent.prototype.applyFilters = function () {
        var _this = this;
        if (this.details) {
            this.previousGroups = _.cloneDeep(this.details.groups);
            this.previousDetails = _.cloneDeep(this.details);
        }
        _.each(this.previousDetails.groups, function (group) {
            group.rows = _this.filterRows(group.rows);
            group.recalculateFilteredValues();
        });
        _.each(this.previousGroups, function (group) {
            group.rows = _this.filterRows(group.rows);
            group.recalculateFilteredValues();
        });
        this.calcAppliedFilters();
        this.convertToDailyUnitEmployee();
    };
    DailyUnitComponent.prototype.cancelFilters = function () {
        this.positionGroupFilter = this.prePositionGroupFilter;
        this.posFilter = this.prevPosFilter;
        this.unitFilter = this.prevUnitFilter;
        this.shiftFilter = this.prevShiftFilter;
    };
    DailyUnitComponent.prototype.toggleEmptySlots = function (group, event) {
        group.isEmptySlotFilterSelected = !group.isEmptySlotFilterSelected;
        group.recalculateFilteredValues();
        this.saveGroupState(group, 'isEmptySlotFilterSelected', group.isEmptySlotFilterSelected);
        event.stopPropagation();
    };
    DailyUnitComponent.prototype.toggleOutOfPar = function (group, event) {
        group.isOutOfParFilterSelected = !group.isOutOfParFilterSelected;
        group.recalculateFilteredValues();
        this.saveGroupState(group, 'isOutOfParFilterSelected', group.isOutOfParFilterSelected);
        event.stopPropagation();
    };
    DailyUnitComponent.prototype.toggleNewMessages = function (group, event) {
        group.isMessageFilterSelected = !group.isMessageFilterSelected;
        group.recalculateFilteredValues();
        this.saveGroupState(group, 'isMessageFilterSelected', group.isMessageFilterSelected);
        event.stopPropagation();
    };
    DailyUnitComponent.prototype.toggleOvertimeEmployees = function (group, event) {
        group.isOvertimeFilterSelected = !group.isOvertimeFilterSelected;
        group.recalculateFilteredValues();
        this.saveGroupState(group, 'isOvertimeFilterSelected', group.isOvertimeFilterSelected);
        event.stopPropagation();
    };
    DailyUnitComponent.prototype.viewSettingsChanged = function (event) {
        this.currentViewMode = event;
        this.saveViewMode();
    };
    DailyUnitComponent.prototype.saveFilters = function () {
        var filters = {
            positionGroup: this.positionGroupFilter,
            positions: this.posFilter,
            units: this.unitFilter,
            shifts: this.shiftFilter
        };
        this.storageService.setControlState(this.stateManagement.componentKey, FILTERS, { value: filters }, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.ParameterInRoute);
        if (this.selectedPositionGroupIds && this.selectedPositionGroupIds.length) {
            this.pathPositionGroupIds = this.selectedPositionGroupIds.concat([]);
        }
        else {
            this.pathPositionGroupIds = [];
        }
        if (this.selectedPositionIds && this.selectedPositionIds.length) {
            this.pathPositionIds = this.selectedPositionIds.concat([]);
        }
        else {
            this.pathPositionIds = [];
        }
        if (this.selectedUnitIds && this.selectedUnitIds.length) {
            this.unitIds = this.selectedUnitIds.concat([]);
        }
        else {
            this.unitIds = [];
        }
        if (this.selectedShiftIds && this.selectedShiftIds.length) {
            this.shiftIds = this.selectedShiftIds.concat([]);
        }
        else {
            this.shiftIds = [];
        }
        this.changeDateOn(this.dateOn);
    };
    DailyUnitComponent.prototype.groupContainerCreated = function (item) {
        this.groupContainer = item;
        this.scrollGroupContainer();
    };
    DailyUnitComponent.prototype.scrollTo = function (value) {
        this.scrollToValue = value;
        this.scrollGroupContainer();
    };
    Object.defineProperty(DailyUnitComponent.prototype, "totalEmptySlotCount", {
        get: function () {
            if (!this.previousGroups)
                return 0;
            var mapped = _.map(this.previousGroups, 'emptySlotCount');
            return _.sum(mapped);
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitComponent.prototype.scrollGroupContainer = function () {
        if (this.groupContainer && this.scrollToValue > 0) {
            this.groupContainer.scrollTop = this.scrollToValue - this.groupContainer.offsetTop;
            this.scrollToValue = 0;
            this.groupContainer = null;
        }
    };
    DailyUnitComponent.prototype.saveGroupState = function (group, prop, propValue) {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, group.name);
        if (!state || !state.value) {
            state = { value: {} };
        }
        state.value[prop] = propValue;
        this.storageService.setControlState(this.stateManagement.componentKey, group.name, state, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange);
    };
    DailyUnitComponent.prototype.saveViewMode = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, VIEW_MODE_KEY, { value: this.currentViewMode }, StateResetTypes.None);
    };
    DailyUnitComponent.prototype.saveDate = function (date) {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
        if (!state || !state.value) {
            state = { value: {} };
        }
        state.value = date;
        this.storageService.setControlState(this.stateManagement.componentKey, DATE, state, StateResetTypes.SessionEnd | StateResetTypes.MenuChange);
    };
    DailyUnitComponent.prototype.getSavedDate = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, DATE);
        if (state.value)
            return state.value;
        return new Date();
    };
    DailyUnitComponent.prototype.restoreViewMode = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, VIEW_MODE_KEY);
        if (state.value && state.value in DetailGroupViewSettingsTypes) {
            this.currentViewMode = state.value;
        }
        else {
            this.currentViewMode = DetailGroupViewSettingsTypes.slots;
        }
    };
    DailyUnitComponent.prototype.restoreFilters = function () {
        var _this = this;
        var positionGroupToSelect = [];
        if (this.pathPositionGroupIds && this.pathPositionGroupIds.length) {
            if (this.positionGroupLookup && this.positionGroupLookup.items) {
                _.forEach(this.pathPositionGroupIds, function (id) {
                    var pos = _.find(_this.positionGroupLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (pos)
                        positionGroupToSelect.push(pos);
                });
                if (positionGroupToSelect && positionGroupToSelect.length) {
                    this.positionGroupFilter = positionGroupToSelect;
                    this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
                }
            }
        }
        var positionToSelect = [];
        if (this.pathPositionIds && this.pathPositionIds.length) {
            if (this.positionLookup && this.positionLookup.items) {
                _.forEach(this.pathPositionIds, function (id) {
                    var pos = _.find(_this.positionLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (pos)
                        positionToSelect.push(pos);
                });
                if (positionToSelect && positionToSelect.length) {
                    this.posFilter = positionToSelect;
                    this.onFilterChange(LookupType.position, this.posFilter);
                }
            }
        }
        var unitToSelect = [];
        if (this.unitIds && this.unitIds.length) {
            if (this.unitsLookup && this.unitsLookup.items) {
                _.forEach(this.unitIds, function (id) {
                    var unit = _.find(_this.unitsLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (unit)
                        unitToSelect.push(unit);
                });
                if (unitToSelect && unitToSelect.length) {
                    this.unitFilter = unitToSelect;
                    this.onFilterChange('unit', this.unitFilter);
                }
            }
        }
        var shiftsToSelect = [];
        if (this.shiftIds && this.shiftIds.length) {
            if (this.shiftsLookup && this.shiftsLookup.items) {
                _.forEach(this.shiftIds, function (id) {
                    var shift = _.find(_this.shiftsLookup.items, function (item) {
                        return item.id === id;
                    });
                    if (shift)
                        shiftsToSelect.push(shift);
                });
                if (shiftsToSelect && shiftsToSelect.length) {
                    this.shiftFilter = shiftsToSelect;
                    this.onFilterChange(LookupType.shift, this.shiftFilter);
                }
            }
        }
        var state = this.storageService.getControlState(this.stateManagement.componentKey, FILTERS);
        var filters = state.value;
        if (filters) {
            if (!positionGroupToSelect || !positionGroupToSelect.length) {
                this.positionGroupFilter = filters.positionGroup;
            }
            if (!positionToSelect || !positionToSelect.length) {
                this.posFilter = filters.positions;
            }
            if (!unitToSelect || !unitToSelect.length) {
                this.unitFilter = filters.units;
            }
            if (!shiftsToSelect || !shiftsToSelect.length) {
                this.shiftFilter = filters.shifts;
            }
            this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
            this.onFilterChange(LookupType.position, this.posFilter);
            this.onFilterChange(LookupType.shift, this.shiftFilter);
            this.onFilterChange('unit', this.unitFilter);
        }
        else {
            if (!positionGroupToSelect || !positionGroupToSelect.length) {
                this.onFilterChange(LookupType.positionGroups, this.positionGroupFilter);
            }
            if (positionToSelect && positionToSelect.length) {
                this.onFilterChange(LookupType.position, this.posFilter);
            }
            if (unitToSelect || unitToSelect.length) {
                this.onFilterChange('unit', this.unitFilter);
            }
            if (shiftsToSelect || shiftsToSelect.length) {
                this.onFilterChange(LookupType.shift, this.shiftFilter);
            }
        }
        this.applyFilters();
    };
    DailyUnitComponent.prototype.restoreGroupsStates = function (groups) {
        var _this = this;
        _.forEach(groups, function (group) {
            var state = _this.storageService.getControlState(_this.stateManagement.componentKey, group.name);
            if (state && state.value) {
                var val = state.value;
                group.isEmptySlotFilterSelected = val.isEmptySlotFilterSelected && (group.emptySlotCount > 0);
                group.isOutOfParFilterSelected = val.isOutOfParFilterSelected && (group.outOfParCount > 0);
                group.isMessageFilterSelected = val.isMessageFilterSelected && (group.messageCount > 0);
                group.isOvertimeFilterSelected = val.isOvertimeFilterSelected && (group.overtimeCount > 0);
                group.recalculateFilteredValues();
            }
        });
    };
    DailyUnitComponent.prototype.getField = function (fields, name) {
        return _.find(fields, function (field) {
            return field.name === name;
        });
    };
    DailyUnitComponent.prototype.captureScreen = function () {
        var _this = this;
        var data = document.getElementById('contentToConvert');
        html2canvas(data).then(function (canvas) {
            // Few necessary setting options
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;
            var contentDataURL = canvas.toDataURL('image/png');
            var pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
            var position = 10;
            var _selectedDateVal = document.getElementById('_selectedDateVal').innerText;
            var current = new Date(_this.dateOn);
            var cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            var cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            var dateTime = cDate + ' ' + cTime;
            pdf.setFontSize(10);
            //pdf.text(dateTime, 7, 7);
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            pdf.save('Daily_Unit_Assignment_' + cDate + '.pdf'); // Generated PDF
        });
    };
    DailyUnitComponent.prototype.exportToExcel = function () {
        var myCompOneObj = new ExportExcelGridComponent();
        myCompOneObj.exportData();
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], DailyUnitComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitComponent.prototype, "detailsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitComponent.prototype, "dateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitComponent.prototype, "notDepartmentSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitComponent.prototype, "orgLevelSubscripion", void 0);
    return DailyUnitComponent;
}());
export { DailyUnitComponent };
