export interface IPBJExportResult {
  exportId: number;
  isSuccess: boolean;
  errorMessage: string;
}

export class PBJExportResult {
  public exportId: number;
  public isSuccess: boolean;
  public errorMessage: string;
}
