import * as _ from 'lodash';

import { IDatedObject, WeeklyData } from '../../../core/models/index';
import { ScheduleEntryDefinition, EmployeeDefinition, Position, ShiftDefinition, LocationUnit, IPosition, IShiftDefinition, ILocationUnit } from '../../../organization/models/index';
import { OpenShiftDetails, IOpenShiftDetails, EmployeeMessage } from './index';
import * as moment from 'moment';

export interface IOpenShiftSummary {
  dateOn: Date;
  details: IOpenShiftDetails[];
  canEditScheduleApprovedPeriod?: boolean;
}

export class OpenShiftSummary {
  public dateOn: Date;
  public canEditScheduleApprovedPeriod :boolean;
  public get shiftCount(): number {
    if (this.details) {
      return _.sumBy(this.details, (detailsInfo: OpenShiftDetails): number => {
        return detailsInfo.openShiftCount;
      });
    }
    return 0;
  }
  public get shiftCountPartial(): number {
    let returnedOpenCount = 0;
    if (this.details) {
      _.forEach(this.details, (detailsInfo: OpenShiftDetails) => {
        if(detailsInfo.parentShiftId===-1){
          returnedOpenCount += detailsInfo.calculatedOpenShiftCount;
        }
      })
    }
    return returnedOpenCount;
  }
  public get newMessageCount(): number {
    if (this.details) {
      return _.sumBy(this.details, (detailsInfo: OpenShiftDetails): number =>
        detailsInfo.newMessageCount);
    }
    return 0;
  }
  public get partnerShiftCount(): number {
    if (this.details) {
      return _.sumBy(this.details, (detailsInfo: OpenShiftDetails): number =>
        detailsInfo.partnerShiftCount);
    }
    return 0; 
  }
  public details: OpenShiftDetails[];
}
