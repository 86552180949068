import { Injectable } from '@angular/core';

import { ResponseBody, Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { SlxHttpRequest } from '../../../core/models/index';
import { essTemplatesConfig } from '../ess-templates.config';
import { EssTemplateMapService } from './ess-templates-map.service';
import {
  IEssTemplateDefinition, EssTemplateDefinition,
  IEssTemplateDeletionRespose, EssTemplateDeletionRespose,
  IEssTemplate, EssTemplate
} from '../models/index';

@Injectable()
export class EssTemplateApiService {
  constructor(
    private mapService: EssTemplateMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public async getTemplates(orgLevelId: number): Promise<EssTemplate[]> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.templates}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { orgLevelId });

    const response = await this.apiUtilService.requestNew<IEssTemplate[], Meta>(request);
    return this.mapService.mapToEssTemplates(response.data);
  }

  public async getTemplateDefinitions(orgLevelId: number): Promise<EssTemplateDefinition[]> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.templateDefinitions}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { orgLevelId });

    const response = await this.apiUtilService.requestNew<IEssTemplateDefinition[], Meta>(request);
    return this.mapService.mapToEssTemplateDefinitions(response.data);
  }


  public async getTemplate(templateId: number): Promise<EssTemplate> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.template}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { templateId });

    const response = await this.apiUtilService.requestNew<IEssTemplate, Meta>(request);
    return this.mapService.mapToEssTemplate(response.data);
  }

  public async getTemplateForPosition(positionId: number): Promise<EssTemplate> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.template}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { positionId });

    const response = await this.apiUtilService.requestNew<IEssTemplate, Meta>(request);
    return this.mapService.mapToEssTemplate(response.data);
  }

  public async getTemplateDefinitionForPosition(positionId: number): Promise<EssTemplateDefinition> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.templateDefinition}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { positionId });

    const response = await this.apiUtilService.requestNew<IEssTemplateDefinition, Meta>(request);
    return this.mapService.mapToEssTemplateDefinition(response.data);
  }

  public async addTemplate(orgLevelId: number): Promise<EssTemplate> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.template}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, undefined, { orgLevelId });

    const response = await this.apiUtilService.requestNew<IEssTemplate, Meta>(request);
    return this.mapService.mapToEssTemplate(response.data);
  }

  public async saveTemplate(template: EssTemplate): Promise<EssTemplate> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.template}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestPut(url, this.mapService.mapToEssTemplateDto(template));

    const response = await this.apiUtilService.requestNew<IEssTemplate, Meta>(request);
    return this.mapService.mapToEssTemplate(response.data);
  }

  public async deleteTemplate(templateId: number): Promise<EssTemplateDeletionRespose> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.template}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestDelete(url, { templateId });

    const response = await this.apiUtilService.requestNew<IEssTemplateDeletionRespose, Meta>(request);
    return this.mapService.mapDeletionResponse(response.data);
  }

  public async assignTemplate(positionId: number, templateId: number): Promise<any> {

    const url: string = `${this.getApiUrl()}/${essTemplatesConfig.api.essTemplates.template}/${essTemplatesConfig.api.essTemplates.assign}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, { positionId, templateId });

    const response = await this.apiUtilService.requestNew<any, Meta>(request);
    return response.data;
  }

  public getApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${essTemplatesConfig.api.essTemplates.root}`;
  }

}
