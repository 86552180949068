import { AcuityConfiguration } from './../../models/index';
import { CensusEntriesComponent } from './../census-entries/census-entries.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DialogOptions } from './../../../common/models/dialog-options';
import { ModalService } from './../../../common/services/modal/modal.service';

@Component({
  moduleId: module.id,
  selector: 'slx-census-entries-popup',
  templateUrl: 'census-entries-popup.component.html',
  styleUrls: ['census-entries-popup.component.scss']
})
export class CensusEntriesPopupComponent {

  @Input()
  public fullSize: boolean;

  @ViewChild('census', {static: true})
  public census: CensusEntriesComponent;
  public dialogResult: boolean;
  public unsavedDataAlert: boolean;

  @Output()
  public onSaved: EventEmitter<AcuityConfiguration>;

  @Output()
  public onCanceled: EventEmitter<AcuityConfiguration>;

  constructor() {
    //private modalService: ModalService, private options: DialogOptions
    this.onSaved = new EventEmitter<AcuityConfiguration>();
    this.onCanceled = new EventEmitter<AcuityConfiguration>();
  }

  public closeWindow(): void {
    this.unsavedDataAlert = false;
    this.dialogResult = false;
    this.onCanceled.emit(this.census.acuityConfiguration);
  }

  public hideNotSavedErrorMessage(): void {
    this.unsavedDataAlert = false;
  }

  public existsUnsavedData(): boolean {
    if (this.census.hasChanges) {
      this.unsavedDataAlert = true;
      return true;
    }
    this.unsavedDataAlert = false;
    return false;
  }

  public updateInfoBy(date: Date): void {
    if (this.existsUnsavedData()) return;
    if (this.census.entryDate === date) return;
    this.census.entryDate = date;
  }

  public onSave(event: any): void {
    this.unsavedDataAlert = false;
    this.onSaved.emit(this.census.acuityConfiguration);
  }

  public get currentCapacity(): number {
    return this.census.currentCapacity;
  }
}
