
import * as _ from 'lodash';
import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { ApplicationActions } from '../../actions/index';
import { OrgLevelActions } from '../../../organization/actions/index';
import { EmployeeListActions } from '../../../employee/employee-list/store/index';
import { TimecardsActions } from '../../../time-and-attendance/store/index';
import { ComponentStateStorageService } from '../../../common/services/index';
import { StateResetTypes } from '../../../core/models/index';

@Injectable()
export class SideEffectsService {

  constructor(
    private componentStateStorageService: ComponentStateStorageService,
    private employeeListActions: EmployeeListActions,
    private timecardsActions: TimecardsActions
  ) {
  }

  public orgLevelSelected(): void {
    this.componentStateStorageService.clearComponentStates(StateResetTypes.OrgLevelChange);
  }

  public menuChanged(): void {
    //todo
  }

  public menuItemClicked(): void {
    this.componentStateStorageService.clearComponentStates(StateResetTypes.MenuChange);
    this.employeeListActions.resetView();
    this.timecardsActions.timecardsDisplayClearPayCycle();
  }

  public logout(): void {
    //todo
  }

  public login(): void {
    //todo
  }

}
