import { TypedRecord } from 'typed-immutable-record';

import { fromJS, List, Map, Iterable, Record } from 'immutable';

export function makeTypedCollectionFactory<E, T extends TypedRecord<T> & E>(obj: E): (val?: E) => T {
  return function TypedCollectionFactory(val: E = null): T {
    return fromJS(Object.assign(obj, val));
  };
}

export function makeTypedFactory<E, T>(obj: E): (val?: E) => T {
  return function TypedFactory(val: E = null): T {
    return fromJS(Object.assign(obj, val), (key: any, value: any) => {
      let isIndexed: boolean = Iterable.isIndexed(value);
      return isIndexed ? List.of(value) : Record(value);
    });
  };
}

export function makeTypedMapFactory(obj: any = {}): (val?: any) => Map<string, any> {
  return function TypedMapFactory(val: any = {}): Map<string, any> {
    return fromJS(Object.assign(obj, val));
  };
}
