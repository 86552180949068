import { IDatedObject } from '../../../core/models/index';
import { OpenShiftShortDetails } from './index';

export class OpenShiftShortDetailsWrapper implements IDatedObject {
  public selected: boolean;
  public date: Date;
  public details: OpenShiftShortDetails;
  public canApproveDenyPartnerShiftCount: boolean;
  
}
