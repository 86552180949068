
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export class ExceptionConsoleNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToExceptionConsole(orgLevelId: number, usePayCycle: boolean): void {
    this.router.navigateByUrl(this.getUrlTree({ orgLevelId: orgLevelId, usePayCycle: usePayCycle.toString() }));
  }

  public navigateToExceptionConsoleDates(orgLevelId: number, startDate: Date, endDate: Date, usePayCycle: boolean): void {
    let params: any = {
      orgLevelId: orgLevelId,
      startDate: moment(startDate).format(appConfig.linkDateFormat),
      endDate: moment(endDate).format(appConfig.linkDateFormat),
      usePayCycle: usePayCycle.toString()
    };
    this.router.navigateByUrl(this.getUrlTree(params));
  }

  private getUrlTree(params: any): UrlTree {
    return this.router.createUrlTree(['exception_console'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
  }
}
