import {
  ShiftDefinition,
  IShiftDefinition,
  LocationUnit,
  ILocationUnit,
  ConstraintDefinition,
  IConstraintDefinition,
  Position,
  IPosition,
  IScheduleAbsence,
  ScheduleAbsence
} from '../../../organization/models/index';

export interface IEmployeeShift {
  position: IPosition;
  shift: IShiftDefinition;
  unit: ILocationUnit;
  constraint: IConstraintDefinition;
  absence: IScheduleAbsence; //can be undefined
  isDirty: boolean;
}

export class EmployeeShift {
  public position: Position;
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public constraint: ConstraintDefinition;
  public absence: ScheduleAbsence; //can be undefined
  public isDirty: boolean = false;

  public get isValid(): boolean {
    if (this.position && this.shift && this.unit) {
      return true;
    }
    return false;
  }
}
