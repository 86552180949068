import { ManageACAPeriods } from './manage-aca-periods.model';
import { Actions } from '../../../core/models/index';

export class IManageACAPeriodsContainer {
    records: any[];
    actions: Actions;
}

export class ManageACAPeriodsContainer {
    public records: ManageACAPeriods[];
    public actions: Actions;
}