import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService, ModalService, StateManagementService, ComponentStateStorageService } from '../../../common/services/index';
import { LookupApiService, ProductModuleMapService } from '../../../organization/services/index';
import { RolesApiService } from './roles-api.service';
import { RolesContainer, Role, SaveRolesRequest, RoleRightRecord, RoleFieldRecord, RolesProfileRow, RolesSectionRow, RolesSubsectionRow, RolesFieldRow, RoleField, RoleRight, RolesRightGroupRow, RolesRightRow, RolesRightModuleRow, RolesMenuModuleGroupRow, RolesMenuModuleRow, RolesMenuRow, RolesSubmenuRow, RoleMenu, RoleSubmenu, RoleMenuModuleRecord, RoleComponent, RolesComponentsModuleGroupRow, RolesComponentsModuleRow, RolesComponentRow, RoleComponentsModuleRecord, RoleColumnsState, RoleColumn } from '../../models/index';
import { IControlState, StateResetTypes } from '../../../core/models/index';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../common/components/index';
var RolesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(RolesManagementService, _super);
    function RolesManagementService(apiService, lookupApiService, productModuleMapService, changeService, stateService, storageService, modalService) {
        var _this = _super.call(this) || this;
        _this.productModuleMapService = productModuleMapService;
        _this.changeService = changeService;
        _this.stateService = stateService;
        _this.storageService = storageService;
        _this.modalService = modalService;
        _this.componentId = 'UserRolesComponent';
        _this.controlName = 'rolesColumns';
        _this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        _this.clearChanges$ = new Subject();
        _this.roleRightFilterChanged$ = new ReplaySubject(1);
        _this.rolesColumnsStateChanged$ = new BehaviorSubject(new RoleColumnsState());
        _this.apiService = apiService;
        _this.lookupApiService = lookupApiService;
        _this.stateService.init(_this.componentId);
        _this.initSubscription = _this.stateService.onInit$.subscribe(function () {
            _this.loadAccessTable();
        });
        _this.subscribeToLoad(false);
        return _this;
    }
    RolesManagementService.prototype.reLoadAccessTable = function () {
        this.subscribeToLoad(true);
        this.loadAccessTable();
    };
    RolesManagementService.prototype.loadAccessTable = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getRoleAccessTable()
            .then(function (container) {
            _this.container = container;
            _this.onLoaded(container);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    RolesManagementService.prototype.roleRightsFilterChanged = function (filter, type) {
        var data = { filter: filter, type: type };
        this.roleRightFilterChanged$.next(data);
    };
    RolesManagementService.prototype.recalcRights = function (roleId) {
        _.forEach(this.container.rightGroupRows, function (row) {
            row.recalcStatus(roleId);
        });
        this.changeNotify$.next('recalcRights');
    };
    RolesManagementService.prototype.saveChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var req, modules, turnOfModules, names, checkString_1, res;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        req = new SaveRolesRequest();
                        req.roleFields = [];
                        req.roleRights = [];
                        req.otherRoleRights = [];
                        req.roleMenuModules = [];
                        req.roleComponentModules = [];
                        //collect dirty fields
                        _.forEach(this.container.profileRows, function (profile) {
                            _.forEach(profile.childRows, function (section) {
                                _.forEach(section.childRows, function (subsection) {
                                    _.forEach(subsection.childRows, function (field) {
                                        _.forEach(field.roles, function (role) {
                                            var f = field.mapByRole[role.id];
                                            if (f.isDirty) {
                                                var fRecord = new RoleFieldRecord();
                                                fRecord.field = f;
                                                fRecord.roleId = role.id;
                                                fRecord.sectionName = section.mapByRole[role.id].name;
                                                fRecord.subsectionName = subsection.mapByRole[role.id].name;
                                                req.roleFields.push(fRecord);
                                            }
                                        });
                                    });
                                });
                            });
                        });
                        //collect dirty rights
                        _.forEach(this.container.rightGroupRows, function (group) {
                            _.forEach(group.childRows, function (rightModule) {
                                _.forEach(rightModule.childRows, function (right) {
                                    _.forEach(right.roles, function (role) {
                                        var r = right.mapByRole[role.id];
                                        if (r.isDirty) {
                                            var rRecord = new RoleRightRecord();
                                            rRecord.roleId = role.id;
                                            rRecord.rightId = r.id;
                                            rRecord.isEnabled = r.isEnabled;
                                            req.roleRights.push(rRecord);
                                        }
                                    });
                                });
                            });
                        });
                        //collect dirty other rights
                        _.forEach(this.container.otherRightGroupRows, function (group) {
                            _.forEach(group.childRows, function (rightModule) {
                                _.forEach(rightModule.childRows, function (right) {
                                    _.forEach(right.roles, function (role) {
                                        var r = right.mapByRole[role.id];
                                        if (r.isDirty) {
                                            var rRecord = new RoleRightRecord();
                                            rRecord.roleId = role.id;
                                            rRecord.rightId = r.id;
                                            rRecord.isEnabled = r.isEnabled;
                                            req.otherRoleRights.push(rRecord);
                                        }
                                    });
                                });
                            });
                        });
                        //collect dirty menus
                        _.forEach(this.container.menuModuleGroupRows, function (groupModuleRow) {
                            _.forEach(groupModuleRow.childRows, function (menuModuleRow) {
                                _.forEach(menuModuleRow.childRows, function (menuRow) {
                                    _.forEach(menuRow.roles, function (role) {
                                        var m = menuRow.mapByRole[role.id];
                                        if (m.isDirty) {
                                            var mRecord = new RoleMenuModuleRecord();
                                            mRecord.menuId = m.id;
                                            mRecord.isEnabled = m.isEnabled;
                                            mRecord.roleId = role.id;
                                            req.roleMenuModules.push(mRecord);
                                        }
                                    });
                                    _.forEach(menuRow.childRows, function (submenuRow) {
                                        _.forEach(submenuRow.roles, function (role) {
                                            var f = submenuRow.mapByRole[role.id];
                                            if (f.isDirty) {
                                                var sRecord = new RoleMenuModuleRecord();
                                                sRecord.menuId = f.id;
                                                sRecord.isEnabled = f.isEnabled;
                                                sRecord.roleId = role.id;
                                                req.roleMenuModules.push(sRecord);
                                            }
                                        });
                                    });
                                });
                            });
                        });
                        //collect dirty components
                        _.forEach(this.container.componentsModuleGroupRows, function (groupModuleRow) {
                            _.forEach(groupModuleRow.childRows, function (componentsModuleRow) {
                                _.forEach(componentsModuleRow.childRows, function (componentRow) {
                                    _.forEach(componentRow.roles, function (role) {
                                        var m = componentRow.mapByRole[role.id];
                                        if (m.isDirty) {
                                            var mRecord = new RoleComponentsModuleRecord();
                                            mRecord.componentId = m.id;
                                            mRecord.isEnabled = m.isEnabled;
                                            mRecord.roleId = role.id;
                                            req.roleComponentModules.push(mRecord);
                                        }
                                    });
                                });
                            });
                        });
                        modules = _.filter(this.container.productModules, function (m) { return m.isDirty; });
                        req.productModules = _.map(modules, function (m) { return _this.productModuleMapService.mapToProductModule(m); });
                        turnOfModules = _.filter(modules, function (m) { return m.isDisabled; });
                        if (!(turnOfModules.length > 0)) return [3 /*break*/, 2];
                        names = _.map(turnOfModules, function (m) { return "\"" + m.name + "\""; });
                        checkString_1 = '"Message Center"';
                        _.remove(names, function (name) { return name == checkString_1; });
                        if (!names.length) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.confirmModules(_.join(names))];
                    case 1:
                        res = _a.sent();
                        if (!res) {
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        this.onLoadStatusChanged(true);
                        this.apiService.saveRoleAccessTable(req)
                            .then(function (container) {
                            _this.container = container;
                            _this.onLoaded(container);
                            _this.onLoadStatusChanged(false);
                            _this.changeService.clearChanges();
                            _this.clearChanges$.next();
                        })
                            .catch(function (reason) {
                            _this.onLoadStatusChanged(false);
                            _this.changeService.clearChanges();
                            _this.clearChanges$.next();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    RolesManagementService.prototype.confirmModules = function (modulesList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                options = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                options.className = 'slx-confirm-wrap';
                options.width = 500;
                options.height = 250;
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        ConfirmDialogComponent.openDialog('Confirmation', "Are you sure you want to Turn OFF " + modulesList + "?\r\nIf yes, you will lose all data for " + modulesList + " and this cannot be undone.\r\nData entered in V6 will be lost once the nightly process is run to reactivate the " + modulesList + " in V5", _this.modalService, function (result) {
                            resolve(result);
                        }, options);
                    })];
            });
        });
    };
    RolesManagementService.prototype.dicardChanges = function () {
        this.changeService.clearChanges();
        this.clearChanges$.next();
        this.reLoadAccessTable();
    };
    RolesManagementService.prototype.onChangeNotify = function (source) {
        this.changeService.changeNotify(source);
        this.changeNotify$.next(source);
    };
    RolesManagementService.prototype.changeRolesColumnsState = function (roleColumnsState) {
        this.saveControl(roleColumnsState.columns);
        this.rolesColumnsStateChanged$.next(roleColumnsState);
    };
    RolesManagementService.prototype.subscribeToLoad = function (skipFirstCallSubscription) {
        var _this = this;
        var firstCall = true;
        this.loadSubscription = this.onLoaded$.subscribe(function (container) {
            if (skipFirstCallSubscription && firstCall) {
                firstCall = false;
                return;
            }
            var restoredFilter = _this.restoreControl();
            var roleColumnsState = new RoleColumnsState(container.roles);
            if (_.isArray(restoredFilter)) {
                if (_.size(restoredFilter) !== _.size(container.roles)) {
                    var newColumns = _.differenceWith(roleColumnsState.columns, restoredFilter, _.isEqual);
                    _.each(newColumns, function (col) { return col.visible = true; });
                }
                roleColumnsState.applyState(restoredFilter);
            }
            else {
                var defaultRoles = _.slice(roleColumnsState.columns, 0, 5);
                _.each(defaultRoles, function (r) { return r.visible = true; });
                roleColumnsState.applyFilter(defaultRoles);
            }
            _this.changeRolesColumnsState(roleColumnsState);
            _this.loadSubscription.unsubscribe();
            firstCall = false;
        });
    };
    RolesManagementService.prototype.saveControl = function (columns) {
        this.storageService.setControlState(this.stateService.componentKey, this.controlName, {
            value: columns
        }, this.resetBy);
    };
    RolesManagementService.prototype.restoreControl = function () {
        var state = this.storageService.getControlState(this.stateService.componentKey, this.controlName);
        var savedColumns = _.get(state, 'value');
        if (_.isArray(savedColumns)) {
            return savedColumns;
        }
        return null;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], RolesManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementService.prototype, "loadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementService.prototype, "initSubscription", void 0);
    return RolesManagementService;
}(ManagementBaseService));
export { RolesManagementService };
