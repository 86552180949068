export class EmployeePayRateComponent {
    public component: string;
    public rate: number;
}

export interface IEmployeePayRateComponent {
    component: string;
    rate: number;
}

export interface IEmployeePayRateComponents {
    records: IEmployeePayRateComponent[];
}