import { Shift } from './shift';

export class Employee {
  public sortSequence: number;
  public recordType: string;
  public id: number;
  public name: string;
  public hireDate: Date;
  public shift: string;
  public primaryPosition: string;
  public schedule: { [date: string]: Shift };
  public weeklyTotals: { [week: string]: number };
  public scheduleChanged: { [date: string]: boolean };
  public type: string = 'employee';
}
