import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { ExceptionConsoleMapService } from './exception-console-map.service';
import { IExceptionConsoleRecord, ExceptionConsoleRecord, ExceptionConsoleContainer, ExceptionConsoleRollupRecord, IExceptionConsoleRollupRecord, IExceptionConsoleContainer } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { IExceptionConsoleRollupContainer, ExceptionConsoleRollupContainer } from '../../models/exception-console/exceptions-console-rollup-container';

@Injectable()
export class ExceptionConsoleApiService {
  constructor(
    private mapService: ExceptionConsoleMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getExceptionConsoleRecords(orgLevelId: number, startDate: Date, endDate: Date, exactPayCycle: boolean): Promise<ExceptionConsoleContainer> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getExceptionConsoleApiRoot()}/${timeAndAttendanceConfig.api.exceptionConsole.orgLevel.root}/${orgLevelId}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      orgLevelId: orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate),
      exactPayCycle: exactPayCycle
    });
    let promise: Promise<ExceptionConsoleContainer> = this.apiUtilService.request<IExceptionConsoleContainer, Meta>(request)
      .then((response: ResponseBody<IExceptionConsoleContainer, Meta>) => {
        return this.mapService.mapExceptionConsoleContainer(response.data);
      });
    return promise;
  }

  public getExceptionConsoleRollupRecords(orgLevelId: number, startDate: Date, endDate: Date): Promise<ExceptionConsoleRollupContainer> {

    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getExceptionConsoleApiRoot()}/${timeAndAttendanceConfig.api.exceptionConsole.rollup}/${timeAndAttendanceConfig.api.exceptionConsole.orgLevel.root}/${orgLevelId}`;

    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let promise: Promise<ExceptionConsoleRollupContainer> = this.apiUtilService.request<IExceptionConsoleRollupContainer, Meta>(request)
      .then((response: ResponseBody<IExceptionConsoleRollupContainer, Meta>) => {
        return this.mapService.mapExceptionConsoleRollupContainer(response.data);
      });
    return promise;
  }

  private getExceptionConsoleApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.exceptionConsole.root}`;
  }

}
