export interface IWorkersCompensationConfig {
  settings: {
    phoneMask:{
      mask: any[],
      guide: boolean
    },
    minDateString: string;
  };
  files: {
    acceptedTypes: string[];
    maxFileSizeBytes: number;
    maxFiles: number;
  };
  api: {
    wc: {
      root: string;
      roster: {
        root: string;
      };
      comparisons: {
        root: string;
      };
      osha: {
        root: string;
      };
      employee: string;
      report: string;
      attachment: string;
      codes: string;
      reportStatuses: string;
      medTreatments: string;
      compCases: string;
    },
    configuration: {
      root: string;
      wccode: {
        root: string;
      },
      codeTypes: {
        root: string;
      }
    }
  };
}

export const wcConfig: IWorkersCompensationConfig = {
  settings: {
    phoneMask:{
      mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
      guide: true
    },
    minDateString: '01/01/1900'
  },
  files: {
    acceptedTypes: ['.csv', '.doc', '.docm', '.docx', '.gif', '.jpeg', '.jpg', '.pdf', '.png', '.tiff', '.txt', '.xls', '.xlsm', '.xlsx'],
    maxFileSizeBytes: 10485760,
    maxFiles: 100
  },
  api: {
    wc: {
      root: 'wc',
      roster: {
        root: 'roster'
      },
      comparisons: {
        root: 'comparison'
      },
      osha: {
        root: 'rosterOsha'
      },
      employee: 'employee',
      report: 'report',
      attachment: 'attachment',
      codes: 'codes',
      reportStatuses: 'reportStatuses',
      medTreatments: 'medTreatments',
      compCases: 'compCases'
    },
    configuration: {
      root: 'configuration',
      wccode: {
        root: 'workersCompensationCode'
      },
      codeTypes: {
        root: 'codeTypes'
      }
    }
  }
};
