import * as tslib_1 from "tslib";
import { ExportDataExecutionStatus } from '../export-data-execution-status';
var AcaExportExecutionStatus = /** @class */ (function (_super) {
    tslib_1.__extends(AcaExportExecutionStatus, _super);
    function AcaExportExecutionStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AcaExportExecutionStatus;
}(ExportDataExecutionStatus));
export { AcaExportExecutionStatus };
