import { PayCode } from '../../../configuration/models/index';

export interface IAttendancePointsCategory {
  name: string;
}

export interface IAttendancePointsDefinition {
  id: number;
  definition: string;
  points: number;
  category: IAttendancePointsCategory;
  exceptionNames: string[];
}

export class AttendancePointsCategory {
  public name: string;
}

export class AttendancePointsDefinition {
  public id: number;
  public definition: string;
  public points: number;
  public category: AttendancePointsCategory;
  public exceptions: PayCode[];
}

