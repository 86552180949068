import { IOrganizationPayrollContainer, OrganizationPayrollContainer } from './../../models/payroll-export-test/organization-payroll-container';
import { IPayrollExportConfigContainer, PayrollExportConfigContainer } from './../../models/payroll-export-test/payroll-export-config-container';
import { AfterTestActionCodes } from './../../models/payroll-export-test/after-test-action-codes';
import { PayrollExportTestStateStatuses, PayrollExportTestStateStatusLabels } from './../../models/payroll-export-test/payroll-export-test-state-statuses';
import { IPayrollExportTestsContainer, PayrollExportTestsContainer } from './../../models/payroll-export-test/payroll-export-test-container';
import { PayrollExportTestState } from './../../models/payroll-export-test/payroll-export-test-state';
import { LookupMapService } from './../../../../organization/services/lookup/lookup-map.service';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { dateTimeUtils } from '../../../../common/utils/index';
import { IOrganizationPayroll, OrganizationPayroll } from '../../models/payroll-export-test/organization-payroll';
import { IPayrollExportTestState } from '../../models/payroll-export-test/payroll-export-test-state';
import { IPayrollExportConfig, PayrollExportConfig } from '../../models/payroll-export-test/payroll-export-config';
import { IOrgPayrollExportStatus, OrgPayrollExportStatus } from '../../models/payroll-export-test/org-payroll-export-status';
import { ExportPayrollTimeInterval, IExportPayrollTimeInterval } from '../../models/payroll-export-test/export-payroll-time-interval';


@Injectable()
export class PayrollExportTestMapService {

    constructor(private lookupMapService: LookupMapService) {
    }

    public mapPayrollExportTestsContainer(dto: IPayrollExportTestsContainer): PayrollExportTestsContainer {
        const cont: PayrollExportTestsContainer = new PayrollExportTestsContainer();
        cont.organizationPayroll = this.mapOrganizationPayroll(dto.organizationPayroll);
        cont.tests = this.mapPayrollExportTestStateList(dto.tests);
        return cont;
    }

    public mapOrganizationPayrollContainer(dto: IOrganizationPayrollContainer): OrganizationPayrollContainer {
        let container: OrganizationPayrollContainer = new OrganizationPayrollContainer();
        container.canExportToPayroll = dto.canExportToPayroll;
        container.canChangeConfig = dto.canChangeConfig;
        container.canUnlockTimecards = dto.canUnlockTimecards;
        container.globalExportSettingsEnabled = dto.globalExportSettingsEnabled;
        container.settingsAvailable = dto.settingsAvailable;
        container.organizationPayrollList = this.mapOrganizationPayrollList(dto.organizationPayrollList);
        return container;
    }

    public mapOrganizationPayrollList(dtos: IOrganizationPayroll[]): OrganizationPayroll[] {
        return _.map(dtos, dto => this.mapOrganizationPayroll(dto));
    }

    public mapOrganizationPayroll(dto: IOrganizationPayroll): OrganizationPayroll {
        const org: OrganizationPayroll = new OrganizationPayroll();
        org.organization = this.lookupMapService.mapOrganization(dto.organization);
        org.organizationPayrollId = dto.organizationPayrollId;
        org.state = dto.state;
        org.alertCount = dto.alertCount;
        org.failCount = dto.failCount;
        org.runAt = dateTimeUtils.convertFromDtoDateTimeString(dto.runAt);
        org.runBy = dto.runBy;
        org.runByUser = dto.runByUser;
        org.canExportToPayroll = dto.canExportToPayroll;
        org.isLocked = dto.isLocked;
        org.hasReleasedExports = dto.hasReleasedExports;
        org.payrollExportId = dto.payrollExportId;
        org.status = dto.status;
        org.payrollAppStatus = dto.payrollAppStatus;
        return org;
    }

    public mapPayrollExportTestStateList(dtos: IPayrollExportTestState[]): PayrollExportTestState[] {
        return _.map(dtos, dto => this.mapPayrollExportTestState(dto));
    }

    public mapPayrollExportTestState(dto: IPayrollExportTestState): PayrollExportTestState {
        const test: PayrollExportTestState = new PayrollExportTestState();
        test.id = dto.id;
        test.description = dto.description;
        test.testId = dto.testId;
        test.afterTestActionCode = dto.afterTestActionCode;
        test.completedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.completedAt);
        test.runAt = dateTimeUtils.convertFromDtoDateTimeString(dto.runAt);
        test.runById = dto.runById;
        test.runByUser = dto.runByUser;
        test.status = dto.status;
        test.summary = dto.summary;
        test.statusLabel = this.getTestStatusLabel(test.status, test.afterTestActionCode);
        return test;
    }

    public mapPayrollExportConfigContainerDTO(config: PayrollExportConfigContainer): IPayrollExportConfigContainer {
        return {
            id: config.id,
            lockTimecardsAfterExport: config.lockTimecardsAfterExport,
            configName: config.configName,
            organizationId: config.organizationId,
            configs: _.map(config.configs, (c: PayrollExportConfig) => {
                return this.mapPayrollExportConfigDTO(c);
            })
        };
    }

    public mapPayrollExportConfigDTO(config: PayrollExportConfig): IPayrollExportConfig {
        return {
            id: config.id,
            configId: config.configId,
            testId: config.testId,
            code: config.code,
            testName: config.testName
        };
    }

    public mapPayrollExportConfigContainer(dto: IPayrollExportConfigContainer): PayrollExportConfigContainer {
        let container: PayrollExportConfigContainer = new PayrollExportConfigContainer();
        container.id = dto.id;
        container.lockTimecardsAfterExport = true; //dto.lockTimecardsAfterExport;
        container.configName = dto.configName;
        container.organizationId = dto.organizationId;
        container.configs = _.map(dto.configs, (c: IPayrollExportConfig) => {
            return this.mapPayrollExportConfig(c);
        });
        container.isGlobal = container.configName === 'Global';
        return container;
    }

    public mapPayrollExportConfig(dto: IPayrollExportConfig): PayrollExportConfig {
        let config: PayrollExportConfig = new PayrollExportConfig();
        config.id = dto.id;
        config.testId = dto.testId;
        config.code = dto.code;
        config.configId = dto.configId;
        config.testName = dto.testName;
        return config;
    }

    private getTestStatusLabel(status: PayrollExportTestStateStatuses, code: AfterTestActionCodes): PayrollExportTestStateStatusLabels {
        switch(status) {
            case PayrollExportTestStateStatuses.INIT:
                return PayrollExportTestStateStatusLabels.INIT;
            case PayrollExportTestStateStatuses.RUNNING:
                return PayrollExportTestStateStatusLabels.PROGRESS;
                case PayrollExportTestStateStatuses.SUCCEDED:
                return PayrollExportTestStateStatusLabels.PASS;
                case PayrollExportTestStateStatuses.FAILED:
                    if (code === AfterTestActionCodes.ALERT) {
                        return PayrollExportTestStateStatusLabels.ALERT;
                    } else {
                        return PayrollExportTestStateStatusLabels.FAIL;
                    }
                case PayrollExportTestStateStatuses.UNKNOWN:
                return PayrollExportTestStateStatusLabels.UNKNOWN;
        }
        return null;
    }

    public mapOrgPayrollExportStatusDTO(orgPayrollExportStatus: any): IOrgPayrollExportStatus {
        return {
            Id: orgPayrollExportStatus.id,
            PayrollRecordId: orgPayrollExportStatus.payrollRecordId,
            Status: orgPayrollExportStatus.status,
            CreatedAt: orgPayrollExportStatus.createdAt,
            CreatedBy: orgPayrollExportStatus.createdBy,
            UpdatedAt: orgPayrollExportStatus.updatedAt,
            UpdatedBy: orgPayrollExportStatus.updatedBy
        };
    }

    public mapOrgPayrollExportStatusDTOList(dtos: IOrgPayrollExportStatus[]): OrgPayrollExportStatus[] {
        return _.map(dtos, dto => this.mapOrgPayrollExportStatusDTO(dto));
    }

    public mapExportPayrollTimeIntervalDTO(exportPayrollTimeInterval: any): IExportPayrollTimeInterval {
        return {
            payrollExportId: exportPayrollTimeInterval.payrollExportId,
            intervalSeconds: exportPayrollTimeInterval.intervalSeconds,
            organizationPayrollId: exportPayrollTimeInterval.organizationPayrollId
        };
    }

    public mapExportPayrollTimeIntervalDTOList(dtos: IExportPayrollTimeInterval[]): ExportPayrollTimeInterval[] {
        return _.map(dtos, dto => this.mapExportPayrollTimeIntervalDTO(dto));
    }
}

