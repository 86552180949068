/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./msg-grid-thumbnail-src.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./msg-grid-thumbnail-src.component";
import * as i4 from "../../../../organization/services/employee/employee-images.service";
var styles_MsgGridThumbnailSrcComponent = [i0.styles];
var RenderType_MsgGridThumbnailSrcComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MsgGridThumbnailSrcComponent, data: {} });
export { RenderType_MsgGridThumbnailSrcComponent as RenderType_MsgGridThumbnailSrcComponent };
function View_MsgGridThumbnailSrcComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "leave-request-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Employee Photo"], ["class", "img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.imgPhoto, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_MsgGridThumbnailSrcComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), ((_co.employeeName == null) ? null : _co.employeeName.charAt(0)))); _ck(_v, 1, 0, currVal_0); }); }
function View_MsgGridThumbnailSrcComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "compose-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Employee Photo"], ["class", "img-compose"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.imgPhoto, ""); _ck(_v, 1, 0, currVal_0); }); }
function View_MsgGridThumbnailSrcComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "compose-circle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "firstLetterText"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "compose-circle"; var currVal_1 = ((!_co.mobilePhoneNumber || (_co.empOptIn != 1)) ? "avatar-disable" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), ((_co.employeeName == null) ? null : _co.employeeName.split("")[0]))); _ck(_v, 4, 0, currVal_2); }); }
export function View_MsgGridThumbnailSrcComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MsgGridThumbnailSrcComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MsgGridThumbnailSrcComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MsgGridThumbnailSrcComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MsgGridThumbnailSrcComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.imgPhoto && (_co.parent === "messageGrid")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_co.imgPhoto && (_co.parent === "messageGrid")); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.imgPhoto && (_co.parent === "composeNewModal")); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_co.parent === "composeNewModal") && !_co.imgPhoto); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_MsgGridThumbnailSrcComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-msg-grid-thumbnail-src", [], null, null, null, View_MsgGridThumbnailSrcComponent_0, RenderType_MsgGridThumbnailSrcComponent)), i1.ɵdid(1, 573440, null, 0, i3.MsgGridThumbnailSrcComponent, [i4.EmployeeImagesService], null, null)], null, null); }
var MsgGridThumbnailSrcComponentNgFactory = i1.ɵccf("slx-msg-grid-thumbnail-src", i3.MsgGridThumbnailSrcComponent, View_MsgGridThumbnailSrcComponent_Host_0, { employeeId: "employeeId", employeeName: "employeeName", parent: "parent", mobilePhoneNumber: "mobilePhoneNumber", empOptIn: "empOptIn" }, {}, []);
export { MsgGridThumbnailSrcComponentNgFactory as MsgGridThumbnailSrcComponentNgFactory };
