import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Assert } from '../../../../framework/index';
import { StateResetTypes } from '../../../../core/models/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { ComponentStateStorageService, StateManagementService } from '../../../../common/services/index';
var BenefitConsoleDetailsCommonService = /** @class */ (function () {
    function BenefitConsoleDetailsCommonService(storageService, stateManagement) {
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.showExpiredPlansState$ = new ReplaySubject(1);
        this.viewShowExpiredPlans = 'notShowingExpired';
        this.resetBy = StateResetTypes.SessionEnd;
        this.subscriptions = {};
        this.init('BenefitConsoleDetailsCommonService');
    }
    BenefitConsoleDetailsCommonService.prototype.init = function (componentId) {
        if (_.isNil(this.componentId)) {
            this.componentId = componentId;
            this.restoreState();
        }
    };
    BenefitConsoleDetailsCommonService.prototype.destroy = function () { };
    BenefitConsoleDetailsCommonService.prototype.subscribeToShowExpiredPlansState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.showExpiredPlansState$.subscribe(callback);
    };
    BenefitConsoleDetailsCommonService.prototype.saveIsShowingExpiredPlansState = function (isShowingExpiredPlans) {
        this.storageService.setControlState(this.componentId, this.viewShowExpiredPlans, { value: isShowingExpiredPlans }, this.resetBy);
    };
    BenefitConsoleDetailsCommonService.prototype.restoreState = function () {
        var _this = this;
        this.stateManagement.init(this.componentId, true);
        this.subscriptions.isShowingExpiredPlans = this.stateManagement.onInit$
            .subscribe(function () {
            var state = _this.storageService.getControlState(_this.componentId, _this.viewShowExpiredPlans);
            var value = _.isBoolean(state.value) ? state.value : false;
            _this.showExpiredPlansState$.next(value);
        });
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], BenefitConsoleDetailsCommonService.prototype, "subscriptions", void 0);
    return BenefitConsoleDetailsCommonService;
}());
export { BenefitConsoleDetailsCommonService };
