import { Component, OnInit, Input, Renderer2, ElementRef, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { FavoriteManagementService } from '../../../configuration/services/favorites/favorites-management.service';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators';
import { NavigationMenuItem } from '../../models/navigation-menu-item';
import { NavigationMenuRedirectService } from '../../services/navigation/navigation-menu-redirect.service';

@Component({
  moduleId: module.id,
  selector: 'slx-favorites-menu',
  templateUrl: './favorites-menu.component.html',
  styleUrls: ['./favorites-menu.component.scss'],
})
export class FavoritesMenuComponent implements OnInit, AfterViewInit {
  
  @Input()
  public set show(value: boolean) {
    this.visibilityChanged(value);
  }
  @Input()
  public set top(value: number) {
    this.positionChanged(value, null, null);
  }
  @Input()
  public set left(value: number) {
    this.positionChanged(null, value, null);
  }
  @Input()
  public set pointerIconShift(value: number) {
    this.positionChanged(null, null, value);
  }
  public get favoriteViewElem(): HTMLElement {
    return this.elementRef.nativeElement as HTMLElement;
  }

  public isViewInit: boolean;
  public isShowFavorites: boolean;
  public posTop: number;
  public posLeft: number;
  public iconShift: number;
  public isLocked: boolean;
  public searchValue: string;

  public menuItems: NavigationMenuItem[];
  public favorites: NavigationMenuItem[];

  public visibilityChanged(isShow: boolean): void {
    this.isShowFavorites = isShow;
    if (this.isViewInit) {
      this.setVisibility();
    }
  }

  public positionChanged(top: number, left: number, iconShift: number): void {
    if (_.isNumber(top)) this.posTop = top;
    if (_.isNumber(left)) this.posLeft = left;
    if (_.isNumber(iconShift)) this.iconShift = iconShift;
    if (this.isViewInit) {
      this.setPosition();
    }
  }

  @unsubscribe()
  public menuSubscription: Subscription;
  
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private favService: FavoriteManagementService,
    private navService: NavigationMenuRedirectService
  ) {
    this.isShowFavorites = false;
    this.isViewInit = false;
    this.isLocked = true;
    this.searchValue = "";
   }

  public ngOnInit() {
    this.menuSubscription = this.favService.favoriteItemsUpdated$.subscribe((menuItems: NavigationMenuItem[]) => {
      this.menuItems = menuItems;
      this.onClearSearch();
    });
  }

  public ngAfterViewInit(): void {
    this.isViewInit = true;
    this.setVisibility();
    this.setPosition();
  }

  public setPosition(): void {
    this.renderer.setStyle(this.favoriteViewElem, 'top', `${this.posTop}px`);
    this.renderer.setStyle(this.favoriteViewElem, 'left', `${this.posLeft}px`);
    const pointerIcon: HTMLElement = this.favoriteViewElem.querySelector('.js-pointer');
    if (_.isElement(pointerIcon)) {
      const styles: CSSStyleDeclaration = window.getComputedStyle(pointerIcon);
      this.renderer.setStyle(pointerIcon, 'left', `${this.iconShift - (parseInt(styles.width) / 2)}px`);
    }
  }

  public setVisibility(): void {
    if (this.isShowFavorites) {
      this.renderer.addClass(this.favoriteViewElem, 'show');
    } else {
      this.onClearSearch();
      this.renderer.removeClass(this.favoriteViewElem, 'show');
    }
  }

  public open(menu: NavigationMenuItem): void {
    this.navService.open(menu);
  }

  public filterFavorites(value: string): void{
    this.favorites = this.menuItems.filter((res) => res.displayName.toLowerCase().includes(value.toLowerCase()));
  }

  public onClearSearch(): void {
    this.searchValue = "";
    this.favorites = this.menuItems;
  }

  public showConfigDialog(): void{
    this.onClearSearch();
    this.favService.showConfigDialog();
  }
}
