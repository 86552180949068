export interface IEmployeeSectionsConfig {
  files: {
    acceptedTypes: string[];
    maxFileSizeBytes: number;
  },
  api: {
    employees: {
      root: string;
      list: string;
      properties: string;
      notes: {
        root: string;
      };
      documents: string;
      sections: {
        root: string;
        personal: {
          root: string;
          save: string;
          profile: string;
          contacts: string;
          emergencyContacts: string;
          dnr:string,
          certifications: string,
          warnings: string,
          newWarning: string,
          downloadWarning: string,
          licenses: string;
          physicals: string;
          documents: string;
          backgroundcheck: string;
          education: string;
        },
        employment: {
          root: string;
          payroll: string;
          positions: string;
          termination: string;
          ratehistory: string;
          positionhistory: string;
          leaveManagement: string;
          timeclocks: {
            root: string;
            resubmitTimeclocks: string;
          }
          paycycles: {
            root: string;
            regenerate: string;
          },
          selfService: string;
          essTemplate: string;
          i9: string;
          telepunch: string;
          workersCompensation: {
            root: string;
          }
          performanceManagement: {
            root: string;
          }
          aca: {
            root: string;
          }
        },
        custom: {
          root: string;
          customfields: string;
        },
        audit: {
          root: string;
          futureUpdates: string;
          audittrail: string;
        },
        performance: {
          root: string;
          attendance: string;
          attendancePoints: string;
          buybackPoints: string;
          assignedDefintions: string;
        },
        schedule: {
          root: string;
          availability: string;
          rotations: {
            root: string;
            restore: string;
            generate: string;
            import: {
              root: string;
              employees: string;
            }
          }
        },
        benefitsManagement: {
          root: string,
          enrollment: string,
          benefitHistory:string,
          dependents: string,
          benefitClasses: string,
          notes: string,
          attachments:string,
          download:string,
        },
        accruals: {
          root: string;
          policyAssignment: string;
          policyBalance: string;
          policy: {
            root: string;
            all: string;
            assign: string;
          }
          transactions: string;
          accrualTypes: string;
          balances: string;
          balance: {
            root: string;
            reset: string;
          }
          calculate: string;
        },
        workplace: string;
      }
      validation: {
        root: string;
        ssn: string;
        payrollNumber: string;
        names: string;
        badgeId: string;
      }
      actions: {
        root: string;
        list: string;
        group: {
          root: string;
          sms: string;
        }
        generatePassword: string;
        generateBadgeId: string;
      };
    };
    schedule: {
      root: string;
      communications: {
        root: string;
        calls: {
          root: string;
          log: {
            root: string
            employee: string;
          }
        }
      };
    };
    app: {
      root: string;
      settings: {
        root: string;
        accruals: {
          root: string;
          allowDynamicAccrualPolicies: string
        }
      }
    }
  };
  nonEditableSubsections: StringMap<boolean>;
  editableSubsectionsWhenEmpTerminated: Set<string>;
  nonEditableSubsectionsWhenEmpFutureRehire: Set<string>;
}

export const employeeSectionsConfig: IEmployeeSectionsConfig = {
  files: {
    acceptedTypes: ['.csv', '.doc', '.docm', '.docx', '.gif', '.jpeg', '.jpg', '.pdf', '.png', '.tiff', '.txt', '.xls', '.xlsm', '.xlsx'],
    maxFileSizeBytes: 10485760,
  },
  api: {
    employees: {
      root: 'employees',
      list: 'list',
      notes: {
        root: 'notes'
      },
      documents: 'documents',
      sections: {
        root: 'sections',
        personal: {
          root: 'personal',
          save: 'save',
          profile: 'profile',
          contacts: 'contacts',
          emergencyContacts: 'emergencyContacts',
          dnr: 'agencyCertificate',
          certifications: 'certifications',
          warnings: 'warnings',
          newWarning: 'new',
          downloadWarning: 'download',
          licenses: 'licenses',
          physicals: 'physicals',
          documents: 'documents',
          backgroundcheck: 'backgroundcheck',
          education: 'education',
        },
        employment: {
          root: 'employment',
          payroll: 'payroll',
          positions: 'positions',
          termination: 'termination',
          ratehistory: 'ratehistory',
          positionhistory: 'positionhistory',
          leaveManagement: 'leaveManagement',
          timeclocks: {
            root: 'timeclocks',
            resubmitTimeclocks: 'resubmitTimeclocks'
          },
          paycycles: {
            root: 'paycycles',
            regenerate: 'regenerate'
          },
          selfService: 'selfService',
          essTemplate: 'essTemplate',
          i9: 'i9',
          telepunch: 'telepunch',
          workersCompensation: {
            root: 'workersCompensation'
          },
          performanceManagement: {
            root: 'performanceManagement'
          },
          aca: {
            root: 'aca'
          }
        },
        custom: {
          root: 'custom',
          customfields: 'fields'
        },
        audit: {
          root: 'audit',
          futureUpdates: 'futureUpdates',
          audittrail: 'audittrail'
        },
        performance: {
          root: 'performance',
          attendance: 'attendance',
          attendancePoints: 'attendancePoints',
          buybackPoints: 'buybackPoints',
          assignedDefintions: 'assignedDefintions'
        },
        schedule: {
          root: 'schedule',
          availability: 'availability',
          rotations: {
            root: 'rotations',
            restore: 'restore',
            generate: 'generate',
            import: {
              root: 'import',
              employees: 'employees'
            }
          }
        },
        benefitsManagement: {
          root: 'benefitsManagement',
          enrollment: 'enrollment',
          benefitHistory:'benefitsEnrollmentHistory',
          dependents: 'dependents',
          benefitClasses: 'benefitClasses',
          notes:'notes',
          attachments:'attachments',
          download:'download',
        },
        accruals: {
          root: 'accruals',
          policyAssignment: 'policyAssignment',
          policyBalance: 'policyBalance',
          policy: {
            root: 'policy',
            all: 'all',
            assign: 'assign',
          },
          balances: 'balances',
          balance: {
            root: 'balance',
            reset: 'reset'
          },
          transactions: 'transactions',
          accrualTypes: 'accrualTypes',
          calculate: 'calculate',
        },
        workplace: 'workplace',
      },
      properties: 'properties',
      actions: {
        root: 'actions',
        list: 'list',
        group: {
          root: 'group',
          sms: 'sms'
        },
        generatePassword: 'generatePassword',
        generateBadgeId: 'generateBadgeId'
      },
      validation: {
        root: 'validation',
        ssn: 'ssn',
        payrollNumber: 'payrollNumber',
        names: 'names',
        badgeId: 'badgeId',
      }
    },
    schedule: {
      root: 'schedule',
      communications: {
        root: 'communications',
        calls: {
          root: 'calls',
          log: {
            root: 'log',
            employee: 'employee'
          }
        }
      }
    },
    app: {
      root: 'app',
      settings: {
        root: 'settings',
        accruals: {
          root: 'accruals',
          allowDynamicAccrualPolicies: 'allowDynamicAccrualPolicies'
        }
      }
    },
  },
  nonEditableSubsections: {
    FutureUpdates: false,
    //TermHistory: false,
    RateHistory: false,
    PositionHistory: false,
    AttendancePoints: false,
    AuditTrail: false,
    PayCycles: false,
    Rotations: false,
    Accruals: false,
    //Availability: false,
    Attendance: false,
    BenefitsEnrollmentHistory:false
  },
  editableSubsectionsWhenEmpTerminated: new Set([
    'ContactsInfo',
    'EmergencyContacts',
    'Transactions',
    'EmpWarningsData',
    'Documents',
    'BackgroundCheck',
    'Dependents',
    'BenefitsEnrollmentHistory',
    'TermHistory'
  ]),
  nonEditableSubsectionsWhenEmpFutureRehire: new Set([
    'EmpWarningsData',
    'LeaveManagement',
    'WorkersCompensation',
    'PerformanceManagement',
    'Transactions',
    'AttendancePoints'
  ])
};
