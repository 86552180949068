import { Component, OnDestroy, OnInit, Input, NgZone, Host, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsCustomFields, EmployeeSectionsTemporalModel, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsCustomApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

import { FieldData } from '../../../../../core/models/index';
import { dateTimeUtils } from './../../../../../common/utils';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-custom-fields',
  templateUrl: 'employee-sections-custom-fields.component.html',
  styleUrls: ['employee-sections-custom-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsCustomFieldsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {
  @Input('employeeSectionsCustomFields')
  public set physicals(employeeSectionsCustomFields: EmployeeSectionsCustomFields) {
    this.employeeSectionsCustomFields = employeeSectionsCustomFields;
    if(this.employeeSectionsCustomFields !== null) {
      this.employeeSectionsCustomFields.fields.forEach(element => {
        if(element.dataType.toLowerCase() === 'bit' || element.dataType.toLowerCase() === 'char') {
          element.fieldData.fieldValue = {id: element.fieldData.fieldValue, name: element.fieldData.fieldValue}
        }
      });
    }
  }
  @Input() public employeeId: number;
  public isInvalidData: boolean;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  public set specialConsideration(value: FieldData<string>) {
    if (this.employeeSectionsCustomFields)
      this.employeeSectionsCustomFields.specialConsideration = value;
  }

  public get specialConsideration(): FieldData<string> {
    return this.employeeSectionsCustomFields ? this.employeeSectionsCustomFields.specialConsideration : null;
  }

  public set specialConsideration2(value: FieldData<string>) {
    if (this.employeeSectionsCustomFields)
      this.employeeSectionsCustomFields.specialConsideration2 = value;
  }

  public get specialConsideration2(): FieldData<string> {
    return this.employeeSectionsCustomFields ? this.employeeSectionsCustomFields.specialConsideration2 : null;
  }

  @ViewChild('form', { static: false })
  public ngForm: NgForm;
  public employeeSectionsCustomFields: EmployeeSectionsCustomFields;
  public bitValues = [{id:'True', name: 'True'}, {id:'False', name: 'False'}];
  public charValues = [{id:'Y', name: 'Y'}, {id:'N', name: 'N'}];
  public pattern = '^/\d+$';
  constructor(
    private employeeSectionsCustomApiService: EmployeeSectionsCustomApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone, private changeDetector: ChangeDetectorRef) {
    super(decorator, ngZone);
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsCustomFields;
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsCustomApiService.getCustomFields(this.employeeId)
      .then((employeeSectionsCustomFields: EmployeeSectionsCustomFields) => {
        this.employeeSectionsCustomFields = employeeSectionsCustomFields;
        this.employeeSectionsCustomFields.fields.forEach(element => {
          if (element.dataType.toLowerCase() === 'bit' || element.dataType.toLowerCase() === 'char') {
            element.fieldData.fieldValue = { id: element.fieldData.fieldValue, name: element.fieldData.fieldValue }
          }
          if (element.displayName == "AccrualsEligibilityDate") {
            if (!dateTimeUtils.validateDate(element.fieldData.fieldValue)) {
              this.isInvalidData = true;
              this.disableSaveButton(); 
            }
          }
        });
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }).catch(() => {
        this.stopProgress();
      });
  }

  protected doSave(effectiveDate: Date): void {
    this.employeeSectionsCustomApiService.setCustomFields(this.employeeId, this.employeeSectionsCustomFields, effectiveDate)
      .then(() => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    if (!this.employeeSectionsCustomFields) return null;
    let temporalModel: EmployeeSectionsTemporalModel = this.metaFieldsTemporalDirtyArrayChecker(this.employeeSectionsCustomFields.fields);
    return null;
  }

  checkIntLength(field) {
    if (field.fieldData.fieldValue !== null) {
      if (parseInt(field.fieldData.fieldValue)) {
        if (parseInt(field.fieldData.fieldValue) > 2147483647) {
          this.disableSaveButton(); 
          return true;
        } else {
          this.disableSaveButton(); 
          return false;
        }
      } else {
        this.disableSaveButton(); 
        return false;
      }
    }
    this.disableSaveButton();     
  }

  checkDecimalLength(field) {
    if (field.fieldData.fieldValue !== null) {
      if (parseFloat(field.fieldData.fieldValue) > 999999.00) {
        this.disableSaveButton(); 
        return true;
      } else if (field.fieldData.fieldValue.toString().includes('.')) {
        let decimalLength = field.fieldData.fieldValue.toString().split('.')[1];
        if (decimalLength.toString().length > parseInt(field.dataTypePrecision)) {
          this.disableSaveButton(); 
          return true;
        } else {
          this.disableSaveButton(); 
          return false;
        }
      }
    }
    
  }


  onChangeDate(e: any) {
    if (dateTimeUtils.validateDate(e)) {
      return this.isInvalidData = false;
    }
    else {
      return this.isInvalidData = true;
    }
  }

  disableSaveButton() {
    setTimeout(() => {
      const htmlElement = document.getElementById('CustomFields');
      const btnElement = htmlElement.getElementsByClassName('subection-save');
      const intErrorMsg = document.getElementById('integerErrorMsg');
      const decimalErrorMsg = document.getElementById('decimalErrorMsg');
      if (btnElement.length > 0) {
        if(intErrorMsg !== null || decimalErrorMsg !== null) {
          btnElement[0]['disabled'] = true;
        } else {
          btnElement[0]['disabled'] = false;
        }
      }
    }, 100);
  } 

  removeFieldValue(field) {
    return field.fieldData.fieldValue ? field.fieldData.fieldValue = null : field.fieldData.fieldValue;
  }
}
