
export class AcaExportCorrected1095C {
  public type: number;
  public employeeId: number;
  public employeeIdMasterId: number;
  public employeeName: string;
  public recordId: string;
  public ssn: string;
  public ssnStatus: number;
  public empOrgId: number;
  public companyId: number;
  public matchId: number;
  public isSelected: boolean;
}

export class IAcaExportCorrected1095C {
  type: number;
  employeeId: number;
  employeeIdMasterId: number;
  employeeName: string;
  recordId: string;
  ssn: string;
  ssnStatus: number;
  empOrgId: number;
  companyId: number;
  matchId: number;
}