import * as _ from 'lodash';
import { Directive, Input, OnInit, OnDestroy, Host } from '@angular/core';

import { ColumnComponent } from '@progress/kendo-angular-grid';

import { DevicesDefinition, DependsOnDeviceTypeHelper } from '../../../models/index';
import { DeviceDetectorService } from '../../../services/index';

@Directive({
  selector: '[kendoGridColumnFilterableOn]',
})
export class KendoGridColumnFilterableOnDirective extends DependsOnDeviceTypeHelper implements OnInit, OnDestroy {
  @Input()
  public set kendoGridColumnFilterableOn(v: DevicesDefinition[]) {
    if (_.isArray(v)) {
      this.devices = v;
      this.validateDevices();
    }
  }

  constructor(@Host() private column: ColumnComponent, protected devDetector: DeviceDetectorService) {
    super();
    this.deviceList = this.devDetector.deviceList;
  }

  protected updateView(): void {
    this.column.filterable = this.deviceHasInDeviceList();
  }
}
