import { Directive, ViewContainerRef, ComponentFactoryResolver, HostListener, Input, ComponentRef, ComponentFactory } from '@angular/core';
import { PopoverContentComponent } from '../../components/popover-content/popover-content.component';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
@Directive({
  selector: '[popover]'
})
export class PopoverDirective {

  @Input('popover')
  public content: string | PopoverContentComponent;

  @Input('mode')
  public popupMode: 'click' | 'hover' = 'click'; // can be 'click' or 'hover'

  private viewContainerRef: ViewContainerRef;
  private resolver: ComponentFactoryResolver;
  private popoverRef: ComponentRef<PopoverContentComponent>;
  private visible: boolean;
  private isHidden: boolean = true;
  private subscriptions: StringMap<Subscription> = {};
  constructor(viewContainerRef: ViewContainerRef,
    resolver: ComponentFactoryResolver) {
    this.viewContainerRef = viewContainerRef;
    this.resolver = resolver;
  }


  @HostListener('click')
  public showOrHideOnClick(): void {
    if (this.popupMode === 'click') {
      this.toggle();
    }
  }


  @HostListener('mouseover')
  public showOnMouseover(): void {
    if (this.popupMode === 'hover') {
      this.show();
      this.isHidden = false;
    }
  }

  @HostListener('mouseout')
  public hideOnMouseout(): void {
    if (this.popupMode === 'hover') {
      this.isHidden = true;
      const subscr = Observable.of(true).delay(300).subscribe(() => {
        subscr.unsubscribe();
        if (this.isHidden)
          this.hide();
      });
    }
  }
  public getElement(): any {
    return this.viewContainerRef.element.nativeElement;
  }

  public toggle(): void {
    if (!this.visible) {
      this.show();
    } else {
      this.hide();
    }
  }

  public show(): void {
    if (this.visible) return;
    this.visible = true;
    let popover: PopoverContentComponent;
    if (_.isString(this.content)) {
      const factory: ComponentFactory<PopoverContentComponent> = this.resolver.resolveComponentFactory(PopoverContentComponent);
      if (!this.visible)
        return;
      this.popoverRef = this.viewContainerRef.createComponent(factory);
      popover = this.popoverRef.instance as PopoverContentComponent;
    } else {
      popover = this.content as PopoverContentComponent;
    }
    popover.popoverDir = this;
    this.subscriptions.closeOutside = popover.onCloseFromOutside.subscribe(() => this.hide());
    if (this.popupMode === 'hover') {
      this.subscriptions.mouseEnterMouseLeave = popover.onMouseLeaveMouseEnter.subscribe((isMouseover: boolean) => {
        if (isMouseover) {
          this.isHidden = false;
        } else {
          this.hide();
          this.isHidden = true;
        }
      });
    }
    popover.show();
  }

  public hide(): void {
    if (!this.visible) return;

    this.visible = false;
    if (this.popoverRef)
      this.popoverRef.destroy();

    if (this.content instanceof PopoverContentComponent) {
      let component: PopoverContentComponent = this.content as PopoverContentComponent;
      component.hideFromPopover();
    }

    if (this.subscriptions.mouseEnterMouseLeave)
      this.subscriptions.mouseEnterMouseLeave.unsubscribe();

    if (this.subscriptions.closeOutside)
      this.subscriptions.closeOutside.unsubscribe();
  }
}
