import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/combineLatest';
import { ViewModeSettings, SearchModeSettings, CoordsModeSettings, MapCircleChangedEvent } from '../../../models/index';
import { appConfig } from '../../../../../app/app.config';
var MapCircle = /** @class */ (function () {
    function MapCircle(lat, lng, r) {
        this.lat = lat;
        this.lng = lng;
        this.radius = r;
    }
    return MapCircle;
}());
export { MapCircle };
var GeolocationMapComponent = /** @class */ (function () {
    function GeolocationMapComponent() {
        this.externalAppUIUrl = appConfig.links.appLink;
        this.viewModeReady$ = new ReplaySubject();
        this.coordinatesModeReady$ = new ReplaySubject();
        this.searchModeReady$ = new ReplaySubject();
        this.overrideModeOn = false;
        this.notFoundFor = '';
        this.isUrl = false;
        this.viewModeDefaults = { radius: 300, commonZoom: 7, placeZoom: 16 };
        this.circleSettings = {
            canDrag: true,
            canEdit: true,
            circleColor: '#0092cb',
            circleOpacity: 0.5,
            freezedCanDrag: false,
            freezedCanEdit: false,
            freezedCircleColor: '#f68d2e',
            freezedCircleOpacity: 0.5
        };
        this.defaults = {
            maxRadius: 1000
        };
        this.errorCode = 0;
        this.subscriptions = {};
        this.fields = 'formatted_address,geometry,id,name,place_id,types';
        this.radiusChanged = new EventEmitter();
        this.centerChange = new EventEmitter();
        this.addressNotFound = new EventEmitter();
        this.geoUrl = "/assets/geo/geojs.html?url=" + encodeURI(this.externalAppUIUrl) + "&";
    }
    Object.defineProperty(GeolocationMapComponent.prototype, "viewModeSettings", {
        set: function (s) {
            // console.log("debug view",s);
            if (_.isObjectLike(s)) {
                this.checkViewModeSettings(s);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationMapComponent.prototype, "activeModeSettings", {
        set: function (s) {
            // console.log("debug active",s);
            if (_.isObjectLike(s) || _.isNull(s)) {
                this.checkActiveModeSettings(s);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationMapComponent.prototype, "overrideBy", {
        set: function (v) {
            // console.log("debug INPUT override",v);
            if (_.isObjectLike(v) && _.isFinite(v.lat) && _.isFinite(v.lng) || _.isNull(v)) {
                this.applyOverrideMode(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationMapComponent.prototype, "hasError", {
        get: function () {
            return _.isString(this.notFoundFor) && _.size(this.notFoundFor) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GeolocationMapComponent.prototype, "gMaps", {
        get: function () {
            return window.google.maps;
        },
        enumerable: true,
        configurable: true
    });
    GeolocationMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        window.addEventListener('message', function (event) {
            //   console.log("debug message", event, event.data.from, this.externalAppUIUrl, event.origin);
            // Ensure the origin is trusted (security measure)
            if (_this.externalAppUIUrl == null || !_this.externalAppUIUrl.startsWith(event.origin) || event.data.from === undefined || event.data.from !== 'geo') {
                console.log("debug Not GEO message", event);
                return;
            }
            // Access the data sent by the iframe
            var message = event.data;
            // console.log("debug received message", message);
            if (message.mode == 'error') {
                var errorMessage = "failed";
                _this.errorCode = message.errorCode;
                if (message.errorCode == 3) {
                    errorMessage = _this.searchMode.addressForSearch;
                }
                _this.showErrorMessage(errorMessage);
            }
            else {
                _this.location = { lat: message.lat, lng: message.lng };
                // console.log("debug received mode", message.mode);
                // Common for blue or orange circle
                if (message.modifiedField == 'radius') {
                    _this.onRadiusChange(message.radius);
                }
                // Find - maps to the blue circle
                if (message.mode == 'find' || message.mode == 'l') {
                    // console.log("debug updating address coordinates");
                    _this.showResults(_this.location);
                }
                else {
                    // override modes - orange circle
                    if (message.modifiedField == 'center') {
                        var c = new MapCircleChangedEvent(_this.location.lat, _this.location.lng, true);
                        _this.onCenterChange(c);
                    }
                }
            }
        });
        this.subscriptions.viewMode = this.viewModeReady$
            .subscribe(function () {
            // console.log("debug", this.viewMode);
            _this.mapLat = _this.viewMode.lat;
            _this.mapLng = _this.viewMode.lng;
            _this.mapZoom = _this.viewMode.zoom;
        });
        this.subscriptions.searchMode = this.searchModeReady$
            .subscribe(function () {
            _this.findAddress(_this.searchMode.addressForSearch);
        });
        this.subscriptions.searchMode = this.coordinatesModeReady$
            .subscribe(function () {
            _this.findLocation();
        });
    };
    GeolocationMapComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    GeolocationMapComponent.prototype.onRadiusChange = function (r) {
        var radius = r;
        /******
          We need to make sure user doesn't be able to set radius more than 1000,
          the only way to do this - update radius immediately if it exceeds max radius value
        ******/
        if (radius > this.defaults.maxRadius) {
            radius = this.circle.radius === this.defaults.maxRadius ? this.defaults.maxRadius - 1 : this.defaults.maxRadius;
        }
        if (this.circle.radius === radius) {
            return;
        }
        this.circle.radius = radius;
        this.radiusChanged.next(radius);
    };
    GeolocationMapComponent.prototype.onCenterChange = function (c, dragged) {
        if (dragged === void 0) { dragged = true; }
        this.centerChange.next(new MapCircleChangedEvent(c.lat, c.lng, dragged));
    };
    GeolocationMapComponent.prototype.checkViewModeSettings = function (s) {
        if (_.isNumber(s.lat) && _.isNumber(s.lng) && _.isNumber(s.zoom)) {
            this.viewMode = new ViewModeSettings(s.lat, s.lng, s.zoom);
            this.notFoundFor = null;
            this.viewModeReady$.next();
        }
    };
    GeolocationMapComponent.prototype.checkActiveModeSettings = function (s) {
        // console.log("debug checkActiveModeSettings", s);
        this.notFoundFor = null;
        if (_.isNull(s)) {
            this.resetToViewMode();
        }
        else if (s instanceof CoordsModeSettings) {
            this.applyCoordinatesMode(s);
        }
        else if (s instanceof SearchModeSettings) {
            this.applySearchMode(s);
        }
    };
    GeolocationMapComponent.prototype.applyCoordinatesMode = function (s) {
        // console.log("debug CoordsModeSettings", s);
        if (_.isNumber(s.lat) && _.isNumber(s.lng) && _.isNumber(s.circleRadius) && _.isNumber(s.zoom)) {
            this.coordinatesMode = new CoordsModeSettings(s.lat, s.lng, s.circleRadius, s.zoom);
            this.isUrl = true;
            this.url = this.geoUrl + "mode=l&lat=" + s.lat + "&lng=" + s.lng + "&radius=" + s.circleRadius;
            this.coordinatesModeReady$.next();
        }
    };
    GeolocationMapComponent.prototype.applySearchMode = function (s) {
        // console.log("debug applySearchMode", s);
        if (_.isString(s.addressForSearch) && _.isNumber(s.circleRadius) && _.isNumber(s.zoom)) {
            this.isUrl = true;
            this.url = this.geoUrl + "address=" + s.addressForSearch + "&radius=" + s.circleRadius;
            var d = new Date();
            // console.log("debug inside sesss", s, d.getMilliseconds());
            this.searchMode = new SearchModeSettings(s.addressForSearch, s.circleRadius, s.zoom);
            this.searchModeReady$.next();
        }
    };
    GeolocationMapComponent.prototype.applyOverrideMode = function (coords) {
        // console.log("debug applyoverride received cords settings", coords);
        this.notFoundFor = null;
        var overrideModeOn = !_.isNull(coords);
        if (this.overrideModeOn !== overrideModeOn) {
            this.overrideModeOn = overrideModeOn;
            // console.log("debug applyoverride", this.overrideModeOn);
            this.isUrl = true;
            if (this.overrideModeOn) {
                this.freezedCircle = new MapCircle(this.circle.lat, this.circle.lng, this.circle.radius);
                this.circle = new MapCircle(coords.lat, coords.lng, this.circle.radius);
                this.url = this.geoUrl + "mode=o&lat=" + this.freezedCircle.lat + "&lng=" + this.freezedCircle.lng + "&radius=" + coords.distance + "&olat=" +
                    coords.lat + "&olng=" + coords.lng + "&oradius=" + this.circle.radius;
                this.onCenterChange(this.getCoords(this.circle), false);
                // console.log("debug applyoverride", this.freezedCircle, this.circle);
            }
            else {
                if (_.isObjectLike(this.circle) && _.isObjectLike(this.freezedCircle)) {
                    this.circle = new MapCircle(this.freezedCircle.lat, this.freezedCircle.lng, this.freezedCircle.radius);
                    this.url = this.geoUrl + "mode=l&lat=" + this.circle.lat + "&lng=" + this.circle.lng + "&radius=" + this.circle.radius;
                    // console.log("debug applyoverride", this.circle);
                    this.onCenterChange(this.getCoords(this.circle), false);
                }
                this.freezedCircle = null;
            }
        }
    };
    GeolocationMapComponent.prototype.resetToViewMode = function () {
        // console.log("debug reset view mode");
        //this.activeModeSettings = null;
        this.mapLat = this.viewMode.lat;
        this.mapLng = this.viewMode.lng;
        this.mapZoom = this.viewMode.zoom;
        this.notFoundFor = null;
        this.url = this.geoUrl + "address=";
        this.circle = null;
    };
    GeolocationMapComponent.prototype.findAddress = function (address) {
        // console.log("debug findAddress", address);
        this.isUrl = true;
        this.url = this.geoUrl + "address=" + address + "&radius=" + this.viewModeDefaults.radius + "&mode=find";
    };
    GeolocationMapComponent.prototype.showResults = function (results) {
        var coords = this.getCoords(results);
        this.mapLat = coords.lat;
        this.mapLng = coords.lng;
        this.mapZoom = this.searchMode.zoom;
        this.circle = new MapCircle(coords.lat, coords.lng, this.searchMode.circleRadius);
        this.notFoundFor = null;
        this.addressNotFound.next(true);
        this.onCenterChange(coords, false);
    };
    GeolocationMapComponent.prototype.findLocation = function () {
        // console.log("debug findlocation ",this.coordinatesMode);
        var coords = this.getCoords(this.coordinatesMode);
        this.mapLat = coords.lat;
        this.mapLng = coords.lng;
        this.mapZoom = this.coordinatesMode.zoom;
        this.circle = new MapCircle(coords.lat, coords.lng, this.coordinatesMode.circleRadius);
    };
    GeolocationMapComponent.prototype.showErrorMessage = function (searchText) {
        this.notFoundFor = searchText;
        this.addressNotFound.next(false);
    };
    GeolocationMapComponent.prototype.getCoords = function (obj) {
        var lat = _.isFunction(obj.lat) ? obj.lat() : obj.lat;
        var lng = _.isFunction(obj.lng) ? obj.lng() : obj.lng;
        return { lat: lat, lng: lng };
    };
    return GeolocationMapComponent;
}());
export { GeolocationMapComponent };
