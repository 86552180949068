import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { WcReport, WcInjuryReport, WcLookupsContainer, WcOSHAReport, WCCompensationReport } from '../../../models';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { PopoverContentComponent } from '../../../../../common';
import { Lookup, Employee, EmployeeShortInfo, LookupType } from '../../../../../organization/models';
import { EmployeeDefinitionsApiService, LookupService } from '../../../../../organization/services';
import { OrgLevel } from '../../../../../state-model/models';
import { FormGroup, FormBuilder, NgModel } from '@angular/forms';
import { wcConfig } from '../../../workers-compensation.config';

@Component({
  moduleId: module.id,
  selector: 'slx-wc-injury-report',
  templateUrl: 'wc-injury-report.component.html',
  styleUrls: ['wc-injury-report.component.scss']
})

export class WcInjuryReportComponent implements OnInit, OnDestroy {

  public min = 0;
  public max = 99999999.99;

  public get authorName(): string {
    if (this.injury && this.injury.user) {
      return this.injury.user.name || this.injury.user.login;
    }
    return '';
  }

  public get isCreatingNew(): boolean {
    return this.report && !_.isFinite(this.report.id);
  }

  public get isOshaSwitchEnabled(): boolean {
    return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || this.injury.isOSHARecordable;
  }

  public get isOshaSwitchDisabled(): boolean {
    return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || (this.enableOsha ? false : this.injury.isOSHARecordable);
  }

  public get isWCSwitchEnabled(): boolean {
    return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || this.injury.isWorkersCompCase;
  }

  public get isWCSwitchDisabled(): boolean {
    return this.isCreatingNew ? !this.report.editMode : !this.report.editMode || (this.enableWorkersComp ? false : this.injury.isWorkersCompCase);
  }

  public get injury(): WcInjuryReport {
    return this.report ? this.report.injuryReport : null;
  }
  public get isOtherEquipment(): boolean {
    if (this.report && this.report.injuryReport && this.report.injuryReport.equipmentCode) {
      return this.report.injuryReport.equipmentCode.code === 'Other';
    }
    return false;
  }

  public get minimumDate(): Date {
    return moment(this.startOfMinDate).isSameOrAfter(this.m_minimumDate) ? this.startOfMinDate : this.m_minimumDate;
  }

  public commentsCount: number;

  public phoneMaskConf: any;
  public report: WcReport;
  public lookups: WcLookupsContainer;
  public employeesLookup: Lookup;
  public orgLevel: OrgLevel;
  public enableOsha: boolean = false;
  public enableWorkersComp: boolean = false;
  public incidentDate: Date;
  public startOfMinDate: Date;

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};

  @ViewChild('injuryReport', { static: true })
  public injuryReportForm: FormGroup;

  @ViewChild('witnessPhone', { static: false })
  public witnessPhone: NgModel;

  private m_minimumDate: Date;

  constructor(private management: WCReportManagementService) {
    this.phoneMaskConf = this.phoneMaskConf = wcConfig.settings.phoneMask;
    this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
  }

  public ngOnInit(): void {

    this.subscriptions.orgLevel = this.management.subscribeToOrgLevelChange((o: OrgLevel) => {
      this.orgLevel = o;
      this.loadEmployeesLookup();
    });

    this.subscriptions.incidentDate = this.management.subscribeToSetIncidentDate((iDate: Date) => {
      this.incidentDate = iDate;
      this.startOfMinDate = moment(iDate).startOf('day').toDate();
    });

    this.subscriptions.load = this.management.onLoaded$
      .combineLatest(this.management.wcLookupsLoaded$)
      .subscribe(async ([report, lookups]: [WcReport, WcLookupsContainer]) => {
        this.lookups = lookups;
        this.report = report;
      });

    this.subscriptions.value = this.injuryReportForm.statusChanges.subscribe(x => {
      if (this.injuryReportForm.dirty && this.injuryReportForm.touched) {
        this.management.onChangeNotify();
      }
      this.management.setInjuryFormValidity(this.injuryReportForm.valid);

      this.updateCommentsCount();
    });

  }

  public ngOnDestroy(): void { }

  public async onClickDelete(acceptPopover: PopoverContentComponent): Promise<void> {
    acceptPopover.hide();
    try {
      await this.management.deleteReport();
    } catch (e) {
      console.error(e);
    }
  }

  public onClickCancel(acceptPopover: PopoverContentComponent): void {
    acceptPopover.hide();
  }

  public onChangeOsha(enable: boolean): void {
    if (enable) {
      this.management.enableOshaReport();
      this.enableOsha = true;
    } else {
      this.management.disableOshaReport();

    }
  }

  public onChangeWCCase(enable: boolean): void {
    if (enable) {
      this.management.enableWCReport();
      this.enableWorkersComp = true;
    } else {
      this.management.disableWCReport();
    }
  }

  public async onWitnessEmployeeChanged(newEmployee: Employee): Promise<void> {
    if (newEmployee) {
      await this.management.setWitnessEmployee(newEmployee.id);
    }
  }

  public onWitnessIsEmployeeChanged(isEmp: boolean): void {
    if (isEmp !== this.injury.isWitnessEmployee) {
      this.injury.witnessEmployeeObj = null;
      this.injury.witnessEmployee = null;
    }
    this.injury.witnessPhone = null;
  }

  public onHasEmployeeSignChanged(has: boolean): void {
    if (!has) {
      this.injury.employeeSignDate = null;
    }else{
      this.injury.employeeSignDate = new Date();
    }
  }

  public onHasSupervisorSignChanged(has: boolean): void {
    if (!has) {
      this.injury.supervisorSignDate = null;
    }else{
      this.injury.supervisorSignDate = new Date();
    }
  }

  public onHasDirectorSignChanged(has: boolean): void {
    if (!has) {
      this.injury.exDirectorSignDate = null;
    }else{
      this.injury.exDirectorSignDate = new Date();
    }
  }

  public loadEmployeesLookup(): void {
    this.management.loadEmployees()
      .then((l: Lookup) => {
        this.employeesLookup = l;
      });
  }


  private updateCommentsCount() {
    let commentsCount = 0;
    if (!_.isNil(this.injury.employeeComment) && _.size(this.injury.employeeComment) > 0) {
      commentsCount++;
    }
    if (!_.isNil(this.injury.supervisorComment) && _.size(this.injury.supervisorComment) > 0) {
      commentsCount++;
    }
    if (!_.isNil(this.injury.exDirectorComment) && _.size(this.injury.exDirectorComment) > 0) {
      commentsCount++;
    }
    if (!_.isNil(this.injury.userComment) && _.size(this.injury.userComment) > 0) {
      commentsCount++;
    }
    if (commentsCount !== this.commentsCount) {
      this.commentsCount = commentsCount;
    }
  }
}
