import * as _ from 'lodash';
var TimecardFlatRecord = /** @class */ (function () {
    function TimecardFlatRecord() {
    }
    Object.defineProperty(TimecardFlatRecord.prototype, "isLocked", {
        get: function () {
            return this.isTimecardLocked || this.isPayCycleLocked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardFlatRecord.prototype, "departmentName", {
        get: function () {
            if (this.earning) {
                return this.earning.department.name;
            }
            if (this.emp && this.emp.employeePosition) {
                return this.emp.employeePosition.positionDepartment.name;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardFlatRecord.prototype, "organizationName", {
        get: function () {
            if (this.earning) {
                return this.earning.organization.name;
            }
            if (this.emp) {
                return this.emp.empOrganization;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardFlatRecord.prototype, "positionName", {
        get: function () {
            if (this.earning) {
                return this.earning.position.name;
            }
            if (this.emp && this.emp.employeePosition) {
                return this.emp.employeePosition.position.name;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardFlatRecord.prototype, "shiftDiffPolicyName", {
        get: function () {
            if (this.shiftDiffPolicy) {
                return this.shiftDiffPolicy.name;
            }
            if (this.emp && this.emp.shiftDiffPolicy) {
                return this.emp.shiftDiffPolicy.name;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardFlatRecord.prototype, "costCenterCode", {
        get: function () {
            if (this.earning) {
                return this.earning.costCenterCode;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardFlatRecord.prototype, "payRate", {
        get: function () {
            if (this.earning) {
                return this.earning.payRate;
            }
            return this.standardPayRate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardFlatRecord.prototype, "payPolicyName", {
        get: function () {
            if (this.payPolicy) {
                return this.payPolicy.name;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    TimecardFlatRecord.prototype.getRuleValue = function (name) {
        var rules = this.rulesMap[name] || this.rulesMap[_.toUpper(name)] || this.rulesMap[_.toLower(name)];
        if (rules) {
            return _.sumBy(rules, function (r) { return r.value ? r.value : 0; });
        }
        return null;
    };
    TimecardFlatRecord.prototype.isMoneyRule = function (name) {
        var rules = this.rulesMap[name];
        return rules && _.every(rules, function (r) { return r.isMoneyAmountRule; });
    };
    return TimecardFlatRecord;
}());
export { TimecardFlatRecord };
