import * as moment from 'moment';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { DateRange, IDateRange, IColumnSettings } from '../../../../core/models/index';
import { DeviceDetectorService } from '../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../common/services/index';
import { AccrualsTransactionsManagementService } from '../../services/index';
import { PayCycle } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { LookupApiService } from '../../../../organization/services/index';
import { RecalculateDialogComponent } from '../recalculate-dialog/recalculate-dialog.component';
import { ModalService, ConfirmOptions, ConfirmDialogComponent, DialogOptions } from '../../../../common/index';

@Component({
  selector: 'slx-accruals-transactions-toolbar',
  templateUrl: './accruals-transactions-toolbar.component.html',
  styleUrls: ['./accruals-transactions-toolbar.component.scss']
})
export class AccrualsTransactionsToolbarComponent implements OnInit, OnDestroy {

  public dateRange: DateRange = new DateRange(null, null);
  private dayInSec: number = 60 * 60 * 24;
  public maxSecondsRange: number = 366 * this.dayInSec;
  public maxRangeError: string = 'The dates range cannot be more than 366 days';
  public calculateAccrualsAction: boolean = false;

  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get dateRangeWidth(): number {
    return this.isDesktop ? 325 : 246;
  }

  public readonly columnsStateName: string = 'AccrualsTransaction';

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private modalService: ModalService,
    private accrualsManagementService: AccrualsTransactionsManagementService,
    private columnManagementService: ColumnManagementService,
    private stateManagement: StateManagementService,
    private devDetector: DeviceDetectorService,
    private lookupApiService: LookupApiService
  ) { }

  public ngOnInit(): void {
    this.subscribeToOrgLevel();
    this.initServices();
    this.defineDateRange();
    this.subscribeTocalculateAccrualsAction();
  }

  private subscribeTocalculateAccrualsAction(): void {
    this.subscriptions.onLoaded = this.accrualsManagementService
      .subscribeTocalculateAccrualsAction((r: boolean) => {
        this.calculateAccrualsAction = r;
      });
  }

  public ngOnDestroy(): void { }

  public onChangeDates(range: IDateRange): void {
    this.accrualsManagementService.changeDateRange(range);
  }

  public onClickExport(isPDF: boolean): void {
    this.accrualsManagementService.exportTo(isPDF);
  }

  private initServices(): void {
    this.stateManagement.init('AccrualsTransactionsComponent');
    this.columnManagementService.init('AccrualsTransactionsComponent');
    this.columnManagementService.initGroup(this.columnsStateName, 11);
  }

  private subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
    });
  }

  public defineDateRange(): void {
    const promise: Promise<PayCycle[]> = this.lookupApiService.getPayCyles(this.orgLevel.id);

    promise.then((cycles: PayCycle[]) => {
      const startDate = moment().subtract(30, 'days').toDate();
      const currentDate: moment.Moment = moment();
      const currentCycles = _.filter(cycles, (cycle) => {
        const currStartDate: moment.Moment = moment(cycle.startDate);
        const currEndDate: moment.Moment = moment(cycle.endDate);
        return moment(currentDate).isBetween(currStartDate, currEndDate);
      });

      const payCycle: PayCycle = _.first(_.orderBy(currentCycles, c => moment(c.endDate), ['desc']));

      this.dateRange = new DateRange(startDate, payCycle ? payCycle.endDate : new Date());
      this.onChangeDates(this.dateRange);
    });
  }

  public onClickRecalcAccruals() : void {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 950;
    dialogOptions.height = 480;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.hideTitleBar = true;
    let dialog: RecalculateDialogComponent = this.modalService.globalAnchor.openDialog(
      RecalculateDialogComponent,
      'Confirmation',
      dialogOptions, undefined);
    dialog.orgLevelId = this.orgLevel.id;
    if (dialog.initialized) {
      dialog.onLoadRecalculateData();
    }
  }
}
