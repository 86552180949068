import * as _ from 'lodash';
import { BenefitDetailsApiService } from './benefit-details-api.service';
var BenefitDetailsValidatorAdapter = /** @class */ (function () {
    function BenefitDetailsValidatorAdapter(benefitsDetailsApiService) {
        this.benefitsDetailsApiService = benefitsDetailsApiService;
        this.delay = 300;
    }
    BenefitDetailsValidatorAdapter.prototype.validate = function (validationName, value) {
        var _this = this;
        var params = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            params[_i - 2] = arguments[_i];
        }
        var promise;
        var defPromise = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        switch (validationName) {
            case BenefitDetailsValidatorAdapter.providerNameValidation:
                if (!_.isNil(this.providerNameValidator)) {
                    this.providerNameValidator.changeToSync();
                }
                this.providerNameValidator = this.createValidator(function () { return _this.benefitsDetailsApiService.validateProviderName(value, params[0], params[1]); }, function () { return defPromise; });
                promise = this.providerNameValidator.promise;
                break;
            default:
                promise = defPromise;
        }
        return promise;
    };
    BenefitDetailsValidatorAdapter.prototype.createValidator = function (asyncValidate, syncValidate) {
        var validator = this.getValidator(asyncValidate, syncValidate);
        validator.promise = this.createDelayedPromise(validator.validate);
        return validator;
    };
    BenefitDetailsValidatorAdapter.prototype.getValidator = function (asyncValidate, syncValidate) {
        var firstCall = true;
        var isAsyncValidation = true;
        return {
            validate: function () {
                if (firstCall) {
                    firstCall = false;
                    if (isAsyncValidation) {
                        return asyncValidate();
                    }
                    return syncValidate();
                }
            },
            changeToSync: function () { return (isAsyncValidation = false); },
            promise: Promise.resolve(null)
        };
    };
    BenefitDetailsValidatorAdapter.prototype.createDelayedPromise = function (callback) {
        var _this = this;
        return new Promise(function (resolve) {
            setTimeout(function () { return resolve(callback()); }, _this.delay);
        });
    };
    BenefitDetailsValidatorAdapter.providerNameValidation = 'providerNameValidation';
    return BenefitDetailsValidatorAdapter;
}());
export { BenefitDetailsValidatorAdapter };
