import { Arrival, IArrival, Departure, IDeparture } from './index';

export interface IArrivalDeparturesContainer {
  orgLevelId: number;
  workDate: Date;
  departures: IDeparture[];
  arrivals: IArrival[];
  missingPunchesCount: number;
  timecardExceptionCount: number;
}

export class  ArrivalDeparturesContainer {
  public orgLevelId: number;
  public workDate: Date;
  public departures: Departure[];
  public arrivals: Arrival[];
  public missingPunchesCount: number;
  public timecardExceptionCount: number;
}
