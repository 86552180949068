import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonValidators } from '../../../../common/validators/common-validators';
import { Timeclock, TimeclockContainer } from '../../../models/index';
import { LookupService } from '../../../../organization/services/index';
import { Organization, Department, Lookup, LookupType } from '../../../../organization/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';
var TimeclockEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(TimeclockEditorComponent, _super);
    function TimeclockEditorComponent(lookupService) {
        var _this = _super.call(this) || this;
        _this.lookupService = lookupService;
        _this.loadOrganizations();
        return _this;
    }
    Object.defineProperty(TimeclockEditorComponent.prototype, "item", {
        get: function () {
            return this.m_item;
        },
        set: function (item) {
            this.m_item = item;
            this.setRecords();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockEditorComponent.prototype, "containerInput", {
        set: function (container) {
            this.container = container;
            this.setRecords();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockEditorComponent.prototype, "timezoneLookup", {
        get: function () {
            return this.m_timezoneLookup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockEditorComponent.prototype, "isMessagesEnabled", {
        get: function () {
            return this.m_isMessagesEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockEditorComponent.prototype, "organizationLookup", {
        get: function () {
            return this.m_organizationLookup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockEditorComponent.prototype, "departmentLookup", {
        get: function () {
            return this.m_departmentLookup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockEditorComponent.prototype, "clockModelLookup", {
        get: function () {
            return this.m_clockModelLookup;
        },
        enumerable: true,
        configurable: true
    });
    TimeclockEditorComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        // See #issueWithAOTCompiler
    };
    TimeclockEditorComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.canSetOpenShiftRequests = !this.item.isVirtual;
    };
    TimeclockEditorComponent.prototype.loadOrganizations = function () {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.organization, orgLevelId: undefined, employeeId: undefined })
            .then(function (value) {
            _this.m_organizationLookup = value;
            _this.organization = undefined;
            if (_this.item && _this.item.organization) {
                _this.organization = _.find(_this.m_organizationLookup.items, function (org) {
                    return org.orgLevelId === _this.item.organization.orgLevelId;
                });
                if (_this.organization) {
                    _this.item.organization = _this.organization;
                    _this.formGroup.get('organization').setValue(_this.item.organization);
                }
            }
            _this.loadDepartments();
            _this.loadModels();
            _this.loadTimezones();
        });
    };
    TimeclockEditorComponent.prototype.loadDepartments = function () {
        var _this = this;
        if (!this.organization) {
            this.m_departmentLookup = undefined;
            this.loadModels();
            return;
        }
        this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.organization.orgLevelId, employeeId: undefined })
            .then(function (value) {
            _this.m_departmentLookup = value;
            var all = new Department();
            all.id = 0;
            all.name = 'All';
            _this.m_departmentLookup.items.unshift(all);
            _this.department = undefined;
            if (_this.item.department) {
                _this.department = _.find(_this.m_departmentLookup.items, function (d) {
                    return d.orgLevelId === _this.item.department.orgLevelId;
                });
                if (_this.department) {
                    _this.formGroup.get('department').setValue(_this.department);
                }
            }
            _this.loadModels();
            _this.loadTimezones();
        });
    };
    TimeclockEditorComponent.prototype.loadModels = function () {
        var _this = this;
        if (!this.organization) {
            this.m_clockModelLookup = undefined;
            return;
        }
        var orgLevelId = this.department ? this.department.orgLevelId : this.organization.orgLevelId;
        this.lookupService.getLookup({ lookupType: LookupType.timeclockModelDefinition, orgLevelId: orgLevelId })
            .then(function (value) {
            _this.m_clockModelLookup = value;
        });
    };
    TimeclockEditorComponent.prototype.loadTimezones = function () {
        var _this = this;
        if (!this.organization) {
            this.m_timezoneLookup = undefined;
            return;
        }
        var orgLevelId = this.department ? this.item.department.orgLevelId : this.organization.orgLevelId;
        this.lookupService.getLookup({ lookupType: LookupType.timezoneDefinition, orgLevelId: orgLevelId })
            .then(function (value) {
            _this.m_timezoneLookup = value;
        });
    };
    TimeclockEditorComponent.prototype.uniqueCheck = function () {
        var _this = this;
        if (!this.records) {
            return;
        }
        var t = _.find(this.records, function (timeclock) {
            return timeclock.id === _this.id && timeclock.name === _this.name && timeclock.physicalId === _this.physicalId;
        });
        if (t) {
            this.formGroup.get('name').setErrors({ 'uniqueTimeclock': true });
            this.formGroup.get('id').setErrors({ 'uniqueTimeclock': true });
            this.formGroup.get('physicalId').setErrors({ 'uniqueTimeclock': true });
        }
        else {
            this.omitUniqueError('name');
            this.omitUniqueError('id');
            this.omitUniqueError('physicalId');
        }
    };
    TimeclockEditorComponent.prototype.onIsVirtualClick = function () {
        this.canSetOpenShiftRequests = this.formGroup.get('isVirtual').value;
    };
    TimeclockEditorComponent.prototype.updateItem = function () {
        this.item.organization = this.formGroup.get('organization').value;
        this.item.department = this.formGroup.get('department').value;
        this.item.name = this.formGroup.get('name').value;
        this.item.model = this.formGroup.get('model').value;
        this.item.id = this.formGroup.get('id').value;
        this.item.physicalId = this.formGroup.get('physicalId').value;
        this.item.cmdMessageLine1 = this.formGroup.get('cmdMessageLine1').value;
        this.item.cmdMessageLine2 = this.formGroup.get('cmdMessageLine2').value;
        this.item.cmdMessageLine3 = this.formGroup.get('cmdMessageLine3').value;
        this.item.cmdMessageLine4 = this.formGroup.get('cmdMessageLine4').value;
        this.item.timezone = this.formGroup.get('timezone').value;
        this.item.ipaddress = this.formGroup.get('ipaddress').value;
        this.item.isVirtual = this.formGroup.get('isVirtual').value;
        this.item.openShiftRequestsEnabled = !this.item.isVirtual && this.formGroup.get('openShiftRequestsEnabled').value;
        this.item.isMessagesEnabled = this.formGroup.get('isMessagesEnabled').value;
    };
    TimeclockEditorComponent.prototype.createFormGroup = function () {
        var _this = this;
        this.m_isMessagesEnabled = this.item.isMessagesEnabled;
        var fg = new FormGroup({
            organization: new FormControl(this.item.organization, Validators.required),
            department: new FormControl(this.item.department, Validators.required),
            name: new FormControl(this.item.name, Validators.required),
            model: new FormControl(this.item.model, Validators.required),
            id: new FormControl(this.item.id, [Validators.required, CommonValidators.number]),
            physicalId: new FormControl(this.item.physicalId, [Validators.required, CommonValidators.number]),
            cmdMessageLine1: new FormControl(this.item.cmdMessageLine1),
            cmdMessageLine2: new FormControl(this.item.cmdMessageLine2),
            cmdMessageLine3: new FormControl(this.item.cmdMessageLine3),
            cmdMessageLine4: new FormControl(this.item.cmdMessageLine4),
            timezone: new FormControl(this.item.timezone, Validators.required),
            ipaddress: new FormControl(this.item.ipaddress, CommonValidators.ipv4),
            isVirtual: new FormControl(this.item.isVirtual),
            openShiftRequestsEnabled: new FormControl(this.item.openShiftRequestsEnabled),
            isMessagesEnabled: new FormControl(this.item.isMessagesEnabled),
        });
        this.orgSubscribe = fg.get('organization').valueChanges.subscribe(function (value) {
            _this.organization = value;
            _this.loadDepartments();
        });
        this.depSubscribe = fg.get('department').valueChanges.subscribe(function (value) {
            _this.department = value;
            _this.loadModels();
            _this.loadTimezones();
        });
        this.msgSubscribe = fg.get('isMessagesEnabled').valueChanges.subscribe(function (value) {
            _this.m_isMessagesEnabled = value;
        });
        this.idSubscribe = fg.get('name').valueChanges.subscribe(function (value) {
            _this.name = value;
            _this.uniqueCheck();
        });
        this.nameSubscribe = fg.get('id').valueChanges.subscribe(function (value) {
            _this.id = value;
            _this.uniqueCheck();
        });
        this.phSubscribe = fg.get('physicalId').valueChanges.subscribe(function (value) {
            _this.physicalId = value;
            _this.uniqueCheck();
        });
        return fg;
    };
    TimeclockEditorComponent.prototype.updateFormGroup = function () {
        this.formGroup.get('organization').setValue(this.item.organization);
        this.formGroup.get('department').setValue(this.item.department);
        this.formGroup.get('name').setValue(this.item.name);
        this.formGroup.get('model').setValue(this.item.model);
        this.formGroup.get('id').setValue(this.item.id);
        this.formGroup.get('physicalId').setValue(this.item.physicalId);
        this.formGroup.get('cmdMessageLine1').setValue(this.item.cmdMessageLine1);
        this.formGroup.get('cmdMessageLine2').setValue(this.item.cmdMessageLine2);
        this.formGroup.get('cmdMessageLine3').setValue(this.item.cmdMessageLine3);
        this.formGroup.get('cmdMessageLine4').setValue(this.item.cmdMessageLine4);
        this.formGroup.get('timezone').setValue(this.item.timezone);
        this.formGroup.get('ipaddress').setValue(this.item.ipaddress);
        this.formGroup.get('isVirtual').setValue(this.item.isVirtual);
        this.formGroup.get('openShiftRequestsEnabled').setValue(this.item.openShiftRequestsEnabled);
        this.formGroup.get('isMessagesEnabled').setValue(this.item.isMessagesEnabled);
    };
    TimeclockEditorComponent.prototype.setRecords = function () {
        var _this = this;
        if (!this.container || !this.item) {
            return;
        }
        this.records = _.filter(this.container.records, function (record) {
            return _this.item.id !== record.id || _this.item.name !== record.name || _this.item.physicalId !== record.physicalId;
        });
    };
    TimeclockEditorComponent.prototype.omitUniqueError = function (name) {
        var errors = _.omit(this.formGroup.get(name).errors, 'uniqueTimeclock');
        if (_.values(errors).length === 0) {
            errors = null;
        }
        this.formGroup.get(name).setErrors(errors);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockEditorComponent.prototype, "orgSubscribe", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockEditorComponent.prototype, "depSubscribe", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockEditorComponent.prototype, "msgSubscribe", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockEditorComponent.prototype, "idSubscribe", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockEditorComponent.prototype, "nameSubscribe", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockEditorComponent.prototype, "phSubscribe", void 0);
    return TimeclockEditorComponent;
}(EditableListEditorComponent));
export { TimeclockEditorComponent };
