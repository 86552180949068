<div class="actions">
  <slx-static-dropdown-list *ngIf="canAddEdit && isEditable"
    class="slx-wide slx-short slx-old-style"
    [options]="leaveTypes"
    (selectItem)="createNewRequest($event)"
    controlIcon="fa fa-plus"
    placeholder="Create New"
  ></slx-static-dropdown-list>
</div>

<kendo-grid scrollable="'scrollable'"
  [data]="gridState.view"
  (dataStateChange)="gridState.dataStateChange($event)"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  [slxKendoGridState]="gridState.gridDefinition"
  (stateRestored)="gridState.dataStateChange($event)"
  [filterable]="true"
  [filter]="gridState.state.filter"
>
  <kendo-grid-column field="type.name" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      Request Type
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.type?.name }}
    </ng-template>

    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="reason" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      Reason
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.reason }}
    </ng-template>

    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="actStartDate" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      Act. Start Date
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="openRequest(dataItem)">{{ dataItem.actStartDate | amDateFormat: appConfig.dateFormat }}</a>
    </ng-template>

    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="actEndDate" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      Act. End Date
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.actEndDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>

    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="estStartDate" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      Est. Start Date
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="openRequest(dataItem)" *ngIf="!dataItem.actStartDate">{{ dataItem.estStartDate | amDateFormat: appConfig.dateFormat }}</a>
      <ng-container *ngIf="dataItem.actStartDate">{{ dataItem.estStartDate | amDateFormat: appConfig.dateFormat }}</ng-container>
    </ng-template>

    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="estEndDate" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      Est. End Date
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.estEndDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>

    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="daysOnLoaText" [width]="100">
    <ng-template kendoGridHeaderTemplate>
      # Days
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.daysOnLoaText }}
    </ng-template>

    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
