import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { orderBy, State, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { IFilteredItems, PagingData } from '../../../../../core/models/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { PbjExportLogManagementService } from '../../../services/pbj-export/pbj-export-log-management.service';
var PbjLogGridComponent = /** @class */ (function () {
    function PbjLogGridComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.pageSize = 50;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'exportId', dir: 'desc' }];
        this.managementService.sort = this.gridState.state.sort;
        this.gridState.state.filter = null;
        this.gridState.state.group = [];
    }
    PbjLogGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (records) {
            _this.records = records;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    PbjLogGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PbjLogGridComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.gridPageChanged({ take: event.take, skip: event.skip });
    };
    PbjLogGridComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.refreshGrid();
        this.managementService.sortChanged(this.gridState.state.sort);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PbjLogGridComponent.prototype.filterChange = function (filter) {
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PbjLogGridComponent.prototype.gridPageChanged = function (pagingData) {
        this.managementService.pageChanged(pagingData);
    };
    PbjLogGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        var filteredRecords = filterBy(this.records.items, this.gridState.state.filter);
        var sortedRecords = orderBy(filteredRecords, this.gridState.state.sort);
        this.gridState.view = {
            data: sortedRecords,
            total: this.records.count
        };
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogGridComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogGridComponent.prototype, "onLoadedSubscription", void 0);
    return PbjLogGridComponent;
}());
export { PbjLogGridComponent };
