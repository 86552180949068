var PositionsSummaryContainer = /** @class */ (function () {
    function PositionsSummaryContainer() {
    }
    return PositionsSummaryContainer;
}());
export { PositionsSummaryContainer };
var TimecardSummarySecondaryPosition = /** @class */ (function () {
    function TimecardSummarySecondaryPosition() {
    }
    Object.defineProperty(TimecardSummarySecondaryPosition.prototype, "positionPath", {
        get: function () {
            var posName = this.position && this.position.name || '';
            var orgName = this.organization && this.organization.name || '';
            var depName = this.department && this.department.name || '';
            if (orgName.length > 0 && depName.length > 0) {
                return orgName + " > " + depName + " > " + posName;
            }
            return posName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardSummarySecondaryPosition.prototype, "secondaryPositionPath", {
        get: function () {
            var posName = this.secondaryPosition && this.secondaryPosition.name || '';
            var orgName = this.secondaryOrganization && this.secondaryOrganization.name || '';
            var depName = this.secondaryDepartment && this.secondaryDepartment.name || '';
            if (orgName.length > 0 && depName.length > 0) {
                return orgName + " > " + depName + " > " + posName;
            }
            return posName;
        },
        enumerable: true,
        configurable: true
    });
    return TimecardSummarySecondaryPosition;
}());
export { TimecardSummarySecondaryPosition };
