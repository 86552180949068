
export interface ILoaAttachment {
  id: number;
  name: string;
  employeeId: number;
  fileName: string;
}

export class LoaAttachment {
  public id: number;
  public name: string;
  public employeeId: number;
  public fileName: string;
  constructor(id: number, name: string, empId: number, fileName: string) {
    this.id = id;
    this.name = name;
    this.employeeId = empId;
    this.fileName = fileName;
  }
}
