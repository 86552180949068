
export interface ITimeclockModelDefinition {
  id: string;
  name: string;
  qrPunchSupported: boolean;
}

export class TimeclockModelDefinition {
  public id: string;
  public name: string;
  public qrPunchSupported: boolean;
}
