<slx-spinner [show]="isLoading">
  <form #dropForm="ngForm" novalidate class="slx-full-height">
  <div class="modal-body">
    <div class="ben-main-cls-100">
      <div class="slx-flex-row">
        <span class="drop-label">Employees Selected: <strong class="selected-count">
            {{selectedEmployees.length}}</strong></span>
      </div>
    </div>

    <div class="ben-main-cls-100">
      <div class="left slx-flex-row">
        <span class="reason-label">Reason: </span>
        <slx-dropdown-list #cMethod="ngModel" name="dropReason" class="slx-wide slx-short slx-padd-r" valueField="id" titleField="name"
          [options]="reasonList" [(ngModel)]="reason" (ngModelChange)="onReasonChange($event)"></slx-dropdown-list>
      </div>
    </div>
    <div class="ben-main-cls">
      <div class="left slx-flex-row">
        <span class="date-label">Benefit End Date:</span>
        <div>
          <i aria-hidden="true" class="fas fa-info-circle info-icon"><span [slxTooltip]="getEndDateToolTip()"
              [tipClass]="'wide-tip'" tipPosition="bottom"></span></i>
        </div>
        <div class="flex__grow">
          <slx-datepicker class="slx-padd-r date-control" [minDate]="employeeStartDate"
            [maxDate]="employeeEndDate" [(ngModel)]="benefitEndDate" (ngModelChange)="onChangeBenefitEndDate($event)"
            format="MM/dd/yyyy" [acceptNullDate]="false" [required]="true" #benefitEndDateModel="ngModel"
            name="benefitEndDate">
          </slx-datepicker>
          <span *ngIf="benefitEndDateModel?.errors" class="slx-error-block">
            Incorrect Date
          </span>
        </div>
      </div>
      <div class="right slx-flex-row">
        <span class="date-label">Event Date:</span>
        <div>
          <i aria-hidden="true" class="fas fa-info-circle info-icon"><span [slxTooltip]="getEventDateToolTip()"
              [tipClass]="'wide-tip'" tipPosition="bottom"></span></i>
        </div>
        <div class="flex__grow">
          <slx-datepicker class="slx-padd-r date-control" [minDate]="eventEmployeeStartDate"
            [maxDate]="eventEmployeeEndDate" [(ngModel)]="eventDate" (ngModelChange)="onChangeEventdDate($event)"
            format="MM/dd/yyyy" [acceptNullDate]="true" [required]="false" #eventDateModel="ngModel" name="eventDate">
          </slx-datepicker>
          <span *ngIf="eventDateModel?.errors" class="slx-error-block">
            Incorrect Date
          </span>
        </div>
      </div>
    </div>


    <div class="ben-main-cls third" *ngIf="canMapPayroll && enableBenefitDeduction">
      <div class="left  slx-flex-row">
        <span class="date-end-label">Deduction End Date : </span>
        <div class="flex__grow">
          <slx-datepicker class="slx-padd-r date-control" name="DedEndDate" #dedEndDateModel="ngModel"
            [minDate]="minDedDate" [maxDate]="maxDedDate" [(ngModel)]="payrollDeductionEndDate"
            (ngModelChange)="onChangeDedEndDate()">
          </slx-datepicker>
          <span *ngIf="dedEndDateModel?.errors" class="slx-error-block">
            <span *ngIf="dedEndDateModel?.errors?.date" errorMessage for="date">Incorrect Date</span>
            <span *ngIf="dedEndDateModel?.errors?.maxDate" errorMessage for="maxDate">The value should be less than
             {{ maxDedDate | amDateFormat: appConfig.dateFormat }}</span>
            <span *ngIf="dedEndDateModel?.errors?.minDate" errorMessage for="minDate">The value should be greater than
              {{ minDedDate | amDateFormat: appConfig.dateFormat }}</span>
          </span>
          <span *ngIf="dropForm.valid && isDedAfterBeneEndDt && canMapPayroll" class="warning-message">
            {{warningMsg}}
          </span>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" (click)="dropBenefits()" class="btn green"
      [disabled]="dropForm.invalid || datesError" title="{{ title }}"><i
        class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</button>
    <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon"
        aria-hidden="true"></i>Cancel</button>
  </div>
</form>
</slx-spinner>