import { IEmployeeDefinition, EmployeeDefinition } from './employee-definition';
import { IPosition, Position, IDepartment, Department } from '../index';

export interface IEmployeePositionDefinition {
  employee: IEmployeeDefinition;
  position: IPosition;
  positionDepartment: IDepartment;
  rotationsCount: number;
}

export class EmployeePositionDefinition {
  public employee: EmployeeDefinition;
  public position: Position;
  public positionDepartment: Department;
  public rotationsCount: number;
}
