import * as moment from 'moment';
import { IPosition } from '../../../../organization/models/index';
import { IdealSchedulePositionDayDTO } from './ideal-schedule-position-day-dto';
import { IdealSchedulePositionRangeDTO } from './ideal-schedule-position-range-dto';

export class IdealSchedulePositionDTO {
  public deviation: number;
  public position: IPosition;
  public isDailyVariance: boolean;
  public targetHours: number;
  public totalHours: number;
  public startDate: string;
  public endDate: string;
  public idealScheduleType: string;
  public targetCensus: number;
  public isActive: boolean;
  public calculationType: string;
  public positionRanges: IdealSchedulePositionRangeDTO[];
}
