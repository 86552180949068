export * from './roster/index';
export * from './create-review/index';
export * from './config/index';

import { rosterComponents } from './roster/index';
import { createReviewComponents } from './create-review/index';
import { PmCreateReviewPopupComponent } from './create-review/index'
import { configComponents } from './config/index'

export const components: any[] = [
  ...rosterComponents,
  ...createReviewComponents,
  ...configComponents
];

export const entryComponents: any[] = [
  PmCreateReviewPopupComponent
];

export const exportComponents: any[] = [];
