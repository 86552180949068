/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog2.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirm-dialog2.component";
import * as i4 from "../../models/dialog-options";
import * as i5 from "../../services/modal/modal.service";
import * as i6 from "../confirm-dialog/confirm-dialog.component";
var styles_ConfirmDialog2Component = [i0.styles];
var RenderType_ConfirmDialog2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialog2Component, data: {} });
export { RenderType_ConfirmDialog2Component as RenderType_ConfirmDialog2Component };
function View_ConfirmDialog2Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.okButtonText; _ck(_v, 2, 0, currVal_0); }); }
function View_ConfirmDialog2Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cancelButtonText; _ck(_v, 2, 0, currVal_0); }); }
export function View_ConfirmDialog2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "bootbox-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialog2Component_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialog2Component_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.confirmOptions.showOK; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.confirmOptions.showCancel; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "modal-content ", ((_co.confirmOptions == null) ? null : _co.confirmOptions.className), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.options == null) ? null : _co.options.message); _ck(_v, 3, 0, currVal_1); }); }
export function View_ConfirmDialog2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-confirm-dialog2", [], null, null, null, View_ConfirmDialog2Component_0, RenderType_ConfirmDialog2Component)), i1.ɵdid(1, 49152, null, 0, i3.ConfirmDialog2Component, [i4.DialogOptions2, i5.ModalService, i6.ConfirmOptions], null, null)], null, null); }
var ConfirmDialog2ComponentNgFactory = i1.ɵccf("slx-confirm-dialog2", i3.ConfirmDialog2Component, View_ConfirmDialog2Component_Host_0, {}, {}, []);
export { ConfirmDialog2ComponentNgFactory as ConfirmDialog2ComponentNgFactory };
