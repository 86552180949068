/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payroll-export-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../organization/components/paycycle-dropdown/paycycle-dropdown.component.ngfactory";
import * as i3 from "../../../../../organization/components/paycycle-dropdown/paycycle-dropdown.component";
import * as i4 from "../../../../../organization/services/lookup/lookup-api.service";
import * as i5 from "./payroll-export-header.component";
import * as i6 from "../../../services/payroll-export/payroll-export-management.service";
var styles_PayrollExportHeaderComponent = [i0.styles];
var RenderType_PayrollExportHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PayrollExportHeaderComponent, data: {} });
export { RenderType_PayrollExportHeaderComponent as RenderType_PayrollExportHeaderComponent };
export function View_PayrollExportHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-paycycle-dropdown", [], null, [[null, "payCycleSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("payCycleSelected" === en)) {
        var pd_0 = (_co.onPayCycleSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PayCycleDropdownComponent_0, i2.RenderType_PayCycleDropdownComponent)), i1.ɵdid(1, 245760, null, 0, i3.PayCycleDropdownComponent, [i4.LookupApiService], { empId: [0, "empId"], setPayCycle: [1, "setPayCycle"] }, { payCycleSelected: "payCycleSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = 0; var currVal_1 = _co.currentPayCycle; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PayrollExportHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-payroll-export-header", [], null, null, null, View_PayrollExportHeaderComponent_0, RenderType_PayrollExportHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i5.PayrollExportHeaderComponent, [i6.PayrollExportManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PayrollExportHeaderComponentNgFactory = i1.ɵccf("slx-payroll-export-header", i5.PayrollExportHeaderComponent, View_PayrollExportHeaderComponent_Host_0, {}, {}, []);
export { PayrollExportHeaderComponentNgFactory as PayrollExportHeaderComponentNgFactory };
