import * as _ from 'lodash';
import { IOrganization, Organization, IPayCycle, PayCycle, Department, IDepartment } from '../../../organization/models/index';
import { ExceptionRecord, IExceptionRecord } from './exception-record';
export interface IExceptionConsoleRollupRecord {
  organization: IOrganization;
  department: IDepartment;
  payCycle: IPayCycle;
  totalExceptionsCount: number;
  pendingMissingPunchesCount: number;
  approvedMissingPunchesCount: number;
  exceptions: IExceptionRecord[];
}

export class ExceptionConsoleRollupRecord {

  public organization: Organization;
  public department: Department;
  public payCycle: PayCycle;
  public totalExceptionsCount: number;
  public pendingMissingPunchesCount: number;
  public approvedMissingPunchesCount: number;
  public exceptionsMap: StringMap<ExceptionRecord>;
  public exceptions: ExceptionRecord[];

  public get missingPunches(): string {
    return `${this.pendingMissingPunchesCount}/${this.approvedMissingPunchesCount}`;
  }

  public get orgName(): string {
    return this.organization ? this.organization.name : '';
  }

  public get depName(): string {
    return this.department ? this.department.name : '';
  }

  public get startDate(): Date {
    return this.payCycle ? this.payCycle.startDate : null;
  }

  public get endDate(): Date {
    return this.payCycle ? this.payCycle.endDate : null;
  }

  public getExceptionCount(name: string): number {
    return this.exceptionsMap && this.exceptionsMap[name] ? this.exceptionsMap[name].exceptionCount : 0;
  }
}
