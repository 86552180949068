/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./portal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../common/directives/modal-anchor/modal-anchor.directive";
import * as i4 from "../../../common/services/modal/modal.service";
import * as i5 from "../loading-indicator/loading-indicator.component.ngfactory";
import * as i6 from "../loading-indicator/loading-indicator.component";
import * as i7 from "../../../organization/services/application-state-bus/application-state-bus.service";
import * as i8 from "./portal.component";
import * as i9 from "../../../core/services/logger/logger.service";
import * as i10 from "../../actions/application.actions";
import * as i11 from "../../../core/services/user-activity/user-activity.service";
import * as i12 from "../../../authentication/services/authentication/authentication.service";
import * as i13 from "../../../authentication/actions/session.actions";
import * as i14 from "../../../core/services/throttling/throttling.service";
import * as i15 from "../../../core/services/token-validity/token-validity.service";
import * as i16 from "../../services/pbj-signalr/pbj-signalr.service";
import * as i17 from "../../../app-modules/pbj/services/pbj-export/pbj-export-log-management.service";
import * as i18 from "../../../core/services/session/session.service";
import * as i19 from "../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
var styles_PortalComponent = [i0.styles];
var RenderType_PortalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PortalComponent, data: {} });
export { RenderType_PortalComponent as RenderType_PortalComponent };
export function View_PortalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [["name", "header"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "header"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 16777216, null, null, 1, "router-outlet", [["name", "leftSidebar"]], null, null, null, null, null)), i1.ɵdid(3, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "leftSidebar"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(5, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [["name", "rightSidebar"]], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "rightSidebar"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "div", [["class", "global-anchor"], ["modalAnchor", ""]], null, null, null, null, null)), i1.ɵdid(9, 16384, null, 0, i3.ModalAnchorDirective, [i1.ViewContainerRef, i4.ModalService, i1.Renderer2], { isGlobal: [0, "isGlobal"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "slx-loading-indicator", [], null, null, null, i5.View_LoadingIndicatorComponent_0, i5.RenderType_LoadingIndicatorComponent)), i1.ɵdid(11, 245760, null, 0, i6.LoadingIndicatorComponent, [i7.ApplicationStateBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); var currVal_0 = true; _ck(_v, 9, 0, currVal_0); _ck(_v, 11, 0); }, null); }
export function View_PortalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-portal", [], null, null, null, View_PortalComponent_0, RenderType_PortalComponent)), i1.ɵdid(1, 245760, null, 0, i8.PortalComponent, [i7.ApplicationStateBusService, i9.LoggerService, i10.ApplicationActions, i11.UserActivityService, i4.ModalService, i12.AuthenticationService, i2.Router, i13.SessionActions, i14.ThrottlingService, i15.TokenValidityService, i16.PbjSignalrService, i17.PbjExportLogManagementService, i18.SessionService, i19.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PortalComponentNgFactory = i1.ɵccf("slx-portal", i8.PortalComponent, View_PortalComponent_Host_0, {}, {}, []);
export { PortalComponentNgFactory as PortalComponentNgFactory };
