import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy, ElementRef } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { SectionState, SectionDetail, sectionType } from '../../models/message-list';
import { appConfig } from '../../../../../app/app.config';
import { SortService } from '../../services/sort.service';
import { MessagesManagementService } from '../../services/messages-management.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificationsApiService } from '../../../../../app/portal/services';
import { mutableSelect, unsubscribe, unsubscribeAll } from '../../../../core/decorators';
import * as _ from 'lodash';
import { IMessageList } from '../../models/message-list';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../common/index';
var MessageListComponent = /** @class */ (function () {
    function MessageListComponent(sortService, messageManService, api, elementRef, modalService) {
        this.sortService = sortService;
        this.messageManService = messageManService;
        this.api = api;
        this.elementRef = elementRef;
        this.appConfig = appConfig;
        this.messageList = [];
        this.sortingOptions = ['Newest To Oldest', 'Oldest To Newest', 'Name A to Z', 'Name Z to A'];
        this.isAllSelected = false;
        this.getArchiveReadUnreadCount = new EventEmitter();
        this.searchText = '';
        this.msgArray = ['less than one month', 'Older than one month', 'Older than three months'];
        this.selectedSortFilter = 'Newest To Oldest';
        this.messageRange = [];
        this.lessThanOneMonth = [];
        this.olderThanOneMonth = [];
        this.olderThanThreeMonth = [];
        this.olderThanSixMonth = [];
        this.selectedMessageIds = [];
        this.updateMsgCenterSelectedMessageIds = new EventEmitter();
        this.isMyMessage = true;
        this.searchList = [];
        this.currentPageno = 1;
        this.hotStorageCurrentPageNo = 0;
        this.selectedId = '';
        this.orgLevelId = null;
        this.subscriptions = {};
        this.selectedEmp = null;
        this.selectedAll = false;
        this.searchInput = '';
        this.dateOrderByAscending = null;
        this.nameOrderByAscending = null;
        this.searchTerm$ = new Subject();
        this.selectedCheckBoxEvent = '';
        this.unSelectedemployess = [];
        this.pageSize = 100;
        this.sendSelectedEventToParent = new EventEmitter();
        this.isColdStorageData = false;
        this.requestObj = {
            currentPageno: 1,
            IsArchive: false,
            orgLevelId: 1,
            dateOrderByAscending: false,
            nameOrderByAscending: null,
            includeCold: false
        };
        this.isDataLoading = new EventEmitter();
        this.sectionTypesMap = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.IsArchive = false;
        this.modalService = modalService;
    }
    MessageListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.messageRange = ['lessthanmonth', 'olderthanonemonth', 'olderthanthreemonth', 'loadolderThan6Month'];
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (level) {
            if (level && level.id && ((_this.orgLevel && (level.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevelId = level.id;
                _this.requestObj.orgLevelId = _this.orgLevelId;
                _this.bindPageLoadData();
            }
        });
        this.subscriptions.messageFilter = this.messageManService.subscribeToswitchMessageFilter(function (res) {
            _this.isMyMessage = res;
            _this.bindPageLoadData();
        });
        this.subscriptions.waitingPopup = this.messageManService
            .subscribeToWaitingPopup(function (isShow) { return _this.showWaitingPopup(isShow); });
        this.subscriptions.id = this.messageManService.subscribeToGetMessageId(function (id) {
            _this.selectedId = id;
        });
        this.subscriptions.alert = this.api.subcribeToNewmessageAlert(function (res) {
            _this.updateMessage(res.employeeId);
        });
        this.subscriptions.refresh = this.messageManService.subscribeRefreshMessageList(function (res) {
            _this.updateMessagesList(res);
        });
        this.requestObj.IsArchive = this.IsArchive;
        this.requestObj.includeCold = false;
        this.messageManService.setRequestParams(this.requestObj);
        this.subscriptions.searchEmployee = this.messageManService.searchEmployee(this.searchTerm$)
            .subscribe(function (v) {
            if (v) {
                _this.clearData();
                _this.messageCountRecords = v;
                _.each(_this.messageCountRecords, function (item) {
                    _this.updateSectionMapWithTotalCount(String(item.sectionName), item.messageCount);
                });
                _this.getTabMessagesRecords(sectionType.lessThanOneMonth);
                _this.refreshGrid();
            }
        });
        this.subscriptions.TabsMessageCounts = this.messageManService.subscribeToTabsMessagesCount(function (v) {
            _this.messageCountRecords = v;
            _.each(_this.messageCountRecords, function (item) {
                _this.updateSectionMapWithTotalCount(String(item.sectionName), item.messageCount);
            });
        });
        this.subscriptions.MoreTabsMessageRecords = this.messageManService.subscribeToMoreTabMessages(function (v) {
            if (v.messages.length > 0) {
                _this.updateTabMessageRecords(v);
            }
            else {
                _this.refreshGrid();
                _this.isLoading = false;
            }
        });
        this.subscriptions.TabsMessageRecords = this.messageManService.subscribeToTabMessages(function (v) {
            if (v.messages.length > 0) {
                _this.updateTabMessageRecords(v);
            }
            else {
                _this.refreshGrid();
                _this.isLoading = false;
                _this.isDataLoading.emit(false);
            }
        });
        this.subscriptions.recentMessage = this.messageManService.subscribeToRecentMessage((function (res) {
            var isItemFound = false;
            var foundItem;
            var allList = _this.allMessageList();
            _.each(allList, function (item, i) {
                var index = item.findIndex(function (x) { return x.employeeId === res.employeeId; });
                var selectedEmp = item.filter(function (x) { return x.id === _this.selectedId; }).map(function (y) { return y.employeeId; })[0];
                if (index > -1) {
                    isItemFound = true;
                    item[index]['id'] = res['id'];
                    item[index]['created'] = res['created'];
                    item[index]['modified'] = res['modified'];
                    item[index]['body'] = res['body'];
                    item[index]['replyCount'] = res['replyCount'];
                    item[index]['mobilePhoneNumber'] = res['mobilePhoneNumber'];
                    item[index]['optIn'] = res['optIn'];
                    item[index]['orgLevelId'] = res['orgLevelId'];
                    item[index]['photo'] = res['photo'];
                    item[index]['status'] = res['status'];
                    item[index]['isOnLeave'] = res['isOnLeave'];
                    item[index]['fullName'] = res['employeeName'];
                    item[index]['employeeName'] = res['employeeName'];
                    if (selectedEmp === res['employeeId']) {
                        var rowData = item.filter(function (x) { return x.employeeId === selectedEmp; })[0];
                        _this.selectedId = rowData.id;
                        _this.messageManService.updateMessageThread(rowData, _this.orgLevelId, true, _this.isMyMessage);
                        _this.scrollToTop();
                    }
                    else {
                        item[index]['read'] = res['read'];
                    }
                    foundItem = _.cloneDeep(item[index]);
                    _.remove(item, { employeeId: res.employeeId });
                    _this.sortClientSide(_this.selectedSortFilter);
                }
            });
            if (isItemFound) {
                _this.lessThanOneMonth = _this.lessThanOneMonth.concat(foundItem);
            }
            else {
                _this.lessThanOneMonth = _this.lessThanOneMonth.concat(res);
            }
            _this.lessThanOneMonth = _this.sortService.messageSort(_this.lessThanOneMonth, _this.selectedSortFilter);
            _this.sortClientSide(_this.selectedSortFilter);
        }));
        this.subscriptions.archiveMessages = this.messageManService.subscribeToUpdateArchiveMessages(function () {
            _this.onToggelSelected('None');
            _this.isAllSelected = false;
            var allList = _this.allMessageList();
            _.each(allList, function (item, i) {
                var selectedEmpId = item.filter(function (x) { return x.id === _this.selectedId; }).map(function (y) { return y.employeeId; })[0];
                if (selectedEmpId) {
                    _this.messageManService.emptyMessageThread();
                }
            });
            allList = [];
            _this.unSelectedemployess = [];
            _this.currentPageno = 1;
            _this.requestObj.currentPageno = _this.currentPageno;
            _this.messageManService.setRequestParams(_this.requestObj);
            _this.clearData();
            _this.switchTabContent();
            _this.isLoading = false;
            _this.selectedAll = false;
            _this.getArchiveReadUnreadCount.emit(true);
            _this.messageManService.callUnReadCountApi();
        });
        this.subscriptions.unArchiveMessages = this.messageManService.subscribeToUpdateUnarchiveMessages((function (res) {
            var selectedIds = _.cloneDeep(_this.selectedMessageIds);
            _this.onToggelSelected('None');
            _this.isAllSelected = false;
            var allList = _this.allMessageList();
            _.each(allList, function (item, i) {
                var selectedEmpId = item.filter(function (x) { return x.id === _this.selectedId; }).map(function (y) { return y.employeeId; })[0];
                if (selectedEmpId) {
                    _this.messageManService.emptyMessageThread();
                }
            });
            allList = [];
            _this.unSelectedemployess = [];
            _this.currentPageno = 1;
            _this.requestObj.currentPageno = _this.currentPageno;
            _this.messageManService.setRequestParams(_this.requestObj);
            _this.clearData();
            _this.switchTabContent();
            _this.isLoading = false;
            _this.getArchiveReadUnreadCount.emit(true);
            _this.selectedAll = false;
            _this.messageManService.callUnReadCountApi();
        }));
        this.subscriptions.updateReadMessages = this.messageManService.subscribeToUpdateReadMessages((function (res) {
            var selectedIds = _.cloneDeep(_this.selectedMessageIds);
            _this.onToggelSelected('None');
            _this.isAllSelected = false;
            var allList = _this.allMessageList();
            _.each(allList, function (item, i) {
                if (_this.selectedAll) {
                    item.map(function (y) { return y.read = true; });
                }
                else {
                    selectedIds.forEach(function (element) {
                        item.filter(function (x) { return x.employeeId === element; }).map(function (y) { return y.read = true; });
                    });
                }
            });
            allList = [];
            _this.sortClientSide(_this.selectedSortFilter);
            _this.selectedAll = false;
            _this.unSelectedemployess = [];
            _this.getArchiveReadUnreadCount.emit(true);
            _this.messageManService.callUnReadCountApi();
            _this.isLoading = false;
        }));
        this.subscriptions.updateUnreadMessages = this.messageManService.subscribeToUpdateUnreadMessages((function (res) {
            var selectedIds = _.cloneDeep(_this.selectedMessageIds);
            _this.onToggelSelected('None');
            _this.isAllSelected = false;
            var allList = _this.allMessageList();
            _.each(allList, function (item, i) {
                if (_this.selectedAll) {
                    item.map(function (y) { return y.read = false; });
                }
                else {
                    selectedIds.forEach(function (element) {
                        item.filter(function (x) { return x.employeeId === element; }).map(function (y) { return y.read = false; });
                    });
                }
            });
            allList = [];
            _this.sortClientSide(_this.selectedSortFilter);
            _this.selectedAll = false;
            _this.unSelectedemployess = [];
            _this.getArchiveReadUnreadCount.emit(true);
            _this.messageManService.callUnReadCountApi();
            _this.isLoading = false;
        }));
    };
    MessageListComponent.prototype.bindPageLoadData = function () {
        this.currentPageno = 1;
        this.requestObj.currentPageno = this.currentPageno;
        this.messageManService.setRequestParams(this.requestObj);
        this.messageList = [];
        this.messageManService.emptyMessageThread();
        this.switchTabContent();
    };
    MessageListComponent.prototype.ngOnDestroy = function () { };
    MessageListComponent.prototype.updateMessage = function (employeeId) {
        var _this = this;
        if (this.IsArchive) {
            var allList = this.allMessageList();
            _.each(allList, function (item) {
                var selectedEmp = item.filter(function (x) { return x.id === _this.selectedId; }).map(function (y) { return y.employeeId; })[0];
                var index = item.findIndex(function (x) { return x.employeeId === employeeId; });
                if (index > -1) {
                    item.splice(index, 1);
                    _this.getArchiveReadUnreadCount.emit(true);
                    _this.messageManService.callUnReadCountApi();
                }
                if (selectedEmp === employeeId) {
                    _this.messageManService.emptyMessageThread();
                }
            });
            allList = [];
        }
        else {
            this.messageManService.getRecentMessageByEmployeeId(employeeId, this.isMyMessage);
        }
    };
    MessageListComponent.prototype.scrollToTop = function () {
        if (!this.elementRef.nativeElement) {
            return false;
        }
        var msgListElements = this.elementRef.nativeElement.querySelectorAll('#emp-msg-list');
        if (msgListElements && msgListElements[msgListElements.length - 1]) {
            msgListElements[0].scrollIntoView(true);
        }
    };
    MessageListComponent.prototype.switchTabContent = function () {
        this.onToggelSelected('None');
        this.updateMsgCenterSelectedMessageIds.emit([]);
        this.clearData();
        this.getMessageDataWithCount();
        this.selectedId = '';
        this.selectedAll = false;
    };
    MessageListComponent.prototype.clearData = function () {
        this.messageRange = [];
        this.lessThanOneMonth = [];
        this.olderThanSixMonth = [];
        this.olderThanThreeMonth = [];
        this.olderThanOneMonth = [];
        this.setMessageRange();
        this.setCurrentSections();
        this.messageManService.emptyMessageThread();
    };
    MessageListComponent.prototype.getSectionToLoad = function () {
        var _this = this;
        var sectionName = "";
        var sectionState;
        _.each(this.messageRange, function (item) {
            if (sectionType.lessThanOneMonth == item && sectionName.length == 0) {
                sectionState = _this.sectionTypesMap[String(sectionType.lessThanOneMonth)];
                if (sectionState.loadCount < sectionState.totalCount && !sectionState.isCollapsed) {
                    if ((sectionState.loadCount == 0 && !sectionState.isloaded) || (sectionState.loadCount > 0 && sectionState.totalCount > _this.pageSize))
                        sectionName = String(sectionState.sectionName);
                }
            }
            else if (sectionType.olderThanOneMonth == item && sectionName.length == 0) {
                sectionState = _this.sectionTypesMap[String(sectionType.olderThanOneMonth)];
                if (sectionState.loadCount < sectionState.totalCount && !sectionState.isCollapsed) {
                    if ((sectionState.loadCount == 0 && !sectionState.isloaded) || (sectionState.loadCount > 0 && sectionState.totalCount > _this.pageSize))
                        sectionName = String(sectionState.sectionName);
                }
            }
            else if (sectionType.olderThanThreeMonth == item && sectionName.length == 0) {
                sectionState = _this.sectionTypesMap[String(sectionType.olderThanThreeMonth)];
                if (sectionState.loadCount < sectionState.totalCount && !sectionState.isCollapsed) {
                    if ((sectionState.loadCount == 0 && !sectionState.isloaded) || (sectionState.loadCount > 0 && sectionState.totalCount > _this.pageSize))
                        sectionName = String(sectionState.sectionName);
                }
            }
            else if (sectionType.olderThanSixMonth == item && sectionName.length == 0) {
                sectionState = _this.sectionTypesMap[String(sectionType.olderThanSixMonth)];
                if (sectionState.loadCount < sectionState.totalCount && !sectionState.isCollapsed) {
                    if ((sectionState.loadCount == 0 && !sectionState.isloaded) || (sectionState.loadCount > 0 && sectionState.totalCount > _this.pageSize))
                        sectionName = String(sectionState.sectionName);
                }
            }
        });
        return sectionName;
    };
    MessageListComponent.prototype.getTabMessagesRecords = function (sectionName, isReset) {
        if (_.isNil(sectionName) || _.isEmpty(sectionName)) {
            sectionName = this.getSectionToLoad();
        }
        if (sectionName.length > 0) {
            this.isLoading = true;
            if (!this.IsArchive) {
                this.selectedReadIds.splice(0, this.selectedReadIds.length);
                this.selectedUnreadIds.splice(0, this.selectedUnreadIds.length);
            }
            var sectionState = this.sectionTypesMap[sectionName];
            var iTabMessageRequest = {
                page: sectionState.currentPageNumber + 1,
                pageSize: this.pageSize,
                employees: [],
                isArchived: this.IsArchive,
                searchText: this.searchText,
                orgLevelId: this.orgLevelId,
                dateOrderByAscending: this.dateOrderByAscending,
                nameOrderByAscending: this.nameOrderByAscending,
                includeCold: sectionState.includeCold,
                startDate: sectionState.startDate,
                endDate: sectionState.endDate,
                sectionName: sectionName,
                isReset: isReset,
                isMyMessage: this.isMyMessage
            };
            this.messageManService.getMoreTabMessages(iTabMessageRequest);
        }
    };
    MessageListComponent.prototype.getMessageDataWithCount = function () {
        var _this = this;
        this.isLoading = true;
        this.isDataLoading.emit(true);
        var iSections = [];
        _.each(this.sectionsDetail, function (item) {
            var secState = _this.sectionTypesMap[String(item.sectionName)];
            var iSection = {
                sectionName: secState.sectionName,
                startDate: secState.startDate,
                endDate: secState.endDate,
                includeCold: secState.includeCold,
                orderByNameAsc: _this.nameOrderByAscending
            };
            iSections.push(iSection);
        });
        var iMessageCountRequest = {
            isArchived: this.IsArchive,
            searchText: this.searchText,
            orgLevelId: this.orgLevelId,
            isMyMessage: this.isMyMessage,
            sections: iSections
        };
        var sectionState = this.sectionTypesMap[sectionType.lessThanOneMonth];
        var iTabMessageRequest = {
            page: sectionState.currentPageNumber + 1,
            pageSize: this.pageSize,
            employees: [],
            isArchived: this.IsArchive,
            searchText: this.searchText,
            orgLevelId: this.orgLevelId,
            dateOrderByAscending: this.dateOrderByAscending,
            nameOrderByAscending: this.nameOrderByAscending,
            includeCold: sectionState.includeCold,
            startDate: sectionState.startDate,
            endDate: sectionState.endDate,
            sectionName: String(sectionState.sectionName),
            isReset: true,
            isMyMessage: this.isMyMessage
        };
        var iMessageDataAndCountRequest = {
            messageCount: iMessageCountRequest,
            messageData: iTabMessageRequest
        };
        this.iMessageDataAndCountRequestState = iMessageDataAndCountRequest;
        this.messageManService.getTabsMessagesCount(iMessageCountRequest);
        this.messageManService.getTabMessages(iTabMessageRequest);
    };
    MessageListComponent.prototype.getMessages = function (searchText, dateOrderByAscending, nameOrderByAscending, pageNo) {
        this.isLoading = true;
        this.isDataLoading.emit(true);
        if (!this.IsArchive) {
            this.selectedReadIds.splice(0, this.selectedReadIds.length);
            this.selectedUnreadIds.splice(0, this.selectedUnreadIds.length);
        }
        this.messageManService.getMessages(searchText, dateOrderByAscending, nameOrderByAscending, pageNo, this.orgLevelId, this.IsArchive, this.requestObj.includeCold, this.pageSize, this.isMyMessage);
    };
    MessageListComponent.prototype.getArchiveMessageList = function (searchText, dateOrderByAscending, nameOrderByAscending, pageNo) {
        this.isLoading = true;
        this.isDataLoading.emit(true);
        this.messageManService.getMessages(searchText, dateOrderByAscending, nameOrderByAscending, pageNo, this.orgLevelId, this.IsArchive, this.requestObj.includeCold, this.pageSize, this.isMyMessage);
    };
    MessageListComponent.prototype.refreshGrid = function () {
        if (!this.messageRange) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.messageRange, this.gridState.state);
    };
    MessageListComponent.prototype.onFilter = function (event) {
        this.searchText = event;
        this.messageList = [];
        this.currentPageno = 1;
        this.requestObj.currentPageno = this.currentPageno;
        this.messageManService.setRequestParams(this.requestObj);
        this.switchTabContent();
    };
    MessageListComponent.prototype.onToggleAllSelected = function (event, status) {
        var _this = this;
        this.selectedCheckBoxEvent = status;
        var allList = this.allMessageList();
        if (!allList)
            return;
        else if (allList.length == 0)
            return;
        if (event && status === 'All') {
            this.selectedAll = true;
            this.unSelectedemployess = [];
        }
        else {
            this.selectedAll = false;
        }
        _.each(allList, function (item) {
            item.forEach(function (element) {
                var employeeId = element.employeeId;
                if ((status === 'Read' || status === 'All') && element.read === true) {
                    element.isSelected = event;
                    if (_this.selectedReadIds.includes(employeeId)) {
                        _this.selectedReadIds.splice(_this.selectedReadIds.findIndex(function (val) { return val === employeeId; }), 1);
                    }
                    else {
                        _this.selectedReadIds.push(employeeId);
                    }
                }
                else if ((status === 'Unread' || status === 'All') && (element.read === false || element.read === null)) {
                    element.isSelected = event;
                    if (_this.selectedUnreadIds.includes(employeeId)) {
                        _this.selectedUnreadIds.splice(_this.selectedUnreadIds.findIndex(function (val) { return val === employeeId; }), 1);
                    }
                    else {
                        _this.selectedUnreadIds.push(employeeId);
                    }
                }
                else if (status === 'None') {
                    element.isSelected = false;
                }
                if (element.isSelected && event && !_this.selectedMessageIds.includes(employeeId)) {
                    _this.selectedMessageIds.push(employeeId);
                }
                else if (_this.selectedMessageIds.includes(employeeId)) {
                    _this.selectedMessageIds.splice(_this.selectedMessageIds.findIndex(function (val) { return val === employeeId; }), 1);
                }
                _this.updateMsgCenterSelectedMessageIds.emit(_this.selectedMessageIds);
            });
        });
        allList = [];
        if (!this.isAllSelected && this.selectedMessageIds.length === 0 && status === 'All') {
            this.sendSelectedEventToParent.emit('None');
            this.unSelectedemployess = [];
        }
        else {
            this.sendSelectedEventToParent.emit(this.selectedCheckBoxEvent);
        }
        if (this.messageCountObj) {
            if (status === 'None') {
                this.isAllSelected = false;
                this.selectedAll = false;
            }
            else if ((status === 'Unread' && this.messageCountObj.unRead !== this.messageCountObj.total) || (status === 'Read' && this.messageCountObj.unRead !== 0)) {
                this.isAllSelected = false;
                this.selectedAll = false;
            }
            else if ((status === 'Unread' && this.messageCountObj.unRead === this.messageCountObj.total) || (status === 'Read' && this.messageCountObj.unRead === 0)) {
                this.isAllSelected = true;
            }
            else if (this.selectedAll) {
                this.isAllSelected = true;
            }
        }
        this.messageList = _.cloneDeep(this.messageList);
        this.sortClientSide(this.selectedSortFilter);
    };
    MessageListComponent.prototype.updateMsgListSelectedMessageIds = function (item) {
        this.selectedCheckBoxEvent = '';
        if (item.read === true) {
            if (this.selectedReadIds.includes(item.employeeId)) {
                this.selectedReadIds.splice(this.selectedReadIds.findIndex(function (val) { return val === item.employeeId; }), 1);
            }
            else {
                this.selectedReadIds.push(item.employeeId);
            }
        }
        else if (item.read === false) {
            if (this.selectedUnreadIds.includes(item.employeeId)) {
                this.selectedUnreadIds.splice(this.selectedUnreadIds.findIndex(function (val) { return val === item.employeeId; }), 1);
            }
            else {
                this.selectedUnreadIds.push(item.employeeId);
            }
        }
        if (this.selectedMessageIds.includes(item.employeeId)) {
            this.selectedMessageIds.splice(this.selectedMessageIds.findIndex(function (val) { return val === item.employeeId; }), 1);
        }
        else {
            this.selectedMessageIds.push(item.employeeId);
        }
        var allList = this.allMessageList();
        var len = 0;
        _.each(allList, function (item) {
            len = len + item.length;
        });
        this.isAllSelected = len === this.selectedMessageIds.length;
        this.updateMsgCenterSelectedMessageIds.emit(this.selectedMessageIds);
        allList = [];
    };
    MessageListComponent.prototype.onToggelSelected = function (selectedVal) {
        this.selectedReadIds.splice(0, this.selectedReadIds.length);
        this.selectedUnreadIds.splice(0, this.selectedUnreadIds.length);
        this.selectedCheckBoxEvent = selectedVal;
        this.unSelectedemployess = [];
        this.onToggleAllSelected(false, 'None');
        switch (selectedVal) {
            case 'All':
                this.onToggleAllSelected(true, 'All');
                break;
            case 'Read':
                this.onToggleAllSelected(true, 'Read');
                break;
            case 'Unread':
                this.onToggleAllSelected(true, 'Unread');
                break;
            case 'None':
                break;
        }
    };
    MessageListComponent.prototype.collapsedChange = function (event, collapseName) {
        var sectionState = this.sectionTypesMap[collapseName];
        sectionState.isCollapsed = event;
        this.sectionTypesMap[collapseName] = sectionState;
        if (sectionState.loadCount == 0) {
            this.loadSectionData(sectionState);
        }
    };
    MessageListComponent.prototype.loadSectionData = function (sectionState) {
        this.getTabMessagesRecords(String(sectionState.sectionName));
    };
    MessageListComponent.prototype.messageSort = function (type) {
        this.selectedSortFilter = type;
        this.messageList = [];
        this.currentPageno = 1;
        if (type === 'Name Z to A' || type === 'Name A to Z') {
            this.dateOrderByAscending = null;
            if (type === 'Name A to Z') {
                this.nameOrderByAscending = true;
            }
            else {
                this.nameOrderByAscending = false;
            }
        }
        else if (type === 'Newest To Oldest' || type === 'Oldest To Newest') {
            this.nameOrderByAscending = null;
            if (type === 'Newest To Oldest') {
                this.dateOrderByAscending = false;
            }
            else {
                this.dateOrderByAscending = true;
            }
        }
        this.requestObj.nameOrderByAscending = this.nameOrderByAscending;
        this.requestObj.dateOrderByAscending = this.dateOrderByAscending;
        this.requestObj.currentPageno = this.currentPageno;
        this.requestObj.includeCold = false;
        this.messageManService.setRequestParams(this.requestObj);
        this.switchTabContent();
        this.scrollToTop();
    };
    MessageListComponent.prototype.updateArchiveMessages = function () {
        var obj = {
            "employees": this.selectedMessageIds,
            "excludeEmployees": [],
            "all": false,
            "orgLevelId": this.orgLevelId,
            "isArchived": this.IsArchive,
            "allUnRead": false,
            "allRead": false,
            "includeCold": this.requestObj.includeCold
        };
        this.isLoading = true;
        this.messageManService.updateArchiveMessages(obj);
    };
    MessageListComponent.prototype.updateUnArchiveMessages = function () {
        var obj = {
            "employees": this.selectedMessageIds,
            "excludeEmployees": [],
            "all": false,
            "orgLevelId": this.orgLevelId,
            "isArchived": this.IsArchive,
            "allUnRead": false,
            "allRead": false,
            "includeCold": this.requestObj.includeCold
        };
        this.isLoading = true;
        this.messageManService.updateUnArchiveMessages(obj);
    };
    MessageListComponent.prototype.updateReadMessages = function () {
        var obj = {
            "employees": this.selectedMessageIds,
            "excludeEmployees": [],
            "all": false,
            "orgLevelId": this.orgLevelId,
            "isArchived": this.IsArchive,
            "allUnRead": false,
            "allRead": false,
            "includeCold": this.requestObj.includeCold
        };
        this.isLoading = true;
        this.messageManService.updateReadMessages(obj);
    };
    MessageListComponent.prototype.updateUnreadMessages = function () {
        var selectedIds = _.cloneDeep(this.selectedMessageIds);
        var obj = {
            "employees": this.selectedMessageIds,
            "excludeEmployees": [],
            "all": false,
            "orgLevelId": this.orgLevelId,
            "isArchived": this.IsArchive,
            "allRead": false,
            "allUnRead": false,
            "includeCold": this.requestObj.includeCold
        };
        this.isLoading = true;
        this.messageManService.updateUnreadMessages(obj);
    };
    MessageListComponent.prototype.onScroll = function () {
        var msgListElements = this.elementRef.nativeElement.querySelectorAll('#emp-msg-list');
        if (msgListElements && msgListElements[msgListElements.length]) {
            msgListElements[msgListElements.length].scrollIntoView(true);
        }
        this.getTabMessagesRecords();
        this.messageManService.setRequestParams(this.requestObj);
    };
    MessageListComponent.prototype.updateMessagesList = function (updatedMessage) {
        var _this = this;
        if (updatedMessage !== null) {
            if (updatedMessage.archived) {
                this.selectedMessageIds.push(updatedMessage.employeeId);
                this.updateUnArchiveMessages();
            }
            else {
                updatedMessage.modified = new Date().toUTCString();
                var allList = this.allMessageList();
                _.each(allList, function (item) {
                    var index = item.findIndex(function (x) { return x.employeeId === updatedMessage.employeeId; });
                    if (index > -1) {
                        item[index] = updatedMessage;
                    }
                });
                allList = [];
                this.sortClientSide(this.selectedSortFilter);
            }
        }
        else {
            var allList = this.allMessageList();
            _.each(allList, function (item) {
                _this.selectedEmp = item.filter(function (x) { return x.id === _this.selectedId; }).map(function (y) { return y.employeeId; })[0];
            });
            allList = [];
            this.currentPageno = 1;
            this.requestObj.currentPageno = this.currentPageno;
            this.messageManService.setRequestParams(this.requestObj);
            this.messageManService.emptyMessageThread();
            this.getArchiveReadUnreadCount.emit(true);
            this.messageManService.callUnReadCountApi();
        }
        this.scrollToTop();
    };
    MessageListComponent.prototype.setMessageRange = function () {
        if ((!this.dateOrderByAscending || this.dateOrderByAscending === null) && this.nameOrderByAscending === null) {
            this.messageRange = ['lessthanmonth', 'olderthanonemonth', 'olderthanthreemonth', 'loadolderThan6Month'];
        }
        else if (this.dateOrderByAscending && this.nameOrderByAscending === null) {
            this.messageRange = ['loadolderThan6Month', 'olderthanthreemonth', 'olderthanonemonth', 'lessthanmonth'];
        }
        else if (this.nameOrderByAscending !== null) {
            this.messageRange = ['lessthanmonth'];
        }
        this.refreshGrid();
    };
    MessageListComponent.prototype.sortClientSide = function (type) {
        this.refreshGrid();
    };
    MessageListComponent.prototype.onsearchType = function (search) {
        if (search !== this.searchInput) {
            this.isLoading = true;
            this.searchInput = search;
            this.messageList = [];
            this.currentPageno = 1;
            this.requestObj.includeCold = false;
            this.requestObj.currentPageno = this.currentPageno;
            this.messageManService.setRequestParams(this.requestObj);
            this.searchTerm$.next(search);
        }
        else {
            this.isLoading = false;
        }
    };
    MessageListComponent.prototype.getUnSelectedemployess = function (event) {
        this.unSelectedemployess = event.map(function (x) { return x.employeeId; });
    };
    MessageListComponent.prototype.getAccordianlevel = function (item) {
        return item === 'olderthanthreemonth' ? 'Older than three months' : item === 'olderthanonemonth' ? 'Older than one month' : item === 'loadolderThan6Month' ? 'Older than six months' : '';
    };
    MessageListComponent.prototype.getColdStorageExist = function () {
        this.messageManService.getColdStorageExist(this.orgLevelId, this.IsArchive);
    };
    MessageListComponent.prototype.setCurrentSections = function () {
        var _this = this;
        this.sectionsDetail = [];
        _.each(this.messageRange, function (s) {
            var section = new SectionDetail();
            var sectionState = new SectionState();
            section.sectionName = s;
            section.orderByNameAsc = _this.nameOrderByAscending;
            sectionState.sectionName = section.sectionName;
            sectionState.startDate = section.startDate;
            sectionState.endDate = section.endDate;
            sectionState.includeCold = section.includeCold;
            sectionState.totalCount = 0;
            sectionState.loadCount = 0;
            sectionState.isCollapsed = true;
            sectionState.currentPageNumber = 0;
            _this.sectionsDetail.push(section);
            _this.sectionTypesMap[s] = sectionState;
        });
    };
    MessageListComponent.prototype.updateSectionMapWithTotalCount = function (sectionName, totalCount) {
        var state = this.sectionTypesMap[sectionName];
        state.totalCount = totalCount;
        state.loadCount = 0;
        state.isCollapsed = true;
        if (_.isEqual(state.sectionName, sectionType.lessThanOneMonth)) {
            state.isCollapsed = false;
        }
        this.sectionTypesMap[sectionName] = state;
    };
    MessageListComponent.prototype.updateSectionMapWithLoadCount = function (sectionName, loadCount) {
        var pageNumber = 0;
        pageNumber = (loadCount - (loadCount % this.pageSize)) / this.pageSize;
        if (loadCount % this.pageSize > 0) {
            pageNumber = pageNumber + 1;
        }
        var state = this.sectionTypesMap[sectionName];
        state.loadCount = loadCount;
        state.isloaded = true;
        state.currentPageNumber = pageNumber;
        this.sectionTypesMap[sectionName] = state;
        if (_.isEqual(sectionType.olderThanSixMonth, sectionName)) {
            if (loadCount > 0) {
                this.requestObj.includeCold = true;
            }
            else {
                this.requestObj.includeCold = false;
            }
        }
    };
    MessageListComponent.prototype.updateTabMessageThread = function (currentSectionRecords) {
        var _this = this;
        var selectedEmp = currentSectionRecords.filter(function (x) { return x.id === _this.selectedId; }).map(function (y) { return y.employeeId; })[0];
        if (selectedEmp) {
            var rowData = currentSectionRecords.filter(function (x) { return x.employeeId === selectedEmp; })[0];
            this.selectedId = rowData.id;
            this.messageManService.updateMessageThread(rowData, this.orgLevelId, true, this.isMyMessage);
        }
        this.searchInput = this.searchText;
        this.isLoading = false;
        this.isDataLoading.emit(false);
    };
    MessageListComponent.prototype.updateTabMessageRecords = function (record) {
        if (_.isEqual(record.sectionName, sectionType.lessThanOneMonth)) {
            this.lessThanOneMonth = this.lessThanOneMonth.concat(record.messages);
            this.updateSectionMapWithLoadCount(record.sectionName, this.lessThanOneMonth.length);
            this.updateTabMessageThread(this.lessThanOneMonth);
        }
        else if (_.isEqual(record.sectionName, sectionType.olderThanOneMonth)) {
            this.olderThanOneMonth = this.olderThanOneMonth.concat(record.messages);
            this.updateSectionMapWithLoadCount(record.sectionName, this.olderThanOneMonth.length);
            this.updateTabMessageThread(this.olderThanOneMonth);
        }
        else if (_.isEqual(record.sectionName, sectionType.olderThanThreeMonth)) {
            this.olderThanThreeMonth = this.olderThanThreeMonth.concat(record.messages);
            this.updateSectionMapWithLoadCount(record.sectionName, this.olderThanThreeMonth.length);
            this.updateTabMessageThread(this.olderThanThreeMonth);
        }
        else if (_.isEqual(record.sectionName, sectionType.olderThanSixMonth)) {
            this.olderThanSixMonth = this.olderThanSixMonth.concat(record.messages);
            this.updateSectionMapWithLoadCount(record.sectionName, this.olderThanSixMonth.length);
            this.updateTabMessageThread(this.olderThanSixMonth);
        }
        if (this.isAllSelected) {
            var prevState = _.cloneDeep(this.isAllSelected);
            this.onToggelSelected('All');
            this.isAllSelected = prevState;
        }
        else {
            if (_.isEqual(this.selectedCheckBoxEvent, 'Read') || _.isEqual(this.selectedCheckBoxEvent, 'Unread')) {
                var prevState = _.cloneDeep(this.selectedCheckBoxEvent);
                this.onToggelSelected(this.selectedCheckBoxEvent);
                this.selectedCheckBoxEvent = prevState;
            }
        }
    };
    MessageListComponent.prototype.allMessageList = function () {
        var allList = [];
        allList.push(this.lessThanOneMonth);
        allList.push(this.olderThanOneMonth);
        allList.push(this.olderThanThreeMonth);
        allList.push(this.olderThanSixMonth);
        return allList;
    };
    MessageListComponent.prototype.showWaitingPopup = function (isShow) {
        if (isShow) {
            var message = 'Please wait while we process your request.';
            var options = new ConfirmOptions();
            options.showCancel = false;
            options.showOK = true;
            options.height = 144;
            ConfirmDialog2Component.openDialog('Information', message, this.modalService, function (result) {
                if (result) {
                    //Success
                }
            }, options);
        }
    };
    MessageListComponent.prototype.updateSelectedMessageRecords = function (message) {
        this.lessThanOneMonth.unshift(message);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MessageListComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], MessageListComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MessageListComponent.prototype, "orgLevelSubscription", void 0);
    return MessageListComponent;
}());
export { MessageListComponent };
