<div class="modal-content-resizable">
  <div class="modal-body">
    <div>
      <slx-input-decorator className="slx-no-label slx-no-border slx-small-font slx-no-padding">
        <slx-checkbox-input slx-input name="sdeleteAllLinkedVirtualClocks" fieldName="deleteAllLinkedVirtualClocks" placeholder=""
          caption="Delete all linked Virtual Clocks" [(ngModel)]="timeclockRemoveRequest.deleteAllLinkedVirtualClocks"></slx-checkbox-input>
      </slx-input-decorator>
    </div>
    <div>
      <slx-input-decorator className="slx-no-label slx-no-border slx-small-font slx-no-padding">
        <slx-checkbox-input slx-input name="unassignAllEmployees" fieldName="unassignAllEmployees" placeholder=""
          caption="Unassign All Employees" [(ngModel)]="timeclockRemoveRequest.unassignAllEmployees"></slx-checkbox-input>
      </slx-input-decorator>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
    <button type="button" (click)="onOk()" class="theme-button-apply">OK</button>
  </div>
</div>
