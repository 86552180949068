<label *ngIf="hasPlaceholder" class="slx-datetimepicker__label">{{ placeholder }}:</label>
<div class="slx-datepicker slx-datetimepicker__date">
  <kendo-datepicker class="slx-datepicker__kendo"
    name="date"
    [min]="minDate"
    [max]="maxDate"
    [format]="dateFormat"
    [disabled]="readonly"
    [value]="date"
    (valueChange)="onChangeDate($event)"
    (blur)="onBlur()"
  ></kendo-datepicker>
</div>
<div class="slx-timepicker slx-datetimepicker__time">
  <kendo-timepicker class="slx-timepicker__kendo"
    name="time"
    [min]="minTime"
    [max]="maxTime"
    [format]="timeFormat"
    [disabled]="readonly || readonlyOnlyTime || isReadonlyUntilDateInput"
    [value]="time"
    (valueChange)="onChangeTime($event)"
    (blur)="onBlur()"
  ></kendo-timepicker>
</div>
