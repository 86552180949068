import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class ValuesPipe implements PipeTransform {
  public transform(value: any, args?: any[]): any[] {
    if (!value) {
      return null;
    }
    let keyArr: string[] = Object.keys(value),
        dataArr: any[] = [];
    keyArr.forEach((key: any) => {
      dataArr.push({
        key: key,
        value: value[key]
      });
    });
    return dataArr;
  }
}
