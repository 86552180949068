import { Directive, ElementRef, AfterViewInit, Input, OnChanges, SimpleChanges, Renderer2, HostListener, AfterViewChecked } from '@angular/core';
import * as _ from 'lodash';

import { appConfig } from '../../../app.config';

@Directive({
  selector: '[slxMenuItemScroll]',
})
export class MenuItemScrollDirective implements OnChanges, AfterViewChecked {
  @Input('slxMenuItemScroll')
  public enabled: boolean;

  private elementRef: ElementRef;

  constructor(elementRef: ElementRef, private renderer: Renderer2) {
    this.elementRef = elementRef;
  }

  public ngOnChanges(changes: SimpleChanges): void {
     if (changes['enabled']) {
       if (this.enabled) {
         this.process();
       } else {
         this.restore();
       }
     }
  }

  public ngAfterViewChecked(): void {
    if (this.enabled) {
      this.process();
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent): void {
    if (this.enabled) {
      this.process();
    }
  }

  public process(): void {
    const winHeight = $(window).height();
    const topOffset = $(this.elementRef.nativeElement).offset().top;
    const height = $(this.elementRef.nativeElement).height();
    const maxHeight = _.max([winHeight - topOffset - 5, 0]);
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', `${maxHeight}px`);
    if ((height + 10) > maxHeight) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'overflow', 'auto');
    }
  }

  public restore(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', 'initial');
    this.renderer.setStyle(this.elementRef.nativeElement, 'overflow', 'visible');
  }

  private get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }
}
