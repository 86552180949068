import { Injectable } from '@angular/core';

import { ProductModule, IProductModule } from '../../models/index';

@Injectable()
export class ProductModuleMapService {
  constructor() {
  }

  public mapToProductModuleDto(data: ProductModule): IProductModule {
    const dto: IProductModule = {
      id: data.id,
      name: data.name,
      type: data.type,
      applicationId: data.applicationId,
      menuId: data.menuId,
      isDisabled: data.isDisabled,
      isEditable: data.isEditable
    };
    return dto;
  }

  public mapToProductModule(dto: IProductModule): ProductModule {
    const data = new ProductModule();
    data.id = dto.id;
    data.name = dto.name;
    data.type = dto.type;
    data.applicationId = dto.applicationId;
    data.menuId = dto.menuId;
    data.isDisabled = dto.isDisabled;
    data.isEditable = dto.isEditable;
    data.isDisabledInitial = data.isDisabled;
    return data;
  }
}
