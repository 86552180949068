import { Organization, IOrganization, Department, IDepartment, ScheduleCycle, IScheduleCycle } from '../../../organization/models/index';

export interface IScheduleNotPosted {
    organization: IOrganization;
    department: IDepartment;
    scheduleCycle: IScheduleCycle;
    schedulecyclestart: number;
    countOfEmployees: number;
    countOfshifts: number;
}
export class ScheduleNotPosted {
    public get organizationName(): string {
        return this.organization ? this.organization.name : '';
    }
    public get departmentName(): string {
        return this.department ? this.department.name : '';
    }
    public get scheduleCycleStartDate(): Date {
        return this.scheduleCycle ? this.scheduleCycle.startDate.toDate() : null;
    }
    public get scheduleCycleEndDate(): Date {
        return this.scheduleCycle ? this.scheduleCycle.endDate.toDate() : null;
    }
    public organization: Organization;
    public department: Department;
    public scheduleCycle: ScheduleCycle;
    public schedulecyclestart: number;
    public countOfEmployees: number;
    public countOfshifts: number;

    public get schedulecyclestartDay(): string {
        return `${this.schedulecyclestart} days`;
    }
}