import * as tslib_1 from "tslib";
import { ConfirmDialogComponent, ConfirmOptions } from './../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from './../../../common/services/modal/modal.service';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ShiftSwapApiService } from './shift-swap-api.service';
import { ShiftSwapStatus } from '../../models/shift-swap/shift-swap-status';
import { ShiftSwapType } from '../../models/shift-swap/shift-swap-type';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators';
import { ShiftSwapApproveDenyPopupComponent } from '../../components/shift-swap/shift-swap-approve-deny-popup/shift-swap-approve-deny-popup.component';
import * as _ from 'lodash';
import * as moment from 'moment';
var ShiftSwapManageService = /** @class */ (function () {
    function ShiftSwapManageService(apiService, modalService) {
        this.apiService = apiService;
        this.modalService = modalService;
        this.startDate = new Date();
        this.endDate = moment(new Date()).add(1, 'month').toDate();
        this.orgLevelId = 1;
        this.type = ShiftSwapType.All;
        this.status = ShiftSwapStatus.Accepted;
        this.statusList = _.values(ShiftSwapStatus);
        this.typeList = _.values(ShiftSwapType);
        this.isLoading = false;
    }
    ShiftSwapManageService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
            _this.refreshList();
        });
        this.createColumns();
    };
    ShiftSwapManageService.prototype.destroy = function () { };
    ShiftSwapManageService.prototype.changeDateRange = function (sDate, eDate) {
        if (this.startDate.toLocaleString() === sDate.toLocaleString()
            && this.endDate.toLocaleString() === eDate.toLocaleString())
            return;
        this.startDate = sDate;
        this.endDate = eDate;
        this.refreshList();
    };
    ShiftSwapManageService.prototype.changeStatus = function (status) {
        if (this.status === status)
            return;
        this.status = status;
        this.refreshList();
    };
    ShiftSwapManageService.prototype.changeType = function (type) {
        if (this.type === type)
            return;
        this.type = type;
        this.refreshList();
    };
    ShiftSwapManageService.prototype.refreshList = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.getList(this.orgLevelId, this.startDate, this.endDate, this.type, this.status)
            .then(function (list) {
            _this.list = list;
            _this.isLoading = false;
        });
    };
    ShiftSwapManageService.prototype.showDetails = function (shiftSwap) {
        var _this = this;
        ShiftSwapApproveDenyPopupComponent.openDialog(shiftSwap, this.modalService, function (needUpdate) {
            if (needUpdate) {
                _this.refreshList();
            }
        });
    };
    ShiftSwapManageService.prototype.approve = function (shiftSwap, comment) {
        var _this = this;
        if (comment === void 0) { comment = null; }
        if (this.isLoading) {
            return Promise.reject();
        }
        if (shiftSwap.status !== ShiftSwapStatus.Accepted) {
            return new Promise(function (resolve) {
                var options = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog('Shift Swap Approve', "Employee " + shiftSwap.submittedTo.name + " is yet to accept this shift swap request. Are you sure you want to approve?", _this.modalService, function (result) {
                    if (result) {
                        resolve(_this.approveShiftSwap(shiftSwap, comment));
                    }
                }, options);
            });
        }
        else {
            return this.approveShiftSwap(shiftSwap, comment);
        }
    };
    ShiftSwapManageService.prototype.deny = function (shiftSwap, comment) {
        var _this = this;
        if (comment === void 0) { comment = null; }
        if (this.isLoading) {
            return;
        }
        this.isLoading = true;
        return this.apiService.deny(this.orgLevelId, shiftSwap.shiftSwapId, comment)
            .then(function (value) {
            _this.refreshList();
        })
            .catch(function (reason) {
            _this.isLoading = false;
        });
    };
    ShiftSwapManageService.prototype.loadDetails = function (shiftSwap) {
        return this.apiService.getDetails(this.orgLevelId, shiftSwap.shiftSwapId).then(function (details) {
            return details;
        });
    };
    ShiftSwapManageService.prototype.recalculateColumns = function () {
        this.displayedColumns = _.filter(this.columns, function (c) { return c.displayed; });
    };
    ShiftSwapManageService.prototype.approveShiftSwap = function (shiftSwap, comment) {
        var _this = this;
        if (comment === void 0) { comment = null; }
        this.isLoading = true;
        return this.apiService.approve(this.orgLevelId, shiftSwap.shiftSwapId, comment)
            .then(function (value) {
            _this.refreshList();
        })
            .catch(function (reason) {
            _this.isLoading = false;
        });
    };
    ShiftSwapManageService.prototype.createColumns = function () {
        this.columns = [
            { name: 'dateHired', description: 'Date of Hire', displayed: true },
            { name: 'unionStatus', description: 'Union Status', displayed: true },
            { name: 'projectedHours', description: 'Projected Hours', displayed: true },
            { name: 'projectedForOvertime', description: 'Projected for Overtime', displayed: true },
            { name: 'type', description: 'Emp Type', displayed: true },
            { name: 'position', description: 'Position', displayed: true },
            { name: 'department', description: 'Department', displayed: true },
        ];
        this.recalculateColumns();
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftSwapManageService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftSwapManageService.prototype, "orgLevelSubscription", void 0);
    return ShiftSwapManageService;
}());
export { ShiftSwapManageService };
