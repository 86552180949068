<div class="star-rating-content" [ngClass]="{ 'star-reverse':hasError || !five_star_goal_section_enabled }">
    <div class="content-left-part" [ngClass]="{ 'expand-left':hasError || !five_star_goal_section_enabled, 'expand-left-print': (hasError || !five_star_goal_section_enabled) && hideToPrint }">
        <slx-fivestar-predictor-rating-badge [model]="srOrganization" [showDetails]="!hasError" [hasRightErrors]="hasError || !five_star_goal_section_enabled"></slx-fivestar-predictor-rating-badge>
    </div>

    <div [ngClass]="{ 'expand-left': hasError || !five_star_goal_section_enabled }">
        <!-- <div *ngIf="outdatedData" class="error-info not-critical">
            <div class="error-messagex-box">
                <span class="error-mesage-text">
                    Due to technical issues in retrieving latest data, calculations have been based on last values retrieved from CMS. We are working with CMS to get the latest values.
                </span>
            </div>
        </div> -->

    <div *ngIf="!isLoading">
        <div *ngIf="hasError" class="error-info">
            <em *ngIf="hasError" class="triangle-exclamation fas fa-exclamation-triangle"></em>
            <div class="error-messagex-box">
                <span class="error-mesage-text" *ngIf="!hasData && !isLoading ">Can not load information for this organization</span>
                <span class="error-mesage-text" *ngIf="noCmsData">
                    Calculations cannot be performed for this facility as there is no staffing hours data available on CMS.
                </span>
                <span class="error-mesage-text" *ngIf="noCcn">
                  Please enter the facility CCN number to proceed with the Staffing Rating Predictor.
                  <span class="link-text" (click)="goToConfigScreen()">Click here</span>
                  to enter the CCN number on the
                  <span class="link-text" (click)="goToConfigScreen()">“Configure PBJ Organization Screen”</span>
                </span>
                <span class="error-mesage-text" *ngIf="noCensus">
                  There is no census value for {{srOrganization?.organization.name}}. Please enter a census value for your facility
                </span>
                <span class="error-mesage-text" *ngIf="noHoursData">No hours in the system for the date range entered</span>
                <span class="error-mesage-text" *ngIf="rnZeroDays">
                    Your Facility will receive a 1 star rating as there are Zero RN Hours for 4 or more days in the date range selected
                </span>
                <span class="error-mesage-text" *ngIf="isFiveStarRatingAvailable">One or more required data points for the selected time period are not available on the CMS website for this location.  The Five Star Rating cannot be calculated until this data is available from the CMS.  Please see the <a href="https://www.medicare.gov/care-compare/?providerType=NursingHome" target="_blank"> CMS Care Compare website</a> for more details 
                </span>
            </div>
        </div>
    </div>
    </div>

    <div class="content-right-part" *ngIf="!hasError && five_star_goal_section_enabled">
        <slx-fivestar-predictor-rating-predictor class="rating-predictor" (getRating)="goalRating($event)" [sectionIndex]="sectionIndex" >
        </slx-fivestar-predictor-rating-predictor>
    </div>
</div>
