import * as moment from 'moment';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { IUserLoginSessionInfo, UserLoginSessionInfo } from '../../models/user-login-management/user-session-info';
import { IUserLoginManagement, UserLoginManagement } from '../../models/user-login-management/user-login-management';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';

@Injectable()
export class UserLoginManagementMapService {

    constructor() { }

    public mapUserManagementInfo(data: IUserLoginManagement): UserLoginManagement {
        const info = new UserLoginManagement();
        info.refreshRate = data.refreshRate;
        info.sessions = this.mapUserSessionInfos(data.sessions);
        return info;
    }


    public mapUserSessionInfos(data: IUserLoginSessionInfo[]): UserLoginSessionInfo[] {
        return _.map(data, d => this.mapUserSessioninfo(d));
    }

    public mapUserSessioninfo(record: IUserLoginSessionInfo): UserLoginSessionInfo {
        const session = new UserLoginSessionInfo();
        // from user record
        session.userId = record.userId;
        session.userName = record.userName;
        session.userLogin = record.userLogin;
        session.email = record.email;
        session.ipAddress = record.ipAddress;
        session.lastUpdateOn = record.lastUpdateOn ? new Date(record.lastUpdateOn): null;
        session.status = record.status;
        // from session record
        session.lastLogin =record.lastLogin ? new Date(record.lastLogin): null;
        session.ipAddress = record.ipAddress;
        session.closed = record.closed;
        session.closeTime = record.closeTime ? new Date(record.closeTime): null;
        // calculated
        const sessionEnd = session.lastLogin ? (session.closeTime || moment().toDate()) : null;
        session.daySinceLastLogin = session.lastLogin ? moment.duration(moment().diff(session.lastLogin)).days() : 0;
        session.sessionDuration = session.lastLogin ? moment(sessionEnd).diff(session.lastLogin) : 0;
        session.formattedSessionDuration = dateTimeUtils.formatDuration(moment.duration(session.sessionDuration));
        session.loggedIn = session.lastLogin ? !session.closed : false;
        session.selectable = session.loggedIn;
        return session;
    }
}
