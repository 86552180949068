import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { Assert } from '../../../../framework/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { EmployeeSectionsBenefitsManagementMapService } from './employee-sections-benefits-management-map.service';
import { dateTimeUtils } from '../../../../common/utils';
var EmployeeSectionsBenefitsManagementApiService = /** @class */ (function () {
    function EmployeeSectionsBenefitsManagementApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsManagementSection = function (employeeId, effectiveDate) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getBenefitsManagementApiRoot(employeeId);
        var request = this.urlParamsService.createGetRequest(url, { effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToSection(response.data); });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getEmployeeEnrollments = function (employeeId, effectiveDate) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getEnrollmentApiRootWithoutEmp();
        var request = this.urlParamsService.createGetRequest(url, { employeeId: employeeId, effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToEnrollment(response.data, response.meta); });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getEmployeeBenefitClasses = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getBenManApiRootWithoutEmp() + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitClasses;
        var req = this.urlParamsService.createGetRequest(url, { employeeId: employeeId });
        return this.apiUtilService.request(req)
            .then(function (response) {
            return _this.mapService.mapToEmployeeBenefitClasses(response.data, response.meta);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.saveBenefitClassesSection = function (employeeSectionsBenefitClasses, employeeId) {
        var url = this.getBenefitsManagementApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitClasses;
        var saveDto = this.mapService.mapToBenefitClassesDTO(employeeSectionsBenefitClasses);
        var request = new HttpRequest('PUT', url, { benefitClassesSubsection: saveDto });
        return this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsManagementDependents = function (employeeId) {
        var _this = this;
        Assert.isNotNull(employeeId, 'employeeId');
        var url = this.getBenefitsManagementDependentsApiRoot(employeeId);
        var request = new HttpRequest('GET', url);
        return this.apiUtilService.request(request)
            .then(function (response) {
            return _this.mapService.mapToDependents(response.data.data, response.data.metadata);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsEnrollmentAttachment = function (employeeId) {
        var _this = this;
        var url = this.getBenefitsManagementEnrollmentAttachmentsApiRoot();
        var request = this.urlParamsService.createGetRequest(url, { employeeId: employeeId });
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapToAttachments(response.data); });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.saveNotes = function (employeeId, selectedRecord) {
        var _this = this;
        var url = this.getBenefitsManagemApiSaveNotesRoot(employeeId);
        var request = this.urlParamsService.createPostRequest(url, selectedRecord);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapBenefitEnrollmentHistoryRecord(response.data); });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.addDocument = function (documents, employeeId, name) {
        var url = this.getBenefitsManagementEnrollmentAttachmentsApiRoot();
        var formData = this.mapService.mapFilesToFormData(documents, name);
        var request = this.urlParamsService.requestPost(url, formData, { employeeId: employeeId });
        request.autoContentType = true;
        var promise = this.apiUtilService.requestNew(request, false)
            .then(function (response) { return response.data; });
        return promise;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.deleteAttachment = function (documentIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getBenefitsManagementEnrollmentAttachmentsApiRoot();
                        request = this.urlParamsService.createDeleteRequest(url, { documentIds: documentIds });
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.downloadAttachment = function (documentId) {
        var url = this.getBenefitsManagementEnrollmentDownLoad();
        var request = this.urlParamsService.createGetRequest(url, { documentId: documentId });
        return this.apiUtilService.requestForFile(request)
            .then(function (file) { return file; });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.deleteEnrollment = function (recordId) {
        Assert.isNotNull(recordId, 'recordId');
        var url = this.getEnrollmentApiRootWithoutEmp();
        var request = this.urlParamsService.createDeleteRequest(url, { employeeToBenefitId: recordId });
        return this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.updateEnrollment = function (enrollment) {
        var _this = this;
        var url = this.getEnrollmentApiRootWithoutEmp();
        var body = this.mapService.mapBenefitEnrollmentHistoryDto(enrollment);
        var request = this.urlParamsService.createPutRequest(url, body);
        return this.apiUtilService
            .request(request)
            .then(function (response) { return _this.mapService.mapBenefitEnrollmentHistoryRecord(response.data); });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getEmployeeActiveBenefitPlans = function (empId, groupId, effectiveDate) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/activeBenefitPlans";
        var req = this.urlParamsService.createGetRequest(url, { employeeId: empId, benefitGroupId: groupId, effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate) });
        return this.apiUtilService.request(req)
            .then(function (response) {
            return _this.mapService.mapToBenefitPlanShortInfos(response.data, response.meta);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitPlanOptionRates = function (lineId) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitPlanOptionrates";
        var req = this.urlParamsService.createGetRequest(url, { lineId: lineId });
        return this.apiUtilService.request(req)
            .then(function (response) {
            return _this.mapService.mapToBenefitPlanOptionRates(response.data, response.meta);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitTiers = function (lineId) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitTiers";
        var req = this.urlParamsService.createGetRequest(url, { lineId: lineId });
        return this.apiUtilService.request(req)
            .then(function (response) {
            return _this.mapService.mapToBenefitTierDefinitions(response.data, response.meta);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitPlanFlat = function (tierId) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitPlanFlat";
        var req = this.urlParamsService.createGetRequest(url, { tierId: tierId });
        return this.apiUtilService.request(req)
            .then(function (response) {
            return _this.mapService.mapToBenefitsEmpEnrollmentFlat(response.data, response.meta);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitPlanFormula = function (tierId, employeeId, effectiveDate, coverage, optionCode) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitPlanFormula";
        var req = this.urlParamsService.createGetRequest(url, { tierId: tierId, employeeId: employeeId, effectiveDate: effectiveDate, coverage: coverage, optionCode: optionCode });
        return this.apiUtilService.request(req)
            .then(function (response) {
            return _this.mapService.mapToBenefitsEmpEnrollmentFormula(response.data, response.meta);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitPlan401k = function (tierId) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitPlan401K";
        var req = this.urlParamsService.createGetRequest(url, { tierId: tierId });
        return this.apiUtilService.request(req)
            .then(function (response) {
            return _this.mapService.mapToBenefitsEmpEnrollment401k(response.data, response.meta);
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBeneficiaries = function (enrollmentId) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitsEnrollBeneficiaries";
        var req = this.urlParamsService.createGetRequest(url, { employeeToBenefitId: enrollmentId });
        return this.apiUtilService.request(req)
            .then(function (response) {
            var beneficiaries = _this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
            var relations = _this.mapService.mapToRelations(response.data.relations);
            return { beneficiaries: beneficiaries, relations: relations };
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBeneficiariesPrev = function (enrollmentId) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitsEnrollPrevBeneficiaries";
        var req = this.urlParamsService.createGetRequest(url, { employeeToBenefitId: enrollmentId });
        return this.apiUtilService.request(req)
            .then(function (response) {
            var beneficiaries = _this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
            var relations = _this.mapService.mapToRelations(response.data.relations);
            return { beneficiaries: beneficiaries, relations: relations };
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.removeBeneficiary = function (item) {
        var url = this.getBenManApiRootWithoutEmp() + "/benefitsEnrollBeneficiaries";
        var req = this.urlParamsService.createDeleteRequest(url, { beneficiaryId: item.id });
        return this.apiUtilService.request(req)
            .then(function (response) { return response.data; });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.addBeneficiaries = function (items) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitsEnrollBeneficiaries";
        var req = this.urlParamsService.createPostRequest(url, this.mapService.mapToBeneficiariesDto(items));
        return this.apiUtilService.request(req)
            .then(function (response) {
            var beneficiaries = _this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
            var relations = _this.mapService.mapToRelations(response.data.relations);
            return { beneficiaries: beneficiaries, relations: relations };
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.updateBeneficiaries = function (items) {
        var _this = this;
        var url = this.getBenManApiRootWithoutEmp() + "/benefitsEnrollBeneficiaries";
        var req = this.urlParamsService.createPostRequest(url, this.mapService.mapToBeneficiariesDto(items));
        return this.apiUtilService.request(req)
            .then(function (response) {
            var beneficiaries = _this.mapService.mapToBeneficiaries(response.data.empBeneficiaries, response.meta);
            var relations = _this.mapService.mapToRelations(response.data.relations);
            return { beneficiaries: beneficiaries, relations: relations };
        });
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version + "/" + employeeSectionsConfig.api.employees.root;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getSectionsApiRoot = function (employeeId) {
        return this.getApiRoot() + "/" + employeeId + "/" + employeeSectionsConfig.api.employees.sections.root;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsManagementApiRoot = function (employeeId) {
        return this.getSectionsApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.root;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getEnrollmentApiRootWithoutEmp = function () {
        return this.getBenManApiRootWithoutEmp() + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitHistory;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenManApiRootWithoutEmp = function () {
        return this.getApiRoot() + "/" + employeeSectionsConfig.api.employees.sections.root + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.root;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsManagementDependentsApiRoot = function (employeeId) {
        return this.getBenefitsManagementApiRoot(employeeId) + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.dependents;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsManagementEnrollmentAttachmentsApiRoot = function () {
        return this.getApiRoot() + "/" + employeeSectionsConfig.api.employees.sections.root + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.root + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.attachments;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsManagementEnrollmentDownLoad = function () {
        return this.getBenefitsManagementEnrollmentAttachmentsApiRoot() + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.download;
    };
    EmployeeSectionsBenefitsManagementApiService.prototype.getBenefitsManagemApiSaveNotesRoot = function (employeeId) {
        return this.getApiRoot() + "/" + employeeSectionsConfig.api.employees.sections.root + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.root + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.benefitHistory + "/" + employeeSectionsConfig.api.employees.sections.benefitsManagement.notes;
    };
    return EmployeeSectionsBenefitsManagementApiService;
}());
export { EmployeeSectionsBenefitsManagementApiService };
