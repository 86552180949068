import { Component, Input } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { Unit } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-location-unit-remove-dialog',
  templateUrl: 'location-unit-remove-dialog.component.html',
  styleUrls: ['location-unit-remove-dialog.component.scss']
})

export class UnitRemoveDialogComponent implements IDialog {

    public dialogResult: boolean;
    public unit: Unit;
    @Input()
    public units: Unit[] = [];

    private modalService: ModalService;
    private options: DialogOptions;

    constructor(options: DialogOptions, modalService: ModalService) {
        this.options = options;
        this.modalService = modalService;
    }

    public onOk(): void {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }

    public onCancel(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }
}
