import * as tslib_1 from "tslib";
import { Meta } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { payUnitsConfig } from '../../pay-units.config';
import { PayUnitsMassAssignmentMapService } from './pay-units-mass-assignment-map.service';
import { dateTimeUtils } from '../../../../common/utils/index';
var PayUnitsMassAssignmentApiService = /** @class */ (function () {
    function PayUnitsMassAssignmentApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    PayUnitsMassAssignmentApiService.prototype.getMassAssignPayUnits = function (orgLevelId, startDate, endDate, empIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + payUnitsConfig.massAssignment;
                        params = {
                            orgLevelId: orgLevelId,
                            startDate: dateTimeUtils.convertToDtoString(startDate),
                            endDate: dateTimeUtils.convertToDtoString(endDate),
                            empIds: empIds
                        };
                        request = this.urlParamsService.createGetRequest(url, params, true);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToMassAssignPayUnits(response.data)];
                }
            });
        });
    };
    PayUnitsMassAssignmentApiService.prototype.saveMassAssignPayUnits = function (orgLevelId, payload) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + payUnitsConfig.massAssignment;
                        params = {
                            orgLevelId: orgLevelId
                        };
                        request = this.urlParamsService.createPutRequest(url, payload, params);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PayUnitsMassAssignmentApiService.prototype.getApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + payUnitsConfig.ta + "/" + payUnitsConfig.payunits;
    };
    return PayUnitsMassAssignmentApiService;
}());
export { PayUnitsMassAssignmentApiService };
