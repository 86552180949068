import { Injectable } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../app.config';

import { ManagementBaseService } from '../../../../core/services/index';
import { PayrollExportApiService } from './payroll-export-api.service';

import { PayrollExportContainer } from '../../models/index';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { IControlState, StateResetTypes } from '../../../../core/models/index';
import { NotificationsService } from '../../../../core/components/index';
import { appMessages } from '../../../../app.messages';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';

@Injectable()
export class PayrollExportManagementService extends ManagementBaseService<PayrollExportContainer, any> {

  public data: PayrollExportContainer;
  public startDate: Date;
  public endDate: Date;
  public orgLevel: OrgLevel;

  constructor(
    private api: PayrollExportApiService,
    private notificationsService: NotificationsService,
  ) {
    super();
  }

  public isOrganization(): boolean {
    return this.orgLevel.type === OrgLevelType.organization;
  }

  public loadPayrollExport(lvl: OrgLevel, startDate: Date, endDate: Date): void {
    this.orgLevel = lvl;
    this.startDate = startDate;
    this.endDate = endDate;
    this.onLoadStatusChanged(true);
    this.api.getPayrollExport(this.orgLevel.id, startDate, endDate)
      .then((data: PayrollExportContainer) => {
        this.data = data;
        this.onLoaded(data);
        this.onLoadStatusChanged(false);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public releaseToPayroll(groupName: string): void {
    this.onLoadStatusChanged(true);
    this.api.releaseToPayroll(this.orgLevel.id, groupName, this.startDate, this.endDate)
      .then((x: any) => {
        this.loadPayrollExport(this.orgLevel, this.startDate, this.endDate);
        this.notificationsService.success(`${appMessages.success.releasePayrollExport.title}`, appMessages.success.releasePayrollExport.message);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public cancelRelease(groupName: string): void {
    this.onLoadStatusChanged(true);
    this.api.cancelRelease(this.orgLevel.id, groupName, this.startDate, this.endDate)
      .then((x: any) => {
        this.loadPayrollExport(this.orgLevel, this.startDate, this.endDate);
        this.notificationsService.success(`${appMessages.success.cancelPayrollExport.title}`, appMessages.success.cancelPayrollExport.message);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }
}
