import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator } from '@angular/forms';
import { requireDecimalMultiValidator } from './slx-require-decimal-multi.validator';
const DECIMAL_MULTI_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxRequireDecimalValidator),
  multi: true,
};

@Directive({
  selector: '[slxDecimalMulti][formControlName],[slxDecimalMulti][formControl],[slxDecimalMulti][ngModel]',
  providers: [DECIMAL_MULTI_VALIDATOR],
})
export class SlxRequireDecimalValidator implements Validator {
  public validate(c: AbstractControl): { [key: string]: any } {
    const validator = requireDecimalMultiValidator();

    const result = validator(c);
    return result;
  }
}
