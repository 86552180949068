export class PbjNewExportOptions {
  public title: string;
  public showConfigWarning: boolean;
  public IsPBJExportCorpLevelEnabled: boolean;
  constructor(title: string, showConfigWarning: boolean, IsPBJExportCorpLevelEnabled: boolean) {
    this.title = title;
    this.showConfigWarning = showConfigWarning;
    this.IsPBJExportCorpLevelEnabled = IsPBJExportCorpLevelEnabled;
  }
}
