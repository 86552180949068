import * as tslib_1 from "tslib";
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { mutableSelect } from '../../../core/decorators/index';
import { JwtService } from '../jwt/jwt.service';
import { Assert } from '../../../framework/index';
import { NgRedux } from '@angular-redux/store';
var SessionService = /** @class */ (function () {
    function SessionService(jwtService, ngRedux) {
        var _this = this;
        Assert.isNotNull(jwtService, 'jwtService');
        this.jwtService = jwtService;
        this.onTokenRenewed = new Subject();
        this.onTokenRemoved = new Subject();
        //we cant use @mutableSelect this because NgRedux.instance not yet initialized
        ngRedux.select('session').subscribe(function (session) {
            _this.session = session.toJS();
            if (_this.session && _this.session.user) {
                _this.lastUser = _this.getUser();
                _this.lastAlias = _this.session.alias;
                _this.isIPAllowed = _this.session.isIPAllowed;
                _this.onTokenRenewed.next();
            }
            else {
                _this.onTokenRemoved.next();
            }
        });
    }
    SessionService.prototype.isExpired = function () {
        if (!this.session || !this.session.token) {
            return true;
        }
        var isTokenExpired = this.jwtService.isTokenExpired(this.session.token, this.session.clientTimeShift);
        return isTokenExpired;
    };
    SessionService.prototype.getToken = function () {
        if (!this.session.token) {
            return '';
        }
        return this.session.token;
    };
    SessionService.prototype.getAlias = function () {
        if (!this.session.alias) {
            return '';
        }
        return this.session.alias;
    };
    SessionService.prototype.getUser = function () {
        if (!this.session.user) {
            return null;
        }
        return this.session.user;
    };
    SessionService.prototype.isIpAddressValid = function () {
        if (!this.session.isIPAllowed) {
            return false;
        }
        return this.session.isIPAllowed;
    };
    SessionService.prototype.getTokenExpirationDate = function () {
        return this.jwtService.getTokenExpirationDate(this.session.token, undefined, this.session.clientTimeShift);
    };
    tslib_1.__decorate([
        mutableSelect('session'),
        tslib_1.__metadata("design:type", Observable)
    ], SessionService.prototype, "session$", void 0);
    return SessionService;
}());
export { SessionService };
