import * as tslib_1 from "tslib";
import { LookupEntity } from './lookup-entity';
var PbjPosition = /** @class */ (function (_super) {
    tslib_1.__extends(PbjPosition, _super);
    function PbjPosition() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PbjPosition.prototype, "title", {
        get: function () {
            return this.name + " (" + this.govermentCode + ")";
        },
        enumerable: true,
        configurable: true
    });
    return PbjPosition;
}(LookupEntity));
export { PbjPosition };
