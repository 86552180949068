export interface IScheduleDailyDifferences {
  workDate: string;
  orgLevelId: number;
  positionId: number;
  positionName: string;
  shiftGroupId: number;
  shiftGroupName: string;
  shiftId: number;
  shiftName: string;
  unitId: number;
  unitName: string;
  requiredCount: number;
  requiredHours: number;
  scheduledCount: number;
  scheduledHours: number;
  differencesCount: number;
  differencesHours: number;
}

export class ScheduleDailyDifferences {
  public workDate: Date;
  public orgLevelId: number;
  public positionId: number;
  public positionName: string;
  public shiftGroupId: number;
  public shiftGroupName: string;
  public shiftId: number;
  public shiftName: string;
  public unitId: number;
  public unitName: string;
  public requiredCount: number;
  public requiredHours: number;
  public scheduledCount: number;
  public scheduledHours: number;
  public differencesCount: number;
  public differencesHours: number;
}
