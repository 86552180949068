import * as _ from 'lodash';
var AutoSchedulingConfig = /** @class */ (function () {
    function AutoSchedulingConfig(config) {
        this.daysBeforeGenerating = config.daysBeforeGenerating || 0;
        this.daysBeforePosting = config.daysBeforePosting || 0;
        this.enabled = config.enabled || false;
    }
    Object.defineProperty(AutoSchedulingConfig.prototype, "hasConfiguredGeneratingDate", {
        get: function () {
            return _.size(this.generatingDate) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoSchedulingConfig.prototype, "hasConfiguredPostingDate", {
        get: function () {
            return _.size(this.postingDate) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoSchedulingConfig.prototype, "hasConfiguredDates", {
        get: function () {
            return this.hasConfiguredGeneratingDate && this.hasConfiguredPostingDate;
        },
        enumerable: true,
        configurable: true
    });
    return AutoSchedulingConfig;
}());
export { AutoSchedulingConfig };
