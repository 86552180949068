import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { KendoGridStateHelper } from '../../../../../common';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { PBJExportChildResult } from '../../../models/pbj-export/pbj-export-child-result';
import { PbjExportLogManagementService, PbjExportMapService } from '../../../services';
import { PBJExportParentGrid } from '../../../models/pbj-export/pbj-export-parent-grid';
import { PBJExportChildGrid } from '../../../models/pbj-export/pbj-export-child-grid';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { PBJChildDownload,PBJDownloadExcelPdf  } from '../../../models/pbj-export/pbjChildGridDownload';
import { IPBJExportNotificationMessage, } from '../../../models/pbj-export/pbj-export-notification-message';
import { SessionService } from '../../../../../core/services';
import { PbjSignalrService } from '../../../../../portal/services/pbj-signalr/pbj-signalr.service';

@Component({
  selector: 'slx-pbj-export-grid-child',
  templateUrl: './pbj-export-grid-child.component.html',
  styleUrls: ['./pbj-export-grid-child.component.scss']
})
export class PbjExportGridChildComponent implements OnInit {
@Input() childRequestObj: PBJExportParentGrid;
@Input() orgLevelId:number;
  public gridState: KendoGridStateHelper<PBJExportChildResult>;
  public records: PBJExportChildResult[]=[];
  public mySelection: any = [];
  public gridView!: GridDataResult;
  public data = [
    {
    text: "Download Excel",
    value:"excel"
    },
    {
      text: "Download PDF",
      value:"pdf"
    },
    {
      text: "Download XML",
      value:"xml"
    }
  ];
  isAllSelected:boolean = false;
  isLoading: boolean=false;

  constructor(private managementService: PbjExportLogManagementService, private changeDetector: ChangeDetectorRef, private sessionService: SessionService, private mapService:PbjExportMapService, private pbjSignalRService: PbjSignalrService) {
    this.gridState = new KendoGridStateHelper<PBJExportChildResult>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'exportID', dir: 'desc' }];
    this.managementService.sort = this.gridState.state.sort;
    this.gridState.state.filter = null;
    this.gridState.state.group = [];
    this.pbjSignalRService.pbjExportRealtimeFeedback$.subscribe((args: any[])=>{
      let childRealTimeUpdate: IPBJExportNotificationMessage = args && args.length > 2 ? JSON.parse(args[2]) : null;
      if(this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase()){
        this.updateChildProgressBar(childRealTimeUpdate);
      }
    })
  }

  ngOnInit() {
    this.getGridRecords();
  }

  public updateChildProgressBar(exportChildItem:IPBJExportNotificationMessage){
    this.records.map((item:PBJExportChildResult) =>{
      if(item.exportID === exportChildItem.ExportId){
        item.exportProgress= exportChildItem.ExportIdProgress
        item.exportStatus = exportChildItem.ExportIdStatus == 'SUCCESS' ? 'Completed' : exportChildItem.ExportIdStatus;
        if(exportChildItem.ExportIdProcessEndDate != null){
          item.processEndDate = exportChildItem.ExportIdProcessEndDate;
          item.processingTime = this.mapService.getProcessingTime(item.processStartDate, exportChildItem.ExportIdProcessEndDate);;
        }
        if(exportChildItem.ExportIdDetails != null){
          item.details = exportChildItem.ExportIdDetails;
        }
      }
    })
  }

  isCheckboxDisabled(dataItem:PBJExportChildResult){
    if(!(dataItem.exportProgress === 100 && dataItem.exportStatus === 'Completed')){
      return true;
    }
    return false;
  }

  public selectAllItems(checked:boolean): void {
    this.isAllSelected = checked;
    let totalCheckableRecords = this.records
    .map(item => {
      if (item.exportProgress === 100 && item.exportStatus === 'Completed') {
        return item.exportID;
      } else {
        return null;
      }
    })
    .filter(item => item !== null);
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox: HTMLInputElement) => {
        if (!checkbox.disabled && totalCheckableRecords.includes(parseInt(checkbox.id))) {
          checkbox.checked = checked;
        }
      });
    if(checked){
      checkboxes.forEach((checkbox: HTMLInputElement) => {
        if (checkbox.checked && totalCheckableRecords.includes(parseInt(checkbox.id))) {
          if(this.mySelection.includes(parseInt(checkbox.id))){
            return
          }else{
            this.mySelection.push(parseInt(checkbox.id))
          }
        }
      });
    }else{
      this.mySelection = [];
    }
  }

  public selectItem(dataItem: any, checked: boolean): void {
    let totalCheckable = this.records
    .map(item => {
      if (item.exportProgress === 100 && item.exportStatus === 'Completed') {
        return item.exportID;
      } else {
        return null;
      }
    })
    .filter(item => item !== null);

    if(checked){
      if(this.mySelection.includes(dataItem.exportID)){
        return;
      }else{
        this.mySelection.push(dataItem.exportID)
      }
    }else{
      const index = this.mySelection.indexOf(dataItem.exportID);
      if (index !== -1) {
        this.mySelection.splice(index, 1);
      }
    if(totalCheckable.length != this.mySelection.length){
      this.isAllSelected = false;
    }
    }
    if(totalCheckable.length == this.mySelection.length){
      this.isAllSelected = true;
    }
  }

  getGridRecords(){
  this.isLoading=true;
    let reqObj= new PBJExportChildGrid();
    reqObj.batchID= this.childRequestObj.batchId;
    reqObj.orgLevelId= this.orgLevelId;
    reqObj.startDate = this.childRequestObj.startDate;
    reqObj.endDate = this.childRequestObj.endDate;
    this.managementService.getChildExportData(reqObj).then((val: PBJExportChildResult[]) => {
      this.records = val;
      this.isLoading = false;
     this.refreshGrid();
    }).catch((reason: any) => {
      this.managementService.onError(reason);
      this.isLoading = false;
    });
    this.mySelection = this.records.filter((id: any) =>
      this.gridView.data.find((item) => item['exportID'] === id)
    );
  }


  downloadFiles(item:any) {
    let fileType = item.value.toLowerCase();
    if (fileType === 'xml'){
    let exportObj= new PBJChildDownload();
    exportObj.exportIds= this.mySelection.toString(),
    exportObj.batchId = this.childRequestObj.batchId,
    exportObj.orgLevelId= this.orgLevelId;
    this.managementService.downloadChildExportData(exportObj)
  }
  else if(fileType === 'pdf' || fileType === 'excel'){
    this.pbjSignalRService.isPDFDownloadInitiated$.next(true);
    let pdfExcelObj = new PBJDownloadExcelPdf();
    pdfExcelObj.type = fileType;
    pdfExcelObj.exportId= this.mySelection.toString();
    pdfExcelObj.batchId = this.childRequestObj.batchId;
    pdfExcelObj.alias=null;
    pdfExcelObj.userId=null;
    pdfExcelObj.orgLevelId=this.orgLevelId;
    this.managementService.downloadExcelPDF(pdfExcelObj)
  }
}


  get isChecked(){
    return this.mySelection.length>0;
  }

  public filterChangeHandler(event: any): void {
    this.gridState.state.filter = event;
    this.refreshGrid();
  }


  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
   }


  public refreshGrid(){
    if (this.records === undefined || this.records.length == 0) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox: HTMLInputElement) => {
          checkbox.checked = false;
      });
      this.mySelection =[];
  }
}
