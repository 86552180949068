<div class="filter-container">
  <div class="filter-content">
  <div class="col-sm-4 filter-options">
    <div class="row">
      <div class="col-xs-12">
        Filter results
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-auto-height">
          <slx-dropdown-multiselect slx-input [lookup]="positionLookup" orderLookup="asc" placeholder="Position" name="positionFilter"
            [(ngModel)]="positionsFilter">
          </slx-dropdown-multiselect>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-auto-height">
          <slx-dropdown-multiselect slx-input [lookup]="shiftLookup" placeholder="Shift" name="shiftFilter" [(ngModel)]="shiftsFilter">
          </slx-dropdown-multiselect>
        </slx-input-decorator>
      </div>
    </div>
  </div>

  <div class="col-sm-4 filter-options">
    <div class="row">
      <div class="col-xs-12">
        Timecards to display
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="empValid" fieldName="empValid" caption="Valid Timecards" [(ngModel)]="filter.empValid">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="empNoPunches" fieldName="empNoPunches" caption="No Punches" [(ngModel)]="filter.empNoPunches">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="empMissingPunches" fieldName="empMissingPunches" caption="Missing Punches" [(ngModel)]="filter.empMissingPunches">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="schedule" fieldName="empInvalidPunches" caption="Invalid Punches" [(ngModel)]="filter.empInvalidPunches">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="empScheduleOnly" fieldName="empScheduleOnly" caption="Scheduled, No Show" [(ngModel)]="filter.empScheduleOnly">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="empRequest" fieldName="empRequest" caption="Punch Request" [(ngModel)]="filter.empRequest">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="invalidLogin" fieldName="invalidLogin" caption="Invalid Login" [(ngModel)]="filter.invalidLogin">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
    </div>
  </div>

  <div class="col-sm-4 filter-options">
    <div class="row">
      <div class="col-xs-12">
        Events to display
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="empPunch" fieldName="empPunch" caption="Valid Punch" [(ngModel)]="filter.eventFilter.empPunch">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="editPunch" fieldName="editPunch" caption="Punch Edit" [(ngModel)]="filter.eventFilter.editPunch">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="essRequest" fieldName="essRequest" caption="Punch Request" [(ngModel)]="filter.eventFilter.essRequest">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>

      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="invalidPunch" fieldName="invalidPunch" caption="Invalid Punch" [(ngModel)]="filter.eventFilter.invalidPunch">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="schedule" fieldName="schedule" caption="Schedule" [(ngModel)]="filter.eventFilter.schedule">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>  
      <div class="col-xs-12">
        <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
          <slx-checkbox-input slx-input name="invalidLogin" fieldName="invalidLogin" caption="Invalid Login" 		[(ngModel)]="filter.eventFilter.invalidLogin">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>        
    </div>
  </div>
  </div>
  <div class="buttons-container">
    <button class="filters-apply-button theme-button-apply margin-r" type="button" (click)="onFiltersChanged()">Apply</button>
    <button class="filters-cancel-button theme-button-cancel" type="button" (click)="onClose()">Close</button>
  </div>
</div>
