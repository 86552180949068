import { AttachmentDefinition } from './attachment-definition';
import { ReadFile } from '../../../organization/models';

export class FileModel {
    public readonly isAttachment: boolean;
    public readonly currentUserName: string;
    constructor (public sourceItem: AttachmentDefinition | ReadFile, currentUserName: string = null) {
        this.isAttachment = this.sourceItem instanceof AttachmentDefinition;
        this.currentUserName = currentUserName;
    }

    public get id(): number {
        return this.isAttachment ? (this.sourceItem as AttachmentDefinition).id : null;
    }

    public get name(): string {
        return this.sourceItem.fileName;
    }

    public get addedBy (): string {
        return this.isAttachment ? (this.sourceItem as AttachmentDefinition).addedBy : this.currentUserName;
    }
}