import * as _ from 'lodash';

import { IColumnSettings } from '../../../core/models/index';
import { AcaMeasurementActions } from './aca-measurement-actions';

export class AcaMeasurementSettings {
  public columns: AcaMeasurementColumnsSettings;
}

export class AcaMeasurementColumnsSettings {
  public columnsMap: StringMap<IColumnSettings>;
  public columns: IColumnSettings[];
  public createColumns(actions: AcaMeasurementActions): void {
    this.columns = [
      {
        name: 'organizationName',
        description: 'Organization',
        displayed: false
      },
      {
        name: 'departmentName',
        description: 'Department',
        displayed: false
      },
      {
        name: 'positionName',
        description: 'Position',
        displayed: false
      },
      {
        name: 'dateHired',
        description: 'Hire Date',
        displayed: false
      },
      {
        name: 'dateTerm',
        description: 'Term Date',
        displayed: false
      },
      {
        name: 'empType',
        description: 'Emp Type',
        displayed: false
      },
      {
        name: 'measurementPeriodStart',
        description: 'MP Start',
        displayed: false
      },
      {
        name: 'measurementPeriodEnd',
        description: 'MP End',
        displayed: false
      },
      {
        name: 'weeklyAvgHours',
        description: 'Weekly Avg Hours',
        displayed: true
      },
      {
        name: 'administrativePeriodStart',
        description: 'AP Start',
        displayed: false
      },
      {
        name: 'administrativePeriodEnd',
        description: 'AP End',
        displayed: false
      },
      {
        name: 'stabilityPeriodStart',
        description: 'SP Start',
        displayed: false
      },
      {
        name: 'stabilityPeriodEnd',
        description: 'SP End',
        displayed: false
      },
      {
        name: 'acaDate',
        description: 'ACA Date',
        displayed: false
      },
      {
        name: 'acaType',
        description: 'ACA Type',
        displayed: false
      },
      {
        name: 'acaExclude',
        description: 'ACA Exclude',
        displayed: false
      },
      {
        name: 'benefitDeclined',
        description: 'Benefit Declined',
        displayed: false
      },
      {
        name: 'purchasedMarketplace',
        description: 'Purchased Marketplace',
        displayed: false
      },
      {
        name: 'benefitName',
        description: 'Benefit Name',
        displayed: false
      },
      {
        name: 'benefitStart',
        description: 'Benefit Start',
        displayed: false
      },
      
    ];
    if (actions) {
      if (!actions.organizationName) {
        let item = _.find(this.columns, ['name', 'organizationName']);
          this.columns = _.without(this.columns, item);
      }
      if (!actions.departmentName) {
        let item = _.find(this.columns, ['name', 'departmentName']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.positionName) {
        let item = _.find(this.columns, ['name', 'positionName']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.dateHired) {
        let item = _.find(this.columns, ['name', 'dateHired']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.dateTerm) {
        let item = _.find(this.columns, ['name', 'dateTerm']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.empType) {
        let item = _.find(this.columns, ['name', 'empType']);
        this.columns = _.without(this.columns, item);
      }
      if (!actions.acaRecord) {
        let item = _.find(this.columns, ['name', ['acaDate', 'acaType', 'acaExclude', 'benefitDeclined', 'purchasedMarketplace', 'benefitName','benefitStart']]);
        this.columns = _.without(this.columns, item);
      }

    }
    this.mapColumns();
  }
  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.columns, (column: IColumnSettings) => {
      return column.name;
    });
  }
}
