import { IBenefitDetailsBasic, BenefitDetailsBasic } from './benefit-details-basic';

export interface IBenefitDetailsGroupBasic extends IBenefitDetailsBasic<number, string> {
  type: string;
}

export class BenefitDetailsGroupBasic extends BenefitDetailsBasic<number, string> {
  constructor(
    public id: number = null,
    public name: string = null,
    public title: string = null,
    public type: string = null
  ) {
    super(id, name, title);
  }
}
