var ExceptionColumn = /** @class */ (function () {
    function ExceptionColumn() {
    }
    Object.defineProperty(ExceptionColumn.prototype, "fieldName", {
        get: function () {
            var fieldName = this.exceptionName + this.exceptionId;
            fieldName = fieldName.replace(/\s+/g, '');
            return fieldName;
        },
        enumerable: true,
        configurable: true
    });
    return ExceptionColumn;
}());
export { ExceptionColumn };
