import { ElementRef, SimpleChanges, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Assert } from '../../../framework/assert/assert';
import { dateTimeUtils } from '../../../common/utils/index';
var TimeInputComponent = /** @class */ (function () {
    function TimeInputComponent(elementRef) {
        var _this = this;
        this.format = 'MM/dd/yyyy';
        this.placeholder = 'Time';
        this.onDateChanged = new EventEmitter();
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = '';
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.timeMaskConf = {
            mask: function (value) {
                var numberMatch = value.match(/\d/g);
                var numberLength = numberMatch ? numberMatch.join('').length : 0;
                var patterns = [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /(a|A|p|P)/, /(m|M)/];
                if (numberLength > 1) {
                    if (parseInt(numberMatch[0]) === 1) {
                        patterns.splice(1, 1, /[0-2]/);
                        return patterns;
                    }
                }
                return patterns;
            },
            guide: true,
            keepCharPositions: true
        };
        this.hasFocus = false;
        this.dateChangeHandler = function (e) {
            var m = moment(_this.kendoDatePicker.value());
            _this.calendarDateChanged(m);
        };
    }
    Object.defineProperty(TimeInputComponent.prototype, "showCalendar", {
        get: function () {
            return this.internalShowCalendar;
        },
        set: function (value) {
            this.internalShowCalendar = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "dateToUpdate", {
        set: function (value) {
            // strange bug sometimes string from get value assigned here
            if (value instanceof Date) {
                this.internalDateToUpdate = value;
                this.internalDateToUpdateMoment = moment(value);
            }
            else if (moment.isMoment(value)) {
                this.internalDateToUpdateMoment = value;
                this.internalDateToUpdate = value.toDate();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "minDateLimit", {
        set: function (value) {
            if (value) {
                this.internalMinMoment = moment(value);
            }
            else {
                this.internalMinMoment = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "maxDateLimit", {
        set: function (value) {
            if (value) {
                this.internalMaxMoment = moment(value);
            }
            else {
                this.internalMaxMoment = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "readonly", {
        get: function () {
            return !this.inEdit;
        },
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v ? v : null;
                this.dateToUpdate = v ? this.prepareValue(this.innerValue, this.internalDateToUpdate) : null;
                this.onChangeCallback(this.innerValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    TimeInputComponent.prototype.ngOnInit = function () {
        this.initialized = true;
    };
    TimeInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    TimeInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
    };
    TimeInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    TimeInputComponent.prototype.writeValue = function (v) {
        this.innerValue = v ? v : null;
        this.inputComponent.nativeElement.value = this.innerValue;
        if (this.internalDateToUpdate) {
            this.dateToUpdate = v ? this.prepareValue(this.innerValue, this.internalDateToUpdate) : null;
        }
    };
    TimeInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    TimeInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    TimeInputComponent.prototype.toggleCalendar = function () {
        if (!this.kendoDatePicker) {
            this.createDatePicker();
        }
        this.kendoDatePicker.open();
    };
    TimeInputComponent.prototype.prepareValue = function (time, originalValue) {
        return dateTimeUtils.setTime(originalValue, time, true);
    };
    TimeInputComponent.prototype.calendarDateChanged = function (moment) {
        this.onDateChanged.emit(moment);
    };
    TimeInputComponent.prototype.createDatePicker = function () {
        if (this.$datepickerInput && !this.kendoDatePicker) {
            var kendoDatePickerOptions = this.kendoDatePickerOptions();
            var input = this.$datepickerInput;
            input.kendoDatePicker(kendoDatePickerOptions);
            this.kendoDatePicker = input.data('kendoDatePicker');
            this.kendoDatePicker.close();
        }
    };
    Object.defineProperty(TimeInputComponent.prototype, "$element", {
        get: function () {
            return $(this.elementRef.nativeElement);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "$calendarBtn", {
        get: function () {
            return this.$element.find('.button.calendar');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeInputComponent.prototype, "$datepickerInput", {
        get: function () {
            return this.$element.find('input.date-picker-field');
        },
        enumerable: true,
        configurable: true
    });
    TimeInputComponent.prototype.kendoDatePickerOptions = function () {
        var options = {
            format: this.format,
            change: this.dateChangeHandler
        };
        if (this.internalMinMoment) {
            options.min = this.internalMinMoment.toDate();
        }
        if (this.internalMaxMoment) {
            options.max = this.internalMaxMoment.toDate();
        }
        return options;
    };
    return TimeInputComponent;
}());
export { TimeInputComponent };
