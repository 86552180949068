export class AcaExportReplace1095C {
  public type: number;
  public employeeId: number;
  public employeeIdMasterId: number;
  public employeeName: string;
  public recordId: string;
  public ssn: string;
  public ssnStatus: number;
  public empOrgId: number;
  public companyId: number;
}

export class IAcaExportReplace1095C {
  type: number;
  employeeId: number;
  employeeIdMasterId: number;
  employeeName: string;
  recordId: string;
  ssn: string;
  ssnStatus: number;
  empOrgId: number;
  companyId: number;
}

export class MatchEmp {
  public soureEmpID: number;
  public tragetEmpID: number;
}