/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payroll-export-result-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./payroll-export-result-dialog.component";
import * as i4 from "../../../../common/models/dialog-options";
import * as i5 from "../../../../common/services/modal/modal.service";
import * as i6 from "../../../models/timecards/payroll-export-submit-result";
var styles_PayrollExportResultDialogComponent = [i0.styles];
var RenderType_PayrollExportResultDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PayrollExportResultDialogComponent, data: {} });
export { RenderType_PayrollExportResultDialogComponent as RenderType_PayrollExportResultDialogComponent };
function View_PayrollExportResultDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "header-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Operation can not be performed because there are errors: "]))], null, null); }
function View_PayrollExportResultDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "result-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "error": 0 }), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, (_v.context.$implicit.errorCode > 0)); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.errorMessage; _ck(_v, 5, 0, currVal_1); }); }
export function View_PayrollExportResultDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayrollExportResultDialogComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PayrollExportResultDialogComponent_2)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.result.hasErrors; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.result == null) ? null : _co.result.results); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PayrollExportResultDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-payroll-export-result-dialog", [], null, null, null, View_PayrollExportResultDialogComponent_0, RenderType_PayrollExportResultDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.PayrollExportResultDialogComponent, [i4.DialogOptions, i5.ModalService, i6.PayrollExportSubmitResults], null, null)], null, null); }
var PayrollExportResultDialogComponentNgFactory = i1.ɵccf("slx-payroll-export-result-dialog", i3.PayrollExportResultDialogComponent, View_PayrollExportResultDialogComponent_Host_0, {}, {}, []);
export { PayrollExportResultDialogComponentNgFactory as PayrollExportResultDialogComponentNgFactory };
