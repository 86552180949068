import { Component, Input } from '@angular/core';
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { dataCheck, ICostcentersyncDetails } from '../../../models/wfm-sync';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';

@Component({
  selector: 'slx-wfm-ngp-laborcode-sync',
  templateUrl: './wfm-ngp-laborcode-sync.component.html',
  styleUrls: ['./wfm-ngp-laborcode-sync.component.scss']
})
export class WfmNgpLaborcodeSyncComponent extends BaseSyncComponent<ICostcentersyncDetails> {
  @Input() syncType: string;

  constructor(
    private wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  protected syncDataCore(selectedItems: ICostcentersyncDetails[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.LabourCodeSyncOrg(selectedItems, this.syncType)
    );
  }

  protected getSyncItemKey(item: ICostcentersyncDetails): string {
    return '' + item.id;
  }

  protected async fetchData(): Promise<ICostcentersyncDetails[]> {
    const response = await this.busyService.busy(
      this.wfmSyncService.GetLabourCodeChangeLogs(this.syncType)
    );

    return this.processData(response.data);
  }

  private processData(data: ICostcentersyncDetails[]): ICostcentersyncDetails[] {
    return dataCheck(data);
  }
}
