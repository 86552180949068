<slx-spinner [show]="state.isLoading" class="openShift-spinner">
  <div class="open-shift slx-main-content-indents">
    <div class="l-col">
      <div class="row-cont">
        <div class="row-cont">
          <slx-schedule-cycle-slider class="cycles" [itemsArray]="scheduleCycles" [currentItem]="selectedScheduleCycle" (itemSelected)="onScheduleCycleSelected($event)">
          </slx-schedule-cycle-slider>
        </div>
        <div class="row-cont right-align post-schedule-block">
          <div class="post-btn">
            <button type="button" class="theme-button-apply" [popover]="postSchedulePopover" [disabled]="summaryDetailsSet?.isPosted || !openShiftManagementManagementService.canPostSchedule">Post Schedule</button>
          </div>
          <popover-content #postSchedulePopover
            title="Post Schedule"
            class="popover-content-arrow slx-wide"
            disableClickOutside="true"
            (onShow)="filtersPanel.cancelAndClose();settingsPopover.hide();"
            placement="bottom"
          >
            <slx-post-schedule-form (onCancel)="postSchedulePopover.hide()" (onPost)="postSchedulePopover.hide();postSchedule();"></slx-post-schedule-form>
          </popover-content>
          <button type="button" [popover]="settingsPopover" class="theme-button-apply filter-btn filter">
            <span class="fas fa-cog"></span>
          </button>
          <popover-content #settingsPopover
            title="View Settings"
            class="popover-content-arrow slx-wide"
            disableClickOutside="true"
            (onShow)="filtersPanel.cancelAndClose();postSchedulePopover.hide();"
            placement="bottom"
          >
          <slx-post-schedule-settings-form (onApply)="settingsPopover.hide(); savePostSettings()" (onCancel)="settingsPopover.hide()"></slx-post-schedule-settings-form>
        </popover-content>
          <slx-open-shift-management-filters #filtersPanel (onShow)="settingsPopover.hide();postSchedulePopover.hide();" (filtersChanged)="onFilterChanged($event)" [selectedOrgLevel]="selectedOrgLevel">
          </slx-open-shift-management-filters>
        </div>
      </div>
      <div class="right-message">
        <span *ngIf="!summaryDetailsSet?.isPosted">Schedule has not been posted</span>
        <span *ngIf="summaryDetailsSet?.isPosted">Schedule posted by {{summaryDetailsSet?.postedByUserLogin}} on {{summaryDetailsSet?.postDate | amDateFormat: appConfig.dateTimeFormat}}</span>
        <span *ngIf="!openShiftManagementManagementService.canPostSchedule"> (No rights)</span>
      </div>
      <slx-open-shift-management-schedule-cycle [summaryDetailsSet]="summaryDetailsSet" (onSummaryItemSelected)="onSummaryItemSelected($event)" (isPartnerShiftCountUpdated)="updatePartnerShiftCount($event)"></slx-open-shift-management-schedule-cycle>
    </div>
    <div class="r-col">
      <slx-open-shift-management-daily-details *ngIf="selectedOpenShiftSummary" [details]="selectedOpenShiftSummary"></slx-open-shift-management-daily-details>
      <!-- <div class="info-label" *ngIf="selectedOpenShiftSummary && selectedOpenShiftSummary.shiftCount===0">
          <i class="far fa-5x fa-check-circle"></i>
          <span>Great Job!</span>
          <span>All open shifts have been filled</span>
       </div> -->
       <div class="info-label" *ngIf="!selectedOpenShiftSummary">
         <span>Please select a date</span>
       </div>
    </div>
  </div>
</slx-spinner>
