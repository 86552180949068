import { AbstractControl } from '@angular/forms';

export function ftMultiplicationValueValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const minWeeklyHours = control.get('ftMinWeeklyHours').value;
    const ftCalendarDay = control.get('ftMaxHoursPerCalenderDay').value;
    const ftCalendarWeek = control.get('ftMaxNoOfDaysPerCalenderWeek').value;
    if (minWeeklyHours && ftCalendarDay && ftCalendarWeek) {
        if (ftCalendarDay * ftCalendarWeek < minWeeklyHours)
            return { 'isFtInvalidValue': true };
    }
    return null;
}

export function ptMultiplicationValueValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const minWeeklyHours = control.get('ptMinWeeklyHours').value;
    const ptCalendarDay = control.get('ptMaxHoursPerCalenderDay').value;
    const ptCalendarWeek = control.get('ptMaxNoOfDaysPerCalenderWeek').value;
    if (minWeeklyHours && ptCalendarDay && ptCalendarWeek) {
        if (ptCalendarDay * ptCalendarWeek < minWeeklyHours)
            return { 'isPtInvalidValue': true };
    }
    return null;
}

export function ptMinValueValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const minWeeklyHours = control.get('ptMinWeeklyHours').value;
  const ptMaxWeeklyHours = control.get('ptMaxWeeklyHours').value;
  if ( ptMaxWeeklyHours ) {
      if (ptMaxWeeklyHours < minWeeklyHours)
          return { 'isPtMinValue': true };
          }
  return null;
}
