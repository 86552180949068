import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeviewConfig } from 'ngx-treeview';

@Component({
  selector: 'slx-checkbox-tree-view',
  templateUrl: './checkbox-tree-view.component.html',
  styleUrls: ['./checkbox-tree-view.component.scss']
})
export class CheckboxTreeViewComponent implements OnInit {
  @Input() items: any;
  @Input() config: TreeviewConfig;
  @Output() selectedOrgIds = new EventEmitter();
  constructor() { }
  ngOnInit() {
  }
 public getValues(values){
    this.selectedOrgIds.emit(values)
  }

}

