import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';

import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { LMCommonTab } from '../../lm-common-tab';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-continuous-tab',
  templateUrl: 'lm-continuous-tab.component.html',
  styleUrls: ['lm-continuous-tab.component.scss']
})
export class LMContinuousTabComponent extends LMCommonTab implements OnInit {
  public appConfig: IApplicationConfig = appConfig;

  constructor(private manService: LMCreationAbsenceManagementService) {
    super(manService);
  }

  public ngOnInit(): void {
    super.init();
    this.validateDates();
  }

  public onChangeEstStartDate(date: Date): void {
    super.onChangeEstStartDate(date);
    this.validateDates();
  }

  public onChangeEstEndDate(date: Date): void {
    super.onChangeEstEndDate(date);
    this.validateDates();
  }

  public onChangeEstAllDay(): void {
    super.onChangeEstAllDay();
    this.validateDates();
  }

  public onChangeActStartDate(date: Date): void {
    super.onChangeActStartDate(date);
    this.validateDates();
  }

  public onChangeActEndDate(date: Date): void {
    super.onChangeActEndDate(date);
    this.validateDates();
  }

  public onChangeActAllDay(): void {
    super.onChangeActAllDay();
    this.validateDates();
  }

  public validateDates(): void {
    const loadDates = this.manService.getLoaDates();
    if (_.isDate(loadDates.startDate) && _.isDate(loadDates.endDate)) {
      this.hasIncorrectContinuousDates = !this.manService.hasCorrectContinuousDates;
    }
  }
}
