<section [ngStyle]="{'height': !expanded ? '61px' : '185px'}" class="slx-collapsible-section active"
  [ngClass]="getModeClass()">
  <button (click)="onToggleSection()">
    {{ toggleIconTitle() }}
  </button>
  <h3 [ngStyle]="{'height': !expanded ? '61px' : '185px'}" class="slx-collapsible-section__header active">
    <div>
      <i *ngIf="hasCustomIcon" class="slx-collapsible-section__title-icon" [ngClass]="customIcon"
        aria-hidden="true"></i>
      <span class="customSize" *ngIf="!emptyHeader">{{ title }} : </span>
      <span *ngIf="!expanded" class="slx-collapsible-section__title-counter active">{{ counter }}</span>
      <div *ngIf="expanded" class="expanded-content">
        <span class="custom-count"> {{ counter }}</span>
        <span class="custom-font"> {{ info }}</span>
      </div>
    </div>
    <br />
    <div class="slx-collapsible-section__content active" [ngClass]="{ 'shown': isShown() }">
      <ng-content select="[body]"></ng-content>
    </div>
  </h3>
</section>