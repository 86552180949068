<slx-spinner [show]="state.isLoading||state.isAccessTableLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents roles-header">
      <ng-container *ngIf="!state.isManageRoleMode">
        <div class="roles-header__l-col">
          <i *ngIf="!canSave" aria-hidden="true" class="fas fa-exclamation-triangle warning">
              <span slxTooltip="Editing disabled when more than 5 roles are selected" tipPosition="right"></span>
            </i>
          <button type="button"
            (click)="onSaveChanges()"
            [disabled]="!hasChanges || !canSave"
            class="slx-button slx-with-icon slx-mobile-adapted slx-margin-r"
          >
            <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>
            <span class="slx-button__text">Save Changes</span>
          </button>

          <button type="button"
            (click)="onDicardChanges()"
            [disabled]="!hasChanges"
            class="slx-button slx-with-icon slx-mobile-adapted slx-margin-r"
          >
            <i class="fas fa-ban slx-button__icon" aria-hidden="true"></i>
            <span class="slx-button__text">Discard Changes</span>
          </button>
        </div>
        <div class="roles-header__r-col">
          <button type="button" (click)="onPasswordSettings()" class="slx-button slx-min-w90 slx-margin-r">Password Settings</button>
          <button type="button" (click)="onShowLegend()" class="slx-button slx-min-w90 slx-margin-r">Legend</button>
          <button type="button" (click)="onManagingRoles()" class="slx-button slx-min-w90 slx-margin-r">Manage Roles</button>
          <slx-multiselect class="roles-header__filters"
            [options]="columnsList"
            [(ngModel)]="shownColumns"
            (ngModelChange)="onFiltersChanged($event)"
            placeholder="Roles filter..."
            name="statusFilter"
          ></slx-multiselect>
        </div>
      </ng-container>
      <ng-container *ngIf="state.isManageRoleMode">
        <div class="flex-horizontal">
          <button type="button"
            (click)="onAddDefinition()"
            [disabled]="manageModeHasChanges"
            class="slx-button slx-min-w90"
          >Add Role</button>
          <button type="button"
            (click)="onCloneDefinition()"
            [disabled]="!selectedRole || manageModeHasChanges"
            class="slx-button slx-min-w90"
          >Clone Role</button>
        </div>
        <button type="button" (click)="onManagingAccessTable()" class="slx-button slx-min-w90">Manage Access Table</button>
      </ng-container>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <div *ngIf="!state.isManageRoleMode" class="slx-full-height">
        <slx-roles-access-table-grid></slx-roles-access-table-grid>
      </div>
      <div *ngIf="state.isManageRoleMode" class="slx-full-height">
        <slx-roles-definition-grid></slx-roles-definition-grid>
      </div>
    </div>
  </div>
</slx-spinner>
