import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MomentModule } from 'angular2-moment';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { UploadModule } from '@progress/kendo-angular-upload';

import { NgxPopperModule } from 'ngx-popper';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

import { CommonModule as CommonAppModule } from '../common/common.module';
import { pipes } from './pipes/index';
import { exportComponents, components } from './components/index';
import { services } from './services/index';
import { exportDirectives, directives } from './directives/index';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UploadInterceptor } from './components/file-upload/upload-interceptor';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MomentModule,
    DropDownsModule,
    DateInputsModule,
    InputsModule,
    NgxPopperModule,
    CommonAppModule,
    UploadModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot()
  ],
  declarations: [
    ...components, ...directives, ...pipes, FileUploadComponent
  ],
  providers: [
    ...services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UploadInterceptor,
      multi: true
    }
  ],
  exports: [
    ...exportComponents, ...exportDirectives, ...pipes
  ]
})
export class ComponentsLibraryModule {
}
