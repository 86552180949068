<kendo-grid #kendoGrid [data]="users">

  <kendo-grid-column title="User Name">
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [userProfileLink]="dataItem.userProfile.id">{{dataItem.userProfile.name}}</a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Login Id" field="userProfile.login"></kendo-grid-column>
  <kendo-grid-column title="Email" field="userProfile.email"></kendo-grid-column>
  <kendo-grid-column title="Org Level Description" field="userProfile.orgLevelDescription"></kendo-grid-column>

</kendo-grid>
