import * as _ from 'lodash';
import * as moment from 'moment';
import { CalendarViewTypes } from '../../models/index';
var StaticCalendarComponent = /** @class */ (function () {
    function StaticCalendarComponent() {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.viewType = CalendarViewTypes.month;
        this.getCalendarCellClass = function () { return ''; };
    }
    Object.defineProperty(StaticCalendarComponent.prototype, "calendarCellClass", {
        set: function (value) {
            if (_.isString(value)) {
                this.getCalendarCellClass = function () { return value; };
            }
            if (_.isFunction(value)) {
                this.getCalendarCellClass = function (date) { return value(date); };
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaticCalendarComponent.prototype, "currentDate", {
        get: function () {
            return this.m_currentDate;
        },
        set: function (value) {
            if (_.isDate(value) && !moment(this.m_currentDate).isSame(value)) {
                this.m_currentDate = value;
                this.onChangeCallback(this.m_currentDate);
            }
        },
        enumerable: true,
        configurable: true
    });
    StaticCalendarComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.currentDate = value;
        }
    };
    StaticCalendarComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    StaticCalendarComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    return StaticCalendarComponent;
}());
export { StaticCalendarComponent };
