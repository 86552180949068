export interface IAcuity {
  id: number;
  name: string;
  orgLevelId: number;
}

export class Acuity {
  public id: number;
  public name: string;
  public orgLevelId: number;
}
