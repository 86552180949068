import * as _ from 'lodash';

import { Component, Input, Output, EventEmitter  } from '@angular/core';
import { ActionsListComponent } from './../actions-list.component';
import { PopperContent, PopperController } from 'ngx-popper';
import { PopperPositions } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-actions-button',
  templateUrl: 'actions-button.component.html',
  styleUrls: ['actions-button.component.scss'],
})
export class ActionsButtonComponent {
  @Input()
  public popperContent: PopperContent;
  @Input()
  public popperPosition: PopperPositions = 'bottom-start';
  @Output()
  public popperOnShow: EventEmitter<PopperController> = new EventEmitter<PopperController>();
  @Output()
  public popperOnHide: EventEmitter<PopperController> = new EventEmitter<PopperController>();

  @Input('className')
  public customClassName: string;

  @Input()
  public fullyCustomStyle: boolean = false;
  private escCode: number = 27;
  private cssClasses: string[] = ['slx-actions-button'];

  public onKeyup(event: KeyboardEvent): void {
    if (event.keyCode === this.escCode) {
      this.popperContent.hide();
    }
  }

  public onShown(popper: PopperController): void {
    this.popperOnShow.emit(popper);
  }

  public onHidden(popper: PopperController): void {
    this.popperOnHide.emit(popper);
  }

  public get className(): string {
    const hasClass: boolean = _.isString(this.customClassName) && _.size(this.customClassName) > 0;
    const standartClasses: string = this.fullyCustomStyle ? 'slx-clear-button' : `${this.cssClasses.join(' ')}`;
    if (hasClass) {
      return `${standartClasses} ${this.customClassName}`;
    }

    return standartClasses;
  }
}
