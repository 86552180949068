/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./roles-profile-access-toggler.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./roles-profile-access-toggler.component";
var styles_RolesProfileAccessTogglerComponent = [i0.styles];
var RenderType_RolesProfileAccessTogglerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RolesProfileAccessTogglerComponent, data: {} });
export { RenderType_RolesProfileAccessTogglerComponent as RenderType_RolesProfileAccessTogglerComponent };
export function View_RolesProfileAccessTogglerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "toggler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAccessClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "i", [["aria-hidden", "true"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "far": 0, "fa-check-square": 1, "fa-square": 2, "fa-eye": 3, "fa-eye-slash": 4, "fab fa-delicious": 5, "dirty": 6 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, ((((_co.rowItem.mapByRole[_co.roleId].access === "edit") || (_co.rowItem.mapByRole[_co.roleId].access === "none")) || (_co.rowItem.mapByRole[_co.roleId].access === "view")) || (_co.rowItem.mapByRole[_co.roleId].access === "masked")), (_co.rowItem.mapByRole[_co.roleId].access === "edit"), (_co.rowItem.mapByRole[_co.roleId].access === "none"), (_co.rowItem.mapByRole[_co.roleId].access === "view"), (_co.rowItem.mapByRole[_co.roleId].access === "masked"), (_co.rowItem.mapByRole[_co.roleId].access === "mixed"), _co.rowItem.dirtyByRole[_co.roleId]); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RolesProfileAccessTogglerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-roles-profile-access-toggler", [], null, null, null, View_RolesProfileAccessTogglerComponent_0, RenderType_RolesProfileAccessTogglerComponent)), i1.ɵdid(1, 49152, null, 0, i3.RolesProfileAccessTogglerComponent, [], null, null)], null, null); }
var RolesProfileAccessTogglerComponentNgFactory = i1.ɵccf("slx-roles-profile-access-toggler", i3.RolesProfileAccessTogglerComponent, View_RolesProfileAccessTogglerComponent_Host_0, { rowItem: "rowItem", roleId: "roleId" }, { roleToggled: "roleToggled" }, []);
export { RolesProfileAccessTogglerComponentNgFactory as RolesProfileAccessTogglerComponentNgFactory };
