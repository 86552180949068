import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { EmployeeBannerInfo } from '../../models/index';
import { UserService } from '../../../../core/services/index';
import { EmployeeSelfServiceApiService } from '../../services';
import { Observable } from 'rxjs';
import { OrgLevel } from '../../../../state-model/models/index';
import { mutableSelect } from '../../../../core/decorators';
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-banner',
  templateUrl: 'employee-banner.component.html',
  styleUrls: ['employee-banner.component.scss']
})
export class EmployeeBannerComponent implements OnInit{
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  @Input()
  public canNotEditOwnProfile: boolean;

  @Input()
  public hasEditingSection: boolean;
  @Input()
  public employeeBannerInfo: EmployeeBannerInfo;
  @Output()
  public onTerminate: EventEmitter<any>;
  @Output()
  public onTransfer: EventEmitter<any>;
  @Output()
  public onUndoTransfer: EventEmitter<any>;
  @Output()
  public onRehire: EventEmitter<any>;
  @Output()
  public onCancelTerminate: EventEmitter<any>;
  @Output()
  public onChangeTerminateDate: EventEmitter<any>;
  @Output()
  public onResendTimeclocksEmail: EventEmitter<any>;
  public isNGPUser: boolean = false;
  public isSystemUser: boolean = false;
  public canEditOwnProfile (): boolean {
    if (this.employeeBannerInfo) {
      if (this.userService.isEmployeeLinkedToStoredUser(this.employeeBannerInfo.empoyeeId)) {
        return !this.canNotEditOwnProfile;
      }
    }
    return true;
  }
  public orgLevelId: number;
  public canSeePayroll: boolean = false;
  public orgLevel: OrgLevel;
  private appSettingManageService: AppSettingsManageService;

  constructor(private userService: UserService, 
    private employeeSelfServiceApiService: EmployeeSelfServiceApiService,
    appSettingManageService: AppSettingsManageService,) {
    this.onTerminate = new EventEmitter<any>();
    this.onTransfer = new EventEmitter<any>();
    this.onUndoTransfer = new EventEmitter<any>();
    this.onRehire = new EventEmitter<any>();
    this.onCancelTerminate = new EventEmitter<any>();
    this.onChangeTerminateDate = new EventEmitter<any>();
    this.onResendTimeclocksEmail = new EventEmitter<any>();
    this.appSettingManageService = appSettingManageService;
  }
  ngOnInit(): void {
    this.orgLevel$.subscribe((orgLevel: OrgLevel)=>{
      this.orgLevelId = orgLevel ? orgLevel.id : undefined;
      this.orgLevel = orgLevel;
    })
    this.getNGPFlag(); 
  }
  
  public transfer(): void {
    this.onTransfer.emit(null);
  }

  public undoTransfer (): void {
    this.onUndoTransfer.emit (null);
  }

  public terminate(): void {
    this.onTerminate.emit(null);
  }

  public rehire(): void {
    this.onRehire.emit(null);
  }

  public cancelTerminate (): void {
    this.onCancelTerminate.emit(null);
  }

  public changeTerminateDate (): void {
    this.onChangeTerminateDate.emit(null);
  }

  public resendEmail (): void {
    this.onResendTimeclocksEmail.emit(null);
  }

  public get employeeFullName(): string {
      return this.employeeBannerInfo.fullName;
  }

  public get actionsAvailable(): boolean {
    if (!this.employeeBannerInfo) return false;        
    if(this.employeeBannerInfo.employeeType.name !== 'AFT' && this.employeeBannerInfo.employeeType.name !== 'APT'
    && this.employeeBannerInfo.isPayrollVisible && this.isNGPUser && !this.employeeBannerInfo.isTransferred 
    && !this.employeeBannerInfo.isTerminated && !this.employeeBannerInfo.isAgency)
    {
      this.canSeePayroll = true;
    }
    if (this.employeeBannerInfo.isTerminated) {
      return this.employeeBannerInfo.canRehire || this.employeeBannerInfo.canCancelTerminate; 
    } else {
      return this.employeeBannerInfo.canTerminate || this.employeeBannerInfo.canTransfer;
    }
  }

  public async getNGPFlag() {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
    this.isNGPUser = appServerConfig.IsNextgenPayrollEnabled;
    if(this.isNGPUser){
      this.isSystemUser = this.userService.getUser() && this.userService.getUser().username.toLowerCase() === 'system' ? true : false;
    }
  }

  public activateNGPSSO(){
    //console.log('NGP SSO Window Activate');
    this.employeeSelfServiceApiService.navigateSSO('nextgenpayroll', this.orgLevelId);  
  }
}

