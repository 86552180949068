import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
import { appConfig } from '../../../../../app/app.config';
import * as moment from 'moment';
var EmployeeSectionsPositionHistoryRecord = /** @class */ (function () {
    function EmployeeSectionsPositionHistoryRecord() {
    }
    Object.defineProperty(EmployeeSectionsPositionHistoryRecord.prototype, "startDateFormatForExcel", {
        get: function () {
            return moment(this.archivedDate.fieldValue).format(appConfig.dateFormat);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPositionHistoryRecord.prototype, "endDateFormatForExcel", {
        get: function () {
            if (this.endDate.fieldValue === null || moment(this.endDate.fieldValue).format(appConfig.dateFormat) === '01/01/1900') {
                return null;
            }
            else {
                return moment(this.endDate.fieldValue).format(appConfig.dateFormat);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPositionHistoryRecord.prototype, "verifyCostCenterCalue", {
        get: function () {
            return this.costCenter.fieldValue ? this.costCenter.fieldValue : null;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSectionsPositionHistoryRecord;
}());
export { EmployeeSectionsPositionHistoryRecord };
var EmployeeSectionsPositionHistory = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPositionHistory, _super);
    function EmployeeSectionsPositionHistory() {
        var _this = _super.call(this) || this;
        _this.records = [];
        _this.primaryRecords = [];
        _this.secondaryRecords = [];
        return _this;
    }
    return EmployeeSectionsPositionHistory;
}(EmployeeSectionsBase));
export { EmployeeSectionsPositionHistory };
