import { IPayloadAction } from '../../state-model/models/index';
import { OrgLevelActions } from '../../organization/actions/org-level.actions';
import { IOrgLevel } from './org-level.types';
import { Map } from 'immutable';
import {
  INITIAL_ORG_LEVEL_STATE
} from './org-level.initial-state';

export function orgLevelReducer(state: Map<string, any> = INITIAL_ORG_LEVEL_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case OrgLevelActions.SELECT_ORG_LEVEL_SUCCESS:
      let { orgLevel }: { orgLevel: any } = action.payload;
      let ol: IOrgLevel = {
        id: orgLevel.id,
        name: orgLevel.name,
        type: orgLevel.type,
        sequenceNumber: orgLevel.sequenceNumber,
        relatedItemId: orgLevel.relatedItemId,
        parentId: orgLevel.parentId,
        treeViewPath: orgLevel.treeViewPath,
        treeViewNamePath: orgLevel.treeViewNamePath,
        location: orgLevel.location,
        organizationId: orgLevel.organizationId
      };
      return state.merge(ol);
    case OrgLevelActions.DESELECT_ORG_LEVEL:
      return state.merge(INITIAL_ORG_LEVEL_STATE);
    default:
      return state;
  }
}
