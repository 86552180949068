import { Entity } from '../../../core/models/index';

export class UserApplication extends Entity {
  public name: string;
  public title: string;
  public link: string;

  constructor(id?: number, name?: string) {
    super(id);
    this.name = name;
  }
}
