import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slxPlus',
  pure: false
})
@Injectable()
export class PlusPipe implements PipeTransform {
  public transform(obj: any): string {
    obj = Number(obj);
    if (obj === 0 || obj === null || obj === undefined || isNaN(obj)) return '0';
    if (obj > 0) return '+' + obj;
    return obj.toString();
  }
}
