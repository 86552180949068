import * as moment from 'moment';
import { IPosition, Position, IShift, Shift, IScheduleDefinition, ScheduleDefinition  } from '../../../organization/models/index';


export interface IClosingPunchAttributes {
  interval: number;
  roundedInterval: number;
  position: IPosition;
  schedule: IScheduleDefinition;
  absence: string;
}

export class ClosingPunchAttributes {
  public interval: moment.Duration;
  public roundedInterval: moment.Duration;
  public position: Position;
  public schedule: ScheduleDefinition;
  public absence: string;
}

