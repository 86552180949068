var UnassignedEmployee = /** @class */ (function () {
    function UnassignedEmployee() {
    }
    Object.defineProperty(UnassignedEmployee.prototype, "employeeName", {
        get: function () {
            return this.employee ? this.employee.employee.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnassignedEmployee.prototype, "centerName", {
        get: function () {
            if (this.employee && this.employee.positionDepartment) {
                return this.employee.positionDepartment.name;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UnassignedEmployee.prototype, "positionName", {
        get: function () {
            if (this.employee && this.employee.position) {
                return this.employee.position.name;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    return UnassignedEmployee;
}());
export { UnassignedEmployee };
