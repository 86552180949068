import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const IPV4_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => Ipv4Validator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[ipv4][formControlName],[ipv4][formControl],[ipv4][ngModel]',
  /* tslint:enable */
  providers: [IPV4_VALIDATOR]
})
export class Ipv4Validator implements Validator {
  public validate(c: AbstractControl): { [key: string]: any } {
    if (c.dirty) {
      return CommonValidators.ipv4(c);
    }
    return null;
  }
}
