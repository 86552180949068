import { IdealScheduleConfigurationRecordDTO } from './ideal-schedule-configuration-record-dto';

export class IdealScheduleConfigurationDTO {
  public startDate: string;
  public endDate: string;
  public records: IdealScheduleConfigurationRecordDTO[];
}

export class IdealScheduleShiftGroupDTO{
  public flagStatus:boolean;
  public shiftGroupCount :number;
  public shifts:ShiftDetails[];
}
export class ShiftDetails{
  public shiftId:string;
  public name :string;
  public defaultShift:number;
  public group:string;
  public groupId:number;
  public totalHours:number;
}