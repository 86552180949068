<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <form #atForm="ngForm">
      <div class="col-sm-4">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input name="startDate" [readonly]="true" [ngModel]="req?.date"></slx-date-picker-ngx>
        </slx-input-decorator>
      </div>
      <div class="col-sm-4">
       <h3 class="emp-name">{{req?.employeeName}}</h3>
      </div>
    </form>
    <div class="slx-high-box__body">
      <slx-audit-trail-limited-grid class="base-height" [gridView]="actionList" (gridPageChanged)="gridPageChanged($event)">
        </slx-audit-trail-limited-grid>
    </div>
  </div>
</slx-spinner>
