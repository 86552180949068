import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { ColumnSettingsStorageService, ColumnManagementService, StateManagementService, ComponentStateStorageService } from '../../../../../common/services/index';

import { WcCommonManagementService, WcSummaryManagementService, WcRosterToolbarService } from '../../../services/index';

@Component({
  selector: 'slx-wc-summary',
  templateUrl: './wc-summary.component.html',
  styleUrls: ['./wc-summary.component.scss'],
  providers: [
    WcCommonManagementService,
    WcSummaryManagementService,
    WcRosterToolbarService,
    ColumnManagementService,
    ColumnSettingsStorageService,
    StateManagementService,
    ComponentStateStorageService
  ]
})
export class WcSummaryComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: WcSummaryManagementService) {}

  public ngOnInit() {
    this.manService.init('WcSummaryRoster');

    this.subscriptions.onLoad = this.manService
      .subscribeToLoading((value: boolean) => (this.isLoading = value));
  }

  public ngOnDestroy(): void {
    this.manService.destroy();
  }
}
