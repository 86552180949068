<div #dropdownBtn>
  <kendo-dropdownbutton
    [data]="data"
    [disabled]="disabled"
    [textField]="textField"
    [popupSettings]="{popupClass: 'dropdown-btn-popup', animate: true}"
    (itemClick)="handleClick($event)"
  >
    <div class="slx-dropdownbtn__icon" *ngIf="icon">
      <i [class]="icon" aria-hidden="true"></i>
    </div>
    {{ title }}
    <ng-template kendoDropDownButtonItemTemplate let-dataItem>
      <span
        class="dropdown-btn-item-width"
        [style.min-width.px]="dropdownItemMinWidth"
      >
        {{ valuePrimitive ? dataItem : dataItem[textField] }}
      </span>
    </ng-template>
  </kendo-dropdownbutton>
</div>
