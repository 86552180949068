/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-ip-restriction-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../services/authentication/authentication.service";
import * as i3 from "../../../core/services/token-validity/token-validity.service";
import * as i4 from "../../../core/services/session/session.service";
import * as i5 from "../../services/authentication-api/authentication.api-service";
import * as i6 from "../../actions/session.actions";
import * as i7 from "@angular/router";
import * as i8 from "../../../core/services/logger/logger.service";
import * as i9 from "../../../core/services/user-activity/user-activity.service";
import * as i10 from "../../../common/services/modal/modal.service";
import * as i11 from "../../../core/services/local-storage/local-storage.service";
import * as i12 from "./login-ip-restriction-message.component";
var styles_LoginIpRestrictionMessageComponent = [i0.styles];
var RenderType_LoginIpRestrictionMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginIpRestrictionMessageComponent, data: {} });
export { RenderType_LoginIpRestrictionMessageComponent as RenderType_LoginIpRestrictionMessageComponent };
export function View_LoginIpRestrictionMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "restriction-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "row panels"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "col-md-4 restriction-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [["class", "group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["alt", "Smartlinx"], ["src", "/assets/img/login/Group.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "slx-logo-6-1-blue"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "img", [["alt", "Smartlinx"], ["src", "/assets/img/login/slx-logo-6-1-blue.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "invalidip-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This device's IP Address has been restricted. "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please contact your administrator for support. "])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "theme-button-apply back-button"], ["tabindex", "4"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Go Back "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [["class", "col-md-4"]], null, null, null, null, null))], null, null); }
export function View_LoginIpRestrictionMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-login-ip-restriction-message", [], null, null, null, View_LoginIpRestrictionMessageComponent_0, RenderType_LoginIpRestrictionMessageComponent)), i1.ɵprd(512, null, i2.AuthenticationService, i2.AuthenticationService, [i3.TokenValidityService, i4.SessionService, i5.AuthenticationApiService, i6.SessionActions, i7.Router, i8.LoggerService, i9.UserActivityService, i10.ModalService, i11.LocalStorageService]), i1.ɵdid(2, 49152, null, 0, i12.LoginIpRestrictionMessageComponent, [i2.AuthenticationService, i7.Router], null, null)], null, null); }
var LoginIpRestrictionMessageComponentNgFactory = i1.ɵccf("slx-login-ip-restriction-message", i12.LoginIpRestrictionMessageComponent, View_LoginIpRestrictionMessageComponent_Host_0, {}, {}, []);
export { LoginIpRestrictionMessageComponentNgFactory as LoginIpRestrictionMessageComponentNgFactory };
