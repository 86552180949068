import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { VacationPlanSettings, ShiftReplacementRequest } from '../../../models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { ShiftReplacementOpenComponent } from '../../shift-replacement/shift-replacement-open/shift-replacement-open.component';
import { StateManagementService } from '../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateResetTypes } from '../../../../core/models/settings/index';
var VacationPlannerHeaderComponent = /** @class */ (function () {
    function VacationPlannerHeaderComponent(modalService, stateManagement, storageService) {
        this.modalService = modalService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.m_yearFilterControlId = 'YearFiltersNgx';
        this.m_columnFilterControlId = 'ColumnFiltersNgx';
        this.m_resetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.settingsChanged = new EventEmitter();
        this.currentYear = moment().year();
        this.yearItems = _.range(this.currentYear - 5, this.currentYear + 4, 1);
    }
    VacationPlannerHeaderComponent.prototype.ngOnInit = function () {
        this.stateManagement.init('VacationPlannerHeaderComponent', false);
        this.appConfig = appConfig;
        this.settings = new VacationPlanSettings();
        this.settings.reload = true;
        this.settings.year = this.currentYear;
        this.settings.columns = [
            {
                name: 'position',
                description: 'Position',
                displayed: false,
                sortField: 'position.name'
            },
            {
                name: 'type',
                description: 'Type',
                displayed: false,
                sortField: 'employee.employeeType.name'
            },
            {
                name: 'dateOfHire',
                description: 'Date of Hire',
                displayed: false,
                sortField: 'employee.dateHired'
            },
            {
                name: 'unit',
                description: 'Unit',
                displayed: false,
                sortField: 'unit.name'
            },
            {
                name: 'shift',
                description: 'Shift',
                displayed: false,
                sortField: 'shift.name'
            },
            {
                name: 'group',
                description: 'Group',
                displayed: false,
                sortField: 'position.group'
            },
            {
                name: 'days',
                description: 'Days',
                displayed: false,
                sortField: 'vacationDays'
            },
            {
                name: 'hours',
                description: 'Hours',
                displayed: false,
                sortField: 'vacationHours'
            }
        ];
        this.loadSubscription = this.stateManagement.loadData$.subscribe(function () {
            // this.restoreStates();
        });
        this.restoreStates();
        this.settingsChanged.emit(_.cloneDeep(this.settings));
    };
    VacationPlannerHeaderComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    VacationPlannerHeaderComponent.prototype.onSelectYear = function (year) {
        this.settings.reload = true;
        this.settings.year = year;
        this.saveYearFilterStates();
        this.settingsChanged.emit(_.cloneDeep(this.settings));
    };
    VacationPlannerHeaderComponent.prototype.columnSelected = function () {
        this.settings.reload = false;
        this.saveColumnStates();
        this.settingsChanged.emit(_.cloneDeep(this.settings));
        this.columnsMenuOpened = false;
    };
    VacationPlannerHeaderComponent.prototype.toggleColumnsMenu = function () {
        this.columnsMenuOpened = !this.columnsMenuOpened;
    };
    VacationPlannerHeaderComponent.prototype.selectAll = function (event) {
        _.forEach(this.settings.columns, function (column) {
            column.displayed = true;
        });
    };
    VacationPlannerHeaderComponent.prototype.clearAll = function (event) {
        _.forEach(this.settings.columns, function (column) {
            column.displayed = false;
        });
    };
    VacationPlannerHeaderComponent.prototype.onOpenShift = function () {
        var request = new ShiftReplacementRequest();
        request.shiftId = 11;
        request.positionId = 258;
        request.date = new Date();
        request.showDayOffEmployees = true;
        ShiftReplacementOpenComponent.openDialog(request, 1, this.modalService, function (result) {
            if (result) {
                //open shift api call
            }
        });
    };
    VacationPlannerHeaderComponent.prototype.saveYearFilterStates = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_yearFilterControlId, {
            value: { year: this.settings.year }
        }, this.m_resetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_yearFilterControlId);
    };
    VacationPlannerHeaderComponent.prototype.saveColumnStates = function () {
        var value = {};
        _.forEach(this.settings.columns, function (column) {
            value[column.name] = column.displayed;
        });
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_columnFilterControlId, {
            value: value
        }, this.m_resetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_yearFilterControlId);
    };
    VacationPlannerHeaderComponent.prototype.restoreStates = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_yearFilterControlId);
        if (state && state.value) {
            this.settings.year = state.value.year;
        }
        state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_columnFilterControlId);
        if (state && state.value) {
            _.forEach(this.settings.columns, function (column) {
                column.displayed = state.value[column.name];
            });
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], VacationPlannerHeaderComponent.prototype, "loadSubscription", void 0);
    return VacationPlannerHeaderComponent;
}());
export { VacationPlannerHeaderComponent };
