import { Injectable } from '@angular/core';

@Injectable()
export class EmployeeListCommonService {

  constructor() { }
  isSpecColumn( fieldDef): boolean {
    return !(fieldDef.fieldName === 'EmpName' || fieldDef.fieldName === 'BenefitProvider'
      || fieldDef.fieldName === 'EmpDependentOption' || fieldDef.fieldName === 'BenefitOption' || fieldDef.fieldName === 'OptIn')
    }
}
