var EligibleSupervisor = /** @class */ (function () {
    function EligibleSupervisor() {
        this.selectable = true;
    }
    Object.defineProperty(EligibleSupervisor.prototype, "userName", {
        get: function () {
            var name = '';
            if (this.user && this.user.name) {
                name += this.user.name;
            }
            if (this.user && this.user.login) {
                name += '(' + this.user.login + ')';
            }
            return name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EligibleSupervisor.prototype, "employeeName", {
        get: function () {
            return this.employee ? this.employee.employee.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EligibleSupervisor.prototype, "centerName", {
        get: function () {
            if (this.employee && this.employee.positionDepartment) {
                return this.employee.positionDepartment.name;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EligibleSupervisor.prototype, "positionName", {
        get: function () {
            if (this.employee && this.employee.position) {
                return this.employee.position.name;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    return EligibleSupervisor;
}());
export { EligibleSupervisor };
