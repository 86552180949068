import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LmNavigationService } from '../../../../../common/services/navigation/index';
import { LMManagementService } from '../../../services/index';
import { screenUtils } from '../../../../../common/utils/index';
var LMConsoleToolbarComponent = /** @class */ (function () {
    function LMConsoleToolbarComponent(managementService, activatedRoute, router) {
        this.managementService = managementService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.subscriptions = {};
        this.leaveTypes = managementService.getRequestTypes();
        this.hrNavService = new LmNavigationService(this.router, this.activatedRoute);
    }
    Object.defineProperty(LMConsoleToolbarComponent.prototype, "canAdd", {
        get: function () {
            return this.managementService.canAddEdit;
        },
        enumerable: true,
        configurable: true
    });
    LMConsoleToolbarComponent.prototype.ngOnInit = function () { };
    LMConsoleToolbarComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMConsoleToolbarComponent.prototype.onCreate = function (item) {
        this.managementService.openCreationDialog(item.id);
    };
    LMConsoleToolbarComponent.prototype.onClickConsole = function () {
        this.hrNavService.navigateToLmRoster();
    };
    LMConsoleToolbarComponent.prototype.getLeaveTypeWidth = function () {
        return screenUtils.isMobile ? 30 : 130;
    };
    return LMConsoleToolbarComponent;
}());
export { LMConsoleToolbarComponent };
