export class AuthError {
  public name: string;
  public message: string;
  public stack: string;

  public constructor(message: any) {
    this.name = 'AuthError';
    this.message = message || '';
    this.stack = (new Error()).stack;
  }
}
