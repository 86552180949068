export interface IStepCaseConf {
  name: string;
  description: string;

}
export class StepDefinition implements IStepCaseConf {
  public step: number;
  public name: string;
  public description: string;
}

