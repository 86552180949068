import { Department } from './../../../organization/models/lookup/department';
import { Position } from './../../../organization/models/lookup/position';
import { LookupType, Lookup } from './../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { dateTimeUtils } from './../../../common/utils/dateTimeUtils';
import { ShiftSwapInfo, ShiftSwapProfile, IShiftSwapContainer } from './../../models/shift-swap/shift-swap-info';
import { Injectable } from '@angular/core';
import { IShiftSwapInfo } from '../../models/shift-swap/shift-swap-info';
import * as _ from 'lodash';
import { ShiftSwapStatus } from '../../models/shift-swap/shift-swap-status';
import { ShiftSwapType } from '../../models/shift-swap/shift-swap-type';
import { IShiftSwapDetails, ShiftSwapDetails, IEmployeeShiftSwapDetails, EmployeeShiftSwapDetails } from '../../models/shift-swap/shift-swap-details';
import { DatePipe } from '@angular/common';
@Injectable()
export class ShiftSwapMapService {
    constructor(private lookupService: LookupService) {}

    public mapShiftSwapInfos(dto: IShiftSwapContainer): ShiftSwapInfo[] {
        const list: ShiftSwapInfo[] = _.map(dto.shiftSwaps, (d: IShiftSwapInfo) => {
            const item: ShiftSwapInfo =  this.mapShiftSwapInfo(d);
            item.canApprove = dto.canApprove;
            return item;
        });
        return list;
    }

    public mapShiftSwapInfo(dto: IShiftSwapInfo): ShiftSwapInfo {
        const info: ShiftSwapInfo = new ShiftSwapInfo();
        info.shiftSwapId = dto.shiftSwapId;
        info.createdOn = dateTimeUtils.convertFromDtoString(dto.createdOn);

        info.submittedBy = new ShiftSwapProfile();
        info.submittedBy.id = dto.submittedBy;
        info.submittedBy.hireDate = dateTimeUtils.convertFromDtoString(dto.submittedByHireDate);
        info.submittedBy.name = dto.submittedByFullName;
        info.submittedBy.shiftDate = dateTimeUtils.convertFromDtoString(dto.submittedByShiftDate);
        info.submittedBy.shiftName = dto.submittedByShiftName;
        info.submittedBy.unitName = dto.submittedByUnitName;

        info.submittedTo = new ShiftSwapProfile();
        info.submittedTo.id = dto.submittedTo;
        info.submittedTo.hireDate = dateTimeUtils.convertFromDtoString(dto.submittedToHireDate);
        info.submittedTo.name = dto.submittedToFullName;
        info.submittedTo.shiftDate = dateTimeUtils.convertFromDtoString(dto.submittedToShiftDate);
        info.submittedTo.shiftName = dto.submittedToShiftName;
        info.submittedTo.unitName = dto.submittedToUnitName;

        info.status = dto.status as ShiftSwapStatus;
        info.type = dto.type as ShiftSwapType;
        return info;
    }

    public mapShiftSwapDetails(dto: IShiftSwapDetails): ShiftSwapDetails {
        const details: ShiftSwapDetails = new ShiftSwapDetails();
        details.shiftSwapId = dto.shiftSwapId;
        details.submittedBy = this.mapEmployeeShiftSwapDetails(dto.submittedBy);
        details.submittedTo = this.mapEmployeeShiftSwapDetails(dto.submittedTo);
        return details;
    }

    public mapEmployeeShiftSwapDetails(dto: IEmployeeShiftSwapDetails): EmployeeShiftSwapDetails {
        if (!dto) {
            return null;
        }
        const details: EmployeeShiftSwapDetails = new EmployeeShiftSwapDetails();
        const pipe: DatePipe = new DatePipe('EN-US');
        details.dateHired = pipe.transform(dto.dateHired, 'MMMM dd, yyyy');
        details.id = dto.id;
        details.fullName = dto.fullName;
        details.departmentId = dto.departmentId;
        details.organizationId = dto.organizationId;
        details.homePositionId = dto.homePositionId;
        details.unionStatus = dto.unionStatus;
        details.type = dto.type;
        details.projectedHours = dto.projectedHours;
        details.projectedForOvertime = dto.projectedForOvertime;
        this.setDepartmentName(details);
        return details;
    }

    private setPositionName(details: EmployeeShiftSwapDetails, orgLevelId: number): void {
        details.position = 'Loading...';
        this.lookupService.getLookup({ lookupType: LookupType.position, updateCacheForced: true, orgLevelId: orgLevelId}).then((value: Lookup) => {
            const position: Position = _.find(value.items, (pos: Position) => {
                return pos.id === details.homePositionId;
            });
            if (position) {
                details.position = position.name;
            } else {
                details.position = '';
            }
        });
    }

    private setDepartmentName(details: EmployeeShiftSwapDetails): void {
        details.department = 'Loading...';
        this.lookupService.getLookup({ lookupType: LookupType.department, updateCacheForced: false}).then((value: Lookup) => {
            const department: Department = _.find(value.items, (dep: Department) => {
                return dep.id === details.departmentId;
            });
            if (department) {
                details.department = department.name;
                this.setPositionName(details, department.orgLevelId);
            }
        });
    }
}
