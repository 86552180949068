export enum MasterScheduleGroupingType {
    BYPOSITION = 1,
    BYPOSITIONGROUP = 2
}


export class MasterScheduleGroupingTypes {
    public static ByPosition: IMasterScheduleGroupingType = {
        id: MasterScheduleGroupingType.BYPOSITION,
        name: 'By Position'
    };
    public static ByPositionGroup: IMasterScheduleGroupingType = {
        id: MasterScheduleGroupingType.BYPOSITIONGROUP,
        name: 'By Position Group'
    };
}

export interface IMasterScheduleGroupingType {
    id: number;
    name: string;
}