import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GeolocationManagementService } from '../../services/index';
var GeolocationComponent = /** @class */ (function () {
    function GeolocationComponent(manService) {
        this.manService = manService;
        this.isLoading = true;
        this.formIsOpen = false;
        this.hideFormBeforeOpening = true;
        this.subscriptions = {};
    }
    Object.defineProperty(GeolocationComponent.prototype, "animationCssClass", {
        get: function () {
            return this.hideFormBeforeOpening ? 'c-hidden' : (this.formIsOpen ? 'g-shown' : 'g-hidden');
        },
        enumerable: true,
        configurable: true
    });
    GeolocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init();
        this.subscriptions.buttons = this.manService.subscribeToGeoEvent(function (event) {
            if (event.isAdd) {
                _this.formIsOpen = true;
                _this.hideFormBeforeOpening = false;
            }
            else if (event.isCancel) {
                _this.formIsOpen = false;
            }
        });
        this.subscriptions.entitySelected = this.manService.subscribeToEntitySelected(function (entity) {
            if (_.isObjectLike(entity)) {
                _this.formIsOpen = true;
                _this.hideFormBeforeOpening = false;
            }
        });
        this.subscriptions.cancel = this.manService.subscribeToCloseForm(function () {
            _this.formIsOpen = false;
        });
        this.subscriptions.loading = this.manService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    GeolocationComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    return GeolocationComponent;
}());
export { GeolocationComponent };
