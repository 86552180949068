import { dateTimeUtils } from '../utils/dateTimeUtils';
import * as moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../app.config';

@Pipe({ name: 'slxInterval' })
export class SlxIntervalPipe implements PipeTransform {

  public transform(value: number, type: 'number' | 'hmstring' | 'hminstring' = 'number', precision: number = 2, ): string {
    return this.formatIntervalString(value, type, precision);
  }

  private formatIntervalString(diff: number, type: 'number' | 'hmstring' | 'hminstring', precision: number): string {
    let interval: moment.Duration = moment.duration(diff);
    let intervalString: string;
    if (type === 'hmstring') {
      intervalString = dateTimeUtils.formatDuration(interval);
    } else if(type === 'hminstring') {
      intervalString = dateTimeUtils.formatDuration(interval, true);
    } else {
      intervalString = dateTimeUtils.formatDurationDecimal(interval, precision);
    }
    return intervalString;
  }
}


