import { Component, Provider } from '@angular/core';
import { DialogOptions } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';

@Component({
  moduleId: module.id,
  selector: 'slx-info-dialog',
  templateUrl: 'info-dialog.component.html',
  styleUrls: ['info-dialog.component.scss']
})
export class InfoDialogComponent {

  public options: DialogOptions;
  public dialogResult: boolean;
  private modalService: ModalService;

  public static OpenDialog(title: string,
    message: string, modalService: ModalService): InfoDialogComponent {
    let dialogOptions = new DialogOptions();
    dialogOptions.width = 350;
    dialogOptions.height = 200;
    dialogOptions.message = message;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let dialog: InfoDialogComponent = modalService.globalAnchor
      .openDialog(InfoDialogComponent, title, dialogOptions, resolvedProviders);
    return dialog;
}

  constructor(options: DialogOptions, modalService: ModalService) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
