import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { OrgLevelFlat } from '../../models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { Assert } from '../../../framework/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
var OrgLevelWatchService = /** @class */ (function () {
    function OrgLevelWatchService(appStateBusService) {
        this.appStateBusService = appStateBusService;
        this.orgLevelTreeLoaded$ = new ReplaySubject(1);
        this.refreshOrgLevelTree = new Subject();
        this.orgLevelTreeActualized$ = new Subject();
    }
    OrgLevelWatchService.prototype.reloadOrgTree = function () {
        this.appStateBusService.reloadOrgLevels();
    };
    OrgLevelWatchService.prototype.getTopLevel = function () {
        if (!this.orgLevelTree)
            return null;
        return this.orgLevelTree[0];
    };
    OrgLevelWatchService.prototype.setOrgLevelTree = function (orgLevelTree) {
        this.orgLevelTree = orgLevelTree;
        this.defaultOrgLevel = _.first(orgLevelTree);
        this.flatList = [];
        this.flattenOrgLevel(null, this.orgLevelTree);
        this.orgLevelTreeLoaded$.next(true);
        this.orgLevelTreeActualized$.next(null);
    };
    OrgLevelWatchService.prototype.resetOrgLevelTree = function () {
        this.orgLevelTree = null;
        this.defaultOrgLevel = null;
        this.flatList = [];
    };
    OrgLevelWatchService.prototype.hasOrgLevelTreeLoaded = function () {
        return _.size(this.orgLevelTree) > 0;
    };
    OrgLevelWatchService.prototype.getFlatList = function () {
        return this.flatList;
    };
    OrgLevelWatchService.prototype.getOrgLevelPath = function (orgLevelId) {
        if (!this.orgLevelTree) {
            return [];
        }
        return this.exploreOrgLevelPath(this.orgLevelTree, orgLevelId);
    };
    OrgLevelWatchService.prototype.selectOrgLevel = function (orgLevel) {
        if (!orgLevel)
            return;
        this.onOrgLevelSelected(orgLevel);
    };
    OrgLevelWatchService.prototype.getCurrentOrgLevel = function () {
        return this.currentOrgLevel;
    };
    OrgLevelWatchService.prototype.resetOrgLevel = function () {
        this.currentOrgLevel = null;
    };
    OrgLevelWatchService.prototype.getLocation = function (orgLevel) {
        var _this = this;
        if (!orgLevel || orgLevel.type === OrgLevelType.corporation) {
            return Promise.resolve(null);
        }
        if (orgLevel.type === OrgLevelType.department && !orgLevel.location) {
            this.getOrgLevelByIdSafe(orgLevel.parentId)
                .then(function (parentOrgLevel) {
                return _this.getLocation(parentOrgLevel);
            });
        }
        return Promise.resolve(orgLevel.location);
    };
    OrgLevelWatchService.prototype.getOrgLevelByIdSafeAsync = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getOrgLevelByIdSafe(id)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    OrgLevelWatchService.prototype.getOrgLevelByIdSafe = function (id) {
        var _this = this;
        if (this.orgLevelTree) {
            var orgLevel = this.getOrgLevelById(id);
            return Promise.resolve(orgLevel);
        }
        return this.orgLevelTreeActualized$.map(function (data) { return _this.getOrgLevelById(id); }).first().toPromise();
    };
    OrgLevelWatchService.prototype.getOrgLevelByIdOrDeafault = function (id) {
        var _this = this;
        return this.getOrgLevelByIdSafe(id)
            .then(function (value) {
            if (!value) {
                return _this.defaultOrgLevel;
            }
            return value;
        });
    };
    /**
    * @deprecated since version 6.1 please use getOrgLevelByIdSafe
    */
    OrgLevelWatchService.prototype.getOrgLevelById = function (orgLevelId) {
        var root = _.find(this.flatList, function (o) { return o.orgLevel.id === orgLevelId; });
        return root ? root.orgLevel : null;
    };
    OrgLevelWatchService.prototype.getOrgLevelByRelatedItemId = function (relatedId, orglevelType) {
        var root = _.find(this.flatList, function (o) {
            return o.orgLevel.relatedItemId === relatedId && o.orgLevel.type === orglevelType;
        });
        return root ? root.orgLevel : null;
    };
    OrgLevelWatchService.prototype.getFlatChildOrglevels = function (orgLevelId) {
        var root = _.find(this.flatList, function (o) { return o.orgLevel.id === orgLevelId; });
        var acc = [];
        if (root) {
            this.reduceChilds(acc, root.orgLevel);
        }
        return acc;
    };
    OrgLevelWatchService.prototype.exploreOrgLevelPath = function (childs, orgLevelId) {
        var _this = this;
        var result = [];
        _.forEach(childs, function (orgLevel) {
            if (orgLevel.id === orgLevelId) {
                result.push(orgLevel);
                return;
            }
            else {
                var childResult = _this.exploreOrgLevelPath(orgLevel.childs, orgLevelId);
                if (childResult.length > 0) {
                    result = childResult;
                    result.unshift(orgLevel);
                    return;
                }
            }
        });
        return result;
    };
    OrgLevelWatchService.prototype.onOrgLevelSelected = function (orgLevel) {
        Assert.isNotNull(orgLevel, 'orgLevel');
        this.currentOrgLevel = orgLevel;
    };
    OrgLevelWatchService.prototype.flattenOrgLevel = function (parent, childs) {
        var _this = this;
        _.forEach(childs, function (orgLevel) {
            var flat = new OrgLevelFlat();
            flat.orgLevel = orgLevel;
            flat.parentOrgLevel = parent;
            _this.flatList.push(flat);
            if (orgLevel.childs && orgLevel.childs.length > 0) {
                _this.flattenOrgLevel(orgLevel, orgLevel.childs);
            }
        });
    };
    OrgLevelWatchService.prototype.reduceChilds = function (acc, orgLevel) {
        var _this = this;
        _.forEach(orgLevel.childs, function (o) {
            acc.push(o);
            _this.reduceChilds(acc, o);
        });
    };
    return OrgLevelWatchService;
}());
export { OrgLevelWatchService };
