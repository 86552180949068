import { NavigationMenuItem } from '../../../../organization/models/navigation-menu-item';
import { NavigationMenuRedirectService } from '../../../../organization';
var FavoriteItemComponent = /** @class */ (function () {
    function FavoriteItemComponent(navService) {
        this.navService = navService;
    }
    Object.defineProperty(FavoriteItemComponent.prototype, "icon", {
        get: function () {
            return this.menuItem && this.menuItem.menuItemMapping ? this.menuItem.menuItemMapping.icon : '';
        },
        enumerable: true,
        configurable: true
    });
    FavoriteItemComponent.prototype.open = function () {
        this.navService.open(this.menuItem);
    };
    return FavoriteItemComponent;
}());
export { FavoriteItemComponent };
