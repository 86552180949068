import { TemplateRef } from '@angular/core';
import * as _ from 'lodash';
var ToolbarSectionTemplateDirective = /** @class */ (function () {
    function ToolbarSectionTemplateDirective(templateRef) {
        this.templateRef = templateRef;
        /**Please do not use this deprecated input, instead please use alignMode='right'*/
        //@Input()
        //public alignRight: boolean;
        this.alignMode = 'left';
        this.m_width = 100;
    }
    Object.defineProperty(ToolbarSectionTemplateDirective.prototype, "width", {
        get: function () {
            return this.m_width;
        },
        set: function (value) {
            if (_.isNumber(value)) {
                this.m_width = value;
            }
            else {
                this.m_width = _.toNumber(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    return ToolbarSectionTemplateDirective;
}());
export { ToolbarSectionTemplateDirective };
