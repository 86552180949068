export * from './configure-performance-management.component';
export * from './pm-codes-grid/pm-codes-grid.component';
export * from './pm-templates-grid/pm-templates-grid.component';
export * from './pm-config-toolbar/pm-config-toolbar.component';

import { ConfigurePerformanceManagementComponent } from './configure-performance-management.component';

import { PmCodesGridComponent } from './pm-codes-grid/pm-codes-grid.component';
import { PmTemplatesGridComponent } from './pm-templates-grid/pm-templates-grid.component';
import { PmConfigToolbarComponent } from './pm-config-toolbar/pm-config-toolbar.component';

export const configComponents: any[] = [
    PmCodesGridComponent,
    PmTemplatesGridComponent,
    ConfigurePerformanceManagementComponent,
    PmConfigToolbarComponent
];