import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { IDateRange } from '../../../../../core/models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import { BenefitDependentDroppedEnrollment } from '../../../../../app-modules/benefits/models/index';
var EmployeeDependentDropEnrollmentComponent = /** @class */ (function () {
    function EmployeeDependentDropEnrollmentComponent(manService) {
        this.manService = manService;
        this.dropEnrollment = new EventEmitter();
        this.terminationReasons = [];
        this.terminationReason = null;
        this.eventDate = new Date();
        this.coverageDate = null;
        this.isLoading = true;
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.deendentEnrollment = new BenefitDependentDroppedEnrollment(null, this.eventDate, this.coverageDate, this.terminationReason);
    }
    Object.defineProperty(EmployeeDependentDropEnrollmentComponent.prototype, "options", {
        set: function (v) {
            if (_.isObjectLike(v)) {
                this.restrictions = v;
                this.coverageDate = new Date(v.endDate.getTime());
                this.deendentEnrollment.coverageEndDate = this.coverageDate;
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeDependentDropEnrollmentComponent.prototype.ngOnInit = function () {
        this.loadTerminationReasons();
    };
    EmployeeDependentDropEnrollmentComponent.prototype.ngOnDestroy = function () { };
    EmployeeDependentDropEnrollmentComponent.prototype.onChangedTermReason = function (reason) {
        this.deendentEnrollment.reason = reason;
        this.emitDropEnrollment();
    };
    EmployeeDependentDropEnrollmentComponent.prototype.onChangedEventDate = function (date) {
        this.deendentEnrollment.eventDate = date;
        this.emitDropEnrollment();
    };
    EmployeeDependentDropEnrollmentComponent.prototype.onChangedCovDate = function (date) {
        this.deendentEnrollment.coverageEndDate = date;
        this.emitDropEnrollment();
    };
    EmployeeDependentDropEnrollmentComponent.prototype.loadTerminationReasons = function () {
        var _this = this;
        this.isLoading = true;
        this.manService.getBenefitTerminationReasons()
            .then(function (reasons) {
            _this.terminationReasons = reasons;
            _this.isLoading = false;
        });
    };
    EmployeeDependentDropEnrollmentComponent.prototype.emitDropEnrollment = function () {
        this.dropEnrollment.emit(_.cloneDeep(this.deendentEnrollment));
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeDependentDropEnrollmentComponent.prototype, "subscriptions", void 0);
    return EmployeeDependentDropEnrollmentComponent;
}());
export { EmployeeDependentDropEnrollmentComponent };
