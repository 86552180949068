import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsPayroll = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPayroll, _super);
    function EmployeeSectionsPayroll() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EmployeeSectionsPayroll.prototype.areBenefitsFieldsDirty = function () {
        return this.payrollNumber.isDirty ||
            this.empType.isDirty ||
            this.benefitHours.isDirty ||
            this.ftDate.isDirty ||
            this.lastIncreaseDate.isDirty ||
            this.hireDate.isDirty ||
            this.union.isDirty ||
            this.position.isDirty ||
            this.payPolicy.isDirty ||
            this.shiftDiffPolicy.isDirty ||
            this.payType.isDirty ||
            this.exemptStatus.isDirty ||
            this.payRate.isDirty ||
            this.standardWeeklyHours.isDirty ||
            this.annualSalary.isDirty;
    };
    EmployeeSectionsPayroll.prototype.isOnlyPayRate = function () {
        return this.payRate.isDirty &&
            !this.position.isDirty &&
            !this.exemptStatus.isDirty &&
            !this.payType.isDirty &&
            !this.standardWeeklyHours.isDirty &&
            !this.shiftDiffPolicy.isDirty &&
            !this.payPolicy.isDirty;
    };
    EmployeeSectionsPayroll.prototype.isNotOnlyPayRate = function () {
        return (this.position.isDirty ||
            this.exemptStatus.isDirty ||
            this.payType.isDirty ||
            this.standardWeeklyHours.isDirty ||
            this.shiftDiffPolicy.isDirty ||
            this.payPolicy.isDirty);
    };
    return EmployeeSectionsPayroll;
}(EmployeeSectionsBase));
export { EmployeeSectionsPayroll };
