import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { RolesComponentsModuleRow, RoleColumnsState } from '../../../models/index';
import { RolesManagementService } from '../../../services/index';
var RolesComponentGridComponent = /** @class */ (function () {
    function RolesComponentGridComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.roleMenuChanged = new EventEmitter();
        this.roleColumnsState = new RoleColumnsState();
    }
    RolesComponentGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.filterSubscription = this.managementService.rolesColumnsStateChanged$
            .subscribe(function (state) {
            _this.roleColumnsState = state;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.onChangeNotifySubscription = this.managementService.changeNotify$.subscribe(function (v) {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    RolesComponentGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    RolesComponentGridComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['container']) {
            this.records = this.container.childRows;
            this.refreshGrid();
            if (this.gridState.view) {
                _.times(this.gridState.view.total, function (num) { return _this.grid.expandRow(num); });
            }
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
    };
    RolesComponentGridComponent.prototype.onRoleComponentToggled = function (roleId, row) {
        this.roleMenuChanged.emit(roleId);
        this.managementService.onChangeNotify('onRoleComponentToggled');
    };
    RolesComponentGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesComponentGridComponent.prototype, "onChangeNotifySubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesComponentGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesComponentGridComponent.prototype, "filterSubscription", void 0);
    return RolesComponentGridComponent;
}());
export { RolesComponentGridComponent };
