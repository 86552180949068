import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { ResponseBody, Meta } from '../../../core/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { appConfig } from '../../../app.config';

import { schedulerConfig } from '../../scheduler.config';
import { ScheduleEntry, ScheduleEntryShift, DailyOpenShiftDetailsData,PayPeriodTimeCard} from '../../models/index';
import { IScheduleEntryContainerDto } from '../../models/schedule-entry/dto/index';
import { ScheduleEntryMapService } from './schedule-entry-map.service';
import { dateTimeUtils } from '../../../common/utils/index';
import { DetailsAgencyPosition, IDetailsAgencyPosition } from '../../models/detail-screen/details-agency-position';

@Injectable()
export class ScheduleEntryApiService {
  private apiUtilService: ApiUtilService;
  private scheduleEntryMapService: ScheduleEntryMapService;
  private urlParamsService: UrlParamsService;

  constructor(apiUtilService: ApiUtilService, scheduleEntryMapService: ScheduleEntryMapService, urlParamsService: UrlParamsService, private applicationStateBusService: ApplicationStateBusService) {
    this.apiUtilService = apiUtilService;
    this.scheduleEntryMapService = scheduleEntryMapService;
    this.urlParamsService = urlParamsService;
  }

  public getScheduleEntryRaw(employeeId: number, dateOn: Date): Promise<IScheduleEntryContainerDto> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.entry}/${String(employeeId)}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ dateOn: dateTimeUtils.convertToDtoString(dateOn) })
    });

    let promise: Promise<IScheduleEntryContainerDto> = this.apiUtilService.request<IScheduleEntryContainerDto, Meta>(request)
      .then((response: ResponseBody<IScheduleEntryContainerDto, Meta>) => { return response.data; });
    return promise;
  }


  public getScheduleEntry(employeeId: number, dateOn: Date): Promise<ScheduleEntry> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.entry}/${String(employeeId)}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({ dateOn: dateTimeUtils.convertToDtoString(dateOn) })
    });

    let promise: Promise<ScheduleEntry> = this.apiUtilService.request<IScheduleEntryContainerDto, Meta>(request)
      .then((response: ResponseBody<IScheduleEntryContainerDto, Meta>) => this.scheduleEntryMapService.mapToScheduleEntry(response.data));
    return promise;
  }

  public saveScheduleEntry(scheduleEntry: ScheduleEntry, orgLevelId: number): Promise<any> {

    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${dateTimeUtils.convertToDtoString(scheduleEntry.date)}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.employees.root}/${String(scheduleEntry.employee.id)}`;
    let request: HttpRequest<any> = new HttpRequest('PUT', url, this.scheduleEntryMapService.mapToScheduleShiftDefinitionDtos(scheduleEntry));

    return this.apiUtilService.request<ScheduleEntry, Meta>(request)
      .then((response: ResponseBody<ScheduleEntry, Meta>) => {
        this.applicationStateBusService.resetCache('TimecardsApiServiceCache');
        return response.data
      });
  }

  public saveShiftsEntry(dailyOpenShiftDetailsData: DailyOpenShiftDetailsData[], orgLevelId: number): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevelId}/${schedulerConfig.api.saveSlxPartnerData}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, dailyOpenShiftDetailsData);
    let promise: Promise<DailyOpenShiftDetailsData> = this.apiUtilService.request<DailyOpenShiftDetailsData, Meta>(request)
      .then((response: ResponseBody<DailyOpenShiftDetailsData, Meta>) => response.data);
      return promise;
  }

  public getAgencyPoistionsData(customerId:string, partnerId: string): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`lookup/customer/${customerId}/partner/${partnerId}/${schedulerConfig.api.positions}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<IDetailsAgencyPosition[]> = this.apiUtilService.request<IDetailsAgencyPosition[], Meta>(request)
      .then((response: ResponseBody<IDetailsAgencyPosition[], Meta>) => this.scheduleEntryMapService.mapToPostions(response.data));
      return promise;
  }
  public async checkApprovedPayperiod(empId : String , startDate : String, EndDate : String): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.timecards.timeAndAttendance}/${schedulerConfig.timecards.root}/${schedulerConfig.timecards.approvedPayperiodForIndividual}`;
    let obj = {
      EmpId: empId,
      StartDate: startDate,
      EndDate: EndDate
    }    
    const request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, obj);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
  }
  public async getApprovedPayPeriod(data: PayPeriodTimeCard[]): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.timecards.timeAndAttendance}/${schedulerConfig.timecards.root}/${schedulerConfig.timecards.approvedPayperiod}`;
    const body = data;
    const request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
  }
  public async checkApprovedPayperiodForOrgLevel(orglevel:number,startDate:string,endDate:string ): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.timecards.timeAndAttendance}/${schedulerConfig.timecards.root}/${schedulerConfig.timecards.approvedPayperiodForOrgLevel}`;
    let obj = {
      OrgLevel: orglevel,
      StartDate: startDate,
      EndDate: endDate
    } 
    const body = obj;
    const request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
  }
  
  public async getApprovedPayPeriodForQuickEdit(data: PayPeriodTimeCard[]): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`${schedulerConfig.timecards.timeAndAttendance}/${schedulerConfig.timecards.root}/${schedulerConfig.timecards.approvedPayperiodForQuickEdit}`;
    const body = data;
    const request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
  }
}