<form novalidate #dialogForm="ngForm">
  <div class="modal-content flex-container">
    <div class="modal-body flex-container">
      <div class="user-image" style="float: left;">
          <div class="circular-portrait">
            <img class="rounded-image" [employeeImageSrc]="getEmployeeId()" alt="Employee Photo" />
          </div>
      </div>
      <div>
          <span>Scheduling Cycle:</span><br />
          <strong>{{ getDates() }}</strong>
      </div>
      <div class="btn-group" role="group" class="btn-group-vertical" style="width: 100%;">
        <button *ngIf="!request.isPosted" type="button" (click)="onSelect('GenerateSchedule')" [disabled]="(!request.scheduleActions?.canGenerate || !request.isStartCycleDate)||disableButtons.disableGeneratebtn" class="theme-iconed-button">Generate Schedule</button>
        <button *ngIf="request.isPosted" type="button" [popover]="acceptPopover" [disabled]="!request.scheduleActions?.canGenerate || !request.isStartCycleDate" class="theme-iconed-button">Generate Schedule</button>
        <popover-content #acceptPopover title="This schedule has already been posted, are you sure you want to re-generate?" placement="auto bottom">
          <div class ="popover-container">
            <button type="button" style="width:50px" (click)="onGenerateScheduleAction(acceptPopover, false)" class="theme-button-cancel">No</button>
            <button type="button" style="width:50px" (click)="onGenerateScheduleAction(acceptPopover, true)" class="theme-button-apply">Yes</button>
          </div>
        </popover-content>
        <button type="button" (click)="onSelect('DeleteSchedule')" [disabled]="(!request.scheduleActions?.canDelete || !request.isStartCycleDate) || disableButtons.disableDeletebtn" class="theme-iconed-button">Delete Schedule</button>
        <button type="button" (click)="onSelect('IndividualSchedule')" class="theme-iconed-button">View Individual Schedule</button>
        <button type="button" (click)="toggleCreateRotationFromSchedule()" class="theme-iconed-button" [disabled]=" !request.scheduleActions?.canEditRotations || !request.scheduleActions?.canAccessEmployeeRecord || request.isFromDifferntDepartment " [class.active]="createRotationFromScheduleSection">Create Rotation from Schedule</button>
        <!--
        <button type="button" (click)="onSelect('Generate Schedule')" class="btn btn-default">View Attendance Record</button>
        <button type="button" (click)="onSelect('Generate Schedule')" class="btn btn-default">View Availability</button>
        <button type="button" (click)="onSelect('Generate Schedule')" class="btn btn-default">Schedule Time Off</button>
        <button type="button" (click)="onSelect('Generate Schedule')" class="btn btn-default">Issue Warning</button>
        -->
      </div>

      <div class="rotation-section" *ngIf="createRotationFromScheduleSection">
        <div class="flex-horizontal jc-space-between">
          <div>Rotation Start Date:</div>
          <div>
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                name="rotationScheduleStartDate"
                [(ngModel)]="createRotation.startDate"
                (ngModelChange)="onCreateRotationStartDateChange()"
                [acceptNullDate]="false"
                [required]="true"
                [minDate]="rotationMinDate"
                [maxDate]="rotationMaxDate"
              ></slx-date-picker-ngx>
              <span errorMessage for="required"></span>
              <span errorMessage for="date"></span>
              <span errorMessage for="minDate">Date should be greater or equal than the Cycle Start Date</span>
              <span errorMessage for="maxDate">Date should be less or equal than the Cycle End Date</span>
            </slx-input-decorator>
          </div>
        </div>

        <div class="flex-horizontal jc-space-between">
          <div>Schedule repeats every</div>
          <div>
            <slx-dropdown-list
              class="slx-wide slx-short"
              [options]="rotationWeeks"
              [valueField]="null"
              [titleField]="null"
              [valuePrimitive]="true"
              [(ngModel)]="createRotation.weeks"
              name="rotationScheduleWeeks"
            ></slx-dropdown-list>
          </div>
          <div>week(s)</div>
        </div>
      </div>
    </div>
    <div class="modal-footer flex-horizontal">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Cancel</button>
      <button type="button" [popover]="savePopover" class="theme-button-apply save-button" *ngIf="canSave" [disabled]="!dialogForm.valid">Save</button>
      <popover-content #savePopover [title]="request.hasRotations ? 'This employee has a current rotation. Are you sure you want to override it?' : 'Are you sure?'" placement="auto top">
        <div style="width:110px">
          <button type="button" style="width:50px" (click)="onSaveAction(savePopover, false)" class="theme-button-cancel">No</button>
          <button type="button" style="width:50px" (click)="onSaveAction(savePopover, true)" class="theme-button-apply">Yes</button>
        </div>
      </popover-content>
    </div>
  </div>
</form>
