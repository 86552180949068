import * as _ from 'lodash';
import * as moment from 'moment';
import { ElementRef, OnInit, OnDestroy, Renderer2, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { isAcceptableControlValueAccessor } from '../../../core/models/index';
var DatePagerDirective = /** @class */ (function () {
    function DatePagerDirective(elementRef, renderer) {
        this.styleContainerClass = 'slx-date-pager';
        this.clickHandlers = [];
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.buttonTypes = { left: 'left', right: 'right' };
        this.buttonLeftClass = '';
        this.buttonRightClass = '';
        this.onDateChanged = new EventEmitter();
    }
    Object.defineProperty(DatePagerDirective.prototype, "readonly", {
        set: function (value) {
            this.m_readonly = value;
            this.updateButtons();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePagerDirective.prototype, "pagerMinDate", {
        set: function (value) {
            this.m_minDate = value;
            this.updateButtons();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DatePagerDirective.prototype, "pagerMaxDate", {
        set: function (value) {
            this.m_maxDate = value;
            this.updateButtons();
        },
        enumerable: true,
        configurable: true
    });
    DatePagerDirective.prototype.ngOnInit = function () {
        var container = _.get(this.container, 'elementRef.nativeElement', this.container);
        if (_.isElement(container)) {
            this.buttonLeft = this.createButton(this.buttonTypes.left);
            this.buttonRight = this.createButton(this.buttonTypes.right);
            this.mobileButtonLeft = this.createButton(this.buttonTypes.left, true);
            this.mobileButtonRight = this.createButton(this.buttonTypes.right, true);
            container.classList.add(this.styleContainerClass);
            if (this.styleContainerClass === 'slx-date-pager-vert' || this.styleContainerClass === 'slx-date-pager-date') {
                this.renderer.appendChild(container, this.mobileButtonLeft);
                var holder = this.createButtonsHolder();
                this.renderer.appendChild(holder, this.buttonRight);
                this.renderer.appendChild(holder, this.buttonLeft);
                this.renderer.appendChild(container, holder);
                this.renderer.appendChild(container, this.mobileButtonRight);
            }
            else {
                this.renderer.appendChild(container, this.buttonLeft);
                this.renderer.appendChild(container, this.buttonRight);
            }
        }
        this.updateButtons();
    };
    DatePagerDirective.prototype.ngOnDestroy = function () {
        _.each(this.clickHandlers, function (handler) {
            handler();
        });
    };
    DatePagerDirective.prototype.onModelChange = function (event) {
        this.updateButtons(event);
    };
    DatePagerDirective.prototype.createButtonsHolder = function () {
        var holder = this.renderer.createElement('div');
        this.renderer.addClass(holder, 'date-buttons-holder');
        return holder;
    };
    DatePagerDirective.prototype.createButton = function (type, forMobile) {
        var button = this.renderer.createElement('button');
        var icon = this.renderer.createElement('i');
        var isLeftButton = type === this.buttonTypes.left;
        var buttonClass = isLeftButton ? this.buttonLeftClass : this.buttonRightClass;
        var clickHandler = isLeftButton ? this.onPrevDate.bind(this) : this.onNextDate.bind(this);
        var buttonClasses = ['theme-iconed-accent-button', "slx-date-pager-" + type, buttonClass];
        if (forMobile)
            buttonClasses.push('mobile-show-btn');
        this.addClasses(button, buttonClasses);
        var clickWrapper = this.renderer.listen(button, 'mouseup', clickHandler);
        this.clickHandlers.push(clickWrapper);
        this.renderer.setAttribute(icon, 'title', isLeftButton ? 'Previous day' : 'Next day');
        this.renderer.appendChild(button, icon);
        var iconClasses = ['far', "fa-arrow-alt-circle-" + type];
        this.addClasses(icon, iconClasses);
        this.renderer.setAttribute(icon, 'aria-hidden', 'true');
        this.renderer.setStyle(icon, 'width', '100%');
        this.renderer.setStyle(icon, 'text-align', 'center');
        return button;
    };
    DatePagerDirective.prototype.addClasses = function (node, classes) {
        var _this = this;
        _.forEach(classes, function (className) {
            if (_.isString(className) && className.length > 0) {
                _this.renderer.addClass(node, className);
            }
        });
    };
    DatePagerDirective.prototype.onPrevDate = function (event) {
        var m = moment(this.model.value).subtract(1, 'day');
        var dateOn = m.toDate();
        if (this.m_minDate) {
            if (m.isSameOrAfter(this.m_minDate)) {
                this.changeValue(dateOn);
            }
            else {
                dateOn = moment(this.m_minDate).toDate();
                this.changeValue(dateOn);
            }
        }
        else {
            this.changeValue(dateOn);
        }
    };
    DatePagerDirective.prototype.onNextDate = function (event) {
        var m = moment(this.model.value).add(1, 'day');
        var dateOn = m.toDate();
        if (this.m_maxDate) {
            if (m.isSameOrBefore(this.m_maxDate)) {
                this.changeValue(dateOn);
            }
            else {
                dateOn = moment(this.m_maxDate).toDate();
                this.changeValue(dateOn);
            }
        }
        else {
            this.changeValue(dateOn);
        }
    };
    DatePagerDirective.prototype.changeValue = function (value) {
        var _this = this;
        if (isAcceptableControlValueAccessor(this.model.valueAccessor)) {
            var res = this.model.valueAccessor.changeValue(value);
            this.model.valueAccessor.writeValue(value);
            if (res instanceof Promise) {
                res.then(function (accept) {
                    if (accept) {
                        _this.onDateChanged.emit(value);
                        _this.updateButtons();
                    }
                });
            }
        }
        else {
            this.model.control.setValue(value);
            this.onDateChanged.emit(value);
            this.updateButtons();
        }
    };
    DatePagerDirective.prototype.updateButtons = function (modelValue) {
        if (modelValue === void 0) { modelValue = null; }
        var leftDisabled;
        var rightDisabled;
        if (this.m_readonly) {
            leftDisabled = true;
            rightDisabled = true;
        }
        else {
            if (!this.model.value && modelValue)
                return;
            var dateValue = modelValue ? moment(modelValue) : moment(this.model.value);
            var nextDateValue = moment(dateValue).add(1, 'day');
            var prevDateValue = moment(dateValue).add(-1, 'day');
            if (this.m_minDate) {
                if (moment(this.m_minDate).isAfter(prevDateValue, 'day')) {
                    leftDisabled = true;
                }
                else {
                    leftDisabled = false;
                }
            }
            else {
                leftDisabled = false;
            }
            if (this.m_maxDate) {
                if (moment(this.m_maxDate).isBefore(nextDateValue, 'day')) {
                    rightDisabled = true;
                }
                else {
                    rightDisabled = false;
                }
            }
            else {
                rightDisabled = false;
            }
        }
        if (this.buttonLeft)
            this.buttonLeft.disabled = leftDisabled;
        if (this.mobileButtonLeft)
            this.mobileButtonLeft.disabled = leftDisabled;
        if (this.buttonRight)
            this.buttonRight.disabled = rightDisabled;
        if (this.mobileButtonRight)
            this.mobileButtonRight.disabled = rightDisabled;
    };
    return DatePagerDirective;
}());
export { DatePagerDirective };
