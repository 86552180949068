import { ElementRef, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import * as createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var PERCENT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return PercentInputComponent; }),
    multi: true
};
var PercentInputComponent = /** @class */ (function () {
    function PercentInputComponent(elementRef) {
        this.internalValue = '';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.hasFocus = false;
        this.percentMask = {
            mask: createNumberMask.default({
                prefix: '',
                suffix: '%',
                integerLimit: 100
            }),
            guide: false,
            keepCharPositions: true
        };
    }
    Object.defineProperty(PercentInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PercentInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    PercentInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
        this.onTouchedCallback();
    };
    PercentInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    PercentInputComponent.prototype.writeValue = function (v) {
        var value = +v;
        //TODO:
        //https://smartlinx-legacy.visualstudio.com/slx-worklinx-v6/_git/slx-worklinx-v6-app/pullrequest/3629?_a=overview
        this.internalValue = _.isNumber(value) ? value + "%" : '';
    };
    PercentInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    PercentInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    PercentInputComponent.prototype.valueChanged = function (value) {
        this.onChangeCallback(+value.replace(/\%$/g, ''));
    };
    return PercentInputComponent;
}());
export { PercentInputComponent };
