import { Injectable } from '@angular/core';
import { appConfig } from '../../../app.config';
import { authenticationConfig } from '../../authentication.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { UserExtendedSettingsMapService } from './user-extended-settings-map.service';
import { ApiUtilService } from '../../../common/services/api/api-util.service';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';
import { UserExtendedSettings } from '../../models/index';
import { HttpRequest } from '@angular/common/http';
import { CacheType } from '../../../common/models/cache/cache-definition';


@Injectable()
export class UserExtendedSettingsApiService {

  constructor(private apiService: ApiUtilService, private urlParamsService: UrlParamsService, private mapService: UserExtendedSettingsMapService) {
  }
  
  public getUserSettings(): Promise<UserExtendedSettings> {

    let url: string = `${this.apiService.getApiRoot()}/${authenticationConfig.api.userExtendedSetting}`;
    const request = this.urlParamsService.createGetRequest(url);
    return this.apiService.request<UserExtendedSettings, Meta>(request)
          .then((response: ResponseBody<UserExtendedSettings, Meta>) => {
            return  this.mapService.mapUserSettings(response.data);
          });
        
  }

}