
import { Component, Input, Output } from '@angular/core';
import { AbsenceStatItem } from '../../../../models/employee-sections-performance/employee-sections-attendance';

import { ColorUtil } from '../../../../../../common/utils/index';

import * as moment from 'moment';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-attendance-stats-grid',
  templateUrl: 'employee-sections-attendance-stats-grid.component.html',
  styleUrls: ['employee-sections-attendance-stats-grid.component.scss']
})
export class EmployeeSectionsAttendanceStatsGridComponent {
    @Input()
    public gridView: AbsenceStatItem[];

    @Input()
    public showActual: boolean;

    public firstSymbol(str: string): string {
      return str ? str.charAt(0) : '';
    }
}
