<div class="k-window-titlebar k-header">
  <span class="k-window-title">Call Log</span>
  <div class="k-window-actions">
    <a role="button" href="#" class="k-button k-bare k-button-icon k-window-action" aria-label="Close" (click)="onCloseClick($event)"><span class="k-icon k-i-close"></span></a>
  </div>
</div>
<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <form novalidate>
      <div class="employee-list-actions">
        <slx-date-range-ngx
          name="startEndDate"
          [startDate]="state.startDate"
          [endDate]="state.endDate"
          (rangeDateChanged)="onDataRangeChange($event)"
        ></slx-date-range-ngx>
      </div>
    </form>
    <div class="slx-high-box__body">
      <slx-employee-call-log-grid [pageSize]="pageSize" [employeeLink]="employeeLink" class="height-100"></slx-employee-call-log-grid>
    </div>
  </div>
</slx-spinner>
