var OrgLevelFlat = /** @class */ (function () {
    function OrgLevelFlat() {
    }
    Object.defineProperty(OrgLevelFlat.prototype, "parentName", {
        get: function () {
            if (!this.parentOrgLevel) {
                return '';
            }
            return this.parentOrgLevel.name;
        },
        enumerable: true,
        configurable: true
    });
    return OrgLevelFlat;
}());
export { OrgLevelFlat };
