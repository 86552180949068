import { IPayloadAction } from '../../../../state-model/models/index';
import { AddEmployeeWizardActions } from './add-employee-wizard.actions';
import { IAddEmployeeWizardStateRecord, AddEmployeeWizardStatus } from './add-employee-wizard.types';
import {
  INITIAL_ADD_EMPLOYEE_WIZARD_STATE, INITIAL_ADD_EMPLOYEE_MODEL_STATE
} from './add-employee-wizard.initial-state';
import { Map } from 'immutable';
import { makeTypedMapFactory } from '../../../../framework/immutable-utils/index';

function addEmployeModelStateReducer(state: Map<string, any> = INITIAL_ADD_EMPLOYEE_MODEL_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case AddEmployeeWizardActions.START_WIZARD:
    case AddEmployeeWizardActions.STEP_CHANGE:
    case AddEmployeeWizardActions.ADD_NEW_EMPLOYEE:
      return state.merge(action.payload);
    default:
      return state;
  }
}

export function addEmployeWizardStateReducer(state: Map<string, any> = INITIAL_ADD_EMPLOYEE_WIZARD_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case AddEmployeeWizardActions.START_WIZARD:
      let model: any = state.get('model');
      let status: any = state.get('status');
      return state.merge({
        model: addEmployeModelStateReducer(model, { type: action.type, payload: status === AddEmployeeWizardStatus.success ? INITIAL_ADD_EMPLOYEE_MODEL_STATE : action.payload }),
        status: AddEmployeeWizardStatus.started,
        newEmpId: 0
      });
    case AddEmployeeWizardActions.CLEAR_WIZARD:
      return state.merge({
        model: addEmployeModelStateReducer(INITIAL_ADD_EMPLOYEE_MODEL_STATE, { type: action.type, payload: INITIAL_ADD_EMPLOYEE_MODEL_STATE }),
        status: AddEmployeeWizardStatus.started,
        newEmpId: 0
      });
    case AddEmployeeWizardActions.STEP_CHANGE:
      return state.merge({
        model: action.payload
      });
    case AddEmployeeWizardActions.ADD_NEW_EMPLOYEE:
      return state.merge({
        status: AddEmployeeWizardStatus.pending,
        model: action.payload
      });
    case AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR:
      return state.merge({
        status: action.payload.status,
        newEmpId: 0
      });
    case AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS:
      return state.merge({
        status: action.payload.status,
        newEmpId: action.payload.newEmpId,
        payrollNumber: action.payload.payrollNumber,
        model: action.payload.model
      });
    case AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_VALIDATION_ERROR:
      return state.merge({
        status: action.payload.status
      });
    default:
      return state;
  }
}
