import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { ConfirmDialog3Component, ConfirmOptions3 } from '../../../../common/components/confirm-dialog3/confirm-dialog3.component';
import { ModalService } from '../../../../common/index';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { StateResetTypes } from '../../../../core/models/settings/index';
import { employeeListConfig } from '../../../../employee/employee-list/employee-list.config';
import { ExportExcelGridComponent } from '../export-excel-grid/export-excel-grid.component';
import { screenUtils } from '../../../../common/utils/screenUtils';
import { DailyUnitGridEmployee } from './daily-unit-grid-employees-model';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
var SELECTED_EMP_ID = 'selectedEmpId';
var DailyUnitGridEmployeesComponent = /** @class */ (function () {
    function DailyUnitGridEmployeesComponent(stateManagement, storageService, modalService) {
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.modalService = modalService;
        this.showFilterData = false;
        this.singleRowSelected = false;
        this.isEmptyExists = false;
        this.IsEnabled = false;
        this.rowData = [];
        this.showGrid = false;
        this.GroupedListArray = [];
        this.GroupedList = [];
        this.filteredData = [];
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.onEmployeeCancel = new EventEmitter();
        this.onEmployeeDelete = new EventEmitter();
        this.onFillShift = new EventEmitter();
        this.onReplaceEmployee = new EventEmitter();
        this.onShiftOpen = new EventEmitter();
        this.onSwitchEmployeeShift = new EventEmitter();
        this.onOpenIndividual = new EventEmitter();
        this.onNeedScroll = new EventEmitter();
        this.restrcitedMobileFields = ['EmpName', 'DepartmentName', 'ShiftName', 'UnitName'];
        this.restoreSelectedEmp();
    }
    Object.defineProperty(DailyUnitGridEmployeesComponent.prototype, "rows", {
        set: function (value) {
            this.m_originalRows = value;
            this.refreshView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitGridEmployeesComponent.prototype, "showEmptySlots", {
        get: function () {
            return this.m_showEmptySlots;
        },
        set: function (value) {
            this.m_showEmptySlots = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitGridEmployeesComponent.prototype, "showPendingSlots", {
        get: function () {
            return this.m_showPendingSlots;
        },
        set: function (value) {
            this.m_showPendingSlots = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitGridEmployeesComponent.prototype, "showOvertimes", {
        get: function () {
            return this.m_showOvertimes;
        },
        set: function (value) {
            this.m_showOvertimes = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitGridEmployeesComponent.prototype, "showMessages", {
        get: function () {
            return this.m_showMessages;
        },
        set: function (value) {
            this.m_showMessages = value;
            this.refreshView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyUnitGridEmployeesComponent.prototype, "showOutOfPar", {
        get: function () {
            return this.m_showOutOfPar;
        },
        set: function (value) {
            this.m_showOutOfPar = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitGridEmployeesComponent.prototype.exportToExcel = function () {
        var myCompOneObj = new ExportExcelGridComponent();
        myCompOneObj.exportData();
    };
    DailyUnitGridEmployeesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            _this.loadData();
        });
    };
    DailyUnitGridEmployeesComponent.prototype.loadData = function () {
        this.GroupedList = [];
        this.GroupedListArray = [];
        this.convertToDailyUnitEmployee();
        this.showGrid = true;
        this.rowData.sort(function (a, b) { return (a.EmpName.toUpperCase() > b.EmpName.toUpperCase()) ? 1 : -1; });
        this.refreshGrid();
        this.getExportData();
    };
    DailyUnitGridEmployeesComponent.prototype.getExportData = function () {
        try {
            this.GroupedListArray = this.GroupedListArray.length > 0 ? this.GroupedListArray[0] : this.GroupedListArray;
        }
        catch (e) { }
    };
    DailyUnitGridEmployeesComponent.prototype.dataStateChange = function (state) {
        this.gridState.state = state;
        this.gridState.view = process(this.filteredData, this.gridState.state);
    };
    DailyUnitGridEmployeesComponent.prototype.createSorting = function (event) {
        var sorting = [];
        return sorting;
    };
    DailyUnitGridEmployeesComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.rowData) {
            this.gridState.view = null;
            return;
        }
        if (this.rowData.length == 1) {
            this.singleRowSelected = true;
        }
        this.showFilterData = this.showEmptySlots || this.showPendingSlots || this.showOutOfPar || this.showOvertimes;
        this.filteredData = [];
        _.forEach(this.rowData, function (row) {
            var rowToAdd = undefined;
            if (_this.showEmptySlots && row.IsOpenShift)
                rowToAdd = row;
            if (!rowToAdd && _this.showPendingSlots && row.IsPendingShift)
                rowToAdd = row;
            if (!rowToAdd && _this.showOutOfPar && row.IsSwitchShift)
                rowToAdd = row;
            if (!rowToAdd && _this.showOvertimes && row.IsOTShift)
                rowToAdd = row;
            if (rowToAdd) {
                _this.filteredData.push(rowToAdd);
            }
        });
        if (!this.showFilterData) {
            this.filteredData = this.rowData;
        }
        var records = [];
        //get all parent shifts + partial with employee assigned(scheduled)
        records = this.filteredData.filter(function (row) { return ((row.ParentShiftId == '-1') || (row.ParentShiftId != '-1' && row.EmpId != '0')); });
        records.forEach(function (item) {
            item.Hours = parseFloat(item.Hours);
            item.SchedHours = parseFloat(item.SchedHours);
            //add partial shifts for parent
            var partialShifts = [];
            if (item.HasPartialShift) {
                partialShifts = _this.filteredData.filter(function (row) { return (row.ParentShiftId == item.ShiftId && row.RecordNumber == item.RecordNumber && row.JobCode == item.JobCode && row.UnitId == item.UnitId && row.EmpId == '0'); });
            }
            item.PartialShiftDailyUnitGridEmployee = partialShifts;
        });
        //only display 1. scheduled; 2. non-scheduled for shift with non-children; 3. full shift with children
        records = records.filter(function (row) { return (row.EmpId !== '0' || (row.EmpId === '0' && row.PartialShiftDailyUnitGridEmployee.length === 0) || (row.HasPartialShift && row.PartialShiftDailyUnitGridEmployee.length != 0)); });
        this.gridState.view = process(records, this.gridState.state);
    };
    DailyUnitGridEmployeesComponent.prototype.removeEmployee = function (row) {
        this.onEmployeeDelete.emit(row);
    };
    DailyUnitGridEmployeesComponent.prototype.cancelEmployee = function (row, option) {
        this.isLoading = true;
        this.onEmployeeCancel.emit({ row: row, option: option });
        this.isLoading = false;
    };
    DailyUnitGridEmployeesComponent.prototype.switchEmployee = function (row) {
        this.selectEmployee(row);
        this.onSwitchEmployeeShift.emit(row);
    };
    DailyUnitGridEmployeesComponent.prototype.getEmployeeId = function (row) {
        return row.getFieldValue(employeeListConfig.employeeIdentifierName);
    };
    DailyUnitGridEmployeesComponent.prototype.isEmptyEmployee = function (row) {
        if (row.EmpId === '0')
            return true;
    };
    DailyUnitGridEmployeesComponent.prototype.getFieldValue = function (row, column) {
        var field = _.find(row.fields, function (f) { return f.name === column.name; });
        return field ? field.value : '';
    };
    DailyUnitGridEmployeesComponent.prototype.fillShift = function (row) {
        this.onFillShift.emit(row);
    };
    DailyUnitGridEmployeesComponent.prototype.onFillShiftPartial = function (row) {
        this.onFillShift.emit(row);
    };
    DailyUnitGridEmployeesComponent.prototype.replaceEmployee = function (row) {
        this.onReplaceEmployee.emit(row);
    };
    DailyUnitGridEmployeesComponent.prototype.openIndividual = function (row) {
        this.onOpenIndividual.emit(row);
        this.selectEmployee(row);
    };
    DailyUnitGridEmployeesComponent.prototype.openShift = function (row) {
        this.onShiftOpen.emit(row);
        this.selectEmployee(row);
    };
    DailyUnitGridEmployeesComponent.prototype.isInPar = function (row) {
        return row.InPar.toLowerCase() !== 'false';
    };
    DailyUnitGridEmployeesComponent.prototype.isOvertime = function (row) {
        return row.IsOvertime.toLowerCase() === 'true';
    };
    DailyUnitGridEmployeesComponent.prototype.getMessageCount = function (row) {
        return +row.MessageCount;
    };
    DailyUnitGridEmployeesComponent.prototype.selectEmployee = function (row) {
        var empId = +row.EmpId;
        this.storageService.setControlState(this.stateManagement.componentKey, SELECTED_EMP_ID, { value: empId }, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange);
    };
    DailyUnitGridEmployeesComponent.prototype.isSelectedEmployee = function (row) {
        return this.selectedEmpId && +this.getEmployeeId(row) === this.selectedEmpId;
    };
    DailyUnitGridEmployeesComponent.prototype.onTRCreated = function (item) {
        this.onNeedScroll.emit(item.offsetTop + item.offsetParent.offsetTop);
    };
    DailyUnitGridEmployeesComponent.prototype.getRemoveTip = function (row) {
        return this.isOvertime(row) ? 'Projected for OT' : 'Remove Employee';
    };
    DailyUnitGridEmployeesComponent.prototype.getShiftRequestDateTip = function (row) {
        var dateString = row.ShiftRequestDate;
        var stringvalue = moment(dateTimeUtils.convertFromDtoDateTimeString(dateString)).format(appConfig.requestDateTime);
        return stringvalue.toString();
    };
    Object.defineProperty(DailyUnitGridEmployeesComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    DailyUnitGridEmployeesComponent.prototype.restoreSelectedEmp = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, SELECTED_EMP_ID);
        if (state.value > 0) {
            this.selectedEmpId = state.value;
        }
    };
    DailyUnitGridEmployeesComponent.prototype.refreshView = function () {
        this.filteredRows = this.filterRows(this.m_originalRows);
    };
    DailyUnitGridEmployeesComponent.prototype.filterRows = function (rows) {
        var _this = this;
        if (!this.showEmptySlots && !this.showMessages && !this.showOutOfPar && !this.showOvertimes) {
            return rows.concat([]);
        }
        var filteredRows = [];
        _.forEach(rows, function (row) {
            var rowToAdd = undefined;
            if (_this.showEmptySlots && row.isEmpty)
                rowToAdd = row;
            if (!rowToAdd && _this.showMessages && row.messageCount > 0)
                rowToAdd = row;
            if (!rowToAdd && _this.showOutOfPar && row.isOutOfPar)
                rowToAdd = row;
            if (!rowToAdd && _this.showOvertimes && row.isOvertime)
                rowToAdd = row;
            if (rowToAdd) {
                filteredRows.push(rowToAdd);
            }
        });
        return filteredRows;
    };
    DailyUnitGridEmployeesComponent.prototype.isNoPatner = function (row) {
        if (row.SlxpartnerdataId === '0') {
            return true;
        }
        else {
            if (row.RequeststatusId === '0' || row.RequeststatusId === '3') {
                return true;
            }
            else {
                return false;
            }
        }
    };
    DailyUnitGridEmployeesComponent.prototype.getShiftStatus = function (row) {
        if (row.RequeststatusId === '1') {
            return 'Pending Agency Assignment';
        }
    };
    DailyUnitGridEmployeesComponent.prototype.isPartnerEmployee = function (row) {
        if (row.EmpId !== '0' && row.RequeststatusId === '2') {
            return true;
        }
        else {
            return false;
        }
    };
    DailyUnitGridEmployeesComponent.prototype.checkPartnerData = function (row) {
        if (row.SlxpartnerdataId !== '0')
            return true;
    };
    DailyUnitGridEmployeesComponent.prototype.convertToDailyUnitEmployee = function () {
        var _this = this;
        this.filteredRows && this.filteredRows.forEach(function (currentDetailRow) {
            var dailyUnitGridEmployee = new DailyUnitGridEmployee();
            currentDetailRow.fields.forEach(function (field) {
                if ('InPar' === field.name) {
                    dailyUnitGridEmployee.InPar = field.value;
                }
                else if ('EmpId' === field.name) {
                    dailyUnitGridEmployee.EmpId = field.value;
                }
                else if ('RecordNumber' === field.name) {
                    dailyUnitGridEmployee.RecordNumber = field.value;
                }
                else if ('EmpName' === field.name) {
                    dailyUnitGridEmployee.EmpName = field.value;
                }
                else if ('DepartmentId' === field.name) {
                    dailyUnitGridEmployee.DepartmentId = field.value;
                }
                else if ('PositionGroupId' === field.name) {
                    dailyUnitGridEmployee.PositionGroupId = field.value;
                }
                else if ('PositionGroupName' === field.name) {
                    dailyUnitGridEmployee.PositionGroupName = field.value;
                }
                else if ('JobCode' === field.name) {
                    dailyUnitGridEmployee.JobCode = field.value;
                }
                else if ('JobDescription' === field.name) {
                    dailyUnitGridEmployee.JobDescription = field.value;
                }
                else if ('ShiftGroupId' === field.name) {
                    dailyUnitGridEmployee.ShiftGroupId = field.value;
                }
                else if ('ShiftGroup' === field.name) {
                    dailyUnitGridEmployee.ShiftGroup = field.value;
                }
                else if ('IsConstraint' === field.name) {
                    dailyUnitGridEmployee.IsConstraint = field.value;
                }
                else if ('ParentShiftId' === field.name) {
                    dailyUnitGridEmployee.ParentShiftId = field.value;
                }
                else if ('HasPartialShift' === field.name) {
                    dailyUnitGridEmployee.HasPartialShift = field.value === "1";
                }
                else if ('ParentShiftName' === field.name) {
                    dailyUnitGridEmployee.ParentShiftName = field.value;
                }
                else if ('ExcludeFromCount' === field.name) {
                    dailyUnitGridEmployee.ExcludeFromCount = field.value;
                }
                else if ('ShiftId' === field.name) {
                    dailyUnitGridEmployee.ShiftId = field.value;
                }
                else if ('ShiftName' === field.name) {
                    dailyUnitGridEmployee.ShiftName = field.value;
                }
                else if ('ShiftStart' === field.name) {
                    dailyUnitGridEmployee.ShiftStart = field.value;
                }
                else if ('ShiftEnd' === field.name) {
                    dailyUnitGridEmployee.ShiftEnd = field.value;
                }
                else if ('UnitId' === field.name) {
                    dailyUnitGridEmployee.UnitId = field.value;
                }
                else if ('UnitName' === field.name) {
                    dailyUnitGridEmployee.UnitName = field.value;
                }
                else if ('SchedHours' === field.name) {
                    dailyUnitGridEmployee.SchedHours = field.value;
                }
                else if ('Hours' === field.name) {
                    dailyUnitGridEmployee.Hours = field.value;
                }
                else if ('IsOvertime' === field.name) {
                    dailyUnitGridEmployee.IsOvertime = field.value;
                }
                else if ('PPD' === field.name) {
                    dailyUnitGridEmployee.PPD = field.value;
                }
                else if ('SlxpartnerdataId' === field.name) {
                    dailyUnitGridEmployee.SlxpartnerdataId = field.value;
                }
                else if ('RequeststatusId' === field.name) {
                    dailyUnitGridEmployee.RequeststatusId = field.value;
                }
                else if ('SlxagencyId' === field.name) {
                    dailyUnitGridEmployee.SlxagencyId = field.value;
                    if (field.value != "0") {
                        dailyUnitGridEmployee.AgencyName = _this.agencyMap[field.value];
                    }
                }
                else if ('MessageCount' === field.name) {
                    dailyUnitGridEmployee.MessageCount = field.value;
                }
                else if ('PartnerId' === field.name) {
                    dailyUnitGridEmployee.PartnerId = field.value;
                }
                else if ('ShiftRequestDate' === field.name) {
                    dailyUnitGridEmployee.ShiftRequestDate = field.value;
                }
                else if ('ActualShiftStart' === field.name) {
                    dailyUnitGridEmployee.actualShiftStart = field.value;
                }
                else if ('ActualShiftEnd' === field.name) {
                    dailyUnitGridEmployee.actualShiftEnd = field.value;
                }
            });
            if (dailyUnitGridEmployee.RequeststatusId == "1" && dailyUnitGridEmployee.EmpName == "") {
                dailyUnitGridEmployee.EmpName = employeeListConfig.pendingShiftPartnerStatus;
                dailyUnitGridEmployee.AgencyName = "";
            }
            if (dailyUnitGridEmployee.RequeststatusId == "4" && dailyUnitGridEmployee.EmpName == "") {
                dailyUnitGridEmployee.EmpName = employeeListConfig.preassignPendingStatus;
                dailyUnitGridEmployee.AgencyName = "";
            }
            if (currentDetailRow.isEmpty && !currentDetailRow.isPendingShift) {
                dailyUnitGridEmployee.IsOpenShift = currentDetailRow.isEmpty;
            }
            dailyUnitGridEmployee.IsPendingShift = currentDetailRow.isPendingShift;
            dailyUnitGridEmployee.IsSwitchShift = currentDetailRow.isOutOfPar && !currentDetailRow.isEmpty && _this.isEmptyExists;
            dailyUnitGridEmployee.IsOTShift = currentDetailRow.isOvertime;
            _this.rowData.push(dailyUnitGridEmployee);
        });
        this.GroupData && this.GroupData.forEach(function (currentDetailGroup) {
            currentDetailGroup && currentDetailGroup.rows.forEach(function (currentDetailRow) {
                var dailyUnitGridEmployee = new DailyUnitGridEmployee();
                currentDetailRow.fields.forEach(function (field) {
                    if ('InPar' === field.name) {
                        dailyUnitGridEmployee.InPar = field.value;
                    }
                    else if ('EmpId' === field.name) {
                        dailyUnitGridEmployee.EmpId = field.value;
                    }
                    else if ('RecordNumber' === field.name) {
                        dailyUnitGridEmployee.RecordNumber = field.value;
                    }
                    else if ('EmpName' === field.name) {
                        dailyUnitGridEmployee.EmpName = field.value;
                    }
                    else if ('DepartmentId' === field.name) {
                        dailyUnitGridEmployee.DepartmentId = field.value;
                    }
                    else if ('PositionGroupId' === field.name) {
                        dailyUnitGridEmployee.PositionGroupId = field.value;
                    }
                    else if ('PositionGroupName' === field.name) {
                        dailyUnitGridEmployee.PositionGroupName = field.value;
                    }
                    else if ('JobCode' === field.name) {
                        dailyUnitGridEmployee.JobCode = field.value;
                    }
                    else if ('JobDescription' === field.name) {
                        dailyUnitGridEmployee.JobDescription = field.value;
                    }
                    else if ('ShiftGroupId' === field.name) {
                        dailyUnitGridEmployee.ShiftGroupId = field.value;
                    }
                    else if ('ShiftGroup' === field.name) {
                        dailyUnitGridEmployee.ShiftGroup = field.value;
                    }
                    else if ('ParentShiftId' === field.name) {
                        dailyUnitGridEmployee.ParentShiftId = field.value;
                    }
                    else if ('HasPartialShift' === field.name) {
                        dailyUnitGridEmployee.HasPartialShift = field.value === "1";
                    }
                    else if ('ParentShiftName' === field.name) {
                        dailyUnitGridEmployee.ParentShiftName = field.value;
                    }
                    else if ('ExcludeFromCount' === field.name) {
                        dailyUnitGridEmployee.ExcludeFromCount = field.value;
                    }
                    else if ('ShiftId' === field.name) {
                        dailyUnitGridEmployee.ShiftId = field.value;
                    }
                    else if ('ShiftName' === field.name) {
                        dailyUnitGridEmployee.ShiftName = field.value;
                    }
                    else if ('ShiftStart' === field.name) {
                        dailyUnitGridEmployee.ShiftStart = field.value;
                    }
                    else if ('ShiftEnd' === field.name) {
                        dailyUnitGridEmployee.ShiftEnd = field.value;
                    }
                    else if ('UnitId' === field.name) {
                        dailyUnitGridEmployee.UnitId = field.value;
                    }
                    else if ('UnitName' === field.name) {
                        dailyUnitGridEmployee.UnitName = field.value;
                    }
                    else if ('SchedHours' === field.name) {
                        dailyUnitGridEmployee.SchedHours = field.value;
                    }
                    else if ('Hours' === field.name) {
                        dailyUnitGridEmployee.Hours = field.value;
                    }
                    else if ('IsOvertime' === field.name) {
                        dailyUnitGridEmployee.IsOvertime = field.value;
                    }
                    else if ('PPD' === field.name) {
                        dailyUnitGridEmployee.PPD = field.value;
                    }
                    else if ('SlxpartnerdataId' === field.name) {
                        dailyUnitGridEmployee.SlxpartnerdataId = field.value;
                    }
                    else if ('RequeststatusId' === field.name) {
                        dailyUnitGridEmployee.RequeststatusId = field.value;
                    }
                    else if ('SlxagencyId' === field.name) {
                        dailyUnitGridEmployee.SlxagencyId = field.value;
                        if (field.value != "0") {
                            dailyUnitGridEmployee.AgencyName = _this.agencyMap[field.value];
                        }
                    }
                    else if ('MessageCount' === field.name) {
                        dailyUnitGridEmployee.MessageCount = field.value;
                    }
                    else if ('PartnerId' === field.name) {
                        dailyUnitGridEmployee.PartnerId = field.value;
                    }
                    else if ('ShiftRequestDate' === field.name) {
                        dailyUnitGridEmployee.ShiftRequestDate = field.value;
                    }
                });
                if (dailyUnitGridEmployee.RequeststatusId == "1" && dailyUnitGridEmployee.EmpName == "") {
                    dailyUnitGridEmployee.EmpName = employeeListConfig.pendingShiftPartnerStatus;
                    dailyUnitGridEmployee.AgencyName = "";
                }
                if (dailyUnitGridEmployee.RequeststatusId == "4" && dailyUnitGridEmployee.EmpName == "") {
                    dailyUnitGridEmployee.EmpName = employeeListConfig.preassignPendingStatus;
                    dailyUnitGridEmployee.AgencyName = "";
                }
                if (currentDetailRow.isEmpty && !currentDetailRow.isPendingShift) {
                    dailyUnitGridEmployee.IsOpenShift = currentDetailRow.isEmpty;
                }
                dailyUnitGridEmployee.IsPendingShift = currentDetailRow.isPendingShift;
                dailyUnitGridEmployee.IsSwitchShift = currentDetailRow.isOutOfPar && !currentDetailRow.isEmpty && _this.isEmptyExists;
                dailyUnitGridEmployee.IsOTShift = currentDetailRow.isOvertime;
                _this.GroupedList.push(dailyUnitGridEmployee);
            });
            _this.GroupedList.sort(function (a, b) { return (a.EmpName.toUpperCase() > b.EmpName.toUpperCase()) ? 1 : -1; });
            _this.GroupedListArray.push(_this.GroupedList);
        });
    };
    DailyUnitGridEmployeesComponent.prototype.cancelConfirmation = function (item, cancelOption) {
        var _this = this;
        var options = new ConfirmOptions3();
        options.showCancel = true;
        options.showOK = true;
        options.itemData = item;
        if (cancelOption == "noshow") {
            options.note = " Note:  The cancellation will be reported as a No Show cancellation. This cancellation indicates the Agency employee did not come in and did not call out for their assigned shift.";
        }
        else if (cancelOption == "calledout") {
            options.note = " Note:  The cancellation will be reported as a Called Out cancellation. This cancellation indicates the Agency employee is no longer available for the assigned shift.";
        }
        else if (cancelOption == "facility") {
            options.note = " Note: Please check your agency cancellation policy to determine if you will incur any cancellation fees";
        }
        options.agencyName = item.AgencyName;
        ConfirmDialog3Component.openDialog('Confirmation', 'Are you sure you want to cancel this shift request?', this.modalService, function (result) {
            if (result) {
                _this.cancelEmployee(item, cancelOption);
            }
        }, options);
    };
    DailyUnitGridEmployeesComponent.prototype.showMutliPartnerRows = function (dataItem) {
        var dsplayPartialGrid = false;
        if (dataItem) {
            if (dataItem.EmpId === "0" && dataItem.PartialShiftDailyUnitGridEmployee && dataItem.PartialShiftDailyUnitGridEmployee.length > 0) {
                dsplayPartialGrid = true;
            }
        }
        var showmultipartnerrows = dataItem.SlxpartnerdataId !== '0' && (dataItem.RequeststatusId == '1' || dataItem.RequeststatusId == '4');
        return showmultipartnerrows || dsplayPartialGrid;
    };
    DailyUnitGridEmployeesComponent.prototype.checkCancelActions = function (dataItem, actions) {
        if (dataItem.SlxpartnerdataId !== '0' && dataItem.RequeststatusId == '2' && dataItem.EmpName != "") {
            var actionFlag_1 = false;
            if (this.cancelTypeList != null && this.cancelTypeList.length > 0) {
                this.cancelTypeList && this.cancelTypeList.forEach(function (e) {
                    if (e.partnerId == dataItem.PartnerId) {
                        actionFlag_1 = e.types.some(function (i) { return i.name == actions; });
                    }
                });
            }
            return actionFlag_1;
        }
    };
    DailyUnitGridEmployeesComponent.prototype.filterChange = function (dataItem) {
        if (this.gridState.view.data.length == 1) {
            if (this.checkCancelActions(this.gridState.view.data[0], 'noshow') || this.checkCancelActions(this.gridState.view.data[0], 'calledout')) {
                this.singleRowSelected = true;
            }
        }
        else {
            this.singleRowSelected = false;
        }
    };
    DailyUnitGridEmployeesComponent.prototype.displayPartialGrid = function (dataItem) {
        var dsplayPartialGrid = false;
        if (dataItem) {
            if (dataItem.EmpId === "0" && dataItem.PartialShiftDailyUnitGridEmployee && dataItem.PartialShiftDailyUnitGridEmployee.length > 0) {
                dsplayPartialGrid = true;
            }
        }
        return dsplayPartialGrid;
    };
    DailyUnitGridEmployeesComponent.prototype.displayPartial = function (dataItem) {
        var dsplayPartial = false;
        if (dataItem.EmpId === "0" && dataItem.HasPartialShift && dataItem.PartialShiftDailyUnitGridEmployee.length > 0) {
            dsplayPartial = true;
        }
        return dsplayPartial;
    };
    DailyUnitGridEmployeesComponent.prototype.displayActionIcon = function (dataItem) {
        var dsplayActionIcon = true;
        if (dataItem.HasPartialShift) {
            var scheduledPartials = this.rowData.filter(function (row) { return (row.ParentShiftId == dataItem.ShiftId && row.RecordNumber == dataItem.RecordNumber && row.EmpId != '0'); });
            if (scheduledPartials.length > 0) {
                dsplayActionIcon = false;
            }
        }
        return dsplayActionIcon;
    };
    DailyUnitGridEmployeesComponent.inParFieldName = 'InPar';
    DailyUnitGridEmployeesComponent.isOvertimeFieldName = 'IsOvertime';
    DailyUnitGridEmployeesComponent.messageCountFieldName = 'MessageCount';
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyUnitGridEmployeesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitGridEmployeesComponent.prototype, "orgLevelSubscription", void 0);
    return DailyUnitGridEmployeesComponent;
}());
export { DailyUnitGridEmployeesComponent };
