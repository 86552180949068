import { Input, Output, EventEmitter } from '@angular/core';
import { EditableListActionKind } from '../../models/index';

export class CustomListActorBase {

  @Input ()
  public item: any;

  @Input ()
  public selected: boolean;

  @Input ()
  public editable: boolean = true;

  @Input ()
  public selectable: boolean = true;

  @Input ()
  public editMode: boolean = false;

  @Output ()
  public actionEmitter: EventEmitter<EditableListActionKind> = new EventEmitter<EditableListActionKind> ();
}
