export * from './pm-create-review-popup/pm-create-review-popup.component';
export * from './pm-create-review-container/pm-create-review-container.component';
export * from './pm-review-attachment/pm-review-attachment.component';
export * from './pm-review-form/pm-review-form.component';

import { PmCreateReviewPopupComponent } from './pm-create-review-popup/pm-create-review-popup.component';
import { PmCreateReviewContainerComponent } from './pm-create-review-container/pm-create-review-container.component';
import { PmReviewAttachmentComponent } from './pm-review-attachment/pm-review-attachment.component';
import { PmReviewFormComponent } from './pm-review-form/pm-review-form.component';

export const createReviewComponents: any[] = [
  PmCreateReviewPopupComponent,
  PmCreateReviewContainerComponent,
  PmReviewAttachmentComponent,
  PmReviewFormComponent
];
