import { Directive, Host, ViewContainerRef, TemplateRef } from '@angular/core';
import { AccessibleSwitchDirective } from './accessible-switch.directive';
import { SwitchView } from '../../../core/models/index';

@Directive({selector: '[slxAccessibleCase]'})
export class AccessibleCaseDirective {
  constructor(
      viewContainer: ViewContainerRef, templateRef: TemplateRef<Object>,
      @Host() accessibleSwitch: AccessibleSwitchDirective) {
        accessibleSwitch.addAccessibleCase(new SwitchView(viewContainer, templateRef));
  }
}

@Directive({selector: '[slxNonAccessibleCase]'})
export class NonAccessibleCaseDirective {
  constructor(
      viewContainer: ViewContainerRef, templateRef: TemplateRef<Object>,
      @Host() accessibleSwitch: AccessibleSwitchDirective) {
        accessibleSwitch.addNonAccessibleCase(new SwitchView(viewContainer, templateRef));
  }
}
