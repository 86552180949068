<slx-spinner [show]="isLoaded">
  <div class="modal-content">
    <div class="modal-body" style="height: 500px; overflow-y: scroll;">
      <kendo-grid [data]="gridView" [sortable]="{ mode: 'multiple' }" [sort]="sort" scrollable="none" (sortChange)="sortChange($event)">
        <kendo-grid-column width="30">
          <ng-template kendoGridHeaderTemplate>
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <input type="checkbox" [(ngModel)]="dataItem.selected" (ngModelChange)="onItemSelectionChanged(dataItem)" [(checked)]="dataItem.selected"
            />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            Name
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.item?.employee?.name }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            Position
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.item?.position?.name }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column>
          <ng-template kendoGridHeaderTemplate>
            Type
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.item?.employee?.employeeType?.name }}
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <div class="bootbox-body">{{options?.message}}</div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
      <button type="button" (click)="onOk()" [disabled]="!isAnyItemSelected" class="theme-button-apply">OK</button>
    </div>
  </div>
</slx-spinner>
