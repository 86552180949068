import { Directive, QueryList, Input, AfterContentInit } from '@angular/core';
import * as _ from 'lodash';
import { ErrorMessageDirective } from '../../error-filters/errorMessage';

@Directive({
  selector: '[error-messages]'
})
export class ErrorMessagesDirective implements AfterContentInit {
  @Input()
  public messages: QueryList<ErrorMessageDirective>;

  @Input('errors')
  public set errors(errors: StringMap<boolean | string>) {
    this.lastErrors = errors;
    if (!this.messages) {
      return;
    }
    this.setErrors(errors);
  }
  private lastErrors: StringMap<boolean | string>;

  public ngAfterContentInit(): void {
    if (!this.messages) return;
    if (!this.lastErrors) return;
    this.setErrors(this.lastErrors);
  }

  private setErrors(errors: StringMap<boolean | string>): void {
    let isFirstError: boolean = true;
    let errorMessages: ErrorMessageDirective[] = this.messages.map((errorMessage: ErrorMessageDirective) => errorMessage);
    _.each(errorMessages, (errorMessage: ErrorMessageDirective) => {
      let hasError: boolean = !!errors ? errors.hasOwnProperty(errorMessage.for) : false;
      if (hasError && isFirstError) {
        let errValue: boolean | string = errors[errorMessage.for];
        if (_.isString(errValue)) {
          errorMessage.setText(errValue);
        }
        errorMessage.show();
        isFirstError = false;
      } else {
        errorMessage.hide();
      }
    });
  }
}

