import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  ViewChild,
  HostListener,
  HostBinding,
  OnChanges,
  SimpleChanges
} from '@angular/core';

import * as _ from 'lodash';
import * as createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { CustomDomEvents } from '../../models/index';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export const PERCENT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PercentInputComponent),
  multi: true
};

@Component({
  moduleId: module.id,
  selector: 'slx-percent-input',
  templateUrl: 'percent-input.component.html',
  styleUrls: ['percent-input.component.scss'],
  providers: [PERCENT_VALUE_ACCESSOR]
})
export class PercentInputComponent implements ControlValueAccessor, OnChanges {
  @Input() public className: string;
  @Input() public placeholder: string;
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  public percentMask: any;
  public inEdit: boolean;
  public internalValue: any = '';
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  @HostBinding('class.edited') private hasFocus: boolean = false;

  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.hasFocus = false;
    this.percentMask = {
      mask: createNumberMask.default({
        prefix: '',
        suffix: '%',
        integerLimit: 100
      }),
      guide: false,
      keepCharPositions: true
    };
  }

  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }

  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
    this.onTouchedCallback();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }

  public writeValue(v?: any): void {
    const value: number = +v;
    //TODO:
    //https://smartlinx-legacy.visualstudio.com/slx-worklinx-v6/_git/slx-worklinx-v6-app/pullrequest/3629?_a=overview
    this.internalValue = _.isNumber(value) ? `${value}%` : '';
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public valueChanged(value: any): void {
    this.onChangeCallback(+(<string>value).replace(/\%$/g, ''));
  }
}
