<div>
  <div class="benefit-eligibility-rules-list">
    <span class="no-data-label" *ngIf="!benefitEligibilityRules || !benefitEligibilityRules.length"
      >No benefit eligibility rules avaliable</span
    >

    <div *ngFor="let rule of benefitEligibilityRules; trackBy: trackByItem">
      <div class="benefit-eligibility-rule" *ngIf="rule.status !== BenefitEligibilityRuleStatus.Deleted">
        <span
          class="benefit-eligibility-rule__editmode-name"
          (click)="showUpdateEligibilityRuleDialog(rule)"
          [title]="rule.name + '_' + benefitName"
          >{{ rule.name + '_' + benefitName }}</span
        >
        <button
          class="benefit-eligibility-rule__btn-delete"
          (click)="onDeleteRule(rule.id)"
          [disabled]="!canDelete"
          *ngIf="isEditMode"
        >
          <i
            class="fal fa-trash-alt benefit-eligibility-rule__i-delete"
            title="Delete the rule"
            aria-hidden="true"
            [ngClass]="{ 'benefit-eligibility-rule_delete-enabled': canDelete }"
          ></i>
        </button>
      </div>
    </div>
  </div>
</div>
