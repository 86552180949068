<slx-spinner [show]="state.isLoading" [heightNotFill]="true">
    <slx-single-message [isActive]="empPunches?.isPayrollCycleSubmitted" level="warning">
        <div class="slx-single-message__message">Daily punches is readonly.
          <span class="slx-single-message__description">Payroll has been submitted!</span>
        </div>
    </slx-single-message>
    <slx-single-message [isActive]="empPunches?.isLocked" level="warning">
        <div class="slx-single-message__message">Daily punches is readonly.
          <span class="slx-single-message__description">Timecard is locked!</span>
        </div>
    </slx-single-message>
    <slx-single-message [isActive]="isOutOfPayCycle" level="warning">
      <div class="slx-single-message__message">Daily punches is readonly.
        <span class="slx-single-message__description">You cannot edit punches since Payroll Cycle has not been generated!</span>
      </div>
    </slx-single-message>
    <slx-single-message [isActive]="empPunches?.header?.isAfterTermination" level="warning">
      <div class="slx-single-message__message">Timecard is readonly.
        <span class="slx-single-message__description">You can not edit terminated employee timecard!</span>
      </div>
    </slx-single-message>
    <slx-single-message [isActive]="empPunches?.header?.hasLicenseRestriction" level="warning">
      <div class="slx-single-message__message">License missing or Expired.Timecard cannot be edited
        <span class="slx-single-message__description"></span>
      </div>
    </slx-single-message>
    <slx-single-message [isActive]="!canEditOwnTimecard" level="warning">
      <div class="slx-single-message__message">Timecard is readonly.
        <span class="slx-single-message__description">You are not authorized to edit your own timecard.</span>
      </div>
    </slx-single-message>

  <slx-daily-line-punch-grid
    [employeeId]="employeeId"
    [employeeName]="employeeName"
    [defaultPunchTime]="defaultPunchTime"
    [minPunchDate]="minPunchDate"
    [maxPunchDate]="maxPunchDate"
    [hideSettingsButton]="true"
    [groupName]="'DetailsLinePunches'"
    [userActions]="userActions"
    [isTimecardLocked]="empPunches?.isLocked || empPunches?.header?.isAfterTermination || empPunches?.header?.hasLicenseRestriction"
    [comment]="empPunches?.header?.comment"
  ></slx-daily-line-punch-grid>
</slx-spinner>
