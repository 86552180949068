export interface IBenefitDetailsPayrollGroup {
  id : number;
  name: string;
  type: string;
}

export class BenefitDetailsPayrollGroup {
  public id: number;
  public name: string;
  public type: string;
}
