import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { appConfig } from '../../../../app.config';
import { AbsenceModel, EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
var ConfigureAbsenceRendererComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigureAbsenceRendererComponent, _super);
    function ConfigureAbsenceRendererComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editable = true;
        _this.selectable = true;
        return _this;
    }
    Object.defineProperty(ConfigureAbsenceRendererComponent.prototype, "context", {
        set: function (value) {
            this.item = value.item;
            this.rowIndex = value.rowIndex;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigureAbsenceRendererComponent.prototype, "appConfig", {
        get: function () {
            return this.m_appConfig;
        },
        enumerable: true,
        configurable: true
    });
    ConfigureAbsenceRendererComponent.prototype.ngOnInit = function () {
        this.m_appConfig = appConfig;
    };
    ConfigureAbsenceRendererComponent.prototype.onEditClick = function () {
        this.actionEmitter.emit(EditableListActionKind.START_EDIT);
    };
    ConfigureAbsenceRendererComponent.prototype.onItemSelect = function () {
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    };
    return ConfigureAbsenceRendererComponent;
}(CustomListActorBase));
export { ConfigureAbsenceRendererComponent };
