import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { IUser, ISession } from '../../../authentication/store/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../../../organization/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-user-menu',
  templateUrl: 'user-menu.component.html',
  styleUrls: ['user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit, OnDestroy {


  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;
  @mutableSelect(['session'])
  public user$: Observable<ISession>;

  public menuOpened: boolean;
  public userName: string;
  public alias: string;
  public login: string;

  @unsubscribe()
  private userSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private changeManagementService: ChangeManagementService,
    private applicationStateBusService: ApplicationStateBusService
  ) {
  }

  public ngOnInit(): void {
    this.userSubscription = this.user$.subscribe((session: ISession) => {
      if (session) {
        this.alias = session.alias;
        if (session.user) {
          this.userName = session.user.name;
          this.login = session.user.username;
        }
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public logOut(): void {
    this.changeManagementService.canMoveForward()
      .then((can: boolean) => {
        if (can) {
          this.authenticationService.logout();
        } else {
          //todo
        }
      });
  }

  public resetPassword(): void {
    this.changeManagementService.canMoveForward()
      .then((can: boolean) => {
        if (can) {
          this.authenticationService.navigateToResetPassword(this.login, this.alias);
        } else {
          //todo
        }
      });
  }
}
