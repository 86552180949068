import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListRequest } from '../../../../organization/models/generic-list/index';
import { appConfig } from '../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { StateManagementService } from '../../../../common/services';
var ScheduleCommentsComponent = /** @class */ (function () {
    function ScheduleCommentsComponent(genericListManagementService, stateManagement) {
        this.genericListManagementService = genericListManagementService;
        this.stateManagement = stateManagement;
        this.state = {
            isLoading: false
        };
        this.startDate = moment().startOf('year').toDate();
        this.endDate = moment().endOf('year').toDate();
    }
    ScheduleCommentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('ScheduleCommentsComponent', false);
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.application)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentApplication = application;
            _this.loadDefinitions();
        });
        this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe(function (defs) {
            _this.stateManagement.onComponentActiveStateChanged({});
            _this.loadValues();
        });
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe(function (list) {
            _this.state.isLoading = false;
            _this.stateManagement.loadedData({});
        });
    };
    ScheduleCommentsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ScheduleCommentsComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadValues();
    };
    ScheduleCommentsComponent.prototype.loadDefinitions = function () {
        this.state.isLoading = true;
        this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.scheduleComments, false);
    };
    ScheduleCommentsComponent.prototype.loadValues = function () {
        this.state.isLoading = true;
        var req = new GenericListRequest();
        req.startDate = this.startDate;
        req.endDate = this.endDate;
        this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleCommentsComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleCommentsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleCommentsComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleCommentsComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleCommentsComponent.prototype, "valuesLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleCommentsComponent.prototype, "loadSubscription", void 0);
    return ScheduleCommentsComponent;
}());
export { ScheduleCommentsComponent };
