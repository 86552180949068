import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import { ILookupMultiselectModel } from '../../models/index';
import { createValuAccessor } from '../../utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-dropdown-multiselect',
  templateUrl: 'dropdown-multiselect.component.html',
  styleUrls: ['dropdown-multiselect.component.scss'],
  providers: [createValuAccessor(DropdownMultiSelectComponent)]
})

export class DropdownMultiSelectComponent implements ControlValueAccessor {
  @Input()
  public valueField: string;

  @Input()
  public titleField: string;

  @Input()
  public readonly: boolean;

  @Input()
  public disabled: boolean = false;

  @Input()
  public placeholder: string;

  @Input()
  public set options(items: any[]) {
    this.dataFiltered = items;
    this.m_dataOriginal = items;
    this.m_internalValue = this.preselectedValue;
  }

  @Output()
  public selectionChanged: EventEmitter<ILookupMultiselectModel[]>;

  public dataFiltered: any[];
  public get internalValue(): any {
    return this.m_internalValue;
  }
  public set internalValue(value: any) {
    if ((value && value.length === 0) || value === undefined) {
      value = null;
    }
    this.m_internalValue = value;
    this.onChangeCallback(value);
  }
  public preselectedValue: any;
  private m_internalValue: any;
  private m_dataOriginal: any[];

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = (value: any) => { Promise.resolve(null).then(() => fn(value)); };
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public filterChange(filter: any): void {
    if (!this.m_dataOriginal) return;
    this.dataFiltered = this.m_dataOriginal.filter((item: any) => {
      if(!item[this.titleField] || !item[this.titleField].toLowerCase) {
        return false;
      }
      return item[this.titleField].toLowerCase().indexOf(filter.toLowerCase()) !== -1;
    });
  }

  public writeValue(value: any): void {
    if (!this.m_dataOriginal) {
      this.preselectedValue = value;
      return;
    }
    if (value === undefined) {
      value = null;
      this.preselectedValue = this.m_internalValue = null;
    }
    this.preselectedValue = this.m_internalValue = value;
  }
}

