import * as moment from 'moment';
import {
  ShiftDiffPolicy,
  IShiftDiffPolicy,
  AccrualPolicy,
  IAccrualPolicy,
  IDepartment,
  Department,
  IOrganization,
  Organization,
  IPayPolicy,
  PayPolicy,
  IBudgetedPosition,
  BudgetedPosition,
  Position,
  PositionGroup,
  IPosition,
  IPbjPosition,
  PbjPosition,
  LicenseType
  } from '../../../organization/models/lookup/index';
import { IEssTemplateDefinition, EssTemplateDefinition } from '../../../app-modules/ess-templates/models/index';

import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';
import { ISelectableItemContainer } from '../../../common';

export interface IPositionDTO {
  id: number;
  name: string;
  positionGroup: PositionGroup;
  department: IDepartment;
  organization: IOrganization;
  payPolicy: IPayPolicy;
  shiftDifferentialPolicy: IShiftDiffPolicy;
  accrualPolicy: IAccrualPolicy;
  isPrimary: boolean;
  hourlyRate: number;
  lastUpdateUsername: string;
  lastUpdateDate: string;
  clientDepartment: string;
  groupedWith: IPosition;
  isDirectCare: boolean;
  jobClass: string;
  legalId: number;
  defaultShiftHours: string;
  objectAccount: string;
  employeesCount: number;
  jobListOrder: number;
  budgetedPosition: IBudgetedPosition;
  businessUnit: string;
  pbjCmsPosition: IPbjPosition;
  additionalInfo: IAdditionalInfoDTO;
  essTemplate?: IEssTemplateDefinition;
  replacesPositionsIds?: number[];
  positionLicenseTypes?: number[];
  positionLicenseTypesSelection: ISelectableItemContainer<LicenseType>[];
  operator: string;
  supervisorDepartment: Department;
  supervisorOrganization: Organization;
  supervisorPosition: Position;
  isSystemLogin: boolean;
}

export interface IAdditionalInfoDTO {
  eeocClass: string;
  eeocGroup: string;
  fte: number;
  maximumRate: number;
  minimumRate: number;
  occupationCode: string;
  payGrade: string;
  payType: string;
  perDiemRate: number;
  workersCompCode: string;
}

export interface IConfOrganization {
  id: number;
  orgLevelId: number;
  name: string;
  departments: IDepartment[];
}

export class ConfOrganization implements IConfOrganization {
  public id: number;
  public orgLevelId: number;
  public name: string;
  public departments: Department[];
}

export class AdditionalInfo {

  public eeocClass: string;
  public eeocGroup: string;
  public fte: number;
  public maximumRate: number;
  public minimumRate: number;
  public occupationCode: string;
  public payGrade: string;
  public payType: string;
  public perDiemRate: number;
  public workersCompCode: string;
}

export class PositionModel implements IBaseSelectableModel {

  /* getters for grid filters */

  public get groupedWithName(): string {
    return this.groupedWith ? this.groupedWith.name : '';
  }

  public get budgetedPositionName(): string {
    return this.budgetedPosition ? this.budgetedPosition.description : '';
  }

  public get positionGroupName():string {
    return this.positionGroup ? this.positionGroup.name:'Undefined';
  }

  public get pbjCmsPositionName(): string {
    return this.pbjCmsPosition ? this.pbjCmsPosition.name : '';
  }

  public get isPositionLicense(): string{
    var description = this.positionLicenseTypes.map(function (elem) {
      if (elem) {
        return elem;
      }
    }).join(",");
    return  description ? 'Y' : 'N'
  }


  public id: number;
  public name: string;
  public positionGroup: PositionGroup;
  public department: Department;
  public organization: Organization;
  public payPolicy: PayPolicy;
  public shiftDifferentialPolicy: ShiftDiffPolicy;
  public accrualPolicy: AccrualPolicy;
  public isPrimary: boolean;
  public hourlyRate: number;
  public lastUpdateUsername: string;
  public lastUpdateDate: Date;
  public clientDepartment: string;
  public groupedWith: Position;
  public isDirectCare: boolean;
  public jobClass: string;
  public legalId: number;
  public defaultShiftHours: string;
  public objectAccount: string;
  public employeesCount: number;
  public jobListOrder: number;
  public budgetedPosition: BudgetedPosition;
  public businessUnit: string;
  public pbjCmsPosition: PbjPosition;
  public additionalInfo: AdditionalInfo;
  public essTemplate?: EssTemplateDefinition;
  public replacesPositionsIds?: number[];
  public positionLicenseTypes?: any[]=[];
  public operator: string = 'AND';
  public isSelected: boolean;
  public selectable: boolean = true;

  public supervisorDepartment: Department;
  public supervisorOrganization: Organization;
  public supervisorPosition: Position;
  public isSystemLogin: boolean;

  public positionLicenseTypesSelection : any[];

  [key: string]: any;
  constructor() {
    this.positionLicenseTypesSelection = [];
  }
}
