/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-time-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../moment-input/moment-time-input.component.ngfactory";
import * as i3 from "@angular/forms";
import * as i4 from "../moment-input/moment-time-input.component";
import * as i5 from "../../directives/blur-forwarder/blur-forwarder.directive";
import * as i6 from "../../directives/focus-forwarder/focus-forwarder.directive";
import * as i7 from "./date-time-input.component";
var styles_DateTimeInputComponent = [i0.styles];
var RenderType_DateTimeInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateTimeInputComponent, data: {} });
export { RenderType_DateTimeInputComponent as RenderType_DateTimeInputComponent };
export function View_DateTimeInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { inputComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "slx-moment-time-input", [["protectDate", "true"]], [[1, "required", 0], [2, "edited", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onDateChanged"], [null, "slx-focus"], [null, "slx-blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("slx-focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onCustomFocus() !== false);
        ad = (pd_0 && ad);
    } if (("slx-blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onCustomBlur() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.value = $event) !== false);
        ad = (pd_2 && ad);
    } if (("onDateChanged" === en)) {
        var pd_3 = (_co.onTimeChanged($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_MomentTimeInputComponent_0, i2.RenderType_MomentTimeInputComponent)), i1.ɵdid(2, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.RequiredValidator]), i1.ɵdid(4, 638976, null, 0, i4.MomentTimeInputComponent, [i1.ElementRef], { protectDate: [0, "protectDate"], field: [1, "field"], model: [2, "model"], className: [3, "className"], placeholder: [4, "placeholder"], readonly: [5, "readonly"] }, { onDateChanged: "onDateChanged" }), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.MomentTimeInputComponent]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "hidden-date-input"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "input", [["class", "date-picker-field"]], null, [[null, "blur"], [null, "focus"]], function (_v, en, $event) { var ad = true; if (("blur" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onBlur($event) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onFocus($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i5.BlurForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), i1.ɵdid(13, 16384, null, 0, i6.FocusForwarderDirective, [i1.ElementRef, i1.Renderer], null, null), (_l()(), i1.ɵted(-1, null, ["\u200B "])), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "button active calendar"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCalendar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "caption"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Calendar"])), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-calendar-alt icon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.required; _ck(_v, 2, 0, currVal_9); var currVal_10 = "true"; var currVal_11 = _co.field; var currVal_12 = _co.model; var currVal_13 = _co.className; var currVal_14 = _co.placeholder; var currVal_15 = _co.readonly; _ck(_v, 4, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = _co.value; _ck(_v, 6, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 2).required ? "" : null); var currVal_1 = i1.ɵnov(_v, 4).hasFocus; var currVal_2 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 8).ngClassValid; var currVal_7 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_17 = _co.readonly; _ck(_v, 9, 0, currVal_17); }); }
export function View_DateTimeInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-date-time-input", [], null, null, null, View_DateTimeInputComponent_0, RenderType_DateTimeInputComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DateTimeInputComponent]), i1.ɵdid(2, 245760, null, 0, i7.DateTimeInputComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DateTimeInputComponentNgFactory = i1.ɵccf("slx-date-time-input", i7.DateTimeInputComponent, View_DateTimeInputComponent_Host_0, { dateFormat: "dateFormat", field: "field", model: "model", minDateLimit: "minDateLimit", maxDateLimit: "maxDateLimit", readonly: "readonly", required: "required", className: "className", placeholder: "placeholder", value: "value" }, { onDateChanged: "onDateChanged" }, []);
export { DateTimeInputComponentNgFactory as DateTimeInputComponentNgFactory };
