import * as signalR from '@microsoft/signalr';
import { OnDestroy } from '@angular/core';
import { ThrottlingService, SessionService } from '../../../core/services';
import { ReplaySubject } from 'rxjs';
var SignalrHub2Service = /** @class */ (function () {
    function SignalrHub2Service(throttlingService, sessionService) {
        this.throttlingService = throttlingService;
        this.sessionService = sessionService;
        this.connectionBuilder = new signalR.HubConnectionBuilder();
        this.connectionStatedChanged = new ReplaySubject();
    }
    SignalrHub2Service.prototype.init = function (signalrHubConfig) {
        var _this = this;
        this.signalrHubConfig = signalrHubConfig;
        var options = {
            transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling,
            accessTokenFactory: function () { return _this.sessionService.getToken(); }
        };
        this.connection = this.connectionBuilder
            .withUrl(signalrHubConfig.url + '/' + signalrHubConfig.hubName, options)
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();
        this.throttlingService.subscribeToModeChange(function (event) {
            _this.reconnect().then(function () {
            });
        });
        this.connection.onclose(function (error) {
            _this.connectionStatedChanged.next(false);
        });
        this.connection.onreconnecting(function (error) {
            _this.connectionStatedChanged.next(false);
        });
        this.connection.onreconnected(function (connectionId) {
            _this.connectionStatedChanged.next(true);
        });
        this.start();
    };
    SignalrHub2Service.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    SignalrHub2Service.prototype.subscribe = function (methodName) {
        var subject = new ReplaySubject();
        this.connection.on(methodName, function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            subject.next(args);
        });
        return subject;
    };
    SignalrHub2Service.prototype.destroy = function () {
        this.connection.stop();
    };
    SignalrHub2Service.prototype.reconnect = function () {
        var _this = this;
        return this.stop().then(function () {
            _this.start().then(function () { });
        });
    };
    SignalrHub2Service.prototype.start = function () {
        var _this = this;
        return this.connection.start()
            .then(function () {
            _this.connectionStatedChanged.next(true);
        }).catch(function (exc) {
            _this.connectionStatedChanged.next(false);
        });
    };
    SignalrHub2Service.prototype.stop = function () {
        var _this = this;
        return this.connection.stop()
            .then(function () {
            _this.connectionStatedChanged.next(false);
        }).catch(function (exc) {
            _this.connectionStatedChanged.next(_this.connection.state === signalR.HubConnectionState.Connected);
        });
    };
    SignalrHub2Service.prototype.getConnectionStatus = function () {
        if (this.connection) {
            return this.connection.state;
        }
        return undefined;
    };
    SignalrHub2Service.prototype.RestartConnection = function () {
        if (this.connection && this.connection.state === signalR.HubConnectionState.Disconnected) {
            this.reconnect();
        }
    };
    return SignalrHub2Service;
}());
export { SignalrHub2Service };
