<div class="slx-lr-toolbar">
    <div class="slx-lr-toolbar__dates">
      <slx-daterange
        class="lr-toolbar__range"
        name="startEndDate"
        [startDate]="startDate"
        [endDate]="endDate"
        (rangeDateChanged)="onDatesRangeChanged($event)"
        slxControlActiveState
        startDatePlaceholder="Shift Start Date"
        endDatePlaceholder="Shift End Date"
        hasApplyButton="true"
      ></slx-daterange>
    </div>
    <div class="slx-lr-toolbar__filters">
        <slx-dropdown-list class="slx-lr-toolbar__filter type-filter"
            [options]="typeList"
            [(ngModel)]="type"
            (ngModelChange)="onTypeChanged($event)"
            placeholder="Type"
            name="typeFilter"
            valuePrimitive="true">
        </slx-dropdown-list>
        <slx-dropdown-list class="slx-lr-toolbar__filter"
            [options]="statusList"
            [(ngModel)]="status"
            (ngModelChange)="onStatusChanged($event)"
            placeholder="Status"
            name="statusFilter"
            valuePrimitive="true">
        </slx-dropdown-list>
    </div>
  </div>
