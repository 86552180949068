import * as _ from 'lodash';
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { IDialog, ModalService, DialogOptions } from '../../../../../common/index';
import { Assert } from '../../../../../framework/index';
import { AccrualPolicy, DynamicAccrualPolicy } from '../../../../../organization/models/accruals/index';
import * as moment from 'moment';
import { NgForm } from '@angular/forms';
var DynamicPolicyDialogComponent = /** @class */ (function () {
    function DynamicPolicyDialogComponent(changeDetector, options, modalService) {
        this.changeDetector = changeDetector;
        this.accrualPolicyLookup = [];
        this.dynamicAccrualPolicy = [];
        this.dynamicPolicyId = 0;
        this.now = moment().startOf('day').toDate();
        this.isPolicyDisabled = false;
        Assert.isNotNull(options, 'options');
        this.options = options;
        Assert.isNotNull(modalService, 'modalService');
        this.modalService = modalService;
        this.dialogResult = false;
        this.isLoading = false;
    }
    Object.defineProperty(DynamicPolicyDialogComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicPolicyDialogComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    DynamicPolicyDialogComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.loadSubsection();
        if (this.dynamicAccrualPolicy.length == 0) {
            this.isPolicyDisabled = true;
        }
    };
    Object.defineProperty(DynamicPolicyDialogComponent.prototype, "SelectedPolicyIds", {
        get: function () {
            var str = "";
            this.dynamicAccrualPolicy.forEach(function (i) {
                if (i.policyId != '0') {
                    if (str == "") {
                        str = str.concat(i.policyId);
                    }
                    else {
                        str = str.concat("-", i.policyId);
                    }
                }
            });
            return str;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DynamicPolicyDialogComponent.prototype, "SelectedPolicyNames", {
        get: function () {
            var str = "";
            this.dynamicAccrualPolicy.forEach(function (i) {
                if (i.policyId != '0') {
                    if (str == "") {
                        str = str.concat(i.policyName);
                    }
                    else {
                        str = str.concat("-", i.policyName);
                    }
                }
            });
            return str;
        },
        enumerable: true,
        configurable: true
    });
    DynamicPolicyDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    DynamicPolicyDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    DynamicPolicyDialogComponent.prototype.loadSubsection = function () {
        if (this.dynamicAccrualPolicy.length == 0) {
            this.onAddPolicy();
        }
    };
    DynamicPolicyDialogComponent.prototype.onAddPolicy = function () {
        this.dynamicPolicyId++;
        var accrualPolicy = new DynamicAccrualPolicy();
        accrualPolicy.id = this.dynamicPolicyId;
        accrualPolicy.policyName = "";
        accrualPolicy.policyId = "0";
        accrualPolicy.isError = false;
        accrualPolicy.accrualType = "";
        this.dynamicAccrualPolicy.push(accrualPolicy);
        this.isPolicyDisabled = true;
    };
    DynamicPolicyDialogComponent.prototype.addPolicyDisabled = function () {
        var _this = this;
        if (this.dynamicAccrualPolicy.length == 0) {
            this.isPolicyDisabled = true;
        }
        else {
            var anySelected_1 = false;
            var anyError_1 = false;
            var count_1 = 0;
            _.forEach(this.dynamicAccrualPolicy, function (x) {
                if (x.policyId != "0") {
                    anySelected_1 = true;
                }
                count_1 = _.filter(_this.dynamicAccrualPolicy, ['accrualType', x.accrualType]).length;
                if (count_1 == 1) {
                    x.isError = false;
                }
                if (x.isError) {
                    anyError_1 = true;
                }
            });
            if (!anySelected_1 || anyError_1) {
                this.isPolicyDisabled = true;
            }
            else {
                this.isPolicyDisabled = false;
            }
        }
    };
    DynamicPolicyDialogComponent.prototype.onDeletePolicy = function (pid) {
        _.remove(this.dynamicAccrualPolicy, { id: pid });
        this.addPolicyDisabled();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    DynamicPolicyDialogComponent.prototype.policyChanged = function (item, value) {
        var dpolicy = _.without(this.dynamicAccrualPolicy, item);
        _.forEach(this.accrualPolicyLookup, function (value) {
            if (value.id == parseInt(item.policyId)) {
                item.policyName = value.policyName;
                item.accrualType = _.first(value.rules).accrualTypeName;
                return false;
            }
        });
        if ((_.findIndex(dpolicy, ['accrualType', item.accrualType])) != -1) {
            item.isError = true;
            this.isPolicyDisabled = true;
        }
        else {
            item.isError = false;
            this.isPolicyDisabled = false;
        }
        this.addPolicyDisabled();
    };
    return DynamicPolicyDialogComponent;
}());
export { DynamicPolicyDialogComponent };
