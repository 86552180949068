export * from './lm-roster.component';
export * from './lm-roster-toolbar/lm-roster-toolbar.component';
export * from './lm-roster-list/lm-roster-list.component';

import { LMRosterComponent } from './lm-roster.component';
import { LMRosterToolbarComponent } from './lm-roster-toolbar/lm-roster-toolbar.component';
import { LMRosterListComponent } from './lm-roster-list/lm-roster-list.component';

export const lmRosterComponents: any[] = [
  LMRosterComponent,
  LMRosterToolbarComponent,
  LMRosterListComponent
];
