/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lm-roster.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./lm-roster-list/lm-roster-list.component.ngfactory";
import * as i3 from "./lm-roster-list/lm-roster-list.component";
import * as i4 from "../../services/lm-management.service";
import * as i5 from "../../services/lm-roster/lm-roster-management.service";
import * as i6 from "../lm-calendar/lm-calendar-view/lm-calendar-view.component.ngfactory";
import * as i7 from "../lm-calendar/lm-calendar-view/lm-calendar-view.component";
import * as i8 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i9 from "../../../../common/components/spinner/spinner.component";
import * as i10 from "./lm-roster-toolbar/lm-roster-toolbar.component.ngfactory";
import * as i11 from "./lm-roster-toolbar/lm-roster-toolbar.component";
import * as i12 from "@angular/router";
import * as i13 from "@angular/common";
import * as i14 from "../../services/lm-request-mapper/lm-request-mapper.service";
import * as i15 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i16 from "../../../../core/services/user-settings/user-settings.service";
import * as i17 from "../../../../common/services/app-user-settings/app-user-settings.service";
import * as i18 from "../../../../common/services/column-settings/column-management.service";
import * as i19 from "../../../../common/services/component-state/component-state-storage.service";
import * as i20 from "../../../../common/services/state-management/state-management.service";
import * as i21 from "../../services/lm-api.service";
import * as i22 from "./lm-roster.component";
var styles_LMRosterComponent = [i0.styles];
var RenderType_LMRosterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LMRosterComponent, data: {} });
export { RenderType_LMRosterComponent as RenderType_LMRosterComponent };
function View_LMRosterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-lm-roster-list", [], null, null, null, i2.View_LMRosterListComponent_0, i2.RenderType_LMRosterListComponent)), i1.ɵdid(2, 245760, null, 0, i3.LMRosterListComponent, [i4.LMManagementService, i5.LMRosterManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_LMRosterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-lm-calendar-view", [], null, null, null, i6.View_LMCalendarViewComponent_0, i6.RenderType_LMCalendarViewComponent)), i1.ɵdid(2, 245760, null, 0, i7.LMCalendarViewComponent, [i5.LMRosterManagementService, i4.LMManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LMRosterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "slx-spinner", [], null, null, null, i8.View_SpinnerComponent_0, i8.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i9.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "div", [["class", "slx-high-box leave-management"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-lm-roster-toolbar", [], null, null, null, i10.View_LMRosterToolbarComponent_0, i10.RenderType_LMRosterToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i11.LMRosterToolbarComponent, [i4.LMManagementService, i5.LMRosterManagementService, i12.ActivatedRoute, i12.Router], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMRosterComponent_1)), i1.ɵdid(8, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMRosterComponent_2)), i1.ɵdid(10, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); var currVal_1 = _co.isListView; _ck(_v, 8, 0, currVal_1); var currVal_2 = !_co.isListView; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_LMRosterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "slx-lm-roster", [], null, null, null, View_LMRosterComponent_0, RenderType_LMRosterComponent)), i1.ɵprd(512, null, i14.LMRequestMapperService, i14.LMRequestMapperService, []), i1.ɵprd(512, null, i15.ColumnSettingsStorageService, i15.ColumnSettingsStorageService, [i16.UserSettingsService, i17.AppUserSettingsService]), i1.ɵprd(512, null, i18.ColumnManagementService, i18.ColumnManagementService, [i15.ColumnSettingsStorageService]), i1.ɵprd(512, null, i19.ComponentStateStorageService, i19.ComponentStateStorageService, [i16.UserSettingsService, i17.AppUserSettingsService]), i1.ɵprd(512, null, i20.StateManagementService, i20.StateManagementService, [i19.ComponentStateStorageService, i15.ColumnSettingsStorageService]), i1.ɵprd(512, null, i5.LMRosterManagementService, i5.LMRosterManagementService, [i14.LMRequestMapperService, i21.LMApiService, i15.ColumnSettingsStorageService, i18.ColumnManagementService, i19.ComponentStateStorageService, i20.StateManagementService, i4.LMManagementService]), i1.ɵdid(7, 245760, null, 0, i22.LMRosterComponent, [i5.LMRosterManagementService, i4.LMManagementService, i12.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 7, 0); }, null); }
var LMRosterComponentNgFactory = i1.ɵccf("slx-lm-roster", i22.LMRosterComponent, View_LMRosterComponent_Host_0, {}, {}, []);
export { LMRosterComponentNgFactory as LMRosterComponentNgFactory };
