import { ScheduleEntry } from './schedule-entry';
import { ScheduleEntryShiftContainer } from './schedule-entry-shift-container';
export class ScheduleEntryContainer {
    date: Date;
    employeeId: number;
    employeeName: string;
    employeePayrollNumber: string;
    shifts: ScheduleEntryShiftContainer[];
    canReplaceEmployee: Boolean;
}

export class PayPeriodTimeCard {
    empId:number;
    startDate:Date;
    endDate:Date;
}
