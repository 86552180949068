import { Component, ViewChild, OnInit } from '@angular/core';
import { TelepunchLocation } from '../../../models/telepunch-locations/telepunch-location';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { ConfigurationComponentHelper } from '../../../utils/configuration-component-helper';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { TelepunchLocationsManagementService } from '../../../services/telepunch-locations/telepunch-locations-management.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import * as _ from 'lodash';
import { ModalService } from '../../../../common/services/index';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../../common/index';
import { NgForm } from '@angular/forms';

@Component({
    moduleId: module.id,
    selector: 'slx-telepunch-locations-grid',
    templateUrl: 'telepunch-locations-grid.component.html',
    styleUrls: ['telepunch-locations-grid.component.scss']
})
export class TelepunchLocationsGridComponent implements OnInit {

    public crudHelper: ConfigurationComponentHelper<TelepunchLocation>;
    public selectionHelper: KendoGridCustomSelectionHelper<TelepunchLocation>;
    public gridState: KendoGridStateHelper<TelepunchLocation>;

    public appConfig: IApplicationConfig;
    public management: TelepunchLocationsManagementService;

    @ViewChild('kendoGrid', {static: true})
    private set grid(value: GridComponent) {
        if (this.crudHelper) this.crudHelper.grid = value;
        this.m_grid = value;
    }
    private get grid(): GridComponent {
        return this.m_grid;
    }
    private m_grid: GridComponent;


    @ViewChild('templateForm', { static: true })
    private mainForm: NgForm;

    @unsubscribe()
    private stateSubscription: Subscription;
    @unsubscribe()
    private removeSubscription: Subscription;
    @unsubscribe()
    private gridSelectSubscription: Subscription;
    @unsubscribe()
    private savedSubscription: Subscription;

    @unsubscribe()
    private addItemSubscription: Subscription;
    @unsubscribe()
    private deleteItemSubscription: Subscription;

    @unsubscribe()
    private editSubscription: Subscription;
    @unsubscribe()
    private mainFormSubscription: Subscription;

    constructor(
        management: TelepunchLocationsManagementService,
        private modalService: ModalService
    ) {
        this.management = management;
        this.gridState = new KendoGridStateHelper<TelepunchLocation>();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);

        this.crudHelper = new ConfigurationComponentHelper<TelepunchLocation>();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
    }

    public ngOnInit(): void {
        this.appConfig = appConfig;
        this.addItemSubscription = this.management.addItemClicked$.subscribe(() => {
            let tp: TelepunchLocation = new TelepunchLocation();
            this.crudHelper.addItem(tp);
        });

        this.deleteItemSubscription = this.management.deleteItemClicked$.subscribe(() => {
            this.crudHelper.deleteSelected();
        });


        this.stateSubscription = this.management.onStateChanged$.subscribe((state: {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: false,
            canAdd: false,
            canDelete: false,
            orgLevelChanged: false,
            canEditExceptionFlag: false,
            canEditPBJFlag: false
        }) => {
            if (_.has(state, 'bulkEditMode')) {
                if (state.bulkEditMode) {
                    if (this.grid) this.gridState.closeEditor(this.grid);
                }
            }
            if (_.has(state, 'orgLevelChanged')) {
                if (this.grid) this.gridState.closeEditor(this.grid);
            }
        });

        this.savedSubscription = this.management.onItemSaved$.subscribe((item: TelepunchLocation) => {
            this.gridState.editedRecord = null;
            this.gridState.savedEditedRecord = null;
        });


        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe((records: TelepunchLocation[]): void => {
            this.crudHelper.selectionChange(_.first(records), 0);
        });

        this.removeSubscription = this.management.removeItemsCmd$.subscribe((itemToDelete: TelepunchLocation) => {
                let options: ConfirmOptions = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog(
                    'Confirmation',
                    'Do you want to delete the Telepunch Location?',
                    this.modalService,
                    (result: boolean) => {
                        if (result) {
                            this.management.doRemoveItem(itemToDelete);
                        }
                    }, options);

        });

        this.editSubscription = this.management.editItemCmd$.subscribe((item: TelepunchLocation) => {
            if (item) {
              this.mainFormSubscription = this.mainForm.statusChanges.subscribe(() => {
                if (this.mainForm.dirty) {
                  this.management.markAsDirty();
                }
              });
            } else {
              if (this.mainFormSubscription) {
                this.mainFormSubscription.unsubscribe();
              }
            }
          });


        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
    }

    public onItemFieldChange(item: TelepunchLocation, field: string, value: any): void {
        item[field] = value;
        this.management.setItemDirty(item);
    }

    public rowCallback(context: RowClassArgs): any {
        if (context.dataItem.isDirty) {
            return { 'dirty-item': true };
        }
        return {};
    }

}
