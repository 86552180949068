import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeWarningHeaderDetail = /** @class */ (function () {
    function EmployeeWarningHeaderDetail(id, label, value, dataType) {
        this.id = id;
        this.label = label;
        this.value = value;
        this.dataType = dataType;
    }
    return EmployeeWarningHeaderDetail;
}());
export { EmployeeWarningHeaderDetail };
var EmployeeWarningBasic = /** @class */ (function () {
    function EmployeeWarningBasic() {
        this.id = -1;
        this.warningSubject = '';
    }
    return EmployeeWarningBasic;
}());
export { EmployeeWarningBasic };
var EmployeeWarningExtended = /** @class */ (function () {
    function EmployeeWarningExtended() {
        this.employee = null;
        this.additionalInfo = [];
        this.warningSubject = null;
        this.warningDate = null;
        this.sections = [];
    }
    return EmployeeWarningExtended;
}());
export { EmployeeWarningExtended };
var EmployeeSubsectionWarnings = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSubsectionWarnings, _super);
    function EmployeeSubsectionWarnings() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.warnings = [];
        return _this;
    }
    return EmployeeSubsectionWarnings;
}(EmployeeSectionsBase));
export { EmployeeSubsectionWarnings };
