import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyPunchesManagementService, DailyTimecardToolbarService, TimecardsCheckRightsService } from '../../../services/index';
import { LinePunch, EmployeeDailyPunches, PunchesDisplaySettings, DailyLinePunchActions, IApplyPunchesEvent } from '../../../models/index';
import { PunchesManagementService } from '../../../services/punches/punches-management.service';
import { PayCycleHelperService } from '../../../../organization/services/index';
var DailyPunchesManagementComponent = /** @class */ (function () {
    function DailyPunchesManagementComponent(managementService, punchesManagementService, dailyTimecardToolbarService, payCycleHelperService, changeDetector, timecardsCheckService) {
        this.managementService = managementService;
        this.punchesManagementService = punchesManagementService;
        this.dailyTimecardToolbarService = dailyTimecardToolbarService;
        this.payCycleHelperService = payCycleHelperService;
        this.changeDetector = changeDetector;
        this.timecardsCheckService = timecardsCheckService;
        this.isOutOfPayCycle = false;
        this.canEditOwnTimecard = false;
        this.state = {
            isLoading: false
        };
        this.applyChanges = new EventEmitter();
        this.changingPunches = new EventEmitter();
        this.discardChanges = new EventEmitter();
    }
    Object.defineProperty(DailyPunchesManagementComponent.prototype, "defineUserActions", {
        set: function (a) {
            this.userActions = new DailyLinePunchActions(a.canEditTimecards, true);
        },
        enumerable: true,
        configurable: true
    });
    DailyPunchesManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.canEditOwnTimecard = this.timecardsCheckService.userCanEditOwnTimecard(this.employeeId);
        this.punchesManagementService.isPaidRestBreakEnabled$.subscribe(function (res) {
            _this.managementService.isPaidRestBreakEnabled$.next(res);
        });
        this.filterSetSubscription = this.punchesManagementService.onFilterSet$.subscribe(function (filter) {
            if (!filter) {
                return;
            }
            _this.managementService.applyFilter(_this.punches, filter.eventFilter);
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.loadStatusHeadersSubscription = this.punchesManagementService.onLoadHeaderPunchesStatus$
            .subscribe(function (event) {
            if (event.employeeId !== _this.employeeId || !moment(event.date).isSame(_this.defaultPunchTime)) {
                return;
            }
            _this.state.isLoading = event.value;
            //this.changeDetector.markForCheck();
            //this.changeDetector.detectChanges();
        });
        this.loadedPunchesSubscription = this.punchesManagementService.onLoadedHeaderPunches$
            .subscribe(function (empPunches) {
            if (empPunches.header.employee.id !== _this.employeeId || !moment(empPunches.header.date).isSame(_this.defaultPunchTime)) {
                return;
            }
            _this.updateCanEdit(empPunches);
            _this.managementService.setPanches(empPunches.punches);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.applyChangesSubscription = this.managementService.onApplyChanges$
            .subscribe(function (changes) {
            _this.applyChanges.emit(changes);
        });
        this.changingPunchesSubscription = this.managementService.onChangingPunches$
            .subscribe(function (changes) {
            _this.changingPunches.emit(changes);
        });
        this.discardChangesSubscription = this.managementService.onDiscardChanges$
            .subscribe(function () {
            _this.discardChanges.emit(null);
            _this.discardPending = true;
        });
        this.payCycleHelperService.getPayCycleByDate(this.defaultPunchTime, undefined, this.employeeId, true)
            .then(function (payCycle) {
            _this.isOutOfPayCycle = !payCycle;
            _this.updateCanEdit(_this.empPunches);
        });
    };
    DailyPunchesManagementComponent.prototype.ngOnChanges = function (changes) {
        if (changes['empPunches'] && this.empPunches) {
            if (this.empPunches.isPayrollCycleSubmitted) {
                this.managementService.canEditChanged(false);
            }
            if (!this.empPunches.punches) {
                this.punchesManagementService.loadPunches(this.empPunches);
            }
            else {
                this.managementService.setPanches(this.punches);
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
        }
        if (changes['punches']) {
            if ((this.empPunches && this.punches !== this.empPunches.punches) || this.discardPending) {
                this.discardPending = false;
                this.managementService.setPanches(this.punches);
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
        }
        this.dailyTimecardToolbarService.onRecalcToolbar();
    };
    DailyPunchesManagementComponent.prototype.updateCanEdit = function (empPunches) {
        var editable = !(empPunches.isPayrollCycleSubmitted || this.isOutOfPayCycle || empPunches.isLocked || !this.canEditOwnTimecard);
        this.managementService.canEditChanged(editable);
    };
    DailyPunchesManagementComponent.prototype.ngOnDestroy = function () {
        // Must be, see #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "loadStatusHeadersSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "loadedPunchesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "applyChangesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "changingPunchesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "discardChangesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPunchesManagementComponent.prototype, "filterSetSubscription", void 0);
    return DailyPunchesManagementComponent;
}());
export { DailyPunchesManagementComponent };
