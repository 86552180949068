import { Subscription } from 'rxjs/Subscription';
import { ApplicationStateBusService } from './../../../../organization/services/application-state-bus/application-state-bus.service';
import { NavigationMenuItem } from './../../../../organization/models/navigation-menu-item';
import { Provider, Component, OnInit } from '@angular/core';
import { unsubscribe } from './../../../../core/decorators';
import { FavoriteManagementService } from '../../../services';



@Component({
  moduleId: module.id,
  selector: 'slx-modules-list-configuration',
  templateUrl: 'modules-list-configuration.component.html',
  styleUrls: ['./modules-list-configuration.component.scss']
})


export class ModulesListConfigurationComponent implements OnInit {
  public menuItems: NavigationMenuItem[];

  @unsubscribe()
  public menuSubscription: Subscription;

  constructor(private favService: FavoriteManagementService) {

  }

  public ngOnInit(): void {
    this.menuSubscription = this.favService.menuItemsUpdated$.subscribe((menuItems: NavigationMenuItem[]) => {
      this.menuItems = menuItems;
    });
  }

  public onStarClicked(menuItem: NavigationMenuItem): void {
    this.favService.changeFavoriteState(menuItem);
  }

  public canBeFavorite(menuItem: NavigationMenuItem): boolean {
    return this.favService.canBeFavorite(menuItem);
  }
}
