export interface IDashboardAppAlertConfig {
  soAlerts: IAppAlertConfig;
  taAlerts: IAppAlertConfig;
  lmAlerts: IAppAlertConfig;
}

export interface IAppAlertConfig {
  appId: number;
  alerts: IAlertConfig[];
}

export interface IAlertConfig {
  userAppAlertId: number;
  alertId: number;
  alertName: string;
  displayOrder: number;
  lastModifiedDate: string;
  loginId: string;
  visible: boolean;
  icon: string;
  link: string;
  counter: number;
}

export class DashboardAppAlertConfig {
  public soAlerts: AppAlertConfig;
  public taAlerts: AppAlertConfig;
  public lmAlerts: AppAlertConfig;
}

export class AppAlertConfig {
  public appId: number;
  public alerts: AlertConfig[];
}

export class AlertConfig {
  public userAppAlertId: number;
  public alertId: number;
  public alertName: string;
  public displayOrder: number;
  public lastModifiedDate: Date;
  public loginId: string;
  public visible: boolean;
  public icon: string;
  public link: string;
  public counter: number;
}

export class DashBoardAlertsModel {
  public appId: number;
  public appTitle: string;
  public visible: boolean;
  public alertId: number;
  public alertName: string;
  public displayOrder: number;
  public userAppAlertId: number;

  public orgLevelId: number;
  public lastModifiedDate: Date;
  public loginId: string;
  public userId: number;
}

export enum DashboardAppTypeEnum {
  soAlerts = 3,
  taAlerts = 4,
  lmAlerts = 6,
}

export enum DashboardSOAlertEnum {
  openShiftReq = 1,
  empProjOvertime = 2,
  ptoReq = 3,
}
export enum DashboardTAAlertEnum {
  missingPunchCount = 1,
  overtimeApprovals = 2,
  timecardExceptions = 3,
}
export enum DashboardLMAlertEnum {
  onLeave = 1,
  returning = 2,
  upcoming = 3,
  incomplete = 4,
  pastDue = 5,
}
export enum AlertClassEnum {
  // so
  'far fa-address-card' = 13, // open shift req
  'far fa-clock' = 23, // emp proj overtime
  'fa fa-user-times' = 33, // pto req
  // ta
  'far fa-calendar-times' = 14, // missing punch count
  'far fa-calendar-plus' = 24, // overtime approvals
  'far fa-clock ' = 34, // timecard exceptions
  // lm
  'far fa-calendar-times ' = 16, // on leave
  'far fa-calendar-plus ' = 26, // returning
  'far fa-clock  ' = 36, // upcoming
  'far fa-calendar-plus  ' = 46, // incomplete
  'far fa-clock   ' = 56 // past due
}

