import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/index';


@Component({
  selector: 'slx-login-ip-restriction-message',
  templateUrl: 'login-ip-restriction-message.component.html',
  styleUrls: ['login-ip-restriction-message.component.scss'],
  providers:[AuthenticationService]
})

export class LoginIpRestrictionMessageComponent  {

  constructor(private authenticationService: AuthenticationService, private router: Router) { }
 
  public navigateToLogin(): void {
  
    this.authenticationService.navigateToLogin();
  }
}
