import * as tslib_1 from "tslib";
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { BenefitDetailsManagementService, BenefitDetailsPermissionService } from '../../services/index';
var BenefitDetailsComponent = /** @class */ (function () {
    function BenefitDetailsComponent(manService, permissionService, router, activatedRoute, appSettingsManageService) {
        this.manService = manService;
        this.permissionService = permissionService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.appSettingsManageService = appSettingsManageService;
        this.isLoading = true;
        this.enableBenefitDeduction2 = false;
        this.subscriptions = {};
    }
    BenefitDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init('benefits_management_details', this.router, this.activatedRoute);
        this.getSettings();
        this.subscriptions.onLoad = this.manService
            .subscribeToLoading(function (value) { return Promise.resolve().then(function () { return (_this.isLoading = value); }); });
    };
    BenefitDetailsComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
        this.permissionService.destroy();
    };
    BenefitDetailsComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.enableBenefitDeduction2 = config.isBenefitDeductionEnabled2;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsComponent;
}());
export { BenefitDetailsComponent };
