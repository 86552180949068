import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Group, Path, Text, geometry } from '@progress/kendo-drawing';
var Rect = geometry.Rect, Point = geometry.Point, Size = geometry.Size, transform = geometry.transform;
import { ActivatedRoute, Router } from '@angular/router';
import { LoaConsole } from '../../../models/index';
import { LmNavigationService } from '../../../../../common/services/navigation/index';
var LMConsoleOverviewComponent = /** @class */ (function () {
    function LMConsoleOverviewComponent(activatedRoute, router) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.data = [];
        this.subscriptions = {};
        this.hrNavService = new LmNavigationService(this.router, this.activatedRoute);
    }
    Object.defineProperty(LMConsoleOverviewComponent.prototype, "loaConsole", {
        get: function () {
            return this.m_loaConsole;
        },
        set: function (value) {
            this.m_loaConsole = value;
            this.createData();
        },
        enumerable: true,
        configurable: true
    });
    LMConsoleOverviewComponent.prototype.ngOnInit = function () { };
    LMConsoleOverviewComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMConsoleOverviewComponent.prototype.labelContent = function (e) {
        return e.value;
    };
    LMConsoleOverviewComponent.prototype.onSeriesClick = function (event) {
        if (!event.dataItem) {
            return;
        }
        this.hrNavService.navigateToLmRoster(undefined, undefined, event.dataItem.name);
    };
    LMConsoleOverviewComponent.prototype.labelVisual = function (e) {
        if (e.rect.origin.x === 0) {
            return null;
        }
        var offsetPerChar = 4;
        var offsetLabelTop = 35;
        var offsetLabelContentTop = 55;
        var offsetMarkTop = 12;
        var offsetMarkLeft = 12;
        var minWidth = 120;
        var isTooSmall = false;
        var defaultLabel = e.createVisual();
        defaultLabel.removeAt(0);
        var textElement = defaultLabel.children[0];
        var dataItem = e.dataItem;
        var charOffset = isTooSmall ? 5 * offsetPerChar : e.dataItem.name.length * offsetPerChar;
        var offset = 0;
        if (e.dataItem.priorSum > 0) {
            offset = -e.dataItem.value * (e.rect.origin.x / (e.dataItem.priorSum + e.dataItem.value)) / 2;
        }
        else {
            offset = -(e.rect.origin.x / 2);
        }
        defaultLabel.transform((geometry.transform().translate(offset - charOffset, offsetLabelTop)));
        textElement.content(dataItem.name);
        var bbox = defaultLabel.bbox();
        var borderRect = new Rect(new Point(bbox.bottomLeft().x - offsetMarkLeft, bbox.bottomLeft().y - offsetMarkTop), new Size(4, 12));
        var path = Path.fromRect(borderRect, {
            stroke: {
                color: dataItem.color,
                width: 4
            }
        });
        var text = new Text(e.dataItem.actualValue, new Point(bbox.bottomLeft().x, bbox.bottomLeft().y - offsetLabelContentTop), {
            font: 'bold 24px Arial',
            fill: {
                color: '#ffffff'
            }
        });
        var group = new Group();
        group.append(defaultLabel, text);
        group.append(defaultLabel, path);
        return group;
    };
    LMConsoleOverviewComponent.prototype.createData = function () {
        var _this = this;
        var sum = 0;
        this.data.length = 0;
        if (!this.m_loaConsole) {
            return;
        }
        _.forEach(this.m_loaConsole.loaTypeOverview, function (o) {
            if (o.count > 0) {
                _this.data.push({
                    loaTypeOverview: o,
                    priorSum: sum,
                    value: 10,
                    actualValue: o.count,
                    name: o.loaType.name,
                    color: o.loaType.color
                });
                sum = sum + 10;
            }
        });
    };
    return LMConsoleOverviewComponent;
}());
export { LMConsoleOverviewComponent };
