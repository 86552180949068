import { RolesMenuAccess } from './role-access-definition';

export interface IRoleSubmenu {
  id: number;
  name: string;
  isEnabled: boolean;
}

export class RoleSubmenu {
  public id: number;
  public name: string;
  public isEnabled: boolean;
  public access: RolesMenuAccess;

  public get isDirty(): boolean {
    return this.isEnabled !== this.initial_isEnabled;
  }
  private initial_isEnabled: boolean;

  public setInitialState(): void {
    this.initial_isEnabled = this.isEnabled;
  }
}
