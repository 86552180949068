import { ITimeclockAssignmentRestriction, TimeclockAssignmentRestriction } from '../timeclock-assignment-restriction';
import { EmployeeSectionsBase } from '../employee-sections-base';
import { Timeclock, ITimeclock } from '../../../../configuration/models/index';
import { FieldData, FieldsMeta } from '../../../../core/models/index';
import { Organization, Department, IOrganization, IDepartment, ITimeclockRestrictionDefinition, TimeclockRestrictionDefinition, TimeclockDefinition, TimeclockSlateProfileDefinition, ITimeclockSlateProfileDefinition } from '../../../../organization/models/lookup/index';
import { EmployeeBadge, IEmployeeBadge } from '../../../../organization/models/employee/index';

export interface IEmployeeSectionTimeclock {
    assignmentRestriction: ITimeclockAssignmentRestriction;
    organization: IOrganization;
    department: IDepartment;
    lastPunch: string;
    lastDelivery: string;
    lastDeliveryFP: string;
    lastCommunicationDate: string;
}

export class EmployeeSectionTimeclock {
    public assignmentRestriction: TimeclockAssignmentRestriction;
    public assigned: boolean;
    public organization: Organization;
    public department: Department;
    public lastPunch: string;
    public lastDelivery: string;
    public lastDeliveryFP: string;
    public lastCommunicationDate: string;
    public communicationStatus: number;

    public get id(): number {
        return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.id : 0;
    }

    public get timeclockName(): string {
        return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.name : '-';
    }

    public get restrictionName(): string {
        return this.assignmentRestriction && this.assignmentRestriction.restriction ? this.assignmentRestriction.restriction.name : '-';
    }

    public get organizationName(): string {
        return this.organization ? this.organization.name : '-';
    }

    public get departmentName(): string {
        return this.department ? this.department.name : '-';
    }

    public get physicalId(): number {
        return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.physicalId : 0;
    }

    public get clockOrganization(): string {
        return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.clockOrganization :'-';
    }

    public get restriction(): TimeclockRestrictionDefinition {
        return this.assignmentRestriction.restriction;
    }

    public set restriction(value: TimeclockRestrictionDefinition) {
        if (!this.assignmentRestriction)
            this.assignmentRestriction = new TimeclockAssignmentRestriction();
        this.assignmentRestriction.restriction = value;
    }

    public get timeclock(): TimeclockDefinition {
        return this.assignmentRestriction.timeclock;
    }

    public set timeclock(value: TimeclockDefinition) {
        if (!this.assignmentRestriction)
            this.assignmentRestriction = new TimeclockAssignmentRestriction();
        this.assignmentRestriction.timeclock = value;
    }

    public get lastUpdateRestriction(): Date {
        return this.assignmentRestriction ? this.assignmentRestriction.lastUpdateDate : null;
    }
}

export interface IEmployeeSectionTimeclockContainer {
    agencyPrefix: string;
    badgeId: IEmployeeBadge;
    pin: string;
    homeOrgLevelId: number;
    restriction: ITimeclockRestrictionDefinition;
    records: IEmployeeSectionTimeclock[];
    multiPositionPunch: boolean;
    isPositionsMultiPunchEnabled?: boolean;
    slateProfile: ITimeclockSlateProfileDefinition;
    slateProfileEnabled: boolean;
}

export class EmployeeSectionTimeclockContainer extends EmployeeSectionsBase {
    public agencyPrefix: string;
    public badgeId: FieldData<EmployeeBadge>;
    public pinNumber: FieldData<string>;
    public restriction: FieldData<TimeclockRestrictionDefinition>;
    public timeclockAssignments: FieldData<any>;
    public homeOrgLevelId: number;
    public records: EmployeeSectionTimeclock[];
    public multiPositionPunch: boolean = false;
    public isPositionsMultiPunchEnabled: boolean = false;
    public slateProfile: FieldData<TimeclockSlateProfileDefinition>;
    public slateProfileEnabled: boolean;
}
