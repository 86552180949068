export * from './modal-anchor/modal-anchor.directive';
export * from './page-scroll/page-scroll.directive';
export * from './scroll-watch/scroll-watch-source.directive';
export * from './scroll-watch/scroll-watch-area.directive';
export * from './prevent-default-link/prevent-default-link.directive';
export * from './wizard-step/wizard-step.directive';
export * from './blur-forwarder/blur-forwarder.directive';
export * from './focus-forwarder/focus-forwarder.directive';
export * from './field-policy/field-policy.directive';
export * from './input-policy/input-policy.directive';
export * from './popover/popover.directive';
export * from './popup/popup.directive';
export * from './navigation-link/index';
export * from './class-to-parent/class-to-parent.directive';
export * from './removable-input/removable-input.directive';
export * from './scroll-element-selector/scroll-element-selector.directive';
export * from './element-selector/element-selector.directive';
export * from './select-on-focus/select-on-focus.directive';
export * from './kendo-ui-extensions/index';
export * from './disable-click/disable-click.directive';
export * from './slx-tooltip/slx-tooltip.directive';
export * from './set-if-less-then/set-if-less-then.directive';
export * from './set-if-great-then/set-if-great-then.directive';
export * from './forms/provide-parent-form.directive';
export * from './number-only/number-ony.directive';
export * from './date-pager/date-pager.directive';
export * from './slx-trim-string-value/slx-trim-string-value.directive';
export * from './control-state/index';
export * from './change-management/change-management.directive';
export * from './toolbar/index';
export * from './chart-widget/chart-widget-top-template.directive';
export * from './chart-widget/chart-widget-middle-template.directive';
export * from './chart-widget/chart-widget-bottom-template.directive';
export * from './hidden-on/hidden-on.directive';
export * from './input-text-forbid-symbols/input-text-forbid-symbols.directive';
export * from './input-value-as-string/input-value-as-string.directive';

import { ModalAnchorDirective } from './modal-anchor/modal-anchor.directive';
import { PageScrollDirective } from './page-scroll/page-scroll.directive';
import { ScrollWatchSourceDirective } from './scroll-watch/scroll-watch-source.directive';
import { ScrollWatchAreaDirective } from './scroll-watch/scroll-watch-area.directive';
import { PreventDefaultLinkDirective } from './prevent-default-link/prevent-default-link.directive';
import { StepSwitchDirective, StepCaseDirective, StepDefaultDirective } from './wizard-step/wizard-step.directive';
import { BlurForwarderDirective } from './blur-forwarder/blur-forwarder.directive';
import { FocusForwarderDirective } from './focus-forwarder/focus-forwarder.directive';
import { FieldPolicyDirective } from './field-policy/field-policy.directive';
import { InputPolicyDirective } from './input-policy/input-policy.directive';
import { PopoverDirective } from './popover/popover.directive';
import { PopupDirective } from './popup/popup.directive';
import { navigationDirectives } from './navigation-link/index';
import { ClassToParentDirective } from './class-to-parent/class-to-parent.directive';
import { RemovableInputDirective } from './removable-input/removable-input.directive';
import { ScrollElementSelectorDirective } from './scroll-element-selector/scroll-element-selector.directive';
import { ElementSelectorDirective, ElementSelectorSecondDirective } from './element-selector/element-selector.directive';
import { SelectOnFocusDirective } from './select-on-focus/select-on-focus.directive';
import { kendoDirectives } from './kendo-ui-extensions/index';
import { DisableClickDirective } from './disable-click/disable-click.directive';
import { SlxTooltipDirective } from './slx-tooltip/slx-tooltip.directive';
import { SetIfLessThenDirective } from './set-if-less-then/set-if-less-then.directive';
import { SetIfGreatThenDirective } from './set-if-great-then/set-if-great-then.directive';
import { ProvideParentFormDirective } from './forms/provide-parent-form.directive';
import { NumberOnlyDirective } from './number-only/number-ony.directive';
import { DatePagerDirective } from './date-pager/date-pager.directive';
import { stateDirectives } from './control-state/index';
import { SlxElementCreatedSelectorDirective } from './slx-element-created-selector/slx-element-created-selector.directive';
import { SlxTrimStringValueDirective } from './slx-trim-string-value/slx-trim-string-value.directive';
import { ChangeManagementDirective } from './change-management/change-management.directive';
import { toolbarDirectives } from './toolbar/index';
import { ChartWidgetTopTemplateDirective } from './chart-widget/chart-widget-top-template.directive';
import { ChartWidgetMiddleTemplateDirective } from './chart-widget/chart-widget-middle-template.directive';
import { ChartWidgetBottomTemplateDirective } from './chart-widget/chart-widget-bottom-template.directive';
import { HiddenOnDirective } from './hidden-on/hidden-on.directive';
import { InputTextForbidSymbolsDirective } from './input-text-forbid-symbols/input-text-forbid-symbols.directive';
import { InputValueAsStringDirective } from './input-value-as-string/input-value-as-string.directive';

export const exportDirectives: any[] = [
  ModalAnchorDirective,
  PageScrollDirective,
  ScrollWatchAreaDirective,
  ScrollWatchSourceDirective,
  PreventDefaultLinkDirective,
  StepSwitchDirective,
  StepCaseDirective,
  StepDefaultDirective,
  FieldPolicyDirective,
  InputPolicyDirective,
  PopoverDirective,
  PopupDirective,
  ClassToParentDirective,
  RemovableInputDirective,
  ScrollElementSelectorDirective,
  ElementSelectorDirective, ElementSelectorSecondDirective,
  SelectOnFocusDirective,
  DisableClickDirective,
  SlxTooltipDirective,
  SetIfLessThenDirective,
  SetIfGreatThenDirective,
  ProvideParentFormDirective,
  NumberOnlyDirective,
  DatePagerDirective,
  SlxElementCreatedSelectorDirective,
  SlxTrimStringValueDirective,
  ChangeManagementDirective,
  ChartWidgetTopTemplateDirective,
  ChartWidgetMiddleTemplateDirective,
  ChartWidgetBottomTemplateDirective,
  HiddenOnDirective,
  InputTextForbidSymbolsDirective,
  InputValueAsStringDirective,
  ...stateDirectives,
  ...navigationDirectives,
  ...kendoDirectives,
  ...toolbarDirectives
];

export const directives: any[] = [
  ...exportDirectives,
  BlurForwarderDirective,
  FocusForwarderDirective
];
