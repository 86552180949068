import * as tslib_1 from "tslib";
import { LookupType } from './../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { HttpRequest } from '@angular/common/http';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { schedulerConfig } from '../../scheduler.config';
import { DetailScreenMapService } from './detail-screen-map.service';
import { dateTimeUtils } from '../../../common/utils/index';
import { CacheType } from '../../../common/models/cache/cache-definition';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
var DetailScreenService = /** @class */ (function () {
    function DetailScreenService(apiUtilService, detailScreenMapService, urlParamService, lookupService, cacheUtilService, notificationsService) {
        this.apiUtilService = apiUtilService;
        this.detailScreenMapService = detailScreenMapService;
        this.urlParamService = urlParamService;
        this.lookupService = lookupService;
        this.cacheUtilService = cacheUtilService;
        this.notificationsService = notificationsService;
    }
    DetailScreenService.prototype.getDetails = function (orgLevel, dateOn, positionIds, shiftIds, unitIds, grouping) {
        Assert.isNotNull(orgLevel);
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.root + "/" + dateTimeUtils.convertToDtoString(dateOn) + "/" + schedulerConfig.api.orglevel + "/" + orgLevel.id + "/" + schedulerConfig.api.details;
        var request = new HttpRequest('POST', url, {
            positionIds: positionIds,
            unitIds: unitIds,
            shiftIds: shiftIds,
            groupBy: grouping
        });
        return this.sendRequest(request, orgLevel.id);
    };
    DetailScreenService.prototype.getDailyUnits = function (orgLevel, dateOn) {
        Assert.isNotNull(orgLevel);
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.root + "/" + schedulerConfig.api.orglevel + "/" + orgLevel.id + "/" + schedulerConfig.api.dailyUnitAssignments;
        var request = new HttpRequest('GET', url, {
            params: this.urlParamService.convertToParams({ dateOn: dateTimeUtils.convertToDtoString(dateOn) })
        });
        return this.sendRequest(request, orgLevel.id);
    };
    DetailScreenService.prototype.deleteDailyUnitEmployee = function (dateOn, shiftId, employeeId) {
        Assert.isNotNull(dateOn);
        Assert.isNotNull(shiftId);
        Assert.isNotNull(employeeId);
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.root + "/" + dateTimeUtils.convertToDtoString(dateOn) + "/" + schedulerConfig.api.schedule.shift.root + "/" + shiftId + "/" + schedulerConfig.api.employees.root + "/" + employeeId;
        var request = new HttpRequest('DELETE', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    DetailScreenService.prototype.cancelDailyUnitEmployee = function (dateOn, shiftId, employeeId, partnerId, requestId, cancelType) {
        var _this = this;
        Assert.isNotNull(dateOn);
        Assert.isNotNull(shiftId);
        Assert.isNotNull(partnerId);
        Assert.isNotNull(requestId);
        Assert.isNotNull(cancelType);
        if (employeeId === null || employeeId == void 0) {
            employeeId = 0;
        }
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.root + "/partner/" + partnerId + "/day/" + dateTimeUtils.convertToDtoString(dateOn) + "/" + schedulerConfig.api.schedule.shift.root + "/" + shiftId + "/" + schedulerConfig.api.employees.root + "/" + employeeId + "/request/" + requestId + "/type/" + cancelType + " ";
        var request = new HttpRequest('DELETE', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            _this.notificationsService.success('success', 'Successfully canceled shift request');
            return response.data;
        });
        return promise;
    };
    DetailScreenService.prototype.sendRequest = function (request, orgLevelId) {
        var _this = this;
        var lookupPromise = this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: orgLevelId });
        var mainPromise = this.apiUtilService.request(request);
        return Promise.all([lookupPromise, mainPromise])
            .then(function (_a) {
            var lookup = _a[0], response = _a[1];
            return _this.detailScreenMapService.mapToDetails(response.data, response.meta, lookup.items);
        });
    };
    DetailScreenService.prototype.getAgencyDetails = function (orgLevel, alias) {
        var _this = this;
        Assert.isNotNull(orgLevel);
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.root + "/customer/" + alias + "/" + schedulerConfig.api.orglevel + "/" + orgLevel.id + "/" + schedulerConfig.api.getPartnerDetails;
        var request = new HttpRequest('GET', url);
        // clear cache
        this.cacheUtilService.delete({ key: url }, '');
        var promise = this.apiUtilService
            .cachedRequest(request, CacheType.longTerm)
            .then(function (response) { return _this.detailScreenMapService.mapToPartnerDetails(response.data); });
        return promise;
    };
    DetailScreenService.prototype.getShiftRequestDetails = function (shiftRequestId, alias) {
        var _this = this;
        var url = this.apiUtilService.getApiRoot() + "/" + schedulerConfig.api.root + "/customer/" + alias + "/" + schedulerConfig.api.schedule.shift.root + "/" + shiftRequestId + "/" + schedulerConfig.api.details;
        var request = new HttpRequest('GET', url);
        Assert.isNotNull(shiftRequestId);
        // clear cache
        this.cacheUtilService.delete({ key: url }, '');
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.detailScreenMapService.mapToShiftRequestDetails(response.data); });
        return promise;
    };
    DetailScreenService.prototype.saveIdealSchedule = function (orgLevelId, openShift) {
        var url = this.apiUtilService.apiRoot(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["configuration/orglevel/", "/idealSchedule/openshift"], ["configuration/orglevel/", "/idealSchedule/openshift"])), orgLevelId);
        var body = openShift;
        var request = new HttpRequest('POST', url, body);
        return this.apiUtilService.request(request);
    };
    DetailScreenService.prototype.saveIdealScheduleV2 = function (orgLevelId, openShift) {
        var url = appConfig.api.url + "/" + appConfig.api.version + "/configuration/orglevel/" + orgLevelId + "/idealSchedule/openshift";
        var body = openShift;
        var request = new HttpRequest('POST', url, body);
        return this.apiUtilService.request(request);
    };
    return DetailScreenService;
}());
export { DetailScreenService };
var templateObject_1;
