<slx-spinner [show]="isLoading">
  <div class="modal-body">
    <slx-dashboard-alerts-select-popup
        [modelData] = "modelData"
        (onLoading)="onLoading($event)" 
        (onHasChanges)="onHasChanges($event)">
    </slx-dashboard-alerts-select-popup>
  </div>
  <div class="modal-footer">
      <div (click)="onCancel()" class="btn"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Close</div>
  </div>
</slx-spinner>
