import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceRuleOption, RecurrenceFrequencies, LMCommonRecurrence } from '../../../models/index';
var LMDailyRecurrenceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LMDailyRecurrenceComponent, _super);
    function LMDailyRecurrenceComponent(manService) {
        var _this = _super.call(this) || this;
        _this.manService = manService;
        return _this;
    }
    Object.defineProperty(LMDailyRecurrenceComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canChange;
        },
        enumerable: true,
        configurable: true
    });
    LMDailyRecurrenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var estimateEndDate = this.defineStartDate();
        var recurrence = this.manService.getRecurrence();
        this.defineDefaults(recurrence, estimateEndDate);
        this.defineMode(recurrence.count, recurrence.until);
        this.updatesRestrictionDates();
        this.subscriptions.loaRequest = this.manService
            .subscribeToChangedRequest(function () {
            _this.defineStartDate();
            _this.onChangeRecurrence();
        });
    };
    LMDailyRecurrenceComponent.prototype.onChangeRecurrence = function () {
        var options = new RecurrenceRuleOption(this.interval, null, null);
        this.updatesRestrictionDates();
        this.updateEndMode(options);
        this.manService.setRecurrence(RecurrenceFrequencies.daily, options);
    };
    LMDailyRecurrenceComponent.prototype.defineDefaults = function (recurrence, endDate) {
        this.interval = recurrence.interval || 1;
        this.count = recurrence.count || 1;
        this.until = recurrence.until || endDate;
    };
    LMDailyRecurrenceComponent.prototype.updatesRestrictionDates = function () {
        var copyOfEndOnDate = this.copyDate(this.limitEndOnDate);
        this.calculatedMaxDate = new Date(copyOfEndOnDate.getTime());
        if (!_.isDate(this.until) || this.until < this.calculatedMaxDate) {
            this.until = this.copyDate(this.calculatedMaxDate);
        }
    };
    LMDailyRecurrenceComponent.prototype.updateEndMode = function (options) {
        switch (this.endMode) {
            case this.endAfterOccurrence:
                options.count = this.count;
                break;
            case this.endOnDate:
                options.until = this.until;
                break;
        }
    };
    LMDailyRecurrenceComponent.prototype.defineMode = function (count, until) {
        if (_.isFinite(count) && count > 0) {
            this.endMode = this.endAfterOccurrence;
        }
        else {
            this.endMode = this.endOnDate;
        }
    };
    LMDailyRecurrenceComponent.prototype.defineStartDate = function () {
        var endDate = this.manService.getLoaDates().endDate;
        var estimateEndDate = this.manService.getEstamatedDates().endDate;
        this.limitEndOnDate = endDate || new Date();
        return estimateEndDate;
    };
    return LMDailyRecurrenceComponent;
}(LMCommonRecurrence));
export { LMDailyRecurrenceComponent };
