<kendo-grid kendoGridFixFreezeHeader slxKendoGridMobileManager class="slx-full-height slx-blue-grid slx-no-border"
  slxKendoGridState="PJB_EXPORT_EMPLOYEE_DATA" (stateRestored)="gridState.dataStateChange($event)"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [slxGroupable]="{ showFooter: true }"
  [slxGroupableMobile]="false"
  [group]="gridState.state.group"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  (pageChange)="pageChanged($event)"
  (sortChange)="sortChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <!--(filterChange)="filterChange($event)"-->

  <kendo-grid-column media="xs" title="PBJ Log">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>
        <dt>Emp Master ID</dt>
        <dd>{{ dataItem?.pbjId }}</dd>

        <dt>Name</dt>
        <dd>{{ dataItem?.employeeName }}</dd>

        <dt>Payroll No</dt>
        <dd>{{ dataItem?.payrollNumber }}</dd>
    </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="pbjIdConfigEnabled" title="PBJ ID" width="150" media="sm" field="pbjId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>PBJ ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.pbjId }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="!pbjIdConfigEnabled" title="Emp Master ID" width="150" media="sm" field="employeeMasterId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Master ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeMasterId }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="150" media="sm" field="employeeName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeName }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Payroll No" width="150" media="sm" field="payrollNumber">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll No</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.payrollNumber }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<!-- export grid -->

<kendo-grid #exportGrid *ngIf="isExportInProgress && currentExportType != xlsExportType" class="export-grid slx-blue-grid slx-no-border"
  [data]="exportGridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="exportGridState.state.sort"
  [group]="exportGridState.state.group"
  [filterable]="'menu'"
  [filter]="exportGridState.state.filter"
  [pageable]="false"
>

  <kendo-grid-column *ngIf="pbjIdConfigEnabled" title="PBJ ID" width="150" field="pbjId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>PBJ ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.pbjId }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="!pbjIdConfigEnabled" title="Emp Master ID" width="150" field="employeeMasterId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Master ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeMasterId }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="150" field="employeeName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeName }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Payroll No" width="150" field="payrollNumber">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll No</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.payrollNumber }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-pdf fileName="{{exportFilename}}.pdf"
      paperSize="A4" [scale]="pdfScale" [landscape]="true" [allPages]="true" [repeatHeaders]="true"
      >
      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>

      <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="page-template">
          <div class="header">
              <h6>{{pbjHeader?.organization?.name}} ({{pbjHeader?.startDate| amDateFormat: appConfig.dateFormat}} - {{pbjHeader?.endDate| amDateFormat: appConfig.dateFormat}}) Exported on {{pbjHeader?.exportDate| amDateFormat: appConfig.dateTimeFormatUS}}</h6>
          </div>
          <div class="footer">
            Page {{ pageNum }} of {{ totalPages }}
          </div>
        </div>
      </ng-template>

  </kendo-grid-pdf>

</kendo-grid>
