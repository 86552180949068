export class MessageModel {
    public createdOn: Date;
    public messageId: string;
    public senderFullName: string;
    public status: string;
    public text: string;
}

export interface IMessageModel {
    createdOn: string;
    messageId: string;
    senderFullName: string;
    status: string;
    text: string;
}
