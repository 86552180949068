/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./exception-console-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../exception-console-rollup/exception-console-rollup.component.ngfactory";
import * as i3 from "../../../../common/services/state-management/state-management.service";
import * as i4 from "../../../../common/services/component-state/component-state-storage.service";
import * as i5 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i6 from "../../../services/exception-console/exception-console-rollup-management.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/exception-console/exception-console-api.service";
import * as i9 from "../exception-console-rollup/exception-console-rollup.component";
import * as i10 from "../../../../organization/services/org-level/org-level-watch.service";
import * as i11 from "../../../../organization/services/pay-cycle-helper/pay-cycle-helper.service";
import * as i12 from "../exception-console/exception-console.component.ngfactory";
import * as i13 from "../../../services/exception-console/exception-console-management.service";
import * as i14 from "../../../services/timecards/timecards-api.service";
import * as i15 from "../../../../core/services/error-handling/error-handling.service";
import * as i16 from "../exception-console/exception-console.component";
import * as i17 from "@angular/common";
import * as i18 from "./exception-console-page.component";
var styles_ExceptionConsolePageComponent = [i0.styles];
var RenderType_ExceptionConsolePageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExceptionConsolePageComponent, data: {} });
export { RenderType_ExceptionConsolePageComponent as RenderType_ExceptionConsolePageComponent };
function View_ExceptionConsolePageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-exception-console-rollup", [], null, null, null, i2.View_ExceptionConsoleRollupComponent_0, i2.RenderType_ExceptionConsoleRollupComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.ExceptionConsoleRollupManagementService, i6.ExceptionConsoleRollupManagementService, [i7.Router, i7.ActivatedRoute, i8.ExceptionConsoleApiService, i5.ColumnSettingsStorageService]), i1.ɵdid(3, 245760, null, 0, i9.ExceptionConsoleRollupComponent, [i3.StateManagementService, i4.ComponentStateStorageService, i7.Router, i7.ActivatedRoute, i10.OrgLevelWatchService, i11.PayCycleHelperService, i6.ExceptionConsoleRollupManagementService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_ExceptionConsolePageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-exception-console", [], null, null, null, i12.View_ExceptionConsoleComponent_0, i12.RenderType_ExceptionConsoleComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵprd(512, null, i13.ExceptionConsoleManagementService, i13.ExceptionConsoleManagementService, [i7.Router, i7.ActivatedRoute, i8.ExceptionConsoleApiService, i14.TimecardsApiService, i5.ColumnSettingsStorageService, i3.StateManagementService, i4.ComponentStateStorageService, i15.ErrorHandlingService]), i1.ɵdid(3, 245760, null, 0, i16.ExceptionConsoleComponent, [i3.StateManagementService, i4.ComponentStateStorageService, i7.Router, i7.ActivatedRoute, i11.PayCycleHelperService, i13.ExceptionConsoleManagementService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_ExceptionConsolePageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExceptionConsolePageComponent_1)), i1.ɵdid(2, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExceptionConsolePageComponent_2)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDepartament; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isDepartament; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ExceptionConsolePageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-exception-console-page", [], null, null, null, View_ExceptionConsolePageComponent_0, RenderType_ExceptionConsolePageComponent)), i1.ɵdid(1, 114688, null, 0, i18.ExceptionConsolePageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExceptionConsolePageComponentNgFactory = i1.ɵccf("slx-exception-console-page", i18.ExceptionConsolePageComponent, View_ExceptionConsolePageComponent_Host_0, {}, {}, []);
export { ExceptionConsolePageComponentNgFactory as ExceptionConsolePageComponentNgFactory };
