import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Assert } from '../../../framework/index';
import { ResponseBody, Meta, FieldsMeta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

import { schedulerConfig } from '../../scheduler.config';
import { LeaveRequestMapService } from './leave-request-map.service';

import {
  LeaveRequestEntries, LeaveRequestDetailEntries, ILeaveRequestEntries, LeaveRequestActionPayload, ILeaveRequestDetailEntries, LeaveRequestDetailEntry
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export class LeaveRequestApiService {

  private exportTo$ = new Subject<boolean>();

  constructor(
    private apiUtilService: ApiUtilService,
    private leaveRequestMapService: LeaveRequestMapService,
    private urlParamsService: UrlParamsService) {
  }

  public getLeaveRequests(orgLevelId: number, startDate: Date, endDate: Date): Promise<LeaveRequestEntries> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');

    const url: string = `${this.getLeaveRequestApiRoot(orgLevelId)}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    return this.apiUtilService.request<ILeaveRequestEntries, FieldsMeta>(request)
      .then((response: ResponseBody<ILeaveRequestEntries, FieldsMeta>) => this.leaveRequestMapService.mapLeaveRequests(response.data, response.meta));
  }

  public getLeaveRequestDetails(orgLevelId: number, leaveRequestId: number): Promise<LeaveRequestDetailEntries> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(leaveRequestId, 'leaveRequestId');

    const url: string = `${this.getLeaveRequestApiRoot(orgLevelId)}/${leaveRequestId}/${schedulerConfig.api.schedule.orglevel.leaveRequests.details}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<ILeaveRequestDetailEntries, Meta>(request)
      .then((response: ResponseBody<ILeaveRequestDetailEntries, Meta>) => this.leaveRequestMapService.mapLeaveRequestDetails(response.data));
  }

  public approveLeaveRequest(orgLevelId: number, leaveRequestId: number, payload: LeaveRequestActionPayload): Promise<number> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(leaveRequestId, 'leaveRequestId');
    const url: string = `${this.getLeaveRequestApiRoot(orgLevelId)}/${leaveRequestId}/${schedulerConfig.api.schedule.orglevel.leaveRequests.approve}`;
    let request = this.urlParamsService.createPostRequest(url, payload);

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
  }

  public denyLeaveRequest(orgLevelId: number, leaveRequestId: number, payload: LeaveRequestActionPayload): Promise<number> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(leaveRequestId, 'leaveRequestId');
    const url: string = `${this.getLeaveRequestApiRoot(orgLevelId)}/${leaveRequestId}/${schedulerConfig.api.schedule.orglevel.leaveRequests.deny}`;
    let request = this.urlParamsService.createPostRequest(url, payload);

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
  }

  public submitForReviewRequest(orgLevelId: number, leaveRequestId: number, payload: LeaveRequestActionPayload): Promise<number> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(leaveRequestId, 'leaveRequestId');
    const url: string = `${this.getLeaveRequestApiRoot(orgLevelId)}/${leaveRequestId}/${schedulerConfig.api.schedule.orglevel.leaveRequests.submitForReview}`;
    let request = this.urlParamsService.createPutRequest(url, payload);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
  }

  public updateDetailsRecord(orgLevelId: number, leaveRequestId: number, details: LeaveRequestDetailEntry): Promise<number> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(leaveRequestId, 'leaveRequestId');
    Assert.isNotNull(details, 'details');

    const url: string = `${this.getLeaveRequestApiRoot(orgLevelId)}/${leaveRequestId}/${schedulerConfig.api.schedule.orglevel.leaveRequests.details}`;
    let request = this.urlParamsService.createPutRequest(url, this.leaveRequestMapService.mapToDTODetails(details));

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.data);
  }

  private getLeaveRequestApiRoot(orgLevelId: number): string {
    return `${this.getOrgLevelApiRoot(orgLevelId)}/${schedulerConfig.api.schedule.orglevel.leaveRequests.root}`;
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}`;
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }
}
