import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { NgModel } from '@angular/forms';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../../framework/index';
import {
  BenefitEnrolledEmployee
} from '../../models/index';

@Injectable()
export class BenefitEmployeeManagementService {

  private updatedEmployeeInfo$ = new Subject<BenefitEnrolledEmployee[]>();
  private isChangeOccured$ = new Subject<boolean>();
  private getToDetailsPage$ = new Subject<boolean>();
  private calculateCoverage$ = new Subject();
  private viewModeChanged: boolean = false;

  constructor() { }

  public destroy(): void {
    this.updatedEmployeeInfo$.complete();
    this.isChangeOccured$.complete();
    this.getToDetailsPage$.complete();
  }

  public subscribeToIsChangeOccured(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.isChangeOccured$.subscribe(callback);
  }

  public subscribeToUpdatedEmployeeData(callback: (v: BenefitEnrolledEmployee[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.updatedEmployeeInfo$.subscribe(callback);
  }

  public benefitsOptionTabDetailsChanged(hasError: boolean = false) {
    this.isChangeOccured$.next(hasError);
  }

  public updateEmployeesUpdatedInfo(empData: BenefitEnrolledEmployee[]): void {
    this.updatedEmployeeInfo$.next(empData);
  }

  public subscribeTogetToDetailsPage(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.getToDetailsPage$.subscribe(callback);
  }

  public getBackToDetailsPage() {
    this.getToDetailsPage$.next(true);
  }

  public changeMode(hasChangedMode: boolean) {
    if (_.isBoolean(hasChangedMode)) {
      this.viewModeChanged = hasChangedMode;
    }
  }

  public get viewModeIsChanged(): boolean {
    return this.viewModeChanged;
  }

  public calculateCoverage() {
    this.calculateCoverage$.next();
  }

  public subscribeTocalculateCoverage(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.calculateCoverage$.subscribe(callback);
  }

  public isValidCovOption(e: BenefitEnrolledEmployee): boolean {
    return _.size(e.code) > 0
  }

  public isValidEnrollment(empContr: number, erContr: number): boolean {
    return _.isFinite(empContr) && _.isFinite(erContr) && (empContr >= 0 && erContr >= 0);
  }

  public setControlErrors(model: NgModel, errors: StringMap<boolean>): void {
    if (_.isObjectLike(model)) {
      model.control.setErrors(_.size(errors) > 0 ? errors : null);
    }
  }

  public deleteControlError(model: NgModel, errorName: string): void {
    if (_.isObjectLike(model)) {
      const errors = model.control.errors || {};
      delete errors[errorName];
      this.setControlErrors(model, errors);
    }
  }
}
