import { IServerValidationResult } from '../../../../common/validators/common-validators-models';

export interface IBenefitsDetailsValidationResult {
  isValid: boolean;
  isReadyForValidation: boolean;
  errorMessage: string;
}

export class BenefitsDetailsValidationResult implements IServerValidationResult {
  public isValid: boolean;
  public isReadyForValidation: boolean;
  public errorMessage: string;
}


