import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, HostBinding } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor, dateTimeUtils } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timepicker',
  templateUrl: 'timepicker.component.html',
  providers: [createValuAccessor(TimepickerInputComponent)]
})
export class TimepickerInputComponent implements ControlValueAccessor {
  @Input()
  public minTime: Date;
  @Input()
  public maxTime: Date;
  @Input()
  public readonly: boolean;
  @Input()
  public placeholder: boolean;
  @Input()
  public format: string = 'hh:mm a';
  @Input()
  public cssClass: string;
  @Input()
  public set watermarkStyleUnlessTouched(v: boolean) {
    this.watermarkClass = !!v;
  }

  @HostBinding('class.slx-watermark')
  public watermarkClass: boolean;

  public value: Date;

  public get hasPlaceholder(): boolean {
    return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
  }

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  constructor() {
    this.readonly = false;
  }

  public onChangeValue(time: Date): void {
    this.value = time;
    this.onChangeCallback(time);
  }

  public writeValue(time: Date): void {
    if (_.isDate(time) || _.isNull(time)) {
      this.value = time;
    }
  }

  public onBlur(): void {
    this.onTouchedCallback();
  }

  public registerOnChange(fn: () => void): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouchedCallback = fn;
  }
}
