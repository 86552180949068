import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { process, State } from '@progress/kendo-data-query';
import { LookupApiService } from '../../../../organization/services/index';
import { BudgetedGroup } from '../../../../organization/models/index';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { GridComponent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { BudgetApiService, BudgetManagementService } from '../../../services/index';
var BudgetedGroupEditorComponent = /** @class */ (function () {
    function BudgetedGroupEditorComponent(budgetManagementService, lookupApiService, budgetApiService) {
        this.budgetManagementService = budgetManagementService;
        this.lookupApiService = lookupApiService;
        this.budgetApiService = budgetApiService;
        this.finishEditing = new EventEmitter();
        this.state = {
            isLoading: false
        };
        this.isAnySelected = false;
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(BudgetedGroupEditorComponent.prototype, "orgLevelId", {
        set: function (o) {
            if (o === this.currentOrgLevelId) {
                return;
            }
            this.currentOrgLevelId = o;
            this.loadGroups();
        },
        enumerable: true,
        configurable: true
    });
    BudgetedGroupEditorComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BudgetedGroupEditorComponent.prototype.loadGroups = function (forcedLoadGroup) {
        var _this = this;
        if (forcedLoadGroup === void 0) { forcedLoadGroup = false; }
        if (this.currentOrgLevelId === 0 || !this.currentOrgLevelId) {
            return;
        }
        this.state.isLoading = true;
        this.lookupApiService.getBudgetedGroups(this.currentOrgLevelId, forcedLoadGroup)
            .then(function (groups) {
            _this.state.isLoading = false;
            _this.groups = _.map(groups, function (grp) { return { group: grp, inEdit: false, toDelete: false, groupName: grp.description }; });
            _this.refreshGrid();
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    BudgetedGroupEditorComponent.prototype.onEditGroupsDiscard = function () {
        this.finishEditing.emit(false);
    };
    BudgetedGroupEditorComponent.prototype.onAddGroup = function (grid) {
        this.gridState.closeEditor(grid);
        var group = new BudgetedGroup();
        var newRecord = { group: group, inEdit: false, toDelete: false, groupName: group.description };
        grid.addRow(newRecord);
    };
    BudgetedGroupEditorComponent.prototype.onKeyName = function (event, wrapper, nameField) {
        if (!nameField) {
            return;
        }
        var name = nameField.value;
        var existGroup = _.find(this.groups, function (record) { return record.group.description === name && record.group.id !== wrapper.group.id; });
        if (existGroup) {
            nameField.control.setErrors({ unique: true });
        }
    };
    BudgetedGroupEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            event.dataItem.group.description = event.dataItem.groupName;
            if (event.isNew) {
                _this.addGroup(event.dataItem.group);
            }
            else {
                _this.updateGroup(event.dataItem.group);
            }
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            _this.deleteGroup([event.dataItem.group]);
        });
        this.saveBudgetSubscription = this.budgetManagementService.onSaveBudget$.subscribe(function (isSave) {
            if (isSave) {
                _this.loadGroups(isSave);
            }
        });
    };
    BudgetedGroupEditorComponent.prototype.addGroup = function (grp) {
        var _this = this;
        this.state.isLoading = true;
        this.budgetApiService.createBudgetedGroup(this.currentOrgLevelId, grp)
            .then(function (newgrp) {
            var newRecord = { group: newgrp, inEdit: false, toDelete: false, groupName: newgrp.description };
            _this.groups.unshift(newRecord);
            _this.refreshGrid();
            _this.state.isLoading = false;
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    BudgetedGroupEditorComponent.prototype.updateGroup = function (grp) {
        var _this = this;
        this.state.isLoading = true;
        this.budgetApiService.updateBudgetedGroup(grp)
            .then(function (grp) {
            _this.state.isLoading = false;
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    BudgetedGroupEditorComponent.prototype.deleteGroup = function (grp) {
        var _this = this;
        this.state.isLoading = true;
        var ids = _.map(grp, function (g) { return g.id; });
        this.budgetApiService.deleteBudgetedGroup(ids)
            .then(function (result) {
            _this.state.isLoading = false;
            _.remove(_this.groups, function (item) {
                return _.includes(ids, item.group.id);
            });
            _this.refreshGrid();
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    BudgetedGroupEditorComponent.prototype.refreshGrid = function () {
        if (!this.groups) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.groups, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetedGroupEditorComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetedGroupEditorComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetedGroupEditorComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetedGroupEditorComponent.prototype, "saveBudgetSubscription", void 0);
    return BudgetedGroupEditorComponent;
}());
export { BudgetedGroupEditorComponent };
