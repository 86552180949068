import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { DateRange } from '../../core/models/index';
var DateRangeWithDate = /** @class */ (function (_super) {
    tslib_1.__extends(DateRangeWithDate, _super);
    function DateRangeWithDate(selectedDate, startDate, endDate) {
        var _this = _super.call(this, startDate, endDate) || this;
        _this.selectedDate = selectedDate;
        return _this;
    }
    return DateRangeWithDate;
}(DateRange));
export { DateRangeWithDate };
export function isSameRanges(range1, range2) {
    var isCorrectRange1 = isCorrectRange(range1);
    var isCorrectRange2 = isCorrectRange(range2);
    return !isCorrectRange1 && !isCorrectRange2 ||
        isCorrectRange1 && isCorrectRange2 &&
            moment(range1.selectedDate).isSame(range2.selectedDate) &&
            moment(range1.startDate).isSame(range2.startDate) &&
            moment(range1.endDate).isSame(range2.endDate);
}
export function isCorrectRange(range) {
    return _.isObject(range) && _.isDate(range.selectedDate) && _.isDate(range.startDate) && _.isDate(range.endDate);
}
