import { ILoaType, LoaType } from './loa-type';

export interface ILoaTypeOverview {
  loaType: ILoaType;
  count: number;
}

export class LoaTypeOverview {
  public loaType: LoaType;
  public count: number;
}
