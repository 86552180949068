import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { dateTimeUtils } from '../utils/index';
@Pipe({
  name: 'invalidDate',
  pure: false
})
@Injectable()
export class InvalidDatePipe implements PipeTransform {
  public transform(obj?: any, alterValue?: any): any {
    if (!dateTimeUtils.validateDate(obj)) return alterValue ? alterValue : null;
    return obj;
  }
}
