import { ScheduleCycle } from './../../../../organization/models/lookup/schedule-cycle';
import { Subscription } from 'rxjs/Subscription';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewContainerRef, Inject } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServerConfig } from '../../../../../app/app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import {
  EmployeeGridData, TotalsDescription, MasterScheduleColumnsSettings, MasterScheduleSettings,
  MasterScheduleTotalsSettings, MasterScheduleFilters, MasterScheduleLookupFilters, AutoSchedulingConfig, IAutoSchedulingConfig, AutoSchedulingState
} from '../../../models/index';
import {
  Position, EmpType, LocationUnit, ShiftGroupDefinition, EmployeeScheduleDefinition, GenerateScheduleSummary, ScheduleCycleMessages,
  ScheduleCycleSummaryViewAction,
  IScheduleActions,
  ScheduleActions,
  PositionGroup
} from '../../../../organization/models/index';
import { IColumnSettings } from '../../../../core/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { LookupApiService, ScheduleCycleHelperService } from '../../../../organization/services/index';
import { ModalService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { PostScheduleNavigationService, ScheduleEntryNavigationService, EmployeeSectionNavigationService } from '../../../../common/services/index';
import { ToolbarSectionTypes, DialogOptions, DialogOptions2, DialogModeSize } from '../../../../common/models/index';
import { ConfirmOptions, ConfirmDialogComponent, ConfirmDialog2Component } from '../../../../common/components/index';

import { ScheduleApiService } from '../../../services/index';
import { RestoreScheduleComponent } from '../../restore-schedule/restore-schedule.component';
import {
  MasterScheduleManagementService,
  MasterScheduleToolbarService,
  MasterScheduleQuickEditService,
  IQuickEditStatusEvent,
  MasterScheduleAutoSchedulingService
} from '../../../services/index';
import { TOOLBAR_SERVICE } from '../../../../core/services/index';

import { NotificationsService } from '../../../../core/components/index';
import { appMessages } from '../../../../app.messages';
import { dateTimeUtils } from '../../../../common/utils/index';
import { ScheduleCycleSummaryDialogComponent } from '../../../../organization/components/index';
import { PopperContent } from 'ngx-popper';
import { StateManagementService, ColumnsChangedEvent } from '../../../../common/services/index';
import { IMasterScheduleGroupingType, MasterScheduleGroupingTypes } from '../../../models/master-schedule/master-schedule-grouping-type';
import {  ScheduleEntryApiService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-header',
  templateUrl: 'master-schedule-header.component.html',
  styleUrls: ['master-schedule-header.component.scss']
})
export class MasterScheduleHeaderComponent implements OnInit, OnDestroy {
  @Output()
  public onExportSchedule: EventEmitter<string> = new EventEmitter();

  @Output()
  public onFetchSchedule: EventEmitter<EmployeeScheduleDefinition[]> = new EventEmitter();

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public filters: MasterScheduleFilters;
  public filtersSaved: MasterScheduleFilters;
  public defaultItem: any = { id: null, name: 'ALL' };

  public appConfig: IApplicationConfig;
  public positionGroupLookup: PositionGroup[];
  public positionLookup: Position[];
  public employeeTypeLookup: EmpType[];
  public unitLookup: LocationUnit[];
  public shiftGroupLookup: ShiftGroupDefinition[];
  public isModifyPayPeriodApproved: boolean;
  public actions: ScheduleActions;
  public isPosted: boolean;
  public min: Date = new Date(2000, 0, 1);
  public max: Date = new Date(2079, 11, 31);
  public isFiltered: boolean;
  public summary: GenerateScheduleSummary;
  public quickEditSummary: ScheduleCycleMessages;
  public settings: MasterScheduleSettings;
  public isQuickEditActive: boolean;
  public isQuickEditStarted: boolean;
  public quickEditWindowTop: number = 42;
  public quickEditWindowLeft: number = 800;
  public quickEditWidth: number = 450;
  public quickEditHeight: number = 260;
  public daysBeforeGenerating: StringMap<number>;
  public generatingDaysList: StringMap<number>[];
  public daysBeforePosting: StringMap<number>;
  public postingDaysList: StringMap<number>[];
  public autoSchedulingConfig: AutoSchedulingConfig;
  public storedAutoSchedulingConfig: AutoSchedulingConfig;
  public loadingAutoSchedulingConfig: boolean;
  public autoSchedulingState: AutoSchedulingState;
  public initalPositionGrouping: IMasterScheduleGroupingType = MasterScheduleGroupingTypes.ByPosition;
  public approvedPayPeriod: boolean;
  public allEmployyeData:any;

  public get dateFrom(): Date {
    return this.managementService.dateFrom;
  }
  public get cycles(): ScheduleCycle[] {
    return this.managementService.cycles;
  }
  public autoGenerateDays: StringMap<number>;
  public autoGenerateDaysList: StringMap<number>[];
  public autoPostDays: StringMap<number>;
  public autoPostDaysList: StringMap<number>[];
  public autoGenerateDate: string;
  public autoPostedDate: string;
  public autoGenerateIncorrect: boolean;
  public autoPostedIncorrect: boolean;

  public state: {
    isSelectPropagated: boolean;
  };
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscriptionFilters: Subscription;
  @unsubscribe()
  private recalculatedSubscription: Subscription;
  @unsubscribe()
  private filterSubscription: Subscription;
  @unsubscribe()
  private quickEditActiveSubscription: Subscription;
  @unsubscribe()
  private quickEditSavedSubscription: Subscription;
  @unsubscribe()
  private autoSchedulingSubscription: Subscription;
  @unsubscribe()
  private autoSchedulingLoadingSubscription: Subscription;
  @unsubscribe()
  private actionsSubscription: Subscription;
  @unsubscribe()
  private scheduleCyclesSubscription: Subscription;
  @unsubscribe()
  private dataLoadedSubscription: Subscription;


  private selectedOrgLevel: OrgLevel;

  private saveSettings: MasterScheduleSettings;

  constructor(
    private lookupApiService: LookupApiService,
    private scheduleApiService: ScheduleApiService,
    private modalService: ModalService,
    private managementService: MasterScheduleManagementService,
    private router: Router,
    private appSettingsManageService: AppSettingsManageService,
    private activatedRoute: ActivatedRoute,
    private notificationsService: NotificationsService,
    private scheduleCycleHelperService: ScheduleCycleHelperService,
    @Inject(TOOLBAR_SERVICE) private masterScheduleToolbarService: MasterScheduleToolbarService,
    private stateManagementService: StateManagementService,
    private masterScheduleQuickEditService: MasterScheduleQuickEditService,
    private autoSchedulingService: MasterScheduleAutoSchedulingService,
    private ScheduleEntryApiService: ScheduleEntryApiService

  ) {
    this.generatingDaysList = this.autoSchedulingService.generateDays(30);
    this.postingDaysList = this.autoSchedulingService.generateDays(29);
    this.autoSchedulingConfig = new AutoSchedulingConfig({} as IAutoSchedulingConfig);

    this.state = {
      isSelectPropagated: true
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.quickEditActiveSubscription = this.masterScheduleQuickEditService.quickEditStatusChanged$.subscribe((event: IQuickEditStatusEvent) => {
      this.isQuickEditActive = event.isActive;
      this.isQuickEditStarted = event.isStarted;
    });

    this.quickEditSavedSubscription = this.masterScheduleQuickEditService.quickSaveResult$.subscribe((result: ScheduleCycleMessages) => {
      this.quickEditSummary = result;
      this.showQuickEditSummary();
      this.masterScheduleToolbarService.onFiltersChanged(this.filters);
    });

    this.autoSchedulingSubscription = this.autoSchedulingService.subscribeToAutoScheduling((state: AutoSchedulingState) => {
      this.autoSchedulingConfig = state.config;
      this.storedAutoSchedulingConfig = _.cloneDeep(state.config);
      this.assignInitDays(state.config);
      this.autoSchedulingState = state;
    });

    this.autoSchedulingLoadingSubscription = this.autoSchedulingService.subscribeToLoadingState((isLoading: boolean) => {
      this.loadingAutoSchedulingConfig = isLoading;
    });

    this.masterScheduleToolbarService.onSettingsChanged$.subscribe((settings: MasterScheduleSettings) => {
      this.settings = settings;
      this.settings.display.positionGrouping = settings.display.positionGrouping ? settings.display.positionGrouping : this.initalPositionGrouping;
      this.saveSettings = _.cloneDeep(settings);
    });

    this.filterSubscription = this.masterScheduleToolbarService.onFiltersChanged$
      .subscribe((filters: MasterScheduleFilters) => {
        this.filters = filters;
        this.filtersSaved = _.cloneDeep(this.filters);
        this.checkIsFiltered();
        this.restorePositionGroupPreset();
        this.restorePositionPreset();
        this.restoreEmpTypesPreset();
        this.restoreUnitsPreset();
        this.restoreShiftGroupPreset();
      });

    this.orgLevelSubscriptionFilters = this.orgLevel$
      .withLatestFrom(this.managementService.filtersRestored$)
      .subscribe(([orgLevel, filters]: [OrgLevel, MasterScheduleFilters]) => {
        if (!orgLevel || !orgLevel.id) {
          return;
        }
        this.selectedOrgLevel = orgLevel;

        this.lookupApiService.getPositionGroups(orgLevel.id, true).then((value: PositionGroup[]) => {
          this.positionGroupLookup = value;
          this.restorePositionGroupPreset();
        });

        this.lookupApiService.getPositions(undefined, orgLevel.id).then((value: Position[]) => {
          this.positionLookup = value;
          this.restorePositionPreset();

        });
        this.lookupApiService.getEmpTypes().then((value: EmpType[]) => {
          this.employeeTypeLookup = value;
          this.restoreEmpTypesPreset();
        });
        this.lookupApiService.getLocationUnits(undefined, orgLevel.id).then((value: LocationUnit[]) => {
          this.unitLookup = value;
          this.restoreUnitsPreset();
        });
        this.lookupApiService.getShiftGroupDefinition(orgLevel.id).then((value: ShiftGroupDefinition[]) => {
          this.shiftGroupLookup = value;
          this.restoreShiftGroupPreset();
        });
        this.autoSchedulingService.init(orgLevel.id);
      });

    this.recalculatedSubscription = this.managementService.onRecalculated$.subscribe((data: EmployeeGridData) => {
      this.isPosted = data.isPosted;
    });

    this.actionsSubscription = this.managementService.actions$.subscribe((data: IScheduleActions) => {
      this.actions = data;
    });

    this.scheduleCyclesSubscription = this.managementService.scheduleCyclesLoaded$.subscribe(() => this.onDaysListChanged(true));
    this.getSettings();
  }


  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public isVisibleSection(sectionType: ToolbarSectionTypes): boolean {
    return sectionType === ToolbarSectionTypes.VISIBLE;
  }

  public onDateFromChanged(dateFrom: Date): void {
    if (moment(dateFrom).year() > 2000) {
      this.managementService.updateDatesSubscription(dateFrom);
      this.filters.dateFrom = dateFrom;
      this.filters.weekNumber = this.managementService.getWeeksByDate(dateFrom);
      this.masterScheduleToolbarService.onFiltersChanged(this.filters);
      //this.actions.setFilters({ dateFrom, scheduleDisplay: this.getWeeksByDate(dateFrom) });
    }
  }

  public isStartCycle(date: Date): boolean {
    return this.managementService.isStartCycle(date);
  }

  public onSettingsClosed(isApply: boolean): void {
    if (isApply) {
      return;
    }
    this.settings = _.cloneDeep(this.saveSettings);
  }

  public onSettingsChanged(popperContent: PopperContent, isApply: boolean): void {
    popperContent.hide();
    if (!isApply) {
      return;
    }
    const filterChanged = this.saveSettings.totals.viewTotalsFTEs !== this.settings.totals.viewTotalsFTEs;
    this.masterScheduleToolbarService.settingsChanged(this.settings);
    if (filterChanged) {
      this.filters.showTotalsFTEs = this.settings.totals.viewTotalsFTEs;
      this.masterScheduleToolbarService.onFiltersChanged(this.filters);
    }
  }

  public onDisplaySelectChanged(): void {
    this.masterScheduleToolbarService.settingsChanged(this.settings);
  }

  public onPositionGroupingChanged(): void {
    if (this.settings.display.positionGrouping.id !== this.filters.groupBy.id) {
        this.settings.display.positionGrouping = this.filters.groupBy;
        this.masterScheduleToolbarService.groupingChanged(this.filters.groupBy);
        this.masterScheduleToolbarService.onFiltersChanged(this.filters);
      }
  }

  public onFiltersChanged(popperContent: PopperContent, isApply: boolean): void {
    popperContent.hide();
    if (isApply) {
      this.masterScheduleToolbarService.onFiltersChanged(this.filters);
    } else {
      this.filters = _.cloneDeep(this.filtersSaved);
    }
  }
  public onDisplayOrderChanged(orderTypeName: string, value: any): void {
    this.settings.display.displayOrder = value;
  }

  public onSeparateChanged(): void {
    //this.actions.setSettings(this.settings);
  }

  public onDaysListChanged(isGenerating: boolean): void {
    if (!this.cycles) return;

    const currentDate: Date = new Date();
    const generatingDays: number = _.get(this.daysBeforeGenerating, 'id');
    const postingDays: number = _.get(this.daysBeforePosting, 'id');
    if (isGenerating) {
      this.defineScheduleCycle(currentDate, generatingDays);

      this.assignGenerateDate(generatingDays);
      this.autoSchedulingConfig.daysBeforeGenerating = generatingDays;

      this.assignPostingDate(postingDays);
      this.autoSchedulingConfig.daysBeforePosting = postingDays;
    } else {
      this.assignPostingDate(postingDays);
      this.autoSchedulingConfig.daysBeforePosting = postingDays;
    }

    this.checkPostingDateValidity();
  }

  public onSaveAutoScheduling(popperContent: PopperContent): void {
    this.autoSchedulingService.saveAutoScheduling(this.selectedOrgLevel.id, this.autoSchedulingConfig)
      .then(() => {
        this.autoSchedulingConfig.enabled = true;
        const config: IAutoSchedulingConfig = _.pick(this.autoSchedulingConfig, ['daysBeforeGenerating', 'daysBeforePosting', 'enabled']);
        this.storedAutoSchedulingConfig = new AutoSchedulingConfig(config);
        popperContent.hide();
      });
  }

  public onDeleteAutoScheduling(popperContent: PopperContent): void {
    this.autoSchedulingService.deleteAutoScheduling(this.selectedOrgLevel.id)
      .then(() => {
        this.autoSchedulingConfig.enabled = false;
        this.storedAutoSchedulingConfig = new AutoSchedulingConfig((<any>{}));
        popperContent.hide();
      });
  }

  public onCloseAutoScheduling(): void {
    if (
      this.autoSchedulingConfig.daysBeforeGenerating !== this.storedAutoSchedulingConfig.daysBeforeGenerating ||
      this.autoSchedulingConfig.daysBeforePosting !== this.storedAutoSchedulingConfig.daysBeforePosting
    ) {
      const currentDate: Date = new Date();
      this.autoSchedulingConfig = new AutoSchedulingConfig(this.storedAutoSchedulingConfig);
      this.defineScheduleCycle(currentDate, this.autoSchedulingConfig.daysBeforeGenerating);
      this.assignGenerateDate(this.autoSchedulingConfig.daysBeforeGenerating);
      this.assignPostingDate(this.autoSchedulingConfig.daysBeforePosting);
      this.assignInitDays(this.autoSchedulingConfig);
    }
  }

  public assignGenerateDate(days: number): void {
    if (days > 0) {
      this.autoSchedulingConfig.generatingDate = this.getLastScheduleCycleDay(days) || '';
    }
  }

  public assignPostingDate(days: number): void {
    if (days > 0) {
      this.autoSchedulingConfig.postingDate = this.getLastScheduleCycleDay(days) || '';
    }
  }

  public getLastScheduleCycleDay(days: number): string {
    if (this.autoSchedulingConfig.scheduleCycle) {
      const lastScheduleCycleDay: moment.Moment = this.autoSchedulingConfig.scheduleCycle.endDate.clone().subtract(days, 'day');
      return lastScheduleCycleDay.format(this.appConfig.dateFormat);
    }

    return null;
  }

  public defineScheduleCycle(date: Date, days: number): void {
    if (days > 0) {
      let currentScheduleCycle: ScheduleCycle = this.managementService.getScheduleCycle(date);
      let lastScheduleCycleDay: moment.Moment = currentScheduleCycle.endDate.clone().subtract(days, 'day');

      while (!!currentScheduleCycle && lastScheduleCycleDay.isBefore(date)) {
        const newScheduleCycleDay: moment.Moment = currentScheduleCycle.endDate.clone().add(1, 'day');
        currentScheduleCycle = this.managementService.getScheduleCycle(newScheduleCycleDay.toDate());
        if (!!currentScheduleCycle) {
          lastScheduleCycleDay = currentScheduleCycle.endDate.clone().subtract(days, 'day');
        }
      }

      this.autoSchedulingConfig.scheduleCycle = currentScheduleCycle;
    }
  }

  public hasAutoSchedulingErrors(): boolean {
    return this.autoSchedulingConfig.postingDateBeforeGenerating || this.autoSchedulingConfig.postingDateInPast;
  }

  public checkPostingDateValidity(): void {
    const generatingDate: string = _.get(this.autoSchedulingConfig, 'generatingDate');
    const postingDate: string = _.get(this.autoSchedulingConfig, 'postingDate');

    this.autoSchedulingConfig.postingDateInPast = moment(new Date()).isAfter(new Date(postingDate));
    this.autoSchedulingConfig.postingDateBeforeGenerating = false;

    if (!this.autoSchedulingConfig.postingDateInPast && _.size(generatingDate) > 0 && _.size(postingDate) > 0) {
      this.autoSchedulingConfig.postingDateBeforeGenerating = moment(postingDate, this.appConfig.dateFormat).isSameOrBefore(new Date(generatingDate));
    }
  }

  public assignInitDays(config: AutoSchedulingConfig): void {
    this.daysBeforeGenerating = config.daysBeforeGenerating > 0 ?
      {
        id: config.daysBeforeGenerating,
        day: config.daysBeforeGenerating
      }
      :
      null;
    this.daysBeforePosting = config.daysBeforePosting > 0 ?
      {
        id: config.daysBeforePosting,
        day: config.daysBeforePosting
      }
      :
      null;
  }

  public quickEditToggle(): void {
    if (!this.isQuickEditActive) {
      this.masterScheduleQuickEditService.activateQuickEdit();
    } else {
      this.masterScheduleQuickEditService.deactivateQuickEdit();
    }
  }

  public quickEditClose(): void {
    this.masterScheduleQuickEditService.deactivateQuickEdit();
  }


  public stopPropagation($event: Event): void {
    if (this.state.isSelectPropagated) {
      $event.stopPropagation();
    } else {
      this.state.isSelectPropagated = true;
    }
  }
  
  public onActionChanged(action: any): void {
    let startDate = moment(this.managementService.dateFrom).format("MM-DD-YYYY"); 
    let endDate = moment(this.managementService.endDate).format("MM-DD-YYYY");
    let OrgLevel= this.managementService.currentOrgLevelId;
    
    const message = this.actions.canEditForApprovedPayPeriod ?
        `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the 
        employee, are you sure you want to continue?`: `You do not have permissions to modify a 
        schedule in an approved pay period`;        
        let popupOptions: ConfirmOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = this.actions.canEditForApprovedPayPeriod ? true : false;
        popupOptions.buttonOKtext = 'Ok';
        popupOptions.buttonCanceltext = this.actions.canEditForApprovedPayPeriod ? 'Cancel':'Ok';
    switch (action) {
      case 'post':
        if (this.isStartCycle(this.managementService.dateFrom)) {
          let navService: PostScheduleNavigationService = new PostScheduleNavigationService(this.router, this.activatedRoute);
          navService.navigateToOpenShiftManagement(this.managementService.dateFrom);
        }
        break;
      case 'showSummary':
        let promise = Promise.resolve(this.summary);
        if (!this.summary) {
          promise = this.scheduleApiService.loadScheduleSummaryMessages(this.managementService.currentOrgLevelId, this.managementService.dateFrom, this.managementService.endDate);
        }
        promise.then((s: GenerateScheduleSummary) => {
          if (!s.scheduleCycleStartDate || !s.scheduleCycleEndDate) {
            s.scheduleCycleStartDate = this.managementService.dateFrom;
            s.scheduleCycleEndDate = this.managementService.endDate;
          }
          ScheduleCycleSummaryDialogComponent.openDialog(s, this.modalService, (action: ScheduleCycleSummaryViewAction) => { /* nothing todo*/ });
        });
        break;
      case 'generate':
        if (this.isModifyPayPeriodApproved) {
          this.ScheduleEntryApiService.checkApprovedPayperiodForOrgLevel(OrgLevel,startDate,endDate).then((data: any) => {
            this.approvedPayPeriod = data;
            if (this.approvedPayPeriod == true) {
              ConfirmDialog2Component.openDialog(
                'Warning',
                message,
                this.modalService,
                (result: boolean) => {
                  if (result) {
                    let options: ConfirmOptions = new ConfirmOptions();
                    options.showCancel = false;
                    options.showOK = true;
                    if (this.isPosted) {
                      ConfirmDialogComponent.openDialog('Confirmation', 'This schedule has already been posted, are you sure you want to re-generate?', this.modalService, (result: boolean) => {
                        if (result) {
                          this.doGenerateSchedule();
                        }
                      }, options);
                    } else {
                      this.doGenerateSchedule();
                    }
                  }
                },popupOptions
              )
            }
            else {
              this.doGenerateSchedule();
            }
          });
        }
        else {
          this.doGenerateSchedule();
        }

        break;
      case 'delete':
          this.ScheduleEntryApiService.checkApprovedPayperiodForOrgLevel(OrgLevel,startDate,endDate).then((data: any) => {
            this.approvedPayPeriod = data;
            if (this.approvedPayPeriod == true && this.isModifyPayPeriodApproved) {
              ConfirmDialog2Component.openDialog(
                'Warning',
                message,
                this.modalService,
                (result: boolean) => {
                  if (result) {
                    this.managementService.onLoadStatusChanged(true);
                    this.scheduleApiService.deleteScheduleForDepartment(this.selectedOrgLevel, this.managementService.dateFrom, this.managementService.endDate)
                      .then(() => this.onFetchSchedule.emit(null));
                  }
                },popupOptions);
            }
            else {
              this.managementService.onLoadStatusChanged(true);
              this.scheduleApiService.deleteScheduleForDepartment(this.selectedOrgLevel, this.managementService.dateFrom, this.managementService.endDate)
                .then(() => this.onFetchSchedule.emit(null));
            }
          });
        break;
      case 'backup':
        this.scheduleApiService.backupSchedule(this.managementService.dateFrom, this.managementService.endDate, this.selectedOrgLevel)
          .then(
            () => this.notificationsService.success(appMessages.success.scheduleBackupCreated.title, appMessages.success.scheduleBackupCreated.message));
        break;
      case 'restore':
          this.ScheduleEntryApiService.checkApprovedPayperiodForOrgLevel(OrgLevel,startDate,endDate).then((data: any) => {
            this.approvedPayPeriod = data;
            if (this.approvedPayPeriod == true && this.isModifyPayPeriodApproved) {
              ConfirmDialog2Component.openDialog(
                'Warning',
                message,
                this.modalService,
                (result: boolean) => {
                  if (result) {
                    let dialogOptions: DialogOptions2 = new DialogOptions2();
                    dialogOptions.fullHeightOnMobile = true;
                    dialogOptions.modeSize = DialogModeSize.grid;
                    let restoreComponent: RestoreScheduleComponent = this.modalService.globalAnchor.openDialog2(
                      RestoreScheduleComponent,
                      'Restore Schedule',
                      dialogOptions,
                      null,
                      (result: boolean) => {
                        if (result) {
                          this.onFetchSchedule.emit(null);
                        }
                      }
                    );
                  }
                },popupOptions
              );
            }
            else {
              let dialogOptions: DialogOptions2 = new DialogOptions2();
              dialogOptions.fullHeightOnMobile = true;
              dialogOptions.modeSize = DialogModeSize.grid;
              let restoreComponent: RestoreScheduleComponent =
              this.modalService.globalAnchor.openDialog2(
                RestoreScheduleComponent,
                'Restore Schedule',
                dialogOptions,
                null,
                (result: boolean) => {
                  if (result) {
                    this.onFetchSchedule.emit(null);
                  }
                }
              );
            }
          });
        break;
      case 'export':
        this.onExportSchedule.emit();
        break;
    }
  }
  
  public onQuickWindowDragEnd(): void {
    const offset = 70;
    if (this.quickEditWindowTop < 0) {
      this.quickEditWindowTop = 0;
    } else if (this.quickEditWindowTop > window.innerHeight - this.quickEditHeight - offset) {
      this.quickEditWindowTop = window.innerHeight - this.quickEditHeight - offset;
    }
    if (this.quickEditWindowLeft < 0) {
      this.quickEditWindowLeft = 0;
    } else if (this.quickEditWindowLeft > window.innerWidth - this.quickEditWidth - offset) {
      this.quickEditWindowLeft = window.innerWidth - this.quickEditWidth - offset;
    }
  }

  private checkIsFiltered(): void {
    if (this.filters.filters.positionGroup && this.filters.filters.positionGroup.id) {
      this.isFiltered = true;
      return;
    }
    if (this.filters.filters.position && this.filters.filters.position.id) {
      this.isFiltered = true;
      return;
    }
    if (this.filters.filters.shiftGroup && this.filters.filters.shiftGroup.id) {
      this.isFiltered = true;
      return;
    }
    if (this.filters.filters.unit && this.filters.filters.unit.id) {
      this.isFiltered = true;
      return;
    }
    if (this.filters.filters.employeeType && this.filters.filters.employeeType.length > 0) {
      this.isFiltered = true;
      return;
    }
    this.isFiltered = false;
  }

  private doGenerateSchedule(): void {
    this.managementService.onLoadStatusChanged(true);
    this.scheduleApiService.generateScheduleAtBackend(this.selectedOrgLevel.id, this.managementService.dateFrom)
      .then((summary: GenerateScheduleSummary) => {
        this.summary = summary;
        this.managementService.onLoadStatusChanged(false);
        this.onFetchSchedule.emit(summary.schedule);
        if (summary.messages && summary.messages.length > 0) {
          this.showSummary();
        }
      });
  }
  private showSummary(): void {
    ScheduleCycleSummaryDialogComponent.openDialog(this.summary, this.modalService, (action: ScheduleCycleSummaryViewAction) => {
      if (!action) {
        return;
      }
      if (action.action === 'NavigateToEmployee') {
        const es = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
        es.NavigateToEmployeeSections(action.employeeId, false);
      }
      if (action.action === 'NavigateToScheduleEntry') {
        const se = new ScheduleEntryNavigationService(this.router, this.activatedRoute);
        se.NavigateToScheduleEntry(action.employeeId, action.date);
      }
    });
  }

  private showQuickEditSummary(): void {
    if (!this.quickEditSummary || !this.quickEditSummary.messages || this.quickEditSummary.messages.length === 0) {
      return;
    }
    ScheduleCycleSummaryDialogComponent.openDialog(this.quickEditSummary, this.modalService, (action: ScheduleCycleSummaryViewAction) => {
      if (!action) {
        return;
      }
      if (action.action === 'NavigateToEmployee') {
        const es = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
        es.NavigateToEmployeeSections(action.employeeId, false);
      }
      if (action.action === 'NavigateToScheduleEntry') {
        const se = new ScheduleEntryNavigationService(this.router, this.activatedRoute);
        se.NavigateToScheduleEntry(action.employeeId, action.date);
      }
    });
  }

  private restorePositionGroupPreset(): void {
    if (this.filters && this.filters.filters.positionGroup && this.positionGroupLookup) {
      if (!_.find(this.positionGroupLookup, (p: PositionGroup) => p.id === this.filters.filters.positionGroup.id)) {
        if (this.filters.filters.positionGroup.id !== null) {
          this.positionGroupLookup.push(this.filters.filters.positionGroup);
        }
      }
    }
  }

  private restorePositionPreset(): void {
    if (this.filters && this.filters.filters.position && this.positionLookup) {
      if (!_.find(this.positionLookup, (p: Position) => p.id === this.filters.filters.position.id)) {
        if (this.filters.filters.position.id !== null) {
          this.positionLookup.push(this.filters.filters.position);
        }
      }
    }
  }

  private restoreEmpTypesPreset(): void {
    if (!this.filters || !this.employeeTypeLookup) {
      return;
    }
    _.forEach(this.filters.filters.employeeType, (empFilter: EmpType) => {
      if (!_.find(this.employeeTypeLookup, (et: EmpType) => et.name === empFilter.name)) {
        this.employeeTypeLookup.push(empFilter);
      }
    });
  }

  private restoreUnitsPreset(): void {
    if (this.filters && this.filters.filters.unit && this.unitLookup) {
      if (!_.find(this.unitLookup, (p: LocationUnit) => p.id === this.filters.filters.unit.id)) {
        if (this.filters.filters.unit.id !== null) {
          this.unitLookup.push(this.filters.filters.unit);
        }
      }
    }
  }

  private restoreShiftGroupPreset(): void {
    if (this.filters && this.filters.filters.shiftGroup && this.shiftGroupLookup) {
      if (!_.find(this.shiftGroupLookup, (p: ShiftGroupDefinition) => p.id === this.filters.filters.shiftGroup.id)) {
        if (this.filters.filters.shiftGroup.id !== null) {
          this.shiftGroupLookup.push(this.filters.filters.shiftGroup);
        }
      }
    }
  }

  public getActionCaption(dateFrom: Date): string {
    return !this.isStartCycle(dateFrom) ? "This action may only be performed per scheduling period. To enable this action, first use the date selector to set the appropriate Start and End Dates for a scheduling period." : "";
  }
  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved =  config.ModifySchedulesApprovedinPayPeriods;
  }
}