import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
var LoadingIndicatorComponent = /** @class */ (function () {
    function LoadingIndicatorComponent(appBusService) {
        this.appBusService = appBusService;
        this.orgLevels = null;
        this.listMenu = null;
        this.afterRelogin = false;
        this.hasError = false;
    }
    LoadingIndicatorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelIdSubscription = this.appBusService.orgLevelIdSelected$.subscribe(function (orgLevelId) {
            _this.afterRelogin = _.isNull(_this.orgLevels) && _.isNull(_this.listMenu);
        });
        this.orgLevelsLoadedSubscription = this.appBusService.subscribeToOrgLevelsLoaded(function (orgLevels) {
            _this.orgLevels = orgLevels;
        });
        this.menuLoadedSubscription = this.appBusService.subscribeToMenuLoaded(function (listMenu) {
            if (_this.afterRelogin) {
                _this.afterRelogin = false;
                return;
            }
            _this.listMenu = listMenu;
        });
        this.appErrorSubscription = this.appBusService.subscribeToAppError(function () {
            _this.hasError = true;
        });
    };
    LoadingIndicatorComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.orgLevels = null;
        this.listMenu = null;
    };
    Object.defineProperty(LoadingIndicatorComponent.prototype, "isShow", {
        get: function () {
            return !this.hasError && (_.isNull(this.orgLevels) || _.isNull(this.listMenu));
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        select('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], LoadingIndicatorComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LoadingIndicatorComponent.prototype, "orgLevelsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LoadingIndicatorComponent.prototype, "menuLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LoadingIndicatorComponent.prototype, "orgLevelIdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LoadingIndicatorComponent.prototype, "appErrorSubscription", void 0);
    return LoadingIndicatorComponent;
}());
export { LoadingIndicatorComponent };
