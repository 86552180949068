<div class="w2s-status approved" *ngIf="w2sState==1">
  Form W-2 Statements were published to the employees.
</div>
<div class="w2s-status not-approved" *ngIf="w2sState==2">
  Form W-2 Statements have not yet been published to the employees.
</div>
<div class="w2s-status not-approved" *ngIf="w2sState != 1 && w2sState != 2">
  Form W-2 Statements will appear here once they are received and ready to publish to employees.
</div>
<kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells slx-cell-padding"
          scrollable="'scrollable'"
          [slxKendoGridColumnsGroup]="columnsGroupName"
          [data]="gridState.view"
          [sortable]="{ mode: 'multiple' }"
          [sort]="gridState.state.sort"
          [filterable]="'menu'"
          [filter]="gridState.state.filter"
          [skip]="gridState.state.skip"
          [pageable]="true"
          [pageSize]="pageSize"
          [reorderable]="isReordable"
          (dataStateChange)="gridState.dataStateChange($event)">

<kendo-grid-column title="Name"
                   field="employee.fullName"
                   width="220"
                   filter="text"
                   >
  <ng-template kendoGridHeaderTemplate>
    <span>Name</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>


      {{ dataItem.employee?.fullName }}


  </ng-template>
</kendo-grid-column>


<kendo-grid-column title="Department"
                   field="department.name"
                   width="200"
                   filter="text"
                   >
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip>Department</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.department?.name }}</ng-template>
</kendo-grid-column>

<kendo-grid-column title="Position"
                   field="position.name"
                   width="270"
                   filter="text"
                   >
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip>Position</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.position?.name }}</ng-template>
</kendo-grid-column>
<kendo-grid-column title="Employee Type"
    field="employee.type"
    width="150"
    filter="text"
    >
  <ng-template kendoGridHeaderTemplate>
  <span slxKendoGridHeaderTooltip>Employee Type</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.employee?.type }}</ng-template>
</kendo-grid-column>

<kendo-grid-column title="W2 Document"
                   field="w2Document.w2DocumentTitle"
                   width="150"
                   filter="text"
                   >
  <ng-template kendoGridHeaderTemplate>
    <span slxKendoGridHeaderTooltip>Form W-2</span>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
    <span  class="w2s-emp__text w2s-link" (click)="downloadW2SFile(dataItem.w2Document?.exportId,dataItem.w2Document?.w2DocumentTitle)" title="{{ dataItem.w2Document?.w2DocumentTitle }}">{{ dataItem.w2Document.w2DocumentTitle }}</span>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column title="Approved"
field="approvedStatus"
width="270"
filter="text"
>
<ng-template kendoGridHeaderTemplate>
<span slxKendoGridHeaderTooltip>Approved</span>
</ng-template>
<ng-template kendoGridCellTemplate let-dataItem>
  <ng-container *ngIf="dataItem.approvedStatus?.includes('Failed') else showStatus">
    <div class="w2s-pdf-data-status" *ngIf="dataItem.w2Document?.isApproved==false && dataItem.w2Document?.status.toLowerCase() === pdfDataStatus.FAILED.toLowerCase() else showDateTime">
      <div class="w2s-pdf-data-fail"
      [popper]="failed" [popperTrigger]="'hover'" [popperPlacement]="'bottom-left'" [popperDisableStyle]="'true'" (click)="showReasonInfoPopup(dataItem)">
        <em class="fas fa-times-circle w2s-pdf-data-fail icon" aria-hidden="true"></em>
        <span>{{dataItem.w2Document?.status}}: </span>
        <popper-content #failed class="slx-popper">
          <div class="w2s-pdf-data-popper">
            <span>{{failedReason}}</span>
          </div>
        </popper-content>
      </div>
      <span>{{dataItem.w2Document?.approvedTime | amDateFormat: appConfig.dateTimeFormatUS}}</span>
    </div>
  </ng-container>
  <ng-template #showStatus><span>{{dataItem.approvedStatus}}</span></ng-template>
</ng-template>
</kendo-grid-column>

</kendo-grid>
