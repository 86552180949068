import { IShiftAppNotificationResponse, ShiftAppNotificationResponse, IShiftSmsNotificationResponse, ShiftSmsNotificationResponse } from './index';

export interface IShiftNotificationResponse {
  employeeId: string;
  appResponse: IShiftAppNotificationResponse;
  smsResponse: IShiftSmsNotificationResponse;
}

export class ShiftNotificationResponse {
  public employeeId: string;
  public appResponse: ShiftAppNotificationResponse;
  public smsResponse: ShiftSmsNotificationResponse;
}
