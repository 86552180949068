import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SummarySectionBase } from '../summary-section-base/summary-section.base';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { PositionsSummaryContainer, TimecardSummarySecondaryPosition } from '../../../models/index';
import { AggregateDescriptor, aggregateBy } from '@progress/kendo-data-query';

@Component({
    moduleId: module.id,
    selector: 'slx-timecard-summary-positions',
    templateUrl: 'positions-summary.component.html',
    styleUrls: ['positions-summary.component.scss']
})
export class PositionsSummaryComponent extends SummarySectionBase implements OnInit, OnDestroy {

    @ViewChild('kendoGrid', {static: true})
    public grid: GridComponent;

    public aggregates: AggregateDescriptor[];
    public totals: StringMap<any>;

    public dollarFormat = '$0.00';
    public numberFormat = '0.00';

    constructor(management: TimecardsSummaryManagementService) {
        super(management);
        this.aggregates = [
            { field: 'rate', aggregate: 'sum' }
            ,{ field: 'hours', aggregate: 'sum' }
        ];
    }

    public ngOnInit(): void {
        this.setupManagementService();
    }

    public ngOnDestroy(): void {
      this.destroy();
    }

    public checkDifference(item: TimecardSummarySecondaryPosition, diff: number): boolean {
        let percent: number;
        percent = Math.abs(((item.hours - item.hours) / item.hours) * 100);
        if (percent === 0) return false;
        return percent > diff;
    }

    protected onDataLoaded(): void {
        _.noop();
    }

    protected refreshGrid (): void {
        super.refreshGrid();
        this.totals = aggregateBy(this.container.records, this.aggregates);
    }

    public onExcelExport(e: any): void {
      const rows = e.workbook.sheets[0].rows;
      const cells = _.filter(rows, { type: 'footer' });
      _.forEach(_.head(cells).cells, (cell) => {
        const value = parseFloat(cell.value);
        if (_.isFinite(value)) {
          cell.value = value;
        }
      });
    }
}
