import { IServerFilterDescriptor, ServerFilterDescriptor } from './server-filter-descriptor';
import * as _ from 'lodash';

import { Assert } from '../../../framework/index';

export interface IServerCompositeFilterDescriptor {
  /**
   * The logical operation to use when the `filter.filters` option is set.
   *
   * The supported values are:
   * * `"and"`
   * * `"or"`
   */
  logic: 'or' | 'and';
  filters: IServerFilterDescriptor[];
}

export class ServerCompositeFilterDescriptor {
  public logic: 'or' | 'and';
  public filters: Array<ServerFilterDescriptor | ServerCompositeFilterDescriptor>;

}

export function isServerCompositeFilterDescriptor (source: any): source is ServerCompositeFilterDescriptor {
    return !!source.filters;
}

//export declare const isServerCompositeFilterDescriptor: (source: ServerFilterDescriptor | ServerCompositeFilterDescriptor) => source is ServerCompositeFilterDescriptor;
