import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import { Component, Input, OnInit, OnDestroy, Provider } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../../core/decorators/index';

import { ShiftReplacementManagementService } from '../../../services/index';
import { DialogOptions, IDialog, ColumnSettingsDefinitions } from '../../../../common/index';
import { ModalService, ColumnSettingsStorageService } from '../../../../common/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { ShiftReplacementRequest, ShiftReplacementSettings, ShiftEligibleEmployee, ShiftAddEmployeeCmd, ShiftReplacementSms, GroupDetailsData, DetailGroup } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { NotificationsService } from '../../../../../app/core/components';
import { ShiftOverlapHelper } from '../shift-overlap-helper';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-add-shift-open',
  templateUrl: 'employee-add-shift-open.component.html',
  styleUrls: ['employee-add-shift-open.component.scss']
})
export class EmployeeAddShiftOpenComponent extends ShiftOverlapHelper implements IDialog, OnInit, OnDestroy {
  public options: DialogOptions;
  public dialogResult: boolean;
  public checkedEmployees: ShiftEligibleEmployee[];
  public sendSmsModeOn: boolean;
  public smsData: ShiftReplacementSms;


  @unsubscribe()
  private smsModeChangeSubscription: Subscription;
  @unsubscribe()
  private selectedEmployeesSubscription: Subscription;
  @unsubscribe()
  private availabilityToSendSmsSubscription: Subscription;

  private message: string;
  private shiftManagementService: ShiftReplacementManagementService;
  private modalService: ModalService;
  private appConfig: IApplicationConfig;
  private lookupApiService: LookupApiService;
  private notificationService: NotificationsService;
  private columnSettingsStorageService: ColumnSettingsStorageService;

  public static openDialog(data: GroupDetailsData, unitId: number, modalService: ModalService, callback: (result: boolean, cmd: ShiftAddEmployeeCmd) => void): EmployeeAddShiftOpenComponent {
    const request = data.request;
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.minHegiht = 550;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: GroupDetailsData,
        useValue: data
      }
    ];
    let header: string = `Add employee to shift ${request.shiftName} ${moment(request.date).format(appConfig.serverDateFormat)}`;
    let dialog: EmployeeAddShiftOpenComponent = modalService.globalAnchor
      .openDialog2(EmployeeAddShiftOpenComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        let cmd: ShiftAddEmployeeCmd;
        if (result) {
          cmd = new ShiftAddEmployeeCmd();
          cmd.selectedEmployee = _.head(dialog.selectedEmployees);
          cmd.dateOn = request.date;
          cmd.shiftId = request.shiftId;
          cmd.positionId = cmd.selectedEmployee.position.id;
          cmd.unitId = unitId;
        }
        callback(result, cmd);
      });
    return dialog;
  }

  constructor(
    options: DialogOptions,
    modalService: ModalService,
    lookupApiService: LookupApiService,
    data: GroupDetailsData,
    columnSettingsStorageService: ColumnSettingsStorageService,
    shiftManagementService: ShiftReplacementManagementService,
    notificationService: NotificationsService
  ) {
    super();
    this.modalService = modalService;
    this.options = options;
    this.lookupApiService = lookupApiService;
    this.notificationService = notificationService;
    this.columnSettingsStorageService = columnSettingsStorageService;
    this.shiftManagementService = shiftManagementService;
    this.smsData = new ShiftReplacementSms();
    this.settings = new ShiftReplacementSettings();
    this.settings.createColumns();
    this.settings.request = data.request;
    this.groupData = data.groups;
    this.previousDayGroupData = data.previousDayGroups;
    this.nextDayGroupData = data.nextDayGroups;
    this.settings.isMultipleSelectionMode = false;
    this.settings.context = ColumnSettingsDefinitions.SHIFT_REPLACEMENT_ADD_EMPLOYEE;
    this.settings.columns = this.columnSettingsStorageService.getColumnsState(this.settings.context.toString(), undefined, this.settings.columns);
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.selectedEmployeesSubscription = this.shiftManagementService.subscribeToSelectedEmployees((selected: ShiftEligibleEmployee[]) => {
      this.selectedEmployees = selected;
    });
    this.smsModeChangeSubscription = this.shiftManagementService.subscribeToChangeSmsMode((sendSmsOn: boolean) => {
      this.sendSmsModeOn = sendSmsOn;
    });
    this.availabilityToSendSmsSubscription = this.shiftManagementService
      .subscribeToChangeAvailabilityToSendSms((smsData: ShiftReplacementSms) => {
        this.smsData = smsData;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    console.info('Destroy Add Employee popup');
  }

  public get isAddDisabled(): boolean {
    return !(this.selectedEmployees && this.selectedEmployees.length > 0);
  }

  public onCancelSend(): void {
    this.shiftManagementService.changeSmsMode(false);
  }

  public onSend(): void {
    this.shiftManagementService.sendSms(this.smsData);
  }

  public onCancelAdd(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onAdd(): void {
    if (this.selectedEmployees) {
      if (this.isCurrentDayShiftOverlapped() 
          || this.isPreviousDayShiftOverlapped() 
          || this.isNextDayShiftOverlapped()
          || this.isPrimarySecondaryShiftsOverlap()) {
        this.notificationService.warn('Overlap', 'This employee has an overlap shift');
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
        return;
      }
      else {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
      }
    }
  }
}
