import { Component, Provider } from '@angular/core';
import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { Timeclock, TimeclockRemoveRequest } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-remove-dialog',
  templateUrl: 'timeclock-remove-dialog.component.html',
  styleUrls: ['timeclock-remove-dialog.component.scss']
})
export class TimeclockRemoveDialogComponent {
  public timeclockRemoveRequest: TimeclockRemoveRequest;
  public options: DialogOptions;
  public dialogResult: boolean;
  private modalService: ModalService;
  public static openDialog(req: TimeclockRemoveRequest, modalService: ModalService, callback: (result: boolean, cmd: TimeclockRemoveRequest) => void): TimeclockRemoveDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 370;
    dialogOptions.showCloseButton = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: TimeclockRemoveRequest,
        useValue: req
      }
    ];
    let dialog: TimeclockRemoveDialogComponent = modalService.globalAnchor
      .openDialog(TimeclockRemoveDialogComponent, 'Please setup removing options', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.timeclockRemoveRequest);
      });
    return dialog;
  }

  constructor(options: DialogOptions, modalService: ModalService, req: TimeclockRemoveRequest) {
    this.options = options;
    this.modalService = modalService;
    this.timeclockRemoveRequest = req;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}



