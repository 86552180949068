import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import { PopoverContentComponent } from '../../../../../common/index';
import { Lookup, Employee } from '../../../../../organization/models/index';
import { WindowRef } from '../../../../../core/services/window/window-ref.model';
import { EmployeeSectionNavigationService } from '../../../../../common/services/index';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { LoaType, LoaRepeat, LoaRequest } from '../../../models/index';
import { unsubscribe } from '../../../../../core/decorators';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-create-absence',
  templateUrl: 'lm-create-absence.component.html',
  styleUrls: ['lm-create-absence.component.scss']
})
export class LMCreateAbsenceComponent implements OnInit {
  public empId: number;
  public empName: string;
  public posName: string;
  public depName: string;
  public orgName: string;
  public employeesLookup: Lookup;
  public absenceTypes: LoaType[];
  public absenceType: LoaType = null;
  public hasAbsenceTypes: boolean = true;
  public reason: string;
  public requestDate: Date;

  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  public get canDeleteRequest(): boolean {
    return this.manService.canDeleteRequest && !this.manService.isCreatingNew;
  }

  public get isCreatingNew(): boolean {
    return this.manService.isCreatingNew;
  }

  public get isContinous(): boolean {
    return this.manService.isContinuous;
  }

  public get isIntermittent(): boolean {
    return this.manService.isIntermittent;
  }



  private empNavService: EmployeeSectionNavigationService;

  @unsubscribe()
  private loadSubscription: Subscription;

  constructor(
    private manService: LMCreationAbsenceManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private win: WindowRef
  ) {
    this.empNavService = new EmployeeSectionNavigationService(this.router, this.route);
  }

  public ngOnInit(): void {

    this.setRequestData();

    this.loadSubscription = this.manService.subscribeToChangedRequest((r: LoaRequest) => {
      this.setRequestData();
    });

  }

  public getSelectedTab(tabType :string): boolean {
    if (this.manService.loaRequest.loaRequestTabs.valueOf() === tabType) {
      return true;
    }
    return false;
  }

  public onClickEmployee(): void {
    const urlTree = this.empNavService.getUrlTree(this.empId, true);
    const url = `${this.win.nativeWindow.document.location.origin}/#/apps/common${urlTree.toString()}`;
    this.win.nativeWindow.open(url, '_blank');
  }

  public async onClickDelete(acceptPopover: PopoverContentComponent): Promise<void> {
    acceptPopover.hide();
    this.manService.changeLoading(true);
    try {
      await this.manService.deleteLoaRequest();
    } catch (e) {
      console.error(e);
    } finally {
      this.manService.closePopup();
      this.manService.loadRequests();
    }
  }

  public onClickCancel(acceptPopover: PopoverContentComponent): void {
    acceptPopover.hide();
  }

  public onChangeAbsence(type: LoaType): void {
    this.manService.setAbsenceType(type);
  }

  public onChangeReason(reason: string): void {
    this.manService.setAbsenceReason(reason);
  }

  public onChangeRequestDate(date: Date): void {
    this.manService.setRequestDate(date);
  }

  private setRequestData(): void {
    this.assignRequestDate();
    this.assignAbsenceTypes();
    this.assignReason();
    this.assignEmployee();
  }

  private async assignAbsenceTypes(): Promise<void> {
    const absenceType = this.manService.getAbsenceType() || new LoaType();
    this.absenceTypes = await this.loadAbsenceTypes();
    this.absenceType = _.find(this.absenceTypes, (a) => a.name === absenceType.name);

    this.hasAbsenceTypes = _.isArray(this.absenceTypes) && _.size(this.absenceTypes) > 0;
  }

  private assignReason(): void {
    this.reason = this.manService.getAbsenceReason() || '';
  }

  private assignRequestDate(): void {
    this.requestDate = this.manService.getRequestDate() || new Date();
  }

  private assignEmployee(): void {
    const req = this.manService.loaRequest;
    this.empId = req.empId;
    this.empName = req.empName;
    this.posName = req.positionName;
    this.depName = req.departmentName;
    this.orgName = req.organizationName;
  }

  private async loadAbsenceTypes(): Promise<LoaType[]> {
    return this.manService.loadAbsenceTypes()
      .catch((err) => {
        console.error(err);

        return [];
      });
  }
}
