<ag-grid-angular style="width: 100%; height: 100%;" class="ag-theme-balham"
                 [gridOptions]="gridOptions"
                 [columnDefs]="gridHelper.columnDefs"
                 [defaultColDef]="gridHelper.defaultColumnDefs"
                 [rowData]="rowData"
                 [pagination]="true"
                 [paginationPageSize]="50"
                 [frameworkComponents]="gridHelper.frameworkComponents"
                 [getContextMenuItems]="getContextMenuItems"
                 [getRowHeight]="getRowHeight"
                 (gridReady)="onGridReady($event)"
                 (cellClicked)="onCellClicked($event)"  
                 (cellDoubleClicked)="onCellDoubleClick($event)"          
                 >
</ag-grid-angular>



