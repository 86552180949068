import { Department, EmployeeShortInfo, IDepartment, IEmployeeShortInfo, IPosition, Position } from '../../../organization/models';
import { appConfig } from '../../../../app/app.config';
import * as moment from 'moment';
import { PdfDataStatus } from '../../../reports/enums/pdf-data-status';
export interface IW2sInfo {
    publishW2s: boolean;
    unPublishW2s: boolean;
    records:IW2sRecord[];
    
}
export class W2sInfo {
    public publishW2sAction:boolean;
    public unPublishW2sAction:boolean;
    public records: W2sRecord[];
     
}
export interface IW2sRecord {
    employee: IEmployeeShortInfo;
    department: IDepartment;
    position: IPosition;
    w2Document:Iw2Document;
    
}

export class W2sRecord {
    public employee = new EmployeeShortInfo();
    public department = new Department();
    public position = new Position();
    public w2Document = new w2Document(); 
    public get approvedStatus(): string {
        if(this.w2Document.isApproved==0 && this.w2Document.status.toLowerCase() !== PdfDataStatus.FAILED.toLowerCase()){
            return "No";
        }
        else if(this.w2Document.isApproved==0 && this.w2Document.status.toLowerCase() === PdfDataStatus.FAILED.toLowerCase()){
            return (this.w2Document.status+": "+ moment(this.w2Document.approvedTime).format(appConfig.dateTimeFormatUS));
        }
        else{
            return moment(this.w2Document.approvedTime).format(appConfig.dateTimeFormatUS);
        }
    } 
}

export class w2Document{
    public id:number;
    public exportId:number;
    public costCenter:string;     
    public w2DocumentTitle:string;
    public w2DocumentFile:string;
    public isApproved :number;
    public approvedTime:Date;
    public status:string;
}
export class Iw2Document{
    id:number;
    exportId:number;
    costCenter:string;  
    w2DocumentTitle:string;
    w2DocumentFile:string;
    isApproved :number;
    approvedTime:Date;
    status:string;
}

export class CompanyCounts{
    public companieswithW2Data: number;
    public companiesFinalized: number;
    public companiesinProgress: number;
}

export interface ICompanyCounts{
    companieswithW2Data: number;
    companiesFinalized: number;
    companiesinProgress: number;
}

export interface IPublishType{
    publish: boolean;
    unPublish: boolean;
}