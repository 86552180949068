import { OnInit, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { orderBy } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { OrgLevel } from '../../../../state-model/models/index';
import { PunchAttestationGroup } from '../../models/punch-attestation-group';
import { PunchAttestationManagementService } from '../../services/index';
var PunchAttestationSectionComponent = /** @class */ (function () {
    function PunchAttestationSectionComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.appConfig = appConfig;
        this.areGroupNamesValid = true;
        this.isEditingQuestion = [];
        this.MAX_QUESTIONS_PER_GROUP = 20;
        this.MAX_QUESTION_PER_GROUP_FOR_LEGACY = 4;
        this.sort = [];
    }
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    PunchAttestationSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.group.questions.map(function (question) {
            question.restrictedAnswerId = _this.restrictedAnswerLookup.find(function (x) { return x.restrictedAnswerId == question.restrictedAnswerId; });
            question.punchType = _this.punchAttestationPunchType.find(function (x) { return x.id == question.punchType; });
            question.defaultAnswer = _this.punchAttestationAnswerCodes.find(function (x) { return x.id == question.defaultAnswer; });
            question.attestationTypeId = _this.attestationTypeLookup.find(function (x) { return x.attestationTypeId == question.attestationTypeId; });
            return question;
        });
        this.refreshGrid();
    };
    PunchAttestationSectionComponent.prototype.save = function () {
        if (this.isFormValid && this.isGroupNameEntered) {
            this.group.questions = this.group.questions.filter(function (x) { return x !== null; });
            this.managementService.saveGroup(this.orgLevel, this.group);
        }
    };
    PunchAttestationSectionComponent.prototype.addQuestion = function () {
        if (this.isAbleToAddQuestions && this.isFormValid && this.isGroupNameEntered) {
            var length = this.group.questions.length;
            var question = {
                id: 0,
                order: this.group.questions.reduce(function (op, item) { return op = op > item.order ? op : item.order; }, 0) + 1,
                textLine1: '',
                textLine2: '',
                defaultAnswer: 0,
                punchType: null,
                attestationTypeId: null,
                restrictedAnswerId: 0,
                enable: false
            };
            this.setLocalDataItem(question);
            this.group.questions.push(question);
            this.refreshGrid();
            this.grid.editRow(length);
        }
    };
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "isGroupNameEntered", {
        get: function () {
            return this.group.groupName !== null && this.group.groupName.trim() !== '' ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "isAbleToAddQuestions", {
        get: function () {
            return this.group.questions ? this.group.questions.length < (this.isLegacyClockGroup ? this.MAX_QUESTION_PER_GROUP_FOR_LEGACY : this.MAX_QUESTIONS_PER_GROUP) : false;
        },
        enumerable: true,
        configurable: true
    });
    PunchAttestationSectionComponent.prototype.delete = function (e) {
        if (e.pointerType === 'mouse') {
            this.managementService.deleteGroup(this.orgLevel, this.group);
        }
    };
    PunchAttestationSectionComponent.prototype.deleteQuestion = function (question) {
        if (question.id === null || question.id === 0) {
            this.group.questions = this.group.questions.filter(function (x) { return x.order !== question.order; });
        }
        else {
            this.managementService.deleteQuestion(this.orgLevel, this.group, question);
        }
        this.refreshGrid();
    };
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "areGroupNameValid", {
        get: function () {
            return this.managementService.areGroupNameValid(this.group);
        },
        enumerable: true,
        configurable: true
    });
    PunchAttestationSectionComponent.prototype.attestationTypeName = function (attestationTypeId) {
        return this.attestationTypeLookup && attestationTypeId !== null ? this.attestationTypeLookup.find(function (x) { return x.attestationTypeId === attestationTypeId.attestationTypeId; }).attestationTypeName : '';
    };
    PunchAttestationSectionComponent.prototype.restrictedAnswerName = function (restrictedAnswerId) {
        return this.restrictedAnswerLookup && restrictedAnswerId !== 0 ? this.restrictedAnswerLookup.find(function (x) { return x.restrictedAnswerId === restrictedAnswerId; }).restrictedAnswerName : '';
    };
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "isLegacyClockGroup", {
        get: function () {
            return this.group ? this.group.groupName.toLocaleLowerCase() === this.managementService.legacyclockquestiongroup.toLocaleLowerCase() : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "getmaxlengths", {
        get: function () {
            return this.isLegacyClockGroup ? 50 : 500;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "isFormValid", {
        get: function () {
            return this.form.valid;
        },
        enumerable: true,
        configurable: true
    });
    PunchAttestationSectionComponent.prototype.sortChangeHandler = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    PunchAttestationSectionComponent.prototype.refreshGrid = function () {
        this.gridData = {
            data: orderBy(this.group.questions, this.sort),
            total: this.group.questions.length
        };
    };
    PunchAttestationSectionComponent.prototype.removeHandler = function (event) {
        this.deleteQuestion(event.dataItem);
    };
    PunchAttestationSectionComponent.prototype.editHandler = function (event) {
        this.setLocalDataItem(event.dataItem);
        this.grid.editRow(event.rowIndex);
    };
    PunchAttestationSectionComponent.prototype.saveHandler = function (event) {
        if (this.isFormValid) {
            this.grid.closeRow(event.rowIndex);
        }
    };
    PunchAttestationSectionComponent.prototype.cancelHandler = function (event) {
        if (event.dataItem.id !== 0) {
            var index = this.group.questions.findIndex(function (x) { return x.id == event.dataItem.id; });
            this.group.questions[index] = this.editedDataItem;
        }
        else {
            var index = this.group.questions.findIndex(function (x) { return x.order == event.dataItem.order; });
            this.group.questions[index] = this.editedDataItem;
        }
        this.grid.closeRow(event.rowIndex);
    };
    PunchAttestationSectionComponent.prototype.setLocalDataItem = function (dataItem) {
        this.editedDataItem = {
            id: dataItem.id,
            attestationTypeId: dataItem.attestationTypeId,
            defaultAnswer: dataItem.defaultAnswer,
            enable: dataItem.enable,
            order: dataItem.order,
            punchType: dataItem.punchType,
            restrictedAnswerId: dataItem.restrictedAnswerId,
            textLine1: dataItem.textLine1,
            textLine2: dataItem.textLine2,
        };
    };
    Object.defineProperty(PunchAttestationSectionComponent.prototype, "isconfigure", {
        get: function () {
            return this.managementService.configurePunchAttestation.getValue() ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    return PunchAttestationSectionComponent;
}());
export { PunchAttestationSectionComponent };
