import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { PopperContent } from 'ngx-popper';
var ActionListItemComponent = /** @class */ (function () {
    function ActionListItemComponent(popper) {
        this.popper = popper;
        this.fullyCustomStyle = false;
        this.defaultCssClasse = 'slx-action-list-item';
        this.hidden = false;
        this.fullyCustomStyle = false;
        this.popper = popper;
        this.onClick = new EventEmitter();
    }
    ActionListItemComponent.prototype.onMouseup = function (event) {
        if (!this.isDisabled) {
            this.onClick.emit(event);
            this.popper.hide();
        }
    };
    Object.defineProperty(ActionListItemComponent.prototype, "className", {
        get: function () {
            var classes = [];
            if (_.isString(this.customClassName) && _.size(this.customClassName) > 0) {
                classes.push(this.customClassName);
            }
            if (!this.fullyCustomStyle) {
                classes.push(this.defaultCssClasse);
            }
            if (this.isDisabled) {
                classes.push('disabled');
            }
            return classes.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    return ActionListItemComponent;
}());
export { ActionListItemComponent };
