<div class="searc-employee">
  <h5 class="searc-employee__h5">{{instructions}}</h5>
  <div class="searc-employee__list search-flex align-items__center">
    <label *ngIf="isShownEmpNameLabel" class="searc-employee__label">Employee Name:</label>
    <span class="flex__grow">
      <slx-autocomplete class="slx-wide" [lookup]="employeesLookup" [(ngModel)]="employee"
        (ngModelChange)="onEmployeeChanged($event)" [strictSearch]="false" rightIcon="fas fa-search" name="employee"
        [virtual]="virtual" [readonly]="readonly">
        <ng-template slxAutocompleteItem let-item="item">
          <p class="emp-item flex align-items__center" *ngIf="!isMobile">
            <img class="emp-item__icon" [employeeThumbinalSrc]="item.id" alt="Employee Photo" />
            <span class="emp-item__info flex__grow">
              <strong class="emp-item__name">{{ item.name }}</strong>
              <strong class="emp-item__field" *ngIf="!hideDetails && item.position">Position:&nbsp;</strong>
              <span class="emp-item__value" *ngIf="!hideDetails && item.position">{{ item.position?.name }}</span>
              <strong class="emp-item__field" *ngIf="!hideDetails && item.department">Department:&nbsp;</strong>
              <span class="emp-item__value" *ngIf="!hideDetails && item.department">{{ item.department?.name }}</span>
              <strong class="emp-item__field" *ngIf="!hideDetails && item.organization">Facility:&nbsp;</strong>
              <span class="emp-item__value" *ngIf="!hideDetails && item.organization">{{ item.organization?.name }}</span>
            </span>
          </p>

          <p class="emp-item flex align-items__center" *ngIf="isMobile">
            <img class="emp-item__icon" [employeeThumbinalSrc]="item.id" alt="Employee Photo" />
            <span class="emp-item__info flex__grow">

              <strong class="emp-item__name">{{ item.name }}</strong>
              <strong class="emp-item__field" *ngIf="!hideDetails && item.position">Position:&nbsp;</strong>
              <span class="emp-item__value" *ngIf="!hideDetails && item.position">{{ item.position?.name }}</span>

              <span class="emp-item-wrap" *ngIf="!hideDetails && item.department">
                <strong class="emp-item__field">Department:&nbsp;</strong>
                <span class="emp-item__value">{{ item.department?.name }}</span>
              </span>
              <span class="emp-item-wrap" *ngIf="!hideDetails && item.organization">
                <strong class="emp-item__field">Facility:&nbsp;</strong>
                <span class="emp-item__value">{{ item.organization?.name }}</span>
              </span>
            </span>
          </p>



        </ng-template>
      </slx-autocomplete>
    </span>
  </div>
</div>
