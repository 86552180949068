/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-editable-photo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../organization/directives/employee-image-src/employee-image-src.directive";
import * as i4 from "../../../../../organization/services/employee/employee-definitions-api.service";
import * as i5 from "./employee-editable-photo.component";
import * as i6 from "../../../../../common/services/modal/modal.service";
import * as i7 from "../../../../../organization/services/employee/employee-images.service";
var styles_EmployeeEditablePhotoComponent = [i0.styles];
var RenderType_EmployeeEditablePhotoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeEditablePhotoComponent, data: {} });
export { RenderType_EmployeeEditablePhotoComponent as RenderType_EmployeeEditablePhotoComponent };
function View_EmployeeEditablePhotoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "emp-photo-edit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditPhoto() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Edit "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "emp-photo-edit-icon"]], null, null, null, null, null))], null, null); }
export function View_EmployeeEditablePhotoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { employeePhotoElem: 0 }), i1.ɵqud(402653184, 2, { employeeImageDirective: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "emp-photo-box"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "emp-photo-box-edit": 0 }), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["employeePhotoElem", 1]], null, 1, "img", [["alt", "Employee Photo"], ["class", "box-image"]], null, null, null, null, null)), i1.ɵdid(7, 540672, [[2, 4]], 0, i3.EmployeeImageSrcDirective, [i1.ElementRef, i1.Renderer, i4.EmployeeDefinitionsApiService], { employeeImageSrc: [0, "employeeImageSrc"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "emp-photo-edit-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeEditablePhotoComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "emp-photo-box"; var currVal_1 = _ck(_v, 5, 0, _co.canEdit); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.employeeId; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.canEdit; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_EmployeeEditablePhotoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-editable-photo", [], null, null, null, View_EmployeeEditablePhotoComponent_0, RenderType_EmployeeEditablePhotoComponent)), i1.ɵdid(1, 49152, null, 0, i5.EmployeeEditablePhotoComponent, [i6.ModalService, i4.EmployeeDefinitionsApiService, i7.EmployeeImagesService], null, null)], null, null); }
var EmployeeEditablePhotoComponentNgFactory = i1.ɵccf("slx-employee-editable-photo", i5.EmployeeEditablePhotoComponent, View_EmployeeEditablePhotoComponent_Host_0, { employeeId: "employeeId", canEdit: "canEdit" }, {}, []);
export { EmployeeEditablePhotoComponentNgFactory as EmployeeEditablePhotoComponentNgFactory };
