import { Injectable } from '@angular/core';
import { SortDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Assert } from '../../../framework/index';
import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';
import { ServerCompositeFilterDescriptor, ServerFilterDescriptor, isServerCompositeFilterDescriptor, ServerQuery, PagingData } from '../../models/index';
import * as kendoUIItils from '../../utils/kendo-ui-utils';

export function removeFilter(cfilter: ServerCompositeFilterDescriptor, field: string): void {
  cfilter.filters = cfilter.filters || [];
  _.forEach(cfilter.filters, (f: ServerCompositeFilterDescriptor | ServerFilterDescriptor) => {
    if (isServerCompositeFilterDescriptor(f)) {
      removeFilter(f, field);
    }
  });
  _.remove(cfilter.filters, (x: ServerFilterDescriptor | ServerCompositeFilterDescriptor) => {
    if (isServerCompositeFilterDescriptor(x)) {
      return x.filters.length === 0;
    } else {
      return x.field === field;
    }
  });
}


export function addFilter(cfilter: ServerCompositeFilterDescriptor, filter: ServerCompositeFilterDescriptor | ServerFilterDescriptor): void {
  cfilter.filters = cfilter.filters || [];
  cfilter.filters.push(filter);
}

export function addFieldToFilter(cfilter: ServerCompositeFilterDescriptor, field: string, operator: string, value: any): void {
  if (!field || !operator || !value) {
    return;
  }
  let fl: ServerFilterDescriptor = new ServerFilterDescriptor();
  fl.field = field;
  fl.operator = operator;
  fl.value = value;
  addFilter(cfilter, fl);
}

export function removeFieldFromFilterWithValue(cfilter: ServerCompositeFilterDescriptor, field: string, value: any, operator: string = null): void {
  if (!field || !value) {
    return;
  }

  let flatFilters: ServerFilterDescriptor[] = kendoUIItils.flattenFilters(cfilter);
  _.remove(flatFilters, x => {
    x.field === field && x.value === value && (!operator || x.operator === operator)
  })
}

export function composeObjectToFilter(cfilter: ServerCompositeFilterDescriptor, obj: any): void {
  _.forIn(obj, (value: any, key: string) => {
    kendoUIItils.composeFieldToFilter(cfilter, key, 'eq', value);
  });
}

export function addObjectToFilter(cfilter: ServerCompositeFilterDescriptor, obj: any): void {
  _.forIn(obj, (value: any, key: string) => {
    addFieldToFilter(cfilter, key, 'eq', value);
  });
}
export function flatten(filter: ServerCompositeFilterDescriptor): any[] {
  if (filter.filters) {
    return _.reduce(filter.filters, (acc: any, curr: ServerFilterDescriptor) => {
      return acc.concat([curr]);
    }, []);
  }
  return [];
}

export function extractFieldFilter(filter: ServerCompositeFilterDescriptor, fieldName: string): ServerFilterDescriptor {
  let flatFilters: ServerFilterDescriptor[] = kendoUIItils.flattenFilters(filter);
  return _.find(flatFilters, (f: ServerFilterDescriptor) => f.field === fieldName);
}

export function extractFieldFilters(filter: ServerCompositeFilterDescriptor, fieldName: string): ServerFilterDescriptor[] {
  let flatFilters: ServerFilterDescriptor[] = kendoUIItils.flattenFilters(filter);
  return _.filter(flatFilters, (f: ServerFilterDescriptor) => f.field === fieldName);
}

export function extractFilterValue(filter: ServerCompositeFilterDescriptor, name: string): any {
  const valueFilter: ServerFilterDescriptor = extractFieldFilter(filter, name);
  //Assert.isNotNull(valueFilter, name, 'unknown filer value');
  if (valueFilter) {
    return valueFilter.value;
  }
  return null;
}

export function extractFilterValues(filter: ServerCompositeFilterDescriptor, name: string): any[] {
  const valueFilters: ServerFilterDescriptor[] = extractFieldFilters(filter, name);
  return _.map(valueFilters, x => x.value);
}

@Injectable()
export class ServerFilterService {
  public changes$: Subject<ServerCompositeFilterDescriptor>;
  public currentFilter: ServerCompositeFilterDescriptor;

  constructor() {
    this.changes$ = new Subject();
    this.clearFilter();
  }

  public clearFilter(): void {
    this.currentFilter = new ServerCompositeFilterDescriptor();
    this.currentFilter.filters = [];
    this.currentFilter.logic = 'and';
  }

  public applyFilter(): void {
    this.changes$.next(this.currentFilter);
  }

  public composeFilter(filter: ServerFilterDescriptor): void {
    kendoUIItils.composeFilter(this.currentFilter, filter);
  }

  public composeFromKendoFilter(kf: CompositeFilterDescriptor): void {
    kendoUIItils.composeFromKendoFilter(this.currentFilter, kf);
  }

  public removeFilter(field: string): void {
    removeFilter(this.currentFilter, field);
  }

  public extractFilterValue(field: string): any {
    return extractFilterValue(this.currentFilter, field);
  }

  public extractFilterValues(field: string): any[] {
    return extractFilterValues(this.currentFilter, field);
  }

  public composeObjectToFilter(obj: any): void {
    composeObjectToFilter(this.currentFilter, obj);
  }
  public addObjectToFilter(obj: any): void {
    addObjectToFilter(this.currentFilter, obj);
  }
  public addFilter(filter: ServerCompositeFilterDescriptor| ServerFilterDescriptor): void {
    addFilter(this.currentFilter, filter);
  }

  public createQuery(pagingData: PagingData, sort?: SortDescriptor[]): ServerQuery {
    let q: ServerQuery = new ServerQuery();
    if (pagingData) {
      q.skip = pagingData.skip;
      q.take = pagingData.take;
    } else {
      q.skip = 0;
      q.take = 50;
    }
    if (sort) q.sort = sort;
    q.filter = this.currentFilter;
    return q;
  }
}

