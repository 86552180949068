<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents cp-header">
      <div class="flex create-new-position-block" >
        <button type="button"
          class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"
          [slx-list-add-operation]="onAddItem"
        >
          <i class="fa fa-plus slx-button__icon" aria-hidden="true"></i>
          <span class="slx-button__text">Create New Position</span>
        </button>
        <button type="button"
          class="slx-button slx-no-breaks"
          *ngIf="management.canManageGroups"
          [disabled]="management.access.restrictedByOrgLevel"
          (click)="onManageGroups()"
        >Manage Groups</button>
      </div>

      <div class="flex align-items__center justify-content__flex-end ">
        <div *ngIf="!management.editingItem" [ngClass]="{'inactive': management.access.lockActions}">
          <button type="button"
            class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"
            [disabled]="!management.access.canEdit"
            (click)="onCopyItems ($event)"
          >
            <i class="fas fa-copy slx-button__icon" aria-hidden="true"></i>
            <span class="slx-button__text">Copy</span>
          </button>
          <div class="col md-no-line-column">
            <span
              *ngIf="management.access.restrictedByOrgLevel"
              class="edit-info-warning"
            >Positions are editable only at Department level</span>
          </div>
        </div>

        <div class="position-action-cls" *ngIf="!isMobile">
          <slx-action-list class="slx-wide">
            <slx-action-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions <span class="caret"></span></slx-action-button>
            <popper-content #popperContent>
              <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
              <slx-actions-list-item (onClick)="onExportToPdf()">Export to PDF</slx-actions-list-item>
            </popper-content>
          </slx-action-list>
        </div>
      </div>
    </div>

    <div class="slx-high-box__body" *ngIf="state.configureMode">

      <ng-container *ngIf="management.editingItem">
        <slx-configure-position-editor class="slx-high-box__body position-editor" [item]="management.editingItem" [isAddPosition] = "isAddPosition" [isSupervisorEnabled] = "appSetting?.isSupervisorEnabled" (actionEmitter)="extenalEditorAction ($event)">
        </slx-configure-position-editor>
      </ng-container>

      <form #templateForm="ngForm" novalidate *ngIf="!management.editingItem"
        class="slx-high-box__body slx-main-content-indents">
        <kendo-grid #kendoGrid class="slx-full-height position-grid"
          [data]="gridState?.view"
          [selectable]="{ mode: 'single' }"
          [filterable]="'menu'"
          [groupable]="false"
          [group]="groups"
          [sort]="gridState.state.sort"
          [sortable]="{ mode: 'multiple' }"
          [filter]="gridState.state.filter"
          [resizable]="true"
          (selectionChange)="gridState.selectionChange($event)"
          (dataStateChange)="gridState.dataStateChange($event)"
          (cancel)="gridState.cancelHandler($event)"
          (edit)="gridState.editHandler($event)"
          (remove)="gridState.removeHandler($event)"
          (save)="gridState.saveHandler($event)"
          [scrollable]="'virtual'"
          (excelExport)="onExcelExport($event)"
          >
          <!-- mobile view -->
          <kendo-grid-column media="(max-width: 450px)" width="100%">
            <ng-template kendoGridHeaderTemplate></ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <slx-configure-position-renderer [rowIndex]="rowIndex" [item]="dataItem"
                (actionEmitter)="onMobileRendererEvent ($event, dataItem, rowIndex)"></slx-configure-position-renderer>
            </ng-template>
          </kendo-grid-column>



          <kendo-grid-command-column title="Edit" [width]="120" [minResizableWidth]="100" *ngIf="!management.access.restrictedByOrgLevel"
            [locked]="true" media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
              <button kendoGridEditCommand [primary]="true">
                <i class="fas fa-edit" aria-hidden="true"></i>
              </button>
              <button *ngIf="dataItem?.isSystemLogin" kendoGridRemoveCommand (click)="onDeletedItems()">
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
              <span class="wrapper">
              <button type="button" *ngIf="!dataItem?.isSystemLogin" class="k-button pointer-events-none">
                <i class="fas fa-trash" aria-hidden="true"></i>
              </button>
              </span>
              <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid">
                <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand type="button">
                <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
              </button>
            </ng-template>
          </kendo-grid-command-column>

          <kendo-grid-column title="Position Group" field="positionGroupName" [filterable]="true" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridGroupHeaderTemplate let-value="value">
              {{value}}
            </ng-template>
            <ng-template kendoGridGroupHeaderTemplate let-value="value" [ngIf]="!positionGroupName">
              UNDEFINED
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Name" field="name" [filterable]="true" width="200" media="(min-width: 450px)">
            <ng-template kendoGridGroupHeaderTemplate let-value="value">
              {{value}}
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Client Dept" field="clientDepartment" [filterable]="true" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align">{{ dataItem.clientDepartment }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Job Class" field="jobClass" [filterable]="true" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align">{{ dataItem.jobClass }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Business Unit" field="businessUnit" [filterable]="true" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align">{{ dataItem.businessUnit }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Object Account" field="objectAccount" [filterable]="true" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align"> {{ dataItem.objectAccount }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Group With" field="groupedWithName" [filterable]="true" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align">{{ dataItem.groupedWithName }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Budgeted Position" field="budgetedPositionName" [filterable]="true" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align"> {{ dataItem.budgetedPositionName }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Direct Care" field="isDirectCare" [filterable]="true" filter="boolean" width="200"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span *ngIf="dataItem.isDirectCare">
                <i class="fa fa-check" aria-hidden="true"></i>
              </span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
              </slx-kendo-grid-boolean-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="CMS - PBJ Job Category" field="pbjCmsPositionName" [filterable]="true" width="210"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align">{{ dataItem.pbjCmsPositionName }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Ess Template" field="essTemplate.name" [filterable]="true" width="200"
            [hidden]="!essTemplatesEnabled" media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align">{{ dataItem.essTemplate?.name }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="Has License Restrictions" field='isPositionLicense' [filterable]="true" width="250"
            media="(min-width: 450px)">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span class="left-align"> {{ dataItem.isPositionLicense }}</span>
            </ng-template>
            <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
              <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
              </slx-kendo-grid-string-filter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column-group title="Updated" [width]="600" [locked]="false" media="(min-width: 450px)">
            <kendo-grid-column title="Updated By" field="lastUpdateUsername" [filterable]="true" width="200"
              media="(min-width: 450px)" [locked]="false">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">{{ dataItem.lastUpdateUsername }}</span>
              </ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                </slx-kendo-grid-string-filter>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="lastUpdateDate" title="Last Updated" [width]="300" [locked]="false">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align"
                  *ngIf="dataItem.lastUpdateDate">{{ dataItem.lastUpdateDate | amDateFormat: appConfig.dateTimeFormat }}</span>
              </ng-template>
              <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                </slx-kendo-grid-date-filter>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid-column-group>

          <kendo-grid-excel fileName="Positions.xlsx" [fetchData]="getGridData()"></kendo-grid-excel>
          <kendo-grid-pdf fileName="Positions.pdf" paperSize="A0" [landscape]="true" [allPages]="true" [repeatHeaders]="true">
            <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
            <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
              <div class="page-template">
                <div class="header">
                  <h4>{{exportedFileHeader}}</h4>
                </div>
                <div class="footer">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
            </ng-template>
          </kendo-grid-pdf>
        </kendo-grid>
      </form>
    </div>

    <div class="copy-content" *ngIf="state.copyMode">
      <slx-configure-copy-positions [positions]="management.container?.records" (onDiscard)="switchToConfigure ()">
      </slx-configure-copy-positions>
    </div>
  </div>
</slx-spinner>
