import { GenericFieldDefinition } from '../../../../organization/models/generic-list/generic-field-definition';
import { GenericListDefinition } from '../../../../organization/models/generic-list/generic-list-definition';
import { Injectable } from '@angular/core';

import { Employee, EmployeeListItem, EmployeeActionDefinition, EmployeeFieldDefinition, EmployeeColumnsDefinition, EmployeeActionDefinitions, IEmployeeActionDefinitions } from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';

import * as _ from 'lodash';

export type EmployeeData = { fieldName: string, value: string };

@Injectable()
export class EmployeeListMapService {
  public mapToEmployee(data: EmployeeData[], itemDef: EmployeeColumnsDefinition, listId?: number): Employee {
    if(listId === 5){
      data.map((callLog) => {
        if(callLog.fieldName.toLocaleLowerCase() === "contactmessage" && callLog.value){
          callLog["value"] = callLog.value.replace(/ {2,}/g, ' ').trim();
        } 
      })
    }
    let map: StringMap<EmployeeFieldDefinition> = _.keyBy(itemDef.fields, (fieldDef: EmployeeFieldDefinition) => {
      return fieldDef.fieldName;
    });
    let employee: Employee = new Employee();
    _.each(data, (employeeData: EmployeeData) => {
      let def: EmployeeFieldDefinition = map[employeeData.fieldName];
      if (def) {
        if (def.fieldType === 'date') {
          if (def.masked) {
            employee[employeeData.fieldName] = employeeData.value;
          }
          else if (employeeData.value) {
            employee[employeeData.fieldName] = dateTimeUtils.getUtcDateTime(new Date(employeeData.value));
          }
        }
        else if (def.fieldType === 'datetime') {
          if (def.masked) {
            employee[employeeData.fieldName] = employeeData.value;
          }
          else if (employeeData.value) {
            employee[employeeData.fieldName] = dateTimeUtils.getUtcDateTime(new Date(employeeData.value));
          }
        } else if (def.fieldType === 'boolean') {
          employee[employeeData.fieldName] = this.parseBooleanValue(employeeData.value);
        } else if (def.fieldType === 'number') {
          const val: number = parseFloat(employeeData.value);
          employee[employeeData.fieldName] = isNaN(val) ? null : val;
        } else if (def.fieldType === 'int') {
          const intVal: number = parseInt(employeeData.value);
          employee[employeeData.fieldName] = isNaN(intVal) ? null : intVal;
        } else {
          employee[employeeData.fieldName] = employeeData.value;
        }
      } else {
        if (employeeData.fieldName === 'Selectable') {
          employee[employeeData.fieldName] = this.parseBooleanValue(employeeData.value);
        } else {
          employee[employeeData.fieldName] = employeeData.value;
        }
      }
    });

    if (_.isUndefined(employee.Selectable)) {
      employee.Selectable = true;
    }

    return employee;
  }

  public mapToEmplyeeListItem(data: any): EmployeeListItem {
    let listItem: EmployeeListItem = new EmployeeListItem();
    listItem.fields = _.map(data.fields, (field: any) => this.mapToEmployeeListItemField(field));
    listItem.id = data.id;
    listItem.listName = data.listName;
    return listItem;
  }

  public mapToEmployeeActionDefinitions(data: IEmployeeActionDefinitions): EmployeeActionDefinitions {
    const actionDefinitions = new EmployeeActionDefinitions();
    actionDefinitions.canAdjustPayRate =data.canAdjustPayRate;
    actionDefinitions.canAddEmployee = data.canAddEmployee;
    actionDefinitions.actions = _.map(data.actions, action => this.mapToEmployeeActionDefinition(action));
    return actionDefinitions;
  }

  public mapToEmployeeActionDefinition(data: any): EmployeeActionDefinition {
    let actionDefinition: EmployeeActionDefinition = new EmployeeActionDefinition();
    actionDefinition.displayName = data.displayName;
    actionDefinition.id = data.id;
    switch (actionDefinition.id) {
      case 15:
        actionDefinition.type = 'assign-pay-policy';
        break;
      case 34:
        actionDefinition.type = 'create-pay-cycles';
        break;
      case 17:
        actionDefinition.type = 'create-ess-policy';
        break;
      case 51:
        actionDefinition.type = 'assign-accruals-policy';
        break;
      case 18:
        actionDefinition.type = 'schedule-for-inservice';
        break;
      case 19:
        actionDefinition.type = 'send-sms';
        break;
      case 57:
        actionDefinition.type = 'resend-opt-sms';
        break;
      case 58:
        actionDefinition.type = 'send-email-instructions';
        break;
      case 70:
        actionDefinition.type = 'mass-assign-slate-profile';
        break;
      case 79:
          actionDefinition.type = 'mass-rate-adjustments';
          break;
    }
    actionDefinition.fields = _.map(data.fields, (field: any) => this.mapToEmployeeListItemField(field));
    return actionDefinition;
  }

  public mapToEmployeeListItemField(data: any): EmployeeFieldDefinition {
    let itemField: EmployeeFieldDefinition = new EmployeeFieldDefinition();
    itemField.displayName = data.displayName;
    itemField.fieldName = data.fieldName;
    if (data.hyperlink) {
      itemField.fieldType = 'hyperlink';
    } else {
      itemField.fieldType = data.fieldType;
    }
    if (data.accessLevel && data.accessLevel.toString() === 'hidden') {
      itemField.hidden = true;
    } else {
      itemField.hidden = data.hidden;
    }
    itemField.masked = data.accessLevel ? data.accessLevel.toString() === 'masked' : false;
    itemField.width = data.width;
    return itemField;
  }

  public mapFromGenericListDefinition(definition: GenericListDefinition): EmployeeActionDefinition {
    let def: EmployeeActionDefinition = new EmployeeActionDefinition();
    def.displayName = definition.listName;
    def.id = definition.id;
    def.fields = this.mapFromGenericFieldsDefinition(definition.fields);
    return def;
  }

  public mapFromGenericFieldsDefinition(fields: StringMap<GenericFieldDefinition>): EmployeeFieldDefinition[] {
    let eFields: EmployeeFieldDefinition[] = [];
    _.forEach(fields, (obj: GenericFieldDefinition) => {
      let o: EmployeeFieldDefinition = new EmployeeFieldDefinition();
      o.displayName = obj.displayName;
      o.fieldName = obj.fieldName;
      o.hidden = obj.hidden;
      o.fieldType = obj.fieldType;
      // o.masked = obj.masked;
      o.width = obj.width;
      eFields.push(o);
    });
    return eFields;
  }

  private parseBooleanValue(v: string): boolean {
    const value = parseInt(v);
    if (_.isFinite(value)) {
      return value === 0 || value === 1 ? Boolean(value) : null;
    } else {
      const lowerCase = String(v).toLowerCase();
      return lowerCase === 'true' || (lowerCase === 'false' ? false : null);
    }
  }
  
}
