import * as tslib_1 from "tslib";
import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { Observable } from 'rxjs/Observable';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { TimeclockCommunicationSendTypes } from '../../models/index';
var TimeclockCommunicationComponent = /** @class */ (function () {
    function TimeclockCommunicationComponent() {
        this.sendType = TimeclockCommunicationSendTypes.specific;
        //this.orgLevel = orgLevel;
        this.onCancel = new EventEmitter();
        this.state = {
            isLoading: false,
            isActiveOnly: false
        };
    }
    TimeclockCommunicationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
    };
    TimeclockCommunicationComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    TimeclockCommunicationComponent.prototype.cancel = function () {
        this.onCancel.emit();
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], TimeclockCommunicationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockCommunicationComponent.prototype, "orgLevelSubscription", void 0);
    return TimeclockCommunicationComponent;
}());
export { TimeclockCommunicationComponent };
