export class AgencyShiftDetails {
    partnerId:string ;
    state:string ;
    shiftDate: Date; 
    createdDate: Date; 
    updatedDate: Date; 
    stateType:string;
    stateSubType:string;
    employeeName:string;
    empId:string;
    shiftType:string;
    shift:string;
    hours :string;
    position:string;
    unit:string;
    workedHours: string;
    mealBreakDuration:string;
    attributes: string;
    cancellationType:string;
    cancellationDate:Date;
    
}