<div>
  <form novalidate #gridForm="ngForm">
    <kendo-grid #kendoGrid scrollable="'scrollable'" [data]="gridState.view" [sortable]="{ mode: 'multiple' }"
      [sort]="sort" [pageable]="true" [pageSize]="pageSize" [skip]="skip"
      [groupable]="false" [slxKendoGridState]="gridState.gridDefinition"
      [rowClass]="rowCallback" (dataStateChange)="gridState.dataStateChange($event)"
      (pageChange)="pageChange($event)" (sortChange)="sortChange($event)"
      (selectionChange)="gridState.selectionChange($event)" (stateRestored)="gridState.dataStateChange($event)"
      (edit)="gridState.editHandler($event)" (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)" (remove)="gridState.removeHandler($event)">

      <ng-template kendoGridToolbarTemplate position="top">
        <form novalidate>
          <slx-date-range-ngx class="col-sm-6" name="startEndDate" [startDate]="startDate" [endDate]="endDate"
            [hasApplyButton]="!state.isEditMode" [startDateReadonly]="state.isEditMode"
            [endDateReadonly]="state.isEditMode" (rangeDateChanged)="loadOnDateChanged($event)"></slx-date-range-ngx>
        </form>
        <button *ngIf="state.isEditMode && isEditable" class="theme-iconed-accent-button recal-reset-button pull-left"
          (click)="onResetBal()">
          <span class="icon-button-content">
            <span>Reset Balance</span>
          </span>
        </button>
        <button *ngIf="state.isEditMode && isEditable" class="theme-iconed-accent-button recal-reset-button pull-right"
          (click)="onRecal()">
          <span class="icon-button-content">
            <span>Recalculate</span>
          </span>
        </button>
        <span [class.active]="employeeActionToolBar" *ngIf="!state.isEditMode"
          class="accruals-toolbar__control as-flex-start mt-16 pull-right">
          <slx-action-list>
            <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent"
              [popperPosition]="'bottom-end'">Actions</slx-action-button>
            <popper-content #popperContent>
              <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
              <slx-action-list-item (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
            </popper-content>
          </slx-action-list>
        </span>
      </ng-template>

      <kendo-grid-column *ngIf="state.isEditMode && isEditable" title="" [sortable]="false" [filterable]="false"
        width="90">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">
            <button kendoGridEditCommand type="button" class="theme-iconed-accent-button grid-btn"
              [disabled]="addMode || !dataItem.isLastManualTransaction"
              *ngIf="employeeAccrualTransactions?.actions.canEdit">
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand type="button" class="theme-iconed-accent-button grid-btn"
              [disabled]="addMode ||  !dataItem.isLastManualTransaction"
              *ngIf="employeeAccrualTransactions?.actions.canEdit">
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" class="theme-iconed-accent-button grid-btn slx-margin-r"
              [disabled]="!gridForm.valid">
              <i class="fas fa-save" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="theme-iconed-accent-button grid-btn">
              <i class="fas fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="policyName" title="Accrual Policy" [width]="150">
        <ng-template kendoGridHeaderTemplate>
          Accrual Policy
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.policyName }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="transactionDate" title="Date" [width]="110">
        <ng-template kendoGridHeaderTemplate>
          Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.transactionDate | amDateFormat: appConfig.dateFormat }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="accrualType" title="Type" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Type
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.accrualType }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="action" title="Action" [width]="150">
        <ng-template kendoGridHeaderTemplate>
          Action
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.action }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="originalValue" title="Original" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          Original
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.originalValue | slxDecimal24Switch : isShowHighPrecision }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="change" title="Change" [width]="100" editable="false">
        <ng-template kendoGridHeaderTemplate>
          Change
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="whole-cell" [style.color]="colorCode(dataItem.change)">
            {{ dataItem.change | slxDecimal24Switch : isShowHighPrecision }}
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="newValue" title="New Value" [width]="100">
        <ng-template kendoGridHeaderTemplate>
          New Value
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.newValue | slxDecimal24Switch : isShowHighPrecision }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="modifiedOn" [width]="170">
        <ng-template kendoGridHeaderTemplate>
          Updated
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.modifiedOn | amDateFormat: appConfig.dateTimeFormatUS }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="notes" title="Notes" [width]="200" editable="false">
        <ng-template kendoGridHeaderTemplate>
          Notes
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <p class="transaction-notes" slxTooltip="{{ dataItem.notes }}" tipPosition="left">{{ dataItem.notes }}</p> 
        </ng-template>
        <ng-template *ngIf="state.isEditMode" kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <input slx-input type="text" [(ngModel)]="dataItem.notes" placeholder="type notes here...." maxlength="225"
              name="notesInput{{rowIndex}}">
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-excel [fileName]="getFileName(false)" [fetchData]="retriveAllPages()">
        <kendo-excelexport-column-group [title]="getTitle()" [headerCellOptions]="{ textAlign:'center' }">
          <kendo-excelexport-column-group [title]="getEmployeeInfo()" [headerCellOptions]="{ textAlign: 'center' }">
            <kendo-excelexport-column title="Accrual Policy" field="policyName" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
              <ng-template kendoExcelExportFooterTemplate>Created on</ng-template>
            </kendo-excelexport-column>
            <kendo-excelexport-column title="Date" field="transactionDateFormat" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }">
              <ng-template kendoExcelExportFooterTemplate>{{createdDateTime}}</ng-template>
            </kendo-excelexport-column>
            <kendo-excelexport-column title="Type" field="accrualType" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }"></kendo-excelexport-column>
            <kendo-excelexport-column title="Action" field="action" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }"></kendo-excelexport-column>
            <kendo-excelexport-column title="Original" field="originalValue" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="cells"></kendo-excelexport-column>
            <kendo-excelexport-column title="Change" field="change" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="cells"></kendo-excelexport-column>
            <kendo-excelexport-column title="New Value" field="newValue" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="cells"></kendo-excelexport-column>
            <kendo-excelexport-column title="Updated" field="updatedDate" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }"></kendo-excelexport-column>
            <kendo-excelexport-column title="Notes" field="notes" [width]="130" [headerCellOptions]="{ textAlign: 'center' }" [cellOptions]="{ textAlign: 'left' }"></kendo-excelexport-column>
          </kendo-excelexport-column-group>
        </kendo-excelexport-column-group>
      </kendo-grid-excel>

      <kendo-grid-pdf [fileName]="getFileName(true)" 
            [allPages]="pdfTemplate.allPages" 
            [landscape]="pdfTemplate.landscape" 
            [paperSize]="pdfTemplate.paperSize"
            [margin]="pdfTemplate.margin" 
            [scale]="pdfTemplate.scale" 
            [repeatHeaders]="pdfTemplate.repeatHeaders">
        <kendo-grid-pdf-margin 
            [top]="pdfTemplate.marginTop" 
            [bottom]="pdfTemplate.marginBottom" 
            [right]="pdfTemplate.marginRight" 
            [left]="pdfTemplate.marginLeft">
        </kendo-grid-pdf-margin>
        <ng-template kendoGridPDFTemplate>
          <div class="page-template">
            <div class="header">
              <div class="title">{{getTitle()}}</div>
              <div class="title1">
                <span class="text-container">{{employeeName}}</span>
                <span class="text-container">{{employeePosition}}</span>
                <span class="text-container">{{employeeType}}</span>
                <span class="text-container">{{employeePayRollNumber}}</span>
              </div>
            </div>
            <div class="footer">
              <div class="footer-title">Created on : {{createdDateTime}}</div>
            </div>
          </div>
        </ng-template>
      </kendo-grid-pdf>

    </kendo-grid>
  </form>
</div>