import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, EventEmitter } from '@angular/core';
import { StyledUserApplication } from '../../../../organization/models/index';
import { SoDashboardCounters } from '../../../models/index';
import { DashboardSOAlertEnum } from '../../../../portal/models/index';
import * as moment from 'moment';
import { appConfig } from './../../../../app.config';
import { ApplicationDashboardManagementService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var SoDashboardComponent = /** @class */ (function () {
    function SoDashboardComponent(appDashboardMngtService) {
        this.appDashboardMngtService = appDashboardMngtService;
        this.onLoading = new EventEmitter();
        this.links = {
            openShiftRequest: 'so_console',
            employeeProjectedOvertime: 'so_console',
            ptoRequest: 'leave_requests'
        };
        this.datesParams = {
            startDate: moment().startOf('year').format(appConfig.linkDateFormat),
            endDate: moment().endOf('year').format(appConfig.linkDateFormat)
        };
        this.onLoading.emit(true);
    }
    SoDashboardComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SoDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.countersSubscription = this.appDashboardMngtService
            .subscribeToCountersLoaded(function (counters) {
            _this.mapCounters(counters['Scheduler']);
            _this.setCounter();
        });
    };
    SoDashboardComponent.prototype.mapCounters = function (counters) {
        this.counters = _.isObject(counters) && _.size(counters) > 0 ? counters : new SoDashboardCounters();
        this.loadAlerts();
    };
    SoDashboardComponent.prototype.loadAlerts = function () {
        var _this = this;
        this.appDashboardMngtService.getDashboardAppAlerts(this.application.id)
            .then(function (data) {
            _this.alertConfigOrderAndVisibilty = _.filter(data, function (t) { return t.visible; });
            _this.setCounter();
            _this.onLoading.emit(false);
        });
    };
    SoDashboardComponent.prototype.onHasChanges = function (hasChanges) {
        if (hasChanges) {
            this.loadAlerts();
        }
    };
    SoDashboardComponent.prototype.getRouterLink = function (path) {
        return [this.application.link, path];
    };
    SoDashboardComponent.prototype.setCounter = function () {
        var _this = this;
        var appAlerts = this.alertConfigOrderAndVisibilty;
        _.forEach(appAlerts, function (record) {
            switch (record.alertId) {
                case DashboardSOAlertEnum.openShiftReq:
                    record.counter = _this.counters.openShiftRequest;
                    break;
                case DashboardSOAlertEnum.empProjOvertime:
                    record.counter = _this.counters.employeeProjectedOvertime;
                    break;
                case DashboardSOAlertEnum.ptoReq:
                    record.counter = _this.counters.ptoRequest;
                    break;
            }
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SoDashboardComponent.prototype, "countersSubscription", void 0);
    return SoDashboardComponent;
}());
export { SoDashboardComponent };
