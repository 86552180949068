import { IOrganizationPayroll, OrganizationPayroll } from './organization-payroll';
import { PayrollExportTestState, IPayrollExportTestState } from './payroll-export-test-state';

export interface IPayrollExportTestsContainer {
    organizationPayroll: IOrganizationPayroll;
    tests: IPayrollExportTestState[];
}

export class PayrollExportTestsContainer {
    organizationPayroll: OrganizationPayroll;
    tests: PayrollExportTestState[];
}
