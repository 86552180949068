  <input  #emailInput
          [(ngModel)]="innerValue"
          (ngModelChange)="valueChanged($event)"
          [attr.class]="className"
          [readonly]="!inEdit ? true : null"
          type="text" maxlength="255"
          [placeholder]="placeholder"
          [attr.tabindex]="tabindex"
          >
  <!--
  [textMask]="emailMaskConf"
  (keyup)="onKey($event)"
  -->
