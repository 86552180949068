import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../core/decorators/index';
import { IRangeDates } from '../../../common/models/range-dates';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { ComponentStateStorageService } from './../../../common/services/index';
import { IControlState, StateResetTypes } from './../../../core/models/settings/index';
import { LeaveRequestFilter } from '../../models/index';

import { appConfig, IApplicationConfig } from '../../../app.config';
import { LeaveRequestApiService } from '../../services';

@Component({
  moduleId: module.id,
  selector: 'slx-leave-request-toolbar',
  templateUrl: 'leave-request-toolbar.component.html',
  styleUrls: ['leave-request-toolbar.component.scss'],
  providers: [StateManagementService]
})
export class LeaveRequestToolbarComponent implements OnInit, OnDestroy {
  @Input()
  public startDate: Date;

  @Input()
  public endDate: Date;

  @Output()
  public onDatesChanged: EventEmitter<IRangeDates>;

  @Output()
  public onFiltersChanged: EventEmitter<LeaveRequestFilter[]>;

  public filtersList: LeaveRequestFilter[];
  public appliedFilters: LeaveRequestFilter[];
  public dateRange: IRangeDates;

  @unsubscribe()
  private loadSubscription: Subscription;
  private storageService: ComponentStateStorageService;
  private stateManagement: StateManagementService;
  private filtersControlKey: string;
  private datesControlKey: string;
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
  private appConfig: IApplicationConfig;

  constructor(stateManagement: StateManagementService, storageService: ComponentStateStorageService, private apiService: LeaveRequestApiService) {
    this.stateManagement = stateManagement;
    this.storageService = storageService;
    this.appConfig = appConfig;

    this.onDatesChanged = new EventEmitter<IRangeDates>();
    this.onFiltersChanged = new EventEmitter<LeaveRequestFilter[]>();
    this.filtersList = [
      { id: 0, name: 'Pending Approval' },
      { id: 1, name: 'Pending Replacement' },
      { id: 2, name: 'Approved' },
      { id: 3, name: 'Denied' },
      { id: 4, name: 'Submit for Review' },
    ];
    this.appliedFilters = [this.filtersList[0], this.filtersList[1]];
    this.dateRange = { startDate: null, endDate: null };

    this.filtersControlKey = 'filters';
    this.datesControlKey = 'dates';
  }

  public ngOnInit(): void {
    this.stateManagement.init('LeaveRequestComponent', true);
    this.loadSubscription = this.stateManagement.onInit$.subscribe(() => {
      if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
        this.dateRange.startDate = this.startDate;
        this.dateRange.endDate = this.endDate;
        this.restoreFilters();
        this.emitFiltersChanges(this.appliedFilters);
      } else if (_.isNull(this.startDate) && _.isNull(this.endDate)) {
        this.restoreDates();
        this.emitDatesChanges(this.dateRange);

        this.restoreFilters();
        this.emitFiltersChanges(this.appliedFilters);
      } else {
        throw new Error(`One of the date range value has no value. startDate: ${this.startDate}, endDate: ${this.endDate}`);
      }
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onDatesRangeChanged($event: IRangeDates): void {
    this.dateRange.startDate = $event.startDate;
    this.dateRange.endDate = $event.endDate;
    this.saveDates();
    this.emitDatesChanges($event);
  }

  public onFiltersSetChanged($event: LeaveRequestFilter[]): void {
    this.saveFilters();
    this.emitFiltersChanges($event);
  }

  private emitDatesChanges(dateRange: IRangeDates): void {
    this.onDatesChanged.emit(dateRange);
  }

  private emitFiltersChanges(filters: LeaveRequestFilter[]): void {
    this.onFiltersChanged.emit(filters);
  }

  private saveFilters(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.filtersControlKey, { value: this.appliedFilters }, this.resetBy);
  }

  private saveDates(): void {
    const startDate: string = moment(this.startDate).format(appConfig.dateFormat);
    const endDate: string = moment(this.endDate).format(appConfig.dateFormat);
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      this.datesControlKey,
      {
        value: { startDate: this.dateRange.startDate, endDate: this.dateRange.endDate }
      },
      this.resetBy
    );
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.filtersControlKey);
    let filters: LeaveRequestFilter[] = state.value;
    if (_.size(filters) > 0) {
      this.appliedFilters = filters.slice(0);
    }
  }

  private restoreDates(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
    let startDate: moment.Moment = moment(_.get(state.value, 'startDate', null));
    let endDate: moment.Moment = moment(_.get(state.value, 'endDate', null));
    if (startDate.isValid() && endDate.isValid() ) {
      this.dateRange.startDate = startDate.toDate();
      this.dateRange.endDate = endDate.toDate();
    } else {
      this.dateRange.startDate = moment().startOf('year').toDate();
      this.dateRange.endDate = moment().endOf('year').toDate();
    }
  }

  public onClickExport(isPdf: boolean) {
    this.apiService.exportTo(isPdf);
  }
}

