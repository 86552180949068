<kendo-grid kendoGridFixFreezeHeader slxKendoGridMobileManager class="slx-full-height slx-blue-grid slx-no-border"
  slxKendoGridState="PJB_EXPORT_STAFF_HOURS_DATA" (stateRestored)="gridState.dataStateChange($event)"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [slxGroupable]="{ showFooter: true }"
  [slxGroupableMobile]="false"
  [group]="gridState.state.group"
  [filterable]="'menu'"
  [filter]="gridState.state.filter"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  [pageable]="true"
  (pageChange)="pageChanged($event)"
  (sortChange)="sortChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
>
  <!--(filterChange)="filterChange($event)"-->

  <kendo-grid-column media="xs" title="PBJ Log">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>

        <dt>PBJ ID</dt>
        <dd>{{ dataItem?.pbjId }}</dd>

        <dt>Employee Master ID</dt>
        <dd>{{ dataItem?.employeeMasterId }}</dd>

        <dt>Name</dt>
        <dd>{{ dataItem?.employeeName }}</dd>

        <dt>Organization</dt>
        <dd>{{ dataItem?.organizationName }}</dd>

        <dt>Payroll No</dt>
        <dd>{{ dataItem?.payrollNumber }}</dd>

        <dt>Work Date</dt>
        <dd>{{ dataItem?.workDate | amDateFormat: appConfig.dateFormat }}</dd>

        <dt>Work Hours</dt>
        <dd>{{ dataItem?.workHours }}</dd>

        <dt>CMS Job Code</dt>
        <dd>{{ dataItem?.cmsJobCode }}</dd>

        <dt>CMS Job Title</dt>
        <dd>{{ dataItem?.cmsJobNameTitle }}</dd>

        <dt>Exempt Type</dt>
        <dd>{{ dataItem?.exemptType }}</dd>

      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="pbjIdConfigEnabled" title="PBJ ID" width="150" media="sm" field="pbjId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>PBJ ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.pbjId }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="!pbjIdConfigEnabled" title="Emp Master ID" width="150" media="sm" field="employeeMasterId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Master ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeMasterId }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="150" media="sm" field="employeeName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeName }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <!-- 
    <kendo-grid-column title="Organization" width="150" media="sm" field="organizationName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Organization</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.organizationName }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter" slxKendoGridServerFilter>
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
 -->

  <kendo-grid-column title="Payroll No" width="150" media="sm" field="payrollNumber">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll No</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.payrollNumber }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Work Date" width="180" media="sm" field="workDate">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Work Date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.workDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Work Hours" width="150" media="sm" field="workHours">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Work Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.workHours }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="CMS Job Code" width="150" media="sm" field="cmsJobCode">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>CMS Job Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.cmsJobCode }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-numeric-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-numeric-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="CMS Job Title" width="150" media="sm" field="cmsJobNameTitle">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>CMS Job Title</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.cmsJobNameTitle }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Exempt Type" width="150" media="sm" field="exemptType">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Exempt Type</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.exemptType }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-string-filter-menu slxKendoGridServerFilterMenu
                 [column]="column"
                 [filter]="filter"
                 [filterService]="filterService"
                >
      </kendo-grid-string-filter-menu>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<!-- export grid -->

<kendo-grid #exportGrid *ngIf="isExportInProgress && currentExportType != xlsExportType" class="export-grid slx-blue-grid slx-no-border"
  [data]="exportGridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="exportGridState.state.sort"
  [group]="exportGridState.state.group"
  [filterable]="'menu'"
  [filter]="exportGridState.state.filter"
  [pageable]="false"
  [scrollable]="'virtual'"
  [height]="400"
  [rowHeight]="32"
  [pageSize]="pageSize"
  [skip]="exportGridState.state.skip"
>

  <kendo-grid-column *ngIf="pbjIdConfigEnabled" title="PBJ ID" width="150" field="pbjId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>PBJ ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.pbjId }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngIf="!pbjIdConfigEnabled" title="Emp Master ID" width="150" field="employeeMasterId">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Emp Master ID</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeMasterId }}
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Name" width="150" field="employeeName">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Name</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.employeeName }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Payroll No" width="150" field="payrollNumber">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Payroll No</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.payrollNumber }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Work Date" width="150" field="workDate">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Work Date</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.workDate | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Work Hours" width="150" field="workHours">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Work Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.workHours }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="CMS Job Code" width="150" field="cmsJobCode">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>CMS Job Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.cmsJobCode }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="CMS Job Title" width="150" field="cmsJobNameTitle">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>CMS Job Title</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.cmsJobNameTitle }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Exempt Type" width="150" field="exemptType">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Exempt Type</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem?.exemptType }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-pdf fileName="export-{{exportId}}-staffing-hours-data.pdf"
      paperSize="A4" [scale]="pdfScale" [landscape]="true" [allPages]="false" [repeatHeaders]="true"
      >

      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>

      <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="page-template">
          <div class="header">
              <h4>{{pbjHeader?.organization?.name}} ({{pbjHeader?.startDate| amDateFormat: appConfig.dateFormat}} - {{pbjHeader?.endDate| amDateFormat: appConfig.dateFormat}}) Exported on {{pbjHeader?.exportDate| amDateFormat: appConfig.dateTimeFormatUS}}</h4>
          </div>
          <div class="footer">
            Page {{ pageNum }} of {{ totalPages }}
          </div>
        </div>
      </ng-template>

  </kendo-grid-pdf>

  <kendo-grid-excel fileName="export-{{exportId}}-staffing-hours-data.xlsx"></kendo-grid-excel>

</kendo-grid>
