import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { unsubscribeAll, mutableSelect } from '../../../../../core/decorators/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { BenefitDetailsProviderEntity, BenefitDetailsLine, BenefitDetailsEditModes, BenefitDetailsUserActions, BenefitDetailsCalcMethod, BenefitDetailsProviderLineStandartEntity } from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
import { Observable } from 'rxjs/Observable';
var BenefitDetailsStandartComponent = /** @class */ (function () {
    function BenefitDetailsStandartComponent(modalService, commonManService, permissionService, manService) {
        this.modalService = modalService;
        this.commonManService = commonManService;
        this.permissionService = permissionService;
        this.manService = manService;
        this.valueIconClass = 'fas fa-circle blueIconClass';
        this.benefitLines = [];
        this.providerPlanDate = null;
        this.isEditMode = false;
        this.canSave = false;
        this.canEdit = false;
        this.canDelete = false;
        this.isShownFormula = true;
        this.canAddCovOptions = false;
        this.updateCovOptionRatesMsg = 'Are you sure you want to change. This will update enrollment records of previously enrolled employees.';
        this.updateCovFlatMsg = 'You are updating contribution value(s). Values for already enrolled employees will not be updated.';
        this.updateCov401KMsg = 'You are updating value(s) under coverage options. Values for already enrolled employees will not be updated.';
        this.appConfig = appConfig;
        this.subscriptions = {};
    }
    Object.defineProperty(BenefitDetailsStandartComponent.prototype, "isCreatingNew", {
        get: function () {
            return _.isObjectLike(this.selectedProvider) && this.selectedProvider.isDraft;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsStandartComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init('BenefitDetailsStandartComponent');
        this.subscriptions.providers = this.commonManService
            .subscribeToLoadProviders(function (p) { return (_this.providers = p); });
        this.subscriptions.selectProvider = this.commonManService
            .subscribeToSelectProvider(function (p) {
            if (_.isObjectLike(p)) {
                _this.selectedProvider = p;
                _this.benefitLines = p.current.benefitLines || [];
                _this.providerOrglevelName = p.current.orgLevelName || '';
            }
        });
        this.subscriptions.selectProviderLine = this.commonManService
            .subscribeToSelectProviderLine(function (pl) {
            _this.selectedBenefitLine = pl.current.line;
            _this.selectedProviderLineId = pl.current.id;
            _this.minEffectiveDate = pl.current.line.startDate;
            _this.maxEffectiveDate = pl.current.line.endDate;
        });
        this.subscriptions.state = this.manService
            .subscribeToChangeState(function (v) { return (_this.isShownFormula = v); });
        this.subscriptions.calcMethod = this.manService
            .subscribeToChangeCalcMethod(function (calcMethod) { return (_this.calcMethod = calcMethod); });
        this.subscriptions.providerPlanDate = this.manService
            .subscribeToChangeProviderPlanDate(function (d) {
            _this.providerPlanDate = d;
        });
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) {
            _this.isEditMode = v.providerInfo;
        });
        this.subscriptions.userActions = this.permissionService
            .subscribeToUserActions(function (v) {
            Promise.resolve()
                .then(function () {
                _this.canSave = v.canSave;
                _this.canEdit = v.canEdit;
                _this.canDelete = v.canDelete;
                _this.canAddCovOptions = v.canAddCovOptions;
            });
        });
        this.subscriptions.saveState = this.permissionService
            .subscribeToSaveState(function (canSave) {
            Promise.resolve()
                .then(function () {
                _this.canSave = canSave;
            });
        });
        this.subscribeToOrgLevel();
    };
    BenefitDetailsStandartComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
        });
    };
    BenefitDetailsStandartComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
    };
    Object.defineProperty(BenefitDetailsStandartComponent.prototype, "hasProviders", {
        get: function () {
            return _.isArray(this.providers) && _.size(this.providers) > 0;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsStandartComponent.prototype.onChangeProviderPlanDate = function () {
        this.manService.changeProviderPlanDate(this.providerPlanDate);
    };
    BenefitDetailsStandartComponent.prototype.onChangeBenefitLine = function (pl) {
        if (_.isNumber(this.selectedProviderLineId) && _.isObjectLike(pl) && this.selectedProviderLineId !== pl.id) {
            this.selectedProviderLineId = pl.id;
            this.manService.selectProviderLine(pl);
        }
    };
    BenefitDetailsStandartComponent.prototype.onEdit = function () {
        this.manService.startEditingProvider();
    };
    BenefitDetailsStandartComponent.prototype.onCancel = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Discard Changes', 'Are you sure you want to discard all changes?', this.modalService, function (isCancel) {
            if (isCancel) {
                if (_this.selectedProvider.isDraft) {
                    _this.manService.removeDraftProvider();
                    _this.manService.duplicateValue = false;
                }
                else {
                    _this.manService.cancelEditingProvider();
                }
            }
        }, options);
    };
    BenefitDetailsStandartComponent.prototype.onSave = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.selectedProvider.isDraft && this.manService.hasChangesInCovOptions())) return [3 /*break*/, 1];
                        options = new ConfirmOptions();
                        options.showCancel = true;
                        options.showOK = true;
                        options.buttonOKtext = 'Yes';
                        ConfirmDialog2Component.openDialog('Save Confirmation', this.getTitle(), this.modalService, function (isSave) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!isSave) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.save()];
                                    case 1:
                                        _a.sent();
                                        return [3 /*break*/, 3];
                                    case 2:
                                        this.manService.cancelEditingProvider();
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }, options);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.save()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartComponent.prototype.save = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.selectedProvider.isDraft) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.manService.saveProvider()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.manService.saveProviderLine()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.manService.updateEmployees(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitDetailsStandartComponent.prototype.onDelete = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Delete Benefit Plan', 'Are you sure you want to delete this benefit? This will unenroll all the enrolled employees and dependents.', this.modalService, function (result) {
            if (result) {
                _this.manService.deleteProvider();
            }
        }, options);
    };
    BenefitDetailsStandartComponent.prototype.onChangeShowFormula = function () {
        this.manService.changeShownFormulaState(this.isShownFormula);
    };
    BenefitDetailsStandartComponent.prototype.onAdd = function () {
        this.manService.addNewCoverageOption();
    };
    BenefitDetailsStandartComponent.prototype.getTitle = function () {
        return this.calcMethod ?
            this.calcMethod.isOptionsRates ? this.updateCovOptionRatesMsg :
                this.calcMethod.isFlat ? this.updateCovFlatMsg :
                    this.updateCov401KMsg : '';
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsStandartComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitDetailsStandartComponent.prototype, "orgLevel$", void 0);
    return BenefitDetailsStandartComponent;
}());
export { BenefitDetailsStandartComponent };
