<slx-spinner [show]="isLoading">
<form #form="ngForm" class="slx-full-height">
  <kendo-grid #kendoGrid class="slx-full-height slx-blue-grid pbj-entries-grid"
    [data]="gridState?.view"
    [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort"
    [filterable]="'menu'"
    [filter]="gridState.state.filter"
    [groupable]="{ showFooter: true }"
    [group]="gridState.state.group"
    [pageable] = "true"
    [pageSize]="pageSize"
    [skip]="gridState.state.skip"
    (pageChange)="gridState.pageChange($event)"
    (dataStateChange)="gridState.dataStateChange($event)"
    [resizable]="true"
    (edit)="gridState.editHandler($event)"
    (save)="gridState.saveHandler($event)"
    (cancel)="gridState.cancelHandler($event)"
  >
    <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" width="40px">
      <ng-template kendoGridHeaderTemplate>
        <input type="checkbox" name="selectedAll" [ngModel]="selectedAll" [disabled]="gridState.editedRecord" (ngModelChange)="onSelectedEntries($event)" />
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox" name="selectedItem"
        *ngIf="isEntryEditable (dataItem)" [disabled]="gridState.editedRecord"
        [(ngModel)]="dataItem._selectedEntry" (ngModelChange)="onSelectedEntry(dataItem, $event)"
        />
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Edit" [sortable]="false" [filterable]="false" width="90px">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="flex">
          <button kendoGridEditCommand type="button"
          *ngIf="isEntryEditable (dataItem)" [disabled]="gridState.editedRecord"
          class="slx-button slx-only-icon slx-toolbar" title="Edit"
          >
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
          </button>
          <button type="button"
          *ngIf="isEntryEditable(dataItem) && !gridState.editedRecord" [disabled]="gridState.editedRecord"
          class="slx-button slx-only-icon slx-toolbar clone-button" title="Clone"
          (click)="onClone(dataItem)"
          >
            <i class="fas fa-copy" aria-hidden="true"></i>
          </button>
          <button kendoGridSaveCommand type="button" [disabled]="!form.valid" class="slx-button slx-only-icon slx-toolbar slx-margin-r" title="Save">
            <i class="far fa-check-circle" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar" title="Cancel">
            <i class="fa fa-ban" aria-hidden="true"></i>
          </button>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Date" field="date" filter="date" width="150px">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.date | amDateFormat: appConfig.dateFormat }}</ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-datepicker #date="ngModel"
          class="slx-wide"
          [(ngModel)]="dataItem.date"
          [minDate]="getMinDate(dataItem)"
          [maxDate]="getMaxDate(dataItem)"
          placeholder="Date"
          name="date"
          required
          (ngModelChange)="onChangeDate($event, dataItem)"
        ></slx-datepicker>
        <div *ngIf="date.errors" class="slx-error-block">
          <span *ngIf="date.errors.required" errorMessage for="required"></span>
          <span *ngIf="date.errors.date" errorMessage for="date"></span>
          <span *ngIf="date.errors.maxDate" errorMessage for="maxDate">The value should be lower than {{ actualRange.endDate | amDateFormat: appConfig.dateFormat }}</span>
          <span *ngIf="date.errors.minDate" errorMessage for="minDate">The value should be greater than {{ actualRange.startDate | amDateFormat: appConfig.dateFormat }}</span>
        </div>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{value | amDateFormat: appConfig.dateFormat }}</strong>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Name" field="employee.name" width="200px">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [employeeSectionLink]="dataItem.employee?.id">{{dataItem.employee?.name}}</a>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <div *ngIf="!dataItem.id">
          <slx-autocomplete #employee="ngModel"
            class="slx-wide"
            [lookup]="employeesLookup"
            [(ngModel)]="dataItem.employee"
            (ngModelChange)="onEmployeeChanged(dataItem)"
            [required]="true"
            [strictSearch]="false"
            placeholder="Employee name"
            name="employee"
            [readonly]="!isDateSelected(dataItem)"
          ></slx-autocomplete>
          <div *ngIf="employee.errors && isDateSelected(dataItem)" class="slx-error-block">
            <span *ngIf="employee.errors.required" errorMessage for="required"></span>
          </div>
        </div>
        <div *ngIf="dataItem.id">
          <a [employeeSectionLink]="dataItem.employee?.id">{{dataItem.employee?.name}}</a>
        </div>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Organization" field="center.name">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.center?.name}}</ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-dropdown-list #organization="ngModel"
          class="slx-wide slx-short"
          [lookup]="organizationsLookup"
          [(ngModel)]="dataItem.center"
          (ngModelChange)="onOrganizationChanged(dataItem)"
          [required]="true"
          [readonly]="!isEmployeeSelected(dataItem)"
          [disableEmittingIfReadonly]="true"
          name="organization"
          placeholder="Organization"
        ></slx-dropdown-list>
        <div *ngIf="organization.errors && !(!isEmployeeSelected(dataItem))" class="slx-error-block">
          <span *ngIf="organization.errors.required" errorMessage for="required"></span>
        </div>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Department" field="department.name">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.department?.name}}</ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-dropdown-list #department="ngModel"
          class="slx-wide slx-short"
          [lookup]="departmentsLookup"
          [(ngModel)]="dataItem.department"
          (ngModelChange)="onDepartmentChanged(dataItem)"
          [required]="true"
          [readonly]="!isEmployeeSelected(dataItem) || !dataItem.center"
          [disableEmittingIfReadonly]="true"
          name="department"
          placeholder="Department"
        ></slx-dropdown-list>
        <div *ngIf="department.errors && !(!isEmployeeSelected(dataItem) || !dataItem.center)" class="slx-error-block">
          <span *ngIf="department.errors.required" errorMessage for="required"></span>
        </div>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Position" field="position.name">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.position?.name}}</ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-dropdown-list #position="ngModel"
          class="slx-wide slx-short"
          [lookup]="positionsLookup"
          [(ngModel)]="dataItem.position"
          (ngModelChange)="onPositionChanged(dataItem)"
          [required]="true"
          [readonly]="isPositionReadonly"
          [disableEmittingIfReadonly]="true"
          name="position"
          placeholder="Position"
        ></slx-dropdown-list>
        <div *ngIf="position.errors && !isPositionReadonly" class="slx-error-block">
          <span *ngIf="position.errors.required" errorMessage for="required"></span>
        </div>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Hours" field="hours" filter="numeric" width="120px">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.hours}}</ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
        <slx-number #assignedHours="ngModel"
          class="slx-wide"
          [(ngModel)]="dataItem.hours"
          [decimals]="2"
          [step]="0.1"
          [min]="hours.min"
          [max]="hours.max"
          [slxMin]="hours.min"
          [slxMax]="hours.max"
          [required]="true"
          placeholder="Hours"
          name="hours"
          [readonly]="!isDateAndEmpSelected(dataItem)"
        ></slx-number>
        <div *ngIf="assignedHours.errors && isDateAndEmpSelected(dataItem)" class="slx-error-block">
          <span *ngIf="assignedHours.errors.required" errorMessage for="required"></span>
          <span *ngIf="assignedHours.errors.max" errorMessage for="max">The value cannot be greater than {{ hours.max }}</span>
          <span *ngIf="assignedHours.errors.min" errorMessage for="min">The value cannot be less than {{ hours.min }}</span>
        </div>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Entry Type" field="entryType" width="120px" [editable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.entryType}}</ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Added By" field="addedByUsername"  width="120px" [editable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.addedByUsername}}</ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Date Added" field="addedDate"  width="140px" [editable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.addedDate | amDateFormat: appConfig.dateTimeFormat}}</ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        <strong>{{ value }}</strong>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()"></kendo-grid-excel>
    <kendo-grid-pdf [fileName]="pdfName" [allPages]="true">
      <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
    </kendo-grid-pdf>
  </kendo-grid>
</form>
</slx-spinner>
