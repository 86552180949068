import { IncreaseReason } from './../../../../organization/models/lookup/increase-reason';
import { MassRateAdjustmentsDefenitions, PayRateDefenitions } from '../../../../organization/models/employee/mass-rate-adjustments';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody } from '../../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { Meta } from '../../../../core/models/api/meta';
import { PaginationMeta } from '../../../../core/models/api/pagination-meta';
import { Assert } from '../../../../framework/index';
import { Employee, SmsRequest, SmsResponse, EmployeeActionDefinition } from '../../models';
import { EmployeeListMapService } from '../employee-list/employee-list-map.service';
import { employeeListConfig } from '../../employee-list.config';
import { dateTimeUtils } from '../../../../common/utils/index';
import * as _ from 'lodash';
import { configurationConfig } from '../../../../configuration/configuration.config';
import { MassPayRateAdjustmentMapService } from '../mass-rate-adjustments/mass-rate-adjustments-map.service';

@Injectable()
export class GroupActivitiesApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private employeeListMapService: EmployeeListMapService,
    private massPayRateAdjustmentMapService:MassPayRateAdjustmentMapService) {
  }

  public getActionEmployeeList(actionDefinitionId: number, orgLevelId: number, activeOnly: boolean, employeeIds: string[], fieldDefinition: EmployeeActionDefinition): Promise<Employee[]> {
    Assert.isNotNull(actionDefinitionId);
    Assert.isNotNull(orgLevelId);

    const url: string = `${this.apiUtilService.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.actions.root}/${employeeListConfig.api.employees.actions.list}/${actionDefinitionId}`;

    let promise: Promise<Employee[]> = this.apiUtilService.request<Employee[], PaginationMeta>(
      this.urlParamsService.createPostRequest(url, { employeeIds, orgLevelId, activeOnly }, { orgLevelId: orgLevelId }))
      .then((response: ResponseBody<Employee[], PaginationMeta>) => _.map(response.data, (data: any) => this.employeeListMapService.mapToEmployee(data, fieldDefinition)));
    return promise;
  }

  public assignPolicies(employees: Employee[], payPolicy: any, diffPolicy: any, accrualPolicy: any, effectiveDate: Date): Promise<any> {
    let url: string = `${this.apiUtilService.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.actions.assignPoliciesToEmployees}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, {
      payPolicy: payPolicy,
      accrualPolicy: accrualPolicy,
      shiftDifferentialPolicy: diffPolicy,
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate),
      employeeIds: _.map(employees, (emp: Employee) => emp['EmpId'])
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public createPayCycles(employees: Employee[], effectiveDate: Date, period: any, first: number, second: number): Promise<any> {
    let url: string = `${this.apiUtilService.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.actions.assignPaycycleToEmployees}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, {
      payCycle: period.name,
      startDay1: first,
      startDay2: second,
      effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate),
      employeeIds: _.map(employees, (emp: Employee) => emp['EmpId'])
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }


  public sendSms(message: string, recipients: Employee[], orgLevelId: number): Promise<SmsResponse> {
    Assert.isNotNull(message);
    Assert.isNotNull(recipients);
    Assert.isNotNull(orgLevelId);
    const {
      employees: {
        root: employees,
        actions: {
          root: actions,
          group: {
            root: group,
            sms: sms
          }
        }
      }
    }: any = employeeListConfig.api;

    const url: string = `${this.apiUtilService.getApiRoot()}/${employees}/${actions}/${group}/${sms}`;
    const body: SmsRequest = {
      message: {
        text: message
      },
      recipients: _.map(recipients, (recipient: Employee) => recipient['EmpId']),
      orgLevelId: orgLevelId
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<SmsResponse> = this.apiUtilService.request<SmsResponse, Meta>(request)
      .then((response: ResponseBody<SmsResponse, Meta>) => response.data);
    return promise;
  }
  
  private getMessageCenterUrl() {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.root}`;
  }

  public getEmployeeMessages(employees: number[]): Promise<any> {
    let url: string = `${this.getMessageCenterUrl()}/${configurationConfig.api.configuration.messageCenter.employeeMessage}?` + `${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.apiUtilService.getCommunicationApiRootVersion();
    const body: any = {
      employeeIds: employees
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: any) =>
        this.mapToEmployeeMessages(response)
      );
    return promise;
    
  } 

  public getEmployeesWithPayRate( orgLevelId: number,employees: Employee[]): Promise<PayRateDefenitions> {
    Assert.isNotNull(orgLevelId);
    const url: string = this.getPayRateComponentsUrl();
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, {
      orgLevelId: orgLevelId,
      employeeIds: _.map(employees, (emp: Employee) => emp['EmpId'])
    } 
    );
    
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
    .then((response) =>this.massPayRateAdjustmentMapService.mapToPayRateDefenitions(response.data));
  return promise;
     
  }
  
  public adjustEmployeesMassrate(req:MassRateAdjustmentsDefenitions): Promise<any> {
    const url: string = this.getAdjustEmployeesMassrateUrl();
    let adjustedEmployees = this.massPayRateAdjustmentMapService.mapToAdjustedEmployee(req);
    const body: any = {
      increment :adjustedEmployees.increment,
      incrementType:adjustedEmployees.incrementType,
      increaseReason:adjustedEmployees.increaseReason,
      masspayratelist:adjustedEmployees.empMassPayRateRecords,
      effectiveDate:adjustedEmployees.effectiveDate

    };
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public mapToEmployeeMessages(dtos: any): any[] {
    return _.map(dtos, v => this.mapToEmployeeMessageList(v));
  }

  public mapToEmployeeMessageList(dto: any): any {
    return dto;
  }

  private getPayRateComponentsUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.actions.root}/${employeeListConfig.api.employees.actions.payrate.root}/${employeeListConfig.api.employees.actions.payrate.getPayRateComponents}`;
  }
  
  private getAdjustEmployeesMassrateUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeListConfig.api.employees.root}/${employeeListConfig.api.employees.actions.root}/${employeeListConfig.api.employees.actions.payrate.root}/${employeeListConfig.api.employees.actions.payrate.massAdjustment}`;
  }
}
