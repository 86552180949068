/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-request-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "../shift-request-grid/shift-request-grid.component.ngfactory";
import * as i5 from "../../../common/services/state-management/state-management.service";
import * as i6 from "../../../common/services/component-state/component-state-storage.service";
import * as i7 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i8 from "../shift-request-grid/shift-request-grid.component";
import * as i9 from "../../services/schedule/shift-request.service";
import * as i10 from "../../../app-settings/services/app-settings-manage.service";
import * as i11 from "./shift-request-popup.component";
import * as i12 from "../../../common/services/modal/modal.service";
import * as i13 from "../../../core/services/session/session.service";
import * as i14 from "../../services/schedule-entry/schedule-entry-management.service";
var styles_ShiftRequestPopupComponent = [i0.styles];
var RenderType_ShiftRequestPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftRequestPopupComponent, data: {} });
export { RenderType_ShiftRequestPopupComponent as RenderType_ShiftRequestPopupComponent };
export function View_ShiftRequestPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "shift-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-shift-request-grid", [], null, [[null, "gridSelectionChangeHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridSelectionChangeHandler" === en)) {
        var pd_0 = (_co.getShiftDetails($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ShiftRequestGridComponent_0, i4.RenderType_ShiftRequestGridComponent)), i1.ɵprd(4608, null, i5.StateManagementService, i5.StateManagementService, [i6.ComponentStateStorageService, i7.ColumnSettingsStorageService]), i1.ɵdid(5, 114688, null, 0, i8.ShiftRequestGridComponent, [i9.ShiftRequestService, i10.AppSettingsManageService], { requestedDate: [0, "requestedDate"], currentOffset: [1, "currentOffset"] }, { gridSelectionChangeHandler: "gridSelectionChangeHandler" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loader; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.requestedDate; var currVal_2 = _co.getcurrentOffset; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_ShiftRequestPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-shift-request-popup", [], null, null, null, View_ShiftRequestPopupComponent_0, RenderType_ShiftRequestPopupComponent)), i1.ɵdid(1, 770048, null, 0, i11.ShiftRequestPopupComponent, [i12.ModalService, i13.SessionService, i14.ScheduleEntryManagementService, i9.ShiftRequestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftRequestPopupComponentNgFactory = i1.ɵccf("slx-shift-request-popup", i11.ShiftRequestPopupComponent, View_ShiftRequestPopupComponent_Host_0, { requestedDate: "requestedDate", orgLevelId: "orgLevelId" }, { selectedShiftDetails: "selectedShiftDetails" }, []);
export { ShiftRequestPopupComponentNgFactory as ShiftRequestPopupComponentNgFactory };
