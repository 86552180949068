import * as _ from 'lodash';
import { Directive, Input, OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { AccessProviderType, IAccessibleProvider } from '../../models/index';
import { MenuAccessibleProviderService } from '../../services/index';
import { IAccessibleOptions } from '../accessible/accessible.directive';
import { SwitchView } from '../../../core/models/index';

@Directive({
  selector: '[slxAccessibleSwitch]'
})
export class AccessibleSwitchDirective implements OnDestroy {
  @Input()
  public set slxAccessibleSwitch(v: IAccessibleOptions) {
    if (!v) {
      return;
    }
    this.options = v;
    this.subscribeToProvider();
    this.updateView();
  }


  private options: IAccessibleOptions;
  private changeSubscription: Subscription;
  private accessibleCase: SwitchView;
  private nonAccessibleCase: SwitchView;

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  constructor(
    protected menuAccessibleProviderService: MenuAccessibleProviderService
  ) {
  }

  public getProvider(): IAccessibleProvider {
    switch (this.options.provider) {
      case AccessProviderType.Menu:
        return this.menuAccessibleProviderService;
      default:
        throw Error('Unknown AccessProviderType');
    }
  }

  public isAccessible(): boolean {
    const provider = this.getProvider();
    return provider.isAccessible(this.options.permission);
  }

  public addAccessibleCase(view: SwitchView) {
    this.accessibleCase = view;
  }

  public addNonAccessibleCase(view: SwitchView) {
    this.nonAccessibleCase = view;
  }

  private subscribeToProvider(): void {
    this.unsubscribe();
    const provider = this.getProvider();
    this.changeSubscription = provider.subscribeToAccessibleChanged(() => this.updateView());
  }

  private unsubscribe(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
      this.changeSubscription = undefined;
    }
  }

  private updateView(): void {
    if (this.isAccessible()) {
      this.safeEnforceState(this.accessibleCase, true);
      this.safeEnforceState(this.nonAccessibleCase, false);
    } else {
      this.safeEnforceState(this.accessibleCase, false);
      this.safeEnforceState(this.nonAccessibleCase, true);
    }
  }

  private safeEnforceState(view: SwitchView, create: boolean): void {
    if (!view) {
      return;
    }
    view.enforceState(create);
  }
}
