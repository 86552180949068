export interface IStaffingOrganizationInfoRequest {
    startDate: string;
    endDate: string;
    organizationId: number;
}

export class StaffingOrganizationInfoRequest {
    public startDate: Date;
    public endDate: Date;
    public organizationId: number;
}
