import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BaseResolver } from '../base-resolver';
import { RangeDates } from '../../../models/range-dates';
import { appConfig } from '../../../../app.config';
var StartEndDateResolver = /** @class */ (function (_super) {
    tslib_1.__extends(StartEndDateResolver, _super);
    function StartEndDateResolver(router) {
        return _super.call(this, router) || this;
    }
    StartEndDateResolver.prototype.resolve = function (route, state) {
        var startDateParam = _.toString(route.queryParamMap.get('startDate'));
        var endDateParam = _.toString(route.queryParamMap.get('endDate'));
        var startDate = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        var endDate = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;
        if (startDate && endDate) {
            if (!moment(endDate).isAfter(startDate)) {
                endDate = moment(startDate).add(1, 'day').toDate();
            }
        }
        else if (startDate) {
            endDate = moment(startDate).add(1, 'day').toDate();
        }
        else if (endDate) {
            startDate = moment(endDate).subtract(1, 'day').toDate();
        }
        else {
            startDate = moment().startOf('day').toDate();
            endDate = moment(startDate).add(1, 'day').toDate();
        }
        var range = new RangeDates;
        range.startDate = startDate;
        range.endDate = endDate;
        if (!this.disableNavigation) {
            var params = this.createQueryParams(route, range);
            this.navigateToPath(route, params);
        }
        return range;
    };
    StartEndDateResolver.prototype.createQueryParams = function (route, range) {
        var params = _super.prototype.createQueryParams.call(this, route);
        params['startDate'] = moment(range.startDate).format(appConfig.linkDateFormat);
        params['endDate'] = moment(range.endDate).format(appConfig.linkDateFormat);
        return params;
    };
    return StartEndDateResolver;
}(BaseResolver));
export { StartEndDateResolver };
