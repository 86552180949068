import { Component, Input } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { ReportParameter } from '../../../models/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ILookupEntity, LookupDefinition } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pay-cycle-report-parameter',
  templateUrl: 'pay-cycle-parameter.component.html',
  styleUrls: ['pay-cycle-parameter.component.scss']
})
export class PayCycleParameterComponent {
  public includeThousandsSeparator: boolean = false;

  @Input()
  public orgLevel: OrgLevel;
  @Input()
  public set parameter(value: ReportParameter) {
    this.m_parameter = value;
    this.processParameter(this.m_parameter);
  }

  public get parameter(): ReportParameter {
    return this.m_parameter;
  }

  public get hasDefaultValue(): boolean {
    return !_.isNull(this.parameter.defaultValue) && !_.isUndefined(this.parameter.defaultValue) && this.parameter.defaultValue !== '';
  }

  public get lookupValue(): any {
    return this.m_lookupValue;
  }

  public set lookupValue(value: any) {
    if (this.m_parameter) {
      this.m_parameter.value = value;
    }
    this.m_lookupValue = value;
  }

  public get lookupName(): LookupDefinition {
    if (this.parameter && this.parameter.dataType)
      return this.parameter.dataType.lookupName;

    return undefined;
  }

  private m_parameter: ReportParameter;
  private m_lookupValue: ILookupEntity;

  private processParameter(parameter: ReportParameter): void {
    parameter.value = parameter.defaultValue;
  }
}
