/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./census-entries-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../census-entries/census-entries.component.ngfactory";
import * as i4 from "../census-entries/census-entries.component";
import * as i5 from "../../services/schedule/census-entry-api.service";
import * as i6 from "./census-entries-popup.component";
var styles_CensusEntriesPopupComponent = [i0.styles];
var RenderType_CensusEntriesPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CensusEntriesPopupComponent, data: {} });
export { RenderType_CensusEntriesPopupComponent as RenderType_CensusEntriesPopupComponent };
function View_CensusEntriesPopupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "alert alert-danger alert-dismissible"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideNotSavedErrorMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Changes did not saved! Save or discard it!"]))], null, null); }
function View_CensusEntriesPopupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-default theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 8).discardChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Discard"]))], null, null); }
function View_CensusEntriesPopupComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-default theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeWindow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel "]))], null, null); }
export function View_CensusEntriesPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { census: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "window-container": 0, "full-size": 1, "fixed-szie": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CensusEntriesPopupComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-census-entries", [["class", "census-form"]], null, [[null, "onSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSaved" === en)) {
        var pd_0 = (_co.onSave($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CensusEntriesComponent_0, i3.RenderType_CensusEntriesComponent)), i1.ɵdid(8, 245760, [[1, 4], ["census", 4]], 0, i4.CensusEntriesComponent, [i5.CensusEntryApiService], { disableDateInput: [0, "disableDateInput"], hideButtons: [1, "hideButtons"] }, { onSaved: "onSaved" }), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "buttons col-lg-4 col-md-6 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "save-btn btn btn-default theme-button-apply margin-r"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).saveChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-save"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CensusEntriesPopupComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CensusEntriesPopupComponent_3)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, true, _co.fullSize, !_co.fullSize); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.unsavedDataAlert; _ck(_v, 6, 0, currVal_1); var currVal_2 = true; var currVal_3 = true; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 8).hasChanges; _ck(_v, 14, 0, currVal_5); var currVal_6 = !i1.ɵnov(_v, 8).hasChanges; _ck(_v, 16, 0, currVal_6); }, function (_ck, _v) { var currVal_4 = !i1.ɵnov(_v, 8).canSave; _ck(_v, 10, 0, currVal_4); }); }
export function View_CensusEntriesPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-census-entries-popup", [], null, null, null, View_CensusEntriesPopupComponent_0, RenderType_CensusEntriesPopupComponent)), i1.ɵdid(1, 49152, null, 0, i6.CensusEntriesPopupComponent, [], null, null)], null, null); }
var CensusEntriesPopupComponentNgFactory = i1.ɵccf("slx-census-entries-popup", i6.CensusEntriesPopupComponent, View_CensusEntriesPopupComponent_Host_0, { fullSize: "fullSize" }, { onSaved: "onSaved", onCanceled: "onCanceled" }, []);
export { CensusEntriesPopupComponentNgFactory as CensusEntriesPopupComponentNgFactory };
