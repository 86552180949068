import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { IIpManagementConfig, IpManagementConfig } from '../../models/ip-management/ip-management-config.model';
import { IpSettings, IIpSettings } from '../../models/ip-management/ip-settings.model';
import { IpRestriction, IIpRestriction } from '../../models/ip-management/ip-restriction.model';

@Injectable()
export class IpManagementMapService {

  constructor() {}

  public mapToIpManagementConfig(dto: IIpManagementConfig): IpManagementConfig {
    const ipManagementConfig = new IpManagementConfig();

    ipManagementConfig.settings = dto.settings;
    ipManagementConfig.allowAccessList = dto.allowAccessList;
    ipManagementConfig.denyAccessList = dto.denyAccessList;

    return ipManagementConfig;
  }
  
  public mapToIpSettings(dto: IIpSettings): IpSettings {
    const ipSettings = new IpSettings();

    ipSettings.enforceWhitelistRestrictions = dto.enforceWhitelistRestrictions;
    ipSettings.enforceBlacklistRestrictions = dto.enforceBlacklistRestrictions;

    return ipSettings;
  }

  public mapToIpRestriction(dto: IIpRestriction): IpRestriction {
    const ipRestriction = new IpRestriction();

    ipRestriction.id = dto.id;

    ipRestriction.ipAddress = dto.ipAddress;
    ipRestriction.description = dto.description;

    ipRestriction.isAllowRestriction = dto.isAllowRestriction;

    ipRestriction.isEnabled = dto.isEnabled;
    ipRestriction.useInV6 = dto.useInV6;
    ipRestriction.useInGo = dto.useInGo;

    return ipRestriction;
  }

}
