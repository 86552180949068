import { UserProfileApiService } from './user-profile-api.service';
var UserValidatorAdapter = /** @class */ (function () {
    function UserValidatorAdapter(userApiService) {
        this.userApiService = userApiService;
    }
    UserValidatorAdapter.prototype.validate = function (validationName, value) {
        var params = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            params[_i - 2] = arguments[_i];
        }
        var promise;
        var defPromise = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        switch (validationName) {
            case UserValidatorAdapter.loginValidation:
                promise = this.userApiService.validateUserName(value);
                break;
            default:
                promise = defPromise;
        }
        return promise;
    };
    UserValidatorAdapter.loginValidation = 'loginValidation';
    return UserValidatorAdapter;
}());
export { UserValidatorAdapter };
