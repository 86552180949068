import { Component, Input } from '@angular/core';
import {
  ScrollWatchService,
  ISelectableItemContainer,
  SelectableItemsProducer,
  IDialog,
  ModalService,
  DialogOptions
} from '../../../../common/index';
import { Assert } from '../../../../framework/index';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { EmployeePositionDefinition } from '../../../../organization/models/index';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

@Component({
  moduleId: module.id,
  selector: 'employee-position-selector',
  templateUrl: 'employee-position-selector.component.html',
  styleUrls: ['employee-position-selector.component.scss']
})

export class EmployeePositionSelectorComponent implements IDialog {
  @Input()
  public set isLoading(loading: boolean) {
    if (loading) {
      this.startProgress();
    } else {
      this.stopProgress();
    }
  }
  @Input() public componentId: string;
  @Input() public isSingleItemSelectable: boolean;

  public isAnyItemSelected: boolean;
  public options: DialogOptions;
  public dialogResult: boolean;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public isLoaded: boolean;

  private selectableEmployeePositions: ISelectableItemContainer<EmployeePositionDefinition>[];
  private modalService: ModalService;
  private selectableItemsProducer: SelectableItemsProducer;
  private currentlySelected: ISelectableItemContainer<EmployeePositionDefinition>;
  protected checkTemporalDirty(): boolean {
    return false;
  }

  constructor(scrollWatchService: ScrollWatchService, selectableItemsProducer: SelectableItemsProducer, options: DialogOptions, modalService: ModalService) {
    Assert.isNotNull(selectableItemsProducer, 'selectableItemsProducer');
    this.selectableItemsProducer = selectableItemsProducer;
    Assert.isNotNull(options, 'options');
    this.options = options;
    Assert.isNotNull(modalService, 'modalService');
    this.modalService = modalService;
    this.dialogResult = false;
    this.isSingleItemSelectable = false;
  }

  public get selectedItems(): EmployeePositionDefinition[] {
    let selectedItems: EmployeePositionDefinition[] = [];
    this
      .selectableEmployeePositions
      .forEach((i: ISelectableItemContainer<EmployeePositionDefinition>) => {
        if (i.selected)
          selectedItems.push(i.item);
      }
      );
    return selectedItems;
  }

  public set employeePositions(employeePositions: EmployeePositionDefinition[]) {
    if (employeePositions !== null) {
      this.selectableEmployeePositions = this.selectableItemsProducer.produceSelectable<EmployeePositionDefinition>(employeePositions);
      this.refreshGrid();
    }
  }

  public onItemSelectionChanged(value: ISelectableItemContainer<EmployeePositionDefinition>): void {
    if (this.isSingleItemSelectable && value.selected) {
      if (this.currentlySelected) {
        this.currentlySelected.selected = false;
      }
      this.currentlySelected = value;
    }

    this.isAnyItemSelected = this
      .selectableEmployeePositions
      .some((i: ISelectableItemContainer<EmployeePositionDefinition>) => i.selected);
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public onOk(): void {
    this.dialogResult = true;
    this
      .modalService
      .closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this
      .modalService
      .closeWindow(this.options.windowUniqueId);
  }

  protected startProgress(): void {
    this.isLoaded = true;
  }

  protected stopProgress(): void {
    this.isLoaded = false;
  }

  private refreshGrid(): void {
    if (!this.selectableEmployeePositions) {
      this.gridView = null;
      return;
    }
    this.gridView = {
      data: orderBy(this.selectableEmployeePositions, this.sort),
      total: this.selectableEmployeePositions.length
    };
  }
}
