import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Assert } from '../../../../framework/assert/assert';
import { LookupService } from '../../../../organization/services/index';
import { LookupType, ReadFile } from '../../../../organization/models/index';
import { DateRange } from '../../../../core/models/index';
import { LMApiService } from '../lm-api.service';
import { LoaType, LoaRequest, LoaRepeat, LoaRequestEmployee, RecurrenceFrequencyDefinition, RecurrenceFrequency, WeekDay, WeekDays, RecurrenceRuleOption, OffsetPosition } from '../../models/index';
import { LMManagementService } from '../../services/lm-management.service';
import { LMRecurrenceService } from '../../services/lm-recurrence/lm-recurrence.service';
import { LMRequestMapperService } from '../../services/lm-request-mapper/lm-request-mapper.service';
import { FileService } from '../../../../common/index';
import { LoaRequestClass } from '../../../../common/models';
var LMCreationAbsenceManagementService = /** @class */ (function () {
    function LMCreationAbsenceManagementService(manService, recurrenceService, mapperService, lookupService, apiService, fileService) {
        this.manService = manService;
        this.recurrenceService = recurrenceService;
        this.mapperService = mapperService;
        this.lookupService = lookupService;
        this.apiService = apiService;
        this.fileService = fileService;
        this.loading$ = new ReplaySubject(1);
        this.addedNewLoa$ = new ReplaySubject(1);
        this.requestChange$ = new ReplaySubject(1);
        this.closePopup$ = new Subject();
        this.editModeOn = false;
        this.recurrenceInitialized = false;
        this.weekDaysNames = _.values(WeekDays);
        this.dayOfStartRecurrence = WeekDays.monday;
        this.customDates = [];
    }
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "loaRequest", {
        get: function () {
            return _.cloneDeep(this.m_loaRequest);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "isContinuous", {
        get: function () {
            return _.get(this.m_loaRequest, 'requestClass') === LoaRequestClass.Continuous;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "isIntermittent", {
        get: function () {
            return _.get(this.m_loaRequest, 'requestClass') === LoaRequestClass.Intermittent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canAddEdit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "canDeleteRequest", {
        get: function () {
            return this.manService.canDelete;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "editMode", {
        get: function () {
            return this.editModeOn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "canChange", {
        get: function () {
            return this.canEditRequest && this.editMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "isCreatingNew", {
        get: function () {
            return !_.isFinite(this.m_loaRequest && this.m_loaRequest.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "hasEstimatedDates", {
        get: function () {
            return _.isDate(this.m_loaRequest.estStartDate) && _.isDate(this.m_loaRequest.estEndDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "hasActualDates", {
        get: function () {
            return _.isDate(this.m_loaRequest.actStartDate) && _.isDate(this.m_loaRequest.actEndDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "recurrenceFrequencies", {
        get: function () {
            return this.recurrenceService.getRecurrenceFrequencies();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "weekDays", {
        get: function () {
            return this.recurrenceService.getWeekDays();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "offsetPositions", {
        get: function () {
            return this.recurrenceService.getOffsetPositions();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "isCustom", {
        get: function () {
            return this.m_loaRequest.isCustom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "isDaily", {
        get: function () {
            return this.m_loaRequest.isDaily;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "isWeekly", {
        get: function () {
            return this.m_loaRequest.isWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "isMonthly", {
        get: function () {
            return this.m_loaRequest.isMonthly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "hasCorrectContinuousDates", {
        get: function () {
            return this.m_loaRequest.hasCorrectContinuousDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "hasCorrectRepeatableDates", {
        get: function () {
            return this.m_loaRequest.hasCorrectRepeatableDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "hasCorrectCustomDates", {
        get: function () {
            return this.m_loaRequest.hasCorrectCustomDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreationAbsenceManagementService.prototype, "weekDayIndex", {
        get: function () {
            return _.indexOf(this.weekDaysNames, this.dayOfStartRecurrence);
        },
        enumerable: true,
        configurable: true
    });
    LMCreationAbsenceManagementService.prototype.init = function (orgLevelId, req) {
        this.orgLevelId = orgLevelId;
        this.m_loaRequest = _.cloneDeep(req);
        this.customDates = this.m_loaRequest.loaRepeat.customDates;
        if (this.m_loaRequest && this.orgLevelId) {
            if (this.isCreatingNew) {
                this.toggleEditMode(true);
            }
            this.updateRequestData();
        }
    };
    LMCreationAbsenceManagementService.prototype.initRecurrenceService = function (rrule, freq) {
        if (!this.recurrenceInitialized) {
            var reccurence = void 0;
            if (_.isString(rrule) && _.size(rrule) > 0) {
                reccurence = this.recurrenceService.parseRule(rrule);
            }
            if (_.isString(freq) && _.size(freq) > 0) {
                reccurence = this.recurrenceService.setRule(freq, this.weekDayIndex);
            }
            this.m_loaRequest.loaRepeat.recurrenceRule = _.isString(reccurence) && reccurence || '';
            this.recurrenceInitialized = true;
        }
    };
    LMCreationAbsenceManagementService.prototype.destroy = function () {
        this.m_loaRequest = null;
        this.loading$.complete();
        this.addedNewLoa$.complete();
        this.requestChange$.complete();
        this.closePopup$.complete();
        this.recurrenceInitialized = false;
    };
    LMCreationAbsenceManagementService.prototype.loadRequests = function () {
        this.manService.loadRequests();
    };
    LMCreationAbsenceManagementService.prototype.loadAbsenceTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.getLoaTypes()];
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.loadEmployees = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevelId, isActive: true })];
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.createLoaRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.createLoaRequest(this.m_loaRequest)];
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.updateLoaRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.updateLoaRequest(this.m_loaRequest)];
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.deleteLoaRequest = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.deleteLoaRequest(this.m_loaRequest.id)];
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.deleteAttachment = function (attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.apiService.deleteAttachment(attachmentId, this.m_loaRequest.id)];
                    case 1:
                        _a.sent();
                        this.m_loaRequest.attachments = _.filter(this.m_loaRequest.attachments, function (a) { return a.id !== attachmentId; });
                        return [2 /*return*/];
                }
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.attachSavedFiles = function (loa) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.size(this.m_loaRequest.filesToSave) > 0) {
                    return [2 /*return*/, this.apiService.saveAddedFiles(loa.id, this.m_loaRequest.filesToSave)];
                }
                return [2 /*return*/, Promise.resolve(loa)];
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.getAbsenceDays = function () {
        return this.mapperService.buildAbsenceDays(this.m_loaRequest);
    };
    LMCreationAbsenceManagementService.prototype.changeLoading = function (isLoading) {
        this.loading$.next(isLoading);
    };
    LMCreationAbsenceManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    LMCreationAbsenceManagementService.prototype.closePopup = function () {
        this.closePopup$.next();
    };
    LMCreationAbsenceManagementService.prototype.subscribeToClosePopup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.closePopup$.subscribe(callback);
    };
    LMCreationAbsenceManagementService.prototype.setEmployeeId = function (empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lookup, employee;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loadEmployees()];
                    case 1:
                        lookup = _a.sent();
                        employee = _.find(lookup.items, function (x) {
                            return x.id == empId;
                        });
                        if (employee) {
                            this.setEmployee(employee);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.setEmployee = function (emp) {
        if (_.isObjectLike(emp) && _.isFinite(emp.id)) {
            this.m_loaRequest = this.addEmployeeToRequest(emp);
            this.requestChange$.next(this.m_loaRequest);
        }
    };
    LMCreationAbsenceManagementService.prototype.subscribeToChangedRequest = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.requestChange$.subscribe(callback);
    };
    LMCreationAbsenceManagementService.prototype.toggleEditMode = function (editModeOn) {
        this.editModeOn = _.isBoolean(editModeOn) ? editModeOn : !this.editModeOn;
    };
    LMCreationAbsenceManagementService.prototype.readAddedFiles = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promises, readedFiles, binaryData, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = _.map(files, function (f) { return _this.readFileData(f); });
                        readedFiles = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        binaryData = _a.sent();
                        readedFiles = this.mapDateToReadFiles(files, binaryData);
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, readedFiles];
                }
            });
        });
    };
    LMCreationAbsenceManagementService.prototype.addFilesToSave = function (files) {
        if (_.isArray(files)) {
            this.m_loaRequest.filesToSave = this.m_loaRequest.filesToSave.concat(files.concat());
        }
    };
    LMCreationAbsenceManagementService.prototype.deleteFileToSave = function (file) {
        if (_.isObjectLike(file)) {
            this.m_loaRequest.filesToSave = _.filter(this.m_loaRequest.filesToSave, function (f) { return f.fileName !== file.fileName; });
        }
    };
    LMCreationAbsenceManagementService.prototype.getFilesToSave = function () {
        if (_.isArray(this.m_loaRequest.filesToSave)) {
            return this.m_loaRequest.filesToSave.concat();
        }
        return [];
    };
    LMCreationAbsenceManagementService.prototype.getStartOfDay = function (d) {
        return new Date(new Date(d.getTime()).setHours(0, 0, 0, 0));
    };
    LMCreationAbsenceManagementService.prototype.getEndOfDay = function (d) {
        return new Date(new Date(d.getTime()).setHours(23, 59, 0, 0));
    };
    LMCreationAbsenceManagementService.prototype.setRequestDate = function (date) {
        this.m_loaRequest.requestDate = date;
    };
    LMCreationAbsenceManagementService.prototype.getRequestDate = function () {
        return this.m_loaRequest.requestDate;
    };
    LMCreationAbsenceManagementService.prototype.setEstimatedDates = function (sDate, eDate) {
        var hasChanges = false;
        if (_.isDate(sDate)) {
            hasChanges = true;
            this.m_loaRequest.estStartDate = sDate;
        }
        if (_.isDate(eDate)) {
            hasChanges = true;
            this.m_loaRequest.estEndDate = eDate;
        }
        if (hasChanges) {
            this.evaluateAbsencePeriodFromRule();
            this.requestChange$.next(this.m_loaRequest);
        }
    };
    LMCreationAbsenceManagementService.prototype.setActualDates = function (sDate, eDate) {
        if (_.isDate(sDate)) {
            this.m_loaRequest.actStartDate = sDate;
        }
        if (_.isDate(eDate)) {
            this.m_loaRequest.actEndDate = eDate;
        }
        this.requestChange$.next(this.m_loaRequest);
    };
    LMCreationAbsenceManagementService.prototype.getEstamatedDates = function () {
        return this.getEstOrActualDates(true);
    };
    LMCreationAbsenceManagementService.prototype.getActualDates = function () {
        return this.getEstOrActualDates(false);
    };
    LMCreationAbsenceManagementService.prototype.getLoaDates = function () {
        var estimated = this.getEstamatedDates();
        var actual = this.getActualDates();
        var sDate = _.isDate(estimated.startDate) ? new Date(estimated.startDate.getTime()) : null;
        var eDate = _.isDate(estimated.endDate) ? new Date(estimated.endDate.getTime()) : null;
        if (_.isDate(actual.startDate)) {
            sDate = new Date(actual.startDate.getTime());
        }
        if (_.isDate(actual.endDate)) {
            eDate = new Date(actual.endDate.getTime());
        }
        return new DateRange(sDate, eDate);
    };
    LMCreationAbsenceManagementService.prototype.setExceptions = function (exceptions) {
        if (_.isArray(exceptions)) {
            this.m_loaRequest.exceptionDates = exceptions.concat();
        }
    };
    LMCreationAbsenceManagementService.prototype.getExceptions = function () {
        if (_.size(this.m_loaRequest.exceptionDates) > 0) {
            return this.m_loaRequest.exceptionDates.concat();
        }
        return [];
    };
    LMCreationAbsenceManagementService.prototype.setNotes = function (notes) {
        if (_.isString(notes)) {
            this.m_loaRequest.notes = notes;
        }
    };
    LMCreationAbsenceManagementService.prototype.getNotes = function () {
        if (_.isString(this.m_loaRequest.notes)) {
            return this.m_loaRequest.notes;
        }
        return '';
    };
    LMCreationAbsenceManagementService.prototype.setAbsenceType = function (type) {
        this.m_loaRequest.type = type;
    };
    LMCreationAbsenceManagementService.prototype.getAbsenceType = function () {
        if (_.isObjectLike(this.m_loaRequest.type)) {
            return this.m_loaRequest.type;
        }
        return null;
    };
    LMCreationAbsenceManagementService.prototype.setAbsenceReason = function (reason) {
        this.m_loaRequest.reason = reason;
    };
    LMCreationAbsenceManagementService.prototype.getAbsenceReason = function () {
        if (_.isString(this.m_loaRequest.reason)) {
            return this.m_loaRequest.reason;
        }
        return '';
    };
    LMCreationAbsenceManagementService.prototype.setAttachments = function (attachments) {
        if (_.isArray(attachments)) {
            this.m_loaRequest.attachments = attachments.concat();
        }
    };
    LMCreationAbsenceManagementService.prototype.getAttachments = function () {
        if (_.size(this.m_loaRequest.attachments) > 0) {
            return this.m_loaRequest.attachments.concat();
        }
        return [];
    };
    LMCreationAbsenceManagementService.prototype.setCustomDates = function (dates) {
        var loaRepeat = _.get(this.m_loaRequest, 'loaRepeat');
        if (_.isObjectLike(loaRepeat)) {
            loaRepeat.customDates = _.map(dates, function (r) { return new DateRange(new Date(r.startDate.getTime()), new Date(r.endDate.getTime())); });
            this.customDates = loaRepeat.customDates;
            this.requestChange$.next(this.m_loaRequest);
        }
        this.evaluateAbsencePeriodFromCustomDates();
    };
    LMCreationAbsenceManagementService.prototype.getCustomDates = function () {
        var loaRepeat = _.get(this.m_loaRequest, 'loaRepeat');
        if (_.isObjectLike(loaRepeat)) {
            return _.map(loaRepeat.customDates, function (r) { return new DateRange(new Date(r.startDate.getTime()), new Date(r.endDate.getTime())); });
        }
        return [];
    };
    LMCreationAbsenceManagementService.prototype.setFrequency = function (freq) {
        var reccurence = this.recurrenceService.createRRule(freq, this.weekDayIndex);
        this.m_loaRequest.loaRepeat = new LoaRepeat('', _.isString(reccurence) && reccurence || '');
        this.m_loaRequest.loaRepeat.customDates = this.customDates;
        this.evaluateAbsencePeriodFromRule();
    };
    LMCreationAbsenceManagementService.prototype.setRecurrence = function (freq, data) {
        var recurrenceRule = this.recurrenceService.setRecurrence(freq, data);
        var loaRepeat = _.get(this.m_loaRequest, 'loaRepeat');
        if (_.isObjectLike(loaRepeat)) {
            loaRepeat.recurrenceRule = recurrenceRule;
        }
        this.evaluateAbsencePeriodFromRule();
        return recurrenceRule;
    };
    LMCreationAbsenceManagementService.prototype.getRecurrence = function () {
        return this.recurrenceService.getRecurrence();
    };
    LMCreationAbsenceManagementService.prototype.setEstAllDay = function (isChecked) {
        this.m_loaRequest.estAllDayCheckbox = !!isChecked;
    };
    LMCreationAbsenceManagementService.prototype.getEstAllDay = function () {
        if (this.m_loaRequest && this.m_loaRequest.estStartDate && this.m_loaRequest.estEndDate) {
            if (moment(this.m_loaRequest.estStartDate).format("hh:mm:ss a") == '12:00:00 am'
                && moment(this.m_loaRequest.estEndDate).format("hh:mm:ss a") == '11:59:00 pm') {
                return this.m_loaRequest.estAllDayCheckbox = true;
            }
            else {
                return this.m_loaRequest.estAllDayCheckbox = false;
            }
        }
        else {
            return this.m_loaRequest.estAllDayCheckbox;
        }
    };
    LMCreationAbsenceManagementService.prototype.setActAllDay = function (isChecked) {
        this.m_loaRequest.actAllDayCheckbox = !!isChecked;
    };
    LMCreationAbsenceManagementService.prototype.getActAllDay = function () {
        if (this.m_loaRequest && this.m_loaRequest.actStartDate && this.m_loaRequest.actEndDate) {
            if (moment(this.m_loaRequest.actStartDate).format("hh:mm:ss a") == '12:00:00 am'
                && moment(this.m_loaRequest.actEndDate).format("hh:mm:ss a") == '11:59:00 pm') {
                return this.m_loaRequest.actAllDayCheckbox = true;
            }
            else {
                return this.m_loaRequest.actAllDayCheckbox = false;
            }
        }
        else {
            return this.m_loaRequest.actAllDayCheckbox;
        }
    };
    LMCreationAbsenceManagementService.prototype.getLoaRepeat = function () {
        if (_.isObjectLike(this.m_loaRequest.loaRepeat)) {
            return this.m_loaRequest.loaRepeat;
        }
        return new LoaRepeat();
    };
    LMCreationAbsenceManagementService.prototype.downloadAttachment = function (attachmentId) {
        var _this = this;
        try {
            var promise = this.apiService.downloadAttachment(attachmentId)
                .then(function (file) {
                return _this.fileService.saveToFileSystem(file.blob, file.file);
            });
            return promise;
        }
        catch (e) {
            console.error(e);
        }
    };
    LMCreationAbsenceManagementService.prototype.getEstOrActualDates = function (isEstDates) {
        var sDate = _.get(this.m_loaRequest, isEstDates ? 'estStartDate' : 'actStartDate');
        var eDate = _.get(this.m_loaRequest, isEstDates ? 'estEndDate' : 'actEndDate');
        if (!_.isDate(sDate))
            sDate = null;
        if (!_.isDate(eDate))
            eDate = null;
        return new DateRange(sDate, eDate);
    };
    LMCreationAbsenceManagementService.prototype.mapDateToReadFiles = function (files, binaryData) {
        return _.map(files, function (file, i) {
            var index = file.name.lastIndexOf('.');
            var name = file.name.slice(0, index);
            var ext = file.name.slice(index + 1);
            var data = new Blob([binaryData[i]]);
            return new ReadFile(name, file.size, file.type, ext, data);
        });
    };
    LMCreationAbsenceManagementService.prototype.readFileData = function (file) {
        return new Promise(function (resolve, reject) {
            var fr = new FileReader();
            fr.onloadend = function () {
                var buffer = fr.result;
                resolve(buffer);
            };
            fr.onerror = function () {
                reject(fr.error);
            };
            fr.readAsArrayBuffer(file);
        });
    };
    LMCreationAbsenceManagementService.prototype.addEmployeeToRequest = function (emp) {
        var loa = new LoaRequest();
        loa.employee = new LoaRequestEmployee(emp.id, emp.name);
        loa.organization = _.cloneDeep(emp.organization);
        loa.department = _.cloneDeep(emp.department);
        loa.position = _.cloneDeep(emp.position);
        loa.requestClass = this.m_loaRequest.requestClass;
        loa.requestDate = this.m_loaRequest.requestDate;
        return loa;
    };
    LMCreationAbsenceManagementService.prototype.evaluateAbsencePeriodFromRule = function () {
        var estStartDate = this.m_loaRequest.estStartDate;
        var rule = _.get(this.m_loaRequest.loaRepeat, 'recurrenceRule') || '';
        if (_.isDate(estStartDate) && _.size(rule) > 0) {
            var range = this.mapperService.evaluateClearAbsencePeriod(estStartDate, rule);
            if (_.isDate(range.startDate)) {
                this.m_loaRequest.absencePeriod = new DateRange(range.startDate, range.endDate);
            }
        }
    };
    LMCreationAbsenceManagementService.prototype.evaluateAbsencePeriodFromCustomDates = function () {
        var customDates = _.get(this.m_loaRequest.loaRepeat, 'customDates');
        if (_.size(customDates) > 0) {
            var sorted = _.sortBy(customDates, function (r) { return r.startDate.getTime(); });
            this.m_loaRequest.absencePeriod = new DateRange(_.head(sorted).startDate, _.last(sorted).endDate);
        }
    };
    LMCreationAbsenceManagementService.prototype.updateRequestData = function () {
        if (_.isFinite(this.m_loaRequest.empId)
            && _.isNil(this.m_loaRequest.employee.name)
            && _.isNil(this.m_loaRequest.position)
            && _.isNil(this.m_loaRequest.department)
            && _.isNil(this.m_loaRequest.organization)) {
            this.setEmployeeId(this.m_loaRequest.empId);
        }
    };
    return LMCreationAbsenceManagementService;
}());
export { LMCreationAbsenceManagementService };
