import { Component, EventEmitter,Input, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-labeled-value',
  templateUrl: 'labeled-value.component.html',
  styleUrls: ['labeled-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledValueComponent {

  @Input()
  public label: string;

  @Input()
  public value: number;

  @Input()
  public color: string = 'inherit';

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();

  public onClickHandler(event: any): void {
    this.onClick.emit(event);
  }
}
