import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { TimeclockDataService, } from '../../../organization/index';
import { TaSignalrService } from '../../services/signalR/ta-signalr-service';
import { SessionService } from '../../../../app/core/services';
import { NotificationsService } from '../../../../app/core/components';
var TimeclockReviewComponent = /** @class */ (function () {
    function TimeclockReviewComponent(timeclockDataService, taSignalrService, sessionService, notificationService) {
        this.timeclockDataService = timeclockDataService;
        this.taSignalrService = taSignalrService;
        this.sessionService = sessionService;
        this.notificationService = notificationService;
    }
    TimeclockReviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.taSignalrService.checkAndEstablishConnection();
        this.taSignalrService.isRebooted$.subscribe(function (args) {
            if (_this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase()) {
                var itemIndex = _this.timeclockDailySummaryContainer.records.findIndex(function (e) { return e.id === JSON.parse(args[2]).ClockId; });
                _this.timeclockDailySummaryContainer.records[itemIndex].isTimeClockRebootStarted = false;
                _this.timeclockDailySummaryContainer.records[itemIndex].rebootStatus = 'Completed';
                _this.notificationService.success('Sucess', " Clock reboot sucessfully completed for " + _this.timeclockDailySummaryContainer.records[itemIndex].name);
            }
        });
        this.timeclockLoadedSubscription = this.timeclockDataService.onLoaded$
            .subscribe(function (timeclocksContainer) {
            timeclocksContainer.records = _.orderBy(timeclocksContainer.records, ['isVirtual', 'communicationStatus', 'sortName']);
            _this.timeclockDailySummaryContainer = timeclocksContainer;
        });
        this.timeclockDataService.loadTimeclocks();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockReviewComponent.prototype, "timeclockLoadedSubscription", void 0);
    return TimeclockReviewComponent;
}());
export { TimeclockReviewComponent };
