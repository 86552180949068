<form #midNightForm="ngForm">
    <kendo-grid class="slx-mTom-config-grid" [data]="gridData">
        <kendo-grid-column title="Department" field="department"></kendo-grid-column>
        <kendo-grid-column title="Budgeted Position" field="budgetedPosition"></kendo-grid-column>
        <kendo-grid-column title="Position" field="position"></kendo-grid-column>
        <kendo-grid-column title="Percent of Hours" field="hourPercentage" [width]="250">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="slx-slider-hours">
                    <kendo-slider class="slx-custom-kendo-slider" id="value" [min]="0" [max]="100" [smallStep]="1" [(ngModel)]="dataItem.hourPercentage"
                        [disabled]="!dataItem.editPermission" [ngModelOptions]="{standalone: true}"
                        (valueChange)="onValueChange($event, dataItem.id)">
                    </kendo-slider>
                    <span class="slx-value-check">{{dataItem.hourPercentage}}</span>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</form>