import { NgForm } from '@angular/forms';
import { UserPasswordSettingsApiService } from './../../services/index';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserPasswordSettings } from '../../../core/models/index';
import * as _ from 'lodash';
@Component({
    moduleId: module.id,
    selector: 'slx-user-password-settings',
    templateUrl: 'user-password-settings.component.html',
    styleUrls: ['user-password-settings.component.scss']
})

export class UserPasswordSettingsComponent implements OnInit {

    @ViewChild('passForm', {static: true})
    public passForm: NgForm;
    public isLoading: boolean;
    public setting: UserPasswordSettings;
    public originalSetting: UserPasswordSettings;

    @Input()
    public hideToolbar: boolean;
    constructor(private apiService: UserPasswordSettingsApiService) {
        //1;
    }

    public ngOnInit(): void {
        this.loadData();
    }

    public loadData(): void {
        this.isLoading = true;
        this.apiService.getSettings().then((value: UserPasswordSettings) => {
            this.originalSetting = value;
            this.discardChanges();
            this.isLoading = false;
        }).catch((reason: any) => {
            this.isLoading = false;
        });
    }

    public saveData(): Promise<boolean> {
        this.isLoading = true;
        return this.apiService.saveSettings(this.setting).then((value: any) => {
            this.originalSetting = this.setting;
            this.discardChanges();
            this.isLoading = false;
            return true;
        }).catch((reason: any) => {
            this.isLoading = false;
            return false;
        });
    }

    public discardChanges(): void {
        this.setting = _.cloneDeep(this.originalSetting);
    }

    public get hasChanges(): boolean {
        return !_.isEqual(this.setting, this.originalSetting);
    }

    public get canSave(): boolean {
        return this.passForm.valid && this.hasChanges;
    }

    public get lifetimeEnabled(): boolean {
       if (this.setting && this.setting.userPasswordLifetime) {
           return this.setting.userPasswordLifetime.enabled;
       } else {
           return false;
       }
    }

    public set lifetimeEnabled(value: boolean) {
        if (this.setting && this.setting.userPasswordLifetime) {
            this.setting.userPasswordLifetime.enabled = value;
        }
    }

}
