/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-unit-remove-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../common/components/input-decorator/input-decorator.component.ngfactory";
import * as i4 from "../../../../common/components/input-decorator/input-decorator.component";
import * as i5 from "../../../../common/components/dropdown-input/dropdown-input.component.ngfactory";
import * as i6 from "../../../../common/components/dropdown-input/dropdown-input.component";
import * as i7 from "../../../../common/error-filters/errorMessage";
import * as i8 from "./location-unit-remove-dialog.component";
import * as i9 from "../../../../common/models/dialog-options";
import * as i10 from "../../../../common/services/modal/modal.service";
var styles_UnitRemoveDialogComponent = [i0.styles];
var RenderType_UnitRemoveDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnitRemoveDialogComponent, data: {} });
export { RenderType_UnitRemoveDialogComponent as RenderType_UnitRemoveDialogComponent };
export function View_UnitRemoveDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, [["form", 4]], 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 26, "div", [["class", "editor-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 19, "div", [["class", "field-container wrapped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 18, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 17, "slx-input-decorator", [], null, null, null, i3.View_InputDecoratorComponent_0, i3.RenderType_InputDecoratorComponent)), i1.ɵdid(9, 311296, null, 4, i4.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵqud(603979776, 1, { fieldChild1: 0 }), i1.ɵqud(603979776, 2, { fieldChild2: 0 }), i1.ɵqud(603979776, 3, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 4, { errorMessages: 1 }), (_l()(), i1.ɵeld(14, 0, null, 0, 8, "slx-dropdown-input", [["name", "unitSelector"], ["placeholder", "Select new Unit for Employees"], ["slx-input", ""], ["titleField", "description"], ["valueField", "id"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [8, "className", 0]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.unit = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DropdownInputComponent_0, i5.RenderType_DropdownInputComponent)), i1.ɵdid(15, 16384, null, 0, i2.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.RequiredValidator]), i1.ɵdid(17, 49152, null, 0, i6.DropdownInputComponent, [i1.ChangeDetectorRef], { options: [0, "options"], valueField: [1, "valueField"], titleField: [2, "titleField"], placeholder: [3, "placeholder"] }, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DropdownInputComponent]), i1.ɵdid(19, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(21, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵdid(22, 540672, [[2, 4]], 0, i4.SlxInputAltDirective, [[2, i2.NgControl], i1.ElementRef], { required: [0, "required"], placeholder: [1, "placeholder"] }, null), (_l()(), i1.ɵeld(23, 0, null, 3, 2, "span", [["errorMessage", ""], ["for", "required"]], null, null, null, null, null)), i1.ɵdid(24, 81920, [[4, 4]], 0, i7.ErrorMessageDirective, [i1.ElementRef], { for: [0, "for"] }, null), (_l()(), i1.ɵted(-1, null, ["Please, select unit"])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "div", [["class", "actions-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 1, "button", [["class", "action-button save-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reassign"])), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "action-button cancel-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 9, 0); var currVal_16 = true; _ck(_v, 15, 0, currVal_16); var currVal_17 = _co.units; var currVal_18 = "id"; var currVal_19 = "description"; var currVal_20 = "Select new Unit for Employees"; _ck(_v, 17, 0, currVal_17, currVal_18, currVal_19, currVal_20); var currVal_21 = "unitSelector"; var currVal_22 = _co.unit; _ck(_v, 19, 0, currVal_21, currVal_22); var currVal_23 = true; var currVal_24 = "Select new Unit for Employees"; _ck(_v, 22, 0, currVal_23, currVal_24); var currVal_25 = "required"; _ck(_v, 24, 0, currVal_25); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = (i1.ɵnov(_v, 15).required ? "" : null); var currVal_8 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 21).ngClassValid; var currVal_13 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 21).ngClassPending; var currVal_15 = i1.ɵnov(_v, 22).className; _ck(_v, 14, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_26 = i1.ɵnov(_v, 2).invalid; _ck(_v, 28, 0, currVal_26); }); }
export function View_UnitRemoveDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-location-unit-remove-dialog", [], null, null, null, View_UnitRemoveDialogComponent_0, RenderType_UnitRemoveDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.UnitRemoveDialogComponent, [i9.DialogOptions, i10.ModalService], null, null)], null, null); }
var UnitRemoveDialogComponentNgFactory = i1.ɵccf("slx-location-unit-remove-dialog", i8.UnitRemoveDialogComponent, View_UnitRemoveDialogComponent_Host_0, { units: "units" }, {}, []);
export { UnitRemoveDialogComponentNgFactory as UnitRemoveDialogComponentNgFactory };
