import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';

import { ValuePairChartModel, ValuePairWidgetConfig } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-overview-tooltip',
  templateUrl: 'schedule-console-overview-tooltip.component.html',
  styleUrls: ['schedule-console-overview-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleConsoleOverviewTooltipComponent {

  @Input()
  public config: ValuePairWidgetConfig;

  @Input()
  public model: ValuePairChartModel;

  public constructor(
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}
