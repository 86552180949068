import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { appConfig } from '../../../app.config';
import { KendoGridStateHelper, DeviceDetectorService, EmployeeSectionNavigationService } from '../../../common';
import { process } from '@progress/kendo-data-query';
import { AttendancePointsApiService } from '../../services';
import { ActivatedRoute, Router } from '@angular/router';
var AttendanccePointsTotalsComponent = /** @class */ (function () {
    function AttendanccePointsTotalsComponent(devDetector, api, activatedRoute, router) {
        this.devDetector = devDetector;
        this.api = api;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.isActiveOnly = true;
        this.xlsxName = 'Attendance_Points_Totals_';
        this.pdfName = 'Attendance_Points_Totals_';
        this.columnsGroupName = 'AttendancePointsTotals';
        this.pageSize = 50;
        this.subscriptions = {};
        this.state = {
            isLoading: false
        };
        this.startDate = moment().subtract(1, 'year').toDate();
        this.endDate = moment().toDate();
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'organization.name', dir: 'asc' },
            { field: 'department.name', dir: 'asc' },
            { field: 'employee.name', dir: 'asc' }
        ];
    }
    Object.defineProperty(AttendanccePointsTotalsComponent.prototype, "isLockedColumn", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    AttendanccePointsTotalsComponent.prototype.ngOnDestroy = function () {
        this.appConfig = null;
    };
    AttendanccePointsTotalsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        var date = this.getCurrentDate();
        var dateStr = moment(date).format(appConfig.dateFormat);
        this.xlsxName += dateStr + ".xlsx";
        this.pdfName += dateStr + ".pdf";
        this.subscriptions.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.application)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentApplication = application;
            _this.loadTotals();
        });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    AttendanccePointsTotalsComponent.prototype.loadTotals = function () {
        var _this = this;
        this.state.isLoading = true;
        this.gridState.state.skip = 0;
        this.api.loadAttendancePointsTotals(this.currentOrgLevel.id, this.isActiveOnly)
            .then(function (data) {
            _this.records = data.records;
            _this.buyBackEndabled = data.buyBackEnabled;
            _this.refreshGrid();
        }).finally(function () {
            _this.state.isLoading = false;
        });
    };
    AttendanccePointsTotalsComponent.prototype.onDisplaySettingsChanged = function () {
        this.loadTotals();
    };
    AttendanccePointsTotalsComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadTotals();
    };
    AttendanccePointsTotalsComponent.prototype.onEmpNameClick = function (empId) {
        var navService = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
        var urlTree = navService.getUrlTreeFromRoot(empId, true);
        var extras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(urlTree, extras);
    };
    AttendanccePointsTotalsComponent.prototype.onExportToExcel = function () {
        this.grid.saveAsExcel();
    };
    AttendanccePointsTotalsComponent.prototype.onExportToPdf = function () {
        this.grid.saveAsPDF();
    };
    AttendanccePointsTotalsComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () {
            return {
                data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
            };
        };
    };
    AttendanccePointsTotalsComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    AttendanccePointsTotalsComponent.prototype.getCurrentDate = function () {
        return new Date();
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], AttendanccePointsTotalsComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AttendanccePointsTotalsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AttendanccePointsTotalsComponent.prototype, "subscriptions", void 0);
    return AttendanccePointsTotalsComponent;
}());
export { AttendanccePointsTotalsComponent };
