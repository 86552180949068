import {  IColumnSettings } from '../../../core/models/index';

export class WcIncidentTrackingState {
  public columns: IColumnSettings[];
  public columnsMap: StringMap<IColumnSettings>;

  public createColumns(): void {
    this.columns = [
      {
        name: 'organization',
        description: 'Facility',
        displayed: true,
      },
      {
        name: 'position',
        description: 'Position',
        displayed: true,
      },
      {
        name: 'department',
        description: 'Department',
        displayed: true,
      },
      {
        name: 'dateHired',
        description: 'Hire Date',
        displayed: true,
      },
      {
        name: 'dateOfBirth',
        description: 'Date of Birth',
        displayed: true,
      },
      {
        name: 'empAge',
        description: 'Age',
        displayed: true,
      },
      {
        name: 'incidentDate',
        description: 'Date of Incident',
        displayed: true,
      },
      {
        name: 'incidentTime',
        description: 'Time of Incident',
        displayed: true,
      },
      {
        name: 'reportStatus',
        description: 'Report Status',
        displayed: true,
      },
      {
        name: 'injuryType',
        description: 'Injury Type',
        displayed: true,
      },
      {
        name: 'isLossConsciousness',
        description: 'Loss of Consciousness',
        displayed: true,
      },
      {
        name: 'carrierName',
        description: 'Carrier Name',
        displayed: true,
      },
      {
        name: 'injuryLocation',
        description: 'Location of Injury',
        displayed: true,
      },
      {
        name: 'whatDoingDuringInjury',
        description: 'What Was the Co-Worker Doing At the Time of Incident',
        displayed: true,
      },
      {
        name: 'isWorkersComp',
        description: 'Workers\' Comp',
        displayed: true,
      },
      {
        name: 'workersCompCase',
        description: 'Workers\' Comp Case #',
        displayed: true,
      },
      {
        name: 'isOshaRecordable',
        description: 'OSHA',
        displayed: true,
      },
      {
        name: 'oshaCaseNumber',
        description: 'OSHA Case #',
        displayed: true,
      },
      {
        name: 'isUsedProperSafety',
        description: 'Proper Safety Equipment',
        displayed: true,
      },
      {
        name: 'isPriorInjury',
        description: 'Prior Injury',
        displayed: true,
      },
      {
        name: 'isExposedToFluids',
        description: 'Exposed to Bodily Fluids',
        displayed: true,
      },
      {
        name: 'medicalTreatment',
        description: 'Medical Treatment Received',
        displayed: true,
      },
      {
        name: 'isPrivacyCaseInjury',
        description: 'Privacy Case',
        displayed: true,
      }
    ];

    this.mapColumns();
  }

  public mapColumns(): void {
    this.columnsMap = {};
    this.columns.forEach((col: IColumnSettings) => {
      this.columnsMap[col.name] = col;
    });
  }
}
