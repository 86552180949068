/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ideal-schedule-overview-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ideal-schedule-overview/ideal-schedule-overview.component.ngfactory";
import * as i3 from "../ideal-schedule-overview/ideal-schedule-overview.component";
import * as i4 from "../../../services/ideal-schedule/ideal-schedule-api.service";
import * as i5 from "./ideal-schedule-overview-dialog.component";
import * as i6 from "../../../../common/models/dialog-options";
import * as i7 from "../../../models/ideal-schedule/ideal-schedule-overview-request";
import * as i8 from "../../../../common/services/modal/modal.service";
var styles_IdealScheduleOverviewDialogComponent = [i0.styles];
var RenderType_IdealScheduleOverviewDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdealScheduleOverviewDialogComponent, data: {} });
export { RenderType_IdealScheduleOverviewDialogComponent as RenderType_IdealScheduleOverviewDialogComponent };
export function View_IdealScheduleOverviewDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "grid-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-ideal-schedule-overview", [], null, null, null, i2.View_IdealScheduleOverviewComponent_0, i2.RenderType_IdealScheduleOverviewComponent)), i1.ɵdid(2, 114688, null, 0, i3.IdealScheduleOverviewComponent, [i4.IdealScheduleApiService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_IdealScheduleOverviewDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-ideal-schedule-overview-dialog", [], null, null, null, View_IdealScheduleOverviewDialogComponent_0, RenderType_IdealScheduleOverviewDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.IdealScheduleOverviewDialogComponent, [i6.DialogOptions2, i7.IdealScheduleOverviewRequest, i8.ModalService], null, null)], null, null); }
var IdealScheduleOverviewDialogComponentNgFactory = i1.ɵccf("slx-ideal-schedule-overview-dialog", i5.IdealScheduleOverviewDialogComponent, View_IdealScheduleOverviewDialogComponent_Host_0, {}, {}, []);
export { IdealScheduleOverviewDialogComponentNgFactory as IdealScheduleOverviewDialogComponentNgFactory };
