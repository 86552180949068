<div class="details-header">
  <div>
    Supervisors
  </div>
  <div class="flex-horizontal">
    <button *ngIf="!disableOperations" type="button" (click)="onAddSupervisors()" class="theme-button-apply">Add Supervisor</button>
    <button *ngIf="!disableOperations" type="button" [popover]="acceptRemoveSupervisorsPopover" [disabled]="!selectedSupervisors||selectedSupervisors.length<1"
      class="theme-button-apply remove-button">Remove Supervisor</button>
    <popover-content #acceptRemoveSupervisorsPopover title="Do you wish to remove these {{selectedSupervisors?.length}} supervisors?"
      placement="auto bottom">
      <div style="width:110px">
        <button type="button" style="width:50px" (click)="acceptRemoveSupervisorsPopover.hide()" class="theme-button-cancel margin-r">No</button>
        <button type="button" style="width:50px" class="theme-button-apply" (click)="acceptRemoveSupervisorsPopover.hide();onRemoveSupervisors()">Yes</button>.
      </div>
    </popover-content>

  </div>
</div>
<div>
  <slx-supervisor-grid [supervisors]="group.supervisors" (selectionChanged)="supervisorsSelectionChanged($event)" (saveSuprvisor)="saveSuprvisor($event)"></slx-supervisor-grid>
</div>
<div class="details-header">
  <div>
    Employees
  </div>
  <div class="flez-horizontal">
    <button *ngIf="!disableOperations" type="button" (click)="onAddEmployees()" class="theme-button-apply">Add Employee</button>
    <button *ngIf="!disableOperations" type="button" [popover]="acceptRemoveEmployeesPopover" [disabled]="!selectedEmployees||selectedEmployees.length<1"
      class="theme-button-apply remove-button">Remove Employee</button>
    <popover-content #acceptRemoveEmployeesPopover title="Do you wish to remove these {{selectedEmployees?.length}} employees?"
      placement="auto bottom">
      <div style="width:110px">
        <button type="button" style="width:50px" (click)="acceptRemoveEmployeesPopover.hide()" class="theme-button-cancel margin-r">No</button>
        <button type="button" style="width:50px" class="theme-button-apply" (click)="acceptRemoveEmployeesPopover.hide();onRemoveEmployees()">Yes</button>.
      </div>
    </popover-content>

  </div>
</div>
<div>
  <slx-supervised-employee-grid [employees]="group.employees" (selectionChanged)="employeeSelectionChanged($event)"></slx-supervised-employee-grid>
</div>
