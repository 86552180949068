import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { ManagementBaseService } from '../../../core/services/index';
import { Observable } from 'rxjs/Observable';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { Department, Organization } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { EssTemplateApiService } from '../../../app-modules/ess-templates/services/index';
import { EssTemplateDefinition } from '../../../app-modules/ess-templates/models/index';
import { AccessManagementService } from '../accessManagement/access-management.service';

import { PositionsConfigurationApiService } from './positions-configuration-api.service';
import { IConfigutrationContainer } from '../../models/configiration-container.interface';
import { PositionsContainer, PositionModel, RemovePositionRequest, PositionsActions } from '../../models/index';
import { IConfigurationManagementService } from '../../utils/iconfiguration-management-service';

@Injectable()
export class PositionsManagementService extends ManagementBaseService<PositionsContainer, any> implements IConfigurationManagementService {

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    public removeItemsCmd$: ReplaySubject<any>;
    public addItemCmd$: ReplaySubject<any>;
    public editItemCmd$: ReplaySubject<any>;
    public viewRefresh$: Subject<boolean>;
    public dataChanged$: Subject<boolean>;
    public onItemSaved$: ReplaySubject<PositionModel>;

    public editingItem: any;
    public isEditingNewItem: boolean;
    public essTemplatesEnabled: boolean;
    public canManageGroups: boolean;

    public get container(): IConfigutrationContainer {
        return this.m_container;
    }

    private m_container: IConfigutrationContainer;

    private currentOrgLevel: OrgLevel;
    private essTemplates: EssTemplateDefinition[];

    @unsubscribeInService()
    private orgLevelSubscription: Subscription;

    constructor(public access: AccessManagementService,
        public changeService: ChangeManagementService,
        private api: PositionsConfigurationApiService,
        private lookup: LookupApiService,
        private essTemplateApiService: EssTemplateApiService,
        private appSettingsService: AppSettingsManageService) {
        super();
        this.removeItemsCmd$ = new ReplaySubject<any | RemovePositionRequest>();
        this.addItemCmd$ = new ReplaySubject<PositionModel>();
        this.editItemCmd$ = new ReplaySubject<PositionModel>();
        this.onItemSaved$ = new ReplaySubject<PositionModel>();
        this.dataChanged$ = new Subject<boolean>();
        this.viewRefresh$ = new Subject<boolean>();
    }

    public init(): void {

        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = false;
        this.access.allowDepartmentLevel = true;

        this.appSettingsService.getAppServerConfig()
            .then((settings: AppServerConfig) => {
                this.essTemplatesEnabled = settings.essTemplatesEnabled;
            });

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            this.essTemplates = undefined;
            if (_.isNumber(orgLevel.id)) {
                this.currentOrgLevel = orgLevel;
                this.access.orgLevelType = this.currentOrgLevel.type;
                this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
                this.fetchRecords();
            }
        });


    }

    public markAsDirty(): void {
        this.changeService.changeNotify();
    }

    public async getEssTemplateDefinitions(): Promise<EssTemplateDefinition[]> {
        if (this.essTemplates) {
            return Promise.resolve(this.essTemplates);
        }
        return this.essTemplateApiService.getTemplateDefinitions(this.currentOrgLevel.id)
            .then((templates: EssTemplateDefinition[]) => {
                this.essTemplates = templates;
                return this.essTemplates;
            });
    }

    public openCopyItems(): void {
        this.onStateChanged$.next({ configureMode: false, copyMode: true });
    }

    public closeCopyItems(): void {
        this.onStateChanged$.next({ configureMode: true, copyMode: false });
    }

    public setSelectedCount(count: number): void {
        this.access.selectedItemsCount = count;
    }

    public onAddItem(item: any): void {
        this.markAsDirty ();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    }

    public onEditItem(item: any): void {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    }

    public onCancelEditItem(): void {
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    }

    public onRemoveItem(itemToDelete: PositionModel): void {

        let request: RemovePositionRequest = new RemovePositionRequest();
        request.itemToDelete = itemToDelete;

        if (itemToDelete.employeesCount > 0) {
            request.requireReassign = true;
            request.orgLevelId = this.currentOrgLevel.id;
            request.itemIdToDelete = itemToDelete.id;
            request.ressignedPositionId = -1;
            request.effectiveDate = undefined;
            this.removeItemsCmd$.next(request);
        } else {
            request.requireReassign = false;
            this.removeItemsCmd$.next(request);
        }
    }

    public doRemoveItem(item: PositionModel, reassignToPosition?: number, date?: Date): void {
        this.api.deletePosition(this.currentOrgLevel.id, item, reassignToPosition, date)
            .then((items: PositionModel[]) => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    public onSaveItem(info: { dataItem: PositionModel, isNew: boolean }): void {
        if (info.isNew) {
            this.addItem(info.dataItem);
        } else {
            this.updateItem(info.dataItem);
        }
    }

    public loadPositions(): void {
      this.fetchRecords();
    }

    protected addItem(item: PositionModel): void {
        _.each(this.m_container.records, (p: PositionModel) => {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.addPosition(this.currentOrgLevel.id, item)
            .then((items: PositionModel[]) => {
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    protected updateItem(item: PositionModel): void {
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.savePosition(this.currentOrgLevel.id, item)
            .then((savedItem: PositionModel) => {
                this.changeService.clearChanges();
                this.onItemSaved$.next(this.editingItem);
                this.updatePositionModel(this.editingItem, savedItem);
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
                this.fetchRecords();
            }).catch(() => {
                this.access.lockActions = false;
                this.viewRefresh$.next(false);
                this.onStateChanged$.next({ isLoading: false });
            });
    }

    protected updatePositionModel(modelToUpdate: PositionModel, position: PositionModel): void {
        modelToUpdate.accrualPolicy = position.accrualPolicy;
        modelToUpdate.additionalInfo = position.additionalInfo;
        modelToUpdate.budgetedPosition = position.budgetedPosition;
        modelToUpdate.businessUnit = position.businessUnit;
        modelToUpdate.clientDepartment = position.clientDepartment;
        modelToUpdate.defaultShiftHours = position.defaultShiftHours;
        modelToUpdate.department = position.department;
        modelToUpdate.employeesCount = position.employeesCount;
        modelToUpdate.groupedWith = position.groupedWith;
        modelToUpdate.hourlyRate = position.hourlyRate;
        modelToUpdate.isDirectCare = position.isDirectCare;
        modelToUpdate.isPrimary = position.isPrimary;
        modelToUpdate.jobClass = position.jobClass;
        modelToUpdate.jobListOrder = position.jobListOrder;
        modelToUpdate.lastUpdateDate = position.lastUpdateDate;
        modelToUpdate.lastUpdateUsername = position.lastUpdateUsername;
        modelToUpdate.legalId = position.legalId;
        modelToUpdate.name = position.name;
        modelToUpdate.objectAccount = position.objectAccount;
        modelToUpdate.organization = position.organization;
        modelToUpdate.payPolicy = position.payPolicy;
        modelToUpdate.pbjCmsPosition = position.pbjCmsPosition;
        modelToUpdate.selectable = position.selectable;
        modelToUpdate.shiftDifferentialPolicy = position.shiftDifferentialPolicy;
        modelToUpdate.positionGroup = position.positionGroup;
        modelToUpdate.positionGroupId = position.positionGroupId;
        modelToUpdate.replacesPositionsIds = position.replacesPositionsIds;
        modelToUpdate.supervisorOrganization = position.supervisorOrganization;
        modelToUpdate.supervisorDepartment = position.supervisorDepartment;
        modelToUpdate.supervisorJobCode = position.supervisorJobCode;

    }

    protected fetchRecords(): void {
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getPositions(this.currentOrgLevel.id).
            then((result: { actions: PositionsActions, positions: PositionModel[] }) => {
                this.changeService.clearChanges();
                this.m_container = new PositionsContainer();
                this.m_container.records = result.positions;
                this.access.actions = result.actions;
                this.access.lockActions = false;
                this.editingItem = null;
                this.isEditingNewItem = false;
                this.canManageGroups = result.actions.canManageGroups;
                this.onLoaded$.next(this.m_container);
                this.onStateChanged$.next({ isLoading: false });
            }).catch(() => {
                this.access.lockActions = false;
                this.onStateChanged$.next({ isLoading: false });
            });

    }
}
