import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ManagementBaseService } from '../../../../core/services/index';
import { Organization, UserAction } from '../../../../organization/models/index';
import { PbjManualEntryApiService } from './pbj-manual-entry-api.service';
import { PBJManualTimeEntry } from '../../models/index';
import { RangeDates } from '../../../../common/models/range-dates';
import { AccessibleApiService } from '../../../../organization/services/index';
import { Assert } from '../../../../framework/index';
var PbjManualEntryManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PbjManualEntryManagementService, _super);
    function PbjManualEntryManagementService(apiService, accessibleApiService) {
        var _this = _super.call(this) || this;
        _this.accessibleApiService = accessibleApiService;
        _this.exportToPdf$ = new Subject();
        _this.exportToExcel$ = new Subject();
        _this.userActonsChanged$ = new ReplaySubject(1);
        _this.apiService = apiService;
        _this.onSavedEntry$ = new ReplaySubject(1);
        _this.onAddedEntry$ = new ReplaySubject(1);
        _this.onRemovedEntries$ = new ReplaySubject(1);
        _this.onEntriesSelected$ = new ReplaySubject(1);
        _this.onEditState$ = new ReplaySubject(1);
        _this.onAddEntry$ = new ReplaySubject(1);
        _this.onOrgLevel$ = new ReplaySubject(1);
        _this.onFilterDates$ = new ReplaySubject(1);
        return _this;
    }
    PbjManualEntryManagementService.prototype.addEntry = function (entry) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.addPBJManualEntry(this.orgLevel.id, entry)
            .then(function (entry) {
            _this.onLoadStatusChanged(false);
            _this.onAddedEntry$.next(entry);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjManualEntryManagementService.prototype.saveEntry = function (entry) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.savePBJManualEntry(this.orgLevel.id, entry)
            .then(function (entry) {
            _this.onLoadStatusChanged(false);
            _this.onSavedEntry$.next(entry);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjManualEntryManagementService.prototype.removeEntries = function (entriesIds) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.removePBJManualEntries(entriesIds, this.orgLevel.id)
            .then(function () {
            _this.onLoadStatusChanged(false);
            _this.onRemovedEntries$.next(entriesIds);
        })
            .catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjManualEntryManagementService.prototype.addEntryToRow = function (entry) {
        this.onAddEntry$.next(entry);
    };
    PbjManualEntryManagementService.prototype.editStateChanged = function (isCanEdit) {
        this.onEditState$.next(isCanEdit);
    };
    PbjManualEntryManagementService.prototype.orgLevelChanged = function (orgLevel) {
        this.orgLevel = orgLevel;
        this.onOrgLevel$.next(orgLevel);
        this.checkRequiredData();
        this.loadUserActions(orgLevel.id);
    };
    PbjManualEntryManagementService.prototype.subscribeToUserActionsChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.userActonsChanged$.subscribe(callback);
    };
    PbjManualEntryManagementService.prototype.loadUserActions = function (orgLevelId) {
        var _this = this;
        this.accessibleApiService.getUserActions(orgLevelId, ['Export to Excel'])
            .then(function (data) {
            _this.userActonsChanged$.next(data);
        });
    };
    PbjManualEntryManagementService.prototype.dateFilterChanged = function (startDate, endDate) {
        if (_.isDate(startDate) && _.isDate(endDate)) {
            var rangeDates = new RangeDates();
            rangeDates.startDate = this.startDate = startDate;
            rangeDates.endDate = this.endDate = endDate;
            this.onFilterDates$.next(rangeDates);
            this.checkRequiredData();
        }
    };
    PbjManualEntryManagementService.prototype.loadEntries = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.apiService.getPBJManualEntries(this.orgLevel.id, this.startDate, this.endDate)
            .then(function (val) {
            _this.onLoadStatusChanged(false);
            _this.onLoaded(val);
        }).catch(function (reason) {
            _this.onError(reason);
        });
    };
    PbjManualEntryManagementService.prototype.cloneEntry = function (entry) {
        var newEntry = this.createPBJManualEntry();
        newEntry.employee = entry.employee;
        newEntry.center = entry.center;
        newEntry.department = entry.department;
        newEntry.position = entry.position;
        newEntry.hours = entry.hours;
        this.addEntryToRow(newEntry);
    };
    PbjManualEntryManagementService.prototype.createPBJManualEntry = function () {
        var entry = new PBJManualTimeEntry();
        entry.addedByUsername = '';
        entry.addedDate = null;
        entry.center = null;
        entry.date = null;
        entry.department = null;
        entry.employee = null;
        entry.entryType = '';
        entry.hours = null;
        entry.position = null;
        return entry;
    };
    PbjManualEntryManagementService.prototype.checkRequiredData = function () {
        if (this.orgLevel && _.isDate(this.startDate) && _.isDate(this.endDate)) {
            this.loadEntries();
        }
    };
    PbjManualEntryManagementService.prototype.exportToPdf = function () {
        this.exportToPdf$.next();
    };
    PbjManualEntryManagementService.prototype.exportToExcel = function () {
        this.exportToExcel$.next();
    };
    return PbjManualEntryManagementService;
}(ManagementBaseService));
export { PbjManualEntryManagementService };
