<section class="review">
  <header class="review__header">
    <p class="review__emp flex align-items__center">
      <img class="review__emp-icon" [employeeThumbinalSrc]="empId" alt="Employee Photo" />
      <span class="review__emp-info flex__grow">
        <strong class="review__emp-name">{{ empName }}</strong>
        <strong class="review__emp-field">Position:&nbsp;</strong>
        <span class="review__emp-value">{{ posName }}</span>
        <strong class="review__emp-field">Department:&nbsp;</strong>
        <span class="review__emp-value">{{ depName }}</span>
        <strong class="review__emp-field">Facility:&nbsp;</strong>
        <span class="review__emp-value">{{ orgName }}</span>
      </span>
    </p>
    <div class="flex justify-content__space-between review__buttons">
      <div class="flex__grow">
        <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onClickEmployee()">
          <i aria-hidden="true" class="fas fa-user slx-button__icon"></i>
          <span class="slx-button__text">View Profile</span>
        </button>
      </div>
      <span>
        <button
          *ngIf="!((!isEditMode || isCreatingNew) || !canDelete)"
          [popover]="confirmDeleting"
          class="slx-button slx-red slx-with-icon slx-margin-r"
          type="button"
        >
          <i aria-hidden="true" class="fas fa-trash-alt slx-button__icon"></i>
          <span class="slx-button__text">Delete Review</span>
        </button>
        <popover-content #confirmDeleting title="Are you sure you want to delete this review?" placement="auto bottom">
          <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r" (click)="onClickDelete(true, confirmDeleting)">OK</button>
          <button type="button" class="slx-button slx-narrow" (click)="onClickDelete(false, confirmDeleting)">Cancel</button>
        </popover-content>
      </span>
      <span>
        <button *ngIf="!reviewType||!rating" [disabled]="isCreatingNew || !isEditMode || isComplete || !canEdit"
        [popover]="completeReviewMessageBox" mode="hover" class="slx-button slx-blue slx-with-icon slx-margin-r" type="button">
        <i aria-hidden="true" class="fas fa-upload slx-button__icon"></i>
        <span class="slx-button__text">Complete Review</span>
        <i aria-hidden="true" class="fas fa-exclamation-triangle slx-button__icon yellow-icon"></i>
      </button>
      <popover-content #completeReviewMessageBox title="Review cannot be completed until the following fields have been entered:"
        placement="auto bottom" disableClickOutside="true" class="reviewPopover">
        <p class="flex align-items__center review__ctrl">
          <label class="review__label">Review type</label>
          <span class="flex align-items__center flex__grow">
            <slx-dropdown-list class="review_width" [lookup]="typesLookup" [(ngModel)]="reviewTypeInPopover"
              [readonly]="!isEditMode" name="reviewType"></slx-dropdown-list>
          </span>
        </p>
        <p class="flex align-items__center review__ctrl">
          <label class="review__label">Rating</label>
          <span class="flex align-items__center flex__grow">
            <slx-dropdown-list class="review_width" [lookup]="ratingLookup" [(ngModel)]="ratingInPopover"
              [readonly]="!isEditMode" name="rating"></slx-dropdown-list>
          </span>
        </p>
        <div class="btn-container">
          <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r"
            (click)="onClickOk(true, completeReviewMessageBox)">OK</button>
          <button type="button" class="slx-button slx-narrow"
            (click)="onClickOk(false, completeReviewMessageBox)">Cancel</button>
        </div>
      </popover-content>
      <button *ngIf="reviewType && rating" [disabled]="isCreatingNew || !isEditMode || isComplete"
        [popover]="confirmCompleting" class="slx-button slx-blue slx-with-icon slx-margin-r" type="button">
        <i aria-hidden="true" class="fas fa-upload slx-button__icon"></i>
        <span class="slx-button__text">Complete Review</span>
      </button>
      <popover-content #confirmCompleting
        title="Are you sure you want to complete the review? You will be unable to make any changes to the review once it is completed"
        placement="auto bottom">
        <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r"
          (click)="onClickComplete(true, confirmCompleting)">Complete</button>
        <button type="button" class="slx-button slx-narrow"
          (click)="onClickComplete(false, confirmCompleting)">Cancel</button>
      </popover-content>
      </span>
    </div>
  </header>

  <div class="review__form">
    <p class="flex align-items__center review__ctrl">
      <label class="review__label">Review Date</label>
      <span class="flex align-items__center flex__grow review-date-field">
        <slx-datepicker class="slx-wide"
          #reviewDatePicker="ngModel"
          [(ngModel)]="review.reviewDate"
          (ngModelChange)="onChangeReviewDate($event)"
          (valueChanged)="onChangeReviewDate($event)"
          [readonly]="!isEditMode"
          name="reviewDatePicker"
          [slxMinDate]="minDate"
          [minDate]="minDate"
          [required]="true"
          [acceptNullDate]="false"
        ></slx-datepicker>
        <span *ngIf="reviewDatePicker.errors && !loadingReviewData" class="slx-error-block">
          Incorrect Date
        </span>
      </span>
    </p>
    <p class="flex align-items__center review__ctrl">
      <label class="review__label">Review type</label>
      <span class="flex align-items__center flex__grow">
        <slx-dropdown-list class="slx-wide"
          [lookup]="typesLookup"
          [(ngModel)]="reviewType"
          (ngModelChange)="onChangeReviewTypes()"
          [readonly]="!isEditMode"
          name="reviewType"
        ></slx-dropdown-list>
      </span>
    </p>
    <p class="flex align-items__center review__ctrl">
      <label class="review__label">Last Review Date</label>
      <span class="flex align-items__center flex__grow">
        <input type="text"
          class="review__input"
          [ngModel]="lastReviewDate | amDateFormat: appConfig.dateFormat"
          [readonly]="true"
          name="positionName"
          autocomplete="off"
        />
      </span>
    </p>
    <p class="flex align-items__center review__ctrl">
      <label class="review__label">Rating</label>
      <span class="flex align-items__center flex__grow">
        <slx-dropdown-list class="slx-wide"
          [lookup]="ratingLookup"
          [(ngModel)]="rating"
          (ngModelChange)="onChangeRating()"
          [readonly]="!isEditMode"
          name="rating"
        ></slx-dropdown-list>
      </span>
    </p>
  </div>

  <div class="review__body">
    <slx-collapsible-section title="Comments" mode="blue" customIcon="fas fa-comment" counter="3" [expanded]="true">
      <ng-container body>
        <p class="flex flex-direction__column review__ctrl">
          <label class="review__label">Add comments</label>
          <span class="flex align-items__center flex__grow">
            <textarea class="review__input review__textarea"
              [(ngModel)]="comment"
              (ngModelChange)="onChangeComment()"
              [readonly]="!isEditMode"
              maxlength="5000"
              name="employeeComment"
            ></textarea>
          </span>
      </p>
      </ng-container>
    </slx-collapsible-section>
    <slx-collapsible-section title="Attachments" mode="blue" customIcon="fas fa-paperclip" [counter]="attachmentsCount" [expanded]="true">
      <ng-container body>
        <slx-pm-review-attachment
          [disabled]="!isEditMode"
          [attachments]="attachments"
          (addedFiles)="onAddedFiles($event)"
          (deletedFile)="onDeletedFile($event)"
          (deletedAttachment)="onDeletedAttachment($event)"
          (downloadAttachment)="onDownloadAttachment($event)"
        ></slx-pm-review-attachment>
      </ng-container>
    </slx-collapsible-section>
    <slx-collapsible-section title="Templates" mode="blue" customIcon="fal fa-file-upload" [counter]="templateAttachmentsCount" [expanded]="true">
      <ng-container body>
        <slx-pm-review-attachment
          [allowUpload] = "false"
          [disabled]="!isEditMode"
          [canDelete]="false"
          [attachments]="templateAttachments"
          (addedFiles)="onAddedFiles($event)"
          (deletedFile)="onDeletedFile($event)"
          (deletedAttachment)="onDeletedAttachment($event)"
          (downloadAttachment)="onDownloadAttachment($event)"
        ></slx-pm-review-attachment>
      </ng-container>
    </slx-collapsible-section>
    <p class="flex align-items__center review__ctrl">
      <label class="review__label">Reviewed By</label>
      <span class="flex align-items__center flex__grow">
        <input type="text"
          class="review__input"
          [ngModel]="reviewedBy"
          [readonly]="true"
          name="positionName"
        />
      </span>
    </p>
  </div>
</section>
