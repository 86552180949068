import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmOptions, ConfirmDialogComponent } from '../../../common/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { appMessages } from '../../../app.messages';
import { AccessManagementService, ConstraintsConfigurationManagementService } from '../../services/index';
import { ConstraintModel, EditableListActionKind } from '../../models/index';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { NgForm } from '@angular/forms';
var ConfigureConstraintsComponent = /** @class */ (function () {
    function ConfigureConstraintsComponent(management, access, modalService) {
        var _this = this;
        this.access = access;
        this.modalService = modalService;
        this.prohibitedNameValues = [];
        this.prohibitedCodeValues = [];
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            var item = new ConstraintModel();
            _this.crudHelper.addItem(item);
            _this.updateProhibitedNameValues(item);
            _this.updateProhibitedCodeValues(item);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(ConfigureConstraintsComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    ConfigureConstraintsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appMessages = appMessages;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            configureMode: true,
            copyMode: false
        };
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'configureMode'))
                _this.state.configureMode = state.configureMode;
            if (_.has(state, 'copyMode'))
                _this.state.copyMode = state.copyMode;
        });
        this.editSubscription = this.management.editItemCmd$.subscribe(function (item) {
            if (item) {
                _this.updateProhibitedNameValues(item);
                _this.updateProhibitedCodeValues(item);
                _this.mainFormSubscription = _this.mainForm.statusChanges.subscribe(function () {
                    if (_this.mainForm.dirty) {
                        _this.management.markAsDirty();
                    }
                });
            }
            else {
                if (_this.mainFormSubscription) {
                    _this.mainFormSubscription.unsubscribe();
                }
            }
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Confirmation', 'Do you want to delete the constraint?', _this.modalService, function (result) {
                if (result) {
                    _this.management.doRemoveItem(item);
                }
            }, options);
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
    };
    // Must be, see #issueWithAOTCompiler
    ConfigureConstraintsComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    ConfigureConstraintsComponent.prototype.updateProhibitedNameValues = function (item) {
        if (item) {
            var values_1 = [];
            _.each(this.management.container.records, function (s) {
                if (item.name !== s.name) {
                    values_1.push(s.name);
                }
            });
            this.prohibitedNameValues = values_1;
        }
    };
    ConfigureConstraintsComponent.prototype.updateProhibitedCodeValues = function (item) {
        if (item) {
            var values_2 = [];
            _.each(this.management.container.records, function (s) {
                if (item.code !== s.code) {
                    values_2.push(s.code);
                }
            });
            this.prohibitedCodeValues = values_2;
        }
    };
    ConfigureConstraintsComponent.prototype.onMobileRendererEvent = function (action, item, index) {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
        else if (action === EditableListActionKind.START_EDIT) {
            this.crudHelper.startEdit(item, index);
        }
    };
    ConfigureConstraintsComponent.prototype.onMobileEditorEvent = function (action, item, index) {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.crudHelper.completeEdit(item, index);
        }
        else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.crudHelper.cancelEdit(index);
        }
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", ConstraintsConfigurationManagementService)
    ], ConfigureConstraintsComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureConstraintsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureConstraintsComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureConstraintsComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureConstraintsComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureConstraintsComponent.prototype, "mainFormSubscription", void 0);
    return ConfigureConstraintsComponent;
}());
export { ConfigureConstraintsComponent };
