import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ApiUtilService, CacheUtilService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ResponseBody } from '../../../core/models/api/response-body';
import { Organization } from '../../../organization/models/lookup/index';
import { AgenciesMapService } from './agencies-map.service';

import { configurationConfig } from '../../configuration.config';

import { IAgencyMappingDTO, AgencyModel, AgencyMapping } from '../../models/index';

@Injectable()
export class AgenciesApiService {
  constructor(private apiUtilService: ApiUtilService,
              private mapService: AgenciesMapService,
              private cacheUtilService: CacheUtilService) {
  }

  public getAgencies(orgLevelId: number): Promise<AgencyMapping[]> {
    const url: string = this.getOrgLevelApiRoot(orgLevelId);

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IAgencyMappingDTO[], Meta>) => {
      //let actions: Actions = this.metaMapService.mapActions(response.meta as FieldsMeta);
      //this.stateManagement.actions = actions;
      return this.mapService.mapAgenciesMappings(response.data);
    });
  }

  public addAgency(orgLevelId: number, agencyMapping: AgencyMapping): Promise<AgencyMapping> {
    const url: string = this.getOrgLevelApiRoot(orgLevelId);

    let request: HttpRequest<any> = new HttpRequest('POST', url, this.mapService.mapAddRequest(agencyMapping));

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<IAgencyMappingDTO, Meta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapAgencyMapping(response.data);
      });
  }

  public updateAgency(orgLevelId: number, agencyModel: AgencyModel): Promise<AgencyMapping> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${agencyModel.id}`;

    let request: HttpRequest<any> = new HttpRequest('PUT', url, this.mapService.mapAgencyDTO(agencyModel));

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<IAgencyMappingDTO, Meta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapAgencyMapping(response.data);
      });
  }

  public removeAgency(agency: AgencyModel): Promise<any> {
    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.agencies.root}/${agency.id}`;
    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  public unassignOrganizations(agency: AgencyModel, organizations: Organization[]): Promise<any> {
    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.agencies.root}/${agency.id}/${configurationConfig.api.configuration.agencies.unassign}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, this.mapService.mapOrganizationIds(organizations));

    return this.apiUtilService.request<any, Meta>(request);
  }

  public assignOrganizations(agency: AgencyModel, organizations: Organization[]): Promise<any> {
    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.agencies.root}/${agency.id}/${configurationConfig.api.configuration.agencies.assign}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, this.mapService.mapOrganizationIds(organizations));

    return this.apiUtilService.request<any, Meta>(request);
  }

  public getAgency(agencyId: number): Promise<AgencyMapping> {
    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.agencies.root}/${agencyId}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IAgencyMappingDTO, Meta>) => {
      return this.mapService.mapAgencyMapping(response.data);
    });
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.agencies.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
