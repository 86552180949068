import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { EmployersConfigurationApiService, RolesApiService } from '../../services';
import { ModalService, KendoGridStateHelper, PbjNavigationService } from '../../../common/index';
import { Employer, AleGroup, EmployerDialogOptions } from '../../models/employers/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { EmployersManagementService } from '../../services/employers/employers-configuration-management.service';
import { ManageAleGroupsDialogComponent } from './manage-groups/manage-ale-groups-dialog/manage-ale-groups-dialog.component';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { Observable, Subscription } from 'rxjs';
import { process } from '@progress/kendo-data-query';
import { ManageEmployerDialogComponent } from '../../components/employers/manage-employer-dialog/manage-employer-dialog.component';
import { EmployerOrganizationDialogComponent } from '../../components/employers/employer-organization-dialog/employer-organization-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
var ConfigureEmployersComponent = /** @class */ (function () {
    function ConfigureEmployersComponent(management, modalService, api, route, router, rolesApiService) {
        this.modalService = modalService;
        this.api = api;
        this.route = route;
        this.router = router;
        this.rolesApiService = rolesApiService;
        this.isSystemUser = false;
        this.isAccessEmployers = false;
        this.groups = [{ field: 'aleGroupName' }];
        this.tooltip = '';
        this.allFeins = [];
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.sort = [
            { field: 'legalName', dir: 'asc' }
        ];
    }
    ConfigureEmployersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = false;
        this.user$.subscribe(function (user) {
            _this.isSystemUser = user ? user.username === 'system' : false;
        });
        this.onloadedSubscription = this.management
            .subscribeToOnLoaded(function (state) {
            _this.gridState.state.group = _this.groups;
            _this.canEdit = state.canEditEmployersConfig;
            _this.canExport = state.canExportToExcel;
            _this.aleGroupList = state.aleGroupList;
            _this.m_container = state;
            if (!_this.canEdit) {
                _this.tooltip = 'You do not have the right to edit this information';
            }
            _this.refreshGrid();
        });
        var roles, userData, loginRole;
        userData = JSON.parse(localStorage.getItem("slx-worklinx-v6-app"));
        loginRole = userData.session.user.roles[0];
        this.rolesApiService.getRoleAccessTable().then(function (res) {
            roles = res.roles;
            roles.forEach(function (ele) {
                if (loginRole === ele.name) {
                    if (ele.menuModules) {
                        ele.menuModules.forEach(function (element) {
                            if (element.name == 'Configuration') {
                                element.menus.forEach(function (menu) {
                                    if (menu.name == 'General') {
                                        menu.subMenus.forEach(function (subMenu) {
                                            if (subMenu.name == 'Organizations') {
                                                if (subMenu.isEnabled) {
                                                    _this.isAccessEmployers = true;
                                                }
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                }
            });
            // }	
        });
        this.stateSubscription = this.management
            .subscribeToOnStateChanged(function (state) {
            _this.isLoading = state.isLoading;
        });
        this.refreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.management.init();
    };
    ConfigureEmployersComponent.prototype.getGridData = function () {
        var _this = this;
        return function () {
            return {
                data: _.cloneDeep(_this.gridState.view.data),
                group: _this.gridState.state.group
            };
        };
    };
    ConfigureEmployersComponent.prototype.filterChange = function (filter) {
        filter.filters.forEach(function (ele) {
            ele.filters.forEach(function (ele) {
                if (ele.field == "feinWithoutDash") {
                    ele["value"] = ele.value.indexOf('-') > -1 ? ele.value.replace('-', '') : ele.value;
                }
            });
        });
        this.gridState.state.filter = filter;
        this.refreshGrid();
    };
    ConfigureEmployersComponent.prototype.refreshGrid = function () {
        if (!this.m_container) {
            this.gridState.view = null;
            return;
        }
        if (this.m_container.records) {
            this.allFeins = this.m_container.records ? this.m_container.records.map(function (e) { return e.federalEIN.replace('-', ''); }) : [];
        }
        this.gridState.view = process(this.m_container.records, this.gridState.state);
    };
    ConfigureEmployersComponent.prototype.onAddNew = function () {
        var employerDialogOptions = new EmployerDialogOptions();
        employerDialogOptions.employer = new Employer();
        employerDialogOptions.employer.companyId = 0;
        employerDialogOptions.employer.orgLevelId = this.management.orgLevelId;
        employerDialogOptions.employer.aleGroupModel = new AleGroup();
        employerDialogOptions.employer.aleGroupModel.groupId = 0;
        employerDialogOptions.employer.aleGroupModel.groupName = 'Ale Group 1';
        employerDialogOptions.aleGroupList = this.aleGroupList;
        employerDialogOptions.allFeins = this.allFeins;
        this.openPopupDialog(employerDialogOptions);
    };
    ConfigureEmployersComponent.prototype.onEditItems = function (employer) {
        var employerDialogOptions = new EmployerDialogOptions();
        employerDialogOptions.employer = new Employer();
        employer.orgLevelId = this.management.orgLevelId;
        employerDialogOptions.employer = employer;
        employerDialogOptions.aleGroupList = this.aleGroupList;
        employerDialogOptions.allFeins = this.allFeins;
        this.openPopupDialog(employerDialogOptions);
    };
    ConfigureEmployersComponent.prototype.onClickDelete = function (isDelete, acceptPopover, employer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                acceptPopover.hide();
                if (isDelete) {
                    this.onDelete(employer);
                }
                return [2 /*return*/];
            });
        });
    };
    ConfigureEmployersComponent.prototype.onDelete = function (employer) {
        var _this = this;
        this.isLoading = true;
        this.api.getEmployersOrganization(employer.companyId).
            then(function (orgDialogOptions) {
            _this.isLoading = false;
            if (orgDialogOptions && orgDialogOptions.organization.length > 0) {
                EmployerOrganizationDialogComponent.openDialog(orgDialogOptions, _this.modalService);
            }
            else {
                _this.onDeleteEmployer(employer);
            }
        }).catch(function () {
            _this.isLoading = false;
        });
    };
    ConfigureEmployersComponent.prototype.onDeleteEmployer = function (employer) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        employer.deleted = true;
                        employer.orgLevelId = this.management.orgLevelId;
                        return [4 /*yield*/, this.management.onSaveEmployer(employer)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ConfigureEmployersComponent.prototype.openPopupDialog = function (employerDialogOptions) {
        var _this = this;
        ManageEmployerDialogComponent.openDialog(employerDialogOptions, this.modalService, function (hasChanges) {
            if (hasChanges) {
                _this.management.loadEmployers();
            }
        });
    };
    ConfigureEmployersComponent.prototype.onExportToExcel = function () {
        this.grid.saveAsExcel();
    };
    ConfigureEmployersComponent.prototype.onExportToPdf = function () {
        this.grid.saveAsPDF();
    };
    ConfigureEmployersComponent.prototype.navigateToPBJOrg = function () {
        var navigation = new PbjNavigationService(this.router, this.route);
        navigation.NavigateToPbjOrganizationsConfiguration();
    };
    ConfigureEmployersComponent.prototype.checkPbjAccess = function () {
    };
    ConfigureEmployersComponent.prototype.onManageGroups = function () {
        var _this = this;
        ManageAleGroupsDialogComponent.openDialog(this.modalService, function (hasChanges) {
            if (hasChanges) {
                _this.management.loadEmployers();
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], ConfigureEmployersComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureEmployersComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureEmployersComponent.prototype, "onloadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureEmployersComponent.prototype, "refreshSubscription", void 0);
    return ConfigureEmployersComponent;
}());
export { ConfigureEmployersComponent };
