import { GroupDescriptor } from '@progress/kendo-data-query';

export interface IGenericGridExcelExportConfig {
  fileName: string;
  allPages: boolean;
}
export const genericGridExcelExportConfig: IGenericGridExcelExportConfig = {
  fileName: 'Data.xlsx',
  allPages: true,
};

export interface IGenericGridPdfExportConfig {
  fileName: string;
  allPages: boolean;
  top: string;
  left: string;
  right: string;
  bottom: string;
  paperSize: string;
  landscape: boolean;
  scale: number;
  autoscale: boolean;
  repeatHeaders: boolean;
}

export const genericGridPdfExportConfig: IGenericGridPdfExportConfig = {
  fileName: 'Data.pdf',
  allPages: true,
  top: '1cm',
  left: '1cm',
  right: '1cm',
  bottom: '1cm',
  paperSize: 'A4',
  landscape: true,
  autoscale: false,
  scale: 0.6,
  repeatHeaders: true
};

export interface IGenericGridConfig {
  excelExport: IGenericGridExcelExportConfig;
  pdfExport: IGenericGridPdfExportConfig;
  groupFieldCustomValidation: (groupField: GroupDescriptor) => boolean;
}
export const genericGridConfig: IGenericGridConfig = {
  excelExport: genericGridExcelExportConfig,
  pdfExport: genericGridPdfExportConfig,
  groupFieldCustomValidation: null
};

