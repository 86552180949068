import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { IdealScheduleStateService, IdealScheduleToolbarService } from '../../services/index';
import { StateManagementService, ComponentStateStorageService } from '../../../common/index';
import { StateResetTypes } from '../../../core/models/index';
import { IdealScheduleConfigCensusDefinition } from '../../models/index';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as XLSX from 'xlsx';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
var IdealScheduleComponent = /** @class */ (function () {
    function IdealScheduleComponent(idealScheduleToolbarService, stateService, stateManagement, storageService, router) {
        var _this = this;
        this.idealScheduleToolbarService = idealScheduleToolbarService;
        this.stateService = stateService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.router = router;
        this.IdealScheduleConfigCensusDefinition = IdealScheduleConfigCensusDefinition;
        this.m_hideUnusedControlId = 'HideUnused';
        this.m_hideUnusedControlResetType = StateResetTypes.None;
        this.scheduleDetails = [];
        this.isDisabled = false;
        this.router.events.pipe(filter(function (e) { return e instanceof NavigationEnd; })).subscribe({
            next: function (e) {
                if (e.url.includes('configure_ideal_schedule')) {
                    _this.stateService.discardChanges();
                }
            },
        });
    }
    IdealScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('IdealScheduleComponent', false);
        this.stateManagement.onInit$.subscribe(function () {
            _this.restoreState();
            _this.stateService.subscribe();
        });
        this.stateService.onFiltersChanged$.subscribe(function () {
            _this.saveState();
        });
    };
    IdealScheduleComponent.prototype.ngOnDestroy = function () {
        this.stateService.unsubscribe();
    };
    Object.defineProperty(IdealScheduleComponent.prototype, "ranges", {
        get: function () {
            if (this.stateService && this.stateService.selectedPosition) {
                return this.stateService.selectedPosition.ranges;
            }
            return [];
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleComponent.prototype.restoreState = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_hideUnusedControlId);
        if (state) {
            this.stateService.hideUnused = state.value;
        }
    };
    IdealScheduleComponent.prototype.saveState = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_hideUnusedControlId, {
            value: this.stateService.hideUnused
        }, this.m_hideUnusedControlResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_hideUnusedControlId);
    };
    IdealScheduleComponent.prototype.captureIdealScheduleScreen = function () {
        var _this = this;
        var data = document.getElementById('contentToConvert');
        html2canvas(data).then(function (canvas) {
            // Few necessary setting options
            var imgWidth = 210;
            var pageHeight = 295;
            var imgHeight = (canvas.height * imgWidth) / canvas.width;
            var heightLeft = imgHeight;
            var contentDataURL = canvas.toDataURL('image/png');
            var pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
            var position = 10;
            // let _selectedDateVal = document.getElementById('_selectedDateVal').innerText;
            var current = new Date();
            var cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            var cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            _this.dateTime = cDate + ' ' + cTime;
            pdf.setFontSize(10);
            // pdf.text(dateTime, 7, 7);
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            pdf.save('Ideal_Schedule' + _this.dateTime + '.pdf'); // Generated PDF
        });
    };
    IdealScheduleComponent.prototype.exportToExcel = function () {
        var _this = this;
        var wb = XLSX.utils.book_new();
        var range = this.stateService.selectedPosition.ranges[0];
        var current = new Date();
        var cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        var cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
        this.dateTime = cDate + ' ' + cTime;
        if (range.parLevels != null || range != undefined) {
            var allKeys = Object.keys(range.parLevels);
            var allValues = Object.values(range.parLevels);
            this.scheduleDetails = [];
            for (var r = 0; r < allKeys.length; r++) {
                this.scheduleDetails.push(allValues);
            }
            var idealSchedule = this.scheduleDetails[0];
            if (idealSchedule.length > 0 && idealSchedule != undefined) {
                this.days = idealSchedule.filter(function (x) { return x.uniqueId.includes('Day'); });
                this.evening = idealSchedule.filter(function (x) { return x.uniqueId.includes('Evening'); });
                this.night = idealSchedule.filter(function (x) { return x.uniqueId.includes('Night'); });
                console.log('scheduleDetails', this.scheduleDetails, this.days, this.evening, this.night);
            }
            this.rowsData = [];
            if (idealSchedule.length > 0) {
                // const loadValue= settingTotalHours(this.days);
                this.rowsData.push({ "": "Day" });
                if (this.days.length > 0 && this.days.length != null && this.days.length != undefined) {
                    this.days.map(function (x) {
                        _this.rowsData.push({
                            "Shift": x.shift.name + '  ' + x.shift.duration,
                            "Unit": x.unit != null ? x.unit.name : 0,
                            "Sunday": x.days[1].counter + '  ' + x.days[1].hours + 'Hours',
                            "Monday": x.days[2].counter + '  ' + x.days[2].hours + 'Hours',
                            "Tuesday": x.days[3].counter + '  ' + x.days[3].hours + 'Hours',
                            "Wednesday": x.days[4].counter + '  ' + x.days[4].hours + 'Hours',
                            "Thursday": x.days[5].counter + '  ' + x.days[5].hours + 'Hours',
                            "Friday": x.days[6].counter + '  ' + x.days[6].hours + 'Hours',
                            "Saturday": x.days[7].counter + '  ' + x.days[7].hours + 'Hours',
                        });
                    });
                }
                else
                    this.rowsData.push({ "": 'No records found.' });
                this.rowsData.push({ "": "Evening" });
                if (this.evening.length > 0 && this.evening.length != null && this.evening.length != undefined)
                    this.evening.map(function (x) {
                        _this.rowsData.push({
                            "Shift": x.shift.name + '  ' + x.shift.duration,
                            "Unit": x.unit.name,
                            "Sunday": x.days[1].counter + '  ' + x.days[1].hours + 'Hours',
                            "Monday": x.days[2].counter + '  ' + x.days[2].hours + 'Hours',
                            "Tuesday": x.days[3].counter + '  ' + x.days[3].hours + 'Hours',
                            "Wednesday": x.days[4].counter + '  ' + x.days[4].hours + 'Hours',
                            "Thursday": x.days[5].counter + '  ' + x.days[5].hours + 'Hours',
                            "Friday": x.days[6].counter + '  ' + x.days[6].hours + 'Hours',
                            "Saturday": x.days[7].counter + '  ' + x.days[7].hours + 'Hours',
                        });
                    });
                else
                    this.rowsData.push({ "": 'No records found.' });
                this.rowsData.push({ "": "Night" });
                if (this.night.length > 0 && this.night.length != null && this.night.length != undefined)
                    this.night.map(function (x) {
                        _this.rowsData.push({
                            "Shift": x.shift.name + '  ' + x.shift.duration,
                            "Unit": x.unit.name,
                            "Sunday": x.days[1].counter + '  ' + x.days[1].hours + 'Hours',
                            "Monday": x.days[2].counter + '  ' + x.days[2].hours + 'Hours',
                            "Tuesday": x.days[3].counter + '  ' + x.days[3].hours + 'Hours',
                            "Wednesday": x.days[4].counter + '  ' + x.days[4].hours + 'Hours',
                            "Thursday": x.days[5].counter + '  ' + x.days[5].hours + 'Hours',
                            "Friday": x.days[6].counter + '  ' + x.days[6].hours + 'Hours',
                            "Saturday": x.days[7].counter + '  ' + x.days[7].hours + 'Hours',
                        });
                    });
                else
                    this.rowsData.push({ "": 'No records found.' });
            }
        }
        else {
            this.rowsData = [];
            this.rowsData.push({ "": "Day" });
            this.rowsData.push({ "": 'No records found.' });
            this.rowsData.push({ "": "Evening" });
            this.rowsData.push({ "": 'No records found.' });
            this.rowsData.push({ "": "Night" });
            this.rowsData.push({ "": 'No records found.' });
        }
        var ws = XLSX.utils.json_to_sheet(this.rowsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        var wscols = [
            { wch: 10 },
            { wch: 20 },
            { wch: 13 },
            { wch: 13 },
            { wch: 13 },
            { wch: 13 },
            { wch: 13 },
            { wch: 13 },
            { wch: 13 },
            { wch: 13 },
        ];
        ws['!cols'] = wscols;
        XLSX.writeFile(wb, 'Ideal Schedule' + this.dateTime + '.xlsx');
    };
    IdealScheduleComponent.prototype.checkData = function () {
        this.isDisabled = this.stateService.selectedPosition.ranges[0].parLevels != null && Object.keys(this.stateService.selectedPosition.ranges[0].parLevels).length != 0 && this.stateService.selectedPosition.ranges[0] != undefined ? false : true;
    };
    return IdealScheduleComponent;
}());
export { IdealScheduleComponent };
