/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wc-incident-roster.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../../common/components/spinner/spinner.component";
import * as i4 from "../wc-roster-toolbar/wc-roster-toolbar.component.ngfactory";
import * as i5 from "../wc-roster-toolbar/wc-roster-toolbar.component";
import * as i6 from "../../../services/roster/wc-roster-toolbar.service";
import * as i7 from "@angular/router";
import * as i8 from "./wc-incident-roster-grid/wc-incident-roster-grid.component.ngfactory";
import * as i9 from "./wc-incident-roster-grid/wc-incident-roster-grid.component";
import * as i10 from "../../../services/roster/wc-incident-roster-management.service";
import * as i11 from "../../../../../common/services/device-detector/device-detector.service";
import * as i12 from "../../../../../common/services/component-state/component-state-storage.service";
import * as i13 from "../../../../../core/services/user-settings/user-settings.service";
import * as i14 from "../../../../../common/services/app-user-settings/app-user-settings.service";
import * as i15 from "../../../../../common/services/column-settings/column-settings-storage.service";
import * as i16 from "../../../../../common/services/state-management/state-management.service";
import * as i17 from "../../../../../common/services/modal/modal.service";
import * as i18 from "../../../../../common/services/column-settings/column-management.service";
import * as i19 from "../../../services/roster/wc-common-management.service";
import * as i20 from "../../../services/roster/wc-roster-api.service";
import * as i21 from "./wc-incident-roster.component";
var styles_WcIncidentRosterComponent = [i0.styles];
var RenderType_WcIncidentRosterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WcIncidentRosterComponent, data: {} });
export { RenderType_WcIncidentRosterComponent as RenderType_WcIncidentRosterComponent };
export function View_WcIncidentRosterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "slx-high-box leave-management"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-wc-roster-toolbar", [], null, null, null, i4.View_WcRosterToolbarComponent_0, i4.RenderType_WcRosterToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i5.WcRosterToolbarComponent, [i6.WcRosterToolbarService, i7.ActivatedRoute, i7.Router], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-wc-incident-roster-grid", [], null, null, null, i8.View_WcIncidentRosterGridComponent_0, i8.RenderType_WcIncidentRosterGridComponent)), i1.ɵdid(8, 245760, null, 0, i9.WcIncidentRosterGridComponent, [i10.WcIncidentRosterManagementService, i11.DeviceDetectorService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_WcIncidentRosterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-wc-incident-roster", [], null, null, null, View_WcIncidentRosterComponent_0, RenderType_WcIncidentRosterComponent)), i1.ɵprd(512, null, i12.ComponentStateStorageService, i12.ComponentStateStorageService, [i13.UserSettingsService, i14.AppUserSettingsService]), i1.ɵprd(512, null, i15.ColumnSettingsStorageService, i15.ColumnSettingsStorageService, [i13.UserSettingsService, i14.AppUserSettingsService]), i1.ɵprd(512, null, i16.StateManagementService, i16.StateManagementService, [i12.ComponentStateStorageService, i15.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.WcRosterToolbarService, i6.WcRosterToolbarService, [i17.ModalService, i16.StateManagementService, i12.ComponentStateStorageService]), i1.ɵprd(512, null, i18.ColumnManagementService, i18.ColumnManagementService, [i15.ColumnSettingsStorageService]), i1.ɵprd(512, null, i19.WcCommonManagementService, i19.WcCommonManagementService, [i6.WcRosterToolbarService, i20.WcRosterApiService, i15.ColumnSettingsStorageService, i18.ColumnManagementService]), i1.ɵprd(512, null, i10.WcIncidentRosterManagementService, i10.WcIncidentRosterManagementService, [i19.WcCommonManagementService]), i1.ɵdid(8, 245760, null, 0, i21.WcIncidentRosterComponent, [i10.WcIncidentRosterManagementService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); }, null); }
var WcIncidentRosterComponentNgFactory = i1.ɵccf("slx-wc-incident-roster", i21.WcIncidentRosterComponent, View_WcIncidentRosterComponent_Host_0, {}, {}, []);
export { WcIncidentRosterComponentNgFactory as WcIncidentRosterComponentNgFactory };
