import { appConfig, IApplicationConfig } from './../../../../app.config';
import { ShiftSwapInfo, ShiftSwapProfile } from '../../../models/shift-swap/shift-swap-info';
import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-shift-swap-list-item-profile',
    templateUrl: 'shift-swap-list-item-profile.component.html',
    styleUrls: ['shift-swap-list-item-profile.component.scss']
})
export class ShiftSwapListItemProfileComponent {
    public appConfig: IApplicationConfig;
    @Input()
    public profile: ShiftSwapProfile;

    @Input()
    public colorStyle: string;

    @Input()
    public miniProfile: boolean = false;

    constructor() {
        this.appConfig = appConfig;
    }

    public get profileExists(): boolean {
        return this.profile && this.profile.id > 0;
    }
}
