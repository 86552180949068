import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { BaseResolver } from '../../../common/index';
var AccrualsBalancesResolver = /** @class */ (function (_super) {
    tslib_1.__extends(AccrualsBalancesResolver, _super);
    function AccrualsBalancesResolver(router) {
        return _super.call(this, router) || this;
    }
    AccrualsBalancesResolver.prototype.resolve = function (route, state) {
        var isDollarValueActive = this.parseBool(route.queryParamMap.get('showDollarValues'));
        var isEmployeeActive = this.parseBool(route.queryParamMap.get('filterByActiveEmployees'));
        var isShowNegativeBalance = this.parseBool(route.queryParamMap.get('filterByNegativeBalances'));
        var result = {};
        if (!_.isNull(isDollarValueActive)) {
            result.isDollarValueActive = isDollarValueActive;
        }
        if (!_.isNull(isEmployeeActive)) {
            result.isEmployeeActive = isEmployeeActive;
        }
        if (!_.isNull(isShowNegativeBalance)) {
            result.isShowNegativeBalance = isShowNegativeBalance;
        }
        return Promise.resolve(result);
    };
    AccrualsBalancesResolver.prototype.parseBool = function (param) {
        return _.isNull(param) ? null : (param === 'true' || param === '1');
    };
    return AccrualsBalancesResolver;
}(BaseResolver));
export { AccrualsBalancesResolver };
