import {
  Directive, Renderer, HostListener, ViewContainerRef, ViewChild, ComponentFactoryResolver,
  ComponentFactory, ComponentRef, TemplateRef, Input, OnInit, Output, EventEmitter
} from '@angular/core';
import * as _ from 'lodash';
import { PopupComponent } from '../../components/index';

@Directive({
  selector: '[slx-popup]',
})
export class PopupDirective implements OnInit {

  @Input()
  public popupContent: HTMLElement;

  @Input()
  public alignParent: HTMLElement;

  @Input()
  public topOffset: number = 0;

  @Input()
  public left: number;

  @Input()
  public hideOnClick: number;

  @Input()
  public defaultDisplay: string;

  @Input()
  public applyButton: HTMLElement;

  @Input()
  public closeButton: HTMLElement;

  @Output()
  public onClose: EventEmitter<boolean>;

  public state: {
    isShown: boolean;
  };

  private renderer: Renderer;
  private viewContainerRef: ViewContainerRef;
  private componentFactoryResolver: ComponentFactoryResolver;

  private contentDisplay: string;
  private contentWidth: number;

  private componentRef: ComponentRef<PopupComponent>;

  private windowClick: any;
  private globalClickSkiped: boolean;
  private skipNextClick: boolean;

  constructor(renderer: Renderer, viewContainerRef: ViewContainerRef,
    componentFactoryResolver: ComponentFactoryResolver) {
    this.onClose = new EventEmitter();
    this.renderer = renderer;
    this.viewContainerRef = viewContainerRef;
    this.componentFactoryResolver = componentFactoryResolver;

    this.windowClick = (e: MouseEvent) => {
      if (this.state.isShown && this.globalClickSkiped) {
        if (this.hideOnClick || !this.popupContent.contains(<Node>e.target) || this.closeButton === e.target || this.applyButton === e.target) {
          this.hidePopup(this.applyButton === e.target);
          return;
        }
      }

      this.globalClickSkiped = true;
    };

    this.state = {
      isShown: false
    };
  }

  public ngOnInit(): void {
    if (this.popupContent instanceof HTMLElement) {
      this.contentDisplay = this.defaultDisplay ? this.defaultDisplay : this.popupContent.style.display;
      this.contentWidth = this.popupContent.getBoundingClientRect().width;
      if (this.popupContent instanceof HTMLElement) {
        this.popupContent.addEventListener('focusout', (ev: FocusEvent) => {
          if (ev.currentTarget !== this.popupContent && (!ev.relatedTarget || !this.popupContent.contains(<Node>ev.relatedTarget))) {
            this.hidePopup(false);
            this.skipNextClick = true;
            setTimeout(() => { this.skipNextClick = false; }, 100);
          }
        });
      }

      this.hidePopup(false);
    }
  }

  @HostListener('click')
  public onClick(): void {
    if (this.skipNextClick) {
      this.skipNextClick = false;
      return;
    }
    if (this.state.isShown) {
      this.hidePopup(false);
      return;
    }

    let domElement: HTMLElement;

    if (this.popupContent instanceof HTMLElement) {
      domElement = this.popupContent;
    }
    this.renderer.setElementAttribute(domElement, 'tabindex', '-1');
    this.renderer.setElementStyle(domElement, 'outline', 'none');

    let parent: HTMLElement = this.alignParent ? this.alignParent : domElement.parentElement;
    let hostElement: HTMLElement = this.viewContainerRef.element.nativeElement;
    let { top: hostTop, left: hostLeft, right: hostRight, height: hostHeight }: ClientRect = hostElement.getBoundingClientRect();
    let { top: parentTop, left: parentLeft, right: parentRight, width: parentWidth }: ClientRect = parent.getBoundingClientRect();
    let popupWidth: number = this.contentWidth;

    let topToBeSet: number = ((hostTop - parentTop) + hostHeight) + 10 + _.toNumber(this.topOffset);
    let leftToBeSet: number = (hostLeft - parentLeft);
    let rightToBeSet: number = hostRight;

    if (window.innerWidth >= 600) {

      if (parentWidth < leftToBeSet + popupWidth) {
        this.renderer.setElementStyle(domElement, 'right', '0px');
        this.renderer.setElementStyle(domElement, 'left', 'inherit');
      } else {
        this.renderer.setElementStyle(domElement, 'right', 'inherit');
        this.renderer.setElementStyle(domElement, 'left', leftToBeSet + 'px');
      }

      this.renderer.setElementStyle(domElement, 'position', 'absolute');
      this.renderer.setElementStyle(domElement, 'top', topToBeSet + 'px');
      this.renderer.setElementStyle(domElement, 'z-index', '95');
    } else {
      this.renderer.setElementStyle(domElement, 'position', 'absolute');
      this.renderer.setElementStyle(domElement, 'top', topToBeSet + 'px');
      this.renderer.setElementStyle(domElement, 'z-index', '95');
      this.renderer.setElementStyle(domElement, 'right', '0px');
      this.renderer.setElementStyle(domElement, 'left', '0px');
    }

    this.showPopup();
  }

  public hidePopup(isApply: boolean): void {
    this.state.isShown = false;
    if (this.popupContent instanceof HTMLElement) {
      this.renderer.setElementStyle(this.popupContent, 'display', 'none');
    }
    window.document.removeEventListener('click', this.windowClick);
    this.globalClickSkiped = false;
    this.onClose.next(false);
  }

  public showPopup(): void {
    this.state.isShown = true;
    if (this.popupContent instanceof HTMLElement) {
      this.renderer.setElementStyle(this.popupContent, 'display', this.contentDisplay);
      this.popupContent.focus();
      window.document.addEventListener('click', this.windowClick);
    }
  }

}
