import * as tslib_1 from "tslib";
import { LocalStorageService } from './../../../../core/services/local-storage/local-storage.service';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { ColorUtil } from '../../../../common/utils/index';
import * as kendoUiUtils from '../../../../core/utils/kendo-ui-utils';
import { ArrayUtils } from '../../../../framework/array-utils/array-utils';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { TimecardsDisplayManagementService, TimecardsStorageService, TimecardDisplayCommonService } from '../../../services/index';
import { TimecardsActionCmd, TimecardFlatRecord, PayCodeGridModel, TimecardsLastSelectionState } from '../../../models/index';
import { TimecardsActions } from '../../../store';
var CURR_LOGGIN_USER_ALIAS_KEY = "slx-worklinx-user@alias";
var TimecardMobile = /** @class */ (function () {
    function TimecardMobile() {
    }
    return TimecardMobile;
}());
export { TimecardMobile };
var TimecardsDisplayFlatGridComponent = /** @class */ (function () {
    function TimecardsDisplayFlatGridComponent(managementService, changeDetector, storageService, _commonService, timecardActions, localStorageService) {
        var _this = this;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.storageService = storageService;
        this._commonService = _commonService;
        this.timecardActions = timecardActions;
        this.localStorageService = localStorageService;
        this.total = {};
        this.aggregates = [];
        this.aggregatesDef = [
            { field: 'productiveHours', aggregate: 'sum' },
            { field: 'nonProductiveHours', aggregate: 'sum' },
            { field: 'totalHours', aggregate: 'sum' },
            { field: 'regularHours', aggregate: 'sum' },
            { field: 'regularPay', aggregate: 'sum' },
            { field: 'overtimeAndExtraHours', aggregate: 'sum' },
            { field: 'overtimeAndExtraPay', aggregate: 'sum' },
            { field: 'totalAbsencePay', aggregate: 'sum' },
            { field: 'overTimePay', aggregate: 'sum' },
            { field: 'totalOtherPay', aggregate: 'sum' },
            { field: 'totalPay', aggregate: 'sum' },
        ];
        this.pageSize = 50;
        this.punchFilterFields = {
            missing: 'isError',
            invalid: 'isWarning',
            edited: 'isEdited',
            isError: 'missing',
            isWarning: 'invalid',
            isEdited: 'edited'
        };
        this.loading = false;
        this.showPayRatesPreviousState = undefined;
        this.mapFieldNameByFilterName = {};
        this.manuallyUnselectedEverything = false;
        this.isRowSelected = function (e) { return _this.highlightedRows.indexOf(e.dataItem.emp.employeePosition.employee.id) >= 0; };
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.group = [];
        this.gridState.state.sort = [{ field: 'emp.employeePosition.employee.name', dir: 'asc' }];
        this.aggregates = _.map(this.aggregatesDef, function (rule) { return rule; });
        this.highlightedRows = [];
        this.selectedRows = [];
        this.firstInit = true;
        this.stateUpdated = false;
        this.collapsed = {};
    }
    Object.defineProperty(TimecardsDisplayFlatGridComponent.prototype, "isShowHighPrecision", {
        get: function () {
            if (!this.state) {
                return false;
            }
            return this.state.isShowHighPrecision;
        },
        enumerable: true,
        configurable: true
    });
    TimecardsDisplayFlatGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            if (_this.stateUpdated && _.size(_this.records) > 0) {
                _this.gridState.state.skip = 0;
            }
            _this.container = container;
            _this.initFieldNameByFilterNameMapping();
            _this.aggregates = _.map(_this.aggregatesDef, function (rule) { return rule; });
            _.forEach(container.usedRulesDefinitions, function (rule) {
                _this.aggregates.push({ field: rule.uniqName, aggregate: 'sum' });
            });
            _this.load();
            _this.onToggleAllSelected(false);
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.actionSubscription = this.managementService.onActionCmd$.subscribe(function (v) {
            if (v.cmd === TimecardsActionCmd.excelExport) {
                _this.makeXlsExport();
            }
            if (v.cmd === TimecardsActionCmd.pdfExport) {
                _this.grid.saveAsPDF();
            }
        });
        this.stateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            var _a;
            _this.state = state;
            if (_this.state.gridState) {
                _this.gridState.state = _this.state.gridState.state;
                if (_this.gridState.state.filter && _this.gridState.state.filter.filters.length > 0) {
                    _this.gridState.state.filter.filters.map(function (filter) {
                        if (filter.field && filter.field === 'isWarning' || filter.field === 'isError' || filter.field === 'isEdited') {
                            _this.punchFilter = _this.punchFilterFields[filter.field];
                        }
                    });
                }
            }
            // manuallyUnselectedEverything flag checks if the user has manually unselected everything then
            // dont highlight the selected row from state.
            if (!_this.manuallyUnselectedEverything && _this.state.selectedEmployeeId && _this.highlightedRows.indexOf(_this.state.selectedEmployeeId) < 0) {
                _this.highlightedRows.push(_this.state.selectedEmployeeId);
            }
            if (_this.showPayRatesPreviousState === undefined) {
                _this.showPayRatesPreviousState = _this.state.isShowPayRates;
            }
            var col = _.find(state.empColumns.columns, function (c) { return _this.isVisible(c.name); });
            _this.isAllHidden = !!col;
            // manuallyUnselectedEverything flag checks if the user has manually unselected everything then
            // dont highlight the selected row from state.
            if (!_this.manuallyUnselectedEverything && _this.firstInit && _this.state.lastViewedEmployee > 0) {
                _this.highlightedRows.push(_this.state.lastViewedEmployee);
            }
            if (_this.firstInit && _this.state.lastViewedPage > 0) {
                _this.gridState.state.skip = _this.state.lastViewedPage;
                _this.refreshGrid();
            }
            if (_this.firstInit && _this.state.lastSelectedEntries && _this.state.lastSelectedEntries.length > 0) {
                (_a = _this.selectedRows).push.apply(_a, state.lastSelectedEntries);
            }
            if (_this.container && !_this.loading) {
                _this.load(false);
            }
            else {
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            }
            _this.firstInit = false;
            _this.stateUpdated = true;
        });
        this.isLeftSidebarOpenSubscription = this.isLeftSidebarOpen$.subscribe(function (isOpen) {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                if (session.alias && session.user && session.user.name) {
                    var userAtAlias = session.user.name + '@' + session.alias;
                    if (_this.localStorageService.has(CURR_LOGGIN_USER_ALIAS_KEY) && _this.localStorageService.get(CURR_LOGGIN_USER_ALIAS_KEY) !== userAtAlias) {
                        _this.timecardActions.clearTimecardsDisplayGridState();
                    }
                    _this.localStorageService.set(CURR_LOGGIN_USER_ALIAS_KEY, userAtAlias);
                }
            }
        });
    };
    /**
     * Fix for 146380.
     * This function scroll up to the selected row in the grid
     * When user selects the employee in org level timecard and move to individual timecard and move back to org level time card,
     * shows the highlighted employee in the view.
     */
    TimecardsDisplayFlatGridComponent.prototype.scrollToHighlighedRow = function () {
        Array.from(document.querySelectorAll('.k-state-selected')).forEach(function (item) {
            try {
                if (item && item.hasOwnProperty('scrollIntoViewIfNeeded')) {
                    item.scrollIntoViewIfNeeded();
                }
                else {
                    item.scrollIntoView();
                }
            }
            catch (e) {
                console.error(e);
            }
        });
    };
    TimecardsDisplayFlatGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimecardsDisplayFlatGridComponent.prototype.onToggleAllSelected = function (selected) {
        this.isAllSelected = selected;
        if (!this.isAllSelected) {
            _.forEach(this.container.records, function (record) {
                record.isSelected = false;
            });
        }
        else {
            var state = {
                skip: 0,
                take: undefined,
                filter: this.gridState.state.filter
            };
            var filtered = process(this.records, state);
            _.forEach(filtered.data, function (record) {
                record.emp.isSelected = record.isLocked ? false : true;
            });
        }
        this.selectionChange();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    TimecardsDisplayFlatGridComponent.prototype.onChangedState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        this.collapsed[key] = !this.collapsed[key];
    };
    TimecardsDisplayFlatGridComponent.prototype.selectionChange = function () {
        var selectedRecords = [];
        selectedRecords = _.filter(this.container.records, function (record) {
            return record.isSelected === true;
        });
        this.managementService.onRecordsSelected(selectedRecords);
        this.reSelectCheckAll();
    };
    TimecardsDisplayFlatGridComponent.prototype.isVisible = function (field, payCode) {
        if (!this.state) {
            return false;
        }
        var columnState = payCode ? this.state.payCodeColumns : this.state.empColumns;
        if (!columnState || !columnState.columnsMap[field]) {
            return false;
        }
        var column = columnState.columnsMap[field];
        if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
            return false;
        }
        return column.displayed;
    };
    TimecardsDisplayFlatGridComponent.prototype.onCellClick = function (cell) {
        var record = _.get(cell, 'dataItem', null);
        if (_.isObject(record)) {
            var currentId = record.emp.employeePosition.employee.id;
            var previousId = _.head(this.highlightedRows);
            this.highlightedRows.length = 0;
            if (currentId !== previousId) {
                this.highlightedRows.push(currentId);
            }
            // manuallyUnselectedEverything flag check at the time of state change if the user has manually unselected everything then
            // dont highlight the selected row from state.
            this.manuallyUnselectedEverything = (this.highlightedRows.length === 0);
        }
    };
    TimecardsDisplayFlatGridComponent.prototype.onSelectEmployee = function (employeeId) {
        var state = new TimecardsLastSelectionState(employeeId, this.gridState.state.skip);
        this.managementService.onSaveLastSelectionState(state);
        this.saveEmployeeListForIndividualScreen();
    };
    TimecardsDisplayFlatGridComponent.prototype.getWidth = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 100;
    };
    TimecardsDisplayFlatGridComponent.prototype.getFilter = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
    };
    TimecardsDisplayFlatGridComponent.prototype.getColor = function (ruleDef) {
        return ColorUtil.DecToHexString(ruleDef.color, true);
    };
    TimecardsDisplayFlatGridComponent.prototype.getFieldPath = function (ruleDef) {
        return ruleDef.uniqName;
    };
    TimecardsDisplayFlatGridComponent.prototype.getCurrentState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        return _.isBoolean(this.collapsed[key]) ? this.collapsed[key] : (this.collapsed[key] = true);
    };
    TimecardsDisplayFlatGridComponent.prototype.getPayCodeModel = function (record) {
        var payCode = new PayCodeGridModel();
        payCode.positionName = record.positionName;
        payCode.rulesMap = record.rulesMap;
        return payCode;
    };
    TimecardsDisplayFlatGridComponent.prototype.makeGrouppingForMobile = function () {
        var groupOfEmployees = _.groupBy(this.records, function (r) {
            return r.emp.employeePosition.employee.id;
        });
        this.groupOfEmployees = _.values(groupOfEmployees);
    };
    TimecardsDisplayFlatGridComponent.prototype.getGridData = function () {
        var _this = this;
        return function () {
            var gridState = new KendoGridStateHelper();
            gridState.state.skip = 0;
            gridState.state.take = _this.records ? _this.records.length : 0;
            gridState.state.group = _this.gridState.state.group;
            gridState.state.sort = _this.gridState.state.sort;
            gridState.state.filter = _this.gridState.state.filter;
            _this.updateExportState(gridState);
            var data = gridState.view ? gridState.view.data : null;
            return {
                data: data,
                group: _this.gridState.state.group
            };
        };
    };
    TimecardsDisplayFlatGridComponent.prototype.updateRecords = function () {
        var _this = this;
        _.forEach(this.container.records, function (record) {
            var index = _.indexOf(_this.selectedRows, record.employeePosition.employee.id);
            if (index !== -1) {
                record.isSelected = true;
            }
        });
    };
    TimecardsDisplayFlatGridComponent.prototype.refreshGrid = function (updateState) {
        var _this = this;
        if (updateState === void 0) { updateState = true; }
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        if (this.gridState.state.group && this.gridState.state.group.length) {
            this.removeUnavailableGroups();
        }
        this.gridState.state.group.forEach(function (group) { return group.aggregates = _this.aggregates; });
        var keepFilters;
        if (this.gridState.state.filter && this.gridState.state.filter.filters && this.gridState.state.filter.filters.length > 0) {
            this.removeUnavailableFilters();
        }
        if (this.gridState.state.filter && !this.isShowHighPrecision) {
            keepFilters = this.adaptFilterPrecision(this.gridState.state.filter);
        }
        if (this.gridState.state.skip > 0 && this.records && this.records.length < this.gridState.state.skip) {
            this.gridState.state.skip = 0;
        }
        this.gridState.view = process(this.records, this.gridState.state);
        this.total = aggregateBy(this.records, this.aggregates);
        if (keepFilters) {
            this.gridState.state.filter = keepFilters;
        }
        if (updateState) {
            this.timecardActions.changeTimecardsDisplayGridState({
                state: this.gridState.state,
                records: this.records
            });
        }
        this.reSelectCheckAll();
    };
    TimecardsDisplayFlatGridComponent.prototype.reSelectCheckAll = function () {
        var state = {
            skip: 0,
            take: undefined,
            filter: this.gridState.state.filter
        };
        var filteredRecords = process(this.records, state);
        this.isAllSelected = _.every(filteredRecords.data, function (recordItem) {
            return recordItem.emp.isSelected;
        });
    };
    TimecardsDisplayFlatGridComponent.prototype.adaptFilterPrecision = function (filter) {
        var filters = _.cloneDeep(filter);
        kendoUiUtils.adaptNumberFilterPrecision(filter, 2);
        return filters;
    };
    TimecardsDisplayFlatGridComponent.prototype.saveEmployeeListForIndividualScreen = function () {
        var sortOnlyState = new KendoGridStateHelper();
        sortOnlyState.state.skip = 0;
        sortOnlyState.state.take = _.size(this.records);
        sortOnlyState.state.group = [];
        sortOnlyState.state.sort = this.gridState.state.sort;
        sortOnlyState.state.filter = this.gridState.state.filter;
        sortOnlyState.view = process(this.records, sortOnlyState.state);
        var employeesList = _.map(sortOnlyState.view.data, function (record) { return record.emp.employeePosition.employee.id; });
        this.storageService.setEmployeesList(_.uniq(employeesList));
    };
    TimecardsDisplayFlatGridComponent.prototype.updateExportState = function (state) {
        if (!this.records) {
            state.view = null;
            return;
        }
        state.state.sort = this.gridState.state.sort;
        state.state.filter = _.cloneDeep(this.gridState.state.filter);
        state.state.group = _.cloneDeep(this.gridState.state.group); //.forEach((group: any) => group.aggregates = this.aggregates);
        if (state.state.filter && !this.isShowHighPrecision) {
            this.adaptFilterPrecision(state.state.filter);
        }
        state.view = process(this.records, state.state);
    };
    TimecardsDisplayFlatGridComponent.prototype.makeXlsExport = function () {
        var gridState = new KendoGridStateHelper();
        this.updateExportState(gridState);
        var data = gridState ? gridState : null;
        var grid = this.grid;
        var addHeaderInfo = '';
        var group = this.gridState.state.group;
        var fileName = '';
        var usedRulesDefinitionsPayRule = this.container ? this.container.usedRulesDefinitions : [];
        this._commonService.makeXlsExport(data, grid, group, addHeaderInfo, fileName, usedRulesDefinitionsPayRule);
    };
    TimecardsDisplayFlatGridComponent.prototype.load = function (updateState) {
        var _this = this;
        if (updateState === void 0) { updateState = true; }
        this.loading = true;
        var currentEmpId = null;
        try {
            //make note of current page (id of first record in the current page)
            if (this.showPayRatesPreviousState !== this.state.isShowPayRates && this.records) {
                currentEmpId = this.records[this.gridState.state.skip].emp.employeePosition.employee.id;
            }
            var groupByKeys_1 = this.getGroupByKeys();
            this.records = _.reduce(this.container.records, function (result, emp) {
                var first = true;
                var rs = _.map(emp.earnings, function (earn) {
                    var r = new TimecardFlatRecord();
                    r.emp = emp;
                    r.earning = earn;
                    r.regularHours = earn.regularHours;
                    r.regularPay = earn.regularPay;
                    r.overtimeAndExtraHours = earn.overtimeAndExtraHours;
                    r.overtimeAndExtraPay = earn.overtimeAndExtraPay;
                    r.overTimePay = earn.overTimePay;
                    r.totalAbsencePay = earn.totalAbsencePay;
                    r.totalOtherPay = earn.totalOtherPay;
                    r.rulesMap = earn.rulesMap;
                    r.shiftDiffPolicy = emp.shiftDiffPolicy;
                    r.payPolicy = emp.payPolicy;
                    r.standardPayRate = emp.standardPayRate;
                    r.isError = emp.isError;
                    r.isWarning = emp.isWarning;
                    r.isPayCycleLocked = emp.isPayCycleLocked;
                    r.isTimecardLocked = emp.isLocked;
                    r.isEdited = emp.isEdited;
                    //r.rulesValues = {};
                    var ur = r;
                    _.forEach(_this.container.usedRulesDefinitions, function (rule) {
                        var value = r.getRuleValue(rule.name);
                        ur[rule.uniqName] = value ? value : null;
                    });
                    r.totalPay = earn.totalPay;
                    if (first) {
                        first = false;
                        r.productiveHours = emp.productiveHours;
                        r.nonProductiveHours = emp.nonProductiveHours;
                        r.totalHours = emp.totalHours;
                        r.isFirst = true;
                    }
                    else {
                        r.productiveHours = null;
                        r.nonProductiveHours = null;
                        r.totalHours = null;
                    }
                    return r;
                });
                if (rs.length === 0) {
                    var er = new TimecardFlatRecord();
                    er.isFirst = true;
                    er.emp = emp;
                    er.earning = null;
                    er.productiveHours = emp.productiveHours;
                    er.nonProductiveHours = emp.nonProductiveHours;
                    er.totalHours = emp.totalHours;
                    er.regularHours = null;
                    er.regularPay = emp.regularPay;
                    er.overtimeAndExtraHours = null;
                    er.overtimeAndExtraPay = emp.overtimeAndExtra;
                    er.overTimePay = emp.overTimePay;
                    er.totalAbsencePay = emp.totalAbsencePay;
                    er.totalOtherPay = emp.totalOtherPay;
                    er.rulesMap = {};
                    er.shiftDiffPolicy = emp.shiftDiffPolicy;
                    er.payPolicy = emp.payPolicy;
                    er.standardPayRate = emp.standardPayRate;
                    er.isError = emp.isError;
                    er.isWarning = emp.isWarning;
                    er.totalPay = emp.totalPay;
                    er.isPayCycleLocked = emp.isPayCycleLocked;
                    er.isTimecardLocked = emp.isLocked;
                    er.isEdited = emp.isEdited;
                    var uer_1 = er;
                    _.forEach(_this.container.usedRulesDefinitions, function (rule) {
                        uer_1[rule.uniqName] = null;
                    });
                    rs.push(er);
                }
                if (rs.length > 1) {
                    var groupResult = ArrayUtils.groupObjects(rs, groupByKeys_1);
                    var groupedRecords_1 = [];
                    var isFirst_1 = true;
                    _.forEach(groupResult, function (groupRecords) {
                        var er = new TimecardFlatRecord();
                        groupRecords.forEach(function (item) {
                            _this.mergeTimecardRecord(item, er);
                            er.isFirst = isFirst_1;
                        });
                        _this.rebuildRulesMap(er);
                        if (!er.isFirst) {
                            er.productiveHours = null;
                            er.nonProductiveHours = null;
                            er.totalHours = null;
                        }
                        isFirst_1 = false;
                        groupedRecords_1.push(er);
                    });
                    rs = groupedRecords_1;
                }
                return (result || []).concat(rs);
            }, []);
            if (this.selectedRows.length > 0) {
                this.updateRecords();
            }
            if (currentEmpId) {
                var newRecordIndex = this.records.findIndex(function (record) { return record.emp.employeePosition.employee.id === currentEmpId; });
                if (newRecordIndex && newRecordIndex > 1 && this.pageSize > 0) {
                    var newPageIndex = (newRecordIndex - (newRecordIndex % this.pageSize)) / this.pageSize;
                    this.gridState.state.skip = newPageIndex * this.pageSize;
                }
            }
            this.makeGrouppingForMobile();
            this.refreshGrid(updateState);
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
        finally {
            this.scrollToHighlighedRow();
            this.loading = false;
            this.showPayRatesPreviousState = this.state.isShowPayRates;
        }
    };
    TimecardsDisplayFlatGridComponent.prototype.getGroupByKeys = function () {
        var groupByProperties = ['emp.employeePosition.employee.name'];
        this.addGroupByField('organization', 'empOrganization', groupByProperties);
        this.addGroupByField('department', 'departmentName', groupByProperties);
        this.addGroupByField('position', 'positionName', groupByProperties);
        this.addGroupByField('payroll', 'emp.employeePosition.employee.payrollNumber', groupByProperties);
        this.addGroupByField('empType', 'emp.employeePosition.employee.employeeType.name', groupByProperties);
        this.addGroupByField('approved', 'emp.approved', groupByProperties);
        this.addGroupByField('certified', 'emp.certified', groupByProperties);
        this.addGroupByField('payPolicy', 'payPolicy', groupByProperties);
        this.addGroupByField('shiftDiffPolicy', 'earning.shiftDiffPolicy.name', groupByProperties);
        this.addGroupByField('shiftCodeName', 'earning.shiftCodeName', groupByProperties);
        this.addGroupByField('costCenterCode', 'earning.costCenterCode', groupByProperties);
        if (this.state.isShowPayRates) {
            this.addGroupByField('payRate', 'payRate', groupByProperties);
        }
        return groupByProperties;
    };
    TimecardsDisplayFlatGridComponent.prototype.addGroupByField = function (columnName, fieldName, target) {
        if (this.isVisible(columnName)) {
            target.push(fieldName);
        }
    };
    TimecardsDisplayFlatGridComponent.prototype.mergeTimecardRecord = function (source, target) {
        if (!target.earning) {
            target.earning = _.cloneDeep(source.earning);
        }
        else {
            source.earning.rules.forEach(function (rule) {
                var r = _.cloneDeep(rule);
                target.earning.rules.push(r);
            });
        }
        target.emp = source.emp;
        target.productiveHours = source.productiveHours + (target.productiveHours ? target.productiveHours : 0);
        target.nonProductiveHours = source.nonProductiveHours + (target.nonProductiveHours ? target.nonProductiveHours : 0);
        target.totalHours = source.totalHours + (target.totalHours ? target.totalHours : 0);
        target.regularHours = source.regularHours + (target.regularHours ? target.regularHours : 0);
        target.overtimeAndExtraHours = source.overtimeAndExtraHours + (target.overtimeAndExtraHours ? target.overtimeAndExtraHours : 0);
        target.regularPay = source.regularPay + (target.regularPay ? target.regularPay : 0);
        target.overtimeAndExtraPay = source.overtimeAndExtraPay + (target.overtimeAndExtraPay ? target.overtimeAndExtraPay : 0);
        target.overTimePay = source.overTimePay + (target.overTimePay ? target.overTimePay : 0);
        target.totalAbsencePay = source.totalAbsencePay + (target.totalAbsencePay ? target.totalAbsencePay : 0);
        target.totalOtherPay = source.totalOtherPay + (target.totalOtherPay ? target.totalOtherPay : 0);
        target.payPolicy = source.payPolicy;
        target.shiftDiffPolicy = source.shiftDiffPolicy;
        target.standardPayRate = source.standardPayRate;
        target.isError = source.isError;
        target.isWarning = source.isWarning;
        target.isPayCycleLocked = source.isPayCycleLocked;
        target.isTimecardLocked = source.isTimecardLocked;
        target.totalPay = source.totalPay + (target.totalPay ? target.totalPay : 0);
        target.isEdited = source.isEdited;
    };
    TimecardsDisplayFlatGridComponent.prototype.rebuildRulesMap = function (target) {
        target.earning.rulesMap = _.groupBy(target.earning.rules, function (rule) {
            return rule.payRule.name;
        });
        target.rulesMap = _.groupBy(target.earning.rules, function (rule) {
            return rule.payRule.name;
        });
        var ur = target;
        _.forEach(this.container.usedRulesDefinitions, function (rule) {
            var value = target.getRuleValue(rule.name);
            ur[rule.uniqName] = value ? value : null;
        });
    };
    TimecardsDisplayFlatGridComponent.prototype.filterByPunches = function (event, type) {
        event.stopImmediatePropagation();
        var filters = (this.gridState.state.filter || { filters: [] }).filters.filter(function (f) { return f.field !== 'isError' && f.field !== 'isWarning' && f.field !== 'isEdited'; });
        if (this.punchFilter === type) {
            this.punchFilter = undefined;
        }
        else {
            filters.push({
                field: this.punchFilterFields[type],
                operator: 'eq',
                value: true
            });
            this.punchFilter = type;
        }
        if (this.gridState.state.filter) {
            this.gridState.state.filter.filters = filters;
        }
        else {
            this.gridState.state.filter = {
                filters: filters,
                logic: 'and'
            };
        }
        this.refreshGrid();
    };
    /*
    * Check if the columns which are applied on filters are visible in the grid,
    * if they are not, then remove them from the filter column list.
    *
    * There are two exceptions, `isWarning` and `isError` are fields that can be filtered on but do not have a column.
    */
    TimecardsDisplayFlatGridComponent.prototype.removeUnavailableFilters = function () {
        var _this = this;
        if (_.get(this.gridState, 'state.filter.filters')) {
            var result = this.gridState.state.filter.filters.filter(function (item) {
                if (_.get(item, 'filters[0].field')) {
                    var filtersToNotRemove = ['isWarning', 'isError', 'emp.employeePosition.employee.name'];
                    return _this.isVisible(_this.getFieldNameByFilterName(item.filters[0].field)) || _this.isVisible(_this.getFieldNameByFilterName(item.filters[0].field), true) || filtersToNotRemove.includes(item.filters[0].field);
                }
                else {
                    return true;
                }
            });
            if (this.gridState.state.filter.filters.length !== result.length) {
                this.gridState.state.filter.filters = result;
            }
        }
    };
    /*
    * Check if the columns which are applied on grouping are visible in the grid,
    * if they are not, then remove them from the grouping list
    */
    TimecardsDisplayFlatGridComponent.prototype.removeUnavailableGroups = function () {
        var _this = this;
        var result = this.gridState.state.group.filter(function (item) {
            // Name is a special column which will always be visible, need to skip check for it.
            return item.field === 'emp.employeePosition.employee.name' || _this.isVisible(_this.getFieldNameByFilterName(item.field)) || _this.isVisible(_this.getFieldNameByFilterName(item.field), true);
        });
        if (this.gridState.state.group.length !== result.length) {
            this.gridState.state.group = result;
        }
    };
    /*
    * Creates a mapping between column field property and column name.
    */
    TimecardsDisplayFlatGridComponent.prototype.initFieldNameByFilterNameMapping = function () {
        var _this = this;
        this.mapFieldNameByFilterName = {};
        // Field Name ===> Column Name
        this.mapFieldNameByFilterName['emp.employeePosition.employee.name'] = 'emp.employeePosition.employee.name';
        this.mapFieldNameByFilterName['emp.employeePosition.employee.employeeType.name'] = 'empType';
        this.mapFieldNameByFilterName['organizationName'] = 'organization';
        this.mapFieldNameByFilterName['departmentName'] = 'department';
        this.mapFieldNameByFilterName['positionName'] = 'position';
        this.mapFieldNameByFilterName['emp.employeePosition.employee.payrollNumber'] = 'payroll';
        this.mapFieldNameByFilterName['emp.approved'] = 'approved';
        this.mapFieldNameByFilterName['emp.certified'] = 'certified';
        this.mapFieldNameByFilterName['earning.shiftDiffPolicy.name'] = 'shiftDiffPolicy';
        this.mapFieldNameByFilterName['earning.costCenterCode'] = 'costCenterCode';
        this.mapFieldNameByFilterName['emp.employeePosition.employee.dateHired'] = 'hireDate';
        this.mapFieldNameByFilterName['workedHours'] = 'workedHours';
        this.mapFieldNameByFilterName['totalHours'] = 'totalHours';
        this.mapFieldNameByFilterName['overtimeAndExtraPay'] = 'overtimeAndExtraPay';
        this.mapFieldNameByFilterName['overTimePay'] = 'overTimePay';
        this.mapFieldNameByFilterName['totalAbsencePay'] = 'totalAbsencePay';
        this.mapFieldNameByFilterName['totalOtherPay'] = 'totalOtherPay';
        this.mapFieldNameByFilterName['earning.shiftCodeName'] = 'shiftCodeName';
        this.mapFieldNameByFilterName['payRate'] = 'payRate';
        this.mapFieldNameByFilterName['totalPay'] = 'totalPay';
        this.mapFieldNameByFilterName['costCenterCode'] = 'costCenterCode';
        this.mapFieldNameByFilterName['overtimeAndExtraHours'] = 'overtimeAndExtraHours';
        this.mapFieldNameByFilterName['emp.homeShift.name'] = 'homeShift';
        this.mapFieldNameByFilterName['regularHours'] = 'regularHours';
        this.mapFieldNameByFilterName['regularPay'] = 'regularPay';
        this.mapFieldNameByFilterName['payPolicyName'] = 'payPolicy';
        if (this.container && this.container.usedRulesDefinitions && this.container.usedRulesDefinitions.length) {
            _.forEach(this.container.usedRulesDefinitions, function (rule) {
                _this.mapFieldNameByFilterName[_this.getFieldPath(rule)] = rule.name;
            });
        }
    };
    /*
    * Method return a column name for a column field.
    */
    TimecardsDisplayFlatGridComponent.prototype.getFieldNameByFilterName = function (name) {
        var result = this.mapFieldNameByFilterName[name];
        return result;
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], TimecardsDisplayFlatGridComponent.prototype, "isLeftSidebarOpen$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayFlatGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayFlatGridComponent.prototype, "stateChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayFlatGridComponent.prototype, "actionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayFlatGridComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayFlatGridComponent.prototype, "isLeftSidebarOpenSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayFlatGridComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], TimecardsDisplayFlatGridComponent.prototype, "user$", void 0);
    return TimecardsDisplayFlatGridComponent;
}());
export { TimecardsDisplayFlatGridComponent };
