<div *ngIf="dataTypeName === 'int'" class="export-data-parameter_container">
  <div class="slx-ed-toolbar__section">
    <slx-kendo-number slx-input number [name]="parameter.name" #model="ngModel" [(ngModel)]="fieldValue" (ngModelChange)="onChangeValue(model)" [disabled]="disabled"
      [required]="parameter.dataType.required" [min]="parameter.dataType.min" [max]="parameter.dataType.max"
      [format]="includeThousandsSeparator ? 'n' : '#'"
    ></slx-kendo-number>
    <span *ngIf="model?.errors && submitted" class="slx-error-block error-box">
      <span *ngIf="model?.errors?.required" errorMessage for="required"></span>
    </span>
  </div>
</div>

<div *ngIf="dataTypeName === 'date'" class="export-data-parameter_container">
  <div class="slx-ed-toolbar__section">
    <slx-date-picker-ngx slx-input [name]="parameter.name" 
      [required]="parameter.dataType.required" #model="ngModel" [(ngModel)]="fieldValue" (ngModelChange)="onChangeValue(model)" [disabled]="disabled" [readonly]="disabled"
      [minDate]="parameter.dataType.min" [maxDate]="parameter.dataType.max"></slx-date-picker-ngx>
    <span *ngIf="model?.errors && submitted" class="slx-error-block error-box">
      <span *ngIf="model?.errors?.required" errorMessage for="required"></span>
    </span>
  </div>
</div>

<div *ngIf="dataTypeName === 'bit'" class="export-data-parameter_container">
  <div class="slx-ed-toolbar__section">
    <slx-switcher labelOn="Yes" labelOff="No" #model="ngModel" [(ngModel)]="fieldValue" (ngModelChange)="onChangeValue(model)" [disabled]="disabled">
    </slx-switcher>
    <span *ngIf="model?.errors && submitted" class="slx-error-block error-box">
      <span *ngIf="model?.errors?.required" errorMessage for="required"></span>
    </span>
  </div>
</div>

<div *ngIf="dataTypeName === 'string' || dataTypeName === 'text'" class="export-data-parameter_container">
  <div class="slx-ed-toolbar__section">
    <input slx-input #model="ngModel" [(ngModel)]="fieldValue" (ngModelChange)="onChangeValue(model)" placeholder="" [disabled]="disabled"
      [required]="parameter.dataType.required" [minLength]="parameter.dataType.minLength" type="text">
    <span *ngIf="model?.errors && submitted" class="slx-error-block error-box">
      <span *ngIf="model?.errors?.required" errorMessage for="required"></span>
    </span>
  </div>
</div>

<div *ngIf="dataTypeName === 'lookup'" class="export-data-parameter_container">
  <div class="slx-ed-toolbar__section">
    <slx-dropdown-list class="slx-wide dropdown" [valueField]="lookupValueField" [titleField]="lookupTitleField" [options]="options" required [disabled]="disabled" [readonly]="disabled"
      #model="ngModel" [(ngModel)]="fieldValue" (ngModelChange)="onChangeValue(model)" [valuePrimitive]="false" [virtual]="virtual" ></slx-dropdown-list>
    <span *ngIf="model?.errors && submitted" class="slx-error-block error-box">
      <span *ngIf="model?.errors?.required" errorMessage for="required"></span>
    </span>
  </div>
</div>