import * as _ from 'lodash';
import { ViewContainerRef, ComponentRef, Type, ComponentFactoryResolver, Injector, Provider } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { ModalStorageService } from './modal-storage.service';
import { ModalBehavior } from '../../models/index';
import { Assert } from '../../../framework/index';
import { PopupService } from './popup.service';
import { TitleWindowComponent } from '../../components/title-window/slx-title-window.component';
var ModalService = /** @class */ (function () {
    function ModalService(componentFactoryResolver, injector, modalStorageService, popupService) {
        this.popupService = popupService;
        Assert.isNotNull(componentFactoryResolver, 'componentFactoryResolver');
        Assert.isNotNull(injector, 'injector');
        Assert.isNotNull(modalStorageService, 'modalStorageService');
        this.componentFactoryResolver = componentFactoryResolver;
        this.injector = injector;
        this.modalStorageService = modalStorageService;
        this.modalsHash = {};
        this.subscriptionsHash = {};
        this.settingsHash = {};
        this.globalUId = 'global';
        this.createObservables();
    }
    Object.defineProperty(ModalService.prototype, "globalAnchor", {
        get: function () {
            Assert.isNotNull(this.modalAnchorDirective, 'globalAnchor');
            return this.modalAnchorDirective;
        },
        enumerable: true,
        configurable: true
    });
    ModalService.prototype.setGlobalAnchor = function (modalAnchorDirective) {
        //if (this.modalAnchorDirective) {
        // throw new Error('Global anchor already exist');
        //return;
        //}
        this.modalAnchorDirective = modalAnchorDirective;
    };
    ModalService.prototype.open = function (type, element, title, providers, uniqueId) {
        Assert.isNotNull(type, 'type');
        Assert.isNotNull(element, 'element');
        var settings = this.createDefaultModalSettings();
        if (title) {
            settings.title = title;
        }
        return this.openInternal(type, element, settings, providers, uniqueId);
    };
    ModalService.prototype.openWithSaved = function (type, element, title, providers, behavior, uniqueId) {
        Assert.isNotNull(type, 'type');
        Assert.isNotNull(element, 'element');
        var settings = this.getModalSettings(type.name);
        if (behavior) {
            settings.actions = behavior;
        }
        if (title) {
            settings.title = title;
        }
        return this.openInternal(type, element, settings, providers, uniqueId);
    };
    ModalService.prototype.openEx = function (type, element, settings, providers, uniqueId) {
        Assert.isNotNull(type, 'type');
        Assert.isNotNull(element, 'element');
        return this.openInternal(type, element, settings, providers, uniqueId);
    };
    ModalService.prototype.closeAllWindows = function () {
        _.forEach(this.modalsHash, function (popup) {
            popup.close();
        });
    };
    ModalService.prototype.setTitle = function (title, uniqueId) {
        var selector = "slx-title-window .window-container[modalid='" + uniqueId + "'] .title-label";
        var header = document.querySelector(selector);
        header.innerHTML = title;
    };
    ModalService.prototype.closeWindow = function (uniqueId) {
        Assert.isOwnProperty(this.modalsHash, uniqueId, 'Modal Window not exist');
        var popup = this.modalsHash[uniqueId];
        popup.close();
    };
    ModalService.prototype.createDefaultModalSettings = function () {
        var settings = {
            name: '',
            actions: [ModalBehavior.maximize, ModalBehavior.close],
            autoFocus: true,
            draggable: false,
            iframe: false,
            height: '100%',
            width: '100%',
            maxHeight: null,
            maxWidth: null,
            minHeight: null,
            minWidth: null,
            top: 0,
            left: 0,
            modal: true,
            pinned: false,
            resizable: false,
            scrollable: false,
            title: '',
            visible: false,
            maximized: true,
            displayBlock: false,
            useWindowWrapper: true,
            showCloseButton: true,
            className: '',
            topmost: false,
            zIndexForTopMost: 0,
            headerClassName: ''
        };
        return settings;
    };
    ModalService.prototype.setModalSettings = function (type, settings) {
        this.modalStorageService.setModalSettings(type, settings);
    };
    ModalService.prototype.getModalSettings = function (type) {
        var settings;
        if (this.modalStorageService.isExist(type)) {
            settings = this.modalStorageService.getModalSettings(type);
        }
        else {
            settings = this.createDefaultModalSettings();
            this.modalStorageService.setModalSettings(type, settings);
        }
        return settings;
    };
    ModalService.prototype.subscribeOnOpen = function (callback) {
        Assert.isNotNull(callback, 'callback');
        this.onOpen$.subscribe(callback);
    };
    ModalService.prototype.subscribeOnClose = function (callback) {
        Assert.isNotNull(callback, 'callback');
        this.onClose$.subscribe(callback);
    };
    ModalService.prototype.getOpenedDialogsCount = function () {
        return _.keys(this.modalsHash).length;
    };
    ModalService.prototype.openInternal = function (type, element, settings, providers, uniqueId) {
        var _this = this;
        Assert.isNotNull(type, 'type');
        Assert.isNotNull(element, 'element');
        Assert.isNotNull(settings, 'settings');
        this.currentType = type.name;
        var popupSettings = {};
        popupSettings.content = type;
        popupSettings.anchor = element.element;
        popupSettings.animate = true;
        popupSettings.popupClass = 'full-size-popup';
        if (providers) {
            popupSettings.providers = providers;
        }
        if (element) {
            popupSettings.appendTo = element;
        }
        popupSettings.anchorAlign = { horizontal: 'center', vertical: 'center' };
        popupSettings.popupAlign = { horizontal: 'center', vertical: 'center' };
        if (!uniqueId) {
            uniqueId = this.guid();
        }
        var reference;
        if (settings.useWindowWrapper) {
            var wrappedResult = this.popupService.openWithWindowWrapper(TitleWindowComponent, popupSettings);
            reference = wrappedResult.ref;
            var wrapper = wrappedResult.wrapper.instance;
            wrapper.title = settings.title;
            wrapper.modalId = uniqueId;
            wrapper.showCloseButton = settings.showCloseButton;
            wrapper.className = settings.className;
            wrapper.headerClassName = settings.headerClassName;
            if (settings.showCloseButton) {
                var popupCloseByButtonSubscription = wrapper.onCloseByButton.subscribe(function (modalId) {
                    _this.closeWindow(modalId);
                });
            }
        }
        else {
            reference = this.popupService.open(popupSettings);
        }
        if (settings.maximized)
            settings.width = settings.height = '100%';
        if (settings.width) {
            if (typeof settings.width === 'string') {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'width', settings.width);
            }
            else {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'width', settings.width + 'px');
            }
        }
        if (settings.height) {
            if (typeof settings.height === 'string') {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'height', settings.height);
            }
            else {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'height', settings.height + 'px');
            }
        }
        if (settings.minWidth) {
            if (typeof settings.minWidth === 'string') {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-width', settings.minWidth);
            }
            else {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-width', settings.minWidth + 'px');
            }
        }
        if (settings.minHeight) {
            if (typeof settings.minHeight === 'string') {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-height', settings.minHeight);
            }
            else {
                this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'min-height', settings.minHeight + 'px');
            }
        }
        if (settings.topmost) {
            this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'z-index', settings.zIndexForTopMost);
        }
        if (settings.maxHeight !== undefined) {
            this.globalAnchor.setElementStyle(reference.popup.instance.container.nativeElement, 'max-height', settings.maxHeight);
        }
        Assert.isNotOwnProperty(this.modalsHash, uniqueId, 'Modal Window already exist');
        this.modalsHash[uniqueId] = reference;
        var popupCloseSubscription = reference.popupClose.subscribe(function () {
            _this.onClose(reference);
        });
        this.subscriptionsHash[uniqueId] = popupCloseSubscription;
        this.settingsHash[uniqueId] = settings;
        return { uniqueId: uniqueId, reference: reference.content.instance };
    };
    ModalService.prototype.createWindowOptions = function (settings, type) {
        Assert.isNotNull(settings, 'settings');
        Assert.isNotNull(type, 'type');
        var options = {
            actions: settings.actions,
            autoFocus: settings.autoFocus,
            draggable: settings.draggable,
            height: settings.height,
            width: settings.width,
            modal: settings.modal,
            pinned: settings.pinned,
            resizable: settings.resizable,
            scrollable: true,
            title: settings.title,
            visible: false,
            minWidth: settings.minWidth,
            minHeight: settings.minHeight
        };
        return options;
    };
    ModalService.prototype.createObservables = function () {
        this.onOpenSource = new Subject();
        this.onCloseSource = new Subject();
        this.onOpen$ = this.onOpenSource.asObservable();
        this.onClose$ = this.onCloseSource.asObservable();
    };
    ModalService.prototype.onOpen = function (popupRef) {
        Assert.isNotNull(popupRef, 'ModalService onOpen popupRef');
        var uniqId = _.findKey(this.modalsHash, function (ref) {
            return ref === popupRef;
        });
        if (this.onOpenSource) {
            this.onOpenSource.next({ uniqId: uniqId });
        }
    };
    ModalService.prototype.onClose = function (popupRef) {
        Assert.isNotNull(popupRef, 'ModalService onClose popupRef');
        var uniqId = _.findKey(this.modalsHash, function (ref) {
            return ref === popupRef;
        });
        if (uniqId) {
            var subscription = this.subscriptionsHash[uniqId];
            if (subscription)
                subscription.unsubscribe();
            delete this.subscriptionsHash[uniqId];
            delete this.modalsHash[uniqId];
            this.saveSettings(this.settingsHash[uniqId]);
            delete this.settingsHash[uniqId];
        }
        if (this.onCloseSource) {
            this.onCloseSource.next({ uniqId: uniqId });
        }
    };
    ModalService.prototype.clearSuscriptions = function () {
        if (this.onCloseSource) {
            this.onCloseSource.unsubscribe();
            this.onCloseSource = null;
        }
        if (this.onOpenSource) {
            this.onOpenSource.unsubscribe();
            this.onOpenSource = null;
        }
    };
    ModalService.prototype.saveSettings = function (popupSettings) {
        Assert.isNotNull(popupSettings, 'ModalService popupSettings');
        var settings = this.getModalSettings(this.currentType);
        settings.maximized = popupSettings.maximized;
        if (!settings.maximized) {
            settings.height = popupSettings.height;
            settings.width = popupSettings.width;
        }
        this.setModalSettings(this.currentType, settings);
    };
    ModalService.prototype.guid = function () {
        return Math.random().toString(36).substr(2, 9);
    };
    return ModalService;
}());
export { ModalService };
