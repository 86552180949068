<div class="toolbar-holder">
  <slx-toolbar *ngIf="settings">
    <ng-template slxToolbarSectionTemplate  let-sectionType="sectionType" width="320">
      <div class="slx-toolbar-section cycle-range"
        [ngClass]="{ 'slx-border slx-no-padd-l': isVisibleSection(sectionType), 'slx-padd-t slx-padd-b': !isVisibleSection(sectionType) }"
      >
          <slx-schedule-cycle-calendar-slider
          [ngModel]="dateFrom"
          (ngModelChange)="onDateFromChanged($event)"
          [minDate]="min"
          [maxDate]="max"
          [cycles]="cycles"
          name="dateFrom"></slx-schedule-cycle-calendar-slider>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate width="210" let-sectionType="sectionType">
      <div class="slx-so-toolbar__section slx-toolbar-section slx-border dropdown" *ngIf="isVisibleSection(sectionType)">
        <slx-dropdown-list class="slx-wide slx-groupBy-dd"
        [options]="settings.groupByList"
        [(ngModel)]="filters.groupBy"
        valueField="id"
        titleField="name"
        (ngModelChange)="onPositionGroupingChanged()">
        </slx-dropdown-list>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" width="360">
      <div class="slx-toolbar-section settings-dispay-order-section flex-row"
        [ngClass]="{ 'slx-border': isVisibleSection(sectionType), 'slx-padd-t slx-padd-b slx-separator': !isVisibleSection(sectionType) }"
      >
          <slx-radio-button name="cellDisplayOrderName" fieldName="cellDisplayOrderName" option="name" caption="Name"
            [(ngModel)]="settings.display.displayOrder" (ngModelChange)="onDisplaySelectChanged()">
          </slx-radio-button>
          <slx-radio-button name="cellDisplayOrderSeniority" fieldName="cellDisplayOrderSeniority" option="seniority" caption="Seniority"
            [(ngModel)]="settings.display.displayOrder" (ngModelChange)="onDisplaySelectChanged()">
        </slx-radio-button>
        <slx-check-box caption="Pin Total Rows" [(ngModel)]="settings.display.pinTotalRows" (ngModelChange)="onDisplaySelectChanged()">
        </slx-check-box>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType"  width="300">
      <div class="slx-toolbar-section secondary-position-section"
        [ngClass]="{ 'slx-border': isVisibleSection(sectionType), 'slx-padd-t slx-padd-b slx-separator': !isVisibleSection(sectionType) }"
      >
        <slx-check-box caption="Separate Secondary Positions" [(ngModel)]="settings.display.separateSeconaryPositions" (ngModelChange)="onDisplaySelectChanged()">
        </slx-check-box>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" width="180">
        <div class="slx-toolbar-section btn-section"
          [ngClass]="{ 'slx-border': isVisibleSection(sectionType), 'slx-padd-t slx-padd-b slx-separator': !isVisibleSection(sectionType) }"
        >
          <button class="slx-button slx-with-icon quick-edit-btn"
            [class.slx-blue]="isQuickEditStarted"
            (click)="quickEditToggle()"
            [disabled]="!actions?.canEdit"
          >
            <i class="far fa-calendar-plus slx-button__icon quick-edit-btn__c-icon" aria-hidden="true"></i>
            <i class="fas fa-hand-point-up slx-button__icon quick-edit-btn__h-icon" aria-hidden="true"></i>
            <span class="slx-button__text">Quick Edit</span>
          </button>
        </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" let-isCollapsed="isCollapsed" width="180">
      <div class="slx-toolbar-section btn-section"
        [ngClass]="{ 'slx-padd-t slx-padd-b': !isVisibleSection(sectionType) }"
      >
        <button type="button"
          class="slx-button slx-tooltip"
          [disabled]="!isStartCycle(dateFrom)"
          [title]="!isStartCycle(dateFrom) ? 'User does not have sufficient rights' : ''"
          [popper]="autoSchedule"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="'true'"
          (popperOnHidden)="onCloseAutoScheduling()"
        >
          <i class="fas fa-magic slx-button__icon" aria-hidden="true"></i>
          Auto schedule
          
          <!-- Enabled button without warning and without tooltip -->
          <span *ngIf="autoSchedulingConfig.enabled" class="slx-button__tooltip">
            <i class="fa fa-cog" aria-hidden="true"></i>
          </span>

          <span class="slx-button__tooltip slx-warn" *ngIf="autoSchedulingConfig.enabled && !autoSchedulingState.nextSchedule">
              <!-- Enabled button with warning and tooltip -->
              <span
                slxTooltip="Auto schedule enabled but not exist any schedule."
                tipPosition="{{ isCollapsed ? 'top' : 'bottom' }}"
              >
                <i class="fas fa-exclamation" aria-hidden="true"></i>
              </span>
          </span>
        </button>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" alignMode="right">
      <div class="slx-toolbar-section slx-no-padd-r flex-row">

            <button type="button" class="theme-icon-button"
            [popper]="gridSettingsToggler"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-start'"
            [popperDisableStyle]="'true'"
            >
            <i class="fa fa-cog" aria-hidden="true"></i>
            </button>
            <button type="button" [ngClass]="{'filter-enabled': isFiltered}" class="theme-icon-button"
            [popper]="gridFiltersToggler"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-start'"
            [popperDisableStyle]="'true'"
            >
              <i *ngIf="!isFiltered" class="fas fa-sliders-h" aria-hidden="true"></i>
              <i *ngIf="isFiltered" class="fa fa-filter" aria-hidden="true"></i>
            </button>
              <slx-actions-list className="slx-actions-list-grey-theme">
                <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
                  Actions <span class="caret"></span>
                </slx-actions-button>
                <popper-content #popperContent>
                  <slx-actions-list-item [title]="getActionCaption(dateFrom)" [disabled]="!actions?.canBackup || !isStartCycle(dateFrom)" (onClick)="onActionChanged('backup')">Backup Schedule</slx-actions-list-item>
                  <slx-actions-list-item [title]="getActionCaption(dateFrom)" [disabled]="!actions?.canRestore || !isStartCycle(dateFrom)" (onClick)="onActionChanged('restore')">Restore Schedule</slx-actions-list-item>
                  <slx-actions-list-item [title]="getActionCaption(dateFrom)" [disabled]="!actions?.canDelete || !isStartCycle(dateFrom)" (onClick)="onActionChanged('delete')">Delete Schedule</slx-actions-list-item>
                  <slx-actions-list-item className="divider"></slx-actions-list-item>
                  <slx-actions-list-item [title]="getActionCaption(dateFrom)"[disabled]="!actions?.canGenerate || !isStartCycle(dateFrom)" (onClick)="onActionChanged('generate')">Generate Schedule</slx-actions-list-item>
                  <slx-actions-list-item [title]="getActionCaption(dateFrom)" [disabled]="!isStartCycle(dateFrom)" (onClick)="onActionChanged('showSummary')">Show Generate Summary</slx-actions-list-item>
                  <slx-actions-list-item [title]="getActionCaption(dateFrom)" [disabled]="!actions?.canPost || !isStartCycle(dateFrom)" (onClick)="onActionChanged('post')">Post Schedule</slx-actions-list-item>
                  <slx-actions-list-item (onClick)="onActionChanged('export')">Export</slx-actions-list-item>
                </popper-content>
              </slx-actions-list>
            </div>
    </ng-template>
  </slx-toolbar>
</div>
<popper-content #gridSettingsToggler class="popper-white-background wide800-popper">
  <div class="grid-settings" *ngIf="settings">
    <div class="dropdown-menu-settings">
      <div class="cell-display-settings">
        <div class="title">Cell Display</div>
        <div class="cell-display-option" *ngFor="let option of settings.displayList">
          <slx-radio-button name="displayList{{option.id}}" fieldName="displayList{{option.id}}" [option]="option.id" caption="{{option.name}}"
            [(ngModel)]="settings.display.cellDisplay">
          </slx-radio-button>
        </div>
       </div>
    <div class="columns-settings">
        <div class="title">Col Display</div>
        <slx-master-schedule-columns-settings  [columns]="settings?.columns"></slx-master-schedule-columns-settings>
    </div>
    <div class="totals-settings">
        <div class="title">Totals</div>
      <slx-master-schedule-totals-settings [totals]="settings?.totals"></slx-master-schedule-totals-settings>
    </div>
    </div>
    <div class="settings-buttons">
    <button #gridSettingsApply class="settings-apply-button theme-button-apply margin-r" type="button" (click)="onSettingsChanged(gridSettingsToggler, true)">Apply</button>
    <button #gridSettingsClose class="settings-cancel-button theme-button-cancel" type="button" (click)="onSettingsChanged(gridSettingsToggler, false)">Close</button>
    </div>
  </div>
</popper-content>

<popper-content #gridFiltersToggler class="popper-white-background">
  <div class="grid-filters">
    <div class="dropdown-menu-filters" *ngIf="!!filters">
      <slx-dropdown-list class="slx-wide" [defaultItem]="defaultItem" name="positionGroups" [options]="positionGroupLookup" placeholder="Position Group" valueField="id" titleField="name"
        [(ngModel)]="filters.filters.positionGroup">
      </slx-dropdown-list>
       <slx-dropdown-list class="slx-wide" [defaultItem]="defaultItem" name="positions" [options]="positionLookup" placeholder="Position" valueField="id" titleField="name"
        [(ngModel)]="filters.filters.position">
      </slx-dropdown-list>
      <slx-multiselect class="slx-wide"
          [options]="employeeTypeLookup"
          [externalPlaceholder]="true"
          [(ngModel)]="filters.filters.employeeType"
          titleField="description"
          valueField="name"
          placeholder="Employee Type"
          name="employeeTypes"
        ></slx-multiselect>
      <slx-dropdown-list class="slx-wide" [defaultItem]="defaultItem" name="shiftGroups" [options]="shiftGroupLookup" placeholder="Shift Group" valueField="id" titleField="name"
      [(ngModel)]="filters.filters.shiftGroup">
     </slx-dropdown-list>
     <slx-dropdown-list class="slx-wide" [defaultItem]="defaultItem" name="units" [options]="unitLookup" placeholder="Unit" valueField="id" titleField="name"
     [(ngModel)]="filters.filters.unit">
    </slx-dropdown-list>
    </div>
    <button #gridFiltersApply class="filters-apply-button theme-button-apply margin-r" type="button" (click)="onFiltersChanged(gridFiltersToggler, true)">Apply</button>
    <button #gridFiltersClose class="filters-cancel-button theme-button-cancel" type="button" (click)="onFiltersChanged(gridFiltersToggler, false)" >Close</button>
  </div>
</popper-content>

<popper-content #autoSchedule class="popper-white-background">
  <slx-spinner [show]="loadingAutoSchedulingConfig">
    <div class="auto-schedule">
      <h4 class="auto-schedule__title">
        <i class="fas fa-magic auto-schedule__i-title" aria-hidden="true"></i>
        <span class="auto-schedule__title-text">Auto schedule</span>
        <i class="fas fa-times-circle auto-schedule__i-close" aria-hidden="true" (click)="autoSchedule.hide()"></i>
      </h4>
      <p *ngIf="!autoSchedulingConfig.enabled" class="auto-schedule__message">Auto schedule has not been configured yet.</p>
      <div class="auto-schedule__body">
        <p class="auto-schedule__text">How many days before the end of the current schedule, do you want to have the next schedule <strong>auto-generate</strong>?</p>
        <p class="auto-schedule__control">
          <span>
            <slx-dropdown-list #autoGenerate="ngModel"
              class="slx-wide"
              [options]="generatingDaysList"
              [(ngModel)]="daysBeforeGenerating"
              (ngModelChange)="onDaysListChanged(true)"
              [readonly]="!isStartCycle(dateFrom)"
              titleField="day"
              valueField="id"
              placeholder="Days"
              name="autoGenerate"
            ></slx-dropdown-list>
          </span>
          <span class="auto-schedule__date">
            <label class="auto-schedule__label">Projected date</label>
            <input type="text"
              class="auto-schedule__input"
              [(ngModel)]="autoSchedulingConfig.generatingDate"
              readonly
            />
          </span>
        </p>
        <p class="auto-schedule__text">How many days before the end of the current schedule, do you want to have the next schedule <strong>auto-posted</strong>?</p>
        <p class="auto-schedule__control">
          <span>
            <slx-dropdown-list #autoPost="ngModel"
              class="slx-wide"
              [options]="postingDaysList"
              [(ngModel)]="daysBeforePosting"
              (ngModelChange)="onDaysListChanged(false)"
              [readonly]="!isStartCycle(dateFrom) || !autoSchedulingConfig.hasConfiguredGeneratingDate"
              titleField="day"
              valueField="id"
              placeholder="Days"
              title="{{ !autoSchedulingConfig.hasConfiguredGeneratingDate ? 'Please choose auto-generate days firstly' : '' }}"
              name="autoPost"
            ></slx-dropdown-list>
          </span>
          <span class="auto-schedule__date">
            <label class="auto-schedule__label"
              [ngClass]="{ 'error': hasAutoSchedulingErrors() }"
            >Projected date</label>
            <input type="text"
              class="auto-schedule__input"
              [ngClass]="{ 'error': hasAutoSchedulingErrors() }"
              [(ngModel)]="autoSchedulingConfig.postingDate"
              readonly
            />
            <span *ngIf="autoSchedulingConfig.postingDateInPast" class="auto-schedule__error">Auto-posted date cannot be in past</span>
            <span *ngIf="autoSchedulingConfig.postingDateBeforeGenerating" class="auto-schedule__error">Auto-posted date cannot be earlier than auto-generate date</span>
          </span>
        </p>

        <div *ngIf="autoSchedulingConfig.enabled && autoSchedulingConfig" class="flex auto-schedule__state">
           <div class="flex__width50" *ngIf="autoSchedulingState.lastScheduleState">
            <span class="state-par__title">Last Execution State: </span>
            <span class="state-par__value">{{autoSchedulingState.lastScheduleState}}</span>
          </div>
          <div class="flex__width50" *ngIf="autoSchedulingState.lastScheduleTime">
              <span class="state-par__title">Last Execution: </span>
              <span class="state-par__value">{{autoSchedulingState.lastScheduleTime | amDateFormat: appConfig.dateTimeFormatUS}}</span>
          </div>
          <div class="flex__width100" *ngIf="autoSchedulingState.nextSchedule">
              <span class="state-par__title">Next Execution: </span>
              <span class="state-par__value">{{autoSchedulingState.nextSchedule  | amDateFormat: appConfig.dateTimeFormatUS}}</span>
          </div>
        </div>
        <div class="slx-error-block" *ngIf="autoSchedulingState && !autoSchedulingState.userHasAccess">The user does not have rights to configure Auto Schedule</div>
        <p class="auto-schedule__buttons">
          <button type="button" [disabled]="!autoSchedulingState?.userHasAccess"
            *ngIf="autoSchedulingConfig && autoSchedulingConfig.enabled"
            class="slx-button slx-blue slx-margin-r slx-min-w90"
            (click)="onDeleteAutoScheduling(autoSchedule)"
          >Deactivate</button>
          <button type="button"
            class="slx-button slx-blue slx-margin-r slx-min-w90"
            [disabled]="hasAutoSchedulingErrors() || !autoSchedulingConfig.hasConfiguredDates || !autoSchedulingState?.userHasAccess"
            (click)="onSaveAutoScheduling(autoSchedule)"
          >Save</button>
        </p>
      </div>
    </div>
  </slx-spinner>
</popper-content>

<kendo-window class="quick-edit-window"
  *ngIf="isQuickEditActive"
  (dragEnd)="onQuickWindowDragEnd()"
  [resizable]="false"
  [(top)]="quickEditWindowTop"
  [(left)]="quickEditWindowLeft"
  [width]="quickEditWidth"
  [slxKendoWindowPositionRight]="100"
  (close)="quickEditClose()"
>
  <kendo-window-titlebar>
    <div class="quick-edit-header-icon-container">
      <i class="far fa-calendar-plus main-icon" aria-hidden="true"></i>
      <i class="far fa-hand-point-up additional-icon" aria-hidden="true"></i>
    </div>
        Quick Edit Panel
    <button class="quick-edit-close-button" (click)="quickEditClose()">
        <i class="fas fa-times-circle" aria-hidden="true"></i>
    </button>
  </kendo-window-titlebar>
 <slx-master-schedule-quick-edit [orgLevelId]="selectedOrgLevel?.id"></slx-master-schedule-quick-edit>
</kendo-window>
