import { Directive, Input, ElementRef, Renderer2, Host, AfterViewInit} from '@angular/core';
import * as _ from 'lodash';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { WindowComponent } from '@progress/kendo-angular-dialog';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';

@Directive({
  selector: '[slxKendoWindowPositionRight]',
})
export class KendoWindowPositionRightDirective implements AfterViewInit {

  @Input('slxKendoWindowPositionRight')
  public set right(value: number) {
    if (_.isString()) {
      this.m_right = _.toNumber(value);
    } else {
      this.m_right = value;
    }
  }
  public get right(): number {
    return this.m_right;
  }
  private m_right: number = 0;

  constructor(@Host() private kendoWindow: WindowComponent, private elementRef: ElementRef, private renderer: Renderer2) {
  }

  public ngAfterViewInit(): void {
    let width: number = window.innerWidth;
    const leftOffset = width - this.kendoWindow.width - this.right;
    this.kendoWindow.left = leftOffset;
    this.renderer.setStyle(this.elementRef.nativeElement, 'left', leftOffset + 'px');
  }
}
