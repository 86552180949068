<!-- toolbar -->
<div class="slx-content-toolbar-indents flex-container action-buttons">
    <div class="buttons-box">
        <button class="slx-button slx-with-icon slx-min-w90" *ngIf="management.state.canAdd" (click)="onAddItem()">
            <i class="fas fa-plus-circle slx-button__icon" aria-hidden="true"></i>
            <span class="slx-button__text">Add</span>
        </button>
        <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted delete-btn" *ngIf="management.state.canDelete"
            (click)="onDeleteItems()">
            <i class="fas fa-trash-alt slx-button__icon" aria-hidden="true"></i>
            <span class="slx-button__text">Delete</span>
        </button>
    </div>
</div>
