import { Provider } from '@angular/core';
import { DialogOptions, DialogOptions2, ModalService } from '../../../../../common';
import { PbjDuplicateMatchesDialogOptions } from '../../../models/pbj-duplicate-workers/pbj-duplicate-matches-dialog-options';
var PbjDuplicateMatchesDialogComponent = /** @class */ (function () {
    function PbjDuplicateMatchesDialogComponent(pbjDuplicateMatchesDialogOptions, dialogOptions, modalService) {
        this.pbjDuplicateMatchesDialogOptions = pbjDuplicateMatchesDialogOptions;
        this.dialogOptions = dialogOptions;
        this.currentDate = new Date();
        this.modalService = modalService;
        this.dialogResult = false;
        this.selectedDate = PbjDuplicateMatchesDialogComponent.duplicateMatchesDefaultDate;
        console.log(PbjDuplicateMatchesDialogComponent.duplicateMatchesDefaultDate);
        console.log(this.selectedDate);
    }
    Object.defineProperty(PbjDuplicateMatchesDialogComponent.prototype, "foundMatches", {
        get: function () {
            if (this.pbjDuplicateMatchesDialogOptions) {
                return this.pbjDuplicateMatchesDialogOptions.FoundMatchingDupes;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    PbjDuplicateMatchesDialogComponent.openDialog = function (options, modalService, callback) {
        this.duplicateMatchesDefaultDate = options.DuplicateMatchesDate;
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.height = 200;
        dialogOptions.width = 500;
        dialogOptions.className = 'slx-pbj-duplicate-matches-modal';
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions,
            },
            {
                provide: PbjDuplicateMatchesDialogOptions,
                useValue: options
            }
        ];
        var dialog = modalService.globalAnchor.openDialog2(PbjDuplicateMatchesDialogComponent, 'Select Date', dialogOptions, resolvedProviders, callback);
        return dialog;
    };
    PbjDuplicateMatchesDialogComponent.prototype.onFilterDateChanged = function (date) {
        this.selectedDate = date;
    };
    PbjDuplicateMatchesDialogComponent.prototype.onClose = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    PbjDuplicateMatchesDialogComponent.prototype.onSave = function () {
        this.dialogResult = true;
        this.selectedDate = new Date(this.selectedDate.setHours(0, 0, 0, 0));
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    return PbjDuplicateMatchesDialogComponent;
}());
export { PbjDuplicateMatchesDialogComponent };
