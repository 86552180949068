import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../custom-list-actor.base';
var EditableListEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EditableListEditorComponent, _super);
    function EditableListEditorComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EditableListEditorComponent.prototype.ngOnInit = function () {
        this.formGroup = this.createFormGroup();
        if (this.item)
            this.updateFormGroup();
    };
    EditableListEditorComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    EditableListEditorComponent.prototype.onSaveClick = function (event) {
        event.preventDefault();
        this.updateItem();
        this.actionEmitter.emit(EditableListActionKind.COMPLETE_EDIT);
    };
    EditableListEditorComponent.prototype.onCancelClick = function (event) {
        event.preventDefault();
        this.actionEmitter.emit(EditableListActionKind.CANCEL_EDIT);
    };
    EditableListEditorComponent.prototype.updateItem = function () {
        //TODO: complete this functionality for default list editor
        console.debug('update item');
    };
    EditableListEditorComponent.prototype.createFormGroup = function () {
        return new FormGroup({});
    };
    EditableListEditorComponent.prototype.updateFormGroup = function () {
        //TODO: complete this functionality for default list editor
        console.debug('update from group');
    };
    return EditableListEditorComponent;
}(CustomListActorBase));
export { EditableListEditorComponent };
