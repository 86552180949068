import { IWcCodeDefinitionDto, WcCodeDefinition } from './wc-code-definition';
import { IWcMedicalTreatmentDefinitionDto, WcMedicalTreatmentDefinition } from './wc-medical-treatment-definition';
import { IWcReportStatusDefinitionDto, WcReportStatusDefinition } from './wc-report-status-definition';
import { IUserProfileDefinition, UserProfileDefinition, Employee } from '../../../organization/models/index';

export interface IWcInjuryReportDto {
    //witness details
    witnessName: string;
    witnessEmployee: number;
    isWitnessEmployee: boolean;
    witnessPhone: string;
    witnessAddress: string;

    //injury details
    injuryType: IWcCodeDefinitionDto;
    injuryLocation: IWcCodeDefinitionDto;
    hasPriorInjury: boolean;
    exposedFluidToAnother: boolean;
    lossConsciousness: boolean;
    medicalTreatment: IWcMedicalTreatmentDefinitionDto;
    exactLocation: IWcCodeDefinitionDto;
    whatDoingDuringInjury: string;
    howInjuryOccur: string;
    primaryCause: string;
    usedProperSafety: boolean;
    equipmentCode: IWcCodeDefinitionDto;
    equipmentText: string;
    reportStatus: IWcReportStatusDefinitionDto;
    medicalCost: number;
    indemnityCost: number;
    otherTreatmentDescription: string;

    //signatures details
    employeeSignDate: string;
    supervisorSignDate: string;
    exDirectorSignDate: string;
    user: IUserProfileDefinition;
    isFormCompletedByEmployee: boolean;
    whoCompletedForm: string;
    hasCopyForm: boolean;
    carrierName: string;

    //comments
    employeeComment: string;
    supervisorComment: string;
    exDirectorComment: string;
    userComment: string;

    //report category
    isOSHARecordable: boolean;
    isWorkersCompCase: boolean;
}

export class WcInjuryReport {
    //witness details
    public witnessName: string;
    public witnessEmployee: number;
    public witnessEmployeeObj: Employee;
    public isWitnessEmployee: boolean;
    public witnessPhone: string;
    public witnessAddress: string;

    //injury details
    public injuryType: WcCodeDefinition;
    public injuryLocation: WcCodeDefinition;
    public hasPriorInjury: boolean;
    public exposedFluidToAnother: boolean;
    public lossConsciousness: boolean;
    public medicalTreatment: WcMedicalTreatmentDefinition;
    public exactLocation: WcCodeDefinition;
    public whatDoingDuringInjury: string;
    public howInjuryOccur: string;
    public primaryCause: string;
    public usedProperSafety: boolean;
    public equipmentCode: WcCodeDefinition;
    public equipmentText: string;
    public reportStatus: WcReportStatusDefinition;
    public medicalCost: number;
    public indemnityCost: number;
    public otherTreatmentDescription: string;

    //signatures details
    public employeeSignDate: Date;
    public supervisorSignDate: Date;
    public exDirectorSignDate: Date;
    public user: UserProfileDefinition;
    public isFormCompletedByEmployee: boolean;
    public whoCompletedForm: string;
    public hasCopyForm: boolean;
    public carrierName: string;

    public hasEmployeeSign: boolean;
    public hasSupervisorSign: boolean;
    public hasDirectorSign: boolean;

    //comments
    public employeeComment: string;
    public supervisorComment: string;
    public exDirectorComment: string;
    public userComment: string;

    //report category
    public isOSHARecordable: boolean;
    public isWorkersCompCase: boolean;
}
