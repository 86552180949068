import * as _ from 'lodash';
import { PayRuleDefinition } from '../../../organization/models/index';

export interface ITimecardsEarningPayRule {
  name: string; //or use directly IPayRuleDefinition
  payRate: number;
  hours: number;
  moneyAmount: number;
  order: number;
}

export class TimecardsEarningPayRule {
  public name: string;
  public payRule: PayRuleDefinition;
  public payRate: number;
  public hours: number;
  public moneyAmount: number;
  public order: number;
  public get isMoneyAmountRule(): boolean {
    return _.isNumber(this.moneyAmount) && this.moneyAmount !== 0;
  }
  public get value(): number {
    if (this.isMoneyAmountRule) {
      return this.moneyAmount;
    }
    return this.hours;
  }
}
