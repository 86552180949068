<div class="headless-modal-content">
    <div class="modal-body">
        <slx-staffing-predictor-matrix [staffingPointsTables]="staffingPointsTables" [organizationInfo]="organizationInfo" [five_star_v2_enabled]="five_star_v2_enabled"></slx-staffing-predictor-matrix>
    </div>
    <div class="modal-footer center-align">
        <button type="button" (click)="onClose()"
            class="btn theme-button-apply bolder-btn-text">
            <span class="slx-button__text">OK</span>
        </button>
    </div>
</div>
