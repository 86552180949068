<div class="employee-info">
  <div class="name-row">
    <a  class="employee-icon" [employeeSectionLink]="employee?.id">
          <img class="img" [employeeThumbinalSrc]="employee?.id" alt="Employee Photo" />
    </a>
    <div>
        <a [employeeSectionLink]="employee?.id" class="employee-name-title-text">
          {{employee?.name}}
        </a>
    </div>
  </div>
  <div class="info-row">
    <span class="info-label">Position: <span>{{ model?.primaryPosition?.name }}</span> </span>
    <span class="info-label">Emp Id: <span>{{ employee?.id }}</span></span>
    <span class="info-label">Payroll No: <span>{{ employee?.payrollNumber }}</span></span>
    <span class="info-label mobile-hidden">Exempt Ind: <span>{{ model?.exemptStatus?.name }}</span></span>
    <span class="info-label">Pay Policy: <span>{{ model?.payPolicy?.name }}</span></span>
    <span class="info-label mobile-hidden">Shift Diff Policy: <span>{{ model?.shiftDifferentialPolicy?.name }}</span></span>
    <span class="info-label">Emp type: <span>{{ employee?.employeeType?.name }}</span></span>
    <span class="info-label mobile-hidden">Hire Date: <span>{{ employee?.dateHired | amDateFormat: appConfig.dateFormat }}</span></span>
  </div>
  <div class="messages-container">
      <slx-single-message [isActive]="!model?.canEditTimecard && model?.isLocked" level="warning">
          <div class="slx-single-message__message">Timecard is readonly.
            <span class="slx-single-message__description">Only unlocked timecards can be edited!</span>
          </div>
     </slx-single-message>
      <slx-single-message [isActive]="model?.isPayrollCycleSubmitted" level="warning">
          <div class="slx-single-message__message">Timecard is readonly.
            <span class="slx-single-message__description">Payroll has been submitted!</span>
          </div>
     </slx-single-message>

      <slx-single-message [isActive]="!model?.canEditTimecard && model?.isApproved" level="warning">
          <div class="slx-single-message__message">Timecard is readonly.
            <span class="slx-single-message__description">Only unapproved timecards can be edited!</span>
          </div>
     </slx-single-message>
     <slx-single-message [isActive]="!model?.canEditTimecard && !model?.canEdit" level="warning">
        <div class="slx-single-message__message">Timecard is readonly.
          <span class="slx-single-message__description">You have no rights!</span>
        </div>
    </slx-single-message>
    <slx-single-message [isActive]="!model?.canEditTimecard && model?.afterTermination" level="warning">
        <div class="slx-single-message__message">Timecard is readonly.
          <span class="slx-single-message__description" *ngIf="!model?.afterTransfer">You can not edit terminated employee timecard!</span>
          <span class="slx-single-message__description" *ngIf="model?.afterTransfer">You can not edit transferred employee timecard!</span>
        </div>
    </slx-single-message>
    <slx-single-message [isActive]="!model?.canEditTimecard && model?.beforeHire" level="warning">
        <div class="slx-single-message__message">Timecard is readonly.
          <span class="slx-single-message__description">You can not edit timecard before employee was hired!</span>
        </div>
    </slx-single-message>
    <slx-single-message [isActive]="!model?.canEditTimecard && model?.isOutOfPaycycle" level="warning">
        <div class="slx-single-message__message">Timecard is readonly.
          <span class="slx-single-message__description">You can not edit timecard since Payroll Cycle has not been generated!</span>
        </div>
    </slx-single-message>
    <slx-single-message [isActive]="model?.hasMissingPunchesAlert" level="warning">
      <div class="slx-single-message__message">Missing Punches exist on some of the Daily Timecards below.
        <span class="slx-single-message__description">Only timecards without missing punches can be approved!</span>
      </div>
    </slx-single-message>

    <slx-single-message [isActive]="model?.unApprovedPayCodesCount>0" level="warning">
      <a (click)="navigateToUnapprovedPaycodes()" class="slx-single-message__message">Timecard has unapproved Pay Codes
        <span class="slx-single-message__description">Only timecards with approved Pay Codes can be approved!</span>
      </a>
    </slx-single-message>

    <slx-single-message [isActive]="model?.unApprovedOTCount>0" level="warning">
        <a (click)="navigateToUnapprovedOvertimes()" class="slx-single-message__message">Timecard has unapproved overtimes
          <span class="slx-single-message__description">Only timecards with approved overtimes can be approved!</span>
        </a>
    </slx-single-message>
    <slx-single-message [isActive]="!model?.canModifyOwnTimecard && !model?.isApproved" level="warning">
      <div class="slx-single-message__message">Timecard is readonly.
        <span class="slx-single-message__description">You are not authorized to approve your own timecard.</span>
      </div>
    </slx-single-message>
    <slx-single-message [isActive]="!model?.canModifyOwnTimecard && model?.isApproved" level="warning">
      <div class="slx-single-message__message">Timecard is readonly.
        <span class="slx-single-message__description">You are not authorized to unapprove your own timecard.</span>
      </div>
    </slx-single-message>
    <slx-single-message [isActive]="!model?.canEditOwnTimecard" level="warning">
      <div class="slx-single-message__message">Timecard is readonly.
        <span class="slx-single-message__description">You are not authorized to edit your own timecard.</span>
      </div>
    </slx-single-message>
    <slx-single-message [isActive]="model?.hasLicenseRestriction" level="warning">
      <div class="slx-single-message__message">License missing or Expired.Timecard cannot be edited
        <span class="slx-single-message__description"></span>
      </div>
    </slx-single-message>

  </div>
  <span class="info-horizontal-line"></span>
</div>
