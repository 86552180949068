export * from './individual-timecards/individual-timecards.component';
export * from './individual-timecards-grid/individual-timecards-grid.component';
export * from './individual-timecards-detail-grid/individual-timecards-detail-grid.component';
export * from './individual-timecards-flat-grid/individual-timecards-flat-grid.component';
export * from './individual-timecards-grid-tooltip-header/individual-timecards-grid-tooltip-header.component';

import { IndividualTimecardsComponent } from './individual-timecards/individual-timecards.component';
import { IndividualTimecardsGridComponent } from './individual-timecards-grid/individual-timecards-grid.component';
import { IndividualTimecardsDetailGridComponent } from './individual-timecards-detail-grid/individual-timecards-detail-grid.component';
import { IndividualTimecardsFlatGridComponent } from './individual-timecards-flat-grid/individual-timecards-flat-grid.component';
import { IndividualTimecardGridTooltipHeaderComponent } from './individual-timecards-grid-tooltip-header/individual-timecards-grid-tooltip-header.component';

export const individualTimecardsComponents: any = [
  IndividualTimecardsComponent,
  IndividualTimecardsGridComponent,
  IndividualTimecardsDetailGridComponent,
  IndividualTimecardsFlatGridComponent,
  IndividualTimecardGridTooltipHeaderComponent
];
