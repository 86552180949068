import { IMasterScheduleRow, IMasterScheduleEntryRow, IMasterScheduleTotalRow, IMasterScheduleSubtotalRow } from './master-schedule-row';
import { Subtotal } from './subtotal';
import { Total } from './total';


export class MasterScheduleData {
  public employees: IMasterScheduleEntryRow[];
  public totals: IMasterScheduleTotalRow[];
  public subtotals: IMasterScheduleSubtotalRow[];
}

export class EmployeeGridData {
  public rows: IMasterScheduleRow[];
  public totals: IMasterScheduleRow[];
  public subtotals: IMasterScheduleSubtotalRow[];
  public isPosted: boolean;
}
