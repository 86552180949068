import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { INoRotationEmployeeList, IScheduleRotationTemplate } from '../../../../../scheduler/models/schedule-rotation/schedule-rotation-template.model';
import { ScheduleRotationsManagementService } from '../../../../services/scheduled-rotation-template/schedule-rotations-management.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'slx-add-emp-position-grid',
  templateUrl: './add-emp-position-grid.component.html',
  styleUrls: ['./add-emp-position-grid.component.scss']
})
export class AddEmpPositionGridComponent implements OnInit {
  @Input() employeeData: IScheduleRotationTemplate;
  @Input() employeeList: IScheduleRotationTemplate[];
  @Output() onSaveEmp = new EventEmitter<{ emp: IScheduleRotationTemplate, empId: number }>();
  @Output() onSavePos = new EventEmitter<any>();
  @Output() onDeleteRotations = new EventEmitter<IScheduleRotationTemplate>();
  @Input() noRotationEmployeeList: INoRotationEmployeeList[];

  public isAddPerson: boolean = false;
  public isAddPosition: boolean = false;
  public selectedPerson: any;
  public selectedPosition: any;
  public isProceed: boolean = false;
  public positionItems: any[];
  public assignedEmployeeAndEmptySlotEmployee: any[] = [];
  public actualNoRotationEmpData: any;
  public backToListTriggerSubscription: Subscription;
  public updateNoRotationEmpDetailsSubscription: Subscription;

  constructor(public manService: ScheduleRotationsManagementService) {
    this.backToListTriggerSubscription = this.manService.backToListTrigger$.subscribe((isTriggered: boolean) => {
      if(isTriggered) {
        this.backToList();
      }
    });

    this.updateNoRotationEmpDetailsSubscription = this.manService.noRotationEmployeeList$.subscribe((data: INoRotationEmployeeList[]) => {
      this.noRotationEmployeeList = data;
    });
   }

  ngOnInit() {
    this.positionItems = this.manService.positionLookup.items;
    this.actualNoRotationEmpData = this.noRotationEmployeeList;
    if (!this.isPositionNotExists) {
      this.selectedPosition = this.positionItems.find(x => x.id == this.employeeData.jobCode);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.noRotationsEmployees && changes.noRotationsEmployees.currentValue) {
      this.positionItems = this.manService.positionLookup.items;
      this.actualNoRotationEmpData = this.noRotationsEmployees;
      if (!this.isPositionNotExists) {
        this.selectedPosition = this.positionItems.find(x => x.id == this.employeeData.jobCode);
      }
    }
  }

  get isEmployeeNotExists() {
    return this.employeeData.employeeId === 0;
  }

  get isPositionNotExists() {
    return this.employeeData.jobCode === 0;
  }

  get isRotationSaved(){
    return this.employeeData.slotId !== 0;
  }

  get isShiftsAssigned(){
    return this.employeeData.scheduleDays.some(x=> x.shifts.length>0);
  }

  get isEditMode(){
    return this.employeeData.scheduleDays.some(x=> x.shifts.filter(x=> x.isView === false).length > 0);
  }

  public addPersonOrPosition(ind: boolean) {
    if(this.manService.isEmployeeAssigeeMode) return;
    this.manService.isEmployeeAssigeeMode = true;
    let filteredEmpIds = _.map(this.manService.filterEmployeeData, (x) => x.employeeId);
    this.noRotationEmployeeList = _.filter(this.noRotationEmployeeList, (x) => filteredEmpIds.includes(x.employeeId));
    if(this.manService.assignEmployeeToEmptySlot && this.manService.assignEmployeeToEmptySlot.length > 0) {
      let empIds = this.manService.assignEmployeeToEmptySlot.map(x => x.employeeId);
      this.noRotationEmployeeList = _.filter(this.noRotationEmployeeList, (item) => !empIds.includes(item.employeeId));
    }
    this.updateNoRotationEmployees = this.noRotationEmployeeList;
    if (!this.isPositionNotExists) {
      this.selectedPosition = this.positionItems.find(x => x.id == this.employeeData.jobCode);
    }
    this.isProceed = true;
    this.isAddPerson = ind ? true : false;
    this.isAddPosition = !ind ? true : false;
    this.noRotationEmployeeList = this.actualNoRotationEmpData;
    if (this.selectedPosition) {
      this.noRotationEmployeeList = this.selectedPositionEmpDetails(this.selectedPosition);
    }
  }

  public saveData() {
    this.manService.isEmployeeAssigeeMode = false;
    this.isProceed = true;
    if (this.isAddPerson && !this.isAddPosition) {
      if (this.selectedPerson) {
        this.onSaveEmp.emit({ emp: this.employeeData, empId: this.selectedPerson.employeeId });
      }
    }
    else if (!this.isAddPerson && this.isAddPosition) {
      if (this.selectedPosition) {
        this.employeeData.jobCode = this.selectedPosition.id;
        this.employeeData.positionName = this.selectedPosition.name;
        this.employeeData.isEmptySlot = true;
        this.employeeData.isPrimary = true;
        this.manService.assignedEmployeeAndEmptySlotEmployee.push(this.employeeData);
      }
    }
    this.backToList();
  }

  public backToList() {
    this.isAddPerson = false;
    this.isAddPosition = false;
    this.selectedPerson = null;
    this.selectedPosition = null;
    this.isProceed = false;
    this.manService.isEmployeeAssigeeMode = false;
  }

  private selectedPositionEmpDetails(selectedPosition: any) {
    let emptyRotationEmpDetails: any;
    if(this.manService.filterEmployeeData.length > 0) {
      let emptyRotationEmps = this.actualNoRotationEmpData.filter(x => x.jobCode === selectedPosition.id);
      let noRotationEmpIds = emptyRotationEmps.map(x => x.employeeId);
      emptyRotationEmpDetails = this.manService.filterEmployeeData.filter(x => noRotationEmpIds.includes(x.employeeId));
      if(this.manService.assignEmployeeToEmptySlot && this.manService.assignEmployeeToEmptySlot.length > 0) {
        let empIds = this.manService.assignEmployeeToEmptySlot.map(x => x.employeeId);
        emptyRotationEmpDetails = _.filter(emptyRotationEmpDetails, (item) => !empIds.includes(item.employeeId));
      }
      return emptyRotationEmpDetails;
    }
    else {
      emptyRotationEmpDetails = this.actualNoRotationEmpData.filter(x => x.jobCode === selectedPosition.id);
      return emptyRotationEmpDetails;
    }
  }

  removeSlotRotations(){
    this.onDeleteRotations.emit(this.employeeData);
  }

  public set updateNoRotationEmployees(value: any) {
    this.noRotationEmployeeList = value;
  }

  public get noRotationsEmployees() {
    return this.noRotationEmployeeList;
  }

}
