import { Component, Input, OnInit } from '@angular/core';
import { DayOfWeek } from './../../../../../../app/common';
import { EmployeeRotation, EmployeeRotationRecord, WeeklyRotation } from './../../../../../../app/employee/employee-sections/models';
import { appConfig, IApplicationConfig } from './../../../../../../app/app.config';
import * as moment from 'moment';
import * as _ from 'lodash';
export const maximumWeeklyRotationsShown: number = 3;

@Component({
  selector: 'slx-srt-view-individual-schedule',
  templateUrl: './srt-view-individual-schedule.component.html',
  styleUrls: ['./srt-view-individual-schedule.component.scss']
})
export class SrtViewIndividualScheduleComponent implements OnInit {

  public selectedRotationValue: EmployeeRotation;
  public appConfig: IApplicationConfig;
  public get isExpandButtonVisible(): boolean {
    return this.selectedRotationValue
      && this.selectedRotationValue.weeklyRotations
      && this.selectedRotationValue.weeklyRotations.length > maximumWeeklyRotationsShown;
  }
  public isRotationsExpanded: boolean;

  @Input()
  public get invalidRotations(): EmployeeRotationRecord[] {
    let invalidRotations: EmployeeRotationRecord[] = [];
    if (this.selectedRotationValue !== null && this.selectedRotationValue !== undefined) {
      _.forEach(this.selectedRotationValue.weeklyRotations, (w: WeeklyRotation) => {
        _.forEach(w.dailyRecords, (d: EmployeeRotationRecord) => {
          if (!d.isValid || d.showWarning) {
            invalidRotations.push(d);
          }
        });
      });
    }
    return invalidRotations;
  }

  @Input() public weekDaysRibbon: DayOfWeek[];
  @Input() public set selectedRotation(value: EmployeeRotation) {
    this.selectedRotationValue = value;
  }

  constructor() {

  }

  ngOnInit() {
    this.appConfig = appConfig;
  }

  public getWeekStartDate(weeklyRotation: WeeklyRotation, dateIndex: number): number {
    if (_.isObjectLike(weeklyRotation)) {
      let dayNumber = weeklyRotation.weekStartDate.getDate();
      let monthEnd = +moment(weeklyRotation.weekStartDate).endOf('month').format('D');
      if (dayNumber + dateIndex <= monthEnd) {
        return weeklyRotation.weekStartDate.getDate() + dateIndex;
      } else {
        return weeklyRotation.weekStartDate.getDate() + dateIndex - monthEnd;
      }
    }
  }

}
