<div class="headless-modal-content">
  <div class="modal-header">
    <div class="txt-pad10">
      <span class="pull-left header-cls">{{headerTitle}}</span>
      <div class="pull-right top-close" (click)="onCancel()"><span><i class="fa fa-times" aria-hidden="true"></i></span></div>
    </div>
  </div>
  <slx-spinner [show]="isLoading">
    <div class="modal-body">
      <form class="form-horizontal" #employerForm="ngForm">

        <div class="col-lg-12 padl0">
          <div class="col-lg-4">
            <slx-input-decorator>
              <input slx-input type="text"
                     name="legalName"
                     placeholder="Legal Name"
                     [required]="true"
                     required
                     maxlength="255"
                     [(ngModel)]="employer.legalName" />
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>
          <div class="col-lg-4">
            <slx-input-decorator>
              <input slx-input type="text"
                    #fein="ngModel"
                     name="federalEIN"
                     [required]="true"
                     required
                     maxlength="10"
                     [pattern]="commonConfig.feinPattern"
                     placeholder="Federal EIN"
                     [(ngModel)]="employer.federalEIN" 
                     (ngModelChange)="checkDuplicateFein($event, fein)"/>
              <span errorMessage for="required"></span>
              <span errorMessage for="pattern">Must match the [######### or ##-#######] pattern</span>
              <span errorMessage for="duplicateFein">FEIN already exists</span>
            </slx-input-decorator>
          </div>
          <div class="col-lg-4 si-align">
            <span class="selfInsured-align">Self Insured
              <slx-checkbox-button 
                  class="si-checkbox"
                  [(ngModel)]="employer.isSelfInsured" 
                  name="isSelfInsured"
              ></slx-checkbox-button>
            </span>
          </div>
        </div>
        <div class="col-lg-12 padl0">
          <div class="col-lg-4">
            <slx-input-decorator>
              <input slx-input type="text"
                     name="tradeName"
                     maxlength="255"
                     placeholder="Trade Name"
                     [(ngModel)]="employer.tradeName" />
            </slx-input-decorator>
          </div>
          <div class="col-lg-4">
            <slx-input-decorator>
              <input slx-input type="text"
                     name="acaTccCode"
                     placeholder="ACA 1095 TCC Code"
                     maxlength="255"
                     [(ngModel)]="employer.acA1095TCC_Code" />
            </slx-input-decorator>
          </div>
          <div class="col-lg-4">
            <slx-input-decorator>
              <slx-dropdown-input slx-input
                                  [options]="aleGroupList"
                                  titleField="groupName"
                                  valueField="groupId"
                                  name="aleGroupName"
                                  placeholder="ALE Group"
                                  [(ngModel)]="employer.aleGroupModel">
              </slx-dropdown-input>
            </slx-input-decorator>
          </div>
        </div>
        <div class="col-lg-6 address-box">
          <div class="col-lg-12">
            <label class="header-f18">Legal Address</label>
          </div>
          <div>
            <div class="col-lg-12">
              <slx-input-decorator>
                <input slx-input type="text"
                       name="laddress1"
                       [required]="true"
                       required
                       placeholder="Address 1"
                       maxlength="255"
                       [(ngModel)]="employer.legalAddress1" />
                <span errorMessage for="required"></span>
              </slx-input-decorator>
            </div>
          </div>
          <div>
            <div class="col-lg-12">
              <slx-input-decorator>
                <input slx-input type="text"
                       name="laddress2"
                       placeholder="Address 2"
                       maxlength="255"
                       [(ngModel)]="employer.legalAddress2" />
              </slx-input-decorator>
            </div>
          </div>
          <div>
            <div class="col-lg-5">
              <slx-input-decorator>
                <input slx-input type="text"
                       name="lcity"
                       [required]="true"
                       required
                       placeholder="City"
                       maxlength="50"
                       [(ngModel)]="employer.legalCity" />
                <span errorMessage for="required"></span>
              </slx-input-decorator>
            </div>
            <div class="col-lg-3 padl0">
              <slx-input-decorator>
                <slx-dropdown-input slx-input
                                    [lookup]="stateLookup"
                                    name="lstate"
                                    [required]="true"
                                    required
                                    placeholder="State"
                                    [(ngModel)]="employer.legalStateModel">
                </slx-dropdown-input>
                <span errorMessage for="required"></span>
              </slx-input-decorator>
            </div>
            <div class="col-lg-4 padl0">
              <slx-input-decorator>
                <slx-zip-input slx-input
                               name="lzip"
                               [required]="true"
                               required
                               placeholder="Zip"
                               maxlength="20"
                               [(ngModel)]="employer.legalZip">
                </slx-zip-input>
                <span errorMessage for="required"></span>
                <span errorMessage for="min">Min length is 5</span>
                <span errorMessage for="max">Max length is 10</span>
              </slx-input-decorator>
            </div>
          </div>
        </div>
        <div class="col-lg-6 address-box">
          <div class="col-lg-12">
            <label class="pull-left header-f18">Business Address</label>
            <span class="pull-right rb-CheckBox">
              <input type="checkbox" (change)="onChangeSameAsLegal($event.target.checked)"
                     [checked]="employer.isSameAsLegalAddress"
                     name="sameAsLegal" />
              Same as Legal
            </span>
          </div>
          <div [ngClass]="{'disabled-sec' : employer.isSameAsLegalAddress}">
            <div class="col-lg-12">
              <slx-input-decorator>
                <input slx-input type="text"
                       name="baddress1"
                       placeholder="Address 1"
                       maxlength="255"
                       [disabled]=""
                       [(ngModel)]="employer.isSameAsLegalAddress? employer.legalAddress1:employer.businessAddress1" />
              </slx-input-decorator>
            </div>

            <div class="col-lg-12">
              <slx-input-decorator>
                <input slx-input type="text"
                       name="baddress2"
                       placeholder="Address 2"
                       maxlength="255"
                       [(ngModel)]="employer.isSameAsLegalAddress?employer.legalAddress2 :employer.businessAddress2" />
              </slx-input-decorator>
            </div>

            <div class="col-lg-5">
              <slx-input-decorator>
                <input slx-input type="text"
                       name="bcity"
                       maxlength="50"
                       placeholder="City"
                       [(ngModel)]="employer.isSameAsLegalAddress? employer.legalCity :employer.businessCity" />
                <span errorMessage for="required"></span>
              </slx-input-decorator>
            </div>
            <div class="col-lg-3 padl0">
              <slx-input-decorator>
                <slx-dropdown-input slx-input
                                    [options]="stateLookup?.items"
                                    name="bstate"
                                    titleField="name"
                                    valueField="name"
                                    placeholder="State"
                                    [(ngModel)]="employer.isSameAsLegalAddress? employer.legalStateModel : employer.businessStateModel">
                </slx-dropdown-input>
                <span errorMessage for="required"></span>
              </slx-input-decorator>
            </div>
            <div class="col-lg-4 padl0">
              <slx-input-decorator>
                <slx-zip-input slx-input
                               name="bzip"
                               placeholder="Zip"
                               maxlength="20"
                               [(ngModel)]="employer.isSameAsLegalAddress?employer.legalZip : employer.businessZip">
                </slx-zip-input>
                <span errorMessage for="required"></span>
                <span errorMessage for="min">Min length is 5</span>
                <span errorMessage for="max">Max length is 10</span>
              </slx-input-decorator>
            </div>
          </div>
        </div>

        <div class="col-lg-12 primary-contact-box">
          <div class="col-lg-12"><label class="header-f18">Primary Contact</label></div>

          <div class="col-lg-4">
            <slx-input-decorator>
              <input slx-input type="text"
                     name="firstName"
                     placeholder="First Name"
                     [required]="true"
                     required
                     maxlength="50"
                     [(ngModel)]="employer.firstName" />
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>
          <div class="col-lg-4">
            <slx-input-decorator>
              <input slx-input type="text"
                     name="lastName"
                     placeholder="Last Name"
                     [required]="true"
                     required
                     maxlength="50"
                     [(ngModel)]="employer.lastName" />
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>
          <div class="clear">
            <div class="col-lg-4">
              <slx-input-decorator>
                <slx-phone-input slx-input
                                 phone
                                 name="phone"
                                 [required]="true"
                                 required
                                 placeholder="Phone"
                                 maxlength="50"
                                 [(ngModel)]="employer.phone">
                </slx-phone-input>
                <span errorMessage for="required"></span>
                <span errorMessage for="phone"></span>
              </slx-input-decorator>
            </div>
            <div class="col-lg-4">
              <slx-input-decorator>
                <slx-phone-input slx-input
                                 phone
                                 name="fax"
                                 placeholder="Fax"
                                 maxlength="50"
                                 [(ngModel)]="employer.fax">
                </slx-phone-input>
                <span errorMessage for="required"></span>
                <span errorMessage for="fax"></span>
              </slx-input-decorator>
            </div>
            <div class="col-lg-4">
              <slx-input-decorator>
                <slx-email-input slx-input
                                 slx-email
                                 name="email"
                                 maxlength="50"
                                 placeholder="Email"
                                 [(ngModel)]="employer.email">
                </slx-email-input>
                <span errorMessage for="required"></span>
                <span errorMessage for="email"></span>
              </slx-input-decorator>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer center-align">
      <div class="btn-close" (click)="onCancel()"><span><i class="fa fa-times marl5" aria-hidden="true"></i>  Cancel</span></div>
      <div class="btn-save">
        <button class="savecls" (click)="onSave()" [disabled]="!valid"><i class="fas fa-save marl5" aria-hidden="true"></i>  Save</button>
      </div>

    </div>
  </slx-spinner>
</div>
