import * as tslib_1 from "tslib";
import { mutableSelect } from './../../../../app/core/decorators';
import { Observable } from "rxjs";
import { FileUploadsApiService } from "./file-uploads-api.service";
import { KendoGridStateHelper } from './../../../../app/common';
import { process } from '@progress/kendo-data-query';
import { DownloadDataService } from "./download-data.service";
var FileUploadsManagementService = /** @class */ (function () {
    function FileUploadsManagementService(fileApiService, downloadService) {
        var _this = this;
        this.fileApiService = fileApiService;
        this.downloadService = downloadService;
        this.uploadedFileData = [];
        this.skip = 0;
        this.pageSize = 17;
        this.sort = [];
        this.isLoading = false;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (data) {
            _this.isLoading = true;
            _this.orgLevelId = data.id;
            _this.getImportedFiles();
        });
        Observable.interval(1 * 60 * 1000)
            .timeInterval()
            .subscribe(function () {
            _this.isLoading = true;
            _this.getImportedFiles();
        });
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.skip = 0;
        this.gridState.state.take = this.pageSize;
    }
    FileUploadsManagementService.prototype.init = function () {
        this.isLoading = true;
        this.getImportedFiles();
    };
    FileUploadsManagementService.prototype.getImportedFiles = function () {
        var _this = this;
        this.fileApiService.getImportedFiles(this.orgLevelId).then(function (data) {
            _this.uploadedFileData = data;
            _this.refreshGrid();
            _this.isLoading = false;
        });
    };
    FileUploadsManagementService.prototype.setSort = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    FileUploadsManagementService.prototype.pageChange = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    FileUploadsManagementService.prototype.filterChange = function (event) {
        this.gridState.state.filter = event;
        this.refreshGrid();
    };
    FileUploadsManagementService.prototype.sortChangeHandler = function (sort) {
        this.setSort(sort);
    };
    FileUploadsManagementService.prototype.pageChangeHandler = function (event) {
        this.pageChange(event);
    };
    FileUploadsManagementService.prototype.filterChangeHandler = function (event) {
        this.filterChange(event);
    };
    FileUploadsManagementService.prototype.refreshGrid = function () {
        if (!this.uploadedFileData) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.uploadedFileData, this.gridState.state);
    };
    FileUploadsManagementService.prototype.downloadImportedFile = function (importId) {
        var _this = this;
        this.isLoading = true;
        this.fileApiService.downloadImportedFile(importId).then(function (data) {
            _this.downloadService.excelDownloadDataProcess(data, importId);
            _this.isLoading = false;
        });
    };
    tslib_1.__decorate([
        mutableSelect(),
        tslib_1.__metadata("design:type", Observable)
    ], FileUploadsManagementService.prototype, "orgLevel$", void 0);
    return FileUploadsManagementService;
}());
export { FileUploadsManagementService };
