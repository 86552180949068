import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, ViewChild, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { appConfig, IApplicationConfig } from '../../../../../../app.config';

import { EmployeeWarningSections, EmployeeWarningExtended, EmployeeWarningSectionActions, EmployeeWarningAction } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-warnings-actions',
  templateUrl: 'employee-sections-warnings-actions.component.html',
  styleUrls: ['employee-sections-warnings-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsWarningsActionsComponent implements OnInit, OnDestroy {
  public actions: EmployeeWarningSectionActions;
  public actionsList: Array<EmployeeWarningAction> = [];
  public numberOfDays: string;
  public commentForNumberOfDays: string;
  public actionDate: Date;
  public approveDate: Date;
  public actionTitle: string;
  public approvedBy: string;
  public employeeSignature: string;
  public creatorSignature: string;
  public employeeTitle: string;
  public witnessSignature: string;
  public supervisorSignature: string;
  public employeeSignDate: Date;
  public witnessSignDate: Date;
  public issuerSignDate: Date;
  public supervisorSignDate: Date;

  public min = 0;
  public max = 99999999;
  public step = 1;
  public format = 'n0';

  @unsubscribeAll()
  public subscriptions: StringMap<Subscription> = {};
  @ViewChild('form', { static: true })
  public form: NgForm;
  public appConfig: IApplicationConfig = appConfig;

  constructor(
    private readonly manService: EmployeeSectionWarningsManagementService,
    private readonly changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.actions = this.manService.getActionsSection();

    this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(warning => {
      this.assignActions(warning);
      this.assignActionsData();
      this.updateView();
    });

    this.subscriptions.statusChanges = this.form.statusChanges.subscribe(() => {
      this.manService.changeSectionValidity(EmployeeWarningSections.StandardActions, this.form.valid);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onChangeAction(act: EmployeeWarningAction): void {
    const action = _.find(this.actions.data.actions, { id: act.id });
    if (_.isObjectLike(action)) {
      action.selected = act.selected;
      this.manService.markWarningAsEdited(true);
    }
  }

  public onChangeNote(act: EmployeeWarningAction): void {
    const action = _.find(this.actions.data.actions, { id: act.id });
    if (_.isObjectLike(action)) {
      action.notes = act.notes;
      this.manService.markWarningAsEdited(true);
    }
  }

  public onChangeApprovedBy(): void {
    this.actions.data.approvedBy = this.approvedBy;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeNumberOfDays(): void {
    this.actions.data.numberOfDays = this.numberOfDays;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeComment(): void {
    this.actions.data.commentForNumberOfDays = this.commentForNumberOfDays;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeActionDate(): void {
    this.actions.data.actionDate = this.actionDate;
    this.manService.markWarningAsEdited(true);
  }
  public onChangeApproveDate(): void {
    this.actions.data.approveDate = this.approveDate;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeActionTitle(): void {
    this.actions.data.actionTitle = this.actionTitle;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeEmployeeSignature(): void {
    this.actions.data.employeeSignature = this.employeeSignature;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeEmployeeTitle(): void {
    this.actions.data.employeeTitle = this.employeeTitle;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeCreatorSignature(): void {
    this.actions.data.creatorSignature = this.creatorSignature;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeWitnessSignature(): void {
    this.actions.data.witnessSignature = this.witnessSignature;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeSupervisorSignature(): void {
    this.actions.data.supervisorSignature = this.supervisorSignature;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeEmployeeSignDate(): void {
    this.actions.data.employeeSignDate = this.employeeSignDate;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeWitnessSignDate(): void {
    this.actions.data.witnessSignDate = this.witnessSignDate;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeIssuerSignDate(): void {
    this.actions.data.issuerSignDate = this.issuerSignDate;
    this.manService.markWarningAsEdited(true);
  }

  public onChangeSupervisorSignDate(): void {
    this.actions.data.supervisorSignDate = this.supervisorSignDate;
    this.manService.markWarningAsEdited(true);
  }

  private updateView(): void {
    Observable.of(true)
      .delay(200)
      .subscribe(() => this.changeDetector.detectChanges());
  }

  private assignActions(warning: EmployeeWarningExtended): void {
    const actions = _.find(warning.sections, s => s.isStandardActions);
    if (_.isObjectLike(actions) && _.isObjectLike(actions.data)) {
      this.actions = actions as EmployeeWarningSectionActions;
    }
  }

  private assignActionsData(): void {
    this.actionsList = _.cloneDeep(this.actions.data.actions);
    this.numberOfDays = this.actions.data.numberOfDays;
    this.commentForNumberOfDays = this.actions.data.commentForNumberOfDays;
    this.actionDate = this.actions.data.actionDate;
    this.approveDate = this.actions.data.approveDate;
    this.actionTitle = this.actions.data.actionTitle;
    this.approvedBy = this.actions.data.approvedBy;
    this.employeeSignature = this.actions.data.employeeSignature;
    this.employeeTitle = this.actions.data.employeeTitle;
    this.creatorSignature = this.actions.data.creatorSignature;
    this.witnessSignature = this.actions.data.witnessSignature;
    this.supervisorSignature = this.actions.data.supervisorSignature;
    this.employeeSignDate = this.actions.data.employeeSignDate;
    this.witnessSignDate = this.actions.data.witnessSignDate;
    this.issuerSignDate = this.actions.data.issuerSignDate;
    this.supervisorSignDate = this.actions.data.supervisorSignDate;
    
  }
}
