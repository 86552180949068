import * as _ from "lodash";
import { CopyAccrualPolicyComponent } from "./copy-accrual-policy/copy-accrual-policy.component";
import { AccrualOrgLevels, AccrualPolicyDetails, CopyAccrualPolicy, CopyAccrualPolicyResult, CopyAccrualResult, SaveCopyAccrualPolicy } from "../../models/accruals-policy/copy-accruals-policy";
import { ModalService } from "./../../../../../app/common";
import { Subject } from "rxjs";
import { CopiedPolicyResultDialogComponent } from "./copied-policy-result-dialog/copied-policy-result-dialog.component";
import { AccrualsPolicyApiService, AccrualsPolicyMapService } from "../../services";


export class AccrualPolicyDialogHelper {

    public currentOrgAccrualData: AccrualPolicyDetails[] = [];
    public accrualOrgLevels: AccrualOrgLevels[] = [];
    public modalService: ModalService;
    public mapService: AccrualsPolicyMapService;
    public saveCopySelectedPolicies$ = new Subject<SaveCopyAccrualPolicy[]>();
    public apiService: AccrualsPolicyApiService;

    constructor(modalService: ModalService, mapService: AccrualsPolicyMapService, apiService: AccrualsPolicyApiService) {
        this.modalService = modalService;
        this.mapService = mapService;
        this.apiService = apiService;
    }

    public copyPolicy(data, orgLevelName, orgLevelDetails, orgLevelId) {
        if (this.currentOrgAccrualData.length === 0) {
            this.currentOrgAccrualData = this.mapService.accrualsDetails(data);
            this.accrualOrgLevels = orgLevelDetails;
        }
        if(this.accrualOrgLevels.length > 0 || this.currentOrgAccrualData.length > 0){
            this.accrualOrgLevels = [];
            this.currentOrgAccrualData = [];
            this.accrualOrgLevels = orgLevelDetails;
            this.currentOrgAccrualData = this.mapService.accrualsDetails(data);
        }
        let modalData: CopyAccrualPolicy = new CopyAccrualPolicy();
        modalData.header = `Accrual Policies for ${orgLevelName}`;
        modalData.currentPolicies = this.currentOrgAccrualData;
        modalData.orgLevels = this.accrualOrgLevels;
        CopyAccrualPolicyComponent.openDialog(modalData, this.modalService, (result: boolean, request: CopyAccrualPolicy) => {
            if (result) {
                let selectedPolicies = _.filter(request.currentPolicies, (policy) => policy.isChecked);
                let selectedOrgLevels = _.filter(request.orgLevels, (orgLevel) => orgLevel.isChecked);
                let data: SaveCopyAccrualPolicy = new SaveCopyAccrualPolicy();
                data.selectedAccrualPolicies = selectedPolicies;
                data.selectedOrgLevelIDs = selectedOrgLevels;
                this.apiService.postCopiedAccrualPolicies(orgLevelId, data).then((data) => {
                    if (data.length > 0) {
                        let res: CopyAccrualResult = new CopyAccrualResult();
                        res.header = `Accrual Policies for ${orgLevelName}`;
                        res.data = data;
                        this.getResultDialog(res);
                    }
                }).catch(() => {
                    this.getResultDialog(null);
                })
            };
        });
    }

    public getResultDialog(result: CopyAccrualResult) {
        CopiedPolicyResultDialogComponent.openDialog(result, this.modalService, (result: boolean) => {
            return;
        });
    }
}