<slx-spinner [show]="state.isLoading" novalidate>

    <form #form="ngForm" class="slx-high-box">
        <div class="hbox">
            <div class="vbox col-sm-6">
                <div>
                    <slx-input-decorator className="slx-form-group slx-no-border small-font slx-no-error-block slx-no-label">
                        <slx-radio-input slx-input name="sendToOne" fieldName="sendToOne" option="specific"
                            caption="Send to specific employee" [(ngModel)]="sendType">
                        </slx-radio-input>
                    </slx-input-decorator>
                </div>

                <div>
                    <slx-input-decorator className="slx-form-group slx-no-border small-font slx-no-error-block slx-no-label">
                        <slx-radio-input slx-input name="sendToAll" fieldName="sendToAll" option="ticker"
                        caption="Timeclock Ticker" [(ngModel)]="sendType">
                        </slx-radio-input>
                    </slx-input-decorator>
                </div>
            </div>
        </div>
        <div class="slx-high-box__body">
          <slx-specific-message *ngIf="sendType === 'specific'" [orgLevel]="orgLevel"></slx-specific-message>
          <slx-timeclock-message *ngIf="sendType !== 'specific'" [orgLevel]="orgLevel"></slx-timeclock-message>
        </div>
    </form>

</slx-spinner>
