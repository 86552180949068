import { BenefitDetailsState } from './benefit-details-state';

export interface IBenefitDetailsOption {
  id: number;
  optionId: number;

  type: string;
  code: string;
  spouse: boolean;
  children: number;
  cost: number;
  empContribution: number;
  erContribution: number;

  ageStart: number;
  ageEnd: number;
  annualContributionMin: number;
  annualContributionLimit: number;
  erAnnualContribution: number;

  maxContribution: number;
  suggestedContribution: number;
  maxMatchGrant: number;
  maxEmpContribution: number;
  limitAmount: number;
  compensationLimit: number;

  acaCategory: string;
}

export class BenefitDetailsOption extends BenefitDetailsState {
  public id: number;
  public optionId: number;

  public type: string;
  public code: string;
  public spouse: boolean;
  public children: number;
  public cost: number = 0.00;
  public empContribution: number;

  public ageStart: number;
  public ageEnd: number;
  public annualContributionMin: number;
  public annualContributionLimit: number;
  public erAnnualContribution: number;
  public erContribution: number;

  public maxContribution: number;
  public suggestedContribution: number;
  public maxMatchGrant: number;
  public maxEmpContribution: number;
  public limitAmount: number;
  public compensationLimit: number;

  public acaCategory: string;

  public get isDraft(): boolean {
    return !isFinite(this.id) || this.id < 0;
  }

  public get hasTypeAndCode(): boolean {
    return typeof(this.type) === 'string'
      && this.type.length > 0
      && typeof(this.code) === 'string'
      && this.code.length > 0;
  }
}
