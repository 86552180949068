import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-labeled-badge',
  templateUrl: 'labeled-badge.component.html',
  styleUrls: ['labeled-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledBadgeComponent {

  @Input()
  public icon: string;

  @Input()
  public label: string;

  @Input()
  public value: number;

  @Output()
  public onClick: EventEmitter<any> = new EventEmitter<any>();

  public onClickHandler(event: any): void {
    this.onClick.emit(event);
  }
}
