import * as moment from 'moment';
import { IShift, Shift, ILocationUnit, LocationUnit, IScheduleAbsence, ScheduleAbsence, TAAbsence, ITAAbsence, Position, IPosition } from '../../../organization/models/index';

export interface IDailyPunchesShift {
  shift: IShift;
  position: IPosition;
  unit: ILocationUnit;
  absence: IScheduleAbsence;
  duration: string;
  comment: string;
  taAbsence: ITAAbsence;
}

export class DailyPunchesShift {
  public shift: Shift;
  public position: Position;
  public unit: LocationUnit;
  public absence: ScheduleAbsence;
  public duration: moment.Duration;
  public comment: string;
  public taAbsence: TAAbsence;
}
