<div type="button"
  tabindex="1"
  [ngClass]="className"
  (keyup)="onKeyup($event)"
  [popper]="popperContent"
  [popperTrigger]="'click'"
  [popperPlacement]="popperPosition"
  [popperDisableStyle]="'true'"
  (popperOnShown)="onShown($event)"
  (popperOnHidden)="onHidden($event)"
>
  <i *ngIf="iconName" class="{{iconName}} slx-iconed-actions-button__icon" aria-hidden="true"></i>
  <div class="slx-action-button__content">
    <ng-content></ng-content>
  </div>
  <i *ngIf="dropDownIcon" class="{{dropDownIcon}} slx-dropdown-iconed-actions-button__icon" aria-hidden="true"></i>
</div>
