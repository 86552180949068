import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { GridComponent } from "@progress/kendo-angular-grid";
import { process } from '@progress/kendo-data-query';
import { PbjExportLogManagementService } from '../../../services';
import { PBJBatchExport } from '../../../models';
import * as _ from 'lodash';
import { mutableSelect } from './../../../../../../app/core/decorators';
import { Observable } from 'rxjs';
import { PBJChildDownload, PBJDownloadBulkExport, PBJDownloadExcelPdf, PBJDownloadSummary } from '../../../models/pbj-export/pbjChildGridDownload';
import { SessionService } from '../../../../../core/services';
import { PbjSignalrService } from '../../../../../portal/services/pbj-signalr/pbj-signalr.service';
var PbjExportGridParentComponent = /** @class */ (function () {
    function PbjExportGridParentComponent(managementService, changeDetector, sessionService, pbjSignalRService) {
        var _this = this;
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.sessionService = sessionService;
        this.pbjSignalRService = pbjSignalRService;
        this.records = [];
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'batchId', dir: 'desc' }];
        this.managementService.sort = this.gridState.state.sort;
        this.gridState.state.filter = null;
        this.gridState.state.group = [];
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
        this.managementService.filteredData$.subscribe(function (val) {
            _this.records = val;
            if (_this.records === undefined || _this.records.length == 0) {
                _this.gridState.view = null;
                return;
            }
            if (_this.records.length != 0)
                _this.gridState.view = process(_this.records, _this.gridState.state);
            _this.gridState.view = process(_this.records, _this.gridState.state);
            _this.managementService.collapseAll$.subscribe(function (data) {
                _.forEach(_this.records, function (record, ind) {
                    _this.grid.collapseRow(ind);
                    // _.forEach(data, (item, index) => {
                    //   if (item === record.batchId) {
                    //     this.grid.expandRow(index);
                    //   }
                    // });
                });
            });
        });
        this.pbjSignalRService.pbjExportRealtimeFeedback$.subscribe(function (args) {
            var parentRealTimeUpdate = args && args.length > 2 ? JSON.parse(args[2]) : null;
            if (_this.sessionService.getAlias().toLowerCase() === args[1].toLowerCase()) {
                _this.updateParentProgressBar(parentRealTimeUpdate);
            }
        });
    }
    PbjExportGridParentComponent.prototype.ngOnInit = function () {
        this.refreshGrid();
    };
    PbjExportGridParentComponent.prototype.updateParentProgressBar = function (exportParentItem) {
        this.records.map(function (item) {
            if (item.batchId === exportParentItem.BatchId) {
                item.batchProgress = exportParentItem.BatchProgress;
                item.batchStatus = exportParentItem.BatchStatus == 'SUCCESS' ? 'Completed' : exportParentItem.BatchStatus;
            }
        });
    };
    PbjExportGridParentComponent.prototype.refreshGrid = function () {
        var _this = this;
        var batchExportObj = new PBJBatchExport();
        batchExportObj.startDate = this.startDate;
        batchExportObj.endDate = this.endDate;
        batchExportObj.startDate = this.startDate;
        batchExportObj.orgLevelId = this.orgLevel.id;
        this.managementService.getParentGridExportData(batchExportObj)
            .then(function (val) {
            _this.records = val;
            _this.managementService.onLoadStatusChanged(false);
            if (_this.records === undefined || _this.records.length == 0) {
                _this.gridState.view = null;
                return;
            }
            if (_this.records.length != 0)
                _this.gridState.view = process(_this.records, _this.gridState.state);
        }).catch(function (reason) {
            _this.managementService.onError(reason);
        });
        this.gridState.view = process(this.records, this.gridState.state);
        this.managementService.collapseAll$.subscribe(function (data) {
            _.forEach(_this.records, function (record, ind) {
                _this.grid.collapseRow(ind);
            });
        });
    };
    PbjExportGridParentComponent.prototype.filterChangeHandler = function (event) {
        this.gridState.state.filter = event;
        this.filterGrid();
    };
    PbjExportGridParentComponent.prototype.filterGrid = function () {
        if (this.records === undefined || this.records.length == 0) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    Object.defineProperty(PbjExportGridParentComponent.prototype, "dateFormat", {
        get: function () {
            return '{0:MM/dd/yyyy}';
        },
        enumerable: true,
        configurable: true
    });
    PbjExportGridParentComponent.prototype.sortChange = function (sort) {
        this.gridState.state.sort = sort;
        this.filterGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PbjExportGridParentComponent.prototype.downloadParentGridData = function (batchId) {
        var exportObj = new PBJChildDownload();
        exportObj.exportIds = 0;
        exportObj.batchId = batchId;
        exportObj.orgLevelId = this.orgLevel.id;
        this.managementService.downloadChildExportData(exportObj);
    };
    PbjExportGridParentComponent.prototype.onDownloadSummary = function (dataItem, orgLevelId) {
        var exportObj = new PBJDownloadSummary();
        exportObj.batchId = dataItem.batchId;
        exportObj.orgLevelId = orgLevelId;
        exportObj.startDate = dataItem.startDate;
        exportObj.endDate = dataItem.endDate;
        this.managementService.downloadExportSummaryData(exportObj);
        console.log("Download Summary in process !!");
    };
    PbjExportGridParentComponent.prototype.onDownloadBulkExport = function (dataItem, orgLevelId) {
        if (dataItem.staffingInfo == 'N') {
            this.managementService.showBulkExportWarn();
            return;
        }
        var bulkFileObj = new PBJDownloadBulkExport();
        bulkFileObj.batchId = dataItem.batchId;
        bulkFileObj.orgLevelId = orgLevelId;
        this.managementService.downloadBulkExportFile(bulkFileObj);
    };
    PbjExportGridParentComponent.prototype.detailExpand = function (e) {
        if (e.expand) {
            this.managementService.expandedBatchIds.push(e.dataItem.batchId);
        }
    };
    PbjExportGridParentComponent.prototype.detailCollapse = function (e) {
        var index = this.managementService.expandedBatchIds.findIndex(function (x) { return e.dataItem.batchId === x; });
        this.managementService.expandedBatchIds.splice(index, 1);
    };
    PbjExportGridParentComponent.prototype.onDownloadExcelPdf = function (batchId, type) {
        this.pbjSignalRService.isPDFDownloadInitiated$.next(true);
        var expObj = new PBJDownloadExcelPdf();
        expObj.batchId = batchId;
        expObj.type = type;
        expObj.alias = null;
        expObj.userId = null;
        expObj.orgLevelId = this.orgLevel.id;
        expObj.exportId = '0';
        this.managementService.downloadExcelPDF(expObj);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PbjExportGridParentComponent.prototype, "orgLevel$", void 0);
    return PbjExportGridParentComponent;
}());
export { PbjExportGridParentComponent };
