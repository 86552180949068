import { EventEmitter, OnChanges, SimpleChanges, SimpleChange, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ScheduleCycle } from '../../models/lookup/schedule-cycle';
import { appConfig } from '../../../app.config';
var ScheduleCycleSliderComponent = /** @class */ (function () {
    function ScheduleCycleSliderComponent() {
        this.itemSelected = new EventEmitter();
        this.enableDropdown = true;
        this.internalCurrentIndex = -1;
    }
    Object.defineProperty(ScheduleCycleSliderComponent.prototype, "currentIndex", {
        get: function () {
            return this.internalCurrentIndex;
        },
        set: function (value) {
            if (!this.itemsArray) {
                this.internalCurrentIndex = -1;
                this.internalCurrentItem = null;
                return;
            }
            if (value === this.internalCurrentIndex)
                return;
            var clamped = value;
            if (value < -1)
                clamped = -1;
            if (value > this.itemsArray.length - 1)
                clamped = this.itemsArray.length - 1;
            this.internalCurrentIndex = clamped;
            if (this.internalCurrentIndex > -1) {
                this.internalCurrentItem = this.itemsArray[this.internalCurrentIndex];
            }
            else {
                this.internalCurrentItem = null;
            }
            this.itemSelected.emit(this.internalCurrentItem);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleCycleSliderComponent.prototype, "currentItem", {
        get: function () {
            return this.internalCurrentItem;
        },
        set: function (value) {
            var _this = this;
            if (this.internalCurrentItem === value)
                return;
            this.internalCurrentItem = value;
            if (this.internalCurrentItem) {
                this.internalCurrentIndex = _.findIndex(this.itemsArray, function (item) {
                    return item === _this.internalCurrentItem;
                });
            }
            else {
                this.internalCurrentIndex = -1;
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduleCycleSliderComponent.prototype.next = function () {
        this.currentIndex++;
    };
    ScheduleCycleSliderComponent.prototype.prev = function () {
        this.currentIndex--;
    };
    ScheduleCycleSliderComponent.prototype.onItemSelected = function (item) {
        this.currentItem = item;
        this.itemSelected.emit(this.internalCurrentItem);
    };
    Object.defineProperty(ScheduleCycleSliderComponent.prototype, "nextEnabled", {
        get: function () {
            if (!this.itemsArray)
                return false;
            return this.internalCurrentIndex < this.itemsArray.length - 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleCycleSliderComponent.prototype, "prevEnabled", {
        get: function () {
            if (!this.itemsArray)
                return false;
            return this.internalCurrentIndex > 0;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleCycleSliderComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    ScheduleCycleSliderComponent.prototype.ngOnChanges = function (changes) {
        var simpleChange = changes['itemsArray'];
        if (simpleChange) {
            if (!this.itemsArray && !simpleChange.currentValue) {
                this.internalCurrentIndex = -1;
            }
            else if (!this.itemsArray && simpleChange.currentValue) {
                this.internalCurrentIndex = 0;
            }
            else if (this.itemsArray && simpleChange.currentValue && (simpleChange.currentValue !== this.itemsArray)) {
                this.internalCurrentIndex = 0;
            }
        }
    };
    return ScheduleCycleSliderComponent;
}());
export { ScheduleCycleSliderComponent };
