import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { EmployeeSectionsAttendance, AbsenceGridItem } from '../../../../models';
import { appConfig } from '../../../../../../app.config';
var EmployeeSectionsAttendanceAbsencesGridComponent = /** @class */ (function () {
    function EmployeeSectionsAttendanceAbsencesGridComponent() {
        this.appConfig = appConfig;
    }
    Object.defineProperty(EmployeeSectionsAttendanceAbsencesGridComponent.prototype, "attendanceSection", {
        get: function () {
            return this.m_attendanceSection;
        },
        set: function (value) {
            this.m_attendanceSection = value;
            this.recalculateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceAbsencesGridComponent.prototype, "prefix", {
        get: function () {
            return this.m_prefix;
        },
        set: function (value) {
            this.m_prefix = value;
            this.recalculateData();
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendanceAbsencesGridComponent.prototype.ngOnInit = function () {
        this.recalculateData();
    };
    EmployeeSectionsAttendanceAbsencesGridComponent.prototype.recalculateData = function () {
        var _this = this;
        if (!this.attendanceSection || !this.prefix)
            return;
        this.absences = [];
        _.each(this.attendanceSection.dates, function (day) {
            if (day.getAbsence(_this.m_prefix)) {
                var absence = new AbsenceGridItem();
                absence.date = day.date;
                absence.sheduledHours = day.scheduledAbsenceHours;
                absence.absenseHours = day.actualAbsenceHours;
                absence.description = day.getAbsence(_this.m_prefix).description;
                absence.code = day.getAbsence(_this.m_prefix).code;
                absence.color = day.getAbsence(_this.m_prefix).color;
                _this.absences.push(absence);
            }
        });
    };
    EmployeeSectionsAttendanceAbsencesGridComponent.prototype.firstSymbol = function (str) {
        return str ? str.charAt(0) : '';
    };
    return EmployeeSectionsAttendanceAbsencesGridComponent;
}());
export { EmployeeSectionsAttendanceAbsencesGridComponent };
