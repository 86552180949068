/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./individual-schedule-employee.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-moment/date-format.pipe";
import * as i3 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i4 from "../../../../common/components/spinner/spinner.component";
import * as i5 from "../individual-schedule-entries-editor/individual-schedule-entries-editor.component.ngfactory";
import * as i6 from "../individual-schedule-entries-editor/individual-schedule-entries-editor.component";
import * as i7 from "../../../../organization/services/lookup/lookup-api.service";
import * as i8 from "../../../services/schedule-entry/schedule-entry-api.service";
import * as i9 from "../../../services/individual-schedule/individual-schedule-api.service";
import * as i10 from "../../../services/individual-schedule/individual-schedule-management.service";
import * as i11 from "../../../services/individual-schedule/individual-schedule-emp-management.service";
import * as i12 from "../../../../core/services/calendar-data/calendar-data.service";
import * as i13 from "../../../../common/services/date-time/date-time.service";
import * as i14 from "../../../../common/services/modal/modal.service";
import * as i15 from "../../../../organization/services/lookup/lookup.service";
import * as i16 from "@angular/common";
import * as i17 from "../../../../app-settings/services/app-settings-manage.service";
import * as i18 from "../../../store/individual-schedule/individual-schedule.actions";
import * as i19 from "./individual-schedule-employee.component";
import * as i20 from "@angular/router";
import * as i21 from "../../../../organization/services/employee/employee-definitions-api.service";
var styles_IndividualScheduleEmployeeComponent = [i0.styles];
var RenderType_IndividualScheduleEmployeeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IndividualScheduleEmployeeComponent, data: {} });
export { RenderType_IndividualScheduleEmployeeComponent as RenderType_IndividualScheduleEmployeeComponent };
export function View_IndividualScheduleEmployeeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DateFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 12, "slx-spinner", [], null, null, null, i3.View_SpinnerComponent_0, i3.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i4.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "div", [["class", "info-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "date-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " - ", " "])), i1.ɵppd(6, 2), i1.ɵppd(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "emp-name-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "div", [], [[2, "indSchContent", null]], null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "slx-individual-schedule-entries-editor", [], null, [[null, "onHasChanges"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onHasChanges" === en)) {
        var pd_0 = (_co.emitChanges($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_IndividualScheduleEntriesEditorComponent_0, i5.RenderType_IndividualScheduleEntriesEditorComponent)), i1.ɵdid(13, 245760, null, 0, i6.IndividualScheduleEntriesEditorComponent, [i7.LookupApiService, i8.ScheduleEntryApiService, i9.IndividualScheduleApiService, i10.IndividualScheduleManagementService, i11.IndividualScheduleEmpManagementService, i12.CalendarDataService, i13.DateTimeService, i14.ModalService, i15.LookupService, i16.DatePipe, i17.AppSettingsManageService], { cycleStartDate: [0, "cycleStartDate"], cycleEndDate: [1, "cycleEndDate"], showEmployeeName: [2, "showEmployeeName"], hasScheduleViewParams: [3, "hasScheduleViewParams"] }, { onHasChanges: "onHasChanges" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_5 = _co.startDate; var currVal_6 = _co.endDate; var currVal_7 = false; var currVal_8 = _co.hasScheduleViewParams; _ck(_v, 13, 0, currVal_5, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.startDate, _co.appConfig.dateFormat)); var currVal_2 = i1.ɵunv(_v, 5, 1, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.endDate, _co.appConfig.dateFormat)); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = ((_co.empInfo == null) ? null : _co.empInfo.name); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.hasScheduleViewParams; _ck(_v, 11, 0, currVal_4); }); }
export function View_IndividualScheduleEmployeeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-individual-schedule-employee", [], null, null, null, View_IndividualScheduleEmployeeComponent_0, RenderType_IndividualScheduleEmployeeComponent)), i1.ɵprd(4608, null, i10.IndividualScheduleManagementService, i10.IndividualScheduleManagementService, [i18.IndividualScheduleActions, i11.IndividualScheduleEmpManagementService]), i1.ɵprd(512, null, i11.IndividualScheduleEmpManagementService, i11.IndividualScheduleEmpManagementService, []), i1.ɵdid(3, 245760, null, 0, i19.IndividualScheduleEmployeeComponent, [i20.ActivatedRoute, i20.Router, i7.LookupApiService, i21.EmployeeDefinitionsApiService, i11.IndividualScheduleEmpManagementService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var IndividualScheduleEmployeeComponentNgFactory = i1.ɵccf("slx-individual-schedule-employee", i19.IndividualScheduleEmployeeComponent, View_IndividualScheduleEmployeeComponent_Host_0, { scheduleViewParams: "scheduleViewParams" }, { onHasChanges: "onHasChanges" }, []);
export { IndividualScheduleEmployeeComponentNgFactory as IndividualScheduleEmployeeComponentNgFactory };
