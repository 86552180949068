<slx-spinner [show]="isLoading">
  <div class="modal-body">
    <div class="dialog-modal-header">
      <div class="flex align-items__center justify-content__space-between">
        <div class="header__value"><span class="slx-bold">Employee:</span> {{req.employeeName}}</div>
        <div class="header__value"><span class="slx-bold">Emp Id:</span> {{req.employeeId}}</div>
      </div>
      <div class="flex align-items__center">
        <div class="header__value"><span class="slx-bold">Date:</span> {{req.date | amDateFormat: appConfig.dateFormat
          }}</div>
      </div>
    </div>

    <kendo-grid #kendoGrid class="slx-blue-grid slx-transparent-selection slx-grid-slim-rows" [data]="gridState?.view"
      [filterable]="'menu'" [filter]="gridState.state.filter" [sortable]="true" [sort]="gridState.state.sort"
      (dataStateChange)="gridState.dataStateChange($event)" (selectionChange)="gridState.selectionChange($event); selectionChange($event)"
      [selectable]="{ checkboxOnly: true, mode: commentSelection }">

      <kendo-grid-checkbox-column width="30"></kendo-grid-checkbox-column>

      <kendo-grid-column title="Comment" width="80" field="description" [sortable]="true">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{dataItem.description}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

  <div class="modal-footer">
    <button type="button" [disabled]="selectedMultipleComments.length <=0" (click)="onOk()" class="btn green"><i class="fas fa-save slx-button__icon"
        aria-hidden="true"></i>Apply Comments</button>
    <button type="button" (click)="onCancel()" class="btn red"><i class="fas fa-times slx-button__icon"
        aria-hidden="true"></i>Close</button>
  </div>
</slx-spinner>