<slx-spinner [show]="isLoading || hideToPrint">
    <div class="slx-high-box" id="contentToConvert">
        <slx-staffing-predictor-header class="slx-content-toolbar-indents" 
            (exportClick)="generatePDF()" 
            (yearSelected)="onYearSelected($event)"
            (periodSelected)="onPeriodSelected($event)"
            (orgSelected)="onOrgSelected($event)"
            [five_star_v2_enabled]="five_star_v2_enabled"
            [five_star_goal_section_enabled]="five_star_goal_section_enabled"
            ></slx-staffing-predictor-header>
        
        <div class="slx-high-box__body slx-main-content-indents staffing-predictor-content" ngClass="{'no-top-margin':five_star_v2_enabled}">
            <ng-container *ngIf="!five_star_v2_enabled">
                <div class="slx-staffing-predictor-sections" *ngFor="let organization of container?.organizations; let i = index">
                    <slx-staffing-predictor-section
                    [organization]="organization" 
                        [five_star_v2_enabled]
                        ="five_star_v2_enabled"
                        [five_star_goal_section_enabled]="five_star_goal_section_enabled"
                        [sectionIndex]="i"
                        ></slx-staffing-predictor-section>
                </div>
            </ng-container>
            <ng-container *ngIf="five_star_v2_enabled">
                <div id='Section-{{i}}' class="slx-staffing-predictor-sections" [ngClass]=" five_star_goal_section_enabled && value!= undefined && value!= '' && value!= i ? 'disableAccordion' : ''"
                    *ngFor="let organization of fiveStartContainer?.organizations; let i = index">
                    <slx-staffing-predictor-section [isLoading]="isLoading" [fiveStarOrganization]="organization" 
                        [five_star_v2_enabled]="five_star_v2_enabled"
                        [five_star_goal_section_enabled]="five_star_goal_section_enabled"
                        [sectionIndex]="i"
                        ></slx-staffing-predictor-section>
                </div>
            </ng-container>
            <!-- <div class="slx-staffing-predictor-sections">
                <div *ngIf="!container || container?.organizations?.length <= 0" class="error-info not-critical">
                    <div class="error-messagex-box">
                        <span class="error-mesage-text">
                            Facing technical issues in retrieving data from CMS. We are working with them to resolve it asap.
                        </span>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</slx-spinner>
