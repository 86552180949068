/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-details-renew-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-moment/date-format.pipe";
import * as i3 from "@angular/forms";
import * as i4 from "../../../../../components-library/components/datepicker/datepicker.component.ngfactory";
import * as i5 from "../../../../../components-library/components/datepicker/datepicker.component";
import * as i6 from "@angular/common";
import * as i7 from "./benefit-details-renew-dialog.component";
import * as i8 from "../../../../../common/models/dialog-options";
import * as i9 from "../../../../../common/services/modal/modal.service";
import * as i10 from "../../../models/benefit-details/benefit-details-line-standart";
import * as i11 from "../../../models/benefit-details/benefit-details-line";
var styles_BenefitDetailsRenewDialogComponent = [i0.styles];
var RenderType_BenefitDetailsRenewDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitDetailsRenewDialogComponent, data: {} });
export { RenderType_BenefitDetailsRenewDialogComponent as RenderType_BenefitDetailsRenewDialogComponent };
function View_BenefitDetailsRenewDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-xs-12 comment"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This timeline cannot be renewed "]))], null, null); }
function View_BenefitDetailsRenewDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-xs-12 comment"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" This will automatically extend coverage for enrolled employees and dependents. "]))], null, null); }
function View_BenefitDetailsRenewDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-xs-12 comment"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" Select a date within ", " - ", " to not overlap with an existing timeline. "])), i1.ɵppd(3, 2), i1.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _co.newStartDate, _co.appConfig.dateFormat)); var currVal_1 = i1.ɵunv(_v, 2, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.maxValidDate, _co.appConfig.dateFormat)); _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_BenefitDetailsRenewDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DateFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 27, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["class", "col-xs-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Start Date:"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-xs-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "label", [["class", "col-xs-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["New End Date:"])), (_l()(), i1.ɵeld(12, 0, null, null, 10, "div", [["class", "col-xs-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 9, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(15, 4210688, [["form", 4]], 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(17, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 4, "slx-datepicker", [["class", "slx-padd-r benefit-provider__date"], ["name", "dateField"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "slx-watermark", null]], [[null, "ngModelChange"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 22).onMouseover($event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.benefitDetailsLineNewEndDate = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_DatepickerInputComponent_0, i4.RenderType_DatepickerInputComponent)), i1.ɵdid(19, 671744, null, 0, i3.NgModel, [[2, i3.ControlContainer], [8, null], [8, null], [8, null]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(21, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i1.ɵdid(22, 573440, null, 0, i5.DatepickerInputComponent, [i3.NgControl], { minDate: [0, "minDate"], maxDate: [1, "maxDate"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsRenewDialogComponent_1)), i1.ɵdid(24, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsRenewDialogComponent_2)), i1.ɵdid(26, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitDetailsRenewDialogComponent_3)), i1.ɵdid(28, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(33, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_16 = "dateField"; var currVal_17 = _co.benefitDetailsLineNewEndDate; _ck(_v, 19, 0, currVal_16, currVal_17); var currVal_18 = _co.newStartDate; var currVal_19 = _co.maxValidDate; _ck(_v, 22, 0, currVal_18, currVal_19); var currVal_20 = _co.canNotRenew; _ck(_v, 24, 0, currVal_20); var currVal_21 = (i1.ɵnov(_v, 15).valid && !_co.canNotRenew); _ck(_v, 26, 0, currVal_21); var currVal_22 = i1.ɵnov(_v, 15).invalid; _ck(_v, 28, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.newStartDate, _co.appConfig.planDateTimeFormat)); _ck(_v, 7, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 17).ngClassValid; var currVal_6 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 13, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_9 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_10 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_11 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_12 = i1.ɵnov(_v, 21).ngClassValid; var currVal_13 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_14 = i1.ɵnov(_v, 21).ngClassPending; var currVal_15 = i1.ɵnov(_v, 22).watermarkClass; _ck(_v, 18, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_23 = (i1.ɵnov(_v, 15).invalid || _co.canNotRenew); _ck(_v, 30, 0, currVal_23); }); }
export function View_BenefitDetailsRenewDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-details-renew-dialog", [], null, null, null, View_BenefitDetailsRenewDialogComponent_0, RenderType_BenefitDetailsRenewDialogComponent)), i1.ɵdid(1, 49152, null, 0, i7.BenefitDetailsRenewDialogComponent, [i8.DialogOptions, i9.ModalService, i10.BenefitDetailsLineStandart, i11.BenefitDetailsLine], null, null)], null, null); }
var BenefitDetailsRenewDialogComponentNgFactory = i1.ɵccf("slx-benefit-details-renew-dialog", i7.BenefitDetailsRenewDialogComponent, View_BenefitDetailsRenewDialogComponent_Host_0, {}, {}, []);
export { BenefitDetailsRenewDialogComponentNgFactory as BenefitDetailsRenewDialogComponentNgFactory };
