import { IAgencyEmployee, AgencyEmployee } from './agency-employee';
import { Organization, IOrganization, IDepartment, Department, IPosition, Position, IShift, Shift, ILocationUnit, LocationUnit } from '../../../organization/models/index';

export interface IAddAgencyEmployeeReq {
  agencyEmployee: IAgencyEmployee;
  createAgency: boolean;
  createEmployee: boolean;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  shift: IShift;
  unit: ILocationUnit;
}
export class AddAgencyEmployeeReq {
  public agencyEmployee: AgencyEmployee;
  public createAgency: boolean;
  public createEmployee: boolean;
  public organization: IOrganization;
  public department: IDepartment;
  public position: Position;
  public shift: IShift;
  public unit: ILocationUnit;
}
