import * as _ from 'lodash';
import { WcCodeDefinition } from './wc-code-definition';
import { WcReportStatusDefinition } from './wc-report-status-definition';
import { WcMedicalTreatmentDefinition } from './wc-medical-treatment-definition';
import { WcWorkersCompensationCase } from './wc-workers-compensation-case';
export class WcLookupsContainer {

    public reportStatuses: WcReportStatusDefinition[];
    public medicalTreatments: WcMedicalTreatmentDefinition[];
    public compCases: WcWorkersCompensationCase[];
    public injuryTypes: WcCodeDefinition[];
    public oshaInjuryTypes: WcCodeDefinition[];
    public incidentLocations: WcCodeDefinition[];
    public equipmentTypes: WcCodeDefinition[];
    public bodyParts: WcCodeDefinition[];
    public bodySides: WcCodeDefinition[];
    public causes: WcCodeDefinition[];
    public occurences: WcCodeDefinition[];
    public illnesses: WcCodeDefinition[];

    public deepClone(): WcLookupsContainer {
        let wc = new WcLookupsContainer();
        wc.equipmentTypes = _.slice(this.equipmentTypes, 0);
        wc.incidentLocations = _.slice(this.incidentLocations, 0);
        wc.injuryTypes = _.slice(this.injuryTypes, 0);
        wc.oshaInjuryTypes = _.slice(this.oshaInjuryTypes, 0);
        wc.medicalTreatments = _.slice(this.medicalTreatments, 0);
        wc.reportStatuses = _.slice(this.reportStatuses, 0);
        wc.compCases = _.slice(this.compCases, 0);
        wc.bodyParts = _.slice(this.bodyParts, 0);
        wc.bodySides = _.slice(this.bodySides, 0);
        wc.causes = _.slice(this.causes, 0);
        wc.occurences = _.slice(this.occurences, 0);
        wc.illnesses = _.slice(this.illnesses, 0);
        return wc;
    }
}