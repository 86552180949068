import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { LogiManagementService } from '../services/logi-management.service';
import { WindowRef } from '../../../core/services/window/window-ref.model';
var LogiHostComponent = /** @class */ (function () {
    function LogiHostComponent(router, logiManagementService, winRef) {
        this.router = router;
        this.logiManagementService = logiManagementService;
        this.winRef = winRef;
        this.isIFrameLoading = true;
        this.isDataLoading = false;
        this.isActive = false;
    }
    LogiHostComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.logiManagementService.initialize(this.router.url);
        this.loadStateChangedSubscription = this.logiManagementService.onLoadStatus$.subscribe(function (isLoading) {
            if (isLoading) {
                _this.isActive = false;
            }
            _this.isDataLoading = isLoading;
            //  this.isIFrameLoading = isLoading;
        });
        this.logiLinkChangedSubscription = this.logiManagementService.logiUrlChanged$.subscribe(function (url) {
            _this.logiUrl = url;
            _this.isActive = true;
        });
        this.logiLoadedSubscription = this.logiManagementService.subscribeToLogiLoaded(function () {
            _this.isIFrameLoading = false;
            _this.logiManagementService.resetCounterUserInactivity();
        });
        this.logiActionSubscription = this.logiManagementService.subscribeToLogiUserAction(function (event) {
            _this.logiManagementService.resetCounterUserInactivity();
            if (event === 'click') {
                _this.winRef.nativeWindow.document.body.click();
            }
        });
        this.applyFixForIOSSafariScrolling();
    };
    LogiHostComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.logiManagementService.destroy();
    };
    LogiHostComponent.prototype.applyFixForIOSSafariScrolling = function () {
        var elem = this.winRef.nativeWindow.document.body.querySelector('.main-box');
        if (elem !== null) {
            elem.classList.add('ios-safari-scroll-fix');
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LogiHostComponent.prototype, "loadStateChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LogiHostComponent.prototype, "logiLinkChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LogiHostComponent.prototype, "logiLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LogiHostComponent.prototype, "logiActionSubscription", void 0);
    return LogiHostComponent;
}());
export { LogiHostComponent };
