import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { VacationPlan, VacationPlanSettings } from '../../models/index';
import { VacationPlannerApiService } from '../../services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-vacation-planner',
  templateUrl: 'vacation-planner.component.html',
  styleUrls: ['vacation-planner.component.scss']
})
export class VacationPlannerComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public vacationPlannerData: VacationPlan;
  public settings: VacationPlanSettings;
  public selectedOrgLevel: OrgLevel;
  public redrawToggler: boolean;
  public state: {
    isLoading: boolean;
  };

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private dailyDetailsSaveStartedSubscription: Subscription;
  @unsubscribe()
  private dailyDetailsSaveFinishedSubscription: Subscription;

  constructor(private vacationPlannerApiService: VacationPlannerApiService) {
    this.state = {
      isLoading: false,
    };
  }

  public ngOnInit(): void {

    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        scheduleMicrotask(() => {
          this.selectedOrgLevel = o;
          this.loadVacationPlan();
        });
      });

    this.dailyDetailsSaveStartedSubscription = this.vacationPlannerApiService.onSaveDailyDetailsStarted$.subscribe(() => {
      this.state.isLoading = true;
    });
    this.dailyDetailsSaveFinishedSubscription = this.vacationPlannerApiService.onSaveDailyDetailsFinished$.subscribe(() => {
      this.state.isLoading = false;
    });

  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onSettingsChanged(settings: VacationPlanSettings): void {
    scheduleMicrotask(() => {
      this.settings = settings;
      if (this.settings.reload) {
        this.settings.reload = false;
        this.loadVacationPlan();
      }
      if (!this.settings.reload) {
        this.redrawToggler = !this.redrawToggler;
      }
    });
  }

  private loadVacationPlan(): void {
    if (!this.state.isLoading) {
      if (!this.selectedOrgLevel || !this.settings) {
        return;
      }
      this.state.isLoading = true;
      this.vacationPlannerApiService.getVacationPlan(this.selectedOrgLevel.id, this.settings.year)
        .then((vacationPlan: VacationPlan) => {
          this.vacationPlannerData = vacationPlan;
          this.state.isLoading = false;
        });
    }
  }
}
