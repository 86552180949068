import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy } from '@angular/core';
import { KendoGridStateHelper } from './../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
var DailyPartialShiftsGridComponent = /** @class */ (function () {
    function DailyPartialShiftsGridComponent() {
        var _this = this;
        this.itemRemove = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    }
    Object.defineProperty(DailyPartialShiftsGridComponent.prototype, "partials", {
        get: function () {
            return this.partialShifts;
        },
        set: function (value) {
            this.partialShifts = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    DailyPartialShiftsGridComponent.prototype.refreshGrid = function () {
        if (!this.partialShifts) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.take = 24;
        this.gridState.view = process(this.partialShifts, this.gridState.state);
    };
    DailyPartialShiftsGridComponent.prototype.removeItem = function (item, groupDetails) {
        this.partialShifts.filter(function (x) { return x === groupDetails; }).forEach(function (ele) {
            ele.messages = ele.messages.filter(function (y) { return y !== item; });
        });
        this.refreshGrid();
    };
    DailyPartialShiftsGridComponent.prototype.ngOnDestroy = function () {
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyPartialShiftsGridComponent.prototype, "gridRefreshSubscription", void 0);
    return DailyPartialShiftsGridComponent;
}());
export { DailyPartialShiftsGridComponent };
