import * as _ from 'lodash';
import { ControlValueAccessor } from '@angular/forms';
var RadioButtonComponent = /** @class */ (function () {
    function RadioButtonComponent() {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = {};
    }
    Object.defineProperty(RadioButtonComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(this.innerValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    RadioButtonComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.innerValue = value;
        }
    };
    RadioButtonComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    RadioButtonComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    RadioButtonComponent.prototype.isChecked = function () {
        if (this.valueField) {
            return this.option[this.valueField] === this.value[this.valueField];
        }
        return this.option === this.value;
    };
    RadioButtonComponent.prototype.cssClass = function () {
        var cssClass = 'slx-radio';
        if (this.isChecked()) {
            cssClass += " checked";
        }
        if (this.readonly) {
            cssClass += " disabled";
        }
        if (_.isString(this.className) && _.size(this.className) > 0) {
            cssClass += " " + this.className;
        }
        return cssClass;
    };
    return RadioButtonComponent;
}());
export { RadioButtonComponent };
