import { Rules, IRules } from './accrual-rules';
import { Actions } from '../../../../core/models/index';

export interface IAccrualPolicy {
  id: number;
  policyName: string;
  orgLevelId: number;
  lastUpdateId: string;
  lastUpdateDate: Date;
  policyStartDate: string;
  policyEndDate: string;
  rules: IRules[];
}

export class AccrualPolicy {
  public id: number;
  public policyName: string;
  public orgLevelId: number;
  public deleted: boolean;
  public lastUpdateId: string;
  public lastUpdateDate: Date;
  public policyStartDate: string;
  public policyEndDate: string;
  public rules: Rules[];

  public headerName: string;
  public hidden: boolean;
  public expanded: boolean;
  public addNew: boolean;
  public isLoading: boolean;
 
  constructor(name?: string) {
    if (name) {
      this.headerName = name;
      this.hidden = false;
      this.expanded = false;
      this.addNew = false;
      this.isLoading = false;
    }
  }
}

export class AccrualPolicyDefinitions {
  public accrualPolicy: AccrualPolicy[];
  public actions: Actions;
  public CreateorEditAccruals: boolean;
  public DeleteAccruals: boolean;
  public CopyAccrualPolicy:boolean;
  
}
