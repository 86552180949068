import { Injectable } from '@angular/core';

import { ResponseBody, Meta, EditResult, FieldsMeta } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitListMapService } from './benefit-list-map.service';
import { BenefitDetailsMapService } from './../benefit-details/index';

import {
  BenefitLineInfo,
  IBenefitLineInfo,
  BenefitDetailsConfig,
  IBenefitDetailsConfig,
  BenefitDetailsProvider,
  IBenefitDetailsProvider,
  BenefitPayrollMapping,
  IBenefitPayrollMapping,
  BenefitPayrollExistingMapping,
  IBenefitPayrollExistingMapping,
  BenefitPayrollCreateMapping
} from '../../models/index';

@Injectable()
export class BenefitListApiService {
  constructor(
    private mapService: BenefitListMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private commonService: BenefitDetailsMapService
  ) { }

  public async getBenefitList(orgLevelId: number, loadExpired: boolean): Promise<BenefitLineInfo[]> {
    const url = `${this.getApiRoot()}/${benefitsConfig.api.benefits.linesInfo}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId, loadExpired: !!loadExpired });

    return this.apiUtilService.request<IBenefitLineInfo[], Meta>(request)
      .then((response: ResponseBody<IBenefitLineInfo[], Meta>) => this.mapService.mapToBenefitList(response.data));
  }

  public async getBenefitPlanType(orgLevelId: number, groupId: number, loadExpired: boolean): Promise<BenefitDetailsProvider[]> {
    const url = `${this.getApiRoot()}/${benefitsConfig.api.benefits.providersWithEnrollments}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId, groupId, loadExpired: !!loadExpired });

    return this.apiUtilService.request<IBenefitDetailsProvider[], Meta>(request)
      .then((response) => this.commonService.mapToBenefitPlanProviders(response.data));
  }

  public async getBenefitPlanHeaders(orgLevelId: number): Promise<BenefitDetailsConfig> {
    const url = `${this.getApiRoot()}/${benefitsConfig.api.benefits.workplace}`;
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId });

    return this.apiUtilService.request<IBenefitDetailsConfig, Meta>(request)
      .then((response) => this.commonService.mapToBenefitPlanConfig(response.data));
  }

  public async getBenefitPayRollMapping(showIgnored: boolean): Promise<BenefitPayrollMapping> {
    const url = `${this.getApiRoot()}/${benefitsConfig.api.benefits.payrollMapping}`;
    const request = this.urlParamsService.createGetRequest(url, { showIgnored });

    return this.apiUtilService.request<IBenefitPayrollMapping, Meta>(request)
      .then((response) => this.commonService.mapToBenefitPayrollMapping(response.data));
  }

  public async getpayrollExistingMapping(): Promise<BenefitPayrollExistingMapping[]> {
    const url = `${this.getApiRoot()}/${benefitsConfig.api.benefits.payrollExistingMapping}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IBenefitPayrollExistingMapping[], Meta>(request)
      .then((response) => this.commonService.mapToBenefitPayrollExistingMappings(response.data));
  }

  public benefitPayRollCreateMapping(req: BenefitPayrollCreateMapping): Promise<any> {
    const url = `${this.getApiRoot()}/${benefitsConfig.api.benefits.createMapping}`;
    const request = this.urlParamsService.createPostRequest(url, req);
    return this.apiUtilService.request<any[], Meta>(request);
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.benefits.root}`;
  }

}
