import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { EmployeeGridData, MasterScheduleSettings, MasterScheduleFilters, AutoSchedulingConfig, IAutoSchedulingConfig, AutoSchedulingState } from '../../../models/index';
import { LookupApiService, ScheduleCycleHelperService } from '../../../../organization/services/index';
import { ModalService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { PostScheduleNavigationService, ScheduleEntryNavigationService, EmployeeSectionNavigationService } from '../../../../common/services/index';
import { ToolbarSectionTypes, DialogOptions2, DialogModeSize } from '../../../../common/models/index';
import { ConfirmOptions, ConfirmDialogComponent, ConfirmDialog2Component } from '../../../../common/components/index';
import { ScheduleApiService } from '../../../services/index';
import { RestoreScheduleComponent } from '../../restore-schedule/restore-schedule.component';
import { MasterScheduleManagementService, MasterScheduleToolbarService, MasterScheduleQuickEditService, MasterScheduleAutoSchedulingService } from '../../../services/index';
import { NotificationsService } from '../../../../core/components/index';
import { appMessages } from '../../../../app.messages';
import { ScheduleCycleSummaryDialogComponent } from '../../../../organization/components/index';
import { StateManagementService } from '../../../../common/services/index';
import { MasterScheduleGroupingTypes } from '../../../models/master-schedule/master-schedule-grouping-type';
import { ScheduleEntryApiService } from '../../../services/index';
var MasterScheduleHeaderComponent = /** @class */ (function () {
    function MasterScheduleHeaderComponent(lookupApiService, scheduleApiService, modalService, managementService, router, appSettingsManageService, activatedRoute, notificationsService, scheduleCycleHelperService, masterScheduleToolbarService, stateManagementService, masterScheduleQuickEditService, autoSchedulingService, ScheduleEntryApiService) {
        this.lookupApiService = lookupApiService;
        this.scheduleApiService = scheduleApiService;
        this.modalService = modalService;
        this.managementService = managementService;
        this.router = router;
        this.appSettingsManageService = appSettingsManageService;
        this.activatedRoute = activatedRoute;
        this.notificationsService = notificationsService;
        this.scheduleCycleHelperService = scheduleCycleHelperService;
        this.masterScheduleToolbarService = masterScheduleToolbarService;
        this.stateManagementService = stateManagementService;
        this.masterScheduleQuickEditService = masterScheduleQuickEditService;
        this.autoSchedulingService = autoSchedulingService;
        this.ScheduleEntryApiService = ScheduleEntryApiService;
        this.onExportSchedule = new EventEmitter();
        this.onFetchSchedule = new EventEmitter();
        this.defaultItem = { id: null, name: 'ALL' };
        this.min = new Date(2000, 0, 1);
        this.max = new Date(2079, 11, 31);
        this.quickEditWindowTop = 42;
        this.quickEditWindowLeft = 800;
        this.quickEditWidth = 450;
        this.quickEditHeight = 260;
        this.initalPositionGrouping = MasterScheduleGroupingTypes.ByPosition;
        this.generatingDaysList = this.autoSchedulingService.generateDays(30);
        this.postingDaysList = this.autoSchedulingService.generateDays(29);
        this.autoSchedulingConfig = new AutoSchedulingConfig({});
        this.state = {
            isSelectPropagated: true
        };
    }
    Object.defineProperty(MasterScheduleHeaderComponent.prototype, "dateFrom", {
        get: function () {
            return this.managementService.dateFrom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleHeaderComponent.prototype, "cycles", {
        get: function () {
            return this.managementService.cycles;
        },
        enumerable: true,
        configurable: true
    });
    MasterScheduleHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.quickEditActiveSubscription = this.masterScheduleQuickEditService.quickEditStatusChanged$.subscribe(function (event) {
            _this.isQuickEditActive = event.isActive;
            _this.isQuickEditStarted = event.isStarted;
        });
        this.quickEditSavedSubscription = this.masterScheduleQuickEditService.quickSaveResult$.subscribe(function (result) {
            _this.quickEditSummary = result;
            _this.showQuickEditSummary();
            _this.masterScheduleToolbarService.onFiltersChanged(_this.filters);
        });
        this.autoSchedulingSubscription = this.autoSchedulingService.subscribeToAutoScheduling(function (state) {
            _this.autoSchedulingConfig = state.config;
            _this.storedAutoSchedulingConfig = _.cloneDeep(state.config);
            _this.assignInitDays(state.config);
            _this.autoSchedulingState = state;
        });
        this.autoSchedulingLoadingSubscription = this.autoSchedulingService.subscribeToLoadingState(function (isLoading) {
            _this.loadingAutoSchedulingConfig = isLoading;
        });
        this.masterScheduleToolbarService.onSettingsChanged$.subscribe(function (settings) {
            _this.settings = settings;
            _this.settings.display.positionGrouping = settings.display.positionGrouping ? settings.display.positionGrouping : _this.initalPositionGrouping;
            _this.saveSettings = _.cloneDeep(settings);
        });
        this.filterSubscription = this.masterScheduleToolbarService.onFiltersChanged$
            .subscribe(function (filters) {
            _this.filters = filters;
            _this.filtersSaved = _.cloneDeep(_this.filters);
            _this.checkIsFiltered();
            _this.restorePositionGroupPreset();
            _this.restorePositionPreset();
            _this.restoreEmpTypesPreset();
            _this.restoreUnitsPreset();
            _this.restoreShiftGroupPreset();
        });
        this.orgLevelSubscriptionFilters = this.orgLevel$
            .withLatestFrom(this.managementService.filtersRestored$)
            .subscribe(function (_a) {
            var orgLevel = _a[0], filters = _a[1];
            if (!orgLevel || !orgLevel.id) {
                return;
            }
            _this.selectedOrgLevel = orgLevel;
            _this.lookupApiService.getPositionGroups(orgLevel.id, true).then(function (value) {
                _this.positionGroupLookup = value;
                _this.restorePositionGroupPreset();
            });
            _this.lookupApiService.getPositions(undefined, orgLevel.id).then(function (value) {
                _this.positionLookup = value;
                _this.restorePositionPreset();
            });
            _this.lookupApiService.getEmpTypes().then(function (value) {
                _this.employeeTypeLookup = value;
                _this.restoreEmpTypesPreset();
            });
            _this.lookupApiService.getLocationUnits(undefined, orgLevel.id).then(function (value) {
                _this.unitLookup = value;
                _this.restoreUnitsPreset();
            });
            _this.lookupApiService.getShiftGroupDefinition(orgLevel.id).then(function (value) {
                _this.shiftGroupLookup = value;
                _this.restoreShiftGroupPreset();
            });
            _this.autoSchedulingService.init(orgLevel.id);
        });
        this.recalculatedSubscription = this.managementService.onRecalculated$.subscribe(function (data) {
            _this.isPosted = data.isPosted;
        });
        this.actionsSubscription = this.managementService.actions$.subscribe(function (data) {
            _this.actions = data;
        });
        this.scheduleCyclesSubscription = this.managementService.scheduleCyclesLoaded$.subscribe(function () { return _this.onDaysListChanged(true); });
        this.getSettings();
    };
    MasterScheduleHeaderComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    MasterScheduleHeaderComponent.prototype.isVisibleSection = function (sectionType) {
        return sectionType === ToolbarSectionTypes.VISIBLE;
    };
    MasterScheduleHeaderComponent.prototype.onDateFromChanged = function (dateFrom) {
        if (moment(dateFrom).year() > 2000) {
            this.managementService.updateDatesSubscription(dateFrom);
            this.filters.dateFrom = dateFrom;
            this.filters.weekNumber = this.managementService.getWeeksByDate(dateFrom);
            this.masterScheduleToolbarService.onFiltersChanged(this.filters);
            //this.actions.setFilters({ dateFrom, scheduleDisplay: this.getWeeksByDate(dateFrom) });
        }
    };
    MasterScheduleHeaderComponent.prototype.isStartCycle = function (date) {
        return this.managementService.isStartCycle(date);
    };
    MasterScheduleHeaderComponent.prototype.onSettingsClosed = function (isApply) {
        if (isApply) {
            return;
        }
        this.settings = _.cloneDeep(this.saveSettings);
    };
    MasterScheduleHeaderComponent.prototype.onSettingsChanged = function (popperContent, isApply) {
        popperContent.hide();
        if (!isApply) {
            return;
        }
        var filterChanged = this.saveSettings.totals.viewTotalsFTEs !== this.settings.totals.viewTotalsFTEs;
        this.masterScheduleToolbarService.settingsChanged(this.settings);
        if (filterChanged) {
            this.filters.showTotalsFTEs = this.settings.totals.viewTotalsFTEs;
            this.masterScheduleToolbarService.onFiltersChanged(this.filters);
        }
    };
    MasterScheduleHeaderComponent.prototype.onDisplaySelectChanged = function () {
        this.masterScheduleToolbarService.settingsChanged(this.settings);
    };
    MasterScheduleHeaderComponent.prototype.onPositionGroupingChanged = function () {
        if (this.settings.display.positionGrouping.id !== this.filters.groupBy.id) {
            this.settings.display.positionGrouping = this.filters.groupBy;
            this.masterScheduleToolbarService.groupingChanged(this.filters.groupBy);
            this.masterScheduleToolbarService.onFiltersChanged(this.filters);
        }
    };
    MasterScheduleHeaderComponent.prototype.onFiltersChanged = function (popperContent, isApply) {
        popperContent.hide();
        if (isApply) {
            this.masterScheduleToolbarService.onFiltersChanged(this.filters);
        }
        else {
            this.filters = _.cloneDeep(this.filtersSaved);
        }
    };
    MasterScheduleHeaderComponent.prototype.onDisplayOrderChanged = function (orderTypeName, value) {
        this.settings.display.displayOrder = value;
    };
    MasterScheduleHeaderComponent.prototype.onSeparateChanged = function () {
        //this.actions.setSettings(this.settings);
    };
    MasterScheduleHeaderComponent.prototype.onDaysListChanged = function (isGenerating) {
        if (!this.cycles)
            return;
        var currentDate = new Date();
        var generatingDays = _.get(this.daysBeforeGenerating, 'id');
        var postingDays = _.get(this.daysBeforePosting, 'id');
        if (isGenerating) {
            this.defineScheduleCycle(currentDate, generatingDays);
            this.assignGenerateDate(generatingDays);
            this.autoSchedulingConfig.daysBeforeGenerating = generatingDays;
            this.assignPostingDate(postingDays);
            this.autoSchedulingConfig.daysBeforePosting = postingDays;
        }
        else {
            this.assignPostingDate(postingDays);
            this.autoSchedulingConfig.daysBeforePosting = postingDays;
        }
        this.checkPostingDateValidity();
    };
    MasterScheduleHeaderComponent.prototype.onSaveAutoScheduling = function (popperContent) {
        var _this = this;
        this.autoSchedulingService.saveAutoScheduling(this.selectedOrgLevel.id, this.autoSchedulingConfig)
            .then(function () {
            _this.autoSchedulingConfig.enabled = true;
            var config = _.pick(_this.autoSchedulingConfig, ['daysBeforeGenerating', 'daysBeforePosting', 'enabled']);
            _this.storedAutoSchedulingConfig = new AutoSchedulingConfig(config);
            popperContent.hide();
        });
    };
    MasterScheduleHeaderComponent.prototype.onDeleteAutoScheduling = function (popperContent) {
        var _this = this;
        this.autoSchedulingService.deleteAutoScheduling(this.selectedOrgLevel.id)
            .then(function () {
            _this.autoSchedulingConfig.enabled = false;
            _this.storedAutoSchedulingConfig = new AutoSchedulingConfig({});
            popperContent.hide();
        });
    };
    MasterScheduleHeaderComponent.prototype.onCloseAutoScheduling = function () {
        if (this.autoSchedulingConfig.daysBeforeGenerating !== this.storedAutoSchedulingConfig.daysBeforeGenerating ||
            this.autoSchedulingConfig.daysBeforePosting !== this.storedAutoSchedulingConfig.daysBeforePosting) {
            var currentDate = new Date();
            this.autoSchedulingConfig = new AutoSchedulingConfig(this.storedAutoSchedulingConfig);
            this.defineScheduleCycle(currentDate, this.autoSchedulingConfig.daysBeforeGenerating);
            this.assignGenerateDate(this.autoSchedulingConfig.daysBeforeGenerating);
            this.assignPostingDate(this.autoSchedulingConfig.daysBeforePosting);
            this.assignInitDays(this.autoSchedulingConfig);
        }
    };
    MasterScheduleHeaderComponent.prototype.assignGenerateDate = function (days) {
        if (days > 0) {
            this.autoSchedulingConfig.generatingDate = this.getLastScheduleCycleDay(days) || '';
        }
    };
    MasterScheduleHeaderComponent.prototype.assignPostingDate = function (days) {
        if (days > 0) {
            this.autoSchedulingConfig.postingDate = this.getLastScheduleCycleDay(days) || '';
        }
    };
    MasterScheduleHeaderComponent.prototype.getLastScheduleCycleDay = function (days) {
        if (this.autoSchedulingConfig.scheduleCycle) {
            var lastScheduleCycleDay = this.autoSchedulingConfig.scheduleCycle.endDate.clone().subtract(days, 'day');
            return lastScheduleCycleDay.format(this.appConfig.dateFormat);
        }
        return null;
    };
    MasterScheduleHeaderComponent.prototype.defineScheduleCycle = function (date, days) {
        if (days > 0) {
            var currentScheduleCycle = this.managementService.getScheduleCycle(date);
            var lastScheduleCycleDay = currentScheduleCycle.endDate.clone().subtract(days, 'day');
            while (!!currentScheduleCycle && lastScheduleCycleDay.isBefore(date)) {
                var newScheduleCycleDay = currentScheduleCycle.endDate.clone().add(1, 'day');
                currentScheduleCycle = this.managementService.getScheduleCycle(newScheduleCycleDay.toDate());
                if (!!currentScheduleCycle) {
                    lastScheduleCycleDay = currentScheduleCycle.endDate.clone().subtract(days, 'day');
                }
            }
            this.autoSchedulingConfig.scheduleCycle = currentScheduleCycle;
        }
    };
    MasterScheduleHeaderComponent.prototype.hasAutoSchedulingErrors = function () {
        return this.autoSchedulingConfig.postingDateBeforeGenerating || this.autoSchedulingConfig.postingDateInPast;
    };
    MasterScheduleHeaderComponent.prototype.checkPostingDateValidity = function () {
        var generatingDate = _.get(this.autoSchedulingConfig, 'generatingDate');
        var postingDate = _.get(this.autoSchedulingConfig, 'postingDate');
        this.autoSchedulingConfig.postingDateInPast = moment(new Date()).isAfter(new Date(postingDate));
        this.autoSchedulingConfig.postingDateBeforeGenerating = false;
        if (!this.autoSchedulingConfig.postingDateInPast && _.size(generatingDate) > 0 && _.size(postingDate) > 0) {
            this.autoSchedulingConfig.postingDateBeforeGenerating = moment(postingDate, this.appConfig.dateFormat).isSameOrBefore(new Date(generatingDate));
        }
    };
    MasterScheduleHeaderComponent.prototype.assignInitDays = function (config) {
        this.daysBeforeGenerating = config.daysBeforeGenerating > 0 ?
            {
                id: config.daysBeforeGenerating,
                day: config.daysBeforeGenerating
            }
            :
                null;
        this.daysBeforePosting = config.daysBeforePosting > 0 ?
            {
                id: config.daysBeforePosting,
                day: config.daysBeforePosting
            }
            :
                null;
    };
    MasterScheduleHeaderComponent.prototype.quickEditToggle = function () {
        if (!this.isQuickEditActive) {
            this.masterScheduleQuickEditService.activateQuickEdit();
        }
        else {
            this.masterScheduleQuickEditService.deactivateQuickEdit();
        }
    };
    MasterScheduleHeaderComponent.prototype.quickEditClose = function () {
        this.masterScheduleQuickEditService.deactivateQuickEdit();
    };
    MasterScheduleHeaderComponent.prototype.stopPropagation = function ($event) {
        if (this.state.isSelectPropagated) {
            $event.stopPropagation();
        }
        else {
            this.state.isSelectPropagated = true;
        }
    };
    MasterScheduleHeaderComponent.prototype.onActionChanged = function (action) {
        var _this = this;
        var startDate = moment(this.managementService.dateFrom).format("MM-DD-YYYY");
        var endDate = moment(this.managementService.endDate).format("MM-DD-YYYY");
        var OrgLevel = this.managementService.currentOrgLevelId;
        var message = this.actions.canEditForApprovedPayPeriod ?
            "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the \n        employee, are you sure you want to continue?" : "You do not have permissions to modify a \n        schedule in an approved pay period";
        var popupOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = this.actions.canEditForApprovedPayPeriod ? true : false;
        popupOptions.buttonOKtext = 'Ok';
        popupOptions.buttonCanceltext = this.actions.canEditForApprovedPayPeriod ? 'Cancel' : 'Ok';
        switch (action) {
            case 'post':
                if (this.isStartCycle(this.managementService.dateFrom)) {
                    var navService = new PostScheduleNavigationService(this.router, this.activatedRoute);
                    navService.navigateToOpenShiftManagement(this.managementService.dateFrom);
                }
                break;
            case 'showSummary':
                var promise = Promise.resolve(this.summary);
                if (!this.summary) {
                    promise = this.scheduleApiService.loadScheduleSummaryMessages(this.managementService.currentOrgLevelId, this.managementService.dateFrom, this.managementService.endDate);
                }
                promise.then(function (s) {
                    if (!s.scheduleCycleStartDate || !s.scheduleCycleEndDate) {
                        s.scheduleCycleStartDate = _this.managementService.dateFrom;
                        s.scheduleCycleEndDate = _this.managementService.endDate;
                    }
                    ScheduleCycleSummaryDialogComponent.openDialog(s, _this.modalService, function (action) { });
                });
                break;
            case 'generate':
                if (this.isModifyPayPeriodApproved) {
                    this.ScheduleEntryApiService.checkApprovedPayperiodForOrgLevel(OrgLevel, startDate, endDate).then(function (data) {
                        _this.approvedPayPeriod = data;
                        if (_this.approvedPayPeriod == true) {
                            ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                                if (result) {
                                    var options = new ConfirmOptions();
                                    options.showCancel = false;
                                    options.showOK = true;
                                    if (_this.isPosted) {
                                        ConfirmDialogComponent.openDialog('Confirmation', 'This schedule has already been posted, are you sure you want to re-generate?', _this.modalService, function (result) {
                                            if (result) {
                                                _this.doGenerateSchedule();
                                            }
                                        }, options);
                                    }
                                    else {
                                        _this.doGenerateSchedule();
                                    }
                                }
                            }, popupOptions);
                        }
                        else {
                            _this.doGenerateSchedule();
                        }
                    });
                }
                else {
                    this.doGenerateSchedule();
                }
                break;
            case 'delete':
                this.ScheduleEntryApiService.checkApprovedPayperiodForOrgLevel(OrgLevel, startDate, endDate).then(function (data) {
                    _this.approvedPayPeriod = data;
                    if (_this.approvedPayPeriod == true && _this.isModifyPayPeriodApproved) {
                        ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                            if (result) {
                                _this.managementService.onLoadStatusChanged(true);
                                _this.scheduleApiService.deleteScheduleForDepartment(_this.selectedOrgLevel, _this.managementService.dateFrom, _this.managementService.endDate)
                                    .then(function () { return _this.onFetchSchedule.emit(null); });
                            }
                        }, popupOptions);
                    }
                    else {
                        _this.managementService.onLoadStatusChanged(true);
                        _this.scheduleApiService.deleteScheduleForDepartment(_this.selectedOrgLevel, _this.managementService.dateFrom, _this.managementService.endDate)
                            .then(function () { return _this.onFetchSchedule.emit(null); });
                    }
                });
                break;
            case 'backup':
                this.scheduleApiService.backupSchedule(this.managementService.dateFrom, this.managementService.endDate, this.selectedOrgLevel)
                    .then(function () { return _this.notificationsService.success(appMessages.success.scheduleBackupCreated.title, appMessages.success.scheduleBackupCreated.message); });
                break;
            case 'restore':
                this.ScheduleEntryApiService.checkApprovedPayperiodForOrgLevel(OrgLevel, startDate, endDate).then(function (data) {
                    _this.approvedPayPeriod = data;
                    if (_this.approvedPayPeriod == true && _this.isModifyPayPeriodApproved) {
                        ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                            if (result) {
                                var dialogOptions = new DialogOptions2();
                                dialogOptions.fullHeightOnMobile = true;
                                dialogOptions.modeSize = DialogModeSize.grid;
                                var restoreComponent = _this.modalService.globalAnchor.openDialog2(RestoreScheduleComponent, 'Restore Schedule', dialogOptions, null, function (result) {
                                    if (result) {
                                        _this.onFetchSchedule.emit(null);
                                    }
                                });
                            }
                        }, popupOptions);
                    }
                    else {
                        var dialogOptions = new DialogOptions2();
                        dialogOptions.fullHeightOnMobile = true;
                        dialogOptions.modeSize = DialogModeSize.grid;
                        var restoreComponent = _this.modalService.globalAnchor.openDialog2(RestoreScheduleComponent, 'Restore Schedule', dialogOptions, null, function (result) {
                            if (result) {
                                _this.onFetchSchedule.emit(null);
                            }
                        });
                    }
                });
                break;
            case 'export':
                this.onExportSchedule.emit();
                break;
        }
    };
    MasterScheduleHeaderComponent.prototype.onQuickWindowDragEnd = function () {
        var offset = 70;
        if (this.quickEditWindowTop < 0) {
            this.quickEditWindowTop = 0;
        }
        else if (this.quickEditWindowTop > window.innerHeight - this.quickEditHeight - offset) {
            this.quickEditWindowTop = window.innerHeight - this.quickEditHeight - offset;
        }
        if (this.quickEditWindowLeft < 0) {
            this.quickEditWindowLeft = 0;
        }
        else if (this.quickEditWindowLeft > window.innerWidth - this.quickEditWidth - offset) {
            this.quickEditWindowLeft = window.innerWidth - this.quickEditWidth - offset;
        }
    };
    MasterScheduleHeaderComponent.prototype.checkIsFiltered = function () {
        if (this.filters.filters.positionGroup && this.filters.filters.positionGroup.id) {
            this.isFiltered = true;
            return;
        }
        if (this.filters.filters.position && this.filters.filters.position.id) {
            this.isFiltered = true;
            return;
        }
        if (this.filters.filters.shiftGroup && this.filters.filters.shiftGroup.id) {
            this.isFiltered = true;
            return;
        }
        if (this.filters.filters.unit && this.filters.filters.unit.id) {
            this.isFiltered = true;
            return;
        }
        if (this.filters.filters.employeeType && this.filters.filters.employeeType.length > 0) {
            this.isFiltered = true;
            return;
        }
        this.isFiltered = false;
    };
    MasterScheduleHeaderComponent.prototype.doGenerateSchedule = function () {
        var _this = this;
        this.managementService.onLoadStatusChanged(true);
        this.scheduleApiService.generateScheduleAtBackend(this.selectedOrgLevel.id, this.managementService.dateFrom)
            .then(function (summary) {
            _this.summary = summary;
            _this.managementService.onLoadStatusChanged(false);
            _this.onFetchSchedule.emit(summary.schedule);
            if (summary.messages && summary.messages.length > 0) {
                _this.showSummary();
            }
        });
    };
    MasterScheduleHeaderComponent.prototype.showSummary = function () {
        var _this = this;
        ScheduleCycleSummaryDialogComponent.openDialog(this.summary, this.modalService, function (action) {
            if (!action) {
                return;
            }
            if (action.action === 'NavigateToEmployee') {
                var es = new EmployeeSectionNavigationService(_this.router, _this.activatedRoute);
                es.NavigateToEmployeeSections(action.employeeId, false);
            }
            if (action.action === 'NavigateToScheduleEntry') {
                var se = new ScheduleEntryNavigationService(_this.router, _this.activatedRoute);
                se.NavigateToScheduleEntry(action.employeeId, action.date);
            }
        });
    };
    MasterScheduleHeaderComponent.prototype.showQuickEditSummary = function () {
        var _this = this;
        if (!this.quickEditSummary || !this.quickEditSummary.messages || this.quickEditSummary.messages.length === 0) {
            return;
        }
        ScheduleCycleSummaryDialogComponent.openDialog(this.quickEditSummary, this.modalService, function (action) {
            if (!action) {
                return;
            }
            if (action.action === 'NavigateToEmployee') {
                var es = new EmployeeSectionNavigationService(_this.router, _this.activatedRoute);
                es.NavigateToEmployeeSections(action.employeeId, false);
            }
            if (action.action === 'NavigateToScheduleEntry') {
                var se = new ScheduleEntryNavigationService(_this.router, _this.activatedRoute);
                se.NavigateToScheduleEntry(action.employeeId, action.date);
            }
        });
    };
    MasterScheduleHeaderComponent.prototype.restorePositionGroupPreset = function () {
        var _this = this;
        if (this.filters && this.filters.filters.positionGroup && this.positionGroupLookup) {
            if (!_.find(this.positionGroupLookup, function (p) { return p.id === _this.filters.filters.positionGroup.id; })) {
                if (this.filters.filters.positionGroup.id !== null) {
                    this.positionGroupLookup.push(this.filters.filters.positionGroup);
                }
            }
        }
    };
    MasterScheduleHeaderComponent.prototype.restorePositionPreset = function () {
        var _this = this;
        if (this.filters && this.filters.filters.position && this.positionLookup) {
            if (!_.find(this.positionLookup, function (p) { return p.id === _this.filters.filters.position.id; })) {
                if (this.filters.filters.position.id !== null) {
                    this.positionLookup.push(this.filters.filters.position);
                }
            }
        }
    };
    MasterScheduleHeaderComponent.prototype.restoreEmpTypesPreset = function () {
        var _this = this;
        if (!this.filters || !this.employeeTypeLookup) {
            return;
        }
        _.forEach(this.filters.filters.employeeType, function (empFilter) {
            if (!_.find(_this.employeeTypeLookup, function (et) { return et.name === empFilter.name; })) {
                _this.employeeTypeLookup.push(empFilter);
            }
        });
    };
    MasterScheduleHeaderComponent.prototype.restoreUnitsPreset = function () {
        var _this = this;
        if (this.filters && this.filters.filters.unit && this.unitLookup) {
            if (!_.find(this.unitLookup, function (p) { return p.id === _this.filters.filters.unit.id; })) {
                if (this.filters.filters.unit.id !== null) {
                    this.unitLookup.push(this.filters.filters.unit);
                }
            }
        }
    };
    MasterScheduleHeaderComponent.prototype.restoreShiftGroupPreset = function () {
        var _this = this;
        if (this.filters && this.filters.filters.shiftGroup && this.shiftGroupLookup) {
            if (!_.find(this.shiftGroupLookup, function (p) { return p.id === _this.filters.filters.shiftGroup.id; })) {
                if (this.filters.filters.shiftGroup.id !== null) {
                    this.shiftGroupLookup.push(this.filters.filters.shiftGroup);
                }
            }
        }
    };
    MasterScheduleHeaderComponent.prototype.getActionCaption = function (dateFrom) {
        return !this.isStartCycle(dateFrom) ? "This action may only be performed per scheduling period. To enable this action, first use the date selector to set the appropriate Start and End Dates for a scheduling period." : "";
    };
    MasterScheduleHeaderComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleHeaderComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "orgLevelSubscriptionFilters", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "recalculatedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "filterSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "quickEditActiveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "quickEditSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "autoSchedulingSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "autoSchedulingLoadingSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "actionsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "scheduleCyclesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MasterScheduleHeaderComponent.prototype, "dataLoadedSubscription", void 0);
    return MasterScheduleHeaderComponent;
}());
export { MasterScheduleHeaderComponent };
