import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BaseResolver, LoaCategory, LoaActions } from '../../../../common/index';
var LoaFilterResolver = /** @class */ (function (_super) {
    tslib_1.__extends(LoaFilterResolver, _super);
    function LoaFilterResolver(router) {
        return _super.call(this, router) || this;
    }
    LoaFilterResolver.prototype.resolve = function (route, state) {
        var category = _.toString(route.queryParamMap.get('category'));
        var type = _.toString(route.queryParamMap.get('type'));
        var action = _.toString(route.queryParamMap.get('action'));
        var empId = _.toNumber(route.queryParamMap.get('empId'));
        if (!category && !type && !action && !empId) {
            return null;
        }
        var cat = category ? LoaCategory[category] : null;
        var act = action ? LoaActions[action] : null;
        var res = {
            category: cat,
            loaType: type,
            action: act,
            empId: empId
        };
        return Promise.resolve(res);
    };
    return LoaFilterResolver;
}(BaseResolver));
export { LoaFilterResolver };
