import * as tslib_1 from "tslib";
export var EmployeeWarningSections;
(function (EmployeeWarningSections) {
    EmployeeWarningSections["MainSection"] = "MainSection";
    EmployeeWarningSections["StandardViolations"] = "StandardViolations";
    EmployeeWarningSections["StandardActions"] = "StandardActions";
    EmployeeWarningSections["StandardCompanyRemarks"] = "StandardCompanyRemarks";
    EmployeeWarningSections["StandardEmployeeRemarks"] = "StandardEmployeeRemarks";
    EmployeeWarningSections["CustomViolationsAB"] = "ViolationsAB";
    EmployeeWarningSections["CustomIncidentDescription"] = "IncidentDescription";
})(EmployeeWarningSections || (EmployeeWarningSections = {}));
var EmployeeWarningSectionBasic = /** @class */ (function () {
    function EmployeeWarningSectionBasic(type, label) {
        this.type = type;
        this.label = label;
    }
    Object.defineProperty(EmployeeWarningSectionBasic.prototype, "isStandardViolations", {
        get: function () {
            return this.type === EmployeeWarningSections.StandardViolations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeWarningSectionBasic.prototype, "isStandardActions", {
        get: function () {
            return this.type === EmployeeWarningSections.StandardActions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeWarningSectionBasic.prototype, "isStandardCompanyRemarks", {
        get: function () {
            return this.type === EmployeeWarningSections.StandardCompanyRemarks;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeWarningSectionBasic.prototype, "isStandardEmployeeRemarks", {
        get: function () {
            return this.type === EmployeeWarningSections.StandardEmployeeRemarks;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeWarningSectionBasic.prototype, "isCustomViolationsAB", {
        get: function () {
            return this.type === EmployeeWarningSections.CustomViolationsAB;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeWarningSectionBasic.prototype, "isCustomIncidentDescription", {
        get: function () {
            return this.type === EmployeeWarningSections.CustomIncidentDescription;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeWarningSectionBasic;
}());
export { EmployeeWarningSectionBasic };
var EmployeeWarningViolation = /** @class */ (function () {
    function EmployeeWarningViolation(id, label, selected) {
        this.id = id;
        this.label = label;
        this.selected = selected;
    }
    return EmployeeWarningViolation;
}());
export { EmployeeWarningViolation };
var EmployeeWarningAction = /** @class */ (function () {
    function EmployeeWarningAction(id, label, selected, notes) {
        this.id = id;
        this.label = label;
        this.selected = selected;
        this.notes = notes;
    }
    return EmployeeWarningAction;
}());
export { EmployeeWarningAction };
var EmployeeWarningSectionViolations = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeWarningSectionViolations, _super);
    function EmployeeWarningSectionViolations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeWarningSectionViolations;
}(EmployeeWarningSectionBasic));
export { EmployeeWarningSectionViolations };
var EmployeeWarningSectionActions = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeWarningSectionActions, _super);
    function EmployeeWarningSectionActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeWarningSectionActions;
}(EmployeeWarningSectionBasic));
export { EmployeeWarningSectionActions };
var EmployeeWarningSectionCompanyRemarks = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeWarningSectionCompanyRemarks, _super);
    function EmployeeWarningSectionCompanyRemarks() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeWarningSectionCompanyRemarks;
}(EmployeeWarningSectionBasic));
export { EmployeeWarningSectionCompanyRemarks };
var EmployeeWarningSectionEmployeeRemarks = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeWarningSectionEmployeeRemarks, _super);
    function EmployeeWarningSectionEmployeeRemarks() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeWarningSectionEmployeeRemarks;
}(EmployeeWarningSectionBasic));
export { EmployeeWarningSectionEmployeeRemarks };
var EmployeeWarningSectionViolationsAB = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeWarningSectionViolationsAB, _super);
    function EmployeeWarningSectionViolationsAB() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeWarningSectionViolationsAB;
}(EmployeeWarningSectionBasic));
export { EmployeeWarningSectionViolationsAB };
var EmployeeWarningSectionIncidentDescription = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeWarningSectionIncidentDescription, _super);
    function EmployeeWarningSectionIncidentDescription() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeWarningSectionIncidentDescription;
}(EmployeeWarningSectionBasic));
export { EmployeeWarningSectionIncidentDescription };
