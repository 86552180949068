import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { GridComponent, DetailExpandEvent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var KendoGridRowExpandDirective = /** @class */ (function () {
    function KendoGridRowExpandDirective(grid, changeDetector) {
        this.grid = grid;
        this.changeDetector = changeDetector;
        this.mode = 'expand';
        this.expandedRows = {};
    }
    KendoGridRowExpandDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.cellClickSubscription = this.grid.cellClick.asObservable().subscribe(function (clickEvent) {
            if (clickEvent.type !== 'click') {
                return;
            }
            if (!_this.expandedRows[clickEvent.rowIndex]) {
                if (!_this.mode || _this.mode === 'expand' || _this.mode === 'both') {
                    _this.grid.expandRow(clickEvent.rowIndex);
                    var ev = new DetailExpandEvent(undefined);
                    ev.dataItem = clickEvent.dataItem;
                    ev.index = clickEvent.rowIndex;
                    _this.grid.detailExpand.emit(ev);
                    _this.changeDetector.markForCheck();
                    _this.changeDetector.detectChanges();
                }
            }
            else {
                if (_this.mode === 'collapse' || _this.mode === 'both') {
                    _this.grid.collapseRow(clickEvent.rowIndex);
                    _this.changeDetector.markForCheck();
                    _this.changeDetector.detectChanges();
                }
            }
        });
        this.expandChangeSubscription = this.grid.detailExpand.asObservable().subscribe(function (event) {
            _this.expandedRows[event.index] = true;
        });
        this.collapseChangeSubscription = this.grid.detailCollapse.asObservable().subscribe(function (event) {
            _this.expandedRows[event.index] = false;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridRowExpandDirective.prototype, "cellClickSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridRowExpandDirective.prototype, "expandChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridRowExpandDirective.prototype, "collapseChangeSubscription", void 0);
    return KendoGridRowExpandDirective;
}());
export { KendoGridRowExpandDirective };
