export interface IBenefitEmpEnrollment401k {
    id: number;
    lineId: number;
    tierId: number;
    maxEmployeePercentContribution: number;
    limitAmount: number;
}

export class BenefitEmpEnrollment401k {
    public id: number;
    public lineId: number;
    public tierId: number;
    public maxEmployeePercentContribution: number;
    public employeeContribution: number;
    public employeePercentageContribution: number;
    public limitAmount: number;
}