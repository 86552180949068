import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalService, ConfirmOptions, ConfirmDialogComponent } from '../../../../../common/index';
import { OrgStructureCodeService } from '../../../../services/payroll/code-mapping/org-structure-code.service';
import { unsubscribe, mutableSelect } from '../../../../../core/decorators/index';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
var OrgStructureCodeComponent = /** @class */ (function () {
    function OrgStructureCodeComponent(modalService, orgStructureCodeService) {
        this.modalService = modalService;
        this.orgStructureCodeService = orgStructureCodeService;
        this.tabData = {
            Organization: [],
            Departments: [],
            Positions: [],
            userActionsForCodeMapping: {
                "canEditCodeMapping": false,
                "canViewCodeMapping": false
            }
        };
        this.saveRequestBody = {
            Organization: [],
            Departments: [],
            Positions: []
        };
        this.tempRequestBody = {
            Organization: [],
            Departments: [],
            Positions: []
        };
        this.isGlobalSaveEnable = false;
        this.parentLevelName = '';
        this.isReadOnly = false;
    }
    OrgStructureCodeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
        });
        this.getOrgStructureCodes('select');
        this.orgStructureCodeService.getCodeMappingData().subscribe(function (res) {
            _this.tabData = res;
        });
    };
    OrgStructureCodeComponent.prototype.getOrgStructureCodes = function (type) {
        var _this = this;
        this.orgStructureCodeService
            .getOrgStructureCodes(type, this.orgLevelId)
            .then(function (res) {
            if (res && res['data']) {
                _this.tabData.Organization = res['data']['organization'] || [];
                _this.tabData.Departments = res['data']['departments'] || [];
                _this.tabData.Positions = res['data']['positions'] || [];
                _this.isReadOnly = res['data']['userActionsForCodeMapping']['canEditCodeMapping'] ? true : false;
            }
        })
            .catch(function (err) { return console.error(err); });
    };
    OrgStructureCodeComponent.prototype.saveDataHandler = function (data) {
        var formGroup = data.formGroup, rowIndex = data.rowIndex, tabName = data.tabName;
        var updatedVal = {};
        if (data && this.tabData[tabName].length) {
            if (tabName === 'Positions') {
                this.tabData[tabName][rowIndex]['shortCode'] = formGroup.value.ShortCode;
                this.tabData[tabName][rowIndex]['payrollDescription'] = formGroup.value.PayrollDescription;
                this.tabData[tabName][rowIndex]['isSaved'] = false;
            }
            else {
                this.tabData[tabName][rowIndex]['shortCode'] = formGroup.value.ShortCode;
            }
            updatedVal = this.tabData[tabName][rowIndex];
            updatedVal['isActive'] = true;
            var checkDuplicate = _.findIndex(this.tempRequestBody[tabName], function (el) { return el.rowIndex === rowIndex; });
            if (checkDuplicate !== -1) {
                this.tempRequestBody[tabName].splice(checkDuplicate, 1, tslib_1.__assign({}, updatedVal, { 'rowIndex': rowIndex }));
                this.saveRequestBody[tabName].splice(checkDuplicate, 1, updatedVal);
            }
            else {
                this.tempRequestBody[tabName].push(tslib_1.__assign({}, updatedVal, { 'rowIndex': rowIndex }));
                this.saveRequestBody[tabName].push(updatedVal);
            }
        }
    };
    OrgStructureCodeComponent.prototype.saveShortCode = function () {
        var _this = this;
        if (this.saveRequestBody.Departments.length === 0 && this.saveRequestBody.Organization.length === 0 && this.saveRequestBody.Positions.length === 0)
            return;
        var options = new ConfirmOptions();
        options.height = 230;
        options.showCancel = true;
        options.showOK = true;
        options.buttonCanceltext = 'Discard';
        options.buttonOKtext = 'Yes';
        ConfirmDialogComponent.openDialog('Confirmation', 'The changes you are about to make are permanent and can’t be updated without reaching out to Smartlinx Support.', this.modalService, function (result) {
            if (result) {
                _this.saveData(_this.saveRequestBody);
            }
        }, options);
    };
    OrgStructureCodeComponent.prototype.saveData = function (reqestBody) {
        var _this = this;
        this.orgStructureCodeService.saveDataHandler(reqestBody).then(function (res) {
            if (res && res['data']) {
                _this.getOrgStructureCodes('select');
                _this.saveRequestBody = {
                    Organization: [],
                    Departments: [],
                    Positions: []
                };
                _this.tempRequestBody = {
                    Organization: [],
                    Departments: [],
                    Positions: []
                };
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], OrgStructureCodeComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrgStructureCodeComponent.prototype, "orgLevelSubscription", void 0);
    return OrgStructureCodeComponent;
}());
export { OrgStructureCodeComponent };
