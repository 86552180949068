<div class="container-fluid screen">
  <div class="row toggle"  *ngIf="!nextgenPhasedRollout">
    <div class="col-3">
        <slx-checkbox-toggle
          class="active-only-field tog"
          className="toggle-right"
          caption="Migration Sync:"
          [(ngModel)]="migration"
          (ngModelChange)="post()"
          checkedText="Yes"
          uncheckedText="No"
          [isDisabled]="isMigrationDisable"
        ></slx-checkbox-toggle>
    </div>
    <div class="col-3">
      <slx-checkbox-toggle
        class="active-only-field tog"
        caption="Ongoing Sync:"
        className="toggle-right"
        [(ngModel)]="onGoing"
        (ngModelChange)="post()"
        [isDisabled]="isMigrationDisable"
        checkedText="Yes"
        uncheckedText="No"
      ></slx-checkbox-toggle>
  </div>
  <div class="col-3">
    <slx-checkbox-toggle
      class="active-only-field tog"
      className="toggle-right"
      caption="Pause Sync:"
      [(ngModel)]="pause"
      (ngModelChange)="post()"
      checkedText="Yes"
      uncheckedText="No"
    ></slx-checkbox-toggle>
</div>
<div class="col-3">
  <slx-checkbox-toggle
    class="active-only-field tog"
    className="toggle-right"
    caption="Stop Sync:"
    [(ngModel)]="stop"
    (ngModelChange)="stopData()"
    checkedText="Yes"
    uncheckedText="No"
  ></slx-checkbox-toggle>
</div>
  </div>
  <div class="row graph">
    <div class="col-4 dat">
      <slx-org-migration></slx-org-migration>
    </div>
    <div class="col-4 dat">
      <slx-department-dashboard-migration></slx-department-dashboard-migration>
    </div>
    <div class="col-4 dat">
      <slx-position-dashboard-migration></slx-position-dashboard-migration>
    </div>
  </div>
  <div class="row graph">
    <div class="col-4 dat">
      <slx-employee-dashboard-migration></slx-employee-dashboard-migration>
    </div>

    <div class="col-4 dat">
      <slx-benefit-dashboard-migration></slx-benefit-dashboard-migration>
    </div>
    <div class="col-4 dat">
      <slx-costcenter-dashboard-migration></slx-costcenter-dashboard-migration>
    </div>
  </div>
  <div class="row graph" *ngIf="nextgenPhasedRollout && isNextgenPayrollEnabled">
    <div class="col-4 dat">
      <slx-org-payrollappstatus-migration></slx-org-payrollappstatus-migration>
    </div>
    <div class="col-4 dat">
      <slx-employee-payroll-app-status-migration></slx-employee-payroll-app-status-migration>
    </div>
  </div>
  <div class="row graph">
    <div class="dat1">
      <slx-summary-dashboard-migration></slx-summary-dashboard-migration>
    </div>
  </div>
</div>
