import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { AcaMeasurement, EmployeeFilterRecord, AcaMeasurementRecord, EmployeeFilter, PeriodsPopupModel, filterAcaMeasurementRecord } from '../../models/index';
import { initialAcaMeasurementState, AcaMeasurementFilterState } from '../../models/aca-measurement/aca-measurement-state';
import { AcaMeasurementApiService } from './aca-measurement-api.service';
import { AcaMeasurementSettings, AcaMeasurementColumnsSettings } from '../../models/aca-measurement/aca-measurement-settings';
import { ColumnManagementService, StateManagementService } from '../../../common';
import { StateResetTypes } from '../../../core/models';
var AcaMeasurementManagementService = /** @class */ (function () {
    function AcaMeasurementManagementService(apiService, columnManagement, stateManagement) {
        this.apiService = apiService;
        this.columnManagement = columnManagement;
        this.stateManagement = stateManagement;
        this.filter = [];
        this.employeeIdList = [];
        this.expandAll = false;
        this.acaFilterState = new AcaMeasurementFilterState();
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.employeeFilter$ = new Subject();
        this.employeeFilterRecords$ = new ReplaySubject(1);
        this.exportTo$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.stateChanged$ = new BehaviorSubject(initialAcaMeasurementState);
        this.employeeIdList$ = new ReplaySubject(1);
        this.togglePopper$ = new ReplaySubject(1);
        this.currentYear$ = new ReplaySubject(1);
        this.insightsLoaded$ = new Subject();
        this.acaFilterRecord$ = new Subject();
        this.stateKey = 'AcaMeasurementState';
        this.isExpandAll$ = new Subject();
        this.subscriptions = {};
        this.onSettingsChanged$ = new ReplaySubject(1);
    }
    AcaMeasurementManagementService.prototype.init = function () {
        this.subscribeToOrgLevelChanges();
        this.columnSettingsChanged();
    };
    AcaMeasurementManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.employeeFilterRecords$.complete();
        this.exportTo$.complete();
        this.orgLevelChanged$.complete();
        this.stateChanged$.complete();
        this.employeeIdList$.complete();
        this.togglePopper$.complete();
        this.currentYear$.complete();
        this.insightsLoaded$.complete();
        this.acaFilterRecord$.complete();
        this.employeeFilter$.complete();
        this.isExpandAll$.complete();
    };
    AcaMeasurementManagementService.prototype.updateState = function (state) {
        this.stateChanged$.next(state);
    };
    AcaMeasurementManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    AcaMeasurementManagementService.prototype.isExpandAll = function (isExpand) {
        this.expandAll = isExpand;
        this.setMeasurementState();
        this.restoreState();
        this.isExpandAll$.next(isExpand);
    };
    AcaMeasurementManagementService.prototype.subscribeToState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.stateChanged$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToIsExpandAll = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.isExpandAll$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToEmpFilterRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilterRecords$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToEmployeeFilter = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilter$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToEmployeeIdList = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.employeeIdList$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToTogglePopper = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.togglePopper$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToCurrentYear = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.currentYear$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.subscribeToLoadedInsights = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.insightsLoaded$.subscribe(callback);
    };
    AcaMeasurementManagementService.prototype.loadAcaMeasurement = function (year, acaFilterState) {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
            return;
        }
        this.loading$.next(true);
        this.apiService.getAcaMeasurement(this.orgLevel.id, year)
            .then(function (container) {
            if (acaFilterState) {
                var filteredRecords = _.forEach(container.records, function (r) {
                    if (acaFilterState.isExpandAll) {
                        r.expand = true;
                    }
                    else {
                        r.expand = false;
                        if (acaFilterState.employeeIdList) {
                            _.forEach(acaFilterState.employeeIdList, function (k) {
                                if (k.employeeId === r.empId) {
                                    r.expand = true;
                                    return false;
                                }
                            });
                        }
                    }
                });
                container.records = filteredRecords;
            }
            _this.originalContainer = container;
            _this.recordsLoaded$.next(container);
            _this.insightsLoaded$.next(container.insightSummary);
            var employeeFilter = new EmployeeFilter();
            employeeFilter.employeeFilterRecord = [];
            employeeFilter.filter = [];
            if (container && container.records) {
                _.forEach(container.records, function (item) {
                    var empItem = new EmployeeFilterRecord();
                    empItem.id = item.empId;
                    empItem.name = item.empName;
                    employeeFilter.employeeFilterRecord.push(empItem);
                });
                if (acaFilterState && acaFilterState.employeeListFilter) {
                    employeeFilter.filter = acaFilterState.employeeListFilter;
                }
                _this.employeeFilter$.next(employeeFilter);
            }
            _this.loading$.next(false);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    AcaMeasurementManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            if (_this.selectedYear) {
                _this.restoreState();
            }
        });
    };
    AcaMeasurementManagementService.prototype.settingsChanged = function (columnsSettings) {
        this.onSettingsChanged$.next(columnsSettings);
    };
    AcaMeasurementManagementService.prototype.columnSettingsChanged = function () {
        var _this = this;
        this.subscriptions.changedColumns = this.columnManagement.columnsChanged$.filter(function (event) { return event.group === 'AcaMeasurement'; })
            .subscribe(function (event) {
            var columnsSettings = new AcaMeasurementSettings();
            columnsSettings.columns = new AcaMeasurementColumnsSettings();
            columnsSettings.columns.columns = [];
            columnsSettings.columns.columns = event.columns;
            columnsSettings.columns.mapColumns();
            _this.onSettingsChanged$.next(columnsSettings);
        });
    };
    AcaMeasurementManagementService.prototype.changeEmployeeFilter = function (filters) {
        this.employeeFilterRecords$.next(filters);
    };
    AcaMeasurementManagementService.prototype.setEmployeeIdList = function (item) {
        if (this.employeeIdList.indexOf(item) === -1) {
            if (item.expand) {
                this.employeeIdList.push(item);
            }
            else {
                var list = _.find(this.employeeIdList, { 'employeeId': item.employeeId });
                this.employeeIdList = _.without(this.employeeIdList, list);
            }
            this.setMeasurementState();
        }
    };
    AcaMeasurementManagementService.prototype.togglePopper = function (state) {
        this.togglePopper$.next(state);
    };
    AcaMeasurementManagementService.prototype.setCurrentYear = function (state) {
        this.currentYear$.next(state);
    };
    AcaMeasurementManagementService.prototype.filterAcaRecords = function (acaFilterRecord) {
        this.acaFilterRecord$.next(acaFilterRecord);
    };
    AcaMeasurementManagementService.prototype.setEmployeeFilterRecords = function (empFilter) {
        this.employeeFilter$.next(empFilter);
    };
    AcaMeasurementManagementService.prototype.setSelectedYear = function (selYear) {
        this.selectedYear = selYear;
        this.isExpandAll$.next(this.expandAll);
        this.setCurrentYear(selYear);
        this.restoreState();
    };
    AcaMeasurementManagementService.prototype.setInsightsFilter = function (insightsFilter) {
        this.acaInsightFilter = insightsFilter;
        this.applyInsightFilter();
    };
    AcaMeasurementManagementService.prototype.setEmployeeFilter = function (filters) {
        this.filter = filters;
        this.setMeasurementState();
        this.changeEmployeeFilter(filters);
    };
    AcaMeasurementManagementService.prototype.applyInsightFilter = function () {
        var _this = this;
        var container = _.clone(this.originalContainer);
        var records = container.records;
        if (this.acaInsightFilter && this.acaInsightFilter.isClicked) {
            records = _.filter(records, function (r) {
                var res = false;
                if (_this.acaInsightFilter.isCurrentMonth && _this.acaInsightFilter.isMP && !_.isNull(r.currentMP)) {
                    res = (_this.acaInsightFilter.type == "TDE" && (r.currentMP.ptDeterminedEligible || r.currentMP.ftDeterminedEligible))
                        || (_this.acaInsightFilter.type == "PTE" && r.currentMP.ptDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTE" && r.currentMP.ftDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTI" && r.currentMP.ftDeterminedIneligible);
                }
                else if (_this.acaInsightFilter.isCurrentMonth && !_this.acaInsightFilter.isMP && !_.isNull(r.currentAP)) {
                    res = (_this.acaInsightFilter.type == "TDE" && (r.currentAP.ptDeterminedEligible || r.currentAP.ftDeterminedEligible))
                        || (_this.acaInsightFilter.type == "PTE" && r.currentAP.ptDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTE" && r.currentAP.ftDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTI" && r.currentAP.ftDeterminedIneligible);
                }
                else if (!_this.acaInsightFilter.isCurrentMonth && _this.acaInsightFilter.isMP && !_.isNull(r.nextMP)) {
                    res = (_this.acaInsightFilter.type == "TDE" && (r.nextMP.ptDeterminedEligible || r.nextMP.ftDeterminedEligible))
                        || (_this.acaInsightFilter.type == "PTE" && r.nextMP.ptDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTE" && r.nextMP.ftDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTI" && r.nextMP.ftDeterminedIneligible);
                }
                else if (!_this.acaInsightFilter.isCurrentMonth && !_this.acaInsightFilter.isMP && !_.isNull(r.nextAP)) {
                    res = (_this.acaInsightFilter.type == "TDE" && (r.nextAP.ptDeterminedEligible || r.nextAP.ftDeterminedEligible))
                        || (_this.acaInsightFilter.type == "PTE" && r.nextAP.ptDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTE" && r.nextAP.ftDeterminedEligible)
                        || (_this.acaInsightFilter.type == "FTI" && r.nextAP.ftDeterminedIneligible);
                }
                return res;
            });
        }
        container.records = records;
        this.recordsLoaded$.next(container);
        var employeeFilter = new EmployeeFilter();
        employeeFilter.employeeFilterRecord = [];
        employeeFilter.filter = [];
        this.employeeFilterRecords$.next(employeeFilter.employeeFilterRecord);
        if (container && container.records) {
            _.forEach(container.records, function (item) {
                var empItem = new EmployeeFilterRecord();
                empItem.id = item.empId;
                empItem.name = item.empName;
                employeeFilter.employeeFilterRecord.push(empItem);
            });
            employeeFilter.filter = [];
            this.filter = [];
            this.setMeasurementState();
            this.employeeFilter$.next(employeeFilter);
        }
    };
    AcaMeasurementManagementService.prototype.setMeasurementState = function () {
        if (!this.selectedYear) {
            return;
        }
        var state = _.clone(initialAcaMeasurementState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        var acaState = new AcaMeasurementFilterState();
        acaState.year = this.selectedYear;
        acaState.orgLevelId = this.orgLevel.id;
        acaState.employeeListFilter = this.filter;
        if (this.employeeIdList && this.employeeIdList.length > 0) {
            acaState.employeeIdList = this.employeeIdList;
        }
        acaState.isExpandAll = this.expandAll;
        if (state && state.acaMeasurementStateList && state.acaMeasurementStateList.length > 0) {
            var index = _.findIndex(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
            if (index >= 0) {
                var item = _.find(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
                state.acaMeasurementStateList = _.without(state.acaMeasurementStateList, item);
                state.acaMeasurementStateList.push(acaState);
            }
            else {
                state.acaMeasurementStateList.push(acaState);
            }
        }
        else {
            state.acaMeasurementStateList = [acaState];
        }
        this.saveState(state);
    };
    AcaMeasurementManagementService.prototype.saveState = function (state) {
        this.stateManagement.setControlState(this.stateKey, {
            value: state
        }, StateResetTypes.None);
    };
    AcaMeasurementManagementService.prototype.restoreState = function () {
        if (!this.selectedYear) {
            return;
        }
        var state = _.clone(initialAcaMeasurementState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        this.expandAll = false;
        this.employeeIdList = [];
        if (state && state.acaMeasurementStateList) {
            if (_.findIndex(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id }) >= 0) {
                this.acaFilterState = _.find(state.acaMeasurementStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
                this.expandAll = this.acaFilterState.isExpandAll;
            }
            else {
                this.acaFilterState = null;
            }
        }
        else {
            this.acaFilterState = null;
        }
        this.loadAcaMeasurement(this.selectedYear, this.acaFilterState);
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], AcaMeasurementManagementService.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], AcaMeasurementManagementService.prototype, "orgLevel$", void 0);
    return AcaMeasurementManagementService;
}());
export { AcaMeasurementManagementService };
