<slx-spinner [show]="isLoading">

  <form #gridForm="ngForm" class="content-form">

    <div class="content-holder">

      <div class="toolbar">

        <p class="flex form__ctrl margin-right">
          <label class="form__label">Previous Beneficiary</label>
          <span class="flex form-dropdown-control">
            <slx-dropdown-list class="slx-wide"
            [defaultItem]="defaultItem"
            [options]="prevBeneficiaries"
            [ngModel]="selectedPrevBeneficiary"
            (ngModelChange)="onChangePrevBeneficiary($event)"
            name="prevBeneficiaryList"
            valueField="id"
            titleField="fullName"
            >
            </slx-dropdown-list>
          </span>
        </p>

        <button type="button" class="toolbar-btn add-primary-btn margin-left" (click)="addPrimary()">Add Primary
          Beneficiary</button>

        <button type="button" class="toolbar-btn add-contingent-btn margin-left" (click)="addContingent()">Add
          Contingent Beneficiary</button>

      </div>

      <div class="controls-holder">


        <kendo-grid #kendoGrid class="ben-kendo-grid"
          [scrollable]="'scrollable'" [selectable]="false"
          [data]="gridState?.view" [height]="350"
          [sortable]="false" [sort]="gridState.state.sort" 
          [skip]="gridState.state.skip"
          [pageable]="false"
          [filterable]="false" [filter]="gridState.state.filter"
          [groupable]="false"
          [slxKendoGridState]="gridState.gridDefinition"
          (dataStateChange)="gridState.dataStateChange($event)"
          (selectionChange)="gridState.selectionChange($event)"
          (stateRestored)="gridState.dataStateChange($event)"
          (edit)="gridState.editHandler($event)"
          (save)="gridState.saveHandler($event)"
          (cancel)="gridState.cancelHandler($event)"
          (remove)="gridState.removeHandler($event)"
          >

          <ng-template kendoGridNoRecordsTemplate>
            <span *ngIf="primCount == 0 && contCount == 0 && !editedItem">No records available.</span>
          </ng-template>

          <kendo-grid-column title="" [sortable]="false" [filterable]="false" width="90">
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <div class="flex">

                <button kendoGridEditCommand type="button" class="theme-iconed-accent-button grid-btn"
                *ngIf="canEdit && !isEditingRow(dataItem)" [disabled]="isEditing"
                >
                  <i class="fas fa-edit" aria-hidden="true"></i>
                </button>

                <button kendoGridRemoveCommand type="button" class="theme-iconed-accent-button grid-btn"
                  *ngIf="canDelete && !isEditingRow(dataItem)" [disabled]="isEditing"
                  >
                  <i class="fas fa-trash-alt" aria-hidden="true"></i>
                </button>

                <button kendoGridSaveCommand type="button" class="theme-iconed-accent-button grid-btn"
                  [disabled]="!gridForm.valid">
                  <i class="fas fa-save" aria-hidden="true"></i>
                </button>

                <button kendoGridCancelCommand type="button" class="theme-iconed-accent-button grid-btn">
                  <i class="fas fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="firstName" title="Firt Name" [width]="150">

            <ng-template kendoGridHeaderTemplate>
              First Name
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
              <span>{{ dataItem.firstName }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

              <slx-input-decorator>

                <input slx-input
                class="ben-grid-input"
                [inputTextForbidSymbols]="namePattern"
                [(ngModel)]="dataItem.firstName"
                placeholder="First Name"
                maxlength="50"
                name="firstName{{rowIndex}}"
                autocomplete="off"
                required
                />

                <span errorMessage for="required"></span>

              </slx-input-decorator>

            </ng-template>

          </kendo-grid-column>

          <kendo-grid-column field="lastName" title="Last Name" [width]="150">

            <ng-template kendoGridHeaderTemplate>
              Last Name
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <span>{{ dataItem.lastName }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

              <slx-input-decorator>

                <input slx-input class="ben-grid-input"
                [inputTextForbidSymbols]="namePattern"
                [(ngModel)]="dataItem.lastName"
                placeholder="Last Name"
                maxlength="50"
                name="lastName{{rowIndex}}"
                autocomplete="off"
                required
                />

                <span errorMessage for="required"></span>

            </slx-input-decorator>

            </ng-template>


          </kendo-grid-column>

          <kendo-grid-column title="Relationship" [width]="120">
            <ng-template kendoGridHeaderTemplate>
              Relationship
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <span>{{ dataItem.relation.name }}</span>
            </ng-template>
            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

              <slx-input-decorator>

                <slx-dropdown-input slx-input
                class="ben-dropdown slx-wide slx-short"
                [options]="relations"
                [(ngModel)]="dataItem.relation"
                valueField="id"
                titleField="name"
                placeholder="Relationship"
                name="relation{{rowIndex}}"
                #relationshipSelect="ngModel"
                required
                ></slx-dropdown-input>

                  <span errorMessage for="required"></span>

              </slx-input-decorator>

            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="birthDate" title="Date of Birth" [width]="180">
            <ng-template kendoGridHeaderTemplate>
              Date of Birth
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <span>{{ dataItem.birthDate | amDateFormat: appConfig.dateFormat }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

              <slx-input-decorator>

                <slx-date-picker-ngx slx-input
                class="ben-datepicker slx-wide slx-short"
                [(ngModel)]="dataItem.birthDate"
                [acceptNullDate]="true"
                placeholder="Date of Birth"
                name="birthDate{{rowIndex}}"
                #birthDate="ngModel"
                ></slx-date-picker-ngx>

                <span errorMessage for="required"></span>
                <span errorMessage for="date"></span>

              </slx-input-decorator>

            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="ssn" title="SSN" [width]="150">

            <ng-template kendoGridHeaderTemplate>
              SSN
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <span>{{ dataItem.ssn }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

              <slx-input-decorator>

                <slx-ssn-input  ssn slx-input
                  class="ben-grid-input slx-wide slx-short"
                  [(ngModel)]="dataItem.ssn"
                  placeholder="SSN"
                  name="ssn{{rowIndex}}"
                  #ssn="ngModel"
                ></slx-ssn-input>

                  <span errorMessage for="required"></span>
                  <span errorMessage for="ssn"></span>
                  <span errorMessage [for]="ssnValidation">msg from server</span>

              </slx-input-decorator>

            </ng-template>

          </kendo-grid-column>

          <kendo-grid-column field="address" title="Address" [width]="200">

            <ng-template kendoGridHeaderTemplate>
              Address
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <span>{{ dataItem.address }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

              <slx-input-decorator>

                <input slx-input
                class="ben-grid-input slx-wide slx-short"
                [inputTextForbidSymbols]="addressPattern"
                [(ngModel)]="dataItem.address"
                placeholder="Address"
                maxlength="50"
                name="address{{rowIndex}}"
                autocomplete="off"
                #address="ngModel"
              />

                <span errorMessage for="required"></span>

            </slx-input-decorator>

            </ng-template>

          </kendo-grid-column>

          <kendo-grid-column field="primPercent" title="Prim. %" [width]="120">

            <ng-template kendoGridHeaderTemplate>
              Prim. %
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <span>{{ dataItem.primaryPercentage }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

                 <slx-number
                  class="ben-grid-input slx-wide"
                  [min]="0" [max]="100" [step]="0.01" [decimals]="2"
                  [slxMin]="0" [slxMax]="100"
                  [format]="'n2'" [autoCorrect]='true'
                  [(ngModel)]="dataItem.primaryPercentage"
                  name="primaryPercentage{{rowIndex}}"
                  (focus)="onStartEditPrimary(dataItem)"
                  #primaryPercentage="ngModel"
                  (ngModelChange)="onPrimaryChange(dataItem)"
                  [readonly]="!dataItem.isPrimary"
                  [required]="dataItem.isPrimary"
                  ></slx-number>

                  <div *ngIf="primaryPercentage.errors" class="slx-error-block">
                      <span *ngIf="primaryPercentage.errors.required" errorMessage for="required"></span>
                      <span *ngIf="primaryPercentage.errors.min" errorMessage for="required"></span>
                  </div>

            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="contPercent" title="Cont. %" [width]="120">

            <ng-template kendoGridHeaderTemplate>
              Cont. %
            </ng-template>

            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
              <span>{{ dataItem.contigentPercentage }}</span>
            </ng-template>

            <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">

              <slx-number
                class="ben-grid-input slx-wide"
                [min]="0" [max]="100" [step]="0.01" [decimals]="2"
                [slxMin]="0" [slxMax]="100"
                [format]="'n2'" [autoCorrect]='true'
                [(ngModel)]="dataItem.contigentPercentage"
                name="contigentPercentage{{rowIndex}}"
                (focus)="onStartEditCont(dataItem)"
                #contigentPercentage="ngModel"
                (ngModelChange)="onContChange(dataItem)"
                [readonly]="dataItem.isPrimary"
                [required]="!dataItem.isPrimary"
                ></slx-number>

                <div *ngIf="contigentPercentage.errors" class="slx-error-block">
                  <span *ngIf="contigentPercentage.errors.required" errorMessage for="required"></span>
                  <span *ngIf="contigentPercentage.errors.min" errorMessage for="min"></span>
                  <span *ngIf="contigentPercentage.errors.max" errorMessage for="max"></span>
                </div>

            </ng-template>

          </kendo-grid-column>

        </kendo-grid>

        <div class="totals">
          
          <span class="spacer">
            <span *ngIf="(primaryTotal != 100 && primCount > 0 ) || (contTotal != 100 && contCount > 0)" class="warning-message red">{{incompleteWarning}}</span>
            <span *ngIf="primCount == 0 && contCount > 0" class="warning-message red">{{noPrimaryWarning}}</span>
          </span>

          <span class="total-item prim-total">
            <span class="totals-label">Primary total:</span>
            <span [ngClass]="{'totals-value': true, 'red': (primaryTotal != 100 && primaryTotal > 0) }">{{primaryTotal}}%</span>
          </span>

          <span class="total-item cont-total">
            <span class="totals-label">Contingent total:</span>
            <span [ngClass]="{'totals-value': true, 'red': (contTotal != 100 && contTotal > 0)}">{{contTotal}}%</span>
          </span>
          
        </div>

      </div>

    </div>
  </form>

</slx-spinner>
