import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../app.config';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { unsubscribe, unsubscribeAll } from '../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { PbjManualEntryManagementService } from '../../../services/pbj-manual-entry/pbj-manual-entry-management.service';
import { Organization, Department, Lookup, LookupType } from '../../../../../organization/models/index';
import { OrgLevelType } from '../../../../../state-model/models/index';
import { OrgLevelWatchService, LookupService, EmployeeDefinitionsApiService } from '../../../../../organization/services/index';
import { ConfirmDialog2Component, ConfirmOptions, ModalService } from '../../../../../common/index';
import { AutocompleteComponent } from '../../../../../components-library/components';
var PbjManualEntryGridComponent = /** @class */ (function () {
    function PbjManualEntryGridComponent(managementService, lookupService, orgLevelWatchService, employeeDefinitionsApiService, modalService) {
        this.modalService = modalService;
        this.mealGeneratedType = 'PBJMeal';
        this.xlsxName = 'PBJ_Manual_Entry_';
        this.pdfName = 'PBJ_Manual_Entry_';
        this.isLoading = false;
        this.manualEntrySubscriptions = {};
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = this.createSorting();
        this.gridState.state.group = [];
        this.managementService = managementService;
        this.lookupService = lookupService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.selectedAll = false;
        this.predefinedOrganization = null;
        this.predefinedDepartment = null;
        this.isPositionReadonly = true;
        this.hours = { min: -24, max: 24 };
        this.actualRange = { startDate: null, endDate: null };
        this.employeesLookup = this.createLookup([], '', '');
        this.organizationsLookup = this.createLookup([], '', '');
        this.departmentsLookup = this.createLookup([], '', '');
        this.positionsLookup = this.createLookup([], '', '');
        this.pageSize = 20;
    }
    Object.defineProperty(PbjManualEntryGridComponent.prototype, "isOrganizationReadonly", {
        get: function () {
            return _.isObject(this.predefinedOrganization);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjManualEntryGridComponent.prototype, "isDepartmentReadonly", {
        get: function () {
            return _.isObject(this.predefinedDepartment);
        },
        enumerable: true,
        configurable: true
    });
    PbjManualEntryGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        var date = new Date().toLocaleDateString();
        this.xlsxName += date + ".xlsx";
        this.pdfName += date + ".pdf";
        this.manualEntrySubscriptions.savePDF = this.managementService.exportToPdf$.subscribe(function () {
            _this.grid.saveAsPDF();
        });
        this.manualEntrySubscriptions.saveExcel = this.managementService.exportToExcel$.subscribe(function () {
            _this.grid.saveAsExcel();
        });
        this.orgLevelSubscription = this.managementService.onOrgLevel$
            .combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$)
            .subscribe(function (value) {
            var orgLevel = value[0];
            _this.orgLevel = orgLevel;
            _this.defineOrgLevels();
        });
        this.onDateRangeSubscription = this.managementService.onFilterDates$
            .subscribe(function (filterDates) {
            _this.filterDates = filterDates;
            _this.actualRange.startDate = filterDates.startDate;
            _this.actualRange.endDate = filterDates.endDate;
        });
        this.onLoadedSubscription = this.managementService.onLoaded$
            .subscribe(function (entity) {
            _this.entity = entity;
            _this.gridState.state.skip = 0;
            _this.gridState.state.take = _this.pageSize;
            _this.resetControls();
            _this.gridState.closeEditor(_this.grid);
            _this.managementService.editStateChanged(true);
            _this.refreshGrid();
        });
        this.onAddEntrySubscription = this.managementService.onAddEntry$
            .subscribe(function (e) {
            var promise = Promise.resolve();
            var entry = _.cloneDeep(e);
            if (entry.employee && entry.position) {
                promise = _this.loadPositions(entry.employee.id, entry);
            }
            else {
                _this.isPositionReadonly = true;
                entry.position = null;
                _this.currentPositions = null;
            }
            promise.then(function () {
                if (e.employee)
                    _this.currentEmployeeId = entry.employee.id;
                if (e.position)
                    _this.assignPositionFromLookup(entry);
                _this.gridState.closeEditor(_this.grid);
                _this.grid.addRow(entry);
                _this.gridState.editedRecord = entry;
                _this.managementService.editStateChanged(false);
            });
        });
        this.onAddedEntrySubscription = this.managementService.onAddedEntry$
            .subscribe(function (entry) {
            _this.entity.entities.push(entry);
            _this.refreshGrid();
        });
        this.onSavedSubscription = this.managementService.onSavedEntry$
            .subscribe(function (entry) {
            var editedEntry = _.find(_this.entity.entities, { 'id': entry.id });
            _.assign(editedEntry, entry);
            _this.refreshGrid();
        });
        this.onRemovedEntriesSubscription = this.managementService.onRemovedEntries$
            .subscribe(function (entriesIds) {
            _.forEach(entriesIds, function (id) {
                var index = _.findIndex(_this.entity.entities, { id: id });
                _this.entity.entities.splice(index, 1);
            });
            _.forEach(_this.entity.entities, function (entry) { return entry._selectedEntry = false; });
            _this.selectedAll = false;
            _this.filteringSelectedEntries();
            _this.refreshGrid();
            _this.managementService.editStateChanged(true);
        });
        this.gridSaveSubscription = this.gridState.onSave$
            .subscribe(function (item) {
            if (item.isNew) {
                _this.managementService.addEntry(item.dataItem);
            }
            else {
                _this.managementService.saveEntry(item.dataItem);
            }
            _this.managementService.editStateChanged(true);
            // After saving we should reset all controls to initial state
            _this.resetControls();
        });
        this.gridEditSubscription = this.gridState.onEdit$
            .subscribe(function (entry) {
            if (entry && entry.employee) {
                _this.currentEmployeeId = entry.employee.id;
            }
            var employeeSource = null;
            if (_this.currentDepartment) {
                employeeSource = _this.currentDepartment.orgLevelId;
            }
            else if (_this.currentOrganization) {
                employeeSource = _this.currentOrganization.orgLevelId;
            }
            _this.loadEmployees(employeeSource, entry.date).then(function () {
                _this.managementService.editStateChanged(false);
            });
        });
        this.gridCancelSubscription = this.gridState.onCancel$
            .subscribe(function () {
            _this.managementService.editStateChanged(true);
            // After canceling we should reset all controls to initial state
            _this.resetControls();
        });
        this.onEditStateSubscription = this.managementService.onEditState$.subscribe(function (isCanEdit) {
            _this.canAdd = isCanEdit;
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(this.refreshGrid.bind(this));
    };
    PbjManualEntryGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PbjManualEntryGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.entity.entities, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    PbjManualEntryGridComponent.prototype.isEntryEditable = function (dataItem) {
        if (dataItem) {
            return dataItem.entryType !== this.mealGeneratedType;
        }
        return false;
    };
    PbjManualEntryGridComponent.prototype.getMinDate = function (item) {
        if (this.actualRange.startDate === null)
            return null;
        if (item !== null && item.employee !== null && item.employee.dateHired !== null && moment(item.employee.dateHired).isAfter(this.actualRange.startDate)) {
            return item.employee.dateHired;
        }
        return moment(this.actualRange.startDate).startOf('day').toDate();
    };
    PbjManualEntryGridComponent.prototype.getMaxDate = function (item) {
        if (this.actualRange.endDate === null)
            return null;
        return this.actualRange.endDate;
    };
    PbjManualEntryGridComponent.prototype.onClone = function (dataItem) {
        this.managementService.cloneEntry(dataItem);
    };
    PbjManualEntryGridComponent.prototype.onSelectedEntry = function (dataItem, isSelected) {
        dataItem._selectedEntry = isSelected;
        this.updateSelectionState();
    };
    PbjManualEntryGridComponent.prototype.updateSelectionState = function () {
        var _this = this;
        var selectedCount = 0;
        var editableCount = 0;
        var isAllSelected = false;
        if (this.entity) {
            _.forEach(this.entity.entities, function (entry) {
                if (_this.isEntryEditable(entry)) {
                    editableCount++;
                    if (entry._selectedEntry) {
                        selectedCount++;
                    }
                }
                else {
                    entry._selectedEntry = false;
                }
            });
            isAllSelected = selectedCount === editableCount;
        }
        else {
            isAllSelected = false;
        }
        this.selectedAll = isAllSelected;
        this.filteringSelectedEntries();
    };
    PbjManualEntryGridComponent.prototype.onSelectedEntries = function (isAllSelected) {
        var _this = this;
        this.selectedAll = isAllSelected;
        if (!isAllSelected) {
            _.forEach(this.entity.entities, function (record) {
                record._selectedEntry = false;
            });
        }
        else {
            var state = {
                skip: 0,
                take: undefined,
                filter: this.gridState.state.filter
            };
            var filtered = process(this.entity.entities, state);
            _.forEach(filtered.data, function (record) {
                if (_this.isEntryEditable(record)) {
                    record._selectedEntry = isAllSelected;
                }
            });
        }
        this.filteringSelectedEntries();
    };
    PbjManualEntryGridComponent.prototype.isEmployeeSelected = function (entry) {
        return !!_.get(entry, 'employee', null);
    };
    PbjManualEntryGridComponent.prototype.isDateAndEmpSelected = function (entry) {
        return !!_.get(entry, 'employee', null) && !!_.get(entry, 'date', null);
    };
    PbjManualEntryGridComponent.prototype.isDateSelected = function (entry) {
        return !!_.get(entry, 'date', null);
    };
    PbjManualEntryGridComponent.prototype.onEmployeeChanged = function (entry) {
        var employeeId = _.get(entry, 'employee.id', null);
        if (!_.isNumber(employeeId) || this.currentEmployeeId !== employeeId) {
            this.isLoading = true;
            this.currentPositions = null;
            this.currentEmployeeId = null;
            entry.center = null;
            entry.department = null;
            this.resetPosition(entry);
            this.resetLookups();
            this.isLoading = false;
        }
        if (_.isNumber(employeeId)) {
            this.isLoading = true;
            this.currentEmployeeId = employeeId;
            this.loadPositions(employeeId, entry);
        }
    };
    PbjManualEntryGridComponent.prototype.onOrganizationChanged = function (entry) {
        var _this = this;
        if (!entry.center) {
            entry.department = null;
            this.resetPosition(entry);
            return;
        }
        var employeeId = _.get(entry, 'employee.id', null);
        this.loadPositions(employeeId, entry).then(function () {
            var centerId = _.get(entry, 'center.id', null);
            var departmentId = _.get(entry, 'department.id', null);
            var positions = _.filter(_this.currentPositions, function (position) { return position.organizationId === centerId; });
            var departments = _.map(positions, function (position) {
                var department = new Department();
                department.id = position.departmentId;
                department.name = position.departmentName;
                department.parentOrganizationId = position.organizationId;
                return department;
            });
            departments = _.uniqBy(departments, function (department) { return department.id; });
            departments = _.sortBy(departments, function (department) { return department.name; });
            _this.departmentsLookup = _this.createLookup(departments, 'name', 'id');
            var findDepartmentId = departmentId;
            if (!_.isNumber(departmentId)) {
                var primaryPosition = _.first(_.sortBy(_.filter(positions, function (position) { return position.isPrimary && position.organizationId === centerId; }), function (x) { return x.id; }));
                if (primaryPosition) {
                    findDepartmentId = primaryPosition.departmentId;
                }
            }
            var departmentFound;
            if (findDepartmentId) {
                departmentFound = departments.find(function (department) {
                    return department.id === findDepartmentId && department.parentOrganizationId === centerId;
                });
            }
            if (departmentFound) {
                entry.department = departmentFound;
            }
            else {
                entry.department = null;
                _this.resetPosition(entry);
            }
        });
    };
    PbjManualEntryGridComponent.prototype.onDepartmentChanged = function (entry) {
        var _this = this;
        if (!entry.department) {
            if (entry.center && !this.isEmployeeSelected(entry)) {
                this.loadEmployees(entry.center.orgLevelId);
            }
            return;
        }
        var employeeId = _.get(entry, 'employee.id', null);
        this.loadPositions(employeeId, entry).then(function () {
            var departmentId = _.get(entry, 'department.id', null);
            var positions = _.filter(_this.currentPositions, function (position) { return position.departmentId === departmentId; });
            _this.positionsLookup = _this.createLookup(positions, 'nameWithDate', 'nameWithDate');
            _this.isPositionReadonly = false;
            _this.assignPositionFromLookup(entry);
        });
    };
    PbjManualEntryGridComponent.prototype.onPositionChanged = function (entry) {
        var startDate = _.get(entry, 'position.startDate', null);
        var endDate = _.get(entry, 'position.endDate', null);
        if (_.isDate(startDate)) {
            var posStartDate = moment(startDate);
            if (posStartDate.isBefore(this.filterDates.startDate)) {
                this.actualRange.startDate = this.filterDates.startDate;
            }
        }
        else {
            this.actualRange.startDate = this.filterDates.startDate;
        }
        if (_.isDate(endDate)) {
            var posEndDate = moment(endDate);
            if (posEndDate.isAfter(this.filterDates.endDate)) {
                this.actualRange.endDate = this.filterDates.endDate;
            }
        }
        else {
            this.actualRange.endDate = this.filterDates.endDate;
        }
        this.isLoading = false;
    };
    PbjManualEntryGridComponent.prototype.assignPositionFromLookup = function (entry) {
        if (!this.currentPositions || !this.currentPositions.length) {
            return;
        }
        var centerId = _.get(entry, 'center.id', null);
        var positionId = _.get(entry, 'position.id', null);
        var departmentId = _.get(entry, 'department.id', null);
        var foundPosition;
        if (positionId && departmentId) {
            foundPosition = this.currentPositions.find(function (position) {
                return position.id === positionId && position.departmentId === departmentId;
            });
        }
        if (!foundPosition && centerId && departmentId) {
            foundPosition = _.first(_.sortBy(_.filter(this.currentPositions, function (position) {
                return position.isPrimary && position.organizationId === centerId && position.departmentId === departmentId;
            }), function (x) { return x.id; }));
        }
        if (foundPosition) {
            entry.position = foundPosition;
        }
        else {
            this.resetPosition(entry);
            if (departmentId) {
                this.isPositionReadonly = false;
            }
        }
    };
    PbjManualEntryGridComponent.prototype.resetLookups = function () {
        this.positionsLookup = this.createLookup([], '', '');
        this.departmentsLookup = this.createLookup([], '', '');
        this.organizationsLookup = this.createLookup([], '', '');
    };
    PbjManualEntryGridComponent.prototype.resetControls = function (entryDate) {
        if (entryDate === void 0) { entryDate = null; }
        var employeeSource = null;
        if (this.currentDepartment) {
            employeeSource = this.currentDepartment.orgLevelId;
        }
        else if (this.currentOrganization) {
            employeeSource = this.currentOrganization.orgLevelId;
        }
        this.loadEmployees(employeeSource, entryDate);
        this.currentPositions = null;
        this.isPositionReadonly = true;
    };
    PbjManualEntryGridComponent.prototype.resetPosition = function (entry) {
        this.isPositionReadonly = true;
        entry.position = null;
        this.currentPositions = null;
        this.actualRange.startDate = null;
        this.actualRange.endDate = null;
    };
    PbjManualEntryGridComponent.prototype.filteringSelectedEntries = function () {
        var seletedEntries = [];
        if (this.entity) {
            seletedEntries = _.filter(this.entity.entities, { _selectedEntry: true });
        }
        this.managementService.onEntriesSelected$.next(seletedEntries);
    };
    PbjManualEntryGridComponent.prototype.defineOrgLevels = function () {
        switch (this.orgLevel.type) {
            case OrgLevelType.department:
                var parentOrgLevel = this.orgLevelWatchService.getOrgLevelById(this.orgLevel.parentId);
                var currentOrgLevel = this.orgLevelWatchService.getOrgLevelById(this.orgLevel.id);
                if (parentOrgLevel)
                    this.predefinedOrganization = this.makeOrganization(parentOrgLevel);
                this.predefinedDepartment = this.makeDepartment(currentOrgLevel);
                break;
            case OrgLevelType.organization:
                this.predefinedOrganization = this.makeOrganization(this.orgLevel);
                this.predefinedDepartment = null;
                break;
            case OrgLevelType.corporation:
                this.predefinedOrganization = null;
                this.predefinedDepartment = null;
                break;
            default:
        }
        this.currentOrganization = this.predefinedOrganization;
        this.currentDepartment = this.predefinedDepartment;
    };
    PbjManualEntryGridComponent.prototype.makeOrganization = function (orgLevel) {
        var entity = new Organization();
        entity.id = orgLevel.relatedItemId;
        entity.name = orgLevel.name;
        entity.orgLevelId = orgLevel.id;
        return entity;
    };
    PbjManualEntryGridComponent.prototype.makeDepartment = function (orgLevel) {
        var entity = new Department();
        entity.id = orgLevel.relatedItemId;
        entity.name = orgLevel.name;
        entity.orgLevelId = orgLevel.id;
        return entity;
    };
    PbjManualEntryGridComponent.prototype.createLookup = function (items, titleField, valueField) {
        var lookup = new Lookup();
        lookup.titleField = titleField;
        lookup.valueField = valueField;
        lookup.items = items;
        return lookup;
    };
    PbjManualEntryGridComponent.prototype.createSorting = function () {
        var sorting = [];
        sorting.push({ field: 'addedDate', dir: 'desc' });
        return sorting;
    };
    PbjManualEntryGridComponent.prototype.onChangeDate = function (effectiveDate, item) {
        var employeeSource = null;
        if (this.currentDepartment) {
            employeeSource = this.currentDepartment.orgLevelId;
        }
        else if (this.currentOrganization) {
            employeeSource = this.currentOrganization.orgLevelId;
        }
        this.loadEmployees(employeeSource, effectiveDate);
        if (item.employee != null && (item.employee.dateTerminated !== null && moment(effectiveDate).isAfter(item.employee.dateTerminated))) {
            var termDate = moment(item.employee.dateTerminated).format(appConfig.dateFormat);
            var msg = "You have selected a date that is after the employee's Termination date (" + termDate + "), Please verify the selected date before proceeding";
            var options = new ConfirmOptions();
            options.showOK = true;
            options.buttonOKtext = 'OK';
            ConfirmDialog2Component.openDialog('Confirmation', msg, this.modalService, function (result) { }, options);
        }
    };
    PbjManualEntryGridComponent.prototype.loadEmployees = function (orgLevelId, entryDate) {
        var _this = this;
        if (entryDate === void 0) { entryDate = null; }
        var updatedDates = _.clone(this.filterDates);
        if (entryDate !== null) {
            updatedDates.startDate = entryDate;
        }
        return this.lookupService
            .getLookup({ lookupType: LookupType.manualEntryActiveEmployees, orgLevelId: orgLevelId, dateRange: updatedDates })
            .then(function (employeesLookup) {
            var names = new Map();
            var duplicates = new Set();
            employeesLookup.items.forEach(function (emp, index) {
                var value = (names.get(emp.name) || new Set()).add(index);
                names.set(emp.name, value);
                if (value.size > 1) {
                    value.forEach(function (v) { return duplicates.add(v); });
                }
            });
            if (duplicates.size > 0) {
                duplicates.forEach(function (index) {
                    var emp = employeesLookup.items[index];
                    emp.name = emp.name + " (" + emp.id + ")";
                });
            }
            return _this.employeesLookup = employeesLookup;
        });
    };
    PbjManualEntryGridComponent.prototype.loadPositions = function (employeeId, entry) {
        var _this = this;
        var orgLevelId = null;
        if (this.currentDepartment) {
            orgLevelId = this.currentDepartment.orgLevelId;
        }
        else if (this.currentOrganization) {
            orgLevelId = this.currentOrganization.orgLevelId;
        }
        return this.lookupService
            .getLookup({ lookupType: LookupType.pbjPosition, orgLevelId: orgLevelId, employeeId: employeeId, isActive: false })
            .then(function (lookup) { return _this.processPositions(lookup, entry); });
    };
    PbjManualEntryGridComponent.prototype.processPositions = function (lookup, entry) {
        var _this = this;
        var processedLookup = this.filteringPositionsByDates(lookup, this.filterDates.startDate, this.filterDates.endDate);
        processedLookup = this.filteringOutDuplicates(lookup);
        this.currentPositions = processedLookup.items;
        var organizations = _.map(processedLookup.items, function (position) {
            var organization = new Organization();
            organization.id = position.organizationId;
            organization.name = position.organizationName;
            return organization;
        });
        organizations = _.uniqBy(organizations, function (organization) { return organization.id; });
        organizations = _.sortBy(organizations, function (organization) { return organization.name; });
        this.organizationsLookup = this.createLookup(organizations, 'name', 'id');
        var centerId = _.get(entry, 'center.id', null);
        if (!centerId && this.currentOrganization) {
            var foundOrganization = organizations.find(function (organization) { return organization.id === _this.currentOrganization.id; });
            if (foundOrganization) {
                entry.center = foundOrganization;
                if (entry.department) {
                    this.onOrganizationChanged(entry);
                }
            }
        }
        else {
            this.isLoading = false;
        }
        return processedLookup;
    };
    PbjManualEntryGridComponent.prototype.filteringPositionsByDates = function (positionsLookup, startDate, endDate) {
        if (positionsLookup.items.length === 0)
            return positionsLookup;
        var rangeStart = moment(startDate);
        var rangeEnd = moment(endDate);
        var positions = this.createLookup([], 'nameWithDate', 'nameWithDate');
        positions.items = _.filter(positionsLookup.items, function (position) {
            var posStartDate = position.startDate;
            var posEndDate = position.endDate;
            if (!_.isDate(posEndDate))
                posEndDate = new Date();
            return rangeStart.isSameOrBefore(posEndDate) && rangeEnd.isSameOrAfter(posStartDate);
        });
        return positions;
    };
    PbjManualEntryGridComponent.prototype.filteringOutDuplicates = function (lookup) {
        if (lookup.items.length === 0)
            return lookup;
        var positions = this.createLookup([], 'nameWithDate', 'nameWithDate');
        var groupped = _.groupBy(lookup.items, function (pos) { return pos.id + "_" + pos.name + "_" + pos.orgLevelId; });
        positions.items = _.map(groupped, function (p) {
            if (p.length > 1) {
                var sorted = _.sortBy(p, function (pos) { return pos.startDate.getTime(); });
                return _.head(sorted);
            }
            return _.head(p);
        });
        return positions;
    };
    PbjManualEntryGridComponent.prototype.refreshGrid = function () {
        if (!this.entity) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.entity.entities, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "onDateRangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "onSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "onAddEntrySubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "onAddedEntrySubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "onRemovedEntriesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryGridComponent.prototype, "onEditStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PbjManualEntryGridComponent.prototype, "manualEntrySubscriptions", void 0);
    return PbjManualEntryGridComponent;
}());
export { PbjManualEntryGridComponent };
