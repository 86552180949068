import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { appConfig } from '../../../app.config';
import { ActivatedRoute } from '@angular/router';
import { LeaveRequestApiService } from '../../services/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { LookupApiService } from '../../../organization/services/index';
var LeaveRequestComponent = /** @class */ (function () {
    function LeaveRequestComponent(leaveRequestApiService, lookupApiService, route) {
        this.route = route;
        this.appConfig = appConfig;
        this.leaveRequestApiService = leaveRequestApiService;
        this.lookupApiService = lookupApiService;
        this.filters = [];
        this.startDate = null;
        this.endDate = null;
        this.state = {
            isLoading: true
        };
    }
    LeaveRequestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orglevelSubscription = this.orgLevel$
            .combineLatest(this.route.queryParams)
            .subscribe(function (_a) {
            var orgLevel = _a[0], queryParams = _a[1];
            if (!orgLevel || !orgLevel.id) {
                return;
            }
            var queryOrgLevelId = Number(queryParams['orgLevelId']);
            if (queryOrgLevelId > 0 && queryOrgLevelId !== orgLevel.id) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            if (queryParams['startDate'] && queryParams['endDate']) {
                _this.startDate = moment(queryParams['startDate'], appConfig.linkDateFormat).toDate();
                _this.endDate = moment(queryParams['endDate'], appConfig.linkDateFormat).toDate();
            }
            if (_.isDate(_this.startDate) && _.isDate(_this.endDate)) {
                _this.loadGridData();
            }
            _this.loadAbsences();
        });
    };
    LeaveRequestComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    LeaveRequestComponent.prototype.onDatesChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadGridData();
    };
    LeaveRequestComponent.prototype.onFiltersChanged = function (filters) {
        this.filters = filters;
    };
    LeaveRequestComponent.prototype.onUpdateLoading = function (isLoading) {
        this.state.isLoading = isLoading;
        if (!isLoading) {
            this.loadGridData();
        }
    };
    LeaveRequestComponent.prototype.loadGridData = function () {
        var _this = this;
        this.state.isLoading = true;
        this.leaveRequestApiService
            .getLeaveRequests(this.currentOrgLevel.id, this.startDate, this.endDate)
            .then(function (leaveRequests) {
            _this.leaveRequests = leaveRequests.requests.slice(0);
            _this.actions = leaveRequests.actions;
            _this.state.isLoading = false;
        });
    };
    LeaveRequestComponent.prototype.loadAbsences = function () {
        var _this = this;
        this.lookupApiService.getScheduleAbsences(this.currentOrgLevel.id)
            .then(function (absences) {
            _this.absencesCodes = absences;
        });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], LeaveRequestComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LeaveRequestComponent.prototype, "orglevelSubscription", void 0);
    return LeaveRequestComponent;
}());
export { LeaveRequestComponent };
