var PunchesRollupRecord = /** @class */ (function () {
    function PunchesRollupRecord() {
    }
    Object.defineProperty(PunchesRollupRecord.prototype, "workOrgName", {
        get: function () {
            return this.workOrganization ? this.workOrganization.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchesRollupRecord.prototype, "orgName", {
        get: function () {
            return this.organization ? this.organization.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchesRollupRecord.prototype, "startDate", {
        get: function () {
            return this.payCycle ? this.payCycle.startDate : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchesRollupRecord.prototype, "endDate", {
        get: function () {
            return this.payCycle ? this.payCycle.endDate : null;
        },
        enumerable: true,
        configurable: true
    });
    return PunchesRollupRecord;
}());
export { PunchesRollupRecord };
