import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { ShiftDefinition, ScheduleAbsence } from '../../../../../organization/models/index';
import { ShiftPartialAbsence } from '../../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

@Component({
    moduleId: module.id,
    selector: 'slx-scheduled-shift-partial-absence-section',
    templateUrl: 'scheduled-shift-partial-absence-section.component.html',
    styleUrls: [
        'scheduled-shift-partial-absence-section.component.scss'
    ]
})

export class ScheduledShiftPartialAbsenceSectionComponent {

    @Input()
    public partialAbsToEnd: boolean;

    @Input()
    public absences: ScheduleAbsence[];

    @Input()
    public hasScheduleAbsence: boolean;

    @Input()
    public shiftHours: number;

    @Input()
    public startDate: Date;

    @Input()
    public endDate: Date;

    @Input()
    public set partialAbsence(value: ShiftPartialAbsence) {
        this.m_partialAbsence = value;
    }

    @Input()
    public index: number = -1;

    @Input()
    public shift: ShiftDefinition;

    @Output()
    public onChange: EventEmitter<ShiftPartialAbsence>;
    @Output()
    public onTimeChange: EventEmitter<ShiftPartialAbsence>;
    @Output()
    public onAttachmentChange: EventEmitter<boolean>;

    public appConfig: IApplicationConfig;

    public get partialAbsence(): ShiftPartialAbsence {
        return this.m_partialAbsence;
    }

    private m_partialAbsence: ShiftPartialAbsence;

    constructor() {
        this.appConfig = appConfig;
        this.onChange = new EventEmitter<ShiftPartialAbsence>();
        this.onTimeChange = new EventEmitter<ShiftPartialAbsence>();
        this.onAttachmentChange = new EventEmitter<boolean>();
    }

    public onPartialAbsenceChanged(): void {
        this.onChange.next(this.m_partialAbsence);
    }

    public shiftPartialAbsenceTimeChanged(): void {
        this.onTimeChange.next(this.m_partialAbsence);
    }

    public onPartialAbsenceAttachmentChanged(): void {
        this.onAttachmentChange.next(this.partialAbsToEnd);
    }
}
