import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DialogOptions, DialogOptions2, IDialog, ModalService } from '../../../../common';
import { OpenConfigOrgLevelType, OpenShiftPositionGroupDetails } from '../../../models';
import { OpenPositionControlManagementService, OpenPositionsControlApiService } from '../../../services';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { OpenPositionControlSlideOutExcel } from './../../../../../app/scheduler/models/open-position-control/open-position-controls-slide-out-excel';
import { OpenPositionControlHelper } from '../open-position-control-helper';

@Component({
  moduleId: module.id,
  selector: 'slx-position-group-slide-out',
  templateUrl: 'position-group-slide-out.component.html',
  styleUrls: ['position-group-slide-out.component.scss']
})

export class PositionGroupSlideOutComponent extends OpenPositionControlHelper implements IDialog, OnInit {
  @ViewChild('excelExport', { static: true })
  excelExport: ExcelExportComponent;
  @ViewChild('container', { read: ElementRef, static: true })
  private containerElem: ElementRef;
  public isLoading: boolean = true;
  public dialogResult: boolean;
  public openShiftPositionGroupDetails: OpenShiftPositionGroupDetails[] = [];
  public exportOpenPositionControlSlideOutData: any[] = [];
  public filteredData: OpenShiftPositionGroupDetails[] = [];
  public modalService: ModalService;
  public options: DialogOptions;
  public filterOption: string = 'All';
  public orgLevelId: number;
  public startDate: Date;
  public endDate: Date;
  private m_initialized: boolean;
  public orgLevel: OpenConfigOrgLevelType;
  public get initialized(): boolean {
    return this.m_initialized;
  }

  constructor(options: DialogOptions, modalService: ModalService, orgLevel: OpenConfigOrgLevelType,
    private openPositionsControlApiService: OpenPositionsControlApiService) {
    super(modalService);
    this.options = options;
    this.modalService = modalService;
    this.orgLevel = orgLevel;
    this.openPositionsControlApiService.isLoadPositionGroupDetails$.subscribe((data: boolean) => {
      this.isLoading = true;
      data && this.filterData();
    });
  }
  ngOnInit(): void {
    this.m_initialized = true;
    this.updatePopupPosition();
  }

  onFilterChange() {
    this.LoadData();
  }

  LoadData() {
    this.isLoading = true;
    this.filterData();

  }

  filterData() {
    this.filteredData = [];
    this.openPositionsControlApiService.getPositionGroupDetails(this.orgLevelId, this.startDate, this.endDate, this.filterOption).then((data: OpenShiftPositionGroupDetails[]) => {
      this.openShiftPositionGroupDetails = data;
      this.openShiftPositionGroupDetails.forEach(e => {
        this.filteredData.push(
          {
            positionGroup:  e.positionGroup,
            totalShifts:  e.totalShifts,
            totalScheduled: e.totalScheduled,
            totalHours: e.totalHours,
            totalFTHead:  e.totalFTHead,
            totalPTHead:  e.totalPTHead,
            positionDetails: e.positionDetails
          });
      }
      );
      this.isLoading = false;
    });
  }

  updatePopupPosition(): void {
    const container: HTMLElement = this.containerElem.nativeElement;
    const popup = $(container).closest('kendo-popup');
    popup.addClass('slx-slide-out-dialog-custom');
  }
  public getDateRange(): string {
    return 'Date Range: ' + moment(this.startDate).format('L') + ' - ' + moment(this.endDate).format('L');
  }

  public getFilterData(): string {
    return 'Filter Group: ' + this.filterOption;
  }

  public exportToExcel() {
    this.exportOpenPositionControlSlideOutData = [];
    if (this.filterOption == 'All') {
      this.openPositionsControlApiService.getPositionGroupDetails(this.orgLevelId, this.startDate, this.endDate, 'Excel').then((data: OpenShiftPositionGroupDetails[]) => {
        this.openShiftPositionGroupDetails = data;
        this.openShiftPositionGroupDetails.forEach(e => {
          e.positionDetails.forEach(x => {
            let orgData: OpenPositionControlSlideOutExcel = new OpenPositionControlSlideOutExcel();
            orgData.PositionGroup = e.positionGroup;
            orgData.Position = x.position;
            orgData.ShiftGroup = x.shiftGroup;
            orgData.Shifts = x.shifts;
            orgData.Scheduled = x.scheduled;
            if (x.shifts == 0) {
              orgData.hoursGap = "Ideal Schedule is not configured";
            }
            else if (x.shifts > 0 && x.hoursGap == 0) {
              orgData.hoursGap = "No Rotation Gaps";
            }
            else {
              orgData.hoursGap = x.hoursGap.toString();
              // orgData.ftEsNeeded="";
              orgData.ftHeadCount = x.ftHeadCount;
              orgData.ptHeadCount = x.ptHeadCount;
            }
            this.exportOpenPositionControlSlideOutData.push(orgData);
          })

        }
        );
        this.excelExport.data = this.exportOpenPositionControlSlideOutData;
        this.excelExport.save();
      });
    }
    else {
      this.openShiftPositionGroupDetails.forEach(e => {
        e.positionDetails.forEach(x => {
          let orgData: OpenPositionControlSlideOutExcel = new OpenPositionControlSlideOutExcel();
          orgData.PositionGroup = e.positionGroup;
          orgData.Position = x.position;
          orgData.ShiftGroup = x.shiftGroup;
          orgData.Shifts = x.shifts;
          orgData.Scheduled = x.scheduled;
          if (x.shifts == 0) {
            orgData.hoursGap = "Ideal Schedule is not configured";
          }
          else if (x.shifts > 0 && x.hoursGap == 0) {
            orgData.hoursGap = "No Rotation Gaps";
          }
          else {
            orgData.hoursGap = x.hoursGap.toString();
            //orgData.ftEsNeeded="";
            orgData.ftHeadCount = x.ftHeadCount;
            orgData.ptHeadCount = x.ptHeadCount;
          }
          this.exportOpenPositionControlSlideOutData.push(orgData);
        })
      }
      );
      this.excelExport.data = this.exportOpenPositionControlSlideOutData;
      this.excelExport.save();
    }
  }
}