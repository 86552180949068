<slx-spinner [show]="state.isLoading" heightNotFill="true">
  <div class="subsection-header" [ngClass]="{'edit-mode': state.isEditMode}">
    <div class="subection-icon"></div>
    <div class="subection-title-box">
      <span class="subection-title">{{subsection.displayName}}</span>
    </div>
    <hr class="subection-hr">
    <div *ngIf="isEditable" class="subection-edit-box" [ngClass]="{'edit-mode': state.isEditMode}">
      <button *ngIf="!state.isEditMode" class="subection-edit-button" (click)="onEdit()">Edit
        <div class="subection-edit-img"></div>
      </button>
      <button *ngIf="state.isEditMode" [disabled]="!valid || dateError" class="subection-save" (click)="onSave()">Save Changes</button>
      <div class="effective-date-box">
        <slx-input-decorator *ngIf="temporalModel?.isTemporalDirty && !temporalModel?.isOnlyPayRateDirty && !temporalModel?.isNotOnlyPayRateDirty">
          <slx-date-picker-ngx slx-input
              [minDate]="minEffectiveDate"
              [(ngModel)]="effectiveDate"
              [readonly]="!state.isEditMode"
              [emitValueAnyway]="true"
              [constantValidation]="true"
              [required]="true"
              (ngModelChange)="onEffectiveDateChange($event)"
              placeholder="Effective Date"
              name="effectiveDate"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <div errorMessage for="date"></div>
          <div errorMessage for="minDate">Date is out of valid range</div>
          <div errorMessage for="dateError" *ngIf="dateError">Date is Invalid or out of valid range</div>
        </slx-input-decorator>
        <slx-input-decorator *ngIf="!isCurrentPayCycle() && (temporalModel?.isOnlyPayRateDirty || temporalModel?.isNotOnlyPayRateDirty)">
          <slx-date-picker-ngx slx-input
            [minDate]="minEffectiveDate"
            [(ngModel)]="effectiveDate"
            [readonly]="!state.isEditMode"
            [emitValueAnyway]="true"
            [constantValidation]="true"
            [required]="true"
            (ngModelChange)="onEffectiveDateChange($event)"
            placeholder="Effective Date"
            name="effectiveDate"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <div errorMessage for="date"></div>
          <div errorMessage for="minDate">Date is out of valid range</div>
          <div errorMessage for="dateError" *ngIf="dateError">Date is Invalid or out of valid range</div>
        </slx-input-decorator>
        <slx-input-decorator *ngIf="isCurrentPayCycle() && temporalModel?.isOnlyPayRateDirty">
          <slx-date-picker-ngx slx-input
            [minDate]="temporalModel.payrollEffectiveDateSetting?.minDate"
            [maxDate]="temporalModel.payrollEffectiveDateSetting?.maxDate"
            [disabledDateArray]="disabledDates"
            [emitValueAnyway]="true"
            [constantValidation]="true"
            [(ngModel)]="effectiveDate"
            [required]="true"
            [readonly]="!state.isEditMode"
            placeholder="Effective Date"
            (ngModelChange)="onEffectiveDateChange($event)"
            name="effectiveDate"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <div errorMessage for="date"></div>
          <div errorMessage for="minDate">Date is out of valid range</div>
          <div errorMessage for="maxDate">Date is out of valid range</div>
          <div errorMessage for="dateError" *ngIf="dateError">Date is Invalid or out of valid range</div>
        </slx-input-decorator>
        <slx-input-decorator *ngIf="isCurrentPayCycle() && temporalModel?.isNotOnlyPayRateDirty">
          <slx-date-picker-ngx slx-input
            [minDate]="temporalModel.payrollEffectiveDateSetting?.minDate2"
            [maxDate]="temporalModel.payrollEffectiveDateSetting?.maxDate2"
            [disabledDateArray]="disabledDates"
            [emitValueAnyway]="true"
            [constantValidation]="true"
            [(ngModel)]="effectiveDate"
            [required]="true"
            [readonly]="!state.isEditMode"
            placeholder="Effective Date"
            (ngModelChange)="onEffectiveDateChange($event)"
            name="effectiveDate"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <div errorMessage for="date"></div>
          <div errorMessage for="minDate">Date is out of valid range</div>
          <div errorMessage for="maxDate">Date is out of valid range</div>
          <div errorMessage for="dateError" *ngIf="dateError">Date is Invalid or out of valid range</div>
        </slx-input-decorator>

      </div>
      <button *ngIf="state.isEditMode" class="subection-discard" (click)="onCancel()">Discard</button>
    </div>
  </div>
  <ng-content></ng-content>
</slx-spinner>
