import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Output, Provider, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent, PageChangeEvent, ColumnBase, ColumnComponent,
} from '@progress/kendo-angular-grid';

import { Workbook, WorkbookSheetColumn, WorkbookSheetRow, WorkbookSheetRowCell, WorkbookSheet, ExcelExportData, toDataURL, workbookOptions } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';

import { IFilteredItems, ServerCompositeFilterDescriptor, PagingData, MetadataInfo } from '../../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { PbjExportApiService } from '../../../services/index';
import { ServerFilterService } from '../../../../../core/services/index';
import { A4 } from '../../../../../common/models/media/paper-sizes';
import { GridExportType } from '../../../models/pbj-export/grid-export-type';
import { PBJExportLogHeader } from '../../../models/index';
import { IPbjConfig, pbjConfig } from '../../../pbj.config';
import { StateManagementService } from '../../../../../common/index';
import { PbjExportColumn } from '../../../../../app-modules/pbj/models/pbj-export/pbj-export-column';

export abstract class PbjDetailsBasicGridComponent<T> implements OnInit, OnDestroy, OnChanges {

  @Input()
  public set pbjIdConfigEnabled(value: boolean) {
    this.m_pbjIdConfigEnabled = value;
    this.updateColumnsConfig();
  }

  @Input()
  public pbjHeader: PBJExportLogHeader;

  public get pdfScale(): number {
    return this.m_pdfScale;
  }

  public get pbjIdConfigEnabled(): boolean {
    return this.m_pbjIdConfigEnabled;
  }

  @ViewChild('exportGrid', {static: false})
  public set exportGridChild(el: GridComponent) {
    this.exportGrid = el;
    if (this.exportGrid && this.isExportInProgress) {
      if (this.currentExportType === GridExportType.PDF) {
        this.finishPdfExport();
      } else if (this.currentExportType === GridExportType.XLS) {
        this.finishXlsExport();
      } else {
        this.cleanUpExportData();
      }
    }
  }

  public xlsExportType: number;

  public exportId: number;
  public loadStatus: EventEmitter<boolean>;
  public appConfig: IApplicationConfig;
  public pbjConfig: IPbjConfig;
  public records: IFilteredItems<T>;
  public gridState: KendoGridStateHelper<T>;
  public pageSize: number = 50;

  public exportGridState: KendoGridStateHelper<T>;
  public isExportInProgress: boolean;

  public xlsExportColumns: PbjExportColumn[];

  public currentExportType: GridExportType;

  @unsubscribe()
  protected gridRefreshSubscription: Subscription;
  @unsubscribe()
  protected filterChangeSubscription: Subscription;

  protected pagingData: PagingData;

  protected exportGrid: GridComponent;
  protected exportRecords: IFilteredItems<T>;

  protected abstract readonly controlKey: string;

  private m_pdfScale: number = 0;

  private m_pbjIdConfigEnabled: boolean;

  constructor(protected apiService: PbjExportApiService, protected serverFilterService: ServerFilterService, protected changeDetector: ChangeDetectorRef, protected stateManagement: StateManagementService) {
    this.loadStatus = new EventEmitter<boolean>();
    this.gridState = new KendoGridStateHelper<T>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];
    this.pagingData = { take: 50, skip: 0 };

    this.xlsExportType = GridExportType.XLS;

    this.filterChangeSubscription = this.serverFilterService.changes$.subscribe((filter: ServerCompositeFilterDescriptor) => {
      this.refreshData();
    });

  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.pbjConfig = pbjConfig;
    this.stateManagement.init(this.controlKey, true);
    this.applyDefaultSort();
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid(this.records, this.gridState);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.stateManagement.loadedData({});
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['exportId']) {
      if (this.exportId) {
        this.refreshData();
      }
    }
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.pagingData = { take: event.take, skip: event.skip };
    this.refreshData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshData();
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.refreshGrid(this.records, this.gridState);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public exportToPdf(): void {
    this.currentExportType = GridExportType.PDF;
    this.loadAndExport(this.currentExportType);
  }

  public exportToXls(): void {
    this.currentExportType = GridExportType.XLS;
    this.loadAndExport(this.currentExportType);
  }


  public abstract get exportFilename(): string;
  public abstract loadAndExport(type: GridExportType): void;
  public abstract refreshData(): void;
  protected abstract applyDefaultSort(): void;

  protected processExportData(type: GridExportType, val: IFilteredItems<T>): void {
    this.exportRecords = val;
    switch (type) {
      case GridExportType.PDF:
        this.startPdfExport();
        break;
      case GridExportType.XLS:
        this.startXlsExport();
        break;
    }
  }

  protected startXlsExport(): void {
    this.exportGridState = new KendoGridStateHelper<T>();
    this.exportGridState.state.sort = this.gridState.state.sort;
    this.exportGridState.state.group = this.gridState.state.group;
    this.exportGridState.state.filter = this.gridState.state.filter;
    this.refreshGrid(this.exportRecords, this.exportGridState);
    this.isExportInProgress = true;
    // this.changeDetector.markForCheck();
    // this.changeDetector.detectChanges();

    this.finishXlsExport();
  }

  protected startPdfExport(): void {
    this.exportGridState = new KendoGridStateHelper<T>();
    this.exportGridState.state.sort = this.gridState.state.sort;
    this.exportGridState.state.group = this.gridState.state.group;
    this.exportGridState.state.filter = this.gridState.state.filter;
    if (this.exportRecords.items.length > 2000) {
      this.exportRecords.items = _.take(this.exportRecords.items, 2000);
    }
    this.refreshGrid(this.exportRecords, this.exportGridState);
    this.isExportInProgress = true;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  protected finishPdfExport(): void {
    this.setupPdfTemplate();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
    this.exportGrid.saveAsPDF();
    this.cleanUpExportData();
  }

  protected finishXlsExport(): void {

    let headerInfo: string = this.addHeaderInformation();

    let rows: any[] = [];
    _.each(this.exportGridState.view.data, (item: any) => {
      let cells: any[] = [];
      _.each(this.xlsExportColumns, (column: { field: string }) => {
        let cell: any = {
          value: _.get(item, column.field)
        };
        cells.push(cell);
      });
      rows.push({ cells: cells });
    });

    let headers: any[] = [];
    _.each(this.xlsExportColumns, (column: PbjExportColumn) => {
      let cell: any = {
        value: column.displayTitle,
        fontSize: this.pbjConfig.settings.export.xlsx.headerCell.fontSize,
        color: this.pbjConfig.settings.export.xlsx.headerCell.color,
        background: this.pbjConfig.settings.export.xlsx.headerCell.background,
        width: column.width
      };
      headers.push(cell);
    });

    rows.unshift({ cells: headers });

    let additionalDataRow: any = {
      cells: [
        {
          value: headerInfo, colSpan: this.xlsExportColumns.length,
          fontSize: this.pbjConfig.settings.export.xlsx.titleCell.fontSize,
          borderBottom: this.pbjConfig.settings.export.xlsx.titleCell.borderBottom
        }
      ]
    };
    rows.unshift(additionalDataRow);

    const workbook = new Workbook({

      sheets: [
        {
          name: this.pbjHeader.organization.name.slice(0, 31),
          columns: this.xlsExportColumns,
          rows: rows
        }
      ]
    });

    workbook.toDataURL().then(dataUrl => {
      saveAs(dataUrl, this.exportFilename + '.xlsx');
    });

    this.cleanUpExportData();
  }

  protected addHeaderInformation(): string {

    let organization: string = this.pbjHeader.organization.name;
    let start: string = moment(this.pbjHeader.startDate).format(appConfig.dateFormat);
    let end: string = moment(this.pbjHeader.endDate).format(appConfig.dateFormat);
    let exported: string = moment(this.pbjHeader.exportDate).format(appConfig.dateTimeFormatUS);
    let sheetName: string = `${organization} (${start}-${end}) Exported on ${exported}`;
    return sheetName;

  }

  protected cleanUpExportData(): void {
    // clean up
    this.exportRecords = null;
    this.exportGridState = null;
    this.isExportInProgress = false;
  }

  protected setupPdfTemplate(): void {

    const pdfMargins: { top: number, left: number, bottom: number, right: number } = this.pbjConfig.settings.export.pdf.margins;
    const unscaledGridColumnWidthMM: number = this.pbjConfig.settings.export.pdf.unscaledGridColumnWidthMM;
    const fieldCount: number = this.exportGrid.columnList.toArray().length;
    let scale = (A4.heightMM - pdfMargins.left - pdfMargins.right) / (unscaledGridColumnWidthMM * fieldCount);
    this.m_pdfScale = scale;
  }

  protected onLoadStatus(isLoading: boolean): void {
    setTimeout(() => this.loadStatus.next(isLoading), 1);
  }

  protected filterbyExportId(exportId: number): void {
    this.serverFilterService.removeFilter('exportId');
    this.serverFilterService.composeFilter({ field: 'exportId', operator: 'eq', value: exportId });
    this.serverFilterService.applyFilter();
  }

  protected showChanges(): void {
    this.refreshGrid(this.records, this.gridState);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  protected refreshGrid(records: IFilteredItems<T>, gridState: KendoGridStateHelper<T>): void {
    if (!records) {
      gridState.view = null;
      return;
    }
    gridState.view = { data: null, total: null };
    let filtered: T[] = filterBy(records.items, gridState.state.filter);
    gridState.view = { data: orderBy(filtered, gridState.state.sort), total: records.count ? records.count : 0 };
  }

  protected updateColumnsConfig(): void {
    if (this.m_pbjIdConfigEnabled) {
      this.xlsExportColumns = [
        { field: 'pbjId', displayTitle: 'PBJ ID', index: 0, width: 150 }
      ];
    } else {
      this.xlsExportColumns = [
        { field: 'employeeMasterId', displayTitle: 'Emp Master ID', index: 0, width: 150 }
      ];
    }
  }

}

