import * as tslib_1 from "tslib";
import { OnDestroy, Provider, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { process, State } from '@progress/kendo-data-query';
import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { LookupApiService } from '../../../../organization/services/index';
import { TimeclockDefinition, TimeclockRestrictionDefinition } from '../../../../organization/models/index';
import { TimeclockAssignmentState, TimeclockRestrictionTotal, TimeclockAssignmentEmployee, TimeclockAssignment, TimeclockRestriction, AssignMode } from '../../../models/index';
var TimeclockAssignmentDialogComponent = /** @class */ (function () {
    function TimeclockAssignmentDialogComponent(options, modalService, lookupApiService, state, mode) {
        var _this = this;
        this.selectedUnassignedTimclocks = false;
        this.mode = mode;
        this.options = options;
        this.modalService = modalService;
        this.lookupApiService = lookupApiService;
        this.state = state;
        this.gridState = new KendoGridStateHelper();
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.selectedRecords = [];
        this.selectedTimeclocks = [];
        this.definitions = _.map(this.state.timeclocks, function (def) {
            return { isSelected: false, timeclock: def };
        });
        this.restrictions = _.map(this.state.totals, function (total) {
            return total.restriction;
        });
        this.dialogResult = false;
        this.noChangeRestriction = new TimeclockRestrictionDefinition();
        this.noChangeRestriction.id = -1;
        this.noChangeRestriction.name = 'No change';
        if (this.isChangeRestriction()) {
            this.selectedRestriction = this.restrictions[0];
            this.filterRecordBySelectedEmployees();
        }
        else {
            if (this.isAssignMode()) {
                this.selectedRestriction = this.restrictions[0];
            }
            else {
                this.filterForUnassignement();
                this.selectedRestriction = this.noChangeRestriction;
            }
        }
    }
    Object.defineProperty(TimeclockAssignmentDialogComponent.prototype, "hasRestrictionError", {
        get: function () {
            var restrictionId = _.get(this.selectedRestriction, 'id', -1);
            return restrictionId === -1 && this.selectedUnassignedTimclocks && this.isReAssignedMode();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockAssignmentDialogComponent.prototype, "selectedDefinitions", {
        get: function () {
            if (this.isChangeRestriction()) {
                return _.map(this.definitions, function (def) { return def.timeclock; });
            }
            return _.reduce(this.definitions, function (collector, def) {
                if (def.isSelected) {
                    collector.push(def.timeclock);
                }
                return collector;
            }, []);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockAssignmentDialogComponent.prototype, "isRestrictionSelected", {
        get: function () {
            var restrictionId = _.get(this.selectedRestriction, 'id', null);
            return _.isNumber(restrictionId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockAssignmentDialogComponent.prototype, "isDefinitionsSelected", {
        get: function () {
            return _.size(this.selectedDefinitions) > 0;
        },
        enumerable: true,
        configurable: true
    });
    TimeclockAssignmentDialogComponent.openDialog = function (header, req, mode, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 950;
        dialogOptions.height = 550;
        dialogOptions.showCloseButton = true;
        if (mode === AssignMode.changeRestriction) {
            dialogOptions.height = 160;
        }
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: TimeclockAssignmentState,
                useValue: req
            },
            {
                provide: AssignMode,
                useValue: mode
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(TimeclockAssignmentDialogComponent, header, dialogOptions, resolvedProviders, function (result, uniqueId) {
            var assignments = dialog.makeTimeclocksAssignment();
            callback(result, assignments);
        });
        return dialog;
    };
    TimeclockAssignmentDialogComponent.prototype.ngOnInit = function () {
        this.refreshGrid();
    };
    TimeclockAssignmentDialogComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeclockAssignmentDialogComponent.prototype.isAssignMode = function () {
        return this.mode === AssignMode.assign;
    };
    TimeclockAssignmentDialogComponent.prototype.isReAssignedMode = function () {
        return this.mode === AssignMode.reassign;
    };
    TimeclockAssignmentDialogComponent.prototype.isChangeRestriction = function () {
        return this.mode === AssignMode.changeRestriction;
    };
    TimeclockAssignmentDialogComponent.prototype.isUnassignMode = function () {
        return this.mode === AssignMode.unassign;
    };
    TimeclockAssignmentDialogComponent.prototype.isDisabled = function () {
        var result;
        switch (true) {
            case this.isAssignMode():
                result = !this.isRestrictionSelected || !this.isDefinitionsSelected;
                break;
            case this.isReAssignedMode():
                result = !this.isRestrictionSelected || !this.isDefinitionsSelected || this.hasRestrictionError;
                break;
            case this.isChangeRestriction():
                result = !this.isRestrictionSelected;
                break;
            case this.isUnassignMode():
                result = !this.isDefinitionsSelected;
                break;
        }
        return result;
    };
    TimeclockAssignmentDialogComponent.prototype.onSelectionChanged = function (isAllSelected, item) {
        var _this = this;
        this.selectedUnassignedTimclocks = false;
        this.selectedTimeclocks = _.reduce(this.definitions, function (collector, entry) {
            if (isAllSelected) {
                entry.isSelected = _this.isAllSelected;
                collector.push(entry.timeclock);
                if (!entry.isAssigned) {
                    _this.selectedUnassignedTimclocks = true;
                }
            }
            else if (entry.isSelected) {
                collector.push(entry.timeclock);
                if (!entry.isAssigned) {
                    _this.selectedUnassignedTimclocks = true;
                }
            }
            return collector;
        }, []);
    };
    TimeclockAssignmentDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    TimeclockAssignmentDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    TimeclockAssignmentDialogComponent.prototype.makeTimeclocksAssignment = function () {
        if (this.isChangeRestriction()) {
            return this.groupByPersonalTimeclocks();
        }
        return this.groupBySelectedTimeclocks();
    };
    TimeclockAssignmentDialogComponent.prototype.groupBySelectedTimeclocks = function () {
        var _this = this;
        var timeclockRestrictions = this.makeTimeclockRestriction(this.selectedTimeclocks, this.selectedRestriction);
        return _.map(this.state.selectedEmployees, function (employee) { return _this.makeTimeclockAssignment(employee, timeclockRestrictions); });
    };
    TimeclockAssignmentDialogComponent.prototype.groupByPersonalTimeclocks = function () {
        var _this = this;
        return _.reduce(this.selectedRecords, function (collector, record) {
            var timeclockRestrictions = _this.makeTimeclockRestriction(record.assignments, _this.selectedRestriction);
            collector.push(_this.makeTimeclockAssignment(record.employee, timeclockRestrictions));
            return collector;
        }, []);
    };
    TimeclockAssignmentDialogComponent.prototype.makeTimeclockAssignment = function (employee, timeclockRestrictions) {
        var timeclock = new TimeclockAssignment();
        timeclock.employee = employee;
        timeclock.timeclockRestrictions = timeclockRestrictions;
        return timeclock;
    };
    TimeclockAssignmentDialogComponent.prototype.makeTimeclockRestriction = function (entries, restriction) {
        return _.map(entries, function (entry) {
            var record = new TimeclockRestriction();
            record.timeclock = entry instanceof TimeclockDefinition ? entry : entry.timeclock;
            record.restriction = restriction;
            return record;
        });
    };
    TimeclockAssignmentDialogComponent.prototype.filterRecordBySelectedEmployees = function () {
        var _this = this;
        var selectedEmployees = this.state.selectedEmployees;
        _.forEach(this.state.records, function (record) {
            var index = _.findIndex(selectedEmployees, function (emp) { return emp.id === record.employee.id; });
            if (index !== -1) {
                _this.selectedRecords.push(record);
            }
        });
    };
    TimeclockAssignmentDialogComponent.prototype.filterForUnassignement = function () {
        var timeclockIds = [];
        var selectedEmployees = this.state.selectedEmployees;
        _.forEach(this.state.records, function (record) {
            var index = _.findIndex(selectedEmployees, function (emp) { return emp.id === record.employee.id; });
            if (index !== -1) {
                _.forEach(record.assignments, function (assignment) {
                    timeclockIds.push(assignment.timeclock.id);
                });
            }
        });
        _.forEach(this.definitions, function (def) {
            def.isAssigned = !!_.find(timeclockIds, function (timeclockId) { return timeclockId === def.timeclock.id; });
        });
        this.refreshGrid();
    };
    TimeclockAssignmentDialogComponent.prototype.refreshGrid = function () {
        if (!this.definitions) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.definitions, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentDialogComponent.prototype, "gridRefreshSubscription", void 0);
    return TimeclockAssignmentDialogComponent;
}());
export { TimeclockAssignmentDialogComponent };
