/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-center-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-popper";
import * as i4 from "../message-center-notification-list/message-center-notification-list.component.ngfactory";
import * as i5 from "../message-center-notification-list/message-center-notification-list.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../app-modules/message-center/services/messages-management.service";
import * as i8 from "../../services/notifications/msg-notifications.service";
import * as i9 from "../../../organization/services/navigation/message-center-config.service";
import * as i10 from "../../services/notifications/notifications-api.service";
import * as i11 from "../message-center-notification-content/message-center-notification-content.component.ngfactory";
import * as i12 from "../message-center-notification-content/message-center-notification-content.component";
import * as i13 from "../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i14 from "./message-center-notification.component";
import * as i15 from "../../../common/services/modal/modal.service";
var styles_MessageCenterNotificationComponent = [i0.styles];
var RenderType_MessageCenterNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageCenterNotificationComponent, data: {} });
export { RenderType_MessageCenterNotificationComponent as RenderType_MessageCenterNotificationComponent };
function View_MessageCenterNotificationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-envelope"]], null, null, null, null, null))], null, null); }
function View_MessageCenterNotificationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-badge-dissabled"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [["class", "fas fa-times-circle"]], null, null, null, null, null))], null, null); }
function View_MessageCenterNotificationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "header-button-dissabled"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notificationType == _co.notificationTypes.MESSAGES); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.notificationsCount > 0); _ck(_v, 4, 0, currVal_1); }, null); }
function View_MessageCenterNotificationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-envelope"]], null, null, null, null, null))], null, null); }
function View_MessageCenterNotificationComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "notification-badge"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationsCount; _ck(_v, 1, 0, currVal_0); }); }
function View_MessageCenterNotificationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "button", [["class", "header-button"], ["type", "button"]], null, [[null, "keyup"], [null, "popperOnShown"], [null, "popperOnHidden"], [null, "click"], [null, "touchstart"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i1.ɵnov(_v, 1).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i1.ɵnov(_v, 1).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } if (("keyup" === en)) {
        var pd_7 = (_co.onKeyup($event) !== false);
        ad = (pd_7 && ad);
    } if (("popperOnShown" === en)) {
        var pd_8 = (_co.onShown($event) !== false);
        ad = (pd_8 && ad);
    } if (("popperOnHidden" === en)) {
        var pd_9 = (_co.onHidden($event) !== false);
        ad = (pd_9 && ad);
    } if (("click" === en)) {
        var pd_10 = (_co.showPopper() !== false);
        ad = (pd_10 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 737280, null, 0, i3.PopperController, [i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], popperModifiers: [3, "popperModifiers"], disableStyle: [4, "disableStyle"] }, { popperOnShown: "popperOnShown", popperOnHidden: "popperOnHidden" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_7)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 6); var currVal_1 = _co.popperPosition; var currVal_2 = "click"; var currVal_3 = _co.popperModifiers; var currVal_4 = "true"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = (_co.notificationType == _co.notificationTypes.MESSAGES); _ck(_v, 3, 0, currVal_5); var currVal_6 = (_co.notificationsCount > 0); _ck(_v, 5, 0, currVal_6); }, null); }
function View_MessageCenterNotificationComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-message-center-notification-list", [], null, [[null, "closeModal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("closeModal" === en)) {
        var pd_0 = (_co.goToMessageCenter($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MessageCenterNotificationListComponent_0, i4.RenderType_MessageCenterNotificationListComponent)), i1.ɵdid(1, 114688, null, 0, i5.MessageCenterNotificationListComponent, [i6.Router, i7.MessagesManagementService, i8.MessageNotificationService, i9.MessageCenterConfigService, i10.NotificationsApiService], { messageCount: [0, "messageCount"], isMyMessage: [1, "isMyMessage"] }, { closeModal: "closeModal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messageCount; var currVal_1 = _co.isMyMessage; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MessageCenterNotificationComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.notificationType == _co.notificationTypes.MESSAGES) && _co.popupShown); _ck(_v, 2, 0, currVal_0); }, null); }
function View_MessageCenterNotificationComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["tab"]))], null, null); }
function View_MessageCenterNotificationComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 32, "div", [["class", "notification-popup-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 31, "slx-message-center-notification-content", [], null, null, null, i11.View_MessageCenterNotificationContentComponent_0, i11.RenderType_MessageCenterNotificationContentComponent)), i1.ɵdid(2, 49152, null, 0, i12.MessageCenterNotificationContentComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 14, "div", [["class", "tab-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "sms-tabs flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "ip-tabs__slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "ul", [["class", "ip-tabs__list flex flex-direction-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "li", [["class", "sms_select"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "tab_text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-envelope"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" SMS "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "sms_count"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "li", [["class", "inbox_select"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "span", [["class", "tab_text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "li", [["class", "posts_item"]], [[2, "active", null]], null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "span", [["class", "tab_text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "li", [["class", "empty_item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, 1, 4, "div", [["class", "notification-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_9)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_11)), i1.ɵdid(22, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(23, 0, null, 2, 9, "div", [["class", "notification-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 8, "div", [["class", "buttonsdiv"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 3, "button", [["class", "slx-button slx-mobile-adapted mapping-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToMessageCenter(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(27, 0, null, null, 0, "em", [["class", "fas fa-inbox"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Message Center"])), (_l()(), i1.ɵeld(29, 0, null, null, 3, "button", [["class", "slx-button slx-blue slx-mobile-adapted mapping-btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToMessageCenter("composeNew") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 2, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "em", [["class", "far fa-paper-plane"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Compose New"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = !_co.activeTab; _ck(_v, 20, 0, currVal_4); var currVal_5 = _co.activeTab; _ck(_v, 22, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isActiveMenu(0); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.notificationsCount; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.isActiveMenu(1); _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.isActiveMenu(2); _ck(_v, 15, 0, currVal_3); var currVal_6 = (!_co.canSendSMS && !_co.canSendSlate); _ck(_v, 29, 0, currVal_6); }); }
function View_MessageCenterNotificationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "msg-notification-button-container"]], [[2, "highlight", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_5)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "popper-content", [["class", "slx-msg-popper"]], [[4, "top", "px"], [4, "right", "px"]], [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i13.View_PopperContent_0, i13.RenderType_PopperContent)), i1.ɵdid(6, 180224, [["popperContent", 4]], 0, i3.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MessageCenterNotificationComponent_8)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isMessageIcon; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.isMessageIcon; _ck(_v, 4, 0, currVal_2); var currVal_5 = _co.popupShown; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupShown; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.popupClassProperties.top; var currVal_4 = _co.popupClassProperties.right; _ck(_v, 5, 0, currVal_3, currVal_4); }); }
export function View_MessageCenterNotificationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { popperContent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageCenterNotificationComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEnableIcon; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MessageCenterNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-message-center-notification", [], null, null, null, View_MessageCenterNotificationComponent_0, RenderType_MessageCenterNotificationComponent)), i1.ɵdid(1, 245760, null, 0, i14.MessageCenterNotificationComponent, [i6.Router, i8.MessageNotificationService, i10.NotificationsApiService, i15.ModalService, i7.MessagesManagementService, i9.MessageCenterConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageCenterNotificationComponentNgFactory = i1.ɵccf("slx-message-center-notification", i14.MessageCenterNotificationComponent, View_MessageCenterNotificationComponent_Host_0, { popperPosition: "popperPosition", notificationType: "notificationType" }, { popperOnShow: "popperOnShow", popperOnHide: "popperOnHide" }, []);
export { MessageCenterNotificationComponentNgFactory as MessageCenterNotificationComponentNgFactory };
