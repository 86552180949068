import { IBenefitEligibilityRule, BenefitEligibilityRule } from './index';

export interface IBenefitEligibilityRulesChangeRequest {
  rulesToAdd: IBenefitEligibilityRule[];
  rulesToUpdate: IBenefitEligibilityRule[];
  rulesToDelete: number[];
}

export class BenefitEligibilityRulesChangeRequest {
  public readonly rulesToAdd: BenefitEligibilityRule[];
  public readonly rulesToUpdate: BenefitEligibilityRule[];
  public readonly rulesToDelete: number[];

  constructor({ rulesToAdd, rulesToUpdate, rulesToDelete }: Partial<BenefitEligibilityRulesChangeRequest>) {
    this.rulesToAdd = rulesToAdd || [];
    this.rulesToUpdate = rulesToUpdate || [];
    this.rulesToDelete = rulesToDelete || [];
  }
}
