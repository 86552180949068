/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wc-incident-report-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../employee/employee/components/employee-search/employee-search-field.component.ngfactory";
import * as i3 from "../../../../../employee/employee/components/employee-search/employee-search-field.component";
import * as i4 from "../../../../../employee/employee/services/employee-search-base/employee-search-base";
import * as i5 from "../wc-incident-report/wc-incident-report.component.ngfactory";
import * as i6 from "../wc-incident-report/wc-incident-report.component";
import * as i7 from "../../../services/crud/wc-report-management.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i10 from "../../../../../common/components/spinner/spinner.component";
import * as i11 from "../../../services/crud/wc-report-api.service";
import * as i12 from "../../../services/crud/wc-report-map.service";
import * as i13 from "../../../../../organization/services/lookup/lookup.service";
import * as i14 from "../../../../../common/services/modal/modal.service";
import * as i15 from "../../../../../common/services/change-management/change-management.service";
import * as i16 from "../../../../../core/services/session/session.service";
import * as i17 from "@angular/router";
import * as i18 from "../../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i19 from "../../../../../common/services/file/file.service";
import * as i20 from "./wc-incident-report-container.component";
var styles_WCIncidentReportContainerComponent = [i0.styles];
var RenderType_WCIncidentReportContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WCIncidentReportContainerComponent, data: {} });
export { RenderType_WCIncidentReportContainerComponent as RenderType_WCIncidentReportContainerComponent };
function View_WCIncidentReportContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-search-field", [], null, [[null, "loadStatusChange"], [null, "employeeSelected"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadStatusChange" === en)) {
        var pd_0 = (_co.onSearchLoadStatusChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("employeeSelected" === en)) {
        var pd_1 = (_co.onEmployeeSelected($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_EmployeeSearchFieldComponent_0, i2.RenderType_EmployeeSearchFieldComponent)), i1.ɵdid(1, 245760, null, 0, i3.EmployeeSearchFieldComponent, [i4.EmployeeSearchBase], { instructions: [0, "instructions"], orgLevelId: [1, "orgLevelId"] }, { loadStatusChange: "loadStatusChange", employeeSelected: "employeeSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "To create new incident report, search for the employee.\n            Use the employee's name to search, then select the employee to begin."; var currVal_1 = _co.orgLevelId; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_WCIncidentReportContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-wc-incident-report", [], null, null, null, i5.View_WcIncidentReportComponent_0, i5.RenderType_WcIncidentReportComponent)), i1.ɵdid(1, 245760, null, 0, i6.WcIncidentReportComponent, [i7.WCReportManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_WCIncidentReportContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "error-box flex flex-direction__column justify-content__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-exclamation-triangle error-box__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "error-box__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 3, 0, currVal_0); }); }
function View_WCIncidentReportContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
function View_WCIncidentReportContainerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.canSave || _co.hasError) || !_co.formValid); _ck(_v, 0, 0, currVal_0); var currVal_1 = (_co.isCreatingNew ? "Save" : "Update"); _ck(_v, 1, 0, currVal_1); }); }
function View_WCIncidentReportContainerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEdit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canEdit; _ck(_v, 0, 0, currVal_0); }); }
function View_WCIncidentReportContainerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "flex justify-content__flex-end modal-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WCIncidentReportContainerComponent_6)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WCIncidentReportContainerComponent_7)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCreatingNew || _co.editMode); _ck(_v, 4, 0, currVal_0); var currVal_1 = (!_co.isCreatingNew && !_co.editMode); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_WCIncidentReportContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "slx-spinner", [["novalidate", ""]], null, null, null, i9.View_SpinnerComponent_0, i9.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i10.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WCIncidentReportContainerComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WCIncidentReportContainerComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WCIncidentReportContainerComponent_3)), i1.ɵdid(8, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WCIncidentReportContainerComponent_4)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WCIncidentReportContainerComponent_5)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((!_co.hasEmployee && !_co.hasReportId) && !_co.hasError); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.hasEmployee; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.hasError; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_co.hasEmployee; _ck(_v, 11, 0, currVal_4); var currVal_5 = (_co.hasEmployee && _co.report); _ck(_v, 13, 0, currVal_5); }, null); }
export function View_WCIncidentReportContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-wc-incident-report-container", [], null, null, null, View_WCIncidentReportContainerComponent_0, RenderType_WCIncidentReportContainerComponent)), i1.ɵprd(512, null, i7.WCReportManagementService, i7.WCReportManagementService, [i11.WCReportApiService, i12.WCReportMapService, i13.LookupService, i14.ModalService, i15.ChangeManagementService, i16.SessionService, i17.Router, i17.ActivatedRoute, i18.NotificationsService, i19.FileService]), i1.ɵdid(2, 245760, null, 0, i20.WCIncidentReportContainerComponent, [i7.WCReportManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var WCIncidentReportContainerComponentNgFactory = i1.ɵccf("slx-wc-incident-report-container", i20.WCIncidentReportContainerComponent, View_WCIncidentReportContainerComponent_Host_0, { options: "options" }, { action: "action", employeeSelected: "employeeSelected" }, []);
export { WCIncidentReportContainerComponentNgFactory as WCIncidentReportContainerComponentNgFactory };
