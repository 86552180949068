import { Directive, ElementRef, Input, ContentChild, OnInit, OnChanges, Optional, Host, Inject, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import * as _ from 'lodash';
import { dateTimeUtils } from '../../../utils/index';
import { IControlState, ControlStateKey, StateResetTypes } from '../../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { DateRangeNgxComponent } from '../../../components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component';
import { RangeDates, IRangeDates } from '../../../models/range-dates';

@Directive({
  selector: 'slx-date-range-ngx[slxControlActiveState], slx-date-range[slxControlActiveState]'
})
export class DateRangeNgxActiveStateDirective implements OnInit, OnDestroy {

  @Input('slxControlActiveState')
  public set resetType(type: StateResetTypes) {
    if (!type) {
      return;
    }
    this.m_resetType = type;
  }

  @Input('name')
  public controlId: string = 'DateRangeNgx';


  @unsubscribe()
  private contextChangeSubscription: Subscription;
  @unsubscribe()
  private valueChangesSubscription: Subscription;

  private m_resetType: StateResetTypes;
  private state: IControlState;
  private stateKey: ControlStateKey;

  constructor( @Host() private dateRange: DateRangeNgxComponent, private storageService: ComponentStateStorageService, private stateManagement: StateManagementService) {
    this.m_resetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe((key: StringMap<any>) => {
      this.switchState(key);
    });
  }

  public ngOnInit(): void {
    this.stateManagement.registerControl(this.controlId);
  }
  public ngOnDestroy(): void {
    this.stateManagement.unregisterControl(this.controlId);
  }

  public valueChanged(val: IRangeDates): void {
    this.state.value = { startDate: dateTimeUtils.convertToDtoDateTimeString(val.startDate), endDate: dateTimeUtils.convertToDtoDateTimeString(val.endDate) };
    this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.controlId);
  }

  private loadState(): void {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
    this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId, this.stateKey);
    if (this.state.value !== undefined) {
      const startDate = dateTimeUtils.convertFromDtoDateTimeString(this.state.value.startDate);
      const endDate = dateTimeUtils.convertFromDtoDateTimeString(this.state.value.endDate);
      this.dateRange.onRangeDateChanged(startDate, endDate);
    }
    this.valueChangesSubscription = this.dateRange.rangeDateChanged.subscribe((x: IRangeDates): any => {
      this.valueChanged(x);
    });
    this.stateManagement.controlRestored(this.controlId);
  }

  private switchState(context: StringMap<any>): void {
    this.stateKey = new ControlStateKey(context);
    this.loadState();
  }
}
