import { IEmployeeDefinition, EmployeeDefinition } from '../../../organization/index';
import { OrgLevel } from '../../../state-model/models/index';
import { ISupervisor, Supervisor, ISupervisedEmployee, SupervisedEmployee } from './index';

export interface ISupervisorGroup {
  id: number;
  name: string;
  orgLevel: OrgLevel;
  supervisors: ISupervisor[];
  employees: ISupervisedEmployee[];
}

export class SupervisorGroup {
  public id: number;
  public name: string;
  public orgLevel: OrgLevel;
  public supervisors: Supervisor[];
  public employees: SupervisedEmployee[];
  public isSelected: boolean;
}

export class SupervisorGroupsContainer {
  public groups: SupervisorGroup[];
}
