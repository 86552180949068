import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import * as _ from 'lodash';
import { Subscription } from "rxjs/Subscription";
import { Observable } from "rxjs/Observable";
import { mutableSelect } from "../../../core/decorators/redux-decorators";
import { unsubscribe } from "../../../core/decorators/unsubscribe-decorator";
import {
  ISession,
} from "../../../authentication/store/session/session.types";
import { NotificationType } from "../../models/notification-type.enum";
import { PopperContent, PopperController } from "ngx-popper";
import { PopperPositions } from "../../../common/models/index";
import { Router } from "@angular/router";
import { MessageNotificationService } from "../../services/notifications/msg-notifications.service";
import { NotificationsApiService } from '../../services';
import { messageCenterConstants } from '../../constants/message-center.constant'

import { ModalService } from '../../../../app/common';
import { SmsComposeNewDialogComponent } from '../../../../app/app-modules/message-center/components';
import { MessagesManagementService } from '../../../app-modules/message-center/services/messages-management.service';
import { OrgLevel } from '../../../state-model/models/index';
import { unsubscribeAll } from '../../../core/decorators/index';
import { MessageCenterConfigService } from '../../../../../src/app/organization/services/index';
import { ComposeNewTabsPermissions } from "../../../app-modules/message-center/models/ComposeNewTabsPermissions";
@Component({
  moduleId: module.id,
  selector: "slx-message-center-notification",
  templateUrl: "message-center-notification.component.html",
  styleUrls: ["message-center-notification.component.scss"]
})
export class MessageCenterNotificationComponent implements OnInit, OnDestroy {
  public activeTab: number = 0;
  private router: Router;
  public popper;
  public isMessageIcon: boolean;
  public isEnableIcon: boolean;
  private isMessageCenterEnabled: boolean;
  private isMyMessage: boolean;
  public canSendSlate: boolean;
  public canSendSMS: boolean;
  public canViewSlateTab: boolean;
  public canViewSMSTab: boolean;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  @Input()
  public popperPosition: PopperPositions = "bottom";
  @Input()
  public notificationType: NotificationType;

  @Output()
  public popperOnShow: EventEmitter<PopperController> = new EventEmitter<
    PopperController
  >();
  @Output()
  public popperOnHide: EventEmitter<PopperController> = new EventEmitter<
    PopperController
  >();

  @ViewChild("msgPopperContent", { static: true })
  public popperContent: PopperContent;

  @mutableSelect(["session"])
  public session$: Observable<ISession>;

  public notificationTypes: any;

  public notificationsCount: number = 0;

  public popupShown: boolean;
  public popupClassProperties = {};

  public popperModifiers: any = {
    arrow: {
      order: 500,
      enabled: true,
      element: "[x-arrow]",
    },
  };

  tooltipText = messageCenterConstants;

  // private subscriptions: StringMap<Subscription> = {};

  @unsubscribe()
  private userSubscription: Subscription;

  private escCode: number = 27;

  messageCount: any;
  public orgLevel: OrgLevel;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  public orgLevelID = null;
  constructor(
    router: Router,
    private msgNotificationService: MessageNotificationService,
    private api: NotificationsApiService,
    private modalService: ModalService,
    private messagesManagementService: MessagesManagementService,
    private messageCenterConfigService: MessageCenterConfigService
  ) {
    this.router = router;
    this.notificationTypes = NotificationType;
  }

  public ngOnInit(): void {

    this.isMyMessage = this.messagesManagementService.restoreFilterState();
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
        this.orgLevelID = orgLevel.id;
        this.getMessageCenterPermissions();
      }
    });
    this.subscriptions.checkIconAccess = this.msgNotificationService.subscribeToIconAccess(
      (access) => {
        this.setIconAccess(access);
      }
    );

    this.subscriptions.messageFilter = this.messagesManagementService.subscribeToswitchMessageFilter((res: boolean) => {
      this.isMyMessage = res;
      this.getUnreadMessageCount();
    });

    this.subscriptions.updateStatus = this.messagesManagementService.subscribeToUpdateReadStatus((v) => {
      this.getUnreadMessageCount();
    });

    this.subscriptions.alert = this.api.subcribeToNewmessageAlert((v) => {
      this.getUnreadMessageCount();
    }, true);

    this.subscriptions.count = this.messagesManagementService.subscribecallUnReadCountApi(() => {
      this.getUnreadMessageCount();
    })

    this.subscriptions.unreadSmsCount = this.msgNotificationService.subscribeToUnreadSmsCount((count => {
      this.notificationsCount = count.unRead;
      this.messageCount = count;
      this.messagesManagementService.updateMessageCount(count);
    }));
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public getMessageCenterPermissions() {
    this.messagesManagementService.getComposeNewTabsPermissions(this.orgLevelID).then((cNewTabs: ComposeNewTabsPermissions) => {
      this.canSendSMS = cNewTabs.canSendSMS;
      this.canSendSlate = cNewTabs.canSendSlate;
      this.canViewSlateTab = cNewTabs.canViewSlateTab;
      this.canViewSMSTab = cNewTabs.canViewSmsTab;
      this.checkIconAcess();
    });
  }

  public checkIconAcess() {
    this.msgNotificationService.checkIconAcess(this.orgLevel.id);
  }

  public onKeyup(event: KeyboardEvent): void {
    if (event.keyCode === this.escCode) {
      this.popperContent.hide();
    }
  }

  public onShown(popper: PopperController): void {
    this.popupShown = true;
    this.popperOnShow.emit(popper);
    this.popper = popper;
  }

  public onHidden(popper: PopperController): void {
    this.popupShown = false;
    this.popperOnHide.emit(popper);
  }

  public onClickTab(tab: number): void {
    if (this.activeTab === tab) return;

    this.activeTab = tab;
    if (tab === 0) {
    } else if (tab === 1) {
    }
    this.isActiveMenu(this.activeTab);
  }

  public isActiveMenu(tabOrder: number): boolean {
    return this.activeTab === tabOrder ? true : false;
  }


  public setIconAccess(access) {
    if (access.isUserAllowed) {
      if (access.isMessageCenterEnabled && (this.canViewSMSTab || this.canViewSlateTab)) {
        this.isEnableIcon = true;
        this.isMessageIcon = true;
      } else {
        access.isAdminUser
          ? (this.isMessageIcon = false, this.isEnableIcon = true)
          : (this.isEnableIcon = false);
      }
    } else {
      access.isAdminUser
        ? (this.isMessageIcon = false, this.isEnableIcon = true)
        : (this.isEnableIcon = false);
    }

    this.isMessageCenterEnabled = access.isMessageCenterEnabled;

    this.getUnreadMessageCount();
  }
  public goToMessageCenter(event) {
    this.popupShown = false;
    if (event === 'composeNew') {
      SmsComposeNewDialogComponent.openDialog(this.modalService, (result: boolean) => { });
    } else if (event === true) {
      this.getUnreadMessageCount();
      this.router.navigate([this.messageCenterConfigService.GetMessageCenterUrl()]);
    }
  }

  public showPopper() {
    this.popupShown = true;
    let val = this.notificationsCount;
    let count = 0;
    if (val >= 1) ++count;
    while (val / 10 >= 1) {
      val /= 10;
      ++count;
    }
    this.popupClassProperties['top'] = 28;
    switch (count) {
      case 0:
        this.popupClassProperties['right'] = 0;
        break;
      case 1:
        this.popupClassProperties['right'] = 17;
        break;
      case 2:
        this.popupClassProperties['right'] = 22;
        break;
      case 3:
        this.popupClassProperties['right'] = 28;
        break;
      case 4:
        this.popupClassProperties['right'] = 34;
        break;
      case 5:
        this.popupClassProperties['right'] = 40;
        break;
      case 6:
        this.popupClassProperties['right'] = 45;
        break;

    }

  }

  public getUnreadMessageCount() {
    if (!this.isMessageCenterEnabled || !this.orgLevelID) {
      return;
    }
    this.msgNotificationService.getUnreadSmsCount(this.orgLevelID, this.isMyMessage);
  }

  buttonCss() {
    if (this.notificationsCount > 0) {
      return 'notification-on';
    } else {
      return '';
    }
  }

}
