export interface IEmployeeSearchConfig {
  api: {
    employees: {
      root: string;
      search: string;
    }
  };
}

export const employeeSearchConfig: IEmployeeSearchConfig = {
  api: {
    employees: {
      root: 'employees',
      search: 'search'
    }
  }
};
