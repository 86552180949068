/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vacation-employee-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../vacation-employee-details/vacation-employee-details.component.ngfactory";
import * as i3 from "../vacation-employee-details/vacation-employee-details.component";
import * as i4 from "../../../../organization/services/lookup/lookup.service";
import * as i5 from "../vacation-employee-summary/vacation-employee-summary.component.ngfactory";
import * as i6 from "../vacation-employee-summary/vacation-employee-summary.component";
import * as i7 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i8 from "../../../../common/components/spinner/spinner.component";
import * as i9 from "@angular/common";
import * as i10 from "./vacation-employee-dialog.component";
import * as i11 from "../../../../common/models/dialog-options";
import * as i12 from "../../../../common/services/modal/modal.service";
import * as i13 from "../../../services/vacation-planner/vacation-planner-api.service";
import * as i14 from "../../../../organization/services/lookup/lookup-api.service";
import * as i15 from "../../../../organization/models/employee/employee-definition";
import * as i16 from "../../../models/vacation-planner/vacation-plan-employee-week";
import * as i17 from "../../../../core/models/tokens";
var styles_VacationEmployeeDialogComponent = [i0.styles];
var RenderType_VacationEmployeeDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VacationEmployeeDialogComponent, data: {} });
export { RenderType_VacationEmployeeDialogComponent as RenderType_VacationEmployeeDialogComponent };
function View_VacationEmployeeDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-vacation-employee-details", [], null, null, null, i2.View_VacationEmployeeDetailsComponent_0, i2.RenderType_VacationEmployeeDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i3.VacationEmployeeDetailsComponent, [i4.LookupService], { orgLevelId: [0, "orgLevelId"], scheduleRecords: [1, "scheduleRecords"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orgLevelId; var currVal_1 = ((_co.employeeDetails == null) ? null : _co.employeeDetails.records); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_VacationEmployeeDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-vacation-employee-summary", [], null, null, null, i5.View_VacationEmployeeSummaryComponent_0, i5.RenderType_VacationEmployeeSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i6.VacationEmployeeSummaryComponent, [], { year: [0, "year"], employeeSummary: [1, "employeeSummary"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.year; var currVal_1 = _co.employeeSummary; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_VacationEmployeeDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleToSummary() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Summary"]))], null, null); }
function View_VacationEmployeeDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleToDetails() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back to PTO Details"]))], null, null); }
function View_VacationEmployeeDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], null, null); }
export function View_VacationEmployeeDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "slx-spinner", [], null, null, null, i7.View_SpinnerComponent_0, i7.RenderType_SpinnerComponent)), i1.ɵdid(3, 49152, null, 0, i8.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VacationEmployeeDialogComponent_1)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_VacationEmployeeDialogComponent_2)), i1.ɵdid(7, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "link-div"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VacationEmployeeDialogComponent_3)), i1.ɵdid(11, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VacationEmployeeDialogComponent_4)), i1.ɵdid(13, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VacationEmployeeDialogComponent_5)), i1.ɵdid(17, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.state.isSummary; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.state.isSummary; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.state.isSummary; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.state.isSummary; _ck(_v, 13, 0, currVal_4); var currVal_5 = !_co.state.isSummary; _ck(_v, 17, 0, currVal_5); }, null); }
export function View_VacationEmployeeDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-vacation-employee-dialog", [], null, null, null, View_VacationEmployeeDialogComponent_0, RenderType_VacationEmployeeDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.VacationEmployeeDialogComponent, [i11.DialogOptions, i12.ModalService, i13.VacationPlannerApiService, i14.LookupApiService, i15.EmployeeDefinition, i16.VacationPlanEmployeeWeek, i17.ORG_LEVEL_ID_TOKEN], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VacationEmployeeDialogComponentNgFactory = i1.ɵccf("slx-vacation-employee-dialog", i10.VacationEmployeeDialogComponent, View_VacationEmployeeDialogComponent_Host_0, {}, {}, []);
export { VacationEmployeeDialogComponentNgFactory as VacationEmployeeDialogComponentNgFactory };
