import { ShiftSwapInfo } from '../../../models/shift-swap/shift-swap-info';
import { Component, Input } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-shift-swap-list',
    templateUrl: 'shift-swap-list.component.html',
    styleUrls: ['shift-swap-list.component.scss']
})
export class ShiftSwapListComponent {
    @Input()
    public list: ShiftSwapInfo[];
}
