import { Component, OnInit, Provider } from '@angular/core';
import { DialogOptions, DialogOptions2, IDialog, ModalService } from '../../../../common';
import { RNHrsMinAuditDialog } from '../../../../reports/models/report-rn-hrs-min-audit';

@Component({
  selector: 'slx-rn-hrs-min-warning-dialog',
  templateUrl: './rn-hrs-min-warning-dialog.component.html',
  styleUrls: ['./rn-hrs-min-warning-dialog.component.scss']
})
export class RnHrsMinWarningDIalogComponent implements IDialog, OnInit {
  public dialogResult: boolean;
  public isTotalOrConsecutive:boolean =false;
  public data: any;
  public static openDialog(
    modalService: ModalService,
    callback: (result: boolean) => void
  ): RnHrsMinWarningDIalogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.height = 180;
    dialogOptions.width = 500;
    dialogOptions.className = 'slx-reports-rn-hrs-minimum-audit-error-modal';
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions,
      },
    ];
     modalService.globalAnchor.openDialog2(
      RnHrsMinWarningDIalogComponent,
      'Alert',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return null;
  }
  
  constructor() { }

  ngOnInit() {
  }

}
