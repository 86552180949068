<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <form #atForm="ngForm" class="slx-content-toolbar-indents flex-row jc-space-between">
      <div class="date-range">
        <slx-daterange class="slx-width-260"
          name="startEndDate"
          [startDate]="fromFilter"
          [endDate]="toFilter"
          (rangeDateChanged)="filterDateChanged($event)"
          slxControlActiveState
        ></slx-daterange>
      </div>

      <div class="flex-row ai-flex-end">


        <div class="padd-r dropdown-container employees-filter">
          <slx-multiselect
            [options]="lookups.employeeList"
            [(ngModel)]="selectedEmps"
            (ngModelChange)="filterEmpChanged($event)"
            titleField="name"
            valueField="id"
            [valuePrimitive]="true"
            placeholder="Employee filter"
            [externalPlaceholder]="true"
            name="empFilter">
          </slx-multiselect>
          <slx-checkbox-button class="only-active-checkbox"
            [(ngModel)]="isActiveEmpOnly"
            (ngModelChange)="isActiveOnlyChanged()"
            placeholder="Active only"
            name="isActiveEmpOnly"
          ></slx-checkbox-button>
        </div>

        <div class="padd-r dropdown-container">
          <slx-multiselect
            [options]="lookups.auditTrailAreas"
            [(ngModel)]="selectedAreas"
            (ngModelChange)="areaSelected($event)"
            titleField="code"
            valueField="code"
            [valuePrimitive]="true"
            placeholder="Area filter"
            [externalPlaceholder]="true"
            [readonly]="disableGlobalAreaFilter"
            name="area"
            [ngClass]="{'disabled': disableGlobalAreaFilter}">
          </slx-multiselect>
        </div>

        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onExportToExcel()" [disabled]="!canExportData">
              Export to Excel
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onExportToPdf()" [disabled]="!canExportData">
              Export to PDF
            </slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </div>
    </form>

    <div class="slx-high-box__body slx-main-content-indents">
      <slx-audit-trail-grid [skip]="pagingData?.skip" [gridView]="actionList"
      (gridPageChanged)="gridPageChanged($event)" (gridSortChanged)="gridSortChanged ($event)">
      </slx-audit-trail-grid>
    </div>
  </div>
</slx-spinner>
