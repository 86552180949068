import { Organization } from '../../../../organization/models/index';

export interface IPBJExportLogRecord {
  exportId: number;
  startDate: string;
  endDate: string;
  exportDate: string;
  orgLevelId: number;
  organizationId: number;
  organizationName: string;
  staffingType: string;
  censusType: string;
  containsEmployeeInfo: string;
  containsStaffingInfo: string;
  containsCensusInfo: boolean;
  details: string;
  exportedBy: string;
}

export class PBJExportLogRecord {
  public exportId: number;
  public startDate: Date;
  public endDate: Date;
  public exportDate: Date;
  public organizationName: string;
  public organization: Organization;
  public staffingType: string;
  public censusType: string;
  public containsEmployeeInfo: string;
  public containsStaffingInfo: string;
  public containsCensusInfo: boolean;
  public details: string;
  public exportedBy: string;
}
