import { IOrganization, Organization, IDepartment, Department, IPosition, Position } from '../../../../organization/models/index';

export interface IPBJManualTimeEntity {
  orgLevelId: number;
  status: string;
  startDate: string;
  endDate: string;
  entities: IPBJManualTimeEntry[];
}

export class PBJManualTimeEntity {
  public orgLevelId: number;
  public status: string;
  public startDate: Date;
  public endDate: Date;
  public entities: PBJManualTimeEntry[];
}

export interface IPBJManualTimeEntryEmployee {
  dateHired: string;
  employeeType: {
    name: string;
    description: string;
    fullTimeIndex: string;
  };
  id: number;
  mobilePhoneNumber: number;
  name: string;
  payrollNumber: number;
  phoneNumber: number;
}

export class PBJManualTimeEntryEmployee {
  public dateHired: Date;
  public employeeType: {
    name: string;
    description: string;
    fullTimeIndex: string;
  };
  public id: number;
  public mobilePhoneNumber: number;
  public name: string;
  public payrollNumber: number;
  public phoneNumber: number;
  public dateTerminated: Date;
}

export interface IPBJManualTimeEntry {
  addedByUsername: string;
  addedDate: string;
  center: IOrganization;
  date: string;
  department: IDepartment;
  employee: IPBJManualTimeEntryEmployee;
  entryType: string;
  hours: number;
  id: number;
  position: IPosition;
}

export class PBJManualTimeEntry {
  public addedByUsername: string;
  public addedDate: Date;
  public center: Organization;
  public date: Date;
  public department: Department;
  public employee: PBJManualTimeEntryEmployee;
  public entryType: string;
  public hours: number;
  public id?: number;
  public position: Position;
  public _selectedEntry?: boolean;
}
