import * as tslib_1 from "tslib";
import { OnInit, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { FavoriteManagementService } from '../../../configuration/services/favorites/favorites-management.service';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators';
import { NavigationMenuRedirectService } from '../../services/navigation/navigation-menu-redirect.service';
var FavoritesMenuComponent = /** @class */ (function () {
    function FavoritesMenuComponent(renderer, elementRef, favService, navService) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.favService = favService;
        this.navService = navService;
        this.isShowFavorites = false;
        this.isViewInit = false;
        this.isLocked = true;
        this.searchValue = "";
    }
    Object.defineProperty(FavoritesMenuComponent.prototype, "show", {
        set: function (value) {
            this.visibilityChanged(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FavoritesMenuComponent.prototype, "top", {
        set: function (value) {
            this.positionChanged(value, null, null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FavoritesMenuComponent.prototype, "left", {
        set: function (value) {
            this.positionChanged(null, value, null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FavoritesMenuComponent.prototype, "pointerIconShift", {
        set: function (value) {
            this.positionChanged(null, null, value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FavoritesMenuComponent.prototype, "favoriteViewElem", {
        get: function () {
            return this.elementRef.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    FavoritesMenuComponent.prototype.visibilityChanged = function (isShow) {
        this.isShowFavorites = isShow;
        if (this.isViewInit) {
            this.setVisibility();
        }
    };
    FavoritesMenuComponent.prototype.positionChanged = function (top, left, iconShift) {
        if (_.isNumber(top))
            this.posTop = top;
        if (_.isNumber(left))
            this.posLeft = left;
        if (_.isNumber(iconShift))
            this.iconShift = iconShift;
        if (this.isViewInit) {
            this.setPosition();
        }
    };
    FavoritesMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuSubscription = this.favService.favoriteItemsUpdated$.subscribe(function (menuItems) {
            _this.menuItems = menuItems;
            _this.onClearSearch();
        });
    };
    FavoritesMenuComponent.prototype.ngAfterViewInit = function () {
        this.isViewInit = true;
        this.setVisibility();
        this.setPosition();
    };
    FavoritesMenuComponent.prototype.setPosition = function () {
        this.renderer.setStyle(this.favoriteViewElem, 'top', this.posTop + "px");
        this.renderer.setStyle(this.favoriteViewElem, 'left', this.posLeft + "px");
        var pointerIcon = this.favoriteViewElem.querySelector('.js-pointer');
        if (_.isElement(pointerIcon)) {
            var styles = window.getComputedStyle(pointerIcon);
            this.renderer.setStyle(pointerIcon, 'left', this.iconShift - (parseInt(styles.width) / 2) + "px");
        }
    };
    FavoritesMenuComponent.prototype.setVisibility = function () {
        if (this.isShowFavorites) {
            this.renderer.addClass(this.favoriteViewElem, 'show');
        }
        else {
            this.onClearSearch();
            this.renderer.removeClass(this.favoriteViewElem, 'show');
        }
    };
    FavoritesMenuComponent.prototype.open = function (menu) {
        this.navService.open(menu);
    };
    FavoritesMenuComponent.prototype.filterFavorites = function (value) {
        this.favorites = this.menuItems.filter(function (res) { return res.displayName.toLowerCase().includes(value.toLowerCase()); });
    };
    FavoritesMenuComponent.prototype.onClearSearch = function () {
        this.searchValue = "";
        this.favorites = this.menuItems;
    };
    FavoritesMenuComponent.prototype.showConfigDialog = function () {
        this.onClearSearch();
        this.favService.showConfigDialog();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], FavoritesMenuComponent.prototype, "menuSubscription", void 0);
    return FavoritesMenuComponent;
}());
export { FavoritesMenuComponent };
