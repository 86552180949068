import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';

import * as _ from 'lodash';
import * as moment from 'moment';

import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';

import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';

import {
  EmpType, Department
} from '../../../organization/models/index';
import {
  ShiftReplacementInfo,
  ShiftEligibleEmployee, IShiftEligibleEmployee,
  ShiftPhoneCall, IShiftPhoneCall,
  ShiftSmsNotificationResponse, IShiftSmsNotificationResponse,
  ShiftAppNotificationResponse, IShiftAppNotificationResponse,
  ShiftNotificationRequest, IShiftNotificationRequest,
  ShiftNotificationResponse, IShiftNotificationResponse,
  ShiftReplaceCmd, IShiftReplaceCmd, IShiftReplacementInfo,
  ShiftOpenCmd, IShiftOpenCmd,
  ShiftAddEmployeeCmd, IShiftAddEmployeeCmd,
  IScheduledShift,
  ScheduledShift
} from '../../models/index';
import { appConfig } from '../../../app.config';
import { commonConfig } from '../../../common/common.config';

@Injectable()
export class ShiftReplacementMapService {

  private lookupMapService: LookupMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;

  constructor(lookupMapService: LookupMapService, employeeDefinitionsMapService: EmployeeDefinitionsMapService) {
    this.lookupMapService = lookupMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
  }

  public mapShiftOpenCmdDTO(data: ShiftOpenCmd): IShiftOpenCmd {
    let dto: IShiftOpenCmd = {
      dateOn: data.dateOn,
      unitId: data.unitId,
      positionId: data.positionId,
      employeeIds: data.employeeIds,
    };
    return dto;
  }

  public mapShiftReplaceCmdDTO(data: ShiftReplaceCmd): IShiftReplaceCmd {
    Assert.isNotNull(data.selectedEmployee, 'selectedEmployee');
    Assert.isNotNull(data.selectedEmployee.employee, 'employee');

    let dto: IShiftReplaceCmd = {
      dateOn: moment(data.dateOn).format(appConfig.requestDate),
      shiftId: data.shiftId,
      absenceCode: data.absenceCode,
      replaceById: data.selectedEmployee.employee.id,
      comment: data.comment
    };
    return dto;
  }

  public mapShiftAddEmployeeCmdDTO(data: ShiftAddEmployeeCmd): IShiftAddEmployeeCmd {
    let dto: IShiftAddEmployeeCmd = {
      unitId: data.unitId,
      positionId: data.positionId,
      employeeIds: [data.selectedEmployee.employee.id]
    };
    return dto;
  }


  public mapShiftSmsNotificationResponse(dto: IShiftSmsNotificationResponse): ShiftSmsNotificationResponse {
    let data: ShiftSmsNotificationResponse = new ShiftSmsNotificationResponse();
    data.messageText = dto.messageText;
    data.responseText = dto.responseText;
    data.dateSent = dateTimeUtils.convertFromDtoDateTimeString(dto.dateSent);
    return data;
  }

  public mapShiftAppNotificationResponse(dto: IShiftAppNotificationResponse): ShiftAppNotificationResponse {
    let data: ShiftAppNotificationResponse = new ShiftAppNotificationResponse();
    data.responseText = dto.responseText;
    return data;
  }

  public mapShiftPhoneCall(dto: IShiftPhoneCall): ShiftPhoneCall {
    let data: ShiftPhoneCall = new ShiftPhoneCall();
    data.phoneNumber = dto.phoneNumber;
    data.notes = dto.notes;
    return data;
  }

  public mapShiftPhoneCallDTO(data: ShiftPhoneCall): IShiftPhoneCall {
    let dto: IShiftPhoneCall = {
      phoneNumber: data.phoneNumber,
      notes: data.notes
    };
    return dto;
  }

  public mapShiftNotificationRequestDTO(data: ShiftNotificationRequest): IShiftNotificationRequest {
    let dto: IShiftNotificationRequest = {
      employeeIds: data.employeeIds,
      positionId: data.positionId,
      date: dateTimeUtils.convertToDtoString(data.date),
      text: data.text,
      unitId: data.unitId,
    };
    return dto;
  }

  public mapShiftNotificationResponses(dto: IShiftNotificationResponse[]): ShiftNotificationResponse[] {
    let data: ShiftNotificationResponse[];
    data = _.map(dto, (record: IShiftNotificationResponse) => { return this.mapShiftNotificationResponse(record); });
    return data;
  }

  public mapShiftNotificationResponse(dto: IShiftNotificationResponse): ShiftNotificationResponse {
    let data: ShiftNotificationResponse = new ShiftNotificationResponse();
    data.employeeId = dto.employeeId;
    if (dto.appResponse) {
      data.appResponse = this.mapShiftAppNotificationResponse(dto.appResponse);
    }
    if (dto.smsResponse) {
      data.smsResponse = this.mapShiftSmsNotificationResponse(dto.smsResponse);
    }

    return data;
  }

  public mapShiftEligibleEmployee(dto: IShiftEligibleEmployee): ShiftEligibleEmployee {
    let data: ShiftEligibleEmployee = new ShiftEligibleEmployee();
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.position = this.lookupMapService.mapPosition(dto.position);
    if (dto.department) {
      data.department = this.lookupMapService.mapDepartment(dto.department);
    } else {
      data.department = new Department();
    }
    data.acaType = dto.acaType;
    data.acaAvgHours = dto.acaAvgHours;
    data.isAgency = dto.isAgency;
    data.isAvailable = dto.isAvailable;
    data.isWorkingOtherShift = dto.isWorkingOtherShift;
    data.isDayOff = dto.isDayOff;
    data.union = dto.union;
    data.weeklyScheduledHours = dto.weeklyScheduledHours;
    data.projectedHours = dto.projectedHours;
    data.weeklyWorkedHours = dto.weeklyWorkedHours;
    data.weeklyProjectedHours = dto.weeklyProjectedHours;
    data.payRate = dto.payRate;
    data.homePhone = dto.homePhone;
    data.mobilePhone = dto.mobilePhone;
    data.altPhone = dto.altPhone;
    data.approachingOT = dto.approachingOT;

    data.isPhoneCalled = dto.isPhoneCalled;
    data.isSmsSent = dto.isSmsSent;
    data.isAppNotificationSent = dto.isAppNotificationSent;

    data.canReceiveSms = data.mobilePhone && data.mobilePhone.match(commonConfig.phonePattern) !== null;
    data.isAnyResponse = data.isSmsSent || data.isAppNotificationSent;

    if (dto.phoneCall) {
      data.phoneCall = this.mapShiftPhoneCall(dto.phoneCall);
    } else { data.phoneCall = null; }

    if (dto.smsResponse) {
      data.smsResponse = this.mapShiftSmsNotificationResponse(dto.smsResponse);
    } else { data.smsResponse = null; }

    if (dto.appResponse) {
      data.appResponse = this.mapShiftAppNotificationResponse(dto.appResponse);
    } else { data.appResponse = null; }

    data.empOptIn = dto.empOptIn;
    data.canSendSms = dto.canSendSms;
    data.scheduledShifts = this.mapScheduledShifts(dto.scheduledShifts);
    return data;
  }

  public mapScheduledShifts(dto: IScheduledShift[]): ScheduledShift[] {
    return _.map(dto, (item) => this.mapScheduledShift(item));
  }

  public mapScheduledShift(dto: IScheduledShift): ScheduledShift {
    const data: ScheduledShift = new ScheduledShift();
    data.empId = +dto.empId;
    data.jobCode = +dto.jobCode;
    data.jobDescription = dto.jobDescription;
    data.shiftId = +dto.shiftId;
    data.shiftDescription = dto.shiftDescription;
    data.unitId = +dto.unitId;
    data.unitDescription = dto.unitDescription;
    data.workDate = dto.workDate;
    data.shiftStartTime = dto.shiftStartTime;
    data.shiftEndTime = dto.shiftEndTime;
    data.shiftTotalHours = dto.shiftTotalHours;
    return data;
  }

  public mapShiftReplacementInfo(dto: IShiftReplacementInfo): ShiftReplacementInfo {
    let data: ShiftReplacementInfo = new ShiftReplacementInfo();
    data.canSendSms = dto.canSendSms;
    data.records = _.map(dto.records, (record: IShiftEligibleEmployee) => {
      return this.mapShiftEligibleEmployee(record);
    });
    return data;
  }
}
