import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';


export function unsubscribeInService(): PropertyDecorator {
  return function decorate(target: any, key: string | symbol): void {
    let destroyFunction: Function = target['destroy'];
    let destroy: Function = function (): void {
      if (this) {
        let subscription: Subscription = this[key];
        if (subscription) {
          subscription.unsubscribe();
        }
        if (destroyFunction) {
          destroyFunction.call(this);
        }
      }
    };
    target['destroy'] = destroy;
  };
}
