import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList, ContentChildren } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy, filterBy, SortDescriptor } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent, PageChangeEvent
} from '@progress/kendo-angular-grid';

import { IFilteredItems, ServerCompositeFilterDescriptor, PagingData, MetadataInfo } from '../../../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { unsubscribe } from '../../../../../core/decorators/index';

import { PBJExportLogRecord } from '../../../models/index';
import { PbjExportLogManagementService } from '../../../services/pbj-export/pbj-export-log-management.service';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-log-grid',
  templateUrl: 'pbj-log-grid.component.html',
  styleUrls: ['pbj-log-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbjLogGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public records: IFilteredItems<PBJExportLogRecord>;
  public gridState: KendoGridStateHelper<PBJExportLogRecord>;
  public orgLevelId: number;
  public pageSize: number = 50;

  @unsubscribe()
  private loadStateSubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;

  constructor(private managementService: PbjExportLogManagementService, private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<PBJExportLogRecord>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'exportId', dir: 'desc' }];
    this.managementService.sort = this.gridState.state.sort;
    this.gridState.state.filter = null;
    this.gridState.state.group = [];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (records: IFilteredItems<PBJExportLogRecord>) => {
        this.records = records;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public pageChanged(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.gridPageChanged({ take: event.take, skip: event.skip });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.gridState.state.sort = sort;
    this.refreshGrid();
    this.managementService.sortChanged(this.gridState.state.sort);
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public gridPageChanged(pagingData: PagingData): void {
    this.managementService.pageChanged(pagingData);
  }


  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }

    let filteredRecords: PBJExportLogRecord[] = filterBy(this.records.items, this.gridState.state.filter);
    let sortedRecords: PBJExportLogRecord[] = orderBy(filteredRecords, this.gridState.state.sort);

    this.gridState.view = {
      data: sortedRecords,
      total: this.records.count
    };
  }
}

