import { Component, Input, Output, ViewEncapsulation, EventEmitter, OnInit, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../app.config';

import {
  GridDataResult,
  PageChangeEvent
} from '@progress/kendo-angular-grid';

import { EmployeeSearchResult } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-search-results',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: 'employee-search-results.component.html',
  styleUrls: ['employee-search-results.component.scss']
})
export class EmployeeSearchResultsComponent implements OnInit {

  @Input()
  public set searchResults(value: EmployeeSearchResult[]) {
    this.data = value;
    this.loadPage();
  }

  @Output()
  public backToQuery: EventEmitter<any> = new EventEmitter();

  public appConfig: IApplicationConfig;
  public gridView: GridDataResult;
  public data: any[];

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.loadPage();
  }

  public onBackClick(): void {
    this.backToQuery.emit();
  }

  private loadPage(): void {
    if (!this.data) {
      this.gridView = null;

      return;
    }

    this.gridView = {
      data: this.data,
      total: this.data.length
    };
  }
}
