import { Component, OnInit, OnDestroy, OnChanges, Input, Output, Host, ViewChild, NgZone, Provider, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

import { Assert } from '../../../../../framework/index';
import { process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { LookupService } from '../../../../../organization/services/index';
import { Lookup, LookupType, ILookupRequest } from '../../../../../organization/models/index';
import { EmployeeSectionsPayCycles, EmployeeSectionsPayCycleRecord, EmployeeSectionsPayCycleCalcConf, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsPayCycleConfComponent } from '../employee-sections-pay-cycle-conf/employee-sections-pay-cycle-conf.component';
import { DataStateChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { DialogOptions } from '../../../../../common/models/dialog-options';
import * as moment from 'moment';
import { PayCyclePeriod, PayCyclePeriodType } from '../../../../../organization/models/lookup/pay-cycle-period';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper } from '../../../../../../app/common';
import { ExcelExportData, Workbook, WorkbookSheet, WorkbookSheetRow } from '@progress/kendo-angular-excel-export';
import { BoolYNPipe } from '../../../../../common/pipes/boolyn';
@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-pay-cycles',
  templateUrl: 'employee-sections-pay-cycles.component.html',
  styleUrls: ['employee-sections-pay-cycles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmployeeSectionsPayCyclesComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  public employeeName: string;
  public employeePosition: string;
  public employeeType: string;
  public employeePayRollNumber: string;
  public createdDateTime: string = moment().format(appConfig.militaryDateTimeFormat);

  @Input() public employeeRehireDate : Date;
  @Input() public employeeStatus: string;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private orgLevelId: number ;
  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('gridForm', { static: true })
  public ngForm: NgForm;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  
  @Input('employeeSectionsPayCycles')
  public set payCycles(employeeSectionsPayCycles: EmployeeSectionsPayCycles) {
    this.employeeSectionsPayCycles = employeeSectionsPayCycles;
    const canEdit: boolean = _.get(employeeSectionsPayCycles, 'actions.canEdit', null);
    if (_.isBoolean(canEdit)) {
      this.canEdit = canEdit;
    }
    this.refreshGrid();
  }

  @Input()
  public employeeId: number;

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public startDate: Date;
  public endDate: Date;
  public sort: SortDescriptor[];
  public gridState: KendoGridStateHelper<EmployeeSectionsPayCycles>;
  public pageSize: number = 5;
  public skip: number = 0;
  public employeeSectionsPayCycles: EmployeeSectionsPayCycles;
  public appConfig: IApplicationConfig;
  public canEdit: boolean;
  public filteredRecords: EmployeeSectionsPayCycleRecord[];
  
  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;
  private modalService: ModalService;
  private changeDetector: ChangeDetectorRef;
  private init: boolean = false;
  public selectedRange: RangeDates;
  public employeeActionToolBar: boolean = true;
  public pdfTemplate: any = {
    allPages: true,
    landscape: true,
    paperSize: 'A4',
    scale: 0.7,
    repeatHeaders: true,
    margin: '0.25in',
    marginTop: '0.675in',
    marginBottom: '0.5in',
    marginRight: '0.25in',
    marginLeft: '0.25in'
  }

  constructor(
    employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    modalService: ModalService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    changeDetector: ChangeDetectorRef,
    private boolPipe: BoolYNPipe
  ) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<EmployeeSectionsPayCycles>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
    this.modalService = modalService;
    this.changeDetector = changeDetector;
    this.canEdit = false;
    this.sort = [{ field: 'startDate', dir: 'desc' }];
    const endDate: moment.Moment = moment().endOf('day');
    this.endDate = endDate.toDate();
    this.startDate = endDate.subtract(1, 'year').startOf('day').toDate();
    this.selectedRange = new RangeDates();
    this.selectedRange.startDate = this.startDate;
    this.selectedRange.endDate = this.endDate;
    this.employeeName = this.employeeShortInfo.fullName;
    this.employeePosition = this.employeeShortInfo.position.name;
    this.employeeType = this.employeeShortInfo.type;
    this.employeePayRollNumber = this.employeeShortInfo.payrollNumber;
  }

  public ngOnInit(): void {  
    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => _.isNumber(o.id))
      .subscribe((o: OrgLevel) => {
        this.orgLevelId=o.id;
      });
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => ({
      data: process(this.filteredRecords, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
    }) as ExcelExportData;
  }

  private exportTo(isPDF: boolean): void {
    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  public onClickExportPayCycle(isPDF: boolean): void {
    this.exportTo(isPDF);
  }

  public getPayCycleFileName(isPDF: boolean): string {
    if(isPDF) {
      return this.employeeName + ' Pay Cycles History From ' + this.getPayCycleDates()+'.pdf';
    }
    else {
      return this.employeeName + ' Pay Cycles History From ' + this.getPayCycleDates()+'.xlsx';
    }
  }

  public getPayCycleTitle(): string {
    return 'Pay Cycles TimeCard History for the Period ' + this.getPayCycleDates();
  }

  public getPayCycleDates(): string {
    return moment(this.selectedRange.startDate).format(appConfig.dateFormat) + ' to ' + moment(this.selectedRange.endDate).format(appConfig.dateFormat);
  }

  public getPayCycleEmployeeInfo(): string {
    return this.employeeName + '   ' + this.employeePosition + '   ' + this.employeeType + '   ' + this.employeePayRollNumber;
  }

  public onExcelExportPayCycle(e: { workbook: Workbook }): void {
    const sheets: WorkbookSheet = _.head(_.get(e, 'workbook.sheets'));
    _.forEach(sheets.rows, (row: WorkbookSheetRow) => {
      if (row.type === 'data') {
        _.forEach(row.cells, (cell) => {
          if (_.isBoolean(cell.value)) {
            cell.value = this.boolPipe.transform(cell.value);
          }
        });
      }
    });
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsPayCycles;
  }

  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.selectedRange = { startDate, endDate };
    this.skip = 0;
    this.refreshGrid();
  }

  public onStartModify(): void {
    let conf: EmployeeSectionsPayCycleCalcConf = new EmployeeSectionsPayCycleCalcConf();
    let yesterday: Date = moment().startOf('day').subtract(1, 'days').toDate();
    conf.effectiveDate = yesterday;
    const ordered = _.orderBy(this.employeeSectionsPayCycles.records, (r: EmployeeSectionsPayCycleRecord) => {
      return r.endDate;
    }, 'desc');
    const filtered = _.filter(ordered, (r: EmployeeSectionsPayCycleRecord) => {
      return r.approved;
    });
    
    if(this.employeeStatus.toLowerCase() === "future rehire")
    {
      conf.minEffectiveDate = this.findPayCycle(this.employeeSectionsPayCycles.records, this.employeeRehireDate);
      conf.effectiveDate = this.findPayCycle(this.employeeSectionsPayCycles.records, this.employeeRehireDate);
    }
    else
    {
      conf.minEffectiveDate = filtered && filtered.length > 0 ? filtered[0].endDate : null;
    }


    conf.payCycle = this.getDefaultPayCyclePeriod();
    conf.startDay1 = 1;
    conf.startDay2 = 16;
    let options: DialogOptions = new DialogOptions();
    options.height = 370;
    options.width = 600;

    let resolvedProvidersConf: Provider[] = [
      { provide: DialogOptions, useValue: options },
      { provide: EmployeeSectionsPayCycleCalcConf, useValue: conf },
    ];
    this.modalService.globalAnchor.openDialog(EmployeeSectionsPayCycleConfComponent, 'Assign Pay Cycle', options, resolvedProvidersConf, (result: boolean) => {
      if (result) this.doModify(conf);
    }
    );
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState.state = state;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  protected doModify(conf: EmployeeSectionsPayCycleCalcConf): void {
    Assert.isNotNull(conf, 'EmployeeSectionsPayCycleCalcConf');
    this.startProgress();
    this.employeeSectionsEmploymentApiService.setEmploymentPayCyclesConf(this.orgLevelId,this.employeeId, conf)
      .then((status: any) => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected getDefaultPayCyclePeriod(): PayCyclePeriod {
    let period: PayCyclePeriod = new PayCyclePeriod();
    period.name = PayCyclePeriodType.weekly;
    period.description = 'Weekly';
    return period;
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmploymentPayCycles(this.employeeId)
      .then((employeeSectionsPayCycles: EmployeeSectionsPayCycles) => {
        this.employeeSectionsPayCycles = employeeSectionsPayCycles;
        this.canEdit = _.get(employeeSectionsPayCycles, 'actions.canEdit', false);
        this.state.isLoaded = true;
        this.skip = 0;
        this.refreshGrid();
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }).catch(() => {
        this.stopProgress();
      });
  }

  private refreshGrid(): void {
    if (!this.employeeSectionsPayCycles) {
      this.gridState.view = null;
      return;
    }
    this.filteredRecords = _.filter(this.employeeSectionsPayCycles.records, (record: EmployeeSectionsPayCycleRecord) => {
      return moment(record.startDate).isBefore(this.endDate) && moment(record.endDate).isAfter(this.startDate);
    });
    let sortedRecords: EmployeeSectionsPayCycleRecord[] = orderBy(this.filteredRecords, this.sort);
    this.gridState.view = process(sortedRecords, this.gridState.state);
  }

  private findPayCycle(cycles: any[], date: Date): any {
    const currentDate: moment.Moment = moment(date).startOf('day');
    let selectedCycle: any = _.find(cycles, (cycle: any) => {
      return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
    });

    return selectedCycle.startDate;
  }
}
