import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const MAX_DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxDateValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: `[slxMaxDate][formControlName],[slxMaxDate][formControl],[slxMaxDate][ngModel]`,
  /* tslint:enable */
  providers: [MAX_DATE_VALIDATOR]
})
export class MaxDateValidator implements Validator, OnChanges {
  @Input()
  public maxDate: string;
  @Input()
  public set slxMaxDate(value: string) {
    this.maxDate = value;
  }
  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxMaxDate']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): {[key: string]: any} {
    return !_.isNil(this.maxDate) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    //this._onChange = fn;
    this._onChange = () => { Promise.resolve(null).then(() => fn()); };
  }

  private _createValidator(): void {
    this._validator = CommonValidators.maxDate(this.maxDate);
  }
}
