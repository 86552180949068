import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/do';
import * as _ from 'lodash';
import { appConfig } from '../../../app.config';
import { DialogOptions, ModalService } from '../../../common/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { ScheduleApiService } from '../../services/index';
var RestoreScheduleComponent = /** @class */ (function () {
    function RestoreScheduleComponent(scheduleApiService, options, modalService) {
        this.scheduleApiService = scheduleApiService;
        this.options = options;
        this.modalService = modalService;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false
        };
        this.onCancel = new EventEmitter();
    }
    RestoreScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true;
        this.backupSubscription = this.orgLevel$
            .do(function (orgLevel) { return _this.currentOrgLevel = orgLevel; })
            .switchMap(function (orgLevel) { return _this.scheduleApiService.getBackups(orgLevel); })
            .subscribe(function (backups) {
            _this.backupGridData = {
                data: backups,
                total: backups.length
            };
            _this.state.isLoading = false;
        });
    };
    RestoreScheduleComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    RestoreScheduleComponent.prototype.onSelectedChanged = function (selection) {
        var _this = this;
        this.selectedBackup = null;
        _.forEach(selection.selectedRows, function (args) {
            _this.selectedBackup = _this.backupGridData.data[args.index];
        });
        /* Prior version
          if ($event.selected) {
            this.selectedBackup = this.backupGridData.data[$event.index];
          } else {
            this.selectedBackup = null;
          }
        */
    };
    RestoreScheduleComponent.prototype.restoreBackup = function () {
        var _this = this;
        if (this.selectedBackup) {
            this.state.isLoading = true;
            this.scheduleApiService.backupApply(this.currentOrgLevel, this.selectedBackup.id)
                .then(function () {
                _this.state.isLoading = false;
                _this.dialogResult = true;
                _this.modalService.closeWindow(_this.options.windowUniqueId);
            })
                .catch(function () {
                _this.state.isLoading = false;
            });
        }
    };
    RestoreScheduleComponent.prototype.cancelRestoring = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], RestoreScheduleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RestoreScheduleComponent.prototype, "backupSubscription", void 0);
    return RestoreScheduleComponent;
}());
export { RestoreScheduleComponent };
