import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { unsubscribe, unsubscribeAll } from '../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { ColumnManagementService, StateManagementService } from '../../../common';
import { AcaC1095AuditManagementService } from '../../services/aca-c1095-audit/aca-c1095-audit-management.service';
import { AcaC1095Audit, AcaC1095AuditRecord } from '../../models/aca-c1095-audit';
import { PagingData } from '../../../core/models/index';

@Component({
  selector: 'slx-aca-c1095-audit',
  templateUrl: './aca-c1095-audit.component.html',
  styleUrls: ['./aca-c1095-audit.component.scss'],
  providers: [ColumnManagementService, StateManagementService, AcaC1095AuditManagementService]
})
export class AcaC1095AuditComponent implements OnInit {
  public isLoading: boolean = false;
  private readonly componentId = 'AcaC1095AuditComponent';

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private acaC1095AuditManagementService: AcaC1095AuditManagementService,
    private stateManagementService: StateManagementService,
  ) {

  }
  public ngOnDestroy(): void { }
  public ngOnInit() {
    this.stateManagementService.init(this.componentId, true);
    this.acaC1095AuditManagementService.init();
    this.subscriptions.loading = this.acaC1095AuditManagementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }
}


