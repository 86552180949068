import * as tslib_1 from "tslib";
import { Lookup, LookupType, ScheduleCycle } from '../../../../organization/models/index';
import { IndividualScheduleManagementService } from './../../../services/individual-schedule/individual-schedule-management.service';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { CalendarDataService } from '../../../../core/services/index';
import { DateRange } from '../../../../core/models/index';
import { IndividualScheduleApiService, ScheduleEntryApiService } from '../../../services/index';
import { LookupApiService } from '../../../../organization/services/lookup/lookup-api.service';
import { DateTimeService } from '../../../../common/services/date-time/date-time.service';
import { IndividualScheduleEmpManagementService } from '../../../services/index';
import { EmployeeShiftEditorComponent } from '../../../../organization/components/index';
import { EmployeeShift } from '../../../../organization/models/index';
import { ModalService } from '../../../../common/services';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../common';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { LookupService } from './../../../../organization/services/lookup/lookup.service';
import { DatePipe } from '@angular/common';
import { AppSettingsManageService } from '../../../../app-settings/services';
var IndividualScheduleEntriesEditorComponent = /** @class */ (function () {
    function IndividualScheduleEntriesEditorComponent(lookupApiService, scheduleEntryApiService, individualScheduleApiService, individualScheduleManagementService, individualScheduleEmpManagementService, calendarDataService, dateTimeService, modalService, lookupService, datePipe, appSettingsManageService) {
        this.scheduleEntryApiService = scheduleEntryApiService;
        this.individualScheduleApiService = individualScheduleApiService;
        this.individualScheduleManagementService = individualScheduleManagementService;
        this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
        this.calendarDataService = calendarDataService;
        this.dateTimeService = dateTimeService;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.datePipe = datePipe;
        this.appSettingsManageService = appSettingsManageService;
        this.showEmployeeName = true;
        this.onHasChanges = new EventEmitter();
        this.subscriptions = {};
        this.isShiftLoadingState = true;
        this.isModifyPayPeriodApproved = false;
        this.state = {
            startEditMode: false,
            editMode: false,
            isValid: true,
            isDirty: false,
            isFatalOverlapping: false,
        };
    }
    Object.defineProperty(IndividualScheduleEntriesEditorComponent.prototype, "scheduledEntries", {
        get: function () {
            return this.m_scheduledEntries;
        },
        set: function (value) {
            this.m_scheduledEntriesOriginal = this.copyEntries(this.flatEntries(value));
            this.m_scheduledEntries = value;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleEntriesEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.requestSubscription = this.individualScheduleEmpManagementService.onRequestChanged$.subscribe(function (request) {
            _this.loadShiftDefinitionsLookup(request);
            _this.setRequest(request);
        });
        this.getSettings();
    };
    IndividualScheduleEntriesEditorComponent.prototype.ngOnDestroy = function () {
        if (this.requestSubscription) {
            this.requestSubscription.unsubscribe();
        }
    };
    IndividualScheduleEntriesEditorComponent.prototype.emitChanges = function () {
        this.onHasChanges.emit(true);
    };
    IndividualScheduleEntriesEditorComponent.prototype.setRequest = function (request) {
        if (!request) {
            this.request = null;
            return;
        }
        if (!this.employeeShift || !this.request || (this.request.employeeId !== request.employeeId)) {
            this.employeeShift = new EmployeeShift();
        }
        this.request = request;
        this.state.startEditMode = false;
        this.state.editMode = false;
        this.dayColumns = this.calendarDataService.ExtendRangeToDailyData(moment(this.request.startDate), 7, null);
        this.loadScheduledEntries();
    };
    IndividualScheduleEntriesEditorComponent.prototype.setScheduleDateRange = function () {
        if (!(_.isNil(this.scheduledEntries))) {
            var startDate = _.first(_.values(_.first(this.scheduledEntries.weeklyData).days));
            var endDate = _.last(_.values(_.last(this.scheduledEntries.weeklyData).days));
            this.scheduleDateRange = new DateRange(startDate.startOfDay.toDate(), endDate.startOfDay.toDate());
        }
        else {
            this.scheduleDateRange = null;
        }
    };
    IndividualScheduleEntriesEditorComponent.prototype.onEditModeStart = function () {
        if (_.isNull(this.homePositionId))
            return;
        this.state.startEditMode = true;
        this.state.editMode = true;
    };
    IndividualScheduleEntriesEditorComponent.prototype.onEditMode = function () {
        this.state.startEditMode = false;
    };
    IndividualScheduleEntriesEditorComponent.prototype.onCancelClicked = function () {
        this.state.startEditMode = false;
        this.state.editMode = false;
        this.loadScheduledEntries();
    };
    IndividualScheduleEntriesEditorComponent.prototype.onAcceptClicked = function () {
        this.saveChanges();
    };
    IndividualScheduleEntriesEditorComponent.prototype.saveChanges = function () {
        var _this = this;
        this.state.startEditMode = false;
        this.state.editMode = false;
        this.individualScheduleEmpManagementService.onUILock(true);
        if (!this.checkedEntries) {
            this.validateEntries();
        }
        var entries = _.filter(this.checkedEntries, function (entry) { return entry.isDirty; });
        var datesToClear = this.identifyDatesOnScheduleToClear(this.checkedEntries, this.m_scheduledEntriesOriginal);
        if (this.isModifyPayPeriodApproved) {
            var req = this.formReqForcheckApprovedPayperiod(this.checkedEntries, datesToClear);
            this.scheduleEntryApiService.getApprovedPayPeriod(req).then(function (res) {
                if (res == true) {
                    _this.showWarningMessage();
                }
                else {
                    _this.saveEmployeeScheduleDefinitionsServiceCall(_this.request.employeeId, entries, datesToClear);
                }
            });
        }
        else {
            this.saveEmployeeScheduleDefinitionsServiceCall(this.request.employeeId, entries, datesToClear);
        }
    };
    IndividualScheduleEntriesEditorComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        console.log("modifyPayperiod", this.isModifyPayPeriodApproved);
                        return [2 /*return*/];
                }
            });
        });
    };
    IndividualScheduleEntriesEditorComponent.prototype.saveEmployeeScheduleDefinitionsServiceCall = function (empId, entries, datesToClear) {
        var _this = this;
        this.individualScheduleApiService.saveEmployeeScheduleDefinitions(empId, entries, datesToClear)
            .then(function (result) {
            _this.individualScheduleEmpManagementService.isDirty = false;
            _this.loadScheduledEntries();
            _this.individualScheduleEmpManagementService.employeeUpdated();
            _this.emitChanges();
        });
    };
    IndividualScheduleEntriesEditorComponent.prototype.formReqForcheckApprovedPayperiod = function (entries, datesToClear) {
        var _this = this;
        var req = [];
        entries.forEach(function (x) {
            if (x.entryDef.shifts.length > 0) {
                x.entryDef.shifts.forEach(function (y) {
                    var obj = { EmpId: _this.request.employeeId, Startdate: _this.datePipe.transform(y.start, 'MM-dd-yyyy HH:mm:ss'), Enddate: _this.datePipe.transform(y.end, 'MM-dd-yyyy HH:mm:ss') };
                    req.push(obj);
                });
            }
        });
        if (datesToClear) {
            datesToClear.forEach(function (element) {
                var obj = { EmpId: _this.request.employeeId, Startdate: _this.datePipe.transform(element, 'MM-dd-yyyy HH:mm:ss'), Enddate: _this.datePipe.transform(element, 'MM-dd-yyyy HH:mm:ss') };
                req.push(obj);
            });
        }
        return req;
    };
    IndividualScheduleEntriesEditorComponent.prototype.showWarningMessage = function () {
        var _this = this;
        var message = this.scheduledEntries.canEditScheduleApprovedPayPeriod ?
            "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the\n      employee, are you sure you want to continue?" :
            "You do not have permissions to modify a schedule in an approved pay period";
        var popupOptions = new ConfirmOptions();
        popupOptions.showCancel = true;
        popupOptions.showOK = this.scheduledEntries.canEditScheduleApprovedPayPeriod ? true : false;
        popupOptions.buttonOKtext = 'Ok';
        popupOptions.buttonCanceltext = this.scheduledEntries.canEditScheduleApprovedPayPeriod ? 'Cancel' : 'Ok';
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            if (result) {
                var entries = _.filter(_this.checkedEntries, function (entry) { return entry.isDirty; });
                var datesToClear = _this.identifyDatesOnScheduleToClear(_this.checkedEntries, _this.m_scheduledEntriesOriginal);
                _this.saveEmployeeScheduleDefinitionsServiceCall(_this.request.employeeId, entries, datesToClear);
            }
            else {
                _this.onCancelClicked();
            }
        }, popupOptions);
    };
    IndividualScheduleEntriesEditorComponent.prototype.checkAndFixOverlappings = function () {
        var _this = this;
        var i = 0;
        var j = 0;
        _.times(this.checkedEntries.length, function () {
            var entry1 = _this.checkedEntries[i];
            j = i;
            _.times(_this.checkedEntries.length - i, function () {
                var entry2 = _this.checkedEntries[j];
                _this.fixOverlappings(entry1, entry2);
                j++;
            });
            i++;
        });
    };
    IndividualScheduleEntriesEditorComponent.prototype.fixOverlappings = function (entry1, entry2) {
        var _this = this;
        var errors = {};
        var shifts1 = entry1.entryDef.shifts;
        var shifts2 = entry2.entryDef.shifts;
        var shift1counter = 0;
        var shift2counter = 0;
        _.forEach(shifts1, function (shift1) {
            shift2counter = 0;
            _.forEach(shifts2, function (shift2) {
                var date1 = moment(entry1.date);
                var date2 = moment(entry2.date);
                if (!date1.isSame(date2) || (date1.isSame(date2) && shift1counter !== shift2counter && !errors[shift2counter])) {
                    if (_this.dateTimeService.isDateRangeOverlapped(moment(shift1.start), moment(shift1.end), moment(shift2.start), moment(shift2.end), false)) {
                        shift2.start = moment(shift1.end).add(1, 'millisecond').toDate();
                        shift2.duration = moment.range(shift1.end, shift2.end).diff();
                        errors[shift1counter] = true;
                    }
                }
                shift2counter++;
            });
            shift1counter++;
        });
    };
    IndividualScheduleEntriesEditorComponent.prototype.onEntryChanged = function (entry) {
        this.individualScheduleEmpManagementService.isDirty = true;
        this.state.isDirty = true;
    };
    IndividualScheduleEntriesEditorComponent.prototype.onRequiredFieldsPopulated = function () {
        this.onEditMode();
    };
    IndividualScheduleEntriesEditorComponent.prototype.getCssClass = function (dailyData) {
        return _.isDate(this.request.currentDate) && dailyData.startOfDay.isSame(this.request.currentDate) ? 'highlighted' : '';
    };
    IndividualScheduleEntriesEditorComponent.prototype.outOfCycle = function (dailyData) {
        var sc = this.individualScheduleManagementService.selectedCycle;
        var cycleStartDate = sc ? sc.startDate : this.cycleStartDate;
        var cycleEndDate = sc ? sc.endDate : this.cycleEndDate;
        return !dailyData.startOfDay.isBetween(cycleStartDate, cycleEndDate, null, '[]');
    };
    IndividualScheduleEntriesEditorComponent.prototype.outOfEmpWorkDates = function (dailyData) {
        if (this.empTerminatedDate) {
            if (this.empRehireDate) {
                var sc = this.individualScheduleManagementService.selectedCycle;
                var cycleEndDate = sc ? sc.endDate : this.cycleEndDate;
                return !dailyData.startOfDay.isBetween(this.empHiredDate, this.empTerminatedDate, null, '[]')
                    && !dailyData.startOfDay.isBetween(this.empRehireDate, cycleEndDate, null, '[]');
            }
            else {
                return !dailyData.startOfDay.isBetween(this.empHiredDate, this.empTerminatedDate, null, '[]');
            }
        }
        else {
            return dailyData.startOfDay.isBefore(this.empHiredDate);
        }
    };
    IndividualScheduleEntriesEditorComponent.prototype.flatEntries = function (scheduleData) {
        var rawEntries = _.reduce(scheduleData.weeklyData, function (dailyRecordsResult, weeklyData) {
            var days = _.map(_.values(weeklyData.days), function (day) {
                return day.data;
            });
            return (dailyRecordsResult || []).concat(days);
        }, []);
        var entries = _.filter(rawEntries, function (entry) { return !!entry; });
        return entries;
    };
    IndividualScheduleEntriesEditorComponent.prototype.copyEntries = function (scheduleEntries) {
        return _.map(scheduleEntries, function (entry) {
            var copiedEntry = Object.assign({}, entry);
            return copiedEntry;
        });
    };
    IndividualScheduleEntriesEditorComponent.prototype.validateEntries = function () {
        var _this = this;
        this.state.isValid = true;
        this.state.isFatalOverlapping = false;
        var entries = this.flatEntries(this.scheduledEntries);
        var i = 0;
        var j = 0;
        _.times(entries.length, function () {
            var entry1 = entries[i];
            entry1.errors = null;
            j = i;
            _.times(entries.length - i, function () {
                var entry2 = entries[j];
                var errors = _this.checkShiftsOverlapped(entry1, entry2);
                if (errors) {
                    _this.state.isValid = false;
                }
                if (!entry1.errors) {
                    entry1.errors = errors;
                }
                j++;
            });
            i++;
        });
        this.checkedEntries = entries;
    };
    IndividualScheduleEntriesEditorComponent.prototype.identifyDatesOnScheduleToClear = function (newScheduleEntries, originalScheduleEntries) {
        var originalDates = _.map(originalScheduleEntries, function (entry) { return entry.date; });
        var newEtriesList = _.filter(newScheduleEntries, function (entry) { return _.some(entry.entryDef.shifts); });
        var newEtriesListDates = _.map(newEtriesList, function (entry) { return entry.date; });
        return _.difference(originalDates, newEtriesListDates); // originalDates.Except(entriesDates).ToArray();
    };
    IndividualScheduleEntriesEditorComponent.prototype.checkShiftsOverlapped = function (entry1, entry2) {
        var _this = this;
        var errors = {};
        var isOvelapped;
        var shifts1 = entry1.entryDef.shifts;
        var shifts2 = entry2.entryDef.shifts;
        var shift1counter = 0;
        var shift2counter = 0;
        _.forEach(shifts1, function (shift1) {
            shift2counter = 0;
            _.forEach(shifts2, function (shift2) {
                var date1 = moment(entry1.date);
                var date2 = moment(entry2.date);
                if (!date1.isSame(entry2.date) || (date1.isSame(entry2.date) && shift1counter !== shift2counter && !errors[shift2counter])) {
                    if (_this.dateTimeService.isDateRangeOverlapped(moment(shift1.start), moment(shift1.end), moment(shift2.start), moment(shift2.end), false)) {
                        isOvelapped = true;
                        if (moment.range(shift1.start, shift1.end).contains(shift2.start) && moment.range(shift1.start, shift1.end).contains(shift2.end)) {
                            _this.state.isFatalOverlapping = true;
                            errors[shift1counter] = date1.format(_this.appConfig.monthDayDateFormat) + ": Shifts of this day are overlapped with shifts on date " + date2.format(_this.appConfig.monthDayDateFormat) + ". The shift can not be adjusted to remove the overlap.";
                        }
                        else {
                            errors[shift1counter] = date1.format(_this.appConfig.monthDayDateFormat) + ": Shifts of this day are overlapped with shifts on date " + date2.format(_this.appConfig.monthDayDateFormat) + ".";
                        }
                    }
                }
                shift2counter++;
            });
            shift1counter++;
        });
        if (isOvelapped) {
            return errors;
        }
        return null;
    };
    IndividualScheduleEntriesEditorComponent.prototype.loadScheduledEntries = function () {
        var _this = this;
        if (!this.request) {
            return;
        }
        this.individualScheduleEmpManagementService.onUILock(true);
        this.individualScheduleApiService.getOrgLevelEmployeeScheduleDefinitions(this.request)
            .then(function (result) {
            _this.individualScheduleEmpManagementService.onUILock(false);
            _this.scheduledEntries = result;
            _this.employeeShift.position = result.position;
            _this.employeeShift.unit = result.homeUnit;
            _this.employeeShift.shift = result.homeShift;
            _this.checkedEntries = null;
            _this.individualScheduleEmpManagementService.isDirty = false;
            _this.state.isDirty = false;
            _this.state.startEditMode = false;
            _this.state.editMode = false;
            _this.empHiredDate = null;
            _this.empTerminatedDate = null;
            _this.empRehireDate = null;
            if (result.employee) {
                _this.empHiredDate = moment(result.employee.dateHired);
                _this.empTerminatedDate = result.employee.dateTerminated ? moment(result.employee.dateTerminated) : null;
                _this.empRehireDate = moment(result.employee.daterehire);
            }
            _this.setScheduleDateRange();
        })
            .catch(function () {
            _this.individualScheduleEmpManagementService.onUILock(false);
        });
    };
    Object.defineProperty(IndividualScheduleEntriesEditorComponent.prototype, "homeShiftId", {
        get: function () {
            return this.employeeShift && this.employeeShift.shift ? this.employeeShift.shift.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEntriesEditorComponent.prototype, "homeUnitId", {
        get: function () {
            return this.employeeShift && this.employeeShift.unit ? this.employeeShift.unit.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEntriesEditorComponent.prototype, "homePositionId", {
        get: function () {
            return this.employeeShift && this.employeeShift.position ? this.employeeShift.position.id : null;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleEntriesEditorComponent.prototype.isOutOfPositionPeriod = function (dailyData) {
        return dailyData.startOfDay.isBefore(this.employeeShift.position.startDate) || dailyData.startOfDay.isAfter(this.employeeShift.position.endDate);
    };
    IndividualScheduleEntriesEditorComponent.prototype.addShift = function (_dailyData) {
        if (!this.isOutOfPositionPeriod(_dailyData)) {
            var dateOfDay = moment(_dailyData.startOfDay).format(appConfig.dateFormat);
            this.individualScheduleEmpManagementService.cellClicked$.next(dateOfDay);
        }
    };
    IndividualScheduleEntriesEditorComponent.prototype.toggleLCol = function () {
        this.individualScheduleManagementService.changeEmployeeListVisibility();
    };
    IndividualScheduleEntriesEditorComponent.prototype.isDayReadOnly = function (dailyData) {
        return !this.state.editMode || this.outOfCycle(dailyData) || this.outOfEmpWorkDates(dailyData);
    };
    IndividualScheduleEntriesEditorComponent.prototype.isAddShiftReadOnly = function (dailyData) {
        return this.state.editMode && !this.outOfCycle(dailyData) && !this.outOfEmpWorkDates(dailyData);
    };
    IndividualScheduleEntriesEditorComponent.prototype.loadShiftDefinitionsLookup = function (request) {
        var _this = this;
        if (!request) {
            return;
        }
        this.isShiftLoadingState = true;
        this.lookupService.getLookup({ lookupType: LookupType.shift, employeeId: request.employeeId, orgLevelId: request.orgLevelId })
            .then(function (shiftLookup) {
            _this.shiftLookup = shiftLookup;
            _this.isShiftLoadingState = false;
        })
            .catch(function () {
            _this.isShiftLoadingState = false;
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], IndividualScheduleEntriesEditorComponent.prototype, "subscriptions", void 0);
    return IndividualScheduleEntriesEditorComponent;
}());
export { IndividualScheduleEntriesEditorComponent };
