import { IAccrualPolicyRule, AccrualPolicyRule } from './accrual-policy-rule';
export interface IAccrualPolicy {
  id: number;
  policyName: string;
  accrualType: string;
  rules: IAccrualPolicyRule[];
}

export class AccrualPolicy {
  public id: number;
  public policyName: string;
  public accrualType: string;
  public rules: AccrualPolicyRule[];
}

export interface IDynamicAccrualPolicies {
  key: string;
  value: boolean;
}

export class DynamicAccrualPolicies {
  public key: string;
  public value: boolean;
}

export class DynamicAccrualPolicy {
  id: number;
  accrualPolicy: AccrualPolicy;
  policyId: string;
  policyName: string;
  accrualType: string;
  isError: boolean;
}

