import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToRehire } from '../../../../models/index';
import { EmployeeRehireComponent } from '../employee-rehire/employee-rehire.component';
var EmployeeRehireDialogComponent = /** @class */ (function () {
    function EmployeeRehireDialogComponent(options, modalService, employee) {
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
        this.employee = employee;
        this.state = {
            isLoading: false,
        };
    }
    EmployeeRehireDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = request.dialogHeight;
        dialogOptions.width = 650;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EmployeeToRehire,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(EmployeeRehireDialogComponent, 'Employee Rehire', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.requestToRehire);
        });
        return dialog;
    };
    Object.defineProperty(EmployeeRehireDialogComponent.prototype, "requestToRehire", {
        get: function () {
            return this.employeeRehire.requestToRehire;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRehireDialogComponent.prototype, "isEmployeeRehireFormValid", {
        get: function () {
            if (this.employeeRehire && this.employeeRehire.ngForm) {
                return this.employeeRehire.ngForm.valid;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeRehireDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeRehireDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return EmployeeRehireDialogComponent;
}());
export { EmployeeRehireDialogComponent };
