/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pbj-duplicate-matches-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../components-library/components/datepicker/datepicker.component.ngfactory";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../components-library/components/datepicker/datepicker.component";
import * as i6 from "./pbj-duplicate-matches-dialog.component";
import * as i7 from "../../../models/pbj-duplicate-workers/pbj-duplicate-matches-dialog-options";
import * as i8 from "../../../../../common/models/dialog-options";
import * as i9 from "../../../../../common/services/modal/modal.service";
var styles_PbjDuplicateMatchesDialogComponent = [i0.styles];
var RenderType_PbjDuplicateMatchesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PbjDuplicateMatchesDialogComponent, data: {} });
export { RenderType_PbjDuplicateMatchesDialogComponent as RenderType_PbjDuplicateMatchesDialogComponent };
function View_PbjDuplicateMatchesDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please select the start date for the export. The output will include records with disposition dates on or after this date."]))], null, null); }
function View_PbjDuplicateMatchesDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No Matches exist in the selected date range, please select a different start date for your export."]))], null, null); }
export function View_PbjDuplicateMatchesDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PbjDuplicateMatchesDialogComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PbjDuplicateMatchesDialogComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "slx-datepicker", [["name", "selectedDate"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "slx-watermark", null]], [[null, "ngModelChange"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onMouseover($event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.selectedDate = $event) !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = (_co.onFilterDateChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_DatepickerInputComponent_0, i3.RenderType_DatepickerInputComponent)), i1.ɵdid(7, 16384, null, 0, i4.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.RequiredValidator]), i1.ɵdid(9, 671744, null, 0, i4.NgModel, [[8, null], [6, i4.NG_VALIDATORS], [8, null], [8, null]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(11, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), i1.ɵdid(12, 573440, null, 0, i5.DatepickerInputComponent, [i4.NgControl], { required: [0, "required"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn btn-danger bg-red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"])), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn btn-success bg-green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Export"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.foundMatches; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.foundMatches; _ck(_v, 5, 0, currVal_1); var currVal_11 = true; _ck(_v, 7, 0, currVal_11); var currVal_12 = "selectedDate"; var currVal_13 = _co.selectedDate; _ck(_v, 9, 0, currVal_12, currVal_13); var currVal_14 = true; _ck(_v, 12, 0, currVal_14); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 7).required ? "" : null); var currVal_3 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 11).ngClassValid; var currVal_8 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 11).ngClassPending; var currVal_10 = i1.ɵnov(_v, 12).watermarkClass; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_PbjDuplicateMatchesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-pbj-duplicate-matches-dialog", [], null, null, null, View_PbjDuplicateMatchesDialogComponent_0, RenderType_PbjDuplicateMatchesDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.PbjDuplicateMatchesDialogComponent, [i7.PbjDuplicateMatchesDialogOptions, i8.DialogOptions, i9.ModalService], null, null)], null, null); }
var PbjDuplicateMatchesDialogComponentNgFactory = i1.ɵccf("slx-pbj-duplicate-matches-dialog", i6.PbjDuplicateMatchesDialogComponent, View_PbjDuplicateMatchesDialogComponent_Host_0, {}, {}, []);
export { PbjDuplicateMatchesDialogComponentNgFactory as PbjDuplicateMatchesDialogComponentNgFactory };
