import * as _ from 'lodash';
import { Role } from './index';

export class RoleColumn {
  public id: string;
  public name: string;
  public visible: boolean;

  constructor(id: string, name: string, visible: boolean) {
    this.id = id;
    this.name = name;
    this.visible = visible;
  }
}

export class RoleColumnsState {
  public columns: RoleColumn[];

  constructor(roles: Role[] = []) {
    this.makeColumns(roles);
  }

  public applyState(columns: RoleColumn[]): void {
    if (_.isArray(columns) && _.size(columns) > 0) {
      _.each(columns, (stateCol: RoleColumn) => {
        const column: RoleColumn = _.find(this.columns, (col: RoleColumn) => col.id === stateCol.id);
        if (column) {
          column.visible = stateCol.visible;
        }
      });
    }
  }

  public applyFilter(columns: RoleColumn[]): void {

    _.each(this.columns, (col: RoleColumn) => {
      col.visible = false;
    });

    if (_.isArray(columns) && _.size(columns) > 0) {

      _.each(columns, (filterCol: RoleColumn) => {
        const column: RoleColumn = _.find(this.columns, (col: RoleColumn) => col.id === filterCol.id);
        if (column) {
          column.visible = true;
        }
      });
    }
  }

  public clearFilter(): void {
    _.each(this.columns, (col: RoleColumn) => {
      col.visible = true;
    });
  }

  public getVisibleColumns(): RoleColumn[] {
    return _.filter(this.columns, (col: RoleColumn) => {
      return col.visible;
    });
  }

  public isVisible(role: Role): boolean {

    const column: RoleColumn = _.find(this.columns, (col: RoleColumn) => col.id === this.getId(role));
    if (column) {
      return column.visible;
    }
    return false;
  }

  private getId<T extends Role | RoleColumn>(role: T): string {
    return `${role.id}_${role.name}`;
  }

  private makeColumns(roles: Role[]): void {
    if (_.isArray(roles) && _.size(roles) > 0) {

      this.columns = _.map(roles, (role: Role) => {
        const id: string = this.getId(role);
        const column: RoleColumn = new RoleColumn(id, role.name, true);
        return column;
      });
    } else {
      this.columns = [];
    }
  }
}
