import * as tslib_1 from "tslib";
import { appConfig } from '../../../app.config';
import { lmConfig } from '../leave-management.config';
import { ApiUtilService } from '../../../common/index';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';
import { dateTimeUtils } from '../../../common/utils/index';
import { ReadFile } from '../../../organization/models/index';
import { LoaRequestContainer, MetaContainer } from '../models/index';
import { LMMapService } from './lm-map.service';
var LMApiService = /** @class */ (function () {
    function LMApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    LMApiService.prototype.getLoaRequests = function (orgLevelId, sDate, eDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getRosterApi();
                params = {
                    orgLevelId: orgLevelId,
                    start: dateTimeUtils.convertToDtoString(sDate),
                    end: dateTimeUtils.convertToDtoString(eDate)
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) {
                        var fieldsMeta = response.meta;
                        var container = new LoaRequestContainer();
                        container.requests = _this.mapService.mapLoaRequests(response.data);
                        var metaContainer = new MetaContainer(container, fieldsMeta.actions);
                        return metaContainer;
                    })];
            });
        });
    };
    LMApiService.prototype.getLoaRequestsByOrgLevel = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getRosterApi();
                params = {
                    orgLevelId: orgLevelId
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) {
                        var fieldsMeta = response.meta;
                        var container = _this.mapService.mapLoaRequestContainer(response.data);
                        var metaContainer = new MetaContainer(container, fieldsMeta.actions);
                        return metaContainer;
                    })];
            });
        });
    };
    LMApiService.prototype.createLoaRequest = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getLoaApi();
                body = this.mapService.mapLoaRequestToDto(req);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaRequest(response.data); })];
            });
        });
    };
    LMApiService.prototype.updateLoaRequest = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getLoaApi();
                body = this.mapService.mapLoaRequestToDto(req);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaRequest(response.data); })];
            });
        });
    };
    LMApiService.prototype.deleteLoaRequest = function (requestId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getLoaApi();
                request = this.urlParamsService.createDeleteRequest(url, { requestId: requestId });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    LMApiService.prototype.getLoaConsole = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + lmConfig.api.loa.root + "/" + lmConfig.api.loa.console.root;
                params = {
                    orgLevelId: orgLevelId
                };
                request = this.urlParamsService.createGetRequest(url, params);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) {
                        var fieldsMeta = response.meta;
                        var console = _this.mapService.mapLoaConsole(response.data);
                        var container = new MetaContainer(console, fieldsMeta.actions);
                        return container;
                    })];
            });
        });
    };
    LMApiService.prototype.getLoaTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + lmConfig.api.loa.root + "/" + lmConfig.api.loa.types.root;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaTypes(response.data); })];
            });
        });
    };
    LMApiService.prototype.saveAddedFiles = function (requestId, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, formData, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + lmConfig.api.loa.root + "/" + lmConfig.api.loa.attachment.root;
                formData = this.mapService.mapFilesToFormData(files);
                request = this.urlParamsService.requestPost(url, formData, { requestId: requestId });
                request.autoContentType = true;
                return [2 /*return*/, this.apiUtilService
                        .requestNew(request)
                        .then(function (response) { return _this.mapService.mapLoaRequest(response.data); })];
            });
        });
    };
    LMApiService.prototype.downloadAttachment = function (documentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, promise;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + lmConfig.api.loa.root + "/" + lmConfig.api.loa.attachment.root;
                params = {
                    documentId: documentId
                };
                request = this.urlParamsService.createGetRequest(url, params);
                promise = this.apiUtilService.requestForFile(request)
                    .then(function (file) { return file; });
                return [2 /*return*/, promise];
            });
        });
    };
    LMApiService.prototype.deleteAttachment = function (documentId, requestId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + lmConfig.api.loa.root + "/" + lmConfig.api.loa.attachment.root;
                request = this.urlParamsService.createDeleteRequest(url, { documentId: documentId, requestId: requestId });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapLoaRequest(response.data); })];
            });
        });
    };
    LMApiService.prototype.getRosterApi = function () {
        return this.getLoaApi() + "/" + lmConfig.api.loa.roster.root;
    };
    LMApiService.prototype.getLoaApi = function () {
        return this.getApiRoot() + "/" + lmConfig.api.loa.root;
    };
    LMApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return LMApiService;
}());
export { LMApiService };
