import * as _ from 'lodash';

import { Component, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-checkbox-button',
  templateUrl: 'checkbox-button.component.html',
  providers: [createValuAccessor(CheckboxButtonComponent)]
})
export class CheckboxButtonComponent implements ControlValueAccessor {
  @Input()
  public placeholder: string;
  @Input()
  public className: string;
  @Input()
  public readonly: boolean;
  @Input()
  public fieldName: string;

  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(this.innerValue);
    }
  }

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: boolean;

  public writeValue(value?: any): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.innerValue = value;
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public get isChecked(): boolean {
    return  this.value === true;
  }

  public cssClass(): string {
    let cssClass: string = 'slx-checkbox';
    if (this.isChecked) {
      cssClass += ` checked`;
    }
    if (this.readonly) {
      cssClass += ` disabled`;
    }
    if (_.isString(this.className) && _.size(this.className) > 0) {
      cssClass += ` ${this.className}`;
    }

    return cssClass;
  }

  public hasPlaceholder(): boolean {
    return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
  }
}
