import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { AcaInsightFilter, AcaInsightSummaryRecords } from '../../../models';
import { unsubscribeAll } from '../../../../core/decorators';
import { Aca1095cManagementService } from '../../../services';
import * as _ from 'lodash';
var Aca1095cInsightsComponent = /** @class */ (function () {
    function Aca1095cInsightsComponent(managementService) {
        this.managementService = managementService;
        this.selectedFilter = 'All';
        this.subscriptions = {};
    }
    Aca1095cInsightsComponent.prototype.ngOnDestroy = function () {
    };
    Aca1095cInsightsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onInsightsLoaded = this.managementService
            .subscribeToInsightsRecords(function (insightSummary) {
            _this.insights = insightSummary;
            _this.selectedFilter = 'All';
        });
    };
    Aca1095cInsightsComponent.prototype.applyInsightsFilter = function (filterCase) {
        if (_.isEqual(this.selectedFilter, filterCase)) {
            return;
        }
        this.selectedFilter = filterCase;
        var insightsFilter = new AcaInsightFilter();
        insightsFilter.filter = filterCase;
        switch (filterCase) {
            case 'FT_Enrolled_FullYear': {
                insightsFilter.allRecords = false;
                insightsFilter.acaType = 'FT';
                insightsFilter.enrolledInd = true;
                insightsFilter.enrolledFullYear = true;
                break;
            }
            case 'FT_Enrolled_PartYear': {
                insightsFilter.allRecords = false;
                insightsFilter.acaType = 'FT';
                insightsFilter.enrolledInd = true;
                insightsFilter.enrolledPartYear = true;
                break;
            }
            case 'FT_NotEnrolled': {
                insightsFilter.allRecords = false;
                insightsFilter.acaType = 'FT';
                insightsFilter.enrolledInd = false;
                insightsFilter.enrolledFullYear = true;
                break;
            }
            case 'PT_Enrolled': {
                insightsFilter.allRecords = false;
                insightsFilter.acaType = 'PT';
                insightsFilter.enrolledInd = true;
                break;
            }
            case 'NotConfirmed': {
                insightsFilter.allRecords = false;
                insightsFilter.confirmInd = false;
                break;
            }
            default: {
                insightsFilter.allRecords = true;
                break;
            }
        }
        this.managementService.setInsightsFilter(insightsFilter);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], Aca1095cInsightsComponent.prototype, "subscriptions", void 0);
    return Aca1095cInsightsComponent;
}());
export { Aca1095cInsightsComponent };
