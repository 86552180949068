import { RoleDefinition } from '../../../../organization/models/index';
import { UserProfileWithOrgLevelDecorator } from './user-profile-with-orglevel.decorator';

export class RolesWithUsersDecorator {
  public usersRoles: RoleDefinition;

  public get id(): number {
    return this.usersRoles.id;
  }
  public get name(): string {
    return this.usersRoles.name;
  }
  public get assignedCount(): number {
    return this.m_assignedCount;
  }
  public set assignedCount(count:number) {
    this.m_assignedCount = count;
  }
  public get filteredCount(): number {
    return this.m_filteredCount;
  }
  public set filteredCount(count:number) {
    this.m_filteredCount = count;
  }
  public get users(): UserProfileWithOrgLevelDecorator[] {
    return this.m_users;
  }

  private m_assignedCount: number = 0;
  private m_filteredCount: number = 0;
  private m_users: UserProfileWithOrgLevelDecorator[] = [];

  constructor(usersRoles: RoleDefinition) {
    this.usersRoles = usersRoles;
  }

  public addUser(user: UserProfileWithOrgLevelDecorator): void {
    this.m_users.push(user);
  }
}
