
export * from './control-init-state.directive';
export * from './control-loaded-state.directive';
export * from './control-active-state.directive';
export * from './property-init-state.directive';

import { ControlInitStateDirective } from './control-init-state.directive';
import { ControlLoadedStateDirective } from './control-loaded-state.directive';
import { ControlActiveStateDirective } from './control-active-state.directive';
import { PropertyInitStateDirective } from './property-init-state.directive';

export const stateDirectives: any[] = [
  ControlInitStateDirective,
  ControlLoadedStateDirective,
  ControlActiveStateDirective,
  PropertyInitStateDirective
];
