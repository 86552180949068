import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OpenShiftWrapper } from '../../../models/index';
import { OpenShiftManagementApiService } from '../../../services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { screenUtils } from '../../../../common/utils/index';
import { DailyUnitGridEmployee } from '../daily-unit-grid-employees/daily-unit-grid-employees-model';
var SwitchGridEmployeeShiftComponent = /** @class */ (function () {
    function SwitchGridEmployeeShiftComponent(openShiftAPi) {
        this.openShiftAPi = openShiftAPi;
        this.records = [];
        this.onCancel = new EventEmitter();
        this.onSave = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.group = [{ field: 'details.shiftGroupName' }];
        this.screenUtils = screenUtils;
    }
    Object.defineProperty(SwitchGridEmployeeShiftComponent.prototype, "selectedItem", {
        get: function () {
            return this.m_selectedItem;
        },
        set: function (value) {
            this.m_selectedItem = value;
            this.name = value.EmpName;
            this.positionId = parseInt(value.JobCode);
            this.position = value.JobDescription;
            this.shift = value.ShiftName;
            this.unit = value.UnitName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SwitchGridEmployeeShiftComponent.prototype, "isFilterable", {
        get: function () {
            return !this.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    SwitchGridEmployeeShiftComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isMobile = screenUtils.isMobile;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            _this.openShiftAPi.getOpenShiftSummary(_this.currentOrgLevel.id, _this.date, { positionIds: [_this.positionId] }).then(function (shifts) {
                _this.shiftSummary = shifts;
                var filteredRecords = [];
                filteredRecords = shifts.details.filter(function (e) { return !e.partnerShiftId; });
                var groupedOpenShifts = _.groupBy(filteredRecords, function (shift) { return [shift.position.id, shift.unit.id, shift.shift.id].join(); });
                var openShifts = [];
                _.each(groupedOpenShifts, function (groupedShift) {
                    if (groupedShift.length > 0) {
                        var openShiftInfo = Object.assign(groupedShift[0], {});
                        openShiftInfo.adjustedHours = _.sumBy(groupedShift, function (shift) { return shift.adjustedHours; });
                        openShiftInfo.adjustedShiftCount = _.sumBy(groupedShift, function (shift) { return shift.adjustedShiftCount; });
                        openShiftInfo.idealHours = _.sumBy(groupedShift, function (shift) { return shift.idealHours; });
                        openShiftInfo.idealShiftCount = _.sumBy(groupedShift, function (shift) { return shift.idealShiftCount; });
                        openShiftInfo.openHours = _.sumBy(groupedShift, function (shift) { return shift.openHours; });
                        openShiftInfo.openShiftCount = _.sumBy(groupedShift, function (shift) { return shift.openShiftCount; });
                        openShiftInfo.overShiftCount = _.sumBy(groupedShift, function (shift) { return shift.overShiftCount; });
                        openShiftInfo.scheduledHours = _.sumBy(groupedShift, function (shift) { return shift.scheduledHours; });
                        openShiftInfo.scheduledShiftCount = _.sumBy(groupedShift, function (shift) { return shift.scheduledShiftCount; });
                        openShiftInfo.workTimeHours = _.sumBy(groupedShift, function (shift) { return shift.workTimeHours; });
                        if (openShiftInfo.openShiftCount > 0) {
                            openShifts.push(openShiftInfo);
                        }
                    }
                });
                var wrappers = [];
                _.each(openShifts, function (details) {
                    var wrapper = new OpenShiftWrapper();
                    wrapper.details = details;
                    wrappers.push(wrapper);
                });
                _this.records = wrappers;
                _this.refreshGrid();
            });
        });
    };
    SwitchGridEmployeeShiftComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    SwitchGridEmployeeShiftComponent.prototype.onSaveChanges = function () {
        this.onSave.emit(this.selectedShift.details);
    };
    SwitchGridEmployeeShiftComponent.prototype.onDiscardChanges = function () {
        this.onCancel.emit();
    };
    SwitchGridEmployeeShiftComponent.prototype.selectionChange = function (record, state) {
        if (record.isSelected) {
            this.selectedShift = record;
        }
        else {
            this.selectedShift = null;
        }
        _.each(this.records, function (r) {
            if (r !== record) {
                r.isSelected = false;
            }
        });
    };
    SwitchGridEmployeeShiftComponent.prototype.getField = function (fields, name) {
        return _.find(fields, function (field) {
            return field.name === name;
        });
    };
    SwitchGridEmployeeShiftComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], SwitchGridEmployeeShiftComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SwitchGridEmployeeShiftComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SwitchGridEmployeeShiftComponent.prototype, "gridRefreshSubscription", void 0);
    return SwitchGridEmployeeShiftComponent;
}());
export { SwitchGridEmployeeShiftComponent };
