
  <div *ngIf="isQuickConfirmResetStarted " class="message-line">
    <span class="message1">Quick Confirm Reset Active!</span>
    <span class="message2">Click a cell in the grid to apply confirm reset.</span>
  </div>
  
  <div class="shift-editor-holder">




 
    
  </div>
  <div class="buttons-holder">
    <button *ngIf="!isQuickConfirmResetStarted"  (click)="startQuickConfirmReset()" class="start-button">Start</button>
    <button *ngIf="isQuickConfirmResetStarted" (click)="saveQuickConfirmReset()" class="stop-button">Save</button>
    <button *ngIf="!showChanges" [disabled]="!hasChanges" (click)="showChangesClick()" class="show-button">Show Changes</button>
    <button *ngIf="showChanges" (click)="hideChangesClick()" class="hide-button">Hide Changes</button>
  
  </div>
  <div *ngIf="showChanges" class="changes-holder">
    <div class="changes-holder-title">
      <span>Last Changes</span>
      <hr>
    </div>
    <ng-container   *ngFor="let item of items; let index = index">  
      <div #ele  [ngClass]="{ hideChildren : ele.class !== 'showChildren' }">
      <div class="change-item">
        <span class="change-emp-name" (click)="ele.class = ele.class == 'showChildren' ? '' :  'showChildren'" >
          <em class="fa fa-angle-down" [ngClass]="{ hideArrow : ele.class !== 'showChildren' }" [ngClass]="{ showArrow : ele.class == 'showChildren' }"></em>
          <em class="fa fa-angle-right"  [ngClass]="{ hideArrow : ele.class == 'showChildren' }" [ngClass]="{ showArrow : ele.class !== 'showChildren' }" ></em>
          {{item.employeeName}}
        </span>      
        <button (click)="undoEdit(item)" class="undo-button">
          <i class="fas fa-undo-alt" aria-hidden="true"></i>
        </button>
      </div>
        <ng-container *ngFor="let monthItem of item.months; let index = index">       
          <div class="change-child-item">
            <span class="change-month-name">{{monthItem.monthName}}</span>
            <span class="change-value">{{monthItem.selectedCode}}  ${{monthItem.selectedCost}}  {{monthItem.selectedHarbor }}</span>       
            <button (click)="undoEdit(item,monthItem)" class="undo-button">
              <i class="fas fa-undo-alt" aria-hidden="true"></i>
            </button>
          </div>
        </ng-container>   
      </div>
    
    </ng-container>
  </div>
  