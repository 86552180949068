<div class="form-group">
  <div>
    <div class="slx-row">
      <div class="slx-col-2">
        <button
          [disabled]="!isGroupingEnabled || !isEditMode"
          class="slx-button slx-only-icon slx-toolbar slx-group-icon"
          (click)="unionRuleStatementsIntoGroup()"
          [slxTooltip]="groupStatementsTooltipMessage"
          tipPosition="top"
          type="button"
        ></button>
      </div>
      <div
        class="slx-col-auto"
        [class.slx-tiers-count-1]="tiersGroupCount === 1"
        [class.slx-tiers-count-2]="tiersGroupCount === 2"
        [class.slx-tiers-count-3]="tiersGroupCount === 3"
        [class.slx-tiers-count-4]="tiersGroupCount === 4"
        [class.slx-tiers-count-5]="tiersGroupCount === 5"
      ></div>
      <div class="slx-col">
        <div class="slx-row">
          <div class="slx-col-2 slx-label">
            And/Or
          </div>
          <div class="slx-col-3 slx-label">
            Field
          </div>
          <div class="slx-col-4 slx-label no-padding">
            Operator
          </div>
          <div class="slx-col-3 slx-label">
            Value
          </div>
        </div>
      </div>
    </div>

    <div class="slx-row">
      <div class="slx-col-2">
        <ng-container>
          <div
            class="flex flex-direction-row slx-row__padding-bottom"
            *ngFor="let ruleStatement of flatRuleStatements; trackBy: trackByItem; first as isFirst"
          >
            <button
              class="slx-icon-button slx-add-row-btn slx-green"
              type="button"
              (click)="insertRuleStatementBeforeItem(ruleStatement, isFirst)"
              [disabled]="!isEditMode"
              name="btnAddRows{{ ruleId }}"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>
            </button>
            <button
              class="slx-icon-button slx-inline-cancel-button2"
              [disabled]="flatRuleStatements.length < 2 || !isEditMode"
              type="button"
              (click)="onRemoveRule(ruleStatement)"
              name="btnRemoveRows{{ ruleId }}"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>

            <slx-input-decorator
              class="slx-group-rule-checkbox__negative-margin"
              className="slx-no-label slx-no-border slx-small-font slx-no-padding slx-checkbox-center"
            >
              <slx-checkbox-input
                slx-input
                name="isSelected{{ ruleId }}"
                fieldName="isSelected{{ ruleId }}"
                [(ngModel)]="ruleStatement.itemSelected"
                (ngModelChange)="onItemSelectedChanged()"
              >
              </slx-checkbox-input>
            </slx-input-decorator>
          </div>
        </ng-container>
      </div>
      <div class="slx-col-10">
        <ng-container *ngFor="let ruleStatementGroupItem of ruleStatements; trackBy: trackByItem; first as isFirst">
          <slx-benefit-details-eligibility-statements-clause-group
            [ruleStatementGroupItem]="ruleStatementGroupItem"
            [configurationVariables]="configurationVariables"
            [configurationPredicates]="configurationPredicates"
            [tiersGroupCount]="tiersGroupCount"
            [currentGroupLevel]="tiersGroupCount"
            [benefitClasses]="benefitClasses"
            [isFirstItem]="isFirst"
            (ruleStatementGroupItemChanged)="onRuleStatementGroupItemChanged($event)"
            (ruleStatementUngrouped)="onRuleStatementUngrouped($event)"
          ></slx-benefit-details-eligibility-statements-clause-group>
        </ng-container>
      </div>
    </div>
  </div>
  <button
    type="button"
    (click)="addRuleStatement()"
    [disabled]="!isEditMode"
    class="slx-button slx-blue slx-with-icon slx-margin-r slx-mobile-adapted slx-button__margin"
  >
    <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
    <span class="slx-button__text">Add New Clause</span>
  </button>
</div>
