import * as tslib_1 from "tslib";
import { ApplicationActions } from '../../portal/actions/index';
import { TimecardsActions } from '../store/index';
import { Observable } from 'rxjs/Observable';
import { NgRedux } from '@angular-redux/store';
import { mutableSelect } from '../../core/decorators/index';
var TimecardsEpics = /** @class */ (function () {
    function TimecardsEpics(timecardsActions, ngRedux) {
        this.resetPayCycle = function (action$) {
            return action$.filter(function (_a) {
                var type = _a.type;
                return type === ApplicationActions.SELECT_APPLICATION;
            } /*|| type === SidebarActions.RIGHT_MENU_ITEM_CHANGE*/)
                .mergeMap(function (action) {
                return Observable.of({
                    type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE,
                    payload: null
                });
            });
        };
        this.resetSelectionSettings = function (action$) {
            return action$.filter(function (_a) {
                var type = _a.type;
                return type === TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE;
            })
                .mergeMap(function (action) {
                return Observable.of({
                    type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS,
                    payload: null
                });
            });
        };
        this.ngRedux = ngRedux;
    }
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], TimecardsEpics.prototype, "application", void 0);
    return TimecardsEpics;
}());
export { TimecardsEpics };
