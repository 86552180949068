import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';
import { INoMoreValidatorConf } from './common-validators-models';

const NO_MORE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NoMoreValidator),
  multi: true
};

@Directive({
  selector: '[slxNoMore][formControlName],[slxNoMore][formControl],[slxNoMore][ngModel]',
  providers: [NO_MORE_VALIDATOR]
})
export class NoMoreValidator implements Validator, OnChanges {
  @Input()
  public slxNoMore: INoMoreValidatorConf;

  private m_validator: ValidatorFn;
  private m_onChange: () => void;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxNoMore']) {
      this._createValidator();
      if (this.m_onChange) {
        this.m_onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return  this.m_validator(c);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.m_onChange = fn;
  }

  private _createValidator(): void {
    this.m_validator = CommonValidators.noMore(this.slxNoMore);
  }
}
