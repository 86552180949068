import * as _ from 'lodash';

import { Subject } from 'rxjs/Subject';

import { Injectable } from '@angular/core';
import { IServerValidatorAdapter, IServerValidationResult } from '../../../../common/validators/common-validators-models';
import { BenefitDetailsApiService } from './benefit-details-api.service';

interface Validator<T> {
  validate: () => void;
  changeToSync: () => void;
  promise: Promise<T>;
}

@Injectable()
export class BenefitDetailsValidatorAdapter implements IServerValidatorAdapter {
  public static providerNameValidation: string = 'providerNameValidation';

  private delay = 300;

  private providerNameValidator: Validator<IServerValidationResult>;

  constructor(
    private benefitsDetailsApiService: BenefitDetailsApiService
  ) {}

  public validate(validationName: string, value: string, ...params: any[]): Promise<IServerValidationResult> {
    let promise: Promise<IServerValidationResult>;
    const defPromise = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
    switch (validationName) {
      case BenefitDetailsValidatorAdapter.providerNameValidation:
        if (!_.isNil(this.providerNameValidator)) {
          this.providerNameValidator.changeToSync();
        }

        this.providerNameValidator = this.createValidator<IServerValidationResult>(
          () => this.benefitsDetailsApiService.validateProviderName(value, params[0], params[1]),
          () => defPromise
        );

        promise = this.providerNameValidator.promise;
        break;
      default:
        promise = defPromise;
    }
    return promise;
  }

  private createValidator<T>(asyncValidate: () => Promise<T>, syncValidate: () => Promise<T>): Validator<T> {
    const validator = this.getValidator<T>(asyncValidate, syncValidate);
    validator.promise = this.createDelayedPromise<T>(validator.validate);

    return validator;
  }

  private getValidator<T>(asyncValidate: () => Promise<T>, syncValidate: () => Promise<T>): Validator<T> {
    let firstCall = true;
    let isAsyncValidation = true;
    return {
      validate: () => {
        if (firstCall) {
          firstCall = false;
          if (isAsyncValidation) {
            return asyncValidate();
          }
          return syncValidate();
        }
      },
      changeToSync: () => (isAsyncValidation = false),
      promise: Promise.resolve(null)
    };
  }

  private createDelayedPromise<T>(callback: () => void): Promise<T> {
    return new Promise((resolve: (c: any) => void) => {
      setTimeout(() => resolve(callback()), this.delay);
    });
  }
}
