import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[slxChartWidgetTopTemplate]'
})
export class ChartWidgetTopTemplateDirective {

  @Input()
  public isHidden: boolean;

  constructor(public template: TemplateRef<ChartWidgetTopTemplateDirective>) {}
}
