<slx-spinner [show]="state.isLoading">
  <div class="time-and-attendance daily-timecard">
  <div class="action-bar">
    <slx-daily-timecard-actions-bar></slx-daily-timecard-actions-bar>
  </div>

  <div class="daily-timecard-container">
    <div class="daily-timecard-left">
        <slx-daily-timecard-section iconClass="fas fa-hand-paper" title="Punches" [sectionHeight]="punchesHeight" (isCollapsedChange)="punchesCollapsedChange($event)"
        [isLocked]="state.punchesLocked" lockedText="Locked, finish editing in other section">
            <div slxDailyTimecardSectionHeaderLeft>
                <span class="section-badge" [ngClass]="{'section-badge-empty': timecard?.dailyPunches?.length===0}">{{timecard?.dailyPunches?.length}}</span>
            </div>
            <div slxDailyTimecardSectionHeaderRight>
                <div class="punches-alerts" *ngIf="timecard?.punchDisplayTags?.length>0">
                  <span class="punches-alerts-message">{{timecard.punchDisplayTags | slxJoin: "" : ","}}</span>
                  <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                </div>
            </div>
            <slx-section-punches-management slxDailyTimecardSectionContent [timecard]="timecard"></slx-section-punches-management>
        </slx-daily-timecard-section>
        <slx-daily-timecard-section iconClass="fas fa-list-alt" title="Pay Codes" [sectionHeight]="paycodesHeight" (isCollapsedChange)="paycodesCollapsedChange($event)"
         [isLocked]="state.paycodesLocked" lockedText="Locked, finish editing in other section">
            <div slxDailyTimecardSectionHeaderLeft>
              <span class="section-badge" [ngClass]="{'section-badge-empty': timecard?.payRules?.length===0}">{{timecard?.payRules?.length}}</span>
            </div>
            <slx-timecard-paycodes-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-paycodes-list>
        </slx-daily-timecard-section>
    </div>

    <div class="daily-timecard-right">
      <slx-daily-timecard-section iconClass="fas fa-file-alt" title="Paid Summary" [sectionHeight]="summaryHeight"
        [(isCollapsed)]="summaryIsCollapsed" (isCollapsedChange)="summaryCollapsedChange($event)">
            <slx-timecard-summary-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-summary-list>
        </slx-daily-timecard-section>
        <slx-daily-timecard-section iconClass="fal fa-calendar-alt" title="Schedule" [sectionHeight]="scheduleHeight"
        [(isCollapsed)]="scheduleIsCollapsed" (isCollapsedChange)="scheduleCollapsedChange($event)">
            <slx-timecard-shifts-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-shifts-list>
        </slx-daily-timecard-section>
        <slx-daily-timecard-section iconClass="far fa-calendar-times" title="Absences" [sectionHeight]="absencesHeight"
        [(isCollapsed)]="absencesIsCollapsed" (isCollapsedChange)="absencesCollapsedChange($event)"
        [isLocked]="state.absencesLocked" lockedText="Locked, finish editing in other section">
            <div slxDailyTimecardSectionHeaderLeft>
                <span class="section-badge" [ngClass]="{'section-badge-empty': timecard?.absences?.length===0}">{{abscenceCount}}</span>
            </div>
            <slx-timecard-absences-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-absences-list>
        </slx-daily-timecard-section>
        <slx-daily-timecard-section iconClass="fas fa-comment" title="Comments" [sectionHeight]="commentsHeight"
        [(isCollapsed)]="commentsIsCollapsed" (isCollapsedChange)="commentsCollapsedChange($event)">
            <slx-timecard-comments slxDailyTimecardSectionContent [model]="timecard" 
            [editable]="canEditTimecard" (commentChanged)="onCommentChanged($event)"></slx-timecard-comments>
          </slx-daily-timecard-section>
  </div>

  </div>


  <!--
  <div class="time-and-attendance daily-timecard timecard-container">
    <div class="timecard-section-line">
      <div class="timecard-section-left">
        <slx-daily-timecard-section [title]="scheduleTitle">
          <slx-timecard-shifts-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-shifts-list>
        </slx-daily-timecard-section>
      </div>
      <div class="timecard-section-right">
        <slx-daily-timecard-section [title]="punchesTitle" [isLocked]="state.punchesLocked" lockedText="Locked, finish editing in other section">
          <div slxDailyTimecardSectionTitle>
            <div class="punches-alerts" *ngIf="timecard?.punchDisplayTags?.length>0">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span class="punches-alerts-message">{{timecard.punchDisplayTags | slxJoin: "" : ","}}</span>
            </div>
          </div>
          <slx-section-punches-management slxDailyTimecardSectionContent [timecard]="timecard"></slx-section-punches-management>
        </slx-daily-timecard-section>
      </div>
    </div>
    <div class="timecard-section-line ">
      <div class="timecard-section-left timecard-absences-list">
        <slx-daily-timecard-section [title]="absencesTitle" [isLocked]="state.absencesLocked" lockedText="Locked, finish editing in other section">
          <slx-timecard-absences-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-absences-list>
        </slx-daily-timecard-section>
      </div>
      <div class="timecard-section-right timecard-paycodes-list">
        <slx-daily-timecard-section [title]="payCodesTitle" [isLocked]="state.paycodesLocked" lockedText="Locked, finish editing in other section">
          <slx-timecard-paycodes-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-paycodes-list>
        </slx-daily-timecard-section>
      </div>
    </div>
    <div class="timecard-section-line">
      <div class="timecard-section-left">
        <slx-daily-timecard-section title="Comment">
          <slx-timecard-comments slxDailyTimecardSectionContent [model]="timecard" [editable]="canEditTimecard" (commentChanged)="onCommentChanged($event)"></slx-timecard-comments>
        </slx-daily-timecard-section>
      </div>
      <div class="timecard-section-right timecard-summary-list">
        <slx-daily-timecard-section title="Paid Summary">
          <slx-timecard-summary-list slxDailyTimecardSectionContent [model]="timecard"></slx-timecard-summary-list>
        </slx-daily-timecard-section>
      </div>
    </div>
  </div>
  -->
</div>
</slx-spinner>
