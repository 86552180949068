<div class="slx-master-schedule">
    <div class="master-schedule-wrapper">
        <div *ngIf="showGrid" class="slx-full-height">
            <ag-grid-angular #agGrid class="ag-theme-fresh master-schedule-grid"
                        [gridOptions]="gridOptions"
                        [columnDefs]="gridHelper.columnDefs"
                        [rowData]="rowData"
                        [pinnedBottomRowData] ="footerData"
                        groupHeaders
                        rowSelection="single"
                        rowHeight="25"
                        (cellClicked)="onCellClicked($event)"
                        (sortChanged)="onSortChanged($event)"
                        (componentStateChanged)="onComponentStateChanged($event)"
                        (selectionChanged) = "onSelectionChanged($event)"
                        >
            </ag-grid-angular>
        </div>
    </div>

    <span [popper]="censusPopperConent"
        [popperTrigger]="'click'"
        [popperPlacement]="'top'"
        [popperDisableStyle]="true"
        [popperHideOnClickOutside]="true"
        (popperOnShown)="onCensusPopperShown($event)"
        (popperOnHidden)="onCensusPopperHidden($event)"
    ></span>

    <popper-content #censusPopperConent class="census-popup-content">
        <slx-census-entries-popup #censusWindow></slx-census-entries-popup>
    </popper-content>
</div>
