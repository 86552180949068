import * as tslib_1 from "tslib";
import { ToolbarBaseService } from '../../../core/services/index';
var DailyTimecardToolbarService = /** @class */ (function (_super) {
    tslib_1.__extends(DailyTimecardToolbarService, _super);
    function DailyTimecardToolbarService() {
        return _super.call(this) || this;
    }
    return DailyTimecardToolbarService;
}(ToolbarBaseService));
export { DailyTimecardToolbarService };
