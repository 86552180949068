import { TimecardsEarningPayRule, ITimecardsEarningPayRule } from './timecards-earning-pay-rule';


export interface ITimecardFirstWeekTotals {
    paymentTransactionType: string;
    regularHours: number;
    productiveHours: number;
    nonProductiveHours: number;
    totalHours: number;
    regularPay: number;
    overtimeAndExtraHours: number;
    overtimeAndExtraPay: number;
    totalOtherPay: number;
    overTimePay: number;
    totalAbsencePay:number;
    ruleDetails: ITimecardsEarningPayRule[];
  }
  
  export class TimecardFirstWeekTotals {
    public paymentTransactionType: string;
    public regularHours: number;
    public productiveHours: number;
    public nonProductiveHours: number;
    public totalHours: number;
    public regularPay: number;
    public overtimeAndExtraHours: number;
    public overtimeAndExtraPay: number;
    public totalOtherPay: number;
    public overTimePay: number;
    public totalAbsencePay:number;
    public ruleDetails: TimecardsEarningPayRule[];
  }
