import * as moment from 'moment';
import { appConfig } from '../../../app.config';
var PayCycle = /** @class */ (function () {
    function PayCycle() {
    }
    Object.defineProperty(PayCycle.prototype, "description", {
        get: function () {
            return moment(this.startDate).format(appConfig.dateFormat) + " - " + moment(this.endDate).format(appConfig.dateFormat);
        },
        enumerable: true,
        configurable: true
    });
    return PayCycle;
}());
export { PayCycle };
