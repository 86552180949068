import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import 'rxjs/add/operator/combineLatest';
import { ServerCompositeFilterDescriptor, ServerQuery, IFilteredItems, PagingData } from '../../../../core/models/index';
import { ServerFilterService, addFieldToFilter, addFilter, removeFilter } from '../../../../core/services/index';
import { LookupService, OrgLevelWatchService } from '../../../../organization/services/index';
import { LookupType } from '../../../../organization/models/index';
import { AuditTrailApiService } from '../../services/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { IRangeDates } from '../../../../common/models/range-dates';
import * as moment from 'moment';
import { StateManagementService } from '../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateResetTypes } from '../../../../core/models/settings/index';
import { AuditTrailEvent, AuditTrailEventCmd } from '../../models/audit-trail/audit-trail-event';
import { AuditTrailManagementService } from '../../services/audit-trail/audit-trail-management.service';
var AuditTrailConfigComponent = /** @class */ (function () {
    function AuditTrailConfigComponent(apiService, serverFilterService, orgLevelWatchService, stateManagement, storageService, managementService, lookupService) {
        this.apiService = apiService;
        this.serverFilterService = serverFilterService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.managementService = managementService;
        this.lookupService = lookupService;
        this.m_filtersControlId = 'FiltersNgx';
        this.m_resetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.pagingData = { skip: 0, take: 50 };
        this.gridSort = [{ field: 'auditDateTime', dir: 'desc' }];
        this.lookups = {
            auditTrailAreas: []
        };
    }
    AuditTrailConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedAreas = [];
        this.state = { isLoading: false };
        this.fromFilter = moment().add(-2, 'weeks').toDate();
        this.toFilter = moment().toDate();
        this.stateManagement.init('AuditTrailConfigComponent', false);
        this.managementService.subscribeToUserActionsChanged(function (actions) {
            _this.canExportData = _.some(actions, function (x) { return x.name === 'Export To Excel'; });
        });
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$)
            .map(function (value) { return value[0]; })
            .subscribe(function (orgLevel) {
            if (!_this.currentOrgLevel || _this.currentOrgLevel.id !== orgLevel.id) {
                _this.currentOrgLevel = orgLevel;
                _this.state.isLoading = true;
                Promise.all([
                    _this.updateLookups()
                ]).then(function () {
                    _this.serverFilterService.clearFilter();
                    _this.restoreStates();
                    _this.refreshData(false);
                    _this.managementService.loadUserActions(orgLevel.id);
                });
            }
        });
        // after all restore actions complete, subscribe to updates
        this.filterChangeSubscription = this.serverFilterService.changes$.subscribe(function (filter) {
            if (!_this.currentOrgLevel) {
                return;
            }
            _this.refreshData(true);
        });
        this.loadSubscription = this.stateManagement.loadData$.subscribe(function () {
            if (!_this.currentOrgLevel) {
                return;
            }
            _this.refreshData(false);
        });
    };
    AuditTrailConfigComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    AuditTrailConfigComponent.prototype.refreshData = function (localFilterChanged) {
        var _this = this;
        this.state.isLoading = true;
        var areaFilters = this.serverFilterService.extractFilterValues('area');
        if (areaFilters && areaFilters.length > 0) {
            this.disableGlobalAreaFilter = true;
            this.selectedAreas = [];
        }
        else {
            this.disableGlobalAreaFilter = false;
            this.updateAreaServerFilters();
        }
        this.updateDateServerFilters();
        this.apiService.getNonEmployeeActions(this.createQuery(this.pagingData), this.currentOrgLevel.id, this.disableGlobalAreaFilter)
            .then(function (val) {
            _this.state.isLoading = false;
            _this.actionList = val;
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    AuditTrailConfigComponent.prototype.filterDateChanged = function (dates) {
        this.fromFilter = moment(dates.startDate).startOf('day').toDate();
        this.toFilter = moment(dates.endDate).endOf('day').toDate();
        this.pagingData = { skip: 0, take: 50 };
        this.saveFilterStates();
    };
    AuditTrailConfigComponent.prototype.areaSelected = function (areas) {
        this.selectedAreas = areas;
        this.updateAreaServerFilters();
        this.pagingData = { skip: 0, take: 50 };
        this.saveFilterStates();
    };
    AuditTrailConfigComponent.prototype.gridPageChanged = function (pagingData) {
        this.pagingData = pagingData;
        this.refreshData(this.disableGlobalAreaFilter);
    };
    AuditTrailConfigComponent.prototype.gridSortChanged = function (sort) {
        this.gridSort = sort;
        this.refreshData(this.disableGlobalAreaFilter);
    };
    AuditTrailConfigComponent.prototype.onExportToExcel = function () {
        var _this = this;
        var action = new AuditTrailEvent();
        action.cmd = AuditTrailEventCmd.excelExport;
        if (this.actionList.count <= this.pagingData.take) {
            action.payload = this.actionList;
            this.managementService.onActionCmd(action);
            return;
        }
        this.state.isLoading = true;
        var paging = new PagingData();
        paging.skip = 0;
        paging.take = this.actionList.count;
        this.apiService.getNonEmployeeActions(this.createQuery(paging), this.currentOrgLevel.id, this.disableGlobalAreaFilter)
            .then(function (val) {
            _this.state.isLoading = false;
            action.payload = val;
            _this.managementService.onActionCmd(action);
            _this.state.isLoading = false;
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    AuditTrailConfigComponent.prototype.onExportToPdf = function () {
        var action = new AuditTrailEvent();
        action.cmd = AuditTrailEventCmd.pdfExport;
        this.managementService.onActionCmd(action);
    };
    AuditTrailConfigComponent.prototype.createQuery = function (paging) {
        var globalAreaFilters = this.serverFilterService.extractFilterValues('area_global');
        if (globalAreaFilters && globalAreaFilters.length > 0) {
            var query = _.cloneDeep(this.serverFilterService.createQuery(paging, this.gridSort));
            removeFilter(query.filter, 'area_global');
            if (this.selectedAreas && this.selectedAreas.length > 0) {
                var filter_1 = { filters: [], logic: 'or' };
                _.forEach(this.selectedAreas, function (ar) {
                    addFieldToFilter(filter_1, 'area', 'contains', ar);
                });
                addFilter(query.filter, filter_1);
            }
            return query;
        }
        return this.serverFilterService.createQuery(paging, this.gridSort);
    };
    AuditTrailConfigComponent.prototype.updateDateServerFilters = function () {
        this.serverFilterService.removeFilter('auditDateTime');
        if (this.fromFilter) {
            this.serverFilterService.composeFilter({ field: 'auditDateTime', operator: 'gte', value: this.fromFilter });
        }
        if (this.toFilter) {
            this.serverFilterService.composeFilter({ field: 'auditDateTime', operator: 'lte', value: this.toFilter });
        }
    };
    AuditTrailConfigComponent.prototype.updateAreaServerFilters = function () {
        this.serverFilterService.removeFilter('area_global');
        var hasAll = !!_.find(this.selectedAreas, function (a) { return a === 'All'; });
        if (!this.selectedAreas || this.selectedAreas.length === 0 || hasAll) {
            return;
        }
        var filter = { filters: [], logic: 'or' };
        _.forEach(this.selectedAreas, function (ar) {
            addFieldToFilter(filter, 'area_global', 'contains', ar);
        });
        this.serverFilterService.addFilter(filter);
    };
    AuditTrailConfigComponent.prototype.updateLookups = function () {
        var _this = this;
        var auditTrailAreasPromise = this.lookupService.getLookup({
            lookupType: LookupType.auditTrailConfigAreas,
            orgLevelId: this.currentOrgLevel.id,
            employeeId: undefined
        });
        return Promise.all([auditTrailAreasPromise]).then(function (arrays) {
            _this.lookups.auditTrailAreas = arrays[0].items;
        });
    };
    AuditTrailConfigComponent.prototype.saveFilterStates = function () {
        var state = {
            value: { selectedAreas: this.selectedAreas, fromDate: this.fromFilter, toDate: this.toFilter }
        };
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_filtersControlId, state, this.m_resetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_filtersControlId);
    };
    AuditTrailConfigComponent.prototype.restoreStates = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_filtersControlId);
        if (state && state.value) {
            if (state.value.selectedAreas)
                this.selectedAreas = state.value.selectedAreas;
            if (state.value.fromDate)
                this.fromFilter = moment(state.value.fromDate).toDate();
            if (state.value.toDate)
                this.toFilter = moment(state.value.toDate).toDate();
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AuditTrailConfigComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTrailConfigComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTrailConfigComponent.prototype, "filterChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuditTrailConfigComponent.prototype, "loadSubscription", void 0);
    return AuditTrailConfigComponent;
}());
export { AuditTrailConfigComponent };
