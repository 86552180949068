import * as uuid from 'uuid';
import * as _ from 'lodash';
var BenefitEligibilityRuleModel = /** @class */ (function () {
    function BenefitEligibilityRuleModel(_a) {
        var _b = _a === void 0 ? {} : _a, name = _b.name, description = _b.description, ruleStatements = _b.ruleStatements;
        this.name = name || '';
        this.description = description || '';
        this.ruleStatements = ruleStatements || [];
    }
    return BenefitEligibilityRuleModel;
}());
export { BenefitEligibilityRuleModel };
var BenefitEligibilityRuleStatementGroupItem = /** @class */ (function () {
    function BenefitEligibilityRuleStatementGroupItem(_a) {
        var _b = _a === void 0 ? {} : _a, id = _b.id, sequence = _b.sequence, conditionType = _b.conditionType, variable = _b.variable, groups = _b.groups, itemSelected = _b.itemSelected;
        this.id = id || uuid.v4(); // TODO
        this.sequence = sequence || 0;
        this.conditionType = conditionType || null;
        this.variable = variable || null;
        this.groups = groups || null;
        this.isGroupItem = !_.isEmpty(groups);
        this.itemSelected = itemSelected || false;
    }
    return BenefitEligibilityRuleStatementGroupItem;
}());
export { BenefitEligibilityRuleStatementGroupItem };
var BenefitEligibilityRuleVariable = /** @class */ (function () {
    function BenefitEligibilityRuleVariable(_a) {
        var _b = _a === void 0 ? {} : _a, variableId = _b.variableId, operatorType = _b.operatorType, valueType = _b.valueType, value = _b.value, dataType = _b.dataType, predicateEnum = _b.predicateEnum;
        this.variableId = variableId || null;
        this.operatorType = operatorType || null;
        this.valueType = valueType || null;
        this.value = value || null;
        this.dataType = dataType || null;
        this.predicateEnum = predicateEnum || null;
    }
    return BenefitEligibilityRuleVariable;
}());
export { BenefitEligibilityRuleVariable };
export var BenefitEligibilityRuleConditionType;
(function (BenefitEligibilityRuleConditionType) {
    BenefitEligibilityRuleConditionType["AND"] = "AND";
    BenefitEligibilityRuleConditionType["OR"] = "OR";
})(BenefitEligibilityRuleConditionType || (BenefitEligibilityRuleConditionType = {}));
export var benefitEligibilityRuleConditions = [
    BenefitEligibilityRuleConditionType.AND,
    BenefitEligibilityRuleConditionType.OR,
];
export var BenefitEligibilityRuleBooleanType;
(function (BenefitEligibilityRuleBooleanType) {
    BenefitEligibilityRuleBooleanType["False"] = "FALSE";
    BenefitEligibilityRuleBooleanType["True"] = "TRUE";
})(BenefitEligibilityRuleBooleanType || (BenefitEligibilityRuleBooleanType = {}));
export var benefitEligibilityRuleBooleanTypes = [
    BenefitEligibilityRuleBooleanType.False,
    BenefitEligibilityRuleBooleanType.True,
];
var BenefitEligibilityRulePayType = /** @class */ (function () {
    function BenefitEligibilityRulePayType(id, name) {
        this.id = id;
        this.name = name;
    }
    return BenefitEligibilityRulePayType;
}());
export { BenefitEligibilityRulePayType };
export var benefitEligibilityRulePayTypes = [
    new BenefitEligibilityRulePayType(0, 'Hourly'),
    new BenefitEligibilityRulePayType(1, 'Salaried'),
];
export var BenefitEligibilityRuleVariableEnum;
(function (BenefitEligibilityRuleVariableEnum) {
    BenefitEligibilityRuleVariableEnum["BenefitClass"] = "BenefitClass";
    BenefitEligibilityRuleVariableEnum["PayType"] = "PayType";
})(BenefitEligibilityRuleVariableEnum || (BenefitEligibilityRuleVariableEnum = {}));
export var BenefitEligibilityRulePredicateEnum;
(function (BenefitEligibilityRulePredicateEnum) {
    BenefitEligibilityRulePredicateEnum["Between"] = "Between";
    BenefitEligibilityRulePredicateEnum["In"] = "In";
    BenefitEligibilityRulePredicateEnum["NotIn"] = "NotIn";
    BenefitEligibilityRulePredicateEnum["EqualTo"] = "EqualTo";
    BenefitEligibilityRulePredicateEnum["NotEqualTo"] = "NotEqualTo";
    BenefitEligibilityRulePredicateEnum["GreaterThan"] = "GreaterThan";
    BenefitEligibilityRulePredicateEnum["GreaterThanEqualTo"] = "GreaterThanEqualTo";
    BenefitEligibilityRulePredicateEnum["LesserThan"] = "LesserThan";
    BenefitEligibilityRulePredicateEnum["LesserThanEqaulTo"] = "LesserThanEqaulTo";
})(BenefitEligibilityRulePredicateEnum || (BenefitEligibilityRulePredicateEnum = {}));
export var BenefitEligibilityRuleDataType;
(function (BenefitEligibilityRuleDataType) {
    BenefitEligibilityRuleDataType["String"] = "String";
    BenefitEligibilityRuleDataType["Integer"] = "Integer";
    BenefitEligibilityRuleDataType["Decimal"] = "Decimal";
    BenefitEligibilityRuleDataType["Boolean"] = "Boolean";
    BenefitEligibilityRuleDataType["DateTime"] = "DateTime";
})(BenefitEligibilityRuleDataType || (BenefitEligibilityRuleDataType = {}));
