/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tree-node-expander.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tree-node-expander.component";
var styles_TreeNodeExpanderComponent = [i0.styles];
var RenderType_TreeNodeExpanderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TreeNodeExpanderComponent, data: {} });
export { RenderType_TreeNodeExpanderComponent as RenderType_TreeNodeExpanderComponent };
function View_TreeNodeExpanderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "toggle-children-wrapper"]], [[2, "toggle-children-wrapper-expanded", null], [2, "toggle-children-wrapper-collapsed", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.node.mouseAction("expanderClick", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "toggle-children"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.node.isExpanded; var currVal_1 = _co.node.isCollapsed; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TreeNodeExpanderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "toggle-children-placeholder"]], null, null, null, null, null))], null, null); }
export function View_TreeNodeExpanderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeNodeExpanderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TreeNodeExpanderComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.node.hasChildren; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.node.hasChildren; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TreeNodeExpanderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-tree-node-expander", [], null, null, null, View_TreeNodeExpanderComponent_0, RenderType_TreeNodeExpanderComponent)), i1.ɵdid(1, 49152, null, 0, i3.TreeNodeExpanderComponent, [], null, null)], null, null); }
var TreeNodeExpanderComponentNgFactory = i1.ɵccf("slx-tree-node-expander", i3.TreeNodeExpanderComponent, View_TreeNodeExpanderComponent_Host_0, { node: "node" }, {}, []);
export { TreeNodeExpanderComponentNgFactory as TreeNodeExpanderComponentNgFactory };
