import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ConfirmDialogComponent, ModalService } from '../../../../common/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { BudgetRecord, Budget, AddRecordReq } from '../../../models/index';
import { BudgetManagementService } from '../../../services/index';
var BudgetGridComponent = /** @class */ (function () {
    function BudgetGridComponent(budgetManagementService, modalService) {
        this.budgetManagementService = budgetManagementService;
        this.modalService = modalService;
        this.aggregates = [
            { field: 'weekendHours', aggregate: 'sum' },
            { field: 'weekdayHours', aggregate: 'sum' },
            { field: 'payRate', aggregate: 'sum' }
        ];
        Assert.isNotNull(budgetManagementService, 'budgetManagementService');
        this.budgetContainer = this.budgetManagementService.currentBudget;
        this.gridState = new KendoGridStateHelper();
        var aggregates = [
            { field: 'weekendHours', aggregate: 'sum' },
            { field: 'weekdayHours', aggregate: 'sum' },
            { field: 'payRate', aggregate: 'sum' }
        ];
        this.gridState.state.group = [{ field: 'budgetedGroup.description', aggregates: aggregates }];
    }
    BudgetGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.budgetManagementService.onOrgLevelChanged$
            .subscribe(function (orgLevelId) {
            _this.grid.closeRow(_this.editedRowIndex);
            _this.editMode = false;
            _this.addNewMode = false;
            _this.editedRowIndex = undefined;
            _this.editedRecord = undefined;
            _this.budgetContainer = null;
        });
        this.loadedSubscription = this.budgetManagementService.onLoaded$.subscribe(function (container) {
            _this.budgetContainer = container;
            _this.refreshGrid();
        });
        this.groupsEditModeSubscription = this.budgetManagementService.onGroupEdit$.subscribe(function (mode) {
            if (!mode) {
                _this.forceGroupsCache = true;
            }
        });
        this.addedSubscription = this.budgetManagementService.onAdded$.subscribe(function (container) {
            _this.budgetContainer = container;
            _this.refreshGrid();
        });
        this.addPositionSubscription = this.budgetManagementService.onAddPosition$.subscribe(function (req) {
            _this.editMode = true;
            _this.editedOrgLevelId = req.orgLevelId;
            var isIdExists = _.find(_this.budgetContainer.records, function (id) { return id.uniqId === req.record.uniqId; });
            if (isIdExists) {
                _this.closeEditor();
            }
            else {
                _this.addHandler(req.record);
                _this.form.form.markAsDirty();
            }
        });
        this.budgetSelectSubscription = this.budgetManagementService.onBudgetSelect$.subscribe(function (req) {
            _this.budgetContainer = req;
            _this.refreshGrid();
        });
        this.budgetEditModeSubscription = this.budgetManagementService.onEditBudgetMode$.subscribe(function (mode) {
            _this.editMode = mode;
            _this.editedOrgLevelId = _this.budgetManagementService.selectedOrganizationOrgLevelId;
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    BudgetGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BudgetGridComponent.prototype.editHandler = function (sender) {
        var _this = this;
        if (this.editedRecord) {
            ConfirmDialogComponent.openDialog('Unsaved changes', 'There is an unsaved record. If you proceed, the unsaved data will be lost. Are you sure you wish to proceed?', this.modalService, function (result) {
                if (result) {
                    _this.doEdit(sender.dataItem, sender.rowIndex);
                }
            });
            return;
        }
        this.doEdit(sender.dataItem, sender.rowIndex);
    };
    BudgetGridComponent.prototype.addHandler = function (record) {
        this.closeEditor();
        this.addNewMode = true;
        this.editedRecord = record;
        this.grid.addRow(this.editedRecord);
        this.budgetManagementService.onEditRecord(true);
    };
    BudgetGridComponent.prototype.removeHandler = function (event) {
        if (!event.dataItem) {
            return;
        }
        var removedRecords = _.remove(this.budgetContainer.records, function (r) {
            return r.uniqId === event.dataItem.uniqId;
        });
        this.refreshGrid();
    };
    BudgetGridComponent.prototype.closeEditor = function () {
        this.grid.closeRow(this.editedRowIndex);
        this.addNewMode = false;
        this.editedRowIndex = undefined;
        this.editedRecord = undefined;
    };
    BudgetGridComponent.prototype.cancelHandler = function () {
        var _this = this;
        var editedItem = _.find(this.budgetContainer.records, function (r) {
            return r.uniqId === _this.editedRecord.uniqId;
        });
        if (this.editCancelRecord !== undefined && editedItem !== undefined && this.editCancelRecord.uniqId === editedItem.uniqId) {
            Object.assign(editedItem, this.editCancelRecord);
        }
        this.forceGroupsCache = false;
        this.closeEditor();
        this.budgetManagementService.onEditRecord(false);
        this.budgetManagementService.onPositionAdded(undefined);
        this.editCancelRecord = undefined;
    };
    BudgetGridComponent.prototype.saveHandler = function (event) {
        var _this = this;
        this.forceGroupsCache = false;
        if (this.addNewMode) {
            this.budgetContainer.records.push(this.editedRecord);
            this.budgetManagementService.onPositionAdded(this.editedRecord);
        }
        else {
            if (this.editedRecord) {
                var editedItem = _.find(this.budgetContainer.records, function (r) {
                    return r.uniqId === _this.editedRecord.uniqId;
                });
                Object.assign(editedItem, this.editedRecord);
            }
        }
        this.closeEditor();
        this.budgetManagementService.onEditRecord(false);
        this.refreshGrid();
    };
    BudgetGridComponent.prototype.doEdit = function (dataItem, rowIndex) {
        this.closeEditor();
        this.editedRecord = Object.assign({}, dataItem);
        this.editCancelRecord = _.cloneDeep(dataItem);
        this.editedRowIndex = rowIndex;
        this.grid.editRow(this.editedRowIndex);
        this.budgetManagementService.onEditRecord(true);
    };
    BudgetGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.budgetContainer || !this.budgetContainer.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.group.forEach(function (group) { return group.aggregates = _this.aggregates; });
        this.gridState.view = process(this.budgetContainer.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "addedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "addPositionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "budgetSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "budgetEditModeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetGridComponent.prototype, "groupsEditModeSubscription", void 0);
    return BudgetGridComponent;
}());
export { BudgetGridComponent };
