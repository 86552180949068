import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GridModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MomentModule } from 'angular2-moment';

import { CommonModule as CommonAppModule } from '../../common/index';
import { EliteDashboardComponent } from './components/elite-dashboard.component';
import { CreateEliteDashboardComponent } from './components/create-report/elite-dashboard-create.component';
import { rootEpics } from '../../state-model/state-model.module';
import { ViewEliteDashboardComponent } from './components/view-report/elite-dashboard-view.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    DropDownsModule,
    MomentModule,
    CommonAppModule
  ],
  declarations: [
    EliteDashboardComponent,
    CreateEliteDashboardComponent,
    ViewEliteDashboardComponent
  ],
  entryComponents: [
  ],
  providers: [
  ],
  exports: [
    EliteDashboardComponent,
    CreateEliteDashboardComponent,
    ViewEliteDashboardComponent
  ]
})
export class EliteDashboardModule {
}
