import * as _ from 'lodash';
import { Month } from '../../../common/models/months/month';
import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';

export class IHoliday {
  id: number;
  name: string;
  date: string;
  start: string;
  end: string;
  type: string;
  code: string;
  lastUpdateUsername: string;
  lastUpdateDate: string;
  origin: string;
  definingRule: {
    dayDefiningPattern: string;
    dayOfWeek: string;
    month: number;
  };
}

export class Holiday implements IBaseSelectableModel {
  public id: number = 0;
  public name: string;
  public date: Date;
  public start: Date;
  public end: Date;
  public type: string;
  public code: string;
  public origin: string;
  public rule: HolidayRule;
  public updatedBy: string;
  public updatedOn: Date;

  public months: Month[];

  public isSelected: boolean;
  public selectable: boolean = true;

  public get isRuleBased(): boolean {
    return this.origin === 'RuleBased';
  }

  public get ruleDescription(): string {
    return this.getRuleDescription();
  }

  private getRuleDescription(): string {
    if (this.rule) {
      let m: Month = this.getMonthBy(this.rule.month);
      return `${this.rule.dayDefiningPattern} ${this.rule.dayOfWeek} of ${m ? m.name : ''}`;
    }
    return '';
  }

  private getMonthBy(id: number): Month {
    return _.find(this.months, (item: Month) => item.id === id);
  }
}

export class HolidayRule {
  public dayDefiningPattern: string;
  public dayOfWeek: string;
  public month: number;
}
