import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { TimecardSummaryMapService } from './timecards-summary-map.service';
import { TimecardSummaryRequest, ITimecardSummaryRequest, TimecardIndividualSummaryRequest, ITimecardIndividualSummaryRequest, TimecardSummaryContainerDTO, TimecardSummaryModel } from '../../models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';

@Injectable()
export class TimecardSummaryApiService {

    constructor(
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private map: TimecardSummaryMapService,
    ) { }

    public loadTimecardsSummary(request: TimecardSummaryRequest): Promise<TimecardSummaryModel> {
        const url: string = `${this.getTaRoot()}/${timeAndAttendanceConfig.api.summary.root}`;
        let reqDto: ITimecardSummaryRequest = this.map.mapRequestToDto(request);
        let apiReq: HttpRequest<any> = this.urlParamsService.createPostRequest(url, reqDto);
        let promise: Promise<any> = this.apiUtilService.request(apiReq).then((response: ResponseBody<TimecardSummaryContainerDTO, Meta>) => {
            return this.map.mapTimecardSummary(response.data);
        });
        return promise;
    }

    public loadTimecardIndividualSummary(request: TimecardIndividualSummaryRequest): Promise<TimecardSummaryModel> {

        const url: string = `${this.getTaRoot()}/${timeAndAttendanceConfig.api.summary.root}/${timeAndAttendanceConfig.api.summary.employee}/${request.employeeId}`;
        let reqDto: ITimecardIndividualSummaryRequest = this.map.mapIndividualRequestToDto(request);
        let apiReq: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { startDate: reqDto.startDate, endDate: reqDto.endDate });
        let promise: Promise<any> = this.apiUtilService.request(apiReq).then((response: ResponseBody<TimecardSummaryContainerDTO, Meta>) => {
            return this.map.mapTimecardSummary(response.data);
        });
        return promise;
    }

    private getApiRoot(): string {
        return this.apiUtilService.getApiRoot();
    }

    private getTaRoot(): string {
        return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}`;
    }

}
