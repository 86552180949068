
import { IDepartment, Department, IPosition, Position, IOrganization, } from '../../../../organization/models/index';
export interface IEmployeeInfo {
    id: number;
    firstName: string;
    lastName: string;
    middleName: string;
    payRollNumber: string;
    organization: IOrganization;
    department: IDepartment;
    position: IPosition;
    effectiveDate: string;
    terminationDate: string;
    activationDate: string;
    isAgency: boolean;
    ssn: string;
    dateOfBirth: string;
    dateHired: string;
    fullName: string;
    badgeId: number;
    masterId: number;
}

export class EmployeeInfo {
    public id: number;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public payRollNumber: string;
    public organization: IOrganization;
    public department: IDepartment;
    public position: IPosition;
    public effectiveDate: string;
    public terminationDate: string;
    public activationDate: string;
    public isAgency: boolean;
    public ssn: string;
    public dateOfBirth: Date;
    public dateHired: Date;
    public fullName: string;
    public badgeId: number;
    public masterId: number;
}