import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '../../../common/index';
import { ActionsType } from '../../../core/models/field/actions-type';
import { AccessManagementService } from '../../services/accessManagement/access-management.service';
var StateManagementDirectiveBase = /** @class */ (function () {
    function StateManagementDirectiveBase(stateManagement, modalService) {
        this.stateManagement = stateManagement;
        this.modalService = modalService;
        this.operation = ActionsType.noop;
    }
    StateManagementDirectiveBase.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.subscribe(function () {
            _this.updateHost();
        });
        this.updateHost();
    };
    StateManagementDirectiveBase.prototype.ngOnDestroy = function () {
        if (this.subscription)
            this.subscription.unsubscribe();
    };
    StateManagementDirectiveBase.prototype.updateHost = function () {
        var result = this.stateManagement.checkAction(this.operation);
        if (!result) {
            this.disabled = true;
        }
        else {
            this.disabled = undefined;
        }
    };
    StateManagementDirectiveBase.prototype.interceptAction = function (event) {
        var result = this.stateManagement.checkAction(this.operation);
        if (result) {
            if (this.interceptedMethod)
                this.interceptedMethod();
        }
        else {
            this.modalService.globalAnchor.openConfirmDialog('Warning', 'This action is forbidden', function (selection) {
                console.debug('action prevented');
            });
            event.preventDefault();
            event.stopPropagation();
        }
    };
    return StateManagementDirectiveBase;
}());
export { StateManagementDirectiveBase };
var ListDeleteOperationDirective = /** @class */ (function (_super) {
    tslib_1.__extends(ListDeleteOperationDirective, _super);
    function ListDeleteOperationDirective(stateManagement, modalService) {
        var _this = _super.call(this, stateManagement, modalService) || this;
        _this.operation = ActionsType.delete;
        return _this;
    }
    return ListDeleteOperationDirective;
}(StateManagementDirectiveBase));
export { ListDeleteOperationDirective };
var ListAddOperationDirective = /** @class */ (function (_super) {
    tslib_1.__extends(ListAddOperationDirective, _super);
    function ListAddOperationDirective(stateManagement, modalService) {
        var _this = _super.call(this, stateManagement, modalService) || this;
        _this.operation = ActionsType.add;
        return _this;
    }
    return ListAddOperationDirective;
}(StateManagementDirectiveBase));
export { ListAddOperationDirective };
