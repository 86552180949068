import { Component, OnInit, ViewChild } from "@angular/core";
import { GridComponent, GridItem } from "@progress/kendo-angular-grid";
import * as _ from 'lodash';
import { PayrollPayCodesManagementService } from "../../../../../app/configuration/services/payroll/payroll-pay-codes/payroll-pay-codes-management.service";
import { PayrollPayCode } from "../../../../../app/configuration/models/payroll-pay-codes/payroll-pay-code";

@Component({
    selector: 'slx-payroll-pay-codes',
    templateUrl: './payroll-pay-codes.component.html',
    styleUrls: ['./payroll-pay-codes.component.scss'],
})

export class PayrollPayCodesComponent implements OnInit {

    @ViewChild('kendoGrid', { static: true })
    private grid: GridComponent;
    payCodes: PayrollPayCode[] = [];
    payNumbers: number[];
    editedRowIndex: number;
    editedEntry: PayrollPayCode;

    constructor(
        public manageService: PayrollPayCodesManagementService
    ) {}

    ngOnInit() {
        this.manageService.init();
    }

    public editHandler(event: { dataItem: PayrollPayCode, rowIndex: number }): void {
        this.closeEditor();
        this.editedEntry = Object.assign({}, event.dataItem);
        this.editedRowIndex = event.rowIndex;
        this.grid.editRow(this.editedRowIndex);
        this.manageService.findDirtyRecords();
    }
    public saveHandler() {
        const editedEntry: PayrollPayCode = _.find(this.manageService.list, { "payrollPaycode": this.editedEntry.payrollPaycode });
        if(editedEntry.payNumber !== this.editedEntry.payNumber){
            this.manageService.setItemDirty(editedEntry);
            this.manageService.findDirtyRecords();
            _.assign(editedEntry, this.editedEntry);
        }
        this.closeEditor();
    }

    public cancelHandler() {
        this.closeEditor();
        this.manageService.findDirtyRecords();
    }

    public closeEditor(): void {
        this.grid.closeRow(this.editedRowIndex);
        this.editedEntry = undefined;
        this.editedRowIndex = undefined;
    }
    
    public onItemFieldChange(payNumber: number): void {
        const editedEntry: PayrollPayCode = _.find(this.manageService.list, { "payrollPaycode": this.editedEntry.payrollPaycode });
        if (editedEntry.payNumber !== payNumber) {
            this.manageService.markAsDirty();
        }
    }

    public onBulkSaveClick(): void {
        this.manageService.doBulkSave();
    }

    public trackByItem(index: number, item: GridItem): any {
        return index;// or item.id
    }

}
