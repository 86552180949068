import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { RolesContainer, IRolesRow, RolesRightGroupRow, RolesProfileRow, RolesMenuModuleGroupRow, RolesComponentsModuleGroupRow, RoleColumnsState } from '../../../models/index';
import { RolesManagementService } from '../../../services/index';
var RolesAccessTableGridComponent = /** @class */ (function () {
    function RolesAccessTableGridComponent(managementService, changeDetector, elementRef) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.roleColumnsState = new RoleColumnsState();
        this.elementRef = elementRef;
    }
    RolesAccessTableGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.records = [];
            _this.records = _.concat(_this.records, container.profileRows, container.menuModuleGroupRows, container.rightGroupRows, container.otherRightGroupRows);
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.filterSubscription = this.managementService.rolesColumnsStateChanged$
            .subscribe(function (state) {
            _this.roleColumnsState = state;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    RolesAccessTableGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    RolesAccessTableGridComponent.prototype.onCellFilterChanged = function (filter, item) {
        this.managementService.roleRightsFilterChanged(filter, item.type);
    };
    RolesAccessTableGridComponent.prototype.onRoleProfileToggled = function (roleId, row) {
        this.managementService.onChangeNotify('onRoleProfileToggled');
    };
    RolesAccessTableGridComponent.prototype.onRoleRightGroupToggled = function (roleId, row) {
        row.setNextStatus(roleId);
        this.managementService.onChangeNotify('onRoleRightGroupToggled');
    };
    RolesAccessTableGridComponent.prototype.onRoleMenuGroupToggled = function (roleId, row) {
        this.managementService.onChangeNotify('onRoleMenuGroupToggled');
    };
    RolesAccessTableGridComponent.prototype.onRoleComponentsGroupToggled = function (roleId, row) {
        row.setNextStatus(roleId);
        this.managementService.onChangeNotify('onRoleComponentsGroupToggled');
    };
    RolesAccessTableGridComponent.prototype.roleSectionChanged = function (roleId, row) {
        row.recalcStatus(roleId);
    };
    RolesAccessTableGridComponent.prototype.roleRightModuleChanged = function (roleId, row) {
        row.recalcStatus(roleId);
        this.managementService.onChangeNotify('onRoleRightModuleToggled');
    };
    RolesAccessTableGridComponent.prototype.roleOtherRightModuleChanged = function (roleId, row) {
        row.recalcStatus(roleId);
        this.managementService.onChangeNotify('onRoleOtherRightModuleToggled');
    };
    RolesAccessTableGridComponent.prototype.roleMenuModuleChanged = function (roleId, row) {
        row.recalcStatus(roleId);
    };
    RolesAccessTableGridComponent.prototype.roleComponentsModuleChanged = function (roleId, row) {
        row.recalcStatus(roleId);
    };
    RolesAccessTableGridComponent.prototype.isShowFilter = function (item) {
        var descr = _.lowerCase(item.description);
        return descr === 'actions' || descr === 'other rights';
    };
    RolesAccessTableGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesAccessTableGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesAccessTableGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesAccessTableGridComponent.prototype, "filterSubscription", void 0);
    return RolesAccessTableGridComponent;
}());
export { RolesAccessTableGridComponent };
