import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter, Host } from '@angular/core';
import { PopperContent } from 'ngx-popper';

@Component({
  moduleId: module.id,
  selector: 'slx-action-list-item',
  templateUrl: 'action-list-item.component.html',
})
export class ActionListItemComponent {
  @Input('disabled')
  public isDisabled: boolean;

  @Input()
  public hidden: boolean;

  @Input('className')
  public customClassName: string;

  @Input()
  public fullyCustomStyle: boolean = false;

  @Output()
  public onClick: EventEmitter<MouseEvent>;

  private defaultCssClasse: string = 'slx-action-list-item';

  constructor(@Host() private popper: PopperContent) {
    this.hidden = false;
    this.fullyCustomStyle = false;
    this.popper = popper;
    this.onClick = new EventEmitter<MouseEvent>();
  }

  public onMouseup(event: MouseEvent): void {
    if (!this.isDisabled) {
      this.onClick.emit(event);
      this.popper.hide();
    }
  }

  public get className(): string {
    const classes: string[] = [];
    if (_.isString(this.customClassName) && _.size(this.customClassName) > 0) {
      classes.push(this.customClassName);
    }
    if (!this.fullyCustomStyle) {
      classes.push(this.defaultCssClasse);
    }
    if (this.isDisabled) {
      classes.push('disabled');
    }

    return classes.join(' ');
  }
}
