import { Injectable } from '@angular/core';
import {
  IPBJManualTimeEntry,
  PBJManualTimeEntry,
  IPBJManualTimeEntity,
  PBJManualTimeEntity,
  IPBJManualTimeEntryEmployee,
  PBJManualTimeEntryEmployee
} from '../../models/index';
import { LookupMapService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import * as _ from 'lodash';
import * as moment from 'moment';

@Injectable()
export class PbjManualEntryMapService {
  private lookupMapService: LookupMapService;

  constructor(lookupMapService: LookupMapService) {
    this.lookupMapService = lookupMapService;
  }

  public mapToPBJManualEntity(dto: IPBJManualTimeEntity): PBJManualTimeEntity {
    let manualEntry: PBJManualTimeEntity = new PBJManualTimeEntity();

    manualEntry.orgLevelId = dto.orgLevelId;
    manualEntry.status = dto.status;
    manualEntry.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    manualEntry.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    manualEntry.entities = _.map(dto.entities, (entry) => this.mapToPBJManualEntry(entry));

    return manualEntry;
  }

  public mapToPBJManualEntry(entry: IPBJManualTimeEntry): PBJManualTimeEntry {
    let pbjEntry: PBJManualTimeEntry = new PBJManualTimeEntry();
    pbjEntry.addedByUsername = entry.addedByUsername;
    pbjEntry.addedDate = dateTimeUtils.convertFromDtoString(entry.addedDate);
    pbjEntry.center = entry.center;
    pbjEntry.date = dateTimeUtils.convertFromDtoString(entry.date);
    pbjEntry.department = entry.department;
    pbjEntry.employee = this.mapToPBJManualEntryEmployee(entry.employee);
    pbjEntry.entryType = entry.entryType;
    pbjEntry.hours = entry.hours;
    pbjEntry.id = entry.id;
    pbjEntry.position = this.lookupMapService.mapPosition(entry.position);
    pbjEntry._selectedEntry = false;

    return pbjEntry;
  }

  public mapToPBJManualEntryEmployee(emp: IPBJManualTimeEntryEmployee): PBJManualTimeEntryEmployee {
    const employee: PBJManualTimeEntryEmployee = new PBJManualTimeEntryEmployee();
    employee.dateHired = dateTimeUtils.convertFromDtoString(emp.dateHired);
    employee.employeeType = emp.employeeType;
    employee.id = emp.id;
    employee.mobilePhoneNumber = emp.mobilePhoneNumber;
    employee.name = emp.name;
    employee.payrollNumber = emp.payrollNumber;
    employee.phoneNumber = emp.phoneNumber;

    return employee;
  }

  public mapToPBJManualEntryDTO(entry: PBJManualTimeEntry, isNew: boolean): IPBJManualTimeEntry {
    let pbjEntryDto: IPBJManualTimeEntry = {
      addedByUsername: entry.addedByUsername,
      addedDate: dateTimeUtils.convertToDtoString(entry.addedDate),
      center: entry.center,
      date: dateTimeUtils.convertToDtoDateTimeString(entry.date),
      department: entry.department,
      employee: this.mapToPBJManualEntryEmployeeDTO(entry.employee),
      entryType: entry.entryType,
      hours: entry.hours,
      id: entry.id,
      position: this.lookupMapService.mapPositionDto(entry.position),
    };

    if (isNew) {
      delete pbjEntryDto.id;
    }

    return pbjEntryDto;
  }

  public mapToPBJManualEntryEmployeeDTO(employee: PBJManualTimeEntryEmployee): IPBJManualTimeEntryEmployee {
    const employeeDTO: IPBJManualTimeEntryEmployee = {
      dateHired: dateTimeUtils.convertToDtoDateTimeString(employee.dateHired),
      employeeType: employee.employeeType,
      id: employee.id,
      mobilePhoneNumber: employee.mobilePhoneNumber,
      name: employee.name,
      payrollNumber: employee.payrollNumber,
      phoneNumber: employee.phoneNumber,
    };

    return employeeDTO;
  }
}
