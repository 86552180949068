<kendo-grid class="slx-full-height vacation-grid"
  [data]="gridView"
  [sortable]="{ mode: 'multiple' }"
  [groupable]="true"
  [group]="gridState.group"
  [filterable]="false"
  [sort]="gridState.sort"
  (dataStateChange)="dataStateChange($event)"
>
  <kendo-grid-column title="Name" field="employee.name" [locked]="canBeLocked" width="200">
    <ng-template kendoGridHeaderTemplate>
      Name
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem.employee.id">
        {{dataItem.employee.name}}
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['position']?.displayed" title="Position" field="position.name" width="200" >
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.position.name}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['type']?.displayed" title="Type" field="employee.employeeType.name" width="100" >
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.employee.employeeType.name}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['dateOfHire']?.displayed" title="Date of Hire" field="employee.dateHired" width="140" >
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.employee.dateHired | amDateFormat: appConfig.dateFormat}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['unit']?.displayed" title="Unit" field="unit.name" width="100" >
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.unit.name}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['shift']?.displayed" title="Shift" field="shift.name" width="100" >
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.shift.name}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['group']?.displayed" title="Group" field="position.group" width="200" >
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.position.group}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['days']?.displayed" title="Days" field="vacationDays" width="50" filter="numeric">
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.vacationDays}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="columnsSettings['hours']?.displayed" title="Hours" field="vacationHours" width="50" filter="numeric">
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.vacationHours}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column *ngFor="let week of (gridData?.weeks | values)" width="40"
    class="no-padding">
    <ng-template kendoGridHeaderTemplate kendoGridGroupHeaderTemplate>
      <div class="week-column">
        <div>{{week.value | amDateFormat: appConfig.dateMonthTextFormat}}</div>
        <div>{{week.value | amDateFormat: appConfig.dayNumberFormat}}</div>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem >
      <div class="active-cell" (click)="onDetailClick(dataItem, week.key)" [ngClass]="{'not-active-cell': !isActive(dataItem, week.key)}">
        <a class="clicker" *ngIf="isActive(dataItem, week.key)">
          {{dataItem.weeks | property: week.key | property: 'safeDays'}}
        </a>
      </div>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group="group" let-field="field">
      {{ group | vacationDays: week.key }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
