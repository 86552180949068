import { Component, Input, EventEmitter, Output, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import {
  BenefitEligibilityRuleStatementGroupItem,
  BenefitEligibilityRuleConfigurationVariable,
  BenefitClass,
  BenefitEligibilityRuleConfigurationPredicate,
} from '../../../models';
import * as _ from 'lodash';
import { BenefitEligibilityRuleStatementsManagementService } from '../../../services/benefit-eligibility-rules';
import { InfoDialogComponent } from './../../../../../common/components/index';
import { ModalService } from '../../../../../common/services/index';

export enum BenefitEligibilityRuleGroupErrorType {
  None,
  SingleItemGroupSelected,
  NoItemsToGroup,
}

@Component({
  selector: 'slx-benefit-details-eligibility-rule-dialog-statements',
  templateUrl: './benefit-details-eligibility-rule-dialog-statements.component.html',
  styleUrls: ['./benefit-details-eligibility-rule-dialog-statements.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class BenefitDetailsEligibilityRuleDialogStatementsComponent implements OnChanges {
  @Input()
  ruleStatements: BenefitEligibilityRuleStatementGroupItem[];

  @Input()
  configurationVariables: BenefitEligibilityRuleConfigurationVariable[];

  @Input()
  configurationPredicates: BenefitEligibilityRuleConfigurationPredicate[];

  @Input()
  benefitClasses: BenefitClass[];

  @Input()
  isEditMode:boolean;

  @Output()
  ruleStatementsChanged: EventEmitter<BenefitEligibilityRuleStatementGroupItem[]>;

  ruleId: number;
  flatRuleStatements: BenefitEligibilityRuleStatementGroupItem[];
  tiersGroupCount: number;
  isGroupingEnabled: boolean;
  groupStatementsTooltipMessage: string;

  errorState: BenefitEligibilityRuleGroupErrorType;

  constructor(
    private ruleStatementsManagementService: BenefitEligibilityRuleStatementsManagementService,
    private modalService: ModalService
  ) {
    this.ruleId = 0;
    this.flatRuleStatements = [];
    this.tiersGroupCount = 0;
    this.isGroupingEnabled = false;
    this.groupStatementsTooltipMessage = 'Group selected clauses';
    this.errorState = BenefitEligibilityRuleGroupErrorType.NoItemsToGroup;

    this.ruleStatementsChanged = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<
      BenefitDetailsEligibilityRuleDialogStatementsComponent,
      SimpleChange
    >;

    const ruleStatementsChange = componentChanges.ruleStatements;
    if (ruleStatementsChange) {
      const currentGroupItems = ruleStatementsChange.currentValue as BenefitEligibilityRuleStatementGroupItem[];
      const previousGroupItems = ruleStatementsChange.previousValue as BenefitEligibilityRuleStatementGroupItem[];

      if (!_.isEqual(currentGroupItems, previousGroupItems)) {
        this.flatRuleStatements = this.ruleStatementsManagementService.getFlatRuleStatements(this.ruleStatements);
        this.tiersGroupCount = this.ruleStatementsManagementService.getTiersGroupCount(this.ruleStatements);
        this.errorState = this.getGroupErrorState();
        this.isGroupingEnabled = this.getIsGroupingEnabled(this.errorState);
        this.groupStatementsTooltipMessage = this.getGroupTooltipMessage(this.errorState);
      }
    }
  }

  unionRuleStatementsIntoGroup() {
    try {
      const newRuleStatements = this.ruleStatementsManagementService.tryToUnionRuleStatementsIntoGroup(
        this.ruleStatements
      );
      this.ruleStatementsChanged.next(newRuleStatements);
    } catch {
      InfoDialogComponent.OpenDialog('Warning', 'Groups can not intersect each other.', this.modalService);
    }
  }

  onRemoveRule(ruleStatement: BenefitEligibilityRuleStatementGroupItem) {
    if (this.ruleStatements.length > 1) {
      const newRuleStatements = this.ruleStatementsManagementService.removeRuleStatementGroupItem(ruleStatement, this.ruleStatements);
      this.ruleStatementsManagementService.deselectRuleStatements(newRuleStatements);
      this.ruleStatementsChanged.next(newRuleStatements);
    }
  }

  onRuleStatementUngrouped(groupItem: BenefitEligibilityRuleStatementGroupItem) {
    const newRuleStatements = this.ruleStatementsManagementService.ungroupRuleStatement(this.ruleStatements, groupItem);
    this.ruleStatementsManagementService.deselectRuleStatements(newRuleStatements);

    this.ruleStatementsChanged.next(newRuleStatements);
  }

  trackByItem(index: number, item: BenefitEligibilityRuleStatementGroupItem): number {
    return item.id;
  }

  insertRuleStatementBeforeItem(
    ruleStatement: BenefitEligibilityRuleStatementGroupItem,
    isInsertedBeforeFirstItem: boolean
  ) {
    const isItemBelogsToFirstGroup = this.ruleStatementsManagementService.isItemBelogsToFirstGroup(
      this.ruleStatements,
      ruleStatement
    );
    const newRuleStatements = this.ruleStatementsManagementService.insertRuleStatementBeforeItem(
      ruleStatement,
      this.ruleStatements,
      isInsertedBeforeFirstItem || isItemBelogsToFirstGroup
    );
    this.ruleStatementsManagementService.deselectRuleStatements(newRuleStatements);

    this.ruleStatementsChanged.next(newRuleStatements);
  }

  addRuleStatement() {
    const newRuleStatements = this.ruleStatementsManagementService.addRuleStatement(this.ruleStatements);
    this.ruleStatementsManagementService.deselectRuleStatements(newRuleStatements);

    this.ruleStatementsChanged.next(newRuleStatements);
  }

  onItemSelectedChanged() {
    this.ruleStatementsManagementService.reInitializeItemSelections(this.ruleStatements);
    this.errorState = this.getGroupErrorState();
    this.isGroupingEnabled = this.getIsGroupingEnabled(this.errorState);
    this.groupStatementsTooltipMessage = this.getGroupTooltipMessage(this.errorState);
  }

  onRuleStatementGroupItemChanged(ruleStatement: BenefitEligibilityRuleStatementGroupItem) {
    const newRuleStatements = _.map(this.ruleStatements, (statement) => {
      return ruleStatement.id === statement.id ? ruleStatement : statement;
    });

    this.ruleStatementsChanged.next(newRuleStatements);
  }

  private getGroupErrorState() {
    const totalPlainSelectedItemsCount = this.ruleStatementsManagementService.getTotalPlainSelectedItemsCount(
      this.ruleStatements
    );
    const totalExternalSelectedGroupsWithItemsOnlyCount = this.ruleStatementsManagementService.getTotalExternalSelectedGroupsWithItemsOnlyCount(
      this.ruleStatements
    );
    const totalItemsInSelectedGroupsOnlyCount = this.ruleStatementsManagementService.getTotalItemsInSelectedGroupsOnlyCount(
      this.ruleStatements
    );

    if (totalExternalSelectedGroupsWithItemsOnlyCount === 0 && totalPlainSelectedItemsCount === 0) {
      return BenefitEligibilityRuleGroupErrorType.NoItemsToGroup;
    }
    if (
      totalExternalSelectedGroupsWithItemsOnlyCount === 1 &&
      totalPlainSelectedItemsCount === totalItemsInSelectedGroupsOnlyCount
    ) {
      return BenefitEligibilityRuleGroupErrorType.SingleItemGroupSelected;
    }
    if (totalPlainSelectedItemsCount >= 2) {
      return BenefitEligibilityRuleGroupErrorType.None;
    }
    return BenefitEligibilityRuleGroupErrorType.NoItemsToGroup;
  }

  private getGroupTooltipMessage(errorState: BenefitEligibilityRuleGroupErrorType) {
    switch (errorState) {
      case BenefitEligibilityRuleGroupErrorType.SingleItemGroupSelected:
        return 'A group already exists for selected clauses';
      case BenefitEligibilityRuleGroupErrorType.None:
      case BenefitEligibilityRuleGroupErrorType.NoItemsToGroup:
      default:
        return 'Group selected clauses';
    }
  }

  private getIsGroupingEnabled(errorState: BenefitEligibilityRuleGroupErrorType) {
    switch (errorState) {
      case BenefitEligibilityRuleGroupErrorType.None:
        return true;
      case BenefitEligibilityRuleGroupErrorType.NoItemsToGroup:
      case BenefitEligibilityRuleGroupErrorType.SingleItemGroupSelected:
      default:
        return false;
    }
  }
}
