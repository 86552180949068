import { Routes } from '@angular/router';

import { ApplicationContainerComponent } from './components/index';
import { OrgLevelResolver } from './services/index';
import { ConfigurePartnerPositionsComponent } from '../configuration/components';
import { CanLeaveGuard } from '../common/services/index';

export const intgRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        component: ConfigurePartnerPositionsComponent,
        data: { componentId: 'intg_position_mapping', accessContext: 'OrgLevel' }
      },
      {
        path: 'intg_position_mapping',
        resolve: [OrgLevelResolver],
        canDeactivate: [CanLeaveGuard],
        component: ConfigurePartnerPositionsComponent,
        data: { componentId: 'intg_position_mapping', accessContext: 'OrgLevel' }
      },
      
    ]
  },
];


