export interface ICorporation {
    id: number;
    orgLevelId: number;
    name: string;
}

export class Corporation {
    public id: number;
    public orgLevelId: number;
    public name: string;
}

