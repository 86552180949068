import { Component, Provider} from '@angular/core';
import * as _ from 'lodash';
import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { unsubscribe } from '../../../../../core/decorators';
import { Subscription } from 'rxjs';
import { EmployersManagementService } from '../../../../../configuration/services';

@Component({
  selector: 'slx-manage-ale-groups-dialog',
  templateUrl: './manage-ale-groups-dialog.component.html',
  styleUrls: ['./manage-ale-groups-dialog.component.scss'],
  providers: [EmployersManagementService]
})

export class ManageAleGroupsDialogComponent implements IDialog {
  public dialogResult: boolean = false;
  public isSaveDisabled: boolean = true;
  public isLoading: boolean = true;

  private options: DialogOptions;
  private modalService: ModalService;

  @unsubscribe()
  private subscriptionsClose: Subscription;

  public static openDialog(modalService: ModalService, callback?: (result: boolean) => void): ManageAleGroupsDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 800;
    dialogOptions.height = 550;
    dialogOptions.hideTitleBar = true;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];

    return modalService.globalAnchor.openDialog(
      ManageAleGroupsDialogComponent,
      'Manage Groups',
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor(
    options: DialogOptions,
    modalService: ModalService,
    private manService: EmployersManagementService,
  ) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public ngOnInit(): void {

    this.subscriptionsClose = this.manService.subscribeToClosePopup(() => {
      this.onClose();
    });
  }

  public onLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public onHasChanges(hasChanges: boolean): void {
    this.dialogResult = hasChanges;
  }

  public isGroupChanges(value: boolean): void {
    this.isSaveDisabled = value;
  }

  public onCancel(): void {
    if (!this.isSaveDisabled) {
      this.manService.markAsDirty();
      this.manService.requestClosePopup();
    }
    else {
      this.dialogResult = true;
      this.modalService.closeWindow(this.options.windowUniqueId);
    }
  }

  public onClose(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public isSaveComplete(value: boolean): void {
    this.dialogResult = value;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
