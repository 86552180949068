  <div class="message-center-toolbar">
    <slx-toolbar [alignExpandButtonRight]="true">
      <ng-template slxToolbarSectionTemplate alignMode="left" [width]="400">
        <button type="button" *ngIf="canSendSMS || canSendSlate" class="slx-button slx-blue slx-mobile-adapted compose-btn" (click)="composeNewDialog()">
          <span class="slx-button__text"><em class="far fa-paper-plane"> </em> <span class="hide-text pd-lft-text">Compose</span><span class="pd-lft-text">New</span></span>
        </button>
        <button *ngIf="activeTab == 0 && canViewSmsTab" type="button" class="slx-button  slx-mobile-adapted archive-btn" [disabled]="selectedMessageIds.length==0 && selectedCheckboxEvent === ''"
                (click)="archiveMessages()">
          <span class="button__text"><em class="fas fa-archive slx-button-icon"> </em> <span class="hide-text pd-lft-text">Archive</span></span>
        </button>
        <button *ngIf="activeTab==1" type="button" class="slx-button slx-mobile-adapted read-btn" [disabled]="selectedMessageIds.length==0 && selectedCheckboxEvent === ''" (click)="restoreMessages()">
          <span class="button__text"><em class="fas fa-archive slx-button-icon"> </em> <span class="hide-text pd-lft-text">Restore</span></span>
        </button>
        <button type="button" *ngIf="canViewSmsTab" class="slx-button slx-mobile-adapted read-btn" [disabled]="isSelectedUnreadIdsEmpty()" (click)="markAsReadMessages()">
          <span class="button__text"><em class="fas fa-envelope-open slx-button-icon"> </em> <span class="hide-text pd-lft-text">Mark as read</span> </span>
        </button>
        <button type="button" *ngIf="canViewSmsTab" class="slx-button slx-mobile-adapted unread-btn" [disabled]="isSelectedReadIdsEmpty()" (click)="markAsUnreadMessages()">
          <span class="button__text"><em class="fas fa-envelope slx-button-icon"> </em> <span class="hide-text pd-lft-text">Mark as unread</span> </span>
        </button>
      </ng-template>
      <ng-template slxToolbarSectionTemplate alignMode="rightIfNothingCollapsed" [width]="100">
        <slx-action-list class="pad-right">
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Settings</slx-action-button>
          <popper-content #popperContent>
            <div class="content-pad">
              <slx-radio-button caption="Relevant Messages"
                                name="radioMyMessage"
                                [option]="true"
                                [(ngModel)]="isMyMessage"
                                (ngModelChange)="onChangeFilter()"></slx-radio-button>
            </div>
            <div class="content-pad">
              <slx-radio-button caption="All messages"
                                name="radioMyMessage"
                                [option]="false"
                                [(ngModel)]="isMyMessage"
                                (ngModelChange)="onChangeFilter()"></slx-radio-button>
            </div>
          </popper-content>
        </slx-action-list>
        </ng-template>
      </slx-toolbar>
</div>
  <div class="message-center-container">
    <div>
      <div class="providers-tabs flex">
        <div class="providers-tabs__slider">
          <ul #list class="providers-tabs__list flex flex-direction-row">
            <li class="providers-tabs__item number-lebels" *ngIf="canViewSmsTab && (!isExpanded || (isExpanded && isActiveMenu(0)))" [ngClass]="{
            'active':isActiveMenu(0),
            'disabled':!isActiveMenu(0)
          }" (click)="onClickTab(0)">
              <em class="fas fa-envelope font-icon slx-line-tabs__icon number-color"></em>
              <span class="header-title__text">
                SMS <span [ngClass]="{'unread-circle':smsReadUnreadCount?.unRead < 10,'unread-circle-more-message':smsReadUnreadCount?.unRead>=10}" *ngIf="smsReadUnreadCount"> {{smsReadUnreadCount?.unRead}} </span>
                <span class="fontsize12" *ngIf="smsReadUnreadCount"> / {{smsReadUnreadCount?.total}}</span>
              </span>
            </li>

            <li class="providers-tabs__item_slate number-lebels" *ngIf="canViewSlateTab && (!isExpanded || (isExpanded && isActiveMenu(2)))" [ngClass]="{
              'active':isActiveMenu(2),
              'disabled':!isActiveMenu(2)
            }" (click)="onClickTab(2)">
                <em class="fas fa-solid fa-clock font-icon slx-line-tabs__icon number-color"></em>
                <span class="header-title__text">
                  Slate
                  <span class="unread-circle" *ngIf="slateMessagesCount">{{slateMessagesCount}}</span>
                </span>
              </li>

            <li class="providers-tabs__item_archive number-lebels" *ngIf="canViewSmsTab && (!isExpanded || (isExpanded && isActiveMenu(1)))" [ngClass]="{
            'active':isActiveMenu(1),
            'disabled':!isActiveMenu(1)
          }" (click)="onClickTab(1)">
              <em class="fas fa-calendar-times font-icon slx-line-tabs__icon number-color"></em>
              <span class="header-title__text">
                Archived
                <span *ngIf="archiveReadUnreadCount"><span [ngClass]="{'unread-circle_archive':archiveReadUnreadCount?.unRead<10,'unread-circle_archive-more-message':archiveReadUnreadCount?.unRead>=10}" *ngIf="activeTab === 1"> {{archiveReadUnreadCount?.unRead}}</span> </span>
                <span *ngIf="archiveReadUnreadCount"><span class="fontsize12" *ngIf="activeTab === 1"> / {{archiveReadUnreadCount?.total}}</span></span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="flex">
      <div [ngClass]="{
      'message-center-tab-expanded':isExpanded,
      'message-center-tab': !isExpanded
    }">
        <div class="hide-text expColl" [ngClass]="{'colExpand':isExpanded,'colCollapse': !isExpanded}">
          <div *ngIf="!isExpanded" class="exp-coll flex">
            <a class="expCollbtn" (click)="isExpanded=!isExpanded;">
              <em class="fas fa-chevron-left icon"></em>
            </a>
            <span class="btn-text">Collapse</span>
          </div>
          <div *ngIf="isExpanded" class="exp-coll flex">
            <a class="expCollbtn" (click)="isExpanded=!isExpanded;">
              <em class="fas fa-chevron-right icon"></em>
            </a>
            <span class="btn-text">Expand</span>
          </div>
        </div>


        <ng-container *ngIf="!activeTab && canViewSmsTab">
          <div class="message-screen-canvas">
            <slx-message-list [selectedReadIds]="selectedReadIds" [messageCountObj]="smsReadUnreadCount" [selectedId]="selectedId" [selectedUnreadIds]="selectedUnreadIds" [IsArchive]="false" [isExpanded]="isExpanded" [isMyMessage]="isMyMessage"
                              (updateMsgCenterSelectedMessageIds)="updateMsgCenterSelectedMessageIds($event)" (isDataLoading)="disabledTab($event)" (sendSelectedEventToParent)="getSelectedCheckboxEvent($event)">
            </slx-message-list>

          </div>
        </ng-container>
        <ng-container *ngIf="activeTab && activeTab !== 2">
          <div class="message-screen-canvas">
            <slx-message-list [selectedReadIds]="selectedReadIds" [messageCountObj]="archiveReadUnreadCount" [selectedUnreadIds]="selectedUnreadIds" [IsArchive]="true" [isExpanded]="isExpanded" [isMyMessage]="isMyMessage"
                              (updateMsgCenterSelectedMessageIds)="updateMsgCenterSelectedMessageIds($event)" (isDataLoading)="disabledTab($event)" (getArchiveReadUnreadCount)="getArchiveReadUnreadCount($event)" (sendSelectedEventToParent)="getSelectedCheckboxEvent($event)"></slx-message-list>
          </div>
        </ng-container>

        <ng-container *ngIf="activeTab == 2">
          <slx-slate-message-list [isExpanded]="isExpanded" (updateMessagesCount)="updateMessagesCount($event)"></slx-slate-message-list>
        </ng-container>

      </div>
      <div class="hide-text" [ngClass]="{
      'message-center-thread-expanded':isExpanded,
      'message-center-thread': !isExpanded
    }">
        <slx-slate-message-content [selectedSlateMessage]="selectedSlateMessage" *ngIf="activeTab == 2"></slx-slate-message-content>
        <slx-message-content *ngIf="activeTab === 0" [orgLevel]="orgLevel" [isMyMessage]="isMyMessage"></slx-message-content>
        <slx-message-content *ngIf="activeTab === 1" [orgLevel]="orgLevel" (getArchiveReadUnreadCount)="getArchiveReadUnreadCount($event)" [isMyMessage]="isMyMessage"></slx-message-content>
      </div>
    </div>
  </div>
