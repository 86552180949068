import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { Observable } from 'rxjs-compat';
import { Subscription } from 'rxjs/Subscription';
import { DetailScreenService } from '../../../scheduler/services/detail-screen/detail-screen-api.service';
import { appConfig } from '../../../app.config';
import { ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogComponent, KendoGridCustomSelectionHelper, KendoGridStateHelper, ModalService, } from '../../../common/index';
import { screenUtils } from '../../../common/utils/index';
import { destroyService, mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { EditableListActionKind } from '../../models/editableList/editable-list-action.model';
import { AgenciesApiService, PositionsManagementService } from '../../services/index';
import { PartnerConfigApiService } from '../../services/integrations/partner-config-api.service';
import { PartnerConfigManagementService } from '../../services/integrations/partner-config-management.service';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
var ConfigurePartnerPositionsComponent = /** @class */ (function () {
    function ConfigurePartnerPositionsComponent(management, modalService, configAPIService, manageService, detailScreenService, agenciesApiService) {
        var _this = this;
        this.modalService = modalService;
        this.configAPIService = configAPIService;
        this.manageService = manageService;
        this.agenciesApiService = agenciesApiService;
        this.agencyListData = [];
        this.groups = [{ field: 'positionGroupName' }];
        this.positionList = [];
        this.subscriptionId = 1;
        this.addAction = new EventEmitter();
        this.management = management;
        this.detailScreenService = detailScreenService;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            _this.addAction.emit(true);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(ConfigurePartnerPositionsComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePartnerPositionsComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePartnerPositionsComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurePartnerPositionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            configureMode: true,
            copyMode: false,
        };
        this.appConfig = appConfig;
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.customerId = session.alias;
            }
        });
        this.orgLevelSubscripion = this.orgLevel$.subscribe(function (selectedOrgLevel) {
            _this.orgLevel = selectedOrgLevel;
            // Update agency information
            _this.getPartnerAgenciesPositions(_this.orgLevel);
        });
        this.savedSubscription = this.management.onItemSaved$.subscribe(function (item) {
            _this.gridState.editedRecord = null;
            _this.gridState.savedEditedRecord = null;
        });
        this.onloadedSubscription = this.management.onLoaded$.subscribe(function (state) {
            _this.gridState.state.group = _this.groups;
        });
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'configureMode'))
                _this.state.configureMode = state.configureMode;
            if (_.has(state, 'copyMode'))
                _this.state.copyMode = state.copyMode;
            _this.essTemplatesEnabled = _this.management.essTemplatesEnabled;
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (request) {
            if (!request.requireReassign) {
                var options = new ConfirmOptions();
                options.showCancel = true;
                options.showOK = true;
                ConfirmDialogComponent.openDialog('Confirmation', 'Do you want to delete the position?', _this.modalService, function (result) {
                    if (result) {
                        _this.management.doRemoveItem(request.itemToDelete);
                    }
                }, options);
            }
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
        this.orgChangeSubscription = this.management.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
        });
    };
    ConfigurePartnerPositionsComponent.prototype.getPartnerAgenciesPositions = function (selectedOrgLevel) {
        var _this = this;
        this.agenciesApiService.getAgencies(selectedOrgLevel.id).then(function (mappings) {
            _this.agencyMap = mappings.reduce(function (map, obj) {
                map[obj.agency.id] = obj.agency.name;
                return map;
            }, {});
            _this.agencyDetails(selectedOrgLevel).then(function (res) {
                _this.agencyListData = [];
                if (res == null || res.length == 0) {
                    return;
                }
                // filter and for active agencies, then call async calls positionmap for
                var filteredAgencies = res.filter(function (o) { return o.status == 'active'; });
                if (filteredAgencies == null || filteredAgencies.length == 0) {
                    return;
                }
                filteredAgencies.forEach(function (element) {
                    _this.agencyData = element;
                    _this.agencyData.agency_name = _this.agencyMap[_this.agencyData.agency_id];
                    _this.agencyListData.push(_this.agencyData);
                });
            });
        });
    };
    ConfigurePartnerPositionsComponent.prototype.agencyDetails = function (orgLevel) {
        return this.detailScreenService.getAgencyDetails(orgLevel, this.customerId);
    };
    // Must be, see #issueWithAOTCompiler
    ConfigurePartnerPositionsComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    ConfigurePartnerPositionsComponent.prototype.onCopyItems = function (event) {
        event.preventDefault();
        this.management.openCopyItems();
    };
    ConfigurePartnerPositionsComponent.prototype.switchToConfigure = function () {
        this.management.closeCopyItems();
    };
    ConfigurePartnerPositionsComponent.prototype.extenalEditorAction = function (action) {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.crudHelper.onSaveItem({ dataItem: this.management.editingItem, isNew: this.management.isEditingNewItem });
        }
        else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.crudHelper.onCancelItem(this.management.editingItem);
        }
    };
    ConfigurePartnerPositionsComponent.prototype.onMobileRendererEvent = function (action, item, index) {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
    };
    ConfigurePartnerPositionsComponent.prototype.getGridData = function () {
        var _this = this;
        return function () {
            return {
                data: _.cloneDeep(_this.gridState.view.data),
                group: _this.gridState.state.group,
            };
        };
    };
    ConfigurePartnerPositionsComponent.prototype.selectionChange = function (selectedObj) {
        var _this = this;
        this.state.isLoading = true;
        this.state.configureMode = false;
        this.filterPositionList();
        this.selectedPartnerName = selectedObj.agency_name;
        this.selectedPartnerId = selectedObj.partner_id;
        this.manageService.getPartnerPositionsDefinitions(selectedObj.partner_id).then(function (response) {
            _this.partnerPositionDefinitionList = response;
            _this.partnerPositions = _this.partnerPositionDefinitionList.map(function (elem) { return elem.partnerPositionName; }).sort();
            _this.state.isLoading = false;
            _this.state.configureMode = true;
        });
    };
    ConfigurePartnerPositionsComponent.prototype.filterPositionList = function () {
        var positionsData = [];
        this.gridState.view.data.forEach(function (e) {
            e.items.forEach(function (ele) {
                positionsData.push(ele);
            });
        });
        this.positionList = positionsData;
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PositionsManagementService)
    ], ConfigurePartnerPositionsComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "onloadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "orgChangeSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], ConfigurePartnerPositionsComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ConfigurePartnerPositionsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePartnerPositionsComponent.prototype, "orgLevelSubscripion", void 0);
    return ConfigurePartnerPositionsComponent;
}());
export { ConfigurePartnerPositionsComponent };
