<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
      <div class="accruals-toolbar">
        <slx-toolbar class="accruals-toolbar__helper" [alignExpandButtonRight]="false">
          <ng-template slxToolbarSectionTemplate let-isCollapsed="true" alignMode="rightIfNothingCollapsed">
            <div class="accruals-toolbar__control as-flex-start mt-8">
              <div class="pull-left pd-3">
                <span class="ico-expand" (click)="onExpandCollapse(true)">
                  <i class="txt-blue fa fa-angle-double-down ico-mar-r5" aria-hidden="true"></i> Expand All
                </span>
                <span class="slash-bar"></span>
                <span class="ico-expand" (click)="onExpandCollapse(false)">
                  <i aria-hidden="true" class="txt-blue fa fa-angle-double-up ico-mar-r5"></i> Collapse All
                </span>
              </div>
            </div>
            <div class="pull-right mart5">
              <slx-action-list>
                <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent"
                  [popperPosition]="'bottom-end'">Actions</slx-action-button>
                <popper-content #popperContent>
                  <slx-action-list-item (onClick)="onClickExportExcel()">Export to Excel</slx-action-list-item>
                  <slx-action-list-item (onClick)="onClickExportPdf()">Export to PDF</slx-action-list-item>
                  <slx-action-list-item *ngIf="isCreateorEditPolicy && isCreateorEditAccrualsRolePermission"
                    (onClick)="createPolicyForm()">Create New
                    Policy</slx-action-list-item>
                    <slx-action-list-item *ngIf="isCopyPolicy && isCopyAccrualsRolePermission" (onClick)="manService.copyPolicyDialog(orgLevelName, orgLevelId)">Copy Accrual Policy</slx-action-list-item>
                </popper-content>
              </slx-action-list>
            </div>
          </ng-template>
        </slx-toolbar>
      </div>
    </div>
    <div *ngIf="accrualPolicies && accrualPolicies.length == 0 && !isNewPolicy" class="accrual-unavailability-message">
      <section class="content">
        <img src="/assets/img/404-error-image.svg" alt="Error" class="error">
        <img src="/assets/img/slx-logo-6-1-blue.png" alt="Smartlinx" class="logo">
        <p class="details">No Policies are defined at this org level. Please select another org level where policies
          have been defined.</p>
      </section>
    </div>

    <form [formGroup]="policyForm" *ngIf="!!policyForm">
      <div class="slx-high-box__body slx-main-content-indents overflowy" formArrayName="policies">
        <div class="slx-policy-list" *ngFor="let accrualPolicy of accrualPoliciesFormArray(); let index = index">
          <slx-collapsible-section formGroupName="{{index}}" id="slx-Collapse"
            [uniqId]="generateDynamicUniqId([accrualPolicies])" [title]="accrualPolicy.get('policyName')?.value"
            [expanded]="accrualPolicy.get('expanded')?.value"
            (expandChanged)="onSectionExpandChanged(accrualPolicy , accrualPolicy.get('id')?.value)" mode="blue">
            <ng-container body>
              <slx-spinner [show]="isPolicyLoading">
                <div class="main-container">
                  <div class="policy-container">
                    <div class="policy-container-header">
                      <div class="policy-header-content">
                        <label>Accrual Policy Name:</label>
                        <ng-container
                          *ngIf="!accrualPolicy.get('isPolicyViewMode')?.value || accrualPolicy.get('isNewPolicy')?.value">
                          <input 
                              type="text" 
                              [required]="true" 
                              formControlName="policyName" 
                              placeholder="Policy Name"
                            (input)="existingPolicyCheck($event.target.value, accrualPolicy)" />
                        </ng-container>
                        <ng-container class="slx-policy-view" *ngIf="accrualPolicy.get('isPolicyViewMode')?.value">
                          {{accrualPolicy.get('policyName')?.value}}
                        </ng-container>
                        <span class="slx-error" errorMessage *ngIf="accrualPolicy.get('policyName').hasError('required')">
                          Value Required
                        </span>
                        <span class="slx-error" errorMessage
                          *ngIf="accrualPolicy.get('policyName').hasError('duplicateName')">
                          Policy Name already exists !
                        </span>
                      </div>
                      <div *ngIf="isCreateorEditPolicy && isCreateorEditAccrualsRolePermission"
                        class="policy-save-delete-content">
                        <button 
                            [disabled]="isDisabled ? true : false" 
                          *ngIf="accrualPolicy.get('isPolicyViewMode')?.value && !accrualPolicy.get('isNewPolicy')?.value"
                          (click)="editPolicy(accrualPolicy)">
                          <i class="fas fa-pencil"></i>
                        </button>
                        <button [disabled]="accrualPolicy.invalid ? true : false" [ngStyle]="{'border-color': accrualPolicy.invalid ? 'gray' : '#0092cb', 'cursor': accrualPolicy.invalid ? 'not-allowed' : 'pointer'}"
                          *ngIf="!accrualPolicy.get('isPolicyViewMode')?.value && !accrualPolicy.get('isNewPolicy')?.value"
                          (click)="savePolicyChanges(accrualPolicy)" type="submit">
                          <i [ngStyle]="{'color': accrualPolicy.invalid ? 'gray' : '#0092cb'}" class="fas fa-save"></i>
                        </button>
                        <button
                          *ngIf="!accrualPolicy.get('isPolicyViewMode')?.value && !accrualPolicy.get('isNewPolicy')?.value"
                          (click)="cancelPolicy(accrualPolicy)">
                          <i class="fas fa-times"></i>
                        </button>
                        <button 
                          (click)="deletePolicy(accrualPolicy)" 
                          [disabled]="isDisabled ? true : false"
                          *ngIf="accrualPolicy.get('isPolicyViewMode')?.value 
                                  && isDeleteAccrualsRolePermission
                                  && !accrualPolicy.get('isNewPolicy')?.value">
                          <i class="fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div formArrayName="rules">
                    <div *ngFor="let child of rule(accrualPolicy); let ruleIndex=index">
                      <div formGroupName="{{ruleIndex}}">
                        <div class="slx-rule-container" *ngIf="!child.get('deleted').value">
                          <div class="slx-rule-content">
                            <div class="slx-rule-head-content">
                              <label>Benefit Name:</label>
                              <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                && accrualPolicy.get('isPolicyViewMode')?.value
                                || accrualPolicy.get('isNewPolicy')?.value">
                                <input [ngStyle]="{'border': child.get('accrualTypeName')?.value == '' ? '1px solid lightcoral' : '1px solid gray'}"
                                  type="text" [required]="true" placeholder="Benefit Name"
                                  formControlName="accrualTypeName" />
                              </ng-container>
                              <ng-container *ngIf="child.get('isBenefitViewMode')?.value 
                                || !accrualPolicy.get('isPolicyViewMode')?.value">
                                <span *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                  {{child.get('accrualTypeName')?.value}}
                                </span>
                              </ng-container>
                              <span class="slx-error" style="font-size: 10px; padding: 5px; color: lightcoral;"
                                errorMessage *ngIf="child.get('accrualTypeName').errors">
                                Value Required
                              </span>
                            </div>
                            <div class="slx-rule-head-content">
                              <label>Benefit Start Date:</label>
                              <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                && accrualPolicy.get('isPolicyViewMode')?.value
                                || accrualPolicy.get('isNewPolicy')?.value">
                                <slx-date-picker-ngx 
                                  [isActive]="!child.get('startDate')?.value ? true : false"
                                  slx-input formControlName="startDate" 
                                  format='MM/dd/yyyy'
                                  [minDate]="manService.minStartDate()" 
                                  [maxDate]="getMaxOrEndDate(child)"
                                  [acceptNullDate]="true" 
                                  placeholder="Start Date" 
                                  name="startDate">
                                </slx-date-picker-ngx>
                                <span *ngIf="child.get('startDate').errors">Min Date Error</span>
                              </ng-container>
                              <ng-container
                                *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                <span *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('startDate')?.value
                                  | amDateFormat: appConfig.dateFormat}}</span>
                              </ng-container>
                            </div>
                            <div class="slx-rule-head-content">
                              <label>Benefit End Date:</label>
                              <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                && accrualPolicy.get('isPolicyViewMode')?.value
                                || accrualPolicy.get('isNewPolicy')?.value">
                                <slx-date-picker-ngx
                                  [isActive]="!child.get('endDate')?.value ? true : false"
                                  slx-input formControlName="endDate" 
                                  format='MM/dd/yyyy'
                                  [acceptNullDate]="true" 
                                  [minDate]="getMinDate(child)" 
                                  [maxDate]="maxDate()"
                                  placeholder="End Date" 
                                  name="endDate">
                                </slx-date-picker-ngx>
                                <span *ngIf="child.get('endDate').errors">Valid Date Required</span>
                              </ng-container>
                              <ng-container
                                *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                <span *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('endDate')?.value
                                  | amDateFormat: appConfig.dateFormat}}</span>
                              </ng-container>
                            </div>
                            <div class="slx-rule-head-content display-center">
                              <label>Accrued Based on:</label>
                              <span
                                [ngClass]="child.get('isBenefitViewMode')?.value && !accrualPolicy.get('isNewPolicy')?.value ? 'disabled' : 'enabled'">
                                <span>
                                  <input type="radio" [checked]="accrualPolicy.get('isNewPolicy')?.value 
                                              || child.get('accrualCalcType')?.value == 1 ? true : false" [value]="1"
                                    formControlName="accrualCalcType"><span class="slx-radio-content">Periods</span>
                                  <input type="radio" [checked]="child.get('accrualCalcType')?.value == 2 ? true : false" [value]="2"
                                    formControlName="accrualCalcType"><span class="slx-radio-content">Specific Dates</span>
                                  <span class="mr-5" *ngIf="child.get('accrualCalcType')?.value == 2 ? true : false">
                                    <input formControlName="rateFormula" placeholder="Rate Formula"
                                    [slxTooltip]="manService.getRate | bold" tipPosition="left" [tipClass]="'width-tip'"
                                      [disabled]="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value || !accrualPolicy.get('isNewPolicy')?.value ? true : false"
                                      type="text" />
                                  </span>
                                  <ng-container *ngIf="child.get('accrualCalcType')?.value == 2 ? true : false">
                                    <span class="mr-10 vertical-center" *ngIf="!child.get('isBenefitViewMode')?.value 
                                    || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(1)">
                                      <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                        class="box__icon fas fa-info"></i>
                                      <div class="slx-info-custom"
                                        *ngIf="manService.isViewed == 1 && manService.isActive ? true : false">
                                        <span [innerHTML]="manService.title | bold"></span>
                                        <span [innerHTML]="manService.text | bold"></span><br><br>
                                        <span>{{manService.text1}}</span>
                                      </div>
                                    </span>
                                  </ng-container>
                                  <span *ngIf="isAbilitytoChangeAccrualRuleTypeBasedonSeniority">
                                    <input type="radio" [checked]="child.get('accrualCalcType')?.value == 3 ? true : false" [value]="3"
                                      formControlName="accrualCalcType"><span class="slx-radio-content">Custom</span>
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div class="slx-rule-edit-content"
                            *ngIf="isCreateorEditPolicy && isCreateorEditAccrualsRolePermission">
                            <div class="dropdown">
                              <button class="drp-btn" *ngIf="child.get('isBenefitViewMode')?.value"
                                [ngStyle]="{'border': manService.isDisabled ? '1px solid gray' : '1px solid #0092cb', 'background-color': 'white'}"
                                [disabled]="manService.isDisabled"
                                (click)="[editRule(accrualPolicy, child, ruleIndex), manService.dropDownOpen(child)]">
                                <i [ngStyle]="{'color': manService.isDisabled ? 'gray' : '#0092cb'}"
                                  class="fas fa-pencil"></i></button>
                              <ng-container *ngIf="!child.get('isBenefitViewMode')?.value">
                                <div [ngClass]="{'show': manService.isShow}"
                                  id="myDropdown" class="dropdown-content">
                                  <button (click)="[manService.dropDownClose(child), clearAll()]"><i
                                      class="fas fa-times"></i></button>
                                      <button (click)="[addBenefit(accrualPolicy, child, ruleIndex), newBenefitDropdown()]"><i
                                      class="fas fa-plus"></i></button>
                                  <button (click)="deleteBenefit(accrualPolicy, child)"
                                    *ngIf="isDeleteAccrualsRolePermission"><i class="fas fa-trash"></i></button>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                        <div class="benefit-container">
                          <div class="benefit-container-child-1">
                            <div *ngIf="child.get('accrualCalcType')?.value == 1  ? true : false"
                              ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div>
                                <label>Accrual Based on</label>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(11)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 11 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <select class="slx-accrued-input" formControlName="accruedOnPeriod">
                                    <option *ngFor="let item of accDropDown.accruedPeriod;" value="{{item.name}}">
                                      {{item.name}}
                                    </option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('accruedOnPeriod')?.value}}</span>
                                </ng-container>
                                <ng-container *ngIf="child.get('accruedOnPeriod')?.value == 'Weekly' || child.get('accrualCalcType')?.value == 2 ? false : true">
                                  <label>Basis Hours</label>
                                  <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                    && accrualPolicy.get('isPolicyViewMode')?.value
                                    || accrualPolicy.get('isNewPolicy')?.value">
                                    <slx-kendo-number type="number" min="1" max="999" class="slx-prob-input"
                                      formControlName="maxBasisHours" [decimals]="2" name="maxBasisHours">
                                    </slx-kendo-number>
                                    <p class="slx-error" style="font-size: 10px;padding: 5px; color: #c32813;text-align: right;margin-right: 84px;"
                                      *ngIf="child.get('maxBasisHours').errors">
                                      Basis Hours should be 1 to 999.00
                                    </p>
                                  </ng-container>
                                  <ng-container
                                    *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                    <span class="slx-benefit-view-content"
                                      *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('maxBasisHours')?.value}}</span>
                                  </ng-container>
                                </ng-container>
                              </div>
                              <div *ngIf="child.get('accruedOnPeriod')?.value == 'Weekly' ? false : true">
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(2)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info basis-hour-active"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 2 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div *ngIf="child.get('accrualCalcType')?.value == 1  ? true : false" ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div *ngIf="child.get('accruedOnPeriod')?.value == 'Weekly' ? true : false">
                                <label>Minimum Hours to Qualify</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <slx-kendo-number type="number" min="0" max="999" class="slx-accrued-input"
                                    formControlName="minimumRequiredHours">
                                  </slx-kendo-number>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('minimumRequiredHours')?.value}}</span>
                                </ng-container>
                              </div>
                              <div *ngIf="child.get('accruedOnPeriod')?.value == 'Weekly' ? true : false">
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(3)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 3 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div>
                                <label>Probationary Period is</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <slx-kendo-number type="number" min="0" max="999"
                                    class="slx-prob-input slx-wide slx-input-number" formControlName="probationValue"
                                    [required]="false" name="probationValue">
                                  </slx-kendo-number>
                                  <select class="slx-prob-drop-down" formControlName="probationType">
                                    <option *ngFor="let item of accDropDown.probationPeriod;" value="{{item.name}}">
                                      {{item.name}}
                                    </option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('probationValue')?.value}}
                                    {{child.get('probationType')?.value}}</span>
                                </ng-container>
                              </div>
                              <div>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(4)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 4 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div>
                                <label>Deferral Period is</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <slx-kendo-number type="number" min="0"
                                    class="slx-prob-input slx-wide slx-input-number"
                                    formControlName="deferralPeriodValue"
                                    [required]="child.get('deferralPeriodType').value ? true : false"
                                    name="deferralPeriodValue">
                                  </slx-kendo-number>
                                  <select class="slx-prob-drop-down" formControlName="deferralPeriodType">
                                    <option *ngFor="let item of accDropDown.deferralPeriod;" value="{{item.name}}">
                                      {{item.name}}
                                    </option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('deferralPeriodValue')?.value}}
                                    {{child.get('deferralPeriodType')?.value}}</span>
                                </ng-container>
                              </div>
                              <div>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(5)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 5 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div class="slx-anchor-date-input">
                                <label>Anchor Date is based on Employees</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <select class="slx-anchor-option" formControlName="anchorDateSource">
                                    <option *ngFor="let item of accDropDown.anchorDate;" value="{{item.name}}">
                                      {{item.name}}
                                    </option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content slx-anchor-read-text"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('anchorDateSource')?.value}}</span>
                                </ng-container>
                              </div>
                              <div>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(6)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 6 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div>
                                <label>Annual Max Year is based on</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <select class="slx-annual-max-drop-down" formControlName="maxAnnualEarnedType">
                                    <option *ngFor="let item of accDropDown.annualMax;" value="{{item.name}}">
                                      {{item.name}}</option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('maxAnnualEarnedType')?.value}}</span>
                                </ng-container>
                              </div>
                              <div>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(7)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 7 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div>
                                <label>CarryOver</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <select class="slx-carryOver-drop-down" formControlName="carryover">
                                    <option *ngFor="let item of accDropDown.carryOver;" value="{{item.name}}">
                                      {{item.name}}</option>
                                  </select>
                                  <span *ngIf="child.get('carryover')?.value == 'Other' ? true : false">
                                    <input class="slx-prob-input" type="text" (input)="carryoverDayChange($event.target.value, child.get('carryoverDay'))" formControlName="carryoverDay" />
                                    <span class="slx-error" errorMessage *ngIf="child.get('carryoverDay').errors" >
                                     Please enter date without space, example: Jan01.
                                    </span>
                                  </span>
                                  <select class="slx-annual-max-drop-down" formControlName="carryoverType">
                                    <option *ngFor="let item of accDropDown.carryOverUpTo;" value="{{item.name}}">
                                      {{item.name}}</option>
                                  </select>
                                  <ng-container *ngIf="child.get('carryoverType')?.value == 'Value of' ? true : false">
                                    <slx-kendo-number type="number" min="0" max="999" class="slx-prob-input"
                                      formControlName="carryoverValue">
                                    </slx-kendo-number>
                                  </ng-container>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('carryover')?.value}}
                                    <span *ngIf="child.get('carryover')?.value == 'Other' ? true : false">
                                      {{child.get('carryoverDay')?.value}}
                                    </span>
                                    {{child.get('carryoverType')?.value}} {{child.get('carryoverValue')?.value}}</span>
                                </ng-container>
                              </div>
                              <div>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(8)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info"
                                    *ngIf="manService.isViewed == 8 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div>
                                <label>All other hours move to</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <select class="slx-all-other-hours-option"
                                    formControlName="carryoverToAccrualTypeName">
                                    <option *ngFor="let item of allOtherHoursDropDown;" value="{{item}}">
                                      {{item}}
                                    </option>
                                  </select>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('carryoverToAccrualTypeName')?.value}}</span>
                                </ng-container>
                              </div>
                              <div>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(9)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info all-other-hour-active"
                                    *ngIf="manService.isViewed == 9 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                            <div ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                              <div>
                                <label>Max Rollover Hours are</label>
                                <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                  && accrualPolicy.get('isPolicyViewMode')?.value
                                  || accrualPolicy.get('isNewPolicy')?.value">
                                  <slx-kendo-number type="number" min="0" max="999" [decimals]="5"
                                    class="slx-prob-input" formControlName="rollOverMax">
                                  </slx-kendo-number>
                                </ng-container>
                                <ng-container
                                  *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                  <span class="slx-benefit-view-content"
                                    *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{child.get('rollOverMax')?.value}}</span>
                                </ng-container>
                              </div>
                              <div>
                                <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(10)">
                                  <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                    class="box__icon fas fa-info"></i>
                                  <div class="slx-info max-roll-over-active"
                                    *ngIf="manService.isViewed == 10 && manService.isActive ? true : false">
                                    <span [innerHTML]="manService.title | bold"></span>
                                    <span>{{manService.text}}</span><br><br>
                                    <span>{{manService.text1}}</span>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="benefit-container-child-2" formArrayName="seniorityRules">
                            <table class="slx-sr-table">
                              <thead>
                                <tr class="slx-header-col">
                                  <th>From:</th>
                                  <th>To:</th>
                                  <th>Rate:</th>
                                  <th *ngIf="[1, 3].includes(child.get('accrualCalcType')?.value)">Per</th>
                                  <th *ngIf="child.get('accrualCalcType')?.value == 2 ? true : false">Dates:</th>
                                  <th nowrap>Max Year:</th>
                                  <th nowrap>Max Balance:</th>
                                  <th nowrap>Max Carryover:</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="let seniorityRules of seniorityRule(accrualPolicy, ruleIndex); let seniorityRuleIndex=index;"
                                  formGroupName="{{seniorityRuleIndex}}">
                                  <td nowrap>
                                    <span class="slx-input-text-area" *ngIf="!child.get('isBenefitViewMode')?.value 
                                      && accrualPolicy.get('isPolicyViewMode')?.value
                                      || accrualPolicy.get('isNewPolicy')?.value">
                                      <slx-kendo-number class="slx-sr-from-input"
                                        [isInputActive]="!manService.isInputActive(seniorityRules.get('periodFromValue')?.value)"
                                        (input)="manService.onInputChange($event.target.value, accrualPolicy, ruleIndex, seniorityRuleIndex)"
                                        formControlName="periodFromValue" [required]="false" min="0"
                                        [decimals]="2"
                                        name="periodFromValue">
                                      </slx-kendo-number>
                                      <select class="slx-sr-from-option" formControlName="periodFromType">
                                        <option *ngFor="let item of accDropDown.SeniorityPeriod;" value="{{item.name}}">
                                          {{item.name}}
                                        </option>
                                      </select>
                                    </span>
                                    <span class="slx-error" errorMessage *ngIf="seniorityRules.get('periodFromValue').dirty 
                                              && seniorityRules.get('periodFromValue').errors 
                                              && seniorityRules.get('periodFromValue').errors.min">
                                      Value should be Greater than or equal to 0
                                    </span>
                                    <span class="slx-error" errorMessage *ngIf="seniorityRules.get('periodFromValue').dirty 
                                              && seniorityRules.get('periodFromValue').errors 
                                              && seniorityRules.get('periodFromValue').errors.greater">
                                      {{seniorityRules.get('periodFromValue').errors.greater}}
                                    </span>
                                    <div *ngIf="seniorityRules?.errors">{{seniorityRules?.errors.error}}</div>
                                    <div class="slx-view-level" *ngIf="child.get('isBenefitViewMode')?.value 
                                      || !accrualPolicy.get('isPolicyViewMode')?.value">
                                      <div *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        <span class="slx-view-benefit">{{seniorityRules.get('periodFromValue')?.value}}
                                        </span>
                                        <span>{{seniorityRules.get('periodFromType')?.value}}</span>
                                      </div>
                                    </div>

                                    <br />

                                    <div *ngIf="child.get('accrualCalcType')?.value == 3  ? true : false"
                                      ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                                      <div>
                                        <label class="pl-0">Accrual on</label>
                                        <span class="mr-5" *ngIf="!child.get('isBenefitViewMode')?.value 
                                        || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView(11, seniorityRuleIndex)">
                                          <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                            class="box__icon fas fa-info"></i>
                                          <div class='slx-info hide-overflow' [ngClass]="seniorityRuleIndex == 0 ? 'hide-overflow-first-child' : 'hide-overflow-not-first-child'"
                                            *ngIf="manService.isViewed == 11 && manService.isViewedSrIndex == seniorityRuleIndex && manService.isActive ? true : false">
                                            <span [innerHTML]="manService.title | bold"></span>
                                            <span>{{manService.text}}</span><br><br>
                                            <span>{{manService.text1}}</span>
                                          </div>
                                        </span>

                                        <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                          && accrualPolicy.get('isPolicyViewMode')?.value
                                          || accrualPolicy.get('isNewPolicy')?.value">
                                          <select class="slx-accrued-input mr-5" formControlName="accruedOn_Seniority">
                                            <option *ngFor="let item of accDropDown.accruedPeriod;" value="{{item.name}}">
                                              {{item.name}}
                                            </option>
                                          </select>
                                        </ng-container>
                                        <ng-container
                                          *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                          <span class="slx-benefit-view-content"
                                            *ngIf="!accrualPolicy.get('isNewPolicy')?.value">{{seniorityRules.get('accruedOn_Seniority')?.value}}</span>
                                        </ng-container>
                                      </div>
                                    </div>
                                  </td>
                                  <td nowrap>
                                    <span class="slx-input-text-area" *ngIf="!child.get('isBenefitViewMode')?.value 
                                      && accrualPolicy.get('isPolicyViewMode')?.value
                                      || accrualPolicy.get('isNewPolicy')?.value">
                                      <slx-kendo-number
                                        [isInputActive]="!manService.isInputActive(seniorityRules.get('periodToValue')?.value)"
                                        class="slx-sr-to-input" formControlName="periodToValue"
                                        [required]="false" min="1" name="periodToValue">
                                      </slx-kendo-number>
                                      <select class="slx-sr-from-option" formControlName="periodToType">
                                        <option *ngFor="let item of accDropDown.SeniorityPeriod;" value="{{item.name}}">
                                          {{item.name}}
                                        </option>
                                      </select>
                                    </span>
                                    <span class="slx-error" errorMessage *ngIf="seniorityRules.get('periodToValue').dirty 
                                              && seniorityRules.get('periodToValue').errors 
                                              && seniorityRules.get('periodToValue').errors.min">
                                      Value should be Greater than or equal to 0
                                    </span>
                                    <div class="slx-view-level"
                                      *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                      <div *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        <span
                                          class="slx-view-benefit">{{seniorityRules.get('periodToValue')?.value}}</span>
                                        <span>{{seniorityRules.get('periodToType')?.value}}</span>
                                      </div>
                                    </div>

                                    <br />

                                    <div *ngIf="child.get('accrualCalcType')?.value == 3  ? true : false"
                                      ngClass="{{child.get('isBenefitViewMode')?.value}} ? slx_benefit_view : slx_benefit">
                                      <div>
                                        <ng-container>
                                          <label class="pl-0" *ngIf="seniorityRules.get('accruedOn_Seniority')?.value != 'Weekly'">Basis Hours</label>
                                          <label class="pl-0" *ngIf="seniorityRules.get('accruedOn_Seniority')?.value == 'Weekly'">Minimum Hours to Qualify</label>
                                          <ng-container *ngIf="!child.get('isBenefitViewMode')?.value 
                                            && accrualPolicy.get('isPolicyViewMode')?.value
                                            || accrualPolicy.get('isNewPolicy')?.value">
                                            <slx-kendo-number type="number" [min]="(seniorityRules.get('accruedOn_Seniority')?.value != 'Weekly') ?1:0" max="999" class="slx-sr-to-input"
                                                formControlName="basisHour_Seniority" [decimals]="2" name="basisHour_Seniority">
                                            </slx-kendo-number>
                                          </ng-container>

                                          <div class="slx-view-level"
                                            *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                            <div *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                              <span
                                                class="slx-view-benefit">{{seniorityRules.get('basisHour_Seniority')?.value}}</span>
                                            </div>
                                          </div>
                                          &nbsp;
                                          <span *ngIf="!child.get('isBenefitViewMode')?.value 
                                          || accrualPolicy.get('isNewPolicy')?.value" (click)="manService.isView((seniorityRules.get('accruedOn_Seniority')?.value == 'Weekly' ? 3 : 2), seniorityRuleIndex)">
                                            <i aria-hidden="true" *ngIf="!child.get('isBenefitViewMode')?.value"
                                              class="box__icon fas fa-info basis-hour-active"></i>
                                            <div class="slx-info hide-overflow" [ngClass]="seniorityRuleIndex == 0 ? 'hide-overflow-first-child' : 'hide-overflow-not-first-child'"
                                              *ngIf="manService.isViewed == (seniorityRules.get('accruedOn_Seniority')?.value == 'Weekly' ? 3 : 2) && manService.isViewedSrIndex == seniorityRuleIndex && manService.isActive ? true : false">
                                              <span [innerHTML]="manService.title | bold"></span>
                                              <span>{{manService.text}}</span><br><br>
                                              <span>{{manService.text1}}</span>
                                            </div>
                                          </span>
                                        </ng-container>
                                      </div>
                                    </div>
                                  </td>
                                  <td nowrap>
                                    <span class="slx-input-text-area slx-input-acc-rt" *ngIf="!child.get('isBenefitViewMode')?.value 
                                      && accrualPolicy.get('isPolicyViewMode')?.value
                                      || accrualPolicy.get('isNewPolicy')?.value">
                                      <slx-kendo-number class="slx-sr-input" formControlName="accrualRate" min="0"
                                        [isInputActive]="!manService.isInputActive(seniorityRules.get('accrualRate')?.value)"
                                        [decimals]="5" [required]="seniorityRules.get('periodFromType').value && 
                                                    seniorityRules.get('periodFromValue').value && 
                                                    seniorityRules.get('periodToValue').value && 
                                                    seniorityRules.get('periodToType').value ? true : false"
                                        name="accrualRate">
                                      </slx-kendo-number>
                                      <ng-container *ngIf="child.get('accrualCalcType')?.value == 2 ? false : true">
                                        <select class="slx-sr-rate-option" formControlName="rateUnit">
                                          <option *ngFor="let item of accDropDown.SeniorityRate;" value="{{item.name}}">
                                            {{item.name}}
                                          </option>
                                        </select>
                                      </ng-container>
                                    </span>
                                    <span class="slx-error" errorMessage *ngIf="seniorityRules.get('accrualRate').dirty 
                                              && seniorityRules.get('accrualRate').errors 
                                              && seniorityRules.get('accrualRate').errors.min">
                                      Value should be Greater than or equal to 0
                                    </span>
                                    <div class="slx-view-level"
                                      *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                      <div *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        <span class="slx-view-benefit">{{seniorityRules.get('accrualRate')?.value |
                                          slxDecimal24Switch : true}}</span>
                                        <span>{{seniorityRules.get('rateUnit')?.value}}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td nowrap>
                                    <span class="slx-input-text-area" *ngIf="!child.get('isBenefitViewMode')?.value 
                                      && accrualPolicy.get('isPolicyViewMode')?.value
                                      || accrualPolicy.get('isNewPolicy')?.value">
                                      <select *ngIf="[1, 3].includes(child.get('accrualCalcType')?.value) ? true : false"
                                        class="slx-sr-from-option" formControlName="basisUnit">
                                        <option *ngFor="let item of accDropDown.SeniorityPer;" value="{{item.name}}">
                                          {{item.name}}
                                        </option>
                                      </select>
                                      <input class="slx-anchor-option"
                                        *ngIf="child.get('accrualCalcType')?.value == 2 ? true : false" type="text"
                                        [required]="false"
                                        [ngStyle]="{'border': seniorityRules.get('accruedOnDates')?.value == '' ? '1px solid lightcoral' : '1px solid gray'}"
                                        formControlName="accruedOnDates" placeholder="accruedOnDates">
                                    </span>
                                    <div class="slx-view-level"
                                      *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value && [1, 3].includes(child.get('accrualCalcType')?.value)">
                                      <div *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        <span
                                          *ngIf="[1, 3].includes(child.get('accrualCalcType')?.value)">{{seniorityRules.get('basisUnit')?.value}}</span>
                                      </div>
                                    </div>
                                    <div class="slx-view-level"
                                      *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value && child.get('accrualCalcType')?.value == 2">
                                      <div *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        <span
                                          *ngIf="child.get('accrualCalcType')?.value == 2">{{seniorityRules.get('accruedOnDates')?.value}}</span>
                                      </div>
                                    </div>

                                  </td>
                                  <td nowrap>
                                    <span class="slx-input-text-area" *ngIf="!child.get('isBenefitViewMode')?.value 
                                      && accrualPolicy.get('isPolicyViewMode')?.value
                                      || accrualPolicy.get('isNewPolicy')?.value">
                                      <slx-kendo-number class="slx-sr-input1" formControlName="maxAccruedAnnual" min="0"
                                        [decimals]="5" name="maxAccruedAnnual">
                                      </slx-kendo-number>
                                    </span>
                                    <span class="slx-error" errorMessage *ngIf="seniorityRules.get('maxAccruedAnnual').dirty 
                                              && seniorityRules.get('maxAccruedAnnual').errors 
                                              && seniorityRules.get('maxAccruedAnnual').errors.min">
                                      Value should be Greater than or equal to 0
                                    </span>
                                    <div class="slx-view-level"
                                      *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                      <div class="slx-view-benefit" *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        {{seniorityRules.get('maxAccruedAnnual')?.value}}
                                      </div>
                                    </div>
                                  </td>
                                  <td nowrap>
                                    <span class="slx-input-text-area" *ngIf="!child.get('isBenefitViewMode')?.value 
                                      && accrualPolicy.get('isPolicyViewMode')?.value
                                      || accrualPolicy.get('isNewPolicy')?.value">
                                      <slx-kendo-number class="slx-sr-input1" formControlName="maxAccruedBalance"
                                        min="0" [decimals]="5" name="maxAccruedBalance">
                                      </slx-kendo-number>
                                    </span>
                                    <span class="slx-error" errorMessage *ngIf="seniorityRules.get('maxAccruedBalance').dirty 
                                              && seniorityRules.get('maxAccruedBalance').errors 
                                              && seniorityRules.get('maxAccruedBalance').errors.min">
                                      Value should be Greater than or equal to 0
                                    </span>
                                    <div class="slx-view-level"
                                      *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                      <div class="slx-view-benefit" *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        {{seniorityRules.get('maxAccruedBalance')?.value}}
                                      </div>
                                    </div>
                                  </td>
                                  <td nowrap>
                                    <span class="slx-input-text-area" *ngIf="!child.get('isBenefitViewMode')?.value 
                                      && accrualPolicy.get('isPolicyViewMode')?.value
                                      || accrualPolicy.get('isNewPolicy')?.value">
                                      <slx-kendo-number class="slx-sr-input1" formControlName="carryoverValue" min="0"
                                        [decimals]="5" name="carryoverValue">
                                      </slx-kendo-number>
                                    </span>
                                    <span class="slx-error" errorMessage *ngIf="seniorityRules.get('carryoverValue').dirty 
                                              && seniorityRules.get('carryoverValue').errors 
                                              && seniorityRules.get('carryoverValue').errors.min">
                                      Value should be Greater than or equal to 0
                                    </span>
                                    <div class="slx-view-level"
                                      *ngIf="child.get('isBenefitViewMode')?.value || !accrualPolicy.get('isPolicyViewMode')?.value">
                                      <div class="slx-view-benefit" *ngIf="!accrualPolicy.get('isNewPolicy')?.value">
                                        {{seniorityRules.get('carryoverValue')?.value}}
                                      </div>
                                    </div>
                                  </td>
                                  <td nowrap *ngIf="!child.get('isBenefitViewMode')?.value 
                                    && accrualPolicy.get('isPolicyViewMode')?.value
                                    || accrualPolicy.get('isNewPolicy')?.value">
                                    <ng-container *ngIf="manService.getLastIndex(child, seniorityRuleIndex)">
                                      <button
                                        [disabled]="seniorityRule(accrualPolicy, ruleIndex).length == 14 || seniorityRules.invalid ? true : false"
                                        class="slx-button"
                                        (click)="addseniorityRule(accrualPolicy, ruleIndex, seniorityRuleIndex)">
                                        <i [ngClass]="seniorityRule(accrualPolicy, ruleIndex).length == 14 || seniorityRules.invalid ? 'slx-add-disable' : 'slx-add'"
                                          aria-hidden="true" class="fas fa-plus slx-add"></i>
                                      </button>
                                      <button [disabled]="seniorityRuleIndex == 0 ? true : false" class="slx-button"
                                        (click)="removeSeniorityRule(accrualPolicy, ruleIndex, seniorityRuleIndex)">
                                        <i [ngClass]="seniorityRuleIndex == 0 ? 'slx-minus-disable' : 'slx-minus'"
                                          aria-hidden="true" class="fas fa-minus slx-minus"></i>
                                      </button>
                                    </ng-container>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="slx-benefit-footer-content">
                          <button class="theme-iconed-accent-button pull-left" *ngIf="!child.get('isBenefitViewMode')?.value 
                            && accrualPolicy.get('isPolicyViewMode')?.value
                            || accrualPolicy.get('isNewPolicy')?.value"
                            (click)="cancelRule(accrualPolicy, child, ruleIndex)">Cancel</button>
                          <button class="theme-iconed-accent-button pull-left"
                            [disabled]="policyForm.invalid ? true : false" *ngIf="!child.get('isBenefitViewMode')?.value 
                            && accrualPolicy.get('isPolicyViewMode')?.value
                            || accrualPolicy.get('isNewPolicy')?.value" (click)="saveRuleChanges(accrualPolicy, child)"
                            type="submit">Save</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </slx-spinner>
            </ng-container>
          </slx-collapsible-section>
        </div>
      </div>
    </form>
  </div>
</slx-spinner>