export class EmployeeSearchResult {
    public badgeId: string;
    public dateTerminated: Date;
    public status: string;
    public employee: Employee;
    public organization: Organization;
    public department: Department;
    public position: Position;
}

export class Employee {
    public id: number;
    public name: string;
    public dateHired: Date;
    public payrollNumber: string;
}

export class Organization {
    public id: number;
    public name: string;
    public orgLevelId: number;
}

export class Department {
    public id: number;
    public name: string;
    public orgLevelId: number;
}

export class Position {
    public id: number;
    public name: string;
    public orgLevelId: number;
    public group: string;
}


export interface IEmployeeSearchResult {
    badgeId: string;
    dateTerminated: Date;
    status: string;
    employee: IEmployeeDefinition;
    organization: IOrganizationDefinition;
    department: IDepartmentDefinition;
    position: IPositionDefinition;
}

export interface IEmployeeDefinition {
    id: number;
    name: string;
    dateHired: Date;
    payrollNumber: string;
}

export interface IOrganizationDefinition {
    id: number;
    name: string;
    orgLevelId: number;
}

export interface IDepartmentDefinition {
    id: number;
    name: string;
    orgLevelId: number;
}

export interface IPositionDefinition {
    id: number;
    name: string;
    orgLevelId: number;
    group: string;
}
