<slx-spinner class ="request-agency-spinner" [show]="state.isLoading || isLoading ||onLoad ">
<div *ngIf ="!onLoad" >
  <div class="row shift-request-details " >
    <div class="col-xs-6">
      <label class="slx-label select-shift">Select a Shift for: {{dateOn | amDateFormat: appConfig.dateFormat
        }}</label>
    </div>
    <div class="col-xs-6 " *ngIf="isShiftSelected">
      <label class="shift-requests-header" >Add shift request details </label>
    </div>
  </div>
  <div class="row header">
    <div class="col-xs-6"> <!-- shift details -->   
      <slx-shift-request-popup [requestedDate]="dateOn" [orgLevelId]="orgLevelId"
        (selectedShiftDetails)="getSelectedShift($event)"></slx-shift-request-popup>
    </div>
    <div class="col-xs-6"> <!-- Select an Agency: -->
    
      <slx-shift-request-details [isShiftSelected]="isShiftSelected" [agencyData] ="agency" [selectedShiftDetails]="selectedShiftDetails"
        (agencyStaffRequest)="requestedAgencyDetails($event)" [scrollContentSize] = "scrollCountentSize" ></slx-shift-request-details>
    </div>
  </div>
  <div class=" header slx-content-toolbar-indents requestAgency-toolbar"></div>
  <div class="row request-details">
    <div class="col-12  agency-staff-requested"> <!--agency Request staff-->
      <label class=" shift-requests" >Agency staff requested:</label>
      <span *ngIf="isMultiSelectedShift">
        <button type="button" (click)="updateMultipleShifts()"   class="btn multiple-shift-button">
        Update Multiple Shifts
      </button>
    </span>
      <div  *ngIf="isRequestAgencyStaff">
        <kendo-grid #grid 
          class="slx-full-height slx-blue-grid"
          [data]="gridState.view"
          sortable="true"
          [sort]="gridState.state.sort"
          [filter]="states.filter"
          filterable="'menu'"  
          (selectionChange)="gridSelectedShift($event)"
          [selectable]="{  mode: 'multiple' }"
          (dataStateChange)="dataStateChange($event)" >
          
        <kendo-grid-column field="ShiftName" title="Shift" width="140">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.ShiftName}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="Hours" title="Ideal Hours" filter="numeric" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.Hours | number : '1.2-2'}}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="UnitName" title="Unit" width="140">        
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.UnitName}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="JobDescription" title="Position" width="140">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem?.JobDescription}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="instanctAccept" title="Instant Accept"filterable = "false" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{getShiftAttributes(dataItem['selectedAgency'][0].attributes, 'instantAccept')}}
          </ng-template>       
         
        </kendo-grid-column>
        <kendo-grid-column field="infectious" title="Infectious"filterable = "false" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{getShiftAttributes(dataItem['selectedAgency'][0].attributes, 'infectious')}}
          </ng-template>
        
        </kendo-grid-column>      
        <kendo-grid-column field="premiumPay" title="Premium Pay" filterable = "false" width="100" >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span > {{getShiftAttributes(dataItem['selectedAgency'][0].attributes, 'premiumPay')}}
            </span>
            
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="incentivePay" title="IncentivePay" filterable = "false" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{getShiftAttributes(dataItem['selectedAgency'][0].attributes, 'incentivePay')}}
          </ng-template>

        </kendo-grid-column>
        <kendo-grid-column field="agency" title="Agency" filterable = "false" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem['selectedAgency'].length+' agency'}} 
          </ng-template>
         
        </kendo-grid-column>
        <kendo-grid-column field="Action" title="Action" width="100" filterable = "false">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <button [disabled] ="isMultiSelectedShift" (click) = "cancelHandler(dataItem)" class="slx-button slx-grey slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
              <i class="fas fa-ban" aria-hidden="true"></i> </button> 
              <button [disabled] ="isMultiSelectedShift"  type="button" (click) = "editHandler(dataItem)"  class="slx-button slx-with-icon command-button">
                <i class="fas fa-pencil-alt slx-button__icon"  aria-hidden="true"></i>
                </button>
          </ng-template>
        
        </kendo-grid-column>
      <ng-template kendoGridDetailTemplate let-dataItem>
        <slx-agency-details [agencyDetails] ="selectedAgencyDetails(dataItem)"></slx-agency-details>
        </ng-template> 
        </kendo-grid>
      </div>
    </div>
  </div>
  <div class="row no-shift-selected header slx-content-toolbar-indents requestAgency-toolbar" *ngIf="!isRequestAgencyStaff"> <!-- not selected shifts -->
    <label class="no-selected-shift"> No staff has been requested</label>
  </div>
  <div class="header slx-content-toolbar-indents requestAgency-toolbar"  *ngIf="isRequestAgencyStaff"></div>
  <div class=" row pull-right ras-button-area" *ngIf="isRequestAgencyStaff">
   <div class="col-sm-2">
    <button type="button" (click)="cancelRequestAgencyStaff()" class="btn btn-default red cancel">
      Cancel
    </button>
  </div>
  <div class="col-sm-6">
    <button type="button" [disabled]="isEditMode || isMultiSelectedShift" (click)="senedRequestAgencyStaff()"  class="btn request-agency-staff">
      Send Agency Staff Request
    </button>
   </div>
  </div>
</div>
</slx-spinner>
