<ng-container>
  <div *ngIf="!!messages && messages.length > 0">
    <div *ngFor="let message of messages">
      <div class="msg-row" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}" (click)="selectMessage(message)" *ngIf="!isExpanded">
        <div class="flex details-container">
          <div class="sent-avtar" >
            <div class="avatar circle">
              <slx-msg-grid-thumbnail-src [employeeId]="message.employees[0].employeeId" [employeeName]="message.employees[0].employeeName" [parent]="'messageGrid'"></slx-msg-grid-thumbnail-src>
            </div>
            <div class="more-employees-avtar flex" *ngIf="message.employees.length > 1">
              <div class="count">
                +{{message.employees.length -1}}
              </div>
            </div>
          </div>
          <div class="slate-message-list-main-container">
            <div class="name-container" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}">
              <div title="{{message.createdByDisplayName}}">{{message.createdByDisplayName}}</div>
            </div>
            <div class="message-details-container">
              <div class="subject" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}" title="{{message.subject}}">{{message.subject}}</div>
              <div class="message" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}" title="{{message.latestMessage.messageContent}}">{{message.latestMessage.messageContent}}</div>
            </div>
            <div class="date-time-container">
              <div class="date">
                {{message.latestMessage.createdDate | convertDate}}
              </div>
              <div class="icon">
                <em class="fa fa-users" *ngIf="message.employees.length > 1"></em>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="msg-row collapsed" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}" (click)="selectMessage(message)" *ngIf="isExpanded">
        <div class="name-container" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}">
          <div title="{{message.createdByDisplayName}}" class="collapsed-name">{{message.createdByDisplayName}}</div>
        </div>
        <div class="message-details-container">
          <div class="subject collapsed-subject" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}" title="{{message.subject}}">{{message.subject}}</div>
          <div class="message collapsed-message" [ngClass]="{'active': !!selectedMessage && selectedMessage.groupId == message.groupId}" title="{{message.latestMessage.messageContent}}">{{message.latestMessage.messageContent}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
