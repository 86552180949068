import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { QuickEditOvelapDecision, QuickEditOvelapDecisionRequest,  QuickEditOvelap } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-master-schedule-quick-edit-prompt-dialog',
  templateUrl: 'master-schedule-quick-edit-prompt-dialog.component.html',
  styleUrls: ['master-schedule-quick-edit-prompt-dialog.component.scss']
})
export class MasterScheduleQuickEditPromptDialogComponent implements IDialog {


  public dialogResult: boolean;
  public decision: QuickEditOvelapDecision;
  public appConfig: IApplicationConfig;


  public static openDialog(req: QuickEditOvelapDecisionRequest, modalService: ModalService, callback: (result: QuickEditOvelapDecision) => void): MasterScheduleQuickEditPromptDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 300;
    dialogOptions.width = 560;
    dialogOptions.fullHeightOnMobile = true;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: QuickEditOvelapDecisionRequest,
        useValue: req
      }
    ];

    let dialog: MasterScheduleQuickEditPromptDialogComponent = modalService.globalAnchor
      .openDialog(MasterScheduleQuickEditPromptDialogComponent,
        `Please select an option`,
        dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
            callback(dialog.decision);
        });
    return dialog;
  }

  constructor(private options: DialogOptions,
    private modalService: ModalService, public request: QuickEditOvelapDecisionRequest
  ) {
    this.appConfig = appConfig;
  }

  public onReplace(): void {
    this.dialogResult = true;
    this.decision = QuickEditOvelapDecision.Replace;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onOverride(): void {
    this.dialogResult = true;
    this.decision = QuickEditOvelapDecision.Override;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onAppend(): void {
    this.dialogResult = true;
    this.decision = QuickEditOvelapDecision.Append;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.decision = QuickEditOvelapDecision.Cancel;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
