import { ITimecardsEmployee, TimecardsEmployee } from './index';
import { IPayRuleDefinition, PayRuleDefinition, IPayCycle, PayCycle } from '../../../organization/models/index';

export interface ITimecardsSummary {
 payCycle: IPayCycle;
 records: ITimecardsEmployee[];
 rulesDefinitions: IPayRuleDefinition[];
 infoDisplay: string;

 canApprove: boolean;
 canUnapprove: boolean;
 canExport: boolean;
 canManageExports: boolean;
 hideRates: boolean;
 canExportToPDF: boolean;
 canExportToExcel: boolean;
 canRecalculate: boolean;
 canViewSummary: boolean;
 isLocked: boolean;
 shiftDiffBasedOnTime: boolean;
 hideCostCenter: boolean;
 facilityHasPayUnits: boolean;
 hasMassAssignPayUnitsRights: boolean;
 hideEmptyTimeCards: boolean;
}

export class TimecardsSummary {
 public payCycle: PayCycle;
 public records: TimecardsEmployee[];
 public rulesDefinitions: PayRuleDefinition[];
 public usedRulesDefinitions: PayRuleDefinition[];
 public infoDisplay: string;
 public canApprove: boolean;
 public canUnapprove: boolean;
 public canExport: boolean;
 public canManageExports: boolean;
 public hideRates: boolean;
 public canExportToPDF: boolean;
 public canExportToExcel: boolean;
 public canRecalculate: boolean;
 public canViewSummary: boolean;
 public isPayCycleSubmitted: boolean;
 public isLocked: boolean;
 public shiftDiffBasedOnTime: boolean;
 public hideCostCenter: boolean;
 public facilityHasPayUnits: boolean;
 public hasMassAssignPayUnitsRights: boolean;
 public hideEmptyTimeCards: boolean;
}
