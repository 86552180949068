import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsLicense, EmployeeSectionsLicenses } from '../../../models/index';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { BudgetedPosition } from '../../../../../organization/models/lookup/budgeted-position';
import * as _ from 'lodash';
import { FieldAccessType } from '../../../../../core/models/field/field-access-type';
import { commonConfig } from '../../../../../common/common.config';
import { ModalService } from '../../../../../common/index';
import { LicenseType } from '../../../../../../app/organization';
import { Observable, Subscription } from 'rxjs-compat';
import { mutableSelect, unsubscribe } from '../../../../../../app/core/decorators';
var EmployeeSectionsLicensesComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsLicensesComponent, _super);
    function EmployeeSectionsLicensesComponent(employeeSectionsPersonalApiService, lookupApiService, decorator, ngZone, modalService, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.changeDetector = changeDetector;
        _this.maximumLicenses = 10;
        Assert.isNotNull(employeeSectionsPersonalApiService, 'employeeSectionsPersonalApiService');
        _this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
        _this.lookupApiService = lookupApiService;
        _this.orgLevelSubscription = _this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
        });
        return _this;
    }
    Object.defineProperty(EmployeeSectionsLicensesComponent.prototype, "employeeSectionsLicenses", {
        set: function (licenses) {
            this.licenses = licenses;
            if (this.licenses && this.licenses.employeeSectionsLicenses) {
                _.each(this.licenses.employeeSectionsLicenses, function (l, index) { return l.indexSeq = index; });
            }
            this.actualLicenses = this.calcActualLicenses();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsLicensesComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsLicensesComponent.prototype.getSubsectionModel = function () {
        return this.licenses;
    };
    EmployeeSectionsLicensesComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.commonConfig = commonConfig;
        this.loadLookups();
    };
    EmployeeSectionsLicensesComponent.prototype.removeLicense = function (lic) {
        lic.removed = true;
        _.each(this.licenses.employeeSectionsLicenses, function (l, index) { return l.indexSeq = index; });
        this.actualLicenses = [];
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.actualLicenses = this.calcActualLicenses();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.form.updateValueAndValidity();
    };
    EmployeeSectionsLicensesComponent.prototype.addNewLicense = function () {
        var lic = new EmployeeSectionsLicense();
        lic.licenseNumber = this.licenses.licenseNumberMetaData.createBasedOn();
        lic.licenseNumber.securityAttribute.access = FieldAccessType.full;
        lic.expirationDate = this.licenses.expirationDateMetaData.createBasedOn();
        lic.expirationDate.fieldValue = '';
        lic.expirationDate.securityAttribute.access = FieldAccessType.full;
        lic.budgetedPosition = this.licenses.budgetedPositionMetaData.createBasedOn();
        lic.budgetedPosition.securityAttribute.access = FieldAccessType.full;
        lic.budgetedPosition.fieldValue = new BudgetedPosition();
        lic.licenseType = this.licenses.licenseTypeMetaData.createBasedOn();
        lic.licenseType.securityAttribute.access = FieldAccessType.full;
        lic.licenseType.fieldValue = new LicenseType();
        lic.issueDate = this.licenses.issueDateMetaData.createBasedOn();
        lic.issueDate.fieldValue = '';
        lic.issueDate.securityAttribute.access = FieldAccessType.full;
        this.licenses.employeeSectionsLicenses.push(lic);
        lic.seq = 0;
        _.each(this.licenses.employeeSectionsLicenses, function (l, index) { return l.indexSeq = index; });
        this.actualLicenses = [];
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.actualLicenses = this.calcActualLicenses();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.form.updateValueAndValidity();
        if (this.reachMaximum) {
            this.modalService.globalAnchor.openInfoDialog('Attention', 'You reached maximum of creating licenses');
        }
    };
    EmployeeSectionsLicensesComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.getPersonalLicenses(this.employeeId)
            .then(function (employeeSectionsLicenses) {
            _this.licenses = employeeSectionsLicenses;
            _this.actualLicenses = _this.calcActualLicenses();
            _this.state.isLoaded = true;
            _this.stopProgress();
            _this.state.isEditMode = _this.startEdit;
            _this.startEdit = false;
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsLicensesComponent.prototype.setEditState = function (editState) {
        this.startEdit = editState;
    };
    EmployeeSectionsLicensesComponent.prototype.calcActualLicenses = function () {
        var res = [];
        this.reachMaximum = false;
        if (!this.licenses)
            return res;
        res = _.filter(this.licenses.employeeSectionsLicenses, function (lic) {
            return !lic.removed;
        });
        this.reachMaximum = res.length >= this.maximumLicenses;
        return res;
    };
    EmployeeSectionsLicensesComponent.prototype.checkDuplicateLicenses = function () {
        var _this = this;
        var i = 0;
        var j = 0;
        var hasErrors = false;
        _.forEach(this.actualLicenses, function (lic) {
            lic.hasDuplicateAtSeq = -1;
        });
        _.times(this.actualLicenses.length, function () {
            var lic1 = _this.actualLicenses[i];
            j = i + 1;
            _.times(_this.actualLicenses.length - i - 1, function () {
                var lic2 = _this.actualLicenses[j];
                var exDateEq = moment(lic1.expirationDate.fieldValue).startOf('day').isSame(moment(lic2.expirationDate.fieldValue).startOf('day'));
                var licNumEq = lic1.licenseNumber.fieldValue === lic2.licenseNumber.fieldValue;
                if (exDateEq && licNumEq) {
                    lic2.hasDuplicateAtSeq = i;
                    hasErrors = true;
                }
                j++;
            });
            i++;
        });
        if (hasErrors) {
            this.form.setErrors({ 'duplicate': true });
        }
    };
    EmployeeSectionsLicensesComponent.prototype.loadLookups = function () {
        this.getBudgetedPositionLookupData();
        this.getLicenseTypeLookupData();
    };
    EmployeeSectionsLicensesComponent.prototype.getBudgetedPositionLookupData = function () {
        var _this = this;
        if (this.budgetedPositionLookup)
            return;
        this.lookupApiService.getBudgetedPositions(this.employeeId, 0)
            .then(function (budgetedPositionLookup) {
            _this.budgetedPositionLookup = budgetedPositionLookup;
            _this.budgetedPositionMap = {};
            _.forEach(_this.budgetedPositionLookup, function (pos) {
                _this.budgetedPositionMap[pos.id] = pos;
            });
        });
    };
    EmployeeSectionsLicensesComponent.prototype.getLicenseTypeLookupData = function () {
        var _this = this;
        if (this.licenseTypeLookup)
            return;
        this.lookupApiService.getLicenseType(this.employeeId, 0)
            .then(function (licenseTypeLookup) {
            _this.licenseTypeLookup = licenseTypeLookup;
            _this.licenseTypeLookupMap = {};
            _.forEach(_this.licenseTypeLookup, function (pos) {
                _this.licenseTypeLookupMap[pos.licenseTypeID] = pos;
            });
        });
    };
    EmployeeSectionsLicensesComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.employeeSectionsPersonalApiService.setPersonalLicenses(this.employeeId, this.actualLicenses, this.licenses.fieldsMeta)
            .then(function (status) {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsLicensesComponent.prototype.checkTemporalDirty = function () {
        this.checkDuplicateLicenses();
        return this.metaFieldsTemporalDirtyArrayChecker(this.licenses.employeeSectionsLicenses);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeSectionsLicensesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsLicensesComponent.prototype, "orgLevelSubscription", void 0);
    return EmployeeSectionsLicensesComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsLicensesComponent };
