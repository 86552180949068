/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../login-form/login-form.component.ngfactory";
import * as i3 from "../login-form/login-form.component";
import * as i4 from "../../services/authentication/authentication.service";
import * as i5 from "../../../core/services/logger/logger.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../common/services/technical/version.subscribe.service";
import * as i8 from "../../../core/services/user-activity/user-activity.service";
import * as i9 from "../../../core/services/user-menu-pin/user-menu-pin.service";
import * as i10 from "../../../portal/services/notifications/notifications-api.service";
import * as i11 from "./login-dialog.component";
import * as i12 from "../../../core/services/session/session.service";
import * as i13 from "../../../common/models/dialog-options";
import * as i14 from "../../actions/session.actions";
import * as i15 from "../../../common/services/modal/modal.service";
var styles_LoginDialogComponent = [i0.styles];
var RenderType_LoginDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginDialogComponent, data: {} });
export { RenderType_LoginDialogComponent as RenderType_LoginDialogComponent };
export function View_LoginDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "panels"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "right-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "login-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Smartlinx"], ["class", "logo"], ["src", "/assets/img/slx-logo-6-1-blue.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-login-form", [["isLockedByUser", "true"], ["showLogoutButton", "true"]], null, [[null, "onAuthenticated"], [null, "authenticationFailed"], [null, "logout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onAuthenticated" === en)) {
        var pd_0 = (_co.onAuthenticated() !== false);
        ad = (pd_0 && ad);
    } if (("authenticationFailed" === en)) {
        var pd_1 = (_co.onAuthenticationFailed() !== false);
        ad = (pd_1 && ad);
    } if (("logout" === en)) {
        var pd_2 = (_co.onLogout($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(5, 49152, null, 0, i3.LoginFormComponent, [i4.AuthenticationService, i5.LoggerService, i6.Router, i6.ActivatedRoute, i7.VersionSubscribeService, i8.UserActivityService, i9.UserMenuPinService, i10.NotificationsApiService], { username: [0, "username"], isLockedByUser: [1, "isLockedByUser"], showLogoutButton: [2, "showLogoutButton"], handleLoginRedirection: [3, "handleLoginRedirection"] }, { onAuthenticated: "onAuthenticated", logout: "logout", authenticationFailed: "authenticationFailed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUsername; var currVal_1 = "true"; var currVal_2 = "true"; var currVal_3 = false; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_LoginDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-login-dialog", [], null, null, null, View_LoginDialogComponent_0, RenderType_LoginDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.LoginDialogComponent, [i4.AuthenticationService, i12.SessionService, i8.UserActivityService, i9.UserMenuPinService, i13.DialogOptions, i6.Router, i14.SessionActions, i15.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginDialogComponentNgFactory = i1.ɵccf("slx-login-dialog", i11.LoginDialogComponent, View_LoginDialogComponent_Host_0, {}, {}, []);
export { LoginDialogComponentNgFactory as LoginDialogComponentNgFactory };
