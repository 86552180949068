import { TimeclockCommunicationApiService } from './../../../services/timeclock-communication/timeclock-communication-api.service';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LookupType } from '../../../../../organization/models/index';
import { LookupService } from '../../../../../organization/services/index';
import { appConfig } from '../../../../../app.config';
import { OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
var TimeclockMessageComponent = /** @class */ (function () {
    function TimeclockMessageComponent(lookupService, apiService) {
        this.isLoading = false;
        this.lookupService = lookupService;
        this.apiService = apiService;
        this.updateList();
    }
    Object.defineProperty(TimeclockMessageComponent.prototype, "orgLevel", {
        get: function () {
            return this.m_orgLevel;
        },
        set: function (value) {
            this.m_orgLevel = value;
            this.updateList();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockMessageComponent.prototype, "canSend", {
        get: function () {
            return this.selectedRecords
                && this.selectedRecords.length > 0
                && this.messageText
                && this.messageText.trim().length > 0
                && this.startDate < this.endDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockMessageComponent.prototype, "canStop", {
        get: function () {
            return this.selectedRecords
                && this.selectedRecords.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    TimeclockMessageComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.minDate = new Date();
        this.startDate = new Date();
        this.endDate = moment().add(1, 'day').toDate();
    };
    TimeclockMessageComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        _.forEach(this.clocks, function (record) {
            record['isSelected'] = _this.isAllSelected;
        });
        this.selectionChange();
    };
    TimeclockMessageComponent.prototype.selectionChange = function () {
        this.selectedRecords = [];
        this.selectedRecords = _.filter(this.clocks, function (record) {
            return Boolean(record['isSelected']) === true;
        });
        this.isAllSelected = this.selectedRecords.length === this.clocks.length;
    };
    TimeclockMessageComponent.prototype.send = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.sendTimeclockTickerMessage(this.messageText, this.getSelectedIds(), this.startDate, this.endDate)
            .then(function (result) {
            _this.isLoading = false;
            _this.isAllSelected = false;
            _this.selectedRecords = null;
            _this.updateList();
        }).catch(function (error) {
            _this.isLoading = false;
        });
    };
    TimeclockMessageComponent.prototype.stop = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.stopTimeclockTickerMessage(this.getSelectedIds())
            .then(function (result) {
            _this.isLoading = false;
            _this.isAllSelected = false;
            _this.selectedRecords = null;
            _this.updateList();
        }).catch(function (error) {
            _this.isLoading = false;
        });
    };
    TimeclockMessageComponent.prototype.getSelectedIds = function () {
        return _.map(this.selectedRecords, function (item) { return item.id; });
    };
    TimeclockMessageComponent.prototype.updateList = function () {
        var _this = this;
        if (!this.orgLevel || !this.lookupService)
            return;
        this.isLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.timeclockDefinition, orgLevelId: this.orgLevel.id, updateCacheForced: true }).then(function (lookup) {
            _this.clocks = _.filter(lookup.items, function (item) { return !item.isVirtual; });
            _this.isLoading = false;
        }).catch(function (reason) {
            _this.clocks = [];
            _this.isLoading = false;
        });
    };
    return TimeclockMessageComponent;
}());
export { TimeclockMessageComponent };
