import { PayrollExportConfig } from './../../../models/payroll-export-test/payroll-export-config';
import { AfterTestActionCodes } from './../../../models/payroll-export-test/after-test-action-codes';
import { PayrollExportTestManagementService } from './../../../services/payroll-export-test/payroll-export-test-management.service';
import { Component, OnInit } from '@angular/core';
import { PayrollExportConfigContainer } from '../../../models/payroll-export-test/payroll-export-config-container';

@Component({
    moduleId: module.id,
    selector: 'slx-payroll-export-config',
    templateUrl: 'payroll-export-config.component.html',
    styleUrls: ['payroll-export-config.component.scss']
})
export class PayrollExportConfigComponent implements OnInit {
    public container: PayrollExportConfigContainer;
    public codes: any[] = [];
    public isLoading: boolean;

    public wasChanged: boolean = false;

    constructor(private manageService: PayrollExportTestManagementService) {
        this.buildCodes();
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.wasChanged = false;
        this.manageService.getConfig().then((value: PayrollExportConfigContainer) => {
            this.container = value;
            this.isLoading = false;
        });
    }

    public setCode(config: PayrollExportConfig, code: any): void {
        if (config.code !== code.id) {
            config.code = code.id;
            this.wasChanged = true;
            //this.saveConfig();
        }
    }

    public setLock(lock: boolean): void {
        if (this.container.lockTimecardsAfterExport !== lock) {
            this.container.lockTimecardsAfterExport = lock;
            this.wasChanged = true;
            //this.saveConfig();
        }
    }


    public getCode(code: AfterTestActionCodes): any {
        return this.codes[code];
    }

    public saveConfig(): Promise<PayrollExportConfigContainer> {
        this.isLoading = true;
        if (this.wasChanged || this.container.id === 0) {
            return this.manageService.saveConfig(this.container).then((value: PayrollExportConfigContainer) => {
                this.container = value;
                this.isLoading = false;
                return value;
            });
        } else {
           return Promise.resolve(this.container);
        }
    }

    private buildCodes(): void {
        this.codes = [
            { id: AfterTestActionCodes.NONE, name: 'None', icon: '' },
            { id: AfterTestActionCodes.ALERT, name: 'Alert User', icon: 'pe-orange fas fa-exclamation-circle icon' },
            { id: AfterTestActionCodes.PREVENT, name: 'Prevent', icon: 'pe-red fas fa-exclamation-triangle icon' }
        ];
    }
}
