import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta } from '../../../core/models/index';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { TimeCardModel, ITimeCardModel, IDailyAllAbsence } from '../../models/daily-timecard/index';
import { DailyTimecardMapService } from './daily-timecard-map.service';
import { ILinePunch } from '../../models';

@Injectable()
export class DailyTimecardApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private timecardMapService: DailyTimecardMapService) {
  }

  public getDailyTimecard(empId: number, dateOn: Date): Promise<TimeCardModel> {

    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(dateOn, 'dateOn');
    const dateRoute: string = moment(dateOn).format(appConfig.requestDate);

    const url: string = `${this.getTaRoot()}/${dateRoute}/${this.getTimecardURIPortion()}/${empId.toString()}/${timeAndAttendanceConfig.api.timecards.daily.root}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<TimeCardModel> = this.apiUtilService.request<ITimeCardModel, Meta>(request)
      .then((response: ResponseBody<ITimeCardModel, Meta>) => {
        return this.timecardMapService.mapTimcard(response.data);
      });
    return promise;
  }

  public getDailyTimecardForOverlap(empId: number, dateOn: Date): Promise<TimeCardModel> {
    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(dateOn, 'dateOn');
    const dateRoute: string = moment(dateOn).format(appConfig.requestDate);
    const url: string = `${this.getTaRoot()}/${dateRoute}/${this.getTimecardURIPortion()}/${empId.toString()}/${timeAndAttendanceConfig.api.timecards.dailyTimecardPunches.root}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<TimeCardModel> = this.apiUtilService.request<ILinePunch[], Meta>(request)
      .then((response: ResponseBody<ILinePunch[], Meta>) => {
        return this.timecardMapService.mapTimcardForOverLap(response.data);
      });
    return promise;
  }

  public saveTimecard(timecard: TimeCardModel, dateOn: string): Promise<any> {

    const url: string = `${this.getTaRoot()}/${dateOn}/${this.getTimecardOrgLevelURIPortion(timecard.primaryPosition.orgLevelId)}/${timecard.employee.id}/${timeAndAttendanceConfig.api.timecards.daily.root}`;

    let timecardDto: ITimeCardModel = this.timecardMapService.mapTimcardDto(timecard);

    let request: HttpRequest<any> = new HttpRequest('PUT', url, timecardDto);

    let promise: Promise<any> = this.apiUtilService.request<ITimeCardModel, Meta>(request)
      .then((response: ResponseBody<ITimeCardModel, Meta>) => {
        return this.timecardMapService.mapTimcard(response.data);
    });
    return promise;
  }

  public exportTimecard(timecard: TimeCardModel, dateOn: string): Promise<FileBlobResponse> {

    const url: string = `${this.getTaRoot()}/${dateOn}/${this.getTimecardURIPortion()}/${timecard.employee.id}/${timeAndAttendanceConfig.api.timecards.daily.downloadDebug}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);

    return promise;
  }

  public approveTimecard(dateOn: string, empId: string): Promise<any> {

    const url: string = `${this.getTaRoot()}/${dateOn}/${this.getAproveURI()}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, [empId]);

    let promise: Promise<any> = this.apiUtilService.request<FileBlobResponse, Meta>(request);
    return promise;
  }

  public unapproveTimecard(dateOn: string, empId: string): Promise<any> {

    const url: string = `${this.getTaRoot()}/${dateOn}/${this.getUnaproveURI()}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, [empId]);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
    return promise;
  }

  public discardPunches(dateOn: Date, empId: number): Promise<any> {
    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(dateOn, 'dateOn');
    const dateRoute: string = moment(dateOn).format(appConfig.requestDate);

    const url: string = `${this.getTaRoot()}/${dateRoute}/${this.getEmpURI(empId)}/${timeAndAttendanceConfig.api.timecards.employee.discardPunches}`;
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(this.urlParamsService.createPostRequest(url));
    return promise;
  }

  public discardPayRules(dateOn: Date, empId: number): Promise<any> {
    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(dateOn, 'dateOn');
    const dateRoute: string = moment(dateOn).format(appConfig.requestDate);

    const url: string = `${this.getTaRoot()}/${dateRoute}/${this.getEmpURI(empId)}/${timeAndAttendanceConfig.api.timecards.employee.discardPayRules}`;
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(this.urlParamsService.createDeleteRequest(url));
    return promise;
  }

  public lockTimecard(empId: number, dateOn: Date, lock: boolean): Promise<boolean> {

    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(dateOn, 'dateOn');
    const oper: string = lock ? timeAndAttendanceConfig.api.timecards.daily.lock : timeAndAttendanceConfig.api.timecards.daily.unlock;
    const dateRoute: string = moment(dateOn).format(appConfig.requestDate);
    const url: string = `${this.getTaRoot()}/${dateRoute}/${this.getTimecardURIPortion()}/${empId.toString()}/${timeAndAttendanceConfig.api.timecards.daily.root}/${oper}`;

    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url);

    let promise: Promise<boolean> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return true;
      }).catch((reason: any) => {
        return false;
      });
    return promise;
  }

  private getApiRoot(): string {
    return this.apiUtilService.getApiRoot();
  }

  private getTaRoot(): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}`;
  }

  private getTimecardOrgLevelURIPortion(orgLevelId: number): string {
    return `${timeAndAttendanceConfig.api.timecards.root}/${timeAndAttendanceConfig.api.timecards.orgLevel.root}/${orgLevelId}/${timeAndAttendanceConfig.api.timecards.employee.root}`;
  }

  private getTimecardURIPortion(): string {
    return `${timeAndAttendanceConfig.api.timecards.root}/${timeAndAttendanceConfig.api.timecards.employee.root}`;
  }
  private getDailyPunchesURI(): string {
    return `${timeAndAttendanceConfig.api.dailyPunches.root}/${timeAndAttendanceConfig.api.dailyPunches.employee.root}`;
  }

  private getAproveURI(): string {
    return `${timeAndAttendanceConfig.api.timecards.root}/${timeAndAttendanceConfig.api.timecards.approveDailyTimecards}`;
  }
  private getUnaproveURI(): string {
    return `${timeAndAttendanceConfig.api.timecards.root}/${timeAndAttendanceConfig.api.timecards.unapproveDailyTimecards}`;
  }

  private getEmpURI(employeeId: number): string {
    return `${timeAndAttendanceConfig.api.timecards.root}/${timeAndAttendanceConfig.api.timecards.employee.root}/${employeeId}`;
  }
  public getAllAbsence(empId: number, date: Date): Promise<IDailyAllAbsence[]> {
    const dateRoute: string = moment(date).format(appConfig.requestDate);
    const url: string = `${this.getTaRoot()}/${dateRoute}/${timeAndAttendanceConfig.api.timecards.root}/${timeAndAttendanceConfig.api.timecards.allabsence}/${empId.toString()}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<IDailyAllAbsence[]> = this.apiUtilService.request<IDailyAllAbsence[], Meta>(request)
      .then((response: ResponseBody<IDailyAllAbsence[], Meta>) => {
        return response.data;
      });
    return promise;
  }
}
