import * as _ from 'lodash';
import { Role, RoleSubmenu, RolesMenuAccess } from '../role-models/index';
import { RolesRowDefinition, IRolesRow, ILinkedToRightRolesRow } from './roles-row';
import { RolesRightRow } from './roles-right-row';


export class RolesSubmenuRow implements ILinkedToRightRolesRow<RoleSubmenu> {
  public subMenuId: number;
  public roles: Role[];
  public mapByRole: NumberMap<RoleSubmenu>;
  public dirtyByRole: NumberMap<boolean>;
  public type: RolesRowDefinition = 'RolesSubmenuRow';
  public description: string;
  public linkedRightName: string;
  public linkedRightRow: RolesRightRow;

  public recalcStatus(roleId: number): void {
    let submenu: RoleSubmenu = this.mapByRole[roleId];
    if (submenu.isEnabled) {
      submenu.access = RolesMenuAccess.enabled;
    } else {
      submenu.access = RolesMenuAccess.disabled;
    }
    this.dirtyByRole[roleId] = submenu.isDirty;
    if(this.linkedRightRow) {
      this.linkedRightRow.setStatus(submenu.isEnabled, roleId);
    }
  }

  public setNextStatus(roleId: number): void {
    let right: RoleSubmenu = this.mapByRole[roleId];
    right.isEnabled = !right.isEnabled;
    this.recalcStatus(roleId);

  }

  public setStatus(isEnabled: boolean, roleId: number): void {
    let item: RoleSubmenu = this.mapByRole[roleId];
    item.isEnabled = isEnabled;
    this.recalcStatus(roleId);
  }
}
