export * from './employee-call-log/employee-call-log/employee-call-log.component';
export * from './employee-call-log/employee-call-logs/employee-call-logs.component';
export * from './employee-ta-log/employee-ta-log.component';
export * from './employee-call-log/employee-call-log-grid/employee-call-log-grid.component';
export * from './employee-position-selector/employee-position-selector.component';
export * from './employee-activities/employee-terminate/index';
export * from './employee-activities/employee-transfer/index';
export * from './employee-activities/employee-add/index';
export * from './employee-banner/employee-banner.component';
export * from './employee-edit-photo/employee-edit-photo-dialog/employee-edit-photo-dialog.component';
export * from './employee-edit-photo/employee-editable-photo/employee-editable-photo.component';
export * from './timeclock-communication/timeclock-communication.component';
export * from './timeclock-communication/timeclock-message/timeclock-message.component';
export * from './timeclock-communication/specific-message/specific-message.component';
export * from './send-shift-sms/send-shift-sms/send-shift-sms.component';
export * from './send-shift-sms/send-shift-sms-dialog/send-shift-sms-dialog.component';
export * from './log-phone-call/log-phone-call.component';
export * from './employee-activities/employee-rehire/index';
export * from './cost-center-split/cost-center-split.component';
export * from './employee-search/employee-search-field.component';

import { EmployeeCallLogComponent } from './employee-call-log/employee-call-log/employee-call-log.component';
import { EmployeeCallLogsComponent } from './employee-call-log/employee-call-logs/employee-call-logs.component';
import { EmployeeTALogComponent } from './employee-ta-log/employee-ta-log.component';
import { EmployeeCallLogGridComponent } from './employee-call-log/employee-call-log-grid/employee-call-log-grid.component';
import { EmployeePositionSelectorComponent } from './employee-position-selector/employee-position-selector.component';
import { employeeTerminateComponents } from './employee-activities/employee-terminate/index';
import { employeeTransferComponents } from './employee-activities/employee-transfer/index';
import { EmployeeBannerComponent } from './employee-banner/employee-banner.component';
import { employeeActivitiesComponents, AddEmployeeComponent } from './employee-activities/employee-add/index';
import { EmployeeEditPhotoDialogComponent } from './employee-edit-photo/employee-edit-photo-dialog/employee-edit-photo-dialog.component';
import { EmployeeEditablePhotoComponent } from './employee-edit-photo/employee-editable-photo/employee-editable-photo.component';
import { TimeclockCommunicationComponent } from './timeclock-communication/timeclock-communication.component';
import { TimeclockMessageComponent } from './timeclock-communication/timeclock-message/timeclock-message.component';
import { SpecificMessageComponent } from './timeclock-communication/specific-message/specific-message.component';
import { SendShiftSmsComponent } from './send-shift-sms/send-shift-sms/send-shift-sms.component';
import { SendShiftSmsDialogComponent } from './send-shift-sms/send-shift-sms-dialog/send-shift-sms-dialog.component';
import { LogPhoneCallComponent } from './log-phone-call/log-phone-call.component';
import { employeeRehireComponents } from './employee-activities/employee-rehire/index';
import { CostCenterSplitComponent } from './cost-center-split/cost-center-split.component';
import { PayRateComponentComponent } from './pay-rate-component/pay-rate-component.component';
import { EmployeeSearchFieldComponent } from './employee-search/employee-search-field.component';

export const exportComponents: any[] = [
  EmployeeCallLogComponent,
  EmployeeCallLogsComponent,
  EmployeeTALogComponent,
  EmployeeCallLogGridComponent,
  EmployeePositionSelectorComponent,
  EmployeeBannerComponent,
  AddEmployeeComponent,
  EmployeeEditPhotoDialogComponent,
  EmployeeEditablePhotoComponent,
  TimeclockCommunicationComponent,
  TimeclockMessageComponent,
  SpecificMessageComponent,
  SendShiftSmsComponent,
  SendShiftSmsDialogComponent,
  LogPhoneCallComponent,
  CostCenterSplitComponent,
  PayRateComponentComponent,
  EmployeeSearchFieldComponent,
  ...employeeTerminateComponents,
  ...employeeTransferComponents,
  ...employeeActivitiesComponents,
  ...employeeRehireComponents
];

export const components: any[] = [
  ...exportComponents
];
