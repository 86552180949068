import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { ExportDataStatus } from '../../enums/export-data-status';
import { IAcaExportExecutionItem, AcaExportExecutionItem } from '../../models/aca-export/aca-export-execution';
import { AcaExportParameter, IAcaExportParameter } from '../../models/aca-export/aca-export-parameter';
import { IAcaExportExecutionStatus, AcaExportExecutionStatus } from '../../models/aca-export/aca-export-execution-status';
import { IAcaExportNotification } from '../../models/aca-export/aca-export-notification';
import { AcaExportConfiguration } from '../../models/aca-export/aca-export-configuration';
import { exportConfigurations, exportTypeName } from '../../export-data.config';
import { getAcaRelatedExportTypes } from '../../enums/aca-export-type';
import { AcaExportCorrected1095C, IAcaExportCorrected1095C } from '../../models/aca-export/aca-export-corrected-1095c';
import { AcaExportReplace1095C, IAcaExportReplace1095C } from '../../models/aca-export/aca-export-replace-1095c';
import { AcaExportSupplemental1095C, IAcaExportSupplemental1095C } from '../../models/aca-export/aca-export-supplemental-1095c';
import { AcaExportCorrection1094CCounts, IAcaExportCorrection1094CCounts, IAcaExportCorrection1094CCountContainer, AcaExportCorrection1094CCountContainer, IAcaExportCorrection1094CCountDto, IAcaExportCorrection1094CCountsDto } from '../../models/aca-export/aca-export-correction-1094c-counts';
import { AcaExport1094CCorrection, IAcaExport1094CCorrection } from '../../models/aca-export/aca-export-1094c-correction';
import { AcaExportCorrectionParameter } from '../../models/aca-export/aca-export-correction-parameter';

@Injectable()
export class AcaExportMapService {
  constructor() { }

  public mapToAcaExportHistoryList(dtos: IAcaExportExecutionItem[]): AcaExportExecutionItem[] {
    return _.map(dtos, (dto: IAcaExportExecutionItem) => this.mapToAcaExportHistory(dto));
  }

  public mapToAcaExportHistory(dto: IAcaExportExecutionItem): AcaExportExecutionItem {
    const item = new AcaExportExecutionItem();
    item.id = dto.executionId;
    item.type = dto.type;
    item.executedBy = dto.executedBy;
    item.startedOn = dto.startedOn ? moment(dto.startedOn).toDate() : null;
    item.completedOn = dto.completedOn ? moment(dto.completedOn).toDate() : null;
    item.status = dto.exportStatus;
    item.reason = dto.failReason;
    item.employerName = dto.employerName;
    item.year = dto.year;
    item.otherParameters = dto.otherParameters;
    item.receiptId = dto.receiptId;
    item.exportTypeName = exportTypeName.get(dto.type);
    item.employerId = dto.employerId;
    item.memberOfAleGroup = dto.memberOfAleGroup;
    item.offeredMec = dto.offeredMec;
    item.qualifiedOfferMethod = dto.qualifiedOfferMethod;
    item.offerMethod98 = dto.offerMethod98;
    return item;
  }

  public mapToAcaExportStatusList(dtos: IAcaExportExecutionStatus[]): AcaExportExecutionStatus[] {
    return _.map(dtos, (dto: IAcaExportExecutionStatus) => this.mapToAcaExportStatus(dto));
  }

  public mapToAcaExportStatus(dto: IAcaExportExecutionStatus): AcaExportExecutionStatus {
    const item = new AcaExportExecutionStatus();
    item.exportType = dto.exportType;
    item.executionId = dto.executionId;
    item.status = dto.executionStatus as ExportDataStatus;
    item.changedOn = dto.changedOn ? moment(dto.changedOn).toDate() : null;
    item.reason = dto.reason;

    return item;
  }

  public mapNotificationToAcaExportStatus(dto: IAcaExportNotification): AcaExportExecutionStatus {
    const item: AcaExportExecutionStatus = {
      exportType: dto.ExportType,
      executionId: dto.Key,
      status: dto.Status,
      changedOn: dto.ChangedOn ? moment(dto.ChangedOn).toDate() : null,
      reason: dto.FailedReason,
      configurationId: null
    };
    return item;
  }

  public mapToAcaExportRequest(params: AcaExportParameter): IAcaExportParameter {
    let dto: IAcaExportParameter = {
      year: params.year,
      exportType: params.replacement == 'Yes' ? 7 : params.exportType,
      employerId: params.employerId,
      employerName: params.employerName,
      offerMethod98: params.hidden ? false : (params.offerMethod98 == 'Yes' ? true : false),
      qualifiedOfferMethod: params.hidden ? false : (params.qualifiedOfferMethod == 'Yes' ? true : false),
      memberOfAleGroup: params.hidden ? [] : (params.memberOfAleGroup ? _.map(params.memberOfAleGroup, (opt: any) => opt.id == null ? opt : opt.id) : []),
      offeredMec: params.hidden ? [] : (params.offeredMec ? _.map(params.offeredMec, (opt: any) => opt.id == null ? opt : opt.id) : []),
      empIds: params.empIds,
      recordIds: params.recordIds,
      receiptId: params.receiptId,
      overrides: this.mapTo1094CCorrectionCountContainerDto(params.overrides)
    };
    return dto;
  }

  public mapTo1094CCorrectionCountContainerDto(item: AcaExportCorrection1094CCountContainer): IAcaExportCorrection1094CCountDto {
    if (item == null) {
      return null;
    }
    const dto: IAcaExportCorrection1094CCountDto = {
      items: _.map(item.counts, (dto: AcaExportCorrection1094CCounts) => this.mapTo1094CCorrectionCountDto(dto)),
      totalFormsSubmitted: item.totalFormsSubmitted
    };
    return dto;
  }

  public mapTo1094CCorrectionCountDto(item: AcaExportCorrection1094CCounts): IAcaExportCorrection1094CCountsDto {
    const dto: IAcaExportCorrection1094CCountsDto = {
      monthName: item.month,
      ftCount: item.ftCount,
      totalCount: item.totalCount
    };
    return dto;
  }

  public mapToExportDataConfigurationList(dtos: IAcaExportExecutionItem[]): AcaExportConfiguration[] {
    return _.map(exportConfigurations, (dto: AcaExportConfiguration) => this.mapToExportDataConfiguration(dto, dtos));
  }

  public mapToExportDataConfiguration(item: AcaExportConfiguration, dtos: IAcaExportExecutionItem[]): AcaExportConfiguration {
    const relatedExports = getAcaRelatedExportTypes(item.exportType);
    const executionItem: IAcaExportExecutionItem = _.find(dtos, (dto) => {
      return _.includes(relatedExports, dto.type);
    });

    item.lastExecuted = executionItem ? this.mapToExportItem(executionItem) : null;

    return item;
  }

  public mapToExportItem(dto: IAcaExportExecutionItem): AcaExportExecutionItem {
    const item = new AcaExportExecutionItem();
    item.id = dto.executionId;
    item.status = dto.exportStatus;
    item.type = dto.type;
    item.completedOn = dto.completedOn ? moment(dto.completedOn).toDate() : null;
    item.reason = dto.failReason;
    item.year = dto.year;
    item.startedOn = dto.startedOn ? moment(dto.startedOn).toDate() : null;
    item.executedBy = dto.executedBy;
    item.employerName = dto.employerName;
    item.employerId = dto.employerId;
    item.memberOfAleGroup = dto.memberOfAleGroup;
    item.offeredMec = dto.offeredMec;
    item.qualifiedOfferMethod = dto.qualifiedOfferMethod;
    item.offerMethod98 = dto.offerMethod98;
    return item;
  }

  public mapToExportCorrection1094CCount(dtos: IAcaExportCorrection1094CCountContainer): AcaExportCorrection1094CCountContainer {
    const item = new AcaExportCorrection1094CCountContainer();
    item.counts = _.map(dtos.counts, (dto: AcaExportCorrection1094CCounts) => this.mapToExportCorrection1094CCountList(dto));
    item.totalFormsSubmitted = dtos.totalFormsSubmitted;
    return item;
  }

  public mapToExportCorrection1094CCountList(dto: IAcaExportCorrection1094CCounts): AcaExportCorrection1094CCounts {
    const item = new AcaExportCorrection1094CCounts();
    item.month = dto.month;
    item.ftCount = dto.ftCount;
    item.totalCount = dto.totalCount;
    return item;
  }

  public mapTo1094CCorrection(dto: IAcaExport1094CCorrection): AcaExport1094CCorrection {
    const item = new AcaExport1094CCorrection();
    item.executionId = dto.executionId;
    item.qualifiedOfferMethod = dto.qualifiedOfferMethod;
    item.offerMethod98 = dto.offerMethod98;
    item.memberOfAleGroup = dto.memberOfAleGroup;
    item.offeredMec = dto.offeredMec;
    return item;
  }

  public mapToAcaCorrected1095C(dto: IAcaExportCorrected1095C[]): AcaExportCorrected1095C[] {
    return _.map(dto, (dto: IAcaExportCorrected1095C) => this.mapAcaCorrected1095C(dto));
  }

  public mapAcaCorrected1095C(dto: IAcaExportCorrected1095C): AcaExportCorrected1095C {
    let model: AcaExportCorrected1095C = new AcaExportCorrected1095C();
    model.companyId = dto.companyId;
    model.empOrgId = dto.empOrgId;
    model.employeeId = dto.employeeId;
    model.employeeIdMasterId = dto.employeeIdMasterId;
    model.employeeName = dto.employeeName;
    model.ssnStatus = dto.ssnStatus;
    model.recordId = dto.recordId;
    model.ssn = dto.ssn;
    model.type = dto.type;
    return model;
  }

  public mapToReplace1095C(dto: IAcaExportReplace1095C[]): AcaExportReplace1095C[] {
    return _.map(dto, (dto: IAcaExportReplace1095C) => this.mapAcaReplace1095C(dto));
  }

  public mapAcaReplace1095C(dto: IAcaExportReplace1095C): AcaExportReplace1095C {
    let model: AcaExportReplace1095C = new AcaExportReplace1095C();
    model.companyId = dto.companyId;
    model.empOrgId = dto.empOrgId;
    model.employeeId = dto.employeeId;
    model.employeeIdMasterId = dto.employeeIdMasterId;
    model.employeeName = dto.employeeName;
    model.ssnStatus = dto.ssnStatus;
    model.recordId = dto.recordId;
    model.ssn = dto.ssn;
    model.type = dto.type;
    return model;
  }

  public mapToAcaSupplemental1095C(dto: IAcaExportSupplemental1095C[]): AcaExportSupplemental1095C[] {
    return _.map(dto, (dto: IAcaExportSupplemental1095C) => this.mapAcaSupplemental1095C(dto));
  }

  public mapAcaSupplemental1095C(dto: IAcaExportSupplemental1095C): AcaExportSupplemental1095C {
    let model: AcaExportSupplemental1095C = new AcaExportSupplemental1095C();
    model.employeeId = dto.employeeId;
    model.employeeIdMasterId = dto.employeeIdMasterId;
    model.employeeName = dto.employeeName;
    model.ssnStatus = dto.ssnStatus;
    model.recordId = dto.recordId;
    model.ssn = dto.ssn;
    return model;
  }

  public mapTo1094CCorrectionDto(item: AcaExportExecutionItem, correctionParameters: AcaExportCorrectionParameter): IAcaExport1094CCorrection {
    const dto: IAcaExport1094CCorrection = {
      executionId: item.id,
      qualifiedOfferMethod: correctionParameters.qualifiedOfferMethod,
      offerMethod98: correctionParameters.offerMethod98,
      memberOfAleGroup: _.map(_.filter(correctionParameters.memberOfAleGroup, i => i.isSelected === true), i => i.id),
      offeredMec: _.map(_.filter(correctionParameters.offeredMec, i => i.isSelected === true), i => i.id)
    };
    return dto;
  }
}
