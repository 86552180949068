import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ToolbarBaseService } from '../../../../core/services/index';
var ScheduleConsoleToolbarService = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduleConsoleToolbarService, _super);
    function ScheduleConsoleToolbarService() {
        var _this = _super.call(this) || this;
        _this.onSettingsChanged$ = new ReplaySubject(1);
        return _this;
    }
    ScheduleConsoleToolbarService.prototype.settingsChanged = function (columnsSettings) {
        this.onSettingsChanged$.next(columnsSettings);
    };
    return ScheduleConsoleToolbarService;
}(ToolbarBaseService));
export { ScheduleConsoleToolbarService };
