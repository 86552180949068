import { ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';

export abstract class ControlValueAccessorBase implements ControlValueAccessor {
  protected onTouchedCallback: () => void = _.noop;
  protected onChangeCallback: (val: any) => void = _.noop;

  public abstract writeValue(value: any): void;
  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }
}
