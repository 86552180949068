
export * from './benefit-class/index';
export * from './benefit-console/index';
export * from './benefit-details/index';
export * from './benefit-employees/index';

import { benefitClassComponents } from './benefit-class/index';
import { benefitConsoleComponents, BenefitPayrollMappingDialogComponent, BenefitPayrollExistingMappingDialogComponent } from './benefit-console/index';
import {
  benefitDetailsComponents,
  BenefitDetailsExtendDialogComponent,
  BenefitDetailsExpireDialogComponent,
  BenefitDetailsRenewDialogComponent,
  BenefitDetailsEligibilityRuleDialogComponent,
  BenefitDetailsEligibilityRulesListComponent
} from './benefit-details/index';
import { benefitEmployeesComponents, benefitEmployeesEntryComponents } from './benefit-employees/index';


export const components: any[] = [
  ...benefitClassComponents,
  ...benefitConsoleComponents,
  ...benefitDetailsComponents,
  ...benefitEmployeesComponents
];

export const entryComponents: any[] = [
  ...benefitEmployeesEntryComponents,
  BenefitDetailsExtendDialogComponent,
  BenefitDetailsExpireDialogComponent,
  BenefitDetailsRenewDialogComponent,
  BenefitDetailsEligibilityRuleDialogComponent,
  BenefitDetailsEligibilityRulesListComponent,
  BenefitPayrollMappingDialogComponent,
  BenefitPayrollExistingMappingDialogComponent
];

export const exportComponents: any[] = [
];
