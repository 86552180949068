import { FieldData, Actions } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';
import { PayCyclePeriod, PayCyclePeriodDefinition } from '../../../../organization/models/lookup/index';
import { appConfig } from '../../../../../app/app.config';
import * as moment from 'moment';

export interface IEmployeeSectionsPayCycles {
  records: IEmployeeSectionsPayCycleRecord[];
}
export interface IEmployeeSectionsPayCycleRecord {
  startDate: string;
  endDate: string;
  approved: boolean;
  locked: boolean;
  regular: number;
  overtime: number;
  other: number;
  absences: number;
}
export interface IEmployeeSectionsPayCycleCalcConf {
  effectiveDate: string;
  payCycle: PayCyclePeriodDefinition;
  startDay1: number;
  startDay2: number;
}

export class EmployeeSectionsPayCycleRecord {
  public startDate: Date;
  public endDate: Date;
  public approved: boolean;
  public locked: boolean;
  public regular: number;
  public overtime: number;
  public other: number;
  public absences: number;

  public get startDateFormatForExcel(): string {
    return moment(this.startDate).format(appConfig.dateFormat);
  }

  public get endDateFormatForExcel(): string {
    return moment(this.endDate).format(appConfig.dateFormat);
  }
}
export class EmployeeSectionsPayCycleCalcConf {
  public effectiveDate: Date;
  public minEffectiveDate: Date;
  public payCycle: PayCyclePeriod;
  public startDay1: number;
  public startDay2: number;
}
export class EmployeeSectionsPayCycles extends EmployeeSectionsBase {
  public records: EmployeeSectionsPayCycleRecord[];

  constructor() {
    super();
    this.records = [];
  }
}

