import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Input, Inject } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { DOCUMENT } from '@angular/common';
import { Lookup } from '../../../../../organization/models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import {
  KendoGridStateHelper,
  saveEvent,
  removeEvent,
  ModalService,
  ConfirmOptions,
  ConfirmDialog2Component
} from '../../../../../common/index';
import { EmployeeSubsectionDependents } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import { BenefitEmployeeDependent, BenefitEmployeeDependentsEnrollments } from '../../../../../app-modules/benefits/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-dependents-grid',
  templateUrl: 'employee-sections-dependents-grid.component.html',
  styleUrls: ['employee-sections-dependents-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsDependentsGridComponent implements OnInit, OnDestroy {

  @Input()
  public isEditable: boolean;
  
  public employeeId: number;
  public gridState: KendoGridStateHelper<BenefitEmployeeDependent>;
  public pageSize: number = 5;
  public appConfig: IApplicationConfig;
  public isCheckedAll: boolean;
  public dependents: Array<BenefitEmployeeDependent> = [];
  public globalEditMode: boolean = false;
  public maxDate: Date;

  @ViewChild('gridDependents', { static: true })
  public gridDependents: GridComponent;

  public get isEditMode(): boolean {
    return _.isObjectLike(this.editedDependent);
  }
  public get canCreate(): boolean {
    return this.dependentSection.canAddDependents && !this.isEditMode && !this.globalEditMode;
  }
  public get canEdit(): boolean {
    return this.dependentSection.canEditDependents && !this.isEditMode && !this.globalEditMode;
  }
  public get canDelete(): boolean {
    return this.dependentSection.canRemoveDependents && !this.isEditMode && !this.globalEditMode;
  }
  public get canEnroll(): boolean {
    return this.dependentSection.canEnrollDependents && this.selectedDependents.size > 0 && !this.isEditMode;
  }
  public genderLookup: Lookup;
  public stateLookup: Lookup;
  public pattern = /[^\w\s]|[_]/gi;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private editedDependent: BenefitEmployeeDependent;
  private dependentSection = new EmployeeSubsectionDependents();
  private selectedDependents = new Map<number, BenefitEmployeeDependent>();
  private filteredRecords: Array<BenefitEmployeeDependent>;

  constructor(
    private modalService: ModalService,
    private manService: EmployeeSectionsBenefitsManagementService,
    private changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.gridState = new KendoGridStateHelper<BenefitEmployeeDependent>();
    this.appConfig = appConfig;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'firstName', dir: 'asc' }];
    this.maxDate = moment().startOf('day').toDate();
  }

  public ngOnInit(): void {
    this.subscriptions.dependents = this.manService
      .subscribeToDependentsSubsection((sec: EmployeeSubsectionDependents) => {
        this.dependentSection = sec;
        this.updateDependents();
      });

    this.subscriptions.genderLookup = this.manService
      .subscribeToGenderLookup((lookup: Lookup) => (this.genderLookup = lookup));

    this.subscriptions.stateLookup = this.manService
      .subscribeToStateLookup((lookup: Lookup) => (this.stateLookup = lookup));

    this.subscriptions.empId = this.manService
      .subscribeToEmployeeId((empId: number) => {
        this.employeeId = empId;
      });

    this.subscriptions.changeDep = this.manService
      .subscribeToChangedDependent((data) => {
        const dep = data.dependent;
        const index = _.findIndex(this.dependentSection.dependents, d => d.id === dep.id);
        if (data.isCreated) {
          this.dependentSection.dependents.push(dep);
        } else if (data.isEdited) {
          this.dependentSection.dependents.splice(index, 1, dep);
        } else {
          this.dependentSection.dependents.splice(index, 1);
        }
        this.toggleEditMode(null);
        this.updateDependents();
      });

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe((): void => {
        this.updateDependents();
      });

    this.subscriptions.gridSave = this.gridState.onSave$
      .subscribe((event: saveEvent<BenefitEmployeeDependent>) => {
        if (event.isNew) {
          this.manService.createDependent(event.dataItem);
          this.toggleEditMode(null);
          this.manService.toggleEditMode(false);
          return;
        }
        this.manService.editDependent(event.dataItem);
        this.toggleEditMode(null);
        this.manService.toggleEditMode(false);
      });

    this.subscriptions.gridRemove = this.gridState.onRemove$
      .subscribe((event: removeEvent<BenefitEmployeeDependent>) => {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog(
          'Delete Dependent',
          'Are you sure you want to delete this dependent?',
          this.modalService,
          (isDelete: boolean) => {
            if (isDelete) {
              this.manService.deleteDependent(event.dataItem);
            }
          },
          options);

      });

    this.subscriptions.gridEdit = this.gridState.onEdit$
      .subscribe((dependent: BenefitEmployeeDependent) => {
        this.manService.toggleEditMode(true);
        this.toggleEditMode(dependent);
      });

    this.subscriptions.gridCancel = this.gridState.onCancel$
      .subscribe(() => {
        this.manService.toggleEditMode(false);
        this.toggleEditMode(null);
      });

    this.subscriptions.globalEditing = this.manService
      .subscribeToEditMode((isEdit: boolean): void => {
        this.globalEditMode = isEdit;
        this.updateDependents();
      });

    this.subscriptions.enrollments = this.manService
      .subscribeToEnrollmentsQuantity(({ id, quantity }): void => {
        if (quantity === 0) {
          this.markDependentsAsHasNoBenefits(id);
        }
      });

    this.subscriptions.reset = this.manService
      .subscribeToReset((): void => {
        _.times(_.size(this.gridState.view.data), i => this.gridDependents.collapseRow(i));
        this.gridState.closeEditor(this.gridDependents);
        this.toggleEditMode(null);
        this.updateDependents();
      });
  }

  public ngOnDestroy(): void {
    this.dependentSection = new EmployeeSubsectionDependents();
  }

  public isShownAddress(): boolean {
    return _.some(this.dependentSection.dependents, dep => _.size(this.getDependentAddress(dep)) > 0);
  }

  public isEditingRow(dep: BenefitEmployeeDependent): boolean {
    return _.isObjectLike(this.editedDependent) && _.isObjectLike(dep) && this.editedDependent.id === dep.id;
  }

  public isCheckedEmp(dep: BenefitEmployeeDependent): boolean {
    return this.selectedDependents.has(dep.id);
  }

  public getDependentAddress(dep: BenefitEmployeeDependent): string {
    return _.isString(dep.address) && _.size(dep.address) > 0
    && (_.isNil(dep.street) && _.isNil(dep.city) && _.isNil(dep.state) && _.isNil(dep.zipcode)) ? dep.address : '';
  }

  public onAddNewDependent(): void {
    const dependent = new BenefitEmployeeDependent();
    dependent.id = null;
    dependent.employeeId = this.employeeId;
    dependent.birthDate = null;
    dependent.relation = null;
    dependent.gender = null;
    this.toggleEditMode(dependent);
    this.gridState.closeEditor(this.gridDependents);
    this.gridDependents.addRow(this.editedDependent);
    this.onSelectAll(true);
  }

  public onSelectDependentsBenefits(depsEnrollments: BenefitEmployeeDependentsEnrollments): void {
    this.manService.updateDependentsEnrollments(depsEnrollments.dependents);
    this.onSelectAll(true);
    this.markDependentsAsHasBenefits(depsEnrollments.dependents);
    this.updateDependents();
  }

  public onDateChanged(): void {
    if (_.isObjectLike(this.subscriptions.dateChanged)) {
      this.subscriptions.dateChanged.unsubscribe();
    }
    this.subscriptions.dateChanged = Observable.of(true)
      .delay(300)
      .subscribe(() => this.changeDetector.markForCheck());
  }

  public onSelectAll(clearSelection: boolean): void {
    if (this.isCheckedAll || clearSelection) {
      this.selectedDependents.clear();
      this.isCheckedAll = false;
    } else {
      _.forEach(this.filteredRecords, e => {
        this.selectedDependents.set(e.id, e);
      });
      this.isCheckedAll = true;
    }
    this.updateSelectedDependents();
  }

  public onSelectSingle(dep: BenefitEmployeeDependent): void {
    if (this.selectedDependents.has(dep.id)) {
      this.selectedDependents.delete(dep.id);
      this.isCheckedAll = false;
    } else {
      this.selectedDependents.set(dep.id, dep);
      if (_.size(this.filteredRecords) === this.selectedDependents.size) {
        this.isCheckedAll = true;
      }
    }
    this.updateSelectedDependents();
  }

  private markDependentsAsHasBenefits(dependents: Array<BenefitEmployeeDependent>): void {
    _.forEach(this.dependentSection.dependents, dep => {
      const d = _.find(dependents, { id: dep.id });
      if (_.isObjectLike(d)) {
        dep.hasBenefits = true;
      }
    })
  }

  private markDependentsAsHasNoBenefits(id: number): void {
    const dep = _.find(this.dependentSection.dependents, { id: id });
    if (_.isObjectLike(dep)) {
      dep.hasBenefits = false;
      this.updateDependents();
    }
  }

  private updateSelectedDependents(): void {
    this.dependents = Array.from(this.selectedDependents.values());
  }

  private updateDependents(): void {
    this.refreshGrid();
    this.changeDetector.markForCheck();
  }

  private refreshGrid(): void {
    if (!this.dependentSection) {
      return;
    }
    this.filteredRecords = process(this.dependentSection.dependents, { filter: this.gridState.state.filter, sort: this.gridState.state.sort }).data;
    this.gridState.view = process(this.dependentSection.dependents, this.gridState.state);
    setTimeout(() =>{
      let pendoElements = this.document.querySelectorAll('*[id^="_pendo-badge_"]');
      pendoElements.forEach((element: HTMLElement) => {
        if(element.hasAttribute('data-layout') && element.getAttribute('data-layout') === "badgeBlank"){
          element.style["zIndex"] = "99";         
        }
      })
    }, 2000);
  }

  private toggleEditMode(dep: BenefitEmployeeDependent): void {
    this.editedDependent = dep;
  }
}
