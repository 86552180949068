import { WeeklyData } from '../../../core/models/index';
import { OpenShiftShortDetailsWrapper } from './index';

export class OpenShiftShortDetailsSetWrapper {
  public weeklyData: WeeklyData<OpenShiftShortDetailsWrapper>[];
  public isPosted: boolean;
  public postedByUserId: number;
  public postedByUserLogin: string;
  public postDate: Date;
  public canPostSchedule: boolean;
  public canEditOpenShiftCount: boolean;
  public canApproveDenyPartnerShiftCount: boolean;
  public canSendSMS: boolean;
}
