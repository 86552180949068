import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';
var KendoGridTimeFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridTimeFilterComponent, _super);
    function KendoGridTimeFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        var slxFilters = _.map(kendoUtils.slxTimeFilters, function (filter) { return filter.key; });
        var slxFiltersKeysMap = _.keyBy(slxFilters, function (key) { return key; });
        var dateOperators = _this.localizeOperators(Object.assign(slxFiltersKeysMap, {
            'filterEqOperator': 'eq',
            'filterNotEqOperator': 'neq',
            'filterAfterOrEqualOperator': 'gte',
            'filterAfterOperator': 'gt',
            'filterBeforeOrEqualOperator': 'lte',
            'filterBeforeOperator': 'lt'
        }));
        _this.showOperators = true;
        _this.operator = 'eq';
        _this.defaultOperators = dateOperators(_this.localization);
        return _this;
    }
    return KendoGridTimeFilterComponent;
}(KendoGridBasicFilterComponent));
export { KendoGridTimeFilterComponent };
