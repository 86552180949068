import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { ManagementBaseService } from '../../../core/services/management/management-base.service';
import { CostCentersConfigurationContainer } from '../../models/cost-centers/cost-centers-configuration-container';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { ChangeManagementService } from '../../../common/services/change-management/change-management.service';
import { CostCentersApiService } from './cost-centers-api.service';
import { CostCentersMapService } from './cost-centers-map.service';
import { LookupApiService } from '../../../organization/services/index';
var CostCentersConfigurationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(CostCentersConfigurationManagementService, _super);
    function CostCentersConfigurationManagementService(access, changeService, api, map, lookupApiService) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.map = map;
        _this.lookupApiService = lookupApiService;
        _this.removeItemsStartCmd$ = new ReplaySubject();
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.noSelectedItem$ = new Subject();
        _this.confirmDelete$ = new Subject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.onItemSaved$ = new ReplaySubject();
        _this.defaultsInitialized$ = new ReplaySubject();
        _this.viewRefresh$ = new Subject();
        _this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: false,
            canEdit: false,
            canAdd: false,
            canDelete: false,
            orgLevelChanged: false
        };
        return _this;
    }
    Object.defineProperty(CostCentersConfigurationManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    CostCentersConfigurationManagementService.prototype.init = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.changeService.setCurrentComponentId('configure_cost_centers');
                this.access.allowCorporationLevel = false;
                this.access.allowOrganizationLevel = true;
                this.access.allowDepartmentLevel = true;
                this.appDataSubscription = this.orgLevel$.combineLatest(this.user$).subscribe(function (value) {
                    var orgLevel = value[0], user = value[1];
                    if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                        return;
                    }
                    _this.currentOrgLevel = orgLevel;
                    _this.currentUser = user;
                    _this.access.orgLevelType = _this.currentOrgLevel.type;
                    _this.initDefaults();
                });
                return [2 /*return*/];
            });
        });
    };
    CostCentersConfigurationManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    CostCentersConfigurationManagementService.prototype.onDeleteItemStart = function () {
        this.removeItemsStartCmd$.next(null);
    };
    CostCentersConfigurationManagementService.prototype.onRemoveItem = function (itemToDelete) {
        if (itemToDelete.employeeCount > 0) {
            this.confirmDelete$.next(itemToDelete);
        }
        else {
            this.deleteItem(itemToDelete);
        }
    };
    CostCentersConfigurationManagementService.prototype.noSelectedItem = function () {
        this.noSelectedItem$.next(null);
    };
    CostCentersConfigurationManagementService.prototype.onAddItem = function (item) {
        var _this = this;
        this.markAsDirty();
        if (this.currentOrgLevel.type == OrgLevelType.department) {
            var currentDepartment = this.departments.filter(function (x) { return x.id = _this.currentOrgLevel.relatedItemId; })[0];
            item.department = currentDepartment;
        }
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    };
    CostCentersConfigurationManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    CostCentersConfigurationManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    CostCentersConfigurationManagementService.prototype.onCancelEditItem = function () {
        this.editingItem = null;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    };
    CostCentersConfigurationManagementService.prototype.onSaveItem = function (info) {
        if (info.isNew) {
            this.createItem(info.dataItem);
        }
        else {
            this.updateItem(info.dataItem);
        }
    };
    //#endregion IConfigurationManagementService implementation
    CostCentersConfigurationManagementService.prototype.deleteItem = function (item) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.deleteRecord(item.id)
            .then(function () {
            _this.changeService.clearChanges();
            _this.editingItem = null;
            _this.loadRecords();
        })
            .catch(function () { return _this.loadRecords(); });
    };
    CostCentersConfigurationManagementService.prototype.reloadRecords = function () {
        this.onLoaded$.next(null);
        this.fetchRecords(this.currentOrgLevel.id);
    };
    CostCentersConfigurationManagementService.prototype.initDefaults = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var departmentsPromise, positionPromise, unitPromise, results;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        departmentsPromise = this.lookupApiService.getDepartments(null, this.currentOrgLevel.id);
                        positionPromise = this.lookupApiService.getPositions(null, this.currentOrgLevel.id, true, false);
                        unitPromise = this.lookupApiService.getLocationUnits(null, this.currentOrgLevel.id);
                        return [4 /*yield*/, Promise.all([unitPromise, departmentsPromise, positionPromise])];
                    case 1:
                        results = _a.sent();
                        this.units = results[0];
                        this.departments = results[1];
                        this.positions = results[2] ? _.uniqBy(results[2], function (position) { return position.id; }) : [];
                        this.map.departments = this.departments;
                        this.map.positions = this.positions;
                        this.map.units = this.units;
                        this.defaultsInitialized$.next(null);
                        if (this.m_container) {
                            this.m_container.records = [];
                            this.onLoaded$.next(this.m_container);
                        }
                        if (this.currentOrgLevel.type === OrgLevelType.department || this.currentOrgLevel.type === OrgLevelType.organization) {
                            this.onStateChanged$.next({
                                isLoading: false,
                                bulkEditMode: false,
                                canBulkEdit: false,
                                canEdit: false,
                                canAdd: false,
                                canDelete: false,
                                orgLevelChanged: true
                            });
                            this.fetchRecords(this.currentOrgLevel.id);
                        }
                        else {
                            this.onStateChanged$.next({
                                isLoading: false,
                                bulkEditMode: false,
                                canBulkEdit: false,
                                canEdit: false,
                                canAdd: false,
                                canDelete: false,
                                orgLevelChanged: true
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CostCentersConfigurationManagementService.prototype.fetchRecords = function (orgLevelId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.access.lockActions = true;
        this.api.getRecords(orgLevelId).
            then(function (result) {
            _this.changeService.clearChanges();
            _this.access.lockActions = false;
            _this.m_container = result;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            if (_this.m_container.actions)
                _this.access.actions = _this.m_container.actions;
            _this.onLoaded$.next(_this.m_container);
            var state = {
                isLoading: false,
                bulkEditMode: false,
                canBulkEdit: false,
                canEdit: _this.access.actions.canEdit,
                canAdd: _this.access.actions.canAdd,
                canDelete: _this.access.actions.canDelete
            };
            _this.state = state;
            _this.onStateChanged$.next(state);
            _this.onLoadStatusChanged(false);
        }).catch(function (e) {
            _this.m_container = new CostCentersConfigurationContainer();
            _this.m_container.records = [];
            _this.onLoaded$.next(_this.m_container);
            _this.access.lockActions = false;
            _this.onLoadStatusChanged(false);
        });
    };
    CostCentersConfigurationManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.updateRecord(item)
            .then(function () {
            _this.changeService.clearChanges();
            _this.update();
        })
            .catch(function () { return _this.loadRecords(); });
    };
    CostCentersConfigurationManagementService.prototype.createItem = function (item) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.createRecord(item)
            .then(function () {
            _this.changeService.clearChanges();
            _this.update();
        })
            .catch(function () { return _this.loadRecords(); });
    };
    CostCentersConfigurationManagementService.prototype.update = function () {
        this.onItemSaved$.next(this.editingItem);
        this.editingItem = null;
        this.loadRecords();
    };
    CostCentersConfigurationManagementService.prototype.loadRecords = function () {
        this.viewRefresh$.next(false);
        this.onLoadStatusChanged(false);
        this.fetchRecords(this.currentOrgLevel.id);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], CostCentersConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], CostCentersConfigurationManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersConfigurationManagementService.prototype, "appDataSubscription", void 0);
    return CostCentersConfigurationManagementService;
}(ManagementBaseService));
export { CostCentersConfigurationManagementService };
