export interface INotificationRole {
    roleId: number;
    name: string;
    
}
export class NotificationRole {
    constructor(roleId : number, name : string) {

        this.roleId = roleId;
        this.name = name;

    }
    public roleId: number;
    public name: string;
}