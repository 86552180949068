import { EmployeeShortInfo, Department, Organization, IEmployeeShortInfo, IDepartment, IPosition, Position, IOrganization } from '../../../organization';
import * as moment from 'moment';
import * as _ from 'lodash';

export const state1095CValue= {
  suggested: 'suggested',
  edited: 'edited',
  edited_confirmed: 'edit_confirmed',
  confirmed:'confirmed',
  quick_edit:'quickedit'
}
export const aca1095CPopupAction=
{
addEmplyee:'addEmployee',
removeEmployee:'removeEmployee',
edit1095Record:'edit1095Record',
quickEditRecord:'quickEditRecord',
quickConfirmRecord:'quickConfirmRecord',
quickEditResetRecord:'quickEditResetRecord',
quickConfirmResetRecord:'quickConfirmResetRecord'
}
export const acaQuickPopupState=
{
quickEditActive:'quickEditActive',
quickEditStart:'quickEditStart',
quickEditSave:'quickEditSave',
quickEditClearAndClose:'quickEditClearAndClose',
quickEditClear:'quickEditClear',
quickEditClose:'quickEditClose',

quickEditResetActive:'quickEditResetActive',
quickEditResetStart:'quickEditResetStart',
quickEditResetSave:'quickEditResetSave',
quickEditResetClearAndClose:'quickEditResetClearAndClose',
quickEditResetClear:'quickEditResetClear',
quickEditResetClose:'quickEditResetClose',

quickConfirmActive:'quickConfirmActive',
quickConfirmStart:'quickConfirmStart',
quickConfirmSave:'quickConfirmSave',
quickConfirmClearAndClose:'quickConfirmClearAndClose',
quickConfirmClear:'quickConfirmClear',
quickConfirmClose:'quickConfirmClose',

quickConfirmResetActive:'quickConfirmResetActive',
quickConfirmResetStart:'quickConfirmResetStart',
quickConfirmResetSave:'quickConfirmResetSave',
quickConfirmResetClearAndClose:'quickConfirmResetClearAndClose',
quickConfirmResetClear:'quickConfirmResetClear',
quickConfirmResetClose:'quickConfirmResetClose'

}
export class Aca1095CPopupInitData{
  public popupAction:string;
  public params:AcaMonthRecord;
  public companyId:number;
  public employeeId:number;
  public employeeMasterId:number;
}
export interface IAca1095cRecord {
  employeeId:number;
  employeeMasterId:number;
  employeeName: string;
  companyId:number;
  department: string;
  position: string;
  organization: string;
  hireDate: string;
  terminationDate: string;
  benefitDeclined: string;
  purchasedMarketplace: string;
  acaType: string;
  months: AcaMonthRecord[];
  isConfirmed:boolean;
  isNotConfirmed :boolean;
  isFullTimeEnrolledFullYear:boolean;
  isFullTimeEnrolledPartYear:boolean;
  isFullTimeNotEnrolled:boolean;
  isPartTimeEnrolled:boolean;
}
export class Aca1095cRecord {
  public employeeId: number;
  public employeeMasterId:number;
  public employeeName: string;
  public companyId:number;
  public department: string;
  public position: string;
  public organization: string;
  public hireDate: Date;
  public terminationDate: Date;
  public benefitDeclined: string;
  public purchasedMarketplace: string;
  public acaType: string
  public expand: boolean = false;
  public link:boolean;
  public months: AcaMonthRecord[];
  public isConfirmed:boolean;
  public isNotConfirmed :boolean;
  public isFullTimeEnrolledFullYear:boolean;
  public isFullTimeEnrolledPartYear:boolean;
  public isFullTimeNotEnrolled:boolean;
  public isPartTimeEnrolled:boolean;
}
export class Aca1095cActions {
  public employeeName: boolean;
  public organization: boolean;
  public department: boolean;
  public position: boolean;
  public hireDate: boolean;
  public terminationDate: boolean;
  public acaRecord: boolean;
  public addEmployee: boolean;
  public edit1095c:boolean;
  public confirm1095c:boolean;
  public deleteEmployee: boolean;
  public exportToExcel: boolean;
  public exportToPdf: boolean;
  
}

export interface IAca1095c {
  records: IAca1095cRecord[];
  insightSummary: IAcaInsightSummaryRecords;
}

export class Aca1095c {
  public records: Aca1095cRecord[];
  public insightSummary: AcaInsightSummaryRecords;
  public actions: Aca1095cActions;
}
export interface IAcaMonthRecord {
  monthName: string;
  date: string;
  activeInd: number;
  suggestedOption: string;
  suggestedTier: string;
  suggestedBenefitId: number;
  suggestedBenefitName: string;
  suggestedUnsure: number;
  suggestedCode: string;
  suggestedHarbor: string;
  suggestedCost: string;
  enrolledInd: boolean;
  selectedOption: string;
  selectedTier: string;
  selectedBenefitName: string;
  selectedBenefitId: number;
  selectedCode: string;
  selectedHarbor: string;
  selectedCost: string;
  selZip:string;
  selected: string;
  confirmedInd: boolean;
  hireMonthInt: number;
  termMonthInt: number;
  reason:string;
  debug: number;
}
export class AcaMonthRecord {
  public monthName: string;
  public date: string;
  public activeInd: number;
  public suggestedOption: string;
  public suggestedTier: string;
  public suggestedBenefitId: number;
  public suggestedBenefitName: string;
  public suggestedUnsure: number;
  public suggestedCode: string;
  public suggestedHarbor: string;
  public suggestedCost: string;
  public enrolledInd: boolean;
  public edited_enrolledInd: boolean;
  public selectedOption: string;
  public selectedTier: string;
  public selectedBenefitName: string;
  public selectedBenefitId: number;
  public selectedCode: string;
  public edited_selectedCode: string;
  public selectedHarbor: string;
  public edited_selectedHarbor: string;
  public selectedCost: string;
  public edited_selectedCost: string;
  public selZip:string;
  public link:boolean;
  public quickEdit:boolean;
  public quickConfirm:boolean;
  public quickEditReset:boolean;
  public quickConfirmReset:boolean;
  public selected: string;
  public confirmedInd: boolean;
  public hireMonthInt: number;
  public termMonthInt: number;
  public reason:string;
  public edited_reason: string;
  public debug: number;
  public get displayCode():string{
    let displayCode='';
    if(_.isEqual(this.status,state1095CValue.suggested))
    {
      displayCode=this.suggestedCode;
    }
    else
    {
      displayCode=this.selectedCode;
    }
    return displayCode;
  }
  public get displayOption():string{
    let displayOption='';
    if(_.isEqual(this.status,state1095CValue.suggested))
    {
      displayOption=this.suggestedOption;
    }
    else
    {
      displayOption=this.selectedOption;
    }
    return displayOption;
  }
  public get displayTier():string{
    let displayTier='';
    if(_.isEqual(this.status,state1095CValue.suggested))
    {
      displayTier=this.suggestedTier;
    }
    else
    {
      displayTier=this.selectedTier;
    }
    return displayTier;
  }
  public get displayHarbor():string{
    let displayHarbor='';
    if(_.isEqual(this.status,state1095CValue.suggested))
    {
      displayHarbor=this.suggestedHarbor;
    }
    else
    {
      displayHarbor=this.selectedHarbor;
    }
    return displayHarbor;
  }
  public get displayCost():string{
    let displayCost='';
    if(_.isEqual(this.status,state1095CValue.suggested))
    {
      displayCost=this.suggestedCost;
    }
    else
    {
      displayCost=this.selectedCost;
    }
    return displayCost;
  }
  
  public get isQuickMode():boolean{
    if(this.quickEdit || this.quickEditReset || this.quickConfirm || this.quickConfirmReset)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
  public get status():any{
    let status: any = state1095CValue.suggested;
    if(this.confirmedInd)
    {
      if((!_.isEqual(this.selectedCost,this.suggestedCost) || !_.isEqual(this.selectedHarbor,this.suggestedHarbor) || !_.isEqual(this.selectedCode,this.suggestedCode)))
      {
        status=state1095CValue.edited_confirmed;
      }
      else
      {
        status=state1095CValue.confirmed;
      }
    }
    else
    {
      if((this.selectedCost || this.selectedHarbor || this.selectedCode))
      {
        if((!_.isEqual(this.selectedCost,this.suggestedCost) || !_.isEqual(this.selectedHarbor,this.suggestedHarbor) || !_.isEqual(this.selectedCode,this.suggestedCode)))
        {
          status=state1095CValue.edited;
        }
        else
        {
          status=state1095CValue.suggested;
        }
      }
    }
  return status;
  }
}
export class EmployeeFilterRecord {
  public name: string;
  public id: number;
}
export class EmployeeFilter {
  public employeeFilterRecord: EmployeeFilterRecord[];
  public filter: EmployeeFilterRecord[];

}
export interface IAcaInsightSummaryRecords {
  total: number;
  totalConfirmed: number;
  totalNotConfirmed: number;
  fullTimeEnrolledFullYear: number;
  fullTimeEnrolledPartYear: number;
  fullTimeNotEnrolled: number;
  partTimeEnrolled: number;
}
export class AcaInsightSummaryRecords {
  public total: number;
  public totalConfirmed: number;
  public totalNotConfirmed: number;
  public fullTimeEnrolledFullYear: number;
  public fullTimeEnrolledPartYear: number;
  public fullTimeNotEnrolled: number;
  public partTimeEnrolled: number;
}

export class AcaInsightFilter {
  public allRecords: boolean = true;
  public acaType: string = null;
  public confirmInd: boolean = null;
  public enrolledInd: boolean = null;
  public enrolledFullYear: boolean =null;
  public enrolledPartYear: boolean =null;  
  public filter: string =null;  
}

export class EmployeeIdList {
  public employeeId: number;
  public expand: boolean;
}




