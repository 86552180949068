import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SignalrHub2Service } from '../../../channel/services/signalr/signalr-hub2.service';
import { appConfig } from '../../../app.config';
import { pbjConfig } from '../../../app-modules/pbj/pbj.config';
import { SessionService, ThrottlingService } from '../../../core/services';

@Injectable({
  providedIn: 'root',
})
export class PbjSignalrService {
  public pbjDownloadOptionsFeedback$ = new Subject<any>();
  public pbjExportRealtimeFeedback$ = new Subject<any>();
  public isPDFDownloadInitiated$ = new Subject<boolean>();

  constructor(
    private signalRService: SignalrHub2Service,
    private throttlingService: ThrottlingService,
    private sessionService: SessionService
    ) {
    this.signalRService = new SignalrHub2Service(this.throttlingService, this.sessionService);
  }

  public init(){
    this.signalRService.init({ url: this.getApiRoot(), hubName: pbjConfig.api.signalR.signalRHub });
    this.pbjDownloadOptionsFeedback$ = this.signalRService.subscribe('PBJExportExcelPdfGroup');
    this.pbjExportRealtimeFeedback$ = this.signalRService.subscribe('PBJExportGroupName');
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version2}/${pbjConfig.api.signalR.root}/signalr`;
  }
}
