import { PayrollExportConfigPopupComponent } from './../payroll-export-config/payroll-export-config-popup/payroll-export-config-popup.component';
import { PayrollExportConfigComponent } from './../payroll-export-config/payroll-export-config.component';
import { ModalService } from './../../../../../common/services/modal/modal.service';
import { PayrollExportTestManagementService } from './../../../services/payroll-export-test/payroll-export-test-management.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PayCycle } from '../../../../../organization/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-payroll-export-test-toolbar',
  templateUrl: 'payroll-export-test-toolbar.component.html',
  styleUrls: ['payroll-export-test-toolbar.component.scss']
})
export class PayrollExportTestToolbarComponent implements OnDestroy {
  public currentPayCycle: PayCycle;
  public currentPayCycles: PayCycle[];

  constructor(public manageService: PayrollExportTestManagementService, private modalService: ModalService) {
    this.manageService.paycycleChanged.subscribe((value: PayCycle) => {
      if (this.currentPayCycle !== value) {
        this.currentPayCycle = value;        
      }
    });

    this.manageService.paycyclesChanged.subscribe((value: PayCycle[]) => {
      this.currentPayCycles = value;
    });
  }

  public onPayCycleSelected(payCycle: PayCycle): void {
    this.manageService.selectedPayCycle = payCycle;
    this.manageService.updateList();
    this.manageService.isAccrualModuleEnabled();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public showSettings(): void {
    PayrollExportConfigPopupComponent.openDialog(this.manageService, this.modalService, (result: boolean) => {

    });
  }
}
