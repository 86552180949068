import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgGridModule } from 'ag-grid-angular';

import { GridModule, ExcelModule, PDFModule  } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { MomentModule } from 'angular2-moment';
import { NgxPopperModule } from 'ngx-popper';

import { schedulerComponents, exportSchedulerComponents, entrySchedulerComponent } from './components/index';
import { MasterScheduleToolbarService, services } from './services/index';
import { pipes } from './pipes/index';
import { directives, exportDirectives } from './directives/index';
import { routesModule } from './scheduler.routes';
import { EPIC_PROVIDERS, MasterScheduleEpics } from './epics/index';
import { STORE_SELECTORS, INDIVIDUAL_SCHEDULE_ACTION_PROVIDERS } from './store/index';
import { MASTER_SCHEDULE_ACTION_PROVIDERS } from './store/master-schedule/index';
import { CommonModule as CommonAppModule } from '../common/index';
import { rootEpics } from '../state-model/state-model.module';
import { EmployeeModule } from '../employee/employee/index';
import { EmployeeSectionsModule } from '../employee/employee-sections/index';
import { OrganizationModule } from '../organization/index';
import { ChannelModule } from '../channel/index';
import { SchedulerNotificationService } from './services/schedule/schedule-notification.service';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { ComponentsLibraryModule } from '../components-library/index';
import { LicenseManager } from 'ag-grid-enterprise/main';
import { appConfig } from './../app.config';
import { CoreModule } from '../core/index';
import { environment } from '../../environments/environment';
import { PanelBarModule } from '@progress/kendo-angular-layout';
import { PartnerAgencyDetailsComponent } from './components/daily-unit-grid/partner-agency-details/partner-agency-details.component';
import { AgencyStaffingConsoleComponent } from './components/agency-staffing-console/agency-staffing-console.component';
import { AgencyStaffingConsoleGridComponent } from './components/agency-staffing-console/agency-staffing-console-grid/agency-staffing-console-grid.component';
import { AgencyConsoleWeeklyCalenderComponent } from './components/agency-staffing-console/agency-console-weekly-calender/agency-console-weekly-calender.component';
import { RequestAgencyStaffComponent } from './components/request-agency-staff/request-agency-staff.component';
import { ShiftRequestDetailsComponent } from './components/request-agency-staff/shift-request-details/shift-request-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgencyDetailsComponent } from './components/request-agency-staff/agency-details/agency-details.component';
import { AddOpenShiftsV1Component } from './components/add-open-shifts/add-open-shifts-v1/add-open-shifts-v1.component';
import { AddOpenShiftsV2Component } from './components/add-open-shifts/add-open-shifts-v2/add-open-shifts-v2.component';
import { ScheduledRotationsComponent } from './components/scheduled-rotations/scheduled-rotations.component'
import { ScheduledRotationsToolbarComponent } from './components/scheduled-rotations/scheduled-rotations-toolbar/scheduled-rotations-toolbar.component';
import { ScheduledRotationsAvailShiftsComponent } from './components/scheduled-rotations/scheduled-rotations-avail-shifts/scheduled-rotations-avail-shifts.component';
import { ScheduledRotationsEmployeeGridComponent } from './components/scheduled-rotations/scheduled-rotations-employee-grid/scheduled-rotations-employee-grid.component';
import { TOOLBAR_SERVICE } from '../core/services';
import { EmployeeShiftsPipe } from './pipes/employee-shifts.pipe';
import { AddEmpPositionGridComponent } from './components/scheduled-rotations/scheduled-rotations-employee-grid/add-emp-position-grid/add-emp-position-grid.component';
import { ScheduledGenerateRotationComponent } from './components/scheduled-rotations/scheduled-generate-rotation/scheduled-generate-rotation.component';
import { HttpClientModule } from '@angular/common/http';
import { DailyPartialShiftsGridComponent } from './components/open-shift-management/daily-partial-shifts-grid/daily-partial-shifts-grid.component';
import { DailyUnitGridPartialshiftComponent } from './components/daily-unit-grid/daily-unit-grid-partialshift/daily-unit-grid-partialshift.component';
import { DailyUnitViewSettingsPopupComponent } from './components/daily-unit-grid/daily-unit-view-settings-popup/daily-unit-view-settings-popup.component';
import { DailyUnitFilterDialogComponent } from './components/daily-unit-grid/daily-unit-filter-dialog/daily-unit-filter-dialog.component';
LicenseManager.setLicenseKey(environment.AG_GRID_LICENCE);

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    OrganizationModule,
    ChannelModule.forRoot(appConfig.notificationsHubConfig),
    CommonAppModule,
    routesModule,
    FormsModule,
    ReactiveFormsModule,
    GridModule,
    ExcelModule,
    PDFModule,
    DropDownsModule,
    DialogsModule,
    EmployeeModule,
    BrowserAnimationsModule,
    EmployeeSectionsModule,
    MomentModule,
    NgxPopperModule,
    AgGridModule.withComponents([]),
    DateInputsModule,
    ComponentsLibraryModule,
    CoreModule,
    PanelBarModule
  ],
  declarations: [
    ...schedulerComponents,
    ...pipes,
    ...directives,
    PartnerAgencyDetailsComponent,
    AgencyStaffingConsoleComponent,
    AgencyStaffingConsoleGridComponent,
    AgencyConsoleWeeklyCalenderComponent,
    // ShiftDetailsGridComponent,
    RequestAgencyStaffComponent,
    ShiftRequestDetailsComponent,
    AgencyDetailsComponent,
    AddOpenShiftsV1Component,
    AddOpenShiftsV2Component,
    ScheduledRotationsComponent,
    ScheduledRotationsToolbarComponent,
    ScheduledRotationsAvailShiftsComponent,
    ScheduledRotationsEmployeeGridComponent,
    EmployeeShiftsPipe,
    AddEmpPositionGridComponent,
    DailyPartialShiftsGridComponent,
    DailyUnitGridPartialshiftComponent,
    DailyUnitViewSettingsPopupComponent,
    DailyUnitFilterDialogComponent
  ],
  entryComponents: [
    ...entrySchedulerComponent
  ],
  providers: [
    ...services,
    ...MASTER_SCHEDULE_ACTION_PROVIDERS,
    ...INDIVIDUAL_SCHEDULE_ACTION_PROVIDERS,
    ...EPIC_PROVIDERS,
    ...STORE_SELECTORS,
    { provide: TOOLBAR_SERVICE, useClass: MasterScheduleToolbarService }
  ],
  exports: [
    ...exportSchedulerComponents,
    ...exportDirectives
  ]
})
export class SchedulerModule {
  constructor(masterScheduleEpics: MasterScheduleEpics
    /*, private schedulerNotificationService: SchedulerNotificationService - TBD why was removed*/
  ) {
    rootEpics.push(masterScheduleEpics.entriesChange);
    rootEpics.push(masterScheduleEpics.fetchMasterScheduleData);
    rootEpics.push(masterScheduleEpics.fetchEmployeesSchedule);
    rootEpics.push(masterScheduleEpics.fetchTotals);
    rootEpics.push(masterScheduleEpics.completeDataLoading);
    rootEpics.push(masterScheduleEpics.completeLoading);
    rootEpics.push(masterScheduleEpics.resetPayCycle);

  }
}
