import {
  TimeclockRestrictionDefinition, TimeclockDefinition, EmployeeDefinition
} from '../../../organization/models/index';
import { TimeclockAssignmentContainer } from './timeclock-assignment-container';
import { TimeclockAssignmentEmployee } from './timeclock-assignment-employee';

export class TimeclockAssignmentState {
  public showUnassigned: boolean;
  public showAssigned: boolean;
  public resetGrid: boolean;
  public showRestrictions: TimeclockRestrictionDefinition[];
  public unassignedEmployees: number;
  public assignedEmployees: number;
  public timeclocks: TimeclockDefinition[];
  public totals: TimeclockRestrictionTotal[];
  public records: TimeclockAssignmentEmployee[];
  public selectedEmployees: EmployeeDefinition[];
}

export class TimeclockRestrictionTotal {
  public restriction: TimeclockRestrictionDefinition;
  public employeeCount: number;
  public isChecked: boolean;
}

export class AssignMode {
  public static assign: assignMode = 'assign';
  public static reassign: assignMode = 'reassign';
  public static changeRestriction: assignMode = 'changeRestriction';
  public static unassign: assignMode = 'unassign';
}
export type assignMode = 'assign' | 'reassign' | 'changeRestriction' | 'unassign';
