import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { PmManagementService } from './pm-management.service';
import { Assert } from './../../../framework/assert/assert';
import { DateRange } from '../../../core/models/index';
import { PerformanceManagementApiService } from './performance-management-api.service';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { unsubscribeAll } from '../../../core/decorators/index';
var PmEmployeeRosterManagementService = /** @class */ (function () {
    function PmEmployeeRosterManagementService(api, manService) {
        this.api = api;
        this.manService = manService;
        this.loading$ = new Subject();
        this.dataLoaded$ = new ReplaySubject();
        this.recordsLoaded$ = new Subject();
        this.dateRange$ = new Subject();
        this.exportTo$ = new Subject();
        this.createNewReview$ = new Subject();
        this.subscriptions = {};
    }
    PmEmployeeRosterManagementService.prototype.init = function () {
        var _this = this;
        this.manService.subscribeToLoadReviews(function () {
            _this.internalReloadRoster();
        });
        this.internalReloadRoster();
    };
    PmEmployeeRosterManagementService.prototype.setEmployeeId = function (employeeId) {
        this.currentEmpId = employeeId;
        this.internalReloadRoster();
    };
    PmEmployeeRosterManagementService.prototype.destroy = function () {
        this.loading$.complete();
        this.dataLoaded$.complete();
        this.recordsLoaded$.complete();
        this.dateRange$.complete();
        this.exportTo$.complete();
        this.createNewReview$.complete();
    };
    PmEmployeeRosterManagementService.prototype.loadEmployeeReviews = function (empId, start, end) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var container;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        return [4 /*yield*/, this.api.getEmployeeReviews(empId, start, end)];
                    case 1:
                        container = _a.sent();
                        this.employee = container.employee;
                        this.createNewReview$.next(container.canCreateNewReview);
                        this.dataLoaded$.next(container);
                        this.loading$.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    PmEmployeeRosterManagementService.prototype.reloadRoster = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.internalReloadRoster();
                return [2 /*return*/];
            });
        });
    };
    PmEmployeeRosterManagementService.prototype.getDefaultDateRange = function () {
        var d = new Date();
        return new DateRange(new Date(1900, 1, 1), d);
    };
    PmEmployeeRosterManagementService.prototype.subscribeToCanCreateNewReview = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.createNewReview$.subscribe(callback);
    };
    PmEmployeeRosterManagementService.prototype.openReviewPopup = function (review) {
        var title = (_.isObjectLike(review) ? 'Edit' : 'Create New') + " Review";
        if (review) {
            review.employee = this.employee || review.employee;
            this.manService.openReviewPopup(title, review);
        }
        else {
            this.manService.openEmployeNewReviewPopup(title, this.employee);
        }
    };
    PmEmployeeRosterManagementService.prototype.changeDateRange = function (r) {
        this.dateRange = r;
        this.dateRange$.next(r);
        if (_.isNumber(this.currentEmpId)) {
            this.loadEmployeeReviews(this.currentEmpId, this.dateRange.startDate, this.dateRange.endDate);
        }
    };
    PmEmployeeRosterManagementService.prototype.subscribeToDateRange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dateRange$.subscribe(callback);
    };
    PmEmployeeRosterManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    PmEmployeeRosterManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    PmEmployeeRosterManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    PmEmployeeRosterManagementService.prototype.subscribeToDataLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dataLoaded$.subscribe(callback);
    };
    PmEmployeeRosterManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    PmEmployeeRosterManagementService.prototype.internalReloadRoster = function () {
        if (this.currentEmpId) {
            this.loadEmployeeReviews(this.currentEmpId, null, null);
        }
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], PmEmployeeRosterManagementService.prototype, "subscriptions", void 0);
    return PmEmployeeRosterManagementService;
}());
export { PmEmployeeRosterManagementService };
