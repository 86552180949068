<form #form="ngForm" novalidate class="header-form">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 padding-b">
      <div class="box-item">
        <p class="total-counter">{{state.unassignedEmployees}}</p>
        <button type="button" (click)="onUnassigned()" [ngClass]="{'theme-button-apply': state.showUnassigned, 'theme-button-cancel': !state.showUnassigned}">Unassigned</button>
      </div>
      <div class="box-item">
        <p class="total-counter">{{state.assignedEmployees}}</p>
        <button type="button" (click)="onAssigned()" [ngClass]="{'theme-button-apply': state.showAssigned, 'theme-button-cancel': !state.showAssigned}">Assigned</button>
      </div>
    </div>
    <div *ngIf="state.showUnassigned" class="col-xs-12 col-sm-6 col-md-6 talign-r padding-b padding-t">
      <div class="box-item">
        <button type="button" (click)="onAssign()" [disabled]="hasSelectedWithoutBudges || !hasSelected" class="theme-button-apply selector-button">Assign</button>
        <span *ngIf="hasSelectedWithoutBudges" class="slx-error-block">Some employees without Badge Id!</span>
      </div>
      <div class="box-item">
        <button type="button" (click)="onGenerateBadge()" [disabled]="!hasSelected" class="theme-button-apply selector-button">Generate Badge ID</button>
      </div>
    </div>
    <div *ngIf="state.showAssigned" class="col-xs-12 col-sm-6 col-md-6 talign-r padding-b padding-t">
      <div class="box-item">
        <button type="button" (click)="onReAssign()" [disabled]="hasSelectedWithoutBudges || !hasSelected" class="theme-button-apply selector-button">Re-Assign</button>
        <span *ngIf="hasSelectedWithoutBudges" class="slx-error-block">Some employees without Badge Id!</span>
      </div>
      <div class="box-item">
        <button type="button" [disabled]="!hasSelected" (click)="onChangeRestriction()" class="theme-button-apply selector-button">Change Restriction</button>
      </div>
      <div class="box-item">
        <button type="button" [disabled]="!hasSelected" (click)="onUnAssign()" class="theme-button-apply selector-button">Unassign</button>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="state.showAssigned">
    <div class="col-xs-12">
      <p>Restriction Type</p>
      <div *ngFor="let restTotal of state.totals" class="box-item">
        <button type="button" class="selector-button" (click)="toggleRestriction(restTotal)" [ngClass]="{'theme-button-apply': restTotal.isChecked, 'theme-button-cancel': !restTotal.isChecked}">{{restTotal.restriction.name}}</button>
        <p class="total-counter">{{restTotal.employeeCount}}</p>
      </div>
    </div>
  </div>
</form>
