import { PairColorScheme } from '../../models/pair-color-scheme/pair-color-scheme';
import { ValuePairChartInput, ValuePairChartModel, ValuePairWidgetConfig, ValuePairChartSeriesInput, ValuePairChartSeriesModel } from '../../models/index';

import * as _ from 'lodash';

export class ValuePairChartDataCalculationService {

  public static getColor(input: ValuePairChartInput, config: ValuePairWidgetConfig): PairColorScheme {

    let actualPercent: number = (input.value1 / input.value2) * 100;

    if (input.value1 === input.value2) {
      return config.colorMatched;
    } else if (actualPercent >= 100 - input.lowerLimit && actualPercent <= 100 + input.upperLimit) {
      if (input.value1 < input.value2) {
        return config.colorUnderWithinLimits;
      } else {
        return config.colorOverWithinLimits;
      }
    } else if (input.value1 > input.value2) {
      return config.colorOver;
    } else if (input.value1 < input.value2) {
      return config.colorUnder;
    }

    return null;
  }

  public static createModel(input: ValuePairChartInput, config: ValuePairWidgetConfig): ValuePairChartModel {
    if (!input || !config) {
      return null;
    }

    const model = new ValuePairChartModel();
    const color = ValuePairChartDataCalculationService.getColor(input, config);

    model.input = input;
    model.color = color;
    model.additionalColor = model.color.fontColor1;

    if (input.value1 === input.value2) {
      model.value1 = 1;
      model.value2 = 0;

      return model;
    }

    const absValue1 = Math.abs(input.value1);
    const absValue2 = Math.abs(input.value2);

    const minAbsValue = Math.min(absValue1, absValue2);
    const maxAbsValue = Math.max(absValue1, absValue2);
    const absDifference = maxAbsValue - minAbsValue;

    const absDifferencePercentage = absDifference * 100 / maxAbsValue;
    const absMinValuePercentage = minAbsValue * 100 / maxAbsValue;

    if (input.value1 > input.lowerLimit && input.value1 < input.value2) {
      model.value1 = absDifferencePercentage;
      model.value2 = absMinValuePercentage;
    } else if (input.value1 < input.upperLimit && input.value1 > input.value2) {
      model.value1 = absMinValuePercentage;
      model.value2 = absDifferencePercentage;
    } else if (input.value1 > input.value2) {
      model.value1 = absDifferencePercentage;
      model.value2 = absMinValuePercentage;
    } else if (input.value1 < input.value2) {
      model.value1 = absDifferencePercentage;
      model.value2 = absMinValuePercentage;
    }

    return model;
  }

  public static createSeriesModel(seriesInput: ValuePairChartSeriesInput, config: ValuePairWidgetConfig): ValuePairChartSeriesModel {
    if (!seriesInput || !config) {
      return null;
    }

    const seriesModel = new ValuePairChartSeriesModel();

    seriesModel.input = seriesInput;
    seriesModel.color = seriesInput.seriesColor;
    seriesModel.label = seriesInput.seriesLabel;
    seriesModel.models = [];

    _.forEach(seriesInput.valuePairs, (chartInput: ValuePairChartInput) => {
      const model = ValuePairChartDataCalculationService.createModel(chartInput, config);
      seriesModel.models.push(model);
    });

    return seriesModel;
  }

}
