<slx-spinner [show]="isLoading">
    <div class="slx-high-box workers-compensation-configuration">

      <div class="slx-high-box__body">
        <form #form="ngForm" novalidate class="slx-full-height">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
          [data]="gridState?.view"
          [filterable]="'menu'"
          [sortable]="true"
          [filter]="gridState.state.filter"
          [sort]="gridState.state.sort"
          [selectable]="selectableSettings"
          (dataStateChange)="gridState.dataStateChange($event)"
          [pageSize]="pageSize"
          [pageable]="true"
          [skip]="gridState.state.skip"
        >

            <kendo-grid-column title="Employee" width="200" field="employeeName">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Employee</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a [employeeSectionNewWindowLink]="dataItem.employeeId">
                  {{dataItem.employeeName}}
                </a>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Position" width="180" field="position.name">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Position</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.position?.name }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Department" width="180" field="department.name">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Department</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.department?.name }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Type" width="130" field="type.name">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Type</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.type?.name }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Type" width="250" field="optionCode.code">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Option</span>
              </ng-template>
               <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <slx-dropdown-list class="slx-wide small"
                  #option="ngModel"
                  valueField="id"
                  titleField="code"
                  [options]="benefitOptionData"
                  [(ngModel)]="dataItem.optionCode"
                  (ngModelChange)="onOptionValueChange(dataItem)"
                  [required]="true"
                  name="option{{rowIndex}}"
                ></slx-dropdown-list>
                <span *ngIf="option.errors" class="slx-error-block error-box">
                  <span *ngIf="option.errors.required" errorMessage for="required"></span>
                </span>
              </ng-template>

            </kendo-grid-column>

          </kendo-grid>
        </form>
      </div>
    </div>
  </slx-spinner>
