import { IAgencyDefinition, AgencyDefinition } from '../../../organization/models/lookup/index';
import { IEmployeeShortInfo, EmployeeShortInfo } from '../../../organization/models/index';

export interface IAgencyEmployee {
  agency: IAgencyDefinition;
  employeeId: number;
  employeeFirstName: string;
  employeeLastName: string;
  email: string;
  phone: string;
  workDate: string;
  startDate: string;
  badgeId: string;
  prefix: string; //?tbd
  message?: string;
  employeeName:string;
}

export class AgencyEmployee {
  public agency: AgencyDefinition;
  public employeeId: number;
  public employeeFirstName: string;
  public employeeLastName: string;
  public email: string;
  public phone: string;
  public workDate: Date;
  public startDate: Date;
  public badgeId: string;
  public prefix: string; //?tbd
  public message: string;
  public employeeName:string;
}
