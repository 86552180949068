import { Component, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

import { LinePunch, EmployeeDailyPunches, DailyPunchesShift, PunchesEventFilter } from '../../../models/index';
import { DailyPunchesManagementService } from '../../../services/punches/daily-punches-management.service';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Lookup, Position, Shift, LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-punches-event-filter',
  templateUrl: 'punches-event-filter.component.html',
  styleUrls: ['punches-event-filter.component.scss']
})
export class PunchesEventFilterComponent implements OnDestroy {

  @Input()
  public records: LinePunch[];

  public filter: PunchesEventFilter;
  private filterControlKey = 'EmployeePunchesEventFilters';
  private filtersRestored: boolean = false;

  @unsubscribe()
  private stateInitSubscription: Subscription;

  constructor(private lookupService: LookupService, private managementService: DailyPunchesManagementService, private stateManagement: StateManagementService) {
    this.filter = new PunchesEventFilter();
    this.stateInitSubscription = this.stateManagement.onInit$.subscribe(() => {
      this.filter = this.restoreFilters();
      this.managementService.setFilter(this.filter);
      this.filtersRestored = true;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onFilterChanged(): void {
    if (!this.filtersRestored) {
      return;
    }
    this.managementService.applyFilter(this.records, this.filter);
    this.saveFilters(this.filter);
  }


  private saveFilters(filter: PunchesEventFilter): void {
    this.stateManagement.setControlState(this.filterControlKey, { value: filter }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd);
  }

  private restoreFilters(): PunchesEventFilter {
    let filters: PunchesEventFilter;
    let state = this.stateManagement.getControlState(this.filterControlKey);
    if (state && state.value !== undefined) {
      filters = state.value;
      return filters;
    }
    filters = new PunchesEventFilter();
    filters = new PunchesEventFilter();
    filters.empPunch = true;
    filters.editPunch = true;
    filters.essRequest = true;
    filters.invalidPunch = true;
    filters.invalidLogin = true;
    filters.schedule = true;
    filters.deleted = false;
    return filters;
  }
}
