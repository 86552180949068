export interface IBudgetedGroup {
  id: number;
  description: string;
  assignedPositionCount: number;
}

export class BudgetedGroup {
  public id: number;
  public description: string;
  public assignedPositionCount: number;
}
