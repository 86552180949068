import { Organization, IOrganization } from '../../../../organization/models/index';

export interface IPayrollExportFacility {
  organization: IOrganization;
  submittedDate: string;
  submittedBy: string; // <-- TBD can we use get user_id from DB
  releaseDate: string;
  releasedBy: string; // <-- TBD can we use get user_id from DB
  isSubmitted: boolean;
  isReleased: boolean;
}

export class PayrollExportFacility {
  public organization: Organization;
  public submittedDate: Date;
  public submittedBy: string;
  public releaseDate: Date;
  public releasedBy: string;
  public isSubmitted: boolean;
  public isReleased: boolean;
  public exportDate: Date;
}
