
export interface IScheduleDefinition {
  startTime: string;
  endTime: string;
  hours: number;
}

export class ScheduleDefinition {
  startTime: Date;
  endTime: Date;
  hours: number;
}
