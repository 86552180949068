import { ElementRef, SimpleChanges, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Assert } from '../../../framework/assert/assert';
import { dateTimeUtils } from '../../../common/utils/index';
import { appConfig } from '../../../app.config';
var MomentTimeInputComponent = /** @class */ (function () {
    function MomentTimeInputComponent(elementRef) {
        this.placeholder = 'Time';
        this.onDateChanged = new EventEmitter();
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.timeMaskConf = {
            mask: function (value) {
                var numberMatch = value.match(/\d/g);
                var numberLength = numberMatch ? numberMatch.join('').length : 0;
                var patterns = [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /(a|A|p|P)/, /(m|M)/];
                if (numberLength > 1) {
                    if (parseInt(numberMatch[0]) === 1) {
                        patterns.splice(1, 1, /[0-2]/);
                        return patterns;
                    }
                }
                return patterns;
            },
            guide: true,
            keepCharPositions: true
        };
        this.hasFocus = false;
    }
    Object.defineProperty(MomentTimeInputComponent.prototype, "readonly", {
        get: function () {
            return !this.inEdit;
        },
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MomentTimeInputComponent.prototype, "value", {
        get: function () {
            return this.internalValue;
        },
        set: function (v) {
            if (v !== this.internalValue) {
                this.internalValue = v ? v : null;
                this.internalMoment = v ? moment(v) : null;
                if (this.internalValue)
                    this.originalValue = this.internalValue;
                if (this.internalMoment) {
                    this.timeString = this.internalMoment.format(appConfig.timeFormat);
                }
                else {
                    this.timeString = '';
                }
                this.onChangeCallback(this.internalValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    MomentTimeInputComponent.prototype.ngOnInit = function () {
        this.initialized = true;
    };
    MomentTimeInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    MomentTimeInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
        if (this.model && this.field) {
            this.model[this.field] = this.internalValue;
        }
    };
    MomentTimeInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    MomentTimeInputComponent.prototype.onTimeChange = function (time) {
        this.timeString = time;
        if (time !== '') {
            if (!this.internalMoment || !this.internalValue) {
                if (this.originalValue) {
                    this.internalMoment = moment(this.originalValue);
                    this.internalValue = this.internalMoment.toDate();
                }
                else {
                    this.internalMoment = moment();
                    this.internalValue = this.internalMoment.toDate();
                }
            }
            dateTimeUtils.setTimeToMoment(this.internalMoment, this.timeString);
        }
        else {
            if (!this.protectDate) {
                this.internalMoment = null;
                this.internalValue = null;
            }
        }
        if (this.internalMoment)
            this.internalValue = this.internalMoment.toDate();
        if (this.onChangeCallback)
            this.onChangeCallback(this.internalValue);
        /*
        if (this.model && this.field) {
          if (this.internalMoment) this.internalValue = this.internalMoment.toDate();
          this.model[this.field] = this.internalValue;
        } else {
          if (this.internalValue) this.internalValue.setUTCMilliseconds(this.internalMoment.unix());
        }
        */
        this.onDateChanged.emit(this.internalValue);
    };
    MomentTimeInputComponent.prototype.writeValue = function (v) {
        if (v === this.internalValue)
            return;
        this.internalValue = v ? v : null;
        if (this.internalValue)
            this.originalValue = this.internalValue;
        if (v === undefined || v === null) {
            this.internalMoment = null;
            this.timeString = '';
            this.inputComponent.nativeElement.value = this.timeString;
            return;
        }
        if (!(v instanceof Date))
            throw new Error('MomentTimeInput requires Date as a value');
        this.internalMoment = v ? moment(v) : null;
        this.timeString = this.internalMoment.format(appConfig.timeFormat);
        this.inputComponent.nativeElement.value = this.timeString;
    };
    MomentTimeInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    MomentTimeInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    MomentTimeInputComponent.prototype.prepareValue = function (time, originalValue) {
        return dateTimeUtils.setTime(originalValue, time, true);
    };
    Object.defineProperty(MomentTimeInputComponent.prototype, "$element", {
        get: function () {
            return $(this.elementRef.nativeElement);
        },
        enumerable: true,
        configurable: true
    });
    return MomentTimeInputComponent;
}());
export { MomentTimeInputComponent };
