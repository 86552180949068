/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mass-assignment-payunits-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../mass-assignment-payunits/mass-assignment-payunits.component.ngfactory";
import * as i3 from "../mass-assignment-payunits/mass-assignment-payunits.component";
import * as i4 from "../../services/mass-assignment/pay-units-mass-assignment-api.service";
import * as i5 from "../../services/mass-assignment/pay-units-mass-assignment-map.service";
import * as i6 from "../../../../organization/services/lookup/lookup.service";
import * as i7 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i8 from "../../../../common/services/modal/modal.service";
import * as i9 from "./mass-assignment-payunits-dialog.component";
import * as i10 from "../../../../common/models/dialog-options";
import * as i11 from "../../models/mass-assignment/mass-assign-pay-units-request";
var styles_MassAssignmentPayUnitsDialogComponent = [i0.styles];
var RenderType_MassAssignmentPayUnitsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MassAssignmentPayUnitsDialogComponent, data: {} });
export { RenderType_MassAssignmentPayUnitsDialogComponent as RenderType_MassAssignmentPayUnitsDialogComponent };
export function View_MassAssignmentPayUnitsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dialog-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-mass-assignment-payunits", [], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.onClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MassAssignmentPayUnitsComponent_0, i2.RenderType_MassAssignmentPayUnitsComponent)), i1.ɵdid(2, 770048, null, 0, i3.MassAssignmentPayUnitsComponent, [i4.PayUnitsMassAssignmentApiService, i5.PayUnitsMassAssignmentMapService, i6.LookupService, i1.ChangeDetectorRef, i7.NotificationsService, i8.ModalService], { request: [0, "request"] }, { onClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.request; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MassAssignmentPayUnitsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-mass-assignment-payunits-dialog", [], null, null, null, View_MassAssignmentPayUnitsDialogComponent_0, RenderType_MassAssignmentPayUnitsDialogComponent)), i1.ɵdid(1, 245760, null, 0, i9.MassAssignmentPayUnitsDialogComponent, [i10.DialogOptions, i8.ModalService, i11.MassAssignPayUnitsRequest], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MassAssignmentPayUnitsDialogComponentNgFactory = i1.ɵccf("slx-mass-assignment-payunits-dialog", i9.MassAssignmentPayUnitsDialogComponent, View_MassAssignmentPayUnitsDialogComponent_Host_0, {}, {}, []);
export { MassAssignmentPayUnitsDialogComponentNgFactory as MassAssignmentPayUnitsDialogComponentNgFactory };
