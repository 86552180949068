import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../framework/index';
import { OrgLevel } from '../../../state-model/models/index';

import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { Position, PositionGroup, LookupType, Lookup } from '../../../organization/models/index';

import { LookupService } from '../../../organization/services/index';

import { ManageGroupsApiService } from './manage-groups-api.service';
import { ManageGroupsMapService } from './manage-groups-map.service';

import { PositionGroupView } from '../../models/index';

@Injectable()
export class ManageGroupsManagementService {
  public readonly defaultGroupId = -1;
  public readonly defaultGroupName = 'Unassigned Group';

  private orgLevel: OrgLevel;
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  private loading$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private groups$ = new ReplaySubject<PositionGroupView[]>(1);
  private positions$ = new ReplaySubject<Lookup<Position>>(1);

  constructor(
    private lookup: LookupService,
    private apiService: ManageGroupsApiService,
    private mapService: ManageGroupsMapService
  ) {}

  public init(): void {
    this.subscribeToOrgLevelChanges();
  }

  public destroy(): void {
    this.loading$.complete();
    this.orgLevelChanged$.complete();
    this.groups$.complete();
    this.positions$.complete();
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoading(callback: (o: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToLoadGroups(callback: (o: PositionGroupView[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.groups$.subscribe(callback);
  }

  public subscribeToLoadPositions(callback: (o: Lookup<Position>) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.positions$.subscribe(callback);
  }

  public filteringGroups(g: PositionGroupView[], srcText: string): PositionGroupView[] {
    const groups = _.cloneDeep(g);
    if (_.size(srcText) > 0) {
      const searchText = _.toLower(srcText);
      return _.reduce(groups, (accum, groupView) => {
        const isDefaultGroup = groupView.id === this.defaultGroupId;
        const positions = _.filter(groupView.positions, (pos) => _.includes(_.toLower(pos.name), searchText));
        if (isDefaultGroup || !isDefaultGroup && _.size(positions) > 0) {
          accum.push(groupView);
          groupView.positions = positions;
        }
        return accum;
      }, [] as PositionGroupView[]);
    }
    return groups;
  }

  public async assignGroupsToPositions(group: PositionGroupView, position: Position): Promise<void> {
    let result: any = null;
    this.loading$.next(true);
    try {
      position.positionGroupId = group.id === this.defaultGroupId ? null : group.id;
      const groups = this.mapService.mapToGroupsWithPositions(group, position, this.defaultGroupId, this.defaultGroupName);
      result = await this.apiService.assignGroupsToPositions(this.orgLevel.id, groups);
    } finally {
      this.loading$.next(false);

      return result;
    }
  }

  public async createGroup(g: PositionGroupView): Promise<PositionGroupView> {
    let groupView: PositionGroupView = null;
    this.loading$.next(true);
    try {
      const posGroup = await this.apiService.createGroup(this.orgLevel.id, g);
      groupView = this.mapService.makePosViewOfPosGroup(posGroup);
    } finally {
      this.loading$.next(false);

      return groupView;
    }
  }

  public async updateGroup(g: PositionGroupView): Promise<PositionGroupView> {
    let groupView: PositionGroupView = null;
    this.loading$.next(true);
    try {
      const posGroup = await this.apiService.updateGroup(this.orgLevel.id, g);
      groupView = this.mapService.makePosViewOfPosGroup(posGroup);
    } finally {
      this.loading$.next(false);

      return groupView;
    }
  }

  public async deleteGroup(group: PositionGroupView): Promise<void> {
    let result: any = null;
    this.loading$.next(true);
    try {
      result = await this.apiService.deleteGroup(this.orgLevel.id, group.id);
    } finally {
      this.loading$.next(false);

      return result;
    }
  }

  public createGroupView(): PositionGroupView {
    return this.mapService.createGroupView(null, '', this.orgLevel.relatedItemId);
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe((o: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === o.id) return;

        this.orgLevel = o;
        this.loadData();
        this.orgLevelChanged$.next(this.orgLevel);
      });
  }

  private async loadData(): Promise<void> {
    const lookups = await this.loadLookups();
    const groups = this.mapService.mapToPositionGroupView(lookups[0], lookups[1], this.defaultGroupId, this.defaultGroupName);
    this.groups$.next(groups);
    this.positions$.next(lookups[0]);
  }

  private async loadLookups(): Promise<[Lookup<Position>, Lookup<PositionGroup>]> {
    this.loading$.next(true);
    const lookups = await Promise.all([
      this.lookup.getLookup({ lookupType: LookupType.position, orgLevelId: this.orgLevel.id, updateCacheForced: true }),
      this.lookup.getLookup({ lookupType: LookupType.positionGroups, orgLevelId: this.orgLevel.id, updateCacheForced: true })
    ]);
    this.loading$.next(false);

    return lookups;
  }
}
