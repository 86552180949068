import * as tslib_1 from "tslib";
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../configuration.config';
import { ApiUtilService } from '../../../common/index';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';
import { GeolocationMapService } from './geolocation-map.service';
var GeolocationApiService = /** @class */ (function () {
    function GeolocationApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    GeolocationApiService.prototype.getEntities = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getGeoApi() + "?orgLevelId=" + orgLevelId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapEntities(response.data); })];
            });
        });
    };
    GeolocationApiService.prototype.saveEntity = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getGeoApi();
                request = this.urlParamsService.createPostRequest(url, this.mapService.mapNewEntityToDTO(entity));
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    GeolocationApiService.prototype.udateEntity = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getGeoApi();
                request = this.urlParamsService.createPutRequest(url, this.mapService.mapExistingEntityToDTO(entity));
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    GeolocationApiService.prototype.deleteEntity = function (entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getGeoApi() + "/" + entity.id;
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return response.data; })];
            });
        });
    };
    GeolocationApiService.prototype.getGeoApi = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.configuration.root +
            ("/" + configurationConfig.api.configuration.geolocation.root);
    };
    GeolocationApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return GeolocationApiService;
}());
export { GeolocationApiService };
