import { PopoverContentComponent } from './../../../../common/components/popover-content/popover-content.component';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';

import { EmployeeCostCenterAllocation } from './../../models/index';
import { LookupApiService, CostCenter } from '../../../../organization/index';

@Component({
  moduleId: module.id,
  selector: 'slx-cost-center-split',
  templateUrl: 'cost-center-split.component.html',
  styleUrls: ['cost-center-split.component.scss']
})
export class CostCenterSplitComponent implements OnInit {
  public DEFAULT_COUNT: number = 5;
  public MAX_COUNT: number = 10;

  @Input()
  public set allocations(value: EmployeeCostCenterAllocation[]) {
    if (!value) {
      value = [];
    }

    for(let i: number = value.length; i < this.DEFAULT_COUNT; i++) {
      value.push(new EmployeeCostCenterAllocation());
    }

    this._allocations = value;
    this.validate();
  }

  public get allocations(): EmployeeCostCenterAllocation[] {
    return this._allocations;
  }

  @Input()
  public employeeId: number;

  @Output()
  public onChangeAllocations = new EventEmitter<boolean>();


  public percentageValid = true;
  public uniquenessValid = true;
  public currentSum: number = 0;
  public costCenters: CostCenter[] = [];
  private _allocations: EmployeeCostCenterAllocation[];

  constructor(private lookupApiService: LookupApiService) {}

  public ngOnInit(): void {
    this.lookupApiService.getCostCenters(this.employeeId).then((costCenters) => {
      this.costCenters = costCenters;
      this.validate();
    });
  }

  public showCostCenterCode(): void {
    //2
  }
  public addRowIfNeed(): void {
    if (this.allocations.length < this.MAX_COUNT) {
      const allFull: boolean = _.every(this.allocations, (value: EmployeeCostCenterAllocation) => {
        return value.costCenter && value.percentage > 0;
      });

      if (allFull) {
        this.allocations.push(new EmployeeCostCenterAllocation());
      }
    }
    this.validate();
  }

  public onClickDelete(confirmation: boolean, index: number, costPopover: PopoverContentComponent): void {
    if (confirmation == true) {
      this.deleteCostCenter(index);
    }
    else {
      costPopover.hide();
    }
  }
  private deleteCostCenter(index: number): void {
    _.pullAt(this.allocations, index);
    for (let i: number = this.allocations.length; i < this.DEFAULT_COUNT; i++) {
      this.allocations.push(new EmployeeCostCenterAllocation());
    }
    this.validate();
  }

  private validate(): void {
    this.validatePercentage();
    this.validateAllocation();
    this.onChange();
  }

  private validatePercentage(): void {
    const realAllocations = _.filter(this.allocations, a => _.isObjectLike(a.costCenter));
    this.currentSum = _.sumBy(realAllocations, a => a.percentage || 0);
    this.percentageValid = this.currentSum === 100 || _.size(realAllocations) === 0;
  }

  private validateAllocation(): void {
    const realAllocations = _.filter(this.allocations, a => _.isObjectLike(a.costCenter));
    const uniqueCodes = _.uniqBy(realAllocations, a => a.costCenter.code);
    this.uniquenessValid =  _.size(realAllocations) === _.size(uniqueCodes);
  }

  private onChange(): void {
    this.onChangeAllocations.emit(this.percentageValid && this.uniquenessValid);
  }
}
