import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { MetaMapService } from '../../../core/services/index';
import { FieldsMeta, Actions } from '../../../core/models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { CostCenterModel, ICostCenterDTO, CostCentersConfigurationContainer } from '../../models/index';
import { Department, Position, LocationUnit } from '../../../organization/models/index';

@Injectable()
export class CostCentersMapService {

    public departments: Department[];
    public positions: Position[];
    public units: LocationUnit[];

    constructor(private metaMapService: MetaMapService) { }

    public mapToContainer(data: ICostCenterDTO[], meta: FieldsMeta): CostCentersConfigurationContainer {
        let container: CostCentersConfigurationContainer = new CostCentersConfigurationContainer();
        let actions: Actions = new Actions();
        //actions = this.metaMapService.mapActions(meta);
        actions.canAdd = actions.canEdit = actions.canDelete = true;
        container.actions = actions;
        container.records = this.mapToModels(data, meta);
        return container;
    }

    public mapToModels(dtos: ICostCenterDTO[], meta: FieldsMeta): CostCenterModel[] {
        let models: CostCenterModel[] = [];
        _.each(dtos, (dto: ICostCenterDTO) => {
            let model: CostCenterModel = this.mapToModel(dto, meta);
            models.push(model);

        });
        return models;
    }

    public mapToDtos(models: CostCenterModel[]): ICostCenterDTO[] {
        let dtos: ICostCenterDTO[] = [];
        _.each(models, (model: CostCenterModel) => {
            let dto: ICostCenterDTO = this.mapToDto(model);
            dtos.push(dto);
        });
        return dtos;
    }

    public mapToModel(dto: ICostCenterDTO, meta?: FieldsMeta): CostCenterModel {

        let model: CostCenterModel = new CostCenterModel();
        model.id = dto.id;
        model.code = dto.code;
        model.department = _.find(this.departments, (d: Department) => d.id === dto.department);
        model.description = dto.description;
        model.position = _.find(this.positions, (p: Position) => p.id === dto.jobCode);
        model.lineNumber = dto.lineNumber;
        model.unit = _.find(this.units, (u: LocationUnit) => u.id === dto.unit);
        model.employeeCount = dto.employeeCount;
        model.updatedBy = dto.updatedBy;
        model.updatedOn = dateTimeUtils.convertFromDtoString(dto.updatedOn);
        return model;
    }

    public cloneCollection(source: CostCenterModel[]): CostCenterModel[] {
        let collection: CostCenterModel[] = [];
        _.each(source, (model: CostCenterModel) => {
            let clonedModel: CostCenterModel = this.mapToModel(this.mapToDto(model));
            collection.push(clonedModel);
        });
        return collection;
    }

    public mapToDto(model: CostCenterModel): ICostCenterDTO {
        let dto: ICostCenterDTO = {
            id: model.id,
            code: model.code,
            department: model.department.id,
            description: model.description,
            jobCode: model.position ? model.position.id : 0,
            lineNumber: model.lineNumber,
            unit: model.unit ? model.unit.id : 0,
            updatedOn: dateTimeUtils.convertToDtoString(model.updatedOn),
            updatedBy: model.updatedBy,
            employeeCount: model.employeeCount
        };
        return dto;
    }



}
