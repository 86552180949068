import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericListRequest, GenericFieldAlignment } from '../../../../organization/models/generic-list/index';
import { appConfig } from '../../../../app.config';
import { genericListConfig } from '../../../../organization/generic-list.config';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { IDateRange } from '../../../../core/models/index';
import { StateManagementService } from './../../../../common/services/state-management/state-management.service';
import { DirectCareHoursManagementService } from '../../services';
import { GenericGridComponent } from '../../../../organization';
import { genericGridConfig } from '../../../../organization/components/generic-list/generic-grid/generic-grid.config';
var DirectCareHoursComponent = /** @class */ (function () {
    function DirectCareHoursComponent(managementService, genericListManagementService, stateManagement) {
        this.managementService = managementService;
        this.genericListManagementService = genericListManagementService;
        this.stateManagement = stateManagement;
        this.state = {
            isLoading: false,
            definitionsLoaded: false
        };
    }
    DirectCareHoursComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.genericGridConfig = _.cloneDeep(genericGridConfig);
        this.genericGridConfig.pdfExport.autoscale = true;
        this.appConfig = appConfig;
        this.stateManagement.init('DirectCareHoursComponent', false);
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.application)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentApplication = application;
            _this.loadDefinitions();
        });
        this.loadSubscription = this.stateManagement.loadData$.subscribe(function () {
            _this.loadValues();
        });
        this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe(function (defs) {
            _this.state.definitionsLoaded = true;
            _this.stateManagement.onComponentActiveStateChanged({});
        });
        this.definitionsPrepareSubscription = this.genericListManagementService.onDefinitionsPrepare$.subscribe(function (defs) {
            if (defs) {
                _.each(defs.fields, function (x) {
                    if (x.fieldType === 'number' || x.fieldType === 'int') {
                        x.align = 'right';
                    }
                });
            }
        });
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe(function (list) {
            _this.state.isLoading = false;
            _this.stateManagement.loadedData({});
        });
        this.datesChanged = this.managementService.subscribeToDateRange(function (range) {
            _this.startDate = range.startDate;
            _this.endDate = range.endDate;
            _this.loadValues();
        });
        this.managementService.subscribeToExport(function (isPdf) {
            var start = moment(_this.startDate).format(appConfig.dateFormat);
            var end = moment(_this.endDate).format(appConfig.dateFormat);
            _this.genericGridConfig.pdfExport.fileName = "DirectCareHours_" + start + "-" + end + ".pdf";
            _this.genericGridConfig.excelExport.fileName = "DirectCareHours_" + start + "-" + end + ".xlsx";
            if (isPdf) {
                _this.grid.exportToPdf();
            }
            else {
                _this.grid.exportToExcel();
            }
        });
    };
    DirectCareHoursComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DirectCareHoursComponent.prototype.loadDefinitions = function () {
        this.state.isLoading = true;
        this.state.definitionsLoaded = false;
        this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.directCareHours, false);
    };
    DirectCareHoursComponent.prototype.loadValues = function () {
        if (this.state.definitionsLoaded) {
            this.state.isLoading = true;
            var req = new GenericListRequest();
            req.startDate = this.startDate;
            req.endDate = this.endDate;
            this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
        }
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], DirectCareHoursComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], DirectCareHoursComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DirectCareHoursComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DirectCareHoursComponent.prototype, "definitionsPrepareSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DirectCareHoursComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DirectCareHoursComponent.prototype, "valuesLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DirectCareHoursComponent.prototype, "datesChanged", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DirectCareHoursComponent.prototype, "loadSubscription", void 0);
    return DirectCareHoursComponent;
}());
export { DirectCareHoursComponent };
