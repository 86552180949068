import { Component, ViewEncapsulation, ViewContainerRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { appConfig } from '../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-app',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent {
  public options = appConfig.uiNotificationOptions;
  constructor(public viewContainerRef: ViewContainerRef) {
    console.log('Environment config', environment);
  }
}
