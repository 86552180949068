/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./master-shedule.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./master-schedule-header/master-schedule-header.component.ngfactory";
import * as i5 from "./master-schedule-header/master-schedule-header.component";
import * as i6 from "../../../organization/services/lookup/lookup-api.service";
import * as i7 from "../../services/schedule/schedule-api.service";
import * as i8 from "../../../common/services/modal/modal.service";
import * as i9 from "../../services/schedule/master-schedule-management.service";
import * as i10 from "@angular/router";
import * as i11 from "../../../app-settings/services/app-settings-manage.service";
import * as i12 from "../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i13 from "../../../organization/services/schedule/schedule-cycle-helper.service";
import * as i14 from "../../../core/services/toolbar/toolbar-base.service";
import * as i15 from "../../../common/services/state-management/state-management.service";
import * as i16 from "../../services/schedule/master-schedule-quick-edit.service";
import * as i17 from "../../services/schedule/auto-scheduling.service";
import * as i18 from "../../services/schedule-entry/schedule-entry-api.service";
import * as i19 from "./master-schedule-grid/master-schedule-grid.component.ngfactory";
import * as i20 from "./master-schedule-grid/master-schedule-grid.component";
import * as i21 from "../../store/master-schedule/master-schedule.actions";
import * as i22 from "../../../portal/actions/sidebar.actions";
import * as i23 from "../../../organization/services/lookup/lookup.service";
import * as i24 from "../../../common/services/change-management/change-management.service";
import * as i25 from "../../../core/services/session/session.service";
import * as i26 from "../../../core/services/breadcrumb/breadcrumb-state.service";
import * as i27 from "../../services/individual-schedule/individual-schedule-api.service";
import * as i28 from "../../services/schedule/schedule-map.service";
import * as i29 from "../../../organization/services/org-level/org-level-watch.service";
import * as i30 from "../../services/schedule/master-schedule-toolbar.service";
import * as i31 from "../../store/master-schedule/master-schedule-filter.actions";
import * as i32 from "../../store/selectors/employees.selector";
import * as i33 from "../../../organization/services/accessible/menu-accessible-provider/menu-accessible-provider.service";
import * as i34 from "../../../common/services/component-state/component-state-storage.service";
import * as i35 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i36 from "./master-shedule.component";
var styles_MasterScheduleComponent = [i0.styles];
var RenderType_MasterScheduleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MasterScheduleComponent, data: {} });
export { RenderType_MasterScheduleComponent as RenderType_MasterScheduleComponent };
export function View_MasterScheduleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { modalAnchor: 0 }), i1.ɵqud(402653184, 2, { masterScheduleGridComponent: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 6, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(3, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "div", [["class", "slx-high-box scheduler master-scheduler-component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-master-schedule-header", [["class", "slx-content-toolbar-indents"]], null, [[null, "onExportSchedule"], [null, "onFetchSchedule"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onExportSchedule" === en)) {
        var pd_0 = (_co.onExport() !== false);
        ad = (pd_0 && ad);
    } if (("onFetchSchedule" === en)) {
        var pd_1 = (_co.onFetchSchedule($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_MasterScheduleHeaderComponent_0, i4.RenderType_MasterScheduleHeaderComponent)), i1.ɵdid(6, 245760, null, 0, i5.MasterScheduleHeaderComponent, [i6.LookupApiService, i7.ScheduleApiService, i8.ModalService, i9.MasterScheduleManagementService, i10.Router, i11.AppSettingsManageService, i10.ActivatedRoute, i12.NotificationsService, i13.ScheduleCycleHelperService, i14.TOOLBAR_SERVICE, i15.StateManagementService, i16.MasterScheduleQuickEditService, i17.MasterScheduleAutoSchedulingService, i18.ScheduleEntryApiService], null, { onExportSchedule: "onExportSchedule", onFetchSchedule: "onFetchSchedule" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-master-schedule-grid", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, i19.View_MasterScheduleGridComponent_0, i19.RenderType_MasterScheduleGridComponent)), i1.ɵdid(8, 4440064, [[2, 4]], 0, i20.MasterScheduleGridComponent, [i8.ModalService, i1.ChangeDetectorRef, i6.LookupApiService, i21.MasterScheduleActions, i9.MasterScheduleManagementService, i1.ElementRef, i10.Router, i10.ActivatedRoute, i22.SidebarActions, i14.TOOLBAR_SERVICE, i16.MasterScheduleQuickEditService, i23.LookupService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
export function View_MasterScheduleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "slx-master-shedule", [], null, null, null, View_MasterScheduleComponent_0, RenderType_MasterScheduleComponent)), i1.ɵprd(4608, null, i24.ChangeManagementService, i24.ChangeManagementService, [i8.ModalService, i25.SessionService, i26.BreadcrumbStateService]), i1.ɵprd(4608, null, i16.MasterScheduleQuickEditService, i16.MasterScheduleQuickEditService, [i27.IndividualScheduleApiService, i28.ScheduleMapService, i9.MasterScheduleManagementService, i24.ChangeManagementService, i8.ModalService, i11.AppSettingsManageService, i29.OrgLevelWatchService, i6.LookupApiService]), i1.ɵprd(512, null, i14.TOOLBAR_SERVICE, i30.MasterScheduleToolbarService, []), i1.ɵprd(512, null, i9.MasterScheduleManagementService, i9.MasterScheduleManagementService, [i7.ScheduleApiService, i28.ScheduleMapService, i21.MasterScheduleActions, i31.MasterScheduleFilterActions, i32.EmployeesSelector, i6.LookupApiService, i13.ScheduleCycleHelperService, i33.MenuAccessibleProviderService, i14.TOOLBAR_SERVICE]), i1.ɵprd(512, null, i15.StateManagementService, i15.StateManagementService, [i34.ComponentStateStorageService, i35.ColumnSettingsStorageService]), i1.ɵdid(6, 245760, null, 0, i36.MasterScheduleComponent, [i9.MasterScheduleManagementService, i15.StateManagementService, i35.ColumnSettingsStorageService, i21.MasterScheduleActions, i14.TOOLBAR_SERVICE, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, null); }
var MasterScheduleComponentNgFactory = i1.ɵccf("slx-master-shedule", i36.MasterScheduleComponent, View_MasterScheduleComponent_Host_0, {}, { onAction: "onAction" }, []);
export { MasterScheduleComponentNgFactory as MasterScheduleComponentNgFactory };
