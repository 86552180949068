import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SlateMessageGroupInfo } from '../models/slate-message-group-info';


@Injectable({
  providedIn: 'root'
})
export class SlateMessagesManagementService {
  public selectedMessage$ = new BehaviorSubject(null);
  public isMessagesDataUpdated$ = new BehaviorSubject(null);

  public destroy(): void {
    this.selectedMessage$.complete();
    this.isMessagesDataUpdated$.complete();
  }

  public updateSelectedmessage(message: SlateMessageGroupInfo) {
    this.selectedMessage$.next(message);
  }

  public updateMessagesData() {
    this.isMessagesDataUpdated$.next(true);
  }
}

