import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../utils/dateTimeUtils';
var DateTimeService = /** @class */ (function () {
    function DateTimeService() {
    }
    DateTimeService.prototype.GetDiffSeconds = function (startDate, endDate) {
        var startDateMoment = moment(startDate);
        var endDateMoment = moment(endDate);
        if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
            return 0;
        }
        if (endDateMoment.isBefore(startDateMoment)) {
            return 0;
        }
        return endDateMoment.diff(startDateMoment, 'seconds');
    };
    DateTimeService.prototype.GetDiffDays = function (startDate, endDate) {
        var startDateMoment = moment(startDate);
        var endDateMoment = moment(endDate);
        if (!startDateMoment.isValid() || !endDateMoment.isValid()) {
            return 0;
        }
        if (endDateMoment.isBefore(startDateMoment)) {
            return 0;
        }
        return endDateMoment.diff(startDateMoment, 'days');
    };
    Object.defineProperty(DateTimeService.prototype, "GetMinDate", {
        get: function () {
            return new Date(-8640000000000000);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeService.prototype, "GetMaxDate", {
        get: function () {
            return new Date(3453224400000);
        },
        enumerable: true,
        configurable: true
    });
    DateTimeService.prototype.getDateTimeFromTime = function (refDate, militaryTime) {
        return moment(refDate.format(appConfig.dateFormat) + " " + militaryTime, appConfig.dateFormat + "  " + appConfig.militaryTimeFormat);
    };
    DateTimeService.prototype.getDateRangeFromTimeRange = function (refDate, startTime, endTime) {
        var startDate = this.getDateTimeFromTime(refDate, startTime);
        var endDate = this.getDateTimeFromTime(refDate, endTime);
        if (startDate.isAfter(endDate)) {
            endDate.add(1, 'day');
        }
        return { start: startDate, end: endDate };
    };
    DateTimeService.prototype.isDateRangeOverlapped = function (startDate1, endDate1, startDate2, endDate2, strict) {
        if (strict === void 0) { strict = true; }
        if (strict) {
            return startDate1.isSameOrBefore(endDate2) && endDate1.isSameOrAfter(startDate2);
        }
        return startDate1.isBefore(endDate2) && endDate1.isAfter(startDate2);
    };
    DateTimeService.prototype.militaryTimeToLocaTime = function (militaryTime) {
        return moment(militaryTime, appConfig.militaryTimeFormat).format(appConfig.timeFormat);
    };
    DateTimeService.prototype.setTime = function (date, localTime) {
        var time = moment(localTime, appConfig.timeFormat);
        return moment(date).hour(time.hour()).minute(time.minute()).second(time.second()).toDate();
    };
    DateTimeService.prototype.setTimeToMoment = function (mnt, localTime) {
        dateTimeUtils.setTimeToMoment(mnt, localTime);
    };
    DateTimeService.prototype.isAfter = function (militaryTime1, militaryTime2) {
        return moment(militaryTime1, appConfig.militaryTimeFormat).isAfter(moment(militaryTime2, appConfig.militaryTimeFormat));
    };
    DateTimeService.prototype.isBefore = function (militaryTime1, militaryTime2) {
        return moment(militaryTime1, appConfig.militaryTimeFormat).isBefore(moment(militaryTime2, appConfig.militaryTimeFormat));
    };
    DateTimeService.prototype.getDuration = function (start, end) {
        return moment().startOf('date').add(end.diff(start)).format(appConfig.militaryTimeFormat);
    };
    DateTimeService.prototype.durationToHours = function (duration) {
        return moment.duration(duration).asHours();
    };
    DateTimeService.prototype.hoursToDuration = function (hours) {
        var duration = moment.duration(hours, 'hours');
        return duration.hours() + ":" + duration.minutes();
    };
    DateTimeService.prototype.convertToUTC = function (date) {
        return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), 0));
    };
    DateTimeService.prototype.formatDurationDecimal = function (d) {
        return dateTimeUtils.formatDurationDecimal(d);
    };
    DateTimeService.prototype.formatDuration = function (d) {
        return dateTimeUtils.formatDuration(d);
    };
    DateTimeService.prototype.durationToMiliseconds = function (d) {
        return d.asMilliseconds();
    };
    DateTimeService.prototype.convertToDtoDateTimeString = function (d) {
        return dateTimeUtils.convertToDtoDateTimeString(d);
    };
    return DateTimeService;
}());
export { DateTimeService };
