/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inactivity-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./inactivity-notification.component";
import * as i3 from "../../models/dialog-options";
import * as i4 from "../../services/modal/modal.service";
import * as i5 from "../../../core/services/user-activity/user-activity.service";
import * as i6 from "../../../app-settings/services/app-settings-manage.service";
var styles_IncativityNotificationComponent = [i0.styles];
var RenderType_IncativityNotificationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncativityNotificationComponent, data: {} });
export { RenderType_IncativityNotificationComponent as RenderType_IncativityNotificationComponent };
export function View_IncativityNotificationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "inactivity-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "notification-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "col-xs-9 theme-md-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Inactivity Warning"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "col-xs-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "notification-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "theme-sm-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["You have been inactive for ", " minute(s). For security reasons, you will be logged out in ", " minute(s) if you remain inactive."])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "theme-sm-text bold-message-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click anywhere to reset your session."])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-xs-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "theme-button-apply pull-right"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["I'm here"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.remainingTime; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.inactivityThresholdMinutes; var currVal_2 = _co.timeoutAfterMinutes; _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_IncativityNotificationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-inactivity-notification", [], null, null, null, View_IncativityNotificationComponent_0, RenderType_IncativityNotificationComponent)), i1.ɵdid(1, 180224, null, 0, i2.IncativityNotificationComponent, [i3.DialogOptions, i4.ModalService, i5.UserActivityService, i6.AppSettingsManageService], null, null)], null, null); }
var IncativityNotificationComponentNgFactory = i1.ɵccf("slx-inactivity-notification", i2.IncativityNotificationComponent, View_IncativityNotificationComponent_Host_0, {}, {}, []);
export { IncativityNotificationComponentNgFactory as IncativityNotificationComponentNgFactory };
