<div class="model-body">
    <slx-spinner [show]="isLoading">
        <div>
            <span class="date-selection">Shift dates:</span>
            <div class="row date-range">
                <div class="col-sm-3">
                    Starting on: <slx-date-navigator  (ngModelChange)="updateEndDate($event)" format="MM/dd/yyyy" [minDate]="requestedDate" className="slx-mobile-navigator" [navigatorButtons]="true"   [(ngModel)]="openShift.startDateRange"></slx-date-navigator>
                </div>
                <div class="col-sm-3">
                    Ending on: <slx-date-navigator className="slx-mobile-navigator"
                        [(ngModel)]="openShift.endDateRange"  [minDate]="openShift.startDateRange" format="MM/dd/yyyy" [maxDate] ="getMaxDate()"
                        [navigatorButtons]="true"></slx-date-navigator>
                </div>
 
            </div>
            <div class=" row ">
                <div class="col-sm-12 screen-message">
                    New Shifts will be added to each day within this date range
                </div>
            </div>
        </div>
        <div class="add-shift-details"> Shift details:</div>       
        <div class="row">
            <div class="col-sm-2 shift-count">
              <p class="shift-count-label">Number of Shifts</p>
                <slx-number #countField="ngModel" class="slx-wide slx-input-number"         [min]="min" [max]="max"  [slxMin]="min" [slxMax]="max"           
                    [decimals]="0"    [autoCorrect]="true"     [(ngModel)]="openShift.count"  name="countField" >
                </slx-number>
            <span *ngIf="countField?.errors?.min || countField?.errors?.max || openShift.count == null" class="slx-error-block pull-left" errorMessage>Number must be between 1 and 25</span>
        
            </div>
            <div class="col-sm-3 positions">
                
                <p class="shift-count-label">Position</p>
                <kendo-dropdownlist
                [data]="positionData"
                class ="positionData"
                [(ngModel)]="openShift.position"
                [filterable]="true"
                textField="name" valueField="id"  (filterChange)="filterPosition($event)"  ></kendo-dropdownlist>
                <span *ngIf="openShift.position == undefined"  class="slx-error-block">A value must be selected</span>
              
            </div>
            <div class="col-sm-3 units">
                <p class="shift-count-label">Unit</p><kendo-dropdownlist
                [data]="unitData"
                class ="unitData"
                [(ngModel)]="openShift.unit"
                [filterable]="true"
                textField="name" valueField="id"  (filterChange)="filterUnits($event)"  ></kendo-dropdownlist>
                <span *ngIf="openShift.unit == undefined " class="slx-error-block">A value must be selected</span>
              
            </div>
            <div class="col-sm-3 shifts">
                <div class="row">
                <p class="shift-count-label">Shift</p>
                <kendo-dropdownlist
                [data]="shiftsData"
                class ="shiftsData"
                [(ngModel)]="openShift.Shifts"
                [filterable]="true"
                textField="name" valueField="id"  (filterChange)="filterShifts($event)"  ></kendo-dropdownlist>
                <span *ngIf="openShift.Shifts == undefined " class="slx-error-block">A value must be selected</span>
            </div>
            <div class="row">
                <div class="col col-exception">
                    <button type="button" [disabled] = "isSelected()" class="slx-button slx-with-icon add-shift pull-right"
                        (click)="addOpenShifts()">
                        <i class="fal fa-plus-circle" aria-hidden="true"></i>
                        &nbsp;&nbsp; &nbsp;&nbsp;Add Shifts &nbsp;&nbsp;</button>
                </div> 
            </div>
            </div>            
        </div>        
        <div class=" row ">
            <div class="col-sm-12 screen-message">
                Note:  The Number of Shifts entered is considered an adjustment value against the same  pre-existing Position, Shift, Unit.
            </div>
        </div>
        <div class="row header slx-content-toolbar-indents addShift-toolbar"></div>
        <div class="no-selected-shift" *ngIf="!isSelectedShift">
            No shift has been added
        </div>
        <div class="added-shifts-header" *ngIf="isSelectedShift">Added Shift(s)</div>
        <div class="slx-full-height slx-blue-grid slx-grid-slim-rows slx-full-height slx-blue-grid added-shift"  *ngIf="isSelectedShift">
          
            <kendo-grid #grid class="addedShift-grid"
                [data]="gridState.view"
                [sortable]="{ mode: 'multiple' }"
                [sort]="gridState.state.sort"  
                [groupable]="false"
                [group]="gridState.state.group"
                [pageSize]="pageSize"
                [skip]="gridState.state.skip"
                (pageChange)="gridState.pageChange($event)"
                (dataStateChange)="gridState.dataStateChange($event)"
                (groupChange)="gridState.groupChange($event)">
            
                <kendo-grid-column title="Shift Dates" field="shiftDates" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem>{{getDateRange(dataItem)}}</ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-date-filter  [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-date-filter>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Number of shifts" field="count" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.count}} </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-number-filter>
                    </ng-template>
                </kendo-grid-column>          
                <kendo-grid-column title="Position" field="positionName" width="100">
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.position.name}}</ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                    </ng-template>
                </kendo-grid-column>           
                <kendo-grid-column title="Unit" field="unitName" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.unit.name}}</ng-template>    
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                    </ng-template>
                </kendo-grid-column> 
                <kendo-grid-column title="Shift" field="shiftName" width="90">
                    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.Shifts.name}}</ng-template>
                </kendo-grid-column>      
                <kendo-grid-column field="Action" title="" width="100" filterable = "false">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                      <button (click) = "cancelHandler(dataItem)" class="slx-button slx-grey slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
                        <i class="fas fa-ban" aria-hidden="true"></i> </button>                   
                    </ng-template>
                  
                </kendo-grid-column>
            </kendo-grid>  
        </div> 
    </slx-spinner>
</div>
<div class="modal-footer">
    
    <button type="button" (click)="onCancel()" class="btn btn-default red">
        <i class="fas fa-ban" aria-hidden="true"></i> Discard
      </button>
      <button type="button" (click)="onAdd()" [disabled]="!isSelectedShift" class="btn btn-primary green">
        <i class="fas fa-save" aria-hidden="true"></i>Add Shifts
    </button>
   
</div>