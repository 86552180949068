import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ColumnComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { ColumnSettingsStorageService, ColumnManagementService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { KendoGridColumnsGroupDirective } from './kendo-grid-columns-group.directive';
var KendoGridColumnHiddenStateDirective = /** @class */ (function () {
    function KendoGridColumnHiddenStateDirective(group, column, stateManagement, columnSettingsStorageService, changeDetector) {
        this.group = group;
        this.column = column;
        this.stateManagement = stateManagement;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.changeDetector = changeDetector;
        this.displayedDefault = true;
    }
    Object.defineProperty(KendoGridColumnHiddenStateDirective.prototype, "name", {
        get: function () {
            return this.columnName ? this.columnName : this.column.field;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridColumnHiddenStateDirective.prototype, "groupName", {
        get: function () {
            if (!this.columnGroup && this.m_groupName) {
                return this.m_groupName;
            }
            return this.columnGroup ? this.columnGroup : ColumnSettingsStorageService.defaultColumnGroupKey;
        },
        enumerable: true,
        configurable: true
    });
    KendoGridColumnHiddenStateDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.m_groupName = this.group.columnGroup;
        if (this.supressRegistration) {
            return;
        }
        this.initSubscription = this.stateManagement.initGroup$.filter(function (event) { return event.group === _this.groupName; })
            .subscribe(function (event) {
            _this.stateManagement.registerColumn(_this.groupName, { name: _this.name, description: _this.column.displayTitle, displayed: _this.displayedDefault });
        });
        this.changesSubscription = this.stateManagement.columnsChanged$.filter(function (event) { return event.group === _this.groupName; })
            .subscribe(function (event) {
            _this.state = _.find(event.columns, function (c) { return c.name === _this.name; });
            if (!_this.state) {
                return;
            }
            if (_this.state.displayed === undefined) {
                return;
            }
            _this.column.hidden = !_this.state.displayed;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridColumnHiddenStateDirective.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridColumnHiddenStateDirective.prototype, "changesSubscription", void 0);
    return KendoGridColumnHiddenStateDirective;
}());
export { KendoGridColumnHiddenStateDirective };
