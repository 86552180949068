import { Provider } from '@angular/core';

export * from './api/api-util.service';
export * from './app-user-settings/index';
export * from './attachments-uploader-helper/attachments-uploader-helper.service';
export * from './busy/busy.service';
export * from './cache/cache.service';
export * from './change-management/can-leave.guard';
export * from './change-management/change-management.service';
export * from './column-settings/column-management.service';
export * from './column-settings/column-settings-storage.service';
export * from './component-state/component-state-storage.service';
export * from './component-state/component-state.guard';
export * from './date-time/date-time.service';
export * from './device-detector/device-detector.service';
export * from './file/file.service';
export * from './filter-state/filter-state-management.service';
export * from './modal/modal-storage.service';
export * from './modal/modal.service';
export * from './modal/popup.service';
export * from './navigation/index';
export * from './odata-api/odata-api.service';
export * from './page-scroll/page-scroll-instance';
export * from './page-scroll/page-scroll.service';
export * from './resolvers/index';
export * from './scroll-watch/scroll-watch.service';
export * from './selectable-items-producer/selectable-items-producer';
export * from './state-management/state-management.service';
export * from './technical/index';
export * from './timeline/index';
export * from './timeline/timeline-service';
export * from './url-params/url-params.service';
export * from './value-pair-chart-data/value-pair-chart-data.service';

import { ApiUtilService } from './api/api-util.service';
import { CanLeaveGuard } from './change-management/can-leave.guard';
import { ChangeManagementService } from './change-management/change-management.service';
import { ColumnSettingsStorageService } from './column-settings/column-settings-storage.service';
import { ComponentStateGuard } from './component-state/component-state.guard';
import { DateTimeService } from './date-time/date-time.service';
import { ModalStorageService } from './modal/modal-storage.service';
import { ModalService } from './modal/modal.service';
import { PopupService } from './modal/popup.service';
import { PageScrollService } from './page-scroll/page-scroll.service';
import { ScrollWatchService } from './scroll-watch/scroll-watch.service';
import { SelectableItemsProducer } from './selectable-items-producer/selectable-items-producer';
import { UrlParamsService } from './url-params/url-params.service';

import { appSettingsServices } from './app-user-settings/index';
import { AttachmentsUploaderHelperService } from './attachments-uploader-helper/attachments-uploader-helper.service';
import { CacheUtilService } from './cache/cache.service';
import { ComponentStateStorageService } from './component-state/component-state-storage.service';
import { DeviceDetectorService } from './device-detector/device-detector.service';
import { ExportDataEventService } from './export-data/export-data-event.service';
import { FileService } from './file/file.service';
import { EmployeeSectionTabNavigationService, RestoreQueryParamsService } from './navigation/index';
import { OdataApiService } from './odata-api/odata-api.service';
import { resolvers } from './resolvers/index';
import { versionServices } from './technical/index';
import { TimelineService } from './timeline/timeline-service';

export const services: Provider[] = [
  PopupService,
  ModalService,
  ModalStorageService,
  PageScrollService,
  ScrollWatchService,
  DateTimeService,
  SelectableItemsProducer,
  UrlParamsService,
  ApiUtilService,
  ColumnSettingsStorageService,
  versionServices,
  CacheUtilService,
  OdataApiService,
  FileService,
  ComponentStateStorageService,
  ChangeManagementService,
  CanLeaveGuard,
  ComponentStateGuard,
  TimelineService,
  DeviceDetectorService,
  ExportDataEventService,
  RestoreQueryParamsService,
  AttachmentsUploaderHelperService,
  ...appSettingsServices,
  ...resolvers,
  EmployeeSectionTabNavigationService
];
