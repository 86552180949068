import { OnInit } from '@angular/core';
import { DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { LookupApiService } from '../../../../organization/services/index';
import { EmployeeDefinition } from '../../../../organization/models/index';
import { VacationPlanEmployeeWeek } from '../../../models/index';
import { VacationPlannerApiService } from '../../../services/index';
import * as _ from 'lodash';
var VacationEmployeeDialogComponent = /** @class */ (function () {
    function VacationEmployeeDialogComponent(options, modalService, vacationPlannerApiService, lookupApiService, employee, week, orgLevelId) {
        this.options = options;
        this.modalService = modalService;
        this.vacationPlannerApiService = vacationPlannerApiService;
        this.lookupApiService = lookupApiService;
        this.dialogResult = false;
        this.employee = employee;
        this.week = week;
        this.orgLevelId = orgLevelId;
        this.state = {
            isLoading: false,
            isSummary: false
        };
    }
    Object.defineProperty(VacationEmployeeDialogComponent.prototype, "year", {
        get: function () {
            return this.week.start.year();
        },
        enumerable: true,
        configurable: true
    });
    VacationEmployeeDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true;
        this.vacationPlannerApiService.getVacationPlanEmployeeDetails(this.employee, this.week.start.toDate())
            .then(function (employeeDetails) {
            _this.originalRecords = _.cloneDeep(employeeDetails.records);
            _this.employeeDetails = employeeDetails;
            _this.state.isLoading = false;
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
        $('.k-window-title').text(this.employee.name);
    };
    VacationEmployeeDialogComponent.prototype.toggleToSummary = function () {
        var _this = this;
        this.state.isSummary = true;
        if (!this.employeeSummary) {
            this.state.isLoading = true;
            this.vacationPlannerApiService.getVacationPlanEmployeeSummary(this.employee.id, this.year)
                .then(function (employeeSummary) {
                _this.employeeSummary = employeeSummary;
                _this.state.isLoading = false;
            })
                .catch(function () {
                _this.state.isLoading = false;
            });
        }
    };
    VacationEmployeeDialogComponent.prototype.toggleToDetails = function () {
        this.state.isSummary = false;
    };
    VacationEmployeeDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    VacationEmployeeDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return VacationEmployeeDialogComponent;
}());
export { VacationEmployeeDialogComponent };
