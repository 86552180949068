import { IBenefitClassDefinition, BenefitClassDefinition } from '../lookup/index';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export interface IEmployeeBenefitClass {
  id: number;
  empId: number;
  benefitClassId: number;
  benefitClass: IBenefitClassDefinition;
  startDate: string;
  endDate: string;
  updatedOn: string;
  updatedBy: string;
  dateHired?: string;
}

export class EmployeeBenefitClass {
  public id: number;
  public empId: number;
  public benefitClassId: number;
  public benefitClass: BenefitClassDefinition;
  public startDate: Date;
  public endDate: Date;
  public updatedOn: Date;
  public updatedBy: string;
  public dateHired?: Date;

  public isHistory: boolean;
  public isStartDateEditable: boolean;
  public isEndDateEditable: boolean;
}
