<slx-spinner [show]="isLoading">
  <div class="modal-content">
    <form #form="ngForm" novalidate>
      <div class="modal-body" style="height: 500px; overflow-y: scroll;">
        <kendo-grid [data]="gridView" [sortable]="{ mode: 'multiple' }" [sort]="sort" scrollable="none" (sortChange)="sortChange($event)">
          <ng-template kendoGridToolbarTemplate position="top">
            <div class="col-sm-6">
              <slx-input-decorator>
                <slx-date-picker-ngx slx-input
                [(ngModel)]="selectedDate"
                  [required]="true"
                  placeholder="Date"
                  name="selectedDate"
                  (valueChange)="onFilterDateChanged($event)"
                ></slx-date-picker-ngx>
                <span errorMessage for="required"></span>
                <span errorMessage for="date"></span>
              </slx-input-decorator>
            </div>
          </ng-template>
          <kendo-grid-column width="30">
            <ng-template kendoGridHeaderTemplate>
            </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex = "rowIndex">
                <div (mouseover)="isMouseover(dataItem)">
                  <input name="defintionCheck" type="checkbox" [attr.disabled]="isCheckboxEnabled(dataItem)"
                    (change)="onItemSelectionChanged($event,dataItem)" [checked]="dataItem.selected">
                </div>
              </ng-template>
          </kendo-grid-column>
          <kendo-grid-column field="item.category.name">
            <ng-template kendoGridHeaderTemplate>
              Category
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.item?.category?.name }}
            </ng-template>                                                         
          </kendo-grid-column>
          <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
              Definition
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.item?.definition }}
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column>
            <ng-template kendoGridHeaderTemplate>
              Points
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.item?.points | number: ".2-2" }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
        <div class="bootbox-body">{{options?.message}}</div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
        <button type="button" (click)="onOk()" [disabled]="!isAnyItemSelected || !form.valid" class="theme-button-apply">OK</button>
      </div>
    </form>
  </div>
</slx-spinner>
