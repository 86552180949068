import { Directive, HostListener, Input, Provider, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { EmployeeSectionNavigationService, EmployeeSectionTabNavigationService } from '../../services/index';

@Directive({
  /* tslint:disable */
  selector: '[employeeSectionTabLink]',
  /* tslint:enable */
})
export class EmployeeSectionTabLinkDirective {
  @Input()
  public employeeSectionTabLink: number;
  @Input()
  public hideOrgLevelBreadcrumb: boolean;
  @Input()
  public empSectionTab: string = 'Personal';
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute, public empSectionTabNavService: EmployeeSectionTabNavigationService) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);
    navService.NavigateToEmployeeSections(this.employeeSectionTabLink, this.hideOrgLevelBreadcrumb);
    this.empSectionTabNavService.activeContainerScroll(this.empSectionTab);
  }
}
