import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

import { ResponseBody } from '../../../core/models/api/response-body';
import { SearchCategory } from '../../../organization/models/lookup/index';
import { QueryModel, LogicalExpressionModel, ILogicExpression, ISearchPredicate } from '../components/query-builder/index';
import { Meta } from '../../../core/models/api/meta';
import {
    IEmployeeSearchResult, IEmployeeDefinition, IDepartmentDefinition, IOrganizationDefinition, IPositionDefinition,
    EmployeeSearchResult, Employee, Department, Organization, Position
} from '../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class EmployeeSearchMapService {


    public createLogicExpressionDTO(): ILogicExpression {
        return {
            LogicalOperator: 0,
            Predicate: {
                SearchCategoryName: null,
                SearchOperator: null,
                Values: null
            },
            ChildExpressions: []
        };
    }

    public mapLogicatlExpressionToDTO(expression: LogicalExpressionModel, dto: ILogicExpression): ILogicExpression {

        dto.LogicalOperator = expression.logicalOperator;

        dto.Predicate.SearchCategoryName = expression.searchPredicate.searchCategoryName;
        dto.Predicate.SearchOperator = expression.searchPredicate.searchOperator;
        dto.Predicate.Values = expression.searchPredicate.values;

        if (dto.Predicate.Values) {
            if (dto.Predicate.Values[0] instanceof Date) {
                dto.Predicate.Values[0] = dateTimeUtils.convertToDtoString(dto.Predicate.Values[0]);
            }

            if (dto.Predicate.Values.length > 1) {
                if (dto.Predicate.Values[1] instanceof Date) {
                    dto.Predicate.Values[1] = dateTimeUtils.convertToDtoString(dto.Predicate.Values[1]);
                }
            }
        }

        return dto;
    }

    public mapSeachResult(dtos: IEmployeeSearchResult[]): EmployeeSearchResult[] {

        let arr: EmployeeSearchResult[] = [];

        _.forEach(dtos, (dto: IEmployeeSearchResult) => {

            let searchResult: EmployeeSearchResult = new EmployeeSearchResult();

            searchResult.badgeId = dto.badgeId;
            searchResult.dateTerminated = dto.dateTerminated ? moment(dto.dateTerminated).toDate() : null;
            searchResult.status = dto.status;

            searchResult.employee = new Employee();
            searchResult.employee.id = dto.employee.id;
            searchResult.employee.name = dto.employee.name;
            searchResult.employee.payrollNumber = dto.employee.payrollNumber;
            searchResult.employee.dateHired = dto.employee.dateHired ? moment(dto.employee.dateHired).toDate() : null;

            searchResult.organization = new Organization();
            searchResult.organization.id = dto.organization.id;
            searchResult.organization.name = dto.organization.name;
            searchResult.organization.orgLevelId = dto.organization.orgLevelId;

            searchResult.department = new Department();
            searchResult.department.id = dto.department.id;
            searchResult.department.name = dto.department.name;
            searchResult.department.orgLevelId = dto.department.orgLevelId;

            searchResult.position = new Position();
            searchResult.position.id = dto.position.id;
            searchResult.position.name = dto.position.name;
            searchResult.position.orgLevelId = dto.position.orgLevelId;
            searchResult.position.group = dto.position.group;

            arr.push(searchResult);
        });

        return arr;
    }
}
