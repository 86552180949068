import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
var KendoGridCustomSelectionHelper = /** @class */ (function () {
    function KendoGridCustomSelectionHelper(view, singleItemMode) {
        this.m_isAllSelected = false;
        this.selectedRecords = [];
        this.onSelectionChanged = new ReplaySubject();
        this.m_singleItemMode = singleItemMode;
        this.view = view;
    }
    Object.defineProperty(KendoGridCustomSelectionHelper.prototype, "isAllSelected", {
        get: function () {
            return this.m_isAllSelected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridCustomSelectionHelper.prototype, "selectionLength", {
        get: function () {
            return this.selectedRecords.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridCustomSelectionHelper.prototype, "view", {
        get: function () {
            return this.m_view;
        },
        set: function (value) {
            this.m_view = value;
            this.clearSelection();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridCustomSelectionHelper.prototype, "selection", {
        get: function () {
            return this.selectedRecords;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridCustomSelectionHelper.prototype, "singleItemMode", {
        get: function () {
            return this.m_singleItemMode;
        },
        enumerable: true,
        configurable: true
    });
    KendoGridCustomSelectionHelper.prototype.onToggleAllSelected = function () {
        this.m_isAllSelected = !this.m_isAllSelected;
        if (this.isAllSelected) {
            this.selectedRecords = _.filter(this.view.data, function (item) { return item.selectable; });
        }
        else {
            this.selectedRecords = [];
        }
        this.updateAllItems(this.m_isAllSelected);
        this.onSelectionChanged.next(this.selectedRecords);
    };
    KendoGridCustomSelectionHelper.prototype.onItemSelectionChange = function (item) {
        var index = this.selectedRecords.indexOf(item);
        if (!this.m_singleItemMode) {
            if (index === -1) {
                item.isSelected = true;
                this.selectedRecords.push(item);
            }
            else {
                item.isSelected = false;
                this.selectedRecords.splice(index, 1);
            }
        }
        else {
            if (index === -1) {
                item.isSelected = true;
                this.selectedRecords = [item];
            }
            else {
                item.isSelected = false;
                this.selectedRecords = [];
            }
            this.deselectOthers(item);
        }
        this.m_isAllSelected = this.selectedRecords.length === this.view.data.length;
        this.onSelectionChanged.next(this.selectedRecords);
    };
    KendoGridCustomSelectionHelper.prototype.clearSelection = function () {
        this.selectedRecords = [];
        this.m_isAllSelected = false;
        this.updateAllItems(false);
        this.onSelectionChanged.next(this.selectedRecords);
    };
    KendoGridCustomSelectionHelper.prototype.getSelectedItemAt = function (index) {
        if (this.selectionLength > 0 && index < this.selectionLength) {
            return this.selectedRecords[index];
        }
        return null;
    };
    KendoGridCustomSelectionHelper.prototype.restoreSelection = function (items) {
        this.selectedRecords = items;
        this.onSelectionChanged.next(this.selectedRecords);
    };
    KendoGridCustomSelectionHelper.prototype.updateAllItems = function (selected) {
        if (this.view && this.view.data) {
            _.each(this.view.data, function (item) {
                item.isSelected = item.selectable ? selected : false;
            });
        }
    };
    KendoGridCustomSelectionHelper.prototype.deselectOthers = function (selectedItem) {
        if (this.view && this.view.data) {
            _.each(this.view.data, function (item) {
                if (item !== selectedItem) {
                    item.isSelected = false;
                }
            });
        }
    };
    return KendoGridCustomSelectionHelper;
}());
export { KendoGridCustomSelectionHelper };
