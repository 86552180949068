import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams, HttpHeaders } from '@angular/common/http';

import { Shift, IShift, ConfOrganization } from '../../models/index';
import { ShiftsMapService } from './shifts-map.service';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { configurationConfig } from '../../configuration.config';
import { PositionsConfigurationMapService } from '../positions/positions-configuration-map.service';
import { FieldsMeta, Actions } from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';
import { IShiftSetting, ShiftSetting } from '../../models/shifts/shift-setting';

@Injectable()
export class ShiftsApiService {
  constructor(private apiUtilService: ApiUtilService,
              private urlParamService: UrlParamsService,
              private shiftsMapService: ShiftsMapService,
              private posConfMapService: PositionsConfigurationMapService,
              private metaMapService: MetaMapService,
              private cacheUtilService: CacheUtilService) {
  }

  public getShiftsList(orgLevelId: number): Promise<{ actions: Actions, records: Shift[] }> {
    let request: HttpRequest<any> = new HttpRequest('GET', this.getUrlRoot(orgLevelId));
    let promise: Promise<{ actions: Actions, records: Shift[] }> = this.apiUtilService.request<IShift[], Meta>(request)
      .then((response: ResponseBody<IShift[], Meta>) => {
        let actions: Actions = this.metaMapService.mapActions(response.meta as FieldsMeta);
        return {actions: actions, records: this.shiftsMapService.mapShifts(response.data)};
      });
    return promise;
  }

  public getShiftSetting(orgLevelId:number,shiftId:number) : Promise<ShiftSetting> {    
    let reqParams = new HttpParams();    
    let request: HttpRequest<any> = new HttpRequest('GET', this.getSettingUrlRoot(orgLevelId,shiftId));
    let promise =  this.apiUtilService.request<ShiftSetting,any>(request).then(response => {return response.data;});
    return promise;
  }


  public saveShift(shift: Shift, orgLevelId: number): Promise<Shift> {
    let shiftId: string = shift.id > 0 ? '/' + shift.id : '';
    let url: string = `${this.getUrlRoot(orgLevelId)}${shiftId}`;

    let request: HttpRequest<any> = new HttpRequest((shift.id > 0 ? 'PUT' : 'POST'), url, this.shiftsMapService.convertShiftToData(shift));

    let promise: Promise<Shift> = this.apiUtilService.request<IShift, Meta>(request)
      .then((response: ResponseBody<IShift, Meta>) => {
        this.cacheUtilService.clear();
        return this.shiftsMapService.convertDataToShift(<IShift>response.data);
      });
    return promise;
  }

  public removeShift(shift: Shift, orgLevelId: number, reassignedShift: Shift): Promise<any> {
    let url: string = `${this.getUrlRoot(orgLevelId)}/${shift.id}`;
    let params: HttpParams = reassignedShift ? this.urlParamService.convertToParams({reassignedShiftId: reassignedShift.id}) : null;
    let request: HttpRequest<any> = new HttpRequest('DELETE', url, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.cacheUtilService.clear();
        return response.status;
      });
    return promise;
  }

  public getCopyShiftConfiguration(orgLevelId: number): Promise<ConfOrganization[]> {
    const url: string = `${this.getUrlRoot(orgLevelId)}/${configurationConfig.api.configuration.shifts.copyConfiguration}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return this.posConfMapService.mapOrganizations(response.data.organizations);
      });
  }

  public copyShifts(sourceDepartmentId: number, targetDepartmentId: number, postiondIds: number[]): Promise<any> {
    const url: string = `${this.getUrlRoot(sourceDepartmentId)}/${configurationConfig.api.configuration.shifts.copy}`;

    let body: any = postiondIds;
    const request: HttpRequest<any> = new HttpRequest('POST', url, body, {
      params: this.urlParamService.convertToParams({departmentId: targetDepartmentId})
    });

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  private getUrlRoot(orgLevelId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.shifts.root}`;
  }

  private getSettingUrlRoot(orgLevelId: number,shiftId:number): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.shifts.root}/${shiftId}/${configurationConfig.api.configuration.shifts.setting}`;
  }

  public createOrUpdatePartialShift(shiftId:number,partialShifts: Shift[], orgLevelId: number): Promise<unknown> {
    let url: string = `${this.getUrlRoot(orgLevelId)}/${shiftId}/partialshifts`;

    let psList:IShift[] = partialShifts.map(x => x as unknown as IShift)

    let request: HttpRequest<any> = new HttpRequest('POST', url, psList);

    let promise: Promise<unknown> = this.apiUtilService.request(request)
      .then((response) => {
        return response.status === 200;
      });
    return promise;
  }
}
