import { Component, Input, Output, EventEmitter, ViewChild, OnInit, SimpleChanges, ElementRef } from '@angular/core';
import * as _ from 'lodash';

import { IMediaOptions } from '../../models/photo-maker';

@Component({
  moduleId: module.id,
  selector: 'slx-photo-maker',
  templateUrl: 'photo-maker.component.html',
  styleUrls: ['photo-maker.component.scss']
})
export class PhotoMakerComponent implements OnInit {
  @Input()
  public options: IMediaOptions;
  @Output('onPhoto')
  public makePhoto: EventEmitter<string>;
  @Output('onError')
  public failed: EventEmitter<string>;

  @ViewChild('video', {static: true})
  public videoElem: ElementRef;
  @ViewChild('canvas', {static: true})
  public canvasElem: ElementRef;

  private defaultOptions: IMediaOptions;

  constructor() {
    this.defaultOptions = {
      audio: false,
      video: true
    };

    this.makePhoto = new EventEmitter<string>();
    this.failed = new EventEmitter<string>();
  }

  public ngOnInit(): void {
    const options: IMediaOptions = _.assign({}, this.defaultOptions, this.options);
    let promise: Promise<string> = null;
    if (_.get(navigator, 'mediaDevices.getUserMedia', null)) {
      promise = this.newVersion(options);
    } else {
      promise = this.oldVersion(options);
    }
    promise
      .then((stream: any) => {
        this.videoElem.nativeElement.srcObject = stream;
      })
      .catch(this.error.bind(this));
  }

  public onTakePhoto(): void {
    const context: any = this.canvasElem.nativeElement.getContext('2d');
    const width: number = this.videoElem.nativeElement.videoWidth;
    const height: number = this.videoElem.nativeElement.videoHeight;

    this.canvasElem.nativeElement.width = width;
    this.canvasElem.nativeElement.height = height;

    context.drawImage(this.videoElem.nativeElement, 0, 0, width, height);

    this.videoElem.nativeElement.pause();
    this.makePhoto.emit(this.canvasElem.nativeElement.toDataURL('image/png'));
  }

  private newVersion(constraints: IMediaOptions): Promise<any> {
    return navigator.mediaDevices.getUserMedia(constraints)
      .catch(this.error.bind(this));
  }

  private oldVersion(constraints: IMediaOptions): Promise<any> {
    const getUserMedia: Function = navigator.getUserMedia || _.get(navigator, 'webkitGetUserMedia', null) || _.get(navigator, 'mozGetUserMedia', null);

    if (!getUserMedia) {
      return Promise.reject(new Error('Your device is not support this feature'));
    }

    return new Promise(function(resolve, reject): void {
      getUserMedia.call(navigator, constraints, resolve, reject);
    })
    .catch(this.error.bind(this));
  }

  private error(err: any): void {
    if (err) {
      let errorMessage: string = err.name;
      if (_.size(err.message) > 0) {
        errorMessage += `: ${err.message}`;
      }
      this.failed.emit(errorMessage);

      return;
    }
    this.failed.emit('Error occurred during taking photo');
  }
}
