<section class="slx-collapsible-section" [ngClass]="getModeClass()">
  <h3 *ngIf="!headerOpenClose" class="slx-collapsible-section__header">
    <div>
      <i *ngIf="hasCustomIcon" class="slx-collapsible-section__title-icon" [ngClass]="customIcon"
        aria-hidden="true"></i>
      <span *ngIf="!emptyHeader">{{ title }}</span>
      <span *ngIf="hasCounter" class="slx-collapsible-section__title-counter">{{ counter }}</span>
    </div>
    <div class="slx-collapsible-section__right" [class.padd-l]="!emptyHeader">
      <ng-content select="[header]"></ng-content>
      <span class="slx-collapsible-section__icon" (click)="onToggleSection()" title="{{ toggleIconTitle() }}">
        <i [ngClass]="toggleIconClass()" aria-hidden="true"></i>
      </span>
    </div>
  </h3>
  <h3 *ngIf="headerOpenClose" (click)="onToggleSection()" class="slx-collapsible-section__header">
    <div>
      <i *ngIf="hasCustomIcon" class="slx-collapsible-section__title-icon" [ngClass]="customIcon"
        aria-hidden="true"></i>
      <span *ngIf="!emptyHeader">{{ title }}</span>
      <span *ngIf="hasCounter" class="slx-collapsible-section__title-counter">{{ counter }}</span>
    </div>
    <div class="slx-collapsible-section__right" [class.padd-l]="!emptyHeader">
      <ng-content select="[header]"></ng-content>
      <span class="slx-collapsible-section__icon" title="{{ toggleIconTitle() }}">
        <i [ngClass]="toggleIconClass()" aria-hidden="true"></i>
      </span>
    </div>
  </h3>
  <div class="slx-collapsible-section__content" [ngClass]="{ 'shown': isShown() }">
    <ng-content select="[body]"></ng-content>
  </div>
</section>