import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { PunchProfileManagementService } from '../../services/index';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
var PunchProfileComponent = /** @class */ (function () {
    function PunchProfileComponent(punchProfileManagementService, changeDetector, route) {
        this.punchProfileManagementService = punchProfileManagementService;
        this.changeDetector = changeDetector;
        this.route = route;
        this.punchProfile = [];
        this.punchProfileGroup$ = new BehaviorSubject([]);
    }
    PunchProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route.snapshot.data) {
            var lookupData = this.route.snapshot.data[1];
            this.punchProfile = lookupData[0].items;
            this.attestationType = lookupData[1];
            this.punchType = lookupData[2].items;
        }
        this.punchProfileManagementService.getGroupsAndProfiles(0);
        this.loadedSubscription = this.punchProfileManagementService.onLoaded$.subscribe(function (punchProfileGroup) {
            _this.punchProfileGroup$.next(punchProfileGroup);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.statusSubscription = this.punchProfileManagementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
            }
        });
    };
    PunchProfileComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(PunchProfileComponent.prototype, "groups$", {
        get: function () {
            return this.punchProfileManagementService.groups$;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchProfileComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PunchProfileComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchProfileComponent.prototype, "statusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchProfileComponent.prototype, "orgLevelSubscription", void 0);
    return PunchProfileComponent;
}());
export { PunchProfileComponent };
