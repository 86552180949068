import * as _ from 'lodash';
export var state1095CValue = {
    suggested: 'suggested',
    edited: 'edited',
    edited_confirmed: 'edit_confirmed',
    confirmed: 'confirmed',
    quick_edit: 'quickedit'
};
export var aca1095CPopupAction = {
    addEmplyee: 'addEmployee',
    removeEmployee: 'removeEmployee',
    edit1095Record: 'edit1095Record',
    quickEditRecord: 'quickEditRecord',
    quickConfirmRecord: 'quickConfirmRecord',
    quickEditResetRecord: 'quickEditResetRecord',
    quickConfirmResetRecord: 'quickConfirmResetRecord'
};
export var acaQuickPopupState = {
    quickEditActive: 'quickEditActive',
    quickEditStart: 'quickEditStart',
    quickEditSave: 'quickEditSave',
    quickEditClearAndClose: 'quickEditClearAndClose',
    quickEditClear: 'quickEditClear',
    quickEditClose: 'quickEditClose',
    quickEditResetActive: 'quickEditResetActive',
    quickEditResetStart: 'quickEditResetStart',
    quickEditResetSave: 'quickEditResetSave',
    quickEditResetClearAndClose: 'quickEditResetClearAndClose',
    quickEditResetClear: 'quickEditResetClear',
    quickEditResetClose: 'quickEditResetClose',
    quickConfirmActive: 'quickConfirmActive',
    quickConfirmStart: 'quickConfirmStart',
    quickConfirmSave: 'quickConfirmSave',
    quickConfirmClearAndClose: 'quickConfirmClearAndClose',
    quickConfirmClear: 'quickConfirmClear',
    quickConfirmClose: 'quickConfirmClose',
    quickConfirmResetActive: 'quickConfirmResetActive',
    quickConfirmResetStart: 'quickConfirmResetStart',
    quickConfirmResetSave: 'quickConfirmResetSave',
    quickConfirmResetClearAndClose: 'quickConfirmResetClearAndClose',
    quickConfirmResetClear: 'quickConfirmResetClear',
    quickConfirmResetClose: 'quickConfirmResetClose'
};
var Aca1095CPopupInitData = /** @class */ (function () {
    function Aca1095CPopupInitData() {
    }
    return Aca1095CPopupInitData;
}());
export { Aca1095CPopupInitData };
var Aca1095cRecord = /** @class */ (function () {
    function Aca1095cRecord() {
        this.expand = false;
    }
    return Aca1095cRecord;
}());
export { Aca1095cRecord };
var Aca1095cActions = /** @class */ (function () {
    function Aca1095cActions() {
    }
    return Aca1095cActions;
}());
export { Aca1095cActions };
var Aca1095c = /** @class */ (function () {
    function Aca1095c() {
    }
    return Aca1095c;
}());
export { Aca1095c };
var AcaMonthRecord = /** @class */ (function () {
    function AcaMonthRecord() {
    }
    Object.defineProperty(AcaMonthRecord.prototype, "displayCode", {
        get: function () {
            var displayCode = '';
            if (_.isEqual(this.status, state1095CValue.suggested)) {
                displayCode = this.suggestedCode;
            }
            else {
                displayCode = this.selectedCode;
            }
            return displayCode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaMonthRecord.prototype, "displayOption", {
        get: function () {
            var displayOption = '';
            if (_.isEqual(this.status, state1095CValue.suggested)) {
                displayOption = this.suggestedOption;
            }
            else {
                displayOption = this.selectedOption;
            }
            return displayOption;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaMonthRecord.prototype, "displayTier", {
        get: function () {
            var displayTier = '';
            if (_.isEqual(this.status, state1095CValue.suggested)) {
                displayTier = this.suggestedTier;
            }
            else {
                displayTier = this.selectedTier;
            }
            return displayTier;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaMonthRecord.prototype, "displayHarbor", {
        get: function () {
            var displayHarbor = '';
            if (_.isEqual(this.status, state1095CValue.suggested)) {
                displayHarbor = this.suggestedHarbor;
            }
            else {
                displayHarbor = this.selectedHarbor;
            }
            return displayHarbor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaMonthRecord.prototype, "displayCost", {
        get: function () {
            var displayCost = '';
            if (_.isEqual(this.status, state1095CValue.suggested)) {
                displayCost = this.suggestedCost;
            }
            else {
                displayCost = this.selectedCost;
            }
            return displayCost;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaMonthRecord.prototype, "isQuickMode", {
        get: function () {
            if (this.quickEdit || this.quickEditReset || this.quickConfirm || this.quickConfirmReset) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaMonthRecord.prototype, "status", {
        get: function () {
            var status = state1095CValue.suggested;
            if (this.confirmedInd) {
                if ((!_.isEqual(this.selectedCost, this.suggestedCost) || !_.isEqual(this.selectedHarbor, this.suggestedHarbor) || !_.isEqual(this.selectedCode, this.suggestedCode))) {
                    status = state1095CValue.edited_confirmed;
                }
                else {
                    status = state1095CValue.confirmed;
                }
            }
            else {
                if ((this.selectedCost || this.selectedHarbor || this.selectedCode)) {
                    if ((!_.isEqual(this.selectedCost, this.suggestedCost) || !_.isEqual(this.selectedHarbor, this.suggestedHarbor) || !_.isEqual(this.selectedCode, this.suggestedCode))) {
                        status = state1095CValue.edited;
                    }
                    else {
                        status = state1095CValue.suggested;
                    }
                }
            }
            return status;
        },
        enumerable: true,
        configurable: true
    });
    return AcaMonthRecord;
}());
export { AcaMonthRecord };
var EmployeeFilterRecord = /** @class */ (function () {
    function EmployeeFilterRecord() {
    }
    return EmployeeFilterRecord;
}());
export { EmployeeFilterRecord };
var EmployeeFilter = /** @class */ (function () {
    function EmployeeFilter() {
    }
    return EmployeeFilter;
}());
export { EmployeeFilter };
var AcaInsightSummaryRecords = /** @class */ (function () {
    function AcaInsightSummaryRecords() {
    }
    return AcaInsightSummaryRecords;
}());
export { AcaInsightSummaryRecords };
var AcaInsightFilter = /** @class */ (function () {
    function AcaInsightFilter() {
        this.allRecords = true;
        this.acaType = null;
        this.confirmInd = null;
        this.enrolledInd = null;
        this.enrolledFullYear = null;
        this.enrolledPartYear = null;
        this.filter = null;
    }
    return AcaInsightFilter;
}());
export { AcaInsightFilter };
var EmployeeIdList = /** @class */ (function () {
    function EmployeeIdList() {
    }
    return EmployeeIdList;
}());
export { EmployeeIdList };
