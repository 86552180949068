import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { StateResetTypes } from '../../../../../core/models/settings/reset-types';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { PmRosterManagementService } from '../../../services/index';
import { Router, ActivatedRoute } from '@angular/router';
import { PerformanceManNavigationService } from '../../../../../common/services/index';
var PmRosterGridComponent = /** @class */ (function () {
    function PmRosterGridComponent(pmManagementService, devDetector, router, route) {
        this.pmManagementService = pmManagementService;
        this.devDetector = devDetector;
        this.router = router;
        this.route = route;
        this.pageSize = 50;
        this.xlsxName = 'Performance_Roster_';
        this.pdfName = 'Performance_Roster_';
        this.columnsGroupName = 'PerformanceManagementRoster';
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.gridComponentKey = 'PMGridState';
        this.gridState.gridControlStateKey = 'GridFiltersState';
        this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.gridState.refreshGridAfterRestoreState = true;
        this.gridState.assignRestoredState = true;
        this.gridState.state.sort = [
            { field: 'employee.name', dir: 'asc' },
            { field: 'reviewDate', dir: 'asc' }
        ];
        this.appConfig = appConfig;
    }
    Object.defineProperty(PmRosterGridComponent.prototype, "isLockedColumn", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmRosterGridComponent.prototype, "isReordable", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    PmRosterGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        var date = new Date().toLocaleDateString();
        this.xlsxName += date + ".xlsx";
        this.pdfName += date + ".pdf";
        this.subscriptions.onLoaded = this.pmManagementService
            .subscribeToLoadedRecords(function (r) { return _this.assignRecords(r); });
        this.subscriptions.export = this.pmManagementService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    PmRosterGridComponent.prototype.ngOnDestroy = function () { };
    PmRosterGridComponent.prototype.onOpenReport = function (entry) {
        this.pmManagementService.openReviewPopup(entry);
    };
    PmRosterGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    PmRosterGridComponent.prototype.onEmpNameClick = function (empId) {
        var navService = new EmployeeSectionNavigationService(this.router, this.route);
        var urlTree = navService.getUrlTreeFromRoot(empId, true);
        var extras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(urlTree, extras);
    };
    PmRosterGridComponent.prototype.onEmpIconClick = function (empId) {
        var pmNavService = new PerformanceManNavigationService(this.router, this.route);
        pmNavService.navigateToReviews(empId);
    };
    PmRosterGridComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.refreshGrid();
    };
    PmRosterGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    PmRosterGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmRosterGridComponent.prototype, "subscriptions", void 0);
    return PmRosterGridComponent;
}());
export { PmRosterGridComponent };
