import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject, Observable } from 'rxjs';
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../app-settings/model/app-server-config';
import { NightShiftSetting } from './../../../app-settings/model/night-shift-setting';
import { OrgLevel } from './../../../state-model/models/org-level/org-level';
import { ShiftGroupOrder } from './../../../organization/models/schedule/shift-group-order';
import { ScheduledShiftDefinition } from './../../../organization/models/schedule/scheduled-shift-definition';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators';

@Injectable()
export class IndividualScheduleNightShiftService {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  private currentOrgLevel: OrgLevel;
  private m_nightShiftPrimary: boolean = false;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;

  private appSettings: AppServerConfig;
  public onLoading$: ReplaySubject<boolean>;

  constructor(private appSettingsService: AppSettingsManageService) {
      this.onLoading$ = new ReplaySubject(1);

      this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
        this.currentOrgLevel = orgLevel;
        this.loadNightShiftPrimary();
      });

      this.onLoading$.next(true);
      this.appSettingsService.getAppServerConfig().then((value: AppServerConfig) => {
        this.appSettings = value;
        this.loadNightShiftPrimary();
        this.onLoading$.next(false);
      });
  }

  public get nightShiftPrimary(): boolean {
    return this.m_nightShiftPrimary;
  }

  public updateTimeByNightShiftSetting(shift: ScheduledShiftDefinition): void {
    const realNight: boolean = this.isRealNight(shift.start, shift.end);
    if (realNight && this.nightShiftPrimary && shift.shift.group.groupOrder === ShiftGroupOrder.Night) {
      shift.start = moment(shift.start).subtract(1, 'days').toDate();
      shift.end = moment(shift.end).subtract(1, 'days').toDate();
    }
  }

  private isRealNight(sd: Date, ed: Date): boolean {
    return sd.getDate() < ed.getDate() || sd.getMonth() < ed.getMonth() || sd.getFullYear() < ed.getFullYear();
  }

  private loadNightShiftPrimary(): void {
    if (this.currentOrgLevel && this.appSettings) {
      let setting: NightShiftSetting = _.find(this.appSettings.nightShiftSettings, (s: NightShiftSetting) => s.organizationId === this.currentOrgLevel.organizationId);
      if (_.isNil(setting)) {
        setting = _.find(this.appSettings.nightShiftSettings, (s: NightShiftSetting) => s.organizationId === 0);
      }
      this.m_nightShiftPrimary = setting ? setting.enabled : false;
    }
  }

}
