import { ElementRef, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var PHONE_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return PhoneInputComponent; }),
    multi: true
};
var PhoneInputComponent = /** @class */ (function () {
    function PhoneInputComponent(elementRef) {
        this.tabindex = 0;
        this.placeholder = 'Phone';
        this.innerValue = '';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.hasFocus = false;
        this.phoneMaskConf = {
            mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
            guide: true
        };
    }
    Object.defineProperty(PhoneInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PhoneInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    PhoneInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
    };
    PhoneInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    PhoneInputComponent.prototype.writeValue = function (value) {
        this.innerValue = value;
        this.inputComponent.nativeElement.value = value ? value : null;
    };
    PhoneInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    PhoneInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    PhoneInputComponent.prototype.valueChanged = function (value) {
        this.onChangeCallback(value);
    };
    return PhoneInputComponent;
}());
export { PhoneInputComponent };
