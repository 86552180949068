import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../app.config';
import { StateManagementService, ColumnManagementService } from '../../../../../../common/services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/index';
var BenefitOptionDetailsFormulaGridComponent = /** @class */ (function () {
    function BenefitOptionDetailsFormulaGridComponent(manService, stateManagement, columnManagementService) {
        this.manService = manService;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.columnGroup = 'BenefitOptionDetailsOptions';
        this.data = [];
        this.isLoading = true;
        this.isShowingExpired = false;
        this.employeeContribution = 0;
        this.employerContribution = 0;
        this.coverage = 0;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.appConfig = appConfig;
    }
    BenefitOptionDetailsFormulaGridComponent.prototype.ngOnInit = function () {
        this.stateManagement.init('BenefitOptionDetailsFormulaGridComponent');
        this.columnManagementService.init('BenefitOptionDetailsFormulaGridComponent');
        this.columnManagementService.initGroup(this.columnGroup, 5);
        this.data = [this.tierDetails];
        this.getEmployeeContribution();
        this.getEmployerContribution();
        this.getCoverage();
        this.refreshGrid();
    };
    BenefitOptionDetailsFormulaGridComponent.prototype.getEmployeeContribution = function () {
        if (this.tierDetails && this.tierDetails.empFormula) {
            if (this.tierDetails.empFormula.isFixedCalcType) {
                this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
            }
            else if (this.tierDetails.empFormula.isFormulaCalcType) {
                this.employeeContribution = 0;
            }
            else {
                this.employeeContribution = 0;
            }
        }
    };
    BenefitOptionDetailsFormulaGridComponent.prototype.getEmployerContribution = function () {
        if (this.tierDetails && this.tierDetails.erFormula) {
            if (this.tierDetails.erFormula.isFixedCalcType) {
                this.employerContribution = this.tierDetails.erFormula.fixedAmount;
            }
            else if (this.tierDetails.erFormula.isFormulaCalcType) {
                this.employerContribution = 0;
            }
            else {
                this.employerContribution = 0;
            }
        }
    };
    BenefitOptionDetailsFormulaGridComponent.prototype.getCoverage = function () {
        if (this.tierDetails && this.tierDetails.coverageFormula) {
            if (this.tierDetails.coverageFormula.isFixedCalcType) {
                this.coverage = this.tierDetails.coverageFormula.fixedAmount;
            }
            else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
                this.coverage = 0;
            }
            else {
                this.coverage = 0;
            }
        }
    };
    BenefitOptionDetailsFormulaGridComponent.prototype.ngOnDestroy = function () {
    };
    BenefitOptionDetailsFormulaGridComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.data, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionDetailsFormulaGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionDetailsFormulaGridComponent;
}());
export { BenefitOptionDetailsFormulaGridComponent };
