/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-punch-request-toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component.ngfactory";
import * as i3 from "../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component";
import * as i4 from "./employee-punch-request-toolbar.component";
import * as i5 from "../../../services/punches/employee-punch-request-management.service";
import * as i6 from "../../../../organization/services/lookup/lookup-api.service";
import * as i7 from "../../../../common/services/component-state/component-state-storage.service";
import * as i8 from "../../../../common/services/state-management/state-management.service";
var styles_EmployeePunchRequestToolbarComponent = [i0.styles];
var RenderType_EmployeePunchRequestToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeePunchRequestToolbarComponent, data: {} });
export { RenderType_EmployeePunchRequestToolbarComponent as RenderType_EmployeePunchRequestToolbarComponent };
export function View_EmployeePunchRequestToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slx-high-box daily-punches"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["punchesHeader", 1]], null, 5, "div", [["class", "slx-content-toolbar-indents punches-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "left-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "header-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "date-filter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-date-range-ngx", [["name", "startEndDate"]], null, [[null, "rangeDateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rangeDateChanged" === en)) {
        var pd_0 = (_co.onChangeDates($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DateRangeNgxComponent_0, i2.RenderType_DateRangeNgxComponent)), i1.ɵdid(6, 573440, null, 0, i3.DateRangeNgxComponent, [], { startDate: [0, "startDate"], endDate: [1, "endDate"], hasApplyButton: [2, "hasApplyButton"] }, { rangeDateChanged: "rangeDateChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dateRange.startDate; var currVal_1 = _co.dateRange.endDate; var currVal_2 = true; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_EmployeePunchRequestToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-punch-request-toolbar", [], null, null, null, View_EmployeePunchRequestToolbarComponent_0, RenderType_EmployeePunchRequestToolbarComponent)), i1.ɵdid(1, 245760, null, 0, i4.EmployeePunchRequestToolbarComponent, [i5.EmployeePunchRequestManagementService, i6.LookupApiService, i7.ComponentStateStorageService, i8.StateManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeePunchRequestToolbarComponentNgFactory = i1.ɵccf("slx-employee-punch-request-toolbar", i4.EmployeePunchRequestToolbarComponent, View_EmployeePunchRequestToolbarComponent_Host_0, {}, {}, []);
export { EmployeePunchRequestToolbarComponentNgFactory as EmployeePunchRequestToolbarComponentNgFactory };
