import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { PmAttachmentManagementService, PmCreationManagementService } from '../../../services/index';
var PmReviewAttachmentComponent = /** @class */ (function () {
    function PmReviewAttachmentComponent(creationManagement, attachmentService) {
        this.creationManagement = creationManagement;
        this.attachmentService = attachmentService;
        this.allowUpload = true;
        this.allowDownload = true;
        this.disabled = false;
        this.canDelete = true;
        this.addedFiles = new EventEmitter();
        this.deletedFile = new EventEmitter();
        this.deletedAttachment = new EventEmitter();
        this.downloadAttachment = new EventEmitter();
        this.files = [];
        this.errors = {};
        this.subscriptions = {};
    }
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "attachments", {
        set: function (v) {
            if (_.isArray(v)) {
                this.processAttachments(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "hasFiles", {
        get: function () {
            return _.size(this.files) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "maxFiles", {
        get: function () {
            return this.attachmentService.maxFiles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "maxFileSizeBytes", {
        get: function () {
            return this.attachmentService.maxFileSizeBytes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "acceptedFileTypes", {
        get: function () {
            return this.attachmentService.acceptedFileTypes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "acceptedFileTypesInputFormat", {
        get: function () {
            return this.attachmentService.acceptedFileTypesInputFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "acceptedFileTypesReadable", {
        get: function () {
            return this.attachmentService.acceptedFileTypesReadable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewAttachmentComponent.prototype, "maxFileSize", {
        get: function () {
            return this.attachmentService.maxFileSize;
        },
        enumerable: true,
        configurable: true
    });
    PmReviewAttachmentComponent.prototype.ngOnInit = function () { };
    PmReviewAttachmentComponent.prototype.ngOnDestroy = function () { };
    PmReviewAttachmentComponent.prototype.onFileChange = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileList, result, readFiles, files;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.disabled) return [3 /*break*/, 3];
                        fileList = _.get(event, 'target.files');
                        result = this.attachmentService.validateFiles(fileList, event);
                        if (!(result.files.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.attachmentService.readAddedFiles(result.files)];
                    case 1:
                        readFiles = _a.sent();
                        files = this.attachmentService.mapToFiles(readFiles);
                        this.files = this.files.concat(files);
                        this.addedFiles.emit(readFiles);
                        _a.label = 2;
                    case 2:
                        this.errors = result.errors;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PmReviewAttachmentComponent.prototype.onResetErrors = function (event) {
        this.attachmentService.resetInput(event.target);
        this.errors = {};
    };
    PmReviewAttachmentComponent.prototype.onDownloadFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (file.isAttachment) {
                    this.downloadAttachmentFile(file);
                }
                return [2 /*return*/];
            });
        });
    };
    PmReviewAttachmentComponent.prototype.onClickDelete = function (isDelete, acceptPopover, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                acceptPopover.hide();
                if (isDelete) {
                    this.onDeleteAttachment(file);
                }
                return [2 /*return*/];
            });
        });
    };
    PmReviewAttachmentComponent.prototype.onDeleteAttachment = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.disabled) {
                    if (file.isAttachment) {
                        this.deleteAttachment(file);
                    }
                    else {
                        this.deleteFile(file);
                    }
                }
                return [2 /*return*/];
            });
        });
    };
    PmReviewAttachmentComponent.prototype.downloadAttachmentFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.downloadAttachment.emit(file.sourceItem);
                return [2 /*return*/];
            });
        });
    };
    PmReviewAttachmentComponent.prototype.deleteAttachment = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.files = _.filter(this.files, function (f) { return f.id !== file.id; });
                this.deletedAttachment.emit(file.sourceItem);
                return [2 /*return*/];
            });
        });
    };
    PmReviewAttachmentComponent.prototype.deleteFile = function (file) {
        this.files = _.filter(this.files, function (f) { return f.name !== file.name; });
        this.deletedFile.emit(file.sourceItem);
    };
    PmReviewAttachmentComponent.prototype.processAttachments = function (attachments) {
        this.files = this.attachmentService.mapToFiles(attachments);
        if (this.allowUpload) {
            var addedFilesCache = this.creationManagement.getAddedFiles();
            if (addedFilesCache) {
                var addedFiles = this.attachmentService.mapToFiles(addedFilesCache);
                this.files = this.files.concat(addedFiles);
            }
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmReviewAttachmentComponent.prototype, "subscriptions", void 0);
    return PmReviewAttachmentComponent;
}());
export { PmReviewAttachmentComponent };
