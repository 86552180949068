<kendo-grid #grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" sortable="true" [sort]="gridState.state.sort"
  [filterable]="true" [filter]="gridState.state.filter" scrollable="none">

  <kendo-grid-column title="" [sortable]="true" field="description" media="sm" width="266" [filterable]="true">
    <ng-template kendoGridHeaderTemplate>
      Description
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.description}}
    </ng-template>
  </kendo-grid-column>

  <ng-template ngFor let-role [ngForOf]="container?.roles">
    <kendo-grid-column title=""
      [sortable]="false"
      media="sm"
      [filterable]="false"
      width="100"
      [hidden]="!roleColumnsState.isVisible(role)"
    >
      <ng-template kendoGridHeaderTemplate>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <slx-roles-component-access-toggler [rowItem]="dataItem" [roleId]="role.id" (roleToggled)="onRoleComponentToggled($event, dataItem)"></slx-roles-component-access-toggler>
      </ng-template>
    </kendo-grid-column>
  </ng-template>

</kendo-grid>
