import { DetailField } from './detail-field';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';

export class DetailRow {
  public fields: DetailField[];

  public getFieldValue(fieldName: string): any {
    let foundField: DetailField = _.find(
      this.fields,
      (field: DetailField): boolean => field.name.toLowerCase() === fieldName.toLocaleLowerCase()
    );

    if (!foundField) {
      Assert.isNotOwnProperty(fieldName, fieldName);
      return undefined;
    }

    return foundField.value;
  }

  public get isEmpty(): boolean {
    return +this.getFieldValue('EmpId') === 0;
  }

  public get isOutOfPar(): boolean {
    return this.getFieldValue('InPar').toLowerCase() === 'false';
  }

  public get isOvertime(): boolean {
    return this.getFieldValue('IsOvertime').toLowerCase() === 'true';
  }

  public get messageCount(): number {
    return +this.getFieldValue('MessageCount');
  }

  public get key(): string {
    return `${this.getFieldValue('JobCode').toLowerCase()}_${this.getFieldValue(
      'ShiftId'
    ).toLowerCase()}_${this.getFieldValue('UnitId').toLowerCase()}`;
  }

  public get isSlxpartnerdata(): boolean {
    if (this.getFieldValue('SlxpartnerdataId') === 0) {
      return true;
    } else {
      if (this.getFieldValue('RequeststatusId') === 0 || this.getFieldValue('RequeststatusId') === 3) {
        return true;
      } else {
        return false;
      }
    }
  }
  public get shiftStatus(): string {
    if (this.getFieldValue('RequeststatusId') === '1') {
      return 'Pending Agency Assignment';
    }
  }

  public get isPartnerEmployee(): boolean {
    if (this.getFieldValue('EmpId') !== 0 && this.getFieldValue('RequeststatusId') === 2) {
      return true;
    } else {
      return false;
    }
  }

  public get isPendingShift(): boolean {
    if(this.getFieldValue('RequeststatusId') === "1" || this.getFieldValue('RequeststatusId') === "4"){
      return true;
    }else{
      return false;
    }
  }

}
