import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-message-center-notification-content',
    templateUrl: 'message-center-notification-content.component.html',
    styleUrls: ['message-center-notification-content.component.scss']
})

export class MessageCenterNotificationContentComponent {
}
