import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PunchProfileManagementService } from '../../services';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { PunchProfileAttestationQuestionsComponent } from '../punch-profile-attestation-questions/punch-profile-attestation-questions.component';
import { ModalService } from '../../../../common/services/index';
import { PunchAttestationManagementService } from '../../../../app-modules/punch-attestation/services';
var PunchProfileAttestationComponent = /** @class */ (function () {
    function PunchProfileAttestationComponent(punchProfileManagementService, changeDetector, modalService, punchAttestationmanagementService) {
        this.punchProfileManagementService = punchProfileManagementService;
        this.changeDetector = changeDetector;
        this.modalService = modalService;
        this.punchAttestationmanagementService = punchAttestationmanagementService;
        this.isEnabled = false;
        this.legacyclockquestiongroup = 'Legacy clock question group';
    }
    PunchProfileAttestationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setPunchProfileGroupData();
        this.statusSubscription = this.punchProfileManagementService.onLoadStatus$.subscribe(function (isLoading) {
            _this.isLoading = isLoading;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orglevelId = orgLevel.id;
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
            }
        });
        this.punchAttestationmanagementService.getPermissions(this.orglevelId);
    };
    PunchProfileAttestationComponent.prototype.ngOnChanges = function (changes) {
        this.setPunchProfileGroupData();
    };
    PunchProfileAttestationComponent.prototype.savePunchProfileGroup = function () {
        if (this.authorized && !this.disableSaveButton) {
            if (!this.punchProfile) {
                this.punchProfile = {
                    id: 0,
                    groupId: this.group ? this.group.id : 0,
                    profileId: this.profileId
                };
            }
            else {
                this.punchProfile.groupId = this.group ? this.group.id : 0;
            }
            this.punchProfileManagementService.addOrUpdatePunchProfile(this.orgLevel.id, this.punchProfile);
        }
    };
    PunchProfileAttestationComponent.prototype.setPunchProfileGroupData = function () {
        var _this = this;
        if (this.punchProfileGroup) {
            this.punchProfile = this.punchProfileGroup.find(function (x) { return x.profileId == _this.profileId; });
            if (this.punchProfile && this.groups) {
                this.isEnabled = this.punchProfile.groupId !== null ? true : false;
                this.group = this.groups.find(function (x) { return x.id == _this.punchProfile.groupId; });
            }
        }
    };
    PunchProfileAttestationComponent.prototype.viewQuestions = function () {
        var punchProfileData = {
            question: this.group.questions,
            attestationType: this.attestationType,
            punchType: this.punchType,
            isLegacy: this.group.groupName.toLocaleLowerCase() === this.legacyclockquestiongroup.toLocaleLowerCase()
        };
        PunchProfileAttestationQuestionsComponent.openDialog(punchProfileData, this.modalService, function (result, cmd) {
            //todo
        });
    };
    PunchProfileAttestationComponent.prototype.checkboxChecked = function () {
        if (!this.isEnabled) {
            this.group = null;
        }
    };
    Object.defineProperty(PunchProfileAttestationComponent.prototype, "authorized", {
        get: function () {
            return this.punchAttestationmanagementService.configurePunchProfile.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchProfileAttestationComponent.prototype, "disableSaveButton", {
        get: function () {
            if (this.punchProfile) {
                return this.punchProfile.id === 0 && (this.punchProfile.groupId === null || this.punchProfile.groupId === undefined);
            }
            else {
                return (this.group === null || this.group === undefined);
            }
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PunchProfileAttestationComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchProfileAttestationComponent.prototype, "statusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchProfileAttestationComponent.prototype, "orgLevelSubscription", void 0);
    return PunchProfileAttestationComponent;
}());
export { PunchProfileAttestationComponent };
