import {IpSettings} from './ip-settings.model'
import {IpRestriction} from './ip-restriction.model'

export interface IIpManagementConfig {
  settings : IpSettings;
  denyAccessList: IpRestriction[];
  allowAccessList: IpRestriction[];
}
  
export class IpManagementConfig {
  public settings: IpSettings;
  public denyAccessList: IpRestriction[];
  public allowAccessList: IpRestriction[];
}