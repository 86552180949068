import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DeviceDetectorService } from '../../../../../common/services/index';
import { BenefitDetailsUserActions, BenefitDetailsEditModes, BenefitDetailsProviderEntity } from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
var BenefitDetailsProvidersMenuComponent = /** @class */ (function () {
    function BenefitDetailsProvidersMenuComponent(commonManService, permissionService, manService, devDetector) {
        this.commonManService = commonManService;
        this.permissionService = permissionService;
        this.manService = manService;
        this.devDetector = devDetector;
        this.providers = [];
        this.isDisabledLeft = true;
        this.isDisabledRight = true;
        this.canCreate = true;
        this.isEditMode = false;
        this.subscriptions = {};
        this.currentShiftPx = 0;
        this.tabsViewWidth = 0;
        this.totalTabsWidth = 0;
        this.step = 200;
    }
    Object.defineProperty(BenefitDetailsProvidersMenuComponent.prototype, "isMobile", {
        get: function () {
            return this.devDetector.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProvidersMenuComponent.prototype, "isHiddenLeftRigth", {
        get: function () {
            return this.isDisabledLeft && this.isDisabledRight;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsProvidersMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.providers = this.commonManService
            .subscribeToLoadProviders(function (p) {
            if (_.isArray(p)) {
                _this.providers = p;
                if (_.size(p) > 0) {
                    _this.runDesktopFunctionality(null);
                }
                else {
                    _this.selectedProvider = null;
                    _this.isDisabledLeft = true;
                    _this.isDisabledRight = true;
                }
            }
        });
        this.subscriptions.provider = this.commonManService
            .subscribeToSelectProvider(function (p) {
            if (_.isObjectLike(p)) {
                _this.selectedProvider = p;
                _this.runDesktopFunctionality(p);
            }
        });
        this.subscriptions.actions = this.permissionService
            .subscribeToUserActions(function (v) { return (_this.canCreate = v.canAdd); });
        this.subscriptions.editmode = this.permissionService
            .subscribeToEditMode(function (v) { return (_this.isEditMode = v.providerInfo); });
    };
    BenefitDetailsProvidersMenuComponent.prototype.ngOnDestroy = function () {
        this.selectedProvider = null;
    };
    Object.defineProperty(BenefitDetailsProvidersMenuComponent.prototype, "translateX", {
        get: function () {
            return "translateX(-" + this.currentShiftPx + "px)";
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsProvidersMenuComponent.prototype.isActiveMenu = function (p) {
        return this.commonManService.isEqualProviders(this.selectedProvider, p);
    };
    BenefitDetailsProvidersMenuComponent.prototype.onClickTab = function (p) {
        if (this.isActiveMenu(p) || this.isEditMode)
            return;
        this.selectedProvider = p;
        this.commonManService.selectProvider(p, true);
    };
    BenefitDetailsProvidersMenuComponent.prototype.onClickShift = function (isLeft) {
        if (isLeft && !this.isDisabledLeft || !isLeft && !this.isDisabledRight) {
            var shift = isLeft ? this.currentShiftPx - this.step : this.currentShiftPx + this.step;
            this.moveMenuTo(shift);
        }
    };
    BenefitDetailsProvidersMenuComponent.prototype.onChangeProviderOnMobile = function (p) {
        if (this.isEditMode)
            return;
        this.selectedProvider = p;
        this.commonManService.selectProvider(p, true);
    };
    BenefitDetailsProvidersMenuComponent.prototype.onCreateNew = function () {
        this.manService.createDraftProvider();
    };
    BenefitDetailsProvidersMenuComponent.prototype.runDesktopFunctionality = function (p) {
        var _this = this;
        if (!this.isMobile) {
            this.calcMenusDimensions(150)
                .then(function () {
                if (_.isObjectLike(p)) {
                    _this.selectMenu(p);
                }
            });
        }
    };
    BenefitDetailsProvidersMenuComponent.prototype.selectMenu = function (p) {
        var _this = this;
        var index = _.findIndex(this.providers, function (pr) { return _this.commonManService.isEqualProviders(pr, p); });
        var shift = _.reduce(this.ulElem.nativeElement.children, function (accum, elem, i) {
            if (index > i) {
                accum += elem.offsetWidth;
            }
            return accum;
        }, 0);
        this.moveMenuTo(shift);
    };
    BenefitDetailsProvidersMenuComponent.prototype.moveMenuTo = function (shift) {
        var minAvailableShift = 0;
        var maxAvailableShift = Math.max(this.totalTabsWidth - this.tabsViewWidth, 0);
        if (this.totalTabsWidth > this.tabsViewWidth) {
            if (shift >= maxAvailableShift) {
                this.currentShiftPx = maxAvailableShift;
            }
            else if (shift <= minAvailableShift) {
                this.currentShiftPx = minAvailableShift;
            }
            else {
                this.currentShiftPx = shift;
            }
        }
        else {
            this.currentShiftPx = 0;
        }
        this.isDisabledLeft = this.currentShiftPx === minAvailableShift;
        this.isDisabledRight = this.currentShiftPx === maxAvailableShift;
    };
    BenefitDetailsProvidersMenuComponent.prototype.calcMenusDimensions = function (delayMs) {
        var _this = this;
        return Observable.of(true)
            .delay(delayMs)
            .toPromise()
            .then(function () {
            var ul = _this.ulElem.nativeElement;
            var width = _.reduce(ul.children, function (accum, elem) { return (accum + elem.offsetWidth); }, 0);
            if (width > 0) {
                _this.totalTabsWidth = width;
                _this.tabsViewWidth = ul.offsetWidth;
                _this.isDisabledRight = _this.totalTabsWidth <= _this.tabsViewWidth;
            }
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsProvidersMenuComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsProvidersMenuComponent;
}());
export { BenefitDetailsProvidersMenuComponent };
