
  <div class="section-holder">
    <div class="enrollment-tabs">
      <kendo-tabstrip [keepTabContent]="true" class="slx-tabs">
        <kendo-tabstrip-tab title="Enrollment" [selected]="true">
          <ng-template kendoTabContent>
            <slx-benefit-enrollment [canMapPayroll]="canMapPayroll" [isEditable]="isEditable" [employeeStatus] = "employeeStatus" class="slx-tabs__tab"></slx-benefit-enrollment>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="Enrollment History">
          <ng-template kendoTabContent>
            <slx-benefit-enrollment-history class="slx-tabs__tab"></slx-benefit-enrollment-history>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
    <div class="benefit-attachments">
      <slx-benefit-attachments [employeeId]="employeeId" [isEditable]="isEditable"></slx-benefit-attachments>
    </div>
  </div>
