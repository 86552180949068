import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../common/services/index';
import { EmployeeWarningBasic, EmployeeWarningExtended } from '../models/index';
import { EmployeeSectionsWarningsDialogComponent } from '../components/employee-sections-personal/employee-sections-warnings/employee-sections-warnings-dialog/employee-sections-warnings-dialog.component';

@Directive({
  selector: '[slxOpenEmployeeWarningDialog]'
})
export class OpenEmployeeWarningDialogDirective {
  @Input()
  public slxOpenEmployeeWarningDialog: EmployeeWarningBasic;

  @Output()
  public saveWarning = new EventEmitter<EmployeeWarningExtended>();

  constructor(private readonly modalService: ModalService) {}

  @HostListener('click')
  public onClick(): void {
    const dialog = EmployeeSectionsWarningsDialogComponent.openDialog(
      this.slxOpenEmployeeWarningDialog,
      this.modalService,
      (isSave: boolean) => {
        if (isSave) {
          this.saveWarning.next(dialog.warningExtended);
        }
      });
  }
}
