<slx-spinner [show]="state.isLoading">
  <div class="pbj-log-container">
    <div class="slx-content-toolbar-indents slx-flex-row">
      <div>
        <slx-daterange class="slx-width-260" name="startEndDate" [startDate]="startDate" [endDate]="endDate"
          (rangeDateChanged)="onDataRangeChange($event)"></slx-daterange>
      </div>
      <div class="r-col button-container">
        <button type="button" (click)="applyFilter()" class="slx-button slx-min-w90 slx-margin-l">Apply</button>
        <div class="v-spacer" *ngIf="!isAgencyOrglevel || canGeneratePBJFromAgency"></div>
        <div class="btn-tooltip-holder" *ngIf="!isAgencyOrglevel || canGeneratePBJFromAgency">
          <button class="slx-button" (click)="onConfigure()" [disabled]="isConfigureDisabled"
            [slxTooltip]="getConfigureTooltip (mealDeductionSettings)" tipPosition="left">
            <i class="fa fa-cog" aria-hidden="true"></i>
            Configure
          </button>
        </div>
        <div class="spacer"></div>
        <button type="button" [ngClass]="IsPBJExportCorpLevelEnabled ? 'btn' : 'slx-button slx-min-w90 slx-margin-l'" *ngIf="!isAgencyOrglevel || canGeneratePBJFromAgency" [disabled]="disableNewExportBtn" (click)="onNewExport()" class="slx-button slx-min-w90 slx-margin-l">New Export</button>
      </div>
    </div>
    <div class="pbj-log-grid-container slx-main-content-indents">
      <slx-pbj-log-grid *ngIf="!IsPBJExportCorpLevelEnabled"></slx-pbj-log-grid>
      <slx-pbj-export-grid-parent [startDate]="startDate" [endDate]="endDate" *ngIf="IsPBJExportCorpLevelEnabled"></slx-pbj-export-grid-parent>
    </div>
  </div>
</slx-spinner>