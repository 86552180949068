<slx-spinner [show]="state.isLoading">
  <div class="individual-timecards-container slx-high-box">
    <div class="slx-content-toolbar-indents slx-auto-height flex-row jc-space-between">
      <div class="paycycle-list timecards-padd-r">
        <slx-paycycle-dropdown
        class="slx-width-300 paycycles"
        [resetSelectedPayCycleOnEmployeeChange]="false"
        [selectedPayCycle]="currentPayCycle"
        [employeeId]="employeeId"
        (payCycleSelected)="onPayCycleSelected($event)"
        ></slx-paycycle-dropdown>
      </div>
      <div class="flex-row ai-flex-end">
        <slx-switcher
          class="timecards-padd-r switcher high-precision hidden-on-mobile"
          [ngModel]="showHighPrecision"
          (ngModelChange)="onVisibilityHighPrecisionChanged($event)"
          label="High Precision"
          labelOn="Yes"
          labelOff="No"
        ></slx-switcher>

          <slx-switcher
          *ngIf="!hideRates"
          class="timecards-padd-r switcher"
          [ngModel]="isShowPayRates"
          (ngModelChange)="onVisibilityPayRatesChanged($event)"
          label="Show Pay Rates"
          labelOn="Yes"
          labelOff="No"
        ></slx-switcher>

        <div class="timecards-padd-r hidden-on-mobile">
          <button type="button"
            class="slx-button slx-only-icon slx-toolbar"
            [popper]="columnsToggler"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-start'"
            [popperDisableStyle]="true"
          >
            <i class="fa fa-cog" aria-hidden="true"></i>
          </button>
          <popper-content #columnsToggler class="slx-popper">
            <span class="timecards-popper-title">Columns to Display</span>
            <div class="columns-container">
              <div *ngFor="let column of this.globalState.empColumns.columns; let idx=index;">
                <slx-input-decorator *ngIf="isColumnVisible(column.name)" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                  <slx-checkbox-input slx-input name="column{{idx}}" fieldName="column{{idx}}" [caption]="column.description" [(ngModel)]="column.displayed"
                    (ngModelChange)="onChange($event, column)">
                  </slx-checkbox-input>
                </slx-input-decorator>
              </div>
            </div>
            <div class="button-container">
              <button class="slx-button slx-margin-r" type="button" (click)="selectAll($event)"> Select All </button>
              <button class="slx-button" type="button" (click)="clearAll($event)"> Clear All</button>
            </div>
          </popper-content>
        </div>

        <div class="timecards-padd-r hidden-on-mobile" [ngClass]="{'hidden': !hasRulesDefinitions}">
          <button type="button"
            class="slx-button slx-only-icon slx-toolbar"
            [popper]="payCodeColumnsToggler"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-start'"
            [popperDisableStyle]="true"
          >
            <i class="fas fa-sliders-h" aria-hidden="true"></i>
          </button>
          <popper-content #payCodeColumnsToggler class="slx-popper">
            <span class="timecards-popper-title">Pay Code Columns to Display</span>
            <div class="columns-container">
              <div *ngFor="let column of this.globalState.payCodeColumns?.columns; let idx=index;">
                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                  <slx-checkbox-input slx-input name="payCodeColumn{{idx}}" fieldName="payCodeColumn{{idx}}" [caption]="column.description" [(ngModel)]="column.displayed"
                    (ngModelChange)="onChange($event, column)">
                  </slx-checkbox-input>
                </slx-input-decorator>
              </div>
            </div>
            <div class="button-container">
              <button class="slx-button slx-margin-r" type="button" (click)="selectAllPayCodes($event)"> Select All </button>
              <button class="slx-button" type="button" (click)="clearAllPayCodes($event)"> Clear All</button>
            </div>
          </popper-content>
        </div>

        <slx-action-list class="hidden-on-mobile">
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-right'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onViewSummary()" [disabled]="!container?.canViewSummary || (!this.isOrganizationOrgLevel && !hasParentOrganizationId)">
              View Summary <span *ngIf="!container?.canViewSummary || (!this.isOrganizationOrgLevel && !hasParentOrganizationId)">(No rights)</span>
            </slx-action-list-item>
            <slx-action-list-item *ngIf="canSeePayroll" (onClick)="activateNGPSSO(container?.payRollLink)">Payroll</slx-action-list-item>
            <slx-action-list-item (onClick)="onShowAccruals()">Show Accruals</slx-action-list-item>
            <slx-action-list-item (onClick)="onRecalculateTimecards()" [disabled]="isLocked || !container?.canRecalculate || container?.isPayCycleSubmitted">
              Recalculate Timecards
              <span *ngIf="!container?.canRecalculate">(No rights)</span>
              <span *ngIf="isLocked">(Timecard is Locked)</span>
            </slx-action-list-item>
            <slx-action-list-item *ngIf="!container?.approved"  (onClick)="onApproveTimecards()"
              [disabled]="isLocked || !container?.canModifyOwnTimecard || !container?.canApprove || container?.hasErrors || container?.hasUnapprovedOvertime || container?.isPayCycleSubmitted || !container.isApprovablePayCycle">
              Approve Timecards
              <span *ngIf="!container?.canModifyOwnTimecard || !container?.canApprove">(No rights)</span>
              <span *ngIf="container?.canApprove && container?.hasUnapprovedOvertime">(Has unapproved OT)</span>
              <span *ngIf="!container?.isApprovablePayCycle">(Payroll cycle is not over)</span>
              <span *ngIf="isLocked">(Timecard is Locked)</span>
            </slx-action-list-item>

            <slx-action-list-item *ngIf="container?.hasUnapprovedOvertime" (onClick)="onApproveOvertime()"
              [disabled]="isLocked">
              Approve Overtime
              <span *ngIf="isLocked">(Timecard is Locked)</span>
            </slx-action-list-item>
            <slx-action-list-item *ngIf="container?.approved" (onClick)="onUnapproveTimecards()"
              [disabled]="isLocked || !container?.canModifyOwnTimecard || !container?.canUnapprove || container?.isPayCycleSubmitted">
              Unapprove Timecards <span *ngIf="!container?.canModifyOwnTimecard || !container?.canUnapprove">(No rights)</span>
              <span *ngIf="isLocked">(Timecard is Locked)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onExportToExcel()" [disabled]="!container?.canExportToExcel">
              Export to Excel <span *ngIf="!container?.canExportToExcel">(No rights)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onExportToPdf()" [disabled]="!container?.canExportToPDF">
              Export to PDF <span *ngIf="!container?.canExportToPDF">(No rights)</span>
            </slx-action-list-item>            
          </popper-content>
        </slx-action-list>
      </div>

    </div>

    <div class="slx-high-box__body slx-main-content-indents ind-timecards-body">
      <div>
        <header class="ind-timecards-emp">
          <div class="ind-timecards-emp__left">
            <div class="ind-timecards-emp__top">
              <span class="ind-timecards-emp__buttons">
                <button type="button"
                  class="slx-button slx-with-icon slx-toolbar slx-margin-r"
                  [disabled]="!hasPrevEmployee"
                  (click)="onNavByEmployee(false)"
                  title="Previous employee"
                >
                  <i class="fal fa-angle-left slx-button__icon" aria-hidden="true"></i>
                  <span class="slx-button__text hidden-on-mobile">Prev</span>
                </button>
                <button type="button"
                  class="slx-button slx-with-icon slx-toolbar"
                  [disabled]="!hasNextEmployee"
                  (click)="onNavByEmployee(true)"
                  title="Next employee"
                >
                  <span class="slx-button__text slx-right hidden-on-mobile">Next</span>
                  <i class="fal fa-angle-right slx-button__icon" aria-hidden="true"></i>
                </button>
              </span>
              <h3 class="ind-timecards-emp__title">
                <i class="fas fa-user-circle ind-timecards-emp__title-i" aria-hidden="true"></i>
                <a class="ind-timecards-emp__link" [employeeSectionLink]="activeEmployee?.id">{{activeEmployee?.name}}</a>
              </h3>
              <div class="ind-timecards-emp__selector">
                <slx-employee-search-field class="filter-string" [isActive]="false" [orgLevelId]="managementService.orgLevel.id" [empIdsToShow]="employeeIds" 
                (employeeSelected)="onEmployeeChosen($event)" [hideDetails]="true" [isShownEmpNameLabel]="false">
                </slx-employee-search-field>
              </div>
            </div>
            <p class="ind-timecards-emp__info">
              <span class="ind-timecards-emp__row">
                <span class="ind-timecards-emp__info-i"><strong>Position:</strong> {{container?.primaryPosition.name}}</span>
                <span class="ind-timecards-emp__info-i"><strong>Id:</strong> {{container?.employee.id}}</span>
                <span class="ind-timecards-emp__info-i"><strong>Exempt Ind:</strong> {{container?.exemptStatus?.name}}</span>
                <span class="ind-timecards-emp__toggler" (click)="hideDetails = !hideDetails">
                  <ng-container *ngIf="hideDetails">+Show more</ng-container>
                  <ng-container *ngIf="!hideDetails">-Hide</ng-container>
                </span>
              </span>
              <span [ngClass]="{ 'ind-timecards-emp__hidden': hideDetails }">
                <span class="ind-timecards-emp__info-i"><strong>Pay Policy:</strong> {{container?.payPolicy?.name}}</span>
                <span class="ind-timecards-emp__info-i"><strong>Shift Diff Policy:</strong> {{container?.shiftDiffPolicy?.name}}</span>
                <span class="ind-timecards-emp__info-i"><strong>Payroll #:</strong> {{container?.employee.payrollNumber}}</span>
                <span class="ind-timecards-emp__info-i"><strong>Type:</strong> {{container?.employee.employeeType.name}}</span>
                <span class="ind-timecards-emp__info-i"><strong>Hire Date:</strong> {{container?.employee.dateHired | amDateFormat: appConfig.dateFormat}}</span>
                <span *ngIf="container?.infoDisplay" class="ind-timecards-emp__info-i"><strong>Info:</strong> {{ container?.infoDisplay }}</span>
              </span>
            </p>
          </div>
          <div class="messages-container">
            <!--<p *ngIf="container?.hasErrors" class="ind-timecards-emp__err">Missing punches exist on some of the Daily Timecards below! <strong>Only timecards without missing punches can be approved.</strong></p>-->
            <slx-single-message [isActive]="isLocked" level="warning">
              <div class="slx-single-message__message">Timecard is readonly.
                <span class="slx-single-message__description">Timecard has been locked!</span>
              </div>
            </slx-single-message>
            <slx-single-message [isActive]="container && container?.isPayCycleSubmitted" level="warning">
              <div class="slx-single-message__message">Timecard is readonly.
                <span class="slx-single-message__description">Payroll has been submitted!</span>
              </div>
            </slx-single-message>
            <slx-single-message [isActive]="container && container?.hasErrors" level="warning">
              <div class="slx-single-message__message">Missing punches exist on some of the Daily Timecards below!
                <span class="slx-single-message__description">Only timecards without missing punches can be approved.</span>
              </div>
            </slx-single-message>
            <slx-single-message [isActive]="container && !container?.canModifyOwnTimecard && !container?.approved" level="warning">
              <div class="slx-single-message__message">Timecard is readonly.
                <span class="slx-single-message__description">You are not authorized to approve your own timecard.</span>
              </div>
            </slx-single-message>
            <slx-single-message [isActive]="container && !container?.canModifyOwnTimecard && container?.approved" level="warning">
              <div class="slx-single-message__message">Timecard is readonly.
                <span class="slx-single-message__description">You are not authorized to unapprove your own timecard.</span>
              </div>
            </slx-single-message>
            <slx-single-message [isActive]="container && container?.approved" level="success">
              <div class="slx-single-message__message">
                This time card has been approved by {{ container?.approvedBy }} on {{ container?.approvedOn | amDateFormat: appConfig.dateFormat }}.
              </div>
            </slx-single-message>
            <slx-single-message [isActive]="container && container?.certified" level="success">
              <div class="slx-single-message__message">
                This timecard has been signed-off by {{ container?.certifiedBy }} on {{ container?.certifiedOn | amDateFormat: appConfig.dateTimeFormatUS }}.
              </div>
            </slx-single-message>
          </div>
        </header>
      </div>
      <slx-spinner [show]="state.isTableLoading">
        <slx-individual-timecards-flat-grid class="slx-high-box__body"></slx-individual-timecards-flat-grid>
      </slx-spinner>
    </div>
  </div>
</slx-spinner>
