<slx-spinner [show]="isLoading" [heightNotFill]="true">
  <div class="ta-console-widget arrivals">
    <div class="header">
      <div class="header-inner">
        <div class="arrivals-item header-item">
          <i class="header-main-icon fas fa-arrow-alt-circle-down" aria-hidden="true"></i>
          <h4 class="arrivals-label">Arrivals</h4>
          <span class="badge blue-label">{{recordsSum}}</span>
        </div>
        <div class="spacer"></div>
        <div class="late-item header-item">
          <i class="far fa-calendar-exclamation" aria-hidden="true"></i>
          <h4 class="late-label">Late</h4>
          <span [ngClass]="{'badge':true, 'bad-label': lateSum > 0}">{{lateSum}}</span>
        </div>
        <div class="collapse-toggle header-item" (click)="toggleState()" slxPropertyInitState controlId="isArrivalsWigetCollapsed" [(propertySave)]="collapsed">
            <i class="toggle-icon far fa-angle-down" aria-hidden="true" *ngIf="!collapsed"></i>
            <i class="toggle-icon far fa-angle-up" aria-hidden="true" *ngIf="collapsed"></i>
        </div>
      </div>
    </div>
    <div [ngClass]="{'grid-container': true, 'collapsed': collapsed}">
      <slx-arrivals-grid [records]="arrivalDeparturesContainer?.arrivals" (onInFieldClick)="onInFieldClickHandler($event)" (onScheduledFieldClick)="onScheduledFieldClickHandler($event)"
        (onLateFieldClick)="onLateFieldClickHandler($event)" (onDifferenceFieldClick)="onDifferenceFieldClickHandler($event)" (onRowSelected)="onRowSelectedHandler($event)">
      </slx-arrivals-grid>
    </div>
  </div>
</slx-spinner>
