import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';


import { ScheduleConsoleContainerDTO, EntityIndexSummaryDTO, DailyIndexSummaryDTO, EntityDefinitionDTO, ScheduleConsoleFilterDTO } from '../../models/dto/index';
import {
  ScheduleConsoleCycles, Comparison, ComparisonItem, DisplayType, ScheduleConsoleFilterItem,
  EntityDefinition
} from '../../models/index';
import { ScheduleConsoleEntry } from '../../models/index';
import { LocationUnit, ILocationUnit, ShiftGroupDefinition, IShiftGroupDefinition } from '../../../../organization/models/index';
import { CalendarDataService } from '../../../../core/services/calendar-data/calendar-data.service';

@Injectable()
export class ScheduleConsoleMapService {

  constructor(private readonly calendarService: CalendarDataService) {

  }

  public mapScheduleConsoleCycles(data: ScheduleConsoleContainerDTO, startDate: Date): ScheduleConsoleCycles {
    let scheduleConsoleCycles: ScheduleConsoleCycles = new ScheduleConsoleCycles();
    const list: Comparison[] = _.reduce(data.entityIndexSummary, (accumulator: Comparison[], summary: EntityIndexSummaryDTO) => {
      accumulator.push(...this.mapOrgLevelScheduleCycle(summary));

      return accumulator;
    }, []);
    scheduleConsoleCycles.groupByUnit = this.fillMissingEntryDates(this.groupByUnit(list), startDate);
    scheduleConsoleCycles.groupByShift = this.fillMissingEntryDates(this.groupByShift(list), startDate);
    scheduleConsoleCycles.groupByPosition = this.groupByPosition(list, startDate);
    scheduleConsoleCycles.filters = _.map(data.filter, (item: ScheduleConsoleFilterDTO) => this.mapFilterItem(item));
    scheduleConsoleCycles.lowerLimit = +data.lowerLimit.value;
    scheduleConsoleCycles.upperLimit = +data.upperLimit.value;
    scheduleConsoleCycles.filterType = data.filter[0].type || 'position';

    return scheduleConsoleCycles;
  }

  public mapFilterItem(data: ScheduleConsoleFilterDTO): ScheduleConsoleFilterItem {
    let item: ScheduleConsoleFilterItem = new ScheduleConsoleFilterItem();
    item.id = data.id;
    item.name = data.name;
    item.type = data.type;
    item.isDirectCare = _.has(data, 'isDirectCare') ? data.isDirectCare : false;
    return item;
  }

  public mapOrgLevelScheduleCycle(data: EntityIndexSummaryDTO): Comparison[] {
    const orgLevel: EntityDefinition = new EntityDefinition(data.entity.id, data.entity.name, data.entity.type);

    return _.map(data.dailySummaryIndexes, (summary: DailyIndexSummaryDTO, index: number) => {
      const comparsion: Comparison = this.mapComparison(summary);
      comparsion.orgLevel = orgLevel;

      return comparsion;
    });
  }

  public mapComparison(data: DailyIndexSummaryDTO): Comparison {
    let comparison: Comparison = new Comparison();
    comparison.budgetedHours = new ComparisonItem();
    comparison.budgetedPPD = new ComparisonItem();
    comparison.idealHours = new ComparisonItem();
    comparison.idealPPD = new ComparisonItem();
    comparison.budgetedHours.limit = data.budgetedHours.limit;
    comparison.budgetedHours.value = data.budgetedHours.value;
    comparison.idealHours.limit = data.idealHours.limit;
    comparison.idealHours.value = data.idealHours.value;
    comparison.budgetedPPD.limit = data.budgetedPPD.limit;
    comparison.budgetedPPD.value = data.budgetedPPD.value;
    comparison.idealPPD.limit = data.idealPPD.limit;
    comparison.idealPPD.value = data.idealPPD.value;
    comparison.date = moment(data.date);
    comparison.pendingMessagesCount = data.messages;
    comparison.overtimeEmployees = data.overtimeEmployees;
    comparison.ptoRequests = data.ptoRequests;
    comparison.absentCount = data.absentCount;
    comparison.lateCount = data.lateCount;
    comparison.shiftGroup = this.mapShift(data.shiftGroup);
    comparison.unit = this.mapUnit(data.unit);
    comparison.isEmpty = false;

    return comparison;
  }

  private mapShift(shiftGroup: IShiftGroupDefinition): ShiftGroupDefinition {
    const id: number = _.get(shiftGroup, 'id') || 0;
    const name: string = _.get(shiftGroup, 'name') || 'Unscheduled';
    const startTime: string = _.get(shiftGroup, 'startTime') || '';
    const endTime: string = _.get(shiftGroup, 'endTime') || '';

    return new ShiftGroupDefinition(id, name, startTime, endTime);
  }
  private mapUnit(u: ILocationUnit): LocationUnit {
    const unit: LocationUnit = new LocationUnit();
    unit.id = _.get(u, 'id') || 0;
    unit.name = _.get(u, 'name') || 'Unscheduled';

    return unit;
  }

  private groupByUnit(entries: Comparison[]): ScheduleConsoleEntry[] {
    const groupByUnit: ScheduleConsoleEntry[] = [];
    _.forEach(entries, (entry: Comparison) => {
      this.addSoEntry(groupByUnit, entry, entry.unit.name);
    });
    this.groupByFirstLevel(groupByUnit, true);
    this.groupBySecondLevel(groupByUnit);

    return groupByUnit;
  }

  private groupByShift(entries: Comparison[]): ScheduleConsoleEntry[] {
    const groupByShift: ScheduleConsoleEntry[] = [];
    _.forEach(entries, (entry: Comparison) => {
      this.addSoEntry(groupByShift, entry, entry.shiftGroup.name);
    });
    this.groupByFirstLevel(groupByShift, false);
    this.groupBySecondLevel(groupByShift);

    return groupByShift;
  }

  private groupComparisonsByDay(entries: Comparison[]): Comparison[] {
    const groupedComparisons: Comparison[] = [];

    const uniqueComparisonsDates: moment.Moment[] = _.map(
      _.uniqWith(entries, (a: Comparison, b: Comparison) => a.date.isSame(b.date)),
      (comparison: Comparison) => comparison.date
    );

    _.forEach(uniqueComparisonsDates, (date: moment.Moment) => {
      const dateComparisons = _.filter(entries, (comparison: Comparison) => comparison.date.isSame(date));
      groupedComparisons.push(Comparison.mergeComparisons(...dateComparisons));
    });

    return groupedComparisons;
  }

  private groupByPosition(entries: Comparison[], startDate: Date): ScheduleConsoleEntry[] {
    const groupByPosition: ScheduleConsoleEntry[] = [];
    _.forEach(entries, (entry: Comparison) => {
      this.addSoEntry(groupByPosition, entry, entry.orgLevel.name);
    });

    _.forEach(groupByPosition, (entry: ScheduleConsoleEntry) => {
      entry.values = this.fillMissingComparisonsDates(this.groupComparisonsByDay(entry.values), startDate);
    });

    return groupByPosition;
  }

  private groupByFirstLevel(groupedByRootLevel: ScheduleConsoleEntry[], isUnit: boolean): void {
    _.forEach(groupedByRootLevel, (e: ScheduleConsoleEntry) => {
      const groupByUnit: ScheduleConsoleEntry[] = [];
      const groupByShift: ScheduleConsoleEntry[] = [];

      _.forEach(e.values, (entry: Comparison) => {
        if (isUnit) {
          this.addSoEntry(groupByShift, entry, entry.shiftGroup.name);
        } else {
          this.addSoEntry(groupByUnit, entry, entry.unit.name);
        }
      });

      if (isUnit) {
        e.values = groupByShift;
      } else {
        e.values = groupByUnit;
      }
    });
  }

  private groupBySecondLevel(groupedByRootLevel: ScheduleConsoleEntry[]): void {
    _.forEach(groupedByRootLevel, (groupedByFirstLevel: ScheduleConsoleEntry) => {
      _.forEach(groupedByFirstLevel.values, (e: ScheduleConsoleEntry) => {
        const groupByPosition: ScheduleConsoleEntry[] = [];
        _.forEach(e.values, (entry: Comparison) => {
          this.addSoEntry(groupByPosition, entry, entry.orgLevel.name);
        });

        this.sortValues(groupByPosition);

        e.values = groupByPosition;
      });
    });
  }

  private sortValues(groupedByChildLevel: ScheduleConsoleEntry[]): void {
    _.forEach(groupedByChildLevel, (entry: ScheduleConsoleEntry) => {
      entry.values = _.sortBy(entry.values, (com: Comparison) => com.orgLevel.name);
    });
  }

  private addSoEntry(entriesList: ScheduleConsoleEntry[], e: Comparison, name: string): void {
    let entry: ScheduleConsoleEntry = _.find(entriesList, { 'name': name });
    if (!entry) {
      entry = new ScheduleConsoleEntry(name);
      entriesList.push(entry);
    }

    entry.values.push(e);
  }

  private fillMissingEntryDates(entries: ScheduleConsoleEntry[], startDate: Date): ScheduleConsoleEntry[] {
    _.forEach(entries, groupLevel1 => {
      _.forEach(groupLevel1.values, groupLevel2 => {
        _.forEach(groupLevel2.values, position => {
          position.values = this.fillMissingComparisonsDates(position.values, startDate);
        });
      });
    });

    return entries;
  }

  private fillMissingComparisonsDates(comparisons: Comparison[], startDate: Date): Comparison[] {
    if (!comparisons || !comparisons[0]) {
      return comparisons;
    }

    let filledComparisons: Comparison[] = _.cloneDeep(comparisons);

    _.forEach(filledComparisons, (comparison: Comparison) => {
      const weeekRange = this.calendarService.ExtendRangeToDailyData(moment(startDate), 7, filledComparisons);
      filledComparisons = _.map(weeekRange, x => x.data || this.createDefaultComparison(filledComparisons[0].unit, filledComparisons[0].shiftGroup, x.startOfDay));
    });

    return filledComparisons;
  }

  private createDefaultComparison(unit: LocationUnit, shiftGroup: ShiftGroupDefinition, date: moment.Moment): Comparison {
    let comparison: Comparison = new Comparison();
    comparison.budgetedHours = new ComparisonItem(0, 0);
    comparison.budgetedPPD = new ComparisonItem(0, 0);
    comparison.idealHours = new ComparisonItem(0, 0);
    comparison.idealPPD = new ComparisonItem(0, 0);
    comparison.pendingMessagesCount = 0;
    comparison.overtimeEmployees = 0;
    comparison.ptoRequests = 0;
    comparison.absentCount = 0;
    comparison.lateCount = 0;
    comparison.date = moment(date).clone();
    comparison.unit = this.mapUnit(unit);
    comparison.shiftGroup = this.mapShift(shiftGroup);
    comparison.isEmpty = true;

    return comparison;
  }
}
