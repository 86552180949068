import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { ExportDataStatus } from '../../../enums/export-data-status';
import * as _ from 'lodash';
import { unsubscribeAll } from '../../../../../core/decorators';
import * as moment from 'moment';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { LookupApiService } from '../../../../../organization/services';
import { ConfirmDialog2Component, ConfirmOptions, ModalService } from '../../../../../common/index';
import { AcaExportType, getAcaRelatedExportTypes } from '../../../enums/aca-export-type';
import { receiptIdMaskRegExp } from '../../../export-data.config';
var AcaExportSectionComponent = /** @class */ (function () {
    function AcaExportSectionComponent(manService, changeDetector, lookupApiService, modalService) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.lookupApiService = lookupApiService;
        this.modalService = modalService;
        this.subscriptions = {};
        this.yearOptions = [];
        this.yearOptionsTestXml = [2023];
        this.monthOptions = [];
        this.qualifiedOfferOptions = ['Yes', 'No'];
        this.nintyEightOfferOptions = ['Yes', 'No'];
        this.replacementOptions = ['Yes', 'No'];
        this.employersLookup = [];
        this.currentYear = moment().startOf('day').toDate().getFullYear();
        this.receiptIdMaskConf = receiptIdMaskRegExp;
    }
    Object.defineProperty(AcaExportSectionComponent.prototype, "isFormValid", {
        get: function () {
            if (!this.configuration.exportParams.employerId || this.aleMembersInValid) {
                return false;
            }
            if (this.configuration.requiresAllEmployeesToBeConfirmed && !this.areAllEmployeesConfirmed) {
                return false;
            }
            if (this.configuration.requiresAtLeastOneEmployeeToBeConfirmed && this.confirmedEmployees < 1) {
                return false;
            }
            if (this.configuration.requiresTccCode && !this.employerTccCode) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportSectionComponent.prototype, "aleMembersInValid", {
        get: function () {
            if (this.configuration.exportParams.hidden) {
                return false;
            }
            if (this.aleMembersCount <= 1) {
                return false;
            }
            if (this.configuration.exportParams.memberOfAleGroup.length) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportSectionComponent.prototype, "isExpired", {
        get: function () {
            return this.configuration.lastExecuted &&
                (this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.completedOn < moment().subtract(1, 'years').toDate()
                    || !this.configuration.lastExecuted.completedOn && this.configuration.lastExecuted.startedOn < moment().subtract(1, 'years').toDate());
        },
        enumerable: true,
        configurable: true
    });
    AcaExportSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createYearOptions();
        this.createMonthOptions();
        this.bindEmployerLookup();
        this.configuration.exportParams.memberOfAleGroup = this.configuration.exportParams.memberOfAleGroup.length == 0 ? this.monthOptions : this.configuration.exportParams.memberOfAleGroup;
        this.configuration.exportParams.offeredMec = this.configuration.exportParams.offeredMec.length == 0 ? this.monthOptions : this.configuration.exportParams.offeredMec;
        var relatedExports = getAcaRelatedExportTypes(this.configuration.exportType);
        this.subscriptions.exportExecuted = this.manService.subscribeToExportExecuted(function (v) {
            if (_.includes(relatedExports, v.type)) {
                _this.configuration.lastExecuted = v;
            }
        });
        if (!this.configuration.isCollapsed) {
            this.manService.loadExportDataHistory(this.configuration.id);
        }
    };
    AcaExportSectionComponent.prototype.ngOnDestroy = function () {
        this.configuration = null;
        this.isSubmitted = false;
        this.changeDetector.detach();
    };
    AcaExportSectionComponent.prototype.bindEmployerLookup = function () {
        var _this = this;
        this.manService.setLoadingStatus(true);
        this.lookupApiService.getAcaEmployers().then(function (employers) {
            _this.manService.setLoadingStatus(false);
            _this.employersLookup = employers;
            if (_this.configuration.exportParams.employerId !== 0) {
                _this.selectedEmployer = _.find(_this.employersLookup, [
                    'companyId',
                    _this.configuration.exportParams.employerId
                ]);
                _this.setEmployerDetails(_this.selectedEmployer);
            }
        }).catch(function () {
            _this.manService.setLoadingStatus(false);
        });
    };
    AcaExportSectionComponent.prototype.createYearOptions = function () {
        this.yearOptions = _.reverse(_.values(_.range(2016, this.currentYear + 1)));
    };
    AcaExportSectionComponent.prototype.createMonthOptions = function () {
        for (var month = 0; month < 12; month++) {
            this.monthOptions.push({
                id: (month + 1),
                name: moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM')
            });
        }
    };
    Object.defineProperty(AcaExportSectionComponent.prototype, "exportDataStatus", {
        get: function () {
            return ExportDataStatus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportSectionComponent.prototype, "canGenerate", {
        get: function () {
            return this.configuration && (!this.configuration.lastExecuted || (this.configuration.lastExecuted.status !== ExportDataStatus.Waiting &&
                this.configuration.lastExecuted.status !== ExportDataStatus.InProgress));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaExportSectionComponent.prototype, "canCancel", {
        get: function () {
            return this.configuration && !this.canGenerate;
        },
        enumerable: true,
        configurable: true
    });
    AcaExportSectionComponent.prototype.collapsedChange = function (isCollapsed) {
        if (!isCollapsed && (!this.configuration.history || (this.configuration.history && this.configuration.history.length == 0))) {
            this.manService.loadExportDataHistory(this.configuration.id);
        }
    };
    AcaExportSectionComponent.prototype.generate = function () {
        var _this = this;
        if (this.isSubmitted || !this.canGenerate) {
            return;
        }
        if (this.configuration.exportParams.replacement == 'Yes' && (this.configuration.exportParams.receiptId == null || this.configuration.exportParams.receiptId == '')) {
            this.showReceiptDialog();
        }
        else {
            var options = new ConfirmOptions();
            options.showOK = true;
            options.showCancel = true;
            options.height = 250;
            ConfirmDialog2Component.openDialog('Warning', "The " + this.configuration.name + " export will only include records for employer: " + this.configuration.exportParams.employerName + " and will only include 1095-C records for which all 12 months have been confirmed. Please verify that your organization has reviewed and confirmed all suggested values as needed and that you wish to proceed with generating the export.", this.modalService, (function (result) {
                if (!result) {
                    return;
                }
                _this.isSubmitted = true;
                _this.manService.generateAcaExport(_this.configuration.exportParams);
                _this.isSubmitted = false;
            }), options);
        }
    };
    AcaExportSectionComponent.prototype.cancelExecution = function () {
        this.manService.cancelAcaExport(this.configuration.id, this.configuration.lastExecuted.id);
    };
    AcaExportSectionComponent.prototype.onYearChange = function (year) {
        this.updateEmployerStatus();
    };
    AcaExportSectionComponent.prototype.onEmployersChanged = function (item) {
        this.aleMembersCount = 0;
        this.employerTccCode = null;
        this.confirmedEmployees = null;
        this.areAllEmployeesConfirmed = null;
        if (item) {
            this.setEmployerDetails(item);
        }
    };
    AcaExportSectionComponent.prototype.setEmployerDetails = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var options;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.configuration.exportParams.employerId = item.companyId;
                        this.configuration.exportParams.employerName = item.legalName;
                        this.aleMembersCount = item.aleMembers;
                        this.employerTccCode = item.tccCode;
                        return [4 /*yield*/, this.updateEmployerStatus()];
                    case 1:
                        _a.sent();
                        if (this.configuration.requiresTccCode && !this.employerTccCode) {
                            options = new ConfirmOptions();
                            options.showOK = true;
                            options.showCancel = false;
                            options.height = 250;
                            ConfirmDialog2Component.openDialog('Error', "The selected employer has no Transmitter Control Code (TCC) Configured. Please enter the appropriate code obtained directly from the IRS into the appropriate field on the Manage Employer screen of the ACA module to continue.", this.modalService, function () { }, options);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    AcaExportSectionComponent.prototype.updateEmployerStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var employerStatus;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.configuration.exportParams.employerId || !this.configuration.exportParams.year) {
                            return [2 /*return*/];
                        }
                        if (!(this.configuration.requiresAllEmployeesToBeConfirmed || this.configuration.requiresAtLeastOneEmployeeToBeConfirmed)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.manService.getEmployerStatus(this.configuration.exportParams.employerId, this.configuration.exportParams.year)];
                    case 1:
                        employerStatus = _a.sent();
                        if (employerStatus) {
                            this.confirmedEmployees = employerStatus.confirmedEmployees;
                            this.areAllEmployeesConfirmed = employerStatus.areAllEmployeesConfirmed;
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    AcaExportSectionComponent.prototype.generateBtnTooltip = function () {
        var message = '';
        if (!this.configuration.exportParams.employerId) {
            message += 'Please select an employer.';
        }
        if (this.configuration.exportParams.employerId) {
            if (this.configuration.requiresAllEmployeesToBeConfirmed
                && !this.areAllEmployeesConfirmed) {
                if (message.length) {
                    message += ' ';
                }
                message += 'Not all qualified employees have been confirmed.';
            }
            if (this.configuration.requiresAtLeastOneEmployeeToBeConfirmed
                && this.confirmedEmployees < 1) {
                if (message.length) {
                    message += ' ';
                }
                if (this.configuration.exportType === AcaExportType.Export1095C) {
                    message += 'At least one 1095-C record must be confirmed for all 12 months before generating 1095-C PDFs.';
                }
                else {
                    message += 'At least one 1095-C record must be confirmed for all 12 months.';
                }
            }
            if (this.configuration.requiresTccCode
                && !this.employerTccCode) {
                if (message.length) {
                    message += ' ';
                }
                message += 'The selected employer has no Transmitter Control Code (TCC) Configured.';
            }
            if (this.aleMembersInValid) {
                if (message.length) {
                    message += ' ';
                }
                message += 'Please indicate which months this employer was a member before proceeding.';
            }
        }
        return message;
    };
    AcaExportSectionComponent.prototype.showReceiptDialog = function () {
        var options = new ConfirmOptions();
        options.showOK = true;
        options.showCancel = false;
        options.height = 250;
        ConfirmDialog2Component.openDialog('Warning', "\"Replacement?\" is marked Yes, but no Receipt ID is entered. A Receipt ID is required to link a replacement filing to the submission it is replacing. Please enter the appropriate Receipt ID from your ACA Information Returns (AIR) System acknowledgment prior to generating replacement XML.", this.modalService, (function (result) {
            return;
        }), options);
    };
    AcaExportSectionComponent.prototype.onReplacementChange = function () {
        if (this.configuration.exportParams.replacement !== 'Yes') {
            this.configuration.exportParams.receiptId = null;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaExportSectionComponent.prototype, "subscriptions", void 0);
    return AcaExportSectionComponent;
}());
export { AcaExportSectionComponent };
