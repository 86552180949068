var WcIncidentRosterContainer = /** @class */ (function () {
    function WcIncidentRosterContainer() {
    }
    return WcIncidentRosterContainer;
}());
export { WcIncidentRosterContainer };
var WcIncidentRosterEntry = /** @class */ (function () {
    function WcIncidentRosterEntry() {
    }
    Object.defineProperty(WcIncidentRosterEntry.prototype, "empAge", {
        get: function () {
            var a = parseFloat(this.age);
            return isFinite(a) ? String(a) : '*';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "reportFlags", {
        get: function () {
            var osha = this.isOSHARecordable ? 1 : 0;
            var wc = this.isWorkersCompCase ? 2 : 0;
            return osha | wc;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "isOshaRecordable", {
        get: function () {
            return this.isOSHARecordable ? 'Yes' : 'No';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "isWorkersComp", {
        get: function () {
            return this.isWorkersCompCase ? 'Yes' : 'No';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "isLossConsciousness", {
        get: function () {
            return this.lossConsciousness ? 'Yes' : 'No';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "isUsedProperSafety", {
        get: function () {
            return this.usedProperSafety ? 'Yes' : 'No';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "isPriorInjury", {
        get: function () {
            return this.hasPriorInjury ? 'Yes' : 'No';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "isExposedToFluids", {
        get: function () {
            return this.exposedFluidToAnother ? 'Yes' : 'No';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentRosterEntry.prototype, "isPrivacyCaseInjury", {
        get: function () {
            return this.isPrivacyCase ? 'Yes' : 'No';
        },
        enumerable: true,
        configurable: true
    });
    return WcIncidentRosterEntry;
}());
export { WcIncidentRosterEntry };
