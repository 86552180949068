import { OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
var DropdownLookupComponent = /** @class */ (function () {
    function DropdownLookupComponent() {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.valueField = 'id';
        this.titleField = 'name';
    }
    DropdownLookupComponent.prototype.writeValue = function (value) {
        this.internalValue = value ? value[this.valueField] : null;
        this.selectedValue = value;
    };
    DropdownLookupComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    DropdownLookupComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DropdownLookupComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        Observable.fromPromise(this.lookupPromise).subscribe(function (entities) {
            _this.items = entities;
        });
    };
    DropdownLookupComponent.prototype.change = function (selectChange) {
        var _a;
        this.selectedValue = _.find(this.items, (_a = {}, _a[this.valueField] = selectChange, _a));
        this.onChangeCallback(this.selectedValue);
    };
    DropdownLookupComponent.prototype.focus = function () {
        this.select.focus();
    };
    return DropdownLookupComponent;
}());
export { DropdownLookupComponent };
