import { Injectable } from '@angular/core';
import { UserMenuPinState, ResponseBody, IUserMenuPinState } from '../../models';
import { appConfig } from '../../../../app/app.config';
import { authenticationConfig } from '../../../../app/authentication/authentication.config'
import { HttpRequest } from '@angular/common/http';
import { Meta } from '../../../core/models/api/meta';
import { AuthApiService } from '../../../core/services/index';
import { UserMenuPinMapService } from './user-menu-pin-map.service';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

@Injectable({
  providedIn: 'root'
})
export class UserMenuPinAPIService {
  
  constructor(private apiService: AuthApiService, private mapService: UserMenuPinMapService, private urlParamsService: UrlParamsService, private apiUtilService: ApiUtilService) { }

  public getUserMenuPinStatus(): Promise<UserMenuPinState> {
    const url = `${this.getApiRoot()}/${authenticationConfig.api.userMenuPinState}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiService.request<IUserMenuPinState, Meta>(request)
      .then((response: ResponseBody<IUserMenuPinState, Meta>) => {
        return this.mapService.mapToResponse(response.data);
      });
  }

  public toggleUserMenuPinStatus(isPinned: boolean): Promise<UserMenuPinState> {
    const url = `${this.getApiRoot()}/${authenticationConfig.api.userMenuPinStateUpdate}`;
    const body = { IsMenuPinned : isPinned };
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiService.request<IUserMenuPinState, Meta>(request)
      .then((response: ResponseBody<IUserMenuPinState, Meta>) => {
        return this.mapService.mapToResponse(response.data);
      });
  }

    private getApiRoot(): string {
    return this.apiUtilService.getApiRoot();
  }
}
