export * from './ta-console.component';
export * from './ta-console-toolbar/ta-console-toolbar.component';
export * from './arrivals-departures-timeline-details/arrivals-departures-timeline-details.component';
export * from './arrivals-departures-timeline-details-grid/arrivals-departures-timeline-details-grid.component';
export * from './arrivals-departures-timeline-details-row/arrivals-departures-timeline-details-row.component';
export * from './arrivals-departures-timeline-details-toolbar/arrivals-departures-timeline-details-toolbar.component';
export * from './arrivals-departures-timeline/arrivals-departures-timeline.component';

import { TaConsoleComponent } from './ta-console.component';
import { TaConsoleToolbarComponent } from './ta-console-toolbar/ta-console-toolbar.component';
import { ArrivalsDeparturesTimelineDetailsComponent } from './arrivals-departures-timeline-details/arrivals-departures-timeline-details.component';
import { ArrivalsDeparturesTimelineDetailsGridComponent } from './arrivals-departures-timeline-details-grid/arrivals-departures-timeline-details-grid.component';
import { ArrivalsDeparturesTimelineDetailsRowComponent } from './arrivals-departures-timeline-details-row/arrivals-departures-timeline-details-row.component';
import { ArrivalsDeparturesTimelineDetailsToolbarComponent } from './arrivals-departures-timeline-details-toolbar/arrivals-departures-timeline-details-toolbar.component';
import { ArrivalsDeparturesTimelineComponent } from './arrivals-departures-timeline/arrivals-departures-timeline.component';

export const taConsoleComponents: any = [
  TaConsoleComponent,
  TaConsoleToolbarComponent,
  ArrivalsDeparturesTimelineDetailsComponent,
  ArrivalsDeparturesTimelineDetailsGridComponent,
  ArrivalsDeparturesTimelineDetailsRowComponent,
  ArrivalsDeparturesTimelineDetailsToolbarComponent,
  ArrivalsDeparturesTimelineComponent
];
