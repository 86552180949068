/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ess-templates-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ess-templates-header.component";
import * as i4 from "../../services/ess-template-management.service";
import * as i5 from "../../../../app-settings/services/app-settings-manage.service";
var styles_EssTemplatesHeaderComponent = [i0.styles];
var RenderType_EssTemplatesHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EssTemplatesHeaderComponent, data: {} });
export { RenderType_EssTemplatesHeaderComponent as RenderType_EssTemplatesHeaderComponent };
function View_EssTemplatesHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "slx-button slx-with-icon add-template-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTemplate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "slx-button__icon far fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add Template"]))], null, null); }
export function View_EssTemplatesHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EssTemplatesHeaderComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isGoClient; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EssTemplatesHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-ess-templates-header", [], null, null, null, View_EssTemplatesHeaderComponent_0, RenderType_EssTemplatesHeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.EssTemplatesHeaderComponent, [i4.EssTemplateManagementService, i5.AppSettingsManageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EssTemplatesHeaderComponentNgFactory = i1.ɵccf("slx-ess-templates-header", i3.EssTemplatesHeaderComponent, View_EssTemplatesHeaderComponent_Host_0, {}, {}, []);
export { EssTemplatesHeaderComponentNgFactory as EssTemplatesHeaderComponentNgFactory };
