import * as moment from 'moment';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ManagementBaseService } from '../../../core/services/index';
import { ModalService } from '../../../common/services/index';
import { FiveStarRatingContainer, StaffingRatingContainer } from '../models/staffing-rating-container';
import { StaffingPredictorApiService } from './staffing-predictor-api.service';
import { StaffingPredictorOrganizationInfo, StaffingRatingRequest, StaffingOrganizationInfoRequest, StaffingPredictorMatrixDialogOptions, FiveStarPredictorOrganizationInfo } from '../models/index';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { PredictorMatrixDialogComponent } from '../components/predictor-matrix-dialog/predictor-matrix-dialog.component';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { IControlState, StateResetTypes } from '../../../core/models/settings/index';
import { GoalSettingRequest, GoalSettingResponse } from '../models/goal-setting';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class StaffingPredictorManagementService extends ManagementBaseService<StaffingRatingContainer | FiveStarRatingContainer, any> {
    public setStartDate : Date;
    public setEndDate : Date;
    public organizationId : number;
    public goalReqObj = new Subject<GoalSettingRequest>();
    public isGoalValueChanged$ = new Subject<boolean>();
    public disableCollapsableContainer$ = new Subject<any>();
    public disableCollapsableContainer2$ = new Subject<any>();
    public onOrganizationInfoLoaded$: ReplaySubject<StaffingPredictorOrganizationInfo | FiveStarPredictorOrganizationInfo>;
    getAllGoalInfo$=new Subject<GoalSettingResponse>();

    public get container(): StaffingRatingContainer | FiveStarRatingContainer {
        return this.m_container;
    }

    private m_container: StaffingRatingContainer;
    private fivestar_container: FiveStarRatingContainer;

    public get containerInfo(): StaffingPredictorOrganizationInfo | FiveStarPredictorOrganizationInfo {
        return this.m_containerInfo;
    }

    private m_containerInfo: StaffingPredictorOrganizationInfo | FiveStarPredictorOrganizationInfo;
    private dataControlKey: string;
    private organizationInfos: NumberMap<StaffingPredictorOrganizationInfo | FiveStarPredictorOrganizationInfo> = [];
    private resetBy: StateResetTypes = StateResetTypes.SessionEnd;
    
    constructor(
        private api: StaffingPredictorApiService,
        private modalService: ModalService,
        private storageService: ComponentStateStorageService,
        private stateManagement: StateManagementService) {
        super();
        this.dataControlKey = 'fiveStarRatingDataModel';
        this.onOrganizationInfoLoaded$ = new ReplaySubject(1);
        const defaultContainer = this.getDefaultContainer();
        this.m_container = defaultContainer;
    }

    public clearCache() {
        this.storageService.setControlState(this.stateManagement.componentKey, this.dataControlKey, { value: null }, this.resetBy);
    }

    public loadRatingList(startDate: Date, endDate: Date, organizationIds: number[] = null, five_star_v2_enabled: boolean, five_star_goal_section_enabled:boolean, isOrgChanged: boolean = false): void {
        this.onLoadStatusChanged(true);
        let request = new StaffingRatingRequest();
        request.startDate = startDate;
        request.endDate = endDate;
        request.organizationIds = organizationIds != null && organizationIds.length > 0 ? organizationIds : null;

        if (five_star_v2_enabled) {
            let cacheData = this.storageService.getControlState(this.stateManagement.componentKey, this.dataControlKey);
            let ratingCacheData = _.get(cacheData.value, 'data', null);
            if (ratingCacheData && !isOrgChanged) {
                this.fivestar_container = ratingCacheData;
                if (organizationIds.length > 0) this.fivestar_container.organizations = ratingCacheData.organizations.filter(item => organizationIds.includes(item.organization.id));
                this.onLoaded(this.fivestar_container as FiveStarRatingContainer);
                this.onLoadStatusChanged(false);
            } else {
                this.api.getFiveStarRatingList(request)
                    .then((data: FiveStarRatingContainer) => {
                        this.fivestar_container = data;
                        this.storageService.setControlState(this.stateManagement.componentKey, this.dataControlKey, { value: { data } }, this.resetBy);
                        this.onLoaded(this.fivestar_container as FiveStarRatingContainer);
                        this.onLoadStatusChanged(false);
                    })
                    .catch((err: any) => {
                        this.container.hasError = true;
                        this.onLoaded(this.fivestar_container as FiveStarRatingContainer);
                        this.onError(err);
                        this.onLoadStatusChanged(false);
                    });
            }
        } else {
            this.api.getRatingList(request)
                .then((data: StaffingRatingContainer) => {
                    this.m_container = data;
                    this.onLoaded(this.container as StaffingRatingContainer);
                    this.onLoadStatusChanged(false);
                })
                .catch((err: any) => {
                    this.onError(err);
                    this.onLoadStatusChanged(false);
                });
        }
    }

    public loadOrganizationInfo(organizationId: number, startDate: Date, endDate: Date, five_star_v2_enabled: boolean = false, five_star_goal_section_enabled: boolean = false): void {

        this.onLoadStatusChanged(true);

        this.organizationInfos[organizationId] = null;

        const req: StaffingOrganizationInfoRequest = new StaffingOrganizationInfoRequest();
        req.organizationId = organizationId;
        req.startDate = startDate;
        req.endDate = endDate;

        // if (five_star_v2_enabled && five_star_goal_section_enabled) {
        //     this.api.loadFiveStarOrganizationInfo(req)
        //         .then((data: FiveStarPredictorOrganizationInfo) => {
        //             this.m_containerInfo = data;
        //             this.onLoadStatusChanged(false);
        //             this.onOrganizationInfoLoaded$.next(this.m_containerInfo);
        //             this.organizationInfos[organizationId] = this.m_containerInfo;
        //         })
        //         .catch((err: any) => {
        //             this.onError(err);
        //             this.onLoadStatusChanged(false);
        //         });
        // } else 
        {
            this.api.loadOrganizationInfo(req)
                .then((data: StaffingPredictorOrganizationInfo) => {
                    this.m_containerInfo = data;
                    this.onLoadStatusChanged(false);
                    this.onOrganizationInfoLoaded$.next(this.m_containerInfo);
                    this.organizationInfos[organizationId] = this.m_containerInfo;
                })
                .catch((err: any) => {
                    this.onError(err);
                    this.onLoadStatusChanged(false);
                });
        }
    }

    public loadGoalsSectionInfo( five_star_goal_section_enabled: boolean = false,starRating:number){
        this.onLoadStatusChanged(true);
        const goalReq :GoalSettingRequest = new GoalSettingRequest();
        goalReq.startDate = this.setStartDate;
        goalReq.endDate = this.setEndDate;
        goalReq.numberOfStars = starRating ;
        goalReq.organizationId =this.organizationId;
        this.goalReqObj.next(goalReq);
        if(five_star_goal_section_enabled){
            this.api.loadGoalSectionInfo(goalReq).then((data: GoalSettingResponse) => {
                this.onLoadStatusChanged(false);
                this.getAllGoalInfo$.next(data);
            })
        }
    }
    public showDetails(organizationId: number) {

        const options: StaffingPredictorMatrixDialogOptions = new StaffingPredictorMatrixDialogOptions();
        options.staffingPointsTables = (this.container as StaffingRatingContainer).pointTables;
        options.organizationInfo = (this.organizationInfos[organizationId] as StaffingPredictorOrganizationInfo);
        PredictorMatrixDialogComponent.openDialog(options, this.modalService, (result: boolean) => {
            _.noop();
        });

    }

    public showDetailsFiveStar() {
        const options: StaffingPredictorMatrixDialogOptions = new StaffingPredictorMatrixDialogOptions();
        options.staffingPointsTables = (this.container as StaffingRatingContainer).pointTables;
        options.organizationInfo = new StaffingPredictorOrganizationInfo();
        options.width = 500;
        options.height = 360;
        options.five_star_v2_enabled = true;
        PredictorMatrixDialogComponent.openDialog(options, this.modalService, (result: boolean) => {
            _.noop();
        });
    }

    private getDefaultContainer(): StaffingRatingContainer {
        const defaultContainer = new StaffingRatingContainer();
        defaultContainer.organizations = [];
        defaultContainer.ratingMaxValue = 5;
        const quarter = this.getCurrentQuarter();
        defaultContainer.startDate = quarter.startDate;
        defaultContainer.endDate = quarter.endDate;
        this.m_container = defaultContainer;
        return defaultContainer;
    }

    private getCurrentQuarter(): { index: number, startDate: Date, endDate: Date } {
        const now = moment();
        const quarter = now.quarter();
        return {
            index: quarter,
            startDate: moment().quarter(quarter).startOf('quarter').toDate(),
            endDate: moment().quarter(quarter).endOf('quarter').toDate()
        };
    }
}
