import { QueryList, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { ErrorMessageDirective } from '../../error-filters/errorMessage';
var SlxFieldComponent = /** @class */ (function () {
    function SlxFieldComponent(elementRef) {
        this.elementRef = elementRef;
    }
    Object.defineProperty(SlxFieldComponent.prototype, "fieldName", {
        get: function () {
            return this.model ? this.model.name : this.exFieldName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlxFieldComponent.prototype, "form", {
        get: function () {
            return this.model && this.model.formDirective ? this.model.formDirective.form : this.exForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SlxFieldComponent.prototype, "modelElement", {
        get: function () {
            if (this.modelElementSngl)
                return this.modelElementSngl;
            var nativeElement = this.elementRef.nativeElement;
            this.modelElementSngl = nativeElement.querySelector('[name=' + this.fieldName + ']');
            return this.modelElementSngl;
        },
        enumerable: true,
        configurable: true
    });
    return SlxFieldComponent;
}());
export { SlxFieldComponent };
