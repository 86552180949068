import * as tslib_1 from "tslib";
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind, Unit } from '../../../models/index';
var LocationUnitRendererComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LocationUnitRendererComponent, _super);
    function LocationUnitRendererComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editable = true;
        return _this;
    }
    Object.defineProperty(LocationUnitRendererComponent.prototype, "context", {
        set: function (value) {
            this.item = value.item;
            this.rowIndex = value.rowIndex;
        },
        enumerable: true,
        configurable: true
    });
    LocationUnitRendererComponent.prototype.onEditClick = function () {
        this.actionEmitter.emit(EditableListActionKind.START_EDIT);
    };
    LocationUnitRendererComponent.prototype.onItemSelect = function () {
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    };
    return LocationUnitRendererComponent;
}(CustomListActorBase));
export { LocationUnitRendererComponent };
