import * as tslib_1 from "tslib";
import { ResponseBody, Meta } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitEmployeesMapService } from './benefit-employees-map.service';
import { dateTimeUtils } from '../../../../common/utils/index';
var BenefitEmployeesApiService = /** @class */ (function () {
    function BenefitEmployeesApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    BenefitEmployeesApiService.prototype.getBenefitEligibleEmployees = function (orgLevelId, tierId, refDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.employee.eligible;
                        params = {
                            orgLevelId: orgLevelId,
                            benefitTierId: tierId,
                            effectiveDate: dateTimeUtils.convertToDtoString(refDate),
                        };
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEligibleEmployees(response.data)];
                }
            });
        });
    };
    BenefitEmployeesApiService.prototype.getBenefitEnrolledPreviewEmployees = function (orgLevelId, tierId, refDate, coverage, selectedEmployee) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.getBenefitEnrolledPreviewEmployeesWithOptionCode(orgLevelId, tierId, refDate, coverage, '', selectedEmployee)];
            });
        });
    };
    BenefitEmployeesApiService.prototype.getBenefitEnrolledPreviewEmployeesWithOptionCode = function (orgLevelId, tierId, refDate, coverage, optionCode, selectedEmployee) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.employee.processFormulaForEmployees;
                        body = this.mapService.mapToEmployeeBenefitFormulaContributionsRequest2(orgLevelId, tierId, refDate, coverage, optionCode, selectedEmployee);
                        request = this.urlParamsService.requestPost(url, body);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEnrolledEmployeesPreview(response.data)];
                }
            });
        });
    };
    BenefitEmployeesApiService.prototype.getBenefitEligibleEmployeesInfo = function (orgLevelId, tierId, refDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.employee.eligibleInfo;
                        params = {
                            orgLevelId: orgLevelId,
                            benefitTierId: tierId,
                            effectiveDate: dateTimeUtils.convertToDtoString(refDate),
                        };
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEligibleEmployeesInfo(response.data)];
                }
            });
        });
    };
    BenefitEmployeesApiService.prototype.getBenefitEnrolledEmployeesInfo = function (orgLevelId, tierId, refDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.employee.enrolledInfo;
                        params = {
                            orgLevelId: orgLevelId,
                            benefitTierId: tierId,
                            effectiveDate: dateTimeUtils.convertToDtoString(refDate),
                        };
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEnrolledEmployeesInfo(response.data)];
                }
            });
        });
    };
    BenefitEmployeesApiService.prototype.getBenefitEnrolledEmployees = function (orgLevelId, lineId, tierId, refDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getEnrolledEmployeeApiUrl() + "/" + benefitsConfig.api.benefits.employee.enrolledEmployees;
                        params = {
                            orgLevelId: orgLevelId,
                            benefitLineId: lineId,
                            benefitTierId: tierId,
                            effectiveDate: refDate
                        };
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEnrolledEmployees(response.data)];
                }
            });
        });
    };
    BenefitEmployeesApiService.prototype.getReasonsList = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getEnrolledEmployeeApiUrl() + "/" + benefitsConfig.api.benefits.employee.dropReason;
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapBenefitTerminationReasons(response.data)];
                }
            });
        });
    };
    BenefitEmployeesApiService.prototype.dropEmployeeBenefits = function (selectedEmployee, startDate, payrollDeductionEndDate, eventDate, reason) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                startDate = dateTimeUtils.convertToDtoString(startDate);
                payrollDeductionEndDate = dateTimeUtils.convertToDtoString(payrollDeductionEndDate);
                eventDate = dateTimeUtils.convertToDtoString(eventDate);
                url = this.getEnrolledEmployeeApiUrl() + "/" + benefitsConfig.api.benefits.employee.dropCoverage;
                body = this.mapService.mapDropToDto(selectedEmployee, startDate, payrollDeductionEndDate, eventDate, reason);
                request = this.urlParamsService.requestPut(url, body);
                request.autoContentType = true;
                return [2 /*return*/, this.apiUtilService.requestNew(request)
                        .then(function (response) { return _this.mapService.mapToDropBenefits(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.updateEmployeeBenefitEnrollment = function (req, lineId, tierId, date, method) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEnrolledEmployeeApiUrl() + "/" + benefitsConfig.api.benefits.employee.updateEmployeeBenefitEnrollment;
                body = this.mapService.mapDtoToEmployeeInformationUpdate(req, lineId, tierId, date, method);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapEmployeeBenefitsEditResult(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.deleteEmployeeBenefitEnrollment = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEnrolledEmployeeApiUrl() + "/" + benefitsConfig.api.benefits.employee.deleteEmployeeBenefitEnrollment;
                body = req;
                params = {};
                request = this.urlParamsService.createDeleteRequest(url, params, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapEmployeeBenefitsDeleteResult(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.enrollEmployeesBenefit = function (req, notesText, effectiveDate, lineId, tierId, calculationMethod) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                effectiveDate = dateTimeUtils.convertToDtoString(effectiveDate);
                url = this.getEnrolledEmployeeApiUrl() + "/" + benefitsConfig.api.benefits.employee.enrollBenefits;
                body = this.mapService.mapBenefitsEnrollOptionsToDto(req, notesText, effectiveDate, lineId, tierId, calculationMethod);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapEmployeeBenefitsEditResult(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.createEmployeeDependent = function (dependent) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEmployeeDependentApiUrl();
                body = this.mapService.mapEmployeeDependentToDto(dependent);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapDtoToEmployeeDependent(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.editEmployeeDependent = function (dependent) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEmployeeDependentApiUrl();
                body = this.mapService.mapEmployeeDependentToDto(dependent);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapDtoToEmployeeDependent(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.deleteEmployeeDependent = function (dependentId) {
        var url = this.getEmployeeDependentApiUrl();
        var request = this.urlParamsService.createDeleteRequest(url, { dependentId: dependentId });
        return this.apiUtilService.request(request);
    };
    BenefitEmployeesApiService.prototype.getEmployeeDependentEnrollments = function (dependentId, employeeid) {
        var _this = this;
        var url = this.getEmployeeDependentApiUrl() + "/" + benefitsConfig.api.benefits.employee.enrolled;
        var request = this.urlParamsService.createGetRequest(url, { dependentId: dependentId, employeeid: employeeid });
        return this.apiUtilService
            .request(request)
            .then(function (response) { return _this.mapService.mapDtoToEmpDependentEnrollments(response.data); });
    };
    BenefitEmployeesApiService.prototype.getEmployeeDependentEligibleBenefits = function (dependentId) {
        var _this = this;
        var url = this.getEmployeeDependentEnrollApiUrl() + "/" + benefitsConfig.api.benefits.employee.eligible;
        var request = this.urlParamsService.createGetRequest(url, { dependentId: dependentId });
        return this.apiUtilService
            .request(request)
            .then(function (response) { return _this.mapService.mapDtoToEmpDependentEligibleBenefits(response.data); });
    };
    BenefitEmployeesApiService.prototype.getEmployeeDependentsEligibleBenefits = function (dependentIds) {
        var _this = this;
        var url = this.getEmployeeDependentEnrollApiUrl() + "/" + benefitsConfig.api.benefits.employee.eligible;
        var request = this.urlParamsService.createGetRequest(url, { dependentIds: dependentIds }, true);
        return this.apiUtilService
            .request(request)
            .then(function (response) { return _this.mapService.mapDtoToEmpDependentEligibleBenefits(response.data); });
    };
    BenefitEmployeesApiService.prototype.addMultipleDependentsEnrollments = function (enrollments) {
        var url = this.getEmployeeDependentEnrollApiUrl();
        var body = this.mapService.mapEmpDependentsEnrollmentsToDto(enrollments);
        var request = this.urlParamsService.createPostRequest(url, body);
        return this.apiUtilService
            .request(request)
            .then(function (response) { return response.data; });
    };
    BenefitEmployeesApiService.prototype.addDependentEnrollment = function (enroll) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEmployeeDependentEnrollApiUrl();
                body = this.mapService.mapEmpDependentEnrollmentToDto(enroll);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapDtoToEmpDependentEnrollment(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.editDependentEnrollment = function (enroll) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getEmployeeDependentEnrollApiUrl();
                body = this.mapService.mapEmpDependentEnrollmentToDto(enroll);
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapDtoToEmpDependentEnrollment(response.data); })];
            });
        });
    };
    BenefitEmployeesApiService.prototype.deleteDependentEnrollment = function (empDependentEnrollmentId) {
        var url = this.getEmployeeDependentEnrollApiUrl();
        var request = this.urlParamsService.createDeleteRequest(url, { empDependentEnrollmentId: empDependentEnrollmentId });
        return this.apiUtilService.request(request);
    };
    BenefitEmployeesApiService.prototype.dropDependentEnrollment = function (enrollment) {
        var _this = this;
        var url = this.getEmployeeDependentApiUrl() + "/" + benefitsConfig.api.benefits.employee.dropCoverage;
        var body = this.mapService.mapEmpDependentDroppedEnrollmentToDto(enrollment);
        var request = this.urlParamsService.createPutRequest(url, body);
        return this.apiUtilService.request(request)
            .then(function (response) { return _this.mapService.mapDtoToEmpDependentEnrollment(response.data); });
    };
    BenefitEmployeesApiService.prototype.getBenefitTerminationReasons = function () {
        var _this = this;
        var url = this.getEmployeeDependentApiUrl() + "/" + benefitsConfig.api.benefits.employee.dropReasons;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService
            .request(request)
            .then(function (response) { return _this.mapService.mapBenefitTerminationReasons(response.data); });
    };
    BenefitEmployeesApiService.prototype.getApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.benefits.root + "/" + benefitsConfig.api.benefits.employee.root;
    };
    BenefitEmployeesApiService.prototype.getEnrolledEmployeeApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.benefits.employee.benefitEnrollment;
    };
    BenefitEmployeesApiService.prototype.getEmployeeDependentApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.benefits.root + "/" + benefitsConfig.api.benefits.employee.dependents;
    };
    BenefitEmployeesApiService.prototype.getEmployeeDependentEnrollApiUrl = function () {
        return this.getEmployeeDependentApiUrl() + "/" + benefitsConfig.api.benefits.employee.enroll;
    };
    return BenefitEmployeesApiService;
}());
export { BenefitEmployeesApiService };
