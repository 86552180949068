import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../../core/decorators/index';

import { ArrivalsDeparturesTimelineDetailsManagementService } from '../../../services/index';
import { ArrivalsDeparturesDetails, ArrivalsDeparturesDetailsRecord } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-departures-timeline-details-row',
  templateUrl: 'arrivals-departures-timeline-details-row.component.html',
  styleUrls: ['arrivals-departures-timeline-details-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrivalsDeparturesTimelineDetailsRowComponent {

  @Input()
  public workDate: Date;
  @Input()
  public details: ArrivalsDeparturesDetails;
  @Input()
  public record: ArrivalsDeparturesDetailsRecord;
  @Output()
  public detectChanges = new EventEmitter();

  public expandedMenu: boolean;
  public expandedDetails: boolean;

  constructor() {
    this.expandedMenu = false;
    this.expandedDetails = false;
  }

  public changeDetection(): void {
    this.detectChanges.emit();
  }

  public toggleMenu(value:boolean): void {
    this.expandedMenu = value;
    this.changeDetection();
  }

  public toggleDetails(): void {
    this.expandedDetails =!this.expandedDetails;
    this.changeDetection();
  }
}
