import * as _ from 'lodash';
import { Assert } from '../../../framework/index';
var OperationalConsoleField = /** @class */ (function () {
    function OperationalConsoleField(keyName, value) {
        Assert.isNotNull(keyName, 'keyName');
        Assert.isNotNull(value, 'value');
        this.m_keyName = keyName;
        this.m_value = value;
    }
    OperationalConsoleField.getTitle = function (keyName) {
        return _.get(OperationalConsoleField.dataMap[keyName], 'title', '');
    };
    Object.defineProperty(OperationalConsoleField.prototype, "value", {
        get: function () {
            return this.m_value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleField.prototype, "title", {
        get: function () {
            return OperationalConsoleField.dataMap[this.m_keyName].title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleField.prototype, "colName", {
        get: function () {
            return OperationalConsoleField.dataMap[this.m_keyName].colName;
        },
        enumerable: true,
        configurable: true
    });
    OperationalConsoleField.dataMap = {
        scheduledEmployees: { colName: 'SO_Console_ScheduledEmployees', title: 'Emps' },
        scheduledHours: { colName: 'SO_Console_ScheduledHours', title: 'Sched' },
        par: { colName: 'SO_Console_ScheduledVsPar', title: 'Ideal' },
        diff: { colName: 'SO_Console_ScheduledVsPar', title: 'Diff' },
        absentEmployees: { colName: 'SO_Console_Absent', title: 'Abs Emps' },
        absent: { colName: 'SO_Console_Absent', title: 'Abs Hrs' },
        replaced: { colName: 'SO_Consele_AbsencesReplaced', title: 'Replaced Emps' },
        notReplaced: { colName: 'SO_Consele_AbsencesNotReplaced', title: 'Not replaced' },
        diffR: { colName: 'SO_Consele_AbsencesNotReplaced', title: 'Not Replaced' },
        lateEmployees: { colName: 'SO_Console_LateEmployees', title: 'Late' },
        punchedEmployees: { colName: 'SO_Console_PunchedEmployees', title: 'Punched In' },
        notPunchedEmployees: { colName: 'SO_Console_NotPunchedEmployees', title: 'Not Punched' },
        overtime: { colName: 'SO_Console_ApproachingOT', title: 'Approaching OT' }
    };
    return OperationalConsoleField;
}());
export { OperationalConsoleField };
var IOperationalConsoleEntry = /** @class */ (function () {
    function IOperationalConsoleEntry() {
    }
    return IOperationalConsoleEntry;
}());
export { IOperationalConsoleEntry };
var OperationalConsoleEntry = /** @class */ (function () {
    function OperationalConsoleEntry() {
    }
    return OperationalConsoleEntry;
}());
export { OperationalConsoleEntry };
var OperationalConsoleDialogParameters = /** @class */ (function () {
    function OperationalConsoleDialogParameters(date, field, position, orgLevel, application, employeeLinkClick) {
        Assert.isNotNull(date, 'date');
        Assert.isNotNull(field, 'field');
        Assert.isNotNull(orgLevel, 'orgLevel');
        Assert.isNotNull(application, 'application');
        this.date = date;
        this.field = field;
        this.position = position;
        this.orgLevel = orgLevel;
        this.application = application;
        this.employeeLinkClick = employeeLinkClick;
    }
    return OperationalConsoleDialogParameters;
}());
export { OperationalConsoleDialogParameters };
