import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
var BottomMenuComponent = /** @class */ (function () {
    function BottomMenuComponent(authenticationService, changeManagementService, applicationStateBusService) {
        this.authenticationService = authenticationService;
        this.changeManagementService = changeManagementService;
        this.applicationStateBusService = applicationStateBusService;
    }
    BottomMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        scheduleMicrotask(function () {
            _this.isLeftSidebarOpenSubscripion = _this.isLeftSidebarOpen$.subscribe(function (value) {
                _this.isLeftSidebarOpen = value;
                if (_this.isLeftSidebarOpen) {
                    _this.isExpanded = false;
                }
            });
        });
    };
    BottomMenuComponent.prototype.toggleFullScreen = function () {
        var doc = window.document;
        var elem = doc.documentElement;
        if (!doc.fullscreenElement && !doc.mozFullScreenElement &&
            !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            }
            else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
            else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            }
            else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            }
        }
        else {
            if (doc.exitFullscreen) {
                doc.exitFullscreen();
            }
            else if (doc.msExitFullscreen) {
                doc.msExitFullscreen();
            }
            else if (doc.mozCancelFullScreen) {
                doc.mozCancelFullScreen();
            }
            else if (doc.webkitExitFullscreen) {
                doc.webkitExitFullscreen();
            }
        }
    };
    BottomMenuComponent.prototype.toggleExpanded = function () {
        this.isExpanded = !this.isExpanded;
    };
    BottomMenuComponent.prototype.logOut = function () {
        var _this = this;
        this.changeManagementService.canMoveForward()
            .then(function (can) {
            if (can) {
                //TODO we should move authenticationService to oganization module or move applicationStateBusService to common or core
                _this.authenticationService.logout();
                _this.applicationStateBusService.logout();
            }
            else {
                //todo
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], BottomMenuComponent.prototype, "isLeftSidebarOpen$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], BottomMenuComponent.prototype, "isLeftSidebarOpenSubscripion", void 0);
    return BottomMenuComponent;
}());
export { BottomMenuComponent };
