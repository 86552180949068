/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./geo-punch-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../common/components/static-map/static-map.component.ngfactory";
import * as i4 from "../../../../common/components/static-map/static-map.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "./geo-punch-dialog.component";
import * as i7 from "../../../models/punches/geo-punch-dialog-options";
import * as i8 from "../../../../common/models/dialog-options";
import * as i9 from "../../../../common/services/modal/modal.service";
var styles_GeoPunchDialogComponent = [i0.styles];
var RenderType_GeoPunchDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GeoPunchDialogComponent, data: {} });
export { RenderType_GeoPunchDialogComponent as RenderType_GeoPunchDialogComponent };
function View_GeoPunchDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "punch-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Punch is invalid"]))], null, null); }
export function View_GeoPunchDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "geo-punch-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GeoPunchDialogComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-static-map", [], null, null, null, i3.View_StaticMapComponent_0, i3.RenderType_StaticMapComponent)), i1.ɵdid(6, 245760, null, 0, i4.StaticMapComponent, [i5.DomSanitizer], { latitude: [0, "latitude"], longitude: [1, "longitude"], mapWidth: [2, "mapWidth"], mapHeight: [3, "mapHeight"], mapZoom: [4, "mapZoom"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.geoPunchDetails.isInvalidPunch; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.geoPunchDetails.latitude; var currVal_2 = _co.geoPunchDetails.longitude; var currVal_3 = _co.geoPunchDetails.mapWidth; var currVal_4 = _co.geoPunchDetails.mapHeight; var currVal_5 = _co.geoPunchDetails.mapZoom; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_GeoPunchDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-geo-punch-dialog", [], null, null, null, View_GeoPunchDialogComponent_0, RenderType_GeoPunchDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.GeoPunchDialogComponent, [i7.GeoPunchDialogOptions, i8.DialogOptions, i9.ModalService], null, null)], null, null); }
var GeoPunchDialogComponentNgFactory = i1.ɵccf("slx-geo-punch-dialog", i6.GeoPunchDialogComponent, View_GeoPunchDialogComponent_Host_0, {}, {}, []);
export { GeoPunchDialogComponentNgFactory as GeoPunchDialogComponentNgFactory };
