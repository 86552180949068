/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-roles-assignement-action-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./user-roles-assignement-action-bar.component";
import * as i5 from "@angular/router";
var styles_UserRolesAssignementActionBarComponent = [i0.styles];
var RenderType_UserRolesAssignementActionBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserRolesAssignementActionBarComponent, data: {} });
export { RenderType_UserRolesAssignementActionBarComponent as RenderType_UserRolesAssignementActionBarComponent };
function View_UserRolesAssignementActionBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["found ", " of "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rolesDecorator.totalFilteredCount; _ck(_v, 1, 0, currVal_0); }); }
function View_UserRolesAssignementActionBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " role(s), "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserRolesAssignementActionBarComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " users(s) "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.filterString; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rolesDecorator.usersRoles.roles.length; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.rolesDecorator.totalAssignedCount; _ck(_v, 4, 0, currVal_2); }); }
export function View_UserRolesAssignementActionBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "action-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "search-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "input-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "input", [["class", "form-control users-search-input"], ["placeholder", "Filter by users..."], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.filterString = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.onFilterChanged() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserRolesAssignementActionBarComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addUserClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add User"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.filterString; _ck(_v, 6, 0, currVal_7); var currVal_8 = _co.rolesDecorator; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 8).ngClassValid; var currVal_5 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_UserRolesAssignementActionBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-user-roles-assignement-action-bar", [], null, null, null, View_UserRolesAssignementActionBarComponent_0, RenderType_UserRolesAssignementActionBarComponent)), i1.ɵdid(1, 114688, null, 0, i4.UserRolesAssignementActionBarComponent, [i5.Router, i5.ActivatedRoute, i2.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserRolesAssignementActionBarComponentNgFactory = i1.ɵccf("slx-user-roles-assignement-action-bar", i4.UserRolesAssignementActionBarComponent, View_UserRolesAssignementActionBarComponent_Host_0, { rolesDecorator: "rolesDecorator", filterString: "filterString" }, {}, []);
export { UserRolesAssignementActionBarComponentNgFactory as UserRolesAssignementActionBarComponentNgFactory };
