import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { process } from '@progress/kendo-data-query';
import { NgForm, NgModel } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { InfoDialogComponent } from './../../../../../common/components/index';
import { OnInit, OnDestroy } from '@angular/core';
import { BenefitEnrolledEmployeesRequest } from '../../../models/index';
import { unsubscribeAll, mutableSelect } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { BenefitEmployeesApiService } from '../../../services/index';
import { KendoGridStateHelper, DialogOptions } from '../../../../../common/models/index';
import { ModalService, ModalAnchorDirective } from '../../../../../common/index';
import { subscribeToOrgLevel } from '../../../../../organization/selectors/index';
import { screenUtils } from '../../../../../common/utils/index';
import { EmployeeBenefitDropCoverageDialogComponent } from './../benefit-drop-coverage-dialog/benefit-drop-coverage-dialog.component';
import { BenefitDetailsCalcMethod, BenefitDetailsTier, BenefitDetailsLineStandart, BenefitPayrollDeduction } from './../../../models/benefit-details/index';
import { DropCoverageRequest } from './../../../models/benefit-employees/benefit-employee-benefits';
import { GridComponent } from '@progress/kendo-angular-grid';
import { CalculationCommonService } from '../../../services/benefit-details/calculation-common.service';
import { BenefitEnrollmentCommonService } from '../../../services/index';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/components/index';
import { AppSettingsManageService } from '../../../../../app-settings/services/app-settings-manage.service';
var BenefitEmployeesEnrolledComponent = /** @class */ (function () {
    function BenefitEmployeesEnrolledComponent(apiService, modalService, options, request, calculationCommonService, commonValidationService, appSettingsManageService) {
        this.apiService = apiService;
        this.modalService = modalService;
        this.calculationCommonService = calculationCommonService;
        this.commonValidationService = commonValidationService;
        this.appSettingsManageService = appSettingsManageService;
        this.pageSize = 50;
        this.selectedEmployees = new Map();
        this.filteredRecords = [];
        this.columnGroup = 'BenefitEnrolledEmployees';
        this.planDates = [];
        this.coverage = 0;
        this.employeeContributionAmount = 0;
        this.employerContributionAmount = 0;
        this.empContrError = false;
        this.empContrPercentError = false;
        this.hasFormulaError = false;
        this.maxEmployeeContribution = 100;
        this.enableBenefitDeduction = false;
        this.currencyFormat = 'c2';
        this.minAmt = 0;
        this.minFormulaAmt = -1;
        this.maxAmt = 99999999.99;
        this.maxEmpAmt = 999999999.99;
        this.stepcurrency = 0.01;
        this.isCheckedAll = false;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = 50;
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
        this.options = options;
        this.request = request;
    }
    Object.defineProperty(BenefitEmployeesEnrolledComponent.prototype, "skip", {
        set: function (value) {
            if (value === undefined) {
                return;
            }
            this.gridState.state.skip = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrolledComponent.prototype, "canEdit", {
        get: function () {
            return _.isObjectLike(this.calcMethod) && _.isObjectLike(this.tierDetails)
                && (this.calcMethod.isFormula && !this.tierDetails.coverageFormula.isFixedCalcType
                    || !this.calcMethod.isFormula);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitEmployeesEnrolledComponent.prototype, "showEditColumn", {
        get: function () {
            return !(this.calcMethod.isOptionsRates ||
                ((this.calcMethod.isFormulaWithOption || this.calcMethod.isFormula) && this.tierDetails.coverageFormula.isFixedCalcType));
        },
        enumerable: true,
        configurable: true
    });
    BenefitEmployeesEnrolledComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.benefitOptionData = this.tierDetails.options;
        this.calcMethod = new BenefitDetailsCalcMethod(this.method, this.method, this.method);
        this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, function () { return _this.orgLevel; }, function (orgLevel) { return _this.reload(orgLevel); });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function () {
            _this.refreshGrid();
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$
            .subscribe(function (event) {
            _this.isLoading = true;
            _this.doEdit(event.dataItem, event.rowIndex);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$
            .subscribe(function (event) {
            _this.doRemove(event.dataItem, event.rowIndex);
        });
        this.subscriptions.editSubscription = this.gridState.onEdit$
            .subscribe(function (event) {
            _this.selectedRowIndex = _this.gridState.editedRowIndex;
            var coverage = _.find(_this.coverageMultipllierOptions, (function (o) {
                return o.value === event.coverage;
            }));
            _this.editedItemCoverage = coverage;
            _this.validateFormulaEvaluation(event, false);
        });
        this.planEffectiveStartDate = this.request.startDate;
        this.planEffectiveEndDate = this.request.endDate;
        this.planDates = [this.planEffectiveStartDate, this.planEffectiveEndDate];
        this.payrollDeductionEndDate = this.selectedProviderLine.line.payrollDeductionEndDate;
        this.payrollDeductionEndDate = !_.isNull(this.payrollDeductionEndDate) ? this.payrollDeductionEndDate : this.planEffectiveEndDate;
        if (this.tierDetails) {
            if (this.tierDetails.options && this.tierDetails.options.length > 0) {
                if (this.tierDetails.options[0].maxEmpContribution == null) {
                    this.maxEmployeeContribution = 100;
                }
                else {
                    this.maxEmployeeContribution = this.tierDetails.options[0].maxEmpContribution;
                }
                this.maxEmpAmt = (this.maxEmpAmt && this.tierDetails.options[0].limitAmount) > 0 ? this.tierDetails.options[0].limitAmount : this.maxEmpAmt;
            }
        }
        this.loadCovergae();
        this.getCoverage();
        this.getEmployeeContribution();
        this.getEmployerContribution();
    };
    BenefitEmployeesEnrolledComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    BenefitEmployeesEnrolledComponent.prototype.isCheckedEmp = function (emp) {
        return this.selectedEmployees.has(emp.employeeId);
    };
    BenefitEmployeesEnrolledComponent.prototype.loadCovergae = function () {
        if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
            var multiplier = this.tierDetails.coverageFormula.multipler;
            var maxCap = this.tierDetails.coverageFormula.maxCap;
            multiplier = _.isNumber(multiplier) ? multiplier : 500;
            maxCap = _.isNumber(maxCap) ? maxCap : 10000;
            var range = _.range(multiplier, maxCap + multiplier, multiplier);
            var opts = _.map(range, function (value) { return ({ name: _.toString(value), value: value }); });
            this.coverageMultipllierOptions = opts;
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.getCoverage = function () {
        if (this.tierDetails && this.tierDetails.coverageFormula) {
            if (this.tierDetails.coverageFormula.isFixedCalcType) {
                this.coverage = this.tierDetails.coverageFormula.fixedAmount;
            }
            else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
                this.coverage = 0;
                // this.coverage = this.tierDetails.coverageFormula.expression;
            }
            else {
                this.coverage = null;
            }
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.empContrPercentageChange = function (dataItem, rowIndex) {
        if (dataItem) {
            if (this.tierDetails.options[0].maxEmpContribution) {
                if (dataItem.employeeContributionPercentage > this.tierDetails.options[0].maxEmpContribution) {
                    this.empContrError = true;
                    return;
                }
            }
            if (dataItem.employeeContributionAmount === null && dataItem.employeeContributionPercentage === null)
                return this.empContrPercentError = true;
        }
        this.empContrPercentError = false;
        this.empContrError = false;
    };
    BenefitEmployeesEnrolledComponent.prototype.getEmployeeContribution = function () {
        if (this.tierDetails && this.tierDetails.empFormula) {
            if (this.tierDetails.empFormula.isFixedCalcType) {
                this.employeeContributionAmount = this.tierDetails.empFormula.fixedAmount;
            }
            else if (this.tierDetails.empFormula.isFormulaCalcType) {
                this.employeeContributionAmount = 0;
                // this.employeeContributionAmount = this.tierDetails.empFormula.expression;
            }
            else {
                this.employeeContributionAmount = null;
            }
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.getEmployerContribution = function () {
        if (this.tierDetails && this.tierDetails.erFormula) {
            if (this.tierDetails.erFormula.isFixedCalcType) {
                this.employerContributionAmount = this.tierDetails.erFormula.fixedAmount;
            }
            else if (this.tierDetails.erFormula.isFormulaCalcType) {
                this.employerContributionAmount = 0;
                // this.employerContributionAmount = this.tierDetails.erFormula.expression;
            }
            else {
                this.employerContributionAmount = null;
            }
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.doEdit = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tierId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                tierId = this.tierDetails.tier.id;
                return [2 /*return*/, this.apiService.updateEmployeeBenefitEnrollment(dataItem, this.lineId, tierId, this.date, this.calcMethod.id)
                        .then(function (res) {
                        if (res.isSuccess) {
                            _this.reload(_this.orgLevel);
                        }
                        else {
                            _this.reload(_this.orgLevel);
                            InfoDialogComponent.OpenDialog('Warning', 'Something went wrong', _this.modalService);
                        }
                    }).finally(function () {
                        _this.editedItemCoverage = null;
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitEmployeesEnrolledComponent.prototype.doRemove = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.deleteEmployeeBenefitEnrollment([dataItem.id])
                        .then(function (res) {
                        if (res) {
                            _this.records.splice(rowIndex, 1);
                        }
                        else {
                            _this.reload(_this.orgLevel);
                            InfoDialogComponent.OpenDialog('Warning', 'Something went wrong', _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitEmployeesEnrolledComponent.prototype.costCalculation = function () {
        var _this = this;
        if (this.calcMethod.isOptionsRates) {
            this.records.forEach(function (employee) {
                _this.onChangeContribution(employee);
            });
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.reload = function (orgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tierId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                this.orgLevel = orgLevel;
                tierId = this.tierDetails.tier.id;
                return [2 /*return*/, this.apiService.getBenefitEnrolledEmployees(this.lowLevelOrgId, this.lineId, tierId, this.date)
                        .then(function (records) {
                        _this.selectedEmployees.clear();
                        _this.isCheckedAll = false;
                        _this.records = records;
                        _this.updateOptionViewData();
                        _this.costCalculation();
                        _this.refreshGrid();
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    BenefitEmployeesEnrolledComponent.prototype.exportToExcel = function () {
        this.kendoGrid.saveAsExcel();
    };
    BenefitEmployeesEnrolledComponent.prototype.exportToPdf = function () {
        this.kendoGrid.saveAsPDF();
    };
    BenefitEmployeesEnrolledComponent.prototype.onChangeContribution = function (dataItem) {
        var employeeContribution = 0;
        if (dataItem.employeeContributionAmount > 0) {
            employeeContribution = this.calculationCommonService.getConvertedEmployeeContribution(this.selectedProviderLine.costFreq, this.selectedProviderLine.empContFreq, dataItem.employeeContributionAmount);
        }
        dataItem.cost = dataItem.employerContributionAmount + employeeContribution;
        this.maxEmpAmt = (this.maxEmpAmt && dataItem.limit) > 0 ? dataItem.limit : this.maxEmpAmt;
        if (dataItem.employeeContributionAmount === null && dataItem.employeeContributionPercentage === null) {
            return this.empContrPercentError = true;
        }
        this.empContrPercentError = false;
    };
    BenefitEmployeesEnrolledComponent.prototype.onSelectAll = function () {
        var _this = this;
        if (this.isCheckedAll) {
            this.selectedEmployees.clear();
            this.isCheckedAll = false;
        }
        else {
            _.forEach(this.filteredRecords, function (e) {
                _this.selectedEmployees.set(e.employeeId, e);
            });
            this.isCheckedAll = true;
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.onSelectSingle = function (emp) {
        if (this.selectedEmployees.has(emp.employeeId)) {
            this.selectedEmployees.delete(emp.employeeId);
            this.isCheckedAll = false;
        }
        else {
            this.selectedEmployees.set(emp.employeeId, emp);
            if (_.size(this.filteredRecords) === this.selectedEmployees.size) {
                this.isCheckedAll = true;
            }
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.dropEmployeeBenefit = function () {
        var _this = this;
        this.dropCoverageRequest = new DropCoverageRequest(this.planEffectiveStartDate, this.planEffectiveEndDate, this.payrollDeductionEndDate, this.canMapPayroll, this.mappedDeduction, Array.from(this.selectedEmployees.values()));
        EmployeeBenefitDropCoverageDialogComponent.openDialog(this.planName, this.modalAnchor, this.modalService, this.apiService, this.dropCoverageRequest, function (hasChanges) {
            _this.reload(_this.orgLevel);
        });
    };
    BenefitEmployeesEnrolledComponent.prototype.onOptionValueChange = function (dataItem) {
        if (dataItem.optionCode && dataItem.code != dataItem.optionCode.code) {
            dataItem.benefitTierOptionId = dataItem.optionCode.id;
            dataItem.code = dataItem.optionCode.code;
            dataItem.optionType = dataItem.optionCode.type;
            this.calculateFormula(dataItem);
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.onChangeCoverageWithMultiplier = function (value, dataItem, hasError) {
        if (!hasError) {
            this.editedItemCoverage = value;
            var newValue = _.isObjectLike(value) && _.isFinite(value.value) ? value.value : null;
            if (dataItem.coverage !== newValue) {
                dataItem.coverage = newValue;
                this.calculateFormula(dataItem);
            }
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.onCoverageFormulaValueChange = function (value, dataItem) {
        if (this.covFormulaOverrideControl && this.covFormulaOverrideControl.valid) {
            if (dataItem.originalCoverage !== value) {
                dataItem.calculatedCoverage = value;
                dataItem.coverage = value;
                this.calculateFormula(dataItem);
            }
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.calculateFormula = function (dataItem) {
        var _this = this;
        if ((this.tierDetails.coverageFormula.isFormulaCalcType ||
            this.tierDetails.erFormula.isFormulaCalcType ||
            this.tierDetails.empFormula.isFormulaCalcType)
            && ((!this.tierDetails.coverageFormula.isFormulaCalcType && dataItem.coverage && this.form.valid) || this.tierDetails.coverageFormula.isFormulaCalcType)) {
            this.isLoading = true;
            this.apiService.getBenefitEnrolledPreviewEmployeesWithOptionCode(this.orgLevel.id, this.tierDetails.id, this.date, dataItem.coverage, dataItem.optionCode.code, [dataItem])
                .then(function (records) {
                var employeeMap = new Map(records.map(function (employee) { return [employee.employeeId, employee]; }));
                _.map([dataItem], function (employee) {
                    var record = employeeMap.get(employee.employeeId);
                    if (record) {
                        dataItem.employeeContributionAmount = record.employeeContributionAmount;
                        dataItem.employerContributionAmount = record.employerContributionAmount;
                        dataItem.calculatedCoverage = record.coverage;
                        dataItem.originalCoverage = record.coverage;
                    }
                });
            }).finally(function () {
                _this.isLoading = false;
                _this.validateFormulaEvaluation(dataItem, true);
            });
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.onCoverageAnyValueChange = function (value, dataItem, hasError) {
        if (!hasError) {
            this.calculateFormula(dataItem);
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        this.isCheckedAll = false;
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.filteredRecords = process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data;
        this.gridState.view = process(this.records, this.gridState.state);
    };
    BenefitEmployeesEnrolledComponent.prototype.onCancel = function () {
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    BenefitEmployeesEnrolledComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    BenefitEmployeesEnrolledComponent.prototype.validateFormulaEvaluation = function (dataItem, showWarning) {
        var hasFormula = (this.tierDetails.coverageFormula.isFormulaCalcType ||
            this.tierDetails.erFormula.isFormulaCalcType ||
            this.tierDetails.empFormula.isFormulaCalcType);
        if (hasFormula) {
            var errorMessage = this.commonValidationService.validateFormulaResult(dataItem.employeeContributionAmount, dataItem.employerContributionAmount, dataItem.calculatedCoverage);
            this.hasFormulaError = (errorMessage && errorMessage != '');
            if (this.hasFormulaError && showWarning) {
                var options = new ConfirmOptions();
                options.showCancel = false;
                options.showOK = true;
                ConfirmDialog2Component.openDialog('Warning', errorMessage, this.modalService, function (result) { }, options);
            }
        }
    };
    BenefitEmployeesEnrolledComponent.prototype.updateOptionViewData = function () {
        var _this = this;
        this.records.map(function (each) {
            each.optionViewText = _this.calcMethod.isOptionsRates ? each.optionRateCode : each.optionType;
        });
    };
    BenefitEmployeesEnrolledComponent.prototype.isEmpContribSelected = function (entry) {
        return !!_.get(entry, 'employeeContributionPercentage', null) || (entry.employeeContributionPercentage === 0);
    };
    BenefitEmployeesEnrolledComponent.prototype.isEmpPercentSelected = function (entry) {
        return !!_.get(entry, 'employeeContributionAmount', null) || (entry.employeeContributionAmount === 0);
    };
    BenefitEmployeesEnrolledComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitEmployeesEnrolledComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitEmployeesEnrolledComponent.prototype, "subscriptions", void 0);
    return BenefitEmployeesEnrolledComponent;
}());
export { BenefitEmployeesEnrolledComponent };
