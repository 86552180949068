import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { SummarySectionBase } from '../summary-section-base/summary-section.base';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { TimecardSummarySecondaryPosition } from '../../../models/index';
import { aggregateBy } from '@progress/kendo-data-query';
var PositionsSummaryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PositionsSummaryComponent, _super);
    function PositionsSummaryComponent(management) {
        var _this = _super.call(this, management) || this;
        _this.dollarFormat = '$0.00';
        _this.numberFormat = '0.00';
        _this.aggregates = [
            { field: 'rate', aggregate: 'sum' },
            { field: 'hours', aggregate: 'sum' }
        ];
        return _this;
    }
    PositionsSummaryComponent.prototype.ngOnInit = function () {
        this.setupManagementService();
    };
    PositionsSummaryComponent.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    PositionsSummaryComponent.prototype.checkDifference = function (item, diff) {
        var percent;
        percent = Math.abs(((item.hours - item.hours) / item.hours) * 100);
        if (percent === 0)
            return false;
        return percent > diff;
    };
    PositionsSummaryComponent.prototype.onDataLoaded = function () {
        _.noop();
    };
    PositionsSummaryComponent.prototype.refreshGrid = function () {
        _super.prototype.refreshGrid.call(this);
        this.totals = aggregateBy(this.container.records, this.aggregates);
    };
    PositionsSummaryComponent.prototype.onExcelExport = function (e) {
        var rows = e.workbook.sheets[0].rows;
        var cells = _.filter(rows, { type: 'footer' });
        _.forEach(_.head(cells).cells, function (cell) {
            var value = parseFloat(cell.value);
            if (_.isFinite(value)) {
                cell.value = value;
            }
        });
    };
    return PositionsSummaryComponent;
}(SummarySectionBase));
export { PositionsSummaryComponent };
