var SupervisedEmployee = /** @class */ (function () {
    function SupervisedEmployee() {
        this.selectable = true;
    }
    Object.defineProperty(SupervisedEmployee.prototype, "numberGroups", {
        get: function () {
            return this.groups ? this.groups.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    return SupervisedEmployee;
}());
export { SupervisedEmployee };
