import * as tslib_1 from "tslib";
import { BenefitDetailsBasic } from './benefit-details-basic';
import { BenefitDetailsState } from './benefit-details-state';
import { BenefitDetailsFormula } from './benefit-details-formula';
var BenefitDetailsTier = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitDetailsTier, _super);
    function BenefitDetailsTier() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tier = new BenefitDetailsBasic(null, '', '');
        //Formula calculation
        _this.empFormula = new BenefitDetailsFormula();
        _this.erFormula = new BenefitDetailsFormula();
        _this.coverageFormula = new BenefitDetailsFormula();
        _this.attachments = [];
        _this.options = [];
        _this.filesToAttach = [];
        return _this;
    }
    Object.defineProperty(BenefitDetailsTier.prototype, "id", {
        get: function () {
            return this.tier.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsTier.prototype, "name", {
        get: function () {
            return this.tier.name;
        },
        set: function (v) {
            this.tier.name = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsTier.prototype, "shortName", {
        get: function () {
            if (this.tier.name.length <= 25) {
                return this.tier.name;
            }
            return this.tier.name.slice(0, 25) + "...";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsTier.prototype, "title", {
        get: function () {
            return this.tier.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsTier.prototype, "shortTitle", {
        get: function () {
            if (this.tier.title.length <= 25) {
                return this.tier.title;
            }
            return this.tier.title.slice(0, 25) + "...";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsTier.prototype, "isDraft", {
        get: function () {
            return !isFinite(this.id) || this.id < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsTier.prototype, "hasTypeAndCode", {
        get: function () {
            if (Array.isArray(this.options) && this.options.length > 0) {
                return this.options.every(function (o) { return o.hasTypeAndCode; });
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsTier;
}(BenefitDetailsState));
export { BenefitDetailsTier };
