<slx-spinner [show]="state.isLoading">
    <!-- list state -->
    <div *ngIf="state.configureMode" class="inherit-height">
        <div *ngIf="!state.isEditing" class="slx-high-box">
          <div class="slx-content-toolbar-indents header">
            <div class="title">Choose a year: </div>
            <slx-actions-list>
              <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-start'">
                {{currentYear}} <span class="caret"></span>
              </slx-actions-button>
              <popper-content #popperContent>
                <slx-actions-list-item *ngFor="let year of yearItems" [className]="currentYear === year ? 'active': ''" (onClick)="onSelectYear(year)">{{year}}</slx-actions-list-item>
              </popper-content>
            </slx-actions-list>
          </div>

          <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
              <kendo-grid #kendoGrid class="slx-full-height"
                [data]="gridState?.view"
                [selectable]="{ mode: 'single' }"
                [filterable]="true"
                [sortable]="{ mode: 'multiple' }"
                [sort]="gridState?.state?.sort"
                [filter]="gridState?.state?.filter"
                (selectionChange)="gridState.selectionChange($event)"
                (dataStateChange)="gridState.dataStateChange($event)"
                (cancel)="gridState.cancelHandler($event)"
                (edit)="gridState.editHandler($event)"
                (remove)="gridState.removeHandler($event)"
                (save)="gridState.saveHandler($event)"
              >

                  <kendo-grid-command-column *ngIf="!access.restrictedByOrgLevel" width="60" [locked]="true" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
                          <div class="command-container">
                              <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
                                  <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                              </button>
                          </div>
                      </ng-template>
                  </kendo-grid-command-column>

                  <kendo-grid-column title="Holiday" field="name" fiterable="true" sortable="true" locked="false" width="150" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.name}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                      </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="Date" field="date" locked="false" width="150" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.date | amDateFormat: appConfig.dateFormat}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-date-filter>
                      </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="Start Date/Time" field="start" locked="false" width="200" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.start | amDateFormat: appConfig.dateTimeFormat}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-date-filter>
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="End Date/Time" field="end" locked="false" width="200" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.end | amDateFormat: appConfig.dateTimeFormat}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-date-filter>
                    </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="Holiday Rule" field="ruleDescription" locked="false" width="200" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.ruleDescription}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                      </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="Type" field="type" locked="false" width="150" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.type}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                      </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="Code" field="code" locked="false" width="150" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.code}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                      </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="Updated By" field="updatedBy" locked="false" width="150" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.updatedBy}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                      </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-column title="Updated On" field="updatedOn" locked="false" width="200" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem>
                          {{dataItem.updatedOn | amDateFormat: appConfig.dateTimeFormat}}
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-date-filter>
                      </ng-template>
                  </kendo-grid-column>


                  <!-- mobile view -->
                  <kendo-grid-column *ngIf="!access.restrictedByOrgLevel" title="Select" [sortable]="false" [filterable]="false" field="isSelected"
                      width="35" media="(max-width: 450px)">
                      <ng-template kendoGridHeaderTemplate>
                          <span></span>
                      </ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                          <input name="selector{{rowIndex}}" type="checkbox" [ngModel]="dataItem.isSelected" (ngModelChange)="crudHelper.selectionChange(dataItem, rowIndex)">
                      </ng-template>
                  </kendo-grid-column>

                  <kendo-grid-command-column *ngIf="!access.restrictedByOrgLevel" width="60" media="(max-width: 450px)">
                      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
                          <div class="command-container">
                              <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
                                  <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                              </button>
                          </div>
                      </ng-template>
                  </kendo-grid-command-column>

                  <kendo-grid-column media="(max-width: 450px)" width="360">
                      <ng-template kendoGridHeaderTemplate>Holidays</ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                          <div class="mobile-field-container">
                              <div class="col md-full-line-column text-column">Name: {{dataItem.name}}</div>
                              <div class="col md-full-line-column text-column">Date: {{dataItem.date | amDateFormat: appConfig.dateFormat}}</div>
                              <div class="col md-full-line-column text-column">Start: {{dataItem.start | amDateFormat: appConfig.dateTimeFormat}}</div>
                              <div class="col md-full-line-column text-column">End: {{dataItem.end | amDateFormat: appConfig.dateTimeFormat}}</div>
                              <div class="col md-full-line-column text-column">Rule: {{dataItem.ruleDescription}}</div>
                              <div class="col md-full-line-column text-column">Type: {{dataItem.type}}</div>
                              <div class="col md-full-line-column text-column">Code: {{dataItem.code}}</div>
                          </div>
                      </ng-template>
                  </kendo-grid-column>

              </kendo-grid>
          </form>

          <div class="actions-bar">
            <button class="action-button" [disabled]="access.restrictedByOrgLevel" (click)="onCopyItems ($event)">Copy</button>
            <div class="col md-no-line-column">
                <span *ngIf="access.restrictedByOrgLevel" class="edit-info-warning">Holidays can only be edited at the Facility level in the Org Tree.</span>
            </div>
            <button class="action-button" [slx-list-delete-operation]="onDeletedItems">Delete</button>
            <button class="action-button" [slx-list-add-operation]="onAddItem">Add</button>
          </div>

        </div>

        <!-- edit state -->
        <slx-holidays-editor *ngIf="state.isEditing" [item]="management.editingItem" [year]="management.year" [holidays]="crudHelper?.container?.records"
                             (save)="saveItem ()" (cancel)="discardChanges()"></slx-holidays-editor>

    </div>

    <!-- copy state -->
    <div *ngIf="state.copyMode" class="inherit-height">
      <slx-holidays-copy [holidays]="management.container.records" (cancel)="switchToConfigure()" [copyFromYear]="currentYear"></slx-holidays-copy>
    </div>
</slx-spinner>
