import * as tslib_1 from "tslib";
import { EssDefaultPassword } from './../../../../../../organization/models/lookup/ess-default-password';
import { NgForm } from '@angular/forms';
import { EmployeeDefinitionsApiService } from './../../../../../../organization/services/employee/employee-definitions-api.service';
import { AppSettingsManageService } from './../../../../../../app-settings/services/app-settings-manage.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { ConfirmOptions, ConfirmDialogComponent, ModalService } from './../../../../../../common/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { PayType, BenefitClassDefinition } from '../../../../../../organization/models/lookup/index';
import { EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { employeeConfig } from '../../../../employee.config';
import { PbjModes } from '../../../../../../app-settings/model/app-setting-keys';
import { EssTemplateApiService } from '../../../../../../app-modules/ess-templates/services/index';
var AddEmployeeEmploymentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddEmployeeEmploymentComponent, _super);
    function AddEmployeeEmploymentComponent(addEmployeeWizardActions, lookupApiService, employeeActivitiesValidatorAdapter, appSettingsManageService, employeeDefinitionApi, modalService, essTemplateApiService) {
        var _this = _super.call(this, addEmployeeWizardActions) || this;
        _this.appSettingsManageService = appSettingsManageService;
        _this.employeeDefinitionApi = employeeDefinitionApi;
        _this.modalService = modalService;
        _this.essTemplateApiService = essTemplateApiService;
        _this.minSalariedHour = 1;
        _this.hourlyRateLimit = 9999.99;
        _this.weeklyRateLimit = 399999.99;
        _this.hourlyRateWarningLimit = 299.99;
        _this.weeklyRateWarningLimit = 11999.99;
        _this.pbjEnabled = false;
        _this.pbjIsManual = false;
        _this.bswiftEnabled = false;
        _this.employmentValidationLoading = false;
        _this.isNgpUser = false;
        _this.lookupApiService = lookupApiService;
        _this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
        _this.state = {
            isPayTypeLoading: false,
            isBenefitClassLoading: false
        };
        _this.maxBirthDate = moment().subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
        return _this;
    }
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "isSalaryPayType", {
        get: function () {
            return this.addEmployeeModel.payType && this.addEmployeeModel.payType.isSalaryPayType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "isSalariedWithoutHours", {
        get: function () {
            return this.isSalaryPayType && this.minSalariedHour;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "payrollNumberValidation", {
        get: function () {
            return EmployeeValidatorAdapter.payrollNumberValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "ssnValidation", {
        get: function () {
            return EmployeeValidatorAdapter.ssnValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "pbjIdValidation", {
        get: function () {
            return EmployeeValidatorAdapter.pbjIdValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "getRateLimit", {
        get: function () {
            return this.isSalaryPayType ? this.weeklyRateLimit : this.hourlyRateLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "getRateWarningLimit", {
        get: function () {
            return this.isSalaryPayType ? this.weeklyRateWarningLimit : this.hourlyRateWarningLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeEmploymentComponent.prototype, "getRateTitle", {
        get: function () {
            return this.isSalaryPayType ? 'Weekly Rate' : 'Hourly Rate';
        },
        enumerable: true,
        configurable: true
    });
    AddEmployeeEmploymentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe(function (model) {
            _this.onWizard(model);
            if (_this.hasAssignedOrgLevel) {
                _this.loadPayTypeLookup();
                _this.loadBenefitClassLookup();
            }
            if (model != null && _this.addEmployeeRestrictions.ssn.isRequired != true) {
                _this.employmentValidationLoading = true;
                _this.loadEssTemplate(model.position.id)
                    .then(function (ess) {
                    if (_this.essTemplatesEnabled && ess && ess.defaultPwdTemplate === EssDefaultPassword.last4SSN) {
                        _this.addEmployeeRestrictions.ssn.isRequired = true;
                        if ((model.employeeType.name === 'AFT' || model.employeeType.name === 'APT') || model.organization.id == 1000) {
                            _this.addEmployeeRestrictions.ssn.isRequired = false;
                        }
                    }
                    _this.employmentValidationLoading = false;
                }).finally(function () {
                    _this.employmentValidationLoading = false;
                });
                ;
            }
        });
    };
    AddEmployeeEmploymentComponent.prototype.ngAfterViewInit = function () {
        if (this.addEmployeeModel.hireDate) {
            this.maxBirthDate = moment(this.addEmployeeModel.hireDate).subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
        }
        this.addChangesSubscriptionToForm(this.mainForm);
    };
    AddEmployeeEmploymentComponent.prototype.generatePbjId = function () {
        var _this = this;
        this.employeeDefinitionApi.generatePbjId()
            .then(function (value) {
            _this.addEmployeeModel.pbjId = value;
        });
    };
    AddEmployeeEmploymentComponent.prototype.onHireDateChange = function (date) {
        if (date) {
            if (this.addEmployeeModel) {
                this.addEmployeeModel.benefitClassEffectiveDate = date;
            }
            this.maxBirthDate = moment(date).subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
        }
        if (this.addEmployeeModel) {
            this.addEmployeeModel.hireDate = date;
        }
    };
    AddEmployeeEmploymentComponent.prototype.loadPayTypeLookup = function () {
        var _this = this;
        this.state.isPayTypeLoading = true;
        this.lookupApiService.getPayTypes()
            .then(function (payTypeLookup) {
            _this.payTypeLookup = payTypeLookup;
            _this.state.isPayTypeLoading = false;
        })
            .catch(function (res) {
            _this.state.isPayTypeLoading = false;
        });
    };
    AddEmployeeEmploymentComponent.prototype.loadBenefitClassLookup = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orgLevelId, benefitClassLookup, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSettings()];
                    case 1:
                        _a.sent();
                        if (!this.bswiftEnabled) {
                            return [2 /*return*/];
                        }
                        this.state.isBenefitClassLoading = true;
                        orgLevelId = _.get(this.addEmployeeModel, 'department.orgLevelId');
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.lookupApiService.getBenefitClassesDefinitions(null, orgLevelId)];
                    case 3:
                        benefitClassLookup = _a.sent();
                        this.benefitClassLookup = benefitClassLookup;
                        this.showBenefitClassPopup();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _a.sent();
                        return [3 /*break*/, 5];
                    case 5:
                        this.state.isBenefitClassLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddEmployeeEmploymentComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.appConfigPromise) {
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.appConfigPromise = config;
                        this.pbjEnabled = config.PbjEnabled;
                        this.pbjIsManual = config.PbjMode === PbjModes.Manual;
                        this.bswiftEnabled = config.bswiftIntegrationEnabled;
                        this.essTemplatesEnabled = config.essTemplatesEnabled;
                        this.isNgpUser = config.IsNextgenPayrollEnabled || false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddEmployeeEmploymentComponent.prototype.showBenefitClassPopup = function () {
        if (!_.isEmpty(this.benefitClassLookup)) {
            return;
        }
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Warning', 'Benefit class is required. You must configure Benefit class before the employee record can be created.', this.modalService, function () { }, options);
    };
    AddEmployeeEmploymentComponent.prototype.loadEssTemplate = function (positionid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.essTemplateApiService.getTemplateForPosition(positionid)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['addEmployeeWizard', 'model']),
        tslib_1.__metadata("design:type", Observable)
    ], AddEmployeeEmploymentComponent.prototype, "addEmployeeWizard$", void 0);
    return AddEmployeeEmploymentComponent;
}(AddEmployeeBasicComponent));
export { AddEmployeeEmploymentComponent };
