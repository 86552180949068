import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ToolbarBaseService } from '../../../core/services/index';
import { MasterScheduleSettings, MasterScheduleFilters, IMasterScheduleGroupingType } from '../../models/index';
var MasterScheduleToolbarService = /** @class */ (function (_super) {
    tslib_1.__extends(MasterScheduleToolbarService, _super);
    function MasterScheduleToolbarService() {
        var _this = _super.call(this) || this;
        _this.onSettingsChanged$ = new ReplaySubject(1);
        _this.onGroupingChanged$ = new ReplaySubject(1);
        return _this;
    }
    MasterScheduleToolbarService.prototype.settingsChanged = function (columnsSettings) {
        this.onSettingsChanged$.next(columnsSettings);
    };
    MasterScheduleToolbarService.prototype.groupingChanged = function (columnsSettings) {
        this.onGroupingChanged$.next(columnsSettings);
    };
    return MasterScheduleToolbarService;
}(ToolbarBaseService));
export { MasterScheduleToolbarService };
