import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { SupervisorAssignmentMapService } from './supervisor-assignment-map.service';
import { IEmployeePositionDefinition } from '../../../organization/models/index';
import {
  ISupervisor, Supervisor, IEligibleSupervisor, EligibleSupervisor,
  ISupervisedEmployee, SupervisedEmployee, ISupervisorGroup, SupervisorGroup, SupervisorGroupsContainer, UnassignedEmployee
} from '../../models/index';
import { SupervisorGroupDefinition, ISupervisorGroupDefinition, IEmployeeDefinition } from '../../../organization/models/index';

@Injectable()
export class SupervisorAssignmentApiService {
  constructor(
    private mapService: SupervisorAssignmentMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getSupervisorGroups(orgLevelId: number): Promise<SupervisorGroupsContainer> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.groups}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {});
    let promise: Promise<SupervisorGroupsContainer> = this.apiUtilService.request<ISupervisorGroup[], Meta>(request)
      .then((response: ResponseBody<ISupervisorGroup[], Meta>) => {
        return this.mapService.mapSupervisorGroupsContainer(response.data);
      });
    return promise;
  }

  public getUnassignedEmployees(orgLevelId: number): Promise<UnassignedEmployee[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.unassignedEmployees}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {});
    let promise: Promise<UnassignedEmployee[]> = this.apiUtilService.request<IEmployeePositionDefinition[], Meta>(request)
      .then((response: ResponseBody<IEmployeePositionDefinition[], Meta>) => {
        return this.mapService.mapUnassignedEmployees(response.data);
      });
    return promise;
  }

  public getSupervisedEmployees(orgLevelId: number, groupId: number): Promise<SupervisedEmployee[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(groupId, 'groupId');
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.employees}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { groupId: groupId });
    let promise: Promise<SupervisedEmployee[]> = this.apiUtilService.request<ISupervisedEmployee[], Meta>(request)
      .then((response: ResponseBody<ISupervisedEmployee[], Meta>) => {
        return this.mapService.mapSupervisedEmployees(response.data);
      });
    return promise;
  }

  public getEligibleSupervisors(orgLevelId: number, groupId: number): Promise<EligibleSupervisor[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(groupId, 'groupId');
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.supervisors}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { groupId: groupId });
    let promise: Promise<EligibleSupervisor[]> = this.apiUtilService.request<IEligibleSupervisor[], Meta>(request)
      .then((response: ResponseBody<IEligibleSupervisor[], Meta>) => {
        return this.mapService.mapEligibleSupervisors(response.data);
      });
    return promise;
  }

  public createGroup(group: SupervisorGroupDefinition): Promise<SupervisorGroupDefinition> {
    Assert.isNotNull(group, 'SupervisorGroupDefinition');
    const url: string = `${this.getOrgLevelApiRoot(group.orgLevelId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.group}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, this.mapService.mapGroupDefinitionDto(group));
    let promise: Promise<SupervisorGroupDefinition> = this.apiUtilService.request<ISupervisorGroupDefinition, Meta>(request)
      .then((response: ResponseBody<ISupervisorGroupDefinition, Meta>) => {
        return this.mapService.mapGroupDefinition(response.data);
      });
    return promise;
  }

  public updateGroup(group: SupervisorGroupDefinition): Promise<SupervisorGroupDefinition> {
    Assert.isNotNull(group, 'SupervisorGroupDefinition');
    const url: string = `${this.getOrgLevelGroupApiRoot(group.orgLevelId, group.id)}`;
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, this.mapService.mapGroupDefinitionDto(group));
    let promise: Promise<SupervisorGroupDefinition> = this.apiUtilService.request<ISupervisorGroupDefinition, Meta>(request)
      .then((response: ResponseBody<ISupervisorGroupDefinition, Meta>) => {
        return this.mapService.mapGroupDefinition(response.data);
      });
    return promise;
  }

  public deleteGroup(orgLevelId: number, group: SupervisorGroup): Promise<any> {
    Assert.isNotNull(group, 'SupervisorGroupDefinition');
    const url: string = `${this.getOrgLevelGroupApiRoot(orgLevelId, group.id)}`;
    let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });
    return promise;
  }

  public updateSupervisor(supervisor: Supervisor): Promise<Supervisor> {
    Assert.isNotNull(supervisor, 'Supervisor');
    const url: string = `${this.getApiRoot()}/${timeAndAttendanceConfig.api.supervisorAssignment.supervisor}/${supervisor.id}`;
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, this.mapService.mapSupervisorDto(supervisor));
    let promise: Promise<Supervisor> = this.apiUtilService.request<ISupervisor, Meta>(request)
      .then((response: ResponseBody<ISupervisor, Meta>) => {
        return this.mapService.mapSupervisor(response.data);
      });
    return promise;
  }

  public assignSupervisorsToGroup(orgLevelId: number, groupId: number, supervisors: Supervisor[]): Promise<Supervisor[]> {
    Assert.isNotNull(supervisors, 'assignSupervisorsToGroup');
    const url: string = `${this.getOrgLevelGroupApiRoot(orgLevelId, groupId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.assignSupervisorsToGroup}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, this.mapService.mapSupervisorsDto(supervisors));
    let promise: Promise<Supervisor[]> = this.apiUtilService.request<ISupervisor[], Meta>(request)
      .then((response: ResponseBody<ISupervisor[], Meta>) => {
        return this.mapService.mapSupervisors(response.data);
      });
    return promise;
  }

  public removeSupervisorsFromGroup(orgLevelId: number, groupId: number, supervisors: Supervisor[]): Promise<Supervisor[]> {
    Assert.isNotNull(supervisors, 'supervisors');
    const url: string = `${this.getOrgLevelGroupApiRoot(orgLevelId, groupId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.removeSupervisorsFromGroup}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, this.mapService.mapSupervisorIds(supervisors));
    let promise: Promise<Supervisor[]> = this.apiUtilService.request<ISupervisor[], Meta>(request)
      .then((response: ResponseBody<ISupervisor[], Meta>) => {
        return this.mapService.mapSupervisors(response.data);
      });
    return promise;
  }

  public assignEmployeesToGroup(orgLevelId: number, groupId: number, employees: SupervisedEmployee[]): Promise<SupervisedEmployee[]> {
    Assert.isNotNull(employees, 'assignSupervisorsToGroup');
    const url: string = `${this.getOrgLevelGroupApiRoot(orgLevelId, groupId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.assignEmployeesToGroup}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, this.mapService.mapEmployeeIds(employees));
    let promise: Promise<SupervisedEmployee[]> = this.apiUtilService.request<ISupervisedEmployee[], Meta>(request)
      .then((response: ResponseBody<ISupervisedEmployee[], Meta>) => {
        return this.mapService.mapSupervisedEmployees(response.data);
      });
    return promise;
  }

  public removeEmployeesFromGroup(orgLevelId: number, groupId: number, employees: SupervisedEmployee[]): Promise<SupervisedEmployee[]> {
    Assert.isNotNull(employees, 'employees');
    const url: string = `${this.getOrgLevelGroupApiRoot(orgLevelId, groupId)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.removeEmployeesFromGroup
      }`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, this.mapService.mapEmployeeIds(employees));
    let promise: Promise<SupervisedEmployee[]> = this.apiUtilService.request<ISupervisedEmployee[], Meta>(request)
      .then((response: ResponseBody<ISupervisedEmployee[], Meta>) => {
        return this.mapService.mapSupervisedEmployees(response.data);
      });
    return promise;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.supervisorAssignment.root}`;
  }
  private getOrgLevelApiRoot(orglevel: number): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.root}/${orglevel}`;
  }

  private getOrgLevelGroupApiRoot(orglevel: number, groupId: number): string {
    return `${this.getOrgLevelApiRoot(orglevel)}/${timeAndAttendanceConfig.api.supervisorAssignment.orglevel.group}/${groupId}`;
  }
}
