import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AcaC1095AuditManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../core/decorators';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { EmployeeSectionNavigationService } from '../../../../common';
import { appConfig } from '../../../../app.config';
import { AcaC1095AuditExport, EmployeeIdList } from '../../../models/aca-c1095-audit/aca-c1095-audit-record';
import { AcaC1095AuditAgGridHelper } from './aca-c1095-audit-ag-grid-helper';
import * as moment from 'moment';
var AcaC1095AuditGridComponent = /** @class */ (function () {
    function AcaC1095AuditGridComponent(managementService, router, route, elementRef) {
        this.managementService = managementService;
        this.router = router;
        this.route = route;
        this.elementRef = elementRef;
        this.columnList = [];
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.gridHelper = new AcaC1095AuditAgGridHelper();
        this.gridOptions = {};
        this.getRowHeight = function (params) {
            return 30;
        };
    }
    AcaC1095AuditGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridHelper.createColumnDefs();
        this.gridOptions = {
            columnDefs: this.gridHelper.columnDefs,
            defaultColDef: {
                minWidth: 100,
                suppressMenu: true
            },
            enableRangeSelection: true,
            groupSuppressAutoColumn: true,
            animateRows: true
        };
        this.gridOptions.getRowStyle = function (params) { return _this.gridHelper.getRowStyle(params); };
        this.subscriptions.onLoaded = this.managementService
            .subscribeToLoadedRecords(function (container) {
            _this.records = container.records;
            _this.storedRecords = _.clone(container.records);
            _this.refreshGrid();
        });
        this.subscriptions.expand = this.managementService
            .subscribeToExpandAll(function (isExpand) { return _this.expandAll(isExpand); });
        this.subscriptions.export = this.managementService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.empFilterRecords = this.managementService
            .subscribeToEmpFilterRecords(function (employeeFilter) { return _this.applyFilter(employeeFilter); });
        this.subscriptions.employeeFilter = this.managementService
            .subscribeToEmployeeFilter(function (records) {
            _this.applyFilter(records.filter);
        });
    };
    AcaC1095AuditGridComponent.prototype.ngOnDestroy = function () { };
    AcaC1095AuditGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        var records = _.clone(this.storedRecords);
        if (this.selectedEmployees && this.selectedEmployees.length > 0) {
            this.records = _.filter(records, function (r) {
                var res = false;
                _.forEach(_this.selectedEmployees, function (employee) {
                    res = res || _.isEqual(r.employeeId, employee.id);
                });
                return res;
            });
        }
        else {
            this.records = records;
        }
        if (this.records) {
            this.rowData = this.records;
            this.restoreScroll = true;
            this.generateExportData();
        }
    };
    AcaC1095AuditGridComponent.prototype.onRowGroupOpened = function (params) {
        this.hideShowRows(params);
        if (params.node.expanded) {
            if (params.node.level === 0) {
                this.setExpandedList(true, false, params.node.allLeafChildren[0].data.employeeId);
            }
            if (params.node.level === 1) {
                this.setExpandedList(true, true, params.node.allLeafChildren[0].data.employeeId);
            }
        }
        else {
            if (params.node.level === 1) {
                this.setExpandedList(true, false, params.node.allLeafChildren[0].data.employeeId);
            }
            else {
                this.setExpandedList(true, false, params.node.allLeafChildren[0].data.employeeId);
            }
        }
    };
    AcaC1095AuditGridComponent.prototype.hideShowRows = function (params) {
        if (this.elementRef) {
            var controls = this.elementRef.nativeElement;
            var elements = controls.querySelectorAll(".level_" + params.node.level + "_" + params.node.allLeafChildren[0].data.employeeId + "_" + params.node.allLeafChildren[0].data.month);
            _.forEach(elements, function (item) {
                if (params.node.expanded) {
                    item.setAttribute("style", "display:none;");
                }
                else {
                    item.setAttribute("style", "display:block;");
                }
            });
        }
    };
    AcaC1095AuditGridComponent.prototype.onCellClicked = function ($event) {
        if ($event.column.getColDef().colId === 'employeeId') {
            var empId = $event.node.allLeafChildren[0].data.employeeId;
            if (empId !== null && empId !== undefined)
                if (parseInt(empId) === 0) {
                    return;
                }
            var parsedEmpId = parseInt(empId, 10);
            var navService = new EmployeeSectionNavigationService(this.router, this.route);
            var urlTree = navService.getUrlTreeFromRoot(parsedEmpId, true);
            var extras = {
                skipLocationChange: false,
                replaceUrl: false,
            };
            this.router.navigateByUrl(urlTree, extras);
        }
    };
    AcaC1095AuditGridComponent.prototype.expandAll = function (isExpand) {
        _.forEach(this.records, function (item) {
            if (isExpand) {
                item.expand = true;
                item.expandMonth = true;
            }
            else {
                item.expand = false;
                item.expandMonth = false;
            }
        });
        this.managementService.setEmployeeGridExpandCollapse(isExpand);
        this.gridOptions.api.forEachNode(function (node) {
            node.expanded = isExpand;
        });
        this.gridOptions.api.onGroupExpandedOrCollapsed();
    };
    AcaC1095AuditGridComponent.prototype.onComponentStateChanged = function (event) {
        var _this = this;
        this.gridApi.forEachNode(function (node, b) {
            _.forEach(_this.storedRecords, function (k) {
                if (k.expand === true) {
                    if (node.key === k.employeeName) {
                        if (node.level === 0) {
                            node.setExpanded(true);
                        }
                    }
                }
            });
            _.forEach(_this.storedRecords, function (k) {
                if (k.expandMonth === true) {
                    if (node.key === k.month) {
                        if (node.level === 1) {
                            node.setExpanded(true);
                        }
                    }
                }
            });
        });
        if (this.restoreScroll && this.gridScrollElement) {
            this.restoreScroll = false;
            this.gridScrollElement.scrollTop = this.scrollTop;
            this.gridScrollElement.scrollLeft = this.scrollLeft;
            if (this.gridOptions.api) {
                this.gridOptions.api.redrawRows();
            }
        }
    };
    AcaC1095AuditGridComponent.prototype.setExpandedList = function (firstGroupState, secondGroupState, empId) {
        var employee = new EmployeeIdList();
        employee.employeeId = empId;
        employee.expand = firstGroupState;
        employee.expandMonth = secondGroupState;
        this.managementService.setEmployeeIdList(employee);
    };
    AcaC1095AuditGridComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    AcaC1095AuditGridComponent.prototype.onGridReadyExport = function (params) {
        this.gridApiExport = params.api;
    };
    AcaC1095AuditGridComponent.prototype.exportTo = function (isPDF) {
        var columnList = [
            'employeeName',
            'year',
            'month',
            'sugCode',
            'sugCost',
            'sugHarbor',
            'sugZip',
            'selCode',
            'selCost',
            'selHarbor',
            'selZip',
            'selectedBy',
            'selectedOn',
            'confirmedInd',
            'confirmedBy',
            'confirmedOn'
        ];
        var params = {
            allColumns: true,
            skipHeader: false,
            skipGroups: true,
            columnGroups: true,
            myGroupHeaderCallback: function (params) {
                var displayName = params.columnApi.getDisplayNameForColumnGroup(params.columnGroup);
                return displayName.toUpperCase();
            },
            sheetName: 'Aca_1095C_Audit',
            columnKeys: columnList,
            fileName: 'Aca_1095C_Audit_' + moment().format(appConfig.dateTimeFormat).replace(/_/g, " ") + '.xlsx',
        };
        if (this.gridApiExport) {
            this.gridApiExport.exportDataAsExcel(params);
        }
    };
    AcaC1095AuditGridComponent.prototype.generateExportData = function () {
        var exportRecords = [];
        _.forEach(this.records, function (item) {
            var record = new AcaC1095AuditExport();
            record.employeeId = item.employeeId;
            record.employeeName = item.employeeName;
            record.year = item.year;
            record.month = item.month;
            record.expand = item.expand;
            record.sugCode = item.sugCode;
            record.sugCost = item.sugCost;
            record.sugHarbor = item.sugHarbor;
            record.sugZip = item.sugZip;
            record.selCode = item.selCode;
            record.selCost = item.selCost;
            record.selZip = item.selZip;
            record.selHarbor = item.selHarbor;
            record.selectedBy = item.selectedBy;
            record.selectedOn = item.selectedOn;
            record.confirmedInd = item.confirmedInd;
            record.confirmedBy = item.confirmedBy;
            record.confirmedOn = item.confirmedOn;
            exportRecords.push(record);
        });
        this.exportRow = exportRecords;
    };
    AcaC1095AuditGridComponent.prototype.applyFilter = function (employeeFilter) {
        this.selectedEmployees = employeeFilter;
        this.refreshGrid();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaC1095AuditGridComponent.prototype, "subscriptions", void 0);
    return AcaC1095AuditGridComponent;
}());
export { AcaC1095AuditGridComponent };
