export interface IStaffingRatingRequest {
    startDate: string;
    endDate: string;
    organizationIds: number[];
}

export class StaffingRatingRequest {
    public startDate: Date;
    public endDate: Date;
    public organizationIds: number[];
}
