import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter, AfterViewInit } from '@angular/core';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { OrgLevel, OrgLevelType } from '../../../../../../state-model/models/index';
import * as _ from 'lodash';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { NgForm } from '@angular/forms';
var AddEmployeePayrollComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddEmployeePayrollComponent, _super);
    function AddEmployeePayrollComponent(addEmployeeWizardActions, lookupApiService, employeeActivitiesValidatorAdapter) {
        var _this = _super.call(this, addEmployeeWizardActions) || this;
        _this.lookupApiService = lookupApiService;
        _this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
        _this.onSelectOrganization = new EventEmitter();
        _this.state = {
            isDepartmentsLoading: false,
            isPositionsLoading: false,
        };
        _this.namesValidation = { isValid: true, isReadyForValidation: false, errorMessage: null };
        return _this;
    }
    Object.defineProperty(AddEmployeePayrollComponent.prototype, "orgLookup", {
        set: function (organizationLookup) {
            this.organizationLookup = organizationLookup;
            this.setPredefinedLookups();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeePayrollComponent.prototype, "canSelectPosition", {
        get: function () {
            if (!this.addEmployeeModel)
                return false;
            if (!this.addEmployeeModel.organization)
                return false;
            if (!this.addEmployeeModel.department)
                return false;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeePayrollComponent.prototype, "isLoading", {
        get: function () {
            return this.state.isDepartmentsLoading || this.state.isPositionsLoading;
        },
        enumerable: true,
        configurable: true
    });
    AddEmployeePayrollComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe(function (model) {
            _this.onWizard(model);
            _this.setPredefinedLookups();
            _this.validateNames();
        });
    };
    AddEmployeePayrollComponent.prototype.ngAfterViewInit = function () {
        this.addChangesSubscriptionToForm(this.mainForm);
    };
    AddEmployeePayrollComponent.prototype.setPredefinedLookups = function () {
        if (!this.addEmployeeModel || !this.organizationLookup || !this.currentOrgLevel)
            return;
        this.setPredefinedOrganizationOrgLevel();
    };
    AddEmployeePayrollComponent.prototype.setPredefinedOrganizationOrgLevel = function () {
        var _this = this;
        if (!this.addEmployeeModel.organization) {
            if (this.currentOrgLevel.type === OrgLevelType.organization) {
                this.addEmployeeModel.organization = _.find(this.organizationLookup, function (organization) {
                    return organization.orgLevelId === _this.currentOrgLevel.id;
                });
            }
            else if (this.currentOrgLevel.type === OrgLevelType.department) {
                this.addEmployeeModel.organization = _.find(this.organizationLookup, function (organization) {
                    return organization.orgLevelId === _this.currentOrgLevel.parentId;
                });
            }
            if (!this.addEmployeeModel.organization) {
                this.addEmployeeModel.organization = _.head(this.organizationLookup);
            }
        }
        this.storedOrg = this.addEmployeeModel.organization;
        if (this.addEmployeeModel.organization) {
            this.refreshPolicy();
        }
        this.loadDepartments()
            .then(function () { return _this.loadPositions(); });
    };
    AddEmployeePayrollComponent.prototype.setPredefinedDepartmentOrgLevel = function () {
        var _this = this;
        if (!this.addEmployeeModel.department) {
            if (this.currentOrgLevel.type === OrgLevelType.department) {
                this.addEmployeeModel.department = _.find(this.departmentLookup, function (department) {
                    return department.orgLevelId === _this.currentOrgLevel.id;
                });
            }
            if (!this.addEmployeeModel.department) {
                this.addEmployeeModel.department = _.first(this.departmentLookup);
            }
        }
        this.storedDep = this.addEmployeeModel.department;
    };
    AddEmployeePayrollComponent.prototype.setPredefinedPositionOrgLevel = function () {
        if (!this.addEmployeeModel.position) {
            this.addEmployeeModel.position = _.first(this.positionLookup);
        }
    };
    AddEmployeePayrollComponent.prototype.onOrganizationChanged = function (org) {
        var _this = this;
        var orgId = _.get(this.storedOrg, 'id') || -1;
        if (orgId !== org.id) {
            this.storedOrg = org;
            this.addEmployeeModel.department = null;
            this.addEmployeeModel.position = null;
            this.addEmployeeModel.shiftDiffPolicy = null;
            this.addEmployeeModel.payPolicy = null;
            this.loadDepartments()
                .then(function () { return _this.loadPositions(); });
            this.refreshPolicy();
        }
    };
    AddEmployeePayrollComponent.prototype.onDepartmentChanged = function (dep) {
        var depId = _.get(this.storedDep, 'id') || -1;
        if (depId !== dep.id) {
            this.storedDep = dep;
            this.addEmployeeModel.position = null;
            this.addEmployeeModel.shiftDiffPolicy = null;
            this.addEmployeeModel.payPolicy = null;
            this.loadPositions();
            this.validateNames();
        }
    };
    AddEmployeePayrollComponent.prototype.validateNames = function () {
        var _this = this;
        var namesData = {
            firstName: this.addEmployeeModel.firstName,
            lastName: this.addEmployeeModel.lastName,
            middleName: this.addEmployeeModel.middleName,
            suffix: this.addEmployeeModel.suffix,
            departmentOrgLevelId: this.addEmployeeModel.department ? this.addEmployeeModel.department.orgLevelId : null
        };
        this.employeeActivitiesValidatorAdapter.validate(EmployeeValidatorAdapter.namesValidation, null, namesData).
            then(function (result) {
            _this.namesValidation = result;
        })
            .catch(function () {
            _this.namesValidation = { isValid: false, isReadyForValidation: true, errorMessage: 'Server validation error!' };
        });
    };
    AddEmployeePayrollComponent.prototype.loadDepartments = function () {
        var _this = this;
        this.state.isDepartmentsLoading = true;
        return this.lookupApiService.getDepartments(undefined, this.addEmployeeModel.organization.orgLevelId)
            .then(function (departmentLookup) {
            _this.departmentLookup = departmentLookup;
            _this.setPredefinedDepartmentOrgLevel();
            _this.state.isDepartmentsLoading = false;
        })
            .catch(function (res) {
            _this.state.isDepartmentsLoading = false;
        });
    };
    AddEmployeePayrollComponent.prototype.loadPositions = function () {
        var _this = this;
        this.state.isPositionsLoading = true;
        this.lookupApiService.getPositions(undefined, this.addEmployeeModel.department.orgLevelId)
            .then(function (positionLookup) {
            _this.positionLookup = positionLookup;
            _this.setPredefinedPositionOrgLevel();
            _this.state.isPositionsLoading = false;
        })
            .catch(function (res) {
            _this.state.isPositionsLoading = false;
        });
    };
    AddEmployeePayrollComponent.prototype.refreshPolicy = function () {
        this.onSelectOrganization.emit(this.addEmployeeModel);
    };
    tslib_1.__decorate([
        mutableSelect(['addEmployeeWizard', 'model']),
        tslib_1.__metadata("design:type", Observable)
    ], AddEmployeePayrollComponent.prototype, "addEmployeeWizard$", void 0);
    return AddEmployeePayrollComponent;
}(AddEmployeeBasicComponent));
export { AddEmployeePayrollComponent };
