import { Injectable } from '@angular/core';
import { AuthenticationApiService } from '../authentication-api/authentication.api-service';
import { IServerValidationResult, IServerValidatorAdapter } from '../../../common/validators/common-validators-models';

@Injectable()
export class PasswordValidatorAdapter implements IServerValidatorAdapter {

    public static readonly passwordValidation: string = 'passwordValidation';
    public static readonly passwordValidationUnsecure: string = 'passwordValidationUnsecure';

    constructor(private api: AuthenticationApiService) { }

    public validate(validationName: string, value: any, ...params: any[]): Promise<IServerValidationResult> {
        let promise: Promise<IServerValidationResult>;
        let defPromise: Promise<IServerValidationResult> = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        let password: string = value;
        switch (validationName) {
            case PasswordValidatorAdapter.passwordValidation:
                promise = this.api.validatePassword(password);
                break;
            case PasswordValidatorAdapter.passwordValidationUnsecure:
                let alias: string = params[1];
                promise = this.api.validatePasswordUnsecure(alias, password);
                break;
            default:
                promise = defPromise;
        }
        return promise;
    }
}
