import { IPBJExportCSV } from "./pbj-export-csv";

export class PbjNewExportWarningDialogOptions {
  public title: string;
  public pbjNewExportCsvData: IPBJExportCSV[];
  constructor(title: string, pbjNewExportCsvData: IPBJExportCSV[]) {
    this.title = title;
    this.pbjNewExportCsvData = pbjNewExportCsvData;
  }
}
