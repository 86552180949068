import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { MetadataInfo, FieldData, IFieldData, FieldsMeta, Actions } from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';
import { IPbjOrganizationDTO, PbjOrganizationModel, PbjOrganization, IPbjOrganizationModelDTO, PbjOrganizationContainer, PBJMigrationVersion, ConversionState, IConversionStateDTO, CCNNumberValidation, ICCNNumberValidation } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { OrgPayrollAppStatus, PayrollAppStatus } from '../../models/pbj-organizations/payroll-app-status';

@Injectable()
export class PbjOrganizationsMapService {

    constructor(private metaMapService: MetaMapService) { }

    public mapToPbjorganizationsContainer(data: IPbjOrganizationDTO[], meta: FieldsMeta): PbjOrganizationContainer {
        let container: PbjOrganizationContainer = new PbjOrganizationContainer();
        container.records = this.mapToOrganizationModels(data, meta);
        return container;
    }

    public mapToOrganizationModels(dtos: IPbjOrganizationModelDTO[] | IPbjOrganizationDTO[], meta: FieldsMeta): PbjOrganizationModel[] {
        let models: PbjOrganizationModel[] = [];
        let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
        _.each(dtos, (dto: IPbjOrganizationModelDTO) => {
            let model: PbjOrganizationModel = this.mapToOrganizationModel(dto, metaMap);
            models.push(model);
        });
        return models;
    }

    public mapToOrganizationModel(dto: IPbjOrganizationModelDTO, metaMap: StringMap<IFieldData>): PbjOrganizationModel {
        let model: PbjOrganizationModel = new PbjOrganizationModel();
        model.pbjOrganization = this.mapToOrganization(dto);
        model.timezoneOffset = this.metaMapService.mapMeta(metaMap, 'timezoneOffset', model.pbjOrganization.timezoneOffset);
        model.telepunchOffset = this.metaMapService.mapMeta(metaMap, 'telepunchOffset', model.pbjOrganization.telepunchOffset);
        model.lastUpdateUsername = dto.lastUpdateUsername;
        model.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
        return model; 
    }

    public mapToOrganization(dto: IPbjOrganizationDTO): PbjOrganization {
        let model: PbjOrganization = new PbjOrganization();
        model.address = dto.address;
        model.city = dto.city;
        model.cmsPbjCode = dto.cmsPbjCode;
        model.company = dto.companyId;
        model.companyCode = dto.companyCode;
        model.group = dto.group;
        model.organizationId = dto.organizationId;
        model.name = dto.name;
        model.otherName = dto.otherName;
        model.phone = dto.phone;
        model.state = dto.state;
        model.telepunchOffset = dto.telepunchOffset;
        model.timezoneOffset = dto.timezoneOffset;
        model.type = dto.type;
        model.zip = dto.zip;
        model.migrationVersion = new PBJMigrationVersion(_.toLower(dto.migrationVersion), dto.migrationVersion);
        model.conversionDate = dateTimeUtils.convertFromDtoString(dto.conversionDate);
        model.conversionState = dto.conversionState ? this.dtoToConversionState(dto.conversionState) : null;
        model.messageForUsers = dto.messageForUsers;
        model.ccnNumber = dto.ccnNumber;
        model.asOshaCompany = dto.asOshaCompany;
        model.naicsCode = dto.naicsCode;
        model.naicsDescription = dto.naicsDescription;
        model.establishmentType = dto.establishmentType;
        model.establishmentSize = dto.establishmentSize;
        model.companyName=dto.companyName;
        model.payrollCode=dto.payrollCode;
        model.payrollAppStatus=new OrgPayrollAppStatus(dto.payrollAppStatus, PayrollAppStatus[dto.payrollAppStatus]);
        model.payrollAppEffectiveDate=dateTimeUtils.convertFromDtoString(dto.payrollAppEffectiveDate);
        model.payGroup = dto.payGroup;
        return model;
    }

    public mapToOrganizationDto(model: PbjOrganization): IPbjOrganizationDTO {
        let dto: IPbjOrganizationDTO = {
            organizationId: model.organizationId,
            name: model.name,
            city: model.city,
            address: model.address,
            cmsPbjCode: model.cmsPbjCode,
            companyCode: model.companyCode,
            companyId: model.company,
            group: model.group,
            type: model.type,
            otherName: model.otherName,
            phone: model.phone,
            state: model.state,
            zip: model.zip,
            telepunchOffset: model.telepunchOffset,
            timezoneOffset: model.timezoneOffset,
            migrationVersion: model.migrationVersion.name,
            conversionDate: dateTimeUtils.convertToDtoDateTimeString(model.conversionDate),
            messageForUsers: model.messageForUsers,
            conversionState: model.conversionState ? this.conversionStateToDto(model.conversionState) : null,
            ccnNumber: model.ccnNumber ? model.ccnNumber : null,
            asOshaCompany: model.asOshaCompany,
            naicsCode: model.naicsCode,
            naicsDescription: model.naicsDescription,
            establishmentType: model.establishmentType,
            establishmentSize: model.establishmentSize,
            companyName:model.companyName,
            payrollCode:model.payrollCode,
            payrollAppStatus:model.payrollAppStatus.id,
            payrollAppEffectiveDate:dateTimeUtils.convertToDtoDateTimeString(model.payrollAppEffectiveDate),
            payGroup: model.payGroup
        };
        return dto;
    }

    public conversionStateToDto(state: ConversionState): IConversionStateDTO {
        let dto: IConversionStateDTO = {
            id: state.id,
            finishedAt: state.finishedAt ? dateTimeUtils.convertToDtoString(state.finishedAt) : null,
            message: state.message,
            organizationId: state.organizationId,
            runBy: state.runBy,
            scheduledAt: state.scheduledAt ? dateTimeUtils.convertToDtoString(state.scheduledAt) : null,
            startedAt: state.startedAt ? dateTimeUtils.convertToDtoString(state.startedAt) : null,
            state: state.state
        };
        return dto;
    }

    public dtoToConversionState(dto: IConversionStateDTO): ConversionState {
        let state: ConversionState = new ConversionState();
        state.id = state.id;
        state.finishedAt = dto.finishedAt ? dateTimeUtils.convertFromDtoString(dto.finishedAt) : null;
        state.message = dto.message;
        state.organizationId = dto.organizationId;
        state.runBy = state.runBy;
        state.scheduledAt = dto.scheduledAt ? dateTimeUtils.convertFromDtoString(dto.scheduledAt) : null;
        state.startedAt = dto.startedAt ? dateTimeUtils.convertFromDtoString(dto.startedAt) : null;
        state.state = dto.state;
        return state;
    }

    public mapCCNValidation(dto: ICCNNumberValidation): CCNNumberValidation {
        const model: CCNNumberValidation = new CCNNumberValidation();
        model.value = dto.value;
        model.errorMessage = dto.errorMessage;
        model.isReadyForValidation = dto.isReadyForValidation;
        model.isValid = dto.isValid;
        return model;
    }
}
