import { IAppSetting } from './../../../app-settings/model/app-setting';
import { configurationConfig } from './../../configuration.config';
import { appSettingsConfig } from './../../../app-settings/app-settings.config';
import { UserPasswordSettingsMapService } from './user-password-settings-map.service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ApiUtilService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/api/response-body';
import { MetaMapService } from '../../../core/services/index';
import { UserPasswordSettings, IUserPasswordSettings } from '../../../core/models/index';

@Injectable() 
export class UserPasswordSettingsApiService {
  private category: String = 'UserPassword';
  constructor(private apiUtilService: ApiUtilService,
              private mapService: UserPasswordSettingsMapService,
              private metaMapService: MetaMapService) {
  }

  public getSettings(): Promise<UserPasswordSettings> {
    const url: string = this.getGetUrl();
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<IAppSetting[], Meta>(request)
      .then((response: ResponseBody<IAppSetting[], Meta>) => {
          return this.mapService.mapSettings(response.data);
    });
  }

  public saveSettings(settings: UserPasswordSettings): Promise<any> {
    const url: string = this.getSaveUrl();

    let body: any = settings;
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }

  private getSaveUrl(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.password.root}/${configurationConfig.api.configuration.password.policy}`;
  }

  private getGetUrl(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${appSettingsConfig.api.app.root}/${appSettingsConfig.api.app.settings}/${this.category}`;
  }

}
