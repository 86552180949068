import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

export abstract class ManagementBaseService<T, S> {

  public onLoadStatus$: ReplaySubject<boolean>;
  public onLoaded$: ReplaySubject<T>;
  public onStateChanged$: ReplaySubject<S>;
  public changeNotify$: Subject<string>;
  public hideOnPrint$: ReplaySubject<boolean>;
  protected subscriptions: StringMap<Subscription> = {};

  public state: S;

  constructor() {
    this.onLoadStatus$ = new ReplaySubject(1);
    this.onLoaded$ = new ReplaySubject(1);
    this.onStateChanged$ = new ReplaySubject(1);
    this.changeNotify$ = new Subject();
    this.hideOnPrint$ = new ReplaySubject(1);
  }

  public onStateChanged(state: S): void {
    this.state = state;
    this.onStateChanged$.next(state);
  }

  public onLoadStatusChanged(isLoading: boolean): void {
    this.onLoadStatus$.next(isLoading);
  }

  public onLoaded(container: T): void {
    this.onLoaded$.next(container);
  }

  public onChangeNotify(source: string): void {
    this.changeNotify$.next(source);
  }

  public onError(reason: any): void {
    this.onLoadStatus$.next(false);
    throw new Error(reason);
  }

  public destroy(): void {
    if(!this.subscriptions) {
      return;
    }
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public hideOnPrint(hide: boolean): void {
    this.hideOnPrint$.next(hide);
  }
}
