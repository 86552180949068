import * as _ from 'lodash';
import { Role, RoleRight, RoleRightModule, RoleRightGroup, RolesRightsAccess } from '../role-models/index';
import { RolesRowDefinition, IRolesRow } from './roles-row';


export class RolesRightRow implements IRolesRow<RoleRight> {
  public roleRightId: number;
  public roles: Role[];
  public mapByRole: NumberMap<RoleRight>;
  public dirtyByRole: NumberMap<boolean>;
  public type: RolesRowDefinition = 'RolesRightRow';
  public description: string;
  public linkedMenuId: number;
  public linkedMenuName: string;
  public displaySequence: number;

  public recalcStatus(roleId: number): void {
    let right: RoleRight = this.mapByRole[roleId];
    this.dirtyByRole[roleId] = right.isDirty;
  }
  public setNextStatus(roleId: number): void {
    let right: RoleRight = this.mapByRole[roleId];
    right.isEnabled = !right.isEnabled;
    this.recalcStatus(roleId);
  }
  public setStatus(isEnabled: boolean, roleId: number): void {
    let right: RoleRight = this.mapByRole[roleId];
    right.isEnabled = isEnabled;
    this.recalcStatus(roleId);
  }
}

export class RolesRightModuleRow implements IRolesRow<RoleRightModule> {
  public roleRightModuleId: number;
  public roles: Role[];
  public mapByRole: NumberMap<RoleRightModule>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesRightRow[];
  public type: RolesRowDefinition = 'RolesRightModuleRow';
  public description: string;
  public hasLinkedMenus: boolean;

  public recalcStatus(roleId: number): void {
    let module: RoleRightModule = this.mapByRole[roleId];
    let existDisabled: boolean = false;
    let existEnabled: boolean = false;
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (row: RolesRightRow) => {
      let right: RoleRight = row.mapByRole[roleId];
      if (right.isEnabled) {
        existEnabled = true;
      } else {
        existDisabled = true;
      }
      if (row.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if (existEnabled && existDisabled) {
      module.access = RolesRightsAccess.mixed;
    } else if (existDisabled) {
      module.access = RolesRightsAccess.disabled;
    } else if (existEnabled) {
      module.access = RolesRightsAccess.enabled;
    } else {
      module.access = RolesRightsAccess.mixed;
    }
  }

  public setNextStatus(roleId: number): void {
    let module = this.mapByRole[roleId];
    let enabled: boolean;
    if (module.access === RolesRightsAccess.mixed || module.access === RolesRightsAccess.disabled) {
      enabled = true;
      module.access = RolesRightsAccess.enabled;
    } else {
      enabled = false;
      module.access = RolesRightsAccess.disabled;
    }
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (row: RolesRightRow) => {
      let right: RoleRight = row.mapByRole[roleId];
      right.isEnabled = enabled;
      row.recalcStatus(roleId);
      if (row.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
  }
}

export class RolesRightGroupRow implements IRolesRow<RoleRightGroup> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleRightGroup>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesRightModuleRow[];
  public type: RolesRowDefinition = 'RolesRightGroupRow';
  public description: string;

  public recalcStatus(roleId: number): void {
    let group: RoleRightGroup = this.mapByRole[roleId];
    let existDisabled: boolean = false;
    let existEnabled: boolean = false;
    let existMixed: boolean = false;
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (row: RolesRightModuleRow) => {
      let rightModule: RoleRightModule = row.mapByRole[roleId];

      existEnabled = existEnabled || (rightModule.access === RolesRightsAccess.enabled);
      existDisabled = existDisabled || (rightModule.access === RolesRightsAccess.disabled);
      existMixed = existMixed || (rightModule.access === RolesRightsAccess.mixed);
      if (row.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if (existEnabled && existDisabled  || existMixed) {
      group.access = RolesRightsAccess.mixed;
    } else if (existDisabled) {
      group.access = RolesRightsAccess.disabled;
    } else if (existEnabled) {
      group.access = RolesRightsAccess.enabled;
    } else {
      group.access = RolesRightsAccess.mixed;
    }
  }

  public setNextStatus(roleId: number): void {
    let group = this.mapByRole[roleId];
    let enabled: boolean;
    if (group.access === RolesRightsAccess.mixed || group.access === RolesRightsAccess.disabled) {
      enabled = true;
      group.access = RolesRightsAccess.enabled;
    } else {
      enabled = false;
      group.access = RolesRightsAccess.disabled;
    }
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (row: RolesRightModuleRow) => {
      let rightModule: RoleRightModule = row.mapByRole[roleId];
      row.recalcStatus(roleId);
      if (row.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
  }
}
