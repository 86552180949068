import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { GeolocationManagementService } from '../../services/index';
import { IGeolocationEvent, GeolocationEntity } from '../../models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-geolocation',
    templateUrl: 'geolocation.component.html',
    styleUrls: ['geolocation.component.scss']
})

export class GeolocationComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public formIsOpen: boolean = false;
  public get animationCssClass(): string {
    return this.hideFormBeforeOpening ? 'c-hidden' : (this.formIsOpen ? 'g-shown' : 'g-hidden');
  }

  private hideFormBeforeOpening: boolean = true;
  private subscriptions: StringMap<Subscription> = {};

  constructor(private manService: GeolocationManagementService) {}

  public ngOnInit(): void {
    this.manService.init();

    this.subscriptions.buttons = this.manService.subscribeToGeoEvent((event: IGeolocationEvent) => {
      if (event.isAdd) {
        this.formIsOpen = true;
        this.hideFormBeforeOpening = false;
      } else if (event.isCancel) {
        this.formIsOpen = false;
      }
    });

    this.subscriptions.entitySelected = this.manService.subscribeToEntitySelected((entity: GeolocationEntity) => {
      if (_.isObjectLike(entity)) {
        this.formIsOpen = true;
        this.hideFormBeforeOpening = false;
      }
    });

    this.subscriptions.cancel = this.manService.subscribeToCloseForm(() => {
      this.formIsOpen = false;
    });

    this.subscriptions.loading = this.manService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnDestroy(): void {
    this.manService.destroy();

    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }
}
