import { HostListener, Directive, Input, HostBinding, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[disableClick]'
})
export class DisableClickDirective {
  @Input()
  public disableClick: boolean;
  @Output()
  public managedClick: EventEmitter<Event>;

  @HostBinding('class.disabled')
  public get disabled(): boolean {
    return this.disableClick;
  }

  constructor() {
    this.managedClick = new EventEmitter<Event>();
  }

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    if (!this.disableClick) {
      this.managedClick.next(event);
    }
  }
}
