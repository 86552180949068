import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import * as _ from 'lodash';

import { ResponseBody, Meta } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { appConfig } from '../../../../app.config';
import { SsoNavigationService } from '../../../../../app/organization/services/index';

@Injectable()
export class EmployeeSelfServiceApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private ssoService: SsoNavigationService) {
  }

  public createESSPolicy(employees: any[], passType: string, timeMethod: string, options: string[]): Promise<any> {
    let url: string = `${this.getApiRoot()}/${employeeConfig.api.employees.assignESSFlagsToEmployees}`;

    let flagSet: any = {
      defaultPassword: passType ? passType : 'NoChange',
      enterTimeMethod: timeMethod ? timeMethod : 'None',
      timecard: false,
      schedule: false,
      availability: false,
      inservice: false,
      changePassword: false,
      changeAddress: false
    };

    _.forEach(options, (item: string) => { flagSet[item] = true; });

    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, {
      flagSet: flagSet,
      employeeIds: employees
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public deleteESSPolicy(employees: number[]): Promise<any> {
    let url: string = `${this.getApiRoot()}/${employeeConfig.api.employees.unassignESSFlagsToEmployees}`;

    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, {
      employeeIds: employees
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public setEssPasswordState(employees: number[], setDefaultPassword: string, shouldResetPassword: boolean, customPassword: string): Promise<any> {
    let url: string = `${this.getApiRoot()}/${employeeConfig.api.employees.setEssPasswordStateToEmployees}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, {
      setDefaultPassword,
      shouldResetPassword,
      employeeIds: employees,
      customPassword: customPassword
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }
  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.employees.root}`;
  }
  public navigateSSO(menuItem: string, orgLevel_id: number): void {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${appConfig.api.sso}/${menuItem}?orglevel=${orgLevel_id}`;
    this.ssoService.navigationToV5(url);
  }
}
