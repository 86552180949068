import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ManagementBaseService } from '../../../../core/services/index';
import { PayrollPayCodesApiService } from "./payroll-pay-codes-api.service";
import { ChangeManagementService } from '../../../../common/index';
import { NotificationsService } from "../../../../../app/core/components";
import { orderBy } from "@progress/kendo-data-query";
var PayrollPayCodesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PayrollPayCodesManagementService, _super);
    function PayrollPayCodesManagementService(apiService, changeService, notificationService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.changeService = changeService;
        _this.notificationService = notificationService;
        _this.enableBulkSaveBtn = true;
        _this.sort = [{
                field: "payrollPaycode",
                dir: "asc",
            }];
        return _this;
    }
    PayrollPayCodesManagementService.prototype.init = function () {
        this.loadData();
        this.loadPayNumbers();
    };
    PayrollPayCodesManagementService.prototype.loadData = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.getPayrollPayCodes().then(function (value) {
            _this.list = value;
            _this.clearDirtyRecords();
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    PayrollPayCodesManagementService.prototype.loadPayNumbers = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.getPayNumbers().then(function (value) {
            _this.payNumbers = value.data;
        }).finally(function () {
            _this.isLoading = false;
        });
    };
    PayrollPayCodesManagementService.prototype.findDirtyRecords = function () {
        if (this.list.filter(function (e) { return e.isDirty; }).length > 0) {
            this.enableBulkSaveBtn = false;
            this.markAsDirty();
        }
        else {
            this.enableBulkSaveBtn = true;
            this.clearChanges();
        }
    };
    PayrollPayCodesManagementService.prototype.clearDirtyRecords = function () {
        if (this.list.filter(function (e) { return e.isDirty; }).length > 0) {
            this.list.forEach(function (e) { return e.isDirty = false; });
        }
        this.enableBulkSaveBtn = true;
        this.refreshGrid();
        this.clearChanges();
    };
    PayrollPayCodesManagementService.prototype.doBulkSave = function () {
        var _this = this;
        this.isLoading = true;
        var dirtyRecords = [];
        _.each(this.list, function (model) {
            if (model.isDirty) {
                dirtyRecords.push(model);
            }
            model.isDirty = false;
        });
        if (dirtyRecords.length > 0) {
            this.apiService.save(dirtyRecords)
                .then(function () {
                _this.notificationService.success("Pay numbers saved successfully");
            })
                .finally(function () {
                _this.isLoading = false;
                _this.loadData();
            });
        }
        else {
            this.isLoading = false;
        }
    };
    PayrollPayCodesManagementService.prototype.setItemDirty = function (item) {
        item.isDirty = true;
    };
    PayrollPayCodesManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    PayrollPayCodesManagementService.prototype.clearChanges = function () {
        this.changeService.clearChanges();
    };
    PayrollPayCodesManagementService.prototype.refreshGrid = function () {
        if (!this.list) {
            this.gridData = null;
            return;
        }
        var sortedRecords = orderBy(this.list, this.sort);
        this.gridData = {
            data: sortedRecords,
            total: sortedRecords.length
        };
    };
    return PayrollPayCodesManagementService;
}(ManagementBaseService));
export { PayrollPayCodesManagementService };
