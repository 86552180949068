import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, Input, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';

import { dateTimeUtils } from '../../../../../common/utils/index';

import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { DateRange, IDateRange } from '../../../../../core/models/index';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { NgModel } from '@angular/forms';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-absence-exceptions-tab',
  templateUrl: 'lm-absence-exceptions-tab.component.html',
  styleUrls: ['lm-absence-exceptions-tab.component.scss']
})
export class LMAbsenceExceptionsTabComponent implements OnInit,AfterContentChecked {

  @ViewChild('startDateModel', { static: false })
  public startDateModel: NgModel;
  @ViewChild('endDateModel', { static: false })
  public endDateModel: NgModel;
  
  public limitMinDate: Date = null;
  public limitMaxDate: Date = null;
  public startDate: Date = null;
  public endDate: Date = null;
  public loaDates: IDateRange = null;
  public exceptions: IDateRange[] = [];
  public customDates: IDateRange[] = [];
  public hasEqual: boolean = false;
  public hasOverlapped: boolean = false;
  public hasExceededCustom: boolean = false;
  public hasExcludedCustom: boolean = false;
  public hasExceededRepeatbaleDates: boolean = false;
  public hasExcludedStartEnd: boolean = false;
  public isIntermittent: boolean = false;
  public isContinuous: boolean = false;
  public isRepeatable: boolean = false;

  public appConfig: IApplicationConfig = appConfig;

  public get hasExceptions(): boolean {
    return _.size(this.exceptions) > 0;
  }

  public get hasCustomDates(): boolean {
    return _.size(this.customDates) > 0;
  }

  public get canModifyExceptions(): boolean {
    const continuousIsCorrect = this.manService.isContinuous && this.manService.hasCorrectContinuousDates;
    const repeatableIsCorrect = (this.manService.isDaily || this.manService.isWeekly) && this.manService.hasCorrectRepeatableDates;
    const customIsCorrect =  this.manService.isCustom && this.manService.hasCorrectCustomDates;
    return this.manService.canChange
      && (continuousIsCorrect || repeatableIsCorrect || customIsCorrect);
  }

  public get hasEstimatedDates(): boolean {
    return this.manService.hasEstimatedDates;
  }

  public get canAddException(): boolean {
    return this.isValidDate(this.startDate) && this.isValidDate(this.endDate)
      && !this.hasEqual
      && !this.hasOverlapped
      && !this.hasExceededCustom
      && !this.hasExcludedCustom
      && !this.hasExceededRepeatbaleDates
      && !this.hasExcludedStartEnd;
  }

  constructor(private manService: LMCreationAbsenceManagementService,private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.isIntermittent = this.manService.isIntermittent;
    this.isContinuous = this.manService.isContinuous;
    this.isRepeatable = this.manService.isDaily || this.manService.isWeekly || this.manService.isMonthly;
    this.customDates = this.manService.getCustomDates();
    this.exceptions = this.manService.getExceptions();
    this.loaDates = this.manService.getLoaDates();

    this.updateDefaultDatesLimit();
    this.updateDatesLimitForImtermittent();
  }

  public ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public onAddException(): void {
    const item = new DateRange(this.startDate, this.endDate);
    this.exceptions = [...this.exceptions, item];
    this.manService.setExceptions(this.exceptions);
    this.startDate = null;
    this.endDate = null;
  }

  public onDeleteException(index: number): void {
    this.exceptions = _.filter(this.exceptions, (ex: IDateRange, i: number) => i !== index);
    this.manService.setExceptions(this.exceptions);
  }

  public onChangeDate(): void {
    this.hasEqual = this.hasStartAndEndAreEqual();
    this.hasOverlapped = this.hasOverlappedWithExceptions();
    if (this.isIntermittent) {
      if (this.customDates.length > 1) {
        this.hasExceededCustom = this.hasExceededCustomDates();
        this.hasExcludedCustom = this.hasExcludedAllCustomDates();
      }
      if (this.isRepeatable) {
        this.hasExceededRepeatbaleDates = this.hasExceededRepeatableDates();
      }
    }
    if (this.isContinuous) {
      this.hasExcludedStartEnd = this.hasExcludedStartEndDates();
    }
  }

  public get startMaxDate(): Date{
    if(this.endDateModel && this.endDateModel.valid){
      return this.endDate;
    }else{
      return this.limitMaxDate;
    }
  }

  public get endMinDate(): Date{
    if(this.startDateModel && this.startDateModel.valid){
      return this.startDate;
    }else{
      return this.limitMinDate;
    }
  }

  private updateDefaultDatesLimit(): void {
    const { startDate, endDate } = this.manService.getLoaDates();
    if (_.isDate(startDate) && _.isDate(endDate) && endDate.getTime() > startDate.getTime()) {
      this.limitMinDate = startDate;
      this.limitMaxDate = endDate;
    }
  }

  private updateDatesLimitForImtermittent(): void {
    if (this.isIntermittent && this.hasCustomDates) {
      if (this.customDates.length === 1) {
        const { startDate, endDate } = _.head(this.customDates);
        this.limitMinDate = startDate;
        this.limitMaxDate = endDate;
      } else {
        const { minStart, maxEnd } = this.geMinMaxCustomDates();
        this.limitMinDate = minStart;
        this.limitMaxDate = maxEnd;
      }
    }
  }

  private hasStartAndEndAreEqual(): boolean {
    if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
      return dateTimeUtils.getTime(this.startDate) === dateTimeUtils.getTime(this.endDate);
    }
    return false;
  }

  private hasExcludedStartEndDates(): boolean {
    const { startDate: sDate, endDate: eDate } = this.loaDates || new DateRange(null, null);
    if (_.isDate(this.startDate) && _.isDate(this.endDate) && _.isDate(sDate) && _.isDate(eDate)) {
      return dateTimeUtils.getTime(this.startDate) === dateTimeUtils.getTime(sDate)
        && dateTimeUtils.getTime(this.endDate) === dateTimeUtils.getTime(eDate);
    }
    return false;
  }

  private hasOverlappedWithExceptions(): boolean {
    if (_.size(this.exceptions) > 0 && _.isDate(this.startDate) && _.isDate(this.endDate)) {
      const noOverlapping = _.every(this.exceptions, (d) => {
        const exceptionsAreOutside = dateTimeUtils.getTime(this.endDate) <= dateTimeUtils.getTime(d.startDate)
          || dateTimeUtils.getTime(this.startDate) >= dateTimeUtils.getTime(d.endDate);
        const exceptionsArentEqual = dateTimeUtils.getTime(this.startDate) !== dateTimeUtils.getTime(d.startDate)
          && dateTimeUtils.getTime(this.endDate) !== dateTimeUtils.getTime(d.endDate);

        return exceptionsAreOutside && exceptionsArentEqual;
      });
      return !noOverlapping;
    }
    return false;
  }

  private hasExceededCustomDates(): boolean {
    if (this.hasCustomDates && _.isDate(this.startDate) && _.isDate(this.endDate)) {
      const exceptionWithinCustomDates = _.some(
        this.customDates,
        (d) => dateTimeUtils.getTime(this.startDate) >= dateTimeUtils.getTime(d.startDate)
          && dateTimeUtils.getTime(this.endDate) <= dateTimeUtils.getTime(d.endDate)
      );
      return !exceptionWithinCustomDates;
    }
    return false;
  }

  private hasExcludedAllCustomDates(): boolean {
    if (this.hasCustomDates && _.isDate(this.startDate) && _.isDate(this.endDate)) {
      const { minStart, maxEnd } = this.geMinMaxCustomDates();
      const completelyEqualOrGreater = dateTimeUtils.getTime(this.startDate) <= dateTimeUtils.getTime(minStart) && dateTimeUtils.getTime(this.endDate) >= dateTimeUtils.getTime(maxEnd);
      return completelyEqualOrGreater
        || _.some(this.customDates, (d) =>
            (dateTimeUtils.getTime(this.startDate) <= dateTimeUtils.getTime(d.startDate) && dateTimeUtils.getTime(this.endDate) >= dateTimeUtils.getTime(d.endDate))
          );
    }
    return false;
  }

  private hasExceededRepeatableDates(): boolean {
    if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
      const absenceDays = this.manService.getAbsenceDays();
      const exceptionWithinAbsenceDates = _.some(
        absenceDays,
        (ad) => dateTimeUtils.getTime(this.startDate) >= dateTimeUtils.getTime(ad.startDate) && dateTimeUtils.getTime(this.endDate) <= dateTimeUtils.getTime(ad.endDate)
      );
      return !exceptionWithinAbsenceDates;
    }
    return false;
  }

  private geMinMaxCustomDates(): { minStart: Date, maxEnd: Date } {
    const sortedByStart = _.sortBy(this.customDates, (r: IDateRange) => dateTimeUtils.getTime(r.startDate));
    const sortedByEnd = _.sortBy(this.customDates, (r: IDateRange) => dateTimeUtils.getTime(r.endDate));

    return { minStart: _.head(sortedByStart).startDate, maxEnd: _.last(sortedByEnd).endDate };
  }

  private isValidDate(date: Date): boolean {
    return _.isDate(date) && dateTimeUtils.validateDate(date);
  }

}
