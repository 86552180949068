import { OnInit } from '@angular/core';
import { orderBy } from '@progress/kendo-data-query';
import { VacationPlanEmployeeSummary } from '../../../models/index';
import { appConfig } from '../../../../app.config';
var VacationEmployeeSummaryComponent = /** @class */ (function () {
    function VacationEmployeeSummaryComponent() {
        this.gridState = {
            skip: undefined,
            take: undefined,
            filter: undefined,
            sort: [{ field: 'date', dir: 'asc' }],
            group: undefined
        };
    }
    Object.defineProperty(VacationEmployeeSummaryComponent.prototype, "employeeSummary", {
        set: function (employeeSummary) {
            this.gridData = employeeSummary;
            if (this.gridData) {
                this.refreshGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    VacationEmployeeSummaryComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    VacationEmployeeSummaryComponent.prototype.sortChange = function (sort) {
        this.gridState.sort = sort;
        this.refreshGrid();
    };
    VacationEmployeeSummaryComponent.prototype.refreshGrid = function () {
        if (!this.gridData) {
            this.gridView = null;
            return;
        }
        var sortedRecords = orderBy(this.gridData.records, this.gridState.sort);
        this.gridView = {
            data: sortedRecords,
            total: sortedRecords.length
        };
    };
    return VacationEmployeeSummaryComponent;
}());
export { VacationEmployeeSummaryComponent };
