/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editable-list-editor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../custom-list-actor.base";
import * as i3 from "./editable-list-editor.component";
var styles_EditableListEditorComponent = [i0.styles];
var RenderType_EditableListEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditableListEditorComponent, data: {} });
export { RenderType_EditableListEditorComponent as RenderType_EditableListEditorComponent };
export function View_EditableListEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["default editor"]))], null, null); }
export function View_EditableListEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-editable-list-editor", [], null, null, null, View_EditableListEditorComponent_0, RenderType_EditableListEditorComponent)), i1.ɵprd(6144, null, i2.CustomListActorBase, null, [i3.EditableListEditorComponent]), i1.ɵdid(2, 245760, null, 0, i3.EditableListEditorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var EditableListEditorComponentNgFactory = i1.ɵccf("slx-editable-list-editor", i3.EditableListEditorComponent, View_EditableListEditorComponent_Host_0, { item: "item", selected: "selected", editable: "editable", selectable: "selectable", editMode: "editMode" }, { actionEmitter: "actionEmitter" }, []);
export { EditableListEditorComponentNgFactory as EditableListEditorComponentNgFactory };
