import { RoleField } from './role-models/index';
import { IProductModule } from '../../../organization/models/index';

export class RoleFieldRecord {
  public roleId: number;
  public sectionName: string;
  public subsectionName: string;
  public field: RoleField;
}

export class RoleRightRecord {
  public roleId: number;
  public rightId: number;
  public isEnabled: boolean;
}

export class RoleMenuModuleRecord {
  public roleId: number;
  public menuId: number;
  public isEnabled: boolean;
}

export class RoleComponentsModuleRecord {
  public roleId: number;
  public componentId: number;
  public isEnabled: boolean;
}

export class SaveRolesRequest {
  public roleFields: RoleFieldRecord[];
  public roleRights: RoleRightRecord[];
  public otherRoleRights: RoleRightRecord[];
  public roleMenuModules: RoleMenuModuleRecord[];
  public roleComponentModules: RoleComponentsModuleRecord[];
  public productModules: IProductModule[];
}
