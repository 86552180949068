import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { DialogOptions } from '../../../../common/models/dialog-options';
import { SupervisorGroup, SupervisorGroupsContainer, SupervisedEmployee, SupervisorAssignmentAction, EligibleSupervisor, UnassignedEmployee } from '../../../models/index';
import { SupervisorAssignmentManagementService } from '../../../services/supervisor-assignment/supervisor-assignment-management.service';
import { SupervisorAssignmentApiService } from '../../../services/supervisor-assignment/supervisor-assignment-api.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { SupervisorGroupGridComponent } from '../supervisor-group-grid/supervisor-group-grid.component';
import { UnassignedEmployeesPopupComponent } from '../unassigned-employees-popup/unassigned-employees-popup.component';
var SupervisorAssignmentComponent = /** @class */ (function () {
    function SupervisorAssignmentComponent(managementService, supervisorAssignmentApiService, modalService) {
        this.managementService = managementService;
        this.supervisorAssignmentApiService = supervisorAssignmentApiService;
        this.modalService = modalService;
        this.state = {
            isLoading: false,
            hasInnerChanges: false,
            groupEditMode: false,
            addSupervisorMode: false,
            addEmployeeMode: false,
            orgLvl: false
        };
    }
    Object.defineProperty(SupervisorAssignmentComponent.prototype, "isAnyGroupSelected", {
        get: function () {
            return this.selectedGroups && this.selectedGroups.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SupervisorAssignmentComponent.prototype, "isAnyEligibleSupervisorsSelected", {
        get: function () {
            return this.selectedEligibleSupervisors && this.selectedEligibleSupervisors.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SupervisorAssignmentComponent.prototype, "isAnyEligibleEmployeesSelected", {
        get: function () {
            return this.selectedEligibleEmployees && this.selectedEligibleEmployees.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    SupervisorAssignmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (container) {
            _this.container = container;
        });
        this.startEditSubscription = this.managementService.startEditGroupCmd$.subscribe(function () {
            _this.state.groupEditMode = true;
        });
        this.groupSavedSubscription = this.managementService.addedGroupCmd$
            .combineLatest(this.managementService.editedGroupCmd$, (function (addedGroup, editedGroup) {
            return { addedGroup: addedGroup, editedGroup: editedGroup };
        }))
            .subscribe(function (groups) {
            _this.state.groupEditMode = false;
            _this.selectedGroups = null;
        });
        this.supervisorAddedSubscription = this.managementService.addedSupervisorsCmd$
            .subscribe(function (result) {
            _this.state.addSupervisorMode = false;
        });
        this.employeeAddedSubscription = this.managementService.addedSupervisedEmployeesCmd$
            .subscribe(function (result) {
            _this.state.addEmployeeMode = false;
        });
        this.selectedGroupsSubscription = this.managementService.groupsSelected$
            .subscribe(function (groups) {
            _this.selectedGroups = groups;
        });
        this.startAddEmployeeSubscription = this.managementService.startAddEmployeeCmd$
            .subscribe(function (group) {
            _this.state.addEmployeeMode = true;
            _this.groupToAddEmployees = group;
            _this.eligibleEmployees = null;
            _this.selectedEligibleEmployees = null;
            _this.loadEligibleEmployees(_this.managementService.currentOrgLevel.id, group.id);
        });
        this.startAddSupervisorSubscription = this.managementService.startAddSupervisorCmd$
            .subscribe(function (group) {
            _this.state.addSupervisorMode = true;
            _this.groupToAddSupervisors = group;
            _this.eligibleSupervisors = null;
            _this.selectedEligibleSupervisors = null;
            _this.loadEligibleSupervisers(_this.managementService.currentOrgLevel.id, group.id);
        });
        this.stateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.state.orgLvl = state.orgLvl;
        });
        this.unassignedEmployeesSubscription = this.managementService.loadedUnassignedEmployeesCmd$
            .subscribe(function (employees) {
            if (_this.unassignedEmployeesDialog) {
                _this.unassignedEmployeesDialog.employees = employees;
            }
        });
    };
    SupervisorAssignmentComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SupervisorAssignmentComponent.prototype.onAddGroup = function () {
        this.state.groupEditMode = true;
        this.managementService.startAddGroupCmd();
    };
    SupervisorAssignmentComponent.prototype.onEditGroup = function () {
        this.state.groupEditMode = true;
        this.managementService.startEditGroupCmd();
    };
    SupervisorAssignmentComponent.prototype.onDeleteGroup = function () {
        if (!this.isAnyGroupSelected) {
            return;
        }
        var grpToDelete = this.selectedGroups[0];
        this.selectedGroups = null;
        this.managementService.deleteGroupCmd(grpToDelete);
    };
    SupervisorAssignmentComponent.prototype.onDiscard = function () {
        this.state.groupEditMode = false;
        this.state.addSupervisorMode = false;
        this.state.addEmployeeMode = false;
        this.managementService.actionCmd(SupervisorAssignmentAction.cancel);
        if (this.state.hasInnerChanges && this.managementService.currentOrgLevel) {
            this.state.hasInnerChanges = false;
            this.managementService.loadGroups(this.managementService.currentOrgLevel.id);
        }
    };
    SupervisorAssignmentComponent.prototype.onSaveGroup = function () {
        this.state.groupEditMode = false;
        this.state.addSupervisorMode = false;
        this.state.addEmployeeMode = false;
        this.managementService.actionCmd(SupervisorAssignmentAction.saveSupervisedGroup);
    };
    SupervisorAssignmentComponent.prototype.onSaveSupervisorToGroup = function () {
        this.state.groupEditMode = false;
        this.state.addSupervisorMode = false;
        this.state.addEmployeeMode = false;
        var supervisorsToAdd = this.selectedEligibleSupervisors;
        this.selectedEligibleSupervisors = null;
        this.managementService.addSupervisorsToGroupCmd(this.groupToAddSupervisors, supervisorsToAdd);
    };
    SupervisorAssignmentComponent.prototype.onSaveSupervisedEmployeeToGroup = function () {
        this.state.groupEditMode = false;
        this.state.addSupervisorMode = false;
        this.state.addEmployeeMode = false;
        this.state.hasInnerChanges = true;
        var employeesToAdd = this.selectedEligibleEmployees;
        this.selectedEligibleEmployees = null;
        this.managementService.addSupervisedEmployeesToGroupCmd(this.groupToAddEmployees, employeesToAdd);
    };
    SupervisorAssignmentComponent.prototype.selectEligibleSupervisors = function (selectedEligibleSupervisors) {
        this.selectedEligibleSupervisors = selectedEligibleSupervisors;
    };
    SupervisorAssignmentComponent.prototype.selectEligibleEmployees = function (selectedEligibleEmployees) {
        this.selectedEligibleEmployees = selectedEligibleEmployees;
    };
    SupervisorAssignmentComponent.prototype.showUnassignedClicked = function () {
        var _this = this;
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 800;
        dialogOptions.height = 600;
        dialogOptions.showCloseButton = true;
        this.unassignedEmployeesDialog = this.modalService.globalAnchor.openDialog(UnassignedEmployeesPopupComponent, 'Unassigned Employees', dialogOptions, undefined, function (result) {
            _this.unassignedEmployeesDialog = null;
        });
        this.managementService.loadUnassignedForCurrentOrgLevel();
    };
    SupervisorAssignmentComponent.prototype.loadEligibleSupervisers = function (orgLevelId, groupId) {
        var _this = this;
        this.managementService.onLoadStatusChanged(true);
        this.supervisorAssignmentApiService.getEligibleSupervisors(orgLevelId, groupId)
            .then(function (value) {
            _this.eligibleSupervisors = value;
            _this.managementService.onLoadStatusChanged(false);
        });
    };
    SupervisorAssignmentComponent.prototype.loadEligibleEmployees = function (orgLevelId, groupId) {
        var _this = this;
        this.managementService.onLoadStatusChanged(true);
        this.supervisorAssignmentApiService.getSupervisedEmployees(orgLevelId, groupId)
            .then(function (value) {
            _this.eligibleEmployees = value;
            _this.managementService.onLoadStatusChanged(false);
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "groupSavedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "supervisorAddedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "employeeAddedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "selectedGroupsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "startAddEmployeeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "startAddSupervisorSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "startEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorAssignmentComponent.prototype, "unassignedEmployeesSubscription", void 0);
    return SupervisorAssignmentComponent;
}());
export { SupervisorAssignmentComponent };
