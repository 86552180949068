import { Directive, Input, ElementRef, Renderer, HostListener, OnInit, Host, AfterViewInit, DoCheck } from '@angular/core';
import { GridComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { IFieldPolicy } from '../../../../core/models/index';


@Directive({
  selector: '[slxKendoGridColumnPolicy]',
})
export class KendoGridColumnPolicyDirective implements DoCheck, OnInit {
  @Input('slxKendoGridColumnPolicy')
  public columnPolicy: IFieldPolicy;

  private prevIsVisible: boolean;
  private prevIsMasked: boolean;

  constructor( @Host() private column: ColumnComponent) {
  }

  public ngOnInit(): void {
    if (!this.columnPolicy) {
      return;
    }
    this.applyVisiblePolicy();
    this.applyMaskedPolicy();
  }

  public ngDoCheck(): void {
    if (!this.columnPolicy || !this.column) {
      return;
    }
    this.applyVisiblePolicy();
    this.applyMaskedPolicy();
  }

  private applyVisiblePolicy(): void {
    if (this.prevIsVisible === this.columnPolicy.isVisible) {
      return;
    }
    this.column.hidden = !this.columnPolicy.isVisible;
    this.prevIsVisible = this.columnPolicy.isVisible;
  }

  private applyMaskedPolicy(): void {
    if (this.prevIsMasked === this.columnPolicy.isMasked) {
      return;
    }
    if (this.column.filterable !== false) {
      this.column.filterable = !this.columnPolicy.isMasked;
    }
    this.prevIsMasked = this.columnPolicy.isMasked;
  }
}
