<div class="left-toolbar" [ngClass]="{'left-full-width': alignExpandButtonRight && (collapsedSections && collapsedSections.length>0)}">
    <div class="left-toolbar-content">
        <ng-container *ngFor="let sectionTemplate of visibleSections">
          <ng-template [ngTemplateOutlet]="sectionTemplate.templateRef" [ngTemplateOutletContext]="{ sectionType: sectionTemplate.sectionType, counts: counts, isCollapsed: isCollapsedSection(sectionTemplate.sectionType) }"></ng-template>
        </ng-container>
    </div>
    <div class="left-toolbar-button">
        <span class="expand-toolbar-button" *ngIf="collapsedSections && collapsedSections.length>0" [ngClass]="{'expanded': isExpanded}"
        [popper]="popperContent"
        [popperTrigger]="'click'"
        [popperPlacement]="'bottom-start'"
        [popperDisableStyle]="'true'"
        (popperOnShown)="showCollapsed()"
        (popperOnHidden)="hideCollapsed()"
        >
        <i class="fa fa-ellipsis-h" [ngClass]="{'fa-ellipsis-h': !isExpanded, 'fa-ellipsis-v': isExpanded}" aria-hidden="true"></i>
      </span>
      <popper-content #popperContent class="toolbar-popup">
        <div class="collapsed-left-toolbar-content" [ngClass]="customPopperContentClass">
          <ng-container *ngFor="let sectionTemplate of collapsedSections">
            <ng-template [ngTemplateOutlet]="sectionTemplate.templateRef" [ngTemplateOutletContext]="{ sectionType: sectionTemplate.sectionType, counts: counts, isCollapsed: isCollapsedSection(sectionTemplate.sectionType) }"></ng-template>
          </ng-container>
        </div>
      </popper-content>
    </div>
</div>

<div class="right-toolbar">
  <ng-container *ngFor="let sectionTemplate of rightSections">
    <ng-template [ngTemplateOutlet]="sectionTemplate.templateRef" [ngTemplateOutletContext]="{ sectionType: sectionTemplate.sectionType, counts: counts, isCollapsed: isCollapsedSection(sectionTemplate.sectionType) }"></ng-template>
  </ng-container>
</div>
