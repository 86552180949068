export class ExceptionRecord {
    public exceptionId: string;
    public exceptionName: string;
    public exceptionCount: number;
}

export interface IExceptionRecord {
    exceptionId: string;
    exceptionName: string;
    exceptionCount: number;
}
