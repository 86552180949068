<div class="entry-def-cell">
  <div class="emp-shift-attribute-title">{{dailyData.startOfDay | amDateFormat: appConfig.dayFullNumberFormat}}</div>
  <div class="entry-shift-cell data-cell">
    <div class="emp-shift-attribute"
      *ngFor="let shiftDef of dailyData.data?.entryDef.shifts; let i = index; let isFirst = first;" id="s{{i}}"
      [ngClass]="{'cell-dirty': shiftDef.shift?.isDirty}">
      <div class="shift-def-remove" *ngIf="!readonly" (click)="onShiftDefRemove(shiftDef.shift, i, $event)">
        <i *ngIf="!shiftDef.shift?.isDirty" class="fa fa-times-circle" aria-hidden="true"></i>
        <i *ngIf="shiftDef.shift?.isDirty" class="undo-icon fa fa-undo-alt" aria-hidden="true"></i>
      </div>
      <div class="flex-horizontal">
        <div *ngIf="dailyData.data?.errors && dailyData.data?.errors[i]" title="{{dailyData.data?.errors[i]}}"
          class="shift-def-error">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
        </div>
        <div class="shift-value" title="{{shiftDef.position?.name}}">{{shiftDef.position?.name}}</div>
      </div>
      <div class="shift-value" title="{{shiftDef.shift?.name}}">{{shiftDef.shift?.name}}</div>
      <div class="shift-value" *ngIf="!shiftDef.absence && !shiftDef.partialAbsence"
        title="{{shiftDef.unit?.name}}{{shiftDef.constraint?.name ? '-' + shiftDef.constraint?.name : ''}}">
        {{shiftDef.unit?.name}}<span *ngIf="shiftDef.constraint && shiftDef.constraint.id"> -
          {{shiftDef.constraint.name}}</span>
      </div>
      <div class="shift-value" *ngIf="shiftDef.absence" title="{{shiftDef.absence.description}}">
        {{shiftDef.absence.description}}</div>
      <div class="shift-value partial-absence theme-tooltip" *ngIf="shiftDef.partialAbsence">
        {{shiftDef.partialAbsence.description}}*
        <span class="theme-tooltiptext totals-tooltip bottom">Partial Absence</span>
      </div>
    </div>
  </div>
</div>