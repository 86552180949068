var ConfOrganization = /** @class */ (function () {
    function ConfOrganization() {
    }
    return ConfOrganization;
}());
export { ConfOrganization };
var AdditionalInfo = /** @class */ (function () {
    function AdditionalInfo() {
    }
    return AdditionalInfo;
}());
export { AdditionalInfo };
var PositionModel = /** @class */ (function () {
    function PositionModel() {
        this.positionLicenseTypes = [];
        this.operator = 'AND';
        this.selectable = true;
        this.positionLicenseTypesSelection = [];
    }
    Object.defineProperty(PositionModel.prototype, "groupedWithName", {
        /* getters for grid filters */
        get: function () {
            return this.groupedWith ? this.groupedWith.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionModel.prototype, "budgetedPositionName", {
        get: function () {
            return this.budgetedPosition ? this.budgetedPosition.description : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionModel.prototype, "positionGroupName", {
        get: function () {
            return this.positionGroup ? this.positionGroup.name : 'Undefined';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionModel.prototype, "pbjCmsPositionName", {
        get: function () {
            return this.pbjCmsPosition ? this.pbjCmsPosition.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PositionModel.prototype, "isPositionLicense", {
        get: function () {
            var description = this.positionLicenseTypes.map(function (elem) {
                if (elem) {
                    return elem;
                }
            }).join(",");
            return description ? 'Y' : 'N';
        },
        enumerable: true,
        configurable: true
    });
    return PositionModel;
}());
export { PositionModel };
