import * as _ from 'lodash';
import { Component, Input, ChangeDetectorRef } from '@angular/core';
import { StaffingPredictorOrganizationInfo, StaffingPredictorRatingOption } from '../../models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-staffing-predictor-rating-predictor',
    templateUrl: 'staffing-predictor-rating-predictor.component.html',
    styleUrls: ['staffing-predictor-rating-predictor.component.scss']
})
export class StaffingPredictorRatingPredictorComponent {

    @Input()
    public set model(value: StaffingPredictorOrganizationInfo) {
        this.m_model = value;
        if (this.m_model) {
            this.selectedRating = _.clamp(this.m_model.organization.rating.value, 0, this.m_model.organization.rating.maxValue);
        } else {
            this.selectedRating = 0;
        }
        this.updatePossibleOptions();
    }

    @Input()
    public sectionIndex: number;

    public get model(): StaffingPredictorOrganizationInfo {
        return this.m_model;
    }

    public selectedRating: number;
    public possibleOptions: StaffingPredictorRatingOption[];

    private m_model: StaffingPredictorOrganizationInfo;

    constructor(private changeDetector: ChangeDetectorRef) { }

    public onPredictedRatingChange(value: number): void {
        this.selectedRating = value;
        this.updatePossibleOptions();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    }

    private updatePossibleOptions(): void {
        this.possibleOptions = _.filter(this.m_model.optionsTable.options, (o: StaffingPredictorRatingOption) => {
            return o.rating.value === this.selectedRating;
        });
    }
}
