export class TemplateValidator {
    public UniqueId: string;
    public Facility: string;
    public Department: string;
    public ConfigurationType: string;
    public CensusType: string;
    public Position: string;
    public AcuityTypes: string;
    public ShiftGroup: string;
    public Shift: string;
    public Unit: string;
    public CensusRangeFrom: number;
    public CensusRangeTo: number;
    public Sunday: number;
    public Monday: number;
    public Tuesday: number;
    public Wednesday: number;
    public Thursday: number;
    public Friday: number;
    public Saturday: number;
    public IsValidData: boolean;
}

export class DownloadedData {
    public facility: string;
    public department: string;
    public configurationType: string;
    public censusType: string;
    public position: string;
    public acuityTypes: string;
    public shiftGroup: string;
    public shift: string;
    public unit: string;
    public censusRangeFrom: number;
    public censusRangeTo: number;
    public sunday: number;
    public monday: number;
    public tuesday: number;
    public wednesday: number;
    public thursday: number;
    public friday: number;
    public saturday: number;
}

export class DownloadedExcelData {
    public UniqueId: string;
    public Facility: string;
    public Department: string;
    public ConfigurationType: string;
    public CensusType: string;
    public Position: string;
    public AcuityTypes: string;
    public ShiftGroup: string;
    public Shift: string;
    public Unit: string;
    public CensusRangeFrom: number;
    public CensusRangeTo: number;
    public Sunday: number;
    public Monday: number;
    public Tuesday: number;
    public Wednesday: number;
    public Thursday: number;
    public Friday: number;
    public Saturday: number;
}

export class UIException {
    public id?: string;
    public errorCode: string;
    public errorMessage: string;
}

export class CurrentOrganizationDetails {
    public organizationId: number;
    public organizationName: string;
    public departmentId: number;
    public departmentName: string;
}

export class ExcelData {
    public excelHeader: string[];
    public excelData: TemplateValidator[];
}

export class ImportFileDetails {
    public recordId: string;
    public fileData: TemplateValidator[];
}