<form novalidate #roleForm="ngForm" class="slx-full-height">
  <kendo-grid #grid class="slx-full-height slx-blue-grid"
    [data]="gridState.view"
    sortable="true"
    [sort]="gridState.state.sort"
    [selectable]="{ mode: 'single' }"
    [filterable]="'menu'"
    [filter]="gridState.state.filter"
    (cancel)="cancelHandler()"
    (edit)="editHandler($event)"
    (remove)="removeHandler($event)"
    (save)="saveHandler($event)"
    (selectionChange)="gridState.selectionChange($event)"
    (dataStateChange)="gridState.dataStateChange($event)"
  >

    <kendo-grid-command-column title="Сommand" width="80">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-rowIndex="rowIndex" let-dataItem="dataItem">
        <div class="flex-horizontal">
          <button kendoGridEditCommand type="button" class="slx-button slx-only-icon slx-toolbar" [disabled]="editedRecord && editedRecord != dataItem">
            <i class="fas fa-pencil-alt" aria-hidden="true"></i>
          </button>
          <button kendoGridRemoveCommand type="button" class="slx-button slx-only-icon slx-toolbar" [disabled]="editedRecord && editedRecord != dataItem">
            <i class="fas fa-times" aria-hidden="true"></i>
          </button>
          <button kendoGridSaveCommand type="button" class="slx-button slx-only-icon slx-toolbar" [disabled]="!roleForm.valid">
            <i class="fas fa-check-circle" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand type="button" class="slx-button slx-only-icon slx-toolbar">
            <i class="fas fa-ban" aria-hidden="true"></i>
          </button>
          <span class="clone-tip" *ngIf="roleToClone && rowIndex===-1">Clone from {{roleToClone.name}}</span>
        </div>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-column title="" [sortable]="true" field="name" media="sm" width="300" [filterable]="true">
      <ng-template kendoGridHeaderTemplate>
        Role Name
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.name}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator>
          <input slx-input required="true" name="name{{rowIndex}}" #nameField="ngModel" placeholder="Role Name" [(ngModel)]="dataItem.name"
            (keyup)="onKeyName($event, dataItem, nameField)">
          <span errorMessage for="required"></span>
          <span errorMessage for="unique">Role Name should be unique</span>
        </slx-input-decorator>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="100" title="Last Update Date" media="sm" field="lastUpdateDate">
      <ng-template kendoGridHeaderTemplate>
        Last Update Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem?.lastUpdateDate">{{dataItem?.lastUpdateDate | amDateFormat: appConfig.dateFormat }} </span>
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-date-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [width]="100" title="Last Update User" media="sm" field="lastUpdateUsername">
      <ng-template kendoGridHeaderTemplate>
        Last Update User
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.lastUpdateUsername}}
      </ng-template>
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

  </kendo-grid>
</form>
