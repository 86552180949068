import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { appConfig } from '../../../app.config';

@Directive({
  selector: '[slxExternalLink]',
})
export class ExternalLinkDirective {
  @Input()
  public slxExternalLink: 'help' | 'support';

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    switch (this.slxExternalLink) {
      case 'help':
        window.location.href = appConfig.links.helpLink;
        break;
      case 'support':
        window.location.href = appConfig.links.supportLink;
        break;
      default:
        throw new Error(`undefined external link ${this.slxExternalLink}`);
    }

  }
}
