<slx-spinner [show]="isLoading">
  <kendo-grid class="slx-full-height slx-pbj-rec-grid details"
    [data]="gridState.view"
    [rowClass]="defineRowClass()"
    >
    <kendo-grid-column media="(max-width: 780px)"
      [title]="columns.depName.title"
      [field]="columns.depName.field"
      [width]="columns.depName.mobileWidth"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <p class="pbj-rec-link slx-pbj-rec-grid__text-overflow" (click)="onDepClick(dataItem)">
          <i class="slx-pbj-rec-grid__icon fa fa-users" aria-hidden="true"></i>
          <span>{{ dataItem.depName }}</span>
        </p>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(max-width: 780px)"
      [title]="columns.timecardHours.title"
      [field]="columns.timecardHours.field"
      [width]="columns.timecardHours.mobileWidth"
      filter="numeric"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.timecardHours | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(max-width: 780px)"
      [title]="columns.expectedPbjHours.title"
      [field]="columns.expectedPbjHours.field"
      [width]="columns.expectedPbjHours.mobileWidth"
      filter="numeric"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.expectedPbjHours | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(max-width: 780px)"
      [title]="columns.variance.title"
      [field]="columns.variance.field"
      [width]="columns.variance.mobileWidth"
      filter="numeric"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.variance | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(max-width: 780px)"
      width="30"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate>&nbsp;</ng-template>
    </kendo-grid-column>


    <kendo-grid-column media="(min-width: 781px)"
      [title]="columns.depName.title"
      [field]="columns.depName.field"
      [width]="columns.depName.width"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <p class="pbj-rec-link slx-pbj-rec-grid__text-overflow" (click)="onDepClick(dataItem)">
          <i class="slx-pbj-rec-grid__icon fa fa-users" aria-hidden="true"></i>
          <span>{{ dataItem.depName }}</span>
        </p>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      [title]="columns.timecardHours.title"
      [field]="columns.timecardHours.field"
      [width]="columns.timecardHours.width"
      filter="numeric"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.timecardHours | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      *ngIf="showDetailedColumns"
      [title]="columns.startOfPeriod.title"
      [field]="columns.startOfPeriod.field"
      [width]="columns.startOfPeriod.width"
      filter="numeric"
      headerClass="slx-detailed-hd"
      class="slx-cell slx-detailed-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.startOfPeriod | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      *ngIf="showDetailedColumns"
      [title]="columns.endOfPeriod.title"
      [field]="columns.endOfPeriod.field"
      [width]="columns.endOfPeriod.width"
      filter="numeric"
      headerClass="slx-detailed-hd"
      class="slx-cell slx-detailed-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.endOfPeriod | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      *ngIf="showDetailedColumns"
      [title]="columns.excludedPayCodes.title"
      [field]="columns.excludedPayCodes.field"
      [width]="columns.excludedPayCodes.width"
      filter="numeric"
      headerClass="slx-detailed-hd"
      class="slx-cell slx-detailed-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.excludedPayCodes | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="columns.unmappedPositions.width"
      *ngIf="showDetailedColumns"
      [title]="columns.unmappedPositions.title"
      [field]="columns.unmappedPositions.field"
      media="(min-width: 781px)"
      filter="numeric"
      headerClass="slx-detailed-hd"
      class="slx-cell slx-detailed-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.unmappedPositions | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      *ngIf="showDetailedColumns"
      [title]="columns.manualPbjEntries.title"
      [field]="columns.manualPbjEntries.field"
      [width]="columns.manualPbjEntries.width"
      filter="numeric"
      headerClass="slx-detailed-hd"
      class="slx-cell slx-detailed-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.manualPbjEntries | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      *ngIf="showDetailedColumns"
      [title]="columns.unapprovedHours.title"
      [field]="columns.unapprovedHours.field"
      [width]="columns.unapprovedHours.width"
      filter="numeric"
      headerClass="slx-detailed-hd"
      class="slx-cell slx-detailed-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.unapprovedHours | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      [title]="columns.expectedPbjHours.title"
      [field]="columns.expectedPbjHours.field"
      [width]="columns.expectedPbjHours.width"
      filter="numeric"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.expectedPbjHours | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      [title]="columns.totalPbjHours.title"
      [field]="columns.totalPbjHours.field"
      [width]="columns.totalPbjHours.width"
      filter="numeric"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.totalPbjHours | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
    <kendo-grid-column media="(min-width: 781px)"
      [title]="columns.variance.title"
      [field]="columns.variance.field"
      [width]="columns.variance.width"
      filter="numeric"
      class="slx-cell"
    >
      <ng-template kendoGridCellTemplate let-dataItem><p class="slx-pbj-rec-grid__text">{{ dataItem.variance | slxPbjRecNumber }}</p></ng-template>
    </kendo-grid-column>
  </kendo-grid>
</slx-spinner>
