import { TimeclockDailySummary } from '../../../../organization/models/index';
import { TimeclockDataService } from './../../../../../app/organization';
var TimeclockStatusListComponent = /** @class */ (function () {
    function TimeclockStatusListComponent(_timeclockDataService) {
        this._timeclockDataService = _timeclockDataService;
    }
    Object.defineProperty(TimeclockStatusListComponent.prototype, "commStatus", {
        get: function () {
            return this.statusIsGood ? 'Online' : 'Offline';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockStatusListComponent.prototype, "statusIsGood", {
        get: function () {
            return this.timeclockStatus && this.timeclockStatus.communicationStatus > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockStatusListComponent.prototype, "errorsIsGood", {
        get: function () {
            return this.timeclockStatus && this.timeclockStatus.dailyFPErrorsPercent < 25;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclockStatusListComponent.prototype, "restrictionIsGood", {
        get: function () {
            return this.timeclockStatus && this.timeclockStatus.dailyRestrictionsPercent < 25;
        },
        enumerable: true,
        configurable: true
    });
    return TimeclockStatusListComponent;
}());
export { TimeclockStatusListComponent };
