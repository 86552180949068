<div *ngIf="!hideButton">
  <button type="button" (click)="onToggle()" class="theme-iconed-accent-button theme-action-button">
    <span class="icon-button-content"> 
      <span class="{{isCollapsed ? 'fa fa-plus' : 'fa fa-minus'}}"></span>
      <span>{{title}}</span>
    </span>
  </button>
</div>
<div class="collapse-state"  [ngClass]="{'collapse-state': isCollapsed, 'expanding-state': isExpanding }">
  <ng-content></ng-content>
</div>
  