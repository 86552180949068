import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { EventEmitter, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../../app.config';
import { EmployeeWarningSections, EmployeeWarningBasic, EmployeeWarningExtended } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EmployeeSectionsWarningsEntityComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsEntityComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.changeWarning = new EventEmitter();
        this.warningEdited = new EventEmitter();
        this.isLoading = true;
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.warning = new EmployeeWarningExtended();
    }
    Object.defineProperty(EmployeeSectionsWarningsEntityComponent.prototype, "employeeWarning", {
        set: function (v) {
            this.assignWarningBasic(v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWarningsEntityComponent.prototype, "canDownload", {
        get: function () {
            return _.isObjectLike(this.warningBasic) && _.isFinite(this.warningBasic.id);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsWarningsEntityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.createLoadedWarning();
        if (_.isNil(this.warningBasic)) {
            this.manService.getWarningTemplate();
        }
        else {
            this.manService.getWarning(this.warningBasic.id);
        }
        this.subscriptions.spinner = this.manService.subscribeToSpinner(function (isShown) {
            _this.isLoading = isShown;
            _this.updateView();
        });
        this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(function (warning) {
            _this.warning = warning;
            _this.updateView();
        });
        this.subscriptions.statusChanges = this.form.statusChanges.subscribe(function () {
            _this.manService.changeSectionValidity(EmployeeWarningSections.MainSection, _this.form.valid);
        });
        this.subscriptions.canSave = this.manService.subscribeToCanSaveWarning(function (canSave) {
            _this.changeWarning.next(canSave ? _this.warning : null);
        });
        this.subscriptions.canSave = this.manService.subscribeToChangesInWarning(function (hasChanges) {
            _this.warningEdited.next(hasChanges);
        });
    };
    EmployeeSectionsWarningsEntityComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.manService.destroyLoadedWarning();
    };
    EmployeeSectionsWarningsEntityComponent.prototype.onChangeWarningSubject = function () {
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsEntityComponent.prototype.onChangeWarningDate = function () {
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsEntityComponent.prototype.onDownload = function () {
        this.manService.downloadWarning(this.warningBasic.id);
    };
    EmployeeSectionsWarningsEntityComponent.prototype.updateView = function () {
        var _this = this;
        Observable.of(true)
            .delay(200)
            .subscribe(function () { return _this.changeDetector.detectChanges(); });
    };
    EmployeeSectionsWarningsEntityComponent.prototype.assignWarningBasic = function (warning) {
        this.warningBasic = _.isObjectLike(warning) && _.isFinite(warning.id) ? warning : null;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsWarningsEntityComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsWarningsEntityComponent;
}());
export { EmployeeSectionsWarningsEntityComponent };
