import { Injectable } from '@angular/core';

import { Meta, ResponseBody } from '../../../core/models/index';
import { UrlParamsService } from '../url-params/url-params.service';
import { ApiUtilService } from '../api/api-util.service';
import { AppUserSettingsMapService } from './app-user-settings-map.service';
import { AppUserSetting, IAppUserSetting } from '../../models/index';
import { commonConfig } from '../../common.config';
import { CacheType } from '../../../common/models/cache/cache-definition';

@Injectable()
export class AppUserSettingsApiService {

  constructor(private apiService: ApiUtilService, private urlParamsService: UrlParamsService, private mapService: AppUserSettingsMapService) {
  }

  public saveSettings(userSetting: AppUserSetting[]): Promise<any> {
    const url: string = `${this.apiService.getApiRoot()}/${commonConfig.api.settings}`;

    let promise: Promise<any> = this.apiService.request<any, Meta>(this.urlParamsService.createPutRequest(url, this.mapService.mapAppUserSettingsDtos(userSetting)))
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public getSettings(settingsGroup?: string, updateForced: boolean = false): Promise<AppUserSetting[]> {
    let url: string = `${this.apiService.getApiRoot()}/${commonConfig.api.settings}`;
    if(settingsGroup) {
      url = `${url}/${settingsGroup}`;
    }

    let promise: Promise<AppUserSetting[]> = this.apiService
      .cachedRequest<IAppUserSetting[], Meta>(this.urlParamsService.createGetRequest(url), CacheType.longTerm, updateForced)
      .then((response: ResponseBody<IAppUserSetting[], Meta>) => this.mapService.mapAppUserSettings(response.data));
    return promise;
  }

  public getSetting(settingsGroup: string, fieldName: string, updateForced: boolean = false): Promise<AppUserSetting> {
    let url: string = `${this.apiService.getApiRoot()}/${commonConfig.api.settings}/${settingsGroup}/${fieldName}`;
    let promise: Promise<AppUserSetting> = this.apiService
      .cachedRequest<IAppUserSetting, Meta>(this.urlParamsService.createGetRequest(url), CacheType.longTerm, updateForced)
      .then((response: ResponseBody<IAppUserSetting, Meta>) => this.mapService.mapAppUserSetting(response.data));
    return promise;
  }
}
