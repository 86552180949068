import { Component, OnInit } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import { WFMDashBoardModel, data_Colors } from '../../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../../configuration/services/wfm/wfm-sync.service';

@Component({
  selector: 'slx-summary-dashboard-migration',
  templateUrl: './summary-dashboard-migration.component.html',
  styleUrls: ['./summary-dashboard-migration.component.scss']
})
export class SummaryDashboardMigrationComponent implements OnInit {

  autofit:any;

  public pieData:WFMDashBoardModel[] =[];
  public color:string;

  constructor(private intl: IntlService,private wfmService:WfmSyncService) {
      this.labelContent = this.labelContent.bind(this);
  }
    ngOnInit(){
    this.res();
    }
  
  public labelContent(args: WFMDashBoardModel): string {
    this.res();
    const result = args.totalCount+ " share: \n" + args.statusMessage;
      return result;
  }
  public gridData:WFMDashBoardModel[]=[];
  public data:WFMDashBoardModel[]=[];
  public TotalCount:number;
  public res(){
    this.wfmService.syncDashboard('migration','all').then((value2:any)=>{
      this.gridData =data_Colors (value2.data);
      this.color=value2.color;
      this.data=value2.totalCount;
      this.TotalCount=value2.data[0].totalCount;
      // this.percentage();
    });
  }
}
