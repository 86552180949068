/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./radio-button.component";
var styles_RadioButtonComponent = [];
var RenderType_RadioButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioButtonComponent, data: {} });
export { RenderType_RadioButtonComponent as RenderType_RadioButtonComponent };
export function View_RadioButtonComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "label", [], [[1, "class", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "input", [["class", "slx-radio__radio"], ["name", "fieldName"], ["type", "radio"]], [[8, "checked", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 3).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.value = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(3, 212992, null, 0, i1.RadioControlValueAccessor, [i0.Renderer2, i0.ElementRef, i1.ɵangular_packages_forms_forms_o, i0.Injector], { name: [0, "name"], value: [1, "value"] }, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.RadioControlValueAccessor]), i0.ɵdid(5, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(7, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "slx-radio__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = "fieldName"; var currVal_10 = _co.option; _ck(_v, 3, 0, currVal_9, currVal_10); var currVal_11 = "fieldName"; var currVal_12 = (_co.readonly ? true : null); var currVal_13 = _co.value; _ck(_v, 5, 0, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cssClass(); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.isChecked(); var currVal_2 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 7).ngClassValid; var currVal_7 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); var currVal_14 = _co.caption; _ck(_v, 9, 0, currVal_14); }); }
export function View_RadioButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "slx-radio-button", [], null, null, null, View_RadioButtonComponent_0, RenderType_RadioButtonComponent)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.RadioButtonComponent]), i0.ɵdid(2, 49152, null, 0, i2.RadioButtonComponent, [], null, null)], null, null); }
var RadioButtonComponentNgFactory = i0.ɵccf("slx-radio-button", i2.RadioButtonComponent, View_RadioButtonComponent_Host_0, { fieldName: "fieldName", caption: "caption", className: "className", option: "option", valueField: "valueField", readonly: "readonly" }, {}, []);
export { RadioButtonComponentNgFactory as RadioButtonComponentNgFactory };
