import * as tslib_1 from "tslib";
import { Subject } from 'rxjs';
import { EmployeeSearchBase } from '../../../../employee/employee/services/employee-search-base/employee-search-base';
import { LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
var LookupEmployeeProvider = /** @class */ (function (_super) {
    tslib_1.__extends(LookupEmployeeProvider, _super);
    function LookupEmployeeProvider(lookupService) {
        var _this = _super.call(this) || this;
        _this.lookupService = lookupService;
        _this.employees$ = new Subject();
        return _this;
    }
    LookupEmployeeProvider.prototype.subscribeToEmployees = function (callback) {
        return this.employees$.subscribe(callback);
    };
    LookupEmployeeProvider.prototype.getEmployees = function (orgLevelId, isActive) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lookup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.lookupService.getLookup({
                            lookupType: LookupType.employeeList,
                            orgLevelId: orgLevelId,
                            isActive: isActive
                        })];
                    case 1:
                        lookup = _a.sent();
                        this.employees$.next(lookup);
                        return [2 /*return*/];
                }
            });
        });
    };
    return LookupEmployeeProvider;
}(EmployeeSearchBase));
export { LookupEmployeeProvider };
