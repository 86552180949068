export class IPartnerPosition {
    public map_id: number;
    public position_name: string;
    public partner_position_name: string;
}

export class PartnerPosition {
    public map_id: number;
    public position_name: string;
    public partner_position_name: string;
  }

