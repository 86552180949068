<kendo-grid class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [group]="gridState.state.group"
  [filterable]="true"
  [filter]="gridState.state.filter"
  [groupable]="{ showFooter: true, enabled: true }"
  (dataStateChange)="gridState.dataStateChange($event)"
>

  <kendo-grid-column title="Work Date" field="workDate">
    <ng-template kendoGridHeaderTemplate>
      Work Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.workDate | amDateFormat: appConfig.dateFormat}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Employee" field="employeeName">
    <ng-template kendoGridHeaderTemplate>
      Employee
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.employeeName}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position Name" field="positionName">
      <ng-template kendoGridHeaderTemplate>
        Position Name
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.positionName}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Unit Name" field="unitName">
        <ng-template kendoGridHeaderTemplate>
          Unit Name
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.unitName}}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
   </kendo-grid-column>

  <kendo-grid-column title="Shift Group Name" field="shiftGroupName">
    <ng-template kendoGridHeaderTemplate>
      Shift Group Name
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.shiftGroupName}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Shift Name" field="shiftName">
    <ng-template kendoGridHeaderTemplate>
      Shift Name
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.shiftName}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Hours"  class="numeric-cell" footerClass="numeric-cell" field="hours">
    <ng-template kendoGridHeaderTemplate>
      Hours
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.hours| number: ".2-2"}}
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['hours']?.sum | number: ".2-2" }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['hours']?.sum | number: ".2-2" }}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
