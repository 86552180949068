<div>
  <slx-input-decorator className="slx-form-group slx-multiselect-dropdown">
    <slx-dropdown-multiselect slx-input
      type="text"
      [lookup]="lookupName"
      [placeholder]="parameter.displayName"
      [(ngModel)]="lookupValue"
      [orgLevelId]="orgLevel.id"
      [isActive]="isActiveOnly"
    ></slx-dropdown-multiselect>
    <span errorMessage for="required">Report parameter is required.</span>
  </slx-input-decorator>
</div>
