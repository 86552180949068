import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsAttendancePointsEntry = /** @class */ (function () {
    function EmployeeSectionsAttendancePointsEntry() {
    }
    return EmployeeSectionsAttendancePointsEntry;
}());
export { EmployeeSectionsAttendancePointsEntry };
var EmployeeSectionsAttendancePoints = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAttendancePoints, _super);
    function EmployeeSectionsAttendancePoints() {
        var _this = _super.call(this) || this;
        _this.points = [];
        return _this;
    }
    return EmployeeSectionsAttendancePoints;
}(EmployeeSectionsBase));
export { EmployeeSectionsAttendancePoints };
var EmployeeSectionsAttendanceBuybacks = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAttendanceBuybacks, _super);
    function EmployeeSectionsAttendanceBuybacks() {
        var _this = _super.call(this) || this;
        _this.points = [];
        return _this;
    }
    return EmployeeSectionsAttendanceBuybacks;
}(EmployeeSectionsBase));
export { EmployeeSectionsAttendanceBuybacks };
