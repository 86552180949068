import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { LookupType } from '../../../../organization/models/index';
import { ModalService, ChangeManagementService } from '../../../../common/services/index';
import { TimecardAddCommentsDialogComponent } from '../../punches/add-comments-dialog/add-comments-dialog.component';
import { TimeCardModel, TimecardAddCommentsReq } from '../../../models/daily-timecard/index';
import { LookupService } from '../../../../organization/services/index';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
var CommentComponent = /** @class */ (function () {
    function CommentComponent(changeManagementService, modalService, lookupService, appSettingService) {
        this.changeManagementService = changeManagementService;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.appSettingService = appSettingService;
        this.isLoading = false;
        this.changeGroup = 'CommentComponent';
        this.addcomment = false;
        this.commentChanged = new EventEmitter();
        this.getSettings();
    }
    Object.defineProperty(CommentComponent.prototype, "model", {
        set: function (value) {
            if (!value) {
                return;
            }
            this.commentValue = value.comment.split("|").join("\n\n");
            this.empName = value.employee.name;
            this.empId = value.employee.id;
            this.empDate = value.date;
            this.commentValueInitial = value.comment === undefined || value.comment === null ? '' : value.comment;
        },
        enumerable: true,
        configurable: true
    });
    CommentComponent.prototype.onCommentChanged = function () {
        this.commentChanged.emit(this.commentValue.replace(/\n\n/g, "|"));
        this.notifyChangeManagement();
    };
    CommentComponent.prototype.clearComment = function () {
        this.commentValue = '';
        this.commentChanged.emit(this.commentValue);
    };
    CommentComponent.prototype.commentSelected = function (item) {
        if (item) {
            this.commentValue = item.description;
            this.onCommentChanged();
        }
    };
    CommentComponent.prototype.notifyChangeManagement = function () {
        if (this.commentValue !== this.commentValueInitial) {
            this.changeManagementService.changeNotify(this.changeGroup);
        }
        else {
            this.changeManagementService.clearChanges(this.changeGroup);
        }
    };
    CommentComponent.prototype.addComment = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, req, response;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoading = true;
                        if (!!this.isPredefinedCommentsExist) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getLookup({ lookupType: LookupType.timecardPredefinedComment })];
                    case 1:
                        _a.timecardPredefinedComments = _b.sent();
                        _b.label = 2;
                    case 2:
                        req = new TimecardAddCommentsReq();
                        req.employeeId = this.empId;
                        req.employeeName = this.empName;
                        req.date = this.empDate;
                        req.timecardPredefinedComments = this.timecardPredefinedComments;
                        req.employeeId = this.empId;
                        req.employeeName = this.empName;
                        req.date = this.empDate;
                        this.isLoading = false;
                        return [4 /*yield*/, TimecardAddCommentsDialogComponent.openDialog('Add Comments', req, this.modalService, 'multiple')];
                    case 3:
                        response = _b.sent();
                        if (!response.isApply) {
                            return [2 /*return*/];
                        }
                        else {
                            this.commentValue = response.comment;
                            this.onCommentChanged();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    CommentComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, config;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getLookup({ lookupType: LookupType.timecardPredefinedComment })];
                    case 1:
                        _a.timecardPredefinedComments = _b.sent();
                        return [4 /*yield*/, this.appSettingService.getAppServerConfig()];
                    case 2:
                        config = _b.sent();
                        this.addcomment = config.addTimeCardComments;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(CommentComponent.prototype, "isPredefinedCommentsExist", {
        get: function () {
            return this.timecardPredefinedComments && this.timecardPredefinedComments.items && this.timecardPredefinedComments.items.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return CommentComponent;
}());
export { CommentComponent };
