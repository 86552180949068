import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ReportGroup } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-report-group',
  templateUrl: 'report-group.component.html',
  styleUrls: ['report-group.component.scss']
})
export class ReportGroupComponent {

  @Input()
  public set reportGroup(value: ReportGroup) {
    this.m_reportGroup = value;
  }

  public get reportGroup(): ReportGroup {
    return this.m_reportGroup;
  }

  private m_reportGroup: ReportGroup;
}
