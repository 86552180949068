export interface IAccrualsConfig {
  api: {
    accruals: {
      root: string;
      balances: string;
      transaction: string;
      status: {
        root: string;
        calculationRequest: string;
      }
      submit: {
        root: string;
        recalculate: string;
      }
      policy: {
        root: string;
        detailed: {
          root: string;
          all: string;
        }
        short: {
          root: string;
          all: string;
        }
        references: string;
        exists: string;
        export: {
          root: string;
          excel: string;
          pdf: string;
        }
        updatePolicy: {
          root: string;
        }
        deletePolicy: {
          root: string;
        },
        deleteBenefit: {
          root: string;
        },
        updatePolicyName: {
          root: string;
        },
        getOrganizations: {
          root: string;
        },
        copyPolicies: {
          root: string;
        }
      },
      rule: {
        root: string;
        detailed: {
          root: string;
          all: string;
        }
        short: {
          root: string;
          all: string;
        }
      }
    }
  };
}

export const accrualsConfig: IAccrualsConfig = {
  api: {
    accruals: {
      root: 'accruals',
      balances: 'balance',
      transaction: 'transaction',
      status: {
        root: 'status',
        calculationRequest: 'calculationRequest'
      },
      submit: {
        root: 'submit',
        recalculate: 'recalculate'
      },
      policy: {
        root: 'policy',
        detailed: {
          root: 'detailed',
          all: 'all'
        },
        short: {
          root: 'short',
          all: 'all'
        },
        references: 'references',
        exists: 'exists',
        export: {
          root: 'export',
          excel: 'excel',
          pdf: 'pdf'
        },
        updatePolicy: {
          root: 'updatePolicy'
        },
        deletePolicy: {
          root: 'deletePolicy'
        },
        deleteBenefit: {
          root: 'deleteBenefit'
        },
        updatePolicyName: {
          root: 'updatepolicyname'
        },
        getOrganizations: {
          root: 'getOrganizations'
        },
        copyPolicies: {
          root: 'copyPolicies'
        }
      },
      rule: {
        root: 'rule',
        detailed: {
          root: 'detailed',
          all: 'all'
        },
        short: {
          root: 'short',
          all: 'all'
        }
      }
    }
  }
};
