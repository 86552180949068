import { Provider } from '@angular/core';
import { DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../../../app/common/models/index';
import { ModalService } from '../../../../../../../app/common/services/modal/modal.service';
import { EnrollmentHistoryRecord } from '../../../../models';
import { appConfig } from '../../../../../../../app/app.config';
var BenefitPlanSaveNotesDialogComponent = /** @class */ (function () {
    function BenefitPlanSaveNotesDialogComponent(dialogOptions, modalService, selectedRecord) {
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.selectedRecord = selectedRecord;
        this.appConfig = appConfig;
        this.modalService = modalService;
        this.enrollmentStartDate = selectedRecord ? selectedRecord.startDate : null;
        this.enrollmentEndDate = selectedRecord ? selectedRecord.endDate : null;
        this.notes = selectedRecord ? selectedRecord.notes : null;
    }
    BenefitPlanSaveNotesDialogComponent.openDialog = function (selectedRecord, fullName, modalService, callback) {
        var title = fullName + ' - ' + selectedRecord.plan;
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.dialog;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EnrollmentHistoryRecord,
                useValue: selectedRecord
            }
        ];
        return modalService.globalAnchor
            .openDialog2(BenefitPlanSaveNotesDialogComponent, title, dialogOptions, resolvedProviders, function (result) {
            callback(result);
        });
    };
    Object.defineProperty(BenefitPlanSaveNotesDialogComponent.prototype, "hasNotes", {
        get: function () {
            return !(this.notes || this.selectedRecord.notes);
        },
        enumerable: true,
        configurable: true
    });
    BenefitPlanSaveNotesDialogComponent.prototype.save = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    BenefitPlanSaveNotesDialogComponent.prototype.cancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    return BenefitPlanSaveNotesDialogComponent;
}());
export { BenefitPlanSaveNotesDialogComponent };
