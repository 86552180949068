import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ElementRef, Renderer2, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/delay';
import { unsubscribe } from './../../../../core/decorators/index';
var KendoGridHeaderTooltip = /** @class */ (function () {
    function KendoGridHeaderTooltip(elRef, renderer) {
        this.elRef = elRef;
        this.renderer = renderer;
        this.startAssignment$ = new Subject();
        this.kendoGridHeaderClass = 'k-header';
    }
    KendoGridHeaderTooltip.prototype.ngOnInit = function () {
        var _this = this;
        this.assignmentSubscription = this.startAssignment$
            .delay(500)
            .subscribe(function (elem) { return _this.setTooltip(elem); });
    };
    KendoGridHeaderTooltip.prototype.ngAfterViewInit = function () {
        var nativeElem = this.elRef.nativeElement;
        if (_.isElement(nativeElem)) {
            this.renderer.setAttribute(nativeElem, 'class', 'slx-grid-col-header');
            this.startAssignment$.next(nativeElem);
        }
    };
    KendoGridHeaderTooltip.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        if (this.assignmentSubscription) {
            this.assignmentSubscription.unsubscribe();
        }
    };
    KendoGridHeaderTooltip.prototype.setTooltip = function (element) {
        var header = this.findHeader(element);
        if (_.isElement(header)) {
            this.renderer.setAttribute(header, 'title', this.tooltip || element.innerHTML);
        }
    };
    KendoGridHeaderTooltip.prototype.findHeader = function (element) {
        var parent = element && element.parentElement || null;
        if (_.isElement(parent)) {
            if (parent.classList.contains(this.kendoGridHeaderClass)) {
                return parent;
            }
            return this.findHeader(parent);
        }
        return null;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridHeaderTooltip.prototype, "assignmentSubscription", void 0);
    return KendoGridHeaderTooltip;
}());
export { KendoGridHeaderTooltip };
