import { ShiftSwapDetails, EmployeeShiftSwapDetails } from './../../../models/shift-swap/shift-swap-details';
import { ShiftSwapManageService } from './../../../services/shift-swap/shift-swap-manage.service';
import { IColumnSettings } from './../../../../core/models/settings/column-settings';
import { ShiftSwapInfo, ShiftSwapProfile } from '../../../models/shift-swap/shift-swap-info';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-shift-swap-approve-deny',
    templateUrl: 'shift-swap-approve-deny.component.html',
    styleUrls: ['shift-swap-approve-deny.component.scss']
})
export class ShiftSwapApproveDenyComponent {
    @Output()
    public buttonClicked: EventEmitter<number>;
    @Input()
    public set shiftSwap(value: ShiftSwapInfo) {
        this.m_shiftSwap = value;
        if (value) {
            this.loadDetails();
        }
    }

    public get shiftSwap(): ShiftSwapInfo {
        return this.m_shiftSwap;
    }

    public comment: string = '';
    public details: ShiftSwapDetails;
    private m_shiftSwap: ShiftSwapInfo;

    constructor(public manageService: ShiftSwapManageService) {
        this.buttonClicked = new EventEmitter();
    }

    public get statusStyle(): string {
        if (!this.shiftSwap) {
            return 'status-pending';
        }
        return 'status-' + this.shiftSwap.status.toLowerCase();
    }

    public onColumnsChanged(): void {
        this.manageService.recalculateColumns();
    }

    public denyClick(): void {
        this.manageService.deny(this.shiftSwap, this.comment).then((value: any) => {
            this.buttonClicked.emit(2);
        });
    }

    public approveClick(): void {
        this.manageService.approve(this.shiftSwap, this.comment).then((value: any) => {
            this.buttonClicked.emit(1);
        });
    }

    public closeClick(): void {
        this.buttonClicked.emit(0);
    }

    public getDetailsProperty(propName: string, from: boolean): string {
        if (!this.details) {
            return 'Loading...';
        }
        const emp: EmployeeShiftSwapDetails = from ? this.details.submittedBy : this.details.submittedTo;
        return emp ? emp[propName] : '-';
    }

    private loadDetails(): void {
        this.manageService.loadDetails(this.shiftSwap).then((value: ShiftSwapDetails) => {
            this.details = value;
        });
    }
}
