/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lm-creation-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../lm-creation-container/lm-creation-container.component.ngfactory";
import * as i3 from "../../../services/lm-recurrence/lm-recurrence.service";
import * as i4 from "../../../services/lm-request-mapper/lm-request-mapper.service";
import * as i5 from "../../../services/lm-creation-absence/lm-creation-absence.management.service";
import * as i6 from "../../../services/lm-management.service";
import * as i7 from "../../../../../organization/services/lookup/lookup.service";
import * as i8 from "../../../services/lm-api.service";
import * as i9 from "../../../../../common/services/file/file.service";
import * as i10 from "../lm-creation-container/lm-creation-container.component";
import * as i11 from "../../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i12 from "./lm-creation-dialog.component";
import * as i13 from "../../../models/loa-request-dialog-options";
import * as i14 from "../../../../../common/models/dialog-options";
import * as i15 from "../../../../../common/services/modal/modal.service";
var styles_LMCreationDialogComponent = [i0.styles];
var RenderType_LMCreationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LMCreationDialogComponent, data: {} });
export { RenderType_LMCreationDialogComponent as RenderType_LMCreationDialogComponent };
export function View_LMCreationDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { containerElem: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["container", 1]], null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "slx-lm-creation-container", [], null, [[null, "action"], [null, "requestChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("action" === en)) {
        var pd_0 = (_co.onAction($event) !== false);
        ad = (pd_0 && ad);
    } if (("requestChange" === en)) {
        var pd_1 = (_co.onRequestChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_LMCreationContainerComponent_0, i2.RenderType_LMCreationContainerComponent)), i1.ɵprd(512, null, i3.LMRecurrenceService, i3.LMRecurrenceService, []), i1.ɵprd(512, null, i4.LMRequestMapperService, i4.LMRequestMapperService, []), i1.ɵprd(512, null, i5.LMCreationAbsenceManagementService, i5.LMCreationAbsenceManagementService, [i6.LMManagementService, i3.LMRecurrenceService, i4.LMRequestMapperService, i7.LookupService, i8.LMApiService, i9.FileService]), i1.ɵdid(6, 245760, null, 0, i10.LMCreationContainerComponent, [i5.LMCreationAbsenceManagementService, i11.NotificationsService], { options: [0, "options"] }, { action: "action", requestChange: "requestChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogOptions; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_LMCreationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-lm-creation-dialog", [], null, null, null, View_LMCreationDialogComponent_0, RenderType_LMCreationDialogComponent)), i1.ɵdid(1, 4243456, null, 0, i12.LMCreationDialogComponent, [i13.LoaRequestDialogOptions, i14.DialogOptions2, i15.ModalService], null, null)], null, null); }
var LMCreationDialogComponentNgFactory = i1.ɵccf("slx-lm-creation-dialog", i12.LMCreationDialogComponent, View_LMCreationDialogComponent_Host_0, {}, {}, []);
export { LMCreationDialogComponentNgFactory as LMCreationDialogComponentNgFactory };
