import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider } from '@angular/core';
import { EndingBalanceDialogOptions } from '../../../../models/employee-sections-accruals/ending-balance';
import { DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { AppSettingsManageService } from './../../../../../../app-settings/services/index';
var EndingBalanceDialogComponent = /** @class */ (function () {
    function EndingBalanceDialogComponent(employeeAccruals, options, modalService, appSettingsManageService) {
        this.appSettingsManageService = appSettingsManageService;
        this.employeeAccruals = employeeAccruals;
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
    }
    EndingBalanceDialogComponent.openDialog = function (data, modalService) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 400;
        dialogOptions.width = 400;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EndingBalanceDialogOptions,
                useValue: data
            }
        ];
        var dialog = modalService.globalAnchor.openDialog(EndingBalanceDialogComponent, 'Ending Balance', dialogOptions, resolvedProviders);
        return dialog;
    };
    EndingBalanceDialogComponent.prototype.ngOnInit = function () {
        this.getSettings();
    };
    EndingBalanceDialogComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.EnableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(EndingBalanceDialogComponent.prototype, "isShowHighPrecision", {
        get: function () {
            return this.EnableRate4DecimalPlaces;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EndingBalanceDialogComponent.prototype, "hasAccruals", {
        get: function () {
            return _.isArray(this.employeeAccruals.accruals) && _.size(this.employeeAccruals.accruals) > 0;
        },
        enumerable: true,
        configurable: true
    });
    EndingBalanceDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return EndingBalanceDialogComponent;
}());
export { EndingBalanceDialogComponent };
