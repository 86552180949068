import { Component, OnInit, Input, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import 'moment-range';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, GroupDescriptor, GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { EmployeeDefinition } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { ConfirmDialog2Component, ConfirmOptions, DialogOptions } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ScheduleEntryApiService, VacationPlannerApiService } from '../../../services/index';
import {
  VacationPlan,
  VacationPlanEmployeeRecord,
  VacationPlanWeekGroup,
  VacationPlanSettings,
  IVacationPlanColumn,
  VacationPlanEmployeeWeek,
  VacationPlanEmployeeRecordDetails
} from '../../../models/index';
import { VacationEmployeeDialogComponent } from '../vacation-employee-dialog/vacation-employee-dialog.component';
import { ORG_LEVEL_ID_TOKEN } from '../../../../core/models/index';
import { AppServerConfig } from '../../../../../app/app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app/app-settings/services';

@Component({
  moduleId: module.id,
  selector: 'slx-vacation-grid',
  templateUrl: 'vacation-grid.component.html',
  styleUrls: ['vacation-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VacationGridComponent implements OnInit {
  public isModifyPayPeriodApproved: boolean;
  public approvedPayPeriod: boolean;
  @Input()
  public set vacationPlannerData(vacationPlannerData: VacationPlan) {
    this.gridData = vacationPlannerData;
    if (this.gridData) {
      this.refreshGrid();
    }
  }

  @Input()
  public set settings(settings: VacationPlanSettings) {
    if (!settings) {
      return;
    }
    this.planSettings = settings;
    this.columnsSettings = _.keyBy(settings.columns, (column: IVacationPlanColumn) => {
      return column.name;
    });
    this.columnsSortSettings = _.keyBy(settings.columns, (column: IVacationPlanColumn) => {
      return column.sortField;
    });
  }
  @Input()
  public orgLevel: OrgLevel;

  @Input()
  public set redrawToggler(value: boolean) {
    this.refreshGridSort();
  }

  public appConfig: IApplicationConfig;
  public columnsSettings: StringMap<IVacationPlanColumn>;
  public columnsSortSettings: StringMap<IVacationPlanColumn>;
  public filterValues: {
    names: string[];
  };
  public gridView: GridDataResult;
  public gridState: State;
  public gridData: VacationPlan;
  private modalService: ModalService;
  private vacationPlannerApiService: VacationPlannerApiService;

  private weekGroups: StringMap<VacationPlanWeekGroup>;
  private planSettings: VacationPlanSettings;

  constructor(private changeDetectorRef: ChangeDetectorRef,
    modalService: ModalService,
    private ScheduleEntryApiService: ScheduleEntryApiService,
    private appSettingsManageService: AppSettingsManageService,
    vacationPlannerApiService: VacationPlannerApiService) {
    this.vacationPlannerApiService = vacationPlannerApiService;
    this.modalService = modalService;
    this.gridState = {
      skip: undefined,
      take: undefined,
      filter: undefined,
      sort: [{ field: 'employee.name', dir: 'asc' }],
      group: undefined
    };
    this.columnsSettings = {};
    this.filterValues = {
      names: [],
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.getSettings();
  }

  public isActive(record: VacationPlanEmployeeRecord, week: number): boolean {
    return record.weeks[week].isActive;
  }

  public onDetailClick(record: VacationPlanEmployeeRecord, week: number): void {
    if (!this.isActive(record, week)) {
      return;
    }
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 670;
    dialogOptions.width = 500;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }, {
        provide: EmployeeDefinition,
        useValue: record.employee
      },
      {
        provide: VacationPlanEmployeeWeek,
        useValue: record.weeks[week]
      },
      {
        provide: ORG_LEVEL_ID_TOKEN,
        useValue: this.orgLevel.id
      }
    ];
    let dialog: VacationEmployeeDialogComponent = this.modalService.globalAnchor
      .openDialog(VacationEmployeeDialogComponent, 'PTO Planner', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        if (result) {
          const changedRecords = this.getChangedRecords(dialog.originalRecords, dialog.employeeDetails.records);
          let endDate = moment(record.weeks[week].start).add(6, 'day');
          let finalDate = moment(endDate).format('MM/DD/YYYY');
          let startDate = moment(record.weeks[week].start).format('MM/DD/YYYY');
          this.ScheduleEntryApiService.checkApprovedPayperiod(String(dialog.employee.id), startDate, finalDate).then((isApproved: any) => {
            this.approvedPayPeriod = isApproved;
            if (changedRecords.length > 0 && this.isModifyPayPeriodApproved && this.approvedPayPeriod == true) {
              let userPermission = this.gridData.actions.find(x=> x=='Edit Schedule for an Approved Pay Period')
              const message = this.gridData.actions.find(x=> x=='Edit Schedule for an Approved Pay Period') ?
                `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the 
                employee, are you sure you want to continue?`: `You do not have permissions to modify a 
                schedule in an approved pay period`;
              let popupOptions: ConfirmOptions = new ConfirmOptions();
              popupOptions.showCancel = true;
              popupOptions.showOK = userPermission ? true : false;
              popupOptions.buttonOKtext = 'Ok';
              popupOptions.buttonCanceltext = userPermission ? 'Cancel' : 'Ok';
              ConfirmDialog2Component.openDialog(
                'Warning',
                message,
                this.modalService,
                (result: boolean) => {
                  if (result) {
                    this.saveVacationPlanEmployeeDetails(dialog.employee.id, changedRecords, dialog.employeeDetails.records, record.weeks, week);
                  }
                }, popupOptions)
            }
            else {
              this.saveVacationPlanEmployeeDetails(dialog.employee.id, changedRecords, dialog.employeeDetails.records, record.weeks, week);
            }
          });
        }
      })
  }

  public saveVacationPlanEmployeeDetails(empId: number, changedRecords: any[], employeeDetails: any[], record: NumberMap<VacationPlanEmployeeWeek>, week: number) {
    this.vacationPlannerApiService.saveVacationPlanEmployeeDetails(empId, changedRecords)
      .then((success: boolean) => {
        //TBD can be changed to return number
        if (success) {
          let vacations: VacationPlanEmployeeRecordDetails[] = _.filter(employeeDetails, (r: VacationPlanEmployeeRecordDetails) => {
            return !!r.scheduleAbsence;
          });
          record[week].days = vacations.length;
          this.refreshGrid();
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.refreshGrid();
  }

  public get canBeLocked(): boolean {
    return !!this.gridData && !!this.gridData.weeks;
  }

  private refreshGridSort(): void {
    if (!this.gridState.sort || !this.columnsSortSettings) return;
    this.gridState.sort = _.filter(this.gridState.sort, (sd: SortDescriptor) => {
      return !this.columnsSortSettings[sd.field] || this.columnsSortSettings[sd.field].displayed;
    });
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.gridData) {
      this.gridView = null;
      return;
    }
    this.weekGroups = {};
    this.gridView = process(this.gridData.records, this.gridState);
  }

  private getChangedRecords(originalRecords: VacationPlanEmployeeRecordDetails[], dialogRecords: VacationPlanEmployeeRecordDetails[]): VacationPlanEmployeeRecordDetails[] {
    let changedRecords = _.differenceWith(dialogRecords, originalRecords, _.isEqual);
    return changedRecords;
  }
  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
  }

}
