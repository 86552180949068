export interface IPunchAttestationQuestion {
  id: number;
  order: number;
  textLine1: string;
  textLine2:string;
  defaultAnswer: any;
  punchType : number;
  //isConditional: boolean;
  attestationTypeId: any;
  restrictedAnswerId: any;
  enable: boolean;
}

export class PunchAttestationQuestion {
  public id = 0;
  public order: number;
  public textLine1: string;
  public textLine2: string;
  public defaultAnswer: number;
  public punchType : number;
  // public isConditional: boolean;
  public attestationTypeId: number;
  public restrictedAnswerId: number;
  public enable: boolean;
}


