import * as moment from 'moment';

import { IEmployeeDefinition, EmployeeDefinition, IPosition, Position, ScheduleEntryDefinition, IScheduleEntryDefinition } from '../../../organization/models/index';
import { LinePunch, ILinePunch } from '../punches/index';

export interface IArrivalsDeparturesDetailsRecord {
  workDate: string;
  employee: IEmployeeDefinition;
  position: IPosition;
  entry: IScheduleEntryDefinition;
  punches: ILinePunch[];
  scheduledHours: number;
  actualHours: number;
  hasMissingPunches: boolean;
  isLate: boolean;
  isApproachingOT: boolean;
}

export class ArrivalsDeparturesDetailsRecord {
  public workDate: Date;
  public employee: EmployeeDefinition;
  public position: Position;
  public entry: ScheduleEntryDefinition;
  public punches: LinePunch[];
  public scheduledHours: number;
  public actualHours: number;
  public hasMissingPunches: boolean;
  public isLate: boolean;

  public isApproachingOT: boolean;
  public isArrival: boolean;
  public isDeparture: boolean;

  //filter properties
  public inTime: moment.Moment;
  public outTime: moment.Moment;
  public lunchInTime: moment.Moment;
  public lunchOutTime: moment.Moment;
  public firstShiftStart: moment.Moment;
  public lastShiftEnd: moment.Moment;
  public unitsIds: number[];

  public inPunchMissing: boolean;
  public outPunchMissing: boolean;
  public lunchInPunchMissing: boolean;
  public lunchOutPunchMissing: boolean;

  //display properties
  public inTimeStr: string;
  public outTimeStr: string;
  public lunchInTimeStr: string;
  public lunchOutTimeStr: string;
  public positionStr: string;
  public shiftsStr: string;
  public unitsStr: string;
}
