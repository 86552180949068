import * as _ from 'lodash';
export interface IBenefitPayrollDeductionDates {
    benefitDetailsLineStartDate: Date;
    benefitDetailsLineEndDate: Date;
    payrollDeductionStartDate: Date;
    payrollDeductionEndDate: Date;
}

export class BenefitPayrollDeductionDates implements IBenefitPayrollDeductionDates {
    public benefitDetailsLineStartDate: Date;
    public benefitDetailsLineEndDate: Date;
    public payrollDeductionStartDate: Date;
    public payrollDeductionEndDate: Date;
}
