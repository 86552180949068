import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
var DropdownListComponent = /** @class */ (function () {
    function DropdownListComponent() {
        this.listHeight = 200;
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.valueField = 'id';
        this.titleField = 'name';
        this.items = [];
        this.disableEmittingIfReadonly = false;
        this.open = new EventEmitter();
        this.close = new EventEmitter();
    }
    Object.defineProperty(DropdownListComponent.prototype, "options", {
        set: function (items) {
            this.items = items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownListComponent.prototype, "selectedItem", {
        get: function () {
            return this.m_selectedValue;
        },
        set: function (value) {
            if (value !== this.m_selectedValue) {
                this.m_selectedValue = value;
                if (!this.disableEmittingIfReadonly || this.disableEmittingIfReadonly && !this.readonly) {
                    this.onChangeCallback(this.m_selectedValue);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownListComponent.prototype, "hasValueIcon", {
        get: function () {
            return !_.isEmpty(this.valueIconClass);
        },
        enumerable: true,
        configurable: true
    });
    DropdownListComponent.prototype.onOpen = function () {
        this.onTouchedCallback();
        this.open.emit(null);
    };
    DropdownListComponent.prototype.onClose = function () {
        this.close.emit(null);
    };
    DropdownListComponent.prototype.onBlur = function () {
        this.onTouchedCallback();
    };
    DropdownListComponent.prototype.isPlaceholder = function () {
        return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
    };
    DropdownListComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.selectedItem = value;
        }
        else {
            this.selectedItem = this.defaultItem;
        }
    };
    DropdownListComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    DropdownListComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DropdownListComponent.prototype.toggleDropdown = function () {
        this.dropdownlist.toggle();
    };
    return DropdownListComponent;
}());
export { DropdownListComponent };
