import { Injectable } from "@angular/core";
import { ManagementBaseService } from '../../../core/services/index';
import { NavigationMenuItem } from "../../models";

@Injectable()
export class MessageCenterConfigService extends ManagementBaseService<NavigationMenuItem[], any> {
    private messageCenterUrl:string;

    constructor(){
        super();
    }
    public switchMessageCenterUrl(isMessageCenterCombined:boolean){
        if (isMessageCenterCombined == true){
          this.messageCenterUrl = "/apps/common/message_center";
        }
        else if (isMessageCenterCombined == false){
          this.messageCenterUrl = "/apps/common/message_center_sms/sms";
        }
      }
      public GetMessageCenterUrl():string{
        return this.messageCenterUrl; 
      }
}