import * as _ from 'lodash';
import { ChangeDetectorRef } from '@angular/core';
import { StaffingPredictorOrganizationInfo } from '../../models/index';
var StaffingPredictorRatingPredictorComponent = /** @class */ (function () {
    function StaffingPredictorRatingPredictorComponent(changeDetector) {
        this.changeDetector = changeDetector;
    }
    Object.defineProperty(StaffingPredictorRatingPredictorComponent.prototype, "model", {
        get: function () {
            return this.m_model;
        },
        set: function (value) {
            this.m_model = value;
            if (this.m_model) {
                this.selectedRating = _.clamp(this.m_model.organization.rating.value, 0, this.m_model.organization.rating.maxValue);
            }
            else {
                this.selectedRating = 0;
            }
            this.updatePossibleOptions();
        },
        enumerable: true,
        configurable: true
    });
    StaffingPredictorRatingPredictorComponent.prototype.onPredictedRatingChange = function (value) {
        this.selectedRating = value;
        this.updatePossibleOptions();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    StaffingPredictorRatingPredictorComponent.prototype.updatePossibleOptions = function () {
        var _this = this;
        this.possibleOptions = _.filter(this.m_model.optionsTable.options, function (o) {
            return o.rating.value === _this.selectedRating;
        });
    };
    return StaffingPredictorRatingPredictorComponent;
}());
export { StaffingPredictorRatingPredictorComponent };
