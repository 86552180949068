import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevel } from '../../state-model/models/index';

export function subscribeToOrgLevel(orgLevel$: Observable<OrgLevel>, getCurrentOrglevel: () => OrgLevel, callback: (o: OrgLevel) => void | Promise<void>): Subscription {
    return orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe(async (orgLevel: OrgLevel) => {
        const currentOrglevel = getCurrentOrglevel();
        if (_.isFinite(_.get(currentOrglevel, 'id')) && currentOrglevel.id === orgLevel.id) return;
        callback(orgLevel);
      });
  }
