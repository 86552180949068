import { Component, OnInit, OnDestroy, Input, NgZone, ViewChild, Host } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { EmployeeSectionsEmergencyContacts, EmployeeSectionsTemporalModel, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-emergency-contacts',
  templateUrl: 'employee-sections-emergency-contacts.component.html',
  styleUrls: ['employee-sections-emergency-contacts.component.scss']
})
export class EmployeeSectionsEmergencyContactsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input() public employeeSectionsEmergencyContacts: EmployeeSectionsEmergencyContacts;
  @Input() public employeeId: number;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form', {static: false})
  public ngForm: NgForm;

  private employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService;

  constructor(employeeSectionsPersonalApiService: EmployeeSectionsPersonalApiService, @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone) {
    super(decorator, ngZone);
    this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsEmergencyContacts;
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsPersonalApiService.getPersonalEmergencyContacts(this.employeeId)
      .then((employeeSectionsEmergencyContacts: EmployeeSectionsEmergencyContacts) => {
        this.employeeSectionsEmergencyContacts = employeeSectionsEmergencyContacts;
        this.stopProgress();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  protected doSave(): void {
    this.employeeSectionsPersonalApiService.setPersonalEmergencyContacts(this.employeeId, this.employeeSectionsEmergencyContacts)
      .then((status: any) => {
        this.onActionComplete(true);
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsEmergencyContacts);
  }
}
