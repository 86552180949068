import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { LMCommonTab } from '../../lm-common-tab';
var LMContinuousTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LMContinuousTabComponent, _super);
    function LMContinuousTabComponent(manService) {
        var _this = _super.call(this, manService) || this;
        _this.manService = manService;
        _this.appConfig = appConfig;
        return _this;
    }
    LMContinuousTabComponent.prototype.ngOnInit = function () {
        _super.prototype.init.call(this);
        this.validateDates();
    };
    LMContinuousTabComponent.prototype.onChangeEstStartDate = function (date) {
        _super.prototype.onChangeEstStartDate.call(this, date);
        this.validateDates();
    };
    LMContinuousTabComponent.prototype.onChangeEstEndDate = function (date) {
        _super.prototype.onChangeEstEndDate.call(this, date);
        this.validateDates();
    };
    LMContinuousTabComponent.prototype.onChangeEstAllDay = function () {
        _super.prototype.onChangeEstAllDay.call(this);
        this.validateDates();
    };
    LMContinuousTabComponent.prototype.onChangeActStartDate = function (date) {
        _super.prototype.onChangeActStartDate.call(this, date);
        this.validateDates();
    };
    LMContinuousTabComponent.prototype.onChangeActEndDate = function (date) {
        _super.prototype.onChangeActEndDate.call(this, date);
        this.validateDates();
    };
    LMContinuousTabComponent.prototype.onChangeActAllDay = function () {
        _super.prototype.onChangeActAllDay.call(this);
        this.validateDates();
    };
    LMContinuousTabComponent.prototype.validateDates = function () {
        var loadDates = this.manService.getLoaDates();
        if (_.isDate(loadDates.startDate) && _.isDate(loadDates.endDate)) {
            this.hasIncorrectContinuousDates = !this.manService.hasCorrectContinuousDates;
        }
    };
    return LMContinuousTabComponent;
}(LMCommonTab));
export { LMContinuousTabComponent };
