/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-overlap-warning-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shift-overlap-warning-message.component";
import * as i4 from "../../models/dialog-options";
import * as i5 from "../../services/modal/modal.service";
import * as i6 from "../../../scheduler/models/schedule-rotation/schedule-rotation-template.model";
var styles_ShiftOverlapWarningMessageComponent = [i0.styles];
var RenderType_ShiftOverlapWarningMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftOverlapWarningMessageComponent, data: {} });
export { RenderType_ShiftOverlapWarningMessageComponent as RenderType_ShiftOverlapWarningMessageComponent };
function View_ShiftOverlapWarningMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
function View_ShiftOverlapWarningMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-plus-circle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-minus-square"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_ShiftOverlapWarningMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "slx-shift-warning-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "warning-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftOverlapWarningMessageComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftOverlapWarningMessageComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.options.message === "Overlapping Shift"); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.options.message === "Overtime"); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.options == null) ? null : _co.options.message); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_ShiftOverlapWarningMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-shift-overlap-warning-message", [], null, null, null, View_ShiftOverlapWarningMessageComponent_0, RenderType_ShiftOverlapWarningMessageComponent)), i1.ɵdid(1, 114688, null, 0, i3.ShiftOverlapWarningMessageComponent, [i4.DialogOptions, i5.ModalService, i6.OverlapOverTimeMessage], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftOverlapWarningMessageComponentNgFactory = i1.ɵccf("slx-shift-overlap-warning-message", i3.ShiftOverlapWarningMessageComponent, View_ShiftOverlapWarningMessageComponent_Host_0, {}, {}, []);
export { ShiftOverlapWarningMessageComponentNgFactory as ShiftOverlapWarningMessageComponentNgFactory };
