import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { CensusEntry, ICensusEntry, Census, ICensus, IAcuityConfiguration, AcuityConfiguration, IAcuity, Acuity } from '../../models/index';
import { CensusCategoryConfig, ICensusCategoryConfig, ICensusCategoryConfigRecord, CensusCategoryConfigRecord } from '../../models/census/census-category';

@Injectable()
export class CensusEntryMapService {
  public mapToAcuityConfiguration(data: IAcuityConfiguration): AcuityConfiguration {
    let ac: AcuityConfiguration = new AcuityConfiguration();
    ac.budgetedCensus = data.budgetedCensus;
    ac.capacity = data.capacity;
    ac.date = new Date(data.date);
    ac.items = _.map(data.items, (item: IAcuity) => this.mapToAcuity(item));
    ac.canEditCensus = data.canEditCensus;
    return ac;
  }

  public mapToAcuity(data: IAcuity): Acuity {
    let a: Acuity = new Acuity();
    a.type = data.type;
    a.entries = _.map(data.entries, (item: ICensusEntry) => this.mapToCensusEntry(item));
    a.recalcCapacity();
    return a;
  }

  public mapToCensusEntry(data: ICensusEntry): CensusEntry {
    let censusEntry: CensusEntry = new CensusEntry();
    censusEntry.category = data.category;
    censusEntry.value = data.value;
    return censusEntry;
  }
  
  public mapToCensusConfig(data: ICensusCategoryConfig): CensusCategoryConfig {
    let config: CensusCategoryConfig = new CensusCategoryConfig();
    config.orgLevelId = data.orgLevelId;
    config.allowToExcludeFromTotals = data.allowToExcludeFromTotals;
    config.records = _.map(data.records, (item: ICensusCategoryConfigRecord) => this.mapToCensusConfigRecord(item));
    return config;
  }

  public mapToCensusConfigRecord(data: ICensusCategoryConfigRecord): CensusCategoryConfigRecord {
    let record: CensusCategoryConfigRecord = new CensusCategoryConfigRecord();
    record.name = data.name;
    record.unit = data.unit;
    record.value = data.value;
    record.sortId = data.sortId;
    record.includedInTotal = data.includedInTotal;
    return record;
  }
}
