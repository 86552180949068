import {
  IApplicationRecord,
  IApplication
} from './application.types';
import { makeTypedFactory } from 'typed-immutable-record';

export const applicationFactory: (val?: IApplication) => IApplicationRecord = makeTypedFactory<IApplication, IApplicationRecord>({
  name: null,
  title: null,
  icon: null,
  id: null,
  link: null,
  loadCounters: false
});

export const INITIAL_APPLICATION_STATE: IApplicationRecord = applicationFactory();
