export * from './itemEditor/pbj-organization-editor.component';
export * from './configure-pbj-organizations.component';
export * from './mobileRenderer/pbj-organization-renderer.component';

import { PbjOrganizationEditorComponent } from './itemEditor/pbj-organization-editor.component';
import { ConfigurePbjOrganizationsComponent } from './configure-pbj-organizations.component';
import { PbjOrganizationRendererComponent } from './mobileRenderer/pbj-organization-renderer.component';

export const pbjOrganizationsComponents: any[] = [
    PbjOrganizationRendererComponent, PbjOrganizationEditorComponent, ConfigurePbjOrganizationsComponent
];
