import { IOrgLevelDto } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';

export interface IUserRecordDTO {
    id: number;
    name: string;
    login: string;
    email: string;
    roleId: number;
    organizationLevels: IOrgLevelDto[];
}

export class UserRecord {
    public id: number;
    public name: string;
    public login: string;
    public email: string;
    public roleId: number;
    public organizationLevels: OrgLevel[];
}

