/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-sections-attendance-day.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./employee-sections-attendance-day.component";
var styles_EmployeeSectionsAttendanceDayComponent = [i0.styles];
var RenderType_EmployeeSectionsAttendanceDayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeSectionsAttendanceDayComponent, data: {} });
export { RenderType_EmployeeSectionsAttendanceDayComponent as RenderType_EmployeeSectionsAttendanceDayComponent };
function View_EmployeeSectionsAttendanceDayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check-circle work-span"]], null, null, null, null, null))], null, null); }
export function View_EmployeeSectionsAttendanceDayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeSectionsAttendanceDayComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "code-span"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cellRenderer; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cellColor; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isWorkday; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.cellPlannerIndicator; _ck(_v, 8, 0, currVal_3); }); }
export function View_EmployeeSectionsAttendanceDayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-sections-attendance-day", [], null, null, null, View_EmployeeSectionsAttendanceDayComponent_0, RenderType_EmployeeSectionsAttendanceDayComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmployeeSectionsAttendanceDayComponent, [], null, null)], null, null); }
var EmployeeSectionsAttendanceDayComponentNgFactory = i1.ɵccf("slx-employee-sections-attendance-day", i3.EmployeeSectionsAttendanceDayComponent, View_EmployeeSectionsAttendanceDayComponent_Host_0, { attendanceSection: "attendanceSection", selectedYear: "selectedYear", month: "month", day: "day", prefix: "prefix" }, {}, []);
export { EmployeeSectionsAttendanceDayComponentNgFactory as EmployeeSectionsAttendanceDayComponentNgFactory };
