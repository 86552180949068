import * as _ from 'lodash';
import { Directive, Host, OnChanges, SimpleChanges, Input, HostListener } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Lookup } from '../../../organization/models/index';

@Directive({
  selector: '[lookup-add-item][lookup]'
})
export class LookupAddItemDirective implements OnChanges {
  @Input()
  public ngModel: any;
  @Input()
  public lookup: Lookup;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['ngModel'] || changes['lookup']) {
      if (this.ngModel && this.lookup && this.lookup.items && !this.checkItemPresent(this.ngModel, this.lookup.items, this.lookup.valueField)) {
        this.lookup.items.push(this.ngModel);
      }
    }
  }

  @HostListener('lookupApplied', ['$event'])
  public onLookupApplied(lookup: Lookup): void {
    if (lookup && lookup.items) {
      if (this.ngModel && !this.checkItemPresent(this.ngModel, lookup.items, lookup.valueField)) {
        lookup.items.push(this.ngModel);
      }
    }
  }


  private checkItemPresent(value: any, source: any[], idField: string): boolean {
    if (this.lookup) {
      let idFiledValue = value ? value[idField] : null;
      let matches = _.find(source, (item: any): boolean => {
        return item[idField] === idFiledValue;
      });
      return matches !== undefined;
    }
    return false;
  }
}
