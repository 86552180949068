import { DialogOptions2 } from './../../../../common/models/dialog-options';
import { IdealScheduleOverviewRequest } from './../../../models/ideal-schedule/ideal-schedule-overview-request';
import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { DialogOptions } from '../../../../common/models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-ideal-schedule-overview-dialog',
    templateUrl: 'ideal-schedule-overview-dialog.component.html',
    styleUrls: ['ideal-schedule-overview-dialog.component.scss']
})
export class IdealScheduleOverviewDialogComponent {

    public options: DialogOptions2;
    public dialogResult: boolean;

    public static openDialog(
        modalService: ModalService,
        callback: (result: boolean) => void,
        overviewRequest: IdealScheduleOverviewRequest): IdealScheduleOverviewDialogComponent {
        let dialogOptions: DialogOptions2 = new DialogOptions2();
        dialogOptions.width = 800;
        dialogOptions.height = 600;
        // dialogOptions.showCloseButton = true;
        let resolvedProviders: Provider[] = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: IdealScheduleOverviewRequest,
                useValue: overviewRequest
            }
        ];
        let dialog: IdealScheduleOverviewDialogComponent = modalService.globalAnchor
            .openDialog2(IdealScheduleOverviewDialogComponent, 'Overview', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
                callback(result);
            });
        return dialog;
    }

    constructor(
        options: DialogOptions2,
        public overviewRequest: IdealScheduleOverviewRequest,
        private modalService: ModalService
    ) {
        this.options = options;
    }


    public onOk(): void {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }

    public onCancel(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }

}
