import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';

import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import {
  TimeclockAssignmentContainer, TimeclockAssignmentEmployee, TimeclockAssignmentState, TimeclockRestrictionTotal, TimeclockAssignment, AssignMode
} from '../../../models/index';
import { TimeclockRestrictionDefinition, EmployeeBadge, EmployeeDefinition, TimeclockDefinition } from '../../../../organization/models/index';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { TimeclockAssignmentManagementService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { TimeclockAssignmentDialogComponent } from '../timeclock-assignment-dialog/timeclock-assignment-dialog.component';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-assignment-header',
  templateUrl: 'timeclock-assignment-header.component.html',
  styleUrls: ['timeclock-assignment-header.component.scss']
})
export class TimeclockAssignmentHeaderComponent implements OnDestroy {

  public appConfig: IApplicationConfig;
  public state: TimeclockAssignmentState;
  public hasSelectedWithoutBudges: boolean;
  public hasSelected: boolean;
  @unsubscribe()
  private stateChangedSubscription: Subscription;
  @unsubscribe()
  private selectionChangedSubscription: Subscription;
  private selectedRecords: TimeclockAssignmentEmployee[];

  private timeclockAssignmentManagementService: TimeclockAssignmentManagementService;
  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  private modalService: ModalService;

  constructor(timeclockAssignmentManagementService: TimeclockAssignmentManagementService, employeeDefinitionsApiService: EmployeeDefinitionsApiService, modalService: ModalService) {
    this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.modalService = modalService;
    this.appConfig = appConfig;
    this.state = new TimeclockAssignmentState();
    this.stateChangedSubscription = this.timeclockAssignmentManagementService.onStateChanged$.subscribe(
      (state: TimeclockAssignmentState) => {
        this.state = state;
        this.hasSelectedWithoutBudges = false;
        this.hasSelected = false;
      });

    this.selectionChangedSubscription = this.timeclockAssignmentManagementService.onSelectionChanged$.subscribe(
      (records: TimeclockAssignmentEmployee[]) => {
        this.selectedRecords = records;
        this.hasSelected = (records && records.length > 0);
        if (!this.hasSelected) {
          this.hasSelectedWithoutBudges = false;
          return;
        }
        this.hasSelectedWithoutBudges = !!_.find(records, (record: TimeclockAssignmentEmployee) => {
          return !record.badge;
        });
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onUnassigned(): void {
    this.state.showAssigned = false;
    this.state.showUnassigned = true;
    this.timeclockAssignmentManagementService.onStateChanged(true);
  }

  public onAssigned(): void {
    this.state.showAssigned = true;
    this.state.showUnassigned = false;
    if (this.state.showRestrictions.length === 0) {
      let restTotal: TimeclockRestrictionTotal = this.state.totals[0];
      restTotal.isChecked = true;
      this.state.showRestrictions.push(restTotal.restriction);
    }
    this.timeclockAssignmentManagementService.onStateChanged(true);
  }

  public toggleRestriction(restTotal: TimeclockRestrictionTotal): void {
    restTotal.isChecked = !restTotal.isChecked;
    let selected: TimeclockRestrictionTotal[] = _.filter(this.state.totals, (total: TimeclockRestrictionTotal) => {
      return total.isChecked;
    });
    this.state.showRestrictions = _.map(selected, (total: TimeclockRestrictionTotal) => {
      return total.restriction;
    });
    this.timeclockAssignmentManagementService.onStateChanged(true);
  }

  public onGenerateBadge(): void {
    this.timeclockAssignmentManagementService.onLoadStatusChanged(true);
    let ids: number[] = _.map(this.selectedRecords, (record: TimeclockAssignmentEmployee) => {
      return record.employee.id;
    });
    this.employeeDefinitionsApiService.generateBadges(ids)
      .then((badges: EmployeeBadge[]) => {
        _.forEach(badges, (badge: EmployeeBadge) => {
          let emp: TimeclockAssignmentEmployee = _.find(this.state.records, (employee: TimeclockAssignmentEmployee) => {
            return employee.employee.id === badge.employeeId;
          });
          if (emp) {
            emp.badge = badge.badge;
          }
        });
        this.timeclockAssignmentManagementService.onLoadStatusChanged(false);
        this.timeclockAssignmentManagementService.onStateChanged(false);
      });
  }

  public onAssign(): void {
    this.state.selectedEmployees = this.getSelectedEmployees();
    TimeclockAssignmentDialogComponent.openDialog('Assign Employees to Timeclocks', this.state, AssignMode.assign, this.modalService, (result: boolean, assignment: TimeclockAssignment[]) => {
      if (result) {
        this.timeclockAssignmentManagementService.onAssign(assignment);
      }
    });
  }

  public onReAssign(): void {
    this.state.selectedEmployees = this.getSelectedEmployees();
    TimeclockAssignmentDialogComponent.openDialog('Re-Assign Employees to Timeclocks', this.state, AssignMode.reassign, this.modalService, (result: boolean, assignment: TimeclockAssignment[]) => {
      if (result) {
       this.timeclockAssignmentManagementService.onReAssign(assignment);
      }
    });
  }

  public onChangeRestriction(): void {
    this.state.selectedEmployees = this.getSelectedEmployees();
    TimeclockAssignmentDialogComponent.openDialog('Change Restriction Employees to Timeclocks', this.state, AssignMode.changeRestriction, this.modalService, (result: boolean, assignment: TimeclockAssignment[]) => {
      if (result) {
        this.timeclockAssignmentManagementService.onAssign(assignment);
      }
    });
  }
  public onUnAssign(): void {
    this.state.selectedEmployees = this.getSelectedEmployees();
    TimeclockAssignmentDialogComponent.openDialog('Unassign Employees from Timeclocks', this.state, AssignMode.unassign, this.modalService, (result: boolean, assignment: TimeclockAssignment[]) => {
      if (result) {
        this.timeclockAssignmentManagementService.onUnAssign(assignment);
      }
    });
  }

  private getSelectedEmployees(): EmployeeDefinition[] {
    return _.map(this.selectedRecords, (emp: TimeclockAssignmentEmployee) => emp.employee);
  }
}

