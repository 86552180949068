<div class="modal-body-grid">
    <slx-bipa-report [configData]="reportDefinition" (hasValueChange)="onValueChanged($event)" [forceLoad]="forceLoad">
    </slx-bipa-report>
</div>
<div class="modal-footer wrapper">
    <div class="btn red pad-top-10" (click)="onCancel()"><i class="fas fa-times slx-button__icon"
            aria-hidden="true"></i>Cancel
    </div>
    <div class="btn green pad-top-10" (click)="onSave()" [ngClass]="{ 'disabled-content': !hasValueChange }"><i
            class="fas fa-save slx-button__icon" aria-hidden="true"></i>Save</div>
</div>