<slx-spinner [show]="manService.isLoading">
    <div class="slx-upload-status-container">
        <div class="slx-upload-status-table">
            <kendo-grid [ngStyle]="{'height.px': gridHeight}" class="slx-blue-grid slx-full-height no-border"
                [data]="manService.gridState.view" [sortable]="{ mode: 'multiple' }" [sort]="manService.sort"
                (sortChange)="manService.sortChangeHandler($event)" [pageable]="true" [pageSize]="manService.pageSize"
                [skip]="manService.gridState.state.skip" (pageChange)="manService.pageChangeHandler($event)"
                [filterable]="'menu'" [filter]="manService.gridState.state.filter"
                (filterChange)="manService.filterChangeHandler($event)"
                (dataStateChange)="manService.gridState.dataStateChange($event)"
                (stateRestored)="manService.gridState.dataStateChange($event)">
                <ng-template kendoGridNoRecordsTemplate>
                    <span *ngIf="manService.uploadedFileData.length === 0">No records available.</span>
                </ng-template>
                <kendo-grid-column title="Facility" field="facilityName" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.facilityName }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Department" field="departmentName" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.departmentName }}
                    </ng-template></kendo-grid-column>
                <kendo-grid-column title="Upload Type" field="moduleName" filter="text" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.moduleName }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Status" field="getStatus" [width]="100">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span [ngClass]="dataItem.status">
                            {{ dataItem.getStatus}}
                        </span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Imported By" field="importedBy" [width]="100">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{ dataItem.importedBy }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Imported Date/Time" field="importedDateTime" filter="date"
                    [format]="dateWithTimeFormat" [width]="140">
                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.importedDateTime | amDateFormat:
                        appConfig.militaryDateTimeFormat }}
                    </ng-template></kendo-grid-column>
                <kendo-grid-column title="Import File" field="importedFile" [width]="80">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <a [ngClass]="(dataItem.status == 'In-Progress') ? 'slx-disable-link' : 'slx-enable-link'" href="" (click)="manService.downloadImportedFile(dataItem.uniqueId)">Download <i class="fas fa-download"></i></a>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
</slx-spinner>