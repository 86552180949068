/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./actions-list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./actions-list-item.component";
import * as i4 from "ngx-popper";
var styles_ActionsListItemComponent = [i0.styles];
var RenderType_ActionsListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActionsListItemComponent, data: {} });
export { RenderType_ActionsListItemComponent as RenderType_ActionsListItemComponent };
function View_ActionsListItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ActionsListItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["type", "button"]], [[8, "disabled", 0], [8, "title", 0]], [[null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.onMouseup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsListItemComponent_2)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getClassName(); _ck(_v, 2, 0, currVal_2); var currVal_3 = i1.ɵnov(_v.parent, 4); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; var currVal_1 = _co.getTitle(); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ActionsListItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ActionsListItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[2, "disabled", null]], [[null, "mouseup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.onMouseup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsListItemComponent_4)), i1.ɵdid(4, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getClassName(); _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 4); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; _ck(_v, 0, 0, currVal_0); }); }
function View_ActionsListItemComponent_5(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ActionsListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsListItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActionsListItemComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["contentTmpl", 2]], null, 0, null, View_ActionsListItemComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.fullyCustomStyle && !_co.hidden); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.fullyCustomStyle && !_co.hidden); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ActionsListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-actions-list-item", [], null, null, null, View_ActionsListItemComponent_0, RenderType_ActionsListItemComponent)), i1.ɵdid(1, 49152, null, 0, i3.ActionsListItemComponent, [i4.PopperContent], null, null)], null, null); }
var ActionsListItemComponentNgFactory = i1.ɵccf("slx-actions-list-item", i3.ActionsListItemComponent, View_ActionsListItemComponent_Host_0, { isDisabled: "disabled", className: "className", hidden: "hidden", fullyCustomStyle: "fullyCustomStyle", title: "title" }, { onClick: "onClick" }, ["*"]);
export { ActionsListItemComponentNgFactory as ActionsListItemComponentNgFactory };
