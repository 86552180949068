import { NgForm } from '@angular/forms';
import { UserPasswordSettingsApiService } from './../../services/index';
import { OnInit } from '@angular/core';
import * as _ from 'lodash';
var UserPasswordSettingsComponent = /** @class */ (function () {
    function UserPasswordSettingsComponent(apiService) {
        this.apiService = apiService;
        //1;
    }
    UserPasswordSettingsComponent.prototype.ngOnInit = function () {
        this.loadData();
    };
    UserPasswordSettingsComponent.prototype.loadData = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.getSettings().then(function (value) {
            _this.originalSetting = value;
            _this.discardChanges();
            _this.isLoading = false;
        }).catch(function (reason) {
            _this.isLoading = false;
        });
    };
    UserPasswordSettingsComponent.prototype.saveData = function () {
        var _this = this;
        this.isLoading = true;
        return this.apiService.saveSettings(this.setting).then(function (value) {
            _this.originalSetting = _this.setting;
            _this.discardChanges();
            _this.isLoading = false;
            return true;
        }).catch(function (reason) {
            _this.isLoading = false;
            return false;
        });
    };
    UserPasswordSettingsComponent.prototype.discardChanges = function () {
        this.setting = _.cloneDeep(this.originalSetting);
    };
    Object.defineProperty(UserPasswordSettingsComponent.prototype, "hasChanges", {
        get: function () {
            return !_.isEqual(this.setting, this.originalSetting);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserPasswordSettingsComponent.prototype, "canSave", {
        get: function () {
            return this.passForm.valid && this.hasChanges;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserPasswordSettingsComponent.prototype, "lifetimeEnabled", {
        get: function () {
            if (this.setting && this.setting.userPasswordLifetime) {
                return this.setting.userPasswordLifetime.enabled;
            }
            else {
                return false;
            }
        },
        set: function (value) {
            if (this.setting && this.setting.userPasswordLifetime) {
                this.setting.userPasswordLifetime.enabled = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    return UserPasswordSettingsComponent;
}());
export { UserPasswordSettingsComponent };
