import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BenefitsEnrollmentSectionManagementService, EmployeeSectionBridgeService } from '../../../../services';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { GridComponent } from '@progress/kendo-angular-grid';
import { EmployeeSectionsBase, EnrollmentHistoryRecord, EmployeeInfo, CalculationType } from '../../../../models';
import * as _ from 'lodash';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { CalculationCommonService } from '../../../../../../app-modules/benefits/services/benefit-details/calculation-common.service';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-enrollment-history',
  templateUrl: './benefit-enrollment-history.component.html',
  styleUrls: ['./benefit-enrollment-history.component.scss'],
  providers: [CalculationCommonService]
})
export class BenefitEnrollmentHistoryComponent implements OnInit, OnDestroy {
  
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  
  public records: EnrollmentHistoryRecord[];
  public updateRecord: EnrollmentHistoryRecord;
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EnrollmentHistoryRecord>;
  public empName: string;
  public employeeId: number;
  public isLoading: boolean;
  public pageSize: number = 5;
  public calculationMethod: any;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  
  constructor(private manService: BenefitsEnrollmentSectionManagementService,private calculationCommonService: CalculationCommonService,) {
    this.gridState = new KendoGridStateHelper<EnrollmentHistoryRecord>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'startDate', dir: 'desc' }];
    this.appConfig = appConfig;
    this.calculationMethod = CalculationType;
  }

  ngOnInit() {
    this.subscriptions.enrollmentHistorySubscription = this.manService.subscribeToEnrollmentsHistoryLoad((v: EnrollmentHistoryRecord[]) => {
      this.records = v;
      this.setCostValue();
      this.refreshGrid();
    });

    this.subscriptions.employeeEnrollmentUpdateSubscription = this.manService.subscribeToEnrollmentUpdated((v: EnrollmentHistoryRecord) => {
      this.updateRecord = v;
      this.updateEmployeeRecord();
      this.setCostValue();
      this.refreshGrid();
    });

    this.subscriptions.gridEmployeeInfoSubscription = this.manService.subscribeToEmployeeInfo((v: EmployeeInfo) => {
      this.empName = v.fullName;
      this.employeeId = v.id;
    });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
  }

  public ngOnDestroy(): void {
  }

  public updateEmployeeRecord(): void {
    _.forEach(this.records, (employee : EnrollmentHistoryRecord) => {
      if(employee.calcMethod.isOptionsRates && this.updateRecord.calcMethod.isOptionsRates && employee.plan === this.updateRecord.plan){
        employee.employeeContribution = this.updateRecord.employeeContribution ;          
      }
    });
  }
  
  private setCostValue(): void {
    _.forEach(this.records, (employee : EnrollmentHistoryRecord) => {
      if(employee.calcMethod.isOptionsRates){
        let employeeContribution = 0;
        if (employee.employeeContribution > 0) {
          employeeContribution = this.calculationCommonService.getConvertedEmployeeContribution(employee.costFreq,employee.empContFreq, employee.employeeContribution);
          }
          employee.cost = employee.employerContributionAmount + employeeContribution;
      }
    });
  }
  public onPlanClick(selectedRecord): void {
    this.isLoading = true;
    this.manService.planSelected(this.employeeId, selectedRecord).then(() => {
      this.isLoading = false;
    });
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }



}
