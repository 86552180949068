import { Component } from '@angular/core';
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { dataCheck, ICostcentersyncDetails } from '../../../models/wfm-sync';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { BaseFilterSyncComponent } from '../base-filter-sync.component';

@Component({
  selector: 'slx-laborcode-migration',
  templateUrl: './laborcode-migration.component.html',
  styleUrls: ['./laborcode-migration.component.scss']
})
export class LaborcodeMigrationComponent extends BaseFilterSyncComponent<ICostcentersyncDetails> {
  constructor(
    wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(wfmSyncService, notificationService, busyService);
  }

  protected syncDataCore(selectedItems: ICostcentersyncDetails[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.LabourCodeSyncOrg(selectedItems, 'ongoing')
    );
  }

  protected getSyncItemKey(item: ICostcentersyncDetails): string {
    return '' + item.id;
  }

  protected async fetchData(): Promise<ICostcentersyncDetails[]> {
    const response = await this.busyService.busy(
      this.wfmSyncService.GetLabourCodeChangeLogs('ongoing')
    );

    return this.processData(response.data);
  }

  private processData(data: ICostcentersyncDetails[]): ICostcentersyncDetails[] {
    return dataCheck(data);
  }

  protected setGlobalFilter(): void {
    this.globalFilterFunc$.next((item) => {
      const orgId = this.selectedOrganization && this.selectedOrganization.id;
      const deptId = this.selectedDepartment && this.selectedDepartment.id;
      const posId = this.selectedPosition && this.selectedPosition.id;

      return (orgId == null || item.organizationId === orgId) &&
        (deptId == null || item.departmentId === deptId) &&
        (posId == null || item.jobCode === posId);
    })
  }
}
