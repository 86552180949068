import { ShiftSwapManageService } from './../../../services/index';
import { ShiftSwapStatus } from './../../../models/shift-swap/shift-swap-status';
import { ShiftSwapInfo } from '../../../models/shift-swap/shift-swap-info';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-shift-swap-list-item',
    templateUrl: 'shift-swap-list-item.component.html',
    styleUrls: ['shift-swap-list-item.component.scss']
})
export class ShiftSwapListItemComponent {
    @Input()
    public shiftSwap: ShiftSwapInfo;

    constructor(public manageService: ShiftSwapManageService) {
    }

    public get statusStyle(): string {
        if (!this.shiftSwap) {
            return 'status-pending';
        }
        return 'status-' + this.shiftSwap.status.toLowerCase();
    }

    public get showDenied(): boolean {
        return this.shiftSwap && this.shiftSwap.status === ShiftSwapStatus.Denied;
    }

    public get showApproved(): boolean {
        return this.shiftSwap && this.shiftSwap.status === ShiftSwapStatus.Approved;
    }

    public get expired(): boolean {
        return this.shiftSwap && this.shiftSwap.status === ShiftSwapStatus.Expired;
    }

    public seeDetailsClicked(): void {
        this.manageService.showDetails(this.shiftSwap);
    }

    public approveClicked(): void {
        this.manageService.approve(this.shiftSwap);
    }

    public denyClicked(): void {
        this.manageService.deny(this.shiftSwap);
    }

}
