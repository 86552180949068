/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rn-hrs-min-warning-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./rn-hrs-min-warning-dialog.component";
var styles_RnHrsMinWarningDIalogComponent = [i0.styles];
var RenderType_RnHrsMinWarningDIalogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RnHrsMinWarningDIalogComponent, data: {} });
export { RenderType_RnHrsMinWarningDIalogComponent as RenderType_RnHrsMinWarningDIalogComponent };
export function View_RnHrsMinWarningDIalogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please select one or more conditions"]))], null, null); }
export function View_RnHrsMinWarningDIalogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-rn-hrs-min-warning-dialog", [], null, null, null, View_RnHrsMinWarningDIalogComponent_0, RenderType_RnHrsMinWarningDIalogComponent)), i1.ɵdid(1, 114688, null, 0, i2.RnHrsMinWarningDIalogComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RnHrsMinWarningDIalogComponentNgFactory = i1.ɵccf("slx-rn-hrs-min-warning-dialog", i2.RnHrsMinWarningDIalogComponent, View_RnHrsMinWarningDIalogComponent_Host_0, {}, {}, []);
export { RnHrsMinWarningDIalogComponentNgFactory as RnHrsMinWarningDIalogComponentNgFactory };
