import { Component, OnInit, Provider } from '@angular/core';
import { DialogOptions, DialogOptions2, IDialog } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { PbjNewExportWarningDialogOptions } from '../../../../models/pbj-export/Pbj-new-export-warning-options';
import { IPBJExportCSV } from '../../../../models/pbj-export/pbj-export-csv';
import * as XLSX from 'xlsx';

@Component({
  selector: 'slx-pbj-new-export-warning-dialog',
  templateUrl: './pbj-new-export-warning-dialog.component.html',
  styleUrls: ['./pbj-new-export-warning-dialog.component.scss'],
})
export class PbjNewExportWarningDialogComponent implements IDialog, OnInit {
  public dialogResult: boolean;
  public options: DialogOptions;
  private modalService: ModalService;
  static data: IPBJExportCSV[];

  public static openDialog(
    options: PbjNewExportWarningDialogOptions,
    modalService: ModalService,
    callback: (result: boolean) => void
  ): PbjNewExportWarningDialogComponent {
    this.data = options.pbjNewExportCsvData;

    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.height = 180;
    dialogOptions.width = 500;
    dialogOptions.className = 'slx-pbj-exports-error-modal';
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions,
      },
    ];
    let dialog: PbjNewExportWarningDialogComponent = modalService.globalAnchor.openDialog2(
      PbjNewExportWarningDialogComponent,
      'Alert',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

  constructor(options: DialogOptions, modalService: ModalService) {
    this.modalService = modalService;
    this.options = options;
  }

  DownloadPbjExportIssues() {
    const today = new Date();
    let yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();
    let hh = today.getHours();
    let mins = today.getMinutes();
    let ss = today.getSeconds();

    let date = `${mm}${dd}${yyyy}`;
    let time = `${hh}${mins}${ss}`;
    let filename = `PBJ_Export_Config_Issues_${date}_${time}.csv`;

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(PbjNewExportWarningDialogComponent.data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [['Error ID']], { origin: 'A1' });
    XLSX.utils.sheet_add_aoa(ws, [['Error Description']], { origin: 'B1' });
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  ngOnInit() {}
}
