import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { IMessageModel, MessageModel, IReminderModel, ReminderModel, IIconAccess, IconAccess, IMessageNotificationModel, MessageNotificationModel } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { UnreadMessageCount, IUnreadMessageCount } from '../../../app-modules/message-center/models/message-list';
@Injectable()
export class NotificationsMapService {

    public mapToMessages(array: IMessageModel[]): MessageModel[] {
        let messages: MessageModel[] = [];
        _.each(array, (msg: IMessageModel) => {
            let message: MessageModel = this.mapToMessage(msg);
            messages.push(message);
        });
        return messages;
    }

    public mapToMessage(msg: IMessageModel): MessageModel {
        let message: MessageModel = new MessageModel();
        message.messageId = msg.messageId;
        message.senderFullName = msg.senderFullName;
        message.status = msg.status;
        message.text = msg.text;
        message.createdOn = dateTimeUtils.convertFromDtoDateTimeString(msg.createdOn);
        return message;
    }

    public mapToReminders(array: IReminderModel[]): ReminderModel[] {
        let reminders: ReminderModel[] = [];
        _.each(array, (msg: IReminderModel) => {
            let reminder: ReminderModel = this.mapToReminder(msg);
            reminders.push(reminder);
        });
        return reminders;
    }

    public mapToReminder(r: IReminderModel): ReminderModel {
        let reminder: ReminderModel = new ReminderModel();
        reminder.createdOn = dateTimeUtils.convertFromDtoDateTimeString(r.createdOn);
        reminder.dueDate = dateTimeUtils.convertFromDtoDateTimeString(r.dueDate);
        reminder.details = r.details;
        reminder.reminderId = r.reminderId;
        reminder.subject = r.subject;
        reminder.type = r.type;
        reminder.percent = this.getReminderPercent(reminder);
        this.setReminderColors(reminder);
        return reminder;
    }

    public mapToIconAccess(access: IIconAccess): IconAccess {
        let iconAccess: IconAccess = new IconAccess();
        iconAccess.isAdminUser = access.isAdminUser;
        iconAccess.isMessageCenterEnabled = access.isMessageCenterEnabled;
        iconAccess.isUserAllowed = access.isUserAllowed;
        return iconAccess;
    }

    public setReminderColors(reminder: ReminderModel): void {
        reminder.isGreen = reminder.percent <= 33;
        reminder.isOrange = !reminder.isGreen && reminder.percent <= 66;
        reminder.isRed = !reminder.isOrange && reminder.percent <= 100;
    }

    public getReminderPercent(reminder: ReminderModel): number {
        let diffTotal: number = moment(reminder.dueDate).diff(reminder.createdOn);
        let diffNow: number = moment(reminder.dueDate).diff(new Date());
        diffTotal = diffTotal < 0 ? 0 : diffTotal;
        diffNow = diffNow < 0 ? 0 : diffNow;
        let percent: number = diffTotal === 0 && diffNow === 0 ? 100 : Math.round((diffNow / diffTotal) * 100);
        return percent;
    }

    public mapToPopupMessages(array) {
        return array;
    }

    public mapToUpdateReadStatus(array) {
        let message = this.mapToPopupMessage(array);
        return message;
    }

    public mapToPopupMessage(dto: IMessageNotificationModel): MessageNotificationModel {
        let msg: MessageNotificationModel = new MessageNotificationModel();
        msg.date = dto.created;
        msg.from_name = dto.fullName;
        msg.id = dto.id;
        msg.message = dto.body
        msg.read = (dto.read === null) ? false : dto.read;
        msg.time = dto.created;
        msg.from_phone = dto.phone;
        msg.parentId = dto.parentId;
        return msg;
    }

    public unreadMsgCount(data: any): IUnreadMessageCount {
        const unreadMessageCount = new UnreadMessageCount();
        unreadMessageCount.total = data.total;
        unreadMessageCount.unRead = data.unRead;
        unreadMessageCount.cold = data.cold;
        unreadMessageCount.hot = data.hot;
        return unreadMessageCount;
    }
}
