import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { WindowRef } from '../../../../../core/services/window/window-ref.model';
import { unsubscribeAll } from '../../../../../core/decorators/index';

import { EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { PopoverContentComponent } from '../../../../../common/index';
import { Employee, ReadFile, Lookup, LookupEntity, PerformanceReviewCode, PmAttachment, PmTemplate } from '../../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';

import { PmReviewEntry } from '../../../models/index';
import { PmCreationManagementService } from '../../../services/index';
import { NgModel } from '@angular/forms';
import { IPerformanceManagementConfig, pmConfig } from '../../../performance-management.config';

@Component({
  moduleId: module.id,
  selector: 'slx-pm-review-form',
  templateUrl: 'pm-review-form.component.html',
  styleUrls: ['pm-review-form.component.scss']
})
export class PmReviewFormComponent implements OnInit, OnDestroy {

  @ViewChild('reviewDatePicker', { static: false })
  public reviewDatePicker: NgModel;

  public orgLevel: OrgLevel;
  public minDate: Date;
  public reviewDate = new Date();
  public lastReviewDate: Date;
  public reviewType: PerformanceReviewCode;
  public rating: PerformanceReviewCode;
  public reviewTypeInPopover: PerformanceReviewCode;
  public ratingInPopover: PerformanceReviewCode;
  public comment: string;
  public reviewedBy: string;
  public attachments: PmAttachment[] = [];
  public templates: PmTemplate[] = [];
  public templateAttachments: PmAttachment[] = [];

  public isComplete: boolean;
  public ratingLookup: Lookup;
  public typesLookup: Lookup;
  public loadingReviewData : boolean = true;

  public review = new PmReviewEntry();

  public get empId(): number {
    return this.review.employee.id;
  }
  public get empName(): string {
    return this.review.employee.name;
  }
  public get posName(): string {
    return this.review.employee.positionName;
  }
  public get depName(): string {
    return this.review.employee.departmentName;
  }
  public get orgName(): string {
    return this.review.employee.organizationName;
  }
  public get isEditMode(): boolean {
    return this.review.isEditMode;
  }
  public get isCreatingNew(): boolean {
    return this.review.isNew;
  }
  public get canEdit(): boolean {
    return this.review.canEdit;
  }
  public get canDelete(): boolean {
    return this.review.canDelete;
  }

  public templateAttachmentsCount: number;
  public attachmentsCount: number;

  public appConfig: IApplicationConfig = appConfig;
  public pmConfig: IPerformanceManagementConfig = pmConfig;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private empNavService: EmployeeSectionNavigationService;


  constructor(
    private manService: PmCreationManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private win: WindowRef
  ) {
    this.empNavService = new EmployeeSectionNavigationService(this.router, this.route);
    this.minDate = moment(pmConfig.settings.minDateString).toDate();

  }

  public ngOnInit(): void {
    this.subscriptions.review = this.manService.subscribeToReviewChanged((review: PmReviewEntry) => {
      this.review = review;
      this.loadingReviewData = false;
      if (!this.review.reviewDate) {
        this.review.reviewDate = new Date();
      }
      this.onChangeReviewDate(this.review.reviewDate);
      this.reviewType = this.review.reviewType;
      this.lastReviewDate = this.review.lastReviewDate;
      this.rating = this.review.rating;
      this.ratingInPopover = this.review.rating;
      this.reviewTypeInPopover = this.review.reviewType;
      this.comment = this.review.comment;
      this.reviewedBy = this.review.reviewedBy;
      this.attachments = this.review.attachments;
      this.templates = this.review.templates;
      this.templateAttachments = _.map(this.templates, x => x.attachment);
      this.templateAttachmentsCount = _.size(this.templateAttachments);
      var addedFiles = this.manService.getAddedFiles();
      this.attachmentsCount = _.size(addedFiles) + _.size(this.attachments);
      this.isComplete = this.review.status === 'Completed';
    });
    this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel((o: OrgLevel) => {
      this.orgLevel = o;
    });
    this.subscriptions.ratings = this.manService.subscribeToRatingsLoaded((o: Lookup) => {
      this.ratingLookup = o;
    });
    this.subscriptions.types = this.manService.subscribeToTypesLoaded((o: Lookup) => {
      this.typesLookup = o;
    });
  }

  public ngOnDestroy(): void { }

  public onClickEmployee(): void {
    this.manService.navigateToUserProfile(this.empId);
  }

  public async onClickDelete(isDelete: boolean, acceptPopover: PopoverContentComponent): Promise<void> {
    acceptPopover.hide();
    if (isDelete) {
      this.manService.deleteReview();
    }
  }

  public async onClickComplete(isComplete: boolean, acceptPopover: PopoverContentComponent): Promise<void> {
    acceptPopover.hide();
    if (isComplete) {
      this.manService.completeReview();
    }
  }

  public async onClickOk(isComplete: boolean, acceptPopover: PopoverContentComponent): Promise<void> {
    acceptPopover.hide();
    if (isComplete) {
      this.rating = this.ratingInPopover;
      this.reviewType = this.reviewTypeInPopover;
    } else {
      this.onChangeReviewTypes();
      this.onChangeRating();
      this.resetInput();
    }
  }

  public resetInput(): void {
    this.reviewTypeInPopover = null;
    this.ratingInPopover = null;
  }

  public onChangeReviewDate(date: Date): void {
    if (this.reviewDatePicker) {
      this.manService.setReviewDate(date);
      this.manService.setFormValidity(this.reviewDatePicker.valid);
    }
  }

  public onChangeReviewTypes(): void {
    this.manService.setReviewType(this.reviewType);
  }

  public onChangeRating(): void {
    this.manService.setRating(this.rating);
  }

  public onChangeComment(): void {
    this.manService.setComment(this.comment);
  }

  public onAddedFiles(files: ReadFile[]): void {
    this.manService.saveFiles(files);
  }

  public onDeletedFile(file: ReadFile): void {
    this.manService.deleteFile(file);
  }

  public onDeletedAttachment(attachment: PmAttachment): void {
    this.manService.deleteAttachment(attachment.id);
  }

  public onDownloadAttachment(attachment: PmAttachment): void {
    this.manService.downloadAttachment(attachment.id);
  }
}
