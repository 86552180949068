import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class EmployeeListNavigationService {
    private router: Router;
    private route: ActivatedRoute;

    constructor(router: Router, route: ActivatedRoute) {
        this.router = router;
        this.route = route;
    }

    public navigateToEmployeeList(orgLevelId: number = 0): void {
        let params: any;
        if (orgLevelId) {
            params = { orgLevelId: orgLevelId };
        }
        this.router.navigate(['employee_list'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
    }
}
