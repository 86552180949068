import {
  EmployeeSectionsPolicyAssignments,
  IEmployeeSectionsPolicyAssignments,
  EmployeeSectionAccrualTransactions,
  IEmployeeSectionAccrualTransactions,
  IEmployeeSectionAccrualsBalances,
  EmployeeSectionAccrualsBalances
} from './index';
import { IEmployeeSectionsSubsectionContainer } from '../index';

export interface IEmployeeSectionsAccruals {
  balances: IEmployeeSectionsSubsectionContainer<IEmployeeSectionAccrualsBalances>;
  transactions: IEmployeeSectionsSubsectionContainer<IEmployeeSectionAccrualTransactions>;
  policyAssignments: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsPolicyAssignments>;
}

export class EmployeeSectionsAccruals {
  public balances: EmployeeSectionAccrualsBalances;
  public policyAssignments: EmployeeSectionsPolicyAssignments;
  public transactions: EmployeeSectionAccrualTransactions;
}
