<ng-container *ngIf="valueType === BenefitEligibilityRuleDataType.Integer">
  <input
    #multiNumberInput="ngModel"
    class="slx-rule-input"
    type="text"
    name="integer-default-value-{{ uniqueComponentId }}"
    [(ngModel)]="value"
    (ngModelChange)="onValueChanged(value)"
    required
    slxNumberMulti
  />
  <span
    class="slx-error-block error-box"
    *ngIf="
      multiNumberInput &&
      (multiNumberInput.dirty || multiNumberInput.touched) &&
      multiNumberInput.errors?.requireNumberMulti
    "
    errorMessage
    >The field should contain only integer values ​​separated by comma</span
  >
</ng-container>

<ng-container *ngIf="valueType === BenefitEligibilityRuleDataType.Decimal">
  <input
    #multiDecimalInput="ngModel"
    class="slx-rule-input"
    type="text"
    name="decimal-default-value-{{ uniqueComponentId }}"
    [(ngModel)]="value"
    (ngModelChange)="onValueChanged(value)"
    required
    slxDecimalMulti
  />
  <span
    class="slx-error-block error-box"
    *ngIf="
      multiDecimalInput &&
      (multiDecimalInput.dirty || multiDecimalInput.touched) &&
      multiDecimalInput.errors?.requireDecimalMulti
    "
    errorMessage
    >The field should contain only decimal values ​​separated by comma</span
  >
</ng-container>

<ng-container *ngIf="valueType === BenefitEligibilityRuleDataType.String">
  <input
    #multiStringInput="ngModel"
    class="slx-rule-input"
    type="text"
    name="string-default-value-{{ uniqueComponentId }}"
    [(ngModel)]="value"
    (ngModelChange)="onValueChanged(value)"
    required
  />
  <span
    class="slx-error-block error-box"
    *ngIf="
      multiStringInput &&
      (multiStringInput.dirty || multiStringInput.touched) &&
      multiStringInput.errors?.required
    "
    errorMessage
    >The field should contain only string values ​​separated by comma</span
  >
</ng-container>
