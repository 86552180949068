import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta, FieldsMeta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { RolesMapService } from './roles-map.service';
import { configurationConfig } from '../../configuration.config';
import { Role, IRole, RolesContainer, SaveRolesRequest, IRoleAccessTable } from '../../models/index';
import { IRoleDefinition, RoleDefinition } from '../../../organization/models/index';

@Injectable()
export class RolesApiService {
  constructor(private apiUtilService: ApiUtilService,
              private urlParamService: UrlParamsService,
              private mapService: RolesMapService,
              private cacheUtilService: CacheUtilService) {
  }

  public getRoleAccessTable(): Promise<RolesContainer> {
    let url: string = `${this.getRoleConfUrlRoot()}`;
    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, {});
    let promise: Promise<RolesContainer> = this.apiUtilService.request<IRoleAccessTable, FieldsMeta>(request)
      .then((response: ResponseBody<IRoleAccessTable, FieldsMeta>) => this.mapService.mapToRolesContainer(response.data));
    return promise;
  }

  public saveRoleAccessTable(req: SaveRolesRequest): Promise<RolesContainer> {
    Assert.isNotNull(req, 'saveRoleAccessTable req');
    let url: string = `${this.getRoleConfUrlRoot()}`;
    let request: HttpRequest<any> = this.urlParamService.createPutRequest(url, req);
    let promise: Promise<RolesContainer> = this.apiUtilService.request<IRoleAccessTable, Meta>(request)
      .then((response: ResponseBody<IRoleAccessTable, Meta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapToRolesContainer(response.data);
      });
    return promise;
  }

  public getRoles(): Promise<RoleDefinition[]> {
    let url: string = `${this.getRolesUrlRoot()}`;
    let request: HttpRequest<any> = this.urlParamService.createGetRequest(url, {});
    let promise: Promise<RoleDefinition[]> = this.apiUtilService.request<IRoleDefinition[], FieldsMeta>(request)
      .then((response: ResponseBody<IRoleDefinition[], FieldsMeta>) => this.mapService.mapRoleDefitinions(response.data));
    return promise;
  }

  public addRole(role: RoleDefinition): Promise<RoleDefinition> {
    Assert.isNotNull(role, 'cloneRole role');
    let url: string = `${this.getRolesUrlRoot()}`;
    let request: HttpRequest<any> = this.urlParamService.createPostRequest(url, this.mapService.mapRoleDefitinionDto(role));
    let promise: Promise<RoleDefinition> = this.apiUtilService.request<IRoleDefinition, FieldsMeta>(request)
      .then((response: ResponseBody<IRoleDefinition, FieldsMeta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapRoleDefitinion(response.data);
      });
    return promise;
  }

  public cloneRole(role: RoleDefinition, sourceId: number): Promise<RoleDefinition> {
    Assert.isNotNull(role, 'cloneRole role');
    let url: string = `${this.getRolesUrlRoot()}/${sourceId}/${configurationConfig.api.core.roles.clone}`;
    let request: HttpRequest<any> = this.urlParamService.createPostRequest(url, this.mapService.mapRoleDefitinionDto(role));
    let promise: Promise<RoleDefinition> = this.apiUtilService.request<IRoleDefinition, FieldsMeta>(request)
      .then((response: ResponseBody<IRoleDefinition, FieldsMeta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapRoleDefitinion(response.data);
      });
    return promise;
  }

  public saveRole(role: RoleDefinition): Promise<RoleDefinition> {
    Assert.isNotNull(role, 'saveRole role');
    let url: string = `${this.getRolesUrlRoot()}/${role.id}`;
    let request: HttpRequest<any> = this.urlParamService.createPutRequest(url, this.mapService.mapUpdateRoleRequest(role));
    let promise: Promise<RoleDefinition> = this.apiUtilService.request<IRoleDefinition, FieldsMeta>(request)
      .then((response: ResponseBody<IRoleDefinition, FieldsMeta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapRoleDefitinion(response.data);
      });
    return promise;
  }


  public deleteRole(roleId: number): Promise<any> {
    let url: string = `${this.getRolesUrlRoot()}/${roleId}`;
    let request: HttpRequest<any> = this.urlParamService.createDeleteRequest(url, undefined, undefined);
    let promise: Promise<Role> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => {
        this.cacheUtilService.clear();
        return response.data;
      });
    return promise;
  }

  private getRolesUrlRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.core.root}/${configurationConfig.api.core.roles.root}`;
  }

  private getRoleConfUrlRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.core.root}/${configurationConfig.api.core.roleConfiguration.root}`;
  }

}
