import { DayOfWeek } from '../../../common/models/days/day-of-week';

export class WeeklyDayCounter {
  public day: DayOfWeek;
  public counter: number;
  public hours: number;

  public constructor (day: DayOfWeek, counter: number, hours: number) {
    this.day = day;
    this.counter = Math.ceil(counter);
    this.hours = hours;
  }
}
