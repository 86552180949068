import * as _ from 'lodash';
import { IDateRange, DateRange } from '../../../core/models/index';
import { LMCreationAbsenceManagementService } from '../services/index';
import * as moment from 'moment';

export abstract class LMCommonTab {
  public estStartDate: Date;
  public estEndDate: Date;
  public actStartDate: Date;
  public actEndDate: Date;
  public estAllDay: boolean;
  public actAllDay: boolean;
  public hasIncorrectContinuousDates: boolean = false;

  public get hasExceptions(): boolean {
    return _.size(this.exceptionsDates) > 0;
  }

  public get canEditRequest(): boolean {
    return this.service.canChange;
  }

  public get hasEstimatedDates(): boolean {
    return this.service.hasEstimatedDates;
  }

  public get hasActualDates(): boolean {
    return this.service.hasActualDates;
  }

  protected exceptionsDates: DateRange[] = [];

  constructor(private service: LMCreationAbsenceManagementService) { }

  public init(): void {
    const estimated = this.service.getEstamatedDates();
    const actual = this.service.getActualDates();

    this.estStartDate = estimated.startDate;
    this.estEndDate = estimated.endDate;
    this.actStartDate = actual.startDate;
    this.actEndDate = actual.endDate;

    this.exceptionsDates = this.service.getExceptions();
    this.estAllDay = this.service.getEstAllDay();
    this.actAllDay = this.service.getActAllDay();
  }

  public onChangeEstStartDate(date: Date): void {
    this.service.setEstimatedDates(date, null);
  }

  public onChangeEstEndDate(date: Date): void {
    this.service.setEstimatedDates(null, date);
  }

  public onChangeEstAllDay(): void {
    if (this.estAllDay) {
      this.estStartDate = this.service.getStartOfDay(this.estStartDate);
      this.estEndDate = this.service.getEndOfDay(this.estEndDate);
      this.service.setEstimatedDates(this.estStartDate, this.estEndDate);
    } else {
      this.estEndDate = moment(this.estEndDate).hour(0).minute(0).second(0).toDate();

      this.service.setEstimatedDates(this.estStartDate, this.estEndDate);
    }
    this.service.setEstAllDay(this.estAllDay);
  }

  public onChangeActStartDate(date: Date): void {
    this.service.setActualDates(date, null);
  }

  public onChangeActEndDate(date: Date): void {
    this.service.setActualDates(null, date);
  }

  public onChangeActAllDay(): void {
    if (this.actAllDay) {
      this.actStartDate = this.service.getStartOfDay(this.actStartDate);
      this.actEndDate = this.service.getEndOfDay(this.actEndDate);
      this.service.setActualDates(this.actStartDate, this.actEndDate);
    } else {
      this.actEndDate = moment(this.actEndDate).hour(0).minute(0).second(0).toDate();

      this.service.setActualDates(this.actStartDate, this.actEndDate);
    }
    this.service.setActAllDay(this.actAllDay);
  }
}
