import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { BenefitListApiService } from './benefit-list-api.service';
import { Assert } from '../../../../framework/index';
import {
  BenefitPayrollMapping,
  BenefitPayrollExistingMapping,
  BenefitPayrollCreateMapping
} from '../../models/index';

@Injectable()
export class BenefitPayrollMappingManagementService {
  private benefitPayrollMappingLoaded$ = new ReplaySubject<BenefitPayrollMapping>(1);
  private benefitPayrollExistingMappingLoaded$ = new ReplaySubject<BenefitPayrollExistingMapping[]>(1);
  constructor(
    private apiService: BenefitListApiService
  ) {
  }

  public destroy(): void {
    this.benefitPayrollMappingLoaded$.complete();
    this.benefitPayrollExistingMappingLoaded$.complete();
  }

  public subscribeToBenefitPayrollMapping(callback: (v: BenefitPayrollMapping) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.benefitPayrollMappingLoaded$.subscribe(callback);
  }

  public subscribeToBenefitPayrollExistingMapping(callback: (v: BenefitPayrollExistingMapping[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.benefitPayrollExistingMappingLoaded$.subscribe(callback);
  }

  public async getBenefitPayRollMapping(showIgnored: boolean): Promise<any> {
    this.apiService.getBenefitPayRollMapping(showIgnored)
      .then((container: BenefitPayrollMapping) => {
        this.benefitPayrollMappingLoaded$.next(container);
      });
  }

  public async getpayrollExistingMapping(): Promise<any> {
    this.apiService.getpayrollExistingMapping()
      .then((container: BenefitPayrollExistingMapping[]) => {
        this.benefitPayrollExistingMappingLoaded$.next(container);
      });
  }

  public async benefitPayRollCreateMapping(req: BenefitPayrollCreateMapping): Promise<any> {
    return this.apiService.benefitPayRollCreateMapping(req);
  }
}
