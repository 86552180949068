import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PunchProfileManagementService } from '../../services';
import { unsubscribe,mutableSelect } from '../../../../core/decorators/index';
import { IPunchAttestationPeriod } from '../../models';
import { IPunchProfileGroup } from '../../models/punch-profile-group';
import { PunchProfileAttestationQuestionsComponent } from '../punch-profile-attestation-questions/punch-profile-attestation-questions.component';
import { ModalService } from '../../../../common/services/index';
import { AttestationType, ILookupEntity } from '../../../../../app/organization';
import { PunchProfileData } from '../../models/punch-profile-data';
import { OrgLevel } from '../../../../state-model/models/index';
import { PunchAttestationManagementService } from '../../../../app-modules/punch-attestation/services';

@Component({
  selector: 'slx-punch-profile-attestation',
  templateUrl: './punch-profile-attestation.component.html',
  styleUrls: ['./punch-profile-attestation.component.scss']
})
export class PunchProfileAttestationComponent implements OnInit, OnChanges {

  @Input() public groups: IPunchAttestationPeriod[];
  @Input() public profileId: string;
  @Input() public punchProfileGroup: IPunchProfileGroup[];
  @Input() public attestationType: AttestationType[];
  @Input() public punchType: ILookupEntity[];

  public group: IPunchAttestationPeriod;
  public punchProfile: IPunchProfileGroup;
  public isLoading: boolean;
  public isEnabled: boolean = false;
  public orgLevel: OrgLevel;
  public orglevelId: number;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private statusSubscription: Subscription;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  public legacyclockquestiongroup : string = 'Legacy clock question group';
  constructor(private punchProfileManagementService: PunchProfileManagementService,
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService,
    private punchAttestationmanagementService: PunchAttestationManagementService,) { }

  ngOnInit() {
    this.setPunchProfileGroupData();
    this.statusSubscription = this.punchProfileManagementService.onLoadStatus$.subscribe(isLoading => {
      this.isLoading = isLoading;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orglevelId = orgLevel.id;
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
      }
    });
    this.punchAttestationmanagementService.getPermissions(this.orglevelId);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setPunchProfileGroupData();
  }

  savePunchProfileGroup() {
    if(this.authorized && !this.disableSaveButton){
      if (!this.punchProfile) {
        this.punchProfile = {
          id: 0,
          groupId: this.group ? this.group.id : 0,
          profileId: this.profileId
        };
      } else {
        this.punchProfile.groupId = this.group ? this.group.id : 0;
      }
      this.punchProfileManagementService.addOrUpdatePunchProfile(this.orgLevel.id,this.punchProfile);
    }
  }

  setPunchProfileGroupData() {
    if(this.punchProfileGroup){
      this.punchProfile = this.punchProfileGroup.find(x => x.profileId == this.profileId);
      if (this.punchProfile && this.groups) {
        this.isEnabled = this.punchProfile.groupId !== null ? true : false;
        this.group = this.groups.find(x => x.id == this.punchProfile.groupId);
      }
    }
  }

  viewQuestions() {
    const punchProfileData: PunchProfileData = {
      question: this.group.questions,
      attestationType: this.attestationType,
      punchType: this.punchType,
      isLegacy : this.group.groupName.toLocaleLowerCase() === this.legacyclockquestiongroup.toLocaleLowerCase()
    }
    PunchProfileAttestationQuestionsComponent.openDialog(punchProfileData, this.modalService, (result: boolean, cmd: any) => {
      //todo
    });
  }
checkboxChecked(){
    if(!this.isEnabled){
      this.group = null;
    }
  }
  
  get authorized():boolean {
    return this.punchAttestationmanagementService.configurePunchProfile.getValue();
  }

  get disableSaveButton():boolean {
    if(this.punchProfile){
      return this.punchProfile.id === 0 && (this.punchProfile.groupId === null || this.punchProfile.groupId === undefined)
    } else{
     return (this.group === null || this.group === undefined);
    }
  }
}
