import * as _ from 'lodash';
import * as moment from 'moment';
import { Shift } from '../../../models/index';
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind } from '../../../models/index';
import { ILookupEntity } from '../../../../organization/models/index';
import { appMessages, IApplicationMessages } from '../../../../app.messages';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { Component, Input, forwardRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ShiftsManagementService } from '../../../services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-editor-shift-renderer',
    templateUrl: 'editor-shift-renderer.component.html',
    styleUrls: ['editor-shift-renderer.component.scss'],
    providers: [
        {
            provide: CustomListActorBase,
            useExisting: forwardRef(() => EditorShiftRendererComponent)
        }
    ]
})
export class EditorShiftRendererComponent extends CustomListActorBase {

    public appMessages: IApplicationMessages = appMessages;
    public appConfig: IApplicationConfig = appConfig;

    @Input()
    public groups: ILookupEntity[];

    @Input()
    public rowIndex: number;

    @Input()
    public item: Shift;

    @Input()
    public prohibitedNameValues: string[] = [];

    @Input()
    public onShiftStartDateChanged: (shift: Shift) => void;

    @Input()
    public onShiftEndDateChanged: (shift: Shift) => void;

    @Input()
    public shiftPaidTimeChanged: (shift: Shift, value: number) => void;

    @Input()
    public shiftUnpaidTimeChanged: (shift: Shift, value: number) => void;

    @Input()
    public templateForm: FormGroup;

    constructor(public management: ShiftsManagementService) {
        super();
    }

    public onSaveClick(): void {
        this.actionEmitter.emit(EditableListActionKind.COMPLETE_EDIT);
    }

    public onCancelClick(): void {
        this.actionEmitter.emit(EditableListActionKind.CANCEL_EDIT);
    }

}
