
    <slx-spinner [show]="isLoading">
        <span *ngIf="showInActiveMsg()">
            <div class="inactive-client" *ngIf="!isActive ">
            The WFM partner has not been configured. Please contact your system administrator.
        </div>
        <div class="content active-client" *ngIf="isActive">
            <div>
                <div class="row">
                    <div class="column">
                        <div class="card">
                            <label class="slx-label partner-name">Partner Name:{{partnerName}}</label>
                            <slx-input-decorator><input slx-input type="text" [readonly]="true" name="status"
                                    placeholder="Status : " [(ngModel)]="partnerStatus">
                            </slx-input-decorator>
                            <slx-input-decorator><input slx-input type="text" [readonly]="true" name="version"
                                    placeholder="Version : " [(ngModel)]="version">
                            </slx-input-decorator>
                            <slx-input-decorator><input slx-input type="text" [readonly]="true" name="authorizedDate"
                                    placeholder="Authorized Date : " [(ngModel)]="authDate">
                            </slx-input-decorator>
                        </div>
                    </div>
                </div>
            </div>
            <div class="activate">
                <button class="theme-button-apply" (click)="openNewTab(url)">{{status}}</button>
            </div>
        </div>
    </span>
    </slx-spinner>

