import { Component, ViewChild, Provider } from '@angular/core';
import { IDialog, DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/index';
import { StaffingPredictorMatrixDialogOptions, StaffingPointsTable, StaffingPredictorOrganizationInfo } from '../../models/index';
import { PredictorMatrixComponent } from '../predictor-matrix/predictor-matrix.component';

@Component({
  moduleId: module.id,
  selector: 'slx-matrix-dialog',
  templateUrl: 'predictor-matrix-dialog.component.html',
  styleUrls: ['predictor-matrix-dialog.component.scss']
})
export class PredictorMatrixDialogComponent implements IDialog {

  public staffingPointsTables: StaffingPointsTable[];
  public organizationInfo: StaffingPredictorOrganizationInfo;
  public five_star_v2_enabled: boolean = false;

  public dialogResult: boolean;

  @ViewChild('matrixComponent', { static: true })
  private matrixComponent: PredictorMatrixComponent;

  public static openDialog(options: StaffingPredictorMatrixDialogOptions, modalService: ModalService, callback?: (result: boolean) => void): PredictorMatrixDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = options.width;
    dialogOptions.height = options.height;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.hideTitleBar = true;
    let providers: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: StaffingPredictorMatrixDialogOptions,
        useValue: options
      }
    ];
    let dialog: PredictorMatrixDialogComponent = modalService.globalAnchor
      .openDialog(PredictorMatrixDialogComponent, options.title, dialogOptions, providers, (result: boolean) => {
        callback(result);
      });
    dialog.organizationInfo = options.organizationInfo;
    dialog.staffingPointsTables = options.staffingPointsTables;
    dialog.five_star_v2_enabled = options.five_star_v2_enabled;
    return dialog;
  }
  
  constructor(private dialogOptions: DialogOptions,
    private modalService: ModalService) { }

  public onClose(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }
}
