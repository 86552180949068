/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./midnight-to-midnight-report-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./midnight-to-midnight-report/midnight-to-midnight-report.component.ngfactory";
import * as i5 from "./midnight-to-midnight-report/midnight-to-midnight-report.component";
import * as i6 from "@angular/common";
import * as i7 from "./midnight-to-midnight-report-dialog.component";
import * as i8 from "../../../common/services/modal/modal.service";
import * as i9 from "../../../common/models/dialog-options";
import * as i10 from "../../models/report-definition";
import * as i11 from "../../services/reports-api.service";
var styles_MidnightToMidnightReportDialogComponent = [i0.styles];
var RenderType_MidnightToMidnightReportDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MidnightToMidnightReportDialogComponent, data: {} });
export { RenderType_MidnightToMidnightReportDialogComponent as RenderType_MidnightToMidnightReportDialogComponent };
export function View_MidnightToMidnightReportDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "modal-body-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-midnight-to-midnight-report", [], null, [[null, "hasValueChange"], [null, "modifiedIndex"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onValueChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("hasValueChange" === en)) {
        var pd_1 = (_co.onValueChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("modifiedIndex" === en)) {
        var pd_2 = (_co.modifiedDataChanges($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_MidnightToMidnightReportComponent_0, i4.RenderType_MidnightToMidnightReportComponent)), i1.ɵdid(4, 245760, null, 0, i5.MidnightToMidnightReportComponent, [], { gridData: [0, "gridData"] }, { hasValueChange: "hasValueChange", modifiedIndex: "modifiedIndex" }), (_l()(), i1.ɵeld(5, 0, null, 0, 9, "div", [["class", "modal-footer wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "btn red pad-top-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel "])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "btn green pad-top-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "disabled-content": 0 }), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.reportDefinition.gridData; _ck(_v, 4, 0, currVal_1); var currVal_2 = "btn green pad-top-10"; var currVal_3 = _ck(_v, 12, 0, !_co.hasValueChange); _ck(_v, 11, 0, currVal_2, currVal_3); }, null); }
export function View_MidnightToMidnightReportDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-midnight-to-midnight-report-dialog", [], null, null, null, View_MidnightToMidnightReportDialogComponent_0, RenderType_MidnightToMidnightReportDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.MidnightToMidnightReportDialogComponent, [i8.ModalService, i9.DialogOptions, i10.ConfigForceLoad, i10.MidnightToMidnightReportData, i11.ReportsApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MidnightToMidnightReportDialogComponentNgFactory = i1.ɵccf("slx-midnight-to-midnight-report-dialog", i7.MidnightToMidnightReportDialogComponent, View_MidnightToMidnightReportDialogComponent_Host_0, {}, {}, []);
export { MidnightToMidnightReportDialogComponentNgFactory as MidnightToMidnightReportDialogComponentNgFactory };
