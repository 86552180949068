<div class="slx-navigation-menu-container">
<ul (scroll)="onLeftNavScroll()" *ngIf="!noData" #slxNavMenu class="slx-navigation-menu slx-navigation-menu-ignore" [ngClass]="{ 'menu-opened': isOpened, 'mobile': isMobile() }">
  <li class="top-nav-item dashboard-item">
    <a class="top-nav-link real-nav-link" (click)="onToggleFavoritesView($event)">
        <i class="top-nav-icon star fas fa-star" aria-hidden="true"></i>
        <span class="star top-title">Favorites</span>
    </a>
    <slx-favorites-menu [show]="showFavorites" [top]="posTop" [left]="posLeft" [pointerIconShift]="iconShift"></slx-favorites-menu>
  </li>
  <li class="top-nav-item dashboard-item">
      <a class="top-nav-link real-nav-link" slxHomeLink>
          <i class="top-nav-icon fas fa-chart-pie" aria-hidden="true"></i>
          <span class="top-title">Dashboard</span>
      </a>
  </li>
  <li class="top-nav-item" (mouseenter)="menuMouseEnter(topMenuItem)" (mouseleave)="menuMouseLeave(topMenuItem)"
    *ngFor="let topMenuItem of menuItems" (click)="onLeftNavScroll();"
    [ngClass]="{ 'disabled': isDisabled(topMenuItem), 'expanded': isExpanded(topMenuItem), 'selected': topMenuItem.isHovered, 'hidden': isHidden(topMenuItem) }"
  >
      <a *ngIf="topMenuItem.childs?.length <= 0" class="top-nav-link real-nav-link"
          (click)="selectGlobalMenuItem(topMenuItem, null, topMenuItem)">
        <i class="top-nav-icon fa {{topMenuItem.menuItemMapping.icon}}" aria-hidden="true"></i>
        <span class="top-title">{{topMenuItem.displayName}}</span>

      </a>
      <a *ngIf="topMenuItem.childs?.length > 0" (click)="toggleTopItem(topMenuItem)" class="top-nav-link">
        <i class="top-nav-icon fa {{topMenuItem.menuItemMapping.icon}}" aria-hidden="true"></i>
        <span class="top-title">{{topMenuItem.displayName}}</span>
        <i class="top-caret-icon fa" [ngClass]="{ 'fa-angle-left': !topMenuItem.isExpanded, 'fa-angle-down':topMenuItem.isExpanded }" aria-hidden="true"></i>
      </a>

      <ul *ngIf="topMenuItem.childs.length > 0" class="slx-group-menu" [ngClass]="{ 'slx-group-menu-global': topMenuItem.isGlobal && !topMenuItem.childLevelsCount === 3 }">
        <li *ngIf="topMenuItem.isGlobal && !topMenuItem.childLevelsCount === 3" class="top-nav-header">
              <span class="top-title">{{topMenuItem.displayName}}</span>
              <span class="top-nav-header-selected-mark"></span>
        </li>
        <div [slxMenuItemScroll]="!isOpened && topMenuItem.isHovered && topMenuItem.isGlobal">
          <li class="group-nav-item" (mouseenter) ="menuMouseEnter(groupMenuItem)" (mouseleave) ="menuMouseLeave(groupMenuItem)"
            *ngFor="let groupMenuItem of topMenuItem.childs"
            [ngClass]="{ 'expanded': isExpanded(groupMenuItem), 'expanded-scroll': isExpanded(groupMenuItem), 'disabled': isDisabled(groupMenuItem), 'hidden': isHidden(groupMenuItem) }"

          >
            <a *ngIf="groupMenuItem.childs.length <= 0 && isMenuItemEnabled(groupMenuItem)"  class="group-nav-link real-nav-link" [ngClass]="{ 'group-nav-global-link': groupMenuItem.isGlobal }"
            (click)="selectGlobalMenuItem(topMenuItem, groupMenuItem, groupMenuItem)">
              <i class="group-nav-icon {{groupMenuItem.menuItemMapping.icon}}" aria-hidden="true"></i>
              <span class="group-title">{{groupMenuItem.displayName}}</span>
              <span class="group-selected-mark"></span>
              <span *ngIf="isDisabled(groupMenuItem)" tipPosition="bottom" slxTooltip="{{unavailableText}}"></span>
            </a>

            <a *ngIf="groupMenuItem.childs.length > 0" class="group-nav-link" [ngClass]="{ 'open': groupMenuItem.isSelected || groupMenuItem.isHovered }" (click)="toggleGroupItem(topMenuItem, groupMenuItem)">
              <i class="group-nav-icon {{groupMenuItem.menuItemMapping.icon}}" aria-hidden="true"></i>
              <span class="group-title">{{groupMenuItem.displayName}}</span>
              <i class="group-caret-icon fa" aria-hidden="true"></i>
              <span class="group-selected-mark"></span>
            </a>

            <ul *ngIf="groupMenuItem.childs.length > 0" class="slx-item-menu" [ngClass]=" screenWidth < 781 ? 'no-margin' : ''">
              <li class="group-nav-header">
                  <span class="group-title">{{groupMenuItem.displayName}}</span>
                  <span class="group-nav-header-selected-mark"></span>
              </li>
              <div [slxMenuItemScroll]="!isOpened && groupMenuItem.isSelected || groupMenuItem.isHovered || (!this.isMobile() && isExpanded(groupMenuItem))">
              <ng-container *ngFor="let menuItem of groupMenuItem.childs; let menuIndex = index">
                <li class="menu-item-nav" *ngIf="isMenuItemEnabled(menuItem)"
                  [ngClass]="{ 'disabled': isDisabled(menuItem), 'hidden': isHidden(menuItem), 'last-visible': isLastVisibleItem(groupMenuItem,menuIndex) }"
                 >
                  <a class="item-nav-link real-nav-link" (click)="selectMenuItem(topMenuItem, groupMenuItem, menuItem)">
                  <span *ngIf="isDisabled(menuItem)" tipPosition="bottom" slxTooltip="{{unavailableText}}"></span>
                  <span class="item-mark"></span>
                  <span class="item-title">{{menuItem.displayName}}</span>
                  </a>

                </li>
              </ng-container>
             </div>
            </ul>

          </li>
        </div>
      </ul>
  </li>
</ul>
  <div class="no-data" *ngIf="noData">
    No Data
  </div>
</div>
