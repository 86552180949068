import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { LookupApiService } from '../../../organization/services/index';
import { PayCycle } from '../../../organization/models/index';
var Year = /** @class */ (function () {
    function Year(year) {
        this.year = year;
    }
    return Year;
}());
export { Year };
var Range = /** @class */ (function () {
    function Range(range, year, payCycle) {
        this.range = range;
        this.year = year;
        this.payCycle = payCycle;
    }
    return Range;
}());
export { Range };
var PayCycleDropdownComponent = /** @class */ (function () {
    function PayCycleDropdownComponent(lookupApiService) {
        this._resetSelectedPayCycle = true;
        this.lookupApiService = lookupApiService;
        this.appConfig = appConfig;
        this.payCycleSelected = new EventEmitter();
        this.payCyclesLoaded = new EventEmitter();
        this.defaultRange = new Range('Please select Pay Cycle', 0, null);
        this.isSetDefaultPayCycle = true;
    }
    Object.defineProperty(PayCycleDropdownComponent.prototype, "resetSelectedPayCycle", {
        set: function (val) {
            if (_.isBoolean(val)) {
                this._resetSelectedPayCycle = val;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleDropdownComponent.prototype, "empId", {
        set: function (val) {
            if (_.isNumber(val) && this.employeeId !== val) {
                // const resetSelectedPayCycle: boolean = _.isNumber(this.employeeId);
                var resetSelectedPayCycle = this._resetSelectedPayCycle;
                this.employeeId = val;
                this.loadPayCycles(resetSelectedPayCycle);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleDropdownComponent.prototype, "setOrgLevelId", {
        set: function (val) {
            if (_.isNumber(val) && this.customOrgLevelId !== val) {
                // 138762 - Retain currently selected paycycle on organisation change
                var resetSelectedPayCycle = false;
                this.customOrgLevelId = val;
                if (this.customOrgLevelId > 0) {
                    this.loadPayCycles(resetSelectedPayCycle, this.customOrgLevelId);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleDropdownComponent.prototype, "setPayCycle", {
        set: function (val) {
            if (_.isObject(val) && _.isDate(val.startDate) && _.isDate(val.endDate)) {
                this.selectedPayCycle = val;
                this.selectPayCycle();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleDropdownComponent.prototype, "setDefault", {
        set: function (val) {
            if (_.isBoolean(val)) {
                this.isSetDefaultPayCycle = val;
                if (val) {
                    this.selectPayCycle();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PayCycleDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return _.isNumber(o.id); })
            .subscribe(function (o) {
            if (!_.isNumber(_this.customOrgLevelId)) {
                var orgLevelId = _.get(_this.orgLevel, 'id');
                if (orgLevelId === o.id)
                    return;
                _this.orgLevel = o;
                _this.loadPayCycles(_.isNumber(orgLevelId), _this.orgLevel.id);
            }
        });
    };
    PayCycleDropdownComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    Object.defineProperty(PayCycleDropdownComponent.prototype, "currentYear", {
        get: function () {
            return this.m_year;
        },
        set: function (year) {
            if (_.get(this.m_year, 'year') !== _.get(year, 'year')) {
                this.setupPayCycle(year, this.currentRange);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleDropdownComponent.prototype, "currentRange", {
        get: function () {
            return this.m_range;
        },
        set: function (range) {
            if (this.m_range !== range) {
                this.m_range = range;
                if (_.isDate(_.get(range, 'payCycle.startDate')) && _.isDate(_.get(range, 'payCycle.endDate'))) {
                    this.payCycleSelected.emit(range.payCycle);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    PayCycleDropdownComponent.prototype.loadPayCycles = function (resetSelectedPayCycle, orgLevelId) {
        var _this = this;
        var id = orgLevelId || _.get(this.orgLevel, 'id');
        if (_.isNumber(id) && id > 0) {
            if (resetSelectedPayCycle) {
                this.selectedPayCycle = null;
                this.m_range = null;
                this.m_year = null;
            }
            this.lookupApiService.getPayCyles(id, this.employeeId)
                .then(function (cycles) {
                _this.origPayCycles = _.sortBy(cycles, function (item) { return moment(item.startDate).unix(); });
                _this.payCyclesLoaded.emit(cycles);
                _this.createYearsList();
                _this.createRangesList();
                _this.selectPayCycle();
            });
        }
    };
    PayCycleDropdownComponent.prototype.createYearsList = function () {
        var yearsList = _.reduce(this.origPayCycles, function (accum, cycle) {
            var year = moment(cycle.startDate).year();
            accum[year] = new Year(year);
            return accum;
        }, {});
        this.yearList = _.reverse(_.values(yearsList));
    };
    PayCycleDropdownComponent.prototype.createRangesList = function () {
        var rangeList = _.reduce(this.origPayCycles, function (accum, cycle) {
            accum[cycle.description] = new Range(cycle.description, moment(cycle.startDate).year(), cycle);
            return accum;
        }, {});
        this.origRangeList = _.reverse(_.values(rangeList));
    };
    PayCycleDropdownComponent.prototype.filteringRanges = function (year) {
        return _.filter(this.origRangeList, function (range) { return year.year === range.year; });
    };
    PayCycleDropdownComponent.prototype.selectPayCycle = function () {
        if (!_.isArray(this.yearList) || !_.isArray(this.origRangeList))
            return;
        var sDate = _.get(this.selectedPayCycle, 'startDate');
        var eDate = _.get(this.selectedPayCycle, 'endDate');
        if (_.isDate(sDate) && _.isDate(eDate)) {
            var startDate_1 = moment(sDate);
            var endDate_1 = moment(eDate);
            var year = _.find(this.yearList, { year: sDate.getFullYear() });
            var currentRange = null;
            if (year) {
                var ranges = this.filteringRanges(year);
                currentRange = _.find(ranges, function (r) { return startDate_1.isSame(r.payCycle.startDate) && endDate_1.isSame(r.payCycle.endDate); });
            }
            else {
                year = _.head(this.yearList);
            }
            this.setupPayCycle(year, currentRange);
        }
        else if (this.isSetDefaultPayCycle) {
            var currentYear = _.head(this.yearList);
            this.setupPayCycle(currentYear, null);
        }
    };
    PayCycleDropdownComponent.prototype.setupPayCycle = function (year, currentRange) {
        if (!year) {
            this.rangeList = [];
        }
        if (!this.m_year || this.m_year !== year || !this.currentRange || this.currentRange !== currentRange) {
            this.m_year = year;
            var ranges = this.filteringRanges(year);
            this.rangeList = ranges;
            var setFirstRange = _.isNil(currentRange) || !this.isCurrentRangeIsAvailableInPaycycleRange(this.rangeList, currentRange);
            if (setFirstRange) {
                this.currentRange = _.first(this.rangeList);
            }
            else {
                this.currentRange = currentRange;
            }
        }
    };
    PayCycleDropdownComponent.prototype.isCurrentRangeIsAvailableInPaycycleRange = function (rangeList, currentRange) {
        var result = false;
        if (rangeList && rangeList instanceof Array && rangeList.length > 0 && currentRange) {
            result = rangeList.indexOf(currentRange) > -1;
        }
        return result;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PayCycleDropdownComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCycleDropdownComponent.prototype, "orgLevelSubscription", void 0);
    return PayCycleDropdownComponent;
}());
export { PayCycleDropdownComponent };
