import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { dateTimeUtils } from '../utils/index';
import * as moment from 'moment';
@Pipe({
  name: 'slxTimeFromNow',
  pure: true
})
@Injectable()
export class TimeFromNowPipe implements PipeTransform {
  public transform(date: Date): string {
    return moment.duration(moment(date).diff(new Date())).humanize();
  }
}
