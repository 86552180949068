import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { createValuAccessor } from '../../../common/utils/index';
import { CalendarViewTypes, CalendarViewTypesDefinitions } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-static-calendar',
  templateUrl: 'static-calendar.component.html',
  providers: [createValuAccessor(StaticCalendarComponent)]
})
export class StaticCalendarComponent implements ControlValueAccessor {
  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  @Input()
  public readonly: boolean;

  @Input()
  public viewType: CalendarViewTypesDefinitions;

  @Input()
  public set calendarCellClass(value: string | Function) {
    if (_.isString(value)) {
      this.getCalendarCellClass = () => value;
    }

    if (_.isFunction(value)) {
      this.getCalendarCellClass = (date: Date) => value(date);
    }
  }

  public getCalendarCellClass: (date: Date) => string;
  private m_currentDate: Date;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  constructor() {
    this.viewType = CalendarViewTypes.month;
    this.getCalendarCellClass = () => '';
  }

  public get currentDate(): Date {
    return this.m_currentDate;
  }

  public set currentDate(value: Date) {
    if (_.isDate(value) && !moment(this.m_currentDate).isSame(value)) {
      this.m_currentDate = value;
      this.onChangeCallback(this.m_currentDate);
    }
  }

  public writeValue(value: Date): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.currentDate = value;
    }
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
