import { IBenefitDetailsPayrollCreateMapping, BenefitDetailsPayrollCreateMapping } from './benefit-details-payroll-create-mapping';

export interface IBenefitPayrollCreateMapping {
  mapping: IBenefitDetailsPayrollCreateMapping[];
  ignorePayrollDeductions: number[];
  resetIgnorePayrollDeductions: number[];
}

export class BenefitPayrollCreateMapping {
  public mapping: BenefitDetailsPayrollCreateMapping[] = [];
  public ignorePayrollDeductions: number[] = [];
  public resetIgnorePayrollDeductions: number[] = [];
}
