import { IEmployeeDailyPunchesHeader, EmployeeDailyPunchesHeader, DailyLinePunchActions } from './index';

export interface IEmployeesPunchesHeaderContainer {
  status: string;
  startDate: string;
  endDate: string;
  orgLevelId: number;
  entities: IEmployeeDailyPunchesHeader[];
}

export class EmployeesPunchesHeaderContainer {
  status: string;
  startDate: Date;
  endDate: Date;
  orgLevelId: number;
  entities: EmployeeDailyPunchesHeader[];
  actions: DailyLinePunchActions;
}
