import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../app.config';
import { ConfirmDialog2Component, ConfirmOptions, KendoGridStateHelper, ModalService } from '../../../../../common/index';
import { EmployeeSubsectionWarnings } from '../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../services/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
var EmployeeSectionsWarningsComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsComponent(manService, modalService, changeDetector, decorator) {
        this.manService = manService;
        this.modalService = modalService;
        this.changeDetector = changeDetector;
        this.decorator = decorator;
        this.gridState = new KendoGridStateHelper();
        this.warnings = [];
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.pageSize = 10;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'issuedDate', dir: 'desc' }];
        this.gridState.state.take = this.pageSize;
        this.decorator.isEditableByConfiguration = false;
    }
    Object.defineProperty(EmployeeSectionsWarningsComponent.prototype, "employeeSectionsWarnings", {
        set: function (v) {
            this.assignData(v, null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWarningsComponent.prototype, "employeeId", {
        set: function (v) {
            this.assignData(null, v);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWarningsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWarningsComponent.prototype, "canAddWarning", {
        get: function () {
            return this.canAdd;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWarningsComponent.prototype, "canEditWarning", {
        get: function () {
            return this.canEdit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsWarningsComponent.prototype, "canDeleteWarning", {
        get: function () {
            return this.canDelete;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsWarningsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init(this.empId);
        this.subscriptions.loadedWarnings = this.manService.subscribeToLoadedWarnings(function (warnings) {
            _this.warnings = warnings;
            _this.updateGrid();
        });
        this.subscriptions.reloadWarnings = this.manService.subscribeToReloadWarnings(function () {
            _this.manService.getWarnings();
        });
        this.subscriptions.spinner = this.manService.subscribeToSpinner(function (isShown) {
            _this.isLoading = isShown;
            _this.checkChanges();
        });
        this.subscriptions.refreshGrid = this.gridState.onRefreshGrid.subscribe(function () {
            _this.updateGrid();
        });
    };
    EmployeeSectionsWarningsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsWarningsComponent.prototype.onDownload = function (warningBasic) {
        this.manService.downloadWarning(warningBasic.id);
    };
    EmployeeSectionsWarningsComponent.prototype.onSaveWarning = function (warning) {
        if (_.isFinite(warning.id) && warning.id > 0) {
            this.manService.editWarning(warning);
        }
        else {
            this.manService.addWarning(warning);
        }
    };
    EmployeeSectionsWarningsComponent.prototype.onRemoveWarning = function (warning) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Delete Warning', 'Are you sure that you want to remove this warning?', this.modalService, function (result) {
            if (result) {
                _this.manService.deleteWarning(warning);
            }
        }, options);
    };
    EmployeeSectionsWarningsComponent.prototype.assignData = function (subsection, empId) {
        if (_.isObjectLike(subsection) && _.isArray(subsection.warnings)) {
            this.warnings = subsection.warnings;
            this.canAdd = subsection.actions.canAdd;
            this.canEdit = subsection.actions.canEdit;
            this.canDelete = subsection.actions.canDelete;
            this.updateGrid();
        }
        if (_.isFinite(empId)) {
            this.empId = empId;
        }
    };
    EmployeeSectionsWarningsComponent.prototype.updateGrid = function () {
        this.refreshGrid();
        this.checkChanges();
    };
    EmployeeSectionsWarningsComponent.prototype.checkChanges = function () {
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsWarningsComponent.prototype.refreshGrid = function () {
        if (!this.warnings) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.warnings, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsWarningsComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsWarningsComponent;
}());
export { EmployeeSectionsWarningsComponent };
