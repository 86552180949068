import { IPayloadAction } from '../../../state-model/models/index';
import { TimecardsActions } from './timecards.actions';
import { IColumnSettings } from '../../../core/models/index';
import { Map } from 'immutable';
import * as _ from 'lodash';
import {
  INITIAL_STATE_TIMECARDS_DISPLAY_STATE, INITIAL_STATE_INDIVIDUAL_TIMECARDS_STATE, INITIAL_STATE_TIMECARDS_STATE
} from './timecards.initial-state';
import { TimecardsState, IndividualTimecardsState } from '../../models/index';

import { ITimecardsDisplayState, IIndividualTimecardsState, ITimecardsState } from './timecards.types';

export function timecardsDisplayStateReducer(state: Map<string, any> = INITIAL_STATE_TIMECARDS_DISPLAY_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_SETTINGS:
      let settings: TimecardsState = action.payload;
      let ds: any = {
        isShowPayRates: settings.isShowPayRates,
        isShowHighPrecision: settings.isShowHighPrecision,
        flatMode: settings.flatMode,
        lastViewedEmployee: settings.lastViewedEmployee,
        lastViewedPage: settings.lastViewedPage,
        lastSelectedEntries: settings.lastSelectedEntries
      };
      return state.merge(ds);
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_PAYCYCLE:
      let st: any = {
        payCycle: action.payload,
      };
      return state.merge(st);
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE:
      return state.merge({ payCycle: null });
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_ORGLEVEL:
      return state.merge({
        orgLevel: action.payload,
      });
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_ORGLEVEL:
      return state.merge({ orgLevel: null });
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_GRIDSTATE:
      return state.merge({
        gridState: action.payload,
      });
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_GRIDSTATE:
        return state.merge({ gridState: null });
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS:
      return state.merge({
        lastViewedEmployee: 0,
        lastViewedPage: 0,
        lastSelectedEntries: []
      });
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_SELECTED_EMPLOYEE:
      return state.merge({
        selectedEmployeeId: action.payload,
      });
    default:
      return state;
  }
}

export function individualTimecardsStateReducer(state: Map<string, any> = INITIAL_STATE_INDIVIDUAL_TIMECARDS_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case TimecardsActions.INDIVIDUAL_TIMECARDS_CHANGE_SETTINGS:
    const settings: IndividualTimecardsState = action.payload;
    const ds: any = {
      isShowPayRates: settings.isShowPayRates,
      isShowHighPrecision: settings.isShowHighPrecision,
      empColumnStates: _.mapValues(settings.empColumns.columnsMap, (col: IColumnSettings) => { return col.displayed; }),
      payCodeColumnStates: {}
    };
    if (settings.payCodeColumns) {
      ds.payCodeColumnStates = _.mapValues(settings.payCodeColumns.columnsMap, (col: IColumnSettings) => { return col.displayed; });
    }

    return state.merge(ds);
    case TimecardsActions.INDIVIDUAL_TIMECARDS_SET_SCROLL_POSITION:
      if (action.payload.flatMode) {
        return state.setIn(['extendedScrollPosition', action.payload.orgLevelId], { top: action.payload.top, left: action.payload.left });
      } else {
        return state.setIn(['standardScrollPosition', action.payload.orgLevelId], { top: action.payload.top, left: action.payload.left });
      }
    default:
      return state;
  }
}

export function timecardsStateReducer(state: Map<string, any> = INITIAL_STATE_TIMECARDS_STATE, action: IPayloadAction): Map<string, any> {
  switch (action.type) {
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_SETTINGS:
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_PAYCYCLE:
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE:
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_ORGLEVEL:
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_ORGLEVEL:
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_GRIDSTATE:
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_GRIDSTATE:
    case TimecardsActions.TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS:
    case TimecardsActions.TIMECARDS_DISPLAY_CHANGE_SELECTED_EMPLOYEE:
      let timecardsDisplayState: any = {
        timecardsDisplay: timecardsDisplayStateReducer(state.get('timecardsDisplay'), action)
      };
      return state.merge(timecardsDisplayState);

    case TimecardsActions.INDIVIDUAL_TIMECARDS_CHANGE_SETTINGS:
    case TimecardsActions.INDIVIDUAL_TIMECARDS_SET_SCROLL_POSITION:
      let individualTimecardsState: any = {
        individualTimecards: individualTimecardsStateReducer(state.get('individualTimecards'), action)
      };
      return state.merge(individualTimecardsState);
    default:
      return state;
  }
}
