import * as tslib_1 from "tslib";
import { TimeclockAssignmentRestriction } from '../timeclock-assignment-restriction';
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionTimeclock = /** @class */ (function () {
    function EmployeeSectionTimeclock() {
    }
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "id", {
        get: function () {
            return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.id : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "timeclockName", {
        get: function () {
            return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.name : '-';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "restrictionName", {
        get: function () {
            return this.assignmentRestriction && this.assignmentRestriction.restriction ? this.assignmentRestriction.restriction.name : '-';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "organizationName", {
        get: function () {
            return this.organization ? this.organization.name : '-';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "departmentName", {
        get: function () {
            return this.department ? this.department.name : '-';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "physicalId", {
        get: function () {
            return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.physicalId : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "clockOrganization", {
        get: function () {
            return this.assignmentRestriction && this.assignmentRestriction.timeclock ? this.assignmentRestriction.timeclock.clockOrganization : '-';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "restriction", {
        get: function () {
            return this.assignmentRestriction.restriction;
        },
        set: function (value) {
            if (!this.assignmentRestriction)
                this.assignmentRestriction = new TimeclockAssignmentRestriction();
            this.assignmentRestriction.restriction = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "timeclock", {
        get: function () {
            return this.assignmentRestriction.timeclock;
        },
        set: function (value) {
            if (!this.assignmentRestriction)
                this.assignmentRestriction = new TimeclockAssignmentRestriction();
            this.assignmentRestriction.timeclock = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionTimeclock.prototype, "lastUpdateRestriction", {
        get: function () {
            return this.assignmentRestriction ? this.assignmentRestriction.lastUpdateDate : null;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSectionTimeclock;
}());
export { EmployeeSectionTimeclock };
var EmployeeSectionTimeclockContainer = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionTimeclockContainer, _super);
    function EmployeeSectionTimeclockContainer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.multiPositionPunch = false;
        _this.isPositionsMultiPunchEnabled = false;
        return _this;
    }
    return EmployeeSectionTimeclockContainer;
}(EmployeeSectionsBase));
export { EmployeeSectionTimeclockContainer };
