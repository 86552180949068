<div class="modal-content">
    <div class="modal-body">
        <div class="flex-horizontal filter-panel">
            <div class="date-filter">
            <slx-date-navigator format="MM/dd/yyyy" [(ngModel)]="dateOn" (ngModelChange)="loadData()"></slx-date-navigator>
            </div>
            <slx-check-box class="show-all-filter" caption="Show All" [(ngModel)]="showAll" (ngModelChange)="refreshGrid()">
            </slx-check-box>
            <slx-action-list>
                <slx-action-button [iconName]="'fas fa-upload'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Export
                </slx-action-button>
                <popper-content #popperContent>
                    <slx-action-list-item (onClick)="onExportToExcel()">
                    Export to Excel
                    </slx-action-list-item>
                    <slx-action-list-item (onClick)="onExportToPdf()">
                    Export to PDF
                    </slx-action-list-item>
                </popper-content>
            </slx-action-list>
        </div>
        <div class="table-container">
            <kendo-grid #kendoGrid
                class="slx-blue-grid slx-grid-slim-rows slx-full-height"
                [data]="gridState.view"
                [sortable]="{ mode: 'multiple' }"
                [sort]="gridState.state.sort"
                [filter]="gridState.state.filter"
                (dataStateChange)="gridState.dataStateChange($event)"
                >

                <kendo-grid-column title="Position" field="positionName">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="header-column">Budgeted Position</div> 
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem?.positionName}}
                    </ng-template>
                    <ng-template kendoGridFooterTemplate>
                        <div class="total-text">Totals</div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column-group title="Hours" [locked]="false">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="header-column">Hours</div>
                    </ng-template>
                    <kendo-grid-column title="Scheduled" field="scheduledHours">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="header-column">Scheduled</div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="numeric-field">{{dataItem?.scheduledHours}}</div>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate>
                            <div class="total-text numeric-field">{{totalScheduled}}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column title="Budgeted" field="budgetedHours">
                        <ng-template kendoGridHeaderTemplate>
                            <div class="header-column">Budgeted</div>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-dataItem>
                            <div class="numeric-field">{{dataItem?.budgetedHours}}</div>
                        </ng-template>
                        <ng-template kendoGridFooterTemplate>
                            <div class="total-text numeric-field">{{totalBudgeted}}</div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid-column-group>
                <kendo-grid-column title="Discrepency" field="discrepency">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="header-column">Discrepency</div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="total-text numeric-field" 
                            [ngClass]="{'positive': (dataItem?.discrepency > 0), 'negative': dataItem?.discrepency < 0}">
                            {{dataItem?.discrepency}}</div>
                    </ng-template>
                    <ng-template kendoGridFooterTemplate>
                        <div class="total-text numeric-field" 
                            [ngClass]="{'positive': (totalDiscrepency > 0), 'negative': totalDiscrepency < 0}">
                            {{totalDiscrepency}}</div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="Comments" field="comment">
                    <ng-template kendoGridHeaderTemplate>
                        <div class="header-column">Comments</div>
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem>
                        {{dataItem?.comment}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-excel fileName="BudgetedParsDetails.xlsx" [fetchData]="getGridData()"></kendo-grid-excel>
                <kendo-grid-pdf fileName="BudgetedParsDetails.pdf" [allPages]="true">
                  <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
                </kendo-grid-pdf>
            </kendo-grid>
        </div>
    </div>
  </div>