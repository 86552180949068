import { LocationUnit, Lookup, Position, PositionGroup, Shift } from "../../../organization";
import { DetailGroupViewSettingsTypes } from "../detail-screen";

export class DailyUnitFilterOptions {
positionGroupLookup: Lookup;
 positionLookup: Lookup;
 unitsLookup: Lookup;
 shiftsLookup: Lookup;
 selectedPositionIds: number[];
 selectedUnitIds: number[];
 selectedShiftIds: number[];
 selectedPositionGroupIds: number[];
 positionGroupFilter: PositionGroup[];
 posFilter: Position[];
 unitFilter: LocationUnit[];
 shiftFilter: Shift[];
 settingsType:DetailGroupViewSettingsTypes;
}