import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';

export interface IShiftSetting {
    shiftId:number;
    allowPartialRequest:boolean;
    allowAgencyAccess: boolean;
}

export class ShiftSetting implements IBaseSelectableModel,IShiftSetting {
    shiftId: number;
    isSelected: boolean;
    selectable: boolean;
    allowAgencyAccess: boolean = false;
    allowPartialRequest: boolean = true;
    minPartialShiftCount: number;
    isUpdated: boolean = false;
}