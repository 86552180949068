import { Component, OnInit } from '@angular/core';
import { CompositeFilterDescriptor, process } from "@progress/kendo-data-query";
import { BehaviorSubject, combineLatest, Observable, Subject } from "rxjs";
import { distinctUntilChanged, first, map, shareReplay, startWith, tap } from "rxjs/operators";
import { BusyService } from "../../../common/services";
import { NotificationsService } from '../../../core/components';
import { distinctPrimitive } from '../../models/wfm-sync';

export interface SyncItem {
  isDisabled: boolean;
  ischecked: boolean;
}

@Component({
  template: ''
})
export abstract class BaseSyncComponent<T extends SyncItem> implements OnInit {
  viewModel$: Observable<T[]>;
  isSyncDisabled$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  isCheckedAll$: Observable<boolean>;
  isCheckAllDisabled$: Observable<boolean>;
  pageSize: number = 50;
  filter$ = new BehaviorSubject<CompositeFilterDescriptor>(null);

  readonly dateFormat: string ='{0:MM/dd/yyyy}';

  protected globalFilterFunc$ = new BehaviorSubject<(item: T) => boolean>(null);
  protected selectionChanged$ = new Subject<void>();
  protected selectedItems$: Observable<T[]>;
  protected filteredItems$: Observable<T[]>;
  protected viewModel: T[];

  private data$ = new BehaviorSubject<T[]>([]);

  constructor(
    protected notificationService: NotificationsService,
    protected busyService: BusyService
  ) {}

  ngOnInit(): void {
    // Indicates if a loading operation is currently in progress
    this.isLoading$ = this.busyService.busyState$.pipe(map(busyState => busyState.isBusy));

    // Optional prefiltering of the raw data
    this.viewModel$ = combineLatest(this.data$, this.globalFilterFunc$).pipe(
      map(([data, globalFilterFunc]) => {
        if (globalFilterFunc) {
          return data.filter(item => globalFilterFunc(item));
        }

        return data;
      }),
      tap(vm => this.viewModel = vm)
    );

    // Computes the filtered items based on the grid's filter.
    this.filteredItems$ = combineLatest(this.viewModel$, this.filter$).pipe(
      map(([data, filter]) => {
        const dataResult = process(data, { filter });
        return dataResult.data;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    // Computes selected items. Changes whenever data view changes or the selection changes
    const selectionChanged$ = this.selectionChanged$.pipe(startWith(true));
    this.selectedItems$ = combineLatest(this.filteredItems$, selectionChanged$).pipe(
      map(([data]) => data.filter(item => !this.isSyncItemDisabled(item) && item.ischecked)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    // Changes whenever selection changes to enable/disable the sync button
    this.isSyncDisabled$ = this.selectedItems$.pipe(
      map(selectedItems => !selectedItems.length),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    // Changes whenever the data view or selection changes to indicate if all items are selected
    this.isCheckedAll$ = combineLatest(this.filteredItems$, this.selectedItems$).pipe(
      map(([data, selectedItems]) => {
        const enabledItems = data.filter(item => !this.isSyncItemDisabled(item));
        return enabledItems.length === selectedItems.length && !!selectedItems.length;
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    // Changes whenever the data view changes to indicate if there are any items to check
    this.isCheckAllDisabled$ = this.filteredItems$.pipe(
      map(data => {
        const enabledItems = data.filter(item => !this.isSyncItemDisabled(item));
        return !enabledItems.length;
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    )

    // Fetch initial data and set view model
    this.fetchData().then(data => this.data$.next(data));
  }

  onCheckboxChange(event: any, item: T): void {
    if (!item.isDisabled) {
      item.ischecked = event.target.checked;
      this.selectionChanged$.next();
    }
  }

  onFilterChanged(filter: CompositeFilterDescriptor): void {
    this.filter$.next(filter);
    this.clearSelectedItems();
  }

  toggleCheckAll(event: any): void {
    this.filteredItems$.pipe(first()).subscribe((data) => {
      data.forEach(item => {
        if (!this.isSyncItemDisabled(item)) {
          item.ischecked = event.target.checked;
        }
      })
    })
    this.selectionChanged$.next();
  }

  onRefreshClicked(): void {
    this.selectedItems$.pipe(first()).subscribe(async (selectedItems) => {
      const data = await this.fetchData();

      // Apply previous selection to the new data
      this.applySelection(selectedItems, data);

      this.data$.next(data);
    });
  }

  syncData(): void {
    this.selectedItems$.pipe(first()).subscribe(async (selectedItems) => {
      if (!!selectedItems.length) {
        await this.syncDataCore(selectedItems);

        // Clear all selected items
        this.clearSelectedItems();

        // Refresh data
        const data = await this.fetchData();
        this.data$.next(data);

        // Display confirmation to user
        this.notificationService.success(`Successfully Submitted ${selectedItems.length} records.`);
      }
    });
  }

  distinctPrimitive(fieldName: string, isNumeric: boolean): unknown[] {
    let data = [];
    data = distinctPrimitive(fieldName, isNumeric, this.viewModel);
    return data;
  }

  clearSelectedItems(): void {
    this.viewModel$.pipe(first()).subscribe((data) => {
      data.forEach(item => item.ischecked = false);
      this.selectionChanged$.next();
    })
  }

  protected applySelection(selectedItems: T[], data: T[]): void {
    if (!!selectedItems.length) {
      // Build a hashmap of the new data for quick lookup
      const keyMap = data
        .map(item => ({ key: this.getSyncItemKey(item), item }))
        .reduce((prev, curr) => {
          prev[curr.key] = curr.item;
          return prev;
        }, {} as Record<number, T>);

        // Restore selected items in the new data
        selectedItems.forEach(selectedItem => {
        const newItem = keyMap[this.getSyncItemKey(selectedItem)];
        if (newItem && !this.isSyncItemDisabled(newItem)) {
          newItem.ischecked = true;
        }
      });
    }
  }

  /**
   * Optionally overridable to dynamically control when an item is disabled.
   * By default returns the value of SyncItem.isDisabled
   * @param item
   */
  protected isSyncItemDisabled(item: T): boolean {
    return item.isDisabled;
  }

  /**
   * Optional pre filter for grid data. Return false for items that should not be displayed
   * @param item the item to filter
   */
  protected preFilterSyncItem(item: T): boolean {
    return true;
  }

  protected abstract getSyncItemKey(item: T): string;

  protected abstract fetchData(): Promise<T[]>;

  protected abstract syncDataCore(selectedItems: T[]): Promise<any>;
}
