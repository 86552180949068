import { Component, EventEmitter, Output, Input, Provider, OnInit } from '@angular/core';
import { DetailGroupViewSettingsTypes } from '../../../models/index';
import { DialogOptions, IDialog, ModalService } from '../../../../common';
import { ScheduleEntryManagementService } from '../../../../scheduler/services';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { Observable, Subscription } from 'rxjs';
import { scheduleMicrotask } from '../../../../core/utils';
import { DailyUnitFilterOptions } from '../../../../scheduler/models/daily-unit-assignment/daily-unit-filter-options';

@Component({
  selector: 'slx-daily-unit-view-settings-popup',
  templateUrl: './daily-unit-view-settings-popup.component.html',
  styleUrls: ['./daily-unit-view-settings-popup.component.scss']
})
export class DailyUnitViewSettingsPopupComponent implements IDialog {
  public dialogResult: boolean
  public currentViewMode: DetailGroupViewSettingsTypes;

  @mutableSelect(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @unsubscribe()
  private isLeftSidebarOpenSubscripion: Subscription;
  public isLeftSideNavbarOpen: boolean = false;


  constructor(public modalService: ModalService, public scheduleManService: ScheduleEntryManagementService) {
    this.currentViewMode = this.scheduleManService.duaOptions.settingsType;
    this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe((value: boolean) => {
      scheduleMicrotask(() => { this.isLeftSideNavbarOpen = value; });
    });
  }
  public onApply(): void {
    this.dialogResult = true;
    this.modalService.closeAllWindows();
    // this.close.emit(this.settingsType);
  }


  public static openDialog(options: DailyUnitFilterOptions, modalService: ModalService, callback: (result: boolean) => void): DailyUnitViewSettingsPopupComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 300;
    dialogOptions.className = 'slx-dua-view-settings';
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let dialog: DailyUnitViewSettingsPopupComponent = modalService.globalAnchor
      .openDialog(DailyUnitViewSettingsPopupComponent, 'View Settings', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {

        callback(result);
      });
    return dialog;
  }

  public handleSettingType(event) {
    this.currentViewMode = event;
    this.scheduleManService.duaOptions.settingsType = event;
  }

}
