export interface IPayrollExportRelease {
  orgLevelId: number;
  groupName: string;
  startDate: string;
  endDate: string;
}

export class PayrollExportRelease implements IPayrollExportRelease {
  orgLevelId: number;
  groupName: string;
  startDate: string;
  endDate: string;
}
