export * from './supervisor-assignment/supervisor-assignment.component';
export * from './supervisor-group-grid/supervisor-group-grid.component';
export * from './supervisor-group-detail/supervisor-group-detail.component';
export * from './supervisor-grid/supervisor-grid.component';
export * from './eligible-supervisor-grid/eligible-supervisor-grid.component';
export * from './supervised-employee-grid/supervised-employee-grid.component';
export * from './eligible-supervised-employee-grid/eligible-supervised-employee-grid.component';
export * from './unassigned-employees-grid/unassigned-employees-grid.component';
export * from './unassigned-employees-popup/unassigned-employees-popup.component';


import { SupervisorAssignmentComponent } from './supervisor-assignment/supervisor-assignment.component';
import { SupervisorGroupGridComponent } from './supervisor-group-grid/supervisor-group-grid.component';
import { SupervisorGroupDetailComponent } from './supervisor-group-detail/supervisor-group-detail.component';
import { SupervisorGridComponent } from './supervisor-grid/supervisor-grid.component';
import { EligibleSupervisorGridComponent } from './eligible-supervisor-grid/eligible-supervisor-grid.component';
import { SupervisedEmployeeGridComponent } from './supervised-employee-grid/supervised-employee-grid.component';
import { EligibleSupervisedEmployeesGridComponent } from './eligible-supervised-employee-grid/eligible-supervised-employee-grid.component';
import { UnassignedEmployeesGridComponent } from './unassigned-employees-grid/unassigned-employees-grid.component';
import { UnassignedEmployeesPopupComponent } from './unassigned-employees-popup/unassigned-employees-popup.component';

export const supervisorAssignmentComponents: any = [
  SupervisorAssignmentComponent,
  SupervisorGroupGridComponent,
  SupervisorGroupDetailComponent,
  SupervisorGridComponent,
  EligibleSupervisorGridComponent,
  SupervisedEmployeeGridComponent,
  EligibleSupervisedEmployeesGridComponent,
  UnassignedEmployeesGridComponent,
  UnassignedEmployeesPopupComponent
];
