import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsEmergencyContacts } from '../../../models/index';
import { EmployeeSectionsPersonalApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
var EmployeeSectionsEmergencyContactsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsEmergencyContactsComponent, _super);
    function EmployeeSectionsEmergencyContactsComponent(employeeSectionsPersonalApiService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsEmergencyContactsComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsEmergencyContactsComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsEmergencyContacts;
    };
    EmployeeSectionsEmergencyContactsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.getPersonalEmergencyContacts(this.employeeId)
            .then(function (employeeSectionsEmergencyContacts) {
            _this.employeeSectionsEmergencyContacts = employeeSectionsEmergencyContacts;
            _this.stopProgress();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsEmergencyContactsComponent.prototype.doSave = function () {
        var _this = this;
        this.employeeSectionsPersonalApiService.setPersonalEmergencyContacts(this.employeeId, this.employeeSectionsEmergencyContacts)
            .then(function (status) {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsEmergencyContactsComponent.prototype.checkTemporalDirty = function () {
        return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsEmergencyContacts);
    };
    return EmployeeSectionsEmergencyContactsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsEmergencyContactsComponent };
