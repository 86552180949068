<div class="modal-content">
  <div class="modal-body">
    <slx-spinner [show]="state.isLoading">
      <slx-employee-transfer #employeeTransfer [employee]="employee"></slx-employee-transfer>
    </slx-spinner>
    <div>
    </div>
  </div>
  <div class="modal-footer">
    <button 
      type="button" (click)="onOk()" 
      [disabled]="!isEmployeeTransferFormValid||!effectiveDate?.valid" 
      class="theme-button-apply dialog-button">OK</button>

    <div class="date-selector" *ngIf="requestToTerminate">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input date #effectiveDate="ngModel" [(ngModel)]="requestToTerminate.date" [minDate]="employeeShortInfo?.effectiveEndDate"
          [required]="true" placeholder="Last day in current department" name="date"
          [acceptNullDate]="false"
          >
        </slx-date-picker-ngx>
        <span errorMessage for="required"></span>
        <span errorMessage for="date"></span>
        <span errorMessage for="minDate">Field cannot be less than last approved pay cycle {{employeeShortInfo?.effectiveEndDate | amDateFormat: appConfig.dateFormat
          }}</span>
      </slx-input-decorator>
    </div>
    <button class="col-xs-" type="button" (click)="onCancel()" class="theme-button-cancel dialog-button">Cancel</button>
  </div>
</div>
