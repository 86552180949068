import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { FormBuilder, NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../../app/app.config';
import { ModalService, ModalAnchorDirective } from '../../../../../../common/index';
import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { ConfirmOptions, ConfirmDialog2Component } from './../../../../../../common/components/index';
import { BenefitEmployeesApiService, BenefitEnrollmentCommonService } from '../../../../services/index';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';
import { BenefitEnrolledEmployeePreview } from '../../../../models/benefit-employees/benefit-enrolled-employee-preview';
var BenefitOptionFormulaWithOptionGridComponent = /** @class */ (function () {
    function BenefitOptionFormulaWithOptionGridComponent(formBuilder, manService, cdr, modalService, apiService, commonValidationService) {
        this.formBuilder = formBuilder;
        this.manService = manService;
        this.cdr = cdr;
        this.modalService = modalService;
        this.apiService = apiService;
        this.commonValidationService = commonValidationService;
        this.columnGroup = 'BenefitOptionFormula';
        this.data = [];
        this.isLoading = true;
        this.isCoverageEditable = true;
        this.minFormulaAmt = -1;
        this.minAmt = 0;
        this.maxAmt = 99999999.99;
        this.employeeContributionDefined = true;
        this.employerContributionDefined = true;
        this.coverageDefined = true;
        this.employeeContribution = null;
        this.employerContribution = null;
        this.coverage = null;
        this.defaultMultiplierValue = 500;
        this.defaultMultiplierMaxCap = 10000;
        this.stepcurrency = 0.01;
        this.pageSize = 50;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.appConfig = appConfig;
        this.onEnrollmentCancel = new EventEmitter();
    }
    BenefitOptionFormulaWithOptionGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, function () { return _this.orgLevel; }, function (orgLevel) { return _this.reload(orgLevel); });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.populateValues = function () {
        this.populateCoverageOptions();
        this.populateEmployeeContribution();
        this.populateEmployerContribution();
        this.populateCoverage();
        this.validatePrerequisites();
        if (this.benefitOptionData && this.benefitOptionData.length && this.coverageDefined && this.employeeContributionDefined && this.employerContributionDefined) {
            this.showZeroCoverageWarning();
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.ngOnDestroy = function () {
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.ngAfterViewInit = function () {
        this.cdr.detectChanges();
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.onOptionValueChange = function (dataItem, rowIndex, coverageHasError) {
        if (_.isObjectLike(dataItem.optionCode) && dataItem.benefitTierOptionId !== dataItem.optionCode.id) {
            dataItem.benefitTierOptionId = dataItem.optionCode.id;
            dataItem.code = dataItem.optionCode.code;
            dataItem.optionRateCode = dataItem.optionCode.code;
            //In case of Coverage Formula - when selecting the Option make sure the required field errors are cleared (as the coverage going to be assumed as 0).
            //This is to ensure form validation is successful immediately after selecting the option
            if (this.tierDetails.coverageFormula.isFormulaCalcType && (!dataItem.coverage || coverageHasError)) {
                dataItem.coverage = 0;
                dataItem.calculatedCoverage = 0;
            }
            this.evaluateFormula(dataItem);
            this.updateRowsData();
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.populateCoverageOptions = function () {
        if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
            var multiplier = this.tierDetails.coverageFormula.multipler;
            var maxCap = this.tierDetails.coverageFormula.maxCap;
            multiplier = _.isNumber(multiplier) ? multiplier : this.defaultMultiplierValue;
            maxCap = _.isNumber(maxCap) ? maxCap : this.defaultMultiplierMaxCap;
            var range = _.range(multiplier, maxCap + multiplier, multiplier);
            var opts = _.map(range, function (value) { return ({ name: _.toString(value), value: value }); });
            this.coverageMultipllierOptions = opts;
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.onChangeCoverageWithMultiplier = function (value, dataItem, rowIndex) {
        var newValue = _.isObjectLike(value) && _.isFinite(value.value) ? value.value : null;
        var hasValueChanged = dataItem.coverage != newValue;
        dataItem.coverage = newValue;
        if (hasValueChanged) {
            this.evaluateFormula(dataItem);
        }
        else {
            this.updateRowsData();
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.onCoverageAnyValueChange = function (value, dataItem, rowIndex) {
        this.evaluateFormula(dataItem);
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.onChangeCoverageWithFormula = function (value, dataItem, rowIndex, errorExists) {
        if (!errorExists && dataItem.calculatedCoverage != value) {
            dataItem.coverage = value;
            dataItem.calculatedCoverage = value;
            this.evaluateFormula(dataItem);
        }
        else {
            this.updateRowsData();
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.evaluateFormula = function (dataItem) {
        var _this = this;
        this.manService.benefitsOptionTabDetailsChanged(true);
        if (this.tierDetails.coverageFormula.isFormulaCalcType ||
            this.tierDetails.erFormula.isFormulaCalcType ||
            this.tierDetails.empFormula.isFormulaCalcType) {
            if (!dataItem.code
                || (this.tierDetails.coverageFormula.isFixedCalcType && !this.coverage)
                || ((this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType || this.tierDetails.coverageFormula.isMultiplierCalcType)
                    && !_.isFinite(dataItem.coverage))) {
                return;
            }
            dataItem.coverage = dataItem.coverage ? dataItem.coverage : 0;
            this.isLoading = true;
            var coverageToEvaluate = this.tierDetails.coverageFormula.isFixedCalcType ? this.coverage : dataItem.coverage;
            this.apiService.getBenefitEnrolledPreviewEmployeesWithOptionCode(this.orgLevel.id, this.tierDetails.id, this.date, coverageToEvaluate, dataItem.code, [dataItem])
                .then(function (records) {
                var employeeMap = new Map(records.map(function (employee) { return [employee.employeeId, employee]; }));
                _this.data = _.map(_this.selectedEmployees, function (employee) {
                    var record = employeeMap.get(employee.employeeId);
                    if (record) {
                        dataItem.employeeContributionAmount = record.employeeContributionAmount;
                        dataItem.employerContributionAmount = record.employerContributionAmount;
                        if (_this.tierDetails.coverageFormula.isFormulaCalcType) {
                            dataItem.calculatedCoverage = record.coverage;
                        }
                        else {
                            dataItem.coverage = record.coverage;
                        }
                        _this.validateFormulaEvaluation(dataItem);
                    }
                    return employee;
                });
            }).finally(function () {
                _this.isLoading = false;
                _this.updateRowsData();
            });
        }
        else {
            this.updateRowsData();
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.populateEmployeeContribution = function () {
        if (this.tierDetails && this.tierDetails.empFormula) {
            if (this.tierDetails.empFormula.isFixedCalcType && _.isNumber(this.tierDetails.empFormula.fixedAmount)) {
                this.employeeContributionDefined = true;
                this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
            }
            else if (this.tierDetails.empFormula.isFormulaCalcType) {
                if (!this.tierDetails.empFormula.expression) {
                    this.employeeContributionDefined = false;
                }
            }
            else {
                this.employeeContributionDefined = false;
            }
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.populateEmployerContribution = function () {
        if (this.tierDetails && this.tierDetails.erFormula) {
            if (this.tierDetails.erFormula.isFixedCalcType && _.isNumber(this.tierDetails.erFormula.fixedAmount)) {
                this.employerContributionDefined = true;
                this.employerContribution = this.tierDetails.erFormula.fixedAmount;
            }
            else if (this.tierDetails.erFormula.isFormulaCalcType) {
                if (!this.tierDetails.erFormula.expression) {
                    this.employerContributionDefined = false;
                }
            }
            else {
                this.employerContributionDefined = false;
            }
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.populateCoverage = function () {
        if (this.tierDetails && this.tierDetails.coverageFormula) {
            if (this.tierDetails.coverageFormula.isFixedCalcType && _.isNumber(this.tierDetails.coverageFormula.fixedAmount)) {
                this.coverageDefined = true;
                this.coverage = this.tierDetails.coverageFormula.fixedAmount;
            }
            else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
                if (!this.tierDetails.coverageFormula.expression) {
                    this.coverageDefined = false;
                }
            }
            else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
                this.coverageDefined = true;
                this.coverage = 0;
            }
            else if (this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType) {
                this.coverageDefined = true;
                this.coverage = 0;
            }
            else {
                this.coverageDefined = false;
            }
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.updateRowsData = function () {
        var _this = this;
        this.manService.updateEmployeesUpdatedInfo(this.data);
        var invalidRecordExists = _.find(this.data, function (item) {
            if (!item.benefitTierOptionId ||
                (_this.tierDetails.coverageFormula.isFormulaCalcType && item.calculatedCoverage < 0) ||
                (_this.tierDetails.coverageFormula.isFixedCalcType && _this.coverage < 0) ||
                (!(_this.tierDetails.coverageFormula.isFormulaCalcType || _this.tierDetails.coverageFormula.isFixedCalcType) && item.coverage < 0) ||
                (_this.tierDetails.empFormula.isFixedCalcType && _this.employeeContribution < 0) ||
                (!_this.tierDetails.empFormula.isFixedCalcType && item.employeeContributionAmount < 0) ||
                (_this.tierDetails.erFormula.isFixedCalcType && _this.employerContribution < 0) ||
                (!_this.tierDetails.erFormula.isFixedCalcType && item.employerContributionAmount < 0)) {
                return true;
            }
        });
        this.manService.benefitsOptionTabDetailsChanged(!!invalidRecordExists || this.form.invalid);
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.validatePrerequisites = function () {
        var _this = this;
        var message = '';
        if (!this.benefitOptionData || this.benefitOptionData.length === 0) {
            message = 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.';
        }
        else {
            message = 'Employees cannot be enrolled as ';
            if (!this.employeeContributionDefined && !this.employerContributionDefined && !this.coverageDefined) {
                message += 'the Employee and Employer Contribution and Coverage amounts have';
            }
            else if (!this.employeeContributionDefined && !this.employerContributionDefined) {
                message += 'the Employee and Employer Contribution amounts have';
            }
            else if (!this.employeeContributionDefined && !this.coverageDefined) {
                message += 'the Employee Contribution and Coverage amounts have';
            }
            else if (!this.employerContributionDefined && !this.coverageDefined) {
                message += 'the Employer Contribution and Coverage amounts have';
            }
            else if (!this.employeeContributionDefined) {
                message += 'the Employee Contribution amount has';
            }
            else if (!this.employerContributionDefined) {
                message += 'the Employer Contribution amount has';
            }
            else if (!this.coverageDefined) {
                message += 'the Coverage amount has';
            }
            else {
                return;
            }
            message += ' not been created for this benefit plan. Please add contribution and Coverage amounts for this plan to enroll employees.';
        }
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            _this.onEnrollmentCancel.emit();
        }, options);
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.showZeroCoverageWarning = function () {
        var message = '';
        if (this.coverage === 0) {
            if (this.employeeContribution === 0 || this.employerContribution === 0) {
                message = 'Employees will be enrolled into zero coverage with zero Employee/Employer contributions.';
            }
            else {
                message = 'Employees will be enrolled into zero coverage.';
            }
        }
        else {
            return;
        }
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
        }, options);
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.validateFormulaEvaluation = function (dataItem) {
        var coverage = this.tierDetails.coverageFormula.isFormulaCalcType ? dataItem.calculatedCoverage : dataItem.coverage;
        var errorMessage = this.commonValidationService.getFormulaError(this.tierDetails.empFormula.isFormulaCalcType, this.tierDetails.erFormula.isFormulaCalcType, this.tierDetails.coverageFormula.isFormulaCalcType, dataItem.employeeContributionAmount, dataItem.employerContributionAmount, coverage);
        if (errorMessage && errorMessage !== '') {
            var options = new ConfirmOptions();
            options.showCancel = false;
            options.showOK = true;
            ConfirmDialog2Component.openDialog('Warning', errorMessage, this.modalService, function (result) { }, options);
        }
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.reload = function (orgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.orgLevel = orgLevel;
                this.data = this.selectedEmployees;
                this.refreshGrid();
                this.populateValues();
                this.updateRowsData();
                return [2 /*return*/];
            });
        });
    };
    BenefitOptionFormulaWithOptionGridComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.data, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitOptionFormulaWithOptionGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionFormulaWithOptionGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionFormulaWithOptionGridComponent;
}());
export { BenefitOptionFormulaWithOptionGridComponent };
