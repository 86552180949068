<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
        <div class="slx-high-box__body copy-shifts">
          <div class="l-col">
            <form #copyActionsForm="ngForm" class="form">
              <div class="from-info">
                <span class="from-field-label">Copy shifts</span>
                <span class="from-field-label">From</span>
              </div>

              <div class="control-group  dropdown-group">
                  <slx-input-decorator>
                      <input class="form-control" [readonly]="true" slx-input placeholder="Center" name="copyFromCenter" [(ngModel)]="copyFromCenter">
                    </slx-input-decorator>
              </div>

              <div class="control-group  dropdown-group">
                  <slx-input-decorator>
                      <input class="form-control" [readonly]="true" slx-input placeholder="Department" name="copyFromDepartment" [(ngModel)]="copyFromDepartment">
                    </slx-input-decorator>
              </div>

              <div class="from-info">
                <span class="from-field-label">To</span>
              </div>

              <div class="control-group  dropdown-group">
                  <slx-input-decorator>
                      <slx-dropdown-input class="form-control" slx-input placeholder="Center" name="copyToCenter" [required]="true" [options]="organizations"
                                          [(ngModel)]="copyToCenter" (ngModelChange)="onTargetOrganizationChanged()"></slx-dropdown-input>
                    </slx-input-decorator>
              </div>

              <div class="control-group  dropdown-group">
                  <slx-input-decorator>
                      <slx-dropdown-input class="form-control" slx-input placeholder="Department" [options]="copyToCenter?.departments" name="copyToDepartment"
                                          [required]="true" [(ngModel)]="copyToDepartment" (ngModelChange)="onTargetDepartmentChanged()"></slx-dropdown-input>
                  </slx-input-decorator>
              </div>
            </form>
          </div>

          <div class="r-col">
              <kendo-grid #kendoGrid class="slx-full-height"
                [data]="gridState?.view"
                [selectable]="false"
                [filterable]="true"
                [filter]="gridState.state.filter"
                (dataStateChange)="gridState.dataStateChange($event)"
                (cancel)="gridState.cancelHandler($event)"
                (edit)="gridState.editHandler($event)"
                (remove)="gridState.removeHandler($event)"
                (save)="gridState.saveHandler($event)"
              >

                  <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" width="45"  [locked]="true">
                      <ng-template kendoGridHeaderTemplate>
                          <input type="checkbox" name="allSelector" [ngModel]="selectionHelper.isAllSelected" (ngModelChange)="selectionHelper.onToggleAllSelected()">
                      </ng-template>
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                          <input *ngIf="dataItem.selectable"
                                name="selector{{rowIndex}}" type="checkbox" [(ngModel)]="dataItem.isSelected"
                                (ngModelChange)="selectionHelper.onItemSelectionChange(dataItem, rowIndex)">
                      </ng-template>
                  </kendo-grid-column>

                    <kendo-grid-column title="Shift Description" field="name" [filterable]="true" width="200">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.name }}</span>
                        </ng-template>
                        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                          </slx-kendo-grid-string-filter>
                        </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Shift Start" field="startTime" [filterable]="true" width="200" media="(min-width: 450px)">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.start | amDateFormat: appConfig.timeFormat }}</span>
                        </ng-template>
                        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                          <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
                          </slx-kendo-grid-time-filter>
                      </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Shift End" field="endTime" [filterable]="true" width="200" media="(min-width: 450px)">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.end | amDateFormat: appConfig.timeFormat }}</span>
                        </ng-template>
                        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                          <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
                          </slx-kendo-grid-time-filter>
                      </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Work Hours" field="duration" [filterable]="true" width="200" media="(min-width: 450px)">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.duration }}</span>
                        </ng-template>
                        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                          <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter" [min]="0">
                          </slx-kendo-grid-number-filter>
                      </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Lunch Duration" field="lunchDuration" [filterable]="true" width="200" media="(min-width: 450px)">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.lunchDuration }}</span>
                        </ng-template>
                        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                          <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
                          </slx-kendo-grid-number-filter>
                      </ng-template>
                    </kendo-grid-column>

                    <kendo-grid-column title="Shift Group" field="groupName" [filterable]="true" width="200" media="(min-width: 450px)">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.groupName }}</span>
                        </ng-template>
                        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                          </slx-kendo-grid-string-filter>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="isDefaultShiftEnabled" title="Default Shift" field="defaultShiftData" [filterable]="true" width="200" media="(min-width: 450px)">
                      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                          <span class="left-align" [ngClass]="{'form-field-disabled': !dataItem.selectable}">{{ dataItem.defaultShiftData }}</span>
                      </ng-template>
                      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                        </slx-kendo-grid-string-filter>
                      </ng-template>
                  </kendo-grid-column>

              </kendo-grid>
          </div>
        </div>

        <div class="actions-bar" [ngClass]="{'inactive': !state.allowEdit}">
          <div class="col md-no-line-column"></div>
          <button type="button" class="theme-button-apply copy-btn" [disabled]="!state.allowCopy" (click)="onDoCopy ()">Copy</button>
          <button type="button" class="action-button" (click)="onDiscardCopy ()">Back</button>
        </div>
    </div>
</slx-spinner>
