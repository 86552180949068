import * as tslib_1 from "tslib";
import { AttachmentFile } from '../../../../organization/models/index';
var EnrollementAttachmentFile = /** @class */ (function (_super) {
    tslib_1.__extends(EnrollementAttachmentFile, _super);
    function EnrollementAttachmentFile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EnrollementAttachmentFile;
}(AttachmentFile));
export { EnrollementAttachmentFile };
