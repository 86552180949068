<div [ngClass]="{'field-container':true, 'table-record': true}">

    <div class="configure-position-mobile">
        <p class="configure-position-mobile__emp-info">
            <span class="configure-position-mobile__emp-item"><strong>Position Group:</strong> 122 </span>
            <span class="configure-position-mobile__emp-item"><strong>Name:</strong> {{item?.name}}</span>
            <button class="edit-button" kendoGridEditCommand (click)="onEditClick ()" *ngIf="editable"></button>
            <button kendoGridRemoveCommand (click)="onDeletedItems()" *ngIf="editable" class="delete-icon"> <i
                    class="fas fa-trash " aria-hidden="true"></i></button>
        </p>
        <div class="configure-position-mobile__grid">
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Position:</label>
                <span class="configure-position-mobile__g-value">{{item?.name}}</span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Cient Dept:</label>
                <span class="configure-position-mobile__g-value">{{item?.clientDepartment}}</span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Job Class:</label>
                <span class="configure-position-mobile__g-value">{{item?.jobClass}}</span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Business Unit:</label>
                <span class="configure-position-mobile__g-value">{{item?.businessUnit}}</span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Object Account:</label>
                <span class="configure-position-mobile__g-value">{{item?.objectAccount}}</span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Group With::</label>
                <span class="configure-position-mobile__g-value">{{item?.groupedWith?.name}}</span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Budgeter Position:</label>
                <span class="configure-position-mobile__g-value">{{item.budgetedPosition?.description}}</span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">Direct Care: </label>
                <span class="configure-position-mobile__g-value"><i class="fa fa-check" aria-hidden="true"></i></span>
            </p>
            <p class="configure-position-mobile__g-item">
                <label class="configure-position-mobile__g-label">CMS - PBJ Job Category: </label>
                <span class="configure-position-mobile__g-value">{{ item.pbjCmsPosition?.name }}</span>
            </p>
        </div>
    </div>
</div>