
<div class="providers-tabs flex left">

  <div class="providers-tabs__slider">
    <ul #list class="providers-tabs__list flex flex-direction-row">
      <li class="providers-tabs__item" [class.active]="activeTab0" (click)="onClickTab(0)">
          <span class="header-title__text">Organization Sync</span>
      </li>
      <li class="providers-tabs__item" [class.active]="activeTab1" (click)="onClickTab(1)">
           <span class="header-title__text">Employee Sync</span></li>
          <li class="providers-tabs__item" [class.active]="activeTab2" (click)="onClickTab(2)">
                   <span class="header-title__text">Benefit-Deduction Sync</span>
            </li>
            <li class="providers-tabs__item" [class.active]="activeTab3" (click)="onClickTab(3)">
                <span class="header-title__text"> Cost Center Sync</span></li>
            <li class="providers-tabs__item" [class.active]="activeTab4" (click)="onClickTab(4)">
              <span class="header-title__text"> Secondary Positions Sync</span>
            </li>
     
    </ul>
  </div>

  <ng-container *ngIf="activeTab0">
    <slx-work-location-sync></slx-work-location-sync>
  </ng-container>
  <ng-container *ngIf="activeTab1">
    <slx-employee-migration></slx-employee-migration>
  </ng-container>
  <ng-container *ngIf="activeTab2">
  <slx-benefitdeduction-migration></slx-benefitdeduction-migration>
</ng-container>
<ng-container *ngIf="activeTab3">
  <slx-laborcode-migration></slx-laborcode-migration>
</ng-container>
<ng-container *ngIf="activeTab4">
  <slx-wfm-ngp-secondary-positions-sync [syncType]="type"></slx-wfm-ngp-secondary-positions-sync>
</ng-container>
</div>

