export * from './accessible-case.directive';
export * from './accessible-switch.directive';

import { AccessibleCaseDirective, NonAccessibleCaseDirective } from './accessible-case.directive';
import { AccessibleSwitchDirective } from './accessible-switch.directive';

export const accessibleSwitchDirectives: any[] = [
  AccessibleCaseDirective,
  NonAccessibleCaseDirective,
  AccessibleSwitchDirective
];

