import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'weather-location',
  styleUrls: ['weather-location.component.scss'],
  templateUrl: 'weather-location.component.html'
})
export class WeatherLocationComponent {
  @Input() place: string;
}
