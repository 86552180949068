import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { select } from '@angular-redux/store';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../framework/index';
import { OrgLevel } from '../../../state-model/models/index';
import { unsubscribe } from '../../../core/decorators/index';

import { NavigationMenuItem } from '../../../organization/models/index';
import { ApplicationStateBusService } from '../../../organization/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-loading-indicator',
  templateUrl: 'loading-indicator.component.html',
  styleUrls: ['loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
  @select('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public appBusService: ApplicationStateBusService;

  private orgLevels: OrgLevel[];
  private listMenu: NavigationMenuItem[];
  private afterRelogin: boolean;
  private hasError: boolean;
  @unsubscribe()
  private orgLevelsLoadedSubscription: Subscription;
  @unsubscribe()
  private menuLoadedSubscription: Subscription;
  @unsubscribe()
  private orgLevelIdSubscription: Subscription;
  @unsubscribe()
  private appErrorSubscription: Subscription;

  constructor(appBusService: ApplicationStateBusService) {
    this.appBusService = appBusService;
    this.orgLevels = null;
    this.listMenu = null;
    this.afterRelogin = false;
    this.hasError = false;
  }

  public ngOnInit(): void {
    this.orgLevelIdSubscription = this.appBusService.orgLevelIdSelected$.subscribe((orgLevelId: number) => {
      this.afterRelogin = _.isNull(this.orgLevels) && _.isNull(this.listMenu);
    });
    this.orgLevelsLoadedSubscription = this.appBusService.subscribeToOrgLevelsLoaded((orgLevels: OrgLevel[]) => {
      this.orgLevels = orgLevels;
    });

    this.menuLoadedSubscription = this.appBusService.subscribeToMenuLoaded((listMenu: NavigationMenuItem[]) => {
      if (this.afterRelogin) {
        this.afterRelogin = false;

        return;
      }

      this.listMenu = listMenu;
    });

    this.appErrorSubscription = this.appBusService.subscribeToAppError(() => {
      this.hasError = true;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    this.orgLevels = null;
    this.listMenu = null;
  }

  public get isShow(): boolean {
    return !this.hasError && (_.isNull(this.orgLevels) || _.isNull(this.listMenu));
  }
}
