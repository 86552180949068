import {
  EmployeeDefinition, TimeclockDefinition, TimeclockRestrictionDefinition
} from '../../../organization/models/index';

export interface ITimeclockUnAssignment {
  employeeIds: number[];
  timeclockIds: number[];
}

export interface ITimeclockAssignment {
  employeeId: number;
  timeclockRestrictions: ITimeclockRestriction[];
}

export interface ITimeclockRestriction {
  timeclockId: number;
  restrictionId: number;
}

export class TimeclockAssignment {
  public employee: EmployeeDefinition;
  public timeclockRestrictions: TimeclockRestriction[];
}

export class TimeclockRestriction {
  public timeclock: TimeclockDefinition;
  public restriction: TimeclockRestrictionDefinition;
}
