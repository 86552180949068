import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { StateResetTypes } from './../../../../../core/models/settings/index';
import { ComponentStateStorageService } from './../../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from './../../../../../common/services/state-management/state-management.service';
import { appConfig } from '../../../../../app.config';
import { PbjExportLogManagementService, MealDeductionManagementService } from '../../../services/index';
import { PbjNewExportDialog } from '../pbj-new-export-dialog/pbj-new-export-dialog.component';
import { PbjNewExportOptions, MealDeducitonsDialogOptions } from '../../../models/index';
import { mutableSelect, unsubscribe, destroyService } from '../../../../../core/decorators/index';
import { NotificationsService } from '../../../../../core/components/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../../../common/components/index';
import { appMessages } from '../../../../../app.messages';
import { MealDeductionNewConfigurationDialogComponent } from '../../meal-deduction/meal-deduction-new-configuration-dialog/meal-deduction-new-configuration-dialog.component';
import { AppSettingsManageService } from '../../../../../app-settings/services/index';
import { MealDeductionConfigurationDialogComponent } from '../../meal-deduction';
import { PbjNewExportHierarchyHelper } from '../pbj-new-export/pbj-new-export-hierarchy-helper';
var PbjLogExportComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PbjLogExportComponent, _super);
    function PbjLogExportComponent(managementService, orgLevelWatchService, stateManagement, storageService, modalService, notificationsService, mealDeducitonManagement, appSettingsManageService) {
        var _this = _super.call(this) || this;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.stateManagement = stateManagement;
        _this.storageService = storageService;
        _this.modalService = modalService;
        _this.notificationsService = notificationsService;
        _this.mealDeducitonManagement = mealDeducitonManagement;
        _this.appSettingsManageService = appSettingsManageService;
        _this.resetBy = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        _this.managementService = managementService;
        _this.state = {
            isLoading: false,
        };
        _this.datesControlKey = 'startDatePeriod';
        var today = moment().startOf('day');
        _this.endDate = today.toDate();
        _this.startDate = today.subtract(6, 'month').toDate();
        return _this;
    }
    PbjLogExportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('PbjLogExportComponent', true);
        this.appConfig = appConfig;
        this.appSettingsManageService.getAppServerConfig().then(function (config) {
            _this.IsPBJExportCorpLevelEnabled = config.IsPBJExportCorpLevelEnabled;
            _this.managementService.IsPBJExportCorpLevelEnabled = _this.IsPBJExportCorpLevelEnabled;
            _this.mealDeducionSettingsLoadSubscription = _this.mealDeducitonManagement.onLoaded$.subscribe(function (s) {
                _this.mealDeductionSettings = s;
                _this.disableNewExportBtn = false;
                _this.canGeneratePBJFromAgency = s.generatePBJFromAgency;
            });
            _this.orgLevelSubscription = _this.orgLevel$
                .combineLatest(_this.orgLevelWatchService.orgLevelTreeLoaded$, _this.stateManagement.onInit$)
                .map(function (value) { return value[0]; })
                .subscribe(function (orgLevel) {
                if (orgLevel && orgLevel.id === null)
                    return;
                if (!_this.currentOrgLevel || _this.currentOrgLevel.id !== orgLevel.id) {
                    _this.currentOrgLevel = orgLevel;
                    _this.restoreDates();
                    _this.managementService.filterRangeChanged(_this.startDate, _this.endDate);
                    _this.managementService.orgLevelChanged(_this.currentOrgLevel);
                    _this.mealDeducitonManagement.onOrglevelChanged(_this.currentOrgLevel);
                }
                _this.isAgencyOrglevel = false;
                if (_this.currentOrgLevel.organizationId == 1000) {
                    _this.isAgencyOrglevel = true;
                }
            });
            _this.exportSubscription = _this.managementService.exportPerformed$
                .subscribe(function (value) {
                _this.showConfirmDialog(value);
            });
            _this.loadStatusSubscription = _this.managementService.onLoadStatus$
                .subscribe(function (value) {
                _this.state.isLoading = value;
            });
        });
        this.mealDeducionSettingsLoadSubscription = this.mealDeducitonManagement.onLoaded$.subscribe(function (s) {
            _this.mealDeductionSettings = s;
            _this.disableNewExportBtn = false;
            _this.canGeneratePBJFromAgency = s.generatePBJFromAgency;
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
    };
    PbjLogExportComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PbjLogExportComponent.prototype.onDataRangeChange = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.filterDates = { startDate: startDate, endDate: endDate };
        this.managementService.startDate = startDate;
        this.managementService.endDate = endDate;
    };
    PbjLogExportComponent.prototype.applyFilter = function () {
        if (this.filterDates) {
            var startDate = this.filterDates.startDate, endDate = this.filterDates.endDate;
            this.saveDates(startDate, endDate);
            this.managementService.filterRangeChanged(startDate, endDate);
            this.managementService.IsPBJExportCorpLevelEnabled = this.IsPBJExportCorpLevelEnabled;
            this.managementService.applyFilter();
        }
    };
    PbjLogExportComponent.prototype.onNewExport = function () {
        var _this = this;
        var mealSettingsConfigured = this.mealDeductionSettings && !_.isNil(this.mealDeductionSettings.recordId) && this.mealDeductionSettings.recordId !== 0;
        var options = new PbjNewExportOptions(this.currentOrgLevel.name + " PBJ - XML Export", !mealSettingsConfigured, this.IsPBJExportCorpLevelEnabled);
        var dialog = PbjNewExportDialog.openDialog(options, this.modalService, function (result) {
            if (result) {
                _this.exportRequest = dialog.request;
                if (!_this.IsPBJExportCorpLevelEnabled) {
                    _this.exportRequest.orgLevelId = _this.currentOrgLevel.id;
                    _this.managementService.performExport(_this.exportRequest);
                    _this.notificationsService.success("" + appMessages.success.pbjExportStarted.title, appMessages.success.pbjExportStarted.message);
                }
                else if (_this.IsPBJExportCorpLevelEnabled) {
                    _this.exportRequest.parentId = _this.currentOrgLevel.id;
                    _this.managementService.performBatchExport(_this.exportRequest);
                }
            }
            else {
                _this.exportRequest = null;
            }
        });
    };
    PbjLogExportComponent.prototype.onConfigure = function () {
        var _this = this;
        var options = new MealDeducitonsDialogOptions('PBJ - Meal Deduction Settings', false, this.isConfigureDisabled);
        if (this.IsPBJExportCorpLevelEnabled) {
            this.mealDeducitonManagement.setSelectedIds(this.organizationIds);
            MealDeductionNewConfigurationDialogComponent.openDialog(options, this.modalService, function (result) {
                if (result) {
                    _this.disableNewExportBtn = true;
                    _this.mealDeducitonManagement.reload();
                }
                else {
                    _this.disableNewExportBtn = false;
                }
            });
        }
        else {
            MealDeductionConfigurationDialogComponent.openDialog(options, this.modalService, function (result) {
                if (result) {
                    _this.disableNewExportBtn = true;
                    _this.mealDeducitonManagement.reload();
                }
                else {
                    _this.disableNewExportBtn = false;
                }
            });
        }
    };
    PbjLogExportComponent.prototype.getConfigureTooltip = function (settings) {
        if ((settings && settings.canConfigure && (!this.isAgencyOrglevel || this.canGeneratePBJFromAgency)) || this.IsPBJExportCorpLevelEnabled) {
            return 'Configure Meal Deduction';
        }
        return 'You have no rights to Configure Meal Deduction in this organization';
    };
    PbjLogExportComponent.prototype.saveDates = function (sDate, eDate) {
        var startDate = moment(sDate).format(appConfig.dateFormat);
        var endDate = moment(eDate).format(appConfig.dateFormat);
        this.storageService.setControlState(this.stateManagement.componentKey, this.datesControlKey, { value: { startDate: startDate, endDate: endDate } }, this.resetBy);
    };
    PbjLogExportComponent.prototype.restoreDates = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
        var startDate = moment(_.get(state.value, 'startDate', null));
        var endDate = moment(_.get(state.value, 'endDate', null));
        if (startDate.isValid() && endDate.isValid()) {
            this.startDate = startDate.toDate();
            this.endDate = endDate.toDate();
        }
    };
    PbjLogExportComponent.prototype.showConfirmDialog = function (value) {
        var message;
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        if (value.isSuccess) {
            message = 'A new PBJ Export has been successfully created';
            this.managementService.applyFilter();
        }
        else {
            message = 'An error occurred during the PBJ Export. \n';
            message += value.errorMessage ?
                value.errorMessage :
                "Please review the error log for Export ID " + value.exportId + " for additional details";
        }
        ConfirmDialogComponent.openDialog('PBJ Export', message, this.modalService, function (result) {
            // TODO
        }, options);
    };
    Object.defineProperty(PbjLogExportComponent.prototype, "isConfigureDisabled", {
        get: function () {
            if (this.IsPBJExportCorpLevelEnabled)
                return false;
            if (this.mealDeductionSettings && this.mealDeductionSettings.canConfigure)
                return false;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PbjLogExportComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogExportComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogExportComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogExportComponent.prototype, "exportSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogExportComponent.prototype, "mealDeducionSettingsLoadSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PbjExportLogManagementService)
    ], PbjLogExportComponent.prototype, "managementService", void 0);
    return PbjLogExportComponent;
}(PbjNewExportHierarchyHelper));
export { PbjLogExportComponent };
