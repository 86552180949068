/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/actions-list/actions-list.component.ngfactory";
import * as i3 from "../../../common/components/actions-list/actions-list.component";
import * as i4 from "../../../common/components/actions-list/actions-button/actions-button.component.ngfactory";
import * as i5 from "../../../common/components/actions-list/actions-button/actions-button.component";
import * as i6 from "../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i7 from "ngx-popper";
import * as i8 from "../../../common/components/actions-list/actions-list-item/actions-list-item.component.ngfactory";
import * as i9 from "../../../common/components/actions-list/actions-list-item/actions-list-item.component";
import * as i10 from "./user-menu.component";
import * as i11 from "../../../authentication/services/authentication/authentication.service";
import * as i12 from "../../../common/services/change-management/change-management.service";
import * as i13 from "../../../organization/services/application-state-bus/application-state-bus.service";
var styles_UserMenuComponent = [i0.styles];
var RenderType_UserMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserMenuComponent, data: {} });
export { RenderType_UserMenuComponent as RenderType_UserMenuComponent };
export function View_UserMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "slx-user-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "div", [["class", "user-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 19, "slx-actions-list", [], null, null, null, i2.View_ActionsListComponent_0, i2.RenderType_ActionsListComponent)), i1.ɵdid(3, 49152, null, 0, i3.ActionsListComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, 1, 5, "slx-actions-button", [["className", "user-button"]], null, null, null, i4.View_ActionsButtonComponent_0, i4.RenderType_ActionsButtonComponent)), i1.ɵdid(5, 49152, null, 0, i5.ActionsButtonComponent, [], { popperContent: [0, "popperContent"], popperPosition: [1, "popperPosition"], customClassName: [2, "customClassName"], fullyCustomStyle: [3, "fullyCustomStyle"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "span", [["class", "user-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "span", [["class", "user-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-angle-down"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, 1, 11, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_PopperContent_0, i6.RenderType_PopperContent)), i1.ɵdid(11, 180224, [["popperContent", 4]], 0, i7.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "slx-actions-list-item", [["className", "user-actions-item no-hover"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ActionsListItemComponent_0, i8.RenderType_ActionsListItemComponent)), i1.ɵdid(13, 49152, null, 0, i9.ActionsListItemComponent, [i7.PopperContent], { className: [0, "className"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(14, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "far fa-power-off"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Log Out"])), (_l()(), i1.ɵeld(17, 0, null, 0, 4, "slx-actions-list-item", [["className", "user-actions-item no-hover"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.resetPassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ActionsListItemComponent_0, i8.RenderType_ActionsListItemComponent)), i1.ɵdid(18, 49152, null, 0, i9.ActionsListItemComponent, [i7.PopperContent], { className: [0, "className"] }, { onClick: "onClick" }), (_l()(), i1.ɵeld(19, 0, null, 0, 0, "i", [["aria-hidden", "true"], ["class", "far fa-key"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Change Password"])), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [["class", "user-avatar"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 11); var currVal_1 = "bottom-end"; var currVal_2 = "user-button"; var currVal_3 = true; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = "user-actions-item no-hover"; _ck(_v, 13, 0, currVal_5); var currVal_6 = "user-actions-item no-hover"; _ck(_v, 18, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.userName; _ck(_v, 7, 0, currVal_4); }); }
export function View_UserMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-user-menu", [], null, null, null, View_UserMenuComponent_0, RenderType_UserMenuComponent)), i1.ɵdid(1, 245760, null, 0, i10.UserMenuComponent, [i11.AuthenticationService, i12.ChangeManagementService, i13.ApplicationStateBusService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMenuComponentNgFactory = i1.ɵccf("slx-user-menu", i10.UserMenuComponent, View_UserMenuComponent_Host_0, {}, {}, []);
export { UserMenuComponentNgFactory as UserMenuComponentNgFactory };
