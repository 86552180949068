export interface IGoalSettingRequest {
    startDate: string;
    endDate: string;
    organizationId: number;
    numberOfStars: number;
}

export class GoalSettingRequest {
    public startDate: Date;
    public endDate: Date;
    public organizationId: number;
    public numberOfStars: number;
}

export interface IGoalSettingResponse {
    avgDailyCensus: number;
    avgDailyCensusWeekEnd:number;   
    totalNursingAvgDailyHours:number;
    reportedTotalNursingHRD:number;
    adjustedTotalNurseHRD:number;
    adjustedTotalNurseHRDPoints:number;
    rnAvgDailyHours:number;
    reportedRnHRD:number;
    adjustedRnHRD:number;
    adjustedRnHRDPoints:number;
    totalNursingAvgDailyHoursWeekEnd:number;
    reportedTotalNursingHRDWeekEnd:number;
    adjustedTotalNurseOnWeekendHRD:number;
    adjustedTotalNurseOnWeekendHRDPoints:number;
    totalNursingTurnOver:number;
    rnTurnOver:number;
    administratorDepartures:number;
    totalNursingTurnOverPoints :number;
    rnTurnOverPoints :number;
    administratorDeparturesPoints :number;
    caseMixRN:number;
    caseMixTotalNurseWeekend:number;
    caseMixTotalNurse:number;
    nationalCaseMixTotalNurse:number;
    nationalCaseMixRN:number;
    nationalCaseMixTotalNurseWeekend:number;
}

export class GoalSettingResponse {
    public avgDailyCensus: number;
    public avgDailyCensusWeekEnd:number;   
    public totalNursingAvgDailyHours:number;
    public rnAvgDailyHours:number;
    public totalNursingAvgDailyHoursWeekEnd:number;
    public totalNursingTurnOver:number;
    public rnTurnOver:number;
    public administratorDepartures:number;
    public reportedTotalNursingHRD:number;
    public adjustedTotalNurseHRD:number;
    public  adjustedTotalNurseHRDPoints:number;
    public adjustedRnHRD:number;
    public adjustedRnHRDPoints :number;
    public  reportedRnHRD:number;
    public reportedTotalNursingHRDWeekEnd :number
    public adjustedTotalNurseOnWeekendHRD:number;
    public  adjustedTotalNurseOnWeekendHRDPoints:number;
    public totalNursingTurnOverPoints :number;
    public rnTurnOverPoints :number;
    public administratorDeparturesPoints :number;
    public caseMixTotalNurse:number;
    public caseMixRN:number;
    public caseMixTotalNurseWeekend:number;
    public nationalCaseMixTotalNurse:number;
    public nationalCaseMixRN:number;
    public nationalCaseMixTotalNurseWeekend:number;
    public staffingRating?:number;
}