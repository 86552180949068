export * from './budget-grid/budget-grid.component';
export * from './budget-list/budget-list.component';
export * from './budget-header/budget-header.component';
export * from './budgeted-group-editor/budgeted-group-editor.component';
export * from './budget-history/budget-history.component';
export * from './budget-history-detail/budget-history-detail.component';
export * from './budget-restore-dialog/budget-restore-dialog.component';
export * from './budget-census-adjust/budget-census-adjust.component';

import { BudgetGridComponent } from './budget-grid/budget-grid.component';
import { BudgetListComponent } from './budget-list/budget-list.component';
import { BudgetHeaderComponent } from './budget-header/budget-header.component';
import { BudgetedGroupEditorComponent } from './budgeted-group-editor/budgeted-group-editor.component';
import { BudgetHistoryComponent } from './budget-history/budget-history.component';
import { BudgetHistoryDetailComponent } from './budget-history-detail/budget-history-detail.component';
import { BudgetRestoreDialogComponent } from './budget-restore-dialog/budget-restore-dialog.component';
import { BudgetCensusAdjustComponent } from './budget-census-adjust/budget-census-adjust.component';

export const budgetComponents: any = [
  BudgetGridComponent,
  BudgetListComponent,
  BudgetHeaderComponent,
  BudgetedGroupEditorComponent,
  BudgetHistoryComponent,
  BudgetHistoryDetailComponent,
  BudgetRestoreDialogComponent,
  BudgetCensusAdjustComponent
];
