import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { process } from "@progress/kendo-data-query";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { distinctUntilChanged, first, map, shareReplay, startWith, tap } from "rxjs/operators";
import { BusyService } from "../../../common/services";
import { NotificationsService } from '../../../core/components';
import { distinctPrimitive } from '../../models/wfm-sync';
var BaseSyncComponent = /** @class */ (function () {
    function BaseSyncComponent(notificationService, busyService) {
        this.notificationService = notificationService;
        this.busyService = busyService;
        this.pageSize = 50;
        this.filter$ = new BehaviorSubject(null);
        this.dateFormat = '{0:MM/dd/yyyy}';
        this.globalFilterFunc$ = new BehaviorSubject(null);
        this.selectionChanged$ = new Subject();
        this.data$ = new BehaviorSubject([]);
    }
    BaseSyncComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Indicates if a loading operation is currently in progress
        this.isLoading$ = this.busyService.busyState$.pipe(map(function (busyState) { return busyState.isBusy; }));
        // Optional prefiltering of the raw data
        this.viewModel$ = combineLatest(this.data$, this.globalFilterFunc$).pipe(map(function (_a) {
            var data = _a[0], globalFilterFunc = _a[1];
            if (globalFilterFunc) {
                return data.filter(function (item) { return globalFilterFunc(item); });
            }
            return data;
        }), tap(function (vm) { return _this.viewModel = vm; }));
        // Computes the filtered items based on the grid's filter.
        this.filteredItems$ = combineLatest(this.viewModel$, this.filter$).pipe(map(function (_a) {
            var data = _a[0], filter = _a[1];
            var dataResult = process(data, { filter: filter });
            return dataResult.data;
        }), shareReplay({ bufferSize: 1, refCount: true }));
        // Computes selected items. Changes whenever data view changes or the selection changes
        var selectionChanged$ = this.selectionChanged$.pipe(startWith(true));
        this.selectedItems$ = combineLatest(this.filteredItems$, selectionChanged$).pipe(map(function (_a) {
            var data = _a[0];
            return data.filter(function (item) { return !_this.isSyncItemDisabled(item) && item.ischecked; });
        }), shareReplay({ bufferSize: 1, refCount: true }));
        // Changes whenever selection changes to enable/disable the sync button
        this.isSyncDisabled$ = this.selectedItems$.pipe(map(function (selectedItems) { return !selectedItems.length; }), distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
        // Changes whenever the data view or selection changes to indicate if all items are selected
        this.isCheckedAll$ = combineLatest(this.filteredItems$, this.selectedItems$).pipe(map(function (_a) {
            var data = _a[0], selectedItems = _a[1];
            var enabledItems = data.filter(function (item) { return !_this.isSyncItemDisabled(item); });
            return enabledItems.length === selectedItems.length && !!selectedItems.length;
        }), distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
        // Changes whenever the data view changes to indicate if there are any items to check
        this.isCheckAllDisabled$ = this.filteredItems$.pipe(map(function (data) {
            var enabledItems = data.filter(function (item) { return !_this.isSyncItemDisabled(item); });
            return !enabledItems.length;
        }), distinctUntilChanged(), shareReplay({ bufferSize: 1, refCount: true }));
        // Fetch initial data and set view model
        this.fetchData().then(function (data) { return _this.data$.next(data); });
    };
    BaseSyncComponent.prototype.onCheckboxChange = function (event, item) {
        if (!item.isDisabled) {
            item.ischecked = event.target.checked;
            this.selectionChanged$.next();
        }
    };
    BaseSyncComponent.prototype.onFilterChanged = function (filter) {
        this.filter$.next(filter);
        this.clearSelectedItems();
    };
    BaseSyncComponent.prototype.toggleCheckAll = function (event) {
        var _this = this;
        this.filteredItems$.pipe(first()).subscribe(function (data) {
            data.forEach(function (item) {
                if (!_this.isSyncItemDisabled(item)) {
                    item.ischecked = event.target.checked;
                }
            });
        });
        this.selectionChanged$.next();
    };
    BaseSyncComponent.prototype.onRefreshClicked = function () {
        var _this = this;
        this.selectedItems$.pipe(first()).subscribe(function (selectedItems) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.fetchData()];
                    case 1:
                        data = _a.sent();
                        // Apply previous selection to the new data
                        this.applySelection(selectedItems, data);
                        this.data$.next(data);
                        return [2 /*return*/];
                }
            });
        }); });
    };
    BaseSyncComponent.prototype.syncData = function () {
        var _this = this;
        this.selectedItems$.pipe(first()).subscribe(function (selectedItems) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!!selectedItems.length) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.syncDataCore(selectedItems)];
                    case 1:
                        _a.sent();
                        // Clear all selected items
                        this.clearSelectedItems();
                        return [4 /*yield*/, this.fetchData()];
                    case 2:
                        data = _a.sent();
                        this.data$.next(data);
                        // Display confirmation to user
                        this.notificationService.success("Successfully Submitted " + selectedItems.length + " records.");
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); });
    };
    BaseSyncComponent.prototype.distinctPrimitive = function (fieldName, isNumeric) {
        var data = [];
        data = distinctPrimitive(fieldName, isNumeric, this.viewModel);
        return data;
    };
    BaseSyncComponent.prototype.clearSelectedItems = function () {
        var _this = this;
        this.viewModel$.pipe(first()).subscribe(function (data) {
            data.forEach(function (item) { return item.ischecked = false; });
            _this.selectionChanged$.next();
        });
    };
    BaseSyncComponent.prototype.applySelection = function (selectedItems, data) {
        var _this = this;
        if (!!selectedItems.length) {
            // Build a hashmap of the new data for quick lookup
            var keyMap_1 = data
                .map(function (item) { return ({ key: _this.getSyncItemKey(item), item: item }); })
                .reduce(function (prev, curr) {
                prev[curr.key] = curr.item;
                return prev;
            }, {});
            // Restore selected items in the new data
            selectedItems.forEach(function (selectedItem) {
                var newItem = keyMap_1[_this.getSyncItemKey(selectedItem)];
                if (newItem && !_this.isSyncItemDisabled(newItem)) {
                    newItem.ischecked = true;
                }
            });
        }
    };
    /**
     * Optionally overridable to dynamically control when an item is disabled.
     * By default returns the value of SyncItem.isDisabled
     * @param item
     */
    BaseSyncComponent.prototype.isSyncItemDisabled = function (item) {
        return item.isDisabled;
    };
    /**
     * Optional pre filter for grid data. Return false for items that should not be displayed
     * @param item the item to filter
     */
    BaseSyncComponent.prototype.preFilterSyncItem = function (item) {
        return true;
    };
    return BaseSyncComponent;
}());
export { BaseSyncComponent };
