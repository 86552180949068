import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { appConfig } from '../../../app.config';
import { lmConfig } from '../leave-management.config';

import { Meta } from '../../../core/models/api/meta';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { ApiUtilService } from '../../../common/index';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';

import { dateTimeUtils } from '../../../common/utils/index';

import { LoaAttachment, ReadFile } from '../../../organization/models/index';

import { ResponseBody } from '../../../core/models/api/response-body';
import { ILoaRequest, LoaRequest, LoaConsole, ILoaConsole, LoaType, ILoaType, ILoaRequestContainer, LoaRequestContainer, MetaContainer } from '../models/index';
import { LMMapService } from './lm-map.service';
import { FieldsMeta } from '../../../core/models';

@Injectable()
export class LMApiService {
  constructor (
    private apiUtilService: ApiUtilService,
    private mapService: LMMapService,
    private urlParamsService: UrlParamsService
  ) {
  }

  public async getLoaRequests(orgLevelId: number, sDate: Date, eDate): Promise<MetaContainer<LoaRequestContainer>> {
    const url: string = this.getRosterApi();
    const params = {
      orgLevelId: orgLevelId,
      start: dateTimeUtils.convertToDtoString(sDate),
      end: dateTimeUtils.convertToDtoString(eDate)
    };
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService
      .request<ILoaRequest[], Meta>(request)
      .then((response: ResponseBody<ILoaRequest[], Meta>) => {
        let fieldsMeta: FieldsMeta = response.meta as FieldsMeta;
        let container: LoaRequestContainer = new LoaRequestContainer();
        container.requests = this.mapService.mapLoaRequests(response.data);
        let metaContainer: MetaContainer<LoaRequestContainer> = new MetaContainer(container, fieldsMeta.actions)
        return metaContainer;
      });
  }

  public async getLoaRequestsByOrgLevel(orgLevelId: number): Promise<MetaContainer<LoaRequestContainer>> {
    const url: string = this.getRosterApi();
    const params = {
      orgLevelId: orgLevelId
    };
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService
      .request<ILoaRequestContainer, Meta>(request)
      .then((response: ResponseBody<ILoaRequestContainer, Meta>) => {
        let fieldsMeta: FieldsMeta = response.meta as FieldsMeta;
        let container = this.mapService.mapLoaRequestContainer(response.data);
        let metaContainer: MetaContainer<LoaRequestContainer> = new MetaContainer(container, fieldsMeta.actions);
        return metaContainer;
      });
  }

  public async createLoaRequest(req: LoaRequest): Promise<LoaRequest> {
    const url: string = this.getLoaApi();
    const body = this.mapService.mapLoaRequestToDto(req);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<ILoaRequest, Meta>(request)
      .then((response: ResponseBody<ILoaRequest, Meta>) => this.mapService.mapLoaRequest(response.data));
  }

  public async updateLoaRequest(req: LoaRequest): Promise<LoaRequest> {
    const url: string = this.getLoaApi();
    const body = this.mapService.mapLoaRequestToDto(req);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<ILoaRequest, Meta>(request)
      .then((response: ResponseBody<ILoaRequest, Meta>) => this.mapService.mapLoaRequest(response.data));
  }

  public async deleteLoaRequest(requestId: number): Promise<void> {
    const url: string = this.getLoaApi();
    const request = this.urlParamsService.createDeleteRequest(url, { requestId });

    return this.apiUtilService
      .request<void, Meta>(request)
      .then((response: ResponseBody<void, Meta>) => response.data);
  }

  public async getLoaConsole(orgLevelId: number): Promise<MetaContainer<LoaConsole>> {
    const url: string = `${this.getApiRoot()}/${lmConfig.api.loa.root}/${lmConfig.api.loa.console.root}`;
    const params = {
      orgLevelId: orgLevelId
    };
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService
      .request<ILoaConsole, Meta>(request)
      .then((response: ResponseBody<ILoaConsole, Meta>) => {
        let fieldsMeta: FieldsMeta = response.meta as FieldsMeta;
        let console = this.mapService.mapLoaConsole(response.data);
        let container: MetaContainer<LoaConsole> = new MetaContainer(console, fieldsMeta.actions);
        return container;
      });
  }

  public async getLoaTypes(): Promise<LoaType[]> {
    const url: string = `${this.getApiRoot()}/${lmConfig.api.loa.root}/${lmConfig.api.loa.types.root}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .request<ILoaType[], Meta>(request)
      .then((response: ResponseBody<ILoaType[], Meta>) => this.mapService.mapLoaTypes(response.data));
  }

  public async saveAddedFiles(requestId: number, files: ReadFile[]): Promise<LoaRequest> {
    const url: string = `${this.getApiRoot()}/${lmConfig.api.loa.root}/${lmConfig.api.loa.attachment.root}`;
    const formData = this.mapService.mapFilesToFormData(files);
    const request = this.urlParamsService.requestPost(url, formData, { requestId });
    request.autoContentType = true;

    return this.apiUtilService
      .requestNew<ILoaRequest, Meta>(request)
      .then((response: ResponseBody<ILoaRequest, Meta>) => this.mapService.mapLoaRequest(response.data));
  }

  public async downloadAttachment(documentId: number): Promise<FileBlobResponse> {
    const url: string = `${this.getApiRoot()}/${lmConfig.api.loa.root}/${lmConfig.api.loa.attachment.root}`;
    const params = {
      documentId: documentId
    };
    const request = this.urlParamsService.createGetRequest(url, params);
    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
    return promise;
  }

  public async deleteAttachment(documentId: number, requestId: number): Promise<LoaRequest> {
    const url: string = `${this.getApiRoot()}/${lmConfig.api.loa.root}/${lmConfig.api.loa.attachment.root}`;
    const request = this.urlParamsService.createDeleteRequest(url, { documentId, requestId });

    return this.apiUtilService
      .request<ILoaRequest, Meta>(request)
      .then((response: ResponseBody<ILoaRequest, Meta>) => this.mapService.mapLoaRequest(response.data));
  }

  private getRosterApi(): string {
    return `${this.getLoaApi()}/${lmConfig.api.loa.roster.root}`;
  }

  private getLoaApi(): string {
    return `${this.getApiRoot()}/${lmConfig.api.loa.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
