import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { OrgLevelType } from '../../../../../state-model/models/index';
import { ApplicationStateBusService } from '../../../../../organization/services/index';
import { PbjReconciliationManagementService } from '../../../services/index';
import { PBJRecOrgEntry } from '../../../models/index';
var PbjReconciliationOrgDetailsComponent = /** @class */ (function () {
    function PbjReconciliationOrgDetailsComponent(managementService, cdr, stateBusService) {
        this.managementService = managementService;
        this.cdr = cdr;
        this.stateBusService = stateBusService;
        this.appConfig = appConfig;
        this.isLoading = true;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'depName', dir: 'asc' }];
    }
    Object.defineProperty(PbjReconciliationOrgDetailsComponent.prototype, "innerEntry", {
        set: function (value) {
            if (_.isObject(value)) {
                this.entry = value;
                this.loadData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjReconciliationOrgDetailsComponent.prototype, "innerStartDate", {
        set: function (value) {
            if (_.isDate(value)) {
                this.startDate = value;
                this.loadData();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjReconciliationOrgDetailsComponent.prototype, "innerEndDate", {
        set: function (value) {
            if (_.isDate(value)) {
                this.endDate = value;
                this.loadData();
            }
        },
        enumerable: true,
        configurable: true
    });
    PbjReconciliationOrgDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateBusSubscription = this.stateBusService.orgLevelsLoaded$.subscribe(function (orgLevels) {
            if (_.isArray(orgLevels)) {
                _this.orgLevels = orgLevels;
                _this.loadData();
            }
        });
        this.orgEntryDetailsSubscription = this.managementService.subscribeToLoadedDepEntries(function (_a) {
            var orgLevelId = _a.orgLevelId, entries = _a.entries;
            if (_this.orgLevelId === orgLevelId) {
                _this.pbjRecDetails = entries;
                _this.isLoading = false;
                _this.refreshGrid();
                _this.cdr.detectChanges();
            }
        });
        this.expandedDetailsSubscription = this.managementService.subscribeToExpandedDetails(function (isOn) {
            _this.showDetailedColumns = isOn;
            _this.refreshGrid();
            _this.cdr.detectChanges();
        });
    };
    PbjReconciliationOrgDetailsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PbjReconciliationOrgDetailsComponent.prototype.defineRowClass = function () {
        return function (row) { return 'reconcil-row'; };
    };
    PbjReconciliationOrgDetailsComponent.prototype.onDepClick = function (item) {
        this.managementService.changeOrgLevel(item.depId, OrgLevelType.department);
    };
    PbjReconciliationOrgDetailsComponent.prototype.findOrgLevelInTree = function () {
        var orgId = _.get(this.entry, 'orgId');
        var orgName = _.get(this.entry, 'orgName');
        if (_.isNumber(orgId) && _.isString(orgName) && _.isArray(this.orgLevels)) {
            return this.searchOrglevel(orgId, orgName);
        }
        return null;
    };
    PbjReconciliationOrgDetailsComponent.prototype.searchOrglevel = function (relatedItemId, name) {
        var _this = this;
        var orgLevel = null;
        _.forEach(this.orgLevels, function (o) {
            orgLevel = _this.searchInTree(o, relatedItemId, name);
        });
        return orgLevel;
    };
    PbjReconciliationOrgDetailsComponent.prototype.searchInTree = function (o, relatedItemId, name) {
        var _this = this;
        if (o.relatedItemId === relatedItemId && o.name === name)
            return o;
        var orgLevel = null;
        if (o.childs.length > 0) {
            _.forEach(o.childs, function (currOrgLevel) {
                if (_.isNull(orgLevel)) {
                    orgLevel = _this.searchInTree(currOrgLevel, relatedItemId, name);
                }
            });
        }
        return orgLevel;
    };
    PbjReconciliationOrgDetailsComponent.prototype.loadData = function () {
        var orgLevel = this.findOrgLevelInTree();
        if (_.isNumber(_.get(orgLevel, 'id')) &&
            _.isDate(this.startDate) &&
            _.isDate(this.endDate)) {
            this.orgLevelId = orgLevel.id;
            this.managementService.loadDepEntries(orgLevel.id, this.startDate, this.endDate, false);
        }
    };
    PbjReconciliationOrgDetailsComponent.prototype.refreshGrid = function () {
        if (!this.pbjRecDetails) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.pbjRecDetails, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgDetailsComponent.prototype, "stateBusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgDetailsComponent.prototype, "orgEntryDetailsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationOrgDetailsComponent.prototype, "expandedDetailsSubscription", void 0);
    return PbjReconciliationOrgDetailsComponent;
}());
export { PbjReconciliationOrgDetailsComponent };
