import * as tslib_1 from "tslib";
import { PmEmployeeRosterManagementService } from './../../../services/pm-employee-roster-management.service';
import { OnInit, OnDestroy, } from '@angular/core';
import { OrgLevel } from '../../../../../state-model/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DeviceDetectorService } from '../../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';
import { DateRange, IDateRange } from '../../../../../core/models/index';
var PmEmployeeRosterToolbarComponent = /** @class */ (function () {
    function PmEmployeeRosterToolbarComponent(pmManagementService, columnManagementService, stateManagement, devDetector) {
        this.pmManagementService = pmManagementService;
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.devDetector = devDetector;
        this.yearInSeconds = 31536000;
        this.dateRange = new DateRange(null, null);
        this.canCreateNewReview = false;
        this.columnsStateName = 'PerformanceManagementEmployeeRoster';
        this.subscriptions = {};
    }
    Object.defineProperty(PmEmployeeRosterToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmEmployeeRosterToolbarComponent.prototype, "dateRangeWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmEmployeeRosterToolbarComponent.prototype, "newReviewBtnWidth", {
        get: function () {
            return this.isDesktop ? 175 : 35;
        },
        enumerable: true,
        configurable: true
    });
    PmEmployeeRosterToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initServices();
        this.defineDateRange();
        this.subscriptions.abilityToCreateNewReview = this.pmManagementService
            .subscribeToCanCreateNewReview(function (canCreate) { return (_this.canCreateNewReview = canCreate); });
    };
    PmEmployeeRosterToolbarComponent.prototype.ngOnDestroy = function () { };
    PmEmployeeRosterToolbarComponent.prototype.onClickCreate = function () {
        this.pmManagementService.openReviewPopup(null);
    };
    PmEmployeeRosterToolbarComponent.prototype.onChangeDates = function (range) {
        this.pmManagementService.changeDateRange(range);
    };
    PmEmployeeRosterToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.pmManagementService.exportTo(isPDF);
    };
    PmEmployeeRosterToolbarComponent.prototype.defineDateRange = function () {
        this.dateRange = this.pmManagementService.getDefaultDateRange();
        this.onChangeDates(this.dateRange);
    };
    PmEmployeeRosterToolbarComponent.prototype.initServices = function () {
        this.stateManagement.init('PerformanceManagementEmployeeRosterComponent');
        this.columnManagementService.init('PerformanceManagementEmployeeRosterComponent');
        this.columnManagementService.initGroup(this.columnsStateName, 9);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmEmployeeRosterToolbarComponent.prototype, "subscriptions", void 0);
    return PmEmployeeRosterToolbarComponent;
}());
export { PmEmployeeRosterToolbarComponent };
