import * as tslib_1 from "tslib";
import * as _ from 'lodash';
var PBJRecBase = /** @class */ (function () {
    function PBJRecBase() {
    }
    Object.defineProperty(PBJRecBase.prototype, "timecardHours", {
        get: function () {
            return this.reconciliation.timecardHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "startOfPeriod", {
        get: function () {
            return this.reconciliation.startOfPeriod;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "endOfPeriod", {
        get: function () {
            return this.reconciliation.endOfPeriod;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "excludedPayCodes", {
        get: function () {
            return this.reconciliation.excludedPayCodes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "unmappedPositions", {
        get: function () {
            return this.reconciliation.unmappedPositions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "manualPbjEntries", {
        get: function () {
            return this.reconciliation.manualPbjEntries;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "unapprovedHours", {
        get: function () {
            return this.reconciliation.unapprovedHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "expectedPbjHours", {
        get: function () {
            return this.reconciliation.expectedPbjHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "totalPbjHours", {
        get: function () {
            return this.reconciliation.totalPbjHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PBJRecBase.prototype, "variance", {
        get: function () {
            return this.reconciliation.variance;
        },
        enumerable: true,
        configurable: true
    });
    return PBJRecBase;
}());
export { PBJRecBase };
var PBJRecEntry = /** @class */ (function () {
    function PBJRecEntry() {
    }
    return PBJRecEntry;
}());
export { PBJRecEntry };
var PBJRecOrgEntry = /** @class */ (function (_super) {
    tslib_1.__extends(PBJRecOrgEntry, _super);
    function PBJRecOrgEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PBJRecOrgEntry;
}(PBJRecBase));
export { PBJRecOrgEntry };
var PBJRecDepEntry = /** @class */ (function (_super) {
    tslib_1.__extends(PBJRecDepEntry, _super);
    function PBJRecDepEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PBJRecDepEntry;
}(PBJRecBase));
export { PBJRecDepEntry };
var PBJRecEmpEntry = /** @class */ (function (_super) {
    tslib_1.__extends(PBJRecEmpEntry, _super);
    function PBJRecEmpEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PBJRecEmpEntry;
}(PBJRecBase));
export { PBJRecEmpEntry };
var PBJRecDailyEntry = /** @class */ (function (_super) {
    tslib_1.__extends(PBJRecDailyEntry, _super);
    function PBJRecDailyEntry() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PBJRecDailyEntry;
}(PBJRecBase));
export { PBJRecDailyEntry };
var PBJRecColumn = /** @class */ (function () {
    function PBJRecColumn(title, field, width, mobileWidth) {
        this.title = title;
        this.field = field;
        this.width = width;
        if (_.isNumber(mobileWidth)) {
            this.mobileWidth = mobileWidth;
        }
    }
    return PBJRecColumn;
}());
export { PBJRecColumn };
var PBJRecColumns = /** @class */ (function () {
    function PBJRecColumns() {
        this.columns = {};
        this.columnsDef = [
            { field: 'orgName', title: 'Facility', width: 240, mobileWidth: 100 },
            { field: 'depName', title: 'Facility', width: 240, mobileWidth: 100 },
            { field: 'empName', title: 'Employee Name', width: 240, mobileWidth: 100 },
            { field: 'dateOn', title: 'Employee Timecard Date', width: 240, mobileWidth: 100 },
            { field: 'timecardHours', title: 'Time Card Hours', width: 150, mobileWidth: 70 },
            { field: 'startOfPeriod', title: 'Start of Period Adjustments', width: 170 },
            { field: 'endOfPeriod', title: 'End of Period Adjustments', width: 170 },
            { field: 'excludedPayCodes', title: 'Excluded Pay Codes', width: 150 },
            { field: 'unmappedPositions', title: 'Unmapped Positions', width: 150 },
            { field: 'manualPbjEntries', title: 'Manual PBJ Entries', width: 150 },
            { field: 'unapprovedHours', title: 'Unapproved Hours', width: 150 },
            { field: 'expectedPbjHours', title: 'Expected PBJ Hours', width: 150, mobileWidth: 70 },
            { field: 'totalPbjHours', title: 'Actual PBJ Hours', width: 150 },
            { field: 'variance', title: 'Variance', width: 150, mobileWidth: 70 },
        ];
    }
    PBJRecColumns.prototype.makeColumns = function () {
        var columns = {};
        _.forEach(this.columnsDef, function (column) {
            columns[column.field] = new PBJRecColumn(column.title, column.field, column.width);
        });
        return columns;
    };
    PBJRecColumns.prototype.makeAggregates = function () {
        var aggregates = [];
        _.forEach(this.columnsDef, function (column) {
            var field = column.field;
            if (field !== 'orgName' && field !== 'depName' && field !== 'empName' && field !== 'dateOn') {
                aggregates.push({ field: column.field, aggregate: 'sum' });
            }
        });
        return aggregates;
    };
    return PBJRecColumns;
}());
export { PBJRecColumns };
var PbjReconciliationOrglevel = /** @class */ (function () {
    function PbjReconciliationOrglevel(sDate, eDate, maxDaysRange) {
        this.startDate = sDate;
        this.endDate = eDate;
        this.maxDaysRange = maxDaysRange;
    }
    return PbjReconciliationOrglevel;
}());
export { PbjReconciliationOrglevel };
var PbjReconciliationEmployee = /** @class */ (function (_super) {
    tslib_1.__extends(PbjReconciliationEmployee, _super);
    function PbjReconciliationEmployee(empId, empName, sDate, eDate, maxDaysRange) {
        var _this = _super.call(this, sDate, eDate, maxDaysRange) || this;
        _this.employeeId = empId;
        _this.employeeName = empName;
        return _this;
    }
    return PbjReconciliationEmployee;
}(PbjReconciliationOrglevel));
export { PbjReconciliationEmployee };
