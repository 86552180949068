import { IShiftDiffPolicy, IPayPolicy, IEmployeePositionDefinition, EmployeePositionDefinition, ShiftDefinition, IShiftDefinition, ShiftDiffPolicy, PayPolicy } from '../../../organization/models/index';
import { ITimecardsEarningPayRule, ITimecardsEarning, TimecardsEarning } from './index';

export interface ITimecardsEmployee {
  employeePosition: IEmployeePositionDefinition;
  earnings: ITimecardsEarning[];
  standardPayRate: number;
  homeShift: IShiftDefinition;
  approved: boolean;
  certified: boolean;
  //totals
  productiveHours: number;
  nonProductiveHours: number;
  totalHours: number;
  regularPay: number;
  overtimeAndExtra: number;
  overTimePay:number;
  totalAbsencePay:number;
  totalOtherPay:number;
  totalPay: number;
  //alert Badges
  isWarning: boolean;
  isError: boolean;
  shiftDifferentialPolicy: IShiftDiffPolicy;
  payPolicy: IPayPolicy;
  empOrganization: string;
  isLocked: boolean;
  isPayCycleLocked: boolean;
  isEdited: boolean;
}

export class TimecardsEmployee {
  public employeePosition: EmployeePositionDefinition;
  public earnings: TimecardsEarning[];
  public standardPayRate: number;
  public homeShift: ShiftDefinition;
  public approved: boolean;
  public certified: boolean;
  //totals
  public productiveHours: number;
  public nonProductiveHours: number;
  public totalHours: number;
  public regularPay: number;
  public overtimeAndExtra: number;
  public overTimePay:number;
  public totalAbsencePay:number;
  public totalOtherPay:number;
  public totalPay: number;

  public isWarning: boolean;
  public isError: boolean;

  public isSelected: boolean;
  public shiftDiffPolicy: ShiftDiffPolicy;
  public payPolicy: PayPolicy;
  public canModifyOwnTimecard: boolean;
  public empOrganization: string;
  public isLocked: boolean;
  public isPayCycleLocked: boolean;
  public isEdited: boolean;
}
