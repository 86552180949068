/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-daily-details-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../schedule-daily-details/schedule-daily-details.component.ngfactory";
import * as i3 from "../schedule-daily-details/schedule-daily-details.component";
import * as i4 from "../../../services/schedule/schedule-daily-details-api.service";
import * as i5 from "./schedule-daily-details-dialog.component";
import * as i6 from "../../../../common/models/dialog-options";
import * as i7 from "../../../../common/services/modal/modal.service";
import * as i8 from "../../../models/schedule-daily-details/schedule-daily-details-req";
var styles_ScheduleDailyDetailsDialogComponent = [i0.styles];
var RenderType_ScheduleDailyDetailsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleDailyDetailsDialogComponent, data: {} });
export { RenderType_ScheduleDailyDetailsDialogComponent as RenderType_ScheduleDailyDetailsDialogComponent };
export function View_ScheduleDailyDetailsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "slx-schedule-daily-details", [], null, null, null, i2.View_ScheduleDailyDetailsComponent_0, i2.RenderType_ScheduleDailyDetailsComponent)), i1.ɵdid(3, 573440, null, 0, i3.ScheduleDailyDetailsComponent, [i4.ScheduleDailyDetailsApiService], { req: [0, "req"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn green"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDua() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Proceed to Daily Unit Assignments"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.request; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ScheduleDailyDetailsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-daily-details-dialog", [], null, null, null, View_ScheduleDailyDetailsDialogComponent_0, RenderType_ScheduleDailyDetailsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.ScheduleDailyDetailsDialogComponent, [i6.DialogOptions, i7.ModalService, i8.ScheduleDailyDetailsRequest], null, null)], null, null); }
var ScheduleDailyDetailsDialogComponentNgFactory = i1.ɵccf("slx-schedule-daily-details-dialog", i5.ScheduleDailyDetailsDialogComponent, View_ScheduleDailyDetailsDialogComponent_Host_0, {}, {}, []);
export { ScheduleDailyDetailsDialogComponentNgFactory as ScheduleDailyDetailsDialogComponentNgFactory };
