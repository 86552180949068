import * as _ from 'lodash';
var OpenShiftDetails = /** @class */ (function () {
    function OpenShiftDetails() {
    }
    Object.defineProperty(OpenShiftDetails.prototype, "requestedPartialShiftCount", {
        get: function () {
            var partialCount = 0;
            if (this.partialShifts) {
                _.forEach(this.partialShifts, function (partialShift) {
                    if (!partialShift.hasPartialShift && partialShift.messages.length > 0) {
                        partialCount += partialShift.messages.length;
                    }
                });
            }
            return partialCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftDetails.prototype, "hasPartialShifts", {
        get: function () {
            if (this.partialShifts && this.partialShifts.length > 0) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftDetails.prototype, "partnerShiftCount", {
        get: function () {
            var _this = this;
            var partnerMessages = _.filter(this.partnerMessages, function (message) {
                message.partnerShiftId = _this.partnerShiftId;
                return (message.isNew || message.isRequested);
            });
            return partnerMessages.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftDetails.prototype, "messagesFromThreads", {
        get: function () {
            return _.flatMap(this.messagesThreads, function (thread) {
                return thread.messages || [];
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftDetails.prototype, "newMessageCount", {
        get: function () {
            var messages = _.filter(this.messages, function (message) {
                return message.isInbound && (message.isNew || message.isRequested);
            });
            return messages.length;
        },
        enumerable: true,
        configurable: true
    });
    OpenShiftDetails.prototype.getThreadByMessageId = function (messageId) {
        return _.find(this.messagesThreads, function (thread) {
            return thread.messages.some(function (message) { return message.id === messageId; });
        });
    };
    Object.defineProperty(OpenShiftDetails.prototype, "positionName", {
        get: function () {
            return this.position ? this.position.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftDetails.prototype, "shiftName", {
        get: function () {
            return this.shift ? this.shift.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftDetails.prototype, "unitName", {
        get: function () {
            return this.unit ? this.unit.name : '';
        },
        enumerable: true,
        configurable: true
    });
    return OpenShiftDetails;
}());
export { OpenShiftDetails };
