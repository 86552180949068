import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsAttendance {
  dates: IEmployeeSectionsAttendanceDay[];
  totals: IAbsenceStatistics;
}

export interface IEmployeeSectionsAttendanceDay {
  date: Date;
  isWeekend: boolean;
  isScheduled: boolean;
  isWorkDay: boolean;
  scheduleHours: number;
  workHours: number;
  actualAbsenceHours: number;
  scheduledAbsenceHours: number;
  scheduledAbsence: IAbsence;
  actualAbsence: IAbsence;
}

export interface IAbsence {
  code: string;
  description: string;
  isPaid: boolean;
  loaIndicator: boolean;
  ptoPlannerIndicator: boolean;
  color: number;
  essPresent: boolean;
  taCode: string;
  lastUpdateDate: string;
  lastUpdateUsername: string;
}

export interface IAbsenceStatItem {
  absence: IAbsence;
  totalsHours: number;
  shiftCount: number;
  occurrences: number;
  partialShiftCount: number;
}

export interface IAbsenceStatistics {
  actualAbsenceStatistics: IAbsenceStatItem[];
  scheduledAbsenceStatistics: IAbsenceStatItem[];
  frequentlyAbsentOn: string;
  occurrences: number;
  absentBeforeHoliday: number;
  absentAfterHoliday: number;
}

export class EmployeeSectionsAttendance extends EmployeeSectionsBase {
  public dates: EmployeeSectionsAttendanceDay[];
  public totals: AbsenceStatistics;
}

export class EmployeeSectionsAttendanceDay {
  public date: Date;
  public isWeekend: boolean;
  public isScheduled: boolean;
  public isWorkDay: boolean;
  public scheduleHours: number;
  public workHours: number;
  public actualAbsenceHours: number;
  public scheduledAbsenceHours: number;
  public scheduledAbsence: IAbsence;
  public actualAbsence: IAbsence;

  public getAbsence(prefix: string): IAbsence {
    return prefix === 'actual' ? this.actualAbsence : this.scheduledAbsence;
  }

  public getAbsenceHours(prefix: string): number {
    return prefix === 'actual' ? this.actualAbsenceHours : this.scheduledAbsenceHours;
  }

  public getMarkedDay(prefix: string): boolean {
    if (this.getAbsence(prefix)) return false;
    return prefix === 'actual' ? this.isWorkDay : this.isScheduled;
  }
}

export class AbsenceStatItem {
  public absence: IAbsence;
  public totalsHours: number;
  public shiftCount: number;
  public partialShiftCount: number;
  public occurrences: number;

  public get name(): string {
    return this.absence ? this.absence.description : '';
  }

  public get code(): string {
    return this.absence ? this.absence.code : '';
  }

  public get color(): number {
    return this.absence ? this.absence.color : 0xFFFFFF;
  }
}

export class AbsenceStatistics {
  public actualAbsenceStatistics: AbsenceStatItem[];
  public scheduledAbsenceStatistics: AbsenceStatItem[];
  public frequentlyAbsentOn: string;
  public occurrences: number;
  public absentBeforeHoliday: number;
  public absentAfterHoliday: number;

  public getAbsenceStatistics(prefix: string): AbsenceStatItem[] {
    return prefix === 'actual' ? this.actualAbsenceStatistics : this.scheduledAbsenceStatistics;
  }
}
