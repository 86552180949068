export interface IBenefitOption {
  id: number;
  type: string;
  code: string;
}

export class BenefitOption implements IBenefitOption {
  public id: number;
  public type: string;
  public code: string;
}
