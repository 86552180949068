export interface ILicenseType {
    licenseTypeID: number;
    licenseTypeName: string;
    confirmingBody?: string;
    region?: string;
  }

  export class LicenseType {
    public licenseTypeID: number;
    public licenseTypeName: string;
    public confirmingBody: string;
    public region: string;
  }
