import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, Provider } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { EmployeeListActions } from '../../store/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { EmployeeListService } from '../../services/index';
import { EmployeeActionDefinition, EmployeeActionDefinitions } from '../../models/index';
import { GroupSmsComponent } from '../group-sms/group-sms.component';
import { AssignPayPolicyComponent } from '../assign-pay-policy/assign-pay-policy.component';
import { AssignAccrualsPolicyComponent } from '../assign-accruals-policy/assign-accruals-policy.component';
import { CreateESSPolicyComponent } from '../create-ess-policy/create-ess-policy.component';
import { AssignPayCyclesComponent } from '../assign-pay-cycles/assign-pay-cycles.component';
import { employeeListConfig } from '../../employee-list.config';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../common/services/index';
import { ModalRef, IDialogComponent } from '../../../../common/models/index';
import { EmployeeSectionNavigationService } from '../../../../employee/employee-sections/services/index';
import { EmployeeGridComponent } from '../employee-grid/employee-grid.component';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN, FILTERED_EMPLOYEES_TOKEN } from '../../../../core/models/index';
import { EmployeeListCommonService } from '../../services/employee-list/employee-list-common.service';
import { ResendOptSmsComponent } from '../resend-opt-sms/resend-opt-sms.component';
import { SendEmailInstructionsComponent } from '../send-email-instructions/send-email-instructions.component';
import { SlateProfileAssignmentComponent } from '../slate-profile-assignment/slate-profile-assignment.component';
import { MassRateAdjustmentsDialogComponent } from '../mass-rate-adjustments/mass-rate-adjustments.component';
var EmployeeListComponent = /** @class */ (function () {
    function EmployeeListComponent(employeeListCommonService, employeeListService, modalService, router, route, employeeListActions, stateManagement, columnManagementService) {
        var _this = this;
        this.employeeListCommonService = employeeListCommonService;
        this.employeeListService = employeeListService;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
        this.employeeListActions = employeeListActions;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.employeeListColumns = [];
        this.actionDefinitions = new EmployeeActionDefinitions();
        this.isColumnLocked = false;
        this.showFunctionsDict = {};
        this.employeeListItems = [];
        this.state = {
            isLoading: false,
            isActiveOnly: true
        };
        this.isActiveOnly = this.state.isActiveOnly;
        this.showFunctionsDict = {
            'mass-assign-slate-profile': function (actionDefinition) { _this.showDialog2(SlateProfileAssignmentComponent, actionDefinition); },
            'send-sms': function (actionDefinition) { _this.showDialog2(GroupSmsComponent, actionDefinition); },
            'assign-pay-policy': function (actionDefinition) { _this.showWindow(AssignPayPolicyComponent, actionDefinition); },
            'assign-accruals-policy': function (actionDefinition) { _this.showWindow(AssignAccrualsPolicyComponent, actionDefinition); },
            'create-ess-policy': function (actionDefinition) { _this.showWindow(CreateESSPolicyComponent, actionDefinition); },
            'create-pay-cycles': function (actionDefinition) { _this.showWindow(AssignPayCyclesComponent, actionDefinition); },
            'resend-opt-sms': function (actionDefinition) { _this.showDialog2(ResendOptSmsComponent, actionDefinition); },
            'send-email-instructions': function (actionDefinition) { _this.showDialog2(SendEmailInstructionsComponent, actionDefinition); },
            'mass-rate-adjustments': function (actionDefinition) { _this.showDialog2(MassRateAdjustmentsDialogComponent, actionDefinition); }
        };
        this.dateRange = { startDate: moment().endOf('day').add(-1, 'week').toDate(), endDate: moment().endOf('day').toDate() };
    }
    Object.defineProperty(EmployeeListComponent.prototype, "groupName", {
        get: function () {
            return "EmployeeList_" + (_.isUndefined(this.employeeListItem) || _.isNull(this.employeeListItem) ? '0' : this.employeeListItem.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "listDefinitionsIsEmpty", {
        get: function () {
            return this.employeeListItems ? this.employeeListItems.length === 0 : true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "isCallLog", {
        get: function () {
            return this.employeeListItem ? this.employeeListItem.listName === 'Call Log' : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeListComponent.prototype, "fileName", {
        get: function () {
            return this.employeeListItem.listName + "_" + moment().format("MM/DD/YYYY");
        },
        enumerable: true,
        configurable: true
    });
    EmployeeListComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        this.modalService.closeAllWindows();
    };
    EmployeeListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true;
        this.stateManagement.init('EmployeeListComponent');
        this.employeesActionSubscription = this.orgLevel$
            .combineLatest(this.application)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            if (_this.employeesSubscription) {
                _this.employeesSubscription.unsubscribe();
                _this.employeesSubscription = null;
            }
            _this.employeeListService.getEmployeeListItems(application, orgLevel.id).then(function (employeeList) {
                _this.employeeListItems1 = employeeList.filter(function (s) { return s.listName != 'Basic Information'; });
                _this.employeeListItems = employeeList.filter(function (s) { return s.listName === 'Basic Information'; }).concat(_this.employeeListItems1);
                if (_this.employeeListItems.length === 0) {
                    _this.state.isLoading = false;
                }
                else {
                    _this.subscribeToState();
                }
            });
            _this.employeeListService.getEmployeeActionDefinitions(application.id, orgLevel.id)
                .then(function (actionDefinitions) {
                _this.actionDefinitions = actionDefinitions;
            });
        });
        this.columnsChangedSubscription = this.columnManagementService.columnsChanged$.combineLatest().subscribe(function (eventArgs) {
            if (_.filter(eventArgs[0].columns, function (e) { return e.displayed == true; }).length > 0) {
                _this.isColumnLocked = true;
            }
            else {
                _this.isColumnLocked = false;
            }
            setTimeout(function () {
                _this.employeeGrid.refreshColumns();
            }, 200);
        });
    };
    EmployeeListComponent.prototype.subscribeToState = function () {
        var _this = this;
        if (this.employeesSubscription) {
            return;
        }
        this.employeesSubscription = this.orgLevel$
            .combineLatest(this.application, this.employeeListState$)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1], employeeListState = value[2];
            var lastIsActive = _this.state.isActiveOnly;
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            _this.state.isActiveOnly = _this.isActiveOnly = employeeListState.isActive;
            var orgLevelState = employeeListState.stateByOrglevel[orgLevel.id];
            // #step_1 save original value
            var viewName = employeeListState.viewName;
            var employeeListItem;
            if (viewName) {
                // #step_2 try to find employeeListItem by viewName
                employeeListItem = _.find(_this.employeeListItems, function (item) { return item.id === viewName; });
            }
            if (!employeeListItem) {
                // #step_3 if employeeListItem did not find, select first list as default and set viewName from employeeListItem.id
                employeeListItem = _this.employeeListItems[0];
                viewName = employeeListItem.id;
            }
            if (orgLevelState) {
                if (orgLevelState.stateByView) {
                    // #step_4 if skip these 3 steps we will get incorrect viewState
                    var viewState = orgLevelState.stateByView[viewName];
                    if (viewState) {
                        _this.selectedEmployeeIds = viewState.itemIds;
                    }
                    else {
                        _this.selectedEmployeeIds = [];
                    }
                }
            }
            if (!employeeListItem || (_this.employeeListItem && (_this.employeeListItem.id === employeeListItem.id))) {
                if (_this.currentOrgLevel && _this.currentOrgLevel.id === orgLevel.id && lastIsActive === _this.state.isActiveOnly) {
                    return;
                }
            }
            _this.changeEmployeesListStatusFieldVisibility(employeeListItem, employeeListState.isActive);
            _this.currentOrgLevel = orgLevel;
            _this.employeeListItem = employeeListItem;
            _this.stateManagement.onComponentActiveStateChanged({ employeeListItem: _this.employeeListItem.id, orgLevel: _this.currentOrgLevel.id, isActiveOnly: _this.state.isActiveOnly });
            _this.columnManagementService.init('EmployeeListComponent');
            var employeeFilteredcolumns = _.filter(_this.employeeListItem.fields, _this.employeeListCommonService.isSpecColumn);
            _this.columnManagementService.initGroup(_this.groupName, _.filter(employeeFilteredcolumns, function (field) { return !field.hidden; }).length);
            _this.loadEmployees();
        });
    };
    EmployeeListComponent.prototype.onItemSelect = function (employeeListItem) {
        this.setViewState(employeeListItem, this.state.isActiveOnly);
    };
    EmployeeListComponent.prototype.onDisplaySettingsChanged = function () {
        this.setViewState(this.employeeListItem, this.isActiveOnly);
    };
    EmployeeListComponent.prototype.setViewState = function (employeeListItem, isActiveOnly) {
        this.employeeListActions.selectView(employeeListItem ? employeeListItem.id : null, isActiveOnly);
    };
    EmployeeListComponent.prototype.onGroupActivityActionSelect = function (actionDefinition) {
        if (this.showFunctionsDict[actionDefinition.type]) {
            this.showFunctionsDict[actionDefinition.type](actionDefinition);
        }
        else {
            this.modalService.globalAnchor.openInfoDialog('Warning', actionDefinition.displayName + ' are not implemented');
        }
    };
    EmployeeListComponent.prototype.getWinProviders = function (actionDefinition) {
        var resolvedProvidersConf = [
            { provide: EMPLOYEES_ID_TOKEN, useValue: this.currentEmployees },
            { provide: EmployeeActionDefinition, useValue: actionDefinition },
            { provide: OrgLevel, useValue: this.currentOrgLevel },
            { provide: FILTERED_EMPLOYEES_TOKEN, useValue: this.employeeGrid.getfilteredEmployeeList() },
            { provide: ISACTIVEONLY_ID_TOKEN, useValue: this.isActiveOnly }
        ];
        return resolvedProvidersConf;
    };
    EmployeeListComponent.prototype.showWindow = function (winClass, actionDefinition) {
        var _this = this;
        var ref = this.modalService.globalAnchor.open(winClass, actionDefinition.displayName, this.getWinProviders(actionDefinition));
        var win = ref.reference;
        var onCancelSubscription;
        var onCompleteSubscription;
        if (this.canActAsModal(win)) {
            var unsubscribe_1 = function () {
                _this.modalService.closeWindow(ref.uniqueId);
                if (onCancelSubscription)
                    onCancelSubscription.unsubscribe();
                if (onCompleteSubscription)
                    onCompleteSubscription.unsubscribe();
            };
            onCancelSubscription = win.onCancel.subscribe(function () { return unsubscribe_1(); });
            onCompleteSubscription = win.onComplete.subscribe(function () { return unsubscribe_1(); });
        }
    };
    EmployeeListComponent.prototype.showDialog2 = function (winClass, actionDefinition) {
        var _this = this;
        winClass.openDialog(this.modalService, this.getWinProviders(actionDefinition), function (result) {
            if (result) {
                _this.loadEmployees();
            }
        });
    };
    EmployeeListComponent.prototype.employeeSelected = function (employees) {
        if (!this.selectedEmployees && !employees) {
            return;
        }
        if ((this.selectedEmployees && this.selectedEmployees.length === 0) && (employees && employees.length === 0)) {
            return;
        }
        this.selectedEmployees = employees;
        var itemIds = _.map(this.selectedEmployees, function (emp) { return emp[employeeListConfig.employeeIdentifierName]; });
        this.employeeListActions.selectItems(this.employeeListItem ? this.employeeListItem.id : null, this.state.isActiveOnly, itemIds);
    };
    EmployeeListComponent.prototype.employeesFiltered = function (employees) {
        this.currentEmployees = employees;
    };
    EmployeeListComponent.prototype.employeeOpen = function (employee) {
        var empId = employee[employeeListConfig.employeeIdentifierName];
        var navService = new EmployeeSectionNavigationService(this.router, this.route);
        navService.NavigateToEmployeeSections(+empId);
    };
    EmployeeListComponent.prototype.onDatesRangeChanged = function ($event) {
        this.dateRange.startDate = $event.startDate;
        this.dateRange.endDate = $event.endDate;
        this.loadEmployees();
    };
    EmployeeListComponent.prototype.canActAsModal = function (component) {
        return _.has(component, 'onCancel') && _.has(component, 'onComplete') && component.onCancel !== undefined && component.onComplete !== undefined;
    };
    EmployeeListComponent.prototype.loadEmployees = function () {
        var _this = this;
        if (this.employeeListItem) {
            this.state.isLoading = true;
            this.employeeListService.getEmployees(this.employeeListItem.id, this.currentOrgLevel.id, this.state.isActiveOnly, this.employeeListItem, this.dateRange.startDate, this.dateRange.endDate)
                .then(function (employees) {
                _this.currentEmployees = _this.employees = employees;
                _this.employeeGrid.setEmployeesToSelect(_this.selectedEmployeeIds);
                _this.state.isLoading = false;
                _this.stateManagement.loadedData({ employeeListItem: _this.employeeListItem.id, orgLevel: _this.currentOrgLevel.id, isActiveOnly: _this.state.isActiveOnly });
                if (_this.employeeGrid.gridState.state.sort.length == 0) {
                    _this.employeeGrid.sortByNameInAscending();
                }
            });
        }
        else {
            this.currentEmployees = this.employees = [];
        }
    };
    EmployeeListComponent.prototype.changeEmployeesListStatusFieldVisibility = function (employeeListItem, hidden) {
        _.each(employeeListItem.fields, function (field) {
            if (field.fieldName.toLowerCase() === 'status') {
                field.hidden = hidden;
            }
            if (field.fieldName.toLowerCase() === 'empterminationdate') {
                field.hidden = hidden;
            }
            if (field.fieldName.toLowerCase() === 'termdate') {
                field.hidden = hidden;
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeListComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeListComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['employeeList']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeListComponent.prototype, "employeeListState$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeListComponent.prototype, "employeesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeListComponent.prototype, "employeesActionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeListComponent.prototype, "columnsChangedSubscription", void 0);
    return EmployeeListComponent;
}());
export { EmployeeListComponent };
