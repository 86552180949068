import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { ScheduleEntryNavigationService } from '../../services/index';

@Directive({
  /* tslint:disable */
  selector: '[scheduleEntryLink][employeeId][dateOn]',
  /* tslint:enable */
})
export class ScheduleEntryLinkDirective {
  @Input()
  public employeeId: number;
  @Input()
  public dateOn: Date;
  @Input()
  public orgLevelId: number;
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: ScheduleEntryNavigationService = new ScheduleEntryNavigationService(this.router, this.route);
    navService.NavigateToScheduleEntry(this.employeeId, this.dateOn, this.orgLevelId, true);
  }
}
