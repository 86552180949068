import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'slx-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
  public isLoading: boolean = true;
  public activeTab0: boolean = true;
  public activeTab1: boolean = false;
  public activeTab2: boolean = false;
  public activeTab3: boolean = false;
  public BenefitPlansList: string[] = [];
  public selectedBenefitPlan: string;
  public tabSwitch: boolean;
  public isShowingExpired: boolean = null;
  public benefitPlansLoading: boolean = true;
  public currentExpandedGroups: number[];
  public canMapPayroll: boolean = false;


  constructor() {
    this.activeTab0=true;
  }

  public ngOnInit() {
    
    this.BenefitPlansList = ['Organization','Department','Position'];
    this.selectedBenefitPlan = 'Organization';

  }

 

  public onClickTab(tab: number): void {
    if ( tab ==0)
    {
      this.activeTab0=true;
      this.activeTab1=false
      this.activeTab2=false;
    }
    if ( tab ==1)
    {
      this.activeTab0=false;
      this.activeTab1=true;
      this.activeTab2=false;
    }
    if ( tab ==2)
    {
      this.activeTab0=false;
      this.activeTab1=false;
      this.activeTab2=true;
    }
  }

}