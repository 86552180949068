<div class="attachments">
    <div class="flex align-items__center">
        <label class="attachments__label">Upload Files</label>
        <span class="attachments__btn-box">
            <button class="slx-button slx-with-icon" type="button" [disabled]="!editMode">
                <i aria-hidden="true" class="fal fa-paperclip slx-button__icon"></i>
                <span class="slx-button__text">Browse...</span>
            </button>
            <input class="attachments__input"
            type="file"
            [disabled]="!editMode"
            [readonly]="!editMode"
            (change)="onFileChange($event)"
            (click)="onResetErrors($event)"
            [accept]="acceptedFileTypesInputFormat"
            multiple
          />
        </span>
        <span class="flex__grow attachments__types">Allowed file types: {{ acceptedFileTypesReadable }}.
            {{ maxFileSize }} max</span>
    </div>
    <p class="slx-error-block attachments__error" *ngIf="errors">
        <span *ngIf="errors.maxFiles">You selected more than {{ maxFiles }} files</span>
        <span *ngIf="errors.maxFileSize">Unable to upload file. Max size for files is {{ maxFileSize }}.</span>
        <span *ngIf="errors.fileType">Unable to upload file. Allowed types: Word, Excel, PDF and image files.</span>
    </p>
    <ng-container *ngIf="hasFiles">
        <div class="attachments__attached">
            <p class="flex">
                <span class="flex__grow attachments__file-name-title">File Name</span>
                <span class="flex__shrink added-col attachments__added-title">Added By</span>
            </p>
            <p class="flex align-items__center" *ngFor="let file of files">
                <span class="flex flex__grow attachments__file">
                    <i class="fal attachments__i-file"
                        [class.fa-file-download]="isDownloadable(file)"
                        [class.fa-file]="!isDownloadable(file)"
                        (click)="onDownloadFile(file)"
                        aria-hidden="true"
                    ></i>
                    <span class="flex__grow attachments__name"
                        [class.download-link]="isDownloadable(file)"
                        (click)="onDownloadFile(file)"
                    >{{ file.name }}</span>
                    <i *ngIf="this.editMode" class="fal fa-trash-alt attachments__i-delete"
                        title="Delete file immediately" aria-hidden="true" [popover]="confirmDeleting"></i>
                    <popover-content #confirmDeleting title="Are you sure?" placement="auto bottom">
                      <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r" (click)="onClickDelete(true, confirmDeleting, file)">Delete</button>
                      <button type="button" class="slx-button slx-narrow" (click)="onClickDelete(false, confirmDeleting, file)">Cancel</button>
                    </popover-content>
                </span>
                <span class="flex__shrink added-col added-by">{{file.addedBy}}</span>
            </p>
        </div>
    </ng-container>
    <ng-container *ngIf="!hasFiles">
        <p class="attachments__no-files">No attached files</p>
    </ng-container>
</div>
