import * as tslib_1 from "tslib";
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import { mutableSelect } from '../../../core/decorators/index';
import { unsubscribeInService } from '../../../../app/core/decorators/index';
import { OrgLevelType } from '../../../state-model/models/index';
import * as moment from 'moment';
import * as _ from 'lodash';
import { IdealScheduleApiService } from './ideal-schedule-api.service';
import { OrgLevelWatchService } from '../../../organization/services/org-level/org-level-watch.service';
import { IdealScheduleConfigType, IdealScheduleConfigTypeDefinition, IdealScheduleConfigTypes, IdealScheduleConfigCensus, IdealScheduleConfigCensusDefinition, IdealSchedulePositionPeriod } from '../../models/ideal-schedule/index';
import { IdealScheduleHelperService } from './ideal-schedule.helper.service';
import { MasterScheduleManagementService } from '../../../scheduler/services/index';
import { ChangeManagementService, FileService } from '../../../common/services/index';
import { Subject } from 'rxjs';
import { ImportException } from '../../models/ideal-schedule/ideal-schedule-import-template';
import { v4 as UUID } from 'uuid';
import { NotificationsService } from './../../../../app/core/components';
import { DownloadDataService } from '../file-uploads/download-data.service';
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var IdealScheduleStateService = /** @class */ (function () {
    function IdealScheduleStateService(scheduleApiService, orgLevelService, scheduleHelper, changeManagementService, fileService, notificationService, downloadService) {
        var _this = this;
        this.scheduleApiService = scheduleApiService;
        this.orgLevelService = orgLevelService;
        this.scheduleHelper = scheduleHelper;
        this.changeManagementService = changeManagementService;
        this.fileService = fileService;
        this.notificationService = notificationService;
        this.downloadService = downloadService;
        this.onFiltersChanged$ = new ReplaySubject(1);
        this.changed$ = new ReplaySubject(1);
        this.poolChanged$ = new ReplaySubject(1);
        this.totalHoursChanged$ = new ReplaySubject(1);
        this.censusChange$ = new Subject();
        this.IdealScheduleConfigTypes = IdealScheduleConfigTypes;
        this.isShiftGroupEnabled = false;
        this.isShiftNavPopupEnabled = false;
        this.isDefaultShiftConfigured = false;
        this.actionData = null;
        this.isDefaultShiftGroupFlagEnabled = false;
        this.onIdealScheduleList$ = new ReplaySubject(1);
        this.state = {
            isLoading: true,
            isLoadingPositionPool: false,
            isScheduleAvailable: false,
            isNoDepartmentLevel: false,
            isNoPositionsAvailable: false,
            isError: false
        };
        this.data = {
            orgLevel: null,
            idealSchedule: null,
            selectedPositionDefinition: null,
            selectedPositionDefinitionPeriod: null,
            originalPosition: null,
            selectedPosition: null,
            selectedPositionType: null,
            selectedConfigCensus: null,
            positions: new Map(),
            selectedShiftsGroups: new Map(),
            selectedShifts: new Map(),
            selectedUnits: new Map(),
            hideUnused: false,
            currentRangeConfigPositions: null
        };
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel) {
                _this.subscribe();
            }
        });
    }
    IdealScheduleStateService.prototype.subscribe = function () {
        var _this = this;
        this.scheduleSubscription = this.orgLevel$
            .do(function () { return _this.state.isLoading = true; })
            .switchMap(function (orgLevel) {
            _this.clearState(false);
            _this.data.orgLevel = orgLevel;
            _this.getOrganizationsList(orgLevel.id);
            if (orgLevel.type === OrgLevelType.department) {
                _this.scheduleApiService.getIdealSchedule(orgLevel.id)
                    .then(function (result) {
                    _this.actionData = result.actions;
                    _this.onIdealScheduleSubscribeComplete(result.records);
                    _this.GetIdealShiftValidationData();
                    return result.records;
                });
            }
            else {
                _this.state.isNoDepartmentLevel = true;
                _this.state.isLoading = false;
                return Promise.resolve(null);
            }
        })
            .subscribe(function (list) { return _this.onIdealScheduleSubscribeComplete(list); }, function (error) { return _this.clearState(true); });
    };
    IdealScheduleStateService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    IdealScheduleStateService.prototype.refreshIdealScheduleList = function (orgLevelId) {
        var _this = this;
        this.scheduleApiService.getIdealSchedule(orgLevelId)
            .then(function (result) {
            _this.onIdealScheduleSubscribeComplete(result.records);
        });
    };
    IdealScheduleStateService.prototype.unsubscribe = function () {
        this.scheduleSubscription.unsubscribe();
    };
    Object.defineProperty(IdealScheduleStateService.prototype, "orgLevelId", {
        get: function () {
            return this.data.orgLevel ? this.data.orgLevel.id : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleStateService.prototype, "idealSchedule", {
        get: function () {
            return this.data.idealSchedule;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleStateService.prototype.setIdealSchedule = function (selected) {
        var _this = this;
        this.data.idealSchedule = selected;
        var position = null;
        if (!!selected && !!selected.positions && selected.positions.length > 0) {
            if (this.restorePosition) {
                position = _.find(selected.positions, function (pos) { return pos.position.id === _this.restorePosition.position.id; });
            }
            if (position) {
                this.data.selectedPositionDefinition = null;
            }
            else {
                position = selected.positions[0];
            }
        }
        this.setSelectedPositionDefinition(position);
    };
    Object.defineProperty(IdealScheduleStateService.prototype, "selectedPositionDefinition", {
        get: function () {
            return this.data.selectedPositionDefinition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleStateService.prototype, "selectedPositionDefinitionPeriod", {
        get: function () {
            return this.data.selectedPositionDefinitionPeriod;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleStateService.prototype.setSelectedPositionDefinition = function (selected) {
        // if (selected && this.data.selectedPositionDefinition &&
        //   selected.position.id === this.data.selectedPositionDefinition.position.id) {
        //   return;
        // }
        var _this = this;
        this.data.selectedPositionDefinition = selected;
        this.clearSelectedPosition();
        if (selected) {
            this.data.selectedPositionType = selected.idealScheduleType;
            var range = void 0;
            if (this.restorePeriod) {
                range = _.find(selected.periods, function (r) {
                    return _this.restorePeriod.name === r.name;
                });
            }
            else {
                var now_1 = moment();
                range = _.find(selected.periods, function (r) {
                    return now_1.isSameOrAfter(r.startDate) && now_1.isSameOrBefore(r.endDate);
                });
            }
            if (_.isNil(range)) {
                range = _.get(selected.periods, 0);
            }
            this.data.selectedPositionDefinitionPeriod = range;
            this.clearRestoreData();
            this.loadPosition(range.startDate, range.endDate);
        }
    };
    Object.defineProperty(IdealScheduleStateService.prototype, "selectedPositionType", {
        get: function () {
            return this.data.selectedPositionType;
        },
        set: function (positionType) {
            if (!positionType || (this.data.selectedPositionType && positionType.id === this.data.selectedPositionType.id)) {
                return;
            }
            this.data.selectedPositionType = positionType;
            this.data.selectedPosition = this.getSelectedPosition(this.data.selectedPositionType);
            this.positionChanged();
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleStateService.prototype.changePositionType = function (positionTypeDefinition) {
        this.selectedPositionType = _.find(IdealScheduleConfigTypes, function (idealScheduleConfigType) { return idealScheduleConfigType.id === positionTypeDefinition; });
    };
    Object.defineProperty(IdealScheduleStateService.prototype, "selectedConfigCensus", {
        get: function () {
            return this.data.selectedConfigCensus;
        },
        set: function (configCensus) {
            if (!configCensus || (this.data.selectedConfigCensus && configCensus.id === this.data.selectedConfigCensus.id)) {
                return;
            }
            if (this.data.selectedPosition) {
                this.data.selectedPosition.hasChanges = true;
            }
            this.data.selectedConfigCensus = configCensus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleStateService.prototype, "hideUnused", {
        get: function () {
            return this.data.hideUnused;
        },
        set: function (hideUnused) {
            this.data.hideUnused = hideUnused;
            this.onFiltersChanged$.next(null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleStateService.prototype, "selectedPosition", {
        get: function () {
            return this.data.selectedPosition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleStateService.prototype, "originalPosition", {
        get: function () {
            return this.data.originalPosition;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleStateService.prototype.getSelectedShiftsGroups = function (range) {
        if (!range) {
            range = _.first(this.data.selectedPosition.ranges);
        }
        return this.data.selectedShiftsGroups.get(range) || [];
    };
    IdealScheduleStateService.prototype.setSelectedShiftsGroups = function (models, range) {
        if (!range) {
            range = _.first(this.data.selectedPosition.ranges);
        }
        this.data.selectedShiftsGroups.set(range, models);
    };
    IdealScheduleStateService.prototype.getSelectedShifts = function (range) {
        if (!range) {
            range = _.first(this.data.selectedPosition.ranges);
        }
        return this.data.selectedShifts.get(range) || [];
    };
    IdealScheduleStateService.prototype.setSelectedShifts = function (models, range) {
        if (!range) {
            range = _.first(this.data.selectedPosition.ranges);
        }
        this.data.selectedShifts.set(range, models);
        if (this.data.selectedPosition) {
            this.data.selectedPosition.hasChanges = true;
        }
    };
    IdealScheduleStateService.prototype.getSelectedUnits = function (range) {
        if (!range) {
            range = _.first(this.data.selectedPosition.ranges);
        }
        return this.data.selectedUnits.get(range) || [];
    };
    IdealScheduleStateService.prototype.setSelectedUnits = function (models, range) {
        if (!range) {
            range = _.first(this.data.selectedPosition.ranges);
        }
        this.data.selectedUnits.set(range, models);
        if (this.data.selectedPosition) {
            this.data.selectedPosition.hasChanges = true;
        }
    };
    IdealScheduleStateService.prototype.getSelectedPosition = function (positionType) {
        var position = this.data.positions.get(positionType);
        if (position) {
            return position;
        }
        var clone = this.clonePositionFromExisting(positionType);
        this.data.positions.set(positionType, clone);
        return clone;
    };
    IdealScheduleStateService.prototype.configureShiftGroupData = function (models) {
        var _this = this;
        _.forEach(this.data.selectedPosition.ranges, function (item) {
            _this.setSelectedShifts(models, item);
        });
    };
    IdealScheduleStateService.prototype.positionChanged = function () {
        var _this = this;
        //Due to daterange null issue .so we added terminate condition
        if (this.data.selectedPosition != null) {
            _.forEach(this.data.selectedPosition.ranges, function (range) {
                _this.recalculateHours(range);
                _this.resetAcuityTypeOnTotalCensus(range);
            });
        }
        this.changed$.next(this.data.selectedPosition);
    };
    IdealScheduleStateService.prototype.resetAcuityTypeOnTotalCensus = function (range) {
        var _this = this;
        this.censusChange$.subscribe(function (data) {
            if (data) {
                if (_this.data.selectedConfigCensus.name === 'Total') {
                    range.acuity.acuity.id = _this.data.selectedPosition.ranges[0].acuity.acuity.id;
                    range.acuity.acuity.name = _this.data.selectedPosition.ranges[0].acuity.acuity.name;
                }
            }
        });
    };
    Object.defineProperty(IdealScheduleStateService.prototype, "isFixedCensus", {
        get: function () {
            return this.selectedConfigCensus && this.selectedConfigCensus.id === IdealScheduleConfigCensusDefinition.Fixed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IdealScheduleStateService.prototype, "isRangedCensus", {
        get: function () {
            return this.selectedConfigCensus && this.selectedConfigCensus.id === IdealScheduleConfigCensusDefinition.Range;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleStateService.prototype.isDailyVarianceChanged = function () {
        this.data.selectedPosition.isDailyVariance = !this.data.selectedPosition.isDailyVariance;
        this.changed$.next(this.data.selectedPosition);
    };
    IdealScheduleStateService.prototype.clonePositionFromExisting = function (positionType) {
        var originalPosition = this.data.positions.get(_.find(IdealScheduleConfigTypes, function (idealScheduleConfigType) { return idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftUnit; }))
            || this.data.positions.get(_.find(IdealScheduleConfigTypes, function (idealScheduleConfigType) { return idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.Shift; }))
            || this.data.positions.get(_.find(IdealScheduleConfigTypes, function (idealScheduleConfigType) { return idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftGroup; }));
        if (!originalPosition) {
            return null;
        }
        this.data.originalPosition = null;
        var position = _.cloneDeep(originalPosition);
        position.idealScheduleType = positionType;
        this.configureIdealSchedule(position, positionType);
        return position;
    };
    IdealScheduleStateService.prototype.cloneRange = function (range) {
        if (!this.data.selectedPosition) {
            return;
        }
        var clone = _.cloneDeep(range);
        clone.id = -1;
        this.data.selectedPosition.ranges.push(clone);
    };
    IdealScheduleStateService.prototype.deleteRange = function (rangeIndex) {
        this.data.selectedPosition.ranges.splice(rangeIndex, 1);
        this.rangeChanged();
    };
    IdealScheduleStateService.prototype.setSelectedPosition = function (positionType, selected) {
        this.data.positions.clear();
        this.data.positions.set(positionType, selected);
        this.data.selectedPositionType = positionType;
        this.data.selectedConfigCensus = selected.idealScheduleCensus;
        this.data.selectedPosition = this.getSelectedPosition(positionType);
        this.positionChanged();
    };
    IdealScheduleStateService.prototype.selectedPositionPeriodChanged = function (selected) {
        if (this.data.selectedPosition && this.data.selectedPosition.name === this.selectedPositionDefinition.position.name) {
            return;
        }
        this.data.selectedPositionDefinitionPeriod = selected;
        this.loadPosition(selected.startDate, selected.endDate);
    };
    IdealScheduleStateService.prototype.clearSelectedPosition = function () {
        this.data.positions.set(_.find(IdealScheduleConfigTypes, function (idealScheduleConfigType) { return idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftGroup; }), null);
        this.data.positions.set(_.find(IdealScheduleConfigTypes, function (idealScheduleConfigType) { return idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.ShiftUnit; }), null);
        this.data.positions.set(_.find(IdealScheduleConfigTypes, function (idealScheduleConfigType) { return idealScheduleConfigType.id === IdealScheduleConfigTypeDefinition.Shift; }), null);
        this.data.selectedPosition = null;
    };
    IdealScheduleStateService.prototype.resetShiftRowHours = function (range, rowUniqueId) {
        var current = _.find(range.parLevels, function (level) {
            return level.uniqueId === rowUniqueId;
        });
        var hasChanges = false;
        for (var dayNumber = 0; dayNumber <= 7; dayNumber++) {
            hasChanges = hasChanges || current.getDayCounter(dayNumber) > 0 || current.getDayHours(dayNumber) > 0;
            current.setDayCounter(dayNumber, 0);
            current.setDayHours(dayNumber, 0);
        }
        this.recalculateHours(range);
        if (hasChanges) {
            this.selectedPosition.hasChanges = true;
        }
    };
    IdealScheduleStateService.prototype.applyToAllDays = function (range, rowUniqueId) {
        var current = _.find(range.parLevels, function (level) {
            return level.uniqueId === rowUniqueId;
        });
        current.isDailyVariance = !current.isDailyVariance;
        this.recalculateHours(range);
    };
    IdealScheduleStateService.prototype.resetDailyHours = function (range, dayNumber) {
        _.forOwn(range.parLevels, function (level) {
            level.setDayCounter(dayNumber, 0);
            level.setDayHours(dayNumber, 0);
        });
        this.recalculateHours(range);
    };
    IdealScheduleStateService.prototype.setDayCounter = function (schedule, range, parLevelUniqueId, dayNumber, value) {
        var parLevel = range.parLevels[parLevelUniqueId];
        if (!parLevel) {
            return;
        }
        if (!value) {
            value = 0;
        }
        if (!this.data.originalPosition) {
            this.data.originalPosition = _.cloneDeep(schedule);
        }
        schedule.hasChanges = true;
        range.hasChanges = true;
        this.changeManagementService.changeNotify();
        var duration = parLevel.shift ? parLevel.shift.durationHours : parLevel.shiftGroup.durationHours;
        parLevel.setDayCounter(dayNumber, value);
        parLevel.setDayHours(dayNumber, value * duration);
        range.counters[dayNumber] = this.scheduleHelper.calculateDayCounterHours(range, dayNumber);
        if (dayNumber === 0) {
            for (var dayN = 1; dayN <= 7; dayN++) {
                parLevel.setDayCounter(dayN, parLevel.days[0].counter);
                parLevel.setDayHours(dayN, parLevel.days[0].hours);
                range.counters[dayN] = this.scheduleHelper.calculateDayCounterHours(range, dayN);
            }
            range.totalHours = range.counters[dayNumber];
            //this.recalculateTotalHours(schedule);
            this.totalHoursChanged$.next(this.data.selectedPosition);
        }
    };
    IdealScheduleStateService.prototype.recalculateHours = function (range) {
        for (var dayNumber = 0; dayNumber <= 7; dayNumber++) {
            range.counters[dayNumber] = this.scheduleHelper.calculateDayCounterHours(range, dayNumber);
        }
        range.totalHours = range.counters[0];
        //this.recalculateTotalHours(range);
        this.totalHoursChanged$.next(this.data.selectedPosition);
    };
    IdealScheduleStateService.prototype.reconfigureRangeParLevels = function (range) {
        if (!range) {
            return;
        }
        var parLevels;
        var selectedUnits = this.getSelectedUnits(range);
        var selectedShifts = this.getSelectedShifts(range);
        var selectedShiftsGroups = this.getSelectedShiftsGroups(range);
        switch (this.data.selectedPositionType.id) {
            case IdealScheduleConfigTypeDefinition.Shift:
                parLevels = this.scheduleHelper.getParLevelsForShift(range.parLevels, selectedShifts);
                break;
            case IdealScheduleConfigTypeDefinition.ShiftUnit:
                parLevels = this.scheduleHelper.getParLevelsForShiftAndUnit(range.parLevels, selectedShifts, selectedUnits);
                break;
            case IdealScheduleConfigTypeDefinition.ShiftGroup:
                parLevels = this.scheduleHelper.getParLevelsForShiftGroup(range.parLevels, selectedShiftsGroups);
                break;
            default:
                throw new Error('Unknown type of IdealScheduleConfigTypeDefinition');
        }
        range.parLevels = parLevels;
        this.positionChanged();
    };
    IdealScheduleStateService.prototype.reconfigureIdealSchedule = function () {
        if (!this.data.selectedPosition || !this.data.selectedPositionType) {
            return;
        }
        this.configureIdealSchedule(this.data.selectedPosition, this.data.selectedPositionType);
    };
    IdealScheduleStateService.prototype.configureIdealSchedule = function (position, positionType) {
        var _this = this;
        if (!position || !positionType) {
            return;
        }
        _.forEach(position.ranges, function (range) {
            var parLevels;
            var selectedUnits = _this.getSelectedUnits(range);
            var selectedShifts = _this.getSelectedShifts(range);
            //const selectedShiftsGroups: LookupMultiselectModel[] = this.getSelectedShiftsGroups(range);
            switch (positionType.id) {
                case IdealScheduleConfigTypeDefinition.Shift:
                    parLevels = _this.scheduleHelper.getParLevelsForShift(range.parLevels, selectedShifts);
                    break;
                case IdealScheduleConfigTypeDefinition.ShiftUnit:
                    parLevels = _this.scheduleHelper.getParLevelsForShiftAndUnit(range.parLevels, selectedShifts, selectedUnits);
                    break;
                case IdealScheduleConfigTypeDefinition.ShiftGroup:
                    parLevels = _this.scheduleHelper.getParLevelsForShift(range.parLevels, selectedShifts);
                    //parLevels = this.scheduleHelper.getParLevelsForShiftGroup(range.parLevels, selectedShiftsGroups);
                    break;
                default:
                    throw new Error('Unknown type of IdealScheduleConfigTypeDefinition');
            }
            range.parLevels = parLevels;
        });
        this.positionChanged();
    };
    /*
    public saveTargetHours(definition: IdealSchedulePositionDefinition, value: number): void {
  
      if (definition) {
        definition.targetHours = value;
  
        this.scheduleApiService.saveIdealScheduleConfiguration(
          this.data.idealSchedule.id,
          this.orgLevelService.getCurrentOrgLevel().id,
          definition.startDate,
          definition.endDate,
          definition.position.id,
          value)
          .then((success: any) => {
            if (this.selectedPosition && this.selectedPosition.position.name === definition.position.name && this.selectedPosition.isActive) {
              this.selectedPosition.targetHours = definition.targetHours;
              this.totalHoursChanged$.next(this.selectedPosition);
            }
          })
          .catch((error: any) => {
            this.clearState(true);
          });
      }
    }
    */
    IdealScheduleStateService.prototype.savePosition = function (position, startDate, endDate) {
        var _this = this;
        position.hasChanges = true;
        this.changeManagementService.changeNotify();
        this.state.isLoadingPositionPool = true;
        var isPeriodChanged = !(moment(position.startDate).isSame(startDate) && moment(position.endDate).isSame(endDate));
        this.scheduleApiService.saveIdealSchedulePosition(this.data.idealSchedule.id, this.orgLevelService.getCurrentOrgLevel().id, this.data.selectedConfigCensus, startDate, endDate, position)
            .then(function (success) {
            _this.clearSelectedPosition();
            MasterScheduleManagementService.firstLoad = true;
            position.hasChanges = false;
            _this.changeManagementService.clearChanges();
            if (isPeriodChanged) {
                _this.restorePosition = position;
                _this.restorePeriod = new IdealSchedulePositionPeriod();
                _this.restorePeriod.startDate = startDate;
                _this.restorePeriod.endDate = endDate;
                _this.refreshIdealScheduleList(_this.orgLevelService.getCurrentOrgLevel().id);
            }
            else {
                _this.loadPosition(_this.data.selectedPositionDefinitionPeriod.startDate, _this.data.selectedPositionDefinitionPeriod.endDate);
            }
        })
            .catch(function (error) { return _this.clearState(true); });
    };
    IdealScheduleStateService.prototype.discardChanges = function () {
        var position = this.selectedPosition;
        if (_.isNull(this.selectedPosition))
            return;
        this.loadPosition(position.startDate, position.endDate);
    };
    IdealScheduleStateService.prototype.isCurrentOrFutureSchedule = function (position) {
        if (!position) {
            return null;
        }
        return moment(position.endDate).isSameOrAfter(moment(new Date()));
    };
    IdealScheduleStateService.prototype.rangeChanged = function () {
        if (!this.selectedPosition) {
            return;
        }
        this.selectedPosition.hasChanges = true;
    };
    IdealScheduleStateService.prototype.isRangeMinValueValid = function (checkedRange, checkedRangeIndex) {
        if (!this.selectedPosition || !this.selectedPosition.ranges.length) {
            return true;
        }
        var otherRanges = this.getOtherRangesByAcuity(checkedRange, checkedRangeIndex);
        return !_.some(otherRanges, function (otherRange) {
            return checkedRange.acuity.minValue >= otherRange.acuity.minValue
                && checkedRange.acuity.minValue <= otherRange.acuity.maxValue;
        });
    };
    IdealScheduleStateService.prototype.isRangeMaxValueValid = function (checkedRange, checkedRangeIndex) {
        if (!this.selectedPosition || !this.selectedPosition.ranges.length) {
            return true;
        }
        var otherRanges = this.getOtherRangesByAcuity(checkedRange, checkedRangeIndex);
        return !_.some(otherRanges, function (otherRange) {
            return checkedRange.acuity.maxValue >= otherRange.acuity.minValue
                && checkedRange.acuity.maxValue <= otherRange.acuity.maxValue;
        });
    };
    IdealScheduleStateService.prototype.isRangeValid = function (checkedRange, checkedRangeIndex) {
        if (!this.selectedPosition || !this.selectedPosition.ranges.length) {
            return true;
        }
        return this.rangeHasConflictingValues(checkedRange, checkedRangeIndex);
    };
    IdealScheduleStateService.prototype.areRangesValid = function () {
        var _this = this;
        if (!this.selectedPosition || !this.selectedConfigCensus || this.isFixedCensus || !this.selectedPosition.ranges.length) {
            return true;
        }
        var valid = true;
        _.forEach(this.selectedPosition.ranges, function (range, index) {
            if (_.isNull(range.acuity.minValue) || _.isNull(range.acuity.maxValue)
                || _.isUndefined(range.acuity.minValue) || _.isUndefined(range.acuity.maxValue)
                || range.acuity.maxValue === 0) {
                valid = false;
                return;
            }
            if (!range.parLevels) {
                valid = false;
                return;
            }
            var isConflicting = _this.rangeHasConflictingValues(range, index);
            if (isConflicting) {
                valid = false;
                return;
            }
        });
        return valid;
    };
    IdealScheduleStateService.prototype.allNecessaryFieldsFilled = function () {
        if (!this.data.selectedPositionType)
            return false;
        return true;
    };
    IdealScheduleStateService.prototype.canBeSaved = function () {
        return this.selectedPosition.hasChanges && this.areRangesValid() && this.allNecessaryFieldsFilled();
    };
    Object.defineProperty(IdealScheduleStateService.prototype, "isLoading", {
        get: function () {
            return this.state.isLoading || this.state.isLoadingPositionPool;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleStateService.prototype.clearRestoreData = function () {
        this.restorePeriod = null;
        this.restorePosition = null;
    };
    IdealScheduleStateService.prototype.rangeHasConflictingValues = function (range, index) {
        return _.some(this.getOtherRangesByAcuity(range, index), function (otherRange) {
            var minRange;
            var maxRange;
            if (range.acuity.minValue < otherRange.acuity.minValue) {
                minRange = range;
                maxRange = otherRange;
            }
            else {
                minRange = otherRange;
                maxRange = range;
            }
            return minRange.acuity.maxValue >= maxRange.acuity.minValue;
        });
    };
    IdealScheduleStateService.prototype.getOtherRangesByAcuity = function (checkedRange, checkedRangeIndex) {
        return _.filter(this.selectedPosition.ranges, function (range, index) {
            return index !== checkedRangeIndex
                && range.acuity.acuity
                && checkedRange.acuity.acuity
                && range.acuity.acuity.id === checkedRange.acuity.acuity.id;
        });
    };
    IdealScheduleStateService.prototype.clearState = function (changeLoading) {
        this.state.isScheduleAvailable = false;
        this.state.isNoDepartmentLevel = false;
        this.state.isNoPositionsAvailable = false;
        this.state.isError = false;
        if (changeLoading) {
            this.state.isLoading = false;
        }
    };
    IdealScheduleStateService.prototype.onIdealScheduleSubscribeComplete = function (list) {
        if (list !== null) {
            this.state.isScheduleAvailable = list !== null && list.positions && list.positions.length > 0;
            this.state.isNoPositionsAvailable = list !== null && list.positions && list.positions.length === 0;
            this.setIdealSchedule(list);
        }
        this.state.isLoading = false;
        this.data.currentRangeConfigPositions = list.positions;
        this.onIdealScheduleList$.next(list);
    };
    IdealScheduleStateService.prototype.loadPosition = function (startDate, endDate) {
        var _this = this;
        this.state.isLoadingPositionPool = true;
        return this.scheduleApiService.getIdealSchedulePosition(this.orgLevelId, this.selectedPositionDefinition.position.id, startDate, endDate)
            .then(function (value) {
            return _this.loadPositionSuccess(value);
        })
            .catch(function (ex) {
            _this.state.isLoadingPositionPool = false;
        });
    };
    IdealScheduleStateService.prototype.loadPositionSuccess = function (position) {
        var _this = this;
        this.setSelectedPosition(position.idealScheduleType, position);
        _.forEach(position.ranges, function (range) {
            var parLevels = _.values(range.parLevels);
            _this.setSelectedUnits(_this.scheduleHelper.getSelectedUnitsFromParLevels(parLevels), range);
            _this.setSelectedShifts(_this.scheduleHelper.getSelectedShiftsFromParLevels(parLevels), range);
            _this.setSelectedShiftsGroups(_this.scheduleHelper.getSelectedShiftGroupsFromParLevels(parLevels), range);
            _this.recalculateHours(range);
        });
        position.targetHours = this.data.selectedPositionDefinition.getTargetHours();
        this.state.isLoadingPositionPool = false;
        this.data.selectedPosition.hasChanges = false;
        this.changeManagementService.clearChanges();
        this.poolChanged$.next(position);
        return position;
    };
    // private getPositionByDates(pools: IdealSchedulePosition[], startDate: moment.Moment, endDate: moment.Moment): IdealSchedulePosition {
    //   if (!pools || pools.length === 0) {
    //     return new IdealSchedulePosition();
    //   }
    //   let position: any = _.find(pools, (elem: IdealSchedulePosition) => {
    //     return elem.startDate === startDate && elem.endDate === endDate;
    //   });
    //   return !position ? pools[0] : position;
    // }
    IdealScheduleStateService.prototype.recalculateTotalHours = function (value) {
        if (this.data.selectedPositionDefinition) {
            this.data.selectedPositionDefinition.totalHours = value.totalHours;
        }
    };
    IdealScheduleStateService.prototype.GetIdealShiftValidationData = function () {
        var _this = this;
        this.scheduleApiService.GetIdealShiftValidation(this.data.orgLevel.id).then(function (idealScheduleGroup) {
            _this.shiftGroupDetailss = idealScheduleGroup;
            _this.isDefaultShiftConfigured = idealScheduleGroup.shiftGroupCount < 3 ? true : false;
            _this.modifyOption(_this.IdealScheduleConfigTypes);
        });
    };
    IdealScheduleStateService.prototype.modifyOption = function (options) {
        this.IdealScheduleConfigTypes = this.shiftGroupDetailss.flagStatus ? options : _.filter(options, function (item) { return item.id !== 1; });
        return this.IdealScheduleConfigTypes;
    };
    IdealScheduleStateService.prototype.getOrganizationsList = function (orgLevelId) {
        var _this = this;
        this.scheduleApiService.getOrganizationsList(orgLevelId).then(function (data) {
            _this.organizationDetails = data;
        });
    };
    IdealScheduleStateService.prototype.checkPositionsValidation = function (modalService, uniqueId, importData) {
        var _this = this;
        this.state.isLoading = true;
        var selectedShiftGroupData = importData.positionList.filter(function (x) { return x.isChecked && x.idealScheduleType.id == 1; });
        if (selectedShiftGroupData && selectedShiftGroupData.length > 0) {
            if (this.isDefaultShiftConfigured) {
                var exceptionData_1 = _.map(selectedShiftGroupData, function (item) {
                    var data = new ImportException();
                    data.positionId = item.position.id;
                    data.positionName = item.position.name;
                    data.configurationType = item.idealScheduleType.name;
                    data.censusType = item.idealScheduleCensus.name;
                    data.errorMessage = 'Default Shifts Not Configured';
                    data.isInvalidConfigType = true;
                    data.isInvalidCensusType = false;
                    data.isNoException = false;
                    return data;
                });
                setTimeout(function () {
                    _this.state.isLoading = false;
                    importData.exceptionData = exceptionData_1;
                }, 1000);
                return importData;
            }
            else {
                return this.downloadImportTemplate(modalService, uniqueId, importData);
            }
        }
        else {
            return this.downloadImportTemplate(modalService, uniqueId, importData);
        }
    };
    IdealScheduleStateService.prototype.downloadImportTemplate = function (modalService, uniqueId, data) {
        var _this = this;
        this.state.isLoading = true;
        this.scheduleApiService.downloadImportTemplate(this.orgLevelId, data).then(function (resultSet) {
            if (resultSet.fileData != null && resultSet.fileName != null) {
                _this.downloadService.excelDownloadDataProcess(resultSet.fileData, resultSet.fileName);
                _this.state.isLoading = false;
                data.positionList.map(function (x) { return x.isChecked = false; });
                return modalService.closeWindow(uniqueId);
            }
            else {
                _this.notificationService.error('Error', 'Shifts Not Configured');
                _this.state.isLoading = false;
                data.positionList.map(function (x) { return x.isChecked = false; });
                return modalService.closeWindow(uniqueId);
            }
        }).catch(function (reason) {
            console.log(reason);
            _this.state.isLoading = false;
            data.positionList.map(function (x) { return x.isChecked = false; });
            return modalService.closeWindow(uniqueId);
        });
    };
    Object.defineProperty(IdealScheduleStateService.prototype, "currentRangeConfigPositions", {
        get: function () {
            return this.data.currentRangeConfigPositions;
        },
        enumerable: true,
        configurable: true
    });
    IdealScheduleStateService.prototype.isAllNoException = function (data) {
        return _.every(data, function (item) { return item.isConfigured && !item.isInvalidCensusType && !item.isInvalidConfigType; });
    };
    IdealScheduleStateService.prototype.updateIdealScheduleId = function (importTemplate, exceptionData) {
        _.map(importTemplate.positionList, function (item) {
            if (exceptionData) {
                _.forEach(exceptionData, function (exception) {
                    if (item.position.id == exception.positionId) {
                        item.idealScheduleId = exception.idealScheduleId;
                    }
                });
            }
        });
        return importTemplate;
    };
    IdealScheduleStateService.prototype.getUniqueId = function () {
        return UUID();
    };
    tslib_1.__decorate([
        mutableSelect(),
        tslib_1.__metadata("design:type", Observable)
    ], IdealScheduleStateService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], IdealScheduleStateService.prototype, "onTotalHoursSubscription", void 0);
    return IdealScheduleStateService;
}());
export { IdealScheduleStateService };
