import { Component } from '@angular/core';
import { BusyService } from "../../../common/services";
import { NotificationsService } from '../../../core/components';
import { Department, Organization, Position } from '../../../organization/models/index';
import { WfmSyncService } from '../../services/wfm/wfm-sync.service';
import { BaseSyncComponent, SyncItem } from './wfm-sync-base.component';

@Component({
  template: ''
})
export abstract class BaseFilterSyncComponent<T extends SyncItem> extends BaseSyncComponent<T> {
  organizationLookup: Organization[];
  selectedOrganization: Organization;
  departmentLookup: Department[];
  selectedDepartment: Department;
  positionLookup: Position[];
  selectedPosition: Position;
  canSelectPosition: boolean = false;

  constructor(
    protected wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.fetchOrganizationLookup();
  }

  async onOrganizationFilterChanged(): Promise<void> {
    this.departmentLookup = null;
    this.positionLookup = null;
    this.selectedDepartment = null;
    this.selectedPosition = null;

    await this.fetchDepartmentLookup();
    this.setGlobalFilter();
  }

  async onDepartmentFilterChanged(dep: Department): Promise<void> {
    this.positionLookup = null;
    this.selectedPosition = null;
    if(dep.name=="ALL"){
      this.canSelectPosition = true;
    }else{
      this.canSelectPosition = false;
    }

    await this.fetchPositionLookup();
    this.setGlobalFilter();
  }

  onPositionFilterChanged(): void {
    this.setGlobalFilter();
  }

  private async fetchOrganizationLookup(): Promise<void> {
    const response = await this.busyService.busy(
      this.wfmSyncService.orgDataDetails(0, true)
    );
    this.organizationLookup = response.data;
  }

  private async fetchDepartmentLookup(): Promise<void> {
    const response = await this.busyService.busy(
      this.wfmSyncService.depDataDetails(null, this.selectedOrganization.orgLevelId, true)
    );
    this.departmentLookup = response.data;
  }

  private async fetchPositionLookup(): Promise<void> {
    const response = await this.busyService.busy(
      this.wfmSyncService.posDataDetails(null, this.selectedDepartment.orgLevelId, true)
    );
    this.positionLookup = response.data;
  }

  protected abstract setGlobalFilter(): void;
}
