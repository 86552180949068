import { isDateRange } from './../../../core/models/calendar-data/date-range';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';


import { UserSettingsService } from '../../../core/services/index';
import {
  IControlState, IControlStates, IComponentStateStorage, IControlStateStorage,
  IGridState, IGridStates, isIControlStates,
  ControlStateKey, StateResetTypes, UserSettings
} from '../../../core/models/index';
import { Assert } from '../../../framework/index';
import { dateTimeUtils } from '../../utils/index';
import { AppUserSettingsService } from '../../services/app-user-settings/app-user-settings.service';
import { AppUserSetting } from '../../models/index';
/*
  SessionEnd = 1 << 0,
  OrgLevelChange = 1 << 1,
  MenuChange = 1 << 2
*/
@Injectable()
export class ComponentStateStorageService {

  constructor(private userSettingsService: UserSettingsService, private appUserSettingsService: AppUserSettingsService) {
  }

  public clearComponentStates(resetTypes: StateResetTypes): void {
    let settings: UserSettings = this.userSettingsService.get();
    let componentsStorage: IComponentStateStorage = settings.componentStateStorage;
    let controlsStorage: IControlStateStorage;
    let states: IControlStates;
    if (!componentsStorage) {
      return;
    }
    _.forOwn(componentsStorage.components, (controlsStorage: IControlStateStorage) => {
      _.forOwn(controlsStorage.controls, (states: IControlStates) => {
        if (states.resetType & resetTypes) {
          states.states = {};
        }
      });
      _.forOwn(controlsStorage.grids, (gridStates: IGridStates) => {
        _.forOwn(gridStates.states, (state: IGridState) => {
          if (resetTypes & StateResetTypes.OrgLevelChange || resetTypes & StateResetTypes.SessionEnd) {
            state.scrollLeft = 0;
            state.scrollTop = 0;
            state.skip = 0;
          }
          if (resetTypes & StateResetTypes.SessionEnd) {
            state.filters = {};
          }
        });
      });
    });

    if (resetTypes & StateResetTypes.SessionEnd) {
      this.clearGridStates(settings);
    }
    this.userSettingsService.set(settings);
  }

  public restoreServerControlsStorage(component: string, forceReload?: boolean): Promise<IControlStateStorage> {
    if (this.appUserSettingsService.isLocalOrRestoredGroup(component, forceReload)) {
      let settings: UserSettings = this.userSettingsService.get();
      let controlsStorage: IControlStateStorage = this.getControlsStorageLocal(component, settings);
      return Promise.resolve(controlsStorage);
    }

    let promise: Promise<IControlStateStorage> = this.appUserSettingsService.getFromServer(component)
      .then((appSettings: AppUserSetting[]) => {
        let settings: UserSettings = this.userSettingsService.get();
        let controlsStorage: IControlStateStorage = this.getControlsStorageLocal(component, settings);
        _.forEach(appSettings, (appSett: AppUserSetting) => {
          let conf = this.appUserSettingsService.getConfigurationByServerNames(appSett.settingsGroup, appSett.name);
          if (conf && conf.serverSide) {
            if (!controlsStorage.controls[appSett.name] || !controlsStorage.controls[appSett.name].states) {
              controlsStorage.controls[appSett.name] = { states: {}, resetType: StateResetTypes.None };
            }
            let states: IControlStates = controlsStorage.controls[appSett.name];
            states.states[ControlStateKey.DEFAULT_KEY] = { value: appSett.value };
          }
        });
        this.userSettingsService.set(settings);
        return controlsStorage;
      }).catch(() => {
        let settings: UserSettings = this.userSettingsService.get();
        let controlsStorage: IControlStateStorage = this.getControlsStorageLocal(component, settings);
        return controlsStorage;
      });
    return promise;
  }

  public getControlState(component: string, control: string, key?: ControlStateKey): IControlState {
    let settings: UserSettings = this.userSettingsService.get();
    const keyString: string = key ? key.createKeyString() : ControlStateKey.DEFAULT_KEY;
    let controlsStorage: IControlStateStorage = this.getControlsStorageLocal(component, settings);
    if (!controlsStorage) {
      return {};
    }
    let states: IControlStates;
    let state: IControlState;

    if (!controlsStorage.controls[control]) {
      return {};
    }
    states = controlsStorage.controls[control];
    if (!states.states) {
      return {};
    }
    state = states.states[keyString];
    if (!state) {
      state = {};
    }
    return state;
  }

  public setControlState(component: string, control: string, state: IControlState, resetType: StateResetTypes, key?: ControlStateKey): void {
    let settings: UserSettings = this.userSettingsService.get();
    let controlsStorage: IControlStateStorage = this.getControlsStorageLocal(component, settings);
    let states: IControlStates;
    if (!controlsStorage.controls[control]) {
      controlsStorage.controls[control] = { states: {}, resetType: resetType };
    }
    states = controlsStorage.controls[control];
    states.resetType = resetType;

    const keyString: string = key ? key.createKeyString() : ControlStateKey.DEFAULT_KEY;
    states.states[keyString] = state;
    this.userSettingsService.set(settings);
    let conf = this.appUserSettingsService.getConfigurationByLocalNames(component, control);
    if (conf && conf.serverSide) {
      this.appUserSettingsService.saveToServer([{ settingsGroup: this.appUserSettingsService.getServerGroupName(component), name: conf.serverName, value: state.value }]);
    }
  }

  public getGridState(component: string, grid: string, key?: ControlStateKey): IGridState {
    const keyString: string = key ? key.createKeyString() : ControlStateKey.DEFAULT_KEY;
    let settings: UserSettings = this.userSettingsService.get();
    let controlsStorage: IControlStateStorage = this.getControlsStorageLocal(component, settings);
    let states: IGridStates;
    let state: IGridState;

    states = controlsStorage.grids[grid];
    if (!states) {
      return { filters: {}, sort: [] };
    }
    state = states.states[keyString];
    if (!state) {
      state = { filters: {}, sort: [] };
    }
    return state;
  }

  public setGridState(component: string, grid: string, state: IGridState, key?: ControlStateKey): void {
    let settings: UserSettings = this.userSettingsService.get();
    let controlsStorage: IControlStateStorage = this.getControlsStorageLocal(component, settings);
    let states: IGridStates;
    if (!controlsStorage.grids[grid]) {
      controlsStorage.grids[grid] = { states: {} };
    }
    states = controlsStorage.grids[grid];
    const keyString: string = key ? key.createKeyString() : ControlStateKey.DEFAULT_KEY;
    states.states[keyString] = state;
    this.userSettingsService.set(settings);
  }

  public mapValueToState(value: any, state: IControlState): IControlState {
    if (_.isNil(value)) {
      state.type = null;
      state.value = null;
      return state;
    }
    if (_.isDate(value)) {
      state.type = 'date';
      state.value = dateTimeUtils.convertToDtoDateTimeString(value);
      return state;
    }
    if (_.isString(value)) {
      state.type = 'string';
      state.value = value;
      return state;
    }
    if (_.isNumber(value)) {
      state.type = 'string';
      state.value = value;
      return state;
    }
    if (_.isBoolean(value)) {
      state.type = 'boolean';
      state.value = value;
      return state;
    }
    if (_.isArray(value)) {
      state.type = 'array';
      state.value = value;
      return state;
    }
    if (moment.isMoment(value)) {
      state.type = 'moment';
      state.value = dateTimeUtils.convertToDtoDateTimeString(value.toDate());
      return state;
    }
    if (isDateRange(value)) {
      state.type = 'dateRange';
      state.value = {};
      state.value.startDate = dateTimeUtils.convertToDtoDateTimeString(value.startDate);
      state.value.endDate = dateTimeUtils.convertToDtoDateTimeString(value.endDate);
      return state;
    }
    throw new TypeError('can`t map unknown type of value');
  }

  public mapValueFromState(state: IControlState): any {
    switch (state.type) {
      case 'date':
        return dateTimeUtils.convertFromDtoDateTimeString(state.value);
      case 'string':
        return state.value;
      case 'number':
        return state.value;
      case 'boolean':
        return state.value;
      case 'array':
        return state.value;
      case 'moment':
        return moment(dateTimeUtils.convertFromDtoDateTimeString(state.value));
      case 'dateRange':
        let range: any = {};
        range.startDate = dateTimeUtils.convertFromDtoDateTimeString(state.value.startDate);
        range.endDate = dateTimeUtils.convertFromDtoDateTimeString(state.value.endDate);
        return range;
      default:
        return null;
    }
  }

  public mapFiltersIntoState(filter: { logic: string, filters: Array<any> }): { logic: string, filters: Array<any> } {
    _.forEach(filter.filters, f => {
      if (_.isString(f.logic) && _.isArray(f.filters)) {
        this.mapFiltersIntoState(f);
      } else {
        f.value = this.mapValueToState(f.value, {});
      }
    });

    return filter;
  }

  public mapStateIntoFilters(filter: { logic: string, filters: Array<any> }): { logic: string, filters: Array<any> } {
    _.forEach(filter.filters, f => {
      if (_.isString(f.logic) && _.isArray(f.filters)) {
        this.mapStateIntoFilters(f);
      } else {
        f.value = this.mapValueFromState(f.value);
      }
    });

    return filter;
  }

  private clearGridStates(settings: UserSettings): void {
    let componentsStorage: IComponentStateStorage = settings.componentStateStorage;
    let controlsStorage: IControlStateStorage;
    if (!componentsStorage) {
      return;
    }
    _.forOwn(componentsStorage.components, (controlsStorage: IControlStateStorage) => {
      controlsStorage.grids = {};
    });
  }

  private getControlsStorageLocal(component: string, settings: UserSettings): IControlStateStorage {
    let componentsStorage: IComponentStateStorage = settings.componentStateStorage;
    if (!componentsStorage) {
      componentsStorage = { components: {} };
      settings.componentStateStorage = componentsStorage;
      this.userSettingsService.set(settings);
    }
    if (!componentsStorage.components[component]) {
      componentsStorage.components[component] = { controls: {}, grids: {} };
      this.userSettingsService.set(settings);
    }
    return componentsStorage.components[component];
  }

}
