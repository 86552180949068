<slx-spinner [show]="employmentValidationLoading">
<div class="wizard-container">
  <form class="form-horizontal" #employmentForm="ngForm">
    <div class="col-lg-3">
        <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.payrollNumber.isVisible">
          <slx-input-decorator>
            <input slx-input maxlength="20"
              class="form-control"
              [inputPolicy]="addEmployeeRestrictions?.payrollNumber.Policy"
              [server]="{validationName: payrollNumberValidation, parameters: [addEmployeeModel.organization.orgLevelId], validationAdapter: employeeActivitiesValidatorAdapter}"
              [(ngModel)]="addEmployeeModel.payrollNumber"
              placeholder="Payroll Number"
              name="payrollNumber"
              [readonly]="isNgpUser ? true : false"
              type="text">
            <span errorMessage for="required"></span>
            <span errorMessage [for]="payrollNumberValidation">msg from server</span>
          </slx-input-decorator>
        </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.ssn.isVisible">
        <slx-input-decorator>
          <slx-ssn-input slx-input name="ssn"
                         [server]="{validationName: ssnValidation, parameters: [addEmployeeModel.organization.orgLevelId], validationAdapter: employeeActivitiesValidatorAdapter}"
                         ssn placeholder="SSN"
                         [inputPolicy]="addEmployeeRestrictions?.ssn.Policy"
                         [(ngModel)]="addEmployeeModel.ssn"
                         [required]="addEmployeeRestrictions?.ssn.isRequired"
                         >
          </slx-ssn-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="ssn"></span>
          <span errorMessage [for]="ssnValidation">msg from server</span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            [ngModel]="addEmployeeModel.hireDate"
            (ngModelChange)="onHireDateChange($event)"
            placeholder="Hire Date"
            name="hireDate"
            [acceptNullDate]="false"
            required
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <span errorMessage for="date"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.birthDate.isVisible">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            [(ngModel)]="addEmployeeModel.birthDate"
            [inputPolicy]="addEmployeeRestrictions?.birthDate.Policy"
            [maxDate]="maxBirthDate"
            placeholder="Date of Birth"
            [acceptNullDate]="false"
            name="birthDate"
            required
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <span errorMessage for="date"></span>
          <span errorMessage for="maxDate">Date of Hire cannot be within 14 years of date of birth</span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.payPolicy.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'payPolicy', orgLevelId: addEmployeeModel.department?.orgLevelId}"
                              [inputPolicy]="addEmployeeRestrictions?.payPolicy.Policy"
                              name="payPolicy"
                              placeholder="Pay Policy"
                              [(ngModel)]="addEmployeeModel.payPolicy">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.shiftDiffPolicy.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'shiftDiffPolicy', orgLevelId: addEmployeeModel.department?.orgLevelId}"
                              [inputPolicy]="addEmployeeRestrictions?.shiftDiffPolicy.Policy"
                              name="shiftDiffPolicy"
                              placeholder="Shift Diff Policy"
                              [(ngModel)]="addEmployeeModel.shiftDiffPolicy">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="col-md-10 col-md-offset-1">
        <slx-spinner [show]="state.isPayTypeLoading">
        <div class="row">
          <div class="col-xs-6" *ngFor="let type of payTypeLookup; let idx = index;">
            <slx-input-decorator className="slx-no-border">
              <slx-radio-input slx-input
                               name="payType{{type.name}}"
                               fieldName="{{type.name}}"
                               valueField="name"
                               [option]="type"
                               [caption]="type.name"
                               [placeholder]="idx==0?'Pay Type':''"
                               [(ngModel)]="addEmployeeModel.payType">
              </slx-radio-input>
            </slx-input-decorator>
          </div>
        </div>
        </slx-spinner>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.exemptStatus.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'exemptStatus'}"
                              [inputPolicy]="addEmployeeRestrictions?.exemptStatus.Policy"
                              name="exemptStatus"
                              placeholder="Exempt Status"
                              [(ngModel)]="addEmployeeModel.exemptStatus">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" >
        <slx-input-decorator>
          <slx-kendo-number slx-input number
            [(ngModel)]="addEmployeeModel.workWeekHours"
            [workHoursValidation]="isSalariedWithoutHours"
            [decimals]="2"
            [min]="0"
            [slxMin]="0"
            [max]="168"
            [slxMax]="168"
            [required]="isSalaryPayType"
            name="standardWeeklyHours"
            placeholder="Work Week (Hours)"
          ></slx-kendo-number>
          <span errorMessage for="required">The field is required for Salary pay type</span>
          <span errorMessage for="number"></span>
          <span errorMessage for="max">Value must be lower 168</span>
          <span errorMessage for="workHoursValidation">This field can't be 0 if Pay type = Salaried</span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.dailyBenefitHours.isVisible">
        <slx-input-decorator>
          <slx-kendo-number slx-input number
            [(ngModel)]="addEmployeeModel.dailyBenefitHours"
            [inputPolicy]="addEmployeeRestrictions?.dailyBenefitHours.Policy"
            [decimals]="2"
            [min]="0"
            [slxMin]="0"
            [slxMax]="999999.99"
             [max]="999999.99"
            name="dailyBenefitHours"
            placeholder="Daily Benefit Hours"
          ></slx-kendo-number>
          <span errorMessage for="required"></span>
          <span errorMessage for="max">The value should be less than 999999.99</span>
          <span errorMessage for="number"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.benefitClass.isVisible && bswiftEnabled">
        <slx-spinner [show]="state.isBenefitClassLoading">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
                                [lookup]="{lookupType: 'benefitClasses', orgLevelId: addEmployeeModel.department?.orgLevelId}"
                                name="benefitClass"
                                placeholder="Benefit Class"
                                [(ngModel)]="addEmployeeModel.benefitClass"
                                [inputPolicy]="addEmployeeRestrictions?.benefitClass.Policy">
            </slx-dropdown-input>
            <span errorMessage for="required"></span>
          </slx-input-decorator>
        </slx-spinner>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.benefitClassEffectiveDate.isVisible && bswiftEnabled">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input
            [(ngModel)]="addEmployeeModel.benefitClassEffectiveDate"
            placeholder="Benefit Class Effective Date"
            name="benefitClassEffectiveDate"
            [readonly]="true"
            [inputPolicy]="addEmployeeRestrictions?.benefitClassEffectiveDate.Policy"
          ></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.hourlyRate.isVisible">
        <slx-input-decorator>
          <slx-money-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.hourlyRate.Policy"
                           [decimalLimit]="2"
                           [requireDecimal]="false"
                           name="hourlyRate" number [slxMax]="getRateLimit"
                           placeholder="{{getRateTitle}}"
                           [(ngModel)]="addEmployeeModel.hourlyRate">
          </slx-money-input>
          <div class="warning-triangle" *ngIf="addEmployeeModel.hourlyRate > getRateWarningLimit">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"
               slxTooltip="This rate appears high, are you sure this is an {{getRateTitle}} employee?"
               tipPosition="right" tipClass="slx-warning-tooltip"></i>
          </div>
          <span errorMessage for="required"></span>
          <span errorMessage for="number"></span>
          <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.hourlyRate2.isVisible">
        <slx-input-decorator>
          <slx-money-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.hourlyRate2.Policy"
                           [decimalLimit]="2"
                           [requireDecimal]="false"
                           name="hourlyRate2" number [slxMax]="getRateLimit"
                           placeholder="{{getRateTitle}} 2"
                           [(ngModel)]="addEmployeeModel.hourlyRate2">
          </slx-money-input>
          <div class="warning-triangle" *ngIf="addEmployeeModel.hourlyRate2 > getRateWarningLimit">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"
               slxTooltip="This rate appears high, are you sure this is an {{getRateTitle}} employee?"
               tipPosition="right" tipClass="slx-warning-tooltip"></i>
          </div>
          <span errorMessage for="required"></span>
          <span errorMessage for="number"></span>
          <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.hourlyRate3.isVisible">
        <slx-input-decorator>
          <slx-money-input slx-input
                           [inputPolicy]="addEmployeeRestrictions?.hourlyRate3.Policy"
                           [decimalLimit]="2"
                           [requireDecimal]="false"
                           name="hourlyRate3" number [slxMax]="getRateLimit"
                           placeholder="{{getRateTitle}} 3"
                           [(ngModel)]="addEmployeeModel.hourlyRate3">
          </slx-money-input>
          <div class="warning-triangle" *ngIf="addEmployeeModel.hourlyRate3 > getRateWarningLimit">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"
               slxTooltip="This rate appears high, are you sure this is an {{getRateTitle}} employee?"
               tipPosition="right" tipClass="slx-warning-tooltip"></i>
          </div>
          <span errorMessage for="required"></span>
          <span errorMessage for="number"></span>
          <span errorMessage for="max">Should not exceed {{getRateLimit}}</span>
        </slx-input-decorator>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.payClass.isVisible">
        <slx-input-decorator>
          <slx-kendo-number slx-input number
            [(ngModel)]="addEmployeeModel.payClass"
            [inputPolicy]="addEmployeeRestrictions?.payClass.Policy"
            [min]="0"
            [slxMin]="0"
            name="payClass"
            placeholder="Pay Class"
          ></slx-kendo-number>
          <span errorMessage for="required"></span>
          <span errorMessage for="number"></span>
          <span errorMessage for="min"></span>
          <span errorMessage for="max"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.companyCode.isVisible">
        <slx-input-decorator>
          <input slx-input type="text"
                 [inputPolicy]="addEmployeeRestrictions?.companyCode.Policy"
                 name="companyCode"
                 placeholder="Company Code"
                 [slxMaxLength]="25"
                 [(ngModel)]="addEmployeeModel.companyCode">
          <span errorMessage for="slxMaxLength">Maximum is 25 symbols</span>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.fileNumber.isVisible">
        <slx-input-decorator>
          <slx-kendo-number slx-input number
            [(ngModel)]="addEmployeeModel.fileNumber"
            [inputPolicy]="addEmployeeRestrictions?.fileNumber.Policy"
            format="#"
            name="fileNumber"
            placeholder="File Number"
          ></slx-kendo-number>
          <span errorMessage for="required"></span>
          <span errorMessage for="number"></span>
          <span errorMessage for="min">Cannot be less than {{ addEmployeeRestrictions?.fileNumber?.Policy?.minValue }}</span>
          <span errorMessage for="max">Cannot be greater than {{ addEmployeeRestrictions?.fileNumber?.Policy?.maxValue }}</span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="pbjEnabled && pbjIsManual">
        <slx-input-decorator>
          <input slx-input slxPbjId [slxMaxLength]="pbjIdMaxSymbols" type="text" required="true" [readonly]="!pbjIsManual" name="pbjId" placeholder="PBJ ID"
            [(ngModel)]="addEmployeeModel.pbjId" [inputPolicy]="addEmployeeRestrictions?.pbjId.Policy"
            [server]="{validationName: pbjIdValidation, parameters: [employeeId], validationAdapter: employeeActivitiesValidatorAdapter}">
            <span errorMessage for="required"></span>
            <span errorMessage for="pbjId">You can use letters, digits and , . \ -</span>
            <span errorMessage for="slxMaxLength">Maximum is 9 symbols</span>
            <span errorMessage [for]="pbjIdValidation">msg from server</span>
        </slx-input-decorator>
        <button type=button class="bt-generate" *ngIf="addEmployeeRestrictions?.pbjId.Policy.isVisible"
          [ngClass]="{'bt-generate-auto': !pbjIsManual}" (click)="generatePbjId()">
          <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </form>
  <div class="wizard-footer">
   <div class="wizard-buttons">
    <button type="button" slxBackLink class="theme-button-cancel margin-r">Cancel</button>
    <button class="theme-iconed-accent-button prev-step" (click)="prevStep()"><i class="far fa-arrow-alt-circle-left" aria-hidden="true"></i>Back</button>
    <button class="theme-iconed-accent-button next-step" (click)="nextStep()" [disabled]="!employmentForm.valid && !ignoreValidation">
      Next<i class="far fa-arrow-alt-circle-right"  aria-hidden="true"></i>
     </button>
   </div>
  </div>
</div>
</slx-spinner>
