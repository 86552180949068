import * as tslib_1 from "tslib";
import { ExportDataMapService } from './export-data-map.service';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { exportDataConfig } from '../export-data.config';
import { appConfig } from '../../../app.config';
var ExportDataApiService = /** @class */ (function () {
    function ExportDataApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    ExportDataApiService.prototype.getExportConfigurationList = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.exports.exportConfigurations + "/" + orgLevelId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToExportDataConfigurationList(response); })];
            });
        });
    };
    ExportDataApiService.prototype.getExportDataHistory = function (orgLevelId, configurationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.exports.exportDataHistory + "/" + orgLevelId + "/" + configurationId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToExportDataHistoryList(response); })];
            });
        });
    };
    ExportDataApiService.prototype.getExportDataStatuses = function (orgLevelId, ids) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.exports.exportDataStatuses;
                request = this.urlParamsService.createPostRequest(url, { orgLevelId: orgLevelId, executionIds: ids });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToExportDataStatusList(response); })];
            });
        });
    };
    ExportDataApiService.prototype.generateExportData = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.exports.generate;
                request = this.urlParamsService.createPostRequest(url, req);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToExportDataHistory(response); })];
            });
        });
    };
    ExportDataApiService.prototype.downloadExportData = function (executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.exports.download + "/" + executionId;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.requestForFile(request)
                        .then(function (file) { return file; })];
            });
        });
    };
    ExportDataApiService.prototype.cancelExportData = function (configurationId, executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.exports.cancel + "/" + executionId;
                request = this.urlParamsService.createDeleteRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToExportDataStatus(response); })];
            });
        });
    };
    ExportDataApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version2 + "/" + exportDataConfig.api.root;
    };
    return ExportDataApiService;
}());
export { ExportDataApiService };
