import { Directive, ElementRef, Input, ContentChild, OnInit, OnChanges, Renderer2, SimpleChanges, RendererStyleFlags2 } from '@angular/core';
import { Validators, NgModel } from '@angular/forms';

@Directive({
  selector: '[removableInput][formControlName],[removableInput][formControl],[removableInput][ngModel]'
})
export class RemovableInputDirective implements OnInit, OnChanges {

  @Input('removableInput')
  public isVisible: boolean;

  @Input('elementClass')
  public elementClass: string = 'theme-removable-input-button';

  @Input('parentClass')
  public parentClass: string;

  private elementRef: ElementRef;
  private renderer: Renderer2;

  @ContentChild(NgModel, {static: true})
  private model: NgModel;
  private removeButton: any;

  constructor(elementRef: ElementRef, renderer: Renderer2) {
    this.elementRef = elementRef;
    this.renderer = renderer;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isVisible']) {
      this.setVisibility();
    }
  }

  public ngOnInit(): void {
    this.removeButton = this.renderer.createElement('button');
    this.renderer.addClass(this.removeButton, this.elementClass);
    this.setVisibility();
    let icon: any = this.renderer.createElement('i');
    this.renderer.addClass(icon, 'fa');
    this.renderer.addClass(icon, 'fa-eraser');
    this.renderer.setAttribute(icon, 'aria-hidden', 'true');
    this.renderer.appendChild(this.removeButton, icon);

    this.renderer.listen(this.removeButton, 'click', (event: any) => {
      this.model.reset(null);
    });
    let parent: any;
    if (this.parentClass) {
      parent = this.findAncestor(this.elementRef.nativeElement, this.parentClass);
    }
    if (!parent) {
      parent = this.elementRef.nativeElement;
    }
    this.renderer.appendChild(parent, this.removeButton);
  }

  public setVisibility(): void {
    if (this.removeButton) {
      this.renderer.setStyle(this.removeButton, 'visibility', this.isVisible ? 'visible' : 'hidden');
    }
  }

  public findAncestor(node: HTMLElement, cl: string): HTMLElement {
    let parent: any;
    if (node === null || cl === '') {
      return null;
    }
    parent = node.parentNode;
    if (!parent) {
      return null;
    }
    while (parent.tagName !== 'HTML') {
      if (parent.classList.contains(cl)) {
        return parent;
      }
      parent = parent.parentNode;
    }
    return parent;
  }
}
