
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, Input, ChangeDetectorRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { FormBuilder, FormGroup, FormArray, Validators, NgForm } from '@angular/forms';

import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent } from '../../../../../../common/models/index';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';
import { ModalService, ModalAnchorDirective } from '../../../../../../common/index';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitEnrolledEmployee, BenefitDetailsTier, BenefitDetailsOption } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { InfoDialogComponent, ConfirmOptions, ConfirmDialog2Component } from './../../../../../../common/components/index';

import { OrgLevel } from '../../../../../../state-model/models/index';
import { BenefitEmployeesApiService, BenefitEnrollmentCommonService } from '../../../../services/index';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';
import { BenefitEnrolledEmployeePreview, IBenefitEnrolledEmployeePreview } from '../../../../models/benefit-employees/benefit-enrolled-employee-preview';


@Component({
  selector: 'benefit-option-formula-with-option-grid',
  templateUrl: 'benefit-option-formula-with-option-grid.component.html',
  styleUrls: ['./benefit-option-formula-with-option-grid.component.scss']
})
export class BenefitOptionFormulaWithOptionGridComponent implements OnInit, OnDestroy, AfterViewInit {
  public gridState: KendoGridStateHelper<BenefitEnrolledEmployee>;
  public columnGroup = 'BenefitOptionFormula';
  public appConfig: IApplicationConfig;
  public data: BenefitEnrolledEmployee[] = [];
  public coverageMultipllierOptions: { name: string, value: number }[];
  public isLoading: boolean = true;
  public isCoverageEditable: boolean = true;
  public minFormulaAmt = -1;
  public minAmt = 0;
  public maxAmt = 99999999.99;
  public employeeContributionDefined: boolean = true;
  public employerContributionDefined: boolean = true;
  public coverageDefined: boolean = true;

  public employeeContribution: any = null;
  public employerContribution: any = null;
  public coverage: any = null;

  private defaultMultiplierValue: number = 500;
  private defaultMultiplierMaxCap: number = 10000;

  public stepcurrency = 0.01;
  @Input()
  public selectedEmployees: BenefitEnrolledEmployee[];
  @Input()
  public benefitOptionData: BenefitDetailsOption[];

  @Input()
  public tierDetails: BenefitDetailsTier;

  @Input()
  public date: Date;

  @Output()
  public onEnrollmentCancel: EventEmitter<true>;

  public readonly pageSize = 50;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private orgLevel: OrgLevel;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild(ModalAnchorDirective, { static: true })
  private modalAnchor: ModalAnchorDirective;

  @ViewChild('form', { static: true })
  private form: NgForm;

  constructor(
    private formBuilder: FormBuilder,
    private manService: BenefitEmployeeManagementService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private apiService: BenefitEmployeesApiService,
    private commonValidationService: BenefitEnrollmentCommonService,
  ) {
    this.gridState = new KendoGridStateHelper<BenefitEnrolledEmployee>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.appConfig = appConfig;
    this.onEnrollmentCancel = new EventEmitter();
  }

  public ngOnInit() {
    this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, () => this.orgLevel, (orgLevel: OrgLevel) => this.reload(orgLevel));

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
  }

  private populateValues() {
    this.populateCoverageOptions();
    this.populateEmployeeContribution();
    this.populateEmployerContribution();
    this.populateCoverage();
    this.validatePrerequisites();

    if (this.benefitOptionData && this.benefitOptionData.length && this.coverageDefined && this.employeeContributionDefined && this.employerContributionDefined) {
      this.showZeroCoverageWarning();
    }
  }
  public ngOnDestroy(): void {
  }

  public ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public onOptionValueChange(dataItem, rowIndex: number, coverageHasError: boolean) {
    if (_.isObjectLike(dataItem.optionCode) && dataItem.benefitTierOptionId !== dataItem.optionCode.id) {
      dataItem.benefitTierOptionId = dataItem.optionCode.id;
      dataItem.code = dataItem.optionCode.code;
      dataItem.optionRateCode = dataItem.optionCode.code;
      //In case of Coverage Formula - when selecting the Option make sure the required field errors are cleared (as the coverage going to be assumed as 0).
      //This is to ensure form validation is successful immediately after selecting the option
      if(this.tierDetails.coverageFormula.isFormulaCalcType  && (!dataItem.coverage || coverageHasError)){
        dataItem.coverage = 0;
        dataItem.calculatedCoverage = 0;
      }
      this.evaluateFormula(dataItem);
      this.updateRowsData();
    }
  }

  public populateCoverageOptions(): void {
    if (this.tierDetails.coverageFormula.isMultiplierCalcType) {

      let multiplier = this.tierDetails.coverageFormula.multipler;
      let maxCap = this.tierDetails.coverageFormula.maxCap;
      multiplier = _.isNumber(multiplier) ? multiplier : this.defaultMultiplierValue;
      maxCap = _.isNumber(maxCap) ? maxCap : this.defaultMultiplierMaxCap;
      const range = _.range(multiplier, maxCap + multiplier, multiplier);
      const opts = _.map(range, value => ({ name: _.toString(value), value }));

      this.coverageMultipllierOptions = opts;
    }
  }

  public onChangeCoverageWithMultiplier(value: { name: string, value: number }, dataItem: BenefitEnrolledEmployee, rowIndex: number): void {
    let newValue = _.isObjectLike(value) && _.isFinite(value.value) ? value.value : null;
    let hasValueChanged = dataItem.coverage != newValue;
    dataItem.coverage = newValue;
    if (hasValueChanged) {
      this.evaluateFormula(dataItem);
    } else {
      this.updateRowsData();
    }
  }

  public onCoverageAnyValueChange(value: number, dataItem: BenefitEnrolledEmployee, rowIndex: number): void {
    this.evaluateFormula(dataItem);
  }

  public onChangeCoverageWithFormula(value: number, dataItem: BenefitEnrolledEmployee, rowIndex: number, errorExists: boolean): void {
    if (!errorExists && dataItem.calculatedCoverage != value) {
      dataItem.coverage = value;
      dataItem.calculatedCoverage = value;
      this.evaluateFormula(dataItem);
    } else {
      this.updateRowsData();
    }
  }

  private evaluateFormula(dataItem) {
    this.manService.benefitsOptionTabDetailsChanged(true);
    if (this.tierDetails.coverageFormula.isFormulaCalcType ||
      this.tierDetails.erFormula.isFormulaCalcType ||
      this.tierDetails.empFormula.isFormulaCalcType) {

      if (!dataItem.code
            || (this.tierDetails.coverageFormula.isFixedCalcType && !this.coverage)
            || (
              (this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType || this.tierDetails.coverageFormula.isMultiplierCalcType)
              && !_.isFinite(dataItem.coverage))
            ) {
        return;
      }
      dataItem.coverage = dataItem.coverage ? dataItem.coverage : 0;
      this.isLoading = true;
      let coverageToEvaluate = this.tierDetails.coverageFormula.isFixedCalcType? this.coverage : dataItem.coverage;
      this.apiService.getBenefitEnrolledPreviewEmployeesWithOptionCode(this.orgLevel.id, this.tierDetails.id, this.date, coverageToEvaluate, dataItem.code, [dataItem])
        .then((records: BenefitEnrolledEmployeePreview[]) => {
          const employeeMap = new Map(records.map(employee => [employee.employeeId, employee]));
          this.data = _.map(this.selectedEmployees, employee => {
            const record = employeeMap.get(employee.employeeId);
            if (record) {
              dataItem.employeeContributionAmount = record.employeeContributionAmount;
              dataItem.employerContributionAmount = record.employerContributionAmount;
              if (this.tierDetails.coverageFormula.isFormulaCalcType) {
                dataItem.calculatedCoverage = record.coverage;
              } else {
                dataItem.coverage = record.coverage;
              }
              this.validateFormulaEvaluation(dataItem);
            }
            return employee;
          });
        }).finally(() => {
          this.isLoading = false;
          this.updateRowsData();
        });
    } else {
      this.updateRowsData();
    }
  }


  public populateEmployeeContribution() {
    if (this.tierDetails && this.tierDetails.empFormula) {
      if (this.tierDetails.empFormula.isFixedCalcType && _.isNumber(this.tierDetails.empFormula.fixedAmount)) {
        this.employeeContributionDefined = true;
        this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
      } else if (this.tierDetails.empFormula.isFormulaCalcType) {
        if (!this.tierDetails.empFormula.expression) {
          this.employeeContributionDefined = false;
        }
      } else {
        this.employeeContributionDefined = false;
      }
    }
  }

  public populateEmployerContribution() {
    if (this.tierDetails && this.tierDetails.erFormula) {
      if (this.tierDetails.erFormula.isFixedCalcType && _.isNumber(this.tierDetails.erFormula.fixedAmount)) {
        this.employerContributionDefined = true;
        this.employerContribution = this.tierDetails.erFormula.fixedAmount;
      } else if (this.tierDetails.erFormula.isFormulaCalcType) {
        if (!this.tierDetails.erFormula.expression) {
          this.employerContributionDefined = false;
        }
      } else {
        this.employerContributionDefined = false;
      }
    }
  }

  public populateCoverage() {
    if (this.tierDetails && this.tierDetails.coverageFormula) {
      if (this.tierDetails.coverageFormula.isFixedCalcType && _.isNumber(this.tierDetails.coverageFormula.fixedAmount)) {
        this.coverageDefined = true;
        this.coverage = this.tierDetails.coverageFormula.fixedAmount;
      } else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
        if (!this.tierDetails.coverageFormula.expression) {
          this.coverageDefined = false;
        }
      } else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
        this.coverageDefined = true;
        this.coverage = 0;
      } else if (this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType) {
        this.coverageDefined = true;
        this.coverage = 0;
      } else {
        this.coverageDefined = false;
      }
    }
  }

  public updateRowsData() {
    this.manService.updateEmployeesUpdatedInfo(this.data);
    let invalidRecordExists = _.find(this.data, (item) => {
      if (!item.benefitTierOptionId ||
        (this.tierDetails.coverageFormula.isFormulaCalcType && item.calculatedCoverage < 0) ||
        (this.tierDetails.coverageFormula.isFixedCalcType && this.coverage < 0) ||
        (!(this.tierDetails.coverageFormula.isFormulaCalcType || this.tierDetails.coverageFormula.isFixedCalcType) && item.coverage < 0) ||
        (this.tierDetails.empFormula.isFixedCalcType && this.employeeContribution < 0) ||
        (!this.tierDetails.empFormula.isFixedCalcType && item.employeeContributionAmount < 0) ||
        (this.tierDetails.erFormula.isFixedCalcType && this.employerContribution < 0) ||
        (!this.tierDetails.erFormula.isFixedCalcType && item.employerContributionAmount < 0)) {
        return true;
      }
    });
    this.manService.benefitsOptionTabDetailsChanged(!!invalidRecordExists || this.form.invalid);
  }

  public validatePrerequisites(): void {

    let message = '';
    if (!this.benefitOptionData || this.benefitOptionData.length === 0) {
      message = 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.';
    } else {
      message = 'Employees cannot be enrolled as ';
      if (!this.employeeContributionDefined && !this.employerContributionDefined && !this.coverageDefined) {
        message += 'the Employee and Employer Contribution and Coverage amounts have';
      } else if (!this.employeeContributionDefined && !this.employerContributionDefined) {
        message += 'the Employee and Employer Contribution amounts have';
      } else if (!this.employeeContributionDefined && !this.coverageDefined) {
        message += 'the Employee Contribution and Coverage amounts have';
      } else if (!this.employerContributionDefined && !this.coverageDefined) {
        message += 'the Employer Contribution and Coverage amounts have';
      } else if (!this.employeeContributionDefined) {
        message += 'the Employee Contribution amount has';
      } else if (!this.employerContributionDefined) {
        message += 'the Employer Contribution amount has';
      } else if (!this.coverageDefined) {
        message += 'the Coverage amount has';
      } else {
        return;
      }
      message += ' not been created for this benefit plan. Please add contribution and Coverage amounts for this plan to enroll employees.';
    }
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        this.onEnrollmentCancel.emit();
      }, options);
  }

  public showZeroCoverageWarning(): void {

    let message = '';
    if (this.coverage === 0) {
      if (this.employeeContribution === 0 || this.employerContribution === 0) {
        message = 'Employees will be enrolled into zero coverage with zero Employee/Employer contributions.';
      } else {
        message = 'Employees will be enrolled into zero coverage.';
      }
    } else {
      return;
    }

    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
      }, options);
  }

  public validateFormulaEvaluation(dataItem: any): void {
    let coverage = this.tierDetails.coverageFormula.isFormulaCalcType ? dataItem.calculatedCoverage : dataItem.coverage;

     let errorMessage = this.commonValidationService.getFormulaError(
      this.tierDetails.empFormula.isFormulaCalcType,
      this.tierDetails.erFormula.isFormulaCalcType,
      this.tierDetails.coverageFormula.isFormulaCalcType,
      dataItem.employeeContributionAmount,
      dataItem.employerContributionAmount,
      coverage
    );

    if (errorMessage && errorMessage !== '') {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = false;
      options.showOK = true;
      ConfirmDialog2Component.openDialog(
        'Warning',
        errorMessage,
        this.modalService,
        (result: boolean) => { },
        options);
    }
  }

  public async reload(orgLevel: OrgLevel): Promise<any> {
    this.orgLevel = orgLevel;
    this.data = this.selectedEmployees;
    this.refreshGrid();
    this.populateValues();
    this.updateRowsData();
  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.data) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.data, this.gridState.state);
  }
}
