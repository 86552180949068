
export interface IEmployeeAccrualBalance {
  accrualTypeId: number;
  accrualTypeName: string;
  balance: number;
}

export class EmployeeAccrualBalance {
  accrualTypeId: number;
  accrualTypeName: string;
  balance: number;
}
