import { Provider } from "@angular/core";
import { OpenConfigOrgLevelType } from "../../models";
import { DialogOptions, ModalService } from "./../../../../app/common";
import { OpenPositionControlConfigurationComponent } from "./open-position-control-configuration/open-position-control-configuration.component";
import { Subject } from "rxjs";


export class OpenPositionControlHelper {
    
    public modalService: ModalService;

    constructor(modalService: ModalService) {
        this.modalService = modalService;
    }

    openConfigPopup(orgLevelType: string, orgLevelId: number, orgLevelName: string, isMobile:boolean): void {
        let dialogOptions: DialogOptions = new DialogOptions();
        dialogOptions.width =  isMobile ? 370 : 750;
        dialogOptions.height = isMobile ? 500 :400;
        dialogOptions.showCloseButton = true;
        dialogOptions.className = 'slx-opc-config-dialog-custom';
        dialogOptions.headerClassName = isMobile ? 'slx-opc-config-dialog-header': '';
        let configOrgLevelType: OpenConfigOrgLevelType = new OpenConfigOrgLevelType();
        configOrgLevelType.orgLevelType = orgLevelType;
        configOrgLevelType.orgLevelId = orgLevelId;
        configOrgLevelType.orgLevelName = orgLevelName;
        let resolvedProviders: Provider[] = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ModalService,
                useValue: this.modalService
            },
            {
                provide: OpenConfigOrgLevelType,
                useValue: configOrgLevelType
            }
        ];
        let dialog: OpenPositionControlConfigurationComponent = this.modalService.globalAnchor.openDialog(
            OpenPositionControlConfigurationComponent, 'Open Position Control Settings: ' + configOrgLevelType.orgLevelName, dialogOptions, resolvedProviders,
            (result: boolean, uniqueId?: string) => {
            });
        if (dialog.initialized) {
            dialog.LoadData();
        }
    }

}