import { AttachmentFile } from '../../../../organization/models/index';
export interface IAttachmentFile {
    id: number;
    fileName: string;
    name: string;
    addedBy: string;
    addedOn: string;
    category: string;
}

export class EnrollementAttachmentFile extends AttachmentFile {
    public category: string;
}
