import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { appConfig } from '../../../../app.config';
var DailyTimeCardEmployeeInfoComponent = /** @class */ (function () {
    function DailyTimeCardEmployeeInfoComponent(managementService) {
        var _this = this;
        this.managementService = managementService;
        this.appConfig = appConfig;
        this.subscription = this.managementService.onLoaded$.subscribe(function (model) {
            _this.model = model;
            _this.employee = model.employee;
        });
    }
    DailyTimeCardEmployeeInfoComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DailyTimeCardEmployeeInfoComponent.prototype.navigateToUnapprovedPaycodes = function () {
        this.managementService.navigateToUnapprovedPaycodes();
    };
    DailyTimeCardEmployeeInfoComponent.prototype.navigateToUnapprovedOvertimes = function () {
        this.managementService.navigateToUnapprovedOvertimes(this.model.primaryPosition.orgLevelId);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyTimeCardEmployeeInfoComponent.prototype, "subscription", void 0);
    return DailyTimeCardEmployeeInfoComponent;
}());
export { DailyTimeCardEmployeeInfoComponent };
