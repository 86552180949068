<slx-spinner [show]="state.isLoading">
<form #configurationForm="ngForm">
    <div class="body flex-container">

        <div class="content-section description" *ngIf="!hideDescription">
            <div class="section-content">
                <span class="description-text">Enabling the PBJ Meal Deduction feature for a group of employees will automatically
                    calculate the required Meal Deduction for each employee based on the length of their shift and any existing
                    break calculations. The meal deductions will be listed on the “PBJ Manual Entries’ screen and the data in the
                    PBJ export will be adjusted accordingly.</span>
            </div>
        </div>

        <div class="content-section error-message" *ngIf="!mealDeductionSettings && !state.isLoading">
            <span>Can't load meal deduction settings</span>
        </div>

        <div class="content-section settings" *ngIf="mealDeductionSettings">
            <div class="section-title">
                <div class="col left-col">
                    <span class="settings-header">Types:</span>
                </div>
                <div class="col right-col">
                    <span class="settings-header">Settings:</span>
                </div>
            </div>
            <div class="section-content settings-list">
                <div class="content-section mid-shift description flex-container">
                    <div class="description-text settings-row">
                        <div class="left-col">
                            Date Applied for Overnight Shifts
                        </div>
                        <div class="right-col">
                            <slx-dropdown-list
                                class="slx-wide slx-short config-mode"
                                [options]="shiftModes"
                                [(ngModel)]="shiftMode"
                                (ngModelChange)="onChangeShiftMode()"
                                [readonly]="!mealDeductionSettings.canConfigure"
                                name="shiftMode"
                            ></slx-dropdown-list>
                        </div>
                    </div>
                    <div class="description-text settings-row" *ngIf="isMidShift">
                        <div class="left-col">
                            <p class="align-center">
                                Shift 1 Mid-Point
                            </p>
                            <p class="title-text">
                                <slx-number class="slx-wide"
                                    #shiftOneField="ngModel"
                                    [slxMin]="shiftOneMin"
                                    [slxMax]="shiftOneMax"
                                    [step]="step"
                                    [autoCorrect]='false'
                                    [readonly]="!mealDeductionSettings.canConfigure"
                                    [(ngModel)]="mealDeductionSettings.midShiftOne"
                                    name="shiftOneField"
                                    [required]="true"
                                    (ngModelChange)="updateFormValidity()"
                                ></slx-number>
                                <span *ngIf="shiftOneField.errors" class="slx-error-block error-box">
                                    <span *ngIf="shiftOneField.errors.required" errorMessage for="required"></span>
                                    <span *ngIf="shiftOneField.errors.min" errorMessage for="min">Entry should be between {{shiftOneMin}} and {{shiftOneMax}}</span>
                                    <span *ngIf="shiftOneField.errors.max" errorMessage for="max">Entry should be between {{shiftOneMin}} and {{shiftOneMax}}</span>
                                </span>
                            </p>
                        </div>
                        <div class="right-col">
                            <p class="left-col">
                                Shift 2 Mid-Point
                            </p>
                            <p class="title-text right-col in-block">
                                <slx-number class="slx-wide"
                                    #shiftTwoField="ngModel"
                                    [slxMin]="shiftTwoMin"
                                    [slxMax]="shiftTwoMax"
                                    [step]="step"
                                    [autoCorrect]='false'
                                    [readonly]="!mealDeductionSettings.canConfigure"
                                    [(ngModel)]="mealDeductionSettings.midShiftTwo"
                                    name="shiftTwoField"
                                    [required]="true"
                                    (ngModelChange)="updateFormValidity()"
                                ></slx-number>
                                <span *ngIf="shiftTwoField.errors" class="slx-error-block error-box">
                                    <span *ngIf="shiftTwoField.errors.required" errorMessage for="required"></span>
                                    <span *ngIf="shiftTwoField.errors.min" errorMessage for="min">Entry should be between {{shiftTwoMin}} and {{shiftTwoMax}}</span>
                                    <span *ngIf="shiftTwoField.errors.max" errorMessage for="max">Entry should be between {{shiftTwoMin}} and {{shiftTwoMax}}</span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="settings-row">
                    <div class="left-col">
                        <span>Max Threshold (in minutes) between punch pairs to consider as a single shift</span>
                    </div>
                    <div class="right-col">
                        <div class="punch-treshold-control">
                            <slx-number name="maxPunchTreshold" [(ngModel)]="mealDeductionSettings.maxPunchTreshold"
                                [min]="minTreshold" [max]="maxTreshold" autoCorrect="true"
                                [readonly]="!mealDeductionSettings.canConfigure"
                                [required]="true"
                                >
                            </slx-number>
                            <span>minutes</span>
                        </div>
                    </div>
                </div>
                <div class="settings-row">
                    <div class="left-col">
                        <span>Enable Meal Deductions for Non Exempt Staff</span>
                    </div>
                    <div class="right-col">
                        <slx-radio-button class="p-left-0" name="enableNonExempt" fieldName="enableForNonExempt"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForNonExempt"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="disableNonExempt" fieldName="disableNonExempt"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForNonExempt"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="settings-row">
                    <div class="left-col">
                        <span>Enable Meal Deductions for Exempt type "Scheduled Time"</span>
                    </div>
                    <div class="right-col">
                        <slx-radio-button class="p-left-0" name="enableForExemptS" fieldName="enableForExemptS"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptS"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptS" fieldName="enableForExemptS"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptS"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="settings-row">
                    <div class="left-col">
                        <span>Enable Meal Deductions for Exempt type "Use Timesheets"</span>
                    </div>
                    <div class="right-col">
                        <slx-radio-button class="p-left-0" name="enableForExemptT" fieldName="enableForExemptT"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptT"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptT" fieldName="enableForExemptT"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptT"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="settings-row">
                    <div class="left-col">
                        <span>Enable Meal Deductions for Exempt status "Scheduled Time with Generated Punches"</span>
                    </div>
                    <div class="right-col">
                        <slx-radio-button class="p-left-0" name="enableForExemptX" fieldName="enableForExemptX"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptX"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptX" fieldName="enableForExemptX"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptX"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="settings-row">
                    <div class="left-col">
                        <span>Enable Meal Deductions for Exempt status of "Weekly Hours spread evenly across weekdays"</span>
                    </div>
                    <div class="right-col">
                        <slx-radio-button class="p-left-0" name="enableForExemptY" fieldName="enableForExemptY"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptY"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptY" fieldName="enableForExemptY"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptY"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="settings-row">
                    <div class="left-col">
                        <span>Enable Meal Deduction for Agency Staff</span>
                    </div>
                    <div class="right-col">
                        <slx-radio-button class="p-left-0" name="enableForAgency" fieldName="enableForAgency"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForAgency"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForAgency" fieldName="enableForAgency"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForAgency"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            >
                        </slx-radio-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
</slx-spinner>
