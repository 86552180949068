<div class="floating-banner" [ngClass]="{'mobile-hidden': !this.menuCollapsed, 'left-sidebar-open': (isLeftSidebarOpen | async)}">
  <slx-employee-banner [employeeBannerInfo]="employeeBannerInfo"
        (onTerminate)="terminate($event)"
        (onCancelTerminate)="cancelTerminate ($event)"
        (onChangeTerminateDate)="changeTerminationDate ($event)"
        (onTransfer)="transfer($event)"
        (onUndoTransfer)="undoTransfer($event)"
        (onRehire)="rehire($event)"
        (onResendTimeclocksEmail)="onResendTimeclocksEmail ($event)"
        [hasEditingSection]="hasEditingSection"
        [canNotEditOwnProfile]="canNotEditOwnProfile"
        >
  </slx-employee-banner>
</div>
<div class="menu">
  <slx-spinner [show]="this.getIsLoadingPersonal()" >
    <slx-two-level-menu (menuToggled)="menuToggled($event)" [menuItems]="menuItems" [activeItemId]="activeSection?.id" [activeSubItemId]="activeSubSection?.id" (itemActivated)="onItemActivated($event)"></slx-two-level-menu>
  </slx-spinner>
</div>
<div #contentContainer class="content" [scrollContainer]="80">
  <div [attr.id]="section.id" class="section-container" *ngFor="let section of employeeSections">
    <div *ngFor="let subsection of section.subsections" [attr.id]="subsection.id" [scrollSection]="subsection.id">
      <slx-employee-subsection-decorator *ngIf="!!employeeShortInfo"
      [subsection]="subsection"
      [employeeShortInfo]="employeeShortInfo"
      [employeeStatus] = "employeeBannerInfo ? employeeBannerInfo?.status : null"
      [employeeRehireDate] = "employeeBannerInfo ? employeeBannerInfo?.rehireDate : null"
      [isEditableByConfiguration]="subsection.isEditableByConfiguration"
      [isEditableWhenEmpTerminated]="subsection.isEditableWhenEmpTerminated"
      [isNotEditableWhenEmpFutureRehired]="subsection.isNotEditableWhenEmpFutureRehired"
      [isTermHistoryEditEndabled]="canEditTermHistory"
      [isLoadingSection]=getIsLoadingSection(subsection.id)
      [ngClass]="{hideSectionsInIE: !sectionsAreVisible}"
      >
        <ng-container [ngSwitch]="subsection.id">
          <ng-container *ngSwitchCase="'Profile'">
            <slx-employee-sections-profile class="single-section" #employeeSectionsProfile [employeeSectionsProfile]="personalSection?.profile" [employeeId]="currentEmployeeSectionsContext?.employeeId" (updateBanner)="updateBanner($event)">
            </slx-employee-sections-profile>
          </ng-container>
          <ng-container *ngSwitchCase="'ContactInfo'">
            <slx-employee-sections-contacts class="single-section" [employeeSectionsContacts]="personalSection?.contacts" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-contacts>
          </ng-container>
          <ng-container *ngSwitchCase="'EmergencyContacts'">
            <slx-employee-sections-emergency-contacts class="single-section" [employeeSectionsEmergencyContacts]="personalSection?.emergencyContacts" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-emergency-contacts>
          </ng-container>
          <ng-container *ngSwitchCase="'Licenses'">
            <slx-employee-sections-licenses class="single-section" [employeeSectionsLicenses]="personalSection?.licenses" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-licenses>
          </ng-container>
          <ng-container *ngSwitchCase="'Certifications'">
            <slx-employee-sections-certifications class="single-section" [employeeSectionsCertifications]="personalSection?.certifications" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-certifications>
          </ng-container>
          <ng-container *ngSwitchCase="'EmpWarningsData'">
            <slx-employee-sections-warnings class="single-section" [employeeSectionsWarnings]="personalSection?.warnings" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-warnings>
          </ng-container>
          <ng-container *ngSwitchCase="'Physicals'">
            <slx-employee-sections-physicals class="single-section" [employeeSectionsPhysicals]="personalSection?.physicals" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-physicals>
          </ng-container>
          <ng-container *ngSwitchCase="'Documents'">
            <slx-employee-sections-documents class="single-section" [employeeSectionsDocuments]="personalSection?.documents" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-documents>
          </ng-container>
          <ng-container *ngSwitchCase="'Education'">
            <slx-employee-sections-educations class="single-section" [employeeSectionsEducations]="personalSection?.education" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-educations>
          </ng-container>
          <ng-container *ngSwitchCase="'Agency Certificate'">
            <slx-employee-sections-agency-certificate  class="single-section"  [agencyCertificate]="personalSection?.agencyCertificate" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-agency-certificate> 
          </ng-container>
          <ng-container *ngSwitchCase="'BackgroundCheck'">
            <slx-employee-sections-background-check class="single-section" [employeeSectionsBackgroundCheck]="personalSection?.backgroundChecks" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-background-check>
          </ng-container>
          <ng-container *ngSwitchCase="'Payroll'">
            <slx-employee-sections-payroll class="single-section" [employeeSectionsPayroll]="employmentSection?.payroll" [employeePayCycles]="employmentSection?.payCycles" [employeeId]="currentEmployeeSectionsContext?.employeeId" [employeeStatus] = "employeeBannerInfo ? employeeBannerInfo?.status : null">
            </slx-employee-sections-payroll>
          </ng-container>
          <ng-container *ngSwitchCase="'SmartLinxGo'">
            <slx-employee-sections-ess-template *ngIf="employmentSection?.essTemplate && !employmentSection?.selfService" [employeeSectionsEssTemplate]="employmentSection?.essTemplate" [employeeId]="currentEmployeeSectionsContext?.employeeId"></slx-employee-sections-ess-template>
            <slx-employee-sections-self-service *ngIf="employmentSection?.selfService" [employeeSectionsSelfService]="employmentSection?.selfService" [employeeId]="currentEmployeeSectionsContext?.employeeId"></slx-employee-sections-self-service>
          </ng-container>
          <ng-container *ngSwitchCase="'Telepunch'">
            <slx-employee-sections-telepunch [employeeId]="currentEmployeeSectionsContext?.employeeId" [employeeSectionsTelepunch]="employmentSection?.telepunch"></slx-employee-sections-telepunch>
          </ng-container>
          <ng-container *ngSwitchCase="'WorkersCompensation'">
            <slx-employee-sections-workers-comp [workersComp]="employmentSection?.workersComp" [employeeId]="currentEmployeeSectionsContext?.employeeId"></slx-employee-sections-workers-comp>
          </ng-container>
          <ng-container *ngSwitchCase="'PerformanceManagement'">
            <slx-employee-section-performance-management [reviews]="employmentSection?.performanceManagement" [employeeId]="currentEmployeeSectionsContext?.employeeId"></slx-employee-section-performance-management>
          </ng-container>
          <ng-container *ngSwitchCase="'Positions'">
            <slx-employee-sections-positions class="single-section" [employeeSectionsPositions]="employmentSection?.positions"
            [employeePayType]="employmentSection?.payroll ? employmentSection?.payroll?.payType?.innerValue: null"
            [employeeRehireDate] = "personalSection?.profile ? personalSection?.profile?.rehireDate : null"
            [employeeStatus] = "personalSection?.profile ? personalSection?.profile?.status : null"
            [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-positions>
          </ng-container>
          <ng-container *ngSwitchCase="'PayCycles'">
            <slx-employee-sections-pay-cycles class="single-section" 
            [employeeSectionsPayCycles]="employmentSection?.payCycles" 
            [employeeId]="currentEmployeeSectionsContext?.employeeId"
            [employeeStatus] = "employeeBannerInfo ? employeeBannerInfo?.status : null"
            [employeeRehireDate] = "employeeBannerInfo ? employeeBannerInfo?.rehireDate : null">
            </slx-employee-sections-pay-cycles>
          </ng-container>
          <ng-container *ngSwitchCase="'PositionHistory'">
            <slx-employee-sections-position-history class="single-section" [employeeSectionsPositionHistory]="employmentSection?.positionHistory" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-position-history>
          </ng-container>
          <ng-container *ngSwitchCase="'RateHistory'">
            <slx-employee-sections-rate-history class="single-section" [employeeSectionsRateHistory]="employmentSection?.rateHistory" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-rate-history>
          </ng-container>
          <ng-container *ngSwitchCase="'LeaveManagement'">
            <slx-employee-sections-leave-management class="single-section" [leaveManagement]="employmentSection?.leaveManagement" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-leave-management>
          </ng-container>
          <ng-container *ngSwitchCase="'I9'">
            <slx-employee-sections-i9 class="single-section" [i9]="employmentSection?.i9" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-i9>
          </ng-container>
          <ng-container *ngSwitchCase="'TermHistory'">
            <slx-employee-sections-terminations-history class="single-section" [employeeSectionsTerminationHistoryEntries]="employmentSection?.terminationHistory"
                                                        [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-terminations-history>
          </ng-container>
          <ng-container *ngSwitchCase="'Timeclocks'">
            <slx-employee-sections-timeclocks class="single-section" [employeeSectionTimeclockContainer]="employmentSection?.timeclocks"
                                              [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-timeclocks>
          </ng-container>
          <ng-container *ngSwitchCase="'BenefitsEnrollmentHistory'">
            <slx-benefit-enrollment-section class="single-section"
                                            [benefitEnrollment]="benefitsManagementSection?.enrollmentSubsection"
                                            [employeeId]="currentEmployeeSectionsContext?.employeeId"
                                            [employeeStatus] = "employeeBannerInfo ? employeeBannerInfo?.status : null" 
                                            [canMapPayroll]="benefitsManagementSection?.canMapPayroll">
            </slx-benefit-enrollment-section>
          </ng-container>
          <ng-container *ngSwitchCase="'Dependents'">
            <slx-employee-sections-dependents class="single-section"
                                              [employeeSubsectionDependents]="benefitsManagementSection?.dependentsSubsection"
                                              [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-dependents>
          </ng-container>
          <ng-container *ngSwitchCase="'BenefitClass'">
            <slx-employee-sections-benefit-class [employeeSectionsBenefitClasses]="benefitsManagementSection?.benefitClassSubSection" [employeeStatus] = "employeeBannerInfo ? employeeBannerInfo?.status : null" [employeeRehireDate] = "employeeBannerInfo ? employeeBannerInfo?.rehireDate : null" [employeeId]="currentEmployeeSectionsContext?.employeeId"></slx-employee-sections-benefit-class>
          </ng-container>
          
          <ng-container *ngSwitchCase="'AttendancePoints'">
            <slx-employee-sections-attendance-points class="single-section" [employeeSubsectionAttendancePoints]="performanceSection?.attendancePoints" [employeeId]="currentEmployeeSectionsContext?.employeeId"
                                                     [startDate]="attendancePointsStartDate" [endDate]="attendancePointsEndDate">
            </slx-employee-sections-attendance-points>
          </ng-container>

          <ng-container *ngSwitchCase="'BuyBackPointsHistoryData'">
            <slx-employee-sections-attendance-buybacks class="single-section" [buybacksSubsection]="performanceSection?.buyBackPoints" [employeeId]="currentEmployeeSectionsContext?.employeeId"
                                                     [startDate]="attendancePointsStartDate" [endDate]="attendancePointsEndDate">
            </slx-employee-sections-attendance-buybacks>
          </ng-container>

          <ng-container *ngSwitchCase="'FutureUpdates'">
            <slx-employee-sections-future-updates class="single-section" [employeeSectionsFutureUpdates]="auditSection?.futureUpdates"
                                                  [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-future-updates>
          </ng-container>
          <ng-container *ngSwitchCase="'AuditTrail'">
            <slx-employee-sections-audit-trail class="single-section" [employeeSectionsAuditTrail]="auditSection?.auditTrail"
                                               [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-audit-trail>
          </ng-container>
          <ng-container *ngSwitchCase="'CustomFields'">
            <slx-employee-sections-custom-fields class="single-section" [employeeSectionsCustomFields]="customSection?.customFields"
                                                 [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-custom-fields>
          </ng-container>
          <ng-container *ngSwitchCase="'Rotations'" class="rotation-section">
            <slx-employee-sections-rotations class="single-section" [employeeSubsectionRotations]="scheduleSection?.rotationsSubsection"
                                             [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-rotations>
          </ng-container>
          <ng-container *ngSwitchCase="'Availability'">
            <slx-employee-sections-availability class="single-section" [employeeSubsectionAvailability]="scheduleSection?.availabilitySubsection"
                                                [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-availability>
          </ng-container>
          <ng-container *ngSwitchCase="'Attendance'">
            <slx-employee-sections-attendance class="single-section" [employeeSubsectionAttendance]="performanceSection?.attendance"
                                              [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-attendance>
          </ng-container>
          <ng-container *ngSwitchCase="'PolicyAssignments'">
            <slx-employee-sections-policy-assignments class="single-section" [employeeSectionsPolicyAssignments]="accrualsSection?.policyAssignments"
                                                      [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-policy-assignments>
          </ng-container>
          <ng-container *ngSwitchCase="'Transactions'">
            <slx-employee-sections-transactions class="single-section" [employeeAccrualTransactions]="accrualsSection?.transactions"
                                                [employeeId]="currentEmployeeSectionsContext?.employeeId"
                                                [startDate]="accrualsStartDate" [endDate]="accrualsEndDate">
            </slx-employee-sections-transactions>
          </ng-container>
          <ng-container *ngSwitchCase="'AccrualBalances'">
            <slx-employee-sections-accruals class="single-section" [accruals]="accrualsSection?.balances" [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-accruals>
          </ng-container>

          <ng-container *ngSwitchCase="'ACA'">
            <slx-employee-sections-aca class="single-section" [employeeSectionsACA]="employmentSection?.aca"     
            [employeeRehireDate] = "personalSection?.profile ? personalSection?.profile?.rehireDate : null"
            [employeeStatus] = "personalSection?.profile ? personalSection?.profile?.status : null"
            [employeeId]="currentEmployeeSectionsContext?.employeeId">
            </slx-employee-sections-aca>
          </ng-container>
        </ng-container>
      </slx-employee-subsection-decorator>
    </div>
  </div>
  <div class="section-container" style="min-height:800px"></div>
</div>
