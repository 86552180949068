/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefits-add-file-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../common/validators/slx-max-length";
import * as i4 from "@angular/forms";
import * as i5 from "./benefits-add-file-modal.component";
import * as i6 from "../../../../../../common/models/dialog-options";
import * as i7 from "../../../../../../common/services/modal/modal.service";
import * as i8 from "../../../../services/employee-sections-benefits-management/benefits-enrollment-section-management.service";
var styles_BenefitAddAttachmentsDialogComponent = [i0.styles];
var RenderType_BenefitAddAttachmentsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitAddAttachmentsDialogComponent, data: {} });
export { RenderType_BenefitAddAttachmentsDialogComponent as RenderType_BenefitAddAttachmentsDialogComponent };
function View_BenefitAddAttachmentsDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to upload file. Max size for files is 10MB."]))], null, null); }
function View_BenefitAddAttachmentsDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to upload file. Allowed types: Word, Excel, PDF and image files"]))], null, null); }
function View_BenefitAddAttachmentsDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to upload file. Please try again"]))], null, null); }
function View_BenefitAddAttachmentsDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "slx-error-block attachments__error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitAddAttachmentsDialogComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitAddAttachmentsDialogComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitAddAttachmentsDialogComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.maxFileSize; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors.fileType; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.errors.zeroFileSize; _ck(_v, 6, 0, currVal_2); }, null); }
function View_BenefitAddAttachmentsDialogComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name must not exceed 50 characters"]))], null, null); }
function View_BenefitAddAttachmentsDialogComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "slx-error-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitAddAttachmentsDialogComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = ((i1.ɵnov(_v.parent, 24).errors == null) ? null : i1.ɵnov(_v.parent, 24).errors.slxMaxLength); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BenefitAddAttachmentsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 29, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 28, "div", [["class", "attachments"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "flex align-items__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "label", [["class", "attachments__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Browse"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "span", [["class", "attachments__btn-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "slx-button slx-with-icon"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-paperclip slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "input", [["class", "attachments__input"], ["type", "file"]], [[8, "accept", 0]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onResetErrors($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "flex__grow attachments__types"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["Allowed file types: ", ". ", " max"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitAddAttachmentsDialogComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 12, "div", [["class", "flex align-items__center margin-t"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "p", [["class", "attachments__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(17, 0, null, null, 9, "p", [["class", "slx-benefit-box__value flex__grow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 8, "input", [["class", "slx-benefit-box__text-field"], ["name", "Name"], ["required", ""], ["type", "text"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 20).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 20)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 20)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.customFileName = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 540672, null, 0, i3.SlxMaxLengthValidator, [], { slxMaxLength: [0, "slxMaxLength"] }, null), i1.ɵdid(20, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(21, 16384, null, 0, i4.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i4.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i3.SlxMaxLengthValidator, i4.RequiredValidator]), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(24, 671744, [["fileNameField", 4]], 0, i4.NgModel, [[8, null], [6, i4.NG_VALIDATORS], [8, null], [6, i4.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i4.NgControl, null, [i4.NgModel]), i1.ɵdid(26, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitAddAttachmentsDialogComponent_5)), i1.ɵdid(29, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(34, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.errors; _ck(_v, 13, 0, currVal_3); var currVal_12 = 50; _ck(_v, 19, 0, currVal_12); var currVal_13 = ""; _ck(_v, 21, 0, currVal_13); var currVal_14 = "Name"; var currVal_15 = _co.customFileName; _ck(_v, 24, 0, currVal_14, currVal_15); var currVal_16 = i1.ɵnov(_v, 24).errors; _ck(_v, 29, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.acceptedFileTypesInputFormat; _ck(_v, 9, 0, currVal_0); var currVal_1 = _co.acceptedFileTypesReadable; var currVal_2 = _co.maxFileSize; _ck(_v, 11, 0, currVal_1, currVal_2); var currVal_4 = (i1.ɵnov(_v, 21).required ? "" : null); var currVal_5 = i1.ɵnov(_v, 26).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 26).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 26).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 26).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 26).ngClassValid; var currVal_10 = i1.ɵnov(_v, 26).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 26).ngClassPending; _ck(_v, 18, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_17 = (_co.isFileSelected || ((i1.ɵnov(_v, 24).errors == null) ? null : i1.ɵnov(_v, 24).errors.slxMaxLength)); _ck(_v, 31, 0, currVal_17); }); }
export function View_BenefitAddAttachmentsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "benefits-add-file-modal", [["class", "component"]], null, null, null, View_BenefitAddAttachmentsDialogComponent_0, RenderType_BenefitAddAttachmentsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.BenefitAddAttachmentsDialogComponent, [i6.DialogOptions, i7.ModalService, i8.BenefitsEnrollmentSectionManagementService], null, null)], null, null); }
var BenefitAddAttachmentsDialogComponentNgFactory = i1.ɵccf("benefits-add-file-modal.component", i5.BenefitAddAttachmentsDialogComponent, View_BenefitAddAttachmentsDialogComponent_Host_0, {}, {}, []);
export { BenefitAddAttachmentsDialogComponentNgFactory as BenefitAddAttachmentsDialogComponentNgFactory };
