/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./master-schedule-custom-ch.component";
var styles_MasterScheduleCustomColumnHeader = [".main-header-text[_ngcontent-%COMP%] {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-style: normal;\n    font-weight: bold;\n    border-right: initial;\n    border-bottom: initial;\n    color: #2F2F2F;\n    position: static;\n    margin-left: 1px;\n    font-size: 12px;\n  }\n  .additional-info-text[_ngcontent-%COMP%] {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-style: normal;\n    border-right: initial;\n    border-bottom: initial;\n    font-weight: normal;\n    color: #15A5BD;\n    position: static;\n    margin-left: 1px;\n    font-size: 12px;\n   }\n  .custom-column-header[_ngcontent-%COMP%] {\n    height: 100%;\n    position: relative;\n  }\n  .custom-column-header-holder[_ngcontent-%COMP%] {\n    position: absolute;\n    bottom: 5px;\n  }"];
var RenderType_MasterScheduleCustomColumnHeader = i0.ɵcrt({ encapsulation: 0, styles: styles_MasterScheduleCustomColumnHeader, data: {} });
export { RenderType_MasterScheduleCustomColumnHeader as RenderType_MasterScheduleCustomColumnHeader };
function View_MasterScheduleCustomColumnHeader_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "additional-info-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.additionalInfo; _ck(_v, 1, 0, currVal_0); }); }
export function View_MasterScheduleCustomColumnHeader_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "custom-column-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "custom-column-header-holder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "main-header-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MasterScheduleCustomColumnHeader_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !!_co.additionalInfo; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.params.displayName; _ck(_v, 3, 0, currVal_0); }); }
export function View_MasterScheduleCustomColumnHeader_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-master-schedule-custom-ch", [], null, null, null, View_MasterScheduleCustomColumnHeader_0, RenderType_MasterScheduleCustomColumnHeader)), i0.ɵdid(1, 49152, null, 0, i2.MasterScheduleCustomColumnHeader, [], null, null)], null, null); }
var MasterScheduleCustomColumnHeaderNgFactory = i0.ɵccf("slx-master-schedule-custom-ch", i2.MasterScheduleCustomColumnHeader, View_MasterScheduleCustomColumnHeader_Host_0, {}, {}, []);
export { MasterScheduleCustomColumnHeaderNgFactory as MasterScheduleCustomColumnHeaderNgFactory };
