import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { mutableSelect } from '../../../core/decorators/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { organizationConfig } from '../../../organization/organization.config';
import { Filter, Group } from '../../models/index';
var FilterAndGroupsComponent = /** @class */ (function () {
    function FilterAndGroupsComponent(lookupApiService) {
        this.lookupApiService = lookupApiService;
        this.filterLookups = {};
        this.lookupMapping = {
            'Position': 'position',
            'Shift': 'shift',
            'Unit': 'unit'
        };
        this.restrictedColumns = ['Position', 'Shift', 'Unit'];
        this.onGroupsChange = new EventEmitter();
        this.onFiltersChange = new EventEmitter();
    }
    FilterAndGroupsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.defaultFilters = _.clone(this.filters);
        this.defaultGroups = _.clone(this.groups);
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (selectedOrgLevel) { return selectedOrgLevel.type === OrgLevelType.department; })
            .subscribe(function (orgLevel) {
            _this.groups.forEach(function (group) {
                var lookupMapping = _this.lookupMapping[group.fieldName];
                _this.filterLookups[group.fieldName] = Observable.fromPromise(_this.lookupApiService.getLookup(organizationConfig.lookup[lookupMapping], orgLevel.id));
            });
        });
    };
    FilterAndGroupsComponent.prototype.ngOnDestroy = function () {
        this.orgLevelSubscription.unsubscribe();
    };
    FilterAndGroupsComponent.prototype.isFilteredBy = function (fieldName, value) {
        var applyedFilter = _.find(this.filters, function (filter) { return filter.fieldName === fieldName; });
        return applyedFilter && _.includes(applyedFilter.value, value);
    };
    FilterAndGroupsComponent.prototype.addFilter = function (fieldName, value) {
        var filter = _.find(this.filters, function (f) { return f.fieldName === fieldName; });
        if (!filter) {
            filter = new Filter();
            filter.fieldName = fieldName;
            filter.value = [value];
            this.filters.push(filter);
        }
        else if (_.includes(filter.value, value)) {
            _.pull(filter.value, value);
            if (!filter.value.length) {
                _.remove(this.filters, { fieldName: fieldName });
            }
        }
        else {
            filter.value.push(value);
        }
        this.onFiltersChange.emit(this.filters);
    };
    FilterAndGroupsComponent.prototype.removeGroupAndFilter = function (fieldName) {
        _.remove(this.filters, { fieldName: fieldName });
        _.remove(this.groups, { fieldName: fieldName });
        this.onGroupsChange.emit(this.groups);
        this.onFiltersChange.emit(this.filters);
    };
    FilterAndGroupsComponent.prototype.getGroupingFilter = function () {
        return this.groups.map(function (group) { return group.fieldName; });
    };
    FilterAndGroupsComponent.prototype.restoreDefaultFiltersAndGroupings = function () {
        this.groups = _.clone(this.defaultGroups);
        this.filters = _.clone(this.defaultFilters);
        this.onGroupsChange.emit(this.groups);
        this.onFiltersChange.emit(this.filters);
    };
    FilterAndGroupsComponent.prototype.addGroup = function (fieldName) {
        var group = new Group();
        group.fieldName = fieldName;
        this.groups.push(group);
        this.onGroupsChange.emit(this.groups);
    };
    FilterAndGroupsComponent.prototype.getAddGroupFilter = function () {
        var groupNames = this.groups.map(function (group) { return group.fieldName; });
        return this.restrictedColumns.filter(function (columnName) { return !_.includes(groupNames, columnName); });
    };
    FilterAndGroupsComponent.prototype.isFilter = function (fieldName) {
        return this.filters.map(function (filter) { return filter.fieldName; }).includes(fieldName);
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], FilterAndGroupsComponent.prototype, "orgLevel$", void 0);
    return FilterAndGroupsComponent;
}());
export { FilterAndGroupsComponent };
