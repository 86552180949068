import { Component, OnInit, Provider, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DialogOptions, IDialog, DialogOptions2, DialogModeSize } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { MasterScheduleContextMenuRequest } from '../../../../scheduler/models/master-schedule/master-schedule-context-menu-request';

@Component({
  selector: 'slx-view-individual-schedule-dialog',
  templateUrl: './view-individual-schedule-dialog.component.html',
  styleUrls: ['./view-individual-schedule-dialog.component.scss']
})
export class ViewIndividualScheduleDialogComponent implements IDialog {
  public dialogResult: boolean = false;
  public isLoading: boolean = false;
  private dialogOptions: DialogOptions;
  private modalService: ModalService;
  public scheduleViewParams: MasterScheduleContextMenuRequest;

  public static showDialog(modalService: ModalService,
    request: MasterScheduleContextMenuRequest,
    callback?: (result: boolean) => void): ViewIndividualScheduleDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();

    dialogOptions.width = '80%';
    dialogOptions.height = '80%';
    dialogOptions.minHeight = 320;

    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: MasterScheduleContextMenuRequest,
        useValue: request
      }
    ];

    return modalService.globalAnchor.openDialog2(
      ViewIndividualScheduleDialogComponent,
      `Individual Schedule`,
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor (
    options: DialogOptions,
    modalService: ModalService,
    request: MasterScheduleContextMenuRequest
  ) {
    this.dialogOptions = options;
    this.modalService = modalService;
    this.scheduleViewParams = request;
  }

  public onLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public onHasChanges(hasChanges: boolean): void {
    this.dialogResult = hasChanges;
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

}
