import * as moment from 'moment';

import { ColumnManagementService } from './../../../../common/services/column-settings/column-management.service';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { Component, Output, Inject, EventEmitter, OnInit } from '@angular/core';

import { Employee, SmsResponse, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from '../../../../core/models/index';
@Component({
  moduleId: module.id,
  selector: 'slx-assign-pay-cycles',
  templateUrl: 'assign-pay-cycles.component.html',
  styleUrls: ['assign-pay-cycles.component.scss'],
  providers: [ColumnManagementService]
})
export class AssignPayCyclesComponent implements OnInit {
  @Output()
  public onCancel: EventEmitter<any>;

  @Output()
  public onComplete: EventEmitter<string>;

  public actionEmployees: Employee[];

  public employees: Employee[];
  public fieldDefinition: EmployeeActionDefinition;
  public orgLevel: OrgLevel;


  public state: {
    isLoading: boolean;
  };
  public effectiveDate: Date;

  public period: any;
  public startDay1: number;
  public startDay2: number;
  public maxApprovedDate: Date;
  public canSend: boolean;
  public days: { min: number, max: number, canChoose: boolean };

  private selectedEmployees: Employee[];
  private groupActivitiesService: GroupActivitiesApiService;

  constructor(groupActivitiesService: GroupActivitiesApiService, @Inject(EMPLOYEES_ID_TOKEN) employees: Employee[], fieldDefinition: EmployeeActionDefinition,
              orgLevel: OrgLevel, private  modalService: ModalService) {
    this.groupActivitiesService = groupActivitiesService;
    this.employees = employees;
    this.fieldDefinition = fieldDefinition;
    this.orgLevel = orgLevel;
    this.onCancel = new EventEmitter<any>();
    this.onComplete = new EventEmitter<string>();
    this.days = {
      min: 1,
      max: 31,
      canChoose: false
    };

    this.state = {
      isLoading: false,
    };
    this.effectiveDate = moment(this.effectiveDate).toDate();
    this.validateEffectiveDate();
  }

  public get isSemiMonthly(): boolean {
    return this.period && this.period.name==='smonthly';
  }

  public get minDay(): number {
    return _.isNumber(this.startDay1) ? this.startDay1 : this.days.min;
  }

  public get maxDay(): number {
    return _.isNumber(this.startDay2) ? this.startDay2 : this.days.max;
  }

  public ngOnInit(): void {
    this.loadEmployeesList();
  }

  public onModelChanged(): void {
    this.validateEffectiveDate();
  }

  public validateEffectiveDate(): void {
    if (_.isDate(this.effectiveDate)) {
      this.days.canChoose = _.isDate(this.effectiveDate);
      this.days.max = moment(this.effectiveDate).daysInMonth();

      return;
    }

    this.days.canChoose = false;
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
    this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
  }

  public loadEmployeesList(): void {
    this.state.isLoading = true;
    this.maxApprovedDate = new Date(1950, 1);
    let employeeIds: string[] = _.map(this.employees, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
    this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, employeeIds, this.fieldDefinition)
      .then((actionEmployees: Employee[]) => {
        _.map(actionEmployees, (employee: Employee) => {
          if (employee['LastApprovedDate'] > this.maxApprovedDate) {
            this.maxApprovedDate = employee['LastApprovedDate'];
          }
        });
        this.maxApprovedDate = new Date(this.maxApprovedDate.getTime() + 86400000);
        this.actionEmployees = actionEmployees;
        this.state.isLoading = false;
      });
  }

  public createPayCycles(): void {
    this.state.isLoading = true;
    this.groupActivitiesService.createPayCycles(this.selectedEmployees, this.effectiveDate, this.period, this.startDay1, this.startDay2)
      .then((response: any) => {
        this.state.isLoading = false;
        this.afterSave();
        this.modalService.globalAnchor.openInfoDialog('Information', 'Pay Cycle created!',
        (result: boolean) => {
            this.cancel();
        });
        //this.loadEmployeesList();
      })
      .catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  private afterSave(): void {
    _.forEach(this.selectedEmployees, (emp: any) => {emp['isSelected'] = false; });
    this.selectedEmployees = null;
    this.canSend = false;
  }
}
