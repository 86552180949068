<div class="modal-content {{confirmOptions?.className}}">
    <div class="modal-body">
      <div class="mobile-icon" *ngIf="confirmOptions?.isWarningIcon != undefined">
        <em [ngClass]="{'fas fa-mobile-alt': confirmOptions?.isWarningIcon === false, 'fal fa-exclamation-triangle': confirmOptions?.isWarningIcon === true}"></em>
      </div>
      <div class="bootbox-body">{{options?.message}}</div>
      <div class="space-top" *ngIf="confirmOptions?.notes">
        <span>{{confirmOptions?.notes}}</span>
      </div>
    </div>
    <div class="modal-footer">
      <button *ngIf="confirmOptions.showCancel" type="button" (click)="onCancel()" class="theme-button-cancel margin-r">{{ cancelButtonText }}</button>
      <button *ngIf="confirmOptions.showOK" type="button" (click)="onOk()" class="theme-button-apply">{{ okButtonText }}</button>
    </div>
  </div>
  