import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Assert } from '../../../framework/index';
import { ResponseBody, Meta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../../time-and-attendance/time-and-attendance.config';
import { ApiUtilService, UrlParamsService, CacheUtilService } from '../../../common/services/index';
import { AttendancePointsDefinition, IAttendancePointsDefinition } from '../../../organization/models/lookup/index';
import { AttendancePointsConfigMapService } from './attendance-points-config-map.service';

@Injectable()
export class AttendancePointsConfigApiService {

  constructor(private attendancePointsConfigMapService: AttendancePointsConfigMapService,
              private apiUtilService: ApiUtilService,
              private urlParamsService: UrlParamsService,
              private cacheUtilService: CacheUtilService) {
  }

  public addEntry(req: AttendancePointsDefinition, orgLevelId: number): Promise<AttendancePointsDefinition> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getAttendancePointsApiRoot(orgLevelId)}`;
    let body: any = this.attendancePointsConfigMapService.mapToAttendancePointsDto(req);
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);

    let promise: Promise<AttendancePointsDefinition> = this.apiUtilService.request<IAttendancePointsDefinition, Meta>(request)
      .then((response: ResponseBody<IAttendancePointsDefinition, Meta>) => {
        this.cacheUtilService.clear();
        return this.attendancePointsConfigMapService.mapAttendancePoints(response.data);
      });
    return promise;
  }

  public saveEntry(req: AttendancePointsDefinition, orgLevelId: number): Promise<any> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getAttendancePointsApiRoot(orgLevelId)}/${req.id}`;
    let body: any = this.attendancePointsConfigMapService.mapToAttendancePointsDto(req);
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, body);

    let promise: Promise<AttendancePointsDefinition> = this.apiUtilService.request<IAttendancePointsDefinition, Meta>(request)
      .then((response: ResponseBody<IAttendancePointsDefinition, Meta>) => {
        this.cacheUtilService.clear();
        return this.attendancePointsConfigMapService.mapAttendancePoints(response.data);
      });
    return promise;
  }

  public deleteEntry(definitionId: number): Promise<any> {
    Assert.isNotNull(definitionId, 'definitionId');

    const url: string = `${this.getAttendancePointsApiRoot()}`;
    let body: any = [definitionId];
    let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url, null, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.cacheUtilService.clear();
        return response.data;
      });
    return promise;
  }

  private getApiRoot(): string {
    return this.apiUtilService.getApiRoot();
  }

  private getAttendancePointsApiRoot(orgLevelId?: number): string {
    const apiRoot = timeAndAttendanceConfig.api;
    let route = `${this.getApiRoot()}/${apiRoot.root}/${apiRoot.configuration.root}`;
    if(_.isNumber(orgLevelId)) {
      route += `/${apiRoot.orglevel.root}/${orgLevelId}`;
    }

    return `${route}/${apiRoot.configuration.attendancePoint.root}`;
  }
}
