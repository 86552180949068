import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import { range } from 'lodash';
import * as moment from 'moment';

export interface CalendarDate {
  mDate: moment.Moment;
  selected?: boolean;
  today?: boolean;
}

@Component({
  selector: 'slx-agency-console-weekly-calender',
  templateUrl: './agency-console-weekly-calender.component.html',
  styleUrls: ['./agency-console-weekly-calender.component.scss']
})
export class AgencyConsoleWeeklyCalenderComponent implements OnInit, OnChanges  {

 
  public currentDate: moment.Moment;
  public namesOfDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public weeks: Array<CalendarDate[]> = [];
  public selectedDate;
  public selectedStartWeek;
  public selectedEndWeek;
  public show: boolean;

  @ViewChild('calendar', {static: true}) calendar;

  @HostListener('document:click', ['$event'])
  clickOut(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.show = false;
    }
  }
  @Input()
  public selectedFilter: string ;

  @Output()
  public selectedDateRange: EventEmitter<string[]>

  constructor(private eRef: ElementRef) {
    this.selectedDateRange= new EventEmitter<string[]>();
  }

  ngOnInit() {
  
    this.currentDate = moment();
    this.selectedStartWeek = moment().weekday(0);
    this.selectedEndWeek = moment().weekday(6);
    this.selectedDate = `${this.selectedStartWeek.format('MM/DD/YYYY')} - ${this.selectedEndWeek.format('MM/DD/YYYY')}`;
    this.generateCalendar();
  }
  ngOnChanges(): void {    
    this.currentDate = moment();
    if(this.selectedFilter== "month") {
      this.selectedStartWeek = moment().startOf('month');
      this.selectedEndWeek = moment().endOf('month');
    }
    else if(this.selectedFilter== "week"){
      this.selectedStartWeek = moment().weekday(0);
      this.selectedEndWeek = moment().weekday(6);
    }
    else {
      this.selectedStartWeek = moment().startOf('day');
      this.selectedEndWeek = moment().endOf('day');
    }       
    this.selectedDate = `${this.selectedStartWeek.format('MM/DD/YYYY')} - ${this.selectedEndWeek.format('MM/DD/YYYY')}`;
    let dateRange:string[]= [];
    dateRange['startDate']= this.selectedStartWeek.format('YYYY-MM-DD');
    dateRange['endDate']= this.selectedEndWeek.format('YYYY-MM-DD')
    //this.selectedDateRange.emit(dateRange);
    this.generateCalendar();
    this.show = !this.show;
  }


  private generateCalendar(): void {
    const dates = this.fillDates(this.currentDate);
    const weeks = [];
    while (dates.length > 0) {
      weeks.push(dates.splice(0, 7));
    }
    this.weeks = weeks;
  }

  private fillDates(currentMoment: moment.Moment) {
    // index first day of month in week
    const firstOfMonth = moment(currentMoment).startOf('month').day();
    // index last day of month  in week
    const lastOfMonth = moment(currentMoment).endOf('month').day();

    const firstDayOfGrid = moment(currentMoment).startOf('month').subtract(firstOfMonth, 'days');
    // get last start of week + week
    const lastDayOfGrid = moment(currentMoment).endOf('month').subtract(lastOfMonth, 'days').add(7, 'days');

    const startCalendar = firstDayOfGrid.date();


    return range(startCalendar, startCalendar + lastDayOfGrid.diff(firstDayOfGrid, 'days')).map((date) => {
      const newDate = moment(firstDayOfGrid).date(date);
      return {
        today: this.isToday(newDate),
        selected: this.isSelected(newDate),
        mDate: newDate,
      };
    });
  }

  public prevMonth(): void {
    this.currentDate = moment(this.currentDate).subtract(1, 'months');
    this.generateCalendar();
  }

  public nextMonth(): void {
    this.currentDate = moment(this.currentDate).add(1, 'months');
    this.generateCalendar();
  }

  public isDisabledMonth(currentDate): boolean {
    const today = moment();
    return moment(currentDate).isBefore(today, 'months');
  }

  private isToday(date: moment.Moment): boolean {
    return moment().format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD');
  }

  private isSelected(date: moment.Moment): boolean {
    return moment(date).isBefore(this.selectedEndWeek) && moment(date).isAfter(this.selectedStartWeek)
      || moment(date.format('YYYY-MM-DD')).isSame(this.selectedStartWeek.format('YYYY-MM-DD'))
      || moment(date.format('YYYY-MM-DD')).isSame(this.selectedEndWeek.format('YYYY-MM-DD'));
  }

  public isDayBeforeLastSat(date: moment.Moment): boolean {
    const lastSat = moment().weekday(-1);
    return moment(date).isSameOrBefore(lastSat);
  }

  public isSelectedMonth(date: moment.Moment): boolean {
    return moment(date).isSame(this.currentDate, 'month');
  }


  public selectDate(date: CalendarDate) {    
    if(this.selectedFilter== "month") {  
      this.selectedStartWeek = moment(date.mDate).startOf('month');
      this.selectedEndWeek = moment(date.mDate).endOf('month');
    }
    else if(this.selectedFilter== "week"){
      this.selectedStartWeek = moment(date.mDate).startOf('week');
      this.selectedEndWeek = moment(date.mDate).endOf('week');
    }
    else {
      this.selectedStartWeek = moment(date.mDate).startOf('day');
      this.selectedEndWeek = moment(date.mDate).endOf('day');
    }           
    this.selectedDate = `${ this.selectedStartWeek.format('MM/DD/YYYY')} - ${this.selectedEndWeek.format('MM/DD/YYYY')}`;
   let dateRange:string[]= [];
    dateRange['startDate']= this.selectedStartWeek.format('YYYY-MM-DD');
    dateRange['endDate']= this.selectedEndWeek.format('YYYY-MM-DD')
    this.selectedDateRange.emit(dateRange);
    this.generateCalendar();
    this.show = !this.show;
  }
 
}

  


