import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { UserExtendedSettings ,IUserExtendedSettings } from '../../models/index';
import {UserExtendedSettingsApiService} from './user-extended-settings-api.service'

@Injectable()
export class UserExtendedSettingsManagementService {

   public isLiveChatMenuenabled :boolean = false;
  constructor( private userSettingsApiService: UserExtendedSettingsApiService) {
  }

  public async getLiveChatMenu(){
    
    await this.userSettingsApiService.getUserSettings()
    .then((value: UserExtendedSettings) => {
      this.isLiveChatMenuenabled = value.isLiveChatEndabled; 
    });
  }
}