export interface IReportsConfig {
  api: {
    root: string;
    template: string;
    generate: string;
    orglevel: {
      root: string,
      list: string
    };
    settings: {
      root: string,
      staffing: string,
      attendance: string,
      dailyStaffing: string,
      midnight: string,
      saveMidnightConfig: {
        root: string
      }
    },
    w2document:{
      root: string
      
    }
  };
}

export const reportsConfig: IReportsConfig = {
  api: {
    root: 'reports',
    template: 'reporttemplate.aspx',
    generate: 'generate',
    orglevel: {
      root: 'orglevel',
      list: 'list'
    },
    settings: {
      root: 'config',
      staffing: 'staffings',
      attendance:'attendance',
      dailyStaffing: 'Dailystaffings',
      midnight: 'Midnight',
      saveMidnightConfig: {
        root: 'save'
      }
    },
    w2document:{
     
      root: 'W2',
    }
  }
};
