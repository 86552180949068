import { HoursSummaryContainer } from './hours-summary.container';
import { PeriodsSummaryContainer } from './periods-summary.container';
import { ExemptsSummaryContainer } from './exempts-summary.container';
import { PositionsSummaryContainer } from './positions-summary.container';
import { TimecardSummaryPaidHoursDto } from './timecard-summary-paid-hours-dto';
import { TimecardSummaryPeriodCompareDto } from './timecard-summary-period-compare-dto';
import { TimecardSummaryExemptEmployeeDto } from './timecard-summary-exempt-employee-dto';
import { TimecardSummarySecondaryPositionDto } from './timecard-summary-secondary-position-dto';
import { ICorporation, Corporation, IDepartment, Department, PayCycle, IPayCycle, IOrganization, Organization } from '../../../organization/models/index';

export class TimecardSummaryContainerDTO {

    public corporation: ICorporation;
    public organization: IOrganization;
    public payrollCycle: IPayCycle;
    public exportRequestedBy: string;
    public releasedToPayrollBy: string;
    public employeesCount: number;
    public manuallyModifiedTimecardCount: number;
    public approvalRequiredTimecardCount: number;
    public pendingApprovalCount: number;
    public terminatedEmployeesCount: number;

    public paidHours: TimecardSummaryPaidHoursDto[];
    public periodCompare: TimecardSummaryPeriodCompareDto[];
    public exemptEmployee: TimecardSummaryExemptEmployeeDto[];
    public secondaryPosition: TimecardSummarySecondaryPositionDto[];
}

export class TimecardSummaryModel {

    public corporation: Corporation;
    public organization: Organization;
    public payrollCycle: PayCycle;
    public exportRequestedBy: string;
    public releasedToPayrollBy: string;
    public employeesCount: number;
    public manuallyModifiedTimecardCount: number;
    public approvalRequiredTimecardCount: number;
    public pendingApprovalCount: number;
    public terminatedEmployeesCount: number;

    public paidHours: HoursSummaryContainer;
    public periodCompare: PeriodsSummaryContainer;
    public exemptEmployees: ExemptsSummaryContainer;
    public secondaryPosition: PositionsSummaryContainer;
}
