import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { DateRange, IDateRange } from '../../../../core/models/index';
import { Subscription } from 'rxjs';
import { Assert } from '../../../../framework';


@Injectable()
export class ProjectedHoursManagementService {
  private dateRange$ = new Subject<DateRange>();
  private exportTo$ = new Subject<boolean>();

  constructor () { }

  public getDefaultDateRange(): DateRange {
    let startDate = moment().startOf('week').toDate();
    let endDate = moment().endOf('week').toDate();
    return new DateRange(startDate, endDate);
  }

  public subscribeToDateRange(callback: (dateRange: IDateRange) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.dateRange$.subscribe(callback);
  }

  public subscribeToExport(callback: (isPdf: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }

  public doExport(isPdf: boolean): void {
    this.exportTo$.next(isPdf);
  }

  public changeDateRange(dateRange: IDateRange): void {
    this.dateRange$.next(dateRange);
  }

}
