import { ModalService } from './../../../../../common/services/modal/modal.service';
import { ConfirmDialogComponent, ConfirmOptions } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { PayrollExportGroup } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { PayrollExportManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-payroll-export-section',
  templateUrl: 'payroll-export-section.component.html',
  styleUrls: ['payroll-export-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PayrollExportSectionComponent {

  public isCollapsed: boolean;

  @Input()
  public group: PayrollExportGroup;

  @unsubscribe()
  private loadedSubscription: Subscription;

  public get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  constructor(private managementService: PayrollExportManagementService,
              private changeDetector: ChangeDetectorRef,
              public modalService: ModalService) {
  }

  public isExportAvailable(): boolean {
    if (!this.group) return false;

    let orgCount: number = this.group.facilities ? this.group.facilities.length : 0;

    const result: boolean = this.group.canReleaseToPayroll
      && !this.group.isAllFacilitiesReleased
      && this.group.isAllFacilitiesRequested
      && this.group.groupOrgCount === orgCount;

    return result;
  }

  public getRequestColor(): string {
    return this.group && this.group.isAllFacilitiesRequested ? '#8FAE15' : '#0092CB';
  }

  public collapsedChange(isCollapsed: boolean): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public onReleaseToPayroll(): void {
    this.managementService.releaseToPayroll(this.group.name);
  }

  public onCancelRelease(): void {
    let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog(
          'Cancel Release',
          'Cancelling the release of this payroll will delete this request force you to resubmit. Are you sure you want to proceed?',
          this.modalService,
          (result: boolean) => {
            if (result) {
                this.managementService.cancelRelease(this.group.name);
            }
          },
          options
        );
  }
}
