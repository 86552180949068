import { Injectable } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { ApiUtilService, UrlParamsService, CacheUtilService } from "./../../../../app/common";
import { Actions, ResponseBody, FieldsMeta } from "./../../../../app/core/models";
import { MetaMapService } from "./../../../../app/core/services";
import { IShift, IShiftGroup, ShiftGroup, UpdateShiftGroup } from "../../models";
import { ShiftGroupMapService } from "./shift-group-map.service";
import { configurationConfig } from "../../configuration.config";

@Injectable()
export class ShiftGroupApiService {
    constructor(private apiUtilService: ApiUtilService,
        private urlParamService: UrlParamsService,
        private shiftGroupMapService: ShiftGroupMapService,
        private metaMapService: MetaMapService,
        private cacheUtilService: CacheUtilService) { }

    public getShiftGroupList(orgLevelId: number): Promise<IShiftGroup> {
        const url: string = this.getUrlRoot(orgLevelId);
        const request = this.urlParamService.createGetRequest(url);
        let promise: any = this.apiUtilService.request<IShiftGroup[], null>(request)
            .then((response: ResponseBody<IShiftGroup[], Meta>) => this.shiftGroupMapService.mapShiftGroup(response.data));
        return promise;
    }

    public updateShiftGroup(orgLevelId: number, shiftGroup: UpdateShiftGroup): Promise<UpdateShiftGroup> {
        const url: string = this.getPutUrlRoot(orgLevelId);
        const request = this.urlParamService.createPutRequest(url, shiftGroup);
        return this.apiUtilService.request<UpdateShiftGroup, null>(request)
      .then((response: ResponseBody<UpdateShiftGroup, Meta>) => response.data);
    }

    public getUrlRoot(orgLevelId: number) {
        return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.shiftGroup.root}`;
    }

    public getPutUrlRoot(orgLevelId: number) {
        return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.shiftGroup.root}/${configurationConfig.api.configuration.shiftGroup.updateShiftGroup}`;
    }
}