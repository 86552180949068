
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { SessionService } from '../../../app/core/services';
import { MessageConversion } from './models/message-conversation';
import { IMessageList, MessageList, EmployeeProfilePicture, IEmployeeProfilePicture, UserPicture, IUserPicture, LatestScheduleCycle, ILatestScheduleCycle, IColdStorageFlag, ColdStorageFlag, IMessageCountResponse, MessageCountResponse } from './models';
import { Assert } from '../../framework/index';
import { ComposeNewTabsPermissions } from './models/ComposeNewTabsPermissions';
import { ISlateLatestMessageInfo, ISlateMessageEmployeeByGroupIdInfo, ISlateMessageEmployeeInfo, ISlateMessageEmployeesInGroup, ISlateMessageGroupInfo, ISlateMessageGroupInfoOfGroupId, ISlateMessageInfoOfGroup, SlateLatestMessageInfo, SlateMessageEmployeeByGroupIdInfo, SlateMessageEmployeeInfo, SlateMessageEmployeesInGroup, SlateMessageGroupInfo, SlateMessageGroupInfoOfGroupId, SlateMessageInfoOfGroup } from './models/slate-message-group-info';

@Injectable({
  providedIn: 'root'
})
export class MessagemapService {

  constructor(private sessionService: SessionService) { }

  public mapToMessages(dtos: any): any[] {
    return _.map(dtos, v => this.mapToMessageList(v));
  }
  public mapToMessageList(dto: any): any {
    return dto;
  }

  public mapToMessageThread(data: any) {
    let thread = [];
    _.forEach(data, (record) => {
      let r: MessageConversion = {
        from: this.getFrom(record),
        time: record.created !== null ? record.created : record.modified,
        message: record.body,
        replied: record.replied,
        phone: record.sender,
        status: record.status,
        id: record.id,
        read: record.read,
        employeeId: record.employeeId,
        errorCode: record.errorCode,
        isOptInRequest: record.isOptInRequest,
        userId: record.userId,
        userName: record.userName
      };
      thread.push(r);
    });
    return thread;
  }

  public getFrom(val) {
    const me = "Me"
    let from;
    if (val.userId !== this.sessionService.getUser().id) {
      from = val.userName;
    } else if (val.replied) {
      from = val.fullName;
    }
    else {
      from = me;
    }
    return from;
  }


  public mapToTabCountRecords(data: IMessageCountResponse[]): MessageCountResponse[] {
    const arr: IMessageCountResponse[] = [];
    _.forEach(data, (record: IMessageCountResponse) => {
      arr.push(this.mapToTabCountRecord(record));
    });
    return arr;
  }
  public mapToTabCountRecord(data: IMessageCountResponse): MessageCountResponse {
    Assert.isNotNull(data, 'data');
    const messageCountResponse: MessageCountResponse = new MessageCountResponse();
    messageCountResponse.sectionName=data.sectionName;
    messageCountResponse.messageCount=data.messageCount;
    return messageCountResponse;
  }

  public mapToMessasgeListRecords(data: any): MessageList[] {
    const arr: IMessageList[] = [];
    _.forEach(data, (record: IMessageList) => {
      arr.push(this.mapToMessageListRecord(record));
    });
    return arr;
  }

  public mapToMessageListRecord(data: any): MessageList {
    Assert.isNotNull(data, 'data');
    const messageList = new MessageList();
    messageList.id = data.id;
    messageList.alias = data.alias;
    messageList.archived = data.archived;
    messageList.body = data.body;
    messageList.employeeId = data.employeeId;
    messageList.employeeName = data.employeeName;
    messageList.modified = data.created == null ? data.modified : data.created;
    messageList.read = data.read != null ? data.read: false ;
    messageList.replyCount = data.replyCount;
    messageList.isOnLeave = data.isOnLeave;
    messageList.mobilePhoneNumber = data.mobilePhoneNumber;
    messageList.optIn = data.optIn;
    messageList.orgLevelId = data.orgLevelId;
    messageList.photo = data.photo;
    messageList.status = data.status;
    messageList.fullName = data.employeeName;

    return messageList;
  }

  public mapToRecentMessage(data: any): MessageList {
    Assert.isNotNull(data, 'data');
    const messageList = new MessageList();
    messageList.id = data.id;
    messageList.alias = data.alias;
    messageList.archived = data.archived;
    messageList.body = data.body;
    messageList.employeeId = data.employeeId;
    messageList.employeeName = data.employeeName;
    messageList.modified = data.modified == null ? data.created : data.modified;
    messageList.read = data.read != null ? data.read: false ;
    messageList.replyCount = data.replyCount;
    messageList.isOnLeave = data.isOnLeave;
    messageList.mobilePhoneNumber = data.mobilePhoneNumber;
    messageList.optIn = data.optIn;
    messageList.orgLevelId = data.orgLevelId;
    messageList.photo = data.photo;
    messageList.status = data.employeeStatus;
    messageList.fullName = data.employeeName;

    return messageList;
  }

  public mapToEmployeeProfilePictures(data: any): EmployeeProfilePicture[] {
    const arr: IEmployeeProfilePicture[] = [];
    _.forEach(data, (record: IMessageList) => {
      arr.push(this.mapToEmployeeProfilePicture(record));
    });
    return arr;
  }

  public mapToEmployeeProfilePicture(data: any): EmployeeProfilePicture {
    Assert.isNotNull(data, 'data');
    const picture = new EmployeeProfilePicture();
    picture.employeeId = data.employeeId;
    picture.fullName = data.fullName;
    picture.isOnLeave = data.isOnLeave;
    picture.mobilePhoneNumber = data.mobilePhoneNumber;
    picture.optIn = data.optIn;
    picture.orgLevelId = data.orgLevelId;
    picture.photo = data.photo;
    picture.status = data.status;
    return picture;
  }

  public mapToUserPictures(data: any): UserPicture[] {
    const arr: IUserPicture[] = [];
    _.forEach(data, (record: IUserPicture) => {
      arr.push(this.mapToUserPicture(record));
    });
    return arr;
  }

  public mapToUserPicture(data: any): UserPicture {
    Assert.isNotNull(data, 'data');
    const picture = new UserPicture();
    picture.userId = data.userId;
    picture.profilePicture = data.photo;

    return picture;
  }

  public mapToLatestScheduleCycle(data: any): ILatestScheduleCycle {
    Assert.isNotNull(data, 'data');
    const latestCycle = new LatestScheduleCycle();
    latestCycle.startDate = data.startDate;
    latestCycle.endDate = data.endDate;
    return latestCycle;
  }

  public mapToColdStorageFlag(data: any): IColdStorageFlag {
    Assert.isNotNull(data, 'data');
    const coldStorageFlag = new ColdStorageFlag();
    coldStorageFlag.hasMessages = data.hasMessages;
    return coldStorageFlag;
  }

  public mapToSlateMessageGroupInfo(slateMessageGroupInfosDto: Array<ISlateMessageGroupInfo>): SlateMessageGroupInfo[] {
    const slateMessageGroupInfos: Array<SlateMessageGroupInfo> = new Array<SlateMessageGroupInfo>();
    slateMessageGroupInfosDto.forEach(dto => {
      const slateMessageGroupInfo = new SlateMessageGroupInfo();
      slateMessageGroupInfo.groupId = dto.groupId;
      slateMessageGroupInfo.createdByDisplayName = dto.createdByDisplayName;
      slateMessageGroupInfo.createdDate = dto.createdDate;
      slateMessageGroupInfo.employees = this.mapToSlateMessageEmployeeInfo(dto.employees);
      slateMessageGroupInfo.latestMessage = this.mapToSlateLatestMessageInfo(dto.latestMessage);
      slateMessageGroupInfo.subject = dto.subject;
      slateMessageGroupInfo.updatedDate = dto.updatedDate;
      slateMessageGroupInfos.push(slateMessageGroupInfo);
    });

    return slateMessageGroupInfos;
  }

  public mapToSlateMessageGroupInfoOfGroupId(SlateMessageGroupInfosOfGroupIdDto: Array<ISlateMessageGroupInfoOfGroupId>): SlateMessageGroupInfoOfGroupId[] {
    const slateMessageGroupInfos: Array<SlateMessageGroupInfoOfGroupId> = new Array<SlateMessageGroupInfoOfGroupId>();
    SlateMessageGroupInfosOfGroupIdDto.forEach(dto => {
      const slateMessageGroupInfoOfGroupId = new SlateMessageGroupInfoOfGroupId();
      slateMessageGroupInfoOfGroupId.groupId = dto.groupId;
      slateMessageGroupInfoOfGroupId.subject = dto.subject;
      slateMessageGroupInfoOfGroupId.createdByDisplayName = dto.createdByDisplayName;
      slateMessageGroupInfoOfGroupId.messages = this.mapToSlateMessageInfoOfGroup(dto.messages);
      slateMessageGroupInfoOfGroupId.employees = this.mapToSlateMessageEmployeesInGroup(dto.employees);
      slateMessageGroupInfos.push(slateMessageGroupInfoOfGroupId);
    });

    return slateMessageGroupInfos;
  }

  private mapToSlateMessageEmployeesInGroup(slateMessageEmployeesInGroupDto: ISlateMessageEmployeesInGroup[]): SlateMessageEmployeesInGroup[] {
    const slateMessageEmployeesInGroupList: Array<SlateMessageEmployeesInGroup> = new Array<SlateMessageEmployeesInGroup>();
    slateMessageEmployeesInGroupDto.forEach(dto => {
      const slateMessageEmployeesInGroup: SlateMessageEmployeesInGroup = new SlateMessageEmployeesInGroup();
      slateMessageEmployeesInGroup.employeeId = dto.employeeId;
      slateMessageEmployeesInGroup.employeeName = dto.employeeName;
      slateMessageEmployeesInGroup.read = dto.read;
      slateMessageEmployeesInGroup.readAt = dto.readAt;
      slateMessageEmployeesInGroupList.push(slateMessageEmployeesInGroup);
    });

    return slateMessageEmployeesInGroupList;
  }
  public mapToComposeNewTabsPermissions(data:any):ComposeNewTabsPermissions{
    Assert.isNotNull(data,'data');
    const composeNewTabsPermissions = new ComposeNewTabsPermissions();
    composeNewTabsPermissions.canSendSMS = data.data.canSendSMS;
    composeNewTabsPermissions.canSendSlate = data.data.canSendSlate;
    composeNewTabsPermissions.canViewSlateTab = data.data.canViewSlateTab;
    composeNewTabsPermissions.canViewSmsTab = data.data.canViewSmsTab;
    return composeNewTabsPermissions;
  }


  private mapToSlateMessageInfoOfGroup(slateMessageInfosOfGroupDto: Array<ISlateMessageInfoOfGroup>): SlateMessageInfoOfGroup[] {
    const slateMessageInfosOfGroupList: Array<SlateMessageInfoOfGroup> = new Array<SlateMessageInfoOfGroup>();
    slateMessageInfosOfGroupDto.forEach(dto => {
      const slateMessageInfoOfGroup = new SlateMessageInfoOfGroup();
      slateMessageInfoOfGroup.createdByDisplayName = dto.createdByDisplayName;
      slateMessageInfoOfGroup.createdDate = dto.createdDate;
      slateMessageInfoOfGroup.messageId = dto.messageId;
      slateMessageInfoOfGroup.messageContent = dto.messageContent;
      slateMessageInfoOfGroup.employees = this.mapToSlateMessageEmployeeByGroupIdInfo(dto.employees);
      slateMessageInfosOfGroupList.push(slateMessageInfoOfGroup);
    });

    return slateMessageInfosOfGroupList;
  }

  private mapToSlateMessageEmployeeByGroupIdInfo(slateMessageEmployeesByGroupIdInfoDto: Array<ISlateMessageEmployeeByGroupIdInfo>): SlateMessageEmployeeByGroupIdInfo[] {
    const slateMessageEmployeeByGroupIdInfoList: Array<SlateMessageEmployeeByGroupIdInfo> = new Array<SlateMessageEmployeeByGroupIdInfo>();
    slateMessageEmployeesByGroupIdInfoDto.forEach(dto => {
      const slateMessageEmployeeByGroupIdInfo: SlateMessageEmployeeByGroupIdInfo = new SlateMessageEmployeeByGroupIdInfo();
      slateMessageEmployeeByGroupIdInfo.employeeId = dto.employeeId;
      slateMessageEmployeeByGroupIdInfo.read = dto.read;
      slateMessageEmployeeByGroupIdInfo.readAt = dto.readAt;
      slateMessageEmployeeByGroupIdInfoList.push(slateMessageEmployeeByGroupIdInfo);
    });

    return slateMessageEmployeeByGroupIdInfoList;
  }

  private mapToSlateLatestMessageInfo(slateLatestMessageInfoDto: ISlateLatestMessageInfo): SlateLatestMessageInfo {
    const slateLatestMessageInfo = new SlateLatestMessageInfo();
    slateLatestMessageInfo.createdDate = slateLatestMessageInfoDto.createdDate;
    slateLatestMessageInfo.messageContent = slateLatestMessageInfoDto.messageContent;
    slateLatestMessageInfo.messageId = slateLatestMessageInfoDto.messageId;
    return slateLatestMessageInfo;
  }

  private mapToSlateMessageEmployeeInfo(SlateMessageEmployeeInfosDto: Array<ISlateMessageEmployeeInfo>) {
    const slateMessageEmployeesInfo = new Array<SlateMessageEmployeeInfo>();
    SlateMessageEmployeeInfosDto.forEach(dto => {
      const slateMessageEmployeeInfo = new SlateMessageEmployeeInfo();
      slateMessageEmployeeInfo.employeeId = dto.employeeId;
      slateMessageEmployeeInfo.employeeName = dto.employeeName;
      slateMessageEmployeesInfo.push(slateMessageEmployeeInfo);
    });

    return slateMessageEmployeesInfo;
  }
}
