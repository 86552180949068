import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { StaffingPredictorManagementService } from '../../services/index';
import { FiveStarRatingContainer, StaffingRatingContainer } from '../../models/staffing-rating-container';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import html2pdf from 'html2pdf.js'
import { AppSettingsManageService } from '../../../../app-settings/services/index';
import { SessionService } from '../../../../core/services/session/session.service';

@Component({
    moduleId: module.id,
    selector: 'slx-staffing-predictor',
    templateUrl: 'staffing-predictor.component.html',
    styleUrls: ['staffing-predictor.component.scss'],
    providers: [StaffingPredictorManagementService]
})
export class StaffingPredictorComponent implements OnInit, OnDestroy {

    public isLoading: boolean;
    public container: StaffingRatingContainer;
    public fiveStartContainer: FiveStarRatingContainer;
    public five_star_v2_enabled: boolean = false;
    public five_star_goal_section_enabled: boolean = false;
    public yearSelected: number;
    public periodSelected: string;
    public orgSelected: string;
    public hideToPrint: boolean = false;

    @unsubscribe()
    private loadedSubscription: Subscription;
    @unsubscribe()
    private statusSubscription: Subscription;
    value: string;

    constructor(private managementService: StaffingPredictorManagementService, private changeDetector: ChangeDetectorRef,
        private appSettingsManageService: AppSettingsManageService,
        private sessionService: SessionService) { 
            this.managementService.disableCollapsableContainer$.subscribe(result=>{
                this.value = result[0] ? result[1] : '';
            })
        }

    public ngOnInit(): void {
        this.loadedSubscription = this.managementService.onLoaded$.subscribe((data: any) => {
            if (this.five_star_v2_enabled) {
                this.fiveStartContainer = data as unknown as FiveStarRatingContainer;
                this.container = new StaffingRatingContainer();

            } else {
                this.container = data as unknown as StaffingRatingContainer;
                this.fiveStartContainer = new FiveStarRatingContainer();
            }
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        });
        this.appSettingsManageService.getAppServerConfig().then((config) => {
                this.five_star_v2_enabled = config.five_star_v2_enabled;
                this.five_star_goal_section_enabled = config.five_star_goal_section_enabled;
        });
        this.statusSubscription = this.managementService.onLoadStatus$.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        });
    }

    public ngOnDestroy(): void {
        // See #issueWithAOTCompiler
    }

    public onYearSelected(option: number) {
        this.yearSelected = option;
    }

    public onPeriodSelected(option: string) {
        this.periodSelected = option;
    }

    public onOrgSelected(option: string) {
        this.orgSelected = option;
    }

    public getUserName (): string {
        var user = this.sessionService.getUser();
        return user.username;
    }

    public generatePDF() {
        this.hideToPrint = true;
        this.managementService.hideOnPrint(true);
        var element = document.getElementById('contentToConvert');
        var opt = {
            margin:       [5, 0, 5, 0],
            filename:     `Five-Star-${this.orgSelected}-${this.yearSelected}-${this.periodSelected}-${this.getUserName()}.pdf`,
            image:        { type: 'jpeg', quality: 1 },
            html2canvas:  { scale: 2 },
            jsPDF:        { unit: 'mm', orientation: 'portrait' }
        };
        html2pdf().from(element).set(opt).save().then(()=> {
            this.hideToPrint = false;
            this.managementService.hideOnPrint(false);
        }).catch(() => {
            this.hideToPrint = false;
            this.managementService.hideOnPrint(false);
        });
    }
}
