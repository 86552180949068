<div class="export-data-section" *ngIf="configuration">
  <div class="export-data-section-header">
    <div class="export-data-section-header-button-left">
      <slx-collapse-button [(isCollapsed)]="isCollapsed"  (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
    <div class="export-data-section-header-content">
      <div class="header-item header-title">
        <i class="fal fa-building header-title__icon regular-icon" aria-hidden="true"></i>
        <i class="fas fa-check-circle header-title__icon submitted-icon" aria-hidden="true"></i>
        <span class="header-title__text">{{configuration.name}}</span>
      </div>
      <div class="header-item" >
        <div class="button-item-bar-header" *ngIf="configuration.lastExecuted && !isExpired">
          <div *ngIf="configuration.lastExecuted.status === exportDataStatus.Waiting" class="ed-grey">
            <i class="fas fa-clock icon" aria-hidden="true"></i>
            <span>Waiting</span>
          </div>
          <div *ngIf="configuration.lastExecuted.status === exportDataStatus.Completed || configuration.lastExecuted.status === exportDataStatus.Expired" class="ed-green">
            <i class="fas fa-check-circle icon" aria-hidden="true"></i>
            <span>Completed</span>
          </div>
          <div *ngIf="configuration.lastExecuted.status === exportDataStatus.InProgress" class="ed-blue">
              <i class="fas fa-spinner fa-pulse icon" aria-hidden="true"></i>
              <span>In progress</span>
          </div>
          <div *ngIf="configuration.lastExecuted.status == exportDataStatus.Failed" class="ed-red">
            <i class="fas fa-times-circle icon" aria-hidden="true"></i>
            <span>Failed</span>
          </div>
          <div *ngIf="configuration.lastExecuted.status == exportDataStatus.Cancelled" class="ed-red">
            <i class="fas fa-times-circle icon" aria-hidden="true"></i>
            <span>Cancelled</span>
          </div>
        </div>
  
      </div>
    </div>
    <div class="export-data-section-header-button-right">
      <slx-collapse-button [(isCollapsed)]="isCollapsed" (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
  </div>
  <div #collapseContent class="export-data-section-content">
    <div class="export-data-section-toolbar">
      <div class="export-data-section-toolbar-parameters">
        <div *ngFor="let parameter of parameters">
          <div class="slx-toolbar-section slx-border" *ngIf="parameter.dataType.type !== 'hidden'">
            <span class="export-data-parameter_label">{{parameter.displayName}}</span>
            <div class="export-data-parameter_container">
              <slx-export-data-parameter [parameter]="parameter" [disabled]="!generateIsAllowed" [submitted]="isSubmitted">
              </slx-export-data-parameter>
            </div>
          </div>
        </div>
      </div>
      <div class="export-data-section-toolbar-actions">
        <button class="slx-button slx-blue slx-margin-r" type="button" [disabled]="!formValid" *ngIf="generateIsAllowed" (click)="generate()" >
          <span class="slx-button__text">Generate</span>
        </button>
        <button class="slx-button slx-blue slx-margin-r" type="button" *ngIf="cancelIsAllowed" (click)="cancelExecution()" >
          <span class="slx-button__text">Cancel</span>
        </button>
      </div>
    </div>

    <slx-export-data-history [configuration]="configuration">
    </slx-export-data-history>
  </div>
</div>
