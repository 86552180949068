import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import { RecaptchaInfo, RecaptchaInfoDto } from '../../../common/models/index';

@Injectable()
export class RecaptchaMapService {

  public mapInfo(item: RecaptchaInfoDto): RecaptchaInfo {
    Assert.isNotNull(item, 'item');
    let info: RecaptchaInfo = new RecaptchaInfo();
    info.siteKey = item.siteKey;
    return info;
  }
}


