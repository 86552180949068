import { Component, ViewChild, ElementRef } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

/*
Seems it is unaviliable to use separated template and css files with ag-grid custom components.
*/
@Component({
  selector: 'slx-master-schedule-custom-ch',
  template: `
  <div class="custom-column-header">
    <div class="custom-column-header-holder">
      <span class="main-header-text">{{params.displayName}}</span><span class="additional-info-text" *ngIf="!!additionalInfo">{{additionalInfo}}</span>
    <div>
  </div>`,
  styles: [`
  .main-header-text {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    border-right: initial;
    border-bottom: initial;
    color: #2F2F2F;
    position: static;
    margin-left: 1px;
    font-size: 12px;
  }
  .additional-info-text {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    border-right: initial;
    border-bottom: initial;
    font-weight: normal;
    color: #15A5BD;
    position: static;
    margin-left: 1px;
    font-size: 12px;
   }
  .custom-column-header {
    height: 100%;
    position: relative;
  }
  .custom-column-header-holder {
    position: absolute;
    bottom: 5px;
  }
  `]
})
export class MasterScheduleCustomColumnHeader implements IHeaderAngularComp {
  public additionalInfo: string;
  public params: any;
  private ascSort: string;
  private descSort: string;
  private noSort: string;
  public agInit(params: IHeaderParams): void {
    this.params = params;
    this.additionalInfo = this.params.additionalInfo;
    params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    this.onSortChanged();
  }
  public onSortChanged(): void {
    this.ascSort = this.descSort = this.noSort = 'inactive';
    if (this.params.column.isSortAscending()) {
      this.ascSort = 'active';
    } else if (this.params.column.isSortDescending()) {
      this.descSort = 'active';
    } else {
      this.noSort = 'active';
    }
  }
}
