import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthenticationService } from '../../services/index';
import { RecaptchaApiService, RecaptchaInfo } from '../../../common/index';
import { LoggerService } from '../../../core/services/index';
import { Assert } from '../../../framework/index';
import { ResponseBody, Meta, StatusCodes } from '../../../core/models/index';
import { ForgotPassword, ForgotPasswordCodes } from '../../models/password-reset';

@Component({
  moduleId: module.id,
  selector: 'slx-forgot-password-form',
  templateUrl: 'forgot-password-form.component.html',
  styleUrls: ['forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
  public email: string;
  public admins: string[];
  public siteKey: string;
  public username: string;
  public recaptcha: string;

  public state: {
    isInitialized: boolean;
    isLoading: boolean;
    isUnknownError: boolean;
    isValidationError: boolean;
    hasFailed: boolean;
    hasSuccedeed: boolean;
    userNotFound: boolean;
  };

  constructor(private authenticationService: AuthenticationService, private loggerService: LoggerService, private recaptchaApiService: RecaptchaApiService) {
    Assert.isNotNull(authenticationService, 'authenticationService');
    Assert.isNotNull(loggerService, 'loggerService');

    this.state = {
      isInitialized: false,
      isLoading: false,
      isUnknownError: false,
      isValidationError: false,
      hasFailed: false,
      hasSuccedeed: false,
      userNotFound: false
    };
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    this.state.isInitialized = false;

    this.recaptchaApiService.getInfo()
      .then((info: RecaptchaInfo) => {
        this.siteKey = info.siteKey;
        this.state.isLoading = false;
        this.state.isInitialized = true;
      })
      .catch(() => {
        this.state.isUnknownError = true;
        this.state.hasFailed = true;
        this.state.isLoading = false;
        this.state.isInitialized = true;
      });
  }

  public onResetPasswordClicked(): void {
    this.admins = [];
    this.email = null;
    this.state.isLoading = true;
    this.state.userNotFound = false;
    this.state.isUnknownError = false;
    this.state.isValidationError = false;

    this.authenticationService.forgotPassword(this.username, this.recaptcha)
      .then((response: ForgotPassword) => this.handleResetPasswordSuccess(response))
      .catch((error: ResponseBody<any, Meta>) => this.handleResetPasswordFailed(error));
  }

  public hideUserNotFoundErrorMessage(): void {
    this.state.userNotFound = false;
  }

  public hideUnknownErrorMessage(): void {
    this.state.isUnknownError = false;
  }

  public hideValidationErrorMessage(): void {
    this.state.isValidationError = false;
  }

  private handleResetPasswordSuccess(forgotPassword: ForgotPassword): void {
    this.state.isLoading = false;

    switch (forgotPassword.code) {
      case ForgotPasswordCodes.EmailSentToUser:
        this.email = forgotPassword.userMaskedEmail;
        this.state.hasSuccedeed = true;
        break;
      case ForgotPasswordCodes.NotificationSentToAdmin:
        this.admins = forgotPassword.adminNames;
        this.state.hasFailed = true;
        break;
      case ForgotPasswordCodes.InvalidCaptcha:
        this.state.isValidationError = true;
        break;
      case ForgotPasswordCodes.UserNotFound:
        this.state.userNotFound = true;
        break;
      default:
        this.state.isUnknownError = true;
        this.loggerService.error('Unexpected error when requesting a password reset token', forgotPassword.message);
        break;
    }
  }

  private handleResetPasswordFailed(error: ResponseBody<any, Meta>): void {
    Assert.isNotNull(error, 'error');

    this.state.isLoading = false;
    this.state.isUnknownError = true;

    this.loggerService.error('Unexpected error during authentication', error);
  }
}
