import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { OnInit } from '@angular/core';
import { unsubscribe } from './../../../../core/decorators';
import { FavoriteManagementService } from '../../../services';
var ModulesListConfigurationComponent = /** @class */ (function () {
    function ModulesListConfigurationComponent(favService) {
        this.favService = favService;
    }
    ModulesListConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuSubscription = this.favService.menuItemsUpdated$.subscribe(function (menuItems) {
            _this.menuItems = menuItems;
        });
    };
    ModulesListConfigurationComponent.prototype.onStarClicked = function (menuItem) {
        this.favService.changeFavoriteState(menuItem);
    };
    ModulesListConfigurationComponent.prototype.canBeFavorite = function (menuItem) {
        return this.favService.canBeFavorite(menuItem);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ModulesListConfigurationComponent.prototype, "menuSubscription", void 0);
    return ModulesListConfigurationComponent;
}());
export { ModulesListConfigurationComponent };
