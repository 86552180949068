import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../common/services/index';
import { BenefitAddAttachmentsDialogComponent } from '../components/employee-sections-benefits-management/employee-sections-enrollment/benefits-add-file-modal/benefits-add-file-modal.component';

@Directive({
    selector: '[slxAttachDocumentsDialog]',
})
export class EmployeeEnrollmentAddAttachmentDirective {
    constructor(private modalService: ModalService) { }

    @Output()
    public attachDocument = new EventEmitter<any>();

    @HostListener('click', ['$event'])
    public onClick(e: MouseEvent): void {
        const dialog = BenefitAddAttachmentsDialogComponent.openDialog(
            this.modalService,
            (result: boolean) => {
                if (result) {
                    const data = {
                        name: dialog.customFileName,
                        files: dialog.files
                    };
                    this.attachDocument.emit(data);
                }
            }
        );
    }
}
