import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { MessagesService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
var SingleMessageComponent = /** @class */ (function () {
    function SingleMessageComponent(messagesService) {
        var _this = this;
        this.messagesService = messagesService;
        this.level = 'warning';
        this.canClose = true;
        if (messagesService) {
            this.resetClosedSubscription = messagesService.resetClosed$.subscribe(function () {
                _this.m_isClosed = false;
            });
        }
    }
    Object.defineProperty(SingleMessageComponent.prototype, "isActive", {
        get: function () {
            return this.m_isActive && !this.m_isClosed;
        },
        set: function (value) {
            if (!this.m_isActive && value) {
                this.m_isClosed = false;
            }
            this.m_isActive = value;
        },
        enumerable: true,
        configurable: true
    });
    SingleMessageComponent.prototype.onClose = function () {
        this.m_isClosed = true;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SingleMessageComponent.prototype, "resetClosedSubscription", void 0);
    return SingleMessageComponent;
}());
export { SingleMessageComponent };
