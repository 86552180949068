import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../../../core/models/index';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsScheduleMapService } from './employee-section-schedule-map.service';
import {
  RotationTemplate,
  EmployeeRotation,
  IEmployeeSectionsRotations,
  EmployeeSectionsRotations,
  IEmployeeSectionsSchedule,
  EmployeeSectionsSchedule,
  IEmployeeSectionsAvailability,
  EmployeeSectionsAvailability,
  RotationModifyResponse, IRotationModifyResponse,
  RotationModifyRequest, IRotationModifyRequest
} from '../../models/index';
import { IEmployeePositionDefinition, EmployeePositionDefinition } from '../../../../organization/models/employee/employee-position-definition';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class EmployeeSectionsScheduleApiService {
  constructor(
    private employeeSectionsScheduleMapService: EmployeeSectionsScheduleMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getScheduleSection(employeeId: number): Promise<EmployeeSectionsSchedule> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getScheduleApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsSchedule> = this.apiUtilService.request<IEmployeeSectionsSchedule, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsSchedule, Meta>) =>
        this.employeeSectionsScheduleMapService.mapToSection(response.data)
      );
    return promise;
  }

  public getAvailability(employeeId: number, startDate: Date, endDate: Date): Promise<EmployeeSectionsAvailability> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getScheduleAvailabilityApiRoot(employeeId)}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamsService.convertToParams({
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      })
    });
    let promise: Promise<EmployeeSectionsAvailability> = this.apiUtilService.request<IEmployeeSectionsAvailability, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsAvailability, FieldsMeta>) =>
        this.employeeSectionsScheduleMapService.mapAvailability(response.data, response.meta)
      );
    return promise;
  }

  public setAvailability(employeeId: number, startDate: Date, endDate: Date, subsection: EmployeeSectionsAvailability): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(subsection, 'subsection');

    const url: string = `${this.getScheduleAvailabilityApiRoot(employeeId)}`;

    let body: IEmployeeSectionsAvailability = this.employeeSectionsScheduleMapService.mapAvailabilityDto(subsection);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body, {
      params: this.urlParamsService.convertToParams({
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      })
    });

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request);

    return promise;
  }

  public getRotations(employeeId: number): Promise<EmployeeSectionsRotations> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getScheduleRotationsApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsRotations> = this.apiUtilService.request<IEmployeeSectionsRotations, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsRotations, FieldsMeta>) =>
        this.employeeSectionsScheduleMapService.mapToRotations(response.data, response.meta)
      );

    return promise;
  }

  public getImportEmployees(employeeId: number): Promise<EmployeePositionDefinition[]> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getScheduleRotationsImportEmployeesApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeePositionDefinition[]> = this.apiUtilService.request<IEmployeePositionDefinition[], FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeePositionDefinition[], FieldsMeta>) =>
        this.employeeSectionsScheduleMapService.mapToEmployeePositions(response.data, response.meta)
      );

    return promise;
  }

  public importRotations(employeeId: number, importEmployeeId: number): Promise<any> {
    const url: string = `${this.getScheduleRotationsImportApiRoot(employeeId)}`;
    let params: HttpParams = this.urlParamsService.convertToParams({ importEmployeeId: importEmployeeId.toString() });

    let request: HttpRequest<any> = new HttpRequest('PUT', url, null, {
      params: params
    });

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );

    return promise;
  }

  public generateRotations(employeeId: number, template: RotationTemplate): Promise<any> {
    Assert.isNotNull(template, 'template');

    const url: string = `${this.getScheduleRotationsGenerateApiRoot(employeeId)}`;
    let body: any = this.employeeSectionsScheduleMapService.mapToRotationTemplateDto(template);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );
    return promise;
  }

  public setRotations(employeeId: number, req: RotationModifyRequest): Promise<RotationModifyResponse> {
    Assert.isNotNull(req, 'request');

    const url: string = `${this.getScheduleRotationsApiRoot(employeeId)}`;
    let body: any = this.employeeSectionsScheduleMapService.mapModifyRotationRequestDTO(req);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<RotationModifyResponse> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<IRotationModifyResponse, FieldsMeta>) => this.employeeSectionsScheduleMapService.mapModifyRotationResponse(response.data)
      );
    return promise;
  }

  public restoreRotations(employeeId: number, rotation: EmployeeRotation): Promise<any> {
    Assert.isNotNull(rotation, 'rotation');

    const url: string = `${this.getScheduleRotationsRestoreApiRoot(employeeId)}`;
    let body: any = this.employeeSectionsScheduleMapService.mapToRotationsDto(rotation);
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<number, FieldsMeta>) => response.status
      );
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getSectionsApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getScheduleApiRoot(employeeId: number): string {
    return `${this.getSectionsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.schedule.root}`;
  }

  private getScheduleAvailabilityApiRoot(employeeId: number): string {
    return `${this.getScheduleApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.schedule.availability}`;
  }

  private getScheduleRotationsApiRoot(employeeId: number): string {
    return `${this.getScheduleApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.schedule.rotations.root}`;
  }

  private getScheduleRotationsRestoreApiRoot(employeeId: number): string {
    return `${this.getScheduleRotationsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.schedule.rotations.restore}`;
  }

  private getScheduleRotationsGenerateApiRoot(employeeId: number): string {
    return `${this.getScheduleRotationsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.schedule.rotations.generate}`;
  }

  private getScheduleRotationsImportApiRoot(employeeId: number): string {
    return `${this.getScheduleRotationsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.schedule.rotations.import.root}`;
  }

  private getScheduleRotationsImportEmployeesApiRoot(employeeId: number): string {
    return `${this.getScheduleRotationsImportApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.schedule.rotations.import.employees}`;
  }
}
