export class StatusCodes {
  public static unknown: number = 0;
  public static success: number = 200;
  public static noContent: number = 204;
  public static unauthorized: number = 401;
  public static forbidden: number = 401;
  public static notFound: number = 404;
  public static conflict: number = 409;
  public static inValidIPAddress: number = 400;
  public static exception: number = 500;
}
