import * as tslib_1 from "tslib";
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import { isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DocumentInterruptSource, StorageInterruptSource } from '@ng-idle/core';
import { unsubscribeInService } from '../../../core/decorators/index';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { TabMode } from '../../../core/models/index';
import { ThrottlingService } from '../throttling/throttling.service';
var UserActivityService = /** @class */ (function () {
    function UserActivityService(idle, throttle, router, appSettingsManageService) {
        var _this = this;
        this.idle = idle;
        this.throttle = throttle;
        this.router = router;
        this.appSettingsManageService = appSettingsManageService;
        this.state = serviceState.initial;
        this.m_activityTimeoutReached = false;
        this.isThresholdReached = false;
        Assert.isNotNull(idle, 'idle');
        Assert.isNotNull(router, 'router');
        this.onNavigationStarted = this.router.events.subscribe(function (value) {
            if (!!value && !!_this.idle) {
                _this.idle.interrupt();
            }
        });
        this.onTimeoutThresholdReached = new Subject();
        this.onInactivityTimedOut = new Subject();
        this.onNeedToHideIncativityDialog = new Subject();
        this.onIncativityReseted = new Subject();
        this.onTimeoutCountdown = new Subject();
    }
    Object.defineProperty(UserActivityService.prototype, "isActivityTimeoutReached", {
        get: function () {
            return this.m_activityTimeoutReached;
        },
        enumerable: true,
        configurable: true
    });
    UserActivityService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    UserActivityService.prototype.start = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.state !== serviceState.started && this.state !== serviceState.starting)) return [3 /*break*/, 3];
                        this.state = serviceState.starting;
                        if (!!this.servicesInited) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.initServices()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.startWatcher();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserActivityService.prototype.stop = function () {
        if (this.state === serviceState.started || this.state === serviceState.starting) {
            this.idle.stop();
            this.state = serviceState.stoped;
        }
    };
    UserActivityService.prototype.reset = function () {
        this.idle.interrupt();
    };
    Object.defineProperty(UserActivityService.prototype, "thresholdSeconds", {
        get: function () {
            return this.appServerConfig.thresholdSeconds;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserActivityService.prototype, "timeoutSeconds", {
        get: function () {
            return this.appServerConfig.timeoutSeconds;
        },
        enumerable: true,
        configurable: true
    });
    UserActivityService.prototype.startWatcher = function () {
        try {
            if (this.state === serviceState.started)
                throw new Error('Cannot start activity service since it has already beed started.');
            this.isThresholdReached = false;
            this.m_activityTimeoutReached = false;
            this.idle.watch();
            this.state = serviceState.started;
            if (isDevMode() && !appConfig.session.enableInactivityMonitorInDevMode)
                this.stop();
        }
        catch (error) {
            this.state = serviceState.failed;
            throw error;
        }
    };
    UserActivityService.prototype.initServices = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, interruptSources, idleName;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        _a.appServerConfig = _b.sent();
                        this.idle.setIdle(this.thresholdSeconds);
                        this.idle.setTimeout(this.timeoutSeconds);
                        interruptSources = [new DocumentInterruptSource('keydown mousedown touchstart'), new StorageInterruptSource()];
                        //this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
                        this.idle.setInterrupts(interruptSources);
                        idleName = "user-inactivity-idle" + moment().format();
                        this.idle.setIdleName(idleName);
                        this.subscribeToIdle();
                        this.subscribeToThrottle();
                        this.servicesInited = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    UserActivityService.prototype.subscribeToIdle = function () {
        var _this = this;
        this.idle.onTimeout.subscribe(function () {
            _this.m_activityTimeoutReached = true;
            _this.state = serviceState.stoped;
            _this.onNeedToHideIncativityDialog.next();
            _this.onInactivityTimedOut.next();
        });
        this.idle.onIdleEnd.subscribe(function () {
            _this.isThresholdReached = false;
            _this.onIncativityReseted.next();
        });
        this.idle.onIdleStart.subscribe(function () {
            if (!_this.isThresholdReached) {
                _this.isThresholdReached = true;
                _this.onTimeoutThresholdReached.next();
            }
        });
        this.idle.onTimeoutWarning.subscribe(function (countdown) {
            _this.onTimeoutCountdown.next(countdown);
        });
    };
    UserActivityService.prototype.subscribeToThrottle = function () {
        var _this = this;
        this.throttle.reset();
        this.throttleSubscrtiption = this.throttle.subscribeToModeChange(function (e) {
            // if we coming back to front from background tab intervals possible where paused and we should check real time elapsed
            if (e.mode === TabMode.FRONT) {
                var totalTimeToLogin = _this.thresholdSeconds + _this.timeoutSeconds;
                if (e.timeFromChangeMode > totalTimeToLogin * 1000 && !_this.m_activityTimeoutReached) {
                    _this.stop();
                    _this.m_activityTimeoutReached = true;
                    _this.onNeedToHideIncativityDialog.next();
                    _this.onInactivityTimedOut.next();
                }
            }
        });
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserActivityService.prototype, "onNavigationStarted", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserActivityService.prototype, "throttleSubscrtiption", void 0);
    return UserActivityService;
}());
export { UserActivityService };
var serviceState;
(function (serviceState) {
    serviceState[serviceState["initial"] = 0] = "initial";
    serviceState[serviceState["started"] = 1] = "started";
    serviceState[serviceState["stoped"] = 2] = "stoped";
    serviceState[serviceState["starting"] = 3] = "starting";
    serviceState[serviceState["failed"] = -1] = "failed";
})(serviceState || (serviceState = {}));
