import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export interface IPayCycle {
  startDate: Date;
  endDate: Date;
  isApproved: boolean;
}

export class PayCycle {
  public startDate: Date;
  public endDate: Date;
  public isApproved: boolean;
  public get description(): string {
    return `${moment(this.startDate).format(appConfig.dateFormat)} - ${moment(this.endDate).format(appConfig.dateFormat)}`;
  }
}
