/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./favorites-configuration-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./favorites-configuration/favorites-configuration.component.ngfactory";
import * as i3 from "./favorites-configuration/favorites-configuration.component";
import * as i4 from "./favorites-configuration-dialog.component";
import * as i5 from "../../../common/models/dialog-options";
import * as i6 from "../../../common/services/modal/modal.service";
var styles_FavoritesConfigurationDialogComponent = [i0.styles];
var RenderType_FavoritesConfigurationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FavoritesConfigurationDialogComponent, data: {} });
export { RenderType_FavoritesConfigurationDialogComponent as RenderType_FavoritesConfigurationDialogComponent };
export function View_FavoritesConfigurationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-favorites-configuration", [], null, null, null, i2.View_FavoritesConfigurationComponent_0, i2.RenderType_FavoritesConfigurationComponent)), i1.ɵdid(2, 49152, null, 0, i3.FavoritesConfigurationComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], null, null); }
export function View_FavoritesConfigurationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-favorites-configuration-dialog", [], null, null, null, View_FavoritesConfigurationDialogComponent_0, RenderType_FavoritesConfigurationDialogComponent)), i1.ɵdid(1, 49152, null, 0, i4.FavoritesConfigurationDialogComponent, [i5.DialogOptions2, i6.ModalService], null, null)], null, null); }
var FavoritesConfigurationDialogComponentNgFactory = i1.ɵccf("slx-favorites-configuration-dialog", i4.FavoritesConfigurationDialogComponent, View_FavoritesConfigurationDialogComponent_Host_0, {}, {}, []);
export { FavoritesConfigurationDialogComponentNgFactory as FavoritesConfigurationDialogComponentNgFactory };
