import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ApiUtilService } from '../../../common/index';
import { UrlParamsService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ResponseBody } from '../../../core/models/api/response-body';

import { RoleDefinition, IRoleDefinition } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { IServerValidationResult } from '../../../common/validators/common-validators-models';

import { configurationConfig } from '../../configuration.config';

import { RolesApiService } from '../roles/roles-api.service';
import { RolesMapService } from '../roles/roles-map.service';

import { UserProfileMapService } from './user-profile-map.service';
import { UserProfileModel, UserRoleRelationModel, IUserProfileDTO, IUserRolerelationDTO, IUserFieldValidationResult, UserValidationResult } from '../../models/index';

@Injectable()
export class UserProfileApiService {

    constructor(
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: UserProfileMapService,
        private orgLevelWatchService: OrgLevelWatchService,
        private rolesApi: RolesApiService,
        private rolesMap: RolesMapService) {
    }

    public getRoles(): Promise<RoleDefinition[]> {

        return new Promise((resolve, reject) => {
            this.rolesApi.getRoles().then((roles: RoleDefinition[]) => {
                resolve(roles);
            }).catch((result: any) => {
                reject();
            });
        });
    }

    public getUserProfile(userId: number): Promise<UserProfileModel> {

        const url: string = `${this.getUsersApiRoot()}/${userId}`;

        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {});

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IUserProfileDTO, Meta>) => {
            return this.mapService.mapUserProfile(response.data);
        });

    }

    public getUserRoles(userId: number): Promise<UserRoleRelationModel[]> {

        const url: string = `${this.getUsersApiRoot()}/${userId}/${configurationConfig.api.configuration.users.userRoles}`;

        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {});

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IUserRolerelationDTO[], Meta>) => {
            return this.mapService.mapUserRoleRelations(response.data);
        });
    }

    public createUser(user: UserProfileModel, roles: UserRoleRelationModel[]): Promise<UserProfileModel> {

        const url: string = `${this.getUsersApiRoot()}`;

        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, {
            roles: this.mapService.mapUserRoleRelationsDTO(roles),
            userProfile: this.mapService.mapUserProfileDTO(user)
        });

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IUserProfileDTO, Meta>) => {
            return this.mapService.mapUserProfile(response.data);
        });
    }

    public saveUserProfile(user: UserProfileModel): Promise<UserProfileModel> {

        const url: string = `${this.getUsersApiRoot()}/${user.id}`;
        let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, this.mapService.mapUserProfileDTO(user));

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IUserProfileDTO, Meta>) => {
            return this.mapService.mapUserProfile(response.data);
        });
    }

    public saveUserRoles(userId: number, roles: UserRoleRelationModel[]): Promise<UserRoleRelationModel[]> {

        const url: string = `${this.getUsersApiRoot()}/${userId}/${configurationConfig.api.configuration.users.userRoles}`;
        let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url, this.mapService.mapUserRoleRelationsDTO(roles));

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IUserRolerelationDTO[], Meta>) => {
            return this.mapService.mapUserRoleRelations(response.data);
        });
    }

    public activateUser(userId: number): Promise<any> {
        const url: string = `${this.getUsersApiRoot()}/${userId}/${configurationConfig.api.configuration.users.changeDeleteState}`;
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, undefined, { isDeleted: false });
        return this.apiUtilService.request<any, Meta>(request);
    }

    public disableUser(userId: number): Promise<any> {

        const url: string = `${this.getUsersApiRoot()}/${userId}/${configurationConfig.api.configuration.users.changeDeleteState}`;
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, undefined, { isDeleted: true });
        return this.apiUtilService.request<any, Meta>(request);
    }

    public validateUserName(username: string): Promise<UserValidationResult> {
        username = encodeURIComponent(username);
        const url: string = `${this.getUsersApiRoot()}/${configurationConfig.api.configuration.users.validate.root}/${configurationConfig.api.configuration.users.validate.username}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
            username:username
        });
        
        return this.apiUtilService.request<IUserFieldValidationResult, Meta>(request)
            .then((response: ResponseBody<IUserFieldValidationResult, Meta>) => {
                return this.mapService.mapUserValidation(response.data);
            });
    }

    private getUsersApiRoot(): string {
        return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.users.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }
}
