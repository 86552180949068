import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { ManagementBaseService, SessionService } from '../../../../core/services/index';
import { ModalService, ChangeManagementService, EmployeeSectionNavigationService, LmNavigationService, FileService } from '../../../../common/services/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { WCReportApiService } from './wc-report-api.service';
import { WcEmployeeInfo, WcReport, WcInjuryReport, WcLookupsContainer, AttachmentDefinition, WcOSHAReport, WCCompensationReport, ReportCommonFields } from '../../models';
import { Assert } from '../../../../framework';
import { Subject } from 'rxjs/Subject';
import { LookupService } from '../../../../organization/services';
import { LookupType, Employee, Lookup, UserProfileDefinition, ReadFile } from '../../../../organization/models';
import { WCReportMapService } from './wc-report-map.service';
import { LoaActionsDropDownItem } from '../../../../common/models';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NotificationsService } from '../../../../core/components';
var WCReportManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(WCReportManagementService, _super);
    function WCReportManagementService(api, mapService, lookupService, modalService, changeService, sessionService, router, route, notificationService, fileService) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.mapService = mapService;
        _this.lookupService = lookupService;
        _this.modalService = modalService;
        _this.changeService = changeService;
        _this.sessionService = sessionService;
        _this.router = router;
        _this.route = route;
        _this.notificationService = notificationService;
        _this.fileService = fileService;
        _this.orgLevelChanged$ = new ReplaySubject(1);
        _this.employeeInfoLoaded$ = new ReplaySubject(1);
        _this.wcLookupsLoaded$ = new ReplaySubject(1);
        _this.onFileChanged$ = new ReplaySubject(1);
        _this.closePopup$ = new Subject();
        _this.onError$ = new Subject();
        _this.onValidityChanged$ = new Subject();
        _this.incidentDate$ = new ReplaySubject(1);
        _this.changeGroupName = 'wc-incident-report-form';
        _this.changeManagementComponentId = 'wc-incident-report';
        _this.wcValid = true;
        _this.oshaValid = true;
        _this.injuryValid = true;
        _this.mainControlsValid = true;
        _this.ignoreChanges = false;
        _this.incidentDate = null;
        _this.init();
        return _this;
    }
    WCReportManagementService.prototype.destroy = function () {
        this.incidentDate$.complete();
    };
    WCReportManagementService.prototype.init = function () {
        var _this = this;
        this.changeService.setCurrentComponentId(this.changeManagementComponentId);
        this.report = this.createWcReportModel();
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                    return [2 /*return*/];
                this.orgLevel = orgLevel;
                this.orgLevelChanged$.next(orgLevel);
                this.loadLookups();
                return [2 /*return*/];
            });
        }); });
    };
    WCReportManagementService.prototype.subscribeToOrgLevelChange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    WCReportManagementService.prototype.subscribeToReportLoad = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onLoaded$.subscribe(callback);
    };
    WCReportManagementService.prototype.subscribeToFileChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onFileChanged$.subscribe(callback);
    };
    WCReportManagementService.prototype.subscribeToClosePopup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.closePopup$.subscribe(callback);
    };
    WCReportManagementService.prototype.subscribeToError = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onError$.subscribe(callback);
    };
    WCReportManagementService.prototype.subscribeToLookupsLoad = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.wcLookupsLoaded$.subscribe(callback);
    };
    WCReportManagementService.prototype.subscribeToSetIncidentDate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.incidentDate$.subscribe(callback);
    };
    WCReportManagementService.prototype.onValidityChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onValidityChanged$.subscribe(callback);
    };
    WCReportManagementService.prototype.onFileChangeNotify = function (sendChangeAlso) {
        this.onFileChanged$.next();
        if (sendChangeAlso) {
            this.onChangeNotify();
        }
    };
    WCReportManagementService.prototype.closePopup = function (afterActionSaved) {
        if (afterActionSaved === void 0) { afterActionSaved = true; }
        this.ignoreChanges = true;
        this.changeService.clearChanges(this.changeGroupName);
        this.changeService.clearCurrentComponentId();
        this.closePopup$.next(afterActionSaved);
    };
    WCReportManagementService.prototype.changeLoading = function (isLoading) {
        this.onLoadStatusChanged(isLoading);
    };
    WCReportManagementService.prototype.setMainFormValidity = function (valid) {
        this.mainControlsValid = valid;
        this.updateValidity();
    };
    WCReportManagementService.prototype.setWcFormValidity = function (valid) {
        this.wcValid = valid;
        this.updateValidity();
    };
    WCReportManagementService.prototype.setInjuryFormValidity = function (valid) {
        this.injuryValid = valid;
        this.updateValidity();
    };
    WCReportManagementService.prototype.setOshaFormValidity = function (valid) {
        this.oshaValid = valid;
        this.updateValidity();
    };
    WCReportManagementService.prototype.onChangeNotify = function () {
        if (!this.ignoreChanges) {
            this.changeService.changeNotify(this.changeGroupName);
            this.changeNotify$.next(this.changeGroupName);
        }
    };
    WCReportManagementService.prototype.toggleEditMode = function () {
        if (this.report) {
            this.report.editMode = !this.report.editMode;
        }
    };
    WCReportManagementService.prototype.disableOshaReport = function () {
        if (this.report) {
            this.report.injuryReport.isOSHARecordable = false;
        }
        this.oshaValid = true;
        this.updateValidity();
        this.onChangeNotify();
    };
    WCReportManagementService.prototype.disableWCReport = function () {
        if (this.report) {
            this.report.injuryReport.isWorkersCompCase = false;
        }
        this.wcValid = true;
        this.updateValidity();
        this.onChangeNotify();
    };
    WCReportManagementService.prototype.enableOshaReport = function () {
        if (this.report) {
            if (!this.report.oshaReport) {
                var osha = new WcOSHAReport();
                this.report.oshaReport = osha;
            }
            this.report.injuryReport.isOSHARecordable = true;
        }
        this.onChangeNotify();
    };
    WCReportManagementService.prototype.enableWCReport = function () {
        if (this.report) {
            if (!this.report.compReport) {
                var wc = new WCCompensationReport();
                this.report.compReport = wc;
            }
            this.report.injuryReport.isWorkersCompCase = true;
        }
        this.onChangeNotify();
    };
    WCReportManagementService.prototype.setReportId = function (reportId) {
        this.loadReport(reportId);
    };
    WCReportManagementService.prototype.createNewReport = function (empId) {
        var _this = this;
        this.changeLoading(true);
        this.getEmployeeInfo(empId)
            .then(function (e) {
            _this.employeeInfo = e;
            _this.employeeInfoLoaded$.next(_this.employeeInfo);
            var report = _this.createWcReportModel();
            report.employee = _this.employeeInfo;
            _this.report = report;
            _this.onLoaded$.next(_this.report);
            _this.changeLoading(false);
            _this.changeService.clearChanges(_this.changeGroupName);
        });
    };
    WCReportManagementService.prototype.reloadReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isFinite(this.report.id)) {
                    this.loadReport(this.report.id);
                }
                return [2 /*return*/];
            });
        });
    };
    WCReportManagementService.prototype.loadReport = function (reportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var report, info, err_1, errorMessage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.changeLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, this.api.getReport(reportId)];
                    case 2:
                        report = _a.sent();
                        this.report = report;
                        this.employeeInfo = report.employee;
                        if (!(this.report.injuryReport.isWitnessEmployee && _.isFinite(this.report.injuryReport.witnessEmployee))) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getEmployeeInfo(this.report.injuryReport.witnessEmployee)];
                    case 3:
                        info = _a.sent();
                        this.report.injuryReport.witnessEmployeeObj = this.getLookupEmployeeFromWcEmpInfo(info);
                        _a.label = 4;
                    case 4:
                        this.employeeInfoLoaded$.next(this.employeeInfo);
                        this.onLoaded$.next(this.report);
                        this.changeLoading(false);
                        this.changeService.clearChanges();
                        return [3 /*break*/, 6];
                    case 5:
                        err_1 = _a.sent();
                        errorMessage = err_1 && err_1.message || 'An error has occurred. Try again later';
                        this.onError$.next(errorMessage);
                        this.changeLoading(false);
                        throw err_1;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.saveReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var x, reportWithDocs;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.changeLoading(true);
                        return [4 /*yield*/, this.api.createReport(this.report)];
                    case 1:
                        x = _a.sent();
                        x.employee = this.employeeInfo;
                        return [4 /*yield*/, this.attachSavedFiles(x.id, this.report.filesToSave)];
                    case 2:
                        reportWithDocs = _a.sent();
                        this.report = reportWithDocs || x;
                        this.changeService.clearChanges();
                        this.employeeInfoLoaded$.next(this.employeeInfo);
                        this.onLoaded$.next(this.report);
                        this.changeLoading(false);
                        this.notificationService.success('Report created', 'Incident report was successfully created');
                        this.closePopup();
                        return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.updateReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var x, reportWithDocs;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.changeLoading(true);
                        return [4 /*yield*/, this.api.updateReport(this.report)];
                    case 1:
                        x = _a.sent();
                        x.employee = this.employeeInfo;
                        return [4 /*yield*/, this.attachSavedFiles(this.report.id, this.report.filesToSave)];
                    case 2:
                        reportWithDocs = _a.sent();
                        this.report = reportWithDocs || x;
                        this.changeService.clearChanges();
                        this.employeeInfoLoaded$.next(this.employeeInfo);
                        this.onLoaded$.next(this.report);
                        this.onLoadStatusChanged(false);
                        this.notificationService.success('Report updated', 'Incident report was successfully updated');
                        this.closePopup();
                        return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.deleteReport = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2, errorMessage;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        this.changeLoading(true);
                        return [4 /*yield*/, this.api.deleteReport(this.report.id)];
                    case 1:
                        _a.sent();
                        this.report = null;
                        this.employeeInfo = null;
                        this.changeService.clearChanges();
                        this.changeLoading(false);
                        this.closePopup();
                        this.notificationService.success('Report deleted', 'Incident report was successfully deleted');
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        errorMessage = err_2 && err_2.message || 'An error has occurred. Try again later';
                        this.onError$.next(errorMessage);
                        this.changeLoading(false);
                        this.notificationService.error('Error', 'Incident report was not deleted. Try again later');
                        throw err_2;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.onCancel = function () {
        var _this = this;
        this.changeService.canMoveForward(this.changeManagementComponentId).then(function (canMove) {
            if (!canMove) {
                return;
            }
            _this.closePopup(false);
        });
    };
    WCReportManagementService.prototype.getEmployeeInfo = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var info;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.getWcEmployeeInfo(id)];
                    case 1:
                        info = _a.sent();
                        return [2 /*return*/, info];
                }
            });
        });
    };
    WCReportManagementService.prototype.setWitnessEmployee = function (empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var info, fieldInfo;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.changeLoading(true);
                        this.report.injuryReport.witnessEmployee = empId;
                        return [4 /*yield*/, this.getEmployeeInfo(empId)];
                    case 1:
                        info = _a.sent();
                        fieldInfo = _.find(info.metaMap, function (f) { return f.fieldName === 'empPhone'; });
                        if (fieldInfo.access === 'full') {
                            this.report.injuryReport.witnessPhone = info.employee.phoneNumber;
                        }
                        else {
                            fieldInfo = _.find(info.metaMap, function (f) { return f.fieldName === 'cellPhoneNo'; });
                            if (fieldInfo.access === 'full') {
                                this.report.injuryReport.witnessPhone = info.employee.mobilePhoneNumber;
                            }
                        }
                        this.changeLoading(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.getLookupEmployeeFromWcEmpInfo = function (info) {
        var employee = this.mapService.mapWcEmployeeInfoToEmployeeLookup(info);
        return employee;
    };
    WCReportManagementService.prototype.loadEmployees = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id, isActive: true })];
            });
        });
    };
    WCReportManagementService.prototype.loadStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.lookupService.getLookup({ lookupType: LookupType.state })];
            });
        });
    };
    WCReportManagementService.prototype.readAddedFiles = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promises, readedFiles, binaryData, err_3;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = _.map(files, function (f) { return _this.readFileData(f); });
                        readedFiles = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        binaryData = _a.sent();
                        readedFiles = this.mapDateToReadFiles(files, binaryData);
                        return [3 /*break*/, 5];
                    case 3:
                        err_3 = _a.sent();
                        console.error(err_3);
                        return [3 /*break*/, 5];
                    case 4: return [2 /*return*/, readedFiles];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.deleteAttachment = function (attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.api.deleteDocument(this.report.id, [attachmentId])];
                    case 1:
                        _a.sent();
                        this.report.attachments = _.filter(this.report.attachments, function (a) { return a.id !== attachmentId; });
                        return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.setAttachments = function (attachments) {
        if (_.isArray(attachments)) {
            this.report.attachments = _.slice(attachments, 0);
        }
    };
    WCReportManagementService.prototype.getAttachments = function () {
        if (_.size(this.report.attachments) > 0) {
            return _.slice(this.report.attachments, 0);
        }
        return [];
    };
    WCReportManagementService.prototype.addFilesToSave = function (files) {
        if (_.isArray(files)) {
            this.report.filesToSave = _.concat(files, this.report.filesToSave || []);
        }
    };
    WCReportManagementService.prototype.deleteFileToSave = function (file) {
        if (_.isObjectLike(file)) {
            this.report.filesToSave = _.filter(this.report.filesToSave, function (f) { return f.fileName !== file.fileName; });
        }
    };
    WCReportManagementService.prototype.getFilesToSave = function () {
        if (_.isArray(this.report.filesToSave)) {
            return _.slice(this.report.filesToSave, 0);
        }
        return [];
    };
    WCReportManagementService.prototype.navigateToUserProfile = function (id) {
        var _this = this;
        this.changeService.canMoveForward(this.changeManagementComponentId).then(function (canMove) {
            if (!canMove) {
                return;
            }
            var navService = new EmployeeSectionNavigationService(_this.router, _this.route);
            var urlTree = navService.getUrlTreeFromRoot(id, true);
            var extras = {
                skipLocationChange: false,
                replaceUrl: false,
            };
            _this.router.navigateByUrl(urlTree, extras);
            _this.closePopup(false);
        });
    };
    WCReportManagementService.prototype.navigateToLeaveManagementWithAction = function (action, empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.changeService.canMoveForward(this.changeManagementComponentId).then(function (canMove) {
                    if (!canMove) {
                        return;
                    }
                    var navService = new LmNavigationService(_this.router, _this.route);
                    navService.navigateToLmRosterWithAction(_this.orgLevel.id, action.id, empId);
                    _this.closePopup(false);
                });
                return [2 /*return*/];
            });
        });
    };
    WCReportManagementService.prototype.getUserName = function () {
        var user = this.sessionService.getUser();
        return user.username;
    };
    WCReportManagementService.prototype.downloadAttachment = function (attachmentId) {
        var _this = this;
        this.changeLoading(true);
        try {
            var promise = this.api.downloadAttachment(attachmentId)
                .then(function (file) {
                return _this.fileService.saveToFileSystem(file.blob, file.file);
            });
            return promise;
        }
        catch (e) {
            console.error(e);
        }
        finally {
            this.changeLoading(false);
        }
    };
    WCReportManagementService.prototype.attachSavedFiles = function (reportId, filesToSave) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var report;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(_.size(filesToSave) > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.api.createDocument(reportId, filesToSave)];
                    case 1:
                        report = _a.sent();
                        return [2 /*return*/, report];
                    case 2: return [2 /*return*/, null];
                }
            });
        });
    };
    WCReportManagementService.prototype.createWcReportModel = function () {
        var report = new WcReport();
        report.editMode = true;
        report.incidentDateTime = null;
        report.reportDateTime = null;
        report.injuryReport = new WcInjuryReport();
        report.injuryReport.isOSHARecordable = false;
        report.injuryReport.isWorkersCompCase = false;
        var profile = new UserProfileDefinition();
        var user = this.sessionService.getUser();
        profile.id = user.id;
        profile.name = user.username;
        profile.login = user.name;
        profile.email = user.email;
        report.injuryReport.user = profile;
        report.common = new ReportCommonFields();
        return report;
    };
    WCReportManagementService.prototype.loadLookups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var statuses, treatments, compCases, injuryTypes, oshaInjuryTypes, equipmentTypes, locations, bodyParts, bodySide, causes, occ, illnesses, results, results2, wcLookups;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        statuses = this.api.getReportStatuses();
                        treatments = this.api.getTreatments();
                        compCases = this.api.getWcCompCases();
                        injuryTypes = this.api.getWcCodes('injuryType');
                        oshaInjuryTypes = this.api.getWcCodes('oSHAInjuryType');
                        equipmentTypes = this.api.getWcCodes('equipment');
                        locations = this.api.getWcCodes('location');
                        bodyParts = this.api.getWcCodes('bodyPart2');
                        bodySide = this.api.getWcCodes('body');
                        causes = this.api.getWcCodes('cause');
                        occ = this.api.getWcCodes('occurrence');
                        illnesses = this.api.getWcCodes('illness');
                        return [4 /*yield*/, Promise.all([
                                injuryTypes,
                                oshaInjuryTypes,
                                locations,
                                equipmentTypes,
                                bodyParts,
                                bodySide,
                                causes,
                                occ,
                                illnesses,
                            ])];
                    case 1:
                        results = _a.sent();
                        return [4 /*yield*/, Promise.all([
                                statuses,
                                treatments,
                                compCases
                            ])];
                    case 2:
                        results2 = _a.sent();
                        wcLookups = new WcLookupsContainer();
                        wcLookups.reportStatuses = results2[0];
                        wcLookups.medicalTreatments = results2[1];
                        wcLookups.compCases = results2[2];
                        wcLookups.injuryTypes = results[0];
                        wcLookups.oshaInjuryTypes = results[1];
                        wcLookups.incidentLocations = results[2];
                        wcLookups.equipmentTypes = results[3];
                        wcLookups.bodyParts = results[4];
                        wcLookups.bodySides = results[5];
                        wcLookups.causes = results[6];
                        wcLookups.occurences = results[7];
                        wcLookups.illnesses = results[8];
                        this.wcLookups = wcLookups;
                        this.wcLookupsLoaded$.next(this.wcLookups.deepClone());
                        return [2 /*return*/];
                }
            });
        });
    };
    WCReportManagementService.prototype.mapDateToReadFiles = function (files, binaryData) {
        return _.map(files, function (file, i) {
            var index = file.name.lastIndexOf('.');
            var name = file.name.slice(0, index);
            var ext = file.name.slice(index + 1);
            var data = new Blob([binaryData[i]]);
            return new ReadFile(name, file.size, file.type, ext, data);
        });
    };
    WCReportManagementService.prototype.readFileData = function (file) {
        return new Promise(function (resolve, reject) {
            var fr = new FileReader();
            fr.onloadend = function () {
                var buffer = fr.result;
                resolve(buffer);
            };
            fr.onerror = function () {
                reject(fr.error);
            };
            fr.readAsArrayBuffer(file);
        });
    };
    WCReportManagementService.prototype.updateValidity = function () {
        this.onValidityChanged$.next(this.wcValid && this.injuryValid && this.oshaValid && this.mainControlsValid);
    };
    WCReportManagementService.prototype.setIncidentDateTime = function (iDate) {
        this.incidentDate = iDate;
        this.incidentDate$.next(iDate);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], WCReportManagementService.prototype, "orgLevel$", void 0);
    return WCReportManagementService;
}(ManagementBaseService));
export { WCReportManagementService };
