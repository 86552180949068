import * as _ from 'lodash';
import {
  IDepartment,
  Department,
  IPosition,
  Position,
  IOrganization,
  Organization,
  LoaAttachment,
  ILoaAttachment,
  ReadFile
} from '../../../organization/models/index';
import { DateRange, IDateRangeStr, IDateRange } from '../../../core/models/index';
import { ILoaRepeat, LoaRepeat } from './loa-repeat';
import { ILoaType, LoaType } from './loa-type';
import { LoaRequestClass, LoaCategory } from '../../../common/models';
import { RecurrenceFrequencies } from './frequency';


export interface ILoaRequestEmployee {
  id: number;
  name: string;
}

export class LoaRequestEmployee {
  constructor(public id: number, public name: string) {}
}


export interface ILoaRequest {
  id: number;
  employee: ILoaRequestEmployee;
  department: IDepartment;
  position: IPosition;
  organization: IOrganization;
  requestClass: LoaRequestClass;
  type: ILoaType;
  requestDate: string;
  estStartDate: string;
  estEndDate: string;
  actStartDate?: string;
  actEndDate?: string;
  reason: string;
  notes: string;
  isDeleted: boolean;
  attachments: ILoaAttachment[];
  exceptionDates: IDateRangeStr[];
  addedDate?: string;
  addedBy: string;
  updatedDate?: string;
  updatedBy: string;
  loaRepeat: ILoaRepeat;
  loaCategories: LoaCategory[];
  daysOnLeave: number;
  daysRemaining: number;
  daysOnLoa: number;
}


export class LoaRequest {
  public id: number;
  public employee: LoaRequestEmployee;
  public department: Department;
  public position: Position;
  public organization: Organization;
  public requestClass: LoaRequestClass;
  public type: LoaType;
  public requestDate: Date;
  public estStartDate: Date;
  public estEndDate: Date;
  public actStartDate?: Date;
  public actEndDate?: Date;
  public reason: string;
  public notes: string;
  public isDeleted: boolean;
  public attachments: LoaAttachment[];
  public exceptionDates: DateRange[];
  public addedDate?: Date;
  public addedBy: string;
  public updatedDate?: Date;
  public updatedBy: string;
  public loaRepeat: LoaRepeat;
  public loaCategories: LoaCategory[];
  public daysOnLeave: number;
  public daysRemaining: number;
  public daysOnLoa: number;

  public filesToSave: ReadFile[];
  public estAllDayCheckbox: boolean;
  public actAllDayCheckbox: boolean;
  public absencePeriod: IDateRange;
  public loaRequestTabs: LoaRequestTabs;

  public daysOnLeaveText?: number;
  public daysRemainingText?: number;

  public daysOnLoaText?: string;

  constructor () {
    this.loaRepeat = new LoaRepeat();
    this.filesToSave = [];
    this.estAllDayCheckbox = false;
    this.actAllDayCheckbox = false;
    this.loaRequestTabs = LoaRequestTabs.Dates;
    
  }

  public get attachmentsLength(): number {
    if (!this.attachments || !isFinite(this.attachments.length)) return 0;

    return this.attachments.length;
  }

  public get isContinuous(): any {
    return (LoaRequestClass[this.requestClass] as any) === LoaRequestClass.Continuous;
  }

  public get isIntermittent(): boolean {
    return (LoaRequestClass[this.requestClass] as any) === LoaRequestClass.Intermittent;
  }

  public get isCustom(): boolean {
    return this.isIntermittent
      && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.custom));
  }

  public get isDaily(): boolean {
    return this.isIntermittent
      && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.daily));
  }

  public get isWeekly(): boolean {
    return this.isIntermittent
      && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.weekly));
  }

  public get isMonthly(): boolean {
    return this.isIntermittent
      && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.monthly));
  }

  public get typeColor(): string {
    return this.type && this.type.color || '';
  }

  public get typeName(): string {
    return this.type && this.type.name || '';
  }

  public get isNegativeDaysBalance(): boolean {
    return this.daysRemaining < 0;
  }

  public get positionName(): string {
    return this.position && this.position.name;
  }

  public get departmentName(): string {
    return this.department && this.department.name;
  }

  public get organizationName(): string {
    return this.organization && this.organization.name;
  }

  public get empId(): number {
    return this.employee && this.employee.id;
  }

  public get empName(): string {
    return this.employee && this.employee.name;
  }

  public get readyToSave(): boolean {
    const continuousIsCorrect = this.isContinuous && this.hasCorrectContinuousDates;
    const intermittentRepeatableIsCorrect = (this.isDaily || this.isWeekly || this.isMonthly) && this.hasCorrectRepeatableDates;
    const intermittentCustomIsCorrect = this.isCustom && this.hasCorrectCustomDates;
    return (continuousIsCorrect || intermittentCustomIsCorrect || intermittentRepeatableIsCorrect)
      && _.isObjectLike(this.type)
      && _.size(this.reason) > 0;
  }

  public get hasCorrectContinuousDates(): boolean {
    let sDate = this.estStartDate;
    let eDate = this.estEndDate;
    if (_.isDate(this.actStartDate)) {
      sDate = this.actStartDate;
    }
    if (_.isDate(this.actEndDate)) {
      eDate = this.actEndDate;
    }
    return _.isDate(this.estStartDate) && _.isDate(this.estEndDate) && eDate.getTime() > sDate.getTime();
  }

  public get hasCorrectRepeatableDates(): boolean {
    return _.isDate(this.estStartDate) && _.isDate(this.estEndDate) && this.estEndDate.getTime() >= this.estStartDate.getTime();
  }

  public get hasCorrectCustomDates(): boolean {
    return _.isDate(this.estStartDate) && _.isDate(this.estEndDate) && this.loaRepeat.customDates.length > 0 && this.estEndDate.getTime() >= this.estStartDate.getTime();
  }
}

export class LoaMappedRequest {
  constructor(public loaRequest: LoaRequest, public absenceDays: StringMap<IDateRange[]>) {}
}

export interface ILoaRequestContainer {
  requests: ILoaRequest[];
  start: string;
  end: string;
}

export class LoaRequestContainer {
  requests: LoaRequest[];
  start: Date;
  end: Date;
}

export enum LoaRequestTabs {
  Dates = 'Dates',
  Notes = 'Notes',
  Attachments = 'Attachments',
  Exceptions = 'Exceptions',
}

