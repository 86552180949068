import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { Observable, Subject } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { mutableSelect, unsubscribeAll, unsubscribeInService } from '../../../../app/core/decorators';
import { EmployeePunchRequestApiService } from './employee-punch-request-api.service';
import { DateRange } from '../../../core/models/index';
import { KendoGridStateHelper } from './../../../../app/common';
import { Assert } from './../../../../app/framework';
import { process } from '@progress/kendo-data-query';
var EmployeePunchRequestManagementService = /** @class */ (function () {
    function EmployeePunchRequestManagementService(apiService) {
        this.apiService = apiService;
        this.dateRange$ = new Subject();
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.skip = 0;
        this.pageSize = 15;
        this.subscriptions = {};
        this.sort = [];
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.skip = 0;
        this.gridState.state.take = this.pageSize;
    }
    EmployeePunchRequestManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (_this.orgLevelId != orgLevel.id) {
                _this.orgLevelId = orgLevel.id;
                if (!_this.dateRange) {
                    _this.dateRange = _this.getDefaultDateRange();
                }
                else {
                    _this.loadEmployeePunchRequestData();
                }
            }
            ;
        });
    };
    EmployeePunchRequestManagementService.prototype.destroy = function () {
        this.dateRange$.complete();
        this.loading$.complete();
        this.recordsLoaded$.complete();
    };
    EmployeePunchRequestManagementService.prototype.getDefaultDateRange = function () {
        var d = new Date();
        return new DateRange(new Date(d.getFullYear() - 1, d.getMonth(), d.getDate()), d);
    };
    EmployeePunchRequestManagementService.prototype.changeDateRange = function (r) {
        this.dateRange = r;
        this.dateRange$.next(r);
        this.loadEmployeePunchRequestData();
    };
    EmployeePunchRequestManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    EmployeePunchRequestManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    EmployeePunchRequestManagementService.prototype.loadEmployeePunchRequestData = function () {
        var _this = this;
        this.isLoading = true;
        this.loading$.next(true);
        this.apiService.getEmployeePunchRequest(this.orgLevelId, this.dateRange.startDate, this.dateRange.endDate).then(function (value) {
            _this.recordsLoaded$.next(value);
            _this.list = value;
            _this.refreshGrid();
            _this.isLoading = false;
            _this.loading$.next(false);
        }).finally(function () {
            _this.isLoading = false;
            _this.loading$.next(false);
        });
    };
    EmployeePunchRequestManagementService.prototype.setSort = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeePunchRequestManagementService.prototype.pageChange = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    EmployeePunchRequestManagementService.prototype.filterChange = function (event) {
        this.gridState.state.filter = event;
        this.refreshGrid();
    };
    EmployeePunchRequestManagementService.prototype.refreshGrid = function () {
        if (!this.list) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.list, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeePunchRequestManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeePunchRequestManagementService.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], EmployeePunchRequestManagementService.prototype, "subscriptions", void 0);
    return EmployeePunchRequestManagementService;
}());
export { EmployeePunchRequestManagementService };
