
export interface IShiftOpenCmd {
  dateOn: Date;
  unitId: number;
  positionId: number;
  employeeIds: number[];
}
export class ShiftOpenCmd {
  public shiftId: number;

  public dateOn: Date;
  public unitId: number;
  public positionId: number;
  public employeeIds: number[];
}
