import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Lookup, Employee } from '../../../../organization/models/index';
import { VirtualizationSettings } from '@progress/kendo-angular-dropdowns';
import { screenUtils } from '../../../../common/utils/index';
import { EmployeeSearchBase } from '../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribeAll } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-search-field',
  templateUrl: 'employee-search-field.component.html',
  styleUrls: ['employee-search-field.component.scss'],
})

export class EmployeeSearchFieldComponent implements OnInit, OnDestroy {
  @unsubscribeAll()
  private subscription: StringMap<Subscription> = {};

  @Input()
  public virtual: VirtualizationSettings = {
    itemHeight: 55
  };

  @Input()
  public instructions: string;

  @Input()
  public set orgLevelId(value: number) {
    if (value !== this.m_orglevelId) {
      this.m_orglevelId = value;
      this.employeesLookup = null;
      this.employee = null;
      if (this.m_orglevelId) {
        this.loadLookup();
      }
    }
  }

  @Input('empIdsToShow')
  public set employeeIds(value: number[]) {
    if (value && value.length > 0 && value.sort().toString() !== this.m_employeeIds.sort().toString()) {
      this.m_employeeIds = value;
      this.employee = null;
      this.filterEmployeeByIds(this.employeesLookup);
    }
  }

  @Input()
  public isShownEmpNameLabel: boolean = true;

  @Input()
  public set isActive(value: boolean) {
    if (_.isBoolean(value) && this.m_isActive !== value) {
      this.m_isActive = value;
      this.employeesLookup = null;
      this.employee = null;
      if (this.m_orglevelId && this.m_isInit) {
        this.loadLookup();
      }
    }
  }

  @Input()
  public readonly: boolean = false;

  @Input()
  public hideDetails: boolean = false;

  @Output()
  public loadStatusChange: EventEmitter<boolean>;

  @Output()
  public employeeSelected: EventEmitter<Employee>;

  public employeesLookup: Lookup;
  public employee: Employee;
  private m_orglevelId: number;
  private m_employeeIds: number[] = [];
  private m_isActive: boolean = true;
  private m_isInit: boolean = false;

  constructor(private employeeSearchService: EmployeeSearchBase) {
    this.loadStatusChange = new EventEmitter<boolean>();
    this.employeeSelected = new EventEmitter<Employee>();
    this.employeesLookup = this.createLookup([], '', '');
  }

  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  public ngOnInit(): void {
    this.m_isInit = true;
    this.subscription.employees = this.employeeSearchService.subscribeToEmployees((employeesLookup: Lookup) => {
      this.employeesLookup = employeesLookup;
      this.filterEmployeeByIds(this.employeesLookup);
      this.loadStatusChange.emit(false);
    });

    if (this.m_orglevelId) {
      this.loadLookup();
    }
  }

  public loadLookup() {
    if (!this.m_isInit) return;
    this.loadStatusChange.emit(true);

    this.employeeSearchService.getEmployees(this.m_orglevelId, this.m_isActive);
  }

  public ngOnDestroy(): void {
    // Must be, see #issueWithAOTCompiler
  }

  public onEmployeeChanged(emp: Employee): void {
    this.employee = emp;
    this.employeeSelected.emit(emp);
  }

  private createLookup(items: any[], titleField: string, valueField: string): Lookup {
    let lookup: Lookup = new Lookup();
    lookup.titleField = titleField;
    lookup.valueField = valueField;
    lookup.items = items;
    return lookup;
  }

  private filterEmployeeByIds(employeesLookup) {
    if (employeesLookup && employeesLookup.items && this.m_employeeIds.length > 0) {
      employeesLookup.items = employeesLookup.items.filter(employee => this.m_employeeIds.includes(employee.id))
    }
  }
}
