var BenefitClass = /** @class */ (function () {
    function BenefitClass() {
    }
    Object.defineProperty(BenefitClass.prototype, "canEdit", {
        get: function () {
            return this.orgLevelDirectionForEdit === 0;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitClass;
}());
export { BenefitClass };
