import { WeatherLayout, WeatherSettings, TemperatureScale } from './../../weather.interfaces';
import { OnDestroy, Renderer2, ChangeDetectorRef, ElementRef } from '@angular/core';
import { WeatherApiService } from '../../services/api/weather.api.service';
var WeatherContainerComponent = /** @class */ (function () {
    function WeatherContainerComponent(weatherApi, changeDetectorRef, renderer, element) {
        this.weatherApi = weatherApi;
        this.changeDetectorRef = changeDetectorRef;
        this.renderer = renderer;
        this.element = element;
        this.isWideLayout = false;
    }
    Object.defineProperty(WeatherContainerComponent.prototype, "settings", {
        get: function () {
            return this._settings;
        },
        set: function (value) {
            if (!value) {
                return;
            }
            this._settings = value;
            this.getWeather();
            if (this._settings.layout) {
                this.isWideLayout = this._settings.layout === WeatherLayout.WIDE;
            }
            this.scaleLabel = this.getScaleLabel(value.scale);
        },
        enumerable: true,
        configurable: true
    });
    WeatherContainerComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptionCurrentWeather) {
            this.subscriptionCurrentWeather.unsubscribe();
        }
        if (this.subscriptionForecast) {
            this.subscriptionForecast.unsubscribe();
        }
        this.changeDetectorRef.detach();
        this._destroyed = true;
    };
    WeatherContainerComponent.prototype.getWeather = function () {
        var _this = this;
        if (this._destroyed)
            return;
        var params = Object.assign({}, this.settings.location, { units: this.settings.scale }, { lang: this.settings.language });
        this.weatherApi.getWeather(params)
            .then(function (value) {
            if (_this._destroyed)
                return;
            _this.currentWeather = value;
            _this.changeDetectorRef.markForCheck();
            _this.changeDetectorRef.detectChanges();
        }).catch(function (reason) {
            if (_this._destroyed)
                return;
            _this.currentWeather = null;
            _this.changeDetectorRef.markForCheck();
            _this.changeDetectorRef.detectChanges();
        });
    };
    Object.defineProperty(WeatherContainerComponent.prototype, "location", {
        get: function () {
            if (!this.currentWeather)
                return '';
            var p = this.currentWeather.location;
            if (this._settings && this._settings.location && this._settings.location.stateAbbreviation) {
                p = p + ", " + this._settings.location.stateAbbreviation;
            }
            return p;
        },
        enumerable: true,
        configurable: true
    });
    WeatherContainerComponent.prototype.getScaleLabel = function (value) {
        switch (value) {
            case TemperatureScale.CELCIUS:
                return 'C';
            case TemperatureScale.FAHRENHEIT:
                return 'F';
            case TemperatureScale.KELVIN:
                return 'K';
            default:
                return 'F';
        }
    };
    return WeatherContainerComponent;
}());
export { WeatherContainerComponent };
