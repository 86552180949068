import { UserApplication } from '../../state-model/models/index';
import { NavigationMenuItem } from '../models/index';

export interface IApplicationStyleMap {
  id: number;
  icon: string;
  loadCounters: boolean;
  index: number;
}

export class ApplicationDashboardItem {
  public id: number;
  public link: string;
  public title: string;
  public icon: string;
}

export class StyledUserApplication extends UserApplication implements IApplicationStyleMap {
  public icon: string;
  public loadCounters: boolean;
  public isAvailableOnMobile: boolean;
  public index: number;
  public dashboards: ApplicationDashboardItem[];
}

export class DashboardApplications {
  public userId: number;
  public applications: DashboardApplication[];
}

export class DashboardApplication {
  public id: number;
  public appName: string;
  public icon: string;
  public userId: number;
  public appId: number;
  public displayOrder: number;
  public visible: boolean;
  public itemId: number;
  public itemType: number;
}
