import * as moment from 'moment';

import { EmployeeDefinition } from '../../../organization/index';

export class Backup {
  public id: number;
  public username: string;
  public createdAt: moment.Moment;
  public comment: string;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public employee: any;
  public numberOfRecords: number;
  public isFull: boolean;
}
