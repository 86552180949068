/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unassigned-employees-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../unassigned-employees-grid/unassigned-employees-grid.component.ngfactory";
import * as i5 from "../unassigned-employees-grid/unassigned-employees-grid.component";
import * as i6 from "./unassigned-employees-popup.component";
import * as i7 from "../../../../common/models/dialog-options";
import * as i8 from "../../../../common/services/modal/modal.service";
var styles_UnassignedEmployeesPopupComponent = [i0.styles];
var RenderType_UnassignedEmployeesPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnassignedEmployeesPopupComponent, data: {} });
export { RenderType_UnassignedEmployeesPopupComponent as RenderType_UnassignedEmployeesPopupComponent };
export function View_UnassignedEmployeesPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(3, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "slx-unassigned-employees", [["class", "slx-full-height"]], null, null, null, i4.View_UnassignedEmployeesGridComponent_0, i4.RenderType_UnassignedEmployeesGridComponent)), i1.ɵdid(5, 114688, null, 0, i5.UnassignedEmployeesGridComponent, [], { employees: [0, "employees"] }, null), (_l()(), i1.ɵted(-1, null, [">\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.employees; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_UnassignedEmployeesPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-unassigned-employees-popup", [], null, null, null, View_UnassignedEmployeesPopupComponent_0, RenderType_UnassignedEmployeesPopupComponent)), i1.ɵdid(1, 114688, null, 0, i6.UnassignedEmployeesPopupComponent, [i7.DialogOptions, i8.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnassignedEmployeesPopupComponentNgFactory = i1.ɵccf("slx-unassigned-employees-popup", i6.UnassignedEmployeesPopupComponent, View_UnassignedEmployeesPopupComponent_Host_0, { employees: "employees" }, {}, []);
export { UnassignedEmployeesPopupComponentNgFactory as UnassignedEmployeesPopupComponentNgFactory };
