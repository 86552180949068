<div modalAnchor class="slx-local-anchor"></div>
<slx-spinner [show]="state.isLoading" novalidate class="create-ess-policy">
  <div class=" main-container">
    <div class="sections">

        <form #form="ngForm" class="row-form row-item">

          <slx-input-decorator class="element">
              <slx-dropdown-input slx-input
                  [lookup]="{lookupType: 'essPasswordTypes'}"
                  valueField="name" titleField="name" [(ngModel)]="passType"
                  name="passInput"
                  [required]="true" placeholder="Default all passwords to">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
          </slx-input-decorator>

          <slx-input-decorator class="element">
              <slx-dropdown-input slx-input [disabled]="!isGoClient"
                  [lookup]="{lookupType: 'essEnterTimeMethods'}"
                  valueField="name" titleField="description" [(ngModel)]="timeMethod"
                  name="timeMethodInput"
                  [required]="false" placeholder="Time Entry Method">
              </slx-dropdown-input>
              <!-- <span errorMessage for="required"></span> -->
          </slx-input-decorator>

          <slx-input-decorator class="element width-40 auto-height">
              <slx-dropdown-multiselect slx-input [disabled]="!isGoClient"
                  [lookup]="{lookupType: 'essOptions'}"
                  valueField="name" titleField="description" [(ngModel)]="options"
                  name="optionsInput"
                  [required]="isGoClient" placeholder="Options">
              </slx-dropdown-multiselect>
              <span errorMessage for="required"></span>
          </slx-input-decorator>


          <div class="row-item nowrap buttons">
            <button type="button" class="theme-button-apply active margin-r" (click)="createPolicy()" [disabled]="!form.valid || !canSend">Create</button>
            <button type="button" class="theme-button-cancel" (click)="cancel()">Cancel</button>
          </div>
        </form>

      <div class="section">
        <slx-employee-grid [fieldDefinition]="fieldDefinition" [employees]="actionEmployees" [isMultiselect]="true" (onEmployeesSelect)="employeesSelect($event)"></slx-employee-grid>
      </div>
    </div>
</div>
</slx-spinner>
