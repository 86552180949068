import * as _ from 'lodash';
import { Component, OnInit,Input, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { OrgLevel } from '../../../../state-model/models';
import { AbstractControl, NgForm } from '@angular/forms';

import { Aca1095cRecord ,AcaMonthRecord,acaQuickPopupState} from '../../../models/aca-1095-c';
import { AcaOfferOfCoverage,AcaSafeHarbor,AcaReason,IAcaSafeHarbor,IAcaOfferOfCoverage,IAcaReason} from '../../../../organization/models/index';
import { Aca1095cManagementService } from '../../../services/aca-1095-c/aca-1095-c-management.service';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-1095c-quick-edit',
  templateUrl: 'aca-1095c-quick-edit.component.html',
  styleUrls: ['aca-1095c-quick-edit.component.scss']
})
export class QuickEdit1095CPopup implements  OnInit {
  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }
  public currencyFormat = 'c2';
  public minAmt: number = 0.0;
  public maxAmt: number = 99999999.99;
  public stepcurrency = 0.1;
  public items:Aca1095cRecord[]; 
  public expandedItem:Aca1095cRecord;
  public showChanges: boolean = false;
  public isQuickEditStarted: boolean = false;
  public isAllFieldsPopulated: boolean =false;

  public selectedReason: AcaReason;
  public selectedHarbor: AcaSafeHarbor;
  public selectedCoverage: AcaOfferOfCoverage;
  public selectedCost: string;
  public isEnrolled:boolean;
  private quickEditSelectedData:AcaMonthRecord;

  @Input()
  public reasonOptions: AcaReason[];
  @Input()
  public coverageOptions: AcaOfferOfCoverage[]; 
  @Input()
  public harborOptions: AcaSafeHarbor[];  
  @Input()
  public isResetTab: boolean;
  @Input()
  popupInitData:AcaMonthRecord;

  @ViewChild('form', { static: false })
  public ngForm: NgForm;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};


  public get hasChanges(): boolean {

    return this.items && this.items.length > 0;    
  }
  constructor(private acaManagementService: Aca1095cManagementService
    
    ) {
    
  }
  public markChanges():void{
    if(this.items && this.items.length>0)
      {
        this.acaManagementService.markAsDirty();
      }
      else
      {
       this.acaManagementService.clearChanges();
      }   
  }
  public ngOnInit(): void {
    this.subscriptions.onMarkItemForQickEdit=this.acaManagementService.subscribeToQuickEditRecords((items:Aca1095cRecord[])=>{
      this.items=items;  
      this.markChanges();       
    });
    
    if(this.popupInitData)
    {
      this.selectedCoverage=new AcaOfferOfCoverage();
      this.selectedReason=new AcaReason();
      this.selectedHarbor=new AcaSafeHarbor();
      if(this.popupInitData.selectedCode ||this.popupInitData.selectedHarbor ||this.popupInitData.selectedCost)
      {
      this.selectedCoverage.id=this.popupInitData.selectedCode;
      this.selectedCoverage.name=this.popupInitData.selectedCode;

      this.selectedHarbor.id=this.popupInitData.selectedHarbor;
      this.selectedHarbor.name=this.popupInitData.selectedHarbor;

      this.selectedCost=this.popupInitData.selectedCost;
      }
      else
      {
        this.selectedCoverage.id=this.popupInitData.suggestedCode;
      this.selectedCoverage.name=this.popupInitData.suggestedCode;

      this.selectedHarbor.id=this.popupInitData.suggestedHarbor;
      this.selectedHarbor.name=this.popupInitData.suggestedHarbor;

      this.selectedCost=this.popupInitData.suggestedCost;
      }
      this.selectedReason.id=this.popupInitData.reason;
      this.selectedReason.name=this.popupInitData.reason;
      this.isEnrolled=this.popupInitData.enrolledInd;
    }
  }
  public configurationChanged(): void {
    if (this.selectedReason && this.selectedCoverage && this.selectedHarbor && !_.isEmpty(this.selectedCoverage.id))
    {
      this.quickEditSelectedData=new AcaMonthRecord();
      this.isAllFieldsPopulated = true;
      this.quickEditSelectedData.selectedHarbor=this.selectedHarbor.id;
      this.quickEditSelectedData.selectedCost=this.selectedCost;
      this.quickEditSelectedData.selectedCode=this.selectedCoverage.id;
      this.quickEditSelectedData.reason=this.selectedReason.id;
      this.quickEditSelectedData.enrolledInd=this.isEnrolled;
      this.acaManagementService.setQuickEditSelectedData(this.quickEditSelectedData)
      
    } else {
      this.isAllFieldsPopulated = false;
    }   
  }
  public undoEdit(item:Aca1095cRecord,monthItem:AcaMonthRecord): void {
    let undoItem:Aca1095cRecord;
    if(monthItem && item.months.length>1)
    {
      undoItem=_.clone(item);
      undoItem.months=[monthItem];    
     
    }
    else
    {
      undoItem=_.clone(item);

    }    
    if(undoItem)
    {      
      this.acaManagementService.setUndoQuickRecord(undoItem);
    }
  }

  public showChangesClick(): void {
    this.showChanges = true;
  }
  public onChangeEnrolled(event:any):void{
    this.configurationChanged();
  }
  public onChangeContribution():void{
    this.configurationChanged();
  }
  public onChangeHarbor(event:any):void{
    this.configurationChanged();
  }
  public onChangeReason(event:any):void{
    this.configurationChanged();
  }
  public onChangeCoverage(event:any):void{
    this.configurationChanged();
  }
  public hideChangesClick(): void {
    this.showChanges = false;
  }

  public startQuickEdit(): void {
    if(!this.isQuickEditStarted)
    {
    this.isQuickEditStarted = true;
    this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditStart);
    }    
  }
  public saveQuickEdit(): void {
    if(this.isQuickEditStarted)
    {
    this.isQuickEditStarted = false;   
    this.items=[];
    this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickEditSave );
    this.acaManagementService.clearChanges();
    }
  }
  public ngOnDestroy(): void {
    // Must be, see #issueWithAOTCompiler
  }

  public setExpandedItem(item:Aca1095cRecord):void{
    if(_.isEqual(this.expandedItem,item))
    {
      this.expandedItem=null; 
    }
    else
    {
      this.expandedItem=item; 
    }   
  }
}
