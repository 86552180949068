import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { DialogOptions } from '../../../../common/models/dialog-options';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { SupervisorGroupDefinition } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { SupervisorGroup, SupervisorGroupsContainer, Supervisor, SupervisedEmployee, SupervisorAssignmentAction, EligibleSupervisor, UnassignedEmployee } from '../../../models/index';
import { SupervisorAssignmentManagementService, supervisorsGroup, employeesGroup } from '../../../services/supervisor-assignment/supervisor-assignment-management.service';
import { SupervisorAssignmentApiService } from '../../../services/supervisor-assignment/supervisor-assignment-api.service';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { SupervisorGroupGridComponent } from '../supervisor-group-grid/supervisor-group-grid.component';
import { UnassignedEmployeesPopupComponent } from '../unassigned-employees-popup/unassigned-employees-popup.component';

@Component({
  moduleId: module.id,
  selector: 'slx-supervisor-assignment',
  templateUrl: 'supervisor-assignment.component.html',
  styleUrls: ['supervisor-assignment.component.scss'],
  providers: [SupervisorAssignmentManagementService]
})
export class SupervisorAssignmentComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
    hasInnerChanges: boolean;
    groupEditMode: boolean;
    addSupervisorMode: boolean;
    addEmployeeMode: boolean;
    orgLvl: boolean;
  };

  public get isAnyGroupSelected(): boolean {
    return this.selectedGroups && this.selectedGroups.length > 0;
  }
  public get isAnyEligibleSupervisorsSelected(): boolean {
    return this.selectedEligibleSupervisors && this.selectedEligibleSupervisors.length > 0;
  }
  public get isAnyEligibleEmployeesSelected(): boolean {
    return this.selectedEligibleEmployees && this.selectedEligibleEmployees.length > 0;
  }
  public eligibleSupervisors: EligibleSupervisor[];
  public eligibleEmployees: SupervisedEmployee[];

  @ViewChild('groupGrid', {static: false})
  public groupGrid: SupervisorGroupGridComponent;

  private unassignedEmployeesDialog: UnassignedEmployeesPopupComponent;

  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private groupSavedSubscription: Subscription;
  @unsubscribe()
  private supervisorAddedSubscription: Subscription;
  @unsubscribe()
  private employeeAddedSubscription: Subscription;
  @unsubscribe()
  private selectedGroupsSubscription: Subscription;
  @unsubscribe()
  private startAddEmployeeSubscription: Subscription;
  @unsubscribe()
  private startAddSupervisorSubscription: Subscription;
  @unsubscribe()
  private startEditSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private unassignedEmployeesSubscription: Subscription;


  private container: SupervisorGroupsContainer;
  private selectedGroups: SupervisorGroup[];
  private selectedEligibleSupervisors: EligibleSupervisor[];
  private selectedEligibleEmployees: SupervisedEmployee[];
  private groupToAddSupervisors: SupervisorGroup;
  private groupToAddEmployees: SupervisorGroup;


  constructor(private managementService: SupervisorAssignmentManagementService, private supervisorAssignmentApiService: SupervisorAssignmentApiService, private modalService: ModalService) {
    this.state = {
      isLoading: false,
      hasInnerChanges: false,
      groupEditMode: false,
      addSupervisorMode: false,
      addEmployeeMode: false,
      orgLvl: false
    };
  }

  public ngOnInit(): void {
    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });

    this.loadedSubscription = this.managementService.onLoaded$
      .subscribe((container: SupervisorGroupsContainer) => {
        this.container = container;
      });

    this.startEditSubscription = this.managementService.startEditGroupCmd$.subscribe(() => {
      this.state.groupEditMode = true;
    });

    this.groupSavedSubscription = this.managementService.addedGroupCmd$
      .combineLatest(this.managementService.editedGroupCmd$,
        ((addedGroup: SupervisorGroup, editedGroup: SupervisorGroup) => {
          return { addedGroup: addedGroup, editedGroup: editedGroup };
        }))
      .subscribe((groups: { addedGroup: SupervisorGroup, editedGroup: SupervisorGroup }) => {
        this.state.groupEditMode = false;
        this.selectedGroups = null;
      });

    this.supervisorAddedSubscription = this.managementService.addedSupervisorsCmd$
      .subscribe((result: supervisorsGroup) => {
        this.state.addSupervisorMode = false;
      });

    this.employeeAddedSubscription = this.managementService.addedSupervisedEmployeesCmd$
      .subscribe((result: employeesGroup) => {
        this.state.addEmployeeMode = false;
      });

    this.selectedGroupsSubscription = this.managementService.groupsSelected$
      .subscribe((groups: SupervisorGroup[]) => {
        this.selectedGroups = groups;
      });

    this.startAddEmployeeSubscription = this.managementService.startAddEmployeeCmd$
      .subscribe((group: SupervisorGroup) => {
        this.state.addEmployeeMode = true;
        this.groupToAddEmployees = group;
        this.eligibleEmployees = null;
        this.selectedEligibleEmployees = null;
        this.loadEligibleEmployees(this.managementService.currentOrgLevel.id, group.id);

      });

    this.startAddSupervisorSubscription = this.managementService.startAddSupervisorCmd$
      .subscribe((group: SupervisorGroup) => {
        this.state.addSupervisorMode = true;
        this.groupToAddSupervisors = group;
        this.eligibleSupervisors = null;
        this.selectedEligibleSupervisors = null;
        this.loadEligibleSupervisers(this.managementService.currentOrgLevel.id, group.id);
      });

    this.stateSubscription = this.managementService.onStateChanged$
      .subscribe((state: { orgLvl: boolean }) => {
        this.state.orgLvl = state.orgLvl;
      });

    this.unassignedEmployeesSubscription = this.managementService.loadedUnassignedEmployeesCmd$
      .subscribe((employees: UnassignedEmployee[]) => {
        if (this.unassignedEmployeesDialog) {
          this.unassignedEmployeesDialog.employees = employees;
        }
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onAddGroup(): void {
    this.state.groupEditMode = true;
    this.managementService.startAddGroupCmd();
  }

  public onEditGroup(): void {
    this.state.groupEditMode = true;
    this.managementService.startEditGroupCmd();
  }

  public onDeleteGroup(): void {
    if (!this.isAnyGroupSelected) {
      return;
    }
    let grpToDelete = this.selectedGroups[0];
    this.selectedGroups = null;
    this.managementService.deleteGroupCmd(grpToDelete);
  }

  public onDiscard(): void {
    this.state.groupEditMode = false;
    this.state.addSupervisorMode = false;
    this.state.addEmployeeMode = false;
    this.managementService.actionCmd(SupervisorAssignmentAction.cancel);

    if (this.state.hasInnerChanges && this.managementService.currentOrgLevel) {
      this.state.hasInnerChanges = false;
      this.managementService.loadGroups(this.managementService.currentOrgLevel.id);
    }
  }

  public onSaveGroup(): void {
    this.state.groupEditMode = false;
    this.state.addSupervisorMode = false;
    this.state.addEmployeeMode = false;
    this.managementService.actionCmd(SupervisorAssignmentAction.saveSupervisedGroup);
  }

  public onSaveSupervisorToGroup(): void {
    this.state.groupEditMode = false;
    this.state.addSupervisorMode = false;
    this.state.addEmployeeMode = false;
    let supervisorsToAdd = this.selectedEligibleSupervisors;
    this.selectedEligibleSupervisors = null;
    this.managementService.addSupervisorsToGroupCmd(this.groupToAddSupervisors, supervisorsToAdd);
  }

  public onSaveSupervisedEmployeeToGroup(): void {
    this.state.groupEditMode = false;
    this.state.addSupervisorMode = false;
    this.state.addEmployeeMode = false;
    this.state.hasInnerChanges = true;
    let employeesToAdd = this.selectedEligibleEmployees;
    this.selectedEligibleEmployees = null;
    this.managementService.addSupervisedEmployeesToGroupCmd(this.groupToAddEmployees, employeesToAdd);
  }

  public selectEligibleSupervisors(selectedEligibleSupervisors: EligibleSupervisor[]): void {
    this.selectedEligibleSupervisors = selectedEligibleSupervisors;
  }

  public selectEligibleEmployees(selectedEligibleEmployees: SupervisedEmployee[]): void {
    this.selectedEligibleEmployees = selectedEligibleEmployees;
  }

  public showUnassignedClicked(): void {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 800;
    dialogOptions.height = 600;
    dialogOptions.showCloseButton = true;
    this.unassignedEmployeesDialog = this.modalService.globalAnchor.openDialog(
      UnassignedEmployeesPopupComponent,
      'Unassigned Employees',
      dialogOptions, undefined, (result: boolean) => {
        this.unassignedEmployeesDialog = null;
      });

    this.managementService.loadUnassignedForCurrentOrgLevel();
  }

  private loadEligibleSupervisers(orgLevelId: number, groupId: number): void {
    this.managementService.onLoadStatusChanged(true);
    this.supervisorAssignmentApiService.getEligibleSupervisors(orgLevelId, groupId)
      .then((value: EligibleSupervisor[]) => {
        this.eligibleSupervisors = value;
        this.managementService.onLoadStatusChanged(false);
      });
  }

  private loadEligibleEmployees(orgLevelId: number, groupId: number): void {
    this.managementService.onLoadStatusChanged(true);
    this.supervisorAssignmentApiService.getSupervisedEmployees(orgLevelId, groupId)
      .then((value: SupervisedEmployee[]) => {
        this.eligibleEmployees = value;
        this.managementService.onLoadStatusChanged(false);
      });
  }
}



