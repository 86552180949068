import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { EmployeeSubsectionBenefitClasses } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { KendoGridStateHelper, ConfirmDialog2Component, ModalService, ConfirmOptions } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';
import { LookupService, LookupType, EmployeeBenefitClass } from '../../../../../organization/index';
import { ConfirmBenefitChangesDialogComponent } from './confirm-benefit-changes-dialog/confirm-benefit-changes-dialog.component';
import { appConfig } from '../../../../../app.config';
var EmployeeSectionsBenefitClassComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsBenefitClassComponent, _super);
    function EmployeeSectionsBenefitClassComponent(modalService, lookupService, changeDetector, apiService, decorator, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.lookupService = lookupService;
        _this.changeDetector = changeDetector;
        _this.apiService = apiService;
        _this.sort = [];
        _this.benefitClassDefinitionsLookup = [];
        _this.now = moment().startOf('day').toDate();
        _this.maxDate = moment(appConfig.maxCorrectDate).toDate();
        decorator.isEditableByConfiguration = false;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.skip = 0;
        _this.gridState.state.sort = [{ field: 'startDate', dir: 'asc' }];
        _this.createSubscriptions();
        _this.updateLookups();
        return _this;
    }
    Object.defineProperty(EmployeeSectionsBenefitClassComponent.prototype, "benefitClasses", {
        set: function (empBenefitClasses) {
            this.employeeSectionsBenefitClasses = empBenefitClasses;
            this.originalEmployeeBenefitClasses = empBenefitClasses ? _.slice(empBenefitClasses.empBenefitClasses, 0) : null;
            this.refreshGrid();
            this.updateLookups();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBenefitClassComponent.prototype, "employeeId", {
        get: function () {
            return this.m_employeeId;
        },
        set: function (value) {
            this.m_employeeId = value;
            this.updateLookups();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBenefitClassComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBenefitClassComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsBenefitClassComponent.prototype, "minDate", {
        get: function () {
            return (this.employeeStatus.toLowerCase() === "future rehire") ? this.employeeRehireDate : new Date(1900, 1, 1);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsBenefitClassComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.formValueChangeSubscription = this.ngFormChild.statusChanges.subscribe(function (x) {
            console.log(x);
            console.log(_this.ngFormChild.valid);
        });
    };
    EmployeeSectionsBenefitClassComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsBenefitClassComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.editingItem = item;
            _this.addMode = false;
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.editingItem = null;
            _this.addMode = false;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            if (item.isNew) {
                var source = _this.employeeSectionsBenefitClasses.empBenefitClasses;
                _this.employeeSectionsBenefitClasses.empBenefitClasses = _.concat(source, [item.dataItem]);
                _this.addMode = false;
            }
            _this.doUpdate(item.dataItem);
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialog2Component.openDialog('Confirmation', 'Are you sure that you want to remove this class?', _this.modalService, function (result) {
                if (result) {
                    _this.doRemove(item.dataItem);
                }
            }, options);
        });
    };
    EmployeeSectionsBenefitClassComponent.prototype.benefitClassChanged = function (item, value) {
        item.benefitClassId = value.id;
        item.benefitClass = value;
    };
    EmployeeSectionsBenefitClassComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsBenefitClasses;
    };
    EmployeeSectionsBenefitClassComponent.prototype.onStartAdd = function () {
        var newEmpBenClass = new EmployeeBenefitClass();
        newEmpBenClass.empId = this.employeeId;
        newEmpBenClass.startDate = (this.employeeStatus.toLowerCase() === "future rehire") ? this.employeeRehireDate : moment().startOf('day').toDate();
        newEmpBenClass.endDate = null;
        this.addMode = true;
        this.grid.addRow(newEmpBenClass);
    };
    EmployeeSectionsBenefitClassComponent.prototype.doUpdate = function (item) {
        this.updateTimeline(item, false);
        this.confirmSaveChanges();
    };
    EmployeeSectionsBenefitClassComponent.prototype.doRemove = function (item) {
        this.employeeSectionsBenefitClasses.empBenefitClasses = _.without(this.employeeSectionsBenefitClasses.empBenefitClasses, item);
        this.updateTimeline(item, true);
        this.confirmSaveChanges();
    };
    EmployeeSectionsBenefitClassComponent.prototype.confirmSaveChanges = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        var hasDeleted = this.deletedItems && _.size(this.deletedItems) > 0;
        var hasAdded = this.addedItems && _.size(this.addedItems) > 0;
        var hasChanged = this.changedItems && _.size(this.changedItems) > 0;
        if (_.size(this.originalEmployeeBenefitClasses) == 0) {
            hasAdded = hasDeleted = hasChanged = false;
        }
        if (hasDeleted || hasAdded) {
            ConfirmBenefitChangesDialogComponent
                .openDialog(this.changedItems, this.addedItems, this.deletedItems, 'Confirmation', 'By updating the following benefit classes will be', this.modalService, function (result) {
                if (result) {
                    _this.originalEmployeeBenefitClasses = _this.employeeSectionsBenefitClasses.empBenefitClasses = _this.tempTimeline;
                    _this.refreshGrid();
                    _this.startProgress();
                    _this.saveSubsection();
                }
                else {
                    _this.tempTimeline = null;
                    _this.employeeSectionsBenefitClasses.empBenefitClasses = _this.originalEmployeeBenefitClasses;
                    _this.refreshGrid();
                    _this.changeDetector.markForCheck();
                    _this.changeDetector.detectChanges();
                }
            });
        }
        else {
            if (hasChanged) {
                ConfirmDialog2Component.openDialog('Confirmation', 'Are you sure you want apply changes?', this.modalService, function (result) {
                    if (result) {
                        _this.originalEmployeeBenefitClasses = _this.employeeSectionsBenefitClasses.empBenefitClasses = _this.tempTimeline;
                        _this.refreshGrid();
                        _this.startProgress();
                        _this.saveSubsection();
                    }
                    else {
                        _this.tempTimeline = null;
                        _this.employeeSectionsBenefitClasses.empBenefitClasses = _this.originalEmployeeBenefitClasses;
                        _this.refreshGrid();
                        _this.changeDetector.markForCheck();
                        _this.changeDetector.detectChanges();
                    }
                }, options);
            }
            else {
                this.tempTimeline = null;
                this.originalEmployeeBenefitClasses = this.employeeSectionsBenefitClasses.empBenefitClasses;
                this.refreshGrid();
                this.startProgress();
                this.saveSubsection();
            }
        }
    };
    EmployeeSectionsBenefitClassComponent.prototype.saveSubsection = function () {
        var _this = this;
        this.apiService.saveBenefitClassesSection(this.employeeSectionsBenefitClasses, this.employeeId)
            .then(function (status) {
            _this.loadSubsection();
        })
            .catch(function (error) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsBenefitClassComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.apiService.getEmployeeBenefitClasses(this.employeeId)
            .then(function (subsectionData) {
            _this.employeeSectionsBenefitClasses = subsectionData;
            _this.refreshGrid();
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function (res) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsBenefitClassComponent.prototype.refreshGrid = function () {
        if (!this.employeeSectionsBenefitClasses) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.employeeSectionsBenefitClasses.empBenefitClasses, this.gridState.state);
    };
    EmployeeSectionsBenefitClassComponent.prototype.updateLookups = function () {
        var _this = this;
        if (this.m_employeeId) {
            this.lookupService.getLookup({
                lookupType: LookupType.benefitClasses, employeeId: this.m_employeeId
            }).then(function (lookup) {
                var classes = [];
                _.forEach(lookup.items, function (item) {
                    classes.push(item);
                });
                _this.benefitClassDefinitionsLookup = _.sortBy(classes, function (x) { return x.name; });
            });
        }
    };
    EmployeeSectionsBenefitClassComponent.prototype.updateTimeline = function (editedItem, isRemove) {
        var _this = this;
        var maxTime = 864 * Math.pow(10, 13);
        this.tempTimeline = null;
        this.deletedItems = [];
        this.addedItems = [];
        this.changedItems = [];
        var copy = _.slice(this.employeeSectionsBenefitClasses.empBenefitClasses, 0);
        if (isRemove) {
            if (_.size(copy) == 0) {
                this.deletedItems = [editedItem];
            }
            /*
            if (_.size(copy) > 0) {
              if (moment(editedItem.startDate).isBefore(this.now)) {
      
                // close benefit with current date;
                let closedItem = this.copyBenefitItem(editedItem);
                closedItem.endDate = moment(this.now).startOf('day').toDate();
      
                this.deletedItems = [editedItem];
                this.addedItems = [closedItem];
              }
            } else {
              this.deletedItems = [editedItem];
            }
            */
        }
        else {
            if (_.isNil(editedItem.id)) {
                this.addedItems = [editedItem];
            }
            else {
                this.changedItems = [editedItem];
            }
        }
        if (_.size(copy) <= 1) {
            this.tempTimeline = _.slice(copy, 0);
            return;
        }
        // -- process timeline resonving overlaps and filling gaps
        var ranges = _.map(copy, function (x) {
            return {
                benClass: x,
                protectedItem: _.isNil(x.id) || editedItem === x,
                x: moment(x.startDate).startOf('day').toDate().getTime(),
                y: x.endDate ? moment(x.endDate).startOf('day').toDate().getTime() : maxTime,
                parent: null,
                skip: false
            };
        });
        var protectedItemIsSubset = false;
        var protectedItem = _.find(ranges, function (x) { return x.protectedItem; });
        ranges = _.without(ranges, protectedItem);
        var sets = [];
        _.each(ranges, function (range) {
            _.each(ranges, function (set) {
                if (range.x >= set.x && range.y <= set.y && range !== set && !set.skip) {
                    range.skip = true;
                }
            });
        });
        _.each(ranges, function (range) {
            if (!range.skip) {
                sets.push(range);
            }
        });
        if (protectedItem) {
            _.each(sets, function (range) {
                if (range.x < protectedItem.x && range.y > protectedItem.y) {
                    protectedItemIsSubset = true;
                    protectedItem.parent = range;
                }
                else if (range.x == protectedItem.x && range.y == protectedItem.y) {
                    range.skip = true;
                }
                else if (range.x > protectedItem.x && range.y < protectedItem.y) {
                    range.skip = true;
                }
                else if (range.x == protectedItem.x && range.y < protectedItem.y) {
                    range.skip = true;
                }
                else if (range.x > protectedItem.x && range.y == protectedItem.y) {
                    range.skip = true;
                }
            });
            var newArr_1 = [];
            _.each(sets, function (range) {
                if (!range.skip) {
                    newArr_1.push(range);
                }
            });
            sets = newArr_1;
            if (protectedItemIsSubset) {
                var parent_1 = protectedItem.parent;
                if (protectedItem.benClass.benefitClassId !== parent_1.benClass.benefitClassId) {
                    var leftItem = {
                        benClass: parent_1.benClass,
                        protectedItem: false,
                        x: parent_1.x,
                        y: protectedItem.x,
                        parent: null,
                        skip: false
                    };
                    var rightItem = {
                        benClass: parent_1.benClass,
                        protectedItem: false,
                        x: protectedItem.y,
                        y: parent_1.y,
                        parent: null,
                        skip: false
                    };
                    sets = _.without(sets, parent_1);
                    sets = _.concat(sets, [leftItem, protectedItem, rightItem]);
                }
                else {
                    parent_1.protectedItem = true;
                    parent_1.skip = false;
                }
            }
            else {
                sets = _.concat(sets, protectedItem);
            }
        }
        sets = _.sortBy(sets, 'x');
        // resolve overlaps (respect newly added item)
        var distinctSets = [];
        var setsSize = _.size(sets);
        var dayInMillisecods = 1000 * 60 * 60 * 24;
        for (var i = 0; i < setsSize; i++) {
            var currentSet = sets[i];
            var priorSet = null;
            if (i > 0) {
                priorSet = sets[i - 1];
            }
            if (!currentSet.protectedItem) {
                if (priorSet) {
                    if (currentSet.benClass.benefitClassId === priorSet.benClass.benefitClassId) {
                        currentSet.skip = true;
                        priorSet.y = currentSet.y;
                    }
                    else {
                        if (currentSet.x < priorSet.y + dayInMillisecods) {
                            currentSet.x = priorSet.y + dayInMillisecods;
                        }
                    }
                }
            }
            else {
                if (priorSet) {
                    if (currentSet.benClass.benefitClassId === priorSet.benClass.benefitClassId) {
                        currentSet.skip = true;
                        priorSet.y = currentSet.y;
                    }
                    else {
                        if (priorSet.y > currentSet.x - dayInMillisecods) {
                            priorSet.y = currentSet.x - dayInMillisecods;
                        }
                    }
                }
            }
        }
        for (var i = 0; i < setsSize; i++) {
            var currentSet = sets[i];
            if (!currentSet.skip) {
                distinctSets.push(currentSet);
            }
        }
        // produce new benefits array
        var dSetsSize = _.size(distinctSets);
        var newBenefitClasses = [];
        for (var i = 0; i < dSetsSize; i++) {
            var set = distinctSets[i];
            var benefitClass = new EmployeeBenefitClass();
            benefitClass.id = set.benClass.id;
            benefitClass.benefitClassId = set.benClass.benefitClassId;
            benefitClass.empId = this.employeeId;
            benefitClass.startDate = moment(new Date(set.x)).startOf('day').toDate();
            benefitClass.endDate = set.y != maxTime ? moment(new Date(set.y)).startOf('day').toDate() : null;
            benefitClass.benefitClass = set.benClass.benefitClass;
            benefitClass.updatedOn = set.benClass.updatedOn;
            benefitClass.updatedBy = set.benClass.updatedBy;
            newBenefitClasses.push(benefitClass);
        }
        this.tempTimeline = newBenefitClasses;
        _.each(this.employeeSectionsBenefitClasses.empBenefitClasses, function (original) {
            if (!_.isNil(original.id)) {
                var matchingItem = _.find(_this.tempTimeline, function (temp) {
                    return temp.id === original.id;
                });
                if (_.isNil(matchingItem)) {
                    _this.deletedItems.push(original);
                }
                else {
                    if (!moment(matchingItem.startDate).isSame(original.startDate) || !moment(matchingItem.endDate).isSame(original.endDate)) {
                        _this.changedItems.push(matchingItem);
                    }
                }
            }
        });
    };
    EmployeeSectionsBenefitClassComponent.prototype.copyBenefitItem = function (editedItem) {
        var copy = new EmployeeBenefitClass();
        copy.benefitClassId = editedItem.benefitClassId;
        copy.benefitClass = editedItem.benefitClass;
        copy.empId = editedItem.empId;
        copy.startDate = editedItem.startDate;
        copy.endDate = editedItem.endDate;
        copy.id = editedItem.id;
        copy.updatedBy = editedItem.updatedBy;
        copy.updatedOn = editedItem.updatedOn;
        return copy;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBenefitClassComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBenefitClassComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBenefitClassComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBenefitClassComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBenefitClassComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsBenefitClassComponent.prototype, "formValueChangeSubscription", void 0);
    return EmployeeSectionsBenefitClassComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsBenefitClassComponent };
