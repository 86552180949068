import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { State } from '../../../../organization/models/index';
import { GeolocationEntity, IGeolocationEntityDTO, IGeolocationEvent, ViewModeSettings, SearchModeSettings, CoordsModeSettings, MapCircleChangedEvent } from '../../../models/index';
import { GeolocationManagementService } from '../../../services/index';
var GeoState = /** @class */ (function (_super) {
    tslib_1.__extends(GeoState, _super);
    function GeoState(name) {
        var _this = _super.call(this) || this;
        _this.id = name;
        _this.name = name;
        return _this;
    }
    return GeoState;
}(State));
export { GeoState };
var GeolocationFormComponent = /** @class */ (function () {
    function GeolocationFormComponent(manService) {
        this.manService = manService;
        this.viewModeDefaults = { radius: 300, commonZoom: 7, placeZoom: 16 }; // radius = meters
        this.activeModeDefaults = { zoom: 16 };
        this.override = null;
        this.canOverride = false;
        this.isLoading = true;
        this.metricsList = [{ id: 1, name: 'Meters', value: 1 }, { id: 2, name: 'Yards', value: 1.0936 }];
        this.orgLevelLat = 0;
        this.orgLevelLng = 0;
        this.radiusInMeters = 0;
        this.subscriptions = {};
        this.editingEntity = new GeolocationEntity();
        this.currentMetric = _.head(this.metricsList);
    }
    Object.defineProperty(GeolocationFormComponent.prototype, "isDisabled", {
        get: function () {
            return !_.isNull(this.override);
        },
        enumerable: true,
        configurable: true
    });
    GeolocationFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.manService.subscribeToOrgLevelChanged(function (orgLevel) {
            _this.handleOrgLevelChange(orgLevel);
        });
        this.subscriptions.entitySelected = this.manService.subscribeToEntitySelected(function (entity) {
            if (_.isObjectLike(entity)) {
                _this.handleEditEvent(entity);
            }
        });
        this.subscriptions.buttons = this.manService.subscribeToGeoEvent(function (event) {
            if (event.isSave) {
                _this.handleSaveEvent();
            }
            else if (event.isUpdate) {
                _this.handleUpdateEvent();
            }
            else if (event.isCancel) {
                _this.handleCancelEvent();
            }
        });
        this.subscriptions.states = this.manService.subscribeToStatesLoaded(function (states) {
            _this.statesLookup = _.map(states, function (s) { return new GeoState(s.name); });
            _this.currentState = _.head(_this.statesLookup);
        });
    };
    GeolocationFormComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subscriptions.form = this.geoForm.statusChanges.subscribe(function () {
            if (_this.editingEntity.currentLat !== 0 && _this.editingEntity.currentLng !== 0) {
                _this.updateFormButtonsState(_this.geoForm.valid);
            }
        });
    };
    GeolocationFormComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    GeolocationFormComponent.prototype.onSearchPlaceOnMap = function () {
        var metersMetric = _.head(this.metricsList);
        var fullAddress = this.editingEntity.address + ", " + this.editingEntity.city + ", " + this.editingEntity.state + " " + this.editingEntity.zip;
        this.activeModeSettings = new SearchModeSettings(fullAddress, this.viewModeDefaults.radius, this.viewModeDefaults.placeZoom);
        this.editingEntity.currentDistance = this.viewModeDefaults.radius;
        this.editingEntity.currentUnitOfMeasure = metersMetric.id;
        this.currentDistance = this.viewModeDefaults.radius;
        this.currentMetric = metersMetric;
    };
    GeolocationFormComponent.prototype.onClickOverride = function () {
        // console.log("debug form onclickoverride", this.editingEntity);
        this.assignOverride(this.editingEntity.currentLat, this.editingEntity.currentLng, 0.002, this.editingEntity.overriddenDistance, this.editingEntity.distance);
        this.editingEntity.isOverridden = true;
        this.editingEntity.overriddenUnitOfMeasure = this.editingEntity.unitOfMeasure;
        this.editingEntity.overriddenDistance = this.editingEntity.distance;
    };
    GeolocationFormComponent.prototype.onClickDeleteOverride = function () {
        this.editingEntity.isOverridden = false;
        this.resetOverride(this.editingEntity);
    };
    GeolocationFormComponent.prototype.onCenterChange = function (event) {
        if (event.dragged && !this.editingEntity.isOverridden) {
            this.assignOverride(event.lat, event.lng, 0, this.editingEntity.overriddenDistance, this.editingEntity.distance);
            this.editingEntity.isOverridden = true;
            this.editingEntity.overriddenUnitOfMeasure = this.editingEntity.unitOfMeasure;
            this.editingEntity.overriddenDistance = this.editingEntity.distance;
        }
        this.editingEntity.currentLat = event.lat;
        this.editingEntity.currentLng = event.lng;
    };
    GeolocationFormComponent.prototype.onRadiusChanged = function (r) {
        var radius = this.radiusInMeters = _.round(r);
        var distance = this.getDistance(radius, this.currentMetric);
        this.editingEntity.currentDistance = distance;
        this.currentDistance = distance;
    };
    GeolocationFormComponent.prototype.onNotFound = function (isFoundAddress) {
        this.updateFormButtonsState(isFoundAddress);
    };
    GeolocationFormComponent.prototype.onChangedMetric = function (metric) {
        var distance = this.getDistance(this.radiusInMeters, metric);
        this.editingEntity.currentDistance = distance;
        this.editingEntity.currentUnitOfMeasure = metric.id;
        this.currentDistance = distance;
    };
    GeolocationFormComponent.prototype.onChangedState = function (state) {
        this.editingEntity.state = state.name;
    };
    GeolocationFormComponent.prototype.handleOrgLevelChange = function (orgLevel) {
        this.orgLevel = orgLevel;
        this.orgLevelLat = _.get(this.orgLevel, 'location.latitude', 0) || 0;
        this.orgLevelLng = _.get(this.orgLevel, 'location.longitude', 0) || 0;
        this.viewModeSettings = new ViewModeSettings(this.orgLevelLat, this.orgLevelLng, this.viewModeDefaults.commonZoom);
        this.editingEntity.orgLevelId = this.orgLevel.id;
        this.editingEntity.orgLevelName = this.orgLevel.name;
    };
    GeolocationFormComponent.prototype.handleEditEvent = function (entity) {
        this.editingEntity = entity;
        var distance = entity.currentDistance;
        var unitOfMeasure = entity.currentUnitOfMeasure;
        this.radiusInMeters = this.getDistanceInMeters(distance, unitOfMeasure);
        this.activeModeSettings = new CoordsModeSettings(entity.latitude, entity.longitude, this.radiusInMeters, this.activeModeDefaults.zoom);
        this.currentDistance = distance;
        this.currentMetric = this.getMetric(unitOfMeasure);
        this.currentState = new GeoState(entity.state);
        this.updateFormButtonsState(true);
        if (entity.isOverridden) {
            // console.log("debug form handleeditevent", entity);
            this.assignOverride(entity.currentLat, entity.currentLng, 0, this.editingEntity.overriddenDistance, this.editingEntity.distance);
        }
    };
    GeolocationFormComponent.prototype.handleSaveEvent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.saveGeolocation(this.editingEntity)];
                    case 1:
                        _a.sent();
                        this.manService.loadGeolocations(this.editingEntity.orgLevelId);
                        this.manService.closeForm();
                        this.resetSettings();
                        return [2 /*return*/];
                }
            });
        });
    };
    GeolocationFormComponent.prototype.handleUpdateEvent = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.updateGeolocation(this.editingEntity)];
                    case 1:
                        _a.sent();
                        this.manService.loadGeolocations(this.editingEntity.orgLevelId);
                        this.manService.closeForm();
                        this.resetSettings();
                        return [2 /*return*/];
                }
            });
        });
    };
    GeolocationFormComponent.prototype.handleCancelEvent = function () {
        this.resetSettings();
    };
    GeolocationFormComponent.prototype.resetSettings = function () {
        this.activeModeSettings = null;
        this.editingEntity = new GeolocationEntity();
        this.editingEntity.orgLevelId = this.orgLevel.id;
        this.editingEntity.orgLevelName = this.orgLevel.name;
        this.override = null;
        this.currentDistance = 0;
        this.currentMetric = _.head(this.metricsList);
        this.currentState = _.head(this.statesLookup);
        this.canOverride = false;
        this.viewModeSettings = new ViewModeSettings(this.orgLevelLat, this.orgLevelLng, this.viewModeDefaults.commonZoom);
    };
    GeolocationFormComponent.prototype.getDistance = function (distance, metric) {
        return _.round(distance * metric.value);
    };
    GeolocationFormComponent.prototype.getDistanceInMeters = function (distance, metricId) {
        var metric = _.find(this.metricsList, { id: metricId });
        if (metric) {
            if (metric.name === 'Meters') {
                return distance;
            }
            return _.round(distance / metric.value);
        }
        return 0;
    };
    GeolocationFormComponent.prototype.getMetric = function (metricId) {
        var metric = _.find(this.metricsList, { id: metricId });
        if (metric) {
            return metric;
        }
        return null;
    };
    GeolocationFormComponent.prototype.updateFormButtonsState = function (isActiveState) {
        this.canOverride = isActiveState;
        this.manService.changeToolbarMode(isActiveState);
    };
    GeolocationFormComponent.prototype.resetOverride = function (e) {
        this.override = null;
        e.overriddenDistance = 0;
        e.overriddenUnitOfMeasure = 0;
        e.overriddenLatitude = 0;
        e.overriddenLongitude = 0;
    };
    GeolocationFormComponent.prototype.assignOverride = function (latitude, longitude, shift, overriddenDistance, distance) {
        if (shift === void 0) { shift = 0; }
        var lat = shift > 0 ? latitude + shift : latitude;
        var lng = shift > 0 ? longitude + shift : longitude;
        this.override = { lat: lat, lng: lng, overriddenDistance: overriddenDistance, distance: distance };
    };
    return GeolocationFormComponent;
}());
export { GeolocationFormComponent };
