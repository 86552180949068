var ExceptionConsoleRecord = /** @class */ (function () {
    function ExceptionConsoleRecord() {
    }
    Object.defineProperty(ExceptionConsoleRecord.prototype, "missingPunches", {
        get: function () {
            return this.pendingMissingPunchesCount + "/" + this.approvedMissingPunchesCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExceptionConsoleRecord.prototype, "isLocked", {
        get: function () {
            return this.isOrganizationPayrollLocked || this.isPayCycleLocked || this.isTimecardLocked;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExceptionConsoleRecord.prototype, "startDate", {
        get: function () {
            return this.payCycle ? this.payCycle.startDate : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExceptionConsoleRecord.prototype, "endDate", {
        get: function () {
            return this.payCycle ? this.payCycle.endDate : null;
        },
        enumerable: true,
        configurable: true
    });
    ExceptionConsoleRecord.prototype.getExceptionCount = function (name) {
        return this.exceptionsMap && this.exceptionsMap[name] ? this.exceptionsMap[name].exceptionCount : 0;
    };
    return ExceptionConsoleRecord;
}());
export { ExceptionConsoleRecord };
