export var BenefitEligibilityRuleStatus;
(function (BenefitEligibilityRuleStatus) {
    BenefitEligibilityRuleStatus[BenefitEligibilityRuleStatus["NoChanges"] = 0] = "NoChanges";
    BenefitEligibilityRuleStatus[BenefitEligibilityRuleStatus["Added"] = 1] = "Added";
    BenefitEligibilityRuleStatus[BenefitEligibilityRuleStatus["Updated"] = 2] = "Updated";
    BenefitEligibilityRuleStatus[BenefitEligibilityRuleStatus["Deleted"] = 3] = "Deleted";
})(BenefitEligibilityRuleStatus || (BenefitEligibilityRuleStatus = {}));
var BenefitEligibilityRule = /** @class */ (function () {
    function BenefitEligibilityRule(_a) {
        var id = _a.id, name = _a.name, description = _a.description, type = _a.type, rule = _a.rule, ruleV5 = _a.ruleV5, isDeleted = _a.isDeleted, modifiedAt = _a.modifiedAt, modifiedBy = _a.modifiedBy, addedAt = _a.addedAt, addedBy = _a.addedBy, ruleStatements = _a.ruleStatements, status = _a.status;
        this.id = id;
        this.name = name;
        this.description = description;
        this.type = type;
        this.rule = rule;
        this.ruleV5 = ruleV5;
        this.isDeleted = isDeleted;
        this.modifiedAt = modifiedAt;
        this.modifiedBy = modifiedBy;
        this.addedAt = addedAt;
        this.addedBy = addedBy;
        this.ruleStatements = ruleStatements;
        this.status = status || BenefitEligibilityRuleStatus.NoChanges;
    }
    return BenefitEligibilityRule;
}());
export { BenefitEligibilityRule };
