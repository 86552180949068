import { Component, OnDestroy, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';

import { BudgetApiService, BudgetManagementService } from '../../../services/index';
import { Budget, BudgetRecord } from '../../../models/index';
import { BudgetDefinition } from '../../../../organization/models/index';


@Component({
  moduleId: module.id,
  selector: 'slx-budget-history-detail',
  templateUrl: 'budget-history-detail.component.html',
  styleUrls: ['budget-history-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BudgetHistoryDetailComponent implements OnDestroy {

  @Input()
  public set budgetDefinition(def: BudgetDefinition) {
    if (!def) { return; }
    this.loadBudget(def);
  }
  public state: {
    isLoading: boolean;
  };
  public appConfig: IApplicationConfig;
  public currentBudget: Budget;
  public gridState: KendoGridStateHelper<BudgetRecord>;
  private budgetApiService: BudgetApiService;

  private changeDetector: ChangeDetectorRef;
  private aggregates: any = [
    { field: 'weekendHours', aggregate: 'sum' },
    { field: 'weekdayHours', aggregate: 'sum' },
    { field: 'payRate', aggregate: 'sum' }
  ];

  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  constructor(budgetApiService: BudgetApiService, changeDetector: ChangeDetectorRef) {
    this.budgetApiService = budgetApiService;
    this.changeDetector = changeDetector;

    this.state = {
      isLoading: false
    };
    this.gridState = new KendoGridStateHelper<BudgetRecord>();
    let aggregates: any = [
      { field: 'weekendHours', aggregate: 'sum' },
      { field: 'weekdayHours', aggregate: 'sum' },
      { field: 'payRate', aggregate: 'sum' }
    ];
    this.gridState.state.group = [{ field: 'budgetedGroup.description', aggregates: aggregates }];
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public loadBudget(def: BudgetDefinition): void {
    if (this.currentBudget && this.currentBudget.id === def.id) {
      return;
    }
    this.state.isLoading = true;
    this.budgetApiService.getBudget(def.orgLevelId, def.id)
      .then((result: Budget) => {
        this.currentBudget = result;
        this.state.isLoading = false;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      })
      .catch(() => {
        this.state.isLoading = false;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

  }

  private refreshGrid(): void {
    if (!this.currentBudget || !this.currentBudget.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.state.group.forEach((group: any) => group.aggregates = this.aggregates);
    this.gridState.view = process(this.currentBudget.records, this.gridState.state);
  }

}
