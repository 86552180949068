import * as _ from 'lodash';
import { Component, Input, Host } from '@angular/core';
import { PopperContent } from 'ngx-popper';

@Component({
  moduleId: module.id,
  selector: 'slx-actions-list',
  templateUrl: 'actions-list.component.html',
  styleUrls: ['actions-list.component.scss'],
})
export class ActionsListComponent {
  @Input()
  public className: string;

  public getClassName(): string {
    const hasClass: boolean = _.isString(this.className) && _.size(this.className) > 0;
    if (hasClass) {
      return ` ${this.className}`;
    }

    return '';
  }
}
