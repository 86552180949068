import { OrganizationPayrollStates } from './organization-payroll-states';
var OrganizationPayroll = /** @class */ (function () {
    function OrganizationPayroll() {
    }
    Object.defineProperty(OrganizationPayroll.prototype, "isExported", {
        get: function () {
            return this.state === OrganizationPayrollStates.EXPORTED;
        },
        enumerable: true,
        configurable: true
    });
    return OrganizationPayroll;
}());
export { OrganizationPayroll };
