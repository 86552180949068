import { ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { WindowComponent } from '@progress/kendo-angular-dialog';
var KendoWindowPositionRightDirective = /** @class */ (function () {
    function KendoWindowPositionRightDirective(kendoWindow, elementRef, renderer) {
        this.kendoWindow = kendoWindow;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.m_right = 0;
    }
    Object.defineProperty(KendoWindowPositionRightDirective.prototype, "right", {
        get: function () {
            return this.m_right;
        },
        set: function (value) {
            if (_.isString()) {
                this.m_right = _.toNumber(value);
            }
            else {
                this.m_right = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    KendoWindowPositionRightDirective.prototype.ngAfterViewInit = function () {
        var width = window.innerWidth;
        var leftOffset = width - this.kendoWindow.width - this.right;
        this.kendoWindow.left = leftOffset;
        this.renderer.setStyle(this.elementRef.nativeElement, 'left', leftOffset + 'px');
    };
    return KendoWindowPositionRightDirective;
}());
export { KendoWindowPositionRightDirective };
