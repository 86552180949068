import { Component } from '@angular/core';
import { CostCentersConfigurationManagementService } from '../../../services/index';
import { CostCenterModel } from '../../../models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-cost-centers-toolbar',
    templateUrl: 'cost-centers-toolbar.component.html',
    styleUrls: ['cost-centers-toolbar.component.scss'],
})
  
export class CostCentersToolbarComponent {
    constructor(public management: CostCentersConfigurationManagementService) { } 

    public onAddItem(): void {
        let model: CostCenterModel = new CostCenterModel ();
        this.management.onAddItem(model);
    }

    public onDeleteItems(): void {
        this.management.onDeleteItemStart();
    }

}
