import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { MealDeductionSettings, IMealDeductionSettingsDTO, ShiftModes } from '../../models';
import { dateTimeUtils } from '../../../../common/utils/index';
import * as _ from 'lodash';

@Injectable()
export class MealDeductionMapService {

    public mapMealDeductionSettings(dto: IMealDeductionSettingsDTO): MealDeductionSettings {
        if (dto === null) return null;
        let model = new MealDeductionSettings();
        model.recordId = dto.recordId;
        model.organizationId = dto.organizationId;
        model.maxPunchTreshold = dateTimeUtils.convertFromDtoDurationStringToNumber(dto.maxPunchTreshold, 's') / 60;
        model.enableForNonExempt = dto.enableForNonExempt;
        model.enableForAgency = dto.enableForAgency;
        model.enableForExemptS = dto.enableForExemptS;
        model.enableForExemptT = dto.enableForExemptT;
        model.enableForExemptX = dto.enableForExemptX;
        model.enableForExemptY = dto.enableForExemptY;
        model.lastUpdateBy = dto.lastUpdateBy;
        model.lastUpdateOn = dateTimeUtils.convertFromDtoString(dto.lastUpdateOn);
        model.canConfigure = dto.canConfigure;
        model.generatePBJFromAgency = dto.generatePBJFromAgency == null ? false : dto.generatePBJFromAgency;
        model.midShiftOne = dto.shiftOneMidPoint == null ? 4 : dto.shiftOneMidPoint;
        model.midShiftTwo = dto.shiftTwoMidPoint == null ? 12 : dto.shiftTwoMidPoint;
        model.shift_Type = dto.shiftMode ? dto.shiftMode : _.head(ShiftModes);
        return model;
    }

    public mapMealDeductionSettingsToDTO(settings: MealDeductionSettings): IMealDeductionSettingsDTO {
        return {
            recordId: settings.recordId,
            organizationId: settings.organizationId,
            enableForAgency: settings.enableForAgency,
            enableForExemptS: settings.enableForExemptS,
            enableForExemptT: settings.enableForExemptT,
            enableForExemptX: settings.enableForExemptX,
            enableForExemptY: settings.enableForExemptY,
            enableForNonExempt: settings.enableForNonExempt,
            maxPunchTreshold: dateTimeUtils.convertToDtoDurationString(moment.duration(settings.maxPunchTreshold, 'minutes')),
            lastUpdateBy: settings.lastUpdateBy,
            lastUpdateOn: dateTimeUtils.convertToDtoDateTimeString(settings.lastUpdateOn),
            canConfigure: settings.canConfigure,
            generatePBJFromAgency: settings.generatePBJFromAgency,
            shiftOneMidPoint: settings.midShiftOne ? settings.midShiftOne : 4,
            shiftTwoMidPoint: settings.midShiftTwo ? settings.midShiftTwo : 12,
            shiftMode: settings.shift_Type ? settings.shift_Type : _.head(ShiftModes)
        };
    }
}
