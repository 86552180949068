import { WeeklyDayCounter } from './weekly-day-counter';
import { WeekDaysWithEmpty } from '../../../common/models/index';
import * as _ from 'lodash';
var ParLevels = /** @class */ (function () {
    function ParLevels(init) {
        this.isDailyVariance = false;
        this.days = [];
        Object.assign(this, init);
        for (var dayN = 0; dayN <= WeekDaysWithEmpty.length; dayN++) {
            this.days[dayN] = new WeeklyDayCounter(WeekDaysWithEmpty[dayN], 0, 0);
        }
    }
    Object.defineProperty(ParLevels.prototype, "group", {
        get: function () {
            return this.shiftGroup ? this.shiftGroup.name : '';
        },
        enumerable: true,
        configurable: true
    });
    ParLevels.prototype.getDayCounter = function (dayN) {
        return this.days[dayN].counter;
    };
    ParLevels.prototype.setDayCounter = function (dayN, counter) {
        this.days[dayN].counter = _.round(counter, 2);
    };
    ParLevels.prototype.getDayHours = function (dayN) {
        return this.days[dayN].hours;
    };
    ParLevels.prototype.setDayHours = function (dayN, hours) {
        this.days[dayN].hours = _.round(hours, 2);
    };
    ParLevels.prototype.isUnused = function () {
        for (var dayN = 0; dayN <= WeekDaysWithEmpty.length; dayN++) {
            if (this.days[dayN].hours) {
                return false;
            }
        }
        return true;
    };
    return ParLevels;
}());
export { ParLevels };
