/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar-section.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toolbar-section.component";
var styles_ToolbarSectionComponent = [i0.styles];
var RenderType_ToolbarSectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarSectionComponent, data: {} });
export { RenderType_ToolbarSectionComponent as RenderType_ToolbarSectionComponent };
function View_ToolbarSectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "toolbar-section-divider"]], null, null, null, null, null))], null, null); }
export function View_ToolbarSectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolbarSectionComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showDivider; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "toolbar-section-content ", _co.className, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_ToolbarSectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-toolbar-section", [], null, null, null, View_ToolbarSectionComponent_0, RenderType_ToolbarSectionComponent)), i1.ɵdid(1, 49152, null, 0, i3.ToolbarSectionComponent, [], null, null)], null, null); }
var ToolbarSectionComponentNgFactory = i1.ɵccf("slx-toolbar-section", i3.ToolbarSectionComponent, View_ToolbarSectionComponent_Host_0, { showDivider: "showDivider", className: "className" }, {}, ["*"]);
export { ToolbarSectionComponentNgFactory as ToolbarSectionComponentNgFactory };
