import { Injectable } from "@angular/core";
import { mutableSelect } from './../../../../app/core/decorators';
import { OrgLevel } from './../../../../app/state-model/models';
import { Observable, Subscription } from "rxjs";
import { ExcelData, ImportedFiles } from "../../models/imported-files/ImportedFiles";
import { FileUploadsApiService } from "./file-uploads-api.service";
import { FileService, KendoGridStateHelper } from './../../../../app/common';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { DownloadDataService } from "./download-data.service";

@Injectable()
export class FileUploadsManagementService {

  @mutableSelect()
  public orgLevel$: Observable<OrgLevel>;
  public orgLevelSubscription: Subscription;
  public orgLevelId: number;
  public uploadedFileData: ImportedFiles[] = [];

  public gridState: KendoGridStateHelper<ImportedFiles>;
  public skip: number = 0;
  public pageSize: number = 17;
  public sort: SortDescriptor[] = [];
  public isLoading: boolean = false;

  constructor(public fileApiService: FileUploadsApiService, public downloadService: DownloadDataService) {
    this.orgLevelSubscription = this.orgLevel$.subscribe((data: OrgLevel) => {
      this.isLoading = true;
      this.orgLevelId = data.id;
      this.getImportedFiles();
    });

    Observable.interval(1 * 60 * 1000)
    .timeInterval()
    .subscribe(() => {
      this.isLoading = true;
      this.getImportedFiles();
    });

    this.gridState = new KendoGridStateHelper<ImportedFiles>();
    this.gridState.state.skip = 0;
    this.skip = 0;
    this.gridState.state.take = this.pageSize;
  }

  init() {
    this.isLoading = true;
    this.getImportedFiles();
  }

  public getImportedFiles() {
    this.fileApiService.getImportedFiles(this.orgLevelId).then((data: ImportedFiles[]) => {
      this.uploadedFileData = data;
      this.refreshGrid();
      this.isLoading = false;
    });
  }

  public setSort(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public pageChange(event: PageChangeEvent): void {
    this.gridState.state.skip = event.skip;
    this.refreshGrid();
  }

  public filterChange(event: any): void {
    this.gridState.state.filter = event;
    this.refreshGrid();
  }

  public sortChangeHandler(sort: SortDescriptor[]): void {
    this.setSort(sort);
  }

  public pageChangeHandler(event: PageChangeEvent): void {
    this.pageChange(event);
  }

  public filterChangeHandler(event: any): void {
    this.filterChange(event);
  }

  public refreshGrid(): void {
    if (!this.uploadedFileData) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.uploadedFileData, this.gridState.state);
  }

  public downloadImportedFile(importId: string) {
    this.isLoading = true;
    this.fileApiService.downloadImportedFile(importId).then((data: ExcelData[]) => {
      this.downloadService.excelDownloadDataProcess(data, importId);
      this.isLoading = false;
    });
  }




}