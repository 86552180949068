import * as _ from 'lodash';
var CheckboxButtonComponent = /** @class */ (function () {
    function CheckboxButtonComponent() {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
    }
    Object.defineProperty(CheckboxButtonComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(this.innerValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    CheckboxButtonComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.innerValue = value;
        }
    };
    CheckboxButtonComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    CheckboxButtonComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    Object.defineProperty(CheckboxButtonComponent.prototype, "isChecked", {
        get: function () {
            return this.value === true;
        },
        enumerable: true,
        configurable: true
    });
    CheckboxButtonComponent.prototype.cssClass = function () {
        var cssClass = 'slx-checkbox';
        if (this.isChecked) {
            cssClass += " checked";
        }
        if (this.readonly) {
            cssClass += " disabled";
        }
        if (_.isString(this.className) && _.size(this.className) > 0) {
            cssClass += " " + this.className;
        }
        return cssClass;
    };
    CheckboxButtonComponent.prototype.hasPlaceholder = function () {
        return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
    };
    return CheckboxButtonComponent;
}());
export { CheckboxButtonComponent };
