import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { ScheduleConsoleToolbarService } from '../../services/index';
import { ToolbarSectionTypes } from '../../../../common/models/index';
import { appConfig } from '../../../../app.config';
import { unsubscribe, debounce } from '../../../../core/decorators/index';
import { ScheduleConsoleFilterItem, ConsoleConfig, ScheduleConsoleFilterChangedEvent } from '../../models/index';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';
import { CollapsibleSectionService } from '../../../../components-library/services/index';
import { ChartMenuItem, ScheduleConsoleGroupBy, ScheduleConsoleGroupByType } from '../../models/index';
var ScheduleConsoleToolbarComponent = /** @class */ (function () {
    function ScheduleConsoleToolbarComponent(consoleToolbarService, stateManagement, storageService, sectionService) {
        var _this = this;
        this.consoleToolbarService = consoleToolbarService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.sectionService = sectionService;
        this.filtersControlKey = 'filter';
        this.onDateRangeChange = new EventEmitter();
        this.onDateRangeTypeChange = new EventEmitter();
        this.onConfigChange = new EventEmitter();
        this.onFilterChange = new EventEmitter();
        this.onGrouppingChange = new EventEmitter();
        this.onShownChartChange = new EventEmitter();
        this.selectedDate = moment().toDate();
        this.startOfWeek = 'Sunday';
        this.selectedRangeType = 'Day';
        this.isShowLimits = false;
        this.isShowMobileFilter = false;
        this.initSubscription = this.stateManagement.onInit$
            .subscribe(function () {
            _this.restoreFilters();
        });
    }
    Object.defineProperty(ScheduleConsoleToolbarComponent.prototype, "setOrgLevel", {
        set: function (value) {
            if (value && value.id) {
                this.orgLevel = value;
                if (!this.isDepartment && this.showOnlyDirectCare) {
                    this.showOnlyDirectCare = false;
                }
                this.filterGroupByOptions();
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduleConsoleToolbarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(ScheduleConsoleToolbarComponent.prototype, "appliedFilters", {
        get: function () {
            return this.m_appliedFilters;
        },
        set: function (value) {
            this.m_appliedFilters = value;
            var selectedFilters = [];
            var isAllDirectCare;
            if (_.isArray(value) && _.size(value) > 0) {
                selectedFilters = value.slice(0);
            }
            if (!this.lockUpdateDirectCare) {
                if (_.size(selectedFilters) > 0) {
                    var directCareSelectedCount_1 = 0;
                    var directCareCount_1 = 0;
                    _.each(this.filtersList, function (filter) {
                        if (filter.isDirectCare) {
                            directCareCount_1++;
                            var itemSelected = _.indexOf(selectedFilters, filter) !== -1;
                            if (itemSelected) {
                                directCareSelectedCount_1++;
                            }
                        }
                    });
                    isAllDirectCare = (directCareCount_1 === directCareSelectedCount_1) && (_.size(selectedFilters) === directCareSelectedCount_1);
                }
                else {
                    isAllDirectCare = false;
                }
                this.showOnlyDirectCare = isAllDirectCare;
            }
            else {
                this.lockUpdateDirectCare = false;
            }
            this.saveFilters();
            var event = new ScheduleConsoleFilterChangedEvent();
            event.filters = selectedFilters;
            event.isDirectCare = this.showOnlyDirectCare;
            this.onFilterChange.emit(event);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleToolbarComponent.prototype, "isDepartment", {
        get: function () {
            return this.orgLevel && this.orgLevel.type === OrgLevelType.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleToolbarComponent.prototype, "isOrganization", {
        get: function () {
            return this.orgLevel && this.orgLevel.type === OrgLevelType.organization;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleConsoleToolbarComponent.prototype.onRangeChanged = function (range) {
        this.onDateRangeChange.next(range);
    };
    ScheduleConsoleToolbarComponent.prototype.onRangeTypeChanged = function (range) {
        this.onDateRangeTypeChange.next(range);
    };
    ScheduleConsoleToolbarComponent.prototype.onGrouppingChanged = function (groupBy) {
        this.onGrouppingChange.next(groupBy);
    };
    ScheduleConsoleToolbarComponent.prototype.onConfigChanged = function () {
        this.onConfigChange.emit(this.consoleConfig);
    };
    ScheduleConsoleToolbarComponent.prototype.onChartItemClick = function (chart, popper) {
        var index = _.indexOf(this.charts, chart);
        if (index !== -1) {
            var currentChart = this.charts[index];
            this.shownChart.isShown = false;
            currentChart.isShown = true;
            this.shownChart = currentChart;
            this.onShownChartChange.emit(currentChart);
        }
        if (popper) {
            popper.hide();
        }
    };
    ScheduleConsoleToolbarComponent.prototype.onToggleSection = function (isExpand) {
        this.sectionService.toggleAllSections(isExpand);
    };
    ScheduleConsoleToolbarComponent.prototype.isVisibleSection = function (sectionType) {
        return sectionType === ToolbarSectionTypes.VISIBLE;
    };
    ScheduleConsoleToolbarComponent.prototype.hasCollapsedByResize = function (counts) {
        return counts[ToolbarSectionTypes.COLLAPSED_BY_RESIZE] > 0;
    };
    ScheduleConsoleToolbarComponent.prototype.getDateRangeWidth = function () {
        return (screen.width <= appConfig.mobileMaxWidth) ? 260 : 425;
    };
    ScheduleConsoleToolbarComponent.prototype.isDesktop = function () {
        return screen.width > appConfig.mobileMaxWidth;
    };
    ScheduleConsoleToolbarComponent.prototype.onChangeShowDirectCare = function () {
        this.lockUpdateDirectCare = true;
        if (!this.showOnlyDirectCare) {
            this.appliedFilters = [];
        }
        else {
            var directCareItems_1 = [];
            _.each(this.filtersList, function (item) {
                if (item.isDirectCare) {
                    directCareItems_1.push(item);
                }
            });
            this.appliedFilters = directCareItems_1;
        }
    };
    ScheduleConsoleToolbarComponent.prototype.filterGroupByOptions = function () {
        var _this = this;
        this.groupByList = _.filter(this.origGroupByList, function (groupBy) {
            if (groupBy.name === ScheduleConsoleGroupByType.UNIT) {
                return _this.isDepartment;
            }
            if (groupBy.name === ScheduleConsoleGroupByType.SHIFT) {
                return _this.isDepartment || _this.isOrganization;
            }
            return true;
        });
        var hasCurrentGroupping = _.find(this.groupByList, { name: this.groupBy.name });
        if (!hasCurrentGroupping) {
            this.groupBy = this.origGroupByList[0];
            this.onGrouppingChanged(this.groupBy);
        }
    };
    ScheduleConsoleToolbarComponent.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.filtersControlKey, { value: { items: this.appliedFilters || [], isDirectCare: this.showOnlyDirectCare } }, StateResetTypes.All);
    };
    ScheduleConsoleToolbarComponent.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.filtersControlKey);
        if (state.value) {
            var items = state.value.items;
            if (_.isArray(items) && _.size(items) > 0) {
                this.appliedFilters = items;
            }
            else {
                this.appliedFilters = [];
            }
            this.showOnlyDirectCare = state.value.isDirectCare;
        }
        else {
            this.showOnlyDirectCare = false;
            this.appliedFilters = [];
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleToolbarComponent.prototype, "contextChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleToolbarComponent.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleToolbarComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        debounce(500),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], ScheduleConsoleToolbarComponent.prototype, "onConfigChanged", null);
    return ScheduleConsoleToolbarComponent;
}());
export { ScheduleConsoleToolbarComponent };
