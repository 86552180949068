<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">

    <div class="slx-content-toolbar-indents timecards-header">
      <div class="flex-horizontal">
        <slx-paycycle-dropdown class="slx-width-300 paycycles"
          [selectedPayCycle]="currentPayCycle"
          [orgLevelId]="orgLevelIdForPayCycles"
          (payCycleSelected)="onPayCycleSelected($event)"
          (payCyclesLoaded)="onPayCyclesLoaded($event)"
        ></slx-paycycle-dropdown>
      </div>
      <div class="flex-horizontal">
        <p class="timecards-info">{{ container?.infoDisplay }}</p>
      </div>
      <div class="flex-horizontal">
        <span class="tooltip_edit">
          <i style="font-style: normal;"
            [slxTooltip]="getShowHideToolTip()"
            tipPosition="left" aria-hidden="true">
            <slx-switcher 
              class="timecards-padd-r" 
              [ngModel]="isShowHideEmptyTimecard"
              (ngModelChange)="onVisibilityHideEmptyTimecardChanged($event)" 
              label="Hide Empty Timecard" 
              labelOn="Yes"
              labelOff="No">
            </slx-switcher>
          </i>
        </span>

          <slx-switcher
          class="timecards-padd-r switcher high-precision hidden-on-mobile"
          [ngModel]="showHighPrecision"
          (ngModelChange)="onVisibilityHighPrecisionChanged($event)"
          label="High Precision"
          labelOn="Yes"
          labelOff="No"
        ></slx-switcher>

        <slx-switcher
          *ngIf="!hideRates"
          class="timecards-padd-r"
          [ngModel]="isShowPayRates"
          (ngModelChange)="onVisibilityPayRatesChanged($event)"
          label="Show Pay Rates"
          labelOn="Yes"
          labelOff="No">
        </slx-switcher>

        <!-- <div class="timecards-padd-r timecards-align-bott">
          <button type="button"
            class="slx-button slx-only-icon slx-toolbar"
            (click)="onToggleView(!globalState?.flatMode)"
            [slxTooltip]="globalState?.flatMode ? 'Standard mode' : 'Expanded mode'"
            tipPosition="right"
          >
            <i *ngIf="globalState?.flatMode" class="fa fa-compress" aria-hidden="true"></i>
            <i *ngIf="!globalState?.flatMode" class="fa fa-expand" aria-hidden="true"></i>
          </button>
        </div> -->

        <div class="timecards-padd-r timecards-align-bott hidden-on-mobile">
          <button type="button"
            class="slx-button slx-only-icon slx-toolbar"
            [popper]="columnsToggler"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-start'"
            [popperDisableStyle]="true"
          >
            <i class="fa fa-cog" aria-hidden="true"></i>
          </button>
          <popper-content #columnsToggler class="slx-popper">
            <p class="timecards-popper-title">Employee Summary Columns to Display</p>
            <div class="columns-container">
              <div *ngFor="let column of globalState?.empColumns?.columns; let idx=index;">
                <slx-input-decorator *ngIf="isColumnVisible(column.name)" className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                  <slx-checkbox-input slx-input name="empColumn{{idx}}" fieldName="empColumn{{idx}}" [caption]="column.description" [(ngModel)]="column.displayed"
                      (ngModelChange)="onChangeEmpColumns($event, column)">
                  </slx-checkbox-input>
                </slx-input-decorator>
              </div>
            </div>
            <div class="button-container">
              <button class="slx-button slx-margin-r" type="button" (click)="selectAll($event)"> Select All </button>
              <button class="slx-button" type="button" (click)="clearAll()"> Clear All</button>
            </div>
          </popper-content>
        </div>

        <div class="timecards-padd-r timecards-align-bott hidden-on-mobile" [ngClass]="{'hidden': !globalState?.flatMode || !hasRulesDefinitions}">
          <button type="button"
            class="slx-button slx-only-icon slx-toolbar"
            [popper]="payCodeColumnsToggler"
            [popperTrigger]="'click'"
            [popperPlacement]="'bottom-start'"
            [popperDisableStyle]="true"
          >
            <i class="fas fa-sliders-h" aria-hidden="true"></i>
          </button>
          <popper-content #payCodeColumnsToggler class="slx-popper">
            <p class="timecards-popper-title">Pay Code Columns to Display</p>
            <div class="columns-container">
              <div *ngFor="let column of globalState?.payCodeColumns?.columns; let idx=index;">
                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                  <slx-checkbox-input slx-input name="payCodeColumn{{idx}}" fieldName="payCodeColumn{{idx}}" [caption]="column.description" [(ngModel)]="column.displayed"
                    (ngModelChange)="onChangePayCodeColumns($event, column)">
                  </slx-checkbox-input>
                </slx-input-decorator>
              </div>
            </div>
            <div class="button-container">
              <button class="slx-button slx-margin-r" type="button" (click)="selectAllPayCodes($event)"> Select All </button>
              <button class="slx-button" type="button" (click)="clearAllPayCodes()"> Clear All</button>
            </div>
          </popper-content>
        </div>

        <slx-action-list class="timecards-align-bott hidden-on-mobile">
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-right'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onExportToPayroll()" [disabled]="!container?.canExport || !this.isOrganizationOrgLevel || container?.isPayCycleSubmitted">
              Export to Payroll
              <span *ngIf="!container?.canExport">(No rights)</span>
              <span *ngIf="!this.isOrganizationOrgLevel">(No organization selected)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onViewSummary()" [disabled]="!container?.canViewSummary || !isDepartmentOrOrganizationOrgLevel ">
              View Summary <span *ngIf="!container?.canViewSummary ">(No rights)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onRecalculateTimecards()" [disabled]="!selectedRecords || selectedRecords.length===0 || !container?.canRecalculate || container?.isPayCycleSubmitted">
              Recalculate Timecards <span *ngIf="!container?.canRecalculate">(No rights)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onTimecardQueueClicked()">
              Timecard Queue
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onApproveTimecards()" [disabled]="!selectedRecords || selectedRecords.length===0 || !canMassApprove || !container?.canApprove || !isFinishedPayCycle || container?.isPayCycleSubmitted">
              Approve Timecards
              <span *ngIf="!container?.canApprove">(No rights)</span>
              <span *ngIf="container?.canApprove && !canMassApprove">(Has missing punches)</span>
              <span *ngIf="container?.canApprove && canMassApprove && !isFinishedPayCycle">(Payroll cycle is open)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onUnapproveTimecards()" [disabled]="!selectedRecords || selectedRecords.length===0 || !container?.canUnapprove || container?.isPayCycleSubmitted">
              Unapprove Timecards <span *ngIf="!container?.canUnapprove">(No rights)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onExportToExcel()" [disabled]="!container?.canExportToExcel">
              Export to Excel <span *ngIf="!container?.canExportToExcel">(No rights)</span>
            </slx-action-list-item>
            <slx-action-list-item (onClick)="onExportToPdf()" [disabled]="!container?.canExportToPDF">
              Export to PDF<span *ngIf="!container?.canExportToPDF">(No rights)</span>
            </slx-action-list-item>
            <slx-action-list-item *ngIf="container?.facilityHasPayUnits && currentPayCycle && orgLevelIdForPayCycles" [disabled]="!container?.hasMassAssignPayUnitsRights || !selectedEmpIds || selectedEmpIds.length===0 "
              [slxMassAssignPayUnitsModal]="{ orgLevelId: orgLevelIdForPayCycles, startDate: currentPayCycle.startDate, endDate: currentPayCycle.endDate, employeeIds: selectedEmpIds}">
              Mass Assign Pay Units <span *ngIf="!container?.hasMassAssignPayUnitsRights">(No rights)</span>
            </slx-action-list-item>

          </popper-content>
        </slx-action-list>
      </div>
    </div>
    <slx-single-message [isActive]="container?.isPayCycleSubmitted" level="warning">
      <div class="slx-single-message__message">Timecard is readonly.
        <span class="slx-single-message__description">Payroll has been submitted!</span>
      </div>
    </slx-single-message>
    <!-- <div class="slx-high-box__body slx-main-content-indents" *ngIf="!globalState?.flatMode">
      <slx-timecards-display-grid></slx-timecards-display-grid>
    </div> -->
    <div class="slx-high-box__body slx-main-content-indents timecards-body">
      <div>
        <h3 class="timecards-emp__title">
          <i class="ind-timecards-emp__title-i" aria-hidden="true" [ngClass]="iconClass"></i>
          <span>{{orgLevel?.name}}</span>
        </h3>
      </div>
      <slx-timecards-display-flat-grid class="slx-high-box__body"></slx-timecards-display-flat-grid>
    </div>
  </div>
</slx-spinner>
