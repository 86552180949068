import * as _ from 'lodash';
var UserPermissions = /** @class */ (function () {
    function UserPermissions(approveOwnTimecard, editOwnTimecard, editOwnProfile, approveOwnESSRequest) {
        this.approveOwnTimecard = approveOwnTimecard;
        this.editOwnTimecard = editOwnTimecard;
        this.editOwnProfile = editOwnProfile;
        this.approveOwnESSRequest = approveOwnESSRequest;
    }
    Object.defineProperty(UserPermissions.prototype, "canApproveOwnTimecard", {
        get: function () {
            return _.lowerCase(this.approveOwnTimecard) === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserPermissions.prototype, "canEditOwnTimecard", {
        get: function () {
            return _.lowerCase(this.editOwnTimecard) === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserPermissions.prototype, "canEditOwnProfile", {
        get: function () {
            return _.lowerCase(this.editOwnProfile) === 'true';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserPermissions.prototype, "canApproveOwnESSRequest", {
        get: function () {
            return _.lowerCase(this.approveOwnESSRequest) === 'true';
        },
        enumerable: true,
        configurable: true
    });
    return UserPermissions;
}());
export { UserPermissions };
