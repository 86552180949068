/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overtime-approvals.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/actions-list/actions-list.component.ngfactory";
import * as i3 from "../../../../common/components/actions-list/actions-list.component";
import * as i4 from "../../../../common/components/actions-list/actions-button/actions-button.component.ngfactory";
import * as i5 from "../../../../common/components/actions-list/actions-button/actions-button.component";
import * as i6 from "../../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i7 from "ngx-popper";
import * as i8 from "../../../../common/components/actions-list/actions-list-item/actions-list-item.component.ngfactory";
import * as i9 from "../../../../common/components/actions-list/actions-list-item/actions-list-item.component";
import * as i10 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i11 from "../../../../common/components/spinner/spinner.component";
import * as i12 from "../../../../organization/components/generic-list/generic-grid/generic-grid.component.ngfactory";
import * as i13 from "../../../../organization/components/generic-list/generic-grid/generic-grid.component";
import * as i14 from "../../../../organization/services/generic-list/generic-list-management.service";
import * as i15 from "@angular/forms";
import * as i16 from "../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component.ngfactory";
import * as i17 from "../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component";
import * as i18 from "@angular/common";
import * as i19 from "../../../../common/services/column-settings/column-management.service";
import * as i20 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i21 from "../../../../organization/services/generic-list/generic-list-api.service";
import * as i22 from "../../../../common/services/state-management/state-management.service";
import * as i23 from "../../../../common/services/component-state/component-state-storage.service";
import * as i24 from "./overtime-approvals.component";
import * as i25 from "../../services/overtime-approvals/overtime-approvals-api.service";
import * as i26 from "../../../../organization/services/lookup/lookup-api.service";
import * as i27 from "@angular/router";
import * as i28 from "../../../../organization/services/application-state-bus/application-state-bus.service";
import * as i29 from "../../../../time-and-attendance/services/timecards/timecards-api.service";
var styles_OvertimeApprovalsComponent = [i0.styles];
var RenderType_OvertimeApprovalsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OvertimeApprovalsComponent, data: {} });
export { RenderType_OvertimeApprovalsComponent as RenderType_OvertimeApprovalsComponent };
function View_OvertimeApprovalsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Payroll cycle or Timecard is in locked state"]))], null, null); }
function View_OvertimeApprovalsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "slx-actions-list", [], null, null, null, i2.View_ActionsListComponent_0, i2.RenderType_ActionsListComponent)), i1.ɵdid(2, 49152, null, 0, i3.ActionsListComponent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, 1, 3, "slx-actions-button", [], null, null, null, i4.View_ActionsButtonComponent_0, i4.RenderType_ActionsButtonComponent)), i1.ɵdid(4, 49152, null, 0, i5.ActionsButtonComponent, [], { popperContent: [0, "popperContent"], popperPosition: [1, "popperPosition"] }, null), (_l()(), i1.ɵted(-1, 0, [" Actions "])), (_l()(), i1.ɵeld(6, 0, null, 0, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, 1, 7, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_PopperContent_0, i6.RenderType_PopperContent)), i1.ɵdid(8, 180224, [["popperContent", 4]], 0, i7.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "slx-actions-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onExportToExcel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ActionsListItemComponent_0, i8.RenderType_ActionsListItemComponent)), i1.ɵdid(10, 49152, null, 0, i9.ActionsListItemComponent, [i7.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, ["Export to Excel"])), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "slx-actions-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onExportToPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ActionsListItemComponent_0, i8.RenderType_ActionsListItemComponent)), i1.ɵdid(13, 49152, null, 0, i9.ActionsListItemComponent, [i7.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, ["Export to PDF"]))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8); var currVal_1 = "bottom-end"; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_OvertimeApprovalsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 20, "slx-spinner", [["novalidate", ""]], null, null, null, i10.View_SpinnerComponent_0, i10.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i11.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 18, "slx-generic-grid", [["gridKey", "OVERTIME_APPROVALS"]], null, [[null, "selectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.onSelectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_GenericGridComponent_0, i12.RenderType_GenericGridComponent)), i1.ɵdid(4, 245760, [[1, 4]], 0, i13.GenericGridComponent, [i14.GenericListManagementService, i1.ChangeDetectorRef], { gridKey: [0, "gridKey"], genericGridConfig: [1, "genericGridConfig"], columnCustomCssClass: [2, "columnCustomCssClass"] }, { selectionChanged: "selectionChanged" }), (_l()(), i1.ɵeld(5, 0, null, 0, 16, "form", [["class", "overtime-form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i15.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(7, 4210688, [["form", 4]], 0, i15.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i15.ControlContainer, null, [i15.NgForm]), i1.ɵdid(9, 16384, null, 0, i15.NgControlStatusGroup, [[4, i15.ControlContainer]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 1, "slx-date-range-ngx", [["endDatePlaceholder", "End Date"], ["name", "startEndDate"], ["startDatePlaceholder", "Start Date"]], null, [[null, "rangeDateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rangeDateChanged" === en)) {
        var pd_0 = (_co.onFilterDateChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_DateRangeNgxComponent_0, i16.RenderType_DateRangeNgxComponent)), i1.ɵdid(11, 573440, null, 0, i17.DateRangeNgxComponent, [], { startDate: [0, "startDate"], endDate: [1, "endDate"], startDatePlaceholder: [2, "startDatePlaceholder"], endDatePlaceholder: [3, "endDatePlaceholder"] }, { rangeDateChanged: "rangeDateChanged" }), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "spacer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvertimeApprovalsComponent_1)), i1.ɵdid(14, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "overtime-form-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "theme-button-apply main-action-button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUnapprove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Un-Approve Overtime"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "theme-button-apply main-action-button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onApprove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Approve Overtime"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OvertimeApprovalsComponent_2)), i1.ɵdid(21, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = "OVERTIME_APPROVALS"; var currVal_2 = _co.gridConfig; var currVal_3 = _co.pdfColorClass; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_11 = _co.startDate; var currVal_12 = _co.endDate; var currVal_13 = "Start Date"; var currVal_14 = "End Date"; _ck(_v, 11, 0, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.someOvertimesAreLocked; _ck(_v, 14, 0, currVal_15); var currVal_18 = !_co.isMobile; _ck(_v, 21, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵnov(_v, 9).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 9).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 9).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 9).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 9).ngClassValid; var currVal_9 = i1.ɵnov(_v, 9).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 9).ngClassPending; _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_16 = !_co.canMakeUnapproveAction; _ck(_v, 16, 0, currVal_16); var currVal_17 = !_co.canMakeApproveAction; _ck(_v, 18, 0, currVal_17); }); }
export function View_OvertimeApprovalsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-overtime-approvals", [], null, null, null, View_OvertimeApprovalsComponent_0, RenderType_OvertimeApprovalsComponent)), i1.ɵprd(4608, null, i19.ColumnManagementService, i19.ColumnManagementService, [i20.ColumnSettingsStorageService]), i1.ɵprd(512, null, i14.GenericListManagementService, i14.GenericListManagementService, [i21.GenericListApiService]), i1.ɵprd(512, null, i22.StateManagementService, i22.StateManagementService, [i23.ComponentStateStorageService, i20.ColumnSettingsStorageService]), i1.ɵdid(4, 245760, null, 0, i24.OvertimeApprovalsComponent, [i14.GenericListManagementService, i25.OvertimeApprovalsApiService, i26.LookupApiService, i22.StateManagementService, i23.ComponentStateStorageService, i27.ActivatedRoute, i27.Router, i28.ApplicationStateBusService, i29.TimecardsApiService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var OvertimeApprovalsComponentNgFactory = i1.ɵccf("slx-overtime-approvals", i24.OvertimeApprovalsComponent, View_OvertimeApprovalsComponent_Host_0, {}, {}, []);
export { OvertimeApprovalsComponentNgFactory as OvertimeApprovalsComponentNgFactory };
