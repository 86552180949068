<kendo-chart class="front-chart" [categoryAxis]="{ categories: categories }">
  <kendo-chart-legend position="left"></kendo-chart-legend>

  <kendo-chart-series>
    <kendo-chart-series-item
      type="column"
      color="#E3E3E3"
      [border]="{ width: 0 }"
      [data]="idealHoursByCategory"
      [zIndex]="1"
      [highlight]="{ visible: false }"
    ></kendo-chart-series-item>

    <kendo-chart-series-item
      *ngFor="let series of chartSeries; let i = index"
      type="area"
      field="value"
      [data]="series.dataItems"
      [name]="series.model.label"
      [color]="series.model.color"
      [opacity]="0.7"
      [zIndex]="chartSeries?.length - i + 1"
      [visibleInLegend]="true"
    >
      <kendo-chart-series-item-tooltip background="#ffffff">
        <ng-template kendoChartSeriesTooltipTemplate let-dataItem="dataItem">
          <slx-schedule-console-overview-tooltip
            [config]="config"
            [model]="dataItem.model"
          ></slx-schedule-console-overview-tooltip>
        </ng-template>
      </kendo-chart-series-item-tooltip>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>

<div class="mobile-view">
  <div class="headers">
    <div class="positions">Days of Week</div>

    <div class="days">
      <div class="day" *ngFor="let day of categories">{{ day | slice:0:1 }}</div>
    </div>
  </div>

  <div class="content">
    <div class="positions">
      <div class="position" *ngFor="let model of models">
        {{ model.label }}
      </div>
    </div>

    <div class="week">
      <div class="day" *ngFor="let day of categories; let i = index">
        <div class="indicator" *ngFor="let seriesModel of models">
          <div
            class="indicator-color"
            [style.background]="seriesModel.models[i]?.color?.fontColor1"
            [popper]="popperContent"
            popperTrigger="click"
            popperPlacement="bottom-start"
            popperDisableStyle="true"
          ></div>

          <popper-content #popperContent class="popper-white-background popper-squared-border">
            <slx-schedule-console-overview-tooltip
              [config]="config"
              [model]="seriesModel.models[i]"
            ></slx-schedule-console-overview-tooltip>
          </popper-content>
        </div>
      </div>
    </div>
  </div>
</div>
