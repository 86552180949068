import { Injectable } from '@angular/core';
import { ToolbarBaseService } from '../../../core/services/index';

@Injectable()
export class DailyTimecardToolbarService extends ToolbarBaseService<any>  {

    constructor() {
        super();
      }

}
