import { Injectable } from '@angular/core';

import { MasterScheduleData } from '../../models/index';

import * as _ from 'lodash';
import {
  IMasterScheduleRow, IMasterScheduleEntryRow, IMasterScheduleSubtotalRow, IMasterScheduleTotalRow,
  MasterScheduleEntryRow, MasterScheduleShiftInfo, MasterScheduleEntryCell, IMasterScheduleEntryCell, MasterScheduleSubtotalRow, MasterScheduleTotalRow,
  MasterScheduleTotalCell, MasterScheduleRecordType, IMasterScheduleShiftInfo, IMasterScheduleTotalCell,
  ScheduleSubtotal, ScheduleTotal, DayTotal,
  isMasterScheduleEntryRow, isMasterScheduleSubtotalRow, isMasterScheduleTotalRow, ISchedulePosition
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { EmployeesStateSelector } from './employees-state.selector';

@Injectable()
export class EmployeesSelector {

  public static restoreMasterScheduleEntryRow(e: IMasterScheduleEntryRow): MasterScheduleEntryRow {
    let row = new MasterScheduleEntryRow();
    row.id = e.id;
    row.name = e.name;
    row.recordType = e.recordType;
    row.sortSequence = e.sortSequence;
    row.hireDateStr = e.hireDateStr;
    row.dateTerminatedStr = e.dateTerminatedStr;
    row.activationDateStr = e.activationDateStr;
    row.hireDate = row.hireDateStr ? dateTimeUtils.convertFromDtoString(row.hireDateStr) : e.hireDate;
    row.dateTerminated = row.dateTerminatedStr ? dateTimeUtils.convertFromDtoString(row.dateTerminatedStr) : e.dateTerminated;
    row.activationDate = row.activationDateStr ? dateTimeUtils.convertFromDtoString(row.activationDateStr) : e.activationDate;
    row.positionGroupName = e.positionGroupName;
    row.position = EmployeesStateSelector.mapPosition(e.position);
    row.primaryPosition = EmployeesStateSelector.mapPosition(e.position);
    row.secondaryPositions = _.map(e.secondaryPositions, (p: ISchedulePosition) => EmployeesStateSelector.mapPosition(p));
    row.homeShiftName = e.homeShiftName;
    row.homeShiftId = e.homeShiftId;
    row.sumShiftsPerWeek = e.sumShiftsPerWeek;
    row.avgWeeklyRotationsHrs = e.avgWeeklyRotationsHrs;
    row.isAgency = e.isAgency;
    row.hasRotations = e.hasRotations;
    row.seniority = e.seniority;
    row.cell = {};
    row.weeklyTotals = {};
    row.employeeType = e.employeeType;
    return row;
  }

  public static restoreMasterScheduleEntryCell(c: IMasterScheduleEntryCell): MasterScheduleEntryCell {
    let cell = new MasterScheduleEntryCell();
    cell.dateOn = c.dateOn;
    cell.isPosted = c.isPosted;
    cell.isReplacing = c.isReplacing;
    cell.isReplaced = c.isReplaced;
    cell.isPreScheduledOvertime = c.isPreScheduledOvertime;
    cell.isOvertimeApproach = c.isOvertimeApproach;
    cell.isAgency = c.isAgency;
    cell.hasAbsence = c.hasAbsence;
    cell.hasPartialAbsence = c.hasPartialAbsence;
    cell.scheduledInDifferentShiftGroup = c.scheduledInDifferentShiftGroup;
    cell.shiftsInfo = [];
    return cell;
  }

  public static restoreMasterScheduleShiftInfo(i: IMasterScheduleShiftInfo): MasterScheduleShiftInfo {
    let info = new MasterScheduleShiftInfo();
    info.empoyeeId = i.empoyeeId;
    info.unitId = i.unitId;
    info.unitName = i.unitName;
    info.shiftName = i.shiftName;
    info.shiftId = i.shiftId;
    info.shiftDefStart = i.shiftDefStart;
    info.shiftDefEnd = i.shiftDefEnd;
    info.shiftStart = i.shiftStart;
    info.shiftEnd = i.shiftEnd;
    info.absenceCode = i.absenceCode;
    info.absenceName = i.absenceName;
    info.partialAbsenceCode = i.partialAbsenceCode;
    info.partialAbsenceName = i.partialAbsenceName;
    info.partialAbsenceStart = i.partialAbsenceStart;
    info.partialAbsenceEnd = i.partialAbsenceEnd;
    info.partialAbsenceDuration = i.partialAbsenceDuration;
    info.constraintId = i.constraintId;
    info.constraintCode = i.constraintCode;
    info.constraintCountAs = i.constraintCountAs;
    info.position = EmployeesStateSelector.mapPosition(i.position);
    info.departmentOrgLevelId = i.departmentOrgLevelId;
    info.departmentId = i.departmentId;
    info.departmentName = i.departmentName;
    info.duration = i.duration;
    info.prescheduledOvertimeDuration = i.prescheduledOvertimeDuration;
    info.prescheduledOvertimeStart = i.prescheduledOvertimeStart;
    info.prescheduledOvertimeEnd = i.prescheduledOvertimeEnd;
    return info;
  }

  public getMasterScheduleEntryRow(e: IMasterScheduleEntryRow): MasterScheduleEntryRow {
    let row = EmployeesSelector.restoreMasterScheduleEntryRow(e);
    _.forIn(e.cell, (c: IMasterScheduleEntryCell, key: string) => {
      row.cell[key] = EmployeesSelector.restoreMasterScheduleEntryCell(c);
      _.forEach(c.shiftsInfo, (i: IMasterScheduleShiftInfo) => {
        let info = EmployeesSelector.restoreMasterScheduleShiftInfo(i);
        row.cell[key].shiftsInfo.push(info);
      });
    });
    return row;
  }

  public getMasterScheduleSubtotalRow(e: IMasterScheduleSubtotalRow, isViewFteTotal?: boolean): MasterScheduleSubtotalRow {
    let row = new MasterScheduleSubtotalRow();
    row.id = e.id;
    row.name = e.name;
    row.recordType = e.recordType;
    row.sortSequence = e.sortSequence;
    row.positionGroupName = e.positionGroupName;
    row.position = EmployeesStateSelector.mapPosition(e.position);
    row.cell = {};
    _.forIn(e.cell, (c: IMasterScheduleTotalCell, key: string) => {
      row.cell[key] = new MasterScheduleTotalCell();
      row.cell[key].dateOn = c.dateOn;
      row.cell[key].totalValue = c.totalValue;
      row.cell[key].fteTotalValue = c.fteTotalValue;
      row.cell[key].isViewFteTotal = !!isViewFteTotal;
    });
    return row;
  }

  public getMasterScheduleTotalRow(e: IMasterScheduleTotalRow, notAccessible: boolean, isViewFteTotal?: boolean): MasterScheduleTotalRow {
    let row = new MasterScheduleTotalRow();
    row.id = e.id;
    row.name = e.name;
    row.recordType = e.recordType;
    row.sortSequence = e.sortSequence;
    row.cell = {};
    _.forIn(e.cell, (c: IMasterScheduleTotalCell, key: string) => {
      row.cell[key] = new MasterScheduleTotalCell();
      row.cell[key].dateOn = c.dateOn;
      row.cell[key].totalValue = c.totalValue;
      row.cell[key].fteTotalValue = c.fteTotalValue;
      if (notAccessible) {
        row.cell[key].totalValue = 0;
        row.cell[key].fteTotalValue = 0;
      } else {
        if (row.name === 'Total') {
          row.cell[key].isViewFteTotal = !!isViewFteTotal;
        }
      }
    });
    return row;
  }

  public getEmployeeRows(stateRows: any): IMasterScheduleEntryRow[] {
    let rows: IMasterScheduleEntryRow[] = [];
    let row: IMasterScheduleEntryRow;
    _.forIn(stateRows, (e: IMasterScheduleRow, key: string) => {
      if (isMasterScheduleEntryRow(e)) {
        row = this.getMasterScheduleEntryRow(e);
        rows.push(row);
      }
    });
    return rows;
  }

  public getTotals(stateRows: any, notAccessibleTotals: string[] = []): IMasterScheduleTotalRow[] {
    let rows: IMasterScheduleTotalRow[] = [];
    let row: IMasterScheduleTotalRow;
    _.forIn(stateRows, (e: IMasterScheduleRow, key: string) => {
      if (isMasterScheduleTotalRow(e)) {
        row = this.getMasterScheduleTotalRow(e, _.includes(notAccessibleTotals, key));
      } else if (isMasterScheduleSubtotalRow(e)) {
        row = this.getMasterScheduleSubtotalRow(e);
      }
      if (row) {
        rows.push(row);
      }
    });
    return rows;
  }

  public getTotalAfterViewChange(stateRows: any, notAccessibleTotals: string[] = [], isViewFteTotal?: boolean) {
    let rows: IMasterScheduleTotalRow[] = [];
    let row: IMasterScheduleTotalRow;
    if (isMasterScheduleTotalRow(stateRows)) {
      row = this.getMasterScheduleTotalRow(stateRows, _.includes(notAccessibleTotals, stateRows.key), isViewFteTotal);
    }
    if (row) {
      rows.push(row);
    }
    return rows;
  }

  public getSubtotals(stateRows: any): IMasterScheduleSubtotalRow[] {
    let rows: IMasterScheduleSubtotalRow[] = [];
    let row: IMasterScheduleSubtotalRow;
    _.forIn(stateRows, (e: IMasterScheduleRow, key: string) => {
      if (isMasterScheduleSubtotalRow(e)) {
        row = this.getMasterScheduleSubtotalRow(e);
      }
      if (row) {
        rows.push(row);
      }
    });
    return rows;
  }

  public getSubtotalAfterViewChange(stateRows: any, isViewFteTotal?: boolean) {
    let rows: IMasterScheduleSubtotalRow[] = [];
    let row: IMasterScheduleSubtotalRow;
    if (isMasterScheduleSubtotalRow(stateRows)) {
      row = this.getMasterScheduleSubtotalRow(stateRows, isViewFteTotal);
    }
    if (row) {
      rows.push(row);
    }
    return rows;
  }

}

