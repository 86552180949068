import { Injectable } from '@angular/core';

import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';

import { IPayloadAction } from '../../../state-model/models/index';
import { EmployeeListActions } from '../store/index';
import { Observable } from 'rxjs/Observable';
import { SessionActions } from '../../../authentication/actions/index';
import { ApplicationActions, SidebarActions } from '../../../portal/actions/index';

@Injectable()
export class EmployeeListEpics {

  public resetView = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.filter(({ type, payload }: IPayloadAction) => (type === SessionActions.USER_LOGGED_OUT && payload === false)/*|| type === SidebarActions.RIGHT_MENU_ITEM_CHANGE*/)
      .mergeMap((action: IPayloadAction) => {
        return Observable.of({
          type: EmployeeListActions.RESET_VIEW
        });
      });
  }
/*
  public resetIsActive = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const resetPayCycle$: Observable<IPayloadAction> = action$.filter(
      ({ type }: { type: string }) => type === SidebarActions.RIGHT_MENU_ITEM_CHANGE);
    return resetPayCycle$.mergeMap((action: IPayloadAction) => {
      return Observable.of(
        {
          type: EmployeeListActions.RESET_IS_ACTIVE
        });
    });
  }*/
}
