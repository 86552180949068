import { ElementRef, forwardRef } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var CHEKBOX_TOGGLE_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return CheckboxToggleComponent; }),
    multi: true
};
var CheckboxToggleComponent = /** @class */ (function () {
    function CheckboxToggleComponent(elementRef) {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.elementName = this.getUniqueId('toggle');
        this.className = 'toggle-left';
    }
    Object.defineProperty(CheckboxToggleComponent.prototype, "readonly", {
        set: function (ro) {
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxToggleComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    CheckboxToggleComponent.prototype.writeValue = function (value) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    };
    CheckboxToggleComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = function (value) { Promise.resolve(null).then(function () { return fn(value); }); };
    };
    CheckboxToggleComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = function () { Promise.resolve(null).then(function () { return fn(); }); };
    };
    //TODO: Need to encapsulate this function to some kind of service
    CheckboxToggleComponent.prototype.getUniqueId = function (name) {
        return name + "_" + Math.random().toString(36).substr(2, 9);
    };
    return CheckboxToggleComponent;
}());
export { CheckboxToggleComponent };
