/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scheduled-generate-rotation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scheduled-generate-rotation.component";
import * as i3 from "../../../../common/services/modal/modal.service";
import * as i4 from "../../../../common/models/dialog-options";
import * as i5 from "../../../services/scheduled-rotation-template/schedule-rotations-management.service";
var styles_ScheduledGenerateRotationComponent = [i0.styles];
var RenderType_ScheduledGenerateRotationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduledGenerateRotationComponent, data: {} });
export { RenderType_ScheduledGenerateRotationComponent as RenderType_ScheduledGenerateRotationComponent };
export function View_ScheduledGenerateRotationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "employee-rotation"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["You have modified ", " employee rotations, select when you would like this rotation update to be effective:"])), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "dialog-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "radio-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "input", [["checked", "checked"], ["id", "Current-Schedule"], ["name", "Schedule"], ["type", "radio"], ["value", "1"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.cycleChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [["for", "Current-Schedule"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Current Date"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "radio-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "input", [["id", "Next-Schedule"], ["name", "Schedule"], ["type", "radio"], ["value", "2"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.cycleChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["for", "Next-Schedule"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Next Schedule Cycle"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "information mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, [" Schedule cycle start date is: ", " "])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelGenerateRotation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Discard "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveGenerateRotation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Generate Rotation "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.totalEmployees; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.scheduleCycleStartDate; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.isDisabled(); _ck(_v, 18, 0, currVal_2); }); }
export function View_ScheduledGenerateRotationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-scheduled-generate-rotation", [], null, null, null, View_ScheduledGenerateRotationComponent_0, RenderType_ScheduledGenerateRotationComponent)), i1.ɵdid(1, 114688, null, 0, i2.ScheduledGenerateRotationComponent, [i3.ModalService, i4.DialogOptions, i5.ScheduleRotationsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduledGenerateRotationComponentNgFactory = i1.ɵccf("slx-scheduled-generate-rotation", i2.ScheduledGenerateRotationComponent, View_ScheduledGenerateRotationComponent_Host_0, {}, {}, []);
export { ScheduledGenerateRotationComponentNgFactory as ScheduledGenerateRotationComponentNgFactory };
