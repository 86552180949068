
import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { schedulerConfig } from '../../scheduler.config';
import { DetailsPartner } from '../../models/index';
import { CacheType } from '../../../common/models/cache/cache-definition';
import { AgencyShiftDetails } from '../../models/agency-staffing-console/agency-shift-details';
import { AgencyStaffingConsoleMapService } from './agency-staffing-console-map.service';

@Injectable({
  providedIn: 'root'
})
export class AgencyStaffingConsoleApiService {

  constructor( private apiUtilService: ApiUtilService,
    private urlParamService: UrlParamsService,
    private agencyMapService: AgencyStaffingConsoleMapService,
    private lookupService: LookupService,
    private cacheUtilService: CacheUtilService) { }
  
  public getShiftDetails(startDate:string, endDate:string): Promise<AgencyShiftDetails[]> {
    Assert.isNotNull(startDate);
    Assert.isNotNull(endDate);
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.orglevel.agencyConsole.root}/${schedulerConfig.api.schedule.orglevel.agencyConsole.startDate}/${startDate}/${schedulerConfig.api.schedule.orglevel.agencyConsole.endDate}/${endDate}`;
    let request: HttpRequest<AgencyShiftDetails[]> = new HttpRequest('GET', url);
    // clear cache
    this.cacheUtilService.delete({ key: url }, '');
    let promise: Promise<AgencyShiftDetails[]> = this.apiUtilService
      .cachedRequest<DetailsPartner[], Meta>(request, CacheType.longTerm)
        .then((response: ResponseBody<any, Meta>) =>  this.agencyMapService.mapShiftDetails(response.data));
      return promise;
  }

  public getShiftSummary(startDate:string, endDate:string, custamerId:string):Promise<any> {
    Assert.isNotNull(startDate);
    Assert.isNotNull(endDate);
    Assert.isNotNull(custamerId);
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.orglevel.agencyConsole.root}/${schedulerConfig.api.schedule.orglevel.agencyConsole.shortInfo}/${schedulerConfig.api.schedule.orglevel.agencyConsole.startDate}/${startDate}/${schedulerConfig.api.schedule.orglevel.agencyConsole.endDate}/${endDate}`;
    let request: HttpRequest<AgencyShiftDetails[]> = new HttpRequest('GET', url);
    // clear cache
    this.cacheUtilService.delete({ key: url }, '');
    let promise: Promise<DetailsPartner[]> = this.apiUtilService
      .cachedRequest<DetailsPartner[], Meta>(request, CacheType.longTerm)
        .then((response: ResponseBody<any, Meta>) =>  this.agencyMapService.mapShiftShortDetails(response.data));
      return promise;
 
  }
 
 
  public exportToPdf(data:string[]): Promise<void> {
    return;
  }
  public exportToExcel(data:string[]):Promise<void>{
    return;
  }
  public exportToPdfAgencyDetails(data:string[]):Promise<void> {
    return
  }


}
