import { ITimecardsState, ITimecardsDisplayState, IIndividualTimecardsState, ITimecardsDisplayPayCycle } from './timecards.types';
import { Map } from 'immutable';
import { makeTypedMapFactory } from '../../../framework/immutable-utils/index';

export const timecardsDisplayStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  lastViewedEmployee: 0,
  lastViewedPage: 0,
  lastSelectedEntries: [],
  isShowPayRates: false,
  isShowHighPrecision: false,
  flatMode: true,
  payCycle: null,
  orgLevel: null,
  gridState: null,
  selectedEmployeeId: null
});
export const individualTimecardsStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  isShowHighPrecision: false,
  isShowPayRates: false,
  empColumnStates: {firstInit: true},
  payCodeColumnStates: {firstInit: true},
  standardScrollPosition: {},
  extendedScrollPosition: {}
});

export const INITIAL_STATE_TIMECARDS_DISPLAY_STATE: Map<string, any> = timecardsDisplayStateFactory();
export const INITIAL_STATE_INDIVIDUAL_TIMECARDS_STATE: Map<string, any> = individualTimecardsStateFactory();

export const timecardsStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  timecardsDisplay: INITIAL_STATE_TIMECARDS_DISPLAY_STATE,
  individualTimecards: INITIAL_STATE_INDIVIDUAL_TIMECARDS_STATE
});

export const INITIAL_STATE_TIMECARDS_STATE: Map<string, any> = timecardsStateFactory();
