import { Component, OnInit } from '@angular/core';
import { WFMFlagsSyncStatus, WFMStopSyncDetails } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { WfmResponseNavigationService } from '../../../../configuration/services/wfm-response-navigation.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';

@Component({
  selector: 'slx-sync-dashboard-migration',
  templateUrl: './sync-dashboard-migration.component.html',
  styleUrls: ['./sync-dashboard-migration.component.scss']
})
export class SyncDashboardMigrationComponent implements OnInit {
  constructor(
    private wfmsyncService: WfmSyncService,
    private router: Router,
    private route: ActivatedRoute,
    appSettingManageService: AppSettingsManageService) {
      this.appSettingManageService = appSettingManageService;
  }

  public onGoing: boolean = false;
  public migration: boolean = false;
  public pause: boolean = false;
  public stop:boolean=false;
  public customerId:string;
  public Data: WFMFlagsSyncStatus;
  public stopSync:WFMStopSyncDetails;
  private appSettingManageService: AppSettingsManageService;
  public nextgenPhasedRollout: boolean = false;
  public isNextgenPayrollEnabled: boolean = false;

  ngOnInit() {
    this.getSettings();
    this.res();
    this.getStopData();
  }

  public res() {
    this.wfmsyncService.getSyncStatus().then((value2: any) => {
      this.Data = value2.data;
      this.onGoing = this.Data.ongoingSync;
      this.migration = this.Data.migrationSync;
      this.pause = this.Data.pauseSync;
    });

  }

  get isMigrationDisable() {
    if ( this.onGoing == true && this.migration==false) {
      return true;
    }
    return false;
  }

  public post() {
    let req: WFMFlagsSyncStatus=new WFMFlagsSyncStatus();
    if (this.Data.migrationSync == false && this.migration == true && this.onGoing == true) {
      this.migration = false;
      return;
    }

    if (this.onGoing == true ) {
      req.ongoingSync=true;
      req.migrationSync=false;
      
    }
      else if(this.migration==true){
        req.ongoingSync=false;
        req.migrationSync=true;
    }else  if(this.migration==false){
      req.ongoingSync=true;
      req.migrationSync=false;
  }else{

    }
    req.pauseSync=this.pause
    this.wfmsyncService.postSyncStatus(req).then((value2: any) => {
    if(req.ongoingSync==true || req.migrationSync==false){
      const params: Params = {};
      params.orgLevelId = this.getOrglevelIdFromRoute();
      let url = `apps/ngp/sync_dashboard?orgLevelId=${params.orgLevelId}`;

      this.router.navigateByUrl(url);

      window.history.replaceState(null, null, `#/${url}`);

      window.location.reload();
    }    
    this.res();
    }).catch((reason: any) => {
      this.res();
    });
  }

  public getStopData() {
    this.wfmsyncService.getStopSyncStatus().then((value2: any) => {
      this.stopSync = value2.data;
     this.customerId=this.stopSync.customerId;
     this.stop=this.stopSync.stopSync;
    });

  }

  public stopData() {
    let req1: WFMStopSyncDetails=new WFMStopSyncDetails();
    req1.customerId=this.customerId;
    req1.stopSync=this.stop;
    this.wfmsyncService.stopSyncStatus(req1).then((value2: any) => {
      this.getStopData()
    }).catch((reason: any) => {
      this.res();
    });
  }
  private getOrglevelIdFromRoute(): number {
    const id: string = this.route.snapshot.queryParamMap.get('orgLevelId');
    return +id;
  }
        
  public async getSettings(): Promise<void> {
      let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
      this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
      this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
  }
}
