import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ShiftDefinition, Position, Department, ConstraintDefinition, LocationUnit, ScheduleAbsence } from '../../../../../organization/models/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { IScheduleEntryShiftMainContainer } from '../../../../models/schedule-entry/ischedule-entry-shift-main-container';

@Component({
    moduleId: module.id,
    selector: 'slx-scheduled-shift-main-section',
    templateUrl: 'scheduled-shift-main-section.component.html',
    styleUrls: [
        'scheduled-shift-main-section.component.scss'
    ]
})
export class ScheduledShiftMainSectionComponent {

    @Input()
    public index: number = -1;

    @Input()
    public set container(value: IScheduleEntryShiftMainContainer) {
        this.m_container = value;
    }

    @Output()
    public onShiftChanged: EventEmitter<ShiftDefinition>;
    @Output()
    public onPositionChanged: EventEmitter<Position>;
    @Output()
    public onUnitChanged: EventEmitter<LocationUnit>;
    @Output()
    public onConstraintChanged: EventEmitter<ConstraintDefinition>;
    @Output()
    public onAbsenceChanged: EventEmitter<ScheduleAbsence>;
    @Output()
    public onStartDateChanged: EventEmitter<Date>;
    @Output()
    public onEndDateChanged: EventEmitter<Date>;
    @Output()
    public onShiftHoursChanged: EventEmitter<number>;
    @Output()
    public onUnpaidHoursChanged: EventEmitter<number>;

    public appConfig: IApplicationConfig;

    public get container(): IScheduleEntryShiftMainContainer {
        return this.m_container;
    }

    private m_container: IScheduleEntryShiftMainContainer;

    constructor() {
        this.appConfig = appConfig;
        this.onPositionChanged = new EventEmitter<Position>();
        this.onShiftChanged = new EventEmitter<ShiftDefinition>();
        this.onUnitChanged = new EventEmitter<LocationUnit>();
        this.onConstraintChanged = new EventEmitter<ConstraintDefinition>();
        this.onAbsenceChanged = new EventEmitter<ScheduleAbsence>();
        this.onStartDateChanged = new EventEmitter<Date>();
        this.onEndDateChanged = new EventEmitter<Date>();
        this.onShiftHoursChanged = new EventEmitter<number>();
        this.onUnpaidHoursChanged = new EventEmitter<number>();
    }

    public positionChanged(position: Position): void {
        this.onPositionChanged.next(position);
    }

    public shiftChange(shift: ShiftDefinition): void {
        this.onShiftChanged.next(shift);
    }

    public unitChange(unit: LocationUnit): void {
        this.onUnitChanged.next(unit);
    }

    public constraintChange(constraint: ConstraintDefinition): void {
        this.onConstraintChanged.next(constraint);
    }

    public absenceChange(absence: ScheduleAbsence): void {
        this.onAbsenceChanged.next(absence);
    }

    public onShiftStartDateChanged(): void {
        this.onStartDateChanged.next(this.m_container.shiftStartDate);
    }

    public onShiftEndDateChanged(): void {
        this.onEndDateChanged.next(this.m_container.shiftEndDate);
    }

    public shiftPaidTimeChanged(value: number): void {
        this.onShiftHoursChanged.next(this.m_container.shiftHours);
    }

    public shiftUnpaidTimeChanged(value: number): void {
        this.onUnpaidHoursChanged.next(this.m_container.unpaidHours);
    }

}
