<form novalidate #form="ngForm">
  <div class="col-md-12">
    <h4 class="brcramp">
      List A
      <span class="clear" *ngIf="state.isEditMode && isEditable" (click)="clearI9ListA()">clear</span>
    </h4>
    <hr>
    <div class="col-xs-12">
      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listA" fieldName="listAUsPassport"
          [option]="I9ListATypes.UsPassport" caption="US Passport" [(ngModel)]="i9?.listA.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listA" fieldName="listAUsCitizenshipCertificate"
          [option]="I9ListATypes.UsCitizenshipCertificate" caption="Certificate of US Citizenship" [(ngModel)]="i9?.listA.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listA" fieldName="listANaturalizationCertificate"
          [option]="I9ListATypes.NaturalizationCertificate" caption="Certificate of Naturalization" [(ngModel)]="i9?.listA.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listA" fieldName="listAForeignPassportWithEmploymentAuth"
          [option]="I9ListATypes.ForeignPassportWithEmploymentAuth" caption="Foreign Passport with Employment Auth" 
          [(ngModel)]="i9?.listA.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listA" fieldName="listAAlienRegistrationCard"
          [option]="I9ListATypes.AlienRegistrationCard" caption="Alien Registration Card" [(ngModel)]="i9?.listA.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" maxlength="255" name="listADocumentId" placeholder="Document Id"
            [(ngModel)]="i9?.listA.documentId" [readonly]="!state.isEditMode">
        </slx-input-decorator>
      </div>

      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input date
            [(ngModel)]="i9?.listA.documentExpiration"
            [readonly]="!state.isEditMode"
            placeholder="Document Expiration"
            name="listADocumentExpiration"
          ></slx-date-picker-ngx>
        </slx-input-decorator>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <h4 class="brcramp">
      List B
      <span class="clear" *ngIf="state.isEditMode && isEditable" (click)="clearI9ListB()">clear</span>
    </h4>
    <hr>
    <div class="col-xs-12">
      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listB" fieldName="listBStateIssuedDriversLicense"
          [option]="I9ListBTypes.StateIssuedDriversLicense" caption="State Issued Drivers License" [(ngModel)]="i9?.listB.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listB" fieldName="listBUsMilitaryCard"
          [option]="I9ListBTypes.UsMilitaryCard" caption="US Military Card" [(ngModel)]="i9?.listB.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listB" fieldName="listBIsOther"
          [option]="I9ListBTypes.IsOther" caption="Other (Specify Document and Issuing Authority)" [(ngModel)]="i9?.listB.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" maxlength="255" name="listBOther" placeholder="Other"
            [(ngModel)]="i9?.listB.other" [readonly]="!state.isEditMode">
        </slx-input-decorator>
      </div>

      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" maxlength="255" name="listBDocumentId" placeholder="Document Id"
            [(ngModel)]="i9?.listB.documentId" [readonly]="!state.isEditMode">
        </slx-input-decorator>
      </div>

      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input date
            [(ngModel)]="i9?.listB.documentExpiration"
            [readonly]="!state.isEditMode"
            placeholder="Document Expiration"
            name="listBDocumentExpiration"
          ></slx-date-picker-ngx>
        </slx-input-decorator>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <h4 class="brcramp">
      List C
      <span class="clear" *ngIf="state.isEditMode && isEditable" (click)="clearI9ListC()">clear</span>
    </h4>
    <hr>
    <div class="col-xs-12">
      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listC" fieldName="listCOriginalSSNCard"
          [option]="I9ListCTypes.OriginalSSNCard" caption="Original SSN Card" [(ngModel)]="i9?.listC.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listC" fieldName="listCBirthCertificate"
          [option]="I9ListCTypes.BirthCertificate" caption="Birth Certificate" [(ngModel)]="i9?.listC.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="listC" fieldName="listCUnexpiredINSEmploymentAuth"
          [option]="I9ListCTypes.UnexpiredINSEmploymentAuth" caption="Unexpired INS Employment Auth" [(ngModel)]="i9?.listC.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" maxlength="255" name="listCDocumentId" placeholder="Document Id"
            [(ngModel)]="i9?.listC.documentId" [readonly]="!state.isEditMode">
        </slx-input-decorator>
      </div>

      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <slx-date-picker-ngx slx-input date
            [(ngModel)]="i9?.listC.documentExpiration"
            [readonly]="!state.isEditMode"
            placeholder="Document Expiration"
            name="listCDocumentExpiration"
          ></slx-date-picker-ngx>
        </slx-input-decorator>
      </div>
    </div>
  </div>

  <div class="col-xs-12">
    <h4 class="brcramp">
      Additional Info
      <span class="clear" *ngIf="state.isEditMode && isEditable" (click)="clearI9AdditionalInfo()">clear</span>
    </h4>
    <hr>
    <div class="col-xs-12">
      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="additionalInfo" fieldName="additionalInfoUsCitizen"
          [option]="I9AdditionalInfoTypes.UsCitizen" caption="A citizen of the United States" [(ngModel)]="i9?.additionalInfo.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="additionalInfo" fieldName="additionalInfoNonUsCitizen"
          [option]="I9AdditionalInfoTypes.NonUsCitizen" caption="A noncitizen national of the United States" [(ngModel)]="i9?.additionalInfo.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>

      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="additionalInfo" fieldName="additionalInfoLawfulPermanentResident"
          [option]="I9AdditionalInfoTypes.LawfulPermanentResident" caption="A lawful permanent resident (Alien #)" [(ngModel)]="i9?.additionalInfo.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="255" name="additionalInfoAlienNumber" placeholder="Alien #"
          [(ngModel)]="i9?.additionalInfo.alienNumber" [readonly]="!state.isEditMode">
      </slx-input-decorator>
    </div>

    <div class="col-xs-12">
      <slx-input-decorator className="slx-no-border slx-no-label">
        <slx-radio-input slx-input [readonly]="!state.isEditMode" name="additionalInfo" fieldName="additionalInfoAlienAuthorizedToWork"
          [option]="I9AdditionalInfoTypes.AlienAuthorizedToWork" caption="An alien authorized to work (A# or Admission #)" [(ngModel)]="i9?.additionalInfo.type"
          (ngModelChange)="updateData()">
        </slx-radio-input>
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="255" name="additionalInfoAlienOrAdmissionNumber" placeholder="A# or Admission #"
          [(ngModel)]="i9?.additionalInfo.alienOrAdmissionNumber" [readonly]="!state.isEditMode">
      </slx-input-decorator>
    </div>

    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input date
          [(ngModel)]="i9?.additionalInfo.expiration"
          [readonly]="!state.isEditMode"
          placeholder="Expiration Date, if applicable"
          name="additionalInfoExpiration"
        ></slx-date-picker-ngx>
      </slx-input-decorator>
    </div>
  </div>
</form>
