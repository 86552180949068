import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  HostBinding
} from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export const CHEKBOX_TOGGLE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxToggleComponent),
  multi: true
};

@Component({
  moduleId: module.id,
  selector: 'slx-checkbox-toggle',
  templateUrl: 'checkbox-toggle.component.html',
  styleUrls: ['checkbox-toggle.component.scss'],
  providers: [CHEKBOX_TOGGLE_VALUE_ACCESSOR]
})
export class CheckboxToggleComponent implements ControlValueAccessor {
  @Input()
  public className: string;
  @Input()
  public checkedText: string;
  @Input()
  public uncheckedText: string;
  @Input()
  public caption: string;
  @Input()
  public isDisabled: boolean;
  @Input()
  public set readonly(ro: boolean) {
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }
  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public elementName: string;
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: boolean = false;

  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.elementName = this.getUniqueId('toggle');
    this.className = 'toggle-left';
  }

  public writeValue(value?: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = (value: any) => { Promise.resolve(null).then(() => fn(value)); };
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = () => { Promise.resolve(null).then(() => fn()); };
  }

  //TODO: Need to encapsulate this function to some kind of service
  private getUniqueId(name: string): string {
    return `${name}_${Math.random().toString(36).substr(2, 9)}`;
  }


}
