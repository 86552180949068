<form #absencesForm="ngForm" novalidate>

  <div class="col-sm-8">
    <slx-input-decorator className="slx-no-background">
      <slx-dropdown-input slx-input [required]="true" [(ngModel)]="dataItem.absence" name="absenceDefinition" [options]="absenceCodesLookup"
                          (ngModelChange)="onTypeChanged(dataItem)" titleField="description" placeholder="Description">
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
    </slx-input-decorator>
  </div>

  <div class="col-sm-4">
    <slx-input-decorator *ngIf="dataItem.absence" className="slx-no-background">
      <input slx-input [ngModel]="dataItem.absence?.code" name="absenceCode" [readonly]="true" placeholder="Code">
    </slx-input-decorator>
  </div>

  <div class="col-sm-12">
    <slx-input-decorator className="slx-no-background">
      <slx-dropdown-input slx-input [(ngModel)]="dataItem.shift" name="absenceShift" [options]="shiftsLookup" (ngModelChange)="onShiftChanged(dataItem)"
                          titleField="name" placeholder="Shift">
      </slx-dropdown-input>
      <span errorMessage for="required"></span>
      <span errorMessage *ngIf="isOverlapped" class="slx-error-block ng-star-inserted">This shift is overlapping with an existing schedule</span>
      <span errorMessage *ngIf="isOverlappedAbsenceshift" class="slx-error-block ng-star-inserted">This shift time is overlapping with an existing absence shift time</span>
    </slx-input-decorator>
  </div>
  <div class="col-sm-12">
    <slx-input-decorator #startTimeContainer className="slx-no-background">
      <input slx-time-picker slx-input name="startDate" required="true" placeholder="Start Time" [(ngModel)]="dataItem.start" (onDateChanged)="onStartDateChanged (dataItem)"
             format="hh:mm tt" dateInput="true" [slxMaxDate]="dataItem.end" [minDate]="minDateLimit" [maxDate]="maxDateLimit" slxDatePager
             [pagerMinDate]="minDateLimit" [pagerMaxDate]="maxDateLimit" [targetContainer]="startTimeContainer" btnLeftClass="prev-day-btn"
             btnRightClass="next-day-btn" styleContainerClass="slx-date-pager-date">
      <span errorMessage for="required">Start time is required</span>
      <span errorMessage for="maxDate">Start date should be less than end date</span>
       <span errorMessage  *ngIf="isOverlappedAbsencestartdate">This absence is overlapping with an existing absence</span>
      <span slx-input-hint>{{ dataItem.start | amDateFormat: appConfig.dateFormat }}</span>
    </slx-input-decorator>
  </div>
  <div class="col-sm-12">
    <slx-input-decorator #endTimeContainer className="slx-no-background">
      <input slx-time-picker slx-input name="endDate" required="true" placeholder="End Time" [(ngModel)]="dataItem.end" (onDateChanged)="onEndDateChanged (dataItem)"
             format="hh:mm tt" dateInput="true" [slxMinDate]="dataItem.start" [minDate]="minDateLimit" [maxDate]="maxDateLimit"
             slxDatePager [pagerMinDate]="minDateLimit" [pagerMaxDate]="maxDateLimit" [targetContainer]="endTimeContainer" btnLeftClass="prev-day-btn"
             btnRightClass="next-day-btn" styleContainerClass="slx-date-pager-date">
      <span errorMessage for="required">End time is required</span>
      <span errorMessage for="minDate">End date should be greater than start date</span>
      <span errorMessage *ngIf="isOverlappedAbsenceenddate">This absence is overlapping with an existing absence</span>
      <span slx-input-hint>{{ dataItem.end | amDateFormat: appConfig.dateFormat }}</span>
    </slx-input-decorator>
  </div>
  <div class="slx-error-block" *ngIf="isOverlappedAbsencesDB">There is already absence added for this date and time.Please adjust time to remove overlap!</div>
  <div class="col-sm-6">
    <slx-input-decorator *ngIf="displayOptions==='number'" className="slx-no-background">
      <slx-kendo-number slx-input #absenseInterval
                        [ngModel]="dataItem.interval | slxInterval"
                        (ngModelChange)="onIntervalChanged(dataItem, $event)"
                        [decimals]="2"
                        [min]="0"
                        [slxMin]="0"
                        placeholder="Interval"
                        name="interval"></slx-kendo-number>
      <span errorMessage for="paidPeriod">Unpaid portion of the shift is greater than paid portion.</span>
    </slx-input-decorator>
    <slx-input-decorator *ngIf="displayOptions==='number'" className="slx-no-background" class="unpaid-hours">
      <slx-kendo-number slx-input
                        [ngModel]="dataItem.unpaidInterval | slxInterval"
                        [decimals]="2"
                        [min]="0"
                        [slxMin]="0"
                        placeholder="Unpaid Interval"
                        [readonly]="true"
                        name="unpaidInterval"></slx-kendo-number>
    </slx-input-decorator>
    <slx-input-decorator *ngIf="displayOptions==='hmstring'" className="slx-no-background">
      <input slxDurationInput slx-input name="interval" placeholder="Interval" [(ngModel)]="dataItem.intervalMin" (ngModelChange)="onIntervalChanged(dataItem, dataItem.intervalMin / 60)">
      <span errorMessage for="paidPeriod">Unpaid portion of the shift is greater than paid portion.</span>
    </slx-input-decorator>
    <slx-input-decorator *ngIf="displayOptions==='hmstring'" className="slx-no-background" class="unpaid-hours">
      <input slxDurationInput slx-input name="unpaidIntervalMin" [readonly]="true" placeholder="Unpaid Interval" [(ngModel)]="dataItem.unpaidIntervalMin">
    </slx-input-decorator>

  </div>
  <div class="col-sm-6">
    <slx-input-decorator className="slx-no-border slx-small-font slx-no-padding slx-no-background">
      <slx-checkbox-input slx-input tipPosition="top" [readonly]="!dataItem.hasLinkedSa" name="updateSchedule" fieldName="updateSchedule"
                          [(ngModel)]="dataItem.updateSchedule" (ngModelChange)="onUpdateScheduleChanged(dataItem)" placeholder="Update Schedule"
                          [caption]="dataItem.hasLinkedSa?'':'Not linked to a scheduled absence'"></slx-checkbox-input>
    </slx-input-decorator>
  </div>
</form>
