import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpParams, HttpRequest } from '@angular/common/http';
import { dateTimeUtils } from '../../../../common/utils/index';

import { ResponseBody, Meta, EditResult } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { benefitsConfig } from '../../benefits.config';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { ReadFile } from '../../../../organization/models/index';
import { BenefitDetailsMapService } from './benefit-details-map.service';
import {
  IBenefitDetailsConfig,
  BenefitDetailsConfig,
  IBenefitDetailsProvider,
  BenefitDetailsProvider,
  BenefitDetailsProviderEntity,
  IBenefitDetailsLineStandart,
  BenefitDetailsLineStandart,
  BenefitDetailsProviderLineStandartEntity,
  IBenefitDetailsSavedProvider,
  BenefitDetailsSavedProvider,
  IBenefitDetailsTier,
  BenefitDetailsTier,
  BenefitDetailsLine,
  IBenefitDetailsLine,
  IBenefitsDetailsValidationResult,
  BenefitsDetailsValidationResult,
  DeductionCodeValidation
} from '../../models/index';

@Injectable()
export class BenefitDetailsApiService {
  constructor(
    private mapService: BenefitDetailsMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) { }

  public async getBenefitDetailsConfig(orgLevelId: number): Promise<BenefitDetailsConfig> {
    const url = this.getApiConfig();
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId });

    return this.apiUtilService.request<IBenefitDetailsConfig, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanConfig(response.data));
  }

  public async getBenefitProviders(orgLevelId: number, groupId: number, loadExpired: boolean): Promise<BenefitDetailsProviderEntity[]> {
    const url = this.getApiProviders();
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId, groupId, loadExpired });

    return this.apiUtilService.request<IBenefitDetailsProvider[], Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanProviderEntities(response.data));
  }

  public saveBenefitProvider(provider: BenefitDetailsProvider, providerLine: BenefitDetailsLineStandart): Promise<BenefitDetailsSavedProvider> {
    const url = this.getApiProvider();
    const body = this.mapService.mapToSaveProviderRequest(provider, providerLine);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService.request<IBenefitDetailsSavedProvider, Meta>(request)
      .then((response) => this.mapService.mapToSavedProvider(response.data));
  }

  public deleteBenefitProvider(providerId: number): Promise<void> {
    const url = this.getApiProvider();
    const request = this.urlParamsService.createDeleteRequest(url, { providerId });

    return this.apiUtilService.request<void, Meta>(request)
      .then((response) => response.data);
  }

  public async getBenefitDetailsLineStandart(lineId: number): Promise<BenefitDetailsProviderLineStandartEntity> {
    const url = this.getApiLine();
    const request = this.urlParamsService.createGetRequest(url, { lineId });

    return this.apiUtilService.request<IBenefitDetailsLineStandart, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanLineEntity(response.data));
  }

  public saveBenefitDetailsLineStandart(providerName: string, providerLine: BenefitDetailsLineStandart, providerPlanDate: Date): Promise<BenefitDetailsProviderLineStandartEntity> {
    const url = this.getApiLine();
    const body = this.mapService.mapToSaveProviderLineStandartRequest(providerName, providerLine, providerPlanDate);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService.request<IBenefitDetailsLineStandart, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanLineEntity(response.data));
  }

  public async saveTiersAttachments(savedTiers: BenefitDetailsTier[], draftTiers: BenefitDetailsTier[]): Promise<BenefitDetailsTier[]> {
    const tierFilesToSave = this.mapService.mapToTiersFilesToSave(savedTiers, draftTiers);
    if (tierFilesToSave.size > 0) {
      const promises: Array<Promise<BenefitDetailsTier>> = [];
      tierFilesToSave.forEach((files, tierId) => {
        promises.push(this.saveTierAttachments(tierId, files));
      });
      return Promise.all(promises);
    }

    return Promise.resolve([]);
  }

  public saveTierAttachments(tierId: number, files: ReadFile[]): Promise<BenefitDetailsTier> {
    const url = this.getApiAttachments();
    const formData = this.mapService.mapFilesToFormData(files);
    const request = this.urlParamsService.requestPost(url, formData, { tierId });
    request.autoContentType = true;

    return this.apiUtilService.requestNew<IBenefitDetailsTier, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanTier(response.data));
  }

  public deleteTierAttachments(tierId: number, documentIds: number[]): Promise<BenefitDetailsTier> {
    const url = this.getApiAttachments();
    const request = this.urlParamsService.createDeleteRequest(url, { tierId, documentIds }, null, true);

    return this.apiUtilService.request<IBenefitDetailsTier, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanTier(response.data));
  }

  public deleteTiersAttachments(attachments: Map<number, number[]>): Promise<BenefitDetailsTier[]> {
    const promises = Array.from(attachments)
      .map(([tierId, attachmentIds]) => this.deleteTierAttachments(tierId, attachmentIds));

    return Promise.all(promises);
  }

  public downloadTierAttachment(documentId: number): Promise<FileBlobResponse> {
    const url = this.getApiAttachment();
    const request = this.urlParamsService.createGetRequest(url, { documentId });
    return this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);
  }

  public expireBenefitPlan(lineId: number, newEndDate: Date): Promise<BenefitDetailsLine> {
    const url: string = this.getApiLineExpire();
    const params = {
      lineId: lineId,
      newEndDate: dateTimeUtils.convertToDtoString(newEndDate)
    };
    const request = this.urlParamsService.createPutRequest(url, null, params);
    return this.apiUtilService.request<IBenefitDetailsLine, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanLine(response.data));
  }

  public extendBenefitPlan(lineId: number, newEndDate: Date): Promise<BenefitDetailsLine> {
    const url: string = this.getApiLineExtend();
    const params = {
      lineId,
      newEndDate: dateTimeUtils.convertToDtoString(newEndDate)
    };
    const request = this.urlParamsService.createPutRequest(url, null, params);

    return this.apiUtilService.request<IBenefitDetailsLine, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanLine(response.data));
  }

  public renewBenefitPlan(lineId: number, newEndDate: Date): Promise<BenefitDetailsProviderLineStandartEntity> {
    const url: string = this.getApiLineRenew();
    const params = {
      lineId: lineId,
      newEndDate: dateTimeUtils.convertToDtoString(newEndDate)
    };
    const request = this.urlParamsService.createPutRequest(url, null, params);
    return this.apiUtilService.request<IBenefitDetailsLineStandart, Meta>(request)
      .then((response) => this.mapService.mapToBenefitPlanLineEntity(response.data));
  }

  public validateProviderName(newProviderName: string, orgLevelId: number, ignoreProviderId?: number): Promise<BenefitsDetailsValidationResult> {
    const url = this.getApiValidation();
    const params: StringMap<any> = {
      orgLevelId,
      newProviderName
    };
    if (_.isFinite(ignoreProviderId)) {
      params.ignoreProviderId = ignoreProviderId;
    }
    const request = this.urlParamsService.createGetRequest(url, params);

    return this.apiUtilService.request<IBenefitsDetailsValidationResult, Meta>(request)
      .then((response) => this.mapService.mapToBenefitValidationResult(response.data));
  }

  private getApiValidation(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.validation.root}/${benefitsConfig.api.benefits.validation.providerName}`;
  }

  private getApiLineExpire(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.line}/${benefitsConfig.api.benefits.expire}`;
  }

  private getApiLineRenew(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.line}/${benefitsConfig.api.benefits.renew}`;
  }

  private getApiLineExtend(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.line}/${benefitsConfig.api.benefits.extend}`;
  }

  private getApiLine(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.line}`;
  }

  private getApiAttachments(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.attachments}`;
  }

  private getApiAttachment(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.attachment}`;
  }

  private getApiProvider(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.provider}`;
  }

  private getApiProviders(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.providers}`;
  }

  private getApiConfig(): string {
    return `${this.getApiRoot()}/${benefitsConfig.api.benefits.workplace}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.benefits.root}`;
  }

  public getdeductionCodeInfo(tierId:number,deductionCode:string): Promise<DeductionCodeValidation>
  { 
    const url: string = `${this.getgetdeductionCodeInfoUrl()}`;
    let params: StringMap<any> = {};
    if (tierId) {
      params['tierId'] = tierId;
    }
    if (deductionCode) {
      params['deductionCode'] = deductionCode;
    }
    const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url,params);
    const promise: Promise<DeductionCodeValidation> = this.apiUtilService.request<DeductionCodeValidation, Meta>(request)
      .then((response: ResponseBody<DeductionCodeValidation, Meta>) => response.data);
    return promise;
      
  }

  private getgetdeductionCodeInfoUrl():string
  {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.benefits.root}/${benefitsConfig.api.benefits.validateDeductionCode}`;
  }
}
