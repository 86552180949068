import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { LookupApiService, LookupService, LookupType } from '../../../organization';

@Injectable()
export class PunchProfileResolver implements Resolve<Promise<any>> {
  constructor(private lookupApiService: LookupApiService,
    private lookupService : LookupService) {}

  resolve(): Promise<any> {
    return Promise.all([this.lookupService.getLookup({ lookupType: LookupType.punchProfile }), this.lookupApiService.getAttestationTypes(),this.lookupService.getLookup({ lookupType: LookupType.punchAttestationPunchType })]);
  }
}