
export class TimecardsAction {
  cmd: TimecardsActionCmd;
  public payload: any;
}

export class TimecardsActionCmd {
  public static excelExport: TimecardsActionCmdDefinition = 'ExcelExport';
  public static pdfExport: TimecardsActionCmdDefinition = 'PdfExport';
}

export type TimecardsActionCmdDefinition = 'ExcelExport' | 'PdfExport';
