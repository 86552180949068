import { Component, Provider } from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { DialogOptions, StartEndDates } from '../../models/index';
import * as moment from 'moment';
import * as _ from 'lodash';
import { DateTimeService } from '../../services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-start-end-dates-dialog',
    templateUrl: 'start-end-dates-dialog.component.html',
    styleUrls: ['start-end-dates-dialog.component.scss']
})
export class StartEndDatesDialogComponent {
  public options: DialogOptions;
  public dialogResult: boolean;
  public dates: StartEndDates;
  public isValidFromDate: boolean;
  public isValidToDate: boolean;

  public maxDateRestriction: Date;
  public minDateRestriction: Date;
  public maxDateProperty: Date;

  public static openDialog(
      dates: StartEndDates,
      modalService: ModalService,
      callback: (result: boolean, dates: StartEndDates) => void): StartEndDatesDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 320;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: StartEndDates,
        useValue: dates
      }
    ];
    let dialog: StartEndDatesDialogComponent = modalService.globalAnchor
      .openDialog(StartEndDatesDialogComponent, 'Choose start and end dates', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        dialog.dates.startDate = moment(dialog.dates.startDate);
        dialog.dates.endDate = dialog.dates.endDate === null ? moment(dialog.dateTimeService.GetMaxDate) : moment(dialog.dates.endDate);
        callback(result, dialog.dates);
      });
    return dialog;
  }

  constructor(
    options: DialogOptions,
    private modalService: ModalService,
    dates: StartEndDates,
    private dateTimeService: DateTimeService
  ) {
    this.options = options;
    this.dates = _.cloneDeep(dates);
    this.minDateRestriction = this.getMinDateRestriction();
    this.maxDateRestriction = this.getMaxDateRestriction();
    this.maxDateProperty = dateTimeService.GetMaxDate;
    this.minDateRestriction = this.getMinDateRestriction();
    this.maxDateRestriction = this.getMaxDateRestriction();
    this.isValidFromDate = this.isValidDate(this.dates.startDate);
    this.isValidToDate = this.isValidDate(this.dates.endDate);
  }

  public startDateChanged(date: Date): void {
    this.isValidFromDate = this.isValidDate(moment(date));
    if(this.isValidFromDate) {
      this.minDateRestriction = date;
    }
  }

  public endDateChanged(date: Date): void {
    if(date === null) {
      this.isValidToDate = true;
      this.maxDateRestriction = this.dateTimeService.GetMaxDate;
    } else {
      this.isValidToDate = this.isValidDate(moment(date));
      if(this.isValidToDate) {
        this.maxDateRestriction = date;
      }
    }
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public isValidDate(date: moment.Moment): boolean {
    return date && date.isValid();
  }

  private getMaxDateRestriction(): Date {
    if(this.dates && this.dates.endDate) {
      if (this.dates.endDate instanceof Date) {
        return this.dates.endDate;
      } else if (this.dates.endDate instanceof moment) {
        return this.dates.endDate.toDate();
      }
    }
    return this.dateTimeService.GetMaxDate;
  }

  private getMinDateRestriction(): Date {
    if(this.dates && this.dates.endDate) {
      if (this.dates.startDate instanceof Date) {
        return this.dates.startDate;
      } else if (this.dates.startDate instanceof moment) {
        return this.dates.startDate.toDate();
      }
    }
    return this.dateTimeService.GetMinDate;
  }
}
