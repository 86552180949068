<slx-spinner [show]="state.isLoading">

  <div class="f-container f-horizontal f-wrap width100 height100 attendance-points-totals">

    <div class="f-item f-container f-horizontal attendance-points-toolbar">
      <form class="f-item f-container f-horizontal width100 attendance-points-header"
        novalidate>

        <span class="f-spacer"></span>

        <div class="attendance-points-active-checkbox">
          <slx-checkbox-toggle class="active-only-field"
            className="toggle-right"
            name="isActiveOnly"
            [(ngModel)]="isActiveOnly"
            (ngModelChange)="onDisplaySettingsChanged()"
            caption="Active only"
            checkedText="Yes"
            uncheckedText="No"></slx-checkbox-toggle>
        </div>

        <div class="attendance-points-dropdown">
          <slx-actions-list>
            <slx-actions-button [popperContent]="popperContent"
              [popperPosition]="'bottom-end'">
              Actions <span class="caret"></span>
            </slx-actions-button>
            <popper-content #popperContent>
              <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
              <slx-actions-list-item (onClick)="onExportToPdf()">Export to Pdf</slx-actions-list-item>
            </popper-content>
          </slx-actions-list>
        </div>

      </form>
    </div>

    <div class="f-item attendance-points-grid">


      <kendo-grid #kendoGrid
        class="slx-full-height slx-blue-grid slx-white-cells"
        scrollable="'scrollable'"
        [data]="gridState?.view"
        [sortable]="{ mode: 'multiple' }"
        [sort]="gridState.state.sort"
        [skip]="gridState.state.skip"
        [pageable]="true"
        [pageSize]="pageSize"
        [filterable]="'menu'"
        [filter]="gridState.state.filter"
        (dataStateChange)="gridState.dataStateChange($event)"
        (stateRestored)="gridState.dataStateChange($event)"
        [slxKendoGridState]="gridState.gridDefinition"
        [slxKendoGridColumnsGroup]="columnsGroupName">


        <kendo-grid-column *slxHiddenOn="['mobile']"
          title="Organization"
          width="150"
          [locked]="isLockedColumn"
          field="organization.name"
          >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Organization</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>{{ dataItem.organization?.name }}</ng-template>
        </kendo-grid-column>

        <kendo-grid-column *slxHiddenOn="['mobile']"
          title="Department"
          field="department.name"
          width="150"
          [locked]="isLockedColumn"
          >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Department</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>{{ dataItem.department?.name }}</ng-template>
        </kendo-grid-column>

        <kendo-grid-column *slxHiddenOn="['mobile']"
          title="Employee Name"
          field="employee.name"
          [locked]="isLockedColumn"
          width="220">
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employee Name</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span class="att-emp"
              [title]="employee?.name"
              (click)="onEmpNameClick(dataItem.employee?.id)">
              <img class="att-emp__img"
                [employeeThumbinalSrc]="dataItem.employee?.id"
                alt="Employee Photo" />
              <span class="att-emp__text att-link">{{ dataItem.employee?.name }}</span>
            </span>
          </ng-template>
        </kendo-grid-column>
       

        <kendo-grid-column *ngIf="!isActiveOnly && isLockedColumn"
          title="Employee Status"
          field="status"
          [locked]="isLockedColumn"
          width="180"
          >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Employee Status</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <span>{{dataItem.status}}</span>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column *slxHiddenOn="['mobile']"
          title="Position"
          field="position.name"
          [locked]="isLockedColumn"
          width="150"
          >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Position</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>{{ dataItem.position?.name }}</ng-template>
        </kendo-grid-column>

        <kendo-grid-column  *ngIf="buyBackEndabled && isLockedColumn"
          title="Total Accumulated Points"
          field="totalAccumulatedPoints"
          width="200"
          >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Total Accumulated Points</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>{{ dataItem.totalAccumulatedPoints }}</ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="buyBackEndabled && isLockedColumn"
          title="Total Buyback Points"
          field="totalBuyBackPoints"
          width="200"
          >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Total Buyback Points</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>{{ dataItem.totalBuyBackPoints }}</ng-template>
        </kendo-grid-column>

        <kendo-grid-column *slxHiddenOn="['mobile']"
          title="Current Balance"
          field="totalPoints"
          width="200"
          >
          <ng-template kendoGridHeaderTemplate>
            <span slxKendoGridHeaderTooltip>Current Balance</span>
          </ng-template>
          <ng-template kendoGridCellTemplate
            let-dataItem>{{ dataItem.totalPoints }}</ng-template>
        </kendo-grid-column>


        <kendo-grid-column *slxHiddenOn="['desktop']"
          title=""
          class="overflow-visible-cell action-column"
          [width]="50"
          [filterable]="false"
          [sortable]="false"
          >
          <ng-template kendoGridCellTemplate
            let-dataItem>
            <div class="m-attp">
              <span class="m-attp__btn"
                [popper]="pmInfo"
                [popperTrigger]="'click'"
                [popperPlacement]="'bottom-end'"
                [popperDisableStyle]="'true'">
                <i class="fas fa-list-ul"
                  aria-hidden="true"></i>
              </span>
              
              <span>{{dataItem.employee.name}}</span>

              <popper-content #pmInfo class="slx-popper">
                <section class="m-attp__popper">
                  <div class="flex flex-direction-row m-attp__group"
                    *slxHiddenOn="['tablet']">
                    <p class="m-attp__field">
                      <span class="m-attp__title">Organization</span>
                      <span class="m-attp__value">{{ dataItem.organization.name }}</span>
                    </p>
                    <p class="m-attp__field">
                      <span class="m-attp__title">Department</span>
                      <span class="m-attp__value">{{ dataItem.department.name }}</span>
                    </p>
                    <p class="m-attp__field">
                      <span class="m-attp__title">Position</span>
                      <span class="m-attp__value">{{ dataItem.position.name }}</span>
                    </p>
                  </div>

                  <div class="flex flex-direction-row m-attp__group"
                    *slxHiddenOn="['tablet']">
                    <p class="m-attp__field">
                      <span class="m-attp__title">Termination Date</span>
                      <span class="m-attp__value">{{ dataItem.employee.terminationDate | amDateFormat:
                        appConfig.dateFormat }}</span>
                    </p>
                  </div>

                  <div class="flex flex-direction-row m-attp__group" *slxHiddenOn="['tablet']">
                    <p class="m-attp__field" *ngIf="buyBackEndabled">
                      <span class="m-attp__title">Total Accumulated Points</span>
                      <span class="m-attp__value">{{ dataItem.totalAccumulatedPoints }}</span>
                    </p>
                    <p class="m-attp__field" *ngIf="buyBackEndabled">
                      <span class="m-attp__title">Total Buyback Points</span>
                      <span class="m-attp__value">{{ dataItem.totalBuyBackPoints }}</span>
                    </p>
                    <p class="m-attp__field">
                      <span class="m-attp__title">Current Balance</span>
                      <span class="m-attp__value">{{ dataItem.totalPoints }}</span>
                    </p>
                  </div>

                  <div class="flex flex-direction-row m-attp__group">
                    <p class="m-attp__field m-attp__icon">
                      <span class="att-link"
                        (click)="onEmpNameClick(dataItem.employee?.id)">
                        <i class="att-emp__icon fas fa-file-search"
                          aria-hidden="true">&nbsp;</i>Employee Profile
                      </span>
                    </p>
                  </div>
                </section>
              </popper-content>
            </div>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-excel [fileName]="xlsxName"
          [fetchData]="retriveAllPages()"></kendo-grid-excel>
        <kendo-grid-pdf [fileName]="pdfName"
          [allPages]="true">
          <kendo-grid-pdf-margin top="1cm"
            left="1cm"
            right="1cm"
            bottom="1cm"></kendo-grid-pdf-margin>
        </kendo-grid-pdf>

      </kendo-grid>


    </div>

  </div>
</slx-spinner>