<div class="info-container">
  <h2>Employee: {{req?.entryRow.name}}</h2>
  <h4>Week: {{startOfWeek | amDateFormat: appConfig.dateFormat}} - {{endOfWeek | amDateFormat: appConfig.dateFormat}}</h4>
  <h4>Date: {{req?.dateOn | amDateFormat: appConfig.dateFormat}}</h4>
  <div *ngIf="!req?.dayCell">Employee not scheduled</div>
  <div class="shifts-container" *ngIf="req?.dayCell">
    <div class="shift-container" *ngFor="let shiftInfo of req?.dayCell.shiftsInfo">
      <div>
        <h4>Shift Name: {{shiftInfo.shiftName}}</h4>
      </div>
      
      <div>Times: {{getTimeString(shiftInfo)}}</div>

      <div>Interval: {{shiftInfo.duration | slxInterval}}</div>
      <div *ngIf="shiftInfo.unitName">Unit: {{shiftInfo.unitName}}</div>
      <div>Position: {{shiftInfo.positionName || shiftInfo.position?.name}}</div>
      <div *ngIf="shiftInfo.absenceCode">Absence: {{shiftInfo.absenceCode}}</div>
      <div *ngIf="shiftInfo.partialAbsenceCode">Partial Absence: {{shiftInfo.partialAbsenceName}} 
        <span *ngIf="shiftInfo.partialAbsenceStart && shiftInfo.partialAbsenceEnd">({{shiftInfo.partialAbsenceStart | amDateFormat: appConfig.timeFormat}} - {{shiftInfo.partialAbsenceEnd | amDateFormat: appConfig.timeFormat}})</span>
      </div>
      <div *ngIf="shiftInfo.constraintCode">Constraint: {{shiftInfo.constraintCode}}</div>
      <div *ngIf="shiftInfo.prescheduledOvertimeDuration">Prescheduled OT: {{ shiftInfo.prescheduledOvertimeDuration | slxInterval }}</div>
    </div>
  </div>
</div>
