<slx-spinner [show]="isLoading" novalidate>
    <div class="vbox width-100 main-container">
        <div class="hbox">
            <slx-input-decorator class="employee-field">
                <slx-autocomplete-input slx-input
                [lookup]="{lookupType: 'employeeList', orgLevelId: orgLevel?.id}"
                [(ngModel)]="selectedEmployee"
                (ngModelChange)="employeeChanged($event)"
                [required]="true"
                placeholder="Employee name"
                name="employee"
                ></slx-autocomplete-input>
                <span errorMessage for="required"></span>
            </slx-input-decorator>

            <slx-input-decorator class="date-field">
                <slx-date-picker-ngx slx-input
                [(ngModel)]="selectedDate"
                placeholder="Date"
                name="date"
                required
                ></slx-date-picker-ngx>
                <span errorMessage for="required"></span>
                <span errorMessage for="date"></span>
        
            </slx-input-decorator>
        </div>
        <div class="vbox width-100">
            <div *ngIf="homePhone">
                <slx-input-decorator className="slx-form-group slx-no-border small-font slx-no-error-block slx-no-label">
                    <slx-radio-input slx-input name="homePhone" fieldName="homePhone" option="home"
                        caption="Home Phone: {{homePhone}}" [(ngModel)]="phoneType">
                    </slx-radio-input>
                </slx-input-decorator>
            </div>

            <div *ngIf="mobilePhone">
                <slx-input-decorator className="slx-form-group slx-no-border small-font slx-no-error-block slx-no-label">
                    <slx-radio-input slx-input name="mobilePhone" fieldName="mobilePhone" option="mobile"
                    caption="Mobile Phone: {{mobilePhone}}" [(ngModel)]="phoneType">
                    </slx-radio-input>
                </slx-input-decorator>
            </div>

            <div *ngIf="altPhone">
                <slx-input-decorator className="slx-form-group slx-no-border small-font slx-no-error-block slx-no-label">
                    <slx-radio-input slx-input name="altPhone" fieldName="altPhone" option="alternative"
                    caption="Alt Phone: {{altPhone}}" [(ngModel)]="phoneType"> 
                    </slx-radio-input>
                </slx-input-decorator>
            </div>
        </div>
        <div class="vbox width-100">
            <div>
                <label for="msg-text">Message:</label>
            </div>
            <div class="width-100">
                <textarea name="msg-text" class="width-100 message-field" [(ngModel)]="messageText" required></textarea>
            </div>
        </div>
        <div class="hbox">
            <div></div>
            <div>
                <button type="button" class="btn action-button" (click)="save()" [disabled]="!canSave">Save</button>
            </div>
        </div>
    </div>
</slx-spinner>