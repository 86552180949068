import { EventEmitter } from '@angular/core';
import { LookupService } from '../../services/lookup/lookup.service';
import { LocationUnit, ConstraintDefinition, Lookup, LookupType, OrgLevelShiftTemplate } from '../../models/index';
var OrglevelShiftEditorComponent = /** @class */ (function () {
    function OrglevelShiftEditorComponent(lookupService) {
        this.onShiftTemplateChanged = new EventEmitter();
        this.lookupService = lookupService;
        this.state = {
            isShiftLoading: false,
            isUnitLoading: false,
            isConstraintLoading: false,
            isAbsenceLoading: false,
        };
    }
    Object.defineProperty(OrglevelShiftEditorComponent.prototype, "orgLevelId", {
        get: function () {
            return this.m_orgLevelId;
        },
        set: function (orgLevelId) {
            if (this.m_orgLevelId === orgLevelId) {
                return;
            }
            this.m_orgLevelId = orgLevelId;
            this.loadLookups();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrglevelShiftEditorComponent.prototype, "isAbsenceSelected", {
        get: function () {
            return !!this.shiftTemplate.absence && !!this.shiftTemplate.absence.code;
        },
        enumerable: true,
        configurable: true
    });
    OrglevelShiftEditorComponent.prototype.shiftChanged = function () {
        this.onShiftTemplateChanged.emit(this.shiftTemplate);
    };
    OrglevelShiftEditorComponent.prototype.unitChanged = function () {
        if (this.shiftTemplate.unit && this.shiftTemplate.unit.id) {
            this.shiftTemplate.absence = null;
        }
        this.onShiftTemplateChanged.emit(this.shiftTemplate);
    };
    OrglevelShiftEditorComponent.prototype.constraintChanged = function () {
        if (this.shiftTemplate.constraint && this.shiftTemplate.constraint.id) {
            this.shiftTemplate.absence = null;
        }
        this.onShiftTemplateChanged.emit(this.shiftTemplate);
    };
    OrglevelShiftEditorComponent.prototype.absenceChanged = function () {
        if (this.shiftTemplate.absence && this.shiftTemplate.absence.code) {
            this.unitSaved = this.shiftTemplate.unit;
            this.constraintSaved = this.shiftTemplate.constraint;
            this.shiftTemplate.unit = null;
            this.shiftTemplate.constraint = null;
        }
        this.onShiftTemplateChanged.emit(this.shiftTemplate);
    };
    OrglevelShiftEditorComponent.prototype.absenceRemove = function () {
        this.shiftTemplate.absence = null;
        this.shiftTemplate.unit = this.unitSaved;
        this.shiftTemplate.constraint = this.constraintSaved;
        this.onShiftTemplateChanged.emit(this.shiftTemplate);
    };
    OrglevelShiftEditorComponent.prototype.loadLookups = function () {
        var _this = this;
        this.shiftTemplate = new OrgLevelShiftTemplate();
        this.state.isShiftLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.shift, employeeId: undefined, orgLevelId: this.orgLevelId })
            .then(function (shiftLookup) {
            _this.state.isShiftLoading = false;
            _this.shiftLookup = shiftLookup;
        }).catch(function () {
            _this.state.isShiftLoading = false;
        });
        this.state.isUnitLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.locationUnit, employeeId: undefined, orgLevelId: this.orgLevelId })
            .then(function (unitLookup) {
            _this.state.isUnitLoading = false;
            _this.unitLookup = unitLookup;
        }).catch(function () {
            _this.state.isUnitLoading = false;
        });
        this.state.isConstraintLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.constraintDefinition, employeeId: undefined, orgLevelId: this.orgLevelId })
            .then(function (constraintLookup) {
            _this.constraintLookup = constraintLookup;
            _this.state.isConstraintLoading = false;
        })
            .catch(function () {
            _this.state.isConstraintLoading = false;
        });
        this.state.isAbsenceLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.scheduleAbsence, orgLevelId: this.orgLevelId })
            .then(function (scheduleAbsenceLookup) {
            _this.scheduleAbsenceLookup = scheduleAbsenceLookup;
            _this.state.isAbsenceLoading = false;
        })
            .catch(function () {
            _this.state.isAbsenceLoading = false;
        });
    };
    return OrglevelShiftEditorComponent;
}());
export { OrglevelShiftEditorComponent };
