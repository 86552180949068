<kendo-grid #kendoGrid class="individual-timecards individual-timecards-grid slx-full-height slx-blue-grid"
            [data]="gridState.view"
            (dataStateChange)="gridState.dataStateChange($event)"
            [sortable]="{ mode: 'multiple' }"
            [sort]="gridState.state.sort"
            [groupable]="{ showFooter: true, enabled: false }"
            [group]="gridState.state.group"
            [filterable]="'menu'"
            [filter]="gridState.state.filter"
            [selectable]="{ mode: 'single' }"
            [rowSelected]="isRowSelected()"
            (cellClick)="onCellClick($event)"
            kendoGridFixFreezeHeader>
  <kendo-grid-column [hidden]="true" title="Week" field="day.week" [width]="40" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <div>
        <span slxTooltip="Week of cycle" tipPosition="right">Week</span>
      </div>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.day.week}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [locked]="true" [width]="120" title="Day" media="sm" field="day.weekDay" [filterable]="false" class="overflow-visible-cell timecard-pdf-grid" headerClass="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate>
      <div class="day-container">
        <span class="day-name">Day</span>
      </div>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="day-container" *ngIf="dataItem?.isFirst">
        <span class="day-name">{{ dataItem?.day.weekDay }}</span>
        <div *ngIf="dataItem.isLocked" class="day-alert">
          <i class="fa fa-lock" slxTooltip="Timecard is locked" tipPosition="right" aria-hidden="true"></i>
        </div>
        <div *ngIf="dataItem.isError" class="day-alert">
          <i class="fa fa-exclamation-circle alert-error" slxTooltip="Has missing punches" tipPosition="bottom" aria-hidden="true"></i>
        </div>
        <div *ngIf="dataItem.isEdited" class="day-alert">
          <i class="fa edited-tc-icon" slxTooltip="Timecard has been edited" tipPosition="bottom" aria-hidden="true"></i>
        </div>
        <div *ngIf="dataItem.isInvalidPunch || dataItem.isInvalidLogin || dataItem.isNoShow" class="day-alert">
          <i class="fa fa-exclamation-triangle alert-warning" [slxTooltip]="dataItem.warningMessage" tipPosition="bottom" aria-hidden="true"></i>
        </div>
    </div>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-first let-aggregates>
      <div class="numeric-group-cell">&nbsp;</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM' && aggregates['day.week'].max == 1" class="numeric-group-cell"><strong>Weekly Totals:</strong></div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [locked]="isAllHidden" [width]="120" title="Date" media="sm" field="day.date" filter="date" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      Date
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [dailyTimecardsLink]="container?.employee?.id" [timecardDate]="dataItem?.day.date">
        {{dataItem?.day.date | amDateFormat: appConfig.dateFormat }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('schedule')" [width]="getWidth('schedule')" [hidden]="!isVisible('schedule')" title="Schedule"
                     media="sm" field="day.shiftNames" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Schedule</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="dataItem?.isFirst">
        <a scheduleEntryLink [employeeId]="container?.employee?.id" [dateOn]="dataItem?.day.date">
          {{dataItem?.day.shiftNames }}
        </a>
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('in')" [hidden]="!isVisible('in')" title="IN" media="sm" field="day.punchInSeconds" [filterable]="false" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      IN
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="dataItem?.isFirst">
        {{dataItem?.day.punchInTime | amDateFormat: appConfig.timeFormat }}
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('out')" [hidden]="!isVisible('out')" title="OUT" media="sm" field="day.punchOutSeconds" [filterable]="false" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      OUT
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <ng-container *ngIf="dataItem?.isFirst">
        {{dataItem?.day.punchOutTime | amDateFormat: appConfig.timeFormat }}
      </ng-container>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('organization')" [width]="getWidth('organization')" [hidden]="!isVisible('organization')"
                     title="Organization" media="lg" field="organizationName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Organization</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.organizationName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('department')" [width]="getWidth('department')" [hidden]="!isVisible('department')"
                     title="Department" media="lg" field="day.departmentName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Department</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.day.departmentName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('position')" [width]="getWidth('position')" [hidden]="!isVisible('position')" title="Position"
                     media="sm" field="positionName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Position</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.positionName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('approved')" [width]="getWidth('approved')" [hidden]="!isVisible('approved')" title="Approved"
                     media="sm" field="day.approved" filter="boolean" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Approved</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.day?.approved | boolyn}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('payPolicy')" [width]="getWidth('payPolicy')" [hidden]="!isVisible('payPolicy')" title="Pay Policy"
                     media="lg" field="payPolicyName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Pay Policy</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.payPolicyName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('shiftDiffPolicy')" [width]="getWidth('shiftDiffPolicy')" [hidden]="!isVisible('shiftDiffPolicy')"
                     title="Shift Diff Policy" media="lg" field="shiftDiffPolicyName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Shift Diff Policy</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.shiftDiffPolicyName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('shiftCodeName')" [width]="getWidth('shiftCodeName')" [hidden]="!isVisible('shiftCodeName') || !container || !container.shiftDiffBasedOnTime"
                     title="Shift Code" media="lg" field="earning.shiftCodeName" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Shift Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.earning?.shiftCodeName}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filter]="getFilter('costCenterCode')" [width]="getWidth('costCenterCode')" [hidden]="!isVisible('costCenterCode') || !container || container.hideCostCenter"
                     title="Cost Center Code" media="lg" field="earning.costCenterCode" class="timecard-pdf-grid">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Cost Center Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.earning?.costCenterCode}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('payRate')" [hidden]="!isVisible('payRate') || !state.isShowPayRates" title="Pay Rate"
                     media="sm" field="payRate" class="numeric-cell timecard-pdf-grid" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Pay Rate</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.payRate | slxDecimal24Switch : isShowHighPrecision | slxMoney}}
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column [width]="getWidth('payRate')" [hidden]="!isVisible('payRate') || !state.isShowPayRates" title="Pay Rate"
    media="sm" field="day.standardPayRate">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Standard Pay Rate</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.day.standardPayRate | slxDecimal24Switch : isShowHighPrecision| slxMoney}}
    </ng-template>

  </kendo-grid-column> -->
  <!-- <kendo-grid-column [filter]="getFilter('shiftCode')" [width]="getWidth('shiftCode')" [hidden]="!isVisible('shiftCode')" title="Shift Code"
    media="sm" field="shiftCode">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Shift Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.shiftCode }}
    </ng-template>
  </kendo-grid-column> -->
  <!-- <kendo-grid-column [filter]="getFilter('costCenterCode')" [width]="getWidth('costCenterCode')" [hidden]="!isVisible('costCenterCode')"
    title="Cost Center Code" media="sm" field="costCenterCode">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Cost Center Code</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.costCenterCode}}
    </ng-template>
  </kendo-grid-column> -->

  <kendo-grid-column *ngFor="let rule of container?.usedRulesDefinitions; let f=first" [hidden]="!isVisible(rule.name, true)" width="150" title="{{rule.name}}"
                     media="sm" [field]="getFieldPath(rule)" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate let-dataItem>
      <i class="pay-code-highlighter" [style.border-color]="getColor(rule)" aria-hidden="true"></i>
      <span slxKendoGridHeaderTooltip>{{rule.description}}</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span *ngIf="f" [classToParent]="{'altered-row': dataItem?.isFirst}" [parentTagName]="'tr'"></span>
      <span *ngIf="dataItem.isMoneyRule(rule.name)"> {{dataItem.getRuleValue(rule.name) | slxDecimal24Switch : isShowHighPrecision | slxMoney}}</span>
      <span *ngIf="!dataItem.isMoneyRule(rule.name)">{{dataItem.getRuleValue(rule.name) | slxDecimal24Switch : isShowHighPrecision}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates| property : rule.uniqName | property : 'sum' | slxDecimal24Switch : isShowHighPrecision }}</div>
      <div class="numeric-group-cell" *ngFor="let rule of firstWeekTotals?.ruleDetails | filter : 'name' : rule.name"><strong *ngIf="aggregates['day.week'].max == 1  && firstWeekTotals?.paymentTransactionType === 'SM' ">{{ rule.hours | slxDecimal24Switch : isShowHighPrecision}}</strong></div>
      <div class="numeric-group-cell" *ngIf="(firstWeekTotals?.ruleDetails | filter : 'name' : rule.name).length == 0"><strong *ngIf="aggregates['day.week'].max == 1  && firstWeekTotals?.paymentTransactionType === 'SM' ">0.00</strong></div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total| property : rule.uniqName | property : 'sum' | slxDecimal24Switch : isShowHighPrecision}}</strong>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column [width]="getWidth('productiveHours')" [hidden]="!isVisible('productiveHours')" title="Productive Hours" media="sm"
                     field="productiveHours" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Productive Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.productiveHours| slxDecimal24Switch : isShowHighPrecision}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['productiveHours']?.sum | slxDecimal24Switch : isShowHighPrecision}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates['productiveHours'].sum | slxDecimal24Switch : isShowHighPrecision}}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell"><strong>{{firstWeekTotals.productiveHours | slxDecimal24Switch : isShowHighPrecision}}</strong></div>
    </ng-template>
  </kendo-grid-column>

   <kendo-grid-column [width]="getWidth('nonProductiveHours')" [hidden]="!isVisible('nonProductiveHours')" title="Non-Productive Hours" media="sm"
                     field="nonProductiveHours" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Non-Productive Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.nonProductiveHours| slxDecimal24Switch : isShowHighPrecision}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['nonProductiveHours']?.sum | slxDecimal24Switch : isShowHighPrecision}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates['nonProductiveHours'].sum | slxDecimal24Switch : isShowHighPrecision}}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell"><strong>{{firstWeekTotals.nonProductiveHours | slxDecimal24Switch : isShowHighPrecision}}</strong></div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [width]="getWidth('totalHours')" [hidden]="!isVisible('totalHours')" title="Total Hours" media="sm"
                     field="totalHours" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.totalHours| slxDecimal24Switch : isShowHighPrecision}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalHours']?.sum | slxDecimal24Switch : isShowHighPrecision}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{aggregates['totalHours'].sum| slxDecimal24Switch : isShowHighPrecision}}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell"><strong>{{firstWeekTotals.totalHours | slxDecimal24Switch : isShowHighPrecision}}</strong></div>
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-column [width]="getWidth('regularHours')" [hidden]="!isVisible('regularHours')" title="Regular Hours" media="sm"
    field="regularHours" class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Regular Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.regularHours | number: ".2-2"}}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell"> {{ aggregates['regularHours']?.sum | number: ".2-2" }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['regularHours']?.sum | number: ".2-2"}}</strong>
    </ng-template>

  </kendo-grid-column> -->

  <kendo-grid-column [width]="getWidth('regularPay')" [hidden]="!isVisible('regularPay')" title="Regular Pay" media="sm" field="regularPay"
                     class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Regular Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.regularPay | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['regularPay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell">&nbsp;</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['regularPay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</strong>
    </ng-template>
  </kendo-grid-column>

  <!-- <kendo-grid-column [width]="getWidth('overtimeAndExtraHours')" [hidden]="!isVisible('overtimeAndExtraHours')" title="Overtime and Extra Hours"
    media="sm" field="overtimeAndOtherHours" class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Overtime and Extra Hours</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.overtimeAndExtraHours | number: ".2-2" }}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['overtimeAndExtraHours']?.sum | number: ".2-2" }}</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['overtimeAndExtraHours']?.sum | number: ".2-2" }}</strong>
    </ng-template>

  </kendo-grid-column> -->
  <kendo-grid-column [width]="getWidth('overTimePay')" [hidden]="!isVisible('overTimePay')" title="Overtime Pay"
                     media="sm" field="overTimePay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Overtime Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.overTimePay | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['overTimePay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell">&nbsp;</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['overTimePay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</strong>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column [width]="getWidth('totalAbsencePay')" [hidden]="!isVisible('totalAbsencePay')" title="Total Paid Absences"
                     media="sm" field="totalAbsencePay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Paid Absences</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.totalAbsencePay | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['totalAbsencePay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell">&nbsp;</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalAbsencePay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</strong>
    </ng-template>

  </kendo-grid-column>
  <kendo-grid-column [width]="getWidth('totalOtherPay')" [hidden]="!isVisible('totalOtherPay')" title="Total Other Pay"
                     media="sm" field="totalOtherPay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Other Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.totalOtherPay | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['totalOtherPay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell">&nbsp;</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalOtherPay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</strong>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column [width]="getWidth('overtimeAndExtraPay')" [hidden]="!isVisible('overtimeAndExtraPay')" title="OT and Other Pay"
                     media="sm" field="overtimeAndExtraPay" class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>OT and Other Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span>{{dataItem?.overtimeAndExtraPay | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</span>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['overtimeAndExtraPay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell">&nbsp;</div>
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['overtimeAndExtraPay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</strong>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-column [hidden]="!isVisible('totalPay')" title="Total Pay" media="sm" [width]="getWidth('totalPay')" field="totalPay"
                     class="numeric-cell timecard-pdf-grid" footerClass="numeric-cell" filter="numeric">
    <ng-template kendoGridHeaderTemplate>
      <span slxKendoGridHeaderTooltip>Total Pay</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-group let-field="field" let-value="value" let-aggregates>
      <div class="numeric-group-cell">{{ aggregates['totalPay']?.sum | slxDecimal24Switch : isShowHighPrecision| slxMoney }}</div>
      <div *ngIf="firstWeekTotals?.paymentTransactionType === 'SM'  && aggregates['day.week'].max == 1" class="numeric-group-cell">&nbsp;</div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [classToParent]="{'altered-row': dataItem?.isFirst}" [parentTagName]="'tr'"></span>
      {{dataItem?.totalPay| slxDecimal24Switch : isShowHighPrecision | slxMoney }}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{ total['totalPay']?.sum | slxDecimal24Switch : isShowHighPrecision | slxMoney }}</strong>
    </ng-template>

  </kendo-grid-column>

  <kendo-grid-pdf fileName="{{exportFilename}}.pdf"
                  paperSize="A4" [scale]="pdfScale" [landscape]="true" [allPages]="true" [repeatHeaders]="true">

    <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>

    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="page-template">
        <div class="header">
          <div class="top-running-title ">
            <span class="ind-timecards-emp__row">
              <span class="ind-timecards-emp__info-i"><strong>Name:</strong>{{container?.employee.name}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Position:</strong> {{container?.primaryPosition.name}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Id:</strong> {{container?.employee.id}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Exempt Ind:</strong> {{container?.exemptStatus?.name}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Pay Policy:</strong> {{container?.payPolicy?.name}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Shift Diff Policy:</strong> {{container?.shiftDiffPolicy?.name}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Payroll #:</strong> {{container?.employee.payrollNumber}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Type:</strong> {{container?.employee.employeeType.name}}</span>
              <span class="ind-timecards-emp__info-i"><strong>Hire Date:</strong> {{container?.employee.dateHired | amDateFormat: appConfig.dateFormat}}</span>
              <span class="ind-timecards-emp__info-i" *ngIf="container?.infoDisplay"><strong>Info:</strong> {{ container?.infoDisplay }}</span>
            </span>
          </div>
        </div>
        <div class="footer">
          Page {{ pageNum }} of {{ totalPages }}
        </div>
      </div>
    </ng-template>

  </kendo-grid-pdf>

</kendo-grid>


<div class="ind-timecards-mobile">
  <section *ngFor="let groupOfWeek of groupsOfWeeks; let i = index" class="collapsible-box">
    <h2 class="collapsible-box__header">
      <span class="collapsible-box__w-name">Week {{ groupOfWeek.weekNumber }}</span>
      <span class="collapsible-box__w-date">({{ groupOfWeek.start | amDateFormat: appConfig.dateFormat }} - {{ groupOfWeek.end | amDateFormat: appConfig.dateFormat }})</span>
      <span *ngIf="groupOfWeek.weekErrors.errors > 0"  class="collapsible-box__w-icons error">
        <span class="collapsible-box__icons-text">{{groupOfWeek.weekErrors.errors }}</span>
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      </span>
      <span *ngIf="groupOfWeek.weekErrors.invalidPunches > 0" class="collapsible-box__w-icons invalid">
        <span class="collapsible-box__icons-text">{{groupOfWeek.weekErrors.invalidPunches }}</span>
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
      <span *ngIf="groupOfWeek.weekErrors.edited > 0"  class="collapsible-box__w-icons">
        <span class="collapsible-box__icons-text">{{groupOfWeek.weekErrors.edited }}</span>
        <i class="fa edited-tc-icon" aria-hidden="true"></i>
      </span>
      <slx-collapse-button class="collapsible-box__w-button"
        [isCollapsed]="getCurrentState(i)"
        (isCollapsedChange)="onChangedState(i)"
      ></slx-collapse-button>
    </h2>
    <div *ngIf="!getCurrentState(i)" class="collapsible-box__body">
      <section *ngFor="let groupOfDays of groupOfWeek.groupsOfDays; let ind = index" class="collapsible-box small-box">
        <h2 class="collapsible-box__header small-box">
          <span class="collapsible-box__w-name small-box">{{ groupOfDays[0].day.weekDay }}, </span>
          <span class="collapsible-box__w-date small-box">{{ groupOfDays[0].day.date | amDateFormat: appConfig.dateFormat }}</span>
          <span *ngIf="groupOfDays[0].isError" class="collapsible-box__w-icons error">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span class="collapsible-box__icons-text small-box">Missing punch</span>
          </span>
          <span *ngIf="groupOfDays[0].isInvalidPunch || groupOfDays[0].isNoShow" class="collapsible-box__w-icons invalid">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <span class="collapsible-box__icons-text small-box">Invalid punch</span>
          </span>
          <span *ngIf="groupOfDays[0].isEdited" class="collapsible-box__w-icons">
            <i class="fa edited-tc-icon mobile" aria-hidden="true"></i>
            <span class="collapsible-box__icons-text small-box">Edited timecard</span>
          </span>

          <slx-collapse-button class="collapsible-box__w-button"
            [isCollapsed]="getCurrentState(i, ind)"
            (isCollapsedChange)="onChangedState(i, ind)"
          ></slx-collapse-button>
        </h2>
        <div *ngIf="!getCurrentState(i, ind)" class="collapsible-box__body small-box">
          <ng-container *ngFor="let record of groupOfDays">
            <div class="ind-timecards-mobile__grid">
              <p class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Day:</label>
                <span class="ind-timecards-mobile__g-value">{{ record?.day.weekDay }}</span>
              </p>
              <p class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Date:</label>
                <a class="ind-timecards-mobile__g-value link" [dailyTimecardsLink]="container?.employee?.id" [timecardDate]="record?.day.date">
                  {{ record?.day.date | amDateFormat: appConfig.dateFormat }}
                </a>
              </p>
              <p class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Schedule:</label>
                <a class="ind-timecards-mobile__g-value link" scheduleEntryLink [employeeId]="container?.employee.id" [dateOn]="record?.day.date">
                  {{record?.day.shiftNames }}
                </a>
              </p>
              <p class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Punch In:</label>
                <span class="ind-timecards-mobile__g-value">{{record?.day.punchInTime | amDateFormat: appConfig.timeFormat }}</span>
              </p>
              <p class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Punch Out:</label>
                <span class="ind-timecards-mobile__g-value">{{record?.day.punchOutTime | amDateFormat: appConfig.timeFormat }}</span>
              </p>
              <p class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Approved:</label>
                <span class="ind-timecards-mobile__g-value">{{record?.day.approved | boolyn}}</span>
              </p>
              <p class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Position:</label>
                <span class="ind-timecards-mobile__g-value">{{record?.day.positionName}}</span>
              </p>
              <p *ngIf="state.isShowPayRates" class="ind-timecards-mobile__g-item">
                <label class="ind-timecards-mobile__g-label">Pay Rate:</label>
                <span class="ind-timecards-mobile__g-value">{{record?.payRate |  number: ".2-2" | slxMoney}}</span>
              </p>
            </div>
            <slx-pay-codes-grid class="ind-timecards-mobile__pay-codes" [payCodeModel]="getPayCodeModel(record)" [ruleList]="container?.usedRulesDefinitions"></slx-pay-codes-grid>
          </ng-container>
        </div>
      </section>
    </div>
  </section>
</div>
