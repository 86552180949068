import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { ChangeManagementService } from '../../../common/index';
import { EmployersContainer, AleGroup } from '../../models/employers/index';
import { EmployersConfigurationApiService } from '../employers/employers-configuration-api.service';
import { EmployersConfigurationMapService } from '../employers/employers-configuration-map.service';
import { Assert } from '../../../framework/index';
var EmployersManagementService = /** @class */ (function () {
    function EmployersManagementService(changeService, api, apiMapService) {
        this.changeService = changeService;
        this.api = api;
        this.apiMapService = apiMapService;
        this.aleGroups$ = new ReplaySubject(1);
        this.loadingAleGroup$ = new Subject();
        this.isSaveComplete$ = new Subject();
        this.closePopup$ = new Subject();
        this.defaultGroupId = 0;
        this.defaultGroupName = 'ALE Group 1';
        this.aleGroups$ = new ReplaySubject(1);
        this.onLoaded$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
        this.assign_group = [];
    }
    EmployersManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (_.isNumber(orgLevel.id)) {
                _this.currentOrgLevel = orgLevel;
                _this.orgLevelId = orgLevel.id;
                _this.onStateChanged$.next({ orgLevelChanged: true });
                _this.fetchRecords();
            }
        });
    };
    EmployersManagementService.prototype.subscribeToLoadingAleGroup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadingAleGroup$.subscribe(callback);
    };
    EmployersManagementService.prototype.subscribeToOnLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onLoaded$.subscribe(callback);
    };
    EmployersManagementService.prototype.subscribeToOnStateChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onStateChanged$.subscribe(callback);
    };
    EmployersManagementService.prototype.subscribeToLoadAleGroups = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.aleGroups$.subscribe(callback);
    };
    EmployersManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    EmployersManagementService.prototype.onSaveEmployer = function (item) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.onStateChanged$.next({ configureMode: true, isLoading: true });
                        return [4 /*yield*/, this.api.postEmployers(item.orgLevelId, item).
                                then(function (result) {
                                _this.orgLevelId = item.orgLevelId;
                                _this.loadEmployers();
                                _this.onStateChanged$.next({ configureMode: false, isLoading: true });
                            }).catch(function () {
                                _this.onStateChanged$.next({ configureMode: true, isLoading: true });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployersManagementService.prototype.loadEmployers = function () {
        this.fetchRecords();
    };
    EmployersManagementService.prototype.mapALeGroups = function (groups) {
        var aleGroupList = [];
        var aleDefaultGroup = new AleGroup();
        aleDefaultGroup.groupId = this.defaultGroupId;
        aleDefaultGroup.groupName = this.defaultGroupName;
        aleGroupList.push(aleDefaultGroup);
        aleGroupList = aleGroupList.concat(groups);
        return aleGroupList;
    };
    EmployersManagementService.prototype.fetchRecords = function () {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getEmployers(this.orgLevelId).
            then(function (result) {
            _this.m_container = new EmployersContainer();
            _this.m_container = result;
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchAleGroups();
        }).catch(function () {
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    EmployersManagementService.prototype.fetchAleGroups = function () {
        var _this = this;
        this.loadingAleGroup$.next(true);
        this.api.getAleGroups(this.orgLevelId).then(function (groups) {
            _this.m_container.aleGroupList = _this.mapALeGroups(groups);
            _this.onLoaded$.next(_this.m_container);
            var aleGroupView = _this.apiMapService.mapToPositionGroupView(_this.m_container.records, groups, _this.defaultGroupId, _this.defaultGroupName);
            _this.aleGroups$.next(aleGroupView);
            _this.loadingAleGroup$.next(false);
        }).catch(function () {
            _this.loadingAleGroup$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    EmployersManagementService.prototype.filteringGroups = function (g, srcText) {
        var _this = this;
        var groups = _.cloneDeep(g);
        if (_.size(srcText) > 0) {
            var searchText_1 = _.toLower(srcText);
            return _.reduce(groups, function (accum, groupView) {
                var isDefaultGroup = groupView.groupId === _this.defaultGroupId;
                var employers = _.filter(groupView.employers, function (pos) { return _.includes(_.toLower(pos.legalName), searchText_1); });
                if (isDefaultGroup || !isDefaultGroup && _.size(employers) > 0) {
                    accum.push(groupView);
                    groupView.employers = employers;
                }
                return accum;
            }, []);
        }
        return groups;
    };
    EmployersManagementService.prototype.assignGroupsToEmployers = function (group, employer) {
        employer.aleGroupId = group.groupId === this.defaultGroupId ? null : group.groupId;
        var groups = this.apiMapService.mapToGroupsWithEmployers(group, employer, this.defaultGroupId, this.defaultGroupName);
        _.forEach(this.assign_group, function (group) {
            group.companyIds = group.companyIds.filter(function (s) { return s != employer.companyId; });
        });
        this.assign_group.push({ aleGroupId: groups.groupId, companyIds: groups.employerIds });
    };
    EmployersManagementService.prototype.saveGroupAssignments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loadingAleGroup$.next(true);
                this.api.saveAssignGroup(this.currentOrgLevel.id, this.assign_group).
                    then(function (result) {
                    _this.isSaveComplete$.next();
                    _this.loadingAleGroup$.next(false);
                }).catch(function () {
                    _this.loadingAleGroup$.next(false);
                });
                return [2 /*return*/];
            });
        });
    };
    EmployersManagementService.prototype.addUpdateGroup = function (g) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groupView, posGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loadingAleGroup$.next(true);
                        groupView = null;
                        this.onStateChanged$.next({ isLoading: true });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.api.addUpdateGroup(this.currentOrgLevel.id, g)];
                    case 2:
                        posGroup = _a.sent();
                        groupView = this.apiMapService.makePosViewOfPosGroup(posGroup);
                        return [3 /*break*/, 4];
                    case 3:
                        this.onStateChanged$.next({ isLoading: false });
                        this.loadingAleGroup$.next(false);
                        return [2 /*return*/, groupView];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EmployersManagementService.prototype.updateGroup = function (g) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var groupView, posGroup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        groupView = null;
                        this.loadingAleGroup$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.api.addUpdateGroup(this.currentOrgLevel.id, g)];
                    case 2:
                        posGroup = _a.sent();
                        groupView = this.apiMapService.makePosViewOfPosGroup(posGroup);
                        return [3 /*break*/, 4];
                    case 3:
                        this.loadingAleGroup$.next(false);
                        return [2 /*return*/, groupView];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EmployersManagementService.prototype.deleteGroup = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = null;
                        this.loadingAleGroup$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.api.addUpdateGroup(this.currentOrgLevel.id, group)];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.loadingAleGroup$.next(false);
                        return [2 /*return*/, result];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EmployersManagementService.prototype.createGroupView = function () {
        return this.apiMapService.createGroupView(null, '', this.currentOrgLevel.relatedItemId);
    };
    EmployersManagementService.prototype.requestClosePopup = function () {
        var _this = this;
        this.changeService.canMoveForward().then(function (canMove) {
            if (!canMove) {
                return;
            }
            _this.closePopup();
        });
    };
    EmployersManagementService.prototype.closePopup = function () {
        this.changeService.clearChanges();
        this.changeService.clearCurrentComponentId();
        this.closePopup$.next();
    };
    EmployersManagementService.prototype.subscribeToClosePopup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.closePopup$.subscribe(callback);
    };
    EmployersManagementService.prototype.subscribeToSaveComplete = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.isSaveComplete$.subscribe(callback);
    };
    EmployersManagementService.prototype.destroy = function () {
        this.aleGroups$.complete();
        this.loadingAleGroup$.complete();
        this.isSaveComplete$.complete();
        this.closePopup$.complete();
        this.currentOrgLevel = null;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployersManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployersManagementService.prototype, "orgLevelSubscription", void 0);
    return EmployersManagementService;
}());
export { EmployersManagementService };
