import * as _ from 'lodash';
import { EventEmitter } from '@angular/core';
import { PopperContent } from 'ngx-popper';
var ActionButtonComponent = /** @class */ (function () {
    function ActionButtonComponent() {
        this.popperPosition = 'bottom-start';
        this.popperOnShow = new EventEmitter();
        this.popperOnHide = new EventEmitter();
        this.fullyCustomStyle = false;
        this.escCode = 27;
        this.defaultCssClasse = 'slx-action-button';
    }
    ActionButtonComponent.prototype.onKeyup = function (event) {
        if (event.keyCode === this.escCode) {
            this.popperContent.hide();
        }
    };
    ActionButtonComponent.prototype.onShown = function (popper) {
        this.popperOnShow.emit(popper);
    };
    ActionButtonComponent.prototype.onHidden = function (popper) {
        this.popperOnHide.emit(popper);
    };
    Object.defineProperty(ActionButtonComponent.prototype, "className", {
        get: function () {
            var classes = [];
            if (_.isString(this.customClassName) && _.size(this.customClassName) > 0) {
                classes.push(this.customClassName);
            }
            if (!this.fullyCustomStyle) {
                classes.push(this.defaultCssClasse);
            }
            return classes.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    return ActionButtonComponent;
}());
export { ActionButtonComponent };
