import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsI9 {
  listA: {
    usPassport: boolean;
    usCitizenshipCertificate: boolean;
    naturalizationCertificate: boolean;
    foreignPassportWithEmploymentAuth: boolean;
    alienRegistrationCard: boolean;
    documentId: string;
    documentExpiration: string;
  };
  listB: {
    stateIssuedDriversLicense: boolean;
    usMilitaryCard: boolean;
    isOther: boolean;
    other: string;
    documentId: string;
    documentExpiration: string;
  };
  listC: {
    originalSSNCard: boolean;
    birthCertificate: boolean;
    unexpiredINSEmploymentAuth: boolean;
    documentId: string;
    documentExpiration: string;
  };
  additionalInfo: {
    usCitizen: boolean;
    nonUsCitizen: boolean;
    lawfulPermanentResident: boolean;
    alienAuthorizedToWork: boolean;
    alienNumber: string;
    alienOrAdmissionNumber: string;
    expiration: string;
  };
}

export class EmployeeSectionsI9ListA {
  public type: I9ListATypes;
  public documentId: string;
  public documentExpiration: Date;

  public clear(): void {
    this.type = -1;
    this.documentId = null;
    this.documentExpiration = null;
  }
}

export class EmployeeSectionsI9ListB {
  public type: I9ListBTypes;
  public other: string;
  public documentId: string;
  public documentExpiration: Date;

  public clear(): void {
    this.type = -1;
    this.other = null;
    this.documentId = null;
    this.documentExpiration = null;
  }
}

export class EmployeeSectionsI9ListC {
  public type: I9ListCTypes;
  public documentId: string;
  public documentExpiration: Date;

  public clear(): void {
    this.type = -1;
    this.documentId = null;
    this.documentExpiration = null;
  }
}

export class EmployeeSectionsI9AdditionalInfo {
  public type: I9AdditionalInfoTypes;
  public alienNumber: string;
  public alienOrAdmissionNumber: string;
  public expiration: Date;

  public clear(): void {
    this.type = -1;
    this.alienNumber = null;
    this.alienOrAdmissionNumber = null;
    this.expiration = null;
  }
}

export class EmployeeSectionsI9 extends EmployeeSectionsBase {
  public listA: EmployeeSectionsI9ListA = new EmployeeSectionsI9ListA();
  public listB: EmployeeSectionsI9ListB = new EmployeeSectionsI9ListB();
  public listC: EmployeeSectionsI9ListC = new EmployeeSectionsI9ListC();
  public additionalInfo: EmployeeSectionsI9AdditionalInfo = new EmployeeSectionsI9AdditionalInfo();
}

export enum I9ListATypes {
  UsPassport,
  UsCitizenshipCertificate,
  NaturalizationCertificate,
  ForeignPassportWithEmploymentAuth,
  AlienRegistrationCard
}

export enum I9ListBTypes {
  StateIssuedDriversLicense,
  UsMilitaryCard,
  IsOther
}

export enum I9ListCTypes {
  OriginalSSNCard,
  BirthCertificate,
  UnexpiredINSEmploymentAuth
}

export enum I9AdditionalInfoTypes {
  UsCitizen,
  NonUsCitizen,
  LawfulPermanentResident,
  AlienAuthorizedToWork
}
