import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { mutableSelect } from '../../../core/decorators/index';

import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { LookupEntity } from '../../../organization/models/index';
import { LookupApiService } from '../../../organization/services/index';
import { organizationConfig } from '../../../organization/organization.config';

import { Filter, Group } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-filter-and-groups',
  templateUrl: 'filter-and-groups.component.html',
  styleUrls: ['filter-and-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterAndGroupsComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @Input()
  public filters: Filter[];

  @Input()
  public groups: Group[];

  @Output()
  public onFiltersChange: EventEmitter<Filter[]>;

  @Output()
  public onGroupsChange: EventEmitter<Group[]>;

  public restrictedColumns: string[];
  public filterLookups: StringMap<Observable<LookupEntity[]>>;

  private lookupApiService: LookupApiService;
  private lookupMapping: StringMap<string>;
  private defaultGroups: Group[];
  private defaultFilters: Filter[];
  private orgLevelSubscription: Subscription;

  constructor(lookupApiService: LookupApiService) {
    this.lookupApiService = lookupApiService;
    this.filterLookups = {};

    this.lookupMapping = {
      'Position': 'position',
      'Shift': 'shift',
      'Unit': 'unit'
    };

    this.restrictedColumns = ['Position', 'Shift', 'Unit'];
    this.onGroupsChange = new EventEmitter<Group[]>();
    this.onFiltersChange = new EventEmitter<Filter[]>();
  }

  public ngOnInit(): void {
    this.defaultFilters = _.clone(this.filters);
    this.defaultGroups = _.clone(this.groups);

    this.orgLevelSubscription = this.orgLevel$
      .filter((selectedOrgLevel: OrgLevel) => selectedOrgLevel.type === OrgLevelType.department)
      .subscribe((orgLevel: OrgLevel) => {
        this.groups.forEach((group: Group) => {
          let lookupMapping: string = this.lookupMapping[group.fieldName];
          this.filterLookups[group.fieldName] = Observable.fromPromise(this.lookupApiService.getLookup(organizationConfig.lookup[lookupMapping], orgLevel.id));
        });
      });
  }

  public ngOnDestroy(): void {
    this.orgLevelSubscription.unsubscribe();
  }

  public isFilteredBy(fieldName: string, value: number): boolean {
    let applyedFilter: Filter = _.find(this.filters, (filter: Filter) => filter.fieldName === fieldName);
    return applyedFilter && _.includes(applyedFilter.value, value);
  }

  public addFilter(fieldName: string, value: number): void {
    let filter: Filter = _.find(this.filters, (f: Filter) => f.fieldName === fieldName);
    if (!filter) {
      filter = new Filter();
      filter.fieldName = fieldName;
      filter.value = [value];
      this.filters.push(filter);
    } else if (_.includes(filter.value, value)) {
      _.pull(filter.value, value);
      if (!filter.value.length) {
        _.remove(this.filters, { fieldName: fieldName });
      }
    } else {
      filter.value.push(value);
    }
    this.onFiltersChange.emit(this.filters);
  }

  public removeGroupAndFilter(fieldName: string): void {
    _.remove(this.filters, { fieldName: fieldName });
    _.remove(this.groups, { fieldName: fieldName });
    this.onGroupsChange.emit(this.groups);
    this.onFiltersChange.emit(this.filters);
  }

  public getGroupingFilter(): StringMap<any> {
    return this.groups.map((group: Group) => group.fieldName);
  }

  public restoreDefaultFiltersAndGroupings(): void {
    this.groups = _.clone(this.defaultGroups);
    this.filters = _.clone(this.defaultFilters);
    this.onGroupsChange.emit(this.groups);
    this.onFiltersChange.emit(this.filters);
  }

  public addGroup(fieldName: string): void {
    let group: Group = new Group();
    group.fieldName = fieldName;
    this.groups.push(group);
    this.onGroupsChange.emit(this.groups);
  }

  public getAddGroupFilter(): StringMap<any> {
    let groupNames: string[] = this.groups.map((group: Group) => group.fieldName);
    return this.restrictedColumns.filter((columnName: string) => !_.includes(groupNames, columnName));
  }

  public isFilter(fieldName: string): boolean {
    return this.filters.map((filter: Filter) => filter.fieldName).includes(fieldName);
  }
}
