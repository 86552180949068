export interface ITAAbsence {
    id: number;
    code: string;
    description: string;
    color: number;
}

export class TAAbsence {
    public id: number;
    public code: string;
    public description: string;
    public color: number;

    public get codeDescription(): string {
        return `[${this.code}] ${this.description}`;
    }
}
