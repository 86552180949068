import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';

import { TOOLBAR_SERVICE } from '../../../core/services/index';
import { IdealScheduleStateService, IdealScheduleToolbarService } from '../../services/index';
import { StateManagementService, ComponentStateStorageService } from '../../../common/index';
import { IControlState, StateResetTypes, ControlStateKey } from '../../../core/models/index';
import { IdealScheduleConfigCensusDefinition } from '../../models/index';
import { IdealSchedulePositionRange } from '../../models/ideal-schedule/ideal-schedule-position-range';
import * as _ from 'lodash';
import { IdealScheduleToolbarComponent } from './ideal-schedule-toolbar/ideal-schedule-toolbar.component';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import * as XLSX from 'xlsx';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
@Component({
  moduleId: module.id,
  selector: 'ideal-schedule',
  templateUrl: 'ideal-schedule.component.html',
  styleUrls: ['ideal-schedule.component.scss'],
  providers: [{ provide: TOOLBAR_SERVICE, useClass: IdealScheduleToolbarService }, StateManagementService],
})
export class IdealScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  public IdealScheduleConfigCensusDefinition = IdealScheduleConfigCensusDefinition;
  // @ViewChild('popupRef', { static: false }) popupRefChild: IdealScheduleToolbarComponent;

  public grid: IdealScheduleToolbarComponent;
  private stateKey: ControlStateKey;
  private readonly m_hideUnusedControlId: string = 'HideUnused';
  private readonly m_hideUnusedControlResetType: StateResetTypes = StateResetTypes.None;
  idealScheduleData: any;
   public scheduleDetails = [];
  days: any;
  evening: any;
  night: any;
  rowsData: any[];
  dateTime: string;
  isDisabled: boolean=false;
  constructor(
    @Inject(TOOLBAR_SERVICE) private idealScheduleToolbarService: IdealScheduleToolbarService,
    public stateService: IdealScheduleStateService,
    public stateManagement: StateManagementService,
    public storageService: ComponentStateStorageService,
    public router: Router
  ) {
    this.router.events.pipe(filter((e: RouterEvent) => e instanceof NavigationEnd)).subscribe({
      next: (e: NavigationEnd) => {
        if (e.url.includes('configure_ideal_schedule')) {
          this.stateService.discardChanges();
        }
      },
    });
  }

  public ngOnInit(): void {
    this.stateManagement.init('IdealScheduleComponent', false);

    this.stateManagement.onInit$.subscribe(() => {
      this.restoreState();
      this.stateService.subscribe();
    });

    this.stateService.onFiltersChanged$.subscribe(() => {
      this.saveState();
    });
  }

  public ngOnDestroy(): void {
    this.stateService.unsubscribe();
  }

  public get ranges(): IdealSchedulePositionRange[] {
    if(this.stateService && this.stateService.selectedPosition)  {
      return this.stateService.selectedPosition.ranges;
    }
    return [];
  }

  private restoreState(): void {
    let state: IControlState = this.storageService.getControlState(
      this.stateManagement.componentKey,
      this.m_hideUnusedControlId
    );

    if (state) {
      this.stateService.hideUnused = state.value;
    }
  }

  private saveState(): void {
    this.storageService.setControlState(
      this.stateManagement.componentKey,
      this.m_hideUnusedControlId,
      {
        value: this.stateService.hideUnused
      },
      this.m_hideUnusedControlResetType,
      this.stateKey
    );

    this.stateManagement.controlValueChanged(this.m_hideUnusedControlId);
  }

  captureIdealScheduleScreen(){
    let data = document.getElementById('contentToConvert');
    html2canvas(data).then((canvas) => {
      // Few necessary setting options
      let imgWidth = 210;
      let pageHeight = 295;
      let imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');

      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
      let position = 10;
      // let _selectedDateVal = document.getElementById('_selectedDateVal').innerText;
      let current = new Date();
      let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
      let cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
      this.dateTime = cDate + ' ' + cTime;

      pdf.setFontSize(10);
      // pdf.text(dateTime, 7, 7);
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save('Ideal_Schedule' +this.dateTime+ '.pdf'); // Generated PDF
    });

  }

  public exportToExcel(): void {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let range = this.stateService.selectedPosition.ranges[0];
    let current = new Date();
  let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
  let cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
  this.dateTime = cDate + ' ' + cTime;

    if(range.parLevels!=null || range!=undefined ){
    let allKeys = Object.keys(range.parLevels);
    let allValues = Object.values(range.parLevels);
    this.scheduleDetails = [];
     for(let r=0; r<allKeys.length; r++){
      this.scheduleDetails.push(allValues)
      }
      let idealSchedule= this.scheduleDetails[0];
      if(idealSchedule.length >0 && idealSchedule!=undefined){
        this.days =  idealSchedule.filter(x=> x.uniqueId.includes('Day'));
        this.evening =  idealSchedule.filter(x=> x.uniqueId.includes('Evening'));
        this.night =  idealSchedule.filter(x=> x.uniqueId.includes('Night'));
      console.log('scheduleDetails', this.scheduleDetails, this.days, this.evening, this.night)
      }
        this.rowsData = [];
    if(idealSchedule.length>0){
      // const loadValue= settingTotalHours(this.days);

      this.rowsData.push({"":"Day"});
      if(this.days.length > 0 && this.days.length!=null && this.days.length!=undefined){

      this.days.map(x => {
        this.rowsData.push({
          "Shift": x.shift.name + '  '+ x.shift.duration,
          "Unit": x.unit!=null ? x.unit.name :0,
          "Sunday":  x.days[1].counter + '  '+ x.days[1].hours+'Hours',
          "Monday":  x.days[2].counter + '  '+ x.days[2].hours+'Hours',
          "Tuesday":  x.days[3].counter + '  '+ x.days[3].hours+'Hours',
          "Wednesday":  x.days[4].counter + '  '+ x.days[4].hours+'Hours',
          "Thursday":  x.days[5].counter + '  '+ x.days[5].hours+'Hours',
          "Friday":  x.days[6].counter + '  '+ x.days[6].hours+'Hours',
          "Saturday":  x.days[7].counter + '  '+ x.days[7].hours+'Hours',
        })
      });
    }
      else this.rowsData.push({ "": 'No records found.'});

      this.rowsData.push({"":"Evening"});
      if(this.evening.length > 0 && this.evening.length!=null && this.evening.length!=undefined)
      this.evening.map(x => {
        this.rowsData.push({

          "Shift": x.shift.name + '  '+ x.shift.duration,

          "Unit": x.unit.name,
          "Sunday":  x.days[1].counter + '  '+ x.days[1].hours+'Hours',
          "Monday":  x.days[2].counter + '  '+ x.days[2].hours+'Hours',
          "Tuesday":  x.days[3].counter + '  '+ x.days[3].hours+'Hours',
          "Wednesday":  x.days[4].counter + '  '+ x.days[4].hours+'Hours',
          "Thursday":  x.days[5].counter + '  '+ x.days[5].hours+'Hours',
          "Friday":  x.days[6].counter + '  '+ x.days[6].hours+'Hours',
          "Saturday":  x.days[7].counter + '  '+ x.days[7].hours+'Hours',
        })
      });
      else this.rowsData.push({ "": 'No records found.'});

      this.rowsData.push({"":"Night"});
      if(this.night.length > 0 && this.night.length!=null && this.night.length!=undefined)
      this.night.map(x => {
        this.rowsData.push({
          "Shift": x.shift.name + '  '+ x.shift.duration,
          "Unit": x.unit.name,
          "Sunday":  x.days[1].counter + '  '+ x.days[1].hours+'Hours',
          "Monday":  x.days[2].counter + '  '+ x.days[2].hours+'Hours',
          "Tuesday":  x.days[3].counter + '  '+ x.days[3].hours+'Hours',
          "Wednesday":  x.days[4].counter + '  '+ x.days[4].hours+'Hours',
          "Thursday":  x.days[5].counter + '  '+ x.days[5].hours+'Hours',
          "Friday":  x.days[6].counter + '  '+ x.days[6].hours+'Hours',
          "Saturday":  x.days[7].counter + '  '+ x.days[7].hours+'Hours',
        })
      });
      else this.rowsData.push({ "": 'No records found.' });
    }
    }
    else{
      this.rowsData=[];
      this.rowsData.push({"":"Day"});
      this.rowsData.push({ "": 'No records found.'});
      this.rowsData.push({"":"Evening"});
      this.rowsData.push({ "": 'No records found.'});
      this.rowsData.push({"":"Night"});
      this.rowsData.push({ "": 'No records found.'});
    }
       const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.rowsData);


       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
       const wscols = [
        { wch: 10 },
        { wch: 20 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },
        ];
        ws['!cols'] = wscols;

    XLSX.writeFile(wb, 'Ideal Schedule'+ this.dateTime +'.xlsx');

}
public checkData(): void {
  this.isDisabled = this.stateService.selectedPosition.ranges[0].parLevels!=null && Object.keys(this.stateService.selectedPosition.ranges[0].parLevels).length!=0  && this.stateService.selectedPosition.ranges[0]!=undefined? false : true;
  }
}
