var ScheduleAbsence = /** @class */ (function () {
    function ScheduleAbsence() {
    }
    Object.defineProperty(ScheduleAbsence.prototype, "codeDescription", {
        get: function () {
            return "[" + this.code + "] " + this.description;
        },
        enumerable: true,
        configurable: true
    });
    return ScheduleAbsence;
}());
export { ScheduleAbsence };
