import { Component, Input, Output, EventEmitter } from '@angular/core';
import { RecipientInfo, RecipientType, SendShiftSmsRequest, SentMessageAttributes } from '../../../models/index';
import { EmployeeMessagesApiService } from '../../../services/index';

import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-send-shift-sms',
  templateUrl: 'send-shift-sms.component.html',
  styleUrls: ['send-shift-sms.component.scss']
})
export class SendShiftSmsComponent {
  public set recipientsInfo(value: RecipientInfo[]) {
    this.m_recipientsInfo = value;
    if (value && value.length > 0) {
      this.selectedRecipients.push(_.first(value));
    }
  }

  public get recipientsInfo(): RecipientInfo[] {
    return this.m_recipientsInfo;
  }

  @Input()
  public set sendSmsRequest(value: SendShiftSmsRequest) {
    this.m_sendShiftSmsRequest = value;
    if(value) {
      this.recipientsInfo = value.recipients;
    }
  }

  @Output()
  public sendCanceled: EventEmitter<any>;

  @Output()
  public messageSent: EventEmitter<SentMessageAttributes>;

  @Output()
  public errorOccured: EventEmitter<any>;

  public get sendSmsRequest(): SendShiftSmsRequest {
    return this.m_sendShiftSmsRequest;
  }

  public messageText: string;
  public get selectedRecipients(): RecipientInfo[] {
    return this.m_selectedRecipients;
  }

  public set selectedRecipients(value: RecipientInfo[]) {
    this.m_selectedRecipients = value;
  }

  public state = {
    inProcess: false
  };

  private m_recipientsInfo: RecipientInfo[];
  private m_sendShiftSmsRequest: SendShiftSmsRequest;
  private m_selectedRecipients: RecipientInfo[] = [];

  constructor(private employeeMessagesApiService: EmployeeMessagesApiService) {
    this.sendCanceled = new EventEmitter<any>();
    this.messageSent = new EventEmitter<SentMessageAttributes>();
    this.errorOccured = new EventEmitter<any>();
  }

  public send(): void {
    this.state.inProcess = true;
    this.employeeMessagesApiService
      .sendShiftSms(this.sendSmsRequest).then((messageId: number): void => {
        let sentAttributes: SentMessageAttributes = new SentMessageAttributes();
        sentAttributes.messageId = messageId;
        this.state.inProcess = false;
        this.messageSent.emit(sentAttributes);
      }).catch((error: any): void => {
        this.state.inProcess = false;
        this.errorOccured.emit();
      });
  }

  public cancel(): void {
    this.sendCanceled.emit();
  }
}

