import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy, Host, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { EmployeeSubsectionDependents, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-dependents',
  templateUrl: 'employee-sections-dependents.component.html',
  styleUrls: ['employee-sections-dependents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsDependentsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {
  @Input()
  public set employeeSubsectionDependents(dependents: EmployeeSubsectionDependents) {
    if (_.isObjectLike(dependents)) {
      this.dependentSection = dependents;
      this.changedInputs$.next({ dependents });
    }
  }

  @Input()
  public set employeeId(empId: number) {
    if (_.isFinite(empId)) {
      this.empId = empId;
      this.changedInputs$.next({ empId });
    }
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  public get form(): AbstractControl {
    return null;
  }

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private changedInputs$ = new ReplaySubject<{ empId?: number, dependents?: EmployeeSubsectionDependents }>(1);
  private dependentSection: EmployeeSubsectionDependents;
  private empId: number;

  constructor(
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private manService: EmployeeSectionsBenefitsManagementService,
    private changeDetector: ChangeDetectorRef
  ) {
    super(decorator, ngZone);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.manService.init();

    this.subscriptions.inputs = this.changedInputs$.subscribe((data) => {
      if (_.isFinite(data.empId)) {
        this.manService.changeEmpId(data.empId);
      }
      if (_.isObjectLike(data.dependents) && _.size(data.dependents) > 0) {
        this.manService.changeDependents(data.dependents);
      }
    });

    this.subscriptions.spinner = this.manService.subscribeToSpinner((isShown: boolean) => {
      if (isShown) {
        this.startProgress();

        return;
      }
      this.stopProgress();
      this.changeDetector.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.manService.destroy();
    this.changedInputs$.complete();
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.dependentSection;
  }

  protected loadSubsection(): void {}
}
