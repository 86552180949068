import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { Aca1095cMapService } from './aca-1095-c-map.service';
import { HttpRequest } from '@angular/common/http';
import { configurationConfig } from '../../configuration.config';
var Aca1095cApiService = /** @class */ (function () {
    function Aca1095cApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    Aca1095cApiService.prototype.getAca1095cRecords = function (orgLevelId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAca1095cApi(orgLevelId, year);
                request = this.urlParamsService.createGetRequest(url, {
                    year: year
                });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAca1095cRecords(response.data, response.meta); })];
            });
        });
    };
    Aca1095cApiService.prototype.addEmployee1095c = function (orgLevelId, year, empMasterId, empId, companyId, departmentId, organizationId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, requestBody, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAcaAddEmployee1095cApi(orgLevelId, year);
                requestBody = { 'year': year, 'employeeMasterId': empMasterId, 'employeeId': empId, 'companyId': companyId, 'departmentId': departmentId, 'organizationId': organizationId };
                request = this.urlParamsService.createPostRequest(url, requestBody);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAca1095cRecords(response.data, response.meta); })];
            });
        });
    };
    Aca1095cApiService.prototype.removeEmployee1095c = function (orgLevelId, year, empMasterId, empId, companyId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, requestBody, params, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAcaRemoveEmployee1095cApi(orgLevelId, year);
                requestBody = { 'year': year, 'employeeMasterId': empMasterId, 'employeeId': empId, 'companyId': companyId, 'departmentId': 0, 'organizationId': 0 };
                params = {};
                request = this.urlParamsService.createDeleteRequest(url, params, requestBody);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAca1095cRecords(response.data, response.meta); })];
            });
        });
    };
    Aca1095cApiService.prototype.edit1095cRecords = function (orgLevelId, year, requestBody) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAca1095cEditApi(orgLevelId, year);
                request = new HttpRequest('PUT', url, requestBody);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAca1095cRecords(response.data, response.meta); })];
            });
        });
    };
    Aca1095cApiService.prototype.editReset1095cRecords = function (orgLevelId, year, requestBody) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAca1095cEditResetApi(orgLevelId, year);
                request = new HttpRequest('PUT', url, requestBody);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAca1095cRecords(response.data, response.meta); })];
            });
        });
    };
    Aca1095cApiService.prototype.confirm1095cRecords = function (orgLevelId, year, requestBody) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAca1095cConfirmApi(orgLevelId, year);
                request = new HttpRequest('PUT', url, requestBody);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAca1095cRecords(response.data, response.meta); })];
            });
        });
    };
    Aca1095cApiService.prototype.confirmReset1095cRecords = function (orgLevelId, year, requestBody) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAca1095cConfirmResetApi(orgLevelId, year);
                request = new HttpRequest('PUT', url, requestBody);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAca1095cRecords(response.data, response.meta); })];
            });
        });
    };
    Aca1095cApiService.prototype.getAcaAddEmployee1095cApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095.root + "/" + orgLevelId + "/" + configurationConfig.api.ACA.c1095.addEmployee;
    };
    Aca1095cApiService.prototype.getAcaRemoveEmployee1095cApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095.root + "/" + orgLevelId + "/" + configurationConfig.api.ACA.c1095.removeEmployee;
    };
    Aca1095cApiService.prototype.getAca1095cApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095.root + "/" + orgLevelId + "/" + year + "/" + configurationConfig.api.ACA.c1095.all;
    };
    Aca1095cApiService.prototype.getAca1095cEditApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095.root + "/" + orgLevelId + "/" + year + "/" + configurationConfig.api.ACA.c1095.edit1095cRecords;
    };
    Aca1095cApiService.prototype.getAca1095cEditResetApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095.root + "/" + orgLevelId + "/" + year + "/" + configurationConfig.api.ACA.c1095.editReset1095cRecords;
    };
    Aca1095cApiService.prototype.getAca1095cConfirmApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095.root + "/" + orgLevelId + "/" + year + "/" + configurationConfig.api.ACA.c1095.confirm1095cRecords;
    };
    Aca1095cApiService.prototype.getAca1095cConfirmResetApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095.root + "/" + orgLevelId + "/" + year + "/" + configurationConfig.api.ACA.c1095.confirmReset1095cRecords;
    };
    Aca1095cApiService.prototype.getAcaRootApi = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root;
    };
    Aca1095cApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return Aca1095cApiService;
}());
export { Aca1095cApiService };
