import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsRateHistoryRecord = /** @class */ (function () {
    function EmployeeSectionsRateHistoryRecord() {
    }
    return EmployeeSectionsRateHistoryRecord;
}());
export { EmployeeSectionsRateHistoryRecord };
var EmployeeSectionsRateHistory = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsRateHistory, _super);
    function EmployeeSectionsRateHistory() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    return EmployeeSectionsRateHistory;
}(EmployeeSectionsBase));
export { EmployeeSectionsRateHistory };
