import * as moment from 'moment';
import * as _ from 'lodash';
import { VacationPlanEmployeeRecord, IVacationPlanEmployeeRecord } from './index';

export interface IVacationPlan {
  firstWeekDate: string;
  weeksInYear: number;
  records: IVacationPlanEmployeeRecord[];
  actions:[];
}
export class VacationPlan {

  // public static mock: IVacationPlan = {
  //   firstWeekDate: new Date('2016/01/01'),
  //   weeksInYear: 52,
  //   records: [
  //     {
  //         employee: {
  //           id: 2,
  //           name: 'test2',
  //           dateHired: new Date(2000, 1, 1),
  //           payrollNumber: 'ddfdffd',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos1', orgLevelId: 10, group: 'grp1', startDate: null, endDate: null },
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date('2016/01/01'), days: 1 }, { start: new Date('2016/01/08'), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 2,
  //           name: 'test2',
  //           dateHired: new Date(2000, 1, 1),
  //           payrollNumber: 'ddfdffd',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos1', orgLevelId: 10, group: 'grp1', startDate: null, endDate: null },
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date('2016/01/01'), days: 2 }, { start: new Date('2016/01/08'), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 2,
  //           name: 'test2',
  //           dateHired: new Date(2000, 1, 1),
  //           payrollNumber: 'ddfdffd',
  //           employeeType: { name: 't', description: 'tt1', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos1', orgLevelId: 10, group: 'grp1', startDate: null, endDate: null },
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date('2016/01/01'), days: 2 }, { start: new Date('2016/01/08'), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 3,
  //           name: 'test3',
  //           dateHired: new Date(2003, 4, 1),
  //           payrollNumber: 'ttttt',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos1', orgLevelId: 10 , group: 'grp2', startDate: null, endDate: null},
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date(2017, 7, 1), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 3,
  //           name: 'test3',
  //           dateHired: new Date(2003, 4, 1),
  //           payrollNumber: 'ttttt',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos1', orgLevelId: 10 , group: 'grp2', startDate: null, endDate: null},
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date(2017, 7, 1), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 3,
  //           name: 'test4',
  //           dateHired: new Date(2003, 4, 1),
  //           payrollNumber: 'ttttt4',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos4', orgLevelId: 10 , group: 'grp4', startDate: null, endDate: null},
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date(2017, 7, 1), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 3,
  //           name: 'test4',
  //           dateHired: new Date(2003, 4, 1),
  //           payrollNumber: 'ttttt4',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos4', orgLevelId: 10 , group: 'grp4', startDate: null, endDate: null},
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date(2017, 7, 1), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 3,
  //           name: 'test3',
  //           dateHired: new Date(2003, 4, 1),
  //           payrollNumber: 'ttttt4',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos1', orgLevelId: 10 , group: 'grp2', startDate: null, endDate: null},
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date(2017, 7, 1), days: 1 }
  //       ]
  //     },
  //     {
  //         employee: {
  //           id: 3,
  //           name: 'test3',
  //           dateHired: new Date(2003, 4, 1),
  //           payrollNumber: 'ttttt4',
  //           employeeType: { name: 't', description: 'tt', fullTimeIndex: '' },
  //           mobilePhoneNumber: '8 (911) 123 1234',
  //           phoneNumber: '8 (911) 123 1234'
  //         },
  //         position: { id: 0, name: 'pos1', orgLevelId: 10 , group: 'grp2', startDate: null, endDate: null},
  //       shift: { id: 0, name: 'shift1', start: '', end: '', duration: '', group: null, employeesCount: 0, lunchDuration: '' },
  //       unit: { id: 0, name: 'unit1' },
  //       daysWorking: 10,
  //       vacationDays: 5,
  //       vacationHours: 60,
  //       weeks: [
  //         { start: new Date(2017, 7, 1), days: 1 }
  //       ]
  //     }
  //   ]
  // };
  public records: VacationPlanEmployeeRecord[];
  public weeks: NumberMap<moment.Moment>;
  public actions:[];
}
