import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyAbsence } from '../../../models/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { appConfig } from '../../../../app.config';
import { dateTimeUtils } from '../../../../common/utils/index';
import { DateTimeService } from '../../../../common/services/date-time/date-time.service';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
var TimeCardAbsencesEditorComponent = /** @class */ (function () {
    function TimeCardAbsencesEditorComponent(managementService, dateTimeService, appSettingsManageService) {
        this.managementService = managementService;
        this.dateTimeService = dateTimeService;
        this.appSettingsManageService = appSettingsManageService;
        this.isOverlapped = false;
        this.isOverlappedAbsencestartdate = false;
        this.isOverlappedAbsenceenddate = false;
        this.isOverlappedAbsenceshift = false;
        this.isOverlappedAbsencesDB = false;
        this.shiftsLookup = managementService.shiftsLookup;
        this.absenceCodesLookup = managementService.absenceCodesLookup;
        this.appConfig = appConfig;
        this.schedules = managementService.dataModel.schedule;
        this.dateon = managementService.dateOn;
    }
    TimeCardAbsencesEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modelSubscription = this.managementService.onLoaded$
            .subscribe(function (model) {
            _this.m_model = model;
            _this.maxDateLimit = moment(model.date).add(1, 'd').toDate();
            _this.minDateLimit = moment(model.date).subtract(1, 'd').toDate();
            if (!_this.uneditedAbsences) {
                _this.uneditedAbsences = _this.m_model.absences;
            }
        });
        //GET PreventOverlappingAbsences FROM SETTINGS API
        this.appSettingsManageService.getAppServerConfig()
            .then(function (config) {
            _this.PreventOverlappingAbsences = config.preventoverlappingabsences;
        });
        this.optionsChangedSubscription = this.managementService.displayOptionSelected$
            .subscribe(function (options) {
            _this.displayOptions = options;
        });
        var shift = this.GetDefaultShift();
        if (shift !== undefined && this.dataItem.shift === undefined) {
            this.dataItem.shift = shift;
            if (!this.dataItem.absence) {
                this.onShiftChanged(this.dataItem);
            }
        }
        if (!this.uneditedItem) {
            this.uneditedItem = _.cloneDeep(this.dataItem);
        }
    };
    TimeCardAbsencesEditorComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeCardAbsencesEditorComponent.prototype.onStartDateChanged = function (dataItem) {
        dataItem.calculateInterval();
        dataItem.copyIntervalToRaw();
        this.checkIntervalError(dataItem);
        if (this.PreventOverlappingAbsences) {
            this.CheckOverlappedAbsenceTime(dataItem, 'startdate');
        }
    };
    TimeCardAbsencesEditorComponent.prototype.onEndDateChanged = function (dataItem) {
        dataItem.calculateInterval();
        dataItem.copyIntervalToRaw();
        this.checkIntervalError(dataItem);
        if (this.PreventOverlappingAbsences) {
            this.CheckOverlappedAbsenceTime(dataItem, 'enddate');
        }
    };
    TimeCardAbsencesEditorComponent.prototype.onIntervalChanged = function (dataItem, hours) {
        //if (hours > 24) hours = 24;
        var ms = hours * 60 * 60 * 1000;
        dataItem.interval = ms;
        this.calculateEndDate(dataItem);
        dataItem.copyIntervalToRaw();
        this.checkIntervalError(dataItem);
        if (this.PreventOverlappingAbsences) {
            this.CheckOverlappedAbsenceTime(dataItem, 'enddate');
        }
    };
    TimeCardAbsencesEditorComponent.prototype.checkIntervalError = function (dataItem) {
        var _this = this;
        setTimeout(function () {
            var _a;
            var intervalControl = _this.absencesForm.controls['interval'];
            if (!intervalControl) {
                return;
            }
            if (dataItem.updateSchedule && dataItem.interval < 0 && dataItem.unpaidInterval > 0) {
                intervalControl.setErrors((_a = {}, _a['paidPeriod'] = true, _a));
            }
            else {
                intervalControl.setErrors(null);
            }
        }, 100);
    };
    TimeCardAbsencesEditorComponent.prototype.onIntervalHMChanged = function (dataItem, value) {
        var interval = dateTimeUtils.getIntervalFromIntervalTime(value, 'h');
        this.onIntervalChanged(dataItem, interval);
    };
    TimeCardAbsencesEditorComponent.prototype.onTypeChanged = function (dataItem) {
        dataItem.id = dataItem.absence.id;
        dataItem.name = dataItem.absence.description;
        dataItem.checkHasLinkedSa();
        if (!dataItem.hasLinkedSa) {
            dataItem.updateSchedule = false;
        }
        else {
            dataItem.updateSchedule = this.managementService.lastUpdateScheduleSelection;
        }
        this.checkIntervalError(dataItem);
        if (this.PreventOverlappingAbsences) {
            this.CheckOverlappedAbsenceTime(dataItem, 'scheduleshift');
        }
    };
    TimeCardAbsencesEditorComponent.prototype.onUpdateScheduleChanged = function (dataItem) {
        this.managementService.saveLastUpdateScheduleOption(dataItem.updateSchedule);
        this.checkIntervalError(dataItem);
    };
    TimeCardAbsencesEditorComponent.prototype.onShiftChanged = function (dataItem) {
        this.isOverlapped = false;
        if (!dataItem.shift) {
            return;
        }
        this.isOverlapped = this.CheckOverlappedShifts(dataItem.shift);
        var dateRange = dateTimeUtils.getDateRangeFromTimeRange(dataItem.start, dataItem.shift.start, dataItem.shift.end);
        dataItem.start = dateRange.start;
        dataItem.end = dateRange.end;
        dataItem.calculateInterval();
        dataItem.copyIntervalToRaw();
        this.checkIntervalError(dataItem);
        if (this.PreventOverlappingAbsences) {
            this.CheckOverlappedAbsenceTime(dataItem, 'scheduleshift');
        }
        dataItem.checkHasLinkedSa();
        if (!dataItem.hasLinkedSa) {
            dataItem.updateSchedule = false;
        }
        else {
            dataItem.updateSchedule = this.managementService.lastUpdateScheduleSelection;
        }
    };
    TimeCardAbsencesEditorComponent.prototype.CheckOverlappedAbsenceTime = function (dataItem, dateString) {
        var _this = this;
        var i = 0;
        var date = dataItem.end;
        this.isOverlappedAbsencestartdate = false;
        this.isOverlappedAbsenceenddate = false;
        this.isOverlappedAbsenceshift = false;
        this.isOverlappedAbsencesDB = false;
        if (dateString === 'startdate') {
            date = dataItem.start;
        }
        else if (dateString === 'enddate') {
            date = dataItem.end;
        }
        if (date != null) {
            var allAbsence_1 = this.uneditedAbsences.filter(function (x) { return !(x.id === _this.uneditedItem.id && x.start.toString() === _this.uneditedItem.start.toString() && x.end.toString() === _this.uneditedItem.end.toString()); });
            _.times(allAbsence_1.length, function () {
                if (_this.isTimeOverlapped(dataItem, allAbsence_1[i])) {
                    if (dateString === 'startdate') {
                        _this.isOverlappedAbsencestartdate = true;
                    }
                    if (dateString === 'enddate') {
                        _this.isOverlappedAbsenceenddate = true;
                    }
                    if (dateString === 'scheduleshift') {
                        _this.isOverlappedAbsenceshift = true;
                    }
                }
                i++;
            });
        }
    };
    TimeCardAbsencesEditorComponent.prototype.isTimeOverlapped = function (dataItem, allAbsence) {
        var date1 = moment(dataItem.start);
        var date2 = moment(dataItem.end);
        var date3 = moment(allAbsence.start);
        var date4 = moment(allAbsence.end);
        if (((this.dateTimeService.isDateRangeOverlapped(moment(date1), moment(date2), moment(date3), moment(date4), true)) || (moment.range(date3, date4).contains(date1) || moment.range(date3, date4).contains(date2)))) {
            return true;
        }
        else
            return false;
    };
    TimeCardAbsencesEditorComponent.prototype.calculateEndDate = function (item) {
        item.end = new Date(item.interval + item.unpaidInterval + item.start.getTime());
        item.calculateInterval();
    };
    TimeCardAbsencesEditorComponent.prototype.CheckOverlappedShifts = function (shift) {
        var _this = this;
        var isOverlapped = false;
        this.schedules.forEach(function (schedule) {
            var shiftDateRange = dateTimeUtils.getDateRangeFromTimeRange(_this.dateon, shift.start, shift.end);
            var schedulerange = moment.range(schedule.start, schedule.end);
            var shiftRange = moment.range(shiftDateRange.start, shiftDateRange.end);
            if (!shiftRange.isSame(schedulerange) && shiftRange.overlaps(schedulerange)) {
                isOverlapped = true;
            }
        });
        return isOverlapped;
    };
    TimeCardAbsencesEditorComponent.prototype.GetDefaultShift = function () {
        var _this = this;
        var defaultshift;
        if (this.schedules[0] !== undefined && this.dataItem.id === undefined) {
            var schedulerange_1 = moment.range(this.schedules[0].start, this.schedules[0].end);
            this.shiftsLookup.forEach(function (shift) {
                var shiftDateRange = dateTimeUtils.getDateRangeFromTimeRange(_this.dateon, shift.start, shift.end);
                var shiftRange = moment.range(shiftDateRange.start, shiftDateRange.end);
                if (shiftRange.isSame(schedulerange_1)) {
                    defaultshift = shift;
                    return defaultshift;
                }
            });
        }
        else if (this.shiftsLookup !== undefined) {
            var daterange = moment.range(this.dataItem.start, this.dataItem.end);
            var closeRange = void 0;
            var startTimeDiff = void 0;
            for (var _i = 0, _a = this.shiftsLookup; _i < _a.length; _i++) {
                var shift = _a[_i];
                var shiftDateRange = dateTimeUtils.getDateRangeFromTimeRange(this.dateon, shift.start, shift.end);
                var shiftRange = moment.range(shiftDateRange.start, shiftDateRange.end);
                if (shiftRange.isSame(daterange)) {
                    defaultshift = shift;
                    break;
                }
                else {
                    var timeDiff = Math.abs(daterange.diff('seconds') - shiftRange.diff('seconds'));
                    var startRange = moment.range(this.dataItem.start, shiftDateRange.start);
                    var startDiff = Math.abs(startRange.diff('seconds'));
                    if (startTimeDiff === undefined || startDiff <= startTimeDiff) {
                        startTimeDiff = startDiff;
                        if (closeRange === undefined || timeDiff <= closeRange) {
                            closeRange = timeDiff;
                            defaultshift = shift;
                        }
                    }
                }
            }
        }
        return defaultshift;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesEditorComponent.prototype, "modelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesEditorComponent.prototype, "absenceSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesEditorComponent.prototype, "optionsChangedSubscription", void 0);
    return TimeCardAbsencesEditorComponent;
}());
export { TimeCardAbsencesEditorComponent };
