export * from './section-punches-management.component';

import { SectionPunchesManagementComponent } from './section-punches-management.component';

export const punchesComponents: any = [
    SectionPunchesManagementComponent
];

export const exportPunchesComponents: any = [
    SectionPunchesManagementComponent
];
