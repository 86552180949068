export interface IPBJExportChildGrid {
     batchID: number;
     orgLevelId: number;
     startDate: Date;
     endDate : Date
}

export class PBJExportChildGrid {
    public batchID: number;
    public orgLevelId: number;
    public startDate: Date;
    public endDate : Date
}

