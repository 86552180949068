import * as moment from 'moment';
import { Pipe, Injectable, PipeTransform, ElementRef } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../app.config';
@Pipe({
    name: 'slxPeriod',
    pure: true
})
@Injectable()
export class SlxPeriodPipe implements PipeTransform {
    public transform(period: { startDate: Date, endDate: Date }): any {
        if (period) {
            return `${moment(period.startDate).format(appConfig.dateFormat)} - ${moment(period.endDate).format(appConfig.dateFormat)}`;
        }
        return '';
    }
}
