import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, ViewChild, Provider } from '@angular/core';
import { EnrollmentHistoryRecord } from '../../../../models';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { KendoGridStateHelper, DialogOptions, ModalService, ModalAnchorDirective, IDialog, DialogOptions2, DialogModeSize } from '../../../../../../common';
import { GridDataResult, GridComponent, RowArgs, SelectionEvent, SelectAllCheckboxState } from '@progress/kendo-angular-grid';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { NgForm, NgModel } from '@angular/forms';
import { BenefitEmployeesApiService } from '../../../../../../app-modules/benefits/services';
import { BenefitTerminationReason } from '../../../../../../app-modules/benefits/models';

@Component({
  selector: 'slx-drop-coverage-popup',
  templateUrl: './drop-coverage-popup.component.html',
  styleUrls: ['./drop-coverage-popup.component.scss']
})
export class DropCoveragePopupComponent implements OnInit, IDialog {

  public set enrollments(value: EnrollmentHistoryRecord[]) {
    this.m_enrollments = value;
    _.each(this.m_enrollments, (e: EnrollmentHistoryRecord) => e.isSelected = false);
    this.refreshGrid();
  }

  public hasSelected: boolean;

  public showEnrollments: boolean = true;

  //#region configuration
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EnrollmentHistoryRecord>;
  public gridView: GridDataResult;
  //#endregion

  //#region properties
  public isLoading: boolean = false;

  public dialogResult: boolean;
  public terminationReason: BenefitTerminationReason;

  public eventDate: Date;
  public minEventDate: Date;
  public maxEventDate: Date;
  public maxDedDate: Date = new Date(appConfig.maxCorrectDate);
  public minDedDate: Date = new Date(appConfig.minCorrectDate);

  public coverageEndDate: Date;
  public coverageEndDateValid: boolean;

  public payrollDeductionEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;

  public isDedAfterBeneEndDt: boolean;
  public canMapPayroll: boolean;

  public minCoverageEndDate: Date;
  public maxCoverageEndDate: Date;

  public selectedEnrollments: EnrollmentHistoryRecord[];
  public selectedEnrollmentIds: number[] = [];
  public selectAllState: SelectAllCheckboxState = 'unchecked';

  //#endregion


  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @ViewChild('gridForm', { static: true })
  private ngFormChild: NgForm;

  public terminationReasons: BenefitTerminationReason[];

  private m_enrollments: EnrollmentHistoryRecord[];

  public static openDialog(
    showEnrollments: boolean,
    enrollments: EnrollmentHistoryRecord[],
    canMapPayroll: boolean,
    effectiveDate: Date,
    modalService: ModalService,
    apiService: BenefitEmployeesApiService,
    callback?: (result: boolean) => void): DropCoveragePopupComponent {

    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.height = showEnrollments ? 450 : 310;
    dialogOptions.width = 600;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },

      {
        provide: BenefitEmployeesApiService,
        useValue: apiService
      }
    ];
    const title = `Drop Benefit Coverage`;
    let component = modalService.globalAnchor.openDialog2(
      DropCoveragePopupComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    component.showEnrollments = showEnrollments;
    component.enrollments = enrollments;
    component.canMapPayroll = canMapPayroll;

    if (showEnrollments) {
      component.coverageEndDate = effectiveDate;
      component.payrollDeductionEndDate = effectiveDate;
    } else {
      component.selectedEnrollments = enrollments;
      component.hasSelected = true;
      let enrollment = _.first(enrollments);
      component.coverageEndDate = enrollment.endDate;
      component.payrollDeductionEndDate = moment(  _.isNull(enrollment.payrollDeductionEndDate) ? enrollment.payrollDeductionEndDate : enrollment.endDate).startOf('day').toDate();
      component.minDedDate = _.isNull(enrollment.dedStartDate) ? enrollment.startDate : enrollment.dedStartDate;
      component.maxDedDate = _.isNull(enrollment.dedEndDate) ? enrollment.endDate : enrollment.dedEndDate;
      if (_.gt( component.payrollDeductionEndDate,component.maxDedDate))
      {
        component.payrollDeductionEndDate = component.maxDedDate;
      }
      
    }
    return component;
  }

  constructor(
    private options: DialogOptions,
    private modalService: ModalService,
    private benefitsApiService: BenefitEmployeesApiService
  ) {
    this.selectedEnrollments = [];
    this.hasSelected = false;
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<EnrollmentHistoryRecord>();
  }

  public ngOnInit() {
    this.eventDate = new Date();
    this.updateCoverageMinDateRange();
    this.loadReasons();
  }

  private loadReasons() {
    this.isLoading = true;
    this.benefitsApiService.getReasonsList()
      .then((reasons: BenefitTerminationReason[]) => {
        this.isLoading = false;
        this.terminationReasons = reasons;
      }).catch((error: any) => {
        this.terminationReasons = null;
        this.isLoading = false;
      });
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onSelectionChange(event: SelectionEvent): void {
    this.gridState.selectionChange(event);
    let selectedRecords: EnrollmentHistoryRecord[] = _.map(event.selectedRows, (args: RowArgs) => args.dataItem);
    let deselectedRecords: EnrollmentHistoryRecord[] = _.map(event.deselectedRows, (args: RowArgs) => args.dataItem);
    this.selectedEnrollments = _.without(this.selectedEnrollments, ...deselectedRecords);
    this.selectedEnrollments = _.uniqBy(_.concat(this.selectedEnrollments, selectedRecords), (r: EnrollmentHistoryRecord) => r.empToBenefitsId);
    this.hasSelected = _.size(this.selectedEnrollments) > 0;
    this.updateCoverageMinDateRange();
    this.validateCoverageEndDate();
  }


  public onChangeTerminationReason(reason: BenefitTerminationReason): void {
    this.terminationReason = reason;
    this.validateCoverageEndDate();
  }

  public onChangeEventDate(value: Date): void {
    this.eventDate = value;
  }

  public onChangeCoverageEndDate(value: Date): void {
    this.coverageEndDate = value;
    this.updateCoverageMinDateRange();
    this.validateCoverageEndDate();
  }

  public onChangePayrollDedEndDate(): void {
    this.isDedAfterBeneEndDt = !!_.gt(this.payrollDeductionEndDate, this.coverageEndDate);
    this.updateCoverageMinDateRange();
    this.validateCoverageEndDate();

  }

  public dropBenefits(): void {

    if (_.isNil(this.selectedEnrollments) || _.size(this.selectedEnrollments) === 0) return;
    this.isLoading = true;
    let ids = _.map(this.selectedEnrollments, (e: EnrollmentHistoryRecord) => e.empToBenefitsId);

    this.benefitsApiService.dropEmployeeBenefits(ids, this.coverageEndDate, this.payrollDeductionEndDate, this.eventDate, this.terminationReason)
      .then((drop: any) => {
        this.isLoading = false;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
      }).catch((reason: any) => {
        this.dialogResult = false;
        this.terminationReason = null;
        this.isLoading = false;
      });
  }

  private updateCoverageMinDateRange(): void {
    if (_.size(this.selectedEnrollments) > 0) {
      let startDateEnrollment = _.minBy(this.selectedEnrollments, 'startDate');
      let endDateEnrollment = _.maxBy(this.selectedEnrollments, 'endDate');
      this.minCoverageEndDate = startDateEnrollment.startDate;
      this.maxCoverageEndDate = endDateEnrollment.endDate;
      let minDedEndDate = _.minBy(this.selectedEnrollments, 'payrollDeductionEndDate');
      let maxDedEndDate = _.maxBy(this.selectedEnrollments, 'payrollDeductionStartDate');
      if (!_.isNil(minDedEndDate) && !_.isNil(maxDedEndDate))
        { 
          this.minDedDate = minDedEndDate.payrollDeductionEndDate ? this.minCoverageEndDate : minDedEndDate.payrollDeductionEndDate ;
          this.maxDedDate = maxDedEndDate.payrollDeductionStartDate ? this.maxCoverageEndDate : maxDedEndDate.payrollDeductionStartDate ;
        }
        else{
          this.minDedDate = this.minCoverageEndDate;
          this.maxDedDate =this.maxCoverageEndDate;
        }
    } else {
      this.minCoverageEndDate = null;
      this.maxCoverageEndDate = null;
      this.maxDedDate = null;
      this.minDedDate  = null;

    }
  }

  private validateCoverageEndDate() {

    if (this.coverageEndDate) {
      let isValid = this.hasSelected;
      _.each(this.selectedEnrollments, (r: EnrollmentHistoryRecord) => {
        if (!moment(this.coverageEndDate).isBetween(r.startDate, r.endDate, null, '[]')) {
          isValid = false;
        }

      });
      this.coverageEndDateValid = isValid;
      this.isDedAfterBeneEndDt = !!_.gt(this.payrollDeductionEndDate, this.coverageEndDate);
    } else {
      this.coverageEndDateValid = false;
    }
  }

  private refreshGrid(): void {
    if (!this.m_enrollments) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.m_enrollments, this.gridState.state);
  }

}
