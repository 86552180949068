import { BenefitEmployeeDependent } from './benefit-employee-dependent';
import { BenefitEligibleDependentBenefit } from './benefit-eligible-dependent-benefit';

export class BenefitEmployeeDependentsEnrollments {
  constructor(
    public startDate: Date,
    public endDate: Date,
    public dependents: Array<BenefitEmployeeDependent>,
    public benefits: Array<BenefitEligibleDependentBenefit>
  ) {}
}
