import { Component, OnInit, OnDestroy, Input, Host, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from './../../../../../core/decorators/unsubscribe-decorator';

import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsFutureUpdates, EmployeeSectionsFutureUpdate, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsAuditApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

import { KendoGridStateHelper } from '../../../../../common/models/index';
import { ConfirmDialogComponent } from '../../../../../common/components/index';
import { GroupResult, orderBy, groupBy, State } from '@progress/kendo-data-query';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-future-updates',
  templateUrl: 'employee-sections-future-updates.component.html',
  styleUrls: ['employee-sections-future-updates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsFutureUpdatesComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input('employeeSectionsFutureUpdates')
  public set futureUpdates(employeeSectionsFutureUpdates: EmployeeSectionsFutureUpdates) {
    this.employeeSectionsFutureUpdates = employeeSectionsFutureUpdates;
    this.refreshGrid();
  }
  @Input()
  public employeeId: number;

  public get form(): AbstractControl {
    return null;
  }

  public now: Date;

  public gridState: KendoGridStateHelper<EmployeeSectionsFutureUpdate>;
  @unsubscribe()
  private gridEventSubscription: Subscription;
  private employeeSectionsFutureUpdates: EmployeeSectionsFutureUpdates;

  constructor(
    private employeeSectionsAuditApiService: EmployeeSectionsAuditApiService,
    private modalService: ModalService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private changeDetector: ChangeDetectorRef) {
    super(decorator, ngZone);
    this.now = new Date();

    this.gridState = new KendoGridStateHelper<EmployeeSectionsFutureUpdate>();
    this.gridState.state.sort = [{ field: 'scheduledFor', dir: 'asc' }];
    this.gridState.itemKey = 'id';
    this.gridEventSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsFutureUpdates;
  }

  public onStartRemove(dataItem: EmployeeSectionsFutureUpdate): void {
    ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure?', this.modalService, (result: boolean) => {
      if (result) {
        this.doRemove(dataItem);
      }
    });
  }

  protected doRemove(dataItem: EmployeeSectionsFutureUpdate): void {
    let itemId: number = dataItem.id;
    this.startProgress();
    this.employeeSectionsAuditApiService.deleteFutureUpdate(this.employeeId, itemId)
      .then((status: any) => {
        this.loadSubsection();
      }).catch(() => {
        this.loadSubsection();
      });
  }


  public onRefreshClicked(): void {
    this.loadSubsection();
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsAuditApiService.getFutureUpdates(this.employeeId)
      .then((employeeSectionsFutureUpdates: EmployeeSectionsFutureUpdates) => {
        this.employeeSectionsFutureUpdates = employeeSectionsFutureUpdates;
        this.refreshGrid();
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }).catch(() => {
        this.loadSubsection();
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.gridEventSubscription) {
      this.gridEventSubscription.unsubscribe();
    }
  }

  private refreshGrid(): void {
    if (!this.employeeSectionsFutureUpdates) {
      this.gridState.view = null;
      return;
    }
    let sortedRecords: EmployeeSectionsFutureUpdate[] = orderBy(this.employeeSectionsFutureUpdates.updates, this.gridState.state.sort);
    let groupedRecords: EmployeeSectionsFutureUpdate[] | GroupResult[] = groupBy(sortedRecords, this.gridState.state.group);
    this.gridState.view = {
      data: groupedRecords,
      total: groupedRecords.length
    };
    if (this.gridState.selectedRowsIds.length === 0) {
      setTimeout(() => { this.gridState.selectFirstRow(); }, 1);
    }
  }
}
