import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { appConfig } from '../../../../app.config';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { PayCycle } from '../../../../organization/models/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { PayCycleHelperService, OrgLevelWatchService } from '../../../../organization/services/index';
import * as _ from 'lodash';
var TimecardSummaryComponent = /** @class */ (function () {
    function TimecardSummaryComponent(route, management, payCycleHelperService, orgLevelWatchService) {
        this.route = route;
        this.payCycleHelperService = payCycleHelperService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.state = {
            isLoading: false,
            isError: false
        };
        this.management = management;
    }
    Object.defineProperty(TimecardSummaryComponent.prototype, "allowFilters", {
        get: function () {
            return this.management.allowFilters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardSummaryComponent.prototype, "showExemptEmployees", {
        get: function () {
            return this.management.showExemptEmployees;
        },
        enumerable: true,
        configurable: true
    });
    TimecardSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.routeSubscripion = this.route.params
            .combineLatest(this.route.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var d1, d2, d3, d4, d5, payCycle, employeeId, orgLevelId, organizationId, currentOrglevel, organizationOrglevel, date, startDate, endDate;
                return tslib_1.__generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            d1 = queryParams['startDate'];
                            d2 = queryParams['endDate'];
                            d3 = queryParams['organization'];
                            d4 = queryParams['employeeId'];
                            d5 = queryParams['orgLevelId'];
                            if (!(d3 !== undefined)) return [3 /*break*/, 3];
                            organizationId = parseInt(d3, 10);
                            if (!(d5 !== undefined)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafe(parseInt(d5, 10))];
                        case 1:
                            currentOrglevel = _b.sent();
                            orgLevelId = currentOrglevel.id;
                            _b.label = 2;
                        case 2: return [3 /*break*/, 6];
                        case 3:
                            if (!(d4 !== undefined)) return [3 /*break*/, 4];
                            employeeId = parseInt(d4, 10);
                            return [3 /*break*/, 6];
                        case 4:
                            if (!d5) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafe(parseInt(d5, 10))];
                        case 5:
                            currentOrglevel = _b.sent();
                            orgLevelId = currentOrglevel.id;
                            _b.label = 6;
                        case 6:
                            if (!currentOrglevel) return [3 /*break*/, 10];
                            if (!(currentOrglevel.type === OrgLevelType.organization)) return [3 /*break*/, 7];
                            organizationId = currentOrglevel.relatedItemId;
                            organizationOrglevel = currentOrglevel;
                            return [3 /*break*/, 10];
                        case 7:
                            if (!(currentOrglevel.type === OrgLevelType.department)) return [3 /*break*/, 9];
                            return [4 /*yield*/, this.orgLevelWatchService.getOrgLevelByIdSafe(currentOrglevel.parentId)];
                        case 8:
                            organizationOrglevel = _b.sent();
                            if (_.isNull(organizationOrglevel)) {
                                organizationId = currentOrglevel.organizationId;
                                organizationOrglevel = currentOrglevel;
                            }
                            else {
                                organizationId = organizationOrglevel.relatedItemId;
                            }
                            return [3 /*break*/, 10];
                        case 9: return [2 /*return*/];
                        case 10:
                            if (!(!d1 || !d2)) return [3 /*break*/, 12];
                            date = d1 ? moment(d1, appConfig.linkDateFormat).toDate() : new Date();
                            return [4 /*yield*/, this.payCycleHelperService.getLastPayCycleByDate(date, orgLevelId, employeeId)];
                        case 11:
                            payCycle = _b.sent();
                            return [3 /*break*/, 13];
                        case 12:
                            startDate = moment(d1, appConfig.linkDateFormat).toDate();
                            endDate = moment(d2, appConfig.linkDateFormat).toDate();
                            payCycle = new PayCycle();
                            payCycle.startDate = startDate;
                            payCycle.endDate = endDate;
                            _b.label = 13;
                        case 13:
                            this.management.onPayCycleChanged(payCycle);
                            if (organizationId !== undefined) {
                                this.management.onOrgLevelChanged(organizationId, organizationOrglevel, currentOrglevel);
                            }
                            else if (employeeId !== undefined) {
                                this.management.onEmployeeIdChanged(employeeId);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        });
        this.loadStatusSubscription = this.management.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.onSummaryLoadedSubscription = this.management.onLoaded$.subscribe(function (summary) {
            _this.summary = summary;
            if (!_this.summary)
                _this.state.isError = true;
        });
    };
    TimecardSummaryComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardSummaryComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardSummaryComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardSummaryComponent.prototype, "onSummaryLoadedSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", TimecardsSummaryManagementService)
    ], TimecardSummaryComponent.prototype, "management", void 0);
    return TimecardSummaryComponent;
}());
export { TimecardSummaryComponent };
