export * from './employee-sections-future-updates/employee-sections-future-updates.component';
export * from './employee-sections-audit-trail/employee-sections-audit-trail.component';
export * from './employee-sections-note-edit/employee-sections-note-edit.component';

import { EmployeeSectionsFutureUpdatesComponent } from './employee-sections-future-updates/employee-sections-future-updates.component';
import { EmployeeSectionsAuditTrailComponent } from './employee-sections-audit-trail/employee-sections-audit-trail.component';
import { EmployeeSectionsNoteEditComponent } from './employee-sections-note-edit/employee-sections-note-edit.component';

export const componentsAuditSection: any[] = [
  EmployeeSectionsFutureUpdatesComponent,
  EmployeeSectionsAuditTrailComponent,
  EmployeeSectionsNoteEditComponent,
];
