export class TimecardIndividualSummaryRequest {
    public employeeId: number;
    public startDate: Date;
    public endDate: Date;
}

export interface ITimecardIndividualSummaryRequest {
    employeeId: number;
    startDate: string;
    endDate: string;
}
