import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ConfirmDialogComponent } from '../../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { BudgetApiService, BudgetManagementService } from '../../../services/index';
import { Budget, BudgetCensus, BudgetRecord, AddRecordReq, BudgetHeaderModes } from '../../../models/index';
import { Subscription } from 'rxjs/Subscription';

import { BudgetDefinition, BudgetedPosition } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { BudgetSelectorDropdownComponent } from '../../../../organization/components/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-budget-header',
  templateUrl: 'budget-header.component.html',
  styleUrls: ['budget-header.component.scss']
})
export class BudgetHeaderComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public budget: Budget;
  public state: {
    mode: BudgetHeaderModes.None | BudgetHeaderModes.AddBudget | BudgetHeaderModes.EditBudget;
    isRecordEditMode: boolean;
    isGroupEditMode: boolean;
  };

  @ViewChild('budgetSelector', { read: BudgetSelectorDropdownComponent , static: true})
  private budgetSelector: BudgetSelectorDropdownComponent;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private dummyLoadedSubscription: Subscription;
  @unsubscribe()
  private addedSubscription: Subscription;
  @unsubscribe()
  private deletedSubscription: Subscription;
  @unsubscribe()
  private restoredSubscription: Subscription;
  @unsubscribe()
  private groupEditSubscription: Subscription;
  @unsubscribe()
  private addedRecordSubscription: Subscription;
  @unsubscribe()
  private budgetSelectSubscription: Subscription;
  @unsubscribe()
  private editRecordSubscription: Subscription;
  @unsubscribe()
  private overlapSubscription: Subscription;
  @unsubscribe()
  private editModeSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private orglevelsLoadedSubscription: Subscription;

  private orglevelid: number = undefined;

  constructor(public budgetManagementService: BudgetManagementService, private modalService: ModalService, private orgLevelService: OrgLevelWatchService) {

    this.state = {
      mode: BudgetHeaderModes.None,
      isRecordEditMode: false,
      isGroupEditMode: false
    };
  }

  public ngOnInit(): void {

    this.appConfig = appConfig;

    this.orgLevelSubscription = this.budgetManagementService.onOrgLevelChanged$
      .subscribe((orgLevelId: number) => {
        if (this.budgetSelector) {
          let o: OrgLevel = this.orgLevelService.getOrgLevelById(orgLevelId);
          if (o && o.id != this.orglevelid) {
            this.budgetSelector.setOrglevel(o);
            this.orglevelid = o.id;
          } else {
            this.orglevelsLoadedSubscription = this.orgLevelService.orgLevelTreeLoaded$.subscribe(() => {
              let o: OrgLevel = this.orgLevelService.getOrgLevelById(orgLevelId);
              if (o && o.id != this.orglevelid) {
                this.budgetSelector.setOrglevel(o);
                this.orglevelid = o.id;
              }
            });
          }
        }
      });


    this.dummyLoadedSubscription = this.budgetManagementService.onDummyLoaded$.subscribe((budget: Budget) => {

      if (this.budgetManagementService.editMode) return;

      if (this.budgetSelector && this.budgetSelector.budgetDefinitions) {
        let definitions: BudgetDefinition[] = this.budgetSelector.budgetDefinitions.slice(0);
        let latestDefinition: BudgetDefinition;
        if (definitions.length > 0) {
          latestDefinition = _.first(definitions);
          let endMoment: moment.Moment = moment(latestDefinition.endDate);
          if (dateTimeUtils.isBeforeMaxSQLDate(latestDefinition.endDate)) {
            budget.startDate = endMoment.add(1, 'days').toDate();
          } else {
            budget.startDate = moment(latestDefinition.startDate).add(1, 'months').toDate();
          }
        }
      }
      this.state.mode = BudgetHeaderModes.AddBudget;
      this.budgetManagementService.priorBudgetDefinition = this.budgetManagementService.currentBudgetDefinition;
      this.budget = budget;
      this.budgetManagementService.onBudgetSelect(this.budget);
    });

    this.editModeSubscription = this.budgetManagementService.onEditBudgetMode$.subscribe(
      (editMode: boolean) => {
        if (this.budgetManagementService.addMode) return;
        this.state.mode = editMode ? BudgetHeaderModes.EditBudget : BudgetHeaderModes.None;
      });

    this.loadedSubscription = this.budgetManagementService.onLoaded$.subscribe(
      (budget: Budget) => {
        if (this.budgetManagementService.addMode) return;
        this.budget = budget;
        if (this.budgetManagementService.editMode) return;
        this.state.mode = BudgetHeaderModes.None;
      });

    this.addedSubscription = this.budgetManagementService.onAdded$.subscribe(
      (budget: Budget) => {
        this.budget = budget;
        this.budgetManagementService.currentBudgetDefinition = new BudgetDefinition();
        this.budgetManagementService.currentBudgetDefinition.id = budget.id;
        this.budgetManagementService.currentBudgetDefinition.orgLevelId = this.budgetManagementService.selectedOrganizationOrgLevelId;
        this.budgetSelector.loadBudgetsDefinitions(this.budgetManagementService.selectedOrganizationOrgLevelId, true, false);
      });

    this.deletedSubscription = this.budgetManagementService.onDeleted$.subscribe((budgetId: number) => {
      this.budgetSelector.SelectBudgetDefinition = undefined;
      this.budgetManagementService.currentBudgetDefinition = undefined;
      this.budgetSelector.loadBudgetsDefinitions(this.budgetManagementService.selectedOrganizationOrgLevelId, true, true);
    });

    this.restoredSubscription = this.budgetManagementService.onRestoreBudget$.subscribe((result: boolean) => {
      if (!result) {
        this.budgetSelector.budgetDefinitions = [];
        this.budgetSelector.loadBudgetsDefinitions(this.budgetManagementService.selectedOrganizationOrgLevelId, true);
      }
    });

    this.budgetSelectSubscription = this.budgetManagementService.onBudgetSelect$.subscribe(
      (req: Budget) => {
        if (this.budgetManagementService.addMode) return;
        if (this.budgetManagementService.editMode) return;
        if (req) {
          this.budget = req;
          let def: BudgetDefinition = new BudgetDefinition();
          def.id = req.id;
          def.orgLevelId = req.orgLevelId;
          def.startDate = req.startDate;
          def.endDate = req.endDate;
          this.budgetManagementService.currentBudgetDefinition = def;
          this.budgetSelector.SelectBudgetDefinition(def, false);
        }
      });

    this.editRecordSubscription = this.budgetManagementService.onEditRecord$.subscribe(
      (inRecordEdit: boolean) => {
        this.state.isRecordEditMode = inRecordEdit;
      });
    this.addedRecordSubscription = this.budgetManagementService.onPositionAdded$.subscribe(
      (record: BudgetRecord) => {
        this.state.isRecordEditMode = false;
      });

    this.groupEditSubscription = this.budgetManagementService.onGroupEdit$
      .subscribe((editMode: boolean) => {
        this.state.isGroupEditMode = editMode;
        if (!editMode) {
          this.budget = this.budgetManagementService.currentBudget;
        }
      });

    this.overlapSubscription = this.budgetManagementService.overlapWarning$.subscribe((overlap: { budget: Budget, isNew: boolean }) => {
      ConfirmDialogComponent.openDialog(
        'Warning',
        'The dates you have entered overlap with an existing budget. If you want to keep these dates the existing budget will be modified to correct the overlap. Do you want to proceed with these dates?',
        this.modalService,
        (result: boolean) => {
          if (result) {
            if (overlap.isNew) {
              this.budgetManagementService.onAddRequest(overlap.budget, null, true);
            } else {
              this.budgetManagementService.onSaveRequest(overlap.budget, null, true);
            }
          }
        });
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public budgetDefinitionSelected(item: BudgetDefinition): void {
    if (this.budgetManagementService.addMode) return;
    this.budgetManagementService.currentBudgetDefinition = item;
    if (this.budgetManagementService.editMode) return;
    this.budgetManagementService.onLoadRequest(this.budgetManagementService.currentBudgetDefinition);
  }

  public budgetDefinitionAdd(item: any): void {
    /*
    this.state.mode = 'AddBudget';
    this.priorBudgetDefinition = this.currentBudgetDefinition;
    this.budget = new Budget();
    this.budget.census = new BudgetCensus();
    this.budget.orgLevelId = this.budgetManagementService.selectedOrganizationOrgLevelId;
    this.budget.records = [];
    this.budgetManagementService.onBudgetSelect(this.budget);
    */
    this.budgetManagementService.onAddBudgetMode(true);
    this.budgetManagementService.getDummy();
  }

  public onEditDiscard(): void {
    this.state.mode = BudgetHeaderModes.None;
    this.budgetManagementService.onEditBudgetMode(false);
    if (this.budgetManagementService.currentBudgetDefinition) {
      this.budgetManagementService.onLoadRequest(this.budgetManagementService.currentBudgetDefinition);
    }
  }

  public onEditSave(): void {
    this.budget.orgLevelId = this.budgetManagementService.selectedOrganizationOrgLevelId;
    this.budgetManagementService.onEditBudgetMode(false);
    this.budgetManagementService.onSaveRequest(this.budget, null, true);
  }

  public onAddDiscard(): void {
    this.state.mode = BudgetHeaderModes.None;
    this.budgetManagementService.onEditBudgetMode(false);
    this.budgetManagementService.onAddBudgetMode(false);
    this.budgetManagementService.currentBudgetDefinition = this.budgetManagementService.priorBudgetDefinition;
    if (this.budgetManagementService.currentBudgetDefinition) {
      this.budgetManagementService.onLoadRequest(this.budgetManagementService.currentBudgetDefinition);
    }
  }

  public onAddSave(): void {
    let definitions: BudgetDefinition[];
    if (this.budgetSelector && this.budgetSelector.budgetDefinitions) {
      definitions = this.budgetSelector.budgetDefinitions.slice(0);
    }
    if (definitions) {
      let overlaps: boolean = this.budgetManagementService.checkOverlapping(this.budget, definitions);
      if (!overlaps) {
        this.state.mode = BudgetHeaderModes.None;
        this.budgetManagementService.onEditBudgetMode(false);
        this.budgetManagementService.onAddBudgetMode(false);
        this.budgetManagementService.onAddRequest(this.budget, definitions, true);
      } else {
        this.budgetManagementService.sendOverlapWaning(this.budget, true);
      }
    } else {
      this.budgetManagementService.onEditBudgetMode(false);
      this.budgetManagementService.onAddBudgetMode(false);
      this.budgetManagementService.onAddRequest(this.budget, null, true);
    }
  }

  public onRestore(): void {
    this.budgetManagementService.onRestoreBudget(true);
  }

  public onDelete(): void {
    ConfirmDialogComponent.openDialog(
      'Warning',
      'You are about to delete this budget. Once executed, this action cannot be un-done. Are you sure you want to delete?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.budgetManagementService.onDeleteRequest(this.budget.id);
        }
      });
  }

  public onEdit(): void {
    this.budgetManagementService.onEditBudgetMode(true);
  }

  public editCensus(): void {
    this.budgetManagementService.onBudgetCensusAdjust(true);
  }

  public onManageGroups(): void {
    this.state.isGroupEditMode = true;
    this.budgetManagementService.onGroupEdit(true);
  }

  public onEditGroupsDiscard(): void {
    this.state.isGroupEditMode = false;
    this.budgetManagementService.onGroupEdit(false);
  }

  public onEditGroupsSave(): void {
    this.state.isGroupEditMode = false;
    this.budgetManagementService.onGroupEdit(false);
  }

  public onAddPosition(): void {
    this.state.isRecordEditMode = true;
    let req: AddRecordReq = new AddRecordReq();
    req.record = new BudgetRecord();
    req.record.budgetedPosition = new BudgetedPosition();
    req.record.censusAdjust = false;
    let maxId: number = 0;
    _.each(this.budget.records, (rec: BudgetRecord) => {
      maxId = Math.max(rec.uniqId, maxId);
    });
    req.record.uniqId = maxId + 1;
    this.budgetManagementService.onAddPosition(req);
  }

  public removeEndDate(): void {
    this.budget.endDate = null;
  }

}

