import { Subject } from 'rxjs/Subject';
import { TimelineExpandHelper } from './timeline-expand-helper';
import { TimelineFitHelper } from './timeline-fit-helper';
var TimelineService = /** @class */ (function () {
    function TimelineService() {
        this.loaded = false;
        this.selectedIndex = 0;
        this.changeDetection = new Subject();
    }
    Object.defineProperty(TimelineService.prototype, "eventsWrapperWidth", {
        get: function () {
            if (!this.helper) {
                return 0;
            }
            return this.helper.eventsWrapperWidth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimelineService.prototype, "prevLinkInactive", {
        get: function () {
            if (!this.helper) {
                return true;
            }
            return this.helper.prevLinkInactive;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimelineService.prototype, "nextLinkInactive", {
        get: function () {
            if (!this.helper) {
                return true;
            }
            return this.helper.nextLinkInactive;
        },
        enumerable: true,
        configurable: true
    });
    TimelineService.prototype.configireAfterViewInit = function (config) {
        this.config = config;
        this.viewInitialized = true;
    };
    TimelineService.prototype.initItems = function (items, rtConfig) {
        this.items = items;
        if (!this.viewInitialized || !rtConfig.startDate || !rtConfig.endDate) {
            return;
        }
        this.detectChanges();
        if (this.items && this.items.length) {
            if (rtConfig.fitToWidth) {
                this.helper = new TimelineFitHelper(this.config);
            }
            else {
                this.helper = new TimelineExpandHelper(this.config);
            }
            this.helper.initTimeline(this.items, rtConfig);
            this.loaded = true;
        }
        this.detectChanges();
    };
    TimelineService.prototype.updateFillingStartByDate = function (date) {
        if (!this.helper) {
            return;
        }
        this.helper.updateFillingStartByDate(date);
    };
    TimelineService.prototype.updateFillingEndByDate = function (date) {
        if (!this.helper) {
            return;
        }
        this.helper.updateFillingEndByDate(date);
    };
    TimelineService.prototype.onScrollClick = function (event, forward) {
        this.helper.updateSlide(this.helper.eventsWrapperWidth, forward);
        this.detectChanges();
    };
    TimelineService.prototype.onTimeChanged = function (date) {
        this.helper.updateFilling(date);
    };
    TimelineService.prototype.detectChanges = function () {
        this.changeDetection.next();
    };
    return TimelineService;
}());
export { TimelineService };
