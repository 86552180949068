import * as _ from 'lodash';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { AcaC1095AuditManagementService } from '../../../services/aca-c1095-audit/aca-c1095-audit-management.service';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { AcaC1095Audit } from '../../../models/aca-c1095-audit';
import { EmployeeFilterRecord, EmployeeFilter } from '../../../models/index';

@Component({
  selector: 'slx-aca-c1095-audit-toolbar',
  templateUrl: './aca-c1095-audit-toolbar.component.html',
  styleUrls: ['./aca-c1095-audit-toolbar.component.scss']
})

export class AcaC1095AuditToolbarComponent implements OnInit {
  public defaultYear: number = moment().startOf('day').toDate().getFullYear();
  public selectedYear: number;
  public yearOptions: number[] = [];
  public isExpand: boolean = false;
  public canExportToExcel: boolean;
  public canExportToPdf: boolean;
  public employeeFilterRecords: EmployeeFilterRecord[];
  public filter: EmployeeFilterRecord[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private acaAuditManagementService: AcaC1095AuditManagementService) { }

  ngOnInit() {
    this.createYearOptions();
    this.selectedYear = this.defaultYear;

    this.subscriptions.onLoaded = this.acaAuditManagementService
      .subscribeToEmployeeFilter((records: EmployeeFilter) => {
        this.filter = records.filter;
        this.employeeFilterRecords = records.employeeFilterRecord;
      });

    this.subscriptions.onLoadedActions = this.acaAuditManagementService
      .subscribeToLoadedRecords((container: AcaC1095Audit) => {
        this.canExportToExcel = container.actions.canExportToExcel;
        this.canExportToPdf = container.actions.canExportToPdf;
      });
  }

  public ngOnDestroy(): void { }

  private createYearOptions(): void {
    this.yearOptions = _.reverse(_.values(_.range(2020, this.defaultYear + 1)));
  }


  public getAcaWidth(): number {
    return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
  }

  public onChangeYear(selYear: number): void {
    this.selectedYear = selYear;
    this.acaAuditManagementService.setSelectedYear(selYear);
  }

  public onClickExpand(isExpand: boolean): void {
    this.isExpand = isExpand;
    this.acaAuditManagementService.expandAll(isExpand);
  }

  public onClickExport(isPDF: boolean): void {
    this.acaAuditManagementService.exportTo(isPDF);
  }

  public onChangeFilter(filters: EmployeeFilterRecord[]): void {
    this.acaAuditManagementService.setEmployeeFilter(filters);
  }
}
