import { IBenefitDetailsPayrollGroup, BenefitDetailsPayrollGroup} from './benefit-details-payroll-group';
import { IBenefitDetailsPayrollDeductions, BenefitDetailsPayrollDeductions} from './benefit-details-payroll-deductions';

export interface IBenefitPayrollExistingMapping {
  benefitName: string;
  payrollDeduction: IBenefitDetailsPayrollDeductions;
  benefitLineId: number;
}

export class BenefitPayrollExistingMapping {
  public payrollDeduction: BenefitDetailsPayrollDeductions;
  public benefitName: string;
  public benefitLineId: number;
}
