export interface IPayRule {
  id: number;
  name: string;
  description: string;
  color: number;
  ruleType: string;
  group: string;
  startTimeOffset: PayRuleOffsetDefinition;
  endTimeOffset: PayRuleOffsetDefinition;
  additionalVariable: string;
}

export class PayRule {
  public id: number;
  public name: string;
  public description: string;
  public color: number;
  public ruleType: string;
  public group: string;
  public startTimeOffset: PayRuleOffsetDefinition;
  public endTimeOffset: PayRuleOffsetDefinition;
  public additionalVariable: string;
}

export type PayRuleOffsetDefinition = 'Schedule Start' | 'Last Punch' | 'First Punch';
