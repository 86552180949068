<form #eligibilityRuleDialogForm="ngForm" novalidate>
  <slx-spinner [show]="state.isLoading">
    <div class="modal-body eligibility-rule-dialog-form">
      <div [ngClass]="{ 'slx-not-editable': !state.isEditing }">
        <div class="row">
          <label class="col-xs-12 col-md-6">
            <slx-input-decorator>
              <input
                #ruleNameValue="ngModel"
                slx-input
                type="text"
                name="ruleName"
                required
                [(ngModel)]="ruleName"
                placeholder="Rule Name"
                slxMaxLength="50"
                [slxUniqueStr]="benefitRulesAlreadyUsedNames"
                (blur)="onValueChanged($event)"
              />
              <div class="slx-error-block rule-name-error-block__margin-top" *ngIf="state.isEditing">
                <span errorMessage for="required" *ngIf="ruleNameValue.errors?.required"></span>
                <span errorMessage for="slxMaxLength" *ngIf="ruleNameValue.errors?.slxMaxLength">
                  Max characters allowed 50
                </span>
                <span errorMessage for="unique" *ngIf="ruleNameValue.errors?.unique"
                  >Same rule name exists. Please choose a different name.</span
                >
              </div>
            </slx-input-decorator>
          </label>
          <div class="col-xs-12 col-md-6">
            <slx-input-decorator>
              <input
                #ruleDescriptionValue="ngModel"
                slx-input
                type="text"
                name="description"
                [(ngModel)]="description"
                placeholder="Description"
                slxMaxLength="1500"
              />
              <span errorMessage for="slxMaxLength" *ngIf="state.isEditing && ruleDescriptionValue.errors?.slxMaxLength">
                Max characters allowed 1500
              </span>
            </slx-input-decorator>
          </div>
        </div>
        <div class="row" *ngIf="ruleModel">
          <div class="col-xs-12 col-md-12">
            <slx-benefit-details-eligibility-rule-dialog-statements
              [ruleStatements]="ruleModel.ruleStatements"
              [configurationVariables]="configurationVariables"
              [configurationPredicates]="configurationPredicates"
              [benefitClasses]="benefitClasses"
              (ruleStatementsChanged)="onRuleStatementsChanged($event)"
              [isEditMode]="isEditMode"
            ></slx-benefit-details-eligibility-rule-dialog-statements>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer" *ngIf="isEditMode">
      <button
        type="button"
        class="btn blue"
        (click)="edit()"
        *ngIf="!state.isEditing"
        [disabled]="request?.isReadonlyMode"
      >
        <i class="fas fa-edit" aria-hidden="true"></i>
        <span class="btn-cancel__text"> Edit </span>
      </button>
      <button
        type="button"
        class="btn green"
        [disabled]="eligibilityRuleDialogForm.invalid || state.isLoading || state.isSaving || request?.isReadonlyMode"
        (click)="save()"
        *ngIf="state.isEditing"
      >
        <i class="fas fa-save" aria-hidden="true"></i>
        <span class="btn-cancel__text"> {{  ruleToUpdate ? 'Update' : 'Save' }} </span>
      </button>
      <button type="button" class="btn red" (click)="cancel()">
        <i class="fa fa-times" aria-hidden="true"></i>
        <span class="btn-cancel__text">Cancel</span>
      </button>
    </div>
  </slx-spinner>
</form>
