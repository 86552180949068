import { EmployeeDefinitionsApiService } from './employee-definitions-api.service';
import * as _ from 'lodash';
var EmployeeValidatorAdapter = /** @class */ (function () {
    function EmployeeValidatorAdapter(employeeDefinitionsApiService) {
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    }
    EmployeeValidatorAdapter.prototype.validate = function (validationName, value) {
        var params = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            params[_i - 2] = arguments[_i];
        }
        var promise;
        var defPromise = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        switch (validationName) {
            case EmployeeValidatorAdapter.payrollNumberValidation:
                promise = this.employeeDefinitionsApiService.validatePayrollNumber(value, params[0], params[1]);
                break;
            case EmployeeValidatorAdapter.ssnValidation:
                if (value === null || value === undefined || value === '') {
                    return defPromise;
                }
                promise = this.employeeDefinitionsApiService.validateSsn(value, params[0], params[1]);
                break;
            case EmployeeValidatorAdapter.badgeIdValidation:
                promise = this.employeeDefinitionsApiService.validateBadgeId(value, params[0]);
                break;
            case EmployeeValidatorAdapter.namesValidation:
                var model = params[0];
                var firstName = model.firstName;
                var lastName = model.lastName;
                var middleName = model.middleName;
                var suffix = model.suffix;
                var departmentOrgLevelId = model.departmentOrgLevelId;
                if (_.isString(firstName) && _.isString(lastName) && _.isNumber(departmentOrgLevelId)) {
                    promise = this.employeeDefinitionsApiService.validateNames(firstName, lastName, middleName, suffix, departmentOrgLevelId);
                }
                else {
                    promise = Promise.resolve({ isValid: false, isReadyForValidation: false, errorMessage: 'Not all required fields are filled' });
                }
                break;
            case EmployeeValidatorAdapter.pbjIdValidation:
                if (value === null || value === undefined || value === '') {
                    return defPromise;
                }
                promise = this.employeeDefinitionsApiService.validatePbjId(value, params[0]);
                break;
            case EmployeeValidatorAdapter.emailValidation:
                if (value === null || value === undefined || value === '') {
                    return defPromise;
                }
                promise = this.employeeDefinitionsApiService.isValidEmail(value, params[0]);
                break;
            default:
                promise = defPromise;
        }
        return promise;
    };
    EmployeeValidatorAdapter.payrollNumberValidation = 'payrollNumberValidation';
    EmployeeValidatorAdapter.ssnValidation = 'ssnValidation';
    EmployeeValidatorAdapter.namesValidation = 'namesValidation';
    EmployeeValidatorAdapter.badgeIdValidation = 'badgeIdValidation';
    EmployeeValidatorAdapter.pbjIdValidation = 'pbjIdValidation';
    EmployeeValidatorAdapter.emailValidation = 'emailValidation';
    return EmployeeValidatorAdapter;
}());
export { EmployeeValidatorAdapter };
