import {Pipe, PipeTransform} from '@angular/core'
import { DomSanitizer} from '@angular/platform-browser'


@Pipe({
    name: 'highlight'
})
export class HighlightSearch implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(value: any, args: any): any {
    if (!args || !value) {
      return value;
    }

    // Match in a case insensitive maneer
    
    if(args.includes('\\')) {
      args = args.replaceAll('\\', '\\\\');
     }

    if(args.includes('(')) {
     args = args.replaceAll('(', '\\(');
    }

    if(args.includes(')')) {
      args = args.replaceAll(')', '\\)');
     }

     if(args.includes('*')) {
      args = args.replaceAll('*', '\\*');
     }

     if(args.includes('+')) {
      args = args.replaceAll('+', '\\+');
     }

     if(args.includes('?')) {
      args = args.replaceAll('?', '\\?');
     }
 
    const re = new RegExp(args, 'gi');
    const match = value.match(re);

    // If there's no match, just return the original value.
    if (!match) {
      return value;
    }

    return value.replace(re, "<mark style='background-color: yellow !important;'>" + match[0] + "</mark>")
  }
}