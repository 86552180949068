<kendo-grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort" [filterable]="false" [filter]="gridState.state.filter" [scrollable]="true">

  <kendo-grid-column title="Select" [sortable]="false" field="isSelected" media="sm" width="100" [filterable]="false">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
    </ng-template>
    <ng-template kendoGridHeaderTemplate>
      <input type="checkbox" [ngModel]="selectionHelper.isAllSelected" (ngModelChange)="allSelectionChange()" name="allSelectionToggle">
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <input type="checkbox" [ngModel]="dataItem.isSelected" (ngModelChange)="selectionChange(dataItem)" name="selector{{rowIndex}}">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="200" media="sm" field="employee.employee.name">
    <ng-template kendoGridHeaderTemplate>
      Name
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem?.employee.employee.id">
        {{dataItem?.employee?.employee?.name}}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Department" width="200" media="sm" field="employee.positionDepartment.name">
    <ng-template kendoGridHeaderTemplate>
      Department
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employee?.positionDepartment?.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position" width="200" media="sm" field="employee.position.name">
    <ng-template kendoGridHeaderTemplate>
      Position
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employee?.position?.name}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Badge #" width="150" media="sm" field="employee.badgeId">
    <ng-template kendoGridHeaderTemplate>
      Badge #
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.badgeId}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="# of Groups" width="150" media="sm" field="groups.length">
    <ng-template kendoGridHeaderTemplate>
      # of Groups
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.groups?.length}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Groups" media="sm" width="200">
    <ng-template kendoGridHeaderTemplate>
      Groups
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.groups | slxJoin :'name' :','}}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>