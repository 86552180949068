import { DetailGroupViewSettingsTypes } from './../../../models/index';
import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Comparison, DisplayDefinition, DisplayType } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-comparison',
  templateUrl: 'schedule-console-comparison.component.html',
  styleUrls: ['schedule-console-comparison.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleConsoleComparisonComponent implements OnChanges {
  @Input()
  public comparison: Comparison;

  @Input()
  public lowerLimit: number;

  @Input()
  public upperLimit: number;

  @Input()
  public displayType: DisplayDefinition;

  @Input()
  public entityId: number;

  @Input()
  public entityType: string;

  public limit: number;
  public value: number;
  public limitText: string;
  public actualText: string;
  public viewMode: DetailGroupViewSettingsTypes;

  public state: {
    isStartOfWeek: boolean;
    isEndOfWeek: boolean;
  };

  private router: Router;
  private activatedRoute: ActivatedRoute;

  constructor(router: Router, activatedRoute: ActivatedRoute) {
    this.state = {
      isStartOfWeek: false,
      isEndOfWeek: false
    };
    this.router = router;
    this.activatedRoute = activatedRoute;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['comparison']) {
      let { date, idealHours, idealPPD, budgetedHours, budgetedPPD }: Comparison = this.comparison;
      this.state.isEndOfWeek = date.weekday() === 6;
      this.state.isStartOfWeek = date.weekday() === 0;
    }

    if (this.comparison && this.displayType) {
      let { idealHours, idealPPD, budgetedHours, budgetedPPD }: Comparison = this.comparison;

      switch (this.displayType) {
        case DisplayType.idealScheduleHours:
          this.actualText = 'Scheduled Hours';
          this.limitText = 'Ideal Schedule Hours';
          this.viewMode = DetailGroupViewSettingsTypes.hours;
          Object.assign(this, idealHours);
          break;
        case DisplayType.idealSchedulePPD:
          this.actualText = 'Scheduled PPD';
          this.limitText = 'Ideal Schedule PPD';
          this.viewMode = DetailGroupViewSettingsTypes.ppd;
          Object.assign(this, idealPPD);
          break;
        case DisplayType.budgetedHours:
          this.actualText = 'Budgeted Hours';
          this.limitText = 'Budgeted Hours';
          this.viewMode = DetailGroupViewSettingsTypes.hours;
          Object.assign(this, budgetedHours);
          break;
        case DisplayType.budgetedPPD:
          this.actualText = 'Budgeted PPD';
          this.limitText = 'Budgeted PPD';
          this.viewMode = DetailGroupViewSettingsTypes.ppd;
          Object.assign(this, budgetedPPD);
          break;
      }
    }
  }

  public isLessThanIdeal(): boolean {
    let actualPercent: number = (this.value / this.limit) * 100;
    return this.lowerLimit && actualPercent < 100 - this.lowerLimit;
  }

  public isGreaterThanIdeal(): boolean {
    let actualPercent: number = (this.value / this.limit) * 100;
    return this.upperLimit && actualPercent > 100 + this.upperLimit;
  }
}
