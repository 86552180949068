import { map, trim, isNil } from 'lodash';
export var uniqueValidationKey = 'unique';
export function uniqueValidator(values, isIgnoreCase) {
    if (isIgnoreCase === void 0) { isIgnoreCase = true; }
    var existingValues = isIgnoreCase ? map(values, function (value) { return !isNil(value) ? trim(value.toLowerCase()) : value; }) : values;
    var validator = function (control) {
        var _a;
        var value = trim(control.value);
        var currentValue = isIgnoreCase && value ? value.toLowerCase() : value;
        var isValid = existingValues.indexOf(currentValue) === -1;
        return !isValid ? (_a = {}, _a[uniqueValidationKey] = true, _a) : null;
    };
    return validator;
}
