import { HostBinding, Input, Output, ContentChild, Component, OnDestroy, Inject, Optional, Host, EventEmitter, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import * as moment from 'moment';

import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { KendoGridFilterInputDirective, KendoGridServerFilterDirective, KendoGridStateDirective } from '../../../directives/index';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoFilterHelper, filterValue, filterCalculated } from '../../../../core/utils/index';
import { dateTimeUtils } from '../../../utils/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { FilterStateManagementService, filterState } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-filter-wrapper-cell',
  templateUrl: 'kendo-grid-filter-wrapper-cell.component.html',
  styleUrls: ['kendo-grid-filter-wrapper-cell.component.scss'],
})
export class KendoGridFilterWrapperCellComponent extends BaseFilterCellComponent implements OnDestroy, AfterContentInit {
  @HostBinding('class.k-filtercell-wrapper')
  public get hostClasses(): boolean { return true; }
  @HostBinding('class.k-filtercell')
  public get overrideBaseClasses(): boolean { return false; }
  @Input()
  public showOperators: boolean;
  @Input()
  public operators: Array<{
    text: string;
    value: string;
  }>;
  @Input()
  public column: ColumnComponent;

  @Input()
  public filter: CompositeFilterDescriptor;

  @Input()
  public get defaultOperator(): string {
    if (!kendoUtils.isNullOrEmptyString(this.m_defaultOperator)) {
      return this.m_defaultOperator;
    } else if (this.operators && this.operators.length) {
      return this.operators[0].value;
    }
    return 'eq';
  }

  @Output()
  public operatorChanged: EventEmitter<string | Function>;
  @Output()
  public clear: EventEmitter<any>;

  public set defaultOperator(value: string) {
    this.m_defaultOperator = value;
  }

  @ContentChild(KendoGridFilterInputDirective, {static: true})
  private input: KendoGridFilterInputDirective;

  @unsubscribe()
  private restoreFilterSubscription: Subscription;
  @unsubscribe()
  private filterRestoredSubscription: Subscription;
  @unsubscribe()
  private changeSubscription: Subscription;

  public get currentFilter(): FilterDescriptor {
    return this.filterByField(this.column.field);
  }

  public get showButton(): boolean {
    let filter: FilterDescriptor = this.currentFilter;
    return kendoUtils.isPresent(filter) && (!kendoUtils.isBlank(filter.value) || kendoUtils.isBlankOperator(filter.operator));
  }

  public get currentOperator(): string | Function {
    let filter: FilterDescriptor = this.currentFilter;
    if (!this.m_operator) {
      this.m_operator = filter ? filter.operator : this.defaultOperator;
    }
    return this.m_operator;
  }

  public set currentOperator(value: string | Function) {
    if (!value) {
      return; //operator can not be undefined
    }
    const changed = this.m_operator !== value;
    this.m_operator = value;
    let slxFilter: kendoUtils.slxFilter = kendoUtils.slxFiltersMap[<string>value];
    if ((slxFilter && !slxFilter.needValue) || value === 'isnull' || value === 'isnotnull' || value === 'isempty' || value === 'isnotempty') {
      this.applyNoValueFilter();
    } else if (!kendoUtils.isBlank(value) && kendoUtils.isPresent(this.lastValue)) {
      this.onChange(this.lastValue);
    }
    if (changed) {
      this.operatorChanged.next(this.m_operator);
    }
  }

  private m_defaultOperator: string;
  private m_operator: string | Function;
  private lastValue: any;
  private changeDetectorRef: ChangeDetectorRef;


  constructor(filterService: FilterService,
    @Inject(KendoGridServerFilterDirective) @Optional() private serverFilterDirective: KendoGridServerFilterDirective,
    @Optional() private filterStateManagementService: FilterStateManagementService
  ) {
    super(filterService);

    this.operators = [];
    this.operatorChanged = new EventEmitter();
    this.clear = new EventEmitter();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngAfterContentInit(): void {
    if (kendoUtils.isPresent(this.input)) {
      this.changeSubscription = this.input.change.subscribe(this.onChange.bind(this));
      if (this.filterStateManagementService) {
        this.restoreFilterSubscription = this.filterStateManagementService.restoreFiltersState$.subscribe((state: filterState) => {
          if (state.field !== this.column.field) {
            return;
          }
          this.setValue(state.value);
          this.m_operator = state.operator;
          this.operatorChanged.next(this.m_operator);
        });

        this.filterRestoredSubscription = this.filterStateManagementService.allFiltersRestored$.subscribe((value: {lastField: string; filters: CompositeFilterDescriptor;}) => {
          if (value.lastField !== this.column.field) {
            return;
          }
          this.filter = value.filters;
          this.applyFilter(value.filters);
        });
      }
    }
  }

  public onChange(value: any): void {
    if (kendoUtils.isBlank(value)) {
      this.removeFilter(this.column.field);
      if (this.serverFilterDirective) {
        this.serverFilterDirective.removeFilter(this.column.field);
      }
      this.applyFilter(this.filter);
      this.saveFilterState(value, this.currentOperator, this.column.field);
      return;
    }

    this.filter = this.calcFilter(value);
    this.applyFilter(this.filter);
    this.saveFilterState(value, this.currentOperator, this.column.field);
    if (this.serverFilterDirective) {
      this.serverFilterDirective.filterChanged(this.filter);
    }
  }

  public onClear(): void {
    this.setValue(null);
    this.onChange(null);
    this.currentOperator = this.defaultOperator;
    this.clear.emit(null);
  }

  protected setValue(value: any): void {
    this.lastValue = value;
    this.input.value = value;
  }

  protected calcFilter(value: any): CompositeFilterDescriptor {
    this.removeFilter(this.column.field);
    let root: CompositeFilterDescriptor = this.filter || { filters: [], logic: 'and' };
    this.setValue(value);
    let descriptor: filterCalculated = KendoFilterHelper.createValuedFilters(this.column.field, this.currentOperator, value);
    if (descriptor) {
      root.filters.push(descriptor.filter);
      if (this.m_operator !== descriptor.newOperator) {
        this.m_operator = descriptor.newOperator;
        this.operatorChanged.next(this.m_operator);
      }
    }
    return root;
  }

  protected composeFilter(current: CompositeFilterDescriptor, filter: FilterDescriptor): CompositeFilterDescriptor {
    return kendoUtils.composeKendoFilter(current, filter);
  }

  protected calcNoValueFilter(): CompositeFilterDescriptor {
    kendoUtils.trimFilterByField(this.filter, this.column.field);
    let root: CompositeFilterDescriptor = this.filter;
    let currentDay: moment.Moment = moment().startOf('day');
    let endCurrentDay: moment.Moment = moment().endOf('day');
    let res: filterValue = KendoFilterHelper.createNonValuedFilters(this.column.field, this.currentOperator, this.lastValue);
    this.setValue(res.value);
    _.forEach(res.filters, (f: FilterDescriptor) => root = this.composeFilter(root, f));
    return root;
    //this.setValue(null);
    //return this.updateFilter({ field: this.column.field, operator: this.currentOperator, value: null });
  }

  protected applyNoValueFilter(): void {
    this.filter = this.calcNoValueFilter();
    this.applyFilter(this.filter);
    if (this.serverFilterDirective) {
      this.serverFilterDirective.filterChanged(this.filter);
    }
  }

  private saveFilterState(value: any, operator: string | Function, field: string): void {
    if (!this.filterStateManagementService) {
      return;
    }
    this.filterStateManagementService.saveFilterState(value, operator, field);
  }
}
