import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { Assert } from '../../../framework/index';
import {
  Arrival, IArrival, Departure, IDeparture,
  ArrivalDeparturesContainer, IArrivalDeparturesContainer,
  DepartureDetails, IDepartureDetails,
  ArrivalDetails, IArrivalDetails,
  IArrivalsDeparturesDetails, IArrivalsDeparturesDetailsRecord, ArrivalsDeparturesDetails, ArrivalsDeparturesDetailsRecord, LinePunch
} from '../../models/index';
import {
  IFieldData,
  FieldRenderType,
  FieldsMeta, FieldAccessType
} from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';
import { LookupMapService, EmployeeDefinitionsMapService, ScheduleDefinitionsMapService } from '../../../organization/services/index';
import { PunchesMapService } from '../punches/punches-map.service';
import { EmployeeDetails, ScheduledShiftDefinition } from '../../../organization/models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class ArrivalsDeparturesMapService {

  constructor(private lookupMapService: LookupMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private scheduleDefinitionsMapService: ScheduleDefinitionsMapService,
    private punchesMapService: PunchesMapService,
    private metaMapService: MetaMapService
  ) {
  }

  public mapArrivalsDetails(arrivalsDto: IArrivalDetails[]): ArrivalDetails[] {
    Assert.isNotNull(arrivalsDto, 'arrivalsDto');
    let arrivals: ArrivalDetails[] = [];
    _.forEach(arrivalsDto, (arrivalDto: IArrivalDetails) => {
      let arrival: ArrivalDetails = this.mapArrivalDetails(arrivalDto);
      arrivals.push(arrival);
    });
    return arrivals;
  }

  public mapArrivalDetails(detailsDto: IArrivalDetails): ArrivalDetails {
    Assert.isNotNull(detailsDto, 'detailsDto');
    let details: ArrivalDetails = new ArrivalDetails();
    details.actualEnd = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.actualEnd);
    details.actualStart = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.actualStart);
    details.scheduleEnd = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.scheduleEnd);
    details.scheduleStart = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.scheduleStart);
    details.isDiff = detailsDto.isDiff;
    details.isIn = detailsDto.isIn;
    details.isLate = detailsDto.isLate;
    details.isScheduled = detailsDto.isScheduled;
    details.position = this.lookupMapService.mapPosition(detailsDto.position);
    details.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(detailsDto.employee);
    return details;
  }

  public mapDeparturesDetails(departuresDto: IDepartureDetails[]): DepartureDetails[] {
    Assert.isNotNull(departuresDto, 'arrivalsDto');
    let departures: DepartureDetails[] = [];
    _.forEach(departuresDto, (arrivalDto: IDepartureDetails) => {
      let departure: DepartureDetails = this.mapDepartureDetails(arrivalDto);
      departures.push(departure);
    });
    return departures;
  }

  public mapDepartureDetails(detailsDto: IDepartureDetails): DepartureDetails {
    Assert.isNotNull(detailsDto, 'detailsDto');
    let details: DepartureDetails = new DepartureDetails();
    details.actualEnd = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.actualEnd);
    details.actualStart = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.actualStart);
    details.scheduleEnd = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.scheduleEnd);
    details.scheduleStart = dateTimeUtils.convertFromDtoDateTimeString(detailsDto.scheduleStart);
    details.isDiff = detailsDto.isDiff;
    details.isOut = detailsDto.isOut;
    details.isInOvertime = detailsDto.isInOvertime;
    details.isScheduled = detailsDto.isScheduled;
    details.position = this.lookupMapService.mapPosition(detailsDto.position);
    details.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(detailsDto.employee);
    return details;
  }

  public mapArrivalsDeparturesContainer(containerDto: IArrivalDeparturesContainer): ArrivalDeparturesContainer {
    Assert.isNotNull(containerDto, 'containerDto');
    let container: ArrivalDeparturesContainer = new ArrivalDeparturesContainer();
    container.orgLevelId = containerDto.orgLevelId;
    container.workDate = containerDto.workDate;
    container.arrivals = this.mapArrivals(containerDto.arrivals);
    container.departures = this.mapDepartures(containerDto.departures);
    container.missingPunchesCount = containerDto.missingPunchesCount;
    container.timecardExceptionCount = containerDto.timecardExceptionCount;
    return container;
  }

  public mapArrivals(arrivalsDto: IArrival[]): Arrival[] {
    Assert.isNotNull(arrivalsDto, 'arrivalsDto');
    let arrivals: Arrival[] = [];
    _.forEach(arrivalsDto, (arrivalDto: IArrival) => {
      let arrival: Arrival = this.mapArrival(arrivalDto);
      arrivals.push(arrival);
    });
    return arrivals;
  }

  public mapArrival(arrivalDto: IArrival): Arrival {
    Assert.isNotNull(arrivalDto, 'arrivalDto');
    let arrival: Arrival = new Arrival();
    arrival.arrivalTime = dateTimeUtils.convertFromDtoDateTimeString(arrivalDto.arrivalTime);
    arrival.difference = arrivalDto.difference;
    arrival.in = arrivalDto.in;
    arrival.late = arrivalDto.late;
    arrival.scheduled = arrivalDto.scheduled;
    arrival.shiftId = arrivalDto.shiftId;
    return arrival;
  }

  public mapDepartures(departuresDto: IDeparture[]): Departure[] {
    Assert.isNotNull(departuresDto, 'departuresDto');
    let departures: Departure[] = [];
    _.forEach(departuresDto, (departureDto: IDeparture) => {
      let departure: Departure = this.mapDeparture(departureDto);
      departures.push(departure);
    });
    return departures;
  }

  public mapDeparture(departureDto: IDeparture): Departure {
    Assert.isNotNull(departureDto, 'departureDto');
    let departure: Departure = new Departure();
    departure.departureTime = dateTimeUtils.convertFromDtoDateTimeString(departureDto.departureTime);
    departure.difference = departureDto.difference;
    departure.out = departureDto.out;
    departure.overtime = departureDto.overtime;
    departure.scheduled = departureDto.scheduled;
    departure.shiftId = departureDto.shiftId;
    return departure;
  }


  public mapDeparturesDetailsDtoToEmployeeDetails(departuresDto: IDepartureDetails[]): EmployeeDetails[] {
    let departuresDetails: DepartureDetails[] = this.mapDeparturesDetails(departuresDto);
    return this.mapDeparturesDetailsToEmployeeDetails(departuresDetails);
  }

  public mapDeparturesDetailsToEmployeeDetails(departuresDto: DepartureDetails[]): EmployeeDetails[] {
    Assert.isNotNull(departuresDto, 'departuresDto');
    let departures: EmployeeDetails[] = [];
    _.forEach(departuresDto, (departureDto: DepartureDetails) => {
      let departure: EmployeeDetails = this.mapDepartureDetailsToEmployeeDetails(departureDto);
      departures.push(departure);
    });
    return departures;
  }

  public mapDepartureDetailsToEmployeeDetails(departureDetails: DepartureDetails): EmployeeDetails {
    Assert.isNotNull(departureDetails);
    let details: EmployeeDetails = new EmployeeDetails();
    details.actualStart = departureDetails.actualStart;
    details.actualEnd = departureDetails.actualEnd;
    details.employee = departureDetails.employee;
    details.position = departureDetails.position;
    details.scheduleEnd = departureDetails.scheduleEnd;
    details.scheduleStart = departureDetails.scheduleStart;
    return details;
  }

  public mapArrivalsDetailsDtoToEmployeeDetails(arrivalsDto: IArrivalDetails[]): EmployeeDetails[] {
    let arrivalsDetails: ArrivalDetails[] = this.mapArrivalsDetails(arrivalsDto);
    return this.mapArrivalsDetailsToEmployeeDetails(arrivalsDetails);
  }

  public mapArrivalsDetailsToEmployeeDetails(arrivalsDto: ArrivalDetails[]): EmployeeDetails[] {
    Assert.isNotNull(arrivalsDto, 'departuresDto');
    let arrivals: EmployeeDetails[] = [];
    _.forEach(arrivalsDto, (arrivalDto: ArrivalDetails) => {
      let departure: EmployeeDetails = this.mapArrivalDetailsToEmployeeDetails(arrivalDto);
      arrivals.push(departure);
    });
    return arrivals;
  }

  public mapArrivalDetailsToEmployeeDetails(arrivalDetails: ArrivalDetails): EmployeeDetails {
    Assert.isNotNull(arrivalDetails);
    let details: ArrivalDetails = new ArrivalDetails();
    details.actualStart = arrivalDetails.actualStart;
    details.actualEnd = arrivalDetails.actualEnd;
    details.employee = arrivalDetails.employee;
    details.position = arrivalDetails.position;
    details.scheduleEnd = arrivalDetails.scheduleEnd;
    details.scheduleStart = arrivalDetails.scheduleStart;
    return details;
  }

  public mapArrivalsDeparturesRecordDetailsRecord(dto: IArrivalsDeparturesDetailsRecord): ArrivalsDeparturesDetailsRecord {
    const r = new ArrivalsDeparturesDetailsRecord();
    r.workDate = dateTimeUtils.convertFromDtoString(dto.workDate);
    r.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    r.entry = dto.entry ? this.scheduleDefinitionsMapService.mapToScheduleEntryDefinition(dto.entry) : null;
    r.punches = _.orderBy(this.punchesMapService.mapLinePanches(dto.punches), (p: LinePunch) => p.time);
    r.scheduledHours = dto.scheduledHours;
    r.actualHours = dto.actualHours;
    r.isLate = dto.isLate;
    r.hasMissingPunches = dto.hasMissingPunches;

    r.isApproachingOT = dto.isApproachingOT;

    const inPunches = _.filter(r.punches, (p: LinePunch) => p.type.isIn && !p.type.isLunch);
    const outPunches = _.filter(r.punches, (p: LinePunch) => p.type.isOut && !p.type.isLunch);
    const inLunchPunches = _.filter(r.punches, (p: LinePunch) => p.type.isIn && p.type.isLunch);
    const outLunchPunches = _.filter(r.punches, (p: LinePunch) => p.type.isOut && p.type.isLunch);
    const inPunch = _.first(inPunches);
    const outPunch = _.last(outPunches);
    const outLunchPunch = _.first(outLunchPunches);
    const inLunchPunch = _.last(inLunchPunches);

    r.inTime = inPunch ? moment(inPunch.time) : null;
    r.outTime = outPunch ? moment(outPunch.time) : null;
    r.lunchOutTime = outLunchPunch ? moment(outLunchPunch.time) : null;
    r.lunchInTime = inLunchPunch ? moment(inLunchPunch.time) : null;

    r.isArrival = !!inPunch;
    r.isDeparture = !!outPunch;

    let emptyTime = '';
    if (r.hasMissingPunches) {
      emptyTime = 'Missing';
      r.inPunchMissing = !!inPunch;
      r.outPunchMissing = !!outPunch;
      r.lunchOutPunchMissing = !!outLunchPunch;
      r.lunchInPunchMissing = !!inLunchPunch;
    }
    r.inTimeStr = r.inTime ? r.inTime.format(appConfig.timeFormat) : emptyTime;
    r.outTimeStr = r.outTime ? r.outTime.format(appConfig.timeFormat) : emptyTime;
    r.lunchOutTimeStr = r.lunchOutTime ? r.lunchOutTime.format(appConfig.timeFormat) : emptyTime;
    r.lunchInTimeStr = r.lunchInTime ? r.lunchInTime.format(appConfig.timeFormat) : emptyTime;
    if (r.entry && r.entry.shifts && r.entry.shifts.length > 0) {
      const orderedShifts = _.orderBy(r.entry.shifts, (shift: ScheduledShiftDefinition) => shift.start);
      r.firstShiftStart = moment(_.first(orderedShifts).start);
      r.lastShiftEnd = moment(_.last(orderedShifts).end);
      r.shiftsStr = _.join(_.map(r.entry.shifts, (shift: ScheduledShiftDefinition) => `${moment(shift.start).format(appConfig.timeFormat)}-${moment(shift.end).format(appConfig.timeFormat)}`));
      const unitArr = _.map(r.entry.shifts, (shift: ScheduledShiftDefinition) => shift.unit ? shift.unit.name : '');
      const unitsUniq = _.uniq(unitArr);
      r.unitsStr = _.join(unitsUniq);
      r.unitsIds = _.map(r.entry.shifts, (shift: ScheduledShiftDefinition) => shift.unit ? shift.unit.id : 0);
      const posArr = _.map(r.entry.shifts, (shift: ScheduledShiftDefinition) => shift.position ? shift.position.name : '');
      const posUniq = _.uniq(posArr);
      r.positionStr = _.join(posUniq);
    } else {
      r.shiftsStr = '';
      r.unitsStr = '';
      r.positionStr = '';
      r.unitsIds = [];
    }
    return r;
  }

  public mapArrivalsDeparturesDetails(dto: IArrivalsDeparturesDetails, meta: FieldsMeta): ArrivalsDeparturesDetails {
    const d = new ArrivalsDeparturesDetails();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    d.empPhoneMeta = this.metaMapService.mapMeta<string>(metaMap, 'empPhone', null);
    d.cellPhoneNoMeta = this.metaMapService.mapMeta<string>(metaMap, 'cellPhoneNo', null);
    d.records = _.map(dto.records, (r: IArrivalsDeparturesDetailsRecord) => this.mapArrivalsDeparturesRecordDetailsRecord(r));
    return d;
  }
}


