
export class EssDefaultPassword {
  public static noChange: EssDefaultPasswordDefinition = 'NoChange';
  public static last4SSN: EssDefaultPasswordDefinition = 'SSNLast4Digits';
  public static dobMMDD: EssDefaultPasswordDefinition = 'DateOfBirthMMDD';
  public static dobMMYY: EssDefaultPasswordDefinition = 'DateOfBirthMMYY';
  public static payrollNo: EssDefaultPasswordDefinition = 'PayrollNumber';
  public static custom: EssDefaultPasswordDefinition = 'Custom';

  public static getList(addNoChanges: boolean, addCustomPassword: boolean = false): IEssDefaultPasswordOption[] {
    const list = [
      { id: EssDefaultPassword.last4SSN, name: 'Last 4 digits of SSN' },
      { id: EssDefaultPassword.dobMMDD, name: 'DOB - MMDD' },
      { id: EssDefaultPassword.dobMMYY, name: 'DOB - MMYY' },
      { id: EssDefaultPassword.payrollNo, name: 'Payroll No' }
    ];
    if (addNoChanges) {
      list.unshift( { id: EssDefaultPassword.noChange, name: 'No changes' });
    }
    if (addCustomPassword) {
      list.push( { id: EssDefaultPassword.custom, name: 'Custom Password' });
    }
    return list;
  }
}

export type EssDefaultPasswordDefinition = 'NoChange' | 'SSNLast4Digits' | 'DateOfBirthMMDD' | 'DateOfBirthMMYY' | 'PayrollNumber' | 'Custom';

export interface IEssDefaultPasswordOption {
  id: EssDefaultPasswordDefinition;
  name: string;
}
