<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div class="slx-main-content-indents slx-high-box__body page-overflow">
      <div class="slx-el-table-header slx-el-row border-top">
        <div class="slx-el-col hxs"></div>
        <!-- <div *ngIf="stateManagement.canDelete" class="slx-el-col xs"></div>
        <div *ngIf="stateManagement.canEdit" class="slx-el-col hxs"></div> -->

        <div *ngIf="stateManagement.canEdit" class="slx-el-col ohxs"></div>

        <div class="slx-el-col md">
          <span class="slx-el-header-title">Organization</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Department</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Clock Name</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Clock Model</span>
        </div>
        <div class="slx-el-col ohxs">
          <span class="slx-el-header-title">Clock ID</span>
        </div>
        <div class="slx-el-col ohxs">
          <span class="slx-el-header-title">Physical ID</span>
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-header-title">IP Address</span>
        </div>
      </div>

      <slx-editable-list [data]="container?.records" class="slx-el-table-body" (editStart)="onItemEditStart ($event)" (editCancel)="onItemEditCancel ($event)"
        (selectionChange)="onSelectionChange ($event)" (itemSave)="onItemSave ($event)" [editable]="false" [selectable]="false">

        <ng-template slx-editable-list-renderer let-context>
          <slx-timeclock-renderer slx-list-highlight slx-list-actions [context]="context"></slx-timeclock-renderer>
        </ng-template>

        <!--<ng-template slx-editable-list-editor let-item>
          <slx-timeclock-editor [item]="item" [container]="container" slx-list-actions></slx-timeclock-editor>
        </ng-template> -->

      </slx-editable-list>
    </div>

    <!-- <div class="slx-el-actions-bar" [ngClass]="{'inactive': stateManagement.lockActions}">
      <button class="slx-el-action-button" [slx-list-delete-operation]="onDeleteSelected">Delete</button>
      <span *ngIf="stateManagement.restrictedByOrgLevel" class="slx-error-block">You can add Timeclok only at Organization or Department level</span>
      <button [disabled]="stateManagement.restrictedByOrgLevel" class="slx-el-action-button" [slx-list-add-operation]="onAddItem">Add</button>
    </div> -->
  </div>

</slx-spinner>
