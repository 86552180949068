import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimeclockAssignmentManagementService } from '../../../services/index';
import { TimeclockAssignmentEmployee, TimeclockAssignment, TimeclockRestriction } from '../../../models/index';
var TimeclockAssignmentTimeclocksComponent = /** @class */ (function () {
    function TimeclockAssignmentTimeclocksComponent(timeclockAssignmentManagementService) {
        this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
        this.gridState = new KendoGridStateHelper();
        this.restrictionChanged = false;
        this.prevRestrictionId = null;
    }
    Object.defineProperty(TimeclockAssignmentTimeclocksComponent.prototype, "isEditMode", {
        get: function () {
            return _.isNumber(this.gridState.editedRowIndex);
        },
        enumerable: true,
        configurable: true
    });
    TimeclockAssignmentTimeclocksComponent.prototype.ngOnChanges = function (changes) {
        if (changes['records']) {
            this.refreshGrid();
        }
    };
    TimeclockAssignmentTimeclocksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            var item = event.dataItem;
            var assignment = _this.makeTimeclocksAssignment(_this.employee.employee, item.timeclock, item.restriction);
            _this.timeclockAssignmentManagementService.onReAssign(assignment);
            _this.restrictionChanged = false;
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            var item = event.dataItem;
            var assignment = _this.makeTimeclocksAssignment(_this.employee.employee, item.timeclock, item.restriction);
            _this.timeclockAssignmentManagementService.onUnAssign(assignment);
            _.remove(_this.records, function (record) { return record.timeclock.id === item.timeclock.id; });
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) { return _this.prevRestrictionId = item.restriction.id; });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function () { return _this.restrictionChanged = false; });
    };
    TimeclockAssignmentTimeclocksComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeclockAssignmentTimeclocksComponent.prototype.onRestrictionChanged = function (restriction) {
        this.restrictionChanged = restriction.id !== this.prevRestrictionId;
    };
    TimeclockAssignmentTimeclocksComponent.prototype.makeTimeclocksAssignment = function (employee, timeclock, restriction) {
        var timeclockRestriction = new TimeclockRestriction();
        timeclockRestriction.timeclock = timeclock;
        timeclockRestriction.restriction = restriction;
        var assignment = new TimeclockAssignment();
        assignment.employee = employee;
        assignment.timeclockRestrictions = [timeclockRestriction];
        return [assignment];
    };
    TimeclockAssignmentTimeclocksComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentTimeclocksComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentTimeclocksComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentTimeclocksComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentTimeclocksComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclockAssignmentTimeclocksComponent.prototype, "gridCancelSubscription", void 0);
    return TimeclockAssignmentTimeclocksComponent;
}());
export { TimeclockAssignmentTimeclocksComponent };
