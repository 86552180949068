import * as tslib_1 from "tslib";
import { BusyService } from '../../../../common';
import { data_Check } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { NotificationsService } from '../../../../core/components/angular2-notifications';
import { BaseFilterSyncComponent } from '../base-filter-sync.component';
var EmployeeMigrationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeMigrationComponent, _super);
    function EmployeeMigrationComponent(wfmSyncService, notificationService, busyService) {
        return _super.call(this, wfmSyncService, notificationService, busyService) || this;
    }
    EmployeeMigrationComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.employeeSyncOrg(selectedItems, 'ongoing'));
    };
    EmployeeMigrationComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.employeeID;
    };
    EmployeeMigrationComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.employeeDetailsSync('ongoing'))];
                    case 1:
                        data = _a.sent();
                        return [2 /*return*/, this.processData(data)];
                }
            });
        });
    };
    EmployeeMigrationComponent.prototype.processData = function (data) {
        return data_Check(data);
    };
    EmployeeMigrationComponent.prototype.setGlobalFilter = function () {
        var _this = this;
        this.globalFilterFunc$.next(function (item) {
            var orgId = _this.selectedOrganization && _this.selectedOrganization.id;
            var deptId = _this.selectedDepartment && _this.selectedDepartment.id;
            var posId = _this.selectedPosition && _this.selectedPosition.id;
            return (orgId == null || item.organizationId === orgId) &&
                (deptId == null || item.departmentId === deptId) &&
                (posId == null || item.positionId === posId);
        });
    };
    return EmployeeMigrationComponent;
}(BaseFilterSyncComponent));
export { EmployeeMigrationComponent };
