import { Component, OnInit } from '@angular/core';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';

@Component({
  selector: 'slx-ongoing-sync',
  templateUrl: './wfm-ongoing-sync.component.html',
  styleUrls: ['./wfm-ongoing-sync.component.scss']
})
export class WFMOngoingSyncComponent implements OnInit
 {
    constructor(appSettingManageService: AppSettingsManageService) {
        this.appSettingManageService = appSettingManageService;
    }
    
  public nextgenPhasedRollout: boolean = false;
  private appSettingManageService: AppSettingsManageService;

    public ngOnInit() {
        this.getSettings();
    }

    public async getSettings(): Promise<void> {
        let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
        this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
    }

 }