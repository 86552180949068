import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsLicense = /** @class */ (function () {
    function EmployeeSectionsLicense() {
    }
    return EmployeeSectionsLicense;
}());
export { EmployeeSectionsLicense };
var EmployeeSectionsLicenses = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsLicenses, _super);
    function EmployeeSectionsLicenses() {
        var _this = _super.call(this) || this;
        _this.employeeSectionsLicenses = [];
        return _this;
    }
    return EmployeeSectionsLicenses;
}(EmployeeSectionsBase));
export { EmployeeSectionsLicenses };
