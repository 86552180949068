import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { unsubscribeAll } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common';
import { AcaC1095AuditManagementService } from '../../services/aca-c1095-audit/aca-c1095-audit-management.service';
var AcaC1095AuditComponent = /** @class */ (function () {
    function AcaC1095AuditComponent(acaC1095AuditManagementService, stateManagementService) {
        this.acaC1095AuditManagementService = acaC1095AuditManagementService;
        this.stateManagementService = stateManagementService;
        this.isLoading = false;
        this.componentId = 'AcaC1095AuditComponent';
        this.subscriptions = {};
    }
    AcaC1095AuditComponent.prototype.ngOnDestroy = function () { };
    AcaC1095AuditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagementService.init(this.componentId, true);
        this.acaC1095AuditManagementService.init();
        this.subscriptions.loading = this.acaC1095AuditManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaC1095AuditComponent.prototype, "subscriptions", void 0);
    return AcaC1095AuditComponent;
}());
export { AcaC1095AuditComponent };
