<div class="modal-content">
    <div class="modal-body">
       <p *ngIf="foundMatches">Please select the start date for the export. The output will include records with disposition dates on or after this date.</p>
       <p *ngIf="!foundMatches">No Matches exist in the selected date range, please select a different start date for your export.</p>
       <slx-datepicker
        [required]="true"
        name="selectedDate"
        [(ngModel)]="selectedDate"
        (ngModelChange)="onFilterDateChanged($event)"
      ></slx-datepicker>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger bg-red" (click)="onClose()">Close</button>
        <button type="button" class="btn btn-success bg-green" (click)="onSave()">Export</button>
    </div>
</div>