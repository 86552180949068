import { OnInit } from '@angular/core';
import { ModalService, DialogOptions2 } from '../../../../../common';
import { AbstractControl, NgForm } from '@angular/forms';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { acaCorrectionTypeConfig, receiptIdMaskRegExp } from '../../../export-data.config';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import * as _ from 'lodash';
var AcaCorrectionsDialogComponent = /** @class */ (function () {
    function AcaCorrectionsDialogComponent(modalService, options, manService) {
        this.acaCorrectionType = new AcaExportCorrectionType();
        this.dialogResult = false;
        this.isLoading = true;
        this.options = options;
        this.modalService = modalService;
        this.manService = manService;
        this.receiptIdMaskConf = receiptIdMaskRegExp;
    }
    Object.defineProperty(AcaCorrectionsDialogComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AcaCorrectionsDialogComponent.prototype, "form", {
        get: function () {
            return this.ngFormChild ? this.ngFormChild.form : null;
        },
        enumerable: true,
        configurable: true
    });
    AcaCorrectionsDialogComponent.prototype.createCorrectionTypeOptions = function () {
        var _this = this;
        var correctionType = acaCorrectionTypeConfig;
        correctionType = _.filter(acaCorrectionTypeConfig, function (item) { return (_.includes(item.relatedExportType, _this.acaExportExecutionItem.type)); });
        this.correctionTypeOptions = correctionType;
    };
    AcaCorrectionsDialogComponent.prototype.resetReceiptId = function () {
        this.acaCorrectionType.receiptId = null;
    };
    AcaCorrectionsDialogComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.isLoading = false;
    };
    Object.defineProperty(AcaCorrectionsDialogComponent.prototype, "valid", {
        get: function () {
            var formValid = this.form.invalid ? false : true;
            return formValid;
        },
        enumerable: true,
        configurable: true
    });
    AcaCorrectionsDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    AcaCorrectionsDialogComponent.prototype.showSelectEmpDialog = function () {
        if (this.acaCorrectionType && this.acaCorrectionType.id == 1) {
            this.onCancel();
            var acaExportDialogOptions = new AcaExportDialogOptions();
            acaExportDialogOptions.dialogType = 5;
            acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
            acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
            this.manService.openAcaExportDialog(acaExportDialogOptions);
        }
        else if (this.acaCorrectionType && this.acaCorrectionType.id == 2) {
            this.onCancel();
            var acaExportDialogOptions = new AcaExportDialogOptions();
            acaExportDialogOptions.dialogType = 2;
            acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
            acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
            this.manService.openAcaExportDialog(acaExportDialogOptions);
        }
        else if (this.acaCorrectionType && this.acaCorrectionType.id == 3) {
            this.onCancel();
            var acaExportDialogOptions = new AcaExportDialogOptions();
            acaExportDialogOptions.dialogType = 4;
            acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
            acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
            this.manService.openAcaExportDialog(acaExportDialogOptions);
        }
    };
    return AcaCorrectionsDialogComponent;
}());
export { AcaCorrectionsDialogComponent };
