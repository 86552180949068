import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, Event, ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import { RouteData } from '../../models/index';
import { BreadcrumbStateService } from '../../services/index';
import { IBreadcrumbItemConfig, BreadcrumbItem } from '../../models/index';
import { AppSettingsManageService } from '../../../app-settings/services';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';

@Component({
  moduleId: module.id,
  selector: 'slx-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
  styleUrls: ['breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  public urls: BreadcrumbItem[];
  public icon: string;
  public hasMoreThanNecessary: boolean;

  private router: Router;
  private breadcrumbService: BreadcrumbStateService;
  private routerSubscription: Subscription;
  private activatedRoute: ActivatedRoute;
  private readonly hrmsAppName: string = 'hrms';
  public nextgenPhasedRollout: boolean = false;
  private appSettingManageService: AppSettingsManageService;

  constructor(router: Router, activatedRoute: ActivatedRoute, breadcrumbService: BreadcrumbStateService,
    appSettingManageService: AppSettingsManageService) {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.breadcrumbService = breadcrumbService;
    this.appSettingManageService = appSettingManageService;
    this.urls = [];
  }

  public ngOnInit(): void {
    this.getSettings();
    this.buildBreadcrumbs(this.router.url);
    this.routerSubscription = this.router.events
      .filter((event: Event) => event instanceof NavigationEnd)
      .subscribe((event: NavigationEnd) => {
        if (event.url) {
          this.buildBreadcrumbs(event.urlAfterRedirects ? event.urlAfterRedirects : event.url);
        }
      });
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public onBreadcrumbClick(item: BreadcrumbItem): void {
    let currentUrls: string[], splitUrls: string[], currentUrl: string
      , empId = "d001"; //To compare URL against any employee, replaced employee id in URL with this value for comparing
    if(this.router.url.startsWith("/apps/common/employee/") && this.router.url.includes("employee_sections")){
      currentUrls = this.router.url.split('?');
      if(currentUrls[0]){
        splitUrls = currentUrls[0].split('/');
        if(splitUrls.length > 5){
          splitUrls[4] = empId;
        }
      }
      currentUrl = splitUrls.join('/');
      if(item.linkPath === "/apps/common/employee_list" && currentUrl === `/apps/common/employee/${empId}/employee_sections`){
        localStorage.setItem('hitFromEmpSection', 'yes');
      }
    }
    this.breadcrumbService.clickOnBreadcrumb(item);
  }

  // TODO: as per discussion decided to fix it in this way, after release will be refactored
  public isHrmsApp(appName: string): boolean {
    return this.hrmsAppName === _.lowerCase(appName);
  }

  private buildBreadcrumbs(eventUrl: string): void {
    this.breadcrumbService.buildBreadcrumbs(eventUrl)
      .then((result: BreadcrumbItem[]) => {
        if (result.length > 4) {
          this.hasMoreThanNecessary = true;
          this.urls = result.slice(result.length - 4);
        } else {
          this.hasMoreThanNecessary = false;
          
          if(this.nextgenPhasedRollout == true){
            var index = result.map(function(e) { return e.linkPath; }).indexOf('/apps/ngp');
            if(index >=0){
              result[index].isNotLink = true;
              result[index].title = 'Next Gen Payroll';
  
              result.splice(index+1 ,0 ,this.buildSyncBreadcrumb());
            }
          }  
          this.urls = result;
        }
        let icon = this.breadcrumbService.icon;
        if (icon instanceof Promise) {
          icon.then((v: string) => {
            this.icon = v;
          });
        } else {
          this.icon = icon;
        }
      });
  }

  public buildSyncBreadcrumb(): BreadcrumbItem {
    let breadcumb= new BreadcrumbItem();
    breadcumb.title = 'Sync',
    breadcumb.isNotLink= true
    return breadcumb;
  }

  public async getSettings(): Promise<void> {
    let appServerConfig: AppServerConfig = await this.appSettingManageService.getAppServerConfig();
    this.nextgenPhasedRollout = appServerConfig.NextgenPhasedRollout;
  }
}
