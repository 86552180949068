import * as tslib_1 from "tslib";
import { ChangeManagementService } from './../../../../../../common/services/change-management/change-management.service';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddEmployeeWizardActions } from '../../../../store/add-employee-wizard/add-employee-wizard.actions';
import { AddEmployeeModel } from '../../../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { Organization } from '../../../../../../organization/models/lookup/index';
import { mutableSelect, unsubscribe } from '../../../../../../core/decorators/index';
import { AddEmployeeWizardStatus } from '../../../../store/add-employee-wizard/add-employee-wizard.types';
import { EmployeeActivitiesApiService } from '../../../../services/employee-activities/employee-add-api.service';
import { AddEmployeeNavigationService } from '../../../../../../common/services/navigation/add-employee-navigation.service';
var AddEmployeeComponent = /** @class */ (function () {
    function AddEmployeeComponent(addEmployeeWizardActions, lookupApiService, employeeActivitiesApiService, activatedRoute, router, changeService) {
        this.addEmployeeWizardActions = addEmployeeWizardActions;
        this.lookupApiService = lookupApiService;
        this.employeeActivitiesApiService = employeeActivitiesApiService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.changeService = changeService;
        this.navigationService = new AddEmployeeNavigationService(this.router, this.activatedRoute);
        this.state = {
            isOrganizationsLoading: false,
            isEmployeeAdding: false,
            isClearAllEnable: true
        };
    }
    Object.defineProperty(AddEmployeeComponent.prototype, "isLoading", {
        get: function () {
            return this.state.isOrganizationsLoading || this.state.isEmployeeAdding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeComponent.prototype, "clearAllEnable", {
        get: function () {
            return this.state.isClearAllEnable;
        },
        enumerable: true,
        configurable: true
    });
    AddEmployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.changeService.setCurrentComponentId('add_employee');
        this.addEmployeeWizardActions.startWizard(new AddEmployeeModel());
        this.orgLevel$.subscribe(function (storedOrgLevel) {
            _this.currentOrgLevel = storedOrgLevel;
        });
        this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe(function (status) {
            if (status === AddEmployeeWizardStatus.started) {
                _this.state.isClearAllEnable = true;
            }
            if (status === AddEmployeeWizardStatus.pending) {
                _this.state.isEmployeeAdding = true;
            }
            if (status === AddEmployeeWizardStatus.success) {
                _this.state.isEmployeeAdding = false;
                _this.changeService.clearChanges();
                _this.state.isClearAllEnable = false;
            }
            if (status === AddEmployeeWizardStatus.error) {
                _this.state.isEmployeeAdding = false;
                _this.state.isClearAllEnable = true;
            }
            if (status === AddEmployeeWizardStatus.validationFailed) {
                _this.state.isEmployeeAdding = false;
                _this.state.isClearAllEnable = true;
            }
        });
        this.clearAllFields();
        this.loadLookups();
    };
    AddEmployeeComponent.prototype.ngOnDestroy = function () {
        if (this.addEmployeeWizardSubscription) {
            this.addEmployeeWizardSubscription.unsubscribe();
        }
    };
    AddEmployeeComponent.prototype.loadLookups = function () {
        if (!this.organizationLookup) {
            this.loadOrganizationsLookup();
        }
    };
    AddEmployeeComponent.prototype.loadOrganizationsLookup = function () {
        var _this = this;
        this.state.isOrganizationsLoading = true;
        this.lookupApiService.getUserAccesibleOrganizations()
            .then(function (organizationLookup) {
            _this.organizationLookup = organizationLookup;
            _this.state.isOrganizationsLoading = false;
        });
    };
    AddEmployeeComponent.prototype.loadRestrictions = function (model) {
        var _this = this;
        if (model.payrollNumber == null) {
            this.employeeActivitiesApiService.getAddEmployeeProperties(model.organization.orgLevelId)
                .then(function (addEmployeeRestrictions) {
                _this.addEmployeeRestrictions = addEmployeeRestrictions;
                if (!model.payrollNumber || model.payrollNumber === model.defaultPayrollNumber) {
                    model.payrollNumber = _this.addEmployeeRestrictions.payrollNumber.defaultFieldValue;
                }
                model.defaultPayrollNumber = _this.addEmployeeRestrictions.payrollNumber.defaultFieldValue;
            });
        }
    };
    AddEmployeeComponent.prototype.clearAllFields = function () {
        this.changeService.clearChanges();
        this.addEmployeeWizardActions.clearWizard();
        this.addEmployeeWizardActions.startWizard(new AddEmployeeModel());
    };
    AddEmployeeComponent.prototype.onFormChanged = function () {
        this.changeService.changeNotify();
    };
    tslib_1.__decorate([
        mutableSelect(['addEmployeeWizard', 'status']),
        tslib_1.__metadata("design:type", Observable)
    ], AddEmployeeComponent.prototype, "addEmployeeWizard$", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AddEmployeeComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AddEmployeeComponent.prototype, "routeSubscripion", void 0);
    return AddEmployeeComponent;
}());
export { AddEmployeeComponent };
