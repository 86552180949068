import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { ApprovalRequiredCodeRecord } from './../../models/approval-required-codes/approval-required-code-record.model';
import { genericListConfig } from '../../../../organization/generic-list.config';
import { GenericListRequest } from './../../../../organization/models/generic-list/index';
import { appConfig } from './../../../../app.config';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from './../../../../core/decorators/index';
import { GenericListManagementService } from '../../../../organization/services/generic-list/generic-list-management.service';
import { ApprovalRequiredCodesApiService } from './../../services/approval-required-codes/approval-required-codes-api.service';
import { OnInit, OnDestroy } from '@angular/core';
import { StateManagementService } from './../../../../common/services/state-management/state-management.service';
var ApprovalRequiredCodesComponent = /** @class */ (function () {
    function ApprovalRequiredCodesComponent(genericListManagementService, apiService, stateManagement, storageService, activatedRoute, router) {
        this.genericListManagementService = genericListManagementService;
        this.apiService = apiService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.m_dateFiltersControlId = 'DateFiltersNgx';
        this.m_dateFiltersResetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.state = {
            isLoading: false,
            isActiveOnly: false
        };
        this.startDate = moment().startOf('year').toDate();
        this.endDate = moment().endOf('year').toDate();
    }
    Object.defineProperty(ApprovalRequiredCodesComponent.prototype, "canApprove", {
        get: function () {
            return this.selectedToApprove && this.selectedToApprove.length > 0 &&
                _.every(this.selectedToApprove, function (fields) { return !fields.isLocked; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApprovalRequiredCodesComponent.prototype, "canUnapprove", {
        get: function () {
            return this.selectedToUnapprove && this.selectedToUnapprove.length > 0 &&
                _.every(this.selectedToUnapprove, function (fields) { return !fields.isLocked; });
        },
        enumerable: true,
        configurable: true
    });
    ApprovalRequiredCodesComponent.prototype.onSelectionChanged = function (items) {
        var _this = this;
        this.selectedToApprove = [];
        this.selectedToUnapprove = [];
        _.forEach(items, function (item) {
            var rec = new ApprovalRequiredCodeRecord();
            rec.payCodeId = item.fields.ApprovalExceptionId.value.value;
            rec.employeeId = item.fields.EmpId.value.value;
            rec.date = item.fields.ApprovalPunchDate.value.value;
            rec.isLocked = item.fields.IsPayCycleLocked.value.value === 'True' || item.fields.IsTimecardLocked.value.value === 'True';
            if (item.fields.IsCodeApproved.value.value === 'Yes') {
                _this.selectedToUnapprove.push(rec);
            }
            else {
                _this.selectedToApprove.push(rec);
            }
        });
    };
    ApprovalRequiredCodesComponent.prototype.approve = function () {
        var _this = this;
        this.state.isLoading = true;
        this.apiService.approve(this.selectedToApprove).then(function (result) {
            _this.state.isLoading = false;
            _this.loadValues();
        }).catch(function (error) {
            _this.state.isLoading = false;
        });
    };
    ApprovalRequiredCodesComponent.prototype.unapprove = function () {
        var _this = this;
        this.state.isLoading = true;
        this.apiService.unapprove(this.selectedToUnapprove).then(function (result) {
            _this.state.isLoading = false;
            _this.loadValues();
        }).catch(function (error) {
            _this.state.isLoading = false;
        });
    };
    ApprovalRequiredCodesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('ApprovalRequiredCodesComponent', false);
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.application, this.activatedRoute.queryParams)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1], queryParams = value[2];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentApplication = application;
            var startDate = queryParams['startDate'];
            var endDate = queryParams['endDate'];
            if (startDate && endDate) {
                _this.startDate = moment(startDate, appConfig.linkDateFormat).toDate();
                _this.endDate = moment(endDate, appConfig.linkDateFormat).toDate();
            }
            else {
                _this.restoreFilters();
            }
            _this.loadDefinitions();
        });
        this.loadSubscription = this.stateManagement.loadData$.subscribe(function () {
            _this.loadValues();
        });
        this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe(function (defs) {
            _this.stateManagement.onComponentActiveStateChanged({});
        });
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe(function (list) {
            _this.state.isLoading = false;
            _this.stateManagement.loadedData({});
        });
    };
    ApprovalRequiredCodesComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ApprovalRequiredCodesComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadValues();
        this.saveFilters();
    };
    ApprovalRequiredCodesComponent.prototype.loadDefinitions = function () {
        this.state.isLoading = true;
        this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.approvalRequiredCodes);
    };
    ApprovalRequiredCodesComponent.prototype.loadValues = function () {
        this.state.isLoading = true;
        this.selectedToApprove = [];
        this.selectedToUnapprove = [];
        var req = new GenericListRequest();
        req.startDate = this.startDate;
        req.endDate = this.endDate;
        this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
    };
    ApprovalRequiredCodesComponent.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId, {
            value: { startDate: this.startDate, endDate: this.endDate }
        }, this.m_dateFiltersResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
    };
    ApprovalRequiredCodesComponent.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
        if (state && state.value) {
            if (state.value.startDate)
                this.startDate = moment(state.value.startDate).toDate();
            if (state.value.endDate)
                this.endDate = moment(state.value.endDate).toDate();
        }
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], ApprovalRequiredCodesComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ApprovalRequiredCodesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApprovalRequiredCodesComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApprovalRequiredCodesComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApprovalRequiredCodesComponent.prototype, "valuesLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ApprovalRequiredCodesComponent.prototype, "loadSubscription", void 0);
    return ApprovalRequiredCodesComponent;
}());
export { ApprovalRequiredCodesComponent };
