import { IIndividualTimecardsDay, IndividualTimecardsDay, ITimecardFirstWeekTotals, TimecardFirstWeekTotals } from './index';
import {
  IPayRuleDefinition, PayRuleDefinition, ShiftDiffPolicy, IShiftDiffPolicy,
  IPayPolicy, PayPolicy,
  IPayCycle, PayCycle, IEmployeeDefinition, EmployeeDefinition, IPosition, Position, IEmployeePositionDefinition, EmployeePositionDefinition, ExemptStatus, IExemptStatus,
} from '../../../organization/models/index';

export interface IIndividualTimecardsContainer {
  employee: IEmployeeDefinition;
  primaryPosition: IPosition;
  payCycle: IPayCycle;
  timecards: IIndividualTimecardsDay[];
  rulesDefinitions: IPayRuleDefinition[];
  shiftDifferentialPolicy: IShiftDiffPolicy;
  payPolicy: IPayPolicy;
  exemptStatus: IExemptStatus;
  infoDisplay: string;
  approved: boolean;
  approvedBy: string;
  approvedOn: string;
  certified: boolean;
  certifiedOn: string;
  certifiedBy: string;
  canApprove: boolean;
  canUnapprove: boolean;
  hideRates: boolean;
  hasUnapprovedOvertime: boolean;
  canExportToExcel: boolean;
  canExportToPDF: boolean;
  payCycleStartDay: number;
  empOrganization: string;
  isPayCycleLocked: boolean;
  isOrganizationPayrollLocked: boolean;
  firstWeekTotals: ITimecardFirstWeekTotals;
  shiftDiffBasedOnTime: boolean;
  hideCostCenter: boolean;
  isPayRollVisible: boolean;
  payRollLink: string;
}

export class IndividualTimecardsContainer {
  public employee: EmployeeDefinition;
  public primaryPosition: Position;
  public payCycle: PayCycle;
  public records: IndividualTimecardsDay[];
  public rulesDefinitions: PayRuleDefinition[];
  public shiftDiffPolicy: ShiftDiffPolicy;
  public exemptStatus: IExemptStatus;
  public payPolicy: PayPolicy;
  public usedRulesDefinitions: PayRuleDefinition[];
  public infoDisplay: string;
  public approved: boolean;
  public approvedBy: string;
  public approvedOn: Date;
  public certified: boolean;
  public certifiedOn: Date;
  public certifiedBy: string;
  public canApprove: boolean;
  public canUnapprove: boolean;
  public hideRates: boolean;
  public hasErrors: boolean;
  public hasWarnings: boolean;
  public hasUnapprovedOvertime: boolean;
  public canExportToExcel: boolean;
  public canExportToPDF: boolean;
  public canRecalculate: boolean;
  public canViewSummary: boolean;
  public payCycleStartDay: number;
  public isPayCycleSubmitted: boolean;
  public isApprovablePayCycle: boolean;
  public canModifyOwnTimecard: boolean;
  public empOrganization: string;
  public isPayCycleLocked: boolean;
  public isOrganizationPayrollLocked: boolean;
  public firstWeekTotals: TimecardFirstWeekTotals;
  public shiftDiffBasedOnTime: boolean;
  public hideCostCenter: boolean;
  public isPayRollVisible: boolean;
  public payRollLink: string;
}
