import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, Provider } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { DialogOptions } from '../../../../common/index';
import { DialogOptions2 } from '../../../../common/models/dialog-options';
import { ModalService } from '../../../../common/services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { ColDef } from 'ag-grid-community';
import { PayCycle } from '../../../../organization/index';
import { DailyTimecardApiService } from '../../services/employee-ta-log/employee-ta-log-api.service';
import { EmployeeTAPunchLogRecord, EmployeeTAPayLogRecord } from '../../models/employee-ta-log';
import { PayCycleHelperService } from './../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';
var EmployeeTALogComponent = /** @class */ (function () {
    function EmployeeTALogComponent(datepipe, options, managementService, modalService, payCycleHelper) {
        this.datepipe = datepipe;
        this.payCycleHelper = payCycleHelper;
        this.isLoading = false;
        this.columnDefs = [
            { field: "PunchDate", headerName: "Date", cellStyle: { 'padding': "6px" }, width: 155 },
            { field: "PunchInTime", headerName: "In", cellStyle: { 'padding': "6px" }, width: 155 },
            { field: "PunchOutTime", headerName: "Out", cellStyle: { 'padding': "6px" }, width: 155 },
            { field: "Interval", headerName: "Interval", cellStyle: { 'padding': "6px" }, width: 155 },
        ];
        this.columnDefPayCode = [
            { field: "Code", headerName: "Code", cellStyle: { 'padding': "6px" }, width: 155 },
            { field: "Paid", headerName: "Paid", cellStyle: { 'padding': "6px" }, width: 155 },
            { field: "Interval", headerName: "Interval", cellStyle: { 'padding': "6px" }, width: 155 },
            { field: "Amount", headerName: "Amount", cellStyle: { 'padding': "6px" }, width: 155 },
        ];
        this.payCycle = new PayCycle();
        this.rowPayCodeData = [];
        this.rowPaySummary = [];
        this.rowHeight = 35;
        this.modalService = modalService;
        this.options = options;
        this.managementService = managementService;
    }
    EmployeeTALogComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getBatchId(EmployeeTALogComponent.employeeId)];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.findPaycycle()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.loadTimeCardDetails(EmployeeTALogComponent.employeeId, EmployeeTALogComponent.requestedDate)];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTALogComponent.prototype.loadTimeCardDetails = function (employeeId, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.managementService.getDailyTimecard(employeeId, date).then(function (res) {
                                if (res) {
                                    _this.payPolicy = res.payPolicy;
                                    _this.shiftDiffPolicy = res.shiftDifferentialPolicy;
                                    _this.exempt = res.exemptStatus;
                                    var paydata = void 0;
                                    _this.rowPaySummary = _.map(res.earningStatistics, function (sum) { return _this.mapPaySummary(sum); });
                                    _this.rowPayCodeData = _.map(res.payRules, function (rule) { return _this.mapPayRules(rule); });
                                    _this.rowData = [];
                                    res.dailyPunches.forEach(function (result) {
                                        var data;
                                        if (result.timeclock) {
                                            if (result.type.name.includes('in')) {
                                                _this.punchInTime = dateTimeUtils.convertFromDtoDateTimeString(result.time);
                                            }
                                            else {
                                                _this.punchOutTime = dateTimeUtils.convertFromDtoDateTimeString(result.time);
                                            }
                                            if (_this.punchOutTime) {
                                                data = new EmployeeTAPunchLogRecord();
                                                data.PunchDate = _this.datepipe.transform(result.punchDate, 'MM/dd/yyyy');
                                                if (_this.punchInTime) {
                                                    data.PunchInTime = _this.datepipe.transform(_this.punchInTime, 'h:mm a');
                                                }
                                                data.PunchOutTime = _this.datepipe.transform(_this.punchOutTime, 'h:mm a');
                                                var interval = Math.round((((_this.punchOutTime - _this.punchInTime) / (1000 * 60 * 60) % 24) * 100)) / 100;
                                                data.Interval = Number.isNaN(interval) ? '0.00' : (Math.round(interval * 4) / 4).toFixed(2);
                                                _this.rowData.push(data);
                                                _this.punchInTime = undefined;
                                                _this.punchOutTime = undefined;
                                            }
                                        }
                                    });
                                }
                                _this.isLoading = false;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTALogComponent.prototype.mapPayRules = function (record) {
        var paydata = new EmployeeTAPayLogRecord();
        paydata.Code = record.ruleDefinition.description;
        paydata.Paid = this.rowPaySummary.includes(record.ruleDefinition.description) ? 'Y' : 'N';
        paydata.Amount = record.moneyAmount;
        var start = dateTimeUtils.convertFromDtoDateTimeString(record.start);
        var end = dateTimeUtils.convertFromDtoDateTimeString(record.end);
        paydata.Interval = (Math.round((dateTimeUtils.convertFromDtoDurationString(record.interval).asHours()) * 100) / 100).toFixed(2);
        return paydata;
    };
    EmployeeTALogComponent.prototype.mapPaySummary = function (record) {
        return record.codeName;
    };
    EmployeeTALogComponent.prototype.findPaycycle = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.currentPaycycle = null;
                        return [4 /*yield*/, this.payCycleHelper.getPayCycleByDate(EmployeeTALogComponent.requestedDate, EmployeeTALogComponent.orgLevelId, +EmployeeTALogComponent.employeeId)
                                .then(function (cycle) {
                                if (cycle) {
                                    _this.currentPaycycle = cycle;
                                    _this.payPeriod = _this.datepipe.transform(cycle.startDate, 'MM/dd/yyyy') + "-" + _this.datepipe.transform(cycle.endDate, 'MM/dd/yyyy');
                                    _this.isLoading = false;
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTALogComponent.prototype.getBatchId = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.badge = null;
                        return [4 /*yield*/, this.managementService.getBatchId(employeeId)
                                .then(function (badgeId) {
                                _this.badge = badgeId;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTALogComponent.openDialog = function (modalService, employeeId, requestDate, orgLevelId) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.height = 640;
        dialogOptions.width = 700;
        //dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.minHegiht = 640;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions,
            }
        ];
        EmployeeTALogComponent.requestedDate = requestDate;
        EmployeeTALogComponent.employeeId = employeeId;
        EmployeeTALogComponent.orgLevelId = orgLevelId;
        var header = 'TA Log';
        modalService.globalAnchor.openDialog2(EmployeeTALogComponent, header, dialogOptions, resolvedProviders, function (result, uniqueId) { });
        return null;
    };
    EmployeeTALogComponent.prototype.onClose = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeTALogComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        //console.info('Destroy Add Employee popup');
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeTALogComponent.prototype, "userSubscription", void 0);
    return EmployeeTALogComponent;
}());
export { EmployeeTALogComponent };
