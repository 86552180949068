<div class="dates-tab">
  <p *ngIf="hasExceptions" class="dates-tab__error">
    <span class="slx-error-block">One or more exceptions exist for this LOA request. You must delete those exceptions before making changes to LOA dates.</span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Est. Start Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        name="startDate"
        [(ngModel)]="estStartDate"
        (ngModelChange)="onChangeEstStartDate($event)"
        [maxDateTime]="estEndDate"
        [readonly]="!canEditRequest || hasExceptions"
        [readonlyOnlyTime]="estAllDay"
        [readonlyTimeWithoutDate]="true"
        [required]="true" #estStartDateModel="ngModel"
      ></slx-datetimepicker>
      <slx-checkbox-button class="dates-tab__checkbox"
        [(ngModel)]="estAllDay"
        (ngModelChange)="onChangeEstAllDay()"
        [readonly]="!canEditRequest || hasExceptions || !hasEstimatedDates"
        placeholder="All Day"
        name="estAllDay"
      ></slx-checkbox-button>
    </span>
  </p>
  <p class="date_error_message">
    <span *ngIf="estStartDateModel.errors" class="slx-error-block error-box">
      <span *ngIf="estStartDateModel.errors.required" errorMessage for="required"></span>
      <span *ngIf="estStartDateModel.errors.date" errorMessage>Date is out of valid range</span>
      <span *ngIf="estStartDateModel.errors.maxDateTime" errorMessage>Cannot be greater than {{ estEndDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
    </span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Est. End Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        name="endDate"
        [(ngModel)]="estEndDate"
        (ngModelChange)="onChangeEstEndDate($event)"
        [minDateTime]="estStartDate"
        [readonly]="!canEditRequest || hasExceptions"
        [readonlyOnlyTime]="estAllDay"
        [readonlyTimeWithoutDate]="true"
        [required]="true" #estEndDateModel="ngModel"
      ></slx-datetimepicker>
      <span class="dates-tab__checkbox"></span>
    </span>
  </p>
  <div class="flex align-items__center dates-tab__ctrl margin-b">
    <p class="date_error_message">
        <span *ngIf="estEndDateModel.errors" class="slx-error-block error-box">
          <span *ngIf="estEndDateModel.errors.required" errorMessage for="required"></span>
          <span *ngIf="estEndDateModel.errors.date" errorMessage>Date is out of valid range</span>
          <span *ngIf="estEndDateModel.errors.minDateTime" errorMessage>Cannot be less than {{ estStartDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
        </span>
      </p>
  </div>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Actual Start Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        [(ngModel)]="actStartDate"
        (ngModelChange)="onChangeActStartDate($event)"
        [maxDateTime]="actEndDate"
        [readonly]="!canEditRequest || hasExceptions || !hasEstimatedDates"
        [readonlyOnlyTime]="actAllDay"
        [readonlyTimeWithoutDate]="true"
        #actStartDateModel="ngModel"
      ></slx-datetimepicker>
      <slx-checkbox-button
        class="dates-tab__checkbox"
        [(ngModel)]="actAllDay"
        (ngModelChange)="onChangeActAllDay()"
        [readonly]="!canEditRequest || hasExceptions || !hasActualDates || !hasEstimatedDates"
        name="actAllDay"
        placeholder="All Day"
      ></slx-checkbox-button>
    </span>
  </p>
  <p class="date_error_message">
    <span *ngIf="actStartDateModel.errors" class="slx-error-block error-box">
      <span *ngIf="actStartDateModel.errors.date" errorMessage>Date is out of valid range</span>
      <span *ngIf="actStartDateModel.errors.maxDateTime" errorMessage>Cannot be greater than {{ actEndDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
    </span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label">Actual End Date</label>
    <span class="flex align-items__center flex__grow">
      <slx-datetimepicker
        [(ngModel)]="actEndDate"
        (ngModelChange)="onChangeActEndDate($event)"
        [minDateTime]="actStartDate"
        [readonly]="!canEditRequest || hasExceptions || !hasEstimatedDates"
        [readonlyOnlyTime]="actAllDay"
        [readonlyTimeWithoutDate]="true"
        #actEndDateModel="ngModel"
      ></slx-datetimepicker>
      <span class="dates-tab__checkbox"></span>
    </span>
  </p>
  <p class="date_error_message" *ngIf="actEndDateModel.errors">
    <span class="slx-error-block error-box" *ngIf="actEndDateModel.errors.date" errorMessage>Date is out of valid range</span>
    <span *ngIf="actEndDateModel.errors.minDateTime" errorMessage>Cannot be less than {{ estStartDate | amDateFormat: appConfig.dateTimeFormatUS  }}</span>
  </p>
  <p class="flex align-items__center dates-tab__ctrl">
    <label class="dates-tab__label"></label>
    <span class="flex align-items__center flex__grow">
      <span class="slx-error-block" *ngIf="hasIncorrectContinuousDates">Start and End date shouldn't be equal and End date have to be greater than Start date</span>
    </span>
  </p>
</div>
