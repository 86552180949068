import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupSmsComponent } from './../../../../../employee/employee-list/components/group-sms/group-sms.component';
import { EmployeeCallLogNavigationService } from './../../../../../common/services/navigation/employee-call-log-navigation.service';
import { unsubscribe } from '../../../../../core/decorators/index';
import { EmployeeCallLogManagementService } from '../../../services/index';
import { ModalService } from './../../../../../common/services/modal/modal.service';
import { TimeclockCommunicationComponent } from './../../timeclock-communication/timeclock-communication.component';
import { IRangeDates } from '../../../../../common/models/range-dates';
import { LookupService } from '../../../../../organization/services/index';
import { LookupType } from '../../../../../organization/models/index';
import { mutableSelect } from '../../../../../core/decorators/index';
import { StateManagementService } from '../../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../../common/services/component-state/component-state-storage.service';
import { StateResetTypes } from '../../../../../core/models/settings/index';
import { scheduleMicrotask } from '../../../../../core/utils/index';
var EmployeeCallLogsComponent = /** @class */ (function () {
    function EmployeeCallLogsComponent(managementService, modalService, activatedRoute, router, stateManagement, storageService, lookupService) {
        this.managementService = managementService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.lookupService = lookupService;
        this.m_dateFiltersControlId = 'DateFiltersNgx';
        this.m_dateFiltersResetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.m_employeeFilterControlId = 'EmployeeFiltersNgx';
        this.m_employeeFiltersResetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.state = {
            isLoading: false,
            startDate: moment().subtract(1, 'week').toDate(),
            endDate: moment().toDate(),
            isLeftSidebarOpen: false,
        };
        this.pageSize = 20;
        this.employeeLink = true;
        this.employeesIds = [];
        this.navigateService = new EmployeeCallLogNavigationService(this.router, this.activatedRoute);
    }
    EmployeeCallLogsComponent.prototype.getScreenSize = function (event) {
        this.screenWidth = window.innerWidth;
    };
    EmployeeCallLogsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.screenWidth = window.innerWidth;
        this.isDesktop();
        this.stateManagement.init('EmployeeCallLogsComponent', false);
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevel = orgLevel;
            _this.updateList();
            _this.managementService.freeze = true;
            _this.managementService.orgLevelChanged(_this.orgLevel);
        });
        this.appSubscription = this.application$.subscribe(function (app) {
            _this.managementService.applicationId = app.id;
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadSubscription = this.stateManagement.loadData$.subscribe(function () {
            _this.managementService.dateRangeChanged(_this.state.startDate, _this.state.endDate);
        });
        this.managementService.smsDataLoaded$.subscribe(function (value) {
            _this.showDialog2(value);
        });
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (callLog) {
            var sendSmsItem = _.find(_this.actions, function (a) { return a.type === 'send-sms'; });
            if (sendSmsItem) {
                if (!callLog.canSendSms)
                    _this.actions = _this.actions.filter(function (action) { return action.type != 'send-sms'; });
                else
                    sendSmsItem.enabled = callLog.canSendSms;
            }
            var logPhoneCallItem = _.find(_this.actions, function (a) { return a.type === 'log-phone-call'; });
            if (logPhoneCallItem)
                logPhoneCallItem.enabled = callLog.canLogPhoneCall;
            var sendClockMessageItem = _.find(_this.actions, function (a) { return a.type === 'send-clock-message'; });
            if (sendClockMessageItem)
                sendClockMessageItem.enabled = callLog.canSendToTimeClock;
        });
        this.actions = [
            { type: 'send-sms', displayName: 'Send SMS' },
            { type: 'log-phone-call', displayName: 'Log Phone Call', winClass: '' },
            { type: 'send-clock-message', displayName: 'Send Clock Message', winClass: TimeclockCommunicationComponent }
            // { type: 'send-email', displayName: 'Send Email', winClass: '' },
            // { type: 'conversation-note', displayName: 'Conversation Note', winClass: '' }
        ];
        this.messageCenterEnabledSubscription = this.managementService.messageCenterEnabled$.subscribe(function () {
            _this.managementService.dateRangeChanged(_this.state.startDate, _this.state.endDate);
        });
        this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe(function (value) {
            scheduleMicrotask(function () { _this.state.isLeftSidebarOpen = value; });
        });
    };
    EmployeeCallLogsComponent.prototype.ngOnDestroy = function () { };
    EmployeeCallLogsComponent.prototype.onDataRangeChange = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.state.startDate = startDate;
        this.state.endDate = endDate;
        this.saveDateFiltersStates();
    };
    EmployeeCallLogsComponent.prototype.showWindow = function (action) {
        switch (action.type) {
            case 'send-clock-message':
                this.navigateService.navigateToSendClockMessage();
                break;
            case 'log-phone-call':
                this.navigateService.navigateToLogPhoneCall();
                break;
            case 'send-sms':
                this.managementService.loadSmsData();
                break;
            default:
                this.modalService.globalAnchor.openInfoDialog('Warning', action.displayName + ' hasn\'t implemented yet');
        }
    };
    EmployeeCallLogsComponent.prototype.onEmployeesChange = function (employeesIds) {
        var _a;
        this.employeesIds.length = 0;
        if (_.isArray(employeesIds)) {
            (_a = this.employeesIds).push.apply(_a, employeesIds);
        }
        this.saveEmployeeFilterStates(this.employeesIds);
        this.managementService.employeesChanged(this.employeesIds);
    };
    EmployeeCallLogsComponent.prototype.showDialog2 = function (actionDefinition) {
        GroupSmsComponent.openDialog(this.modalService, this.managementService.getSmsWindowProviders(actionDefinition), function (result) {
            if (result) {
            }
        });
    };
    EmployeeCallLogsComponent.prototype.saveDateFiltersStates = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId, {
            value: { startDate: this.state.startDate, endDate: this.state.endDate }
        }, this.m_dateFiltersResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
    };
    EmployeeCallLogsComponent.prototype.saveEmployeeFilterStates = function (employeesIds) {
        if (!_.isArray(employeesIds)) {
            employeesIds = [];
        }
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_employeeFilterControlId + this.orgLevel.id, {
            value: { employeesIds: employeesIds.join(',') }
        }, this.m_employeeFiltersResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_employeeFilterControlId);
    };
    EmployeeCallLogsComponent.prototype.restoreStates = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
        if (state && state.value) {
            if (state.value.startDate)
                this.state.startDate = moment(state.value.startDate).toDate();
            if (state.value.endDate)
                this.state.endDate = moment(state.value.endDate).toDate();
            this.managementService.dateRangeChanged(this.state.startDate, this.state.endDate);
        }
        state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_employeeFilterControlId + this.orgLevel.id);
        this.employeesIds = [];
        if (state && state.value) {
            if (state.value.employeesIds && state.value.employeesIds.length) {
                var ids = state.value.employeesIds.split(',');
                this.employeesIds = _.map(ids, function (id) { return +id; });
            }
        }
        this.updateSelectedUser();
        this.managementService.freeze = false;
        this.managementService.employeesChanged(this.employeesIds);
    };
    EmployeeCallLogsComponent.prototype.updateList = function () {
        var _this = this;
        if (!this.lookupService)
            return;
        this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id, isActive: false })
            .then(function (lookup) {
            _this.employees = lookup.items;
            _this.restoreStates();
        }).catch(function () {
            _this.employees = [];
        });
    };
    EmployeeCallLogsComponent.prototype.updateSelectedUser = function () {
        var _this = this;
        if (this.employees && this.employeesIds) {
            this.empFilter = [];
            var _loop_1 = function (selectedEmpId) {
                var selectedEmployee = _.filter(this_1.employees, function (emp) {
                    return emp.masterId === selectedEmpId;
                });
                if (selectedEmployee && selectedEmployee.length > 0) {
                    _.each(selectedEmployee, function (emp) {
                        _this.empFilter.push(emp.masterId);
                    });
                }
            };
            var this_1 = this;
            for (var _i = 0, _a = this.employeesIds; _i < _a.length; _i++) {
                var selectedEmpId = _a[_i];
                _loop_1(selectedEmpId);
            }
        }
    };
    EmployeeCallLogsComponent.prototype.isDesktop = function () {
        return this.screenWidth > 1023 ? true : false;
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeCallLogsComponent.prototype, "application$", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeCallLogsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeCallLogsComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogsComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogsComponent.prototype, "appSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogsComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogsComponent.prototype, "loadSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogsComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogsComponent.prototype, "messageCenterEnabledSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogsComponent.prototype, "isLeftSidebarOpenSubscripion", void 0);
    return EmployeeCallLogsComponent;
}());
export { EmployeeCallLogsComponent };
