import { IBenefitDetailsPayrollGroup, BenefitDetailsPayrollGroup} from './benefit-details-payroll-group';
import { IBenefitDetailsPayrollDeductions, BenefitDetailsPayrollDeductions} from './benefit-details-payroll-deductions';

export interface IBenefitPayrollMapping {
  benefitGroups: IBenefitDetailsPayrollGroup[];
  payrollDeductions: IBenefitDetailsPayrollDeductions[];
  isPayrolSyncEnabled: boolean;
}

export class BenefitPayrollMapping {
  public benefitGroups: BenefitDetailsPayrollGroup[] = [];
  public payrollDeductions: BenefitDetailsPayrollDeductions[] = [];
  public isPayrolSyncEnabled: boolean;
}
