import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { ApplicationStateBusService } from '../../services/index';
import { OrgLevelBreadcrumbModes } from '../../models/index';
import { Assert } from '../../../framework/index';
var OrgLevelBreadcrumbComponent = /** @class */ (function () {
    function OrgLevelBreadcrumbComponent(orgLevelWatchService, applicationStateBusService, elementRef) {
        this.orgLevelWatchService = orgLevelWatchService;
        this.applicationStateBusService = applicationStateBusService;
        this.elementRef = elementRef;
        this.showOrgTree = false;
        this.posTop = 0;
        this.posLeft = 0;
        this.iconShift = 0;
        this.currentPath = [];
        this.canChangeOrgLevel = true;
        this.userCannotChangeOrglevelMessage = 'You cannot change organization level on this page';
    }
    OrgLevelBreadcrumbComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.applicationStateBusService.subscribeToSelectOrgLevel(function (o) {
            if (_.isObject(o) && _.isNumber(o.id) && (!_this.orgLevel || _this.orgLevel.id !== o.id)) {
                _this.orgLevel = o;
                _this.getPath();
            }
        });
        this.orgTreeLoadedSubscription = this.applicationStateBusService.subscribeToOrgLevelsLoaded(function () {
            _this.getPath();
        });
        this.routeInfoSubscription = this.applicationStateBusService.subscribeToRouteInfo(function (data) {
            if (_.isObject(data)) {
                var canChange = !data.orgLevelChangeDisabled;
                if (_this.canChangeOrgLevel !== canChange) {
                    _this.canChangeOrgLevel = canChange;
                }
                return;
            }
            _this.canChangeOrgLevel = true;
        });
    };
    OrgLevelBreadcrumbComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    OrgLevelBreadcrumbComponent.prototype.pressEscape = function (event) {
        if (this.showOrgTree && event.keyCode === 27) {
            this.showOrgTree = false;
        }
    };
    OrgLevelBreadcrumbComponent.prototype.clickOutside = function (event) {
        var elem = this.elementRef.nativeElement;
        if (elem && this.showOrgTree) {
            if (!elem.contains(event.target)) {
                this.showOrgTree = false;
            }
        }
    };
    OrgLevelBreadcrumbComponent.prototype.onToggleOrgTree = function (event, fromNavMenu) {
        if (this.canChangeOrgLevel) {
            this.showOrgTree = !this.showOrgTree;
            if (this.showOrgTree) {
                var breadcrumbElem = event.currentTarget;
                var iconElem = breadcrumbElem.querySelector('.js-icon');
                this.refreshPosition(breadcrumbElem, iconElem);
            }
        }
    };
    OrgLevelBreadcrumbComponent.prototype.refreshPosition = function (breadcrumbElem, iconElem) {
        Assert.isNotNull(breadcrumbElem, 'breadcrumbElem');
        Assert.isNotNull(iconElem, 'iconElem');
        var posLeft = 0;
        var posTop = 0;
        var minIndentFromBorder = 0;
        var horizontalShift = 25;
        var verticalShift = 7;
        var iconStyles = window.getComputedStyle(iconElem);
        var iconPosCenter = _.isNaN(parseInt(iconStyles.width)) ? 0 : parseInt(iconStyles.width) / 2;
        var treeStyles = window.getComputedStyle(this.orgTree.nativeElement);
        var treeWidth = _.isNaN(parseInt(treeStyles.width)) ? 0 : parseInt(treeStyles.width);
        var rect = breadcrumbElem.getBoundingClientRect();
        var iconPosLeft = rect.left + iconPosCenter;
        posTop = rect.top + rect.height + verticalShift;
        posLeft = iconPosLeft - horizontalShift;
        if (posLeft < 0) {
            posLeft = minIndentFromBorder;
        }
        if (posLeft + treeWidth >= window.innerWidth) {
            posLeft = window.innerWidth - treeWidth - minIndentFromBorder;
        }
        this.posTop = posTop;
        this.posLeft = posLeft;
        this.iconShift = Math.abs(posLeft - iconPosLeft);
    };
    OrgLevelBreadcrumbComponent.prototype.getStateIconClass = function () {
        return this.showOrgTree ? 'fa-chevron-up' : 'fa-chevron-down';
    };
    OrgLevelBreadcrumbComponent.prototype.isMenuMode = function () {
        return this.mode === OrgLevelBreadcrumbModes.menu;
    };
    OrgLevelBreadcrumbComponent.prototype.getCorpName = function (withAlternative) {
        var name = '';
        var corpOrgLevel = _.head(this.currentPath);
        if (corpOrgLevel) {
            name = corpOrgLevel.name;
        }
        if (withAlternative) {
            return this.canChangeOrgLevel ? name : this.userCannotChangeOrglevelMessage;
        }
        return name;
    };
    OrgLevelBreadcrumbComponent.prototype.getLastOrgName = function (withAlternative) {
        var name = '';
        var lastOrgLevel = _.last(this.currentPath);
        if (lastOrgLevel) {
            name = lastOrgLevel.name;
        }
        if (withAlternative) {
            return this.canChangeOrgLevel ? name : this.userCannotChangeOrglevelMessage;
        }
        return name;
    };
    OrgLevelBreadcrumbComponent.prototype.getPath = function () {
        if (!this.orgLevel)
            return;
        var path = this.orgLevelWatchService.getOrgLevelPath(this.orgLevel.id);
        if (path.length > 2) {
            path = path.slice(-2);
        }
        this.currentPath = path;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrgLevelBreadcrumbComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrgLevelBreadcrumbComponent.prototype, "orgTreeLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrgLevelBreadcrumbComponent.prototype, "routeInfoSubscription", void 0);
    return OrgLevelBreadcrumbComponent;
}());
export { OrgLevelBreadcrumbComponent };
