import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { ISchedulePosition } from './ischedule-position';

export function isMasterScheduleEntryCell(value: any): value is IMasterScheduleEntryCell {
  if (!value) {
    return false;
  }
  return !!value.dateOn && !!value.shiftsInfo;
}

export interface IMasterScheduleEntryCell {
  dateOn: Date;
  shiftsInfo: IMasterScheduleShiftInfo[];
  isScheduledToSecondaryPosition: boolean;
  isScheduledToMultipleShifts: boolean;
  hasConstraint: boolean;
  isPosted: boolean;
  isReplacing: boolean;
  isReplaced: boolean;
  isPreScheduledOvertime: boolean;
  isOvertimeApproach: boolean;
  scheduledInDifferntDepartment: boolean;
  holidayDayCode: string;
  isAgency: boolean;
  hasAbsence: boolean;
  hasPartialAbsence: boolean;
  scheduledInDifferentShiftGroup: boolean;
}

export class MasterScheduleEntryCell implements IMasterScheduleEntryCell {
  public dateOn: Date;
  public shiftsInfo: MasterScheduleShiftInfo[];
  public isScheduledToSecondaryPosition: boolean;
  public isScheduledToMultipleShifts: boolean;
  public hasConstraint: boolean;
  public isPosted: boolean;
  public isReplacing: boolean;
  public isReplaced: boolean;
  public isPreScheduledOvertime: boolean;
  public isOvertimeApproach: boolean;
  public scheduledInDifferntDepartment: boolean;
  public holidayDayCode: string;
  public isAgency: boolean;
  public hasAbsence: boolean;
  public hasPartialAbsence: boolean;
  public isInQuickEdit: boolean;
  public scheduledInDifferentShiftGroup: boolean;

  public get unitName(): string {
    if (this.m_unitName === undefined) {
      this.m_unitName = _.join(_.map(this.shiftsInfo, (info: MasterScheduleShiftInfo) => {
        if (info.absenceCode) {
          return info.absenceCode;
        }
        if (info.unitName) {
          return info.unitName;
        }
        return '-';
      }), ',');
    }
    return this.m_unitName;
  }
  public get shiftName(): string {
    if (this.m_shiftName === undefined) {
      this.m_shiftName = _.join(_.map(this.shiftsInfo, (info: MasterScheduleShiftInfo) => {
        if (info.absenceCode) {
          return info.absenceCode;
        }
        return info.shiftName;
      }), ',');
    }
    return this.m_shiftName;
  }
  public get shiftTimes(): string {
    if (this.m_shiftTimes === undefined) {
      this.m_shiftTimes = _.join(_.map(this.shiftsInfo, (info: MasterScheduleShiftInfo) => {
        if (info.absenceCode) {
          return info.absenceCode;
        }
        return `${moment(info.shiftStart).format(appConfig.linkTimeFormat)}-${moment(info.shiftEnd).format(appConfig.linkTimeFormat)}`;
      }), ',');
    }
    return this.m_shiftTimes;
  }
  public get shiftNameAndUnit(): string {
    if (this.m_shiftNameAndUnit === undefined) {
      this.m_shiftNameAndUnit = _.join(_.map(this.shiftsInfo, (info: MasterScheduleShiftInfo) => {
        if (info.absenceCode) {
          return info.absenceCode;
        }
        return `${info.shiftName}|${info.unitName}`;
      }), ',');
    }
    return this.m_shiftNameAndUnit;
  }

  public get shiftDurationHours(): string {
    if (this.m_shiftDurationHours === undefined) {
      let val: number = _.reduce(this.shiftsInfo, (acc: number, info: MasterScheduleShiftInfo) => {
        return acc + info.duration / 3600000;
      }, 0);
      this.m_shiftDurationHours = val.toFixed(2);
    }
    return this.m_shiftDurationHours;
  }
  public get shiftDurationMin(): string {
    if (this.m_shiftDurationMin === undefined) {
      let val: number = _.reduce(this.shiftsInfo, (acc: number, info: MasterScheduleShiftInfo) => {
        return acc + info.duration / 60000;
      }, 0);
      this.m_shiftDurationMin = val.toFixed(1);
    }
    return this.m_shiftDurationMin;
  }

  private m_unitName: string;
  private m_shiftName: string;
  private m_shiftTimes: string;
  private m_shiftNameAndUnit: string;
  private m_shiftDurationHours: string;
  private m_shiftDurationMin: string;

  public clearNames(): void {
    this.m_unitName = undefined;
    this.m_shiftName = undefined;
    this.m_shiftTimes = undefined;
    this.m_shiftNameAndUnit = undefined;
    this.m_shiftDurationHours = undefined;
    this.m_shiftDurationMin = undefined;
  }
}

export interface IMasterScheduleShiftInfo {
  empoyeeId: number;
  unitId: number;
  unitName: string;
  shiftId: number;
  shiftName: string;
  shiftDefStart: Date;
  shiftDefEnd: Date;
  shiftStart: Date;
  shiftEnd: Date;
  position: ISchedulePosition;
  absenceCode: string;
  absenceName: string;
  partialAbsenceCode: string;
  partialAbsenceName: string;
  partialAbsenceStart: Date;
  partialAbsenceEnd: Date;
  partialAbsenceDuration: number;
  constraintId: number;
  constraintCode: string;
  constraintCountAs: number;
  departmentId: number;
  departmentName: string;
  departmentOrgLevelId: number;
  duration: number;
  prescheduledOvertimeDuration: number;
  prescheduledOvertimeStart: string;
  prescheduledOvertimeEnd: string;
}

export class MasterScheduleShiftInfo implements IMasterScheduleShiftInfo {
  public empoyeeId: number;
  public unitId: number;
  public unitName: string;
  public shiftId: number;
  public shiftName: string;
  public shiftDefStart: Date;
  public shiftDefEnd: Date;
  public shiftStart: Date;
  public shiftEnd: Date;
  public position: ISchedulePosition;
  public absenceCode: string;
  public absenceName: string;
  public partialAbsenceCode: string;
  public partialAbsenceName: string;
  public partialAbsenceStart: Date;
  public partialAbsenceEnd: Date;
  public partialAbsenceDuration: number;
  public constraintId: number;
  public constraintCode: string;
  public constraintCountAs: number;
  public departmentId: number;
  public departmentName: string;
  public departmentOrgLevelId: number;
  public duration: number;
  public prescheduledOvertimeDuration: number;
  public prescheduledOvertimeStart: string;
  public prescheduledOvertimeEnd: string;
  public lunchDuration: number;
}

export function isMasterScheduleTotalCell(value: any): value is IMasterScheduleEntryCell {
  if (!value) {
    return false;
  }
  return !!value.dateOn && _.isNumber(value.totalValue);
}

export interface IMasterScheduleTotalCell {
  dateOn: Date;
  totalValue: number;
  fteTotalValue: number;
  isViewFteTotal: boolean;
}

export class MasterScheduleTotalCell {
  public dateOn: Date;
  public totalValue: number;
  public fteTotalValue: number;
  public isViewFteTotal: boolean;

  public get value(): string {
    if (this.isViewFteTotal) {
      return this.fteTotalValue.toFixed(2);
    } else {
      return this.totalValue.toFixed(2);
    }
  }

}
