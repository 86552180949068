import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { dateTimeUtils } from '../../../../common/utils/index';
import { MetaMapService } from '../../../../core/services/index';
import { FieldsMeta, IFieldData, FieldAccessType } from '../../../../core/models/index';

import {
  Employee,
  IEmployee,
} from '../../../../organization/models/index';

import { LookupMapService } from '../../../../organization/services/index';
import { WCReportMapService } from '../../services/crud/wc-report-map.service';

import {
  WcIncidentRosterEntry,
  IWcIncidentRosterEntry,
  IWcCodeEntry,
  WcCodeEntry,
  IWcIncidentRosterContainer,
  WcIncidentRosterContainer,
  IWcComparisonsContainer,
  WcComparisonsContainer,
  IWcComparisonsEntry,
  WcComparisonsEntry,
  ICreateOsha300AFormDialogOptions,
  CreateOsha300AFormDialogOptions
} from '../../models/index';
import { IWcGenerateOsha300APDF, WcGenerateOsha300APDF } from '../../models/wc-generate-osha-pdf-300A';
import { IWcGenerateOsha301APDF, WcGenerateOsha301APDF } from '../../models/wc-generate-osha-pdf-301';

@Injectable()
export class WcRosterMapService {
  constructor(
    private lookupMapService: LookupMapService,
    private reportMapService: WCReportMapService,
    private metaMapService: MetaMapService
  ) { }

  public mapInsidentRosterContainer(dtos: IWcIncidentRosterContainer, meta: FieldsMeta = null): WcIncidentRosterContainer {
    const data = new WcIncidentRosterContainer();
    data.canCreate = data.canEdit = dtos.canCreateEdit;
    data.canDelete = dtos.canDelete;
    data.records = _.map(dtos.records, (dto: IWcIncidentRosterEntry) => this.mapInsidentRosterEntry(dto, meta));
    return data;
  }

  public mapComparisonsContainer(dtos: IWcComparisonsContainer): WcComparisonsContainer {
    const data = new WcComparisonsContainer();
    data.canEdit = dtos.canEdit;
    data.records = _.map(dtos.records, (dto: IWcComparisonsEntry) => this.mapComparisonsEntry(dto));
    return data;
  }

  public mapComparisonsEntry(dto: IWcComparisonsEntry): WcComparisonsEntry {
    const entry = new WcComparisonsEntry();
    entry.organization = dto.organization;
    entry.months = dto.months;
    entry.total = dto.total;
    return entry;
  }

  public mapInsidentRosterEntry(dto: IWcIncidentRosterEntry, meta: FieldsMeta): WcIncidentRosterEntry {
    const entry = new WcIncidentRosterEntry();
    entry.id = dto.id;
    entry.employee = this.mapEmployee(dto.employee);
    entry.organization = _.isObjectLike(dto.organization) ? this.lookupMapService.mapOrganization(dto.organization) : null;
    entry.department = _.isObjectLike(dto.department) ? this.lookupMapService.mapDepartment(dto.department) : null;
    entry.position = _.isObjectLike(dto.position) ? this.lookupMapService.mapPosition(dto.position) : null;
    entry.incidentDateTime = dateTimeUtils.convertFromDtoDateTimeString(dto.incidentDateTime);
    entry.injuryType = this.mapCodeEntry(dto.injuryType);
    entry.oshaInjuryType = this.mapCodeEntry(dto.oshaInjuryType);
    entry.reportDateTime = dto.reportDateTime ? dateTimeUtils.convertFromDtoDateTimeString(dto.reportDateTime) : null;
    entry.bodyPart = this.mapCodeEntry(dto.bodyPart);
    entry.accidentCause = this.mapCodeEntry(dto.accidentCause);
    entry.injuryOccured = this.mapCodeEntry(dto.injuryOccured);
    entry.injuryLocation = this.mapCodeEntry(dto.injuryLocation);
    entry.reportStatus = this.reportMapService.mapToReportStatus(dto.reportStatus);
    entry.medicalTreatment = this.reportMapService.mapToMedicalTreatment(dto.medicalTreatment);

    entry.dateLoseBegan = dateTimeUtils.convertFromDtoDateString(dto.dateLoseBegan);
    entry.dateLoseEnd = dateTimeUtils.convertFromDtoDateString(dto.dateLoseEnd);
    entry.daysLost = dto.daysLost;

    entry.oshaDateLoseBegan = dateTimeUtils.convertFromDtoDateString(dto.oshaDateLoseBegan);
    entry.oshaDateLoseEnd = dateTimeUtils.convertFromDtoDateString(dto.oshaDateLoseEnd);
    entry.oshaDaysLost = dto.oshaDaysLost;

    entry.primaryCause = dto.primaryCause;
    entry.howInjuryOccur = dto.howInjuryOccur;

    entry.isOSHARecordable = dto.isOSHARecordable;
    entry.isWorkersCompCase = dto.isWorkersCompCase;

    entry.restrictedDutyDaysNum = dto.restrictedDutyDaysNum;
    entry.lossConsciousness = dto.lossConsciousness;
    entry.carrierName = dto.carrierName;
    entry.whatDoingDuringInjury = dto.whatDoingDuringInjury;
    entry.usedProperSafety = dto.usedProperSafety;
    entry.hasPriorInjury = dto.hasPriorInjury;
    entry.exposedFluidToAnother = dto.exposedFluidToAnother;
    entry.isPrivacyCase = dto.isPrivacyCase;
    entry.dateHired = dateTimeUtils.convertFromDtoDateString(dto.dateHired);
    entry.dateOfBirth = dateTimeUtils.convertFromDtoDateString(dto.dateOfBirth);
    entry.empType = dto.empType;
    entry.oshaCaseNumber = dto.oshaCaseNumber;

    entry.addedBy = dto.addedBy;
    entry.updatedBy = dto.updatedBy;

    entry.medicalCost = dto.medicalCost;
    entry.indemnityCost = dto.indemnityCost;

    if (meta) {
      let actions = this.metaMapService.mapActions(meta);
      let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
      const age = this.metaMapService.mapMeta<number>(metaMap, 'age', dto.age);
      entry.age = age.fieldValue;

      entry.canEdit = actions.canEdit;
      entry.canDelete = actions.canDelete;

      entry.metaMap = metaMap;
    } else {
      entry.age = dto.age;
    }

    return entry;
  }

  private mapCodeEntry(dto: IWcCodeEntry): WcCodeEntry {
    const code = new WcCodeEntry();
    code.id = _.get(dto, 'id') || null;
    code.orgLevelId = _.get(dto, 'orgLevelId') || null;
    code.code = _.get(dto, 'code') || '';
    code.codeType = _.get(dto, 'codeType') || '';
    code.description = _.get(dto, 'description') || '';

    return code;
  }

  private mapEmployee(dto: IEmployee): Employee {
    const emp = new Employee();
    emp.id = _.get(dto, 'id') || null;
    emp.name = _.get(dto, 'name') || '';

    return emp;
  }

  public mapGenerateOshaPDF300TypeModelToDto(data: IWcGenerateOsha300APDF): WcGenerateOsha300APDF {
    if (_.isObjectLike(data)) {
      return {
        orgLevelId: data.orgLevelId,
        formName: data.formName,
        startDate: data.startDate,
        endDate: data.endDate,
        recordIds: data.recordIds
      };
    }
    return null;
  }

  public mapGenerateOshaPDF301TypeModelToDto(data: IWcGenerateOsha301APDF): WcGenerateOsha301APDF {
    if (_.isObjectLike(data)) {
      return {
        orgLevelId: data.orgLevelId,
        formName: data.formName,
        recordIds: data.recordIds
      };
    }
    return null;
  }

  public mapEstablishmentSize(dtos: ICreateOsha300AFormDialogOptions): CreateOsha300AFormDialogOptions {
    const data = new CreateOsha300AFormDialogOptions();
    data.establishmentSize = dtos.establishmentSize;
    return data;
  }
}


