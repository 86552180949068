import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { Router } from '@angular/router';
import { Assert } from '../../../framework/index';
import { Session, AuthStatusCode } from '../../../state-model/models/index';
import { SessionService } from '../../../core/services/session/session.service';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { LoggerService } from '../../../core/services/logger/logger.service';
import { AuthenticationApiService } from '../authentication-api/authentication.api-service';
import { SessionActions } from '../../actions/index';
import { authenticationConfig } from '../../authentication.config';
import { unsubscribeInService } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { PollingPeriodService } from '../../../core/services/polling-period/polling-period.service';
import { LoginEvent } from '../../models/index';
import { TokenValidityService } from '../../../core/services/token-validity/token-validity.service';
import { ModalService } from '../../../common/services/modal/modal.service';
import { LocalStorageService } from '../../../core/services';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(tokenValidity, sessionService, authenticationApiService, sessionActions, router, loggerService, userActivityService, modalService, localStorageService) {
        var _this = this;
        this.tokenValidity = tokenValidity;
        this.sessionService = sessionService;
        this.authenticationApiService = authenticationApiService;
        this.sessionActions = sessionActions;
        this.router = router;
        this.loggerService = loggerService;
        this.userActivityService = userActivityService;
        this.modalService = modalService;
        this.localStorageService = localStorageService;
        this.tokenRequestIsInProcess = false;
        this.appConfig = appConfig;
        this.inValidIPAddress = false;
        Assert.isNotNull(sessionService, 'sessionService');
        Assert.isNotNull(authenticationApiService, 'authenticationApiService');
        Assert.isNotNull(sessionActions, 'sessionActions');
        Assert.isNotNull(router, 'router');
        Assert.isNotNull(userActivityService, 'userActivityService');
        Assert.isNotNull(tokenValidity, 'tokenValidity');
        this.lastAlias = null;
        this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
        if (this.appConfig.session.checkTokenExpirationPeriod < 5) {
            this.loggerService.warning("Check session expiration period(" + this.appConfig.session.checkTokenExpirationPeriod + ")" +
                " is too low this can cause to unexpected behavior and extra web service calls. Take in attention.");
        }
        this.checkTokenSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(function () {
            _this.checkTokenExpiration();
        });
        this.applicationHeartbeatService.listen(this.appConfig.session.checkTokenExpirationPeriod * 1000);
        this.onLogout = new Subject();
        this.login$ = new Subject();
    }
    AuthenticationService.prototype.authenticate = function (username, password, alias, isFirstLogin) {
        var _this = this;
        Assert.isNotNull(username, 'username');
        Assert.isNotNull(password, 'password');
        var promise = this.authenticationApiService.authenticate(username, password, alias);
        promise.then(function (data) {
            if (data.statusCode === AuthStatusCode.valid) {
                _this.checkAliasChanging(alias, false);
                if (isFirstLogin) {
                    _this.sessionActions.userLoggedIn();
                    _this.sessionActions.startClearSession();
                }
                _this.sessionActions.saveSession(data.session);
                _this.applicationHeartbeatService.listen(_this.appConfig.session.checkTokenExpirationPeriod * 1000);
                _this.tokenRequestIsInProcess = false;
                _this.login$.next(new LoginEvent(username, data.session.user.email, data.session.user.roles, alias, _this.isAliasFirstOrChanged, false));
            }
        }).catch(function () {
            _this.checkAliasChanging(alias, true);
            _this.sessionActions.startClearSession();
            _this.tokenRequestIsInProcess = false;
        });
        this.tokenRequestIsInProcess = true;
        return promise;
    };
    AuthenticationService.prototype.getAuthorizationCode = function (authorizationRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promise;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(authorizationRequest, 'authorizationRequest');
                promise = this.authenticationApiService.getAuthorizationCode(authorizationRequest);
                return [2 /*return*/, promise];
            });
        });
    };
    AuthenticationService.prototype.validateAuthSession = function (state) {
        var _this = this;
        Assert.isNotNull(state, 'state');
        var promise = this.authenticationApiService.validateAuthSession(state);
        promise.then(function (data) {
            if (data.statusCode === AuthStatusCode.valid) {
                //this.checkAliasChanging(alias, false);
                //if (isFirstLogin) {
                _this.sessionActions.userLoggedIn();
                _this.sessionActions.startClearSession();
                //}
                _this.sessionActions.saveSession(data.session);
                _this.applicationHeartbeatService.listen(_this.appConfig.session.checkTokenExpirationPeriod * 1000);
                _this.tokenRequestIsInProcess = false;
                //this.login$.next(new LoginEvent(userName, data.session.user.email, data.session.user.roles, alias, this.isAliasFirstOrChanged, false));
            }
        }).catch(function () {
            //this.checkAliasChanging(alias, true);
            _this.sessionActions.startClearSession();
            _this.tokenRequestIsInProcess = false;
        });
        this.tokenRequestIsInProcess = true;
        return promise;
    };
    AuthenticationService.prototype.forgotPassword = function (usernameWithAlias, recaptcha) {
        Assert.isNotNull(usernameWithAlias, 'usernameWithAlias');
        Assert.isNotNull(recaptcha, 'recaptcha');
        return this.authenticationApiService.requestResetPassword(usernameWithAlias, recaptcha);
    };
    AuthenticationService.prototype.getResetPasswordInfo = function (token) {
        Assert.isNotNull(token, 'token');
        var promise = Promise.resolve('system');
        return promise;
    };
    AuthenticationService.prototype.setToken = function (token) {
        var _this = this;
        var session = new Session(token);
        this.sessionActions.saveSession(session);
        return this.renewAuthentication().then(function (session) {
            _this.sessionActions.userLoggedIn();
            _this.sessionActions.startClearSession();
            _this.sessionActions.saveSession(session);
            _this.applicationHeartbeatService.listen(_this.appConfig.session.checkTokenExpirationPeriod * 1000);
            _this.tokenRequestIsInProcess = false;
            _this.login$.next(new LoginEvent(session.user.username, session.user.email, session.user.roles, session.alias, _this.isAliasFirstOrChanged, true));
            return session;
        });
    };
    AuthenticationService.prototype.resetPasswordByToken = function (token, username, password, alias) {
        Assert.isNotNull(token, 'token');
        Assert.isNotNull(username, 'username');
        Assert.isNotNull(password, 'password');
        return this.authenticationApiService.resetPasswordByToken(token, username, password, alias);
    };
    AuthenticationService.prototype.resetPasswordByOldPassword = function (username, oldPassword, newPassword, alias) {
        Assert.isNotNull(username, 'username');
        Assert.isNotNull(oldPassword, 'oldPassword');
        Assert.isNotNull(newPassword, 'newPassword');
        return this.authenticationApiService.resetPasswordByOldPassword(username, oldPassword, newPassword, alias);
    };
    AuthenticationService.prototype.isAuthenticated = function () {
        var isAuthenticated = !this.sessionService.isExpired();
        return isAuthenticated;
    };
    AuthenticationService.prototype.getAlias = function () {
        return this.sessionService.getAlias();
    };
    AuthenticationService.prototype.getUser = function () {
        return this.sessionService.getUser();
    };
    AuthenticationService.prototype.navigateToResetPassword = function (username, alias) {
        Assert.isNotNull(username, 'username');
        var queryParams = {};
        var paramsObj = new Object();
        paramsObj[authenticationConfig.login.username] = username;
        if (alias) {
            paramsObj[authenticationConfig.login.alias] = alias;
        }
        var paramStr = JSON.stringify(paramsObj);
        var base64token = btoa(paramStr);
        queryParams["id"] = base64token;
        var navigationExtras = {
            queryParams: queryParams
        };
        this.onLogout.next();
        this.modalService.closeAllWindows();
        this.sessionActions.startClearSession();
        this.router.navigate(['login', 'reset_password'], navigationExtras);
    };
    AuthenticationService.prototype.navigateToInvalidIPAddress = function () {
        this.onLogout.next();
        this.modalService.closeAllWindows();
        this.sessionActions.startClearSession();
        this.router.navigate(['login_ip_restriction']);
    };
    AuthenticationService.prototype.navigateToLogin = function () {
        this.applicationHeartbeatService.stop();
        this.modalService.closeAllWindows();
        this.onLogout.next();
        this.router.navigate(['/login']);
    };
    AuthenticationService.prototype.logout = function () {
        var _this = this;
        this.authenticationApiService.logout()
            .finally(function () {
            _this.sessionActions.startClearSession();
            _this.navigateToLogout(_this.router);
        });
    };
    AuthenticationService.prototype.navigateToLogout = function (router, returnPath) {
        this.applicationHeartbeatService.stop();
        var urls = returnPath ? _.split(returnPath, '?', 2) : [];
        var returnUrl = urls.length > 0 ? urls[0] : null;
        var returnQs = urls.length > 1 ? urls[1] : null;
        var queryParams = {};
        if (returnUrl) {
            queryParams[authenticationConfig.login.returnUrlQueryParameter] = returnUrl;
        }
        if (returnQs) {
            queryParams[authenticationConfig.login.returnUrlQueryStringParameter] = returnQs;
        }
        var navigationExtras = {
            queryParams: queryParams
        };
        this.modalService.closeAllWindows();
        this.onLogout.next();
        this.router.navigate(['/login'], navigationExtras);
        var b2cSSologin = this.localStorageService.get(appConfig.b2sSSoLoginKey);
        if (b2cSSologin) {
            this.localStorageService.remove(appConfig.b2sSSoLoginKey);
            window.open(appConfig.api.identityUrl + "/signout");
        }
    };
    AuthenticationService.prototype.loadPasswordPolicyUnsecure = function (alias) {
        return this.authenticationApiService.loadPasswordPolicyUnsecure(alias);
    };
    AuthenticationService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    AuthenticationService.prototype.renewAuthentication = function () {
        var _this = this;
        var promise = this.authenticationApiService.renew();
        promise.then(function (session) {
            if (!session.isIPAllowed) {
                _this.navigateToInvalidIPAddress();
            }
            else {
                _this.sessionActions.saveSession(session);
            }
            _this.tokenRequestIsInProcess = false;
        }).catch(function () {
            // Don't clear session unless it has expired.    
            if (!_this.sessionService.isIpAddressValid()) {
                _this.navigateToInvalidIPAddress();
            }
            else if (_this.sessionService.isExpired()) {
                _this.sessionActions.clearSession();
            }
            _this.tokenRequestIsInProcess = false;
        });
        this.tokenRequestIsInProcess = true;
        return promise;
    };
    AuthenticationService.prototype.checkAliasChanging = function (alias, isReset) {
        this.isAliasFirstOrChanged = _.isNull(this.lastAlias) || this.lastAlias !== alias;
        this.isAliasChanged = !_.isNull(this.lastAlias) && this.lastAlias !== alias;
        this.lastAlias = isReset ? null : alias;
    };
    AuthenticationService.prototype.checkTokenExpiration = function () {
        if (this.isAuthenticated()) {
            var token = this.sessionService.getToken();
            var sessionExpirationDate = this.sessionService.getTokenExpirationDate();
            var secondsSessionExperiredAfter = moment(sessionExpirationDate)
                .diff(moment(), 'seconds');
            if (secondsSessionExperiredAfter <= this.appConfig.session.startRenewUntilSessionExpiredSeconds) {
                this.renewToken();
            }
        }
    };
    AuthenticationService.prototype.renewToken = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.tokenRequestIsInProcess) return [3 /*break*/, 5];
                        console.info('Renew token started.');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.renewAuthentication()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.loggerService.warning('Cant renew jwt.', error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        console.info('Renew token complited.');
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], AuthenticationService.prototype, "checkTokenSubscription", void 0);
    return AuthenticationService;
}());
export { AuthenticationService };
