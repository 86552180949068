import * as _ from 'lodash';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe, unsubscribeAll } from '../../../../core/decorators/index';
import { ApplicationDashboardManagementService, ApplicationService } from '../../../services/index';
import { SessionService } from '../../../../core/services/index';
import { IUser } from '../../../../authentication/store/index';
import { DashboardApplication, DashboardApplications, StyledUserApplication } from '../../../../organization/models/index';
import { appConfig } from '../../../../app.config';

@Component({
  selector: 'slx-applications-config',
  templateUrl: './applications-config.component.html',
  styleUrls: ['./applications-config.component.scss']
})

export class ApplicationsConfigComponent implements OnInit {
  public dialogResult: boolean;
  public isLoading: boolean;
  public appsModel: DashboardApplication[];
  public activeApps: DashboardApplication[];
  public inActiveApps: DashboardApplication[];
  public appsToUpdate: DashboardApplication[] = [];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @Output()
  public onLoading = new EventEmitter<boolean>();

  @Output()
  public onHasChanges = new EventEmitter<boolean>();

  public applications: StyledUserApplication[];

  constructor(private appDashboardMngtService: ApplicationDashboardManagementService,
    private sessionService: SessionService,
    private applicationService: ApplicationService) { 
      this.appsModel = this.activeApps = this.inActiveApps = [];
    }

  public ngOnInit(): void {
    this.subscriptions.loading = this.appDashboardMngtService
    .subscribeToLoading((isLoading: boolean) => {
      this.onLoading.emit(isLoading);
    });

    this.loadApps();
  }

  public loadApps() : void {
    this.subscriptions.applicationUpdate = this.applicationService.applicationsUpdated$
      .subscribe((apps: StyledUserApplication[]) => {
        this.applications = apps;
    });

    this.subscriptions.dashboardUpdates = this.appDashboardMngtService.dashboardApps$.subscribe((apps: DashboardApplications) => {
      this.appsModel = [];
      let user: IUser = this.sessionService.getUser();
      if (apps.applications) {
        _.forEach(this.applications, (app: StyledUserApplication) => {
          if(_.includes(appConfig.implementedAppDashboards, app.name)){
            let result = _.find(apps.applications, ['appId', app.id]);
            let userApp = new DashboardApplication();
            userApp.id = 0;
            userApp.appName = app.title;
            userApp.icon = app.icon;
            userApp.userId = user.id;
            userApp.appId = app.id;
            userApp.displayOrder = 999;
            userApp.visible = true;
            userApp.itemId = app.id;
            userApp.itemType = 0;
            if(app.name === 'HR'){
              userApp.appName = 'Leave Management';
              userApp.itemType = 1;
            }
            if(!_.isUndefined(result) && !_.isUndefined(result.visible)){
              userApp.visible = result.visible;
              userApp.id = result.id;
              userApp.displayOrder = result.displayOrder;
            }
            if(userApp.itemType == 0 || (userApp.itemType == 1 && app.dashboards.length > 0)){
              this.appsModel.push(userApp);
            }
          }
        });
        this.configureApps();
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private emitChanges(): void {
    this.onHasChanges.emit(true);
  }

  public configureApps(): void {
    this.activeApps = _.sortBy(_.filter(this.appsModel, (t) => t.visible), (a) => a.displayOrder);
    this.inActiveApps = _.filter(this.appsModel, (t) => !t.visible);
  }

  public async onDragEnd(movedTo: DashboardApplication[]): Promise<void> {
    this.appsToUpdate = [];
    let order = 1;

    _.forEach(this.activeApps, (item) => {
      item.displayOrder = order;
      order++;
      this.appsToUpdate.push(item);
    });
    this.emitChanges();
    await this.appDashboardMngtService.modifyApps(this.appsToUpdate);
  }

  public async showHideAppItem(item: DashboardApplication): Promise<void> {
    let user: IUser = this.sessionService.getUser();
    this.appsToUpdate = [];
    item.displayOrder = _.max(_.map(this.activeApps, (a) => a.displayOrder)) + 1;
    item.visible = !item.visible;
    item.userId = user.id;
    this.appsToUpdate.push(item);
    this.configureApps();
    this.emitChanges();
    await this.appDashboardMngtService.modifyApps(this.appsToUpdate);
  }

}
