import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/combineLatest';
import * as _ from 'lodash';
import { ScheduleApiService } from '../../services/schedule/schedule-api.service';
import { mutableSelect } from '../../../core/decorators/redux-decorators';
import { unsubscribe } from '../../../core/decorators/index';
import { PostScheduleNavigationService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { PostScheduleData, SummaryGridRecord } from '../../models/post-schedule/post-schedule-data';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { LookupApiService } from '../../../organization/services/index';
import * as moment from 'moment';
var PostScheduleComponent = /** @class */ (function () {
    function PostScheduleComponent(api, lookupApiService, route, router) {
        this.allowOT = true;
        this.allowSameDay = true;
        this.pageSize = 15;
        this.skip = 0;
        this.route = route;
        this.router = router;
        this.api = api;
        this.scheduleData = new PostScheduleData();
        this.sort = [{ field: 'startDate', dir: 'desc' }];
        this.lookupApiService = lookupApiService;
    }
    PostScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            isLoaded: false,
            editMode: false
        };
        this.appConfig = appConfig;
        this.orgLevelSubscription = Observable.combineLatest(this.orgLevel$, this.filters$, this.route.params).subscribe(function (value) {
            var orgLevel = value[0];
            var filters = value[1];
            var params = value[2];
            _this.currentOrgLevel = orgLevel;
            if (filters) {
                var linkMoment = moment(params['date'], appConfig.linkDateFormat).startOf('day');
                var filterMoment = moment(filters.dateFrom).startOf('day');
                _this.scheduleData.startDate = filterMoment.isSame(linkMoment) ? filterMoment.toDate() : linkMoment.toDate();
                _this.scheduleData.endDate = moment(_this.scheduleData.startDate).add(filters.weekNumber, 'weeks').toDate();
                _this.filters = filters;
            }
            _this.lookupApiService.getScheduleCycles(orgLevel.id)
                .then(function (cycles) {
                _this.scheduleCycles = cycles;
                if (!_this.scheduleCycles)
                    return;
                var startDate = moment(_this.scheduleData.startDate);
                var selectedCycle = _.find(_this.scheduleCycles, function (cycle) {
                    return cycle.startDate.isSame(startDate, 'day');
                });
                if (selectedCycle) {
                    _this.selectedScheduleCycle = selectedCycle;
                    _this.refreshData();
                }
                else {
                    selectedCycle = _.find(_this.scheduleCycles, function (cycle) {
                        return cycle.startDate.isBefore(startDate, 'day') && cycle.endDate.isAfter(startDate, 'day');
                    });
                    if (selectedCycle)
                        _this.onScheduleCycleSelected(selectedCycle);
                }
            });
        });
    };
    PostScheduleComponent.prototype.ngOnDestroy = function () {
        this.appConfig = null;
    };
    PostScheduleComponent.prototype.onScheduleCycleSelected = function (cycle) {
        if (cycle) {
            this.scheduleData.startDate = cycle.startDate.toDate();
            var service = new PostScheduleNavigationService(this.router, this.route);
            service.updateRoute(this.scheduleData.startDate);
            this.refreshData();
        }
    };
    PostScheduleComponent.prototype.onPostClick = function () {
        var _this = this;
        this.startProgress();
        this.api.postSummarySchedule(this.currentOrgLevel, this.allowOT, this.allowSameDay, this.scheduleData)
            .then(function (response) {
            _this.stopProgress();
        }).catch(function (ex) {
            _this.stopProgress();
        });
    };
    PostScheduleComponent.prototype.switchState = function (item) {
        item.editing = !item.editing;
    };
    PostScheduleComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    PostScheduleComponent.prototype.pageChange = function (event) {
        this.skip = event.skip;
        this.refreshGrid();
    };
    PostScheduleComponent.prototype.gridSelectionChange = function (selection) {
        //Andrey Skorik  Please check this logic
        _.forEach(selection.selectedRows, function (args) { args.dataItem.editing = true; });
        _.forEach(selection.deselectedRows, function (args) {
            var item = args.dataItem ? args.dataItem : args;
            item.editing = false;
        });
        /* Prior version
        _.forEach(this.scheduleData.entities, (record: SummaryGridRecord, index: number) => {
              if (selection.index !== index) {
                record.editing = false;
              } else {
                record.editing = selection.selected;
              }
         });
        */
    };
    PostScheduleComponent.prototype.startProgress = function () {
        this.state.isLoading = true;
    };
    PostScheduleComponent.prototype.stopProgress = function () {
        this.state.isLoading = false;
    };
    PostScheduleComponent.prototype.refreshGrid = function () {
        if (!this.scheduleData.entities) {
            this.gridView = null;
            return;
        }
        var sortedRecords = orderBy(this.scheduleData.entities, this.sort);
        var pagedRecords = sortedRecords.slice(this.skip, this.skip + this.pageSize);
        this.gridView = {
            data: pagedRecords,
            total: sortedRecords.length
        };
    };
    PostScheduleComponent.prototype.refreshData = function () {
        var _this = this;
        if (this.currentOrgLevel) {
            this.startProgress();
            this.api.getScheduleSummary(this.currentOrgLevel, this.scheduleData.startDate)
                .then(function (response) {
                _this.scheduleData = response.data;
                if (!_this.scheduleData) {
                    _this.scheduleData = new PostScheduleData();
                    _this.scheduleData.startDate = _this.selectedScheduleCycle.startDate.toDate();
                }
                _this.refreshGrid();
                _this.stopProgress();
            }).catch(function (ex) {
                _this.scheduleData = new PostScheduleData();
                _this.scheduleData.startDate = _this.selectedScheduleCycle.startDate.toDate();
                _this.refreshGrid();
                _this.stopProgress();
                console.debug('Error fetching records', ex);
            });
        }
    };
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'filters']),
        tslib_1.__metadata("design:type", Observable)
    ], PostScheduleComponent.prototype, "filters$", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PostScheduleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PostScheduleComponent.prototype, "orgLevelSubscription", void 0);
    return PostScheduleComponent;
}());
export { PostScheduleComponent };
