import { FieldData } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';
import { ExemptStatus, PayType, IExemptStatus } from '../../../../organization/models/lookup/index';

export interface IEmployeeSectionsRateHistory {
  records: IEmployeeSectionsRateHistoryRecord[];
  isEnableRate4DecimalPlaces: boolean;
  isEnableIncreasedReason: boolean;
}

export interface IEmployeeSectionsRateHistoryRecord {
  startDate: string;
  endDate: string;
  rate: number;
  rate2: number;
  rate3: number;
  payType: string;
  standardHours: number;
  benefitHours: number;
  exemptStatus: IExemptStatus;
  incReason: string;
}

export class EmployeeSectionsRateHistoryRecord {
  public startDate: FieldData<Date>;
  public endDate: FieldData<Date>;
  public rate: FieldData<number>;
  public rate2: FieldData<number>;
  public rate3: FieldData<number>;
  public payType: FieldData<PayType>;
  public standardHours: FieldData<number>;
  public benefitHours: FieldData<number>;
  public exemptStatus: FieldData<ExemptStatus>;
  public incReason: string;
}

export class EmployeeSectionsRateHistory extends EmployeeSectionsBase {
  public records: EmployeeSectionsRateHistoryRecord[];
  public isEnableRate4DecimalPlaces: boolean;
  public isEnableIncreasedReason: boolean;

  constructor() {
    super();
    this.records = [];
  }
}

