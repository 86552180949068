var ButtonCollapsePaneComponent = /** @class */ (function () {
    function ButtonCollapsePaneComponent() {
    }
    Object.defineProperty(ButtonCollapsePaneComponent.prototype, "collapsed", {
        set: function (val) {
            if (val) {
                this.onCollapse();
            }
            else {
                this.onExpand();
            }
        },
        enumerable: true,
        configurable: true
    });
    ButtonCollapsePaneComponent.prototype.onCollapse = function () {
        this.isExpanding = false;
        this.isCollapsed = true;
        if (this.collapsedTitle)
            this.title = this.collapsedTitle;
    };
    ButtonCollapsePaneComponent.prototype.onExpand = function () {
        var _this = this;
        this.isExpanding = true;
        this.isCollapsed = false;
        setTimeout(function () {
            _this.isExpanding = false;
        }, 1000);
        if (this.expandedTitle)
            this.title = this.expandedTitle;
    };
    ButtonCollapsePaneComponent.prototype.onToggle = function () {
        if (!this.isCollapsed) {
            this.onCollapse();
        }
        else {
            this.onExpand();
        }
    };
    return ButtonCollapsePaneComponent;
}());
export { ButtonCollapsePaneComponent };
