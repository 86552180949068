export interface IStaffingPredictorConfig {
    api: {
        staffingPredictor: {
            root: string;
            predictor: string;
            rating: string;
            organization: string;
            pastfuture: string;
            goalSetting: string;
            SavingGoal: string;
            retrieveGoal:string;
        }
    };
}
export const staffingPredictorConfig: IStaffingPredictorConfig = {
    api: {
        staffingPredictor: {
            root: 'staffing',
            predictor: 'predictor',
            rating: 'rating',
            organization: 'organization',
            pastfuture: 'PastFutureRatedAndUnRated',
            goalSetting: 'goalSetting',
            SavingGoal: 'SavingGoal',
            retrieveGoal: 'retrieveGoal'
        }
    }
};
