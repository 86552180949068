<slx-dashboard-item [dashboard]="dashboard"
  [className]="alertConfigOrderAndVisibilty?.length > 3 ? 'expanded maxheight' : 'expanded'" 
  [progressbar]="progressbar" (onHasChanges)="onHasChanges($event)" (navigationClick)="navigate()">
  <div class="app__links wrap-tablet">
    <a *ngFor="let appAlert of alertConfigOrderAndVisibilty" [ngClass]="{ 'hidden': !appAlert.visible }" class="app__link"
    [lmRosterLink]="appAlert.link">
    <span slxTooltip="{{appAlert.alertName}} Count for next 30 Days">
      <span class="app__link-name">{{appAlert.alertName}}</span>
      <i class="app__link-icon  {{appAlert.icon}}" aria-hidden="true"></i>
      <i class="app__link-amount" aria-hidden="true"
        [ngClass]="{ 'empty': appAlert.counter === 0 }">{{ appAlert.counter}}</i>
    </span>
  </a>
  </div>
</slx-dashboard-item>
