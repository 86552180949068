/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./supervisor-group-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/directives/popover/popover.directive";
import * as i3 from "@angular/common";
import * as i4 from "../../../../common/components/popover-content/popover-content.component.ngfactory";
import * as i5 from "../../../../common/components/popover-content/popover-content.component";
import * as i6 from "../supervisor-grid/supervisor-grid.component.ngfactory";
import * as i7 from "../supervisor-grid/supervisor-grid.component";
import * as i8 from "../supervised-employee-grid/supervised-employee-grid.component.ngfactory";
import * as i9 from "../supervised-employee-grid/supervised-employee-grid.component";
import * as i10 from "./supervisor-group-detail.component";
import * as i11 from "../../../services/supervisor-assignment/supervisor-assignment-management.service";
var styles_SupervisorGroupDetailComponent = [i0.styles];
var RenderType_SupervisorGroupDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupervisorGroupDetailComponent, data: {} });
export { RenderType_SupervisorGroupDetailComponent as RenderType_SupervisorGroupDetailComponent };
function View_SupervisorGroupDetailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddSupervisors() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Supervisor"]))], null, null); }
function View_SupervisorGroupDetailComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "button", [["class", "theme-button-apply remove-button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOrHideOnClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showOnMouseover() !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).hideOnMouseout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.PopoverDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { content: [0, "content"] }, null), (_l()(), i1.ɵted(-1, null, ["Remove Supervisor"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = i1.ɵnov(_v.parent, 9); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.selectedSupervisors || (_co.selectedSupervisors.length < 1)); _ck(_v, 0, 0, currVal_0); }); }
function View_SupervisorGroupDetailComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddEmployees() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Employee"]))], null, null); }
function View_SupervisorGroupDetailComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "button", [["class", "theme-button-apply remove-button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOrHideOnClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showOnMouseover() !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).hideOnMouseout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.PopoverDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { content: [0, "content"] }, null), (_l()(), i1.ɵted(-1, null, ["Remove Employee"])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_1 = i1.ɵnov(_v.parent, 28); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.selectedEmployees || (_co.selectedEmployees.length < 1)); _ck(_v, 0, 0, currVal_0); }); }
export function View_SupervisorGroupDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "details-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Supervisors "])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SupervisorGroupDetailComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SupervisorGroupDetailComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 7, "popover-content", [["placement", "auto bottom"]], null, [[null, "mouseenter"], [null, "mouseleave"], ["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).showOnMouseEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).hideOnMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).onDocumentMouseDown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_PopoverContentComponent_0, i4.RenderType_PopoverContentComponent)), i1.ɵdid(9, 4243456, [["acceptRemoveSupervisorsPopover", 4]], 0, i5.PopoverContentComponent, [i1.ElementRef, i1.ChangeDetectorRef], { placement: [0, "placement"], title: [1, "title"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 5, "div", [["style", "width:110px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["style", "width:50px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["style", "width:50px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        i1.ɵnov(_v, 9).hide();
        var pd_0 = (_co.onRemoveSupervisors() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "slx-supervisor-grid", [], null, [[null, "selectionChanged"], [null, "saveSuprvisor"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.supervisorsSelectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("saveSuprvisor" === en)) {
        var pd_1 = (_co.saveSuprvisor($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_SupervisorGridComponent_0, i6.RenderType_SupervisorGridComponent)), i1.ɵdid(18, 770048, null, 0, i7.SupervisorGridComponent, [i1.ChangeDetectorRef], { supervisors: [0, "supervisors"] }, { selectionChanged: "selectionChanged", saveSuprvisor: "saveSuprvisor" }), (_l()(), i1.ɵeld(19, 0, null, null, 15, "div", [["class", "details-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Employees "])), (_l()(), i1.ɵeld(22, 0, null, null, 12, "div", [["class", "flez-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SupervisorGroupDetailComponent_3)), i1.ɵdid(24, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SupervisorGroupDetailComponent_4)), i1.ɵdid(26, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(27, 0, null, null, 7, "popover-content", [["placement", "auto bottom"]], null, [[null, "mouseenter"], [null, "mouseleave"], ["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).showOnMouseEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 28).hideOnMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 28).onDocumentMouseDown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_PopoverContentComponent_0, i4.RenderType_PopoverContentComponent)), i1.ɵdid(28, 4243456, [["acceptRemoveEmployeesPopover", 4]], 0, i5.PopoverContentComponent, [i1.ElementRef, i1.ChangeDetectorRef], { placement: [0, "placement"], title: [1, "title"] }, null), (_l()(), i1.ɵeld(29, 0, null, 0, 5, "div", [["style", "width:110px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["style", "width:50px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 28).hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No"])), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["style", "width:50px"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        i1.ɵnov(_v, 28).hide();
        var pd_0 = (_co.onRemoveEmployees() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(35, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(36, 0, null, null, 1, "slx-supervised-employee-grid", [], null, [[null, "selectionChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChanged" === en)) {
        var pd_0 = (_co.employeeSelectionChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_SupervisedEmployeeGridComponent_0, i8.RenderType_SupervisedEmployeeGridComponent)), i1.ɵdid(37, 770048, null, 0, i9.SupervisedEmployeeGridComponent, [i1.ChangeDetectorRef], { employees: [0, "employees"] }, { selectionChanged: "selectionChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.disableOperations; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.disableOperations; _ck(_v, 7, 0, currVal_1); var currVal_2 = "auto bottom"; var currVal_3 = i1.ɵinlineInterpolate(1, "Do you wish to remove these ", ((_co.selectedSupervisors == null) ? null : _co.selectedSupervisors.length), " supervisors?"); _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = _co.group.supervisors; _ck(_v, 18, 0, currVal_4); var currVal_5 = !_co.disableOperations; _ck(_v, 24, 0, currVal_5); var currVal_6 = !_co.disableOperations; _ck(_v, 26, 0, currVal_6); var currVal_7 = "auto bottom"; var currVal_8 = i1.ɵinlineInterpolate(1, "Do you wish to remove these ", ((_co.selectedEmployees == null) ? null : _co.selectedEmployees.length), " employees?"); _ck(_v, 28, 0, currVal_7, currVal_8); var currVal_9 = _co.group.employees; _ck(_v, 37, 0, currVal_9); }, null); }
export function View_SupervisorGroupDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-supervisor-group-detail", [], null, null, null, View_SupervisorGroupDetailComponent_0, RenderType_SupervisorGroupDetailComponent)), i1.ɵdid(1, 770048, null, 0, i10.SupervisorGroupDetailComponent, [i11.SupervisorAssignmentManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupervisorGroupDetailComponentNgFactory = i1.ɵccf("slx-supervisor-group-detail", i10.SupervisorGroupDetailComponent, View_SupervisorGroupDetailComponent_Host_0, { group: "group", disableOperations: "disableOperations" }, {}, []);
export { SupervisorGroupDetailComponentNgFactory as SupervisorGroupDetailComponentNgFactory };
