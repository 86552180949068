import { NavigationMenuMapService } from './../../../organization/services/navigation/navigation-menu-map.service';
import { NavigationMenuItem, INavigationMenuItem } from './../../../organization/models/navigation-menu-item';
import { IFavoriteItem, FavoriteItem } from './../../models/favorites/favorite-item';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class FavoritesMapService {

  constructor(private menuMapService: NavigationMenuMapService) {
  }

  public mapMenuItems(dto: INavigationMenuItem[]): NavigationMenuItem[] {
    return _.map(dto, x => this.menuMapService.mapToModel(x));
  }

  public mapFavoriteItems(dto: IFavoriteItem[]): FavoriteItem[] {
    return dto.map(x => this.mapFavoriteItem(x));
  }

  public mapFavoriteItem(dto: IFavoriteItem): FavoriteItem {
    const item: FavoriteItem = new FavoriteItem();
    item.displayOrder = dto.displayOrder;
    item.menuId = dto.menuId;
    return item;
  }

  public mapMenuToFavoriteItemsDto(items: NavigationMenuItem[]): IFavoriteItem[] {
    return items.map(x => this.mapMenuToFavoriteItemDto(x));
  }

  public mapMenuToFavoriteItemDto(item: NavigationMenuItem): IFavoriteItem {
    const dto: IFavoriteItem = {
      displayOrder: item.order,
      menuId: item.id
    };
    return dto;
  }

}
