import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { ActivatedRoute, Router } from '@angular/router';
import { mutableSelect, unsubscribe } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { PbjExportApiService } from '../../../services/index';
import { PopperContent } from 'ngx-popper';
import { PBJExportLogHeader } from '../../../models/index';
import { FileService } from '../../../../../common/services/index';
import { PbjNavigationService } from '../../../../../common/services/index';
import { PbjDetailsEmpDataGridComponent } from '../pbj-details-emp-data-grid/pbj-details-emp-data-grid.component';
import { PbjDetailsCensusDataGridComponent } from '../pbj-details-census-data-grid/pbj-details-census-data-grid.component';
import { PbjDetailsErrorsDataGridComponent } from '../pbj-details-errors-data-grid/pbj-details-errors-data-grid.component';
import { PbjDetailsStaffingHoursGridComponent } from '../pbj-details-staffing-hours-grid/pbj-details-staffing-hours-grid.component';
import { AppSettingsManageService } from '../../../../../app-settings/services';
var PbjLogExportDetailsComponent = /** @class */ (function () {
    function PbjLogExportDetailsComponent(route, router, apiService, fileService, appSettingsManageService) {
        this.route = route;
        this.router = router;
        this.apiService = apiService;
        this.fileService = fileService;
        this.appSettingsManageService = appSettingsManageService;
        this.orgLevelId = null;
        this.state = {
            isLoading: false,
            isChildLoading: false,
            empData: false,
            staffingHours: false,
            census: false,
            errors: false
        };
    }
    PbjLogExportDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.appSettingsManageService.getAppServerConfig().then(function (config) {
            _this.isPBJExportCorpLevelEnabled = config.IsPBJExportCorpLevelEnabled;
            _this.pdfName = _this.isPBJExportCorpLevelEnabled ? 'Download PDF' : 'Export to  PDF';
            _this.excelName = _this.isPBJExportCorpLevelEnabled ? 'Download Excel' : 'Export to  Excel';
            _this.xmlName = _this.isPBJExportCorpLevelEnabled ? 'Download XML' : 'Export XML File';
        });
        this.routeSubscripion = this.route.params
            .combineLatest(this.route.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var orgLevelId = +queryParams['orgLevelId'];
            if (_.isFinite(_this.orgLevelId) && _.isFinite(orgLevelId) && _this.orgLevelId !== orgLevelId) {
                _this.navigateToPbjExport();
                return;
            }
            if (!_.isFinite(_this.orgLevelId) && _.isFinite(orgLevelId)) {
                _this.orgLevelId = orgLevelId;
            }
            _this.exportId = +params['exportId'];
            _this.state.empData = true;
            _this.loadHeaders(_this.exportId);
        });
    };
    PbjLogExportDetailsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PbjLogExportDetailsComponent.prototype.onChildLoadStatusChange = function (isLoading) {
        this.state.isChildLoading = isLoading;
    };
    PbjLogExportDetailsComponent.prototype.onEmployeeData = function () {
        this.state.empData = true;
        this.state.staffingHours = false;
        this.state.census = false;
        this.state.errors = false;
    };
    PbjLogExportDetailsComponent.prototype.onStaffingHours = function () {
        this.state.empData = false;
        this.state.staffingHours = true;
        this.state.census = false;
        this.state.errors = false;
    };
    PbjLogExportDetailsComponent.prototype.onCensus = function () {
        this.state.empData = false;
        this.state.staffingHours = false;
        this.state.census = true;
        this.state.errors = false;
    };
    PbjLogExportDetailsComponent.prototype.onErrors = function () {
        this.state.empData = false;
        this.state.staffingHours = false;
        this.state.census = false;
        this.state.errors = true;
    };
    PbjLogExportDetailsComponent.prototype.onXMLFile = function () {
        var _this = this;
        this.apiService.getPBJExportData(this.exportId)
            .then(function (val) {
            _this.state.isLoading = false;
            _this.fileService.saveToFileSystem(val.blob, val.file);
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    PbjLogExportDetailsComponent.prototype.onExportToPdf = function () {
        if (this.state.empData) {
            this.empDataGrid.exportToPdf();
        }
        else if (this.state.census) {
            this.censusDataGrid.exportToPdf();
        }
        else if (this.state.errors) {
            this.errorsDataGrid.exportToPdf();
        }
        else if (this.state.staffingHours) {
            this.staffingHoursGrid.exportToPdf();
        }
    };
    PbjLogExportDetailsComponent.prototype.onExportToExcel = function () {
        if (this.state.empData) {
            this.empDataGrid.exportToXls();
        }
        else if (this.state.census) {
            this.censusDataGrid.exportToXls();
        }
        else if (this.state.errors) {
            this.errorsDataGrid.exportToXls();
        }
        else if (this.state.staffingHours) {
            this.staffingHoursGrid.exportToXls();
        }
    };
    Object.defineProperty(PbjLogExportDetailsComponent.prototype, "hasErrors", {
        get: function () {
            return this.pbjHeader && this.pbjHeader.errorsCount > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjLogExportDetailsComponent.prototype, "title", {
        get: function () {
            return this.hasErrors ? 'Export is not available for items that contain errors' : '';
        },
        enumerable: true,
        configurable: true
    });
    PbjLogExportDetailsComponent.prototype.loadHeaders = function (exportId) {
        var _this = this;
        this.state.isLoading = true;
        this.apiService.getPBJExportLogHeader(exportId)
            .then(function (val) {
            _this.state.isLoading = false;
            _this.pbjHeader = val;
        }).catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    PbjLogExportDetailsComponent.prototype.navigateToPbjExport = function () {
        var pbjNavService = new PbjNavigationService(this.router, this.route);
        pbjNavService.NavigateToPbjExport();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjLogExportDetailsComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PbjLogExportDetailsComponent.prototype, "orgLevel$", void 0);
    return PbjLogExportDetailsComponent;
}());
export { PbjLogExportDetailsComponent };
