import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { acaQuickPopupState } from '../../../models/aca-1095-c';
import { Aca1095cManagementService } from '../../../services/aca-1095-c/aca-1095-c-management.service';
import { unsubscribeAll } from '../../../../core/decorators/index';
var QuickConfirm1095CPopup = /** @class */ (function () {
    function QuickConfirm1095CPopup(acaManagementService) {
        this.acaManagementService = acaManagementService;
        this.showChanges = false;
        this.isQuickConfirmStarted = false;
        this.subscriptions = {};
    }
    Object.defineProperty(QuickConfirm1095CPopup.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(QuickConfirm1095CPopup.prototype, "hasChanges", {
        get: function () {
            return this.items && this.items.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    QuickConfirm1095CPopup.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onMarkItemForQickConfirm = this.acaManagementService.subscribeToQuickConfirmRecords(function (items) {
            _this.items = items;
            _this.markChanges();
        });
    };
    QuickConfirm1095CPopup.prototype.markChanges = function () {
        if (this.items && this.items.length > 0) {
            this.acaManagementService.markAsDirty();
        }
        else {
            this.acaManagementService.clearChanges();
        }
    };
    QuickConfirm1095CPopup.prototype.undoEdit = function (item, monthItem) {
        var undoItem;
        if (monthItem && item.months.length > 1) {
            undoItem = _.clone(item);
            undoItem.months = [monthItem];
        }
        else {
            undoItem = _.clone(item);
        }
        if (undoItem) {
            this.acaManagementService.setUndoQuickRecord(undoItem);
        }
    };
    QuickConfirm1095CPopup.prototype.showChangesClick = function () {
        this.showChanges = true;
    };
    QuickConfirm1095CPopup.prototype.hideChangesClick = function () {
        this.showChanges = false;
    };
    QuickConfirm1095CPopup.prototype.startQuickConfirm = function () {
        if (!this.isQuickConfirmStarted) {
            this.isQuickConfirmStarted = true;
            this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmStart);
        }
    };
    QuickConfirm1095CPopup.prototype.saveQuickConfirm = function () {
        if (this.isQuickConfirmStarted) {
            this.items = [];
            this.isQuickConfirmStarted = false;
            this.acaManagementService.setQuickPopupState(acaQuickPopupState.quickConfirmSave);
            this.acaManagementService.clearChanges();
        }
    };
    QuickConfirm1095CPopup.prototype.ngOnDestroy = function () {
        // Must be, see #issueWithAOTCompiler
    };
    QuickConfirm1095CPopup.prototype.setExpandedItem = function (item) {
        if (_.isEqual(this.expandedItem, item)) {
            this.expandedItem = null;
        }
        else {
            this.expandedItem = item;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], QuickConfirm1095CPopup.prototype, "subscriptions", void 0);
    return QuickConfirm1095CPopup;
}());
export { QuickConfirm1095CPopup };
