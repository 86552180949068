import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ApiUtilService, CacheUtilService, UrlParamsService } from '../../../common/services/index';
import { ResponseBody } from '../../../core/models/api/response-body';
import { FieldsMeta, Actions } from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';
import { configurationConfig } from '../../configuration.config';
import { AbsenceConfigurationMapService } from './absence-configuration-map.service';
import { IAbsenceDTO, AbsenceModel } from '../../models/absences/absence.model';

@Injectable()
export class AbsenceConfigurationApiService {

  constructor(private apiUtilService: ApiUtilService,
    private mapService: AbsenceConfigurationMapService,
    private metaMapService: MetaMapService,
    private cacheUtilService: CacheUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getAbsences(orgLevelId: number): Promise<{ records: AbsenceModel[], actions: Actions }> {
    const url: string = this.getOrgLevelApiRoot(orgLevelId);

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiUtilService.request<any[], Meta>(request).then((response: ResponseBody<IAbsenceDTO[], Meta>) => {
      let actions: Actions = this.metaMapService.mapActions(response.meta as FieldsMeta);
      return { records: this.mapService.mapAbsences(response.data), actions: actions };
    });
  }

  public addAbsence(orgLevelId: number, absence: AbsenceModel): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}`;

    let body: any = absence;
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  public saveAbsence(orgLevelId: number, absence: AbsenceModel): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}`;

    let body: any = absence;
    const request = this.urlParamsService.createPutRequest(url, body, { absenceCode: absence.code });
    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }


  public deleteAbsence(orgLevelId: number, absence: AbsenceModel): Promise<any> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}`;
    const request = this.urlParamsService.createDeleteRequest(url, { absenceCode: absence.code });
    return this.apiUtilService.request<any[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.cacheUtilService.clear();
        return response;
      });
  }

  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}
/${configurationConfig.api.configuration.root}
/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}
/${configurationConfig.api.configuration.absences.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }

}
