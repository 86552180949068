import * as tslib_1 from "tslib";
import { ReplaySubject, Subject } from 'rxjs';
import { Assert } from '../../../framework';
import { NotificationsApiService } from './notifications-api.service';
import { IconAccess, MessageNotificationModel } from '../../models/index';
var MessageNotificationService = /** @class */ (function () {
    function MessageNotificationService(apiService) {
        this.apiService = apiService;
        this.iconAccess$ = new ReplaySubject(1);
        this.unreadSmsCount$ = new Subject();
        this.unreadArchiveCount$ = new Subject();
        this.messageStatusUpdate$ = new Subject();
    }
    MessageNotificationService.prototype.subscribeToIconAccess = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.iconAccess$.subscribe(callback);
    };
    MessageNotificationService.prototype.checkIconAcess = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.apiService.getIconAccessCheck(orgLevelId).then(function (access) {
                    _this.iconAccess$.next(access);
                });
                return [2 /*return*/];
            });
        });
    };
    MessageNotificationService.prototype.getMessages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.apiService.getMessages();
                return [2 /*return*/];
            });
        });
    };
    MessageNotificationService.prototype.subscribeToUnreadSmsCount = function (callback) {
        Assert.isNotNull(callback, "callback");
        return this.unreadSmsCount$.subscribe(callback);
    };
    MessageNotificationService.prototype.getUnreadSmsCount = function (orgLevelId, isMyMessage) {
        var _this = this;
        this.apiService.getUnreadCount(orgLevelId, isMyMessage).then(function (res) {
            _this.unreadSmsCount$.next(res);
        });
    };
    MessageNotificationService.prototype.subscribeToUnreadArchiveCount = function (callback) {
        Assert.isNotNull(callback, "callback");
        return this.unreadArchiveCount$.subscribe(callback);
    };
    MessageNotificationService.prototype.getUnreadArchiveCount = function (orgLevelId, isMyMessage) {
        var _this = this;
        this.apiService.getArchiveCount(orgLevelId, isMyMessage).then(function (res) {
            _this.unreadArchiveCount$.next(res);
        });
    };
    MessageNotificationService.prototype.subscribeToMessageStatusUpdate = function (callback) {
        Assert.isNotNull(callback, "callback");
        return this.messageStatusUpdate$.subscribe(callback);
    };
    MessageNotificationService.prototype.getMessageStatusUpdate = function (rowData) {
        var _this = this;
        this.apiService.getMessageStatusUpdate(rowData.employeeId).then(function (res) {
            _this.messageStatusUpdate$.next(rowData);
        });
    };
    return MessageNotificationService;
}());
export { MessageNotificationService };
