import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SсheduleHeaderMode } from '../../models/index';
import { CollapsibleSectionService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
var CustomCollapsibleSectionComponent = /** @class */ (function () {
    function CustomCollapsibleSectionComponent(sectionService) {
        this.expanded = false;
        this.emptyHeader = false;
        this.sectionService = sectionService;
        this.expandChanged = new EventEmitter();
    }
    Object.defineProperty(CustomCollapsibleSectionComponent.prototype, "hasCustomIcon", {
        get: function () {
            return _.isString(this.customIcon) && _.size(this.customIcon) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomCollapsibleSectionComponent.prototype, "hasCounter", {
        get: function () {
            return _.isFinite(this.counter);
        },
        enumerable: true,
        configurable: true
    });
    CustomCollapsibleSectionComponent.prototype.ngOnChanges = function (changes) {
        var self = this;
        _.forEach(changes, function (change, prop) {
            if (changes.previousValue !== change.currentValue) {
                self[prop] = change.currentValue;
            }
        });
    };
    CustomCollapsibleSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sectionSubscription = this.sectionService.subscribeToToggleSections(function (isExpand) {
            _this.expanded = isExpand;
        });
    };
    CustomCollapsibleSectionComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    CustomCollapsibleSectionComponent.prototype.onToggleSection = function () {
        this.expanded = !this.expanded;
        this.expandChanged.emit(this);
    };
    CustomCollapsibleSectionComponent.prototype.toggleIconTitle = function () {
        return this.expanded ? 'Close' : 'Open';
    };
    CustomCollapsibleSectionComponent.prototype.isShown = function () {
        return this.expanded;
    };
    CustomCollapsibleSectionComponent.prototype.getModeClass = function () {
        var cssClass = '';
        switch (this.mode) {
            case SсheduleHeaderMode.blue:
                cssClass = 'blue';
                break;
            case SсheduleHeaderMode.gray:
                cssClass = 'gray';
                break;
            case SсheduleHeaderMode.white:
                cssClass = 'white';
                break;
            default:
        }
        return cssClass;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CustomCollapsibleSectionComponent.prototype, "sectionSubscription", void 0);
    return CustomCollapsibleSectionComponent;
}());
export { CustomCollapsibleSectionComponent };
