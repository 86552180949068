import * as _ from 'lodash';
import { Directive, Input, Output, EventEmitter, OnInit, Inject, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { ServerFilterService } from '../../../../core/services/index';
import { ServerFilterDescriptor } from '../../../../core/models/index';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';

import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';

@Directive({
  selector: '[slxKendoGridServerFilterMenu]',
})
export class KendoGridServerFilterMenuDirective implements OnDestroy {

  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;

  @unsubscribe()
  private filterSubscription: Subscription;

  constructor(private filterService: FilterService, private serverFilterService: ServerFilterService) {
    this.filterSubscription = filterService.changes.subscribe((filter: CompositeFilterDescriptor) => {
      this.filterChanged(filter);
    });
  }

  public filterChanged(filter: CompositeFilterDescriptor): void {
    if (!filter || !filter.filters || !filter.filters.length) {
      this.removeFilter(this.column.field);
      return;
    }

    const serverFilter = kendoUtils.convertFromKendoComposeFilter(filter);
    this.serverFilterService.removeFilter(this.column.field);
    this.serverFilterService.addFilter(serverFilter);
    this.serverFilterService.applyFilter();
  }
  public removeFilter(filter: string): void {
    this.serverFilterService.removeFilter(filter);
    this.serverFilterService.applyFilter();
  }


  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }
}
