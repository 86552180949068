import { Directive, HostListener, HostBinding } from '@angular/core';

@Directive ({
    selector: '[slx-list-highlight]'
})
export class ListHightLightDirective {

  @HostBinding('class.highlight')
  private mouseover: boolean;

 // @HostBinding('state')
 // private hostState: any;

  @HostListener ('mouseover')
  private onMouseOver (): void {
     // if (!this.hostState.selected) {
        this.mouseover = true;
     // }
  }

  @HostListener('mouseout')
  private onMouseOut (): void {
    this.mouseover = false;
  }

}
