<kendo-grid class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [group]="gridState.state.group"
  [filterable]="true"
  [filter]="gridState.state.filter"
  [groupable]="{ showFooter: true, enabled: true }"
  (dataStateChange)="gridState.dataStateChange($event)"
>

  <kendo-grid-column title="Work Date" field="workDate" width="150">
    <ng-template kendoGridHeaderTemplate>
      Work Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <a (click)="navigateToScheduleEntry(dataItem)">
            {{dataItem.workDate | amDateFormat: appConfig.dateFormat}}
        </a>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column title="Employee" field="employeeName"  width="150">
    <ng-template kendoGridHeaderTemplate>
      Employee
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <a (click)="navigateToEmployee(dataItem)">
            {{dataItem.employeeName}}
        </a>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position" field="positionName"  width="200">
      <ng-template kendoGridHeaderTemplate>
        Position
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.positionName}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Unit" field="unitName"  width="200">
        <ng-template kendoGridHeaderTemplate>
          Unit
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.unitName}}
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
   </kendo-grid-column>

   <kendo-grid-column title="Shift" field="shiftName"  width="150">
    <ng-template kendoGridHeaderTemplate>
      Shift
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.shiftName}}
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Level" field="summaryMessageLevel" width="150">
      <ng-template kendoGridHeaderTemplate>
        Level
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span [ngClass]="{'level-info': dataItem.summaryMessageLevel==='Info', 'level-exception': dataItem.summaryMessageLevel==='Exception', 'level-error': dataItem.summaryMessageLevel==='Error' }">
          {{dataItem.summaryMessageLevel}}
        </span>
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
        </slx-kendo-grid-string-filter>
      </ng-template>
    </kendo-grid-column>

  <kendo-grid-column title="Message" field="text" width="400">
    <ng-template kendoGridHeaderTemplate>
      Message
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <span [ngClass]="{'level-info': dataItem.summaryMessageLevel==='Info', 'level-exception': dataItem.summaryMessageLevel==='Exception', 'level-error': dataItem.summaryMessageLevel==='Error' }">
        {{dataItem.text}}
      </span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

