import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { UserApplication } from '../../../state-model/models/index';
import { OrgLevelBreadcrumbModes } from '../../../organization/models/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe, mutableSelect } from '../../../core/decorators/index';
import { UserMenuPinService } from '../../../core/services/user-menu-pin/user-menu-pin.service';
import { ApplicationStateManagementService } from '../../services/application-state-management/application-state-management.service';
var LeftSidebarComponent = /** @class */ (function () {
    function LeftSidebarComponent(userMenuPinService, appStateManagementService) {
        this.userMenuPinService = userMenuPinService;
        this.appStateManagementService = appStateManagementService;
        this.orgLevelBreadcrumbsMenuMode = OrgLevelBreadcrumbModes.menu;
        this.app = new UserApplication(0, '');
    }
    LeftSidebarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appSubscription = this.app$.subscribe(function (app) {
            if (!_this.app.id && _.isNumber(app.id)) {
                _this.app = app;
            }
        });
        this.isOpenedSubscription = this.isLeftSidebarOpen$.subscribe(function (opened) {
            _this.isOpened = opened;
        });
    };
    LeftSidebarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    LeftSidebarComponent.prototype.pinMenu = function () {
        this.userMenuPinService.changeUserMenuPinStatus(true);
    };
    LeftSidebarComponent.prototype.unPinMenu = function () {
        this.userMenuPinService.changeUserMenuPinStatus(false);
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], LeftSidebarComponent.prototype, "isLeftSidebarOpen$", void 0);
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], LeftSidebarComponent.prototype, "app$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LeftSidebarComponent.prototype, "appSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LeftSidebarComponent.prototype, "isOpenedSubscription", void 0);
    return LeftSidebarComponent;
}());
export { LeftSidebarComponent };
