/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dynamic-policy-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../common/error-filters/errorMessage";
import * as i4 from "../../../../../common/components/input-decorator/input-decorator.component.ngfactory";
import * as i5 from "../../../../../common/components/input-decorator/input-decorator.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i8 from "../../../../../common/components/spinner/spinner.component";
import * as i9 from "./dynamic-policy-dialog.component";
import * as i10 from "../../../../../common/models/dialog-options";
import * as i11 from "../../../../../common/services/modal/modal.service";
var styles_DynamicPolicyDialogComponent = [i0.styles];
var RenderType_DynamicPolicyDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DynamicPolicyDialogComponent, data: {} });
export { RenderType_DynamicPolicyDialogComponent as RenderType_DynamicPolicyDialogComponent };
function View_DynamicPolicyDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [2, i2.SelectControlValueAccessor]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.policyName; _ck(_v, 3, 0, currVal_2); }); }
function View_DynamicPolicyDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "error text-danger"], ["errorMessage", ""], ["for", "error"]], null, null, null, null, null)), i1.ɵdid(1, 81920, [[5, 4]], 0, i3.ErrorMessageDirective, [i1.ElementRef], { for: [0, "for"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Policies can not have duplicate existing types"]))], function (_ck, _v) { var currVal_0 = "error"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DynamicPolicyDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "ln-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "col-lg-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "slx-input-decorator", [], null, null, null, i4.View_InputDecoratorComponent_0, i4.RenderType_InputDecoratorComponent)), i1.ɵdid(3, 311296, null, 4, i5.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵqud(603979776, 2, { fieldChild1: 0 }), i1.ɵqud(603979776, 3, { fieldChild2: 0 }), i1.ɵqud(603979776, 4, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 5, { errorMessages: 1 }), (_l()(), i1.ɵeld(8, 0, null, 0, 8, "select", [["slx-input", ""]], [[8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_v.context.$implicit.policyId = $event) !== false);
        ad = (pd_2 && ad);
    } if (("change" === en)) {
        var pd_3 = (_co.policyChanged(_v.context.$implicit, $event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 16384, null, 0, i2.SelectControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.SelectControlValueAccessor]), i1.ɵdid(11, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(13, 540672, [[3, 4]], 0, i5.SlxInputAltDirective, [[2, i2.NgControl], i1.ElementRef], null, null), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicPolicyDialogComponent_2)), i1.ɵdid(16, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, 3, 1, null, View_DynamicPolicyDialogComponent_3)), i1.ɵdid(18, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "col-lg-2 delete-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDeletePolicy(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-trash"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_8 = i1.ɵinlineInterpolate(1, "accrualPolicy", _v.context.index, ""); var currVal_9 = _v.context.$implicit.policyId; _ck(_v, 11, 0, currVal_8, currVal_9); var currVal_10 = _co.accrualPolicyLookup; _ck(_v, 16, 0, currVal_10); var currVal_11 = _v.context.$implicit.isError; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 13).className; var currVal_1 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 14).ngClassValid; var currVal_6 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 8, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_DynamicPolicyDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { ngForm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 22, "slx-spinner", [], null, null, null, i7.View_SpinnerComponent_0, i7.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i8.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 20, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(5, 4210688, [[1, 4], ["form", 4]], 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "modal-body mcontent"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Policies below:"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "row-item-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DynamicPolicyDialogComponent_1)), i1.ɵdid(13, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "clear"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "button", [["class", "theme-iconed-accent-button pull-left "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddPolicy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["class", "icon-button-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Policy"])), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r pull-left"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "button", [["class", "theme-button-apply pull-right btn-dynamic-policy"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Apply"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.dynamicAccrualPolicy; _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = _co.isPolicyDisabled; _ck(_v, 22, 0, currVal_9); }); }
export function View_DynamicPolicyDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-dynamic-policy-dialog", [["class", "component"]], null, null, null, View_DynamicPolicyDialogComponent_0, RenderType_DynamicPolicyDialogComponent)), i1.ɵdid(1, 114688, null, 0, i9.DynamicPolicyDialogComponent, [i1.ChangeDetectorRef, i10.DialogOptions, i11.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicPolicyDialogComponentNgFactory = i1.ɵccf("slx-dynamic-policy-dialog.component", i9.DynamicPolicyDialogComponent, View_DynamicPolicyDialogComponent_Host_0, {}, {}, []);
export { DynamicPolicyDialogComponentNgFactory as DynamicPolicyDialogComponentNgFactory };
