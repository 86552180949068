import { IndividualTimecardsDay } from './../../../models/timecards/individual-timecards-day';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { Employee, PayCycle } from '../../../../organization/models/index';
import { IColumnSettings, StateResetTypes, IControlState } from '../../../../core/models/index';
import {
  IndividualTimecardsContainer, IndividualTimecardsState,
  TimecardFlatRecord,
  TimecardsAction, TimecardsActionCmd, TimecardsColumnState
} from '../../../models/index';
import { IndividualTimecardsManagementService, TimecardsApiService, TimecardsStorageService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimecardsNavigationService, TimecardsSummaryNavigationService, ApprovalOvertimesNavigationService, StateManagementService, ComponentStateStorageService, ModalService } from '../../../../common/services/index';
import { TimecardsActions } from '../../../store/index';
import { EmployeeSectionAccrualsBalances } from '../../../../employee/employee-sections/models/index';
import { AccrualBalanceDialogOptions } from '../../../../organization/models/index';
import { AccrualBalanceDialogComponent } from '../../../../organization/components/index';
import { MessagesService } from '../../../../components-library/services/index';
import { OrgLevelType } from '../../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-individual-timecards',
  templateUrl: 'individual-timecards.component.html',
  styleUrls: ['individual-timecards.component.scss'],
  providers: [IndividualTimecardsManagementService, StateManagementService, MessagesService]
})
export class IndividualTimecardsComponent implements OnInit, OnDestroy {

  public get hideRates(): boolean {
    return this.m_hideRates;
  }

  public set hideRates(value: boolean) {
    this.m_hideRates = value;
    if (!!this.m_hideRates) {
      this.setIsShowPayRate(false);
    }
  }

  public get hasParentOrganizationId(): boolean {
    return this.managementService.orgLevel &&
      !!this.managementService.orgLevel.parentId;
  }

  public get isOrganizationOrgLevel(): boolean {
    return this.managementService.orgLevel &&
      this.managementService.orgLevel.type === OrgLevelType.organization;
  }

  public get hasPrevEmployee(): boolean {
    return this.managementService.hasPrevious();
  }

  public get hasNextEmployee(): boolean {
    return this.managementService.hasNext();
  }

  public state: {
    isLoading: boolean;
    isTableLoading?: boolean;
  };

  public container: IndividualTimecardsContainer;
  public activeEmployee: any;
  public globalState: IndividualTimecardsState;
  public appConfig: IApplicationConfig;
  public employeeId: number;
  public isShowPayRates: boolean;
  public currentPayCycle: PayCycle;
  public hideDetails: boolean;
  public hideCostCenterCode: boolean;
  public showHighPrecision: boolean;
  public hasRulesDefinitions: boolean;
  public isEmployeeChanged: boolean = false;
  public canSeePayroll: boolean = false;

  @unsubscribe()
  private routeSubscripion: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private loadStatusSubscription: Subscription;
  @unsubscribe()
  private employeeLoadedSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private accrualsSubscription: Subscription;

  private payCycleKey: string = 'payCycleKey';
  private resetBy: StateResetTypes = StateResetTypes.SessionEnd;
  private m_hideRates: boolean;
  public employeeIds: number[] = [];
  public orgLevelId: number;

  constructor(
    public managementService: IndividualTimecardsManagementService,
    private timecardsActions: TimecardsActions,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: TimecardsStorageService,
    private stateManagement: StateManagementService,
    private compStorageService: ComponentStateStorageService,
    private messagesService: MessagesService,
    public timecardsApiService: TimecardsApiService
  ) {
    this.appConfig = appConfig;
    this.state = {
      isLoading: false,
      isTableLoading: true
    };
    this.isShowPayRates = false;
    this.hideDetails = true;
    this.m_hideRates = false;
  }

  public ngOnInit(): void {
    this.stateManagement.init('IndividualTimecardsComponent');
    this.routeSubscripion = this.route.params
      .combineLatest(this.route.queryParams)
      .subscribe(([params, queryParams]) => {
        // As we are using combineLatest method, this subscribe event gets fire twice when when we update both the params
        // We do not get the proper employeeId and StartDate,EndDate combination in the first time call
        // Do not remove this check,
        if (+queryParams.employeeId != +params.employeeId)
          return;
        this.employeeId = +queryParams.employeeId || +params.employeeId;
        this.orgLevelId = +queryParams.orgLevelId ;
        let d1: string = queryParams.startDate;
        let d2: string = queryParams.endDate;

        if (!d1 || !d2) {
          this.currentPayCycle = this.restorePayCycle();
          this.managementService.onEmployeeChange(this.employeeId);
          return;
        }

        const payCycle: PayCycle = new PayCycle();
        payCycle.startDate = moment(d1, this.appConfig.linkDateFormat).toDate();
        payCycle.endDate = moment(d2, this.appConfig.linkDateFormat).toDate();

        this.currentPayCycle = payCycle;
        this.managementService.onQueryChange(this.employeeId, this.currentPayCycle);
        this.storageService.setCurrentEmpId(this.employeeId);
      });

    this.loadStatusSubscription = this.managementService.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });

    this.employeeLoadedSubscription = this.managementService.onEmployeeLoaded$
      .subscribe((employees: TimecardFlatRecord[]) => {
        if (employees.length > 0) {
          this.employeeIds = employees.map(item => item.emp.employeePosition.employee.id);
        }
      });

    this.onLoadedSubscription = this.managementService.onLoaded$
      .subscribe((container: IndividualTimecardsContainer) => {
        this.messagesService.resetClosed();
        this.container = container;
        this.hasRulesDefinitions = this.container.usedRulesDefinitions && this.container.usedRulesDefinitions.length > 0;
        this.hideRates = this.container.hideRates;
        this.currentPayCycle = container.payCycle;
        this.hideCostCenterCode = this.container.hideCostCenter;
        this.state.isTableLoading = false;
        this.activeEmployee = {
          id: this.container.employee.id,
          name: this.container.employee.name
        };
        this.setPayrollVisibleFlag(container);
      });

    this.accrualsSubscription = this.managementService.accrualsLoaded$
      .subscribe((accruals: EmployeeSectionAccrualsBalances) => {
        const accrualEmployee: AccrualBalanceDialogOptions = new AccrualBalanceDialogOptions(this.container.employee.name, this.container.primaryPosition.name, accruals.records);
        AccrualBalanceDialogComponent.openDialog(accrualEmployee, this.modalService);
      });

    this.stateSubscription = this.managementService.onStateChanged$
      .subscribe((state: IndividualTimecardsState) => {
        this.globalState = state;
        this.isShowPayRates = !!this.hideRates ? false : this.globalState.isShowPayRates;
        this.showHighPrecision = !!state.isShowHighPrecision;
      });
  }
  public setPayrollVisibleFlag(container : IndividualTimecardsContainer){
   if (
      container.isPayRollVisible &&
      container.employee.employeeType.name !== 'AFT' &&
      container.employee.employeeType.name !== 'APT' &&
      !container.employee.isAgencyEmployee
    ) {
      this.canSeePayroll = true;
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get employees(): any[] {
    return this.managementService.getEmployees();
  }

  public isColumnVisible(columnName: string): boolean {
    let columnState: TimecardsColumnState = this.globalState.empColumns;
    let column: IColumnSettings = columnState.columnsMap[columnName];
    if (!this.globalState.isShowPayRates && column.payload && column.payload.payRateRelated) {
      return false;
    }
    if (column.name === 'costCenterCode' && this.hideCostCenterCode) {
      return false;
    }

    if (column.name === 'shiftCodeName' && (!this.container || !this.container.shiftDiffBasedOnTime)) {
      return false;
    }
    return true;
  }

  public onPayCycleSelected(payCycle: PayCycle): void {
    if (this.state.isLoading || (this.currentPayCycle
      && moment(this.currentPayCycle.startDate).isSame(payCycle.startDate)
      && moment(this.currentPayCycle.endDate).isSame(payCycle.endDate))) {
      if (!this.isEmployeeChanged) {
        return;
      }
    }
    this.isEmployeeChanged = false;
    this.savePayCycle(payCycle);
    let navService: TimecardsNavigationService = new TimecardsNavigationService(this.router, this.route);
    navService.NavigateToIndividualTimecards(this.employeeId, payCycle.startDate, payCycle.endDate);
  }

  public onVisibilityPayRatesChanged($event: boolean): void {
    this.setIsShowPayRate($event);
  }

  public onVisibilityHighPrecisionChanged($event: boolean): void {
    this.setIsShowHighPrecision($event);
  }

  public onExportToExcel(): void {
    let action: TimecardsAction = new TimecardsAction();
    action.cmd = TimecardsActionCmd.excelExport;
    this.managementService.onActionCmd(action);
  }

  public onExportToPdf(): void {
    let action: TimecardsAction = new TimecardsAction();
    action.cmd = TimecardsActionCmd.pdfExport;
    this.managementService.onActionCmd(action);
  }

  public onViewSummary(): void {
    let navService: TimecardsSummaryNavigationService = new TimecardsSummaryNavigationService(this.router, this.route);
    navService.NavigateToIndividualTimecardSummary(this.employeeId, this.managementService.currentPayCycle.startDate, this.managementService.currentPayCycle.endDate);
  }

  public onShowAccruals(): void {
    this.managementService.loadAccruals(this.container.employee.id);
  }

  public onRecalculateTimecards(): void {
    this.managementService.recalculateTimecards();
  }

  public onApproveTimecards(): void {
    this.managementService.approveTimecards();
  }

  public onUnapproveTimecards(): void {
    this.managementService.unapproveTimecards();
  }

  public onNavByEmployee(isNext: boolean): void {
    this.state.isTableLoading = true;
    const newEmployeeId = this.managementService.getEmployeeIdByIndex(isNext);

    if (_.isNumber(newEmployeeId) && newEmployeeId > -1) {
      this.container = undefined;
      this.navigateToIndividualTimecards(newEmployeeId);
    } else {
      this.state.isTableLoading = false;
    }
  }

  public onEmployeeChosen(employee: Employee): void {
    if (employee && _.isNumber(employee.id) && employee.id > -1 &&
      employee.id !== this.employeeId) {
      this.state.isTableLoading = true;
      this.navigateToIndividualTimecards(employee.id);
    }
  }

  public navigateToIndividualTimecards(employeeId: number) {
    // We just need to change the emlployee.
    // A setter function in paycycle component is available which reloads the paycycle select the first cycle and reload the page.
    this.isEmployeeChanged = true;
    this.employeeId = employeeId;
  }

  public selectAll(event: MouseEvent): void {
    _.forEach(this.globalState.empColumns.columns, (column: IColumnSettings) => {
      column.displayed = true;
    });
    this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
  }
  public clearAll(event: MouseEvent): void {
    _.forEach(this.globalState.empColumns.columns, (column: IColumnSettings) => {
      column.displayed = false;
    });
    this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
  }

  public selectAllPayCodes(event: MouseEvent): void {
    _.forEach(this.globalState.payCodeColumns.columns, (column: IColumnSettings) => {
      column.displayed = true;
    });
    this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
  }

  public clearAllPayCodes(event: MouseEvent): void {
    _.forEach(this.globalState.payCodeColumns.columns, (column: IColumnSettings) => {
      column.displayed = false;
    });
    this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
  }

  public onChange(event: MouseEvent, column: IColumnSettings): any {
    this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
  }

  public onApproveOvertime(): void {
    let navService: ApprovalOvertimesNavigationService = new ApprovalOvertimesNavigationService(this.router, this.route);
    navService.navigateToOvertimeApproval(this.currentPayCycle.startDate, this.currentPayCycle.endDate, [this.container.employee.id]);
  }

  public get isLocked(): boolean {
    return this.container
      && (this.container.isOrganizationPayrollLocked
        || this.container.isPayCycleLocked
        || _.some(this.container.records, (day: IndividualTimecardsDay) => day.isLocked));
  }

  private setIsShowPayRate(value: boolean): void {
    this.isShowPayRates = !!this.hideRates ? false : value;

    if (!!this.globalState) {
      this.globalState.isShowPayRates = this.isShowPayRates;
    }

    this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
  }


  private setIsShowHighPrecision(value: boolean): void {
    if (!!this.globalState) {
      this.globalState.isShowHighPrecision = value;
    }

    this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
  }

  private savePayCycle(payCycle: PayCycle): void {
    const startDate: string = payCycle.startDate.toISOString();
    const endDate: string = payCycle.endDate.toISOString();
    const isApproved: boolean = payCycle.isApproved;

    this.compStorageService.setControlState(
      this.stateManagement.componentKey,
      this.payCycleKey,
      { value: { startDate, endDate, isApproved } },
      this.resetBy
    );
  }

  private restorePayCycle(): PayCycle {
    let state: IControlState = this.compStorageService.getControlState(this.stateManagement.componentKey, this.payCycleKey);
    const value: { startDate: Date, endDate: Date, isApproved: boolean } = _.get(state, 'value');
    if (_.isObject(value)) {
      const payCycle: PayCycle = new PayCycle();
      payCycle.startDate = new Date(value.startDate);
      payCycle.endDate = new Date(value.endDate);
      payCycle.isApproved = value.isApproved;
      return payCycle;
    }

    return null;
  }
  public activateNGPSSO(){
    this.timecardsApiService.navigateSSO('nextgenpayroll', this.orgLevelId);
  }
}
