import { Injectable } from '@angular/core';
import { WeatherApiService } from '../weather.api.service';
import { iconCodes, IconCodeType } from './open-weather-map-to-weather-icons';
import { WeatherQueryParams, TemperatureScale } from '../../../weather.interfaces';
import { IOpenWeatherMapCurrentWeatherResponse,
          ICurrentWeather,
          IOpenWeatherMapForecastResponse,
          IForecast,
          IOpenWeatherMapForecastResponseElement,
          IOpenWeatherMapLocationRequest
        } from '../../../models/current-weather.model';

@Injectable()
export class OpenWeatherMapApiService {
  iconCodes: IconCodeType;
  constructor() {
    this.iconCodes = iconCodes;
  }

  public mapQueryParams(
    params: WeatherQueryParams
  ): IOpenWeatherMapLocationRequest {
    const mapped: any = {
      id: params.cityId,
      q: params.cityName,
      lat: params.latLng ? params.latLng.lat : undefined,
      lon: params.latLng ? params.latLng.lng : undefined,
      zip: params.zipCode,
      units: params.units ? this.mapUnits(params.units) : undefined,
      lang: params.lang
    };
    return mapped;
  }

  public mapCurrentWeatherResponse(
    response: IOpenWeatherMapCurrentWeatherResponse
  ): ICurrentWeather {
    if (!response) {
      return <ICurrentWeather>{};
    }
    const weather: ICurrentWeather = {
      temp: response.main.temp,
      pressure: response.main ? response.main.pressure : undefined,
      humidity: response.main ? response.main.humidity : undefined,
      minTemp:
        response.main && response.main.temp
          ? response.main.temp_min
          : undefined,
      maxTemp:
        response.main && response.main.temp
          ? response.main.temp_max
          : undefined,
      sunrise: response.sys ? response.sys.sunrise : undefined,
      sunset: response.sys ? response.sys.sunset : undefined,
      location: response.name,
      iconUrl: '',
      iconClass: this.mapResponseToIconClass(response),
      description: response.weather[0].description,
      wind: {
        deg: response.wind.deg,
        speed: response.wind.speed
      }
    };
    return weather;
  }

  protected mapForecastResponse(
    response: IOpenWeatherMapForecastResponse
  ): IForecast[] {
    if (!response) {
      return <IForecast[]>[];
    }
    const city = response.city;
    return response.list.map((el: IOpenWeatherMapForecastResponseElement) => {
      const forecast: IForecast = {
        temp: el.main.temp,
        pressure: el.main.pressure,
        humidity: el.main.humidity,
        minTemp: el.main.temp_min,
        maxTemp: el.main.temp_max,
        location: city.name,
        iconClass: this.mapResponseToIconClass(el),
        description: el.weather[0].description,
        data: new Date(el.dt * 1000),
        wind: {
          deg: el.wind.deg,
          speed: el.wind.speed
        }
      };
      return forecast;
    });
  }

  protected mapResponseToIconUrl(
    response: IOpenWeatherMapForecastResponseElement
  ): string {
    return `https://openweathermap.org/img/w/${response.weather[0].icon}.png`;
  }

  protected mapResponseToIconClass(
    response:
      | IOpenWeatherMapCurrentWeatherResponse
      | IOpenWeatherMapForecastResponseElement
  ): string {
    const code = response.weather[0].id;
    const prefix = 'wi wi-';
    let icon = iconCodes[code].icon;
    // If we are not in the ranges mentioned above, add a day/night prefix.
    if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
      icon = 'day-' + icon;
    }
    icon = prefix + icon;
    return icon;
  }

  protected setTokenKey(): string {
    return 'APPID';
  }

  private mapUnits(unit: TemperatureScale): string {
    switch (unit) {
      case TemperatureScale.CELCIUS:
        return 'metric';
      case TemperatureScale.FAHRENHEIT:
        return 'imperial';
      case TemperatureScale.KELVIN:
        return '';
      default:
        return 'metric';
    }
  }
}
