<slx-spinner [show]="state.isLoading">
  <div class="slx-full-height">
    <div *ngIf="!state.isBudgetGroupEditMode && !state.isRestoreBudgetMode && !state.isBudgetAdjustMode" class="slx-high-box">
      <slx-budget-header class="slx-content-toolbar-indents slx-auto-height"></slx-budget-header>
      <div class="slx-high-box__body slx-main-content-indents">
        <slx-budget-grid></slx-budget-grid>
      </div>
    </div>
    <div *ngIf="state.isBudgetGroupEditMode" class="slx-full-height slx-main-content-indents">
      <slx-budgeted-group-editor [startDate]="budget?.startDate" [orgLevelId]="selectedOrganizationOrgLevelId" (finishEditing)="groupsfinishEditing($event)"></slx-budgeted-group-editor>
    </div>
    <div *ngIf="state.isRestoreBudgetMode" class="slx-full-height slx-main-content-indents">
      <slx-budget-history [orgLevelId]="selectedOrganizationOrgLevelId" (finishEditing)="restorefinishEditing($event)"></slx-budget-history>
    </div>
    <div *ngIf="state.isBudgetAdjustMode" class="slx-full-height slx-main-content-indents">
      <slx-budget-census-adjust [startDate]="budget.startDate" [budgetCensus]="budget.census" (finishEditing)="budgetCensusAdjustfinishEditing($event)"></slx-budget-census-adjust>
    </div>
  </div>
</slx-spinner>
