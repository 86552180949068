import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Lookup } from '../../../../../organization/models/index';
import { WindowRef } from '../../../../../core/services/window/window-ref.model';
import { EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { LoaType, LoaRequest } from '../../../models/index';
import { unsubscribe } from '../../../../../core/decorators';
var LMCreateAbsenceComponent = /** @class */ (function () {
    function LMCreateAbsenceComponent(manService, router, route, win) {
        this.manService = manService;
        this.router = router;
        this.route = route;
        this.win = win;
        this.absenceType = null;
        this.hasAbsenceTypes = true;
        this.empNavService = new EmployeeSectionNavigationService(this.router, this.route);
    }
    Object.defineProperty(LMCreateAbsenceComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canChange;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreateAbsenceComponent.prototype, "canDeleteRequest", {
        get: function () {
            return this.manService.canDeleteRequest && !this.manService.isCreatingNew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreateAbsenceComponent.prototype, "isCreatingNew", {
        get: function () {
            return this.manService.isCreatingNew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreateAbsenceComponent.prototype, "isContinous", {
        get: function () {
            return this.manService.isContinuous;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCreateAbsenceComponent.prototype, "isIntermittent", {
        get: function () {
            return this.manService.isIntermittent;
        },
        enumerable: true,
        configurable: true
    });
    LMCreateAbsenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setRequestData();
        this.loadSubscription = this.manService.subscribeToChangedRequest(function (r) {
            _this.setRequestData();
        });
    };
    LMCreateAbsenceComponent.prototype.getSelectedTab = function (tabType) {
        if (this.manService.loaRequest.loaRequestTabs.valueOf() === tabType) {
            return true;
        }
        return false;
    };
    LMCreateAbsenceComponent.prototype.onClickEmployee = function () {
        var urlTree = this.empNavService.getUrlTree(this.empId, true);
        var url = this.win.nativeWindow.document.location.origin + "/#/apps/common" + urlTree.toString();
        this.win.nativeWindow.open(url, '_blank');
    };
    LMCreateAbsenceComponent.prototype.onClickDelete = function (acceptPopover) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acceptPopover.hide();
                        this.manService.changeLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.manService.deleteLoaRequest()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.manService.closePopup();
                        this.manService.loadRequests();
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    LMCreateAbsenceComponent.prototype.onClickCancel = function (acceptPopover) {
        acceptPopover.hide();
    };
    LMCreateAbsenceComponent.prototype.onChangeAbsence = function (type) {
        this.manService.setAbsenceType(type);
    };
    LMCreateAbsenceComponent.prototype.onChangeReason = function (reason) {
        this.manService.setAbsenceReason(reason);
    };
    LMCreateAbsenceComponent.prototype.onChangeRequestDate = function (date) {
        this.manService.setRequestDate(date);
    };
    LMCreateAbsenceComponent.prototype.setRequestData = function () {
        this.assignRequestDate();
        this.assignAbsenceTypes();
        this.assignReason();
        this.assignEmployee();
    };
    LMCreateAbsenceComponent.prototype.assignAbsenceTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var absenceType, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        absenceType = this.manService.getAbsenceType() || new LoaType();
                        _a = this;
                        return [4 /*yield*/, this.loadAbsenceTypes()];
                    case 1:
                        _a.absenceTypes = _b.sent();
                        this.absenceType = _.find(this.absenceTypes, function (a) { return a.name === absenceType.name; });
                        this.hasAbsenceTypes = _.isArray(this.absenceTypes) && _.size(this.absenceTypes) > 0;
                        return [2 /*return*/];
                }
            });
        });
    };
    LMCreateAbsenceComponent.prototype.assignReason = function () {
        this.reason = this.manService.getAbsenceReason() || '';
    };
    LMCreateAbsenceComponent.prototype.assignRequestDate = function () {
        this.requestDate = this.manService.getRequestDate() || new Date();
    };
    LMCreateAbsenceComponent.prototype.assignEmployee = function () {
        var req = this.manService.loaRequest;
        this.empId = req.empId;
        this.empName = req.empName;
        this.posName = req.positionName;
        this.depName = req.departmentName;
        this.orgName = req.organizationName;
    };
    LMCreateAbsenceComponent.prototype.loadAbsenceTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.manService.loadAbsenceTypes()
                        .catch(function (err) {
                        console.error(err);
                        return [];
                    })];
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LMCreateAbsenceComponent.prototype, "loadSubscription", void 0);
    return LMCreateAbsenceComponent;
}());
export { LMCreateAbsenceComponent };
