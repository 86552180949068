import { Injectable } from '@angular/core';
import { CacheService, CacheStoragesEnum } from 'ng2-cache';
import { ICacheKey } from '../../models/cache/icache-key';
import * as _ from 'lodash';

@Injectable()
export class CacheUtilService {
  private cacheService: CacheService;

  constructor() {
    this.cacheService = new CacheService(null).useStorage(CacheStoragesEnum.MEMORY);
  }

  public get(key: ICacheKey): any {
    const resolvedKey: string = this.makeKey(key);

    return this.cacheService.get(resolvedKey);
  }

  public put(key: ICacheKey, value: any, exp: number = 0): boolean {
    const resolvedKey: string = this.makeKey(key);
    const minutes = exp === 0 ? Number.MAX_VALUE : exp * 60;

    return this.cacheService.set(resolvedKey, value, { maxAge: minutes });
  }

  public delete(key: ICacheKey, value: any): any {
    const resolvedKey: string = this.makeKey(key);

    return this.cacheService.remove(resolvedKey);
  }

  public clear(): void {
    this.cacheService.removeAll();
  }

  private makeKey({ key }: ICacheKey): string {
    let hash = this.makeHash(key);

    return `_${hash}`;
  }

  private makeHash(key: string): number {
    let hash = 0;
    if (key.length === 0) return hash;

    for (let i = 0, length = key.length; i < length; i++) {
      let charCode = key.charCodeAt(i);
      hash  = ((hash << 5) - hash) + charCode;
      hash |= 0;
    }

    return hash;
  }
}
