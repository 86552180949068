/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-changes-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./confirm-changes-dialog.component";
import * as i3 from "../../models/dialog-options";
import * as i4 from "../../services/modal/modal.service";
import * as i5 from "../../models/change-management/changes-dialog-options";
var styles_ConfirmChangesDialogComponent = [i0.styles];
var RenderType_ConfirmChangesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmChangesDialogComponent, data: {} });
export { RenderType_ConfirmChangesDialogComponent as RenderType_ConfirmChangesDialogComponent };
export function View_ConfirmChangesDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "modal-content-resizable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "bootbox-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.changesOptions.questionMessage; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.changesOptions.warningMessage; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.changesOptions.continueButtonText; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.changesOptions.cancelButtonText; _ck(_v, 11, 0, currVal_3); }); }
export function View_ConfirmChangesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-confirm-changes-dialog", [], null, null, null, View_ConfirmChangesDialogComponent_0, RenderType_ConfirmChangesDialogComponent)), i1.ɵdid(1, 49152, null, 0, i2.ConfirmChangesDialogComponent, [i3.DialogOptions, i4.ModalService, i5.ChangesDialogOptions], null, null)], null, null); }
var ConfirmChangesDialogComponentNgFactory = i1.ɵccf("slx-confirm-changes-dialog", i2.ConfirmChangesDialogComponent, View_ConfirmChangesDialogComponent_Host_0, {}, {}, []);
export { ConfirmChangesDialogComponentNgFactory as ConfirmChangesDialogComponentNgFactory };
