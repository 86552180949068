import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appConfig } from '../../../../../app.config';
import { WindowRef } from '../../../../../core/services/window/window-ref.model';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { ReadFile, Lookup, PerformanceReviewCode, PmAttachment, PmTemplate } from '../../../../../organization/models/index';
import { OrgLevel } from '../../../../../state-model/models/index';
import { PmReviewEntry } from '../../../models/index';
import { PmCreationManagementService } from '../../../services/index';
import { NgModel } from '@angular/forms';
import { pmConfig } from '../../../performance-management.config';
var PmReviewFormComponent = /** @class */ (function () {
    function PmReviewFormComponent(manService, router, route, win) {
        this.manService = manService;
        this.router = router;
        this.route = route;
        this.win = win;
        this.reviewDate = new Date();
        this.attachments = [];
        this.templates = [];
        this.templateAttachments = [];
        this.loadingReviewData = true;
        this.review = new PmReviewEntry();
        this.appConfig = appConfig;
        this.pmConfig = pmConfig;
        this.subscriptions = {};
        this.empNavService = new EmployeeSectionNavigationService(this.router, this.route);
        this.minDate = moment(pmConfig.settings.minDateString).toDate();
    }
    Object.defineProperty(PmReviewFormComponent.prototype, "empId", {
        get: function () {
            return this.review.employee.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "empName", {
        get: function () {
            return this.review.employee.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "posName", {
        get: function () {
            return this.review.employee.positionName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "depName", {
        get: function () {
            return this.review.employee.departmentName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "orgName", {
        get: function () {
            return this.review.employee.organizationName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "isEditMode", {
        get: function () {
            return this.review.isEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "isCreatingNew", {
        get: function () {
            return this.review.isNew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "canEdit", {
        get: function () {
            return this.review.canEdit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmReviewFormComponent.prototype, "canDelete", {
        get: function () {
            return this.review.canDelete;
        },
        enumerable: true,
        configurable: true
    });
    PmReviewFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.review = this.manService.subscribeToReviewChanged(function (review) {
            _this.review = review;
            _this.loadingReviewData = false;
            if (!_this.review.reviewDate) {
                _this.review.reviewDate = new Date();
            }
            _this.onChangeReviewDate(_this.review.reviewDate);
            _this.reviewType = _this.review.reviewType;
            _this.lastReviewDate = _this.review.lastReviewDate;
            _this.rating = _this.review.rating;
            _this.ratingInPopover = _this.review.rating;
            _this.reviewTypeInPopover = _this.review.reviewType;
            _this.comment = _this.review.comment;
            _this.reviewedBy = _this.review.reviewedBy;
            _this.attachments = _this.review.attachments;
            _this.templates = _this.review.templates;
            _this.templateAttachments = _.map(_this.templates, function (x) { return x.attachment; });
            _this.templateAttachmentsCount = _.size(_this.templateAttachments);
            var addedFiles = _this.manService.getAddedFiles();
            _this.attachmentsCount = _.size(addedFiles) + _.size(_this.attachments);
            _this.isComplete = _this.review.status === 'Completed';
        });
        this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel(function (o) {
            _this.orgLevel = o;
        });
        this.subscriptions.ratings = this.manService.subscribeToRatingsLoaded(function (o) {
            _this.ratingLookup = o;
        });
        this.subscriptions.types = this.manService.subscribeToTypesLoaded(function (o) {
            _this.typesLookup = o;
        });
    };
    PmReviewFormComponent.prototype.ngOnDestroy = function () { };
    PmReviewFormComponent.prototype.onClickEmployee = function () {
        this.manService.navigateToUserProfile(this.empId);
    };
    PmReviewFormComponent.prototype.onClickDelete = function (isDelete, acceptPopover) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                acceptPopover.hide();
                if (isDelete) {
                    this.manService.deleteReview();
                }
                return [2 /*return*/];
            });
        });
    };
    PmReviewFormComponent.prototype.onClickComplete = function (isComplete, acceptPopover) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                acceptPopover.hide();
                if (isComplete) {
                    this.manService.completeReview();
                }
                return [2 /*return*/];
            });
        });
    };
    PmReviewFormComponent.prototype.onClickOk = function (isComplete, acceptPopover) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                acceptPopover.hide();
                if (isComplete) {
                    this.rating = this.ratingInPopover;
                    this.reviewType = this.reviewTypeInPopover;
                }
                else {
                    this.onChangeReviewTypes();
                    this.onChangeRating();
                    this.resetInput();
                }
                return [2 /*return*/];
            });
        });
    };
    PmReviewFormComponent.prototype.resetInput = function () {
        this.reviewTypeInPopover = null;
        this.ratingInPopover = null;
    };
    PmReviewFormComponent.prototype.onChangeReviewDate = function (date) {
        if (this.reviewDatePicker) {
            this.manService.setReviewDate(date);
            this.manService.setFormValidity(this.reviewDatePicker.valid);
        }
    };
    PmReviewFormComponent.prototype.onChangeReviewTypes = function () {
        this.manService.setReviewType(this.reviewType);
    };
    PmReviewFormComponent.prototype.onChangeRating = function () {
        this.manService.setRating(this.rating);
    };
    PmReviewFormComponent.prototype.onChangeComment = function () {
        this.manService.setComment(this.comment);
    };
    PmReviewFormComponent.prototype.onAddedFiles = function (files) {
        this.manService.saveFiles(files);
    };
    PmReviewFormComponent.prototype.onDeletedFile = function (file) {
        this.manService.deleteFile(file);
    };
    PmReviewFormComponent.prototype.onDeletedAttachment = function (attachment) {
        this.manService.deleteAttachment(attachment.id);
    };
    PmReviewFormComponent.prototype.onDownloadAttachment = function (attachment) {
        this.manService.downloadAttachment(attachment.id);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmReviewFormComponent.prototype, "subscriptions", void 0);
    return PmReviewFormComponent;
}());
export { PmReviewFormComponent };
