import * as tslib_1 from "tslib";
import { NgForm } from '@angular/forms';
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { EmployeeValidatorAdapter, EmployeeDefinitionsApiService } from '../../../../../../organization/services/index';
import * as _ from 'lodash';
import { NotificationsApiService } from '../../../../../../portal/services';
var AddEmployeePersonalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddEmployeePersonalComponent, _super);
    function AddEmployeePersonalComponent(addEmployeeWizardActions, employeeActivitiesValidatorAdapter, employeeDefinitionsApiService, notificationApiService) {
        var _this = _super.call(this, addEmployeeWizardActions) || this;
        _this.notificationApiService = notificationApiService;
        _this.optInTemplateText = '';
        _this.isOptInCheckboxEnabled = false;
        _this.isMessageCenterEnabled = false;
        _this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
        _this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        return _this;
    }
    AddEmployeePersonalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe(function (model) {
            _this.onWizard(model);
            _this.checkMessageCenterAccess();
            _this.getSMSTemplate();
        });
    };
    AddEmployeePersonalComponent.prototype.ngAfterViewInit = function () {
        this.addChangesSubscriptionToForm(this.mainForm);
    };
    Object.defineProperty(AddEmployeePersonalComponent.prototype, "emailValidation", {
        get: function () {
            return EmployeeValidatorAdapter.emailValidation;
        },
        enumerable: true,
        configurable: true
    });
    AddEmployeePersonalComponent.prototype.checkMessageCenterAccess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orgLevelId, access;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        orgLevelId = _.get(this.addEmployeeModel, 'organization.orgLevelId');
                        return [4 /*yield*/, this.notificationApiService.getIconAccessCheck(orgLevelId)];
                    case 1:
                        access = _a.sent();
                        this.isMessageCenterEnabled = access.isMessageCenterEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddEmployeePersonalComponent.prototype.getSMSTemplate = function () {
        var _this = this;
        var orgLevelId = _.get(this.addEmployeeModel, 'organization.orgLevelId');
        this.employeeDefinitionsApiService.getSmsTemplate(orgLevelId)
            .then(function (template) {
            _this.optInTemplateText = template;
        }).catch(function (reason) {
        });
    };
    AddEmployeePersonalComponent.prototype.onMobileNoChange = function () {
        if (this.addEmployeeModel && (!_.isNull(this.addEmployeeModel.mobilePhone) && this.addEmployeeModel.mobilePhone != '')) {
            this.isOptInCheckboxEnabled = true;
            this.addEmployeeModel.optInRequest = true;
        }
        else {
            this.isOptInCheckboxEnabled = false;
            this.addEmployeeModel.optInRequest = false;
        }
    };
    tslib_1.__decorate([
        mutableSelect(['addEmployeeWizard', 'model']),
        tslib_1.__metadata("design:type", Observable)
    ], AddEmployeePersonalComponent.prototype, "addEmployeeWizard$", void 0);
    return AddEmployeePersonalComponent;
}(AddEmployeeBasicComponent));
export { AddEmployeePersonalComponent };
