import { IServerValidationResult } from '../../../common/validators/common-validators-models';

export class CCNNumberValidation implements IServerValidationResult {
    public isValid: boolean;
    public isReadyForValidation: boolean;
    public errorMessage: string;
    public value: string;
}

export interface ICCNNumberValidation {
    isValid: boolean;
    isReadyForValidation: boolean;
    errorMessage: string;
    value: string;
}
