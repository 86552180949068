/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./open-shift-management-schedule-cycle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../open-shift-management-schedule-cycle-details/open-shift-management-schedule-cycle-details.component.ngfactory";
import * as i4 from "../open-shift-management-schedule-cycle-details/open-shift-management-schedule-cycle-details.component";
import * as i5 from "angular2-moment/date-format.pipe";
import * as i6 from "./open-shift-management-schedule-cycle.component";
import * as i7 from "../../../../core/services/calendar-data/calendar-data.service";
import * as i8 from "../../../services/open-shift-management/open-shift-management-management.service";
var styles_OpenShiftManagementScheduleCycleComponent = [i0.styles];
var RenderType_OpenShiftManagementScheduleCycleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OpenShiftManagementScheduleCycleComponent, data: {} });
export { RenderType_OpenShiftManagementScheduleCycleComponent as RenderType_OpenShiftManagementScheduleCycleComponent };
function View_OpenShiftManagementScheduleCycleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "weekday-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "theme-lg-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.startOfDay, _co.appConfig.dayShortNameWeekDayFormat)); _ck(_v, 2, 0, currVal_0); }); }
function View_OpenShiftManagementScheduleCycleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "table-cell"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "selected-cell": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-open-shift-management-schedule-cycle-details", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSummaryItemSelectedHandler(((_v.context.$implicit == null) ? null : _v.context.$implicit.firstOrDefault)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_OpenShiftManagementScheduleCycleDetailsComponent_0, i3.RenderType_OpenShiftManagementScheduleCycleDetailsComponent)), i1.ɵdid(5, 114688, null, 0, i4.OpenShiftManagementScheduleCycleDetailsComponent, [], { detailsWrapper: [0, "detailsWrapper"] }, null)], function (_ck, _v) { var currVal_0 = "table-cell"; var currVal_1 = _ck(_v, 3, 0, ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.firstOrDefault == null) ? null : _v.context.$implicit.firstOrDefault.selected))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.firstOrDefault); _ck(_v, 5, 0, currVal_2); }, null); }
function View_OpenShiftManagementScheduleCycleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "table-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenShiftManagementScheduleCycleComponent_3)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.days; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OpenShiftManagementScheduleCycleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DateFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "calendar-host"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "table-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenShiftManagementScheduleCycleComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "table-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OpenShiftManagementScheduleCycleComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dayColumns; _ck(_v, 6, 0, currVal_0); var currVal_1 = ((_co.summaryDetailsSet == null) ? null : _co.summaryDetailsSet.weeklyData); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_OpenShiftManagementScheduleCycleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-open-shift-management-schedule-cycle", [], null, null, null, View_OpenShiftManagementScheduleCycleComponent_0, RenderType_OpenShiftManagementScheduleCycleComponent)), i1.ɵdid(1, 245760, null, 0, i6.OpenShiftManagementScheduleCycleComponent, [i7.CalendarDataService, i8.OpenShiftManagementManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OpenShiftManagementScheduleCycleComponentNgFactory = i1.ɵccf("slx-open-shift-management-schedule-cycle", i6.OpenShiftManagementScheduleCycleComponent, View_OpenShiftManagementScheduleCycleComponent_Host_0, { summaryDetailsSet: "summaryDetailsSet" }, { onSummaryItemSelected: "onSummaryItemSelected", isPartnerShiftCountUpdated: "isPartnerShiftCountUpdated" }, []);
export { OpenShiftManagementScheduleCycleComponentNgFactory as OpenShiftManagementScheduleCycleComponentNgFactory };
