import { Provider, ElementRef, OnInit } from '@angular/core';
import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeEditPhoto } from '../../../models/employee-edit-photo';
import { PhotoCropperComponent } from '../../../../../common/components/photo-cropper/photo-cropper.component';
import { employeeConfig } from '../../../employee.config';
import * as _ from 'lodash';
var EmployeeEditPhotoDialogComponent = /** @class */ (function () {
    function EmployeeEditPhotoDialogComponent(employee, options, modalService) {
        this.employee = employee;
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
        this.fileType = employeeConfig.photo.type;
        this.cropperOptions = employeeConfig.cropper;
        this.errors = { uploadFormat: false, imageFormat: false, photoError: false };
        this.photoUploaded = false;
        this.canMakePhoto = false;
        this.errorMessage = '';
        this.setupPhotoEditor();
    }
    EmployeeEditPhotoDialogComponent.openDialog = function (req, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 550;
        dialogOptions.width = 850;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EmployeeEditPhoto,
                useValue: req
            }
        ];
        var dialog = modalService.globalAnchor.openDialog(EmployeeEditPhotoDialogComponent, 'Edit employee photo', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.getCroppedImage());
        });
        return dialog;
    };
    EmployeeEditPhotoDialogComponent.prototype.ngOnInit = function () {
        var maxWidth = this.getWidth(this.croppieContainer.nativeElement);
        var maxHeight = this.getHeight(this.croppieContainer.nativeElement);
        if (this.cropperOptions.boundary.width > maxWidth) {
            this.cropperOptions.boundary.width = maxWidth;
        }
        if (this.cropperOptions.boundary.height > maxHeight) {
            this.cropperOptions.boundary.height = maxHeight;
        }
    };
    Object.defineProperty(EmployeeEditPhotoDialogComponent.prototype, "fileTypesFieldFormat", {
        get: function () {
            return this.fileType.join(',');
        },
        enumerable: true,
        configurable: true
    });
    EmployeeEditPhotoDialogComponent.prototype.onFileClick = function (event) {
        var elem = event.target;
        elem.value = '';
        this.resetError();
    };
    EmployeeEditPhotoDialogComponent.prototype.onFileChange = function (event) {
        var files = _.get(event, 'target.files');
        var file = files[0];
        if (!file || !_.includes(this.fileType, file.type)) {
            this.setError('uploadFormat');
            return;
        }
        this.readImage(file);
    };
    EmployeeEditPhotoDialogComponent.prototype.onApply = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeEditPhotoDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeEditPhotoDialogComponent.prototype.onMakePhoto = function () {
        this.canMakePhoto = true;
        this.canCropping = false;
    };
    EmployeeEditPhotoDialogComponent.prototype.onMadePhoto = function (dataUrl) {
        this.canMakePhoto = false;
        this.employee.photo.src = dataUrl;
        this.photoUploaded = this.canCropping = true;
        this.resetError();
    };
    EmployeeEditPhotoDialogComponent.prototype.onErrorPhoto = function (errorText) {
        this.canMakePhoto = false;
        this.errorMessage = errorText;
        this.setError('photoError');
    };
    EmployeeEditPhotoDialogComponent.prototype.getCroppedImage = function () {
        if (this.photoCropper) {
            return this.photoCropper.getCroppedImage();
        }
        return Promise.resolve('');
    };
    EmployeeEditPhotoDialogComponent.prototype.setError = function (errorType) {
        this.errors.uploadFormat = false;
        this.errors.imageFormat = false;
        this.errors.photoError = false;
        switch (errorType) {
            case 'uploadFormat':
                this.errors.uploadFormat = true;
                break;
            case 'imageFormat':
                this.errors.imageFormat = true;
                break;
            case 'photoError':
                this.errors.photoError = true;
                break;
        }
    };
    EmployeeEditPhotoDialogComponent.prototype.resetError = function () {
        this.errors.uploadFormat = false;
        this.errors.imageFormat = false;
        this.errors.photoError = false;
    };
    EmployeeEditPhotoDialogComponent.prototype.isValidPhoto = function (width, height) {
        return width > employeeConfig.photo.minWidth && height > employeeConfig.photo.minHeight;
    };
    EmployeeEditPhotoDialogComponent.prototype.setupPhotoEditor = function () {
        this.canCropping = this.isValidPhoto(this.employee.photo.width, this.employee.photo.height);
        if (!this.canCropping) {
            this.setError('imageFormat');
        }
    };
    EmployeeEditPhotoDialogComponent.prototype.readImage = function (file) {
        var _this = this;
        var fileReader = new FileReader();
        fileReader.onloadend = function () {
            _this.getImageSize(fileReader.result)
                .then(function (size) {
                if (_this.isValidPhoto(size.width, size.height)) {
                    _this.employee.photo.src = fileReader.result;
                    _this.photoUploaded = _this.canCropping = true;
                    _this.resetError();
                    return;
                }
                _this.setError('uploadFormat');
            });
        };
        fileReader.readAsDataURL(file);
    };
    EmployeeEditPhotoDialogComponent.prototype.getImageSize = function (dataUri) {
        var resolver;
        var promise = new Promise(function (res) { return (resolver = res); });
        var image = new Image();
        image.onload = function () {
            resolver({ width: image.width, height: image.height });
        };
        image.onerror = function () {
            resolver({ width: 0, height: 0 });
        };
        image.src = dataUri;
        return promise;
    };
    EmployeeEditPhotoDialogComponent.prototype.getWidth = function (elem) {
        var style = getComputedStyle(elem);
        var offsets = this.toNumber(style.paddingLeft) + this.toNumber(style.paddingRight) +
            this.toNumber(style.borderLeftWidth) + this.toNumber(style.borderRightWidth);
        return elem.offsetWidth - offsets;
    };
    EmployeeEditPhotoDialogComponent.prototype.getHeight = function (elem) {
        var style = getComputedStyle(elem);
        var offsets = this.toNumber(style.paddingTop) + this.toNumber(style.paddingBottom) +
            this.toNumber(style.borderTopWidth) + this.toNumber(style.borderBottomWidth);
        return elem.offsetHeight - offsets;
    };
    EmployeeEditPhotoDialogComponent.prototype.toNumber = function (item) {
        var num = parseInt(item);
        return _.isNaN(num) ? 0 : num;
    };
    return EmployeeEditPhotoDialogComponent;
}());
export { EmployeeEditPhotoDialogComponent };
