import { Component } from '@angular/core';
import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsAuditTrailNoteRecord } from '../../../models/employee-sections-audit/employee-sections-audittrail';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-note-edit',
  templateUrl: 'employee-sections-note-edit.component.html',
  styleUrls: ['employee-sections-note-edit.component.scss']
})
export class EmployeeSectionsNoteEditComponent {
  public options: DialogOptions;
  public dialogResult: boolean;
  public note: EmployeeSectionsAuditTrailNoteRecord;
  public subject: string;
  public details: string;
  public isPublic: boolean;

  private modalService: ModalService;

  constructor(options: DialogOptions, modalService: ModalService, note: EmployeeSectionsAuditTrailNoteRecord) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.note = note;

    if (note) {
      this.details = note.detail.fieldValue;
      this.subject = note.subject.fieldValue;
      this.isPublic = note.isPublic;
    }
  }

  public onOk(): void {
    this.dialogResult = true;
    this.note.detail.fieldValue = this.details;
    this.note.subject.fieldValue = this.subject;
    this.note.isPublic = this.isPublic;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
