/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./daily-punches-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../punches-management-rollup/punches-management-rollup.component.ngfactory";
import * as i3 from "../../../../common/services/state-management/state-management.service";
import * as i4 from "../../../../common/services/component-state/component-state-storage.service";
import * as i5 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i6 from "../../../services/punches/punches-rollup-management.service";
import * as i7 from "../../../services/punches/punches-api.service";
import * as i8 from "@angular/router";
import * as i9 from "../punches-management-rollup/punches-management-rollup.component";
import * as i10 from "../punches-management/punches-management.component.ngfactory";
import * as i11 from "../../../services/punches/punches-management.service";
import * as i12 from "../../../services/punches/punches-map.service";
import * as i13 from "../../../../organization/services/lookup/lookup-api.service";
import * as i14 from "../../../../organization/services/constraints/constraints-api.service";
import * as i15 from "../../../../common/services/column-settings/column-management.service";
import * as i16 from "../punches-management/punches-management.component";
import * as i17 from "@angular/common";
import * as i18 from "./daily-punches-page.component";
var styles_DailyPunchesPageComponent = [i0.styles];
var RenderType_DailyPunchesPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DailyPunchesPageComponent, data: {} });
export { RenderType_DailyPunchesPageComponent as RenderType_DailyPunchesPageComponent };
function View_DailyPunchesPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-punches-management-rollup", [], null, null, null, i2.View_PunchesManagementRollupComponent_0, i2.RenderType_PunchesManagementRollupComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.PunchesRollupManagementService, i6.PunchesRollupManagementService, [i7.PunchesApiService, i5.ColumnSettingsStorageService, i3.StateManagementService, i4.ComponentStateStorageService, i8.ActivatedRoute]), i1.ɵdid(3, 245760, null, 0, i9.PunchesManagementRollupComponent, [i3.StateManagementService, i6.PunchesRollupManagementService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_DailyPunchesPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-punches-management", [], null, null, null, i10.View_PunchesManagementComponent_0, i10.RenderType_PunchesManagementComponent)), i1.ɵprd(512, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵprd(512, null, i11.PunchesManagementService, i11.PunchesManagementService, [i7.PunchesApiService, i12.PunchesMapService, i13.LookupApiService, i14.ConstraintsApiService, i3.StateManagementService]), i1.ɵprd(512, null, i15.ColumnManagementService, i15.ColumnManagementService, [i5.ColumnSettingsStorageService]), i1.ɵdid(4, 245760, null, 0, i16.PunchesManagementComponent, [i11.PunchesManagementService, i15.ColumnManagementService, i3.StateManagementService, i4.ComponentStateStorageService, i8.ActivatedRoute, i8.Router], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
export function View_DailyPunchesPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DailyPunchesPageComponent_1)), i1.ɵdid(2, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DailyPunchesPageComponent_2)), i1.ɵdid(4, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showRollup; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.showRollup; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DailyPunchesPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-daily-punches-page", [], null, null, null, View_DailyPunchesPageComponent_0, RenderType_DailyPunchesPageComponent)), i1.ɵdid(1, 114688, null, 0, i18.DailyPunchesPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DailyPunchesPageComponentNgFactory = i1.ɵccf("slx-daily-punches-page", i18.DailyPunchesPageComponent, View_DailyPunchesPageComponent_Host_0, {}, {}, []);
export { DailyPunchesPageComponentNgFactory as DailyPunchesPageComponentNgFactory };
