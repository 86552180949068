export interface IDateRange {
    startDate: Date;
    endDate: Date;
}

export class DateRange {
    public startDate: Date;
    public endDate: Date;

    constructor(sDate: Date, eDate: Date) {
      this.startDate = sDate;
      this.endDate = eDate;
    }
}

export interface IDateRangeStr {
  startDate: string;
  endDate: string;
}

export function isDateRange(value: any): value is IDateRange {
    return value.startDate !== undefined && value.endDate !== undefined;
}
