import { DayOfWeek } from './day-of-week';

export const enum DayOfWeekDefinition {
  sunday = 'Sunday',
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday'
}

export const enum DayOfWeekShortDefinition {
  sun = 'Sun',
  mon = 'Mon',
  tue = 'Tue',
  wed = 'Wed',
  thu = 'Thu',
  fri = 'Fri',
  sat = 'Sat'
}

export const WeekDays: DayOfWeek[] = [
  { name: DayOfWeekDefinition.sunday.toString(), shortName: DayOfWeekShortDefinition.sun.toString(), dayNumber: 1 },
  { name: DayOfWeekDefinition.monday.toString(), shortName: DayOfWeekShortDefinition.mon.toString(), dayNumber: 2 },
  { name: DayOfWeekDefinition.tuesday.toString(), shortName: DayOfWeekShortDefinition.tue.toString(), dayNumber: 3 },
  { name: DayOfWeekDefinition.wednesday.toString(), shortName: DayOfWeekShortDefinition.wed.toString(), dayNumber: 4 },
  { name: DayOfWeekDefinition.thursday.toString(), shortName: DayOfWeekShortDefinition.thu.toString(), dayNumber: 5 },
  { name: DayOfWeekDefinition.friday.toString(), shortName: DayOfWeekShortDefinition.fri.toString(), dayNumber: 6 },
  { name: DayOfWeekDefinition.saturday.toString(), shortName: DayOfWeekShortDefinition.sat.toString(), dayNumber: 7 }];

export const WeekDaysWithEmpty: DayOfWeek[] = [{ name: '', shortName: '', dayNumber: 0 }].concat(WeekDays);

