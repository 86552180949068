export interface IAcaMeasurementInsights {
  currentMonthMP: IAcaInsightPeriod;
  currentMonthAP: IAcaInsightPeriod;
  nextMonthMP: IAcaInsightPeriod;
  nextMonthAP: IAcaInsightPeriod;
}

export class AcaMeasurementInsights {
  public currentMonthMP: AcaInsightPeriod;
  public currentMonthAP: AcaInsightPeriod;
  public nextMonthMP: AcaInsightPeriod;
  public nextMonthAP: AcaInsightPeriod;
}

export interface IAcaInsightPeriod {
  period: string;
  totalDeterminedEligibleCount: number;
  ptDeterminedEligibleCount: number;
  ftDeterminedEligibleCount: number;
  ftDeterminedIneligibleCount: number;
}

export class AcaInsightPeriod {
  public period: string;
  public totalDeterminedEligibleCount: number;
  public ptDeterminedEligibleCount: number;
  public ftDeterminedEligibleCount: number;
  public ftDeterminedIneligibleCount: number;
}


export interface IAcaInsightField {
  isEnding: boolean;
  ptDeterminedEligible: boolean;
  ftDeterminedEligible: boolean;
  ftDeterminedIneligible: boolean;
}

export class AcaInsightField {
  public isEnding: boolean;
  public ptDeterminedEligible: boolean;
  public ftDeterminedEligible: boolean;
  public ftDeterminedIneligible: boolean;
}

