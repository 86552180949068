import * as tslib_1 from "tslib";
import { AleGroup } from './ale-group';
var AleGroupView = /** @class */ (function (_super) {
    tslib_1.__extends(AleGroupView, _super);
    function AleGroupView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AleGroupView;
}(AleGroup));
export { AleGroupView };
var AleGroupAssign = /** @class */ (function () {
    function AleGroupAssign() {
    }
    return AleGroupAssign;
}());
export { AleGroupAssign };
