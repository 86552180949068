
import * as _ from 'lodash';
import { Injectable, EventEmitter } from '@angular/core';
import { NavigationMenuItem, NavigationMenuType, NavigationMenuEventType } from '../../models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { UserApplication } from '../../../state-model/models/index';
import { NavigationMenuApiService } from './navigation-menu-api.service';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ManagementBaseService } from '../../../core/services/index';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ApplicationStateBusService, IReloadMenuEvent } from '../application-state-bus/application-state-bus.service';

@Injectable()
export class NavigationMenuManagementService extends ManagementBaseService<NavigationMenuItem[], any> {
  public selectedItem: NavigationMenuItem;
  public selectedApplicationItem: NavigationMenuItem;
  public orgLevel: OrgLevel;
  @unsubscribeInService()
  private reloadMenuSubscription: Subscription;

  constructor(private appStateBusService: ApplicationStateBusService, private navigationMenuApiService: NavigationMenuApiService) {
    super();
    this.selectedItem = null;
    this.reloadMenuSubscription = this.appStateBusService.reloadMenu$.subscribe((event: IReloadMenuEvent) => {
      this.orgLevel = event.orgLevel;
      this.loadMenu(event.app, event.orgLevel);
    });
  }

  public menuSelected(type: NavigationMenuEventType, menuItem: NavigationMenuItem): void {
    this.selectedItem = menuItem;
    this.appStateBusService.selectMenu({ type: type, menuItem: menuItem });
  }

  public isMenuSupportOrgLevel(menuItem: NavigationMenuItem): boolean {
    if (_.isObject(menuItem) && _.isObject(this.orgLevel)) {
      const types = menuItem.supportedOrgTypes;
      return _.indexOf(types, this.orgLevel.type) !== -1 || types.length === 0;
    }
    return false;
  }

  public loadMenu(application: UserApplication, orgLevel: OrgLevel): void {
    this.navigationMenuApiService.getApplicationMenu(orgLevel).then((menuItems: NavigationMenuItem[]) => {
      this.onLoaded(menuItems);
      this.appStateBusService.loadedMenu(menuItems);
    });
  }
}
