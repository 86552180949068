import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { ISelectableItemContainer, SelectableItemsProducer, DateTimeService } from '../../../../../common/index';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsAttendancePoints } from '../../../models/index';
import { EmployeeSectionsPerformanceApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { IRangeDates } from '../../../../../common/models/range-dates';
import { orderBy } from '@progress/kendo-data-query';
var EmployeeSectionsAttendanceBuybacksComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAttendanceBuybacksComponent, _super);
    function EmployeeSectionsAttendanceBuybacksComponent(employeeSectionsPerformanceApiService, dateTimeService, selectableItemsProducer, modalService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.sort = [];
        _this.totalPoints = 0;
        _this.totalCurrentPoints = 0;
        _this.pageSize = 10;
        _this.skip = 0;
        Assert.isNotNull(employeeSectionsPerformanceApiService, 'employeeSectionsPerformanceApiService');
        Assert.isNotNull(dateTimeService, 'dateTimeService');
        _this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
        _this.modalService = modalService;
        _this.dateTimeService = dateTimeService;
        _this.selectableItemsProducer = selectableItemsProducer;
        _this.changeDetector = changeDetector;
        _this.sort = [{ field: 'item.dateOn.fieldValue', dir: 'desc' }];
        return _this;
    }
    Object.defineProperty(EmployeeSectionsAttendanceBuybacksComponent.prototype, "buybacksSubsection", {
        set: function (attendancePoints) {
            this.buybacks = attendancePoints;
            if (attendancePoints !== null) {
                this.selectableAttendancePoints = this.selectableItemsProducer.produceSelectable(attendancePoints.points);
                this.refreshGrid();
                this.isAnyItemSelected = false;
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceBuybacksComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendanceBuybacksComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendanceBuybacksComponent.prototype.getSubsectionModel = function () {
        return this.buybacks;
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.skip = 0;
        this.employeeSectionsPerformanceApiService.getPerformanceAttendanceBuybacks(this.employeeId, this.startDate, this.endDate)
            .then(function (attendancePoints) {
            _this.buybacks = attendancePoints;
            _this.stopProgress();
            _this.selectableAttendancePoints = _this.selectableItemsProducer.produceSelectable(_this.buybacks.points);
            _this.refreshGrid();
            _this.isAnyItemSelected = false;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        })
            .catch(function (error) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.onSortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.onPageChange = function (event) {
        this.skip = event.skip;
        this.refreshGrid();
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.onRemovePointsClicked = function () {
        var _this = this;
        this.modalService.globalAnchor.openConfirmDialog('Warning', 'The selected buyback(s) will be removed and the total attendance points balance will be recalculated.', function (result) {
            if (result) {
                var pointIds_1 = [];
                _this.selectableAttendancePoints.forEach(function (i) { if (i.selected)
                    pointIds_1.push(i.item.id); });
                _this.doRemove(pointIds_1);
            }
        });
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadSubsection();
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.onItemSelectionChanged = function () {
        this.isAnyItemSelected = this.selectableAttendancePoints.some(function (i) { return i.selected; });
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.doRemove = function (pointIds) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPerformanceApiService.deleteAttendancePoints(this.employeeId, pointIds)
            .then(function (result) {
            _this.stopProgress();
            _this.loadSubsection();
        })
            .catch(function (error) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAttendanceBuybacksComponent.prototype.refreshGrid = function () {
        if (!this.selectableAttendancePoints) {
            this.gridView = null;
            return;
        }
        var sortedRecords = orderBy(this.selectableAttendancePoints, this.sort);
        var pagedRecords = sortedRecords.slice(this.skip, this.skip + this.pageSize);
        this.gridView = {
            data: pagedRecords,
            total: this.selectableAttendancePoints.length
        };
    };
    return EmployeeSectionsAttendanceBuybacksComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsAttendanceBuybacksComponent };
