import { Component, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { EmployeeImagesService, EmployeePhoto } from '../../../../../app/organization';
import { SessionService } from '../../../../../app/core/services';
@Component({
  selector: 'slx-message-thread-thumbnail-src',
  templateUrl: './message-thread-thumbnail-src.component.html',
  styleUrls: ['./message-thread-thumbnail-src.component.scss']
})
export class MessageThreadThumbnailSrcComponent implements OnChanges {
  @Input() messageThread: any;
  @Input() employeeName: string;
  @Input() employeeStatus: string;
  @Input() employeeOptIn: number;
  @Input() employeeLeaveStatus: boolean;
  public imgPhoto: string;
  loggedInUser = '';
  loggedInUserId = null;
  @Input() usersPicture: {};
  constructor(private employeeImagesService: EmployeeImagesService, private sessionService: SessionService) {
    const constUserDetails = this.sessionService.getUser();
    this.loggedInUser = constUserDetails.name;
    this.loggedInUserId = constUserDetails.id;
  }



  ngOnChanges() {
    this.imgPhoto = '';
    this.employeeImagesService.getEmployeeThumbnail(this.messageThread.employeeId)
    .then((employeePhoto: EmployeePhoto) => {
      if (employeePhoto.photo) {
        this.imgPhoto = employeePhoto.photo;
      }
    });

  }

  public getEmployeeDisableStatus(dataItem) {
    let color = '';
    if ((this.employeeStatus === 'Terminated' 
    || this.employeeStatus === 'Transferred' ||
     (this.employeeOptIn===0)|| (this.employeeOptIn===2)) && !(dataItem.userId !== this.loggedInUserId && dataItem.userId !==0 && dataItem.userName !== null)) {
        color = 'disable-color';
    }
    if(dataItem.errorCode !== null && dataItem.errorCode !== '0') {
      color = color + ' error-msg-left'
    }
    return color;
  }
}
