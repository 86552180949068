import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import * as _ from 'lodash';
import { select } from '@angular-redux/store';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { unsubscribe, destroyService, mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { IndSchOrgLevelEmployee, IndSchOrgLevelEmployees, IndSchEmployeeSchedulesRequest, IPeriodLength, Employee } from '../../../models/index';
import { IndividualScheduleApiService, IndividualScheduleManagementService, IndividualScheduleEmpManagementService } from '../../../services/index';
import { ScheduleCycle } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { LookupApiService, LookupService } from '../../../../organization/services/index';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { IControlState, StateResetTypes } from '../../../../core/models/index';
import { PayCycleEmployeeProvider } from '../../../services/index';
import { EmployeeSearchBase } from '../../../../employee/employee/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-individual-schedule-employee-list',
  templateUrl: 'individual-schedule-employee-list.component.html',
  styleUrls: ['individual-schedule-employee-list.component.scss'],
  providers: [{ provide: EmployeeSearchBase, useClass: PayCycleEmployeeProvider }]
})
export class IndividualScheduleEmployeeListComponent implements OnInit, OnDestroy {
  @select(['sidebar', 'isLeftSidebarOpen'])
  public isLeftSidebarOpen: Observable<boolean>;

  @select(['sidebar', 'isLeftSidebarHidden'])
  public isLeftSidebarHidden: Observable<boolean>;

  public appConfig: IApplicationConfig;
  public orgLevelEmployees: IndSchOrgLevelEmployees;
  public periods: IPeriodLength[];
  public filterString: string;
  public filter: string;
  public selectedPeriod: IPeriodLength;
  public scheduleCycles: ScheduleCycle[];
  public selectedScheduleCycle: ScheduleCycle;

  public state: {
    isLoading: boolean;
  };

  private selectedOrgLevel: OrgLevel;
  private individualScheduleApiService: IndividualScheduleApiService;
  private selectedEmployee: IndSchOrgLevelEmployee;
  public individualScheduleManagementService: IndividualScheduleManagementService;
  private filterStringControlKey: string = 'empFilterString';
  private scheduleCycleControlKey: string = 'scheduleCycle';
  public showLColumn: boolean = true;

  @unsubscribe()
  private employeeSubscription: Subscription;
  @unsubscribe()
  private empLockListSubscription: Subscription;
  @unsubscribe()
  private employeeUpdateSubscription: Subscription;
  @unsubscribe()
  private contextChangeSubscription: Subscription;
  @unsubscribe()
  private initSubscription: Subscription;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;

  private lookupApiService: LookupApiService;
  private lookupService: LookupService;

  private get numWeeks(): number {
    if (!this.selectedScheduleCycle) return 0;
    let numWeeks: number = this.selectedScheduleCycle.endDate.diff(this.selectedScheduleCycle.startDate, 'days');
    numWeeks = Math.ceil(numWeeks / 7);
    return numWeeks;
  }

  private get employeeSearchService(): PayCycleEmployeeProvider {
    return this.employeeSearchBase as any;
  }

  constructor(individualScheduleApiService: IndividualScheduleApiService,
    individualScheduleManagementService: IndividualScheduleManagementService,
    lookupApiService: LookupApiService,
    lookupService: LookupService,
    private individualScheduleEmpManagementService: IndividualScheduleEmpManagementService,
    private stateManagement: StateManagementService,
    private employeeSearchBase: EmployeeSearchBase
  ) {
    this.individualScheduleApiService = individualScheduleApiService;
    this.individualScheduleManagementService = individualScheduleManagementService;
    this.lookupApiService = lookupApiService;
    this.lookupService = lookupService;
    this.state = {
      isLoading: false
    };
    this.employeeSubscription = this.individualScheduleManagementService.onSelectionChanged$.subscribe((emp: IndSchOrgLevelEmployee) => {
      this.selectedEmployee = emp;
      this.emitRequest(emp);
    });

    this.employeeUpdateSubscription = this.individualScheduleEmpManagementService.onEmployeeUpdated$.subscribe((value: any) => {
      this.loadOrgLevelEmployees();
    });

    this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe((key: StringMap<any>) => {
      this.stateManagement.loadData();
    });
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;

    this.subscriptions.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.selectedOrgLevel && (orgLevel.id !== this.selectedOrgLevel.id)) || !this.selectedOrgLevel)) {
        this.selectedOrgLevel = orgLevel;
        this.selectedEmployee = null;
      }
    });

    this.empLockListSubscription = this.individualScheduleManagementService.onEmpListLock$.subscribe((isLock: boolean) => {
      this.state.isLoading = isLock;
    });

    this.individualScheduleManagementService.subscribeToLoadOrgLvlEmployees((cycle: ScheduleCycle) => {
      this.selectedScheduleCycle = cycle;
      this.employeeSearchService.startDate = cycle.startDate.toDate();
      this.employeeSearchService.endDate = cycle.endDate.toDate();
      this.loadOrgLevelEmployees();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private loadOrgLevelEmployees(): void {
    if (!this.selectedOrgLevel || !this.selectedScheduleCycle) {
      return;
    }
    this.individualScheduleManagementService.onScheduleCycleSelected(this.selectedScheduleCycle);
    this.state.isLoading = true;
    this.individualScheduleApiService.getOrgLevelEmployees(this.selectedOrgLevel.id, this.selectedScheduleCycle.startDate.toDate(), this.numWeeks)
      .then((orgLevelEmployees: IndSchOrgLevelEmployees) => {
        this.employeeSearchService.orgLevelEmployees = orgLevelEmployees.entities;
        this.employeeSearchService.Load();
        this.orgLevelEmployees = orgLevelEmployees;
        this.state.isLoading = false;
      })
      .catch(() => {
        this.state.isLoading = false;
      });
  }

  private emitRequest(employeeWrap: IndSchOrgLevelEmployee): void {
    if (!employeeWrap || !this.orgLevelEmployees) {
      this.individualScheduleManagementService.onEmployeeRequest(null);
      return; }
    let req: IndSchEmployeeSchedulesRequest = new IndSchEmployeeSchedulesRequest();
    req.employeeId = employeeWrap.employee.id;
    req.orgLevelId = this.orgLevelEmployees.orgLevelId;
    req.scheduleWeeksCount = this.numWeeks;
    req.startDate = this.selectedScheduleCycle.startDate.toDate();
    req.employeeName = employeeWrap.employee.name;
    this.individualScheduleManagementService.onEmployeeRequest(req);
  }

  public onEmployeeSelected(emp: Employee): void {
    if (!_.isNil(emp)) {
      this.individualScheduleManagementService.onSearchEmpSelected$.next(emp.id);
    }
  }

  public toggleLCol() {
    this.individualScheduleManagementService.changeEmployeeListVisibility();
  }
}
