import * as moment from 'moment';
import * as _ from 'lodash';

import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { appConfig, IApplicationConfig } from '../../../app.config';

export class IndividualScheduleNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public NavigateToIndividualScheduleEmp(employeeId: number, startDate: Date, endDate: Date, currentDate: Date = null): void {
    if (startDate && endDate) {
      const queryParams: StringMap<any> = { startDate: moment(startDate).format(appConfig.linkDateFormat), endDate: moment(endDate).format(appConfig.linkDateFormat) };
      if (_.isDate(currentDate)) {
        queryParams.currentDate = moment(currentDate).format(appConfig.linkDateFormat);
      }
      this.router.navigate(['individual_schedule', 'employee', employeeId],
        {
          relativeTo: this.route.pathFromRoot[2],
          queryParams
        });
      return;
    }
    if (!startDate && !endDate) {
      this.router.navigate(['individual_schedule', 'employee', employeeId], { relativeTo: this.route.pathFromRoot[2] });
      return;
    }
    throw Error('incorrect routing');
  }
}
