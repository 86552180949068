import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldData, FieldRenderType, FieldsMeta } from '../../../../core/models/index';

export interface IEmployeeSectionsPhysicals {
  groups: IEmployeeSectionsPhysicalGroup[];
}
export interface IEmployeeSectionsPhysicalGroup {
  fields: IEmployeeSectionsPhysicalField[];
  seq: number;
}
export interface IEmployeeSectionsPhysicalField {
  id: number;
  name: string;
  displayName: string;
  value: any;
  fieldType: string;
  fieldLength: string;
  renderType: string;
  seq: number;
}

export class EmployeeSectionsPhysicalGroup {
  public fields: EmployeeSectionsPhysicalField<any>[];
  public seq: number;
  constructor() {
    this.fields = [];
  }
}
export class EmployeeSectionsPhysicalField<T> {
  public id: number;
  public displayName: string;
  public fieldData: FieldData<T>;
  public fieldRender: FieldRenderType;
  public fieldType: string;
  public fieldLength: number;
  public seq: number;

}

export class EmployeeSectionsPhysicals extends EmployeeSectionsBase {
  public static dataMock: IEmployeeSectionsPhysicalGroup[] = [
    {
      fields: [
        {
          id: 3,
          name: 'physicalDate',
          displayName: 'Physical date value',
          value: new Date('11/4/2017'),
          fieldType: 'Date',
          fieldLength: '10',
          renderType: 'Date',
          seq: 3
        },
        {
          id: 4,
          name: 'physicalNumberValue',
          displayName: 'Physical number value',
          value: 10,
          fieldType: 'number',
          fieldLength: '2',
          renderType: 'number',
          seq: 4
        },
        {
          id: 1,
          name: 'physicalString',
          displayName: 'Physical string value',
          value: 'string',
          fieldType: 'string',
          fieldLength: '1',
          renderType: 'string',
          seq: 1
        },
        {
          id: 2,
          name: 'physicalString2',
          displayName: 'Physical string2 value',
          value: 'string2',
          fieldType: 'string',
          fieldLength: '1',
          renderType: 'string',
          seq: 2
        }
      ],
      seq: 1
    },
    {
      fields: [
        {
          id: 1,
          name: 'physicalDropDown',
          displayName: 'Physical date value',
          value: false,
          fieldType: 'yesno',
          fieldLength: '1',
          renderType: 'yesno',
          seq: 1
        },
        {
          id: 2,
          name: 'physicalNumber2Value',
          displayName: 'Physical number2 value',
          value: 10,
          fieldType: 'number',
          fieldLength: '3',
          renderType: 'number',
          seq: 2
        }
      ],
      seq: 2
    }
  ];
  public groups: EmployeeSectionsPhysicalGroup[];
  public fieldsMeta: FieldsMeta;
  constructor() {
    super();
    this.groups = [];
  }
}

