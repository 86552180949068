// declare type ClassDecorator = <TFunction extends Function>(target: TFunction) => TFunction | void;
// declare type PropertyDecorator = (target: Object, propertyKey: string | symbol) => void;
// declare type MethodDecorator = <T>(target: Object, propertyKey: string | symbol, descriptor: TypedPropertyDescriptor<T>) => TypedPropertyDescriptor<T> | void;
// declare type ParameterDecorator = (target: Object, propertyKey: string | symbol, parameterIndex: number) => void;
// interface TypedPropertyDescriptor<T> {
//     enumerable?: boolean;
//     configurable?: boolean;
//     writable?: boolean;
//     value?: T;
//     get?: () => T;
//     set?: (value: T) => void;
// }

import { NgRedux } from '@angular-redux/store';
import { Observable } from 'rxjs/Observable';
import { Iterable } from 'immutable';


export type PropertySelector = string | number | symbol;
export type PathSelector = (string | number)[];
export type FunctionSelector<RootState, S> = ((s: RootState) => S);
export type Comparator = (x: any, y: any) => boolean;

export function mutableSelect<T>(
  selector?: PropertySelector | PathSelector | FunctionSelector<any, T>,
  comparator?: Comparator): PropertyDecorator {
  return function decorate(target: any, key: string): void {
    let bindingKey: PropertySelector | PathSelector | FunctionSelector<any, T> = selector;
    if (!selector) {
      bindingKey = (key.lastIndexOf('$') === key.length - 1) ?
        key.substring(0, key.length - 1) :
        key;
    }

    function getter(): Observable<T> {
      let o: Observable<T> = NgRedux.instance ? NgRedux.instance
        .select(bindingKey, comparator)
        .map((obj: any) => {
          return Iterable.isIterable(obj) ? (<Iterable<string, T>>obj).toJS() : obj;
        }) : null;
      return o;
    }

    if (delete target[key]) {
      Object.defineProperty(target, key, {
        get: getter,
        enumerable: true,
        configurable: true
      });
    }
  };
}

import { middleware } from '../../store/index';
import { InjectionToken, Provider } from '@angular/core';

export const EPICS_TOKEN: InjectionToken<string> = new InjectionToken('epics');
export const EPIC_PROVIDERS: Provider[] = middleware.map((m: any) => ({
  provide: EPICS_TOKEN,
  useValue: m,
  multi: true
})) || [];

export function epic<T>(): MethodDecorator {
  return function decorate<T>(target: any, propertyKey: string | symbol, descriptor: TypedPropertyDescriptor<T>): TypedPropertyDescriptor<T> | void {
    EPIC_PROVIDERS.push({
      provide: EPICS_TOKEN,
      useValue: target,
      multi: true
    });
  };
}
