import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsBackgroundCheck = /** @class */ (function () {
    function EmployeeSectionsBackgroundCheck() {
    }
    return EmployeeSectionsBackgroundCheck;
}());
export { EmployeeSectionsBackgroundCheck };
var EmployeeSectionsBackgroundChecks = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsBackgroundChecks, _super);
    function EmployeeSectionsBackgroundChecks() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsBackgroundChecks;
}(EmployeeSectionsBase));
export { EmployeeSectionsBackgroundChecks };
