import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { ValuePairChartDataCalculationService } from './value-pair-chart-data-calculation.service';
import { ValuePairChartInput } from '../../models/value-pair-chart/value-pair-chart-input';
import { ValuePairChartSeriesInput } from '../../models/value-pair-chart/value-pair-chart-series-input';
import { ValuePairChartSeriesModel } from '../../models/value-pair-chart/value-pair-chart-series-model';
import { ValuePairWidgetConfig } from '../../models/value-pair-widget/value-pair-widget-config';
import { PairColorScheme } from '../../models/pair-color-scheme/pair-color-scheme';

import * as _ from 'lodash';

@Injectable()
export class ValuePairChartSeriesDataService {

  public onConfigLoaded: ReplaySubject<ValuePairWidgetConfig>;
  public onModelsLoaded: ReplaySubject<ValuePairChartSeriesModel[]>;

  constructor() {
    this.onConfigLoaded = new ReplaySubject<ValuePairWidgetConfig>(1);
    this.onModelsLoaded = new ReplaySubject<ValuePairChartSeriesModel[]>(1);
  }

  public setConfig(config: ValuePairWidgetConfig): void {
    this.onConfigLoaded.next(config);
  }

  public setModels(inputs: ValuePairChartSeriesInput[], config: ValuePairWidgetConfig): void {
    const seriesModels: ValuePairChartSeriesModel[] = [];

    _.forEach(inputs, (seriesInput: ValuePairChartSeriesInput) => {
      const seriesModel = ValuePairChartDataCalculationService.createSeriesModel(seriesInput, config);
      seriesModels.push(seriesModel);
    });

    this.onModelsLoaded.next(seriesModels);
  }
}
