import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { EditableListActionModel, EditableListActionKind, EditableListProps } from '../../models/index';

@Injectable()
export class ListActionsService {

  public set multiselect(value: boolean) {
    this.allowMultiselect = value;
    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.MULTISELECT, value: value });
    this.sendAction(action);
  }

  public get multiselect(): boolean {
    return this.allowMultiselect;
  }

  public set editable(value: boolean) {
    this.allowEdit = value;
    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.EDITABLE, value: value });
    this.sendAction(action);
  }

  public get editable(): boolean {
    return this.allowEdit;
  }

  public set selectable(value: boolean) {
    this.allowSelect = value;
    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.SELECTABLE, value: value });
    this.sendAction(action);
  }

  public get selectable(): boolean {
    return this.allowSelect;
  }

  public set itemCount(value: number) {
    this.privateItemCount = value;
  }

  public get itemCount(): number {
    return this.privateItemCount;
  }

  public get selectedItems(): any[] {
    return this.selectedItemsArray.slice(0);
  }

  public get allSelected(): boolean {
    return this.allItemsSelected;
  }

  public set renderIsEditor(value: boolean) {
    this.renderIsEditorInternal = value;
  }

  public get renderIsEditor(): boolean {
    return this.renderIsEditorInternal;
  }

  public set editMode(value: boolean) {
    this.editModeInternal = value;
    if (this.renderIsEditorInternal) {
      let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.EDIT_MODE, value: value });
      this.sendAction(action);
    }
  }

  public get editMode(): boolean {
    return this.editModeInternal;
  }

  private subject: Subject<any> = new Subject<any>();
  private allowMultiselect: boolean;
  private allowEdit: boolean = true;
  private allowSelect: boolean = true;
  private privateItemCount: number = 0;

  private selectedItemsArray: any[] = [];
  private allItemsSelected: boolean;
  private hasEditingItem: boolean;
  private newItem: any;

  private renderIsEditorInternal: boolean = false;
  private editModeInternal: boolean = false;

  public addNewItem(item: any): void {
    this.newItem = item;
    if (!this.renderIsEditor) this.startEditItem(this.newItem);
  }

  public isNew(item: any): boolean {
    return (this.newItem === item);
  }

  public startEditItem(item: any): void {
    if (!this.allowEdit) return;
    this.hasEditingItem = true;
    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.START_EDIT, [item]);
    this.sendAction(action);
  }

  public cancelEditItem(item: any): void {
    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.CANCEL_EDIT, [item]);
    this.sendAction(action);
    this.hasEditingItem = false;
  }

  public saveItem(item: any): void {
    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.COMPLETE_EDIT, [item]);
    this.sendAction(action);
    this.hasEditingItem = false;
    this.newItem = null;
  }

  public removeItem(item: any): void {
    if (this.renderIsEditor) {
        let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.REMOVE, [item]);
        this.sendAction(action);
    } else {
      if (!this.hasEditingItem) {
        let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.REMOVE, [item]);
        this.sendAction(action);
      }
    }
  }

  public toggleSelect(item: any): void {

    if (this.hasEditingItem) return;

    if (this.allowMultiselect) {
      if (this.selectedItemsArray.indexOf(item) === -1) {
        this.selectedItemsArray.push(item);
      } else {
        this.selectedItemsArray = _.without(this.selectedItemsArray, item);
      }
    } else {
      if (this.selectedItemsArray.indexOf(item) === -1) {
        this.selectedItemsArray = [item];
      } else {
        this.selectedItemsArray = [];
      }
    }

    if (this.selectedItemsArray.length !== this.privateItemCount) {
      this.allItemsSelected = false;
    } else {
      this.allItemsSelected = true;
    }

    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.SELECTION_CHANGE, [item]);
    this.sendAction(action);
  }

  public selectAll(items?: any[]): void {

    if (this.hasEditingItem) return;
    if (this.allowMultiselect) {

      if (this.allItemsSelected === false) {
        this.allItemsSelected = true;
        this.selectedItemsArray = items || [];
      } else {
        this.allItemsSelected = false;
        this.selectedItemsArray = [];
      }

      let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.SELECT_ALL, items || []);
      this.sendAction(action);
    }
  }

  public resetSelection(): void {
    this.selectedItemsArray = [];
    this.allItemsSelected = false;
    let action: EditableListActionModel = new EditableListActionModel(EditableListActionKind.SELECTION_CHANGE);
    this.sendAction(action);
  }

  public isSelected(item: any): boolean {
    return (this.selectedItemsArray.indexOf(item) !== -1);
  }

  public subscribe(listener: (value: EditableListActionModel) => void): Subscription {
    return this.subject.asObservable().subscribe(listener);
  }

  protected sendAction(action: EditableListActionModel): void {
    this.subject.next(action);
  }

}
