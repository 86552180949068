<div class="section-container" [ngStyle]="{'height': heightValue}">
  <div class="section-header">
      <div class="section-left">
        <i class="section-main-icon {{iconClass}}" aria-hidden="true"></i>
        <h4 class="section-title">{{title}}</h4>
        <ng-content select="[slxDailyTimecardSectionHeaderLeft]"></ng-content>
      </div>
      <div class="section-right">
        <slx-collapse-button [(isCollapsed)]="isCollapsed" (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
        <ng-content select="[slxDailyTimecardSectionHeaderRight]"></ng-content>
      </div>
      <!--
      <div class="section-alerts">
        <span [ngClass]="{'locked-info': isLocked}" class="changes-info">
              <i aria-hidden="true" class="fa fa-lock"></i>{{lockedText}}
            </span>
      </div>
      -->
    </div>
    <div #collapseContent class="section-content" [ngClass]="{'locked-state': isLocked}">
      <ng-content select="[slxDailyTimecardSectionContent]"></ng-content>
    </div>
</div>
