import * as _ from 'lodash';
import * as moment from 'moment';
import { CalculationType } from './calculation-type.enum';
import { FormulaTypeEnum } from './formula-type.enum';
import { appConfig } from '../../../../app.config';
import { IBenefitDetailsBasic,BenefitDetailsBasic } from '../../../../app-modules/benefits/models/benefit-details/benefit-details-basic';
import { BenefitDetailsCalcMethod } from '../../../../app-modules/benefits/models/benefit-details/benefit-details-calc-method';
import { IBenefitOption } from '.';

export interface IEnrollmentHistoryRecord {

  empToBenefitsId: number;
  benefitTierId?: number;
  plan: string;
  startDate: string;
  endDate: string;
  employeePercentageContribution: number;
  employeeContribution: number;
  cost: number;
  option: string;
  coverage: number;
    inputCoverage?: number;
  limitAmount: number;
  notes: string;
  calculationMethod: number;
  isDeleted: boolean;
  calculationType: number;
  maxCap: number;
  multipler: number;
  maxEmployeePercentageContribution: number;
  employerContributionAmount: number;
  costFreq: IBenefitDetailsBasic<number, string>;
  empContFreq: IBenefitDetailsBasic<number, string>;
  calcMethod: IBenefitDetailsBasic<string, string>;
  hasBeneficiaries: boolean;
  benefitOptions: IBenefitOption[];
  selectedOption: IBenefitOption;
  benefitTierOptionId?: number;
  optionType: string;
  calculatedCoverage: number;
  payrollDeductionStartDate: string;
  payrollDeductionEndDate: string;
  dedStartDate: string;
  dedEndDate: string;
}

export class EnrollmentHistoryRecord {

  public empToBenefitsId: number;
  public benefitTierId: number;
  public plan: string;
  public startDate: Date;
  public endDate: Date;
  public employeePercentageContribution: number = 0;
  public employeeContribution: number;
  public cost: number = 0;
  public option: string;
  public coverage: number;
  public limitAmount: number;
  public notes: string;
  public calculationMethod: CalculationType;
  public isDeleted: boolean;
  public calculationType: FormulaTypeEnum;
  public maxCap: number;
  public multiplier: number;
  public maxEmployeePercentageContribution: number;
  public employerContributionAmount: number;
  public costFreq: BenefitDetailsBasic<number, string>;
  public empContFreq: BenefitDetailsBasic<number, string>;
  public calcMethod: BenefitDetailsCalcMethod = null;
  public hasBeneficiaries: boolean;
    public originalCoverage?: number;
  public isSelected: boolean;
  public benefitOptions: IBenefitOption[];
  public selectedOption: IBenefitOption;
  public benefitTierOptionId?: number;
  public optionType: string;
  public calculatedCoverage: number;
  public coverageLimit: number;
  public payrollDeductionStartDate: Date;
  public payrollDeductionEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;
}
