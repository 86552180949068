import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsAttendance = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAttendance, _super);
    function EmployeeSectionsAttendance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsAttendance;
}(EmployeeSectionsBase));
export { EmployeeSectionsAttendance };
var EmployeeSectionsAttendanceDay = /** @class */ (function () {
    function EmployeeSectionsAttendanceDay() {
    }
    EmployeeSectionsAttendanceDay.prototype.getAbsence = function (prefix) {
        return prefix === 'actual' ? this.actualAbsence : this.scheduledAbsence;
    };
    EmployeeSectionsAttendanceDay.prototype.getAbsenceHours = function (prefix) {
        return prefix === 'actual' ? this.actualAbsenceHours : this.scheduledAbsenceHours;
    };
    EmployeeSectionsAttendanceDay.prototype.getMarkedDay = function (prefix) {
        if (this.getAbsence(prefix))
            return false;
        return prefix === 'actual' ? this.isWorkDay : this.isScheduled;
    };
    return EmployeeSectionsAttendanceDay;
}());
export { EmployeeSectionsAttendanceDay };
var AbsenceStatItem = /** @class */ (function () {
    function AbsenceStatItem() {
    }
    Object.defineProperty(AbsenceStatItem.prototype, "name", {
        get: function () {
            return this.absence ? this.absence.description : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbsenceStatItem.prototype, "code", {
        get: function () {
            return this.absence ? this.absence.code : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AbsenceStatItem.prototype, "color", {
        get: function () {
            return this.absence ? this.absence.color : 0xFFFFFF;
        },
        enumerable: true,
        configurable: true
    });
    return AbsenceStatItem;
}());
export { AbsenceStatItem };
var AbsenceStatistics = /** @class */ (function () {
    function AbsenceStatistics() {
    }
    AbsenceStatistics.prototype.getAbsenceStatistics = function (prefix) {
        return prefix === 'actual' ? this.actualAbsenceStatistics : this.scheduledAbsenceStatistics;
    };
    return AbsenceStatistics;
}());
export { AbsenceStatistics };
