import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { organizationConfig } from '../../../organization/organization.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { IAccrualPolicy, AccrualPolicy, IDynamicAccrualPolicies } from '../../models/accruals/index';
import { AccrualMapService } from './accrual-map.service';
var AccrualApiService = /** @class */ (function () {
    function AccrualApiService(apiUtilService, urlParamsService, accrualMapService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.accrualMapService = accrualMapService;
    }
    AccrualApiService.prototype.getAllowDynamicAccrualPolicies = function () {
        var _this = this;
        var url = this.apiUtilService.getApiRoot() + "/" + organizationConfig.api.app.root + "/" + organizationConfig.api.app.settings.root + "/" + organizationConfig.api.app.settings.accruals.root + "/" + organizationConfig.api.app.settings.accruals.allowDynamicAccrualPolicies;
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) {
            return _this.accrualMapService.mapToDynamicPolicy(response.data, response.meta);
        });
        return promise;
    };
    AccrualApiService.prototype.getAccrualsPoliciesRecords = function (orgLevelId, effectiveDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.apiUtilService.getApiRoot() + "/" + organizationConfig.api.accruals.root + "/" + orgLevelId + "/" + organizationConfig.api.accruals.policy.root + "/" + organizationConfig.api.accruals.policy.all;
                request = this.urlParamsService.createGetRequest(url, {
                    effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
                });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.accrualMapService.mapAccrualsPoliciesRecords(response.data); })];
            });
        });
    };
    return AccrualApiService;
}());
export { AccrualApiService };
