import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { StateManagementService, ColumnManagementService, ModalService, DeviceDetectorService } from '../../../../../common/services';
import { CfgPmCode } from '../../../models';
import { KendoGridStateHelper } from '../../../../../common/models';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NgForm } from '@angular/forms';
import { screenUtils } from '../../../../../common/utils';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../../app/app.config';
import { LookupService } from '../../../../../organization/services';
import { LookupType } from '../../../../../organization';
import { PmConfigurationManagementService } from '../../../services';
import { ConfirmOptions, ConfirmDialogComponent } from '../../../../../common';
var PmCodesGridComponent = /** @class */ (function () {
    function PmCodesGridComponent(management, stateManagement, columnManagementService, lookupService, modalService, devDetector) {
        this.management = management;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.lookupService = lookupService;
        this.modalService = modalService;
        this.devDetector = devDetector;
        this.columnsGroupName = 'PerformanceManagementConfigureCodes';
        this.columnsStateName = 'PerformanceManagementConfigureCodes';
        this.canCreateNew = true;
        this.canEdit = true;
        this.sort = [];
        this.pageSize = 50;
        this.prohibitedCodeValuesByType = {};
        this.prohibitedCodeValues = [];
        this.showCodeNameControl = true;
        this.itemClass = CfgPmCode;
        this.xlsxNameConst = 'Performance_Codes_';
        this.pdfNameConst = 'Performance_Codes_';
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'modifiedDate', dir: 'desc' },
            { field: 'typeName', dir: 'asc' },
            { field: 'name', dir: 'asc' }
        ];
    }
    Object.defineProperty(PmCodesGridComponent.prototype, "codes", {
        get: function () {
            return this.m_codes;
        },
        set: function (c) {
            var _this = this;
            this.m_codes = c;
            this.prohibitedCodeValues = [];
            this.prohibitedCodeValuesByType = {};
            _.each(this.m_codes, function (c) {
                if (!_this.prohibitedCodeValuesByType[c.type.id])
                    _this.prohibitedCodeValuesByType[c.type.id] = [];
                _this.prohibitedCodeValuesByType[c.type.id].push(c.name);
            });
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmCodesGridComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    PmCodesGridComponent.prototype.ngOnInit = function () {
        this.initServices();
        this.createSubscriptions();
        this.refreshGrid();
        this.loadLookups();
    };
    PmCodesGridComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    PmCodesGridComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            if (event.isNew) {
                _this.doAdd(event.dataItem);
                return;
            }
            _this.doEdit(event.dataItem, event.rowIndex);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            _this.startRemove(event.dataItem, event.rowIndex);
        });
        this.subscriptions.addNewCodeSubscription = this.management.subscribeToAddNewCode(function (newCode) {
            _this.gridState.closeEditor(_this.grid);
            _this.grid.addRow(newCode);
        });
        this.subscriptions.exportSubscription = this.management.subscribeToExportCodes(function (isPdf) {
            var date = new Date();
            _this.xlsxName = _this.xlsxNameConst + (date + ".xlsx");
            _this.pdfName = _this.pdfNameConst + (date + ".pdf");
            if (isPdf) {
                _this.grid.saveAsPDF();
            }
            else {
                _this.grid.saveAsExcel();
            }
        });
    };
    PmCodesGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.m_codes, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    PmCodesGridComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    PmCodesGridComponent.prototype.editHandler = function (event) {
        if (event.dataItem) {
            if (event.dataItem.type) {
                this.prohibitedCodeValues = this.prohibitedCodeValuesByType[event.dataItem.type.id];
            }
            this.originalSelfValue = event.dataItem.name;
        }
        this.gridState.editHandler(event);
    };
    PmCodesGridComponent.prototype.onCodeTypeChange = function (item, dataItem) {
        var _this = this;
        this.showCodeNameControl = false;
        if (item) {
            if (dataItem.id && dataItem.type && dataItem.type.id !== item.id) {
                var index = _.findIndex(this.prohibitedCodeValuesByType[dataItem.type.id], function (n) { return n === _this.originalSelfValue; });
                this.prohibitedCodeValuesByType[dataItem.type.id].splice(index, 1);
                if (!this.prohibitedCodeValuesByType[item.id]) {
                    this.prohibitedCodeValuesByType[item.id] = [];
                }
                this.prohibitedCodeValuesByType[item.id].push(this.originalSelfValue);
            }
            dataItem.type = item;
            this.prohibitedCodeValues = this.prohibitedCodeValuesByType[item.id];
        }
        else {
            this.prohibitedCodeValues = [];
        }
        _.delay(function () {
            _this.showCodeNameControl = true;
        }, 100);
    };
    PmCodesGridComponent.prototype.startRemove = function (dataItem, rowIndex) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure?', this.modalService, function (result) {
            if (result) {
                _this.doRemove(dataItem, rowIndex);
            }
        }, options);
    };
    PmCodesGridComponent.prototype.doRemove = function (dataItem, rowIndex) {
        this.management.removeCode(dataItem);
    };
    PmCodesGridComponent.prototype.doEdit = function (dataItem, rowIndex) {
        var _this = this;
        this.management.updateCode(dataItem)
            .then(function () {
            _this.gridState.closeEditor(_this.grid);
        });
    };
    PmCodesGridComponent.prototype.doAdd = function (dataItem) {
        var _this = this;
        this.management.addNewCode(dataItem)
            .then(function () {
            _this.gridState.closeEditor(_this.grid);
        });
    };
    PmCodesGridComponent.prototype.loadLookups = function () {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.performanceReviewCodeTypes })
            .then(function (codeTypesLookup) {
            _this.codeTypesLookup = codeTypesLookup;
        });
    };
    PmCodesGridComponent.prototype.initServices = function () {
        this.stateManagement.init('PerformanceManagementConfigureCodes');
        this.columnManagementService.init('PerformanceManagementConfigureCodes');
        this.columnManagementService.initGroup(this.columnsStateName, 6);
    };
    PmCodesGridComponent.prototype.refreshGrid = function () {
        if (!this.m_codes) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.m_codes, this.gridState.state);
    };
    return PmCodesGridComponent;
}());
export { PmCodesGridComponent };
