import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {UserExtendedSettings ,IUserExtendedSettings } from '../../models/index';


@Injectable()
export class UserExtendedSettingsMapService {
    public isLiveChatEnabled:string = 'isLiveChatEnabled';
      public mapUserSettings(dto: IUserExtendedSettings): UserExtendedSettings {
        let response:  UserExtendedSettings = new  UserExtendedSettings();
        
        if( dto[this.isLiveChatEnabled])
          response.isLiveChatEndabled = true;
        return response;
      }

}