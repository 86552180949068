import * as _ from 'lodash';
import { IColumnSettings } from '../../../core/models/index';
import { ExceptionColumn } from './exception-column';

export class ExceptionConsoleRollupState {

  public get allColumns(): IColumnSettings[] {
    return this.dynamicColumns ? _.concat(this.columns, this.dynamicColumns) : this.columns;
  }

  public exceptionOnly: boolean;

  public columnsMap: StringMap<IColumnSettings>;

  public columns: IColumnSettings[];
  public dynamicColumns: IColumnSettings[];

  public mapColumns(): void {
    this.columnsMap = _.keyBy(this.allColumns, (column: IColumnSettings) => {
      return column.name;
    });
  }

  public createColumns(): void {
    this.columns = [
      {
        name: 'organization',
        description: 'Organization',
        readonly: false,
        displayed: true,
        width: 180,
        filter: 'text'
      },
      {
        name: 'paycycle',
        description: 'Payroll Cycle',
        readonly: false,
        displayed: true,
        width: 200,
        filter: null
      },
      {
        name: 'totalExceptionsCount',
        description: 'Exceptions',
        readonly: false,
        displayed: true,
        width: 150,
        filter: 'numeric'
      },
      {
        name: 'missingPunches',
        description: 'Pending/Approved Missing Punches',
        readonly: false,
        displayed: true,
        width: 170,
        filter: null
      }
    ];
  }

  public setDynamicColumns(cols: ExceptionColumn[]): void {
    let columns: IColumnSettings[] = _.map(cols, (col: ExceptionColumn) => {
      return {
        name: col.exceptionId,
        description: col.exceptionName,
        readonly: false,
        displayed: true,
        width: 120,
        filter: 'numeric',
        payload: {
          exceptionField: col.fieldName,
          countAsException: true
        }
      };
    });
    this.dynamicColumns = columns;
    this.mapColumns();
  }


  public setState(initialState: StringMap<boolean>): void {
    this.createColumns();
    _.forEach(this.columns, (column: IColumnSettings) => {
      column.displayed = initialState[column.name];
    });
  }

}
