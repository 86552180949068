import { UnprocessedTimecardStats, IUnprocessedTimecardStats } from './../../models/timecards/unprocessed-timecard-stats';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService, DateTimeService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { TimecardsMapService } from './timecards-map.service';

import { PayCycle } from '../../../organization/models/index';
import { ApplicationStateBusService, SsoNavigationService } from '../../../organization/services/index';
import {
  ITimecardsSummary, TimecardsSummary, IIndividualTimecardsContainer, IndividualTimecardsContainer,
  PayrollExportSubmitResults, IPayrollExportSubmitResult, IPayrollExportSubmitResults
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { TimecardApprovalRequest, PayCycleApprovalRequest } from '../../models/timecards/timecards-approval-request';

@Injectable()
export class TimecardsApiService {
  individualTimecardsCacheData = [];
  constructor(
    private mapService: TimecardsMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private dateTimeService: DateTimeService,
    applicationStateBusService: ApplicationStateBusService,
    private ssoService: SsoNavigationService) {
    applicationStateBusService.resetCache$.subscribe((cacheName: string) => {
      if (cacheName !== 'TimecardsApiServiceCache') {
        return;
      }
      this.clearIndividualTimecardsCacheData();
    });
  }
  public navigateSSO(menuItem: string, orgLevel_id: number): void {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${appConfig.api.sso}/${menuItem}?orglevel=${orgLevel_id}`;
    this.ssoService.navigationToV5(url);
  }
  public recalculateTimecards(req: number[], payCycle: PayCycle): Promise<any> {
    Assert.isNotNull(req, 'req');
    const startDate = payCycle.startDate;
    const endDate = payCycle.endDate;
    return this.recalculateTimecardsByRange(req, startDate, endDate);
  }

  public recalculateTimecardsByRange(req: number[], startDate: Date, endDate: Date): Promise<any> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getTimecardsApiRoot()}/${timeAndAttendanceConfig.api.timecards.orgLevel.recalculateTimecards}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, req,
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      });
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.clearIndividualTimecardsCacheData();
        return response.data;
      });
    return promise;
  }

  public approveTimecards(orgLevelId: number, req: TimecardApprovalRequest): Promise<any> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getOrgLevelTimecardsApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.timecards.orgLevel.approveTimecards}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, req);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.clearIndividualTimecardsCacheData();
        return response.data;
      });
    return promise;
  }

  public approveTimecard(empId: number, payCycle: PayCycle): Promise<any> {

    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(payCycle, 'payCycle');

    const url: string = `${this.getEmployeeTimecardsApiRoot(empId)}/${timeAndAttendanceConfig.api.timecards.employee.approve}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, null,
      {
        startDate: dateTimeUtils.convertToDtoString(payCycle.startDate),
        endDate: dateTimeUtils.convertToDtoString(payCycle.endDate),
      });
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.clearIndividualTimecardsCacheData();
        return response.data;
      });
    return promise;
  }

  public unapproveTimecards(orgLevelId: number, req: number[], payCycle: PayCycle): Promise<any> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getOrgLevelTimecardsApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.timecards.orgLevel.unapproveTimecards}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, req,
      {
        startDate: dateTimeUtils.convertToDtoString(payCycle.startDate),
        endDate: dateTimeUtils.convertToDtoString(payCycle.endDate),
      });
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.clearIndividualTimecardsCacheData();
        return response.data;
      });
    return promise;
  }

  public unapproveTimecard(empId: number, payCycle: PayCycle): Promise<any> {

    Assert.isNotNull(empId, 'empId');
    Assert.isNotNull(payCycle, 'payCycle');

    const url: string = `${this.getEmployeeTimecardsApiRoot(empId)}/${timeAndAttendanceConfig.api.timecards.employee.unapprove}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, null,
      {
        startDate: dateTimeUtils.convertToDtoString(payCycle.startDate),
        endDate: dateTimeUtils.convertToDtoString(payCycle.endDate),
      });
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        this.clearIndividualTimecardsCacheData();
        return response.data;
      });
    return promise;
  }

  public getTimecardsSummary(orgLevelId: number, payCycle: PayCycle): Promise<TimecardsSummary> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(payCycle, 'payCycle');

    const url: string = `${this.getOrgLevelTimecardsApiRoot(orgLevelId)}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        orgLevelId: orgLevelId,
        startDate: dateTimeUtils.convertToDtoString(payCycle.startDate),
        endDate: dateTimeUtils.convertToDtoString(payCycle.endDate),
      });
    let promise: Promise<TimecardsSummary> = this.apiUtilService.request<ITimecardsSummary, Meta>(request)
      .then((response: ResponseBody<ITimecardsSummary, Meta>) => {
        return this.mapService.mapTimecardsSummary(response.data);
      });
    //let promise: Promise<TimecardsSummary> = Promise.resolve(this.mapService.mapTimecardsSummary(null));
    return promise;
  }

  public clearIndividualTimecardsCacheData() {
    this.individualTimecardsCacheData = [];
  }

  public clearOldIndividualTimecardsCacheData() {
    const currentTime = new Date();
    this.individualTimecardsCacheData = this.individualTimecardsCacheData.filter(item => {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
      if (seconds < appConfig.cacheInvalidateIndividualTimeCardTime) {
        return item;
      }
    });
  }

  public getIndividualTimecards(employeeId: number, payCycle: PayCycle): Promise<IndividualTimecardsContainer> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(payCycle, 'payCycle');

    let promise: Promise<IndividualTimecardsContainer> = null;

    // Check if data already fetched for the selected employee and paycycle within 60 seconds.
    // If yes than return the cached data
    // Else fetch data from API, store it in cache and return it.
    const cacheId = employeeId + '_' + payCycle.startDate.getTime() + '_' + payCycle.endDate.getTime();
    this.clearOldIndividualTimecardsCacheData();
    const cacheItemIndex = this.individualTimecardsCacheData.findIndex(item => item.cacheId === cacheId);
    if (cacheItemIndex > -1) {
      const seconds = this.dateTimeService.GetDiffSeconds(new Date(this.individualTimecardsCacheData[cacheItemIndex].timestamp), new Date());
      if (seconds < appConfig.cacheInvalidateIndividualTimeCardTime) {
        // In here means data present in cache.
        promise = new Promise((resolve) => {
          resolve(this.individualTimecardsCacheData[cacheItemIndex].data);
        });
        return promise;
      }
    }

    const url: string = `${this.getEmployeeTimecardsApiRoot(employeeId)}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        startDate: dateTimeUtils.convertToDtoString(payCycle.startDate),
        endDate: dateTimeUtils.convertToDtoString(payCycle.endDate),
      });
    promise = this.apiUtilService.request<IIndividualTimecardsContainer, Meta>(request)
      .then((response: ResponseBody<IIndividualTimecardsContainer, Meta>) => {
        this.individualTimecardsCacheData.push({
          cacheId,
          data: this.mapService.mapIndividualTimecards(response.data),
          timestamp: new Date().getTime()
        });
        return this.mapService.mapIndividualTimecards(response.data);
      });

    //let promise: Promise<IndividualTimecardsContainer> = Promise.resolve(this.mapService.mapIndividualTimecards(IndividualTimecardsContainer.mock));
    return promise;
  }

  public getExportedTimecards(organizationId: number, startDate: Date, endDate: Date): Promise<FileBlobResponse> {
    Assert.isNotNull(organizationId, 'organizationId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getExportTimecardsApiRoot()}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(
      url,
      {
        organizationId,
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate),
      }
    );
    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);

    return promise;
  }

  public submitPayroll(organizationId: number, startDate: Date, endDate: Date): Promise<PayrollExportSubmitResults> {
    const url: string =
      `${this.getOrganizationTaApiRoot(organizationId)}/${timeAndAttendanceConfig.api.organization.payroll}/${timeAndAttendanceConfig.api.organization.exports}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, {},
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate),
      });
    let promise: Promise<PayrollExportSubmitResults> = this.apiUtilService.request<IPayrollExportSubmitResults, Meta>(request)
      .then((response: ResponseBody<IPayrollExportSubmitResults, Meta>) => {
        return this.mapService.mapPayrollExportSubmitResults(response.data);
      });
    return promise;
  }

  public getPendingTimecards(orgLevelId: number, startDate: Date, endDate: Date, empIds:number[] = null): Promise<UnprocessedTimecardStats> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getOrgLevelTimecardsApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.timecards.orgLevel.unprocessedTimecards}`;
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(
      url, empIds,
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate),
      }
    );
    let promise: Promise<UnprocessedTimecardStats> = this.apiUtilService.request<IUnprocessedTimecardStats, Meta>(request)
      .then((response: ResponseBody<IUnprocessedTimecardStats, Meta>) => {
        return this.mapService.mapUnprocessedTimecardStats(response.data);
      });
    return promise;
  }

  private getApiRoot(): string {
    return this.apiUtilService.getApiRoot();
  }

  private getEmployeeTimecardsApiRoot(employeeId: number): string {
    return `${this.getTimecardsApiRoot()}/${timeAndAttendanceConfig.api.timecards.employee.root}/${employeeId}`;
  }
  private getOrganizationTaApiRoot(organizationId: number): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.organization.root}/${organizationId}`;
  }
  private getOrgLevelTimecardsApiRoot(orgLevelId: number): string {
    return `${this.getTimecardsApiRoot()}/${timeAndAttendanceConfig.api.orglevel.root}/${orgLevelId}`;
  }
  private getTimecardsApiRoot(): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.timecards.root}`;
  }
  private getExportTimecardsApiRoot(): string {
    return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.export.root}/${timeAndAttendanceConfig.api.export.download.root}`;
  }
}
