import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { StaffingPointsTable, StaffingPredictorOrganizationInfo, StaffingPointsTableRow, StaffingPointsTableCell } from '../../models/index';
import { IApplicationConfig, appConfig } from '../../../../app.config';

@Component({
    moduleId: module.id,
    selector: 'slx-staffing-predictor-matrix',
    templateUrl: 'predictor-matrix.component.html',
    styleUrls: ['predictor-matrix.component.scss']
})

export class PredictorMatrixComponent implements OnInit {

    @Input()
    public organizationInfo: StaffingPredictorOrganizationInfo;

    @Input()
    public set staffingPointsTables(value: StaffingPointsTable[]) {
        this.m_staffingPointsTables = value;
        if (this.m_staffingPointsTables !== null) {
            this.currentTable = _.first(this.m_staffingPointsTables);
        }
    }

    @Input()
    public five_star_v2_enabled: boolean;

    public get staffingPointsTables(): StaffingPointsTable[] {
        return this.m_staffingPointsTables;
    }

    public currentTable: StaffingPointsTable;

    public appConfig: IApplicationConfig;

    private m_staffingPointsTables: StaffingPointsTable[];

    public maxRatingArray: number = 5;

    constructor() { }

    public ngOnInit(): void {
        this.appConfig = appConfig;
    }

    public isInRnRange(value: number, row: StaffingPointsTableRow): boolean {
        return value >= row.lowLimitRn && (value <= row.highLimitRn || row.highLimitRn === 0);
    }


    public isInTotalRange(value: number, cell: StaffingPointsTableCell): boolean {
        return value >= cell.lowLimitTotal && (value <= cell.highLimitTotal || cell.highLimitTotal === 0);
    }
}
