import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService, CacheUtilService } from '../../../common/services/index';
import { MetaMapService } from '../../../core/services/index';
import { Actions, FieldsMeta, Meta, ResponseBody } from '../../../core/models/index';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../configuration.config';

import { TelepunchLocation, ITelepunchLocation, TelepunchLocationsContainer } from '../../models/index';
import { TelepunchLocationsMapService } from './telepunch-locations-map.service';

import * as _ from 'lodash';

@Injectable()
export class TelepunchLocationsApiService {

    constructor(private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: TelepunchLocationsMapService,
        private metaMapService: MetaMapService,
        private cacheUtilService: CacheUtilService) {
    }

    public getTelepunchLocations(): Promise<TelepunchLocationsContainer> {
        const url: string = this.getTelepunchesRoot();
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
        return this.apiUtilService.request<ITelepunchLocation[], Meta>(request).then((response: ResponseBody<ITelepunchLocation[], Meta>) => {
            let fieldsMeta: FieldsMeta = response.meta as FieldsMeta;
            let container: TelepunchLocationsContainer = this.mapService.mapToTelepunchLocationsContainer(response.data, fieldsMeta);
            return container;
        });
    }

    public saveTelepunchLocations(items: TelepunchLocation[]): Promise<any> {
        const url: string = this.getTelepunchesRoot();
        const body: any = this.mapService.mapToITelepunchLocations(items);
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
        return this.apiUtilService.request<any[], Meta>(request);
    }

    public deleteTelepunchLocation(item: TelepunchLocation): Promise<any> {
        const url: string = `${this.getTelepunchesRoot()}/${item.id}`;
        let request: HttpRequest<any> = this.urlParamsService.createDeleteRequest(url);
        return this.apiUtilService.request<any[], Meta>(request);
    }

    private getTelepunchesRoot(): string {
        return `${this.getConfigurationApiRoot()}/${configurationConfig.api.configuration.telepunchLocations.root}`;
    }

    private getConfigurationApiRoot(): string {
        return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }

}
