<slx-spinner [show]="isLoading" [heightNotFill]="true">
  <div class="ta-console-widget departures">

    <div class="header">
      <div class="header-inner">

        <div class="departures-item header-item">
          <i class="header-main-icon fas fa-arrow-alt-circle-up" aria-hidden="true"></i>
          <h4 class="departures-label">Departures</h4>
          <span class="badge dark-label">{{recordsSum}}</span>
        </div>
        <div class="spacer"></div>
        <div class="ot-item header-item">
          <i class="far fa-calendar-plus" aria-hidden="true"></i>
          <h4 class="overtime-label">Overtime</h4>
          <span [ngClass]="{'badge':true, 'bad-label': otSum > 0}">{{otSum}}</span>
        </div>
        <div class="collapse-toggle header-item" (click)="toggleState()" slxPropertyInitState controlId="isDeparturesWigetCollapsed" [(propertySave)]="collapsed">
          <i class="toggle-icon far fa-angle-down" aria-hidden="true" *ngIf="!collapsed"></i>
          <i class="toggle-icon far fa-angle-up" aria-hidden="true" *ngIf="collapsed"></i>
        </div>
      </div>
    </div>

    <div [ngClass]="{'grid-container': true, 'collapsed': collapsed}">
      <slx-departures-grid [records]="arrivalDeparturesContainer?.departures" (onOutFieldClick)="onOutFieldClickHandler($event)"
        (onScheduledFieldClick)="onScheduledFieldClickHandler($event)" (onOvertimeFieldClick)="onOvertimeFieldClickHandler($event)"
        (onDifferenceFieldClick)="onDifferenceFieldClickHandler($event)" (onRowSelected)="onRowSelectedHandler($event)">
      </slx-departures-grid>
    </div>
  </div>
</slx-spinner>
