
<div [attr.class]="cssClass()">
  <input type="checkbox" class="slx-check-box-input"
    [disabled]="readonly ? true : null"
    [(ngModel)]="value"
    [checked]="value"
    [attr.id]="fieldName"
    name="fieldName" >
    <label class="slx-checkbox-label" [attr.for]="fieldName" >
      <div class="slx-checkbox-helper">
        <span class="check"></span>
        <span class="box"></span>
      </div>
      <div class="slx-checkbox-text">{{caption}}</div>
    </label>
  </div>
