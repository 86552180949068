import { LocationUnit, ShiftGroupDefinition } from '../../../organization/models/index';
import { IDatedObject } from '../../../core/models/index';

import * as _ from 'lodash';
import * as moment from 'moment';
import { ComparisonItem } from './comparison-item';
import { EntityDefinition } from './entity-definition';

export class Comparison implements IDatedObject {
  public date: moment.Moment;
  public idealHours: ComparisonItem;
  public idealPPD: ComparisonItem;
  public budgetedHours: ComparisonItem;
  public budgetedPPD: ComparisonItem;
  public pendingMessagesCount: number;
  public absentCount: number;
  public ptoRequests: number;
  public overtimeEmployees: number;
  public lateCount: number;
  public shiftGroup: ShiftGroupDefinition;
  public unit: LocationUnit;
  public orgLevel: EntityDefinition;
  public isEmpty: boolean;

  public get uniqId(): string {
    return [this.orgLevel.id,
    this.unit ? this.unit.id : 0,
    this.shiftGroup ? this.shiftGroup.id : 0
    ].join('_');
  }

  public static mergeComparisons(...comparisons: any[]): Comparison {
    const mergedComparison: Comparison = new Comparison();

    mergedComparison.pendingMessagesCount = 0;
    mergedComparison.absentCount = 0;
    mergedComparison.ptoRequests = 0;
    mergedComparison.overtimeEmployees = 0;
    mergedComparison.lateCount = 0;

    mergedComparison.idealPPD = new ComparisonItem(0, 0);
    mergedComparison.idealHours = new ComparisonItem(0, 0);
    mergedComparison.budgetedPPD = new ComparisonItem(0, 0);
    mergedComparison.budgetedHours = new ComparisonItem(0, 0);

    _.forEach(comparisons, (comparison: Comparison) => {
      mergedComparison.date = comparison.date;
      mergedComparison.orgLevel = comparison.orgLevel;

      mergedComparison.lateCount += comparison.lateCount;
      mergedComparison.absentCount += comparison.absentCount;
      mergedComparison.ptoRequests = Math.max(comparison.ptoRequests, mergedComparison.ptoRequests);
      mergedComparison.overtimeEmployees += comparison.overtimeEmployees;
      mergedComparison.pendingMessagesCount += comparison.pendingMessagesCount;

      mergedComparison.idealPPD.limit += comparison.idealPPD.limit;
      mergedComparison.idealPPD.value += comparison.idealPPD.value;
      mergedComparison.idealHours.limit += comparison.idealHours.limit;
      mergedComparison.idealHours.value += comparison.idealHours.value;

      mergedComparison.budgetedPPD.limit += comparison.budgetedPPD.limit;
      mergedComparison.budgetedPPD.value += comparison.budgetedPPD.value;
      mergedComparison.budgetedHours.limit += comparison.budgetedHours.limit;
      mergedComparison.budgetedHours.value += comparison.budgetedHours.value;
    });

    return mergedComparison;
  }
}
