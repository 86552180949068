export * from './employee-sections-transactions/employee-sections-transactions.component';
export * from './employee-sections-policy-assignments/employee-sections-policy-assignments.component';
export * from './employee-sections-policy-assignments/ending-balance-dialog/ending-balance-dialog.component';
export * from './employee-sections-policy-assignments/dynamic-policy-dialog/dynamic-policy-dialog.component';

import { EmployeeSectionsTransactionsComponent } from './employee-sections-transactions/employee-sections-transactions.component';
import { EmployeeSectionsPolicyAssignmentsComponent } from './employee-sections-policy-assignments/employee-sections-policy-assignments.component';
import { EndingBalanceDialogComponent } from './employee-sections-policy-assignments/ending-balance-dialog/ending-balance-dialog.component';
import { DynamicPolicyDialogComponent } from './employee-sections-policy-assignments/dynamic-policy-dialog/dynamic-policy-dialog.component';
import { ResetBalanceDialogComponent } from './employee-sections-transactions/reset-balance-dialog/reset-balance-dialog.component';
export const componentsAccrualsSection: any[] = [
  EmployeeSectionsPolicyAssignmentsComponent,
  EmployeeSectionsTransactionsComponent,
  EndingBalanceDialogComponent,
  DynamicPolicyDialogComponent,
  ResetBalanceDialogComponent
];
