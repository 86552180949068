import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { ValuePairChartDataService } from '../../services/value-pair-chart-data/value-pair-chart-data.service';
var DonutChartValuePairComponent = /** @class */ (function () {
    function DonutChartValuePairComponent(changeDetector, dataService) {
        this.changeDetector = changeDetector;
        this.dataService = dataService;
        this.onClick = new EventEmitter();
    }
    DonutChartValuePairComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modelLoaded = this.dataService.onModelLoaded.subscribe(function (model) {
            _this.model = model;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    DonutChartValuePairComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DonutChartValuePairComponent.prototype.handleClick = function (event) {
        if (event.originalEvent && event.originalEvent.type === 'contextmenu') {
            return;
        }
        this.onClick.emit(event);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DonutChartValuePairComponent.prototype, "modelLoaded", void 0);
    return DonutChartValuePairComponent;
}());
export { DonutChartValuePairComponent };
