export interface IEmployeePunchRequest {
    id: number;
    employeeId: number;
    punchTime: string;
    punchDate: string;
    requestOn: string;
    isApproved: number;
    punchTypes: any;
    deleteInd: boolean;
    employeeName: string;
    positionName: string;
    departmentName: string;
}

export class EmployeePunchRequest {
    public id: number;
    public employeeId: number;
    public punchTime: Date;
    public punchDate: Date;
    public requestOn: Date;
    public isApproved: number;
    public punchTypes: any;
    public deleteInd: boolean;
    public employeeName: string;
    public positionName: string;
    public departmentName: string;

    public get checkIsApproved(): string{
        return this.isApproved == 0 ? 'N' : 'Y';
    }

    public get punchTypeName(): string {
        return this.punchTypes == 0 ? 'IN' : 'OUT';
    }
}