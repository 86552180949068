import { Provider } from '@angular/core';
import { ConfirmOptions } from '../confirm-dialog/confirm-dialog.component';
import { DialogOptions2 } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';
var ConfirmDialog2Component = /** @class */ (function () {
    function ConfirmDialog2Component(options, modalService, confirmOptions) {
        this.options = options;
        this.modalService = modalService;
        this.confirmOptions = confirmOptions;
        this.dialogResult = false;
    }
    Object.defineProperty(ConfirmDialog2Component.prototype, "okButtonText", {
        get: function () {
            if (!this.confirmOptions || !this.confirmOptions.buttonOKtext) {
                return 'OK';
            }
            return this.confirmOptions.buttonOKtext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmDialog2Component.prototype, "cancelButtonText", {
        get: function () {
            if (!this.confirmOptions || !this.confirmOptions.buttonCanceltext) {
                return 'Cancel';
            }
            return this.confirmOptions.buttonCanceltext;
        },
        enumerable: true,
        configurable: true
    });
    ConfirmDialog2Component.openDialog = function (title, message, modalService, callback, options) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.topmost = true;
        dialogOptions.zIndexForTopMost = 102;
        dialogOptions.message = message;
        var confirmOptions = options;
        if (!options) {
            confirmOptions = new ConfirmOptions();
            confirmOptions.showOK = true;
            confirmOptions.showCancel = true;
        }
        dialogOptions.width = confirmOptions.width || 350;
        dialogOptions.height = confirmOptions.height || 200;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: ConfirmOptions,
                useValue: confirmOptions
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog2(ConfirmDialog2Component, title, dialogOptions, resolvedProviders, callback);
        return dialog;
    };
    ConfirmDialog2Component.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ConfirmDialog2Component.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return ConfirmDialog2Component;
}());
export { ConfirmDialog2Component };
