/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./weather-current-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./weather-current-details.component";
var styles_WeatherCurrentDetailsComponent = [i0.styles];
var RenderType_WeatherCurrentDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WeatherCurrentDetailsComponent, data: {} });
export { RenderType_WeatherCurrentDetailsComponent as RenderType_WeatherCurrentDetailsComponent };
export function View_WeatherCurrentDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Low:"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", "\u00B0", ""])), i1.ɵppd(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["High:"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "value"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", "\u00B0", ""])), i1.ɵppd(12, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.minTemp, "1.0-0")); var currVal_1 = _co.scaleLabel; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.maxTemp, "1.0-0")); var currVal_3 = _co.scaleLabel; _ck(_v, 11, 0, currVal_2, currVal_3); }); }
export function View_WeatherCurrentDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "weather-current-details", [], null, null, null, View_WeatherCurrentDetailsComponent_0, RenderType_WeatherCurrentDetailsComponent)), i1.ɵdid(1, 49152, null, 0, i3.WeatherCurrentDetailsComponent, [], null, null)], null, null); }
var WeatherCurrentDetailsComponentNgFactory = i1.ɵccf("weather-current-details", i3.WeatherCurrentDetailsComponent, View_WeatherCurrentDetailsComponent_Host_0, { maxTemp: "maxTemp", minTemp: "minTemp", pressure: "pressure", humidity: "humidity", scaleLabel: "scaleLabel" }, {}, []);
export { WeatherCurrentDetailsComponentNgFactory as WeatherCurrentDetailsComponentNgFactory };
