/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accruals-transactions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../accruals-transactions-toolbar/accruals-transactions-toolbar.component.ngfactory";
import * as i5 from "../accruals-transactions-toolbar/accruals-transactions-toolbar.component";
import * as i6 from "../../../../common/services/modal/modal.service";
import * as i7 from "../../services/accruals-transactions-management.service";
import * as i8 from "../../../../common/services/column-settings/column-management.service";
import * as i9 from "../../../../common/services/state-management/state-management.service";
import * as i10 from "../../../../common/services/device-detector/device-detector.service";
import * as i11 from "../../../../organization/services/lookup/lookup-api.service";
import * as i12 from "../accruals-transactions-grid/accruals-transactions-grid.component.ngfactory";
import * as i13 from "../accruals-transactions-grid/accruals-transactions-grid.component";
import * as i14 from "../../../../app-settings/services/app-settings-manage.service";
import * as i15 from "@angular/router";
import * as i16 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i17 from "../../../../common/services/component-state/component-state-storage.service";
import * as i18 from "../../services/accruals-management.service";
import * as i19 from "../../services/accruals-api.service";
import * as i20 from "./accruals-transactions.component";
var styles_AccrualsTransactionsComponent = [i0.styles];
var RenderType_AccrualsTransactionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccrualsTransactionsComponent, data: {} });
export { RenderType_AccrualsTransactionsComponent as RenderType_AccrualsTransactionsComponent };
export function View_AccrualsTransactionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-accruals-transactions-toolbar", [], null, null, null, i4.View_AccrualsTransactionsToolbarComponent_0, i4.RenderType_AccrualsTransactionsToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i5.AccrualsTransactionsToolbarComponent, [i6.ModalService, i7.AccrualsTransactionsManagementService, i8.ColumnManagementService, i9.StateManagementService, i10.DeviceDetectorService, i11.LookupApiService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-accruals-transactions-grid", [], null, null, null, i12.View_AccrualsTransactionsGridComponent_0, i12.RenderType_AccrualsTransactionsGridComponent)), i1.ɵdid(8, 245760, null, 0, i13.AccrualsTransactionsGridComponent, [i7.AccrualsTransactionsManagementService, i10.DeviceDetectorService, i14.AppSettingsManageService, i15.Router, i15.ActivatedRoute], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_AccrualsTransactionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-accruals-transactions", [], null, null, null, View_AccrualsTransactionsComponent_0, RenderType_AccrualsTransactionsComponent)), i1.ɵprd(4608, null, i8.ColumnManagementService, i8.ColumnManagementService, [i16.ColumnSettingsStorageService]), i1.ɵprd(4608, null, i9.StateManagementService, i9.StateManagementService, [i17.ComponentStateStorageService, i16.ColumnSettingsStorageService]), i1.ɵprd(512, null, i7.AccrualsTransactionsManagementService, i7.AccrualsTransactionsManagementService, [i18.AccrualsManagementService, i19.AccrualsApiService]), i1.ɵdid(4, 245760, null, 0, i20.AccrualsTransactionsComponent, [i7.AccrualsTransactionsManagementService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var AccrualsTransactionsComponentNgFactory = i1.ɵccf("slx-accruals-transactions", i20.AccrualsTransactionsComponent, View_AccrualsTransactionsComponent_Host_0, {}, {}, []);
export { AccrualsTransactionsComponentNgFactory as AccrualsTransactionsComponentNgFactory };
