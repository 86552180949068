import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';

export class EmployeeCallLogNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToSendClockMessage(): void {
    this.router.navigate(['send_clock_message'], {
      relativeTo: this.route.parent
    });
  }

  public navigateToLogPhoneCall(): void {
    this.router.navigate(['log_phone_call'], {
      relativeTo: this.route.parent
    });
  }

  public navigateToSendSms(): void {
    this.router.navigate(['send_sms'], {
      relativeTo: this.route.parent
    });
  }
}
