import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';

import { EmployeeDefinitionsApiService, LookupService, EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { Lookup, LookupType, Department, Organization, BenefitClassDefinition, EmployeeBenefitClass } from '../../../../../../organization/models/index';
import { EmployeeToRehire, EmployeeRehire } from '../../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';

import { AppSettingsManageService } from '../../../../../../app-settings/services/index';
import { AppServerConfig } from '../../../../../../app-settings/model/app-server-config';
import { NotificationsApiService } from '../../../../../../portal/services';
import { IconAccess } from '../../../../../../portal/models';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-rehire',
  templateUrl: 'employee-rehire.component.html',
  styleUrls: ['employee-rehire.component.scss']
})
export class EmployeeRehireComponent implements OnInit {
  public isV6ReHireEnhacementEnabled: boolean = false;
  @Input('employee')
  public set emp(emp: EmployeeToRehire) {
    this.employee = emp;
  }
  public get departmentEditable(): boolean {
    return !!this.requestToRehire.organization;
  }
  public get positionEditable(): boolean {
    return !!this.requestToRehire.department;
  }
  public get payPolicyEditable(): boolean {
    return !!this.requestToRehire.department;
  }
  public get shiftEditable(): boolean {
    return !!this.requestToRehire.department;
  }
  public get unitEditable(): boolean {
    return !!this.requestToRehire.department;
  }
  public positionLookup: Lookup;
  public departmentLookup: Lookup;
  public unitLookup: Lookup;
  public shiftDiffPolicyLookup: Lookup;
  public payPolicyLookup: Lookup;
  public employee: EmployeeToRehire;
  public appConfig: IApplicationConfig;
  public requestToRehire: EmployeeRehire;
  public getRateLimit : number;
  public currentOrgLevelId: number;
  public bswiftEnabled: boolean = false;
  public employeeBenefitClass: EmployeeBenefitClass;
  public benefitClassesLookup: Lookup<BenefitClassDefinition>;
  public employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter;
  public get payrollNumberValidation(): string {
    return EmployeeValidatorAdapter.payrollNumberValidation;
  }
  @ViewChild('form', { static: false })
  public ngForm: NgForm;
  public optInTemplateText: string = '';
 
  public isMessageCenterEnabled: boolean = false;
  public isNgpUser: boolean = false;
  public config: AppServerConfig;
  constructor(
    private lookupService: LookupService,
    private appSettingsManageService: AppSettingsManageService,
    private employeeDefinitionsApiService: EmployeeDefinitionsApiService,
    private notificationApiService: NotificationsApiService,
    employeeActivitiesValidatorAdapter: EmployeeValidatorAdapter,
  ) { 
    this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.requestToRehire = new EmployeeRehire();
    this.requestToRehire.employeeId = this.employee.employeeId;
    this.requestToRehire.oldOrganizationId = this.employee.organizationId;
    this.requestToRehire.oldEmployeeType = this.employee.employeeType;
    this.requestToRehire.date = moment().startOf('day').toDate();
    this.requestToRehire.payrollNumber = this.employee.payrollNumber;
    this.getRateLimit = this.employee.payRateLimit;
    this.onRehireDateUpdate(this.requestToRehire.date);

    this.populateData();
    this.getSettings();
    this.checkMessageCenterAccess();
    this.getSMSTemplate();
    this.getNextPayrollNumber();
  }

  public onRehireDateUpdate(date: Date): void {
    if (date) {
      this.requestToRehire.benefitClassEffectiveDate = date;
    }
  }

  private async populateData(): Promise<void> {
    const [departmentLookup, benefitClassesLookup, employeeBenefitClass] = await Promise.all([
      this.lookupService.getLookup({ lookupType: LookupType.department, employeeId: this.employee.employeeId }),
      this.lookupService.getLookup({ lookupType: LookupType.benefitClasses, employeeId: this.employee.employeeId }),
      this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employee.employeeId)
    ]);

    const department: Department = _.find(departmentLookup.items, (d: Department) => d.id === this.employee.departmentId);
    this.currentOrgLevelId = department.orgLevelId;

    this.benefitClassesLookup = benefitClassesLookup;
    this.employeeBenefitClass = employeeBenefitClass;
    if (employeeBenefitClass && this.benefitClassesLookup) {
      const benefitClassDefinition: BenefitClassDefinition =
        _.find(this.benefitClassesLookup.items, benefitClass => benefitClass.id === employeeBenefitClass.benefitClass.id);

      if (benefitClassDefinition) {
        this.requestToRehire.benefitClass = benefitClassDefinition;
      }
    }

    this.requestToRehire.mobilePhone = this.employee.mobilePhone;
  }

  private async getSettings(): Promise<void> {
    this.config = await this.appSettingsManageService.getAppServerConfig(); 
    this.bswiftEnabled = this.config.bswiftIntegrationEnabled;
    this.isV6ReHireEnhacementEnabled = this.config.isV6ReHireEnhacementEnabled;
    this.checkForNGPFlag();
  }

  public async checkMessageCenterAccess(): Promise<void> {
    const orgLevelId: number = this.employee.orgLevelId;
    const access: IconAccess = await this.notificationApiService.getIconAccessCheck(orgLevelId);
      this.isMessageCenterEnabled = access.isMessageCenterEnabled;
  }

  public getSMSTemplate(): void {
    const orgLevelId: number = this.employee.orgLevelId;
    this.employeeDefinitionsApiService.getSmsTemplate(orgLevelId)
      .then(template => {
        this.optInTemplateText = template;
      }).catch((reason: any) => {
      });
  }
  
  public onOrganizationChanged(org: Organization): void {
    this.clearOrgDependences();
    if (!this.requestToRehire.organization) {
      return;
    }
    this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.requestToRehire.organization.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((departmentLookup: Lookup) => {
        this.departmentLookup = departmentLookup;
        this.getNextPayrollNumber();
      });
    this.checkForNGPFlag();
  }

  public clearOrgDependences(): void {
    this.requestToRehire.department = null;
    this.clearDepDependences();
  }

  public clearDepDependences(): void {
    this.requestToRehire.position = null;
    this.requestToRehire.payPolicy = null
    this.requestToRehire.shiftDiffPolicy = null;
    this.requestToRehire.unit = null;
    this.requestToRehire.benefitClass = null;
  }

  public onDepartmentChanged(dep: Department): void {
    this.clearDepDependences();
    if (!this.requestToRehire.department) {
      return;
    }
    this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((positionLookup: Lookup) => {
        this.positionLookup = positionLookup;
      });
    this.lookupService.getLookup({ lookupType: LookupType.payPolicy, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((payPolicyLookup: Lookup) => {
        this.payPolicyLookup = payPolicyLookup;
      });
    this.lookupService.getLookup({ lookupType: LookupType.shiftDiffPolicy, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((shiftDiffPolicyLookup: Lookup) => {
        this.shiftDiffPolicyLookup = shiftDiffPolicyLookup;
      });
    this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
      .then((unitLookup: Lookup) => {
        this.unitLookup = unitLookup;
      });
    if (this.bswiftEnabled) {
      this.lookupService.getLookup({ lookupType: LookupType.benefitClasses, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
        .then((benefitClassLookup: Lookup) => {
          this.benefitClassesLookup = benefitClassLookup;
          this.updateBenefitClass();
        });
    }
  }

  private updateBenefitClass(): void {
    if (this.employeeBenefitClass && this.benefitClassesLookup) {
      const benefitClassDefinition: BenefitClassDefinition =
        _.find(this.benefitClassesLookup.items, benefitClass => benefitClass.id === this.employeeBenefitClass.benefitClass.id);

      if (benefitClassDefinition) {
        this.requestToRehire.benefitClass = benefitClassDefinition;
      }
    }
  }
  private async getNextPayrollNumber() {
    if(!this.requestToRehire.organization) {
      return;
    }
    if(!this.isNgpUser) {
    const nextPayrollNumber = await this.employeeDefinitionsApiService.getNextPayrollNumber(
      this.requestToRehire.organization.orgLevelId,
      this.requestToRehire.employeeId
    );
    this.requestToRehire.payrollNumber = nextPayrollNumber;
    }
  }
  public checkForNGPFlag(){
    this.isNgpUser = this.config.IsNextgenPayrollEnabled || false;    
  }
}
