/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audit-trail-limited-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../audit-trail-limited/audit-trail-limited.component.ngfactory";
import * as i3 from "../../../../../core/services/server-filter/server-filter.service";
import * as i4 from "../audit-trail-limited/audit-trail-limited.component";
import * as i5 from "../../../services/audit-trail/audit-trail-api.service";
import * as i6 from "../../../../../organization/services/org-level/org-level-watch.service";
import * as i7 from "../../../services/audit-trail/audit-trail-map.service";
import * as i8 from "./audit-trail-limited-dialog.component";
import * as i9 from "../../../../../common/models/dialog-options";
import * as i10 from "../../../../../common/services/modal/modal.service";
import * as i11 from "../../../models/audit-trail/audit-trail-limited-req";
var styles_AuditTrailLimitedDialogComponent = [i0.styles];
var RenderType_AuditTrailLimitedDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AuditTrailLimitedDialogComponent, data: {} });
export { RenderType_AuditTrailLimitedDialogComponent as RenderType_AuditTrailLimitedDialogComponent };
export function View_AuditTrailLimitedDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "dialog-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "dialog-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "slx-audit-trail-limited", [], null, null, null, i2.View_AuditTrailLimitedComponent_0, i2.RenderType_AuditTrailLimitedComponent)), i1.ɵprd(512, null, i3.ServerFilterService, i3.ServerFilterService, []), i1.ɵdid(4, 770048, null, 0, i4.AuditTrailLimitedComponent, [i5.AuditTrailApiService, i3.ServerFilterService, i6.OrgLevelWatchService, i7.AuditTrailMapService], { req: [0, "req"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "dialog-modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.request; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_AuditTrailLimitedDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-audit-trail-limited-dialog", [], null, null, null, View_AuditTrailLimitedDialogComponent_0, RenderType_AuditTrailLimitedDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.AuditTrailLimitedDialogComponent, [i9.DialogOptions, i10.ModalService, i11.AuditTrailLimitedReq], null, null)], null, null); }
var AuditTrailLimitedDialogComponentNgFactory = i1.ɵccf("slx-audit-trail-limited-dialog", i8.AuditTrailLimitedDialogComponent, View_AuditTrailLimitedDialogComponent_Host_0, {}, {}, []);
export { AuditTrailLimitedDialogComponentNgFactory as AuditTrailLimitedDialogComponentNgFactory };
