<div class="search-results">

  <div class="info-bar row">
    <div class="cols col-xs-6 col-sm-6 col-md-6">
      <button type="button" class="theme-button-apply theme-iconed-button back-button" (click)="onBackClick()">
        <i class="back-icon fa fa-angle-left" aria-hidden="true"></i>
        <span class="back-label">Back</span> 
      </button>
    </div>
    <div class="cols col-xs-6 col-sm-6 col-md-6">
      <span class="total-results">Total results found: {{ data?.length || 0 }}</span>
    </div>
  </div>

  <div class="results">
    <kendo-grid class="grid" [data]="gridView">

      <kendo-grid-column media="(max-width: 800px)" title="Employees">
        <ng-template kendoGridCellTemplate let-dataItem>
          <h4>
            <a href="#" [employeeSectionLink]="dataItem.employee.id">
              <span>{{ dataItem.employee?.name }}</span>
            </a>
          </h4>

          <dl>
            <dt>Badge Id</dt>
            <dd>{{ dataItem.badgeId }}</dd>

            <dt>Status</dt>
            <dd>{{ dataItem.status }}</dd>

            <dt>Position</dt>
            <dd>{{ dataItem.position.name }}</dd>

            <dt>Date Hired</dt>
            <dd>{{ dataItem.employee?.dateHired | amDateFormat: appConfig.dateFormat }}</dd>

            <dt>Date terminated</dt>
            <dd>{{ dataItem.dateTerminated | amDateFormat: appConfig.dateFormat }}</dd>

            <dt>Organization</dt>
            <dd>{{ dataItem.organization?.name }}</dd>

            <dt>Department</dt>
            <dd>{{ dataItem.department?.name }}</dd>

          </dl>
        </ng-template>
      </kendo-grid-column>


      <kendo-grid-column media="(min-width: 800px)" field="employee.name" title="Employee" [class]="'nowrap-text'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <a href="#" [employeeSectionLink]="dataItem.employee.id">
            <span>{{ dataItem.employee?.name }}</span>
          </a>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 800px)" field="badgeId" title="Badge id" [class]="'nowrap-text'">
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 800px)" field="status" title="Status" [class]="'nowrap-text'">
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 800px)" title="Date Hired" [class]="'nowrap-text'">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.employee.dateHired | amDateFormat: appConfig.dateFormat}}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 800px)" title="Date Terminated" [class]="'nowrap-text'">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.dateTerminated">{{ dataItem.dateTerminated | amDateFormat: appConfig.dateFormat }}</span>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 800px)" field="organization.name" title="Organization" [class]="'nowrap-text'">
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 800px)" field="department.name" title="Department" [class]="'nowrap-text'">
      </kendo-grid-column>
      <kendo-grid-column media="(min-width: 800px)" field="position.name" title="Position" [class]="'nowrap-text'">
      </kendo-grid-column>
    </kendo-grid>
  </div>

</div>
