
export interface ITimeclockRestrictionDefinition {
  id: number;
  name: string;
}

export class TimeclockRestrictionDefinition {
  public id: number;
  public name: string;
}


