<div>
  <h4>Offer Open shift to:</h4>
  <div>
    <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="offerToApproachingOT" fieldName="offerToApproachingOT" caption="Employees with projected OT"
        [(ngModel)]="settings.offerToApproachingOT">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
  <div>
    <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="offerToSameDay" fieldName="offerToSameDay" caption="Employees scheduled same day" [(ngModel)]="settings.offerToSameDay">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
  <div>
    <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="offerToSecondaryPositions" fieldName="offerToSecondaryPositions" caption="Employees with Secondary position"
        [(ngModel)]="settings.offerToSecondaryPositions">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
  <div>
    <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="offerToPositionGrouping" fieldName="offerToPositionGrouping" caption="Employees with a Position that can Replace the Shift Position"
        [(ngModel)]="settings.offerToPositionGrouping">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
  <div>
    <slx-input-decorator *ngIf="isAbilityForAdminToTurnOnAndOffAutoShiftPickUp" className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="allowAutoShiftPickUpByEmployee" fieldName="allowAutoShiftPickUpByEmployee" caption="Allow auto shift pick up by employee"        [(ngModel)]="settings.allowAutoShiftPickUpByEmployee">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
  <h4>Notification Options:</h4>
  <div>
    <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="notifyOnFirstPost" fieldName="notifyOnFirstPost" caption="Send Text when schedule is first posted"
        [(ngModel)]="settings.notifyOnFirstPost">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
  <div>
    <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="notifyOnAvailability" fieldName="notifyOnAvailability" caption="Send Text reminder to employees with availability"
        [(ngModel)]="settings.notifyOnAvailability">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
  <div>
    <slx-input-decorator *ngIf="!isIncreaseFrequencyofShiftsPostedNotifications" className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="notifyWeekly" fieldName="notifyWeekly" caption="Send weekly reminder about open shifts"
        [(ngModel)]="settings.notifyWeekly" (change)="notifyCheckChange()">
  </slx-checkbox-input>
  </slx-input-decorator>
  </div>
  <div>
    <slx-input-decorator *ngIf="isIncreaseFrequencyofShiftsPostedNotifications" className="slx-no-border slx-no-label slx-no-error-block notifyCount">
      <slx-checkbox-input slx-input name="notifyWeekly" fieldName="notifyWeekly" caption="Send reminder about open shifts"
        [(ngModel)]="settings.notifyWeekly" (change)="notifyCheckChange()">
      </slx-checkbox-input>
    </slx-input-decorator>
    <div *ngIf="isIncreaseFrequencyofShiftsPostedNotifications && settings.notifyWeekly" class="notifyTypeCount">
      <input slx-input type="number" placeholder="" id="notify" name="notifyCount" (valueChange)="handleNotifyType($event)" [(ngModel)]="settings.reminderCount" className="notifyCount" (input)="validateNumber($event)" min="1"
        required tabindex="1" [disabled]="settings.notifyDaysAndWeekly==0 && settings.notifyWeekly">
        <slx-dropdown-list class="slx-wide notifyType"        valueField="notifyId"
        titleField="notifyType"
        [options]="notifyType"
        [(ngModel)]="notifyData"
        (ngModelChange)="onNotifyChange()"
      ></slx-dropdown-list>
    </div>

  </div>
  <div>
    <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block">
      <slx-checkbox-input slx-input name="notifySenioritiesFirst" fieldName="notifySenioritiesFirst" caption="Notify Employees in order of seniority"
        [(ngModel)]="settings.notifySenioritiesFirst">
      </slx-checkbox-input>
    </slx-input-decorator>
  </div>
</div>
