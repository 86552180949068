import { Component, OnInit, OnDestroy, Input, Output, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy, CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { RolesRightModuleRow, RolesRightRow, IRolesRow, RoleRight, RoleColumnsState, Role } from '../../../models/index';
import { RolesManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-roles-right-grid',
  templateUrl: 'roles-right-grid.component.html',
  styleUrls: ['roles-right-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesRightGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public container: RolesRightModuleRow;
  @Input()
  public containerType: string;
  @Output()
  public roleRightChanged: EventEmitter<number>;

  public appConfig: IApplicationConfig;
  public records: RolesRightRow[];
  public gridState: KendoGridStateHelper<IRolesRow<RoleRight>>;
  public roleColumnsState: RoleColumnsState;

  private managementService: RolesManagementService;
  public sort: SortDescriptor[] = [{ field: "displaySequence", dir: "asc" }];

  @unsubscribe()
  private onChangeNotifySubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private filterChangedSubscription: Subscription;
  @unsubscribe()
  private columnsStateSubscription: Subscription;

  private changeDetector: ChangeDetectorRef;

  constructor(managementService: RolesManagementService, changeDetector: ChangeDetectorRef) {
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<IRolesRow<RoleRight>>();
    this.roleRightChanged = new EventEmitter<number>();
    this.roleColumnsState = new RoleColumnsState();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.columnsStateSubscription = this.managementService.rolesColumnsStateChanged$
      .subscribe((state: RoleColumnsState) => {
        this.roleColumnsState = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.onChangeNotifySubscription = this.managementService.changeNotify$.subscribe((v: string): void => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.filterChangedSubscription = this.managementService.roleRightFilterChanged$.subscribe(({ filter, type }: { filter: CompositeFilterDescriptor, type: string }): void => {
      if (this.containerType === type) {
        this.gridState.state.filter = filter;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['container']) {
      this.records = this.container.childRows;
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }

  public onAccessClick(row: RolesRightRow, roleId: number): void {
    row.setNextStatus(roleId);
    this.roleRightChanged.emit(roleId);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    const ordered = orderBy(this.records, this.sort);
    this.gridState.view = { data: this.setSort(ordered), total: ordered.length };
  }
  
  private setSort(records: any[]) {
    records.forEach((record:any)=>{
      return record.roles.forEach((right:any)=>{
         right.rights = _.sortBy( right.rights, (item) => item.displaySequence)
      })
    })
    return records;
  }
}

