import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';
import { IUniqueValidatorConf } from './common-validators-models';

const UNIQUE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => UniqueValidator),
  multi: true
};

@Directive({
  selector: '[slxUnique][formControlName],[slxUnique][formControl],[slxUnique][ngModel]',
  providers: [UNIQUE_VALIDATOR]
})
export class UniqueValidator implements Validator, OnChanges {
  @Input()
  public slxUnique: IUniqueValidatorConf;

  private m_validator: ValidatorFn;
  private m_onChange: () => void;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxUnique']) {
      this._createValidator();
      if (this.m_onChange) {
        this.m_onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return  this.m_validator(c);
  }

  public registerOnValidatorChange(fn: () => void): void {
    this.m_onChange = fn;
  }

  private _createValidator(): void {
    this.m_validator = CommonValidators.unique(this.slxUnique);
  }
}
