import * as tslib_1 from "tslib";
import { BenefitDetailsBasic } from './benefit-details-basic';
var BenefitDetailsCalcMethods;
(function (BenefitDetailsCalcMethods) {
    BenefitDetailsCalcMethods["Flat"] = "Flat";
    BenefitDetailsCalcMethods["OptionsRates"] = "OptionsRates";
    BenefitDetailsCalcMethods["Formula"] = "Formula";
    BenefitDetailsCalcMethods["C401K"] = "C401K";
    BenefitDetailsCalcMethods["FormulaWithOption"] = "FormulaWithOption";
})(BenefitDetailsCalcMethods || (BenefitDetailsCalcMethods = {}));
var BenefitDetailsCalcMethod = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitDetailsCalcMethod, _super);
    function BenefitDetailsCalcMethod(id, name, title) {
        return _super.call(this, id, name, title) || this;
    }
    Object.defineProperty(BenefitDetailsCalcMethod.prototype, "isFlat", {
        get: function () {
            return this.id === BenefitDetailsCalcMethods.Flat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCalcMethod.prototype, "isOptionsRates", {
        get: function () {
            return this.id === BenefitDetailsCalcMethods.OptionsRates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCalcMethod.prototype, "isFormula", {
        get: function () {
            return this.id === BenefitDetailsCalcMethods.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCalcMethod.prototype, "is401K", {
        get: function () {
            return this.id === BenefitDetailsCalcMethods.C401K;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCalcMethod.prototype, "isFormulaWithOption", {
        get: function () {
            return this.id === BenefitDetailsCalcMethods.FormulaWithOption;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsCalcMethod;
}(BenefitDetailsBasic));
export { BenefitDetailsCalcMethod };
