import { HostListener, Directive, Input } from '@angular/core';

@Directive({
  selector: '[href]'
})
export class PreventDefaultLinkDirective {
  @Input()
  public href: string;

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    this.preventDefault(event);
  }

  private preventDefault(event: Event): void {
    if (this.href.length === 0 || this.href === '#') {
      event.preventDefault();
    }
  }
}
