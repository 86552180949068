import {
  IDepartment,
  Department,
  ShiftDefinition,
  IShiftDefinition,
  LocationUnit,
  ILocationUnit,
  ConstraintDefinition,
  IConstraintDefinition,
  Position,
  IPosition,
  IScheduleAbsence,
  ScheduleAbsence,
  ISchedulePartialAbsence,
  SchedulePartialAbsence
} from '../lookup/index';

import {
  IEmployeeDefinition, EmployeeDefinition
} from '../employee/index';

import {
  IPrescheduledOvertime,
  PrescheduledOvertime
} from './index';

export interface IScheduledShiftDefinition {
  department: IDepartment;
  position: IPosition;
  shift: IShiftDefinition;
  unit: ILocationUnit;
  constraint: IConstraintDefinition;
  absence: IScheduleAbsence;
  partialAbsence: ISchedulePartialAbsence;
  prescheduledOvertime: IPrescheduledOvertime;
  start: any;
  end: any;
  duration: string;
}

export class ScheduledShiftDefinition {
  public department: Department;
  public position: Position;
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public constraint: ConstraintDefinition;
  public absence: ScheduleAbsence;
  public partialAbsence: SchedulePartialAbsence;
  public prescheduledOvertime: PrescheduledOvertime;
  public start: Date;
  public end: Date;
  public duration: number; //in mseconds
}
