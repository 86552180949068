import { OnInit, Provider } from '@angular/core';
import { ModalService } from '../../../../common/index';
import { LookupApiService } from '../../../../organization/services/index';
import { DialogOptions } from '../../../../common/models/index';
import * as _ from 'lodash';
import { RemovePositionRequest } from '../../../models/index';
var ConfigurePositionsReassignPopupComponent = /** @class */ (function () {
    function ConfigurePositionsReassignPopupComponent(options, modalService, lookupApiService, reassignRequest) {
        this.modalService = modalService;
        this.lookupApiService = lookupApiService;
        this.effectiveDate = new Date();
        this.m_positionsFiltered = [];
        this.options = options;
        this.reassignRequest = reassignRequest;
    }
    Object.defineProperty(ConfigurePositionsReassignPopupComponent.prototype, "positionsFiltered", {
        get: function () {
            return this.m_positionsFiltered;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurePositionsReassignPopupComponent.openDialog = function (reassignRequest, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 230;
        dialogOptions.width = 370;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: RemovePositionRequest,
                useValue: reassignRequest
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(ConfigurePositionsReassignPopupComponent, 'Choose position to reassign employees', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.reassignRequest);
        });
        return dialog;
    };
    ConfigurePositionsReassignPopupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lookupApiService.getPositions(null, this.reassignRequest.orgLevelId, true).then(function (positionLookup) {
            _this.m_positionsFiltered = _.filter(positionLookup, function (p) {
                return p.id !== _this.reassignRequest.itemIdToDelete;
            });
        });
    };
    ConfigurePositionsReassignPopupComponent.prototype.onOk = function () {
        if (this.position)
            this.reassignRequest.ressignedPositionId = this.position.id;
        if (this.effectiveDate)
            this.reassignRequest.effectiveDate = this.effectiveDate;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ConfigurePositionsReassignPopupComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return ConfigurePositionsReassignPopupComponent;
}());
export { ConfigurePositionsReassignPopupComponent };
