import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { PayCycle } from '../../../../organization/models/index';
var SummarySectionBase = /** @class */ (function () {
    function SummarySectionBase(management) {
        this.subscriptions = {};
        this.management = management;
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(SummarySectionBase.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        set: function (value) {
            this.m_container = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    SummarySectionBase.prototype.exportToExel = function () {
        this.todayDateTime = moment().format(appConfig.dateTimeFormatUS);
        this.grid.saveAsExcel();
    };
    SummarySectionBase.prototype.exportToPdf = function () {
        this.todayDateTime = moment().format(appConfig.dateTimeFormatUS);
        this.grid.saveAsPDF();
    };
    SummarySectionBase.prototype.setupManagementService = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.management.onLoaded$.subscribe(function () {
            _this.onDataLoaded();
        });
        this.subscriptions.orgLevel = this.management.orgLevel$.subscribe(function (o) {
            _this.orgLevelName = o.name;
        });
    };
    SummarySectionBase.prototype.destroy = function () { };
    SummarySectionBase.prototype.refreshGrid = function () {
        if (!this.m_container || !this.m_container.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_container.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], SummarySectionBase.prototype, "subscriptions", void 0);
    return SummarySectionBase;
}());
export { SummarySectionBase };
