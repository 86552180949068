import { Assert } from '../../../framework/assert/assert';
export class PageScrollUtilService {

  /**
   * Util method to check whether a given variable is either undefined or null
   * @param variable
   * @returns {boolean} true the variable is undefined or null
   */
  public static isUndefinedOrNull(variable: any): boolean {
    return (typeof variable === 'undefined') || variable === undefined || variable === null;
  }

  public static extractElementPosition(document: Document, scrollTargetElement: HTMLElement): {top: number, left: number} {
    Assert.isNotNull(document, 'document');
    Assert.isNotNull(scrollTargetElement, 'scrollTargetElement');
    let body: HTMLElement = document.body;
    let docEl: HTMLElement = document.documentElement;

    let windowPageYOffset: number = document.defaultView && document.defaultView.pageYOffset || undefined;
    let windowPageXOffset: number = document.defaultView && document.defaultView.pageXOffset || undefined;

    let scrollTop: number = windowPageYOffset || docEl.scrollTop || body.scrollTop;
    let scrollLeft: number = windowPageXOffset || docEl.scrollLeft || body.scrollLeft;

    let clientTop: number = docEl.clientTop || body.clientTop || 0;
    let clientLeft: number = docEl.clientLeft || body.clientLeft || 0;


    if (PageScrollUtilService.isUndefinedOrNull(scrollTargetElement)) {
      // No element found, so return the current position to not cause any change in scroll position
      return { top: scrollTop, left: scrollLeft };
    }
    let box: ClientRect = scrollTargetElement.getBoundingClientRect();

    let top: number = box.top + scrollTop - clientTop;
    let left: number = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
  }
}
