<div class="badge-container badge-container-blue badge-border" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <span class="container-child badge-title badge-title-blue">Your Rating Results</span>
    <slx-star-rating [readonly]="true"
        [rating]="model.rating.value"
        [maxValue]="model.rating.maxValue"
        [filledItemClassName]="'full-blue-big-star'"
        [emptyItemClassName]="'far empty-blue-big-star'"
        class="container-child star-rating-margin">
    </slx-star-rating>
    <div class="container-child badge-note badge-note-blue">
        <div class="points-box">{{model.adjustedTotalNurseHRDPoints + model.adjustedRnHRDPoints + model.adjustedTotalNurseOnWeekendHRDPoints + model.totalNursingTurnOverPoints + model.rnTurnOverPoints + model.administratorDeparturesPoints}}</div>
        out of 380 points
    </div>
    <span class="container-child badge-note badge-note-blue" >How is this calculated? <em class="fa fa-info-circle" aria-hidden="true" (click)="onShowDetails()"></em> 
        <div *ngIf="!hasRightErrors" class="un-seen">
            <span class="hide-4k">Click the star rating you want to acheive to see the values change below. This will indicate the needed values
                <span class="hide-14"> for your staffing levels for that reflected rating.</span> 
                <span class="hide-s hide-14"> You can</span>
                <span *ngIf="hideToPrint"> for your staffing levels for that reflected rating.</span><br/>
            </span>
            <span class="badge-fb">Note:</span> These values are an approximation based on a combination of current and historical hours.
        </div>
    </span>
</div>
<div class="badge-container badge-border badge-block badge-container-1" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-main">AVG DAILY CENSUS</div>
    <div class="badge-side badge-fb"><div class="badge-fbr">{{model.avgDailyCensus | number: ".2-2"}}</div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max"></div>
</div>
<div class="badge-container badge-border badge-block badge-container-1" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-main">AVG DAILY CENSUS FOR WEEKENDS</div>
    <div class="badge-side badge-fb"><div class="badge-fbr">{{model.avgDailyCensusWeekEnd | number: ".2-2"}}</div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max"></div>
</div>
<div class="badge-container badge-border badge-block badge-container-2" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-header">TOTAL NURSING HRD</div>
    <div class="badge-header position-align">CASE-MIX ADJ</div>
    <div class="badge-fb badge-sp-head">
        <span>
            <span>{{model.totalNursingAvgDailyHours | number: ".2-2"}}</span>
            <span class="spacious-sym">/</span>
            <span>{{model.avgDailyCensus | number: ".2-2"}}</span>
            <span class="spacious-sym" [ngClass]="{'cond-margin': checkForCondMargin(model, model.totalNursingAvgDailyHours)}">=</span>
            <span>{{model.totalNursingHRD | number: ".2-2"}}</span>
        </span>
    </div>
    <div class="badge-side badge-fb"><div class="badge-fbr">{{model.adjustedTotalNurseHRD| number: ".2-2"}}</div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max"><div class="small-points-box">{{model.adjustedTotalNurseHRDPoints}}</div> out of 100 points</div>
    <div class="badge-footer">HOURS / CENSUS = HRD</div>
</div>

<div class="badge-container badge-border badge-block badge-container-2" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-header">RN HRD</div>
    <div class="badge-fb badge-sp-head">
        <span>
            <span>{{model.rNAvgDailyHours | number: ".2-2"}}</span>
            <span class="spacious-sym">/</span>
            <span>{{model.avgDailyCensus | number: ".2-2"}}</span>
            <span class="spacious-sym" [ngClass]="{'cond-margin': checkForCondMargin(model, model.rNAvgDailyHours)}">=</span>
            <span>{{model.rnHRD | number: ".2-2"}}</span>
        </span>
    </div>
    <div class="badge-side badge-fb"><div class="badge-fbr">{{model.adjustedRnHRD | number: ".2-2"}}</div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max"><div class="small-points-box">{{model.adjustedRnHRDPoints}}</div> out of 100 points</div>
    <div class="badge-footer">HOURS / CENSUS = HRD</div>
</div>

<div class="badge-container badge-border badge-block badge-container-2" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-header">TOTAL NURSING WEEKEND HRD</div>
    <div class="badge-fb badge-sp-head">
        <span>
            <span>{{model.totalNursingAvgDailyHoursWeekEnd | number: ".2-2"}}</span>
            <span class="spacious-sym">/</span>
            <span>{{model.avgDailyCensusWeekEnd | number: ".2-2"}}</span>
            <span class="spacious-sym" [ngClass]="{'cond-margin': checkForCondMargin(model, model.totalNursingAvgDailyHoursWeekEnd)}">=</span>
            <span>{{model.totalNursingHRDWeekEnd | number: ".2-2"}}</span>
        </span>
    </div>
    <div class="badge-side badge-fb"><div class="badge-fbr">{{model.adjustedTotalNurseOnWeekendHRD | number: ".2-2"}}</div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max"><div class="small-points-box">{{model.adjustedTotalNurseOnWeekendHRDPoints}}</div> out of 50 points</div>
    <div class="badge-footer">HOURS / CENSUS = HRD</div>
</div>

<div class="badge-container badge-border badge-block badge-container-3" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-main badge-block">TOTAL NURSING TURNOVER</div>
    <div class="badge-side badge-fb"><div class="badge-fbr">{{model.totalNursingTurnOver| number: ".2-2"}}</div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max">
<div class="small-points-box">{{model.totalNursingTurnOverPoints}}</div> out of 50 points</div>
</div>
<div class="badge-container badge-border badge-block badge-container-3" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-main badge-block">RN TURNOVER<!-- <div class="badge-main6"></div> --></div>
    <div class="badge-side badge-fb badge-fbl"><div class="badge-fbr"> {{model.rnTurnOver| number: ".2-2"}} </div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max"><div class="small-points-box">{{model.rnTurnOverPoints}}</div> out of 50 points</div>
</div>
<div class="badge-container badge-border badge-block badge-container-3" [ngClass]="{ 'badge-border-nrb': !hasRightErrors }">
    <div class="badge-main badge-block">ADMINISTRATOR DEPARTURES<!-- <div class="badge-main6"></div> --></div>
    <div class="badge-side badge-fb"><div class="badge-fbr">{{model.administratorDepartures}}</div></div>
    <div class="badge-side sup" *ngIf="!hideToPrint"></div>
    <div class="badge-main badge-center-max"><div class="small-points-box">{{model.administratorDeparturesPoints}}</div> out of 30 points
    </div>
</div>