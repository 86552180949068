import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';


export function clearChanges(): PropertyDecorator {
  return function decorate(target: any, key: string | symbol): void {
    let ngOnDestroyFunction: Function = target['ngOnDestroy'];
    let ngOnDestroy: Function = function (): void {
      if (this) {
        let service: any = this[key];
        if (service.clearChanges) {
          service.clearChanges();
        }
        if (ngOnDestroyFunction) {
          ngOnDestroyFunction.call(this);
        }
      }
    };
    target['ngOnDestroy'] = ngOnDestroy;
  };
}

export function clearChangesParam(): ParameterDecorator {
  return function decorate(target: any, key: string | symbol): void {
    let ngOnDestroyFunction: Function = target['ngOnDestroy'];
    let ngOnDestroy: Function = function (): void {
      if (this) {
        let service: any = this[key];
        if (service.clearChanges && this.constructor && this.constructor.name) {
          service.clearChanges(this.constructor.name);
        }
        if (ngOnDestroyFunction) {
          ngOnDestroyFunction.call(this);
        }
      }
    };
    target['ngOnDestroy'] = ngOnDestroy;
  };
}
