import * as tslib_1 from "tslib";
import { ResponseBody, Meta } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitListMapService } from './benefit-list-map.service';
import { BenefitDetailsMapService } from './../benefit-details/index';
var BenefitListApiService = /** @class */ (function () {
    function BenefitListApiService(mapService, apiUtilService, urlParamsService, commonService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.commonService = commonService;
    }
    BenefitListApiService.prototype.getBenefitList = function (orgLevelId, loadExpired) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + benefitsConfig.api.benefits.linesInfo;
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, loadExpired: !!loadExpired });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToBenefitList(response.data); })];
            });
        });
    };
    BenefitListApiService.prototype.getBenefitPlanType = function (orgLevelId, groupId, loadExpired) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + benefitsConfig.api.benefits.providersWithEnrollments;
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId, groupId: groupId, loadExpired: !!loadExpired });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.commonService.mapToBenefitPlanProviders(response.data); })];
            });
        });
    };
    BenefitListApiService.prototype.getBenefitPlanHeaders = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + benefitsConfig.api.benefits.workplace;
                request = this.urlParamsService.createGetRequest(url, { orgLevelId: orgLevelId });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.commonService.mapToBenefitPlanConfig(response.data); })];
            });
        });
    };
    BenefitListApiService.prototype.getBenefitPayRollMapping = function (showIgnored) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + benefitsConfig.api.benefits.payrollMapping;
                request = this.urlParamsService.createGetRequest(url, { showIgnored: showIgnored });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.commonService.mapToBenefitPayrollMapping(response.data); })];
            });
        });
    };
    BenefitListApiService.prototype.getpayrollExistingMapping = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + benefitsConfig.api.benefits.payrollExistingMapping;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.commonService.mapToBenefitPayrollExistingMappings(response.data); })];
            });
        });
    };
    BenefitListApiService.prototype.benefitPayRollCreateMapping = function (req) {
        var url = this.getApiRoot() + "/" + benefitsConfig.api.benefits.createMapping;
        var request = this.urlParamsService.createPostRequest(url, req);
        return this.apiUtilService.request(request);
    };
    BenefitListApiService.prototype.getApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.benefits.root;
    };
    return BenefitListApiService;
}());
export { BenefitListApiService };
