import * as _ from 'lodash';
import { ControlValueAccessor } from '@angular/forms';
var CheckBoxComponent = /** @class */ (function () {
    function CheckBoxComponent() {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = false;
        this.m_fieldName = this.getUniqueId('checkbox');
    }
    Object.defineProperty(CheckBoxComponent.prototype, "fieldName", {
        get: function () {
            return this.m_fieldName;
        },
        set: function (value) {
            if (!this.m_fieldName && value) {
                this.m_fieldName = this.getUniqueId(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckBoxComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(this.innerValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    CheckBoxComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.innerValue = value;
        }
    };
    CheckBoxComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    CheckBoxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    CheckBoxComponent.prototype.cssClass = function () {
        var cssClass = 'slx-check-box';
        if (this.value) {
            cssClass += " checked";
        }
        if (this.readonly) {
            cssClass += " disabled";
        }
        if (_.isString(this.className) && _.size(this.className) > 0) {
            cssClass += " " + this.className;
        }
        return cssClass;
    };
    CheckBoxComponent.prototype.getUniqueId = function (name) {
        return name + "_" + Math.random().toString(36).substr(2, 9);
    };
    return CheckBoxComponent;
}());
export { CheckBoxComponent };
