<kendo-grid [data]="gridView"
  scrollable="'scrollable'"
  [sortable]="{ mode: 'multiple' }"
  [sort]="sort"
  (sortChange)="onSortChange($event)"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="skip"
  (pageChange)="onPageChange($event)"
>
  <ng-template kendoGridToolbarTemplate position="top">
    
    <form novalidate class="f-container">
      
      <slx-date-range-ngx 
        name="startEndDate"
        [startDate]="startDate"
        [endDate]="endDate"
        (rangeDateChanged)="onFilterDateChanged($event)"
      ></slx-date-range-ngx>

        <span class="f-spacer"></span>

        <div class="f-buttons">
          <button class="remove-ap-button theme-iconed-button" [disabled]="!isAnyItemSelected || !hasAccessToDelete || !isEditable" (click)="onRemovePointsClicked()">
              <span class="icon-button-content">
                  <span>Remove</span>
                  <i class="fa fa-minus" aria-hidden="true"></i>
              </span>
              </button>
        </div>

    </form>

  </ng-template>

  <kendo-grid-column width="30">
    <ng-template kendoGridHeaderTemplate>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <input type="checkbox" [(ngModel)]="dataItem.selected" (ngModelChange)="onItemSelectionChanged()" [(checked)]="dataItem.selected"
      />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.dateOn.fieldValue" filter="date" width="100">
    <ng-template kendoGridHeaderTemplate>
      Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.dateOn?.fieldValue | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="item.definition.fieldValue" width="100">
    <ng-template kendoGridHeaderTemplate>
      Definition
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.definition?.fieldValue }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="item.value.fieldValue" filter="numeric" width="100">
    <ng-template kendoGridHeaderTemplate>
      Original Points
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.value?.fieldValue | number: ".2-2" }}
    </ng-template>
    <!--
    <ng-template kendoGridFooterTemplate>
      {{ totalPoints | number: ".2-2" }}
    </ng-template>
    -->
  </kendo-grid-column>

  <!--
  <kendo-grid-column field="item.expiration.fieldValue" filter="date" width="100">
    <ng-template kendoGridHeaderTemplate>
      Expiration
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.item?.expiration?.fieldValue | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>
  -->

</kendo-grid>
