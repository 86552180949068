import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, Inject, forwardRef } from '@angular/core';
import { Assert } from '../../../framework/index';
// import { ModalService } from '../../services/modal/modal.service';

@Component({
    moduleId: module.id,
    selector: 'slx-title-window',
    templateUrl: 'slx-title-window.component.html',
    styleUrls: [
        'slx-title-window.component.scss'
    ]
})

export class TitleWindowComponent implements OnInit {

    @Input()
    public title: string | boolean;

    @Input()
    public isModal: boolean = true;

    @Input()
    public showCloseButton: boolean = true;

    @Input()
    public modalId: string;

    @Input()
    public className: string;

    @Input()
    public headerClassName: string;

    @Output()
    public onClose: EventEmitter<any>;

    public onCloseByButton: EventEmitter<string>;



    // private modalService: ModalService;
    // @Inject(forwardRef(() => ModalService)) modalService: ModalService
    constructor() {
        this.onClose = new EventEmitter();
        this.onCloseByButton = new EventEmitter();
        //this.modalService = modalService;
    }

    public ngOnInit(): void {
        _.noop();
    }

    public closeWindow(): void {
        if (this.isModal) {
            Assert.isNotNull(this, 'modalId');
            this.onCloseByButton.emit(this.modalId);
            //     this.modalService.closeWindow(this.modalId);
        }
        this.onClose.next();
    }
}
