<slx-spinner [show]="state.isLoading">
  <slx-generic-grid gridKey="SCHEDULE_COMMENTS">
    <form novalidate>
      <slx-date-range-ngx
        name="startEndDate"
        [startDate]="startDate"
        [endDate]="endDate"
        (rangeDateChanged)="onFilterDateChanged($event)"
        slxControlActiveState
      ></slx-date-range-ngx>
    </form>
  </slx-generic-grid>
</slx-spinner>
