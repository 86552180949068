import { Component, Input, OnInit, Provider } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { mutableSelect } from './../../../../../app/core/decorators';
import { OrgLevel } from './../../../../../app/state-model/models';
import { Lookup, ScheduleCycle } from './../../../../../app/organization';
import { ScheduleRotationsManagementService } from './../../../../../app/scheduler/services/scheduled-rotation-template/schedule-rotations-management.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ChangeManagementService, DeviceDetectorService, ModalService, } from './../../../../../app/common';
import { DialogOptions2, DialogModeSize, DialogOptions } from '../../../../common/models/index';
import { ScheduledGenerateRotationComponent } from '../scheduled-generate-rotation/scheduled-generate-rotation.component';
import { PopperContent } from 'ngx-popper';
import { FilterRotationOptions, IFilterData, ISecondaryPositionData } from './../../../../../app/scheduler/models/schedule-rotation/schedule-rotation-template.model';
import { ScheduledRotationMapService } from './../../../../../app/scheduler/services/scheduled-rotation-template/scheduled-rotation-map.service';
import * as _ from 'lodash';

@Component({
  selector: 'slx-scheduled-rotations-toolbar',
  templateUrl: './scheduled-rotations-toolbar.component.html',
  styleUrls: ['./scheduled-rotations-toolbar.component.scss']
})
export class ScheduledRotationsToolbarComponent implements OnInit {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  @Input()
  public orgLevelId: number;
  private orgLevelSubscription: Subscription;
  public isActionListActive: boolean = true;
  @Input()
  public positionGroupLookup: Lookup;
  @Input()
  public positionLookup: Lookup;
  @Input()
  public shiftsLookup: Lookup;
  @Input()
  public startDate: string;
  @Input()
  public endDate: string;
  @Input()
  public unitsData: any;

  public min: Date = new Date(2000, 0, 1);
  public max: Date = new Date(2079, 11, 31);
  public cycles: ScheduleCycle[] = [];
  public dateFrom = moment().toDate();
  public firstCycleStart: Moment;
  public saveDisable: boolean;
  public modalService: ModalService;
  public filterOptions: FilterRotationOptions;

  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get toolbarWidth(): number {
    return this.isDesktop ? 250 : 150;
  }

  constructor(public manService: ScheduleRotationsManagementService, public devDetector: DeviceDetectorService, public modalServices: ModalService,
    private changeManagementService: ChangeManagementService,
    public mapService: ScheduledRotationMapService) {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
    });
    this.modalService = modalServices;
    this.manService.isDragabble$.subscribe((isDisabled: boolean) => {
      this.saveDisable = isDisabled;
    });

    this.manService.payCycle.subscribe((dateRange: ScheduleCycle) => {
      this.startDate = moment(dateRange.startDate).format('MM/DD/YYYY');
      this.endDate = moment(dateRange.endDate).format('MM/DD/YYYY');
    });

    this.manService.filterRotationsRecord$.subscribe((data: FilterRotationOptions) => {
      this.filterOptions = this.manService.filterRotationsRecord;
    });
  }

  ngOnInit() {
    this.filterOptions = this.manService.filterRotationsRecord;
    this.manService.defaultToolbarFilterOptions = this.manService.generatePositionOptionsCombinations(this.manService.filterRotationsRecord.positionOptions);
    this.manService.getSessionStorageforGenerateRotation();
  }

  public saveRotations() {
    this.manService.saveRotations(this.orgLevelId);
    this.changeManagementService.clearChanges();
  }

  public exportRotations() {
    this.manService.exportRotation$.next(true);
  }

  public generateRotation(): void {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ModalService,
        useValue: this.modalService
      },
      {
        provide: ScheduleRotationsManagementService,
        useValue: this.manService
      }
    ];
    this.modalService.globalAnchor.openDialog2(
      ScheduledGenerateRotationComponent,
      'Generate Rotation',
      dialogOptions,
      resolvedProviders, (result: boolean) => {
        if (result) {
          this.manService.saveGenerateRotation();
        }
      }
    );
  }

  get disableGenerateRotation() {
    return this.manService.generateRotationRecord && this.manService.generateRotationRecord.empIds.length > 0 ? false : true;
  }

  public onSettingsChanged(popperContent: PopperContent, isApply: boolean): void {
    popperContent.hide();
    if (!isApply) {
      this.manService.isToolbarFilterApplied$.next(false);
      return;
    }
    else {
      this.manService.isToolbarFilterApplied$.next(true);
      this.manService.onFilterChange();
    }
  }

  public checkedChange(filterOption: IFilterData, filterArray: IFilterData[]) {
    filterArray.map(x => x.id == filterOption.id ? x.isSelected = true : x.isSelected = false);
  }

  public checkAllChange(showSecondaryPosition: ISecondaryPositionData, event: boolean) {
    showSecondaryPosition.isSelectAll = event;
    showSecondaryPosition.options.map(x => x.isSelected = event);
  }

  public checkBoxChange(showSecondaryPosition: ISecondaryPositionData, filterName: IFilterData[], property: string, event: boolean) {
    let ind = filterName.findIndex(x => x.name === property);
    filterName[ind].isSelected = event;
    showSecondaryPosition.isSelectAll = showSecondaryPosition.options.some(x => x.isSelected);
  }

  public checkAgencyChange(checkAgency: IFilterData, property: string, event: boolean) {
    if (checkAgency.name == property) {
      checkAgency.isSelected = event;
    }
  }

  get isSelectAll(): boolean {
    return this.filterOptions.positionOptions.secondaryPosition.isSelectAll;
  }

}
