<div class="header-container">

    <div class="col title-col">
        <span [ngClass]="{'section-title':true, 'section-collapsed-title': panel?.isCollapsed, 'section-expanded-title': !panel?.isCollapsed}">{{panel.title}}</span>
    </div>

    <div *ngIf="panel?.locked" class="col info-col">
        <span class="changes-info">
            <i class="fa fa-lock" aria-hidden="true"></i>Error, try to reload page
        </span>
    </div>

    <div class="col line-col">
        <hr class="section-separator" />
    </div>

    <button type="button" *ngIf="!state.isEditMode && !panel?.isCollapsed" [disabled]="otherSectionEditing || disableActions || panel?.locked"
        class="theme-button-apply" (click)="onEditClick ()">Edit</button>

    <button type="button" *ngIf="state.isEditMode && !addNewUserMode" class="theme-button-apply margin-r" [disabled]="!section?.isValid || disableActions" (click)="onSaveClick ()">Save</button>
    <button type="button" *ngIf="state.isEditMode && !addNewUserMode" class="theme-button-cancel" [disabled]="disableActions" (click)="onDiscardClick ()">Discard</button>

    <div *ngIf="state.isEditMode && addNewUserMode && forRolesSection">

            <button type="button" *ngIf="!state?.addSelectedRole" class="theme-button-apply margin-r" (click)="onAddRoleClick ()">Add Role</button>
            <button type="button" *ngIf="!state?.addSelectedRole" [disabled]="!section?.hasSelectedRoles" class="theme-button-apply" (click)="onDeleteRoleClick ()">Remove Role</button>

            <button type="button" *ngIf="!!state?.addSelectedRole" [disabled]="!section.hasSelectedOrgLevels" class="theme-button-apply" (click)="onSaveAddedClick ()">Add Selected</button>
            <button type="button" *ngIf="!!state?.addSelectedRole" class="theme-button-cancel" (click)="onCancelAddClick ()">Cancel</button>
    </div>

    <div class="col buttons-col" *ngIf="!panel?.hideButton && !state.isEditMode">
        <button type="button" (click)="panel?.onToggle()" class="collapse-button open-btn" *ngIf="panel?.isCollapsed">
            <i class="fa fa-caret-down" aria-hidden="true"></i>
        </button>
        <button type="button" (click)="panel?.onToggle()" class="collapse-button close-btn" *ngIf="!panel?.isCollapsed">
            <i class="fa fa-caret-up" aria-hidden="true"></i>
        </button>
    </div>

</div>
