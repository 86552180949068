
import {
IAttachmentFile,
EnrollementAttachmentFile
} from './index';

export interface IEmployeeSectionsEnrollmentAttachments {
    benefitsManagementAttachmentsDTOs: IAttachmentFile[];
}

export class EmployeeSectionsEnrollmentAttachments {
    benefitsManagementAttachmentsDTOs:EnrollementAttachmentFile[];
}
