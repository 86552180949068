/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./application.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./application.component";
import * as i4 from "../../../time-and-attendance/store/timecards/timecards.actions";
var styles_ApplicationComponent = [i0.styles];
var RenderType_ApplicationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplicationComponent, data: {} });
export { RenderType_ApplicationComponent as RenderType_ApplicationComponent };
export function View_ApplicationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ApplicationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-application", [], null, null, null, View_ApplicationComponent_0, RenderType_ApplicationComponent)), i1.ɵdid(1, 49152, null, 0, i3.ApplicationComponent, [i4.TimecardsActions], null, null)], null, null); }
var ApplicationComponentNgFactory = i1.ɵccf("slx-application", i3.ApplicationComponent, View_ApplicationComponent_Host_0, {}, {}, []);
export { ApplicationComponentNgFactory as ApplicationComponentNgFactory };
