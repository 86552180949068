import { HttpRequest } from '@angular/common/http';

export class ApiStatus {
  public static requestStart: string = 'requestStart';
  public static requestEnd: string = 'requestEnd';
}

export class ApiEvent {
  public url: string;
  public isBackgroundRequest: boolean;

  constructor(url: string, isBackgroundRequest: boolean = false) {
    this.url = url;
    this.isBackgroundRequest = isBackgroundRequest;
  }
}
