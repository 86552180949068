<slx-spinner [show]="isLoading">
  <div class="modal-content">
    <div class="modal-body">
      <div class="container-fluid">
        <table style="
        justify-content: space-between; 
        align-items: center;
        padding: 0px;
        gap: 10px;
        width: 640px;">
          <tr style="width: 100%;">
              <td class="spn-header">Badge:</td>  
              <td class="spn-header">Pay Period:</td>
              <td class="spn-header">Pay Policy:</td>
              <td class="spn-header">Shift Diff Policy:</td>
              <td class="spn-header">Exempt Ind:</td>
          </tr>
          <tr style="width: 100%;">
            <td class="spn-header-info">{{badge}}</td>  
            <td class="spn-header-info">{{payPeriod}}</td>
            <td class="spn-header-info">{{payPolicy}}</td>
            <td class="spn-header-info">{{shiftDiffPolicy}}</td>
            <td class="spn-header-info">{{exempt}}</td>
          </tr>
        </table>

      </div>
      <br/>
      <div class="container-fluid heading" >
          Punches:
        <ag-grid-angular #agGrid class="ag-theme-balham ta-grid" [rowData]="rowData" [columnDefs]="columnDefs"
        [rowHeight]="rowHeight" id="agPunchGrid" style="min-height:150px; max-height: fit-content;">
        </ag-grid-angular>
      </div>

      <br/><br/>
      <div class="container-fluid heading" >
        Pay Codes/Exceptions:
        <ag-grid-angular #agGrid class="ag-theme-balham ta-grid" [rowData]="rowPayCodeData" [columnDefs]="columnDefPayCode"
        [rowHeight]="rowHeight"  id="agPayCodeGrid" style="min-height:150px ; max-height: fit-content;">
        </ag-grid-angular>
      </div>
    </div>
    <div style="align-content:center;">
      <button type="button" (click)="onClose()" class="btn btn-default btn-close">
        <i class="fa" aria-hidden="true"></i>Close
      </button>
    </div>
  </div>
  </slx-spinner>
  