var RoleSubmenu = /** @class */ (function () {
    function RoleSubmenu() {
    }
    Object.defineProperty(RoleSubmenu.prototype, "isDirty", {
        get: function () {
            return this.isEnabled !== this.initial_isEnabled;
        },
        enumerable: true,
        configurable: true
    });
    RoleSubmenu.prototype.setInitialState = function () {
        this.initial_isEnabled = this.isEnabled;
    };
    return RoleSubmenu;
}());
export { RoleSubmenu };
