
import * as moment from 'moment';

import { EmployeeMessage  } from './employee-message';

export class MessagesThread {
  public id: number;
  public firstMessageTimestamp: Date;
  public messages: EmployeeMessage[];
}
