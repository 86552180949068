import { Component } from '@angular/core';
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { BaseFilterSyncComponent } from '../base-filter-sync.component';
import { dataCheck, IBenefitDeductionsync } from './../../../models/wfm-sync';

@Component({
  selector: 'slx-benefitdeduction-sync',
  templateUrl: './benefitdeduction-sync.component.html',
  styleUrls: ['./benefitdeduction-sync.component.scss'],
})
export class BenefitdeductionSyncComponent extends BaseFilterSyncComponent<IBenefitDeductionsync> {
  constructor(
    wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(wfmSyncService, notificationService, busyService);
  }

  protected syncDataCore(selectedItems: IBenefitDeductionsync[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.benefitdeductionSyncOrg(selectedItems, 'migration')
    );
  }

  protected getSyncItemKey(item: IBenefitDeductionsync): string {
    return '' + item.enrollmentId;
  }

  protected async fetchData(): Promise<IBenefitDeductionsync[]> {
    const response = await this.busyService.busy(
      this.wfmSyncService.GetBenefitDeductionChangeLogs('migration')
    );

    return this.processData(response.data);
  }

  private processData(data: IBenefitDeductionsync[]): IBenefitDeductionsync[] {
    return dataCheck(data);
  }

  protected setGlobalFilter(): void {
    this.globalFilterFunc$.next((item) => {
      const orgId = this.selectedOrganization && this.selectedOrganization.id;
      const deptId = this.selectedDepartment && this.selectedDepartment.id;
      const posId = this.selectedPosition && this.selectedPosition.id;

      return (orgId == null || item.organizationId === orgId) &&
        (deptId == null || item.departmentId === deptId) &&
        (posId == null || item.positionId === posId);
    })
  }
}
