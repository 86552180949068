import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { destroyService, unsubscribe } from "../../../core/decorators";
import { NotificationsManagementService } from "../../services/notifications/notifications-management.service";
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(managementService) {
        this.loadPage = false;
        this.canSave = true;
        this.maxVisibleColumnsToSave = 5;
        this.isDataLoaded = false;
        this.managementService = managementService;
        this.state = {
            isLoading: false
        };
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadStatusSubscription2 = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.isDataLoaded = true;
        });
        this.selectDefinitionSubscription = this.managementService.selectRecord$
            .subscribe(function (group) {
            _this.selectedRole = group;
        });
        this.changesNotifySubscription = this.managementService.changeNotify$.subscribe(function (source) {
            _this.hasChanges = true;
        });
        this.changesClearSubscription = this.managementService.clearChanges$.subscribe(function () {
            _this.hasChanges = false;
        });
    };
    NotificationsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    NotificationsComponent.prototype.onAddDefinition = function () {
        this.loadPage = true;
        this.managementService.addCmd();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "loadStatusSubscription2", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "selectDefinitionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "changesNotifySubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "changesClearSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "managementModeChangesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NotificationsComponent.prototype, "managementModeChangesClearSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", NotificationsManagementService)
    ], NotificationsComponent.prototype, "managementService", void 0);
    return NotificationsComponent;
}());
export { NotificationsComponent };
