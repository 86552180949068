<div class="modal-content group-sms">
  <slx-spinner [show]="state.isLoading" novalidate>
    <div class="modal-body">
      <div>
        <div class="compose-message medium">
          <div class="compose-message-text">By using the SmartLinx Application, you agree to the <a
              href="https://www.smartlinx.com/sms-terms/" target="_blank">Terms and Conditions.</a> Please
            remember, conversations are visible to users with rights to the employee(s).</div>
        </div>
      </div>

      <div class="header-container">
        <textarea name="sms-Text" placeholder="Enter SMS message here (up to {{ maxSmsCharactersLength }} characters)"
          [(ngModel)]="smsText" class="form-control align-textarea" cols="40" rows="3"
          [maxlength]="maxSmsCharactersLength" required>
      </textarea>
        <span class="msgcount">{{maxSmsCharactersLength-smsText.length}}/{{maxSmsCharactersLength}}</span>
      </div>
      <div class="employees-list">
        <slx-employee-grid [fieldDefinition]="fieldDefinition" [employees]="actionEmployees" [isMultiselect]="true"
          [isOptInStatus]="true" (onEmployeesSelect)="employeesSelect($event)"></slx-employee-grid>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon"
          aria-hidden="true"></i>Cancel</button>
      <button type="button" (click)="sendSms()" class="btn green" [disabled]="!canSend || this.smsText.length==0 "><i
          class="fa fa-paper-plane slx-button__icon" aria-hidden="true"></i>Send</button>
    </div>
  </slx-spinner>
</div>
