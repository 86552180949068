import * as tslib_1 from "tslib";
import { ApiService } from '../../../core/services/api/api.service';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { HttpRequest } from '@angular/common/http';
import { configurationConfig } from '../../../configuration/configuration.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { MessagemapService } from '../messagemap.service';
var SlateMessagesApiService = /** @class */ (function () {
    function SlateMessagesApiService(apiService, apiUtilService, urlParamsService, messagemapService) {
        this.apiService = apiService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.messagemapService = messagemapService;
    }
    SlateMessagesApiService.prototype.getSlateMessageGroupsWithDateRange = function (orglevelId, startDate, endDate) {
        var _this = this;
        var url = this.getSlateNotificationUrl() + "/" + configurationConfig.api.slate.notification.orgLevel + "/" + orglevelId + "/" + configurationConfig.api.slate.notification.group.root + "/" + configurationConfig.api.slate.notification.group.messages;
        var request = this.urlParamsService.createGetRequest(url, {
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        return this.apiUtilService.request(request)
            .then(function (response) {
            return _this.messagemapService.mapToSlateMessageGroupInfo(response.data);
        });
    };
    SlateMessagesApiService.prototype.getSlateMessagesByGroupId = function (orglevelId, groupId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getSlateNotificationUrl() + "/" + configurationConfig.api.slate.notification.orgLevel + "/" + orglevelId + "/" + configurationConfig.api.slate.notification.group.root + "/" + groupId + "/" + configurationConfig.api.slate.notification.group.messages;
                        request = new HttpRequest('GET', url);
                        return [4 /*yield*/, this.apiUtilService.request(request)
                                .then(function (response) {
                                return _this.messagemapService.mapToSlateMessageGroupInfoOfGroupId(response.data);
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SlateMessagesApiService.prototype.sendSlateMessage = function (orgLevelId, slateMessage) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, promise;
            return tslib_1.__generator(this, function (_a) {
                url = this.getSlateNotificationUrl() + "/" + configurationConfig.api.slate.notification.orgLevel + "/" + orgLevelId + "/" + configurationConfig.api.slate.notification.group.root + "/" + configurationConfig.api.slate.notification.group.message + "/" + configurationConfig.api.slate.notification.group.send;
                request = this.urlParamsService.createPostRequest(url, slateMessage);
                promise = this.apiUtilService.request(request)
                    .then(function (response) {
                    return response.data;
                });
                return [2 /*return*/, promise];
            });
        });
    };
    SlateMessagesApiService.prototype.getSlateNotificationUrl = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.slate.root + "/" + configurationConfig.api.slate.notification.root;
    };
    SlateMessagesApiService.prototype.getApiRoot = function () {
        return "" + this.apiUtilService.getApiRoot();
    };
    return SlateMessagesApiService;
}());
export { SlateMessagesApiService };
