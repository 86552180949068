import { TypedRecord } from 'typed-immutable-record';
import { AddEmployeeModel } from '../../models/employee-activities/add-employee';

export class AddEmployeeWizardStatus {
  public static initial: number = 0;
  public static started: number = 1;
  public static success: number = 2;
  public static error: number = 3;
  public static pending: number = 4;
  public static validationFailed: number = 5;
}
export interface IAddEmployeeWizardState {
  model: AddEmployeeModel;
  status: number;
  newEmpId: number;
  payrollNumber: string;
}
export interface IAddEmployeeWizardStateRecord extends TypedRecord<IAddEmployeeWizardStateRecord>, IAddEmployeeWizardState {
}
