import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { IAppUserSetting, AppUserSetting } from '../../models/index';


@Injectable()
export class AppUserSettingsMapService {

  public mapAppUserSetting(dto: IAppUserSetting): AppUserSetting {
    Assert.isNotNull(dto, 'dto mapAppUserSetting');
    let data: AppUserSetting = new AppUserSetting();
    data.name = dto.name;
    data.value = JSON.parse(dto.value);
    data.settingsGroup = dto.settingsGroup;
    return data;
  }

  public mapAppUserSettingDto(data: AppUserSetting): IAppUserSetting {
    Assert.isNotNull(data, 'data mapAppUserSetting');
    let dto: IAppUserSetting = {
      name: data.name,
      value: data.value ? JSON.stringify(data.value) : '',
      settingsGroup: data.settingsGroup,
    };
    return dto;
  }

  public mapAppUserSettings(dto: IAppUserSetting[]): AppUserSetting[] {
    return _.map(dto, (d: IAppUserSetting) => this.mapAppUserSetting(d));
  }

  public mapAppUserSettingsDtos(data: AppUserSetting[]): IAppUserSetting[] {
    return _.map(data, (d: AppUserSetting) => this.mapAppUserSettingDto(d));
  }
}


