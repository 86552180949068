import { Pipe, Injectable, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'slxAuditTrail',
  pure: true
})
@Injectable()
export class SlxAuditTrailValuePipe implements PipeTransform {
    public transform(obj?: any, nullLabel: string = '[Blank]'): any {
        if (_.isNil(obj)) {
            
            return nullLabel;
        }

        try {
            if(!isNaN(obj)) return obj;
            let res = JSON.parse(obj);
            res = this.removeFields(res);
            if (res.length === 0) return nullLabel;
            return res;
        }
        catch(e) {
            return obj;
        }

    }

    private removeFields(jsonStr: any): string {
        let result : string[] = [];
        for(let i in jsonStr) {
            if (i != 'Id') {
                result.push( `${i} = ${jsonStr[i]}`);
            }
        }
        if (result.length > 0){
             return '\n' + result.join(',\n');
        }
        return '';
    }
  
}