import * as _ from 'lodash';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper, removeEvent, cancelEvent, ChangeManagementService } from '../../common/index';
import { unsubscribe } from '../../core/decorators/index';
import { IConfigutrationContainer } from '../models/configiration-container.interface';
import { IConfigurationManagementService } from './iconfiguration-management-service';
import { Subscription } from 'rxjs/Subscription';
import { IBaseSelectableModel } from '../../common/models/kendo-grid-helpers/base-selectable-model';

export class ConfigurationComponentHelper<T extends IBaseSelectableModel> {

    public set container(value: IConfigutrationContainer) {
        this.m_container = value;
        this.refreshGrid();
    }

    public get container(): IConfigutrationContainer {
        return this.m_container;
    }

    public lastEditedRowIndex: number;

    public management: IConfigurationManagementService;
    public grid: GridComponent;

    public gridState: KendoGridStateHelper<T>;
    public selectionHelper: KendoGridCustomSelectionHelper<T>;

    private m_container: IConfigutrationContainer;

    private gridRefreshSubscription: Subscription;
    private gridOnSaveScubScription: Subscription;
    private gridOnEditScubScription: Subscription;
    private gridOnCancelScubScription: Subscription;
    private gridOnRemoveScubScription: Subscription;
    private managementLoadedSubscription: Subscription;
    private managementRefreshSubscription: Subscription;
    private managementAddSubscription: Subscription;
    private managementStateChangedSubscription: Subscription;


    public init(): void {

        /*
        if (!this.grid) {
            throw new Error('CrudGridHelper requires kendo grid link');
        }
        */

        if (!this.management) {
            throw new Error('CrudGridHelper requires management service instance of ICrudService');
        }

        if (!this.gridState) {
            throw new Error('CrudHelper requires instance of KendoGridStateHelper');
        }

        if (!this.selectionHelper) {
            throw new Error('CrudHelper requires instance of KendoGridCustomSelectionHelper');
        }

        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((state: State) => {
            this.refreshGrid();
        });

        this.gridOnSaveScubScription = this.gridState.onSave$.subscribe((result: { dataItem: any, isNew: boolean }) => {
            this.onSaveItem(result);
        });

        this.gridOnEditScubScription = this.gridState.onEdit$.subscribe((dataItem: any) => {
            this.management.setSelectedCount(0);
            this.onEditItem(dataItem);
        });

        this.gridOnCancelScubScription = this.gridState.onCancel$.subscribe((event: cancelEvent<any>) => {
            this.onCancelItem(event);
        });

        this.gridOnRemoveScubScription = this.gridState.onRemove$.subscribe((event: removeEvent<any>) => {
            this.onRemoveItem(event.dataItem);
        });

        this.managementLoadedSubscription = this.management.onLoaded$.subscribe((container: IConfigutrationContainer) => {
            this.m_container = container;
            this.gridState.state.filter = null;
            if (this.grid) {
                this.gridState.closeEditor(this.grid);
            }
            this.refreshGrid();
            this.selectionHelper.clearSelection();
            this.management.setSelectedCount(0);
        });

        this.managementRefreshSubscription = this.management.viewRefresh$.subscribe(() => {
            this.refreshGrid();
        });


        this.managementStateChangedSubscription = this.management.onStateChanged$.subscribe((data: any) => {
            if (_.has(data, 'orgLevelChanged')) {
                if (this.grid) {
                    this.gridState.closeEditor(this.grid);
                } else {
                    this.onCancelItem(null);
                }
            }
        });

        this.managementAddSubscription = this.management.addItemCmd$.subscribe((item: T) => {
            if (this.grid) this.gridState.closeEditor(this.grid);
            if (this.grid) this.grid.addRow(item);
            this.management.setSelectedCount(0);
        });

    }

    public destroy(): void {
        if (this.gridRefreshSubscription) this.gridRefreshSubscription.unsubscribe();
        if (this.gridOnSaveScubScription) this.gridOnSaveScubScription.unsubscribe();
        if (this.gridOnEditScubScription) this.gridOnEditScubScription.unsubscribe();
        if (this.gridOnCancelScubScription) this.gridOnCancelScubScription.unsubscribe();
        if (this.gridOnRemoveScubScription) this.gridOnRemoveScubScription.unsubscribe();
        if (this.managementLoadedSubscription) this.managementLoadedSubscription.unsubscribe();
        if (this.managementRefreshSubscription) this.managementRefreshSubscription.unsubscribe();
        if (this.managementAddSubscription) this.managementAddSubscription.unsubscribe();
        if (this.managementStateChangedSubscription) this.managementStateChangedSubscription.unsubscribe();
    }

    // -- api methods

    public addItem(newItem: T): void {
        this.management.onAddItem(newItem);
    }

    public deleteSelected(): void {
        if (this.selectionHelper.singleItemMode) {
            if (this.selectionHelper.selectionLength === 1) {
                let model: any = this.selectionHelper.getSelectedItemAt(0);
                this.onRemoveItem(model);
            }
        } else {
            this.onRemoveItem(this.selectionHelper.selection);
        }
    }

    public onToggleAllSelected(): void {
        this.selectionHelper.onToggleAllSelected();
        this.onSelectionChange(this.selectionHelper.selectionLength);
    }

    public selectionChange(item: any, index: number): void {
        this.selectionHelper.onItemSelectionChange(item);
        this.onSelectionChange(this.selectionHelper.selectionLength);
    }

    public startEdit(item: T, rowIndex: number): void {
        if (this.grid) this.gridState.editHandler({ sender: this.grid, dataItem: item, rowIndex: rowIndex });
    }

    public completeEdit(item: T, rowIndex: number): void {
        if (this.grid) this.gridState.saveHandler({ sender: this.grid, rowIndex: rowIndex, dataItem: item, isNew: this.management.isEditingNewItem });
    }

    public cancelEdit(rowIndex: number): void {
        if (this.grid) {
            this.gridState.cancelHandler({ sender: this.grid, rowIndex: rowIndex });
        } else {
            this.onCancelItem(null);
        }
    }

    //--- handlers ---

    public onSaveItem(result: { dataItem: any, isNew: boolean }): void {
        this.management.onSaveItem(result);
    }

    public onEditItem(dataItem: any): void {
        if (this.gridState.editedRecord) {
            this.management.onCancelEditItem();
        }
        this.lastEditedRowIndex = this.gridState.editedRowIndex;
        this.selectionHelper.clearSelection();
        this.management.onEditItem(dataItem);
    }

    public onCancelItem(event: cancelEvent<any>): void {
        this.management.onCancelEditItem();
        if (this.gridState) {
            this.gridState.editedRowIndex = undefined;
            this.gridState.editedRecord = undefined;
        }
    }

    public onRemoveItem(dataItem: any): void {
        this.management.onRemoveItem(dataItem);
    }

    protected onSelectionChange(count: number): void {
        if (this.grid && !this.gridState.editedRecord && !this.management.editingItem) {
            this.management.setSelectedCount(count);
        }
    }

    protected refreshGrid(): void {
        if (!this.m_container || !this.m_container.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_container.records, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    }
}
