import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { GenericListManagementService } from '../../../organization/services/index';
import { GenericListDefinition, GenericList, GenericListRequest } from '../../../organization/models/generic-list/index';
import { GenericGridComponent } from '../../../organization/components/generic-list/index';
import { appConfig } from '../../../app.config';
import { genericGridConfig } from '../../../organization/components/generic-list/generic-grid/generic-grid.config';
import { genericListConfig } from '../../../organization/generic-list.config';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { IRangeDates } from '../../../common/models/range-dates';
var AttendancePointsComponent = /** @class */ (function () {
    function AttendancePointsComponent(genericListManagementService) {
        this.genericListManagementService = genericListManagementService;
        this.state = {
            isLoading: false
        };
        this.startDate = moment().subtract(1, 'year').toDate();
        this.endDate = moment().toDate();
    }
    AttendancePointsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.genericGridConfig = _.assign({}, genericGridConfig);
        this.genericGridConfig.pdfExport.fileName = 'AttendancePoints.pdf';
        this.genericGridConfig.excelExport.fileName = 'AttendancePoints.xlsx';
        this.genericGridConfig.pdfExport.autoscale = true;
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.application)
            .subscribe(function (value) {
            var orgLevel = value[0], application = value[1];
            if (!orgLevel || !orgLevel.id || !application.id) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentApplication = application;
            _this.loadDefinitions();
        });
        this.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe(function (defs) {
            _this.loadValues();
        });
        this.valuesLoadedSubscription = this.genericListManagementService.onValuesLoaded$.subscribe(function (list) {
            _this.state.isLoading = false;
        });
    };
    AttendancePointsComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    AttendancePointsComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadValues();
    };
    AttendancePointsComponent.prototype.onExportToExcel = function () {
        this.grid.exportToExcel();
    };
    AttendancePointsComponent.prototype.onExportToPdf = function () {
        this.grid.exportToPdf();
    };
    AttendancePointsComponent.prototype.loadDefinitions = function () {
        this.state.isLoading = true;
        this.genericListManagementService.loadListDefinition(this.currentApplication.id, this.currentOrgLevel.id, genericListConfig.attendancePoints);
    };
    AttendancePointsComponent.prototype.loadValues = function () {
        this.state.isLoading = true;
        var req = new GenericListRequest();
        req.startDate = this.startDate;
        req.endDate = this.endDate;
        this.genericListManagementService.loadListValues(this.currentOrgLevel.id, req);
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], AttendancePointsComponent.prototype, "application", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AttendancePointsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AttendancePointsComponent.prototype, "definitionsLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AttendancePointsComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AttendancePointsComponent.prototype, "valuesLoadedSubscription", void 0);
    return AttendancePointsComponent;
}());
export { AttendancePointsComponent };
