import { Directive, Input, forwardRef, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const MIN_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[slxMin][formControlName],[slxMin][formControl],[slxMin][ngModel]',
  /* tslint:enable */
  providers: [MIN_VALIDATOR]
})
export class MinValidator implements Validator, OnChanges {
  @Input()
  public slxMin: string;

  @Input()
  public slxMinStrict: boolean = false;

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxMin']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return _.isFinite(parseFloat(this.slxMin)) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.min(parseFloat(this.slxMin), this.slxMinStrict);
  }
}
