import * as tslib_1 from "tslib";
import { KendoFilterHelper } from './../../../core/utils/kendo-filter-helper';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribeInService } from '../../../core/decorators/index';
import { ControlStateKey } from '../../../core/models/index';
import { StateManagementService } from '../state-management/state-management.service';
import * as kendoUtils from '../../../core/utils/kendo-ui-utils';
var FilterStateManagementService = /** @class */ (function () {
    function FilterStateManagementService(stateManagement) {
        this.stateManagement = stateManagement;
        this.restoreFiltersState$ = new ReplaySubject(1);
        this.allFiltersRestored$ = new ReplaySubject(1);
        this.filterStateSaved$ = new ReplaySubject(1);
    }
    FilterStateManagementService.prototype.setGridId = function (gridId) {
        var _this = this;
        this.gridId = gridId;
        if (this.changeSubscription) {
            this.changeSubscription.unsubscribe();
        }
        if (this.stateManagement) {
            this.changeSubscription = this.stateManagement.loadedData$.subscribe(function (key) {
                _this.switchState(key);
            });
        }
    };
    FilterStateManagementService.prototype.saveFilterState = function (value, operator, field) {
        if (!this.stateManagement || !this.gridId || !this.enabled) {
            return;
        }
        var op = operator;
        var gridId = this.gridId;
        var state = this.stateManagement.getGridState(gridId, this.stateKey);
        var valueState = {};
        this.stateManagement.mapValueToState(value, valueState);
        state.filters[field] = { value: valueState, operator: op };
        this.stateManagement.setGridState(gridId, state, this.stateKey);
        this.filterStateSaved$.next({ gridId: gridId, state: state });
    };
    FilterStateManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    FilterStateManagementService.prototype.switchState = function (context) {
        var _this = this;
        if (!this.enabled) {
            return;
        }
        this.stateKey = new ControlStateKey(context);
        setTimeout(function () {
            _this.loadState();
        }, 500);
    };
    FilterStateManagementService.prototype.loadState = function () {
        var _this = this;
        if (!this.stateManagement || !this.gridId) {
            return;
        }
        var state = this.stateManagement.getGridState(this.gridId, this.stateKey);
        if (!state.filters) {
            return;
        }
        var root = { filters: [], logic: 'and' };
        var lastField;
        _.forIn(state.filters, function (filterState, key) {
            if (filterState && filterState.value) {
                var value = _this.stateManagement.mapValueFromState(filterState.value);
                _this.restoreFiltersState$.next({ value: value, operator: filterState.operator, field: key });
                lastField = key;
                if (value) {
                    var fc = KendoFilterHelper.createValuedFilters(key, filterState.operator, value);
                    if (fc) {
                        root.filters.push(fc.filter);
                    }
                }
                else {
                    kendoUtils.trimFilterByField(root, key);
                    var noValue = KendoFilterHelper.createNonValuedFilters(key, filterState.operator, value);
                    if (noValue && !noValue.isUnknown) {
                        _.forEach(noValue.filters, function (f) { return root = kendoUtils.composeKendoFilter(root, f); });
                    }
                }
            }
        });
        this.allFiltersRestored$.next({ lastField: lastField, filters: root });
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], FilterStateManagementService.prototype, "changeSubscription", void 0);
    return FilterStateManagementService;
}());
export { FilterStateManagementService };
