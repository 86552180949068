import { IPosition, Position } from '../../../../organization/models/index';
import { IEmpPayUnit, EmpPayUnit } from './emp-pay-unit';

export interface IEmpTimecardPayUnit {
  date: string;
  employeeId: number;
  isApproved: boolean;
  isNonActive: boolean;
  isLocked: boolean;
  position: IPosition;
  payUnits: IEmpPayUnit[];
  start: string;
  workHours: number;
}

export class EmpTimecardPayUnit {
  date: Date;
  employeeId: number;
  isApproved: boolean;
  isNonActive: boolean;
  isLocked: boolean;
  position: Position;
  payUnits: EmpPayUnit[];
  start: Date;
  workHours: number;
  public get lockDay(): boolean {
    return this.isApproved || this.isNonActive || this.isLocked;
  }
}
