import { BenefitDetailsLine } from './../../models/benefit-details/benefit-details-line';
import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../../common/services/index';
import { BenefitDetailsRenewDialogComponent } from '../../components/benefit-details/benefit-details-renew-dialog/benefit-details-renew-dialog.component';
import { BenefitDetailsLineStandart } from '../../models/index';

@Directive({
  selector: '[slxBenefitDetailsRenewDialog]',
})
export class BenefitDetailsRenewDialogDirective {
  constructor(private modalService: ModalService) {}

  @Input()
  public slxBenefitDetailsRenewDialog: BenefitDetailsLineStandart;
  @Input()
  public canDoAction: boolean;
  @Output()
  public changedRenewedNewEndDate = new EventEmitter<Date>();
  @Input()
  public nextLineDetails: BenefitDetailsLine;

  @HostListener('click')
  public onClick(): void {
    if (this.canDoAction) {
      const renewDateValue = BenefitDetailsRenewDialogComponent.openDialog(
        this.slxBenefitDetailsRenewDialog,
        this.modalService,
        this.nextLineDetails,
        (result: boolean) => {
          if (result) {
            this.changedRenewedNewEndDate.emit(renewDateValue.benefitDetailsLineNewEndDate);
          }
        }
      );
    }
  }
}
