import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { EmployeeDefinition } from '../../../../../organization/models/index';
@Component({
    moduleId: module.id,
    selector: 'slx-scheduled-shift-replacements-section',
    templateUrl: 'scheduled-shift-replacements-section.component.html',
    styleUrls: [
        'scheduled-shift-replacements-section.component.scss'
    ]
})
export class ScheduledShiftReplacementsSectionComponent implements OnInit {

    @Input()
    public index: number = -1;

    @Input()
    public replaced: EmployeeDefinition;

    @Input()
    public replacing: EmployeeDefinition;

    public appConfig: IApplicationConfig;

    constructor() {
        this.appConfig = appConfig;
    }

    public ngOnInit(): void {
        _.noop();
    }

}
