<div *ngIf="!!totals" class="totals-settings">
  <div class="total-option">
    <slx-check-box  [fieldName]="weeklyTotals" caption="Weekly Totals" [(ngModel)]="totals.weeklyTotals">
    </slx-check-box>
  </div>
  <div class="total-option">
    <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalSubtotals)}}" [fieldName]="positionSubtotals" caption="Position Subtotals" [(ngModel)]="totals.positionSubtotals">
    </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalTotals)}}" [fieldName]="dailyTotals" caption="Daily Totals" [(ngModel)]="totals.dailyTotals">
      </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalPARLevels)}}" [fieldName]="idealSchedule" caption="Ideal Schedule" [(ngModel)]="totals.idealSchedule">
      </slx-check-box>
  </div>
  <div class="total-option">
    <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalBudgetedPars)}}" [fieldName]="budgetedPars" caption="Budgeted PARs" [(ngModel)]="totals.budgetedPars">
    </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalOpenShifts)}}" [fieldName]="openShifts" caption="Open Shifts" [(ngModel)]="totals.openShifts">
      </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalDifference)}}" [fieldName]="difference" caption="Difference" [(ngModel)]="totals.difference">
      </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalCensus)}}" [fieldName]="census" caption="Census" [(ngModel)]="totals.census">
      </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalPPD)}}" [fieldName]="scheduledPpd" caption="Scheduled PPD" [(ngModel)]="totals.scheduledPpd">
      </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalStaffingRatio)}}" [fieldName]="staffingRatio" caption="Staffing Ratio" [(ngModel)]="totals.staffingRatio">
      </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalActualHours)}}" [fieldName]="actualHours" caption="Actual Hours" [(ngModel)]="totals.actualHours">
      </slx-check-box>
  </div>
  <div class="total-option">
      <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.totalActualPPD)}}" [fieldName]="actualPpd" caption="Actual PPD" [(ngModel)]="totals.actualPpd">
      </slx-check-box>
  </div>
  <div class="total-option">
    <slx-check-box tipPosition="bottom" slxTooltip="{{getTooltip(totalsDescription.viewTotalsFTEs)}}" [fieldName]="viewTotalsFTEs" caption="View Totals as FTEs" [(ngModel)]="totals.viewTotalsFTEs">
    </slx-check-box>
  </div>

</div>
