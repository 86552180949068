import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsCustomFields = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsCustomFields, _super);
    function EmployeeSectionsCustomFields() {
        var _this = _super.call(this) || this;
        _this.fields = [];
        return _this;
    }
    return EmployeeSectionsCustomFields;
}(EmployeeSectionsBase));
export { EmployeeSectionsCustomFields };
var EmployeeSectionsCustomField = /** @class */ (function () {
    function EmployeeSectionsCustomField() {
    }
    return EmployeeSectionsCustomField;
}());
export { EmployeeSectionsCustomField };
