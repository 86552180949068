/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./wc-report-attacments.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../common/directives/popover/popover.directive";
import * as i4 from "../../../../../common/components/popover-content/popover-content.component.ngfactory";
import * as i5 from "../../../../../common/components/popover-content/popover-content.component";
import * as i6 from "./wc-report-attacments.component";
import * as i7 from "../../../services/crud/wc-report-management.service";
var styles_WcReportAttachmentsComponent = [i0.styles];
var RenderType_WcReportAttachmentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WcReportAttachmentsComponent, data: {} });
export { RenderType_WcReportAttachmentsComponent as RenderType_WcReportAttachmentsComponent };
function View_WcReportAttachmentsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["You selected more than ", " files"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxFiles; _ck(_v, 1, 0, currVal_0); }); }
function View_WcReportAttachmentsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["Unable to upload file. Max size for files is ", "."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxFileSize; _ck(_v, 1, 0, currVal_0); }); }
function View_WcReportAttachmentsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unable to upload file. Allowed types: Word, Excel, PDF and image files."]))], null, null); }
function View_WcReportAttachmentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "p", [["class", "slx-error-block attachments__error"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errors.maxFiles; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.errors.maxFileSize; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.errors.fileType; _ck(_v, 6, 0, currVal_2); }, null); }
function View_WcReportAttachmentsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "i", [["aria-hidden", "true"], ["class", "fal fa-trash-alt attachments__i-delete"], ["title", "Delete file immediately"]], null, [[null, "click"], [null, "mouseover"], [null, "mouseout"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOrHideOnClick() !== false);
        ad = (pd_0 && ad);
    } if (("mouseover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showOnMouseover() !== false);
        ad = (pd_1 && ad);
    } if (("mouseout" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).hideOnMouseout() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.PopoverDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver], { content: [0, "content"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 8); _ck(_v, 1, 0, currVal_0); }, null); }
function View_WcReportAttachmentsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "p", [["class", "flex align-items__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "span", [["class", "flex flex__grow attachments__file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal attachments__i-file"]], [[2, "fa-file-download", null], [2, "fa-file", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "flex__grow attachments__name"]], [[2, "download-link", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDownloadFile(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_7)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 5, "popover-content", [["placement", "auto bottom"], ["title", "Are you sure?"]], null, [[null, "mouseenter"], [null, "mouseleave"], ["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).showOnMouseEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).hideOnMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 8).onDocumentMouseDown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_PopoverContentComponent_0, i4.RenderType_PopoverContentComponent)), i1.ɵdid(8, 4243456, [["confirmDeleting", 4]], 0, i5.PopoverContentComponent, [i1.ElementRef, i1.ChangeDetectorRef], { placement: [0, "placement"], title: [1, "title"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 1, "button", [["class", "slx-button slx-blue slx-narrow slx-margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete(true, i1.ɵnov(_v, 8), _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "button", [["class", "slx-button slx-narrow"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete(false, i1.ɵnov(_v, 8), _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "flex__shrink added-col added-by"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.editMode; _ck(_v, 6, 0, currVal_4); var currVal_5 = "auto bottom"; var currVal_6 = "Are you sure?"; _ck(_v, 8, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDownloadable(_v.context.$implicit); var currVal_1 = !_co.isDownloadable(_v.context.$implicit); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isDownloadable(_v.context.$implicit); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_3); var currVal_7 = _v.context.$implicit.addedBy; _ck(_v, 14, 0, currVal_7); }); }
function View_WcReportAttachmentsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "attachments__attached"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "p", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "flex__grow attachments__file-name-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["File Name"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "flex__shrink added-col attachments__added-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Added By"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_6)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.files; _ck(_v, 8, 0, currVal_0); }, null); }
function View_WcReportAttachmentsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "attachments__no-files"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No attached files"]))], null, null); }
export function View_WcReportAttachmentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "attachments"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "flex align-items__center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "label", [["class", "attachments__label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload Files"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "span", [["class", "attachments__btn-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "slx-button slx-with-icon"], ["type", "button"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-paperclip slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Browse..."])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "input", [["class", "attachments__input"], ["multiple", ""], ["type", "file"]], [[8, "disabled", 0], [8, "readOnly", 0], [8, "accept", 0]], [[null, "change"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onResetErrors($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "flex__grow attachments__types"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["Allowed file types: ", ". ", " max"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_1)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_5)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcReportAttachmentsComponent_8)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.errors; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.hasFiles; _ck(_v, 15, 0, currVal_7); var currVal_8 = !_co.hasFiles; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editMode; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.editMode; var currVal_2 = !_co.editMode; var currVal_3 = _co.acceptedFileTypesInputFormat; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.acceptedFileTypesReadable; var currVal_5 = _co.maxFileSize; _ck(_v, 11, 0, currVal_4, currVal_5); }); }
export function View_WcReportAttachmentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-wc-report-attachments", [], null, null, null, View_WcReportAttachmentsComponent_0, RenderType_WcReportAttachmentsComponent)), i1.ɵdid(1, 245760, null, 0, i6.WcReportAttachmentsComponent, [i7.WCReportManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WcReportAttachmentsComponentNgFactory = i1.ɵccf("slx-wc-report-attachments", i6.WcReportAttachmentsComponent, View_WcReportAttachmentsComponent_Host_0, { editMode: "editMode" }, {}, []);
export { WcReportAttachmentsComponentNgFactory as WcReportAttachmentsComponentNgFactory };
