import * as moment from 'moment';
import { EmployeeSectionsBase } from '../employee-sections-base';
import { appConfig } from '../../../../app.config';
import { IEmployeeShift, EmployeeShift } from '../../../../organization/models/index';
import * as _ from 'lodash';

export interface IRotationTemplate {
  rotationsStartDate: string;
  rotationsCount: number;
  records: IRotationTemplateRecord[];
  employeeShift: IEmployeeShift;
}

export interface IRotationTemplateRecord {
  daysOn: number;
  daysOff: number;
}

export interface IEmployeeSectionsRotations {
  homePositionId: number;
  homeShiftId: number;
  homeUnitId: number;
  rotations: IEmployeeRotation[];
}

export interface IWeeklyRotation {
  isCurrentWeek: Boolean;
  weekStartDate: Date;
  weekNum: number;
  records: IEmployeeRotationRecord[];
}

export interface IEmployeeRotationRecord {
  dayNo: number;
  weekdayName: string;
  shifts: IEmployeeShift[];
}

export interface IEmployeeRotation {
  isCurrent: boolean;
  archivedDate: string;
  rotationsCount: number;
  avgWeeklyHours: number;
  weeklyRotations: IWeeklyRotation[];
}

export interface IValidatable {
  isValid: boolean;
  validationMessage: string;
  //discardValidState(): void
}

export class RotationTemplate {
  public rotationsStartDate: Date;
  public rotationsCount: number;
  public records: RotationTemplateRecord[];
  public employeeShift: EmployeeShift;
}

export class RotationTemplateRecord {
  public daysOn: number;
  public daysOff: number;
}

export class EmployeeRotationRecord implements IValidatable {
  public dayNumber: number;
  public dayOfWeek: string;
  public weeklyRotation: WeeklyRotation;
  public isValid: boolean;
  public validationMessage: string;
  public showWarning: boolean;
  public employeeShifts: EmployeeShift[];

  public get nextDayShiftEndTime(): Date {
    let endDateTime: Date;
    this.employeeShifts.forEach((s: EmployeeShift) => {
      if (s && s.shift && s.shift.isNextDayShift && (s.shift.endDate > endDateTime || endDateTime === undefined)) {
        endDateTime = s.shift.endDate;
      }
    });
    return endDateTime;
  }

  public get prevDayShiftStartTime(): Date {
    let startDateTime: Date;
    this.employeeShifts.forEach((s: EmployeeShift) => {
      if ((s !== null && s !== undefined)  && s.shift.isNextDayShift && (s.shift !== null && s.shift !== undefined) && (s.shift.startDate < startDateTime || startDateTime === undefined)) {
        startDateTime = s.shift.startDate;
      }
    });
    return startDateTime;
  }

  public get shiftEnd(): Date {
    let endDateTime: Date;
    this.employeeShifts.forEach((s: EmployeeShift) => {
      if (s && s.shift && (s.shift.endDate > endDateTime || endDateTime === undefined)) {
        endDateTime = s.shift.endDate;
      }
    });
    return endDateTime;
  }

  public get shiftStart(): Date {
    let startDateTime: Date;
    this.employeeShifts.forEach((s: EmployeeShift) => {
      if ((s !== null && s !== undefined) && (s.shift !== null && s.shift !== undefined) && (s.shift.startDate < startDateTime || startDateTime === undefined)) {
        startDateTime = s.shift.startDate;
      }
    });
    return startDateTime;
  }

  public get shifts(): EmployeeShift[] {
    return this.employeeShifts;
  }

  public set shifts(shifts: EmployeeShift[]) {
    this.employeeShifts = shifts;
  }

  public setNextShift(shift: EmployeeShift): void {
    if(this.employeeShifts.length < 10) {
      this.employeeShifts.push(shift);
    }
  }

  public removeShift(shift: EmployeeShift): void {
      _.remove(this.employeeShifts, shift);
  }

  public clearShifts(): void {
    if ((this.employeeShifts !== null && this.employeeShifts !== undefined) && this.employeeShifts.length > 0) {
      while (this.employeeShifts.length > 0) {
        this.employeeShifts.pop();
      }
    }
    // this.discardValidState();
  }

  constructor(weeklyRotation: WeeklyRotation) {
    this.isValid = true;
    this.employeeShifts = [];
    this.weeklyRotation = weeklyRotation;
  }

  // public discardValidState(): void {
  //   this.isValid = true;
  // }
}

export class WeeklyRotation {
  public isCurrent: Boolean;
  public weekStartDate: Date;
  public weekNumber: number;
  public dailyRecords: EmployeeRotationRecord[];
  constructor() {
    this.dailyRecords = [];
  }
}

export class EmployeeRotation {
  public id: number;
  public isCurrent: boolean;
  public archivedDate: Date;
  public rotationsCount: number;
  public avgWeeklyHours: number;
  public weeklyRotations: WeeklyRotation[];
  public get firstWeek(): WeeklyRotation {
    if (this.weeklyRotations) {
      return this.weeklyRotations[0];
    }
    return undefined;
  }
  public get description(): string {
    return this.isCurrent ? 'Current week' : moment(this.archivedDate).format(appConfig.dateTimeFormat);
  }
  public get firstDayFirstWeek(): EmployeeRotationRecord {
    let firstWeek: WeeklyRotation = this.firstWeek;
    if (firstWeek !== null && firstWeek !== undefined) {
      let dayNumbers: number[] = [];
      firstWeek.dailyRecords.forEach((d: EmployeeRotationRecord) => dayNumbers.push(d.dayNumber));
      let minDayNumber: number = Math.min(...dayNumbers);
      return firstWeek.dailyRecords.find((d: EmployeeRotationRecord) => d.dayNumber === minDayNumber);
    }
    return undefined;
  }

  constructor() {
    this.weeklyRotations = [];
  }
}

export class EmployeeSectionsRotations extends EmployeeSectionsBase {
  public homePositionId: number;
  public homeShiftId: number;
  public homeUnitId: number;
  public rotations: EmployeeRotation[];
  constructor() {
    super();
    this.rotations = [];
  }
}

export class ConfigureRotationsRequest {
  public employeeId: number;
  public homePositionId: number;
  public homeShiftId: number;
  public homeUnitId: number;
}

