<slx-spinner [show]="isLoading">
    <form #form="ngForm" class="content-form">

        <div class="modal-body">
            <div class="flex content-holder">

                <div *ngIf="showEnrollments" class="flex enrollment-grid-holder">

                    <kendo-grid #kendoGrid class="kendo-grid slx-grid-slim-rows"
                        [height]="200"
                        scrollable="'scrollable'"
                        [data]="gridState?.view"
                        [sortable]="false" [pageable]="false"
                        [filterable]="false" [groupable]="false"
                        (dataStateChange)="gridState.dataStateChange($event)"
                        (selectionChange)="onSelectionChange($event)"
                        (stateRestored)="gridState.dataStateChange($event)"
                        (edit)="gridState.editHandler($event)"
                        (save)="gridState.saveHandler($event)"
                        (cancel)="gridState.cancelHandler($event)"
                        (remove)="gridState.removeHandler($event)"
                        kendoGridSelectBy="empToBenefitsId"
                        [selectedKeys]="selectedEnrollmentIds"
                        [selectable]="{ checkboxOnly: true, mode: 'multiple' }"
                        >

                        <kendo-grid-checkbox-column width="45">
                            <ng-template kendoGridHeaderTemplate>
                                <input class="k-checkbox select-header-checkbox" id="selectAllCheckboxId" kendoGridSelectAllCheckbox>
                                <label class="k-checkbox-label" for="selectAllCheckboxId"></label>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-idx="rowIndex">
                                <input [kendoGridSelectionCheckbox]="idx" class="select-checkbox" />
                            </ng-template>
                        </kendo-grid-checkbox-column>

                        <kendo-grid-column field="plan" title="Plan">
                            <ng-template kendoGridHeaderTemplate>
                                Plan
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span class="edit-link">{{ dataItem.plan }}</span>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="startDate" title="Start Date">
                            <ng-template kendoGridHeaderTemplate>
                                Start Date
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.startDate | amDateFormat: appConfig.dateFormat }}
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="endDate" title="End Date">
                            <ng-template kendoGridHeaderTemplate>
                                End Date
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.endDate | amDateFormat: appConfig.dateFormat }}
                            </ng-template>
                        </kendo-grid-column>

                    </kendo-grid>

                </div>

                <div class="enrollment-controls-holder">

                    <p class="flex form__ctrl">
                        <label class="form__label">Reason:</label>
                        <span class="flex form-input align-items__center flex__grow form-dropdown-control">
                            <slx-dropdown-list 
                            class="slx-wide" 
                            [options]="terminationReasons"
                            [ngModel]="terminationReason" 
                            (ngModelChange)="onChangeTerminationReason($event)"
                            name="terminationReasonField" 
                            #terminationReasonField="ngModel" 
                            required
                            >
                            </slx-dropdown-list>

                            <div *ngIf="terminationReasonField.errors" class="slx-error-block">
                                <span *ngIf="terminationReasonField.errors.required" errorMessage for="required"></span>
                            </div>

                        </span>
                    </p>
                </div>

                <div class="enrollment-controls-holder">

                    <p class="flex form__ctrl">

                        <label class="form__label">Coverage End Date:</label>
                        <span class="flex form-input align-items__center form-dropdown-control">
                            <slx-datepicker class="date-controls" 
                            #coverageEndDatePicker="ngModel"
                            [ngModel]="coverageEndDate" 
                            (ngModelChange)="onChangeCoverageEndDate($event)"
                            (valueChanged)="onChangeCoverageEndDate($event)" 
                            name="coverageEndDatePicker"
                            [slxMinDate]="minCoverageEndDate" 
                            [minDate]="minCoverageEndDate"
                            [maxDate]="maxCoverageEndDate" 
                            [slxMaxDate]="maxCoverageEndDate"
                            [required]="true"
                            [acceptNullDate]="false" 
                            [constantValidation]="true">
                            </slx-datepicker>
                            <span *ngIf="coverageEndDatePicker.errors" class="slx-error-block error-box">
                                <span *ngIf="coverageEndDatePicker.errors?.required" errorMessage for="required"></span>
                                <span *ngIf="coverageEndDatePicker.errors?.date" errorMessage for="date"></span>
                                <span *ngIf="coverageEndDatePicker.errors?.maxDate" errorMessage for="maxDate">The value
                                    should be lower than {{ maxCoverageEndDate | amDateFormat: appConfig.dateFormat
                                    }}</span>
                                <span *ngIf="coverageEndDatePicker.errors?.minDate" errorMessage for="minDate">The value
                                    should be greater than {{ minCoverageEndDate | amDateFormat: appConfig.dateFormat
                                    }}</span>
                            </span>
                        </span>

                        <label class="form__label padd__left">Event Date:</label>

                        <span class="flex form-input align-items__center form-dropdown-control">
                            <slx-datepicker class="date-controls"
                            #eventDatePicker="ngModel"
                            name="eventDatePicker"
                            [ngModel]="eventDate" 
                            (ngModelChange)="onChangeEventDate($event)"
                            (valueChanged)="onChangeEventDate($event)"
                            [slxMinDate]="minEventDate"
                            [minDate]="minEventDate" 
                            [slxMaxDate]="maxEventDate"
                            [maxDate]="maxEventDate"
                            [required]="true"
                            [acceptNullDate]="false"
                            [constantValidation]="true"
                            >
                            </slx-datepicker>
                            <span *ngIf="eventDatePicker.errors" class="slx-error-block error-box">
                                <span *ngIf="eventDatePicker.errors?.required" errorMessage for="required"></span>
                                <span *ngIf="eventDatePicker.errors?.date" errorMessage for="date"></span>
                                <span *ngIf="eventDatePicker.errors?.maxDate" errorMessage for="maxDate">The value
                                    should be lower than {{ maxEventDate | amDateFormat: appConfig.dateFormat }}</span>
                                <span *ngIf="eventDatePicker.errors?.minDate" errorMessage for="minDate">The value
                                    should be greater than {{ minEventDate | amDateFormat: appConfig.dateFormat
                                    }}</span>
                            </span>
                        </span>
                    </p>

                </div>

                <div class="enrollment-controls-holder" *ngIf="canMapPayroll">
                    <p class="flex form__ctrl">
                        <label class="form__label__ded">Deduction End Date:</label>
                        <span class="flex form-input align-items__center flex__grow form-dropdown-control">
                            <slx-datepicker class="slx-wide control__width"
                            #payrollDedEndDatePicker="ngModel"
                            [(ngModel)]="payrollDeductionEndDate"
                            (ngModelChange)="onChangePayrollDedEndDate($event)"
                            name="payrollDedEndDatePicker"
                            [minDate]="minDedDate" 
                            [maxDate]="maxDedDate" 
                            [required]="true"
                            [acceptNullDate]="false"
                            [constantValidation]="true"
                            >
                            </slx-datepicker>
                            <span class="width__space"></span>
                            <span *ngIf="payrollDedEndDatePicker?.errors" class="slx-error-block error-box">
                                <span *ngIf="payrollDedEndDatePicker?.errors?.required" errorMessage
                                    for="required"></span>
                                <span *ngIf="payrollDedEndDatePicker?.errors?.date" errorMessage for="date"></span>
                                <span *ngIf="payrollDedEndDatePicker?.errors?.maxDate" errorMessage for="maxDate">Deduction End Date cannot be after
                                    the Deduction End Date on the Deduction Master List in Payroll {{ maxDedDate | amDateFormat:
                                    appConfig.dateFormat }}</span>
                                <span *ngIf="payrollDedEndDatePicker?.errors?.minDate" errorMessage for="minDate">The
                                    value should be greater than {{ minDedDate | amDateFormat: appConfig.dateFormat
                                    }}</span>
                            </span>
                            <span *ngIf="form.valid && isDedAfterBeneEndDt && canMapPayroll" class="warning-message">
                                Payroll Deduction End Date is set after the Benefit Coverage End Date.
                            </span>
                        </span>
                        
                    </p>
                </div>

                <div *ngIf="showEnrollments && !coverageEndDateValid && hasSelected">
                    <span class="slx-error-block">The new coverage end date cannot be before the coverage start date or
                        after the coverage end date for each selected enrollment</span>
                </div>


            </div>
        </div>
        <div class="modal-footer">
            <button
                [disabled]="form.invalid || !hasSelected || !eventDate || !coverageEndDate || !coverageEndDateValid"
                type="button" (click)="dropBenefits()" class="btn green">
                <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>
                OK</button>
            <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
        </div>
    </form>
</slx-spinner>