import { AutoSchedulingConfig, IAutoSchedulingConfig } from './auto-scheduling-config';

export interface IAutoSchedulingState {
  lastScheduleState: string;
  lastScheduleTime: string;
  nextSchedule: string;
  config: IAutoSchedulingConfig;
  userHasAccess: boolean;
}


export class AutoSchedulingState {
  public lastScheduleState: string;
  public lastScheduleTime: Date;
  public nextSchedule: Date;
  public config: AutoSchedulingConfig;
  public userHasAccess: boolean;
}
