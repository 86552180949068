import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { map, trim, isNil } from 'lodash';

export const uniqueValidationKey = 'unique';

export function uniqueValidator(values: string[], isIgnoreCase: boolean = true): ValidatorFn {
  const existingValues = isIgnoreCase ? map(values, (value) => !isNil(value) ? trim(value.toLowerCase()) : value) : values;

  const validator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = trim(control.value);
    const currentValue = isIgnoreCase && value ? value.toLowerCase() : value;

    const isValid = existingValues.indexOf(currentValue) === -1;
    return !isValid ? { [uniqueValidationKey]: true } : null;
  };

  return validator;
}
