import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { ManagementBaseService } from '../../../../core/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { DateRange } from '../../../../core/models/index';
import { StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';
import { WcViewList, WcViewListDisplay, WcViewItem, WcIncidentAmountItem } from '../../models/index';
import { WCIncidentReportDialogComponent } from '../../components/crud/wc-incident-report-dialog/wc-incident-report-dialog.component';
import { ModalService } from '../../../../common/services';
var WcRosterToolbarService = /** @class */ (function (_super) {
    tslib_1.__extends(WcRosterToolbarService, _super);
    function WcRosterToolbarService(modalService, stateManagement, compStorageService) {
        var _this = _super.call(this) || this;
        _this.modalService = modalService;
        _this.stateManagement = stateManagement;
        _this.compStorageService = compStorageService;
        _this.buttonIsActive = true;
        _this.currentView = null;
        _this.viewsList = [];
        _this.incidentsList = [];
        _this.newIncident$ = new Subject();
        _this.permissionsChanged$ = new Subject();
        _this.dateRange$ = new Subject();
        _this.initDateRange$ = new ReplaySubject();
        _this.view$ = new Subject();
        _this.definedState$ = new Subject();
        _this.saveState$ = new Subject();
        _this.exportTo$ = new Subject();
        _this.incidentsAmount$ = new Subject();
        _this.toolbarActions$ = new Subject();
        _this.orgLevelChanged$ = new ReplaySubject(1);
        _this.yearRange = new ReplaySubject(1);
        _this.dateRangeKey = 'dateRangeKey';
        _this.resetBy = StateResetTypes.SessionEnd;
        _this.availableIncidents = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        _this.generateOSHAForm$ = new Subject();
        _this.init();
        return _this;
    }
    Object.defineProperty(WcRosterToolbarService.prototype, "newIncidentIsActive", {
        get: function () {
            return this.buttonIsActive;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcRosterToolbarService.prototype, "currentDateRange", {
        get: function () {
            return this.dateRange;
        },
        enumerable: true,
        configurable: true
    });
    WcRosterToolbarService.prototype.init = function () {
        this.viewsList = this.getViewList();
        this.incidentsList = this.getIncidentList();
        this.initStateManagement();
        this.subscribeToOrgLevelChanges();
    };
    WcRosterToolbarService.prototype.destroy = function () {
        this.orgLevel = null;
        this.buttonIsActive = true;
        this.currentView = null;
        this.viewsList = [];
        this.newIncident$.complete();
        this.permissionsChanged$.complete();
        this.dateRange$.complete();
        this.initDateRange$.complete();
        this.view$.complete();
        this.definedState$.complete();
        this.saveState$.complete();
        this.exportTo$.complete();
        this.incidentsAmount$.complete();
        this.toolbarActions$.complete();
        this.orgLevelChanged$.complete();
        this.generateOSHAForm$.complete();
        this.yearRange.complete();
        _super.prototype.destroy.call(this);
    };
    WcRosterToolbarService.prototype.permissionsChanged = function (canCreate) {
        this.permissionsChanged$.next(canCreate);
    };
    WcRosterToolbarService.prototype.clickNewIncident = function (isActive) {
        this.newIncident$.next(isActive);
    };
    WcRosterToolbarService.prototype.toogleToolbarActions = function (actionsAllowed) {
        this.toolbarActions$.next(actionsAllowed);
    };
    WcRosterToolbarService.prototype.subscribeToToolbarActions = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.toolbarActions$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.subscribeToPermissionsChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.permissionsChanged$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.subscribeToNewIncident = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.newIncident$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.changeDateRange = function (r) {
        this.dateRange = r;
        this.dateRange$.next(r);
        this.saveDateRange(r);
    };
    WcRosterToolbarService.prototype.selectedYearRange = function (selectedYearsList) {
        this.yearRange.next(selectedYearsList);
    };
    WcRosterToolbarService.prototype.subscritpionToSenseYearChange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.yearRange.subscribe(callback);
    };
    WcRosterToolbarService.prototype.subscribeToDateRange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dateRange$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.subscribeToInitDateRange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.initDateRange$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.saveState = function (state) {
        this.saveState$.next(_.cloneDeep(state));
    };
    WcRosterToolbarService.prototype.subscribeToSaveState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.saveState$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.defineState = function (state) {
        this.definedState$.next(_.cloneDeep(state));
    };
    WcRosterToolbarService.prototype.subscribeToDefineState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.definedState$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    WcRosterToolbarService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.changeIncidentsAmount = function (amount) {
        this.incidentsAmount$.next(amount);
    };
    WcRosterToolbarService.prototype.subscribeToIncidentsAmount = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.incidentsAmount$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    WcRosterToolbarService.prototype.defineView = function (url) {
        if (_.isArray(url) && _.size(url) > 0) {
            var headUrl_1 = _.head(url);
            var view = _.find(this.viewsList, function (view) { return view.path === headUrl_1.path; });
            if (_.isObjectLike(view) && _.get(this.currentView, 'path') !== view.path) {
                this.currentView = view;
                this.view$.next(this.currentView);
            }
            return _.isObjectLike(view) && view || null;
        }
        return null;
    };
    WcRosterToolbarService.prototype.ytdListRender = function () {
        var amountOfYearsLessCurrent = 3;
        var currentYear = moment().year();
        var ytdList = _.range(currentYear, currentYear - amountOfYearsLessCurrent, -1);
        return ytdList;
    };
    WcRosterToolbarService.prototype.getDefaultRange = function () {
        return new DateRange(new Date(this.dateRange.startDate.getTime()), new Date(this.dateRange.endDate.getTime()));
    };
    WcRosterToolbarService.prototype.getViewsList = function () {
        return this.viewsList.concat();
    };
    WcRosterToolbarService.prototype.getIncidentsList = function () {
        return this.incidentsList.concat();
    };
    WcRosterToolbarService.prototype.getCurrentIncident = function () {
        return this.incidentsList[2];
    };
    WcRosterToolbarService.prototype.openNewReportDialog = function () {
        var _this = this;
        WCIncidentReportDialogComponent.openDialog(null, this.modalService, function (result) {
            _this.clickNewIncident(true);
            if (result) {
                _this.changeDateRange(_this.dateRange);
            }
        });
    };
    WcRosterToolbarService.prototype.openEditReportDialog = function (reportId) {
        var _this = this;
        this.clickNewIncident(false);
        WCIncidentReportDialogComponent.openDialog({ reportId: reportId, employeeId: null }, this.modalService, function (result) {
            _this.clickNewIncident(true);
            if (result) {
                _this.changeDateRange(_this.dateRange);
            }
        });
    };
    WcRosterToolbarService.prototype.initStateManagement = function () {
        var _this = this;
        this.stateManagement.init('IncidentRosterToolbar', true);
        var stateSubscription = this.stateManagement.onInit$
            .combineLatest(this.view$)
            .subscribe(function () {
            var range = null;
            if (_this.currentView.isIncidentRoster) {
                range = _this.getDefaultDateRange();
                _this.initDateRange$.next(range);
                _this.changeDateRange(range);
            }
            else {
                range = _this.restoreDateRange();
                if (!_.isObjectLike(range)) {
                    range = _this.getDefaultDateRange();
                }
                _this.initDateRange$.next(range);
                _this.changeDateRange(range);
            }
            stateSubscription.unsubscribe();
        });
    };
    WcRosterToolbarService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
        });
    };
    WcRosterToolbarService.prototype.getViewList = function () {
        return _.map(WcViewList, function (value) {
            var viewPath = value.split(' ').join('_').toLowerCase();
            var item = new WcViewItem(value, WcViewListDisplay[value], viewPath);
            return item;
        });
    };
    WcRosterToolbarService.prototype.getIncidentList = function () {
        return _.map(this.availableIncidents, function (v) {
            var id = v;
            var value = v === 10 ? v + "+" : "" + v;
            return new WcIncidentAmountItem(id, value);
        });
    };
    WcRosterToolbarService.prototype.saveDateRange = function (range) {
        var startDate = range.startDate.toISOString();
        var endDate = range.endDate.toISOString();
        this.compStorageService.setControlState(this.stateManagement.componentKey, this.dateRangeKey, { value: { startDate: startDate, endDate: endDate } }, this.resetBy);
    };
    WcRosterToolbarService.prototype.restoreDateRange = function () {
        var state = this.compStorageService.getControlState(this.stateManagement.componentKey, this.dateRangeKey);
        var value = _.get(state, 'value');
        if (_.isObject(value) && _.isString(value.startDate) && _.isString(value.endDate)) {
            return new DateRange(new Date(value.startDate), new Date(value.endDate));
        }
        return null;
    };
    WcRosterToolbarService.prototype.getDefaultDateRange = function () {
        var d = new Date();
        return new DateRange(new Date(d.getFullYear(), 0, 1), d);
    };
    WcRosterToolbarService.prototype.generateOSHAForm300A = function (action) {
        this.generateOSHAForm$.next(action);
    };
    WcRosterToolbarService.prototype.subscribeToGenerateOSHAForm300A = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.generateOSHAForm$.subscribe(callback);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], WcRosterToolbarService.prototype, "orgLevel$", void 0);
    return WcRosterToolbarService;
}(ManagementBaseService));
export { WcRosterToolbarService };
