<slx-spinner [show]="stateService.isLoading">
  <kendo-grid #kendoGrid class="slx-full-height"
    [data]="gridState.view"
    [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort"
    [pageable]="true"
    [pageSize]="gridState.state.take"
    [skip]="gridState.state.skip"
    (pageChange)="gridState.pageChange($event)"
    (sortChange)="gridState.sortChange($event)"
  >
    <kendo-grid-column [title]="getOrgLevelTitle()" field="orgLevel.name" width="200" [locked]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onOrgLevelClick(dataItem)"
          [ngClass]="{ 'active': canChangeOrgLevel() }"
        >{{dataItem.orgLevel.name}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="scheduledEmployees.value" width="70" >
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.scheduledEmployees)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.scheduledEmployees) }"
        >{{dataItem.scheduledEmployees.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="scheduledHours.value" width="70">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.scheduledHours)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.scheduledHours) }"
        >{{dataItem.scheduledHours.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="par.value" width="70">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.par)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.par) }"
        >{{dataItem.par.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="diff.value" width="70">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.diff)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.diff) }"
        >{{dataItem.diff.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="absentEmployees.value" width="120">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.absentEmployees)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.absentEmployees) }"
        >{{dataItem.absentEmployees.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="absent.value" width="80">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.absent)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.absent) }"
        >{{dataItem.absent.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="replaced.value" width="150">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.replaced)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.replaced) }"
        >{{dataItem.replaced.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="diffR.value" width="150">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.diffR)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.diffR) }"
        >{{dataItem.diffR.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="punchedEmployees.value" width="120">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.punchedEmployees)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.punchedEmployees) }"
        >{{dataItem.punchedEmployees.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="lateEmployees.value" width="70">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.lateEmployees)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.lateEmployees) }"
        >{{dataItem.lateEmployees.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="notPunchedEmployees.value" width="120">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.notPunchedEmployees)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.notPunchedEmployees) }"
        >{{dataItem.notPunchedEmployees.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="overtime.value" width="150">
      <ng-template kendoGridHeaderTemplate let-column>
        {{getTitle(column.field)}}
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="slx-operational-console__link"
          (click)="onEntryClick(dataItem, dataItem.overtime)"
          [ngClass]="{ 'active': isActiveEntry(dataItem.overtime) }"
        >{{dataItem.overtime.value}}</span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-excel fileName="OperationalDashboard.xlsx"></kendo-grid-excel>
    <kendo-grid-pdf fileName="OperationalDashboard.pdf" [allPages]="true">
      <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
    </kendo-grid-pdf>
  </kendo-grid>
</slx-spinner>