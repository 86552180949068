import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { ManagementBaseService } from '../../../core/services/index';
import { PunchProfileApiService } from './punch-profile-api.service';
var PunchProfileManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PunchProfileManagementService, _super);
    function PunchProfileManagementService(punchProfileApiService) {
        var _this = _super.call(this) || this;
        _this.punchProfileApiService = punchProfileApiService;
        _this.punchProfileGroup = [];
        _this.groups$ = new BehaviorSubject([]);
        return _this;
    }
    PunchProfileManagementService.prototype.getGroupsAndProfiles = function (orglevelid) {
        var _this = this;
        this.onLoadStatusChanged(true);
        Promise.all([this.punchProfileApiService.getAllGroups(), this.punchProfileApiService.getAllPunchProfiles()]).then(function (data) {
            _this.groups$.next(data[0]);
            _this.onLoaded(data[1]);
            _this.punchProfileGroup = data[1];
            _this.onLoadStatusChanged(false);
        }).catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PunchProfileManagementService.prototype.addOrUpdatePunchProfile = function (orglevelid, punchProfile) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.punchProfileApiService.addOrUpdatePunchProfile(orglevelid, punchProfile)
            .then(function (punchProfileGroup) {
            _this.punchProfileGroup = punchProfileGroup;
            _this.onLoaded(_this.punchProfileGroup);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    return PunchProfileManagementService;
}(ManagementBaseService));
export { PunchProfileManagementService };
