import { EmployeeFieldDefinition } from './employeeFieldDefinition';

import { employeeListConfig } from '../employee-list.config';

export class EmployeeColumnsDefinition {
  public fields: EmployeeFieldDefinition[];

  public toColumns(): kendo.ui.GridColumn[] {
    let columns: kendo.ui.GridColumn[] = [];
    this.fields.forEach((field: EmployeeFieldDefinition) => {
      if (field.fieldType === 'boolean') {
        columns.push({
          field: field.fieldName,
          sortable: false,
          headerTemplate: this.getHeaderTemplate(field),
          filterable: false,
          width: '20px',
          template: this.getTemplate(field)
        });
      } else {
        let column: kendo.ui.GridColumn = {
          field: field.fieldName,
          title: field.displayName,
          hidden: field.hidden,
          width: '200px',
          template: this.getTemplate(field)
        };
        columns.push(column);
      }
    });
    return columns;
  }

  public toModel(): kendo.data.Model {
    let fields: kendo.data.DataSourceSchemaModelFields = {};
    this.fields.forEach((field: EmployeeFieldDefinition) => {
      if (field.fieldName !== employeeListConfig.employeeIdentifierName) {
        fields[field.fieldName] = {
          type: field.fieldType
        };
      }
    });
    let model: kendo.data.Model = <kendo.data.Model>{
      id: employeeListConfig.employeeIdentifierName,
      fields
    };
    return model;
  }

  private getTemplate(field: EmployeeFieldDefinition): string {
    switch (field.fieldType) {
      case 'date':
        return `#= kendo.toString(kendo.parseDate(${field.fieldName}), 'MM/dd/yyyy') # `;
      case 'boolean':
        return `<input name="isSelected" type="checkbox" # if(!${field.fieldName}){# checked #} else {# disabled #} # />`;
      case 'hyperlink':
        return `<a href="javascript:void(0);" class="hyperlink">#: ${field.fieldName} #</a>`;
      default:
        return undefined;
    }
  }

  private getHeaderTemplate(field: EmployeeFieldDefinition): string {
    switch (field.fieldType) {
      case 'boolean':
        return `<input id="selectAll" type="checkbox" />`;
      default:
        return undefined;
    }
  }
}
