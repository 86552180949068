export interface IPayType {
  name: string;
}

export class PayType {
  public name: string;
  public description: string;
  public get isSalaryPayType(): boolean {
      return this.name === 'Salaried';
  }
}
