import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { ScheduleCycleMessages, ScheduleCycleSummaryMessage, ScheduleCycleSummaryViewAction } from '../../../models/index';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';


@Component({
  moduleId: module.id,
  selector: 'slx-schedule-cycle-summary-grid',
  templateUrl: 'schedule-cycle-summary-grid.component.html',
  styleUrls: ['schedule-cycle-summary-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleCycleSummaryGridComponent implements OnInit, OnDestroy {
  @Input()
  public set summary(value: ScheduleCycleMessages) {
    this.records = value.messages;
    this.refreshGrid();
  }
  @Output()
  public actionEvent: EventEmitter<ScheduleCycleSummaryViewAction>;

  public records: ScheduleCycleSummaryMessage[];
  public gridState: KendoGridStateHelper<ScheduleCycleSummaryMessage>;
  public appConfig: IApplicationConfig;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;


  constructor(private changeDetector: ChangeDetectorRef) {
    this.gridState = new KendoGridStateHelper<ScheduleCycleSummaryMessage>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];
    this.actionEvent = new EventEmitter();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public navigateToEmployee(item: ScheduleCycleSummaryMessage): void {
    const action = new ScheduleCycleSummaryViewAction();
    action.employeeId = item.employeeId;
    action.action = 'NavigateToEmployee';
    this.actionEvent.emit(action);
  }

  public navigateToScheduleEntry(item: ScheduleCycleSummaryMessage): void {
    const action = new ScheduleCycleSummaryViewAction();
    action.employeeId = item.employeeId;
    action.date = item.workDate;
    action.action = 'NavigateToScheduleEntry';
    this.actionEvent.emit(action);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}
