import { IBenefitClassDefinition, BenefitClassDefinition } from '../../../../organization/models/index';


export interface IBenefitClass {
  benefitClassDefinition: IBenefitClassDefinition;
  orgLevelDirectionForEdit: number;
  modifiedOn: string;
  modifiedBy: string;
  orgLevelName: string;
}

export class BenefitClass {
  public benefitClassDefinition: BenefitClassDefinition;
  public orgLevelDirectionForEdit: number;
  public modifiedOn: Date;
  public modifiedBy: string;
  public orgLevelName: string;
  public get canEdit(): boolean {
    return this.orgLevelDirectionForEdit === 0;
  }
}
