import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../../core/decorators/index';

import { pmConfig } from '../../../performance-management.config';

import { ReadFile, PmAttachment } from '../../../../../organization/models/index';
import { PmFile } from '../../../models/index';

import { PmAttachmentManagementService, PmCreationManagementService } from '../../../services/index';
import { PopoverContentComponent } from '../../../../../common';

@Component({
  moduleId: module.id,
  selector: 'slx-pm-review-attachment',
  templateUrl: 'pm-review-attachment.component.html',
  styleUrls: ['pm-review-attachment.component.scss'],
  providers: [PmAttachmentManagementService]
})
export class PmReviewAttachmentComponent implements OnInit, OnDestroy {

  @Input()
  public allowUpload: boolean = true;

  @Input()
  public allowDownload: boolean = true;

  @Input()
  public disabled: boolean = false;

  @Input()
  public canDelete: boolean = true;

  @Input()
  public set attachments(v: PmAttachment[]) {
    if (_.isArray(v)) {
      this.processAttachments(v);
    }
  }

  @Output()
  public addedFiles = new EventEmitter<ReadFile[]>();

  @Output()
  public deletedFile = new EventEmitter<ReadFile>();

  @Output()
  public deletedAttachment = new EventEmitter<PmAttachment>();

  @Output()
  public downloadAttachment = new EventEmitter<PmAttachment>();

  public get hasFiles(): boolean {
    return _.size(this.files) > 0;
  }
  public get maxFiles(): number {
    return this.attachmentService.maxFiles;
  }
  public get maxFileSizeBytes(): number {
    return this.attachmentService.maxFileSizeBytes;
  }
  public get acceptedFileTypes(): string[] {
    return this.attachmentService.acceptedFileTypes;
  }
  public get acceptedFileTypesInputFormat(): string {
    return this.attachmentService.acceptedFileTypesInputFormat;
  }
  public get acceptedFileTypesReadable(): string {
    return this.attachmentService.acceptedFileTypesReadable;
  }
  public get maxFileSize(): string {
    return this.attachmentService.maxFileSize;
  }

  public files: PmFile[] = [];
  public errors: { maxFiles?: boolean, maxFileSize?: boolean, fileType?: boolean } = {};

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor (private creationManagement: PmCreationManagementService, private attachmentService: PmAttachmentManagementService) { }

  public ngOnInit(): void { }

  public ngOnDestroy(): void { }

  public async onFileChange(event: MouseEvent): Promise<void> {
    if (!this.disabled) {
      const fileList: FileList = _.get(event, 'target.files');
      const result = this.attachmentService.validateFiles(fileList, event);

      if (result.files.length > 0) {
        const readFiles = await this.attachmentService.readAddedFiles(result.files);
        const files = this.attachmentService.mapToFiles(readFiles);
        this.files = this.files.concat(files);
        this.addedFiles.emit(readFiles);
      }

      this.errors = result.errors;
    }
  }

  public onResetErrors(event: any): void {
    this.attachmentService.resetInput(event.target);
    this.errors = {};
  }

  public async onDownloadFile(file: PmFile): Promise<void> {
      if (file.isAttachment) {
        this.downloadAttachmentFile(file);
      }
  }

  public async onClickDelete(isDelete: boolean, acceptPopover: PopoverContentComponent, file: PmFile): Promise<void> {
    acceptPopover.hide();
    if (isDelete) {
      this.onDeleteAttachment(file)
    }
  }

  public async onDeleteAttachment(file: PmFile): Promise<void> {
    if (!this.disabled) {
      if (file.isAttachment) {
        this.deleteAttachment(file);
      } else {
        this.deleteFile(file);
      }
    }
  }

  private async downloadAttachmentFile(file: PmFile): Promise<void> {
    this.downloadAttachment.emit(file.sourceItem as PmAttachment);
  }

  private async deleteAttachment(file: PmFile): Promise<void> {
    this.files = _.filter(this.files, (f: PmFile) => f.id !== file.id);
    this.deletedAttachment.emit(file.sourceItem as PmAttachment);
  }

  private deleteFile(file: PmFile): void {
    this.files = _.filter(this.files, (f: PmFile) => f.name !== file.name);
    this.deletedFile.emit(file.sourceItem as ReadFile);
  }

  private processAttachments(attachments: PmAttachment[]): void {
    this.files = this.attachmentService.mapToFiles(attachments);
    if (this.allowUpload) {
      const addedFilesCache = this.creationManagement.getAddedFiles();
      if (addedFilesCache) {
        const addedFiles = this.attachmentService.mapToFiles(addedFilesCache)
        this.files = this.files.concat(addedFiles);
      }
    }
  }
}
