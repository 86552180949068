<div class="modal-content">
    <div class="modal-body">
        <slx-meal-deduction-configuration #configEditorComponent [hideDescription]="hideDescription"
            (onSaved)="closeAfterSave($event)"></slx-meal-deduction-configuration>
    </div>
    <div class="modal-footer center-align">
        <button type="button" (click)="onClose()"
            class="btn btn-default slx-button slx-with-icon slx-white-blue-text slx-no-border bolder-btn-text">
            <i aria-hidden="true" class="fa fa-times slx-button__icon"></i>
            <span class="slx-button__text">Close</span>
        </button>
        <button type="button" (click)="onSave()" [disabled]="this.configEditorComponent.form.invalid"
            class="btn btn-default slx-button slx-with-icon slx-white-blue-text slx-no-border bolder-btn-text">
            <i aria-hidden="true" class="fa fa-save slx-button__icon"></i>
            <span class="slx-button__text">Save</span>
        </button>
    </div>
</div>
