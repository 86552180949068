import { WfmTransactionReport, statusCheck } from './../../../models/wfm-sync';
import { Component, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { process } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { NotificationsService } from '../../../../../app/core/components/index';
import * as moment from 'moment';
import { KendoGridStateHelper } from '../../../../common/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
@Component({
  selector: 'slx-wfm-dailytransaction-report',
  templateUrl: './wfm-dailytransaction-report.component.html',
  styleUrls: ['./wfm-dailytransaction-report.component.scss']
})
export class WfmDailytransactionReportComponent implements OnInit {

  public gridState: KendoGridStateHelper<WfmTransactionReport>;
  public dataView: WfmTransactionReport[] = [];
  public isLoading: boolean=false;
  public syncId: string;
  public endDate: string = '';
  public checkStartDate: Date;
  public startDate: string = '';
  public dateError: boolean = false;
  public endDateValue: Date = new Date();
  public today: Date = new Date();
  public month: number = (this.endDateValue.getMonth());
  public day: number = (this.endDateValue.getDay());
  public year: number = (this.endDateValue.getFullYear());
  public previousMonth: number = (this.month === 0) ? 11 : this.month - 1;
  public previousYear: number = (this.month === 0) ? this.year - 1 : this.year;
  // public startDateValue: Date = new Date(this.previousYear, this.previousMonth, this.endDateValue.getDate()+1, 0, 0, 0, 0);
  public startDateValue:Date= moment(new Date()).subtract(31, 'days').toDate();
  public isActiveOnly: boolean = false;
  public actualData: WfmTransactionReport[] = [];
  public filterData: WfmTransactionReport[] = [];
  public appConfig: IApplicationConfig;
  public disable:boolean=false;
  public pageSize: number = 50;
  public startDateMessage: string;
  public endDateMessage: string;
  @unsubscribe()
  private refreshSubscription: Subscription;

  constructor(
    private wfmsyncService: WfmSyncService,
    public notificationService: NotificationsService
  ) { 
    this.allData = this.allData.bind(this); 
    this.gridState = new KendoGridStateHelper<WfmTransactionReport>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
  }
  ngOnInit() {
    this.appConfig = appConfig;
    this.isLoading= false;
    this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
    this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';
    this.res();
    this.refreshSubscription = this.gridState.onRefreshGrid.subscribe((v): void => {
      this.refreshGrid();
    });
  }
  public res() {
    this.isLoading = true;
    this.wfmsyncService.dailyTransactionReport(this.startDate, this.endDate).then((value2: any) => {
      this.dataView = value2.data;
      this.actualData = statusCheck(value2.data);
      this.setDefaultData(this.isActiveOnly);
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  
  public onChangeStartDate(dateSent: Date): void {
    this.dateError = false;
    this.startDateMessage = '';
    let value = Math.floor((Date.UTC(this.endDateValue.getFullYear(), this.endDateValue.getMonth(), this.endDateValue.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    if (value >= 31 || value < 0) {
      this.dateError = true;
      this.disable=true;
      this.startDateMessage = 'Difference between Start date and End date should not be greater than one month';
    }else{
      this.disable=false;
    }
    this.startDateValue=dateSent;
    this.startDate = this.startDateValue ? dateTimeUtils.convertToDtoString(this.startDateValue) : '';
    this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';

  }
  
  onEndDateChange(dateSent: Date) {
    this.dateError = false;
    this.endDateMessage = '';
    let value = Math.floor((Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) - Date.UTC(this.startDateValue.getFullYear(), this.startDateValue.getMonth(), this.startDateValue.getDate())) / (1000 * 60 * 60 * 24));
    if (value >= 31 || value < 0) {
      this.dateError = true;
      this.disable=true;
      this.endDateMessage = 'Difference between Start date and End date should not be greater than one month';
    }else{
      this.disable=false;
    }
    this.endDateValue=dateSent;
    this.startDate = this.startDate;
    this.endDate = this.endDateValue ? dateTimeUtils.convertToDtoString(this.endDateValue) : '';

  }
  public apply(){
    this.res();
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataView, {
        sort: [{ field: 'transactionId', dir: 'asc' }],
      }).data
    };

    return result;
  }
  handleButtonClick(dataItem: any) {
    this.isLoading=true;
    dataItem.reSyncAction="RETRY"
    this.wfmsyncService.resync(dataItem).then((value2: any) => {
      this.isLoading=false;
      this.notificationService.success('Re-submit', 'successful');
      this.res();
    }).catch(() => {
      this.isLoading=false;
    });
    
  }
  click(dataItem: any) {
    this.isLoading=true;
    dataItem.reSyncAction="ACCEPT"
    this.wfmsyncService.resync(dataItem).then((value2: any) => {
      this.isLoading=false;
      this.notificationService.success('Accept', 'successful');
      this.res();
    }).catch(() => {
      this.isLoading=false;
    });
  }

  onDisplaySettingsChanged(e) {
    // let pageevent = {skip:0, take:50};
    // this.pageChange(pageevent);
    
    this.setDefaultData(e);
  }
  setDefaultData(isActiveOnly) {
    let filterData = ["FAILED", "QUEUED", "", "NULL","RESUBMITTED","PENDING","EVENT PAUSED","EVENT STOPPED","UN KNOWN", "ACCEPTED"];
    this.isActiveOnly = isActiveOnly;
    let onlyInsert = _.filter(this.actualData, (item) => filterData.includes(item.transactionStatus));
    this.dataView = this.isActiveOnly ? this.actualData : onlyInsert;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.refreshGrid();
  }
  
  private refreshGrid(): void {
    if (!this.dataView) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.dataView, this.gridState.state);
  }
}
