import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import * as moment from 'moment';

import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Meta, ResponseBody } from '../../../../core/models/index';

import { schedulerConfig } from '../../../scheduler.config';

import { ScheduleConsoleContainerDTO } from '../../models/dto/index';
import { ScheduleConsoleCycles } from '../../models/index';
import { ScheduleConsoleMapService } from './schedule-console-map.service';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class ScheduleConsoleApiService {
  private apiUtilService: ApiUtilService;
  private urlParamsService: UrlParamsService;
  private scheduleConsoleMapService: ScheduleConsoleMapService;

  constructor(apiUtilService: ApiUtilService, urlParamsService: UrlParamsService, scheduleConsoleMapService: ScheduleConsoleMapService) {
    this.apiUtilService = apiUtilService;
    this.urlParamsService = urlParamsService;
    this.scheduleConsoleMapService = scheduleConsoleMapService;
  }

  public getScheduleConsoleData(orgLevelId: number, startDate: Date, endDate: Date): Promise<ScheduleConsoleCycles> {
    const url: string = this.apiUtilService.apiRoot `${schedulerConfig.api.root}/${schedulerConfig.api.console}/${orgLevelId}`;

    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, null, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let promise: Promise<ScheduleConsoleCycles> = this.apiUtilService.request<ScheduleConsoleContainerDTO, Meta>(request)
      .then((response: ResponseBody<ScheduleConsoleContainerDTO, Meta>) => this.scheduleConsoleMapService.mapScheduleConsoleCycles(response.data, startDate));
    return promise;
  }
}
