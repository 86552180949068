import { Component, Input, OnInit } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../app.config';

import { OrgLevelBreadcrumbDefinition, OrgLevelBreadcrumbModes } from '../../../organization/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-header-nav',
  templateUrl: 'header-nav.component.html',
  styleUrls: ['header-nav.component.scss']
})
export class HeaderNavComponent implements OnInit {
  @Input()
  public hideLeftSideBar: boolean;
  @Input()
  public hideOrgLevelBreadcrumb: boolean;

  public appConfig: IApplicationConfig;
  public orgLevelBreadcrumbsHeaderMode: OrgLevelBreadcrumbDefinition = OrgLevelBreadcrumbModes.header;

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }
}
