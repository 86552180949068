import { IPosition, Position, Lookup } from '../../../../organization/models/index';
import { IEmpTimecardPayUnit, EmpTimecardPayUnit } from './emp-timecard-pay-unit';

export interface IEmpPayUnitsInfo {
  employeeId: number;
  departmentId: number;
  employeeName: string;
  hireDate: string;
  terminateDate: string;
  position: IPosition;
  timecardPayUnits: IEmpTimecardPayUnit[];
  positions: IPosition[];
  start: string;
  end: string;
}

export class EmpPayUnitsInfo {
  employeeId: number;
  departmentId: number;
  employeeName: string;
  hireDate: Date;
  terminateDate: Date;
  position: Position;
  timecardPayUnits: EmpTimecardPayUnit[];
  positions: Position[];
  positionsLookup: Lookup;
  positionsLookupDateKey: string;
  dayTimecard: StringMap<EmpTimecardPayUnit>
  start: Date;
  end: Date;

}
