import { Component, OnInit, Provider } from '@angular/core';
import { DialogOptions, DialogOptions2, IDialog, ModalService } from '../../../../../common';
import { PbjDuplicateMatchesDialogOptions } from '../../../models/pbj-duplicate-workers/pbj-duplicate-matches-dialog-options';
import * as moment from 'moment';

@Component({
  selector: 'slx-pbj-duplicate-matches-dialog',
  templateUrl: './pbj-duplicate-matches-dialog.component.html',
  styleUrls: ['./pbj-duplicate-matches-dialog.component.scss']
})
export class PbjDuplicateMatchesDialogComponent implements IDialog {
  
  static duplicateMatchesDefaultDate:Date;
  public dialogResult: boolean;
  private modalService: ModalService;
  public selectedDate:Date;
  public currentDate:Date = new Date();

  constructor(private pbjDuplicateMatchesDialogOptions: PbjDuplicateMatchesDialogOptions,
    private dialogOptions: DialogOptions, modalService: ModalService){ 
    this.modalService = modalService;
    this.dialogResult = false;
    this.selectedDate = PbjDuplicateMatchesDialogComponent.duplicateMatchesDefaultDate;
    console.log(PbjDuplicateMatchesDialogComponent.duplicateMatchesDefaultDate);
    console.log(this.selectedDate)
  }

  public get foundMatches(): boolean {
    if (this.pbjDuplicateMatchesDialogOptions) {
        return this.pbjDuplicateMatchesDialogOptions.FoundMatchingDupes;
    }
    return true;
}

  public static openDialog(
    options: PbjDuplicateMatchesDialogOptions,
    modalService: ModalService,
    callback: (result: boolean) => void
  ): PbjDuplicateMatchesDialogComponent {
    this.duplicateMatchesDefaultDate = options.DuplicateMatchesDate;
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.height = 200;
    dialogOptions.width = 500;
    dialogOptions.className = 'slx-pbj-duplicate-matches-modal';
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions,
      },
      {
        provide: PbjDuplicateMatchesDialogOptions,
        useValue: options
      }
    ];
    let dialog: PbjDuplicateMatchesDialogComponent = modalService.globalAnchor.openDialog2(
      PbjDuplicateMatchesDialogComponent,
      'Select Date',
      dialogOptions,
      resolvedProviders,
      callback
    );
    return dialog;
  }

  public onFilterDateChanged(date:Date){
    this.selectedDate = date;
  }

  public onClose(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public onSave(): void {
    this.dialogResult = true;
    this.selectedDate = new Date(this.selectedDate.setHours(0,0,0,0));
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }
}
