import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import * as moment from 'moment';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { KendoGridFilterInputDirective, KendoGridServerFilterDirective } from '../../../directives/index';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoFilterHelper } from '../../../../core/utils/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { FilterStateManagementService } from '../../../services/index';
var KendoGridFilterWrapperCellComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridFilterWrapperCellComponent, _super);
    function KendoGridFilterWrapperCellComponent(filterService, serverFilterDirective, filterStateManagementService) {
        var _this = _super.call(this, filterService) || this;
        _this.serverFilterDirective = serverFilterDirective;
        _this.filterStateManagementService = filterStateManagementService;
        _this.operators = [];
        _this.operatorChanged = new EventEmitter();
        _this.clear = new EventEmitter();
        return _this;
    }
    Object.defineProperty(KendoGridFilterWrapperCellComponent.prototype, "hostClasses", {
        get: function () { return true; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridFilterWrapperCellComponent.prototype, "overrideBaseClasses", {
        get: function () { return false; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridFilterWrapperCellComponent.prototype, "defaultOperator", {
        get: function () {
            if (!kendoUtils.isNullOrEmptyString(this.m_defaultOperator)) {
                return this.m_defaultOperator;
            }
            else if (this.operators && this.operators.length) {
                return this.operators[0].value;
            }
            return 'eq';
        },
        set: function (value) {
            this.m_defaultOperator = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridFilterWrapperCellComponent.prototype, "currentFilter", {
        get: function () {
            return this.filterByField(this.column.field);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridFilterWrapperCellComponent.prototype, "showButton", {
        get: function () {
            var filter = this.currentFilter;
            return kendoUtils.isPresent(filter) && (!kendoUtils.isBlank(filter.value) || kendoUtils.isBlankOperator(filter.operator));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridFilterWrapperCellComponent.prototype, "currentOperator", {
        get: function () {
            var filter = this.currentFilter;
            if (!this.m_operator) {
                this.m_operator = filter ? filter.operator : this.defaultOperator;
            }
            return this.m_operator;
        },
        set: function (value) {
            if (!value) {
                return; //operator can not be undefined
            }
            var changed = this.m_operator !== value;
            this.m_operator = value;
            var slxFilter = kendoUtils.slxFiltersMap[value];
            if ((slxFilter && !slxFilter.needValue) || value === 'isnull' || value === 'isnotnull' || value === 'isempty' || value === 'isnotempty') {
                this.applyNoValueFilter();
            }
            else if (!kendoUtils.isBlank(value) && kendoUtils.isPresent(this.lastValue)) {
                this.onChange(this.lastValue);
            }
            if (changed) {
                this.operatorChanged.next(this.m_operator);
            }
        },
        enumerable: true,
        configurable: true
    });
    KendoGridFilterWrapperCellComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    KendoGridFilterWrapperCellComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        if (kendoUtils.isPresent(this.input)) {
            this.changeSubscription = this.input.change.subscribe(this.onChange.bind(this));
            if (this.filterStateManagementService) {
                this.restoreFilterSubscription = this.filterStateManagementService.restoreFiltersState$.subscribe(function (state) {
                    if (state.field !== _this.column.field) {
                        return;
                    }
                    _this.setValue(state.value);
                    _this.m_operator = state.operator;
                    _this.operatorChanged.next(_this.m_operator);
                });
                this.filterRestoredSubscription = this.filterStateManagementService.allFiltersRestored$.subscribe(function (value) {
                    if (value.lastField !== _this.column.field) {
                        return;
                    }
                    _this.filter = value.filters;
                    _this.applyFilter(value.filters);
                });
            }
        }
    };
    KendoGridFilterWrapperCellComponent.prototype.onChange = function (value) {
        if (kendoUtils.isBlank(value)) {
            this.removeFilter(this.column.field);
            if (this.serverFilterDirective) {
                this.serverFilterDirective.removeFilter(this.column.field);
            }
            this.applyFilter(this.filter);
            this.saveFilterState(value, this.currentOperator, this.column.field);
            return;
        }
        this.filter = this.calcFilter(value);
        this.applyFilter(this.filter);
        this.saveFilterState(value, this.currentOperator, this.column.field);
        if (this.serverFilterDirective) {
            this.serverFilterDirective.filterChanged(this.filter);
        }
    };
    KendoGridFilterWrapperCellComponent.prototype.onClear = function () {
        this.setValue(null);
        this.onChange(null);
        this.currentOperator = this.defaultOperator;
        this.clear.emit(null);
    };
    KendoGridFilterWrapperCellComponent.prototype.setValue = function (value) {
        this.lastValue = value;
        this.input.value = value;
    };
    KendoGridFilterWrapperCellComponent.prototype.calcFilter = function (value) {
        this.removeFilter(this.column.field);
        var root = this.filter || { filters: [], logic: 'and' };
        this.setValue(value);
        var descriptor = KendoFilterHelper.createValuedFilters(this.column.field, this.currentOperator, value);
        if (descriptor) {
            root.filters.push(descriptor.filter);
            if (this.m_operator !== descriptor.newOperator) {
                this.m_operator = descriptor.newOperator;
                this.operatorChanged.next(this.m_operator);
            }
        }
        return root;
    };
    KendoGridFilterWrapperCellComponent.prototype.composeFilter = function (current, filter) {
        return kendoUtils.composeKendoFilter(current, filter);
    };
    KendoGridFilterWrapperCellComponent.prototype.calcNoValueFilter = function () {
        var _this = this;
        kendoUtils.trimFilterByField(this.filter, this.column.field);
        var root = this.filter;
        var currentDay = moment().startOf('day');
        var endCurrentDay = moment().endOf('day');
        var res = KendoFilterHelper.createNonValuedFilters(this.column.field, this.currentOperator, this.lastValue);
        this.setValue(res.value);
        _.forEach(res.filters, function (f) { return root = _this.composeFilter(root, f); });
        return root;
        //this.setValue(null);
        //return this.updateFilter({ field: this.column.field, operator: this.currentOperator, value: null });
    };
    KendoGridFilterWrapperCellComponent.prototype.applyNoValueFilter = function () {
        this.filter = this.calcNoValueFilter();
        this.applyFilter(this.filter);
        if (this.serverFilterDirective) {
            this.serverFilterDirective.filterChanged(this.filter);
        }
    };
    KendoGridFilterWrapperCellComponent.prototype.saveFilterState = function (value, operator, field) {
        if (!this.filterStateManagementService) {
            return;
        }
        this.filterStateManagementService.saveFilterState(value, operator, field);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridFilterWrapperCellComponent.prototype, "restoreFilterSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridFilterWrapperCellComponent.prototype, "filterRestoredSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridFilterWrapperCellComponent.prototype, "changeSubscription", void 0);
    return KendoGridFilterWrapperCellComponent;
}(BaseFilterCellComponent));
export { KendoGridFilterWrapperCellComponent };
