import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { GridComponent } from '@progress/kendo-angular-grid';
import { StateResetTypes } from '../../../../core/models/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { StateManagementService } from '../../../../common/services/index';
import { KendoGridStateHelper } from '../../../models/index';
var KendoGridFiltersStateDirective = /** @class */ (function () {
    function KendoGridFiltersStateDirective(kendoGrid, stateManagement) {
        this.kendoGrid = kendoGrid;
        this.stateManagement = stateManagement;
        this.kendoGridStateChanged = new EventEmitter();
        this.subscriptions = {};
    }
    Object.defineProperty(KendoGridFiltersStateDirective.prototype, "gridStateHelper", {
        set: function (v) {
            this.gridState = v;
        },
        enumerable: true,
        configurable: true
    });
    KendoGridFiltersStateDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.validateMandatoryFields();
        this.initialFiltersState = _.cloneDeep(this.gridState.state);
        this.stateManagement.init(this.gridState.gridComponentKey, true);
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return _.isObjectLike(o) && _.isFinite(o.id) && (!_.isObjectLike(_this.orgLevel) || _this.orgLevel.id !== o.id); })
            .subscribe(function (o) { return _this.resetGridState(o); });
        this.subscriptions.stateReady = this.stateManagement.onInit$
            .subscribe(function () { return _this.restoreGridState(); });
        this.subscriptions.gridState = this.kendoGrid.dataStateChange
            .asObservable()
            .subscribe(function () { return _this.saveGridState(); });
    };
    KendoGridFiltersStateDirective.prototype.ngOnDestroy = function () {
    };
    KendoGridFiltersStateDirective.prototype.restoreGridState = function () {
        var state = this.getStoredState();
        this.assignRestoredState(state);
        this.kendoGridStateChanged.emit(state);
    };
    KendoGridFiltersStateDirective.prototype.getStoredState = function () {
        var state = this.stateManagement.getControlState(this.gridState.gridControlStateKey);
        var gState = _.isObjectLike(state.value) ? state.value : null;
        if (_.isObjectLike(gState) && _.isObjectLike(gState.filter)) {
            gState.filter = this.stateManagement.mapStateIntoFilters(gState.filter);
        }
        return gState;
    };
    KendoGridFiltersStateDirective.prototype.assignRestoredState = function (value) {
        if (_.isObjectLike(value) && this.gridState.assignRestoredState) {
            this.gridState.state = value;
            if (this.gridState.refreshGridAfterRestoreState) {
                this.gridState.refreshGrid();
            }
        }
    };
    KendoGridFiltersStateDirective.prototype.saveGridState = function () {
        var gState = _.cloneDeep(this.gridState.state);
        if (_.isObjectLike(gState) && _.isObjectLike(gState.filter)) {
            gState.filter = this.stateManagement.mapFiltersIntoState(gState.filter);
        }
        this.stateManagement.setControlState(this.gridState.gridControlStateKey, { value: gState }, this.gridState.gridComponentStateResetType);
    };
    KendoGridFiltersStateDirective.prototype.resetGridState = function (o) {
        var manuallyChangedOrgLevel = _.isObjectLike(this.orgLevel) && _.isFinite(this.orgLevel.id);
        this.orgLevel = o;
        if (manuallyChangedOrgLevel && this.gridState.gridComponentStateResetType & StateResetTypes.OrgLevelChange) {
            var state = _.cloneDeep(this.initialFiltersState);
            this.assignRestoredState(state);
            this.kendoGridStateChanged.emit(state);
        }
    };
    KendoGridFiltersStateDirective.prototype.validateMandatoryFields = function () {
        if (!(_.isObjectLike(this.gridState))) {
            throw new TypeError('The "slxKendoGridFiltersState" is not an object');
        }
        if (!(this.gridState instanceof KendoGridStateHelper)) {
            throw new TypeError('The input "slxKendoGridFiltersState" can be only instance of KendoGridStateHelper class');
        }
        if (!_.isString(this.gridState.gridComponentKey) || _.size(this.gridState.gridComponentKey) === 0) {
            throw new TypeError('The property "gridStateKey" cannot be null or empty');
        }
        if (!_.isString(this.gridState.gridControlStateKey) || _.size(this.gridState.gridControlStateKey) === 0) {
            throw new TypeError('The property "gridFilterStateKey" cannot be null or empty');
        }
        if (!_.isFinite(this.gridState.gridComponentStateResetType)) {
            throw new TypeError('The property "gridFiltersStateResetType" cannot be null or undefined');
        }
        if (this.gridState.gridComponentKey === this.gridState.gridControlStateKey) {
            throw new TypeError('The property "gridStateKey" cannot be equal to "gridFilterStateKey" ');
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], KendoGridFiltersStateDirective.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], KendoGridFiltersStateDirective.prototype, "subscriptions", void 0);
    return KendoGridFiltersStateDirective;
}());
export { KendoGridFiltersStateDirective };
