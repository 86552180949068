import { Location } from '@angular/common';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';

export class UserProfileNavigationService {

    constructor(private router: Router, private route: ActivatedRoute, private location: Location) { }

    public navigateToUserSearch(search: string): void {
        const extras: NavigationExtras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(this.getSearchUrlTree(search), extras);
    }

    public navigateToUserProfile(userId: number): void {
        const extras: NavigationExtras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(this.getUrlTree(userId), extras);
    }

    public navigateCreateNewUser(): void {
        const extras: NavigationExtras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(this.getUrlTree(), extras);
    }

    public navigateToUsersScreen(): void {
        const extras: NavigationExtras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        let tree: UrlTree = this.router.createUrlTree(['configure_users'], { relativeTo: this.route.pathFromRoot[2] });
        this.router.navigateByUrl(tree, extras);
    }

    public discardNewUser(): void {
        this.location.back();
    }

    private getSearchUrlTree(search: string): UrlTree {
        return this.router.createUrlTree(
            ['configure_users'],
            {
                relativeTo: this.route.pathFromRoot[2],
                queryParams: { search: search }
            });
    }

    private getUrlTree(userId?: number): UrlTree {
        if (userId) {
            return this.router.createUrlTree(['user', userId, 'profile'], { relativeTo: this.route.pathFromRoot[2] });
        }
        return this.router.createUrlTree(['user', 'create'], { relativeTo: this.route.pathFromRoot[2] });
    }
}
