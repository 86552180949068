<slx-moment-time-input [required]="required" [readonly]="readonly" [placeholder]="placeholder" [(ngModel)]="value" [className]="className"
    [model]="model" [field]="field" (onDateChanged)="onTimeChanged ($event)" protectDate="true"></slx-moment-time-input>

<div [hidden]="readonly">
    <div class="hidden-date-input">
        <input class="date-picker-field" />
    </div>​
    <button type="button" class="button active calendar" (click)="toggleCalendar ()">
            <span class="caption">Calendar</span>
            <i class="fas fa-calendar-alt icon" aria-hidden="true"></i>
      </button>
</div>
