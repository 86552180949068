import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { ColumnSettingsStorageService, ColumnManagementService } from '../../../../common/services/index';
import { WcIncidentRosterState, WcIncidentRosterContainer, WcViewList, WcViewItem } from '../../models/index';
import { WcRosterToolbarService } from './wc-roster-toolbar.service';
import { WcRosterApiService } from './wc-roster-api.service';
var WcCommonManagementService = /** @class */ (function () {
    function WcCommonManagementService(toolbarService, apiService, columnSettingsStorageService, columnManagementService) {
        this.toolbarService = toolbarService;
        this.apiService = apiService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.columnManagementService = columnManagementService;
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.exportTo$ = new Subject();
        this.state$ = new ReplaySubject(1);
        this.loading$ = new ReplaySubject(1);
        this.recordsLoaded$ = new ReplaySubject(1);
        this.dateRange$ = new Subject();
        this.subscriptions = {};
    }
    WcCommonManagementService.prototype.init = function (componentId, columnsVisibilityKey, viewMode) {
        this.componentId = componentId;
        this.columnsVisibilityKey = columnsVisibilityKey;
        this.currentViewMode = new WcViewItem(viewMode, '', '');
    };
    WcCommonManagementService.prototype.destroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
        this.orgLevelChanged$.complete();
        this.exportTo$.complete();
        this.state$.complete();
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.dateRange$.complete();
    };
    WcCommonManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    WcCommonManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    WcCommonManagementService.prototype.subscribeToChangeState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.state$.subscribe(callback);
    };
    WcCommonManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    WcCommonManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    WcCommonManagementService.prototype.openEditReportDialog = function (reportId) {
        this.toolbarService.openEditReportDialog(reportId);
    };
    WcCommonManagementService.prototype.subscribeToToolbarService = function () {
        var _this = this;
        this.subscriptions.dateRange = this.toolbarService
            .subscribeToDateRange(function (r) {
            _this.dateRange = r;
            _this.loadIncidents();
            _this.dateRange$.next(r);
        });
        this.subscriptions.export = this.toolbarService
            .subscribeToExport(function (isPDF) { return _this.exportTo$.next(isPDF); });
        this.subscriptions.saveState = this.toolbarService
            .subscribeToSaveState(function (s) {
            _this.columnSettingsStorageService.setColumnsState(_this.componentId, _this.columnsVisibilityKey, s.columns);
            _this.state$.next(s);
        });
        this.subscriptions.defineState = this.toolbarService
            .subscribeToDefineState(function (s) {
            _this.state$.next(s);
        });
    };
    WcCommonManagementService.prototype.subscribeToIncidentsAmount = function () {
        var _this = this;
        this.subscriptions.incidents = this.toolbarService
            .subscribeToIncidentsAmount(function (i) {
            _this.incidentsAmount = i;
            _this.loadIncidents();
        });
    };
    WcCommonManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                    return [2 /*return*/];
                this.orgLevel = orgLevel;
                this.orgLevelChanged$.next(this.orgLevel);
                this.loadIncidents();
                return [2 /*return*/];
            });
        }); });
    };
    WcCommonManagementService.prototype.loadIncidents = function () {
        if (this.currentViewMode.isOshaBudgetTracker) {
            this.loadOshaIncidents();
        }
        else {
            this.loadRosterIncidents();
        }
    };
    WcCommonManagementService.prototype.loadRosterIncidents = function () {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))
            || !_.isDate(_.get(this.dateRange, 'startDate'))
            || !_.isDate(_.get(this.dateRange, 'endDate'))
            || this.currentViewMode.isRepeatInjuryList && !_.isFinite(this.incidentsAmount))
            return;
        this.loading$.next(true);
        this.apiService.getIncidentRoster(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate, this.incidentsAmount)
            .then(function (container) {
            _this.recordsLoaded$.next(container);
            _this.loading$.next(false);
            _this.toolbarService.permissionsChanged(container.canCreate);
        });
    };
    WcCommonManagementService.prototype.loadOshaIncidents = function () {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))
            || !_.isDate(_.get(this.dateRange, 'startDate'))
            || !_.isDate(_.get(this.dateRange, 'endDate')))
            return;
        this.loading$.next(true);
        this.apiService.getOshaIncidentRoster(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
            .then(function (container) {
            _this.recordsLoaded$.next(container);
            _this.loading$.next(false);
            _this.toolbarService.permissionsChanged(container.canCreate);
        });
    };
    WcCommonManagementService.prototype.restoreState = function (state) {
        var _this = this;
        this.columnManagementService.init(this.componentId);
        this.columnManagementService.initializeGroupWithColumns(this.columnsVisibilityKey, state.columns);
        this.subscriptions.state = this.columnManagementService.groupInitialized$
            .filter(function (event) { return event.group === _this.columnsVisibilityKey; })
            .subscribe(function () {
            _this.toolbarService.defineState(state);
        });
    };
    WcCommonManagementService.prototype.setLoading = function (v) {
        this.loading$.next(v);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], WcCommonManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], WcCommonManagementService.prototype, "subscriptions", void 0);
    return WcCommonManagementService;
}());
export { WcCommonManagementService };
