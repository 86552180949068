/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../shift-swap-approve-deny/shift-swap-approve-deny.component.ngfactory";
import * as i2 from "../shift-swap-approve-deny/shift-swap-approve-deny.component";
import * as i3 from "../../../services/shift-swap/shift-swap-manage.service";
import * as i4 from "./shift-swap-approve-deny-popup.component";
import * as i5 from "../../../../common/models/dialog-options";
import * as i6 from "../../../../common/services/modal/modal.service";
import * as i7 from "../../../models/shift-swap/shift-swap-info";
var styles_ShiftSwapApproveDenyPopupComponent = [];
var RenderType_ShiftSwapApproveDenyPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ShiftSwapApproveDenyPopupComponent, data: {} });
export { RenderType_ShiftSwapApproveDenyPopupComponent as RenderType_ShiftSwapApproveDenyPopupComponent };
export function View_ShiftSwapApproveDenyPopupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-shift-swap-approve-deny", [], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.onButtonClicked($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ShiftSwapApproveDenyComponent_0, i1.RenderType_ShiftSwapApproveDenyComponent)), i0.ɵdid(1, 49152, null, 0, i2.ShiftSwapApproveDenyComponent, [i3.ShiftSwapManageService], { shiftSwap: [0, "shiftSwap"] }, { buttonClicked: "buttonClicked" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shiftSwap; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ShiftSwapApproveDenyPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-shift-swap-approve-deny-popup", [], null, null, null, View_ShiftSwapApproveDenyPopupComponent_0, RenderType_ShiftSwapApproveDenyPopupComponent)), i0.ɵdid(1, 49152, null, 0, i4.ShiftSwapApproveDenyPopupComponent, [i5.DialogOptions, i6.ModalService, i7.ShiftSwapInfo], null, null)], null, null); }
var ShiftSwapApproveDenyPopupComponentNgFactory = i0.ɵccf("slx-shift-swap-approve-deny-popup", i4.ShiftSwapApproveDenyPopupComponent, View_ShiftSwapApproveDenyPopupComponent_Host_0, {}, {}, []);
export { ShiftSwapApproveDenyPopupComponentNgFactory as ShiftSwapApproveDenyPopupComponentNgFactory };
