import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges
} from '@angular/core';
import * as _ from 'lodash';

import { LookupService } from '../../services/lookup/lookup.service';
import {
  Position,
  LocationUnit,
  ShiftDefinition,
  EmployeeShift,
  Lookup, LookupType
} from '../../models/index';
import { DateRange } from '../../../core/models';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-shift-editor',
  templateUrl: 'employee-shift-editor.component.html',
  styleUrls: ['employee-shift-editor.component.scss']
})
export class EmployeeShiftEditorComponent implements OnChanges {
  @Input('positionPeriod')
  public set positionDateRange(positionPeriod: DateRange) {
    if (this.positionPeriod === positionPeriod) {
      return;
    }
    this.positionPeriod = positionPeriod;
    if (!(_.isNil(this.employeeId))) {
      this.loadPositionLookup();
    }
  }
  @Input('employeeId')
  public set employee(employeeId: number) {
    if (this.employeeId === employeeId) {
      return;
    }
    this.employeeId = employeeId;
    this.loadPositionLookup();
  }
  @Input()
  public homePositionId: number;
  @Input()
  public homeShiftId: number;
  @Input()
  public homeUnitId: number;
  @Input()
  public employeeShift: EmployeeShift;
  @Input()
  public useAbsence: boolean;
  @Input()
  public canChangePosition: boolean;
  @Input()
  public useConstraint: boolean;
  @Input()
  public loadHomeValues: boolean = false;
  @Input()
  public isReadOnly: boolean = false;

  @Output()
  public onRequiredFieldsPopulated: EventEmitter<any> = new EventEmitter<any>();

  public positionLookup: Lookup;
  public shiftLookup: Lookup;
  public unitLookup: Lookup;
  public constraintLookup: Lookup;
  public scheduleAbsenceLookup: Lookup;

  public state: {
    isPositionLoading: boolean;
    isShiftLoading: boolean;
    isUnitLoading: boolean;
    isConstraintLoading: boolean;
    isAbsenceLoading: boolean;
  };

  private lookupService: LookupService;
  private employeeId: number;
  private positionPeriod: DateRange;
  private selectedPositionOrgLevelId: number;

  private set position(value: Position) {
    this.employeeShift.position = value;
    this.selectedPositionChanged(this.employeeShift.position);
  }

  constructor(lookupService: LookupService) {
    this.lookupService = lookupService;
    this.state = {
      isPositionLoading: false,
      isShiftLoading: false,
      isUnitLoading: false,
      isConstraintLoading: false,
      isAbsenceLoading: false,
    };
    this.useAbsence = false;
    this.useConstraint = true;
    this.canChangePosition = true;
  }

  public ngOnChanges() {
    if (this.loadHomeValues) {
      this.setHomePostion();
      this.setHomeShift();
      this.setHomeUnit();
      this.employeeShift.constraint = null;
    }
  }

  public selectedPositionChanged(selectedPosition: Position): void {
    this.modelChanged();
    if (selectedPosition && selectedPosition.orgLevelId && this.selectedPositionOrgLevelId !== selectedPosition.orgLevelId) {
      this.selectedPositionOrgLevelId = selectedPosition.orgLevelId;
      this.loadShiftDefinitionsLookup(this.employeeId, this.selectedPositionOrgLevelId, true);
      this.loadLocationUnitsLookup(this.employeeId, this.selectedPositionOrgLevelId);
      this.loadConstraintDefinitionsLookup(this.employeeId, this.selectedPositionOrgLevelId);
      this.loadScheduleAbsenceLookup(this.selectedPositionOrgLevelId);
    } else {
      this.setHomeShift();
    }
  }

  public modelChanged(): void {
    if ((!this.canChangePosition || this.employeeShift.position)
      && this.employeeShift.shift && this.employeeShift.unit) {
      this.onRequiredFieldsPopulated.emit();
    }
  }

  private loadPositionLookup(): void {
    this.state.isPositionLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.position, employeeId: this.employeeId, isActive: _.isNil(this.positionPeriod) })
      .then((positionLookup: Lookup) => {
        this.positionLookup = positionLookup;
        if (!_.isNil(this.positionPeriod)) {
          this.positionLookup.items = _.filter(this.positionLookup.items, (x: Position) => x.startDate <= this.positionPeriod.endDate && x.endDate >= this.positionPeriod.startDate);
        }
        if (this.positionLookup && this.positionLookup.items) {
          let positionToSelect: Position = null;
          if (this.employeeShift.position) {
            positionToSelect = this.findPositionById(this.employeeShift.position.id);
          }
          if (!positionToSelect && this.homePositionId) {
            positionToSelect = this.findPositionById(this.homePositionId);
          }
          if (!positionToSelect) {
            positionToSelect = _.first(this.positionLookup.items);
          }
          this.position = positionToSelect;
        }
        this.state.isPositionLoading = false;
      })
      .catch(() => {
        this.state.isPositionLoading = false;
      });
  }

  private findPositionById(positionId: number): Position {
    return _.find(this.positionLookup.items, (item: Position): boolean => {
      return item.id === positionId;
    });
  }

  private loadShiftDefinitionsLookup(employeeId: number, orgLevelId: number, isPositionDropDownChange: boolean = false): void {
    this.state.isShiftLoading = true;
    this.employeeShift.shift = null;
    this.lookupService.getLookup({ lookupType: LookupType.shift, employeeId: employeeId, orgLevelId: orgLevelId })
      .then((shiftLookup: Lookup) => {
        if (this.selectedPositionOrgLevelId === orgLevelId) {
          this.shiftLookup = shiftLookup;
        }

        if (this.shiftLookup && this.shiftLookup.items) {
          this.setHomeShift();
        }

        if(!this.employeeShift.shift && isPositionDropDownChange)
        {
          this.employeeShift.shift = _.first(this.shiftLookup.items);
        }
        
        this.modelChanged();
        this.state.isShiftLoading = false;
      })
      .catch(() => {
        this.state.isShiftLoading = false;
      });
  }

  private loadLocationUnitsLookup(employeeId: number, orgLevelId: number): void {
    this.state.isUnitLoading = true;
    this.employeeShift.unit = null;
    this.lookupService.getLookup({ lookupType: LookupType.locationUnit, employeeId: employeeId, orgLevelId: orgLevelId })
      .then((unitLookup: Lookup) => {
        if (this.selectedPositionOrgLevelId === orgLevelId) {
          this.unitLookup = unitLookup;
        }
        if (this.unitLookup && this.unitLookup.items) {
          this.setHomeUnit();
        }
        this.modelChanged();
        this.state.isUnitLoading = false;
      })
      .catch(() => {
        this.state.isUnitLoading = false;
      });
  }

  private loadConstraintDefinitionsLookup(employeeId: number, orgLevelId: number): void {
    if (!this.useConstraint) { return; }
    this.state.isConstraintLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.constraintDefinition, employeeId: employeeId, orgLevelId: orgLevelId })
      .then((constraintLookup: Lookup) => {
        if (this.selectedPositionOrgLevelId === orgLevelId) {
          this.constraintLookup = constraintLookup;
        }
        this.state.isConstraintLoading = false;
      })
      .catch(() => {
        this.state.isConstraintLoading = false;
      });
  }

  private loadScheduleAbsenceLookup(orgLevelId: number): void {
    if (!this.useAbsence) { return; }
    this.state.isAbsenceLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.scheduleAbsence, orgLevelId: orgLevelId })
      .then((scheduleAbsenceLookup: Lookup) => {
        if (this.selectedPositionOrgLevelId === orgLevelId) {
          this.scheduleAbsenceLookup = scheduleAbsenceLookup;
        }
        this.state.isAbsenceLoading = false;
      })
      .catch(() => {
        this.state.isAbsenceLoading = false;
      });
  }

  public setHomeUnit(): void {
    if (this.homeUnitId) {
      let homeUnit: LocationUnit = _.find(this.unitLookup.items, (item: LocationUnit): boolean => {
        return item.id === this.homeUnitId;
      });
      if (homeUnit) {
        this.employeeShift.unit = homeUnit;
      }
    }
    if (!this.employeeShift.unit) {
      this.employeeShift.unit = _.first(this.unitLookup.items);
    }
  }

  public setHomeShift(): void {
    if (this.homeShiftId) {
      let homeShift: ShiftDefinition = _.find(this.shiftLookup.items, (item: ShiftDefinition): boolean => {
        return item.id === this.homeShiftId;
      });
      if (homeShift) {
        this.employeeShift.shift = homeShift;
      }
    }
  }

  public setHomePostion(): void {
    let positionToSelect: Position = null;
    if (this.homePositionId) {
      positionToSelect = this.findPositionById(this.homePositionId);
    }
    if (!positionToSelect) {
      positionToSelect = _.first(this.positionLookup.items);
    }
    this.position = positionToSelect;
  }
}
