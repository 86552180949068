import { Injectable } from '@angular/core';

import * as moment from 'moment';
import * as _ from 'lodash';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../app.config';

import { ManagementBaseService } from '../../../core/services/index';
import { Position } from '../../../organization/models/index';
import { EssTemplateApiService } from './ess-templates-api.service';
import { InfoDialogComponent } from '../../../common/components/index';
import { ModalService } from '../../../common/services/index';

import { EssTemplate, EssTemplateDeletionRespose } from '../models/index';
import { NotificationsService } from '../../../core/components/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

@Injectable()
export class EssTemplateManagementService extends ManagementBaseService<EssTemplate[], any> {

  public orgLevel: OrgLevel;
  public templates: EssTemplate[];
  public selectTemplate$: Subject<EssTemplate>;
  public addTemplateBtn$ = new Subject<boolean>();

  constructor(
    private api: EssTemplateApiService,
    private modalService: ModalService,
  ) {
    super();
    this.selectTemplate$ = new Subject();
  }

  public isOrganization(): boolean {
    return this.orgLevel.type === OrgLevelType.organization;
  }

  public loadTemplates(lvl: OrgLevel): void {
    this.orgLevel = lvl;
    this.onLoadStatusChanged(true);
    this.api.getTemplates(this.orgLevel.id)
      .then((data: EssTemplate[]) => {
        this.templates = _.orderBy(data, (t: EssTemplate) => t.essTemplateDefinition.isDefault, 'desc');
        this.onLoaded(this.templates);
        this.onLoadStatusChanged(false);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public addTemplate(lvl: OrgLevel): void {
    this.orgLevel = lvl;
    this.onLoadStatusChanged(true);
    this.api.addTemplate(this.orgLevel.id)
      .then((data: EssTemplate) => {
        this.templates.push(data);
        this.onLoaded(this.templates);
        this.onLoadStatusChanged(false);
        this.selectTemplate(data);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public saveTemplate(template: EssTemplate): void {
    this.onLoadStatusChanged(true);
    this.api.saveTemplate(template)
      .then((data: EssTemplate) => {
        if (template.essTemplateDefinition.isDefault) {
          this.templates.splice(0, 1, data);
        } else {
          const index = _.findIndex(this.templates, (t: EssTemplate) => data.essTemplateDefinition.id === t.essTemplateDefinition.id);
          this.templates.splice(index, 1, data);
        }
        this.onLoaded(this.templates);
        this.onLoadStatusChanged(false);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public deleteTemplate(template: EssTemplate): void {
    this.onLoadStatusChanged(true);
    this.api.deleteTemplate(template.essTemplateDefinition.id)
      .then((resp: EssTemplateDeletionRespose) => {
        if (resp.isSuccess) {
          _.remove(this.templates, (t: EssTemplate) => template.essTemplateDefinition.id === t.essTemplateDefinition.id);
          this.onLoaded(this.templates);
          this.onLoadStatusChanged(false);
        } else {
          this.onLoadStatusChanged(false);
          const positions = _.map(resp.assignedPositions, ((p: Position) => `"${p.name}"`));
          const message = `Template "${template.essTemplateDefinition.name}" is used for position(s)\r\n: ${_.join(positions)}`;
          InfoDialogComponent.OpenDialog('Cannot remove template', message, this.modalService);
        }
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public selectTemplate(template: EssTemplate): void {
    this.selectTemplate$.next(template);
  }
  
  public AddNewTempateAction(value : boolean) {
    return this.addTemplateBtn$.next(value);
  }
}
