export interface IScheduleRotationTemplate {
    id?: string;
    departmentId?: number;
    departmentName: string;
    name: string;
    employeeId: number;
    employeeType: string;
    jobCode: number;
    positionName: string;
    shiftId?: number;
    shiftName: string;
    employeeTypeName: string;
    scheduleDays: IScheduleDays[];
    totalHours?: number;
    isPrimary: boolean;
    isDifferentDepartment: boolean;
    state: any;
    isOverTime: boolean;
    otHours?: number;
    rotationCount?: number;
    terminationDate?: Date;
    activationDate?: Date;
    secondaryJobCodes: string;
    primaryGroupId?: number;
    primaryPositionGroupName: string;
    isNewRotation: boolean;
    homeShiftGroupId?: number;
    homeShiftGroupDescription: string;
    positionGroupId: number;
    positionGroupName: string;
    slotId: number;
    isNewlyAssigned: boolean;
    isEmptySlot: boolean;
    seqId: number;
    shiftGroupName: string;
    isAgencyEmployee : boolean;
    hasRotations : boolean;
    empPayPeriod: string;
    isFullTime: boolean;
    onlySecondaryJobCodes: ISecondaryJobCodes[];
    maskedJobCode: number;
    positionGroupedJobCodes: string;
    otherDepartmentSecondaryJobCodes: ISecondaryJobCodes[];
}

export class ScheduleRotationTemplate {
    public id?: string;
    public departmentId?: number;
    public departmentName: string;
    public name: string;
    public employeeId: number;
    public employeeType: string;
    public jobCode: number;
    public positionName: string;
    public shiftId?: number;
    public shiftName: string;
    public employeeTypeName: string;
    public scheduleDays: ScheduleDays[];
    public totalHours?: number = 0;
    public isPrimary: boolean;
    public isDifferentDepartment: boolean;
    public state: any;
    public isOverTime: boolean;
    public otHours?: number;
    public rotationCount?: number;
    public terminationDate?: Date;
    public activationDate?: Date;
    public secondaryJobCodes: number[];
    public primaryGroupId?: number;
    public primaryPositionGroupName: string;
    public isNewRotation: boolean;
    public homeShiftGroupId?: number;
    public homeShiftGroupDescription: string;
    public positionGroupId: number;
    public positionGroupName: string;
    public slotId: number;
    public isNewlyAssigned: boolean;
    public isEmptySlot: boolean;
    public seqId: number;
    public shiftGroupName: string;
    public isAgencyEmployee : boolean;
    public hasRotations : boolean;
    public empPayPeriod: string;
    public isFullTime: boolean;
    public onlySecondaryJobCodes: SecondaryJobCodes[];
    public get isFullTimeEmployee(): string {
        return this.isFullTime ? 'FT' : 'PT';
    }
    public maskedJobCode: number;
    public positionGroupedJobCodes: number[];
    public otherDepartmentSecondaryJobCodes: SecondaryJobCodes[];
}

export interface ISecondaryJobCodes {
    jobCode: number;
    jobName: string;
    positionGroupId: number;
    positionGroupName: string;
}

export class SecondaryJobCodes {
    public jobCode: number;
    public jobName: string;
    public positionGroupId: number;
    public positionGroupName: string;
}

export interface IScheduleDays {
    day: IDays;
    shifts: IAvailShifts[];
    isOverLap: boolean;

}

export class ScheduleDays {
    public day: Days;
    public shifts: AvailShifts[];
    public isOverLap: boolean;
}

export interface IDays {
    day: string;
    fullDayName: string;
    date: Date;
    dayName: string;
    formatDate: string;
}

export class Days {
    public day: string;
    public fullDayName: string;
    public date: Date;
    public dayName: string;
    public formatDate: string;
}

export interface IAvailShifts {
    calendardate: string;
    calendarFormatDate: string;
    orgLevelId: string;
    jobCode: number;
    shiftGroupId?: number;
    shiftGroupDescription: string;
    shiftId: number;
    unitId: number;
    configType: string;
    hours?: number;
    count?: number;
    weekDay: string;
    positionName: string;
    unitName: string;
    shiftName: string;
    positionGroupId: string;
    positionGroupName: string;
    id?: number;
    isView: boolean;
    isPrimary: string;
    targetDepartment: string;
    state: any;
    startTime: string;
    endTime: string;
    scheduleDate: string;
    deleteInd: boolean;
    srtLockInd: string;
    slotId: number;
    isWhiteColor: boolean;
    isLock: boolean;
    isPrimaryColor: string;
    isAssignedShift: boolean;
    dayNo: number;
    scheduleRotationCount: number;
    seqId: number;
    recordId: number;
    frequency: number;
    isPositionGroupedShift: boolean;
    isJobCodeHidden: boolean;
}

export class AvailShifts {
    public calendardate: string;
    public calendarFormatDate: string;
    public orgLevelId: string;
    public jobCode: number;
    public shiftGroupId?: number;
    public shiftGroupDescription: string;
    public shiftId: number;
    public unitId: number;
    public configType: string;
    public hours?: number;
    public count?: number;
    public weekDay: string;
    public positionName: string;
    public unitName: string;
    public shiftName: string;
    public positionGroupId: string;
    public positionGroupName: string;
    public id: number;
    public isView: boolean;
    public isPrimary: string;
    public targetDepartment: boolean;
    public state: any;
    public startTime: string;
    public endTime: string;
    public scheduleDate: string;
    public deleteInd: boolean;
    public srtLockInd: string;
    public slotId: number;
    public isWhiteColor: boolean;
    public isLock: boolean;

    public isPrimaryColor: boolean;
    public isAssignedShift: boolean;
    public dayNo: number;
    public scheduleRotationCount: number;
    public seqId: number;
    public recordId: number;
    public frequency: number;
    public isPositionGroupedShift: boolean;
    public isJobCodeHidden: boolean;
}

export interface IScheduleRotationPermisions {
    modify: boolean;
}

export interface IEmployeeScheduledData {
    employeeId: number;
    unitId: number;
    jobCode: number;
    shiftId: number;
    scheduledDate: string;
    deleteInd: boolean;
    dayNo: number;
    frequency: number;
    isNewlyAssigned: boolean;
    slotId: number;
    isEmptySlot: boolean;
    seqId: number;
    recordId: number;
    isAlreadyAssignedShift: boolean;
}

export class EmployeeScheduledData {
    employeeId: number;
    unitId: number;
    jobCode: number;
    shiftId: number;
    scheduledDate: string;
    deleteInd: boolean;
    dayNo: number;
    frequency: number;
    isNewlyAssigned: boolean;
    slotId: number;
    isEmptySlot: boolean;
    seqId: number;
    recordId: number;
    isAlreadyAssignedShift: boolean;
    maskedJobCode: number;
}

export class EmpScheduleEntries {
    public employeeId: number;
    public isNewRotation: boolean;
    public isNewlyAssigned: boolean;
    public isEmptySlot: boolean;
    public seqId: number;
    public deletedEntries: AvailShifts[];
    public addedEntries: AvailShifts[];
    public modifiedEntries: AvailShifts[];
    public homeJobCode: number;
    public secondaryJobCodes: number[];
    public onlySecondaryJobCodes: ISecondaryJobCodes[];
}

export interface INoRotationEmployeeList {
    departmentId: number;
    employeeId: number;
    Name: string;
    jobCode: number;
    positionName: string;
}

export class NoRotationEmployeeList {
    public departmentId: number;
    public employeeId: number;
    public Name: string;
    public jobCode: number;
    public positionName: string;
}

export interface ITerminateRequest {
    employeeId: number;
    isNew: boolean;
    slotId: number;
    seqId: number;
    scheduledDate: Date;
    shiftId: number;
    unitId: number;
    jobCode: number;
    deleteInd: boolean;
    dayNo?: number;
    frequency: number;
}
export class TerminateRequest {
    public employeeId: number;
    public isNew: boolean;
    public slotId: number;
    public seqId: number;
    public scheduledDate: Date;
    public shiftId: number;
    public unitId: number;
    public jobCode: number;
    public deleteInd: boolean;
    public dayNo: number;
    public frequency: number;
}

export class SaveRotationData {
    public empSaveRecord: EmployeeScheduledData[];
    public empTermRecord: TerminateRequest[];
}

export class EmployeesGenerateRotation {
    public orgLevelId: number;
    public empIds: number[];
    public startDate: string;
}

export class AssignEmpToEmptySlot {
    public employeeId: number;
    public slotId: number;
}

export class FutureValidationShift {
    public employeeId: number;
    public shiftId: number;
    public frequencyId: number;
    public scheduleDate: Date;
    public shiftStartTime: Date;
    public shiftEndTime: Date;
    public empOTHours: number;
    public shiftHours: number;
    public slotId:number;
    public scheduleStartDate: string;
    public scheduleEndDate: string;
    public rotationsRequest: any[];
    public orgLevelId:number;
    public isAlreadyAssignedShift: boolean;
}

export class FutureValidationShiftResult {
    public id: number;
    public dayNo: number;
    public errorMessage: string;
    public overLappingDate: Date;
}

export class OverlapOverTimeMessage {
    public overLap: string;
    public overTime: string;
}

export interface IFilterRotationOptions {
    showStaff: IFilterData[];
    viewWeeks: IFilterData[];
    positionOptions: IPositionOptions;
    gridFilterOption: IFilterData;
}

export interface IPositionOptions {
    primaryPosition: IFilterData;
    secondaryPosition: ISecondaryPositionData;
    agencyStaff: IFilterData;
    emptyRotation: IFilterData;
}

export interface IFilterData {
    id: number;
    name: string;
    isSelected: boolean;
}

export interface ISecondaryPositionData {
    id: number;
    name: string;
    isSelectAll: boolean;
    options: IFilterData[];
}

export class FilterRotationOptions {
    public showStaff: FilterData[];
    public viewWeeks: FilterData[];
    public positionOptions: PositionOptions;
    public gridFilterOption: FilterData;
}

export class PositionOptions {
    public primaryPosition: FilterData;
    public secondaryPosition: SecondaryPositionData;
    public agencyStaff: FilterData;
    public emptyRotation: FilterData;
}

export class FilterData {
    public id: number;
    public name: string;
    public isSelected: boolean;
}

export class SecondaryPositionData {
    public id: number;
    public name: string;
    public isSelectAll: boolean;
    public options: FilterData[];
}