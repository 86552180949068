<kendo-grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" sortable="false" [sort]="gridState.state.sort"
  [filterable]="false" [filter]="gridState.state.filter" scrollable="none">

  <kendo-grid-column title="" [sortable]="true" field="description" media="sm" width="268" [headerClass]="{'kendo-hidden-header': true}" >
    <ng-template kendoGridHeaderTemplate>
      Description
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.description}}
    </ng-template>

  </kendo-grid-column>
  <ng-template ngFor let-role [ngForOf]="container?.roles">
    <kendo-grid-column title=""
      [sortable]="false"
      media="sm"
      [filterable]="false"
      width="100"
      class="overflow-visible-cell"
      [headerClass]="{'kendo-hidden-header': true}"
      [hidden]="!roleColumnsState.isVisible(role)"
    >
      <ng-template kendoGridHeaderTemplate>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngIf="!dataItem.linkedMenuId" class="right-changer" (click)="onAccessClick(dataItem, role.id)">
          <i class="far" [ngClass]="{
            'fa-check-square': dataItem.mapByRole[role.id].isEnabled,
            'fa-square': !dataItem.mapByRole[role.id].isEnabled,
             'dirty': dataItem.dirtyByRole[role.id]
            }"
            aria-hidden="true"></i>
        </div>
        <div *ngIf="dataItem.linkedMenuId" class="right-changer-disabled">
          <i class="far"  tipPosition="bottom" slxTooltip="Action is linked to Menu Item '{{dataItem.linkedMenuName}}'. This item cannot be independently edited."
          [ngClass]="{
            'fa-check-square': dataItem.mapByRole[role.id].isEnabled,
            'fa-square': !dataItem.mapByRole[role.id].isEnabled,
             'dirty': dataItem.dirtyByRole[role.id]
            }"
            aria-hidden="true"></i>
        </div>

      </ng-template>
    </kendo-grid-column>
  </ng-template>

</kendo-grid>
