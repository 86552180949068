import { Injectable, Type } from '@angular/core';
import {
  CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationExtras,
  CanActivateChild, Params
} from '@angular/router';
import { ComponentStateStorageService } from './component-state-storage.service';
import { ChangeManagementService } from '../change-management/change-management.service';
import { StateResetTypes, IRouteInfo, isRouteInfo, IRouteDetails } from '../../../core/models/index';

@Injectable()
export class ComponentStateGuard implements CanActivateChild {

  constructor(private componentStateStorageService: ComponentStateStorageService, private changeManagementService: ChangeManagementService) {
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isResetRoute = !!childRoute.queryParams['resetState'];
    if (isResetRoute) {
      this.componentStateStorageService.clearComponentStates(StateResetTypes.ParameterInRoute);
    }
    if (childRoute.data && isRouteInfo(childRoute.data)) {
      this.changeManagementService.setCurrentComponentId(childRoute.data.componentId);
    }
    return true;
  }

}
