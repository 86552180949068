import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { TimecardsNavigationService } from '../../services/index';

@Directive({
  selector: '[slxAddEmployeeLink]',
})
export class AddEmployeeLinkDirective {
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let params: any = {
      hideLeftSideBar: true
    };
    this.router.navigate(['add_employee'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
  }
}
