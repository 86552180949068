import { IOrganizationPayroll, OrganizationPayroll } from './organization-payroll';
export interface IOrganizationPayrollContainer {
    organizationPayrollList: IOrganizationPayroll[];
    canExportToPayroll: boolean;
    globalExportSettingsEnabled: boolean;
    settingsAvailable: boolean;
    canChangeConfig: boolean;
    canUnlockTimecards: boolean;
}

export class OrganizationPayrollContainer {
    public organizationPayrollList: OrganizationPayroll[];
    public canExportToPayroll: boolean;
    public globalExportSettingsEnabled: boolean;
    public settingsAvailable: boolean;
    public canChangeConfig: boolean;
    public canUnlockTimecards: boolean;
}
