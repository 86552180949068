<slx-spinner [show]="state.isLoading">

    <slx-agency-mappings #agencyMappingsComponent class="slx-full-height"
      *ngIf="!state.agencySelected"
      [orglevelId]="effectiveOrgLevelId"
      [isOrganization]="state.organizationSelected || state.departmentSelected"
      [organizations]="organizations"
      [agencyMappings]="agencyMappings"
      (onSaveAgency)="onSaveAgency ($event)"
      (onRemoveAgency)="onRemoveAgency($event)"
    ></slx-agency-mappings>

    <slx-agency-details
      *ngIf="stateManagement.isDepartment && state.agencySelected"
      [standaloneMode]="true"
      [orglevelId]="effectiveOrgLevelId"
      [agencyMapping]="agencyMapping"
      [organizations]="organizations"
    ></slx-agency-details>

</slx-spinner>
