import { Injectable, ElementRef, QueryList, Renderer2 } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { Subject } from 'rxjs/Subject';

import { ITimelineItem } from '../../models/index';
import { TimelineComponentConfigiration, TimelineComponentDrawConfigiration, TimelineHelper } from './timeline-helper';
import { TimelineExpandHelper } from './timeline-expand-helper';
import { TimelineFitHelper } from './timeline-fit-helper';


@Injectable()
export class TimelineService {
  public changeDetection: Subject<any>;
  public items: ITimelineItem[];
  public config: TimelineComponentConfigiration;

  public loaded: boolean = false;
  public viewInitialized: boolean;
  public helper: TimelineHelper;

  public get eventsWrapperWidth(): number {
    if (!this.helper) {
      return 0;
    }
    return this.helper.eventsWrapperWidth;
  }
  public get prevLinkInactive(): boolean {
    if (!this.helper) {
      return true;
    }
    return this.helper.prevLinkInactive;
  }

  public get nextLinkInactive(): boolean {
    if (!this.helper) {
      return true;
    }
    return this.helper.nextLinkInactive;
  }

  selectedIndex: number = 0;

  constructor() {
    this.changeDetection = new Subject();
  }

  public configireAfterViewInit(config: TimelineComponentConfigiration): void {
    this.config = config;
    this.viewInitialized = true;
  }

  public initItems(items: ITimelineItem[], rtConfig: TimelineComponentDrawConfigiration): void {
    this.items = items;
    if (!this.viewInitialized || !rtConfig.startDate || !rtConfig.endDate) {
      return;
    }
    this.detectChanges();
    if (this.items && this.items.length) {
      if (rtConfig.fitToWidth) {
        this.helper = new TimelineFitHelper(this.config);
      } else {
        this.helper = new TimelineExpandHelper(this.config);
      }
      this.helper.initTimeline(this.items, rtConfig);
      this.loaded = true;
    }
    this.detectChanges();
  }

  public updateFillingStartByDate(date: Date): void {
      if (!this.helper) {
      return;
    }
    this.helper.updateFillingStartByDate(date);
  }

  public updateFillingEndByDate(date: Date): void {
    if (!this.helper) {
    return;
  }
  this.helper.updateFillingEndByDate(date);
}

  public onScrollClick(event: Event, forward: boolean): void {
    this.helper.updateSlide(this.helper.eventsWrapperWidth, forward);
    this.detectChanges();
  }

  public onTimeChanged(date: Date): void {
    this.helper.updateFilling(date);
  }

  private detectChanges(): void {
    this.changeDetection.next();
  }
}
