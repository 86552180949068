import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { FieldsMeta } from '../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { ResponseBody } from '../../../core/models/api/response-body';

import { appConfig } from '../../../app.config';
import { AcaMeasurementMapService } from './aca-measurement-map.service';
import { configurationConfig } from '../../configuration.config';
import { AcaMeasurement, IAcaMeasurement } from '../../models/index';

@Injectable()
export class AcaMeasurementApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private mapService: AcaMeasurementMapService,
    private urlParamsService: UrlParamsService
  ) { }

  public async getAcaMeasurement(orgLevelId: number, year: number): Promise<AcaMeasurement> {
    const url: string = `${this.getAcaMeasurementRootApi()}/${configurationConfig.api.ACA.measurement.root}/details/${orgLevelId}/all`;

    const request = this.urlParamsService.createGetRequest(url, {
      year: year
    });
   
    return this.apiUtilService
      .request<IAcaMeasurement, FieldsMeta>(request)
      .then((response: ResponseBody<IAcaMeasurement, FieldsMeta>) => this.mapService.mapAcaMeasurement(response.data, response.meta, year));
  }

  private getAcaMeasurementRootApi(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
