<div>
  <div *ngIf="isTodayShow" class="today-div" [style.left.%]="todayBarWidth-2">
    <div class="today">TODAY</div>
  </div>
  <div *ngIf="isTodayBarShow" class="today-bar" [style.left.%]="todayBarWidth"></div>
  <div *ngIf="!isExpand">
    <div class="progress-main">
      <div *ngFor="let item of periodTypes;" class="progress-section">
        <div class="per-section">
          <div *ngFor="let pItem of item.acaPeriod;" [title]="pItem.periodName" class="bars" [ngStyle]="{'background-color': pItem.colorCode}" [style.height.px]="10" [style.width.%]="pItem.widthPercentage"></div>
        </div>
      </div>
    </div>
  </div>
  <div id="barSection" *ngIf="isExpand" class="progress-main">
    <div *ngFor="let item of periodTypes;let i = index" class="progress-det-section">
      <div class="per-section">
        <div class="chevron chevron-prev" *ngIf="item.isPrevYearExist">
          <em class="fal fa-chevron-double-left"></em>
        </div>
        <div *ngFor="let pItem of item.acaPeriod;let p = index">
          <button *ngIf="pItem.periodSortName === 'MP' && pItem.widthPercentage > 0" class="bars msp" type="button"
                  [ngStyle]="{'background-color': '#00719E'}"
                  [style.height.px]="30" [style.width.%]="pItem.widthPercentage"
                  (mouseenter)="togglePopper(pItem, true, $event)" (mouseleave)="togglePopper(pItem, false, $event)"
                  (mousemove)="togglePopper(pItem, true, $event)">
            <div class="progressDiv" [style.left.%]="pItem.mspWidthPercentage" [ngStyle]="{'background-color': pItem.colorCode}"></div>
            <span class="progressbar-text">
              <span *ngIf="pItem.widthPercentage < 9">{{pItem.periodSortName}}</span>
              <span *ngIf="pItem.widthPercentage >= 9">{{pItem.periodName}}</span>
            </span>
          </button>
          <button *ngIf="(pItem.periodSortName == 'AP' || pItem.periodSortName == 'SP') && pItem.widthPercentage > 0" class="bars" type="button"
                  [ngStyle]="{'background-color': pItem.colorCode}"
                  [style.height.px]="30" [style.width.%]="pItem.widthPercentage"
                  (mouseenter)="togglePopper(pItem, true, $event)" (mouseleave)="togglePopper(pItem, false, $event)"
                  (mousemove)="togglePopper(pItem, true, $event)">
            <span class="progressbar-text">
              <span *ngIf="pItem.widthPercentage < 9">{{pItem.periodSortName}}</span>
              <span *ngIf="pItem.widthPercentage >= 9">{{pItem.periodName}}</span>
            </span>
          </button>
          <button *ngIf="pItem.periodSortName == '' && pItem.widthPercentage > 0" class="bars" type="button"
                  [ngStyle]="{'background-color': pItem.colorCode}"
                  [style.height.px]="30" [style.width.%]="pItem.widthPercentage">
            <span class="progressbar-text">
              <span *ngIf="pItem.widthPercentage < 9">{{pItem.periodSortName}}</span>
              <span *ngIf="pItem.widthPercentage >= 9">{{pItem.periodName}}</span>
            </span>
          </button>
        </div>
        <div class="chevron chevron-next" *ngIf="item.isNextYearExist">
          <em class="fal fa-chevron-double-right"></em>
        </div>
      </div>
    </div>
  </div>
</div>
