import { ITimeclockDailySummary, TimeclockDailySummary } from './time-clock-daily-summary';

export interface ITimeclockDailySummaryContainer {
  orgLevelId: number;
  orgLevelName: string;
  records: ITimeclockDailySummary[];
}

export class TimeclockDailySummaryContainer {
  public orgLevelId: number;
  public orgLevelName: string;
  public records: TimeclockDailySummary[];
}

export class RebootClockContainer{
  public  Id :number;
  public DeviceId :string;
  public RealpostId :number
  public Status :string;
  public StatusDetails :string;
  public RequestedUserId :string;
  public RequestedOn :Date;
  public ProcessedOn :Date;
}

export interface IRebootClockContainer{
  Id :number;
  DeviceId :string;
  RealpostId :number
  Status :string;
  StatusDetails :string;
  RequestedUserId :string;
  RequestedOn :Date;
  ProcessedOn :Date;
}

export interface IRebootStatus{
  Id :number;
  DeviceId :number;
  isSuccess :boolean;
  ErrorCode : number;
  ErrorMessage :string;
}

export class IRebootStatus{
  public Id :number;
  public DeviceId :number;
  public isSuccess :boolean;
  public ErrorCode : number;
  public ErrorMessage :string;
}
