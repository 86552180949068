<div class="width100 flex-container">

  <div class="container-item flex-container attendance-toolbar">

    <slx-actions-list>
      <slx-actions-button [popperContent]="popperContent"
        [popperPosition]="'bottom-start'"
        (popperOnShow)="onShowMenu($event)"
        (popperOnHide)="onHideMenu($event)">
        <span class="icon-button-content">
          <span>{{selectedYear}}</span>
          <span class="caret"></span>
        </span>
      </slx-actions-button>
      <popper-content #popperContent>
        <slx-actions-list-item *ngFor="let year of attendanceYears"
          [className]="selectedYear === year ? 'active' : ''"
          (onClick)="onSelectYear(year)">{{year}}</slx-actions-list-item>
      </popper-content>
    </slx-actions-list>

    <div>
      <slx-checkbox-toggle className="toggle-left"
        [(ngModel)]="showActual"
        (ngModelChange)="onActualChanged()"
        caption="Show Actual"
        checkedText="Yes"
        uncheckedText="No"></slx-checkbox-toggle>
    </div>

    <div class="spacer"></div>

    <div>
      <button class="add-ap-button theme-iconed-accent-button" (click)="onPrintClick()">
        Print
        <i class="fas fa-print"></i>
      </button>
    </div>

  </div>

  <div class="container-item attendance-calendar">
    <div class="left-panel">
      <slx-employee-sections-attendance-stats-grid [gridView]="getStats(absencePrefix)"
        [showActual]="absencePrefix==='actual'">
      </slx-employee-sections-attendance-stats-grid>
    </div>
    <div class="right-panel">
      <div class="caption">
      </div>
      <div class="day-names-container">
        <div style="display:inline-block; padding-left: 6px; width: 20px; font-size: 10px"
        *ngFor="let day of days">
        <div class="dayboxCaption">{{day}}</div>
      </div>
    </div>
      <div *ngFor="let month of monthNames" class="month-container">
        <div class="monthcaption">{{month}}</div>
        <div style="display:inline-block; padding-left: 5px"
          *ngFor="let day of days">
          <slx-employee-sections-attendance-day [attendanceSection]="attendanceSection"
            [selectedYear]="selectedYear"
            [month]="month"
            [day]="day"
            [prefix]="absencePrefix">
          </slx-employee-sections-attendance-day>

        </div>
      </div>
    </div>
  </div>
  <div class="container-item attendance-summary">
    <div *ngIf="showActual">
      <slx-input-decorator>
        <input slx-input type="text"
              [readonly]="true" name="stat1" placeholder="Most Frequently Absent on" [ngModel]="statFreq">
      </slx-input-decorator>

      <slx-input-decorator>
        <input slx-input type="text"
              [readonly]="true" name="stat2" placeholder="Adjacent to Holiday" [ngModel]="statAdjacent">
      </slx-input-decorator>
  </div>
  </div>
  <div class="container-item attendance-absences">
    <slx-employee-sections-attendance-absences-grid 
    [attendanceSection]="attendanceSection"
    [prefix]="absencePrefix"
    >
  </slx-employee-sections-attendance-absences-grid>
  </div>
</div>