import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../../../app.config';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { EmployeeCallLogManagementService } from '../../../services/employee-call-log/employee-call-log-management.service';
var EmployeeCallLogGridComponent = /** @class */ (function () {
    function EmployeeCallLogGridComponent(managementService) {
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'sentTimeFC', dir: 'desc' }];
        this.gridState.state.group = [];
        this.managementService = managementService;
        this.pageSize = 20;
        this.appConfig = appConfig;
    }
    EmployeeCallLogGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (records) {
            _this.generateFilters(records.records);
            _this.records = records;
            _this.gridState.state.skip = 0;
            _this.refreshGrid();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.resetGridStateubscription = this.managementService.resetGridState$.subscribe(function (v) {
            _this.gridState.state.skip = 0;
            _this.gridState.state.sort = [{ field: 'sentTimeFC', dir: 'desc' }];
            _this.gridState.state.group = [];
        });
        this.messageCenterEnabledSubscription = this.managementService.messageCenterEnabled$.subscribe(function (isMessageCenterEnabled) {
            _this.isMessageCenterEnabled = isMessageCenterEnabled;
        });
    };
    EmployeeCallLogGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeCallLogGridComponent.prototype.generateFilters = function (records) {
        this.typeFilters = this.generateFilter(records, 'type');
        this.errorFilters = this.generateFilter(records, 'error');
    };
    EmployeeCallLogGridComponent.prototype.generateFilter = function (records, field) {
        var _this = this;
        var temp = _.uniqBy(records, field);
        temp = _.filter(temp, function (item) { return item[field] !== null; });
        return _.map(temp, function (item) { return _this.generateFilterItem(item[field]); });
    };
    EmployeeCallLogGridComponent.prototype.generateFilterItem = function (value) {
        return {
            text: value,
            value: value
        };
    };
    EmployeeCallLogGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.take = this.pageSize;
        this.gridState.view = { data: null, total: null };
        this.gridState.view = process(this.records.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogGridComponent.prototype, "resetGridStateubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeCallLogGridComponent.prototype, "messageCenterEnabledSubscription", void 0);
    return EmployeeCallLogGridComponent;
}());
export { EmployeeCallLogGridComponent };
