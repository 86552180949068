/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-daily-differences.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../schedule-daily-differences-grid/schedule-daily-differences-grid.component.ngfactory";
import * as i5 from "../schedule-daily-differences-grid/schedule-daily-differences-grid.component";
import * as i6 from "./schedule-daily-differences.component";
import * as i7 from "../../../services/schedule/schedule-daily-details-api.service";
var styles_ScheduleDailyDifferencesComponent = [i0.styles];
var RenderType_ScheduleDailyDifferencesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleDailyDifferencesComponent, data: {} });
export { RenderType_ScheduleDailyDifferencesComponent as RenderType_ScheduleDailyDifferencesComponent };
export function View_ScheduleDailyDifferencesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-high-box__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-schedule-daily-differences-grid", [], null, null, null, i4.View_ScheduleDailyDifferencesGridComponent_0, i4.RenderType_ScheduleDailyDifferencesGridComponent)), i1.ɵdid(5, 245760, null, 0, i5.ScheduleDailyDifferencesGridComponent, [i1.ChangeDetectorRef], { records: [0, "records"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.records; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ScheduleDailyDifferencesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-daily-differences", [], null, null, null, View_ScheduleDailyDifferencesComponent_0, RenderType_ScheduleDailyDifferencesComponent)), i1.ɵdid(1, 573440, null, 0, i6.ScheduleDailyDifferencesComponent, [i7.ScheduleDailyDetailsApiService], null, null)], null, null); }
var ScheduleDailyDifferencesComponentNgFactory = i1.ɵccf("slx-schedule-daily-differences", i6.ScheduleDailyDifferencesComponent, View_ScheduleDailyDifferencesComponent_Host_0, { req: "req" }, {}, []);
export { ScheduleDailyDifferencesComponentNgFactory as ScheduleDailyDifferencesComponentNgFactory };
