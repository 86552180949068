<div class="slx-high-box" class="attestation-div">
  <b>Punch Attestation</b>
  <hr style="border-top: 1px solid #d5cdcd;" />
  <div class="slx-benefit-box__control flex align-items__center attestation-div-titles">
    <b class="slx-benefit-box__label">Enable Punch Attestation </b>
    <b class="slx-benefit-box__value flex__grow">
      <slx-checkbox-button class="dates-tab__checkbox" [(ngModel)]="isEnabled" (change)="checkboxChecked()" name="enabled">
      </slx-checkbox-button>
    </b>
  </div>
  <div class="slx-benefit-box__control flex align-items__center attestation-div-titles">
    <b class="slx-benefit-box__label">Punch Attestation Groups : </b>
    <b class="slx-benefit-box__value flex__grow">
      <slx-dropdown-list class="slx-wide" [options]="groups" [(ngModel)]="group" valueField="id" [readonly]="!isEnabled"
        titleField="groupName"></slx-dropdown-list>
    </b>
  </div>
  <div class="slx-benefit-box__control flex attestation-div-titles" style="display:contents;">
    <button class="slx-button slx-with-icon save-btn" (click)="savePunchProfileGroup()"
      [disabled]="!authorized || disableSaveButton">
      <i class="slx-button__icon fas fa-save"></i> Save
      <span *ngIf="!authorized"
      slxTooltip="You are not an authorized user to save punch profile" 
      tipPosition="bottom"></span>
    </button>
  <a class="hreflink" (click)="viewQuestions()" style="float:right;">Preview Question Groups</a>
  </div>
</div>