import * as _ from 'lodash';
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ExportDataManagementService } from '../../services/export-data-management.service';
import { ExportDataConfigurationInfo } from '../../models';
import { Subscription } from 'rxjs';
import { ExportDataStatus } from '../../enums/export-data-status';
import { StateManagementService } from '../../../../../app/common';
import { unsubscribeAll } from '../../../../core/decorators';
import { ExportDataEventService } from '../../../../common/services/export-data/export-data-event.service';

@Component({
  selector: 'slx-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss'],
  providers: [StateManagementService, ExportDataEventService, ExportDataManagementService]
})
export class ExportDataComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  public exportConfigurations: ExportDataConfigurationInfo[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public get exportDataStatus() { return ExportDataStatus; }

  constructor(private stateManagement: StateManagementService,
              private manService: ExportDataManagementService,
              private changeDetector: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.stateManagement.init('ExportDataComponent');

    this.subscriptions.loading = this.manService.subscribeToLoading((data: boolean) => {
      this.isLoading = data;
    });

    this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel(() => {
      this.exportConfigurations = [];
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();

      this.manService.loadExportDataConfiguration();
    });

    this.subscriptions.loadedConfigurtion = this.manService.subscribeToLoadedConfigurations((data: ExportDataConfigurationInfo[]) => {
      this.exportConfigurations = data;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.manService.init();
  }

  ngOnDestroy() {
    this.exportConfigurations = null;
    this.changeDetector.detach();
  }
}
