import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params, Resolve
} from '@angular/router';

import { appConfig } from '../../../../app.config';
import { pbjConfig } from '../../pbj.config';
import { DateRange } from '../../../../core/models/index';
import { BaseResolver, ComponentStateStorageService, StateManagementService } from '../../../../common/index';
import { dateTimeUtils } from '../../../../common/utils/dateTimeUtils';

@Injectable()
export class PbjReconciliationResolver extends BaseResolver<DateRange> implements Resolve<DateRange> {
  public componentId: string = 'PbjReconciliationComponent';
  private reportFrom: string = 'StartDate';
  private reportTo: string = 'EndDate';

  private maxDaysRange: number;
  constructor(router: Router,
    private storageService: ComponentStateStorageService,
    private stateManagement: StateManagementService
    ) {
    super(router);
    this.maxDaysRange = pbjConfig.settings.reconciliation.maxDaysRange;
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): DateRange {
    const startDate = route.queryParamMap.get('startDate');
    const endDate = route.queryParamMap.get('endDate');
    const orgLevelId = route.queryParamMap.get('orgLevelId');
    let range: DateRange = null;

    if (startDate && endDate) {
      const sDate = moment(startDate, appConfig.linkDateFormat).toDate();
      const eDate = moment(endDate, appConfig.linkDateFormat).toDate();
      range = new DateRange(sDate, eDate);
    } else {
      const dates = this.getRangeFromQuery();
      if (_.size(dates.s) > 0 && _.size(dates.e) > 0) {
        const sDate = moment(dates.s, appConfig.linkDateFormat).toDate();
        const eDate = moment(dates.e, appConfig.linkDateFormat).toDate();
        range = new DateRange(sDate, eDate);
      } else {
        range = this.restoreDates();
      }

      const params: Params = this.createQueryParams(route, state);
      if (_.size(orgLevelId) > 0) {
        params.orgLevelId = orgLevelId;
      }
      params.startDate = moment(range.startDate).format(appConfig.linkDateFormat);
      params.endDate = moment(range.endDate).format(appConfig.linkDateFormat);
      this.navigateToPath(route, params);
    }

    return range;
  }

  private getRangeFromQuery(): { s: string, e: string } {
    const urls = window.location.hash.split('?');
    let sDate = '';
    let eDate = '';
    if (urls.length > 0) {
      const queryUrl = _.last(urls);
      const queryParams = queryUrl.split('&');
      _.forEach(queryParams, (param: string) => {
        const [key, value] = param.split('=');
        if (key === 'startDate' && _.size(value) > 0) {
          sDate = value;
        }
        if (key === 'endDate' && _.size(value) > 0) {
          eDate = value;
        }
      });
    }
    return { s: sDate, e: eDate };
  }

  private getInitRange(): DateRange {
    const halfPeriod: number = this.maxDaysRange / 2;
    const prevDays: number = Math.round(halfPeriod) === halfPeriod ? halfPeriod : Math.floor(halfPeriod);
    const nextDays: number = Math.round(halfPeriod) === halfPeriod ? halfPeriod - 1 : Math.floor(halfPeriod);
    const today: moment.Moment = moment();
    const start: moment.Moment = today.clone().subtract(prevDays, 'days');
    const end: moment.Moment = today.clone().add(nextDays, 'days');

    return new DateRange(start.toDate(), end.toDate());
  }

  public restoreDates(): DateRange {
    var today1 = new Date();
    var quarter = Math.floor((today1.getMonth() / 3));
    var startDate = new Date(new Date().getFullYear(), quarter * 3 - 3, 1);
    var endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 0);

    this.stateManagement.init(this.componentId, true);

    const state = this.storageService.getControlState(this.componentId, this.reportFrom);
    const value = state && state.value && _.isDate(new Date(state.value)) && moment(new Date(state.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state.value) : startDate;

    const state1 = this.storageService.getControlState(this.componentId, this.reportTo);
    const value1 = state1 && state1.value && _.isDate(new Date(state1.value)) && moment(new Date(state1.value), appConfig.linkDateFormat).toDate() > moment('1/1/1970', appConfig.linkDateFormat).toDate() ? new Date(state1.value) : endDate;

    return new DateRange(value, value1);
  }
}
