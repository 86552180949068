import { Assert } from '../../../framework/index';
import * as moment from 'moment';
import * as _ from 'lodash';

import { EmployeeSectionBridgeService } from './employee-section-bridge.service';
import { EmployeeSectionApiService } from './employee-section-api.service';
import { DateTimeService } from '../../../common/services/date-time/date-time.service';
import { EmployeeSectionsContext, EmployeeBannerInfo } from '../../employee/models/index';

import {
  EmployeeSection,
  EmployeeSubSection,
  employeeSectionConfig,
  EmployeeSectionConfig,
  EmployeeSectionsPersonal,
  EmployeeSectionsEmployment,
  EmployeeSectionsCustom,
  EmployeeSectionsAudit,
  EmployeeSectionsPerformance,
  EmployeeSectionsSchedule,
  EmployeeSectionsBenefitsManagement,
  EmployeeSectionsProfile,
  EmployeeSectionsAccruals,
  EmployeeSectionsEnrollmentAttachments,
  EmployeeWorkplace
} from '../models/index';
import { EmployeeShortInfo } from '../../../organization/models/index';

export class EmployeeSectionManagementService {
  public set employeeShortInfo(value: EmployeeShortInfo) {
    this.m_employeeShortInfo = value;
    if (this.personalSection && this.personalSection.profile) {
      this.setEmployeeBannerInfo(this.personalSection.profile);
    }
  }

  public get employeeShortInfo(): EmployeeShortInfo {
    return this.m_employeeShortInfo;
  }

  public personalSection: EmployeeSectionsPersonal;
  public employmentSection: EmployeeSectionsEmployment;
  public customSection: EmployeeSectionsCustom;
  public auditSection: EmployeeSectionsAudit;
  public performanceSection: EmployeeSectionsPerformance;
  public scheduleSection: EmployeeSectionsSchedule;
  public benefitManagementSection: EmployeeSectionsBenefitsManagement;
  public accrualsSection: EmployeeSectionsAccruals;
  public accrualsStartDate: Date;
  public accrualsEndDate: Date;
  public attendancePointsStartDate: Date;
  public attendancePointsEndDate: Date;
  public attendanceStartDate: Date;
  public attendanceEndDate: Date;
  public effectiveDate: Date;

  public isLoadingPersonal: boolean;
  public isLoadingEmployment: boolean;
  public isLoadingCustom: boolean;
  public isLoadingBenefitsManagement: boolean;
  public isLoadingAudit: boolean;
  public isLoadingPerformance: boolean;
  public isLoadingSchedule: boolean;
  public isLoadingAccruals: boolean;
  public employeeBannerInfo: EmployeeBannerInfo;

  private subsectionsMap: StringMap<EmployeeSubSection>;
  private sectionsMap: StringMap<EmployeeSection>;
  private sectionsTosubsectionsMap: StringMap<EmployeeSection>;

  private employeeSectionBridgeService: EmployeeSectionBridgeService;
  private employeeSectionApiService: EmployeeSectionApiService;
  private dateTimeService: DateTimeService;
  private employeeSectionsContext: EmployeeSectionsContext;
  private loadedSectionsMap: StringMap<EmployeeSection>;
  private empName: string;
  private m_employeeShortInfo: EmployeeShortInfo;


  constructor(employeeSectionsContext: EmployeeSectionsContext, employeeSectionBridgeService: EmployeeSectionBridgeService, employeeSectionApiService: EmployeeSectionApiService, dateTimeService: DateTimeService) {
    this.employeeSectionBridgeService = employeeSectionBridgeService;
    this.employeeSectionsContext = employeeSectionsContext;
    this.dateTimeService = dateTimeService;
    this.employeeSectionApiService = employeeSectionApiService;
    this.loadedSectionsMap = {};
  }

  public getSectionStateBySubsectionId(id: string): boolean {
    let section: EmployeeSection = this.getSectionBySubsectionId(id);
    if (!section) { return false; }
    if (section.id === 'Personal') { return this.isLoadingPersonal; }
    if (section.id === 'Employment') { return this.isLoadingEmployment; }
    if (section.id === 'Custom') { return this.isLoadingCustom; }
    if (section.id === 'Audit') { return this.isLoadingAudit; }
    if (section.id === 'Performance') { return this.isLoadingPerformance; }
    if (section.id === 'Schedule') { return this.isLoadingSchedule; }
    if (section.id === 'Accruals') { return this.isLoadingAccruals; }
    if (section.id === 'BenefitsManagement') { return this.isLoadingBenefitsManagement; }

    return false;
  }

  public getSectionById(id: string): EmployeeSection {
    if (!this.sectionsMap) {
      return null;
    }
    return this.sectionsMap[id];
  }

  public getSubSectionById(id: string): EmployeeSubSection {
    if (!this.subsectionsMap) {
      return null;
    }
    return this.subsectionsMap[id];
  }

  public getSectionBySubsectionId(id: string): EmployeeSection {
    if (!this.sectionsTosubsectionsMap) {
      return null;
    }
    return this.sectionsTosubsectionsMap[id];
  }

  public getDefaultSection(): EmployeeSection {
    return this.getSectionBySubsectionId('Profile');
  }
  public getDefaultSubSection(): EmployeeSubSection {
    return this.getSubSectionById('Profile');
  }
  public getEmployeeName(): string {
    return this.empName;
  }

  public createSectionsMapping(sections: EmployeeSection[]): void {
    this.subsectionsMap = {};
    this.sectionsMap = {};
    this.sectionsTosubsectionsMap = {};
    _.forEach(sections, (section: EmployeeSection) => {
      this.sectionsMap[section.id] = section;
      _.forEach(section.subsections, (subsection: EmployeeSubSection) => {
        this.subsectionsMap[subsection.id] = subsection;
        this.sectionsTosubsectionsMap[subsection.id] = section;
      });
    });
  }
  public setEmployeeBannerInfo(profile: EmployeeSectionsProfile): void {
    this.employeeBannerInfo = new EmployeeBannerInfo();

    this.employeeBannerInfo.empoyeeId = this.employeeSectionsContext.employeeId;
    this.employeeBannerInfo.firstName = profile.firstName.fieldValue;
    this.employeeBannerInfo.lastName = profile.lastName.fieldValue;
    this.employeeBannerInfo.middleName = profile.middleName.fieldValue;
    this.employeeBannerInfo.fullName = profile.fullName.fieldValue;
    this.employeeBannerInfo.suffix = profile.suffix.fieldValue;
    this.employeeBannerInfo.employeeType = profile.employeeType.fieldValue;
    this.employeeBannerInfo.position = profile.position.fieldValue;
    this.employeeBannerInfo.status = profile.status;
    this.employeeBannerInfo.isTerminated = profile.isTerminated;
    this.employeeBannerInfo.isTransferred = profile.isTransferred;
    this.employeeBannerInfo.isFutureRehire = profile.isFutureRehire;
    this.employeeBannerInfo.isTransferPending = profile.isTransferPending; 
    this.employeeBannerInfo.isLatestTerminate = profile.isLatestTerminate;
    this.employeeBannerInfo.canTerminate = profile.canTerminate;
    this.employeeBannerInfo.canCancelTerminate = profile.canCancelTerminate;
    this.employeeBannerInfo.canTransfer = profile.canTransfer;
    this.employeeBannerInfo.canRehire = profile.canRehire;
    this.employeeBannerInfo.isRehired = profile.isRehired;
    this.employeeBannerInfo.rehireDate = profile.rehireDate;
    this.employeeBannerInfo.createdThroughTransfer = profile.createdThroughTransfer;
    this.employeeBannerInfo.isPayrollVisible = profile.isPayrollVisible;

    if (this.employeeShortInfo) {
      this.employeeBannerInfo.isAgency = this.employeeShortInfo.isAgency;
      this.employeeBannerInfo.payrollNumber = this.employeeShortInfo.payrollNumber;
      //this.employeeBannerInfo.bannerInfoLicense = this.employeeShortInfo.bannerInfoLicense;
    }
  }

  public loadSection(section: EmployeeSection): Promise<boolean> {
    Assert.isNotNull(section, 'section');
    const promise: { loadingState?: Promise<boolean>, resolve?: (value: boolean) => void, reject?: (reason: any) => void } = {};
    promise.loadingState = new Promise((res, rej) => (promise.resolve = res, promise.reject = rej));

    let loaded: EmployeeSection = this.loadedSectionsMap[section.id];
    if (!loaded) {
      this.loadedSectionsMap[section.id] = section;
      switch (section.id) {
        case 'Personal':
          this.isLoadingPersonal = true;
          this.employeeSectionBridgeService.getPersonalSection(this.employeeSectionsContext.employeeId)
            .then((personalSection: EmployeeSectionsPersonal) => {
              this.personalSection = personalSection;
              this.isLoadingPersonal = false;
              if (personalSection.profile) {
                this.empName = personalSection.profile.fullName.fieldValue;
                this.setEmployeeBannerInfo(personalSection.profile);
                return Promise.resolve(this.employeeBannerInfo);
              }
              return Promise.resolve(this.employeeBannerInfo);
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingPersonal = false;
              promise.reject(reason);
              throw new Error(reason);
            });

          break;
        case 'Employment':
          this.isLoadingEmployment = true;
          this.employeeSectionBridgeService.getEmploymentSection(this.employeeSectionsContext.employeeId)
            .then((employmentSection: EmployeeSectionsEmployment) => {
              this.employmentSection = employmentSection;
              this.isLoadingEmployment = false;
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingEmployment = false;
              promise.reject(reason);
              throw new Error(reason);
            });
          break;
        case 'Custom':
          this.isLoadingCustom = true;
          this.employeeSectionBridgeService.getCustomSection(this.employeeSectionsContext.employeeId)
            .then((customSection: EmployeeSectionsCustom) => {
              this.customSection = customSection;
              this.isLoadingCustom = false;
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingCustom = false;
              promise.reject(reason);
              throw new Error(reason);
            });
          break;
        case 'Audit':
          this.isLoadingAudit = true;
          this.employeeSectionBridgeService.getAuditSection(this.employeeSectionsContext.employeeId)
            .then((auditSection: EmployeeSectionsAudit) => {
              this.auditSection = auditSection;
              this.isLoadingAudit = false;
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingAudit = false;
              promise.reject(reason);
              throw new Error(reason);
            });
          break;
        case 'Performance':
          this.isLoadingPerformance = true;
          this.attendancePointsStartDate = moment().subtract(1, 'year').startOf('day').toDate();
          this.attendancePointsEndDate = moment().endOf('day').toDate();
          this.attendanceStartDate = moment().startOf('year').toDate();
          this.attendanceEndDate = moment().endOf('day').toDate();
          //let attendancePointsDaysCount: number = this.dateTimeService.GetDiffDays(this.attendanceStartDate, this.attendanceEndDate);
          this.employeeSectionBridgeService.getPerformanceSection(this.employeeSectionsContext.employeeId, this.attendancePointsStartDate, this.attendancePointsEndDate, this.attendanceStartDate, this.attendanceEndDate)
            .then((performanceSection: EmployeeSectionsPerformance) => {
              this.performanceSection = performanceSection;
              this.isLoadingPerformance = false;
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingPerformance = false;
              promise.reject(reason);
              throw new Error(reason);
            });
          break;
        case 'Schedule':
          this.isLoadingSchedule = true;
          this.employeeSectionBridgeService.getScheduleSection(this.employeeSectionsContext.employeeId)
            .then((scheduleSection: EmployeeSectionsSchedule) => {
              this.scheduleSection = scheduleSection;
              this.isLoadingSchedule = false;
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingSchedule = false;
              promise.reject(reason);
              throw new Error(reason);
            });
          break;
        case 'BenefitsManagement':
          this.isLoadingBenefitsManagement = true;
          this.effectiveDate = moment().toDate();
          this.employeeSectionBridgeService.getBenefitsManagementSection(this.employeeSectionsContext.employeeId, this.effectiveDate)
            .then((benefitsSection: EmployeeSectionsBenefitsManagement) => {
              this.benefitManagementSection = benefitsSection;
              this.isLoadingBenefitsManagement = false;
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingBenefitsManagement = false;
              promise.reject(reason);
              throw new Error(reason);
            });
          break;
        case 'Accruals':
          this.isLoadingAccruals = true;
          const accrualsEndDate: moment.Moment = moment().endOf('day');
          const accrualsStartDate: moment.Moment = moment().endOf('day');
          this.accrualsEndDate = accrualsEndDate.add(10, 'day').startOf('day').toDate();
          this.accrualsStartDate = accrualsStartDate.subtract(90, 'day').startOf('day').toDate();
          this.employeeSectionBridgeService.getAccrualsSection(this.employeeSectionsContext.employeeId, this.accrualsStartDate, this.accrualsEndDate)
            .then((accrualsSection: EmployeeSectionsAccruals) => {
              this.accrualsSection = accrualsSection;
              this.isLoadingAccruals = false;
            })
            .then(() => promise.resolve(true))
            .catch((reason: any) => {
              this.isLoadingAccruals = false;
              promise.reject(reason);
              throw new Error(reason);
            });
          break;
        default:
      }
    } else {
      promise.resolve(false);
    }

    return promise.loadingState;
  }

  public loadWorkplace(): Promise<EmployeeWorkplace> {
    return this.employeeSectionApiService.getWorkplace();
  }
}
