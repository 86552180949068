import * as moment from 'moment';
import * as _ from 'lodash';
import { AuthError } from '../../../core/models/index';
import { appConfig } from '../../../app.config';
import { appMessages, NotificationMessage } from '../../../app.messages';
import { StatusCodes } from '../../../core/models/api/status-codes';
import { ValidationError, HandledError } from '../../../core/models/index';
import { CacheUtilService } from '../../../common/services/cache/cache.service';
import { CacheType } from '../../models/cache/cache-definition';
import { AuthApiService } from '../../../core/services/auth-api/auth-api.service';
import { ErrorHandlingService } from '../../../core/services/error-handling/error-handling.service';
var ApiUtilService = /** @class */ (function () {
    function ApiUtilService(authApiService, errorHandlingService, cacheUtilService) {
        this.authApiService = authApiService;
        this.errorHandlingService = errorHandlingService;
        this.cacheUtilService = cacheUtilService;
    }
    ApiUtilService.prototype.cachedRequest = function (request, cacheType, updateCacheForced, suppress) {
        if (cacheType === void 0) { cacheType = CacheType.default; }
        if (updateCacheForced === void 0) { updateCacheForced = false; }
        if (suppress === void 0) { suppress = false; }
        var url = this.getReducedUrl(request.urlWithParams);
        var cachedResponse = updateCacheForced ? undefined : this.cacheUtilService.get({ key: url });
        if (!_.isNull(cachedResponse) && !_.isUndefined(cachedResponse)) {
            return cachedResponse;
        }
        var config = this.getCacheConfig(cacheType);
        var response = this.request(request, suppress);
        this.cacheUtilService.put({ key: url }, response, config.expires);
        return response;
    };
    ApiUtilService.prototype.requestNew = function (request, suppress) {
        var _this = this;
        if (suppress === void 0) { suppress = false; }
        return this.authApiService.requestNew(request)
            .catch(function (error) {
            _this.handleError(error, suppress);
        });
    };
    ApiUtilService.prototype.request = function (request, suppress) {
        var _this = this;
        if (suppress === void 0) { suppress = false; }
        return this.authApiService.request(request)
            .catch(function (error) {
            _this.handleError(error, suppress);
        });
    };
    ApiUtilService.prototype.requestUnauthorized = function (request, alias, suppress) {
        var _this = this;
        if (suppress === void 0) { suppress = false; }
        return this.authApiService.requestUnauthorized(request, alias)
            .catch(function (error) {
            _this.handleError(error, suppress);
        });
    };
    ApiUtilService.prototype.requestForFile = function (request, suppress) {
        var _this = this;
        if (suppress === void 0) { suppress = false; }
        return this.authApiService.requestForFile(request)
            .catch(function (error) {
            _this.handleError(error, suppress);
        });
    };
    ApiUtilService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    ApiUtilService.prototype.getCommunicationApiRoot = function () {
        return "" + appConfig.api.communicationsApiUrl;
    };
    ApiUtilService.prototype.getSignalRApiRoot = function () {
        return "" + appConfig.api.communicationsSignalRUrl;
    };
    ApiUtilService.prototype.getCommunicationApiRootVersion = function () {
        return "" + appConfig.api.communicationsApiVersion;
    };
    ApiUtilService.prototype.apiRoot = function (apiTemplate) {
        var substitutions = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            substitutions[_i - 1] = arguments[_i];
        }
        var result = apiTemplate[0];
        substitutions.forEach(function (substitution, idx) {
            if (substitution instanceof Date || substitution instanceof moment) {
                result += moment(substitution).format(appConfig.requestDate);
            }
            else {
                result += String(substitution);
            }
            result += apiTemplate[idx + 1];
        });
        return this.getApiRoot() + "/" + result;
    };
    ApiUtilService.prototype.handleError = function (error, suppress) {
        if (suppress === void 0) { suppress = false; }
        if (error instanceof AuthError) {
            throw error;
        }
        if (error && error.status === StatusCodes.conflict) {
            this.errorHandlingService.errorWithoutLog(new NotificationMessage('Validation error', error.meta.error), error, suppress);
            throw new ValidationError(error);
        }
        if (error && (error.data || error.meta)) {
            this.errorHandlingService.errorWithoutLog(appMessages.error.requestFailed, error, suppress);
        }
        else {
            this.errorHandlingService.error(appMessages.error.requestFailed, error, suppress);
        }
        throw new HandledError(error);
    };
    ApiUtilService.prototype.getReducedUrl = function (url) {
        var apiUrl = appConfig.api.url;
        var reducedUrl = url;
        if (_.startsWith(url, apiUrl)) {
            reducedUrl = url.slice(apiUrl.length);
        }
        return reducedUrl;
    };
    ApiUtilService.prototype.getCacheConfig = function (cacheType) {
        var config;
        switch (cacheType) {
            case CacheType.default:
                config = appConfig.cacheConfig.default;
                break;
            case CacheType.shortTerm:
                config = appConfig.cacheConfig.shortTerm;
                break;
            case CacheType.longTerm:
                config = appConfig.cacheConfig.longTerm;
                break;
            default:
                throw new Error("CacheType unknown cache: " + cacheType);
        }
        return config;
    };
    return ApiUtilService;
}());
export { ApiUtilService };
