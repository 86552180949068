import { AttachmentFile } from './attachment-file';
var UiAttachmentFile = /** @class */ (function () {
    function UiAttachmentFile(sourceItem, currentUserName) {
        if (currentUserName === void 0) { currentUserName = null; }
        this.sourceItem = sourceItem;
        this.currentUserName = currentUserName;
        this.isAttachment = this.sourceItem instanceof AttachmentFile;
    }
    Object.defineProperty(UiAttachmentFile.prototype, "id", {
        get: function () {
            return this.isAttachment ? this.sourceItem.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UiAttachmentFile.prototype, "name", {
        get: function () {
            return this.sourceItem.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UiAttachmentFile.prototype, "fileName", {
        get: function () {
            return this.sourceItem.fileName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UiAttachmentFile.prototype, "addedBy", {
        get: function () {
            return this.isAttachment ? this.sourceItem.addedBy : this.currentUserName;
        },
        enumerable: true,
        configurable: true
    });
    return UiAttachmentFile;
}());
export { UiAttachmentFile };
