import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { uniqueValidator } from './slx-unique-value.validator';

const UNIQUE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxUniqueValidator),
  multi: true,
};

@Directive({
  selector: '[slxUniqueStr][formControlName],[slxUniqueStr][formControl],[slxUniqueStr][ngModel]',
  providers: [UNIQUE_VALIDATOR],
})
export class SlxUniqueValidator implements Validator {
  @Input()
  public slxUniqueStr: string[];

  public validate(c: AbstractControl): { [key: string]: any } {
    const validator = uniqueValidator(this.slxUniqueStr);

    const result = validator(c);
    return result;
  }
}
