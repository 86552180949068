export interface IIpRestriction {
  id: number;

  ipAddress: string;
  description: string;

  isAllowRestriction: boolean;

  isEnabled: boolean;
  useInV6: boolean;
  useInGo: boolean;
}
  
export class IpRestriction {
  public id: number;
  
  public ipAddress: string;
  public description: string;

  public isAllowRestriction: boolean;

  public isEnabled: boolean;
  public useInV6: boolean;
  public useInGo: boolean;
}