import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, EventEmitter, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { NgModel } from '@angular/forms';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';
import { Supervisor } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';
var SupervisorGridComponent = /** @class */ (function () {
    function SupervisorGridComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
        this.selectionChanged = new EventEmitter();
        this.saveSuprvisor = new EventEmitter();
    }
    SupervisorGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function () {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            _this.saveSuprvisor.emit(event.dataItem);
        });
    };
    SupervisorGridComponent.prototype.onCheckedPrimary = function (supervisor, ngModel) {
        var primarySupervisor = _.find(this.supervisors, function (s) { return supervisor.id !== s.id && s.isPrimary; });
        if (_.isObjectLike(primarySupervisor) && primarySupervisor.id !== supervisor.id && supervisor.isPrimary) {
            ngModel.control.setErrors({ noMore: true });
        }
        else {
            var errors = ngModel.control.errors || {};
            delete errors.noMore;
            ngModel.control.setErrors(_.size(errors) > 0 ? errors : null);
        }
    };
    SupervisorGridComponent.prototype.ngOnChanges = function (changes) {
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGridComponent.prototype.allSelectionChange = function () {
        this.selectionHelper.onToggleAllSelected();
        this.selectedRecords = this.selectionHelper.selection.slice(0);
        this.selectionChanged.emit(this.selectedRecords);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGridComponent.prototype.selectionChange = function (supervisor) {
        this.selectionHelper.onItemSelectionChange(supervisor);
        this.selectedRecords = this.selectionHelper.selection.slice(0);
        this.selectionChanged.emit(this.selectedRecords);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SupervisorGridComponent.prototype.refreshGrid = function () {
        if (!this.supervisors) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.supervisors, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGridComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGridComponent.prototype, "gridSaveSubscription", void 0);
    return SupervisorGridComponent;
}());
export { SupervisorGridComponent };
