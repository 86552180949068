import { EmployeeTerminationReason } from './../../../../organization/models/lookup/employee-termination-reason';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ITimeclockAssignmentRestriction, TimeclockAssignmentRestriction, IEmployeeSectionsI9, EmployeeSectionsI9, I9ListATypes, I9ListBTypes, I9ListCTypes, I9AdditionalInfoTypes, EmployeeLeaveManagement, IEmployeeLeaveManagement, IEmployeeSubsectionBenefitClasses, EmployeeSubsectionBenefitClasses, IEmployeeSectionsSubsectionContainer, TelepunchSubsection, ITelepunchSubsection, PhonePunchRestrictions, IPhonePunchRestrictions } from '../../models/index';
import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';

import { Assert } from '../../../../framework/index';
import {
  EmployeeSectionsTerminationHistoryEntry,
  IEmployeeSectionsTerminationHistoryEntry,
  EmployeeSectionsTerminationHistoryEntries,
  IEmployeeSectionsTerminationHistoryEntries,
  EmployeeSectionsEmployment,
  IEmployeeSectionsEmployment,
  EmployeeSectionsPayroll,
  IEmployeeSectionsPayroll,
  EmployeeSectionsPositions,
  IEmployeeSectionsPositions,
  EmployeeSectionsPosition,
  IEmployeeSectionsPosition,
  IEmployeeSectionsRateHistory,
  EmployeeSectionsRateHistory,
  IEmployeeSectionsRateHistoryRecord,
  EmployeeSectionsRateHistoryRecord,
  IEmployeeSectionsPositionHistory,
  EmployeeSectionsPositionHistory,
  IEmployeeSectionsPositionHistoryRecord,
  EmployeeSectionsPositionHistoryRecord,
  EmployeeSectionsPayCycleRecord,
  IEmployeeSectionsPayCycleRecord,
  EmployeeSectionsPayCycles,
  IEmployeeSectionsPayCycles,
  EmployeeSectionsPayCycleCalcConf,
  IEmployeeSectionsPayCycleCalcConf,
  IEmployeeSectionTimeclock, EmployeeSectionTimeclock, IEmployeeSectionTimeclockContainer, EmployeeSectionTimeclockContainer,
  IEmployeeSectionsSelfService, EmployeeSectionsSelfService,
  IEmployeeSectionsEssTemplate, EmployeeSectionsEssTemplate
} from '../../models/index';

import { IFieldData, FieldsMeta, Actions } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';
import {
  EmpType,
  Position,
  PayPolicy,
  ShiftDiffPolicy,
  AccrualPolicy,
  PayType, IPayType,
  ExemptStatus, IExemptStatus,
  Organization,
  Department,
  TimeclockRestrictionDefinition, ITimeclockRestrictionDefinition, TimeclockSlateProfileDefinition
} from '../../../../organization/models/lookup/index';
import { LookupMapService } from '../../../../organization/services/index';
import { EmployeeBadge, IBenefitClass, BenefitClass } from '../../../../organization/models/employee/index';
import { Timeclock } from '../../../../configuration/models/index';
import { EmployeeDefinitionsMapService } from '../../../../organization/services/index';
import { EmployeeCostCenterAllocation, EmployeePayRateComponent, IEmployeeCostCenterAllocation, IEmployeeCostCenterAllocations, IEmployeePayRateComponent, IEmployeePayRateComponents } from '../../../employee/models/index';
import { EssTemplateMapService } from '../../../../app-modules/ess-templates/services/index';
import { LMMapService } from '../../../../app-modules/leave-management/services/index';
import { WcRosterMapService } from '../../../../app-modules/workers-compensation/services';
import { IWcIncidentRosterContainer, WcIncidentRosterContainer, IWcIncidentRosterEntry, WcIncidentRosterEntry } from '../../../../app-modules/workers-compensation/models';
import { EmployeeIncidents, IEmployeeIncidents } from '../../models/employee-sections-employment/employee-incidents';
import { EmployeeSectionsReviews, IEmployeeSectionsReviews } from '../../models/employee-sections-employment/employee-sections-reviews';
import { EmployeeSectionsPerformanceMapService } from '../employee-section-performance/employee-section-performance-map.service';
import { PerformanceManagementMapService } from '../../../../app-modules/performance-management/services';
import { EmployeeRosterRecord, IEmployeeRosterRecord } from '../../../../app-modules/performance-management/models';
import { LoaSecurityActions } from '../../../../app-modules/leave-management/models';
import { IEmployeeSectionsACA, EmployeeSectionsACA, EmployeeACA, IEmployeeACA, EmployeeAcaDto } from '../../models/employee-sections-employment/employee-sections-aca';
import { appConfig } from '../../../../app.config';

@Injectable()
export class EmployeeSectionsEmploymentMapService {

  constructor(private metaMapService: MetaMapService,
    private lookupMapService: LookupMapService,
    private essTemplateMapService: EssTemplateMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private lmMapService: LMMapService,
    private wcMapService: WcRosterMapService,
    private pmMapService: PerformanceManagementMapService
  ) {

    Assert.isNotNull(metaMapService, 'metaMapService');
    Assert.isNotNull(lookupMapService, 'lookupMapService');
    Assert.isNotNull(lmMapService, 'lmMapService');
    Assert.isNotNull(wcMapService, 'wcMapService');
    Assert.isNotNull(employeeDefinitionsMapService, 'employeeDefinitionsMapService');

  }

  public mapToSelfService(dselfService: IEmployeeSectionsSelfService, meta: FieldsMeta): EmployeeSectionsSelfService {
    let selfService: EmployeeSectionsSelfService = new EmployeeSectionsSelfService();

    selfService.hasEssAccount = dselfService != null;
    selfService.isEssEnabled = dselfService != null;

    if (dselfService != null) {
      selfService.badgeId = dselfService.badgeId;
      selfService.employeeId = dselfService.employeeId;

      selfService.password = dselfService.password;
      selfService.enterTimeMethod = dselfService.enterTimeMethod;

      selfService.availability = dselfService.availability;
      selfService.timecard = dselfService.timecard;
      selfService.schedule = dselfService.schedule;
      selfService.inservice = dselfService.inservice;
      selfService.changeAddress = dselfService.changeAddress;
      selfService.changePassword = dselfService.changePassword;
      selfService.payStubs = dselfService.payStubs;
    }

    selfService.actions = this.metaMapService.mapActions(meta);

    return selfService;
  }

  public mapToEssTemplateSubsection(dessTemplateSection: IEmployeeSectionsEssTemplate, meta: FieldsMeta): EmployeeSectionsEssTemplate {
    let essTemplateSection: EmployeeSectionsEssTemplate = new EmployeeSectionsEssTemplate();
    essTemplateSection.employeeId = dessTemplateSection.employeeId;
    essTemplateSection.passwordShouldBeReseted = dessTemplateSection.passwordShouldBeReseted;
    essTemplateSection.essTemplate = this.essTemplateMapService.mapToEssTemplate(dessTemplateSection.essTemplate);
    essTemplateSection.actions = this.metaMapService.mapActions(meta);
    return essTemplateSection;
  }

  public mapToTelepunch(dTelepunch: ITelepunchSubsection, meta: FieldsMeta): TelepunchSubsection {
    Assert.isNotNull(dTelepunch, 'dTelepunch');
    let telepunch: TelepunchSubsection = new TelepunchSubsection();
    let restrictions: PhonePunchRestrictions[] = [];

    _.each(dTelepunch.phonepunchRestrictions, (r: IPhonePunchRestrictions) => {
      let restriction: PhonePunchRestrictions = new PhonePunchRestrictions();
      restriction.id = r.id;
      restriction.name = r.name;
      restrictions.push(restriction);
    });

    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    let restriction = _.find(restrictions, item => item.id === dTelepunch.phonepunchEnabled);
    let phonePunchEnabled = false;
    if (!_.isNil(restriction)) {
      phonePunchEnabled = true;
    }
    telepunch.phonepunchEnabled = this.metaMapService.mapMeta<boolean>(metaMap, 'phonepunchEnabled', phonePunchEnabled, '******');
    telepunch.restrictionId = dTelepunch.phonepunchEnabled;
    telepunch.phonepunchRestrictions = this.metaMapService.mapMeta<PhonePunchRestrictions[]>(metaMap, 'phonepunchRestrictions', restrictions, '******');
    telepunch.actions = this.metaMapService.mapActions(meta);

    return telepunch;
  }

  public mapToI9(di9: IEmployeeSectionsI9, meta: FieldsMeta): EmployeeSectionsI9 {
    Assert.isNotNull(di9, 'di9');

    let i9: EmployeeSectionsI9 = new EmployeeSectionsI9();

    if (di9.listA.alienRegistrationCard) {
      i9.listA.type = I9ListATypes.AlienRegistrationCard;
    } else if (di9.listA.usPassport) {
      i9.listA.type = I9ListATypes.UsPassport;
    } else if (di9.listA.usCitizenshipCertificate) {
      i9.listA.type = I9ListATypes.UsCitizenshipCertificate;
    } else if (di9.listA.naturalizationCertificate) {
      i9.listA.type = I9ListATypes.NaturalizationCertificate;
    } else if (di9.listA.foreignPassportWithEmploymentAuth) {
      i9.listA.type = I9ListATypes.ForeignPassportWithEmploymentAuth;
    }
    i9.listA.documentId = di9.listA.documentId;
    i9.listA.documentExpiration = di9.listA.documentExpiration ? dateTimeUtils.convertFromDtoString(di9.listA.documentExpiration) : null;

    if (di9.listB.stateIssuedDriversLicense) {
      i9.listB.type = I9ListBTypes.StateIssuedDriversLicense;
    } else if (di9.listB.usMilitaryCard) {
      i9.listB.type = I9ListBTypes.UsMilitaryCard;
    } else if (di9.listB.isOther) {
      i9.listB.type = I9ListBTypes.IsOther;
    }
    i9.listB.other = di9.listB.other;
    i9.listB.documentId = di9.listB.documentId;
    i9.listB.documentExpiration = di9.listB.documentExpiration ? dateTimeUtils.convertFromDtoString(di9.listB.documentExpiration) : null;

    if (di9.listC.originalSSNCard) {
      i9.listC.type = I9ListCTypes.OriginalSSNCard;
    } else if (di9.listC.birthCertificate) {
      i9.listC.type = I9ListCTypes.BirthCertificate;
    } else if (di9.listC.unexpiredINSEmploymentAuth) {
      i9.listC.type = I9ListCTypes.UnexpiredINSEmploymentAuth;
    }
    i9.listC.documentId = di9.listC.documentId;
    i9.listC.documentExpiration = di9.listC.documentExpiration ? dateTimeUtils.convertFromDtoString(di9.listC.documentExpiration) : null;

    if (di9.additionalInfo.usCitizen) {
      i9.additionalInfo.type = I9AdditionalInfoTypes.UsCitizen;
    } else if (di9.additionalInfo.nonUsCitizen) {
      i9.additionalInfo.type = I9AdditionalInfoTypes.NonUsCitizen;
    } else if (di9.additionalInfo.lawfulPermanentResident) {
      i9.additionalInfo.type = I9AdditionalInfoTypes.LawfulPermanentResident;
    } else if (di9.additionalInfo.alienAuthorizedToWork) {
      i9.additionalInfo.type = I9AdditionalInfoTypes.AlienAuthorizedToWork;
    }
    i9.additionalInfo.alienNumber = di9.additionalInfo.alienNumber;
    i9.additionalInfo.alienOrAdmissionNumber = di9.additionalInfo.alienOrAdmissionNumber;
    i9.additionalInfo.expiration = di9.additionalInfo.expiration ? dateTimeUtils.convertFromDtoString(di9.additionalInfo.expiration) : null;

    i9.actions = this.metaMapService.mapActions(meta);

    return i9;
  }

  public mapToI9Dto(i9: EmployeeSectionsI9): IEmployeeSectionsI9 {
    Assert.isNotNull(i9, 'i9');

    let di9: IEmployeeSectionsI9 = {
      listA: {
        usPassport: i9.listA.type === I9ListATypes.UsPassport,
        usCitizenshipCertificate: i9.listA.type === I9ListATypes.UsCitizenshipCertificate,
        naturalizationCertificate: i9.listA.type === I9ListATypes.NaturalizationCertificate,
        foreignPassportWithEmploymentAuth: i9.listA.type === I9ListATypes.ForeignPassportWithEmploymentAuth,
        alienRegistrationCard: i9.listA.type === I9ListATypes.AlienRegistrationCard,
        documentId: i9.listA.documentId,
        documentExpiration: dateTimeUtils.convertToDtoString(i9.listA.documentExpiration)
      },
      listB: {
        stateIssuedDriversLicense: i9.listB.type === I9ListBTypes.StateIssuedDriversLicense,
        usMilitaryCard: i9.listB.type === I9ListBTypes.UsMilitaryCard,
        isOther: i9.listB.type === I9ListBTypes.IsOther,
        other: i9.listB.other,
        documentId: i9.listB.documentId,
        documentExpiration: dateTimeUtils.convertToDtoString(i9.listB.documentExpiration)
      },
      listC: {
        originalSSNCard: i9.listC.type === I9ListCTypes.OriginalSSNCard,
        birthCertificate: i9.listC.type === I9ListCTypes.BirthCertificate,
        unexpiredINSEmploymentAuth: i9.listC.type === I9ListCTypes.UnexpiredINSEmploymentAuth,
        documentId: i9.listC.documentId,
        documentExpiration: dateTimeUtils.convertToDtoString(i9.listC.documentExpiration)
      },
      additionalInfo: {
        usCitizen: i9.additionalInfo.type === I9AdditionalInfoTypes.UsCitizen,
        nonUsCitizen: i9.additionalInfo.type === I9AdditionalInfoTypes.NonUsCitizen,
        lawfulPermanentResident: i9.additionalInfo.type === I9AdditionalInfoTypes.LawfulPermanentResident,
        alienAuthorizedToWork: i9.additionalInfo.type === I9AdditionalInfoTypes.AlienAuthorizedToWork,
        alienNumber: i9.additionalInfo.alienNumber,
        alienOrAdmissionNumber: i9.additionalInfo.alienOrAdmissionNumber,
        expiration: dateTimeUtils.convertToDtoString(i9.additionalInfo.expiration)
      }
    };

    return di9;
  }

  public mapToPayroll(dpayroll: IEmployeeSectionsPayroll, meta: FieldsMeta): EmployeeSectionsPayroll {
    Assert.isNotNull(dpayroll, 'dpayroll');
    let payroll: EmployeeSectionsPayroll = new EmployeeSectionsPayroll();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    payroll.payrollNumber = this.metaMapService.mapMeta<string>(metaMap, 'payrollNumber', dpayroll.payrollNumber);
    payroll.fileNumber = this.metaMapService.mapMeta<string>(metaMap, 'fileNumber', dpayroll.fileNumber);
    payroll.ssn = this.metaMapService.mapMeta<string>(metaMap, 'ssn', dpayroll.ssn);
    payroll.hireDate = this.metaMapService.mapMeta<Date>(metaMap, 'hireDate', dateTimeUtils.convertFromDtoString(dpayroll.hireDate), dpayroll.hireDate);
    payroll.rehireDate = dateTimeUtils.getUtcDateTime(dpayroll.rehireDate);

    let empType: EmpType = this.lookupMapService.mapEmpType(dpayroll.empType);
    payroll.empType = this.metaMapService.mapMeta<EmpType>(metaMap, 'empType', empType, empType ? empType.name : null);
    payroll.orgNavBreadcrumb = dpayroll.orgNavBreadcrumb;
    payroll.ftDate = this.metaMapService.mapMeta<Date>(metaMap, 'ftDate', dateTimeUtils.convertFromDtoString(dpayroll.ftDate), dpayroll.ftDate);
    payroll.acaType = this.metaMapService.mapMeta<string>(metaMap, 'acaType', dpayroll.acaType);
    payroll.benefitHours = this.metaMapService.mapMeta<number>(metaMap, 'benefitHours', dpayroll.benefitHours);
    payroll.lastIncreaseDate = this.metaMapService.mapMeta<Date>(metaMap, 'lastIncreaseDate', dateTimeUtils.convertFromDtoString(dpayroll.lastIncreaseDate), dpayroll.lastIncreaseDate);

    let position: Position = this.lookupMapService.mapPosition(dpayroll.position);
    payroll.position = this.metaMapService.mapMeta<Position>(metaMap, 'position', position, position ? position.name : null);

    let payPolicy: PayPolicy = this.lookupMapService.mapPayPolicy(dpayroll.payPolicy);
    payroll.payPolicy = this.metaMapService.mapMeta<PayPolicy>(metaMap, 'payPolicy', payPolicy, payPolicy ? payPolicy.description : null);

    let shiftDiffPolicy: ShiftDiffPolicy = this.lookupMapService.mapShiftDiffPolicy(dpayroll.shiftDiffPolicy);
    payroll.shiftDiffPolicy = this.metaMapService.mapMeta<ShiftDiffPolicy>(metaMap, 'shiftDiffPolicy', shiftDiffPolicy, shiftDiffPolicy ? shiftDiffPolicy.description : null);

    let payTypeDto: IPayType = { name: dpayroll.payType };
    let payType: PayType = this.lookupMapService.mapPayType(payTypeDto);
    payroll.payType = this.metaMapService.mapMeta<PayType>(metaMap, 'payType', payType, payType ? payType.description : null);

    let exemptStatusDto: IExemptStatus = { name: dpayroll.exemptStatus };
    let exemptStatus: ExemptStatus = this.lookupMapService.mapExemptStatus(exemptStatusDto);

    payroll.exemptStatus = this.metaMapService.mapMeta<ExemptStatus>(metaMap, 'exemptStatus', exemptStatus, exemptStatus ? exemptStatus.name : null);
    payroll.payRate = this.metaMapService.mapMeta<number>(metaMap, 'payRate', dpayroll.payRate);
    payroll.payRate2 = this.metaMapService.mapMeta<number>(metaMap, 'payRate2', dpayroll.payRate2);
    payroll.payRate3 = this.metaMapService.mapMeta<number>(metaMap, 'payRate3', dpayroll.payRate3);
    payroll.standardWeeklyHours = this.metaMapService.mapMeta<number>(metaMap, 'standardWeeklyHours', dpayroll.standardWeeklyHours);
    payroll.annualSalary = this.metaMapService.mapMeta<number>(metaMap, 'annualSalary', dpayroll.annualSalary);

    let costCenterAllocations = this.mapCostCenterAllocations(dpayroll.costCenterAllocations);
    payroll.costCenterAllocations = this.metaMapService.mapMeta<EmployeeCostCenterAllocation[]>(metaMap, 'costCenterAllocations', costCenterAllocations);
    payroll.costCenterCode = this.metaMapService.mapMeta<string>(metaMap, 'costCenterCode', dpayroll.costCenterCode);

    let payRateComponents = this.mapPayRateComponents(dpayroll.payRateComponents);
    payroll.payRateComponents = this.metaMapService.mapMeta<EmployeePayRateComponent[]>(metaMap, 'payRateComponent', payRateComponents);

    payroll.pbjId = this.metaMapService.mapMeta<string>(metaMap, 'pbjId', dpayroll.pbjId);
    payroll.union = this.metaMapService.mapMeta<string>(metaMap, 'union', dpayroll.union);
    payroll.homeOrgLevelId = dpayroll.homeOrgLevelId;
    payroll.isPayrollNumberRequired = dpayroll.isPayrollNumberRequired;
    payroll.isEnableRate4DecimalPlaces = dpayroll.isEnableRate4DecimalPlaces;
    payroll.isPayRateComponents = dpayroll.isPayRateComponents;
    payroll.maxAllowableHireDate = dateTimeUtils.convertFromDtoString(dpayroll.maxAllowableHireDate);

    payroll.icnreaseReason = dpayroll.inreaseReason;
    payroll.isEnabledInreaseReason = dpayroll.isEnabledInreaseReason;
    payroll.supervisor = dpayroll.supervisor;
    payroll.actions = this.metaMapService.mapActions(meta);
    payroll.fieldsMeta = meta;
    return payroll;
  }

  public mapToPayrollDto(payroll: EmployeeSectionsPayroll): IEmployeeSectionsPayroll {
    Assert.isNotNull(payroll, 'payroll');

    let payRateComponents: IEmployeePayRateComponents;
    payRateComponents = this.mapPayRateComponentDto(payroll.payRateComponents.fieldValue);

    let dpayroll: IEmployeeSectionsPayroll = {
      payrollNumber: payroll.payrollNumber.fieldValue,
      fileNumber: payroll.fileNumber.fieldValue,
      ssn: payroll.ssn.fieldValue,
      hireDate: dateTimeUtils.convertToDtoString(payroll.hireDate.fieldValue),
      rehireDate: payroll.rehireDate,
      empType: this.lookupMapService.mapEmpTypeDto(payroll.empType.fieldValue),
      orgNavBreadcrumb: payroll.orgNavBreadcrumb,
      ftDate: dateTimeUtils.convertToDtoString(payroll.ftDate.fieldValue),
      acaType: payroll.acaType.fieldValue,
      benefitHours: payroll.benefitHours.fieldValue,
      lastIncreaseDate: dateTimeUtils.convertToDtoString(payroll.lastIncreaseDate.fieldValue),
      position: this.lookupMapService.mapPositionDto(payroll.position.fieldValue),
      payPolicy: this.lookupMapService.mapPayPolicyDto(payroll.payPolicy.fieldValue),
      shiftDiffPolicy: this.lookupMapService.mapShiftDiffPolicyDto(payroll.shiftDiffPolicy.fieldValue),
      payType: this.lookupMapService.mapPayTypeDto(payroll.payType.fieldValue).name,
      exemptStatus: this.lookupMapService.mapExemptStatusDto(payroll.exemptStatus.fieldValue).name,
      payRate: payroll.payRate.fieldValue,
      payRate2: payroll.payRate2.fieldValue,
      payRate3: payroll.payRate3.fieldValue,
      payRateComponents,
      standardWeeklyHours: payroll.standardWeeklyHours.fieldValue,
      annualSalary: payroll.annualSalary.fieldValue,
      costCenterCode: payroll.costCenterCode.fieldValue,
      costCenterAllocations: this.mapCostCenterAllocationsDto(payroll.costCenterAllocations.fieldValue),
      homeOrgLevelId: payroll.homeOrgLevelId,
      isPayrollNumberRequired: payroll.isPayrollNumberRequired,
      isEnableRate4DecimalPlaces: payroll.isEnableRate4DecimalPlaces,
      isPayRateComponents: payroll.isPayRateComponents,
      pbjId: payroll.pbjId.fieldValue,
      union: payroll.union.fieldValue,
      maxAllowableHireDate: null,
      inreaseReason: payroll.icnreaseReason,
      isEnabledInreaseReason: false,
      supervisor: this.lookupMapService.mapSupervisor(payroll.supervisor)
    };
    if (dpayroll.ssn === '') dpayroll.ssn = null;
    return dpayroll;
  }

  public mapCostCenterAllocations(items: IEmployeeCostCenterAllocations): EmployeeCostCenterAllocation[] {
    const allocations: EmployeeCostCenterAllocation[] = [];

    _.forEach(items && items.list ? items.list : [], (item: IEmployeeCostCenterAllocation) => {
      const allocation = new EmployeeCostCenterAllocation();

      allocation.costCenter = {
        code: item.costCenterCode.toString(),
        description: item.costCenterDescription
      };
      allocation.percentage = item.percentage;

      allocations.push(allocation);
    });

    return allocations;
  }

  public mapPayRateComponents(items: IEmployeePayRateComponents): EmployeePayRateComponent[] {
    const payRateRecords: EmployeePayRateComponent[] = [];

    _.forEach(items && items.records ? items.records : [], (item: IEmployeePayRateComponent) => {
      const payRateRecord = new EmployeePayRateComponent();

      payRateRecord.component = item.component;
      payRateRecord.rate = item.rate;

      payRateRecords.push(payRateRecord);
    });

    return payRateRecords;
  }

  public mapCostCenterAllocationsDto(allocations: EmployeeCostCenterAllocation[]): IEmployeeCostCenterAllocations {
    const items: IEmployeeCostCenterAllocation[] = [];

    _.forEach(allocations, (allocation: EmployeeCostCenterAllocation) => {
      if (!allocation.costCenter || !allocation.costCenter.code) {
        return;
      }

      items.push({
        costCenterCode: parseInt(allocation.costCenter.code, 10),
        costCenterDescription: allocation.costCenter.description,
        percentage: allocation.percentage
      });
    });

    return {
      list: items
    };
  }

  public mapPayRateComponentDto(allocations: EmployeePayRateComponent[]): IEmployeePayRateComponents {
    const items: IEmployeePayRateComponent[] = [];

    _.forEach(allocations, (allocation: EmployeePayRateComponent) => {
      if (!allocation.component) {
        return;
      }

      items.push({
        component: allocation.component,
        rate: allocation.rate
      });
    });

    return {

      records: items
    };
  }

  public mapToLeaveManagement(leaveRequestsRawData: IEmployeeLeaveManagement, meta: FieldsMeta): EmployeeLeaveManagement {
    Assert.isNotNull(leaveRequestsRawData.records, 'records');

    const leaveManagement = new EmployeeLeaveManagement();
    leaveManagement.canAddEdit = _.findIndex(meta.actions, x => x === LoaSecurityActions.canAddEdit) >= 0;
    leaveManagement.canDelete = _.findIndex(meta.actions, x => x === LoaSecurityActions.canDelete) >= 0;
    leaveManagement.records = this.lmMapService.mapLoaRequests(leaveRequestsRawData.records);

    return leaveManagement;
  }

  public mapToPositions(dpositions: IEmployeeSectionsPositions, meta: FieldsMeta): EmployeeSectionsPositions {
    Assert.isNotNull(dpositions, 'dpositions');
    let empPositions: EmployeeSectionsPositions = new EmployeeSectionsPositions();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    empPositions.actions = this.metaMapService.mapActions(meta);

    empPositions.positionMetaData = this.metaMapService.mapMeta<Position>(metaMap, 'position', null);
    empPositions.organizationMetaData = this.metaMapService.mapMeta<Organization>(metaMap, 'organization', null);
    empPositions.departmentMetaData = this.metaMapService.mapMeta<Department>(metaMap, 'department', null);
    empPositions.shiftDiffPolicyMetaData = this.metaMapService.mapMeta<ShiftDiffPolicy>(metaMap, 'shiftDiffPolicy', null);
    empPositions.payPolicyMetaData = this.metaMapService.mapMeta<PayPolicy>(metaMap, 'payPolicy', null);
    empPositions.hourlyRateMetaData = this.metaMapService.mapMeta<number>(metaMap, 'hourlyRate', null);
    empPositions.startDateMetaData = this.metaMapService.mapMeta<Date>(metaMap, 'startDate', null);
    empPositions.endDateMetaData = this.metaMapService.mapMeta<Date>(metaMap, 'endDate', null);


    _.forEach(dpositions.positions, (dposition: IEmployeeSectionsPosition) => {
      let empPosition: EmployeeSectionsPosition = new EmployeeSectionsPosition();
      empPosition.id = dposition.id;
      let organization: Organization = this.lookupMapService.mapOrganization(dposition.organization);
      empPosition.organization = this.metaMapService.mapMeta<Organization>(metaMap, 'organization', organization, organization ? organization.name : null);
      let department: Department = this.lookupMapService.mapDepartment(dposition.department);
      empPosition.department = this.metaMapService.mapMeta<Department>(metaMap, 'department', department, department ? department.name : null);
      let position: Position = this.lookupMapService.mapPosition(dposition.position);
      if (!!dposition.isPrimary) {
        empPosition.position = this.metaMapService.mapMeta<Position>(metaMap, 'isPrimary', position, position ? position.name : null);
      } else {
        empPosition.position = this.metaMapService.mapMeta<Position>(metaMap, 'position', position, position ? position.name : null);
      }
      let shiftDifferentialPolicy: ShiftDiffPolicy = this.lookupMapService.mapShiftDiffPolicy(dposition.shiftDifferentialPolicy);
      empPosition.shiftDiffPolicy = this.metaMapService.mapMeta<ShiftDiffPolicy>(metaMap, 'shiftDifferentialPolicy', shiftDifferentialPolicy, shiftDifferentialPolicy ? shiftDifferentialPolicy.description : null);
      let payPolicy: PayPolicy = this.lookupMapService.mapPayPolicy(dposition.payPolicy);
      empPosition.payPolicy = this.metaMapService.mapMeta<PayPolicy>(metaMap, 'payPolicy', payPolicy, payPolicy ? payPolicy.description : null);
      empPosition.hourlyRate = this.metaMapService.mapMeta<number>(metaMap, 'hourlyRate', dposition.hourlyRate);
      empPosition.isPrimary = dposition.isPrimary;
      empPosition.startDate = this.metaMapService.mapMeta<Date>(metaMap, 'startDate', dateTimeUtils.convertFromDtoString(dposition.startDate), dposition.startDate);
      empPosition.endDate = this.metaMapService.mapMeta<Date>(metaMap, 'endDate', dateTimeUtils.convertFromDtoString(dposition.endDate), dposition.endDate);
      empPosition.isSaved = true;
      empPositions.positions.push(empPosition);
    });
    empPositions.actions = this.metaMapService.mapActions(meta);
    empPositions.fieldsMeta = meta;
    return empPositions;
  }

  public mapToPositionsDto(empPositions: EmployeeSectionsPositions): IEmployeeSectionsPositions {
    Assert.isNotNull(empPositions, 'empPositions');

    let dpositions: IEmployeeSectionsPositions = {
      positions: []
    };
    _.forEach(empPositions.positions, (empPosition: EmployeeSectionsPosition) => {
      let dposition: IEmployeeSectionsPosition = {
        id: empPosition.id,
        organization: this.lookupMapService.mapOrganizationDto(empPosition.organization.fieldValue),
        department: this.lookupMapService.mapDepartmentDto(empPosition.department.fieldValue),
        position: this.lookupMapService.mapPositionDto(empPosition.position.fieldValue),
        shiftDifferentialPolicy: this.lookupMapService.mapShiftDiffPolicyDto(empPosition.shiftDiffPolicy.fieldValue),
        payPolicy: this.lookupMapService.mapPayPolicyDto(empPosition.payPolicy.fieldValue),
        hourlyRate: empPosition.hourlyRate.fieldValue,
        isPrimary: empPosition.isPrimary,
        startDate: dateTimeUtils.convertToDtoString(empPosition.startDate.fieldValue),
        endDate: dateTimeUtils.convertToDtoString(empPosition.endDate.fieldValue),
        isDeleted: empPosition.isDeleted
      };
      dpositions.positions.push(dposition);
    });
    return dpositions;
  }

  public mapToTerminationHistory(terminationEntriesDto: IEmployeeSectionsTerminationHistoryEntries, meta: FieldsMeta): EmployeeSectionsTerminationHistoryEntries {
    Assert.isNotNull(terminationEntriesDto, 'terminationEntriesDto');
    let terminationEntries: EmployeeSectionsTerminationHistoryEntries = new EmployeeSectionsTerminationHistoryEntries();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    _.forEach(terminationEntriesDto.terminations, (termination: IEmployeeSectionsTerminationHistoryEntry) => {
      let terminationEntry: EmployeeSectionsTerminationHistoryEntry = new EmployeeSectionsTerminationHistoryEntry();
      terminationEntry.doNotRehire = this.metaMapService.mapMeta<boolean>(metaMap, 'doNotRehire', termination.doNotRehire);
      terminationEntry.notes = this.metaMapService.mapMeta<string>(metaMap, 'notes', termination.notes);
      terminationEntry.emp_Id = termination.emp_Id;
      terminationEntry.term_History_Id = termination.term_History_Id;
      let employeeTerminationReason: EmployeeTerminationReason = this.lookupMapService.mapTerminateReason( termination.reason);
      terminationEntry.reason = this.metaMapService.mapMeta<EmployeeTerminationReason>(metaMap, 'reason', employeeTerminationReason, '*******');
      terminationEntry.terminatedBy = this.metaMapService.mapMeta<string>(metaMap, 'terminatedBy', termination.terminatedBy);
      terminationEntry.terminationDate = this.metaMapService.mapMeta<Date>(metaMap, 'terminationDate',
        dateTimeUtils.convertFromDtoString(termination.terminationDate), termination.terminationDate);
      terminationEntry.voluntaryTerm = this.metaMapService.mapMeta<boolean>(metaMap, 'voluntaryTerm', termination.voluntaryTerm);
      terminationEntries.terminationEntries.push(terminationEntry);
    });
    terminationEntries.actions = this.metaMapService.mapActions(meta);
    return terminationEntries;
  }

  public mapToTerminationHistoryDto(terminationEntries: EmployeeSectionsTerminationHistoryEntries): IEmployeeSectionsTerminationHistoryEntries {
    Assert.isNotNull(terminationEntries, 'terminationEntries');
    let terminations: IEmployeeSectionsTerminationHistoryEntry[] = [];

    _.forEach(terminationEntries.terminationEntries, (termination: EmployeeSectionsTerminationHistoryEntry) => {
      let terminationEntry: IEmployeeSectionsTerminationHistoryEntry = {
        doNotRehire: termination.doNotRehire.fieldValue,
        notes: termination.notes.fieldValue,
        reason: termination.reason.fieldValue ? termination.reason.fieldValue.reason : null, // termination.reason.fieldValue,
        terminatedBy: termination.terminatedBy.fieldValue,
        terminationDate: dateTimeUtils.convertToDtoString(termination.terminationDate.fieldValue),
        voluntaryTerm: termination.voluntaryTerm.fieldValue,
        emp_Id: termination.emp_Id,
        term_History_Id:termination.term_History_Id
      };
      terminations.push(terminationEntry);
    });
    let terimationEntries: IEmployeeSectionsTerminationHistoryEntries = {
      terminations: terminations
    };
    return terimationEntries;
  }

  public mapToRateHistory(rateHistoryDto: IEmployeeSectionsRateHistory, meta: FieldsMeta): EmployeeSectionsRateHistory {
    Assert.isNotNull(rateHistoryDto, 'rateHistoryDto');
    let rateHistory: EmployeeSectionsRateHistory = new EmployeeSectionsRateHistory();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    rateHistory.isEnableRate4DecimalPlaces = rateHistoryDto.isEnableRate4DecimalPlaces;
    rateHistory.isEnableIncreasedReason = rateHistoryDto.isEnableIncreasedReason;

    _.forEach(rateHistoryDto.records, (recordDto: IEmployeeSectionsRateHistoryRecord) => {
      let record: EmployeeSectionsRateHistoryRecord = new EmployeeSectionsRateHistoryRecord();
      record.startDate = this.metaMapService.mapMeta<Date>(metaMap, 'startDate',
        dateTimeUtils.convertFromDtoString(recordDto.startDate), '*******');
      record.endDate = this.metaMapService.mapMeta<Date>(metaMap, 'endDate',
        dateTimeUtils.convertFromDtoString(recordDto.endDate), '*******');
      record.rate = this.metaMapService.mapMeta<number>(metaMap, 'rate', recordDto.rate, '*******');
      record.rate2 = this.metaMapService.mapMeta<number>(metaMap, 'rate2', recordDto.rate2, '*******');
      record.rate3 = this.metaMapService.mapMeta<number>(metaMap, 'rate3', recordDto.rate3, '*******');

      let payTypeDto: IPayType = { name: recordDto.payType };
      let payType: PayType = this.lookupMapService.mapPayType(payTypeDto);
      record.payType = this.metaMapService.mapMeta<PayType>(metaMap, 'payType', payType, '*******');

      record.standardHours = this.metaMapService.mapMeta<number>(metaMap, 'standardHours', recordDto.standardHours, '*******');
      record.benefitHours = this.metaMapService.mapMeta<number>(metaMap, 'benefitHours', recordDto.benefitHours, '*******');

      let exemptStatus: ExemptStatus = this.lookupMapService.mapExemptStatus(recordDto.exemptStatus);
      record.exemptStatus = this.metaMapService.mapMeta<ExemptStatus>(metaMap, 'exemptStatus', exemptStatus, '*******');
      record.incReason = recordDto.incReason ? recordDto.incReason  : ' ';
      rateHistory.records.push(record);
    });
    rateHistory.actions = this.metaMapService.mapActions(meta);
    return rateHistory;
  }

  public mapToPositionHistory(positionHistoryDto: IEmployeeSectionsPositionHistory, meta: FieldsMeta): EmployeeSectionsPositionHistory {
    Assert.isNotNull(positionHistoryDto, 'positionHistoryDto');
    let positionHistory: EmployeeSectionsPositionHistory = new EmployeeSectionsPositionHistory();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    _.forEach(positionHistoryDto.records, (recordDto: IEmployeeSectionsPositionHistoryRecord) => {
      let record: EmployeeSectionsPositionHistoryRecord = new EmployeeSectionsPositionHistoryRecord();
      record.archivedDate = this.metaMapService.mapMeta<Date>(metaMap, 'archivedDate', dateTimeUtils.convertFromDtoString(recordDto.archivedDate), recordDto.archivedDate);
      record.endDate = this.metaMapService.mapMeta<Date>(metaMap, 'endDate', dateTimeUtils.convertFromDtoString(recordDto.endDate), recordDto.endDate);
      record.jobDescription = this.metaMapService.mapMeta<string>(metaMap, 'jobDescription', recordDto.jobDescription);
      record.departmentName = this.metaMapService.mapMeta<string>(metaMap, 'departmentName', recordDto.departmentName);
      record.organizationName = this.metaMapService.mapMeta<string>(metaMap, 'organizationName', recordDto.organizationName);
      record.isPrimary = this.metaMapService.mapMeta<boolean>(metaMap, 'isPrimary', recordDto.isPrimary);
      record.activation_date = this.metaMapService.mapMeta<Date>(metaMap, 'activation_date', dateTimeUtils.convertFromDtoString(recordDto.activation_date), recordDto.activation_date);
      record.isHideEnddate = this.metaMapService.mapMeta<boolean>(metaMap, 'isHideEnddate', recordDto.isHideEnddate);
      record.emp_dt_termination = this.metaMapService.mapMeta<Date>(metaMap, 'emp_dt_termination', dateTimeUtils.convertFromDtoString(recordDto.emp_dt_termination), recordDto.emp_dt_termination);
     
      let payPolicy: PayPolicy = this.lookupMapService.mapPayPolicy(recordDto.payPolicy);
      record.payPolicy = this.metaMapService.mapMeta<PayPolicy>(metaMap, 'payPolicy', payPolicy, payPolicy ? payPolicy.description : null);

      let shiftDiffPolicy: ShiftDiffPolicy = this.lookupMapService.mapPayPolicy(recordDto.shiftDifferentialPolicy);
      record.shiftDiffPolicy = this.metaMapService.mapMeta<ShiftDiffPolicy>(metaMap, 'shiftDiffPolicy', shiftDiffPolicy, shiftDiffPolicy ? shiftDiffPolicy.description : null);

      record.costCenter = this.metaMapService.mapMeta<number>(metaMap, 'costCenter', +recordDto.costCenter);

      let employeeType: EmpType = this.lookupMapService.mapEmpType(recordDto.employeeType);
      record.employeeType = this.metaMapService.mapMeta<EmpType>(metaMap, 'employeeType', employeeType, employeeType ? employeeType.name : null);
      positionHistory.records.push(record);
      if (recordDto.isPrimary)
        positionHistory.primaryRecords.push(record);
      else
        positionHistory.secondaryRecords.push(record);
    });
    positionHistory.actions = this.metaMapService.mapActions(meta);
    return positionHistory;
  }

  public mapToPayCycles(payCyclesDto: IEmployeeSectionsPayCycles, meta: FieldsMeta): EmployeeSectionsPayCycles {
    Assert.isNotNull(payCyclesDto, 'payCyclesDto');
    let payCycles: EmployeeSectionsPayCycles = new EmployeeSectionsPayCycles();
    _.forEach(payCyclesDto.records, (recordDto: IEmployeeSectionsPayCycleRecord) => {
      let record: EmployeeSectionsPayCycleRecord = new EmployeeSectionsPayCycleRecord();
      record.startDate = dateTimeUtils.convertFromDtoString(recordDto.startDate);
      record.endDate = dateTimeUtils.convertFromDtoString(recordDto.endDate);
      record.approved = recordDto.approved;
      record.locked = recordDto.locked;
      record.regular = recordDto.regular;
      record.overtime = recordDto.overtime;
      record.other = recordDto.other;
      record.absences = recordDto.absences;
      payCycles.records.push(record);
    });
    payCycles.actions = this.metaMapService.mapActions(meta);
    /*payCycles.actions = new Actions();
    payCycles.actions.canEdit = true;
    payCycles.actions.canAdd = true;
    payCycles.actions.canDelete = true;*/
    return payCycles;

  }

  public mapToPayCyclesConfDto(payCyclesConf: EmployeeSectionsPayCycleCalcConf): IEmployeeSectionsPayCycleCalcConf {
    let dto: IEmployeeSectionsPayCycleCalcConf = {
      effectiveDate: dateTimeUtils.convertToDtoString(payCyclesConf.effectiveDate),
      payCycle: payCyclesConf.payCycle ? payCyclesConf.payCycle.name : 'weekly',
      startDay1: payCyclesConf.startDay1,
      startDay2: payCyclesConf.startDay2
    };
    return dto;
  }

  public mapToSectionsEmployment(data: IEmployeeSectionsEmployment): EmployeeSectionsEmployment {
    let employment: EmployeeSectionsEmployment = new EmployeeSectionsEmployment();
    employment.payroll = this.mapToPayroll(data.payroll.data, data.payroll.metadata);
    employment.positions = this.mapToPositions(data.positions.data, data.positions.metadata);
    employment.terminationHistory = this.mapToTerminationHistory(data.terminationHistory.data, data.terminationHistory.metadata);
    employment.rateHistory = this.mapToRateHistory(data.rateHistory.data, data.rateHistory.metadata);
    employment.positionHistory = this.mapToPositionHistory(data.positionHistory.data, data.positionHistory.metadata);
    employment.payCycles = this.mapToPayCycles(data.payCycles.data, data.payCycles.metadata);
    employment.timeclocks = this.mapToTimeclocks(data.timeclocks.data, data.timeclocks.metadata);
    employment.selfService = data.selfService ? this.mapToSelfService(data.selfService.data, data.selfService.metadata) : null;
    employment.essTemplate = data.essTemplate ? this.mapToEssTemplateSubsection(data.essTemplate.data, data.essTemplate.metadata) : null;
    employment.i9 = this.mapToI9(data.i9.data, data.i9.metadata);
    employment.leaveManagement = this.mapToLeaveManagement(data.leaveManagement.data, data.leaveManagement.metadata);
    employment.workersComp = data.workersCompensation ? this.mapToEmployeeIncidentsSubSection(data.workersCompensation, data.workersCompensation.metadata) : null;
    employment.performanceManagement = data.performanceManagement ? this.mapToEmployeePerformanceManagementSubSection(data.performanceManagement, data.performanceManagement.metadata) : null;
    employment.telepunch = this.mapToTelepunch(data.telepunch.data, data.telepunch.metadata);
    employment.aca = data.aca ? this.mapToEmployeeACA(data.aca.data, data.aca.metadata) : null;
    return employment;
  }

  public mapToEmployeePerformanceManagementSubSection(performanceManagement: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsReviews>, metadata: FieldsMeta): EmployeeSectionsReviews {

    let container = new EmployeeSectionsReviews();

    let entries: EmployeeRosterRecord[] = [];
    _.each(performanceManagement.data.records, (r: IEmployeeRosterRecord) => {
      let review = this.pmMapService.mapToEmployeeReview(r);
      entries.push(review);
    });
    container.records = entries;
    container.canCreateNewReview = performanceManagement.data.canCreateNewReview;
    container.employee = performanceManagement.data.employee ? this.lookupMapService.mapEmployee(performanceManagement.data.employee) : null;

    return container;
  }

  public mapToEmployeeReviews(reviews: IEmployeeSectionsReviews, meta: FieldsMeta): EmployeeSectionsReviews {

    let container = new EmployeeSectionsReviews();
    container.canCreateNewReview = reviews.canCreateNewReview;
    container.employee = reviews.employee ? this.lookupMapService.mapEmployee(reviews.employee) : null;
    let entries: EmployeeRosterRecord[] = [];
    _.each(reviews.records, (r: IEmployeeRosterRecord) => {
      let review = this.pmMapService.mapToEmployeeReview(r);
      entries.push(review);
    });
    container.records = entries;

    return container;
  }

  public mapToEmployeeIncidentsSubSection(workersComp: IEmployeeSectionsSubsectionContainer<IEmployeeIncidents>, meta: FieldsMeta): EmployeeIncidents {

    let container = new EmployeeIncidents();
    let entries: WcIncidentRosterEntry[] = [];
    _.each(workersComp.data.records, (r: IWcIncidentRosterEntry) => {
      let entry = this.wcMapService.mapInsidentRosterEntry(r, meta);
      entries.push(entry);
    });
    container.records = entries;
    container.actions = this.metaMapService.mapActions(meta);
    return container;
  }

  public mapToEmployeeIncidents(workersComp: IEmployeeIncidents, meta: FieldsMeta): EmployeeIncidents {

    let container = new EmployeeIncidents();
    let entries: WcIncidentRosterEntry[] = [];
    _.each(workersComp.records, (r: IWcIncidentRosterEntry) => {
      let entry = this.wcMapService.mapInsidentRosterEntry(r, meta);
      entries.push(entry);
    });
    container.records = entries;
    container.actions = this.metaMapService.mapActions(meta);
    return container;
  }



  public mapToTimeclocks(dto: IEmployeeSectionTimeclockContainer, meta: FieldsMeta): EmployeeSectionTimeclockContainer {
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    let timeclocks: EmployeeSectionTimeclockContainer = new EmployeeSectionTimeclockContainer();
    let empBadge: EmployeeBadge = null;
    if (dto.badgeId) {
      empBadge = new EmployeeBadge();
      empBadge.badge = dto.badgeId.badge;
      empBadge.employeeId = dto.badgeId.employeeId;
    }
    timeclocks.badgeId = this.metaMapService.mapMeta<EmployeeBadge>(metaMap, 'badgeId', empBadge, empBadge ? empBadge.badge : null);
    let pinNumber: string = null;
    if (dto.pin) {
      pinNumber = dto.pin;
    }
    timeclocks.pinNumber = this.metaMapService.mapMeta<string>(metaMap, 'pinNumber', pinNumber, pinNumber);
    let restriction: TimeclockRestrictionDefinition = null;
    if (dto.restriction) {
      restriction = new TimeclockRestrictionDefinition();
      restriction.id = dto.restriction.id;
      restriction.name = dto.restriction.name;
    }
    timeclocks.restriction = this.metaMapService.mapMeta<TimeclockRestrictionDefinition>(metaMap, 'restriction', restriction, restriction ? restriction.name : null);
    timeclocks.timeclockAssignments = this.metaMapService.mapMeta<any>(metaMap, 'timeclockAssignments', null, null);
    timeclocks.multiPositionPunch = dto.multiPositionPunch;
    timeclocks.isPositionsMultiPunchEnabled = dto.isPositionsMultiPunchEnabled;
    let slateProfile: TimeclockSlateProfileDefinition = null;
    if (dto.slateProfile) {
      slateProfile = dto.slateProfile;
    }
    timeclocks.slateProfile =  this.metaMapService.mapMeta<TimeclockSlateProfileDefinition>(metaMap, 'slateProfile', slateProfile, slateProfile ? slateProfile.name : null);
    timeclocks.slateProfileEnabled = dto.slateProfileEnabled;
    timeclocks.agencyPrefix = dto.agencyPrefix;
    timeclocks.homeOrgLevelId = dto.homeOrgLevelId;
    timeclocks.records = _.map(dto.records, (item: IEmployeeSectionTimeclock) => this.mapToTimeclock(item));
    timeclocks.actions = this.metaMapService.mapActions(meta);
    return timeclocks;
  }

  public mapToTimeclocksDto(data: EmployeeSectionTimeclockContainer): IEmployeeSectionTimeclockContainer {
    const itemsToSave: EmployeeSectionTimeclock[] = _.filter(data.records, (item: EmployeeSectionTimeclock): boolean => { return item.assigned; });
    const recordsDto: IEmployeeSectionTimeclock[] = _.map(itemsToSave, (item: EmployeeSectionTimeclock) => this.mapToTimeclockDto(item));
    const badge = data.badgeId.fieldValue ? data.badgeId.fieldValue : null;
    const restriction = data.restriction.fieldValue ? data.restriction.fieldValue : null;
    const pinNumber = data.pinNumber.fieldValue ? data.pinNumber.fieldValue : null;
    const slateProfile = data.slateProfile.fieldValue ? data.slateProfile.fieldValue : null;
    const dto: IEmployeeSectionTimeclockContainer = {
      badgeId: badge,
      agencyPrefix: data.agencyPrefix,
      pin: pinNumber,
      restriction: restriction,
      homeOrgLevelId: data.homeOrgLevelId,
      records: recordsDto,
      multiPositionPunch: data.multiPositionPunch,
      slateProfile: slateProfile,
      slateProfileEnabled: data.slateProfileEnabled
    };
    return dto;
  }

  public mapTimeclockAssignmentRestriction(dto: ITimeclockAssignmentRestriction): TimeclockAssignmentRestriction {
    Assert.isNotNull(dto, 'ITimeclockAssignmentRestriction');
    let data: TimeclockAssignmentRestriction = new TimeclockAssignmentRestriction();
    data.timeclock = this.lookupMapService.mapTimeclockDefinition(dto.timeclock);
    data.lastUpdateDate = dateTimeUtils.convertFromDtoDateTimeString(dto.lastUpdateDate);
    data.restriction = dto.restriction ? this.lookupMapService.mapTimeclockRestrictionDefinition(dto.restriction) : { id: 0, name: 'None' };
    return data;
  }



  private mapToTimeclock(dto: IEmployeeSectionTimeclock): EmployeeSectionTimeclock {
    let tc: EmployeeSectionTimeclock = new EmployeeSectionTimeclock();
    tc.lastPunch = dto.lastPunch;
    tc.lastDelivery = dto.lastDelivery;
    tc.lastDeliveryFP = dto.lastDeliveryFP;
    tc.lastCommunicationDate = dto.lastCommunicationDate;
    tc.organization = this.lookupMapService.mapOrganization(dto.organization);
    tc.department = this.lookupMapService.mapDepartment(dto.department);
    tc.assignmentRestriction = this.mapTimeclockAssignmentRestriction(dto.assignmentRestriction);
    return tc;
  }

  private mapToTimeclockDto(data: EmployeeSectionTimeclock): IEmployeeSectionTimeclock {
    let dto: IEmployeeSectionTimeclock = {
      assignmentRestriction: {
        timeclock: this.lookupMapService.mapTimeclockDefinitionDto(data.assignmentRestriction.timeclock),
        restriction: data.assignmentRestriction.restriction,
        lastUpdateDate: dateTimeUtils.convertToDtoString(data.assignmentRestriction.lastUpdateDate)
      },
      organization: data.organization ? this.lookupMapService.mapOrganizationDto(data.organization) : null,
      department: data.department ? this.lookupMapService.mapDepartmentDto(data.department) : null,
      lastPunch: data.lastPunch,
      lastDelivery: data.lastDelivery,
      lastDeliveryFP: data.lastDeliveryFP,
      lastCommunicationDate: data.lastCommunicationDate
    };
    return dto;
  }

  public mapToEmployeeACA(aca: IEmployeeSectionsACA, metadata: FieldsMeta): EmployeeSectionsACA {
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(metadata);
    let container = new EmployeeSectionsACA();

    let records: EmployeeACA[] = [];
    _.each(aca.records, (r: IEmployeeACA) => {
      let acarecord = this.mapToACA(r,metaMap);
      records.push(acarecord);
    });
    container.records = records;
    container.actions = this.metaMapService.mapActions(metadata);

    return container;
  }

  private mapToACA(dto: IEmployeeACA,metaMap: StringMap<IFieldData>): EmployeeACA {
    return this.mapACA(dto,metaMap);
  }

  public mapToEmployeeAcaDTO(dto: EmployeeACA): any {
    let aca: EmployeeAcaDto = new EmployeeAcaDto();
    aca.id = dto.id;
    aca.empId = dto.empId;
    aca.startDate = dto.startDate ? moment(dto.startDate).format(appConfig.dateFormat) : null;
    aca.endDate = dto.endDate ? moment(dto.endDate).format(appConfig.dateFormat) : null;
    aca.isEmployee = dto.nonEmployee === 'No' ? true : false;
    aca.isSystemCalculated = dto.incomeCalculationType === 'System-Calculated' ? true : false;
    aca.annualIncome = dto.annualIncome.fieldValue;
    aca.deleted = dto.deleted;
    aca.isDeclinedOffer = dto.declinedOffer === 'Yes' ? true : false;
    aca.isMarketplacePurchase = dto.marketplacePurchase === 'Yes' ? true : false;
    return aca;
  }

  private mapACA(dto: any , metaMap: StringMap<IFieldData> ): EmployeeACA {

    let aca: EmployeeACA = new EmployeeACA();
    aca.id = dto.id;
    aca.empId = dto.empId;
    aca.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    aca.endDate = dto.endDate ? dateTimeUtils.convertFromDtoString(dto.endDate) : null;
    aca.isEmployee = dto.isEmployee;
    aca.nonEmployee = dto.isEmployee ? 'No' : 'Yes';
    aca.isSystemCalculated = dto.isSystemCalculated;
    aca.incomeCalculationType = dto.isSystemCalculated ? 'System-Calculated' : 'Employee-Provided';
    aca.annualIncome = this.metaMapService.mapMeta<number>(metaMap, 'annualHouseholdIncome',dto.annualIncome , '*******');
    aca.modifiedBy = dto.modifiedBy ? dto.modifiedBy : null;
    aca.deleted = dto.deleted;
    aca.modifiedDate = dto.modifiedDate ? dateTimeUtils.convertFromDtoString(dto.modifiedDate) : null;
    aca.declinedOffer = dto.isDeclinedOffer ? 'Yes' : 'No';
    aca.marketplacePurchase = dto.isMarketplacePurchase ? 'Yes' : 'No';

    return aca;
  }
}
