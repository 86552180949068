<div class="dropdown" [ngClass]="{'open': columnsMenuOpened}">
  <button class="slx-button slx-with-icon slx-mobile-adapted" (click)="toggleColumnsMenu()" type="button" aria-haspopup="true" aria-expanded="true">
    <i class="fa fa-filter slx-button__icon" aria-hidden="true"></i>
    <span class="slx-button__text">Columns</span>
  </button>
  <div class="dropdown-menu" aria-labelledby="group-activities">
    <span class="title"><strong>Columns to display</strong></span>
    <div *ngFor="let column of this.settings.columns; let idx=index;" class="checkbox-height">
      <slx-input-decorator className="slx-form-group slx-no-error slx-no-border slx-no-label slx-small-font">
          <slx-checkbox-input [attr.id]="idx" slx-input [(ngModel)]="column.displayed" (click)="onChange($event, column)"
            caption="{{column.description}}" placeholder=""></slx-checkbox-input>
      </slx-input-decorator>
    </div>
    <div class="button-container">
      <button class="btn btn-default" type="button" (click)="selectAll($event)">
        Select All
      </button>
      <button class="btn btn-default" type="button" (click)="clearAll($event)">
        Clear All
      </button>
    </div>
  </div>
</div>

