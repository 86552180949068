<div [ngClass]="{'field-container':true, 'table-record': true, 'alternate-row': rowIndex%2 == 0}">
    <div class="col absence-actions"  *ngIf="selectable">

        <div class="slx-form-group slx-no-border small-font">
            <div class="slx-input-group">
                <div class="slx-form-control">
                    <input [attr.id]="'selectRow'+rowIndex" type="checkbox" [checked]="item.isSelected" (click)="onItemSelect()">
                    <label class="checkbox-label" [attr.for]="'selectRow'+rowIndex">
                                    <span class="check"></span>
                                    <span class="box"></span>
                                </label>
                </div>
            </div>
        </div>
    </div>
    <div class="col absence-edit" *ngIf="editable">
        <button class="edit-button" (click)="onEditClick ()"></button>
    </div>
    <div class="col absence-code">
        <span class="field-label">Code: </span> {{ item.code }}
    </div>

    <div class="col absence-description">
        <span class="field-label">Description: </span> {{ item.description }}
    </div>

    <div class="col absence-color color-border" [style.background-color]="item.color | DecHex"></div>

    

    <div class="col absence-paid">
        <span class="field-label">Paid: </span>
        <span *ngIf="item.isPaid"><i class="fa fa-check" aria-hidden="true"></i></span>
    </div>

    <div class="col absence-pto">
        <span class="field-label">PTO Planner: </span>
        <span *ngIf="item.ptoPlannerIndicator"><i class="fa fa-check" aria-hidden="true"></i></span>
    </div>

    <div class="col absence-loa">
        <span class="field-label">LOA: </span>
        <span *ngIf="item.loaIndicator"><i class="fa fa-check" aria-hidden="true"></i></span>
    </div>
    <div class="col absence-ess">
        <span class="field-label">ESS: </span>
        <span *ngIf="item.essPresent"><i class="fa fa-check" aria-hidden="true"></i></span>
    </div>

    <div class="col absence-ta">
        <span class="field-label">TA Code(s): </span> {{ item.taCode }}
    </div>

    <div class="col absence-updated" *ngIf="item.lastUpdateDate">{{ item.lastUpdateDate | amDateFormat: appConfig.dateFormat }}</div>
    <div class="col absence-updated-by">{{ item.lastUpdateUsername }}</div>

</div>
