var ImportedFiles = /** @class */ (function () {
    function ImportedFiles() {
    }
    Object.defineProperty(ImportedFiles.prototype, "getStatus", {
        get: function () {
            switch (this.status) {
                case 'In-Progress':
                    return 'In Process';
                case 'Success':
                    return 'Uploaded';
                case 'Failed':
                    return 'Failed';
                default:
                    return this.status;
            }
        },
        enumerable: true,
        configurable: true
    });
    return ImportedFiles;
}());
export { ImportedFiles };
var ExcelData = /** @class */ (function () {
    function ExcelData() {
    }
    return ExcelData;
}());
export { ExcelData };
