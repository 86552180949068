import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { FieldsMeta, ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

import { schedulerConfig } from '../../scheduler.config';
import { OpenShiftManagementMapService } from './open-shift-management-map.service';

import {
  OpenShiftShortDetailsSetWrapper,
  OpenShiftSummary, IOpenShiftSummary,
  OpenShiftShortDetailsContainer, ISetOpenShiftCountRequest
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';


@Injectable()
export class OpenShiftManagementApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private openShiftManagementMapServiceMapService: OpenShiftManagementMapService,
    private urlParamsService: UrlParamsService) {
  }

  public getOpenShiftScheduleCycleSummary(orgLevelId: number, startDate: Date, filters:any = null): Promise<OpenShiftShortDetailsSetWrapper> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    const url: string = this.getOrgLevelApiForCycleOpenShiftRoot(orgLevelId, startDate);
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, filters);
    let promise: Promise<OpenShiftShortDetailsSetWrapper> = this.apiUtilService.request<OpenShiftShortDetailsContainer, Meta>(request)
      .then((response: ResponseBody<OpenShiftShortDetailsContainer, Meta>) => this.openShiftManagementMapServiceMapService.mapOpenShiftShortDetailsSetToWrapper(response.data));
    return promise;
  }

  public setOpenShiftScheduleCycleSummary(orgLevelId: number, dateOn: Date, openshiftCountRequest: ISetOpenShiftCountRequest): Promise<OpenShiftShortDetailsSetWrapper> {
    Assert.isNotNull(openshiftCountRequest, 'openshiftCountRequest');
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');
    const url: string = this.getOrgLevelApiForOpenShiftCount(orgLevelId, dateOn);
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, null, openshiftCountRequest);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public getOpenShiftSummary(orgLevelId: number, dateOn: Date, filters: any = null): Promise<OpenShiftSummary> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');
    const url: string = this.getOrgLevelApiRoot(orgLevelId, dateOn);
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, filters);
    let promise: Promise<OpenShiftSummary> = this.apiUtilService.request<IOpenShiftSummary, Meta>(request)
      .then((response: ResponseBody<IOpenShiftSummary, Meta>) => this.openShiftManagementMapServiceMapService.mapOpenShiftDetailsSummary(response.data));
    return promise;
  }

  public getOpenShiftDetails(orgLevelId: number, dateOn: Date): Promise<OpenShiftSummary>{
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(dateOn, 'dateOn');
    const url: string = this.getOrgLevelApiRoot(orgLevelId, dateOn);
    
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.openShiftManagementMapServiceMapService.mapOpenShiftsDetails(response.data)
      );

    return promise;
  }
  
  private getOrgLevelDenyOpenShiftDetails(shiftId:number):string{
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.orglevel.openShift.staffing}/${schedulerConfig.api.schedule.orglevel.openShift.shift}/${shiftId}`;
   
  }
  private getOrgLeveApiForSetOpenShift(alias:string,partnerId:string,shiftId:number,empId:number): string{
  
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.orglevel.openShift.staffing}/${schedulerConfig.api.schedule.orglevel.openShift.customer}/${alias}/${schedulerConfig.api.schedule.orglevel.openShift.partner}/${partnerId}/${schedulerConfig.api.schedule.orglevel.openShift.shift}/${shiftId}/${schedulerConfig.api.schedule.orglevel.employee}/${empId}`;
    
  }
  private getOrgLevelApiForOpenShiftCount(orgLevelId: number, dateOn: Date): string {
    return `${this.getApiRoot()}/${dateTimeUtils.convertToDtoString(dateOn)}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}/${schedulerConfig.api.schedule.orglevel.openShift.root}/${schedulerConfig.api.schedule.orglevel.openShift.count}`;
  }

  private getOrgLevelApiForOpenShiftRoot(orgLevelId: number, startDate: Date): string {
    return `${this.getApiRoot()}/${dateTimeUtils.convertToDtoString(startDate)}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}/${schedulerConfig.api.schedule.orglevel.openShift.root}`;
  }

  private getOrgLevelApiForCycleOpenShiftRoot(orgLevelId: number, startDate: Date): string {
    return `${this.getApiRoot()}/${schedulerConfig.api.schedule.cycle}/${dateTimeUtils.convertToDtoString(startDate)}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}/${schedulerConfig.api.schedule.orglevel.openShift.root}`;
  }

  private getOrgLevelApiRoot(orgLevelId: number, dateOn: Date): string {
    return `${this.getApiRoot()}/${dateTimeUtils.convertToDtoString(dateOn)}/${schedulerConfig.api.schedule.orglevel.root}/${orgLevelId}/${schedulerConfig.api.schedule.orglevel.openShift.root}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}`;
  }
}
