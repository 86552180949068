<div [ngClass]="decoratorClass">
  <label class="slx-label">{{titlename}}</label>
  <div class="slx-input-group kendo kendo-common kendo-default">
    <div *ngIf="hideFieldChild">
      <span *ngIf="!inputPolicy.isVisible" class="slx-hidden">** Hidden **</span>
      <span *ngIf="inputPolicy.isMasked" class="slx-masked">{{inputPolicy.rawValue}}</span>
    </div>
    <ng-content selector="[slx-input],[slx-input-alt]"></ng-content>
  </div>
  <ng-content select="[slx-input-suffix]"></ng-content>
  <ng-content select="[slx-input-hint]"></ng-content>
  <span *ngIf="fieldChild?.ngControl && (fieldChild?.ngControl | pending)" class="slx-pending">
    <i class="far fa-clock" aria-hidden="true"></i>
  </span>
  <div *ngIf="!hideFieldChild && fieldChild?.ngControl" error-messages [messages]="errorMessages" [errors]="fieldChild?.ngControl | errors"
    class="slx-error-block" [ngClass]="{'slx-readonly': fieldChild?.readonly}">
    <ng-content select="[errorMessage]"></ng-content>
  </div>
</div>
