import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../app.config';

export interface IGeolocationPlace {
  location: { lat: number, lng: number };
  lat: number;
  lng: number;
}

export class GeolocationPlace {
  public get lat(): number {
    return this.place.lat;
  }

  public get lng(): number {
    return this.place.lng;
  }

  public location: { lat: number, lng: number };

  constructor(private place: any) {
    this.location = { lat: this.place.lat, lng: this.place.lng };
  }
}

export class ViewModeSettings {
  public zoom: number;
  public lat: number;
  public lng: number;

  constructor(lat: number, lng: number, z: number) {
    this.lat = lat;
    this.lng = lng;
    this.zoom = z;
  }
}

export class BasicModeSettings {
  public circleRadius: number;
  public zoom: number;

  constructor(r: number, z: number) {
    this.circleRadius = r;
    this.zoom = z;
  }
}

export class SearchModeSettings extends BasicModeSettings {
  public addressForSearch: string;

  constructor(s: string, r: number, z: number) {
    super(r, z);
    this.addressForSearch = s;
  }
}

export class CoordsModeSettings extends BasicModeSettings {
  public lat: number;
  public lng: number;

  constructor(lat: number, lng: number, r: number, z: number) {
    super(r, z);
    this.lat = lat;
    this.lng = lng;
  }
}

export class MapCircleChangedEvent {
  constructor(public lat: number, public lng: number, public dragged: boolean) {}
}

export interface IGeolocationEntityDTO {
  id: number;
  orgLevelId: number;
  description: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  distance: number;
  unitOfMeasure: number;
  overriddenLatitude: number;
  overriddenLongitude: number;
  overriddenDistance: number;
  overriddenUnitOfMeasure: number;
  isOverridden: boolean;
  isActive: boolean;
  createdBy: string;
  createdAt: string;
  isAllowPunchWhenLocationServiceDisabled: boolean;
}

export interface INewGeolocationEntityDTO {
  orgLevelId: number;
  description: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  latitude: number;
  longitude: number;
  distance: number;
  unitOfMeasure: number;
  overriddenLatitude: number;
  overriddenLongitude: number;
  overriddenDistance: number;
  overriddenUnitOfMeasure: number;
  isOverridden: boolean;
  isActive: boolean;
  isAllowPunchWhenLocationServiceDisabled: boolean;
}

export class GeolocationEntity {
  public id: number = 0;
  public orgLevelId: number = 0;
  public orgLevelName: string = '';
  public description: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: string;
  public latitude: number = 0;
  public longitude: number = 0;
  public distance: number = 0;
  public unitOfMeasure: number = 1;
  public overriddenLatitude: number = 0;
  public overriddenLongitude: number = 0;
  public overriddenDistance: number = 0;
  public overriddenUnitOfMeasure: number = 0;
  public isOverridden: boolean = false;
  public isActive: boolean = true;
  public createdBy: string;
  public createdAt: Date;
  public isAllowPunchWhenLocationServiceDisabled: boolean = true;
  public get currentLat(): number {
    return this.isOverridden ? this.overriddenLatitude : this.latitude;
  }

  public set currentLat(v: number) {
    if (this.isOverridden) {
      this.overriddenLatitude = v;
      return;
    }
    this.latitude = v;
  }

  public get currentLng(): number {
    return this.isOverridden ? this.overriddenLongitude : this.longitude;
  }

  public set currentLng(v: number) {
    if (this.isOverridden) {
      this.overriddenLongitude = v;
      return;
    }
    this.longitude = v;
  }

  public get currentDistance(): number {
    return this.isOverridden ? this.overriddenDistance : this.distance;
  }

  public set currentDistance(v: number) {
    if (this.isOverridden) {
      this.overriddenDistance = v;
      return;
    }
    this.distance = v;
  }

  public get currentUnitOfMeasure(): number {
    return this.isOverridden ? this.overriddenUnitOfMeasure : this.unitOfMeasure;
  }

  public set currentUnitOfMeasure(v: number) {
    if (this.isOverridden) {
      this.overriddenUnitOfMeasure = v;
      return;
    }
    this.unitOfMeasure = v;
  }

  private readonly storedDistance: Map<string, string> = new Map<string, string>();
  public get localizedCurrentDistance(): string {
    const key = `${this.currentDistance}_${this.currentUnitOfMeasure}`;
    if (this.storedDistance.has(key)) {
      return this.storedDistance.get(key);
    }
    const units = ['Meters', 'Yards'];
    let unitOfMeasure = 'Unknown type';
    if (this.currentUnitOfMeasure > 0) {
      unitOfMeasure = units[this.currentUnitOfMeasure - 1];
    }
    const distance = `${this.currentDistance} ${unitOfMeasure}`;
    this.storedDistance.set(key, distance);

    return distance;
  }

  private readonly storedDates: Map<Date, string> = new Map<Date, string>();
  public get localizedCreatedDate(): string {
    if (this.storedDates.has(this.createdAt)) {
      return this.storedDates.get(this.createdAt);
    }
    const date = moment(this.createdAt).format(appConfig.dateFormat);
    this.storedDates.set(this.createdAt, date);
    return date;
  }

  public location(): { lat: number; lng: number; } {
    return { lat: this.currentLat, lng: this.currentLng };
  }
}

export type GeolocationEventsTypes = 'ADD' | 'UPDATE' | 'SAVE' | 'CANCEL' | 'PDF' | 'EXCEL';

export class GeolocationEvents {
  public static readonly ADD: GeolocationEventsTypes = 'ADD';
  public static readonly UPDATE: GeolocationEventsTypes = 'UPDATE';
  public static readonly SAVE: GeolocationEventsTypes = 'SAVE';
  public static readonly CANCEL: GeolocationEventsTypes = 'CANCEL';
  public static readonly PDF: GeolocationEventsTypes = 'PDF';
  public static readonly EXCEL: GeolocationEventsTypes = 'EXCEL';
}

export class IGeolocationEvent {
  data: any;
  isAdd: boolean;
  isUpdate: boolean;
  isSave: boolean;
  isCancel: boolean;
  isExportToPDF: boolean;
  isExportToExcel: boolean;
}

export class GeolocationEvent {
  public static createAdd(data: any = null): IGeolocationEvent {
    return new GeolocationEvent(GeolocationEvents.ADD, data);
  }
  public static createUpdate(data: any = null): IGeolocationEvent {
    return new GeolocationEvent(GeolocationEvents.UPDATE, data);
  }
  public static createSave(data: any = null): IGeolocationEvent {
    return new GeolocationEvent(GeolocationEvents.SAVE, data);
  }
  public static createCancel(data: any = null): IGeolocationEvent {
    return new GeolocationEvent(GeolocationEvents.CANCEL, data);
  }
  public static createPDF(data: any = null): IGeolocationEvent {
    return new GeolocationEvent(GeolocationEvents.PDF, data);
  }
  public static createExcel(data: any = null): IGeolocationEvent {
    return new GeolocationEvent(GeolocationEvents.EXCEL, data);
  }

  constructor(private m_type: GeolocationEventsTypes, private m_data: any) {}

  public get data(): any {
    return this.m_data;
  }
  public get isAdd(): boolean {
    return this.m_type === GeolocationEvents.ADD;
  }
  public get isUpdate(): boolean {
    return this.m_type === GeolocationEvents.UPDATE;
  }
  public get isSave(): boolean {
    return this.m_type === GeolocationEvents.SAVE;
  }
  public get isCancel(): boolean {
    return this.m_type === GeolocationEvents.CANCEL;
  }
  public get isExportToPDF(): boolean {
    return this.m_type === GeolocationEvents.PDF;
  }
  public get isExportToExcel(): boolean {
    return this.m_type === GeolocationEvents.EXCEL;
  }
}

export class GeolocationDialogOptions {
  public latitude: number;
  public longitude: number;
  public mapWidth: number;
  public mapHeight: number;
  public mapZoom: number;

  constructor(latitude: number, longitude: number, mapWidth: number = 300, mapHeight: number = 200, mapZoom: number = 15) {
    this.latitude = latitude;
    this.longitude = longitude;
    this.mapWidth = mapWidth;
    this.mapHeight = mapHeight;
    this.mapZoom = mapZoom;
  }
}
