import * as _ from 'lodash';
var SwitcherComponent = /** @class */ (function () {
    function SwitcherComponent() {
        this.onChangeCallback = _.noop;
        this.onTouchedCallback = _.noop;
    }
    Object.defineProperty(SwitcherComponent.prototype, "hasLabel", {
        get: function () {
            return _.isString(this.label) && _.size(this.label) > 0;
        },
        enumerable: true,
        configurable: true
    });
    SwitcherComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    SwitcherComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    SwitcherComponent.prototype.writeValue = function (value) {
        if (!_.isUndefined(value) && !_.isNull(value)) {
            this.checked = value;
        }
    };
    SwitcherComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    SwitcherComponent.prototype.onValueChanged = function (value) {
        this.onChangeCallback(value);
    };
    return SwitcherComponent;
}());
export { SwitcherComponent };
