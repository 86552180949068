import { Component, Input } from '@angular/core';
import { ICollapssiblePanel } from '../../../../common/components/collapsible-panel/collapsible-panel.component';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { SummarySectionBase } from '../summary-section-base/summary-section.base';

@Component({
    moduleId: module.id,
    selector: 'slx-timecard-summary-panel-header',
    templateUrl: 'timecard-summary-panel-header.component.html',
    styleUrls: ['timecard-summary-panel-header.component.scss']
})

export class TimecardSummaryPanelHeaderComponent {
    @Input()
    public panel: ICollapssiblePanel;

    @Input()
    public component: SummarySectionBase;

    constructor(private management: TimecardsSummaryManagementService) {

    }

    public onExportExel(): void {
        this.component.exportToExel ();
    }

    public onExportPdf(): void {
        this.component.exportToPdf ();
    }
}
