import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ResponseBody, Meta, EditResult } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { SlxHttpRequest } from '../../../../core/models/index';
import { payUnitsConfig } from '../../pay-units.config';
import { PayUnitsMassAssignmentMapService } from './pay-units-mass-assignment-map.service';
import { dateTimeUtils } from '../../../../common/utils/index';
import {
  IMassAssignPayUnits, MassAssignPayUnits, IMassAssignPayUnitsPayload
} from '../../models/index';

@Injectable()
export class PayUnitsMassAssignmentApiService {
  constructor(
    private mapService: PayUnitsMassAssignmentMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public async getMassAssignPayUnits(orgLevelId: number, startDate: Date, endDate: Date, empIds: number[]): Promise<MassAssignPayUnits> {
    const url: string = `${this.getApiUrl()}/${payUnitsConfig.massAssignment}`;
    const params = {
      orgLevelId: orgLevelId,
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate),
      empIds: empIds
    };
    const request = this.urlParamsService.createGetRequest(url, params, true);
    const response = await this.apiUtilService.request<IMassAssignPayUnits, Meta>(request);
    return this.mapService.mapToMassAssignPayUnits(response.data);
  }

  public async saveMassAssignPayUnits(orgLevelId: number, payload: IMassAssignPayUnitsPayload): Promise<any> {
    const url: string = `${this.getApiUrl()}/${payUnitsConfig.massAssignment}`;

    const params = {
      orgLevelId: orgLevelId
    };
    const request = this.urlParamsService.createPutRequest(url, payload,  params);
    const response = await this.apiUtilService.request<IMassAssignPayUnits, Meta>(request);
  }

  public getApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${payUnitsConfig.ta}/${payUnitsConfig.payunits}`;
  }

}

