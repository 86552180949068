import * as tslib_1 from "tslib";
import { OnInit, Provider } from '@angular/core';
import { Observable } from 'rxjs';
import { mutableSelect } from './../../../../../app/core/decorators';
import { Lookup } from './../../../../../app/organization';
import { ScheduleRotationsManagementService } from './../../../../../app/scheduler/services/scheduled-rotation-template/schedule-rotations-management.service';
import * as moment from 'moment';
import { ChangeManagementService, DeviceDetectorService, ModalService, } from './../../../../../app/common';
import { DialogOptions2, DialogModeSize, DialogOptions } from '../../../../common/models/index';
import { ScheduledGenerateRotationComponent } from '../scheduled-generate-rotation/scheduled-generate-rotation.component';
import { ScheduledRotationMapService } from './../../../../../app/scheduler/services/scheduled-rotation-template/scheduled-rotation-map.service';
var ScheduledRotationsToolbarComponent = /** @class */ (function () {
    function ScheduledRotationsToolbarComponent(manService, devDetector, modalServices, changeManagementService, mapService) {
        var _this = this;
        this.manService = manService;
        this.devDetector = devDetector;
        this.modalServices = modalServices;
        this.changeManagementService = changeManagementService;
        this.mapService = mapService;
        this.isActionListActive = true;
        this.min = new Date(2000, 0, 1);
        this.max = new Date(2079, 11, 31);
        this.cycles = [];
        this.dateFrom = moment().toDate();
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevelId = orgLevel.id;
        });
        this.modalService = modalServices;
        this.manService.isDragabble$.subscribe(function (isDisabled) {
            _this.saveDisable = isDisabled;
        });
        this.manService.payCycle.subscribe(function (dateRange) {
            _this.startDate = moment(dateRange.startDate).format('MM/DD/YYYY');
            _this.endDate = moment(dateRange.endDate).format('MM/DD/YYYY');
        });
        this.manService.filterRotationsRecord$.subscribe(function (data) {
            _this.filterOptions = _this.manService.filterRotationsRecord;
        });
    }
    Object.defineProperty(ScheduledRotationsToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledRotationsToolbarComponent.prototype, "toolbarWidth", {
        get: function () {
            return this.isDesktop ? 250 : 150;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledRotationsToolbarComponent.prototype.ngOnInit = function () {
        this.filterOptions = this.manService.filterRotationsRecord;
        this.manService.defaultToolbarFilterOptions = this.manService.generatePositionOptionsCombinations(this.manService.filterRotationsRecord.positionOptions);
        this.manService.getSessionStorageforGenerateRotation();
    };
    ScheduledRotationsToolbarComponent.prototype.saveRotations = function () {
        this.manService.saveRotations(this.orgLevelId);
        this.changeManagementService.clearChanges();
    };
    ScheduledRotationsToolbarComponent.prototype.exportRotations = function () {
        this.manService.exportRotation$.next(true);
    };
    ScheduledRotationsToolbarComponent.prototype.generateRotation = function () {
        var _this = this;
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ModalService,
                useValue: this.modalService
            },
            {
                provide: ScheduleRotationsManagementService,
                useValue: this.manService
            }
        ];
        this.modalService.globalAnchor.openDialog2(ScheduledGenerateRotationComponent, 'Generate Rotation', dialogOptions, resolvedProviders, function (result) {
            if (result) {
                _this.manService.saveGenerateRotation();
            }
        });
    };
    Object.defineProperty(ScheduledRotationsToolbarComponent.prototype, "disableGenerateRotation", {
        get: function () {
            return this.manService.generateRotationRecord && this.manService.generateRotationRecord.empIds.length > 0 ? false : true;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledRotationsToolbarComponent.prototype.onSettingsChanged = function (popperContent, isApply) {
        popperContent.hide();
        if (!isApply) {
            this.manService.isToolbarFilterApplied$.next(false);
            return;
        }
        else {
            this.manService.isToolbarFilterApplied$.next(true);
            this.manService.onFilterChange();
        }
    };
    ScheduledRotationsToolbarComponent.prototype.checkedChange = function (filterOption, filterArray) {
        filterArray.map(function (x) { return x.id == filterOption.id ? x.isSelected = true : x.isSelected = false; });
    };
    ScheduledRotationsToolbarComponent.prototype.checkAllChange = function (showSecondaryPosition, event) {
        showSecondaryPosition.isSelectAll = event;
        showSecondaryPosition.options.map(function (x) { return x.isSelected = event; });
    };
    ScheduledRotationsToolbarComponent.prototype.checkBoxChange = function (showSecondaryPosition, filterName, property, event) {
        var ind = filterName.findIndex(function (x) { return x.name === property; });
        filterName[ind].isSelected = event;
        showSecondaryPosition.isSelectAll = showSecondaryPosition.options.some(function (x) { return x.isSelected; });
    };
    ScheduledRotationsToolbarComponent.prototype.checkAgencyChange = function (checkAgency, property, event) {
        if (checkAgency.name == property) {
            checkAgency.isSelected = event;
        }
    };
    Object.defineProperty(ScheduledRotationsToolbarComponent.prototype, "isSelectAll", {
        get: function () {
            return this.filterOptions.positionOptions.secondaryPosition.isSelectAll;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduledRotationsToolbarComponent.prototype, "orgLevel$", void 0);
    return ScheduledRotationsToolbarComponent;
}());
export { ScheduledRotationsToolbarComponent };
