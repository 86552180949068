import { StaffingPredictorRatingOption, IStaffingPredictorRatingOptionDTO } from './staffing-predictor-rating-option';

export interface IStaffingOptionsTableDTO {
    startDate: string;
    endDate: string;
    organizationId: number;
    options: IStaffingPredictorRatingOptionDTO[];
}

export class StaffingOptionsTable {
    public startDate: Date;
    public endDate: Date;
    public organizationId: number;
    public options: StaffingPredictorRatingOption[];
}
