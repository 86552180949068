import * as _ from 'lodash';
import { HttpRequest } from '@angular/common/http';

export class SlxHttpRequest<T> {
  public httpRequest: HttpRequest<T>;
  public isBackgroundRequest: boolean;
  public autoContentType: boolean;
  public get url(): string {
    const url: string = _.get(this.httpRequest, 'url') || '';

    return url;
  }

  constructor(httpRequest: HttpRequest<T>, isBackgroundRequest: boolean = false, autoContentType: boolean = false) {
    this.httpRequest = httpRequest;
    this.isBackgroundRequest = isBackgroundRequest;
    this.autoContentType = autoContentType;
  }
}
