/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./configure-cost-centers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./cost-centers-grid/cost-centers-grid.component.ngfactory";
import * as i3 from "./cost-centers-grid/cost-centers-grid.component";
import * as i4 from "../../services/cost-centers/cost-centers-configuration-management.service";
import * as i5 from "./cost-centers-mobile-view/cost-centers-mobile-view.component.ngfactory";
import * as i6 from "./cost-centers-mobile-view/cost-centers-mobile-view.component";
import * as i7 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i8 from "../../../common/components/spinner/spinner.component";
import * as i9 from "./cost-centers-toolbar/cost-centers-toolbar.component.ngfactory";
import * as i10 from "./cost-centers-toolbar/cost-centers-toolbar.component";
import * as i11 from "@angular/common";
import * as i12 from "../../services/accessManagement/access-management.service";
import * as i13 from "../../../common/services/change-management/change-management.service";
import * as i14 from "../../services/cost-centers/cost-centers-api.service";
import * as i15 from "../../services/cost-centers/cost-centers-map.service";
import * as i16 from "../../../organization/services/lookup/lookup-api.service";
import * as i17 from "./configure-cost-centers.component";
import * as i18 from "../../../common/services/modal/modal.service";
var styles_ConfigureCostCentersComponent = [i0.styles];
var RenderType_ConfigureCostCentersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfigureCostCentersComponent, data: {} });
export { RenderType_ConfigureCostCentersComponent as RenderType_ConfigureCostCentersComponent };
function View_ConfigureCostCentersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-cost-centers-grid", [["class", "desktop-grid-component"]], null, null, null, i2.View_CostCentersGridComponent_0, i2.RenderType_CostCentersGridComponent)), i1.ɵdid(1, 114688, null, 0, i3.CostCentersGridComponent, [i4.CostCentersConfigurationManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ConfigureCostCentersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-cost-centers-mobile-view", [["class", "mobile-component"]], null, null, null, i5.View_CostCentersMobileViewComponent_0, i5.RenderType_CostCentersMobileViewComponent)), i1.ɵdid(1, 114688, null, 0, i6.CostCentersMobileViewComponent, [i4.CostCentersConfigurationManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ConfigureCostCentersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i7.View_SpinnerComponent_0, i7.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i8.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-cost-centers-toolbar", [], null, null, null, i9.View_CostCentersToolbarComponent_0, i9.RenderType_CostCentersToolbarComponent)), i1.ɵdid(4, 49152, null, 0, i10.CostCentersToolbarComponent, [i4.CostCentersConfigurationManagementService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigureCostCentersComponent_1)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigureCostCentersComponent_2)), i1.ɵdid(8, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isMobile(); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.isMobile(); _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ConfigureCostCentersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-configure-cost-centers", [], null, null, null, View_ConfigureCostCentersComponent_0, RenderType_ConfigureCostCentersComponent)), i1.ɵprd(512, null, i12.AccessManagementService, i12.AccessManagementService, []), i1.ɵprd(512, null, i4.CostCentersConfigurationManagementService, i4.CostCentersConfigurationManagementService, [i12.AccessManagementService, i13.ChangeManagementService, i14.CostCentersApiService, i15.CostCentersMapService, i16.LookupApiService]), i1.ɵdid(3, 114688, null, 0, i17.ConfigureCostCentersComponent, [i4.CostCentersConfigurationManagementService, i18.ModalService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ConfigureCostCentersComponentNgFactory = i1.ɵccf("slx-configure-cost-centers", i17.ConfigureCostCentersComponent, View_ConfigureCostCentersComponent_Host_0, {}, {}, []);
export { ConfigureCostCentersComponentNgFactory as ConfigureCostCentersComponentNgFactory };
