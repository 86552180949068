import { BenefitDetailsLine } from './benefit-details-line';
var BenefitDetailsLineStandart = /** @class */ (function () {
    function BenefitDetailsLineStandart() {
        this.line = new BenefitDetailsLine();
        this.tiers = [];
    }
    Object.defineProperty(BenefitDetailsLineStandart.prototype, "id", {
        get: function () {
            return this.line.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsLineStandart.prototype, "name", {
        get: function () {
            return this.line.name;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsLineStandart;
}());
export { BenefitDetailsLineStandart };
