import * as _ from 'lodash';

import { Component, OnInit, ViewChild, OnDestroy, ElementRef, ChangeDetectorRef, Output, EventEmitter, Input } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { MessageConversion } from '../../models/message-conversation';
import { Subscription, from, Observable } from 'rxjs';
import { MessagesManagementService } from '../../services/messages-management.service';
import { EmployeeSmsManagementService } from '../../../../../app/configuration/services/employee-sms-management/employee-sms-management.service';
import { IEmployeeSendSms, EmployeeSendSms } from '../../models';
import { ErrorCodeMessageCenter } from '../../constants/error-code.constants';
import { Router } from '@angular/router';
import { SessionService } from '../../../../../app/core/services';
import { unsubscribeAll, mutableSelect, unsubscribe } from '../../../../core/decorators';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { AccessibleApiService } from '../../../../organization/services/accessible/accessible-api.service'
import { AccessibleService } from '../../../../organization/services/accessible/accessible.service';
import { NavigationMenuItem, MenuAccessibleProviderService, LookupApiService } from '../../../../organization';
import { MessageApiService } from '../../services/message-api.service';
import { OrgLevel } from '../../../../state-model/models';
import * as moment from 'moment';
import {SegmentedMessage} from '../../utils/segments-calculate';

@Component({
  moduleId: module.id,
  selector: 'slx-message-content',
  templateUrl: 'message-content.component.html',
  styleUrls: ['message-content.component.scss'],
})
export class MessageContentComponent implements OnInit, OnDestroy {
  public gridState: KendoGridStateHelper<MessageConversion>;
  public isLoading: boolean;
  public messageConversation = [];
  public textmessage: string;
  public message1: string = '';
  public maxCharPerSms = 320;
  public selectedMessage: any;

  public employeeRecordConversionRestriction: boolean;
  public employeeRecordRightsRestriction: boolean;
  public schedulerConversionRestriction: boolean;
  public schedulerRightsRestriction: boolean;
  public timeCardConversionRestriction: boolean;
  public timeCardRightsRestriction: boolean;
  @Input() isMyMessage: boolean = true;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  private appSettings: AppServerConfig;

  searchText = '';
  employeeId = null;
  profileUrl = '';
  errorCodes = ErrorCodeMessageCenter;
  timeCardUrl = '';
  scheduleLinkUrl = '';
  employeeStatus = '';
  employeeLeave = false;
  empOptInStatus = null;
  loggedInUser = '';
  loggedInUserId = null;
  employeeNumber = null;
  markAsRead = true;
  get rawEmployeeNumber(): string {
    if (!this.employeeNumber) return null;
    return `+1${this.employeeNumber.replace(/[\(\)\-\s']+/g, '')}`;
  }
  completeThread = [];
  @Output() getArchiveReadUnreadCount = new EventEmitter<any>();
  @Input() orgLevel: any;
  public usersPicture = {};
  constructor(
    private messageManService: MessagesManagementService,
    private employeeManService: EmployeeSmsManagementService,
    private route: Router,
    private sessionService: SessionService,
    private appSettingsManageService: AppSettingsManageService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private accessibleApiService: AccessibleApiService,
    private accessibleService: AccessibleService,
    private menuAccessibleProviderService: MenuAccessibleProviderService,
    private lookupApi: LookupApiService
  ) {
    this.gridState = new KendoGridStateHelper<MessageConversion>();
    this.gridState.view = null;    
    this.messageConversation=[];
  }

  public appendColdThread():void{
    this.messageManService.appendColdMessageThread(this.selectedMessage,this.selectedMessage.orgLevelId,true,this.isMyMessage);    
  }

  public ngOnInit() {
    this.getAppSettings();
    const constUserDetails = this.sessionService.getUser();
    this.loggedInUser = constUserDetails.name;
    this.loggedInUserId = constUserDetails.id;
    this.subscriptions.subscribeToGetMessageId = this.messageManService.subscribeToGetMessageId(v => {
      this.isLoading = true;
    });

    this.subscriptions.messageFilter = this.messageManService.subscribeToswitchMessageFilter((res: boolean) => {
      this.isMyMessage = res;
    });

    this.subscriptions.coldMessageThread= this.messageManService.subscribeToGetColdMessageThread((v)=>{
      let data=v.conversation;
     
      if(data.length>0)
      this.messageConversation =this.messageConversation.concat(JSON.parse(JSON.stringify(data)));     
  

      if (this.messageConversation.length > 1) {
        this.messageConversation = this.messageConversation.sort(function (a, b) {
          return a.time.localeCompare(b.time);
        });
      }
      const userIds = [];
      for (let c of this.messageConversation) {
        if (c.userName && c.userId != 0) {
          if (!userIds.includes(c.userId))
            userIds.push(c.userId)
        }
      }
      this.usersPicture = {};
      this.messageManService.getEmployeePicsByUserIds(userIds);
      this.employeeId = this.messageConversation[0].employeeId;

      if (this.markAsRead) {
        this.messageManService.updateReadStatusOfthread(this.employeeId);
      }

      this.refreshGrid();
      setTimeout(() => {
        this.scrollBottom();
      }, 200);
    });


    this.subscriptions.messageThread = this.messageManService
      .subscribeToGetMessageThread((v) => {        
        this.renderConversation(v.conversation);
        this.timeCardUrl = `#/apps/time/timecards/employee/${v.employeeData.employeeId}?keepOrgLevelBreadcrmb=true&orgLevelId=${v.employeeData.orgLevelId}`;
        this.profileUrl = `#/apps/common/employee/${v.employeeData.employeeId}/employee_sections?orgLevelId=${v.employeeData.orgLevelId}`;
        this.employeeStatus = v.employeeData.status;
        this.selectedMessage = v.employeeData;
        this.employeeLeave = v.employeeData.isOnLeave;
        this.empOptInStatus = v.employeeData.optIn;
        this.employeeNumber = v.employeeData.mobilePhoneNumber;        
        this.appendColdThread();
        this.message1 = '';
        this.isLoading = false;
        this.checkAccessForEmployeeRecord(v.employeeData.employeeId);
        if (v.employeeData.orgLevelId) {
          this.checkAccessForIndividualTimeCard(v.employeeData.orgLevelId);
          this.checkAccessForIndividualScheduler(v.employeeData.orgLevelId);
        }
        if (this.selectedMessage.archived) {
          this.getArchiveReadUnreadCount.emit(true);
        }
        this.markAsRead = !v.hasOwnProperty('markAsRead') || v.markAsRead;
        setTimeout(() => {
          this.scrollBottom();
        }, 200);
      });
    this.subscriptions.emptyThread = this.messageManService.subscribeEmptyMessageThread((v) => {
      this.completeThread = [];
      this.messageConversation = [];   
    })
    this.refreshGrid();

    this.subscriptions.userPictures = this.messageManService.subscribeToUserPicturesLoaded((res => {
      for (let data of res) {
        this.usersPicture[data['userId']] = data['profilePicture'];
      }
    }));

    this.subscriptions.latestScheduleCycle = this.messageManService.subscribeToLatestScheduleCyleLoaded(res=>{
      if(this.selectedMessage.status != 'Active')  {
        this.scheduleLinkUrl = `#/apps/scheduler/individual_schedule?empID=${this.selectedMessage.employeeId}&orgLevelId=${this.selectedMessage.orgLevelId}&startDate=${res.startDate}&endDate=${res.endDate}`;
      } else {
        this.scheduleLinkUrl = `#/apps/scheduler/individual_schedule?empID=${this.selectedMessage.employeeId}&orgLevelId=${this.selectedMessage.orgLevelId}`;
      }
      window.open(this.scheduleLinkUrl, '_blank');
    });
  }

  ngOnDestroy() {}

  public checkAccessForEmployeeRecord(employeeId: number) {
    let employeeList: NavigationMenuItem = this.menuAccessibleProviderService.getMenuItem("employee_list");
    _.forEach(employeeList.childs, (m: NavigationMenuItem) => {
      if (m.displayName == 'Employee List') {
        this.employeeRecordConversionRestriction = false;
        this.employeeRecordRightsRestriction = false;
      }
    });
  }

  public checkAccessForIndividualTimeCard(orgLevelId: number) {
    this.accessibleApiService.getComponentAccessibleByOrgLevel
      (this.accessibleService.applicationId, 'individual_timecards', orgLevelId)
      .then((res) => {
        this.timeCardConversionRestriction = res.conversionRestriction;
        this.timeCardRightsRestriction = res.rightsRestriction;
      });
  }

  public checkAccessForIndividualScheduler(orgLevelId: number) {
    this.accessibleApiService.getComponentAccessibleByOrgLevel
      (this.accessibleService.applicationId, 'individual_schedule', orgLevelId)
      .then((res) => {
        this.schedulerConversionRestriction = res.conversionRestriction;
        this.schedulerRightsRestriction = res.rightsRestriction;
      })
  }

  renderConversation(v) {
    this.messageConversation=[];
    if (v.length > 1) {
      v = v.sort(function (a, b) {
        return a.time.localeCompare(b.time);
      });
    }
    
    this.messageConversation=JSON.parse(JSON.stringify(v));    
    this.completeThread = JSON.parse(JSON.stringify(v));
    this.refreshGrid();
  }

  public async getAppSettings(): Promise<void> {
    try {
      this.appSettings = await this.appSettingsManageService.getAppServerConfig();
    } catch (e) { }
  }

  public setMessageStatus(type) {
    if (type == "incomming") {
      return false;
    }
    else {
      return true;
    }
  }

  public sendMessage() {
    if (this.isLoading) return;

    this.isLoading = true;

    const employeeSendSms: IEmployeeSendSms = new EmployeeSendSms();
    employeeSendSms.messages = [];
    employeeSendSms.orgLevelId = this.orgLevel.id; 
    employeeSendSms.accountSid = this.appSettings.TwilioAccountSid;
    employeeSendSms.authToken = this.appSettings.TwilioAuthToken;
    employeeSendSms.messages.push({
      body: this.message1,
      fullName: this.selectedMessage.fullName,
      to: this.rawEmployeeNumber,
      from: this.appSettings.TwilioSenderPhone,
      read: true,
      employeeId: this.selectedMessage.employeeId,
      isOptInRequest: false
    });

    this.employeeManService.sendSmsToEmployee(employeeSendSms).then((res) => {
      this.selectedMessage.body = this.message1;
      this.messageManService.refreshMessageList(this.selectedMessage);
      this.message1 = '';
      if (!this.selectedMessage.archived) {
        this.messageManService.updateMessageThread(this.selectedMessage, this.orgLevel.id,true,this.isMyMessage);
      }
    }).finally(() => {
      this.isLoading = false;
    });
  }

  public refreshGrid(): void {
    if (!this.messageConversation) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.messageConversation, this.gridState.state);
  }

  onFilter(val) {
    this.searchText = val;
  }

  getFailedStatus(item) {
    return this.errorCodes[item];
  }

  public navigateToTimeCard(): void {
    this.lookupApi.getPayCyles(this.selectedMessage.orgLevelId, this.selectedMessage.employeeId).then(res => {
      const startDate = moment((res[res.length - 1].startDate)).format('MM-DD-YYYY').toString().replace(/-/g,'');
      const endDate = moment((res[res.length - 1].endDate)).format('MM-DD-YYYY').toString().replace(/-/g,'');
      this.timeCardUrl = `#/apps/time/timecards/employee/${this.selectedMessage.employeeId}?keepOrgLevelBreadcrmb=true&orgLevelId=${this.selectedMessage.orgLevelId}&startDate=${startDate}&endDate=${endDate}&employeeId=${this.selectedMessage.employeeId}`;
      window.open(this.timeCardUrl, '_blank');
    });
  }

  public navigateToProfile(): void {
    window.open(this.profileUrl, '_blank');
  }

  public navigateToschedule(): void {
    this.messageManService.getLatestScheduleCycleByEmployeeId(this.selectedMessage.employeeId);
  }

  public scrollBottom(): void {
    if (!this.elementRef.nativeElement) {
      return;
    }

    const msgListElements = this.elementRef.nativeElement.querySelectorAll('#msg-list');

    if (msgListElements && msgListElements[msgListElements.length - 1]) {
      msgListElements[msgListElements.length - 1].scrollIntoView({
        block: 'nearest'
      });
    }
  }

  public isMessageOptIn(message: string): boolean {
    return this.isMessageOptInOut(message, [
      'accept', 'start'
    ]);
  }

  public isMessageOptOut(message: string): boolean {
    return this.isMessageOptInOut(message, [
      'stop'
    ]);
  }

  private isMessageOptInOut(message: string, options: string[]): boolean {
    if (typeof message !== 'string') {
      return false;
    }

    const parsedMessage = message.toLowerCase().trim();

    return _.includes(options, parsedMessage);
  }

  convertHyperLink(body: string) {
    return body.replace(/(http.*?\s)/g, "<a target='_blank' href=\"$1\">$1</a>")
  }

  public getSegmentCount():string {    
    const encoding = "auto";    
    const segmentedMessage = new SegmentedMessage(this.message1, encoding);
    return segmentedMessage.segments.length == 1? "1 Segment" : segmentedMessage.segments.length + " Segments";   
  }

}
