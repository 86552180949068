import * as tslib_1 from "tslib";
import { ConfigForceLoad, MidnightToMidnightReportData, ReportDefinition } from "../../models";
import { MidnightToMidnightReportDialogComponent } from "../../components/midnight-to-midnight-report-dialog/midnight-to-midnight-report-dialog.component";
import { ModalService } from "./../../../../app/common";
import { Observable, Subscription } from "rxjs";
import { mutableSelect, unsubscribe } from "./../../../../app/core/decorators";
import { ReportsApiService } from "../../services/reports-api.service";
var MidnightToMidnightReportConfigDirective = /** @class */ (function () {
    function MidnightToMidnightReportConfigDirective(modalService, reportApi) {
        var _this = this;
        this.modalService = modalService;
        this.reportApi = reportApi;
        this.configForceLoad = null;
        this.configForceLoad = new ConfigForceLoad();
        this.configForceLoad.midnightToMidnightReportConfig = false;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (data) {
            _this.orgLevelId = data.id;
        });
    }
    MidnightToMidnightReportConfigDirective.prototype.onClick = function (e) {
        if (this.slxMidnightReport.reportConfigId) {
            this.getConfigDetails();
        }
    };
    MidnightToMidnightReportConfigDirective.prototype.getConfigDetails = function () {
        var _this = this;
        var data = new MidnightToMidnightReportData();
        this.reportApi.getMidnightToMidnightConfigData(this.orgLevelId, true).then(function (configData) {
            data.orgLevelId = _this.orgLevelId;
            data.gridData = configData;
            MidnightToMidnightReportDialogComponent.openDialog(data, _this.configForceLoad, _this.modalService, function (isSave, data) { });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], MidnightToMidnightReportConfigDirective.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MidnightToMidnightReportConfigDirective.prototype, "orgLevelSubscription", void 0);
    return MidnightToMidnightReportConfigDirective;
}());
export { MidnightToMidnightReportConfigDirective };
