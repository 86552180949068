import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { TreeNodeModel } from '../tree/index';
import { QueryModel, LogicalExpressionModel, ILogicExpression } from '../query-builder/index';
import { EmployeeSearchApiService, EmployeeSearchMapService } from '../../services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { Observable } from 'rxjs/Observable';
import { mutableSelect } from '../../../../core/decorators/redux-decorators';
import * as _ from 'lodash';
var EmployeeSearchComponent = /** @class */ (function () {
    function EmployeeSearchComponent(service, lookup, mapService) {
        this.api = service;
        this.lookup = lookup;
        this.mapService = mapService;
        this.queryModel = new QueryModel(this.mapService);
    }
    EmployeeSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            displayResults: false,
            queryBuilder: false
        };
        this.state.isLoading = true;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
        });
        var root = this.queryModel.root;
        var children = root.children;
        var firstChildExpression = root.children[0].data.expression;
        this.lookup.getSearchCategories()
            .then(function (categories) {
            _this.queryModel.categories = categories;
            var employeeCategory = _.find(categories, function (cat) {
                return cat.categoryFieldName === 'EmpName';
            });
            if (employeeCategory) {
                firstChildExpression.searchPredicate.searchCategoryName = employeeCategory.categoryFieldName;
                firstChildExpression.searchPredicate.searchOperator = QueryModel.getDefaultOperator(employeeCategory.operators);
            }
            _this.queryModel.setExpanded(root);
            _this.state.isLoading = false;
            _this.state.queryBuilder = true;
            _this.state.displayResults = false;
        })
            .catch(function () {
            _this.state.isLoading = false;
            _this.state.queryBuilder = true;
            _this.state.displayResults = false;
        });
    };
    EmployeeSearchComponent.prototype.ngOnDestroy = function () {
        this.orgLevelSubscription.unsubscribe();
    };
    EmployeeSearchComponent.prototype.onBackToQuery = function () {
        this.state.displayResults = false;
        this.state.queryBuilder = true;
    };
    EmployeeSearchComponent.prototype.onSubmit = function (event) {
        var _this = this;
        event.preventDefault();
        if (this.queryModel.valid) {
            var dto = this.queryModel.prepareDTO();
            this.state.isLoading = true;
            this.api.search(dto, this.currentOrgLevel)
                .then(function (results) {
                _this.searchResults = results;
                _this.state.isLoading = false;
                _this.state.queryBuilder = false;
                _this.state.displayResults = true;
            })
                .catch(function () {
                _this.state.isLoading = false;
                _this.state.queryBuilder = true;
                _this.state.displayResults = false;
            });
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeSearchComponent.prototype, "orgLevel$", void 0);
    return EmployeeSearchComponent;
}());
export { EmployeeSearchComponent };
