<button class="toggler" (click)="toggleCollapsed()">
  <i class="fa fa-bars fa-lg" aria-hidden="true"></i>
</button>
<div *ngIf="activeSubItem?.active && !isCollapsed" class="back-button">
  <button (click)="backFromSubItems()">
    <span class="circle"></span>
    <i class="fa fa-angle-left" aria-hidden="true"></i>
    <span class="back-title">Back</span>
  </button>
</div>
<div class="wrapper">
<nav  role="off-canvas" [ngClass]="{'opened': !isCollapsed}">
  <ul class="menu-items" [ngClass]="{'active': activeItem?.active}">
    <li *ngFor="let item of menuItems" class="menu-item" [ngClass]="{'active': item.active }" (click)="onItemClick(item)">
      <div class="menu-item-title-box">
        <span class="menu-item-title">{{item.displayName}}</span>
      </div>
      <i class="fa fa-chevron-right" aria-hidden="true"></i>
      <ul class="menu-sub-items">
        <li *ngFor="let subItem of item.items" class="menu-sub-item" [ngClass]="{'active': subItem.active}" (click)="onSubItemClick(item, subItem, $event)">
          <span class="line"></span>
          <span class="check"></span>
          <span class="box"></span>
           <a (click)="onSubItemClick(item, subItem, $event)">{{subItem.displayName}}</a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
</div>
