import * as _ from 'lodash';
import { LoaRepeat } from './loa-repeat';
import { LoaRequestClass } from '../../../common/models';
import { RecurrenceFrequencies } from './frequency';
var LoaRequestEmployee = /** @class */ (function () {
    function LoaRequestEmployee(id, name) {
        this.id = id;
        this.name = name;
    }
    return LoaRequestEmployee;
}());
export { LoaRequestEmployee };
var LoaRequest = /** @class */ (function () {
    function LoaRequest() {
        this.loaRepeat = new LoaRepeat();
        this.filesToSave = [];
        this.estAllDayCheckbox = false;
        this.actAllDayCheckbox = false;
        this.loaRequestTabs = LoaRequestTabs.Dates;
    }
    Object.defineProperty(LoaRequest.prototype, "attachmentsLength", {
        get: function () {
            if (!this.attachments || !isFinite(this.attachments.length))
                return 0;
            return this.attachments.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "isContinuous", {
        get: function () {
            return LoaRequestClass[this.requestClass] === LoaRequestClass.Continuous;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "isIntermittent", {
        get: function () {
            return LoaRequestClass[this.requestClass] === LoaRequestClass.Intermittent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "isCustom", {
        get: function () {
            return this.isIntermittent
                && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.custom));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "isDaily", {
        get: function () {
            return this.isIntermittent
                && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.daily));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "isWeekly", {
        get: function () {
            return this.isIntermittent
                && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.weekly));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "isMonthly", {
        get: function () {
            return this.isIntermittent
                && _.includes(_.lowerCase(this.loaRepeat.recurrenceRule), _.lowerCase(RecurrenceFrequencies.monthly));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "typeColor", {
        get: function () {
            return this.type && this.type.color || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "typeName", {
        get: function () {
            return this.type && this.type.name || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "isNegativeDaysBalance", {
        get: function () {
            return this.daysRemaining < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "positionName", {
        get: function () {
            return this.position && this.position.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "departmentName", {
        get: function () {
            return this.department && this.department.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "organizationName", {
        get: function () {
            return this.organization && this.organization.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "empId", {
        get: function () {
            return this.employee && this.employee.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "empName", {
        get: function () {
            return this.employee && this.employee.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "readyToSave", {
        get: function () {
            var continuousIsCorrect = this.isContinuous && this.hasCorrectContinuousDates;
            var intermittentRepeatableIsCorrect = (this.isDaily || this.isWeekly || this.isMonthly) && this.hasCorrectRepeatableDates;
            var intermittentCustomIsCorrect = this.isCustom && this.hasCorrectCustomDates;
            return (continuousIsCorrect || intermittentCustomIsCorrect || intermittentRepeatableIsCorrect)
                && _.isObjectLike(this.type)
                && _.size(this.reason) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "hasCorrectContinuousDates", {
        get: function () {
            var sDate = this.estStartDate;
            var eDate = this.estEndDate;
            if (_.isDate(this.actStartDate)) {
                sDate = this.actStartDate;
            }
            if (_.isDate(this.actEndDate)) {
                eDate = this.actEndDate;
            }
            return _.isDate(this.estStartDate) && _.isDate(this.estEndDate) && eDate.getTime() > sDate.getTime();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "hasCorrectRepeatableDates", {
        get: function () {
            return _.isDate(this.estStartDate) && _.isDate(this.estEndDate) && this.estEndDate.getTime() >= this.estStartDate.getTime();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoaRequest.prototype, "hasCorrectCustomDates", {
        get: function () {
            return _.isDate(this.estStartDate) && _.isDate(this.estEndDate) && this.loaRepeat.customDates.length > 0 && this.estEndDate.getTime() >= this.estStartDate.getTime();
        },
        enumerable: true,
        configurable: true
    });
    return LoaRequest;
}());
export { LoaRequest };
var LoaMappedRequest = /** @class */ (function () {
    function LoaMappedRequest(loaRequest, absenceDays) {
        this.loaRequest = loaRequest;
        this.absenceDays = absenceDays;
    }
    return LoaMappedRequest;
}());
export { LoaMappedRequest };
var LoaRequestContainer = /** @class */ (function () {
    function LoaRequestContainer() {
    }
    return LoaRequestContainer;
}());
export { LoaRequestContainer };
export var LoaRequestTabs;
(function (LoaRequestTabs) {
    LoaRequestTabs["Dates"] = "Dates";
    LoaRequestTabs["Notes"] = "Notes";
    LoaRequestTabs["Attachments"] = "Attachments";
    LoaRequestTabs["Exceptions"] = "Exceptions";
})(LoaRequestTabs || (LoaRequestTabs = {}));
