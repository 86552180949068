import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Component, Input, OnInit, OnChanges, ChangeDetectionStrategy, OnDestroy } from '@angular/core';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DateRange, IDateRange } from '../../../../../core/models/index';

import { LMManagementService, LMRosterManagementService} from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-calendar-grid-header',
  templateUrl: 'lm-calendar-grid-header.component.html',
  styleUrls: ['lm-calendar-grid-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LMCalendarGridHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public dateRange: IDateRange = new DateRange(null, null);
  @Input()
  public daysLength: number = 1;
  @Input()
  public showNavButtons: boolean = true;

  public days: Date[] = [];
  public dateFormat: 'E, MM/dd';
  public dateFormatShort: string = 'EEE, M/d';
  public dateFormatForSmallDevice: string = 'M/d';

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private isDayView: boolean;

  constructor(private manService: LMManagementService,  private manRosterService: LMRosterManagementService) {}

  public ngOnChanges(): void {
    const hasDaysLength = _.isFinite(this.daysLength);
    const hasRange = _.isObjectLike(this.dateRange) && _.isDate(this.dateRange.startDate);
    if (hasDaysLength && hasRange) {
      this.renderRange(this.dateRange.startDate, this.daysLength);
    }
  }

  public ngOnInit(): void {
    this.subscriptions.modeView = this.manRosterService
      .subscribeToCalenderMode((isDay: boolean) => {
        this.isDayView = isDay;
      });
  }

  public ngOnDestroy(): void { }

  public onNavButtonClick(isNext: boolean): void {
    this.manService.navButtonClick(isNext);
  }

  private renderRange(sDate: Date, daysLength: number): void {
    const days: Date[] = [];
    let prevDate: Date = null;
    for (let i = 1; daysLength >= i; i++) {
      let date: Date = null;
      if (i === 1) {
        date = new Date(sDate.getTime());
      } else {
        const pDate = new Date(prevDate.getTime());
        date = new Date(pDate.setDate(pDate.getDate() + 1));
      }
      prevDate = date;
      days.push(date);
    }
    this.days = days;
  }
}
