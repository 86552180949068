<div class="slx-autocomplete" [class.custom]="autocompleteItem">
  <label *ngIf="hasPlaceholder" class="slx-autocomplete__label">{{ placeholder }}</label>
  <kendo-autocomplete class="slx-autocomplete__autocomplete" #autocomplete
    [data]="listItems"
    [(ngModel)]="value"
    [valueField]="titleField"
    [filterable]="true"
    (filterChange)="onFilterItems($event)"
    [readonly]="readonly"
    [suggest]="suggest"
    [itemDisabled]="itemDisabled"
    [popupSettings]="popupSettings"
    name="autocomplete"
    [virtual]="virtual"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (valueChange)="onValueChange($event)"
  >
  <ng-template kendoAutoCompleteItemTemplate let-dataItem>
    <ng-container *ngIf="!autocompleteItem">{{ dataItem[titleField] }}</ng-container>
    <ng-container *ngTemplateOutlet="autocompleteItem; context: { item: dataItem }"></ng-container>
  </ng-template>
  </kendo-autocomplete>
  <span class="slx-autocomplete__r-icon flex justify-content__center align-items__center" *ngIf="autocompleteItem">
    <i [ngClass]="rightIcon"></i>
  </span>
</div>
