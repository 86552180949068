import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';
import { PunchesApiService } from './punches-api.service';
import { PunchStatus } from '../../models/index';
import { PunchesFilterHelper } from './punches-filter-helper';
var DailyPunchesManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(DailyPunchesManagementService, _super);
    function DailyPunchesManagementService(apiService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.canEditChanged$ = new ReplaySubject(1);
        _this.onLoadedPairs$ = new ReplaySubject(1);
        _this.onChangeView$ = new Subject();
        _this.onApplyChanges$ = new Subject();
        _this.onChangingPunches$ = new Subject();
        _this.onStartChangingPunches$ = new Subject();
        _this.onCancelChangingPunches$ = new Subject();
        _this.onDiscardChanges$ = new Subject();
        _this.onFilterSet$ = new Subject();
        _this.onFilterApplied$ = new Subject();
        _this.isPaidRestBreakEnabled$ = new ReplaySubject(1);
        return _this;
    }
    DailyPunchesManagementService.prototype.markClosingPunches = function (records) {
        var priorIsIn = false;
        var sorted = _.sortBy(records, 'time');
        _.forEach(sorted, function (punch) {
            if (!punch.type || (punch.punchStatus !== PunchStatus.Edited && punch.punchStatus !== PunchStatus.Valid)) {
                punch.canBeClosing = false;
                return;
            }
            if ((punch.type.isOut && priorIsIn) || !!punch.closingPunch) {
                punch.canBeClosing = true;
            }
            else {
                punch.canBeClosing = false;
            }
            if (punch.type.isIn) {
                priorIsIn = true;
            }
        });
    };
    DailyPunchesManagementService.prototype.validatePairs = function (records) {
        var hasInMissingPunch = false;
        var hasOutMissingPunch = false;
        var expectedDirection = 'in';
        var exepmtPunches = _.filter(records, function (punch) { return !!punch.isExempt; });
        if (exepmtPunches.length > 0) {
            hasInMissingPunch = !_.find(exepmtPunches, function (punch) { return punch.punchStatus === PunchStatus.Valid; });
            return { hasInMissingPunch: hasInMissingPunch, hasOutMissingPunch: hasOutMissingPunch };
        }
        var sorted = _.sortBy(records, 'time');
        _.forEach(sorted, function (punch) {
            if (!punch.type || hasInMissingPunch || hasOutMissingPunch) {
                return;
            }
            if (punch.punchStatus !== PunchStatus.Edited && punch.punchStatus !== PunchStatus.Valid && !punch.isEditedAndValidated) {
                return;
            }
            if (punch.type.isIn) {
                if (expectedDirection === 'out') {
                    hasOutMissingPunch = true;
                }
                expectedDirection = 'out';
            }
            if (punch.type.isOut) {
                if (expectedDirection === 'in') {
                    hasInMissingPunch = true;
                }
                expectedDirection = 'in';
            }
        });
        if (expectedDirection === 'out') {
            hasOutMissingPunch = true;
        }
        return { hasInMissingPunch: hasInMissingPunch, hasOutMissingPunch: hasOutMissingPunch };
    };
    DailyPunchesManagementService.prototype.canEditChanged = function (canEdit) {
        this.canEditChanged$.next(canEdit);
    };
    DailyPunchesManagementService.prototype.changeView = function (view) {
        this.onChangeView$.next(view);
    };
    DailyPunchesManagementService.prototype.discardChanges = function () {
        this.onDiscardChanges$.next();
    };
    DailyPunchesManagementService.prototype.applyChanges = function (changes) {
        this.onApplyChanges$.next(changes);
    };
    DailyPunchesManagementService.prototype.startChanges = function () {
        this.onStartChangingPunches$.next();
    };
    DailyPunchesManagementService.prototype.cancelChanges = function () {
        this.onCancelChangingPunches$.next();
    };
    DailyPunchesManagementService.prototype.emitChanges = function (pairs) {
        this.onChangingPunches$.next(pairs);
    };
    DailyPunchesManagementService.prototype.setPanches = function (pairs) {
        if (this.filter) {
            var filterHelper = new PunchesFilterHelper();
            filterHelper.filter(pairs, this.filter);
        }
        this.onLoadedPairs$.next(pairs);
        this.onFilterApplied$.next(pairs);
    };
    DailyPunchesManagementService.prototype.applyCurrentFilter = function (records) {
        var filteredEntities = this.filterPunches(records, this.filter);
        this.onFilterApplied$.next(filteredEntities);
    };
    DailyPunchesManagementService.prototype.applyFilter = function (records, filter) {
        this.setFilter(filter);
        var filteredEntities = this.filterPunches(records, this.filter);
        this.onFilterApplied$.next(filteredEntities);
    };
    DailyPunchesManagementService.prototype.setFilter = function (filter) {
        this.filter = filter;
        this.onFilterSet$.next(filter);
    };
    DailyPunchesManagementService.prototype.filterPunches = function (entities, filter) {
        if (!filter) {
            return entities;
        }
        var filteredList = entities;
        var filterHelper = new PunchesFilterHelper();
        filterHelper.filter(filteredList, filter);
        return filteredList;
    };
    DailyPunchesManagementService.prototype.removeInvalidLogin = function (date, empId) {
        return this.apiService.removeInvalidLogin(date, empId);
    };
    return DailyPunchesManagementService;
}(ManagementBaseService));
export { DailyPunchesManagementService };
