
export interface IShiftSmsNotificationResponse {
  messageText: string;
  responseText: string;
  dateSent: string;
}
export class ShiftSmsNotificationResponse {
  public messageText: string;
  public responseText: string;
  public dateSent: Date;
}
