import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NgForm } from '@angular/forms';
import { LookupService } from '../../../../organization/services/index';
import { LookupType } from '../../../../organization/models/index';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { UserProfileNavigationService } from '../../../../common/services/index';
import { UserProfileSectionBaseComponent } from '../user-profile-sections/user-profile-section-base.component';
import { UserProfileSectionType } from '../../../models/users/models/user-profile-section-type';
import { UserProfileManagementService, UserValidatorAdapter } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { PasswordValidatorAdapter } from '../../../../authentication/services/index';
var UserProfileSectionProfileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(UserProfileSectionProfileComponent, _super);
    function UserProfileSectionProfileComponent(userValidatorAdapter, passwordValidator, management, router, route, location, lookupService) {
        var _this = _super.call(this, management) || this;
        _this.userValidatorAdapter = userValidatorAdapter;
        _this.passwordValidator = passwordValidator;
        _this.management = management;
        _this.router = router;
        _this.route = route;
        _this.location = location;
        _this.lookupService = lookupService;
        _this.validStateUpdated = new EventEmitter();
        _this.virtual = {
            itemHeight: 65,
            pageSize: 5
        };
        _this.isLoading = false;
        _this.m_type = UserProfileSectionType.PROFILE;
        return _this;
    }
    Object.defineProperty(UserProfileSectionProfileComponent.prototype, "passwordValidation", {
        get: function () {
            return PasswordValidatorAdapter.passwordValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileSectionProfileComponent.prototype, "userNameValidation", {
        get: function () {
            return UserValidatorAdapter.loginValidation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileSectionProfileComponent.prototype, "isValid", {
        get: function () {
            return this.m_isValid;
        },
        enumerable: true,
        configurable: true
    });
    UserProfileSectionProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.updateList();
        this.profileLoadedSubscription = this.management.onProfileLoaded$.subscribe(function (userProfile) {
            _this.userProfile = userProfile;
            if (_this.userProfile.isNew) {
                _this.tempModel = _this.userProfile;
                _this.management.editSection(_this);
            }
            else {
                _this.tempModel = _this.management.cloneProfile(_this.userProfile);
            }
        });
        this.formValueChangeSubscription = this.form.control.valueChanges.subscribe(function (values) { return _this.updateValid(values); });
        this.formStatusChangeSubscription = this.form.control.statusChanges.subscribe(function (values) { return _this.updateValid(values); });
    };
    UserProfileSectionProfileComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
        _super.prototype.ngOnDestroy.call(this);
    };
    UserProfileSectionProfileComponent.prototype.onChangesSaved = function () {
        this.userProfile = this.tempModel;
        this.tempModel = this.management.cloneProfile(this.userProfile);
        this.state.isLoading = false;
        this.state.isEditMode = false;
    };
    UserProfileSectionProfileComponent.prototype.discardChanges = function () {
        this.state.isEditMode = false;
        if (this.userProfile.isNew) {
            var navigation = new UserProfileNavigationService(this.router, this.route, this.location);
            navigation.discardNewUser();
        }
        else {
            this.tempModel = this.management.cloneProfile(this.userProfile);
        }
    };
    UserProfileSectionProfileComponent.prototype.getSaveData = function () {
        return this.tempModel;
    };
    UserProfileSectionProfileComponent.prototype.updateValid = function (values) {
        this.m_isValid = this.form.valid;
        this.validStateUpdated.next(this.m_isValid);
    };
    UserProfileSectionProfileComponent.prototype.updateList = function () {
        var _this = this;
        if (!this.lookupService)
            return;
        this.isLoading = true;
        this.lookupService.getLookup({ lookupType: LookupType.employeeList })
            .then(function (lookup) {
            _this.employees = lookup.items;
            _this.isLoading = false;
        }).catch(function (reason) {
            _this.employees = [];
            _this.isLoading = false;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionProfileComponent.prototype, "formValueChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionProfileComponent.prototype, "formStatusChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileSectionProfileComponent.prototype, "profileLoadedSubscription", void 0);
    return UserProfileSectionProfileComponent;
}(UserProfileSectionBaseComponent));
export { UserProfileSectionProfileComponent };
