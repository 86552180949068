import { screenUtils } from './../../../../common/utils/screenUtils';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, UrlSegment, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { EmployeeDetails } from '../../../../organization/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { EmployeeDetailsTableListComponent } from '../../../../organization/index';
import { StateManagementService } from '../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { ControlStateKey, IControlState, StateResetTypes } from '../../../../core/models/settings/index';


import {
  AttendanceTypeDefinition, ArrivalsDeparturesAnalyticsDefinition,
  AttendanceType, ArrivalsDeparturesAnalytics
} from '../../../models/index';

import { ArrivalDeparturesDetailsManagementService } from '../../../services/index';
@Component({
  moduleId: module.id,
  selector: 'slx-arrivals-departures-details',
  templateUrl: 'arrivals-departures-details.component.html',
  styleUrls: ['arrivals-departures-details.component.scss'],
  providers: [ArrivalDeparturesDetailsManagementService, StateManagementService]
})
export class ArrivalsDeparturesDetailsComponent implements OnInit, OnDestroy {
  public appConfig: IApplicationConfig;
  public employeeDetailsList: EmployeeDetails[];
  public isLoading: boolean;
  public selectedDate: Date;
  public state = {
    isTileView: true
  };

  public get listTitle(): string {
    if (this.analytics === ArrivalsDeparturesAnalytics.unscheduled) {
      return `Date: ${moment(this.selectedDate).format(appConfig.monthDayDateFormat)} ${this.attendanceType} ${this.analytics}`;
    }
    if (this.selectedTime) {
      return `Date: ${moment(this.selectedTime).format(appConfig.monthDayDateFormat)} Time: ${moment(this.selectedTime).format(appConfig.timeFormat)} ${this.attendanceType} ${this.analytics}`;
    }
    return `Date: ${moment(this.selectedDate).format(appConfig.monthDayDateFormat)} Time: ${moment(this.selectedDate).format(appConfig.timeFormat)} ${this.attendanceType} ${this.analytics}`;
  }

  private selectedTime: Date;
  private attendanceType: AttendanceTypeDefinition;
  private analytics: ArrivalsDeparturesAnalyticsDefinition;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private loadStartedSubscription: Subscription;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private routeSubscription: Subscription;

  @ViewChild('detailsTableList', {static: false})
  private tableListComponent: EmployeeDetailsTableListComponent;

  private m_tileViewControlId: string = 'TileViewNgx';
  private m_tileViewResetType: StateResetTypes = StateResetTypes.None;
  private stateKey: ControlStateKey;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private arrivalDeparturesDetailsManagementService: ArrivalDeparturesDetailsManagementService,
    private stateManagement: StateManagementService,
    private storageService: ComponentStateStorageService) {
    Assert.isNotNull(arrivalDeparturesDetailsManagementService, 'arrivalDeparturesDetailsManagementService');
    this.arrivalDeparturesDetailsManagementService = arrivalDeparturesDetailsManagementService;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.stateManagement.init('ArrivalsDeparturesDetailsComponent', false);
    this.restoreStates();

    this.loadStartedSubscription = this.arrivalDeparturesDetailsManagementService.onLoadStarted$.subscribe(
      () => {
        this.isLoading = true;
      });

    this.loadedSubscription = this.arrivalDeparturesDetailsManagementService.onLoaded$.subscribe(
      (details: EmployeeDetails[]) => {
        this.employeeDetailsList = details;
        this.isLoading = false;
      });

    this.routeSubscription = this.activatedRoute.params
      .combineLatest(this.activatedRoute.queryParams)
      .subscribe(([params, queryParams]) => {
        let dateOn = queryParams['date'];
        if (dateOn) {
          this.selectedDate = moment(dateOn, appConfig.linkDateFormat).toDate();
        } else {
          this.selectedDate = moment().toDate();
        }

        let timeOn = queryParams['time'];
        this.selectedTime = timeOn ? moment(timeOn, appConfig.linkDateTimeFormat).toDate() : null;
        this.attendanceType = queryParams['attendance_type'];
        if (!this.attendanceType) {
          this.attendanceType = AttendanceType.arrivals;
        }

        this.analytics = queryParams['analytics'];
        if (!this.analytics) {
          this.analytics = ArrivalsDeparturesAnalytics.unscheduled;
        }

        this.arrivalDeparturesDetailsManagementService.loadDetails(this.selectedDate, this.selectedTime, this.attendanceType, this.analytics);
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onExportToExcel(): void {
    this.tableListComponent.exportToExcel();
  }

  public onExportToPdf(): void {
    this.tableListComponent.exportToPdf();
  }

  public onTileViewChanged(): void {
    this.saveTileViewStates();
  }

  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  private saveTileViewStates(): void {
    this.storageService.setControlState(this.stateManagement.componentKey, this.m_tileViewControlId,
      {
        value: { isTileView: this.state.isTileView }
      },
      this.m_tileViewResetType, this.stateKey);
    this.stateManagement.controlValueChanged(this.m_tileViewControlId);
  }

  private restoreStates(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.m_tileViewControlId);
    if (state && state.value) {
      this.state.isTileView = state.value.isTileView;
    }
  }

}
