<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
        <slx-pm-employee-roster-toolbar></slx-pm-employee-roster-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
        <slx-pm-employee-roster-grid></slx-pm-employee-roster-grid>
    </div>
  </div>
</slx-spinner>
