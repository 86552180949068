import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, Provider } from '@angular/core';
import { AcaExportManagementService } from '../../services/aca-export/aca-export-management.service';
import { Subscription } from 'rxjs';
import { ExportDataStatus } from '../../enums/export-data-status';
import { DialogOptions2, ModalService, StateManagementService } from '../../../../../app/common';
import { unsubscribeAll } from '../../../../core/decorators';
import { AcaExportConfiguration } from '../../models/aca-export/aca-export-configuration';
import { AcaExportEventService } from '../../../../common/services/aca-export/aca-export-event.service';
import { AcaExportDialogOptions } from '../../models/aca-export/aca-export-dialog-options';
import { AcaExportCorrected1095CComponent } from './aca-export-corrected-1095c/aca-export-corrected-1095c.component';
import { AcaCorrectedSelectEmployeeComponent } from './aca-corrected-select-employee/aca-corrected-select-employee.component';
import { AcaExportSupplementalComponent } from './aca-export-supplemental-1095c/aca-export-supplemental-1095c.component';
import { AcaExportCorrected1094cCountsComponent } from './aca-export-corrected-1094c-counts/aca-export-corrected-1094c-counts.component';
import { AcaExportCorrected1094cParametersComponent } from './aca-export-corrected-1094c-parameters/aca-export-corrected-1094c-parameters.component';
import { AcaCorrectionsDialogComponent } from './aca-corrections-dialog/aca-corrections-dialog.component';

@Component({
  selector: 'slx-aca-export',
  templateUrl: './aca-export.component.html',
  styleUrls: ['./aca-export.component.scss'],
  providers: [StateManagementService, AcaExportEventService, AcaExportManagementService]
})

export class AcaExportComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  public exportConfigurations: AcaExportConfiguration[] = [];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public get exportDataStatus() { return ExportDataStatus; }

  constructor(private stateManagement: StateManagementService,
    private manService: AcaExportManagementService,
    private changeDetector: ChangeDetectorRef,
    private modalService: ModalService) {
  }

  public ngOnInit(): void {
    this.stateManagement.init('AcaExportComponent');

    this.subscriptions.loading = this.manService.subscribeToLoading((data: boolean) => {
      this.isLoading = data;
    });

    this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel(() => {
      this.manService.loadAcaExportConfiguration();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.subscriptions.loadedConfigurtion = this.manService.subscribeToLoadedConfigurations((data: AcaExportConfiguration[]) => {
      this.createConfiguration(data);
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.manService.init();

    this.subscriptions.openDialogOptions = this.manService.subscribeToExportDialogOptions((data: AcaExportDialogOptions) => {
      if (data.dialogType == 1) {
        this.openAcaExportCorrectionsPopup(data);
      }
      else if (data.dialogType == 2) {
        this.openAcaExportCorrected1095cPopup(data);
      }
      else if (data.dialogType == 3) {
        this.openAcaExportSelectEmpPopup(data);
      }
      else if (data.dialogType == 4) {
        this.openAcaExportSupplemental1095CsPopup(data);
      }
      else if (data.dialogType == 5) {
        this.openAcaExportParametersPopup(data);
      }
      else if (data.dialogType == 6) {
        this.openAcaExportCountsPopup(data);
      }
    });
  }

  private createConfiguration(data: AcaExportConfiguration[]): void {
    const stateConfigurations:AcaExportConfiguration[]=this.manService.restoreAcaExportState();
    this.exportConfigurations = data;
    _.forEach(this.exportConfigurations, function (item) {
      const exportConfig=_.find(stateConfigurations,(rowItem:AcaExportConfiguration)=>(rowItem.exportType==item.exportType));
      if(exportConfig)
      {
      item.isCollapsed = exportConfig.isCollapsed;
      item.exportParams = exportConfig.exportParams; 
      }
      else
      {
        let year: number = moment().startOf('day').toDate().getFullYear();
        let month: number = moment().startOf('day').toDate().getMonth();
        if (month < 3) {
          year = year - 1;
        }
        item.exportParams.year = year
      }
    });
  
  }

  public ngOnDestroy(): void {
    this.manService.setAcaExportState(this.exportConfigurations);
    this.exportConfigurations = null;
    this.changeDetector.detach();
  }

  public openAcaExportCorrectionsPopup(data: AcaExportDialogOptions): void {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.height = 325;
    dialogOptions.width = 566;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: AcaExportDialogOptions,
        useValue: data
      },
    ];

    let dialog: AcaCorrectionsDialogComponent = this.modalService.globalAnchor.openDialog2(
      AcaCorrectionsDialogComponent,
      'File 1095-C/1094-C Corrections',
      dialogOptions, resolvedProviders, (result: boolean) => {
        if (result) {

        }
      });
    dialog.manService = this.manService;
    dialog.acaExportExecutionItem = data.acaExportExecutionItem;
    dialog.acaCorrectionType = data.acaExportCorrectionType;
    if (dialog.initialized) {
      dialog.createCorrectionTypeOptions();
    }
  }

  public openAcaExportCorrected1095cPopup(data: AcaExportDialogOptions): void {

    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.height = 500;
    dialogOptions.width = 750;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: AcaExportDialogOptions,
        useValue: data
      },
    ];
    let dialog: AcaExportCorrected1095CComponent = this.modalService.globalAnchor.openDialog2(
      AcaExportCorrected1095CComponent,
      'Corrected 1095-Cs',
      dialogOptions, resolvedProviders, (result: boolean) => {
        if (result) {

        }
      });
    dialog.manService = this.manService;
    dialog.acaExportExecutionItem = data.acaExportExecutionItem;
    dialog.acaCorrectionType = data.acaExportCorrectionType;
    dialog.isBack = data.isBack;
    dialog.matchEmployeeList = data.matchEmployeeList;
    if (dialog.initialized) {
      dialog.getAcaCorrected1095C(data.acaExportExecutionItem.id);
    }
    dialog.records = data.acaExportCorrected1095CContainer;
  }

  public openAcaExportSelectEmpPopup(data: AcaExportDialogOptions): void {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.height = 600;
    dialogOptions.width = 651;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: AcaExportDialogOptions,
        useValue: data
      },
    ];

    let dialog: AcaCorrectedSelectEmployeeComponent = this.modalService.globalAnchor.openDialog2(
      AcaCorrectedSelectEmployeeComponent,
      'Select Matching Employee',
      dialogOptions, resolvedProviders, (result: boolean) => {
        if (result) {

        }
      });
    dialog.manService = this.manService;
    dialog.acaExportExecutionItem = data.acaExportExecutionItem;
    dialog.acaCorrectionType = data.acaExportCorrectionType;
    dialog.corrected109fCRecords = data.acaExportCorrected1095CContainer;
    dialog.matchEmployeeList = data.matchEmployeeList;
    dialog.acaReplace1095C = data.acaReplace1095C;
    if (dialog.initialized) {
      dialog.getAcaReplace1095C(data.acaExportExecutionItem.id);
    }
    dialog.records = data.acaExportReplace1095CContainer;
  }

  public openAcaExportSupplemental1095CsPopup(data: AcaExportDialogOptions): void {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.height = 600;
    dialogOptions.width = 651;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: AcaExportDialogOptions,
        useValue: data
      },];

    let dialog: AcaExportSupplementalComponent = this.modalService.globalAnchor.openDialog2(
      AcaExportSupplementalComponent,
      'Supplemental 1095-Cs',
      dialogOptions, resolvedProviders, (result: boolean) => {
        if (result) {

        }
      });
    dialog.manService = this.manService;
    dialog.acaExportExecutionItem = data.acaExportExecutionItem;
    dialog.acaCorrectionType = data.acaExportCorrectionType;
    if (dialog.initialized) {
      dialog.getAcaSupplemental1095C(data.acaExportExecutionItem.id);
    }
    dialog.records = data.acaExportSupplemental1095C;
  }

  public openAcaExportParametersPopup(data: AcaExportDialogOptions): void {

    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.height = 507;
    dialogOptions.width = 766;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: AcaExportDialogOptions,
        useValue: data
      },];

    let dialog: AcaExportCorrected1094cParametersComponent = this.modalService.globalAnchor.openDialog2(
      AcaExportCorrected1094cParametersComponent,
      '1094-C Parameters',
      dialogOptions, resolvedProviders, (result: boolean) => {
      });
    dialog.manService = this.manService;
    dialog.acaExportCorrectionParameter = data.acaExportCorrectionParameter ? data.acaExportCorrectionParameter : this.manService.createParameterOptionsData(data.acaExportExecutionItem);
    dialog.acaCorrectionType = data.acaExportCorrectionType;
    dialog.acaExportExecutionItem = data.acaExportExecutionItem;
  }

  public async openAcaExportCountsPopup(data: AcaExportDialogOptions): Promise<void> {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.height = 470;
    dialogOptions.width = 551;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      },
      {
        provide: AcaExportDialogOptions,
        useValue: data
      },];

    let dialog: AcaExportCorrected1094cCountsComponent = this.modalService.globalAnchor.openDialog2(
      AcaExportCorrected1094cCountsComponent,
      '1094-C Counts',
      dialogOptions, resolvedProviders, (result: boolean) => {
      });
    dialog.manService = this.manService;
    dialog.acaExportCorrectionParameter = data.acaExportCorrectionParameter;
    dialog.acaExportExecutionItem = data.acaExportExecutionItem;
    dialog.acaCorrectionType = data.acaExportCorrectionType;
    if (dialog.initialized) {
      dialog.getAcaExport1094CCountData(data.acaExportExecutionItem, data.acaExportCorrectionParameter);
    }
  }
}
