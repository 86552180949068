import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'slx-locker',
  templateUrl: 'locker.component.html',
  styleUrls: ['locker.component.scss']
})
export class LockerComponent {
  @Input()
  public show: boolean;
}
