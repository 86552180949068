import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';
var KendoGridNumberFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridNumberFilterComponent, _super);
    function KendoGridNumberFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        _this.step = 1;
        _this.spinners = true;
        _this.localization = localization;
        var slxFilters = _.map(kendoUtils.slxNumericFilters, function (filter) { return filter.key; });
        var slxFiltersKeysMap = _.keyBy(slxFilters, function (key) { return key; });
        var numericOperators = _this.localizeOperators(Object.assign(slxFiltersKeysMap, {
            'filterEqOperator': 'eq',
            'filterNotEqOperator': 'neq',
            'filterGteOperator': 'gte',
            'filterGtOperator': 'gt',
            'filterLteOperator': 'lte',
            'filterLtOperator': 'lt',
            'filterIsNullOperator': 'isnull',
            'filterIsNotNullOperator': 'isnotnull'
        }));
        _this.showOperators = true;
        _this.operator = 'slxFilterNumber1';
        _this.defaultOperators = numericOperators(_this.localization);
        return _this;
    }
    Object.defineProperty(KendoGridNumberFilterComponent.prototype, "format", {
        get: function () {
            return !kendoUtils.isNullOrEmptyString(this.m_format) ? this.m_format : this.columnFormat;
        },
        set: function (value) {
            this.m_format = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KendoGridNumberFilterComponent.prototype, "columnFormat", {
        get: function () {
            return this.column && !kendoUtils.isNullOrEmptyString(this.column.format) ?
                kendoUtils.extractFormat(this.column.format) : 'n2';
        },
        enumerable: true,
        configurable: true
    });
    return KendoGridNumberFilterComponent;
}(KendoGridBasicFilterComponent));
export { KendoGridNumberFilterComponent };
