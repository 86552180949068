import * as _ from 'lodash';

export enum LengthUnitDefinition {
    mm = 'mm',
    cm = 'cm',
    m = 'm',
    km = 'km',
}

export class MetricLengthUnit {
    public name: string;
    public nextUnitMultiplier: number;
}

export const millimeter: MetricLengthUnit = { name: LengthUnitDefinition.mm.toString(), nextUnitMultiplier: 10 };
export const centimeter: MetricLengthUnit = { name: LengthUnitDefinition.cm.toString(), nextUnitMultiplier: 100 };
export const meter: MetricLengthUnit = { name: LengthUnitDefinition.m.toString(), nextUnitMultiplier: 1000 };
export const kilometer: MetricLengthUnit = { name: LengthUnitDefinition.km.toString(), nextUnitMultiplier: -1 };

export const MetricLengthUnits: MetricLengthUnit[] = [millimeter, centimeter, meter, kilometer];

export function convertUnit(sourceUnit: MetricLengthUnit, targetUnit: MetricLengthUnit, value: number): number {
    let converted: number;
    if (sourceUnit && targetUnit && !isNaN(value)) {
        let sourceIndex: number = MetricLengthUnits.indexOf(sourceUnit);
        let targetIndex: number = MetricLengthUnits.indexOf(targetUnit);
        if (sourceIndex === -1) throw new Error('Unknown source unit');
        if (targetIndex === -1) throw new Error('Unknown target unit');
        if (sourceIndex < targetIndex) {
            converted = value;
            while (sourceIndex < targetIndex) {
                converted = converted / MetricLengthUnits[sourceIndex].nextUnitMultiplier;
                sourceIndex++;
            }
        } else {
            converted = value;
            while (sourceIndex > targetIndex) {
                sourceIndex--;
                console.log(sourceIndex);
                converted = converted * MetricLengthUnits[sourceIndex].nextUnitMultiplier;
            }
        }
    }
    return converted;
}

export function getMetricLengthUnitByName(name: string): MetricLengthUnit {
    return _.find(MetricLengthUnits, (unit: MetricLengthUnit) => {
        return unit.name === name;
    });
}
