<div class="daily">
  <p class="flex align-items__center daily__section">
    <label class="daily__label">Repeat every</label>
    <slx-number
      class="daily__number"
      [min]="min"
      [step]="step"
      [decimals]="decimals"
      [autoCorrect]='true'
      [readonly]="!canEditRequest"
      [(ngModel)]="interval"
      (ngModelChange)="onChangeRecurrence()"
    ></slx-number>
    <span class="daily__text">day(s)</span>
  </p>

  <p class="flex daily__line">
    <label class="daily__label">End</label>
    <span class="flex__grow">
      <span class="flex align-items__center daily__line">
        <slx-radio-button class="daily__radio"
          caption="After"
          [option]="endAfterOccurrence"
          [readonly]="!canEditRequest"
          [(ngModel)]="endMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-number class="daily__number"
          [min]="min"
          [step]="step"
          [decimals]="decimals"
          [autoCorrect]='true'
          [readonly]="!canEditRequest || countIsReadonly"
          [(ngModel)]="count"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-number>
        <span class="daily__text">occurrence(s)</span>
      </span>
      <span class="flex align-items__center daily__line">
        <slx-radio-button class="daily__radio"
          caption="On"
          [option]="endOnDate"
          [readonly]="!canEditRequest"
          [(ngModel)]="endMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-datepicker class="daily__date"
          [minDate]="calculatedMaxDate || limitEndOnDate"
          [readonly]="!canEditRequest || untilIsReadonly"
          [(ngModel)]="until"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-datepicker>
      </span>
    </span>
  </p>
</div>
