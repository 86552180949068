/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./aca-1095-c-grid.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ag-grid-angular/dist/agGridAngular.ngfactory";
import * as i3 from "ag-grid-angular/dist/angularFrameworkOverrides";
import * as i4 from "ag-grid-angular/dist/angularFrameworkComponentWrapper";
import * as i5 from "ag-grid-angular/dist/agGridAngular";
import * as i6 from "./aca-1095-c-grid.component";
import * as i7 from "../../../services/aca-1095-c/aca-1095-c-management.service";
import * as i8 from "@angular/router";
var styles_Aca1095cGridComponent = [i0.styles];
var RenderType_Aca1095cGridComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_Aca1095cGridComponent, data: {} });
export { RenderType_Aca1095cGridComponent as RenderType_Aca1095cGridComponent };
export function View_Aca1095cGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 4, "ag-grid-angular", [["class", "ag-theme-balham"], ["style", "width: 100%; height: 100%;"]], null, [[null, "gridReady"], [null, "cellClicked"], [null, "cellDoubleClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("gridReady" === en)) {
        var pd_0 = (_co.onGridReady($event) !== false);
        ad = (pd_0 && ad);
    } if (("cellClicked" === en)) {
        var pd_1 = (_co.onCellClicked($event) !== false);
        ad = (pd_1 && ad);
    } if (("cellDoubleClicked" === en)) {
        var pd_2 = (_co.onCellDoubleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_AgGridAngular_0, i2.RenderType_AgGridAngular)), i1.ɵprd(512, null, i3.AngularFrameworkOverrides, i3.AngularFrameworkOverrides, [i1.NgZone]), i1.ɵprd(512, null, i4.AngularFrameworkComponentWrapper, i4.AngularFrameworkComponentWrapper, []), i1.ɵdid(3, 4898816, null, 1, i5.AgGridAngular, [i1.ElementRef, i1.ViewContainerRef, i3.AngularFrameworkOverrides, i4.AngularFrameworkComponentWrapper, i1.ComponentFactoryResolver], { gridOptions: [0, "gridOptions"], rowData: [1, "rowData"], columnDefs: [2, "columnDefs"], frameworkComponents: [3, "frameworkComponents"], defaultColDef: [4, "defaultColDef"], paginationPageSize: [5, "paginationPageSize"], getRowHeight: [6, "getRowHeight"], getContextMenuItems: [7, "getContextMenuItems"], pagination: [8, "pagination"] }, { cellClicked: "cellClicked", cellDoubleClicked: "cellDoubleClicked", gridReady: "gridReady" }), i1.ɵqud(603979776, 1, { columns: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.gridOptions; var currVal_1 = _co.rowData; var currVal_2 = _co.gridHelper.columnDefs; var currVal_3 = _co.gridHelper.frameworkComponents; var currVal_4 = _co.gridHelper.defaultColumnDefs; var currVal_5 = 50; var currVal_6 = _co.getRowHeight; var currVal_7 = _co.getContextMenuItems; var currVal_8 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_Aca1095cGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-aca-1095-c-grid", [], null, null, null, View_Aca1095cGridComponent_0, RenderType_Aca1095cGridComponent)), i1.ɵdid(1, 4440064, null, 0, i6.Aca1095cGridComponent, [i7.Aca1095cManagementService, i8.Router, i8.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Aca1095cGridComponentNgFactory = i1.ɵccf("slx-aca-1095-c-grid", i6.Aca1095cGridComponent, View_Aca1095cGridComponent_Host_0, {}, {}, []);
export { Aca1095cGridComponentNgFactory as Aca1095cGridComponentNgFactory };
