import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';
import { ITAAbsence } from '../../../organization/models/lookup/index';

export interface IAbsenceDTO {
  code: string;
  description: string;
  isPaid: boolean;
  ptoPlannerIndicator: boolean;
  color: number;
  essPresent: boolean;
  taAbsence: ITAAbsence;
  lastUpdateDate: string;
  lastUpdateUsername: string;
  loaIndicator: boolean;
}

export class AbsenceModel implements IBaseSelectableModel {
  public code: string;
  public description: string;
  public isPaid: boolean;
  public ptoPlannerIndicator: boolean;
  public color: number;
  public essPresent: boolean;
  public taCode: string;
  public lastUpdateDate: Date;
  public lastUpdateUsername: string;
  public loaIndicator: boolean;

  public isSelected: boolean;
  public selectable: boolean = true;
}
