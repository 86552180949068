import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { StaffingPredictorMapService } from './staffing-predictor-map.service';
import { staffingPredictorConfig } from '../staffing-predictor.config';
import { StaffingPredictorOrganizationInfo, IStaffingPredictorOrganizationInfoDTO, StaffingRatingRequest, StaffingOrganizationInfoRequest, FiveStarPredictorOrganizationInfo, IFiveStarPredictorOrganizationInfoDTO } from '../models/index';
import { GoalSettingResponse } from '../models/goal-setting';
import { ISaveGoalResponse, SaveGoalRequest, SaveGoalResponse } from '../models/save-goal';
var StaffingPredictorApiService = /** @class */ (function () {
    function StaffingPredictorApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    StaffingPredictorApiService.prototype.getRatingList = function (requestObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + staffingPredictorConfig.api.staffingPredictor.rating;
                        body = this.mapService.mapToStaffinRatingRequestDto(requestObj);
                        request = this.urlParamsService.requestPost(url, body);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToStaffingRatingContainer(response.data)];
                }
            });
        });
    };
    StaffingPredictorApiService.prototype.loadOrganizationInfo = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, urlRequest, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + staffingPredictorConfig.api.staffingPredictor.organization + "/" + request.organizationId;
                        body = this.mapService.mapToStaffinRatingOrganizationInfoRequestDto(request);
                        urlRequest = this.urlParamsService.requestPost(url, body);
                        return [4 /*yield*/, this.apiUtilService.requestNew(urlRequest)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToOrganizationInfo(response.data)];
                }
            });
        });
    };
    StaffingPredictorApiService.prototype.getApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + staffingPredictorConfig.api.staffingPredictor.root + "/" + staffingPredictorConfig.api.staffingPredictor.predictor;
    };
    StaffingPredictorApiService.prototype.getFiveStarRatingList = function (requestObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + staffingPredictorConfig.api.staffingPredictor.pastfuture;
                        body = this.mapService.mapToStaffinRatingRequestDto(requestObj);
                        request = this.urlParamsService.requestPost(url, body);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToFiveStarRatingContainer(response.data)];
                }
            });
        });
    };
    StaffingPredictorApiService.prototype.loadFiveStarOrganizationInfo = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, urlRequest, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + staffingPredictorConfig.api.staffingPredictor.organization + "2/" + request.organizationId;
                        body = this.mapService.mapToStaffinRatingOrganizationInfoRequestDto(request);
                        urlRequest = this.urlParamsService.requestPost(url, body);
                        return [4 /*yield*/, this.apiUtilService.requestNew(urlRequest)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToFiveStarOrganizationInfo(response.data)];
                }
            });
        });
    };
    StaffingPredictorApiService.prototype.loadGoalSectionInfo = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, urlRequest, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + staffingPredictorConfig.api.staffingPredictor.goalSetting;
                        body = this.mapService.mapGoalSectionReq(request);
                        urlRequest = this.urlParamsService.requestPost(url, body);
                        return [4 /*yield*/, this.apiUtilService.requestNew(urlRequest)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapGoalSectionResponse(response.data)];
                }
            });
        });
    };
    StaffingPredictorApiService.prototype.saveGoal = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, urlRequest, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + staffingPredictorConfig.api.staffingPredictor.SavingGoal;
                        body = this.mapService.mapSaveGoalReq(request);
                        urlRequest = this.urlParamsService.requestPost(url, body);
                        return [4 /*yield*/, this.apiUtilService.requestNew(urlRequest)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapGoalresponse(response.data)];
                }
            });
        });
    };
    StaffingPredictorApiService.prototype.retrieveGoalDetails = function (request) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, urlRequest, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + staffingPredictorConfig.api.staffingPredictor.retrieveGoal;
                        body = this.mapService.mapToStaffinRatingOrganizationInfoRequestDto(request);
                        urlRequest = this.urlParamsService.createPostRequest(url, body);
                        return [4 /*yield*/, this.apiUtilService.request(urlRequest)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data != null ? this.mapService.mapGoalSectionResponse(response.data) : new GoalSettingResponse()];
                }
            });
        });
    };
    return StaffingPredictorApiService;
}());
export { StaffingPredictorApiService };
