import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter, SimpleChanges, } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { DeviceDetectorService, ModalService } from '../../../../common/services/index';
import { DateRange } from '../../../../core/models/index';
import { OpenPositionControlManagementService } from '../../../services';
import * as moment from 'moment';
import { OrgLevel } from '../../../../state-model/models';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { OpenPositionControlExcel } from './../../../../../app/scheduler/models/open-position-control/open-position-control-excel';
import { Day } from '@progress/kendo-date-math';
import { OpenPositionControlHelper } from '../open-position-control-helper';
var OpenPositionControlToolbarComponent = /** @class */ (function (_super) {
    tslib_1.__extends(OpenPositionControlToolbarComponent, _super);
    function OpenPositionControlToolbarComponent(managementService, devDetector, modalService) {
        var _this = _super.call(this, modalService) || this;
        _this.managementService = managementService;
        _this.devDetector = devDetector;
        _this.dateRange = new DateRange(null, null);
        _this.maxDateRange = 1 * 60 * 60 * 24 * 84; // max 84 days
        _this.maxDateRangeError = 'You cannot specify range more than 84 days';
        _this.minDate = moment().set({ 'year': 2000, 'month': 0, 'date': 1 }).toDate();
        _this.maxDate = moment().add(83, 'days').toDate();
        _this.startDatePlaceHolder = 'Select Week Starting';
        _this.endDatePlaceHolder = 'Select Week Ending';
        _this.subscriptions = {};
        _this.exportOpenPositionControlData = [];
        _this.topOrgLevels = [
            'Corporation',
            'Region',
            'Entity',
            'Division',
            'Company',
            'Business',
            'Operator',
            'State',
        ];
        _this.disableStartDate = [Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday];
        _this.disableEndDate = [Day.Sunday, Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday];
        _this.onDatesChanged = new EventEmitter();
        return _this;
    }
    Object.defineProperty(OpenPositionControlToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenPositionControlToolbarComponent.prototype, "dateRangeWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenPositionControlToolbarComponent.prototype, "newReviewBtnWidth", {
        get: function () {
            return this.isDesktop ? 175 : 35;
        },
        enumerable: true,
        configurable: true
    });
    OpenPositionControlToolbarComponent.prototype.ngOnInit = function () {
        this.defineDateRange();
        this.excelPositionDetails = this.openPositionDetails;
    };
    OpenPositionControlToolbarComponent.prototype.ngOnDestroy = function () { };
    OpenPositionControlToolbarComponent.prototype.ngOnChanges = function (changes) {
        if (changes['openPositionDetails']) {
            this.excelPositionDetails = this.openPositionDetails;
        }
    };
    OpenPositionControlToolbarComponent.prototype.onChangeDates = function (range) {
        this.managementService.changeDateRange(range);
    };
    OpenPositionControlToolbarComponent.prototype.defineDateRange = function () {
        this.dateRange = this.managementService.getDefaultDateRange();
        this.onChangeDates(this.dateRange);
    };
    OpenPositionControlToolbarComponent.prototype.emitDatesChanges = function (dateRange) {
        if (dateRange.endDate > this.maxDate)
            return;
        this.onDatesChanged.emit(dateRange);
    };
    OpenPositionControlToolbarComponent.prototype.onDatesRangeChanged = function ($event) {
        this.dateRange.startDate = $event.startDate;
        this.dateRange.endDate = $event.endDate;
        this.maxDate = moment(this.dateRange.startDate).add(83, 'days').toDate();
        this.emitDatesChanges($event);
    };
    OpenPositionControlToolbarComponent.prototype.getDateRange = function () {
        return 'Date Range: ' + moment(this.startDate).format('L') + ' - ' + moment(this.endDate).format('L');
    };
    OpenPositionControlToolbarComponent.prototype.exportToExcel = function () {
        var _this = this;
        this.exportOpenPositionControlData = [];
        var orgData = new OpenPositionControlExcel();
        orgData.organizationLevel = this.currentOrgLevel.name;
        orgData.totalHourGaps = this.excelPositionDetails.totalHoursGap;
        orgData.totalOpenShifts = this.excelPositionDetails.totalOpenShifts;
        // orgData.totalFTENeeded = this.excelPositionDetails.totalFTENeeded;
        var topLevelIndex = this.topOrgLevels.findIndex(function (orgType) { return orgType === _this.currentOrgLevel.type; });
        if (topLevelIndex >= 0) {
            this.exportOpenPositionControlData.push(orgData);
        }
        _.forEach(this.excelPositionDetails.openPositionDetails, function (organization) {
            if (organization.orgName) {
                var orgData_1 = new OpenPositionControlExcel();
                orgData_1.organizationLevel = organization.orgName;
                orgData_1.totalHourGaps = organization.totalHoursGap;
                orgData_1.totalOpenShifts = organization.totalOpenShifts;
                // orgData.totalFTENeeded = organization.totalFTENeeded;
                _this.exportOpenPositionControlData.push(orgData_1);
                if (organization.orgDepartmentDetails.length > 0) {
                    _.forEach(organization.orgDepartmentDetails, function (department) {
                        if (department.departmentName) {
                            var orgData_2 = new OpenPositionControlExcel();
                            orgData_2.organizationLevel = organization.orgName + ' > ' + department.departmentName;
                            orgData_2.totalHourGaps = department.totalHoursGap;
                            orgData_2.totalOpenShifts = department.totalOpenShifts;
                            // orgData.totalFTENeeded = department.totalFTENeeded;
                            _this.exportOpenPositionControlData.push(orgData_2);
                        }
                    });
                }
            }
        });
        this.excelExport.data = this.exportOpenPositionControlData;
        this.excelExport.save();
    };
    OpenPositionControlToolbarComponent.prototype.onKeydown = function (value) {
        if (value.keyCode >= 0) {
            return false;
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], OpenPositionControlToolbarComponent.prototype, "subscriptions", void 0);
    return OpenPositionControlToolbarComponent;
}(OpenPositionControlHelper));
export { OpenPositionControlToolbarComponent };
