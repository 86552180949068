<slx-spinner [show]="state.isLoading">
    <form #configurationForm="ngForm">
        <div class="body flex-container mt-5">

            <div class="content-section error-message" *ngIf="!mealDeductionSettings && !state.isLoading">
                <span>Can't load meal deduction settings</span>
            </div>

            <div class="content-section settings" *ngIf="mealDeductionSettings">
                <div class="row">
                    <div class="col col-sm-8">
                        <span class="settings-header">Types:</span>
                    </div>
                    <div class="col col-sm-4">
                        <span class="settings-header">Settings:</span>
                    </div>
                </div>
                <div class="h-divider mb-5"></div>
                <br>
                <div class="row">
                    <div class="col col-sm-8">
                        <label class="common-label">Date Applied for Overnight Shifts</label>
                    </div>
                    <div class="col col-sm-4" style="padding-bottom: 5px;">
                        <slx-dropdown-list
                        class="slx-wide slx-short config-mode"
                        [options]="shiftModes"
                        [(ngModel)]="shiftMode"
                        (ngModelChange)="onChangeShiftMode()"
                        (click)="mealDeductionModified()"
                        [readonly]="!mealDeductionSettings.canConfigure"
                        name="shiftMode"
                    ></slx-dropdown-list>
                    </div>
                </div>
                <div *ngIf="isMidShift">
                    <div class="row">
                        <div class="col col-sm-8">
                            <p class="align-center">
                                Shift 1 Mid-Point
                            </p>
                        </div>
                        <div class="col col-sm-4">
                            <p class="title-text">
                                <slx-number class="slx-wide"
                                    #shiftOneField="ngModel"
                                    [slxMin]="shiftOneMin"
                                    [slxMax]="shiftOneMax"
                                    [step]="step"
                                    [autoCorrect]='false'
                                    [readonly]="!mealDeductionSettings.canConfigure"
                                    [(ngModel)]="mealDeductionSettings.midShiftOne"
                                    name="shiftOneField"
                                    [required]="true"
                                    (ngModelChange)="updateFormValidity()"
                                ></slx-number>
                                <span *ngIf="shiftOneField.errors" class="slx-error-block error-box">
                                    <span *ngIf="shiftOneField.errors.required" errorMessage for="required"></span>
                                    <span *ngIf="shiftOneField.errors.min" errorMessage for="min">Entry should be between {{shiftOneMin}} and {{shiftOneMax}}</span>
                                    <span *ngIf="shiftOneField.errors.max" errorMessage for="max">Entry should be between {{shiftOneMin}} and {{shiftOneMax}}</span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col col-sm-8">
                            <p class="left-col">
                                Shift 2 Mid-Point
                            </p>
                        </div>
                        <div class="col col-sm-4">
                            <p class="title-text right-col in-block">
                                <slx-number class="slx-wide"
                                    #shiftTwoField="ngModel"
                                    [slxMin]="shiftTwoMin"
                                    [slxMax]="shiftTwoMax"
                                    [step]="step"
                                    [autoCorrect]='false'
                                    [readonly]="!mealDeductionSettings.canConfigure"
                                    [(ngModel)]="mealDeductionSettings.midShiftTwo"
                                    name="shiftTwoField"
                                    [required]="true"
                                    (ngModelChange)="updateFormValidity()"
                                ></slx-number>
                                <span *ngIf="shiftTwoField.errors" class="slx-error-block error-box">
                                    <span *ngIf="shiftTwoField.errors.required" errorMessage for="required"></span>
                                    <span *ngIf="shiftTwoField.errors.min" errorMessage for="min">Entry should be between {{shiftTwoMin}} and {{shiftTwoMax}}</span>
                                    <span *ngIf="shiftTwoField.errors.max" errorMessage for="max">Entry should be between {{shiftTwoMin}} and {{shiftTwoMax}}</span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-sm-8">
                        <span>Max Threshold (in minutes) between punch pairs to consider as a single shift</span>
                    </div>
                    <div class="col col-sm-4">
                        <div class="punch-treshold-control">
                            <div class="row">
                                <div class="col col-sm-8">
                                    <slx-number name="maxPunchTreshold" [(ngModel)]="mealDeductionSettings.maxPunchTreshold"
                                        [min]="minTreshold" [max]="maxTreshold" autoCorrect="true"
                                        [readonly]="!mealDeductionSettings.canConfigure"
                                        [required]="true"
                                        (ngModelChange)="mealDeductionModified()"
                                        >
                                    </slx-number>

                                </div>
                                <div class="col col-sm-4">
                                    <span>minutes</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="padding-bottom: 5px;">
                    <div class="col col-sm-8">
                        <span>Enable Meal Deductions for Non Exempt Staff</span>
                    </div>
                    <div class="col col-sm-4">
                        <slx-radio-button class="p-left-0" name="enableNonExempt" fieldName="enableForNonExempt"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForNonExempt"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="disableNonExempt" fieldName="disableNonExempt"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForNonExempt"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-sm-8">
                        <span>Enable Meal Deductions for Exempt type "Scheduled Time"</span>
                    </div>
                    <div class="col col-sm-4" style="padding-bottom: 5px;">
                        <slx-radio-button class="p-left-0" name="enableForExemptS" fieldName="enableForExemptS"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptS"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptS" fieldName="enableForExemptS"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptS"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-sm-8" style="padding-bottom: 5px;">
                        <span>Enable Meal Deductions for Exempt type "Use Timesheets"</span>
                    </div>
                    <div class="col col-sm-4">
                        <slx-radio-button class="p-left-0" name="enableForExemptT" fieldName="enableForExemptT"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptT"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptT" fieldName="enableForExemptT"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptT"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-sm-8" style="padding-bottom: 5px;">
                        <span>Enable Meal Deductions for Exempt status "Scheduled Time with Generated Punches"</span>
                    </div>
                    <div class="col col-sm-4">
                        <slx-radio-button class="p-left-0" name="enableForExemptX" fieldName="enableForExemptX"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptX"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptX" fieldName="enableForExemptX"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptX"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-sm-8" >
                        <span>Enable Meal Deductions for Exempt status of "Weekly Hours spread evenly across weekdays"</span>
                    </div>
                    <div class="col col-sm-4" style="padding-bottom: 5px;">
                        <slx-radio-button class="p-left-0" name="enableForExemptY" fieldName="enableForExemptY"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForExemptY"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForExemptY" fieldName="enableForExemptY"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForExemptY"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                    </div>
                </div>
                <div class="row">
                    <div class="col col-sm-8" style="padding-bottom: 5px;">
                        <span>Enable Meal Deduction for Agency Staff</span>
                    </div>
                    <div class="col col-sm-4">
                        <slx-radio-button class="p-left-0" name="enableForAgency" fieldName="enableForAgency"
                            [option]="true" caption="Yes" [(ngModel)]="mealDeductionSettings.enableForAgency"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                        <slx-radio-button class="p-left-0" name="enableForAgency" fieldName="enableForAgency"
                            [option]="false" caption="No" [(ngModel)]="mealDeductionSettings.enableForAgency"
                            [readonly]="!mealDeductionSettings.canConfigure"
                            (ngModelChange)="mealDeductionModified()"
                            >
                        </slx-radio-button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </slx-spinner>
