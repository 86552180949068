import * as _ from 'lodash';
var OpenShiftSummary = /** @class */ (function () {
    function OpenShiftSummary() {
    }
    Object.defineProperty(OpenShiftSummary.prototype, "shiftCount", {
        get: function () {
            if (this.details) {
                return _.sumBy(this.details, function (detailsInfo) {
                    return detailsInfo.openShiftCount;
                });
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftSummary.prototype, "shiftCountPartial", {
        get: function () {
            var returnedOpenCount = 0;
            if (this.details) {
                _.forEach(this.details, function (detailsInfo) {
                    if (detailsInfo.parentShiftId === -1) {
                        returnedOpenCount += detailsInfo.calculatedOpenShiftCount;
                    }
                });
            }
            return returnedOpenCount;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftSummary.prototype, "newMessageCount", {
        get: function () {
            if (this.details) {
                return _.sumBy(this.details, function (detailsInfo) {
                    return detailsInfo.newMessageCount;
                });
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftSummary.prototype, "partnerShiftCount", {
        get: function () {
            if (this.details) {
                return _.sumBy(this.details, function (detailsInfo) {
                    return detailsInfo.partnerShiftCount;
                });
            }
            return 0;
        },
        enumerable: true,
        configurable: true
    });
    return OpenShiftSummary;
}());
export { OpenShiftSummary };
