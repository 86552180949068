import * as _ from 'lodash';
import { Injectable, Injector, ApplicationRef, ComponentFactoryResolver, ElementRef, ComponentRef, EmbeddedViewRef, TemplateRef, ViewContainerRef, InjectionToken, Inject, Optional, Provider, ResolvedReflectiveProvider, ReflectiveInjector, Type, EventEmitter, ComponentFactory } from '@angular/core';
import { PopupRef, PopupComponent } from '@progress/kendo-angular-popup';
import { PopupSettings } from '../../models/popup-settings';

export const POPUP_CONTAINER2 = new InjectionToken('Popup Container 2');

export const removeElement = function (element: HTMLElement): void {
    if (element && element.parentNode) {
        element.parentNode.removeChild(element);
    }
};

@Injectable()
export class PopupService {

    private get rootViewContainer(): ComponentRef<any> {
        let rootComponents = this.applicationRef.components || [];
        if (rootComponents[0]) {
            return rootComponents[0];
        }
        throw new Error('View Container not found! Inject the POPUP_CONTAINER or define a specific ViewContainerRef via the appendTo option.\n See http://www.telerik.com/kendo-angular-ui/components/popup/api/POPUP_CONTAINER/ for more details');
    }

    private get rootViewContainerNode(): HTMLElement {
        return this.container ? this.container.nativeElement : this.getComponentRootNode(this.rootViewContainer);
    }

    protected providers: Provider[];

    constructor(
        private applicationRef: ApplicationRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private injector: Injector,
        @Inject(POPUP_CONTAINER2) @Optional() private container?: ElementRef
    ) { }

    public openWithWindowWrapper<T>(wrapperType: Type<T>, options?: PopupSettings): { wrapper: ComponentRef<T>, ref: PopupRef } {

        if (options === void 0) { options = {}; }
        if (options.providers) {
            this.providers = options.providers;
        } else {
            this.providers = undefined;
        }
        let component: ComponentRef<any>;
        let nodes: any[];

        component = this.createComponent(options.content, undefined, undefined, this.providers);
        nodes = [component ? [component.location.nativeElement] : []];

        //create wrapper 
        let wrapper: ComponentRef<any> = this.createComponent(wrapperType, nodes);
        let wrapperNodes: any[] = [wrapper ? [wrapper.location.nativeElement] : []];

        let popupComponentRef: ComponentRef<any> = this.appendPopup(wrapperNodes, options.appendTo);
        let popupInstance = popupComponentRef.instance;
        this.projectComponentInputs(popupComponentRef, options);

        popupComponentRef.changeDetectorRef.detectChanges();
        if (wrapper) {
            wrapper.changeDetectorRef.detectChanges();
        }
        if (component) {
            component.changeDetectorRef.detectChanges();
        }

        return {
            wrapper: wrapper,
            ref: {
                close: () => {
                    if (component) {
                        component.destroy();
                    }
                    if (wrapper) {
                        wrapper.destroy();
                    } else {
                        popupComponentRef.instance.content = null;
                        popupComponentRef.changeDetectorRef.detectChanges();
                    }
                    popupComponentRef.destroy();
                },
                content: component,
                popup: popupComponentRef,
                popupAnchorViewportLeave: popupInstance.anchorViewportLeave,
                popupClose: popupInstance.close,
                popupElement: this.getComponentRootNode(popupComponentRef),
                popupOpen: popupInstance.open,
                popupPositionChange: popupInstance.positionChange
            }
        };
    }

    public open(options?: PopupSettings): PopupRef {
        if (options === void 0) { options = {}; }
        if (options.providers) {
            this.providers = options.providers;
        } else {
            this.providers = undefined;
        }
        let content: { component: ComponentRef<any>, nodes: any[] } = this.contentFrom(options.content);
        let component = content.component;
        let nodes: any[] = content.nodes;
        let popupComponentRef: ComponentRef<any> = this.appendPopup(nodes, options.appendTo);
        let popupInstance = popupComponentRef.instance;
        this.projectComponentInputs(popupComponentRef, options);
        popupComponentRef.changeDetectorRef.detectChanges();
        if (component) {
            component.changeDetectorRef.detectChanges();
        }
        const popupElement = this.getComponentRootNode(popupComponentRef);
        return {
            close: () => {
                if (component) {
                    component.destroy();
                } else {
                    popupComponentRef.instance.content = null;
                    popupComponentRef.changeDetectorRef.detectChanges();
                }
                popupComponentRef.destroy();
                removeElement(popupElement);
            },
            content: component,
            popup: popupComponentRef,
            popupAnchorViewportLeave: popupInstance.anchorViewportLeave,
            popupClose: popupInstance.close,
            popupElement: this.getComponentRootNode(popupComponentRef),
            popupOpen: popupInstance.open,
            popupPositionChange: popupInstance.positionChange
        };
    }

    private projectComponentInputs(component: ComponentRef<any>, options: PopupSettings): void {
        /*
        Object.getOwnPropertyNames(options)
            .filter(function (prop) { return prop !== 'content' || options.content instanceof TemplateRef; })
            .map(function (prop) {
                component.instance[prop] = options[prop];
            });
         */
        let props: string[] = _.filter(_.keys(options), (key: string) => key !== 'content' && key !== 'providers');
        _.each(props, (prop: string) => {
            component.instance[prop] = _.get(options, prop);
        });
    }

    private appendPopup(nodes?: any[], container?: ViewContainerRef): ComponentRef<PopupComponent> {
        let appRef = this.applicationRef;
        let popupComponentRef: ComponentRef<PopupComponent> = this.createComponent(PopupComponent, nodes, container);
        if (!container) {
            appRef.attachView(popupComponentRef.hostView);
            this.rootViewContainerNode.appendChild(this.getComponentRootNode(popupComponentRef));
        }
        return popupComponentRef;
    }

    private getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
        return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0];
    }

    private getComponentFactory(componentClass: any): ComponentFactory<any> {
        return this.componentFactoryResolver.resolveComponentFactory(componentClass);
    }

    private createComponent(componentClass: any, nodes?: any[], container?: ViewContainerRef, providers?: Provider[]): ComponentRef<any> {
        let factory = this.getComponentFactory(componentClass);
        let optionalInjector: ReflectiveInjector;
        if (providers) optionalInjector = this.mergeProviders();
        if (container) {
            return container.createComponent(factory, undefined, optionalInjector || this.injector, nodes);
        } else {
            let component = factory.create(optionalInjector || this.injector, nodes);
            this.applicationRef.attachView(component.hostView);
            return component;
        }
    }

    private mergeProviders(): ReflectiveInjector {
        let resolvedProviders: ResolvedReflectiveProvider[] = ReflectiveInjector.resolve(this.providers);
        let optionalInjector: ReflectiveInjector = ReflectiveInjector.fromResolvedProviders(resolvedProviders, this.injector);
        return optionalInjector;
    }

    private contentFrom(content?: any): { component: ComponentRef<any>, nodes: any[] } {
        if (!content || content instanceof TemplateRef) {
            return { component: null, nodes: [[]] };
        }
        let component = this.createComponent(content, undefined, undefined, this.providers);
        let nodes = component ? [component.location.nativeElement] : [];
        return {
            component: component,
            nodes: [
                nodes // <ng-content>
            ]
        };
    }
}
