/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report-daily-attendance-config-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../report-attendance-config/report-attendance-config.component.ngfactory";
import * as i3 from "../../report-attendance-config/report-attendance-config.component";
import * as i4 from "../../../services/reports-management.service";
import * as i5 from "@angular/common";
import * as i6 from "./report-daily-attendance-config-dialog.component";
import * as i7 from "../../../models/report-definition";
import * as i8 from "../../../../common/models/dialog-options";
import * as i9 from "../../../../common/services/modal/modal.service";
var styles_ReportDailyAttendanceConfigDialogComponent = [i0.styles];
var RenderType_ReportDailyAttendanceConfigDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportDailyAttendanceConfigDialogComponent, data: {} });
export { RenderType_ReportDailyAttendanceConfigDialogComponent as RenderType_ReportDailyAttendanceConfigDialogComponent };
export function View_ReportDailyAttendanceConfigDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-report-attendance-config", [], null, [[null, "hasValueChanged"], [null, "reportConfigChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("hasValueChanged" === en)) {
        var pd_0 = (_co.onValueChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("reportConfigChanged" === en)) {
        var pd_1 = (_co.onReportConfigChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_ReportAttendanceConfigComponent_0, i2.RenderType_ReportAttendanceConfigComponent)), i1.ɵdid(2, 245760, null, 0, i3.ReportAttendanceConfigComponent, [i4.ReportsManagementService], { forcedLoad: [0, "forcedLoad"] }, { hasValueChanged: "hasValueChanged", reportConfigChanged: "reportConfigChanged" }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "modal-footer wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "btn red pad-top-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel "])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "btn green pad-top-10"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "disabled-content": 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.forcedLoad; _ck(_v, 2, 0, currVal_0); var currVal_1 = "btn green pad-top-10"; var currVal_2 = _ck(_v, 10, 0, !_co.hasValueChange); _ck(_v, 9, 0, currVal_1, currVal_2); }, null); }
export function View_ReportDailyAttendanceConfigDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-report-daily-attendance-config-dialog", [], null, null, null, View_ReportDailyAttendanceConfigDialogComponent_0, RenderType_ReportDailyAttendanceConfigDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.ReportDailyAttendanceConfigDialogComponent, [i7.ReportDefinition, i7.ConfigForceLoad, i8.DialogOptions, i9.ModalService], null, null)], null, null); }
var ReportDailyAttendanceConfigDialogComponentNgFactory = i1.ɵccf("slx-report-daily-attendance-config-dialog", i6.ReportDailyAttendanceConfigDialogComponent, View_ReportDailyAttendanceConfigDialogComponent_Host_0, {}, {}, []);
export { ReportDailyAttendanceConfigDialogComponentNgFactory as ReportDailyAttendanceConfigDialogComponentNgFactory };
