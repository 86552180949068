import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { RangeDates } from '../../../../common/models/range-dates';
import { StaffingPredictorManagementService } from '../../services/index';
import { DateRange } from '../../../../core/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { StaffingRatingContainer, StaffingRatingOrganization } from '../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { Lookup, Organization, LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { IControlState, StateResetTypes } from '../../../../core/models/settings/index';
import { AppSettingsManageService } from '../../../../app-settings/services/index';

export interface ExportYear {
    id: number;
    year: number;
}
export interface ExportRangeType {
    id: string;
    text: string;
}
export enum PBJExportRange {
    Q1 = 'Q1', Q2 = 'Q2', Q3 = 'Q3', Q4 = 'Q4'
}
export enum PBJExportRange22 {
    Q2 = 'Q2', Q3 = 'Q3', Q4 = 'Q4'
}

@Component({
    moduleId: module.id,
    selector: 'slx-staffing-predictor-header',
    templateUrl: 'staffing-predictor-header.component.html',
    styleUrls: ['staffing-predictor-header.component.scss'],    
    providers: [StateManagementService]
})

export class StaffingPredictorHeaderComponent implements OnInit {

    @Input()
    public five_star_v2_enabled: boolean = false;
    @Input()
    public five_star_goal_section_enabled:boolean = false;
    public container: StaffingRatingContainer;
    public currentRange: RangeDates;
    public get organizationLookup(): Lookup {
        return this.m_organizationLookup;
    }
    public selectedOrganizations: Organization[] = [];
    public loadedOrganizations: Organization[] = [];
    public maximumRangeError: string = 'Maximum range is 92 days';
    public orgLevel: OrgLevel;

    @mutableSelect('orgLevel')
    public orgLevel$: Observable<OrgLevel>;
    public yearsList: ExportYear[];
    public selectedYear: ExportYear;
    public exportRangeTypes: Array<ExportRangeType>;
    public exportRangeTypes22: Array<ExportRangeType>;
    public exportRangeType: ExportRangeType;

    @unsubscribe()
    private orgLevelSubscription: Subscription;

    @unsubscribe()
    private loadedSubscription: Subscription;

    @unsubscribe()
    private hideSubscription: Subscription;

    private m_organizationLookup: Lookup;
    
    @Output()
    public exportClick: EventEmitter<number> = new EventEmitter();

    @Output()
    public yearSelected: EventEmitter<number> = new EventEmitter();

    @Output()
    public periodSelected: EventEmitter<string> = new EventEmitter();

    @Output()
    public orgSelected: EventEmitter<string> = new EventEmitter();
    
    private datesControlKey: string;
    private resetBy: StateResetTypes = StateResetTypes.SessionEnd;
    private standardQuarters1: string[];
    public hideOnPrint: boolean = false;

    constructor(private managementService: StaffingPredictorManagementService, private lookupService: LookupService, private changeDetector: ChangeDetectorRef
        , private storageService: ComponentStateStorageService
        , private stateManagement: StateManagementService
        , private orgLevelWatchService: OrgLevelWatchService
        , private appSettingsManageService: AppSettingsManageService) {
            const currentYear: number = moment().year();
            const years: number[] = _.range(2022, currentYear + 2, 1);
            this.yearsList = _.map(years, (year: number) => ({ id: year, year: year }));
            
            if (currentYear == 2022) {
                this.exportRangeTypes22 = _.map(PBJExportRange22, (expRangeId: string) => ({ id: expRangeId, text: this.exportRangeTexts22[expRangeId] }));
            } else {
                this.exportRangeTypes = _.map(PBJExportRange, (expRangeId: string) => ({ id: expRangeId, text: this.exportRangeTexts[expRangeId] }));
            }
            this.datesControlKey = 'fiscalYearPeriod';

            this.standardQuarters1 = [];
            this.standardQuarters1.push("Q1");
            this.standardQuarters1.push("Q2");
            this.standardQuarters1.push("Q3");
            this.standardQuarters1.push("Q4");
    }

    public ngOnInit(): void {
        this.stateManagement.init('StaffingPredictorHeaderComponent', true);
        this.orgLevelSubscription = this.orgLevel$
        .combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$, this.stateManagement.onInit$)
        .map((value: [OrgLevel, boolean, any]) => value[0])
        .subscribe((orgLevel: OrgLevel) => {
            if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
                this.orgLevel = orgLevel;
                this.container = null;

                this.appSettingsManageService.getAppServerConfig().then((config) => {
                    this.five_star_v2_enabled = config.five_star_v2_enabled;
                    this.five_star_goal_section_enabled = config.five_star_goal_section_enabled
                    this.clearCache();
                    this.loadOrganizations();
                    this.restoreDates();
                });
            }
        });

        this.loadedSubscription = this.managementService.onLoaded$
        .retryWhen((err) => err.delay(1000))
        .subscribe((data: StaffingRatingContainer) => {
            this.container = data;
            // this.updateSelectedFilter();
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        });

        const range: DateRange = new DateRange(this.managementService.container.startDate, this.managementService.container.endDate);
        this.currentRange = range;
        this.hideSubscription = this.managementService.hideOnPrint$.subscribe((hide: boolean) => {
            this.hideOnPrint = hide;
        });
    }

    public filterOrgChanged(evnet: any): void {
        this.loadedOrganizations = _.slice(this.selectedOrganizations);

        let startDate = this.currentRange.startDate;
        let endDate = this.currentRange.endDate;
        let rang: RangeDates = {startDate, endDate};
        this.onRangeChanged(rang);
    }

    public onRangeChanged(newRange: RangeDates, isOrgChanged: boolean = false): void {
        if (newRange) {
            this.managementService.setStartDate = newRange.startDate;
            this.managementService.setEndDate = newRange.endDate;
            this.managementService.loadRatingList(newRange.startDate, newRange.endDate, _.map(this.loadedOrganizations, (o) => o.id), this.five_star_v2_enabled, this.five_star_goal_section_enabled, isOrgChanged)
        }
    }

    public clearCache() {
        let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
        let qua = _.get(state.value, 'exportRange', null);
        let yr = _.get(state.value, 'selYear', null);
        if (qua && yr && ((this.exportRangeType && this.exportRangeType.text != qua.text) || (this.selectedYear && this.selectedYear.year != yr.year))) {
            this.storageService.setControlState(this.stateManagement.componentKey, this.datesControlKey, { value: null }, this.resetBy);
            this.managementService.clearCache();
        }
    }

    public loadOrganizations(): void {
        if (!this.orgLevel) {
            return;
        }
        this.lookupService.getLookup({ lookupType: LookupType.organization, orgLevelId: this.orgLevel.id, employeeId: undefined })
            .then((value: Lookup) => {
                this.m_organizationLookup = value;
                this.updateSelectedFilter();
                let rang: RangeDates;

                if (this.five_star_v2_enabled) {
                    let logicalyear = this.exportRangeType.id == 'Q1' ? this.selectedYear.year -1 : this.selectedYear.year;
                    let logicalmonthStart = this.startQ[this.exportRangeType.id];

                    let startDate = moment(logicalyear, "YYYY").quarter(this.currentQuarter[logicalmonthStart]).startOf('quarter').toDate();
                    let endDate = moment(logicalyear, "YYYY").quarter(this.currentQuarter[logicalmonthStart]).endOf('quarter').toDate();
                    rang = {startDate, endDate};
                } else {
                    const startDate = this.managementService.container.startDate;
                    const endDate = this.managementService.container.endDate;
                    rang = {startDate, endDate};
                    // this.managementService.loadRatingList(startDate, endDate, _.map(this.loadedOrganizations, (o) => o.id), this.five_star_v2_enabled);
                }
                this.onRangeChanged(rang,true);
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            });
    }

    private updateSelectedFilter(): void {
        if (this.m_organizationLookup && this.container) {
            let selectedOrganizations: Organization[] = [];
            _.each(this.m_organizationLookup.items, (org: Organization) => {
                let staffingRatingOrganization = _.find(this.container.organizations, (staff: StaffingRatingOrganization) => staff.organization.id === org.id);
                if (staffingRatingOrganization != null) {
                    selectedOrganizations.push(org);
                }
            });
            this.selectedOrganizations = selectedOrganizations.length > 0 ? selectedOrganizations : [];
            this.loadedOrganizations = _.slice(this.selectedOrganizations);
            if (_.size(this.selectedOrganizations) === _.size(this.m_organizationLookup.items)) {
                this.selectedOrganizations = [];
                this.loadedOrganizations = this.m_organizationLookup.items;
            }
        } else if (this.m_organizationLookup) {
            this.selectedOrganizations = [];
            this.loadedOrganizations = this.m_organizationLookup.items;
        }
    }

    public onSelectYear(year: ExportYear): void {
        if (_.isObject(year)) {
            if (year.year == 2022) {
                this.exportRangeTypes = _.map(PBJExportRange22, (expRangeId: string) => ({ id: expRangeId, text: this.exportRangeTexts22[expRangeId] }));
                this.exportRangeType = _.first(this.exportRangeTypes);
            } else {
                this.exportRangeTypes = _.map(PBJExportRange, (expRangeId: string) => ({ id: expRangeId, text: this.exportRangeTexts[expRangeId] }));
                this.exportRangeType = _.first(this.exportRangeTypes);
            }
        }
    }

    public onChangeExportRange(exportRange: ExportRangeType): void {
        if (_.isObject(exportRange)) {
        //   this.request.exportRange = exportRange.id as PBJExportRange;
        //   if (exportRange.id === 'Custom') {
        //     this.dateRangeCorrect = this.isValidDateRange(this.request.startDate, this.request.endDate);
        //     return;
        //   }
        //   this.dateRangeCorrect = true;
        }
    }

    public currentQuarter = {
        1: 1, // January
        2: 1, // February
        3: 1, // March
     
        4: 2, // April
        5: 2, // May
        6: 2, // June
     
        7: 3, // July
        8: 3, // August
        9: 3, // September
     
        10: 4, // October
        11: 4, // November
        12: 4, // December
     }

    public startQ = {
        Q1: 10,
        Q2: 1,
        Q3: 4,
        Q4: 7
    }

    public applyFilter(): void {
        this.clearCache();
        this.saveDates();
        this.yearSelected.emit();
        this.periodSelected.emit();
        
        let logicalyear = this.exportRangeType.id == 'Q1' ? this.selectedYear.year -1 : this.selectedYear.year;
        let logicalmonthStart = this.startQ[this.exportRangeType.id];

        let startDate = moment(logicalyear, "YYYY").quarter(this.currentQuarter[logicalmonthStart]).startOf('quarter').toDate();
        let endDate = moment(logicalyear, "YYYY").quarter(this.currentQuarter[logicalmonthStart]).endOf('quarter').toDate();
        let rang: RangeDates = {startDate, endDate};
        this.onRangeChanged(rang);
    }

    public onExport() {
        this.exportClick.emit();
    }

    private saveDates(): void {
        const exportRange = this.exportRangeType;
        const selYear = this.selectedYear;
        this.storageService.setControlState(this.stateManagement.componentKey, this.datesControlKey, { value: { exportRange, selYear } }, this.resetBy);
    }
    
    private restoreDates(): void {
        let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
        let startDate = _.get(state.value, 'exportRange', null);
        let endDate = _.get(state.value, 'selYear', null);
        if (startDate && endDate) {
            this.exportRangeType = startDate;
            this.selectedYear = endDate;
        } else {
            this.assignDates();
            this.saveDates();
        }
        this.yearSelected.emit(this.selectedYear.year);
        this.periodSelected.emit(this.exportRangeType.id);
        this.orgSelected.emit(this.orgLevel.name);
    }

    private createQuarter(sDate: Date, eDate: Date): RangeDates {
        let dr: RangeDates = new RangeDates();
        sDate.setHours(0, 0, 0);
        eDate.setHours(23, 59, 59);
        dr.startDate = sDate;
        dr.endDate = eDate;
        return dr;
    }

    public exportRangeTexts: StringMap<string> = {
        'Q1': 'Q1:Oct-Dec',
        'Q2': 'Q2:Jan-Mar',
        'Q3': 'Q3:Apr-Jun',
        'Q4': 'Q4:Jul-Sep'
    };
    public exportRangeTexts22: StringMap<string> = {
        'Q2': 'Q2:Jan-Mar',
        'Q3': 'Q3:Apr-Jun',
        'Q4': 'Q4:Jul-Sep'
    }

    private assignDates(): void {
        let today: Date = new Date();
        let qIndex: number = Math.ceil((today.getMonth() + 1) / 3) - 1;
        let q: string = this.standardQuarters1[qIndex];

        if (moment().year() == 2022) {
            this.exportRangeType = _.find(this.exportRangeTypes22, (expRange: ExportRangeType) => expRange.id === q);
        } else {
            this.exportRangeType = _.find(this.exportRangeTypes, (expRange: ExportRangeType) => expRange.id === q);
        }
        this.selectedYear = _.find(this.yearsList, x => x.id == today.getFullYear());
    }
}
