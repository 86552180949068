/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-swap-list-item-profile.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../organization/directives/employee-thumbinal-src/employee-thumbinal-src.directive";
import * as i3 from "../../../../organization/services/employee/employee-images.service";
import * as i4 from "@angular/common";
import * as i5 from "angular2-moment/date-format.pipe";
import * as i6 from "./shift-swap-list-item-profile.component";
var styles_ShiftSwapListItemProfileComponent = [i0.styles];
var RenderType_ShiftSwapListItemProfileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftSwapListItemProfileComponent, data: {} });
export { RenderType_ShiftSwapListItemProfileComponent as RenderType_ShiftSwapListItemProfileComponent };
function View_ShiftSwapListItemProfileComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "image-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["alt", "Profile photo"], ["class", "rounded-image avatar"]], null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.EmployeeThumbinalSrcDirective, [i1.ElementRef, i1.Renderer, i3.EmployeeImagesService], { employeeThumbinalSrc: [0, "employeeThumbinalSrc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.id; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShiftSwapListItemProfileComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "swap-profile-text swap-flex-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "swap-profile-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "swap-date-container swap-flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-calendar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "div", [["class", "swap-profile-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "shift-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shift: "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "shift-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " "])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "shift-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["(", ") "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "unit-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 0), _co.profile.shiftDate, _co.appConfig.dateFullMonthFormat)); _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.profile.shiftName; _ck(_v, 12, 0, currVal_2); var currVal_3 = _co.profile.unitName; _ck(_v, 14, 0, currVal_3); }); }
function View_ShiftSwapListItemProfileComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "swap-no-profile swap-flex-horizontal"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "swap-profile-icon fa-stack fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-users fa-stack-1x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-circle fa-stack-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "swap-profile-name swap-no-profile-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Waiting for someone to accept"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "swap-no-profile swap-flex-horizontal"; var currVal_1 = _co.colorStyle; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ShiftSwapListItemProfileComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "swap-flex-horizontal swap-profile-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_3)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileExists; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.profileExists; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.profileExists; _ck(_v, 6, 0, currVal_2); }, null); }
function View_ShiftSwapListItemProfileComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "img", [["alt", "Profile photo"], ["class", "rounded-image mini-avatar"]], null, null, null, null, null)), i1.ɵdid(2, 540672, null, 0, i2.EmployeeThumbinalSrcDirective, [i1.ElementRef, i1.Renderer, i3.EmployeeImagesService], { employeeThumbinalSrc: [0, "employeeThumbinalSrc"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.id; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ShiftSwapListItemProfileComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "swap-profile-mini-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ShiftSwapListItemProfileComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "swap-no-profile swap-flex-vertical"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "swap-profile-mini-icon fa-stack fa-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-users fa-stack-1x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-circle fa-stack-2x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "swap-profile-mini-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Waiting for someone to accept"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "swap-no-profile swap-flex-vertical"; var currVal_1 = _co.colorStyle; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ShiftSwapListItemProfileComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "swap-flex-vertical swap-profile-mini-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_6)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_7)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_8)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileExists; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.profileExists; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.profileExists; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ShiftSwapListItemProfileComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DateFormatPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShiftSwapListItemProfileComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.miniProfile; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.miniProfile; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ShiftSwapListItemProfileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-shift-swap-list-item-profile", [], null, null, null, View_ShiftSwapListItemProfileComponent_0, RenderType_ShiftSwapListItemProfileComponent)), i1.ɵdid(1, 49152, null, 0, i6.ShiftSwapListItemProfileComponent, [], null, null)], null, null); }
var ShiftSwapListItemProfileComponentNgFactory = i1.ɵccf("slx-shift-swap-list-item-profile", i6.ShiftSwapListItemProfileComponent, View_ShiftSwapListItemProfileComponent_Host_0, { profile: "profile", colorStyle: "colorStyle", miniProfile: "miniProfile" }, {}, []);
export { ShiftSwapListItemProfileComponentNgFactory as ShiftSwapListItemProfileComponentNgFactory };
