import { EmployeeSectionsBase } from '../employee-sections-base';
import {
  IBenefitDependentRelation,
  BenefitDependentRelation,
  IBenefitEmployeeDependent,
  BenefitEmployeeDependent
} from '../../../../app-modules/benefits/models/index';


export interface IEmployeeSubsectionDependents {
  empDependents: IBenefitEmployeeDependent[];
  relations: IBenefitDependentRelation[];
  canAddDependents: boolean;
  canRemoveDependents: boolean;
  canEditDependents: boolean;
  canEnrollDependents: boolean;
  canDropCoverageDependents: boolean;
}

export class EmployeeSubsectionDependents extends EmployeeSectionsBase {
  public dependents: BenefitEmployeeDependent[] = [];
  public relations: BenefitDependentRelation[] = [];
  public canAddDependents = false;
  public canRemoveDependents = false;
  public canEditDependents = false;
  public canEnrollDependents = false;
  public canDropCoverageDependents = false;
}
