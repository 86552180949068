import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IApplicationConfig } from './../../../../app.config';
import { KendoGridStateHelper } from './../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { OpenShiftDetails } from './../../../models/open-shift-management/open-shift-details';
import { EmployeeMessage } from './../../../models/open-shift-management/employee-message';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-partial-shifts-grid',
  templateUrl: './daily-partial-shifts-grid.component.html',
  styleUrls: ['./daily-partial-shifts-grid.component.scss']
})
export class DailyPartialShiftsGridComponent implements OnDestroy {
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<OpenShiftDetails>;
  public partialShifts: OpenShiftDetails[];

  @Input()
  public set partials(value: OpenShiftDetails[]) {
      this.partialShifts = value;
      this.refreshGrid();
  }
  public get partials(): OpenShiftDetails[] {
    return this.partialShifts;
  }
  
  @Output() itemRemove = new EventEmitter<EmployeeMessage>();

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  
  constructor() { 
    this.gridState = new KendoGridStateHelper();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.gridState.state.group = [];

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: any): void => {
      this.refreshGrid();
    });
  }

  private refreshGrid(): void {
    if (!this.partialShifts) {
     this.gridState.view = null;
     return;
   }

   this.gridState.state.take = 24;
   this.gridState.view = process(this.partialShifts, this.gridState.state);
 }

 public removeItem(item : EmployeeMessage,groupDetails: OpenShiftDetails){
    this.partialShifts.filter(x => x === groupDetails).forEach(ele =>{
      ele.messages = ele.messages.filter(y=> y!== item);
    })
    this.refreshGrid();
 }

  public ngOnDestroy(): void {
  }
}
