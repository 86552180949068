import * as tslib_1 from "tslib";
import { OpenShiftDetails } from './../../../models/open-shift-management/open-shift-details';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { EmployeeMessagesApiService } from './../../../../employee/employee/services/employee-messages/employee-messages-api.service';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { appConfig } from './../../../../app.config';
import { EmployeeMessageState } from './../../../models/open-shift-management/employee-message';
import { KendoGridStateHelper } from './../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { OnDestroy, EventEmitter } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { OpenShiftManagementApiService } from '../../../../../app/scheduler/services';
import { mutableSelect } from '../../../../core/decorators';
import { Observable } from 'rxjs-compat';
import { NotificationsService } from '../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
var DailyShiftMessagesGridComponent = /** @class */ (function () {
    function DailyShiftMessagesGridComponent(employeeMessagesApiService, openShiftManagementManagementService, appSettingsManageService, openShiftAPi, notificationsService) {
        var _this = this;
        this.employeeMessagesApiService = employeeMessagesApiService;
        this.openShiftManagementManagementService = openShiftManagementManagementService;
        this.appSettingsManageService = appSettingsManageService;
        this.openShiftAPi = openShiftAPi;
        this.notificationsService = notificationsService;
        this.isPartnerShift = false;
        this.hasPartialShift = false;
        this.itemRemove = new EventEmitter();
        this.records = [];
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.pageSize = 100;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    }
    Object.defineProperty(DailyShiftMessagesGridComponent.prototype, "summary", {
        get: function () {
            return this.m_summary;
        },
        set: function (value) {
            this.m_summary = value;
            if (value) {
                this.messages = value.messages;
                this.partnerShiftId = value.partnerShiftId;
                if (this.partnerShiftId) {
                    this.messages = value.partnerMessages;
                    this.isPartnerShift = true;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyShiftMessagesGridComponent.prototype, "messages", {
        get: function () {
            return this.m_messages;
        },
        set: function (value) {
            this.m_messages = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyShiftMessagesGridComponent.prototype, "requireMinHeight", {
        get: function () {
            return this.m_messages && this.m_messages.length < 2;
        },
        enumerable: true,
        configurable: true
    });
    DailyShiftMessagesGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.alias = session.alias;
            }
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
        });
        this.getSettings();
        if (this.summary.hasPartialShift || this.summary.parentShiftId !== -1) { //if parent shift is configured to have artial shifts, refresh
            this.hasPartialShift = true;
        }
    };
    DailyShiftMessagesGridComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()
                            .then(function (Config) {
                            if (Config.duagrid && Config.partnerintegrations) {
                                _this.addAgency = true;
                            }
                            else {
                                _this.addAgency = false;
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    DailyShiftMessagesGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    DailyShiftMessagesGridComponent.prototype.onApproveClicked = function (message) {
        var _this = this;
        if (message.partnerShiftId > 0) {
            this.openShiftManagementManagementService.changeLoadingState(true);
            this.employeeMessagesApiService.ApprovePartnerShift(message.id, message.partnerId, this.currentOrgLevel.id).then(function (result) {
                message.state = EmployeeMessageState.scheduled;
                message.stateName = _this.parseEmployeState(EmployeeMessageState.scheduled);
                _this.summary.openShiftCount = _this.summary.openShiftCount - 1;
                _this.openShiftManagementManagementService.onPartnerShiftCountChanged({ dateOn: _this.summary.dateOn, partnerShiftCountDiff: 1 });
                _this.openShiftManagementManagementService.changeLoadingState(false);
                if (result.data && result.data.hasErrors) {
                    _this.notificationsService.error('Error', result.data.errors && result.data.errors.length > 0 ? result.data.errors[0] : 'Something went wrong, please try later');
                }
            }).catch(function (error) {
                _this.openShiftManagementManagementService.changeLoadingState(false);
            });
        }
        else {
            this.openShiftManagementManagementService.changeLoadingState(true);
            this.employeeMessagesApiService.approveShift(message.id, this.summary.dateOn, message.senderId, this.summary.shift.id, this.summary.unit.id, this.summary.position.id)
                .then(function (result) {
                _this.removeMeassage(message);
                message.state = EmployeeMessageState.scheduled;
                message.stateName = _this.parseEmployeState(EmployeeMessageState.scheduled);
                _this.summary.openShiftCount = _this.summary.openShiftCount - 1;
                _this.summary.calculatedOpenShiftCount = _this.summary.calculatedOpenShiftCount - 1;
                _this.openShiftManagementManagementService.onMessageCountChanged({ dateOn: _this.summary.dateOn, messageCountDiff: 1 });
                _this.openShiftManagementManagementService.onOpenShiftCountChanged({ dateOn: _this.summary.dateOn, shiftCountDiff: 1 });
                //if partial shift approval, reload left and right panes
                if (_this.hasPartialShift) {
                    _this.openShiftManagementManagementService.onOpenShiftCountChangedByPartialShift({ dateOn: _this.summary.dateOn, shiftCountDiff: 1 });
                }
                _this.openShiftManagementManagementService.changeLoadingState(false);
            })
                .catch(function (error) {
                _this.openShiftManagementManagementService.changeLoadingState(false);
            });
        }
    };
    DailyShiftMessagesGridComponent.prototype.onDenyClicked = function (message) {
        var _this = this;
        if (message.partnerShiftId > 0) {
            this.openShiftManagementManagementService.changeLoadingState(true);
            this.employeeMessagesApiService.denyPartnerShift(message.id, message.partnerId, this.currentOrgLevel.id).then(function (result) {
                message.state = EmployeeMessageState.closed;
                message.stateName = _this.parseEmployeState(EmployeeMessageState.closed);
                _this.openShiftManagementManagementService.onPartnerShiftCountChanged({ dateOn: _this.summary.dateOn, partnerShiftCountDiff: 1 });
                _this.openShiftManagementManagementService.changeLoadingState(false);
            })
                .catch(function (error) {
                _this.openShiftManagementManagementService.changeLoadingState(false);
            });
        }
        else {
            this.openShiftManagementManagementService.changeLoadingState(true);
            this.employeeMessagesApiService.denyShift(message.id, this.summary.dateOn, message.senderId, this.summary.shift.id, this.summary.unit.id, this.summary.position.id).then(function (result) {
                _this.removeMeassage(message);
                message.state = EmployeeMessageState.closed;
                message.stateName = _this.parseEmployeState(EmployeeMessageState.closed);
                _this.openShiftManagementManagementService.onMessageCountChanged({ dateOn: _this.summary.dateOn, messageCountDiff: 1 });
                _this.openShiftManagementManagementService.changeLoadingState(false);
            })
                .catch(function (error) {
                _this.openShiftManagementManagementService.changeLoadingState(false);
            });
        }
    };
    DailyShiftMessagesGridComponent.prototype.removeMeassage = function (message) {
        this.itemRemove.emit(message);
        this.messages = this.messages.filter(function (x) { return x !== message; });
        this.refreshGrid();
    };
    DailyShiftMessagesGridComponent.prototype.refreshGrid = function () {
        if (!this.messages) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.take = this.pageSize;
        this.gridState.view = process(this.messages, this.gridState.state);
    };
    DailyShiftMessagesGridComponent.prototype.parseEmployeState = function (state) {
        if (state === EmployeeMessageState.new) {
            return 'New';
        }
        if (state === EmployeeMessageState.read) {
            return 'Read';
        }
        if (state === EmployeeMessageState.closed) {
            return 'Closed';
        }
        if (state === EmployeeMessageState.reopened) {
            return 'Reopened';
        }
        if (state === EmployeeMessageState.requested) {
            return 'Requested';
        }
        if (state === EmployeeMessageState.scheduled) {
            return 'Scheduled';
        }
        if (state === EmployeeMessageState.ignored) {
            return 'Ignored';
        }
        return null;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyShiftMessagesGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyShiftMessagesGridComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyShiftMessagesGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyShiftMessagesGridComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyShiftMessagesGridComponent.prototype, "smsAccessSubscription", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyShiftMessagesGridComponent.prototype, "user$", void 0);
    return DailyShiftMessagesGridComponent;
}());
export { DailyShiftMessagesGridComponent };
