import { Subject, of } from 'rxjs';
import { DetailsAgencyModel, AgencyAttributesField, AttributesModel, AttributeValue } from '../../models';
import { DetailsListAgencyPosition } from '../../models/detail-screen/details-list-agency-position';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
var ShiftRequestService = /** @class */ (function () {
    function ShiftRequestService() {
        this.subject = new Subject();
        this.positionSubject = new Subject();
        this.editAgency = new Subject();
        this.shiftdetails = [];
        this.displayId = new Subject();
        this.updateShift = new Subject();
        this.isrequestAgency = new Subject();
        this.openShiftManagementSubject = new Subject();
        this.UpdateShift = new Subject();
        this.isrequestAgencyStaffCancelled = false;
        this.partnerAttributes = [];
        this.shiftRecords = [];
        this.isRequestFromDua = false;
        this.isOpenShiftClosed = new Subject();
        this.pendingShiftDetails = [];
        this.sharedGridData = null;
        this.PosistionData = null;
        this.PartnerPostionMapData = new Map();
        this.agencyList = null;
    }
    ShiftRequestService.prototype.getInentivePayDropDownValues = function (agencyList) {
        var _this = this;
        this.dropDownOptions = [];
        agencyList.forEach(function (element) {
            element.partner_attributes.filter(function (el) {
                if (el.name == "incentivepayPercent") {
                    _this.dropDownOptions = _.cloneDeep(el.attr_values);
                }
            });
        });
        return this.dropDownOptions;
    };
    ShiftRequestService.prototype.setGridData = function (data) {
        this.sharedGridData = data;
    };
    ShiftRequestService.prototype.getGridData = function () {
        return this.sharedGridData;
    };
    ShiftRequestService.prototype.sendclickEvent = function () {
        this.subject.next();
    };
    ShiftRequestService.prototype.getClickEvent = function () {
        return this.subject.asObservable();
    };
    ShiftRequestService.prototype.positionsClickEvent = function () {
        this.positionSubject.next();
    };
    ShiftRequestService.prototype.editAgencyStaffRequest = function (shiftDetails) {
        this.editAgency.next(shiftDetails);
        this.displayId.next(shiftDetails.displayId);
    };
    ShiftRequestService.prototype.editAgencyDetails = function () {
        return this.editAgency.asObservable();
    };
    ShiftRequestService.prototype.openShiftRequest = function (isClosed) {
        this.isOpenShiftClosed.next(isClosed);
    };
    ShiftRequestService.prototype.getopenShiftWindowStatus = function () {
        return this.isOpenShiftClosed.asObservable();
    };
    ShiftRequestService.prototype.getDisplayId = function () {
        return this.displayId.asObservable();
    };
    ShiftRequestService.prototype.updateShifts = function (addShift) {
        this.updateShift.next(addShift);
    };
    ShiftRequestService.prototype.updateShiftRequests = function () {
        return this.updateShift.asObservable();
    };
    ShiftRequestService.prototype.updateRequestAgency = function () {
        this.isrequestAgency.next(true);
    };
    ShiftRequestService.prototype.getshiftChanges = function () {
        return this.isrequestAgency.asObservable();
    };
    ShiftRequestService.prototype.redirectPositionMappingEvent = function () {
        return this.positionSubject.asObservable();
    };
    ShiftRequestService.prototype.filterOpenShiftsData = function (data) {
        var arr = [];
        if (data && data.length > 0) {
            data.forEach(function (elem, i) {
                if ((data[i].fields.find(function (a) { return a.name === 'EmpId'; }).value === 0 ||
                    data[i].fields.find(function (a) { return a.name === 'EmpId'; }).value === '0') &&
                    data[i].fields.find(function (a) { return a.name === 'SlxpartnerdataId'; }).value === '0' &&
                    data[i].fields.find(function (a) { return a.name === 'MessageCount'; }).value === '0') {
                    arr.push(data[i]);
                }
            });
        }
        return arr;
    };
    ShiftRequestService.prototype.getValue = function (shiftArr, fieldName) {
        var returnData = null;
        shiftArr.forEach(function (el) {
            if (el.name === fieldName) {
                returnData = el.value;
            }
        });
        return returnData;
    };
    ShiftRequestService.prototype.filterGridData = function (data, agencyDetails) {
        var _this = this;
        var DataArr = [];
        data.forEach(function (elem, i) {
            var InPar = data[i].fields ? _this.getValue(data[i].fields, 'InPar') : '';
            var EmpId = data[i].fields ? _this.getValue(data[i].fields, 'EmpId') : '';
            var EmpName = data[i].fields ? _this.getValue(data[i].fields, 'EmpName') : '';
            var DepartmentId = data[i].fields ? _this.getValue(data[i].fields, 'DepartmentId') : '';
            var PositionGroupId = data[i].fields ? _this.getValue(data[i].fields, 'PositionGroupId') : '';
            var PositionGroupName = data[i].fields ? _this.getValue(data[i].fields, 'PositionGroupName') : '';
            var JobCode = data[i].fields ? _this.getValue(data[i].fields, 'JobCode') : '';
            var JobDescription = data[i].fields ? _this.getValue(data[i].fields, 'JobDescription') : '';
            var ShiftGroupId = data[i].fields ? _this.getValue(data[i].fields, 'ShiftGroupId') : '';
            var ShiftGroup = data[i].fields ? _this.getValue(data[i].fields, 'ShiftGroup') : '';
            var ShiftId = data[i].fields ? _this.getValue(data[i].fields, 'ShiftId') : '';
            var ShiftName = data[i].fields ? _this.getValue(data[i].fields, 'ShiftName') : '';
            var ShiftStart = data[i].fields ? _this.getValue(data[i].fields, 'ShiftStart') : '';
            var ShiftEnd = data[i].fields ? _this.getValue(data[i].fields, 'ShiftEnd') : '';
            var UnitId = data[i].fields ? _this.getValue(data[i].fields, 'UnitId') : '';
            var UnitName = data[i].fields ? _this.getValue(data[i].fields, 'UnitName') : '';
            var SchedHours = data[i].fields ? _this.getValue(data[i].fields, 'SchedHours') : '';
            var Hours = data[i].fields ? _this.getValue(data[i].fields, 'Hours') : '';
            var IsOvertime = data[i].fields ? _this.getValue(data[i].fields, 'IsOvertime') : '';
            var PPD = data[i].fields ? _this.getValue(data[i].fields, 'PPD') : '';
            var SlxpartnerdataId = data[i].fields ? _this.getValue(data[i].fields, 'SlxpartnerdataId') : '';
            var RequeststatusId = data[i].fields ? _this.getValue(data[i].fields, 'RequeststatusId') : '';
            var SlxagencyId = data[i].fields ? _this.getValue(data[i].fields, 'SlxagencyId') : '';
            var MessageCount = data[i].fields ? _this.getValue(data[i].fields, 'MessageCount') : '';
            var PartnerId = data[i].fields ? _this.getValue(data[i].fields, 'PartnerId') : '';
            var PositionErrorMessage = true;
            // let DetailsPartners : DetailsPartner[] = agencyDetails;
            // let SeletedAgency:DetailsPartner = agencyDetails[0];
            // let agencyListObj = this.setPartnerModelData(agencyDetails);
            // let AgencyList: DetailsAgencyModel[] = _.cloneDeep(agencyListObj);
            var PartnerErrorMessage = true;
            var objectData = {
                InPar: InPar,
                EmpId: EmpId,
                EmpName: EmpName,
                DepartmentId: DepartmentId,
                PositionGroupId: PositionGroupId,
                PositionGroupName: PositionGroupName,
                JobCode: JobCode,
                JobDescription: JobDescription,
                ShiftGroupId: ShiftGroupId,
                ShiftGroup: ShiftGroup,
                ShiftId: ShiftId,
                ShiftName: ShiftName,
                ShiftStart: ShiftStart,
                ShiftEnd: ShiftEnd,
                UnitId: UnitId,
                UnitName: UnitName,
                SchedHours: SchedHours,
                Hours: Hours,
                IsOvertime: IsOvertime,
                PPD: PPD,
                SlxpartnerdataId: SlxpartnerdataId,
                RequeststatusId: RequeststatusId,
                SlxagencyId: SlxagencyId,
                MessageCount: MessageCount,
                PositionErrorMessage: PositionErrorMessage,
                PartnerId: PartnerId,
                // DetailsPartners,
                // SeletedAgency,
                // AgencyList,
                PartnerErrorMessage: PartnerErrorMessage
            };
            DataArr.push(objectData);
        });
        DataArr.sort(function (a, b) { return (a.ShiftStart > b.ShiftStart) ? 1 : -1; });
        return DataArr;
    };
    ShiftRequestService.prototype.setPartnerModelData = function (agencyDetails) {
        var _this = this;
        var AgencyList = [];
        var agancyChecked = true;
        if (agencyDetails.length == 1) {
            agancyChecked = true;
        }
        agencyDetails.forEach(function (e) {
            var modelData = new DetailsAgencyModel();
            modelData.partnerId = e.partner_id;
            modelData.name = e.agency_name;
            modelData.isChecked = agancyChecked;
            modelData.shiftStatus = e.shiftStatus != null ? e.shiftStatus : null;
            modelData.agencyId = e.agency_id;
            modelData.sub_type_id = e.sub_type_id;
            modelData.sub_type_name = e.sub_type_name;
            if (e.partner_attributes.length > 0) {
                modelData.partner_attributes = _this.getFormFields(e.partner_attributes, e.agency_id);
                modelData.attributes = _this.setPartnerAttibutes(e.partner_attributes);
            }
            else {
                modelData.partner_attributes = [];
                modelData.attributes = [];
            }
            AgencyList.push(modelData);
        });
        return AgencyList;
    };
    ShiftRequestService.prototype.setPartnerAttibutes = function (agencyAttributeData) {
        var attributeList = [];
        if (agencyAttributeData.length > 0) {
            agencyAttributeData.forEach(function (item) {
                var attrValuesList = [];
                var attrobj = new AttributesModel();
                if (item.type == "dropdown") {
                    var dropdownValues = item.attr_values;
                    dropdownValues.forEach(function (element) {
                        if (element.id == item.attr_default_values) {
                            attrValuesList.push(element);
                        }
                    });
                }
                if (item.type == "checkbox") {
                    attrobj.value = item.attr_default_values == "true";
                    attrValuesList = item.attr_values;
                }
                if (item.type == "textbox") {
                    attrobj.value = item.attr_default_values;
                }
                attrobj.name = item.name;
                attrobj.id = 0;
                attrobj.partnerAttrId = 0;
                attrobj.attrValues = attrValuesList;
                attributeList.push(attrobj);
            });
        }
        return attributeList;
    };
    ShiftRequestService.prototype.getFormFields = function (agencyAttributeData, agencyId) {
        var inputs = [];
        agencyAttributeData.forEach(function (e) {
            var formField = new AgencyAttributesField();
            if (e.type == "textbox") {
                formField = new AgencyAttributesField({
                    controlType: e.type,
                    key: e.name,
                    label: e.display_name,
                    type: e.type,
                    required: true,
                    validator: "number",
                    order: e.order,
                    value: e.attr_values.toString(),
                    id: agencyId + "_" + e.name,
                });
            }
            if (e.type == "checkbox") {
                formField = new AgencyAttributesField({
                    controlType: e.type,
                    key: e.name,
                    label: e.display_name,
                    required: true,
                    order: e.order,
                    id: agencyId + "_" + e.name,
                });
            }
            if (e.type == "dropdown") {
                var dropdownData = e.attr_values;
                formField = new AgencyAttributesField({
                    controlType: e.type,
                    key: e.name,
                    label: e.display_name,
                    options: dropdownData,
                    order: e.order,
                    id: agencyId + "_" + e.name
                });
            }
            inputs.push(formField);
        });
        var finalInputData = inputs.sort(function (a, b) { return a.order - b.order; });
        return of(finalInputData);
    };
    ShiftRequestService.prototype.setPartnerPositionMapData = function (key, data) {
        var detailsListAgencyPosition = this.PartnerPostionMapData.get(key);
        if (detailsListAgencyPosition == null) {
            detailsListAgencyPosition = new DetailsListAgencyPosition();
        }
        detailsListAgencyPosition.positions = data;
        this.PartnerPostionMapData.set(key, detailsListAgencyPosition);
    };
    ShiftRequestService.prototype.getPartnerPositionMapData = function () {
        return this.PartnerPostionMapData;
    };
    ShiftRequestService.prototype.setAgencies = function (data) {
        this.agencyList = data;
    };
    ShiftRequestService.prototype.getAgencies = function () {
        return this.agencyList;
    };
    ShiftRequestService.prototype.getPartnerAttribute = function (name) {
        var attrValues = new AttributeValue();
        attrValues.name = name;
        attrValues.value = true;
        return attrValues;
    };
    ShiftRequestService.prototype.getPartnerModel = function (agencyDetails) {
        var _this = this;
        var AgencyList = [];
        var agancyChecked = true;
        if (agencyDetails.length == 1) {
            agancyChecked = true;
        }
        agencyDetails.forEach(function (e) {
            var modelData = new DetailsAgencyModel();
            modelData.partnerId = e.partnerId;
            modelData.name = e.name;
            modelData.isChecked = agancyChecked;
            modelData.shiftStatus = e.shiftStatus != null ? e.shiftStatus : null;
            modelData.agencyId = e.agencyId;
            if (e.attributes.length > 0) {
                // modelData.partner_attributes = this.getFormFields(e.partner_attributes, e.agency_id);
                modelData.attributes = _this.getPartnerAttibutes(e.attributes);
            }
            else {
                modelData.partner_attributes = [];
                modelData.attributes = [];
            }
            AgencyList.push(modelData);
        });
        return AgencyList;
    };
    ShiftRequestService.prototype.getPartnerAttibutes = function (agencyAttributeData) {
        var _this = this;
        var attributeList = [];
        if (agencyAttributeData.length > 0) {
            agencyAttributeData.forEach(function (item) {
                var attrValuesList = [];
                var attrobj = new AttributesModel();
                // item.attr_values[0].value = true;
                attrValuesList = _this.getPartnerAttributeValues(item.attrValues);
                attrobj.name = item.name;
                attrobj.id = 0;
                attrobj.value = item.value;
                attrobj.partnerAttrId = 0;
                attrobj.attrValues = attrValuesList;
                attributeList.push(attrobj);
            });
            return attributeList;
        }
    };
    ShiftRequestService.prototype.getPartnerAttributeValues = function (agencyAttributeValues) {
        var attributeValuesList = [];
        if (agencyAttributeValues.length > 0) {
            agencyAttributeValues.forEach(function (item) {
                attributeValuesList.push(_.cloneDeep(item));
            });
        }
        return attributeValuesList;
    };
    ShiftRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ShiftRequestService_Factory() { return new ShiftRequestService(); }, token: ShiftRequestService, providedIn: "root" });
    return ShiftRequestService;
}());
export { ShiftRequestService };
