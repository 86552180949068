import { InjectionToken, Injectable } from '@angular/core';

export const EMPLOYEES_ID_TOKEN = new InjectionToken('employees');
export const ISACTIVEONLY_ID_TOKEN = new InjectionToken('isActiveOnly');
export const EMPLOYEE_ID_TOKEN = new InjectionToken('employeeId');
export const ORG_LEVEL_ID_TOKEN = new InjectionToken('orgLevelId');
export const OPEN_SHIFT_COUNT_TOKEN = new InjectionToken('openShiftCount');
export const TEXT_MSG_TOKEN = new InjectionToken('textMsg');
export const DOCUMENT_TOKEN = new InjectionToken<Document>('document');
export const OPEN_SHIFT_DETAILS_TOKEN = new InjectionToken('openShiftDetails');
export const FILTERED_EMPLOYEES_TOKEN = new InjectionToken('filteredEmployees');
