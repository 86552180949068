export interface IAttachmentDefinitionDto {
    id: number;
    employeeId: number;
    fileName: string;
    name: string;
    addedBy: string;
    addedOn: string;
}

export class AttachmentDefinition {
    public id: number;
    public employeeId: number;
    public fileName: string;
    public name: string;
    public addedBy: string;
    public addedOn: Date;
}