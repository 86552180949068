<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
      <slx-temp-readings-toolbar></slx-temp-readings-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-temp-readings-grid></slx-temp-readings-grid>
    </div>
  </div>
</slx-spinner>
