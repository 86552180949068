import * as moment from 'moment';
import { dateTimeUtils } from '../../../common/utils/index';
export var DailyPayRuleStatus;
(function (DailyPayRuleStatus) {
    DailyPayRuleStatus["generated"] = "Generated";
    DailyPayRuleStatus["adjusted"] = "Adjusted";
    DailyPayRuleStatus["deleted"] = "Deleted";
})(DailyPayRuleStatus || (DailyPayRuleStatus = {}));
var DailyPayRule = /** @class */ (function () {
    function DailyPayRule() {
    }
    Object.defineProperty(DailyPayRule.prototype, "isAdjusted", {
        get: function () {
            if (this.serverInterval !== undefined && this.serverInterval !== this.interval) {
                return true;
            }
            if (this.serverMoneyAmount !== undefined && this.serverMoneyAmount !== this.moneyAmount) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    DailyPayRule.calculateInterval = function (item) {
        if (moment(item.end).isAfter(moment(item.start))) {
            var diff = moment.range(item.start, item.end).diff();
            item.interval = diff;
        }
        item.intervalHM = dateTimeUtils.getIntervalTimeFromInterval(item.start, item.interval, 'ms');
    };
    return DailyPayRule;
}());
export { DailyPayRule };
