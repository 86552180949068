/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-termination-date-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../../../common/components/spinner/spinner.component";
import * as i4 from "../change-termination-date/change-termination-date.component.ngfactory";
import * as i5 from "../change-termination-date/change-termination-date.component";
import * as i6 from "../../../../../../organization/services/employee/employee-definitions-api.service";
import * as i7 from "@angular/common";
import * as i8 from "./change-termination-date-dialog.component";
import * as i9 from "../../../../../../common/models/dialog-options";
import * as i10 from "../../../../../../common/services/modal/modal.service";
import * as i11 from "../../../../models/employee-activities/employee-to-terminate";
var styles_ChangeTerminationDateDialogComponent = [i0.styles];
var RenderType_ChangeTerminationDateDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangeTerminationDateDialogComponent, data: {} });
export { RenderType_ChangeTerminationDateDialogComponent as RenderType_ChangeTerminationDateDialogComponent };
function View_ChangeTerminationDateDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "p-15"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This action will not update the employee's Termination Date in CarePay, please manually update the employee's Termination Date in CarePay to make sure the records in both systems are in sync"]))], null, null); }
export function View_ChangeTerminationDateDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { changeTerminationDate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(4, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "slx-change-termination-date", [], null, null, null, i4.View_ChangeTerminationDateComponent_0, i4.RenderType_ChangeTerminationDateComponent)), i1.ɵdid(6, 114688, [[1, 4], ["changeTerminationDate", 4]], 0, i5.ChangeTerminationDateComponent, [i6.EmployeeDefinitionsApiService, i1.ChangeDetectorRef], { emp: [0, "emp"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangeTerminationDateDialogComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.employee; _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_co.employee && ((_co.employee == null) ? null : _co.employee.isNGPUser)) && ((_co.employee == null) ? null : _co.employee.isSystemUser)); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = !i1.ɵnov(_v, 6).isValid; var currVal_4 = !_co.isemployeeTerminationFormValid; _ck(_v, 13, 0, currVal_3, currVal_4); }); }
export function View_ChangeTerminationDateDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-change-termination-date-dialog", [], null, null, null, View_ChangeTerminationDateDialogComponent_0, RenderType_ChangeTerminationDateDialogComponent)), i1.ɵdid(1, 49152, null, 0, i8.ChangeTerminationDateDialogComponent, [i9.DialogOptions, i10.ModalService, i11.EmployeeToTerminate], null, null)], null, null); }
var ChangeTerminationDateDialogComponentNgFactory = i1.ɵccf("slx-change-termination-date-dialog", i8.ChangeTerminationDateDialogComponent, View_ChangeTerminationDateDialogComponent_Host_0, {}, {}, []);
export { ChangeTerminationDateDialogComponentNgFactory as ChangeTerminationDateDialogComponentNgFactory };
