<input *ngIf="readonly" type="text" readonly [attr.class]="className" [ngModel]="internalValue">
<kendo-autocomplete *ngIf="!readonly"
  [data]="dataItems"
  [value]="internalValue"
  [placeholder]="placeholder"
  [attr.class]="className"
  (valueChange)="change($event)"
  [tabIndex]="tabindex"
  [popupSettings]="popupSettings"
  >
</kendo-autocomplete>
