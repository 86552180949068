/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kendo-grid-filter-wrapper-cell.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@progress/kendo-angular-grid/dist/es2015/index.ngfactory";
import * as i3 from "@progress/kendo-angular-grid";
import * as i4 from "@progress/kendo-angular-l10n";
import * as i5 from "./kendo-grid-filter-wrapper-cell.component";
import * as i6 from "../../../directives/kendo-ui-extensions/kendo-grid-server-filter/kendo-grid-server-filter.directive";
import * as i7 from "../../../services/filter-state/filter-state-management.service";
var styles_KendoGridFilterWrapperCellComponent = [i0.styles];
var RenderType_KendoGridFilterWrapperCellComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KendoGridFilterWrapperCellComponent, data: {} });
export { RenderType_KendoGridFilterWrapperCellComponent as RenderType_KendoGridFilterWrapperCellComponent };
export function View_KendoGridFilterWrapperCellComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵeld(1, 0, null, null, 1, "kendo-grid-filter-cell-operators", [], [[2, "k-filtercell-operator", null]], [[null, "clear"], [null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clear" === en)) {
        var pd_0 = (_co.onClear() !== false);
        ad = (pd_0 && ad);
    } if (("valueChange" === en)) {
        var pd_1 = ((_co.currentOperator = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_FilterCellOperatorsComponent_0, i2.RenderType_FilterCellOperatorsComponent)), i1.ɵdid(2, 114688, null, 0, i3.FilterCellOperatorsComponent, [i4.LocalizationService], { operators: [0, "operators"], showButton: [1, "showButton"], showOperators: [2, "showOperators"], value: [3, "value"] }, { valueChange: "valueChange", clear: "clear" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.operators; var currVal_2 = _co.showButton; var currVal_3 = _co.showOperators; var currVal_4 = _co.currentOperator; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).hostClasses; _ck(_v, 1, 0, currVal_0); }); }
export function View_KendoGridFilterWrapperCellComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-kendo-grid-filter-wrapper-cell", [], [[2, "k-filtercell", null], [2, "k-filtercell-wrapper", null]], null, null, View_KendoGridFilterWrapperCellComponent_0, RenderType_KendoGridFilterWrapperCellComponent)), i1.ɵdid(1, 1228800, null, 2, i5.KendoGridFilterWrapperCellComponent, [i3.FilterService, [2, i6.KendoGridServerFilterDirective], [2, i7.FilterStateManagementService]], null, null), i1.ɵqud(603979776, 1, { operatorList: 1 }), i1.ɵqud(335544320, 2, { input: 0 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).overrideBaseClasses; var currVal_1 = i1.ɵnov(_v, 1).hostClasses; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var KendoGridFilterWrapperCellComponentNgFactory = i1.ɵccf("slx-kendo-grid-filter-wrapper-cell", i5.KendoGridFilterWrapperCellComponent, View_KendoGridFilterWrapperCellComponent_Host_0, { showOperators: "showOperators", operators: "operators", column: "column", filter: "filter", defaultOperator: "defaultOperator" }, { operatorChanged: "operatorChanged", clear: "clear" }, ["*"]);
export { KendoGridFilterWrapperCellComponentNgFactory as KendoGridFilterWrapperCellComponentNgFactory };
