import { ChangeDetectorRef, OnInit, SimpleChanges } from '@angular/core';
import { ScheduleRotationsManagementService } from './../../../../../app/scheduler/services/scheduled-rotation-template/schedule-rotations-management.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ScheduleRotationApiService } from './../../../../../app/scheduler/services/scheduled-rotation-template/scheduled-rotation-api.service';
import { ScheduledRotationMapService } from './../../../../../app/scheduler/services/scheduled-rotation-template/scheduled-rotation-map.service';
var ScheduledRotationsAvailShiftsComponent = /** @class */ (function () {
    function ScheduledRotationsAvailShiftsComponent(manService, apiService, mapService, ref) {
        var _this = this;
        this.manService = manService;
        this.apiService = apiService;
        this.mapService = mapService;
        this.ref = ref;
        this.weekOfDays = [];
        this.ShiftList = [];
        this.isDraggable = true;
        this.payCycleSubscription = this.manService.payCycle.subscribe(function (dateRange) {
            _this.startDate = moment(dateRange.startDate).format('MM/DD/YYYY');
            _this.endDate = moment(dateRange.endDate).format('MM/DD/YYYY');
            var dates = _this.manService.dates;
            _this.setWeekOfDays(dates);
        });
        this.loadDataSubscription = this.manService.subscribeToLoadDataSubscription(function (data) {
            var availableShifts = _this.mapService.mapScheduleRotationAvailShiftsData(data[0], _this.manService.currentWeek);
            _this.ShiftList = availableShifts;
        });
        this.manService.availableShifts$.subscribe(function (shifts) {
            _this.ShiftList = shifts;
            _this.manService.ShiftList = shifts;
            // this.ref.detectChanges();
        });
        this.manService.permissions$.subscribe(function (permissions) {
            _this.permissions = permissions;
        });
        this.manService.isDragabble$.subscribe(function (isDragabble) {
            _this.isDraggable = !isDragabble;
        });
    }
    ScheduledRotationsAvailShiftsComponent.prototype.ngOnInit = function () {
    };
    ScheduledRotationsAvailShiftsComponent.prototype.ngOnChanges = function (changes) {
        if (changes['orgLevelId'] || changes['startDate'] || changes['endDate']) {
            if (this.orgLevelId && this.startDate && this.endDate) {
                this.manService.getScheduleRotationAvailShifts(this.orgLevelId, this.startDate, this.endDate);
                this.manService.getNoRotationEmployeeList(this.orgLevelId, this.startDate, this.endDate);
            }
        }
    };
    ScheduledRotationsAvailShiftsComponent.prototype.dateName = function (days) {
        return "" + days.day;
    };
    Object.defineProperty(ScheduledRotationsAvailShiftsComponent.prototype, "hasPermissions", {
        get: function () {
            return this.permissions ? this.permissions.modify : false;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledRotationsAvailShiftsComponent.prototype.drag = function (ev, shift) {
        shift.isView = true;
        this.manService.draggedShift = shift;
        ev.dataTransfer.setData('shift', JSON.stringify(shift));
    };
    ScheduledRotationsAvailShiftsComponent.prototype.setWeekOfDays = function (dates) {
        var weekDays = _.cloneDeep(dates);
        this.weekOfDays = weekDays.splice(0, 7);
    };
    ScheduledRotationsAvailShiftsComponent.prototype.getExpandChange = function (e) {
        this.manService.isExpand$.next(e.expanded);
    };
    return ScheduledRotationsAvailShiftsComponent;
}());
export { ScheduledRotationsAvailShiftsComponent };
