import { ChangeDetectorRef, ElementRef, OnInit, SimpleChanges } from '@angular/core';
import { ScheduleRotationsManagementService } from './../../../../scheduler/services/scheduled-rotation-template/schedule-rotations-management.service';
import { FutureValidationShift } from './../../../../scheduler/models/schedule-rotation/schedule-rotation-template.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { ScheduledRotationMapService } from './../../../../scheduler/services/scheduled-rotation-template/scheduled-rotation-map.service';
import { NotificationsService } from './../../../../core/components';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { v4 as UUID } from 'uuid';
import { SrtViewIndividualScheduleDialogComponent } from './srt-view-individual-schedule-dialog/srt-view-individual-schedule-dialog.component';
import { EmployeeSectionsScheduleApiService } from './../../../../../app/employee/employee-sections/services';
import { ConfirmDialog2Component, ConfirmOptions, ModalService } from "./../../../../../app/common";
var ScheduledRotationsEmployeeGridComponent = /** @class */ (function () {
    function ScheduledRotationsEmployeeGridComponent(srtManService, mapService, notificationService, modalService, empScheduleApiService, elementRef, changeDetect) {
        var _this = this;
        this.srtManService = srtManService;
        this.mapService = mapService;
        this.notificationService = notificationService;
        this.empScheduleApiService = empScheduleApiService;
        this.elementRef = elementRef;
        this.changeDetect = changeDetect;
        this.employeeList = [];
        this.dates = [];
        this.week = 0;
        this.weekOfDays = [];
        this.isModify = false;
        this.count = 1;
        this.totalHours = 0;
        this.isView = true;
        this.currentWeek = [];
        this.tempData = [];
        this.shiftReducer$ = new Subject();
        this.addAction = 'Add Action';
        this.removeAction = 'Remove Action';
        this.exportEmployeeData = [];
        this.myFilters = {
            logic: 'or',
            filters: []
        };
        this.isExpand = false;
        this.expandedPageHeight = window.innerHeight - 410;
        this.collapsedPageHeight = window.innerHeight - 285;
        this.srtManService.isExpand$.subscribe(function (isExpand) {
            _this.isExpand = isExpand;
        });
        this.modalService = modalService;
        this.srtManService.payCycle.subscribe(function (dateRange) {
            _this.dates = _this.srtManService.dates;
            _this.startDate = moment(dateRange.startDate).format('MM/DD/YYYY');
            _this.endDate = moment(dateRange.endDate).format('MM/DD/YYYY');
            _this.setWeekOfDays();
            _this.count = 1;
            _this.enumerateDaysBetweenDates(_this.startDate, _this.endDate);
            _this.srtManService.isLoading = true;
        });
        this.srtManService.employeeList$.subscribe(function (employeeData) {
            _this.employeeList = employeeData;
            _this.srtManService.actualEmployeeData = employeeData;
            _this.currentWeek = _this.srtManService.weekOfDays;
            _this.srtManService.weeklyHours(_this.employeeList, _this.currentWeek);
        });
        this.loadDataSubscription = this.srtManService.subscribeToLoadDataSubscription(function (data) {
            _this.employeeList = data[1];
        });
        this.srtManService.permissions$.subscribe(function (permissions) {
            _this.permissions = permissions;
            _this.isModify = _this.permissions.modify;
        });
        this.shiftReducer$.subscribe(function (data) {
            if (data[4] === _this.addAction) {
                _this.srtManService.shiftReducer(data);
                if (data[0].isNewRotation) {
                    _this.srtManService.isNewRotationAdded = true;
                    _this.changeDetect.detectChanges();
                }
            }
            if (data[4] === _this.removeAction) {
                _this.srtManService.deleteShiftFromEmployee(data);
            }
        });
        this.srtManService.exportRotation$.subscribe(function (isExport) {
            if (isExport) {
                _this.processDataForExport();
            }
        });
        this.srtManService.noRotationEmployeeList$.subscribe(function (data) {
            _this.updatedNoEmpRotationList = data;
        });
        this.srtManService.filterRotationsRecord$.subscribe(function (data) {
            _this.filterOptions = _this.srtManService.filterRotationsRecord;
        });
    }
    ScheduledRotationsEmployeeGridComponent.prototype.onResize = function (event) {
        this.expandedPageHeight = event.target.innerHeight - 410;
        this.collapsedPageHeight = event.target.innerHeight - 285;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.ngOnInit = function () {
        this.filterOptions = this.srtManService.filterRotationsRecord;
        this.srtManService.defaultGridFilterOptions = this.srtManService.getSelectedShowStaffOptions(this.srtManService.filterRotationsRecord.showStaff);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes['orgLevelId'] || changes['startDate'] || changes['endDate']) {
            if (this.orgLevelId && this.startDate && this.endDate) {
                this.srtManService.getEmployeeRotations(this.orgLevelId, this.startDate, this.endDate);
            }
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.dateName = function (days) {
        var date = moment(days.date).format('MM/DD');
        return this.isViewWeekDate ? _.upperCase(days.day) + " (" + date + ")" : "" + _.upperCase(days.day);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.formatDate = function (days) {
        return moment(days.date).format('MM/DD/YYYY');
    };
    ScheduledRotationsEmployeeGridComponent.prototype.setWeekOfDays = function () {
        var weekDays = _.cloneDeep(this.dates);
        var index = this.week === 0 ? this.week : this.week * 7 - 1;
        this.weekOfDays = weekDays.splice(index, 7);
        this.currentWeek = this.weekOfDays;
        this.srtManService.currentWeek = this.weekOfDays;
        this.dateRangeOfCurrentWeek = moment(this.weekOfDays[0].date).format('ll') + " - " + moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll');
    };
    ScheduledRotationsEmployeeGridComponent.prototype.allowDrop = function (e, employee, day) {
        var shift = this.srtManService.draggedShift;
        if ((employee.employeeId !== 0 || (employee.employeeId === 0)) && !this.isEditView) {
            if (shift.weekDay === day.fullDayName && employee.activationDate <= day.date && day.date <= employee.terminationDate) {
                var index_1 = employee.scheduleDays.findIndex(function (x) { return x.day.formatDate === day.formatDate; });
                if (index_1 >= 0) {
                    var isOtherPosition = true;
                    if (shift.jobCode === employee.jobCode || employee.secondaryJobCodes.includes(shift.jobCode) || employee.positionGroupedJobCodes.includes(shift.jobCode)) {
                        isOtherPosition = false;
                    }
                    var isOverlapped = this.isTimeOverlapped(shift, employee.scheduleDays[index_1].shifts);
                    if (isOverlapped === true || isOtherPosition === true) {
                        employee.scheduleDays[index_1].isOverLap = true;
                        setTimeout(function () {
                            employee.scheduleDays[index_1].isOverLap = false;
                        }, 3000);
                    }
                    else {
                        e.preventDefault();
                    }
                }
            }
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.isOverLap = function (employee, day) {
        var index = employee.scheduleDays.findIndex(function (x) { return x.day.formatDate === day.formatDate; });
        return index >= 0 ? employee.scheduleDays[index].isOverLap : false;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.getEmployeeAssignedShifts = function (employee, day) {
        var shiftIndex = employee.scheduleDays.findIndex(function (x) { return x.day.formatDate === day.formatDate; });
        return shiftIndex >= 0 ? employee.scheduleDays[shiftIndex].shifts : [];
    };
    ScheduledRotationsEmployeeGridComponent.prototype.drop = function (e, employee, day) {
        e.preventDefault();
        var shift = JSON.parse(e.dataTransfer.getData('shift'));
        shift.isView = false;
        shift.isWhiteColor = this.setPrimarySecondaryColors(employee, shift);
        var isPositionGrouped = employee.jobCode === shift.jobCode || employee.onlySecondaryJobCodes.some(function (x) { return x.jobCode === shift.jobCode; });
        shift.isPositionGroupedShift = !isPositionGrouped;
        this.shiftReducer$.next([employee, shift, day, this.srtManService.ShiftList, this.addAction]);
        this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
        this.isEditView = (!shift.isView || employee.isOverTime) ? true : false;
        this.srtManService.isEditViewSaveDisable = this.isEditView;
        // this.setDragabble();
    };
    ScheduledRotationsEmployeeGridComponent.prototype.removeShift = function (data, employee, day) {
        var _a;
        data.scheduleDate = day.formatDate;
        this.shiftReducer$.next([employee, data, day, this.srtManService.ShiftList, this.removeAction]);
        var empIndex = employee.scheduleDays.findIndex(function (x) { return x.day.formatDate === day.formatDate; });
        (_a = employee.scheduleDays[empIndex].shifts).push.apply(_a, this.tempData);
        this.isEditView = this.isEditView ? false : true;
        this.srtManService.isEditViewSaveDisable = this.isEditView;
        _.map(employee.scheduleDays, function (i) { return _.map(i.shifts, function (j) { return j.isView = true; }); });
        // this.setDragabble();
    };
    ScheduledRotationsEmployeeGridComponent.prototype.setDragabble = function () {
        this.srtManService.isDragabble$.next(this.isEditView);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.weekCountIncrement = function (isIncremented) {
        isIncremented ? this.count++ : this.count--;
        var weekDays = _.cloneDeep(this.dates);
        if (this.count === 1) {
            this.weekOfDays = weekDays.splice(0, 7);
            this.currentWeek = this.weekOfDays;
            this.srtManService.currentWeek = this.currentWeek;
            this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
            this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
            this.dateRangeOfCurrentWeek = moment(this.weekOfDays[0].date).format('ll') + " - " + moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll');
        }
        if (this.count === 2) {
            this.weekOfDays = weekDays.splice(7, 7);
            this.currentWeek = this.weekOfDays;
            this.srtManService.currentWeek = this.currentWeek;
            this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
            this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
            this.dateRangeOfCurrentWeek = moment(this.weekOfDays[0].date).format('ll') + " - " + moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll');
        }
        if (this.count === 3) {
            this.weekOfDays = weekDays.splice(14, 7);
            this.currentWeek = this.weekOfDays;
            this.srtManService.currentWeek = this.currentWeek;
            this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
            this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
            this.dateRangeOfCurrentWeek = moment(this.weekOfDays[0].date).format('ll') + " - " + moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll');
        }
        if (this.count === 4) {
            this.weekOfDays = weekDays.splice(21, 7);
            this.currentWeek = this.weekOfDays;
            this.srtManService.currentWeek = this.currentWeek;
            this.updateCurrentWeek(this.srtManService.filterShiftData, this.currentWeek);
            this.srtManService.weeklyHours(this.employeeList, this.currentWeek);
            this.dateRangeOfCurrentWeek = moment(this.weekOfDays[0].date).format('ll') + " - " + moment(this.weekOfDays[this.weekOfDays.length - 1].date).format('ll');
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.addNewRotation = function () {
        if (this.srtManService.isNewRotationAdded)
            return;
        this.myFilters = {
            logic: 'or',
            filters: []
        };
        this.srtManService.setMandatoryChecksForFilter();
        this.srtManService.emptyAddRotationSequenceCount++;
        if (this.employeeList.find(function (x) { return x.employeeId === 0 && x.jobCode === 0; }) === undefined) {
            var data = {
                id: UUID(),
                departmentId: 0,
                departmentName: '',
                name: '',
                employeeId: 0,
                employeeType: '',
                jobCode: 0,
                positionName: '',
                shiftId: 0,
                shiftName: '',
                employeeTypeName: '',
                scheduleDays: this.srtManService.emptyScheduleDates,
                totalHours: 0,
                isPrimary: true,
                isDifferentDepartment: false,
                state: null,
                isOverTime: false,
                otHours: 40,
                rotationCount: 0,
                activationDate: new Date(this.startDate),
                terminationDate: new Date('06/06/2079'),
                secondaryJobCodes: '',
                primaryPositionGroupName: '',
                isNewRotation: true,
                homeShiftGroupId: 0,
                homeShiftGroupDescription: '',
                positionGroupId: 0,
                slotId: 0,
                isNewlyAssigned: false,
                isEmptySlot: false,
                seqId: this.srtManService.emptyAddRotationSequenceCount,
                positionGroupName: '',
                shiftGroupName: '',
                empPayPeriod: '',
                hasRotations: false,
                isAgencyEmployee: false,
                isFullTime: null,
                onlySecondaryJobCodes: null,
                maskedJobCode: null,
                positionGroupedJobCodes: null,
                otherDepartmentSecondaryJobCodes: null
            };
            if (this.isAnyFilterOptionSelected) {
                this.employeeList.splice(0, 0, data);
                this.srtManService.actualEmployeeData.splice(0, 0, data);
            }
            else {
                this.srtManService.actualEmployeeData.splice(0, 0, data);
            }
            this.changeDetect.detectChanges();
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.setEdit = function (e, shift, dataItem, date) {
        var _this = this;
        if (shift.isLock)
            return;
        if (e && !shift.isAssignedShift && !dataItem.isNewRotation) {
            this.srtManService.updateModifiedEntry(dataItem, shift);
        }
        dataItem.isOverTime = false;
        this.isView = e;
        shift.isView = e;
        this.isEditView = !e ? true : false;
        this.srtManService.isEditViewSaveDisable = !e;
        this.setDragabble();
        this.overTimeProceed = false;
        this.srtManService.empScheduleEntries = this.mapService.employeeScheduleEntriesCheck([dataItem, shift, date, this.srtManService.ShiftList, null]);
        if (e && !shift.isAssignedShift) {
            var data = this.setFutureValidationShift(shift.frequency, dataItem, shift, this.srtManService.empScheduleEntries);
            if (data) {
                this.srtManService.futureDateValidation(data, dataItem, shift, date);
                this.srtManService.shiftEdit$.subscribe(function (data) {
                    _this.isEditView = (data[0] && data[1].id === shift.id && data[1].scheduleDate === shift.scheduleDate) ? true : false;
                    shift.isView = (data[0] && data[1].id === shift.id && data[1].scheduleDate === shift.scheduleDate) ? false : true;
                    _this.srtManService.isEditViewSaveDisable = _this.isEditView;
                });
                this.srtManService.shiftOverTimeProceed$.subscribe(function (data) {
                    if (!data[0] && data[1].id === shift.id && data[1].scheduleDate === shift.scheduleDate) {
                        _this.isEditView = true;
                        shift.isView = false;
                        _this.srtManService.isEditViewSaveDisable = _this.isEditView;
                    }
                });
            }
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.isOverTimeProceed = function (e) {
        this.overTimeProceed = e ? true : false;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.scrollUp = function () {
        this.someDiv.nativeElement.scrollBy({ top: 200, left: 0, behavior: 'smooth' });
    };
    ScheduledRotationsEmployeeGridComponent.prototype.scrollDown = function () {
        this.someDiv.nativeElement.scrollBy({ top: -200, left: 0, behavior: 'smooth' });
    };
    ScheduledRotationsEmployeeGridComponent.prototype.isTimeOverlapped = function (currentShift, allShifts) {
        var _this = this;
        var currentStart = new Date().toLocaleDateString() + ' ' + new Date(currentShift.startTime).toLocaleTimeString();
        var startDateAddedHoursDate = moment(currentStart).add(moment.duration(currentShift.hours, 'hours'));
        var currentEnd = moment(startDateAddedHoursDate).format('M/DD/YYYY') + ' ' + new Date(currentShift.endTime).toLocaleTimeString();
        var date1 = moment(currentStart);
        var date2 = moment(currentEnd);
        var isOverlapped = false;
        allShifts.forEach(function (shift) {
            var start = new Date().toLocaleDateString() + ' ' + new Date(shift.startTime).toLocaleTimeString();
            var startAddedHoursDate = moment(start).add(moment.duration(shift.hours, 'hours'));
            var end = moment(startAddedHoursDate).format('M/DD/YYYY') + ' ' + new Date(shift.endTime).toLocaleTimeString();
            var date3 = moment(start);
            var date4 = moment(end);
            if ((_this.isDateRangeOverlapped(moment(date1), moment(date2), moment(date3), moment(date4), true) || (moment.range(date3, date4).contains(date1) || moment.range(date3, date4).contains(date2)))) {
                if (!date1.isSame(date4) && !date2.isSame(date3)) {
                    isOverlapped = true;
                }
            }
        });
        return isOverlapped;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.isDateRangeOverlapped = function (startDate1, endDate1, startDate2, endDate2, strict) {
        if (strict === void 0) { strict = true; }
        if (strict) {
            return startDate1.isSameOrBefore(endDate2) && endDate1.isSameOrAfter(startDate2);
        }
        return startDate1.isBefore(endDate2) && endDate1.isAfter(startDate2);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.updateCurrentWeek = function (shift, date) {
        var availableShifts = this.mapService.mapScheduleRotationAvailShiftsData(shift, date);
        this.srtManService.availableShifts$.next(availableShifts);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.getSelectedEmp = function (empData) {
        var index = this.srtManService.actualEmployeeData.findIndex(function (x) { return x.employeeId === empData.empId; });
        this.fillEmployeeDetails(empData, index);
        if (index >= 0) {
            var element = this.srtManService.actualEmployeeData.splice(index, 1)[0];
            element.seqId = empData.emp.seqId;
            element.scheduleDays = empData.emp.scheduleDays;
            element.slotId = empData.emp.slotId;
            element.isNewlyAssigned = empData.emp.isNewRotation;
            element.isEmptySlot = empData.emp.isEmptySlot;
            var currentIndex = this.srtManService.actualEmployeeData.findIndex(function (x) { return x === empData.emp; });
            this.updateNoRotationEmployeeList(element);
            this.srtManService.assignedEmployeeAndEmptySlotEmployee.push(this.srtManService.actualEmployeeData[currentIndex]);
            this.srtManService.isNewRotationAdded = true;
            this.changeDetect.detectChanges();
            this.srtManService.storeAssignedSlot$.next();
            var uniqueData = _.uniqBy(this.srtManService.actualEmployeeData, function (e) {
                return e['id'];
            });
            this.srtManService.employeeList$.next(uniqueData);
            this.srtManService.onFilterChange();
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.fillEmployeeDetails = function (empData, index) {
        this.srtManService.actualEmployeeData[index].isNewRotation = true;
        empData.emp.employeeId = this.srtManService.actualEmployeeData[index].employeeId;
        empData.emp.name = this.srtManService.actualEmployeeData[index].name;
        empData.emp.employeeType = this.srtManService.actualEmployeeData[index].employeeType;
        empData.emp.employeeTypeName = this.srtManService.actualEmployeeData[index].employeeTypeName;
        empData.emp.jobCode = this.srtManService.actualEmployeeData[index].jobCode;
        empData.emp.positionName = this.srtManService.actualEmployeeData[index].positionName ? this.srtManService.actualEmployeeData[index].positionName.trim() : null;
        empData.emp.shiftId = this.srtManService.actualEmployeeData[index].shiftId;
        empData.emp.shiftName = this.srtManService.actualEmployeeData[index].shiftName ? this.srtManService.actualEmployeeData[index].shiftName.trim() : null;
        empData.emp.isPrimary = this.srtManService.actualEmployeeData[index].isPrimary;
        empData.emp.isDifferentDepartment = this.srtManService.actualEmployeeData[index].isDifferentDepartment;
        // empData.emp.scheduleDays =  this.srtManService.actualEmployeeData[index].scheduleDays
        empData.emp.otHours = this.srtManService.actualEmployeeData[index].otHours;
        empData.emp.rotationCount = this.srtManService.actualEmployeeData[index].rotationCount;
        empData.emp.terminationDate = this.srtManService.actualEmployeeData[index].terminationDate;
        empData.emp.activationDate = new Date(this.srtManService.actualEmployeeData[index].activationDate);
        empData.emp.primaryGroupId = this.srtManService.actualEmployeeData[index].primaryGroupId;
        empData.emp.primaryPositionGroupName = this.srtManService.actualEmployeeData[index].primaryPositionGroupName;
        empData.emp.isNewRotation = this.srtManService.actualEmployeeData[index].isNewRotation;
        empData.emp.homeShiftGroupId = this.srtManService.actualEmployeeData[index].homeShiftGroupId;
        empData.emp.homeShiftGroupDescription = this.srtManService.actualEmployeeData[index].homeShiftGroupDescription;
        empData.emp.positionGroupId = this.srtManService.actualEmployeeData[index].positionGroupId;
        empData.emp.isNewlyAssigned = true;
        empData.emp.isEmptySlot = true;
        empData.emp.isFullTime = this.srtManService.actualEmployeeData[index].isFullTime;
        empData.emp.positionGroupId = this.srtManService.actualEmployeeData[index].positionGroupId;
        empData.emp.positionGroupName = this.srtManService.actualEmployeeData[index].positionGroupName;
        empData.emp.onlySecondaryJobCodes = this.srtManService.actualEmployeeData[index].onlySecondaryJobCodes;
        return empData;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.setSelectedEmpPos = function (pos) {
        var empIndex = this.srtManService.actualEmployeeData.findIndex(function (x) { return x.employeeId === 0 && x.jobCode === 0; });
        this.srtManService.actualEmployeeData[empIndex].jobCode = pos.id;
        this.srtManService.actualEmployeeData[empIndex].positionName = pos.name;
        this.srtManService.actualEmployeeData[empIndex].isEmptySlot = pos.isEmptySlot;
        this.srtManService.actualEmployeeData[empIndex].isPrimary = true;
        this.srtManService.assignedEmployeeAndEmptySlotEmployee.push(this.srtManService.actualEmployeeData[empIndex]);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.processDataForExport = function () {
        var _this = this;
        this.exportEmployeeData = [];
        //let exportData = this.srtManService.filterEmployeeData;
        var exportData = this.employeeGrid.data.data;
        if (exportData.length > 0) {
            exportData.forEach(function (data) {
                var isDataExists = true;
                var index = 0;
                var _loop_1 = function () {
                    var employeeData = {};
                    isDataExists = false;
                    employeeData.EmployeeName = data.name;
                    employeeData.PositionGroup = data.positionGroupName;
                    employeeData.Position = data.positionName;
                    employeeData.HomeShift = data.shiftName;
                    data.scheduleDays.forEach(function (dayData) {
                        var lastIndex = dayData.shifts.length - 1;
                        if (lastIndex >= 0 && index <= lastIndex) {
                            employeeData[dayData.day.dayName] = !dayData.shifts[index].isJobCodeHidden ? dayData.shifts[index].shiftName + " (" + dayData.shifts[index].unitName + ")" : '';
                        }
                        else {
                            employeeData[dayData.day.dayName] = '';
                        }
                        if (lastIndex > index) {
                            isDataExists = true;
                        }
                    });
                    _this.exportEmployeeData.push(employeeData);
                    index++;
                };
                while (isDataExists) {
                    _loop_1();
                }
            });
            this.excelexport.data = this.exportEmployeeData;
            this.excelexport.save();
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.enumerateDaysBetweenDates = function (startDate, endDate) {
        var dates = [];
        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
        var day = moment(currDate).format('ddd');
        var currentData = {
            day: day,
            fullDayName: moment(currDate).format('dddd'),
            date: currDate.clone().toDate(),
            dayName: day + "(" + moment(currDate).format('MM/DD') + ")",
            formatDate: moment(currDate).format('MM/DD/YYYY')
        };
        dates.push({ day: currentData, isOverLap: false, shifts: [] });
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            var day_1 = moment(currDate).format('ddd');
            var data = {
                day: day_1,
                fullDayName: moment(currDate).format('dddd'),
                date: currDate.clone().toDate(),
                dayName: day_1 + "(" + moment(currDate).format('MM/DD') + ")",
                formatDate: moment(currDate).format('MM/DD/YYYY')
            };
            dates.push({ day: data, isOverLap: false, shifts: [] });
        }
        this.srtManService.emptyScheduleDates = dates;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.repeatChange = function (e, employee, shift) {
        shift.frequency = JSON.parse(e.target.value);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.setPrimarySecondaryColors = function (employee, shift) {
        var jobCode = shift.jobCode;
        var isPurpleIndex = -1;
        if (employee.onlySecondaryJobCodes) {
            isPurpleIndex = employee.onlySecondaryJobCodes.findIndex(function (x) { return x.jobCode === jobCode; });
        }
        if (isPurpleIndex < 0 && employee.isDifferentDepartment && employee.positionGroupedJobCodes) {
            isPurpleIndex = employee.positionGroupedJobCodes.findIndex(function (x) { return x === jobCode; });
        }
        if (employee.employeeId !== 0 && isPurpleIndex >= 0) {
            return false;
        }
        return true;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.updateNoRotationEmployeeList = function (emp) {
        this.updatedNoEmpRotationList = _.filter(this.updatedNoEmpRotationList, function (item) { return item.employeeId != emp.employeeId; });
        this.srtManService.noRotationEmployeeList$.next(this.updatedNoEmpRotationList);
    };
    ScheduledRotationsEmployeeGridComponent.prototype.setFutureValidationShift = function (frequencyCount, employee, shift, shifts) {
        var data = new FutureValidationShift();
        data.employeeId = employee.isEmptySlot ? 0 : employee.employeeId;
        data.shiftId = shift.shiftId;
        data.frequencyId = frequencyCount;
        data.scheduleDate = shift.scheduleDate;
        data.shiftStartTime = shift.startTime;
        data.shiftEndTime = shift.endTime;
        data.empOTHours = employee.otHours;
        data.shiftHours = shift.hours;
        data.scheduleStartDate = moment(this.startDate).format('MM/DD/YYYY');
        data.scheduleEndDate = moment(this.endDate).format('MM/DD/YYYY');
        data.rotationsRequest = shifts;
        data.slotId = shift.slotId;
        data.orgLevelId = this.orgLevelId;
        data.isAlreadyAssignedShift = shift.isAssignedShift;
        return data;
    };
    ScheduledRotationsEmployeeGridComponent.prototype.viewIndividualSchedule = function (empData) {
        var _this = this;
        this.srtManService.employeeLoader = true;
        empData.empPayPeriod = moment(this.startDate).format('MM/DD/YYYY') + " - " + moment(this.endDate).format('MM/DD/YYYY');
        this.empScheduleApiService.getScheduleSection(empData.employeeId).then(function (data) {
            _this.srtManService.employeeLoader = false;
            _this.rotationsSection = data.rotationsSubsection;
            SrtViewIndividualScheduleDialogComponent.openDialog(empData, _this.rotationsSection, _this.modalService, function (result) {
            });
        });
    };
    ScheduledRotationsEmployeeGridComponent.prototype.onSettingsChanged = function (popperContent, isApply) {
        popperContent.hide();
        if (!isApply) {
            this.srtManService.isGridFilterApplied$.next(false);
            if (this.employeeList.some(function (x) { return x.employeeId == 0; })) {
                var ids_1 = [3, 4];
                this.srtManService.filterRotationsRecord.showStaff = this.srtManService.filterRotationsRecord.showStaff.map(function (item) {
                    if (ids_1.includes(item.id)) {
                        item.isSelected = true;
                    }
                    return item;
                });
            }
            return;
        }
        else {
            this.srtManService.isGridFilterApplied$.next(true);
            this.srtManService.onFilterChange();
        }
    };
    ScheduledRotationsEmployeeGridComponent.prototype.checkedChange = function (filterName, property, event) {
        var ind = filterName.findIndex(function (x) { return x.name === property; });
        filterName[ind].isSelected = event;
    };
    Object.defineProperty(ScheduledRotationsEmployeeGridComponent.prototype, "isViewWeekDate", {
        get: function () {
            return this.srtManService.viewWeeksDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledRotationsEmployeeGridComponent.prototype, "isAnyFilterOptionSelected", {
        get: function () {
            if (this.srtManService.selectedPos.length > 0
                || this.srtManService.selectedPosGroup.length > 0
                || this.srtManService.selectedUnit.length > 0
                || this.srtManService.selectedShiftGroup.length > 0
                || this.srtManService.filterRotationsRecord.showStaff.some(function (x) { return x.isSelected; })
                || this.srtManService.filterRotationsRecord.positionOptions.agencyStaff.isSelected
                || this.srtManService.filterRotationsRecord.positionOptions.emptyRotation.isSelected
                || this.srtManService.filterRotationsRecord.positionOptions.primaryPosition.isSelected
                || this.srtManService.filterRotationsRecord.positionOptions.secondaryPosition.isSelectAll) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduledRotationsEmployeeGridComponent.prototype.deleteEmptyRotations = function (rotation) {
        var _this = this;
        var message = "Do you want to delete this rotation?\n    The shifts assigned to this rotation will now be in the Available shifts section to be assigned to other rotations.";
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Delete';
        options.buttonCanceltext = 'Return';
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            if (result) {
                if (rotation.slotId !== 0) {
                    _this.srtManService.DeleteEmptyRotation(rotation.slotId).then(function () {
                        _this.srtManService.isNewRotationAdded = false;
                        _this.srtManService.getEmployeeRotations(_this.orgLevelId, _this.startDate, _this.endDate);
                        _this.srtManService.getScheduleRotationAvailShifts(_this.orgLevelId, _this.startDate, _this.endDate);
                    });
                }
                else {
                    _this.employeeList = _this.employeeList.filter(function (x) { return x.id !== rotation.id; });
                    _this.srtManService.actualEmployeeData = _this.srtManService.actualEmployeeData.filter(function (x) { return x.id !== rotation.id; });
                    _this.srtManService.assignedEmployeeAndEmptySlotEmployee = _this.srtManService.assignedEmployeeAndEmptySlotEmployee.filter(function (x) { return x.id !== rotation.id; });
                    _this.srtManService.setMandatoryChecksForFilter();
                    _this.srtManService.isNewRotationAdded = false;
                }
            }
        }, options);
    };
    return ScheduledRotationsEmployeeGridComponent;
}());
export { ScheduledRotationsEmployeeGridComponent };
