import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSubsectionBenefitClasses = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSubsectionBenefitClasses, _super);
    function EmployeeSubsectionBenefitClasses() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSubsectionBenefitClasses;
}(EmployeeSectionsBase));
export { EmployeeSubsectionBenefitClasses };
