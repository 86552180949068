import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { ReplaySubject, Observable } from 'rxjs';
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import { ShiftGroupOrder } from './../../../organization/models/schedule/shift-group-order';
import * as moment from 'moment';
import * as _ from 'lodash';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators';
var IndividualScheduleNightShiftService = /** @class */ (function () {
    function IndividualScheduleNightShiftService(appSettingsService) {
        var _this = this;
        this.appSettingsService = appSettingsService;
        this.m_nightShiftPrimary = false;
        this.onLoading$ = new ReplaySubject(1);
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            _this.loadNightShiftPrimary();
        });
        this.onLoading$.next(true);
        this.appSettingsService.getAppServerConfig().then(function (value) {
            _this.appSettings = value;
            _this.loadNightShiftPrimary();
            _this.onLoading$.next(false);
        });
    }
    Object.defineProperty(IndividualScheduleNightShiftService.prototype, "nightShiftPrimary", {
        get: function () {
            return this.m_nightShiftPrimary;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleNightShiftService.prototype.updateTimeByNightShiftSetting = function (shift) {
        var realNight = this.isRealNight(shift.start, shift.end);
        if (realNight && this.nightShiftPrimary && shift.shift.group.groupOrder === ShiftGroupOrder.Night) {
            shift.start = moment(shift.start).subtract(1, 'days').toDate();
            shift.end = moment(shift.end).subtract(1, 'days').toDate();
        }
    };
    IndividualScheduleNightShiftService.prototype.isRealNight = function (sd, ed) {
        return sd.getDate() < ed.getDate() || sd.getMonth() < ed.getMonth() || sd.getFullYear() < ed.getFullYear();
    };
    IndividualScheduleNightShiftService.prototype.loadNightShiftPrimary = function () {
        var _this = this;
        if (this.currentOrgLevel && this.appSettings) {
            var setting = _.find(this.appSettings.nightShiftSettings, function (s) { return s.organizationId === _this.currentOrgLevel.organizationId; });
            if (_.isNil(setting)) {
                setting = _.find(this.appSettings.nightShiftSettings, function (s) { return s.organizationId === 0; });
            }
            this.m_nightShiftPrimary = setting ? setting.enabled : false;
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleNightShiftService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleNightShiftService.prototype, "orgLevelSubscription", void 0);
    return IndividualScheduleNightShiftService;
}());
export { IndividualScheduleNightShiftService };
