import { IBreadcrumbItemConfig, BreadcrumbDisplayType } from './breadcrumb-item-config';

export class BreadcrumbItem {
  public linkPath: string | string[];
  public queryParams: StringMap<any>;
  public queryParamsLink: StringMap<any>;
  public title: string | Promise<string>;
  public icon: string | Promise<string>;
  public isAsync: boolean;
  public displayType: BreadcrumbDisplayType;
  public isHidden: boolean | Promise<boolean>;
  public config: IBreadcrumbItemConfig;
  public isNotLink : boolean;
}
