<div *ngIf="enableExport">
<p>
  <button id="exportExcelDatacls" (click)="exportToExcelData(grid)">
    Export Grid to Excel...
  </button>
</p>
  <kendo-grid #grid [kendoGridBinding]="ExportGroupData" [height]="400" [pageable]="false"
    [groupable]="true" [group]="group">
    <kendo-grid-column field="EmpName" title="Employee" width="280px">
    </kendo-grid-column>
    <kendo-grid-column field="Hours" title="Ideal Hours" filter="numeric" width="140px">
    </kendo-grid-column>
    <kendo-grid-column field="JobDescription" title="Position" width="140px">
    </kendo-grid-column>
    <kendo-grid-column field="SchedHours" title="Scheduled Hours" filter="numeric" width="160px">
    </kendo-grid-column>
    <kendo-grid-column field="ShiftName" title="Shift" width="120px">
    </kendo-grid-column>
    <kendo-grid-column field="AgencyName" title="Agency" width="120px" *ngIf="PartnerIsEnabled">
    </kendo-grid-column>
    <kendo-grid-column field="UnitName" title="Unit" width="100px">
    </kendo-grid-column>
    <kendo-grid-column field="SwitchShift" title="Switch" width="100px" [filterable]="false">
    </kendo-grid-column>
    <kendo-grid-column field="OpenShift" title="Open" width="100px" [filterable]="false">
    </kendo-grid-column>
    <kendo-grid-column field="OTShift" title="OT" width="100px" [filterable]="false">
    </kendo-grid-column>
    <kendo-grid-column field="ShiftGroup" title="ShiftGroup" [hidden]="true">
      <ng-template kendoGridGroupHeaderTemplate let-value="value">
        {{value}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-excel [fileName]="excelExportFileName"></kendo-grid-excel>
  </kendo-grid>

</div>