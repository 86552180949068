import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { DateRangeWithDate, isSameRanges } from '../../../components-library/models/index';
import { appConfig } from '../../../app.config';
import { RangeType } from '../../models/index';
import { CalendarViewTypesDefinitions } from '../../models/index';
import { PopperContent } from 'ngx-popper';
var RangeNavigatorComponent = /** @class */ (function () {
    function RangeNavigatorComponent() {
        this.appConfig = appConfig;
        this.rangeChanged = new EventEmitter();
        this.rangeTypeChanged = new EventEmitter();
        this.rangeNames = ['Day', 'Week'];
        this.rangeTypes = this.createRangeTypes(this.rangeNames);
        this.currentRange = null;
        this.daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        this.isShown = false;
        this.init = true;
    }
    Object.defineProperty(RangeNavigatorComponent.prototype, "selectedDate", {
        set: function (value) {
            this.m_currentDate = value;
            this.checkRequiredDate();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RangeNavigatorComponent.prototype, "startOfWeek", {
        get: function () {
            return this.m_startOfWeek;
        },
        // Monday, Tuesday, Wednesday etc.
        set: function (value) {
            if (_.indexOf(this.daysOfWeek, value) === -1) {
                throw new Error("The parameter \"startOfWeek\" does not match any day of week. Value: \"" + value + "\"");
            }
            this.m_startOfWeek = value;
            this.checkRequiredDate();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RangeNavigatorComponent.prototype, "selectedRangeType", {
        // Day | Week
        set: function (value) {
            this.m_currentRangeType = _.cloneDeep(this.rangeTypes[value] || this.rangeTypes.Day);
            this.checkRequiredDate();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RangeNavigatorComponent.prototype, "currentDate", {
        get: function () {
            return this.m_currentDate;
        },
        set: function (value) {
            if (this.init && _.isDate(this.m_currentDate) && !moment(this.m_currentDate).isSame(value)) {
                this.m_currentDate = value;
                if (this.popper instanceof PopperContent) {
                    this.popper.hide();
                }
                this.changeRange();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RangeNavigatorComponent.prototype, "currentRangeType", {
        get: function () {
            return this.m_currentRangeType;
        },
        set: function (value) {
            if (this.init && _.isObject(value) && _.isString(value.name) &&
                (!this.m_currentRangeType || this.m_currentRangeType.name !== value.name)) {
                this.m_currentRangeType = value;
                this.rangeTypeChanged.next(value);
                this.changeRange();
            }
        },
        enumerable: true,
        configurable: true
    });
    RangeNavigatorComponent.prototype.onNavButtonClick = function (event, isNext) {
        if (this.readonly)
            return;
        var currentDate = moment(this.currentDate).startOf('day');
        var newCurrentDate = null;
        switch (this.currentRangeType.name) {
            case this.rangeTypes.Day.name:
                if (isNext) {
                    newCurrentDate = currentDate.add(1, 'days');
                }
                else {
                    newCurrentDate = currentDate.subtract(1, 'days');
                }
                break;
            case this.rangeTypes.Week.name:
                var currentRange = this.evaluateWeekRange(currentDate);
                if (isNext) {
                    newCurrentDate = moment(currentRange.endDate).add(1, 'days');
                }
                else {
                    var startDate = moment(currentRange.startDate).subtract(1, 'days');
                    var prevRange = this.evaluateWeekRange(startDate);
                    newCurrentDate = moment(prevRange.startDate);
                }
                break;
            default:
                throw new Error("Current range type does not match any values of \"rangeTypes\" object. Value: \"" + this.currentRangeType.name + "\"");
        }
        this.currentDate = newCurrentDate.toDate();
    };
    // See #issueWithPopperVisibility
    RangeNavigatorComponent.prototype.onToggleVisibility = function (isShown) {
        this.isShown = isShown;
    };
    RangeNavigatorComponent.prototype.getRangeTypes = function () {
        return _.values(this.rangeTypes);
    };
    RangeNavigatorComponent.prototype.isWeekRangeType = function () {
        return this.currentRangeType.name === this.rangeTypes.Week.name;
    };
    RangeNavigatorComponent.prototype.changeRange = function () {
        var currentDate = moment(this.currentDate).startOf('day');
        var newRange = null;
        switch (this.currentRangeType.name) {
            case this.rangeTypes.Day.name:
                newRange = new DateRangeWithDate(this.currentDate, currentDate.toDate(), currentDate.endOf('day').toDate());
                break;
            case this.rangeTypes.Week.name:
                newRange = this.evaluateWeekRange(currentDate);
                break;
            default:
                throw new Error("Current range type does not match any values of \"rangeTypes\" object. Value: \"" + this.currentRangeType.name + "\"");
        }
        if (!this.currentRange || !isSameRanges(this.currentRange, newRange)) {
            this.currentRange = newRange;
            this.rangeChanged.next(newRange);
        }
    };
    RangeNavigatorComponent.prototype.evaluateWeekRange = function (date) {
        var newRange = null;
        var selectedDate = date.toDate();
        var weekLengthInDays = 6;
        var indexOfCurrentDay = date.weekday();
        var indexOfStartDay = _.indexOf(this.daysOfWeek, this.startOfWeek);
        var currentDay = this.daysOfWeek[indexOfCurrentDay];
        if (indexOfCurrentDay === indexOfStartDay) {
            newRange = new DateRangeWithDate(selectedDate, date.toDate(), date.add(weekLengthInDays, 'days').endOf('day').toDate());
        }
        else if (indexOfCurrentDay > indexOfStartDay) {
            var startDay = date.subtract(indexOfCurrentDay - indexOfStartDay, 'days').startOf('day');
            newRange = new DateRangeWithDate(selectedDate, startDay.toDate(), startDay.add(weekLengthInDays, 'days').endOf('day').toDate());
        }
        else if (indexOfCurrentDay < indexOfStartDay) {
            var endDay = date.add(indexOfStartDay - indexOfCurrentDay - 1, 'days').endOf('day');
            var endDayDate = endDay.toDate();
            newRange = new DateRangeWithDate(selectedDate, endDay.subtract(weekLengthInDays, 'days').startOf('day').toDate(), endDayDate);
        }
        return newRange;
    };
    RangeNavigatorComponent.prototype.checkRequiredDate = function () {
        if (_.isDate(this.currentDate) &&
            _.isString(this.startOfWeek) &&
            _.isObject(this.currentRangeType) &&
            _.isString(this.currentRangeType.name)) {
            this.changeRange();
        }
    };
    RangeNavigatorComponent.prototype.createRangeTypes = function (rangeNames) {
        return _.reduce(rangeNames, function (accumulator, rangeName, index) {
            accumulator[rangeName] = new RangeType(index, rangeName);
            return accumulator;
        }, {});
    };
    return RangeNavigatorComponent;
}());
export { RangeNavigatorComponent };
