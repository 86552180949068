import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const NOT_LEAD_ZEROES_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NotLeadZeroesValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[notLeadZeroes][formControlName],[notLeadZeroes][formControl],[notLeadZeroes][ngModel]',
  /* tslint:enable */
  providers: [NOT_LEAD_ZEROES_VALIDATOR]
})
export class NotLeadZeroesValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    return CommonValidators.notLeadZeroes(c);
  }
}
