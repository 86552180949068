import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeDefinitionsApiService } from '../../../../../../organization/services/index';
import { EmployeeShortInfo } from '../../../../../../organization/models/index';
import { EmployeeToTerminate, EmployeeTerminate } from '../../../../models/index';
import { appConfig } from '../../../../../../app.config';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../../core/decorators/index';
var ChangeTerminationDateComponent = /** @class */ (function () {
    function ChangeTerminationDateComponent(employeeDefinitionsApiService, changeDetector) {
        this.changeDetector = changeDetector;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    }
    Object.defineProperty(ChangeTerminationDateComponent.prototype, "emp", {
        set: function (emp) {
            this.employee = emp;
            this.setEmployeeId();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeTerminationDateComponent.prototype, "minDate", {
        get: function () {
            return !this.employee.ignoreLockedPayrollCycles && !_.isNil(this.employeeShortInfo) ? this.employeeShortInfo.effectiveDate : null;
        },
        enumerable: true,
        configurable: true
    });
    ChangeTerminationDateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.formChangeSubscription = this.ngForm.valueChanges.subscribe(function () {
            _this.isValid = _this.ngForm.valid;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.requestToTerminate = new EmployeeTerminate();
        this.requestToTerminate.date = this.employee.terminationDate || moment().startOf('day').toDate();
        this.setEmployeeId();
    };
    ChangeTerminationDateComponent.prototype.setEmployeeId = function () {
        if (this.employee && this.requestToTerminate) {
            this.requestToTerminate.employeeId = this.employee.employeeId;
            this.loadEmployeeInfo();
        }
    };
    ChangeTerminationDateComponent.prototype.loadEmployeeInfo = function () {
        var _this = this;
        this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employee.employeeId)
            .then(function (employeeShortInfo) {
            _this.employeeShortInfo = employeeShortInfo;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ChangeTerminationDateComponent.prototype, "formChangeSubscription", void 0);
    return ChangeTerminationDateComponent;
}());
export { ChangeTerminationDateComponent };
