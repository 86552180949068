<slx-spinner [show]="state.isLoading">
  <div class="details-screen">
    <div class="header">
      <span>Census: {{details?.censusCount}}</span>
      <span>Total hours: {{details?.totalHours}}</span>
    </div>
    <div class="content">
      <div class="details-screen-filters">
        <div class="details-screen-date">
          <slx-input-decorator>
            <slx-date-picker-ngx slx-input
              [(ngModel)]="dateOn"
              (ngModelChange)="changeDateOn($event)"
              [acceptNullDate]="false"
              placeholder="Date"
              name="dateOn"
            ></slx-date-picker-ngx>
            <span errorMessage for="date"></span>
          </slx-input-decorator>
        </div>
        <slx-filter-and-groups [filters]="filters" [groups]="groups" (onFiltersChange)="filtersChanged($event)" (onGroupsChange)="groupsChanged($event)"></slx-filter-and-groups>
      </div>

      <div *ngIf="details && details.groups" class="details-screen-groups">
        <div class="group" *ngFor="let group of details.groups">
          <div class="group-header">
            <div class="left-side">
              <div class="title">
                <span *ngFor="let grouping of group.grouping | filter:{name: restrictedInfo}; let last = last">{{grouping.value}}{{!last ? ', ' : ''}}</span>
              </div>
              <div class="par-level-discrepancy">
                <span class="fa-stack fa-large">
                  <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                  <i class="fa fa-plus fa-stack-1x" aria-hidden="true"></i>
                  <span class="label label-warning">2</span>
                </span>
              </div>
            </div>
            <div class="right-side">
              <div class="total">Total: {{group.parLevel}}</div>
              <span class="fa-stack clickable">
                    <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                    <i class="fa fa-file fa-stack-1x" aria-hidden="true"></i>
                    <span class="label label-warning">{{group.postedShiftRepliesCount}}</span>
              </span>
              <span class="fa-stack fa-large clickable">
                  <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                  <i class="far fa-envelopepen-o fa-stack-1x" aria-hidden="true"></i>
                  <span class="label label-warning">{{group.smsRepliesCount}}</span>
              </span>
              <span class="fa-stack fa-large clickable" (click)="onOpenShift(group)">
                    <i class="far fa-circle fa-stack-2x" aria-hidden="true"></i>
                    <i class="fa fa-user-plus fa-stack-1x" aria-hidden="true"></i>
                  </span>
            </div>
          </div>
          <div class="group-table">
            <table class="table" aria-describedby="employeeName">
              <thead>
                <tr>
                  <th id="employeeName" *ngFor="let column of details?.columns | filter:'access':'read'" class="column-header">{{column.displayName}}</th>
                  <th id="employeeActions"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let row of group.rows">
                  <td *ngFor="let column of details?.columns | filter:'access':'read'">
                    <span *ngIf="column.name !== 'EmpName'">{{getFieldValue(row, column)}}</span>
                    <a *ngIf="column.name === 'EmpName'" (click)="employeeOpen(row)">{{getFieldValue(row, column)}}</a>
                  </td>
                  <td>
                    <div class="row-actions">
                      <i class="fas fa-exchange-alt clickable" aria-hidden="true"></i>
                      <i class="fas fa-sync clickable" aria-hidden="true"></i>
                      <i class="fa fa-minus-circle clickable" aria-hidden="true"></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</slx-spinner>
