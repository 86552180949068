import { Component } from '@angular/core';
import { TelepunchLocationsManagementService } from '../../../services/telepunch-locations/telepunch-locations-management.service';

@Component({
    moduleId: module.id,
    selector: 'slx-telepunch-locations-toolbar',
    templateUrl: 'telepunch-locations-toolbar.component.html',
    styleUrls: ['telepunch-locations-toolbar.component.scss'],
})
  
export class TelepunchLocationsToolbarComponent {
    constructor(
        public management: TelepunchLocationsManagementService
    ) {
        
    } 

    public onAddItem(): void {
        this.management.addItemClicked();
    }

    public onDeleteItems(): void {
        this.management.deleteItemClicked();
    }

    public onBulkEditClick(): void {
        this.management.doBulkEdit();
    }

    public onBulkSaveClick(): void {
        this.management.doBulkSave();
    }
    public onBulkDiscardClick(): void {
        this.management.doBulkDiscard();
    }
}
