import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { DialogOptions, IDialog, ModalService } from '../../../../common';
import { OpenConfigOrgLevelType } from '../../../models';
import { OpenPositionsControlApiService } from '../../../services';
import * as moment from 'moment';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { OpenPositionControlSlideOutExcel } from './../../../../../app/scheduler/models/open-position-control/open-position-controls-slide-out-excel';
import { OpenPositionControlHelper } from '../open-position-control-helper';
var PositionGroupSlideOutComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PositionGroupSlideOutComponent, _super);
    function PositionGroupSlideOutComponent(options, modalService, orgLevel, openPositionsControlApiService) {
        var _this = _super.call(this, modalService) || this;
        _this.openPositionsControlApiService = openPositionsControlApiService;
        _this.isLoading = true;
        _this.openShiftPositionGroupDetails = [];
        _this.exportOpenPositionControlSlideOutData = [];
        _this.filteredData = [];
        _this.filterOption = 'All';
        _this.options = options;
        _this.modalService = modalService;
        _this.orgLevel = orgLevel;
        _this.openPositionsControlApiService.isLoadPositionGroupDetails$.subscribe(function (data) {
            _this.isLoading = true;
            data && _this.filterData();
        });
        return _this;
    }
    Object.defineProperty(PositionGroupSlideOutComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    PositionGroupSlideOutComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.updatePopupPosition();
    };
    PositionGroupSlideOutComponent.prototype.onFilterChange = function () {
        this.LoadData();
    };
    PositionGroupSlideOutComponent.prototype.LoadData = function () {
        this.isLoading = true;
        this.filterData();
    };
    PositionGroupSlideOutComponent.prototype.filterData = function () {
        var _this = this;
        this.filteredData = [];
        this.openPositionsControlApiService.getPositionGroupDetails(this.orgLevelId, this.startDate, this.endDate, this.filterOption).then(function (data) {
            _this.openShiftPositionGroupDetails = data;
            _this.openShiftPositionGroupDetails.forEach(function (e) {
                _this.filteredData.push({
                    positionGroup: e.positionGroup,
                    totalShifts: e.totalShifts,
                    totalScheduled: e.totalScheduled,
                    totalHours: e.totalHours,
                    totalFTHead: e.totalFTHead,
                    totalPTHead: e.totalPTHead,
                    positionDetails: e.positionDetails
                });
            });
            _this.isLoading = false;
        });
    };
    PositionGroupSlideOutComponent.prototype.updatePopupPosition = function () {
        var container = this.containerElem.nativeElement;
        var popup = $(container).closest('kendo-popup');
        popup.addClass('slx-slide-out-dialog-custom');
    };
    PositionGroupSlideOutComponent.prototype.getDateRange = function () {
        return 'Date Range: ' + moment(this.startDate).format('L') + ' - ' + moment(this.endDate).format('L');
    };
    PositionGroupSlideOutComponent.prototype.getFilterData = function () {
        return 'Filter Group: ' + this.filterOption;
    };
    PositionGroupSlideOutComponent.prototype.exportToExcel = function () {
        var _this = this;
        this.exportOpenPositionControlSlideOutData = [];
        if (this.filterOption == 'All') {
            this.openPositionsControlApiService.getPositionGroupDetails(this.orgLevelId, this.startDate, this.endDate, 'Excel').then(function (data) {
                _this.openShiftPositionGroupDetails = data;
                _this.openShiftPositionGroupDetails.forEach(function (e) {
                    e.positionDetails.forEach(function (x) {
                        var orgData = new OpenPositionControlSlideOutExcel();
                        orgData.PositionGroup = e.positionGroup;
                        orgData.Position = x.position;
                        orgData.ShiftGroup = x.shiftGroup;
                        orgData.Shifts = x.shifts;
                        orgData.Scheduled = x.scheduled;
                        if (x.shifts == 0) {
                            orgData.hoursGap = "Ideal Schedule is not configured";
                        }
                        else if (x.shifts > 0 && x.hoursGap == 0) {
                            orgData.hoursGap = "No Rotation Gaps";
                        }
                        else {
                            orgData.hoursGap = x.hoursGap.toString();
                            // orgData.ftEsNeeded="";
                            orgData.ftHeadCount = x.ftHeadCount;
                            orgData.ptHeadCount = x.ptHeadCount;
                        }
                        _this.exportOpenPositionControlSlideOutData.push(orgData);
                    });
                });
                _this.excelExport.data = _this.exportOpenPositionControlSlideOutData;
                _this.excelExport.save();
            });
        }
        else {
            this.openShiftPositionGroupDetails.forEach(function (e) {
                e.positionDetails.forEach(function (x) {
                    var orgData = new OpenPositionControlSlideOutExcel();
                    orgData.PositionGroup = e.positionGroup;
                    orgData.Position = x.position;
                    orgData.ShiftGroup = x.shiftGroup;
                    orgData.Shifts = x.shifts;
                    orgData.Scheduled = x.scheduled;
                    if (x.shifts == 0) {
                        orgData.hoursGap = "Ideal Schedule is not configured";
                    }
                    else if (x.shifts > 0 && x.hoursGap == 0) {
                        orgData.hoursGap = "No Rotation Gaps";
                    }
                    else {
                        orgData.hoursGap = x.hoursGap.toString();
                        //orgData.ftEsNeeded="";
                        orgData.ftHeadCount = x.ftHeadCount;
                        orgData.ptHeadCount = x.ptHeadCount;
                    }
                    _this.exportOpenPositionControlSlideOutData.push(orgData);
                });
            });
            this.excelExport.data = this.exportOpenPositionControlSlideOutData;
            this.excelExport.save();
        }
    };
    return PositionGroupSlideOutComponent;
}(OpenPositionControlHelper));
export { PositionGroupSlideOutComponent };
