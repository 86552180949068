import { ValuePairChartInput } from './value-pair-chart-input';
var ValuePairChartModel = /** @class */ (function () {
    function ValuePairChartModel() {
        this.input = new ValuePairChartInput();
    }
    Object.defineProperty(ValuePairChartModel.prototype, "rawValue1", {
        get: function () {
            return this.input ? this.input.value1 : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ValuePairChartModel.prototype, "rawValue2", {
        get: function () {
            return this.input ? this.input.value2 : null;
        },
        enumerable: true,
        configurable: true
    });
    return ValuePairChartModel;
}());
export { ValuePairChartModel };
