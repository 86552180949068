import { IBenefitTerminationReason, BenefitTerminationReason } from './benefit-employee-benefits';

export interface IBenefitDependentDroppedEnrollment {
  empDependentEnrollmentId: number;
  eventDate: string;
  coverageEndDate: string;
  reason: IBenefitTerminationReason;
}

export class BenefitDependentDroppedEnrollment {
  constructor(
    public empDependentEnrollmentId: number,
    public eventDate: Date,
    public coverageEndDate: Date,
    public reason: BenefitTerminationReason
  ) {}
}
