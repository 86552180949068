import { screenUtils } from './../../../../common/utils/screenUtils';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { IControlState, StateResetTypes } from '../../../../core/models/settings/index';
import { Component, Input, Output, EventEmitter, Provider } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';

import { employeeListConfig } from '../../../../employee/employee-list/employee-list.config';
import { EmployeeSectionNavigationService } from '../../../../employee/employee-sections/services/index';

import { DetailRow, DetailColumn, DetailField } from '../../../models/index';
import { BooleanFilterCellComponent } from '@progress/kendo-angular-grid';

const SELECTED_EMP_ID: string = 'selectedEmpId';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-unit-employees',
  templateUrl: 'daily-unit-employees.component.html',
  styleUrls: ['daily-unit-employees.component.scss']
})
export class DailyUnitEmployeesComponent {
  private static inParFieldName: string = 'InPar';
  private static isOvertimeFieldName: string = 'IsOvertime';
  private static messageCountFieldName: string = 'MessageCount';

  @Input()
  public canReplaceEmployee: boolean;

  @Input()
  public columns: DetailColumn[];

  @Input()
  public set rows(value: DetailRow[]) {
    this.m_originalRows = value;
    this.refreshView();
  }

  @Input()
  public set showEmptySlots(value: boolean) {
    this.m_showEmptySlots = value;
    this.refreshView();
  }
  public get showEmptySlots(): boolean {
    return this.m_showEmptySlots;
  }

  @Input()
  public set showOvertimes(value: boolean) {
    this.m_showOvertimes = value;
    this.refreshView();
  }
  public get showOvertimes(): boolean {
    return this.m_showOvertimes;
  }

  @Input()
  public set showMessages(value: boolean) {
    this.m_showMessages = value;
    this.refreshView();
  }
  public get showMessages(): boolean {
    return this.m_showMessages;
  }

  @Input()
  public set showOutOfPar(value: boolean) {
    this.m_showOutOfPar = value;
    this.refreshView();
  }
  public get showOutOfPar(): boolean {
    return this.m_showOutOfPar;
  }

  @Input()
  public isEmptyExists: boolean = false;

  @Output()
  public onEmployeeDelete: EventEmitter<DetailRow>;

  @Output()
  public onFillShift: EventEmitter<DetailRow>;

  @Output()
  public onReplaceEmployee: EventEmitter<DetailRow>;

  @Output()
  public onSwitchEmployeeShift: EventEmitter<DetailRow>;

  @Output()
  public onShiftOpen: EventEmitter<DetailRow>;

  @Output()
  public onOpenIndividual: EventEmitter<DetailRow>;

  @Output()
  public onNeedScroll: EventEmitter<number>;

  public filteredRows: DetailRow[];

  public restrcitedMobileFields: string[];
  public displayFileds: string[];

  private domSanitizer: DomSanitizer;
  private router: Router;
  private activatedRoute: ActivatedRoute;
  private m_showEmptySlots: boolean;
  private m_showOvertimes: boolean;
  private m_showMessages: boolean;
  private m_showOutOfPar: boolean;
  private m_originalRows: DetailRow[];
  private selectedEmpId: number;

  constructor(domSanitizer: DomSanitizer, router: Router, activatedRoute: ActivatedRoute,
    private stateManagement: StateManagementService, private storageService: ComponentStateStorageService) {
    this.domSanitizer = domSanitizer;
    this.router = router;
    this.activatedRoute = activatedRoute;

    this.onEmployeeDelete = new EventEmitter<DetailRow>();
    this.onFillShift = new EventEmitter<DetailRow>();
    this.onReplaceEmployee = new EventEmitter<DetailRow>();
    this.onShiftOpen = new EventEmitter<DetailRow>();
    this.onSwitchEmployeeShift = new EventEmitter<DetailRow>();
    this.onOpenIndividual = new EventEmitter<DetailRow>();
    this.onNeedScroll = new EventEmitter<number>();

    this.restrcitedMobileFields = ['EmpName', 'DepartmentName', 'ShiftName', 'UnitName'];
    this.displayFileds = ['EmpName', 'Hours', 'JobDescription','SchedHours', 'ShiftName', 'UnitName'];
    this.restoreSelectedEmp();
  }

  public removeEmployee(row: DetailRow): void {
    this.onEmployeeDelete.emit(row);
  }

  public switchEmployee(row: DetailRow): void {
    this.selectEmployee(row);
    this.onSwitchEmployeeShift.emit(row);
  }

  public getEmployeeId(row: DetailRow): string {
    return row.getFieldValue(employeeListConfig.employeeIdentifierName);
  }

  public isEmptyEmployee(row: DetailRow): boolean {
    return row.isEmpty;
  }

  public getFieldValue(row: DetailRow, column: DetailColumn): string {
    let field: DetailField = _.find(row.fields, (f: DetailField) => f.name === column.name);
    return field ? field.value : '';
  }

  public fillShift(row: DetailRow): void {
    this.onFillShift.emit(row);
  }

  public replaceEmployee(row: DetailRow): void {
    this.onReplaceEmployee.emit(row);
  }

  public openIndividual(row: DetailRow): void {
    this.onOpenIndividual.emit(row);
    this.selectEmployee(row);
  }

  public openShift(row: DetailRow): void {
    this.onShiftOpen.emit(row);
    this.selectEmployee(row);
  }

  public isInPar(row: DetailRow): boolean {
    return !row.isOutOfPar;
  }

  public isOvertime(row: DetailRow): boolean {
    return row.isOvertime;
  }

  public getMessageCount(row: DetailRow): number {
    return row.messageCount;
  }

  public selectEmployee(row: DetailRow): void {
    let empId: number = +this.getEmployeeId(row);
    this.storageService.setControlState(this.stateManagement.componentKey, SELECTED_EMP_ID,
      { value: empId }, StateResetTypes.SessionEnd | StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange);
  }

  public isSelectedEmployee(row: DetailRow): boolean {
    return this.selectedEmpId && +this.getEmployeeId(row) === this.selectedEmpId;
  }

  public onTRCreated(item: HTMLElement): void {
    this.onNeedScroll.emit(item.offsetTop + (<HTMLElement>item.offsetParent).offsetTop);
  }

  public getRemoveTip(row: DetailRow): string {
    return this.isOvertime(row) ? 'Projected for OT' : 'Remove Employee';
  }

  public get isMobile(): boolean {
    return screenUtils.isMobile;
  }

  private restoreSelectedEmp(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, SELECTED_EMP_ID);
    if (state.value > 0) {
      this.selectedEmpId = state.value;
    }
  }

  private refreshView(): void {
    this.filteredRows = this.filterRows(this.m_originalRows);
  }


  private filterRows(rows: DetailRow[]): DetailRow[] {
    if (!this.showEmptySlots && !this.showMessages && !this.showOutOfPar && !this.showOvertimes) {
      return rows.concat([]);
    }

    let filteredRows: DetailRow[] = [];
    _.forEach(rows, (row: DetailRow): void => {
      let rowToAdd: DetailRow = undefined;
      if (this.showEmptySlots && row.isEmpty) rowToAdd = row;
      if (!rowToAdd && this.showMessages && row.messageCount > 0) rowToAdd = row;
      if (!rowToAdd && this.showOutOfPar && row.isOutOfPar && !row.isEmpty) rowToAdd = row;
      if (!rowToAdd && this.showOvertimes && row.isOvertime) rowToAdd = row;
      if (rowToAdd) {
        filteredRows.push(rowToAdd);
      }
    });
    return filteredRows;
  }
}
