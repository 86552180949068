export class SearchCategory {

    public categoryFieldId: number;
    public categoryFieldName: string;
    public displayName: string;
    public description: string;
    public dataType: string;

    public operators: string[];
    public tableName: string;
    public fieldName: string;

}

export interface ISearchCategory {

    categoryFieldId: number;
    categoryFieldName: string;
    displayName: string;
    description: string;
    dataType: string;

    operators: string[];
    tableName: string;
    fieldName: string;
}
