<div class="modal-content">

  <div class="modal-body">
    <slx-spinner [show]="state.isLoading" novalidate>
      <div class="header-container">
        <textarea name="emailText" [(ngModel)]="optInEmailTemplate" class="form-control text-area-email" [disabled]="true" cols="40" rows="1"></textarea>
      </div>
      <div class="employees-list">
        <slx-employee-grid [fieldDefinition]="fieldDefinition" [employees]="actionEmployees" [isMultiselect]="true" [isOptInEmail]="true" (onEmployeesSelect)="employeesSelect($event)"></slx-employee-grid>
      </div>
    </slx-spinner>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
    <button type="button" (click)="onSend()" class="btn green" [disabled]="!canSend"><i class="fa fa-paper-plane slx-button__icon" aria-hidden="true"></i>Send</button>
  </div>

</div>
