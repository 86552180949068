export interface IWcCodeEntry {
  id: number;
  orgLevelId: number;
  code: string;
  codeType: string;
  description: string;
}

export class WcCodeEntry {
  constructor(
    public id: number = null,
    public orgLevelId: number = null,
    public code: string = null,
    public codeType: string = null,
    public description: string = null
  ) {}
}
