import * as tslib_1 from "tslib";
import { ExportDataExecutionItem } from '../export-data-execution';
var AcaExportExecutionItem = /** @class */ (function (_super) {
    tslib_1.__extends(AcaExportExecutionItem, _super);
    function AcaExportExecutionItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AcaExportExecutionItem;
}(ExportDataExecutionItem));
export { AcaExportExecutionItem };
