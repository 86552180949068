var OpenShiftPostionsOrgDetails = /** @class */ (function () {
    function OpenShiftPostionsOrgDetails() {
    }
    Object.defineProperty(OpenShiftPostionsOrgDetails.prototype, "isNoConfiguration", {
        get: function () {
            return (this.totalHoursGap === 0 &&
                this.totalOpenShifts === 0 &&
                this.idealIndicator === 0) ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftPostionsOrgDetails.prototype, "isNoRotationGaps", {
        get: function () {
            return (this.totalHoursGap === 0 &&
                this.totalOpenShifts === 0 &&
                this.idealIndicator > 0) ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    return OpenShiftPostionsOrgDetails;
}());
export { OpenShiftPostionsOrgDetails };
