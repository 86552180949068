import { Component, ViewEncapsulation, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { AcaC1095AuditManagementService } from '../../../services';
import { GridOptions, CellEvent } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { unsubscribe, unsubscribeAll } from '../../../../core/decorators';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as _ from 'lodash';
import { EmployeeSectionNavigationService } from '../../../../common';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { AcaC1095Audit, AcaC1095AuditExport, AcaC1095AuditRecord, EmployeeIdList } from '../../../models/aca-c1095-audit/aca-c1095-audit-record';
import { AcaC1095AuditAgGridHelper } from './aca-c1095-audit-ag-grid-helper';
import * as moment from 'moment';
import { EmployeeFilterRecord, EmployeeFilter } from '../../../models';

@Component({
  selector: 'slx-aca-c1095-audit-grid',
  templateUrl: './aca-c1095-audit-grid.component.html',
  styleUrls: ['./aca-c1095-audit-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AcaC1095AuditGridComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public getRowHeight;
  public gridApiExport;
  public exportRow: any;
  public records: AcaC1095AuditRecord[];
  public storedRecords: AcaC1095AuditRecord[];
  public columnList: string[] = [];
  public rowData: any[];
  public rowDataExport: any[];
  public rowCount: string;
  public footerData: any[];
  public gridHelper: AcaC1095AuditAgGridHelper;
  public columnDefs: any[];
  public sugestedColuumnDefs: any[];
  public editedColuumnDefs: any[];
  public confirmedColuumnDefs: any[];
  public defaultColumnDefs: any
  public appConfig: IApplicationConfig;

  private scrollTop: number;
  private scrollLeft: number;
  private restoreScroll: boolean;

  public gridScrollElement: HTMLElement;

  public selectedEmployees: EmployeeFilterRecord[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  constructor(private managementService: AcaC1095AuditManagementService, private router: Router, private route: ActivatedRoute, private elementRef: ElementRef) {
    this.appConfig = appConfig;
    this.gridHelper = new AcaC1095AuditAgGridHelper();
    this.gridOptions = <GridOptions>{};
    this.getRowHeight = function (params) {
      return 30;
    };
  }

  public ngOnInit() {
    this.gridHelper.createColumnDefs();
    this.gridOptions = {
      columnDefs: this.gridHelper.columnDefs,

      defaultColDef: {
        minWidth: 100,
        suppressMenu: true
      },
      enableRangeSelection: true,
      groupSuppressAutoColumn: true,
      animateRows: true

    };
    this.gridOptions.getRowStyle = (params: any) => this.gridHelper.getRowStyle(params);
    this.subscriptions.onLoaded = this.managementService
      .subscribeToLoadedRecords((container: AcaC1095Audit) => {
        this.records = container.records;
        this.storedRecords = _.clone(container.records);
        this.refreshGrid();
      });

    this.subscriptions.expand = this.managementService
      .subscribeToExpandAll((isExpand: boolean) => this.expandAll(isExpand));
    this.subscriptions.export = this.managementService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.empFilterRecords = this.managementService
      .subscribeToEmpFilterRecords((employeeFilter: EmployeeFilterRecord[]) => this.applyFilter(employeeFilter));

    this.subscriptions.employeeFilter = this.managementService
      .subscribeToEmployeeFilter((records: EmployeeFilter) => {
        this.applyFilter(records.filter)
      });
  }

  public ngOnDestroy(): void { }

  private refreshGrid(): void {
    let records: any = _.clone(this.storedRecords);

    if (this.selectedEmployees && this.selectedEmployees.length > 0) {
      this.records = _.filter(records, (r: AcaC1095AuditRecord) => {
        let res = false;
        _.forEach(this.selectedEmployees, (employee: EmployeeFilterRecord) => {
          res = res || _.isEqual(r.employeeId, employee.id);
        });
        return res;
      });
    }
    else {
      this.records = records;
    }
    if (this.records) {
      this.rowData = this.records;
      this.restoreScroll = true;
      this.generateExportData();
    }
  }

  public onRowGroupOpened(params) {
    this.hideShowRows(params);
    if (params.node.expanded) { 
      if (params.node.level === 0) {
        this.setExpandedList(true, false, params.node.allLeafChildren[0].data.employeeId);
      }
      if (params.node.level === 1) {
        this.setExpandedList(true, true, params.node.allLeafChildren[0].data.employeeId);
      }

    } else {
      if (params.node.level === 1) {
        this.setExpandedList(true, false, params.node.allLeafChildren[0].data.employeeId);
      }
      else {
        this.setExpandedList(true, false, params.node.allLeafChildren[0].data.employeeId);
      }
    }
  }

  private hideShowRows(params: any): void {
    if (this.elementRef) {
      const controls: HTMLElement = this.elementRef.nativeElement;
      const elements = controls.querySelectorAll(`.level_${params.node.level}_${params.node.allLeafChildren[0].data.employeeId}_${params.node.allLeafChildren[0].data.month}`);
      _.forEach(elements, function (item) {
        if (params.node.expanded) {
          item.setAttribute("style", "display:none;")
        }
        else {
          item.setAttribute("style", "display:block;")
        }
      });
    }
  }

  public onCellClicked($event: CellEvent): void {
    if ($event.column.getColDef().colId === 'employeeId') {
      let empId: string = $event.node.allLeafChildren[0].data.employeeId;
      if (empId !== null && empId !== undefined)
        if (parseInt(empId) === 0) {
          return;
        }
      const parsedEmpId = parseInt(empId, 10);
      let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);

      const urlTree = navService.getUrlTreeFromRoot(parsedEmpId, true);
      const extras: NavigationExtras = {
        skipLocationChange: false,
        replaceUrl: false,
      };
      this.router.navigateByUrl(urlTree, extras);
    }
  }

  public expandAll(isExpand: boolean): void {
    _.forEach(this.records, function (item) {
      if (isExpand) {
        item.expand = true;
        item.expandMonth = true;
      }
      else {
        item.expand = false;
        item.expandMonth = false;
      }
    });
    this.managementService.setEmployeeGridExpandCollapse(isExpand);
    this.gridOptions.api.forEachNode(node => {
      node.expanded = isExpand;
    });
    this.gridOptions.api.onGroupExpandedOrCollapsed();
  }

  public onComponentStateChanged(event: any): void {
    this.gridApi.forEachNode((node, b) => {
      _.forEach(this.storedRecords, (k) => {
        if (k.expand === true) {
          if (node.key === k.employeeName) {
            if (node.level === 0) {
              node.setExpanded(true);
            }
          }
        }
      });

      _.forEach(this.storedRecords, (k) => {
        if (k.expandMonth === true) {
          if (node.key === k.month) {
            if (node.level === 1) {
              node.setExpanded(true);
            }
          }
        }
      });
    });

    if (this.restoreScroll && this.gridScrollElement) {
      this.restoreScroll = false;
      this.gridScrollElement.scrollTop = this.scrollTop;
      this.gridScrollElement.scrollLeft = this.scrollLeft;
      if (this.gridOptions.api) {
        this.gridOptions.api.redrawRows();
      }
    }
  }

  private setExpandedList(firstGroupState: boolean, secondGroupState: boolean, empId: number): void {
    let employee: EmployeeIdList = new EmployeeIdList();
    employee.employeeId = empId;
    employee.expand = firstGroupState;
    employee.expandMonth = secondGroupState;
    this.managementService.setEmployeeIdList(employee);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridReadyExport(params) {
    this.gridApiExport = params.api;
  }

  private exportTo(isPDF: boolean): void {
    let columnList: any[] = [
      'employeeName',
      'year',
      'month',
      'sugCode',
      'sugCost',
      'sugHarbor',
      'sugZip',
      'selCode',
      'selCost',
      'selHarbor',
      'selZip',
      'selectedBy',
      'selectedOn',
      'confirmedInd',
      'confirmedBy',
      'confirmedOn'
    ];
    let params: any = {
      allColumns: true,
      skipHeader: false,
      skipGroups: true,
      columnGroups: true,
      myGroupHeaderCallback: (params: any) => {
        var displayName = params.columnApi.getDisplayNameForColumnGroup(
          params.columnGroup
        );
        return displayName.toUpperCase();
      },
      sheetName: 'Aca_1095C_Audit',
      columnKeys: columnList,
      fileName: 'Aca_1095C_Audit_' + moment().format(appConfig.dateTimeFormat).replace(/_/g, " ") + '.xlsx',
    };
    if (this.gridApiExport) {
      this.gridApiExport.exportDataAsExcel(params);
    }
  }

  private generateExportData(): void {
    let exportRecords: AcaC1095AuditExport[] = [];
    _.forEach(this.records, function (item) {
      let record: AcaC1095AuditExport = new AcaC1095AuditExport();
      record.employeeId = item.employeeId
      record.employeeName = item.employeeName
      record.year = item.year
      record.month = item.month
      record.expand = item.expand
      record.sugCode = item.sugCode
      record.sugCost = item.sugCost
      record.sugHarbor = item.sugHarbor
      record.sugZip = item.sugZip
      record.selCode = item.selCode
      record.selCost = item.selCost
      record.selZip = item.selZip
      record.selHarbor = item.selHarbor
      record.selectedBy = item.selectedBy
      record.selectedOn = item.selectedOn
      record.confirmedInd = item.confirmedInd
      record.confirmedBy = item.confirmedBy
      record.confirmedOn = item.confirmedOn
      exportRecords.push(record);
    });

    this.exportRow = exportRecords;
  }

  private applyFilter(employeeFilter: EmployeeFilterRecord[]): void {
    this.selectedEmployees = employeeFilter;
    this.refreshGrid();
  }
}
