import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { UrlParamsService } from '../url-params/url-params.service';
import { ApiUtilService } from '../api/api-util.service';
import { ResponseBody, Meta } from '../../../core/models/index';
var AttachmentsUploaderHelperService = /** @class */ (function () {
    function AttachmentsUploaderHelperService(apiUtilService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    AttachmentsUploaderHelperService.prototype.uploadAttachmentsAsFormData = function (url, files, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.handleErrors(url, files);
                promise = Promise.resolve(null);
                _.forEach(files, function (file) {
                    var request = _this.createRequest(url, file, params);
                    promise = promise
                        .then(function () { return _this.apiUtilService.requestNew(request); });
                });
                return [2 /*return*/, promise];
            });
        });
    };
    AttachmentsUploaderHelperService.prototype.createRequest = function (url, file, params) {
        var formData = this.mapFilesToFormData(file);
        var request = this.urlParamsService.requestPost(url, formData, params);
        request.autoContentType = true;
        return request;
    };
    AttachmentsUploaderHelperService.prototype.mapFilesToFormData = function (file) {
        var formData = new FormData();
        formData.append('i0_name', file.name);
        formData.append('i0_fileName', file.fileName);
        formData.append('i0_category', file.mimeType);
        formData.append('i0_file', file.data, file.fileName);
        return formData;
    };
    AttachmentsUploaderHelperService.prototype.handleErrors = function (url, files) {
        if (!_.isString(url) || _.size(url) === 0) {
            throw new TypeError("AttachmentsUploaderHelperService: parameter \"url\" is not a string or empty string. url: " + String(url));
        }
        if (!_.isArray(files) || _.size(files) === 0) {
            throw new TypeError("AttachmentsUploaderHelperService: parameter \"files\" is not an array or empty array. files: " + String(files));
        }
    };
    return AttachmentsUploaderHelperService;
}());
export { AttachmentsUploaderHelperService };
