export interface IUserPasswordSettings {
    userPasswordComplexity: IUserPasswordComplexity;
    userPasswordLifetime: IUserPasswordLifetime;
}

export interface IUserPasswordComplexity {
    pwdLength: number;
    specialCount: number;
    capitalCount: number;
}

export interface IUserPasswordLifetime {
    enabled: boolean;
    daysCount: number;
}

export class UserPasswordSettings {
    public userPasswordComplexity: UserPasswordComplexity;
    public userPasswordLifetime: UserPasswordLifetime;
}

export class UserPasswordComplexity {
    public pwdLength: number;
    public specialCount: number;
    public capitalCount: number;
}

export class UserPasswordLifetime {
    public enabled: boolean;
    public daysCount: number;
}
