import * as tslib_1 from "tslib";
import { Shift } from '../../../models/index';
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind } from '../../../models/index';
import { appMessages } from '../../../../app.messages';
import { appConfig } from '../../../../app.config';
import { FormGroup } from '@angular/forms';
import { ShiftsManagementService } from '../../../services/index';
var EditorShiftRendererComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EditorShiftRendererComponent, _super);
    function EditorShiftRendererComponent(management) {
        var _this = _super.call(this) || this;
        _this.management = management;
        _this.appMessages = appMessages;
        _this.appConfig = appConfig;
        _this.prohibitedNameValues = [];
        return _this;
    }
    EditorShiftRendererComponent.prototype.onSaveClick = function () {
        this.actionEmitter.emit(EditableListActionKind.COMPLETE_EDIT);
    };
    EditorShiftRendererComponent.prototype.onCancelClick = function () {
        this.actionEmitter.emit(EditableListActionKind.CANCEL_EDIT);
    };
    return EditorShiftRendererComponent;
}(CustomListActorBase));
export { EditorShiftRendererComponent };
