import { Component, OnInit } from '@angular/core';
import { IDialog, ModalService, DialogOptions2 } from '../../../../../common';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import { ViewChild, Provider } from '@angular/core';
import { AbstractControl, NgControl, NgForm } from '@angular/forms';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { acaCorrectionTypeConfig, exportTypeName, receiptIdMaskRegExp } from '../../../export-data.config';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import * as _ from 'lodash';

@Component({
  selector: 'slx-aca-corrections-dialog',
  templateUrl: './aca-corrections-dialog.component.html',
  styleUrls: ['./aca-corrections-dialog.component.scss']
})

export class AcaCorrectionsDialogComponent implements IDialog, OnInit {

  public get initialized(): boolean {
    return this.m_initialized;
  }

  @ViewChild('correctionForm', { static: true })
  private ngFormChild: NgForm;

  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }
  public acaCorrectionType: AcaExportCorrectionType = new AcaExportCorrectionType();
  public acaExportExecutionItem: AcaExportExecutionItem;
  public manService: AcaExportManagementService;
  private modalService: ModalService;
  public options: DialogOptions2;
  private m_initialized: boolean;
  public correctionTypeOptions: AcaExportCorrectionType[];
  public receiptIdMaskConf: any;

  constructor(
    modalService: ModalService,
    options: DialogOptions2,
    manService: AcaExportManagementService
  ) {
    this.options = options;
    this.modalService = modalService;
    this.manService = manService;
    this.receiptIdMaskConf = receiptIdMaskRegExp;
  }

  public dialogResult: boolean = false;
  public isLoading: boolean = true;

  public createCorrectionTypeOptions(): void {

    let correctionType: AcaExportCorrectionType[] = acaCorrectionTypeConfig;
    correctionType = _.filter(acaCorrectionTypeConfig, (item: AcaExportCorrectionType) => (
      _.includes(item.relatedExportType, this.acaExportExecutionItem.type)
    ));

    this.correctionTypeOptions = correctionType;

  }
  public resetReceiptId(): void {
    this.acaCorrectionType.receiptId = null;
  }
  public ngOnInit(): void {
    this.m_initialized = true;
    this.isLoading = false;

  }

  public get valid(): boolean {
    let formValid: boolean = this.form.invalid ? false : true;
    return formValid;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public showSelectEmpDialog(): void {
    if (this.acaCorrectionType && this.acaCorrectionType.id == 1) {
      this.onCancel();
      let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
      acaExportDialogOptions.dialogType = 5;
      acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
      acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
      this.manService.openAcaExportDialog(acaExportDialogOptions);
    }
    else if (this.acaCorrectionType && this.acaCorrectionType.id == 2) {
      this.onCancel();
      let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
      acaExportDialogOptions.dialogType = 2;
      acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
      acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
      this.manService.openAcaExportDialog(acaExportDialogOptions);
    } else if (this.acaCorrectionType && this.acaCorrectionType.id == 3) {
      this.onCancel();
      let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
      acaExportDialogOptions.dialogType = 4;
      acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
      acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
      this.manService.openAcaExportDialog(acaExportDialogOptions);
    }
  }
}
