import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { PmEmployeeRosterManagementService } from '../../../services/pm-employee-roster-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { unsubscribeAll } from '../../../../../core/decorators';
var PmEmployeeRosterComponent = /** @class */ (function () {
    function PmEmployeeRosterComponent(manService, activatedRoute, router) {
        this.manService = manService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.subscriptions = {};
    }
    PmEmployeeRosterComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.loading = this.manService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
        this.subscriptions.routeSubscription = this.activatedRoute.params.subscribe(function (params) {
            var employeeId = parseInt(params.employeeId, 10);
            if (_.isNumber(employeeId)) {
                _this.manService.setEmployeeId(employeeId);
            }
        });
        this.manService.init();
    };
    PmEmployeeRosterComponent.prototype.ngOnDestroy = function () { };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmEmployeeRosterComponent.prototype, "subscriptions", void 0);
    return PmEmployeeRosterComponent;
}());
export { PmEmployeeRosterComponent };
