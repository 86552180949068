export interface IUserRoleRelationDTO {
  userId: number;
  roleId: number;
  orgLevelId: number;
}

export class UserRoleRelation {
  public userId: number;
  public roleId: number;
  public orgLevelId: number;
}
