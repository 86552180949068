import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { GridOptions, CellEvent } from 'ag-grid-community';
import { AcaMeasurementAgGridHelper } from './aca-measurement-ag-grid-helper';
import { AcaMeasurement, AcaMeasurementRecord, EmployeeFilterRecord, PeriodsPopupModel, filterAcaMeasurementRecord, EmployeeFilter, AcaMeasurementRecordExport } from '../../../models';
import { AcaMeasurementManagementService } from '../../../services';
import { Subscription } from 'rxjs';
import { unsubscribeAll } from '../../../../core/decorators';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import * as _ from 'lodash';
import { EmployeeSectionNavigationService } from '../../../../common';
import { AcaMeasurementSettings } from '../../../models/aca-measurement/aca-measurement-settings';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { EmployeeIdList } from '../../../models/aca-measurement/aca-measurement-state';
import * as moment from 'moment';

@Component({
  selector: 'slx-aca-measurement-ag-grid',
  templateUrl: './aca-measurement-ag-grid.component.html',
  styleUrls: ['./aca-measurement-ag-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AcaMeasurementAgGridComponent implements OnInit, OnDestroy {
  public gridOptions: GridOptions;
  public gridApi;
  public gridColumnApi;
  public getRowHeight;
  public gridApiExport;
  public exportRow: any;

  public records: AcaMeasurementRecord[];
  public storedRecords: AcaMeasurementRecord[];
  public insightFilteredRecords: AcaMeasurementRecord[];
  public columnList: string[] = [];

  public rowData: any[];
  public rowDataExport: any[];
  public rowCount: string;
  public footerData: any[];
  public acaInsightFilter: filterAcaMeasurementRecord;
  public gridScrollElement: HTMLElement;
  public gridHelper: AcaMeasurementAgGridHelper;


  public selectedEmployees: EmployeeFilterRecord[];
  public periodModel: PeriodsPopupModel = new PeriodsPopupModel();
  public appConfig: IApplicationConfig;
  private isExpandAll: boolean;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(private managementService: AcaMeasurementManagementService, private router: Router, private route: ActivatedRoute) {
    this.appConfig = appConfig;
    this.gridHelper = new AcaMeasurementAgGridHelper();
    this.gridHelper.state = {
      year: 2020,
      numMonths: 12,
      organizationColShown: false,
      departmentColShown: false,
      positionColShown: false,
      dateHiredColShown: false,
      dateTerminatedColShown: false,
      mpStartColShown: false,
      mpEndColShown: false,
      weeklyAvgHoursColShown: false,
      apStartColShown: false,
      apEndColShown: false,
      spStartColShown: false,
      spEndColShown: false,
      acaDateColShown: false,
      acaTypeColShown: false,
      empTypeColShown: false,
      acaExcludeColShown: false,
      benefitDeclinedColShown: false,
      purchaseMarketplaceColShown: false,
      benefitNameColShown: false,
      benefitStartColShown: false,
    };

    this.gridOptions = <GridOptions>{};

    this.getRowHeight = function (params) {
      var periodTypeLength = params.data.periodTypes.length;
      if (params.data.expand) {
        return 34 * periodTypeLength;
      }
      else {
        var width = 12 * periodTypeLength;
        return width < 24 ? 24 : width;
      }
    };
  }

  public ngOnInit() {
    this.gridHelper.createColumnDefs();

    this.subscriptions.onLoaded = this.managementService
      .subscribeToLoadedRecords((container: AcaMeasurement) => {
        this.records = container.records;
        this.storedRecords = _.clone(container.records);
        this.refreshGrid();
      });

    this.subscriptions.empFilterRecords = this.managementService
      .subscribeToEmpFilterRecords((employeeFilter: EmployeeFilterRecord[]) => this.applyFilter(employeeFilter));

    this.subscriptions.employeeFilter = this.managementService
      .subscribeToEmployeeFilter((records: EmployeeFilter) => {
        this.applyFilter(records.filter)
      });

    this.subscriptions.export = this.managementService
      .subscribeToExport((isPDF: boolean) => this.exportTo());

    this.subscriptions.periodPopper = this.managementService
      .subscribeToTogglePopper((item: PeriodsPopupModel) => {
        this.periodModel = item;
      });

    this.subscriptions.expandAll = this.managementService
      .subscribeToIsExpandAll((isExpand: boolean) => {
        this.isExpandAll = isExpand;
        this.setExpandAll();
      });

  }

  public ngAfterViewInit(): void {

    this.subscriptions.settings = this.managementService.onSettingsChanged$.subscribe((settings: AcaMeasurementSettings) => {

      this.gridHelper.state.organizationColShown = settings.columns.columnsMap['organizationName'] ? settings.columns.columnsMap['organizationName'].displayed : false;
      this.gridHelper.state.departmentColShown = settings.columns.columnsMap['departmentName'] ? settings.columns.columnsMap['departmentName'].displayed : false;
      this.gridHelper.state.positionColShown = settings.columns.columnsMap['positionName'] ? settings.columns.columnsMap['positionName'].displayed : false;
      this.gridHelper.state.dateHiredColShown = settings.columns.columnsMap['dateHired'] ? settings.columns.columnsMap['dateHired'].displayed : false;
      this.gridHelper.state.dateTerminatedColShown = settings.columns.columnsMap['dateTerm'] ? settings.columns.columnsMap['dateTerm'].displayed : false;
      this.gridHelper.state.mpStartColShown = settings.columns.columnsMap['measurementPeriodStart'] ? settings.columns.columnsMap['measurementPeriodStart'].displayed : false;
      this.gridHelper.state.mpEndColShown = settings.columns.columnsMap['measurementPeriodEnd'] ? settings.columns.columnsMap['measurementPeriodEnd'].displayed : false;
      this.gridHelper.state.weeklyAvgHoursColShown = settings.columns.columnsMap['weeklyAvgHours'] ? settings.columns.columnsMap['weeklyAvgHours'].displayed : false;
      this.gridHelper.state.apStartColShown = settings.columns.columnsMap['administrativePeriodStart'] ? settings.columns.columnsMap['administrativePeriodStart'].displayed : false;
      this.gridHelper.state.apEndColShown = settings.columns.columnsMap['administrativePeriodEnd'] ? settings.columns.columnsMap['administrativePeriodEnd'].displayed : false;
      this.gridHelper.state.spStartColShown = settings.columns.columnsMap['stabilityPeriodStart'] ? settings.columns.columnsMap['stabilityPeriodStart'].displayed : false;
      this.gridHelper.state.spEndColShown = settings.columns.columnsMap['stabilityPeriodEnd'] ? settings.columns.columnsMap['stabilityPeriodEnd'].displayed : false;
      this.gridHelper.state.acaDateColShown = settings.columns.columnsMap['acaDate'] ? settings.columns.columnsMap['acaDate'].displayed : false;
      this.gridHelper.state.acaTypeColShown = settings.columns.columnsMap['acaType'] ? settings.columns.columnsMap['acaType'].displayed : false;
      this.gridHelper.state.empTypeColShown = settings.columns.columnsMap['empType'] ? settings.columns.columnsMap['empType'].displayed : false;
      this.gridHelper.state.acaExcludeColShown = settings.columns.columnsMap['acaExclude'] ? settings.columns.columnsMap['acaExclude'].displayed : false;
      this.gridHelper.state.benefitDeclinedColShown = settings.columns.columnsMap['benefitDeclined'] ? settings.columns.columnsMap['benefitDeclined'].displayed : false;
      this.gridHelper.state.purchaseMarketplaceColShown = settings.columns.columnsMap['purchasedMarketplace'] ? settings.columns.columnsMap['purchasedMarketplace'].displayed : false;
      this.gridHelper.state.benefitNameColShown = settings.columns.columnsMap['benefitName'] ? settings.columns.columnsMap['benefitName'].displayed : false;
      this.gridHelper.state.benefitStartColShown = settings.columns.columnsMap['benefitStart'] ? settings.columns.columnsMap['benefitStart'].displayed : false;

      this.setColumnAllState();

      this.setFilteredColumns();

    });

  }

  public ngOnDestroy(): void {
  }

  private applyFilter(employeeFilter: EmployeeFilterRecord[]): void {
    this.selectedEmployees = employeeFilter;
    this.refreshGrid();
  }

  private refreshGrid(): void {
    let records = _.clone(this.storedRecords);
    if (this.selectedEmployees && this.selectedEmployees.length > 0) {

      this.records = _.filter(records, (r: AcaMeasurementRecord) => {
        let res = false;
        _.forEach(this.selectedEmployees, (employee: EmployeeFilterRecord) => {
          res = res || _.isEqual(r.empId, employee.id);
        });
        return res;
      });
    }
    else {
      this.records = records;
    }

    if (this.records) {
      this.rowData = this.records;
      this.generateExportData();
    }

  }

  public onCellClicked($event: CellEvent): void {

    if ($event.column.getColDef().field === 'empName') {
      let empId: string = $event.node.data.empId;
      if (empId !== null && empId !== undefined)
        if (parseInt(empId) === 0) {
          return;
        }
      const parsedEmpId = parseInt(empId, 10);
      let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.route);

      const urlTree = navService.getUrlTreeFromRoot(parsedEmpId, true);
      const extras: NavigationExtras = {
        skipLocationChange: false,
        replaceUrl: false,
      };
      this.router.navigateByUrl(urlTree, extras);
    }
    else if ($event.column.getColDef().field === 'expand') {
      if ($event.node.data.expand == false) {
        $event.node.data.expand = true;
        this.setExpandedList(true, $event.node.data.empId);
      }
      else {
        $event.node.data.expand = false;
        this.setExpandedList(false, $event.node.data.empId);
      }
      if (this.gridApi) {
        this.gridApi.expandAll();
        this.gridApi.resetRowHeights();
      }
    }

  }

  private setExpandAll() : void {
    if (this.gridApi) {
      this.gridApi.expandAll();
      this.gridApi.resetRowHeights();
    }
  }
  
  private setExpandedList(state: boolean, empId: number): void {
    let employee: EmployeeIdList = new EmployeeIdList();
    employee.employeeId = empId;
    employee.expand = state;
    this.managementService.setEmployeeIdList(employee);
  }

  private setColumnsState(fieldName: string, isColShown): void {
    if (this.gridOptions.columnApi) {
      this.gridOptions.columnApi.setColumnVisible(fieldName, isColShown);
    }
  }

  private setColumnAllState(): void {
    this.setColumnsState('organizationName', this.gridHelper.state.organizationColShown);
    this.setColumnsState('departmentName', this.gridHelper.state.departmentColShown);
    this.setColumnsState('positionName', this.gridHelper.state.positionColShown);
    this.setColumnsState('dateHired', this.gridHelper.state.dateHiredColShown);
    this.setColumnsState('dateTerm', this.gridHelper.state.dateTerminatedColShown);
    this.setColumnsState('empType', this.gridHelper.state.empTypeColShown);
    this.setColumnsState('measurementPeriodStart', this.gridHelper.state.mpStartColShown);
    this.setColumnsState('measurementPeriodEnd', this.gridHelper.state.mpEndColShown);
    this.setColumnsState('weeklyAvgHours', this.gridHelper.state.weeklyAvgHoursColShown);
    this.setColumnsState('administrativePeriodStart', this.gridHelper.state.apStartColShown);
    this.setColumnsState('administrativePeriodEnd', this.gridHelper.state.apEndColShown);
    this.setColumnsState('stabilityPeriodStart', this.gridHelper.state.spStartColShown);
    this.setColumnsState('stabilityPeriodEnd', this.gridHelper.state.spEndColShown);
    this.setColumnsState('acaDate', this.gridHelper.state.acaDateColShown);
    this.setColumnsState('acaType', this.gridHelper.state.acaTypeColShown);
    this.setColumnsState('acaExclude', this.gridHelper.state.acaExcludeColShown);
    this.setColumnsState('benefitDeclined', this.gridHelper.state.benefitDeclinedColShown);
    this.setColumnsState('purchasedMarketplace', this.gridHelper.state.purchaseMarketplaceColShown);
    this.setColumnsState('benefitName', this.gridHelper.state.benefitNameColShown);
    this.setColumnsState('benefitStart', this.gridHelper.state.benefitStartColShown);
  }

  private setFilteredColumns(): void {
    this.columnList = [];
    this.columnList.push('empName');
    this.columnList.push('periodType');
    if (this.gridHelper.state.organizationColShown) {
      this.columnList.push('organizationName');
    }
    if (this.gridHelper.state.departmentColShown) {
      this.columnList.push('departmentName');
    }
    if (this.gridHelper.state.positionColShown) {
      this.columnList.push('positionName');
    }
    if (this.gridHelper.state.dateHiredColShown) {
      this.columnList.push('dateHired');
    }
    if (this.gridHelper.state.dateTerminatedColShown) {
      this.columnList.push('dateTerm');
    }
    if (this.gridHelper.state.empTypeColShown) {
      this.columnList.push('empType');
    }
    if (this.gridHelper.state.mpStartColShown) {
      this.columnList.push('measurementPeriodStart');
    }
    if (this.gridHelper.state.mpEndColShown) {
      this.columnList.push('measurementPeriodEnd');
    }
    if (this.gridHelper.state.weeklyAvgHoursColShown) {
      this.columnList.push('weeklyAvgHours');
    }
    if (this.gridHelper.state.apStartColShown) {
      this.columnList.push('administrativePeriodStart');
    }
    if (this.gridHelper.state.apEndColShown) {
      this.columnList.push('administrativePeriodEnd');
    }
    if (this.gridHelper.state.spStartColShown) {
      this.columnList.push('stabilityPeriodStart');
    }
    if (this.gridHelper.state.spEndColShown) {
      this.columnList.push('stabilityPeriodEnd');
    }
    if (this.gridHelper.state.acaDateColShown) {
      this.columnList.push('acaDate');
    }
    if (this.gridHelper.state.acaTypeColShown) {
      this.columnList.push('acaType');
    }
    if (this.gridHelper.state.acaExcludeColShown) {
      this.columnList.push('acaExclude');
    }
    if (this.gridHelper.state.benefitDeclinedColShown) {
      this.columnList.push('benefitDeclined');
    }
    if (this.gridHelper.state.purchaseMarketplaceColShown) {
      this.columnList.push('purchasedMarketplace');
    }
    if (this.gridHelper.state.benefitNameColShown) {
      this.columnList.push('benefitName');
    }
    if (this.gridHelper.state.benefitStartColShown) {
      this.columnList.push('benefitStart');
    }

  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onGridReadyExport(params) {
    this.gridApiExport = params.api;
  }

  private exportTo(): void {
    let params: any = {
      allColumns: false,
      sheetName: 'Aca_Measurement',
      columnKeys: this.columnList,
      fileName: 'ACA_Measurement_Export_' + moment().format(appConfig.dateTimeFormat).replace(/_/g, " ") + '.xlsx',
    };
    if (this.gridApiExport) {
      this.gridApiExport.exportDataAsExcel(params);
    }
  }

  private generateExportData(): void {
    let exportRecords: AcaMeasurementRecordExport[] = [];
    _.forEach(this.records, function (item) {
      let i = 0;
      _.forEach(item.periodTypes, function (pItem) {
        let record: AcaMeasurementRecordExport = new AcaMeasurementRecordExport();
        if (i == 0) {
          record.empId = item.empId;
          record.empName = item.empName;
          record.organizationName = item.organizationName;
          record.departmentName = item.departmentName;
          record.positionName = item.positionName;
          record.dateHired = item.dateHired;
          record.dateTerm = item.dateTerm;
          record.empType = item.empType;
        }
        record.periodType = pItem.periodType;
        record.measurementPeriodStart = pItem.measurementPeriodStart;
        record.measurementPeriodEnd = pItem.measurementPeriodEnd;
        var mpPeriod = _.find(pItem.acaPeriod, ['periodSortName', 'MP']);
        if(mpPeriod)
        {
          record.weeklyAvgHours = mpPeriod.empAvgHours.toString();
        }
        else
        {
          record.weeklyAvgHours = '';
        }
        
        record.administrativePeriodStart = pItem.administrativePeriodStart;
        record.administrativePeriodEnd = pItem.administrativePeriodEnd;
        record.stabilityPeriodStart = pItem.stabilityPeriodStart;
        record.stabilityPeriodEnd = pItem.stabilityPeriodEnd;
        record.acaDate = pItem.acaDate;
        record.acaType = pItem.acaType;
        record.acaExclude = pItem.acaExclude;
        record.benefitDeclined = pItem.benefitDeclined;
        record.benefitName = pItem.benefitName;
        record.benefitStart = pItem.benefitStart;
        record.purchasedMarketplace = pItem.purchasedMarketplace;
        exportRecords.push(record);
        i = i + 1;
      });
    });

    this.exportRow = exportRecords;
  }
}
