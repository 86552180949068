import { AppServerConfig } from './../model/app-server-config';

import { ResponseBody, Meta, AuthError } from './../../core/models/index';
import { HttpRequest } from '@angular/common/http';
import { UrlParamsService } from './../../common/services/url-params/url-params.service';
import { ApiUtilService } from './../../common/services/api/api-util.service';
import { CacheType } from './../../common/models/cache/cache-definition';
import { Injectable } from '@angular/core';
import { AppSettingsMapService } from './app-settings-map.service';
import { AppSetting, IAppSetting } from '../model/app-setting';
import { appSettingsConfig } from '../app-settings.config';

@Injectable()
export class AppSettingsApiService {
    constructor(private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: AppSettingsMapService) {
    }

    public getAppSettings(): Promise<AppServerConfig> {
        const url: string = this.getApiRoot();
        const request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
        const promise: Promise<AppServerConfig> = this.apiUtilService.cachedRequest<IAppSetting[], Meta>(request, CacheType.longTerm)
        .then((response: ResponseBody<IAppSetting[], Meta>) => this.mapService.mapToAppServerConfig(response.data));
        return promise;
    }

    private getApiRoot(): string {
        return `${this.apiUtilService.getApiRoot()}/${appSettingsConfig.api.app.root}/${appSettingsConfig.api.app.settings}`;
    }
}
