import * as tslib_1 from "tslib";
import { PositionGroup } from '../../../organization/models/index';
var PositionGroupView = /** @class */ (function (_super) {
    tslib_1.__extends(PositionGroupView, _super);
    function PositionGroupView() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PositionGroupView;
}(PositionGroup));
export { PositionGroupView };
