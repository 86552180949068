/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./date-time-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./date-time-picker.component";
import * as i3 from "@angular/forms";
var styles_DateTimePickerComponent = [i0.styles];
var RenderType_DateTimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DateTimePickerComponent, data: {} });
export { RenderType_DateTimePickerComponent as RenderType_DateTimePickerComponent };
export function View_DateTimePickerComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_DateTimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["slx-date-time-picker", ""]], null, null, null, View_DateTimePickerComponent_0, RenderType_DateTimePickerComponent)), i1.ɵdid(1, 770048, null, 0, i2.DateTimePickerComponent, [i1.ElementRef, i3.NgControl], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DateTimePickerComponentNgFactory = i1.ɵccf("[slx-date-time-picker]", i2.DateTimePickerComponent, View_DateTimePickerComponent_Host_0, { placeholder: "placeholder", readonly: "readonly", minDate: "minDate", maxDate: "maxDate", format: "format", interval: "interval", dateInput: "dateInput" }, {}, []);
export { DateTimePickerComponentNgFactory as DateTimePickerComponentNgFactory };
