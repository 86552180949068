import * as _ from 'lodash';

export enum AcaExportType {
  Export1095C = 2,
  Export1094C = 1,
  ExportXmlTest = 3,
  ExportXmlCommunication = 4,
  ExportXmlCorrected1094C = 5,
  ExportXmlCorrected1095C = 6,
  ExportXmlReplacement1095C = 7,
  ExportXmlSupplemental1095C = 8,
  ExportPdfCorrected1094C = 9,
  ExportPdfCorrected1095C = 10,
  ExportPdfCorrected1095C1094C = 11,
  ExportPdfSupplemental1094C = 12,
  ExportPdfSupplemental1095C = 13
}

export const acaRelatedExports: AcaExportType[][] = [
  [AcaExportType.ExportXmlCommunication, AcaExportType.ExportXmlCorrected1094C, AcaExportType.ExportXmlCorrected1095C, AcaExportType.ExportXmlReplacement1095C, AcaExportType.ExportXmlSupplemental1095C],
  [AcaExportType.Export1094C, AcaExportType.ExportPdfCorrected1094C, AcaExportType.ExportPdfCorrected1095C1094C, AcaExportType.ExportPdfSupplemental1094C],
  [AcaExportType.Export1095C, AcaExportType.ExportPdfCorrected1095C, AcaExportType.ExportPdfSupplemental1095C]
];

export const getAcaRelatedExportTypes = (exportType: AcaExportType): AcaExportType[] => {
  const relatedExports = _.uniq(_.flattenDeep(_.filter(acaRelatedExports, function (item) {
    return _.includes(item, exportType);
  })));

  if (!relatedExports || !relatedExports.length) {
    return [exportType];
  }

  return relatedExports;
};
