import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, ElementRef, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { unsubscribeAll } from '../../../../core/decorators';

import { MessagesManagementService } from '../../services/messages-management.service';

@Component({
  selector: 'slx-messages-grid',
  templateUrl: './messages-grid.component.html',
  styleUrls: ['./messages-grid.component.scss']
})
export class MessagesGridComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public messagesList: any = [];

  @Output()
  public updateMsgListSelectedMessageIds = new EventEmitter<any>();

  @Input()
  public isExpanded: boolean;

  @Input()
  public searchText = '';

  @Input()
  public selectedId: string = '';
  @Input()
  public orgLevelId = null;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  unSelectedArr = [];
  @Output() sendUnselectedEmployee = new EventEmitter<any>();
  @Input() isArchive = false;
  @Input() isMyMessage: boolean = true;
  borderLeft = '';
  constructor(
    private messageManService: MessagesManagementService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.subscriptions.id = this.messageManService
        .subscribeToGetMessageId((v) => {
            this.selectedId = v;
            this.scrollToSelectedMsg();
        });  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedId && changes.selectedId.currentValue !== changes.selectedId.previousValue) {
      this.scrollToSelectedMsg();
    }
    if(this.isArchive) {
      this.borderLeft = '5px solid #b9422c';
    } else {
      this.borderLeft = '5px solid #637908';
    }
  }

  public ngOnDestroy(): void { }

  selectionChange(item, event) {
    if(!event) {
    this.unSelectedArr.push(item);
    } else {
     this.unSelectedArr = this.unSelectedArr.filter(x => x.employeeId !== item.employeeId);
    }
    this.sendUnselectedEmployee.emit(this.unSelectedArr);
    this.updateMsgListSelectedMessageIds.emit(item);
  }

  public showMessage(item) {
    const isRead = item.read === true;
    this.selectedId = item.employeeId;

    if (!isRead) {
      this.messagesList
        .filter(x => x.employeeId === this.selectedId)
        .map(y => y.read = true);
    }

    this.messageManService.updateMessageThread(item, this.orgLevelId, !isRead, this.isMyMessage);
  }

  public getDisableStatusOfemplyee(item) {
    if(item.status === 'Terminated' || item.status === 'Transferred' || (item.optIn === 0) || (item.optIn === 2)) {
      return 'disable-color';
    } else {
      return '';
    }
  }

  public scrollToSelectedMsg() {    
    if (!this.elementRef.nativeElement) {
      return;
    }

    setTimeout(() => {
      const selectedMsg = this.elementRef.nativeElement.querySelector(`[data-message-id="${this.selectedId}"]`);
      if (selectedMsg) {
        selectedMsg.scrollIntoView({ behavior: 'smooth' });
        this.messagesList.filter(x => x.id === this.selectedId).map(y => y.read = true);
      }
    }, 100);
  }

  public get searchFormattedNumber() {
    let phoneNumberString=this.searchText;
      if(phoneNumberString) {      
        let formattedNumber="";
          if(phoneNumberString.length>6)
          {
          formattedNumber= '(' + phoneNumberString.substr(0,3) + ') ' + phoneNumberString.substr(3,3) + '-' + phoneNumberString.substr(6);
          }
          else if(phoneNumberString.length>3)
          {
            formattedNumber= '(' + phoneNumberString.substr(0,3) + ') ' + phoneNumberString.substr(3) ;
          }
          else 
          {
            formattedNumber= phoneNumberString;
          }
          return formattedNumber;
      } 
      else 
      {
            return phoneNumberString;
      }     
  }
}