import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { ArrivalsDeparturesAnalytics } from './arrivals-departures-analytics';

export interface IArrival {
  arrivalTime: string;
  shiftId: number;
  scheduled: number;
  in: number;
  difference: number;
  late: number;
}

export class Arrival {
  public arrivalTime: Date;
  public shiftId: number;
  public scheduled: number;
  public in: number;
  public difference: number;
  public late: number;
  public get arrivalTimeTitle(): string {
    if(this.arrivalTime)
      return `${moment(this.arrivalTime).format(appConfig.timeFormat)}`;
    return  ArrivalsDeparturesAnalytics.unscheduled;
  }
}
