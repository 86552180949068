<div class="facility-container" *ngFor="let facility of records">
  <div class="facility-header">
    <span class="facility-title">{{facility.organization?.name}}</span>
    <div class="facility-header-button">
      <slx-collapse-button [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
  </div>
  <div #collapseContent class="facility-content">
    <div class="slx-blue-field">
      <span class="slx-blue-field__title">Organization:</span>
      <div class="slx-blue-field__value">{{facility.organization?.name}}</div>
    </div>
    <div class="facility-content-row">
      <div class="slx-blue-field slx-half">
        <span class="slx-blue-field__title">Submitted Date:</span>
        <div class="slx-blue-field__value">{{facility.submittedDate | amDateFormat: appConfig.dateTimeFormatUS}}</div>
      </div>
      <div class="slx-blue-field slx-half">
        <span class="slx-blue-field__title">Submitted By:</span>
        <div class="slx-blue-field__value">{{facility.submittedBy}}</div>
      </div>
    </div>
    <div class="facility-content-row">
      <div class="slx-blue-field slx-half">
        <span class="slx-blue-field__title">Release Date:</span>
        <div class="slx-blue-field__value">{{facility.releaseDate | amDateFormat: appConfig.dateTimeFormatUS}}</div>
      </div>
      <div class="slx-blue-field slx-half">
        <span class="slx-blue-field__title">Release By:</span>
        <div class="slx-blue-field__value">{{facility.releasedBy}}</div>
      </div>
    </div>
    <div class="facility-content-row">
        <div class="slx-blue-field slx-half">
          <span class="slx-blue-field__title">Export Date:</span>
          <div class="slx-blue-field__value">{{facility.exportDate | amDateFormat: appConfig.dateTimeFormatUS}}</div>
        </div>
      </div>
  </div>
</div>
