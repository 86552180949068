export * from './toolbar-section/toolbar-section.component';
export * from './toolbar.component';

import { ToolbarComponent } from './toolbar.component';
import { ToolbarSectionComponent } from './toolbar-section/toolbar-section.component';

export const toolbarComponents: any[] = [
  ToolbarComponent,
  ToolbarSectionComponent
];
