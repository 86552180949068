import * as moment from 'moment';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';

import { appConfig } from '../../../../app.config';
import { unsubscribe, destroyService, mutableSelect } from '../../../../core/decorators/index';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { TimecardSummaryModel } from '../../../models/index';
import { PayCycle } from '../../../../organization/models/index';
import { OrgLevelType, OrgLevel } from '../../../../state-model/models/index';
import { PayCycleHelperService, OrgLevelWatchService } from '../../../../organization/services/index';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-timecards-summary',
  templateUrl: 'timecards-summary.component.html',
  styleUrls: ['timecards-summary.component.scss'],
  providers: [TimecardsSummaryManagementService]
})
export class TimecardSummaryComponent implements OnInit, OnDestroy {

  public get allowFilters(): boolean {
    return this.management.allowFilters;
  }

  public get showExemptEmployees(): boolean {
    return this.management.showExemptEmployees;
  }

  public state: {
    isLoading: boolean;
    isError: boolean;
  };

  public summary: TimecardSummaryModel;

  @unsubscribe()
  private routeSubscripion: Subscription;

  @unsubscribe()
  private loadStatusSubscription: Subscription;

  @unsubscribe()
  private onSummaryLoadedSubscription: Subscription;

  @destroyService()
  private management: TimecardsSummaryManagementService;

  constructor(private route: ActivatedRoute, management: TimecardsSummaryManagementService,
    private payCycleHelperService: PayCycleHelperService, private orgLevelWatchService: OrgLevelWatchService) {
    this.state = {
      isLoading: false,
      isError: false
    };
    this.management = management;
  }

  public ngOnInit(): void {
    this.routeSubscripion = this.route.params
      .combineLatest(this.route.queryParams)
      .subscribe(async ([params, queryParams]) => {
        let d1: string = queryParams['startDate'];
        let d2: string = queryParams['endDate'];
        let d3: string = queryParams['organization'];
        let d4: string = queryParams['employeeId'];
        let d5: string = queryParams['orgLevelId'];

        let payCycle;
        let employeeId: number;
        let orgLevelId: number;
        let organizationId: number;
        let currentOrglevel: OrgLevel;
        let organizationOrglevel: OrgLevel;

        if (d3 !== undefined) {
          organizationId = parseInt(d3, 10);
          if (d5 !== undefined) {
            currentOrglevel = await this.orgLevelWatchService.getOrgLevelByIdSafe(parseInt(d5, 10));
            orgLevelId = currentOrglevel.id;
          }
        } else if (d4 !== undefined) {
          employeeId = parseInt(d4, 10);
        } else {
          if (!d5) {
            return;
          }
          currentOrglevel = await this.orgLevelWatchService.getOrgLevelByIdSafe(parseInt(d5, 10));
          orgLevelId = currentOrglevel.id;
        }

        if (currentOrglevel) {
          if (currentOrglevel.type === OrgLevelType.organization) {
            organizationId = currentOrglevel.relatedItemId;
            organizationOrglevel = currentOrglevel;
          } else if (currentOrglevel.type === OrgLevelType.department) {
            organizationOrglevel = await this.orgLevelWatchService.getOrgLevelByIdSafe(currentOrglevel.parentId);
            if (_.isNull(organizationOrglevel)) {
              organizationId = currentOrglevel.organizationId;
              organizationOrglevel = currentOrglevel;
            } else {
            organizationId = organizationOrglevel.relatedItemId;
           }
          } else {
            return;
          }
        }


        if (!d1 || !d2) {
          let date = d1 ? moment(d1, appConfig.linkDateFormat).toDate() : new Date();
          payCycle = await this.payCycleHelperService.getLastPayCycleByDate(date, orgLevelId, employeeId);
        } else {
          let startDate = moment(d1, appConfig.linkDateFormat).toDate();
          let endDate = moment(d2, appConfig.linkDateFormat).toDate();
          payCycle = new PayCycle();
          payCycle.startDate = startDate;
          payCycle.endDate = endDate;
        }

        this.management.onPayCycleChanged(payCycle);

        if (organizationId !== undefined) {
          this.management.onOrgLevelChanged(organizationId, organizationOrglevel, currentOrglevel);
        } else if (employeeId !== undefined) {
          this.management.onEmployeeIdChanged(employeeId);
        }

        // if (!this.management.initialized) this.management.initialize();
      });

    this.loadStatusSubscription = this.management.onLoadStatus$
      .subscribe((value: boolean) => {
        this.state.isLoading = value;
      });

    this.onSummaryLoadedSubscription = this.management.onLoaded$.subscribe((summary: TimecardSummaryModel) => {
      this.summary = summary;
      if (!this.summary) this.state.isError = true;
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}
