import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsEssTemplate = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsEssTemplate, _super);
    function EmployeeSectionsEssTemplate() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsEssTemplate;
}(EmployeeSectionsBase));
export { EmployeeSectionsEssTemplate };
