import { Component, OnInit, OnDestroy, Input, Provider, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import {
  RolesContainer,
  IRolesRow,
  RoleProfile,
  RoleRight,
  RolesRightGroupRow,
  RolesProfileRow,
  RolesMenuModuleGroupRow,
  RolesComponentsModuleGroupRow,
  Role,
  RoleColumnsState
} from '../../../models/index';
import { RolesManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-roles-access-table-grid',
  templateUrl: 'roles-access-table-grid.component.html',
  styleUrls: ['roles-access-table-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesAccessTableGridComponent implements OnInit, OnDestroy {

  public container: RolesContainer;
  public appConfig: IApplicationConfig;
  public records: IRolesRow<any>[];
  public gridState: KendoGridStateHelper<IRolesRow<any>>;
  public roleColumnsState: RoleColumnsState;

  private managementService: RolesManagementService;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private onLoadedSubscription: Subscription;
  @unsubscribe()
  private filterSubscription: Subscription;

  private changeDetector: ChangeDetectorRef;
  private gridHeight: number;
  private elementRef: ElementRef;

  constructor(managementService: RolesManagementService, changeDetector: ChangeDetectorRef, elementRef: ElementRef) {
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<IRolesRow<any>>();
    this.roleColumnsState = new RoleColumnsState();
    this.elementRef = elementRef;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(
      (container: RolesContainer) => {
        this.container = container;
        this.records = [];
        this.records = _.concat(this.records, container.profileRows, container.menuModuleGroupRows, container.rightGroupRows, container.otherRightGroupRows);
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
    this.filterSubscription = this.managementService.rolesColumnsStateChanged$
      .subscribe((state: RoleColumnsState) => {
        this.roleColumnsState = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onCellFilterChanged(filter: CompositeFilterDescriptor, item: IRolesRow<any>): void {
    this.managementService.roleRightsFilterChanged(filter, item.type);
  }

  public onRoleProfileToggled(roleId: number, row: RolesProfileRow): void {
    this.managementService.onChangeNotify('onRoleProfileToggled');
  }

  public onRoleRightGroupToggled(roleId: number, row: RolesRightGroupRow,): void {
    row.setNextStatus(roleId);
    this.managementService.onChangeNotify('onRoleRightGroupToggled');
  }

  public onRoleMenuGroupToggled(roleId: number, row: RolesMenuModuleGroupRow): void {
    this.managementService.onChangeNotify('onRoleMenuGroupToggled');
  }

  public onRoleComponentsGroupToggled(roleId: number, row: RolesComponentsModuleGroupRow): void {
    row.setNextStatus(roleId);
    this.managementService.onChangeNotify('onRoleComponentsGroupToggled');
  }

  public roleSectionChanged(roleId: number, row: RolesProfileRow): void {
    row.recalcStatus(roleId);
  }

  public roleRightModuleChanged(roleId: number, row: RolesRightGroupRow): void {
    row.recalcStatus(roleId);
    this.managementService.onChangeNotify('onRoleRightModuleToggled');
  }

  public roleOtherRightModuleChanged(roleId: number, row: RolesRightGroupRow): void {
    row.recalcStatus(roleId);
    this.managementService.onChangeNotify('onRoleOtherRightModuleToggled');
  }

  public roleMenuModuleChanged(roleId: number, row: RolesMenuModuleGroupRow): void {
    row.recalcStatus(roleId);
  }

  public roleComponentsModuleChanged(roleId: number, row: RolesComponentsModuleGroupRow): void {
    row.recalcStatus(roleId);
  }

  public isShowFilter(item: IRolesRow<any>): boolean {
    const descr: string = _.lowerCase(item.description);
    return descr === 'actions' || descr === 'other rights';
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

