import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { PbjReconciliationManagementService } from '../../../services/index';
var PbjReconciliationToolbarComponent = /** @class */ (function () {
    function PbjReconciliationToolbarComponent(managementService, activatedRoute) {
        this.managementService = managementService;
        this.activatedRoute = activatedRoute;
        this.maxRangeError = 'The dates range cannot be more than 145 days';
        this.appConfig = appConfig;
    }
    PbjReconciliationToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscribtionToExpanded = this.managementService.subscribeToExpandedDetails(function (isOn) {
            _this.expanded = isOn;
        });
        if (this.isEmployeeMode) {
            this.subscribeToReconEmployee();
        }
        else {
            this.subscribeToReconOrglevel();
        }
    };
    PbjReconciliationToolbarComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(PbjReconciliationToolbarComponent.prototype, "isEmployeeMode", {
        get: function () {
            return this.type === 'employee';
        },
        enumerable: true,
        configurable: true
    });
    PbjReconciliationToolbarComponent.prototype.onExportToPdf = function () {
        this.managementService.exportToPdf();
    };
    PbjReconciliationToolbarComponent.prototype.onExportToExcel = function () {
        this.managementService.exportToExcel();
    };
    PbjReconciliationToolbarComponent.prototype.onDateRangeChanged = function (dateRange) {
        var sDate = _.get(dateRange, 'startDate');
        var eDate = _.get(dateRange, 'endDate');
        if (_.isDate(sDate) && _.isDate(eDate)) {
            this.managementService.storeDates(sDate, eDate);
            if (this.isEmployeeMode) {
                this.managementService.navigateToReconciliationEmployee(this.employeeId, sDate, eDate);
            }
            else {
                this.managementService.navigateToReconciliation(null, sDate, eDate);
            }
        }
    };
    PbjReconciliationToolbarComponent.prototype.onTogglerChanged = function (isOn) {
        this.managementService.changeExpandedDetails(isOn);
    };
    PbjReconciliationToolbarComponent.prototype.subscribeToReconEmployee = function () {
        var _this = this;
        this.subscribtionToReconEmployee = this.managementService.subscribeToReconEmployee(function (data) {
            _this.startDate = data.startDate;
            _this.endDate = data.endDate;
            _this.maxSecondsRange = data.maxDaysRange;
            _this.employeeId = data.employeeId;
        });
    };
    PbjReconciliationToolbarComponent.prototype.subscribeToReconOrglevel = function () {
        var _this = this;
        this.subscribtionToReconOrglevel = this.managementService.subscribeToReconOrglevel(function (data) {
            _this.startDate = data.startDate;
            _this.endDate = data.endDate;
            _this.maxSecondsRange = data.maxDaysRange;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationToolbarComponent.prototype, "subscribtionToReconEmployee", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationToolbarComponent.prototype, "subscribtionToReconOrglevel", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjReconciliationToolbarComponent.prototype, "subscribtionToExpanded", void 0);
    return PbjReconciliationToolbarComponent;
}());
export { PbjReconciliationToolbarComponent };
