import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { BenefitEmployeesApiService } from '../../../../app-modules/benefits/services/index';
import { LookupService } from '../../../../organization/services/index';
import { LookupType } from '../../../../organization/models/index';
import { EmployeeDependentEnrollments } from '../../models/index';
import { EmployeeSectionsBenefitsCommonService } from './employee-sections-benefits-common.service';
var ChangedDependent = /** @class */ (function () {
    function ChangedDependent(dependent, isCreated, isEdited, isDeleted) {
        if (isCreated === void 0) { isCreated = false; }
        if (isEdited === void 0) { isEdited = false; }
        if (isDeleted === void 0) { isDeleted = false; }
        this.dependent = dependent;
        this.isCreated = isCreated;
        this.isEdited = isEdited;
        this.isDeleted = isDeleted;
    }
    return ChangedDependent;
}());
var ChangedEnrollment = /** @class */ (function () {
    function ChangedEnrollment(enrollment, isCreated, isEdited, isDeleted) {
        if (isCreated === void 0) { isCreated = false; }
        if (isEdited === void 0) { isEdited = false; }
        if (isDeleted === void 0) { isDeleted = false; }
        this.enrollment = enrollment;
        this.isCreated = isCreated;
        this.isEdited = isEdited;
        this.isDeleted = isDeleted;
    }
    return ChangedEnrollment;
}());
var EmployeeSectionsBenefitsManagementService = /** @class */ (function () {
    function EmployeeSectionsBenefitsManagementService(commonManService, benefitsApiService, lookupService) {
        this.commonManService = commonManService;
        this.benefitsApiService = benefitsApiService;
        this.lookupService = lookupService;
        this.subscriptions = {};
    }
    EmployeeSectionsBenefitsManagementService.prototype.init = function () {
        var _this = this;
        this.dependents$ = new ReplaySubject(1);
        this.empId$ = new ReplaySubject(1);
        this.spinner$ = new ReplaySubject(1);
        this.editMode$ = new ReplaySubject(1);
        this.enrollmentsQuantity$ = new ReplaySubject(1);
        this.changedDependent$ = new ReplaySubject(1);
        this.changedEnrollment$ = new ReplaySubject(1);
        this.changedEnrollments$ = new ReplaySubject(1);
        this.genderLookup$ = new ReplaySubject(1);
        this.stateLookup$ = new ReplaySubject(1);
        this.reset$ = new Subject();
        this.dependentEnrolments = new Map();
        this.getGenderLookup();
        this.getStateLookup();
        this.subscriptions.update = this.commonManService.subscribeToUpdateDependents(function () {
            _this.clearDependentsEnrollments(null);
            _this.reset$.next();
        });
        this.subscriptions.spinner = this.commonManService.subscribeToDependentsSpinner(function (isShown) {
            _this.toggleSpinner(isShown);
        });
    };
    EmployeeSectionsBenefitsManagementService.prototype.destroy = function () {
        this.dependents$.complete();
        this.empId$.complete();
        this.spinner$.complete();
        this.editMode$.complete();
        this.enrollmentsQuantity$.complete();
        this.changedDependent$.complete();
        this.changedEnrollment$.complete();
        this.changedEnrollments$.complete();
        this.genderLookup$.complete();
        this.stateLookup$.complete();
        this.reset$.complete();
        this.dependentEnrolments.clear();
        this.employeeId = null;
        this.dependents = null;
        this.dependentBenefit = null;
        this.dependentEnrolments = null;
    };
    EmployeeSectionsBenefitsManagementService.prototype.toggleSpinner = function (isShown) {
        this.spinner$.next(isShown);
    };
    EmployeeSectionsBenefitsManagementService.prototype.toggleEditMode = function (isEdit) {
        this.editMode$.next(isEdit);
    };
    EmployeeSectionsBenefitsManagementService.prototype.changeDependents = function (dep) {
        if (_.isObjectLike(dep)) {
            this.dependents = dep;
            this.dependents$.next(dep);
        }
    };
    EmployeeSectionsBenefitsManagementService.prototype.changeEnrollmentsQuantity = function (id, quantity) {
        if (_.isFinite(id) && _.isFinite(quantity)) {
            this.enrollmentsQuantity$.next({ id: id, quantity: quantity });
        }
    };
    EmployeeSectionsBenefitsManagementService.prototype.changeEmpId = function (empId) {
        if (_.isFinite(empId) && this.employeeId !== empId) {
            this.employeeId = empId;
            this.empId$.next(empId);
        }
    };
    EmployeeSectionsBenefitsManagementService.prototype.loadDependentEnrollments = function (dep) {
        var _this = this;
        if (_.isObjectLike(dep) && _.isFinite(dep.id)) {
            this.toggleSpinner(true);
            var promise = Promise.resolve(this.dependentEnrolments.get(dep.id));
            if (!this.dependentEnrolments.has(dep.id)) {
                promise = this.benefitsApiService.getEmployeeDependentEnrollments(dep.id, this.employeeId);
            }
            promise
                .then(function (e) { return _this.saveDependentEnrollments(dep.id, e); })
                .finally(function () { return _this.toggleSpinner(false); });
        }
    };
    EmployeeSectionsBenefitsManagementService.prototype.clearDependentsEnrollments = function (depIds) {
        var _this = this;
        if (_.isArray(depIds) && _.size(depIds) > 0) {
            _.forEach(depIds, function (id) {
                _this.dependentEnrolments.delete(id);
            });
        }
        else {
            this.dependentEnrolments.clear();
        }
    };
    EmployeeSectionsBenefitsManagementService.prototype.updateDependentsEnrollments = function (dependents) {
        var _this = this;
        if (_.isArray(dependents) && _.size(dependents) > 0) {
            this.toggleSpinner(true);
            this.clearDependentsEnrollments(_.map(dependents, function (dep) { return dep.id; }));
            var promises = _.map(dependents, function (dep) { return _this.benefitsApiService.getEmployeeDependentEnrollments(dep.id, _this.employeeId); });
            Promise.all(promises)
                .then(function (enrollments) {
                _.forEach(enrollments, function (e) { return _this.saveDependentEnrollments(_.head(e).dependentId, e); });
            })
                .finally(function () { return _this.toggleSpinner(false); });
        }
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToSpinner = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.spinner$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToEditMode = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.editMode$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToDependentsSubsection = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dependents$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToEnrollmentsQuantity = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.enrollmentsQuantity$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToEmployeeId = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.empId$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToChangedDependent = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.changedDependent$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToChangedEnrollment = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.changedEnrollment$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToChangedEnrollments = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.changedEnrollments$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToGenderLookup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.genderLookup$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToStateLookup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.stateLookup$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.subscribeToReset = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.reset$.subscribe(callback);
    };
    EmployeeSectionsBenefitsManagementService.prototype.createDependent = function (dataItem) {
        var _this = this;
        this.toggleSpinner(true);
        this.benefitsApiService.createEmployeeDependent(dataItem)
            .then(function (dep) { return _this.changedDependent$.next(new ChangedDependent(dep, true)); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.editDependent = function (dataItem) {
        var _this = this;
        this.toggleSpinner(true);
        this.benefitsApiService.editEmployeeDependent(dataItem)
            .then(function (dep) { return _this.changedDependent$.next(new ChangedDependent(dep, false, true)); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.deleteDependent = function (dataItem) {
        var _this = this;
        this.toggleSpinner(true);
        this.benefitsApiService.deleteEmployeeDependent(dataItem.id)
            .then(function () { return _this.changedDependent$.next(new ChangedDependent(dataItem, false, false, true)); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.addEnrollment = function (enroll) {
        var _this = this;
        this.toggleSpinner(true);
        this.benefitsApiService.addDependentEnrollment(enroll)
            .then(function (enr) { return _this.changedEnrollment$.next(new ChangedEnrollment(enr, true)); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.editEnrollment = function (enroll) {
        var _this = this;
        this.toggleSpinner(true);
        this.benefitsApiService.editDependentEnrollment(enroll)
            .then(function (enr) { return _this.changedEnrollment$.next(new ChangedEnrollment(enr, false, true)); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.deleteEnrollment = function (enroll) {
        var _this = this;
        this.toggleSpinner(true);
        this.benefitsApiService.deleteDependentEnrollment(enroll.id)
            .then(function () { return _this.changedEnrollment$.next(new ChangedEnrollment(enroll, false, false, true)); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.dropDependentEnrollment = function (enrollment) {
        var _this = this;
        this.toggleSpinner(true);
        return this.benefitsApiService.dropDependentEnrollment(enrollment)
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.getGenderLookup = function () {
        var _this = this;
        this.toggleSpinner(true);
        this.lookupService.getLookup({ lookupType: LookupType.empGender, employeeId: 0 })
            .then(function (lookup) { return _this.genderLookup$.next(lookup); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.getStateLookup = function () {
        var _this = this;
        this.toggleSpinner(true);
        this.lookupService.getLookup({ lookupType: LookupType.state })
            .then(function (lookup) { return _this.stateLookup$.next(lookup); })
            .finally(function () { return _this.toggleSpinner(false); });
    };
    EmployeeSectionsBenefitsManagementService.prototype.getBenefitTerminationReasons = function () {
        return this.benefitsApiService.getBenefitTerminationReasons();
    };
    EmployeeSectionsBenefitsManagementService.prototype.saveDependentEnrollments = function (depId, enrollments) {
        this.dependentEnrolments.set(depId, enrollments);
        this.changedEnrollments$.next(new EmployeeDependentEnrollments(depId, enrollments));
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsBenefitsManagementService.prototype, "subscriptions", void 0);
    return EmployeeSectionsBenefitsManagementService;
}());
export { EmployeeSectionsBenefitsManagementService };
