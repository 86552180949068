import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { unsubscribe, unsubscribeAll } from '../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { ColumnManagementService, StateManagementService } from '../../../common';
import { AcaMeasurementManagementService } from '../../services/aca-measurement/aca-measurement-management.service';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-measurement',
  templateUrl: 'aca-measurement.component.html',
  styleUrls: ['aca-measurement.component.scss'],
  providers: [ColumnManagementService, StateManagementService, AcaMeasurementManagementService]
})
export class AcaMeasurementComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  private readonly componentId = 'AcaMeasurementComponent';
  private readonly groupId = 'AcaMeasurementGrid';
 

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private acaManagementService: AcaMeasurementManagementService,
    private stateManagementService: StateManagementService,
  ) {

  }

  public ngOnInit() {

    this.stateManagementService.init(this.componentId, true);
    this.acaManagementService.init();

    this.subscriptions.loading = this.acaManagementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }
}
