import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-header-notification-popper-content',
    templateUrl: 'header-notification-popper-content.component.html',
    styleUrls: ['header-notification-popper-content.component.scss']
})

export class HeaderNotificaitonPopperContentComponent {
}
