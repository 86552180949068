import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../../app.config';
import { EmployeeWarningSections, EmployeeWarningExtended, EmployeeWarningSectionViolationsAB } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EmployeeSectionsWarningsViolationsABComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsViolationsABComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.violationTypes = _.map(['Part A violation', 'Part B violation'], function (t, i) { return ({ id: i, name: t, isPartAType: i === 0 }); });
    }
    EmployeeSectionsWarningsViolationsABComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.violations = this.manService.getViolationsABSection();
        this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(function (warning) {
            _this.assignViolations(warning);
            _this.assignViolationsData();
            _this.updateView();
        });
        this.subscriptions.statusChanges = this.form.statusChanges.subscribe(function () {
            _this.manService.changeSectionValidity(EmployeeWarningSections.CustomViolationsAB, _this.form.valid);
        });
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.onChangeViolationNumber = function () {
        this.violations.data.violationNumber = this.violationNumber;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.onChangeViolationType = function () {
        this.violations.data.isPartAType = this.selectedViolationType.isPartAType;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.onChangeViolationAbsence = function () {
        this.violations.data.violationAbsence = this.violationAbsence;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.onChangeViolationTardiness = function () {
        this.violations.data.violationTardiness = this.violationTardiness;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.onChangeViolationDate = function () {
        this.violations.data.violationDate = this.violationDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.updateView = function () {
        var _this = this;
        Observable.of(true)
            .delay(200)
            .subscribe(function () { return _this.changeDetector.detectChanges(); });
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.assignViolations = function (warning) {
        var violations = _.find(warning.sections, function (s) { return s.isCustomViolationsAB; });
        if (_.isObjectLike(violations) && _.isObjectLike(violations.data)) {
            this.violations = violations;
        }
    };
    EmployeeSectionsWarningsViolationsABComponent.prototype.assignViolationsData = function () {
        var _this = this;
        this.violationNumber = this.violations.data.violationNumber;
        this.isPartAType = this.violations.data.isPartAType;
        this.violationAbsence = this.violations.data.violationAbsence;
        this.violationTardiness = this.violations.data.violationTardiness;
        this.violationDate = this.violations.data.violationDate;
        this.selectedViolationType = _.find(this.violationTypes, function (t) { return _this.isPartAType === t.isPartAType; });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsWarningsViolationsABComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsWarningsViolationsABComponent;
}());
export { EmployeeSectionsWarningsViolationsABComponent };
