import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { IAppSetting } from './../../../app-settings/model/app-setting';
import { IUserPasswordSettings, UserPasswordSettings, UserPasswordComplexity, UserPasswordLifetime } from '../../../core/models/index';

@Injectable()
export class UserPasswordSettingsMapService {

    public mapSettings(dto: IAppSetting[]): UserPasswordSettings {

        let settings: UserPasswordSettings = new UserPasswordSettings();
        let c: UserPasswordComplexity = new UserPasswordComplexity();
        let lt: UserPasswordLifetime = new UserPasswordLifetime();
        
        settings.userPasswordComplexity = c;
        settings.userPasswordLifetime = lt;

        const dict: any = _.mapKeys(dto, (setting: IAppSetting) => {
            return setting.key;
        });

        c.capitalCount = +dict['capitalCount'].value;
        c.pwdLength = +dict['passwordLength'].value;
        c.specialCount = +dict['specialCount'].value;
        lt.daysCount = +dict['daysCount'].value;
        lt.enabled = dict['enabled'].value === 'true';
        
        return settings;
    }

    public mapUserPasswordSettings(dto: IUserPasswordSettings): UserPasswordSettings {

        let settings: UserPasswordSettings = new UserPasswordSettings();
        let c: UserPasswordComplexity = new UserPasswordComplexity();
        let lt: UserPasswordLifetime = new UserPasswordLifetime();
        
        settings.userPasswordComplexity = c;
        settings.userPasswordLifetime = lt;

        c.capitalCount = dto.userPasswordComplexity.capitalCount;
        c.specialCount = dto.userPasswordComplexity.specialCount;
        c.pwdLength = dto.userPasswordComplexity.pwdLength;

        lt.daysCount = dto.userPasswordLifetime.daysCount;
        lt.enabled = dto.userPasswordLifetime.enabled;

        return settings;
    }
}
