import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'slx-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnChanges, AfterViewInit  {

  @Input()
  public data: Array<any>;

  @Input()
  public disabled: boolean;

  @Input()
  public icon: string;

  @Input()
  public textField: string;

  @Input()
  public title: string;

  @Input()
  public valuePrimitive: boolean;

  @ViewChild('dropdownBtn', {static: true})
  private dropdownBtn: ElementRef;

  @Output()
  public onItemClick: EventEmitter<any> = new EventEmitter<any>();

  public dropdownItemMinWidth: number;

  constructor() {
    this.textField = 'name';
    this.data = [];
    this.dropdownItemMinWidth = 50;
    this.onItemClick = new EventEmitter<any>();
  }

  public ngAfterViewInit() {
    this.updateDropdownItemWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    const isDataNullOrEmpty = !this.data || (this.data && !this.data.length);

    if (changes['data'] && isDataNullOrEmpty) {
      this.data = [];
      const noDataItem = { name: 'No data found', disabled: true };
      this.data.push(noDataItem);
    }

    if(changes['title']) {
      this.updateDropdownItemWidth();
    }
  }

  public handleClick(dataItem: any): void {
    this.onItemClick.next(dataItem);
  }

  private updateDropdownItemWidth() {
    const widthOfPaddingAndScroll = 25;
    this.dropdownItemMinWidth = this.dropdownBtn.nativeElement.offsetWidth - widthOfPaddingAndScroll;
  }
}
