export * from './employee-sections-rotations/employee-sections-rotations.component';
export * from './employee-rotations/employee-rotations.component';
export * from './employee-sections-rotations-settings/employee-sections-rotations-settings.component';
export * from './employee-sections-availability/employee-sections-availability.component';
export * from './rotation-modify-dialog/rotation-modify-dialog.component';

import { EmployeeSectionsRotationsComponent } from './employee-sections-rotations/employee-sections-rotations.component';
import { EmployeeRotationsComponent } from './employee-rotations/employee-rotations.component';
import { EmployeeSectionsRotationsSettingsComponent } from './employee-sections-rotations-settings/employee-sections-rotations-settings.component';
import { EmployeeSectionsAvailabilityComponent } from './employee-sections-availability/employee-sections-availability.component';
import { RotationModifyDialogComponent } from './rotation-modify-dialog/rotation-modify-dialog.component';

export const componentsScheduleSection: any[] = [
  EmployeeSectionsRotationsComponent,
  EmployeeRotationsComponent,
  EmployeeSectionsRotationsSettingsComponent,
  EmployeeSectionsAvailabilityComponent,
  RotationModifyDialogComponent
];
