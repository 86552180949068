import { EmployeePositionDefinition } from '../../../organization/models/index';

export class UnassignedEmployee {

    public get employeeName(): string {
        return this.employee ? this.employee.employee.name : '';
    }

    public get centerName(): string {
        if (this.employee && this.employee.positionDepartment) {
            return this.employee.positionDepartment.name;
        }
        return '';
    }

    public get positionName(): string {
        if (this.employee && this.employee.position) {
            return this.employee.position.name;
        }
        return '';
    }

    public employee: EmployeePositionDefinition;
}
