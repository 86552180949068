import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { IColumnSettings } from '../../../../core/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { DateRange, IDateRange } from '../../../../core/models/index';
import { ColumnSettingsStorageService, ColumnManagementService, FileService } from '../../../../common/services/index';

import { WcIncidentRosterState, WcIncidentRosterContainer, WcViewList, WcViewItem, WcIncidentRosterEntry } from '../../models/index';
import { WcRosterToolbarService } from './wc-roster-toolbar.service';
import { WcRosterApiService } from './wc-roster-api.service';

@Injectable()
export class WcCommonManagementService {
  private componentId: string;
  private columnsVisibilityKey: string;
  private currentViewMode: WcViewItem;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private dateRange: DateRange;
  private incidentsAmount: number;

  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private exportTo$ = new Subject<boolean>();
  private state$ = new ReplaySubject<WcIncidentRosterState>(1);
  private loading$ = new ReplaySubject<boolean>(1);
  private recordsLoaded$ = new ReplaySubject<WcIncidentRosterContainer>(1);
  private dateRange$ = new Subject<DateRange>();

  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private toolbarService: WcRosterToolbarService,
    private apiService: WcRosterApiService,
    private columnSettingsStorageService: ColumnSettingsStorageService,
    private columnManagementService: ColumnManagementService,
  ) { }

  public init(componentId: string, columnsVisibilityKey: string, viewMode: WcViewList): void {
    this.componentId = componentId;
    this.columnsVisibilityKey = columnsVisibilityKey;
    this.currentViewMode = new WcViewItem(viewMode, '', '');
  }

  public destroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};

    this.orgLevelChanged$.complete();
    this.exportTo$.complete();
    this.state$.complete();
    this.loading$.complete();
    this.recordsLoaded$.complete();
    this.dateRange$.complete();
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToExport(callback: (isPDF: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.exportTo$.subscribe(callback);
  }

  public subscribeToChangeState(callback: (s: WcIncidentRosterState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.state$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (v: WcIncidentRosterContainer) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.recordsLoaded$.subscribe(callback);
  }

  public openEditReportDialog(reportId: number): void {
    this.toolbarService.openEditReportDialog(reportId);
  }

  public subscribeToToolbarService(): void {

    this.subscriptions.dateRange = this.toolbarService
      .subscribeToDateRange((r: IDateRange) => {
        this.dateRange = r;
        this.loadIncidents();
        this.dateRange$.next(r);
      });

    this.subscriptions.export = this.toolbarService
      .subscribeToExport((isPDF: boolean) => this.exportTo$.next(isPDF));

    this.subscriptions.saveState = this.toolbarService
      .subscribeToSaveState((s: WcIncidentRosterState) => {
        this.columnSettingsStorageService.setColumnsState(this.componentId, this.columnsVisibilityKey, s.columns);
        this.state$.next(s);
      });

    this.subscriptions.defineState = this.toolbarService
      .subscribeToDefineState((s: WcIncidentRosterState) => {
        this.state$.next(s);
      });
  }

  public subscribeToIncidentsAmount(): void {
    this.subscriptions.incidents = this.toolbarService
      .subscribeToIncidentsAmount((i: number) => {
        this.incidentsAmount = i;
        this.loadIncidents();
      });
  }

  public subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe(async (orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;

        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        this.loadIncidents();
      });
  }

  public loadIncidents(): void {
    if (this.currentViewMode.isOshaBudgetTracker) {
      this.loadOshaIncidents();
    } else {
      this.loadRosterIncidents();
    }
  }

  public loadRosterIncidents(): void {
    if (
      !_.isFinite(_.get(this.orgLevel, 'id'))
      || !_.isDate(_.get(this.dateRange, 'startDate'))
      || !_.isDate(_.get(this.dateRange, 'endDate'))
      || this.currentViewMode.isRepeatInjuryList && !_.isFinite(this.incidentsAmount)
    ) return;

    this.loading$.next(true);
    this.apiService.getIncidentRoster(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate, this.incidentsAmount)
      .then((container: WcIncidentRosterContainer) => {
        this.recordsLoaded$.next(container);
        this.loading$.next(false);
        this.toolbarService.permissionsChanged(container.canCreate);
      });
  }

  public loadOshaIncidents(): void {
    if (
      !_.isFinite(_.get(this.orgLevel, 'id'))
      || !_.isDate(_.get(this.dateRange, 'startDate'))
      || !_.isDate(_.get(this.dateRange, 'endDate'))
    ) return;

    this.loading$.next(true);
    this.apiService.getOshaIncidentRoster(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
      .then((container: WcIncidentRosterContainer) => {
        this.recordsLoaded$.next(container);
        this.loading$.next(false);
        this.toolbarService.permissionsChanged(container.canCreate);
      });
  }

  public restoreState(state: { columns: IColumnSettings[], columnsMap: StringMap<IColumnSettings> }): void {
    this.columnManagementService.init(this.componentId);
    this.columnManagementService.initializeGroupWithColumns(this.columnsVisibilityKey, state.columns);
    
    this.subscriptions.state = this.columnManagementService.groupInitialized$
      .filter((event) => event.group === this.columnsVisibilityKey)
      .subscribe(() => {
        this.toolbarService.defineState(state);
      });
  }

  public setLoading(v: boolean): void {
    this.loading$.next(v);
  }



}
