import { Component, Output, EventEmitter, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { LookupApiService } from '../../../organization/services/index';
import { PayCycle } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pay-cycle-dropdown-selector',
  templateUrl: 'pay-cycle-dropdown-selector.component.html',
  styleUrls: ['pay-cycle-dropdown-selector.component.scss']
})
export class PayCycleDropdownSelectorComponent implements OnChanges, OnDestroy {
  @Input()
  public selectedPayCycle: PayCycle;
  @Input()
  public employeeId: number;
  @Output()
  public payCycleSelected: EventEmitter<PayCycle>;
  @Output()
  public payCyclesLoaded: EventEmitter<PayCycle[]>;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public payCycles: PayCycle[];
  public yearPayCycles: StringMap<PayCycle[]>;
  public years: string[];
  public selectedYear: string;

  public appConfig: IApplicationConfig;
  public state: {
    isLoading: boolean;
  };
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  private lookupApiService: LookupApiService;
  private selectedOrgLevel: OrgLevel;

  constructor(lookupApiService: LookupApiService) {
    this.lookupApiService = lookupApiService;
    this.payCycleSelected = new EventEmitter<PayCycle>();
    this.payCyclesLoaded = new EventEmitter<PayCycle[]>();

    this.state = {
      isLoading: false
    };
    this.yearPayCycles = {};
    this.appConfig = appConfig;
    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        if (!o || !o.id) {
          return;
        }
        this.selectedOrgLevel = o;
        if (this.selectedOrgLevel) {
          this.loadPayCycles(this.selectedOrgLevel.id, this.employeeId);
        }
      });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['employeeId']) {
      this.loadPayCycles(this.selectedOrgLevel.id, this.employeeId);
    }
  }

  public SelectPayCycle(itemToSelect: PayCycle, riseEvent: boolean): void {
    if (this.payCycles && this.payCycles.length > 0) {
      if (itemToSelect) {
        this.selectedPayCycle = _.find(this.payCycles, (item: PayCycle) => {
          return moment(item.startDate).isSame(moment(itemToSelect.startDate)) && moment(item.endDate).isSame(moment(itemToSelect.endDate));
        });
      }
      if (!this.selectedPayCycle) {
        this.selectedPayCycle = this.payCycles[0];
      }
      if (this.selectedPayCycle) {
        this.selectedYear = moment(this.selectedPayCycle.startDate).year().toString();
      } else {
        this.selectedYear = '';
      }
      if (riseEvent) {
        this.onPayCycleSelect(this.selectedPayCycle);
      }
      return;
    }
    this.selectedPayCycle = itemToSelect;
    this.selectedYear = '';
  }

  public loadPayCycles(orgLevelId: number, employeeId: number): void {
    this.lookupApiService.getPayCyles(orgLevelId, employeeId)
      .then((cycles: PayCycle[]) => {
        this.payCycles = _.reverse(_.sortBy(cycles, (item: PayCycle) => { return moment(item.startDate).unix(); }));
        this.yearPayCycles = _.groupBy(this.payCycles, (cycle: PayCycle) => {
          return moment(cycle.startDate).year();
        });
        this.years = _.reverse(_.keys(this.yearPayCycles));
        if (this.payCycles.length === 0) {
          this.selectedPayCycle = null;
        }
        this.SelectPayCycle(this.selectedPayCycle, true);
        this.payCyclesLoaded.emit(cycles);
      });
  }

  public onSelectYear(year: string): void {
    this.selectedYear = year;
    this.selectedPayCycle = null;
  }

  public onPayCycleSelect(item: PayCycle): void {
    this.selectedPayCycle = item;
    this.payCycleSelected.emit(this.selectedPayCycle);
  }

  public isSelected(item: PayCycle): boolean {
    const selectedStartDate: Date = _.get(this.selectedPayCycle, 'startDate', null);
    const selectedEndDate: Date = _.get(this.selectedPayCycle, 'endDate', null);
    const itemStartDate: Date = _.get(item, 'startDate', null);
    const itemEndDate: Date = _.get(item, 'endDate', null);

    if (
      _.isNull(selectedStartDate) ||
      _.isNull(selectedEndDate) ||
      _.isNull(itemStartDate) ||
      _.isNull(itemEndDate)
    ) {
      return false;
    }

    return moment(itemStartDate).isSame(selectedStartDate) && moment(itemEndDate).isSame(selectedEndDate);
  }

  public selectPayCycleByDate(date: Date, riseEvent: boolean): void {
    if (this.payCycles && this.payCycles.length > 0) {
      if (date) {
        this.selectedPayCycle = _.find(this.payCycles, (item: PayCycle) => {
          return moment(item.startDate).isSameOrBefore(moment(date)) && moment(item.endDate).isSameOrAfter(moment(date));
        });
      }
      if (!this.selectedPayCycle) {
        this.selectedPayCycle = this.payCycles[0];
      }
      if (this.selectedPayCycle) {
        this.selectedYear = moment(this.selectedPayCycle.startDate).year().toString();
      } else {
        this.selectedYear = '';
      }
      if (riseEvent) {
        this.onPayCycleSelect(this.selectedPayCycle);
      }
      return;
    }
    this.selectedYear = '';
  }

  public selectPayCycleByDates(startDate: Date, endDate: Date, riseEvent: boolean): void {
    if (this.payCycles && this.payCycles.length > 0) {
      if (startDate && endDate) {
        this.selectedPayCycle = _.find(this.payCycles, (item: PayCycle) => {
          return moment(item.startDate).isSame(moment(startDate)) && moment(item.endDate).isSame(moment(endDate));
        });
      }
      if (!this.selectedPayCycle) {
        this.selectedPayCycle = this.payCycles[0];
      }
      if (this.selectedPayCycle) {
        this.selectedYear = moment(this.selectedPayCycle.startDate).year().toString();
      } else {
        this.selectedYear = '';
      }
      if (riseEvent) {
        this.onPayCycleSelect(this.selectedPayCycle);
      }
      return;
    }
    this.selectedYear = '';
  }
}
