import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { SidebarActions } from '../../actions/index';
import { scheduleMicrotask } from '../../../core/utils/index';
var HeaderLeftSidebarButtonComponent = /** @class */ (function () {
    function HeaderLeftSidebarButtonComponent(sidebarActions) {
        this.sidebarActions = sidebarActions;
        this.state = {
            isLeftSidebarOpen: false
        };
    }
    HeaderLeftSidebarButtonComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe(function (value) {
            scheduleMicrotask(function () { _this.state.isLeftSidebarOpen = value; });
        });
    };
    HeaderLeftSidebarButtonComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    HeaderLeftSidebarButtonComponent.prototype.toggleLeftSidebar = function () {
        this.sidebarActions.setLeftSidebar(!this.state.isLeftSidebarOpen);
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], HeaderLeftSidebarButtonComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderLeftSidebarButtonComponent.prototype, "isLeftSidebarOpenSubscripion", void 0);
    return HeaderLeftSidebarButtonComponent;
}());
export { HeaderLeftSidebarButtonComponent };
