/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-call-log.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../common/directives/prevent-default-link/prevent-default-link.directive";
import * as i3 from "../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i4 from "../../../../../common/components/spinner/spinner.component";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component.ngfactory";
import * as i7 from "../../../../../common/components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component";
import * as i8 from "../employee-call-log-grid/employee-call-log-grid.component.ngfactory";
import * as i9 from "../employee-call-log-grid/employee-call-log-grid.component";
import * as i10 from "../../../services/employee-call-log/employee-call-log-management.service";
import * as i11 from "../../../services/employee-call-log/employee-call-log-api.service";
import * as i12 from "../../../../../organization/services/generic-list/generic-list-api.service";
import * as i13 from "../../../../employee-list/services/employee-list/employee-list-map.service";
import * as i14 from "../../../../../portal/services/notifications/notifications-api.service";
import * as i15 from "./employee-call-log.component";
import * as i16 from "../../../models/employee-sections-context";
var styles_EmployeeCallLogComponent = [i0.styles];
var RenderType_EmployeeCallLogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeCallLogComponent, data: {} });
export { RenderType_EmployeeCallLogComponent as RenderType_EmployeeCallLogComponent };
export function View_EmployeeCallLogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "k-window-titlebar k-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "k-window-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Call Log"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "k-window-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["aria-label", "Close"], ["class", "k-button k-bare k-button-icon k-window-action"], ["href", "#"], ["role", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onCloseClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.PreventDefaultLinkDirective, [], { href: [0, "href"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "k-icon k-i-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 13, "slx-spinner", [], null, null, null, i3.View_SpinnerComponent_0, i3.RenderType_SpinnerComponent)), i1.ɵdid(8, 49152, null, 0, i4.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(9, 0, null, 0, 11, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(12, 4210688, null, 0, i5.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i1.ɵdid(14, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "employee-list-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "slx-date-range-ngx", [["name", "startEndDate"]], null, [[null, "rangeDateChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("rangeDateChanged" === en)) {
        var pd_0 = (_co.onDataRangeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DateRangeNgxComponent_0, i6.RenderType_DateRangeNgxComponent)), i1.ɵdid(17, 573440, null, 0, i7.DateRangeNgxComponent, [], { startDate: [0, "startDate"], endDate: [1, "endDate"] }, { rangeDateChanged: "rangeDateChanged" }), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "slx-high-box__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "slx-employee-call-log-grid", [["class", "height-100"]], null, null, null, i8.View_EmployeeCallLogGridComponent_0, i8.RenderType_EmployeeCallLogGridComponent)), i1.ɵdid(20, 245760, null, 0, i9.EmployeeCallLogGridComponent, [i10.EmployeeCallLogManagementService], { pageSize: [0, "pageSize"], employeeLink: [1, "employeeLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "#"; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.state.isLoading; _ck(_v, 8, 0, currVal_1); var currVal_9 = _co.state.startDate; var currVal_10 = _co.state.endDate; _ck(_v, 17, 0, currVal_9, currVal_10); var currVal_11 = _co.pageSize; var currVal_12 = _co.employeeLink; _ck(_v, 20, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 14).ngClassValid; var currVal_7 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_EmployeeCallLogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-employee-call-log", [], null, null, null, View_EmployeeCallLogComponent_0, RenderType_EmployeeCallLogComponent)), i1.ɵprd(512, null, i10.EmployeeCallLogManagementService, i10.EmployeeCallLogManagementService, [i11.EmployeeCallLogApiService, i12.GenericListApiService, i13.EmployeeListMapService, i14.NotificationsApiService]), i1.ɵdid(2, 245760, null, 0, i15.EmployeeCallLogComponent, [i16.EmployeeSectionsContext, i10.EmployeeCallLogManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var EmployeeCallLogComponentNgFactory = i1.ɵccf("slx-employee-call-log", i15.EmployeeCallLogComponent, View_EmployeeCallLogComponent_Host_0, {}, { onClose: "onClose" }, []);
export { EmployeeCallLogComponentNgFactory as EmployeeCallLogComponentNgFactory };
