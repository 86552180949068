import * as _ from 'lodash';
import { Component, OnInit, Provider, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DialogOptions, IDialog, DialogOptions2, DialogModeSize } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { DashBoardAlertsModel } from '../../../../portal/models/index';

@Component({
  selector: 'slx-dashboard-alerts-select-popup-dialog',
  templateUrl: './dashboard-alerts-select-popup-dialog.component.html',
  styleUrls: ['./dashboard-alerts-select-popup-dialog.component.scss']
})
export class DashboardAlertsSelectPopupDialogComponent implements IDialog {
  public dialogResult: boolean = false;
  public isLoading: boolean = false;

  private options: DialogOptions;
  private modalService: ModalService;
  public modelData: DashBoardAlertsModel;

  public static initiateDialog(_modelData: DashBoardAlertsModel, modalService: ModalService, 
    callback?: (result: boolean) => void): DashboardAlertsSelectPopupDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
          provide: DashBoardAlertsModel,
          useValue: _modelData
      }
    ];

    return modalService.globalAnchor.openDialog2(
      DashboardAlertsSelectPopupDialogComponent,
      `${_modelData.appTitle} Dashboard`,
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor (
    options: DialogOptions,
    modalService: ModalService,
    _modelData: DashBoardAlertsModel
  ) {
    this.options = options;
    this.modalService = modalService;
    this.modelData = _modelData;
  }

  public onLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  public onHasChanges(hasChanges: boolean): void {
    this.dialogResult = hasChanges;
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
