import { DetailGroupViewSettingsTypes } from './../../../scheduler/models/index';
import { ScheduleEntryNavigationService } from './schedule-entry-navigation.service';
import { Router, ActivatedRoute, UrlTree, NavigationExtras, Params } from '@angular/router';
import { appConfig, IApplicationConfig } from '../../../app.config';
import * as moment from 'moment';

export class DailyUnitAssignmentNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToDailyUnitAssignment(dateOn: Date, positionId: number, viewMode: string, positionGroupId?: number, unitId?: number, shiftId?: number): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false
    };
    this.router.navigateByUrl(this.getUrlTree(dateOn, positionId, viewMode, positionGroupId, unitId, shiftId), extras);
  }

  public navigateToCensusEditor(dateOn: Date): void {
    let params: Params = {
      date: moment(dateOn).format(appConfig.linkDateFormat)
    };
    this.router.navigate(['daily_census'], { relativeTo: this.route.parent, queryParams: params });
  }

  public navigateToMasterScheduleOpenShifts(dateOn: Date, empId: string): void {
    let service: ScheduleEntryNavigationService = new ScheduleEntryNavigationService(this.router, this.route);
    service.NavigateToScheduleEntry(+empId, dateOn);
  }

  public navigateToDailyUnitAssignmentDate(dateOn: Date, positionIds?: number[], viewMode?: string, resetFilters?: boolean, unitIds?: number[], shiftIds?: number[]): void {
    let params: any = {
      keepOrgLevelBreadcrmb: true
    };
    if (positionIds && positionIds.length > 0) {
      params.positionId = positionIds.join(',');
    }
    if (unitIds && unitIds.length > 0) {
      params.unitId = unitIds.join(',');
    }
    if (shiftIds && shiftIds.length > 0) {
      params.shiftIds = shiftIds.join(',');
    }
    if (viewMode && viewMode in DetailGroupViewSettingsTypes) {
      params.view_mode = viewMode;
    }
    if (resetFilters) {
      params.resetState = true;
    }

    this.router.navigate([
      'daily_unit_assignment',
      moment(dateOn).format(appConfig.linkDateFormat)], {
        relativeTo: this.route.pathFromRoot[2],
        queryParams: params
      });
  }


  private getUrlTree(dateOn: Date, positionId: number, viewMode: string, positionGroupId?: number, unitId?: number, shiftId?: number): UrlTree {
    let params: any = {
      keepOrgLevelBreadcrmb: true,
      positionId: positionId,
    };
    if (positionGroupId) params.positionGroupId = positionGroupId;
    if (unitId) params.unitId = unitId;
    if (shiftId) params.shiftId = shiftId;

    if (viewMode && viewMode in DetailGroupViewSettingsTypes) {
      params.view_mode = viewMode;
    }
    return this.router.createUrlTree(['scheduler', 'daily_unit_assignment',
      moment(dateOn).format(appConfig.linkDateFormat)],
      {
        relativeTo: this.route.pathFromRoot[1],
        queryParams: params
      });
  }
}
