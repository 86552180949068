import { Component, Input, OnInit, OnChanges, EventEmitter, OnDestroy, SimpleChanges, Output, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { PayUnitsMassAssignmentApiService, PayUnitsMassAssignmentMapService } from '../../services/index';
import { MassAssignPayUnitsRequest, MassAssignPayUnits, EmpPayUnitsInfo, EmpPayUnit, EmpTimecardPayUnit } from '../../models/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs';
import { KendoGridStateHelper, DialogOptions } from '../../../../common/models/index';
import { process, State } from '@progress/kendo-data-query';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, LookupType, Position, PayUnitDefinition, isNilPayUnit } from '../../../../organization/models/index';
import { NotificationsService } from '../../../../core/components';
import { ModalService,  } from '../../../../common/services/index';
import { ModalAnchorDirective } from '../../../../common/index';


@Component({
  moduleId: module.id,
  selector: 'slx-mass-assignment-payunits',
  templateUrl: 'mass-assignment-payunits.component.html',
  styleUrls: ['mass-assignment-payunits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MassAssignmentPayUnitsComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public request: MassAssignPayUnitsRequest;
  @Output()
  public onClose: EventEmitter<boolean> = new EventEmitter();

  public isLoading: boolean;
  public data: MassAssignPayUnits;
  public selectedDate: Date;
  public dateKey: string;
  public gridState: KendoGridStateHelper<EmpPayUnitsInfo>;
  public appConfig: IApplicationConfig;
  public payunitsLookup: NumberMap<Lookup>;
  public rowCallback: any;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @ViewChild(ModalAnchorDirective, { static: true })
  private modalAnchor: ModalAnchorDirective;

  constructor(
    private apiService: PayUnitsMassAssignmentApiService,
    private mapService: PayUnitsMassAssignmentMapService,
    private lookupService: LookupService,
    private changeDetector: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private modalService: ModalService
  ) {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<EmpPayUnitsInfo>();
    this.gridState.state.sort = [{ field: 'employeeName', dir: 'asc' }];
    this.rowCallback = (context: RowClassArgs) => this.rowClassFnc(context);
  }

  public ngOnInit() {
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });
  }

  public ngOnDestroy() { }

  public async ngOnChanges(changes: SimpleChanges): Promise<any> {
    if (this.request) {
      this.load();
      this.lookupService.getLookup({ lookupType: LookupType.payUnits, orgLevelId: this.request.orgLevelId, isActive: true })
        .then((lookup: Lookup) => {
          this.payunitsLookup = {};
          const items: PayUnitDefinition[] = lookup.items;
          const dict = _.groupBy(items, (item) => item.departmentId);
          if (!dict[0]) {
            dict[0] = [];
          }
          const orgItems = _.map(dict[0]);
          _.forIn(dict, (value, key) => {
            const depLookup = new Lookup();
            depLookup.titleField = lookup.titleField;
            depLookup.valueField = lookup.valueField;
            depLookup.items = value;
            if (key !== '0') {
              depLookup.items.push(...orgItems);
            }
            depLookup.items = _.orderBy(depLookup.items, (item) => item.name);
            depLookup.items.unshift({ id: 0, name: '' });
            this.payunitsLookup[+key] = depLookup;
          })
        });
    }
  }

  public async load(): Promise<any> {
    this.isLoading = true;
    this.apiService.getMassAssignPayUnits(this.request.orgLevelId, this.request.startDate, this.request.endDate, this.request.employeeIds)
      .then((res) => {
        this.data = res;
        this.selectedDate = this.request.startDate;
        this.changeDate();
        this.refreshGrid();
      }).finally(() => {
        this.isLoading = false;
        setTimeout(() => {
          this.detectChanges();
        }, 10);
      });
  }

  public rowClassFnc(context: RowClassArgs) {
    if (!context.dataItem || !context.dataItem.dayTimecard || !context.dataItem.dayTimecard[this.dateKey]) {
      return null;
    }
    const timecard = context.dataItem.dayTimecard[this.dateKey];
    const isLock = timecard.lockDay;
    const hasError = this.isTimecardError(timecard);
    return {
      'slx-non-editable': isLock,
      'slx-error-row': hasError
    };
  }

  public isTimecardError(timecard: EmpTimecardPayUnit): boolean {
    return (!!timecard.payUnits[0].payUnit && !timecard.payUnits[0].units) || (!!timecard.payUnits[1].payUnit && !timecard.payUnits[1].units) || (!!timecard.payUnits[2].payUnit && !timecard.payUnits[2].units)
  }
  public isModelHasError(): boolean {
    let hasError = false;
    _.forEach(this.data.records, (r) => {
      _.forEach(r.timecardPayUnits, (t) => {
        hasError = hasError || this.isTimecardError(t);
        if (hasError) {
          return false;
        }
      });
      if (hasError) {
        return false;
      }
    });
    return hasError;
  }
  public changeDate(): void {
    if(!this.selectedDate || !moment(this.selectedDate).isValid() || moment(this.request.startDate).isAfter(this.selectedDate) || moment(this.request.endDate).isBefore(this.selectedDate)) {
      return;
    }
    this.dateKey = moment(this.selectedDate).format(appConfig.linkDateFormat);
    this.detectChanges();
  }

  public async save(): Promise<any> {
    if (this.isModelHasError()) {
      let options: DialogOptions = new DialogOptions();
      options.message = 'Some Pay Units has zero or blank value!';
      options.height = 150;
      options.width = 300;
      this.modalAnchor.openInfoDialogEx(`Warning`, options, (result: boolean) => {
        //nothing to do;
      });
      return;
    }
    const payload = this.mapService.mapToPayload(this.data);
    const hasChanges = !!_.find(payload.records, (r) => r.addRecords.length > 0 || r.deleteRecords.length > 0 || r.updateRecords.length > 0);
    if (!hasChanges) {
      let options: DialogOptions = new DialogOptions();
      options.message = 'There are no changes to save!';
      options.height = 150;
      options.width = 300;
      this.modalAnchor.openInfoDialogEx(`Warning`, options, (result: boolean) => {
        //nothing to do;
      });
      return;
    }
    this.isLoading = true;
    this.apiService.saveMassAssignPayUnits(this.request.orgLevelId, payload)
      .then(() => {
        this.onClose.next(true);
        this.notificationsService.success('Success', 'Unit codes were successfully updated');
      })
      .catch((err) => {
        this.notificationsService.error('Error', 'Error has been occured during update Unit Codes');
      })
      .finally(() => {
        this.isLoading = false;
      });

  }
  public getPayunitsLookup(dateItem: EmpPayUnitsInfo): Lookup {
    if (!this.payunitsLookup) {
      return null;
    }
    let l = this.payunitsLookup[dateItem.departmentId];
    if (!l) {
      l = this.payunitsLookup[0];
    }
    return l;
  }

  public getEmpPositions(dateItem: EmpPayUnitsInfo): Lookup {
    if (!dateItem.positionsLookup || dateItem.positionsLookupDateKey !== this.dateKey) {
      dateItem.positionsLookup = new Lookup();
      dateItem.positionsLookup.items = _.filter(dateItem.positions, (p) => (!p.startDate || moment(p.startDate).isSameOrBefore(this.selectedDate)) && (!p.endDate || moment(p.endDate).isSameOrAfter(this.selectedDate)));
      dateItem.positionsLookup.titleField = 'name';
      dateItem.positionsLookup.valueField = 'id';
      dateItem.positionsLookupDateKey = this.dateKey;
    }
    return dateItem.positionsLookup;
  }

  public changePayUnit(p: PayUnitDefinition, timecard: EmpTimecardPayUnit, pu: EmpPayUnit, index: number): void {
    pu.payUnit = p;
    if (isNilPayUnit(pu.payUnit)) {
      for(let i = index; i < timecard.payUnits.length; i++) {
        const temp = timecard.payUnits[i];
        temp.position = undefined;
        temp.payUnit = undefined;
        temp.units = undefined;
      }
    } else {
      if (pu.position === undefined) {
        pu.position = timecard.position;
      }
      if (pu.units === undefined || pu.units === null) {
        pu.units = 1;
      }
    }
    this.detectChanges();
  }
  public changePosition(position: Position, pu: EmpPayUnit): void {
    if (isNilPayUnit(pu.payUnit)) {
      pu.position = undefined;
      return;
    }
    pu.position = position;
  }

  public changeUnits(units: number, pu: EmpPayUnit): void {
    if (isNilPayUnit(pu.payUnit)) {
      pu.units = undefined;
      return;
    }
    pu.units = units;
  }

  public cancel(): void {
    this.onClose.next(false);
  }

  private refreshGrid(): void {
    if (!this.data) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.data.records, this.gridState.state);
    this.detectChanges();
  }

  private detectChanges(): void {
    this.changeDetector.markForCheck();
    if (!this.changeDetector['destroyed']) {
      this.changeDetector.detectChanges();
    }
  }
}
