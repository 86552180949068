import { Directive, Input, ElementRef, Renderer, HostListener, OnInit, Host, AfterViewInit, DoCheck } from '@angular/core';
import { GridComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { IColumnSettings } from '../../../../core/models/index';

export type kendGridFilterDefinition = 'boolean' | 'text' | 'numeric' | 'date';

@Directive({
  selector: '[kendoGridColumnConfig]',
})
export class KendoGridColumnConfigDirective implements DoCheck, OnInit {
  @Input()
  public kendoGridColumnConfig: StringMap<IColumnSettings>;
  @Input()
  public kendoGridColumnId: string;

  private elRef: ElementRef;
  private renderer: Renderer;
  private column: ColumnComponent;
  private prevIsVisible: boolean;

  constructor( @Host() column: ColumnComponent, elRef: ElementRef, renderer: Renderer) {
    this.elRef = elRef;
    this.renderer = renderer;
    this.column = column;
  }

  public ngOnInit(): void {
    if (!this.kendoGridColumnConfig) {
      return;
    }
    this.column.hidden = !this.isVisible(this.kendoGridColumnId);
    this.column.width = this.getWidth(this.kendoGridColumnId);
    this.column.filter = this.getFilter(this.kendoGridColumnId);
    this.column.title = this.getTitle(this.kendoGridColumnId);
  }

  public ngDoCheck(): void {
    if (!this.kendoGridColumnConfig || !this.column) {
      return;
    }
    let isVisible: boolean = this.isVisible(this.kendoGridColumnId);
    if (this.prevIsVisible === isVisible) {
      return;
    }
    this.column.hidden = !isVisible;
    this.prevIsVisible = isVisible;
  }

  protected isVisible(field: string): boolean {
    return this.kendoGridColumnConfig[field] ? this.kendoGridColumnConfig[field].displayed : false;
  }

  protected getWidth(field: string): number {
    return this.kendoGridColumnConfig[field] ? this.kendoGridColumnConfig[field].width : 50;
  }

  protected getFilter(field: string): kendGridFilterDefinition {
    return this.kendoGridColumnConfig[field] ? <kendGridFilterDefinition>this.kendoGridColumnConfig[field].filter : null;
  }

  protected getTitle(field: string): string {
    return this.kendoGridColumnConfig[field] ? this.kendoGridColumnConfig[field].description : null;
  }
}
