<form class="dialog-form" #dialogForm="ngForm">
  <div class="modal-content">
    <div class="modal-body">
      <div class="col-xs-12 theme-body-text">
        Please select a budgeting period that you wish to clone to:
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-6">
            <slx-input-decorator className="slx-form-group slx-no-border slx-small-font slx-form-slim">
              <slx-radio-input slx-input name="existingBudget" fieldName="existingBudget" option="Exist" caption="Existing Budget" [(ngModel)]="targetBudget"
                (ngModelChange)="onExisting()">
              </slx-radio-input>
            </slx-input-decorator>
          </div>
          <div class="col-sm-10 col-sm-offset-1">
            <slx-input-decorator>
              <slx-dropdown-input #budgetDefinition="ngModel" slx-input required="true" [lookup]="{lookupType: 'budgetDefinition', orgLevelId: restoreRequest.orgLevelId, updateCacheForced: true }" [filterLookup]="filterSelected"
                name="budgetDefinition" placeholder="Budgeted" [(ngModel)]="selectedBudgetDefinition">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <slx-input-decorator className="slx-form-group slx-no-border slx-small-font slx-form-slim">
              <slx-radio-input slx-input name="newBudget" fieldName="newBudget" option="New" caption="Create New Budget" [(ngModel)]="targetBudget"
                (ngModelChange)="onNew()">
              </slx-radio-input>
            </slx-input-decorator>
          </div>
          <div class="col-sm-10 col-sm-offset-1">
            <div class="row">
              <div class="col-sm-6 col-lg-6">
                <slx-input-decorator>
                  <slx-date-picker-ngx slx-input date #startDatePicker="ngModel"
                    [(ngModel)]="startDate"
                    [required]="true"
                    [maxDate]="endDate"
                    placeholder="Budget Start Date"
                    name="startDate"
                  ></slx-date-picker-ngx>
                  <span errorMessage for="required"></span>
                  <span errorMessage for="date"></span>
                </slx-input-decorator>
              </div>
              <div class="col-sm-6 col-lg-6">
                <slx-input-decorator>
                  <slx-date-picker-ngx slx-input
                    [(ngModel)]="endDate"
                    [minDate]="startDate"
                    placeholder="Budget End Date"
                    name="endDate"
                  ></slx-date-picker-ngx>
                  <span errorMessage for="date"></span>
                </slx-input-decorator>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Cancel</button>
      <button type="button" (click)="onOk()" [disabled]="(!budgetDefinition.valid && targetBudget==='Exist') || (!startDatePicker.valid && targetBudget==='New')"
        class="theme-button-apply">OK</button>
    </div>
  </div>
</form>
