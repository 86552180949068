import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, Provider } from '@angular/core';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { Employee, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { commonConfig } from '../../../../common/common.config';
import { DialogOptions2, DialogModeSize } from '../../../../common/index';
import { EmployeeGridComponent } from '../employee-grid/employee-grid.component';
import { NotificationsService } from '../../../../core/components';
import { NotificationsApiService } from '../../../../portal/services';
var GroupSmsComponent = /** @class */ (function () {
    function GroupSmsComponent(groupActivitiesService, employees, fieldDefinition, orgLevel, modalService, notificationsService, options, notificationApiService) {
        this.notificationsService = notificationsService;
        this.notificationApiService = notificationApiService;
        this.messageCount = 0;
        this.smsText = '';
        this.maxSmsCharactersLength = 255;
        this.canSend = false;
        this.groupActivitiesService = groupActivitiesService;
        this.employees = employees;
        this.fieldDefinition = fieldDefinition;
        this.orgLevel = orgLevel;
        this.modalService = modalService;
        this.options = options;
        this.onComplete = new EventEmitter();
        this.state = {
            isLoading: false,
            isActiveOnly: true
        };
        this.checkMessageCenterAccess();
    }
    GroupSmsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var employeeIds = _.map(this.employees, function (employee) { return employee[employeeListConfig.employeeIdentifierName]; });
        this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, this.state.isActiveOnly, employeeIds, this.fieldDefinition).then(function (actionEmployees) {
            _.map(actionEmployees, function (employee) { employee['Selectable'] = (Boolean(employee['CellPhoneNo']) && employee['CellPhoneNo'].match(commonConfig.phonePattern) !== null); });
            _this.actionEmployees = actionEmployees;
            _this.validateEmployee(actionEmployees);
        });
    };
    GroupSmsComponent.prototype.validateEmployee = function (actionEmployees) {
        var selectedEmployees = _.filter(actionEmployees, function (employee) {
            if (employee.CellPhoneNo && _.trim(employee.CellPhoneNo) != '' && _.trim(employee.CellPhoneNo) != '(___) ___-____') {
                if (employee.OptIn == 'Not Responded to the Opt In Request' || employee.OptIn == 'Opted Out') {
                    employee.Selectable = false;
                    employee.Tooltip = '';
                }
                else {
                    employee.Selectable = true;
                    employee.isSelected = false;
                }
            }
            else {
                employee.Selectable = false;
                employee.isSelected = false;
                employee.Tooltip = "Employee doesn't have mobile number";
            }
            return employee;
        });
        this.actionEmployees = this.getEmployeeMessage(selectedEmployees);
    };
    GroupSmsComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    GroupSmsComponent.prototype.employeesSelect = function (selectedEmployees) {
        this.selectedEmployees = selectedEmployees;
        this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
    };
    GroupSmsComponent.prototype.sendSms = function () {
        var _this = this;
        this.state.isLoading = true;
        this.groupActivitiesService.sendSms(this.smsText, this.selectedEmployees, this.orgLevel.id)
            .then(function () {
            _this.state.isLoading = false;
            _this.canSend = false;
            _this.notificationsService.success('SMS message sent', 'SMS message was sent successfully');
            _this.dialogResult = true;
            _this.modalService.closeWindow(_this.options.windowUniqueId);
        })
            .catch(function () {
            _this.state.isLoading = false;
            _this.canSend = false;
            _this.dialogResult = false;
            _this.modalService.closeWindow(_this.options.windowUniqueId);
        });
    };
    GroupSmsComponent.prototype.getEmployeeMessage = function (actionEmployees) {
        if (!actionEmployees) {
            return;
        }
        var selectableEmployees = _.map(_.filter(actionEmployees, ['Selectable', true]), function (employee) { return employee['EmpId']; });
        this.groupActivitiesService.getEmployeeMessages(selectableEmployees)
            .then(function (result) {
            _.each(actionEmployees, function (item) {
                var filterResult = _.filter(result, function (data) {
                    return item['EmpId'] == data.employeeId;
                });
                if (filterResult && filterResult.length > 0) {
                    if (filterResult[0].smsDate) {
                        item.OptInDate = filterResult[0].smsDate;
                    }
                    else {
                        item.OptInDate = null;
                    }
                }
                else {
                    item.OptInDate = null;
                }
            });
        })
            .catch(function () {
        });
        return actionEmployees;
    };
    GroupSmsComponent.openDialog = function (modalService, actionDefinition, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.height = 400;
        dialogOptions.minHegiht = 500;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions,
            }
        ];
        resolvedProviders = resolvedProviders.concat(actionDefinition);
        var dialog = modalService.globalAnchor.openDialog2(GroupSmsComponent, 'Send SMS to a Group of Employees', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    GroupSmsComponent.prototype.checkMessageCenterAccess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var access;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.notificationApiService.getIconAccessCheck(this.orgLevel.id)];
                    case 1:
                        access = _a.sent();
                        if (access.isMessageCenterEnabled) {
                            this.maxSmsCharactersLength = 320;
                        }
                        else {
                            this.maxSmsCharactersLength = 255;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return GroupSmsComponent;
}());
export { GroupSmsComponent };
