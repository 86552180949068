import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { AccrualsTransactionsManagementService } from '../../services/index';
var AccrualsTransactionsComponent = /** @class */ (function () {
    function AccrualsTransactionsComponent(accrualsManagementService) {
        this.accrualsManagementService = accrualsManagementService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    AccrualsTransactionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accrualsManagementService.init();
        this.subscriptions.loading = this.accrualsManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    AccrualsTransactionsComponent.prototype.ngOnDestroy = function () {
        this.accrualsManagementService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsTransactionsComponent.prototype, "subscriptions", void 0);
    return AccrualsTransactionsComponent;
}());
export { AccrualsTransactionsComponent };
