<kendo-grid  #kendoGrid class="log-grid" [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)"
  [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort"
  [group]="gridState.state.group" [groupable]="true" [filterable]="true" [filter]="gridState.state.filter" [scrollable]="true">

  <kendo-grid-column media="xs" title="Punch Logs">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>
        <dt>Log Id</dt>
        <dd>{{dataItem?.id}}</dd>

        <dt>Punch Time</dt>
        <dd>{{ dataItem.punchTime | amDateFormat: appConfig.dateTimeFormatYearless }}</dd>

        <dt>Timeclock</dt>
        <dd>{{dataItem?.timeclock}}</dd>

        <dt>Type</dt>
        <dd>{{dataItem?.type}}</dd>

        <dt>Errors</dt>
        <dd>{{dataItem?.errors}}</dd>

        <dt>Comments</dt>
        <dd>{{dataItem?.comments}}</dd>

        <dt>R</dt>
        <dd>{{dataItem?.isReverse | boolyn}}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Log Id" width="150" media="sm" field="id">
    <ng-template kendoGridHeaderTemplate>
      <span class="column-title-break">Log Id</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.id}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Punch Time" width="150" media="sm" field="punchTime">
    <ng-template kendoGridHeaderTemplate>
      Punch Time
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value | amDateFormat: appConfig.dateTimeFormatYearless }}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.punchTime | amDateFormat: appConfig.dateTimeFormatYearless }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Timeclock" width="150" media="sm" field="timeclock">
    <ng-template kendoGridHeaderTemplate>
      Timeclock
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.timeclock}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Type" width="150" media="sm" field="type">
    <ng-template kendoGridHeaderTemplate>
      Type
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.type}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Errors" width="150" media="sm" field="errors">
    <ng-template kendoGridHeaderTemplate>
      Errors
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.errors}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Comments" width="150" media="sm" field="comments">
    <ng-template kendoGridHeaderTemplate>
      Comments
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.comments}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="R" width="150" media="sm" field="isReverse">
    <ng-template kendoGridHeaderTemplate>
      R
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.isReverse | boolyn}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
      </slx-kendo-grid-boolean-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel fileName="PunchLog.xlsx"></kendo-grid-excel>

</kendo-grid>
