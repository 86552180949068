import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { WcSummaryManagementService } from '../../../services/index';
var WcSummaryComponent = /** @class */ (function () {
    function WcSummaryComponent(manService) {
        this.manService = manService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    WcSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init('WcSummaryRoster');
        this.subscriptions.onLoad = this.manService
            .subscribeToLoading(function (value) { return (_this.isLoading = value); });
    };
    WcSummaryComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcSummaryComponent.prototype, "subscriptions", void 0);
    return WcSummaryComponent;
}());
export { WcSummaryComponent };
