<slx-spinner [show]="isLoading">
    <div class="modal-body">
      <form class="form-horizontal" #correctionForm="ngForm">
        <div class="field-align">
          <slx-input-decorator>
            <slx-dropdown-input slx-input
                                name="correctionType"
                                titleField="correctionType"
                                valueField="id"
                                placeholder="Select Correction Type"
                                [options]="correctionTypeOptions"
                                [required]="true"
                                [(ngModel)]="acaCorrectionType"
                                (ngModelChange)="resetReceiptId()"
                                width="200">
            </slx-dropdown-input>
          </slx-input-decorator>
          <slx-input-decorator>
            <input slx-input type="text"
                   slx-aca-receiptid
                   [textMask]="receiptIdMaskConf"
                   name="acaReceiptId"
                   placeholder="Enter Receipt ID"
                   [required]="true"
                   [(ngModel)]="acaCorrectionType.receiptId"
                   maxlength="255"/>
            <span errorMessage for="required"></span>
            <span errorMessage for="acaReceiptId"></span>
          </slx-input-decorator>
        </div>
      </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn red" (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel</button>
        <button type="button" (click)="showSelectEmpDialog()" class="btn green" [disabled]="!valid"><i class="fa fa-check slx-button__icon" aria-hidden="true"></i>Ok</button>
    </div>
</slx-spinner>
