import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { BenefitEligibilityRuleDataType } from '../../../models';

@Component({
  selector: 'slx-benefit-details-eligibilty-statement-multi-values',
  templateUrl: './benefit-details-eligibilty-statement-multi-values.component.html',
  styleUrls: ['./benefit-details-eligibilty-statement-multi-values.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class BenefitDetailsEligibiltyStatementMultiValuesComponent {
  @Input()
  value: string;

  @Input()
  valueType: BenefitEligibilityRuleDataType;

  @Input()
  uniqueComponentId: string;

  @Output()
  valueChanged: EventEmitter<string>;

  get BenefitEligibilityRuleDataType() {
    return BenefitEligibilityRuleDataType;
  }

  constructor() {
    this.valueChanged = new EventEmitter();
    this.value = '';
  }

  onValueChanged(value: string) {
    this.valueChanged.next(value);
  }
}
