import { AfterContentInit, ElementRef } from '@angular/core';
var CollapsiblePanelComponent = /** @class */ (function () {
    function CollapsiblePanelComponent() {
        this.isCollapsed = true;
        this.defaultHeader = true;
    }
    Object.defineProperty(CollapsiblePanelComponent.prototype, "collapsed", {
        set: function (val) {
            if (val) {
                this.onCollapse();
            }
            else {
                this.onExpand();
            }
        },
        enumerable: true,
        configurable: true
    });
    CollapsiblePanelComponent.prototype.ngAfterContentInit = function () {
        this.defaultHeader = this.barContent.nativeElement.children.length === 0;
    };
    CollapsiblePanelComponent.prototype.onCollapse = function () {
        this.isExpanding = false;
        this.isCollapsed = true;
    };
    CollapsiblePanelComponent.prototype.onExpand = function () {
        var _this = this;
        this.isExpanding = true;
        this.isCollapsed = false;
        setTimeout(function () {
            _this.isExpanding = false;
        }, 1000);
    };
    CollapsiblePanelComponent.prototype.onToggle = function () {
        if (!this.isCollapsed) {
            this.onCollapse();
        }
        else {
            this.onExpand();
        }
    };
    return CollapsiblePanelComponent;
}());
export { CollapsiblePanelComponent };
