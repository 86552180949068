import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { Assert } from '../../../../framework/index';

import { dateTimeUtils } from '../../../../common/utils/index';
import {
  IEmployeeWarningExtended,
  EmployeeWarningExtended,
  IEmployeeWarningBasic,
  EmployeeWarningBasic,
  EmployeeWarningSections,
  EmployeeWarningHeaderDetail,
  IEmployeeWarningSectionViolations,
  IEmployeeWarningSectionActions,
  IEmployeeWarningSectionCompanyRemarks,
  IEmployeeWarningSectionEmployeeRemarks,
  EmployeeWarningSectionViolations,
  EmployeeWarningSectionActions,
  EmployeeWarningSectionCompanyRemarks,
  EmployeeWarningSectionEmployeeRemarks,
  EmployeeWarningViolation,
  IEmployeeWarningSectionViolationsAB,
  EmployeeWarningSectionViolationsAB,
  IEmployeeWarningSectionIncidentDescription,
  EmployeeWarningSectionIncidentDescription,
  EmployeeWarningAction
} from '../../models/index';

import { EmployeeDefinitionsMapService, LookupMapService } from '../../../../organization/services/index';

@Injectable()
export class EmployeeSectionWarningMapService {
  constructor(
    private readonly empMapService: EmployeeDefinitionsMapService
  ) {
  }

  public mapToWarningBasic(dto: IEmployeeWarningBasic): EmployeeWarningBasic {
    Assert.isNotNull(dto, 'dto');

    const warning = new EmployeeWarningBasic();

    warning.id = dto.id;
    warning.warningSubject = dto.label;
    warning.addedBy = dto.addedBy;
    warning.addedAt = dateTimeUtils.convertFromDtoString(dto.addedAt);

    return warning;
  }

  public mapToWarningExtended(dto: IEmployeeWarningExtended): EmployeeWarningExtended {
    Assert.isNotNull(dto, 'dto');
    const warning = new EmployeeWarningExtended();
    warning.id = dto.id;
    warning.employee = this.empMapService.mapToEmployeeShortInfo(dto.employee);
    warning.additionalInfo = _.map(dto.additionalInfo, i => new EmployeeWarningHeaderDetail(i.id, i.label, i.value, i.dataType));
    warning.warningSubject = dto.subject;
    warning.warningDate = dateTimeUtils.convertFromDtoString(dto.dateOfWarning);
    warning.sections = _.map(dto.sections, s => this.mapToWarningSection(s));

    return warning;
  }

  public mapToDtoWarningExtended(warning: EmployeeWarningExtended): IEmployeeWarningExtended {
    Assert.isNotNull(warning, 'warning');
    const dto: IEmployeeWarningExtended = {
      id: warning.id,
      employee: this.empMapService.mapToEmployeeShortInfoDto(warning.employee),
      additionalInfo: _.map(warning.additionalInfo, i => ({ id: i.id, label: i.label, value: i.value, dataType: i.dataType })),
      subject: warning.warningSubject,
      dateOfWarning: dateTimeUtils.convertToDtoString(warning.warningDate),
      sections: _.map(warning.sections, s => this.mapToDtoWarningSection(s))
    };

    return dto;
  }

  public createViolationsSection(
    type = EmployeeWarningSections.StandardViolations,
    label = 'Violations'
  ): EmployeeWarningSectionViolations {
    const section = new EmployeeWarningSectionViolations(type, label);
    section.data = {
      dateOfViolation: null,
      violations: []
    };

    return section;
  }

  public createCompanyRemarksSection(
    type = EmployeeWarningSections.StandardCompanyRemarks,
    label = 'Company Remarks'
  ): EmployeeWarningSectionCompanyRemarks {
    const section = new EmployeeWarningSectionCompanyRemarks(type, label);
    section.data = {
      companyRemarks: ''
    };

    return section;
  }

  public createEmployeeRemarksSection(
    type = EmployeeWarningSections.StandardEmployeeRemarks,
    label = 'Employee Remarks'
  ): EmployeeWarningSectionEmployeeRemarks {
    const section = new EmployeeWarningSectionEmployeeRemarks(type, label);
    section.data = {
      employeeRemarks: '',
      employeeSign: null,
      employeeSignDate: null
    };

    return section;
  }

  public createActionsSection(
    type = EmployeeWarningSections.StandardActions,
    label = 'Actions to be taken'
  ): EmployeeWarningSectionActions {
    const section = new EmployeeWarningSectionActions(type, label);
    section.data = {
      actions: [],
      numberOfDays: '',
      commentForNumberOfDays: '',
      actionDate: null,
      actionTitle: '',
      approvedBy: '',
      approveDate: null,
      employeeSignature: '',
      employeeTitle: '',
      creatorSignature: '',
      witnessSignature: '',
      supervisorSignature: '',
      employeeSignDate: null,
      witnessSignDate: null,
      issuerSignDate: null,
      supervisorSignDate: null
    };

    return section;
  }

  public createViolationsABSection(
    type = EmployeeWarningSections.CustomViolationsAB,
    label = 'Violations'
  ): EmployeeWarningSectionViolationsAB {
    const section = new EmployeeWarningSectionViolationsAB(type, label);
    section.data = {
      violationNumber: '',
      isPartAType: null,
      violationAbsence: '',
      violationTardiness: '',
      violationDate: null
    };

    return section;
  }

  public createIncidentDescriptionSection(
    type = EmployeeWarningSections.CustomIncidentDescription,
    label = 'Incident Description'
  ): EmployeeWarningSectionIncidentDescription {
    const section = new EmployeeWarningSectionIncidentDescription(type, label);
    section.data = {
      incidentDescription: ''
    };

    return section;
  }

  public mapToWarningSection(
    sectionDto: IEmployeeWarningSectionViolations
    | IEmployeeWarningSectionActions
    | IEmployeeWarningSectionCompanyRemarks
    | IEmployeeWarningSectionEmployeeRemarks
    | IEmployeeWarningSectionViolationsAB
    | IEmployeeWarningSectionIncidentDescription
  ):
    EmployeeWarningSectionViolations
    | EmployeeWarningSectionActions
    | EmployeeWarningSectionCompanyRemarks
    | EmployeeWarningSectionEmployeeRemarks
    | EmployeeWarningSectionViolationsAB
    | EmployeeWarningSectionIncidentDescription
  {

    let section: any = null;
    switch (sectionDto.type) {
      case EmployeeWarningSections.StandardViolations:
        section = this.mapToViolations(sectionDto as IEmployeeWarningSectionViolations);
        break;
      case EmployeeWarningSections.StandardCompanyRemarks:
        section = this.mapToCompanyRemarks(sectionDto as IEmployeeWarningSectionCompanyRemarks);
        break;
      case EmployeeWarningSections.StandardEmployeeRemarks:
        section = this.mapToEmployeeRemarks(sectionDto as IEmployeeWarningSectionEmployeeRemarks);
        break;
      case EmployeeWarningSections.StandardActions:
        section = this.mapToActions(sectionDto as IEmployeeWarningSectionActions);
        break;
      case EmployeeWarningSections.CustomViolationsAB:
        section = this.mapToViolationsAB(sectionDto as IEmployeeWarningSectionViolationsAB);
        break;
      case EmployeeWarningSections.CustomIncidentDescription:
        section = this.mapToIncidentDescription(sectionDto as IEmployeeWarningSectionIncidentDescription);
        break;
      default:
        throw new TypeError(`Employee Warning section type is not recognised. Type: ${sectionDto.type}`);
    }

    return section;
  }

  private mapToViolations(dto: IEmployeeWarningSectionViolations): EmployeeWarningSectionViolations {
    const section = this.createViolationsSection(dto.type, dto.label);
    if (this.hasData(dto)) {
      section.data.dateOfViolation = dateTimeUtils.convertFromDtoDateTimeString(dto.data.dateOfViolation);
      section.data.violations = _.map(dto.data.violations, v => new EmployeeWarningViolation(v.id, v.label, v.selected));
    }

    return section;
  }

  private mapToCompanyRemarks(dto: IEmployeeWarningSectionCompanyRemarks): EmployeeWarningSectionCompanyRemarks {
    const section = this.createCompanyRemarksSection(dto.type, dto.label);
    if (this.hasData(dto)) {
      section.data.companyRemarks = dto.data.companyRemarks;
    }

    return section;
  }

  private mapToEmployeeRemarks(dto: IEmployeeWarningSectionEmployeeRemarks): EmployeeWarningSectionEmployeeRemarks {
    const section = this.createEmployeeRemarksSection(dto.type, dto.label);
    if (this.hasData(dto)) {
      section.data.employeeRemarks = dto.data.employeeRemarks;
      section.data.employeeSign = dto.data.employeeSign;
      section.data.employeeSignDate = dateTimeUtils.convertFromDtoString(dto.data.employeeSignDate);
    }

    return section;
  }

  private mapToActions(dto: IEmployeeWarningSectionActions): EmployeeWarningSectionActions {
    const section = this.createActionsSection(dto.type, dto.label);
    if (this.hasData(dto)) {
      section.data.actions = _.map(dto.data.actions, v => new EmployeeWarningAction(v.id, v.label, v.selected, v.notes));
      section.data.numberOfDays = dto.data.numberOfDays;
      section.data.commentForNumberOfDays = dto.data.actionNotes;
      section.data.actionDate = dateTimeUtils.convertFromDtoString(dto.data.actionDate);
      section.data.approveDate = dateTimeUtils.convertFromDtoString(dto.data.approveDate);
      section.data.actionTitle = dto.data.approveTitle;
      section.data.approvedBy = dto.data.approvedBy;
      section.data.employeeSignature = dto.data.employeeSignature;
      section.data.employeeTitle = dto.data.issuerTitle;
      section.data.creatorSignature = dto.data.issuerSignature;
      section.data.witnessSignature = dto.data.witnessSignature;
      section.data.supervisorSignature = dto.data.supervisorSignature;
      section.data.employeeSignDate = dateTimeUtils.convertFromDtoString(dto.data.employeeSignDate);
      section.data.witnessSignDate = dateTimeUtils.convertFromDtoString(dto.data.witnessSignDate);
      section.data.issuerSignDate = dateTimeUtils.convertFromDtoString(dto.data.issuerSignDate);
      section.data.supervisorSignDate = dateTimeUtils.convertFromDtoString(dto.data.supervisorSignDate);
    }

    return section;
  }

  private mapToViolationsAB(dto: IEmployeeWarningSectionViolationsAB): EmployeeWarningSectionViolationsAB {
    const section = this.createViolationsABSection(dto.type, dto.label);
    if (this.hasData(dto)) {
      section.data.violationNumber = dto.data.violationNumber;
      section.data.isPartAType = dto.data.violationType;
      section.data.violationAbsence = dto.data.absence;
      section.data.violationTardiness = dto.data.tardiness;
      section.data.violationDate = dateTimeUtils.convertFromDtoDateTimeString(dto.data.violationDate);
    }

    return section;
  }

  private mapToIncidentDescription(dto: IEmployeeWarningSectionIncidentDescription): EmployeeWarningSectionIncidentDescription {
    const section = this.createIncidentDescriptionSection(dto.type, dto.label);
    if (this.hasData(dto)) {
      section.data.incidentDescription = dto.data.description;
    }

    return section;
  }

  private mapToDtoWarningSection(
    section: EmployeeWarningSectionViolations
    | EmployeeWarningSectionActions
    | EmployeeWarningSectionCompanyRemarks
    | EmployeeWarningSectionEmployeeRemarks
    | EmployeeWarningSectionViolationsAB
    | EmployeeWarningSectionIncidentDescription
  ):
    IEmployeeWarningSectionViolations
    | IEmployeeWarningSectionActions
    | IEmployeeWarningSectionCompanyRemarks
    | IEmployeeWarningSectionEmployeeRemarks
    | IEmployeeWarningSectionViolationsAB
    | IEmployeeWarningSectionIncidentDescription
  {
    let sectionDto: any = null;
    switch (section.type) {
      case EmployeeWarningSections.StandardViolations:
        sectionDto = this.mapToDtoViolations(section as EmployeeWarningSectionViolations);
        break;
      case EmployeeWarningSections.StandardCompanyRemarks:
        sectionDto = this.mapToDtoCompanyRemarks(section as EmployeeWarningSectionCompanyRemarks);
        break;
      case EmployeeWarningSections.StandardEmployeeRemarks:
        sectionDto = this.mapToDtoEmployeeRemarks(section as EmployeeWarningSectionEmployeeRemarks);
        break;
      case EmployeeWarningSections.StandardActions:
        sectionDto = this.mapToDtoActions(section as EmployeeWarningSectionActions);
        break;
      case EmployeeWarningSections.CustomViolationsAB:
        sectionDto = this.mapToDtoViolationsAB(section as EmployeeWarningSectionViolationsAB);
        break;
      case EmployeeWarningSections.CustomIncidentDescription:
        sectionDto = this.mapToDtoIncidentDescription(section as EmployeeWarningSectionIncidentDescription);
        break;
      default:
        throw new TypeError(`Employee Warning section type is not recognised. Type: ${sectionDto.type}`);
    }

    return sectionDto;
  }

  private mapToDtoViolations(section: EmployeeWarningSectionViolations): IEmployeeWarningSectionViolations {
    const dto: IEmployeeWarningSectionViolations = this.getDtoBasicSection(section.type, section.label);
    if (this.hasData(section)) {
      dto.data = {} as any;
      dto.data.dateOfViolation = dateTimeUtils.convertToDtoDateTimeString(section.data.dateOfViolation);
      dto.data.violations = _.map(section.data.violations, v => ({id: v.id, label: v.label, selected: v.selected}));
    }

    return dto;
  }

  private mapToDtoCompanyRemarks(section: EmployeeWarningSectionCompanyRemarks): IEmployeeWarningSectionCompanyRemarks {
    const dto: IEmployeeWarningSectionCompanyRemarks = this.getDtoBasicSection(section.type, section.label);
    if (this.hasData(section)) {
      dto.data = {} as any;
      dto.data.companyRemarks = section.data.companyRemarks;
    }

    return dto;
  }

  private mapToDtoEmployeeRemarks(section: EmployeeWarningSectionEmployeeRemarks): IEmployeeWarningSectionEmployeeRemarks {
    const dto: IEmployeeWarningSectionEmployeeRemarks = this.getDtoBasicSection(section.type, section.label);
    if (this.hasData(section)) {
      dto.data = {} as any;
      dto.data.employeeRemarks = section.data.employeeRemarks;
      dto.data.employeeSign = section.data.employeeSign;
      dto.data.employeeSignDate = dateTimeUtils.convertToDtoString(section.data.employeeSignDate);
    }

    return dto;
  }

  private mapToDtoActions(section: EmployeeWarningSectionActions): IEmployeeWarningSectionActions {
    const dto: IEmployeeWarningSectionActions = this.getDtoBasicSection(section.type, section.label);
    if (this.hasData(section)) {
      dto.data = {} as any;
      dto.data.actions = _.map(section.data.actions, v => ({id: v.id, label: v.label, selected: v.selected, notes: v.notes}));
      dto.data.numberOfDays = section.data.numberOfDays;
      dto.data.actionNotes = section.data.commentForNumberOfDays;
      dto.data.approveDate = dateTimeUtils.convertToDtoString(section.data.approveDate);
      dto.data.actionDate = dateTimeUtils.convertToDtoString(section.data.actionDate);
      dto.data.approveTitle = section.data.actionTitle;
      dto.data.approvedBy = section.data.approvedBy;
      dto.data.employeeSignature = section.data.employeeSignature;
      dto.data.issuerTitle = section.data.employeeTitle;
      dto.data.issuerSignature = section.data.creatorSignature;
      dto.data.witnessSignature = section.data.witnessSignature;
      dto.data.supervisorSignature = section.data.supervisorSignature;
      dto.data.employeeSignDate = dateTimeUtils.convertToDtoString(section.data.employeeSignDate);
      dto.data.witnessSignDate = dateTimeUtils.convertToDtoString(section.data.witnessSignDate);
      dto.data.issuerSignDate = dateTimeUtils.convertToDtoString(section.data.issuerSignDate);
      dto.data.supervisorSignDate = dateTimeUtils.convertToDtoString(section.data.supervisorSignDate);
    }

    return dto;
  }

  private mapToDtoViolationsAB(section: EmployeeWarningSectionViolationsAB): IEmployeeWarningSectionViolationsAB {
    const dto: IEmployeeWarningSectionViolationsAB = this.getDtoBasicSection(section.type, section.label);
    if (this.hasData(section)) {
      dto.data = {} as any;
      dto.data.violationNumber = section.data.violationNumber;
      dto.data.violationType = section.data.isPartAType;
      dto.data.absence = section.data.violationAbsence;
      dto.data.tardiness = section.data.violationTardiness;
      dto.data.violationDate = dateTimeUtils.convertToDtoDateTimeString(section.data.violationDate);
    }

    return dto;
  }

  private mapToDtoIncidentDescription(section: EmployeeWarningSectionIncidentDescription): IEmployeeWarningSectionIncidentDescription {
    const dto: IEmployeeWarningSectionIncidentDescription = this.getDtoBasicSection(section.type, section.label);
    if (this.hasData(section)) {
      dto.data = {} as any;
      dto.data.description = section.data.incidentDescription;
    }

    return dto;
  }

  private getDtoBasicSection(type: EmployeeWarningSections, label: string): any {
    return {
      type,
      label,
      data: null
    };
  }

  private hasData(dto: any): boolean {
    return _.isObjectLike(dto) && _.isObjectLike(dto.data) && _.size(dto.data) > 0;
  }
}

