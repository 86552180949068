import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll, mutableSelect } from '../../../../core/decorators/index';
import { Subscription, Observable } from 'rxjs';
import { StateManagementService, ColumnManagementService } from '../../../../common';
import { OrgLevel } from '../../../../state-model/models';
import { AcaMeasurementManagementService } from '../../../services/aca-measurement/aca-measurement-management.service';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { EmployeeFilterRecord, EmployeeFilter, AcaMeasurement } from '../../../models/index';
import { AcaMeasurementSettings, AcaMeasurementColumnsSettings } from '../../../models/aca-measurement/aca-measurement-settings';
import { IAcaMeasurementState, initialAcaMeasurementState, AcaMeasurementFilterState, EmployeeIdList } from '../../../models/aca-measurement/aca-measurement-state';
import { AcaMeasurementActions } from '../../../models/aca-measurement/aca-measurement-actions';

@Component({
  moduleId: module.id,
  selector: 'slx-aca-measurement-toolbar',
  templateUrl: 'aca-measurement-toolbar.component.html',
  styleUrls: ['aca-measurement-toolbar.component.scss']
})

export class AcaMeasurementToolbarComponent implements OnInit, OnDestroy {

  public get defaultYear(): number {
    let year: number = moment().startOf('day').toDate().getFullYear();
    let month: number = moment().startOf('day').toDate().getMonth();
    
    if (month < 3) {
      year = year - 1;
    }
    
    return year;
  }
  public currentYear: number = moment().startOf('day').toDate().getFullYear();
  public selectedYear: number;
  public yearOptions: number[] = [];
  public employeeFilterRecords: EmployeeFilterRecord[];
  public filter: EmployeeFilterRecord[];
  public employeeIdList: EmployeeIdList[] = [];
  public acaFilterState: AcaMeasurementFilterState = new AcaMeasurementFilterState();
  public readonly columnsStateName: string = 'AcaMeasurement';
  public state: IAcaMeasurementState;
  public actions: AcaMeasurementActions;

  private settings: AcaMeasurementSettings;
  private readonly stateKey: string = 'AcaMeasurementState';


  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private acaManagementService: AcaMeasurementManagementService,
    private columnManagementService: ColumnManagementService,
    private stateManagement: StateManagementService
  ) { }

  public ngOnInit(): void {
    this.createYearOptions();
    this.selectedYear = this.defaultYear;

    this.subscriptions.onLoaded = this.acaManagementService
      .subscribeToEmployeeFilter((records: EmployeeFilter) => {
        this.filter = records.filter;
        this.employeeFilterRecords = records.employeeFilterRecord;
    });

    this.subscriptions.onLoaded = this.acaManagementService
      .subscribeToLoadedRecords((container: AcaMeasurement) => {
        if (!this.actions) {
          this.actions = new AcaMeasurementActions();
          this.actions = container.actions;
          this.initServices();
        }
      });
  }

  private createYearOptions(): void {
    this.yearOptions = _.reverse(_.values(_.range(2016, this.currentYear + 1)));
  }

  public ngOnDestroy(): void { }

  public onClickExport(isPDF: boolean): void {
    this.acaManagementService.exportTo(isPDF);
  }

  private initServices(): void {
    this.restoreSettings();
    this.stateManagement.init('AcaMeasurementComponent');
    this.columnManagementService.init('AcaMeasurementComponent');
    this.columnManagementService.initGroup(this.columnsStateName, 6);
    this.columnManagementService.initializeGroupWithColumns(this.columnsStateName, this.settings.columns.columns);

  }

  public getAcaWidth(): number {
    return (screen.width <= appConfig.mobileMaxWidth) ? 30 : 130;
  }

  public onChangeYear(selYear: number): void {
    this.selectedYear = selYear;
    this.acaManagementService.setSelectedYear(selYear);   
  }
  
  public onChangeFilter(filters: EmployeeFilterRecord[]): void {
    this.acaManagementService.setEmployeeFilter(filters);   
  }
 
  private restoreSettings(): void {
    this.settings = new AcaMeasurementSettings();
    this.settings.columns = new AcaMeasurementColumnsSettings();
    this.settings.columns.createColumns(this.actions);
  }

  public onExpandCollapse(isExpand: boolean): void {
      this.acaManagementService.isExpandAll(isExpand);
  }

}
