import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/observable/merge';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { LookupApiService, OrgLevelWatchService } from '../../../organization/services/index';
import { appConfig } from '../../../app.config';
import { AccessManagementService, AgenciesApiService, AgencyDetailsManagementService } from '../../services/index';
import { AGENCY_ORGANIZATION_ID } from '../../configuration.config';
import { AgencyMappingsComponent } from './agency-mappings/agency-mappings.component';
import * as _ from 'lodash';
var ConfigureAgencyComponent = /** @class */ (function () {
    function ConfigureAgencyComponent(stateManagement, api, lookup, detailsManagement, orglevelWatch) {
        this.stateManagement = stateManagement;
        this.api = api;
        this.lookup = lookup;
        this.detailsManagement = detailsManagement;
        this.orglevelWatch = orglevelWatch;
    }
    Object.defineProperty(ConfigureAgencyComponent.prototype, "agencyMappings", {
        get: function () {
            return this.m_agencyMappings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigureAgencyComponent.prototype, "agencyMapping", {
        get: function () {
            return this.m_agencyMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigureAgencyComponent.prototype, "organizations", {
        get: function () {
            return this.m_organizations;
        },
        enumerable: true,
        configurable: true
    });
    ConfigureAgencyComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            agencySelected: false,
            departmentSelected: false,
            agencyOrgSelected: false,
            organizationSelected: false,
            corporationSelected: false
        };
        this.detaulsActionSubscription = Observable.merge(this.detailsManagement.onAdd$, this.detailsManagement.onRemove$, this.detailsManagement.onUpdate$).subscribe(function () {
            _this.state.isLoading = true;
        });
        this.detaulsActionResultSubscription = Observable.merge(this.detailsManagement.onAdded$, this.detailsManagement.onRemoved$, this.detailsManagement.onUpdated$).subscribe(function () {
            _this.state.isLoading = false;
        });
        this.detaulsActionErrorSubscription = Observable.merge(this.detailsManagement.onAddError$, this.detailsManagement.onRemoveError$, this.detailsManagement.onUpdateError$).subscribe(function () {
            _this.state.isLoading = false;
        });
        this.detailsUpdatedSubscription = this.detailsManagement.onUpdated$.subscribe(function () {
            _this.orglevelWatch.reloadOrgTree();
        });
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.lookup.getOrganizations())
            .subscribe(function (_a) {
            var orgLevel = _a[0], organizations = _a[1];
            _this.m_organizations = organizations;
            _this.currentOrgLevel = orgLevel;
            _this.stateManagement.orgLevelType = _this.currentOrgLevel.type;
            if (_this.mappingsComponent) {
                _this.mappingsComponent.closeEditor();
                _this.mappingsComponent.editMode = false;
            }
            if (_this.stateManagement.isOrganization) {
                _this.state.corporationSelected = true;
                _this.state.departmentSelected = false;
                _this.state.agencyOrgSelected = orgLevel.relatedItemId === AGENCY_ORGANIZATION_ID;
                _this.state.organizationSelected = !_this.state.agencyOrgSelected;
                _this.effectiveOrgLevelId = orgLevel.id;
                if (_this.state.agencyOrgSelected) {
                    _this.lastParentOrganizationId = orgLevel.id;
                }
                else {
                    _this.lastParentOrganizationId = undefined;
                }
                _this.state.agencySelected = false;
                _this.loadAgenciesList();
            }
            else if (_this.stateManagement.isDepartment) {
                _this.effectiveOrgLevelId = orgLevel.parentId;
                _this.state.corporationSelected = true;
                _this.state.agencyOrgSelected = false;
                _this.state.organizationSelected = false;
                var org = _.find(_this.organizations, function (o) {
                    return o.orgLevelId === orgLevel.parentId;
                });
                _this.state.agencySelected = org && org.id === AGENCY_ORGANIZATION_ID;
                _this.state.departmentSelected = !_this.state.agencySelected;
                if (_this.state.agencySelected) {
                    _this.lastParentOrganizationId = undefined;
                    _this.loadAgencyInfo();
                }
                else {
                    if (_this.lastParentOrganizationId !== orgLevel.parentId) {
                        _this.lastParentOrganizationId = orgLevel.parentId;
                        _this.loadAgenciesList();
                    }
                }
            }
            else {
                _this.effectiveOrgLevelId = undefined;
                _this.lastParentOrganizationId = undefined;
                _this.state.agencyOrgSelected = false;
                _this.state.organizationSelected = false;
                _this.state.agencySelected = false;
                _this.state.departmentSelected = false;
                _this.state.corporationSelected = true;
                _this.loadAgenciesList();
            }
        });
    };
    ConfigureAgencyComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    ConfigureAgencyComponent.prototype.onRemoveAgency = function (mapping) {
        var _this = this;
        this.state.isLoading = true;
        this.api.removeAgency(mapping.agency).then(function () {
            _this.state.isLoading = false;
            _this.mappingsComponent.completeRemoveRequest();
            _this.orglevelWatch.reloadOrgTree();
        }).catch(function (result) {
            _this.state.isLoading = false;
            _this.mappingsComponent.cancelRemoveRequest();
            _this.orglevelWatch.reloadOrgTree();
        });
    };
    ConfigureAgencyComponent.prototype.onSaveAgency = function (mapping) {
        var _this = this;
        this.state.isLoading = true;
        if (mapping.agency.id) {
            this.api.updateAgency(this.currentOrgLevel.id, mapping.agency).then(function (value) {
                _this.state.isLoading = false;
                _this.mappingsComponent.completeSaveRequest(value);
                _this.orglevelWatch.reloadOrgTree();
            }).catch(function (result) {
                _this.state.isLoading = false;
            });
        }
        else {
            this.api.addAgency(this.currentOrgLevel.id, mapping).then(function (value) {
                _this.state.isLoading = false;
                _this.mappingsComponent.completeSaveRequest(value);
                _this.orglevelWatch.reloadOrgTree();
            }).catch(function (result) {
                _this.state.isLoading = false;
            });
        }
    };
    ConfigureAgencyComponent.prototype.loadAgenciesList = function () {
        var _this = this;
        this.state.isLoading = true;
        this.m_agencyMappings = null;
        this.api.getAgencies(this.currentOrgLevel.id).then(function (mappings) {
            _this.m_agencyMappings = mappings;
            _this.state.isLoading = false;
        }).catch(function (result) {
            _this.state.isLoading = false;
        });
    };
    ConfigureAgencyComponent.prototype.loadAgencyInfo = function () {
        var _this = this;
        //TODO: investigate possible bug here
        // first update of orglevel has relatedItemId = null
        if (this.currentOrgLevel.relatedItemId) {
            this.state.isLoading = true;
            this.api.getAgency(this.currentOrgLevel.relatedItemId).then(function (mapping) {
                _this.m_agencyMapping = mapping;
                _this.state.isLoading = false;
            }).catch(function (result) {
                _this.state.isLoading = false;
            });
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ConfigureAgencyComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureAgencyComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureAgencyComponent.prototype, "detaulsActionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureAgencyComponent.prototype, "detaulsActionResultSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureAgencyComponent.prototype, "detaulsActionErrorSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureAgencyComponent.prototype, "detailsUpdatedSubscription", void 0);
    return ConfigureAgencyComponent;
}());
export { ConfigureAgencyComponent };
