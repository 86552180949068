import * as _ from 'lodash';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ModalService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Organization, Department } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { Timeclock, TimeclockContainer, TimeclockRemoveRequest } from '../../../models/index';
import { EditableListComponent } from '../../editableList/editable-list.component';
import { TimeclockRemoveDialogComponent } from '../timeclock-remove-dialog/timeclock-remove-dialog.component';

import { AccessManagementService, TimeclocksApiService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-timeclocks-list',
  templateUrl: 'timeclocks-list.component.html',
  styleUrls: ['timeclocks-list.component.scss'],
  providers: [AccessManagementService]
})
export class TimeclocksListComponent implements OnInit, OnDestroy {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public state: {
    isLoading: boolean;
  };

  public onDeleteSelected: any;
  public onAddItem: any;

  public get container(): TimeclockContainer {
    return this.m_container;
  }
  private m_container: TimeclockContainer;

  public get stateManagement(): AccessManagementService {
    return this.m_stateManagement;
  }
  private m_stateManagement: AccessManagementService;

  private currentOrganization: Organization;
  private currentDepartment: Department;
  private selectedItems: any[] = [];
  private currentOrgLevel: OrgLevel;
  private appConfig: IApplicationConfig;
  private timeclocksApiService: TimeclocksApiService;
  private modalService: ModalService;

  @unsubscribe()
  private orgLevelSubscription: Subscription;

  @ViewChild(EditableListComponent, { static: true })
  private list: EditableListComponent;

  constructor(timeclocksApiService: TimeclocksApiService, modalService: ModalService, stateManagement: AccessManagementService) {
    this.timeclocksApiService = timeclocksApiService;
    this.modalService = modalService;
    this.m_stateManagement = stateManagement;

    this.onDeleteSelected = () => {
      this.deleteSelected();
    };
    this.onAddItem = () => {
      this.addItem();
    };
  }

  public ngOnInit(): void {

    this.appConfig = appConfig;

    this.state = {
      isLoading: false
    };

    this.m_stateManagement.allowCorporationLevel = false;
    this.m_stateManagement.allowOrganizationLevel = true;
    this.m_stateManagement.allowDepartmentLevel = true;
    this.state.isLoading = this.m_stateManagement.lockActions = true;

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.state.isLoading = true;
      this.currentOrgLevel = orgLevel;
      this.m_stateManagement.orgLevelType = this.currentOrgLevel.type;
      if (this.currentOrgLevel.type === OrgLevelType.organization) {
        this.currentOrganization = new Organization();
        this.currentOrganization.orgLevelId = this.currentOrgLevel.id;
        this.currentOrganization.name = this.currentOrgLevel.name;
        this.currentDepartment = undefined;
      } else if (this.currentOrgLevel.type === OrgLevelType.department) {
        this.currentOrganization = new Organization();
        this.currentOrganization.orgLevelId = this.currentOrgLevel.parentId;
        this.currentOrganization.name = '';
        this.currentDepartment = new Department();
        this.currentDepartment.orgLevelId = this.currentOrgLevel.id;
        this.currentDepartment.name = this.currentOrgLevel.name;
      } else {
        this.currentOrganization = undefined;
        this.currentDepartment = undefined;
      }

      this.timeclocksApiService.getTimeclocks(this.currentOrgLevel.id).
        then((container: TimeclockContainer) => {
          this.m_container = container;
          this.m_stateManagement.actions = container.actions;
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        }).catch((e: Error) => {
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        });
    });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onItemEditStart(items: Timeclock[]): void {
    this.m_stateManagement.lockActions = true;
  }

  public onItemEditCancel(items: Timeclock[]): void {
    this.m_stateManagement.lockActions = false;
  }

  public onItemSave(items: Timeclock[]): void {
    if (!items || items.length === 0) {
      return;
    }
    let item: Timeclock = items[0];
    this.state.isLoading = this.m_stateManagement.lockActions = true;
    if (item.id !== 0) {
      this.timeclocksApiService.saveTimeclock(item, this.currentOrgLevel.id)
        .then(() => {
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        }).catch(() => {
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        });
    } else {
      this.timeclocksApiService.addTimeclock(item, this.currentOrgLevel.id)
        .then((newItem: Timeclock) => {
          item.id = newItem.id;
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        }).catch(() => {
          this.state.isLoading = this.m_stateManagement.lockActions = false;
        });
    }
  }

  public addItem(): void {
    let newItem: Timeclock = new Timeclock();
    newItem.id = 0;
    newItem.organization = this.currentOrganization;
    newItem.department = this.currentDepartment;
    this.list.addItem(newItem);
  }

  public onSelectionChange(items: Timeclock[]): void {
    this.m_stateManagement.selectedItemsCount = items.length;
    this.selectedItems = items;
  }

  private deleteSelected(): void {
    if (!this.selectedItems) {
      return;
    }

    if (this.selectedItems.length === 1) {
      let req: TimeclockRemoveRequest = new TimeclockRemoveRequest();
      req.timeclock = this.selectedItems[0];
      TimeclockRemoveDialogComponent.openDialog(req, this.modalService, (result: boolean, cmd: TimeclockRemoveRequest) => {
        if (result) {
          this.doDelete(cmd);
        }
      });
    } else {
      alert('Mass delete operation not implemented');
    }
  }

  private doDelete(req: TimeclockRemoveRequest): void {
    this.state.isLoading = this.m_stateManagement.lockActions = true;
    this.timeclocksApiService.removeTimeclock(req, this.currentOrgLevel.id)
      .then(() => {
        this.state.isLoading = this.m_stateManagement.lockActions = false;
        _.pullAll(this.container.records, this.selectedItems);
        this.selectedItems = [];
      })
      .catch((e: Error) => {
        this.state.isLoading = this.m_stateManagement.lockActions = false;
      });
  }
}
