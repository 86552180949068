import { InfoPreloaderDialogComponent } from './../../../../common/components/info-preloader-dialog/info-preloader-dialog.component';
import { InfoPreloaderParams } from './../../../../common/models/info-preloader-model/info-preloader-params';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { ConfirmDialogComponent, ConfirmOptions } from './../../../../common/components/confirm-dialog/confirm-dialog.component';
import { GetHiredMenuApiService } from './get-hired-menu-api.service';
import { Injectable } from '@angular/core';
import { WindowRef } from '../../../../core/services/window/window-ref.model';
import { GetHiredLink } from '../../../models/index';
@Injectable()
export class GetHiredMenuManagementService {

    private cancelPopup: boolean = false;
    private isLoading: boolean = false;
    private loadingDialog: InfoPreloaderDialogComponent;
    constructor(private winRef: WindowRef, private apiService: GetHiredMenuApiService, private modalService: ModalService) {

    }

    public openGetHired(orgLevelId: number): void {
        if (this.isLoading) return;

        this.cancelPopup = false;

        let params: InfoPreloaderParams = new InfoPreloaderParams();
        params.buttonLabel = 'Cancel';
        params.firstMessage = 'Please be patient as we securely connect to and open the '; 
        params.styleMessage = 'Applicant Tracker System';
        params.lastMessage = '. Once connected a new window will open in your browser. If a new window does not open please check that any popup blockers are disabled.';
        params.title = 'Opening Applicant Tracker';

        this.loadingDialog = InfoPreloaderDialogComponent.openDialog(
            params, this.modalService, 
            ((result: boolean) => {
                this.cancelPopup = !result;
                this.isLoading = false;
            })
        );
                                 
        this.isLoading = true;
        this.apiService.getLink(orgLevelId).then((link: GetHiredLink) => {
            if (!this.cancelPopup && this.loadingDialog) {
                if (link.status === 'fail') {
                    this.showError(link.errorMessage);
                } else {
                    this.navigationToGetHired(link.linkUrl);
                }
                this.isLoading = false;
                this.cancelPopup = false;
                this.loadingDialog.onOk();    
            }
        }).catch((reason: any) => {
            this.isLoading = false;
            if (this.loadingDialog) {
                this.loadingDialog.onOk();
            }
        });
    }

    private showError(msg: string): void {
        let opt: ConfirmOptions = new ConfirmOptions();
        opt.showCancel = false;
        opt.showOK = true;
        ConfirmDialogComponent.openDialog('Error', msg, this.modalService, this.dialogClosed, opt);
    }

    private dialogClosed(result: boolean): void {
        //empty
    }

    private navigationToGetHired(url: string): void {
        this.winRef.nativeWindow.open(url, '_blank');
    }
}
