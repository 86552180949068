import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { process, State } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../core/decorators/index';
import { UserRoleRelationModel } from '../../../models/index';
import { UserProfileMapService } from '../../../services/index';
import { UserProfileManagementService } from '../../../services/index';
var OrgLevelSelectorComponent = /** @class */ (function () {
    function OrgLevelSelectorComponent(orgLevelWatchService, mappingService, management) {
        this.orgLevelWatchService = orgLevelWatchService;
        this.mappingService = mappingService;
        this.management = management;
        this.selectionCountChanged = new EventEmitter();
        this.state = {
            isLoading: false,
        };
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(OrgLevelSelectorComponent.prototype, "filterByOrglevelType", {
        get: function () {
            return this.m_filterByOrglevelType;
        },
        set: function (value) {
            this.m_filterByOrglevelType = value;
            this.refreshGrid();
            this.clearSelection();
            this.updateSelection();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OrgLevelSelectorComponent.prototype, "selectedRoleRelation", {
        set: function (value) {
            this.m_selectedRoleRelation = value;
            this.clearSelection();
            this.updateSelection();
        },
        enumerable: true,
        configurable: true
    });
    OrgLevelSelectorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.management.onAddRoleModeSwitch$.subscribe(function () {
            _this.clearSelection();
        });
        this.loadOrgLevels();
    };
    OrgLevelSelectorComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    OrgLevelSelectorComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        _.forEach(this.gridState.view.data, function (record) {
            record.isSelected = _this.isAllSelected && Boolean(record.selectable);
        });
        this.selectedRecords = this.isAllSelected ? this.gridState.view.data.slice(0) : [];
        this.selectionCountChanged.next(this.selectedRecords.length);
    };
    OrgLevelSelectorComponent.prototype.selectionChange = function (dataItem) {
        this.selectedRecords = [];
        if (this.gridState.view && this.gridState.view.data) {
            this.selectedRecords = _.filter(this.gridState.view.data, function (record) {
                return Boolean(record.isSelected) === true;
            });
            if (dataItem && !dataItem.isSelected) {
                this.recursiveDeselectOrgLevel(dataItem.orgLevel.orgLevel);
            }
            this.selectionCountChanged.next(this.selectedRecords.length);
        }
        this.selectionCountChanged.next(this.selectedRecords.length);
    };
    OrgLevelSelectorComponent.prototype.recursiveDeselectOrgLevel = function (orgLevel) {
        var _this = this;
        _.find(this.records, function (record) {
            if (record.orgLevel.orgLevel.id === orgLevel.id) {
                record.isSelected = false;
                if (orgLevel.hasChilds) {
                    _.each(orgLevel.childs, function (child) {
                        _this.recursiveDeselectOrgLevel(child);
                    });
                }
            }
        });
    };
    OrgLevelSelectorComponent.prototype.loadOrgLevels = function () {
        this.records = this.mappingService.wrapOrgLevelFlatList(this.orgLevelWatchService.getFlatList());
        this.refreshGrid();
        this.clearSelection();
        this.updateSelection();
    };
    OrgLevelSelectorComponent.prototype.updateSelection = function () {
        var _this = this;
        if (this.m_selectedRoleRelation) {
            if (this.m_selectedRoleRelation.orgLevels && this.m_selectedRoleRelation.orgLevels.length > 0) {
                _.each(this.records, function (record) {
                    var recordId = record.orgLevel.orgLevel.id;
                    var path = _this.orgLevelWatchService.getOrgLevelPath(recordId);
                    var existingLeveL = _.find(_this.m_selectedRoleRelation.orgLevels, function (orgLevel) {
                        return (orgLevel.id === recordId);
                    });
                    if (existingLeveL) {
                        record.isSelected = true;
                    }
                    else {
                        _.each(path, function (parentLvl) {
                            var existingParent = _.find(_this.m_selectedRoleRelation.orgLevels, function (orgLevel) {
                                return parentLvl.id === orgLevel.id;
                            });
                            if (existingParent) {
                                record.isSelected = true;
                            }
                        });
                    }
                });
            }
        }
        this.selectionChange();
    };
    OrgLevelSelectorComponent.prototype.clearSelection = function () {
        if (this.records) {
            _.forEach(this.records, function (record) {
                record.isSelected = false;
            });
        }
        this.isAllSelected = false;
        this.selectedRecords = [];
        this.selectionCountChanged.next(this.selectedRecords.length);
    };
    OrgLevelSelectorComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        var filteredrecords = this.records;
        if (this.m_filterByOrglevelType) {
            filteredrecords = _.filter(this.records, function (w) {
                return w.orgLevel.orgLevel.type === _this.m_filterByOrglevelType.id;
            });
        }
        this.gridState.view = process(filteredrecords, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OrgLevelSelectorComponent.prototype, "gridRefreshSubscription", void 0);
    return OrgLevelSelectorComponent;
}());
export { OrgLevelSelectorComponent };
