import * as _ from 'lodash';
import { AlertConfig, IAlertConfig } from '../../../../portal/models/index';
import { Component, OnInit, Provider, OnDestroy, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { ManageGroupsManagementService } from '../../../../configuration/services/index';
import { ApplicationService, ApplicationDashboardManagementService } from '../../../services/index';
import { DashBoardAlertsModel } from '../../../../portal/models/index';
import { SessionService } from '../../../../core/services/index';
import { IUser } from '../../../../authentication/store/index';

@Component({
  selector: 'slx-dashboard-alerts-select-popup',
  templateUrl: './dashboard-alerts-select-popup.component.html',
  styleUrls: ['./dashboard-alerts-select-popup.component.scss'],
  providers: [ManageGroupsManagementService],
  encapsulation: ViewEncapsulation.None
})
export class DashboardAlertsSelectPopupComponent implements OnInit {

  public dialogResult: boolean;
  public isLoading: boolean;
  private movedFrom: AlertConfig[];
  public alertsModel: AlertConfig[];
  public activeAlerts: AlertConfig[];
  public inActiveAlerts: AlertConfig[];
  public alertsToUpdate: DashBoardAlertsModel[] = [];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  @Input() 
  public modelData: DashBoardAlertsModel;

  @Output()
  public onLoading = new EventEmitter<boolean>();

  @Output()
  public onHasChanges = new EventEmitter<boolean>();

  constructor (
    private appDashboardMngtService: ApplicationDashboardManagementService,
    private sessionService: SessionService
    ) {
    this.alertsModel = this.activeAlerts = this.inActiveAlerts = [];
  }

  public ngOnInit(): void {
    this.init();
    this.subscriptions.loading = this.appDashboardMngtService
      .subscribeToLoading((isLoading: boolean) => {
        this.onLoading.emit(isLoading);
      });
  }

  public ngOnDestroy(): void {
    this.appDashboardMngtService.destroy();
  }

  public async init(): Promise<void> {
    this.alertsModel = await this.appDashboardMngtService.getDashboardAppAlerts(this.modelData.appId);
    this.configureAlerts();
  }

  public configureAlerts(): void {
    this.activeAlerts = _.filter(this.alertsModel, (t) => t.visible);
    this.inActiveAlerts = _.filter(this.alertsModel, (t) => !t.visible);
  }
  
  public async onDragEnd(movedTo: AlertConfig[]): Promise<void> {
    let user: IUser = this.sessionService.getUser();
    this.alertsToUpdate = [];
    let order = 1;

    _.forEach(this.activeAlerts, (item) => {
      item.displayOrder = order;
      order++;

      let model = new DashBoardAlertsModel();
      model.appId = this.modelData.appId;
      model.visible = item.visible;
      model.alertId = item.alertId;
      model.alertName = item.alertName;
      model.displayOrder = item.displayOrder;
      model.userAppAlertId = item.userAppAlertId;
      model.loginId = item.loginId;
      model.userId = user.id;
      model.lastModifiedDate = new Date();
      this.alertsToUpdate.push(model);
    });

    await this.appDashboardMngtService.modifyAlerts(this.alertsToUpdate);
    this.emitChanges();
  }

  private emitChanges(): void {
    this.onHasChanges.emit(true);
  }

  public async showHideAlertItem(item: AlertConfig): Promise<void> {
    let user: IUser = this.sessionService.getUser();
    this.alertsToUpdate = [];
    item.visible = !item.visible;

    let model = new DashBoardAlertsModel();
    model.appId = this.modelData.appId;
    model.visible = item.visible;
    model.alertId = item.alertId;
    model.alertName = item.alertName;
    model.displayOrder = item.displayOrder;
    model.userAppAlertId = item.userAppAlertId;
    model.loginId = item.loginId;
    model.userId = user.id;
    model.lastModifiedDate = new Date();

    this.alertsToUpdate.push(model);

    this.configureAlerts();
    await this.appDashboardMngtService.modifyAlerts(this.alertsToUpdate);
    this.emitChanges();
  }
  
}
