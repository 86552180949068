import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToRehire, EmployeeRehire } from '../../../../models/index';
import { EmployeeRehireComponent } from '../employee-rehire/employee-rehire.component';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-rehire-dialog',
  templateUrl: 'employee-rehire-dialog.component.html',
  styleUrls: ['employee-rehire-dialog.component.scss']
})
export class EmployeeRehireDialogComponent implements IDialog {
  public options: DialogOptions;
  public dialogResult: boolean;
  public employee: EmployeeToRehire;

  public state: {
    isLoading: boolean;
  };
  @ViewChild('employeeRehire', {static: true})
  public employeeRehire: EmployeeRehireComponent;

  private modalService: ModalService;
  private orgLevelId: number;

  public static openDialog(request: EmployeeToRehire, modalService: ModalService, callback: (result: boolean, req: EmployeeRehire) => void): EmployeeRehireDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = request.dialogHeight;
    dialogOptions.width = 650;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: EmployeeToRehire,
        useValue: request
      }
    ];
    let dialog: EmployeeRehireDialogComponent = modalService.globalAnchor
      .openDialog(EmployeeRehireDialogComponent, 'Employee Rehire', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.requestToRehire);
      });
    return dialog;
  }

  public get requestToRehire(): EmployeeRehire {
    return this.employeeRehire.requestToRehire;
  }

  public get isEmployeeRehireFormValid(): boolean {
    if(this.employeeRehire && this.employeeRehire.ngForm) {
      return this.employeeRehire.ngForm.valid;
    }
    return false;
  }
  constructor(options: DialogOptions,
    modalService: ModalService,
    employee: EmployeeToRehire,
  ) {
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
    this.employee = employee;
    this.state = {
      isLoading: false,
    };
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
