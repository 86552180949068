import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService, CacheUtilService } from '../../../common/services/index';
import { MetaMapService } from '../../../core/services/index';
import { Actions, FieldsMeta, Meta, ResponseBody } from '../../../core/models/index';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../configuration.config';

import { PayCodeModel, IPayCodeModel, PayCodesContainer, AccrualTypes, IAccrualTypes, AccrualPolicy, IAccrualPolicy} from '../../models/index';
import { PayCodesMapService } from './pay-codes-map.service';

@Injectable()
export class PayCodesApiService {

    constructor(private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: PayCodesMapService,
        private metaMapService: MetaMapService,
        private cacheUtilService: CacheUtilService) {
    }

    public getPayCodes(orgLevelId: number): Promise<PayCodesContainer> {
        const url: string = this.getExeptionsRoot(orgLevelId);
        let request: HttpRequest<any> = new HttpRequest('GET', url);
        return this.apiUtilService.request<IPayCodeModel[], Meta>(request).then((response: ResponseBody<IPayCodeModel[], Meta>) => {
            let fieldsMeta: FieldsMeta = response.meta as FieldsMeta;
            let container: PayCodesContainer = this.mapService.mapToPayCodesContainer(response.data, fieldsMeta);
            return container;
        });
    }

    public savePayCodes(payCodes: PayCodeModel[], orgLevelId: number): Promise<any> {
        const url: string = `${this.getExeptionsRoot()}`;
        const body: any = {
            list: this.mapService.mapToPayCodeDtos(payCodes)
        };
        let request: HttpRequest<any> = new HttpRequest('PUT', url, body);
        return this.apiUtilService.request<any[], Meta>(request);
    }

    public savePayCode(payCode: PayCodeModel): Promise<any> {
        const url: string = this.getExeptionsRoot();
        const body: any = {
            list: this.mapService.mapToPayCodeDtos([payCode])
        };
        let request: HttpRequest<any> = new HttpRequest('PUT', url, body);
        return this.apiUtilService.request<any[], Meta>(request);
    }
    
     public getAccrualPolicy(orgLevelId:number):Promise<AccrualPolicy[]>{
        const url: string= `${appConfig.api.url}/${appConfig.api.version}/accruals/${orgLevelId}/policy/short/${"all"}`;
         let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

          let promise: Promise<AccrualPolicy[]> = this.apiUtilService.request<IAccrualPolicy[], Meta>(request).
       then((response: ResponseBody<IAccrualPolicy[], Meta>) =>
         this.mapService.mapAccrualPolicy(response.data)
       );
     return promise;
   }

  public getAccrualType(orgLevelId:number):Promise<AccrualTypes[]>{
    const url: string= `${appConfig.api.url}/${appConfig.api.version}/accruals/${orgLevelId}/${"accrualtype"}`;
     let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

      let promise: Promise<AccrualTypes[]> = this.apiUtilService.request<IAccrualTypes[], Meta>(request).
   then((response: ResponseBody<IAccrualTypes[], Meta>) =>
     this.mapService.mapAccrualTypes(response.data)
   );
 return promise;
}

    private getExeptionsRoot(orgLevelId?: number): string {
        if (orgLevelId !== undefined) {
            return `${this.getConfigurationApiRoot()}/${configurationConfig.api.configuration.payCodes.root}/${orgLevelId}`;
        }
        return `${this.getConfigurationApiRoot()}/${configurationConfig.api.configuration.payCodes.root}`;
    }

    private getConfigurationApiRoot(): string {
        return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }

}
