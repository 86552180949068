import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { appConfig } from '../../../../../../app.config';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { DeviceDetectorService } from '../../../../../../common/services/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { WcRepeatInjuryManagementService } from '../../../../services/index';
var WcRepeatInjuryGridComponent = /** @class */ (function () {
    function WcRepeatInjuryGridComponent(manService, devDetector) {
        this.manService = manService;
        this.devDetector = devDetector;
        this.pageSize = 50;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'organization.name', dir: 'asc' },
            { field: 'employee.name', dir: 'asc' }
        ];
        this.gridState.gridComponentKey = 'WCInjuryGrid';
        this.gridState.gridControlStateKey = 'GridFiltersState';
        this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.appConfig = appConfig;
    }
    WcRepeatInjuryGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.manService
            .subscribeToLoadedRecords(function (r) {
            _this.canEdit = r.canEdit;
            _this.assignRecords(r.records);
        });
        this.subscriptions.state = this.manService
            .subscribeToChangeState(function (s) { return _this.applyState(s); });
        this.subscriptions.export = this.manService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    WcRepeatInjuryGridComponent.prototype.ngOnDestroy = function () { };
    WcRepeatInjuryGridComponent.prototype.onOpenReport = function (entry) {
        this.manService.openEditReportDialog(entry.id);
    };
    WcRepeatInjuryGridComponent.prototype.onGridStateChanged = function (state) {
        if (_.isObjectLike(state)) {
            this.gridState.updateStateWithDelay(state);
        }
    };
    WcRepeatInjuryGridComponent.prototype.isVisible = function (field) {
        return this.state && this.state.columnsMap[field] ? this.state.columnsMap[field].displayed : false;
    };
    WcRepeatInjuryGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    WcRepeatInjuryGridComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.storedRecords = _.clone(records);
        this.refreshGrid();
    };
    WcRepeatInjuryGridComponent.prototype.applyState = function (s) {
        this.state = s;
    };
    WcRepeatInjuryGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    WcRepeatInjuryGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcRepeatInjuryGridComponent.prototype, "subscriptions", void 0);
    return WcRepeatInjuryGridComponent;
}());
export { WcRepeatInjuryGridComponent };
