export class AcaMeasurementActions {
  public empName: boolean;
  public organizationName: boolean;
  public departmentName: boolean;
  public positionName: boolean;
  public dateHired: boolean;
  public dateTerm: boolean;
  public empType: boolean;
  public acaRecord: boolean;
  public exportToExcel: boolean;
}
