import { Component, OnInit, Provider } from '@angular/core';
import { DialogOptions, IDialog, ModalService } from './../../../../../../app/common';
import { CopyAccrualPolicy } from '../../../models/accruals-policy/copy-accruals-policy';
import * as _ from 'lodash';

@Component({
  selector: 'slx-copy-accrual-policy',
  templateUrl: './copy-accrual-policy.component.html',
  styleUrls: ['./copy-accrual-policy.component.scss']
})
export class CopyAccrualPolicyComponent implements IDialog {

  public modalService: ModalService;
  public dialogResult: boolean;
  public dialogData: CopyAccrualPolicy;
  public isAllPolicyCheck: boolean = false;
  public isAllOrgsCheck: boolean = false;

  public static openDialog(request: CopyAccrualPolicy, modalService: ModalService, callback: (result: boolean, request: CopyAccrualPolicy) => void): CopyAccrualPolicyComponent {
    this.resetCheckedItems(request);
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 700;
    dialogOptions.className = 'slx-copy-policy-dialog-custom';
    dialogOptions.showCloseButton = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: CopyAccrualPolicy,
        useValue: request
      }
    ];
    let dialog: CopyAccrualPolicyComponent = modalService.globalAnchor
      .openDialog(CopyAccrualPolicyComponent, request.header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, request);
      });
    return dialog;
  }

  public static resetCheckedItems(request) {
    _.map(request.currentPolicies, (item) => item.isChecked = false);
    _.map(request.orgLevels, (item) => item.isChecked = false);
  }


  constructor(modalService: ModalService, request: CopyAccrualPolicy) {
    this.modalService = modalService;
    this.dialogData = request;
    this.isAllPolicyCheck = _.every(this.dialogData.currentPolicies, (i) => i.isChecked ? true : false);
    this.isAllOrgsCheck = _.every(this.dialogData.orgLevels, (i) => i.isChecked ? true : false);
  }

  selectAllPolicies() {
    this.isAllPolicyCheck = !this.isAllPolicyCheck;
    this.setAllCheck(this.dialogData.currentPolicies, this.isAllPolicyCheck);
  }

  selectAllOrganizations() {
    this.isAllOrgsCheck = !this.isAllOrgsCheck;
    this.setAllCheck(this.dialogData.orgLevels, this.isAllOrgsCheck);
  }

  onChangePolicyCheck(e, data) {
    data.isChecked = !data.isChecked;
    this.isAllPolicyCheck = _.every(this.dialogData.currentPolicies, (i) => i.isChecked ? true : false);
  }

  onChangeOrgLevelCheck(e, data) {
    data.isChecked = !data.isChecked;
    this.isAllOrgsCheck = _.every(this.dialogData.orgLevels, (i) => i.isChecked ? true : false);
  }

  setAllCheck(data, isChecked) {
    _.map(data, (item) => item.isChecked = isChecked);
  }

  get saveDisabled(): boolean {
    let currentPoliciesChecked = _.filter(this.dialogData.currentPolicies, (i) => i.isChecked);
    let orgLevelsChecked = _.filter(this.dialogData.orgLevels, (i) => i.isChecked);
    return currentPoliciesChecked.length > 0 && orgLevelsChecked.length > 0 ? false : true;
  }

  save() {
    this.dialogResult = true;
    this.modalService.closeAllWindows();
  }

  discard() {
    this.dialogResult = false;
    this.modalService.closeAllWindows();
  }

}
