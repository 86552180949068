import * as _ from 'lodash';
import { Directive, HostListener, Input, ContentChild, Host } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[inputTextForbidSymbols][formControlName],[inputTextForbidSymbols][formControl],[inputTextForbidSymbols][ngModel]',
})
export class InputTextForbidSymbolsDirective {
  @Input()
  public inputTextForbidSymbols: RegExp;

  @Input()
  public maxlength: number;

  constructor(@Host() private ngModel: NgModel) {}

  @HostListener('keydown', ['$event'])
  public onkeyDown(event: KeyboardEvent): boolean {
    if (
        event.key !== 'Control'
        && event.key !== 'Shift'
        && event.key !== 'Alt'
        && event.key !== 'Meta'
        && _.isString(event.key) && event.key.search(this.inputTextForbidSymbols) !== -1
    ) {
      event.preventDefault();
      return false;
    }
  }

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent & { target: { value: string, selectionStart: number, selectionEnd: number } }) {
    event.preventDefault();
    const { target, clipboardData } = event;
    const clipboardText = clipboardData.getData('text/plain');
    const cleanValue = clipboardText.replace(this.inputTextForbidSymbols, '');
    const noSelection = target.selectionStart === target.selectionEnd;
    let value = '';
    if (noSelection && target.selectionStart === 0) {
      value = cleanValue + target.value;
    } else if (noSelection && target.selectionStart > 0) {
      value = target.value + cleanValue;
    } else {
      const selection = target.value.slice(target.selectionStart, target.selectionEnd);
      value = target.value.replace(selection, cleanValue);
    }
    if (_.isFinite(+this.maxlength)) value = value.slice(0, +this.maxlength);
    target.value = value;
    this.ngModel.control.setValue(value);
  }
}
