import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { unsubscribeAll } from '../../../core/decorators/index';
import { W2sApiService } from './w2s-api.service';
import { FileService } from '../../../../app/common/services/file/file.service';
var W2sManagementService = /** @class */ (function () {
    function W2sManagementService(apiService, fileService) {
        this.apiService = apiService;
        this.fileService = fileService;
        this.subscriptions = {};
    }
    W2sManagementService.prototype.init = function () {
        this.loading$ = new Subject();
        this.recordAlreadyPublished$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.countsLoaded$ = new Subject();
    };
    W2sManagementService.prototype.destroy = function () {
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.recordAlreadyPublished$.complete();
        this.countsLoaded$.complete();
    };
    W2sManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    W2sManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    W2sManagementService.prototype.subscribeToLoadedCounts = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.countsLoaded$.subscribe(callback);
    };
    W2sManagementService.prototype.subscribeToRecordsAlreadyPublished = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordAlreadyPublished$.subscribe(callback);
    };
    W2sManagementService.prototype.publishLoadedRecords = function (orgLevelId, year) {
        var _this = this;
        this.loading$.next(true);
        var Ids = _.map(this.records.records, function (item) { return (item.w2Document.id); });
        this.apiService.publishRecords(orgLevelId, year, Ids)
            .then(function (updateCount) {
            if (updateCount == 0) {
                _this.recordAlreadyPublished$.next({ "publish": true, "unPublish": false });
                _this.loading$.next(false);
            }
            else {
                _this.loadW2s(orgLevelId, year);
            }
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    W2sManagementService.prototype.loadW2s = function (orgLevelId, year) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getW2sRecords(orgLevelId, year)
            .then(function (w2sRecords) {
            _this.records = w2sRecords;
            _this.recordsLoaded$.next(w2sRecords);
            _this.loading$.next(false);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    W2sManagementService.prototype.downloadW2sDocument = function (exportId, fileName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.downloadW2Document(exportId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, fileName);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        this.loading$.next(false);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    W2sManagementService.prototype.unPublishLoadedRecords = function (orgLevelId, year) {
        var _this = this;
        this.loading$.next(true);
        var Ids = _.map(this.records.records, function (item) { return (item.w2Document.id); });
        this.apiService.unPublishRecords(orgLevelId, year, Ids)
            .then(function (updateCount) {
            if (updateCount == 0) {
                _this.recordAlreadyPublished$.next({ "publish": false, "unPublish": true });
                _this.loading$.next(false);
            }
            else {
                _this.loadW2s(orgLevelId, year);
            }
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    W2sManagementService.prototype.getCompanyCounts = function (orgLevelId, year) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getCompanyCounts(orgLevelId, year)
            .then(function (companyCounts) {
            _this.countsLoaded$.next(companyCounts);
            _this.loading$.next(false);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], W2sManagementService.prototype, "subscriptions", void 0);
    return W2sManagementService;
}());
export { W2sManagementService };
