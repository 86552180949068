import { Department, EmployeeShortInfo, Position } from '../../../organization/models';
import { appConfig } from '../../../../app/app.config';
import * as moment from 'moment';
import { PdfDataStatus } from '../../../reports/enums/pdf-data-status';
var W2sInfo = /** @class */ (function () {
    function W2sInfo() {
    }
    return W2sInfo;
}());
export { W2sInfo };
var W2sRecord = /** @class */ (function () {
    function W2sRecord() {
        this.employee = new EmployeeShortInfo();
        this.department = new Department();
        this.position = new Position();
        this.w2Document = new w2Document();
    }
    Object.defineProperty(W2sRecord.prototype, "approvedStatus", {
        get: function () {
            if (this.w2Document.isApproved == 0 && this.w2Document.status.toLowerCase() !== PdfDataStatus.FAILED.toLowerCase()) {
                return "No";
            }
            else if (this.w2Document.isApproved == 0 && this.w2Document.status.toLowerCase() === PdfDataStatus.FAILED.toLowerCase()) {
                return (this.w2Document.status + ": " + moment(this.w2Document.approvedTime).format(appConfig.dateTimeFormatUS));
            }
            else {
                return moment(this.w2Document.approvedTime).format(appConfig.dateTimeFormatUS);
            }
        },
        enumerable: true,
        configurable: true
    });
    return W2sRecord;
}());
export { W2sRecord };
var w2Document = /** @class */ (function () {
    function w2Document() {
    }
    return w2Document;
}());
export { w2Document };
var Iw2Document = /** @class */ (function () {
    function Iw2Document() {
    }
    return Iw2Document;
}());
export { Iw2Document };
var CompanyCounts = /** @class */ (function () {
    function CompanyCounts() {
    }
    return CompanyCounts;
}());
export { CompanyCounts };
