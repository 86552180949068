import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { ApplicationInsights, SeverityLevel, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { AngularPlugin } from '../../components/angular-plugin/angular-plugin.component';
import { appConfig } from '../../../app.config';
import { User, AppVersionInfo } from '../../../state-model/models/index';
import { SessionService, ErrorHandlingService } from '../../../core/services/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { VersionSubscribeService } from '../../../common/services/index';
import * as i0 from "@angular/core";
import * as i1 from "../application-state-bus/application-state-bus.service";
import * as i2 from "../../../core/services/session/session.service";
import * as i3 from "../../../common/services/technical/version.subscribe.service";
import * as i4 from "../../../core/services/error-handling/error-handling.service";
import * as i5 from "@angular/router";
var ApplicationInsightsProvider = /** @class */ (function () {
    function ApplicationInsightsProvider() {
    }
    ApplicationInsightsProvider.getApplicationInsights = function () {
        return ApplicationInsights;
    };
    ApplicationInsightsProvider.getAngularPlugin = function () {
        return AngularPlugin;
    };
    return ApplicationInsightsProvider;
}());
export { ApplicationInsightsProvider };
var MonitoringService = /** @class */ (function () {
    function MonitoringService(applicationStateBusService, sessionService, versionSubscribeService, errorHandlingService, router) {
        this.applicationStateBusService = applicationStateBusService;
        this.sessionService = sessionService;
        this.versionSubscribeService = versionSubscribeService;
        this.errorHandlingService = errorHandlingService;
        this.router = router;
        this.initialize();
    }
    MonitoringService.prototype.initialize = function () {
        var _a;
        var _this = this;
        if (!appConfig.appInsightInstrumentationKey) {
            return;
        }
        var angularPlugin = new (ApplicationInsightsProvider.getAngularPlugin())();
        this.appInsights = new (ApplicationInsightsProvider.getApplicationInsights())({
            config: {
                instrumentationKey: appConfig.appInsightInstrumentationKey,
                isCookieUseDisabled: true,
                enableAutoRouteTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                distributedTracingMode: DistributedTracingModes.AI,
                extensions: [angularPlugin],
                extensionConfig: (_a = {},
                    _a[angularPlugin.identifier] = { router: this.router },
                    _a),
                correlationHeaderExcludedDomains: ['ekr.zdassets.com']
            }
        });
        this.appInsights.loadAppInsights();
        this.telemetryInitializer();
        this.checkAndInitilizeScope();
        this.applicationStateBusService.login$.subscribe(function () {
            _this.scopeData = undefined;
            _this.checkAndInitilizeScope();
        });
        this.errorHandlingService.exception$.subscribe(function (e) {
            switch (e.severity) {
                case 'fault':
                    _this.trackFaultEvent(e.error);
                    break;
                case 'warning':
                    _this.trackWarningEvent(e.error);
                    break;
                default:
                    _this.trackErrorEvent(e.error);
                    break;
            }
        });
    };
    MonitoringService.prototype.checkAndInitilizeScope = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.scopeData) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getScopeData()];
                    case 1:
                        _a.sent();
                        this.appInsights.setAuthenticatedUserContext(this.scopeData.user.id.toString(), this.scopeData.alias, false);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    MonitoringService.prototype.telemetryInitializer = function () {
        var _this = this;
        var telemetryInitializer = function (envelope) {
            if (!_this.scopeData) {
                return true;
            }
            if (envelope.baseType === 'PageviewData') {
                // https://github.com/microsoft/ApplicationInsights-JS/issues/726
                // issue with hash urls
                if (envelope.baseData) {
                    envelope.baseData.refUri = envelope.baseData.uri;
                }
                if (envelope.ext && envelope.ext.trace) {
                    envelope.ext.trace.name = envelope.baseData.uri;
                }
            }
            envelope.data.userid = _this.scopeData.user.id;
            envelope.data.username = _this.scopeData.user.name;
            envelope.data.alias = _this.scopeData.alias;
            envelope.data.app_version = _this.scopeData.version.appVersion;
            envelope.data.service_version = _this.scopeData.version.serviceVersion;
            envelope.data.db_version = _this.scopeData.version.dbVersion;
            return true;
        };
        this.appInsights.addTelemetryInitializer(telemetryInitializer);
    };
    MonitoringService.prototype.trackFaultEvent = function (error) {
        if (!appConfig.appInsightInstrumentationKey) {
            return;
        }
        this.appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Critical, properties: this.createProperties() });
    };
    MonitoringService.prototype.trackErrorEvent = function (error) {
        if (!appConfig.appInsightInstrumentationKey) {
            return;
        }
        this.appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Error, properties: this.createProperties() });
    };
    MonitoringService.prototype.trackWarningEvent = function (error) {
        if (!appConfig.appInsightInstrumentationKey) {
            return;
        }
        this.appInsights.trackException({ exception: error, severityLevel: SeverityLevel.Warning, properties: this.createProperties() });
    };
    MonitoringService.prototype.createProperties = function () {
        var properties = {};
        if (this.scopeData) {
            properties.userid = this.scopeData.user.id;
            properties.username = this.scopeData.user.name;
            properties.alias = this.scopeData.alias;
            properties.app_version = this.scopeData.version.appVersion;
            properties.service_version = this.scopeData.version.serviceVersion;
            properties.db_version = this.scopeData.version.dbVersion;
        }
        return properties;
    };
    MonitoringService.prototype.trackEvent = function (eventName) {
        if (!appConfig.appInsightInstrumentationKey) {
            return;
        }
        this.appInsights.trackEvent({ name: eventName });
    };
    MonitoringService.prototype.logPageView = function (name, uri) {
        this.appInsights.trackPageView({ name: name, uri: uri });
    };
    MonitoringService.prototype.getScopeData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var version, e_1, isSessionExpired, user, alias;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.scopeData && this.scopeDataInitialized) {
                            return [2 /*return*/, this.scopeData];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.versionSubscribeService.getVersion()];
                    case 2:
                        version = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        if (!version) {
                            version = new AppVersionInfo();
                        }
                        isSessionExpired = this.sessionService.isExpired();
                        if (isSessionExpired) {
                            this.scopeData = {
                                user: new User(0),
                                version: version,
                                alias: ''
                            };
                            return [2 /*return*/, this.scopeData];
                        }
                        user = this.sessionService.getUser();
                        alias = this.sessionService.getAlias();
                        this.scopeData = {
                            user: user,
                            version: version,
                            alias: alias
                        };
                        this.scopeDataInitialized = true;
                        return [2 /*return*/, this.scopeData];
                }
            });
        });
    };
    MonitoringService.prototype.getActivatedComponent = function (snapshot) {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }
        return snapshot.component;
    };
    MonitoringService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MonitoringService_Factory() { return new MonitoringService(i0.ɵɵinject(i1.ApplicationStateBusService), i0.ɵɵinject(i2.SessionService), i0.ɵɵinject(i3.VersionSubscribeService), i0.ɵɵinject(i4.ErrorHandlingService), i0.ɵɵinject(i5.Router)); }, token: MonitoringService, providedIn: "root" });
    return MonitoringService;
}());
export { MonitoringService };
