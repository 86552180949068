import { EmpType } from '../../../organization/models/lookup/index';

export class EmployeeBannerInfo {
  public empoyeeId: number;
  public isAgency: boolean;
  public firstName: string;
  public lastName: string;
  public middleName: string;
  public fullName: string;
  public suffix: string;
  public employeeType: EmpType;
  public position: string;
  public payrollNumber: string;
  public status: string;
  public isTerminated:boolean;
  public isTransferred:boolean;
  public isFutureRehire:boolean;
  public isTransferPending:boolean;
  public isLatestTerminate: boolean;
  public isRehired:boolean;
  public canTransfer: boolean;
  public canTerminate: boolean;
  public canRehire: boolean;
  public canCancelTerminate: boolean;
  public createdThroughTransfer: boolean;
  public isPayrollVisible: boolean;
  public rehireDate: Date;
}

