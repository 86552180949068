import {
  Component,
  Input,
  Output,
  EventEmitter,
  Provider, OnInit, OnDestroy
} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { ModalService } from '../../../../common/services/modal/modal.service';
import { OpenShiftSummary, OpenShiftDetails, IOpenShiftMessageCountChangedEventArgs, ISetOpenShiftCountRequest } from '../../../models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { EditOpenShiftCountComponent } from '../edit-open-shift-count/edit-open-shift-count.component';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { DialogOptions } from '../../../../common/models/dialog-options';
import { OpenShiftManagementManagementService, OpenShiftManagementApiService } from '../../../../scheduler/services/index';
import { OPEN_SHIFT_COUNT_TOKEN } from '../../../../core/models/index';
import { mutableSelect } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-shift-messages-container',
  templateUrl: 'daily-shift-messages-container.component.html',
  styleUrls: ['daily-shift-messages-container.component.scss']
})
export class DailyShiftMessagesContainerComponent implements OnInit, OnDestroy {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  @Input()
  public details: OpenShiftSummary;
  public selectedOrgLevel: OrgLevel;

  private appConfig: IApplicationConfig;
  private orgLevelSubscription: Subscription;
  private modalService: ModalService;
  private openShiftManagementManagementService: OpenShiftManagementManagementService;
  private openShiftManagementApiService: OpenShiftManagementApiService;

  constructor(modalService: ModalService, openShiftManagementManagementService: OpenShiftManagementManagementService, openShiftManagementApiService: OpenShiftManagementApiService) {
    this.modalService = modalService;
    this.openShiftManagementApiService = openShiftManagementApiService;
    this.openShiftManagementManagementService = openShiftManagementManagementService;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        this.selectedOrgLevel = o;
      });
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public onEditOpenShiftsClick(event: MouseEvent, groupDetails: OpenShiftDetails): any {
    event.preventDefault();
    event.stopPropagation();
    let shiftCount: number = groupDetails.openShiftCount;
    let options: DialogOptions = new DialogOptions();
    options.height = 150;
    options.width = 320;
    let resolvedProvidersConf: Provider[] = [
      { provide: DialogOptions, useValue: options },
      { provide: OPEN_SHIFT_COUNT_TOKEN, useValue: shiftCount },
    ];
    let modal: EditOpenShiftCountComponent = this.modalService.globalAnchor.openDialog(
      EditOpenShiftCountComponent,
      'New Shift Count',
      options,
      resolvedProvidersConf, (result: boolean, uniqueId?: string) => {
        if (result) {
          this.changeOpenShiftCount(groupDetails, modal.adjustedShiftCount);
        }
      });
  }

  private changeOpenShiftCount(groupDetails: OpenShiftDetails, value: number): any {
    this.openShiftManagementApiService.setOpenShiftScheduleCycleSummary(
      this.selectedOrgLevel.id,
      groupDetails.dateOn,
      { positionId: groupDetails.position.id, shiftId: groupDetails.shift.id, unitId: groupDetails.unit.id, originalShiftCount: groupDetails.openShiftCount, newShiftCount: value })
      .then((result: any) => {
        let newOpen: number = value - groupDetails.scheduledShiftCount;
        newOpen = newOpen > 0 ? newOpen : 0;
        let shiftCountDiff: number = groupDetails.openShiftCount - value;
        groupDetails.openShiftCount = newOpen;
        groupDetails.calculatedOpenShiftCount = newOpen;
        this.openShiftManagementManagementService.onOpenShiftCountChanged({ dateOn: groupDetails.dateOn, shiftCountDiff: shiftCountDiff });
      });
  }
}
