import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsEducation = /** @class */ (function () {
    function EmployeeSectionsEducation() {
    }
    return EmployeeSectionsEducation;
}());
export { EmployeeSectionsEducation };
var EmployeeSectionsEducations = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsEducations, _super);
    function EmployeeSectionsEducations() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsEducations;
}(EmployeeSectionsBase));
export { EmployeeSectionsEducations };
