import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { ThrottlingChangeEvent, TabMode } from '../../models/index';
import { Assert } from '../../../framework/index';
import { OnPageVisibilityChange, AngularPageVisibilityStateEnum } from 'angular-page-visibility';
import { unsubscribeInService } from '../../decorators/index';

@Injectable()
export class ThrottlingService {

    private lastChange: moment.Moment;

    private toSubscription: Subject<ThrottlingChangeEvent>;

    @unsubscribeInService()
    private visiblityApiSubscription: Subscription;

    constructor() {
        this.toSubscription = new Subject<ThrottlingChangeEvent>();
        this.lastChange = moment();
    }

    @OnPageVisibilityChange()
    public pageVisibility( visibilityState: AngularPageVisibilityStateEnum ) {
      this.onVisibilityChange(AngularPageVisibilityStateEnum[visibilityState]
        === AngularPageVisibilityStateEnum[AngularPageVisibilityStateEnum.VISIBLE]);
    }
    public destroy(): void {
        // See #issueWithAOTCompiler
    }

    public subscribeToModeChange(callback: (event: ThrottlingChangeEvent) => void): Subscription {
        Assert.isNotNull(callback, 'callback');
        return this.toSubscription.subscribe(callback);
    }

    public unsubscribe(s: Subscription): void {
        Assert.isNotNull(s, 'subscription');
        s.unsubscribe();
    }

    public reset(): void {
        this.lastChange = moment();
    }

    private onVisibilityChange(visible: boolean): void {
        if (!visible) {
            this.setBackgroundMode();
        } else {
            this.setFrontMode();
        }
    }

    private setBackgroundMode(): void {
        let range: moment.Range = moment.range(this.lastChange, moment());
        let diff: number = range.diff();
        this.lastChange = moment();

        let event: ThrottlingChangeEvent = new ThrottlingChangeEvent();
        event.mode = TabMode.BACKGROUND;
        event.timeFromChangeMode = diff;
        this.toSubscription.next(event);
    }

    private setFrontMode(): void {
        let range: moment.Range = moment.range(this.lastChange, moment());
        let diff: number = range.diff();
        this.lastChange = moment();

        let event: ThrottlingChangeEvent = new ThrottlingChangeEvent();
        event.mode = TabMode.FRONT;
        event.timeFromChangeMode = diff;
        this.toSubscription.next(event);
    }
}
