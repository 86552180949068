import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';

import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsAttendancePointsDefinitionComponent } from '../employee-sections-attendance-points-definition/employee-sections-attendance-points-definition.component';
import { ScrollWatchService, ISelectableItemContainer, SelectableItemsProducer, DateTimeService, DialogOptions } from '../../../../../common/index';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsAttendanceBuybacks, EmployeeSectionsAttendancePoints, EmployeeSectionsAttendancePointsEntry, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsPerformanceApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';

import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';

import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy
} from '@progress/kendo-data-query';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import * as _ from 'lodash';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-attendance-buybacks',
  templateUrl: 'employee-sections-attendance-buybacks.component.html',
  styleUrls: ['employee-sections-attendance-buybacks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsAttendanceBuybacksComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public set buybacksSubsection (attendancePoints: EmployeeSectionsAttendancePoints) {
    this.buybacks = attendancePoints;
    if (attendancePoints !== null) {
      this.selectableAttendancePoints = this.selectableItemsProducer.produceSelectable<EmployeeSectionsAttendancePointsEntry>(attendancePoints.points);
      this.refreshGrid();
      this.isAnyItemSelected = false;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  @Input()
  public employeeId: number;

  public get form(): AbstractControl {
    return null;
  }

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }
  
  public isAnyItemSelected: boolean;
  public selectableAttendancePoints: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>[];
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;
  public totalPoints: number = 0;
  public totalCurrentPoints: number = 0;
  public pageSize: number = 10;
  public skip: number = 0;

  private buybacks: EmployeeSectionsAttendanceBuybacks;
  private modalService: ModalService;
  private dateTimeService: DateTimeService;
  private employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService;
  private selectableItemsProducer: SelectableItemsProducer;
  private changeDetector: ChangeDetectorRef;

  constructor(
    employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService,
    dateTimeService: DateTimeService,
    selectableItemsProducer: SelectableItemsProducer,
    modalService: ModalService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    changeDetector: ChangeDetectorRef
  ) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsPerformanceApiService, 'employeeSectionsPerformanceApiService');
    Assert.isNotNull(dateTimeService, 'dateTimeService');
    this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
    this.modalService = modalService;
    this.dateTimeService = dateTimeService;
    this.selectableItemsProducer = selectableItemsProducer;
    this.changeDetector = changeDetector;
    this.sort = [{ field: 'item.dateOn.fieldValue', dir: 'desc' }];
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.buybacks;
  }

  protected loadSubsection(): void {

    this.startProgress();
    this.skip = 0;
    
    this.employeeSectionsPerformanceApiService.getPerformanceAttendanceBuybacks(this.employeeId, this.startDate, this.endDate)
      .then((attendancePoints: EmployeeSectionsAttendanceBuybacks) => {
        this.buybacks = attendancePoints;
        this.stopProgress();
        this.selectableAttendancePoints = this.selectableItemsProducer.produceSelectable<EmployeeSectionsAttendancePointsEntry>(this.buybacks.points);
        this.refreshGrid();
        this.isAnyItemSelected = false;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      })
      .catch((error: any) => {
        this.stopProgress();
      });
      
  }

  public onSortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public onPageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }

  public onRemovePointsClicked(): void {
    this.modalService.globalAnchor.openConfirmDialog('Warning', 'The selected buyback(s) will be removed and the total attendance points balance will be recalculated.', (result: boolean) => {
      if (result) {
        let pointIds: number[] = [];
        this.selectableAttendancePoints.forEach((i: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>) => { if (i.selected) pointIds.push(i.item.id); });
        this.doRemove(pointIds);
      }
    });
  }


  public onFilterDateChanged({ startDate, endDate }: IRangeDates): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.loadSubsection();
  }

  public onItemSelectionChanged(): void {
    this.isAnyItemSelected = this.selectableAttendancePoints.some((i: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>) => i.selected);
  }

  private doRemove(pointIds: number[]): void {
    this.startProgress();
    this.employeeSectionsPerformanceApiService.deleteAttendancePoints(this.employeeId, pointIds)
      .then((result: any) => {
        this.stopProgress();
        this.loadSubsection();
      })
      .catch((error: any) => {
        this.stopProgress();
      });
  }

  private refreshGrid(): void {
    if (!this.selectableAttendancePoints) {
      this.gridView = null;
      return;
    }
    let sortedRecords: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>[] = orderBy(this.selectableAttendancePoints, this.sort);
    let pagedRecords: ISelectableItemContainer<EmployeeSectionsAttendancePointsEntry>[] = sortedRecords.slice(this.skip, this.skip + this.pageSize);
    this.gridView = {
      data: pagedRecords,
      total: this.selectableAttendancePoints.length
    };
  }


}

