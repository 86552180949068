import * as tslib_1 from "tslib";
import { ManagementBaseService } from '../../../core/services/index';
var MessageCenterConfigService = /** @class */ (function (_super) {
    tslib_1.__extends(MessageCenterConfigService, _super);
    function MessageCenterConfigService() {
        return _super.call(this) || this;
    }
    MessageCenterConfigService.prototype.switchMessageCenterUrl = function (isMessageCenterCombined) {
        if (isMessageCenterCombined == true) {
            this.messageCenterUrl = "/apps/common/message_center";
        }
        else if (isMessageCenterCombined == false) {
            this.messageCenterUrl = "/apps/common/message_center_sms/sms";
        }
    };
    MessageCenterConfigService.prototype.GetMessageCenterUrl = function () {
        return this.messageCenterUrl;
    };
    return MessageCenterConfigService;
}(ManagementBaseService));
export { MessageCenterConfigService };
