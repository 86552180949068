import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';

@Injectable()
export class TimecardsStorageService {
  private employeesList: number[];
  private currentEmployee: number;
  constructor() {
    this.employeesList = [];
    this.currentEmployee = null;
  }

  public setCurrentEmpId(empId: number): void {
    this.currentEmployee = empId;
  }

  public getPrevEmpId(): number {
    const index: number = _.indexOf(this.employeesList, this.currentEmployee);
    let prevEmployeeId: number = null;
    if (index > 0) {
      const prevId: number = this.employeesList[index - 1];
      prevEmployeeId = prevId;
    }

    return prevEmployeeId;
  }

  public getNextEmpId(): number {
    const listLength: number = this.employeesList.length;
    const index: number = _.indexOf(this.employeesList, this.currentEmployee);
    let nextEmployeeId: number = null;
    if (index !== -1 && index !== listLength - 1) {
      const nextId: number = this.employeesList[index + 1];
      nextEmployeeId = nextId;
    }

    return nextEmployeeId;
  }

  public setEmployeesList(list: number[]): void {
    this.employeesList = list;
  }

  public getEmployeesList(): number[] {
    return this.employeesList.concat();
  }
}
