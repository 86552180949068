import { Injectable } from '@angular/core';
import { IPayloadAction } from '../../../state-model/models/index';
import { WizardActions } from '../../../common/store/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../store/add-employee-wizard/add-employee-wizard.actions';
import { AddEmployeeModel } from '../models/index';
import { EmployeeActivitiesApiService } from '../services/employee-activities/employee-add-api.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/store';
import { AddEmployeeWizardStatus } from '../store/add-employee-wizard/add-employee-wizard.types';
import { ValidationError } from '../../../core/models/index';

@Injectable()
export class AddEmployeeWizardStateEpics {

  private employeeActivitiesApiService: EmployeeActivitiesApiService;
  private ngRedux: NgRedux<IAppState>;
  constructor(employeeActivitiesApiService: EmployeeActivitiesApiService, ngRedux: NgRedux<IAppState>) {
    this.employeeActivitiesApiService = employeeActivitiesApiService;
    this.ngRedux = ngRedux;
  }

  public addEmployee = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const addEmployee$: Observable<IPayloadAction> = action$.filter(({ type }: { type: string }) => type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE);
    return addEmployee$.mergeMap((action: IPayloadAction) => {
        const model: AddEmployeeModel = action.payload;
        return Observable
          .fromPromise(this.employeeActivitiesApiService.addNewEmployee(model))
          .map( (response: any) => {
            return {
              type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS,
              payload: {
                status: AddEmployeeWizardStatus.success,
                newEmpId: response.id,
                payrollNumber : response.payrollNumber,
                model: {...model, payrollNumber : response.payrollNumber}
              }
            };
          })
          .catch((error: any) => {
            if (error instanceof ValidationError) {
              return Observable.of(
                {
                  type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_VALIDATION_ERROR,
                  payload: {
                    status: AddEmployeeWizardStatus.validationFailed
                  }
                });
            }
            return Observable.of(
            {
              type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR,
              payload: {
                status: AddEmployeeWizardStatus.error,
                newEmpId: 0
              }
            });
          });
      });
  }

  public addEmployeeFinish = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    const addEmployee$: Observable<IPayloadAction> = action$.filter(({ type }: { type: string }) =>
    type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_SUCCESS ||  type === AddEmployeeWizardActions.ADD_NEW_EMPLOYEE_ERROR);
    return addEmployee$.mergeMap((action: IPayloadAction) => {
            return Observable.of(
            {
              type: WizardActions.SELECT_NEXT_STEP,
              payload: null
            });
      });
  }
}
