import { Component, Input, Host, ViewChild, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import { EmployeeSectionsI9, I9ListATypes, I9ListCTypes, I9ListBTypes, I9AdditionalInfoTypes } from '../../../models/employee-sections-employment/index';
import { EmployeeSectionsBase } from '../../../models/employee-sections-base';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSectionsTemporalModel } from '../../../models';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-i9',
  templateUrl: 'employee-sections-i9.component.html',
  styleUrls: ['employee-sections-i9.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsI9Component extends EmployeeSectionsBasicComponent {

  @Input()
  public set i9(i9Object: EmployeeSectionsI9) {
    this.empI9 = i9Object;
    this.updateData();
  }
  @Input() public employeeId: number;


  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }
  
  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form', {static: false})
  public ngForm: NgForm;

  public get i9(): EmployeeSectionsI9 {
    return this.empI9;
  }

  public I9ListATypes = I9ListATypes;
  public I9ListBTypes = I9ListBTypes;
  public I9ListCTypes = I9ListCTypes;
  public I9AdditionalInfoTypes = I9AdditionalInfoTypes;

  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;
  private empI9: EmployeeSectionsI9;

  constructor(employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone,
    private changeDetector: ChangeDetectorRef) {
    super(decorator, ngZone);
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.empI9;
  }

  public updateData(): void {
    setTimeout(() => {
      this.changeDetector.detectChanges();
      this.changeDetector.markForCheck();
    }, 0);
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmployeeI9(this.employeeId)
      .then((data: EmployeeSectionsI9) => {
        this.i9 = data;
        this.stopProgress();
      }).catch(() => {
        this.stopProgress();
      });
  }

  protected checkTemporalDirty(): EmployeeSectionsTemporalModel {
    return null;
  }

  protected doSave(): void {
    this.employeeSectionsEmploymentApiService.setEmployeeI9(this.employeeId, this.i9).then((response: any) => {
      this.onActionComplete(true);
    }).catch((reason: any) => {
      this.onActionError(reason);
    });
  }

  public clearI9ListA(): void {
    this.i9.listA.clear();
    this.updateData();
  } 

  public clearI9ListB(): void {
    this.i9.listB.clear();
    this.updateData();
  } 

  public clearI9ListC(): void {
    this.i9.listC.clear();
    this.updateData();
  } 

  public clearI9AdditionalInfo(): void {
    this.i9.additionalInfo.clear();
    this.updateData();
  } 
}
