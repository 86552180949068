import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { ValuePairChartSeriesDataService } from '../../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service';
import { ValuePairChartSeriesModel, ValuePairWidgetConfig, ValuePairChartModel } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';

import * as _ from 'lodash';

export interface StackedChartData {
  color: string;
  value: number;
  model: ValuePairChartModel;
}

export interface LegendData {
  value: number;
  model: ValuePairChartSeriesModel;
}

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-overview-day',
  templateUrl: 'schedule-console-overview-day.component.html',
  styleUrls: ['schedule-console-overview-day.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleConsoleOverviewDayComponent {

  public config: ValuePairWidgetConfig;

  public models: ValuePairChartSeriesModel[];

  @unsubscribe()
  private configLoaded: Subscription;

  @unsubscribe()
  private modelsLoaded: Subscription;

  public constructor(
    private changeDetector: ChangeDetectorRef,
    private dataService: ValuePairChartSeriesDataService
  ) {}

  public ngOnInit(): void {
    this.configLoaded = this.dataService.onConfigLoaded.subscribe((config) => {
      this.config = config;

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.modelsLoaded = this.dataService.onModelsLoaded.subscribe((seriesModels) => {
      const validModels: ValuePairChartSeriesModel[] = [];

      _.forEach(seriesModels, (seriesModel: ValuePairChartSeriesModel) => {
        if (!seriesModel || !seriesModel.models.length ||
          (seriesModel.models[0].rawValue1 === 0 && seriesModel.models[0].rawValue2 === 0)) {
          return;
        }

        validModels.push(seriesModel);
      });

      this.models = validModels;

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public getLegendData(model: ValuePairChartSeriesModel): LegendData {
    return {
      model,
      value: 1,
    };
  }

  public getStackedLeftData(seriesModel: ValuePairChartSeriesModel): StackedChartData {
    const model = seriesModel.models[0];

    if (!model) {
      return null;
    }

    let value: number;

    if (model.input.value1 === model.input.value2) {
      value = model.input.value1;
    } else {
      value = Math.min(model.input.value1, model.input.value2);
    }

    return {
      model,
      value,
      color: model.color.backgroundColor1,
    };
  }

  public getStackedRightData(seriesModel: ValuePairChartSeriesModel): StackedChartData {
    const model = seriesModel.models[0];

    if (!model) {
      return null;
    }

    let value: number;

    if (model.input.value1 === model.input.value2) {
      value = 0;
    } else {
      value = Math.abs(model.input.value2 - model.input.value1);
    }

    return {
      model,
      value,
      color: model.color.backgroundColor2,
    };
  }

  public getLabel = (e: any): string => {
    const dataItem: StackedChartData = e.dataItem;

    if (!dataItem.model) {
      return e.value;
    } else if (dataItem.model.rawValue1 !== dataItem.model.rawValue2) {
      const difference: number = dataItem.model.rawValue1 - dataItem.model.rawValue2;
      return difference > 0 ? '+' + difference : difference.toString();
    }

    return String.fromCharCode(0xf00c);
  }

  public getLabelFont(seriesModel: ValuePairChartSeriesModel): string {
    let font: string;
    const model = seriesModel.models[0];

    if (!model || model.rawValue1 !== model.rawValue2) {
      return 'bold 16px "Helvetica Neue", Helvetica, Arial, sans-serif';
    }

    return '16px Font Awesome\\ 5 Pro';
  }

  public getLegend = (e: any): string => {
    return e.dataItem.model.label;
  }
}
