import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GridComponent } from '@progress/kendo-angular-grid';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { OperationalConsoleStateService } from '../../../services/operational-console/operational-console-state.service';
var OperationalConsoleComponent = /** @class */ (function () {
    function OperationalConsoleComponent(stateService) {
        this.stateService = stateService;
    }
    OperationalConsoleComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], OperationalConsoleComponent.prototype, "application$", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], OperationalConsoleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleComponent.prototype, "orglevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], OperationalConsoleComponent.prototype, "employeeLinkClickSubscription", void 0);
    return OperationalConsoleComponent;
}());
export { OperationalConsoleComponent };
