import { OperationalConsoleApiService } from './operational-console-api.service';
import { Subject } from 'rxjs/Subject';
import { ReportExportType } from '../../../reports/models/report-export-type';
import * as _ from 'lodash';
var OperationalConsoleStateService = /** @class */ (function () {
    function OperationalConsoleStateService(apiService) {
        this.apiService = apiService;
        this.filtersChanged$ = new Subject();
        this.exportAction$ = new Subject();
        this.m_canLoadData = false;
    }
    Object.defineProperty(OperationalConsoleStateService.prototype, "canLoadData", {
        get: function () {
            return this.m_canLoadData;
        },
        set: function (value) {
            this.m_canLoadData = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleStateService.prototype, "currentDate", {
        get: function () {
            return this.m_currentDate;
        },
        set: function (value) {
            this.m_currentDate = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleStateService.prototype, "isLoading", {
        get: function () {
            return this.m_isLoading;
        },
        set: function (value) {
            this.m_isLoading = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleStateService.prototype, "entries", {
        get: function () {
            return this.m_entries;
        },
        set: function (value) {
            this.m_entries = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleStateService.prototype, "orgLevel", {
        get: function () {
            return this.m_orgLevel;
        },
        set: function (value) {
            this.m_orgLevel = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleStateService.prototype, "application", {
        get: function () {
            return this.m_application;
        },
        set: function (value) {
            this.m_application = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperationalConsoleStateService.prototype, "consoleFilters", {
        get: function () {
            return this.m_consoleFilters;
        },
        set: function (filters) {
            if (filters) {
                this.m_consoleFilters = filters;
            }
            else {
                this.m_consoleFilters = [];
            }
        },
        enumerable: true,
        configurable: true
    });
    OperationalConsoleStateService.prototype.loadData = function () {
        var _this = this;
        if (!this.m_canLoadData) {
            return;
        }
        this.isLoading = true;
        var unitIds = _.map(this.m_unitFilter, function (item) { return item.id; });
        var shiftIds = _.map(this.m_shiftFilter, function (item) { return item.id; });
        this.apiService.getOperationalConsole(this.orgLevel.id, this.currentDate, unitIds, shiftIds)
            .then(function (entries) {
            _this.entries = _this.applyFilters(entries);
            _this.filtersChanged$.next(_this.entries);
            _this.isLoading = false;
        });
    };
    OperationalConsoleStateService.prototype.applyFilters = function (entries) {
        if (!this.consoleFilters || this.consoleFilters.length === 0) {
            return entries;
        }
        var filtersNames = _.map(this.consoleFilters, function (filter) { return filter.name; });
        return _.filter(entries, function (item) {
            return item.orgLevel ? _.includes(filtersNames, item.orgLevel.name) : true;
        });
    };
    OperationalConsoleStateService.prototype.overviewFiltersChanged = function (unitFilter, shiftFilter) {
        this.m_unitFilter = unitFilter;
        this.m_shiftFilter = shiftFilter;
    };
    OperationalConsoleStateService.prototype.onExportToExcel = function () {
        this.exportAction$.next(ReportExportType.Excel);
    };
    OperationalConsoleStateService.prototype.onExportToPdf = function () {
        this.exportAction$.next(ReportExportType.Pdf);
    };
    return OperationalConsoleStateService;
}());
export { OperationalConsoleStateService };
