<slx-spinner [show]="isLoading">
  <form novalidate #form="ngForm">
    <div class="modal-body mcontent">
      <label>Add Policies below:</label>
      <div class="row-item-container">
        <div class="ln-height" *ngFor="let dap of dynamicAccrualPolicy; let idx=index">
          <div class="col-lg-10">
            <slx-input-decorator>
              <select slx-input name="accrualPolicy{{idx}}" [(ngModel)]="dap.policyId" (change)="policyChanged(dap, $event.target.value)">
                <option *ngFor="let acc of accrualPolicyLookup" value={{acc.id}}>
                  {{acc.policyName}}
                </option>
              </select>
              <div class="error text-danger" errorMessage for="error" *ngIf="dap.isError"><span><i class="fa fa-info-circle" aria-hidden="true"></i> Policies can not have duplicate existing types</span></div>
            </slx-input-decorator>
          </div>
          <div class="col-lg-2 delete-btn">
            <span (click)="onDeletePolicy(dap.id)"><i class="fa fa-trash" aria-hidden="true"></i></span>
          </div>
        </div>
      </div>
      <div class="clear">
        <button class="theme-iconed-accent-button pull-left " (click)="onAddPolicy()">
          <span class="icon-button-content"><i class="fa fa-plus" aria-hidden="true"></i>Add Policy</span>
        </button>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r pull-left">Cancel</button>
      <button type="button" (click)="onOk()" [disabled]="isPolicyDisabled" class="theme-button-apply pull-right btn-dynamic-policy">Apply</button>
    </div>
  </form>
</slx-spinner>
