<form novalidate #form="ngForm">
    <div class="col-xs-12">
      <div class="emp-title-box">
        <span class="theme-header-text">{{employee.fullName}} </span>
      </div>
    </div>
    <div class="col-xs-12">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input
          [(ngModel)]="requestToTerminate.date"
          [minDate]="minDate"
          [required]="true"
          placeholder="Date of Termination"
          name="date"
        ></slx-date-picker-ngx>
        <span errorMessage for="required"></span>
        <span errorMessage for="minDate">Field cannot be less than last approved pay cycle {{employeeShortInfo?.effectiveDate | amDateFormat: appConfig.dateFormat }}</span>
      </slx-input-decorator>
    </div>

  </form>
  