import * as _ from 'lodash';
import { BenefitDetailsState } from './benefit-details-state';
import { BenefitDetailsProvider } from './benefit-details-provider';

export class BenefitDetailsProviderEntity extends BenefitDetailsState {
  public current: BenefitDetailsProvider;
  public selectedLineId: number = null;
  public requireUpdate = true;

  private stored: BenefitDetailsProvider = null;
  private draft = false;

  public get id(): number {
    return this.current.id;
  }

  public get providerName(): string {
    return this.current.shortName;
  }

  public get storedProviderName(): string {
    return this.current.storedShortName;
  }

  public get isDraft(): boolean {
    return this.draft;
  }

  public get isStored(): boolean {
    return _.isObjectLike(this.stored);
  }

  public markAsDraft(): void {
    this.draft = true;
  }

  public storeEntity(): BenefitDetailsProviderEntity {
    this.stored = _.cloneDeep(this.current);

    return this;
  }

  public dropStored(): void {
    this.stored = null;
  }

  public restoreEntity(): BenefitDetailsProviderEntity {
    this.current = _.cloneDeep(this.stored);
    this.stored = null;

    return this;
  }
}
