import * as _ from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardLMAlertEnum, DashboardAppTypeEnum } from '../../../../portal/models/index';
import { ApplicationDashboardItem } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { ApplicationDashboardApiService, ApplicationDashboardManagementService } from '../../../services/index';
import { LmNavigationService } from '../../../../common/services/navigation/index';
var LeaveManagementDashboardComponent = /** @class */ (function () {
    function LeaveManagementDashboardComponent(applicationDashboardApiService, appDashboardMngtService, router, activatedRoute) {
        this.applicationDashboardApiService = applicationDashboardApiService;
        this.appDashboardMngtService = appDashboardMngtService;
        this.navigationService = new LmNavigationService(router, activatedRoute);
    }
    Object.defineProperty(LeaveManagementDashboardComponent.prototype, "orgLevel", {
        set: function (value) {
            if (!value || !value.id)
                return;
            this.loadCounters(value.id);
        },
        enumerable: true,
        configurable: true
    });
    LeaveManagementDashboardComponent.prototype.loadAlerts = function () {
        var _this = this;
        this.appDashboardMngtService.getDashboardAppAlerts(DashboardAppTypeEnum.lmAlerts)
            .then(function (data) {
            _this.alertConfigOrderAndVisibilty = _.filter(data, function (t) { return t.visible; });
            _this.setCounter();
        });
    };
    LeaveManagementDashboardComponent.prototype.onHasChanges = function (hasChanges) {
        if (hasChanges) {
            this.loadAlerts();
        }
    };
    LeaveManagementDashboardComponent.prototype.loadCounters = function (orglevelId) {
        var _this = this;
        this.progressbar = true;
        this.applicationDashboardApiService.getLoaRequestCounters(orglevelId)
            .then(function (res) {
            _this.counters = res;
            _this.loadAlerts();
        }).finally(function () {
            _this.progressbar = false;
        });
    };
    LeaveManagementDashboardComponent.prototype.navigate = function () {
        this.navigationService.navigateToConsole();
    };
    LeaveManagementDashboardComponent.prototype.setCounter = function () {
        var _this = this;
        var appAlerts = this.alertConfigOrderAndVisibilty;
        _.forEach(appAlerts, function (record) {
            if (record.alertId == DashboardLMAlertEnum.onLeave) {
                record.counter = _this.counters ? _this.counters.onLeaveCount : 0;
            }
            else if (record.alertId == DashboardLMAlertEnum.returning) {
                record.counter = _this.counters ? _this.counters.returningCount : 0;
            }
            else if (record.alertId == DashboardLMAlertEnum.upcoming) {
                record.counter = _this.counters ? _this.counters.upcomingCount : 0;
            }
            else if (record.alertId == DashboardLMAlertEnum.incomplete) {
                record.counter = _this.counters ? _this.counters.incompleteCount : 0;
            }
            else if (record.alertId == DashboardLMAlertEnum.pastDue) {
                record.counter = _this.counters ? _this.counters.pastDueCount : 0;
            }
        });
    };
    return LeaveManagementDashboardComponent;
}());
export { LeaveManagementDashboardComponent };
