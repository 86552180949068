import * as _ from 'lodash';
import { Component, OnDestroy, OnInit, Provider, ViewChild } from '@angular/core';
import { SelectableSettings, GridComponent, GridDataResult, SelectionEvent, RowArgs } from '@progress/kendo-angular-grid';
import { NewEnrollmentModel, BenefitsEmpEnrollOptionRate, BenefitTierDefinition } from '../../../../models';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { ModalService } from '../../../../../../common/services';
import { CalculationCommonService } from '../../../../../../app-modules/benefits/services/benefit-details/calculation-common.service';
import { DialogOptions, IDialog, KendoGridStateHelper, DialogOptions2, DialogModeSize } from '../../../../../../common/models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { NgForm } from '@angular/forms';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { process, SortDescriptor, State } from '@progress/kendo-data-query';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'slx-enroll-employee-select-coverage-popup',
  templateUrl: './enroll-employee-select-coverage-popup.component.html',
  styleUrls: ['./enroll-employee-select-coverage-popup.component.scss']
})
export class EnrollEmployeeSelectCoveragePopupComponent implements OnInit, OnDestroy, IDialog {
  //#region configuration
  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<BenefitsEmpEnrollOptionRate>;
  public gridView: GridDataResult;
  public minAmt = 0;
  public maxAmt = 99999999;
  public stepcurrency = 1;
  public currencyFormat = 'c2';
  //#endregion

  public isLoading: boolean;
  public hasSelected: boolean;
  public selectableSettings: SelectableSettings;
  public coverageOptions: BenefitsEmpEnrollOptionRate[];
  public filteredCoverageOptions: BenefitsEmpEnrollOptionRate[];
  public selectedCoverageOption: BenefitsEmpEnrollOptionRate;

  public selectedTier: BenefitTierDefinition;
  public tiers: BenefitTierDefinition[];

  public groupName: string;
  public effectiveDate: Date;
  public payrollDedStartDate: Date;
  public payrollDedEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;
  public startDate: Date;
  public endDate: Date;
  public canMapPayroll: boolean;
  public hasDedError: boolean = false;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public dialogResult: boolean;
  public showTooltipOnDialogWarning: boolean;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  public static openDialog(
    model: NewEnrollmentModel,
    apiService: EmployeeSectionsBenefitsManagementApiService,
    modalService: ModalService,
    calcService: CalculationCommonService,
    callback?: (result: boolean) => void
  ): EnrollEmployeeSelectCoveragePopupComponent {


    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.width = 850;
    dialogOptions.height = 450;
    
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ModalService,
        useValue: modalService
      },
      {
        provide: NewEnrollmentModel,
        useValue: model
      },
      {
        provide: EmployeeSectionsBenefitsManagementApiService,
        useValue: apiService
      },
      {
        provide: CalculationCommonService,
        useValue: calcService
      }
    ];

    const title = `Enroll Employee in Benefits`;
    let component = modalService.globalAnchor.openDialog2(
      EnrollEmployeeSelectCoveragePopupComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    return component;

  }

  constructor(
    public model: NewEnrollmentModel,
    private apiService: EmployeeSectionsBenefitsManagementApiService,
    private options: DialogOptions,
    private modalService: ModalService,
    private calculationService: CalculationCommonService,
    private man: BenefitsEnrollmentSectionManagementService
  ) {
    this.hasSelected = false;
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<BenefitsEmpEnrollOptionRate>();
    this.selectableSettings = {
      checkboxOnly: false,
      enabled: true,
      mode: 'single'
    };
  }
  public get hasTierOptRates(): boolean {
    if (this.selectedCoverageOption) {
      return this.checkCoverageOptionValidity();
    }
    return true;
  }

  public checkCoverageOptionValidity() {
    return _.isNumber(this.selectedCoverageOption.tierOptionErContribution) &&
      _.isNumber(this.selectedCoverageOption.tierOptionEmpContribution) &&
      _.isNumber(this.selectedCoverageOption.tierOptionCost) &&
      (_.isFinite(this.selectedCoverageOption.id) && this.selectedCoverageOption.id != 0)
  }

  public ngOnInit(): void {

    this.isLoading = true;
    this.groupName = this.model.plan.name;
    this.effectiveDate = this.model.effectiveDate;
    this.payrollDedStartDate = this.model.effectiveDate;
    this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
    this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
    this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
    this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
    this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
    this.startDate =  _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
    this.endDate = this.model.plan.endDate;
    this.canMapPayroll = this.model.canMapPayroll;
    this.apiService.getBenefitPlanOptionRates(this.model.plan.benefitLineId)
      .then((options: BenefitsEmpEnrollOptionRate[]) => {

        this.coverageOptions = options;

        let tiers = _.map(this.coverageOptions, o => {
          let d = new BenefitTierDefinition();
          d.id = o.tierId;
          d.name = o.tierName;
          return d;
        });

        this.tiers = _.uniqBy(tiers, t => t.id);

        this.filteredCoverageOptions = [];
        this.refreshGrid();

        this.isLoading = false;
      });
    this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate((hasDedError: boolean) => {
      this.hasDedError = hasDedError;
    });
  }

  ngOnDestroy(): void {
  }

  public onSelectionChange(event: SelectionEvent): void {
    this.gridState.selectionChange(event);
    let selectedRecords: BenefitsEmpEnrollOptionRate[] = _.map(event.selectedRows, (args: RowArgs) => args.dataItem);
    if (selectedRecords && _.size(selectedRecords) > 0) {
      this.selectedCoverageOption = _.first(selectedRecords);
    }
    this.hasSelected = this.selectedCoverageOption != null;

    if(!this.checkCoverageOptionValidity()){
      this.showWarning();
    }
  }

  public onCancel(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved selections.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.dialogResult = false;
          this.modalService.closeWindow(this.options.windowUniqueId);
        }
      },
      options);
  }

  public showWarning(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = false;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Warning',
      'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.',
      this.modalService,
      (result: boolean) => { },
      options);
  }

  public onEnroll(): void {
    this.model.selectedCoverageOption = this.selectedCoverageOption;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onStartEdit(item: BenefitsEmpEnrollOptionRate): void {
    this.selectedCoverageOption = item;
    this.hasSelected = this.selectedCoverageOption != null;
    this.refreshGrid();
  }

  public isRowSelected = (e: RowArgs): boolean => e.dataItem === this.selectedCoverageOption;

  public onChangeTier(t: BenefitTierDefinition): void {
    this.showTooltipOnDialogWarning = false;
    this.selectedTier = t;
    if (this.selectedTier && this.coverageOptions) {
      this.filteredCoverageOptions = _.filter(this.coverageOptions, o => o.tierId === this.selectedTier.id);
      if(_.size(this.filteredCoverageOptions) === 1 && this.filteredCoverageOptions[0].id === 0){
        this.showTooltipOnDialogWarning = true;
        this.showWarning();
      }
    } else {
      this.filteredCoverageOptions = [];
    }
    if (this.selectedCoverageOption != null) {
      let filterSelectedOption: BenefitsEmpEnrollOptionRate[] = _.filter(this.filteredCoverageOptions, o => o.id === this.selectedCoverageOption.id);
      this.hasSelected = !_.isEmpty(filterSelectedOption);
    }

    this.refreshGrid();
  }

  public onChangeEmpContribution(dataItem: BenefitsEmpEnrollOptionRate): void {
    let employeeContribution = 0;
    if (dataItem.tierOptionEmpContribution > 0) {
      employeeContribution = this.calculationService.getConvertedEmployeeContribution(dataItem.costFreq, dataItem.empContFreq, dataItem.tierOptionEmpContribution);
    }
    dataItem.tierOptionCost = dataItem.tierOptionErContribution + employeeContribution;
  }

  private refreshGrid(): void {
    if (!this.coverageOptions) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.filteredCoverageOptions, this.gridState.state);
  }

  public getOptRatesToolTip(): string {
    return 'Employee cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll.';
  }

  public OnDeductionEndDateChange(date: Date) {
    this.model.plan.payrollDeductionEndDate = date;
  }
  public OnDeductionStartDateChange(date: Date) {
    this.model.plan.payrollDeductionStartDate = date;
  }
}
