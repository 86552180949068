import { FieldData, Actions } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsAuditTrail {
  records: IEmployeeSectionsAuditTrailRecord[];
}
export interface IEmployeeSectionsAuditTrailRecord {
  id: number;
  type: 'note'|'journal'|'edit';
  subject: string;
  detail: string;
  user: string;
  changedOn: Date;
}
export interface IEmployeeSectionsAuditTrailNoteRecord extends IEmployeeSectionsAuditTrailRecord {
  isPublic: boolean;
}
export interface IEmployeeSectionsAuditTrailJournalRecord extends IEmployeeSectionsAuditTrailRecord {
  effectiveDate: Date;
  before: string;
  after: string;
}
export interface IEmployeeSectionsAuditTrailEditRecord extends IEmployeeSectionsAuditTrailRecord {
  effectiveDate: Date;
  before: string;
  after: string;
  fieldName: string;
}


export class EmployeeSectionsAuditTrail extends EmployeeSectionsBase {
  public static dataMock: IEmployeeSectionsAuditTrail = {
    records: [
      {
        id: 124,
        type: 'note',
        subject: 'subject1',
        detail: 'detail1',
        user: 'user1',
        changedOn: new Date('2016-09-01')
      }
    ]
  };
  public records: EmployeeSectionsAuditTrailRecord[];
  constructor() {
    super();
    this.records = [];
  }
}
export class EmployeeSectionsAuditTrailRecord {
  public id: number;
  public type: 'note'|'journal'|'edit';
  public typeDescription: string;
  public subject: FieldData<string>;
  public detail: FieldData<string>;
  public user: string;
  public changedOn: Date;

}
export class EmployeeSectionsAuditTrailNoteRecord extends EmployeeSectionsAuditTrailRecord {
  public isPublic: boolean;
}
export class EmployeeSectionsAuditTrailJournalRecord extends EmployeeSectionsAuditTrailRecord {
  public effectiveDate: Date;
  public before: FieldData<string>;
  public after: FieldData<string>;
}
export class EmployeeSectionsAuditTrailEditRecord extends EmployeeSectionsAuditTrailRecord {
  public effectiveDate: Date;
  public before: FieldData<string>;
  public after: FieldData<string>;
}

