import * as tslib_1 from "tslib";
import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import { OnInit, Provider, OnDestroy } from '@angular/core';
import { DialogOptions } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { MassAssignPayUnitsRequest } from '../../models/index';
import { unsubscribeAll } from '../../../../core/decorators/index';
var MassAssignmentPayUnitsDialogComponent = /** @class */ (function () {
    function MassAssignmentPayUnitsDialogComponent(options, modalService, request) {
        this.subscriptions = {};
        this.modalService = modalService;
        this.options = options;
        this.request = request;
    }
    MassAssignmentPayUnitsDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: MassAssignPayUnitsRequest,
                useValue: request
            }
        ];
        var title = 'Mass Assign Pay Units';
        var dialog = modalService.globalAnchor
            .openDialog2(MassAssignmentPayUnitsDialogComponent, title, dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, undefined);
        });
        return dialog;
    };
    MassAssignmentPayUnitsDialogComponent.prototype.ngOnInit = function () {
    };
    MassAssignmentPayUnitsDialogComponent.prototype.onClose = function (res) {
        this.dialogResult = res;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    MassAssignmentPayUnitsDialogComponent.prototype.ngOnDestroy = function () { };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MassAssignmentPayUnitsDialogComponent.prototype, "subscriptions", void 0);
    return MassAssignmentPayUnitsDialogComponent;
}());
export { MassAssignmentPayUnitsDialogComponent };
