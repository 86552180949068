import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { ColumnSettingsStorageService, ColumnManagementService, ComponentStateStorageService, StateManagementService } from '../../../../common/services/index';
import { LMRequestMapperService } from '../../services/lm-request-mapper/lm-request-mapper.service';
import { mutableSelect } from '../../../../core/decorators/index';
import { IDateRange, DateRange, StateResetTypes } from '../../../../core/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { LoaRosterState, LoaSecurityActions } from '../../models/index';
import { LMApiService } from '../lm-api.service';
import { LMManagementService } from '../lm-management.service';
var LMRosterManagementService = /** @class */ (function () {
    function LMRosterManagementService(requestMapper, apiService, columnSettingsStorageService, columnManagementService, storageService, stateManagement, managementService) {
        this.requestMapper = requestMapper;
        this.apiService = apiService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.columnManagementService = columnManagementService;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.managementService = managementService;
        this.subscriptions = {};
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.loaRequestsLoaded$ = new ReplaySubject(1);
        this.state$ = new ReplaySubject(1);
        this.viewMode$ = new Subject();
        this.calendarMode$ = new Subject();
        this.loading$ = new Subject();
        this.categoryFilter$ = new ReplaySubject(1);
        this.dateRange$ = new ReplaySubject(1);
        this.exportTo$ = new Subject();
        this.routeFilters$ = new ReplaySubject();
        this.viewListState$ = new Subject();
        this.viewDayState$ = new Subject();
        this.columnsVisibilityKey = 'columnsVisibility';
        this.oneDayMs = 1000 * 60 * 60 * 24;
        this.maxDays = 30;
        this.viewCalender = 'viewCalender';
        this.viewDay = 'viewDay';
        this.resetBy = StateResetTypes.SessionEnd;
        var now = new Date();
        this.defaultStartDate = now;
        this.defaultEndDate = new Date(now.getTime() + (this.oneDayMs * this.maxDays));
    }
    LMRosterManagementService.prototype.init = function (componentId) {
        this.componentId = componentId;
        this.changeDateRange(new DateRange(this.defaultStartDate, this.defaultEndDate), false);
        this.restoreState();
        this.subscribeToData();
    };
    LMRosterManagementService.prototype.destroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMRosterManagementService.prototype.changeRouteData = function (routeData) {
        this.updateFilter();
        if (routeData) {
            var filters = _.find(_.values(routeData), function (d) { return _.isObjectLike(d) && (d.category || d.loaType || d.action || d.empId); });
            this.updateFilter(_.get(filters, 'category') || '', _.get(filters, 'loaType') || '', _.get(filters, 'action') || '', _.get(filters, 'empId') || NaN);
        }
        this.routeFilters$.next(this.filter);
        this.subscribeToOrgLevel();
    };
    LMRosterManagementService.prototype.subscribeToRouteFilter = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.routeFilters$.subscribe(callback);
    };
    LMRosterManagementService.prototype.subscribeToOrgLevelChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    LMRosterManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    LMRosterManagementService.prototype.subscribeToRequestsLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loaRequestsLoaded$.subscribe(callback);
    };
    LMRosterManagementService.prototype.changeDateRange = function (r, reloadRequests) {
        if (reloadRequests === void 0) { reloadRequests = true; }
        if (moment(r.startDate).isSame(this.sDate) && moment(r.endDate).isSame(this.eDate))
            return;
        this.sDate = r.startDate;
        this.eDate = r.endDate;
        this.dateRange$.next(r);
        if (reloadRequests) {
            this.loadLoaRequests();
        }
    };
    LMRosterManagementService.prototype.subscribeToDateRange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dateRange$.subscribe(callback);
    };
    LMRosterManagementService.prototype.changeViewMode = function (isListView) {
        var _this = this;
        this.loading$.next(true);
        setTimeout(function () {
            _this.loading$.next(false);
        }, 500);
        this.viewMode$.next(isListView);
        this.saveListViewState(isListView);
    };
    LMRosterManagementService.prototype.subscribeToViewMode = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.viewMode$.subscribe(callback);
    };
    LMRosterManagementService.prototype.mapRequests = function (requests) {
        var _this = this;
        return _.map(requests, function (r) { return _this.requestMapper.mapRequest(r); });
    };
    LMRosterManagementService.prototype.changeCalenderMode = function (isDay) {
        this.calendarMode$.next(isDay);
        this.saveCalanderViewState(isDay);
    };
    LMRosterManagementService.prototype.subscribeToCalenderMode = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.calendarMode$.subscribe(callback);
    };
    LMRosterManagementService.prototype.changeCategoryFilter = function (filters) {
        this.categoryFilter$.next(filters);
    };
    LMRosterManagementService.prototype.subscribeToCategoryFilter = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.categoryFilter$.subscribe(callback);
    };
    LMRosterManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    LMRosterManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    LMRosterManagementService.prototype.saveState = function () {
        this.columnSettingsStorageService.setColumnsState(this.componentId, this.columnsVisibilityKey, this.state.columns);
    };
    LMRosterManagementService.prototype.changeState = function (s) {
        this.state = s;
        this.state$.next(s);
    };
    LMRosterManagementService.prototype.subscribeToChangeState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.state$.subscribe(callback);
    };
    LMRosterManagementService.prototype.subscribeToChangeListViewState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.viewListState$.subscribe(callback);
    };
    LMRosterManagementService.prototype.subscribeToChangeDayViewState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.viewDayState$.subscribe(callback);
    };
    LMRosterManagementService.prototype.loadLoaRequestsByOrgLevel = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.loadRequestsByOrgLevel(this.orgLevel)];
            });
        });
    };
    LMRosterManagementService.prototype.loadLoaRequests = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.loadRequests(this.orgLevel, this.sDate, this.eDate)];
            });
        });
    };
    LMRosterManagementService.prototype.loadRequestsByOrgLevel = function (orgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!_.isFinite(_.get(orgLevel, 'id')))
                    return [2 /*return*/];
                this.loading$.next(true);
                return [2 /*return*/, this.apiService.getLoaRequestsByOrgLevel(orgLevel.id)
                        .then(function (res) {
                        var actions = res.actions;
                        _this.setActions(actions);
                        var data = res.data;
                        _this.changeDateRange(new DateRange(data.start, data.end), false);
                        _this.loaRequestsLoaded$.next(data);
                        _this.loading$.next(false);
                        return data.requests;
                    })
                        .catch(function (err) {
                        _this.loading$.next(false);
                        console.error(err);
                        return [];
                    })];
            });
        });
    };
    LMRosterManagementService.prototype.setActions = function (actions) {
        this.managementService.canDelete = _.findIndex(actions, function (x) { return x == LoaSecurityActions.canDelete; }) >= 0;
        this.managementService.canAddEdit = _.findIndex(actions, function (x) { return x == LoaSecurityActions.canAddEdit; }) >= 0;
    };
    LMRosterManagementService.prototype.loadRequests = function (orgLevel, sDate, eDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (!_.isFinite(_.get(orgLevel, 'id')) || !_.isDate(sDate) || !_.isDate(eDate))
                    return [2 /*return*/];
                this.loading$.next(true);
                return [2 /*return*/, this.apiService.getLoaRequests(orgLevel.id, sDate, eDate)
                        .then(function (cont) {
                        var actions = cont.actions;
                        _this.setActions(actions);
                        _this.loaRequestsLoaded$.next(cont.data);
                        _this.loading$.next(false);
                        return cont.data.requests;
                    })
                        .catch(function (err) {
                        _this.loading$.next(false);
                        console.error(err);
                        return [];
                    })];
            });
        });
    };
    LMRosterManagementService.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        if (!this.subscriptions.orgLevel) {
            this.subscriptions.orgLevel = this.orgLevel$
                .filter(function (o) { return o && _.isFinite(o.id); })
                .subscribe(function (orgLevel) {
                if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                    return;
                _this.orgLevel = orgLevel;
                _this.orgLevelChanged$.next(_this.orgLevel);
            });
        }
    };
    LMRosterManagementService.prototype.subscribeToData = function () {
        var _this = this;
        this.subscriptions.data = this.routeFilters$
            .combineLatest(this.orgLevelChanged$)
            .subscribe(function () {
            if (_.size(_this.filter.category) > 0 || _.size(_this.filter.loaType) > 0) {
                _this.loadLoaRequestsByOrgLevel();
                _this.updateFilter();
            }
            else {
                _this.loadLoaRequests();
            }
        });
    };
    LMRosterManagementService.prototype.saveListViewState = function (isList) {
        this.storageService.setControlState(this.componentId, this.viewCalender, { value: isList }, this.resetBy);
    };
    LMRosterManagementService.prototype.saveCalanderViewState = function (isDay) {
        this.storageService.setControlState(this.componentId, this.viewDay, { value: isDay }, this.resetBy);
    };
    LMRosterManagementService.prototype.restoreState = function () {
        var _this = this;
        var state = new LoaRosterState();
        state.createColumns();
        this.stateManagement.init(this.componentId, true);
        this.columnManagementService.init(this.componentId);
        this.columnManagementService.initializeGroupWithColumns(this.columnsVisibilityKey, state.columns);
        this.subscriptions.viewListState = this.stateManagement.onInit$
            .subscribe(function () {
            var state = _this.storageService.getControlState(_this.componentId, _this.viewCalender);
            var value = _.isBoolean(state.value) ? state.value : true;
            _this.viewListState$.next(value);
            _this.changeViewMode(value);
        });
        this.subscriptions.viewDayState = this.stateManagement.onInit$
            .subscribe(function () {
            var state = _this.storageService.getControlState(_this.componentId, _this.viewDay);
            var value = _.isBoolean(state.value) ? state.value : false;
            _this.viewDayState$.next(value);
            _this.changeCalenderMode(value);
        });
        this.subscriptions.state = this.columnManagementService.groupInitialized$
            .filter(function (event) { return event.group === _this.columnsVisibilityKey; })
            .subscribe(function (event) {
            _this.changeState(state);
        });
    };
    LMRosterManagementService.prototype.updateFilter = function (category, loaType, action, empId) {
        if (category === void 0) { category = ''; }
        if (loaType === void 0) { loaType = ''; }
        if (action === void 0) { action = ''; }
        if (empId === void 0) { empId = NaN; }
        this.filter = {
            category: category,
            loaType: loaType,
            action: action,
            empId: empId
        };
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], LMRosterManagementService.prototype, "orgLevel$", void 0);
    return LMRosterManagementService;
}());
export { LMRosterManagementService };
