<slx-spinner [show]="isLoading">
  <div class="empty-thread" *ngIf="!!!SlateMessageGroupInfo">
    <div class="envelope"><em class="far fa-envelope"></em></div>
    <span class="blank-screen-text">
      Select the message to view the <br>
      thread or respond.
    </span>
  </div>
  <div *ngIf="!!SlateMessageGroupInfo">
    <div class="message-content right">
      <div class="msg-list">
        <kendo-grid #kendoGrid [height]="318" class="slx-msg-grid" [data]="gridState?.view">
          <kendo-grid-column>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <div class="header-container">
                <div class="subject-text" title="{{SlateMessageGroupInfo.subject}}">{{SlateMessageGroupInfo.subject}}</div>
                <div class="sentto-container">
                  <span class="label">Sent to:</span>
                  <div *ngFor="let employee of SlateMessageGroupInfo.employees; let i = index ">
                    <div class="sentto-names" *ngIf="i <= 2">
                        {{employee.employeeName}}
                    </div>
                  </div>
                  <div class="sentto-names" *ngIf="SlateMessageGroupInfo.employees.length > 3">
                    <div  [popover]="moreEmployeesDisplay">
                      +{{SlateMessageGroupInfo.employees.length - 3}} More
                    </div>
                    <popover-content #moreEmployeesDisplay placement="auto bottom">
                      <span *ngFor="let employee of SlateMessageGroupInfo.employees; let i = index">
                        <span class="popover-employee-names" *ngIf="i  >  2">{{employee.employeeName}}</span>
                      </span>
                    </popover-content>
                  </div>
                </div>
              </div>
              <div id="msg-list">
                <div *ngFor="let message of SlateMessageGroupInfo.messages; let messageIndex = index;">
                  <div class="message-box">
                    <div class="circle flex">
                      <div class="text">
                        {{message.createdByDisplayName?.split('')[0]}}
                      </div>
                    </div>
                    <div class="status">
                      <span>{{message.createdDate  | convertDate}}</span>
                      <span class="sentby-text">{{(currentUserName != null || currentUserName != '') ? (currentUserName == message.createdByDisplayName) ? 'Me': message.createdByDisplayName : message.createdByDisplayName}}</span>
                    </div>
                    <div class="message-container">
                      <div class="message">
                        {{message.messageContent}}
                      </div>
                    </div>
                    <div class="status seen-status" *ngIf="isAnyoneSeen(message)">
                      <span [innerHTML]="getSeenByString(message)"></span><span *ngIf="canShowMoreButton(message)" [popover]="moreSeenEmployees"> +More </span> {{getLatestSeenDatetime(message) | convertDate}}
                    </div>
                    <popover-content #moreSeenEmployees placement="auto bottom">
                      <div>{{getMoreEmployeesStrings(message)}}</div>
                    </popover-content>
                  </div>
                </div>
                <div *ngIf="!!currentDateMessages && currentDateMessages.length > 0">
                  <div class="line" *ngIf="isCurrentDateMessage(message)">TODAY</div>
                  <div class="message-box">
                    <div class="circle flex">
                      <div class="text">
                        {{message.createdByDisplayName?.split('')[0]}}
                      </div>
                    </div>
                    <div class="status">
                      <span>{{message.createdDate  | convertDate}}</span>
                      <span class="sentby-text">{{message.createdByDisplayName}}</span>
                    </div>
                    <div class="message-container">
                      <div class="message">
                        {{message.messageContent}}
                      </div>
                    </div>
                    <div class="status seen-status" *ngIf="isAnyoneSeen(message)">
                      <span [innerHTML]="getSeenByString(message)"></span><span *ngIf="canShowMoreButton(message)" [popover]="moreSeenEmployees"> +More </span> {{getLatestSeenDatetime(message) | convertDate}}
                    </div>
                    <popover-content #moreSeenEmployees placement="auto bottom">
                      <div>{{getMoreEmployeesStrings(message)}}</div>
                    </popover-content>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
</slx-spinner>

