import * as _ from 'lodash';
import randomColor from 'randomcolor';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { LMTypeApiService } from '../../services/index';
import { LoaTypeModelContainer, LoaTypeModel, LoaTypeEditResult, LoaType } from '../../models/index';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../common/models/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../common/services/index';
import { ColorUtil, IScreenUtils, screenUtils } from '../../../../common/utils/index';
import { InfoDialogComponent } from '../../../../common/components/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-type-configuration',
  templateUrl: 'lm-type-configuration.component.html',
  styleUrls: ['lm-type-configuration.component.scss'],
  providers: [StateManagementService, ColumnManagementService]
})
export class LMTypeConfigurationComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public appConfig: IApplicationConfig;
  public records: LoaTypeModel[];
  public gridState: KendoGridStateHelper<LoaTypeModel>;
  public canEdit: boolean;
  public pageSize = 50;
  public screenUtils: IScreenUtils;
  public columnGroup = 'LeaveTypes';
  public prohibitedNameValues: string[] = [];

  @ViewChild('typesForm', { static: true })
  public payCodesForm: FormGroup;
  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  private subscriptions: StringMap<Subscription> = {};
  private container: LoaTypeModelContainer;
  private orgLevel: OrgLevel;

  constructor(private apiService: LMTypeApiService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService,
    private modalService: ModalService
  ) {
    this.gridState = new KendoGridStateHelper<LoaTypeModel>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;
  }

  public ngOnInit(): void {
    this.stateManagement.init('LMTypeConfigurationComponent');
    this.columnManagementService.init('LMTypeConfigurationComponent');
    this.columnManagementService.initGroup(this.columnGroup, 4);
    this.subscribeToOrgLevel();

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<LoaTypeModel>) => {
      this.isLoading = true;
      if (event.isNew) {
        this.doAdd(event.dataItem);
        return;
      }
      this.doEdit(event.dataItem, event.rowIndex);
    });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<LoaTypeModel>) => {
      this.doRemove(event.dataItem, event.rowIndex);
    });
  }

  public async reload(): Promise<any> {
    this.isLoading = true;
    return this.apiService.getLoaTypeModels(this.orgLevel.id)
      .then((container: LoaTypeModelContainer) => {
        this.container = container;
        this.records = container.items;
        this.canEdit = container.canEdit;
        this.prohibitedNameValues = _.map(this.records, (m: LoaTypeModel) => m.loaType.name);
        this.refreshGrid();
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doAdd(dataItem: LoaTypeModel): Promise<any> {
    return this.apiService.createLoaType(dataItem)
      .then((res: LoaTypeEditResult) => {
        if (res.isSuccess) {
          this.records.push(res.item);
          this.prohibitedNameValues = _.map(this.records, (m: LoaTypeModel) => m.loaType.name);
          this.refreshGrid();
        } else {
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doEdit(dataItem: LoaTypeModel, rowIndex: number): Promise<any> {
    return this.apiService.modifyLoaType(dataItem)
      .then((res: LoaTypeEditResult) => {
        const index = _.findIndex(this.records, (c: LoaTypeModel) => c.loaType.id === res.item.loaType.id);
        if (!res.isSuccess) {
          this.records.splice(index, 1, res.item);
          this.refreshGrid();
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        } else {
          this.prohibitedNameValues = _.map(this.records, (m: LoaTypeModel) => m.loaType.name);
          this.records[index].modifiedAt = res.item.modifiedAt;
          this.records[index].modifiedBy = res.item.modifiedBy;
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doRemove(dataItem: LoaTypeModel, rowIndex: number): Promise<any> {
    this.isLoading = true;
    return this.apiService.deleteLoaType(dataItem.loaType.id)
      .then((res: LoaTypeEditResult) => {
        if (res.isSuccess) {
          const index = _.findIndex(this.records, (c: LoaTypeModel) => c.loaType.id === dataItem.loaType.id);
          this.records.splice(index, 1);
          this.prohibitedNameValues = _.map(this.records, (m: LoaTypeModel) => m.loaType.name);
          this.refreshGrid();
        } else {
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public addType(): void {
    let model: LoaTypeModel = new LoaTypeModel();
    model.loaType = new LoaType();
    model.loaType.color = _.first(randomColor({count: 1}));
    this.gridState.closeEditor(this.kendoGrid);
    this.kendoGrid.addRow(model);
  }

  public onColorPickerChange(model: LoaTypeModel, colorCode: string): void {
    if (model) {
      model.loaType.color = colorCode;
    }
  }

  private subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe(async (orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        this.orgLevel = orgLevel;
        this.reload();
      });
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

}
