import { Injectable } from '@angular/core';
import { IPayloadAction, UserApplication } from '../../state-model/models/index';
import { ApplicationActions, SidebarActions } from '../../portal/actions/index';
import { TimecardsActions } from '../store/index';
import { Observable } from 'rxjs/Observable';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../store/store';
import { mutableSelect } from '../../core/decorators/index';

@Injectable()
export class TimecardsEpics {
  @mutableSelect('application')
  public application: Observable<UserApplication>;

  private ngRedux: NgRedux<IAppState>;
  private timecardsActions: TimecardsActions;

  constructor(timecardsActions: TimecardsActions, ngRedux: NgRedux<IAppState>) {
    this.ngRedux = ngRedux;
  }

  public resetPayCycle = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.filter(({ type }: { type: string }) => type === ApplicationActions.SELECT_APPLICATION /*|| type === SidebarActions.RIGHT_MENU_ITEM_CHANGE*/)
      .mergeMap((action: IPayloadAction) => {
        return Observable.of({
            type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE,
            payload: null
          });
      });
  }

  public resetSelectionSettings = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.filter(({ type }: { type: string }) => type === TimecardsActions.TIMECARDS_DISPLAY_CLEAR_PAYCYCLE)
      .mergeMap((action: IPayloadAction) => {
        return Observable.of({
            type: TimecardsActions.TIMECARDS_DISPLAY_CLEAR_SELECTION_SETTINGS,
            payload: null
          });
      });
  }
}
