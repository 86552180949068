import * as moment from 'moment';
import * as _ from 'lodash';
import * as i0 from "@angular/core";
var MessagesMappingService = /** @class */ (function () {
    function MessagesMappingService() {
    }
    MessagesMappingService.prototype.mapToSmsMessagesInfo = function (data) {
        _.forEach(data, function (record) {
            var modifiedDate = moment(record.createdAt).format('DD/MM/YYYY HH:MM');
            console.log('mdf', modifiedDate);
            record.createdAt = modifiedDate;
        });
        return data;
    };
    MessagesMappingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagesMappingService_Factory() { return new MessagesMappingService(); }, token: MessagesMappingService, providedIn: "root" });
    return MessagesMappingService;
}());
export { MessagesMappingService };
