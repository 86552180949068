import { Injectable } from '@angular/core';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { NavigationMenuItem, ComponentAccessInfo, IComponentAccessInfo, IUserAction, UserAction } from '../../models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { organizationConfig } from '../../../organization/organization.config';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { Meta } from '../../../core/models/api/meta';
import { Assert } from '../../../framework/index';

@Injectable()
export class AccessibleApiService {
  private previousRequestParametersKey: string;
  private previousRequestResponse: Promise<ComponentAccessInfo>;

  constructor(
    private apiUtilService: ApiUtilService, private urlParamsService: UrlParamsService) {
      this.previousRequestParametersKey = '';
      this.previousRequestResponse = Promise.resolve(null);
  }

  public getComponentAccessibleByOrgLevel(applicationId: number, componentId: string, orgLevelId: number): Promise<ComponentAccessInfo> {
    const url: string = `${this.getAccessibleRoot(applicationId, componentId)}/${organizationConfig.api.applications.components.orglevel}/${orgLevelId}`;
    const currentRequestParametersKey = this.makeRequestParametersKey(applicationId, componentId, orgLevelId);

    if (this.previousRequestParametersKey === currentRequestParametersKey) {
      this.previousRequestParametersKey = '';
      return this.previousRequestResponse;
    }

    const promise = this.apiUtilService.request<IComponentAccessInfo, Meta>(this.urlParamsService.createGetRequest(url))
      .then((response: ResponseBody<ComponentAccessInfo, Meta>) => response.data);

    this.previousRequestParametersKey = currentRequestParametersKey;
    this.previousRequestResponse = promise;

    return promise;
  }

  public getComponentAccessibleByEmployee(applicationId: number, componentId: string, employeeId: number): Promise<ComponentAccessInfo> {
    const url: string = `${this.getAccessibleRoot(applicationId, componentId)}/${organizationConfig.api.applications.components.employee}/${employeeId}`;

    let promise: Promise<ComponentAccessInfo> = this.apiUtilService.request<IComponentAccessInfo, Meta>(this.urlParamsService.createGetRequest(url))
      .then((response: ResponseBody<ComponentAccessInfo, Meta>) => response.data);
    return promise;
  }

  public getUserActions(orgLevelId: number, actionNames: string[]): Promise<UserAction[]> {
    const url: string = `${this.getUserRoot()}/${organizationConfig.api.user.actions}`;

    const params: StringMap<any> = {
      orgLevelId: orgLevelId,
      actionNames: actionNames
    };

    let promise: Promise<UserAction[]> = this.apiUtilService.request<IUserAction[], Meta>(this.urlParamsService.createGetRequest(url, params, true))
      .then((response: ResponseBody<UserAction[], Meta>) => response.data);
    return promise;
  }

  private getAccessibleRoot(applicationId: number, componentId: string): string {
    return `${this.getApiRoot(applicationId)}/${componentId}/${organizationConfig.api.applications.components.accessible}`;
  }
  private getUserRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.api.user.root}`;
  }
  private getApiRoot(applicationId: number): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${organizationConfig.api.applications.root}/${applicationId}/${organizationConfig.api.applications.components.root}`;
  }

  private makeRequestParametersKey(applicationId: number, componentId: string, orgLevelId: number): string {
    return `${applicationId}-${orgLevelId}-${componentId}`;
  }
}
