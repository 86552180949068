import { appConfig } from './../../../../../app/app.config';
import { FileUploadsManagementService } from './../../../../../app/configuration/services/file-uploads/file-uploads-management.service';
import { Router } from '@angular/router';
var UploadedFileStatusComponent = /** @class */ (function () {
    function UploadedFileStatusComponent(manService, router) {
        this.manService = manService;
        this.router = router;
        this.filterByModuleName = null;
        this.filterByImportDate = null;
        this.gridHeight = window.innerHeight - 200;
        this.appConfig = appConfig;
    }
    Object.defineProperty(UploadedFileStatusComponent.prototype, "dateWithTimeFormat", {
        get: function () {
            return '{0:MM/dd/yyyy HH:mm:ss a}';
        },
        enumerable: true,
        configurable: true
    });
    UploadedFileStatusComponent.prototype.onResize = function (event) {
        this.gridHeight = event.target.innerHeight - 200;
    };
    return UploadedFileStatusComponent;
}());
export { UploadedFileStatusComponent };
