import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Comparison, ChartMenuItem, ChartType } from '../../models/index';
import { ValuePairWidgetConfig, ValuePairChartInput } from '../../../../common/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-graphic-comparison-day',
  templateUrl: 'schedule-console-graphic-comparison-day.component.html',
  styleUrls: ['schedule-console-graphic-comparison-day.component.scss']
})
export class ScheduleConsoleGraphicComparisonDayComponent {

  @Input()
  public comparison: Comparison;

  @Input()
  public widgetConfig: ValuePairWidgetConfig;

  @Input()
  public chartInput: ValuePairChartInput;

  @Input()
  public chart: ChartMenuItem;

  @Output()
  public onClickChart: EventEmitter<any>;

  @Output()
  public onClickWidget: EventEmitter<any>;

  @Output()
  public onClickPair1: EventEmitter<any>;

  @Output()
  public onClickPair2: EventEmitter<any>;

  @Output()
  public onClickBadgeMessages: EventEmitter<any>;

  @Output()
  public onClickBadgeOvertime: EventEmitter<any>;

  @Output()
  public onClickBadgePto: EventEmitter<any>;

  @Output()
  public onClickBadgeLate: EventEmitter<any>;

  public chartTypes: typeof ChartType = ChartType;

  constructor() {
    this.onClickWidget = new EventEmitter<any>();
    this.onClickChart = new EventEmitter<any>();
    this.onClickPair1 = new EventEmitter<any>();
    this.onClickPair2 = new EventEmitter<any>();
    this.onClickBadgeMessages = new EventEmitter<any>();
    this.onClickBadgeOvertime = new EventEmitter<any>();
    this.onClickBadgePto = new EventEmitter<any>();
    this.onClickBadgeLate = new EventEmitter<any>();
  }

  public handleClickChart(event: any): void {
    this.onClickChart.emit(event);
  }

  public handleClickPair1(event: any): void {
    this.onClickPair1.emit(event);
  }

  public handleClickPair2(event: any): void {
    this.onClickPair2.emit(event);
  }

  public handleClickBadgeMessages(event: any): void {
    this.onClickBadgeMessages.emit(event);
  }

  public handleClickBadgeOvertime(event: any): void {
    this.onClickBadgeOvertime.emit(event);
  }

  public handleClickBadgePto(event: any): void {
    this.onClickBadgePto.emit(event);
  }

  public handleClickBadgeLate(event: any): void {
    this.onClickBadgeLate.emit(event);
  }

  public hanldeWidgetClick(comparison: Comparison): void {
    this.onClickWidget.emit(comparison);
  }
}
