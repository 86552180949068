import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import randomColor from 'randomcolor';
import { OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../app.config';
import { LMTypeApiService } from '../../services/index';
import { LoaTypeModel, LoaType } from '../../models/index';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../common/services/index';
import { IScreenUtils, screenUtils } from '../../../../common/utils/index';
import { InfoDialogComponent } from '../../../../common/components/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
var LMTypeConfigurationComponent = /** @class */ (function () {
    function LMTypeConfigurationComponent(apiService, stateManagement, columnManagementService, modalService) {
        this.apiService = apiService;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.modalService = modalService;
        this.pageSize = 50;
        this.columnGroup = 'LeaveTypes';
        this.prohibitedNameValues = [];
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
    }
    LMTypeConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('LMTypeConfigurationComponent');
        this.columnManagementService.init('LMTypeConfigurationComponent');
        this.columnManagementService.initGroup(this.columnGroup, 4);
        this.subscribeToOrgLevel();
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            _this.isLoading = true;
            if (event.isNew) {
                _this.doAdd(event.dataItem);
                return;
            }
            _this.doEdit(event.dataItem, event.rowIndex);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            _this.doRemove(event.dataItem, event.rowIndex);
        });
    };
    LMTypeConfigurationComponent.prototype.reload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                return [2 /*return*/, this.apiService.getLoaTypeModels(this.orgLevel.id)
                        .then(function (container) {
                        _this.container = container;
                        _this.records = container.items;
                        _this.canEdit = container.canEdit;
                        _this.prohibitedNameValues = _.map(_this.records, function (m) { return m.loaType.name; });
                        _this.refreshGrid();
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    LMTypeConfigurationComponent.prototype.doAdd = function (dataItem) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.createLoaType(dataItem)
                        .then(function (res) {
                        if (res.isSuccess) {
                            _this.records.push(res.item);
                            _this.prohibitedNameValues = _.map(_this.records, function (m) { return m.loaType.name; });
                            _this.refreshGrid();
                        }
                        else {
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    LMTypeConfigurationComponent.prototype.doEdit = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.modifyLoaType(dataItem)
                        .then(function (res) {
                        var index = _.findIndex(_this.records, function (c) { return c.loaType.id === res.item.loaType.id; });
                        if (!res.isSuccess) {
                            _this.records.splice(index, 1, res.item);
                            _this.refreshGrid();
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                        else {
                            _this.prohibitedNameValues = _.map(_this.records, function (m) { return m.loaType.name; });
                            _this.records[index].modifiedAt = res.item.modifiedAt;
                            _this.records[index].modifiedBy = res.item.modifiedBy;
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    LMTypeConfigurationComponent.prototype.doRemove = function (dataItem, rowIndex) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.isLoading = true;
                return [2 /*return*/, this.apiService.deleteLoaType(dataItem.loaType.id)
                        .then(function (res) {
                        if (res.isSuccess) {
                            var index = _.findIndex(_this.records, function (c) { return c.loaType.id === dataItem.loaType.id; });
                            _this.records.splice(index, 1);
                            _this.prohibitedNameValues = _.map(_this.records, function (m) { return m.loaType.name; });
                            _this.refreshGrid();
                        }
                        else {
                            InfoDialogComponent.OpenDialog('Warning', res.message, _this.modalService);
                        }
                    }).finally(function () {
                        _this.isLoading = false;
                    })];
            });
        });
    };
    LMTypeConfigurationComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    LMTypeConfigurationComponent.prototype.addType = function () {
        var model = new LoaTypeModel();
        model.loaType = new LoaType();
        model.loaType.color = _.first(randomColor({ count: 1 }));
        this.gridState.closeEditor(this.kendoGrid);
        this.kendoGrid.addRow(model);
    };
    LMTypeConfigurationComponent.prototype.onColorPickerChange = function (model, colorCode) {
        if (model) {
            model.loaType.color = colorCode;
        }
    };
    LMTypeConfigurationComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id)
                    return [2 /*return*/];
                this.orgLevel = orgLevel;
                this.reload();
                return [2 /*return*/];
            });
        }); });
    };
    LMTypeConfigurationComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], LMTypeConfigurationComponent.prototype, "orgLevel$", void 0);
    return LMTypeConfigurationComponent;
}());
export { LMTypeConfigurationComponent };
