import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { UserPasswordSettingsDialogComponent } from './../../user-password-settings-dialog/user-password-settings-dialog.component';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { RolesManagementService, RolesDefinitionManagementService } from '../../../services/index';
import { unsubscribe, destroyService } from '../../../../core/decorators/index';
import { RolesLegendDialogComponent } from '../roles-legend-dialog/roles-legend-dialog.component';
import { ModalService } from '../../../../common/services/modal/modal.service';
var RolesManagementComponent = /** @class */ (function () {
    function RolesManagementComponent(managementService, definitionManagementService, modalService) {
        this.modalService = modalService;
        this.canSave = true;
        this.maxVisibleColumnsToSave = 5;
        this.managementService = managementService;
        this.definitionManagementService = definitionManagementService;
        this.state = {
            isLoading: false,
            isAccessTableLoading: true,
            isManageRoleMode: false
        };
        this.columnsList = [];
        this.shownColumns = [];
    }
    RolesManagementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isAccessTableLoading = value;
        });
        this.stateSubscription = this.managementService.rolesColumnsStateChanged$
            .subscribe(function (state) {
            _this.roleColumnsState = state;
            _this.columnsList = _.values(state.columns);
            var filteredColumns = state.getVisibleColumns();
            _this.shownColumns = filteredColumns;
            _this.canSave = _.size(_this.shownColumns) <= _this.maxVisibleColumnsToSave;
        });
        this.loadStatusSubscription2 = this.definitionManagementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.selectDefinitionSubscription = this.definitionManagementService.selectRecord$
            .subscribe(function (role) {
            _this.selectedRole = role;
        });
        this.managementModeChangesSubscription = this.definitionManagementService.changeNotify$.subscribe(function (source) {
            _this.manageModeHasChanges = true;
        });
        this.managementModeChangesClearSubscription = this.definitionManagementService.clearChanges$.subscribe(function () {
            _this.manageModeHasChanges = false;
        });
        this.changesNotifySubscription = this.managementService.changeNotify$.subscribe(function (source) {
            _this.hasChanges = true;
        });
        this.changesClearSubscription = this.managementService.clearChanges$.subscribe(function () {
            _this.hasChanges = false;
        });
    };
    RolesManagementComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    RolesManagementComponent.prototype.onManagingRoles = function () {
        this.state.isManageRoleMode = true;
    };
    RolesManagementComponent.prototype.onShowLegend = function () {
        RolesLegendDialogComponent.openDialog(this.modalService, function (result, cmd) {
            //todo
        });
    };
    RolesManagementComponent.prototype.onManagingAccessTable = function () {
        this.managementService.reLoadAccessTable();
        this.state.isManageRoleMode = false;
    };
    RolesManagementComponent.prototype.onSaveChanges = function () {
        this.managementService.saveChanges();
    };
    RolesManagementComponent.prototype.onDicardChanges = function () {
        this.managementService.dicardChanges();
    };
    RolesManagementComponent.prototype.onAddDefinition = function () {
        this.definitionManagementService.addCmd();
    };
    RolesManagementComponent.prototype.onCloneDefinition = function () {
        this.definitionManagementService.cloneCmd(this.selectedRole);
    };
    RolesManagementComponent.prototype.onPasswordSettings = function () {
        UserPasswordSettingsDialogComponent.openDialog(this.modalService, function (result, cmd) {
            //todo
        });
    };
    RolesManagementComponent.prototype.onFiltersChanged = function () {
        this.roleColumnsState.applyFilter(this.shownColumns.concat());
        this.managementService.changeRolesColumnsState(this.roleColumnsState);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "loadStatusSubscription2", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "selectDefinitionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "changesNotifySubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "changesClearSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "managementModeChangesSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesManagementComponent.prototype, "managementModeChangesClearSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", RolesManagementService)
    ], RolesManagementComponent.prototype, "managementService", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", RolesDefinitionManagementService)
    ], RolesManagementComponent.prototype, "definitionManagementService", void 0);
    return RolesManagementComponent;
}());
export { RolesManagementComponent };
