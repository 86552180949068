import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { dateTimeUtils } from '../../../utils/index';
import { ControlStateKey, StateResetTypes } from '../../../../core/models/index';
import { ComponentStateStorageService, StateManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { DateRangeNgxComponent } from '../../../components/kendo-ui-extensions/date-range-ngx/date-range-ngx.component';
import { IRangeDates } from '../../../models/range-dates';
var DateRangeNgxActiveStateDirective = /** @class */ (function () {
    function DateRangeNgxActiveStateDirective(dateRange, storageService, stateManagement) {
        var _this = this;
        this.dateRange = dateRange;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.controlId = 'DateRangeNgx';
        this.m_resetType = StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe(function (key) {
            _this.switchState(key);
        });
    }
    Object.defineProperty(DateRangeNgxActiveStateDirective.prototype, "resetType", {
        set: function (type) {
            if (!type) {
                return;
            }
            this.m_resetType = type;
        },
        enumerable: true,
        configurable: true
    });
    DateRangeNgxActiveStateDirective.prototype.ngOnInit = function () {
        this.stateManagement.registerControl(this.controlId);
    };
    DateRangeNgxActiveStateDirective.prototype.ngOnDestroy = function () {
        this.stateManagement.unregisterControl(this.controlId);
    };
    DateRangeNgxActiveStateDirective.prototype.valueChanged = function (val) {
        this.state.value = { startDate: dateTimeUtils.convertToDtoDateTimeString(val.startDate), endDate: dateTimeUtils.convertToDtoDateTimeString(val.endDate) };
        this.storageService.setControlState(this.stateManagement.componentKey, this.controlId, this.state, this.m_resetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.controlId);
    };
    DateRangeNgxActiveStateDirective.prototype.loadState = function () {
        var _this = this;
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
        this.state = this.storageService.getControlState(this.stateManagement.componentKey, this.controlId, this.stateKey);
        if (this.state.value !== undefined) {
            var startDate = dateTimeUtils.convertFromDtoDateTimeString(this.state.value.startDate);
            var endDate = dateTimeUtils.convertFromDtoDateTimeString(this.state.value.endDate);
            this.dateRange.onRangeDateChanged(startDate, endDate);
        }
        this.valueChangesSubscription = this.dateRange.rangeDateChanged.subscribe(function (x) {
            _this.valueChanged(x);
        });
        this.stateManagement.controlRestored(this.controlId);
    };
    DateRangeNgxActiveStateDirective.prototype.switchState = function (context) {
        this.stateKey = new ControlStateKey(context);
        this.loadState();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DateRangeNgxActiveStateDirective.prototype, "contextChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DateRangeNgxActiveStateDirective.prototype, "valueChangesSubscription", void 0);
    return DateRangeNgxActiveStateDirective;
}());
export { DateRangeNgxActiveStateDirective };
