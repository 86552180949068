import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { AcaExportApiService } from '../aca-export/aca-export-api.service';
import { OrgLevel } from '../../../../state-model/models/index';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll, unsubscribe } from '../../../../core/decorators';
import { FileService, StateManagementService } from '../../../../common';
import { AcaExportEventService } from '../../../../common/services/aca-export/aca-export-event.service';
import { UserActivityService } from '../../../../core/services/user-activity/user-activity.service';
import { TokenValidityService, PollingPeriodService } from '../../../../core/services';
import { ExportDataStatus } from '../../enums/export-data-status';
import { appConfig } from '../../../../app.config';
import { AcaReportEmployerStatus } from '../../models/index';
import { AcaExportExecutionItem } from '../../models/aca-export/aca-export-execution';
import { AcaExportParameter } from '../../models/aca-export/aca-export-parameter';
import { AcaExportMapService } from './aca-export-map.service';
import { AcaExportExecutionStatus } from '../../models/aca-export/aca-export-execution-status';
import { IAcaExportState, initialAcaExportState } from '../../models/aca-export/aca-export-state';
import { AcaExportConfiguration } from '../../models/aca-export/aca-export-configuration';
import { StateResetTypes } from '../../../../core/models';
import { AcaReportApiService } from '../aca-report/aca-report-api.service';
import { IAcaExportNotification } from '../../models/aca-export/aca-export-notification';
import { AcaExportDialogOptions } from '../../models/aca-export/aca-export-dialog-options';
import { AcaExportCorrected1095C } from '../../models/aca-export/aca-export-corrected-1095c';
import { AcaExportReplace1095C, MatchEmp } from '../../models/aca-export/aca-export-replace-1095c';
import { AcaExportSupplemental1095C } from '../../models/aca-export/aca-export-supplemental-1095c';
import { AcaExportCorrectionParameter } from '../../models/aca-export/aca-export-correction-parameter';
import { AcaExportCorrection1094CCountContainer, AcaExportCorrection1094CCounts } from '../../models/aca-export/aca-export-correction-1094c-counts';


@Injectable()
export class AcaExportManagementService implements OnDestroy {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;
  public exportConfigurations: AcaExportConfiguration[];
  public state: IAcaExportState;
  private readonly stateKey: string = 'AcaExportState';
  private loading$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private configurationsLoaded$ = new ReplaySubject<AcaExportConfiguration[]>(1);
  private historyLoaded$ = new ReplaySubject<AcaExportExecutionItem[]>(1);
  private statusLoaded$ = new ReplaySubject<AcaExportExecutionStatus[]>(1);
  private exportExecuted$ = new ReplaySubject<AcaExportExecutionItem>(1);
  public acaExportDialogOptions$ = new Subject<AcaExportDialogOptions>();
  private applicationHeartbeatService: PollingPeriodService;
  @unsubscribe()
  private poolingSubscription: Subscription;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private apiService: AcaExportApiService,
    private mapService: AcaExportMapService,
    private fileService: FileService,
    private userActivityService: UserActivityService,
    private tokenValidity: TokenValidityService,
    private acaExportEventService: AcaExportEventService,
    private stateManagement: StateManagementService,
    private acaReportApiService: AcaReportApiService
  ) {
    this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
  }

  public init(): void {
    this.subscribeToOrgLevelChanges();
    this.acaExportEventService.init();

    this.subscriptions.exportDataStatusChanged = this.acaExportEventService.exportDataStateChanged$.subscribe((args: any[]) => {
      const executionNotification: IAcaExportNotification = args && args.length > 2 ? JSON.parse(args[2]) : null;
      if (executionNotification) {
        this.statusLoaded$.next([this.mapService.mapNotificationToAcaExportStatus(executionNotification)]);
      }
    });

    this.subscriptions.notificationStateChanged = this.acaExportEventService.notificationStateChanged$.subscribe((enabled: boolean) => {
      if (enabled) {
        if (this.poolingSubscription) {
          this.poolingSubscription.unsubscribe();
          this.applicationHeartbeatService.stop();
        }
      } else {
        if (!this.poolingSubscription || this.poolingSubscription.closed) {
          this.poolingSubscription = this.applicationHeartbeatService.onHeartbeat.subscribe(() => {
            if (this.exportConfigurations) {
              const ids: string[] = this.getStatusesToUpdate();
              if (ids && ids.length > 0) {
                this.loadExportDataStatuses(ids);
              }
            }
          });
          this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.statusLoaded$.complete();
    this.historyLoaded$.complete();
    this.exportExecuted$.complete();
    this.orgLevelChanged$.complete();
    this.acaExportDialogOptions$.complete();
    this.configurationsLoaded$.complete();
    this.acaExportDialogOptions$.complete();
  }

  public loadAcaExportConfiguration(): void {
    this.loading$.next(true);
    this.apiService.getAcaExportConfigurationList()
      .then((data: AcaExportConfiguration[]) => {
        this.exportConfigurations = data;
        this.configurationsLoaded$.next(data);
        this.loading$.next(false);
      });
  }


  private getStatusesToUpdate() {
    const ids: string[] = [];
    _.each(this.exportConfigurations, c => {
      if (c.lastExecuted && (c.lastExecuted.status === ExportDataStatus.InProgress || c.lastExecuted.status === ExportDataStatus.Waiting)) {
        ids.push(c.lastExecuted.id);
      }
    });

    return ids;
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loading$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (v: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public setLoadingStatus(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => _.isFinite(_.get(o, 'id')))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;

        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
      });
  }

  public subscribeToLoadedConfigurations(callback: (v: AcaExportConfiguration[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.configurationsLoaded$.subscribe(callback);
  }

  public subscribeToLoadedHistory(callback: (v: AcaExportExecutionItem[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.historyLoaded$.subscribe(callback);
  }

  public subscribeToLoadedStatuses(callback: (v: AcaExportExecutionStatus[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.statusLoaded$.subscribe(callback);
  }

  public subscribeToExportExecuted(callback: (v: AcaExportExecutionItem) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportExecuted$.subscribe(callback);
  }

  public subscribeToExportDialogOptions(callback: (v: AcaExportDialogOptions) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.acaExportDialogOptions$.subscribe(callback);
  }  

  public async fetchCorrected1095CRecords(lastExectued: string, matchEmployeeList: MatchEmp[]): Promise<AcaExportCorrected1095C[]> {
    let acaExportCorrected1095CContainer = [];
    await this.apiService.getAcaCorrected1095CData(lastExectued).
      then((result: AcaExportCorrected1095C[]) => {
        if(matchEmployeeList && matchEmployeeList.length > 0){
          _.forEach(result, eln =>{
            let matchemplist : MatchEmp[]= _.filter(matchEmployeeList,(list)=> list.soureEmpID == eln.employeeIdMasterId)
            if(matchemplist.length >0){
              eln.ssnStatus= 1
            }
          })
        }
        acaExportCorrected1095CContainer = result;
      }).catch(() => {

      });
    return acaExportCorrected1095CContainer;
  }

  public async fetchReplace1095CRecords(lastExectued: string): Promise<AcaExportReplace1095C[]> {
    let acaExportCorrected1095CContainer = [];
    await this.apiService.getReplace1095CData(lastExectued).
      then((result: AcaExportReplace1095C[]) => {
        acaExportCorrected1095CContainer = result;
      }).catch(() => {

      });
    return acaExportCorrected1095CContainer;
  }

  public async fetchSupplemental1095CRecords(lastExectued: string): Promise<AcaExportSupplemental1095C[]> {
    let acaExportCorrected1095CContainer = [];
    await this.apiService.getSupplemental1095CData(lastExectued).
      then((result: AcaExportSupplemental1095C[]) => {
        acaExportCorrected1095CContainer = result;
      }).catch(() => {

      });
    return acaExportCorrected1095CContainer;
  }

  public loadExportDataHistory(configurationId: number): void {
    this.loading$.next(true);
    this.apiService.getAcaExportHistory(configurationId)
      .then((data: AcaExportExecutionItem[]) => {
        this.historyLoaded$.next(data);
        this.loading$.next(false);
      });
  }

  public loadExportDataStatuses(ids: string[]): void {
    this.apiService.getAcaExportStatuses(ids)
      .then((data: AcaExportExecutionStatus[]) => {
        this.statusLoaded$.next(data);
      });
  }

  public generateAcaExport(params: AcaExportParameter): void {
    this.loading$.next(true);
    const req = this.mapService.mapToAcaExportRequest(params);
    this.apiService.generateAcaExport(req)
      .then((data: AcaExportExecutionItem) => {
        this.exportExecuted$.next(data);
        this.historyLoaded$.next([data]);
        this.loading$.next(false);
      }).catch(() => {
        this.loading$.next(false);
      });
  }

  public async downloadExportData(type: number, executionId: string): Promise<void> {
    this.loading$.next(true);
    try {
      const file = await this.apiService.downloadAcaExport(type, executionId);
      this.fileService.saveToFileSystem(file.blob, file.file);
      this.loading$.next(false);
    } catch (e) {
      console.error(e);
    } finally {
      this.loading$.next(false);
    }
  }

  public cancelAcaExport(exportType: number, executionId: string): void {
    this.loading$.next(true);

    this.apiService.cancelAcaExport(exportType, executionId)
      .then((data: AcaExportExecutionStatus) => {
        const status: AcaExportExecutionStatus = { exportType: exportType, executionId: executionId, status: ExportDataStatus.Cancelled, changedOn: moment().toDate(), reason: null, configurationId: null };
        this.statusLoaded$.next([status]);
        this.loading$.next(false);
      }).catch(() => {
        this.loading$.next(false);
      });
  }

  public setAcaExportState(acaExportDataConfigList: AcaExportConfiguration[]): void {

    let state: IAcaExportState = _.clone(initialAcaExportState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }

    state.acaExportConfigurationList = acaExportDataConfigList;
    this.saveState(state);
  }

  private saveState(state: IAcaExportState): void {
    this.stateManagement.setControlState(this.stateKey, {
      value: state
    }, StateResetTypes.None);
  }

  public restoreAcaExportState(): AcaExportConfiguration[] {
    let config: AcaExportConfiguration[] = [];
    let state: IAcaExportState = _.clone(initialAcaExportState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }

    config = state.acaExportConfigurationList;
    return config;
  }

  public async getEmployerStatus(employerId: number, year: number): Promise<AcaReportEmployerStatus> {
    this.loading$.next(true);

    let result: AcaReportEmployerStatus;

    try {
      result = await this.acaReportApiService.getEmployerStatus(employerId, year);
    } catch (e) { }

    this.loading$.next(false);

    return result;
  }

  public openAcaExportDialog(acaExportDialogOptions: AcaExportDialogOptions): void {
    this.acaExportDialogOptions$.next(acaExportDialogOptions);
  }

  public createParameterOptionsData(item: AcaExportExecutionItem): AcaExportCorrectionParameter {
    const acaExportCorrectionParameter: AcaExportCorrectionParameter = new AcaExportCorrectionParameter();
    acaExportCorrectionParameter.offeredMec = this.generateDataOptions(item.offeredMec);
    acaExportCorrectionParameter.memberOfAleGroup = this.generateDataOptions(item.memberOfAleGroup);
    acaExportCorrectionParameter.offerMethod98 = item.offerMethod98;
    acaExportCorrectionParameter.qualifiedOfferMethod = item.qualifiedOfferMethod;
    return acaExportCorrectionParameter;
  }

  private generateDataOptions(selected: any[]): any[] {
    const dataOptions: any[] = [];
    for (let month: number = 0; month < 12; month++) {
      const id = month + 1;
      dataOptions.push({
        id,
        name: moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'),
        isSelected: _.includes(selected, id)
      });
    }
    return _.cloneDeep(dataOptions);
  }

  public async getAcaExport1094CCountData(item: AcaExportExecutionItem, correctionParameters: AcaExportCorrectionParameter): Promise<AcaExportCorrection1094CCountContainer> {
    let result: AcaExportCorrection1094CCountContainer;

    try {
      result = await this.apiService.getAcaExport1094cCount(item, correctionParameters);
    } catch (e) { }

    return result;
  }

}
