export interface IDayTotal {
  date: string;
  value: number;
  fteValue: number;
}

export class DayTotal {
  date: Date;
  value: number;
  fteValue: number;
}

