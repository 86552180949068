import * as _ from 'lodash';
import { Position, EmpType, ShiftGroupDefinition, LocationUnit, PositionGroup } from '../../../organization/models/index';
import { IMasterScheduleGroupingType } from './master-schedule-grouping-type';
import { IMasterScheduleCell } from './master-schedule-row';

export class MasterScheduleLookupFilters {
  public positionGroup: PositionGroup;
  public position: Position;
  public employeeType: EmpType[];
  public shiftGroup: ShiftGroupDefinition;
  public unit: LocationUnit;
  public getIds(): { positionGroupId: number,positionId: number, employeeTypeNames: string[], shiftGroupId: number, unitId: number } {
    return {
      positionGroupId: this.positionGroup? this.positionGroup.id:null,
      positionId: this.position ? this.position.id : null,
      employeeTypeNames: this.employeeType ? _.map(this.employeeType, (et: EmpType) => et.name) : [],
      shiftGroupId: this.shiftGroup ? this.shiftGroup.id : null,
      unitId: this.unit ? this.unit.id : null
    };
  }
}

export class MasterScheduleFilters {
  public filters: MasterScheduleLookupFilters;
  public dateFrom: Date;
  public weekNumber: number;
  public groupBy: IMasterScheduleGroupingType;
  public selectedCell: IMasterScheduleCell;
  public showTotalsFTEs: boolean;
}
