export enum ForgotPasswordCodes {
  EmailSentToUser = 1,
  NotificationSentToAdmin = 2,
  UserNotFound = 3,
  NoEmailNorAdmins = 4,
  NoAdminsWithEmail = 5,
  NoEmployeeIsLinkedToUser = 6,
  InvalidCaptcha = 7,
  FailedToSendEmailForUser = 8,
  FailedToSendNotificationForAdmin = 9
}

export class ForgotPassword {
  public code: ForgotPasswordCodes;
  public message: string;
  public userMaskedEmail: string;
  public adminNames: string[];
}

export enum ResetPasswordCodes {
  InvalidToken = 1,
  TokenExpired = 2,
  UserNotFound = 3,
  PasswordNotValid = 4,
  PasswordChanged = 5,
  UsernameOrAliasIsNotMatch = 6,
  TokenAlreadyUsed = 7
}

export class ResetPassword {
  public code: ResetPasswordCodes;
  public message: string;
}

export class PasswordResetState {
  public isLoading: boolean;
  public hasSuccedeed: boolean;
  public hasFailed: boolean;
  public isUnknownError: boolean;
  public error: ResetPasswordCodes;
}

export class PasswordValidationResult {
  public isValid: boolean;
}
