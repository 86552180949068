import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';

import { appConfig } from '../../../../app.config';
import { Assert } from '../../../../framework/index';
import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';

import { FieldsMeta, Meta } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';
import { BenefitEmployeesMapService } from '../../../../app-modules/benefits/services/index';
import { LookupMapService } from '../../../../organization/services/index';
import { IBenefitClass, BenefitClass, EmployeeBenefitClass, IEmployeeBenefitClass } from '../../../../organization/models/employee/index';

import {
  IEmployeeSectionsBenefitsManagement,
  EmployeeSectionsBenefitsManagement,
  IEmployeeSubsectionEnrollment,
  EmployeeSubsectionEnrollment,
  IEmployeeSubsectionBenefitClasses,
  EmployeeSubsectionBenefitClasses,
  IEmployeeSubsectionDependents,
  EmployeeSubsectionDependents,
  IEmployeeInfo,
  EmployeeInfo,
  IAttachmentFile,
  EnrollementAttachmentFile,
  IEmployeeSectionsEnrollmentAttachments,
  EmployeeSectionsEnrollmentAttachments,
  IBenefitPlanShortInfo,
  BenefitPlanShortInfo,
  IBenefitsEmpEnrollOptionRate,
  BenefitsEmpEnrollOptionRate,
  IBenefitTierDefinition,
  BenefitTierDefinition,
  IBenefitsEmpEnrollmentFlat,
  BenefitsEmpEnrollmentFlat,
  IBenefitEmpEnrollment401k,
  BenefitEmpEnrollment401k,
  IBenefitEmpEnrollmentFormula,
  BenefitEmpEnrollmentFormula,
  FormulaTypeEnum,
  IEnrollmentHistoryRecord,
  EnrollmentHistoryRecord,
  IBenefitOption,
  BenefitOption,
} from '../../models/index';

import {
  IBenefitDetailsBasic,
  BenefitDetailsCalcMethod,
  BenefitDetailsBasic
} from '../../../../app-modules/benefits/models/index';
import { ReadFile } from '../../../../organization/models/index';
import { IEmpBeneficiaryRelation, EmpBeneficiaryRelation } from '../../models/employee-sections-benefits-management/beneficiary-relation';
import { IBeneficiary, Beneficiary } from '../../models/employee-sections-benefits-management/beneficiary';

@Injectable()
export class EmployeeSectionsBenefitsManagementMapService {

  constructor(
    private metaMapService: MetaMapService,
    private lookupMapService: LookupMapService,
    private benefitMapService: BenefitEmployeesMapService
  ) { }

  public mapToSection(data: IEmployeeSectionsBenefitsManagement): EmployeeSectionsBenefitsManagement {
    const section = new EmployeeSectionsBenefitsManagement();
    const { canMapPayroll, benefitsEnrollmentHistory, benefitEmpDependents, benefitClass } = data;
    if (_.isObjectLike(benefitsEnrollmentHistory)) {
      section.enrollmentSubsection = this.mapToEnrollment(benefitsEnrollmentHistory.data, benefitsEnrollmentHistory.metadata);
    }
    if (_.isObjectLike(benefitEmpDependents)) {
      section.dependentsSubsection = this.mapToDependents(benefitEmpDependents.data, benefitEmpDependents.metadata);
    }
    if (_.isObjectLike(benefitClass)) {
      section.benefitClassSubSection = this.mapToEmployeeBenefitClasses(benefitClass.data, benefitClass.metadata);
    }
    section.canMapPayroll = canMapPayroll;
    return section;
  }

  public mapToEnrollment(dto: IEmployeeSubsectionEnrollment, meta?: FieldsMeta): EmployeeSubsectionEnrollment {
    const enrollment = new EmployeeSubsectionEnrollment();
    if (_.isObjectLike(meta)) {
      enrollment.actions = this.metaMapService.mapActions(meta);
    }
    enrollment.enrollment = this.mapBenefitEnrollmentRecords(dto.benefitEnrolled);
    enrollment.enrollmentHistory = this.mapBenefitEnrollmentRecords(dto.benefitEnrolledHistory);
    enrollment.employeeInfo = this.mapBenefitEnrollmenentEmployeeInfo(dto.employeeInfo);
    return enrollment;
  }

  public mapToDependents(dto: IEmployeeSubsectionDependents, meta: FieldsMeta): EmployeeSubsectionDependents {
    const dependents = this.mapEmployeeDependents(dto);
    if (_.isObjectLike(meta)) {
      dependents.actions = this.metaMapService.mapActions(meta);
    }

    return dependents;
  }

  public mapBenefitEnrollmentRecords(dtos: IEnrollmentHistoryRecord[]): EnrollmentHistoryRecord[] {
    return _.map(dtos, x => this.mapBenefitEnrollmentHistoryRecord(x));
  }

  public mapBenefitEnrollmentHistoryRecord(dto: IEnrollmentHistoryRecord): EnrollmentHistoryRecord {
    let model = new EnrollmentHistoryRecord();
    model.benefitTierId = dto.benefitTierId;
    model.empToBenefitsId = dto.empToBenefitsId;
    model.benefitTierId = dto.benefitTierId;
    model.calculationMethod = dto.calculationMethod;
    model.cost = dto.cost;
    model.coverage = dto.inputCoverage ? dto.inputCoverage : dto.coverage;
    model.calculatedCoverage = dto.coverage;
    model.employeeContribution = dto.employeeContribution;
    model.employeePercentageContribution = dto.employeePercentageContribution;
    model.startDate = dateTimeUtils.convertFromDtoDateTimeString(dto.startDate);
    model.endDate = dateTimeUtils.convertFromDtoDateTimeString(dto.endDate);
    model.limitAmount = dto.limitAmount;
    model.notes = dto.notes;
    model.option = dto.option;
    model.benefitTierOptionId = dto.benefitTierOptionId;
    model.optionType = dto.optionType;
    model.plan = dto.plan;
    model.isDeleted = dto.isDeleted;
    model.calculationType = dto.calculationType;
    model.maxCap = dto.maxCap;
    model.multiplier = dto.multipler;
    model.maxEmployeePercentageContribution = dto.maxEmployeePercentageContribution;
    model.employerContributionAmount = dto.employerContributionAmount;
    model.costFreq = this.mapToBenefitPlanBasic<number, string>(dto.costFreq);
    model.empContFreq = this.mapToBenefitPlanBasic<number, string>(dto.empContFreq);
    model.calcMethod = this.mapToBenefitDetailsCalcMethod(dto.calcMethod);
    model.hasBeneficiaries = dto.hasBeneficiaries;
    model.benefitOptions = this.mapToBenefitOptions(dto.benefitOptions);
    model.payrollDeductionStartDate = dateTimeUtils.convertFromDtoDateTimeString(dto.payrollDeductionStartDate);
    model.payrollDeductionEndDate = dateTimeUtils.convertFromDtoDateTimeString(dto.payrollDeductionEndDate);
    model.dedStartDate = dateTimeUtils.convertFromDtoDateTimeString(dto.dedStartDate);
    model.dedEndDate = dateTimeUtils.convertFromDtoDateTimeString(dto.dedEndDate);

    let option = new BenefitOption();
    option.id = dto.benefitTierOptionId;
    option.code = dto.option;
    option.type = dto.optionType;
    model.selectedOption = option;

    return model;
  }

  public mapBenefitEnrollmentHistoryDto(model: EnrollmentHistoryRecord): IEnrollmentHistoryRecord {
    let dto = {
      empToBenefitsId: model.empToBenefitsId,
      benefitTierId: model.benefitTierId,
      calculationMethod: model.calculationMethod,
      cost: model.cost,
      coverage: model.coverage,
      calculatedCoverage: model.coverage,
      employeeContribution: model.employeeContribution,
      employeePercentageContribution: model.employeePercentageContribution,
      startDate: dateTimeUtils.convertToDtoDateTimeString(model.startDate),
      endDate: dateTimeUtils.convertToDtoDateTimeString(model.endDate),
      limitAmount: model.limitAmount,
      notes: model.notes,
      benefitTierOptionId: model.benefitTierOptionId,
      option: model.option,
      optionType: model.optionType,
      plan: model.plan,
      isDeleted: model.isDeleted,
      calculationType: model.calculationType,
      maxCap: model.maxCap,
      multipler: model.multiplier,
      maxEmployeePercentageContribution: model.maxEmployeePercentageContribution,
      employerContributionAmount: model.employerContributionAmount,
      costFreq: this.mapBenefitPlanBasicToDto<number, string>(model.costFreq),
      empContFreq: this.mapBenefitPlanBasicToDto<number, string>(model.empContFreq),
      calcMethod: this.mapBenefitPlanBasicToDto<string, string>(model.calcMethod),
      hasBeneficiaries: model.hasBeneficiaries,
      benefitOptions: null,
      selectedOption: null,
      payrollDeductionStartDate: dateTimeUtils.convertToDtoDateTimeString(model.payrollDeductionStartDate),
      payrollDeductionEndDate: dateTimeUtils.convertToDtoDateTimeString(model.payrollDeductionEndDate),
      dedStartDate: dateTimeUtils.convertToDtoDateTimeString(model.dedStartDate),
      dedEndDate: dateTimeUtils.convertToDtoDateTimeString(model.dedEndDate)
    };
    return dto;
  }

  public mapEmployeeDependents(dto: IEmployeeSubsectionDependents): EmployeeSubsectionDependents {
    const dependents = new EmployeeSubsectionDependents();
    dependents.canAddDependents = dto.canAddDependents;
    dependents.canRemoveDependents = dto.canRemoveDependents;
    dependents.canEditDependents = dto.canEditDependents;
    dependents.canEnrollDependents = dto.canEnrollDependents;
    dependents.canDropCoverageDependents = dto.canDropCoverageDependents;
    dependents.relations = _.map(dto.relations, r => this.benefitMapService.mapEmployeeDependentRelation(r));
    dependents.dependents = _.map(dto.empDependents, d => this.benefitMapService.mapDtoToEmployeeDependent(d));

    return dependents;
  }

  public mapToEmployeeBenefitClasses(dto: IEmployeeSubsectionBenefitClasses, meta: FieldsMeta): EmployeeSubsectionBenefitClasses {
    let empBenefitClasses = new EmployeeSubsectionBenefitClasses();
    let singleItem = _.size(dto.employeeBenefitClasses) <= 1;
    empBenefitClasses.empBenefitClasses = _.map(dto.employeeBenefitClasses, x => this.mapToEmployeeBenefitClass(x, singleItem));
    empBenefitClasses.actions = this.metaMapService.mapActions(meta);
    return empBenefitClasses;
  }

  public mapToBenefitClassesDTO(model: EmployeeSubsectionBenefitClasses): IEmployeeSubsectionBenefitClasses {
    return {
      employeeBenefitClasses: _.map(model.empBenefitClasses, x => this.mapToEmployeeBenefitClassDTO(x))
    };
  }

  public mapToEmployeeBenefitClassDTO(model: EmployeeBenefitClass): IEmployeeBenefitClass {
    return {
      id: model.id,
      benefitClassId: model.benefitClassId,
      empId: model.empId,
      startDate: dateTimeUtils.convertToDtoString(model.startDate),
      endDate: dateTimeUtils.convertToDtoString(model.endDate),
      updatedOn: dateTimeUtils.convertToDtoString(model.updatedOn),
      updatedBy: null,
      benefitClass: this.lookupMapService.mapBenefitClassDefinition(model.benefitClass),
      dateHired: model.dateHired ? dateTimeUtils.convertToDtoString(model.dateHired) : null
    };
  }

  public mapToBenefitClassDTO(model: BenefitClass): IBenefitClass {
    return {
      id: model.id,
      code: model.code,
      name: model.name,
      description: model.description,
      orgLevelId: model.orgLevelId,
      updatedBy: model.updatedBy,
      updatedOn: dateTimeUtils.convertToDtoString(model.updatedOn)
    };
  }

  public mapToEmployeeBenefitClass(dto: IEmployeeBenefitClass, editable: boolean = false): EmployeeBenefitClass {
    let model: EmployeeBenefitClass = new EmployeeBenefitClass();
    model.id = dto.id;
    model.empId = dto.empId;
    model.benefitClassId = dto.benefitClassId;
    model.startDate = dto.startDate ? dateTimeUtils.convertFromDtoString(dto.startDate) : null;
    model.endDate = dto.endDate ? dateTimeUtils.convertFromDtoString(dto.endDate) : null;
    model.updatedOn = dto.updatedOn ? dateTimeUtils.convertFromDtoString(dto.updatedOn) : null;
    model.updatedBy = dto.updatedBy;
    model.benefitClass = this.lookupMapService.mapToBenefitClassDefinitionDTO(dto.benefitClass);

    let now = moment().startOf('day').toDate();
    /*
    if (!editable) {
      model.isStartDateEditable = moment(model.startDate).isAfter(now);
      model.isEndDateEditable = !_.isNil(model.endDate) ? moment(model.endDate).isAfter(now) : true;
      model.isHistory = !model.isStartDateEditable && !model.isEndDateEditable;
    } else {
      model.isStartDateEditable = true;
      model.isEndDateEditable = true;
      model.isHistory = false;
    }
    */
    return model;
  }

  public mapToBenefitClass(dto: IBenefitClass): BenefitClass {
    let model: BenefitClass = new BenefitClass();
    model.id = dto.id;
    model.name = dto.name;
    model.description = dto.description;
    model.code = dto.code;
    model.orgLevelId = dto.orgLevelId;
    model.updatedBy = dto.updatedBy;
    model.updatedOn = dto.updatedOn ? dateTimeUtils.convertFromDtoString(dto.updatedOn) : null;
    return model;
  }

  public mapBenefitEnrollmenentEmployeeInfo(dto: IEmployeeInfo): EmployeeInfo {
    const employeeRecord = new EmployeeInfo();
    employeeRecord.id = dto.id;
    employeeRecord.fullName = dto.fullName;
    employeeRecord.dateHired = dateTimeUtils.convertFromDtoString(dto.dateHired);
    employeeRecord.terminationDate = dto.terminationDate;
    return employeeRecord;
  }

  public mapToAttachments(data: IEmployeeSectionsEnrollmentAttachments): EmployeeSectionsEnrollmentAttachments {
    const section = new EmployeeSectionsEnrollmentAttachments();
    section.benefitsManagementAttachmentsDTOs = this.mapToBenfitsEnrollmentAttachments(data.benefitsManagementAttachmentsDTOs);
    return section;
  }

  public mapToBenfitsEnrollmentAttachments(dtos: Array<IAttachmentFile>): EnrollementAttachmentFile[] {
    return _.map(dtos, v => this.mapToAttachment(v));
  }

  private mapToAttachment(record: IAttachmentFile): EnrollementAttachmentFile {
    const enrollmentAttachment = new EnrollementAttachmentFile();
    enrollmentAttachment.addedBy = record.addedBy;
    enrollmentAttachment.addedOn = dateTimeUtils.convertFromDtoDateTimeString(record.addedOn);
    enrollmentAttachment.category = record.category;
    enrollmentAttachment.fileName = record.fileName;
    enrollmentAttachment.name = record.name;
    enrollmentAttachment.id = record.id;
    return enrollmentAttachment;
  }

  public mapFilesToFormData(files: ReadFile[], customName: string): FormData {
    const formData: FormData = new FormData();
    _.forEach(files, (file: ReadFile, index: number) => {
      if (customName) {
        formData.append(`i${index}_name`, customName);
      } else {
        formData.append(`i${index}_name`, file.name);
      }
      formData.append(`i${index}_fileName`, file.fileName);
      formData.append(`i${index}_category`, file.mimeType);
      formData.append(`i${index}_file`, file.data, file.fileName);
    });
    return formData;
  }

  public mapToBenefitPlanShortInfos(data: IBenefitPlanShortInfo[], meta: Meta): BenefitPlanShortInfo[] {
    return _.map(data, d => this.mapToBenefitPlanShortInfo(d));
  }

  public mapToBenefitPlanShortInfo(d: IBenefitPlanShortInfo): BenefitPlanShortInfo {
    let model = new BenefitPlanShortInfo();
    model.id = d.id;
    model.name = d.name;
    model.benefitGroupId = d.benefitGroupId;
    model.calculationMethod = d.calculationMethod;
    model.status = d.status;
    model.benefitLineId = d.benefitLineId;
    model.enrolledOutEffectiveDate = d.enrolledOutEffectiveDate;
    model.startDate = dateTimeUtils.convertFromDtoDateTimeString(d.startDate);
    model.endDate = dateTimeUtils.convertFromDtoDateTimeString(d.endDate);
    model.payrollDeductionStartDate = dateTimeUtils.convertFromDtoDateTimeString(d.payrollDeductionStartDate);
    model.payrollDeductionEndDate = dateTimeUtils.convertFromDtoDateTimeString(d.payrollDeductionEndDate);
    model.dedStartDate = dateTimeUtils.convertFromDtoDateTimeString(d.dedStartDate);
    model.dedEndDate = dateTimeUtils.convertFromDtoDateTimeString(d.dedEndDate);
    return model;
  }

  public mapToBenefitPlanOptionRates(data: IBenefitsEmpEnrollOptionRate[], meta: Meta): BenefitsEmpEnrollOptionRate[] {
    return _.map(data, (d) => this.mapToBenefitPlanOptionRate(d));
  }

  public mapToBenefitPlanOptionRate(dto: IBenefitsEmpEnrollOptionRate): BenefitsEmpEnrollOptionRate {
    let model = new BenefitsEmpEnrollOptionRate();
    model.id = dto.id;
    model.lineId = dto.lineId;
    model.tierId = dto.tierId;
    model.tierName = dto.tierName;
    model.tierOptionCode = dto.tierOptionCode;
    model.tierOptionCost = dto.tierOptionCost;
    model.tierOptionEmpContribution = dto.tierOptionEmpContribution;
    model.tierOptionErContribution = dto.tierOptionErContribution;
    model.tierOptionType = dto.tierOptionType;
    model.costFreq = this.mapToBenefitPlanBasic<number, string>(dto.costFreq);
    model.empContFreq = this.mapToBenefitPlanBasic<number, string>(dto.empContFreq);
    return model;
  }

  public mapToBenefitTierDefinitions(dto: IBenefitTierDefinition[], meta: Meta): BenefitTierDefinition[] {
    return _.map(dto, (d) => this.mapToBenefitTierDefinition(d));
  }

  public mapToBenefitTierDefinition(dto: IBenefitTierDefinition): BenefitTierDefinition {
    let model = new BenefitTierDefinition();
    model.id = dto.id;
    model.name = dto.name;
    return model;
  }

  public mapToBenefitsEmpEnrollmentFlat(dto: IBenefitsEmpEnrollmentFlat, meta: Meta): BenefitsEmpEnrollmentFlat {
    let model = new BenefitsEmpEnrollmentFlat();
    model.tierId = dto.tierId;
    model.lineId = dto.lineId;
    model.tierName = dto.tierName;
    model.erContribution = dto.erContribution;
    model.empContribution = dto.empContribution;
    return model;
  }

  public mapToBenefitsEmpEnrollment401k(data: IBenefitEmpEnrollment401k, meta: Meta): BenefitEmpEnrollment401k {
    let model = new BenefitEmpEnrollment401k();
    model.id = data.id;
    model.limitAmount = data.limitAmount;
    model.lineId = data.lineId;
    model.maxEmployeePercentContribution = data.maxEmployeePercentContribution;
    model.tierId = data.tierId;
    return model;
  }

  public mapToBenefitsEmpEnrollmentFormula(data: IBenefitEmpEnrollmentFormula, meta: Meta): BenefitEmpEnrollmentFormula {

    let model = new BenefitEmpEnrollmentFormula();

    model.id = data.id;
    model.formulaType = data.formulaType;
    model.lineId = data.lineId;
    model.tierId = data.tierId;

    model.maxCap = data.maxCap;
    model.multipler = data.multipler;
    model.fixedAmount = data.fixedAmount;
    if (data.formulaType === FormulaTypeEnum.MultiplerMaxCap || data.formulaType === FormulaTypeEnum.AnyWithMaxCap){
      model.formulaValue = null;
    } else{
      model.formulaValue = data.formulaValue;
    }
    model.calculatedCoverage = data.formulaValue;
    model.empFormulaType = data.empFormulaType;
    model.erFormulaType = data.erFormulaType;
    model.empContribution = data.empContribution;
    model.erContribution = data.erContribution;
    model.empFormulaExpression = data.empFormulaExpression;
    model.erFormulaExpression = data.erFormulaExpression;
    model.covFormulaExpression = data.covFormulaExpression;

    return model;

  }

  public mapToRelations(relations: IEmpBeneficiaryRelation[]): EmpBeneficiaryRelation[] {
    return _.map(relations, (r) => this.mapToRelation(r));
  }

  public mapToRelation(dto: IEmpBeneficiaryRelation): EmpBeneficiaryRelation {
    let model: EmpBeneficiaryRelation = new EmpBeneficiaryRelation();
    model.id = dto.id;
    model.name = dto.name;
    return model;
  }

  public mapToRelationDto(model: EmpBeneficiaryRelation): IEmpBeneficiaryRelation {
    return {
      id: model.id,
      name: model.name
    };
  }

  public mapToBeneficiaries(empBeneficiaries: IBeneficiary[], meta: Meta): Beneficiary[] {
    return _.map(empBeneficiaries, (b) => this.mapToBeneficiary(b));
  }
  public mapToBeneficiariesDto(empBeneficiaries: Beneficiary[]): IBeneficiary[] {
    return _.map(empBeneficiaries, (b) => this.mapToBeneficiaryDto(b));
  }

  public mapToBeneficiaryDto(b: Beneficiary): IBeneficiary {
    return {
      addedBy: b.addedBy,
      addedDate: dateTimeUtils.convertToDtoDateTimeString(b.addedDate),
      birthDate: dateTimeUtils.convertToDtoDateTimeString(b.birthDate),
      modifiedDate: dateTimeUtils.convertToDtoDateTimeString(b.modifiedDate),
      address: b.address,
      contigentPercentage: b.contigentPercentage,
      empToBenefitsId: b.empToBenefitsId,
      firstName: b.firstName,
      id: b.id,
      isDeleted: b.isDeleted,
      lastName: b.lastName,
      modifiedBy: b.modifiedBy,
      primaryPercentage: b.primaryPercentage,
      relation: this.mapToRelationDto(b.relation),
      secondName: b.secondName,
      ssn: b.ssn
    };
  }

  public mapToBeneficiary(dto: IBeneficiary): Beneficiary {
    let model: Beneficiary = new Beneficiary();
    model.id = dto.id;
    model.empToBenefitsId = dto.empToBenefitsId;
    model.addedBy = dto.addedBy;
    model.addedDate = dto.addedDate ? dateTimeUtils.convertFromDtoString(dto.addedDate) : null;
    model.modifiedDate = dto.modifiedDate ? dateTimeUtils.convertFromDtoString(dto.modifiedDate) : null;
    model.modifiedBy = dto.modifiedBy;
    model.address = dto.address;
    model.birthDate = dto.birthDate ? dateTimeUtils.convertFromDtoString(dto.birthDate) : null;
    model.contigentPercentage = dto.contigentPercentage;
    model.firstName = dto.firstName;
    model.isDeleted = dto.isDeleted;
    model.lastName = dto.lastName;
    model.relation = this.mapToRelation(dto.relation);
    model.primaryPercentage = dto.primaryPercentage;
    model.secondName = dto.secondName;
    model.ssn = dto.ssn;

    model.isPrimary = _.isNumber(dto.primaryPercentage) && dto.primaryPercentage > 0;
    return model;
  }

  public mapToBenefitDetailsCalcMethod<T, D>(dto: IBenefitDetailsBasic<T, D>): BenefitDetailsCalcMethod {
    let calcMethod = new BenefitDetailsCalcMethod('', '', '');
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      const { id, name } = dto;
      calcMethod = new BenefitDetailsCalcMethod(id as any, name as any, name as any);
    }
    return calcMethod;
  }

  private mapToBenefitPlanBasic<A, B>(dto: IBenefitDetailsBasic<A, B>): BenefitDetailsBasic<A, B> {
    let id = null;
    let name = null;
    if (_.isObjectLike(dto) && _.size(dto) > 0) {
      id = dto.id;
      name = dto.name;
    }
    return new BenefitDetailsBasic<A, B>(id, name, name);
  }

  private mapBenefitPlanBasicToDto<A, B>(b: BenefitDetailsBasic<A, B>): IBenefitDetailsBasic<A, B> {
    let dto = {} as IBenefitDetailsBasic<A, B>;
    if (_.isObjectLike(b) && _.size(b) > 0) {
      dto.id = _.isString(b.id) || _.isFinite(b.id) && (b.id as any) !== -1 ? b.id : null;
      dto.name = _.isString(b.name) ? b.name : null;
    }
    return dto;
  }

  public mapToBenefitOptions(data: IBenefitOption[]): BenefitOption[] {
    return _.map(data, (d) => this.mapToBenefitOption(d));
  }
  public mapToBenefitOption(dto: IBenefitOption): BenefitOption {
    let model = new BenefitOption();
    model.id = dto.id;
    model.code = dto.code;
    model.type = dto.type;
    return model;
  }

}

