import * as tslib_1 from "tslib";
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorHandlingService } from './../../../core/services/error-handling/error-handling.service';
import { NotificationMessage } from '../../../app.messages';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import { ColumnSettingsDefinitions } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ColumnSettingsStorageService, ExceptionConsoleNavigationService } from '../../../common/services/index';
import { ExceptionConsoleState } from '../../models/index';
import { PayCycle } from '../../../organization/models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { ExceptionConsoleApiService, TimecardsApiService } from '../index';
import { StateManagementService } from '../../../common/services/index';
import { ComponentStateStorageService } from '../../../common/services/component-state/component-state-storage.service';
import { StateResetTypes } from '../../../core/models/settings/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { TimecardApprovalRequest, PayCycleApprovalRequest } from '../../models/timecards/timecards-approval-request';
var ExceptionConsoleManagementService = /** @class */ (function () {
    function ExceptionConsoleManagementService(router, route, apiService, timecardsApiService, columnSettingsStorageService, stateService, storageService, errorService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.apiService = apiService;
        this.timecardsApiService = timecardsApiService;
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.stateService = stateService;
        this.storageService = storageService;
        this.errorService = errorService;
        this.m_exceptionOnlyControlId = 'exceptionOnlyControl';
        this.m_resetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.onLoadStatus$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
        this.onLoaded$ = new ReplaySubject(1);
        this.onRecordsSelected$ = new ReplaySubject(1);
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return _this.validateOrgLevel(o); })
            .subscribe(function (o) {
            _this.onOrgLevelChanged(o);
            _this.onRecordsSelected([]);
        });
        this.state = new ExceptionConsoleState();
        this.state.createColumns();
        this.state.mapColumns();
        this.state.exceptionOnly = this.getExceptionOnlyStates();
        this.state.columns = this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, this.state.columns);
        this.onStateChanged(this.state);
    }
    ExceptionConsoleManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    ExceptionConsoleManagementService.prototype.onStateChanged = function (state) {
        this.onStateChanged$.next(state);
        this.columnSettingsStorageService.setColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, this.state.columns);
        this.saveExceptionOnlyStates();
    };
    ExceptionConsoleManagementService.prototype.onLoadStatusChanged = function (isLoading) {
        this.onLoadStatus$.next(isLoading);
    };
    ExceptionConsoleManagementService.prototype.loadExceptionConsoleRecords = function (orgLevelId, range, exactPayCycle) {
        var _this = this;
        this.onLoadStatusChanged(true);
        var pc = new PayCycle();
        pc.startDate = range.startDate;
        pc.endDate = range.endDate;
        this.apiService.getExceptionConsoleRecords(orgLevelId, range.startDate, range.endDate, exactPayCycle)
            .then(function (container) {
            _this.container = container;
            _this.state.setDynamicColumns(_this.container.exceptionColumns);
            _this.state.exceptionOnly = _this.getExceptionOnlyStates();
            _this.state.columns = _this.columnSettingsStorageService.getColumnsState(ColumnSettingsDefinitions.EXCEPTION_CONSOLE.toString(), undefined, _this.state.columns);
            _this.container.payCycle = pc;
            _this.onLoaded(container);
            _this.onStateChanged(_this.state);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    ExceptionConsoleManagementService.prototype.approveTimecards = function (records) {
        var _this = this;
        var request = new TimecardApprovalRequest();
        _.forEach(records, function (record) {
            var payCycle = _.find(request.payCycles, function (item) { return item.startDate === dateTimeUtils.convertToDtoString(record.payCycle.startDate) && item.endDate === dateTimeUtils.convertToDtoString(record.payCycle.endDate); });
            if (!payCycle) {
                payCycle = new PayCycleApprovalRequest();
                payCycle.startDate = dateTimeUtils.convertToDtoString(record.payCycle.startDate);
                payCycle.endDate = dateTimeUtils.convertToDtoString(record.payCycle.endDate);
                payCycle.employeeIds = [];
                payCycle.employeeIds.push(record.employee.id);
                request.payCycles.push(payCycle);
            }
            else {
                payCycle.employeeIds.push(record.employee.id);
            }
        });
        if (request.payCycles.length > 0) {
            this.onLoadStatusChanged(true);
            this.timecardsApiService.approveTimecards(this.currentOrgLevel.id, request)
                .then(function (result) {
                _this.onLoadStatusChanged(false);
            })
                .catch(function (reason) {
                _this.onLoadStatusChanged(false);
            });
        }
    };
    ExceptionConsoleManagementService.prototype.onLoaded = function (container) {
        this.onLoaded$.next(container);
        this.onRecordsSelected([]);
    };
    ExceptionConsoleManagementService.prototype.onRecordsSelected = function (records) {
        this.selectedRecords = records;
        this.onRecordsSelected$.next(records);
    };
    ExceptionConsoleManagementService.prototype.navigateToDateRange = function (range, usePayCycle) {
        this.currentRange = range;
        if (this.currentOrgLevel) {
            var nav = new ExceptionConsoleNavigationService(this.router, this.route);
            nav.navigateToExceptionConsoleDates(this.currentOrgLevel.id, range.startDate, range.endDate, usePayCycle);
        }
    };
    ExceptionConsoleManagementService.prototype.setDateRange = function (range) {
        this.currentRange = range;
        if (this.currentOrgLevel) {
            this.loadExceptionConsoleRecords(this.currentOrgLevel.id, this.currentRange, this.exactPayCycle);
        }
    };
    ExceptionConsoleManagementService.prototype.validateOrgLevel = function (o) {
        var orgLevelType = _.get(o, 'type');
        var isSameOrgLevel = _.get(this.currentOrgLevel, 'id') === _.get(o, 'id');
        return !isSameOrgLevel && orgLevelType === OrgLevelType.department;
    };
    ExceptionConsoleManagementService.prototype.onOrgLevelChanged = function (o) {
        this.currentOrgLevel = o;
        if (this.currentOrgLevel && this.currentRange) {
            this.loadExceptionConsoleRecords(this.currentOrgLevel.id, this.currentRange, this.exactPayCycle);
        }
    };
    ExceptionConsoleManagementService.prototype.saveExceptionOnlyStates = function () {
        this.storageService.setControlState(this.stateService.componentKey, this.m_exceptionOnlyControlId, { value: this.state.exceptionOnly }, this.m_resetType, this.stateKey);
        this.stateService.controlValueChanged(this.m_exceptionOnlyControlId);
    };
    ExceptionConsoleManagementService.prototype.getExceptionOnlyStates = function () {
        var state = this.storageService.getControlState('ExceptionConsoleComponent', this.m_exceptionOnlyControlId);
        return (state && state.value) ? state.value : false;
    };
    ExceptionConsoleManagementService.prototype.extractIdsForModification = function (records) {
        var filtered = _.filter(records, function (rec) { return rec.canModifyOwnTimecard; });
        var ids = _.map(filtered, function (record) { return record.employee.id; });
        if (filtered.length < records.length) {
            this.errorService.info(new NotificationMessage('Timecard is readonly.', "You are not authorized to approve your own timecard"));
        }
        return ids;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ExceptionConsoleManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExceptionConsoleManagementService.prototype, "orgLevelSubscription", void 0);
    return ExceptionConsoleManagementService;
}());
export { ExceptionConsoleManagementService };
