<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
      <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
          <kendo-grid #kendoGrid class="slx-full-height"
            [data]="gridState?.view"
            [selectable]="{ mode: 'single' }"
            [filterable]="true"
            [filter]="gridState.state.filter"
            [sortable]="true"
            [sort]="gridState.state.sort"
            (selectionChange)="gridState.selectionChange($event)"
            (dataStateChange)="gridState.dataStateChange($event)"
            (cancel)="gridState.cancelHandler($event)"
            (edit)="gridState.editHandler($event)"
            (remove)="gridState.removeHandler($event)"
            (save)="gridState.saveHandler($event)"
          >

              <!-- desktop view -->
              <kendo-grid-command-column *ngIf="!access.restrictedByOrgLevel" width="80" [locked]="true" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
                      <div class="command-container">
                          <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button"
                                  [disabled]="management?.editingItem && management?.editingItem != dataItem">
                              <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                          </button>
                          <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid" class="theme-icon-button theme-inline-apply-button">
                              <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
                          </button>
                          <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
                              <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
                          </button>
                      </div>
                  </ng-template>
              </kendo-grid-command-column>

              <kendo-grid-column title="Code" field="code" [filterable]="true" [editable]="!gridState.editedRecord?.code" width="150" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align">{{ dataItem.code }}</span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator>
                          <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.code" [required]="true"
                                 [placeholder]="" [prohibitedValues]="prohibitedNameValues" slxMaxLength="3"
                              name="nameInput{{rowIndex}}">
                          <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                          <span errorMessage for="required"></span>
                        <span errorMessage for="slxMaxLength">Code should be a maximum length of '3'</span>
                      </slx-input-decorator>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-string-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Description" field="description" [filterable]="true" width="200" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align">{{ dataItem.description }}</span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator>
                          <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.description" [required]="true" [placeholder]="" name="descriptionInput{{rowIndex}}">
                          <span errorMessage for="required"></span>
                      </slx-input-decorator>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-string-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Color" field="color" [filterable]="false" width="80" media="(min-width: 450px)" class="no-padding">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <div class="col absence-color color-border" [style.background-color]="dataItem.color | DecHex"></div>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <div class="color-picker">
                          <slx-input-decorator>
                              <input class="hidden-input" type="hidden" slx-input [required]="true" placeholder="">
                              <input id="colorField{{rowIndex}}" type="text" class="color-border color-picker-input" [colorPicker]="getColor (dataItem)"
                                  [cpPosition]="'bottom'" [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" [style.background]="getColor (dataItem)"
                                  (colorPickerChange)="onColorPickerChange(dataItem, $event)" readonly [cpDialogDisplay]="'popup'"
                                  [cpUseRootViewContainer]="true" [placeholder]="''">
                              <span errorMessage for="required"></span>
                          </slx-input-decorator>
                      </div>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Paid" field="isPaid" [filterable]="true" filter="boolean" width="100" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.isPaid">
                          <i class="fa fa-check" aria-hidden="true"></i>
                      </span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator className="slx-form-group slx-no-border small-font">
                          <slx-checkbox-input slx-input [(ngModel)]="dataItem.isPaid" fieldName="isPaidField{{rowIndex}}" name="isPaid{{rowIndex}}"
                              className="reset-display">
                          </slx-checkbox-input>
                      </slx-input-decorator>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
                    </slx-kendo-grid-boolean-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="PTO Planner" field="ptoPlannerIndicator" [filterable]="true"  width="120" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.ptoPlannerIndicator">
                          <i class="fa fa-check" aria-hidden="true"></i>
                      </span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator className="slx-form-group slx-no-border small-font">
                          <slx-checkbox-input slx-input [(ngModel)]="dataItem.ptoPlannerIndicator" fieldName="ptoPlannerIndicatorField{{rowIndex}}" name="ptoPlannerIndicator{{rowIndex}}"
                              className="reset-display">
                          </slx-checkbox-input>
                      </slx-input-decorator>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
                    </slx-kendo-grid-boolean-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="LOA" field="loaIndicator" [filterable]="true"  width="120" media="(min-width: 450px)">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span *ngIf="dataItem.loaIndicator">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </span>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <slx-input-decorator className="slx-form-group slx-no-border small-font">
                            <slx-checkbox-input slx-input [(ngModel)]="dataItem.loaIndicator" fieldName="loaIndicatorField{{rowIndex}}" name="loaIndicator{{rowIndex}}"
                                className="reset-display">
                            </slx-checkbox-input>
                        </slx-input-decorator>
                    </ng-template>
                    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                      <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
                      </slx-kendo-grid-boolean-filter>
                    </ng-template>
                </kendo-grid-column>

              <kendo-grid-column title="ESS" field="essPresent" [filterable]="true"  width="100" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span *ngIf="dataItem.essPresent">
                          <i class="fa fa-check" aria-hidden="true"></i>
                      </span>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator className="slx-form-group slx-no-border small-font">
                          <slx-checkbox-input slx-input [(ngModel)]="dataItem.essPresent" fieldName="essPresentField{{rowIndex}}" name="essPresent{{rowIndex}}"
                              className="reset-display">
                          </slx-checkbox-input>
                      </slx-input-decorator>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-boolean-filter [column]="column" [filter]="filter">
                    </slx-kendo-grid-boolean-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="TA Code(s)" field="taCode" [filterable]="true" width="150" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align">{{ dataItem.taCode }}</span>
                  </ng-template>
                  <!--
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator>
                          <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.taCode" [required]="true" [placeholder]="" name="taCode{{rowIndex}}">
                          <span errorMessage for="required"></span>
                      </slx-input-decorator>
                  </ng-template>
                   -->
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-string-filter>
                  </ng-template>
              </kendo-grid-column>


              <kendo-grid-column title="Updated By" field="lastUpdateUsername" [filterable]="true" width="200" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align">{{ dataItem.lastUpdateUsername }}</span>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-string-filter>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Last Updated" field="lastUpdateDate"  [filterable]="true" width="200" media="(min-width: 450px)">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <span class="left-align" *ngIf="dataItem.lastUpdateDate">{{ dataItem.lastUpdateDate | amDateFormat: appConfig.dateTimeFormat }}</span>
                  </ng-template>
                  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
                    <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
                    </slx-kendo-grid-date-filter>
                  </ng-template>
              </kendo-grid-column>

              <!-- mobile view -->
              <kendo-grid-column media="(max-width: 450px)" width="100%">
                  <ng-template kendoGridHeaderTemplate>Absences</ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <slx-configure-absence-renderer [rowIndex]="rowIndex" [item]="dataItem" (actionEmitter)="onMobileRendererEvent ($event, dataItem, rowIndex)"></slx-configure-absence-renderer>
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-configure-absence-editor [item]="dataItem" [rowIndex]="rowIndex" [prohibitedNameValues]="prohibitedNameValues" (actionEmitter)="onMobileEditorEvent ($event, dataItem, rowIndex)"></slx-configure-absence-editor>
                  </ng-template>
              </kendo-grid-column>
          </kendo-grid>
      </form>

      <div class="button-box" [ngClass]="{'inactive': access.lockActions}">
        <div class="col-xs-12 col-sm-9 col-md-9">
          <span *ngIf="access.restrictedByOrgLevel" class="edit-info-warning">Absences are not editable on this level</span>
        </div>
        <div class="col-xs-12 col-sm-3 col-md-3">
          <div class="buttons">
            <button class="action-button" [slx-list-delete-operation]="onDeletedItems">Delete</button>
            <button class="action-button" [slx-list-add-operation]="onAddItem">Add</button>
          </div>
        </div>
      </div>
  </div>
</slx-spinner>
