/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../tree/components/tree-root/tree-root.component.ngfactory";
import * as i2 from "../../../tree/components/tree-root/tree-root.component";
import * as i3 from "./query-builder.component";
var styles_QueryBuilderComponent = [];
var RenderType_QueryBuilderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QueryBuilderComponent, data: {} });
export { RenderType_QueryBuilderComponent as RenderType_QueryBuilderComponent };
export function View_QueryBuilderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "slx-tree-root-component", [], null, null, null, i1.View_TreeRootComponent_0, i1.RenderType_TreeRootComponent)), i0.ɵdid(1, 49152, null, 0, i2.TreeRootComponent, [], { treeModel: [0, "treeModel"], templates: [1, "templates"] }, null), i0.ɵpod(2, { treeRootTemplate: 0, treeNodeTemplate: 1, treeNodeFullTemplate: 2, treeNodeChildrenTemplate: 3 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.treeModel; var currVal_1 = _ck(_v, 2, 0, _co.treeRootTemplate, _co.treeNodeTemplate, _co.treeNodeFullTemplate, _co.treeNodeChildrenTemplate); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_QueryBuilderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "slx-query-builder-component", [], null, null, null, View_QueryBuilderComponent_0, RenderType_QueryBuilderComponent)), i0.ɵdid(1, 114688, null, 4, i3.QueryBuilderComponent, [], null, null), i0.ɵqud(335544320, 1, { treeRootTemplate: 0 }), i0.ɵqud(335544320, 2, { treeNodeTemplate: 0 }), i0.ɵqud(335544320, 3, { treeNodeFullTemplate: 0 }), i0.ɵqud(335544320, 4, { treeNodeChildrenTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QueryBuilderComponentNgFactory = i0.ɵccf("slx-query-builder-component", i3.QueryBuilderComponent, View_QueryBuilderComponent_Host_0, { treeModel: "treeModel" }, {}, []);
export { QueryBuilderComponentNgFactory as QueryBuilderComponentNgFactory };
