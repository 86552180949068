import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SelectableSettings } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../../app/app.config';
import { ModalService, ModalAnchorDirective } from '../../../../../../common/index';
import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitDetailsTier } from '../../../../models/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { ConfirmOptions, ConfirmDialog2Component } from './../../../../../../common/components/index';
import { BenefitEmployeesApiService, BenefitEnrollmentCommonService } from '../../../../services/index';
import { subscribeToOrgLevel } from '../../../../../../organization/selectors/index';
import { BenefitEnrolledEmployeePreview } from '../../../../models/benefit-employees/benefit-enrolled-employee-preview';
import { NgForm } from '@angular/forms';
var BenefitOptionFormulaGridComponent = /** @class */ (function () {
    function BenefitOptionFormulaGridComponent(manService, commonValidationService, cdr, modalService, apiService) {
        this.manService = manService;
        this.commonValidationService = commonValidationService;
        this.cdr = cdr;
        this.modalService = modalService;
        this.apiService = apiService;
        this.columnGroup = 'BenefitOptionFormula';
        this.data = [];
        this.isLoading = true;
        this.isCoverageEditable = true;
        this.editedRowsCount = 0;
        this.employeeContribution = 0;
        this.employerContribution = 0;
        this.coverage = null;
        this.stepcurrency = 0.01;
        this.employeeContributionDefined = true;
        this.employerContributionDefined = true;
        this.coverageDefined = true;
        this.editeditems = [];
        this.showCalculate = new EventEmitter();
        this.subscriptions = {};
        this.pageSize = 50;
        this.isFormValid = true;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.selectableSettings = {
            checkboxOnly: true
        };
        this.appConfig = appConfig;
        this.onEnrollmentCancel = new EventEmitter();
    }
    BenefitOptionFormulaGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hasViewChanged = this.manService.viewModeIsChanged;
        this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, function () { return _this.orgLevel; }, function (orgLevel) {
            _this.orgLevel = orgLevel;
            if (!_this.hasViewChanged) {
                _this.reload();
            }
            else {
                _this.hasViewChanged = false;
                _this.renderRecords();
            }
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.calculateCoverage = this.manService.subscribeTocalculateCoverage(function () {
            _.forEach(_this.editeditems, function (e) {
                var value = e.coverage;
                _this.calculateFormula(value, e);
            });
            _this.editeditems = [];
        });
        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(function () {
            _this.isFormValid = _this.ngForm.valid;
            _this.manService.benefitsOptionTabDetailsChanged(!_this.isFormValid);
        });
    };
    BenefitOptionFormulaGridComponent.prototype.populateValues = function () {
        this.getCoverageOptions();
        this.getEmployeeContribution();
        this.getEmployerContribution();
        this.getCoverage();
        this.getCoverageValue();
        this.validatePrerequisites();
        if ((this.tierDetails.erFormula.expression || this.tierDetails.empFormula.expression || this.tierDetails.coverageFormula.expression) && (this.employeeContributionDefined && this.employerContributionDefined && this.coverageDefined)) {
            this.formulaSelectionStart(true);
        }
    };
    BenefitOptionFormulaGridComponent.prototype.emitChanges = function () {
        this.showCalculate.emit(true);
    };
    BenefitOptionFormulaGridComponent.prototype.ngOnDestroy = function () {
    };
    BenefitOptionFormulaGridComponent.prototype.ngAfterViewInit = function () {
        this.cdr.detectChanges();
    };
    BenefitOptionFormulaGridComponent.prototype.validatePrerequisites = function () {
        if (!this.employeeContributionDefined || !this.employerContributionDefined || !this.coverageDefined) {
            var message = '';
            message = 'Employees cannot be enrolled as ';
            if (!this.employeeContributionDefined && !this.employerContributionDefined && !this.coverageDefined) {
                message += 'the Employee and Employer Contribution and Coverage amounts have';
            }
            else if (!this.employeeContributionDefined && !this.employerContributionDefined) {
                message += 'the Employee and Employer Contribution amounts have';
            }
            else if (!this.employeeContributionDefined && !this.coverageDefined) {
                message += 'the Employee Contribution and Coverage amounts have';
            }
            else if (!this.employerContributionDefined && !this.coverageDefined) {
                message += 'the Employer Contribution and Coverage amounts have';
            }
            else if (!this.employeeContributionDefined) {
                message += 'the Employee Contribution amount has';
            }
            else if (!this.employerContributionDefined) {
                message += 'the Employer Contribution amount has';
            }
            else if (!this.coverageDefined) {
                message += 'the Coverage amount has';
            }
            message += ' not been created for this benefit plan. Please add contribution and Coverage amounts for this plan to enroll employees.';
            this.coverageSelectionStart(message, true);
        }
    };
    BenefitOptionFormulaGridComponent.prototype.getCoverageValue = function () {
        if (this.tierDetails.coverageFormula.isFixedCalcType) {
            this.isCoverageEditable = false;
            this.manService.updateEmployeesUpdatedInfo(this.data);
            this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
            return this.tierDetails.coverageFormula.fixedAmount;
        }
        else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
            this.isCoverageEditable = false;
            this.manService.updateEmployeesUpdatedInfo(this.data);
            this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
            return this.tierDetails.coverageFormula.expression;
        }
        else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
            this.isCoverageEditable = true;
            return null;
        }
        else {
            return null;
        }
    };
    BenefitOptionFormulaGridComponent.prototype.getCoverageOptions = function () {
        if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
            var multiplier = this.tierDetails.coverageFormula.multipler;
            var maxCap = this.tierDetails.coverageFormula.maxCap;
            multiplier = _.isNumber(multiplier) ? multiplier : 500;
            maxCap = _.isNumber(maxCap) ? maxCap : 10000;
            var range = _.range(multiplier, maxCap + multiplier, multiplier);
            var opts = _.map(range, function (value) { return ({ name: _.toString(value), value: value }); });
            this.coverageMultipllierOptions = opts;
        }
    };
    BenefitOptionFormulaGridComponent.prototype.onChangeCoverageWithMultiplier = function (value, dataItem, hasError) {
        if (!hasError) {
            var newValue = _.isObjectLike(value) && _.isFinite(value.value) ? value.value : null;
            var hasValueChanged = dataItem.coverage !== newValue;
            if (hasValueChanged) {
                dataItem.coverage = newValue;
                this.calculateFormula(dataItem.coverage, dataItem);
            }
        }
        else {
            dataItem.coverage = null;
            this.validateInputs();
        }
    };
    BenefitOptionFormulaGridComponent.prototype.calculateFormula = function (coverage, dataItem) {
        var _this = this;
        if (coverage === dataItem.originalCoverage) {
            return;
        }
        dataItem.coverage = coverage;
        if (this.tierDetails.coverageFormula.isFormulaCalcType ||
            this.tierDetails.erFormula.isFormulaCalcType ||
            this.tierDetails.empFormula.isFormulaCalcType) {
            this.isLoading = true;
            this.manService.benefitsOptionTabDetailsChanged(true);
            this.apiService.getBenefitEnrolledPreviewEmployees(this.orgLevel.id, this.tierDetails.id, this.date, dataItem.coverage, [dataItem])
                .then(function (records) {
                var employeeMap = new Map(records.map(function (employee) { return [employee.employeeId, employee]; }));
                _this.data = _.map(_this.selectedEmployees, function (employee) {
                    var record = employeeMap.get(employee.employeeId);
                    if (record) {
                        dataItem.employeeContributionAmount = record.employeeContributionAmount;
                        dataItem.employerContributionAmount = record.employerContributionAmount;
                        employee.calculatedCoverage = record.coverage;
                        dataItem.originalCoverage = record.coverage;
                    }
                    return employee;
                });
            }).finally(function () {
                _this.isLoading = false;
                _this.updateRowsData();
            });
        }
        else {
            this.updateRowsData();
        }
    };
    BenefitOptionFormulaGridComponent.prototype.updateEditedItems = function (dataItem) {
        if (_.filter(this.editeditems, function (e) { return e.employeeId == dataItem.employeeId; }).length == 0) {
            this.editeditems.push(dataItem);
        }
        else {
            _.remove(this.editeditems, function (x) { return x.employeeId === dataItem.employeeId; });
            this.editeditems.push(dataItem);
        }
        this.emitChanges();
    };
    BenefitOptionFormulaGridComponent.prototype.onCoverageValueChange = function (value, dataItem, hasError) {
        if (!hasError) {
            dataItem.coverage = value;
            this.updateEditedItems(dataItem);
        }
        else {
            dataItem.coverage = null;
        }
        this.validateInputs();
    };
    BenefitOptionFormulaGridComponent.prototype.getEmployeeContribution = function () {
        if (this.tierDetails && this.tierDetails.empFormula) {
            if (this.tierDetails.empFormula.isFixedCalcType && _.gte(this.tierDetails.empFormula.fixedAmount, 0)) {
                this.employeeContributionDefined = true;
                this.employeeContribution = this.tierDetails.empFormula.fixedAmount;
            }
            else if (this.tierDetails.empFormula.isFormulaCalcType) {
                this.employeeContributionDefined = !_.isNil(this.tierDetails.empFormula.expression) && _.size(this.tierDetails.empFormula.expression) > 0;
            }
            else {
                this.employeeContributionDefined = false;
            }
        }
    };
    BenefitOptionFormulaGridComponent.prototype.getEmployerContribution = function () {
        if (this.tierDetails && this.tierDetails.erFormula) {
            if (this.tierDetails.erFormula.isFixedCalcType && _.gte(this.tierDetails.erFormula.fixedAmount, 0)) {
                this.employerContributionDefined = true;
                this.employerContribution = this.tierDetails.erFormula.fixedAmount;
            }
            else if (this.tierDetails.erFormula.isFormulaCalcType) {
                this.employerContributionDefined = !_.isNil(this.tierDetails.erFormula.expression) && _.size(this.tierDetails.erFormula.expression) > 0;
            }
            else {
                this.employerContributionDefined = false;
            }
        }
    };
    BenefitOptionFormulaGridComponent.prototype.getCoverage = function () {
        if (this.tierDetails && this.tierDetails.coverageFormula) {
            if (this.tierDetails.coverageFormula.isFixedCalcType && _.gte(this.tierDetails.coverageFormula.fixedAmount, 0)) {
                this.coverageDefined = true;
                this.coverage = this.tierDetails.coverageFormula.fixedAmount;
                this.manService.updateEmployeesUpdatedInfo(this.data);
                this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid);
            }
            else if (this.tierDetails.coverageFormula.isFormulaCalcType) {
                this.coverageDefined = !_.isNil(this.tierDetails.coverageFormula.expression) && _.size(this.tierDetails.coverageFormula.expression) > 0;
            }
            else if (this.tierDetails.coverageFormula.isMultiplierCalcType) {
                this.coverageDefined = true;
                this.coverage = -1;
            }
            else if (this.tierDetails.coverageFormula.isAnyValueMaxCapCalcType && _.gt(this.tierDetails.coverageFormula.maxCap, 0)) {
                this.coverageDefined = true;
                this.coverage = -1;
            }
            else {
                this.coverageDefined = false;
            }
        }
    };
    BenefitOptionFormulaGridComponent.prototype.updateRowsData = function () {
        this.manService.updateEmployeesUpdatedInfo(this.data);
        this.validateInputs();
    };
    BenefitOptionFormulaGridComponent.prototype.validateInputs = function () {
        var _this = this;
        if (_.size(this.data) > 0) {
            var allDataValid = _.every(this.data, function (e) {
                var coverage = _.isNil(e.calculatedCoverage) ? e.coverage : e.calculatedCoverage;
                if (_.isNil(coverage)) {
                    coverage = _this.coverage;
                }
                var dataValid = (_this.isValid(coverage)) &&
                    (_this.isValid(_this.employeeContribution) || _this.isValid(e.employeeContributionAmount)) &&
                    (_this.isValid(_this.employerContribution) || _this.isValid(e.employerContributionAmount));
                return dataValid;
            });
            if (allDataValid) {
                this.warningMessage = '';
            }
            else if (!this.warningMessage || this.warningMessage === '') {
                this.warningMessage = 'All required fields should be entered';
            }
            this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !allDataValid);
        }
    };
    BenefitOptionFormulaGridComponent.prototype.isValid = function (input) {
        return (_.isFinite(input) && input >= 0);
    };
    BenefitOptionFormulaGridComponent.prototype.coverageSelectionStart = function (message, noValues) {
        var _this = this;
        if ((this.coverageMultipllierOptions && this.coverageMultipllierOptions.length === 0) || noValues) {
            var options = new ConfirmOptions();
            options.height = 225;
            options.showCancel = false;
            options.showOK = true;
            ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
                if (result) {
                    _this.onEnrollmentCancel.emit();
                }
            }, options);
            this.manService.benefitsOptionTabDetailsChanged(true);
        }
    };
    BenefitOptionFormulaGridComponent.prototype.reload = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.tierDetails.coverageFormula.isFormulaCalcType ||
                    this.tierDetails.erFormula.isFormulaCalcType ||
                    this.tierDetails.empFormula.isFormulaCalcType) {
                    this.isLoading = true;
                    return [2 /*return*/, this.apiService.getBenefitEnrolledPreviewEmployees(this.orgLevel.id, this.tierDetails.id, this.date, null, this.selectedEmployees)
                            .then(function (records) {
                            var employeeMap = new Map(records.map(function (employee) { return [employee.employeeId, employee]; }));
                            _this.data = _.map(_this.selectedEmployees, function (employee) {
                                var record = employeeMap.get(employee.employeeId);
                                if (record) {
                                    employee.employeeContributionAmount = record.employeeContributionAmount ? record.employeeContributionAmount : 0;
                                    employee.employerContributionAmount = record.employerContributionAmount ? record.employerContributionAmount : 0;
                                    employee.coverage = record.coverage ? record.coverage : 0;
                                    employee.calculatedCoverage = record.coverage ? record.coverage : 0;
                                    employee.originalCoverage = record.coverage ? record.coverage : 0;
                                }
                                return employee;
                            });
                            _this.refreshGrid();
                            _this.populateValues();
                            _this.validateInputs();
                        })
                            .finally(function () {
                            _this.isLoading = false;
                        })];
                }
                else {
                    this.renderRecords();
                }
                return [2 /*return*/];
            });
        });
    };
    BenefitOptionFormulaGridComponent.prototype.renderRecords = function () {
        this.data = this.selectedEmployees;
        this.refreshGrid();
        this.populateValues();
        this.validateInputs();
        this.isLoading = false;
    };
    BenefitOptionFormulaGridComponent.prototype.refreshGrid = function () {
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.data, this.gridState.state);
    };
    BenefitOptionFormulaGridComponent.prototype.formulaSelectionStart = function (wrongValue) {
        var _this = this;
        _.forEach(this.selectedEmployees, function (employee) {
            if (employee.employeeContributionAmount === null) {
                employee.employeeContributionAmount = -1;
                _this.employeeContribution = -1;
            }
            else {
                _this.employeeContribution = employee.employeeContributionAmount;
            }
            if (employee.employerContributionAmount === null) {
                employee.employerContributionAmount = -1;
                _this.employerContribution = -1;
            }
            else {
                _this.employerContribution = employee.employerContributionAmount;
            }
            if (employee.coverage === null && _this.tierDetails.coverageFormula.isFormulaCalcType) {
                employee.coverage = -1;
                _this.coverage = -1;
            }
            else {
                _this.coverage = employee.coverage;
            }
        });
        var message = this.commonValidationService.getFormulaError(this.tierDetails.empFormula.isFormulaCalcType, this.tierDetails.erFormula.isFormulaCalcType, this.tierDetails.coverageFormula.isFormulaCalcType, this.employeeContribution, this.employerContribution, this.coverage);
        if ((this.coverageMultipllierOptions && this.coverageMultipllierOptions.length === 0) || wrongValue) {
            if (message && message != '') {
                this.warningMessage = message;
                var options = new ConfirmOptions();
                options.showCancel = false;
                options.showOK = true;
                ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
                    _this.validateInputs();
                    _this.onEnrollmentCancel.emit();
                }, options);
            }
        }
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitOptionFormulaGridComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionFormulaGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionFormulaGridComponent;
}());
export { BenefitOptionFormulaGridComponent };
