<section class="groups">
    <header class="groups__hd">
        <p class="groups__hd-title">Select the dashboards you want to show on the dashboard console.</p>
        <p class="groups__hd-text">(Click <i aria-hidden="true" class="fas fa-eye"></i> to Show or Hide a dashboard and Click <i aria-hidden="true" class="fas fa-bars"></i> and Drag to Reorder)</p>
        <p class="groups__hd-active">Active Dashboards</p>
    </header>
    <div class="flex flex-direction__column groups__body">
        <div class="fav-subtitle">
            <div class="td-bars">Reorder</div>
            <div class="td-eye">Show/Hide</div>
        </div>
        <span>
            <kendo-sortable
            [kendoSortableBinding]="activeApps"
            (dragEnd)="onDragEnd(activeApps)"
            [animation]="true"
            emptyText=""
            >
            <ng-template let-item="item">
                <div class="td-section">
                    <table class="tbl" aria-describedby="activeAlerts" aria-hidden="true">
                        <td class="icon-td"><em class="fas {{item.icon}}"></em></td>
                        <td class="text-td"><span title={{item.appName}}>{{ item.appName }}</span></td>
                        <td class="bars-td"><i class="fas fa-bars" aria-hidden="true"></i></td>
                        <td class="eye-td"><i class="fas fa-eye" (click)="showHideAppItem(item)" aria-hidden="true"></i></td>
                    </table>
                </div>
            </ng-template>
            </kendo-sortable>
        </span>
        <div>
            <p class="groups__more">More Dashboards</p>
            <div *ngFor="let item of inActiveApps" class="td-section">
                <table class="tbl" aria-describedby="activeAlerts" aria-hidden="true">
                    <td class="icon-td"><em class="fas {{item.icon}}"></em></td>
                    <td class="text-td"><span title={{item.appName}}>{{ item.appName }}</span></td>
                    <td class="bars-td"></td>
                    <td class="eye-td"><i class="fas fa-eye-slash" (click)="showHideAppItem(item)" aria-hidden="true"></i></td>
                </table>
            </div>
        </div>
    </div>
</section>
