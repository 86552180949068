import { Component, Provider } from '@angular/core';
import { DialogOptions } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';
import { ChangesDialogOptions } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-confirm-changes-dialog',
  templateUrl: 'confirm-changes-dialog.component.html',
  styleUrls: ['confirm-changes-dialog.component.scss']
})
export class ConfirmChangesDialogComponent {

  public options: DialogOptions;
  public dialogResult: boolean;
  public changesOptions: ChangesDialogOptions;
  private modalService: ModalService;

  public static openDialog(
    title: string,
    modalService: ModalService,
    callback: (result: boolean) => void,
    options?: ChangesDialogOptions
  ): ConfirmChangesDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 350;
    if (!options) {
      options = ChangesDialogOptions.NAVIGATE;
    }

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ChangesDialogOptions,
        useValue: options
      }

    ];
    let dialog: ConfirmChangesDialogComponent = modalService.globalAnchor
      .openDialog(ConfirmChangesDialogComponent, title, dialogOptions, resolvedProviders, callback);
    return dialog;
  }

  constructor(options: DialogOptions, modalService: ModalService, changesOptions: ChangesDialogOptions) {
    this.options = options;
    this.modalService = modalService;
    this.changesOptions = changesOptions;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
