import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, Provider, OnDestroy } from '@angular/core';
import { NewEnrollmentModel, FormulaTypeEnum } from '../../../../models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { ModalService } from '../../../../../../common/services';
import { LookupService } from '../../../../../../organization/services';
import { DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../../common/models';
import { appConfig } from '../../../../../../app.config';
import { BenefitEnrollmentCommonService } from '../../../../../../../app/app-modules/benefits/services';
import { NgForm } from '@angular/forms';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EnrollEmployeeFormulaWithOptionComponent = /** @class */ (function () {
    function EnrollEmployeeFormulaWithOptionComponent(model, commonValidationService, apiService, options, modalService, lookupService, man) {
        this.model = model;
        this.commonValidationService = commonValidationService;
        this.apiService = apiService;
        this.options = options;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.man = man;
        this.minAmt = 0;
        this.minFormulaAmt = -1;
        this.maxAmt = 99999999.99;
        this.stepcurrency = 0.01;
        this.errorMessage = '';
        this.hasDedError = false;
        this.subscriptions = {};
        this.isDirty = false;
        this.appConfig = appConfig;
    }
    EnrollEmployeeFormulaWithOptionComponent.openDialog = function (model, apiService, modalService, lookupService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.custom;
        dialogOptions.width = 1050;
        dialogOptions.height = 400;
        dialogOptions.minHeight = 220;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: LookupService,
                useValue: lookupService
            },
            {
                provide: ModalService,
                useValue: modalService
            },
            {
                provide: NewEnrollmentModel,
                useValue: model
            },
            {
                provide: EmployeeSectionsBenefitsManagementApiService,
                useValue: apiService
            }
        ];
        var title = "Enroll Employee in Benefits";
        var component = modalService.globalAnchor.openDialog2(EnrollEmployeeFormulaWithOptionComponent, title, dialogOptions, resolvedProviders, callback);
        return component;
    };
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "areAllValuesOK", {
        get: function () {
            return this.hasSelectedOptionCode
                && this.formulaSettings.empContribution >= 0
                && this.formulaSettings.erContribution >= 0 &&
                ((this.isFixed && this.formulaSettings.fixedAmount >= 0) ||
                    (this.isFormula && this.hasCovFormulaExpression && this.formulaSettings.calculatedCoverage >= 0) ||
                    ((this.multiplerMaxCap || this.anyWithMaxCap) && this.formulaSettings.formulaValue >= 0));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasSelectedOptionCode", {
        get: function () {
            return !_.isNil(this.selectedCoverageOption);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasError", {
        get: function () {
            return this.errorMessage != '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasSelected", {
        get: function () {
            return !_.isNil(this.selectedTier);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasEmpContribution", {
        get: function () {
            if (this.isEmpFormulaType) {
                return this.hasEmpFormulaExpression;
            }
            else {
                return this.hasSettings && _.isNumber(this.formulaSettings.empContribution);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasErContribution", {
        get: function () {
            if (this.isErFormulaType) {
                return this.hasErFormulaExpression;
            }
            else {
                return this.hasSettings && _.isNumber(this.formulaSettings.erContribution);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasCoverage", {
        get: function () {
            if (!this.hasSettings)
                return false;
            if (this.isFixed)
                return _.isNumber(this.formulaSettings.fixedAmount) && _.isNumber(this.formulaSettings.fixedAmount);
            if (this.anyWithMaxCap)
                return true;
            if (this.isFormula)
                return this.hasCovFormulaExpression;
            if (this.multiplerMaxCap)
                return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasSettings", {
        get: function () {
            return !_.isNil(this.formulaSettings);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "anyWithMaxCap", {
        get: function () {
            return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.AnyWithMaxCap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "multiplerMaxCap", {
        get: function () {
            return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.MultiplerMaxCap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "isFixed", {
        get: function () {
            return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.Fixed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "isFormula", {
        get: function () {
            return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "isEmpFormulaType", {
        get: function () {
            return this.formulaSettings && this.formulaSettings.empFormulaType === FormulaTypeEnum.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "isErFormulaType", {
        get: function () {
            return this.formulaSettings && this.formulaSettings.erFormulaType === FormulaTypeEnum.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasEmpFormulaExpression", {
        get: function () {
            return this.formulaSettings && !_.isEmpty(this.formulaSettings.empFormulaExpression);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasErFormulaExpression", {
        get: function () {
            return this.formulaSettings && !_.isEmpty(this.formulaSettings.erFormulaExpression);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaWithOptionComponent.prototype, "hasCovFormulaExpression", {
        get: function () {
            return this.formulaSettings && !_.isEmpty(this.formulaSettings.covFormulaExpression);
        },
        enumerable: true,
        configurable: true
    });
    EnrollEmployeeFormulaWithOptionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.isOptionLoading = true;
        this.groupName = this.model.plan.name;
        this.effectiveDate = this.model.effectiveDate;
        this.payrollDedStartDate = this.model.effectiveDate;
        this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
        this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
        this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
        this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
        this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
        this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
        this.endDate = this.model.plan.endDate;
        this.canMapPayroll = this.model.canMapPayroll;
        this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
            .then(function (tiers) {
            _this.tiers = tiers;
            _this.isLoading = false;
        });
        this.apiService.getBenefitPlanOptionRates(this.model.plan.benefitLineId)
            .then(function (options) {
            _this.coverageOptions = options;
            _this.filteredCoverageOptions = [];
            _this.selectedCoverageOption = null;
            _this.isOptionLoading = false;
        });
        this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate(function (hasDedError) {
            _this.hasDedError = hasDedError;
        });
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.onChangeTier = function (tier) {
        var _this = this;
        this.selectedTier = tier;
        this.isLoading = true;
        this.effectiveDate = this.model.effectiveDate;
        var empId = this.model.empId;
        this.apiService.getBenefitPlanFormula(tier.id, empId, this.effectiveDate, null, null)
            .then(function (settings) {
            _this.formulaSettings = settings;
            _this.maxAmt = _.isFinite(settings.maxCap) ? settings.maxCap : _this.maxAmt;
            if (_this.isEmpFormulaType) {
                _this.formulaSettings.empContribution = null;
            }
            if (_this.isErFormulaType) {
                _this.formulaSettings.erContribution = null;
            }
            _this.isDirty = true;
            if (_this.multiplerMaxCap || _this.anyWithMaxCap) {
                var options = [];
                if (_this.multiplerMaxCap) {
                    _this.editedItemCoverage = null;
                    var mult = _.isNumber(settings.multipler) ? settings.multipler : 500;
                    var cap = _.isNumber(settings.maxCap) ? settings.maxCap : 10000;
                    var range = _.range(mult, cap + mult, mult);
                    options = _.map(range, function (value) { return ({ name: _.toString(value), value: value }); });
                }
                _this.coverageMultipllierOptions = options;
            }
            else {
                _this.coverageMultipllierOptions = [];
                _this.formulaSettings.formulaValue = _.isNumber(_this.formulaSettings.formulaValue) ? _this.formulaSettings.formulaValue : null;
            }
            _this.isLoading = false;
            _this.validatePrerequisites();
        });
        if (this.selectedTier && this.coverageOptions) {
            this.filteredCoverageOptions = _.filter(this.coverageOptions, function (o) { return o.id !== 0 && o.tierId === _this.selectedTier.id; });
        }
        else {
            this.filteredCoverageOptions = [];
        }
        this.selectedCoverageOption = null;
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.onChangeCoverageWithMultiplier = function (value) {
        this.editedItemCoverage = value;
        var newValue = value ? value.value : null;
        var hasChagned = (newValue !== this.formulaSettings.formulaValue);
        if (hasChagned) {
            this.isDirty = this.formulaSettings.formulaValue > 0;
            this.formulaSettings.formulaValue = newValue;
            this.evaluateFormula();
        }
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.onOptionValueChanged = function () {
        if (this.selectedCoverageOption && this.selectedCoverageOption.tierOptionCode) {
            this.isDirty = true;
            //In case of Coverage Formula - when selecting the Option make sure the required field errors are cleared (as the coverage going to be assumed as 0).
            //This is to ensure form validation is successful immediately after selecting the option
            if (this.isFormula && !this.formulaSettings.formulaValue) {
                this.form.control.get('covFormulaValue').setErrors(null);
            }
            this.evaluateFormula();
        }
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.onChangeCoverageFormulaValue = function (value, hasError) {
        if (!hasError && this.formulaSettings.calculatedCoverage !== value) {
            this.formulaSettings.formulaValue = value;
            this.formulaSettings.calculatedCoverage = value;
            this.isDirty = true;
            this.evaluateFormula();
        }
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.onCoverageAnyValueChanged = function (hasError) {
        if (!hasError) {
            this.isDirty = this.formulaSettings.formulaValue > 0;
            this.evaluateFormula();
        }
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.evaluateFormula = function () {
        var _this = this;
        if (this.hasCovFormulaExpression ||
            this.hasEmpFormulaExpression ||
            this.hasErFormulaExpression) {
            var hasOptionCode = this.selectedCoverageOption && this.selectedCoverageOption.tierOptionCode;
            var hasValidCoverage = _.isFinite(this.formulaSettings.formulaValue) || this.formulaSettings.formulaValue > 0;
            if (hasOptionCode && ((!this.isFormula && hasValidCoverage) || this.isFormula)) {
                this.isLoading = true;
                var optionCode = (hasOptionCode) ? this.selectedCoverageOption.tierOptionCode : '';
                this.apiService.getBenefitPlanFormula(this.selectedTier.id, this.model.empId, this.effectiveDate, this.formulaSettings.formulaValue, optionCode)
                    .then(function (record) {
                    if (record) {
                        _this.formulaSettings.empContribution = record.empContribution;
                        _this.formulaSettings.erContribution = record.erContribution;
                        _this.formulaSettings.calculatedCoverage = record.calculatedCoverage;
                        if (_this.multiplerMaxCap || _this.anyWithMaxCap) {
                            _this.formulaSettings.formulaValue = record.calculatedCoverage;
                        }
                    }
                    else {
                        if (_this.isEmpFormulaType) {
                            _this.formulaSettings.empContribution = -1;
                        }
                        if (_this.isErFormulaType) {
                            _this.formulaSettings.erContribution = -1;
                        }
                        if (_this.isFormula) { //coverage
                            _this.formulaSettings.calculatedCoverage = -1;
                        }
                    }
                }).finally(function () {
                    _this.isLoading = false;
                    _this.validateFormulaEvaluation();
                });
            }
        }
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.onEnroll = function () {
        this.model.selectedCoverageOption = this.selectedCoverageOption;
        this.model.formulaSettings = this.formulaSettings;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.onCancel = function () {
        var _this = this;
        if (!this.isDirty) {
            this.closeWindow(false);
            return;
        }
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Discard Changes', 'Are you sure you want to cancel? You will lose all unsaved selections.', this.modalService, function (isCancel) {
            if (isCancel) {
                _this.closeWindow(false);
            }
        }, options);
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.closeWindow = function (dialogResult) {
        this.dialogResult = dialogResult;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.validatePrerequisites = function () {
        var _this = this;
        this.errorMessage = '';
        if (this.filteredCoverageOptions.length === 0) {
            this.errorMessage = 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.';
        }
        else {
            this.errorMessage = 'Employees cannot be enrolled as ';
            if (!this.hasEmpContribution && !this.hasErContribution && !this.hasCoverage) {
                this.errorMessage += 'the Employee and Employer Contribution and Coverage amounts have';
            }
            else if (!this.hasEmpContribution && !this.hasErContribution) {
                this.errorMessage += 'the Employee and Employer Contribution amounts have';
            }
            else if (!this.hasEmpContribution && !this.hasCoverage) {
                this.errorMessage += 'the Employee Contribution and Coverage amounts have';
            }
            else if (!this.hasErContribution && !this.hasCoverage) {
                this.errorMessage += 'the Employer Contribution and Coverage amounts have';
            }
            else if (!this.hasEmpContribution) {
                this.errorMessage += 'the Employee Contribution amount has';
            }
            else if (!this.hasErContribution) {
                this.errorMessage += 'the Employer Contribution amount has';
            }
            else if (!this.hasCoverage) {
                this.errorMessage += 'the Coverage amount has';
            }
            else {
                this.errorMessage = '';
                return;
            }
            this.errorMessage += ' not been created for this benefit plan. Please add contribution and Coverage amounts for this plan to enroll employees.';
        }
        if (this.errorMessage != '') {
            var options = new ConfirmOptions();
            options.showCancel = false;
            options.showOK = true;
            ConfirmDialog2Component.openDialog('Warning', this.errorMessage, this.modalService, function (result) {
                if (!_this.tiers || _this.tiers.length <= 1) {
                    _this.closeWindow(false);
                }
            }, options);
        }
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.validateFormulaEvaluation = function () {
        var hasFormula = (this.formulaSettings.formulaType === FormulaTypeEnum.Formula ||
            this.formulaSettings.erFormulaType === FormulaTypeEnum.Formula ||
            this.formulaSettings.empFormulaType === FormulaTypeEnum.Formula);
        if (hasFormula) {
            this.errorMessage = this.commonValidationService.getFormulaError(this.isEmpFormulaType, this.isErFormulaType, this.isFormula, this.formulaSettings.empContribution, this.formulaSettings.erContribution, this.formulaSettings.calculatedCoverage);
            if (this.errorMessage && this.errorMessage != '') {
                var options = new ConfirmOptions();
                options.showCancel = false;
                options.showOK = true;
                ConfirmDialog2Component.openDialog('Warning', this.errorMessage, this.modalService, function (result) { }, options);
            }
        }
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.OnDeductionEndDateChange = function (date) {
        this.model.plan.payrollDeductionEndDate = date;
    };
    EnrollEmployeeFormulaWithOptionComponent.prototype.OnDeductionStartDateChange = function (date) {
        this.model.plan.payrollDeductionStartDate = date;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EnrollEmployeeFormulaWithOptionComponent.prototype, "subscriptions", void 0);
    return EnrollEmployeeFormulaWithOptionComponent;
}());
export { EnrollEmployeeFormulaWithOptionComponent };
