import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { ArrivalsDeparturesTimelineDetailsManagementService, ArrivalDeparturesManagementService, TaConsoleToolbarService } from '../../../services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import * as moment from 'moment';
var ArrivalsDeparturesTimelineDetailsComponent = /** @class */ (function () {
    function ArrivalsDeparturesTimelineDetailsComponent(changeDetector, consoleManagementService, managementService, consoleToolbarService) {
        this.changeDetector = changeDetector;
        this.consoleManagementService = consoleManagementService;
        this.managementService = managementService;
        this.consoleToolbarService = consoleToolbarService;
        this.m_componentId = 'TaConsoleDetailsComponent';
    }
    ArrivalsDeparturesTimelineDetailsComponent.prototype.changeDetection = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    ArrivalsDeparturesTimelineDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onLoadStatusSubscription = this.managementService.onLoadStatus$.subscribe(function (value) {
            _this.isLoading = value;
            _this.changeDetection();
        });
        this.onFilterChangedSubscription = this.consoleManagementService.onFilterChanged$.subscribe(function (filter) {
            _this.managementService.onArrivalDeparturesFilterChangedSelected(filter);
        });
        this.onArrivalDeprturesLoadedSubscription = this.consoleManagementService.onLoaded$.subscribe(function (container) {
            _this.managementService.ArrivalDeparturesLoaded(container);
        });
    };
    ArrivalsDeparturesTimelineDetailsComponent.prototype.ngOnChanges = function (changes) {
        var isDateChanged = changes['date'] && !moment(changes['date'].previousValue).isSame(changes['date'].currentValue);
        var isOrgLevelChanged = changes['orgLevelId'] && changes['orgLevelId'].previousValue !== changes['orgLevelId'].currentValue;
        if (!isDateChanged && !isOrgLevelChanged) {
            return;
        }
        if (this.orgLevelId && this.date) {
            this.loadData();
        }
    };
    ArrivalsDeparturesTimelineDetailsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ArrivalsDeparturesTimelineDetailsComponent.prototype.toggleState = function () {
        this.isCollapsed = !this.isCollapsed;
        this.consoleToolbarService.onRecalcToolbar();
        this.changeDetection();
    };
    ArrivalsDeparturesTimelineDetailsComponent.prototype.loadData = function () {
        if (!this.orgLevelId || !this.date) {
            return;
        }
        this.managementService.loadData(this.orgLevelId, this.date, this.date);
        this.changeDetection();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsComponent.prototype, "routeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsComponent.prototype, "onLoadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsComponent.prototype, "onFilterChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesTimelineDetailsComponent.prototype, "onArrivalDeprturesLoadedSubscription", void 0);
    return ArrivalsDeparturesTimelineDetailsComponent;
}());
export { ArrivalsDeparturesTimelineDetailsComponent };
