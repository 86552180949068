import { Component, OnInit, Input, Output, OnDestroy, TemplateRef, ContentChild, EventEmitter, ViewEncapsulation, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

import { ListRendererTemplateDirective, ListEditorTemplateDirective } from '../../directives/index';
import { EditableListActionModel, EditableListActionKind } from '../../models/index';

import { ListActionsService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-editable-list,[slx-editable-list]',
  templateUrl: 'editable-list.component.html',
  styleUrls: ['editable-list.component.scss'],
  providers: [ListActionsService]
})
export class EditableListComponent implements OnInit, OnDestroy {

  @Input()
  public set data(value: any[]) {
    this.privateData = value;
    if (this.actionsService && this.privateData) {
      this.actionsService.itemCount = this.privateData.length;
      this.actionsService.resetSelection();
    } else if (this.actionsService) {
      this.actionsService.itemCount = 0;
      this.actionsService.resetSelection();
    }
  }

  @Input()
  public set multiselect(value: boolean) {
    this.allowMultiselect = value;
    if (this.actionsService) {
      this.actionsService.multiselect = this.allowMultiselect;
    }
  }

  @Input()
  public set editable(value: boolean) {
    this.allowEdit = value;
    if (this.actionsService) {
      this.actionsService.editable = this.allowEdit;
    }
  }

  @Input()
  public set selectable(value: boolean) {
    this.allowSelect = value;
    if (this.actionsService) {
      this.actionsService.selectable = this.allowSelect;
    }
  }

  @Input()
  public set renderIsEditor(value: boolean) {
    this.renderIsEditorInternal = value;
    if (this.actionsService) {
      this.actionsService.renderIsEditor = value;
    }
  }

  @Input()
  public set editMode(value: boolean) {
    this.editModeInternal = value;
    if (this.actionsService) {
      this.actionsService.editMode = value;
    }
  }

  @Output('editStart') public onEditStartEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output('editCancel') public onEditCancelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output('itemSave') public onItemSaveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output('itemRemove') public onItemRemoveEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output('selectionChange') public onSelectionChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  public state: {
    hasEditorInstance: boolean;
    hasSelected: boolean;
  };

  public get data(): any[] {
    return this.privateData;
  }

  public get rowTemplateRef(): TemplateRef<any> {
    return this.rowTemplate ? this.rowTemplate.templateRef : undefined;
  }

  public get editorTemplateRef(): TemplateRef<any> {
    return this.editorTemplate ? this.editorTemplate.editorTemplateRef : undefined;
  }

  protected actionSubscription: Subscription;

  @ContentChild(ListRendererTemplateDirective, {static: true})
  private rowTemplate: any;

  @ContentChild(ListEditorTemplateDirective, {static: true})
  private editorTemplate: any;

  private allowMultiselect: boolean;
  private allowEdit: boolean = true;
  private allowSelect: boolean = true;
  private privateData: any[];
  private elementRef: ElementRef;

  private renderIsEditorInternal: boolean = false;
  private editModeInternal: boolean = false;

  constructor(private actionsService: ListActionsService, elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  public ngOnInit(): void {

    this.state = {
      hasEditorInstance: false,
      hasSelected: false
    };

    this.actionsService.multiselect = this.allowMultiselect;
    this.actionsService.editable = this.allowEdit;
    this.actionsService.selectable = this.allowSelect;

    if (this.privateData) {
      this.actionsService.itemCount = this.privateData.length;
    } else {
      this.actionsService.itemCount = 0;
    }

    this.actionSubscription = this.actionsService.subscribe((msg: EditableListActionModel) => this.onAction(msg));

    this.actionsService.editMode = this.editModeInternal;
  }

  public ngOnDestroy(): void {
    if (this.actionSubscription) this.actionSubscription.unsubscribe();
  }

  public addItem(newItem: any, toStart: boolean = true): void {
    this.actionsService.addNewItem(newItem);
    if (toStart) {
      this.privateData.unshift(newItem);
      this.elementRef.nativeElement.scrollTop = 0;
    } else {
      this.privateData.push(newItem);
    }
  }

  public createItem(itemClass: any, toStart: boolean = true): void {
    let newItem: any = new itemClass();
    this.addItem(newItem, toStart);
  }

  public editItem(item: any): void {
    this.actionsService.startEditItem(item);
  }

  public toogleAllSelection(): void {
    this.actionsService.selectAll(this.privateData);
  }

  public resetSelection(): void {
    this.actionsService.resetSelection();
  }

  protected onAction(action: EditableListActionModel): void {
    switch (action.kind) {
      case EditableListActionKind.START_EDIT:
        this.onItemEditStart(action.items);
        break;
      case EditableListActionKind.CANCEL_EDIT:
        this.cancelEditing(action.items);
        break;
      case EditableListActionKind.COMPLETE_EDIT:
        this.onItemSave(action.items);
        break;
      case EditableListActionKind.SELECTION_CHANGE:
        this.onSelectionChanged(action.items);
        break;
      case EditableListActionKind.SELECT_ALL:
        this.onSelectAll();
        break;
      case EditableListActionKind.REMOVE:
        this.onRemoveItem(action.items);
        break;
    }
  }

  protected onRemoveItem(items: any[]): void {
    _.each(items, (item) => {
      let index: number = this.privateData.indexOf(item);
      this.privateData.splice(index, 1);
    });
    this.onItemRemoveEvent.emit(items);
  }

  protected onItemEditStart(items: any[]): void {
    if (this.state.hasEditorInstance) return;
    this.state.hasEditorInstance = true;
    this.onEditStartEvent.emit(items);
  }

  protected cancelEditing(items: any[]): void {
    this.state.hasEditorInstance = false;
    this.onEditCancelEvent.emit(items);
    if (this.actionsService.isNew(items[0])) {
      let index: number = this.privateData.indexOf(items[0]);
      this.privateData.splice(index, 1);
    }
  }

  protected onItemSave(items: any[]): void {
    this.state.hasEditorInstance = false;
    this.onItemSaveEvent.emit(items);
  }

  protected onSelectionChanged(items: any[]): void {
    this.onSelectionChangeEvent.emit(this.actionsService.selectedItems);
  }

  protected onSelectAll(): void {
    if (this.actionsService.allSelected) {
      this.onSelectionChangeEvent.emit(this.privateData);
    } else {
      this.onSelectionChangeEvent.emit([]);
    }
  }
}
