import { IEmployeeDefinition, EmployeeDefinition } from '../../../organization/models/employee/index';
import {
  ShiftDefinition,
  IShiftDefinition,
  LocationUnit,
  ILocationUnit,
  IPosition,
  Position
} from '../../../organization/models/index';

import { VacationPlanEmployeeWeek, IVacationPlanEmployeeWeek } from './index';

export interface IVacationPlanEmployeeRecord {
  employee: IEmployeeDefinition;
  position: IPosition;
  shift: IShiftDefinition;
  unit: ILocationUnit;
  daysWorking: number;
  vacationDays: number;
  vacationHours: number;
  weeks: IVacationPlanEmployeeWeek[];
}

export class VacationPlanEmployeeRecord {
  public employee: EmployeeDefinition;
  public position: Position;
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public daysWorking: number;
  public vacationDays: number;
  public vacationHours: number;
  public weeks: NumberMap<VacationPlanEmployeeWeek>;
}
