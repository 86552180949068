import { Component, Input, Output, EventEmitter, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'slx-benefit-details-eligibility-statement-number-values',
  templateUrl: './benefit-details-eligibility-statement-number-values.component.html',
  styleUrls: ['./benefit-details-eligibility-statement-number-values.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class BenefitDetailsEligibilityStatementNumberValuesComponent implements OnChanges {
  @Input()
  uniqueComponentId: number;

  @Input()
  value: string | string[];

  @Input()
  isMinMaxValue: boolean;

  @Output()
  valueChanged: EventEmitter<string | string[]>;

  minValue: number;
  maxValue: number;
  innerValue: number;
  minLimitForMaxValue: number;
  maxLimitForMinValue: number;

  constructor() {
    this.valueChanged = new EventEmitter();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const componentChanges = changes as PropertyMap<
      BenefitDetailsEligibilityStatementNumberValuesComponent,
      SimpleChange
    >;

    const valueChange = componentChanges.value;
    if (!valueChange) {
      return;
    }
    const currentValue = valueChange.currentValue as string | string[];
    const previousValue = valueChange.previousValue as string | string[];

    if (_.isEqual(currentValue, previousValue)) {
      return;
    }
    if (!currentValue) {
      this.innerValue = null;
      this.minValue = null;
      this.maxValue = null;
      this.minLimitForMaxValue = null;
      this.maxLimitForMinValue = null;
      return;
    }

    if (!this.isMinMaxValue) {
      this.innerValue = Number(currentValue);
      this.minValue = null;
      this.maxValue = null;
      this.minLimitForMaxValue = null;
      this.maxLimitForMinValue = null;
      return;
    }

    const parsedValues = currentValue;
    if (parsedValues && parsedValues.length > 1) {
      this.minValue = _.isFinite(parseInt(parsedValues[0])) ? _.toNumber(parsedValues[0]) : null;
      this.minLimitForMaxValue = _.isFinite(this.minValue) ? this.minValue : null;

      this.maxValue = _.isFinite(parseInt(parsedValues[1])) ? _.toNumber(parsedValues[1]) : null;
      this.maxLimitForMinValue = _.isFinite(this.maxValue) ? this.maxValue : null;

      this.innerValue = null;
    }
  }

  onValueChanged(myValue: number) {
    if (!this.isMinMaxValue) {
      const value = _.isFinite(this.innerValue) ? this.innerValue.toString() : '';
      this.valueChanged.next(value);
      return;
    }

    const minValueString = _.isFinite(this.minValue) ? this.minValue.toString() : '';
    const maxValueString = _.isFinite(this.maxValue) ? this.maxValue.toString() : '';
    const value = [minValueString, maxValueString];
    this.valueChanged.next(value);
  }
}
