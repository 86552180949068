<form [formGroup]="formGroup">
    <div class="editor-container">
        <div class="field-container wrapped">

            <div class="col absence-code">
                <slx-input-decorator>
                    <input class="form-control" slx-input type="text" formControlName="code" [required]="true" placeholder="Code" [prohibitedValues]="prohibitedNameValues">
                </slx-input-decorator>
            </div>
            <div class="col absence-description">
                <slx-input-decorator>
                    <input class="form-control" slx-input type="text" formControlName="description" [required]="true" placeholder="Description">
                </slx-input-decorator>
            </div>
            <div class="col absence-color">
                <slx-input-decorator>
                    <input type="hidden" slx-input [required]="true" placeholder="Color">
                    <input id="myField" type="text" class="color-border color-picker-input" [colorPicker]="formGroup.get('color').value" [cpPosition]="'bottom'"
                        [cpAlphaChannel]="'disabled'" [cpOutputFormat]="'hex'" [style.background]="getColor ()" (colorPickerChange)="onColorPickerChange($event)"
                        readonly>
                </slx-input-decorator>
            </div>
            <div class="col absence-paid">
                <slx-input-decorator className="slx-form-group slx-no-border small-font">
                    <slx-checkbox-input slx-input formControlName="isPaid" placeholder="Paid" fieldName="isPaid" className="reset-display">
                    </slx-checkbox-input>
                </slx-input-decorator>
            </div>
            <div class="col absence-pto">
                <slx-input-decorator className="slx-form-group slx-no-border small-font">
                    <slx-checkbox-input slx-input formControlName="ptoPlannerIndicator" placeholder="PTO Planner" fieldName="ptoPlannerIndicator" className="reset-display">
                    </slx-checkbox-input>
                </slx-input-decorator>
            </div>
            <div class="col absence-loa">
                <slx-input-decorator className="slx-form-group slx-no-border small-font">
                    <slx-checkbox-input slx-input formControlName="loaIndicator" placeholder="LOA" fieldName="loaIndicator" className="reset-display">
                    </slx-checkbox-input>
                </slx-input-decorator>
            </div>
            <div class="col absence-ess">
                <slx-input-decorator className="slx-form-group slx-no-border small-font">
                    <slx-checkbox-input slx-input formControlName="essPresent" placeholder="Ess" fieldName="essPresent" className="reset-display">
                    </slx-checkbox-input>
                </slx-input-decorator>
            </div>

            <div class="col actions-bar">
                <button class="action-button save-btn" (click)="onSaveClick ($event)" [disabled]="formGroup.invalid">Save</button>
                <button class="action-button cancel-btn" (click)="onCancelClick ($event)">Cancel</button>
            </div>

        </div>
    </div>

</form>