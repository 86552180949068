import * as tslib_1 from "tslib";
import { ActionsType, Actions, } from '../../../core/models/index';
var PositionsActionsType = /** @class */ (function (_super) {
    tslib_1.__extends(PositionsActionsType, _super);
    function PositionsActionsType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PositionsActionsType.manageGroups = 'managePositionGroups';
    return PositionsActionsType;
}(ActionsType));
export { PositionsActionsType };
var PositionsActions = /** @class */ (function (_super) {
    tslib_1.__extends(PositionsActions, _super);
    function PositionsActions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return PositionsActions;
}(Actions));
export { PositionsActions };
