import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
var BenefitEmployeeManagementService = /** @class */ (function () {
    function BenefitEmployeeManagementService() {
        this.updatedEmployeeInfo$ = new Subject();
        this.isChangeOccured$ = new Subject();
        this.getToDetailsPage$ = new Subject();
        this.calculateCoverage$ = new Subject();
        this.viewModeChanged = false;
    }
    BenefitEmployeeManagementService.prototype.destroy = function () {
        this.updatedEmployeeInfo$.complete();
        this.isChangeOccured$.complete();
        this.getToDetailsPage$.complete();
    };
    BenefitEmployeeManagementService.prototype.subscribeToIsChangeOccured = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.isChangeOccured$.subscribe(callback);
    };
    BenefitEmployeeManagementService.prototype.subscribeToUpdatedEmployeeData = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updatedEmployeeInfo$.subscribe(callback);
    };
    BenefitEmployeeManagementService.prototype.benefitsOptionTabDetailsChanged = function (hasError) {
        if (hasError === void 0) { hasError = false; }
        this.isChangeOccured$.next(hasError);
    };
    BenefitEmployeeManagementService.prototype.updateEmployeesUpdatedInfo = function (empData) {
        this.updatedEmployeeInfo$.next(empData);
    };
    BenefitEmployeeManagementService.prototype.subscribeTogetToDetailsPage = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.getToDetailsPage$.subscribe(callback);
    };
    BenefitEmployeeManagementService.prototype.getBackToDetailsPage = function () {
        this.getToDetailsPage$.next(true);
    };
    BenefitEmployeeManagementService.prototype.changeMode = function (hasChangedMode) {
        if (_.isBoolean(hasChangedMode)) {
            this.viewModeChanged = hasChangedMode;
        }
    };
    Object.defineProperty(BenefitEmployeeManagementService.prototype, "viewModeIsChanged", {
        get: function () {
            return this.viewModeChanged;
        },
        enumerable: true,
        configurable: true
    });
    BenefitEmployeeManagementService.prototype.calculateCoverage = function () {
        this.calculateCoverage$.next();
    };
    BenefitEmployeeManagementService.prototype.subscribeTocalculateCoverage = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.calculateCoverage$.subscribe(callback);
    };
    BenefitEmployeeManagementService.prototype.isValidCovOption = function (e) {
        return _.size(e.code) > 0;
    };
    BenefitEmployeeManagementService.prototype.isValidEnrollment = function (empContr, erContr) {
        return _.isFinite(empContr) && _.isFinite(erContr) && (empContr >= 0 && erContr >= 0);
    };
    BenefitEmployeeManagementService.prototype.setControlErrors = function (model, errors) {
        if (_.isObjectLike(model)) {
            model.control.setErrors(_.size(errors) > 0 ? errors : null);
        }
    };
    BenefitEmployeeManagementService.prototype.deleteControlError = function (model, errorName) {
        if (_.isObjectLike(model)) {
            var errors = model.control.errors || {};
            delete errors[errorName];
            this.setControlErrors(model, errors);
        }
    };
    return BenefitEmployeeManagementService;
}());
export { BenefitEmployeeManagementService };
