<form #form="ngForm">
    <div class="editor-container">
        <div class="field-container wrapped">

            <div class="col md-full-line-column">
                <slx-input-decorator>
                    <input class="form-control" slx-input type="text" [(ngModel)]="editItem.description" [required]="true" placeholder="Unit Description"
                        [prohibitedValues]="prohibitedDescriptionValues" name="descrInput">
                    <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                    <span errorMessage for="required"></span>
                </slx-input-decorator>
            </div>

            <div class="col md-full-line-column">
                <slx-input-decorator>
                    <input class="form-control" slx-input type="text" [(ngModel)]="editItem.name" [required]="true" placeholder="Unit Name" [prohibitedValues]="prohibitedNameValues"
                        maxlength="20" name="nameInput">
                    <span errorMessage for="prohibitedValues"></span>
                    <span errorMessage for="required"></span>
                </slx-input-decorator>
            </div>
        </div>
        <div class="actions-bar">
            <button class="action-button save-btn" (click)="onSaveClick ($event)" [disabled]="form.invalid">Save</button>
            <button class="action-button cancel-btn" (click)="onCancelClick ($event)">Cancel</button>
        </div>
    </div>
</form>