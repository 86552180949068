import { PmAttachment } from '../../../organization/models';
var PmFile = /** @class */ (function () {
    function PmFile(sourceItem, currentUserName) {
        if (currentUserName === void 0) { currentUserName = null; }
        this.sourceItem = sourceItem;
        this.currentUserName = currentUserName;
        this.isAttachment = this.sourceItem instanceof PmAttachment;
    }
    Object.defineProperty(PmFile.prototype, "id", {
        get: function () {
            return this.isAttachment ? this.sourceItem.id : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmFile.prototype, "name", {
        get: function () {
            return this.sourceItem.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmFile.prototype, "fileName", {
        get: function () {
            return this.sourceItem.fileName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmFile.prototype, "addedBy", {
        get: function () {
            return this.isAttachment ? this.sourceItem.addedBy : this.currentUserName;
        },
        enumerable: true,
        configurable: true
    });
    return PmFile;
}());
export { PmFile };
