export enum WcViewList {
  IncidentRoster = 'Incident Roster',
  Comparisons = 'WC Comparisons',
  Summary = 'WC Summary',
  RepeatInjuryList = 'Repeat Injury List',
  OshaBudgetTracker = 'OSHA Budget Tracker',
  IncidentTracking = 'Incident Tracking',
  CreateOshaRkForms = 'Create OSHA Recordkeeping Forms'
}

export enum WcViewListDisplay {
  'Incident Roster' = 'Incident Roster',
  'WC Comparisons' = 'WC Comparison',
  'WC Summary' = 'WC Summary',
  'Repeat Injury List' = 'Repeat Injury List',
  'OSHA Budget Tracker' = 'OSHA Budget Tracker',
  'Incident Tracking' = 'Incident Tracking',
  'Create OSHA Recordkeeping Forms' = 'Create OSHA Recordkeeping Forms'
}