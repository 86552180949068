import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { appMessages } from '../../../../app.messages';
import { ConstraintsConfigurationManagementService } from '../../../services/index';
import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';
import { ConstraintModel } from '../../../models/index';
import { unsubscribe } from '../../../../core/decorators/index';
var ConfigureConstraintsEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigureConstraintsEditorComponent, _super);
    function ConfigureConstraintsEditorComponent(management) {
        var _this = _super.call(this) || this;
        _this.management = management;
        _this.prohibitedNameValues = [];
        _this.prohibitedCodeValues = [];
        _this.appMessages = appMessages;
        return _this;
    }
    Object.defineProperty(ConfigureConstraintsEditorComponent.prototype, "item", {
        get: function () {
            return this.privateItem;
        },
        set: function (value) {
            this.privateItem = value;
        },
        enumerable: true,
        configurable: true
    });
    ConfigureConstraintsEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.mainFormSubscription = this.formGroup.statusChanges.subscribe(function () {
            if (_this.formGroup.dirty) {
                _this.management.markAsDirty();
            }
        });
    };
    ConfigureConstraintsEditorComponent.prototype.createFormGroup = function () {
        var group = new FormGroup({
            code: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            countAs: new FormControl('', Validators.required)
        });
        return group;
    };
    ConfigureConstraintsEditorComponent.prototype.updateFormGroup = function () {
        this.formGroup.get('name').setValue(this.item.name);
        this.formGroup.get('code').setValue(this.item.code);
        this.formGroup.get('countAs').setValue(this.item.countAs);
    };
    ConfigureConstraintsEditorComponent.prototype.updateItem = function () {
        this.item.name = this.formGroup.get('name').value;
        this.item.code = this.formGroup.get('code').value;
        this.item.countAs = this.formGroup.get('countAs').value;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureConstraintsEditorComponent.prototype, "mainFormSubscription", void 0);
    return ConfigureConstraintsEditorComponent;
}(EditableListEditorComponent));
export { ConfigureConstraintsEditorComponent };
