import { IBenefitDetailsLine, BenefitDetailsLine } from './benefit-details-line';

export interface IBenefitDetailsProvider {
  id: number;
  name: string;
  orgLevelId: number;
  orgLevelName: string;
  benefitGroupId: number;
  benefitGroupName: string;
  allLinesExpired: boolean;
  editableAtSelectedOrgLevel: boolean;
  benefitLines: IBenefitDetailsLine[];
}

export class BenefitDetailsProvider {
  public id: number;
  public name: string = '';
  public storedName: string = '';
  public orgLevelId: number;
  public orgLevelName: string;
  public benefitGroupId: number;
  public benefitGroupName: string;
  public allLinesExpired: boolean;
  public editableAtSelectedOrgLevel: boolean;
  public benefitLines: BenefitDetailsLine[] = [];
  public actualOrLatestBenefitLine: BenefitDetailsLine;

  public get shortName(): string {
    if (this.name.length <= 25) {
      return this.name;
    }
    return `${this.name.slice(0, 25)}...`;
  }

  public get storedShortName(): string {
    if (this.storedName.length <= 25) {
      return this.storedName;
    }
    return `${this.storedName.slice(0, 25)}...`;
  }
}
