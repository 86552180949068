import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { PmConfigurationManagementService, PmAttachmentManagementService } from '../../../services';
import { DeviceDetectorService } from '../../../../../common/services';
import { CfgPmCode } from '../../../models';
import { PmTemplateDefinition } from '../../../../../organization/models';
var PmConfigToolbarComponent = /** @class */ (function () {
    function PmConfigToolbarComponent(managementService, attachmentService, devDetector) {
        this.managementService = managementService;
        this.attachmentService = attachmentService;
        this.devDetector = devDetector;
        this.disabled = false;
        this.canCreateNew = true;
        this.errors = {};
        this.files = [];
    }
    Object.defineProperty(PmConfigToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "newBtnWidth", {
        get: function () {
            return this.isDesktop ? 70 : 35;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "isForCodes", {
        get: function () {
            return this.itemClass === CfgPmCode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "isForTemplates", {
        get: function () {
            return this.itemClass === PmTemplateDefinition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "maxFileSizeBytes", {
        get: function () {
            return this.attachmentService.maxFileSizeBytes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "acceptedFileTypes", {
        get: function () {
            return this.attachmentService.acceptedFileTypes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "acceptedFileTypesInputFormat", {
        get: function () {
            return this.attachmentService.acceptedFileTypesInputFormat;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "acceptedFileTypesReadable", {
        get: function () {
            return this.attachmentService.acceptedFileTypesReadable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmConfigToolbarComponent.prototype, "maxFileSize", {
        get: function () {
            return this.attachmentService.maxFileSize;
        },
        enumerable: true,
        configurable: true
    });
    PmConfigToolbarComponent.prototype.ngOnInit = function () { };
    PmConfigToolbarComponent.prototype.ngOnDestroy = function () { };
    PmConfigToolbarComponent.prototype.onClickCreate = function () {
        if (this.isForCodes) {
            this.managementService.startAddNewCode();
        }
    };
    PmConfigToolbarComponent.prototype.onClickExport = function (isPdf) {
        if (this.isForCodes) {
            this.managementService.exportCodes(isPdf);
        }
        else if (this.isForTemplates) {
            this.managementService.exportTemplates(isPdf);
        }
    };
    PmConfigToolbarComponent.prototype.onFileChange = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileList, result, readFiles;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.disabled) return [3 /*break*/, 3];
                        fileList = _.get(event, 'target.files');
                        result = this.attachmentService.validateFiles(fileList, event);
                        if (!(result.files.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.attachmentService.readAddedFiles(result.files)];
                    case 1:
                        readFiles = _a.sent();
                        this.files = readFiles;
                        this.managementService.startAddNewTemplate(this.files);
                        _a.label = 2;
                    case 2:
                        this.errors = result.errors;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PmConfigToolbarComponent.prototype.onResetErrors = function (event) {
        this.attachmentService.resetInput(event.target);
        this.errors = {};
    };
    return PmConfigToolbarComponent;
}());
export { PmConfigToolbarComponent };
