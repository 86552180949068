import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService, AttachmentsUploaderHelperService } from '../../../../common/services/index';
import { WCReportMapService } from './wc-report-map.service';
import { WcEmployeeInfo, IWcEmployeeInfoDto, WcReport, IWcReportDto, WcCodeDefinition, IWcCodeDefinitionDto, IWcWorkersCompensationCaseDto, WcWorkersCompensationCase, WcMedicalTreatmentDefinition, IWcMedicalTreatmentDefinitionDto, IWcReportStatusDefinitionDto, WcReportStatusDefinition } from '../../models/index';
import { appConfig } from '../../../../app.config';
import { wcConfig } from '../../workers-compensation.config';
var WCReportApiService = /** @class */ (function () {
    function WCReportApiService(apiUtilService, mapService, urlParamsService, uploaderService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
        this.uploaderService = uploaderService;
    }
    WCReportApiService.prototype.getWcEmployeeInfo = function (empId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.employee;
                        params = {
                            empId: empId
                        };
                        request = this.urlParamsService.createGetRequest(url, params);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToEmployeeInfo(response.data, response.meta)];
                }
            });
        });
    };
    WCReportApiService.prototype.getReport = function (reportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.report;
                        params = {
                            reportId: reportId
                        };
                        request = this.urlParamsService.createGetRequest(url, params);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToWcReport(response.data, response.meta)];
                }
            });
        });
    };
    WCReportApiService.prototype.createReport = function (report) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, dto, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.report;
                        dto = this.mapService.mapToWcReportDto(report);
                        request = this.urlParamsService.createPostRequest(url, dto);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToWcReport(response.data)];
                }
            });
        });
    };
    WCReportApiService.prototype.updateReport = function (report) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, dto, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.report;
                        dto = this.mapService.mapToWcReportDto(report);
                        request = this.urlParamsService.createPutRequest(url, dto);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToWcReport(response.data)];
                }
            });
        });
    };
    WCReportApiService.prototype.deleteReport = function (reportId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.report;
                        params = {
                            reportId: reportId
                        };
                        request = this.urlParamsService.createDeleteRequest(url, params);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    WCReportApiService.prototype.downloadAttachment = function (documentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, promise;
            return tslib_1.__generator(this, function (_a) {
                url = this.getWCApi() + "/" + wcConfig.api.wc.report + "/" + wcConfig.api.wc.attachment;
                params = {
                    documentId: documentId
                };
                request = this.urlParamsService.createGetRequest(url, params);
                promise = this.apiUtilService.requestForFile(request)
                    .then(function (file) { return file; });
                return [2 /*return*/, promise];
            });
        });
    };
    WCReportApiService.prototype.createDocument = function (reportId, files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getWCApi() + "/" + wcConfig.api.wc.report + "/" + wcConfig.api.wc.attachment;
                return [2 /*return*/, this.uploaderService.uploadAttachmentsAsFormData(url, files, { reportId: reportId })
                        .then(function (response) { return _this.mapService.mapToWcReport(response.data); })];
            });
        });
    };
    WCReportApiService.prototype.deleteDocument = function (reportId, documentIds) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.report + "/" + wcConfig.api.wc.attachment;
                        params = {
                            reportId: reportId,
                            documentIds: documentIds
                        };
                        request = this.urlParamsService.createDeleteRequest(url, params);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToWcReport(response.data)];
                }
            });
        });
    };
    WCReportApiService.prototype.getWcCodes = function (type) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.codes;
                        params = {
                            type: type
                        };
                        request = this.urlParamsService.createGetRequest(url, params);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToWcCodeDefinitions(response.data)];
                }
            });
        });
    };
    WCReportApiService.prototype.getWcCompCases = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.compCases;
                        request = this.urlParamsService.createGetRequest(url);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToWorkesComCases(response.data)];
                }
            });
        });
    };
    WCReportApiService.prototype.getTreatments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.medTreatments;
                        request = this.urlParamsService.createGetRequest(url);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.maptoMedicalTreatments(response.data)];
                }
            });
        });
    };
    WCReportApiService.prototype.getReportStatuses = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getWCApi() + "/" + wcConfig.api.wc.reportStatuses;
                        request = this.urlParamsService.createGetRequest(url);
                        return [4 /*yield*/, this.apiUtilService
                                .request(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToReportStatuses(response.data)];
                }
            });
        });
    };
    WCReportApiService.prototype.getWCApi = function () {
        return this.getApiRoot() + "/" + wcConfig.api.wc.root;
    };
    WCReportApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return WCReportApiService;
}());
export { WCReportApiService };
