import { EmployeeSectionsBase } from '../employee-sections-base';
import { AccrualPolicy, IAccrualPolicy } from '../../../../organization/index';

export interface IEmployeeSectionsPolicyAssignments {
  records: IEmployeePolicyAssignment[];
  effectiveDate: string;
}

export class EmployeeSectionsPolicyAssignments extends EmployeeSectionsBase {
  public records: EmployeePolicyAssignment[];
  public effectiveDate: Date;

  constructor() {
    super();
    this.records = [];
  }
}

export interface IEmployeePolicyAssignment {
  id: number;
  empId: number;
  policyName: string;
  startDate: string;
  endDate: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  deleted: boolean;
  policyIdList: string;
  accrualPolicy: IAccrualPolicy;
}

export class EmployeePolicyAssignment {
  id: number;
  empId: number;
  policyName: string;
  startDate: Date;
  endDate: Date;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
  deleted: boolean;
  policyIdList: string;
  accrualPolicy: IAccrualPolicy;
}

export class EmployeePolicyAssignmentDTO {
  assignmentId: number;
  empId: number;
  policyName: string;
  startDate: string;
  endDate: string;
  deleted: boolean;
  policyIdList: number[];
}
