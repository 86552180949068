<div class="report-section white">
    <div class="report-section-header">
        <div class="report-section-header-content">
            <div class="header-item header-title">
                <em class="{{icon}}"></em>
                <span class="header-title__text">{{title}}</span>
            </div>
        </div>
        <div class="report-section-header-button-right">
            <button type="button"
              class="slx-button slx-blue slx-no-breaks"
              *ngIf="creatPlanAccess"
              (click)="createNewPlans()"
              [disabled]="!canCreateNew"
            >
                + New
            </button>

            <slx-collapse-button [isCollapsed]="getCollapsedState()" className="slx-with-icon"
                (isCollapsedChange)="collapsedChange($event, groupId)" [slxCollapseByCss]="collapseContent">
            </slx-collapse-button>
        </div>
    </div>
    <div #collapseContent class="report-section-content">
        <benefit-type-section-grid [groupId]="groupId" collapsedState="{{getCollapsedState()}}" [index]="index"></benefit-type-section-grid>
    </div>
</div>

