<slx-spinner [show]="isLoading">
<div class="list-container">
    <div *ngFor="let msg of messages" class="list-item">
        <div class="user-image">
            <div class="circular-portrait">
                <img class="rounded-image" [employeeImageSrc]="0" alt="Employee Photo" />
            </div>
        </div>
        <div class="message-container">
            <span class="sender-name theme-exsm-text">{{msg.senderFullName}}</span>
            <span class="moment-string theme-xs-text">{{ msg.createdOn | slxTimeToNow }}</span>
            <span class="msg-text theme-sm-text">{{msg.text}}</span>
        </div>
    </div>
</div>
</slx-spinner>
