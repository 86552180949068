import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { RowClassArgs } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';

import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { KendoGridStateHelper } from '../../../../../common/models/index';

import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';

import { ApplicationStateBusService } from '../../../../../organization/services/index';

import { PbjReconciliationManagementService } from '../../../services/index';
import { PBJRecOrgEntry, PBJRecDepEntry, PBJRecColumn } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-reconciliation-org-details',
  templateUrl: 'pbj-reconciliation-org-details.component.html',
  styleUrls: ['pbj-reconciliation-org-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbjReconciliationOrgDetailsComponent implements OnInit, OnDestroy {
  @Input('entry')
  public set innerEntry(value: PBJRecOrgEntry) {
    if (_.isObject(value)) {
      this.entry = value;
      this.loadData();
    }
  }

  @Input('startDate')
  public set innerStartDate(value: Date) {
    if (_.isDate(value)) {
      this.startDate = value;
      this.loadData();
    }
  }

  @Input('endDate')
  public set innerEndDate(value: Date) {
    if (_.isDate(value)) {
      this.endDate = value;
      this.loadData();
    }
  }

  @Input()
  public columns: StringMap<PBJRecColumn>;

  public pbjRecDetails: PBJRecDepEntry[];
  public gridState: KendoGridStateHelper<PBJRecDepEntry>;
  public appConfig: IApplicationConfig;
  public showDetailedColumns: boolean;
  public isLoading: boolean;

  @unsubscribe()
  private stateBusSubscription: Subscription;
  @unsubscribe()
  private orgEntryDetailsSubscription: Subscription;
  @unsubscribe()
  private expandedDetailsSubscription: Subscription;

  private entry: PBJRecOrgEntry;
  private orgLevelId: number;
  private orgLevels: OrgLevel[];
  private startDate: Date;
  private endDate: Date;

  constructor(
    private managementService: PbjReconciliationManagementService,
    private cdr: ChangeDetectorRef,
    private stateBusService: ApplicationStateBusService
  ) {
    this.appConfig = appConfig;
    this.isLoading = true;
    this.gridState = new KendoGridStateHelper<PBJRecDepEntry>();
    this.gridState.state.sort = [{ field: 'depName', dir: 'asc' }];
  }

  public ngOnInit(): void {
    this.stateBusSubscription = this.stateBusService.orgLevelsLoaded$.subscribe((orgLevels: OrgLevel[]) => {
      if (_.isArray(orgLevels)) {
        this.orgLevels = orgLevels;
        this.loadData();
      }
    });

    this.orgEntryDetailsSubscription = this.managementService.subscribeToLoadedDepEntries(({ orgLevelId, entries }: { orgLevelId: number, entries: PBJRecDepEntry[] }) => {
      if (this.orgLevelId === orgLevelId) {
        this.pbjRecDetails = entries;
        this.isLoading = false;
        this.refreshGrid();
        this.cdr.detectChanges();
      }
    });

    this.expandedDetailsSubscription = this.managementService.subscribeToExpandedDetails((isOn: boolean) => {
      this.showDetailedColumns = isOn;
      this.refreshGrid();
      this.cdr.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public defineRowClass(): (row: RowClassArgs) => string {
    return (row: RowClassArgs) => 'reconcil-row';
  }

  public onDepClick(item: PBJRecDepEntry): void {
    this.managementService.changeOrgLevel(item.depId, OrgLevelType.department);
  }

  private findOrgLevelInTree(): OrgLevel {
    const orgId: number = _.get(this.entry, 'orgId');
    const orgName: string = _.get(this.entry, 'orgName');
    if (_.isNumber(orgId) && _.isString(orgName) && _.isArray(this.orgLevels)) {
      return this.searchOrglevel(orgId, orgName);
    }
    return null;
  }

  private searchOrglevel(relatedItemId: number, name: string): OrgLevel {
    let orgLevel: OrgLevel = null;
    _.forEach(this.orgLevels, (o: OrgLevel) => {
      orgLevel = this.searchInTree(o, relatedItemId, name);
    });

    return orgLevel;
  }

  private searchInTree(o: OrgLevel, relatedItemId: number, name: string): OrgLevel {
    if (o.relatedItemId === relatedItemId && o.name === name) return o;

    let orgLevel: OrgLevel = null;
    if (o.childs.length > 0) {
      _.forEach(o.childs, (currOrgLevel: OrgLevel) => {
        if (_.isNull(orgLevel)) {
          orgLevel = this.searchInTree(currOrgLevel, relatedItemId, name);
        }
      });
    }

    return orgLevel;
  }

  private loadData(): void {
    const orgLevel: OrgLevel = this.findOrgLevelInTree();
    if (
      _.isNumber( _.get(orgLevel, 'id') ) &&
      _.isDate( this.startDate ) &&
      _.isDate( this.endDate )
    ) {
      this.orgLevelId = orgLevel.id;
      this.managementService.loadDepEntries(orgLevel.id, this.startDate, this.endDate, false);
    }
  }

  private refreshGrid(): void {
    if (!this.pbjRecDetails) {
      this.gridState.view = null;

      return;
    }

    this.gridState.view = process(this.pbjRecDetails, this.gridState.state);
  }
}

