import { TimelineComponentConfigiration, TimelineComponentDrawConfigiration, TimelineHelper } from './timeline-helper';
import { ITimelineItem } from '../../models/index';
import * as domUtils from '../../utils/domUtils';

export class TimelineFitHelper extends TimelineHelper {

  constructor(config: TimelineComponentConfigiration) {
    super(config);
  }

  public updateSlide(timelineTotWidth: number, forward: boolean): void {
    this.prevLinkInactive = true;
    this.nextLinkInactive = true;
  }

  public updateFillingStartByDate(date: Date): void {
    const distanceNorm = this.calcDistanceNorm(this.startDate, date);
    let scaleValue = 0;
    if (distanceNorm > 0) {
      scaleValue = (distanceNorm + this.elementWidth / 2) / (this.eventsWrapperWidth);
    }
    this.setTransformValue(this.config.fillingLineStart.nativeElement, 'scaleX', scaleValue);
  }
  public updateFillingEndByDate(date: Date): void {
    const distanceFromStartNorm = this.calcDistanceNorm(this.startDate, date);
    const distanceNorm = this.calcDistanceNorm(date, this.endDate);
    let scaleValue = 0;
    if (distanceNorm > 0) {
      scaleValue = (distanceNorm - this.elementWidth / 2) / this.eventsWrapperWidth;
    }
    this.setTransformValue(this.config.fillingLineEnd.nativeElement, 'scaleX', scaleValue);
  }

  protected initTimelineImpl(timeLines: ITimelineItem[], rtConfig: TimelineComponentDrawConfigiration): void {
    this.setTimelineWidth();
    this.setDatePosition(timeLines);
    this.setTransformValue(this.config.eventsWrapper.nativeElement, 'translateX', '0px');
    this.prevLinkInactive = true;
    this.nextLinkInactive = true;
    this.updateFillingEndByDate(this.endDate);
    this.updateFillingStartByDate(this.startDate);
  }

  protected setTimelineWidth(): number {
    this.eventsWrapperWidth = this.parentWidth;
    //this.config.renderer.setStyle(this.config.eventsWrapper.nativeElement, 'left', this.offset + 'px');
    let aHref = this.config.eventsWrapper.nativeElement.querySelectorAll('a.selected')[0];
    this.updateFilling(aHref);
    this.updateTimelinePosition(aHref);
    return this.eventsWrapperWidth;
  }

  protected setDatePosition(elements: ITimelineItem[]): void {
    let timeSpan = this.dayDiff(this.startDate, this.endDate);

    let timelineEventsArray = this.config.timelineEvents.toArray();
    if (timelineEventsArray.length === 0) {
      return;
    }

    let i: number = 0;
    this.norm = (this.eventsWrapperWidth - this.offset * 2) / timeSpan;

    for (let component of elements) {
      const distanceNorm = this.calcDistanceNorm(this.startDate, component.date);
      const e = timelineEventsArray[i];
      if (e) {
        this.config.renderer.setStyle(e.nativeElement, 'left', distanceNorm + 'px');
        // span
        let span: HTMLSpanElement = <HTMLSpanElement>e.nativeElement.parentElement.children[1];
        let spanWidth = this.getElementWidth(span);
        this.config.renderer.setStyle(span, 'left', distanceNorm + spanWidth / 2 + 'px');
        //span.style.left = distanceNorm * min + spanWidth / 2 + 'px';
      }
      i++;
    }
  }

  protected calcDistanceNorm(startDate: Date, endDate: Date): number {
    const distance = this.dayDiff(startDate, endDate);
    const distanceNorm = Math.round(distance * this.norm);
    return distanceNorm;
  }
}
