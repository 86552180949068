import { ElementRef, forwardRef, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import * as createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var MONEY_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return MoneyInputComponent; }),
    multi: true
};
var MoneyInputComponent = /** @class */ (function () {
    function MoneyInputComponent(changeDetector, elementRef) {
        this.placeholder = 'Money';
        this.decimalLimit = 0;
        this.requireDecimal = false;
        this.allowNegative = false;
        this.prefix = '$';
        this.suffix = '';
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = '';
        this.hasFocus = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.changeDetector = changeDetector;
        this.hasFocus = false;
    }
    Object.defineProperty(MoneyInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoneyInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                var val = this.innerValue;
                if (val) {
                    val = val.replace(/\$/g, '');
                    val = val.replace(/,/g, '');
                }
                this.onChangeCallback(parseFloat(val));
            }
        },
        enumerable: true,
        configurable: true
    });
    MoneyInputComponent.prototype.onCustomFocus = function () {
        this.hasFocus = true;
    };
    MoneyInputComponent.prototype.onCustomBlur = function () {
        this.hasFocus = false;
    };
    MoneyInputComponent.prototype.ngOnChanges = function (changes) {
        var myattr = this.elementRef.nativeElement.getAttribute('readonly');
        this.inEdit = !myattr;
    };
    MoneyInputComponent.prototype.ngOnInit = function () {
        this.initMask();
    };
    MoneyInputComponent.prototype.writeValue = function (value) {
        this.innerValue = value;
        this.changeDetector.markForCheck();
    };
    MoneyInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    MoneyInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    MoneyInputComponent.prototype.initMask = function () {
        var numberMask = createNumberMask.default({
            prefix: this.prefix,
            suffix: this.suffix,
            includeThousandsSeparator: true,
            allowDecimal: true,
            decimalSymbol: '.',
            decimalLimit: this.decimalLimit,
            requireDecimal: this.requireDecimal,
            allowNegative: this.allowNegative
        });
        this.moneyMaskConf = {
            mask: numberMask,
            modelClean: true
        };
    };
    return MoneyInputComponent;
}());
export { MoneyInputComponent };
