import { EventEmitter } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
var OpenShiftManagementManagementService = /** @class */ (function () {
    function OpenShiftManagementManagementService() {
        this.dataEmitter = new EventEmitter();
        this.onMessageCountChanged$ = new ReplaySubject(1);
        this.onPartnerShiftCountChanged$ = new ReplaySubject(1);
        this.onOpenShiftCountChanged$ = new ReplaySubject(1);
        this.onOpenShiftCountChangedByPartialShift$ = new ReplaySubject(1);
        this.selectOpenShiftsDailyDetails$ = new ReplaySubject(1);
        this.onShowFilledShiftsChanged$ = new ReplaySubject(1);
        this.onPostSettingsLoaded$ = new ReplaySubject(1);
        this.loading$ = new Subject();
        this.selectedScheduleCycleData$ = new ReplaySubject(1);
    }
    Object.defineProperty(OpenShiftManagementManagementService.prototype, "showFilledShifts", {
        get: function () {
            return this.m_showFilledShifts;
        },
        set: function (value) {
            this.m_showFilledShifts = value;
            this.onShowFilledShiftsChanged$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    OpenShiftManagementManagementService.prototype.setCanPostSchedule = function (value) {
        this.canPostSchedule = value;
    };
    OpenShiftManagementManagementService.prototype.setCanEditOpenShiftCount = function (value) {
        this.canEditOpenShiftCount = value;
    };
    OpenShiftManagementManagementService.prototype.setCanApproveDenyPartnerShiftCount = function (value) {
        this.canApproveDenyPartnerShiftCount = value;
    };
    OpenShiftManagementManagementService.prototype.setCanSendSMS = function (value) {
        this.canSendSMS = value;
    };
    OpenShiftManagementManagementService.prototype.onMessageCountChanged = function (args) {
        this.onMessageCountChanged$.next(args);
    };
    OpenShiftManagementManagementService.prototype.onPartnerShiftCountChanged = function (args) {
        this.onPartnerShiftCountChanged$.next(args);
    };
    OpenShiftManagementManagementService.prototype.onOpenShiftCountChanged = function (args) {
        this.onOpenShiftCountChanged$.next(args);
    };
    OpenShiftManagementManagementService.prototype.onOpenShiftCountChangedByPartialShift = function (args) {
        this.onOpenShiftCountChangedByPartialShift$.next(args);
    };
    OpenShiftManagementManagementService.prototype.selectDailyDetails = function (args) {
        this.selectOpenShiftsDailyDetails$.next(args);
    };
    OpenShiftManagementManagementService.prototype.postSettingsLoaded = function (args) {
        this.postSettings = args;
        this.onPostSettingsLoaded$.next(args);
    };
    OpenShiftManagementManagementService.prototype.updatePostSettingsByCycle = function (args) {
        this.postSettings.startDate = args.startDate.toDate();
        this.postSettings.endDate = args.endDate.toDate();
    };
    OpenShiftManagementManagementService.prototype.changeLoadingState = function (isShown) {
        this.loading$.next(isShown);
    };
    OpenShiftManagementManagementService.prototype.isCheckedEmitter = function (data) {
        this.dataEmitter.emit(data);
    };
    OpenShiftManagementManagementService.prototype.getSelectedScheduleCycle = function (args) {
        this.selectedScheduleCycle = args;
        this.selectedScheduleCycleData$.next(args);
    };
    return OpenShiftManagementManagementService;
}());
export { OpenShiftManagementManagementService };
