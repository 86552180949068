<a class="leave-request-link" *ngIf="imgPhoto && parent === 'messageGrid'">
    <img class="img" src="{{imgPhoto}}" alt="Employee Photo" />
</a>
<div *ngIf="!imgPhoto && parent === 'messageGrid'">
    {{ employeeName?.charAt(0) | uppercase}}
</div>
<div *ngIf="imgPhoto && parent === 'composeNewModal'" class="compose-circle">
    <img class="img-compose" src="{{imgPhoto}}" alt="Employee Photo" />
</div>
<div *ngIf="parent === 'composeNewModal' && !imgPhoto" class="compose-circle"
    [ngClass]="!mobilePhoneNumber || empOptIn!=1 ? 'avatar-disable': ''">
    <span class="firstLetterText">{{ employeeName?.split('')[0] | uppercase }}</span>
</div>