import { Component, Input, ContentChild, OnInit, OnDestroy, AfterContentChecked } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { select } from '@angular-redux/store';
import { StepSwitchDirective } from '../../directives/wizard-step/wizard-step.directive';
import { StepDefinition } from '../../directives/wizard-step/wizard-step.types';
import { StringUtils } from '../../../framework/string-utils/string-utils';

@Component({
  moduleId: module.id,
  selector: 'slx-wizard',
  templateUrl: 'wizard.component.html',
  styleUrls: ['wizard.component.scss']
})
export class WizardComponent implements OnInit, OnDestroy, AfterContentChecked {
  @select(['wizard', 'step'])
  public wizard$: Observable<number>;
  @Input() public currentStep: number;

  public get progress(): string {
    if (!this.stepDefinitions) return '0';
    if (this.stepDefinitions.length <= 1) return '100%';
    if (this.currentStep <= 1) return '0';
    if (this.currentStep >= (this.stepDefinitions.length)) return '100%';
    let prg: number = Math.round(100 * (this.currentStep - 1) / this.stepDefinitions.length);
    return StringUtils.format('{0}%', prg);
  }
  public stepDefinitions: StepDefinition[];

  @ContentChild(StepSwitchDirective, {static: true}) private stepSwitch: StepSwitchDirective;

  private switchInitialized: boolean;
  private wizardSubscription: Subscription;

  public ngOnInit(): void {
    this.switchInitialized = false;
    this.wizardSubscription = this.wizard$.subscribe((newStep: number) => {
      this.setStep(newStep);
    });
  }

  public ngOnDestroy(): void {
    this.wizardSubscription.unsubscribe();
  }

  public setStep(step: number): void {
    this.currentStep = step;
    if (this.stepSwitch) {
      this.stepSwitch.stepSwitch = step;
    }
  }

  public ngAfterContentChecked(): void {
    if (this.stepSwitch) {
      this.stepDefinitions = this.stepSwitch.getStepDefinitions();
      if (!this.switchInitialized) {
        this.stepSwitch.stepSwitch = this.currentStep;
      }
    }
  }
}
