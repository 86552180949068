/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-console-weekly-indicator.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "angular2-moment/date-format.pipe";
import * as i4 from "./schedule-console-weekly-indicator.component";
var styles_ScheduleConsoleWeeklyIndicatorComponent = [i0.styles];
var RenderType_ScheduleConsoleWeeklyIndicatorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleConsoleWeeklyIndicatorComponent, data: {} });
export { RenderType_ScheduleConsoleWeeklyIndicatorComponent as RenderType_ScheduleConsoleWeeklyIndicatorComponent };
function View_ScheduleConsoleWeeklyIndicatorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "weekly-indicator__day"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "weekly-indicator__day-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), i1.ɵpid(0, i2.SlicePipe, []), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "weekly-indicator__day-color"]], [[4, "background", null]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 4).transform(i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.date, "dd")), 0, 1)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.color; _ck(_v, 5, 0, currVal_1); }); }
function View_ScheduleConsoleWeeklyIndicatorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "weekly-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleWeeklyIndicatorComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.week; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ScheduleConsoleWeeklyIndicatorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i3.DateFormatPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleWeeklyIndicatorComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasDays; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ScheduleConsoleWeeklyIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-console-weekly-indicator", [], null, null, null, View_ScheduleConsoleWeeklyIndicatorComponent_0, RenderType_ScheduleConsoleWeeklyIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i4.ScheduleConsoleWeeklyIndicatorComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var ScheduleConsoleWeeklyIndicatorComponentNgFactory = i1.ɵccf("slx-schedule-console-weekly-indicator", i4.ScheduleConsoleWeeklyIndicatorComponent, View_ScheduleConsoleWeeklyIndicatorComponent_Host_0, { days: "days" }, {}, []);
export { ScheduleConsoleWeeklyIndicatorComponentNgFactory as ScheduleConsoleWeeklyIndicatorComponentNgFactory };
