import { Actions } from "../../../core/models/index";

export interface IPayrollPayCode {
    payrollPaycode: string;
    payNumber: number;
}

export class PayrollPayCode {
    public payrollPaycode: string;
    public payNumber: number;
    public isDirty: boolean;
}


export class PayrollPayCodeModel {
    public data: PayrollPayCode[];
    public actions: Actions;
}
