import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Params, Resolve } from '@angular/router';
import { PunchesRouteData } from '../../../time-and-attendance/models/punches/punches-route-data';
import { PayCycleHelperService, ApplicationStateBusService } from '../../../organization/services/index';
import { appConfig } from '../../../app.config';
import { RangeDates } from '../../../common/models/range-dates';
import { BaseResolver } from '../../../common/index';
var DailyPunchesResolver = /** @class */ (function (_super) {
    tslib_1.__extends(DailyPunchesResolver, _super);
    function DailyPunchesResolver(router, payCycleHelper, busService) {
        var _this = _super.call(this, router) || this;
        _this.payCycleHelper = payCycleHelper;
        _this.busService = busService;
        return _this;
    }
    DailyPunchesResolver.prototype.resolve = function (route, state) {
        var _this = this;
        var onlyMissing = _.toString(route.queryParamMap.get('onlyMissing')) === 'true';
        if (onlyMissing) {
            var params = this.createQueryParams(route, state);
            var routeData = new PunchesRouteData();
            this.savedMissingFlag = routeData.isMissingOnly = true;
            this.navigateToPath(route, params);
            return routeData;
        }
        var range = this.getRangeDates(route);
        if (range.startDate && range.endDate) {
            if (!moment(range.endDate).isAfter(range.startDate)) {
                range.endDate = moment(range.startDate).add(1, 'day').toDate();
            }
        }
        else if (range.startDate) {
            range.endDate = moment(range.startDate).add(1, 'day').toDate();
        }
        else if (range.endDate) {
            range.startDate = moment(range.endDate).subtract(1, 'day').toDate();
        }
        else {
            //default to yesterday
            range.endDate = moment().add(-1, 'day').toDate();
            range.startDate = moment(range.endDate).startOf('day').toDate();
        }
        if (!this.busService.isInitialized) {
            var routeData = new PunchesRouteData();
            routeData.range = range;
            return routeData;
        }
        return this.getOrgLevel().then(function (orgLevel) {
            return _this.payCycleHelper.getPayCycleByDate(range.startDate, orgLevel.id).then(function (payCycle) {
                if (payCycle) {
                    if (moment(payCycle.startDate).isBefore(range.endDate)) {
                        if (moment(range.endDate).diff(payCycle.startDate, 'day') > 30) {
                            range.startDate = moment(range.endDate).subtract(30, 'day').toDate();
                        }
                        else {
                            range.startDate = payCycle.startDate;
                        }
                    }
                    else {
                        range.startDate = moment(range.endDate).startOf('day').subtract(1, 'day').toDate();
                    }
                }
                var routeData = new PunchesRouteData();
                routeData.range = range;
                routeData.payCycle = payCycle;
                routeData.isMissingOnly = _this.savedMissingFlag;
                _this.savedMissingFlag = false;
                return routeData;
            });
        });
    };
    DailyPunchesResolver.prototype.createQueryParams = function (route, state) {
        var params = _super.prototype.createQueryParams.call(this, route);
        delete params['onlyMissing'];
        return params;
    };
    DailyPunchesResolver.prototype.getOrgLevel = function () {
        var promise = this.busService.orgLevelSelected$.first().toPromise();
        return promise;
    };
    DailyPunchesResolver.prototype.getRangeDates = function (route) {
        var startDateParam = _.toString(route.queryParamMap.get('startDate'));
        var endDateParam = _.toString(route.queryParamMap.get('endDate'));
        var startDate = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        var endDate = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;
        var range = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;
        return range;
    };
    return DailyPunchesResolver;
}(BaseResolver));
export { DailyPunchesResolver };
