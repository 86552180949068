<div class="modal-content">
  <div class="modal-body">
    <slx-shift-replacement-selector [settings]="settings"></slx-shift-replacement-selector>
  </div>

  <div class="modal-footer" *ngIf="sendSmsModeOn">
    <button type="button" (click)="onCancelSend()" class="btn btn-default red">
      <i class="fa fa-ban" aria-hidden="true"></i>Cancel
    </button>
    <button type="button" (click)="onSend()" [disabled]="!smsData.canSend" class="btn btn-primary green">
      <i class="fas fa-mobile-alt" aria-hidden="true"></i>Send
    </button>
  </div>
  <div class="modal-footer" *ngIf="!sendSmsModeOn">
    <button type="button" (click)="onCancelAdd()" class="btn btn-default red">
      <i class="fa fa-ban" aria-hidden="true"></i>Discard
    </button>
    <button type="button" (click)="onAdd()" [disabled]="isAddDisabled" class="btn btn-primary green">
      <i class="fas fa-save" aria-hidden="true"></i>Add
    </button>
  </div>
</div>
