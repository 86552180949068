/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-individual-schedule-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../../individual-schedule/individual-schedule-employee/individual-schedule-employee.component.ngfactory";
import * as i5 from "../../../services/individual-schedule/individual-schedule-management.service";
import * as i6 from "../../../store/individual-schedule/individual-schedule.actions";
import * as i7 from "../../../services/individual-schedule/individual-schedule-emp-management.service";
import * as i8 from "../../individual-schedule/individual-schedule-employee/individual-schedule-employee.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../../organization/services/lookup/lookup-api.service";
import * as i11 from "../../../../organization/services/employee/employee-definitions-api.service";
import * as i12 from "./view-individual-schedule-dialog.component";
import * as i13 from "../../../../common/models/dialog-options";
import * as i14 from "../../../../common/services/modal/modal.service";
import * as i15 from "../../../models/master-schedule/master-schedule-context-menu-request";
var styles_ViewIndividualScheduleDialogComponent = [i0.styles];
var RenderType_ViewIndividualScheduleDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewIndividualScheduleDialogComponent, data: {} });
export { RenderType_ViewIndividualScheduleDialogComponent as RenderType_ViewIndividualScheduleDialogComponent };
export function View_ViewIndividualScheduleDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "slx-individual-schedule-employee", [], null, [[null, "onHasChanges"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onHasChanges" === en)) {
        var pd_0 = (_co.onHasChanges($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IndividualScheduleEmployeeComponent_0, i4.RenderType_IndividualScheduleEmployeeComponent)), i1.ɵprd(4608, null, i5.IndividualScheduleManagementService, i5.IndividualScheduleManagementService, [i6.IndividualScheduleActions, i7.IndividualScheduleEmpManagementService]), i1.ɵprd(512, null, i7.IndividualScheduleEmpManagementService, i7.IndividualScheduleEmpManagementService, []), i1.ɵdid(6, 245760, null, 0, i8.IndividualScheduleEmployeeComponent, [i9.ActivatedRoute, i9.Router, i10.LookupApiService, i11.EmployeeDefinitionsApiService, i7.IndividualScheduleEmpManagementService], { scheduleViewParams: [0, "scheduleViewParams"] }, { onHasChanges: "onHasChanges" }), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.scheduleViewParams; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ViewIndividualScheduleDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-view-individual-schedule-dialog", [], null, null, null, View_ViewIndividualScheduleDialogComponent_0, RenderType_ViewIndividualScheduleDialogComponent)), i1.ɵdid(1, 49152, null, 0, i12.ViewIndividualScheduleDialogComponent, [i13.DialogOptions, i14.ModalService, i15.MasterScheduleContextMenuRequest], null, null)], null, null); }
var ViewIndividualScheduleDialogComponentNgFactory = i1.ɵccf("slx-view-individual-schedule-dialog", i12.ViewIndividualScheduleDialogComponent, View_ViewIndividualScheduleDialogComponent_Host_0, {}, {}, []);
export { ViewIndividualScheduleDialogComponentNgFactory as ViewIndividualScheduleDialogComponentNgFactory };
