<slx-spinner [show]="isLoading">
    <div class="shift-start-time-s">
        <strong>Shift start time(s)</strong>
    </div>
    <div class="basic-information" *ngIf="records">
        <div class="shift-item1">
            <span>Day Shift</span>
        </div>
        <div class="time-item">
            <span>
                <slx-input-decorator className="slx-no-background">
                    <input slx-time-picker slx-input name="day" [(ngModel)]="records.time1"
                        (onDateChanged)="onShiftDateChanged()" format="hh:mm tt" dateInput="true">
                </slx-input-decorator>
            </span>
        </div>
        <div class="shift-item2">
            <span>Evening Shift</span>
        </div>
        <div class="time-item">
            <span>
                <slx-input-decorator className="slx-no-background">
                    <input slx-time-picker slx-input name="evening" [(ngModel)]="records.time2"
                        (onDateChanged)="onShiftDateChanged()" format="hh:mm tt" dateInput="true">
                </slx-input-decorator>
            </span>
        </div>
        <div class="shift-item3">
            <span>Night Shift</span>
        </div>
        <div class="time-item">
            <span>
                <slx-input-decorator className="slx-no-background">
                    <input slx-time-picker slx-input name="night" [(ngModel)]="records.time3"
                        (onDateChanged)="onShiftDateChanged()" format="hh:mm tt" dateInput="true">
                </slx-input-decorator>
            </span>
        </div>

    </div>
    <div class="content-grid">
        <kendo-grid #gridReportSettings class="slx-blue-grid slx-grid-slim-rows slx-full-height"
            [data]="gridState.view">
            <kendo-grid-column field='position' title='Position'>
                <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Position</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    {{ dataItem.position }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field='positionCategoryType' title="Staffing Report">
                <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Staffing Report</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-dropdown-list class="slx-wide" name="positionCategory{{rowIndex}}"
                        [options]="dailyAttendanceOptions"
                        [ngModel]="getPositionCategory(dataItem.positionCategoryType)" [valueField]="'id'"
                        [titleField]="'name'" [valuePrimitive]="false"
                        (ngModelChange)="setPositionCategory(dataItem,$event)">
                    </slx-dropdown-list>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</slx-spinner>