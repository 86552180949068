<kendo-grid #grid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" sortable="true" [sort]="gridState.state.sort"
  [filterable]="false" [filter]="gridState.state.filter" scrollable="none" class="slx-blue-grid slx-user-rights-grid slx-hidden-header">

  <kendo-grid-column title="" [sortable]="true" field="description" media="sm" width="268" [filterable]="true">
    <ng-template kendoGridHeaderTemplate>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.description}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <ng-template ngFor let-role [ngForOf]="container?.roles">
    <kendo-grid-column title=""
      [sortable]="false"
      media="sm"
      [filterable]="false"
      width="100"
      [hidden]="!roleColumnsState.isVisible(role)"
    >
      <ng-template kendoGridHeaderTemplate>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          <div *ngIf="!dataItem.hasLinkedMenus" class="right-changer" (click)="onAccessClick(dataItem, role.id)">
              <i  [ngClass]="{
                'far': dataItem.mapByRole[role.id].access==='enabled' || dataItem.mapByRole[role.id].access==='disabled',
                'fa-check-square': dataItem.mapByRole[role.id].access==='enabled',
                'fa-square': dataItem.mapByRole[role.id].access==='disabled',
                'fab fa-delicious': dataItem.mapByRole[role.id].access==='mixed',
                 'dirty': dataItem.dirtyByRole[role.id]
                }"
                aria-hidden="true"></i>
            </div>
            <div *ngIf="dataItem.hasLinkedMenus" class="right-changer-disabled">
                <i tipPosition="bottom" slxTooltip="At least one Action in group is linked to Menu Item. This item cannot be independently edited."
                [ngClass]="{
                  'far': dataItem.mapByRole[role.id].access==='enabled' || dataItem.mapByRole[role.id].access==='disabled',
                  'fa-check-square': dataItem.mapByRole[role.id].access==='enabled',
                  'fa-square': dataItem.mapByRole[role.id].access==='disabled',
                  'fab fa-delicious': dataItem.mapByRole[role.id].access==='mixed',
                  'dirty': dataItem.dirtyByRole[role.id]
                  }"
                  aria-hidden="true"></i>
            </div>
      </ng-template>
    </kendo-grid-column>
  </ng-template>
  <ng-template kendoGridDetailTemplate let-dataItem>
    <div class="role-details-grid">
      <slx-roles-right-grid [container]="dataItem" [containerType]="container.type" (roleRightChanged)="roleRightChanged($event, dataItem)"></slx-roles-right-grid>
    </div>
  </ng-template>
</kendo-grid>
