import { IBenefitEnrolledEmployee, BenefitEnrolledEmployee } from './index';

export interface IEmployeeBenefitsEditResult {
  isSuccess: boolean;
  item?: IEmployeeBenefitsEditModel;
  message: string;
}

export class EmployeeBenefitsEditResult {
  public isSuccess: boolean;
  public item?: EmployeeBenefitsEditModel;
  public message: string;
}

export interface IEmployeeBenefitsEditModel {
  benefitLineId: number;
  benefitTierId: number;
  effectiveDate: string;
  notes: string;
  calculationMethod: string;
  employeeToBenefitRecords: IBenefitEnrolledEmployee[];
}

export class EmployeeBenefitsEditModel {
  public benefitLineId: number;
  public benefitTierId: number;
  public effectiveDate: any;
  public notes: string;
  public calculationMethod: string;
  public employeeToBenefitRecords: BenefitEnrolledEmployee[];
}
