import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { MetaMapService } from '../../../core/services/index';
import { FieldsMeta, Actions } from '../../../core/models/index';
import { TelepunchLocationsContainer } from '../../models/telepunch-locations/telepunch-location-container';
import { ITelepunchLocation, TelepunchLocation } from '../../models/telepunch-locations/telepunch-location';
import { dateTimeUtils } from '../../../common/utils/index';
@Injectable()
export class TelepunchLocationsMapService {

    constructor(private metaMapService: MetaMapService) { }

    public mapToTelepunchLocationsContainer(data: ITelepunchLocation[], meta: FieldsMeta): TelepunchLocationsContainer {
        let container: TelepunchLocationsContainer = new TelepunchLocationsContainer();
        let actions: Actions = this.metaMapService.mapActions(meta);
        container.actions = actions;
        container.records = this.mapToTelepunchLocations(data, meta);
        return container;
    }

    public mapToTelepunchLocations(dtos: ITelepunchLocation[], meta: FieldsMeta): TelepunchLocation[] {
        let models: TelepunchLocation[] = [];
        _.each(dtos, (dto: ITelepunchLocation) => {
            let model: TelepunchLocation = this.mapToTelepunchLocation(dto, meta);
            models.push(model);

        });
        return models;
    }

    public mapToTelepunchLocation(dto: ITelepunchLocation, meta?: FieldsMeta): TelepunchLocation {
        let model: TelepunchLocation = new TelepunchLocation();
        model.id = dto.id;
        model.authorized = dto.authorized > 0;
        model.locationId = dto.locationId;
        model.locationName = dto.locationName;
        model.phone = dto.phone;
        model.userName = dto.lastUpdateUserName;
        model.date = dateTimeUtils.convertFromDtoDateTimeString(dto.lastUpdateDate);
        return model;
    }

    public cloneCollection(items: TelepunchLocation[]): TelepunchLocation[] {
        let collection: TelepunchLocation[] = [];
        _.each(items, (model: TelepunchLocation) => {
            let clonedCode: TelepunchLocation = this.mapToTelepunchLocation(this.mapToITelepunchLocation(model));
            collection.push(clonedCode);
        });
        return collection;
    }

    public mapToITelepunchLocation(model: TelepunchLocation): ITelepunchLocation {
        let dto: ITelepunchLocation = {
            id: model.id,
            locationId: model.locationId,
            locationName: model.locationName,
            phone: model.phone,
            authorized: model.authorized ? 1 : 0,
            lastUpdateUserName: '',
            lastUpdateDate: ''
        };
        return dto;
    }

    public mapToITelepunchLocations(models: TelepunchLocation[]): ITelepunchLocation[] {
        return _.map(models, tp => this.mapToITelepunchLocation(tp));
    }

}
