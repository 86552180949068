<form [formGroup]="formGroup">
    <div class="editor-container">
        <div class="field-container wrapped">

            <div class="col">
                <slx-input-decorator>
                    <input class="form-control" slx-input type="text" formControlName="name" [required]="true" placeholder="Name"
                    [prohibitedValues]="prohibitedNameValues"
                    >
                    <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                </slx-input-decorator>
            </div>
            <div class="col">
                <slx-input-decorator>
                    <input class="form-control" maxlength="1" minlength="1" slx-input type="text" formControlName="code" [required]="true" placeholder="Code"
                    [prohibitedValues]="prohibitedCodeValues"
                    >
                    <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                </slx-input-decorator>
            </div>
            <div class="col">
                <slx-input-decorator>
                    <slx-kendo-number slx-input
                      class="form-control"
                      [decimals]="2"
                      [step]="0.01"
                      [min]="0"
                      [max]="1"
                      [slxMin]="0"
                      [slxMax]="1"
                      [required]="true"
                      placeholder="Count as"
                      formControlName="countAs"
                    ></slx-kendo-number>
                </slx-input-decorator>
            </div>

            <div class="actions-bar">
                <div class="col md-no-line-column"></div>
                <button class="action-button save-btn" (click)="onSaveClick ($event)" [disabled]="formGroup.invalid">Save</button>
                <button class="action-button cancel-btn" (click)="onCancelClick ($event)">Cancel</button>
            </div>
        </div>
    </div>
</form>
