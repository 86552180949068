<div class="slx-paycycle-dropdown">
    <label class="slx-paycycle-dropdown__label">Payroll Cycle</label>

    <slx-dropdown-list class="slx-wide slx-short slx-paycycle-dropdown__year"
      [options]="yearList"
      [valueField]="'year'"
      [titleField]="'year'"
      [(ngModel)]="currentYear"
    ></slx-dropdown-list>

    <slx-dropdown-list class="slx-wide slx-short slx-paycycle-dropdown__range"
      [options]="rangeList"
      [valueField]="'range'"
      [titleField]="'range'"
      [(ngModel)]="currentRange"
    ></slx-dropdown-list>
</div>
