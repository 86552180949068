import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State, CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../../common/models/index';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { IApplicationConfig, appConfig } from '../../../../../../../app/app.config';

import { mutableSelect, unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { OrgLevel } from '../../../../../../state-model/models';
import { BenefitLineInfo } from '../../../../models/index';

import { BenefitListManagementService } from './../../../../services/benefit-console';

@Component({
  selector: 'benefit-recent-activity',
  templateUrl: 'benefit-recent-activity-component.html',
})
export class BenefitsRecentActivityComponent implements OnInit, OnDestroy {
  public records: BenefitLineInfo[];

  public gridState: KendoGridStateHelper<BenefitLineInfo>;
  public columnGroup = 'BenefitsRecentActivity';
  public appConfig: IApplicationConfig;
  public data: BenefitLineInfo[] = [];
  public isLoading: boolean = true;
  public isShowingExpired: boolean = false;
  public isPageable = false;
  public pageSize = 50;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private navService: BenefitsNavigationService;
  private orgLevel: OrgLevel;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private manService: BenefitListManagementService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitLineInfo>();
    this.gridState.view = null;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [
      { field: 'modifiedAt', dir: 'desc' }
    ];

    this.gridState.gridComponentKey = 'BenefitsRecentGrid';
    this.gridState.gridControlStateKey = 'GridFiltersState';
    this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
    this.gridState.assignRestoredState = true;
    this.gridState.refreshGridAfterRestoreState = true;
    this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
    this.appConfig = appConfig;
  }

  public ngOnInit() {
    this.subscriptions.orgLevel = this.manService
      .subscribeToOrgLevel((v: OrgLevel) => {
        this.orgLevel = v;
     });

    this.subscriptions.onLoad = this.manService
      .subscribeToLoading((value: boolean) => (this.isLoading = value));

    this.subscriptions.onLoaded = this.manService
      .subscribeToLoadedRecords((r: BenefitLineInfo[]) => {
        this.data = r;
        this.data = _.isObjectLike(this.data) ? _.map(_.orderBy(_.groupBy(this.data, x => [x.benefitGroupName, x.name].join()), ['modifiedAt'], ['desc']), x => _.first(x)) : null;
        this.isPageable = _.size(this.data) > this.pageSize;
        this.refreshGrid();
    });

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onClickBenefit(providerLine: BenefitLineInfo): void {
    const { benefitGroupId, benefitProviderId, id } = providerLine;
    this.navService.navigateToDetails(this.orgLevel.id, benefitGroupId, benefitProviderId, id);
  }

  private refreshGrid(): void {
    this.isLoading = false;
    if (!this.data) {
      this.gridState.view = null;
      return;
    }
    this.fixModifiedSorting();
    this.gridState.view = process(this.data, this.gridState.state);
  }

  //Please DO NOT USE this code in other places. It is temporary tricky fix of https://github.com/telerik/kendo-angular/issues/1850. Check this link if needed.
  private fixModifiedSorting(): void {
    if (this.gridState.state.sort) {
      const sortField: SortDescriptor = _.find(this.gridState.state.sort, (x: SortDescriptor) => x.field === 'filterModifiedAt');
      if (sortField) {
        const clone: SortDescriptor = _.cloneDeep(sortField);
        clone.field = 'modifiedAt';
        this.gridState.state.sort.push(clone);
      }
    }
  }

}
