import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';
import { DevicesDefinition, DependsOnDeviceTypeHelper } from '../../models/index';
import { DeviceDetectorService } from '../../services/index';
var HiddenOnDirective = /** @class */ (function (_super) {
    tslib_1.__extends(HiddenOnDirective, _super);
    function HiddenOnDirective(templateRef, viewContainer, devDetector) {
        var _this = _super.call(this) || this;
        _this.templateRef = templateRef;
        _this.viewContainer = viewContainer;
        _this.devDetector = devDetector;
        _this.deviceList = _this.devDetector.deviceList;
        return _this;
    }
    Object.defineProperty(HiddenOnDirective.prototype, "slxHiddenOn", {
        set: function (v) {
            if (_.isArray(v)) {
                this.devices = v;
                this.validateDevices();
            }
        },
        enumerable: true,
        configurable: true
    });
    HiddenOnDirective.prototype.updateView = function () {
        var isHidden = this.deviceHasInDeviceList();
        this.viewContainer.clear();
        if (!isHidden) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    };
    return HiddenOnDirective;
}(DependsOnDeviceTypeHelper));
export { HiddenOnDirective };
