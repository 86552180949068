export let ErrorCodeMessageCenter = {
   30001: "We are looking into this error. Please try sending it again later 30001 ",
   30002: "We are looking into this error. Please try sending it again later 30002 ",
   30003: "Unreachable number. 30003 ",
   30004: "Message blocked. Please contact the employee. 30004 ",
   30005: "Unknown number. 30005 ",
   30006: "Landline or unreachable carrier. 30006 ",
   30007: "Carrier Violation. 30007 ",
   30008: "We are looking into this error. Please try sending it again later 30008 ",
   30009: "Missing Segment. 30009",
   30010: "We are looking into this error. Please try sending it again later 30010 ",
   21211: "Invalid phone number 21211 "
}