import { ParamMap, ActivatedRouteSnapshot } from '@angular/router';

export interface IRouteInfo {
  componentId: string;
  accessContext?: 'OrgLevel' | 'Employee';
  orgLevelChangeDisabled?: boolean;
  showSecondLine?: boolean;
}
export interface IRouteDetails {
  params: ParamMap;
}

export function isRouteInfo(source: any): source is IRouteInfo {
  return !!source.componentId;
}

export function getChildRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  let childRoute: ActivatedRouteSnapshot = route;

  while (childRoute && childRoute.firstChild) {
    childRoute = childRoute.firstChild;
  }

  return childRoute;
}
