import * as tslib_1 from "tslib";
import { Provider } from '@angular/core';
import { DialogOptions, ModalService } from '../../../../common';
import { ScheduleEntryManagementService } from '../../../../scheduler/services';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { Observable, Subscription } from 'rxjs';
import { scheduleMicrotask } from '../../../../core/utils';
var DailyUnitViewSettingsPopupComponent = /** @class */ (function () {
    function DailyUnitViewSettingsPopupComponent(modalService, scheduleManService) {
        var _this = this;
        this.modalService = modalService;
        this.scheduleManService = scheduleManService;
        this.isLeftSideNavbarOpen = false;
        this.currentViewMode = this.scheduleManService.duaOptions.settingsType;
        this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe(function (value) {
            scheduleMicrotask(function () { _this.isLeftSideNavbarOpen = value; });
        });
    }
    DailyUnitViewSettingsPopupComponent.prototype.onApply = function () {
        this.dialogResult = true;
        this.modalService.closeAllWindows();
        // this.close.emit(this.settingsType);
    };
    DailyUnitViewSettingsPopupComponent.openDialog = function (options, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 300;
        dialogOptions.className = 'slx-dua-view-settings';
        dialogOptions.showCloseButton = false;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(DailyUnitViewSettingsPopupComponent, 'View Settings', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    DailyUnitViewSettingsPopupComponent.prototype.handleSettingType = function (event) {
        this.currentViewMode = event;
        this.scheduleManService.duaOptions.settingsType = event;
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], DailyUnitViewSettingsPopupComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], DailyUnitViewSettingsPopupComponent.prototype, "isLeftSidebarOpenSubscripion", void 0);
    return DailyUnitViewSettingsPopupComponent;
}());
export { DailyUnitViewSettingsPopupComponent };
