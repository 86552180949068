import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Assert } from '../../../framework/index';

@Injectable()
export class JwtService {
  private readonly defaultExpirationPropertyName: string = 'exp';
  private readonly defaultIssuedPropertyName: string = 'iat';

  public decodeToken<T>(token: string): T {
    Assert.isNotNull(token, 'token');

    let parts: string[] = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    let decoded: string = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    let result: T = JSON.parse(decoded);
    return result;
  }

  public getClientTimeShift(tokenIssuedTime: Date): number {
    Assert.isNotNull(tokenIssuedTime, 'tokenIssuedTime');
    let diff: number = 0;
    diff = moment().diff(tokenIssuedTime, 'seconds');
    return diff;
  }

  public getTokenIssuedDate(token: string, issuedTimePropertyName?: string): Date {
    Assert.isNotNull(token, 'token');
    issuedTimePropertyName = issuedTimePropertyName || this.defaultIssuedPropertyName;

    let decoded: any = this.decodeToken<any>(token);

    if (!decoded.hasOwnProperty(issuedTimePropertyName)) {
      return undefined;
    }

    let issuedDate: Date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    let expirationValue: number = decoded[issuedTimePropertyName];
    issuedDate.setUTCSeconds(expirationValue);

    return issuedDate;
  }

  public getTokenExpirationDate(token: string, expirationPropertyName?: string, cTimeOffset: number = 0): Date {
    Assert.isNotNull(token, 'token');
    const expPropertyName = expirationPropertyName || this.defaultExpirationPropertyName;
    const clientTimeOffset = +cTimeOffset;

    let decoded: any = this.decodeToken<any>(token);
    let expirationValue: number = +decoded[expPropertyName];

    if (!decoded.hasOwnProperty(expPropertyName) || isNaN(expirationValue)) {
      return undefined;
    }

    let expirationDate: Date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    expirationValue += clientTimeOffset;
    expirationDate.setUTCSeconds(expirationValue);

    return expirationDate;
  }

  public isTokenExpired(token: string, clientTimeShift: number = 0): boolean {

    Assert.isNotNull(token, 'token');
    let expirationDate: Date = this.getTokenExpirationDate(token, undefined, clientTimeShift);

    if (expirationDate === undefined) {
      return false;
    }

    let isExpired: boolean = moment(expirationDate).isBefore();
    return isExpired;
  }

  private urlBase64Decode(value: string): string {
    Assert.isNotNull(value, 'value');

    let output: string = value.replace(/-/g, '+').replace(/_/g, '/');

    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw new Error('Illegal base64url string!');
      }
    }

    let result: string = this.decodeBase64(output);
    return result;
  }


  private decodeBase64(value: string): string {
    Assert.isNotNull(value, 'value');

    // polifyll https://github.com/davidchambers/Base64.js
    let result: string = decodeURIComponent(window.escape(window.atob(value))); //Base64.decode(value);

    return result;
  }
}

