<div class="modal-content {{confirmOptions?.className}}">
  <div class="modal-body">
    <div class="bootbox-body">{{options?.message}}</div>
    <span *ngIf="confirmOptions.isShiftGroup"><a (click)="getRouterLink()">Click Here </a></span>
    <div class="space-top" *ngIf="confirmOptions?.notes">
      <span>{{confirmOptions?.notes}}</span>
    </div>
  </div>
  <div class="modal-footer">
    <button *ngIf="confirmOptions.showCancel" type="button" (click)="onCancel()" class="theme-button-cancel margin-r">{{ cancelButtonText }}</button>
    <button *ngIf="confirmOptions.showOK" type="button" (click)="onOk()" class="theme-button-apply">{{ okButtonText }}</button>
  </div>
</div>
