import * as _ from 'lodash';
var ScheduleConsoleEntry = /** @class */ (function () {
    function ScheduleConsoleEntry(name) {
        this.name = name;
        this.values = [];
        this.hidden = false;
        this.expanded = false;
    }
    return ScheduleConsoleEntry;
}());
export { ScheduleConsoleEntry };
var ScheduleConsoleCycles = /** @class */ (function () {
    function ScheduleConsoleCycles() {
    }
    return ScheduleConsoleCycles;
}());
export { ScheduleConsoleCycles };
var ScheduleConsoleFilterItem = /** @class */ (function () {
    function ScheduleConsoleFilterItem() {
    }
    return ScheduleConsoleFilterItem;
}());
export { ScheduleConsoleFilterItem };
var ScheduleConsoleFilterChangedEvent = /** @class */ (function () {
    function ScheduleConsoleFilterChangedEvent() {
    }
    return ScheduleConsoleFilterChangedEvent;
}());
export { ScheduleConsoleFilterChangedEvent };
var ConsoleConfig = /** @class */ (function () {
    function ConsoleConfig(displayType, lowerLimit, upperLimit) {
        this.displayType = displayType;
        this.lowerLimit = lowerLimit;
        this.upperLimit = upperLimit;
    }
    return ConsoleConfig;
}());
export { ConsoleConfig };
export var ScheduleConsoleGroupByType;
(function (ScheduleConsoleGroupByType) {
    ScheduleConsoleGroupByType["NONE"] = "No Grouping";
    ScheduleConsoleGroupByType["UNIT"] = "Group by Unit";
    ScheduleConsoleGroupByType["SHIFT"] = "Group by Shift Group";
})(ScheduleConsoleGroupByType || (ScheduleConsoleGroupByType = {}));
var ScheduleConsoleGroupBy = /** @class */ (function () {
    function ScheduleConsoleGroupBy(id, name) {
        this.id = id;
        this.name = name;
    }
    Object.defineProperty(ScheduleConsoleGroupBy.prototype, "longTypeName", {
        get: function () {
            return "groupBy" + _.capitalize(this.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleGroupBy.prototype, "shortTypeName", {
        get: function () {
            return _.lowerCase(this.id);
        },
        enumerable: true,
        configurable: true
    });
    return ScheduleConsoleGroupBy;
}());
export { ScheduleConsoleGroupBy };
var ScheduleConsoleViewItem = /** @class */ (function () {
    function ScheduleConsoleViewItem(actualText, limitText, viewMode, scheduled) {
        this.actualText = actualText;
        this.limitText = limitText;
        this.viewMode = viewMode;
        this.scheduled = scheduled;
    }
    return ScheduleConsoleViewItem;
}());
export { ScheduleConsoleViewItem };
var ScheduleConsoleDayIndicator = /** @class */ (function () {
    function ScheduleConsoleDayIndicator(color, date) {
        this.color = color;
        this.date = date;
    }
    return ScheduleConsoleDayIndicator;
}());
export { ScheduleConsoleDayIndicator };
