import { ShiftSwapStatus } from './shift-swap-status';
var ShiftSwapInfo = /** @class */ (function () {
    function ShiftSwapInfo() {
    }
    Object.defineProperty(ShiftSwapInfo.prototype, "hasSubmittedTo", {
        get: function () {
            return this.submittedTo.id > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftSwapInfo.prototype, "canAction", {
        get: function () {
            return this.hasSubmittedTo
                && this.canApprove
                && (this.status === ShiftSwapStatus.Accepted
                    || this.status === ShiftSwapStatus.Pending);
        },
        enumerable: true,
        configurable: true
    });
    return ShiftSwapInfo;
}());
export { ShiftSwapInfo };
var ShiftSwapProfile = /** @class */ (function () {
    function ShiftSwapProfile() {
    }
    return ShiftSwapProfile;
}());
export { ShiftSwapProfile };
