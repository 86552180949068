import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { appConfig } from '../../../app.config';
import { PayCycle } from '../../models/index';
var Year = /** @class */ (function () {
    function Year(year) {
        this.year = year;
    }
    return Year;
}());
export { Year };
var Range = /** @class */ (function () {
    function Range(range, year, payCycle) {
        this.range = range;
        this.year = year;
        this.payCycle = payCycle;
    }
    return Range;
}());
export { Range };
var CustomPayCycleDropdownComponent = /** @class */ (function () {
    function CustomPayCycleDropdownComponent() {
        this.appConfig = appConfig;
        this.payCycleSelected = new EventEmitter();
        this.payCyclesLoaded = new EventEmitter();
        this.defaultRange = new Range('Please select Pay Cycle', 0, null);
        this.isSetDefaultPayCycle = true;
    }
    Object.defineProperty(CustomPayCycleDropdownComponent.prototype, "setPayCycle", {
        set: function (val) {
            if (_.isObject(val) && _.isDate(val.startDate) && _.isDate(val.endDate)) {
                this.selectedPayCycle = val;
                this.selectPayCycle();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomPayCycleDropdownComponent.prototype, "setDefault", {
        set: function (val) {
            if (_.isBoolean(val)) {
                this.isSetDefaultPayCycle = val;
                if (val) {
                    this.selectPayCycle();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomPayCycleDropdownComponent.prototype, "payCycles", {
        set: function (value) {
            this.setPayCycles(value);
        },
        enumerable: true,
        configurable: true
    });
    CustomPayCycleDropdownComponent.prototype.ngOnInit = function () {
    };
    CustomPayCycleDropdownComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    Object.defineProperty(CustomPayCycleDropdownComponent.prototype, "currentYear", {
        get: function () {
            return this.m_year;
        },
        set: function (year) {
            if (_.get(this.m_year, 'year') !== _.get(year, 'year')) {
                this.setupPayCycle(year, this.currentRange);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomPayCycleDropdownComponent.prototype, "currentRange", {
        get: function () {
            return this.m_range;
        },
        set: function (range) {
            if (_.get(this.m_range, 'range') !== _.get(range, 'range')) {
                this.m_range = range;
                if (_.isDate(_.get(range, 'payCycle.startDate')) && _.isDate(_.get(range, 'payCycle.endDate'))) {
                    this.payCycleSelected.emit(range.payCycle);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    CustomPayCycleDropdownComponent.prototype.setPayCycles = function (cycles) {
        this.m_range = null;
        this.m_year = null;
        this.yearList = null;
        this.origPayCycles = _.sortBy(cycles, function (item) { return moment(item.startDate).unix(); });
        this.payCyclesLoaded.emit(cycles);
        this.createYearsList();
        this.createRangesList();
        this.selectPayCycle();
    };
    CustomPayCycleDropdownComponent.prototype.createYearsList = function () {
        var yearsList = _.reduce(this.origPayCycles, function (accum, cycle) {
            var year = moment(cycle.startDate).year();
            accum[year] = new Year(year);
            return accum;
        }, {});
        this.yearList = _.reverse(_.values(yearsList));
    };
    CustomPayCycleDropdownComponent.prototype.createRangesList = function () {
        var rangeList = _.reduce(this.origPayCycles, function (accum, cycle) {
            accum[cycle.description] = new Range(cycle.description, moment(cycle.startDate).year(), cycle);
            return accum;
        }, {});
        this.origRangeList = _.reverse(_.values(rangeList));
    };
    CustomPayCycleDropdownComponent.prototype.filteringRanges = function (year) {
        return _.filter(this.origRangeList, function (range) { return year.year === range.year; });
    };
    CustomPayCycleDropdownComponent.prototype.selectPayCycle = function () {
        if (!_.isArray(this.yearList) || !_.isArray(this.origRangeList))
            return;
        var sDate = _.get(this.selectedPayCycle, 'startDate');
        var eDate = _.get(this.selectedPayCycle, 'endDate');
        if (_.isDate(sDate) && _.isDate(eDate)) {
            var startDate_1 = moment(sDate);
            var endDate_1 = moment(eDate);
            var year = _.find(this.yearList, { year: sDate.getFullYear() });
            // if (!year) {
            //   return;
            // }
            var ranges = this.filteringRanges(year);
            var currentRange = _.find(ranges, function (r) { return startDate_1.isSame(r.payCycle.startDate) && endDate_1.isSame(r.payCycle.endDate); });
            this.setupPayCycle(year, currentRange);
        }
        else if (this.isSetDefaultPayCycle) {
            var currentYear = _.head(this.yearList);
            this.setupPayCycle(currentYear, null);
        }
    };
    CustomPayCycleDropdownComponent.prototype.setupPayCycle = function (year, currentRange) {
        if (_.get(this.m_year, 'year') !== _.get(year, 'year')) {
            this.m_year = year;
            var ranges = this.filteringRanges(year);
            this.rangeList = ranges;
            var setFirstRange = _.isNil(currentRange);
            if (setFirstRange) {
                this.currentRange = _.first(this.rangeList);
            }
            else {
                this.currentRange = currentRange;
            }
        }
    };
    return CustomPayCycleDropdownComponent;
}());
export { CustomPayCycleDropdownComponent };
