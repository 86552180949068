<slx-spinner [show]="state.isLoading">
  <div class="punch-logs-container">
    <div #punchesHeader class="punch-logs-header">
      <div class="left-buttons">
        <div class="employee-name">{{employeeName}}</div>
      </div>
      <div class="middle-buttons">
          <slx-input-decorator #datePagerContainer className="slx-form-group-white">
              <slx-date-picker-ngx slx-input slxDatePager [targetContainer]="datePagerContainer" [(ngModel)]="date"
                (ngModelChange)="onDateChange()" [acceptNullDate]="false" placeholder="Date" name="logicalDate"></slx-date-picker-ngx>
              <span errorMessage for="date"></span>
            </slx-input-decorator>
      </div>
      <div class="right-buttons">
          <button type="button" (click)="onExport()" class="theme-button-apply">Export</button>
      </div>
    </div>
    <div class="grid-container">
      <slx-punch-logs-grid ></slx-punch-logs-grid>
    </div>
  </div>
</slx-spinner>
