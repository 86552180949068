import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { Router, ActivatedRoute } from '@angular/router';
import { appConfig } from '../../../app.config';
import { unsubscribe } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { LeaveRequestActionPayload } from '../../models/index';
import { LeaveRequestApiService } from '../../services/index';
import { UserService } from '../../../core/services/index';
import { Actions } from '../../../core/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { IndividualScheduleNavigationService } from './../../../common/services/index';
import { LeaveRequestConfirmComponent } from '../leave-request-confirm/leave-request-confirm.component';
import { ScheduleCycleHelperService } from '../../../organization/services/index';
import { AccrualBalanceDialogOptions } from '../../../organization/models/index';
import { AccrualBalanceDialogComponent } from '../../../organization/components/index';
import { GridComponent } from '@progress/kendo-angular-grid';
export var ConfirmActions;
(function (ConfirmActions) {
    ConfirmActions["approve"] = "approve";
    ConfirmActions["deny"] = "deny";
    ConfirmActions["submit"] = "submit";
})(ConfirmActions || (ConfirmActions = {}));
var LeaveRequestGridComponent = /** @class */ (function () {
    function LeaveRequestGridComponent(leaveRequestApiService, scheduleCycleHelperService, appSettingsManageService, modalService, userService, router, route) {
        var _a;
        var _this = this;
        this.leaveRequestApiService = leaveRequestApiService;
        this.scheduleCycleHelperService = scheduleCycleHelperService;
        this.appSettingsManageService = appSettingsManageService;
        this.modalService = modalService;
        this.userService = userService;
        this.router = router;
        this.route = route;
        this.xlsxName = 'Leave_Requests_';
        this.pdfName = 'Leave_Requests_';
        this.subscriptions = {};
        this.subscriptions.export = this.leaveRequestApiService.subscribeToExport(function (isPDF) {
            _this.exportTo(isPDF);
        });
        this.appConfig = appConfig;
        this.navService = new IndividualScheduleNavigationService(this.router, this.route);
        this.onLoading = new EventEmitter();
        this.actionsDefinition = (_a = {},
            _a[ConfirmActions.approve] = ConfirmActions.approve,
            _a[ConfirmActions.deny] = ConfirmActions.deny,
            _a[ConfirmActions.submit] = ConfirmActions.submit,
            _a);
        this.pageSize = 20;
        this.detailsEditMode = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];
        this.currentFilters = [];
    }
    Object.defineProperty(LeaveRequestGridComponent.prototype, "incomingLeaveRequests", {
        set: function (value) {
            if (_.isArray(value)) {
                this.detailsEditMode = false;
                this.leaveRequests = value.slice(0);
                this.applyFiltering();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaveRequestGridComponent.prototype, "filters", {
        set: function (value) {
            if (_.isArray(value)) {
                this.currentFilters = value;
                this.applyFiltering();
            }
        },
        enumerable: true,
        configurable: true
    });
    LeaveRequestGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridSubscription = this.gridState.onRefreshGrid.subscribe(function () { return _this.refreshGrid(); });
        this.appSettingsManageService.getAppServerConfig().then(function (conf) {
            _this.userCanApproveOwnLeaveRequests = conf.userPermissions.canApproveOwnESSRequest;
        });
        var date = new Date().toLocaleDateString();
        this.xlsxName += date + ".xlsx";
        this.pdfName += date + ".pdf";
    };
    LeaveRequestGridComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    Object.defineProperty(LeaveRequestGridComponent.prototype, "dateFormat", {
        get: function () {
            return '{0:MM/dd/yyyy}';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaveRequestGridComponent.prototype, "dateWithTimeFormat", {
        get: function () {
            return '{0:MM/dd/yyyy HH:mm a}';
        },
        enumerable: true,
        configurable: true
    });
    LeaveRequestGridComponent.prototype.getIconClass = function (item) {
        var cssClass = '';
        switch (item.status) {
            case 'Approved':
                cssClass = 'green';
                break;
            case 'Denied':
                cssClass = 'red';
                break;
            case 'Pending Approval':
                cssClass = 'yellow';
                break;
            case 'Pending Replacement':
                cssClass = 'orange';
                break;
        }
        return cssClass;
    };
    LeaveRequestGridComponent.prototype.canMakeAction = function (item) {
        return (item.status === 'Pending Approval' || item.status === 'Pending Replacement' || item.status === 'Submit for Review');
    };
    LeaveRequestGridComponent.prototype.canApprove = function (item) {
        return this.canMakeAction(item) && this.canApproveLeaveRequest(item) && this.canApproveMyOwnLeaveRequests(item);
    };
    LeaveRequestGridComponent.prototype.canApproveLeaveRequest = function (item) {
        return item.detailsLength > 0;
    };
    LeaveRequestGridComponent.prototype.canApproveMyOwnLeaveRequests = function (item) {
        if (this.userService.isEmployeeLinkedToStoredUser(item.employee.id)) {
            return this.userCanApproveOwnLeaveRequests;
        }
        return true;
    };
    LeaveRequestGridComponent.prototype.approveRequest = function (item, payload) {
        var _this = this;
        this.onLoading.emit(true);
        this.leaveRequestApiService
            .approveLeaveRequest(item.department.orgLevelId, item.id, payload)
            .then(function (value) {
            _this.onLoading.emit(false);
        })
            .catch(function () {
            _this.onLoading.emit(false);
        });
    };
    LeaveRequestGridComponent.prototype.rejectRequest = function (item, payload) {
        var _this = this;
        this.onLoading.emit(true);
        this.leaveRequestApiService
            .denyLeaveRequest(item.department.orgLevelId, item.id, payload)
            .then(function (value) {
            _this.onLoading.emit(false);
        })
            .catch(function () {
            _this.onLoading.emit(false);
        });
    };
    LeaveRequestGridComponent.prototype.submitRequest = function (item, payload) {
        var _this = this;
        this.onLoading.emit(true);
        this.leaveRequestApiService
            .submitForReviewRequest(item.department.orgLevelId, item.id, payload)
            .then(function (value) {
            _this.onLoading.emit(false);
        })
            .catch(function () {
            _this.onLoading.emit(false);
        });
    };
    LeaveRequestGridComponent.prototype.onMakeRequest = function (item, actionType) {
        var payload = new LeaveRequestActionPayload();
        payload.comment = item.comment;
        switch (actionType) {
            case this.actionsDefinition.approve:
                this.approveRequest(item, payload);
                break;
            case this.actionsDefinition.deny:
                this.rejectRequest(item, payload);
                break;
            case this.actionsDefinition.submit:
                this.submitRequest(item, payload);
                break;
        }
    };
    LeaveRequestGridComponent.prototype.onConfirmDialog = function (item, actionType) {
        var _this = this;
        var callback = null;
        switch (actionType) {
            case this.actionsDefinition.approve:
                item.buttonText = 'Approve';
                item.header = 'Approve request';
                break;
            case this.actionsDefinition.deny:
                item.buttonText = 'Deny';
                item.header = 'Deny request';
                break;
            case this.actionsDefinition.submit:
                item.buttonText = 'Submit';
                item.header = 'Submit for review';
                break;
        }
        LeaveRequestConfirmComponent.openDialog(item, this.modalService, function (result) {
            if (result) {
                _this.onMakeRequest(item, actionType);
            }
            item.comment = '';
        });
    };
    LeaveRequestGridComponent.prototype.onForwadTo = function (item) {
        var _this = this;
        this.onLoading.emit(true);
        var date = moment(item.start);
        this.scheduleCycleHelperService
            .getScheduleCycleByDate(date.toDate(), item.department.orgLevelId)
            .then(function (_a) {
            var startDate = _a.startDate, endDate = _a.endDate;
            _this.navService.NavigateToIndividualScheduleEmp(item.employee.id, startDate.toDate(), endDate.toDate(), date.toDate());
        });
    };
    LeaveRequestGridComponent.prototype.onShowAccruals = function (item) {
        var accrualEmployee = new AccrualBalanceDialogOptions(item.employee.name, item.position.name, item.accruals);
        AccrualBalanceDialogComponent.openDialog(accrualEmployee, this.modalService);
    };
    LeaveRequestGridComponent.prototype.onUpdatedDetails = function () {
        this.onLoading.emit(false);
    };
    LeaveRequestGridComponent.prototype.onChangedEditState = function (isEdit) {
        this.detailsEditMode = isEdit;
    };
    LeaveRequestGridComponent.prototype.applyFiltering = function () {
        if (_.isArray(this.leaveRequests) && _.isArray(this.currentFilters)) {
            this.userService.saveUser();
            this.filterRecords();
            this.refreshGrid();
        }
    };
    LeaveRequestGridComponent.prototype.filterRecords = function () {
        if (this.currentFilters.length === 0) {
            this.records = this.leaveRequests.slice(0);
            return;
        }
        var filterNames = _.map(this.currentFilters, function (filter) { return filter.name; });
        this.records = _.filter(this.leaveRequests, function (record) {
            return _.indexOf(filterNames, record.status) !== -1;
        });
    };
    LeaveRequestGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    LeaveRequestGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    LeaveRequestGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () {
            return ({
                data: process(_this.getFilteredRecords(), {
                    sort: _this.gridState.state.sort,
                    filter: _this.gridState.state.filter,
                }).data,
            });
        };
    };
    LeaveRequestGridComponent.prototype.getFilteredRecords = function () {
        var filteredRecords = this.records;
        return filteredRecords;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LeaveRequestGridComponent.prototype, "gridSubscription", void 0);
    return LeaveRequestGridComponent;
}());
export { LeaveRequestGridComponent };
