import { Injectable } from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import * as _ from 'lodash';
import { ManagementBaseService } from '../../../../core/services/index';
import { PayrollPayCodesApiService } from "./payroll-pay-codes-api.service";
import { ChangeManagementService } from '../../../../common/index';
import { PayrollPayCode, PayrollPayCodeModel } from "../../../../../app/configuration/models/payroll-pay-codes/payroll-pay-code";
import { NotificationsService } from "../../../../../app/core/components";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";

@Injectable()
export class PayrollPayCodesManagementService extends ManagementBaseService<PayrollPayCodeModel, any> {
    public isLoading: boolean;
    public list: PayrollPayCode[];
    public gridData: GridDataResult;
    public payNumbers: any[];
    public enableBulkSaveBtn: boolean = true;
    public sort: SortDescriptor[] = [{
        field: "payrollPaycode",
        dir: "asc",
    }];
    
    constructor(
        private apiService: PayrollPayCodesApiService,
        private changeService: ChangeManagementService,
        protected notificationService: NotificationsService
    ) {
        super();
    }

    public init(): void {
        this.loadData();
        this.loadPayNumbers();
    }

    public loadData(): void {
        this.isLoading = true;
        this.apiService.getPayrollPayCodes().then((value: PayrollPayCode[]) => {
            this.list = value;
            this.clearDirtyRecords();
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public loadPayNumbers(): void {
        this.isLoading = true;
        this.apiService.getPayNumbers().then((value: PayrollPayCodeModel) => {
            this.payNumbers = value.data;
        }).finally(() => {
            this.isLoading = false;
        });
    }

    public findDirtyRecords(){
        if(this.list.filter( e => e.isDirty).length > 0){
            this.enableBulkSaveBtn = false;
            this.markAsDirty();
        }
        else{
            this.enableBulkSaveBtn = true;
            this.clearChanges();
        }
    }

    public clearDirtyRecords(){
        if(this.list.filter( e => e.isDirty).length > 0){
            this.list.forEach( e => e.isDirty = false);
        }
        this.enableBulkSaveBtn = true;
        this.refreshGrid();
        this.clearChanges();
    }

    public doBulkSave(): void {
        this.isLoading = true;
        let dirtyRecords: PayrollPayCode[] = [];
        _.each(this.list, (model: PayrollPayCode) => {
            if (model.isDirty) {
                dirtyRecords.push(model);
            }
            model.isDirty = false;
        });

        if (dirtyRecords.length > 0) {
            this.apiService.save(dirtyRecords)
                .then(() => {
                    this.notificationService.success(`Pay numbers saved successfully`);
                })
                .finally(() => {
                    this.isLoading = false;
                    this.loadData();
                });

        }
        else{ this.isLoading = false; }
    }

    public setItemDirty(item: PayrollPayCode): void {
        item.isDirty = true;
    }

    public markAsDirty(): void {
        this.changeService.changeNotify();
    }

    public clearChanges(): void {
        this.changeService.clearChanges();
    }

    private refreshGrid(): void {
        if (!this.list) {
            this.gridData = null;
            return;
        }
        let sortedRecords: PayrollPayCode[] = orderBy(this.list, this.sort);
        this.gridData = {
            data: sortedRecords,
            total: sortedRecords.length
        };
    }
}