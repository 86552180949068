import * as tslib_1 from "tslib";
import { ValuePairWidgetConfig } from '../../../common/models/value-pair-widget/value-pair-widget-config';
import { PairColorScheme } from '../../../common/models/pair-color-scheme/pair-color-scheme';
var gray = '#dadada';
var red = '#dd3a13';
var green = '#bcd885';
var blue = '#2574b6';
var orange = '#ff9900';
var dullerYellow = '#f0d360';
var colorOverDefault = new PairColorScheme();
colorOverDefault.fontColor1 = red;
colorOverDefault.backgroundColor1 = gray;
colorOverDefault.fontColor2 = gray;
colorOverDefault.backgroundColor2 = red;
var colorUnderDefault = new PairColorScheme();
colorUnderDefault.fontColor1 = blue;
colorUnderDefault.backgroundColor1 = blue;
colorUnderDefault.fontColor2 = gray;
colorUnderDefault.backgroundColor2 = gray;
var colorMatchedDefault = new PairColorScheme();
colorMatchedDefault.fontColor1 = green;
colorMatchedDefault.backgroundColor1 = green;
colorMatchedDefault.fontColor2 = gray;
colorMatchedDefault.backgroundColor2 = green;
var colorOverWithinLimitsDefault = new PairColorScheme();
colorOverWithinLimitsDefault.fontColor1 = dullerYellow;
colorOverWithinLimitsDefault.backgroundColor1 = dullerYellow;
colorOverWithinLimitsDefault.fontColor2 = gray;
colorOverWithinLimitsDefault.backgroundColor2 = orange;
var colorUnderWithinLimitsDefault = new PairColorScheme();
colorUnderWithinLimitsDefault.fontColor1 = orange;
colorUnderWithinLimitsDefault.backgroundColor1 = dullerYellow;
colorUnderWithinLimitsDefault.fontColor2 = gray;
colorUnderWithinLimitsDefault.backgroundColor2 = orange;
var SoValuePairWidgetConfig = /** @class */ (function (_super) {
    tslib_1.__extends(SoValuePairWidgetConfig, _super);
    function SoValuePairWidgetConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.colorOver = colorOverDefault;
        _this.colorUnder = colorUnderDefault;
        _this.colorMatched = colorMatchedDefault;
        _this.colorOverWithinLimits = colorOverWithinLimitsDefault;
        _this.colorUnderWithinLimits = colorUnderWithinLimitsDefault;
        return _this;
    }
    return SoValuePairWidgetConfig;
}(ValuePairWidgetConfig));
export { SoValuePairWidgetConfig };
