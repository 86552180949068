import * as moment from 'moment';
import { AcaMeasurementActions } from './aca-measurement-actions';
import { AcaMeasurementInsights, IAcaMeasurementInsights, AcaInsightField, IAcaInsightField } from './aca-measurement-insights';

export interface IAcaMeasurementRecord {
  empId: number;
  empName: string;
  organizationName: string;
  departmentName: string;
  positionName: string;
  dateHired: string;
  dateTerminated: string;
  empType: string;
  periodTypes: IACAPeriodType[];
  currentMP: IAcaInsightField;
  currentAP: IAcaInsightField;
  nextMP: IAcaInsightField;
  nextAP: IAcaInsightField;
}

export class AcaMeasurementRecord {
  public empId: number;
  public empName: string;
  public organizationName: string;
  public departmentName: string;
  public positionName: string;
  public dateHired: string;
  public dateTerm: string;
  public empType: string;

  public periodTypes: ACAPeriodType[];

  public currentMP: AcaInsightField;
  public currentAP: AcaInsightField;
  public nextMP: AcaInsightField;
  public nextAP: AcaInsightField;
  public expand: boolean = false;
}

export interface IACAPeriodType {
  periodType: string;
  acaPeriod: IACAPeriod[];
  isPrevYearExist: boolean;
  isNextYearExist: boolean;

  measurementPeriodStart: string;
  measurementPeriodEnd: string;
  administrativePeriodStart: string;
  administrativePeriodEnd: string;
  stabilityPeriodStart: string;
  stabilityPeriodEnd: string;
  acaDate: string;
  acaType: string;
  acaExclude: string;
  declinedBenefit: string;
  purchasedMarketplaceBenefit: string;
  benefitName: string;
  benefitStartDate: string;

  weeklyThreshold: number;
  weeklyAverage: number;
  weeklyTotalHours: number;
}

export class ACAPeriodType {
  public periodType: string;
  public periodTypeFullName: string;
  public acaPeriod: ACAPeriod[];
  public isPrevYearExist: boolean;
  public isNextYearExist: boolean;

  public measurementPeriodStart: string;
  public measurementPeriodEnd: string;
  public administrativePeriodStart: string;
  public administrativePeriodEnd: string;
  public stabilityPeriodStart: string;
  public stabilityPeriodEnd: string;
  public acaDate: string;
  public acaType: string;
  public acaExclude: string;
  public benefitDeclined: string;
  public purchasedMarketplace: string;
  public benefitName: string;
  public benefitStart: string;
}

export interface IACAPeriod {
  periodName: string;
  periodSortName: string;
  startFrom: string;
  endTo: string;
  colorCode: string;
  totalAvgHours: number;
  weeklyAvgHours: number;
  empAvgHours: number;
}

export class ACAPeriod {
  public periodName: string;
  public periodSortName: string;
  public startFrom: Date;
  public endTo: Date;
  public currentSelectedYear: number;
  public totalAvgHours: number;
  public weeklyAvgHours: number;
  public empAvgHours: number;
  public get widthPercentage(): number {

    let startDate = moment(this.startFrom).format('YYYY-MM-DD');
    let endDate = moment(this.endTo).format('YYYY-MM-DD');

    if (this.startFrom && this.endTo) {
      if (moment(this.startFrom).toDate().getFullYear() < this.currentSelectedYear) {
        startDate = (this.currentSelectedYear + '-01-01');
      }
      if (moment(this.endTo).toDate().getFullYear() > this.currentSelectedYear) {
        endDate = (this.currentSelectedYear + '-12-31');
      }

    }
    let daysInYear = moment(this.currentSelectedYear + "-02", "YYYY-MM").daysInMonth() == 29 ? 366 : 365;
    let width: any = this.startFrom && this.endTo ? (100 / daysInYear) * (moment(endDate).diff(startDate, 'days', true) + (1)) : 0;
    return width;
  }

  public get mspWidthPercentage(): number {
    return this.totalAvgHours && this.weeklyAvgHours && this.empAvgHours ? (Math.round((100 / this.totalAvgHours) * this.empAvgHours)) : 0;
  }

  public get colorCode(): string {
    return this.periodSortName ? (this.periodSortName == 'MP' ? '#0092CB' : (this.periodSortName == 'SP' ? '#8FAD15' : '#F68D2E')) : 'transparent';
  }

}

export interface IAcaMeasurement {
  records: IAcaMeasurementRecord[];
  insightSummary: IAcaMeasurementInsights;
}

export class AcaMeasurement {
  public records: AcaMeasurementRecord[];
  public actions: AcaMeasurementActions;
  public insightSummary: AcaMeasurementInsights;
}

export class PeriodsPopupModel {
  public isVisible: boolean;
  public posLeft: number;
  public posTop: number;
  public acaPeriod: ACAPeriod;
}

export class filterAcaMeasurementRecord {
  isCurrentMonth: boolean
  isMP: boolean
  type: string
  isClicked: boolean;
}

export class AcaMeasurementRecordExport {
  public empId: number;
  public empName: string;
  public organizationName: string;
  public departmentName: string;
  public positionName: string;
  public dateHired: string;
  public dateTerm: string;
  public empType: string;
  public periodType: string;
  public measurementPeriodStart: string;
  public measurementPeriodEnd: string;
  public weeklyAvgHours: string;
  public administrativePeriodStart: string;
  public administrativePeriodEnd: string;
  public stabilityPeriodStart: string;
  public stabilityPeriodEnd: string;
  public acaDate: string;
  public acaType: string;
  public acaExclude: string;
  public benefitDeclined: string;
  public purchasedMarketplace: string;
  public benefitName: string;
  public benefitStart: string;
}
