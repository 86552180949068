import { StreamInvocationMessage } from '@microsoft/signalr';
  
  export interface IPunchAttestationGroupPermissions {
    isSlate: boolean;
    isLegacy: boolean;
    configurePunchAttestation: boolean;
    configurePunchProfile : boolean;
  }
  
  export class PunchAttestationGroupPermissions {
    public isSlate: boolean;
    public isLegacy: boolean;
    public configurePunchAttestation: boolean;
    public configurePunchProfile : boolean;
  }
  