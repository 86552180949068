import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { TimecardsNavigationService } from '../../services/index';

@Directive({
  /* tslint:disable */
  selector: '[individualTimecardsLink]',
  /* tslint:enable */
})
export class IndividualTimecardsLinkDirective {
  @Input()
  public individualTimecardsLink: number;
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  @Input()
  public hideOrgLevelBreadcrumb: boolean;

  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: TimecardsNavigationService = new TimecardsNavigationService(this.router, this.route);
    navService.NavigateToIndividualTimecards(this.individualTimecardsLink, this.startDate, this.endDate, this.hideOrgLevelBreadcrumb);
  }
}
