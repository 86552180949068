<div class="model-header">

</div>
<div class="modal-body">
  <slx-spinner [show]="isLoading">
    <form class="form-horizontal" #correctionForm="ngForm">
      <div class="main-pad" *ngIf="acaExportCorrectionParameter">
        <div class="header-text">Certification of Eligibility (Select all that apply)</div>
        <div class="flex rb-label">
          <div class="marl5">
            <slx-checkbox-button type="checkbox" class="rb-CheckBox slx-no-indents" name="qualifiedOfferMethod"
                   id="qualifiedOfferMethod"
                   (change)="onChangeQualifiedOffer(acaExportCorrectionParameter,$event.target.checked)"
                   [(ngModel)]="acaExportCorrectionParameter.qualifiedOfferMethod" ></slx-checkbox-button>
          </div>
          <div class="rb-width marl5">Qualified Offer Method</div>

          <div class="marl5">
            <slx-checkbox-button type="checkbox" class="rb-CheckBox" name="offerMethod98"
                   id="offerMethod98"
                   (change)="onChange98PercentageOffer(acaExportCorrectionParameter,$event.target.checked)"
                   [(ngModel)]="acaExportCorrectionParameter.offerMethod98" ></slx-checkbox-button>
          </div>
          <div class="rb-width marl5">98% Offer Method</div>
        </div>
        <div id="multisection">
          <div class="header-text">Did you offer MEC to at least 95% of your full-time employers and their dependents? If so what Months?</div>
          <div class="flex">
            <div class="rb-label rb-width marl5">
              <slx-checkbox-button type="checkbox" class="rb-CheckBox" name="offerMecAll12Month"
                     id="offerMecAll12Month"
                     (change)="onChangeOfferMecAll12Month(acaExportCorrectionParameter,$event.target.checked)"
                     [(ngModel)]="acaExportCorrectionParameter.offeredMecAll12Month" ></slx-checkbox-button>
              All 12 Months
            </div>
            <div class="flex month-section">
              <div class="rb-label rb-width-month" *ngFor="let item of acaExportCorrectionParameter?.offeredMec; let idx=index">
                <div class="marl5">
                  <slx-checkbox-button type="checkbox" class="rb-CheckBox" name="offerMecMonth{{idx}}"
                         id="offerMecMonth{{idx}}"
                         (change)="onChangeOfferedMec(item, $event.target.checked)"
                         [(ngModel)]="item.isSelected"  [readonly]="acaExportCorrectionParameter.offeredMecAll12Month" ></slx-checkbox-button>                         
                  {{item.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="header-text">What months were you a member of an aggregated ALE Group? <span class="astric"> * </span></div>
        <div class="flex rb-width-full">
          <div class="rb-label rb-width marl5">
            <slx-checkbox-button type="checkbox" class="rb-CheckBox" name="aleGroupAll12Month"
                   id="aleGroupAll12Month"
                   (change)="onChangeAleGroupAll12Month(acaExportCorrectionParameter,$event.target.checked)"
                   [(ngModel)]="acaExportCorrectionParameter.memberOfAleGroupAll12Month"> </slx-checkbox-button>
            All 12 Months
          </div>
          <div class="flex month-section">
            <div class="rb-label rb-width-month" *ngFor="let acaitem of acaExportCorrectionParameter?.memberOfAleGroup; let idx=index">
              <div class="marl5">
                <slx-checkbox-button type="checkbox" class="rb-CheckBox" name="aleGroupMonth{{acaitem.id}}"
                       id="aleGroupMonth{{acaitem.id}}"
                       (change)="onChangeAleGroup(acaitem, $event.target.checked)"
                       [(ngModel)]="acaitem.isSelected"  [readonly]="acaExportCorrectionParameter.memberOfAleGroupAll12Month"></slx-checkbox-button>
                {{acaitem.name}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  </slx-spinner>
</div>
<div class="modal-footer">
  <button type="button" (click)="onBack()" class="btn grey"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button>
  <button type="button" class="btn green" (click)="onNext()"><i class="fa fa fa-arrow-right slx-button__icon" aria-hidden="true"></i> Next</button>
</div>

