import { Directive, Input, ElementRef, Renderer, HostListener, OnInit, Host } from '@angular/core';

@Directive({
  selector: '[kendoGridHeightResizeOffsetWindow]',
})
export class KendoGridHeightResizeOffsetWindowDirective implements OnInit {
  @Input('kendoGridHeightResizeOffsetWindow')
  public offset: number;

  private gridHeight: number;
  private elRef: ElementRef;
  private renderer: Renderer;

  constructor(elRef: ElementRef, renderer: Renderer) {
    this.elRef = elRef;
    this.renderer = renderer;
  }

  public ngOnInit(): void {
    this.calcHeight();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: UIEvent): void {
    this.calcHeight();
  }

  protected calcHeight(): void {
    this.gridHeight = window.innerHeight - this.offset;
    this.renderer.setElementStyle(this.elRef.nativeElement, 'maxHeight', `${this.gridHeight}px`);
  }
}
