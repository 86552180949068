import * as tslib_1 from "tslib";
import { Entity } from '../../../core/models/index';
var User = /** @class */ (function (_super) {
    tslib_1.__extends(User, _super);
    function User(id) {
        var _this = _super.call(this, id) || this;
        _this.remidersUnread = 0;
        _this.messagesUnread = 0;
        _this.linkedEmpId = 0;
        _this.isSmartAdmin = false;
        _this.email = '';
        _this.roles = [];
        _this.applications = [];
        return _this;
    }
    return User;
}(Entity));
export { User };
