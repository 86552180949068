/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart-status-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/plus";
import * as i3 from "@angular/common";
import * as i4 from "./chart-status-icon.component";
import * as i5 from "../../../services/value-pair-chart-data/value-pair-chart-data.service";
var styles_ChartStatusIconComponent = [i0.styles];
var RenderType_ChartStatusIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartStatusIconComponent, data: {} });
export { RenderType_ChartStatusIconComponent as RenderType_ChartStatusIconComponent };
function View_ChartStatusIconComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "label-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2), i1.ɵpid(0, i2.PlusPipe, [])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.labelValue, "1.0-2")))); _ck(_v, 1, 0, currVal_0); }); }
function View_ChartStatusIconComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null))], null, null); }
export function View_ChartStatusIconComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgStyleImpl, i3.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgStyle, [i3.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "color": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatusIconComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartStatusIconComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.labelColor); _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.isFull; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.isFull; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_ChartStatusIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-chart-status-icon", [], null, null, null, View_ChartStatusIconComponent_0, RenderType_ChartStatusIconComponent)), i1.ɵdid(1, 114688, null, 0, i4.ChartStatusIconComponent, [i5.ValuePairChartDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartStatusIconComponentNgFactory = i1.ɵccf("slx-chart-status-icon", i4.ChartStatusIconComponent, View_ChartStatusIconComponent_Host_0, {}, {}, []);
export { ChartStatusIconComponentNgFactory as ChartStatusIconComponentNgFactory };
