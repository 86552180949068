import { EmployeePayRateComponent, IEmployeePayRateComponents } from './../../../employee/employee/models/employee-pay-rate-component';

export interface IMassRateAdjustmentsDefenition {
   empId : number;
   empName: string;
   incrementType: string;
   increment : number;
   effectiveDate: string;
   department: string;
   departmentID: number;
   position: string;
   positionID: number;
   union: string;
   hourlyRate: number;
   revisedHourlyRate: number;
   id: number;
   name: string;
   payRateComponents: IEmployeePayRateComponents;
}

export class MassRateAdjustmentsDefenition {
  public empId : number;
  public empName: string;
  public incrementType: string;
  public increment : number;
  public effectiveDate: string;
  public department: string;
  public departmentID: number;
  public position: string;
  public positionID: number;
  public union: string;
  public hourlyRate: number;
  public revisedHourlyRate: number;
  public id: number;
  public name: string;
  public payRateComponents: EmployeePayRateComponent[];
  public Selectable :boolean = false;
  public isSelected :boolean = false;
  
}

export interface IMassRateAdjustmentsDefenitions {
   empMassPayRateRecords : IMassRateAdjustmentsDefenition[];
   isEnableRateComponent :boolean;
   isEnableRate4DecimalPlaces:boolean;
   incrementType: string;
   increment : number;
   effectiveDate: string;
   increaseReason: number;
}

export class MassRateAdjustmentsDefenitions {
   public empMassPayRateRecords : MassRateAdjustmentsDefenition[]= [];
   public isEnableRateComponent :boolean;
   public isEnableRate4DecimalPlaces:boolean;
   public incrementType: string;
   public increment : number;
   public effectiveDate: string;
   public increaseReason: number;
  }

export interface IPayRateDefenition {
   empId : number;
   hourlyRate: number;
   payRateComponents: IEmployeePayRateComponents;
}

export class PayRateDefenition {
   public empId : number;
   public hourlyRate: number;
   public payRateComponents: EmployeePayRateComponent[];
   
}

export interface IPayRateDefenitions {
   empMassPayRateRecords : IPayRateDefenition[];
   isEnableRateComponent :boolean;
   isEnableRate4DecimalPlaces:boolean;

}

export class PayRateDefenitions {
 public  empMassPayRateRecords : PayRateDefenition[] = [];
 public  isEnableRateComponent :boolean;
 public  isEnableRate4DecimalPlaces:boolean;
  
}
