import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { VersionMapService } from './version-map.service';
import { Meta } from '../../../core/models/api/meta';
import { Assert } from '../../../framework/index';
import { ApiUtilService } from '../api/api-util.service';
import { AppVersionInfo, AppVersionInfoDto } from '../../../state-model/models/index';

@Injectable()
export class VersionApiService {
  private versionMapService: VersionMapService;
  private apiUtilService: ApiUtilService;

  constructor(versionMapService: VersionMapService, apiUtilService: ApiUtilService) {
    Assert.isNotNull(versionMapService, 'versionMapService');
    Assert.isNotNull(apiUtilService, 'apiUtilService');
    this.versionMapService = versionMapService;
    this.apiUtilService = apiUtilService;
  }

  public getServerVersion(): Promise<AppVersionInfo> {
    const url: string = `${this.getApiRoot()}/technical/version`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<AppVersionInfoDto, Meta>) =>
        this.versionMapService.mapServerVersion(response.data));
    return promise;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
