import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ScheduleCycle } from '../../models/index';

import { createValuAccessor } from '../../../common/utils/index';
import { ControlValueAccessorBase } from '../../../core/models/index';
import { appConfig } from '../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-cycle-calendar',
  templateUrl: 'schedule-cycle-calendar.component.html',
  styleUrls: ['schedule-cycle-calendar.component.scss'],
  providers: [createValuAccessor(ScheduleCycleCalendarComponent)]
})
export class ScheduleCycleCalendarComponent extends ControlValueAccessorBase {
  @Input()
  public set cycles(value: ScheduleCycle[]) {
    this.m_cycles = value;
    this.m_cycles_start = {};
    this.m_cycles_end = {};
    _.forEach(value, (c: ScheduleCycle) => {
      this.m_cycles_start[moment(c.startDate).startOf('day').format(appConfig.dateFormat)] = c;
      this.m_cycles_end[moment(c.endDate).startOf('day').format(appConfig.dateFormat)] = c;
    });
  }

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  @Input()
  public readonly: boolean;


  private m_cycles: ScheduleCycle[];
  private m_cycles_start: StringMap<ScheduleCycle> = {};
  private m_cycles_end: StringMap<ScheduleCycle> = {};

  private m_currentDate: Date;

  public isCurrent(date: Date): boolean {
    return moment(date).isSame(moment().startOf('day'));
  }

  public isSelected(date: Date): boolean {
    return moment(date).isSame(this.currentDate);
  }

  public isStartOfPayCycle(date: Date): boolean {
    return !!this.m_cycles_start[moment(date).startOf('day').format(appConfig.dateFormat)];
  }
  public isEndOfPayCycle(date: Date): boolean {
    return !!this.m_cycles_end[moment(date).startOf('day').format(appConfig.dateFormat)];
  }

  public get currentDate(): Date {
    return this.m_currentDate;
  }

  public set currentDate(value: Date) {
    if (_.isDate(value) && !moment(this.m_currentDate).isSame(value)) {
      this.m_currentDate = value;
      this.onChangeCallback(this.m_currentDate);
    }
  }

  public writeValue(value: Date): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.m_currentDate = value;
    }
  }
}
