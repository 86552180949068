import { TimecardPredefinedComment } from '../../../organization/models/index';
import { Lookup } from '../../../organization/models/index';

export class TimecardAddCommentsReq {
  public timecardPredefinedComments: Lookup<TimecardPredefinedComment>;
  public employeeId: number;
  public employeeName: string;
  public date: Date;
}

export interface ITimecardAddCommentsResult {
  comment: string;
  isApply: boolean;
}
