import * as moment from 'moment';

export interface IVacationPlanEmployeeWeek {
  start: string;
  days: number;
}

export class VacationPlanEmployeeWeek {
  public start: moment.Moment;
  public days: number;
  public get safeDays(): string {
    return this.days === 0 ? null : this.days.toString();
  }
  public isActive: boolean;
}
