import { TimeclockCommunicationComponent } from './components/timeclock-communication/timeclock-communication.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { components, exportComponents, ChangeTerminationDateDialogComponent } from './components/index';
import { services } from './services/index';
import { directives, exportDirectives } from './directives/index';
//import { pipes, exportPipes } from './pipes/index';
import { CoreModule } from '../../core/index';
import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { MetaMapService } from '../../core/services/index';
import { TextMaskModule } from 'angular2-text-mask';
import { MomentModule } from 'angular2-moment';
import { GridModule } from '@progress/kendo-angular-grid';
import { NgxPopperModule } from 'ngx-popper';
import { DailyTimecardApiService } from './services/employee-ta-log/employee-ta-log-api.service';

import {
  EmployeeCallLogComponent,
  EmployeeCallLogsComponent,
  EmployeeTALogComponent,
  EmployeeTerminateDialogComponent,
  EmployeeTransferDialogComponent,
  EmployeeEditPhotoDialogComponent,
  EmployeeEditablePhotoComponent,
  SendShiftSmsDialogComponent,
  EmployeeRehireDialogComponent
} from './components/index';
import { ACTION_PROVIDERS } from './store/index';
import { EPIC_PROVIDERS, AddEmployeeWizardStateEpics } from './epics/index';
import { rootEpics } from '../../state-model/state-model.module';
import { pipes, exportPipes } from './pipes/index';
import { ComponentsLibraryModule } from '../../components-library/components-library.module';
import { EssTemplatesModule } from '../../app-modules/ess-templates/ess-templates.module';

@NgModule({
  imports: [
    CoreModule,
    CommonModule,
    OrganizationModule,
    CommonAppModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,
    GridModule,
    MomentModule,
    NgxPopperModule,
    ComponentsLibraryModule,
    EssTemplatesModule,
    AgGridModule.withComponents([]),
  ],
  entryComponents: [
    EmployeeCallLogComponent,
    EmployeeCallLogsComponent,
    EmployeeTALogComponent,
    EmployeeTerminateDialogComponent,
    ChangeTerminationDateDialogComponent,
    EmployeeTransferDialogComponent,
    EmployeeEditPhotoDialogComponent,
    SendShiftSmsDialogComponent,
    TimeclockCommunicationComponent,
    EmployeeRehireDialogComponent
  ],
  declarations: [...components, ...directives, ...pipes],
  providers: [...services, MetaMapService, ...ACTION_PROVIDERS, ...EPIC_PROVIDERS,DailyTimecardApiService],
  exports: [
    ...exportPipes,
    ...exportDirectives,
    ...exportComponents
  ]
})
export class EmployeeModule {
  constructor(addEmployeeWizardStateEpics: AddEmployeeWizardStateEpics) {
    rootEpics.push(addEmployeeWizardStateEpics.addEmployee);
    rootEpics.push(addEmployeeWizardStateEpics.addEmployeeFinish);
  }
} 