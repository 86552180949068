import { Subscription } from 'rxjs/Subscription';
import { OrgLevel, OrgLevelType } from './../../../../state-model/models/index';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from './../../../../core/decorators/index';
import { Component, OnInit } from '@angular/core';
import { PunchUtils } from '../../../utils/punch-utils';
@Component({
    moduleId: module.id,
    selector: 'slx-daily-punches-page',
    templateUrl: 'daily-punches-page.component.html',
    styleUrls: ['daily-punches-page.component.scss']
})
export class DailyPunchesPageComponent implements OnInit {
    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;
    public orgLevel: OrgLevel;
    public showRollup: boolean;
    @unsubscribe()
    private orgLevelSubscription: Subscription;

    public ngOnInit(): void {
        this.orgLevelSubscription = this.orgLevel$.subscribe((value: OrgLevel) => {
            this.orgLevel = value;
            this.showRollup = PunchUtils.isRollup (value);
        });
    }
}
