import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../app.config';
import { mutableSelect, unsubscribe, destroyService } from '../../../../../core/decorators/index';
import { Organization, UserAction } from '../../../../../organization/models/index';
import { IRangeDates } from '../../../../../common/models/range-dates';
import { PBJManualTimeEntry } from '../../../models/index';
import { PbjManualEntryManagementService } from '../../../services/pbj-manual-entry/pbj-manual-entry-management.service';
import { PbjManualEntryGridComponent } from '../pbj-manual-entry-grid/pbj-manual-entry-grid.component';
var PbjManualEntryComponent = /** @class */ (function () {
    function PbjManualEntryComponent(managementService) {
        this.maxDateRange = 1 * 60 * 60 * 24 * 100; // max 100 days
        this.maxDateRangeError = 'You cannot specify range more than 100 days';
        this.canExportData = false;
        this.appConfig = appConfig;
        this.managementService = managementService;
        this.state = {
            isLoading: true
        };
        this.pageSize = 20;
        this.canAdd = true;
        this.canRemove = false;
        this.endDate = moment().endOf('day').toDate();
        this.startDate = moment(this.endDate).subtract(7, 'day').toDate();
        this.managementService.dateFilterChanged(this.startDate, this.endDate);
    }
    PbjManualEntryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.managementService.subscribeToUserActionsChanged(function (actions) {
            _this.canExportData = _.some(actions, function (x) { return x.name === 'Export To Excel'; });
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && _.isNumber(orgLevel.id)) {
                _this.orgLevel = orgLevel;
                _this.managementService.orgLevelChanged(_this.orgLevel);
            }
        });
        this.onStatusSubscription = this.managementService.onLoadStatus$.subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.onSelectedSubscription = this.managementService.onEditState$.subscribe(function (isCanEdit) {
            _this.canAdd = isCanEdit;
        });
        this.onSelectedSubscription = this.managementService.onEntriesSelected$.subscribe(function (selectedEntries) {
            _this.selectedEntries = selectedEntries;
            _this.canRemove = _this.selectedEntries.length > 0;
        });
    };
    PbjManualEntryComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PbjManualEntryComponent.prototype.onDataRangeChange = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.selectedDates = { startDate: startDate, endDate: endDate };
        this.startDate = startDate;
        this.endDate = endDate;
        this.managementService.dateFilterChanged(startDate, endDate);
    };
    PbjManualEntryComponent.prototype.onRemoveEntries = function () {
        var selectedEntriesIds = _.map(this.selectedEntries, function (entry) { return entry.id; });
        this.managementService.removeEntries(selectedEntriesIds);
    };
    PbjManualEntryComponent.prototype.onAddEntry = function () {
        var entry = this.managementService.createPBJManualEntry();
        this.managementService.addEntryToRow(entry);
    };
    PbjManualEntryComponent.prototype.onExportToExcel = function () {
        this.managementService.exportToExcel();
    };
    PbjManualEntryComponent.prototype.onExportToPdf = function () {
        this.managementService.exportToPdf();
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PbjManualEntryComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryComponent.prototype, "onStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjManualEntryComponent.prototype, "onSelectedSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PbjManualEntryManagementService)
    ], PbjManualEntryComponent.prototype, "managementService", void 0);
    return PbjManualEntryComponent;
}());
export { PbjManualEntryComponent };
