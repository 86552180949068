import { RoleSection, IRoleSection } from './role-section';
import { RolesAccess } from './role-access-definition';

export interface IRoleProfile {
  sections: IRoleSection[];
}

export class RoleProfile {
  public sections: RoleSection[];
  public access: RolesAccess;
}
