<form novalidate #gridForm="ngForm">
  <div class="worker-comp-section">
    <div class="worker-comp-body">
      <kendo-grid #kendoGrid 
        scrollable="'scrollable'" 
        [data]="gridState?.view" 
        [sortable]="true" [sort]="gridState.state.sort" 
        [skip]="gridState.state.skip" [pageable]="true" [pageSize]="pageSize" 
        [filterable]="true" [filter]="gridState.state.filter"
        [groupable]="false" [selectable]="false"
        [slxKendoGridState]="gridState.gridDefinition" 
        (dataStateChange)="gridState.dataStateChange($event)" 
        (selectionChange)="gridState.selectionChange($event)"
        (stateRestored)="gridState.dataStateChange($event)" 
        (edit)="gridState.editHandler($event)"
        (save)="gridState.saveHandler($event)" 
        (cancel)="gridState.cancelHandler($event)"
        (remove)="gridState.removeHandler($event)"
        (pageChange)="pageChanged($event)"
        >

        <ng-template kendoGridToolbarTemplate position="top">
          <div class="col-xs-12" *ngIf="workersComp?.actions.canEdit && isEditable">
            <div class="row">
              <button class="theme-iconed-accent-button pull-right" (click)="onStartAdd()">
                <span class="icon-button-content">
                  <span>Create New</span>
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span>
              </button>
            </div>
          </div>
        </ng-template>


        <kendo-grid-column field="reportDateTime" title="Date Reported" width="170">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Date Reported
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="edit-link" (click)="onSelectItem(dataItem)">{{ dataItem.reportDateTime!=null ? (dataItem.reportDateTime | amDateFormat: appConfig.dateTimeFormatUS) :paceholderlink}}</span>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="incidentDateTime" title="Incident Date" width="170">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Incident Date
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.incidentDateTime | amDateFormat: appConfig.dateTimeFormatUS }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Incident Type" field="reportFlags">
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-multiselect-filter
              [filter]="filter" [showOperators]="false" [column]="column" [items]="typeFilters" ></slx-kendo-grid-multiselect-filter>
          </ng-template>
          <ng-template kendoGridHeaderTemplate>
            Incident Type
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>Incident</span>
            <span>{{ dataItem.isOSHARecordable ? '/OSHA' : ''}}</span>
            <span>{{ dataItem.isWorkersCompCase ? '/WC' : '' }}</span>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column field="organization.name" title="Organization" width="200">
          <ng-template kendoGridHeaderTemplate>
              Organization
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.organization?.name }}
          </ng-template>
        </kendo-grid-column>

      </kendo-grid>
    </div>
  </div>
</form>