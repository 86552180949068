import { Dictionary } from 'lodash';
import { IPosition, IDepartment, IOrganization, IShiftDiffPolicy,
  IPayPolicy, Position, Department,
  ShiftDiffPolicy, PayPolicy, Organization } from '../../../organization/models/index';
import { ITimecardsEarningPayRule, TimecardsEarningPayRule } from './timecards-earning-pay-rule';

export interface ITimecardsEarning {
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  payRate: number;
  rules: ITimecardsEarningPayRule[];
  shiftCode: number;
  costCenterCode: string;
  shiftCodeName: string;

  //totals
  regularHours: number;
  regularPay: number;
  overtimeAndExtraHours: number;
  overtimeAndExtraPay: number;
  totalOtherPay: number;
  overTimePay: number;
  totalAbsencePay: number;
  productiveHours: number;
  nonProductiveHours: number;
  totalHours: number;
  shiftDifferentialPolicy: IShiftDiffPolicy;
  payPolicy: IPayPolicy;
  totalPay: number;
}

export class TimecardsEarning {
  public organization: Organization;
  public department: Department;
  public position: Position;
  public payRate: number;
  public rules: TimecardsEarningPayRule[];
  public rulesMap: Dictionary<TimecardsEarningPayRule[]>;
  public shiftCode: number;
  public costCenterCode: string;
  public shiftCodeName: string;

  //totals
  public regularHours: number;
  public regularPay: number;
  public overtimeAndExtraHours: number;
  public overtimeAndExtraPay: number;
  public totalOtherPay: number;
  public overTimePay: number;
  public totalAbsencePay: number;
  public productiveHours: number;
  public nonProductiveHours: number;
  public totalHours: number;
  public shiftDiffPolicy: ShiftDiffPolicy;
  public payPolicy: PayPolicy;
  public totalPay: number;
}
