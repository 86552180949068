import { FieldData, FieldsMeta } from '../../../../core/models/index';
import { State } from '../../../../organization/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsContacts {
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  mobile: string;
  alternativePhone: string;
  email1: string;
  email2: string;
  empOptIn: number;
  empOptInBy: string;
  empOptInDate: Date;
  empOptInEmailDate: Date;
  isMessageCenterAccessible: boolean;
}

export class EmployeeSectionsContacts extends EmployeeSectionsBase {
  public address: FieldData<string>;
  public city: FieldData<string>;
  public state: FieldData<State>;
  public zip: FieldData<string>;
  public phone: FieldData<string>;
  public mobile: FieldData<string>;
  public alternativePhone: FieldData<string>;
  public email1: FieldData<string>;
  public email2: FieldData<string>;
  public empOptIn: number;
  public empOptInBy: string;
  public empOptInDate: Date;
  public empOptInEmailDate: Date;
  public isMessageCenterAccessible: boolean;
  public fieldsMeta: FieldsMeta;
}
