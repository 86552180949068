import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToTerminate } from '../../../../models/index';
import { EmployeeTerminateComponent } from '../employee-terminate/employee-terminate.component';
var EmployeeTerminateDialogComponent = /** @class */ (function () {
    function EmployeeTerminateDialogComponent(options, modalService, employee) {
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
        this.employee = employee;
        this.state = {
            isLoading: false,
        };
    }
    EmployeeTerminateDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 580;
        dialogOptions.width = 650;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EmployeeToTerminate,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(EmployeeTerminateDialogComponent, 'Terminate Employee', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.requestToTerminate);
        });
        return dialog;
    };
    Object.defineProperty(EmployeeTerminateDialogComponent.prototype, "requestToTerminate", {
        get: function () {
            return this.employeeTermination.requestToTerminate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTerminateDialogComponent.prototype, "isemployeeTerminationFormValid", {
        get: function () {
            if (this.employeeTermination && this.employeeTermination.ngForm) {
                return this.employeeTermination.ngForm.valid;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTerminateDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeTerminateDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return EmployeeTerminateDialogComponent;
}());
export { EmployeeTerminateDialogComponent };
