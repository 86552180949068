import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';

import { appConfig } from '../../../../../app.config';



import { LmNavigationService } from '../../../../../common/services/navigation/index';

import { LMManagementService } from '../../../services/index';
import { IScreenUtils, screenUtils } from '../../../../../common/utils/index';
import { LoaRequestDropDownItem } from '../../../../../common/models';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-console-toolbar',
  templateUrl: 'lm-console-toolbar.component.html',
  styleUrls: ['lm-console-toolbar.component.scss']
})
export class LMConsoleToolbarComponent implements OnInit, OnDestroy {

  public get canAdd(): boolean {
    return this.managementService.canAddEdit;
  }

  public leaveTypes: LoaRequestDropDownItem[];

  private subscriptions: StringMap<Subscription> = {};
  private hrNavService: LmNavigationService;

  constructor (
    private managementService: LMManagementService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.leaveTypes = managementService.getRequestTypes();
    this.hrNavService = new LmNavigationService(this.router, this.activatedRoute);
  }

  public ngOnInit(): void { }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onCreate(item: LoaRequestDropDownItem): void {
    this.managementService.openCreationDialog(item.id);
  }

  public onClickConsole(): void {
    this.hrNavService.navigateToLmRoster();
  }

  public getLeaveTypeWidth(): number {
    return screenUtils.isMobile ? 30 : 130;
  }
}
