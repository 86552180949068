export class ManageACAPeriods {
    public id: number;
    public newHireFirstOfTheMonth: boolean;
    public newHireAllowOverlap: boolean;
    public newHireMeasurementPeriod: number;
    public newHireAdministrativePeriod: number;
    public newHireStabilityPeriod: number;
    public standardConfigFirstDayOfMeasurement: Date;
    public standardConfigMeasurementPeriod: number;
    public standardConfigAdministrativePeriod: number;
    public standardConfigStabilityPeriod: number;
}

export interface IManageACAPeriodsDTO {
    id: number;
    newHireFirstOfTheMonth: boolean;
    newHireAllowOverlap: boolean;
    newHireMeasurementPeriod: number;
    newHireAdministrativePeriod: number;
    newHireStabilityPeriod: number;
    standardConfigFirstDayOfMeasurement: string;
    standardConfigMeasurementPeriod: number;
    standardConfigAdministrativePeriod: number;
    standardConfigStabilityPeriod: number;
}

export class Employer {
    public id: number;
    public name: string;
}

export interface IEmployer {
    id: number;
    name: string;
}
