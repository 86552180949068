import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { DeviceDetectorService } from '../../../../common/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { StateResetTypes } from '../../../../core/models/index';
import { initialBalancesState } from '../../models/index';
import { AccrualsBalancesManagementService } from '../../services/index';
import { ModalService, DialogOptions } from '../../../../common/index';
import { RecalculateDialogComponent } from '../recalculate-dialog/recalculate-dialog.component';
var AccrualsBalancesToolbarComponent = /** @class */ (function () {
    function AccrualsBalancesToolbarComponent(modalService, accrualsManagementService, stateManagement, devDetector, activatedRoute) {
        this.modalService = modalService;
        this.accrualsManagementService = accrualsManagementService;
        this.stateManagement = stateManagement;
        this.devDetector = devDetector;
        this.activatedRoute = activatedRoute;
        this.effectiveDate = null;
        this.hideRates = true;
        this.stateKey = 'AccrualsBalancesState';
        this.calculateAccrualsAction = false;
        this.subscriptions = {};
    }
    Object.defineProperty(AccrualsBalancesToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccrualsBalancesToolbarComponent.prototype, "effectiveDateWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    AccrualsBalancesToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setDefaultDate();
        this.subscribeToOrgLevel();
        this.subscribeTocalculateAccrualsAction();
        this.subscriptions.state = this.accrualsManagementService.subscribeToState(function (state) {
            _this.state = state;
        });
        this.subscriptions.loadedRecords = this.accrualsManagementService.subscribeToLoadedRecords(function (r) {
            _this.hideRates = r.hideRates;
        });
        this.subscriptions.columnState = this.accrualsManagementService
            .subscribeToColumnState(function (s) { return (_this.columnState = s); });
        this.subscriptions.restoreState = this.activatedRoute.data
            .combineLatest(this.stateManagement.onInit$)
            .map(function (values) { return values[0]; })
            .subscribe(function (routeData) {
            _this.restoreState(routeData);
        });
    };
    AccrualsBalancesToolbarComponent.prototype.subscribeTocalculateAccrualsAction = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.accrualsManagementService
            .subscribeTocalculateAccrualsAction(function (r) {
            _this.calculateAccrualsAction = r;
        });
    };
    AccrualsBalancesToolbarComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
        });
    };
    AccrualsBalancesToolbarComponent.prototype.ngOnDestroy = function () { };
    AccrualsBalancesToolbarComponent.prototype.onChangeEffectiveDate = function (effectiveDate) {
        this.effectiveDate = effectiveDate;
        if (!this.isDesktop) {
            this.applyEffectiveDateChange();
        }
    };
    AccrualsBalancesToolbarComponent.prototype.applyEffectiveDateChange = function () {
        this.accrualsManagementService.changeEffectiveDate(this.effectiveDate);
    };
    AccrualsBalancesToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.accrualsManagementService.exportTo(isPDF);
    };
    AccrualsBalancesToolbarComponent.prototype.onShowActiveEmployee = function ($event) {
        this.state.isEmployeeActive = $event;
        this.accrualsManagementService.updateState(this.state);
        this.saveState();
    };
    AccrualsBalancesToolbarComponent.prototype.onShowNegativeBalance = function ($event) {
        this.state.isShowNegativeBalance = $event;
        this.accrualsManagementService.updateState(this.state);
        this.saveState();
    };
    AccrualsBalancesToolbarComponent.prototype.onToggleDollarValue = function ($event) {
        this.state.isDollarValueActive = $event;
        this.accrualsManagementService.updateState(this.state);
        this.saveState();
    };
    AccrualsBalancesToolbarComponent.prototype.setDefaultDate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.accrualsManagementService.getDefaultEffectiveDate()];
                    case 1:
                        _a.effectiveDate = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AccrualsBalancesToolbarComponent.prototype.saveState = function () {
        this.stateManagement.setControlState(this.stateKey, {
            value: this.state
        }, StateResetTypes.None);
    };
    AccrualsBalancesToolbarComponent.prototype.restoreState = function (routeData) {
        var routeState = routeData ? _.find(_.values(routeData), function (d) { return _.isObjectLike(d) && (d.isEmployeeActive || d.isShowNegativeBalance || d.isDollarValueActive); }) : null;
        var state = _.clone(initialBalancesState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        if (routeState) {
            if (_.isBoolean(routeState.isDollarValueActive)) {
                state.isDollarValueActive = routeState.isDollarValueActive;
            }
            if (_.isBoolean(routeState.isEmployeeActive)) {
                state.isEmployeeActive = routeState.isEmployeeActive;
            }
            if (_.isBoolean(routeState.isShowNegativeBalance)) {
                state.isShowNegativeBalance = routeState.isShowNegativeBalance;
            }
        }
        this.accrualsManagementService.updateState(state);
    };
    AccrualsBalancesToolbarComponent.prototype.onClickRecalcAccruals = function () {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = 950;
        dialogOptions.height = 480;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.hideTitleBar = true;
        var dialog = this.modalService.globalAnchor.openDialog(RecalculateDialogComponent, 'Confirmation', dialogOptions, undefined);
        dialog.orgLevelId = this.orgLevel.id;
        if (dialog.initialized) {
            dialog.onLoadRecalculateData();
        }
    };
    AccrualsBalancesToolbarComponent.prototype.onClickToggleSelection = function (isSelectAll) {
        _.forEach(this.columnState.allColumns, function (column) {
            column.displayed = isSelectAll;
        });
        this.accrualsManagementService.setColumnState(this.columnState);
    };
    AccrualsBalancesToolbarComponent.prototype.onChangeColumn = function () {
        this.accrualsManagementService.setColumnState(this.columnState);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AccrualsBalancesToolbarComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsBalancesToolbarComponent.prototype, "subscriptions", void 0);
    return AccrualsBalancesToolbarComponent;
}());
export { AccrualsBalancesToolbarComponent };
