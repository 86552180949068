import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { createValuAccessor } from '../../../common/utils/index';
import { DateRangeWithDate, isSameRanges } from '../../../components-library/models/index';
import { appConfig, IApplicationConfig } from '../../../app.config';
import { PopperContent } from 'ngx-popper';
import { ControlValueAccessorBase } from '../../../core/models/index';
import { ScheduleCycle } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-cycle-calendar-slider',
  templateUrl: 'schedule-cycle-calendar-slider.component.html',
  styleUrls: ['schedule-cycle-calendar-slider.component.scss'],
  providers: [createValuAccessor(ScheduleCycleCalendarSliderComponent)]
})
export class ScheduleCycleCalendarSliderComponent extends ControlValueAccessorBase {
  @Input()
  public set cycles(value: ScheduleCycle[]) {
    this.m_cycles = value;
    this.recalcCycle();
  }

  public get cycles(): ScheduleCycle[] {
    return this.m_cycles;
  }

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  @Input()
  public readonly: boolean;

  @Output()
  public rangeChanged: EventEmitter<DateRangeWithDate>;

  @ViewChild('popperCalendar', {static: true})
  public popper: PopperContent;
  public isShown: boolean;

  public currentRange: DateRangeWithDate;
  public appConfig: IApplicationConfig;

  private m_cycles: ScheduleCycle[];
  private m_cycles_start: StringMap<ScheduleCycle>;
  private m_cycles_end: StringMap<ScheduleCycle>;

  private m_currentDate: Date;

  constructor() {
    super();
    this.appConfig = appConfig;
    this.isShown = false;
    this.rangeChanged = new EventEmitter<DateRangeWithDate>();
  }

  public get currentDate(): Date {
    return this.m_currentDate;
  }

  public writeValue(value: Date): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.m_currentDate = value;
      this.recalcCycle();
    }
  }

  public set currentDate(value: Date) {
    if (_.isDate(this.m_currentDate) && !moment(this.m_currentDate).isSame(value)) {
      this.m_currentDate = value;
      if (this.popper instanceof PopperContent) {
        this.popper.hide();
      }
      this.onChangeCallback(this.m_currentDate);
      this.recalcCycle();
    }
  }

  public recalcCycle(): void {
    if (!this.currentDate || !this.cycles || this.cycles.length === 0) {
      return;
    }
    const days: number = this.calculateDays(this.currentDate);
    const end = moment(this.currentDate).add(days, 'day').toDate();
    this.currentRange = new DateRangeWithDate(this.currentDate, this.currentDate, end);
    this.rangeChanged.next(this.currentRange);
  }

  public onNavButtonClick(event: MouseEvent, isNext: boolean): void {
    if (this.readonly) return;
    let days: number = this.calculateDays(this.currentDate) + 1;
    if (isNext) {
      this.currentDate = moment(this.currentDate).add(days, 'day').toDate();
    } else {
      this.currentDate = moment(this.currentDate).subtract(days, 'day').toDate();
    }
  }

  // See #issueWithPopperVisibility
  public onToggleVisibility(isShown: boolean): void {
    this.isShown = isShown;
  }

  private calculateDays(date: Date): number {
    const currentDate = moment(date).startOf('day').toDate();
    let selectedCycle = this.findPayCycle(this.cycles, currentDate);
    let days: number = moment(selectedCycle.endDate).diff(selectedCycle.startDate, 'days');
    return days;
  }

  private findPayCycle(cycles: ScheduleCycle[], date: Date): ScheduleCycle {
    let selectedCycle: ScheduleCycle = _.find(cycles, (cycle: ScheduleCycle) => {
      return moment(date).isSameOrAfter(cycle.startDate) && moment(date).isSameOrBefore(cycle.endDate);
    });
    return selectedCycle;
  }
}
