import { Lookup, LookupType } from './../../../organization/models/lookup/lookup-definition';
import { LookupService } from './../../../organization/services/lookup/lookup.service';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import * as moment from 'moment';

import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';

import { OrgLevel } from '../../../state-model/models/index';

import { schedulerConfig } from '../../scheduler.config';
import { DetailScreenMapService } from './detail-screen-map.service';
import { Details, Shift, Unit, DetailsPartner } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { CacheType } from '../../../common/models/cache/cache-definition';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { DetailsShiftRequest } from '../../models/detail-screen/details-shift-request';
import { OpenShiftSchedule } from '../../models/daily-unit-assignment/add-open-shift';


@Injectable()
export class DetailScreenService {
  constructor(
    private apiUtilService: ApiUtilService,
    private detailScreenMapService: DetailScreenMapService,
    private urlParamService: UrlParamsService,
    private lookupService: LookupService,
    private cacheUtilService: CacheUtilService,
    private notificationsService: NotificationsService) {
  }

  public getDetails(orgLevel: OrgLevel, dateOn: Date, positionIds: number[], shiftIds: Shift[], unitIds: Unit[], grouping: string[]): Promise<Details> {
    Assert.isNotNull(orgLevel);

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${schedulerConfig.api.orglevel}/${orgLevel.id}/${schedulerConfig.api.details}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, {
      positionIds: positionIds,
      unitIds: unitIds,
      shiftIds: shiftIds,
      groupBy: grouping
    });
    return this.sendRequest(request, orgLevel.id);
  }

  public getDailyUnits(orgLevel: OrgLevel, dateOn: Date): Promise<Details> {
    Assert.isNotNull(orgLevel);

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${schedulerConfig.api.orglevel}/${orgLevel.id}/${schedulerConfig.api.dailyUnitAssignments}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: this.urlParamService.convertToParams({ dateOn: dateTimeUtils.convertToDtoString(dateOn) })
    });
    return this.sendRequest(request, orgLevel.id);
  }

  public deleteDailyUnitEmployee(dateOn: Date, shiftId: number, employeeId: number): Promise<any> {
    Assert.isNotNull(dateOn);
    Assert.isNotNull(shiftId);
    Assert.isNotNull(employeeId);

    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/${dateTimeUtils.convertToDtoString(dateOn)}/${schedulerConfig.api.schedule.shift.root}/${shiftId}/${schedulerConfig.api.employees.root}/${employeeId}`;
    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);
    return promise;
  }

  public cancelDailyUnitEmployee(dateOn: Date, shiftId: number, employeeId: number, partnerId: string, requestId: number, cancelType: string): Promise<any> {
    Assert.isNotNull(dateOn);
    Assert.isNotNull(shiftId);
    Assert.isNotNull(partnerId);
    Assert.isNotNull(requestId);
    Assert.isNotNull(cancelType)
    
    if( employeeId === null || employeeId == void 0){
      employeeId = 0;
    }
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/partner/${partnerId}/day/${dateTimeUtils.convertToDtoString(dateOn)}/${schedulerConfig.api.schedule.shift.root}/${shiftId}/${schedulerConfig.api.employees.root}/${employeeId}/request/${requestId}/type/${cancelType} `; 
    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => { 
        this.notificationsService.success('success', 'Successfully canceled shift request');
        return response.data;
    });
    return promise;
  }

  private sendRequest(request: HttpRequest<any>, orgLevelId: number): Promise<Details> {
    let lookupPromise: Promise<Lookup> = this.lookupService.getLookup({ lookupType: LookupType.shift, orgLevelId: orgLevelId });
    let mainPromise: Promise<ResponseBody<Details, Meta>> = this.apiUtilService.request<Details, Meta>(request);

    return Promise.all([lookupPromise, mainPromise])
    .then(([lookup, response]) => this.detailScreenMapService.mapToDetails(response.data, response.meta, lookup.items));
  }

  public getAgencyDetails(orgLevel: OrgLevel, alias: string): Promise<DetailsPartner[]> {
    Assert.isNotNull(orgLevel);
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/customer/${alias}/${schedulerConfig.api.orglevel}/${orgLevel.id}/${schedulerConfig.api.getPartnerDetails}`;
    let request: HttpRequest<DetailsPartner[]> = new HttpRequest('GET', url);
    // clear cache
    this.cacheUtilService.delete({ key: url }, '');
    let promise: Promise<DetailsPartner[]> = this.apiUtilService
      .cachedRequest<DetailsPartner[], Meta>(request, CacheType.longTerm)
        .then((response: ResponseBody<any, Meta>) =>  this.detailScreenMapService.mapToPartnerDetails(response.data));
      return promise;
  }

  public getShiftRequestDetails(shiftRequestId: number, alias: string): Promise<DetailsShiftRequest[]> {
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.root}/customer/${alias}/${schedulerConfig.api.schedule.shift.root}/${shiftRequestId}/${schedulerConfig.api.details}`;
    let request: HttpRequest<DetailsShiftRequest[]> = new HttpRequest('GET', url);
    Assert.isNotNull(shiftRequestId);
    // clear cache
    this.cacheUtilService.delete({ key: url }, '');
    let promise: Promise<DetailsShiftRequest[]> = this.apiUtilService.request<DetailsShiftRequest[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => this.detailScreenMapService.mapToShiftRequestDetails(response.data));
    return promise;
  }
  public saveIdealSchedule(orgLevelId: number, openShift: OpenShiftSchedule): Promise<any> {
    const url: string = this.apiUtilService.apiRoot`configuration/orglevel/${orgLevelId}/idealSchedule/openshift`;
    let body: any = openShift;

   let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiUtilService.request<any[], Meta>(request);
  }
  public saveIdealScheduleV2(orgLevelId: number, openShift: OpenShiftSchedule): Promise<any> {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/configuration/orglevel/${orgLevelId}/idealSchedule/openshift`;
    let body: any = openShift;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiUtilService.request<any[], Meta>(request); 
  }
}
