import { Injectable } from '@angular/core';

import { ResponseBody, Meta, EditResult } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { SlxHttpRequest } from '../../../../core/models/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitClassMapService } from './benefit-class-map.service';
import {
  IBenefitClass, BenefitClass
} from '../../models/index';
import { IServerValidationResult } from '../../../../common/validators/common-validators-models';

@Injectable()
export class BenefitClassApiService {
  constructor(
    private mapService: BenefitClassMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public async getBenefitClasses(orgLevelId: number): Promise<BenefitClass[]> {

    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.configuration.benefitClass}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { orgLevelId });

    const response = await this.apiUtilService.requestNew<IBenefitClass[], Meta>(request);
    return this.mapService.mapToBenefitClasses(response.data);
  }

  public async validateBenefitClass(id: number, value: string, field: string, orgLevelId: number): Promise<IServerValidationResult> {

    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.configuration.benefitClass}/${benefitsConfig.api.configuration.validate}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, { id, value, field, orgLevelId });
    const response = await this.apiUtilService.requestNew<IServerValidationResult, Meta>(request);
    return response.data;
  }


  public async addBenefitClass(benefitClass: BenefitClass): Promise<EditResult<BenefitClass>> {

    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.configuration.benefitClass}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestPost(url, this.mapService.mapToBenefitClassDto(benefitClass));

    const response = await this.apiUtilService.requestNew<EditResult<IBenefitClass>, Meta>(request);
    return this.mapService.mapEditResult(response.data);
  }

  public async saveBenefitClass(benefitClass: BenefitClass): Promise<EditResult<BenefitClass>> {

    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.configuration.benefitClass}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestPut(url, this.mapService.mapToBenefitClassDto(benefitClass));

    const response = await this.apiUtilService.requestNew<EditResult<IBenefitClass>, Meta>(request);
    return this.mapService.mapEditResult(response.data);
  }

  public async deleteBenefitClass(id: number): Promise<EditResult<BenefitClass>> {

    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.configuration.benefitClass}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestDelete(url, { id });

    const response = await this.apiUtilService.requestNew<EditResult<IBenefitClass>, Meta>(request);
    return this.mapService.mapEditResult(response.data);
  }

  public getApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.configuration.root}`;
  }

}
