var PayCodeModel = /** @class */ (function () {
    function PayCodeModel() {
    }
    Object.defineProperty(PayCodeModel.prototype, "isCodeApplicableForPbj", {
        get: function () {
            return this.payCode ? this.payCode.isPbjApplicable : false;
        },
        enumerable: true,
        configurable: true
    });
    return PayCodeModel;
}());
export { PayCodeModel };
