export * from './timeclocks-list/timeclocks-list.component';
export * from './timeclock-renderer/timeclock-renderer.component';
export * from './timeclock-editor/timeclock-editor.component';
export * from './timeclock-remove-dialog/timeclock-remove-dialog.component';
export * from './temp-readings/temp-readings.component';
export * from './temp-readings/temp-readings-grid/temp-readings-grid.component';
export * from './temp-readings/temp-readings-toolbar/temp-readings-toolbar.component';

import { TimeclocksListComponent } from './timeclocks-list/timeclocks-list.component';
import { TimeclockRendererComponent } from './timeclock-renderer/timeclock-renderer.component';
import { TimeclockEditorComponent } from './timeclock-editor/timeclock-editor.component';
import { TimeclockRemoveDialogComponent } from './timeclock-remove-dialog/timeclock-remove-dialog.component';
import { TempReadingsComponent } from './temp-readings/temp-readings.component';
import { TempReadingsToolbarComponent } from './temp-readings/temp-readings-toolbar/temp-readings-toolbar.component';
import { TempReadingsGridComponent } from './temp-readings/temp-readings-grid/temp-readings-grid.component';

export const timeclocksComponents: any = [
  TimeclocksListComponent,
  TimeclockRendererComponent,
  TimeclockEditorComponent,
  TimeclockRemoveDialogComponent,
  TempReadingsComponent,
  TempReadingsToolbarComponent,
  TempReadingsGridComponent,
];
