<section *ngIf="!hasScheduleAbsence && !!overtime" class="overtime form-inline" provide-parent-form>
  <span class="title">Prescheduled OT</span>
  <div class="section-body time-group">
    
    <slx-input-decorator #otStartTime>
      <input slx-time-picker slx-input required="true" placeholder="Start time" [(ngModel)]="overtime.startDate"
        dateInput="true" format="hh:mm tt" name="overtimeStartTime{{index}}" (onDateChanged)="overtimeDateChanged()" slxDatePager
        [pagerMinDate]="minDate" [pagerMaxDate]="maxDate" [targetContainer]="otStartTime" btnLeftClass="prev-day-btn"
        btnRightClass="next-day-btn" styleContainerClass="slx-date-pager-date" [slxMinDate]="startDate" [slxMaxDate]="overtime.endDate">
      <span errorMessage for="required">Start time is required</span>
      <span errorMessage for="minDate">Value should be greater than shift start</span>
      <span errorMessage for="maxDate">Value should be less than overtime end</span>
      <span slx-input-hint>{{startDate | amDateFormat: appConfig.monthDayDateFormat}}</span>
    </slx-input-decorator>

    <slx-input-decorator #otEndTime>
      <input slx-time-picker slx-input required="true" placeholder="End time" [(ngModel)]="overtime.endDate"
        dateInput="true" format="hh:mm tt" name="overtimeEndTime{{index}}" (onDateChanged)="overtimeDateChanged()" slxDatePager
        [pagerMinDate]="minDate" [pagerMaxDate]="maxDate" [targetContainer]="otEndTime" btnLeftClass="prev-day-btn"
        btnRightClass="next-day-btn" styleContainerClass="slx-date-pager-date" [slxMinDate]="overtime.startDate"
        [slxMaxDate]="endDate">
      <span errorMessage for="minDate">Value should be greater than overtime start</span>
      <span errorMessage for="maxDate">Value should be less than shift end</span>
      <span errorMessage for="required">End time is required</span>
      <span slx-input-hint>{{endDate | amDateFormat: appConfig.monthDayDateFormat}}</span>
    </slx-input-decorator>

    <slx-input-decorator class="hours-field">
      <slx-kendo-number slx-input [ngModel]="overtime.duration" (ngModelChange)="overtimeHoursChanged($event)"
        [min]="0" [slxMin]="0" [max]="fullTime" [slxMax]="fullTime" [decimals]="2" [required]="true" [readonly]="!shit"
        placeholder="Hours" name="overtimeHours{{index}}"></slx-kendo-number>
      <span errorMessage for="min">Hours should be greater than zero</span>
      <span errorMessage for="max">Hours should be less than shift total time</span>
      <span errorMessage for="required">Hours are required</span>
    </slx-input-decorator>
  </div>
</section>
