import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsProfile = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsProfile, _super);
    function EmployeeSectionsProfile() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(EmployeeSectionsProfile.prototype, "isTerminated", {
        get: function () {
            return this.status.toUpperCase() === 'TERMINATED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsProfile.prototype, "isTransferred", {
        get: function () {
            return this.status.toUpperCase() === 'TRANSFERRED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsProfile.prototype, "isRehired", {
        get: function () {
            return this.status.toUpperCase() === 'REHIRED';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsProfile.prototype, "isFutureRehire", {
        get: function () {
            return this.status.toUpperCase() === 'FUTURE REHIRE';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsProfile.prototype.areBenefitsFieldsDirty = function () {
        return this.birthDate.isDirty;
    };
    return EmployeeSectionsProfile;
}(EmployeeSectionsBase));
export { EmployeeSectionsProfile };
