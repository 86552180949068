import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { WcCreateOshaFormService } from '../../../services/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
var WcCreateOshaRkFormsComponent = /** @class */ (function () {
    function WcCreateOshaRkFormsComponent(oshaService) {
        this.oshaService = oshaService;
        this.isLoading = true;
        this.subscriptions = {};
    }
    WcCreateOshaRkFormsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.oshaService.init('WcCreateOshaFormRoster');
        this.subscriptions.onLoad = this.oshaService
            .subscribeToLoading(function (value) { return (_this.isLoading = value); });
    };
    WcCreateOshaRkFormsComponent.prototype.ngOnDestroy = function () {
        this.oshaService.destroy();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcCreateOshaRkFormsComponent.prototype, "subscriptions", void 0);
    return WcCreateOshaRkFormsComponent;
}());
export { WcCreateOshaRkFormsComponent };
