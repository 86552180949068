<div class="am-toolbar">
  <slx-toolbar class="slx-so-toolbar-container" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate [width]="getAcaWidth()">
      <span class="am-toolbar__control as-flex-end">
        <slx-dropdown-list name="yearDropdown"
                           class="slx-wide slx-short"
                           [valuePrimitive]="true"
                           [options]="yearOptions"
                           (ngModelChange)="onChangeYear($event)"
                           [(ngModel)]="selectedYear">
        </slx-dropdown-list>
      </span>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="160">
      <div class="am-toolbar__control">
        <div class="pd-3">
          <span *ngIf="!acaManagementService?.expandAll" class="slx-button slx-only-icon slx-toolbar ico-expand" (click)="onExpandCollapse(true)">
            <i class="txt-blue fa fa-angle-double-down ico-mar-r5" aria-hidden="true"></i> Expand All
          </span>
          <span *ngIf="acaManagementService?.expandAll" class="slx-button slx-only-icon slx-toolbar ico-expand" (click)="onExpandCollapse(false)"> 
            <i aria-hidden="true" class="txt-blue fa fa-angle-double-up ico-mar-r5"></i> Collapse All
          </span>
        </div>
      </div>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="30">
      <div class="am-toolbar__control">
        <slx-columns-config-button [colGroupKey]="columnsStateName"
                 [blueStyleConfig]="{ btnClass: 'slx-button slx-only-icon slx-toolbar', iconClass: 'fa fa-cog' }"
                 title="Columns settings"></slx-columns-config-button>
      </div>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="100">
      <span class="am-toolbar__control" [ngClass]="{ 'collapsed': isCollapsed }">
        <slx-multiselect class="am-toolbar__filter"
                         [options]="employeeFilterRecords"
                         [(ngModel)]="filter"
                         (ngModelChange)="onChangeFilter($event)"
                         titleField="name"
                         valueField="id"
                         [valuePrimitive]="false"
                         placeholder="Filter Employees"
                         [externalPlaceholder]="false"
                         name="employeeFilter">
        </slx-multiselect>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="90">
      <span class="am-toolbar__control" *ngIf="actions?.exportToExcel">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>
  </slx-toolbar>
</div>

