import * as tslib_1 from "tslib";
import { Subject, Subscription } from "rxjs";
import { unsubscribe } from "./../../../../app/core/decorators";
var EmployeeSectionTabNavigationService = /** @class */ (function () {
    function EmployeeSectionTabNavigationService() {
        var _this = this;
        this.empSectionTab$ = new Subject();
        this.empSectionTab = null;
        this.empSectionTabSubscription = this.empSectionTab$.subscribe(function (tabName) {
            _this.empSectionTab = tabName;
        });
    }
    EmployeeSectionTabNavigationService.prototype.activeContainerScroll = function (tabName) {
        this.empSectionTab$.next(tabName);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionTabNavigationService.prototype, "empSectionTabSubscription", void 0);
    return EmployeeSectionTabNavigationService;
}());
export { EmployeeSectionTabNavigationService };
