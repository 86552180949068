var ReadFile = /** @class */ (function () {
    function ReadFile(name, sizeInBytes, mimeType, extension, data) {
        this.name = name;
        this.sizeInBytes = sizeInBytes;
        this.mimeType = mimeType;
        this.extension = extension;
        this.data = data;
    }
    Object.defineProperty(ReadFile.prototype, "fileName", {
        get: function () {
            return this.name + "." + this.extension;
        },
        enumerable: true,
        configurable: true
    });
    return ReadFile;
}());
export { ReadFile };
