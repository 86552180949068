export class NotificationMessage {
  public title: string;
  public message: string;

  public constructor(title: string, message: string) {
    this.title = title;
    this.message = message;
  }
}

export class ValidationMessage {
  public type: string;
  public message: string;

  public constructor(type: string, message: string) {
    this.type = type;
    this.message = message;
  }
}

export interface IApplicationMessages {
  success: {
    general: NotificationMessage,
    saved: NotificationMessage,
    scheduleBackupCreated: NotificationMessage
    pbjExportStarted: NotificationMessage,
    releasePayrollExport: NotificationMessage,
    cancelPayrollExport: NotificationMessage
  };
  error: {
    general: NotificationMessage,
    requestFailed: NotificationMessage
  };
  validation: {
    requiredField: ValidationMessage,
    filledField: ValidationMessage,
    dateFieldValidation: ValidationMessage,
    numericFieldValidation: ValidationMessage,
    phoneValidation: ValidationMessage,
    emailValidation: ValidationMessage,
    ssnValidation: ValidationMessage,
    zipValidation: ValidationMessage,
    prohibitedNameValidation: ValidationMessage,
    prohibitedDescriptionValidation: ValidationMessage,
    minValueValidation: ValidationMessage,
    maxValueValidation: ValidationMessage,
    ipv4Validation: ValidationMessage
    imageFormatValidation: ValidationMessage,
    uploadFormatValidation: ValidationMessage,
    acaReceiptIdValidation: ValidationMessage,
  };
}

export const appMessages: IApplicationMessages = {
  success: {
    general: {
      title: 'Operation completed successfully',
      message: ''
    },
    saved: {
      title: 'Saved',
      message: ''
    },
    scheduleBackupCreated: {
      title: 'Backup successfully created',
      message: ''
    },
    pbjExportStarted: {
      title: 'New export started',
      message: ''
    },
    releasePayrollExport: {
      title: 'File released',
      message: ''
    },
    cancelPayrollExport: {
      title: 'Release canceled',
      message: ''
    }
  },
  error: {
    general: {
      title: 'Sorry, something went wrong',
      message: 'We\'re working on getting this fixed as soon as we can'
    },
    requestFailed: {
      title: 'Request failed',
      message: 'Sorry, something went wrong'
    }
  },
  validation: {
    requiredField: {
      type: 'required',
      message: 'Required field'
    },
    filledField: {
      type: 'filled',
      message: 'Field must be filled'
    },
    dateFieldValidation: {
      type: 'date',
      message: 'Date is invalid or out of valid range'
    },
    numericFieldValidation: {
      type: 'number',
      message: 'The value should be numeric'
    },
    phoneValidation: {
      type: 'phone',
      message: 'Must match the (###) ###-#### pattern'
    },
    emailValidation: {
      type: 'email',
      message: 'Must match the ####@####.## pattern'
    },
    ssnValidation: {
      type: 'ssn',
      message: 'Must match the ***-**-**** pattern'
    },
    zipValidation: {
      type: 'zip',
      message: 'Must match the Zip pattern'
    },
    prohibitedNameValidation: {
      type: 'prohibitedValues',
      message: 'You can not use this name'
    },
    prohibitedDescriptionValidation: {
      type: 'prohibitedDescription',
      message: 'You can not use this description'
    },
    minValueValidation: {
      type: 'min',
      message: 'Min value'
    },
    maxValueValidation: {
      type: 'max',
      message: 'Max value'
    },
    ipv4Validation: {
      type: 'ipv4',
      message: 'Must be ipV4 address'
    },
    imageFormatValidation: {
      type: 'imageFormat',
      message: 'You cannot edit this image, try upload new'
    },
    uploadFormatValidation: {
      type: 'uploadFormat',
      message: 'You can upload only \'png\' or \'jpeg\' images. The image should be bigger than 200 x 200 px'
    },
    acaReceiptIdValidation: {
      type: 'acaReceiptId',
      message: 'Must match the 1094C or 1095C -##-########## pattern'
    }
  }
};
