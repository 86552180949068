import { OnInit, Input, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevel } from '../../../state-model/models/index';
import { EmployeeDetails, OpenEmplyeesDetails } from '../../../organization/models/index';
import { Assert } from '../../../framework/index';
import { ModalService } from '../../../common/index';
import { unsubscribeInService } from '../../../core/decorators/index';
import * as _ from 'lodash';

import { mutableSelect } from '../../../core/decorators/index';
import { EmployeeDetailsListComponent } from '../../../organization/components/widgets/employee-details-list/employee-details-list.component';

import {
  ArrivalDeparturesContainer, IArrivalDetails, ArrivalDetails,
  DepartureDetails, AttendanceType,
  AttendanceTypeDefinition, ArrivalsDeparturesAnalytics,
  ArrivalsDeparturesAnalyticsDefinition
} from '../../models/index';
import { ArrivalsDeparturesApiService } from './arrivals-departures-api.service';
import { ArrivalsDeparturesMapService } from './arrivals-departures-map.service';
import { IDestroyService } from '../../../core/models/index';

@Injectable()
export class ArrivalDeparturesDetailsManagementService implements IDestroyService {
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  public onLoaded$: ReplaySubject<EmployeeDetails[]>;
  public onLoadStarted$: ReplaySubject<any>;
  private selectedOrgLevel: OrgLevel;
  private selectedDate: Date;
  private selectedTime: Date;
  private analytics: ArrivalsDeparturesAnalyticsDefinition;
  private attendanceType: AttendanceTypeDefinition;

  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  constructor(
    private arrivalsDeparturesApiService: ArrivalsDeparturesApiService,
    private arrivalsDeparturesMapService: ArrivalsDeparturesMapService,
    private modalService: ModalService) {
    this.selectedDate = moment().toDate();
    this.arrivalsDeparturesApiService = arrivalsDeparturesApiService;
    this.arrivalsDeparturesMapService = arrivalsDeparturesMapService;
    this.onLoaded$ = new ReplaySubject(1);
    this.onLoadStarted$ = new ReplaySubject(1);
    this.orgLevelSubscription = this.orgLevel$
      .filter((o: OrgLevel) => !this.selectedOrgLevel || o && this.selectedOrgLevel.id !== o.id)
      .subscribe((o: OrgLevel) => {
        if (o.id) {
          this.selectedOrgLevel = o;
          this.loadData(this.selectedOrgLevel, this.selectedDate, this.selectedTime, this.attendanceType, this.analytics);
        }
      });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  public loadDetails(dateOn: Date, timeOn: Date, attendanceType: AttendanceTypeDefinition, analytics: ArrivalsDeparturesAnalyticsDefinition): void {
    this.attendanceType = attendanceType;
    this.analytics = analytics;
    this.selectedDate = dateOn;
    this.selectedTime = timeOn;
    this.loadData(this.selectedOrgLevel, dateOn, timeOn, attendanceType, analytics);
  }

  public changeSelectedDate(selectedDate: Date): void {
    this.selectedDate = selectedDate;
    this.loadData(this.selectedOrgLevel, this.selectedDate, this.selectedTime, this.attendanceType, this.analytics);
  }

  private loadData(orgLevel: OrgLevel, dateOn: Date, timeOn: Date, attendanceType: AttendanceTypeDefinition, analytics: ArrivalsDeparturesAnalyticsDefinition): void {
    Assert.isNotNull(this.selectedOrgLevel, 'orgLevel');
    this.onLoadStarted$.next(null);
    if (attendanceType === AttendanceType.arrivals) {
      if (analytics === ArrivalsDeparturesAnalytics.in) {
        this.loadArrivalInEmployees(this.selectedDate, this.selectedTime);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.scheduled) {
        this.loadArrivalScheduledEmployees(this.selectedDate, this.selectedTime);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.unscheduled) {
        this.loadArrivalUnscheduledEmployees(this.selectedDate);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.difference) {
        this.loadArrivalDifferenceEmployees(this.selectedDate, this.selectedTime);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.late) {
        this.loadArrivalLateEmployees(this.selectedDate, this.selectedTime);
        return;
      }
    } else if (attendanceType === AttendanceType.departures) {
      if (analytics === ArrivalsDeparturesAnalytics.out) {
        this.loadDepartureOutEmployees(this.selectedDate, this.selectedTime);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.scheduled) {
        this.loadDepartureScheduledEmployees(this.selectedDate, this.selectedTime);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.unscheduled) {
        this.loadDepartureUnscheduledEmployees(this.selectedDate);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.difference) {
        this.loadDepartureDifferenceEmployees(this.selectedDate, this.selectedTime);
        return;
      } else if (analytics === ArrivalsDeparturesAnalytics.overtime) {
        this.loadDepartureOvertimeEmployees(this.selectedDate, this.selectedTime);
        return;
      }
      throw new Error(`Can't load data for attendanceType='${attendanceType}' analytics='${analytics}.'`);
    }
  }

  private loadArrivalScheduledEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: ArrivalDetails) => employeeDetailsItem.isScheduled);
  }

  private loadArrivalUnscheduledEmployees(scheduledDate: Date): void {
    return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, null, (employeeDetailsItem: ArrivalDetails) => !employeeDetailsItem.isScheduled);
  }

  private loadArrivalInEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: ArrivalDetails) => employeeDetailsItem.isIn);
  }

  private loadArrivalLateEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: ArrivalDetails) => employeeDetailsItem.isLate);
  }

  private loadArrivalDifferenceEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByArrivals(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: ArrivalDetails) => employeeDetailsItem.isDiff);
  }

  private loadDepartureScheduledEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: DepartureDetails) => employeeDetailsItem.isScheduled);
  }

  private loadDepartureUnscheduledEmployees(scheduledDate: Date): void {
    return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, null, (employeeDetailsItem: DepartureDetails) => !employeeDetailsItem.isScheduled);
  }

  private loadDepartureOutEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: DepartureDetails) => employeeDetailsItem.isOut);
  }

  private loadDepartureOvertimeEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: DepartureDetails) => employeeDetailsItem.isInOvertime);
  }

  private loadDepartureDifferenceEmployees(scheduledDate: Date, scheduledTime: Date): void {
    return this.loadEmployeeDetailsByDepartures(this.selectedOrgLevel, scheduledDate, scheduledTime, (employeeDetailsItem: DepartureDetails) => employeeDetailsItem.isDiff);
  }

  private loadEmployeeDetailsByArrivals<T>(orgLevel: OrgLevel, scheduledDate: Date, scheduledTime: Date, filterEmployee: _.ListIterator<ArrivalDetails, boolean>): void {
    Assert.isNotNull(this.selectedOrgLevel, 'orgLevel');
    this.onLoadStarted$.next(null);
    if (scheduledTime) {
      this.arrivalsDeparturesApiService.getArrivalsDetails(this.selectedOrgLevel.id, scheduledDate, scheduledTime).then((details: ArrivalDetails[]) => {
        let scheduledEmployees: ArrivalDetails[] = _.filter(details, filterEmployee);
        let employeeDetails: EmployeeDetails[] = this.arrivalsDeparturesMapService.mapArrivalsDetailsToEmployeeDetails(scheduledEmployees);
        this.onLoaded$.next(employeeDetails);
      });
    } else {
      this.arrivalsDeparturesApiService.getArrivalsUnscheduledDetails(this.selectedOrgLevel.id, scheduledDate).then((details: ArrivalDetails[]) => {
        let scheduledEmployees: ArrivalDetails[] = _.filter(details, filterEmployee);
        let employeeDetails: EmployeeDetails[] = this.arrivalsDeparturesMapService.mapArrivalsDetailsToEmployeeDetails(scheduledEmployees);
        this.onLoaded$.next(employeeDetails);
      });
    }
  }

  private loadEmployeeDetailsByDepartures<T>(orgLevel: OrgLevel, scheduledDate: Date, scheduledTime: Date, filterEmployee: _.ListIterator<DepartureDetails, boolean>): void {
    Assert.isNotNull(this.selectedOrgLevel, 'orgLevel');
    this.onLoadStarted$.next(null);
    if (scheduledTime) {
      this.arrivalsDeparturesApiService.getDeparturesDetails(this.selectedOrgLevel.id, scheduledDate, scheduledTime).then((details: DepartureDetails[]) => {
        let scheduledEmployees: DepartureDetails[] = _.filter(details, filterEmployee);
        let employeeDetails: EmployeeDetails[] = this.arrivalsDeparturesMapService.mapDeparturesDetailsToEmployeeDetails(scheduledEmployees);
        this.onLoaded$.next(employeeDetails);
      });
    } else {
      this.arrivalsDeparturesApiService.getDeparturesUnscheduledDetails(this.selectedOrgLevel.id, scheduledDate).then((details: DepartureDetails[]) => {
        let scheduledEmployees: DepartureDetails[] = _.filter(details, filterEmployee);
        let employeeDetails: EmployeeDetails[] = this.arrivalsDeparturesMapService.mapDeparturesDetailsToEmployeeDetails(scheduledEmployees);
        this.onLoaded$.next(employeeDetails);
      });
    }
  }

  // private displayEmployeesDetails(employees: EmployeeDetails[], orgLevelName: string, scheduledDate: Date, scheduledTime: Date): void {
  //   let openDetails: OpenEmplyeesDetails = new OpenEmplyeesDetails();
  //   openDetails.orgLevelName = orgLevelName;
  //   openDetails.dateOn = dateOn;
  //   openDetails.scheduledTime = scheduledTime;
  //   openDetails.employeeDetails = employees;
  //   EmployeeDetailsListComponent.openDialog(openDetails, this.modalService, () => { return  null; });
  // }
}
