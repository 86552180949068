<div class="slx-is-grid-toolbar">
  <div class="slx-is-grid-toolbar-container">
    <div class="slx-is-grid-toolbar-dropdown slx-is-grid-toolbar__section" *ngIf="stateService.selectedPositionType?.id === IdealScheduleConfigTypeDefinition.ShiftUnit">
      <slx-multiselect
        class="slx-wide slx-small-label"
        [options]="lookups.unitModels"
        [(ngModel)]="selectedUnits"
        (ngModelChange)="filtersChanged($event)"
        [externalPlaceholder]="true"
        placeholder="Unit(s):"
      ></slx-multiselect>
    </div>

    <div class="slx-is-grid-toolbar-dropdown slx-is-grid-toolbar__section">
      <slx-multiselect
        class="slx-wide slx-small-label"
        [options]="lookups.shiftModels"
        [(ngModel)]="selectedShifts"
        (ngModelChange)="filtersChanged($event)"
        [externalPlaceholder]="true"
        [readonly]="stateService.isShiftGroupEnabled"
        placeholder="Shift(s):"
      ></slx-multiselect>
    </div>

    <div class="slx-is-grid-toolbar-dropdown slx-is-grid-toolbar__section"
      *ngIf="showAcuitySelector">
      <slx-dropdown-list
        class="slx-wide slx-small-label"
        [lookup]="lookups.acuityLookup"
        [(ngModel)]="range.acuity.acuity"
        valueField="id" titleField="name"
        placeholder="Acuity Type:"
      ></slx-dropdown-list>
    </div>

    <div class="slx-is-grid-toolbar-number-container">
      <div class="slx-is-grid-toolbar-number slx-toolbar-section">
        <slx-input-decorator className="slx-white-background slx-toolbar-input {{ stateService.isRangeMinValueValid(range, rangeIndex) ? '' : 'slx-invalid' }}">
          <slx-kendo-number slx-input
            [(ngModel)]="range.acuity.minValue"
            (ngModelChange)="stateService.rangeChanged()"
            [decimals]="0"
            [step]="1"
            [min]="0"
              [max]="range.acuity.maxValue ? range.acuity.maxValue : maximumRangeValue"
            [autoCorrect]="true"
            name="rangeMinValue"
          ></slx-kendo-number>
        </slx-input-decorator>
      </div>

      <div class="slx-toolbar-section slx-is-grid-toolbar-number-text">
        to
      </div>

      <div class="slx-is-grid-toolbar-number slx-toolbar-section">
        <slx-input-decorator className="slx-white-background slx-toolbar-input {{ stateService.isRangeMaxValueValid(range, rangeIndex) ? '' : 'slx-invalid' }}">
          <slx-kendo-number slx-input
            [(ngModel)]="range.acuity.maxValue"
              (ngModelChange)="stateService.rangeChanged()"
            [decimals]="0"
            [step]="1"
              [min]="range.acuity.minValue ? range.acuity.minValue : 0"
            [autoCorrect]="true"
            name="rangeMaxValue"
          ></slx-kendo-number>
        </slx-input-decorator>
      </div>

      <div class="slx-is-grid-toolbar-error-text" *ngIf="stateService.isRangeValid(range, rangeIndex)">
        This value overlaps with an existing value
      </div>
    </div>

    <div class="slx-is-toolbar__section buttons-container">
      <span class="buttons-footer">
        <button type="button" class="theme-button-apply delete" (click)="onDelete()" *ngIf="canDelete">
          <i class="fas fa-trash-alt" aria-hidden="true"></i>
          Delete
        </button>

        <button type="button" class="theme-button-apply clone" (click)="onClone()">
          <i class="fas fa-clone" aria-hidden="true"></i>
          Clone
        </button>
      </span>
    </div>
  </div>
</div>
