import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { DailyData, WeeklyData, IDatedObject } from '../../models/index';
import { DayOfWeek, WeekDays } from '../../../common/models/index';

@Injectable()
export class CalendarDataService {

  public ExtendRangeToDailyData<T extends IDatedObject>(startOfRange: moment.Moment, dayCount: number, dataList: T[]): DailyData<T>[] {
    let mapByDay: _.Dictionary<T[]> = _.groupBy(dataList, (data: T) => {
      return moment(data.date).startOf('day').unix();
    });
    let days: DailyData<T>[] = [];
    let day: moment.Moment = startOfRange.clone();
    _.times(dayCount, () => {
      let dayDataList: T[] = mapByDay[day.unix()];
      let dailyData: DailyData<T> = new DailyData<T>();
      dailyData.startOfDay = day.clone();
      dailyData.dataList = dayDataList;
      dailyData.dayNumber = days.length;
      days.push(dailyData);
      day.add(1, 'day');
    });
    return days;
  }

  public ExtendRangeToWeeklyData<T extends IDatedObject>(startOfRange: moment.Moment, weekCount: number, dataList: T[]): WeeklyData<T>[] {
    let mapByWeek: _.Dictionary<T[]> = _.groupBy(dataList, (data: T) => {
      return moment(data.date).startOf('week').unix();
    });
    let weeks: WeeklyData<T>[] = [];
    let week: moment.Moment = startOfRange.startOf('week').clone();
    _.times(weekCount, () => {
      let weekDataList: T[] = mapByWeek[week.unix()];
      let weeklyData: WeeklyData<T> = new WeeklyData<T>();
      weeklyData.startOfWeek = week.clone();
      weeklyData.days = this.ExtendRangeToDailyData(week, 7, weekDataList);
      weeklyData.weekNumber = weeks.length;
      weeks.push(weeklyData);
      week.add(1, 'week');
    });
    return weeks;
  }

  public ExtendRangeToWeeklyDataByStartDate<T extends IDatedObject>(startOfRange: moment.Moment, weekCount: number, dataList: T[]): WeeklyData<T>[] {
    let mapByWeek: _.Dictionary<T[]> = _.groupBy(dataList, (data: T) => {
      return Math.floor(moment(data.date).diff(startOfRange, 'day') / 7);
    });
    let weeks: WeeklyData<T>[] = [];
    let week: moment.Moment = startOfRange.clone();
    _.times(weekCount, (num: number) => {
      let weekDataList: T[] = mapByWeek[num];
      let weeklyData: WeeklyData<T> = new WeeklyData<T>();
      weeklyData.startOfWeek = week;
      weeklyData.days = this.ExtendRangeToDailyData(week, 7, weekDataList);
      weeklyData.weekNumber = weeks.length;
      weeks.push(weeklyData);
      week.add(1, 'week');
    });
    return weeks;
  }

  public get getWeekDaysList(): DayOfWeek[] {
    return WeekDays;
  }
}
