import { EmployeeColumnsDefinition } from './employeeColumnsDefinition';

export class EmployeeActionDefinition extends EmployeeColumnsDefinition {
  public id: number;
  public type: 'send-sms' | 'assign-pay-policy' | 'assign-accruals-policy' | 'create-ess-policy' | 'create-pay-cycles' | 'schedule-for-inservice' | 'add-employee' | 'resend-opt-sms' | 'send-email-instructions' | 'mass-rate-adjustments' | 'mass-assign-slate-profile';
  public displayName: string;
  public get isMassRateAdjustments() {
    return this.type == 'mass-rate-adjustments';
  }
}

export interface IEmployeeActionDefinitions {
  canAdjustPayRate: boolean;
  canAddEmployee: boolean;
  actions: EmployeeActionDefinition[];
}

export class EmployeeActionDefinitions {
  public canAdjustPayRate: boolean;
  public canAddEmployee: boolean;
  public actions: EmployeeActionDefinition[] = [];
}
