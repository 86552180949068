import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { IOrgLevelDto, RoleDefinition, IRoleDefinition } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupMapService } from '../../../organization/services/lookup/lookup-map.service';
import { OrgLevelMapService } from '../../../organization/services/org-level/org-level-map.service';

import { RoleAssignementsWrapper, IUserRecordDTO, UserRecord, IUsersRolesDTO, UsersRoles,
        IUserRoleRelationDTO, UserRoleRelation } from '../../models/index';
import { UserProfileMapService } from './user-profile-map.service';

@Injectable()
export class UserRolesMapService {
    constructor(
      private lookupMapService: LookupMapService,
      private orgLevelMapService: OrgLevelMapService,
      private userProfileMapService: UserProfileMapService) { }

    public mapToRoleAsignementWrapper(roleDefinitionDTO: IRoleDefinition): RoleAssignementsWrapper {
        let roleDefinition: RoleDefinition = this.lookupMapService.mapRoleDefinition(roleDefinitionDTO);
        let wrapper: RoleAssignementsWrapper = new RoleAssignementsWrapper();
        wrapper.role = roleDefinition;
        wrapper.users = [];
        return wrapper;
    }

    public mapUserRecords(dtos: IUserRecordDTO[]): UserRecord[] {
        let users: UserRecord[] = [];
        _.each(dtos, (dto: IUserRecordDTO) => {
            let user: UserRecord = this.mapUserRecord(dto);
            users.push(user);
        });
        return users;
    }

    public mapUserRecord(dto: IUserRecordDTO): UserRecord {
        let userRecord: UserRecord = new UserRecord();
        userRecord.id = dto.id;
        userRecord.login = dto.login;
        userRecord.email = dto.email;
        userRecord.name = dto.name;
        userRecord.roleId = dto.roleId;
        let orgLevels: OrgLevel[] = [];
        _.each(dto.organizationLevels, (orgLevelDto: IOrgLevelDto) => {
            orgLevels.push(this.orgLevelMapService.mapToOrgLevel(dto));
        });
        userRecord.organizationLevels = orgLevels;
        return userRecord;
    }

  public mapUsersRolesRecord(dto: IUsersRolesDTO): UsersRoles {
    let userRecord: UsersRoles = new UsersRoles();

    let relations: UserRoleRelation[] = [];
    _.each(dto.relations, (profile: IUserRoleRelationDTO) => {
      relations.push(this.mapToUserRoleRelation(profile));
    });
    userRecord.relations = relations;

    userRecord.orgLevels = this.orgLevelMapService.mapToOrgLevels(dto.orgLevels);
    userRecord.roles = this.lookupMapService.mapRoleDefinitions(dto.roles);
    userRecord.userProfiles = this.userProfileMapService.mapUserProfiles(dto.userProfiles);

    return userRecord;
  }

  private mapToUserRoleRelation(dto: IUserRoleRelationDTO) : UserRoleRelation {
    let result: UserRoleRelation = new UserRoleRelation();
    result.userId = dto.userId;
    result.roleId = dto.roleId;
    result.orgLevelId = dto.orgLevelId;
    return result;
  }
}
