import { Component, EventEmitter, Output, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../core/decorators/index';
import { ValuePairChartDataService } from '../../services/value-pair-chart-data/value-pair-chart-data.service';
import { ValuePairWidgetConfig } from '../../models/value-pair-widget/value-pair-widget-config';
import { ValuePairChartModel } from '../../models/value-pair-chart/value-pair-chart-model';

@Component({
  moduleId: module.id,
  selector: 'slx-labeled-value-pair',
  templateUrl: 'labeled-value-pair.component.html',
  styleUrls: ['labeled-value-pair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabeledValuePairComponent implements OnInit, OnDestroy {

  @Output()
  public onClick1: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public onClick2: EventEmitter<any> = new EventEmitter<any>();

  public model: ValuePairChartModel;

  @unsubscribe()
  private modelLoaded: Subscription;

  constructor(private dataService: ValuePairChartDataService, private changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.modelLoaded = this.dataService.onModelLoaded.subscribe((model) => {
      this.model = model;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onClick1Handler(event: any): void {
    this.onClick1.emit(event);
  }

  public onClick2Handler(event: any): void {
    this.onClick2.emit(event);
  }
}
