var EmployeePunchRequest = /** @class */ (function () {
    function EmployeePunchRequest() {
    }
    Object.defineProperty(EmployeePunchRequest.prototype, "checkIsApproved", {
        get: function () {
            return this.isApproved == 0 ? 'N' : 'Y';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePunchRequest.prototype, "punchTypeName", {
        get: function () {
            return this.punchTypes == 0 ? 'IN' : 'OUT';
        },
        enumerable: true,
        configurable: true
    });
    return EmployeePunchRequest;
}());
export { EmployeePunchRequest };
