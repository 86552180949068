import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { KendoGridCustomSelectionHelper } from '../../../../common/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { LookupApiService } from '../../../../organization/services/index';
import { FormGroup } from '@angular/forms';
import { KendoGridStateHelper } from '../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { PositionsConfigurationApiService } from '../../../services/index';
var ConfigureCopyPositionsComponent = /** @class */ (function () {
    function ConfigureCopyPositionsComponent(api, lookup) {
        this.api = api;
        this.lookup = lookup;
        this.discardEmitter = new EventEmitter();
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
    }
    Object.defineProperty(ConfigureCopyPositionsComponent.prototype, "positions", {
        set: function (value) {
            this.m_positions = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigureCopyPositionsComponent.prototype, "organizations", {
        get: function () {
            return this.m_organizations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigureCopyPositionsComponent.prototype, "copyToDepartaments", {
        get: function () {
            return this.copyToCenter ? this.copyToCenter.departments : [];
        },
        enumerable: true,
        configurable: true
    });
    ConfigureCopyPositionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false,
            allowEdit: true,
            allowCopy: false,
            departmentLevel: false
        };
        this.gridStateSubscription = this.gridState.onRefreshGrid.subscribe(function (state) {
            _this.refreshGrid();
        });
        this.formSubscription = this.form.valueChanges.subscribe(function () {
            _this.checkActionsConditions();
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id === null)
                return;
            _this.currentOrgLevel = orgLevel;
            _this.copyFromDepartment = _this.currentOrgLevel.name;
            _this.lookup.getOrganizations(undefined, _this.currentOrgLevel.parentId).then(function (orgs) {
                if (orgs && orgs.length > 0) {
                    _this.copyFromCenter = orgs[0].name;
                }
                else {
                    _this.copyFromCenter = _this.currentOrgLevel.parentId.toString();
                }
            });
            _this.checkActionsConditions();
            _this.api.getCopyPositionsConfiguration(_this.currentOrgLevel.id).then(function (org) {
                _this.m_organizations = org;
                _this.copyToCenter = _.first(org);
                if (_this.copyToCenter) {
                    _this.copyToDepartment = _.first(_this.copyToCenter.departments);
                    if (_this.copyToDepartment)
                        _this.onTargetDepartmentChanged();
                }
            });
        });
        this.selectionHelperSubscription = this.selectionHelper.onSelectionChanged.subscribe(function () {
            if (_this.selectionHelper.selectionLength > 0) {
                _this.state.allowCopy = true;
            }
            else {
                _this.state.allowCopy = false;
            }
            _this.checkActionsConditions();
        });
    };
    ConfigureCopyPositionsComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    ConfigureCopyPositionsComponent.prototype.onDoCopy = function () {
        var _this = this;
        this.state.isLoading = true;
        var selectedForCopyItems = this.selectionHelper.selection;
        var ids = [];
        _.each(selectedForCopyItems, function (position) {
            ids.push(position.id);
        });
        this.api.copyPositions(this.currentOrgLevel.relatedItemId, this.copyToDepartment.id, ids)
            .then(function (respone) {
            _this.state.isLoading = false;
            _this.selectionHelper.clearSelection();
            _this.onTargetDepartmentChanged();
        })
            .catch(function (reason) {
            _this.state.isLoading = false;
        });
    };
    ConfigureCopyPositionsComponent.prototype.onDiscardCopy = function () {
        this.discardEmitter.emit();
    };
    ConfigureCopyPositionsComponent.prototype.onTargetCenterChanged = function () {
        this.copyToDepartment = _.first(this.copyToCenter.departments);
        this.onTargetDepartmentChanged();
    };
    ConfigureCopyPositionsComponent.prototype.onTargetDepartmentChanged = function () {
        var _this = this;
        if (!this.copyToDepartment || !this.copyToDepartment.orgLevelId) {
            return;
        }
        this.api.getPositions(this.copyToDepartment.orgLevelId).
            then(function (result) {
            _this.m_targetPositions = result.positions;
            _.each(_this.m_positions, function (item) {
                item.selectable = !_.some(_this.m_targetPositions, function (target) { return target.name === item.name; });
            });
        });
    };
    ConfigureCopyPositionsComponent.prototype.refreshGrid = function () {
        if (!this.m_positions) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_positions, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    ConfigureCopyPositionsComponent.prototype.checkActionsConditions = function () {
        var allowCopy = true;
        if (this.currentOrgLevel.type === OrgLevelType.department) {
            this.state.departmentLevel = true;
        }
        else {
            this.state.departmentLevel = false;
            allowCopy = false;
        }
        if (!this.form.valid) {
            allowCopy = false;
        }
        if (this.selectionHelper.selectionLength === 0) {
            allowCopy = false;
        }
        this.state.allowCopy = allowCopy;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ConfigureCopyPositionsComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureCopyPositionsComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureCopyPositionsComponent.prototype, "formSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureCopyPositionsComponent.prototype, "gridStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigureCopyPositionsComponent.prototype, "selectionHelperSubscription", void 0);
    return ConfigureCopyPositionsComponent;
}());
export { ConfigureCopyPositionsComponent };
