<kendo-grid #kendoGrid [data]="absences" 
    scrollable="'scrollable'" [pageable]="false"
    class="height600">

   <kendo-grid-column field="date" [width]="100">
        <ng-template kendoGridHeaderTemplate>
            Date
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{  dataItem.date | amDateFormat: appConfig.dateFormat }}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="sheduledHours" [width]="100">
        <ng-template kendoGridHeaderTemplate>
            Scheduled Hours
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.sheduledHours}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="absenseHours" [width]="100">
      <ng-template kendoGridHeaderTemplate>
          Absence Hours
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.absenseHours}}
      </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="code" [width]="70">
    <ng-template kendoGridHeaderTemplate>
        Code
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <div class="circle" [style.background-color]="dataItem.color | DecHex">
            <span class="code-span">{{firstSymbol(dataItem.code)}}</span>
        </div>
    </ng-template>
</kendo-grid-column>

<kendo-grid-column field="description" [width]="150">
  <ng-template kendoGridHeaderTemplate>
      Scheduled Description
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.description}}
  </ng-template>
</kendo-grid-column>

</kendo-grid>
