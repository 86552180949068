import { Component, Provider } from '@angular/core';
import { IDialog, DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../../app/common/models/index';
import { ModalService } from '../../../../../../app/common/services/modal/modal.service';
import { BenefitDetailsLineStandart } from '../../../../../app-modules/benefits/models';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../../../app/app.config';
import { dateTimeUtils } from '../../../../../common/utils/index';

@Component({
  selector: 'slx-benefit-details-expire-dialog',
  templateUrl: './benefit-details-expire-dialog.component.html',
  styleUrls: ['./benefit-details-expire-dialog.component.scss']
})
export class BenefitDetailsExpireDialogComponent implements IDialog {

  public dialogResult: boolean;
  public benefitDetailsLineEndDate: Date;
  public benefitDetailsLineNewEndDate: Date;
  public maxDate: Date = new Date(appConfig.maxCorrectDate);
  public appConfig: IApplicationConfig = appConfig;
  public oneDayValue = 60 * 60 * 24 * 1000;
  public maxValidDate: Date;
  public minValidDate: Date;

  public static openDialog(benefitDetailsLine: BenefitDetailsLineStandart, modalService: ModalService, callback?: (result: boolean) => void): BenefitDetailsExpireDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.dialog;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: BenefitDetailsLineStandart,
        useValue: benefitDetailsLine
      }
    ];

    return modalService.globalAnchor
      .openDialog2(BenefitDetailsExpireDialogComponent, 'Expire Benefit Plan', dialogOptions, resolvedProviders, (result: boolean) => {
        callback(result);
      });
  }

  constructor(private dialogOptions: DialogOptions, private modalService: ModalService, private benefitDetailsLine: BenefitDetailsLineStandart) {
    this.modalService = modalService;
    this.minValidDate = benefitDetailsLine ? benefitDetailsLine.line.startDate : null;
    this.benefitDetailsLineEndDate = benefitDetailsLine ? benefitDetailsLine.line.endDate : null;

    const newEndDate = new Date(dateTimeUtils.getTime(this.benefitDetailsLineEndDate) - this.oneDayValue);
    this.benefitDetailsLineNewEndDate = newEndDate >= this.maxDate ? this.maxDate : newEndDate;
    this.maxValidDate = _.clone(this.benefitDetailsLineNewEndDate);
  }

  public save(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

}
