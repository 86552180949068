export interface ICopyAccrualPolicy {
    header: string;
    currentPolicies: AccrualPolicyDetails[];
    orgLevels: AccrualOrgLevels[];
}

export class CopyAccrualPolicy {
    public header: string;
    public currentPolicies: AccrualPolicyDetails[];
    public orgLevels: AccrualOrgLevels[];
}

export interface ISaveCopyAccrualPolicy {
     selectedAccrualPolicies: AccrualPolicyDetails[];
     selectedOrgLevelIDs: AccrualOrgLevels[];
}

export class SaveCopyAccrualPolicy {
    public selectedAccrualPolicies: AccrualPolicyDetails[];
    public selectedOrgLevelIDs: AccrualOrgLevels[];
}

export class CopyAccrualResult {
    header: string;
    data: CopyAccrualPolicyResult[];
}

export interface ICopyAccrualPolicyResult {
    header: string;
    policyName: string;
    organizationName: string;
    statusMessage: string;
}

export class CopyAccrualPolicyResult {
    public header: string;
    public policyName: string;
    public organizationName: string;
    public statusMessage: string;
}

export class AccrualPolicyDetails {
    public id: number;
    public policyName: string;
    public orgLevelId: number;
    public isChecked: boolean;
}

export interface IAccrualOrgLevels {
    organizationId: number;
    organizationName: string;
    orgLevelId: string;
    isChecked: boolean; 
}

export class AccrualOrgLevels {
    public organizationId: number;
    public organizationName: string;
    public orgLevelId: string;
    public isChecked: boolean; 
}