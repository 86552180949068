import { TimeclockCommunicationApiService } from './../../../services/timeclock-communication/timeclock-communication-api.service';
import { TimeclockDefinition } from './../../../../../organization/models/lookup/timeclock-definition';
import { filterBy } from '@progress/kendo-data-query';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LookupType, Lookup } from '../../../../../organization/models/index';
import { LookupService } from '../../../../../organization/services/index';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { Component, Inject, EventEmitter, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-message',
  templateUrl: 'timeclock-message.component.html',
  styleUrls: ['timeclock-message.component.scss']
})
export class TimeclockMessageComponent implements OnInit {

    @Input()
    public set orgLevel(value: OrgLevel) {
        this.m_orgLevel = value;
        this.updateList();
    }

    public get orgLevel(): OrgLevel {
        return this.m_orgLevel;
    }

    public startDate: Date;
    public minDate: Date;
    public endDate: Date;
    public clocks: any[];
    public appConfig: IApplicationConfig;
    public isAllSelected: boolean;
    public messageText: string;
    public isLoading: boolean = false;

    private selectedRecords: TimeclockDefinition[];
    private lookupService: LookupService;
    private m_orgLevel: OrgLevel;
    private apiService: TimeclockCommunicationApiService;

    constructor(lookupService: LookupService, apiService: TimeclockCommunicationApiService) {
        this.lookupService = lookupService;
        this.apiService = apiService;
        this.updateList();
    }

    public get canSend(): boolean {
        return this.selectedRecords
            && this.selectedRecords.length > 0
            && this.messageText
            && this.messageText.trim().length > 0
            && this.startDate < this.endDate;
    }

    public get canStop(): boolean {
        return this.selectedRecords
            && this.selectedRecords.length > 0;
    }

    public ngOnInit(): void {
        this.appConfig = appConfig;
        this.minDate = new Date();
        this.startDate = new Date();
        this.endDate = moment().add(1, 'day').toDate();
    }

    public onToggleAllSelected(): void {
        _.forEach(this.clocks, (record: any) => {
            record['isSelected'] = this.isAllSelected;
        });
        this.selectionChange();
    }

    public selectionChange(): void {
        this.selectedRecords = [];
            this.selectedRecords = _.filter(this.clocks, (record: any) => {
                return Boolean(record['isSelected']) === true;
            });
        this.isAllSelected = this.selectedRecords.length === this.clocks.length;
    }

    public send(): void {
        this.isLoading = true;
        this.apiService.sendTimeclockTickerMessage(this.messageText, this.getSelectedIds(), this.startDate, this.endDate)
            .then((result: any)=> {
               this.isLoading = false;
               this.isAllSelected = false;
               this.selectedRecords = null;
               this.updateList();
            }).catch((error: any) => {
               this.isLoading = false;
            });
    }

    public stop(): void {
        this.isLoading = true;
        this.apiService.stopTimeclockTickerMessage(this.getSelectedIds())
            .then((result: any)=> {
               this.isLoading = false;
               this.isAllSelected = false;
               this.selectedRecords = null;
               this.updateList();
            }).catch((error: any) => {
               this.isLoading = false;
            });
    }

    private getSelectedIds(): number[] {
        return _.map(this.selectedRecords, (item: TimeclockDefinition) => item.id);
    }

    private updateList(): void {
        if (!this.orgLevel || !this.lookupService) return;
        this.isLoading = true;
        this.lookupService.getLookup({lookupType: LookupType.timeclockDefinition, orgLevelId: this.orgLevel.id, updateCacheForced: true }).then((lookup: Lookup) => {
            this.clocks = _.filter(lookup.items, (item: TimeclockDefinition)=> { return !item.isVirtual; });
            this.isLoading = false;
        }).catch((reason: any) => {
            this.clocks = [];
            this.isLoading = false;
        });
    }
}
