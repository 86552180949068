import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { PbjNewExportOptions } from '../../../models/index';
import { AppSettingsManageService } from '../../../../../app-settings/services';
import { unsubscribe } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { MealDeductionManagementService } from '../../../services/index';
var PbjNewExportDialog = /** @class */ (function () {
    function PbjNewExportDialog(pbjOptions, dialogOptions, modalService, appSettingsManageService, mealDeducitonManagement) {
        this.pbjOptions = pbjOptions;
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.appSettingsManageService = appSettingsManageService;
        this.mealDeducitonManagement = mealDeducitonManagement;
        this.dateRangeCorrect = true;
        this.isDisabledButton = false;
        this.m_canGenerate = false;
    }
    Object.defineProperty(PbjNewExportDialog.prototype, "canGenerate", {
        get: function () {
            return this.m_canGenerate;
        },
        set: function (v) {
            this.m_canGenerate = v;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjNewExportDialog.prototype, "showConfigWarning", {
        get: function () {
            return this.pbjOptions && this.pbjOptions.showConfigWarning;
        },
        enumerable: true,
        configurable: true
    });
    PbjNewExportDialog.openDialog = function (options, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.width = options.IsPBJExportCorpLevelEnabled ? 795 : 440;
        dialogOptions.height = 530;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.headerClassName = options.IsPBJExportCorpLevelEnabled ? 'setBlueBg' : '';
        var providers = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: PbjNewExportOptions,
                useValue: options
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(PbjNewExportDialog, options.title, dialogOptions, providers, function (result) {
            callback(result);
        });
        return dialog;
    };
    PbjNewExportDialog.prototype.ngOnInit = function () {
        var _this = this;
        this.appSettingsManageService.getAppServerConfig().then(function (config) {
            _this.IsPBJExportCorpLevelEnabled = config.IsPBJExportCorpLevelEnabled;
        });
        this.mealDeducionSettingsLoadSubscription = this.mealDeducitonManagement.onLoaded$.subscribe(function (s) {
            _this.canGeneratePBJFromAgency = s.generatePBJFromAgency;
        });
    };
    PbjNewExportDialog.prototype.onChangedExportRequest = function (req) {
        this.canGenerate = _.isObject(req);
        if (this.canGenerate) {
            this.request = req;
        }
        else {
            this.request = null;
        }
    };
    PbjNewExportDialog.prototype.getDateRangeError = function (data) {
        this.dateRangeCorrect = data[0];
        this.validateIsChecked(data[2].includeEmployee, data[2].includeStaffingHours);
    };
    PbjNewExportDialog.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    PbjNewExportDialog.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    PbjNewExportDialog.prototype.validateIsChecked = function (isEmployee, isStaffing) {
        this.isDisabledButton = !isEmployee && !isStaffing ? true : false;
        return this.isDisabledButton;
    };
    Object.defineProperty(PbjNewExportDialog.prototype, "selCheckbox", {
        get: function () {
            var selIds = sessionStorage.getItem('selIds');
            return (selIds != undefined && selIds.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjNewExportDialog.prototype, "mealDeducionSettingsLoadSubscription", void 0);
    return PbjNewExportDialog;
}());
export { PbjNewExportDialog };
