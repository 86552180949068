/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./org-level-search-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../org-level-search/org-level-search.component.ngfactory";
import * as i3 from "../org-level-search/org-level-search.component";
import * as i4 from "../../services/org-level/org-level-watch.service";
import * as i5 from "./org-level-search-dialog.component";
import * as i6 from "../../../common/models/dialog-options";
import * as i7 from "../../../common/services/modal/modal.service";
var styles_OrgLevelSearchDialogComponent = [i0.styles];
var RenderType_OrgLevelSearchDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrgLevelSearchDialogComponent, data: {} });
export { RenderType_OrgLevelSearchDialogComponent as RenderType_OrgLevelSearchDialogComponent };
export function View_OrgLevelSearchDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { orgLevelSearch: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-org-level-search", [], null, null, null, i2.View_OrgLevelSearchComponent_0, i2.RenderType_OrgLevelSearchComponent)), i1.ɵdid(4, 245760, [[1, 4], ["orgLevelSearch", 4]], 0, i3.OrgLevelSearchComponent, [i4.OrgLevelWatchService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedOrgLevel; _ck(_v, 8, 0, currVal_0); }); }
export function View_OrgLevelSearchDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-org-level-search-dialog", [], null, null, null, View_OrgLevelSearchDialogComponent_0, RenderType_OrgLevelSearchDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.OrgLevelSearchDialogComponent, [i6.DialogOptions, i7.ModalService], null, null)], null, null); }
var OrgLevelSearchDialogComponentNgFactory = i1.ɵccf("slx-org-level-search-dialog", i5.OrgLevelSearchDialogComponent, View_OrgLevelSearchDialogComponent_Host_0, {}, {}, []);
export { OrgLevelSearchDialogComponentNgFactory as OrgLevelSearchDialogComponentNgFactory };
