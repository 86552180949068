import * as moment from 'moment';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { EmployeeShortInfo } from '../../../../organization/models/index';
import { EmployeeSectionsBasicComponent } from '../index';
import { EmployeeSectionsTemporalModel, EmployeeSubSection, EmployeeSectionsBase } from '../../models/index';
import { ChangeManagementService } from '../../../../common/services/index';
import { EmployeeSectionBridgeService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-subsection-decorator',
  templateUrl: 'employee-subsection-decorator.component.html',
  styleUrls: ['employee-subsection-decorator.component.scss']
})
export class EmployeeSubSectionsDecoratorComponent implements OnInit, OnChanges {
  @Input()
  public subsection: EmployeeSubSection;
  @Input()
  public employeeShortInfo: EmployeeShortInfo;
  @Input() public employeeStatus: string;
  @Input() public employeeRehireDate: Date;
  @Input()
  public isEditableByConfiguration: boolean;
  @Input()
  public isTermHistoryEditEndabled: boolean;
  @Input()
  public isEditableWhenEmpTerminated: boolean;
  @Input()
  public isNotEditableWhenEmpFutureRehired: boolean;
  @Input()
  public canTerminateEmp: boolean = false;

  public effectiveDate: Date;
  public minEffectiveDate: Date;
  public temporalModel: EmployeeSectionsTemporalModel;
  public isResetEffectiveDate: boolean = false;
  public payRateDirtyState: boolean[] = [false, false];
  public dateError: boolean = false;
  @Input()
  public set isLoadingSection(loading: boolean) {
    if (loading) {
      this.startProgress();
    } else {
      this.stopProgress();
    }
  }

  public get hasActiveState(): boolean {
    return !this.employeeShortInfo.terminationDate;
  }

  public get isFutureRehired(): boolean {
    return this.employeeStatus ? this.employeeStatus.toLowerCase() === "future rehire" : false;
  }

  public get hasAccessToAdd(): boolean {
    this.updateModel();
    if (!this.subsectionModel) {
      return false;
    }
    return this.subsectionModel.actions ? this.subsectionModel.actions.canAdd : false;
  }

  public get hasAccessToDelete(): boolean {
    this.updateModel();
    if (!this.subsectionModel) {
      return false;
    }
    return this.subsectionModel.actions ? this.subsectionModel.actions.canDelete : false;
  }

  public get hasAccessToEdit(): boolean {
    this.updateModel();
    if (!this.subsectionModel) {
      return false;
    }
    return this.subsectionModel.actions ? this.subsectionModel.actions.canEdit : false;
  }

  public get isEditable(): boolean {
    if (!this.hasActiveState && this.isTermHistoryEditEndabled && this.isEditableWhenEmpTerminated)
      return true;
    if(this.isFutureRehired && this.isNotEditableWhenEmpFutureRehired)
      return false;
    return (this.hasActiveState || this.isEditableWhenEmpTerminated) && this.hasAccessToEdit && this.isEditableByConfiguration;
  }

  public get isSubsectionEditable(): boolean {
    if(this.isFutureRehired && this.isNotEditableWhenEmpFutureRehired)
      return false;
    if(this.hasActiveState || this.isEditableWhenEmpTerminated)
      return true;
  }

  protected subsectionModel: EmployeeSectionsBase;

  public get valid(): boolean {
    let formValid: boolean = this.subsectionComponent ? !this.subsectionComponent.form.invalid : false;
    return formValid;
  }

  public state: {
    isLoading: boolean;
    isLoaded: boolean;
    isEditMode: boolean;
  };

  private subsectionComponent: EmployeeSectionsBasicComponent;
  private isValidSelectedDate = true;

  constructor(private changeService: ChangeManagementService, private bridge: EmployeeSectionBridgeService) {
    this.state = {
      isLoading: false,
      isLoaded: false,
      isEditMode: false
    };
  }
  public ngOnInit(): void {
    //todo
  }

  public ngOnChanges(changes: SimpleChanges): void {
    //todo
  }

  public startProgress(): void {
    this.state.isLoading = true;
  }

  public stopProgress(): void {
    this.state.isLoading = false;
  }

  public onEdit(): void {
    this.state.isEditMode = true;
    this.setEditState(true);
  }

  public onSave(): void {
    this.subsectionComponent.canSave().then((canSave: boolean) => {
      if (canSave) {
        if (!this.temporalModel || !this.temporalModel.isTemporalDirty) {
          this.effectiveDate = null;
          this.isResetEffectiveDate = true;
          this.subsectionComponent.resetCurrentPayCycleWarning();
        }
        this.state.isEditMode = false;
        this.temporalModel = null;
        this.setEditState(false);
        this.subsectionComponent.Save(this.effectiveDate);
      }
    }).catch((error: any) => {
      throw error;
    });
  }

  public onCancel(): void {
    this.state.isEditMode = false;
    this.temporalModel = null;
    this.setEditState(true);
    this.isResetEffectiveDate = true;
    if (this.subsectionComponent)
      this.subsectionComponent.resetCurrentPayCycleWarning();
  }

  public registerSubsectionComponent(subsectionComponent: EmployeeSectionsBasicComponent): void {
    this.subsectionComponent = subsectionComponent;
  }

  public setTemporalModel(temporalModel: EmployeeSectionsTemporalModel): void {
    if (temporalModel && (temporalModel.isOnlyPayRateDirty || temporalModel.isNotOnlyPayRateDirty)) {
      let newPayRateDirtyState: boolean[] = [temporalModel.isOnlyPayRateDirty, temporalModel.isNotOnlyPayRateDirty]
      if (!_.isEqual(this.payRateDirtyState, newPayRateDirtyState)) {
        this.isResetEffectiveDate = true;
      }

      if (this.isResetEffectiveDate) {
        if (temporalModel.payrollEffectiveDateSetting) {
          if (!temporalModel.payrollEffectiveDateSetting.effectiveDateData) {
            if (this.subsectionComponent)
              this.subsectionComponent.showCurrentPayCycleWarning();
            this.effectiveDate = new Date();
          }
          else {
            this.effectiveDate = temporalModel.payrollEffectiveDateSetting.effectiveDateData;
          }
          this.isResetEffectiveDate = false;
          this.payRateDirtyState = newPayRateDirtyState;
        }
      }

    }
    else {
      if (!this.effectiveDate && this.isValidSelectedDate) {
        this.effectiveDate = moment().add(-2, 'days').toDate();
      }
      if (temporalModel && moment(temporalModel.minEffectiveData).isAfter(this.effectiveDate)) {
        this.effectiveDate = temporalModel.minEffectiveData;
      }
      if(this.isFutureRehired){
        this.minEffectiveDate = this.employeeRehireDate;
      }
      else{
        if (temporalModel && !moment(this.minEffectiveDate).isSame(temporalModel.minEffectiveData)) {
          this.minEffectiveDate = temporalModel.minEffectiveData;
        }
      }
    }    
    this.temporalModel = temporalModel;

  }

  public disabledDates = (date: Date): boolean => {
    return this.subsectionComponent.disabledDate(date);
  }

  public isCurrentPayCycle(): boolean {
    if (this.temporalModel && this.temporalModel.payrollEffectiveDateSetting) {
      if (this.temporalModel.payrollEffectiveDateSetting.effectiveDateData) {
        return true;
      }
    }
    return false;
  }


  public setChanges(): void {
    this.changeService.changeNotify(this.subsection.id);
  }

  public clearChanges(): void {
    this.changeService.clearChanges(this.subsection.id);
  }

  private setEditState(reload: boolean): void {
    if (this.subsectionComponent) {
      this.subsectionComponent.setEditState(this.state.isEditMode);
      this.bridge.notifyEditStateChange(this.state.isEditMode, this.subsection);
      if (reload) {
        this.subsectionComponent.load(true);
      }
    }
  }

  private updateModel(): void {
    this.subsectionModel = this.subsectionComponent ? this.subsectionComponent.getSubsectionModel() : null;
  }

  public onEffectiveDateChange(date: Date): void {
    const momentDate = moment(date, 'MM/DD/YYYY');
    this.isValidSelectedDate = momentDate.isValid()
    if (this.isValidSelectedDate && this.subsectionComponent) {
      this.dateError = !this.subsectionComponent.isValidEffectiveDate(date);
    }
    else {
      this.dateError = true;
    }
  }
}
