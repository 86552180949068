<div class="pbj-rec-toolbar">
  <div class="pbj-rec-toolbar__dates">
    <slx-daterange
      class="slx-width-320"
      name="dateRange"
      startDatePlaceholder="Reporting Period"
      endDatePlaceholder="To"
      [maxSecRange]="maxSecondsRange"
      [maxRangeErrorText]="maxRangeError"
      [disableFutureDates]="false"
      [hasApplyButton]="true"
      [startDate]="startDate"
      [endDate]="endDate"
      (rangeDateChanged)="onDateRangeChanged($event)"
    ></slx-daterange>
  </div>
  <div class="pbj-rec-toolbar__toggler">
    <slx-switcher [(ngModel)]="expanded" (ngModelChange)="onTogglerChanged($event)" label="Expanded details" labelOn="Yes" labelOff="No"></slx-switcher>
  </div>
  <div class="pbj-rec-toolbar__actions">
    <slx-action-list class="slx-wide">
      <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
      <popper-content #popperContent>
        <slx-action-list-item (onClick)="onExportToPdf()">Export to PDF</slx-action-list-item>
        <slx-action-list-item (onClick)="onExportToExcel()">Export to Excel</slx-action-list-item>
      </popper-content>
    </slx-action-list>
  </div>
</div>
