import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { IdealScheduleMapService } from "./ideal-schedule-map.service";
import { IdealScheduleStateService } from "./ideal-schedule.state.service";
import { CurrentOrganizationDetails, ExcelData, ImportFileDetails, TemplateValidator, UIException } from "../../models/ideal-schedule/ideal-schedule-import-template-validator";
import * as _ from "lodash";
import * as moment from "moment";
import { v4 as UUID } from 'uuid';
import { IdealScheduleApiService } from "./ideal-schedule-api.service";
import { ImportException } from "../../models/ideal-schedule/ideal-schedule-import-template";

@Injectable({
    providedIn: 'root'
})
export class IdealScheduleTemplateExcelValidationService {

    private expectedHeaders: string[] = [
        'Facility',
        'Department',
        'ConfigurationType',
        'CensusType',
        'Position',
        'AcuityTypes',
        'ShiftGroup',
        'Shift',
        'Unit',
        'CensusRangeFrom',
        'CensusRangeTo',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'];
    private dayOfWeek: string[] = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
    ]
    private censusRanges: string[] = [
        "Range",
        "Total"
    ]
    private validatorObj = {
        configurationType: [
            'Shift',
            'Shift & Unit',
            'Shift Group'
        ],
        censusType: [
            'Fixed',
            'Range',
            'Total'
        ]
    }

    private _excelDataSubscription: Subscription;
    public _excelData: TemplateValidator[] = [];
    private _exceptionListSubscription: Subscription;
    public _exceptionList: UIException[] = [];
    public _apiExceptionList: ImportException[] = [];
    public isFileExist: boolean = false;

    public _excelData$ = new Subject<ExcelData[]>();
    public _exceptionListTrigger$ = new Subject<any[]>();
    public _uiExceptionTrigger$ = new Subject<any[]>();
    public _apiExceptionTrigger$ = new Subject<any[]>();

    constructor(public mapService: IdealScheduleMapService,
        public stateService: IdealScheduleStateService, public api: IdealScheduleApiService) {

        this._excelDataSubscription = this._excelData$.subscribe((data: ExcelData[]) => {
            const currentOrganization = this.currentOrganizationDetails();
            this._excelData = this.mapService.mapExcelResultData(data[0].excelData);
            this.validateExcelData(data[0].excelHeader, currentOrganization, this._excelData);
        });

        this._exceptionListSubscription = this._exceptionListTrigger$.subscribe((data) => {
            this._exceptionList = data[0];
            this._uiExceptionTrigger$.next(this._exceptionList);
            if (this._exceptionList.length === 0) {
                this.stateService.state.isLoading = true;
                this.importDataValidation(this.stateService.orgLevelId, this._excelData);
            }
        });
    }

    private async importDataValidation(orgId, uploadedData) {
        await this.api.importTemplatePositionsValidation(orgId, uploadedData).then((data: ImportException[]) => {
            this._apiExceptionList = data;
            this.isFileExist = (this._exceptionList.length === 0 && this._apiExceptionList.length === 0) ? true : false;
            this._apiExceptionTrigger$.next(this._apiExceptionList);
        }).catch((err) => {
            this.isFileExist = false;
        });
    }

    private validateExcelData(headers: string[], orgDetail: CurrentOrganizationDetails, uploadedData: TemplateValidator[]): void {
        this._exceptionList = [];
        if (headers == undefined || !headers) {
            let obj = this.setException('ERR001', 'File Contain No Data');
            this._exceptionList.push(obj);
        }
        if (uploadedData.length === 0) {
            let obj = this.setException('ERR002', 'No Records Available');
            this._exceptionList.push(obj);
        }
        if (this.isArrayEqual(headers, this.expectedHeaders) || !this.isArrayEqual(headers, this.expectedHeaders, true)) {
            let obj = this.setException('ERR003', 'File Contain Incorrect Header Spelling or Header Order');
            this._exceptionList.push(obj);
        }``
        if (this._exceptionList.length === 0) {
            let fileData = trailingSpaceRemover(uploadedData);
            this.validateOrganizationAndDepartment(orgDetail, fileData);
            if (this._exceptionList.length === 0) {
                this.validateConfigTypeAndCensusType(uploadedData);
                if (this._exceptionList.length === 0) {
                    this.validateFields(uploadedData);
                }
            }
        }
        let isFileValid = this._exceptionList.length === 0 ? true : false;
        this._exceptionListTrigger$.next([this._exceptionList, isFileValid, uploadedData]);
    }

    private isArrayEqual(arr1: string[], arr2: string[], strictOrder = false): boolean {
        let trimHeader = stringTrailingSpaceRemover(arr1);
        if (strictOrder) {
            return trimHeader.join() === arr2.join();
        }
        else {
            let diff = _.difference(arr1, arr2);
            return diff.length > 0;
        }
    }

    private validateOrganizationAndDepartment(orgDetail: CurrentOrganizationDetails, uploadedData: TemplateValidator[]): UIException[] {
        if (!uploadedData) return;
        uploadedData.filter((x, i) => {
            if (!(orgDetail.organizationName == x.Facility)) {
                let ind = i + 2;
                let errorMessage = `Organization name mismatch - wrong data found on ${this.numberPower(ind)} Row`;
                let obj = this.setException('ERR004', errorMessage, x);
                this._exceptionList.push(obj);
            }
            if (!(orgDetail.departmentName == x.Department)) {
                let ind = i + 2;
                let errorMessage = `Department name mismatch - Wrong data found on ${this.numberPower(ind)} Row`;
                let obj = this.setException('ERR005', errorMessage, x);
                this._exceptionList.push(obj);
            }
        });
        return this._exceptionList;
    }

    private validateConfigTypeAndCensusType(uploadedData: TemplateValidator[]): UIException[] {
        if (!uploadedData) return;
        uploadedData.filter((x, i) => {
            if (!this.validatorObj.censusType.includes(x.CensusType)) {
                let ind = i + 2;
                let errorMessage = `Wrong Census Range Type found on ${this.numberPower(ind)} Row`;
                let obj = this.setException('ERR006', errorMessage, x);
                this._exceptionList.push(obj);
            }
            if (!this.validatorObj.configurationType.includes(x.ConfigurationType)) {
                let ind = i + 2;
                let errorMessage = `Wrong Configuration Type found on ${this.numberPower(ind)} Row`;
                let obj = this.setException('ERR006', errorMessage, x);
                this._exceptionList.push(obj);
            }
        })
        return this._exceptionList;
    }

    private validateFields(uploadedData) {
        if (this._exceptionList.length === 0) {
            this.validateDayOfTheWeekColumn(uploadedData);
            this.validateCensusRanges(uploadedData);
        }
    }

    private validateDayOfTheWeekColumn(uploadedData: TemplateValidator[]): UIException[] {
        if (!uploadedData) return;
        uploadedData.filter((x, i) => {
            for (const item in x) {
                if (this.dayOfWeek.includes(item)) {
                    if (_.isUndefined(x[item])) {
                        let ind = i + 2;
                        let errorMessage = `Empty Cells found on ${item} Column ${this.numberPower(ind)} Row`;
                        let obj = this.setException('ERR007', errorMessage, x);
                        this._exceptionList.push(obj);
                    }
                    if (_.isString(x[item]) || !Number.isInteger(x[item])) {
                        let ind = i + 2;
                        let errorMessage = `Days of the week column should only contain any numerical characters found on ${item} Column ${this.numberPower(ind)} Row`;
                        let obj = this.setException('ERR008', errorMessage, x);
                        this._exceptionList.push(obj);
                    }
                    if (_.gt(x[item], 99)) {
                        let ind = i + 2;
                        let errorMessage = `Day of the week cell value should be less than or equal to 99 ${this.numberPower(ind)} Row`;
                        let obj = this.setException('ERR009', errorMessage, x);
                        this._exceptionList.push(obj);
                    }
                    if (_.lt(x[item], 0)) {
                        let ind = i + 2;
                        let errorMessage = `Day of the week cell value should be greater than or equal to 0 ${this.numberPower(ind)} Row`;
                        let obj = this.setException('ERR009', errorMessage, x);
                        this._exceptionList.push(obj);
                    }
                }
            }
        })
        return this._exceptionList;
    }

    private validateCensusRanges(uploadedData: TemplateValidator[]): UIException[] {
        if (!uploadedData) return;
        uploadedData.filter((x, i) => {
            if (this.censusRanges.includes(x.CensusType)) {
                if (_.isUndefined(x.CensusRangeFrom)) {
                    let ind = i + 2;
                    let errorMessage = `Empty Cells found on CensusRangeFrom Column ${this.numberPower(ind)} Row`;
                    let obj = this.setException('ERR007', errorMessage, x);
                    this._exceptionList.push(obj);
                }
                if (_.isUndefined(x.CensusRangeTo)) {
                    let ind = i + 2;
                    let errorMessage = `Empty Cells found on CensusRangeTo Column ${this.numberPower(ind)} Row`;
                    let obj = this.setException('ERR007', errorMessage, x);
                    this._exceptionList.push(obj);
                }
                if (_.isString(x.CensusRangeFrom) || !Number.isInteger(x.CensusRangeFrom)) {
                    let ind = i + 2;
                    let errorMessage = `CensusRangesFrom column should only contain any numerical characters ${this.numberPower(ind)} Row`;
                    let obj = this.setException('ERR008', errorMessage, x);
                    this._exceptionList.push(obj);
                }
                if (_.isString(x.CensusRangeTo) || !Number.isInteger(x.CensusRangeTo)) {
                    let ind = i + 2;
                    let errorMessage = `CensusRangesTo column should only contain any numerical characters ${this.numberPower(ind)} Row`;
                    let obj = this.setException('ERR008', errorMessage, x);
                    this._exceptionList.push(obj);
                }
                if (_.gt(x.CensusRangeTo, 999)) {
                    let ind = i + 2;
                    let errorMessage = `CensusRangeTo Column cell value should be less than or equal to 999 ${this.numberPower(ind)} Row`;
                    let obj = this.setException('ERR009', errorMessage, x);
                    this._exceptionList.push(obj);
                }
                if (_.lt(x.CensusRangeFrom, 0)) {
                    let ind = i + 2;
                    let errorMessage = `CensusRangeFrom Column cell value should be greater than or equal to 0 ${this.numberPower(ind)} Row`;
                    let obj = this.setException('ERR009', errorMessage, x);
                    this._exceptionList.push(obj);
                }
            }
        });
        return this._exceptionList;
    }

    private numberPower(index: number): string {
        return moment.localeData().ordinal(index);
    }

    private setException(errorCode: string, errorMessage: string, item?: TemplateValidator): UIException {
        const data: UIException = new UIException();
        data.id = item ? item.UniqueId : UUID();
        data.errorCode = errorCode;
        data.errorMessage = errorMessage;
        return data;
    }

    private currentOrganizationDetails(): CurrentOrganizationDetails {
        const organizationData = JSON.parse(sessionStorage.getItem('Organizations'));
        let childData = _.filter(organizationData, (x) => x.id == this.stateService.orgLevelId);
        let parentOrganization = _.filter(this.stateService.organizationDetails, (x) => x.organizationId == childData[0].organizationId);
        const data: CurrentOrganizationDetails = new CurrentOrganizationDetails();
        data.departmentId = childData[0].id;
        data.departmentName = childData[0].name;
        data.organizationId = +parentOrganization[0].orgLevelId;
        data.organizationName = parentOrganization[0].organizationName;
        return data;
    }
}

function trailingSpaceRemover(data: any[]) {
    let result = [];
    data.map((x) => {
        let newObj = {};
        Object.keys(x).forEach(y => {
            let key = y.trim();
            newObj[key] = x[y];
        })
        result.push(newObj);
    })
    return result;
}

function stringTrailingSpaceRemover(headers: string[]) {
    return headers.map(x => _.isNaN(x) ? x.trim() : x);
}