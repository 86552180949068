import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { WindowRef } from '../../../core/services/window/window-ref.model';

@Injectable()
export class LogiMessagingServices {
  public logiLoaded$: ReplaySubject<null>;
  public userAction$: Subject<string>;
  private supportedEvents: StringMap<string>;
  private expectedOrigin: string;
  private logiUrl: string;

  constructor(private winRef: WindowRef) { }

  public initialize(): void {
    this.assignDefaults();
  }

  public setLogiUrl(logiUrl: string): void {
    this.logiUrl = logiUrl;
    this.defineExpectedOrigin();
    this.subscribeToMessages();
  }

  public destroy(): void {
    this.logiLoaded$.complete();
    this.userAction$.complete();
  }

  public subscribeToLoaded(callback: () => void): Subscription {
    if (!_.isFunction(callback)) throw new TypeError('Can\'t subscribe, callback argument is not a function');

    return this.logiLoaded$.subscribe(callback);
  }

  public subscribeToUserAction(callback: (type: string) => void): Subscription {
    if (!_.isFunction(callback)) throw new TypeError('Can\'t subscribe, callback argument is not a function');

    return this.userAction$.subscribe(callback);
  }

  private subscribeToMessages(): void {
    // Renderer2 doesn't work with such event type
    this.winRef.nativeWindow.addEventListener('message', (event: MessageEvent) => {
      if (event.origin !== this.expectedOrigin || !_.isString(event.data)) return;

      const type: string = event.data;
      switch (type) {
        case this.supportedEvents.loaded:
          this.logiLoaded$.next(null);
          break;
        case this.supportedEvents.click:
          this.userAction$.next(this.supportedEvents.click);
          break;
        case this.supportedEvents.mousemove:
          // For now don't reacting onmousemove
          //this.userAction$.next(this.supportedEvents.mousemove);
          break;
        default:
          console.error('Catched message from Logi: event type is not supported', type);
      }
    }, false);
  }

  private defineExpectedOrigin(): void {
    let expectedOrigin = '';
    let hostname = '';
    const url = this.logiUrl;
    const startOfHost = '://';
    const index = url.indexOf(startOfHost);
    if (index > -1) {
      hostname = url.slice(index + startOfHost.length);
      expectedOrigin = `${url.slice(0, index)}${startOfHost}${_.head(hostname.split('/'))}`;
    } else {
      expectedOrigin = url;
    }
    this.expectedOrigin = expectedOrigin;
  }

  private assignDefaults(): void {
    this.logiLoaded$ = new ReplaySubject<null>(1);
    this.userAction$ = new Subject<string>();
    this.supportedEvents = {
      loaded: 'loaded',
      click: 'click',
      mousemove: 'mousemove',
    };
  }
}
