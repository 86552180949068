import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';
import { Observable } from 'rxjs/Observable';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { EssTemplateApiService } from '../../../app-modules/ess-templates/services/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { PositionsConfigurationApiService } from './positions-configuration-api.service';
import { PositionsContainer, RemovePositionRequest } from '../../models/index';
var PositionsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PositionsManagementService, _super);
    function PositionsManagementService(access, changeService, api, lookup, essTemplateApiService, appSettingsService) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.lookup = lookup;
        _this.essTemplateApiService = essTemplateApiService;
        _this.appSettingsService = appSettingsService;
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.onItemSaved$ = new ReplaySubject();
        _this.dataChanged$ = new Subject();
        _this.viewRefresh$ = new Subject();
        return _this;
    }
    Object.defineProperty(PositionsManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    PositionsManagementService.prototype.init = function () {
        var _this = this;
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = false;
        this.access.allowDepartmentLevel = true;
        this.appSettingsService.getAppServerConfig()
            .then(function (settings) {
            _this.essTemplatesEnabled = settings.essTemplatesEnabled;
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.essTemplates = undefined;
            if (_.isNumber(orgLevel.id)) {
                _this.currentOrgLevel = orgLevel;
                _this.access.orgLevelType = _this.currentOrgLevel.type;
                _this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
                _this.fetchRecords();
            }
        });
    };
    PositionsManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    PositionsManagementService.prototype.getEssTemplateDefinitions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                if (this.essTemplates) {
                    return [2 /*return*/, Promise.resolve(this.essTemplates)];
                }
                return [2 /*return*/, this.essTemplateApiService.getTemplateDefinitions(this.currentOrgLevel.id)
                        .then(function (templates) {
                        _this.essTemplates = templates;
                        return _this.essTemplates;
                    })];
            });
        });
    };
    PositionsManagementService.prototype.openCopyItems = function () {
        this.onStateChanged$.next({ configureMode: false, copyMode: true });
    };
    PositionsManagementService.prototype.closeCopyItems = function () {
        this.onStateChanged$.next({ configureMode: true, copyMode: false });
    };
    PositionsManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    PositionsManagementService.prototype.onAddItem = function (item) {
        this.markAsDirty();
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    };
    PositionsManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    PositionsManagementService.prototype.onCancelEditItem = function () {
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    };
    PositionsManagementService.prototype.onRemoveItem = function (itemToDelete) {
        var request = new RemovePositionRequest();
        request.itemToDelete = itemToDelete;
        if (itemToDelete.employeesCount > 0) {
            request.requireReassign = true;
            request.orgLevelId = this.currentOrgLevel.id;
            request.itemIdToDelete = itemToDelete.id;
            request.ressignedPositionId = -1;
            request.effectiveDate = undefined;
            this.removeItemsCmd$.next(request);
        }
        else {
            request.requireReassign = false;
            this.removeItemsCmd$.next(request);
        }
    };
    PositionsManagementService.prototype.doRemoveItem = function (item, reassignToPosition, date) {
        var _this = this;
        this.api.deletePosition(this.currentOrgLevel.id, item, reassignToPosition, date)
            .then(function (items) {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PositionsManagementService.prototype.onSaveItem = function (info) {
        if (info.isNew) {
            this.addItem(info.dataItem);
        }
        else {
            this.updateItem(info.dataItem);
        }
    };
    PositionsManagementService.prototype.loadPositions = function () {
        this.fetchRecords();
    };
    PositionsManagementService.prototype.addItem = function (item) {
        var _this = this;
        _.each(this.m_container.records, function (p) {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.addPosition(this.currentOrgLevel.id, item)
            .then(function (items) {
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PositionsManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.savePosition(this.currentOrgLevel.id, item)
            .then(function (savedItem) {
            _this.changeService.clearChanges();
            _this.onItemSaved$.next(_this.editingItem);
            _this.updatePositionModel(_this.editingItem, savedItem);
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PositionsManagementService.prototype.updatePositionModel = function (modelToUpdate, position) {
        modelToUpdate.accrualPolicy = position.accrualPolicy;
        modelToUpdate.additionalInfo = position.additionalInfo;
        modelToUpdate.budgetedPosition = position.budgetedPosition;
        modelToUpdate.businessUnit = position.businessUnit;
        modelToUpdate.clientDepartment = position.clientDepartment;
        modelToUpdate.defaultShiftHours = position.defaultShiftHours;
        modelToUpdate.department = position.department;
        modelToUpdate.employeesCount = position.employeesCount;
        modelToUpdate.groupedWith = position.groupedWith;
        modelToUpdate.hourlyRate = position.hourlyRate;
        modelToUpdate.isDirectCare = position.isDirectCare;
        modelToUpdate.isPrimary = position.isPrimary;
        modelToUpdate.jobClass = position.jobClass;
        modelToUpdate.jobListOrder = position.jobListOrder;
        modelToUpdate.lastUpdateDate = position.lastUpdateDate;
        modelToUpdate.lastUpdateUsername = position.lastUpdateUsername;
        modelToUpdate.legalId = position.legalId;
        modelToUpdate.name = position.name;
        modelToUpdate.objectAccount = position.objectAccount;
        modelToUpdate.organization = position.organization;
        modelToUpdate.payPolicy = position.payPolicy;
        modelToUpdate.pbjCmsPosition = position.pbjCmsPosition;
        modelToUpdate.selectable = position.selectable;
        modelToUpdate.shiftDifferentialPolicy = position.shiftDifferentialPolicy;
        modelToUpdate.positionGroup = position.positionGroup;
        modelToUpdate.positionGroupId = position.positionGroupId;
        modelToUpdate.replacesPositionsIds = position.replacesPositionsIds;
        modelToUpdate.supervisorOrganization = position.supervisorOrganization;
        modelToUpdate.supervisorDepartment = position.supervisorDepartment;
        modelToUpdate.supervisorJobCode = position.supervisorJobCode;
    };
    PositionsManagementService.prototype.fetchRecords = function () {
        var _this = this;
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getPositions(this.currentOrgLevel.id).
            then(function (result) {
            _this.changeService.clearChanges();
            _this.m_container = new PositionsContainer();
            _this.m_container.records = result.positions;
            _this.access.actions = result.actions;
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.canManageGroups = result.actions.canManageGroups;
            _this.onLoaded$.next(_this.m_container);
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PositionsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PositionsManagementService.prototype, "orgLevelSubscription", void 0);
    return PositionsManagementService;
}(ManagementBaseService));
export { PositionsManagementService };
