import * as tslib_1 from "tslib";
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/sample';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/zip';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SessionActions } from '../../authentication/actions/index';
import { MasterScheduleActions } from '../store/master-schedule/master-schedule.actions';
import { LookupApiService, ScheduleCycleHelperService } from '../../organization/services/index';
import { ScheduleApiService } from '../services/index';
import { OrgLevelType } from '../../state-model/models/index';
import { ScheduleTotalSummary } from '../models/index';
import { mutableSelect } from '../../core/decorators/index';
var MasterScheduleEpics = /** @class */ (function () {
    function MasterScheduleEpics(scheduleApiService, lookupApiService, scheduleCycleHelperService) {
        var _this = this;
        this.scheduleApiService = scheduleApiService;
        this.lookupApiService = lookupApiService;
        this.scheduleCycleHelperService = scheduleCycleHelperService;
        this.entriesChange = function (action$) {
            var entriesChange$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGE;
            });
            return entriesChange$.
                mergeMap(function (action) {
                var om = [];
                _.forEach(action.payload.entries, function (entry) {
                    om.push(_this.updateMasterSchedule(action.payload.orgLevelId, entry));
                });
                return Observable.fromPromise(Promise.all(om)).map(function (result) { return ({
                    type: MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED,
                    payload: result
                }); })
                    .catch(function (error) { return Observable.of({ type: MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED_ERROR, payload: { hasError: true, errorMessage: error.message } }); });
            });
        };
        this.fetchMasterScheduleData = function (action$) {
            var fetchEmployeesSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA;
            });
            return fetchEmployeesSchedule$
                .combineLatest(_this.orgLevel$.filter(function (o) { return o.type === OrgLevelType.department; }), _this.filters$)
                .sample(fetchEmployeesSchedule$)
                .mergeMap(function (_a) {
                var orgLevel = _a[1], filters = _a[2];
                return Observable
                    .fromPromise(Promise.all([
                    _this.scheduleApiService.getEmployeesSchedule(orgLevel, filters),
                    _this.scheduleApiService.getTotals(orgLevel, filters)
                ]))
                    .map(function (_a) {
                    var container = _a[0], totals = _a[1];
                    return ({
                        type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS,
                        payload: {
                            actions: container.actions,
                            employees: container.definitions,
                            totals: totals
                        }
                    });
                })
                    .catch(function (error) { return Observable.of({
                    type: MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR,
                    payload: { hasError: true, errorMessage: error.message }
                }); });
            });
        };
        this.fetchEmployeesSchedule = function (action$) {
            var fetchEmployeesSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE;
            });
            return fetchEmployeesSchedule$
                .combineLatest(_this.orgLevel$.filter(function (o) { return o.type === OrgLevelType.department; }), _this.filters$)
                .sample(fetchEmployeesSchedule$)
                .mergeMap(function (_a) {
                var action = _a[0], orgLevel = _a[1], filters = _a[2];
                return Observable
                    .fromPromise(_this.scheduleApiService.getEmployeesSchedule(orgLevel, filters))
                    .map(function (result) { return ({
                    type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS,
                    payload: {
                        actions: result.actions,
                        employees: result.definitions
                    }
                }); })
                    .catch(function (error) { return Observable.of({ type: MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR, payload: { hasError: true, errorMessage: error.message } }); });
            });
        };
        this.fetchTotals = function (action$) {
            var fetchTotals$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED ||
                    type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS ||
                    type === MasterScheduleActions.FETCH_TOTALS;
            });
            return fetchTotals$
                .combineLatest(_this.orgLevel$.filter(function (o) { return o.type === OrgLevelType.department; }), _this.filters$)
                .sample(fetchTotals$)
                .mergeMap(function (_a) {
                var action = _a[0], orgLevel = _a[1], filters = _a[2];
                if (action.type === MasterScheduleActions.SCHEDULE_ENTRIES_CHANGED) {
                    var rows = action.payload;
                    var dateFirst_1 = null;
                    var dateEnd_1 = null;
                    _.forEach(rows, function (row) {
                        _.forEach(row.entries, function (entry) {
                            if (!dateFirst_1 || dateFirst_1.isAfter(entry.dateOn)) {
                                dateFirst_1 = moment(entry.dateOn);
                            }
                            if (!dateEnd_1 || dateEnd_1.isBefore(entry.dateOn)) {
                                dateEnd_1 = moment(entry.dateOn);
                            }
                        });
                    });
                    filters.dateFrom = dateFirst_1.toDate();
                    filters.weekNumber = _this.scheduleCycleHelperService.calcWeeks(dateFirst_1, dateEnd_1);
                }
                return Observable
                    .fromPromise(_this.scheduleApiService.getTotals(orgLevel, filters))
                    .map(function (result) { return ({
                    type: MasterScheduleActions.FETCH_TOTALS_SUCCESS,
                    payload: result
                }); })
                    .catch(function (error) { return Observable.of({ type: MasterScheduleActions.FETCH_TOTALS_ERROR, payload: { hasError: true, errorMessage: error.message } }); });
            });
        };
        this.resetPayCycle = function (action$) {
            var resetPayCycle$ = action$.filter(function (_a) {
                var type = _a.type;
                return type === SessionActions.CLEAR_SESSION;
            });
            return resetPayCycle$.mergeMap(function (action) {
                return Observable.of({
                    type: MasterScheduleActions.MASTER_SCHEDULE_CLEAR_PAYCYCLE,
                    payload: null
                });
            });
        };
        this.completeDataLoading = function (action$) {
            var fetchMasterScheduleData$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS ||
                    type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR;
            });
            var fetchEmployeesSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS ||
                    type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR;
            });
            var generateSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.GENERATE_SCHEDULE_ERROR ||
                    type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS;
            });
            var deleteEmpSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.DELETE_EMP_SCHEDULE_ERROR ||
                    type === MasterScheduleActions.DELETE_EMP_SCHEDULE_SUCCESS;
            });
            var createEmpRotFromSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_ERROR ||
                    type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS;
            });
            return fetchEmployeesSchedule$
                .merge(fetchMasterScheduleData$)
                .merge(deleteEmpSchedule$)
                .merge(createEmpRotFromSchedule$)
                .merge(generateSchedule$).map(function () { return ({
                type: MasterScheduleActions.COMPLETE_DATA_LOADING
            }); });
        };
        this.completeLoading = function (action$) {
            var fetchMasterScheduleData$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_SUCCESS ||
                    type === MasterScheduleActions.FETCH_MASTER_SCHEDULE_DATA_ERROR;
            });
            var fetchTotals$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.FETCH_TOTALS_SUCCESS ||
                    type === MasterScheduleActions.FETCH_TOTALS_ERROR;
            });
            var fetchEmployeesSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_SUCCESS ||
                    type === MasterScheduleActions.FETCH_EMPLOYEES_SCHEDULE_ERROR;
            });
            var generateSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.GENERATE_SCHEDULE_ERROR ||
                    type === MasterScheduleActions.GENERATE_SCHEDULE_SUCCESS;
            });
            var deleteEmpSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.DELETE_EMP_SCHEDULE_ERROR ||
                    type === MasterScheduleActions.DELETE_EMP_SCHEDULE_SUCCESS;
            });
            var createEmpRotFromSchedule$ = action$
                .filter(function (_a) {
                var type = _a.type;
                return type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_ERROR ||
                    type === MasterScheduleActions.CREATE_EMP_ROT_FROM_SCHEDULE_SUCCESS;
            });
            return Observable
                .zip(fetchTotals$, fetchEmployeesSchedule$)
                .merge(fetchMasterScheduleData$)
                .merge(deleteEmpSchedule$)
                .merge(createEmpRotFromSchedule$)
                .merge(generateSchedule$).map(function () { return ({
                type: MasterScheduleActions.COMPLETE_LOADING
            }); });
        };
    }
    MasterScheduleEpics.prototype.updateMasterSchedule = function (orgLevelId, entry) {
        var _this = this;
        var p = this.scheduleCycleHelperService.getScheduleCycleByDate(entry.date, orgLevelId)
            .then(function (value) {
            if (!value) {
                return null;
            }
            return _this.scheduleApiService.getEmployeeSchedule(orgLevelId, entry.employeeId, value.startDate.toDate(), value.endDate.toDate())
                .then(function (val) {
                return val;
            });
        });
        return p;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleEpics.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['masterSchedule', 'filters']),
        tslib_1.__metadata("design:type", Observable)
    ], MasterScheduleEpics.prototype, "filters$", void 0);
    return MasterScheduleEpics;
}());
export { MasterScheduleEpics };
