import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService, AttachmentsUploaderHelperService } from '../../../../common/services/index';
import { WCReportMapService } from './wc-report-map.service';
import { WcEmployeeInfo, IWcEmployeeInfoDto, WcReport, IWcReportDto, AttachmentDefinition, WcCodeDefinition, IWcCodeDefinitionDto, IWcWorkersCompensationCaseDto, WcWorkersCompensationCase, WcMedicalTreatmentDefinition, IWcMedicalTreatmentDefinitionDto, IWcReportStatusDefinitionDto, WcReportStatusDefinition } from '../../models/index';

import { appConfig } from '../../../../app.config';
import { wcConfig } from '../../workers-compensation.config';
import { Meta, FieldsMeta } from '../../../../core/models';
import { ReadFile } from '../../../../organization/models/index';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';

@Injectable()
export class WCReportApiService {
    constructor (
        private apiUtilService: ApiUtilService,
        private mapService: WCReportMapService,
        private urlParamsService: UrlParamsService,
        private uploaderService: AttachmentsUploaderHelperService
    ) {
    }

      public async getWcEmployeeInfo(empId: number): Promise<WcEmployeeInfo> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.employee}`;
        const params = {
            empId: empId
        };
        const request = this.urlParamsService.createGetRequest(url, params);

        const response = await this.apiUtilService
            .request<IWcEmployeeInfoDto, FieldsMeta>(request);
        return this.mapService.mapToEmployeeInfo(response.data, response.meta);
    }

    public async getReport(reportId: number): Promise<WcReport> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.report}`;
        const params = {
            reportId: reportId
        };
        const request = this.urlParamsService.createGetRequest(url, params);

        const response = await this.apiUtilService
            .request<IWcReportDto, FieldsMeta>(request);
        return this.mapService.mapToWcReport(response.data, response.meta);
    }

    public async createReport(report: WcReport): Promise<WcReport> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.report}`;
        const dto = this.mapService.mapToWcReportDto(report);
        const request = this.urlParamsService.createPostRequest(url, dto);
        const response = await this.apiUtilService
            .request<IWcReportDto, Meta>(request);
        return this.mapService.mapToWcReport(response.data);
    }

    public async updateReport(report: WcReport): Promise<WcReport> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.report}`;
        const dto = this.mapService.mapToWcReportDto(report);
        const request = this.urlParamsService.createPutRequest(url, dto);
        const response = await this.apiUtilService
            .request<IWcReportDto, Meta>(request);
        return this.mapService.mapToWcReport(response.data);
    }

    public async deleteReport(reportId: number): Promise<void> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.report}`;
        const params = {
            reportId: reportId
        };
        const request = this.urlParamsService.createDeleteRequest(url, params);

        await this.apiUtilService.request<any, Meta>(request);
    }

    public async downloadAttachment(documentId: number): Promise<FileBlobResponse> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.report}/${wcConfig.api.wc.attachment}`;
        const params = {
            documentId: documentId
        };
        const request = this.urlParamsService.createGetRequest(url, params);
        let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
            .then((file: FileBlobResponse) => file);
        return promise;
    }

    public async createDocument(reportId: number, files: ReadFile[]): Promise<WcReport> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.report}/${wcConfig.api.wc.attachment}`;

        return this.uploaderService.uploadAttachmentsAsFormData<IWcReportDto, Meta>(url, files, { reportId })
          .then((response) => this.mapService.mapToWcReport(response.data));
    }

    public async deleteDocument(reportId: number, documentIds: number[]): Promise<WcReport> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.report}/${wcConfig.api.wc.attachment}`;
        const params = {
            reportId: reportId,
            documentIds: documentIds
        };
        const request = this.urlParamsService.createDeleteRequest(url, params);

        const response = await this.apiUtilService
            .request<IWcReportDto, Meta>(request);
        return this.mapService.mapToWcReport(response.data);
    }

    public async getWcCodes(type: string): Promise<WcCodeDefinition[]> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.codes}`;
        const params = {
            type: type
        };
        const request = this.urlParamsService.createGetRequest(url, params);

        const response = await this.apiUtilService
            .request<IWcCodeDefinitionDto[], Meta>(request);
        return this.mapService.mapToWcCodeDefinitions(response.data);
    }

    public async getWcCompCases(): Promise<WcWorkersCompensationCase[]> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.compCases}`;
        const request = this.urlParamsService.createGetRequest(url);
        const response = await this.apiUtilService
            .request<IWcWorkersCompensationCaseDto[], Meta>(request);
        return this.mapService.mapToWorkesComCases(response.data);
    }

    public async getTreatments(): Promise<WcMedicalTreatmentDefinition[]> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.medTreatments}`;
        const request = this.urlParamsService.createGetRequest(url);
        const response = await this.apiUtilService
            .request<IWcMedicalTreatmentDefinitionDto[], Meta>(request);
        return this.mapService.maptoMedicalTreatments(response.data);
    }

    public async getReportStatuses(): Promise<WcReportStatusDefinition[]> {
        const url: string = `${this.getWCApi()}/${wcConfig.api.wc.reportStatuses}`;
        const request = this.urlParamsService.createGetRequest(url);
        const response = await this.apiUtilService
            .request<IWcReportStatusDefinitionDto[], Meta>(request);
        return this.mapService.mapToReportStatuses(response.data);
    }

    private getWCApi(): string {
        return `${this.getApiRoot()}/${wcConfig.api.wc.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }
}
