import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';

export class AgencyScheduleNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToAgencySchedule(): void {
    this.router.navigate(['agency_schedule'], {relativeTo: this.route.pathFromRoot[2]});
  }
}
