import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { CostCentersConfigurationManagementService } from '../../../services/index';
import { appConfig } from '../../../../app.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { NgForm } from '@angular/forms';
var CostCentersMobileViewComponent = /** @class */ (function () {
    function CostCentersMobileViewComponent(management, changeDetector) {
        this.management = management;
        this.changeDetector = changeDetector;
    }
    Object.defineProperty(CostCentersMobileViewComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    CostCentersMobileViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.collapsedSections = {};
        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: true,
            canAdd: true,
            canDelete: true
        };
        this.mainFormSubscription = this.mainForm.statusChanges.subscribe(function () {
            if (_this.mainForm.dirty) {
                _this.management.markAsDirty();
            }
        });
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            _this.state = state;
            if (state) {
                _this.currentOrgLevel = _this.management.currentOrgLevel;
            }
        });
        this.managementLoadedSubscription = this.management.onLoaded$.subscribe(function (container) {
            _this.m_container = container;
            _this.refreshView();
            _this.management.changeService.clearChanges();
            _this.management.setSelectedCount(0);
        });
        this.managementRefreshSubscription = this.management.viewRefresh$.subscribe(function () {
            _this.refreshView();
        });
        this.managementAddSubscription = this.management.addItemCmd$.subscribe(function (item) {
            _this.container.records.push(item);
            _this.management.changeService.changeNotify();
        });
    };
    CostCentersMobileViewComponent.prototype.getCurrentState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        return _.isBoolean(this.collapsedSections[key]) ? this.collapsedSections[key] : (this.collapsedSections[key] = true);
    };
    CostCentersMobileViewComponent.prototype.onChangedState = function () {
        var indexes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            indexes[_i] = arguments[_i];
        }
        var key = _.map(indexes, function (index) { return String(index); }).join('');
        this.collapsedSections[key] = !this.collapsedSections[key];
    };
    CostCentersMobileViewComponent.prototype.save = function (center) {
        if (_.isNil(center.id)) {
            this.management.onAddItem({ dataItem: center, isNew: true });
        }
        else {
            this.management.onSaveItem({ dataItem: center, isNew: false });
        }
    };
    CostCentersMobileViewComponent.prototype.cancel = function (center) {
        if (_.isNil(center.id)) {
            this.container.records = _.without(this.container.records, center);
        }
        else {
            this.mainForm.form.markAsPristine();
            this.management.onCancelEditItem();
            this.management.reloadRecords();
        }
    };
    CostCentersMobileViewComponent.prototype.remove = function (center) {
        if (_.isNil(center.id)) {
            this.container.records = _.without(this.container.records, center);
        }
        else {
            this.management.onRemoveItem(center);
        }
    };
    CostCentersMobileViewComponent.prototype.refreshView = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersMobileViewComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersMobileViewComponent.prototype, "managementLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersMobileViewComponent.prototype, "managementRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersMobileViewComponent.prototype, "managementAddSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], CostCentersMobileViewComponent.prototype, "mainFormSubscription", void 0);
    return CostCentersMobileViewComponent;
}());
export { CostCentersMobileViewComponent };
