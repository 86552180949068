import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';

import { EmployeeDefinitionsApiService, LookupService } from '../../../../../../organization/services/index';
import { EmployeeShortInfo, EmployeeTerminationReason, Lookup, LookupType } from '../../../../../../organization/models/index';
import { EmployeeToTerminate, EmployeeTerminate } from '../../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../../core/decorators/index';

@Component({
    moduleId: module.id,
    selector: 'slx-change-termination-date',
    templateUrl: 'change-termination-date.component.html',
    styleUrls: ['change-termination-date.component.scss']
})
export class ChangeTerminationDateComponent implements OnInit {

    @Input('employee')
    public set emp(emp: EmployeeToTerminate) {
        this.employee = emp;
        this.setEmployeeId();
    }

    public isValid: boolean;

    public employee: EmployeeToTerminate;
    public appConfig: IApplicationConfig;
    public requestToTerminate: EmployeeTerminate;
    public employeeShortInfo: EmployeeShortInfo;
    public get minDate(): Date {
        return !this.employee.ignoreLockedPayrollCycles && !_.isNil(this.employeeShortInfo) ? this.employeeShortInfo.effectiveDate : null;
      }

    @ViewChild('form', {static: true})
    public ngForm: NgForm;

    @unsubscribe()
    public formChangeSubscription: Subscription;

    private employeeDefinitionsApiService: EmployeeDefinitionsApiService;

    constructor(employeeDefinitionsApiService: EmployeeDefinitionsApiService, private changeDetector: ChangeDetectorRef) {
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    }

    public ngOnInit(): void {
        this.appConfig = appConfig;
        this.formChangeSubscription = this.ngForm.valueChanges.subscribe(() => {
            this.isValid = this.ngForm.valid;
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        });
        this.requestToTerminate = new EmployeeTerminate();
        this.requestToTerminate.date = this.employee.terminationDate || moment().startOf('day').toDate();
        this.setEmployeeId();
    }


    private setEmployeeId(): void {
        if (this.employee && this.requestToTerminate) {
            this.requestToTerminate.employeeId = this.employee.employeeId;
            this.loadEmployeeInfo();
        }
    }

    private loadEmployeeInfo(): void {
        this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employee.employeeId)
            .then((employeeShortInfo: EmployeeShortInfo) => {
                this.employeeShortInfo = employeeShortInfo;
            });
    }

}
