import { LookupEntity, ILookupEntity } from './lookup-entity';

export interface IPbjPosition extends ILookupEntity {
  govermentCode: number;
}
export class PbjPosition extends LookupEntity implements IPbjPosition {
  public govermentCode: number;
  public get title(): string {
    return `${this.name} (${this.govermentCode})`;
  }
}
