<form #templateForm="ngForm" novalidate class="telepunch-locations-mobile slx-high-box__body slx-main-content-indents">
<div class="main-box">
    <div class="header-box">
        <i class="fas fa-phone-square fa-2x blue-icon" aria-hidden="true"></i>
        <span class="header-text">Telepunch Location</span>
    </div>

    <section *ngFor="let location of management.records; let ind = index" class="collapsible-box">
        <h2 class="collapsible-box__header">
        <span class="collapsible-box__w-name">{{location.locationName}}</span>

        <slx-collapse-button class="collapsible-box__w-button"
            [isCollapsed]="getCurrentState(ind)"
            (isCollapsedChange)="onChangedState(ind)"
        ></slx-collapse-button>
        </h2>
        <div *ngIf="!getCurrentState(ind)" class="collapsible-box__body">
        <ng-container>
            <div class="command-container mobile-row">
                <button type="button" [disabled]="!templateForm.valid"
                    class="slx-button slx-with-icon slx-mobile-adapted"
                    (click)="save(location)">
                    <i class="far fa-check-circle fa-2x slx-button__icon" aria-hidden="true"></i>
                </button>
                <button type="button" class="slx-button slx-with-icon slx-mobile-adapted"
                    (click)="cancel(location)">
                    <i class="fa fa-ban fa-2x slx-button__icon" aria-hidden="true"></i>
                </button>
                <div style="width: 100%"></div>
                <button class="slx-button slx-with-icon slx-min-w90"
                    (click)="remove(location)">
                    <i class="fas fa-trash-alt red-icon" aria-hidden="true"></i>
                    <span class="slx-button__text">Remove</span>
                </button>
                </div>
            <div>
                <slx-input-decorator className="slx-small-font">
                    <input class="form-control" slx-input type="text" maxlength="50" [(ngModel)]="location.locationName"
                    [required]="true" placeholder="Description"
                    name="descriptionInput{{ind}}" [prohibitedValues]="management.getProhibitedNames(location)">
                    <span errorMessage for="required"></span>
                    <span errorMessage for="prohibitedValues">This description already exists</span>
                </slx-input-decorator>

                <div class="mobile-row">
                    <div class="mobile-row-item">
                    <slx-input-decorator className="slx-small-font">
                        <slx-phone-input slx-input maxlength="50" phone class="form-control" [readonly]="false"
                        [(ngModel)]="location.phone" [required]="true" placeholder="Phone"
                        name="phoneInput{{ind}}"
                        [prohibitedValues]="management.getProhibitedPhones(location)">
                        </slx-phone-input>
                        <span errorMessage for="required"></span>
                        <span errorMessage for="phone"></span>
                        <span errorMessage for="prohibitedValues">This phone already exists</span>
                    </slx-input-decorator>
                    </div>

                    <div class="mobile-row-item">
                    <slx-checkbox-button
                        placeholder="Authorized #"
                        [(ngModel)]="location.authorized"
                        fieldName="authorizedField{{ind}}"
                        name="authorized{{ind}}"
                    ></slx-checkbox-button>
                    </div>
                </div>
                <div class="mobile-row">
                    <div class="mobile-row-item">
                    <slx-input-decorator className="slx-small-font">
                        <input class="form-control" slx-input type="text"
                            [ngModel]="location.date | amDateFormat: appConfig.dateTimeFormat"
                            [disabled]="true" placeholder="Date"
                            name="date{{ind}}">
                    </slx-input-decorator>
                    </div>
                    <div class="mobile-row-item">
                    <slx-input-decorator className="slx-small-font">
                        <input class="form-control" slx-input type="text" [ngModel]="location.userName"
                            [disabled]="true" placeholder="User Name"
                            name="user{{ind}}">
                    </slx-input-decorator>
                    </div>
                </div>
            </div>
        </ng-container>
        </div>
    </section>
    </div>
</form>
