import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsAttendancePointsDefinitionComponent } from '../employee-sections-attendance-points-definition/employee-sections-attendance-points-definition.component';
import { ISelectableItemContainer, SelectableItemsProducer, DateTimeService, DialogOptions } from '../../../../../common/index';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsAttendancePoints } from '../../../models/index';
import { EmployeeSectionsPerformanceApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { IRangeDates } from '../../../../../common/models/range-dates';
import { orderBy } from '@progress/kendo-data-query';
import * as _ from 'lodash';
var EmployeeSectionsAttendancePointsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAttendancePointsComponent, _super);
    function EmployeeSectionsAttendancePointsComponent(employeeSectionsPerformanceApiService, dateTimeService, selectableItemsProducer, modalService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.sort = [];
        _this.totalPoints = 0;
        _this.totalCurrentPoints = 0;
        _this.pageSize = 10;
        _this.skip = 0;
        Assert.isNotNull(employeeSectionsPerformanceApiService, 'employeeSectionsPerformanceApiService');
        Assert.isNotNull(dateTimeService, 'dateTimeService');
        _this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
        _this.modalService = modalService;
        _this.dateTimeService = dateTimeService;
        _this.selectableItemsProducer = selectableItemsProducer;
        _this.changeDetector = changeDetector;
        _this.sort = [{ field: 'item.dateOn.fieldValue', dir: 'desc' }];
        return _this;
    }
    Object.defineProperty(EmployeeSectionsAttendancePointsComponent.prototype, "employeeSubsectionAttendancePoints", {
        set: function (attendancePoints) {
            this.attendancePoints = attendancePoints;
            if (attendancePoints !== null) {
                this.selectableAttendancePoints = this.selectableItemsProducer.produceSelectable(attendancePoints.points);
                this.refreshGrid();
                this.isAnyItemSelected = false;
                this.changeDetector.markForCheck();
                this.changeDetector.detectChanges();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendancePointsComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAttendancePointsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAttendancePointsComponent.prototype.getSubsectionModel = function () {
        return this.attendancePoints;
    };
    EmployeeSectionsAttendancePointsComponent.prototype.loadSubsection = function () {
        var _this = this;
        var daysCount = this.dateTimeService.GetDiffDays(this.startDate, this.endDate);
        if (!daysCount || daysCount === 0) {
            return;
        }
        this.startProgress();
        this.skip = 0;
        this.employeeSectionsPerformanceApiService.getPerformanceAttendancePoints(this.employeeId, this.startDate, daysCount)
            .then(function (attendancePoints) {
            _this.employeeSubsectionAttendancePoints = attendancePoints;
            _this.stopProgress();
        })
            .catch(function (error) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAttendancePointsComponent.prototype.onSortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsAttendancePointsComponent.prototype.onPageChange = function (event) {
        this.skip = event.skip;
        this.refreshGrid();
    };
    EmployeeSectionsAttendancePointsComponent.prototype.onRemovePointsClicked = function () {
        var _this = this;
        this.modalService.globalAnchor.openConfirmDialog('Confirmation', 'Are you sure that you wish to remove this Attendance Points?', function (result) {
            if (result) {
                var pointIds_1 = [];
                _this.selectableAttendancePoints.forEach(function (i) { if (i.selected)
                    pointIds_1.push(i.item.id); });
                _this.doRemove(pointIds_1);
            }
        });
    };
    EmployeeSectionsAttendancePointsComponent.prototype.onAddPointsClicked = function () {
        var _this = this;
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 600;
        dialogOptions.width = 800;
        var dialog = this.modalService.globalAnchor.openDialog(EmployeeSectionsAttendancePointsDefinitionComponent, 'Select one or more entries and an applicable date.', dialogOptions, undefined, function (result) {
            if (result) {
                var selectedDate = dialog.selectedDate;
                var selectedIds = dialog.selectedIds;
                _this.doAdd(selectedDate, selectedIds);
            }
        });
        dialog.empId = this.employeeId;
        if (!dialog.isLoading && dialog.initialized) {
            dialog.loadSubsection();
        }
    };
    EmployeeSectionsAttendancePointsComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadSubsection();
    };
    EmployeeSectionsAttendancePointsComponent.prototype.onItemSelectionChanged = function () {
        this.isAnyItemSelected = this.selectableAttendancePoints.some(function (i) { return i.selected; });
    };
    EmployeeSectionsAttendancePointsComponent.prototype.doAdd = function (dateOn, pointsDefinitionIds) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPerformanceApiService.addAttendancePoints(this.employeeId, dateOn, pointsDefinitionIds)
            .then(function (result) {
            _this.state.isLoaded = true;
            _this.stopProgress();
            _this.loadSubsection();
        })
            .catch(function (error) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAttendancePointsComponent.prototype.doRemove = function (pointIds) {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPerformanceApiService.deleteAttendancePoints(this.employeeId, pointIds)
            .then(function (result) {
            _this.stopProgress();
            _this.loadSubsection();
        })
            .catch(function (error) {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAttendancePointsComponent.prototype.refreshGrid = function () {
        if (!this.selectableAttendancePoints) {
            this.gridView = null;
            return;
        }
        this.totalPoints = this.getTotalPoints();
        this.totalCurrentPoints = this.getTotalCurrentPoints();
        var sortedRecords = orderBy(this.selectableAttendancePoints, this.sort);
        var pagedRecords = sortedRecords.slice(this.skip, this.skip + this.pageSize);
        this.gridView = {
            data: pagedRecords,
            total: this.selectableAttendancePoints.length
        };
    };
    EmployeeSectionsAttendancePointsComponent.prototype.getTotalPoints = function () {
        var total = 0;
        _.forEach(this.selectableAttendancePoints, function (cont) {
            if (cont.item && cont.item.value) {
                total += cont.item.value.fieldValue;
            }
        });
        return total;
    };
    EmployeeSectionsAttendancePointsComponent.prototype.getTotalCurrentPoints = function () {
        var total = 0;
        _.forEach(this.selectableAttendancePoints, function (cont) {
            if (cont.item && cont.item.value2) {
                total += cont.item.value2.fieldValue;
            }
        });
        return total;
    };
    return EmployeeSectionsAttendancePointsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsAttendancePointsComponent };
