<div *ngIf="!isMobile" class="providers-tabs flex">
  <div class="flex providers-tabs__wrapper">
    <span class="providers-tabs__arrows align-self__center"
      (click)="onClickShift(true)"
      [class.disabled]="isDisabledLeft"
      [class.hidden]="isHiddenLeftRigth"
    >
      <i aria-hidden="true" class="fas fa-angle-left"></i>
    </span>
    <div class="providers-tabs__slider">
      <hr class="page-line">
      <ul #list class="providers-tabs__list flex flex-direction-row" [style.transform]="translateX">
        <li class="providers-tabs__item"
          *ngFor="let provider of providers"
          [title]="provider.current.storedName"
          [class.active]="isActiveMenu(provider)"
          [class.disabled]="isEditMode && !isActiveMenu(provider)"
          (click)="onClickTab(provider)"
        >{{ provider.storedProviderName }}</li>
      </ul>
    </div>
    <span class="providers-tabs__arrows align-self__center"
      (click)="onClickShift(false)"
      [class.disabled]="isDisabledRight"
      [class.hidden]="isHiddenLeftRigth"
    >
      <i aria-hidden="true" class="fas fa-angle-right"></i>
    </span>
  </div>
  <ng-container *ngTemplateOutlet="createButton"></ng-container>
</div>
<div *ngIf="isMobile" class="providers-tabs mobile flex">
  <div class="providers-tabs__mobile-list">
    <slx-dropdown-list class="slx-wide"
      valueField="id"
      titleField="storedProviderName"
      [options]="providers"
      [readonly]="isEditMode"
      [(ngModel)]="selectedProvider"
      (ngModelChange)="onChangeProviderOnMobile($event)"
    ></slx-dropdown-list>
  </div>
  <ng-container *ngTemplateOutlet="createButton"></ng-container>
</div>

<ng-template #createButton>
  <button class="slx-button slx-blue slx-with-icon slx-no-breaks slx-mobile-adapted align-self__center"
    type="button"
    (click)="onCreateNew()"
    [disabled]="!canCreate"
  >
    <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
    <span class="slx-button__text">Create New</span>
  </button>
</ng-template>
