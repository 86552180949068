import { RangeDates } from '../../common/models/range-dates';

export class DateRangeValue extends RangeDates {
  public valid: boolean;

  constructor(sDate: Date = null, eDate: Date = null, isValid: boolean = false) {
    super();

    this.startDate = sDate;
    this.endDate = eDate;
    this.valid = isValid;
  }
}
