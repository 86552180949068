import { PbjOrganization, IPbjOrganizationDTO } from './pbj-organization';
import { FieldData } from '../../../core/models/index';

export class PbjOrganizationModel {

    public pbjOrganization: PbjOrganization;

    public telepunchOffset: FieldData<number>;
    public timezoneOffset: FieldData<number>;

    public lastUpdateUsername: string;
    public lastUpdateDate: Date;

    public isSelected: boolean;
    public selectable: boolean;
}


export interface IPbjOrganizationModelDTO extends IPbjOrganizationDTO {
    lastUpdateUsername: string;
    lastUpdateDate: string;
}
