import { Component, Input, Output, EventEmitter } from '@angular/core';

import { RolesRowDefinition, IRolesRow } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-roles-profile-access-toggler',
  templateUrl: 'roles-profile-access-toggler.component.html',
  styleUrls: ['roles-profile-access-toggler.component.scss']
})
export class RolesProfileAccessTogglerComponent {
  @Input()
  public rowItem: IRolesRow<any>;
  @Input()
  public roleId: number;
  @Output()
  public roleToggled: EventEmitter<number>;

  constructor() {
    this.roleToggled = new EventEmitter<number>();
  }
  public onAccessClick(): void {
    if (this.rowItem) {
      this.rowItem.setNextStatus(this.roleId);
      this.roleToggled.next(this.roleId);
    }
  }
}
