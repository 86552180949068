
<div class="pm-codes-grid slx-full-height">

  <span class="grid-title">Configure Codes</span>

  <slx-pm-config-toolbar [canCreateNew]="canCreateNew" [itemClass]="itemClass"></slx-pm-config-toolbar>

  <form novalidate #gridForm="ngForm" class="grid-form">
    <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
      [slxKendoGridColumnsGroup]="columnsGroupName"
      [data]="gridState?.view"
      scrollable="'scrollable'"
      [sortable]="true"
      [sort]="gridState.state.sort" [skip]="gridState.state.skip"
      [pageable]="true" [pageSize]="pageSize"
      [filterable]="'menu'" [filter]="gridState.state.filter"
      [groupable]="false" [selectable]="false"
      [slxKendoGridState]="gridState.gridDefinition"
      (dataStateChange)="gridState.dataStateChange($event)"
      (selectionChange)="gridState.selectionChange($event)"
      (stateRestored)="gridState.dataStateChange($event)"
      (edit)="editHandler($event)" (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)" (remove)="gridState.removeHandler($event)"
      (pageChange)="pageChanged($event)">

      <kendo-grid-command-column title="Edit" [width]="80" [hidden]="!canEdit">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="inline-buttons">
            <button kendoGridEditCommand type="button" *ngIf="canEdit" class="slx-inline-edit-button2">
              <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridRemoveCommand *ngIf="canEdit && dataItem.useCount == 0"
              class="slx-icon-button slx-inline-remove-button2">
              <i class="fas fa-trash slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" [disabled]="!ngFormChild.valid"
              class="slx-icon-button slx-inline-apply-button2">
              <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
              <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column title="Code Id" field="id" width="50" *slxHiddenOn="['tablet', 'mobile']"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Code Id</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.id }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" title="OrgLevel" width="50"
      [editable]="false" field="orgLevel.name"
      [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']"
      slxKendoGridColumnHiddenState>
      <ng-template kendoGridHeaderTemplate>
        <span slxKendoGridHeaderTooltip>OrgLevel</span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.orgLevel?.name }}</ng-template>
    </kendo-grid-column>


      <kendo-grid-column title="Code Type" field="typeName" width="110"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Code Type</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.typeName }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <slx-dropdown-list #codeType="ngModel"
            class="slx-wide slx-short"
            [options]="codeTypesLookup?.items"
            [ngModel]="dataItem.type"
            [required]="true"
            [valuePrimitive]="false"
            [valueField]="'id'"
            [titleField]="'description'"
            name="codeType"
            placeholder="Code Type"
            (ngModelChange)="onCodeTypeChange($event, dataItem)"
          ></slx-dropdown-list>
          <div *ngIf="codeType.errors" class="slx-error-block">
            <span *ngIf="codeType.errors.required" errorMessage for="required"></span>
          </div>
        </ng-template>

      </kendo-grid-column>

      <kendo-grid-column title="Code" field="name" width="110"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState
        >
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Code</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.name }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator *ngIf="showCodeNameControl">
            <input slx-input required="true" name="codeTitle" placeholder="Code"
            [(ngModel)]="dataItem.name" slxMaxLength="255"
            [selfValue]="dataItem.name"
            [prohibitedValues]="{values: prohibitedCodeValues, excludeSelf: true, caseSensitive: false}"
            class="mobile-input"
            >
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">Code should be a maximum length of 255</span>
            <span errorMessage for="prohibitedValues">Code should be unique</span>
          </slx-input-decorator>
        </ng-template>

      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" title="Code Description" field="description" width="110"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Code Description</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.description }}</ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <input slx-input required="true" name="codeDescription" placeholder="Description"
            [(ngModel)]="dataItem.description" slxMaxLength="4000"
            class="mobile-input"
            >
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">Description should be a maximum length of 4000</span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" title="Modified By" width="110"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']" field="modifiedUserName"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Modified By</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.modifiedUserName }}</ng-template>
      </kendo-grid-column>

      <kendo-grid-column *slxHiddenOn="['tablet', 'mobile']" title="Modified On" width="110"
        [kendoGridColumnFilterableOn]="['desktop']" [kendoGridColumnSortableOn]="['desktop']" field="modifiedDate"
        slxKendoGridColumnHiddenState>
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Modified On</span>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.modifiedDate | amDateFormat: appConfig.dateFormat }}</ng-template>
      </kendo-grid-column>

      <!-- mobile view -->
      <kendo-grid-column *slxHiddenOn="['desktop']"
      title=""
      class="overflow-visible-cell action-column"
      [width]="50"
      [filterable]="false"
      [sortable]="false"
    >

      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <div class="mobile-grid-editor m-grid">

          <div class="flex flex-direction-row m-grid__group">
            <div class="drowpdown-wrapper">
              <slx-dropdown-list #codeTypeMobile="ngModel"
                class="slx-wide slx-short"
                [options]="codeTypesLookup?.items"
                [ngModel]="dataItem.type"
                [required]="true"
                [valuePrimitive]="false"
                [valueField]="'id'"
                [titleField]="'description'"
                name="codeType"
                placeholder="Code Type"
                (ngModelChange)="onCodeTypeChange($event, dataItem)">
              </slx-dropdown-list>
              <div *ngIf="codeTypeMobile.errors" class="slx-error-block">
                <span *ngIf="codeTypeMobile.errors.required" errorMessage for="required"></span>
              </div>
            </div>
          </div>

          <div class="flex flex-direction-row m-grid__group input-group">
            <div *ngIf="showCodeNameControl">
              <input required="true" #codeTitleMobile="ngModel"
              name="codeTitleMobile" placeholder="Code"
                [(ngModel)]="dataItem.name" slxMaxLength="255"
                [selfValue]="dataItem.name"
                [prohibitedValues]="{values: prohibitedCodeValues, trimValue: true, excludeSelf: true, caseSensitive: false}"
                class="grid-editor__input"
                >
              <div *ngIf="codeTitleMobile.errors" class="slx-error-block">
                <span errorMessage for="required"></span>
              </div>
            </div>
          </div>
          <div class="flex flex-direction-row m-grid__group input-group textarea-child">
            <div *ngIf="showCodeNameControl">
              <textarea #codeDescMobile="ngModel"
              slx-input required="true"
              class="grid-editor__input full-width-textarea"
              name="codeDescMobile"
              [(ngModel)]="dataItem.description"
              maxlength="4000"></textarea>
              <div *ngIf="codeDescMobile.errors" class="slx-error-block">
                <span errorMessage for="required"></span>
              </div>
            </div>
          </div>
          <div class="flex flex-direction-row m-grid__group">
            <p class="m-grid__field">
              <span class="m-grid__title">Code Id</span>
              <span class="m-grid__value">{{ dataItem.id }}</span>
            </p>
            <p class="m-grid__field">
              <span class="m-grid__title">OrgLevel</span>
              <span class="m-grid__value">{{ dataItem.orgLevel?.name }}</span>
            </p>
          </div>
           <div class="flex flex-direction-row m-grid__group">
            <p class="m-grid__field">
              <span class="m-grid__title">Created by</span>
              <span class="m-grid__value">{{ dataItem.modifiedUserName }}</span>
            </p>
            <p class="m-grid__field">
              <span class="m-grid__title">Reviewed By</span>
              <span class="m-grid__value">{{ dataItem.modifiedDate | amDateFormat: appConfig.dateFormat }}</span>
            </p>
          </div>
        <div class="flex flex-direction-row m-grid__group">
          <button kendoGridSaveCommand type="button" [disabled]="!ngFormChild.valid"
            class="slx-icon-button slx-inline-apply-button2">
            <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
          </button>
          <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
            <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
          </button>
        </div>
        </div>
      </ng-template>
    </kendo-grid-column>

      <kendo-grid-excel [fileName]="xlsxName" [fetchData]="retriveAllPages()"></kendo-grid-excel>
      <kendo-grid-pdf [fileName]="pdfName" [allPages]="true" [repeatHeaders]="true">
        <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
        <kendo-grid-column field="id" title="Code Id">
        </kendo-grid-column>
        <kendo-grid-column field="orgLevel.name" title="OrgLevel">
        </kendo-grid-column>
        <kendo-grid-column field="typeName" title="Code Type">
        </kendo-grid-column>
        <kendo-grid-column field="name" title="Code">
        </kendo-grid-column>
        <kendo-grid-column field="description" title="Code Description">
        </kendo-grid-column>
        <kendo-grid-column field="modifiedUserName" title="Modified By">
        </kendo-grid-column>
        <kendo-grid-column field="modifiedDate" title="Modified On">
          <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.modifiedDate | amDateFormat: appConfig.dateFormat }}</ng-template>
        </kendo-grid-column>
      </kendo-grid-pdf>

    </kendo-grid>

  </form>
</div>
