import * as _ from 'lodash';

import { Injectable } from '@angular/core';

import { AppServerConfig } from './../model/app-server-config';
import { IAppSetting, AppSetting } from './../model/app-setting';
import { UserPermissions } from '../model/user-permissons';
import { NightShiftSetting } from '../model/night-shift-setting';
import * as M from 'minimatch';

@Injectable()
export class AppSettingsMapService {
  public mapToAppSetting(dtos: IAppSetting[]): AppSetting[] {
    let settings: AppSetting[] = dtos.map((dto: IAppSetting, index: number, arr: IAppSetting[]) => {
      let s: AppSetting = new AppSetting();
      s.key = dto.key;
      s.value = dto.value;
      return s;
    });
    return settings;
  }

  public mapToAppServerConfig(dtos: IAppSetting[]): AppServerConfig {
    let config: AppServerConfig = new AppServerConfig();
    let map: any = _.keyBy(dtos, (item: IAppSetting) => item.key);
    config.thresholdSeconds = +this.getValue(map, 'thresholdSeconds');
    config.timeoutSeconds = +this.getValue(map, 'timeoutSeconds');
    config.PbjEnabled = this.getBoolean(map, 'PbjEnabled');
    config.WorkerDupesReport = this.getBoolean(map, 'WorkerDupesReport');
    config.PbjMode = this.getValue(map, 'PbjMode');
    config.payrollExpManagementEnabled = this.getBoolean(map, 'PayrollExpManagementEnabled');
    config.maxFileSizeLimit = +this.getValue(map, 'maxFileSizeLimit');
    config.essTemplatesEnabled = this.getBoolean(map, 'essTemplatesEnabled');
    config.enablePunchAttestation = this.getBoolean(map, 'enablePunchAttestation');
    config.bswiftIntegrationEnabled = this.getBoolean(map, 'bswiftIntegrationEnabled');
    config.enterpriseId = this.getValue(map, 'enterpriseId');
    config.TwilioAccountSid = this.getValue(map, 'TwilioAccountSid');
    config.TwilioAuthToken = this.getValue(map, 'TwilioAuthToken');
    config.TwilioSenderPhone = this.getValue(map, 'TwilioSenderPhone');
    config.requireTimeclockForPunches = this.getBoolean(map, 'requireTimeclockForPunches');
    config.serverUtcOffsetSec = this.getValue(map, 'serverUtcOffsetSec');
    config.EnableRate4DecimalPlaces = this.getBoolean(map, 'EnableRate4DecimalPlaces');
    config.CreateorEditPolicy = this.getBoolean(map, 'CreateorEditPolicy');
    config.CopyAccrualPolicy=this.getBoolean(map,'CopyAccrualPolicies');
    config.duagrid = this.getBoolean(map, 'duagrid');
    config.partnerintegrations = this.getBoolean(map, 'partnerintegrations');
    config.enableIncreaseReason = this.getBoolean(map, 'EnableIncreasePayRateReason');
    config.addTimeCardComments = this.getBoolean(map, 'addTimeCardComments');
    config.IsGOClient = this.getBoolean(map, 'IsGOClient');
    const approveOwnTimecard = this.getValue(map, 'approveOwnTimecard');
    const editOwnTimecard = this.getValue(map, 'editOwnTimecard');
    const editOwnProfile = this.getValue(map, 'editOwnProfile');
    const approveOwnESSRequest = this.getValue(map, 'approveOwnESSRequest');
    config.preventOverlappingPunches = this.getBoolean(map, 'PreventOverlappingPunches');
    config.preventoverlappingabsences = this.getBoolean(map, 'preventoverlappingabsences');
    config.isSupervisorEnabled = this.getBoolean(map, 'enablesupervisor');
    config.isBenefitDeductionEnabled = this.getBoolean(map, 'enableBenefitDeduction');
    config.isNotesEnabled = this.getBoolean(map, 'EnableNotes');
    config.isTermHistoryEditEnabled = this.getBoolean(map, 'TermHistoryEditEnabled');
    config.userPermissions = new UserPermissions(approveOwnTimecard, editOwnTimecard, editOwnProfile, approveOwnESSRequest);
    config.enableOpenShiftOnFly = this.getBoolean(map, 'enableopenshiftonfly');
    config.ispositionHistoryWithAttributeEnabled = this.getBoolean(map, 'PositionHistoryWithAttributeEnabled');
    config.isBenefitDeductionEnabled2 = this.getBoolean(map, 'enableBenefitDeduction2');
    config.TimecardRedesign = this.getBoolean(map, 'TimecardRedesign');
    config.ScheduleNotPosted = this.getBoolean(map, 'ScheduleNotPosted');
    config.IncreaseFrequencyofShiftsPostedNotifications = this.getBoolean(map,'IncreaseFrequencyofShiftsPostedNotifications');
    config.AbilityForAdminToTurnOnAndOffAutoShiftPickUp = this.getBoolean(map,'AbilityForAdminToTurnOnAndOffAutoShiftPickUp');
    config.ScheduleRotationTemplate = this.getBoolean(map, 'ScheduleRotationTemplate');
    config.AbilitytoChangeAccrualRuleTypeBasedonSeniority = this.getBoolean(map, 'AbilitytoChangeAccrualRuleTypeBasedonSeniority');
    config.isV6ReHireEnhacementEnabled = this.getBoolean(map, 'IsV6ReHireEnhacementEnabled');
    
    let nightShiftsByOrganization: any = this.getValue(map, 'nightShiftPrimary');

    config.five_star_v2_enabled = this.getBoolean(map, 'five_star_v2');
    config.five_star_goal_section_enabled = this.getBoolean(map, 'five_star_goal_section');
    config.IsPBJExportCorpLevelEnabled = this.getBoolean(map, 'pbjexport_corp_level');
    config.isDefaultShiftEnabled = this.getBoolean(map, 'Countshiftsasfilledinsameshiftgroup');
    config.IsPBJConsecutiveHrsEnabled = this.getBoolean(map, 'PBJ_Consecutive_Hrs');
    config.ModifySchedulesApprovedinPayPeriods = this.getBoolean(map, 'ModifySchedulesApprovedinPayPeriods');
    config.IsNextgenPayrollEnabled = this.getBoolean(map,'NextgenPayrollEnabled');
    config.isDownloadImportOptionEnabled = this.getBoolean(map, "IdealScheduleImport");
    config.AllowSelectFutureDateRNMinHours = this.getBoolean(map, 'AllowSelectFutureDateRNMinHours');
    config.NextgenPhasedRollout = this.getBoolean(map, 'NextgenPhasedRollout');

    config.isPartialShiftEnabled = this.getBoolean(map, 'EnablePartialShift');
    config.defaultPartialShiftCount = +this.getValue(map,'DefaultPartialShiftCount');

    config.ExportToPayrollFrequency = +this.getValue(map, 'ExportToPayrollFrequency');
    config.ExportToPayrollDuration = +this.getValue(map, 'ExportToPayrollDuration');
    config.IsNextgenTimeExportEnabled = this.getBoolean(map,'NextgenTimeExportEnabled');
  try {
      nightShiftsByOrganization = JSON.parse(nightShiftsByOrganization);
    } catch {
      nightShiftsByOrganization = []; // should we throw error?
    }

    config.nightShiftSettings = _.map(nightShiftsByOrganization, (dto: { Key: any, Value: any }) => {
      const setting: NightShiftSetting = new NightShiftSetting();
      setting.organizationId = parseInt(dto.Key);
      setting.enabled = (dto.Value !== 'N');
      return setting;
    });


    return config;
  }

  private getValue(map: any, key: string): any {
    let obj: IAppSetting = map[key];
    return obj ? obj.value : null;
  }

  private getBoolean(map: any, key: string): boolean {
    return _.toLower(this.getValue(map, key)) === 'true';
  }
}
