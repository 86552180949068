import { GridComponent } from '@progress/kendo-angular-grid';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { BudgetedParsDetailsRow } from './../../models/budgeted-pars/budgeted-pars-details-row';
import { BudgetedParsDetailsContainer } from './../../models/budgeted-pars/budgeted-pars-details-container';
import { MasterScheduleManagementService } from './../../services/schedule/master-schedule-management.service';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from './../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { ModalService } from './../../../common/services/modal/modal.service';
import { DialogModeSize, DialogOptions, DialogOptions2 } from './../../../common/models/dialog-options';
import { Component, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';

@Component({
    moduleId: module.id,
    selector: 'slx-budgeted-pars-popup',
    templateUrl: 'budgeted-pars-popup.component.html',
    styleUrls: ['budgeted-pars-popup.component.scss']
})
export class BudgetedParsPopupComponent {
  public options: DialogOptions;
  public dialogResult: boolean;
  public dateOn: Date;
  public showAll: boolean;
  public gridState: KendoGridStateHelper<BudgetedParsDetailsRow>;
  public records: BudgetedParsDetailsRow[];
  public totalScheduled: number = 0;
  public totalBudgeted: number = 0;
  public totalDiscrepency: number = 0;

  @ViewChild('kendoGrid', { static: true })
  public grid: GridComponent;

  private modalService: ModalService;

  public static openDialog(modalService: ModalService, managementService: MasterScheduleManagementService, callback: (result: boolean) => void): BudgetedParsPopupComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: MasterScheduleManagementService,
        useValue: managementService
      }
    ];

    let dialog: BudgetedParsPopupComponent = modalService.globalAnchor
      .openDialog2(BudgetedParsPopupComponent, 'Budget Discrepency Detail', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    private managementService: MasterScheduleManagementService
  ) {
    this.modalService = modalService;
    this.options = options;
    this.gridState = new KendoGridStateHelper<any>();
  }

  public setDate(date: Date) {
    if(this.dateOn !== date) {
      this.dateOn = date;
      this.loadData();
    }
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public loadData(): void {
    this.managementService.getBudgetedParsDetails(this.dateOn)
      .then((value: BudgetedParsDetailsContainer) => {
        this.records = value.details;
        this.refreshGrid();
      });
  }

  public refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    let showRecords: BudgetedParsDetailsRow[] = this.records;
    if (!this.showAll) {
      showRecords = _.filter(showRecords, row => row.discrepency !== 0);
    }
    this.gridState.view = process(showRecords, this.gridState.state);
    this.totalBudgeted = 0;
    this.totalDiscrepency = 0;
    this.totalScheduled = 0;

    _.each(showRecords, (rec: BudgetedParsDetailsRow) => {
      this.totalBudgeted += rec.budgetedHours;
      this.totalScheduled += rec.scheduledHours;
      this.totalDiscrepency += rec.discrepency;
    });

    this.totalBudgeted = _.round(this.totalBudgeted, 2);
    this.totalScheduled = _.round(this.totalScheduled, 2);
    this.totalDiscrepency = _.round(this.totalDiscrepency, 2);
  }

  public getGridData(): () => ExcelExportData {
    return (): ExcelExportData => {
      return {
        data: _.cloneDeep(this.gridState.view.data)
      };
    };
  }

  public onExportToExcel(): void {
    this.grid.saveAsExcel();
  }

  public onExportToPdf(): void {
    this.grid.saveAsPDF();
  }
}
