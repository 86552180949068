import { ModalSettings, IComponentColumnsStateStorage, IComponentStateStorage } from '../settings/index';
import { StringUtils, Assert } from '../../../framework/index';

export class UserSettings {
  public id: number;
  public modalSettings: StringMap<ModalSettings>;
  public lastEmployeeSection: string;
  public columnsState: IComponentColumnsStateStorage;
  public componentStateStorage: IComponentStateStorage;
  public readClientMessages: StringMap<boolean>;

  public static getKey(id: number): string {
    Assert.isNotNull(id, 'id');
    return StringUtils.format('UserSettings{0}', id);
  }

  constructor(id?: number) {
    this.id = id;
    this.modalSettings = {};
    this.readClientMessages = {};
  }


}

