import * as moment from 'moment';
var Shift = /** @class */ (function () {
    function Shift() {
        this.isNameEdited = false;
        this.lunchDuration = 0;
        this.duration = 0;
        this.hasPartialShift = false;
        this.partialShiftList = [];
        this.deletedPartials = [];
        this.selectable = true;
        this.isUpdated = false;
        this.isDeleted = false;
        this.isEditing = false;
        this.shiftSetting = undefined;
    }
    Object.defineProperty(Shift.prototype, "defaultShiftData", {
        get: function () {
            return this.setDefaultShift === 'NO' ? 'NO' : 'YES';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Shift.prototype, "defaultPartailShiftFlag", {
        get: function () {
            return this.hasPartialShift ? 'YES' : 'NO';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Shift.prototype, "groupName", {
        get: function () {
            return this.group ? this.group.name : '';
        },
        enumerable: true,
        configurable: true
    });
    Shift.createShiftName = function (start, end) {
        var name = 'new shift';
        if (start && end) {
            var mStart = moment(start).format('hh:mm A');
            var mEnd = moment(end).format('hh:mm A');
            mStart = mStart.replace(':00', '');
            mStart = mStart.replace('AM', 'A');
            mStart = mStart.replace('PM', 'P');
            mStart = mStart.replace(' ', '');
            mEnd = mEnd.replace(':00', '');
            mEnd = mEnd.replace('AM', 'A');
            mEnd = mEnd.replace('PM', 'P');
            mEnd = mEnd.replace(' ', '');
            name = mStart + "-" + mEnd;
        }
        return name;
    };
    return Shift;
}());
export { Shift };
