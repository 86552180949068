var BenefitDetailsProvider = /** @class */ (function () {
    function BenefitDetailsProvider() {
        this.name = '';
        this.storedName = '';
        this.benefitLines = [];
    }
    Object.defineProperty(BenefitDetailsProvider.prototype, "shortName", {
        get: function () {
            if (this.name.length <= 25) {
                return this.name;
            }
            return this.name.slice(0, 25) + "...";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsProvider.prototype, "storedShortName", {
        get: function () {
            if (this.storedName.length <= 25) {
                return this.storedName;
            }
            return this.storedName.slice(0, 25) + "...";
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsProvider;
}());
export { BenefitDetailsProvider };
