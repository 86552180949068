<ul class="nav nav-pills nav-justified steps">
  <li *ngFor="let def of stepDefinitions" [ngClass]="{ 'active': currentStep === def.step, 'done': currentStep > def.step }">
    <a data-toggle="tab" class="step">
      <span class="number"> {{def.name}} </span>
      <span class="desc">
      <i class="fa fa-check" aria-hidden="true"></i> {{def.description}} </span>
    </a>
  </li>
</ul>
<div id="bar" class="progress progress-striped" role="progressbar" style="">
  <div class="progress-bar progress-bar-success" [ngStyle]="{'width': progress }"></div>
</div>
<div class="tab-content">
  <ng-content select="[stepSwitch]"></ng-content>
</div>
