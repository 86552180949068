import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';

import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';
import { LocationUnit, ILocationUnit } from '../../../organization/models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { FieldsMeta, IFieldLimit, IFieldData, FieldAccessType } from '../../../core/models/index';
import { MetaMapService } from '../../../core/services/index';

import { EmployeeSectionAccrualsBalances, EmployeeAccrual, IEmployeeAccrual, IEmployeeSectionAccrualsBalances } from '../../../employee/employee-sections/models/index';
import { EmployeeSectionsAccrualsMapService } from '../../../employee/employee-sections/services/index';


import { IScheduleAbsence, ScheduleAbsence } from '../../../organization/models/index';
import {
  LeaveRequestEntry,
  ILeaveRequestEntries,
  ILeaveRequestEntry,
  LeaveRequestEntries,
  ILeaveRequestDetailEntries,
  ILeaveRequestDetailEntry,
  LeaveRequestDetailEntry,
  LeaveRequestDetailEntries
} from '../../models/index';

@Injectable()
export class LeaveRequestMapService {
  private lookupMapService: LookupMapService;
  private metaMapService: MetaMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;
  private empSectionsMapService: EmployeeSectionsAccrualsMapService;

  constructor(
    lookupMapService: LookupMapService,
    metaMapService: MetaMapService,
    employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    empSectionsMapService: EmployeeSectionsAccrualsMapService
  ) {
    this.lookupMapService = lookupMapService;
    this.metaMapService = metaMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
    this.empSectionsMapService = empSectionsMapService;
  }

  public mapLeaveRequests(dto: ILeaveRequestEntries, meta: FieldsMeta): LeaveRequestEntries {
    const requestEntries: LeaveRequestEntries = new LeaveRequestEntries();
    requestEntries.requests = _.map(dto.requests, (entry: ILeaveRequestEntry) => {
      const request: LeaveRequestEntry = this.mapRequest(entry);

      return request;
    });
    requestEntries.actions = this.metaMapService.mapActions(meta);

    return requestEntries;
  }

  public mapRequest(dto: ILeaveRequestEntry): LeaveRequestEntry {
    let data: LeaveRequestEntry = new LeaveRequestEntry();
    data.id = dto.id;
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.organization = this.lookupMapService.mapOrganization(dto.organization);
    data.department = this.lookupMapService.mapOrganization(dto.department);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.submittedOn = dateTimeUtils.getUtcDateTime(dto.submittedOn);
    data.submittedTo = dto.submittedTo;
    data.start = dateTimeUtils.getUtcDateTime(dto.start);
    data.end = dateTimeUtils.getUtcDateTime(dto.end);
    data.overlapsApproved = dto.overlapsApproved;
    data.overlapsPending = dto.overlapsPending;
    data.reason = dto.reason;
    data.status = dto.status;
    data.detailsLength = dto.detailsLength;
    data.accruals = this.mapAccruals(dto.accruals);

    return data;
  }

  public mapLeaveRequestDetails(dto: ILeaveRequestDetailEntries): LeaveRequestDetailEntries {
    let details = _.map(dto.details, (entry: ILeaveRequestDetailEntry, i: number) => {
      const detail: LeaveRequestDetailEntry = this.mapDetail(entry, i);

      return detail;
    });

    let result = new LeaveRequestDetailEntries();
    result.details = details;
    result.canReplaceEmployee = dto.canReplaceEmployee;
    return result;
  }

  public mapDetail(dto: ILeaveRequestDetailEntry, i: number): LeaveRequestDetailEntry {
    let detail: LeaveRequestDetailEntry = new LeaveRequestDetailEntry();
    detail.date = dateTimeUtils.convertFromDtoString(dto.date);
    detail.unit = this.mapUnit(dto.unit);
    detail.shift = dto.shift ? this.lookupMapService.mapShift(dto.shift) : null;
    detail.shiftDuration = moment.duration(detail.shift && detail.shift.duration || 0);
    detail.absenceReason = this.mapAbsence(dto.absenceReason);
    detail.accrualBalance = dto.accrualBalance;
    detail.approvedAbsences = dto.approvedAbsences;
    detail.submittedAbsences = dto.submittedAbsences;
    detail.replacement = dto.replacement;
    detail.isAccruaBalanceAvailable = dto.isAccruaBalanceAvailable;
    detail.replacedEmployee = null;
    detail.localId = i;
    detail.hasEdited = false;

    return detail;
  }

  public mapUnit(u: ILocationUnit): LocationUnit {
    const unit: LocationUnit = new LocationUnit();
    unit.id = _.get(u, 'id') || 0;
    unit.name = _.get(u, 'name') || '';

    return unit;
  }

  public mapToDTODetails(details: LeaveRequestDetailEntry): any {
    const detail: any = {
      date: dateTimeUtils.convertToDtoString(details.date),
      shiftId: details.shift.id,
      absenceCode: details.absenceReason.code,
      replacedEmployeeId: details.hasReplacedEmployee ? details.replacedEmployee.id : null
    };

    return { details: [detail] };
  }

  private mapAbsence(absence: IScheduleAbsence): ScheduleAbsence {
    const scheduleAbsence: ScheduleAbsence = new ScheduleAbsence();
    scheduleAbsence.code = absence.code;
    scheduleAbsence.description = absence.code;
    scheduleAbsence.color = absence.color;
    scheduleAbsence.isPaid = absence.isPaid;
    scheduleAbsence.ptoPlannerIndicator = absence.ptoPlannerIndicator;
    scheduleAbsence.loaIndicator = absence.loaIndicator;

    return scheduleAbsence;
  }

  private mapAccruals(accruals: IEmployeeAccrual[]): EmployeeAccrual[] {
    const fieldsMeta: FieldsMeta = new FieldsMeta('LeaveRequestFakeMeta');
    fieldsMeta.actions = [];
    fieldsMeta.fields = this.mapFieldData(accruals);
    const empAccruals: EmployeeSectionAccrualsBalances = this.empSectionsMapService.mapToAccrualsBalances({ records: accruals } as IEmployeeSectionAccrualsBalances, fieldsMeta);

    return empAccruals.records;
  }

  private mapFieldData(accruals: IEmployeeAccrual[]): IFieldData[] {
    const accrual: IEmployeeAccrual = accruals[0];
    return _.map(accrual, (value: any, key: string) => {
      let data: IFieldData = {
        access: FieldAccessType.read,
        displayName: _.startCase(key),
        fieldName: key,
        fieldValue: value,
        fieldLimitInfo: {} as IFieldLimit,
        isTemporal: false,
        temporalMinDate: null
      };

      return data;
    });
  }
}
