import { EmployeeSectionsCustomFields, IEmployeeSectionsCustomFields } from './employee-sections-custom-fields';
import { IEmployeeSectionsSubsectionContainer } from '../index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSectionsCustom  {
  customFields: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsCustomFields>;
}

export class EmployeeSectionsCustom extends EmployeeSectionsBase {
  /*
  public static dataMock: IEmployeeSectionsEmployment = {
    payroll: {
      data: EmployeeSectionsPayroll.dataMock,
      metadata: EmployeeSectionsPayroll.metaMock,
    }

  }*/
  public customFields: EmployeeSectionsCustomFields;
}
