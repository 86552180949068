/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./leave-request.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "../leave-request-toolbar/leave-request-toolbar.component.ngfactory";
import * as i5 from "../../../common/services/state-management/state-management.service";
import * as i6 from "../../../common/services/component-state/component-state-storage.service";
import * as i7 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i8 from "../leave-request-toolbar/leave-request-toolbar.component";
import * as i9 from "../../services/leave-request/leave-request-api.service";
import * as i10 from "../leave-request-grid/leave-request-grid.component.ngfactory";
import * as i11 from "../leave-request-grid/leave-request-grid.component";
import * as i12 from "../../../organization/services/schedule/schedule-cycle-helper.service";
import * as i13 from "../../../app-settings/services/app-settings-manage.service";
import * as i14 from "../../../common/services/modal/modal.service";
import * as i15 from "../../../core/services/user/user.service";
import * as i16 from "@angular/router";
import * as i17 from "./leave-request.component";
import * as i18 from "../../../organization/services/lookup/lookup-api.service";
var styles_LeaveRequestComponent = [i0.styles];
var RenderType_LeaveRequestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeaveRequestComponent, data: {} });
export { RenderType_LeaveRequestComponent as RenderType_LeaveRequestComponent };
export function View_LeaveRequestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "slx-leave-request-toolbar", [["class", "slx-content-toolbar-indents"]], null, [[null, "onDatesChanged"], [null, "onFiltersChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDatesChanged" === en)) {
        var pd_0 = (_co.onDatesChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("onFiltersChanged" === en)) {
        var pd_1 = (_co.onFiltersChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_LeaveRequestToolbarComponent_0, i4.RenderType_LeaveRequestToolbarComponent)), i1.ɵprd(512, null, i5.StateManagementService, i5.StateManagementService, [i6.ComponentStateStorageService, i7.ColumnSettingsStorageService]), i1.ɵdid(5, 245760, null, 0, i8.LeaveRequestToolbarComponent, [i5.StateManagementService, i6.ComponentStateStorageService, i9.LeaveRequestApiService], { startDate: [0, "startDate"], endDate: [1, "endDate"] }, { onDatesChanged: "onDatesChanged", onFiltersChanged: "onFiltersChanged" }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "slx-leave-request-grid", [["class", "slx-high-box__body slx-main-content-indents"]], null, [[null, "onLoading"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLoading" === en)) {
        var pd_0 = (_co.onUpdateLoading($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_LeaveRequestGridComponent_0, i10.RenderType_LeaveRequestGridComponent)), i1.ɵdid(7, 245760, null, 0, i11.LeaveRequestGridComponent, [i9.LeaveRequestApiService, i12.ScheduleCycleHelperService, i13.AppSettingsManageService, i14.ModalService, i15.UserService, i16.Router, i16.ActivatedRoute], { incomingLeaveRequests: [0, "incomingLeaveRequests"], filters: [1, "filters"], absencesCodes: [2, "absencesCodes"], orgLevel: [3, "orgLevel"], actions: [4, "actions"] }, { onLoading: "onLoading" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.startDate; var currVal_2 = _co.endDate; _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.leaveRequests; var currVal_4 = _co.filters; var currVal_5 = _co.absencesCodes; var currVal_6 = _co.currentOrgLevel; var currVal_7 = _co.actions; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_LeaveRequestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-leave-request", [], null, null, null, View_LeaveRequestComponent_0, RenderType_LeaveRequestComponent)), i1.ɵdid(1, 245760, null, 0, i17.LeaveRequestComponent, [i9.LeaveRequestApiService, i18.LookupApiService, i16.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeaveRequestComponentNgFactory = i1.ɵccf("slx-leave-request", i17.LeaveRequestComponent, View_LeaveRequestComponent_Host_0, {}, {}, []);
export { LeaveRequestComponentNgFactory as LeaveRequestComponentNgFactory };
