/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./punch-attestation-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i3 from "@angular/common";
import * as i4 from "./punch-attestation-header.component";
import * as i5 from "../../services/punch-attestation-management.service";
var styles_PunchAttestationHeaderComponent = [i0.styles];
var RenderType_PunchAttestationHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PunchAttestationHeaderComponent, data: {} });
export { RenderType_PunchAttestationHeaderComponent as RenderType_PunchAttestationHeaderComponent };
function View_PunchAttestationHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["slxTooltip", "You are not an authorized user to add groups"], ["tipPosition", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var currVal_0 = "You are not an authorized user to add groups"; var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PunchAttestationHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["slxTooltip", "You are not an authorized user to add legacy clock group"], ["tipPosition", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i2.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var currVal_0 = "You are not an authorized user to add legacy clock group"; var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PunchAttestationHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "slx-button slx-with-icon legacyclock"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addLegacyGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "slx-legacy-button_icon far fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Legacy clock question group "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PunchAttestationHeaderComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isConfigure == false); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableLegacyButton; _ck(_v, 0, 0, currVal_0); }); }
export function View_PunchAttestationHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "slx-button slx-with-icon slateclock"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "slx-slate-button_icon far fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add question group "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PunchAttestationHeaderComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PunchAttestationHeaderComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.disableSalteButton; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.isLegacy)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableSalteButton; _ck(_v, 0, 0, currVal_0); }); }
export function View_PunchAttestationHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-punch-attestation-header", [], null, null, null, View_PunchAttestationHeaderComponent_0, RenderType_PunchAttestationHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.PunchAttestationHeaderComponent, [i5.PunchAttestationManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PunchAttestationHeaderComponentNgFactory = i1.ɵccf("slx-punch-attestation-header", i4.PunchAttestationHeaderComponent, View_PunchAttestationHeaderComponent_Host_0, { orgLevel: "orgLevel" }, {}, []);
export { PunchAttestationHeaderComponentNgFactory as PunchAttestationHeaderComponentNgFactory };
