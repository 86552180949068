import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, Provider, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NewEnrollmentModel, FormulaTypeEnum } from '../../../../models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { BenefitEnrollmentCommonService } from '../../../../../../../app/app-modules/benefits/services';
import { ModalService } from '../../../../../../common/services';
import { LookupService } from '../../../../../../organization/services';
import { DialogOptions, IDialog, DialogOptions2 } from '../../../../../../common/models';
import { appConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EnrollEmployeeFormulaComponent = /** @class */ (function () {
    function EnrollEmployeeFormulaComponent(model, apiService, commonValidationService, options, modalService, lookupService, changeDetector, man) {
        this.model = model;
        this.apiService = apiService;
        this.commonValidationService = commonValidationService;
        this.options = options;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.changeDetector = changeDetector;
        this.man = man;
        this.minAmt = 0;
        this.maxAmt = 99999999.99;
        this.stepcurrency = 0.01;
        this.hasError = false;
        this.isCovCalculated = true;
        this.editedItemCoverage = null;
        this.subscriptions = {};
        this.appConfig = appConfig;
    }
    EnrollEmployeeFormulaComponent.openDialog = function (model, apiService, modalService, lookupService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.width = 1050;
        dialogOptions.height = 400;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: LookupService,
                useValue: lookupService
            },
            {
                provide: ModalService,
                useValue: modalService
            },
            {
                provide: NewEnrollmentModel,
                useValue: model
            },
            {
                provide: EmployeeSectionsBenefitsManagementApiService,
                useValue: apiService
            }
        ];
        var title = "Enroll Employee in Benefits";
        var component = modalService.globalAnchor.openDialog2(EnrollEmployeeFormulaComponent, title, dialogOptions, resolvedProviders, callback);
        return component;
    };
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "userCantEnroll", {
        get: function () {
            return !this.hasSelected
                || !this.hasSelectedValidTierFormula
                || !_.isFinite(this.settings.fixedAmount) && !_.isFinite(this.settings.formulaValue)
                || (this.isFixed ? _.lt(this.settings.fixedAmount, 0) : _.lt(this.settings.formulaValue, 0));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasSelectedValidTierFormula", {
        get: function () {
            return this.hasEmpContribution && this.hasErContribution;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasSelected", {
        get: function () {
            return !_.isNil(this.selectedTier);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasEmpContribution", {
        get: function () {
            if (this.isEmpFormulaType) {
                return this.hasEmpFormulaExpression && this.hasSettings && !_.isEqual(this.settings.empContribution, -1);
            }
            else {
                return this.hasSettings &&
                    _.gte(this.settings.empContribution, 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasErContribution", {
        get: function () {
            if (this.isErFormulaType) {
                return this.hasErFormulaExpression && this.hasSettings && !_.isEqual(this.settings.erContribution, -1);
            }
            else {
                return this.hasSettings &&
                    _.gte(this.settings.erContribution, 0);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasCoverage", {
        get: function () {
            if (!this.hasSettings)
                return false;
            if (this.isFixed)
                return _.isNumber(this.settings.fixedAmount) && this.settings.fixedAmount >= 0;
            if (this.anyWithMaxCap)
                return _.isNumber(this.settings.maxCap) && this.settings.maxCap > 0;
            if (this.isFormula)
                return this.hasCovFormulaExpression && !_.isEqual(this.settings.formulaValue, -1);
            if (this.multiplerMaxCap)
                return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasSettings", {
        get: function () {
            return !_.isNil(this.settings);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "anyWithMaxCap", {
        get: function () {
            return this.settings && this.settings.formulaType === FormulaTypeEnum.AnyWithMaxCap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "multiplerMaxCap", {
        get: function () {
            return this.settings && this.settings.formulaType === FormulaTypeEnum.MultiplerMaxCap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "isFixed", {
        get: function () {
            return this.settings && this.settings.formulaType === FormulaTypeEnum.Fixed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "isFormula", {
        get: function () {
            return this.settings && this.settings.formulaType === FormulaTypeEnum.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "isEmpFormulaType", {
        get: function () {
            return this.settings && this.settings.empFormulaType === FormulaTypeEnum.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "isErFormulaType", {
        get: function () {
            return this.settings && this.settings.erFormulaType === FormulaTypeEnum.Formula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasEmpFormulaExpression", {
        get: function () {
            return this.settings && !_.isEmpty(this.settings.empFormulaExpression);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasErFormulaExpression", {
        get: function () {
            return this.settings && !_.isEmpty(this.settings.erFormulaExpression);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployeeFormulaComponent.prototype, "hasCovFormulaExpression", {
        get: function () {
            return this.settings && !_.isEmpty(this.settings.covFormulaExpression);
        },
        enumerable: true,
        configurable: true
    });
    EnrollEmployeeFormulaComponent.prototype.ngOnDestroy = function () {
    };
    EnrollEmployeeFormulaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.groupName = this.model.plan.name;
        this.effectiveDate = this.model.effectiveDate;
        this.payrollDedStartDate = this.model.effectiveDate;
        this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
        this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
        this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
        this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
        this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
        this.endDate = this.model.plan.endDate;
        this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
        this.canMapPayroll = this.model.canMapPayroll;
        this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
            .then(function (tiers) {
            _this.tiers = tiers;
            _this.isLoading = false;
        });
        this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate(function (hasError) {
            _this.hasError = hasError;
        });
    };
    EnrollEmployeeFormulaComponent.prototype.onChangeTier = function (tier) {
        var _this = this;
        this.isCovCalculated = true;
        this.selectedTier = tier;
        this.isLoading = true;
        this.effectiveDate = this.model.effectiveDate;
        var empId = this.model.empId;
        this.apiService.getBenefitPlanFormula(tier.id, empId, this.effectiveDate, null, '')
            .then(function (settings) {
            _this.settings = settings;
            _this.maxAmt = settings.maxCap;
            if (_this.multiplerMaxCap || _this.anyWithMaxCap) {
                var options = [];
                if (_this.multiplerMaxCap) {
                    var mult = _.isNumber(settings.multipler) ? settings.multipler : 500;
                    var cap = _.isNumber(settings.maxCap) ? settings.maxCap : 10000;
                    var range = _.range(mult, cap + mult, mult);
                    options = _.map(range, function (value) { return ({ name: _.toString(value), value: value }); });
                }
                _this.coverageMultipllierOptions = options;
            }
            else {
                _this.settings.formulaValue = _.isFinite(_this.settings.formulaValue) ? _this.settings.formulaValue : null;
                _this.coverageMultipllierOptions = [];
            }
            _this.isLoading = false;
            _this.validateFormula();
        });
    };
    EnrollEmployeeFormulaComponent.prototype.validateFormula = function () {
        if (this.settings.erFormulaExpression || this.settings.empFormulaExpression || this.settings.covFormulaExpression) {
            this.formulaSelectionStart(true);
        }
    };
    EnrollEmployeeFormulaComponent.prototype.onChangeCoverageWithMultiplier = function (value) {
        var numericValue = parseInt(value);
        if (isNaN(numericValue)) {
            this.settings.formulaValue = null;
            return;
        }
        this.settings.formulaValue = value;
        this.calculateFormula();
    };
    EnrollEmployeeFormulaComponent.prototype.calculateFormula = function () {
        var _this = this;
        if (this.settings.calculatedCoverage !== this.settings.formulaValue && !_.isNil(this.settings.formulaValue)) {
            if (this.isFormula || this.multiplerMaxCap || this.anyWithMaxCap) {
                this.isLoading = true;
                this.effectiveDate = this.model.effectiveDate;
                var empId = this.model.empId;
                this.settings.calculatedCoverage = this.settings.formulaValue;
                this.apiService.getBenefitPlanFormula(this.selectedTier.id, empId, this.effectiveDate, this.settings.formulaValue, '')
                    .then(function (record) {
                    if (record) {
                        _this.settings.calculatedCoverage = record.calculatedCoverage;
                        _this.settings.empContribution = record.empContribution;
                        _this.settings.erContribution = record.erContribution;
                        _this.settings.maxCap = _this.maxAmt = record.maxCap;
                        if (_this.multiplerMaxCap || _this.anyWithMaxCap) {
                            _this.settings.formulaValue = record.calculatedCoverage;
                        }
                    }
                }).finally(function () {
                    _this.isLoading = false;
                    _this.changeDetector.markForCheck();
                    _this.changeDetector.detectChanges();
                });
            }
        }
    };
    EnrollEmployeeFormulaComponent.prototype.onCoverageValueChange = function (value) {
        this.settings.formulaValue = value;
        this.isCovCalculated = false;
    };
    EnrollEmployeeFormulaComponent.prototype.onEnroll = function (element) {
        if (element == "Enroll") {
            this.model.formulaSettings = this.settings;
            this.dialogResult = true;
            this.isCovCalculated = false;
            this.modalService.closeWindow(this.options.windowUniqueId);
        }
        else if (element == "Calculate") {
            this.calculateFormula();
            this.isCovCalculated = true;
        }
    };
    EnrollEmployeeFormulaComponent.prototype.onCancel = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Discard Changes', 'Are you sure you want to cancel? You will lose all unsaved selections.', this.modalService, function (isCancel) {
            if (isCancel) {
                _this.dialogResult = false;
                _this.modalService.closeWindow(_this.options.windowUniqueId);
            }
        }, options);
    };
    EnrollEmployeeFormulaComponent.prototype.getFormulaToolTip = function () {
        return this.invalidFormulaMessage ? this.invalidFormulaMessage : 'Employee cannot be enrolled as amounts have not been created under coverage options for this benefit plan. Please create coverage options for this plan to enroll';
    };
    EnrollEmployeeFormulaComponent.prototype.formulaSelectionStart = function (wrongValue) {
        var _this = this;
        var message = this.commonValidationService.getFormulaError(this.isEmpFormulaType, this.isErFormulaType, this.isFormula, this.settings.empContribution, this.settings.erContribution, this.settings.formulaValue);
        if ((_.size(this.coverageMultipllierOptions) === 0 || wrongValue) && _.size(message) > 0) {
            var options = new ConfirmOptions();
            options.showCancel = false;
            options.showOK = true;
            ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
                _this.invalidFormulaMessage = message;
            }, options);
        }
    };
    EnrollEmployeeFormulaComponent.prototype.OnDeductionEndDateChange = function (date) {
        this.model.plan.payrollDeductionEndDate = date;
    };
    EnrollEmployeeFormulaComponent.prototype.OnDeductionStartDateChange = function (date) {
        this.model.plan.payrollDeductionStartDate = date;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EnrollEmployeeFormulaComponent.prototype, "subscriptions", void 0);
    return EnrollEmployeeFormulaComponent;
}());
export { EnrollEmployeeFormulaComponent };
