import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, HostListener, ElementRef } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { MessageList, IMessagePayload, SectionState, SectionDetail, MessageCountResponse, ISectionDetail, IMessageCountRequest, sectionType, ISectionMessagesRequest, TabMessages, IMessageDataAndCountRequest } from '../../models/message-list'
import { MessageApiService } from '../../services/message-api.service';
import { appConfig, IApplicationConfig } from '../../../../../app/app.config';
import * as moment from 'moment';
import { SortService } from '../../services/sort.service';
import { MessagesManagementService } from '../../services/messages-management.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { NotificationsApiService } from '../../../../../app/portal/services';
import { mutableSelect, unsubscribe, unsubscribeAll } from '../../../../core/decorators';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../../app/state-model/models';
import { IMessageList, IEmployeeProfilePicture, IUpdateRequest } from '../../models/message-list';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../common/index';

@Component({
  moduleId: module.id,
  selector: 'slx-message-list',
  templateUrl: 'message-list.component.html',
  styleUrls: ['message-list.component.scss'],
  providers: [SortService]
})
export class MessageListComponent implements OnInit, OnDestroy {
  public appConfig: IApplicationConfig = appConfig;
  public gridState: KendoGridStateHelper<MessageList>;
  public isLoading: boolean;
  public messageList = [];
  public data;
  public sortingOptions = ['Newest To Oldest', 'Oldest To Newest', 'Name A to Z', 'Name Z to A'];
  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;
  @Input() IsArchive: boolean;
  isAllSelected: boolean = false;
  @Output() getArchiveReadUnreadCount = new EventEmitter<any>();
  searchText: string = '';
  msgArray = ['less than one month', 'Older than one month', 'Older than three months']
  selectedSortFilter: string = 'Newest To Oldest';
  messageRange = [];
  lessThanOneMonth = [];
  olderThanOneMonth = [];
  olderThanThreeMonth = [];
  olderThanSixMonth = [];
  selectedMessageIds: string[] = [];
  @Output() updateMsgCenterSelectedMessageIds = new EventEmitter<any>();
  @Input() isExpanded: boolean;
  @Input() selectedReadIds: string[];
  @Input() selectedUnreadIds: string[];
  @Input() isMyMessage: boolean = true;
  searchList = [];
  currentPageno = 1;
  hotStorageCurrentPageNo = 0;
  @Input() selectedId = '';
  orgLevelId = null;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private orgLevel: OrgLevel;
  @mutableSelect('orgLevel')
  public orgLevel$: Observable<any>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  selectedEmp = null;
  selectedAll = false;
  searchInput: string = '';
  dateOrderByAscending = null;
  nameOrderByAscending = null;
  public searchTerm$ = new Subject<string>();
  selectedCheckBoxEvent = '';
  unSelectedemployess = [];
  private pageSize: number = 100;
  @Output() sendSelectedEventToParent = new EventEmitter<any>();
  @Input() messageCountObj: any;
  isColdStorageData = false;
  requestObj: IMessagePayload = {
    currentPageno: 1,
    IsArchive: false,
    orgLevelId: 1,
    dateOrderByAscending: false,
    nameOrderByAscending: null,
    includeCold: false
  }
  @Output() isDataLoading = new EventEmitter<any>();
  coldStorageFlag: any;
  private messageCountRecords:MessageCountResponse[];
  private sectionsDetail: SectionDetail[];

  public sectionTypesMap: StringMap<SectionState>={};
  public iMessageDataAndCountRequestState:IMessageDataAndCountRequest;
  private modalService: ModalService;
  constructor(private sortService: SortService, private messageManService: MessagesManagementService, private api: NotificationsApiService, private elementRef: ElementRef,modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<MessageList>();
    this.gridState.view = null;
    this.IsArchive = false;
    this.modalService = modalService;
  }

  ngOnInit() {
    this.messageRange = ['lessthanmonth', 'olderthanonemonth', 'olderthanthreemonth', 'loadolderThan6Month'];
    this.orgLevelSubscription = this.orgLevel$.subscribe((level: OrgLevel) => {
      if (level && level.id && ((this.orgLevel && (level.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevelId = level.id;
        this.requestObj.orgLevelId = this.orgLevelId;
        this.bindPageLoadData();
      }
    });

    this.subscriptions.messageFilter = this.messageManService.subscribeToswitchMessageFilter((res: boolean) => {
      this.isMyMessage = res;
      this.bindPageLoadData();
    });
   
    this.subscriptions.waitingPopup = this.messageManService
      .subscribeToWaitingPopup((isShow: boolean) => this.showWaitingPopup(isShow));

    this.subscriptions.id = this.messageManService.subscribeToGetMessageId((id: string) => {
      this.selectedId = id;
    });

    this.subscriptions.alert = this.api.subcribeToNewmessageAlert((res: IMessageList) => {
      this.updateMessage(res.employeeId)
    });

    this.subscriptions.refresh = this.messageManService.subscribeRefreshMessageList((res: IMessageList) => {
      this.updateMessagesList(res);
    });

    this.requestObj.IsArchive = this.IsArchive;
    this.requestObj.includeCold = false;
    this.messageManService.setRequestParams(this.requestObj);

    this.subscriptions.searchEmployee = this.messageManService.searchEmployee(this.searchTerm$)
      .subscribe((v) => {
        if(v)
        {
          this.clearData();
          this.messageCountRecords = v;
          _.each(this.messageCountRecords,(item:MessageCountResponse)=>{
            this.updateSectionMapWithTotalCount(String(item.sectionName),item.messageCount);
          });
          this.getTabMessagesRecords(sectionType.lessThanOneMonth);        
          this.refreshGrid();
      
        }
      });


      this.subscriptions.TabsMessageCounts = this.messageManService.subscribeToTabsMessagesCount((v: MessageCountResponse[]) => {
        this.messageCountRecords = v;
        _.each(this.messageCountRecords,(item:MessageCountResponse)=>{
          this.updateSectionMapWithTotalCount(String(item.sectionName),item.messageCount);
        });       
      });

      this.subscriptions.MoreTabsMessageRecords = this.messageManService.subscribeToMoreTabMessages((v: TabMessages) => {
          if (v.messages.length>0) {
            this.updateTabMessageRecords(v);          
          } else {
            this.refreshGrid();
            this.isLoading = false;       
          }
   });
      this.subscriptions.TabsMessageRecords = this.messageManService.subscribeToTabMessages((v: TabMessages) => {
           if (v.messages.length>0) {
          this.updateTabMessageRecords(v);          
        } else {

          this.refreshGrid();
          this.isLoading = false;
          this.isDataLoading.emit(false);
        }
      });

    this.subscriptions.recentMessage = this.messageManService.subscribeToRecentMessage((res => {
      let isItemFound=false;
      let foundItem:any;
      let allList:any[]=this.allMessageList();
      _.each(allList,(item:any,i)=>{
        const index = item.findIndex(x => x.employeeId === res.employeeId);
        let selectedEmp = item.filter(x => x.id === this.selectedId).map(y => y.employeeId)[0];
        if (index > -1) {
          isItemFound=true;
          item[index]['id'] = res['id'];
          item[index]['created'] = res['created'];
          item[index]['modified'] = res['modified'];
          item[index]['body'] = res['body'];
          item[index]['replyCount'] = res['replyCount'];
          item[index]['mobilePhoneNumber'] = res['mobilePhoneNumber'];
          item[index]['optIn'] = res['optIn'];
          item[index]['orgLevelId'] = res['orgLevelId'];
          item[index]['photo'] = res['photo'];
          item[index]['status'] = res['status'];
          item[index]['isOnLeave'] = res['isOnLeave'];
          item[index]['fullName'] = res['employeeName'];
          item[index]['employeeName'] = res['employeeName'];
          if(selectedEmp === res['employeeId']) {
            let rowData = item.filter(x => x.employeeId === selectedEmp)[0];
            this.selectedId = rowData.id;
            this.messageManService.updateMessageThread(rowData, this.orgLevelId,true,this.isMyMessage);
            this.scrollToTop();
          } else  {
            item[index]['read'] = res['read'];
          }
          foundItem=_.cloneDeep(item[index]);
          _.remove(item, { employeeId: res.employeeId });                 
          this.sortClientSide(this.selectedSortFilter);  
        } 
      });
      if(isItemFound) {  
        this.lessThanOneMonth=this.lessThanOneMonth.concat(foundItem);      
      }
      else{
        this.lessThanOneMonth=this.lessThanOneMonth.concat(res);  
      }                
      this.lessThanOneMonth = this.sortService.messageSort(this.lessThanOneMonth, this.selectedSortFilter);  
      this.sortClientSide(this.selectedSortFilter);  
      
    }));

    this.subscriptions.archiveMessages = this.messageManService.subscribeToUpdateArchiveMessages(() => {
      this.onToggelSelected('None');
      this.isAllSelected = false;
      let allList:any[]=this.allMessageList();
      _.each(allList,(item:any,i)=>{
          const selectedEmpId = item.filter(x => x.id === this.selectedId).map(y => y.employeeId)[0];
          if (selectedEmpId) {            
            this.messageManService.emptyMessageThread();
          }
      });
      allList=[];
      this.unSelectedemployess = [];
      this.currentPageno = 1;
      this.requestObj.currentPageno = this.currentPageno;
      this.messageManService.setRequestParams(this.requestObj);
      this.clearData();
      this.switchTabContent();
      this.isLoading=false;
      this.selectedAll = false;
      this.getArchiveReadUnreadCount.emit(true);
      this.messageManService.callUnReadCountApi();
    });

    this.subscriptions.unArchiveMessages = this.messageManService.subscribeToUpdateUnarchiveMessages((res => {
      let selectedIds = _.cloneDeep(this.selectedMessageIds);
      this.onToggelSelected('None');
      this.isAllSelected = false;
      let allList:any[]=this.allMessageList();
      _.each(allList,(item:any,i)=>{
        const selectedEmpId = item.filter(x => x.id === this.selectedId).map(y => y.employeeId)[0];
        if (selectedEmpId) {
          this.messageManService.emptyMessageThread();
        }
      });
      allList=[];
      this.unSelectedemployess = [];
      this.currentPageno = 1;
      this.requestObj.currentPageno = this.currentPageno;
      this.messageManService.setRequestParams(this.requestObj);
      this.clearData();
      this.switchTabContent();
      this.isLoading=false;
      this.getArchiveReadUnreadCount.emit(true);
      this.selectedAll = false;
      this.messageManService.callUnReadCountApi();
    }));

    this.subscriptions.updateReadMessages = this.messageManService.subscribeToUpdateReadMessages((res => {
      let selectedIds = _.cloneDeep(this.selectedMessageIds);
      this.onToggelSelected('None');
      this.isAllSelected = false;
      let allList:any[]=this.allMessageList();
      _.each(allList,(item:any,i)=>{
        if (this.selectedAll) {        
          item.map(y => y.read = true);
        } else {
          selectedIds.forEach(element => {
            item.filter(x => x.employeeId === element).map(y => y.read = true);
          });
        }
      });
      allList=[];
      this.sortClientSide(this.selectedSortFilter);
      this.selectedAll = false;
      this.unSelectedemployess = [];
      this.getArchiveReadUnreadCount.emit(true);
      this.messageManService.callUnReadCountApi();
      this.isLoading = false;
    }));

    this.subscriptions.updateUnreadMessages = this.messageManService.subscribeToUpdateUnreadMessages((res => {
      let selectedIds = _.cloneDeep(this.selectedMessageIds);
      this.onToggelSelected('None');
      this.isAllSelected = false;
      let allList:any[]=this.allMessageList();
      _.each(allList,(item:any,i)=>{
        if (this.selectedAll) {
          item.map(y => y.read = false);
        } else {
          selectedIds.forEach(element => {
            item.filter(x => x.employeeId === element).map(y => y.read = false);
          });
        }
      });
      allList=[];

      this.sortClientSide(this.selectedSortFilter);
      this.selectedAll = false;
      this.unSelectedemployess = [];
      this.getArchiveReadUnreadCount.emit(true);
      this.messageManService.callUnReadCountApi();
      this.isLoading = false;
    }));
  }

  private bindPageLoadData(): void {
    this.currentPageno = 1;
    this.requestObj.currentPageno = this.currentPageno;
    this.messageManService.setRequestParams(this.requestObj);
    this.messageList = [];
    this.messageManService.emptyMessageThread();
    this.switchTabContent();
  }



  ngOnDestroy(): void {}


  updateMessage(employeeId: number) {
    if (this.IsArchive) {
      let allList:any[]=this.allMessageList();
      _.each(allList,(item:any)=>{
        let selectedEmp = item.filter(x => x.id === this.selectedId).map(y => y.employeeId)[0];
        const index = item.findIndex(x => x.employeeId === employeeId);
        if(index > -1)
        {
        item.splice(index, 1);
        this.getArchiveReadUnreadCount.emit(true);
        this.messageManService.callUnReadCountApi();
        }
        if (selectedEmp === employeeId) {
          this.messageManService.emptyMessageThread();
        }
      });
      allList = [];
    }
    else {
      this.messageManService.getRecentMessageByEmployeeId(employeeId,this.isMyMessage);
    }
  }
  public scrollToTop() {
    if (!this.elementRef.nativeElement) {
      return false;
    }
    const msgListElements = this.elementRef.nativeElement.querySelectorAll('#emp-msg-list');
    if (msgListElements && msgListElements[msgListElements.length - 1]) {
      msgListElements[0].scrollIntoView(true);
    }
  }

  public switchTabContent():void {    
    this.onToggelSelected('None');
    this.updateMsgCenterSelectedMessageIds.emit([]);
    this.clearData();    
    this.getMessageDataWithCount();
    this.selectedId='';
    this.selectedAll = false;
  }

  private clearData():void
  {
    
    this.messageRange=[];
    this.lessThanOneMonth=[];
    this.olderThanSixMonth=[];
    this.olderThanThreeMonth=[];
    this.olderThanOneMonth=[];
    this.setMessageRange();
    this.setCurrentSections();
    this.messageManService.emptyMessageThread();
  }
  public getSectionToLoad(): string
  { 
    let sectionName="";
    let sectionState:SectionState;  
    _.each(this.messageRange,(item:any)=>{
      if(sectionType.lessThanOneMonth==item && sectionName.length==0)
      {
        sectionState=this.sectionTypesMap[String(sectionType.lessThanOneMonth)];
        if(sectionState.loadCount<sectionState.totalCount && !sectionState.isCollapsed)
        {
          if((sectionState.loadCount==0 && !sectionState.isloaded )||(sectionState.loadCount>0 && sectionState.totalCount>this.pageSize) )
          sectionName=String(sectionState.sectionName); 
        }
                            
      }
      else if(sectionType.olderThanOneMonth==item &&sectionName.length==0)
      {
        sectionState=this.sectionTypesMap[String(sectionType.olderThanOneMonth)];
        if(sectionState.loadCount<sectionState.totalCount && !sectionState.isCollapsed)
        {
          if((sectionState.loadCount==0 && !sectionState.isloaded )||(sectionState.loadCount>0 && sectionState.totalCount>this.pageSize) )
          sectionName=String(sectionState.sectionName); 
        } 
      }
      else if(sectionType.olderThanThreeMonth==item && sectionName.length==0)
      {
        sectionState=this.sectionTypesMap[String(sectionType.olderThanThreeMonth)];
        if(sectionState.loadCount<sectionState.totalCount && !sectionState.isCollapsed)
        {
          if((sectionState.loadCount==0 && !sectionState.isloaded )||(sectionState.loadCount>0 && sectionState.totalCount>this.pageSize) )
          sectionName=String(sectionState.sectionName); 
        } 
      }
      else if(sectionType.olderThanSixMonth==item && sectionName.length==0)
      {
        sectionState=this.sectionTypesMap[String(sectionType.olderThanSixMonth)];
        if(sectionState.loadCount<sectionState.totalCount && !sectionState.isCollapsed)
        {
          if((sectionState.loadCount==0 && !sectionState.isloaded )||(sectionState.loadCount>0 && sectionState.totalCount>this.pageSize) )
          sectionName=String(sectionState.sectionName); 
        }
      }                  
    });    
    return sectionName;
  }
  private getTabMessagesRecords(sectionName?:string,isReset?:boolean) {
    
    if(_.isNil(sectionName)||_.isEmpty(sectionName))
    {
      sectionName=this.getSectionToLoad();  
    }

    if(sectionName.length>0)
    {
    this.isLoading = true;
   
      if(!this.IsArchive)
      {
      this.selectedReadIds.splice(0, this.selectedReadIds.length);
      this.selectedUnreadIds.splice(0, this.selectedUnreadIds.length);
      }

      let sectionState:SectionState=this.sectionTypesMap[sectionName];       
    
      const iTabMessageRequest: ISectionMessagesRequest= {
        page: sectionState.currentPageNumber+1,
        pageSize: this.pageSize,
        employees: [],
        isArchived: this.IsArchive,
        searchText: this.searchText,
        orgLevelId: this.orgLevelId,
        dateOrderByAscending: this.dateOrderByAscending,
        nameOrderByAscending: this.nameOrderByAscending,
        includeCold: sectionState.includeCold,
        startDate:sectionState.startDate,
        endDate: sectionState.endDate,
        sectionName:sectionName,
        isReset: isReset,
        isMyMessage: this.isMyMessage
      };
      this.messageManService.getMoreTabMessages(iTabMessageRequest);
    }    
  }
  public getMessageDataWithCount(){
    this.isLoading = true;
    this.isDataLoading.emit(true);
    let iSections:ISectionDetail[]=[];
    _.each(this.sectionsDetail,(item:SectionDetail)=>{
        let secState:SectionState=this.sectionTypesMap[String(item.sectionName)];
          let iSection:ISectionDetail={  
          sectionName:secState.sectionName,
          startDate:secState.startDate,
          endDate:secState.endDate,
          includeCold:secState.includeCold,
          orderByNameAsc:this.nameOrderByAscending
          }
        iSections.push(iSection);
      });
    
    let iMessageCountRequest:IMessageCountRequest={
     isArchived:this.IsArchive,
     searchText:this.searchText,
     orgLevelId: this.orgLevelId,
     isMyMessage: this.isMyMessage,
     sections:iSections
    }

    let sectionState:SectionState=this.sectionTypesMap[sectionType.lessThanOneMonth];       
    
      const iTabMessageRequest: ISectionMessagesRequest= {
        page: sectionState.currentPageNumber+1,
        pageSize: this.pageSize,
        employees: [],
        isArchived: this.IsArchive,
        searchText: this.searchText,
        orgLevelId: this.orgLevelId,
        dateOrderByAscending: this.dateOrderByAscending,
        nameOrderByAscending: this.nameOrderByAscending,
        includeCold: sectionState.includeCold,
        startDate:sectionState.startDate,
        endDate: sectionState.endDate,
        sectionName:String(sectionState.sectionName),
        isReset: true,
        isMyMessage: this.isMyMessage
      };
      
      let iMessageDataAndCountRequest : IMessageDataAndCountRequest={
        messageCount:iMessageCountRequest,
        messageData:iTabMessageRequest
      }
      this.iMessageDataAndCountRequestState=iMessageDataAndCountRequest;
     this.messageManService.getTabsMessagesCount(iMessageCountRequest);
     this.messageManService.getTabMessages(iTabMessageRequest)
  }
  public getMessages(searchText, dateOrderByAscending, nameOrderByAscending, pageNo) {
    this.isLoading = true;
    this.isDataLoading.emit(true);
    if (!this.IsArchive)
    {
    this.selectedReadIds.splice(0, this.selectedReadIds.length);
    this.selectedUnreadIds.splice(0, this.selectedUnreadIds.length);
    }
    this.messageManService.getMessages(searchText, dateOrderByAscending, nameOrderByAscending, pageNo, this.orgLevelId, this.IsArchive, this.requestObj.includeCold, this.pageSize, this.isMyMessage);
  }

  getArchiveMessageList(searchText, dateOrderByAscending, nameOrderByAscending, pageNo) {
    this.isLoading = true;
    this.isDataLoading.emit(true);
    this.messageManService.getMessages(searchText, dateOrderByAscending, nameOrderByAscending, pageNo, this.orgLevelId, this.IsArchive, this.requestObj.includeCold, this.pageSize, this.isMyMessage);
  }

  public refreshGrid(): void {
    if (!this.messageRange) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.messageRange, this.gridState.state);
  }

  onFilter(event) {
    this.searchText = event;
    this.messageList = [];
    this.currentPageno = 1;
    this.requestObj.currentPageno = this.currentPageno;
    this.messageManService.setRequestParams(this.requestObj);
    this.switchTabContent();
  }

  public onToggleAllSelected(event, status: string) {
    this.selectedCheckBoxEvent = status;
    let allList:any[]=this.allMessageList();
   
    if (!allList ) return;
    else if (allList.length==0) return;
    if (event && status === 'All') {
      this.selectedAll = true;
      this.unSelectedemployess = [];
    } else {
      this.selectedAll = false;
    }
    _.each(allList,(item:any)=>{    
      item.forEach((element) => {
        const employeeId = element.employeeId;
        if ((status === 'Read' || status === 'All') && element.read === true) {
          element.isSelected = event;
          if (this.selectedReadIds.includes(employeeId)) {
            this.selectedReadIds.splice(this.selectedReadIds.findIndex((val) => val === employeeId), 1);
          } else {
            this.selectedReadIds.push(employeeId)
          }
        } else if ((status === 'Unread' || status === 'All') && (element.read === false || element.read === null)) {
          element.isSelected = event;
          if (this.selectedUnreadIds.includes(employeeId)) {
            this.selectedUnreadIds.splice(this.selectedUnreadIds.findIndex((val) => val === employeeId), 1);
          } else {
            this.selectedUnreadIds.push(employeeId)
          }
        } else if (status === 'None') {
          element.isSelected = false;
        }

        if (element.isSelected && event && !this.selectedMessageIds.includes(employeeId)) {
          this.selectedMessageIds.push(employeeId)
        } else if (this.selectedMessageIds.includes(employeeId)) {
          this.selectedMessageIds.splice(this.selectedMessageIds.findIndex((val) => val === employeeId), 1);
        }
        this.updateMsgCenterSelectedMessageIds.emit(this.selectedMessageIds);
      });
    });
    allList=[];
    if (!this.isAllSelected && this.selectedMessageIds.length === 0 && status === 'All') {
      this.sendSelectedEventToParent.emit('None');
      this.unSelectedemployess = [];
    } else {
      this.sendSelectedEventToParent.emit(this.selectedCheckBoxEvent);
    }
    if (this.messageCountObj) {
      if (status === 'None') {
        this.isAllSelected = false;
        this.selectedAll = false;
      } else if ((status === 'Unread' && this.messageCountObj.unRead !== this.messageCountObj.total) || (status === 'Read' && this.messageCountObj.unRead !== 0)) {
        this.isAllSelected = false;
        this.selectedAll = false;
      } else if ((status === 'Unread' && this.messageCountObj.unRead === this.messageCountObj.total) || (status === 'Read' && this.messageCountObj.unRead === 0)) {
        this.isAllSelected = true;
      } else if (this.selectedAll) {
        this.isAllSelected = true;
      }
    }

    this.messageList = _.cloneDeep(this.messageList);

    this.sortClientSide(this.selectedSortFilter);
  }

  updateMsgListSelectedMessageIds(item) {
    this.selectedCheckBoxEvent = '';
    if (item.read === true) {
      if (this.selectedReadIds.includes(item.employeeId)) {
        this.selectedReadIds.splice(this.selectedReadIds.findIndex((val) => val === item.employeeId), 1);
      } else {
        this.selectedReadIds.push(item.employeeId)
      }
    } else if (item.read === false) {
      if (this.selectedUnreadIds.includes(item.employeeId)) {
        this.selectedUnreadIds.splice(this.selectedUnreadIds.findIndex((val) => val === item.employeeId), 1);
      } else {
        this.selectedUnreadIds.push(item.employeeId)
      }
    }
    if (this.selectedMessageIds.includes(item.employeeId)) {
      this.selectedMessageIds.splice(this.selectedMessageIds.findIndex((val) => val === item.employeeId), 1);
    } else {
      this.selectedMessageIds.push(item.employeeId);
    }
    let allList:any[]=this.allMessageList();
    let len=0;
    _.each(allList,(item:any)=>{
      len=len+item.length;
    })
    this.isAllSelected = len === this.selectedMessageIds.length;
    this.updateMsgCenterSelectedMessageIds.emit(this.selectedMessageIds);
    allList=[];
  }

  onToggelSelected(selectedVal: string) {
    this.selectedReadIds.splice(0, this.selectedReadIds.length);
    this.selectedUnreadIds.splice(0, this.selectedUnreadIds.length);
    this.selectedCheckBoxEvent = selectedVal;
    this.unSelectedemployess = [];
    this.onToggleAllSelected(false, 'None');
    switch (selectedVal) {
      case 'All':
        this.onToggleAllSelected(true, 'All');
        break;
      case 'Read':
        this.onToggleAllSelected(true, 'Read');
        break;
      case 'Unread':
        this.onToggleAllSelected(true, 'Unread');
        break;
      case 'None':
        break;
    }
  }



  public collapsedChange(event: any, collapseName: string): void {
    let sectionState:SectionState=this.sectionTypesMap[collapseName];
    sectionState.isCollapsed=event;
    this.sectionTypesMap[collapseName]=sectionState;
    if(sectionState.loadCount==0)
    {
      this.loadSectionData(sectionState); 
    }
  }
  private loadSectionData(sectionState:SectionState):void{
    this.getTabMessagesRecords(String(sectionState.sectionName));
  }
  messageSort(type) {
    this.selectedSortFilter = type;
    this.messageList = [];
    this.currentPageno = 1;
    if (type === 'Name Z to A' || type === 'Name A to Z') {
      this.dateOrderByAscending = null;
      if (type === 'Name A to Z') {
        this.nameOrderByAscending = true;
      } else {
        this.nameOrderByAscending = false;
      }
    } else if (type === 'Newest To Oldest' || type === 'Oldest To Newest') {
      this.nameOrderByAscending = null;
      if (type === 'Newest To Oldest') {
        this.dateOrderByAscending = false;
      } else {
        this.dateOrderByAscending = true;
      }
    }
    this.requestObj.nameOrderByAscending = this.nameOrderByAscending;
    this.requestObj.dateOrderByAscending = this.dateOrderByAscending;
    this.requestObj.currentPageno = this.currentPageno;
    this.requestObj.includeCold = false;
    this.messageManService.setRequestParams(this.requestObj);
    this.switchTabContent();
    this.scrollToTop();
  }

  public updateArchiveMessages() {
    const obj = {
      "employees": this.selectedMessageIds ,
      "excludeEmployees": [],
      "all": false,
      "orgLevelId": this.orgLevelId,
      "isArchived": this.IsArchive,
      "allUnRead": false,
      "allRead":  false,
      "includeCold": this.requestObj.includeCold
    }

    this.isLoading = true;
    this.messageManService.updateArchiveMessages(obj);
  }

  updateUnArchiveMessages() {
    const obj = {
      "employees": this.selectedMessageIds,
      "excludeEmployees":[],
      "all": false,
      "orgLevelId": this.orgLevelId,
      "isArchived": this.IsArchive,
      "allUnRead":  false,
      "allRead":  false,
      "includeCold": this.requestObj.includeCold
    }


    this.isLoading = true;
    this.messageManService.updateUnArchiveMessages(obj);
  }

  public updateReadMessages() {
    let obj = {
      "employees":  this.selectedMessageIds,
      "excludeEmployees": [],
      "all": false,
      "orgLevelId": this.orgLevelId,
      "isArchived": this.IsArchive,
      "allUnRead": false,
      "allRead": false,
      "includeCold": this.requestObj.includeCold
    }

    this.isLoading = true;
    this.messageManService.updateReadMessages(obj);
  }

  updateUnreadMessages() {
    let selectedIds = _.cloneDeep(this.selectedMessageIds);
    const obj = {
      "employees": this.selectedMessageIds,
      "excludeEmployees": [],
      "all": false,
      "orgLevelId": this.orgLevelId,
      "isArchived": this.IsArchive,
      "allRead":  false,
      "allUnRead": false,
      "includeCold": this.requestObj.includeCold
    }

    this.isLoading = true;
    this.messageManService.updateUnreadMessages(obj);
  }

  onScroll() {
    const msgListElements = this.elementRef.nativeElement.querySelectorAll('#emp-msg-list');
    if (msgListElements && msgListElements[msgListElements.length]) {
      msgListElements[msgListElements.length].scrollIntoView(true);
    }
    this.getTabMessagesRecords();
    this.messageManService.setRequestParams(this.requestObj);    
  }

  public updateMessagesList(updatedMessage) {
     
    if (updatedMessage !== null) {
      if (updatedMessage.archived) {
        this.selectedMessageIds.push(updatedMessage.employeeId);
        this.updateUnArchiveMessages();
      } else {
        updatedMessage.modified = new Date().toUTCString();
        let allList:any[]=this.allMessageList();
          _.each(allList,(item:any)=>{
          const index = item.findIndex(x => x.employeeId === updatedMessage.employeeId);
          if(index > -1 )
          {
          item[index] = updatedMessage;
          }
          });
          allList=[];

        this.sortClientSide(this.selectedSortFilter);
      }
    } else {
      let allList:any[]=this.allMessageList();
        _.each(allList,(item:any)=>{
          this.selectedEmp = item.filter(x => x.id === this.selectedId).map(y => y.employeeId)[0];
        });
        allList=[];

      this.currentPageno = 1;
      this.requestObj.currentPageno = this.currentPageno;
      this.messageManService.setRequestParams(this.requestObj);
      this.messageManService.emptyMessageThread();
      this.getArchiveReadUnreadCount.emit(true);
      this.messageManService.callUnReadCountApi();

    }
    this.scrollToTop();
  }

  setMessageRange() {
    if ((!this.dateOrderByAscending || this.dateOrderByAscending === null) && this.nameOrderByAscending === null ) {
      this.messageRange = ['lessthanmonth', 'olderthanonemonth', 'olderthanthreemonth','loadolderThan6Month'];
    } else if (this.dateOrderByAscending && this.nameOrderByAscending === null ) {
      this.messageRange = ['loadolderThan6Month','olderthanthreemonth', 'olderthanonemonth', 'lessthanmonth'];
    } else if (this.nameOrderByAscending !== null ) {
      this.messageRange = ['lessthanmonth'];
    }
    
    this.refreshGrid();
  }

  sortClientSide(type) {  
    this.refreshGrid();
  }

  onsearchType(search) {
    if (search !== this.searchInput) {
      this.isLoading = true;
      this.searchInput = search;
      this.messageList = [];
      this.currentPageno = 1;
      this.requestObj.includeCold = false;
      this.requestObj.currentPageno = this.currentPageno;
      this.messageManService.setRequestParams(this.requestObj);
      this.searchTerm$.next(search);
    } else {
      this.isLoading = false;
    }
  }

  getUnSelectedemployess(event) {
    this.unSelectedemployess = event.map(x => x.employeeId);
  }

  getAccordianlevel(item) {
    return item === 'olderthanthreemonth' ? 'Older than three months' : item === 'olderthanonemonth' ? 'Older than one month' : item === 'loadolderThan6Month' ? 'Older than six months' : '';
  }

  getColdStorageExist() {    
    this.messageManService.getColdStorageExist(this.orgLevelId, this.IsArchive);
  }

  private setCurrentSections(){  
    this.sectionsDetail=[];  
    _.each(this.messageRange, (s: any) => {   
      let section:SectionDetail=new SectionDetail(); 
      let sectionState:SectionState=new SectionState();
      section.sectionName=s;
      section.orderByNameAsc=this.nameOrderByAscending;
      sectionState.sectionName=section.sectionName;
      sectionState.startDate=section.startDate;
      sectionState.endDate=section.endDate;
      sectionState.includeCold=section.includeCold;
      sectionState.totalCount=0;
      sectionState.loadCount=0;
      sectionState.isCollapsed=true;
      sectionState.currentPageNumber=0;
      this.sectionsDetail.push(section);
      this.sectionTypesMap[s]=sectionState;
    });
  }

  private updateSectionMapWithTotalCount(sectionName:string,totalCount:number):void{
    let state:SectionState=this.sectionTypesMap[sectionName];
    state.totalCount=totalCount;
    state.loadCount=0;
    state.isCollapsed=true;
    if(_.isEqual(state.sectionName,sectionType.lessThanOneMonth))
    {
      state.isCollapsed=false;
    }
    this.sectionTypesMap[sectionName]=state;

   }

   private updateSectionMapWithLoadCount(sectionName:string,loadCount:number):void{
    let pageNumber=0;
    pageNumber=(loadCount-(loadCount%this.pageSize))/this.pageSize;
    if(loadCount%this.pageSize>0)
    {
      pageNumber=pageNumber+1;
    }
    let state:SectionState=this.sectionTypesMap[sectionName];
    state.loadCount=loadCount;
    state.isloaded=true;
    state.currentPageNumber=pageNumber;
    this.sectionTypesMap[sectionName]=state;
    if(_.isEqual(sectionType.olderThanSixMonth,sectionName))
    {
      if(loadCount>0)
      {
        this.requestObj.includeCold = true;
      }
      else
      {
        this.requestObj.includeCold = false;
      }
    }    
   }
 
   private updateTabMessageThread(currentSectionRecords:any):void{
     
    let selectedEmp = currentSectionRecords.filter(x => x.id === this.selectedId).map(y => y.employeeId)[0];
    if (selectedEmp) {
      let rowData = currentSectionRecords.filter(x => x.employeeId === selectedEmp)[0];
      this.selectedId = rowData.id;
      this.messageManService.updateMessageThread(rowData, this.orgLevelId,true,this.isMyMessage);
    }
    this.searchInput = this.searchText;
    this.isLoading = false;
    this.isDataLoading.emit(false);
     
  }   

   public updateTabMessageRecords(record:TabMessages):void{
    if(_.isEqual(record.sectionName,sectionType.lessThanOneMonth))
    {
      this.lessThanOneMonth=this.lessThanOneMonth.concat(record.messages);
      this.updateSectionMapWithLoadCount(record.sectionName,this.lessThanOneMonth.length);
      this.updateTabMessageThread(this.lessThanOneMonth);
    }
    else if(_.isEqual(record.sectionName,sectionType.olderThanOneMonth))
    {
      this.olderThanOneMonth=this.olderThanOneMonth.concat(record.messages);
      this.updateSectionMapWithLoadCount(record.sectionName,this.olderThanOneMonth.length);
      this.updateTabMessageThread(this.olderThanOneMonth);
    }
    else if(_.isEqual(record.sectionName,sectionType.olderThanThreeMonth))
    {
      this.olderThanThreeMonth=this.olderThanThreeMonth.concat(record.messages);
      this.updateSectionMapWithLoadCount(record.sectionName,this.olderThanThreeMonth.length);
      this.updateTabMessageThread(this.olderThanThreeMonth);
    }
    else if(_.isEqual(record.sectionName,sectionType.olderThanSixMonth))
    {
     this.olderThanSixMonth=this.olderThanSixMonth.concat(record.messages);
      this.updateSectionMapWithLoadCount(record.sectionName,this.olderThanSixMonth.length);
      this.updateTabMessageThread(this.olderThanSixMonth);
    }
  
    
    if(this.isAllSelected)
    {      
      let prevState=_.cloneDeep(this.isAllSelected);
      this.onToggelSelected('All');
      this.isAllSelected=prevState;
    }
    else
    {
      if(_.isEqual(this.selectedCheckBoxEvent,'Read') || _.isEqual(this.selectedCheckBoxEvent,'Unread')) 
      {
        let prevState=_.cloneDeep(this.selectedCheckBoxEvent);
        this.onToggelSelected(this.selectedCheckBoxEvent);
        this.selectedCheckBoxEvent=prevState;
        
      }
    }
  } 
  private allMessageList():any[]{
    let allList:any[]=[];
    allList.push(this.lessThanOneMonth);
    allList.push(this.olderThanOneMonth);
    allList.push(this.olderThanThreeMonth);
    allList.push(this.olderThanSixMonth);
    return allList;
  }
  public showWaitingPopup(isShow:boolean): void {  
    if(isShow)
    { 
      let message = 'Please wait while we process your request.';
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = false;
      options.showOK = true;
      options.height = 144;
      ConfirmDialog2Component.openDialog(
        'Information',
        message,
        this.modalService,
        (result: boolean) => {
          if (result) {
          //Success
          }
        }, options);
    }
  }

  private updateSelectedMessageRecords(message:MessageList)
  {
    this.lessThanOneMonth.unshift(message);
  }
}

