import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'weather-current-details',
  templateUrl: 'weather-current-details.component.html',
  styleUrls: ['weather-current-details.component.scss'],
})
export class WeatherCurrentDetailsComponent {
  @Input()
  public maxTemp: number;
  @Input()
  public minTemp: number;
  @Input()
  public pressure: number;
  @Input()
  public humidity: number;
  @Input()
  public scaleLabel: string;
}
