<div class="slx-date-navigator slx-range-navigator {{className}}" [ngClass]="{ 'disabled': readonly }">

    <span  *ngIf="!navigatorButtons" [ngClass]="{'slx-range-navigator__buttons': true, 'disabled': isMinimum(selectedDate)}">
        <i class="slx-range-navigator__icon left fas fa-angle-left" aria-hidden="true" (click)="onNavButtonClick($event, false)"></i>
    </span>

    <slx-datepicker
    #dateSelector="ngModel" name="dateSelector"
    [ngModel]="selectedDate" (ngModelChange)="selectorDateChange($event)"
    [format]="format" [disabled]="readonly" [readOnlyInput]="readOnlyInput"
    [minDate]="minDate" [maxDate]="maxDate" [acceptNullDate]="acceptNullDate"
    class="slx-date-navigator___datepicker"
    >
    </slx-datepicker>

    <span *ngIf="!navigatorButtons" [ngClass]="{'slx-range-navigator__buttons':true, 'disabled': isMaximum(selectedDate)}">
        <i class="slx-range-navigator__icon right fas fa-angle-right" aria-hidden="true" (click)="onNavButtonClick($event, true)"></i>
    </span>
</div>
