import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, NavigationExtras,
    Params, Resolve, UrlSegment, NavigationEnd
} from '@angular/router';

import { PayCycle } from '../../../organization/models/lookup/index';
import { PunchesRouteData } from '../../../time-and-attendance/models/punches/punches-route-data';
import { PayCycleHelperService, ApplicationStateBusService } from '../../../organization/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { appConfig } from '../../../app.config';
import { RangeDates } from '../../../common/models/range-dates';
import { BaseResolver } from '../../../common/index';

@Injectable()
export class DailyPunchesResolver extends BaseResolver<PunchesRouteData> implements Resolve<PunchesRouteData> {

    private savedMissingFlag: boolean;

    constructor(router: Router,
        private payCycleHelper: PayCycleHelperService,
        private busService: ApplicationStateBusService) {
        super(router);
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): PunchesRouteData | Promise<PunchesRouteData> {

        let onlyMissing: boolean = _.toString(route.queryParamMap.get('onlyMissing')) === 'true';


        if (onlyMissing) {
            const params: Params = this.createQueryParams(route, state);
            let routeData: PunchesRouteData = new PunchesRouteData();
            this.savedMissingFlag = routeData.isMissingOnly = true;
            this.navigateToPath(route, params);
            return routeData;
        }

        let range: RangeDates = this.getRangeDates(route);

        if (range.startDate && range.endDate) {
            if (!moment(range.endDate).isAfter(range.startDate)) {
                range.endDate = moment(range.startDate).add(1, 'day').toDate();
            }
        } else if (range.startDate) {
            range.endDate = moment(range.startDate).add(1, 'day').toDate();
        } else if (range.endDate) {
            range.startDate = moment(range.endDate).subtract(1, 'day').toDate();
        } else {
            //default to yesterday
            range.endDate = moment().add(-1, 'day').toDate();
            range.startDate = moment(range.endDate).startOf('day').toDate();
        }

        if (!this.busService.isInitialized) {
            const routeData: PunchesRouteData = new PunchesRouteData();
            routeData.range = range;
            return routeData;
        }

        return this.getOrgLevel().then((orgLevel: OrgLevel) => {
            return this.payCycleHelper.getPayCycleByDate(range.startDate, orgLevel.id).then((payCycle: PayCycle) => {
                if (payCycle) {

                    if (moment(payCycle.startDate).isBefore(range.endDate)) {
                        if (moment(range.endDate).diff(payCycle.startDate, 'day') > 30) {
                            range.startDate = moment(range.endDate).subtract(30, 'day').toDate();
                        } else {
                            range.startDate = payCycle.startDate;
                        }
                    } else {
                        range.startDate = moment(range.endDate).startOf('day').subtract(1, 'day').toDate();
                    }
                }
                let routeData: PunchesRouteData = new PunchesRouteData();
                routeData.range = range;
                routeData.payCycle = payCycle;
                routeData.isMissingOnly = this.savedMissingFlag;
                this.savedMissingFlag = false;
                return routeData;
            });
        });
    }

    public createQueryParams(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Params {
        let params: any = super.createQueryParams(route);
        delete params['onlyMissing'];
        return params;
    }

    protected getOrgLevel(): Promise<OrgLevel> {
        let promise: Promise<OrgLevel> = this.busService.orgLevelSelected$.first().toPromise();
        return promise;
    }

    protected getRangeDates(route: ActivatedRouteSnapshot): RangeDates {

        let startDateParam: string = _.toString(route.queryParamMap.get('startDate'));
        let endDateParam: string = _.toString(route.queryParamMap.get('endDate'));

        let startDate: Date = startDateParam ? moment(startDateParam, appConfig.linkDateFormat).toDate() : null;
        let endDate: Date = endDateParam ? moment(endDateParam, appConfig.linkDateFormat).toDate() : null;

        let range: RangeDates = new RangeDates();
        range.startDate = startDate;
        range.endDate = endDate;

        return range;
    }

}
