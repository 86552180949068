<form [formGroup]="openPositionConfigForm">
  <slx-spinner [show]="isLoading">
    <div class="configuration-main-container" *ngIf="configData">
      <div class="config-container">
        <div class="ft-container">
          <div class="heading">Full Time Employees</div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ftMaxWeeklyHours" type="number" min="30" max="65"
                required="true" formControlName="ftMaxWeeklyHours" />
              <label>Max. Weekly Hours</label>
            </div>
            <span *ngIf="openPositionConfigForm.get('ftMaxWeeklyHours').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>
            <span
              *ngIf="openPositionConfigForm.get('ftMaxWeeklyHours').errors?.min || openPositionConfigForm.get('ftMaxWeeklyHours').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 30 to 65</span>

          </div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ftMaxHoursPerCalenderDay" type="number" min="4"
                max="24" required="true" formControlName="ftMaxHoursPerCalenderDay" />
              <label>Max. Hours per Calendar Day</label>
            </div>
            <!-- Error Message -->
            <span *ngIf="openPositionConfigForm.get('ftMaxHoursPerCalenderDay').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>

            <span
              *ngIf="openPositionConfigForm.get('ftMaxHoursPerCalenderDay').errors?.min || openPositionConfigForm.get('ftMaxHoursPerCalenderDay').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 4 to 24</span>

            <span *ngIf="openPositionConfigForm.errors?.isFtInvalidValue" class="slx-error-block pull-left"
              errorMessage>Entered value should be satisfy with min weekly hours</span>
            <!-- Error Message -->
          </div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ftMaxNoOfDaysPerCalenderWeek" type="number" min="2"
                max="7" required="true" formControlName="ftMaxNoOfDaysPerCalenderWeek" />
              <label>Max. Number of Days per Calendar Week</label>
            </div>
            <!-- Error Message -->
            <span *ngIf="openPositionConfigForm.get('ftMaxNoOfDaysPerCalenderWeek').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>
            <span
              *ngIf="openPositionConfigForm.get('ftMaxNoOfDaysPerCalenderWeek').errors?.min || openPositionConfigForm.get('ftMaxNoOfDaysPerCalenderWeek').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 2 to 7</span>
            <span *ngIf="openPositionConfigForm.errors?.isFtInvalidValue" class="slx-error-block pull-left"
              errorMessage>Entered value should be satisfy with min weekly hours</span>
            <!-- Error Message -->
          </div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ftMinWeeklyHours" type="number" min="4" max="30"
                required="true" formControlName="ftMinWeeklyHours" />
              <label>Min. Weekly Hours</label>
            </div>
            <!-- Error Message -->
            <span *ngIf="openPositionConfigForm.get('ftMinWeeklyHours').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>
            <span
              *ngIf="openPositionConfigForm.get('ftMinWeeklyHours').errors?.min || openPositionConfigForm.get('ftMinWeeklyHours').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 4 to 30</span>
            <!-- Error Message -->
          </div>
        </div>
        <div class="pt-container">
          <div class="heading">Part Time Employees</div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ptMaxWeeklyHours" type="number" min="5" max="45"
                required="true" formControlName="ptMaxWeeklyHours" />
              <label>Max. Weekly Hours</label>
            </div>
            <!-- Error Message -->
            <span *ngIf="openPositionConfigForm.get('ptMaxWeeklyHours').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>
            <span
              *ngIf="openPositionConfigForm.get('ptMaxWeeklyHours').errors?.min || openPositionConfigForm.get('ptMaxWeeklyHours').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 5 to 45</span>
            <span *ngIf="openPositionConfigForm.errors?.isPtMinValue" class="slx-error-block pull-left"
              errorMessage>Entered value should be greater than or equal with min weekly hours</span>
            <!-- Error Message -->
          </div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ptMaxHoursPerCalenderDay" type="number" min="5"
                max="24" required="true" formControlName="ptMaxHoursPerCalenderDay" />
              <label>Max. Hours per Calendar Day</label>
            </div>
            <!-- Error Message -->
            <span *ngIf="openPositionConfigForm.get('ptMaxHoursPerCalenderDay').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>
            <span
              *ngIf="openPositionConfigForm.get('ptMaxHoursPerCalenderDay').errors?.min || openPositionConfigForm.get('ptMaxHoursPerCalenderDay').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 5 to 24</span>
            <span *ngIf="openPositionConfigForm.errors?.isPtInvalidValue" class="slx-error-block pull-left"
              errorMessage>Entered value should be satisfy with min weekly hours</span>
            <!-- Error Message -->
          </div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ptMaxNoOfDaysPerCalenderWeek" type="number" min="1"
                max="7" required="true" formControlName="ptMaxNoOfDaysPerCalenderWeek" />
              <label>Max. Number of Days per Calendar Week</label>
            </div>
            <!-- Error Message -->
            <span *ngIf="openPositionConfigForm.get('ptMaxNoOfDaysPerCalenderWeek').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>
            <span
              *ngIf="openPositionConfigForm.get('ptMaxNoOfDaysPerCalenderWeek').errors?.min || openPositionConfigForm.get('ptMaxNoOfDaysPerCalenderWeek').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 1 to 7</span>
            <span *ngIf="openPositionConfigForm.errors?.isPtInvalidValue" class="slx-error-block pull-left"
              errorMessage>Entered value should be satisfy with min weekly hours</span>
            <!-- Error Message -->
          </div>
          <div class="input-container">
            <div class="input-container-content">
              <input class="slx-opc-wide slx-input-number" name="ptMinWeeklyHours" type="number" min="5" max="45"
                required="true" formControlName="ptMinWeeklyHours" />
              <label>Min. Weekly Hours</label>
            </div>
            <!-- Error Message -->
            <span *ngIf="openPositionConfigForm.get('ptMinWeeklyHours').errors?.required"
              class="slx-error-block pull-left" errorMessage>Required
              field</span>
            <span
              *ngIf="openPositionConfigForm.get('ptMinWeeklyHours').errors?.min || openPositionConfigForm.get('ptMinWeeklyHours').errors?.max"
              class="slx-error-block pull-left" errorMessage>Entered value must be 5 to 45</span>
            <!-- Error Message -->
          </div>
        </div>
      </div>
      <span class="reset-btn" (click)="resetValuesToDefault()">Reset to default values</span>
    </div>
  </slx-spinner>
  <div class="footer-btns-container">
    <button type="button" class="discard-btn" (click)="discard()">
      Discard
    </button>
    <button type="button" [ngClass]="!isFormValid ? 'disabled' : 'save-btn'" (click)="save()">
      Save
    </button>
  </div>
</form>