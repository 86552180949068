import { IPayloadAction } from '../../state-model/models/index';
import { SidebarActions } from '../../portal/actions/sidebar.actions';
import { ISidebarRecord } from './sidebar.types';
import {
  INITIAL_SIDEBAR_STATE
} from './sidebar.initial-state';

export function sidebarReducer(state: ISidebarRecord = INITIAL_SIDEBAR_STATE, action: IPayloadAction): ISidebarRecord {
  switch (action.type) {
    case SidebarActions.SET_RIGHT_SIDEBAR:
      return state.merge({
        isRightSidebarOpen: action.payload
      });
    case SidebarActions.SET_LEFT_SIDEBAR:
      return state.merge({
        isLeftSidebarOpen: action.payload
      });
    case SidebarActions.SET_LEFT_SIDEBAR_HIDDEN:
      return state.merge({
        isLeftSidebarHidden: action.payload
      });
    default:
      return state;
  }
}

