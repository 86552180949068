import * as moment from 'moment';

import { Component, OnInit, OnDestroy, Provider, Output, EventEmitter } from '@angular/core';
import { ModalService } from './../../../../../common/services/modal/modal.service';
import { ModalBehavior } from './../../../../../common/models/modal-behavior';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { EmployeeSectionsContext } from '../../../models/index';
import { EmployeeCallLogManagementService } from '../../../services/index';
import { unsubscribe } from '../../../../../core/decorators/index';

import { OrgLevel } from '../../../../../state-model/models/index';
import { mutableSelect } from '../../../../../core/decorators/index';
import { RangeDates, IRangeDates } from '../../../../../common/models/range-dates';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-call-log',
  templateUrl: 'employee-call-log.component.html',
  styleUrls: ['employee-call-log.component.scss'],
  providers: [EmployeeCallLogManagementService]
})
export class EmployeeCallLogComponent implements OnInit, OnDestroy {

  @Output()
  public onClose: EventEmitter<any>;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;
  public state: {
    isLoading: boolean;
    startDate: Date;
    endDate: Date;
  };
  public pageSize: number;
  public actions: any[];
  public employeeLink: boolean;

  private employeesIds: number[];
  private employeeSectionsContext: EmployeeSectionsContext;
  private currentOrgLevel: OrgLevel;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private messageCenterEnabledSubscription: Subscription;
  private modalService: ModalService;
  private managementService: EmployeeCallLogManagementService;
  private loadStatusSubscription: Subscription;

  constructor(
    employeeSectionsContext: EmployeeSectionsContext,
    managementService: EmployeeCallLogManagementService
  ) {
    this.onClose = new EventEmitter<any>();
    this.employeeSectionsContext = employeeSectionsContext;
    this.managementService = managementService;
    this.state = {
      isLoading: false,
      startDate: moment().subtract(1, 'week').toDate(),
      endDate: moment().toDate()
    };
    this.pageSize = 15;
    this.employeeLink = false;
    this.employeesIds = [this.employeeSectionsContext.employeeId];
  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
      this.managementService.orgLevelChanged(this.currentOrgLevel);
      this.managementService.employeesChanged(this.employeesIds);
    });

    this.loadStatusSubscription = this.managementService.onLoadStatus$.subscribe((value: boolean) => {
      this.state.isLoading = value;
    });

    this.messageCenterEnabledSubscription = this.managementService.messageCenterEnabled$.subscribe(() => {
      this.managementService.dateRangeChanged(this.state.startDate, this.state.endDate);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onDataRangeChange({ startDate, endDate }: IRangeDates): void {
    this.state.startDate = startDate;
    this.state.endDate = endDate;
    this.managementService.dateRangeChanged(this.state.startDate, this.state.endDate);
  }

  public onCloseClick(e: MouseEvent): void {
    e.preventDefault();
    this.onClose.next();
  }
}
