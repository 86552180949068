import * as _ from 'lodash';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmDialog2Component, ConfirmOptions, DialogOptions2, IDialog, KendoGridStateHelper, ModalService, PopupService } from '../../../../../common';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import { ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { AcaExportCorrected1095C } from '../../../models/aca-export/aca-export-corrected-1095c';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, process } from '@progress/kendo-data-query';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { MatchEmp } from '../../../models/aca-export/aca-export-replace-1095c';
import { AcaExportParameter } from '../../../models/aca-export/aca-export-parameter';
import { AcaExportType } from '../../../enums/aca-export-type';

@Component({
  selector: 'slx-aca-export-corrected-1095c',
  templateUrl: './aca-export-corrected-1095c.component.html',
  styleUrls: ['./aca-export-corrected-1095c.component.scss'],
})
export class AcaExportCorrected1095CComponent implements IDialog, OnInit {


  public get initialized(): boolean {
    return this.m_initialized;
  }

  public gridData: any[] = [''];

  @ViewChild('correctionForm', { static: true })
  private ngFormChild: NgForm;
  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  private modalService: ModalService;
  private options: DialogOptions2;
  private changeDetector: ChangeDetectorRef;
  public manService: AcaExportManagementService;

  constructor(
    modalService: ModalService,
    options: DialogOptions2,
    manService: AcaExportManagementService,
    changeDetector: ChangeDetectorRef
  ) {
    this.modalService = modalService;
    this.options = options;
    this.modalService = modalService;
    this.changeDetector = changeDetector;
    this.manService = manService;
    this.gridState = new KendoGridStateHelper<AcaExportCorrected1095C[]>();
    this.gridState.view = null;
  }


  public dialogResult: boolean = false;
  public isLoading: boolean = true;

  public isButtonEnable: boolean = true;
  public acaCorrectionType: AcaExportCorrectionType;
  public acaExportExecutionItem: AcaExportExecutionItem;
  public gridState: KendoGridStateHelper<AcaExportCorrected1095C[]>;
  public gridView: GridDataResult;
  public sort: SortDescriptor[] = [];
  public records: AcaExportCorrected1095C[];
  public m_initialized: boolean;
  public isBack: boolean;
  public matchEmployee: MatchEmp;
  public matchEmployeeList: MatchEmp[];
  public exportConfigurations: AcaExportConfiguration[] = [];
  public exportParams: AcaExportParameter = new AcaExportParameter();
  public configuration: AcaExportConfiguration = new AcaExportConfiguration();
  public isAllSelected: boolean;
  public isSsnVisible: boolean = false;

  public ngOnInit(): void {
    this.m_initialized = true;
    this.isLoading = false;
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public async getAcaCorrected1095C(exectionId: string): Promise<void> {
    this.isLoading = true;
    this.records = await this.manService.fetchCorrected1095CRecords(exectionId, this.matchEmployeeList);
    this.refreshGrid();
    this.isLoading = false;
  }

  public get valid(): boolean {
    let formValid: boolean = this.form.invalid ? false : true;
    return formValid;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onBack() {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.dialogType = 1;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
    acaExportDialogOptions.acaExportCorrected1095CContainer = this.records;
    this.manService.openAcaExportDialog(acaExportDialogOptions);
  }


  public matchRecord(empRecord: AcaExportCorrected1095C) {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.dialogType = 3;
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
    acaExportDialogOptions.acaExportCorrected1095CContainer = this.records;
    acaExportDialogOptions.acaReplace1095C = empRecord;
    acaExportDialogOptions.matchEmployeeList = this.matchEmployeeList;
    this.manService.openAcaExportDialog(acaExportDialogOptions);
  }


  public onCheckedEmployee(isSelected: boolean): void {
    if (isSelected) {
      this.isButtonEnable = false;
    } else {
      this.isButtonEnable = true;
    }

    let selectedEmployees: AcaExportCorrected1095C[] = _.filter(this.records, (employee: AcaExportCorrected1095C) => (employee.isSelected));

    this.exportParams.recordIds = _.map(selectedEmployees, (employee: AcaExportCorrected1095C) => (
      employee.recordId
    ));
    this.exportParams.empIds = _.map(selectedEmployees, (employee: AcaExportCorrected1095C) => (
      employee.employeeIdMasterId
    ));
  }

  public showGenerateXML(): void {

    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'XML',
      'Are you sure you wish to generate a corrected XML export according to the parameters you specified?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.exportParams.exportType = AcaExportType.ExportXmlCorrected1095C;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.exportParams.year = this.acaExportExecutionItem.year;
          this.exportParams.qualifiedOfferMethod = this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
          this.exportParams.offerMethod98 = this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
          this.exportParams.memberOfAleGroup = this.acaExportExecutionItem.memberOfAleGroup;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.empIds = this.replaceEmpIdTOLinkedId();
          this.configuration.exportParams = this.exportParams;
          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }

  public showGenerate1095c(): void {

    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      '1095-Cs PDF',
      'Do you wish to proceed with generating a PDF export?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.exportParams.exportType = AcaExportType.ExportPdfCorrected1095C;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.exportParams.year = this.acaExportExecutionItem.year;
          this.exportParams.qualifiedOfferMethod = this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
          this.exportParams.offerMethod98 = this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
          this.exportParams.memberOfAleGroup = this.acaExportExecutionItem.memberOfAleGroup;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.empIds = this.replaceEmpIdTOLinkedId();
          this.configuration.exportParams = this.exportParams;
          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }


  public showGenerate1094c(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      '1094-C PDF',
      'Do you wish to proceed with generating a PDF export?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.exportParams.exportType = AcaExportType.ExportPdfCorrected1094C;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.exportParams.year = this.acaExportExecutionItem.year;
          this.exportParams.qualifiedOfferMethod = this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
          this.exportParams.offerMethod98 = this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
          this.exportParams.memberOfAleGroup = this.acaExportExecutionItem.memberOfAleGroup;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.empIds = this.replaceEmpIdTOLinkedId();
          this.configuration.exportParams = this.exportParams;
          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public onToggleAllSelected(): void {
    const employees = process(this.records, { filter: this.gridState.state.filter }).data;
    const selectedEmployees: AcaExportCorrected1095C[] = _.filter(employees, (employee: AcaExportCorrected1095C) => {
      employee.isSelected = this.isAllSelected;
      this.onCheckedEmployee(employee.isSelected);
      return (employee.isSelected as boolean);
    });
  }

  public toggleSsnVisibility(): void {
    this.isSsnVisible = !this.isSsnVisible;
  }

  public getSSN(ssn: string): string {
    try {
      if (this.isSsnVisible) {
        return ssn;
      } else {
        return `***-**-${_.takeRight(ssn, 4).join('')}`;
      }
    } catch (e) { }

    return 'Invalid SSN';
  }

  public replaceEmpIdTOLinkedId(): any[] {
    let matchedList: MatchEmp[] = this.matchEmployeeList;
    let exportedEmpIds: any[] = [];
    _.forEach(this.exportParams.empIds, function (value1) {
      let matchedRecord: any[] = _.filter(matchedList, (item) => (item.soureEmpID === value1));
      if (matchedRecord.length > 0) {
        exportedEmpIds.push(matchedRecord[0].tragetEmpID);
      }
      else {
        exportedEmpIds.push(value1);
      }
    });
    return exportedEmpIds;
  }
}
