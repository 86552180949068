<form novalidate #supervisorForm="ngForm">
  <kendo-grid #kendoSupervisorGrid [data]="gridState.view" (dataStateChange)="gridState.dataStateChange($event)" [sortable]="{ mode: 'multiple' }"
    [sort]="gridState.state.sort" [filterable]="false" [filter]="gridState.state.filter" [scrollable]="true" (cancel)="gridState.cancelHandler($event)"
    (edit)="gridState.editHandler($event)" (save)="gridState.saveHandler($event)">

    <kendo-grid-column title="Select" [sortable]="false" field="isSelected" media="sm" width="70">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem">
      </ng-template>
      <ng-template kendoGridHeaderTemplate>
        <input type="checkbox" [ngModel]="selectionHelper.isAllSelected" (ngModelChange)="allSelectionChange()" name="allSelectionToggle">
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <input type="checkbox" [ngModel]="dataItem.isSelected" (ngModelChange)="selectionChange(dataItem)" name="selector{{rowIndex}}">
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="Сommand" width="80" class="overflow-visible-cell">
      <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
        <div class="command-container">
          <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
             <i class="fas fa-pencil-alt" aria-hidden="true"></i>
            </button>
          <button kendoGridSaveCommand type="button" [disabled]="!supervisorForm.valid" class="theme-icon-button theme-inline-apply-button">
          <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
         </button>
          <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
          <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
         </button>
        </div>
      </ng-template>
    </kendo-grid-command-column>

    <kendo-grid-column title="Primary Supervisor" [sortable]="false" field="isPrimary" media="sm" width="135">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator *ngIf="dataItem.user" className="slx-no-border slx-small-font slx-no-padding slx-no-label">
          <slx-checkbox-input slx-input [(ngModel)]="dataItem.isPrimary" (ngModelChange)="onCheckedPrimary(dataItem, isPrimary)"
            name="isPrimary{{rowIndex}}" fieldName="isPrimary{{rowIndex}}" caption="" #isPrimary="ngModel"></slx-checkbox-input>
          <span errorMessage for="noMore">Only one supervisor can be Primary</span>
        </slx-input-decorator>
        <span *ngIf="!dataItem.user"> - </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem.user">{{dataItem.isPrimary | boolyn }}</span>
        <span *ngIf="!dataItem.user"> - </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column width="135" title="Username" media="sm" field="user.name">
      <ng-template kendoGridCellTemplate let-dataItem>
        <a *ngIf="dataItem.user" [userProfileLink]="dataItem?.user.id">{{dataItem?.user.name}}({{dataItem.user?.login}})</a>
        <a *ngIf="!dataItem.user" userProfileLink><span><i class="fa fa-plus-square" aria-hidden="true"></i></span></a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column width="125" title="Role" media="sm" field="user.role">
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngIf="dataItem.role">{{dataItem?.role}}</span>
        <span *ngIf="!dataItem.role"> - </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Punch Override" [sortable]="false" field="punchOverride" media="sm" width="135">
      <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
        <slx-input-decorator *ngIf="dataItem.hasEmployee" className="slx-no-border slx-small-font slx-no-padding slx-no-label">
          <slx-checkbox-input slx-input [(ngModel)]="dataItem.punchOverride" #punchOverride="ngModel" name="punchOverride{{rowIndex}}"
            fieldName="punchOverride{{rowIndex}}" caption=""></slx-checkbox-input>
        </slx-input-decorator>
        <span *ngIf="!dataItem.hasEmployee"> - </span>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem.hasEmployee">{{dataItem.punchOverride | boolyn }}</span>
        <span *ngIf="!dataItem.hasEmployee"> - </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Linked Employee" [sortable]="false" field="employee.employee.name" media="sm" width="165">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a *ngIf="dataItem.hasEmployee" [employeeSectionLink]="dataItem?.employee.employee.id">
        {{dataItem?.employee?.employee?.name}}({{dataItem?.employee?.employee?.id}})
      </a>
      </ng-template>
    </kendo-grid-column>



  </kendo-grid>
</form>
