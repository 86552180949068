import { IIndSchOrgLevelEmployee, IndSchOrgLevelEmployee } from './ind-sch-org-level-employee';

export interface IIndSchOrgLevelEmployees {
  orgLevelId: number;
  startDate: string;
  endDate: string;
  entities: IIndSchOrgLevelEmployee[];
}

export class IndSchOrgLevelEmployees {
  public orgLevelId: number;
  public startDate: Date;
  public endDate: Date;
  public entities: IndSchOrgLevelEmployee[];
}
