export interface IPBJExportLogError {
  exportId: number;
  exportedBy: string;
  employeeMasterId: number;
  pbjId: string;
  employeeId: number;
  employeeName: string;
  employeePayrollNumber: string;
  errorId: number;
  section: string;
  field: string;
  errorMessage: string;
  referenceDate: string;
  entryType: string;
}

export class PBJExportLogError {
  public exportId: number;
  public exportedBy: string;
  public employeeMasterId: number;
  public pbjId: string;
  public employeeId: number;
  public employeeName: string;
  public employeePayrollNumber: string;
  public errorId: number;
  public section: string;
  public field: string;
  public errorMessage: string;
  public referenceDate: Date;
  public entryType: string;
}

