import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { TimecardSummaryRequest } from '../../../models/index';
import { Department } from '../../../../organization/models/index';
var SummaryFiltersComponent = /** @class */ (function () {
    function SummaryFiltersComponent(management) {
        this.management = management;
    }
    Object.defineProperty(SummaryFiltersComponent.prototype, "department", {
        get: function () {
            if (this.management.orgLevelDepartment) {
                return this.management.orgLevelRelatedDepartments;
            }
            else {
                return this.selectedDepartments;
            }
        },
        set: function (value) {
            this.selectedDepartments = value;
        },
        enumerable: true,
        configurable: true
    });
    SummaryFiltersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadSubscription = this.management.onLoaded$.subscribe(function (summary) {
            if (summary && summary.organization &&
                _this.summary && _this.summary.organization &&
                _this.summary.organization.id !== summary.organization.id) {
                _this.resetFilters();
            }
            _this.summary = summary;
        });
    };
    SummaryFiltersComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SummaryFiltersComponent.prototype.onFilterChanged = function () {
        if (this.summary) {
            this.summary = null;
            var request = this.createSummaryRequest();
            this.management.loadTimecardsSummary(request);
        }
    };
    SummaryFiltersComponent.prototype.resetFilters = function () {
        this.department = [];
        this.position = [];
        this.empType = [];
        this.payPolicy = [];
        this.shiftDiffPolicy = [];
    };
    SummaryFiltersComponent.prototype.createSummaryRequest = function () {
        var request = new TimecardSummaryRequest();
        request.organizationId = this.management.organizationId;
        request.startDate = this.management.currentPayCycle.startDate;
        request.endDate = this.management.currentPayCycle.endDate;
        if (this.department)
            request.departmentIds = _.map(this.department, 'id');
        if (this.position)
            request.positionIds = _.map(this.position, 'id');
        if (this.empType)
            request.employeeTypeNames = _.map(this.empType, 'name');
        if (this.payPolicy)
            request.payPolicyNames = _.map(this.payPolicy, 'name');
        if (this.shiftDiffPolicy)
            request.shiftDifferentialPolicyNames = _.map(this.shiftDiffPolicy, 'name');
        return request;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SummaryFiltersComponent.prototype, "loadSubscription", void 0);
    return SummaryFiltersComponent;
}());
export { SummaryFiltersComponent };
