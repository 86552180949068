import { IBenefitDetailsBasic } from '../benefit-details/benefit-details-basic';
import { BenefitDetailsCalcMethod } from '../benefit-details/benefit-details-calc-method';
import { IBenefitDetailsOption, BenefitDetailsOption } from '../benefit-details/benefit-details-option';

export interface IBenefitEligibleDependentBenefit {
  empEnrollmentId: number;
  benefitName: string;
  benefitCalcMethod: IBenefitDetailsBasic<string, string>;
  benefitOption: IBenefitDetailsOption;
  empBenefitStartDate: string;
  empBenefitEndDate: string;
}

export class BenefitEligibleDependentBenefit {
  public empEnrollmentId: number;
  public benefitName: string;
  public benefitCalcMethod: BenefitDetailsCalcMethod;
  public benefitOption: BenefitDetailsOption;
  public empBenefitStartDate: Date;
  public empBenefitEndDate: Date;
}
