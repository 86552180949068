<div class="slx-copy-policy-main-container">
    <div class="slx-select-policy">
        <p class="slx-select-head">Select Policies to Copy</p>
        <kendo-grid class="slx-copy-policy-grid" [kendoGridBinding]="dialogData.currentPolicies" [filterable]="'menu'">
            <kendo-grid-column title="Accrual Policy" field="policyName" filter="text"
                [width]="300"></kendo-grid-column>
            <kendo-grid-checkbox-column [width]="50">
                <ng-template kendoGridHeaderTemplate>
                    <input type="checkbox" class="k-checkbox checkbox-copy-policy" [checked]="isAllPolicyCheck"
                        (change)="selectAllPolicies()" />
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [value]="dataItem.isChecked === true" [checked]="dataItem.isChecked === true"
                        (change)="onChangePolicyCheck($event, dataItem)" />
                </ng-template>
            </kendo-grid-checkbox-column>
        </kendo-grid>
    </div>
    <div class="slx-copy-policy-to">
        <p class="slx-copy-head">Copy Policies</p>
        <kendo-grid class="slx-copy-policy-grid" [kendoGridBinding]="dialogData.orgLevels" [filterable]="'menu'">
            <kendo-grid-column title="To" field="organizationName" filter="text" [width]="300"></kendo-grid-column>
            <kendo-grid-checkbox-column [width]="50">
                <ng-template kendoGridHeaderTemplate>
                    <input type="checkbox" class="k-checkbox checkbox-copy-policy" [checked]="isAllOrgsCheck"
                        (change)="selectAllOrganizations()" />
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <input type="checkbox" [value]="dataItem.isChecked === true" [checked]="dataItem.isChecked === true"
                        (change)="onChangeOrgLevelCheck($event, dataItem)" />
                </ng-template>
            </kendo-grid-checkbox-column>
        </kendo-grid>
    </div>
</div>
<div class="footer-btns-container">
    <button type="button" class="discard-btn" (click)="discard()">
        Discard
    </button>
    <button type="button" [ngClass]="saveDisabled ? 'disabled' : 'save-btn'" (click)="save()">
        Copy Selected Policies
    </button>
</div>