export class StaffingPointsTableCell {
    public lowLimitTotal: number;
    public highLimitTotal: number;
    public rating: number;

    public rangeString: string;
    public ratingArray: any[];
}

export interface IStaffingPointsTableCellDto {
    lowLimitTotal: number;
    highLimitTotal: number;
    rating: number;
}
