import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { AppVersionInfo, AppVersionInfoDto } from '../../../state-model/models/index';

@Injectable()
export class VersionMapService {

  public mapServerVersion(item: AppVersionInfoDto): AppVersionInfo {
    Assert.isNotNull(item, 'item');
    let version: AppVersionInfo = new AppVersionInfo();
    version.appVersion = appConfig.appVersion;
    version.serviceVersion = item.serviceVersion;
    version.dbVersion = item.dbVersion;
    version.updateDate = new Date();
    return version;
  }
}


