import { ServerCompositeFilterDescriptor } from './index';
import { SortDescriptor } from '@progress/kendo-data-query';

export class ServerQuery {
  /**
   * The number of records to be skipped by the pager.
   */
  skip?: number;
  /**
   * The number of records to take.
   */
  take?: number;

  sort?: SortDescriptor[];
  /**
   * The descriptors used for filtering.
   */
  filter?: ServerCompositeFilterDescriptor;
}

export class PagingData {
  skip?: number;
  take?: number;
}
