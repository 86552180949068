import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { KendoGridStateHelper, saveEvent } from '../../../../../common/models/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { ModalService } from '../../../../../common/services/index';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitDetailsBasic, BenefitDetailsFormula, coverageOptionTypesList } from '../../../models/index';
import { BenefitDetailsStandartManagementService, BenefitDetailsPermissionService, BenefitDetailsManagementService } from '../../../services/index';
var BenefitDetailsCoverageCalcFormulaWithOptionComponent = /** @class */ (function () {
    function BenefitDetailsCoverageCalcFormulaWithOptionComponent(modalService, commonManService, manService, permissionService) {
        this.modalService = modalService;
        this.commonManService = commonManService;
        this.manService = manService;
        this.permissionService = permissionService;
        this.records = [];
        this.coverageOptionTypes = coverageOptionTypesList;
        this.calculationTypesShort = [];
        this.calculationTypes = [];
        this.empFormula = new BenefitDetailsFormula();
        this.empContribution = new BenefitDetailsBasic(null, null, null);
        this.empFormulaText = '';
        this.empFixed = 100.00;
        this.emprFormula = new BenefitDetailsFormula();
        this.emprContribution = new BenefitDetailsBasic(null, null, null);
        this.emprFormulaText = '';
        this.emprFixed = 150.00;
        this.covFormula = new BenefitDetailsFormula();
        this.coverage = new BenefitDetailsBasic(null, null, null);
        this.covFormulaText = '';
        this.covFixed = 200.00;
        this.covMultiplier = 250.00;
        this.covMaxCap = 280.00;
        this.canAddCovOptions = false;
        this.isEditMode = false;
        this.min = 0;
        this.max = 99999999.99;
        this.multiplierMax = 100;
        this.step = 1;
        this.format = 'c2';
        this.multiplierFormat = 'n2';
        this.hasEnrolledEmpTierLevel = false;
        this.pageSize = 50;
        this.subscriptions = {};
        this.сanEditFormula = false;
        this.showFormula = false;
        this.tierIsDraft = false;
        this.loadedEmpsFor = { orgLevelId: null, tierId: null, date: null };
        this.calculationTypes = this.manService.getFormulaTypes();
        this.calculationTypesShort = this.calculationTypes.slice(0, 2);
        this.multiplierMaxValues = [500, 1000, 2000, 5000, 10000];
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'type', dir: 'asc' }];
        this.gridState.state.take = this.pageSize;
    }
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isRowEditing", {
        get: function () {
            return _.isObjectLike(this.gridState.editedRecord);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isFormulaEditable", {
        get: function () {
            return this.isEditMode && this.сanEditFormula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isEmpFixedCalcType", {
        get: function () {
            return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFixedCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isEmpFormulaCalcType", {
        get: function () {
            return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFormulaCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isEmprFixedCalcType", {
        get: function () {
            return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFixedCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isEmprFormulaCalcType", {
        get: function () {
            return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFormulaCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isCovFormulaCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFormulaCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isCovFixedCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFixedCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isCovMultiplierCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isMultiplierCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isShownFormula", {
        get: function () {
            return this.tierIsDraft || !this.tierIsDraft && this.showFormula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isCovAnyValueCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isAnyValueMaxCapCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isMaxCapLessThanMultiplier", {
        get: function () {
            if (this.covFormula.isMultiplierCalcType && !_.isNull(this.covMaxCap) && !_.isNull(this.covMultiplier)) {
                return _.lt(this.covMaxCap, this.covMultiplier);
            }
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.onEdit = this.gridState.onEdit$
            .subscribe(function (option) {
            _this.permissionService.toggleCanAddCovOptions(false);
        });
        this.subscriptions.onCancel = this.gridState.onCancel$
            .subscribe(function () {
            _this.permissionService.toggleCanAddCovOptions(true);
        });
        this.subscriptions.onSave = this.gridState.onSave$
            .subscribe(function (event) {
            _this.permissionService.toggleCanAddCovOptions(true);
            if (event.isNew) {
                _this.addOption(event.dataItem);
            }
            else {
                _this.updateOption(event.dataItem);
            }
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
        this.subscriptions.providerLine = this.commonManService
            .subscribeToSelectProviderLine(function (pl) {
            _this.selectedProviderLine = pl.current;
            _this.refreshGrid();
        });
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) {
            _this.isEditMode = v.providerInfo;
            _this.recalculateProhibitedValues();
            _this.permissionService.toggleCanAddCovOptions(_this.isEditMode);
            if (!_this.isEditMode) {
                _this.gridState.closeEditor(_this.kendoGrid);
            }
        });
        this.subscriptions.actions = this.permissionService
            .subscribeToUserActions(function (v) {
            _this.сanEditFormula = v.сanEditFormula;
            _this.canAddCovOptions = v.canAddCovOptions;
        });
        this.subscriptions.state = this.manService
            .subscribeToChangeState(function (v) { return (_this.showFormula = v); });
        this.subscriptions.coverageOption = this.manService
            .subscribeToAddNewCoverageOption(function (o) { return _this.addNewOption(o); });
        this.subscriptions.providerTier = this.manService
            .subscribeToSelectProviderTier(function (v) {
            _this.hasEnrolledEmpTierLevel = false;
            _this.selectedProvidedTier = v;
            _this.gridState.closeEditor(_this.kendoGrid);
            _this.filterOutRecords();
            _this.permissionService.toggleCanAddCovOptions(true);
            var empFormula = v.empFormula, erFormula = v.erFormula, coverageFormula = v.coverageFormula;
            _this.tierIsDraft = v.isDraft;
            _this.empFormula = empFormula;
            _this.empFormulaText = empFormula.expression || '';
            _this.empFixed = _.isFinite(empFormula.fixedAmount) ? empFormula.fixedAmount : null;
            _this.emprFormula = erFormula;
            _this.emprFormulaText = erFormula.expression || '';
            _this.emprFixed = _.isFinite(erFormula.fixedAmount) ? erFormula.fixedAmount : null;
            _this.covFormula = coverageFormula;
            _this.covFormulaText = coverageFormula.expression || '';
            _this.covFixed = _.isFinite(coverageFormula.fixedAmount) ? coverageFormula.fixedAmount : null;
            _this.covMultiplier = coverageFormula.multipler || null;
            _this.covMaxCap = _.isFinite(coverageFormula.maxCap) ? coverageFormula.maxCap : null;
            _this.setDropdownValues();
        });
        this.subscriptions.benefitenrolledInfo = this.manService
            .subscribeToBenefitEnrolledEmployeesChanged(function (hasEnrollEmp) {
            _this.hasEnrolledEmpTierLevel = !_this.tierIsDraft && hasEnrollEmp;
        });
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.ngOnDestroy = function () {
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.isCurrentRowEditing = function (option) {
        return this.isRowEditing && this.gridState.editedRecord.id === option.id;
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.addNewOption = function (option) {
        this.gridState.closeEditor(this.kendoGrid);
        this.kendoGrid.addRow(option);
        this.gridState.editedRecord = option;
        this.permissionService.toggleCanAddCovOptions(false);
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.addOption = function (option) {
        this.manService.saveCoverageOption(option, false);
        this.filterOutRecords();
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.updateOption = function (option) {
        this.manService.updateCoverageOption(option);
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.removeOption = function (option) {
        this.manService.removeCoverageOption(option);
        this.filterOutRecords();
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.recalculateProhibitedValues = function () {
        var opts = this.records;
        if (opts) {
            this.prohibitedCodeValues = opts.map(function (o) { return o.code; });
            this.prohibitedTypeValues = opts.map(function (o) { return o.type; });
        }
        else {
            this.prohibitedCodeValues = [];
            this.prohibitedTypeValues = [];
        }
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.filterOutRecords = function () {
        this.records = _.filter(this.selectedProvidedTier.options, function (o) { return !o.stateIsDeleted; });
        this.refreshGrid();
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.refreshGrid = function () {
        this.recalculateProhibitedValues();
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onAdd = function () {
        this.manService.addNewCoverageOption();
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onDeleteOption = function (dataItem) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        var message = (this.hasEnrolledEmpTierLevel) ?
            'Are you sure you want to remove selected coverage option? This will remove this plan for all enrolled employees.' :
            'Are you sure you want to remove selected coverage option? This will have no effect on enrolled employees.';
        ConfirmDialog2Component.openDialog('Delete Coverage Option', message, this.modalService, function (isDelete) {
            if (isDelete) {
                _this.removeOption(dataItem);
                _this.permissionService.toggleCanAddCovOptions(true);
            }
        }, options);
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onChangeEmpContributionType = function () {
        if (this.empFormula.calculationType !== this.empContribution.name) {
            this.empFormula.calculationType = this.empContribution.name;
            this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onChangeEmpContribution = function () {
        if (_.isString(this.empContribution.name)) {
            this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onChangeEmprContributionType = function () {
        if (this.emprFormula.calculationType !== this.emprContribution.name) {
            this.emprFormula.calculationType = this.emprContribution.name;
            this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onChangeEmprContribution = function () {
        if (_.isString(this.emprContribution.name)) {
            this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onChangeCoverageType = function () {
        if (this.covFormula.calculationType !== this.coverage.name) {
            this.covFormula.calculationType = this.coverage.name;
            this.manService.updateTierCoverageFormula(this.coverage.name, this.covFixed, this.covFormulaText, this.covMultiplier, this.covMaxCap);
        }
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.onChangeCoverage = function () {
        if (_.isString(this.coverage.name)) {
            this.manService.updateTierCoverageFormula(this.coverage.name, this.covFixed, this.covFormulaText, this.covMultiplier, this.covMaxCap);
        }
    };
    BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype.setDropdownValues = function () {
        var _this = this;
        this.empContribution = new BenefitDetailsBasic(null, null, null);
        this.emprContribution = new BenefitDetailsBasic(null, null, null);
        this.coverage = new BenefitDetailsBasic(null, null, null);
        var empContr = null;
        var emprContr = null;
        var cov = null;
        _.forEach(this.calculationTypes, function (ct) {
            if (_.isNil(empContr) && ct.name === _this.empFormula.calculationType) {
                _this.empContribution = empContr = ct;
            }
            if (_.isNil(emprContr) && ct.name === _this.emprFormula.calculationType) {
                _this.emprContribution = emprContr = ct;
            }
            if (_.isNil(cov) && ct.name === _this.covFormula.calculationType) {
                _this.coverage = cov = ct;
            }
        });
    };
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "isTypeReadOnly", {
        get: function () {
            return !this.isEditMode || this.hasEnrolledEmpTierLevel;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsCoverageCalcFormulaWithOptionComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsCoverageCalcFormulaWithOptionComponent;
}());
export { BenefitDetailsCoverageCalcFormulaWithOptionComponent };
