import { Injectable } from '@angular/core';

import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Meta, SlxHttpRequest } from '../../../../core/models/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitEligibilityRulesMapService } from './benefit-eligibility-rules-map.service';
import {
  IBenefitEligibilityRule,
  BenefitEligibilityRule,
  BenefitEligibilityRulesChangeRequest,
  BenefitEligibilityRuleConfigurationData,
  IBenefitEligibilityRuleConfigurationData,
} from '../../models/index';

@Injectable()
export class BenefitEligibilityRulesApiService {
  constructor(
    private mapService: BenefitEligibilityRulesMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) {}

  public async getBenefitEligibilityRule(ruleId: number): Promise<BenefitEligibilityRule> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.eligibilityRule}`;
    const params = {
      ruleId: ruleId,
    };
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, params);

    const response = await this.apiUtilService.requestNew<IBenefitEligibilityRule, Meta>(request);
    return this.mapService.mapToBenefitEligibilityRule(response.data);
  }

  public async getBenefitEligibilityRulesByTierId(benefitTierId: number): Promise<BenefitEligibilityRule[]> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.eligibilityRuleByTier}`;
    const params = {
      benefitTierId: benefitTierId,
    };
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, params);

    const response = await this.apiUtilService.requestNew<IBenefitEligibilityRule[], Meta>(request);
    return this.mapService.mapToBenefitEligibilityRules(response.data);
  }

  public async getBenefitEligibilityRulesNotBelongsToTier(benefitTierId: number): Promise<BenefitEligibilityRule[]> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.eligibleRules}`;
    const params = {
      benefitTierId: benefitTierId,
    };
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url, params);

    const response = await this.apiUtilService.requestNew<IBenefitEligibilityRule[], Meta>(request);
    return this.mapService.mapToBenefitEligibilityRules(response.data);
  }

  public async changeEligibilityRules(
    orgLevelId: number,
    benefitTierId: number,
    changeRequest: BenefitEligibilityRulesChangeRequest
  ): Promise<any> {
    const url = `${this.getApiUrl()}/${benefitsConfig.api.benefits.eligibilityRule}`;
    const params = {
      orgLevelId: orgLevelId,
      benefitTierId: benefitTierId,
    };
    const body = this.mapService.mapToBenefitEligibilityRuleChangeRequestDto(changeRequest);
    const request = this.urlParamsService.requestPost(url, body, params);
    request.autoContentType = true;

    return this.apiUtilService.requestNew<any, Meta>(request);
  }

  public async getBenefitEligibilityConfiguration(): Promise<BenefitEligibilityRuleConfigurationData> {
    const url: string = `${this.getApiUrl()}/${benefitsConfig.api.benefits.eligibilityRuleConfiguration}`;
    const request: SlxHttpRequest<any> = this.urlParamsService.requestGet(url);

    const response = await this.apiUtilService.requestNew<IBenefitEligibilityRuleConfigurationData, Meta>(request);
    return this.mapService.mapToBenefitEligibilityRuleConfigurationData(response.data);
  }

  private getApiUrl(): string {
    return `${this.apiUtilService.getApiRoot()}/${benefitsConfig.api.benefits.root}`;
  }
}
