import { IEmployeeDailyPunches, EmployeeDailyPunches, DailyLinePunchActions } from './index';

export interface IEmployeesPunchesContainer {
  status: string;
  startDate: string;
  endDate: string;
  orgLevelId: number;
  entities: IEmployeeDailyPunches[];
}

export class EmployeesPunchesContainer {
  status: string;
  startDate: Date;
  endDate: Date;
  orgLevelId: number;
  entities: EmployeeDailyPunches[];
  filteredEntities: EmployeeDailyPunches[];
  actions: DailyLinePunchActions;
}
