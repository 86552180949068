import { EmployeeSectionsContacts } from './employee-sections-contacts';
import { EmployeeSectionsDocuments } from './employee-sections-documents';
import { EmployeeSectionsEmergencyContacts } from './employee-sections-emergency-contacts';
import { EmployeeSectionsProfile } from './employee-sections-profile';
import { EmployeeSectionsLicenses } from './employee-sections-licenses';
import { EmployeeSectionsPhysicals } from './employee-sections-physicals';
import { IEmployeeSectionsSubsectionContainer } from '../index';
import {
  IEmployeeSectionsProfile,
  IEmployeeSectionsContacts,
  IEmployeeSectionsEmergencyContacts,
  IEmployeeSectionsLicenses,
  IEmployeeSectionsPhysicalGroup,
  IEmployeeSectionsDocuments,
  EmployeeSubsectionWarnings,
  IEmployeeSubsectionWarnings
} from './index';
import {
  IEmployeeSectionsBackgroundChecks,
  EmployeeSectionsBackgroundChecks
} from './employee-sections-background-check';

import {
  IEmployeeSectionsCertifications,
  EmployeeSectionsCertifications
} from './employee-sections-certification';
import { EmployeeSectionsEducations, IEmployeeSectionsEducations } from './employee-sections-educations';
import {  AgencyCertificates, IAgencyCertificates } from './employee-sections-agency-certificate';

export interface IEmployeeSectionsPersonal {
  profile: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsProfile>;
  contacts: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsContacts>;
  emergencyContacts: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsEmergencyContacts>;
  certifications: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsCertifications>;
  warnings: IEmployeeSectionsSubsectionContainer<IEmployeeSubsectionWarnings>;
  licenses: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsLicenses>;
  physicals: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsPhysicalGroup[]>;
  documents: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsDocuments>;
  backgroundChecks: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsBackgroundChecks>;
  education: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsEducations>;
  agencyCertificate: IEmployeeSectionsSubsectionContainer<IAgencyCertificates>;
}

export class EmployeeSectionsPersonal {
  public profile: EmployeeSectionsProfile;
  public contacts: EmployeeSectionsContacts;
  public emergencyContacts: EmployeeSectionsEmergencyContacts;
  public certifications: EmployeeSectionsCertifications;
  public warnings: EmployeeSubsectionWarnings;
  public licenses: EmployeeSectionsLicenses;
  public physicals: EmployeeSectionsPhysicals;
  public documents: EmployeeSectionsDocuments;
  public backgroundChecks: EmployeeSectionsBackgroundChecks;
  public education: EmployeeSectionsEducations;
  public agencyCertificate: AgencyCertificates;
}
