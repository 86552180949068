import { Component, Input, OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { SupervisorGroup, SupervisorGroupsContainer, Supervisor, SupervisedEmployee, SupervisorAssignmentAction } from '../../../models/index';
import { SupervisorAssignmentManagementService, supervisorsGroup, employeesGroup } from '../../../services/supervisor-assignment/supervisor-assignment-management.service';
import { unsubscribe } from '../../../../core/decorators/index';


@Component({
  moduleId: module.id,
  selector: 'slx-supervisor-group-detail',
  templateUrl: 'supervisor-group-detail.component.html',
  styleUrls: ['supervisor-group-detail.component.scss']
})
export class SupervisorGroupDetailComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public group: SupervisorGroup;
  @Input()
  public disableOperations: boolean;
  public selectedSupervisors: Supervisor[];
  public selectedEmployees: SupervisedEmployee[];
  @unsubscribe()
  private deletedSupervisorCmdSubscription: Subscription;
  @unsubscribe()
  private addedSupervisorCmdSubscription: Subscription;
  @unsubscribe()
  private deletedSupervisedEmployeeCmdSubscription: Subscription;
  @unsubscribe()
  private addedSupervisedEmployeeCmdSubscription: Subscription;


  constructor(private managementService: SupervisorAssignmentManagementService, private changeDetector: ChangeDetectorRef) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public ngOnInit(): void {
    this.deletedSupervisorCmdSubscription = this.managementService.deletedSupervisorsCmd$.subscribe(
      (result: supervisorsGroup) => {
        if (this.group.id !== result.group.id) {
          return;
        }
        this.selectedSupervisors = null;
        result.group.supervisors = result.supervisors;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.addedSupervisorCmdSubscription = this.managementService.addedSupervisorsCmd$.subscribe((result: { group: SupervisorGroup, supervisors: Supervisor[] }) => {
      if (this.group.id !== result.group.id) {
        return;
      }
      this.selectedSupervisors = null;
      result.group.supervisors = result.supervisors;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.deletedSupervisedEmployeeCmdSubscription = this.managementService.deletedSupervisedEmployeesCmd$.subscribe(
      (result: employeesGroup) => {
        if (this.group.id !== result.group.id) {
          return;
        }
        this.selectedEmployees = null;
        result.group.employees = result.employees;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.addedSupervisedEmployeeCmdSubscription = this.managementService.addedSupervisedEmployeesCmd$.subscribe(
      (result: {group: SupervisorGroup, employees: SupervisedEmployee[]}) => {
        if (this.group.id !== result.group.id) {
          return;
        }
        this.selectedEmployees = null;
        result.group.employees = result.employees;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public supervisorsSelectionChanged(supervisors: Supervisor[]): void {
    this.selectedSupervisors = supervisors;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public employeeSelectionChanged(employees: SupervisedEmployee[]): void {
    this.selectedEmployees = employees;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }


  public onAddSupervisors(): void {
    this.managementService.startAddSupervisorCmd(this.group);
  }

  public onRemoveSupervisors(): void {
    this.managementService.deleteSupervisorsFromGroupCmd(this.group, this.selectedSupervisors);
  }

  public saveSuprvisor(supervisor: Supervisor): void {
    this.managementService.updateSupervisor(supervisor);
  }

  public onAddEmployees(): void {
    this.managementService.startAddEmployeeCmd(this.group);
  }

  public onRemoveEmployees(): void {
    this.managementService.deleteSupervisedEmployeesFromGroupCmd(this.group, this.selectedEmployees);
  }
}

