import * as _ from 'lodash';
import { Component, Provider, ViewChild, OnInit } from '@angular/core';

import { DialogOptions, IDialog } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { NgControl, AbstractControl, NgForm, NgModel } from '@angular/forms';
import { EmployersManagementService } from '../../../services/index';
import { LookupService } from '../../../../organization/services/lookup/lookup.service';
import { Lookup, LookupType } from '../../../../organization';
import { AleGroup } from '../../../models/employers/ale-group';
import { Subscription } from 'rxjs';
import { unsubscribe } from '../../../../core/decorators/index';
import { Employer, EmployerDialogOptions } from '../../../models/employers/index';
import { ICommonConfig, commonConfig } from '../../../../common/common.config';

@Component({
  moduleId: module.id,
  selector: 'slx-manage-employer-dialog',
  templateUrl: 'manage-employer-dialog.component.html',
  styleUrls: ['manage-employer-dialog.component.scss'],
  providers: [EmployersManagementService]
})

export class ManageEmployerDialogComponent implements OnInit, IDialog {
  public dialogResult: boolean = false;
  public isLoading: boolean = true;
  public employer: Employer;
  private employerDialogOptions: EmployerDialogOptions;
  public headerTitle: string;

  @ViewChild('employerForm', { static: true })
  private ngFormChild: NgForm;
  allFeins: string[];
  oldFein: string;
  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  public get valid(): boolean {
    let formValid: boolean = this.form.invalid ? false : true;
    return formValid;
  }
  public stateLookup: Lookup;
  public aleGroupList: AleGroup[];

  private configureMode: boolean;
  private options: DialogOptions;
  private modalService: ModalService;
  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private subscriptionsClose: Subscription;
  public commonConfig: ICommonConfig;

  public static openDialog(employerDialogOptions: EmployerDialogOptions, modalService: ModalService, callback?: (result: boolean) => void): ManageEmployerDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 800;
    dialogOptions.height = 570;
    dialogOptions.hideTitleBar = true;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = false;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: EmployerDialogOptions,
        useValue: employerDialogOptions
      },
      {
        provide: NgControl,
        useValue: employerDialogOptions
      }
    ];

    return modalService.globalAnchor.openDialog(
      ManageEmployerDialogComponent,
      '',
      dialogOptions,
      resolvedProviders,
      callback
    );
  }
  
  constructor(
    options: DialogOptions,
    modalService: ModalService,
    employerDialogOptions: EmployerDialogOptions,
    private manService: EmployersManagementService,
    private lookupService: LookupService
  ) {
    this.options = options;
    this.modalService = modalService;
    this.employerDialogOptions = employerDialogOptions;
    if (this.employerDialogOptions && this.employerDialogOptions.employer) {
      if (this.employerDialogOptions.employer.companyId == 0) {
        this.headerTitle = 'Create New Company';
      }
      else {
        this.headerTitle = 'Edit Company';
        this.oldFein = this.employerDialogOptions.employer.federalEIN.replace('-', '');
      }
    }
  }

  public ngOnInit(): void {
    this.isLoading = false;
    this.commonConfig = commonConfig;
    this.stateSubscription = this.manService
      .subscribeToOnStateChanged((state: any) => {
        this.isLoading = state.isLoading;
      });

    this.subscriptionsClose = this.manService.subscribeToClosePopup(() => {
      this.onClose();
    });

    if (this.employerDialogOptions) {
      this.employer = this.employerDialogOptions.employer;
      this.aleGroupList = this.employerDialogOptions.aleGroupList;
      this.allFeins = this.employerDialogOptions.allFeins;
      if (this.employer) {
        this.loadStateLookup(this.employer.orgLevelId);
      }
    }
  }
  
  public async onSave(): Promise<void> {
    await this.manService.onSaveEmployer(this.employer);
    this.dialogResult = this.ngFormChild.dirty;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
      if (this.ngFormChild && this.ngFormChild.dirty) {
        this.manService.markAsDirty();
        this.manService.requestClosePopup();
      }
      else {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
      }
  }

  public onClose(): void {
      if (this.ngFormChild && this.ngFormChild.dirty)
        this.dialogResult = this.ngFormChild.dirty;

    this.modalService.closeWindow(this.options.windowUniqueId);    
  }

  private loadStateLookup(orgLevelId: number): void {

    this.lookupService.getLookup({ lookupType: LookupType.state, orgLevelId: orgLevelId })
      .then((stateLookup: Lookup) => {
        this.stateLookup = stateLookup;
      })
      .catch(() => {
      });
  }

  public onChangeSameAsLegal(isChecked: boolean): void {
    if (isChecked) {
      this.employer.isSameAsLegalAddress = true;
      this.employer.businessAddress1 = this.employer.legalAddress1;
      this.employer.businessAddress2 = this.employer.legalAddress2;
      this.employer.businessCity = this.employer.legalCity;
      this.employer.businessStateModel = this.employer.legalStateModel;
      this.employer.businessZip = this.employer.legalZip;
    }
    else {
      this.employer.isSameAsLegalAddress = false;
    }
  }
  checkDuplicateFein(fein: string, ngModel: NgModel){
    if((fein.indexOf('-') == -1 && fein.length == 9) || fein.length == 10){
      fein = fein.replace('-', '');
      if (this.employerDialogOptions.employer.companyId != 0){
        if(this.oldFein != fein){
          this.validateFein(fein, ngModel);
        }
      }
      else{
        this.validateFein(fein, ngModel);
      }   
    }
  }
  validateFein(fein: string, ngModel: NgModel){
    const errors = ngModel.control.errors || {};
    if(this.allFeins.includes(fein)){
      errors.duplicateFein = true;
      ngModel.control.setErrors(errors);
    }
    else{
      delete errors.duplicateFein;
      ngModel.control.setErrors(_.size(errors) > 0 ? errors : null);
    }
  }
}
