export class OrgLevelType {
  public static corporation: OrgLevelTypeDefinition = 'Corporation';
  public static entity: OrgLevelTypeDefinition = 'Entity';
  public static region: OrgLevelTypeDefinition = 'Region';
  public static division: OrgLevelTypeDefinition = 'Division';
  public static organization: OrgLevelTypeDefinition = 'Organization';
  public static department: OrgLevelTypeDefinition = 'Department';
}

export type OrgLevelTypeDefinition = 'Organization' | 'Region' | 'Division' | 'Corporation' | 'Entity'|  'Department';
