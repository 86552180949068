import { ShiftEligibleEmployee } from './index';

export interface IShiftAddEmployeeCmd {
  unitId: number;
  positionId: number;
  employeeIds: number[];
}

export class ShiftAddEmployeeCmd {
  public selectedEmployee: ShiftEligibleEmployee;
  public dateOn: Date;
  public unitId: number;
  public shiftId: number;
  public positionId: number;
}
