/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "angular-froala-wysiwyg/editor/editor.directive";
import * as i3 from "./text-editor.component";
var styles_TextEditorComponent = [];
var RenderType_TextEditorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextEditorComponent, data: {} });
export { RenderType_TextEditorComponent as RenderType_TextEditorComponent };
export function View_TextEditorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "slx-text-editor"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "textarea", [], [[8, "disabled", 0]], [[null, "froalaModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("froalaModelChange" === en)) {
        var pd_0 = ((_co.innerHtml = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.FroalaEditorDirective]), i0.ɵdid(3, 212992, null, 0, i2.FroalaEditorDirective, [i0.ElementRef, i0.NgZone], { froalaEditor: [0, "froalaEditor"], froalaModel: [1, "froalaModel"] }, { froalaModelChange: "froalaModelChange" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.options; var currVal_2 = _co.innerHtml; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); }); }
export function View_TextEditorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "slx-text-editor", [], null, null, null, View_TextEditorComponent_0, RenderType_TextEditorComponent)), i0.ɵprd(5120, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.TextEditorComponent]), i0.ɵdid(2, 49152, null, 0, i3.TextEditorComponent, [], null, null)], null, null); }
var TextEditorComponentNgFactory = i0.ɵccf("slx-text-editor", i3.TextEditorComponent, View_TextEditorComponent_Host_0, { incomingOptions: "options", disabled: "disabled" }, { onTextChange: "onTextChange" }, []);
export { TextEditorComponentNgFactory as TextEditorComponentNgFactory };
