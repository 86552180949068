import * as tslib_1 from "tslib";
import { TimecardsApiService } from './../../../../../time-and-attendance/services/timecards/timecards-api.service';
import { EmployeeRehireDialogComponent } from './../../../../employee/components/employee-activities/employee-rehire/employee-rehire-dialog/employee-rehire-dialog.component';
import { OnInit, OnDestroy, NgZone, EventEmitter, SimpleChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeSectionsProfile, BenefitClassDialogReq } from '../../../models/index';
import { EmployeeSectionsPersonalApiService, EmployeeSectionBridgeService } from '../../../services/index';
import { AccessibleService, EmployeeDefinitionsApiService, LookupApiService, LookupService } from '../../../../../organization/services/index';
import { NotificationsService } from '../../../../../core/components/index';
import { EmployeeTerminateDialogComponent, EmployeeTransferDialogComponent, ChangeTerminationDateDialogComponent } from '../../../../employee/components/index';
import { EmployeeToTerminate, EmployeeToTransfer, EmployeeToRehire } from '../../../../employee/models/index';
import { EmployeeTerminateApiService, EmployeeTransferApiService, EmployeeRehireApiService } from '../../../../employee/services/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { InfoDialogComponent, EmployeeListNavigationService, EmployeeSectionNavigationService, ConfirmOptions, ConfirmDialogComponent } from '../../../../../common/index';
import { AgencyEmployeeApiService } from '../../../../../configuration/services/index';
import { BenefitClassDialogComponent } from '../../benefit-class-dialog/benefit-class-dialog.component';
import * as moment from 'moment';
import { AppSettingsManageService } from './../../../../../app-settings/services/app-settings-manage.service';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { employeeConfig } from '../../../../employee/employee.config';
import { SessionService } from '../../../../../../app/core/services/session/session.service';
var EmployeeSectionsProfileComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsProfileComponent, _super);
    function EmployeeSectionsProfileComponent(employeeSectionsPersonalApiService, employeeDefinitionsApiService, employeeTerminateApiService, employeeTransferApiService, modalService, domSanitizer, decorator, ngZone, employeeRehireApiService, agencyEmployeeApiService, router, route, lookupService, appSettingsManageService, notificationService, document, employeeSectionBridgeService, lookupApiService, timecardsApi, accessibleService, activatedRoute, sessionService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeRehireApiService = employeeRehireApiService;
        _this.agencyEmployeeApiService = agencyEmployeeApiService;
        _this.router = router;
        _this.route = route;
        _this.lookupService = lookupService;
        _this.appSettingsManageService = appSettingsManageService;
        _this.notificationService = notificationService;
        _this.document = document;
        _this.lookupApiService = lookupApiService;
        _this.timecardsApi = timecardsApi;
        _this.accessibleService = accessibleService;
        _this.activatedRoute = activatedRoute;
        _this.sessionService = sessionService;
        _this.isV6ReHireEnhacementEnabled = false;
        _this.isNGPUser = false;
        _this.isSystemUser = false;
        _this.hourlyRateLimit = 9999.99;
        _this.weeklyRateLimit = 399999.99;
        _this.bswiftEnabled = false;
        _this.domSanitizer = domSanitizer;
        _this.employeeSectionsPersonalApiService = employeeSectionsPersonalApiService;
        _this.employeeTransferApiService = employeeTransferApiService;
        _this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        _this.employeeTerminateApiService = employeeTerminateApiService;
        _this.modalService = modalService;
        _this.updateBanner = new EventEmitter();
        _this.disabilityStatusesLookup = _this.getDisabilityStatusesLookup();
        _this.employeeSectionBridgeService = employeeSectionBridgeService;
        _this.maxBirthDate = moment().subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
        return _this;
    }
    Object.defineProperty(EmployeeSectionsProfileComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsProfileComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsProfile;
    };
    Object.defineProperty(EmployeeSectionsProfileComponent.prototype, "getRateLimit", {
        get: function () {
            var isSalaryPayType = this.employeeShortInfo &&
                this.employeeShortInfo.payType &&
                this.employeeShortInfo.payType.isSalaryPayType;
            return isSalaryPayType ? this.weeklyRateLimit : this.hourlyRateLimit;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getSettings();
        this.contactSubscriptions = this.employeeSectionBridgeService.subscribeTorReloadProfileSection(function (contact) {
            _this.employeeSectionsProfile.mobilePhone = contact.mobile;
            _this.employeeSectionsProfile.phone = contact.phone;
        });
        this.hireDateSubscriptions = this.employeeSectionBridgeService.subscribeToChangeHireDate(function (hireDate) {
            _this.employeeSectionsProfile.hireDate.fieldValue = hireDate;
            _this.maxBirthDate = moment(hireDate).subtract(employeeConfig.maxBirthDateBeforeCurrentInYears, 'year').toDate();
        });
    };
    EmployeeSectionsProfileComponent.prototype.ngOnChanges = function (changes) {
        if (changes['employeeSectionsProfile']) {
            if (this.employeeSectionsProfile && this.employeeSectionsProfile.birthDate.fieldValue) {
                this.employeeSectionBridgeService.changeBirthDate(this.employeeSectionsProfile.birthDate.fieldValue);
            }
        }
    };
    EmployeeSectionsProfileComponent.prototype.onTerminate = function (ignoreLockedPayrollCycles) {
        var _this = this;
        var req = new EmployeeToTerminate();
        req.employeeId = this.employeeId;
        req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
        req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
        req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
        req.suffix = this.employeeSectionsProfile.suffix.fieldValue;
        req.fullName = this.employeeShortInfo.fullName;
        req.ignoreLockedPayrollCycles = ignoreLockedPayrollCycles;
        req.rehireDate = this.employeeSectionsProfile.rehireDate;
        EmployeeTerminateDialogComponent.openDialog(req, this.modalService, function (result, res) {
            if (result && res) {
                _this.employeeSectionsProfile.terminationDate = res.date;
                _this.startProgress();
                _this.employeeTerminateApiService.terminateEmployee(res)
                    .then(function (status) {
                    _this.loadSubsection();
                    _this.notificationService.success('Employee Terminated', 'Employee has terminated successfully');
                })
                    .catch(function () {
                    _this.stopProgress();
                });
            }
        });
    };
    EmployeeSectionsProfileComponent.prototype.onCancelTerminate = function () {
        var _this = this;
        var options = new ConfirmOptions();
        if (this.isSystemUser) { //It is true when NGP flag is true
            options.showCancel = true;
            options.showOK = true;
            options.width = 350;
            options.height = 240;
            options.notes = 'This action will not reactive the employee in CarePay, please manually reactivate the employee in CarePay to make sure the records in both systems are in sync';
            ConfirmDialogComponent.openDialog('Cancel Employee Termination', "Do you want to cancel termination for " + this.employeeShortInfo.fullName, this.modalService, function (result) {
                if (result) {
                    _this.cancelTermination();
                }
            }, options);
        }
        else {
            this.modalService.globalAnchor.openConfirmDialog('Cancel Employee Termination', "Do you want to cancel termination for " + this.employeeShortInfo.fullName, function (result) {
                if (result) {
                    _this.cancelTermination();
                }
            });
        }
    };
    EmployeeSectionsProfileComponent.prototype.cancelTermination = function () {
        var _this = this;
        this.startProgress();
        this.employeeTerminateApiService.cancelEmployeeTermination(this.employeeId)
            .then(function (status) {
            _this.loadSubsection();
        })
            .catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsProfileComponent.prototype.onChangeTerminationDate = function (ignoreLockedPayrollCycles) {
        var _this = this;
        var req = new EmployeeToTerminate();
        req.employeeId = this.employeeId;
        req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
        req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
        req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
        req.terminationDate = this.employeeSectionsProfile.terminationDate;
        req.fullName = this.employeeShortInfo.fullName;
        req.ignoreLockedPayrollCycles = ignoreLockedPayrollCycles;
        req.isNGPUser = this.isNGPUser;
        req.isSystemUser = this.isSystemUser;
        ChangeTerminationDateDialogComponent.openDialog(req, this.modalService, function (result, res) {
            if (result && res) {
                _this.employeeSectionsProfile.terminationDate = res.date;
                _this.startProgress();
                _this.employeeTerminateApiService.changeTerminationDate(res.employeeId, res.date)
                    .then(function (status) {
                    _this.loadSubsection();
                })
                    .catch(function () {
                    _this.stopProgress();
                });
            }
        });
    };
    EmployeeSectionsProfileComponent.prototype.onResendTimeclocksEmail = function () {
        var _this = this;
        this.modalService.globalAnchor.openConfirmDialog('Confirm Action', 'Do you want resend timeclocks email to this employee?', function (result) {
            if (result) {
                _this.startProgress();
                _this.agencyEmployeeApiService.sendTimeclocksEmailToAgencyEmployee(_this.employeeId).then(function () {
                    _this.stopProgress();
                }).catch(function () {
                    _this.stopProgress();
                });
            }
        });
    };
    EmployeeSectionsProfileComponent.prototype.onTransfer = function () {
        var _this = this;
        var req = new EmployeeToTransfer();
        req.employeeId = this.employeeId;
        req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
        req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
        req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
        req.suffix = this.employeeSectionsProfile.suffix.fieldValue;
        req.organization = this.employeeSectionsProfile.organization.fieldValue;
        req.organizationId = this.employeeSectionsProfile.organizationId;
        req.department = this.employeeSectionsProfile.department.fieldValue;
        req.departmentId = this.employeeSectionsProfile.departmentId;
        req.position = this.employeeSectionsProfile.position.fieldValue;
        req.positionId = this.employeeSectionsProfile.positionId;
        req.hireDate = this.employeeSectionsProfile.hireDate.fieldValue;
        req.fullName = this.employeeShortInfo.fullName;
        req.employeeType = this.employeeShortInfo.type;
        req.payrollNumber = this.employeeShortInfo.payrollNumber;
        EmployeeTransferDialogComponent.openDialog(req, this.modalService, function (result, res) {
            if (result && res) {
                _this.startProgress();
                _this.employeeTransferApiService.transferEmployee(res)
                    .then(function (data) {
                    _this.notificationService.success('Employee Transferred', 'Employee has transferred successfully');
                })
                    .then(function () {
                    _this.document.location.reload();
                })
                    .catch(function () { return _this.stopProgress(); });
            }
        });
    };
    EmployeeSectionsProfileComponent.prototype.undoTransfer = function () {
        var _this = this;
        this.modalService.globalAnchor.openConfirmDialog('Are you sure?', 'Do you want to undo transfer of employee?', function (result) {
            if (result) {
                _this.startProgress();
                _this.employeeTransferApiService.undoTransferEmployee(_this.employeeId)
                    .then(function () {
                    _this.stopProgress();
                    var nav = new EmployeeListNavigationService(_this.router, _this.route);
                    nav.navigateToEmployeeList();
                })
                    .catch(function () { return _this.stopProgress(); });
            }
        });
    };
    EmployeeSectionsProfileComponent.prototype.onRehire = function () {
        var _this = this;
        var req = new EmployeeToRehire();
        req.employeeId = this.employeeId;
        req.firstName = this.employeeSectionsProfile.firstName.fieldValue;
        req.lastName = this.employeeSectionsProfile.lastName.fieldValue;
        req.middleName = this.employeeSectionsProfile.middleName.fieldValue;
        req.terminationDate = this.employeeSectionsProfile.terminationDate;
        req.departmentId = this.employeeSectionsProfile.departmentId;
        req.mobilePhone = this.employeeSectionsProfile.mobilePhone.fieldValue;
        req.orgLevelId = this.employeeShortInfo.position.orgLevelId;
        req.fullName = this.employeeShortInfo.fullName;
        req.payRateLimit = this.getRateLimit;
        req.dialogHeight = this.isV6ReHireEnhacementEnabled ? 790 : 570;
        req.employeeType = this.employeeShortInfo.type;
        req.organizationId = this.employeeSectionsProfile.organizationId;
        req.payrollNumber = this.employeeShortInfo.payrollNumber;
        EmployeeRehireDialogComponent.openDialog(req, this.modalService, function (result, res) {
            if (result && res) {
                if (result && res) {
                    _this.startProgress();
                    var newRehireParam = _this.isV6ReHireEnhacementEnabled ? 'enhancement' : '';
                    _this.employeeRehireApiService.rehireEmployee(res, newRehireParam)
                        .then(function (empId) {
                        if (!_this.isV6ReHireEnhacementEnabled) {
                            _this.notificationService.success('Employee Rehired', 'Employee was successfully rehired');
                            _this.employeeSectionBridgeService.updateContactSection(res.mobilePhone);
                            _this.loadSubsection();
                        }
                        else {
                            if (_this.employeeId == empId) {
                                _this.notificationService.success('Employee Rehired', 'Employee was successfully rehired');
                                _this.employeeSectionBridgeService.updateContactSection(res.mobilePhone);
                                _this.loadSubsection();
                            }
                            else {
                                _this.notificationService.success('Employee Rehired', 'Employee was successfully rehired');
                                setTimeout(function () {
                                    var navService = new EmployeeSectionNavigationService(_this.router, _this.activatedRoute);
                                    var urlTree = navService.getUrlTreeFromRoot(empId, true);
                                    var extras = {
                                        skipLocationChange: false,
                                        replaceUrl: false,
                                    };
                                    _this.router.navigateByUrl(urlTree, extras);
                                }, 1000);
                            }
                        }
                    })
                        .catch(function () { return _this.stopProgress(); });
                }
            }
        });
    };
    Object.defineProperty(EmployeeSectionsProfileComponent.prototype, "employeeFullName", {
        get: function () {
            if (!this.employeeSectionsProfile.fullName.fieldValue)
                return this.employeeSectionsProfile.lastName.fieldValue + ', ' + this.employeeSectionsProfile.firstName.fieldValue + ' ' + this.employeeSectionsProfile.middleName.fieldValue;
            else
                return this.employeeSectionsProfile.fullName.fieldValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsProfileComponent.prototype, "statusDateLabel", {
        get: function () {
            if (this.employeeSectionsProfile && this.employeeSectionsProfile.isTransferred)
                return 'Date of Transfer';
            return 'Date of Termination';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsProfileComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsPersonalApiService.getPersonalProfile(this.employeeId)
            .then(function (employeeSectionsProfile) {
            _this.employeeSectionsProfile = employeeSectionsProfile;
            _this.updateBanner.emit(employeeSectionsProfile);
            _this.employeeSectionBridgeService.changeBirthDate(_this.employeeSectionsProfile.birthDate.fieldValue);
            _this.stopProgress();
        });
    };
    EmployeeSectionsProfileComponent.prototype.doSave = function (effectiveDate) {
        if (this.bswiftEnabled &&
            !this.employeeShortInfo.avoidsBswiftChecks &&
            this.employeeSectionsProfile.areBenefitsFieldsDirty()) {
            this.triggerBenefitClassPopup(effectiveDate);
        }
        else {
            this.savePersonalProfile(effectiveDate);
        }
    };
    EmployeeSectionsProfileComponent.prototype.savePersonalProfile = function (effectiveDate) {
        var _this = this;
        this.startProgress();
        return this.employeeSectionsPersonalApiService.setPersonalProfile(this.employeeId, this.employeeSectionsProfile, effectiveDate)
            .then(function (status) {
            _this.employeeDefinitionsApiService.clearEmployeeShortInfoCache(_this.employeeId);
            // Once an employee information changes, invalidating all the cached objects
            _this.lookupApiService.clearEmployeeCacheData();
            _this.lookupApiService.clearPositionsCacheData();
            _this.timecardsApi.clearIndividualTimecardsCacheData();
            _this.accessibleService.clearCheckedByOrglevelCachedData();
            _this.accessibleService.clearCheckedByEmployeeCachedData();
            _this.onActionComplete(true);
            _this.loadSubsection();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsProfileComponent.prototype.checkTemporalDirty = function () {
        return this.metaFieldsTemporalDirtyChecker(this.employeeSectionsProfile);
    };
    EmployeeSectionsProfileComponent.prototype.getDisabilityStatusesLookup = function () {
        return this.lookupService.getDisabilityLookup();
    };
    EmployeeSectionsProfileComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.bswiftEnabled = config.bswiftIntegrationEnabled;
                        this.isV6ReHireEnhacementEnabled = config.isV6ReHireEnhacementEnabled;
                        this.isNGPUser = config.IsNextgenPayrollEnabled;
                        if (this.isNGPUser) {
                            this.isSystemUser = this.sessionService.getUser() && this.sessionService.getUser().username.toLowerCase() === 'system' ? true : false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsProfileComponent.prototype.triggerBenefitClassPopup = function (effectiveDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var canEditBenefitClass, request, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.startProgress();
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getCanEditBenefitClass(this.employeeId)];
                    case 1:
                        canEditBenefitClass = _b.sent();
                        if (!canEditBenefitClass) return [3 /*break*/, 3];
                        request = new BenefitClassDialogReq();
                        request.employeeId = this.employeeId;
                        request.hireDate = this.employeeShortInfo.dateHired;
                        _a = request;
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employeeId, moment().startOf('day').toDate())];
                    case 2:
                        _a.employeeBenefitClass = _b.sent();
                        if (this.employeeSectionsProfile.status.toLowerCase() === "future rehire") {
                            request.rehireDate = this.employeeSectionsProfile.rehireDate;
                        }
                        this.stopProgress();
                        BenefitClassDialogComponent.OpenDialog(request, this.modalService, function (result) {
                            if (result) {
                                _this.savePersonalProfile(effectiveDate);
                            }
                            else {
                                _this.loadSubsection();
                            }
                        });
                        return [3 /*break*/, 5];
                    case 3:
                        this.stopProgress();
                        return [4 /*yield*/, this.savePersonalProfile(effectiveDate)];
                    case 4:
                        _b.sent();
                        InfoDialogComponent.OpenDialog('Warning', 'The changes you have made to the employee record could prompt a change to the employee Benefit Class. Please contact your Benefits Administrator and advise them of the changes.', this.modalService);
                        _b.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsProfileComponent.prototype, "contactSubscriptions", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsProfileComponent.prototype, "hireDateSubscriptions", void 0);
    return EmployeeSectionsProfileComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsProfileComponent };
