import { Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
@Pipe({
    name: 'convertUserFrndlyDate',
})

export class ConvertUserFrndlyDate implements PipeTransform {
    transform(date) {
        date = moment.utc(date).local().format();
        const dayDiff = moment(new Date()).diff(date, 'days');
        if(dayDiff < 1) {
            const hours = moment(new Date()).diff(date, 'hours');
            if(hours < 1) {
                let mint = moment(new Date()).diff(date, 'minutes');
                if(mint === 0 || mint === 1) {
                    return '1 minute ago';
                } else {
                    return mint + ' minutes ago';
                }
                
            } else if(hours === 1) {
                return '1 hour ago';
            } else {
                return  hours + ' hours ago';
            }
			
        } else if (dayDiff >= 1 && dayDiff <= 7){
           return moment(new Date(date)).format('dddd - hh:mm A')
        } else {
            let date1 = moment(new Date(date)).format('LLLL');
            let dt = date1.split(',')
			return dt[0] + ' - ' + dt[1].trim() + ', ' + dt[2].trim()
		}		
    }
}

@Pipe({
    name: 'convertDate',
})

export class ConvertDate implements PipeTransform {
    transform(date) {
        date = moment.utc(date).local().format();
        const dayDiff = moment(new Date()).diff(date, 'days');
        if(dayDiff < 1) {
            const hours = moment(new Date()).diff(date, 'hours');
            if(hours < 1) {
                let mint = moment(new Date()).diff(date, 'minutes');
                if(mint === 0 || mint === 1) {
                    return '1 minute ago';
                } else {
                    return mint + ' minutes ago';
                }
                
            } else if(hours === 1) {
                return '1 hour ago';
            } else {
                return  hours + ' hours ago';
            }
			
        } else {
           return moment(new Date(date)).format('MM/DD/YYYY hh:mm A')
        } 
    }
}