import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { BenefitEligibilityRuleStatementGroupItem, BenefitEligibilityRuleVariable, } from '../../models/benefit-eligibility-rules/benefit-eligibility-rule-model';
import * as i0 from "@angular/core";
var BenefitEligibilityRuleStatementsManagementService = /** @class */ (function () {
    function BenefitEligibilityRuleStatementsManagementService() {
    }
    BenefitEligibilityRuleStatementsManagementService.prototype.findConfigurationVariable = function (variableId, configurationVariables) {
        if (!configurationVariables || !variableId) {
            return null;
        }
        var variable = _.find(configurationVariables, function (variable) { return variable.id === variableId; });
        return variable;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.insertRuleStatementBeforeItem = function (item, ruleStatements, isInsertedBeforeFirstItem) {
        var _this = this;
        if (!item) {
            throw new Error('Item cannot be null');
        }
        var itemIndex = _.indexOf(ruleStatements, item);
        if (itemIndex >= 0) {
            var newRuleStatements_1 = this.insertRuleStatementByIndex(itemIndex, ruleStatements, isInsertedBeforeFirstItem);
            return newRuleStatements_1;
        }
        var newRuleStatements = _.map(ruleStatements, function (ruleStatement) {
            if (_.isEmpty(ruleStatement.groups)) {
                return ruleStatement;
            }
            var newGroups = _this.insertRuleStatementBeforeItem(item, ruleStatement.groups, isInsertedBeforeFirstItem);
            if (_.isEqual(newGroups, ruleStatement.groups)) {
                return ruleStatement;
            }
            return new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, ruleStatement, { groups: newGroups }));
        });
        return newRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.insertRuleStatementByIndex = function (index, ruleStatements, isInsertedBeforeFirstItem) {
        if (index < 0 || !ruleStatements || index >= ruleStatements.length) {
            throw new Error('Wrong index');
        }
        var newRuleStatement = new BenefitEligibilityRuleStatementGroupItem({
            sequence: index,
            variable: new BenefitEligibilityRuleVariable(),
        });
        var newRuleStatements = ruleStatements.slice(0, index).concat([newRuleStatement], ruleStatements.slice(index));
        var sequence = isInsertedBeforeFirstItem ? 0 : 1;
        var newRuleStatementsWithOrdering = _.map(newRuleStatements, function (ruleStatement) {
            return new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, ruleStatement, { sequence: sequence++ }));
        });
        return newRuleStatementsWithOrdering;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.addRuleStatement = function (ruleStatements) {
        var lastSequenceNumber = ruleStatements.length > 0 ? ruleStatements[ruleStatements.length - 1].sequence : 0;
        var newRuleStatements = ruleStatements.concat([
            new BenefitEligibilityRuleStatementGroupItem({
                sequence: lastSequenceNumber + 1,
                variable: new BenefitEligibilityRuleVariable(),
            }),
        ]);
        return newRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.removeRuleStatementGroupItem = function (ruleStatement, ruleStatements) {
        var rulesAfterRemoveItem = this.removeRuleStatement(ruleStatement, ruleStatements);
        var rulesWithUpdatedSequenceNumbers = this.updateSequenceNumbers(rulesAfterRemoveItem, 0);
        var rulesWithoutFirstItem = _.without(rulesWithUpdatedSequenceNumbers, rulesWithUpdatedSequenceNumbers[0]);
        var firstRule = this.setConditionTypeNullToFirstItem(rulesWithUpdatedSequenceNumbers[0]);
        return [firstRule].concat(rulesWithoutFirstItem);
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.removeRuleStatement = function (ruleStatement, ruleStatements) {
        var _this = this;
        var newRuleStatements = [];
        _.forEach(ruleStatements, function (statement) {
            if (statement === ruleStatement && _.isEmpty(statement.groups)) {
                return;
            }
            // if not a group
            if (_.isEmpty(statement.groups)) {
                newRuleStatements = newRuleStatements.concat([statement]);
                return;
            }
            // if group
            var newRuleStatementsGroups = _this.removeRuleStatement(ruleStatement, statement.groups);
            if (newRuleStatementsGroups.length === 1) {
                newRuleStatements = newRuleStatements.concat(newRuleStatementsGroups);
                return;
            }
            newRuleStatements = newRuleStatements.concat([
                new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, statement, { groups: newRuleStatementsGroups })),
            ]);
        });
        return newRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.updateSequenceNumbers = function (ruleStatements, parentSequenceNumber) {
        var _this = this;
        var sequence = parentSequenceNumber === 0 ? 0 : 1;
        var newRuleStatements = _.reduce(ruleStatements, function (newRuleStatements, ruleStatement) {
            if (ruleStatement.isGroupItem) {
                var groupsWithNewSequence = _this.updateSequenceNumbers(ruleStatement.groups, sequence);
                var ruleStatementWithNewSequence_1 = new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, ruleStatement, { sequence: sequence, groups: groupsWithNewSequence }));
                sequence++;
                return newRuleStatements.concat([ruleStatementWithNewSequence_1]);
            }
            var ruleStatementWithNewSequence = new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, ruleStatement, { sequence: sequence }));
            sequence++;
            return newRuleStatements.concat([ruleStatementWithNewSequence]);
        }, []);
        return newRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.isItemBelogsToFirstGroup = function (ruleStatements, ruleStatement) {
        if (_.isEmpty(ruleStatements) || _.isNull(ruleStatements)) {
            return false;
        }
        var firstItem = _.first(ruleStatements);
        if (firstItem.isGroupItem) {
            var foundItem = _.find(firstItem.groups, ruleStatement);
            if (foundItem) {
                return true;
            }
            return this.isItemBelogsToFirstGroup(firstItem.groups, ruleStatement);
        }
        return _.isEqual(firstItem, ruleStatement);
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.getFlatRuleStatements = function (ruleStatements) {
        var _this = this;
        var flatRuleStatements = _.reduce(ruleStatements, function (flatRuleStatements, statement) {
            if (statement.groups && statement.groups.length > 0) {
                var statementGroups = _this.getFlatRuleStatements(statement.groups);
                flatRuleStatements = flatRuleStatements.concat(statementGroups);
            }
            else {
                flatRuleStatements.push(statement);
            }
            return flatRuleStatements;
        }, []);
        return flatRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.getTiersGroupCount = function (ruleStatements) {
        var _this = this;
        var maxGroupsCount = 0;
        _.forEach(ruleStatements, function (statement) {
            if (statement.groups && statement.groups.length > 0) {
                var count = _this.getTiersGroupCount(statement.groups);
                maxGroupsCount = Math.max(maxGroupsCount, count + 1);
            }
        });
        return maxGroupsCount;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.reInitializeItemSelections = function (ruleStatements) {
        var _this = this;
        var isAllItemsSelected = true;
        _.forEach(ruleStatements, function (ruleStatement) {
            if (!_.isEmpty(ruleStatement.groups)) {
                var isAllChildrenSelected = _this.reInitializeItemSelections(ruleStatement.groups);
                ruleStatement.itemSelected = isAllChildrenSelected;
            }
            isAllItemsSelected = ruleStatement.itemSelected ? isAllItemsSelected : false;
        });
        return isAllItemsSelected;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.getTotalGroupsSelectedItemsCount = function (ruleStatements) {
        var _this = this;
        if (ruleStatements) {
            var selectedRuleStatements = this.getTierSelectedRuleStatements(ruleStatements);
            var selectedItemsCount = selectedRuleStatements.length;
            var childrenSelectedItemsCount = _.reduce(ruleStatements, function (totalCount, ruleStatement) {
                if (!_.isEmpty(ruleStatement.groups) && !ruleStatement.itemSelected) {
                    var selectedItemsCount_1 = _this.getTotalGroupsSelectedItemsCount(ruleStatement.groups);
                    totalCount += selectedItemsCount_1;
                }
                return totalCount;
            }, 0);
            return selectedItemsCount + childrenSelectedItemsCount;
        }
        else {
            return 0;
        }
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.getTotalPlainSelectedItemsCount = function (ruleStatements) {
        var _this = this;
        if (ruleStatements) {
            var selectedRuleStatements = _.filter(ruleStatements, function (statement) { return statement.itemSelected && _.isEmpty(statement.groups); });
            var selectedItemsCount = selectedRuleStatements.length;
            var childrenSelectedItemsCount = _.reduce(ruleStatements, function (totalCount, ruleStatement) {
                if (!_.isEmpty(ruleStatement.groups)) {
                    var selectedItemsCount_2 = _this.getTotalPlainSelectedItemsCount(ruleStatement.groups);
                    totalCount += selectedItemsCount_2;
                }
                return totalCount;
            }, 0);
            return selectedItemsCount + childrenSelectedItemsCount;
        }
        else {
            return 0;
        }
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.getTotalExternalSelectedGroupsWithItemsOnlyCount = function (ruleStatements) {
        var _this = this;
        if (ruleStatements) {
            var selectedGroupsRuleStatements = _.filter(ruleStatements, function (statement) { return statement.itemSelected && !_.isEmpty(statement.groups); });
            var selectedItemsCount = selectedGroupsRuleStatements.length;
            if (selectedItemsCount > 0) {
                return selectedItemsCount;
            }
            var childrenSelectedItemsCount = _.reduce(ruleStatements, function (totalCount, ruleStatement) {
                if (!_.isEmpty(ruleStatement.groups)) {
                    var selectedGroupsItemsCount = _this.getTotalExternalSelectedGroupsWithItemsOnlyCount(ruleStatement.groups);
                    totalCount += selectedGroupsItemsCount;
                }
                return totalCount;
            }, 0);
            return selectedItemsCount + childrenSelectedItemsCount;
        }
        else {
            return 0;
        }
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.getTotalItemsInSelectedGroupsOnlyCount = function (ruleStatements) {
        var _this = this;
        if (ruleStatements) {
            var childrenSelectedItemsCount = _.reduce(ruleStatements, function (totalCount, ruleStatement) {
                if (!_.isEmpty(ruleStatement.groups)) {
                    var selectedGroupsRuleStatements = _.filter(ruleStatement.groups, function (statement) { return statement.itemSelected && _.isEmpty(statement.groups) && ruleStatement.itemSelected; });
                    var selectedItemsCount = selectedGroupsRuleStatements.length;
                    var selectedGroupsItemsCount = selectedItemsCount + _this.getTotalItemsInSelectedGroupsOnlyCount(ruleStatement.groups);
                    totalCount += selectedGroupsItemsCount;
                }
                return totalCount;
            }, 0);
            return childrenSelectedItemsCount;
        }
        else {
            return 0;
        }
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.getTierSelectedRuleStatements = function (ruleStatements) {
        var selectedRuleStatements = _.filter(ruleStatements, function (statement) { return statement.itemSelected; });
        return selectedRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.tryToUnionRuleStatementsIntoGroup = function (ruleStatements) {
        var totalSelectedItemsCount = this.getTotalGroupsSelectedItemsCount(ruleStatements);
        if (totalSelectedItemsCount === 0) {
            return ruleStatements;
        }
        var newRuleStatements = this.tryToUnionRuleStatementsIntoGroupWithTotal(totalSelectedItemsCount, ruleStatements);
        return newRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.deselectRuleStatements = function (ruleStatements) {
        var _this = this;
        _.forEach(ruleStatements, function (ruleStatement) {
            if (!_.isEmpty(ruleStatement.groups)) {
                _this.deselectRuleStatements(ruleStatement.groups);
            }
            ruleStatement.itemSelected = false;
        });
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.ungroupRuleStatement = function (ruleStatements, ungroupItem) {
        var _this = this;
        var newRuleStatements = _.reduce(ruleStatements, function (newRuleStatements, statement) {
            if (statement === ungroupItem) {
                return newRuleStatements.concat(statement.groups);
            }
            if (!statement.isGroupItem) {
                return newRuleStatements.concat([statement]);
            }
            var childRuleStatement = _this.ungroupRuleStatement(statement.groups, ungroupItem);
            if (_.isEqual(childRuleStatement, statement.groups)) {
                return newRuleStatements.concat([statement]);
            }
            var newGroup = new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, statement, { groups: childRuleStatement }));
            return newRuleStatements.concat([newGroup]);
        }, []);
        return newRuleStatements;
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.tryToUnionRuleStatementsIntoGroupWithTotal = function (totalSelectedItemsCount, ruleStatements) {
        var _this = this;
        var selectedRuleStatements = this.getTierSelectedRuleStatements(ruleStatements);
        if (selectedRuleStatements.length === 0) {
            var newRuleStatements = _.reduce(ruleStatements, function (newRuleStatements, ruleStatement) {
                // if not a group
                if (!ruleStatement.isGroupItem) {
                    return newRuleStatements.concat([ruleStatement]);
                }
                // if a group
                var newRuleStatement;
                var newGroupRuleStatement = _this.tryToUnionRuleStatementsIntoGroupWithTotal(totalSelectedItemsCount, ruleStatement.groups);
                if (_.isEqual(newGroupRuleStatement, ruleStatement.groups)) {
                    newRuleStatement = ruleStatement;
                }
                else {
                    newRuleStatement = new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, ruleStatement, { groups: newGroupRuleStatement }));
                }
                return newRuleStatements.concat([newRuleStatement]);
            }, []);
            return newRuleStatements;
        }
        if (selectedRuleStatements.length > 0) {
            if (selectedRuleStatements.length !== totalSelectedItemsCount) {
                throw new Error('Groups can not intersect each other.');
            }
            var firstSelectedRuleStatements_1 = _.head(selectedRuleStatements);
            var sequence_1 = 0;
            var newRuleStatements = _.reduce(ruleStatements, function (newRuleStatements, ruleStatement) {
                if (firstSelectedRuleStatements_1 === ruleStatement) {
                    var newGroup = new BenefitEligibilityRuleStatementGroupItem({
                        sequence: sequence_1,
                        groups: selectedRuleStatements,
                        itemSelected: true,
                        conditionType: firstSelectedRuleStatements_1.conditionType,
                    });
                    sequence_1++;
                    return newRuleStatements.concat([newGroup]);
                }
                if (!ruleStatement.itemSelected) {
                    var ruleStatementWithNewSequence = new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, ruleStatement, { sequence: sequence_1 }));
                    sequence_1++;
                    return newRuleStatements.concat([ruleStatementWithNewSequence]);
                }
                // skip selected
                return newRuleStatements;
            }, []);
            return newRuleStatements;
        }
    };
    BenefitEligibilityRuleStatementsManagementService.prototype.setConditionTypeNullToFirstItem = function (ruleStatement) {
        if (ruleStatement.sequence !== 0) {
            return ruleStatement;
        }
        var firstGroupItem;
        var groups;
        if (ruleStatement.isGroupItem) {
            var groupsWithoutFirstItem = _.without(ruleStatement.groups, ruleStatement.groups[0]);
            firstGroupItem = this.setConditionTypeNullToFirstItem(ruleStatement.groups[0]);
            groups = [firstGroupItem].concat(groupsWithoutFirstItem);
        }
        var newRuleStatement = new BenefitEligibilityRuleStatementGroupItem(tslib_1.__assign({}, ruleStatement, { conditionType: null, groups: groups }));
        return newRuleStatement;
    };
    BenefitEligibilityRuleStatementsManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BenefitEligibilityRuleStatementsManagementService_Factory() { return new BenefitEligibilityRuleStatementsManagementService(); }, token: BenefitEligibilityRuleStatementsManagementService, providedIn: "root" });
    return BenefitEligibilityRuleStatementsManagementService;
}());
export { BenefitEligibilityRuleStatementsManagementService };
