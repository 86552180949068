import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { appConfig } from '../../../app.config';
import { Router, ActivatedRoute } from '@angular/router';
import { LeaveRequestEntry } from '../../models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { LeaveRequestApiService } from '../../services/index';
import { unsubscribe } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { IndividualScheduleNavigationService } from './../../../common/services/index';
import { ScheduleCycleHelperService } from '../../../organization/services/index';
import { ShiftReplacementRequest, ShiftReplaceCmd } from '../../../scheduler/models/index';
import { ShiftReplacementReplaceComponent } from '../shift-replacement/index';
var LeaveRequestDetailsComponent = /** @class */ (function () {
    function LeaveRequestDetailsComponent(leaveRequestApiService, scheduleCycleHelperService, modalService, router, route, changeDetector) {
        this.appConfig = appConfig;
        this.leaveRequestApiService = leaveRequestApiService;
        this.scheduleCycleHelperService = scheduleCycleHelperService;
        this.changeDetector = changeDetector;
        this.modalService = modalService;
        this.router = router;
        this.route = route;
        this.navService = new IndividualScheduleNavigationService(this.router, this.route);
        this.detailsUpdated = new EventEmitter();
        this.changedEditState = new EventEmitter();
        this.pageSize = 5;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.isLoading = true;
        this.isEdit = false;
        this.currentIsEditing = false;
    }
    Object.defineProperty(LeaveRequestDetailsComponent.prototype, "editMode", {
        set: function (value) {
            if (!this.currentIsEditing) {
                this.isEdit = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    LeaveRequestDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridSubscription = this.gridState.onRefreshGrid.subscribe(function () {
            _this.refreshGrid();
            _this.changeDetector.detectChanges();
        });
        if (_.get(this.orgLevel, 'id') && _.get(this.leaveRequest, 'id')) {
            this.loadData();
        }
    };
    LeaveRequestDetailsComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    LeaveRequestDetailsComponent.prototype.onSaveClick = function (requestDetails) {
        var _this = this;
        this.isLoading = true;
        this.leaveRequestApiService.updateDetailsRecord(this.orgLevel.id, this.leaveRequest.id, requestDetails)
            .then(function (value) {
            _this.isLoading = false;
            _this.updateEditState(requestDetails, false);
            _this.changeDetector.detectChanges();
            _this.detailsUpdated.emit();
        });
    };
    LeaveRequestDetailsComponent.prototype.onCancelClick = function (requestDetails) {
        var leaveRequestDetails = _.find(this.origLeaveRequestDetails, { localId: requestDetails.localId });
        if (leaveRequestDetails) {
            _.assign(requestDetails, leaveRequestDetails);
            this.updateEditState(requestDetails, false);
            this.changeDetector.detectChanges();
        }
    };
    LeaveRequestDetailsComponent.prototype.onModelChanged = function (requestDetails) {
        var leaveRequestDetails = _.find(this.origLeaveRequestDetails, { localId: requestDetails.localId });
        if (leaveRequestDetails && requestDetails.absenceReason.code !== leaveRequestDetails.absenceReason.code) {
            this.updateEditState(requestDetails, true);
            this.changeDetector.detectChanges();
        }
    };
    LeaveRequestDetailsComponent.prototype.onFindReplacement = function (requestDetails) {
        var _this = this;
        var request = new ShiftReplacementRequest();
        request.replacedEmployeeName = this.leaveRequest.employee.name;
        request.replacedEmployeeId = this.leaveRequest.employee.id;
        request.shiftId = requestDetails.shift.id;
        request.shiftName = requestDetails.shift.name;
        request.positionId = this.leaveRequest.position.id;
        request.positionName = this.leaveRequest.position.name;
        request.date = requestDetails.date;
        request.shiftStart = moment(requestDetails.shift.start).toDate();
        request.shiftEnd = moment(requestDetails.shift.end).toDate();
        request.showDayOffEmployees = true;
        request.orgLevelId = this.orgLevel.id;
        request.scheduleAbsence = requestDetails.absenceReason;
        request.organizationName = this.leaveRequest.organization.name;
        request.unitId = requestDetails.unit.id;
        request.unitName = requestDetails.unit.name;
        request.showSendSmsButton = true;
        ShiftReplacementReplaceComponent.openDialog(request, this.modalService, function (result, cmd) {
            if (result && cmd) {
                requestDetails.absenceReason = cmd.scheduleAbsence;
                requestDetails.replacement = cmd.selectedEmployee.employee.name;
                requestDetails.replacedEmployee = cmd.selectedEmployee.employee;
                _this.updateEditState(requestDetails, true);
                _this.changeDetector.detectChanges();
            }
        });
    };
    LeaveRequestDetailsComponent.prototype.onShowSchedule = function (requestDetails) {
        var _this = this;
        this.isLoading = true;
        this.scheduleCycleHelperService.getScheduleCycleByDate(requestDetails.date, this.leaveRequest.department.orgLevelId)
            .then(function (_a) {
            var startDate = _a.startDate, endDate = _a.endDate;
            _this.isLoading = false;
            _this.navService.NavigateToIndividualScheduleEmp(_this.leaveRequest.employee.id, startDate.toDate(), endDate.toDate(), requestDetails.date);
        });
    };
    LeaveRequestDetailsComponent.prototype.updateEditState = function (reqDetails, state) {
        this.isEdit = this.currentIsEditing = reqDetails.hasEdited = state;
        this.changedEditState.emit(this.isEdit);
    };
    LeaveRequestDetailsComponent.prototype.loadData = function () {
        var _this = this;
        this.isLoading = true;
        this.leaveRequestApiService
            .getLeaveRequestDetails(this.orgLevel.id, this.leaveRequest.id)
            .then(function (leaveRequest) {
            _this.leaveRequestDetails = leaveRequest.details;
            _this.canReplaceEmployee = leaveRequest.canReplaceEmployee;
            _this.origLeaveRequestDetails = _.cloneDeep(_this.leaveRequestDetails);
            _this.refreshGrid();
            _this.isLoading = false;
            _this.changeDetector.detectChanges();
        });
    };
    LeaveRequestDetailsComponent.prototype.refreshGrid = function () {
        if (!this.leaveRequestDetails) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.leaveRequestDetails, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LeaveRequestDetailsComponent.prototype, "gridSubscription", void 0);
    return LeaveRequestDetailsComponent;
}());
export { LeaveRequestDetailsComponent };
