import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ScheduleCycle } from '../../models/lookup/schedule-cycle';
import { appConfig, IApplicationConfig } from '../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-cycle-slider',
  templateUrl: 'schedule-cycle-slider.component.html',
  styleUrls: ['schedule-cycle-slider.component.scss']
})
export class ScheduleCycleSliderComponent implements OnChanges, OnInit {

  @Input('itemsArray') public itemsArray: ScheduleCycle[];

  @Input('currentIndex')
  public set currentIndex(value: number) {

    if (!this.itemsArray) {
      this.internalCurrentIndex = -1;
      this.internalCurrentItem = null;
      return;
    }

    if (value === this.internalCurrentIndex) return;

    let clamped: number = value;
    if (value < -1) clamped = -1;
    if (value > this.itemsArray.length - 1) clamped = this.itemsArray.length - 1;

    this.internalCurrentIndex = clamped;

    if (this.internalCurrentIndex > -1) {
      this.internalCurrentItem = this.itemsArray[this.internalCurrentIndex];
    } else {
      this.internalCurrentItem = null;
    }

    this.itemSelected.emit(this.internalCurrentItem);
  }

  @Input('currentItem')
  public set currentItem(value: ScheduleCycle) {

    if (this.internalCurrentItem === value) return;

    this.internalCurrentItem = value;

    if (this.internalCurrentItem) {
      this.internalCurrentIndex = _.findIndex(this.itemsArray, (item: ScheduleCycle) => {
        return item === this.internalCurrentItem;
      });
    } else {
      this.internalCurrentIndex = -1;
    }

  }
  @Output() public itemSelected: EventEmitter<any> = new EventEmitter<any>();

  public appConfig: IApplicationConfig;
  public enableDropdown: boolean = true;

  private internalCurrentIndex: number = -1;
  private internalLabelFunction: Function;
  private internalCurrentItem: ScheduleCycle;

  public get currentItem(): ScheduleCycle {
    return this.internalCurrentItem;
  }

  public get currentIndex(): number {
    return this.internalCurrentIndex;
  }

  public next(): void {
    this.currentIndex++;
  }

  public prev(): void {
    this.currentIndex--;
  }

  public onItemSelected(item: ScheduleCycle): void {
    this.currentItem = item;
    this.itemSelected.emit(this.internalCurrentItem);
  }

  public get nextEnabled(): boolean {
    if (!this.itemsArray) return false;
    return this.internalCurrentIndex < this.itemsArray.length - 1;
  }

  public get prevEnabled(): boolean {
    if (!this.itemsArray) return false;
    return this.internalCurrentIndex > 0;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnChanges(changes: SimpleChanges): void {

    let simpleChange: SimpleChange = changes['itemsArray'];
    if (simpleChange) {

      if (!this.itemsArray && !simpleChange.currentValue) {
        this.internalCurrentIndex = -1;
      } else if (!this.itemsArray && simpleChange.currentValue) {
        this.internalCurrentIndex = 0;
      } else if (this.itemsArray && simpleChange.currentValue && (simpleChange.currentValue !== this.itemsArray)) {
        this.internalCurrentIndex = 0;
      }
    }
  }

}
