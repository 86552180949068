import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { appConfig } from './../../../../app.config';
import { Redirect2PageService } from '../redirect-to-page/redirect-to-page.service';
import { SessionService } from '../../../../core/services/session/session.service';
import { EmployeeDefinitionsApiService } from '../../employee/employee-definitions-api.service';
import { BenAdminMenuItemState } from './ben-admin-menu-item-state';
var BenAdminMenuManagementService = /** @class */ (function () {
    function BenAdminMenuManagementService(redirectService, sessionService, employeeDefinitionService) {
        this.redirectService = redirectService;
        this.sessionService = sessionService;
        this.employeeDefinitionService = employeeDefinitionService;
        this.appConfig = appConfig;
    }
    BenAdminMenuManagementService.prototype.initialize = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.empInfo = null;
                        user = this.sessionService.getUser();
                        if (!(user && user.linkedEmpId)) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.employeeDefinitionService.getEmployeeShortInfo(user.linkedEmpId)];
                    case 1:
                        _a.empInfo = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BenAdminMenuManagementService.prototype.isBenAdminItem = function (name) {
        return name === appConfig.extraApplicationsInfo.benAdmin.name;
    };
    BenAdminMenuManagementService.prototype.openBswift = function (orgLevelId) {
        if (orgLevelId === void 0) { orgLevelId = 0; }
        var jwt = this.sessionService.getToken();
        this.redirectService.RedirectByGet(this.appConfig.api.url + "/" + this.appConfig.api.version + "/bswift?token=" + jwt, '_blank');
    };
    BenAdminMenuManagementService.prototype.getBenAdminMenuItemState = function () {
        var benAdminMenuItemState = new BenAdminMenuItemState();
        benAdminMenuItemState.IsEmployeeMappedToUser = !!this.empInfo;
        benAdminMenuItemState.EmployeeRecordHasSSN = !!this.empInfo ? !(this.empInfo.ssn === '' || _.isNil(this.empInfo.ssn)) : null;
        benAdminMenuItemState.EmployeeRecordHasDateOfBirth = !!this.empInfo ? !(_.isNil(this.empInfo.dateOfBirth) || !_.isDate(this.empInfo.dateOfBirth)) : null;
        return benAdminMenuItemState;
    };
    BenAdminMenuManagementService.prototype.getAccessDeniedMessage = function () {
        return this.appConfig.extraApplicationsInfo.benAdmin.accessDeniedMessage;
    };
    return BenAdminMenuManagementService;
}());
export { BenAdminMenuManagementService };
