import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../common/services/index';
import { AccrualsBalancesManagementService } from '../../services/accruals-balances-management.service';
import { AppSettingsManageService } from './../../../../app-settings/services/index';
var AccrualsBalancesGridComponent = /** @class */ (function () {
    function AccrualsBalancesGridComponent(accrualManagementService, devDetector, appSettingsManageService, router, route) {
        this.accrualManagementService = accrualManagementService;
        this.devDetector = devDetector;
        this.appSettingsManageService = appSettingsManageService;
        this.router = router;
        this.route = route;
        this.pageSize = 50;
        this.hideRates = true;
        this.xlsxName = 'Accruals_Balances_';
        this.pdfName = 'Accruals_Balances_';
        this.columnsGroupName = 'AccrualsBalances';
        this.isLocked = true;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'employee.fullName', dir: 'asc' }
        ];
        this.appConfig = appConfig;
    }
    Object.defineProperty(AccrualsBalancesGridComponent.prototype, "isLockedColumn", {
        get: function () {
            return this.devDetector.isDesktop && this.columnState && (this.columnState.columns.filter(function (record) { return record.displayed === true; }).length == 0 ? false : true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccrualsBalancesGridComponent.prototype, "isReordable", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    AccrualsBalancesGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        var date = new Date().toLocaleDateString();
        this.xlsxName += date + ".xlsx";
        this.pdfName += date + ".pdf";
        this.getSettings();
        this.subscriptions.state = this.accrualManagementService.subscribeToState(function (state) {
            _this.state = state;
            _this.gridState.state.skip = 0;
            _this.refreshGrid();
        });
        this.subscriptions.columnState = this.accrualManagementService.subscribeToColumnState(function (columnState) {
            _this.columnState = columnState;
        });
        this.subscriptions.onLoaded = this.accrualManagementService
            .subscribeToLoadedRecords(function (container) {
            _this.container = container;
            _this.assignRecords(container.records);
            _this.hideRates = container.hideRates;
        });
        this.subscriptions.export = this.accrualManagementService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    AccrualsBalancesGridComponent.prototype.ngOnDestroy = function () { };
    AccrualsBalancesGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.getFilteredRecords(), { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    AccrualsBalancesGridComponent.prototype.onEmpNameClick = function (empId) {
        var navService = new EmployeeSectionNavigationService(this.router, this.route);
        var urlTree = navService.getUrlTreeFromRoot(empId, true);
        var extras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(urlTree, extras);
    };
    AccrualsBalancesGridComponent.prototype.getFieldPath = function (name, property) {
        return "accrualTypesMap." + name + "." + property;
    };
    AccrualsBalancesGridComponent.prototype.isVisible = function (field) {
        return this.columnState && this.columnState.columnsMap[field] ? this.columnState.columnsMap[field].displayed : false;
    };
    AccrualsBalancesGridComponent.prototype.onExcelExport = function (e) {
        var sheets = _.head(_.get(e, 'workbook.sheets'));
        _.forEach(sheets.rows, function (row) {
            if (row.type === 'data') {
                _.forEach(row.cells, function (cell) {
                    if (_.isBoolean(cell.value)) {
                        cell.value = cell.value ? 'Yes' : 'No';
                    }
                });
            }
        });
    };
    AccrualsBalancesGridComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.refreshGrid();
    };
    AccrualsBalancesGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    AccrualsBalancesGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.getFilteredRecords(), this.gridState.state);
    };
    AccrualsBalancesGridComponent.prototype.getFilteredRecords = function () {
        var filteredRecords = this.records;
        if (this.state.isEmployeeActive === true) {
            filteredRecords = filteredRecords.filter(function (record) { return record.employee.status === 'Active'; });
        }
        if (this.state.isShowNegativeBalance === true) {
            filteredRecords = filteredRecords.filter(function (record) {
                return _.some(record.accrualTypesMap, function (accrualType) { return accrualType.hourBalance < 0 || accrualType.dollarBalance < 0; });
            });
        }
        return filteredRecords;
    };
    AccrualsBalancesGridComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.EnableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
                        if (this.EnableRate4DecimalPlaces) {
                            this.cells = {
                                format: '0.0000'
                            };
                        }
                        else {
                            this.cells = {
                                format: '0.00'
                            };
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AccrualsBalancesGridComponent.prototype, "isShowHighPrecision", {
        get: function () {
            return this.EnableRate4DecimalPlaces;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsBalancesGridComponent.prototype, "subscriptions", void 0);
    return AccrualsBalancesGridComponent;
}());
export { AccrualsBalancesGridComponent };
