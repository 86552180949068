  <!-- #bugInIE11WithEmployeeRecord (ngIf) -->
  <form novalidate #form="ngForm" *ngIf="employeeSectionsProfile">
    <div class="col-xs-12">
      <div class="emp-title-box col-md-10 col-sm-12">
        <div class="row">
          <span class="emp-title">{{employeeFullName}}</span>
          <span *ngIf="employeeSectionsProfile?.suffix.fieldValue" class="emp-title">,</span>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-3 col-lg-2">
       <slx-employee-editable-photo [employeeId]="employeeId" [canEdit]="employeeSectionsProfile?.actions.canEdit"></slx-employee-editable-photo>
    </div>
    <div *ngIf="state.isEditMode" class="col-xs-12 col-md-9 col-lg-10">
      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" required="true" filled maxlength="50" [readonly]="!state.isEditMode" name="lastName" placeholder="Last Name"
            [inputPolicy]="employeeSectionsProfile?.lastName.Policy" [(ngModel)]="employeeSectionsProfile?.lastName.fieldValue" slxTrim="both">
            <span errorMessage for="required"></span>
            <span errorMessage for="filled"></span>
        </slx-input-decorator>
      </div>
      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" required="true" filled maxlength="50" [readonly]="!state.isEditMode" name="firstName" placeholder="First Name"
            [inputPolicy]="employeeSectionsProfile?.firstName.Policy" [(ngModel)]="employeeSectionsProfile?.firstName.fieldValue" slxTrim="both">
            <span errorMessage for="required"></span>
            <span errorMessage for="filled"></span>
        </slx-input-decorator>
      </div>
      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" maxlength="50" [readonly]="!state.isEditMode" name="middleName" placeholder="Middle Name" [inputPolicy]="employeeSectionsProfile?.middleName.Policy"
            [(ngModel)]="employeeSectionsProfile?.middleName.fieldValue" slxTrim="both">
            <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-sm-6 col-lg-3">
        <slx-input-decorator>
          <input slx-input type="text" maxlength="10" [readonly]="!state.isEditMode" name="suffix" placeholder="Suffix" [inputPolicy]="employeeSectionsProfile?.suffix.Policy"
            [(ngModel)]="employeeSectionsProfile?.suffix.fieldValue" slxTrim="both" slxMaxLength="10">
            <span errorMessage for="required"></span>
            <span errorMessage for="slxMaxLength">Suffix should be a maximum length of '10'</span>
        </slx-input-decorator>
      </div>
    </div>
    <div class="col-xs-12 col-md-9 col-lg-10">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-6 col-lg-3" *ngIf="!employeeSectionsProfile?.birthDate.securityAttribute.masked">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                name="birthDate"
                placeholder="Birth Date"
                [(ngModel)]="employeeSectionsProfile?.birthDate.fieldValue"
                [inputPolicy]="employeeSectionsProfile?.birthDate.Policy"
                [required]="true"
                [acceptNullDate]="false"
                [readonly]="!state.isEditMode"
                [maxDate]="maxBirthDate"
              ></slx-date-picker-ngx>
              <span errorMessage for="required"></span>
              <span errorMessage for="date"></span>
              <span errorMessage for="maxDate">Hire date can't be within 14 years of birth date</span>
            </slx-input-decorator>
          </div>
          <!--TODO implement mask function in input-policy-->
          <div class="col-sm-6 col-lg-3" *ngIf="employeeSectionsProfile?.birthDate.securityAttribute.masked">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                name="birthDateMasked"
                placeholder="Birth Date"
                [(ngModel)]="employeeSectionsProfile?.birthDate.fieldValue"
                [inputPolicy]="employeeSectionsProfile?.birthDate.Policy"
                [required]="true"
                [readonly]="true"
              ></slx-date-picker-ngx>
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-dropdown-input slx-input required="true" [lookup]="{lookupType: 'empGender', employeeId: employeeId}" [readonly]="!state.isEditMode"
                name="gender" placeholder="Gender" valueField="name" titleField="description" [inputPolicy]="employeeSectionsProfile?.gender.Policy" [(ngModel)]="employeeSectionsProfile?.gender.fieldValue">
              </slx-dropdown-input>            
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-dropdown-input slx-input required="true" [lookup]="{lookupType: 'empRace', employeeId: employeeId}" [readonly]="!state.isEditMode"
                name="race" placeholder="Race" [inputPolicy]="employeeSectionsProfile?.race.Policy" [(ngModel)]="employeeSectionsProfile?.race.fieldValue">
              </slx-dropdown-input>
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-dropdown-input slx-input required="true" [lookup]="{lookupType: 'maritalStatus', employeeId: employeeId}" [readonly]="!state.isEditMode"
                name="maritalStatus" placeholder="Marital status" [inputPolicy]="employeeSectionsProfile?.maritalStatus.Policy" [(ngModel)]="employeeSectionsProfile?.maritalStatus.fieldValue">
              </slx-dropdown-input>
            </slx-input-decorator>
          </div>
  
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-dropdown-input slx-input
                name="veteranStatus"
                [lookup]="{lookupType: 'veteranStatus', employeeId: employeeId}"
                placeholder="Veteran Status"
                [(ngModel)]="employeeSectionsProfile?.veteranStatus.fieldValue"
                [readonly]="!state.isEditMode"
                [inputPolicy]="employeeSectionsProfile?.veteranStatus.Policy"
                emptyOption="-"
                >
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>
  
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-dropdown-input slx-input
                [lookup]="disabilityStatusesLookup"
                [readonly]="!state.isEditMode"
                [inputPolicy]="employeeSectionsProfile?.disabilityStatus.Policy"
                [(ngModel)]="employeeSectionsProfile?.disabilityStatus.fieldValue"
                emptyOption="-"
                name="disabilityStatus"
                placeholder="Disability Status"
              >
              </slx-dropdown-input>
            </slx-input-decorator>
          </div>
  
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <input slx-input type="text" [readonly]="true" name="organization" placeholder="Organization" [(ngModel)]="employeeSectionsProfile?.organization.fieldValue">
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <input slx-input type="text" [readonly]="true" name="department" placeholder="Department" [(ngModel)]="employeeSectionsProfile?.department.fieldValue">
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <input slx-input type="text" [readonly]="true" name="position" placeholder="Position" [inputPolicy]="employeeSectionsProfile?.position.Policy" [(ngModel)]="employeeSectionsProfile?.position.fieldValue">
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-dropdown-input slx-input readonly="true" lookup="empType" name="employeeType" placeholder="Type" [(ngModel)]="employeeSectionsProfile?.employeeType.fieldValue">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                [(ngModel)]="employeeSectionsProfile?.hireDate.fieldValue"
                [readonly]="true"
                placeholder="Hire Date"
                name="hireDate"
              ></slx-date-picker-ngx>
              <span errorMessage for="required"></span>
              <span errorMessage for="date"></span>
            </slx-input-decorator>
          </div>
          <div *ngIf="employeeSectionsProfile?.terminationDate" class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                [ngModel]="employeeSectionsProfile.terminationDate"
                [readonly]="true"
                placeholder="{{statusDateLabel}}"
                name="terminationDate"
              ></slx-date-picker-ngx>
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-phone-input slx-input phone type="text" readonly="true" name="phone" placeholder="Phone" [(ngModel)]="employeeSectionsProfile?.phone.fieldValue">
              </slx-phone-input>
              <span errorMessage for="phone"></span>
            </slx-input-decorator>
          </div>
          <div class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-phone-input slx-input phone type="text" readonly="true" name="mobilePhone" placeholder="Mobile Phone" [inputPolicy]="employeeSectionsProfile?.mobilePhone.Policy"
                [(ngModel)]="employeeSectionsProfile?.mobilePhone.fieldValue">
                </slx-phone-input>
                <span errorMessage for="phone"></span>
            </slx-input-decorator>
          </div>
           <div class="col-sm-6 col-lg-3" *ngIf="employeeSectionsProfile?.status">
            <slx-input-decorator >
              <input slx-input type="text" [readonly]="true" name="status" placeholder="Status" [ngModel]="employeeSectionsProfile?.status">
            </slx-input-decorator>
          </div>
          <div *ngIf="employeeSectionsProfile?.rehireDate" class="col-sm-6 col-lg-3">
            <slx-input-decorator>
              <slx-date-picker-ngx slx-input
                [ngModel]="employeeSectionsProfile?.rehireDate"
                [readonly]="true"
                placeholder="Rehire Date"
                name="rehireDate"
              ></slx-date-picker-ngx>
            </slx-input-decorator>
          </div>
        </div>
      </div>
    </div>
  </form>
  