import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ManageACAPeriodsContainer } from '../../models/aca-config/manage-aca-container';
import { Assert } from '../../../framework/index';
import { ManageACAPeriods, IManageACAPeriodsDTO, IEmployer, Employer } from '../../models/aca-config/manage-aca-periods.model';
import { FieldsMeta } from '../../../core/models/index';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import { MetaMapService } from '../../../core/services/index';

@Injectable()
export class ACAConfigurationMapService {

    constructor(private metaMapService: MetaMapService
    ) { }

    public mapManageACAPeriodsRecords(dtos: any, metadata: FieldsMeta): ManageACAPeriodsContainer {
        Assert.isNotNull(dtos);
        let manageACAContainer: ManageACAPeriodsContainer = new ManageACAPeriodsContainer();
        let arr: ManageACAPeriods[] = [];
        arr.push(this.mapManageACAPeriods(dtos.records));
        manageACAContainer.records = arr;
        manageACAContainer.actions = this.metaMapService.mapActions(metadata);
        return manageACAContainer;
    }

    public mapManageACAPeriods(dto: IManageACAPeriodsDTO): ManageACAPeriods {
        let model: ManageACAPeriods = new ManageACAPeriods();
        model.id = dto.id;
        model.newHireFirstOfTheMonth = dto.newHireFirstOfTheMonth;
        model.newHireAllowOverlap = dto.newHireAllowOverlap;
        model.newHireMeasurementPeriod = dto.newHireMeasurementPeriod;
        model.newHireAdministrativePeriod = dto.newHireAdministrativePeriod;
        model.newHireStabilityPeriod = dto.newHireStabilityPeriod;
        model.standardConfigFirstDayOfMeasurement = dateTimeUtils.convertFromDtoDateString(dto.standardConfigFirstDayOfMeasurement);
        model.standardConfigMeasurementPeriod = dto.standardConfigMeasurementPeriod;
        model.standardConfigAdministrativePeriod = dto.standardConfigAdministrativePeriod;
        model.standardConfigStabilityPeriod = dto.standardConfigStabilityPeriod;
        return model;
    }

    public mapManageEmp(dto: IEmployer): Employer {
        let model: Employer = new Employer();
        model.id = dto.id;
        model.name = dto.name;
        return model;
    }

    public mapToManageACAPeriodsRecordsDto(dto: ManageACAPeriods): any {
        return {
            id: dto.id,
            newHireFirstOfTheMonth: dto.newHireFirstOfTheMonth,
            newHireAllowOverlap: dto.newHireAllowOverlap,
            newHireMeasurementPeriod: dto.newHireMeasurementPeriod,
            newHireAdministrativePeriod: dto.newHireAdministrativePeriod,
            newHireStabilityPeriod: dto.newHireStabilityPeriod,
            standardConfigFirstDayOfMeasurement: dateTimeUtils.convertToDtoString(dto.standardConfigFirstDayOfMeasurement),
            standardConfigMeasurementPeriod: dto.standardConfigMeasurementPeriod,
            standardConfigAdministrativePeriod: dto.standardConfigAdministrativePeriod,
            standardConfigStabilityPeriod: dto.standardConfigStabilityPeriod
        }
    }
}