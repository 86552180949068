<div class="main-info">
    <div class="line">
        <div class="marker color-bad" [ngClass]="{'color-good': statusIsGood}"></div>
        <span class="info-item info-item-label" [ngClass]="{'is-bold': !statusIsGood}">Comm Status:</span>
        <span class="info-item info-value color-bad" 
        [ngClass]="{'is-bold': !statusIsGood, 'color-good': statusIsGood}"
        >{{commStatus}}</span>
    </div>
    <div class="line">
        <div class="marker color-bad" [ngClass]="{'color-good': errorsIsGood}"></div>
        <span class="info-item info-item-label">FP Errors:</span>
        <span class="info-item info-value color-bad" 
        [ngClass]="{'is-bold': !statusIsGood, 'color-good': errorsIsGood}"
        >{{timeclockStatus?.dailyFPErrorsPercent}}%</span>
    </div>
    <div class="line">
        <div class="marker color-bad" [ngClass]="{'color-good': restrictionIsGood}"></div>
        <span class="info-item info-item-label">Sched Restriction:</span>
        <span class="info-item info-value color-bad"
         [ngClass]="{'is-bold': !statusIsGood, 'color-good': restrictionIsGood}"
         >{{timeclockStatus?.dailyRestrictionsPercent}}%</span>
    </div>
</div>