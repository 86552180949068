import {
  IOrganization,
  Organization,
  IEmployeeShortInfo,
  EmployeeShortInfo,
  IPosition,
  Position,
  Department,
  IDepartment
} from '../../../organization/index';

export interface IAccrualsTransaction {
  date: string;
  type: string;
  action: string;
  original: number;
  change: number;
  newValue: number;
  notes: string;
}

export class AccrualsTransaction {
  date: Date;
  type: string;
  action: string;
  original: number;
  change: number;
  newValue: number;
  notes: string;
}

export interface IAccrualsTransactionRecord {
  employee: IEmployeeShortInfo;
  department: IDepartment;
  position: IPosition;
  organization: IOrganization;
  accrualPolicy: IAccrualPolicyinfo;
  transaction: IAccrualsTransaction;
}

export class AccrualsTransactionRecord {
  public employee = new EmployeeShortInfo();
  public department = new Department();
  public position = new Position();
  public organization = new Organization();
  public accrualPolicy = new AccrualPolicyinfo();
  public transaction = new AccrualsTransaction();
}

export interface IAccrualPolicyinfo {
  id: number;
  policyName: string;
}

export class AccrualPolicyinfo {
  public id: number;
  public policyName: string;
}

export interface IAccrualsTransactionRecords {
  startDate: string;
  endDate: string;
  records: IAccrualsTransactionRecord[];
}

export class AccrualsTransactionRecords {
  
  public startDate: Date;
  public endDate: Date;
  public records: AccrualsTransactionRecord[];
  public calculateAccruals: boolean;
  constructor(
  ) {
    this.records = [];
  }
}
