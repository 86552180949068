import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ACAConfigurationApiService } from './aca-api.service';
import { Assert } from '../../../framework/index';
var ACAConfigurationManagementService = /** @class */ (function () {
    function ACAConfigurationManagementService(api) {
        this.api = api;
        this.exportTo$ = new Subject();
        this.onLoaded$ = new ReplaySubject(1);
        this.onStateChanged$ = new ReplaySubject(1);
    }
    ACAConfigurationManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (_.isNumber(orgLevel.id)) {
                _this.currentOrgLevel = orgLevel;
                _this.orgLevelId = orgLevel.id;
                _this.onStateChanged$.next({ orgLevelChanged: true });
                _this.fetchRecords();
            }
        });
    };
    ACAConfigurationManagementService.prototype.fetchRecords = function () {
        var _this = this;
        this.api.getManageACAPeriodsRecords(this.orgLevelId).
            then(function (result) {
            _this.m_container = result;
            _this.onStateChanged$.next({ isLoading: false });
            _this.onLoaded$.next(_this.m_container);
        }).catch(function () {
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    ACAConfigurationManagementService.prototype.subscribeToOnLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onLoaded$.subscribe(callback);
    };
    ACAConfigurationManagementService.prototype.subscribeToOnStateChanged = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onStateChanged$.subscribe(callback);
    };
    ACAConfigurationManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    ACAConfigurationManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    ACAConfigurationManagementService.prototype.postManageACARecord = function (item) {
        var _this = this;
        this.api.postManageACARecords(item, this.orgLevelId).
            then(function (result) {
            _this.fetchRecords();
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ACAConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ACAConfigurationManagementService.prototype, "orgLevelSubscription", void 0);
    return ACAConfigurationManagementService;
}());
export { ACAConfigurationManagementService };
