<div class="modal-content">
  <div class="modal-body">
    <slx-spinner [show]="state.isLoading">
      <slx-change-termination-date #changeTerminationDate [employee]="employee"></slx-change-termination-date>
    </slx-spinner>
    <ng-container *ngIf="employee && employee?.isNGPUser && employee?.isSystemUser">
      <div class="p-15">This action will not update the employee's Termination Date in CarePay, please manually update the employee's Termination Date in CarePay to make sure the records in both systems are in sync</div>
    </ng-container>
    <div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Cancel</button>
    <button type="button" [disabled]="!changeTerminationDate.isValid" (click)="onOk()" [disabled]="!isemployeeTerminationFormValid" class="theme-button-apply">OK</button>
  </div>
</div>
