import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
export function debounce(miliseconds) {
    if (miliseconds === void 0) { miliseconds = 0; }
    return function decorate(target, propertyKey, descriptor) {
        var originalMethod = descriptor.value;
        var originalContext = target;
        var subject = new Subject();
        descriptor.value = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            originalContext = this;
            subject.next(args);
        };
        subject
            .debounceTime(miliseconds)
            .subscribe(function (args) {
            originalMethod.call.apply(originalMethod, [originalContext].concat(args));
        });
        return descriptor;
    };
}
