import { IEmployeeRotation, EmployeeRotation } from './employee-sections-rotations';
import { ScheduleCycleScope } from '../../../../organization/models/index';

export interface IRotationModifyRequest {
  rotation: IEmployeeRotation;
  reGenerateSchedule: boolean;
  regenerateScope: number;
}

export class RotationModifyRequest {
  public rotation: EmployeeRotation;
  public reGenerateSchedule: boolean;
  public regenerateScope: ScheduleCycleScope;
  public homePositionOrgLevelId: number;
}
