import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { RouteData } from '../../../core/models/index';
import { EmployeeDefinitionsApiService } from '../../../organization/services/index';
import { EmployeeShortInfo, StyledUserApplication } from '../../../organization/models/index';
import { UserProfileApiService } from '../../../configuration/services/index';

import { mutableSelect } from '../../../core/decorators/index';
import { IApplication } from '../../../authentication/store/index';
import { UserApplication } from '../../../state-model/models/index';
import { ApplicationService } from '../application/application.service';
import { UserProfileModel } from '../../../../app/configuration/models';

@Injectable()
export class BreadcrumbValueResolverService {


  private employeeDefinitionsApiService: EmployeeDefinitionsApiService;
  private userProfileApiService: UserProfileApiService;
  private applicationService: ApplicationService;
  private applicationSubscription: Subscription;
  private applications: StyledUserApplication[];
  constructor(employeeDefinitionsApiService: EmployeeDefinitionsApiService, applicationService: ApplicationService, userProfileApiService: UserProfileApiService) {
    this.applicationService = applicationService;
    this.employeeDefinitionsApiService = employeeDefinitionsApiService;
    this.userProfileApiService = userProfileApiService;
  }


  public getApplicationName(url: string, urlEnd: string): Promise<string> {
    let result: Promise<string>;
    return this.getApplications(urlEnd)
      .then((application: StyledUserApplication) => {
        return application ? application.title : urlEnd;
      });
  }

  public getApplicationIsHidden(url: string, urlEnd: string): Promise<boolean> {
    let result: Promise<boolean>;
    return this.getApplications(urlEnd)
      .then((application: StyledUserApplication) => {
        return application ? application.id === appConfig.globalMenuItemsApplication.id : false;
      });

  }

  public getApplicationIcon(url: string, urlEnd: string): Promise<string> {
    return this.getApplications(urlEnd)
      .then((application: StyledUserApplication) => {
        return application ? `fa ${application.icon}` : null;
      });
  }

  public getEmployeeName(url: string, urlEnd: string): Promise<string> {
    let matches: RegExpExecArray = /\/apps\/.*?\/employee\/(\d*)/.exec(url);
    if (!matches || matches.length !== 2) {
      return Promise.resolve(urlEnd);
    }
    let match: string = matches[1];
    let employeeId: number = parseInt(match, 10);

    if (!_.isFinite(employeeId)) {
      return Promise.resolve(urlEnd);
    }

    let promise: Promise<string> = this.employeeDefinitionsApiService.getEmployeeShortInfoCached(employeeId)
      .then((info: EmployeeShortInfo) => {
        return  `${info.fullName}` ;
      });
    return promise;
  }

  public getEmployeeNameTransform(url: string): RouteData {
    let rt: RouteData = new RouteData();
    let matches: RegExpExecArray = /\/apps\/(\w*).*\/employee\/(\d*)/.exec(url);
    if (!matches || matches.length !== 3) {
      rt.path = url.split('/');
      return rt;
    }
    let app: string = matches[1];
    let empMatch: string = matches[2];
    let path: string = `/apps/${app}/employee/${empMatch}/employee_sections`;
    rt.path = path.split('/');
    rt.queryParams = { hideLeftSideBar: true };

    return rt;
  }

  public getUserName(url: string, urlEnd: string): Promise<string> {
    let matches: RegExpExecArray = /\/apps\/.*?\/user\/(\d*)/.exec(url);
    if (!matches || matches.length !== 2) {
      return Promise.resolve(urlEnd);
    }
    let match: string = matches[1];
    let userId: number = parseInt(match, 10);

    if (!_.isFinite(userId)) {
      return Promise.resolve(urlEnd);
    }

    let promise: Promise<string> = this.userProfileApiService.getUserProfile(userId)
      .then((info: UserProfileModel) => {
        return `${info.name}`;
      });
    return promise;
  }

  public getUserNameTransform(url: string): RouteData {
    let rt: RouteData = new RouteData();
    let matches: RegExpExecArray = /\/apps\/(\w*).*\/user\/(\d*)/.exec(url);
    if (!matches || matches.length !== 3) {
      rt.path = url.split('/');
      return rt;
    }
    let app: string = matches[1];
    let usrMatch: string = matches[2];
    let path: string = `/apps/${app}/user/${usrMatch}/profile`;
    rt.path = path.split('/');
    rt.queryParams = { hideLeftSideBar: true };

    return rt;
  }

  public formatDate(url: string, urlEnd: string): Promise<string> {
    let dateTitle: string = `${moment(urlEnd, appConfig.linkDateFormat).startOf('day').format(appConfig.dateFormat)}`;
    return Promise.resolve(dateTitle);
  }

  public getShiftDateTransform(url: string): RouteData {
    let rt: RouteData = new RouteData();
    let index: number = url.indexOf('?') + 1;
    let path: string = url.substring(0, index > 1 ? index : url.length);
    rt.path = path.split('/');
    rt.queryParams = { hideLeftSideBar: true };
    return rt;
  }

  private getApplications(urlEnd: string): Promise<StyledUserApplication> {
    if (!this.applications) {
      return this.applicationService.getStyledApplications()
      .then((applications: StyledUserApplication[]) => {
        this.applications = applications;
        if (applications && applications.length > 0) {
          let application: StyledUserApplication = _.find(this.applications, (app: StyledUserApplication) => (app.link === urlEnd));
          return application;
        }
        return null;
      });
    } else {
      let application: StyledUserApplication = _.find(this.applications, (app: StyledUserApplication) => (app.link === urlEnd));
      return Promise.resolve(application);
    }
  }
}
