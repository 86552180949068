import { CancelType } from "./cancel-type";
import { DetailsPartnerAttributes } from "./details-partner-attributes";

export class IDetailsPartner {
  public id: number;
  public sub_type_id: number;
  public partner_id: string;
  public sub_type_name: string;
  public status: string;
  public shiftStatus:number;
  public isEnabled: boolean;
  public agency_id: number;
  public agency_name: string;
  public agency_grace_time: number;
  public partner_attributes:DetailsPartnerAttributes[];
  public cancelType: CancelType[];
}

export class DetailsPartner {
  public id: number;
  public sub_type_id: number;
  public partner_id: string;
  public sub_type_name: string;
  public status: string;
  public shiftStatus:number;
  public isEnabled: boolean;
  public agency_id: number;
  public agency_name: string;
  // 2 is hours
  public agency_grace_time: number = 2;
  public partner_attributes: DetailsPartnerAttributes[];
  public cancelType: CancelType[];
}
