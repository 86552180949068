import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { ValuePairChartDataService } from '../../services/value-pair-chart-data/value-pair-chart-data.service';
var LabeledValuePairComponent = /** @class */ (function () {
    function LabeledValuePairComponent(dataService, changeDetector) {
        this.dataService = dataService;
        this.changeDetector = changeDetector;
        this.onClick1 = new EventEmitter();
        this.onClick2 = new EventEmitter();
    }
    LabeledValuePairComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.modelLoaded = this.dataService.onModelLoaded.subscribe(function (model) {
            _this.model = model;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    LabeledValuePairComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    LabeledValuePairComponent.prototype.onClick1Handler = function (event) {
        this.onClick1.emit(event);
    };
    LabeledValuePairComponent.prototype.onClick2Handler = function (event) {
        this.onClick2.emit(event);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LabeledValuePairComponent.prototype, "modelLoaded", void 0);
    return LabeledValuePairComponent;
}());
export { LabeledValuePairComponent };
