/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-employees-enrolled-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../benefit-employees-enrolled/benefit-employees-enrolled.component.ngfactory";
import * as i3 from "../../../../../common/services/state-management/state-management.service";
import * as i4 from "../../../../../common/services/component-state/component-state-storage.service";
import * as i5 from "../../../../../common/services/column-settings/column-settings-storage.service";
import * as i6 from "../../../services/benefit-details/calculation-common.service";
import * as i7 from "../benefit-employees-enrolled/benefit-employees-enrolled.component";
import * as i8 from "../../../services/benefit-employees/benefit-employees-api.service";
import * as i9 from "../../../../../common/services/modal/modal.service";
import * as i10 from "../../../../../common/models/dialog-options";
import * as i11 from "../../../models/benefit-employees/benefit-eligible-employees-request";
import * as i12 from "../../../services/benefit-employees/benefit-enrollment-common.service";
import * as i13 from "../../../../../app-settings/services/app-settings-manage.service";
import * as i14 from "./benefit-employees-enrolled-dialog.component";
var styles_BenefitEnrolledEmployeesDialogComponent = [i0.styles];
var RenderType_BenefitEnrolledEmployeesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitEnrolledEmployeesDialogComponent, data: {} });
export { RenderType_BenefitEnrolledEmployeesDialogComponent as RenderType_BenefitEnrolledEmployeesDialogComponent };
export function View_BenefitEnrolledEmployeesDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dialog-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "slx-benefit-employees-enrolled", [], null, null, null, i2.View_BenefitEmployeesEnrolledComponent_0, i2.RenderType_BenefitEmployeesEnrolledComponent)), i1.ɵprd(4608, null, i3.StateManagementService, i3.StateManagementService, [i4.ComponentStateStorageService, i5.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.CalculationCommonService, i6.CalculationCommonService, []), i1.ɵdid(4, 245760, null, 0, i7.BenefitEmployeesEnrolledComponent, [i8.BenefitEmployeesApiService, i9.ModalService, i10.DialogOptions, i11.BenefitEnrolledEmployeesRequest, i6.CalculationCommonService, i12.BenefitEnrollmentCommonService, i13.AppSettingsManageService], { tierDetails: [0, "tierDetails"], date: [1, "date"], canEnroll: [2, "canEnroll"], lineId: [3, "lineId"], method: [4, "method"], planName: [5, "planName"], lowLevelOrgId: [6, "lowLevelOrgId"], selectedProviderLine: [7, "selectedProviderLine"], canMapPayroll: [8, "canMapPayroll"], mappedDeduction: [9, "mappedDeduction"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.request.tierDetails; var currVal_1 = _co.request.date; var currVal_2 = _co.request.canEnroll; var currVal_3 = _co.request.lineId; var currVal_4 = _co.request.calcMethod; var currVal_5 = _co.request.planName; var currVal_6 = _co.request.lowLevelOrgId; var currVal_7 = _co.request.lineDetails; var currVal_8 = _co.request.canMapPayroll; var currVal_9 = ((_co.request.lineDetails == null) ? null : _co.request.lineDetails.mappedDeduction); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
export function View_BenefitEnrolledEmployeesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-employees-enrolled-dialog", [], null, null, null, View_BenefitEnrolledEmployeesDialogComponent_0, RenderType_BenefitEnrolledEmployeesDialogComponent)), i1.ɵdid(1, 49152, null, 0, i14.BenefitEnrolledEmployeesDialogComponent, [i10.DialogOptions, i9.ModalService, i11.BenefitEnrolledEmployeesRequest], null, null)], null, null); }
var BenefitEnrolledEmployeesDialogComponentNgFactory = i1.ɵccf("slx-benefit-employees-enrolled-dialog", i14.BenefitEnrolledEmployeesDialogComponent, View_BenefitEnrolledEmployeesDialogComponent_Host_0, {}, {}, []);
export { BenefitEnrolledEmployeesDialogComponentNgFactory as BenefitEnrolledEmployeesDialogComponentNgFactory };
