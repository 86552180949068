<div class="compose-container">
  <div class="compose-message medium">
    <div class="compose-message-text">By using the SmartLinx Application, you agree to the <a
      href="https://www.smartlinx.com/sms-terms/" target="_blank">Terms and Conditions.</a> Please
   remember, conversations are visible to users with rights to the employee(s).</div>
</div>
</div>

<div class="slx-send-sms">
  <p class="slx-send-sms__row">
      <label class="slx-send-sms__label">To:</label>
      <span class="slx-send-sms__recipients">
        <span *ngFor="let recipient of recipients" class="slx-send-sms__recipient">
          {{ recipient.employee.name }}
          <i class="fas fa-times-circle slx-send-sms__rm-icon" aria-hidden="true" (click)="onRemoveRecipient(recipient)"></i>
        </span>
      </span>
    </p>
  <p class="slx-send-sms__row">
    <label class="slx-send-sms__label">Message:</label>
    <textarea class="slx-send-sms__message"
              [(ngModel)]="message"
              (ngModelChange)="onMessageChange()"
              [maxlength]="maxSmsCharactersLength"
              rows="4"
              placeholder="Enter your message here...">
    </textarea>
    <span class="msgcount">
      {{maxSmsCharactersLength-message.length}}/{{maxSmsCharactersLength}}
    </span>
  </p>
</div>
