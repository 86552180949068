import * as tslib_1 from "tslib";
import { DailyPunchesNavigationService } from './../../../../common/services/index';
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { Router, ActivatedRoute } from '@angular/router';
import { PunchesRollupManagementService } from '../../../services/punches/punches-rollup-management.service';
var PunchesGridRollupComponent = /** @class */ (function () {
    function PunchesGridRollupComponent(managementService, changeDetector, elementRef, router, route) {
        this.total = {};
        this.aggregates = [
            // { field: 'approvedTimecards', aggregate: 'sum' },
            { field: 'validTimecards', aggregate: 'sum' },
            { field: 'missingPunches', aggregate: 'sum' },
            { field: 'pendingEmpRequest', aggregate: 'sum' },
            { field: 'invalidPunches', aggregate: 'sum' },
            { field: 'scheduledPunches', aggregate: 'sum' }
        ];
        this.navService = new DailyPunchesNavigationService(router, route);
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.elementRef = elementRef;
    }
    PunchesGridRollupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (records) {
            _this.records = records;
            _this.orgLevelTitle = _this.managementService.getOrgLevelTitle();
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.loadStateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.state = state;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    PunchesGridRollupComponent.prototype.onPaycycleClicked = function (item) {
        var orgLevelId = this.needShowDepartments ? item.department.orgLevelId : item.organization.orgLevelId;
        this.navService.navigateToDailyPunchesDates(orgLevelId, item.startDate, item.endDate);
    };
    PunchesGridRollupComponent.prototype.onOrgLevelClicked = function (item) {
        var orgLevelId = this.needShowDepartments ? item.department.orgLevelId : item.organization.orgLevelId;
        this.navService.navigateToDailyPunchesDates(orgLevelId, item.startDate, item.endDate);
    };
    PunchesGridRollupComponent.prototype.onOrgLevelClickedWork = function (item) {
        var orgLevelId = item.workOrganization.orgLevelId;
        this.navService.navigateToDailyPunchesDates(orgLevelId, item.startDate, item.endDate);
    };
    PunchesGridRollupComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchesGridRollupComponent.prototype.getOrgLevelName = function (item) {
        if (this.needShowDepartments) {
            if (item.department)
                return item.department.name;
        }
        else {
            if (item.organization)
                return item.organization.name;
        }
        return '';
    };
    Object.defineProperty(PunchesGridRollupComponent.prototype, "needShowDepartments", {
        get: function () {
            return this.managementService.needShowDepartments;
        },
        enumerable: true,
        configurable: true
    });
    PunchesGridRollupComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
        this.total = aggregateBy(this.gridState.view.data, this.aggregates);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesGridRollupComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesGridRollupComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesGridRollupComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesGridRollupComponent.prototype, "recordsSelectedSubscription", void 0);
    return PunchesGridRollupComponent;
}());
export { PunchesGridRollupComponent };
