import { Routes } from '@angular/router';

import { AccrualsBalancesComponent, AccrualsPolicyComponent } from '../app-modules/accruals/components/index';
import { AccrualsTransactionsComponent } from '../app-modules/accruals/components/index';

import { ApplicationContainerComponent } from './components/index';
import { OrgLevelResolver } from './services/index';
import { AccrualsBalancesResolver } from '../app-modules/accruals/services';

export const accrualsRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        resolve: [OrgLevelResolver],
        redirectTo: 'accrual_transactions',
        pathMatch: 'full',
        data: { componentId: 'accrual_transactions', accessContext: 'OrgLevel' }
      },
      {
        path: 'accrual_transactions',
        resolve: [OrgLevelResolver],
        component: AccrualsTransactionsComponent,
        data: { componentId: 'accrual_transactions', accessContext: 'OrgLevel' }
      },
      {
        path: 'accrual_balances',
        resolve: [OrgLevelResolver, AccrualsBalancesResolver],
        component: AccrualsBalancesComponent,
        data: { componentId: 'accrual_balances', accessContext: 'OrgLevel' }
      },
      {
        path: 'accrual_policy_definitions',
        resolve: [OrgLevelResolver],
        component: AccrualsPolicyComponent,
        data: { componentId: 'accrual_policy_definitions', accessContext: 'OrgLevel' }
      }
    ]
  },
];


