import { Router, ActivatedRoute, NavigationExtras, UrlTree } from '@angular/router';
import * as _ from 'lodash';

export class PerformanceManNavigationService {
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  private getUrlTreeFromRoot(employeeId: number, hideOrgLevelBreadcrumb: boolean): UrlTree {
    return this.router.createUrlTree(
      ['apps', 'hr', 'performance_management_roster', 'employee', employeeId],
      {
        relativeTo: this.route.pathFromRoot[0],
        queryParams: { keepOrgLevelBreadcrmb: _.isBoolean(hideOrgLevelBreadcrumb) ? hideOrgLevelBreadcrumb : true }
      });
  }

  public navigateToReviews(employeeId: number): void {
    const urlTree = this.getUrlTreeFromRoot(employeeId, true);
    const extras: NavigationExtras = {
      replaceUrl: false,
    };
    this.router.navigateByUrl(urlTree, extras);
  }
}
