import { Router, ActivatedRoute, UrlTree,Params } from '@angular/router';
import { appConfig } from '../../../app.config';
import * as moment from 'moment';

export class AccrualsNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToBalance(endDate: Date, orgLevelId: number = 0,filterByNegativeBalances:boolean = true): void {
    let params: Params = {
      orgLevelId: orgLevelId,
      filterByNegativeBalances: filterByNegativeBalances,
      filterByDate: moment(endDate).format(appConfig.linkDateFormat)
    };   
    this.router.navigateByUrl(this.getUrlTreeForBalance(params));
  }
  public getUrlTreeForBalance(params: any): UrlTree {
    return this.router.createUrlTree(['accruals', 'accrual_balances'], { relativeTo: this.route.pathFromRoot[1], queryParams: params });
  }
}
