import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Assert } from '../../../../framework/index';
import { EmployeeTerminate, IEmployeeTerminate } from '../../models/index';
import { LookupMapService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class EmployeeTerminateMapService {

  private lookupMapService: LookupMapService;

  public mapToTerminateEmployeeDto(data: EmployeeTerminate): IEmployeeTerminate {
    Assert.isNotNull(data, 'data');
    let dto: IEmployeeTerminate = {
      date: dateTimeUtils.convertToDtoUTC(data.date),
      reasonId: data.reason ? data.reason.id : null,
      voluntary: data.voluntary,
      doNotRehire: data.doNotRehire,
      notes: data.notes,
      email: data.email
    };
    return dto;
  }
}

