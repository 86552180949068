var TAAbsence = /** @class */ (function () {
    function TAAbsence() {
    }
    Object.defineProperty(TAAbsence.prototype, "codeDescription", {
        get: function () {
            return "[" + this.code + "] " + this.description;
        },
        enumerable: true,
        configurable: true
    });
    return TAAbsence;
}());
export { TAAbsence };
