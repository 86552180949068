import { Component, OnInit, OnDestroy, Input, Host, NgZone, Provider } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsRotationsSettingsComponent } from '../employee-sections-rotations-settings/employee-sections-rotations-settings.component';
import { ModalService, ScheduleEntryNavigationService, EmployeeSectionNavigationService } from '../../../../../common/services/index';
import { ApplicationStateBusService } from '../../../../../organization/services/index';

import { ScheduleCycleMessages, ScheduleCycleSummaryViewAction } from '../../../../../organization/models/index';
import { DayOfWeek, DialogOptions } from '../../../../../common/models/index';
import {
  RotationTemplate, EmployeeSectionsRotations,
  EmployeeRotation, EmployeeRotationRecord,
  EmployeeSectionsBase,
  ConfigureRotationsRequest, RotationModifyRequest, RotationModifyResponse
} from '../../../models/index';
import { EmployeeSectionsScheduleApiService, WeekDayService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { RotationModifyDialogComponent } from '../rotation-modify-dialog/rotation-modify-dialog.component';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import { employeeRotationsUiState, IEmployeeRotationsUiState } from './employee-sections-rotations.component.ui-state';
import {
  Position,
  LocationUnit,
  ShiftDefinition,
  ConstraintDefinition,
  EmployeeShift,
  EmployeePositionDefinition, ScheduleCycleScope, EmployeeShortInfo
} from '../../../../../organization/models/index';
import * as _ from 'lodash';
import { IApplicationConfig } from '../../../../../app.config';
import { EmployeePositionSelectorComponent } from '../../../../employee/components/employee-position-selector/employee-position-selector.component';
import { ScheduleCycleSummaryDialogComponent } from '../../../../../organization/components/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-rotations',
  templateUrl: 'employee-sections-rotations.component.html',
  styleUrls: ['employee-sections-rotations.component.scss']
})
export class EmployeeSectionsRotationsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public set employeeSubsectionRotations(rotationsSection: EmployeeSectionsRotations) {
    this.rotationsExists = false;
    if (rotationsSection !== null && rotationsSection !== undefined) {
      this.rotationsSection = rotationsSection;
      this.rotations = rotationsSection.rotations;

      if (this.rotations !== null && this.rotations !== undefined) {
        this.rotationsExists = this.rotations.length > 0;
        this
          .rotations
          .forEach((r: EmployeeRotation) => this.weekDayService.prepareRotation(r));
        this.selectedRotation = this
          .rotationsSection
          .rotations
          .find((r: EmployeeRotation) => r.isCurrent);
        this.weekDaysRibbon = this
          .weekDayService
          .getWeekDaysByRotation(this.selectedRotation);
      }

      this.setCurrentRotationUiState();

    }
  }
  @Input() public employeeId: number;

  public get form(): AbstractControl {
    return null;
  }

  public rotationDefinitions: any[];
  public rotationsSection: EmployeeSectionsRotations;
  public rotations: EmployeeRotation[];
  public rotationsExists: boolean;
  public loadHomeValues: boolean = false;

  public selectedPosition: Position;
  public selectedUnit: LocationUnit;
  public selectedShift: ShiftDefinition;
  public selectedConstraint: ConstraintDefinition;
  public isCurrentRotationSelected: boolean;
  public componentId: string;
  public weekDaysRibbon: DayOfWeek[];
  public positionLookup: Position[];
  public shiftLookupMapByPositionId: {
    [positionId: string]: ShiftDefinition[];
  } = {};
  public shiftLookup: ShiftDefinition[];
  public unitLookupMapByPositionId: {
    [positionId: string]: LocationUnit[];
  } = {};
  public unitLookup: LocationUnit[];
  public constraintLookupMapByPositionId: {
    [positionId: string]: ConstraintDefinition[];
  } = {};
  public constraintLookup: ConstraintDefinition[];
  public selectedEmployeeShift: EmployeeShift;
  public weekDays: string[];
  public weekDayService: WeekDayService;
  public uiStates: IEmployeeRotationsUiState;
  public uiState: string;
  public appConfig: IApplicationConfig;

  public set selectedRotation(val: EmployeeRotation) {
    this.selectedRotationPrototype = val;
    this.selectedRotationClone = _.cloneDeep(val);
  }

  public get selectedRotation(): EmployeeRotation {
    return this.selectedRotationPrototype;
  }

  public get isValid(): boolean {
    if (this.selectedRotation !== null && this.selectedRotation !== undefined && this.selectedRotation.weeklyRotations !== null && this.selectedRotation.weeklyRotations !== undefined) {
      for (let i: number = 0; i < this.selectedRotation.weeklyRotations.length; i++) {
        if (this.selectedRotation.weeklyRotations[i].dailyRecords !== null && this.selectedRotation.weeklyRotations[i].dailyRecords !== undefined) {
          if (this.selectedRotation.weeklyRotations[i].dailyRecords.some((r: EmployeeRotationRecord) => !r.isValid))
            return false;
        }
      }
    }
    return true;
  }

  private employeeSectionsScheduleApiService: EmployeeSectionsScheduleApiService;
  private modalService: ModalService;
  private selectedRotationClone: EmployeeRotation;
  private selectedRotationPrototype: EmployeeRotation;
  private employeesWithSamePosition: EmployeePositionDefinition[];
  private generateScheduleSummary: ScheduleCycleMessages;

  constructor(employeeSectionsScheduleApiService: EmployeeSectionsScheduleApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    weekDayService: WeekDayService,
    lookupApiService: LookupApiService,
    private applicationStateBusService: ApplicationStateBusService,
    modalService: ModalService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsScheduleApiService, 'employeeSectionsScheduleApiService');
    this.employeeSectionsScheduleApiService = employeeSectionsScheduleApiService;
    Assert.isNotNull(weekDayService, 'weekDayService');
    this.weekDayService = weekDayService;
    this.modalService = modalService;
    this.selectedEmployeeShift = {
      isValid: false,
      constraint: undefined,
      position: undefined,
      shift: undefined,
      unit: undefined,
      absence: undefined,
      isDirty: false
    };
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.rotationsSection;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.uiStates = employeeRotationsUiState;
  }

  public onAcceptShiftTemplateClicked(): void {
    this.setEditRotationsUiState();
  }

  public selectedRotaionChanged(): void {
    if (this.selectedRotation.isCurrent) {
      this.setCurrentRotationUiState();
    } else {
      this.setHistoricalUiState();
    }
  }

  public onEditRotationConfigClicked(): void {
    this.setEditSettingsUiState();
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 420;
    dialogOptions.width = 650;
    let request: ConfigureRotationsRequest = new ConfigureRotationsRequest();
    request.employeeId = this.employeeId;
    request.homePositionId = this.rotationsSection.homePositionId;
    request.homeShiftId = this.rotationsSection.homeShiftId;
    request.homeUnitId = this.rotationsSection.homeUnitId;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }, {
        provide: ConfigureRotationsRequest,
        useValue: request
      }
    ];

    let dialog: EmployeeSectionsRotationsSettingsComponent = this
      .modalService.globalAnchor
      .openDialog(EmployeeSectionsRotationsSettingsComponent, 'Rotation settings', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        if (result) {
          let rotationTemplate: RotationTemplate = dialog.selectedRotationTemplate;
          if (rotationTemplate) {
            this.selectedEmployeeShift = rotationTemplate.employeeShift;
            this.doGenerateRotations(this.employeeId, rotationTemplate);
          }
        }
      });
  }

  public onEditShiftTemplateClicked(): void {
    this.setEditShiftTemplateUiState();
  }

  public onImportRotationsClicked(): void {
    if (!this.employeesWithSamePosition) {
      this
        .employeeSectionsScheduleApiService
        .getImportEmployees(this.employeeId)
        .then((positions: EmployeePositionDefinition[]) => {
          this.employeesWithSamePosition = positions;
          this.openSelectEmployeePositionDialog(positions);
        });
    } else {
      this.openSelectEmployeePositionDialog(this.employeesWithSamePosition);
    }
  }

  public onCancelClicked(): void {
    let indexOfCurrent: number = this
      .rotations
      .indexOf(this.selectedRotation);
    this.rotations[indexOfCurrent] = this.selectedRotationClone;
    this.selectedRotation = this.selectedRotationClone;
    this.setCurrentRotationUiState();
  }

  public onRestoreRotationClicked(): void {
    this.doRestoreRotation(this.employeeId, this.selectedRotation);
  }

  public onAcceptClicked(): void {
    if (!this.isValid) {
      this.modalService.globalAnchor
        .openConfirmDialog('Confirmation', 'Some of rotation records have overlapped shifts. Are you sure wish to save this ' +
          'Rotations?',
          (result: boolean) => {
            if (result) {
          this.askAndModify(this.employeeId, this.selectedRotation);
            }
          });
    } else {
      this.askAndModify(this.employeeId, this.selectedRotation);
    }
  }

  public onRequiredFieldsPopulated(): void {
    this.setEditRotationsUiState();
  }

  protected loadSubsection(): void {
    this.loadSubsectionDelegate();
  }

  protected loadSubsectionDelegate(): Promise<any> {
    this.startProgress();
    return this
      .employeeSectionsScheduleApiService
      .getRotations(this.employeeId)
      .then((rotationsSubsection: EmployeeSectionsRotations) => {
        this.employeeSubsectionRotations = rotationsSubsection;
        this.state.isLoaded = true;
        this.stopProgress();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private openSelectEmployeePositionDialog(employeePositions: EmployeePositionDefinition[]): void {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 600;
    dialogOptions.width = 800;
    let dialog: EmployeePositionSelectorComponent = this
      .modalService.globalAnchor
      .openDialog(EmployeePositionSelectorComponent, 'Select the employee rotation that you wish to import.', dialogOptions, undefined, (result: boolean) => {
        if (result) {
          let selectedItems: EmployeePositionDefinition[] = dialog.selectedItems;
          if (selectedItems.length !== 1) {
            throw new Error('Employee positions count not equals to 1.');
          }
          let selectedEmployeePosition: EmployeePositionDefinition = selectedItems[0];
          this.doImport(this.employeeId, selectedEmployeePosition.employee.id);
        }
      });
    dialog.isSingleItemSelectable = true;
    dialog.employeePositions = employeePositions;
  }

  private setCurrentRotationUiState(): void {
    this.uiState = employeeRotationsUiState.currentRotationViewState;
  }

  private setHistoricalUiState(): void {
    this.uiState = employeeRotationsUiState.historicalViewState;
  }

  private setEditSettingsUiState(): void {
    this.uiState = employeeRotationsUiState.editSettingsState;
  }

  private setEditShiftTemplateUiState(): void {
    this.uiState = employeeRotationsUiState.editShiftTemplateState;
  }

  private setEditRotationsUiState(): void {
    this.uiState = employeeRotationsUiState.editRotationsState;
  }

  private askAndModify(employeeId: number, rotation: EmployeeRotation): void {
    const req = new RotationModifyRequest();
    req.rotation = rotation;
    req.regenerateScope = ScheduleCycleScope.currentScheduleCycle;
    req.homePositionOrgLevelId = this.employeeShortInfo.position.orgLevelId;
    RotationModifyDialogComponent.openDialog(req, this.modalService, (result: boolean, res: RotationModifyRequest) => {
      if (result) {
        this.doModify(employeeId, res);
      }
    });
  }

  private doModify(employeeId: number, req: RotationModifyRequest): void {
    this.startProgress();
    this
      .employeeSectionsScheduleApiService
      .setRotations(employeeId, req)
      .then((result: RotationModifyResponse) => {
        this.stopProgress();
        this.loadSubsection();
        this.generateScheduleSummary = null;
        if (result.reGenerated) {
          this.applicationStateBusService.resetCache('TimecardsApiServiceCache');
          this.generateScheduleSummary = result.generateScheduleMessages;
          if (this.generateScheduleSummary.messages && this.generateScheduleSummary.messages.length > 0) {
            this.showSummary();
          } else {
            this.modalService.globalAnchor
              .openInfoDialog('Operation completed', 'Employee schedule was re-generated successfully',
                (result: boolean) => {
                  //do nothing
                });
          }
        }
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private doRestoreRotation(employeeId: number, rotation: EmployeeRotation): void {
    this.startProgress();
    this
      .employeeSectionsScheduleApiService
      .restoreRotations(employeeId, rotation)
      .then((result: any) => {
        this.loadHomeValues = false;
        this.stopProgress();
        this.loadSubsection();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private doGenerateRotations(employeeId: number, rotationTemplate: RotationTemplate): void {
    this.startProgress();
    this
      .employeeSectionsScheduleApiService
      .generateRotations(employeeId, rotationTemplate)
      .then((value: any) => {
        this.stopProgress();
        this.loadSubsectionDelegate().then(() => {
          if (rotationTemplate.rotationsCount && rotationTemplate.rotationsCount > 0) {
            this.setEditShiftTemplateUiState();
          }
        });
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private doImport(employeeId: number, rotationsEmployeeId: number): void {
    this.startProgress();
    this
      .employeeSectionsScheduleApiService
      .importRotations(employeeId, rotationsEmployeeId)
      .then((value: any) => {
        this.stopProgress();
        this.loadSubsection();
      })
      .catch((res: any) => {
        this.stopProgress();
      });
  }

  private showSummary(): void {
    if (this.generateScheduleSummary) {
      ScheduleCycleSummaryDialogComponent.openDialog(this.generateScheduleSummary, this.modalService, (action: ScheduleCycleSummaryViewAction) => {
        if (!action) {
          return;
        }
        if (action.action === 'NavigateToEmployee') {
          const es = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
          es.NavigateToEmployeeSections(action.employeeId, false);
        }
        if (action.action === 'NavigateToScheduleEntry') {
          const se = new ScheduleEntryNavigationService(this.router, this.activatedRoute);
          se.NavigateToScheduleEntry(action.employeeId, action.date);
        }
      });
    }
  }
}
