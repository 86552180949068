export const AGENCY_ORGANIZATION_ID: number = 1000;

export interface IConfigurationConfig {
  api: {
    orglevels:{
      root:string;
      changelogs:string;
      sync:string;
      orgType:string;
      transferPayrollInfo:string;
    }
    wfm:{

      root: string;
      event:string,
      post:string,
      startdate:string,
      enddate:string,
      eventid:string,
      sync:string,
      stop:string,
      flagstatus:string,
      update:string,
      resync:string,
      secondaryPositions: string
     

    }
    configuration: {
      root: string;
      employeeBenifits:{
        root:string;
        sync:string;
      },
      costcenter:{
        root:string,
        sync:string
      }
      
        employee:{
          root:string;
          sync:string;
        },
      
      orglevel: {
        root: string;
      },
      absences: {
        root: string;
        delete: string;
      },
      wfm:{

        root: string;

        sync:string;
        responsedetails:string,
        employees:string,
        employee:string,
        response:string,
        report:string,
        eventreport:string,
        taeventreport:string,
        event:string,
        error:string,
        errordetails:string,
        employeeBenifits:string,
        costcenter:string,
        post:string,
        startdate:string,
        enddate:string,
        dashBoard:string,
        drillDown:string,
        paydatareport:string
       

      },wfmSyncId:string;
      org:{
        root:string,
        orglevels:string
 
       },
      orgsync:{
        lookup:string,
        userAccesibleOrganizations:string;
        department:string;
        position:string;
      }
      units: {
        root: string;
      },
      positions: {
        root: string;
        copyConfiguration: string;
        copy: string;
        positionsGroups: string;
      },
      constraints: {
        root: string;
      },
      shifts: {
        root: string;
        copyConfiguration: string;
        copy: string;
        setting: string;
      },
      shiftGroup: {
        root: string;
        updateShiftGroup: string;
      }
      configuration: {
        root: string,
        idealScheduleSettings: string,
        idealSchedule: {
          root: string;
          overview: string;
        },
        idealScheduleShiftGroup: string,
        importTemplate: {
          root: string;
          export: {
            root: string;
            excel: {
              root: string;
            };
            selectedPositionValidation: {
              root: string;
            };
            saveIdealScheduleExcel: {
              root: string;
            }
          }
        },
        fileUploads: {
          root: string;
          downloadImportedFiles: {
            root: string;
          }
        }
      },
      timeclocks: {
        root: string;
        delete: string;
        timeAndAttendance: string,
        tempSensorReadings: string;
      },
      agencies: {
        root: string;
        assign: string;
        unassign: string;
        employees: string;
        validateAddAgencyEmployee: string;
        addAgencyEmployee: string;
        resendTimeclocksEmail: string;
      },
      users: {
        root: string;
        userRoles: string;
        roles: string;
        changeDeleteState: string;
        usersWithRoles: string;
        validate: {
          root: string;
          username: string;
        },
        management: {
          root: string;
          sessions: string;
          logoff: string;
        }
      },
      roles: {
        root: string;
        users: string;
      },
      pbjOrganizations: {
        root: string;
        orglevel: string;
        ccn: {
          root: string;
          validation: string;
        }
      },
      payCodes: {
        root: string;
      },
      password: {
        root: string,
        policy: string
      },
      telepunchLocations: {
        root: string;
      },
      costCenters: {
        root: string;
      },
      geolocation: {
        root: string;
      },
      ipManagement: {
        root: string;
        configuration: string;
        restriction: string;
      },
      employeeSms: {
        root: string;
        configuration: string;
      },
      employeeSendSms: {
        root: string;
        configuration: string;
        apiVersion: string;
      },
      messageCenter: {
        root: string;
        apiVersion: string;
        page: string;
        pageSize: string;
        orgLevelId: string;
        isArchived: string;
        messages: string;
        paged: string;
        pageData: string;
        messageCount: string;
        archived: string;
        employeeId: string;
        thread: string;
        getEmployeeStatus: string;
        messagecenter: string;
        v1: string;
        recentMessage: string;
        searchText: string;
        dateOrderByAscending: string;
        nameOrderByAscending: string;
        includeCold: string;
        employeePicsByUsers: string;
        unreadCount: string;
        signalr: string;
        communication: string;
        accesstToken: string;
        checkaccess: string;
        user: string;
        reminders: string;
        getLatestScheduleCycle: string;
        getComposeNewTabsPermissions:string;
        archive: {
          configuration: string;
        };
        unArchive: {
          configuration: string;
        };
        read: {
          configuration: string;
        };
        unRead: {
          configuration: string;
        };
        thubmnails: string;
        cold: string;
        exists: string;
        employeeMessage: string;
      },
      eventNotifications: {
        root: string;
        group: string;
        event:string;
        groupCreate:string
      },
      licenseTypes: {
        root: string;
        canDelete: string;
      },
      employeePunchRequest: {
        root: string;
        orgLevel: string;
        rollup: string;
        employee: {
          root: string;
        }
      }
    }
    core: {
      root: string;
      roles: {
        root: string;
        orgLevel: string;
        clone: string;
      }
      roleConfiguration: {
        root: string;
        orgLevel: string;
      }
    },
    ACA: {
      root: string;
      companies: {
        root: string;
        all: string;
        company: string;
        organization: {
          root: string;
          all: string;
        }
        assign: {
          root: string
        },
        groups: {
          root: string;
          all: string;
        }
      },
      configuration: {
        root: string;
        acaPeriods: string;
        acaPeriod: string;
      }
      measurement: {
        root: string;
        insight: {
          root: string;
          all: string;
        }
      }
      c1095: {
        root: string;
        all: string;
        addEmployee: string;
        removeEmployee: string;
        edit1095cRecords: string;
        confirm1095cRecords: string;
        editReset1095cRecords: string;
        confirmReset1095cRecords: string;
      }
      c1095Audit: {
        root: string;
        all: string;
      }
    },
    favorites: {
      root: string;
      menu: string;
      orglevels: string;
    },
    slate: {
      root: string,
      notification: {
        root: string,
        orgLevel: string,
        group: {
          root: string,
          messages: string,
          message: string,
          send: string
        },
        employees: {
          root: string,
          search: string
        }
      },
    },
    signalR: {
      root: string,
      signalRHub: {
        root: string
      }
    }
  };
  deductFromGroups: string[];
}

export const configurationConfig: IConfigurationConfig = {
  api: {
    orglevels: {
      root: 'orglevels',
      changelogs: 'changelogs',
      sync: 'sync',
      orgType: 'orgType',
      transferPayrollInfo: 'transferPayrollInfo'
    },
    wfm: {
      root: 'wfm',
      event: 'event',
      post: 'post',
      startdate: 'startdate',
      enddate: 'enddate',
      eventid: 'eventid',
      sync: 'sync',
      stop: 'stop',
      flagstatus: 'flagstatus',
      update: 'update',
      resync: 'resync',
      secondaryPositions: 'secondaryPositions'
    },
    configuration: {
      root: 'configuration',
      employeeBenifits: {
        root: 'employeeBenifits',
        sync: 'sync'
      },
      costcenter: {
        root: 'costcenter',
        sync: 'sync'
      },
      employee: {
        root: 'employee',
        sync: 'sync'
      },
      orglevel: {
        root: 'orglevel'
      },
      absences: {
        root: 'scheduleAbsences',
        delete: 'delete'
      },
      units: {
        root: 'units'
      },
      positions: {
        root: 'positions',
        copyConfiguration: 'copyConfiguration',
        copy: 'copy',
        positionsGroups: 'positions_groups'
      },
      constraints: {
        root: 'constraints'
      },
      shifts: {
        root: 'shifts',
        copyConfiguration: 'copyConfiguration',
        copy: 'copy',
        setting:'setting'
      },
      shiftGroup: {
        root: 'shiftGroup',
        updateShiftGroup: 'updateShiftGroup'
      },
      configuration: {
        root: 'configuration',
        idealScheduleSettings: 'idealScheduleSettings',
        idealSchedule: {
          root: 'idealSchedule',
          overview: 'overview'
        },
        idealScheduleShiftGroup: 'idealScheduleShiftGroup',
        importTemplate: {
          root: 'importTemplate',
          export: {
            root: 'export',
            excel: {
              root: 'excel'
            },
            selectedPositionValidation: {
              root: 'selectedPositionValidation'
            },
            saveIdealScheduleExcel: {
              root: 'saveIdealScheduleExcel'
            }
          }
        },
        fileUploads: {
          root: 'importedFiles',
          downloadImportedFiles: {
            root: 'downloadImportedFiles'
          }
        }
      },
      timeclocks: {
        timeAndAttendance: 'ta',
        root: 'timeclocks',
        delete: 'delete',
        tempSensorReadings: 'tempreadings',
      },
      agencies: {
        root: 'agencies',
        assign: 'assign',
        unassign: 'unassign',
        employees: 'employees',
        validateAddAgencyEmployee: 'validateAddAgencyEmployee',
        addAgencyEmployee: 'addAgencyEmployee',
        resendTimeclocksEmail: 'resendTimeclocksEmail',
      },
      users: {
        root: 'users',
        userRoles: 'userRoles',
        roles: 'roles',
        changeDeleteState: 'changeDeleteState',
        usersWithRoles: 'usersWithRoles',
        validate: {
          root: 'validate',
          username: 'username'
        },
        management: {
          root: 'management',
          sessions: 'sessions',
          logoff: 'logoff'
        }
      },
      roles: {
        root: 'roles',
        users: 'users'
      },
      pbjOrganizations: {
        root: 'organizations',
        orglevel: 'orglevel',
        ccn: {
          root: 'ccn',
          validation: 'validation'
        }
      },
      payCodes: {
        root: 'exceptions'
      },
      password: {
        root: 'password',
        policy: 'policy'
      },
      telepunchLocations: {
        root: 'telepunchlocations'
      },
      costCenters: {
        root: 'costCenters'
      },
      geolocation: {
        root: 'geolocation'
      },
      ipManagement: {
        root: 'ip-management',
        configuration: 'configuration',
        restriction: 'restriction'
      },
      employeeSms: {
        root: 'messagecenter',
        configuration: 'employeeSMSContacts'
      },
      employeeSendSms: {
        root: 'messages',
        configuration: 'send',
        apiVersion: 'api-version'
      },
      messageCenter: {
        root: 'messages',
        apiVersion: 'api-version',
        page: 'page',
        pageSize: 'pageSize',
        orgLevelId: 'orgLevelId',
        isArchived: 'isArchived',
        messages: 'messages',
        paged: 'paged',
        pageData: 'pageData',
        messageCount: 'messageCount',
        archived: 'archived',
        employeeId: 'employeeId',
        thread: 'thread',
        getEmployeeStatus: 'getEmployeeStatus',
        recentMessage: 'recent-message',
        v1: 'v1',
        messagecenter: 'messagecenter',
        searchText: 'searchText',
        dateOrderByAscending: 'dateOrderByAscending',
        nameOrderByAscending: 'nameOrderByAscending',
        includeCold: 'includeCold',
        employeePicsByUsers: 'employeePicsByUsers',
        unreadCount: 'unread-count',
        signalr: 'signalr',
        communication: 'communication',
        accesstToken: 'access_token',
        checkaccess: 'checkaccess',
        user: 'user',
        reminders: 'reminders',
        getLatestScheduleCycle: 'getLatestScheduleCycle',
        getComposeNewTabsPermissions: 'getComposeNewTabsPermissions',
        archive: {
          configuration: 'archive'
        },
        unArchive: {
          configuration: 'unarchive'
        },
        read: {
          configuration: 'read'
        },
        unRead: {
          configuration: 'unread'
        },
        thubmnails: 'thumbnails',
        cold: 'cold',
        exists: 'exists',
        employeeMessage: 'employee'
      },
      eventNotifications: {
        root: 'notification',
        group: 'group',
        event: 'event',
        groupCreate: 'groupCreate'
      },
      licenseTypes: {
        root: 'licenseTypes',
        canDelete: 'canDelete'
      },
      employeePunchRequest: {
        root: 'employeePunchRequest',
        orgLevel: 'orgLevel',
        rollup: 'rollup',
        employee: {
          root: 'employee'
        }
      },
      wfm: {
        root: 'wfm',

        sync:'sync',
        responsedetails:'responsedetails',
        employees:'employees',
        employee:'employee',
        response:'response',
        report:'report',
        eventreport:'eventreport',
        taeventreport:'taeventreport',
        event:'event',
        error:'error',
        errordetails:'errordetails',
        employeeBenifits:'employeeBenifits',
        costcenter:'costcenter',
        post:'post',
        startdate:'startdate',
        enddate:'enddate',
        dashBoard:'dashBoard',
        drillDown:'drillDown',
        paydatareport:'paydatatransactionreport'
       
      },wfmSyncId: 'SyncId',
      org:{
        root:'applications',
        orglevels:'orglevels'
      },
      orgsync: {
        lookup: 'lookup',
        userAccesibleOrganizations: 'userAccesibleOrganizations',
        department: 'department',
        position: 'position'
      }
    },
    core: {
      root: 'core',
      roles: {
        root: 'roles',
        orgLevel: 'orgLevel',
        clone: 'clone'
      },
      roleConfiguration: {
        root: 'roleConfiguration',
        orgLevel: 'orgLevel'
      }
    },
    ACA: {
      root: 'ACA',
      companies: {
        root: 'companies',
        all: 'all',
        company: 'company',
        organization: {
          root: 'organization',
          all: 'all'
        },
        assign: {
          root: 'assignALEGroup'
        },
        groups: {
          root: 'ALEGroup',
          all: 'all'
        }
      },
      configuration: {
        root: 'configuration',
        acaPeriods: 'acaPeriods',
        acaPeriod: 'acaPeriod'
      },
      measurement: {
        root: 'measurement',
        insight: {
          root: 'insight',
          all: 'all'
        }
      },
      c1095: {
        root: 'report',
        all: 'all',
        addEmployee: 'add/employee',
        removeEmployee: 'remove/employee',
        edit1095cRecords: 'edit1095employee/records',
        confirm1095cRecords: 'confirm1095employee/records',
        editReset1095cRecords: 'resetedit1095employee/records',
        confirmReset1095cRecords: 'resetConfirm1095employee/records'
      },
      c1095Audit: {
        root: 'audit',
        all: 'all'
      }
    },
    favorites: {
      root: 'favorites',
      menu: 'menu',
      orglevels: 'orglevels'
    },
    slate: {
      root: 'slate',
      notification: {
        root: 'notification',
        orgLevel: 'orglevel',
        group: {
          root: 'group',
          messages: 'messages',
          message: 'message',
          send: 'send'
        },
        employees: {
          root: 'employees',
          search: 'search'
        }
      },
    },
    signalR: {
      root: "",
      signalRHub: {
        root: ""
      }
    }
  },
  deductFromGroups: [
    'Absence',
    'Additional - Exempt',
    'Holiday Premium',
    'Other',
    'Other - Exempt'
  ]
};