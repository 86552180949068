<slx-spinner [show]="manageService.isLoading">
    <div class="slx-content-toolbar-indents flex-container action-buttons">
        <div class="buttons-box">
            <button type="button" class="theme-button-apply save-Btn" [disabled]="manageService.enableBulkSaveBtn"
                (click)="onBulkSaveClick()">
                Save
            </button>
        </div>
    </div>
    <div class="slx-high-box">
        <form novalidate #form="ngForm" class="slx-high-box__body slx-main-content-indents no-padding">
            <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height" 
                [data]="manageService.gridData"
                [trackBy]="trackByItem" 
                (edit)="editHandler($event)" 
                (save)="saveHandler()"
                (cancel)="cancelHandler()">
                <kendo-grid-column title="Pay Code" field="payrollPaycode" width="160">
                </kendo-grid-column>
                <kendo-grid-column title="Pay Number" field="payNumber" width="160" editable="true">
                    <ng-template kendoGridCellTemplate let-dataItem>{{ dataItem.payNumber }}</ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                        <slx-dropdown-list name="payNumber{{rowIndex}}" class="slx-wide slx-short"
                            [valuePrimitive]="true" [options]="manageService.payNumbers"
                            (ngModelChange)="onItemFieldChange($event)"
                            [(ngModel)]="editedEntry.payNumber" [required]="true">
                            <span errorMessage for="required"></span>
                        </slx-dropdown-list>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-command-column title="Actions" [width]="200">
                    <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
                        <div class="btn-container">
                            <button kendoGridEditCommand [primary]="true" class="edit-Btn">Edit</button>
                            <button kendoButton kendoGridSaveCommand [disabled]="!form.valid" [primary]="true"
                                class="theme-iconed-accent-button btn-apply">
                                Done
                            </button>
                            <button kendoButton kendoGridCancelCommand class="theme-iconed-accent-button btn-cancel">
                                Cancel
                            </button>
                        </div>
                    </ng-template>
                </kendo-grid-command-column>
            </kendo-grid>
        </form>
    </div>
</slx-spinner>