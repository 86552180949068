/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeclock-reboot-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./timeclock-reboot-dialog.component";
import * as i3 from "../../../../models/timeclock/timeclock";
import * as i4 from "../../../../../common/services/modal/modal.service";
import * as i5 from "../../../../../common/models/dialog-options";
var styles_TimeclockRebootDialogComponent = [i0.styles];
var RenderType_TimeclockRebootDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimeclockRebootDialogComponent, data: {} });
export { RenderType_TimeclockRebootDialogComponent as RenderType_TimeclockRebootDialogComponent };
export function View_TimeclockRebootDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Are you sure you want to restart:"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), (_l()(), i1.ɵted(-1, null, ["?"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This could take a few minutes and your staff may not be able to use the clock until the restart has completed."])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "footer-btns-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "discard-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Discard "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "save-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Yes "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeClock.clockName; _ck(_v, 5, 0, currVal_0); }); }
export function View_TimeclockRebootDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-timeclock-reboot-dialog", [], null, null, null, View_TimeclockRebootDialogComponent_0, RenderType_TimeclockRebootDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.TimeclockRebootDialogComponent, [i3.TimeClock, i4.ModalService, i5.DialogOptions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimeclockRebootDialogComponentNgFactory = i1.ɵccf("slx-timeclock-reboot-dialog", i2.TimeclockRebootDialogComponent, View_TimeclockRebootDialogComponent_Host_0, {}, {}, []);
export { TimeclockRebootDialogComponentNgFactory as TimeclockRebootDialogComponentNgFactory };
