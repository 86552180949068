  <input  #phoneInput
          [textMask]="phoneMaskConf"
          [(ngModel)]="innerValue"
          (ngModelChange)="valueChanged($event)"
          [attr.class]="className"
          [readonly]="!inEdit ? true : null"
          type="text" maxlength="50"
          [placeholder]="placeholder"
          [attr.tabindex]="tabindex"
          >
