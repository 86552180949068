import { IdealSchedulePositionRange } from './../../../models/ideal-schedule/ideal-schedule-position-range';
import { Component, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
    moduleId: module.id,
    selector: 'slx-ideal-schedule-fixed-grid',
    templateUrl: 'ideal-schedule-fixed-grid.component.html',
    styleUrls: ['ideal-schedule-fixed-grid.component.scss']
})

export class IdealScheduleFixedGridComponent {

    @Input()
    public ranges: IdealSchedulePositionRange[];

    public get fixedRange(): IdealSchedulePositionRange {
        return _.first(this.ranges);
    }
    
}
