import { IncreaseReason } from './../../../../organization/models/lookup/increase-reason';
import { LookupEntity } from './../../../../organization/models/lookup/lookup-entity';
import { LookupService } from './../../../../organization/services/lookup/lookup.service';
import { Lookup, LookupType } from './../../../../organization/models/lookup/lookup-definition';
import { AppServerConfig } from './../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../app-settings/services/app-settings-manage.service';

import { EmployeePayRateComponent } from './../../../employee/models/employee-pay-rate-component';
import { appConfig } from './../../../../app.config';
import { MassRateAdjustmentsDefenition, MassRateAdjustmentsDefenitions, PayRateDefenitions } from './../../../../organization/models/employee/mass-rate-adjustments';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { Component, Inject, OnInit, Provider } from '@angular/core';
import { ColumnManagementService } from './../../../../common/services/column-settings/column-management.service';
import { ModalService } from './../../../../common/services/modal/modal.service';
import { Employee, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { EMPLOYEES_ID_TOKEN } from '../../../../core/models/index';
import { IDialog, DialogOptions2, DialogModeSize, KendoGridStateHelper } from '../../../../common/index';
import { NotificationsService } from '../../../../core/components';
import { process } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { MassPayRateAdjustmentMapService } from '../../services/mass-rate-adjustments/mass-rate-adjustments-map.service';

@Component({
  moduleId: module.id,
  selector: 'mass-rate-adjustments',
  templateUrl: './mass-rate-adjustments.component.html',
  styleUrls: ['./mass-rate-adjustments.component.scss'],
  providers: [ColumnManagementService]
})
export class MassRateAdjustmentsDialogComponent implements OnInit, IDialog {

  public actionEmployees: Employee[];
  public employees: Employee[];
  public fieldDefinition: EmployeeActionDefinition;
  public orgLevel: OrgLevel;
  private options: DialogOptions2;
  public gridState: KendoGridStateHelper<Employee>;
  public previewgridState: KendoGridStateHelper<Employee>;
  public isRatecomponentenabled: boolean = false;
  public empRateComponent: PayRateDefenitions;
  public adjustedEmployees: MassRateAdjustmentsDefenitions = new MassRateAdjustmentsDefenitions(); //obj for saving 
  public adjustedMassRates: MassRateAdjustmentsDefenition[];
  public isLoading: boolean = false;
  public type: boolean = true;
  public pageSize: number ;//= 50;
  public isPreviewState: boolean = false;
  public effectiveDate: Date = new Date();
  public decimalLimit = null;
  public employeeIds: string[];
  public selectedEmployees: Employee[];
  private groupActivitiesService: GroupActivitiesApiService;
  private modalService: ModalService;
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  public incrementType: string = "FlatRate";
  public increment: number = 0.00;
  dialogResult: boolean;
  public enableRate4DecimalPlaces: boolean;
  public incrementFormat = 'n2';
  public incrementdecimal :number = 2;
  public increaseReason: LookupEntity=null;
  public increaseReasons: Lookup = null;
  public isIncreaseReasonsEnabled : boolean = false;
  public defaultItem: any = { id: 0, name: '' }; 
  constructor(groupActivitiesService: GroupActivitiesApiService,
    @Inject(EMPLOYEES_ID_TOKEN) employees: Employee[], fieldDefinition: EmployeeActionDefinition,
    private massPayRateAdjustmentMapService: MassPayRateAdjustmentMapService,
    orgLevel: OrgLevel,
    modalService: ModalService, options: DialogOptions2,
    private notificationsService: NotificationsService,
    private appSettingsManageService: AppSettingsManageService,
    private lookupService: LookupService,
  ) {
    this.groupActivitiesService = groupActivitiesService;
    this.employees = employees;
    this.fieldDefinition = fieldDefinition;
    this.pageSize = employees.length;
    this.orgLevel = orgLevel;
    this.initializeGrid();
    this.modalService = modalService;
    this.options = options;

  }
  
  public ngOnInit(): void {
    this.isLoading = true;
    this.getSettings();
    this.lookupService.getLookup({ lookupType: LookupType.increaseReason, employeeId: undefined, orgLevelId: undefined })
      .then((increaseReasons: Lookup) => {
        this.increaseReasons = increaseReasons;
      });
      
    this.employeeIds = _.map(this.employees, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
    this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, this.employeeIds, this.fieldDefinition)
      .then((actionEmployees: Employee[]) => {
        this.actionEmployees = actionEmployees;
        this.validateEmployee(this.actionEmployees);
        this.isLoading = false;
        this.refreshGrid();
      });
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(() => {
      this.refreshGrid();
    });
    this.subscriptions.previewGridRefreshSubscription = this.previewgridState.onRefreshGrid.subscribe(() => {
      this.refreshPreviewGrid();
    });
  }

  public ngOnDestroy(): void { }

  public initializeGrid(): void {
    this.gridState = new KendoGridStateHelper<Employee>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize; 
    this.gridState.state.sort = [];

    this.previewgridState = new KendoGridStateHelper<Employee>();
    this.previewgridState.view = null;
    this.previewgridState.state.skip = 0;
    this.previewgridState.state.take = this.pageSize; 
    this.previewgridState.state.sort = [];
  }
 
  private async getSettings(): Promise<void> {
    const config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.enableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
    this.isIncreaseReasonsEnabled = config.enableIncreaseReason;
    if(this.enableRate4DecimalPlaces){
      this.incrementFormat = 'n4';
      this.incrementdecimal = 4;
    }
  }

  private refreshGrid(): void {
    if (!this.actionEmployees) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.actionEmployees, this.gridState.state);
  }

  public validateEmployee(actionEmployees: Employee[]): void {
    const selectedEmployees: Employee[] = _.filter(actionEmployees, (employee: Employee) => {
      employee.EmployeePayRateComponent = this.massPayRateAdjustmentMapService.mapPayRateComponentDto(employee.EmpRateComponent);
      if (!employee.LastApprovedDate || moment(this.effectiveDate).isAfter(employee.LastApprovedDate,'day') ){
        employee.Selectable = true;
      }
      else{
        employee.Selectable = false;
        employee.isSelected = false;
        employee.Tooltip = `this employee is not eligible for mass rate adjustment as the timecard is already approved for the selected date.`;
      } 
      return employee;

    });
    this.actionEmployees = selectedEmployees;
  }

  public get canPreview(): boolean {
    if(this.selectedEmployees && this.selectedEmployees.length > 0)
    {
    this.selectedEmployees = this.selectedEmployees.filter(e=>e.isSelected);
    return this.selectedEmployees.length >0;
    }
    return false;
    }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
  }
  
  public onChangeEffectiveDate(date: Date): void {
    if (_.isDate(date)) {
      this.effectiveDate = date;
      this.validateEmployee(this.actionEmployees);
      this.refreshGrid();
    }
  }

  public async loadPreviewGrid() {
    await this.groupActivitiesService.getEmployeesWithPayRate(this.orgLevel.id, this.selectedEmployees)
      .then((actionwithRateComponent: PayRateDefenitions) => {
        this.empRateComponent = actionwithRateComponent;
        this.isRatecomponentenabled = actionwithRateComponent.isEnableRateComponent;
        this.isLoading = false;
        this.refreshGrid();
      });
  }

  public static openDialog(
    modalService: ModalService, actionDefinition: Provider[],
    callback: (result: boolean) => void
  ): MassRateAdjustmentsDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.height = 400;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions,
      }
    ];

    resolvedProviders = [...resolvedProviders, ...actionDefinition];
    let dialog: MassRateAdjustmentsDialogComponent = modalService.globalAnchor.openDialog2(
      MassRateAdjustmentsDialogComponent,
      'Mass Pay Rate Adjustment',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

  public onisincrementTypeChanged(enable: boolean): void {
    if (enable) {
      this.incrementType = 'FlatRate';
    }
    else {
      this.incrementType = 'Percentage';
    }
  }

  public async PreviewDialog() {
    if (!this.isPreviewState){
      this.isLoading = true;
      await this.loadPreviewGrid();
      this.retreiveMasspayrateDefinitions(this.selectedEmployees);
      this.buttonChanges();
      this.refreshPreviewGrid();
    }
    else {
      this.adjustedEmployees.empMassPayRateRecords = this.adjustedMassRates;
      this.adjustedEmployees.increment = this.increment;
      this.adjustedEmployees.incrementType = this.incrementType;
      if(!_.isNil(this.increaseReason))
      this.adjustedEmployees.increaseReason = this.increaseReason.id;
      this.adjustedEmployees.effectiveDate = moment(this.effectiveDate).format(appConfig.dateTimeFormatUS);
      this.saveRecords();
    }
  }

  public buttonChanges(): void {
    if (!this.isPreviewState){
      this.isPreviewState = true;
    }
    else {
      this.isPreviewState = false;
     
    }
  }

  public cancel(): void {
    if (!this.isPreviewState){
      this.dialogResult = false;
      this.modalService.closeWindow(this.options.windowUniqueId);
    }
    else {
      this.buttonChanges();
    }
  }

  public retreiveMasspayrateDefinitions(data: Employee[]): void {
    this.adjustedMassRates = _.map(data, v => this.massPayRateDefinitionChanges(v));
  }

  public massPayRateDefinitionChanges(data: Employee): MassRateAdjustmentsDefenition {
    const effectiveDate = moment(this.effectiveDate).format(appConfig.dateTimeFormatUS);
   
    const dt = new MassRateAdjustmentsDefenition();
    dt.empId = data.EmpId;
    dt.empName = data.EmpName;
    dt.incrementType = this.incrementType;
    dt.increment = this.increment;
    dt.effectiveDate = effectiveDate;
    dt.department = data.EmpDepartment;
    dt.departmentID = data.EmpDepartmentId;
    dt.position = data.JobDescription;
    dt.positionID = data.JobCode;
    dt.union = data.EmpUnion;
    dt.hourlyRate = _.round(data.HourlyRate, this.incrementdecimal);
    const { payRateComp, rate } = this.getPayRatewithCalculation(data.EmpId, data.HourlyRate);
    dt.payRateComponents = payRateComp;
    dt.revisedHourlyRate = rate;

    return dt;
  }

  private refreshPreviewGrid(): void {
    if (!this.adjustedMassRates) {
      this.previewgridState.view = null;
      return;
    }
    this.previewgridState.view = process(this.adjustedMassRates, this.previewgridState.state);
  }


  public getPayRatewithCalculation(empid: number, rate: number): { payRateComp: EmployeePayRateComponent[], rate: number } {
    const payrates = _.find(this.empRateComponent.empMassPayRateRecords, (p) => p.empId === empid);
    const { RateComp, payrate } = this.calculatePayRate(payrates.payRateComponents, rate);
    return { payRateComp: RateComp, rate: payrate };
  }

  public calculatePayRate(payRatesRecords: EmployeePayRateComponent[], payrate: number): { RateComp: EmployeePayRateComponent[], payrate: number } {
    const newpayrate: EmployeePayRateComponent[] = _.cloneDeep(payRatesRecords);
    if (this.incrementType == 'FlatRate') {
      _.forEach(newpayrate, (rec: EmployeePayRateComponent) => {
        if (rec.component == 'Base Rate' || rec.component == 'Base') {
          rec.rate = _.sumBy([rec.rate, this.increment])
        }
      });
    }
    else {
      _.forEach(newpayrate, (rec: EmployeePayRateComponent) => {
        if ((rec.component == 'Base Rate' || rec.component == 'Base') && this.increment != 0 && rec.rate != 0) {
          rec.rate = _.round(_.sumBy([rec.rate, _.multiply(rec.rate, _.divide(this.increment, 100))]), this.incrementdecimal);
        }
      });
    }
    return { RateComp: newpayrate, payrate: _.round(_.sumBy(newpayrate, (value: EmployeePayRateComponent) => value.rate || 0), this.incrementdecimal) };
  }

  public saveRecords() {
    this.isLoading = true;
    this.groupActivitiesService.adjustEmployeesMassrate(this.adjustedEmployees)
      .then(() => {
        this.notificationsService.success('Mass Pay Rate', 'Mass Pay Rate Adjusted successfully');
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}
