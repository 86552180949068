/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../employee-dependent-benefits/employee-dependent-benefits.component.ngfactory";
import * as i2 from "../employee-dependent-benefits/employee-dependent-benefits.component";
import * as i3 from "../../../../../common/models/benefits/benefits-employee-dependents-enrollment";
import * as i4 from "../../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i5 from "./employee-dependent-benefits-dialog.component";
import * as i6 from "../../../../../common/models/dialog-options";
import * as i7 from "../../../../../common/services/modal/modal.service";
import * as i8 from "../../../models/employee-sections-benefits-management/employee-dependent-benefits-options";
var styles_EmployeeDependentBenefitsDialogComponent = [];
var RenderType_EmployeeDependentBenefitsDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeDependentBenefitsDialogComponent, data: {} });
export { RenderType_EmployeeDependentBenefitsDialogComponent as RenderType_EmployeeDependentBenefitsDialogComponent };
export function View_EmployeeDependentBenefitsDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "slx-employee-dependent-benefits", [], null, [[null, "selectBenefits"], [null, "enrollmentDone"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectBenefits" === en)) {
        var pd_0 = (_co.onSelectBenefits($event) !== false);
        ad = (pd_0 && ad);
    } if (("enrollmentDone" === en)) {
        var pd_1 = (_co.onCompleteEnrollment($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_EmployeeDependentBenefitsComponent_0, i1.RenderType_EmployeeDependentBenefitsComponent)), i0.ɵdid(2, 245760, null, 0, i2.EmployeeDependentBenefitsComponent, [i3.BENEFITS_EMPLOYEE_DEPENDENTS_ENROLLMENT, i4.NotificationsService, i0.ChangeDetectorRef], { options: [0, "options"], dependentsEnrollments: [1, "dependentsEnrollments"] }, { selectBenefits: "selectBenefits", enrollmentDone: "enrollmentDone" }), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEnroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; var currVal_1 = _co.dependentsEnrollments; _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = !_co.canEnroll; _ck(_v, 4, 0, currVal_2); var currVal_3 = !_co.canCancel; _ck(_v, 7, 0, currVal_3); }); }
export function View_EmployeeDependentBenefitsDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-employee-dependent-benefits-dialog", [], null, null, null, View_EmployeeDependentBenefitsDialogComponent_0, RenderType_EmployeeDependentBenefitsDialogComponent)), i0.ɵdid(1, 49152, null, 0, i5.EmployeeDependentBenefitsDialogComponent, [i6.DialogOptions2, i7.ModalService, i8.EmployeeDependentBenefitsOptions], null, null)], null, null); }
var EmployeeDependentBenefitsDialogComponentNgFactory = i0.ɵccf("slx-employee-dependent-benefits-dialog", i5.EmployeeDependentBenefitsDialogComponent, View_EmployeeDependentBenefitsDialogComponent_Host_0, {}, {}, []);
export { EmployeeDependentBenefitsDialogComponentNgFactory as EmployeeDependentBenefitsDialogComponentNgFactory };
