import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { SidebarActions } from '../../actions/index';
import { scheduleMicrotask } from '../../../core/utils/index';
var HeaderMenuButtonComponent = /** @class */ (function () {
    function HeaderMenuButtonComponent(sidebarActions) {
        this.sidebarActions = sidebarActions;
        this.state = {
            isRightSidebarOpen: false,
            application: null
        };
    }
    HeaderMenuButtonComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isRightSidebarOpenSubscripion = this.isRightSidebarOpen.subscribe(function (value) {
            scheduleMicrotask(function () { _this.state.isRightSidebarOpen = value; });
        });
        this.applicationSubscripion = this.application$.subscribe(function (value) {
            scheduleMicrotask(function () { _this.state.application = value; });
        });
    };
    HeaderMenuButtonComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    HeaderMenuButtonComponent.prototype.toggleRightSidebar = function () {
        this.sidebarActions.setRightSidebar(!this.state.isRightSidebarOpen);
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isRightSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], HeaderMenuButtonComponent.prototype, "isRightSidebarOpen", void 0);
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], HeaderMenuButtonComponent.prototype, "application$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderMenuButtonComponent.prototype, "isRightSidebarOpenSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderMenuButtonComponent.prototype, "applicationSubscripion", void 0);
    return HeaderMenuButtonComponent;
}());
export { HeaderMenuButtonComponent };
