<slx-spinner [show]="state.isLoading" novalidate>
  <div class="main-container">
    <div class="sections">

      <form #form="ngForm" class="row-form row-item">
        <slx-input-decorator class="element">
          <slx-date-picker-ngx slx-input
                               placeholder="Effective Date"
                               name="effectiveDate"
                               [acceptNullDate]="false"
                               [maxDate]="maxDate"
                               [required]="true"
                               [(ngModel)]="effectiveDate"
                               (ngModelChange)="onChangeEffectiveDate($event)"></slx-date-picker-ngx>
          <span errorMessage for="required"></span>
          <span errorMessage for="date"></span>
          <span errorMessage for="maxDate">Date is invalid or out of valid range</span>
        </slx-input-decorator>


        <div *ngIf="allowDynamicPolicy && effectiveDateSelected" class="element_multipolicy pt25">
          <span>{{ policyNameList }}</span>
          <span class="pull-right fs30 pl10 mt-7" (click)="openDynamicPolicyDialog()">
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
          </span>
        </div>
        <div class="element" *ngIf="!allowDynamicPolicy">
          <slx-input-decorator >
            <slx-dropdown-input slx-input [options]="accrualPolicyLookup" valueField="id" titleField="policyName"
                                name="accrualPolicyInput" [required]="true" [ngModel]="policyName" (ngModelChange)="updateSelectedPolicy($event)" placeholder="Accrual Policy">
            </slx-dropdown-input>
            <span errorMessage for="required">Policy selection required</span>
            <span errorMessage *ngIf="!anyPolicySelected">At least one policy should be selected</span>
          </slx-input-decorator>
        </div>


        <div class="spacer"></div>

        <div class="buttons">
          <button type="button" class="theme-button-apply margin-r" (click)="confirmAssignPolicy()" [disabled]="!form.valid || !canSend || !anyPolicySelected">Assign</button>
          <button type="button" class="theme-button-cancel" (click)="cancel()">Cancel</button>
        </div>

      </form>

      <div class="slx-error-block policy-message">
          
      </div>

      <div class="section slx-accrual-policy">
        <slx-employee-grid [fieldDefinition]="fieldDefinition" [employees]="actionEmployees" [isMultiselect]="true" (onEmployeesSelect)="employeesSelect($event)"></slx-employee-grid>
      </div>
  </div>
</div>
</slx-spinner>
