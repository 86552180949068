import { IBaseSelectableModel } from '../../../common';

export class TelepunchLocation implements IBaseSelectableModel {
    public id: number;
    public locationId: number;
    public locationName: string;
    public phone: string;
    public authorized: boolean;
    public date: Date;
    public userName: string;

    public isSelected: boolean;
    public selectable: boolean;
    public isDirty: boolean;
}

export interface ITelepunchLocation {
    id: number;
    locationId: number;
    locationName: string;
    phone: string;
    authorized: number;
    lastUpdateDate: string;
    lastUpdateUserName: string;
}
