import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';

import { appConfig, IApplicationConfig } from '../../../../../../../app/app.config';
import {
  IDialog,
  DialogOptions2,
  DialogModeSize,
  ModalService,
  ConfirmDialog2Component,
  ConfirmOptions
} from '../../../../../../../app/common/index';

import { EmployeeWarningBasic, EmployeeWarningExtended } from '../../../../models/index';

@Component({
  selector: 'slx-employee-sections-warnings-dialog',
  templateUrl: './employee-sections-warnings-dialog.component.html',
  styleUrls: ['./employee-sections-warnings-dialog.component.scss']
})
export class EmployeeSectionsWarningsDialogComponent implements IDialog {
  public dialogResult: boolean = null;
  public appConfig: IApplicationConfig = appConfig;

  public warningExtended: EmployeeWarningExtended;

  public get canSave(): boolean {
    return _.isObjectLike(this.warningExtended);
  }

  private showConfirmation = false;

  public static openDialog(
    warning: EmployeeWarningBasic,
    modalService: ModalService,
    callback?: (result: boolean) => void
  ): EmployeeSectionsWarningsDialogComponent {
    const dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.fullHeightOnMobile = true;
    const resolvedProviders: Array<Provider> = [
      {
        provide: EmployeeWarningBasic,
        useValue: warning
      },
      {
        provide: DialogOptions2,
        useValue: dialogOptions
      }
    ];

    return modalService.globalAnchor.openDialog2(
        EmployeeSectionsWarningsDialogComponent,
        'Employee Warning Record',
        dialogOptions,
        resolvedProviders,
        (res: boolean) => {
          if (_.isFunction(callback)) {
            callback(res);
          }
        }
      );
  }

  constructor(
    public warning: EmployeeWarningBasic,
    private readonly dialogOptions: DialogOptions2,
    private readonly modalService: ModalService
  ) {}

  public onChangedWarning(warning: EmployeeWarningExtended): void {
    this.warningExtended = warning;
  }

  public onEditedWarning(hasChanges: boolean): void {
    this.showConfirmation = hasChanges;
  }

  public onUserAction(isSave: boolean): void {
    if (isSave || !this.showConfirmation) {
      this.closeWarning(isSave);
    }  else {
      this.showConfirmationPopup();
    }
  }

  public closeWarning(isSave: boolean): void {
    this.dialogResult = isSave;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public showConfirmationPopup(): void {
    const options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'Cancel Editing',
      'Are you sure that you want to cancel editing? You will lose all unsaved changes',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.closeWarning(false);
        }
      },
      options
    );
  }

}
