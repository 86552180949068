import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { KendoGridStateHelper, ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { EmployeeSubsectionDependents } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import { BenefitEmployeeDependent } from '../../../../../app-modules/benefits/models/index';
var EmployeeSectionsDependentsGridComponent = /** @class */ (function () {
    function EmployeeSectionsDependentsGridComponent(modalService, manService, changeDetector, document) {
        this.modalService = modalService;
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.document = document;
        this.pageSize = 5;
        this.dependents = [];
        this.globalEditMode = false;
        this.pattern = /[^\w\s]|[_]/gi;
        this.subscriptions = {};
        this.dependentSection = new EmployeeSubsectionDependents();
        this.selectedDependents = new Map();
        this.gridState = new KendoGridStateHelper();
        this.appConfig = appConfig;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: 'firstName', dir: 'asc' }];
        this.maxDate = moment().startOf('day').toDate();
    }
    Object.defineProperty(EmployeeSectionsDependentsGridComponent.prototype, "isEditMode", {
        get: function () {
            return _.isObjectLike(this.editedDependent);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsGridComponent.prototype, "canCreate", {
        get: function () {
            return this.dependentSection.canAddDependents && !this.isEditMode && !this.globalEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsGridComponent.prototype, "canEdit", {
        get: function () {
            return this.dependentSection.canEditDependents && !this.isEditMode && !this.globalEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsGridComponent.prototype, "canDelete", {
        get: function () {
            return this.dependentSection.canRemoveDependents && !this.isEditMode && !this.globalEditMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsGridComponent.prototype, "canEnroll", {
        get: function () {
            return this.dependentSection.canEnrollDependents && this.selectedDependents.size > 0 && !this.isEditMode;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsDependentsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.dependents = this.manService
            .subscribeToDependentsSubsection(function (sec) {
            _this.dependentSection = sec;
            _this.updateDependents();
        });
        this.subscriptions.genderLookup = this.manService
            .subscribeToGenderLookup(function (lookup) { return (_this.genderLookup = lookup); });
        this.subscriptions.stateLookup = this.manService
            .subscribeToStateLookup(function (lookup) { return (_this.stateLookup = lookup); });
        this.subscriptions.empId = this.manService
            .subscribeToEmployeeId(function (empId) {
            _this.employeeId = empId;
        });
        this.subscriptions.changeDep = this.manService
            .subscribeToChangedDependent(function (data) {
            var dep = data.dependent;
            var index = _.findIndex(_this.dependentSection.dependents, function (d) { return d.id === dep.id; });
            if (data.isCreated) {
                _this.dependentSection.dependents.push(dep);
            }
            else if (data.isEdited) {
                _this.dependentSection.dependents.splice(index, 1, dep);
            }
            else {
                _this.dependentSection.dependents.splice(index, 1);
            }
            _this.toggleEditMode(null);
            _this.updateDependents();
        });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () {
            _this.updateDependents();
        });
        this.subscriptions.gridSave = this.gridState.onSave$
            .subscribe(function (event) {
            if (event.isNew) {
                _this.manService.createDependent(event.dataItem);
                _this.toggleEditMode(null);
                _this.manService.toggleEditMode(false);
                return;
            }
            _this.manService.editDependent(event.dataItem);
            _this.toggleEditMode(null);
            _this.manService.toggleEditMode(false);
        });
        this.subscriptions.gridRemove = this.gridState.onRemove$
            .subscribe(function (event) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            options.buttonOKtext = 'Yes';
            ConfirmDialog2Component.openDialog('Delete Dependent', 'Are you sure you want to delete this dependent?', _this.modalService, function (isDelete) {
                if (isDelete) {
                    _this.manService.deleteDependent(event.dataItem);
                }
            }, options);
        });
        this.subscriptions.gridEdit = this.gridState.onEdit$
            .subscribe(function (dependent) {
            _this.manService.toggleEditMode(true);
            _this.toggleEditMode(dependent);
        });
        this.subscriptions.gridCancel = this.gridState.onCancel$
            .subscribe(function () {
            _this.manService.toggleEditMode(false);
            _this.toggleEditMode(null);
        });
        this.subscriptions.globalEditing = this.manService
            .subscribeToEditMode(function (isEdit) {
            _this.globalEditMode = isEdit;
            _this.updateDependents();
        });
        this.subscriptions.enrollments = this.manService
            .subscribeToEnrollmentsQuantity(function (_a) {
            var id = _a.id, quantity = _a.quantity;
            if (quantity === 0) {
                _this.markDependentsAsHasNoBenefits(id);
            }
        });
        this.subscriptions.reset = this.manService
            .subscribeToReset(function () {
            _.times(_.size(_this.gridState.view.data), function (i) { return _this.gridDependents.collapseRow(i); });
            _this.gridState.closeEditor(_this.gridDependents);
            _this.toggleEditMode(null);
            _this.updateDependents();
        });
    };
    EmployeeSectionsDependentsGridComponent.prototype.ngOnDestroy = function () {
        this.dependentSection = new EmployeeSubsectionDependents();
    };
    EmployeeSectionsDependentsGridComponent.prototype.isShownAddress = function () {
        var _this = this;
        return _.some(this.dependentSection.dependents, function (dep) { return _.size(_this.getDependentAddress(dep)) > 0; });
    };
    EmployeeSectionsDependentsGridComponent.prototype.isEditingRow = function (dep) {
        return _.isObjectLike(this.editedDependent) && _.isObjectLike(dep) && this.editedDependent.id === dep.id;
    };
    EmployeeSectionsDependentsGridComponent.prototype.isCheckedEmp = function (dep) {
        return this.selectedDependents.has(dep.id);
    };
    EmployeeSectionsDependentsGridComponent.prototype.getDependentAddress = function (dep) {
        return _.isString(dep.address) && _.size(dep.address) > 0
            && (_.isNil(dep.street) && _.isNil(dep.city) && _.isNil(dep.state) && _.isNil(dep.zipcode)) ? dep.address : '';
    };
    EmployeeSectionsDependentsGridComponent.prototype.onAddNewDependent = function () {
        var dependent = new BenefitEmployeeDependent();
        dependent.id = null;
        dependent.employeeId = this.employeeId;
        dependent.birthDate = null;
        dependent.relation = null;
        dependent.gender = null;
        this.toggleEditMode(dependent);
        this.gridState.closeEditor(this.gridDependents);
        this.gridDependents.addRow(this.editedDependent);
        this.onSelectAll(true);
    };
    EmployeeSectionsDependentsGridComponent.prototype.onSelectDependentsBenefits = function (depsEnrollments) {
        this.manService.updateDependentsEnrollments(depsEnrollments.dependents);
        this.onSelectAll(true);
        this.markDependentsAsHasBenefits(depsEnrollments.dependents);
        this.updateDependents();
    };
    EmployeeSectionsDependentsGridComponent.prototype.onDateChanged = function () {
        var _this = this;
        if (_.isObjectLike(this.subscriptions.dateChanged)) {
            this.subscriptions.dateChanged.unsubscribe();
        }
        this.subscriptions.dateChanged = Observable.of(true)
            .delay(300)
            .subscribe(function () { return _this.changeDetector.markForCheck(); });
    };
    EmployeeSectionsDependentsGridComponent.prototype.onSelectAll = function (clearSelection) {
        var _this = this;
        if (this.isCheckedAll || clearSelection) {
            this.selectedDependents.clear();
            this.isCheckedAll = false;
        }
        else {
            _.forEach(this.filteredRecords, function (e) {
                _this.selectedDependents.set(e.id, e);
            });
            this.isCheckedAll = true;
        }
        this.updateSelectedDependents();
    };
    EmployeeSectionsDependentsGridComponent.prototype.onSelectSingle = function (dep) {
        if (this.selectedDependents.has(dep.id)) {
            this.selectedDependents.delete(dep.id);
            this.isCheckedAll = false;
        }
        else {
            this.selectedDependents.set(dep.id, dep);
            if (_.size(this.filteredRecords) === this.selectedDependents.size) {
                this.isCheckedAll = true;
            }
        }
        this.updateSelectedDependents();
    };
    EmployeeSectionsDependentsGridComponent.prototype.markDependentsAsHasBenefits = function (dependents) {
        _.forEach(this.dependentSection.dependents, function (dep) {
            var d = _.find(dependents, { id: dep.id });
            if (_.isObjectLike(d)) {
                dep.hasBenefits = true;
            }
        });
    };
    EmployeeSectionsDependentsGridComponent.prototype.markDependentsAsHasNoBenefits = function (id) {
        var dep = _.find(this.dependentSection.dependents, { id: id });
        if (_.isObjectLike(dep)) {
            dep.hasBenefits = false;
            this.updateDependents();
        }
    };
    EmployeeSectionsDependentsGridComponent.prototype.updateSelectedDependents = function () {
        this.dependents = Array.from(this.selectedDependents.values());
    };
    EmployeeSectionsDependentsGridComponent.prototype.updateDependents = function () {
        this.refreshGrid();
        this.changeDetector.markForCheck();
    };
    EmployeeSectionsDependentsGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.dependentSection) {
            return;
        }
        this.filteredRecords = process(this.dependentSection.dependents, { filter: this.gridState.state.filter, sort: this.gridState.state.sort }).data;
        this.gridState.view = process(this.dependentSection.dependents, this.gridState.state);
        setTimeout(function () {
            var pendoElements = _this.document.querySelectorAll('*[id^="_pendo-badge_"]');
            pendoElements.forEach(function (element) {
                if (element.hasAttribute('data-layout') && element.getAttribute('data-layout') === "badgeBlank") {
                    element.style["zIndex"] = "99";
                }
            });
        }, 2000);
    };
    EmployeeSectionsDependentsGridComponent.prototype.toggleEditMode = function (dep) {
        this.editedDependent = dep;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsDependentsGridComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsDependentsGridComponent;
}());
export { EmployeeSectionsDependentsGridComponent };
