import { Component, OnInit } from '@angular/core';
import { destroyService } from './../../../../app/core/decorators';
import { ShiftGroupManagementService } from '../../services/shift-group/shift-group-management.service';

@Component({
  selector: 'slx-shift-group',
  templateUrl: './shift-group.component.html',
  styleUrls: ['./shift-group.component.scss']
})
export class ShiftGroupComponent implements OnInit {

  @destroyService()
  private management: ShiftGroupManagementService;

  constructor(management: ShiftGroupManagementService) {
    this.management = management;
  }

  ngOnInit() {
    this.management.init();
  }

}
