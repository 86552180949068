import { Router, ActivatedRoute, UrlTree, NavigationExtras, Params } from '@angular/router';
import { appConfig, IApplicationConfig } from '../../../app.config';
import * as moment from 'moment';

export class ApprovalOvertimesNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToOvertimeApproval(startDate: Date, endDate: Date, empIds: number[] = null, orgLevelId: number = 0): void {
    let params: Params = {
        startDate: moment(startDate).format(appConfig.linkDateFormat),
        endDate: moment(endDate).format(appConfig.linkDateFormat)
      };
    if (empIds && empIds.length > 0) {
      params.empIds = empIds;
    }

    if (orgLevelId > 0) {
      params.orgLevelId = orgLevelId;
    }
    this.router.navigate(['overtime_approvals'], {
      relativeTo: this.route.pathFromRoot[2],
      queryParams: params
    });
  }
}
