export * from './employee-terminate/employee-terminate.component';
export * from './employee-terminate-dialog/employee-terminate-dialog.component';

export * from './change-termination-date/change-termination-date.component';
export * from './change-termination-date-dialog/change-termination-date-dialog.component';

import { EmployeeTerminateComponent } from './employee-terminate/employee-terminate.component';
import { EmployeeTerminateDialogComponent } from './employee-terminate-dialog/employee-terminate-dialog.component';

import { ChangeTerminationDateComponent } from './change-termination-date/change-termination-date.component';
import { ChangeTerminationDateDialogComponent } from './change-termination-date-dialog/change-termination-date-dialog.component';


export const employeeTerminateComponents: any[] = [
  EmployeeTerminateComponent,
  EmployeeTerminateDialogComponent,
  ChangeTerminationDateComponent,
  ChangeTerminationDateDialogComponent
];
