<slx-spinner [show]="isLoading">
  <div class="slx-high-box benefit-class-configuration">
    <div class="slx-content-toolbar-indents">
       <div class="benefit-class-toolbar__left">
        <button type="button" class="slx-button slx-blue slx-with-icon" (click)="addClass()">
          <i aria-hidden="true" class="slx-button__icon fal fa-plus"></i>Add
        </button>
       </div>
       <slx-blue-columns-config-button [colGroupKey]="columnGroup" title="Benefit Class Columns" ></slx-blue-columns-config-button>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
        <form #form="ngForm" novalidate class="slx-full-height">
          <kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height"
            slxKendoGridState="BENEFIT_CLASS_CONFIGURATION"
            [slxKendoGridColumnsGroup]="columnGroup"
            [data]="gridState?.view"
            [filterable]="'menu'"
            [sortable]="{ mode: 'multiple' }"
            [filter]="gridState.state.filter"
            [skip]="gridState.state.skip"
            [sort]="gridState.state.sort"
            [pageable]="true"
            [pageSize]="pageSize"
            (cancel)="gridState.cancelHandler($event)"
            (edit)="gridState.editHandler($event)"
            (remove)="gridState.removeHandler($event)"
            (save)="gridState.saveHandler($event)"
            (dataStateChange)="gridState.dataStateChange($event)">

            <kendo-grid-column class="slx-no-padding" [media]="screenUtils.getKendoMediaForMobile()">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="slx-mobile-expandable slx-blue">
                 <div><span class="slx-mobile-expandable__header">{{ dataItem.benefitClassDefinition?.description }}</span> <span class="slx-mobile-expandable__header-additional">({{dataItem.benefitClassDefinition?.name }})</span></div>
                 <slx-collapse-button className="slx-white-icon" [slxCollapseByCss]="benefitClassCellContent"></slx-collapse-button>
                </div>
                <div #benefitClassCellContent class="slx-mobile-expandable-content">
                  <div class="slx-mobile-expandable-content__buttons">
                    <button kendoGridEditCommand type="button" *ngIf="dataItem.canEdit" class="slx-inline-edit-button2">
                      <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
                    </button>
                    <button kendoGridRemoveCommand *ngIf="dataItem.canEdit" class="slx-icon-button slx-inline-remove-button2">
                      <i class="fas fa-trash slx-inline-edit-button__icon" aria-hidden="true"></i>
                    </button>
                    <i *ngIf="dataItem.orgLevelDirectionForEdit > 0" class="fas fa-arrow-square-up edit-direction-icon" aria-hidden="true"></i>
                    <i *ngIf="dataItem.orgLevelDirectionForEdit < 0" class="fas fa-arrow-square-down edit-direction-icon" aria-hidden="true"></i>
                  </div>
                  <div>
                      <div class="type-value">
                          <span class="type-value-header">Org Level:</span> {{ dataItem.orgLevelName }}
                        </div>

                    <div class="type-value">
                      <span class="type-value-header">Benefit Class:</span> {{ dataItem.benefitClassDefinition?.name }}
                    </div>
                    <div class="type-value">
                      <span class="type-value-header">Description:</span> {{ dataItem.benefitClassDefinition?.description }}
                    </div>
                    <div class="type-value">
                      <span class="type-value-header">Code:</span> {{ dataItem.benefitClassDefinition?.code }}
                    </div>

                    <div class="type-value">
                      <span class="type-value-header">Modified by:</span> {{ dataItem.modifiedBy }}
                    </div>
                    <div class="type-value">
                      <span class="type-value-header">Modified Date:</span> {{ dataItem.modifiedOn | amDateFormat: appConfig.dateFormat  }}
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <div class="slx-mobile-expandable">
                    <div><span class="slx-mobile-expandable__header">{{ dataItem.benefitClassDefinition?.description }}</span> <span class="slx-mobile-expandable__header-additional">({{ dataItem.benefitClassDefinition?.name }})</span></div>
                    <slx-collapse-button className="slx-white-icon" [slxCollapseByCss]="benefitClassEditCellContent"></slx-collapse-button>
                  </div>
                  <div #benefitClassEditCellContent class="slx-mobile-expandable-content">
                    <div class="slx-mobile-expandable-content__buttons">
                      <button kendoGridSaveCommand type="button" [disabled]="!form.valid" class="slx-icon-button slx-inline-apply-button2">
                        <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
                      </button>
                      <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
                        <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div>
                      <slx-input-decorator>
                        <input slx-input required="true" name="name" placeholder="Name" [(ngModel)]="dataItem.benefitClassDefinition.name" slxMaxLength="30" slxMinLength="4">
                        <span errorMessage for="required"></span>
                        <span errorMessage for="slxMaxLength">Name should be a maximum length of '30'</span>
                        <span errorMessage for="slxMinLength">Name should be a minimun length of '4'</span>
                        <span errorMessage [for]="nameValidation"></span>
                      </slx-input-decorator>
                      <slx-input-decorator>
                        <input slx-input required="true" name="description" placeholder="Description" [(ngModel)]="dataItem.benefitClassDefinition.description" slxMaxLength="100">
                        <span errorMessage for="required"></span>
                        <span errorMessage for="slxMaxLength">Description should be a maximum length of '100'</span>
                      </slx-input-decorator>
                      <slx-input-decorator>
                          <input slx-input required="true" name="code" placeholder="Code" [(ngModel)]="dataItem.benefitClassDefinition.code" slxMaxLength="30">
                          <span errorMessage for="required"></span>
                          <span errorMessage for="slxMaxLength">Code should be a maximum length of '30'</span>
                          <span errorMessage [for]="codeValidation"></span>
                      </slx-input-decorator>

                      <div class="type-value">
                          <span class="type-value-header">Modified by:</span> {{ dataItem.modifiedBy }}
                        </div>
                        <div class="type-value">
                          <span class="type-value-header">Modified Date:</span> {{ dataItem.modifiedOn | amDateFormat: appConfig.dateFormat  }}
                        </div>
                    </div>
                  </div>
                </ng-template>
            </kendo-grid-column>


            <kendo-grid-command-column width="100" [media]="screenUtils.getKendoMediaForDesktop()">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="inline-buttons">
                  <button kendoGridEditCommand type="button" *ngIf="dataItem.canEdit" class="slx-inline-edit-button2">
                    <i class="fas fa-edit slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridRemoveCommand *ngIf="dataItem.canEdit" class="slx-icon-button slx-inline-remove-button2">
                    <i class="fas fa-trash slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridSaveCommand type="button" [disabled]="!form.valid" class="slx-icon-button slx-inline-apply-button2">
                    <i class="fas fa-check slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <button kendoGridCancelCommand type="button" class="slx-icon-button slx-inline-cancel-button2">
                    <i class="fa fa-times slx-inline-edit-button__icon" aria-hidden="true"></i>
                  </button>
                  <i *ngIf="dataItem.orgLevelDirectionForEdit > 0" class="fas fa-arrow-square-up edit-direction-icon" aria-hidden="true"></i>
                  <i *ngIf="dataItem.orgLevelDirectionForEdit < 0" class="fas fa-arrow-square-down edit-direction-icon" aria-hidden="true"></i>
                </div>
              </ng-template>
            </kendo-grid-command-column>

            <kendo-grid-column title="Org Level" width="150" field="orgLevelName" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Org Level</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.orgLevelName }}
                </ng-template>

                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  {{ dataItem.orgLevelName }}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Benefit Class" width="150" field="benefitClassDefinition.name" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
              <ng-template kendoGridHeaderTemplate>
                <span slxKendoGridHeaderTooltip>Benefit Class</span>
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.benefitClassDefinition.name }}
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                  <slx-input-decorator>
                      <input slx-input required="true" name="name" placeholder="Name" [(ngModel)]="dataItem.benefitClassDefinition.name" slxMaxLength="30" slxMinLength="4">
                      <span errorMessage for="required"></span>
                      <span errorMessage for="slxMaxLength">Name should be a maximum length of '30'</span>
                      <span errorMessage for="slxMinLength">Name should be a minimun length of '4'</span>
                      <span errorMessage [for]="nameValidation"></span>
                    </slx-input-decorator>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Benefit Class Description" width="200" field="benefitClassDefinition.description" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
                <ng-template kendoGridHeaderTemplate>
                  <span slxKendoGridHeaderTooltip>Benefit Class Description</span>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.benefitClassDefinition.description }}
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                    <slx-input-decorator>
                        <input slx-input required="true" name="description" placeholder="Description" [(ngModel)]="dataItem.benefitClassDefinition.description" slxMaxLength="100">
                        <span errorMessage for="required"></span>
                        <span errorMessage for="slxMaxLength">Description should be a maximum length of '100'</span>
                      </slx-input-decorator>
                  </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="Benefit Class Code" width="200" field="benefitClassDefinition.code" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
                  <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Benefit Class Code</span>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    {{ dataItem.benefitClassDefinition.code }}
                  </ng-template>
                  <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                      <slx-input-decorator>
                          <input slx-input required="true" name="code" placeholder="Code" [(ngModel)]="dataItem.benefitClassDefinition.code" slxMaxLength="30">
                          <span errorMessage for="required"></span>
                          <span errorMessage for="slxMaxLength">Code should be a maximum length of '30'</span>
                          <span errorMessage [for]="codeValidation"></span>
                        </slx-input-decorator>
                    </ng-template>
                </kendo-grid-column>

              <kendo-grid-column title="Modified by" width="200" field="modifiedBy" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" [sortable]="true">
                  <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Modified by</span>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    {{ dataItem.modifiedBy }}
                 </ng-template>
              </kendo-grid-column>
              <kendo-grid-column title="Modified Date" width="200" field="modifiedOn" slxKendoGridColumnHiddenState [media]="screenUtils.getKendoMediaForDesktop()" filter="date" [sortable]="true">
                  <ng-template kendoGridHeaderTemplate>
                    <span slxKendoGridHeaderTooltip>Modified Date</span>
                  </ng-template>
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    {{ dataItem.modifiedOn | amDateFormat: appConfig.dateFormat  }}
                 </ng-template>
              </kendo-grid-column>
          </kendo-grid>
        </form>
    </div>
</div>
</slx-spinner>
