import { ScrollWatchService, SelectableItemsProducer, ModalService, DialogOptions } from '../../../../common/index';
import { Assert } from '../../../../framework/index';
import { orderBy } from '@progress/kendo-data-query';
var EmployeePositionSelectorComponent = /** @class */ (function () {
    function EmployeePositionSelectorComponent(scrollWatchService, selectableItemsProducer, options, modalService) {
        this.sort = [];
        Assert.isNotNull(selectableItemsProducer, 'selectableItemsProducer');
        this.selectableItemsProducer = selectableItemsProducer;
        Assert.isNotNull(options, 'options');
        this.options = options;
        Assert.isNotNull(modalService, 'modalService');
        this.modalService = modalService;
        this.dialogResult = false;
        this.isSingleItemSelectable = false;
    }
    Object.defineProperty(EmployeePositionSelectorComponent.prototype, "isLoading", {
        set: function (loading) {
            if (loading) {
                this.startProgress();
            }
            else {
                this.stopProgress();
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeePositionSelectorComponent.prototype.checkTemporalDirty = function () {
        return false;
    };
    Object.defineProperty(EmployeePositionSelectorComponent.prototype, "selectedItems", {
        get: function () {
            var selectedItems = [];
            this
                .selectableEmployeePositions
                .forEach(function (i) {
                if (i.selected)
                    selectedItems.push(i.item);
            });
            return selectedItems;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeePositionSelectorComponent.prototype, "employeePositions", {
        set: function (employeePositions) {
            if (employeePositions !== null) {
                this.selectableEmployeePositions = this.selectableItemsProducer.produceSelectable(employeePositions);
                this.refreshGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeePositionSelectorComponent.prototype.onItemSelectionChanged = function (value) {
        if (this.isSingleItemSelectable && value.selected) {
            if (this.currentlySelected) {
                this.currentlySelected.selected = false;
            }
            this.currentlySelected = value;
        }
        this.isAnyItemSelected = this
            .selectableEmployeePositions
            .some(function (i) { return i.selected; });
    };
    EmployeePositionSelectorComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeePositionSelectorComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this
            .modalService
            .closeWindow(this.options.windowUniqueId);
    };
    EmployeePositionSelectorComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this
            .modalService
            .closeWindow(this.options.windowUniqueId);
    };
    EmployeePositionSelectorComponent.prototype.startProgress = function () {
        this.isLoaded = true;
    };
    EmployeePositionSelectorComponent.prototype.stopProgress = function () {
        this.isLoaded = false;
    };
    EmployeePositionSelectorComponent.prototype.refreshGrid = function () {
        if (!this.selectableEmployeePositions) {
            this.gridView = null;
            return;
        }
        this.gridView = {
            data: orderBy(this.selectableEmployeePositions, this.sort),
            total: this.selectableEmployeePositions.length
        };
    };
    return EmployeePositionSelectorComponent;
}());
export { EmployeePositionSelectorComponent };
