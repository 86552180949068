import * as tslib_1 from "tslib";
import { OnInit, Provider, ChangeDetectorRef } from '@angular/core';
import 'moment-range';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SortDescriptor, process, State } from '@progress/kendo-data-query';
import { EmployeeDefinition } from '../../../../organization/models/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { ConfirmDialog2Component, ConfirmOptions, DialogOptions } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig } from '../../../../app.config';
import { ScheduleEntryApiService, VacationPlannerApiService } from '../../../services/index';
import { VacationPlan, VacationPlanSettings, VacationPlanEmployeeWeek } from '../../../models/index';
import { VacationEmployeeDialogComponent } from '../vacation-employee-dialog/vacation-employee-dialog.component';
import { ORG_LEVEL_ID_TOKEN } from '../../../../core/models/index';
import { AppSettingsManageService } from '../../../../../app/app-settings/services';
var VacationGridComponent = /** @class */ (function () {
    function VacationGridComponent(changeDetectorRef, modalService, ScheduleEntryApiService, appSettingsManageService, vacationPlannerApiService) {
        this.changeDetectorRef = changeDetectorRef;
        this.ScheduleEntryApiService = ScheduleEntryApiService;
        this.appSettingsManageService = appSettingsManageService;
        this.vacationPlannerApiService = vacationPlannerApiService;
        this.modalService = modalService;
        this.gridState = {
            skip: undefined,
            take: undefined,
            filter: undefined,
            sort: [{ field: 'employee.name', dir: 'asc' }],
            group: undefined
        };
        this.columnsSettings = {};
        this.filterValues = {
            names: [],
        };
    }
    Object.defineProperty(VacationGridComponent.prototype, "vacationPlannerData", {
        set: function (vacationPlannerData) {
            this.gridData = vacationPlannerData;
            if (this.gridData) {
                this.refreshGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationGridComponent.prototype, "settings", {
        set: function (settings) {
            if (!settings) {
                return;
            }
            this.planSettings = settings;
            this.columnsSettings = _.keyBy(settings.columns, function (column) {
                return column.name;
            });
            this.columnsSortSettings = _.keyBy(settings.columns, function (column) {
                return column.sortField;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VacationGridComponent.prototype, "redrawToggler", {
        set: function (value) {
            this.refreshGridSort();
        },
        enumerable: true,
        configurable: true
    });
    VacationGridComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.getSettings();
    };
    VacationGridComponent.prototype.isActive = function (record, week) {
        return record.weeks[week].isActive;
    };
    VacationGridComponent.prototype.onDetailClick = function (record, week) {
        var _this = this;
        if (!this.isActive(record, week)) {
            return;
        }
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 670;
        dialogOptions.width = 500;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            }, {
                provide: EmployeeDefinition,
                useValue: record.employee
            },
            {
                provide: VacationPlanEmployeeWeek,
                useValue: record.weeks[week]
            },
            {
                provide: ORG_LEVEL_ID_TOKEN,
                useValue: this.orgLevel.id
            }
        ];
        var dialog = this.modalService.globalAnchor
            .openDialog(VacationEmployeeDialogComponent, 'PTO Planner', dialogOptions, resolvedProviders, function (result, uniqueId) {
            if (result) {
                var changedRecords_1 = _this.getChangedRecords(dialog.originalRecords, dialog.employeeDetails.records);
                var endDate = moment(record.weeks[week].start).add(6, 'day');
                var finalDate = moment(endDate).format('MM/DD/YYYY');
                var startDate = moment(record.weeks[week].start).format('MM/DD/YYYY');
                _this.ScheduleEntryApiService.checkApprovedPayperiod(String(dialog.employee.id), startDate, finalDate).then(function (isApproved) {
                    _this.approvedPayPeriod = isApproved;
                    if (changedRecords_1.length > 0 && _this.isModifyPayPeriodApproved && _this.approvedPayPeriod == true) {
                        var userPermission = _this.gridData.actions.find(function (x) { return x == 'Edit Schedule for an Approved Pay Period'; });
                        var message = _this.gridData.actions.find(function (x) { return x == 'Edit Schedule for an Approved Pay Period'; }) ?
                            "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the \n                employee, are you sure you want to continue?" : "You do not have permissions to modify a \n                schedule in an approved pay period";
                        var popupOptions = new ConfirmOptions();
                        popupOptions.showCancel = true;
                        popupOptions.showOK = userPermission ? true : false;
                        popupOptions.buttonOKtext = 'Ok';
                        popupOptions.buttonCanceltext = userPermission ? 'Cancel' : 'Ok';
                        ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                            if (result) {
                                _this.saveVacationPlanEmployeeDetails(dialog.employee.id, changedRecords_1, dialog.employeeDetails.records, record.weeks, week);
                            }
                        }, popupOptions);
                    }
                    else {
                        _this.saveVacationPlanEmployeeDetails(dialog.employee.id, changedRecords_1, dialog.employeeDetails.records, record.weeks, week);
                    }
                });
            }
        });
    };
    VacationGridComponent.prototype.saveVacationPlanEmployeeDetails = function (empId, changedRecords, employeeDetails, record, week) {
        var _this = this;
        this.vacationPlannerApiService.saveVacationPlanEmployeeDetails(empId, changedRecords)
            .then(function (success) {
            //TBD can be changed to return number
            if (success) {
                var vacations = _.filter(employeeDetails, function (r) {
                    return !!r.scheduleAbsence;
                });
                record[week].days = vacations.length;
                _this.refreshGrid();
                _this.changeDetectorRef.detectChanges();
            }
        });
    };
    VacationGridComponent.prototype.dataStateChange = function (state) {
        this.gridState = state;
        this.refreshGrid();
    };
    Object.defineProperty(VacationGridComponent.prototype, "canBeLocked", {
        get: function () {
            return !!this.gridData && !!this.gridData.weeks;
        },
        enumerable: true,
        configurable: true
    });
    VacationGridComponent.prototype.refreshGridSort = function () {
        var _this = this;
        if (!this.gridState.sort || !this.columnsSortSettings)
            return;
        this.gridState.sort = _.filter(this.gridState.sort, function (sd) {
            return !_this.columnsSortSettings[sd.field] || _this.columnsSortSettings[sd.field].displayed;
        });
        this.refreshGrid();
    };
    VacationGridComponent.prototype.refreshGrid = function () {
        if (!this.gridData) {
            this.gridView = null;
            return;
        }
        this.weekGroups = {};
        this.gridView = process(this.gridData.records, this.gridState);
    };
    VacationGridComponent.prototype.getChangedRecords = function (originalRecords, dialogRecords) {
        var changedRecords = _.differenceWith(dialogRecords, originalRecords, _.isEqual);
        return changedRecords;
    };
    VacationGridComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        return [2 /*return*/];
                }
            });
        });
    };
    return VacationGridComponent;
}());
export { VacationGridComponent };
