<div class="main-container">
  <div class="row">
    <div class="column title">Component</div>
    <div class="column title">Rate</div>
  </div>
  
  <div class="row" *ngFor="let payRatesRecord of payRatesRecords; let i = index ">
    <div class="column">
      <label>{{payRatesRecord.component}}</label>
    </div>
    <div class="column">
      <slx-input-decorator className="input-item slx-no-label slx-no-error-block slx-small-font">
        <slx-kendo-number slx-input [(ngModel)]="payRatesRecord.rate" [readonly]="!_isEditMode" name="payRatesRecord{{i}}" [format]="currencyFormat"
          [decimals]="4" [min]="0" [max]="999999.9999" (ngModelChange)="calculatePayRate()" [required]="true">
        </slx-kendo-number>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
  </div>
  
  <div class="row">
    <div class="column"></div>
    <div class="column total">
      Total: <span>{{ payRateTotal | number:'1.4-4'}}</span>
    </div>
  </div>
</div>
