/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./time-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./time-picker.component";
import * as i3 from "@angular/forms";
var styles_TimePickerComponent = [i0.styles];
var RenderType_TimePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimePickerComponent, data: {} });
export { RenderType_TimePickerComponent as RenderType_TimePickerComponent };
export function View_TimePickerComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_TimePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["slx-time-picker", ""]], null, null, null, View_TimePickerComponent_0, RenderType_TimePickerComponent)), i1.ɵdid(1, 770048, null, 0, i2.TimePickerComponent, [i1.ElementRef, i3.NgControl], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimePickerComponentNgFactory = i1.ɵccf("[slx-time-picker]", i2.TimePickerComponent, View_TimePickerComponent_Host_0, { placeholder: "placeholder", readonly: "readonly", minDate: "minDate", maxDate: "maxDate", format: "format", interval: "interval", dateInput: "dateInput" }, { onDateChanged: "onDateChanged" }, []);
export { TimePickerComponentNgFactory as TimePickerComponentNgFactory };
