
export class TempSensorReadingEvent {
  cmd: TempSensorReadingEventCmd;
  public payload: any;
}

export class TempSensorReadingEventCmd {
  public static excelExport: TempSensorReadingEventCmdDefinition = 'ExcelExport';
  public static pdfExport: TempSensorReadingEventCmdDefinition = 'PdfExport';
}

export type TempSensorReadingEventCmdDefinition = 'ExcelExport' | 'PdfExport';
