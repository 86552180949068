import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../../framework/index';
import { NotificationsService } from '../../../../core/components/index';
import { FileService } from '../../../../common/services/index';

import {
  EmployeeSubsectionWarnings,
  EmployeeWarningBasic,
  EmployeeWarningExtended,
  EmployeeWarningSections,
  EmployeeWarningSectionViolations,
  EmployeeWarningSectionViolationsAB,
  EmployeeWarningSectionCompanyRemarks,
  EmployeeWarningSectionEmployeeRemarks,
  EmployeeWarningSectionIncidentDescription,
  EmployeeWarningSectionActions
} from '../../models/index';

import { EmployeeSectionsPersonalApiService } from './employee-section-personal-api.service';
import { EmployeeSectionWarningMapService } from './employee-section-warning-map.service';

@Injectable()
export class EmployeeSectionWarningsManagementService {
  private spinner$ = new Subject<boolean>();
  private reloadWarnings$ = new Subject<null>();
  private loadedWarning$ = new ReplaySubject<EmployeeWarningExtended>(1);
  private loadedWarnings$ = new Subject<Array<EmployeeWarningBasic>>();
  private canSaveWarning$ = new Subject<boolean>();
  private warningHasChanges$ = new Subject<boolean>();

  private warningSections = new Map<EmployeeWarningSections, boolean>();
  private employeeId: number;
  private warningHasChanges = false;

  constructor(
    private readonly apiService: EmployeeSectionsPersonalApiService,
    private readonly mapService: EmployeeSectionWarningMapService,
    private readonly notificationService: NotificationsService,
    private readonly fileService: FileService
  ) { }

  public init(empId: number): void {
    this.employeeId = empId;
  }

  public toggleSpinner(isShown: boolean): void {
    this.spinner$.next(isShown);
  }

  public subscribeToSpinner(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.spinner$.subscribe(callback);
  }

  public subscribeToLoadedWarning(callback: (w: EmployeeWarningExtended) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadedWarning$.subscribe(callback);
  }

  public destroyLoadedWarning(): void {
    this.warningHasChanges = false;
    this.loadedWarning$.complete();
  }

  public createLoadedWarning(): void {
    this.loadedWarning$ = new ReplaySubject<EmployeeWarningExtended>(1);
  }

  public subscribeToLoadedWarnings(callback: (w: Array<EmployeeWarningBasic>) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadedWarnings$.subscribe(callback);
  }

  public subscribeToReloadWarnings(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.reloadWarnings$.subscribe(callback);
  }

  public subscribeToCanSaveWarning(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.canSaveWarning$.subscribe(callback);
  }

  public subscribeToChangesInWarning(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.warningHasChanges$.subscribe(callback);
  }

  public changeSectionValidity(type: EmployeeWarningSections, value: boolean): void {
    this.warningSections.set(type, value);
    const canSaveWarning = _.every(Array.from(this.warningSections.values()), s => _.isBoolean(s) && s);
    this.canSaveWarning$.next(canSaveWarning);
  }

  public markWarningAsEdited(hasChanges: boolean): void {
    if (this.warningHasChanges !== hasChanges) {
      this.warningHasChanges = hasChanges;
      this.warningHasChanges$.next(hasChanges);
    }
  }

  public getViolationsSection(): EmployeeWarningSectionViolations {
    return this.mapService.createViolationsSection();
  }

  public getCompanyRemarksSection(): EmployeeWarningSectionCompanyRemarks {
    return this.mapService.createCompanyRemarksSection();
  }

  public getEmployeeRemarksSection(): EmployeeWarningSectionEmployeeRemarks {
    return this.mapService.createEmployeeRemarksSection();
  }

  public getActionsSection(): EmployeeWarningSectionActions {
    return this.mapService.createActionsSection();
  }

  public getViolationsABSection(): EmployeeWarningSectionViolationsAB {
    return this.mapService.createViolationsABSection();
  }

  public getIncidentDescriptionSection(): EmployeeWarningSectionIncidentDescription {
    return this.mapService.createIncidentDescriptionSection();
  }

  public downloadWarning(warningId: number): void {
    this.toggleSpinner(true);
    this.apiService.downloadWarning(warningId)
      .then(file => {
        this.fileService.saveToFileSystem(file.blob, file.file);
      })
      .finally((): void => this.toggleSpinner(false));
  }

  public getWarnings(): void {
    this.toggleSpinner(true);
    this.apiService.getEmployeeWarnings(this.employeeId)
      .then((section: EmployeeSubsectionWarnings) => this.loadedWarnings$.next(section.warnings))
      .finally((): void => this.toggleSpinner(false));
  }

  public getWarningTemplate(): void {
    this.toggleSpinner(true);
    this.apiService.getEmployeeWarningTemplate(this.employeeId)
      .then((w: EmployeeWarningExtended) => this.loadedWarning$.next(w))
      .finally((): void => this.toggleSpinner(false));
  }

  public getWarning(warningId: number): void {
    this.toggleSpinner(true);
    this.apiService.getEmployeeWarning(warningId)
      .then((w: EmployeeWarningExtended) => this.loadedWarning$.next(w))
      .finally((): void => this.toggleSpinner(false));
  }

  public addWarning(warning: EmployeeWarningExtended): void {
    this.toggleSpinner(true);
    this.apiService.addEmployeeWarning(warning, this.employeeId)
      .then(() => {
        this.reloadWarnings$.next();
        this.notificationService.success('Warning Created', 'Employee Warning created successfully');
      })
      .finally((): void => this.toggleSpinner(false));
  }

  public editWarning(warning: EmployeeWarningExtended): void {
    this.toggleSpinner(true);
    this.apiService.editEmployeeWarning(warning, this.employeeId)
      .then(() => {
        this.reloadWarnings$.next();
        this.notificationService.success('Warning Edited', 'Employee Warning edited successfully');
      })
      .finally((): void => this.toggleSpinner(false));
  }

  public deleteWarning(warning: EmployeeWarningBasic): void {
    this.toggleSpinner(true);
    this.apiService.deleteEmployeeWarning(warning.id)
      .then(() => {
        this.reloadWarnings$.next();
        this.notificationService.success('Warning Deleted', 'Employee Warning deleted successfully');
      })
      .finally((): void => this.toggleSpinner(false));
  }
}
