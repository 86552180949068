import { ElementRef, DoCheck, QueryList, Renderer2, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ErrorMessageDirective } from '../../error-filters/errorMessage';
import { InputPolicyDirective } from '../../directives/index';
import * as _ from 'lodash';
var SlxInputSuffixDirective = /** @class */ (function () {
    function SlxInputSuffixDirective() {
        this.className = 'slx-input-suffix';
    }
    return SlxInputSuffixDirective;
}());
export { SlxInputSuffixDirective };
var SlxInputHintDirective = /** @class */ (function () {
    function SlxInputHintDirective() {
        this.className = 'slx-input-hint';
    }
    return SlxInputHintDirective;
}());
export { SlxInputHintDirective };
var SlxInputDirective = /** @class */ (function () {
    function SlxInputDirective(ngControl, elementRef) {
        this.className = 'slx-form-control';
        this.ngControl = ngControl;
        this.elementRef = elementRef;
    }
    return SlxInputDirective;
}());
export { SlxInputDirective };
/*
Temporary approach since HostBinding does not works with ng2 component inputs
https://github.com/angular/angular/issues/13776
*/
var SlxInputAltDirective = /** @class */ (function () {
    function SlxInputAltDirective(ngControl, elementRef) {
        this.className = 'slx-form-control';
        this.ngControl = ngControl;
        if (ngControl)
            this.ngControlHost = ngControl.valueAccessor;
        this.elementRef = elementRef;
    }
    SlxInputAltDirective.prototype.ngOnChanges = function (changes) {
        if (this.ngControlHost)
            this.ngControlHost.className = this.className;
    };
    return SlxInputAltDirective;
}());
export { SlxInputAltDirective };
var InputDecoratorComponent = /** @class */ (function () {
    function InputDecoratorComponent(elementRef, renderer) {
        this.renderer = renderer;
        this.alreadyHidden = false;
        this.elementRef = elementRef;
    }
    Object.defineProperty(InputDecoratorComponent.prototype, "fieldChild", {
        get: function () {
            if (this.fieldChild1) {
                return this.fieldChild1;
            }
            if (this.fieldChild2) {
                return this.fieldChild2;
            }
            throw Error('Underlying input was not founded. Try to check if you assigned [slx-input] directive');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDecoratorComponent.prototype, "hideFieldChild", {
        get: function () {
            if (this.inputPolicy) {
                return !this.inputPolicy.isVisible || this.inputPolicy.isMasked;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDecoratorComponent.prototype, "inputPolicy", {
        get: function () {
            return this.inputPolicyDirective ? this.inputPolicyDirective.inputPolicy : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDecoratorComponent.prototype, "titlename", {
        get: function () {
            return this.fieldChild.titlename || this.fieldChild.placeholder;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputDecoratorComponent.prototype, "decoratorClass", {
        get: function () {
            var isReadonly = false;
            var required = false;
            if (this.fieldChild) {
                required = Boolean(this.fieldChild.required);
                isReadonly = Boolean(this.fieldChild.readonly);
            }
            var pending = false;
            var empty = false;
            if (this.fieldChild && this.fieldChild.ngControl) {
                pending = this.fieldChild.ngControl.pending;
                empty = !this.fieldChild.ngControl.value;
            }
            var dClass = {
                'slx-form-group': true,
                'slx-readonly': isReadonly || (this.inputPolicy ? !this.inputPolicy.isEditable : false),
                'slx-required': required || (this.inputPolicy ? this.inputPolicy.isRequired : false),
                'slx-pending': pending,
                'slx-empty': empty
            };
            if (this.className) {
                dClass[this.className] = true;
            }
            if (_.isEqual(this.prevStateClass, dClass)) {
                return this.prevStateClass;
            }
            else {
                this.prevStateClass = dClass;
            }
            return dClass;
        },
        enumerable: true,
        configurable: true
    });
    InputDecoratorComponent.prototype.ngDoCheck = function () {
        if (this.alreadyHidden || !this.hideFieldChild) {
            return;
        }
        if (!this.fieldChild) {
            return;
        }
        this.renderer.setStyle(this.fieldChild.elementRef.nativeElement, 'display', 'none');
        this.fieldChild.ngControl.control.disable();
        this.alreadyHidden = true;
    };
    return InputDecoratorComponent;
}());
export { InputDecoratorComponent };
