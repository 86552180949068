import { AcaExportType } from '../../enums/aca-export-type';
import { AcaExportCorrection1094CCountContainer, IAcaExportCorrection1094CCountDto } from './aca-export-correction-1094c-counts';

export class IAcaExportParameter {
  exportType: number;
  year: number;
  employerId: number;
  employerName: string;
  qualifiedOfferMethod: boolean;
  offerMethod98: boolean;
  memberOfAleGroup: number[];
  offeredMec: number[];
  empIds: any[];
  recordIds: any[];
  receiptId: string;
  overrides: IAcaExportCorrection1094CCountDto;
}

export class AcaExportParameter {
  public exportType: AcaExportType;
  public year: number;
  public employerId: number;
  public employerName: string;
  public qualifiedOfferMethod: string;
  public offerMethod98: string;
  public memberOfAleGroup: any[];
  public offeredMec: any[];
  public empIds: any[];
  public empMasterIds: any[];
  public recordIds: any[];
  public hidden: boolean;
  public replacement: string;
  public receiptId: string;
  public overrides: AcaExportCorrection1094CCountContainer;
}
