<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
        <div #toolBar class="section employee-list-actions slx-content-toolbar-indents">


            <div class="toolbar-container">

                <span class="spacer"></span>

                <slx-checkbox-toggle class="active-only-field" className="toggle-right" [(ngModel)]="state.isActiveOnly"
                    (ngModelChange)="onDisplaySettingsChanged()" caption="Active only" checkedText="Yes" uncheckedText="No">
                </slx-checkbox-toggle>

                <slx-actions-list>
                    <slx-actions-button [popperContent]="usersActions" [popperPosition]="'bottom-end'">
                        Actions <span class="caret"></span>
                    </slx-actions-button>
                    <popper-content #usersActions>
                        <slx-actions-list-item [disabled]="!state.hasSelected" (onClick)="logOffSelected()">Log-off selected users
                        </slx-actions-list-item>
                        <slx-actions-list-item (onClick)="exportToPdf()">Export to Pdf
                        </slx-actions-list-item>
                        <slx-actions-list-item (onClick)="exportToExcel()">Export to Excel
                        </slx-actions-list-item>
                    </popper-content>
                </slx-actions-list>
            </div>


        </div>
        <div class="slx-high-box__body slx-main-content-indents">



            <kendo-grid #kendoGrid class="slx-full-height slx-blue-grid slx-white-cells slx-cell-padding"
                scrollable="'scrollable'"
                [data]="gridState?.view"
                [sortable]="{ mode: 'multiple' }"
                [sort]="gridState.state.sort"
                [skip]="gridState.state.skip"
                [pageable]="true"
                [pageSize]="pageSize"
                [filterable]="'menu'"
                [filter]="gridState.state.filter"
                (dataStateChange)="gridState.dataStateChange($event)"
                (excelExport)="onExcelExport($event)"
          >

                <kendo-grid-column title="" [sortable]="false" [filterable]="false" width="35">
                    <ng-template kendoGridHeaderTemplate>
                        <input type="checkbox" name="allSelector" [ngModel]="selectionHelper.isAllSelected" (ngModelChange)="selectionHelper.onToggleAllSelected()" />
                    </ng-template>
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <input  [disabled]="!dataItem.selectable"
                                name="selector{{rowIndex}}" type="checkbox" [(ngModel)]="dataItem.isSelected"
                                (ngModelChange)="selectionHelper.onItemSelectionChange(dataItem, rowIndex)" />
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                title="User Name"
                field="userName"
                filter="text"
                >
                </kendo-grid-column>

                <kendo-grid-column
                title="Login ID"
                field="userLogin"
                filter="text"
                >
                </kendo-grid-column>

                <kendo-grid-column
                title="Status"
                field="status"
                filter="boolean"
                [hidden]="!isVisible('status')"
                >
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{ dataItem.status ? 'Active' : 'Inactive' }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                title="Currently Logged In"
                field="loggedIn"
                filter="boolean"
                >
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{ dataItem.loggedIn ? 'Y' : 'N' }}</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                title="Email"
                field="email"
                filter="text"
                >
                </kendo-grid-column>


                <kendo-grid-column
                title="Created On"
                field="lastUpdateOn"
                filter="date"
                width="170"
                >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{ dataItem.lastUpdateOn | amDateFormat: appConfig.dateTimeFormatUS }}</span>
                </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                title="IP Address"
                field="ipAddress"
                filter="text"
                >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.lastLogin">{{ dataItem.ipAddress }}</span>
                    <span *ngIf="!dataItem.lastLogin">N/A</span>
                </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                title="Last Login"
                field="lastLogin"
                filter="date"
                width="170"
                >
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span *ngIf="dataItem.lastLogin">{{ dataItem.lastLogin | amDateFormat: appConfig.dateTimeFormatUS }}</span>
                    <span *ngIf="!dataItem.lastLogin">N/A</span>
                </ng-template>
                </kendo-grid-column>

                <kendo-grid-column
                title="Session Duration"
                field="formattedSessionDuration"
                filter="text"
                >
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.lastLogin">{{ dataItem.formattedSessionDuration }}</span>
                        <span *ngIf="!dataItem.lastLogin">N/A</span>
                    </ng-template>
                </kendo-grid-column>


                <kendo-grid-column
                title="Days Since Last Login"
                field="daySinceLastLogin"
                filter="numeric"
                >
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span *ngIf="dataItem.lastLogin">{{ dataItem.daySinceLastLogin }}</span>
                        <span *ngIf="!dataItem.lastLogin">N/A</span>
                    </ng-template>
                </kendo-grid-column>

                <kendo-grid-excel fileName="UserSessions.xlsx" [fetchData]="allData()"></kendo-grid-excel>
                <kendo-grid-pdf fileName="UserSessions.pdf" [allPages]="true">
                    <kendo-grid-pdf-margin top="1cm" left="1cm" right="1cm" bottom="1cm"></kendo-grid-pdf-margin>
                </kendo-grid-pdf>

        </kendo-grid>
        </div>
    </div>
<!--
    [kendoGridColumnFilterableOn]="['desktop']"
    [kendoGridColumnSortableOn]="['desktop']"
    [hidden]="!isVisible('payrollNumber')"
    <div class="slx-high-box" *ngIf="!state.isLoading">
        <span class="error-span">There are no active list definitions. This can be caused by insufficient rights or
            internal error.</span>
    </div>
-->
</slx-spinner>
