import * as _ from 'lodash';
import { Position, IPosition, ShiftDefinition, LocationUnit } from '../../../organization/models/index';
import { EmployeeMessage, IEmployeeMessage, MessagesThread, OpenShiftShortDetailsWrapper } from './index';
import { PartnerMessages } from './partner-messages';

export interface IOpenShiftDetails {
  dateOn: Date;
  position: IPosition;
  shift: ShiftDefinition;
  unit: LocationUnit;
  openShiftCount: number;
  calculatedOpenShiftCount: number;
  adjustedShiftCount: number;
  scheduledShiftCount: number;
  idealShiftCount: number;
  messages: IEmployeeMessage[];
  partnerMessages: IEmployeeMessage[];
  workTimeHours: number;
  shiftGroup: { name : string };
  openHours: number;
  adjustedHours: number;
  scheduledHours: number;
  idealHours: number;
  partnerShiftId: number
  partnerShiftCount: number;
  partnerOpenShiftCount: number;
  overScheduleCount: number;
  parentShiftId: number;
  hasPartialShift: boolean;
}

export interface IOpenShiftCountChangedEventArgs {
  dateOn: Date;
  shiftCountDiff: number;
}

export interface IOpenShiftDailySDetailsSelectedArgs {
  dailySummaryDetails: OpenShiftShortDetailsWrapper;
}

export class OpenShiftDetails {
  public dateOn: Date;
  public position: Position;
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public openShiftCount: number;
  public calculatedOpenShiftCount: number;
  public adjustedShiftCount: number;
  public scheduledShiftCount: number;
  public idealShiftCount: number;
  public openHours: number;
  public adjustedHours: number;
  public scheduledHours: number;
  public idealHours: number;
  public messages: EmployeeMessage[];
  public partnerMessages: EmployeeMessage[];
  public messagesThreads: MessagesThread[];
  public workTimeHours: number;
  public shiftGroupName: string;
  public overShiftCount: number;
  public partnerShiftId: number;
  public partnerOpenShiftCount: number;
  public overScheduleCount: number;
  public parentShiftId: number;
  public hasPartialShift: boolean;
  public partialShifts: OpenShiftDetails[];

  public get requestedPartialShiftCount(): number {
    let partialCount: number = 0;
    if(this.partialShifts) {
      _.forEach(this.partialShifts, (partialShift: OpenShiftDetails) => {
        if(!partialShift.hasPartialShift && partialShift.messages.length>0) {
          partialCount += partialShift.messages.length;
        } 
      })
    }
    return partialCount;
  }

  public get hasPartialShifts(): boolean {
    if(this.partialShifts && this.partialShifts.length>0) {
      return true;
    }
    return false;
  }

  public get partnerShiftCount(): number {
      let partnerMessages: PartnerMessages[] = _.filter(this.partnerMessages, (message: PartnerMessages): boolean => {
        message.partnerShiftId=this.partnerShiftId;  
        return  (message.isNew || message.isRequested);
      });
      return partnerMessages.length; 
  }


  public get messagesFromThreads(): EmployeeMessage[] {
    return _.flatMap(this.messagesThreads, (thread: MessagesThread) => {
      return thread.messages || [];
    });
  }

  public get newMessageCount(): number {
    let messages: EmployeeMessage[] = _.filter(this.messages, (message: EmployeeMessage): boolean => {
      return message.isInbound && (message.isNew || message.isRequested);
    });
    return messages.length;
  }

  public getThreadByMessageId(messageId: number): MessagesThread {
    return _.find(this.messagesThreads, (thread: MessagesThread): boolean => {
      return thread.messages.some((message: EmployeeMessage): boolean => message.id === messageId);
    });
  }

  public get positionName(): string {
    return this.position ? this.position.name : '';
  }

  public get shiftName(): string {
    return this.shift ? this.shift.name : '';
  }

  public get unitName(): string {
    return this.unit ? this.unit.name : '';
  }
}
