import * as _ from 'lodash';
import { forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import { CommonValidators } from './common-validators';
var PROHIBITED_VALUES_VALIDATOR = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(function () { return ProhibitedValuesValidator; }),
    multi: true
};
var ProhibitedValuesValidator = /** @class */ (function () {
    function ProhibitedValuesValidator() {
    }
    Object.defineProperty(ProhibitedValuesValidator.prototype, "prohibitedValues", {
        set: function (value) {
            if (!Array.isArray(value) && !_.has(value, 'valuePropertyName') && !_.has(value, 'excludeSelf')) {
                this.m_prohibitedValues = [value];
            }
            else {
                this.m_prohibitedValues = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    /* tslint:enable */
    ProhibitedValuesValidator.prototype.ngOnChanges = function (changes) {
        if (changes['prohibitedValues']) {
            this._createValidator();
            if (this._onChange) {
                this._onChange();
            }
        }
    };
    ProhibitedValuesValidator.prototype.validate = function (c) {
        return this._validator(c);
    };
    ProhibitedValuesValidator.prototype.registerOnValidatorChange = function (fn) {
        this._onChange = fn;
    };
    ProhibitedValuesValidator.prototype._createValidator = function () {
        if (!Array.isArray(this.m_prohibitedValues)) {
            this.m_prohibitedValues.selfValue = this.selfValue;
        }
        this._validator = CommonValidators.prohibitedValues(this.m_prohibitedValues);
    };
    return ProhibitedValuesValidator;
}());
export { ProhibitedValuesValidator };
