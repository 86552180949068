
export class AuditTrailEvent {
  cmd: AuditTrailEventCmd;
  public payload: any;
}

export class AuditTrailEventCmd {
  public static excelExport: AuditTrailEventCmdDefinition = 'ExcelExport';
  public static pdfExport: AuditTrailEventCmdDefinition = 'PdfExport';
}

export type AuditTrailEventCmdDefinition = 'ExcelExport' | 'PdfExport';
