export * from './left-toolbar-item.directive';
export * from './right-toolbar-item.directive';
export * from './toolbar-section-template.directive';

import { ToolbarSectionTemplateDirective } from './toolbar-section-template.directive';
import { LeftToolbarItemDirective } from './left-toolbar-item.directive';
import { RightToolbarItemDirective } from './right-toolbar-item.directive';

export const toolbarDirectives: any[] = [
  LeftToolbarItemDirective,
  RightToolbarItemDirective,
  ToolbarSectionTemplateDirective
];
