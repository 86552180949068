import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { benefitsConfig } from '../../../benefits.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DeviceDetectorService } from '../../../../../common/services/index';
import { BenefitDetailsManagementService, BenefitDetailsPermissionService } from '../../../services/index';
var BenefitDetailsTabsComponent = /** @class */ (function () {
    function BenefitDetailsTabsComponent(manService, permissionService, devDetector) {
        this.manService = manService;
        this.permissionService = permissionService;
        this.devDetector = devDetector;
        this.groups = [];
        this.isEditMode = false;
        this.subscriptions = {};
        this.benefitGroupsByType = {};
        this.groupConfig = benefitsConfig.benefitGroupsConfig;
        this.benefitGroupsByType = _.keyBy(this.groupConfig, function (c) { return c.type; });
    }
    Object.defineProperty(BenefitDetailsTabsComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsTabsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.config = this.manService
            .subscribeToChangeConfig(function (config) { return (_this.groups = config.groups); });
        this.subscriptions.config = this.manService
            .subscribeToSelectGroup(function (group) { return (_this.selectedGroup = group); });
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) { return (_this.isEditMode = v.providerInfo); });
    };
    BenefitDetailsTabsComponent.prototype.ngOnDestroy = function () { };
    BenefitDetailsTabsComponent.prototype.isSelectedGroup = function (group) {
        return _.isObjectLike(this.selectedGroup) && this.selectedGroup.id === group.id;
    };
    BenefitDetailsTabsComponent.prototype.isStandartGroup = function (group) {
        var _a = this.groupConfig, medical = _a.medical, dental = _a.dental, vision = _a.vision, insurance = _a.insurance, C401K = _a.C401K, tuition = _a.tuition, voluntary = _a.voluntary;
        return _.isObjectLike(group) &&
            (group.type === medical.type
                || group.type === dental.type
                || group.type === vision.type
                || group.type === insurance.type
                || group.type === C401K.type
                || group.type === tuition.type
                || group.type === voluntary.type);
    };
    BenefitDetailsTabsComponent.prototype.isUndefinedGroup = function (group) {
        var _a = this.groupConfig, medical = _a.medical, dental = _a.dental, vision = _a.vision, C401K = _a.C401K, insurance = _a.insurance, cobra = _a.cobra, tuition = _a.tuition, voluntary = _a.voluntary;
        return !_.isObjectLike(group) ||
            group.type !== medical.type
                && group.type !== dental.type
                && group.type !== vision.type
                && group.type !== C401K.type
                && group.type !== insurance.type
                && group.type !== cobra.type
                && group.type !== tuition.type
                && group.type !== voluntary.type;
    };
    BenefitDetailsTabsComponent.prototype.getIconClass = function (type) {
        var group = this.benefitGroupsByType[type];
        return _.isObjectLike(group) && _.size(group.type) > 0 ? group.icon : '';
    };
    BenefitDetailsTabsComponent.prototype.onSelectTab = function (e) {
        this.manService.selectGroup(this.groups[e.index]);
    };
    BenefitDetailsTabsComponent.prototype.onSelectedGroup = function () {
        this.manService.selectGroup(this.selectedGroup);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsTabsComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsTabsComponent;
}());
export { BenefitDetailsTabsComponent };
