import { FieldData, Actions, FieldsMeta } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

import {
  PayPolicy,
  IPayPolicy,
  ShiftDiffPolicy,
  IShiftDiffPolicy,
  IDepartment,
  Department,
  IOrganization,
  Organization,
  IPosition,
  Position
} from '../../../../organization/models/lookup/index';
import { StringUtils } from '../../../../framework/string-utils/string-utils';

export interface IEmployeeSectionsPositions {
  positions: IEmployeeSectionsPosition[];
}
export interface IEmployeeSectionsPosition {
  id: number;
  department: IDepartment;
  organization: IOrganization;
  position: IPosition;
  shiftDifferentialPolicy: IShiftDiffPolicy;
  payPolicy: IPayPolicy;
  hourlyRate: number;
  isPrimary: boolean;
  startDate: string;
  endDate: string;
  isDeleted: boolean;
}

export class EmployeeSectionsPositions extends EmployeeSectionsBase {
  public fieldsMeta: FieldsMeta;
  public positions: EmployeeSectionsPosition[];
  public actions: Actions;
  public positionMetaData: FieldData<Position>;
  public organizationMetaData: FieldData<Organization>;
  public departmentMetaData: FieldData<Department>;
  public shiftDiffPolicyMetaData: FieldData<ShiftDiffPolicy>;
  public payPolicyMetaData: FieldData<PayPolicy>;
  public hourlyRateMetaData: FieldData<number>;
  public startDateMetaData: FieldData<Date>;
  public endDateMetaData: FieldData<Date>;

  constructor() {
    super();
    this.positions = [];
  }
}

export class EmployeeSectionsPosition {
  public id: number;
  public department: FieldData<Department>;
  public organization: FieldData<Organization>;
  public position: FieldData<Position>;
  public shiftDiffPolicy: FieldData<ShiftDiffPolicy>;
  public payPolicy: FieldData<PayPolicy>;
  public hourlyRate: FieldData<number>;
  public isPrimary: boolean;
  public startDate: FieldData<Date>;
  public endDate: FieldData<Date>;
  public isDeleted: boolean;
  public isSaved: boolean;

  public get orgNavBreadcrumb(): string {
    let orgName: string = this.organization.fieldValue ? this.organization.fieldValue.name : '';
    let depName: string = this.department.fieldValue ? this.department.fieldValue.name : '';
    let bc: string = StringUtils.format('{0} > {1}', orgName, depName);
    return bc;
  }
}
