import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { exportDataConfig } from '../../export-data.config';
import { appConfig } from '../../../../app.config';
import { AcaReportMapService } from './aca-report-map.service';
var AcaReportApiService = /** @class */ (function () {
    function AcaReportApiService(apiUtilService, urlParamsService, mapService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.mapService = mapService;
    }
    AcaReportApiService.prototype.getEmployerStatus = function (employerId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + exportDataConfig.api.aca1095.report + "/" + exportDataConfig.api.aca1095.employer.root + "/" + employerId + "/" + exportDataConfig.api.aca1095.employer.status;
                request = this.urlParamsService.createGetRequest(url, { year: year });
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.mapService.mapToAcaReportEmployerStatus(response.data); })];
            });
        });
    };
    AcaReportApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version + "/" + exportDataConfig.api.aca1095.root;
    };
    return AcaReportApiService;
}());
export { AcaReportApiService };
