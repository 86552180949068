<div class="slx-shift-warning-container">
    <p>{{options?.message}}</p>
    <p class="warning-message">
        {{message}}
    </p>
    <span *ngIf="options.message === 'Overlapping Shift'">
        <button (click)="onCancel()">Close</button>
    </span>
    <span *ngIf="options.message === 'Overtime'">
        <i class="fas fa-plus-circle"
        (click)="onOk()"></i>
    <i class="far fa-minus-square"
        (click)="onCancel()"></i>
    </span>
</div>
