import { Component, OnInit } from '@angular/core';
import { DialogOptions, IDialog } from '../../models';
import { ModalService } from '../../services';
import { OverlapOverTimeMessage } from './../../../../app/scheduler/models/schedule-rotation/schedule-rotation-template.model';

@Component({
  selector: 'slx-shift-overlap-warning-message',
  templateUrl: './shift-overlap-warning-message.component.html',
  styleUrls: ['./shift-overlap-warning-message.component.scss']
})
export class ShiftOverlapWarningMessageComponent implements IDialog, OnInit {

  public dialogResult: boolean;
  public options: DialogOptions;
  public modalService: ModalService;
  private m_initialized: boolean;
public message: string;

  public get initialized(): boolean {
    return this.m_initialized;
  }

  constructor(options: DialogOptions, modalService: ModalService, message: OverlapOverTimeMessage) {
    this.options = options;
    this.modalService = modalService;
    this.message = message.overLap ? message.overLap : message.overTime;
  }

  ngOnInit() {
    this.m_initialized = true;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

}
