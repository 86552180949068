/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editable-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./listRow/editable-list-row.component.ngfactory";
import * as i3 from "./listRow/editable-list-row.component";
import * as i4 from "../../services/editableList/list-actions.service";
import * as i5 from "@angular/common";
import * as i6 from "./editable-list.component";
var styles_EditableListComponent = [i0.styles];
var RenderType_EditableListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditableListComponent, data: {} });
export { RenderType_EditableListComponent as RenderType_EditableListComponent };
function View_EditableListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "no-data-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No data avaliable"]))], null, null); }
function View_EditableListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_EditableListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["slx-editable-list-row", ""]], null, null, null, i2.View_EditableListRowComponent_0, i2.RenderType_EditableListRowComponent)), i1.ɵdid(1, 245760, null, 0, i3.EditableListRowComponent, [i4.ListActionsService], { index: [0, "index"], item: [1, "item"], editorTemplateRef: [2, "editorTemplateRef"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 3, null, View_EditableListComponent_3)), i1.ɵdid(3, 540672, null, 0, i5.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(4, { item: 0, rowIndex: 1 }), i1.ɵpod(5, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.index; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.editorTemplateRef; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _ck(_v, 5, 0, _ck(_v, 4, 0, _v.context.$implicit, _v.context.index)); var currVal_4 = _co.rowTemplateRef; _ck(_v, 3, 0, currVal_3, currVal_4); }, null); }
export function View_EditableListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "editable-list slx-editable-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableListComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableListComponent_2)), i1.ɵdid(4, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.data || !_co.data.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_EditableListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-editable-list", [], null, null, null, View_EditableListComponent_0, RenderType_EditableListComponent)), i1.ɵprd(512, null, i4.ListActionsService, i4.ListActionsService, []), i1.ɵdid(2, 245760, null, 2, i6.EditableListComponent, [i4.ListActionsService, i1.ElementRef], null, null), i1.ɵqud(335544320, 1, { rowTemplate: 0 }), i1.ɵqud(335544320, 2, { editorTemplate: 0 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var EditableListComponentNgFactory = i1.ɵccf("slx-editable-list,[slx-editable-list]", i6.EditableListComponent, View_EditableListComponent_Host_0, { data: "data", multiselect: "multiselect", editable: "editable", selectable: "selectable", renderIsEditor: "renderIsEditor", editMode: "editMode" }, { onEditStartEvent: "editStart", onEditCancelEvent: "editCancel", onItemSaveEvent: "itemSave", onItemRemoveEvent: "itemRemove", onSelectionChangeEvent: "selectionChange" }, []);
export { EditableListComponentNgFactory as EditableListComponentNgFactory };
