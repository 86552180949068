import { AfterViewInit, Directive, ElementRef, Input, OnInit } from "@angular/core";
import * as _ from "lodash";

@Directive({
    selector: '[posGroupIcon]',
})

export class PositionGroupIconDirective implements AfterViewInit {
    private el: HTMLInputElement;

    @Input()
    private posGroupIcon: any;

    constructor(private elementRef: ElementRef) {
        this.el = this.elementRef.nativeElement;
    }

    ngAfterViewInit(): void {
        const isBoolean = _.isBoolean(this.posGroupIcon);
        this.createPositionGroupIcon(isBoolean);
    }

    private createPositionGroupIcon(isBoolean: boolean) {
        if (isBoolean) {
            if (this.posGroupIcon) {
                this.createNode();
            }
            else {
                return;
            }
        }
        else {
            const asterisk = this.posGroupIcon[this.posGroupIcon.length - 1];
            if (asterisk == '*') {
                this.createNode();
            }
            else {
                return;
            }
        }
    }

    private createNode() {
        const node = document.createElement("i");
        node.className = 'fas fa-users';
        node.setAttribute('title', 'Employee has a position that is grouped with this position');
        this.el.appendChild(node);
    }

}