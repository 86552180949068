export * from './lm-calendar-view/lm-calendar-view.component';
export * from './lm-calendar-grid-header/lm-calendar-grid-header.component';
export * from './lm-schedule-line/lm-schedule-line.component';

import { LMCalendarViewComponent } from './lm-calendar-view/lm-calendar-view.component';
import { LMCalendarGridHeaderComponent } from './lm-calendar-grid-header/lm-calendar-grid-header.component';
import { LMScheduleLineComponent } from './lm-schedule-line/lm-schedule-line.component';

export const lmCalendarComponents: any[] = [
  LMCalendarViewComponent,
  LMCalendarGridHeaderComponent,
  LMScheduleLineComponent
];
