import { OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ExportDataManagementService } from '../../../services';
import { ExportDataConfigurationParameter } from '../../../models';
import { LookupService } from '../../../../../organization/services/index';
import { Lookup, LookupDefinition } from '../../../../../organization/models/index';
import { Months } from '../../../../../common';
var ExportDataParameterComponent = /** @class */ (function () {
    function ExportDataParameterComponent(lookupService, exportDataManagementService) {
        this.lookupService = lookupService;
        this.exportDataManagementService = exportDataManagementService;
        this.virtual = {
            itemHeight: 28
        };
        this.fieldValue = null;
    }
    Object.defineProperty(ExportDataParameterComponent.prototype, "orgLevel", {
        get: function () {
            return this.exportDataManagementService.orgLevel;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportDataParameterComponent.prototype, "dataTypeName", {
        get: function () {
            if (this.parameter && this.parameter.dataType)
                return this.parameter.dataType.type;
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportDataParameterComponent.prototype, "lookupValueField", {
        get: function () {
            if (this.lookup !== undefined) {
                return this.lookup.valueField;
            }
            return 'id';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExportDataParameterComponent.prototype, "lookupTitleField", {
        get: function () {
            if (this.lookup !== undefined) {
                return this.lookup.titleField;
            }
            return 'name';
        },
        enumerable: true,
        configurable: true
    });
    ExportDataParameterComponent.prototype.ngOnInit = function () {
        if (this.parameter.dataType.type === 'lookup') {
            this.updateLookups();
        }
    };
    ExportDataParameterComponent.prototype.updateLookups = function () {
        var _this = this;
        switch (this.parameter.lookupType) {
            case 'paycycles':
                this.parameter.lookupType = 'payCycles';
                break;
            case 'getobjacc':
                this.parameter.lookupType = 'getObjAcc';
                break;
        }
        if (this.parameter.sourceType === 'api') {
            var lookupPromise = this.lookupService.getLookup({
                lookupType: this.parameter.lookupType,
                orgLevelId: this.orgLevel.id,
                employeeId: undefined
            });
            lookupPromise.then(function (lookup) {
                if (_this.parameter.lookupType = 'payCycles') {
                    _this.options = _.orderBy(_.map(_.groupBy(lookup.items, function (x) { return [x.startDate, x.endDate].join(); }), function (x) { return _.first(x); }), ['endDate', 'startDate'], ['desc', 'desc']);
                }
                else {
                    _this.options = lookup.items;
                }
                _this.lookup = lookup;
            });
        }
        else if (this.parameter.sourceType === 'internal') {
            switch (this.parameter.lookupType.toLocaleLowerCase()) {
                case 'year':
                    this.options = this.getYearLookup();
                    break;
                case 'quarter':
                    this.options = this.getQuarterLookup();
                    break;
                case 'month':
                    this.options = this.getMonthLookup();
                    break;
                case 'monthyears':
                    this.options = this.getMonthYearLookup();
                    break;
                default: break;
            }
        }
        else {
            if (this.parameter.source) {
                var items = JSON.parse(this.parameter.source);
                this.options = _.map(items, function (x) { return { id: x, name: x }; });
            }
        }
    };
    ExportDataParameterComponent.prototype.getYearLookup = function () {
        var year = moment().year();
        var options = [];
        for (var idx = 0; idx < 5; idx++) {
            options.push({ id: year - idx, name: year - idx });
        }
        return _.orderBy(options);
    };
    ExportDataParameterComponent.prototype.getQuarterLookup = function () {
        var options = [];
        for (var idx = 1; idx <= 4; idx++) {
            options.push({ id: idx, name: idx });
        }
        return _.orderBy(options);
    };
    ExportDataParameterComponent.prototype.getMonthYearLookup = function () {
        var options = [];
        var year = moment().year();
        var month = moment().month();
        var val;
        for (var idx1 = 0; idx1 < 5; idx1++) {
            for (var idx2 = idx1 === 0 ? month : 11; idx2 >= 0; idx2--) {
                val = Months[idx2].shortName + " - " + (year - idx1);
                options.push({ id: val, name: val });
            }
        }
        return options;
    };
    ExportDataParameterComponent.prototype.getMonthLookup = function () {
        return Months;
    };
    ExportDataParameterComponent.prototype.onChangeValue = function (parameter) {
        if (this.parameter.dataType.type === 'lookup') {
            this.parameter.value = parameter.value ? parameter.value[this.lookupValueField] : '';
        }
        else {
            this.parameter.value = parameter.value;
        }
        this.parameter.isValid = parameter.valid;
    };
    return ExportDataParameterComponent;
}());
export { ExportDataParameterComponent };
