<slx-spinner [show]="isLoading" novalidate>
  <form #form="ngForm" novalidate class="emp-warning">
    <header class="emp-warning__emp flex align-items__center">
      <p class="emp-warning__emp-col flex align-items__center flex__width100">
        <img class="emp-warning__emp-icon" [employeeThumbinalSrc]="warning?.employee?.id" alt="Employee Photo">
        <span class="emp-warning__emp-info flex__grow">
          <strong class="emp-warning__emp-name">{{ warning?.employee?.name }}</strong>
          <ng-container *ngFor="let info of warning?.additionalInfo">
            <strong class="emp-warning__emp-field">{{ info.label }}:&nbsp;</strong>
            <span class="emp-warning__emp-value">{{ info.value }}</span>
          </ng-container>
        </span>
      </p>
      <p class="emp-warning__emp-col">
        <button type="button" class="slx-button slx-blue slx-with-icon" [disabled]="!canDownload" (click)="onDownload()">
          <i aria-hidden="true" class="slx-button__icon fas fa-download"></i>
          <span class="slx-button__text">Download</span>
        </button>
      </p>
    </header>
    <section class="emp-warning__body">
      <div class="emp-warning__body-static">
        <p class="flex emp-warning__control">
          <label class="emp-warning__c-label padd-top-6">Warning Subject</label>
          <span class="flex__grow">
            <input class="emp-warning__field"
              maxlength="255"
              [(ngModel)]="warning.warningSubject"
              (ngModalChange)="onChangeWarningSubject()"
              [required]="true"
              autocomplete="off"
              name="warningSubject"
              type="text"
              #warnSubject="ngModel"
            />
            <span *ngIf="warnSubject.errors" class="slx-error-block error-box">
              <span *ngIf="warnSubject.errors.required" errorMessage for="required"></span>
            </span>
          </span>
        </p>
        <p class="flex emp-warning__control">
          <label class="emp-warning__c-label padd-top-6">Date of Warning</label>
          <span class="flex__grow">
            <slx-datepicker class="slx-wide"
              [(ngModel)]="warning.warningDate"
              (ngModalChange)="onChangeWarningDate()"
              [acceptNullDate]="true"
              name="warningDate"
            ></slx-datepicker>
          </span>
        </p>
      </div>
      <div class="emp-warning__body-tabs">
        <kendo-tabstrip class="slx-tabs">
          <kendo-tabstrip-tab [title]="section.label" [selected]="isFirst" *ngFor="let section of warning.sections; first as isFirst">
            <ng-template kendoTabContent>
              <slx-employee-sections-warnings-violations *ngIf="section.isStandardViolations"></slx-employee-sections-warnings-violations>
              <slx-employee-sections-warnings-company-remarks *ngIf="section.isStandardCompanyRemarks"></slx-employee-sections-warnings-company-remarks>
              <slx-employee-sections-warnings-employee-remarks *ngIf="section.isStandardEmployeeRemarks"></slx-employee-sections-warnings-employee-remarks>
              <slx-employee-sections-warnings-actions *ngIf="section.isStandardActions"></slx-employee-sections-warnings-actions>
              <slx-employee-sections-warnings-violationsab *ngIf="section.isCustomViolationsAB"></slx-employee-sections-warnings-violationsab>
              <slx-employee-sections-warnings-incident-description *ngIf="section.isCustomIncidentDescription"></slx-employee-sections-warnings-incident-description>
            </ng-template>
          </kendo-tabstrip-tab>
      </kendo-tabstrip>
      </div>
    </section>
  </form>
</slx-spinner>
