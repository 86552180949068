/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./applications-config-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../applications-config/applications-config.component.ngfactory";
import * as i5 from "../applications-config/applications-config.component";
import * as i6 from "../../../services/applications-dashboard/application-dashboard-management.service";
import * as i7 from "../../../../core/services/session/session.service";
import * as i8 from "../../../services/application/application.service";
import * as i9 from "./applications-config-dialog.component";
import * as i10 from "../../../../common/models/dialog-options";
import * as i11 from "../../../../common/services/modal/modal.service";
var styles_ApplicationsConfigDialogComponent = [i0.styles];
var RenderType_ApplicationsConfigDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ApplicationsConfigDialogComponent, data: {} });
export { RenderType_ApplicationsConfigDialogComponent as RenderType_ApplicationsConfigDialogComponent };
export function View_ApplicationsConfigDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-applications-config", [], null, [[null, "onLoading"], [null, "onHasChanges"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onLoading" === en)) {
        var pd_0 = (_co.onLoading($event) !== false);
        ad = (pd_0 && ad);
    } if (("onHasChanges" === en)) {
        var pd_1 = (_co.onHasChanges($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_ApplicationsConfigComponent_0, i4.RenderType_ApplicationsConfigComponent)), i1.ɵdid(4, 245760, null, 0, i5.ApplicationsConfigComponent, [i6.ApplicationDashboardManagementService, i7.SessionService, i8.ApplicationService], null, { onLoading: "onLoading", onHasChanges: "onHasChanges" }), (_l()(), i1.ɵeld(5, 0, null, 0, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_ApplicationsConfigDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-applications-config-dialog", [], null, null, null, View_ApplicationsConfigDialogComponent_0, RenderType_ApplicationsConfigDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.ApplicationsConfigDialogComponent, [i10.DialogOptions, i11.ModalService], null, null)], null, null); }
var ApplicationsConfigDialogComponentNgFactory = i1.ɵccf("slx-applications-config-dialog", i9.ApplicationsConfigDialogComponent, View_ApplicationsConfigDialogComponent_Host_0, {}, {}, []);
export { ApplicationsConfigDialogComponentNgFactory as ApplicationsConfigDialogComponentNgFactory };
