import { ElementRef, Renderer, OnInit } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
var KendoGridHeightResizeDirective = /** @class */ (function () {
    function KendoGridHeightResizeDirective(grid, elRef, renderer) {
        this.elRef = elRef;
        this.renderer = renderer;
        this.grid = grid;
    }
    Object.defineProperty(KendoGridHeightResizeDirective.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    KendoGridHeightResizeDirective.prototype.ngOnInit = function () {
        this.calcHeight();
    };
    KendoGridHeightResizeDirective.prototype.onResize = function (event) {
        this.calcHeight();
    };
    KendoGridHeightResizeDirective.prototype.calcHeight = function () {
        if (this.isMobile && this.mobileDisableResize) {
            this.renderer.setElementStyle(this.elRef.nativeElement, 'height', '100%');
            return;
        }
        if (this.isMobile && this.mobileTopOffset) {
            this.gridHeight = window.innerHeight - this.mobileTopOffset;
            this.grid.height = this.gridHeight;
            this.renderer.setElementStyle(this.elRef.nativeElement, 'height', this.gridHeight + "px");
            return;
        }
        this.gridHeight = window.innerHeight - this.topOffset;
        this.grid.height = this.gridHeight;
        this.renderer.setElementStyle(this.elRef.nativeElement, 'height', this.gridHeight + "px");
    };
    return KendoGridHeightResizeDirective;
}());
export { KendoGridHeightResizeDirective };
