import { OnInit } from '@angular/core';
import { EmployeeRotation } from './../../../../../../app/employee/employee-sections/models';
import { appConfig } from './../../../../../../app/app.config';
import * as moment from 'moment';
import * as _ from 'lodash';
export var maximumWeeklyRotationsShown = 3;
var SrtViewIndividualScheduleComponent = /** @class */ (function () {
    function SrtViewIndividualScheduleComponent() {
    }
    Object.defineProperty(SrtViewIndividualScheduleComponent.prototype, "isExpandButtonVisible", {
        get: function () {
            return this.selectedRotationValue
                && this.selectedRotationValue.weeklyRotations
                && this.selectedRotationValue.weeklyRotations.length > maximumWeeklyRotationsShown;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SrtViewIndividualScheduleComponent.prototype, "invalidRotations", {
        get: function () {
            var invalidRotations = [];
            if (this.selectedRotationValue !== null && this.selectedRotationValue !== undefined) {
                _.forEach(this.selectedRotationValue.weeklyRotations, function (w) {
                    _.forEach(w.dailyRecords, function (d) {
                        if (!d.isValid || d.showWarning) {
                            invalidRotations.push(d);
                        }
                    });
                });
            }
            return invalidRotations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SrtViewIndividualScheduleComponent.prototype, "selectedRotation", {
        set: function (value) {
            this.selectedRotationValue = value;
        },
        enumerable: true,
        configurable: true
    });
    SrtViewIndividualScheduleComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    SrtViewIndividualScheduleComponent.prototype.getWeekStartDate = function (weeklyRotation, dateIndex) {
        if (_.isObjectLike(weeklyRotation)) {
            var dayNumber = weeklyRotation.weekStartDate.getDate();
            var monthEnd = +moment(weeklyRotation.weekStartDate).endOf('month').format('D');
            if (dayNumber + dateIndex <= monthEnd) {
                return weeklyRotation.weekStartDate.getDate() + dateIndex;
            }
            else {
                return weeklyRotation.weekStartDate.getDate() + dateIndex - monthEnd;
            }
        }
    };
    return SrtViewIndividualScheduleComponent;
}());
export { SrtViewIndividualScheduleComponent };
