import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
  name: 'property',
  pure: false
})
@Injectable()
export class PropertyPipe implements PipeTransform {
  public transform(obj?: any, memberPath?: string): any {
    if (!obj) return null;
    if (!memberPath) return obj;
    return obj[memberPath];
  }
}
