<kendo-grid class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="true"
  [filter]="gridState.state.filter"
  [groupable]="{ showFooter: true }"
  [group]="gridState.state.group"
  [pageable]="true"
  [pageSize]="pageSize"
  [skip]="gridState.state.skip"
  (pageChange)="gridState.pageChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
  (groupChange)="gridState.groupChange($event)"
  scrollable="'scrollable'">

  <kendo-grid-column title="Name" field="employee.name" width="200">
    <ng-template kendoGridHeaderTemplate>Name</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a *ngIf="employeeLink" [employeeSectionLink]="dataItem.employee.id">{{dataItem.employee.name}}</a>
      <span *ngIf="!employeeLink">{{dataItem.employee.name}}</span>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Type" field="type" width="200">
    <ng-template kendoGridHeaderTemplate>Type</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.type}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-multiselect-filter
        [filter]="filter" [showOperators]="false" [column]="column" [items]="typeFilters" ></slx-kendo-grid-multiselect-filter>
   </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Message" field="message" width="200">
    <ng-template kendoGridHeaderTemplate>Message</ng-template>
    <ng-template kendoCellTemplate let-dataItem>{{ dataItem.message}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Sent By" field="sentBy" width="200">
    <ng-template kendoGridHeaderTemplate>Sent By</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.sentBy}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Sent Time" field="sentTimeFC" filter="date" width="200">
    <ng-template kendoGridHeaderTemplate>Sent Time</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.sentTime | amDateFormat: appConfig.dateTimeFormatUS}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter"></slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Response" field="response" width="200">
    <ng-template kendoGridHeaderTemplate>Response</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.response}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Response Time" field="responseTimeFC" filter="date" width="200">
    <ng-template kendoGridHeaderTemplate>Response Time</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.responseTime | amDateFormat: appConfig.dateTimeFormatUS }}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter"></slx-kendo-grid-date-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Notes" field="notes" width="200">
    <ng-template kendoGridHeaderTemplate>Notes</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.notes}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Expiration" field="expiration" width="200">
    <ng-template kendoGridHeaderTemplate>Expiration</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.expiration}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Status" field="status" width="200">
    <ng-template kendoGridHeaderTemplate>Status</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.status}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Error" field="error" width="200">
    <ng-template kendoGridHeaderTemplate>Error</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.error}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-multiselect-filter
        [filter]="filter" [showOperators]="false" [column]="column" [items]="errorFilters"></slx-kendo-grid-multiselect-filter>
   </ng-template>
  </kendo-grid-column>
  <kendo-grid-column title="Segments" field="numSegments" width="200" *ngIf="isMessageCenterEnabled">
    <ng-template kendoGridHeaderTemplate>Segments</ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>{{dataItem.numSegments}}</ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
