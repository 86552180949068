<slx-spinner [show]="state.isLoading" novalidate>
  <div class="main-container">
    <div class="sections">

        <form #form="ngForm" class="row-form row-item">
          <div class="row-item nowrap element-cont">
            <slx-input-decorator className="slx-no-border">
              <slx-checkbox-input slx-input placeholder="" name="payPolicyCheck" fieldName="payPolicyCheck" [(ngModel)]="payPolicyEnabled"
                  (ngModelChange)="payPolicy = null; updateAnyFlag()">
              </slx-checkbox-input>
            </slx-input-decorator>
            <slx-input-decorator class="element">
              <slx-dropdown-input slx-input [disabled]="!payPolicyEnabled"
                  [lookup]="{lookupType: 'payPolicy', orgLevelId: orgLevel?.id}"
                  [readonly]="!payPolicyEnabled"
                  [(ngModel)]="payPolicy"
                  name="payPolicyInput"
                  [required]="true" placeholder="Pay Policy">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>

          <div class="row-item nowrap element-cont">
            <slx-input-decorator className="slx-no-border">
              <slx-checkbox-input slx-input placeholder="" fieldName="shiftDiffPolicyCheck" [(ngModel)]="shiftDiffPolicyEnabled"
                  name="shiftDiffPolicyCheck" (ngModelChange)="shiftDiffPolicy = null; updateAnyFlag()">
              </slx-checkbox-input>
            </slx-input-decorator>
            <slx-input-decorator class="element">
              <slx-dropdown-input slx-input [disabled]="!shiftDiffPolicyEnabled"
                  [readonly]="!shiftDiffPolicyEnabled"
                  [lookup]="{lookupType: 'shiftDiffPolicy', orgLevelId: orgLevel?.id}"
                  [(ngModel)]="shiftDiffPolicy"
                  name="shiftDiffPolicyInput"
                  [required]="true" placeholder="Shift Diff Policy">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>

          <div class="row-item nowrap offset">
            <slx-input-decorator class="element">
              <slx-date-picker-ngx slx-input
                placeholder="Pay Cycle Effective Date"
                name="effectiveDate"
                [acceptNullDate]="false"
                [required]="true"
                [(ngModel)]="effectiveDate"
              ></slx-date-picker-ngx>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </div>

          <div class="row-item nowrap buttons">
            <button type="button" class="theme-button-apply margin-r" (click)="assignPolicy()" [disabled]="!form.valid || !canSend || !anyPolicySelected">Assign</button>
            <button type="button" class="theme-button-cancel" (click)="cancel()">Cancel</button>
          </div>
        </form>

      <div class="slx-error-block policy-message">
        <span errorMessage *ngIf="!anyPolicySelected" >At least one policy should be selected</span>  
      </div>

      <div class="section">
        <slx-employee-grid [fieldDefinition]="fieldDefinition" [employees]="actionEmployees" [isMultiselect]="true" (onEmployeesSelect)="employeesSelect($event)"></slx-employee-grid>
      </div>
  </div>
</div>
</slx-spinner>
