import { OnInit, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { EmployeeCostCenterAllocation } from './../../models/index';
import { LookupApiService } from '../../../../organization/index';
var CostCenterSplitComponent = /** @class */ (function () {
    function CostCenterSplitComponent(lookupApiService) {
        this.lookupApiService = lookupApiService;
        this.DEFAULT_COUNT = 5;
        this.MAX_COUNT = 10;
        this.onChangeAllocations = new EventEmitter();
        this.percentageValid = true;
        this.uniquenessValid = true;
        this.currentSum = 0;
        this.costCenters = [];
    }
    Object.defineProperty(CostCenterSplitComponent.prototype, "allocations", {
        get: function () {
            return this._allocations;
        },
        set: function (value) {
            if (!value) {
                value = [];
            }
            for (var i = value.length; i < this.DEFAULT_COUNT; i++) {
                value.push(new EmployeeCostCenterAllocation());
            }
            this._allocations = value;
            this.validate();
        },
        enumerable: true,
        configurable: true
    });
    CostCenterSplitComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lookupApiService.getCostCenters(this.employeeId).then(function (costCenters) {
            _this.costCenters = costCenters;
            _this.validate();
        });
    };
    CostCenterSplitComponent.prototype.showCostCenterCode = function () {
        //2
    };
    CostCenterSplitComponent.prototype.addRowIfNeed = function () {
        if (this.allocations.length < this.MAX_COUNT) {
            var allFull = _.every(this.allocations, function (value) {
                return value.costCenter && value.percentage > 0;
            });
            if (allFull) {
                this.allocations.push(new EmployeeCostCenterAllocation());
            }
        }
        this.validate();
    };
    CostCenterSplitComponent.prototype.onClickDelete = function (confirmation, index, costPopover) {
        if (confirmation == true) {
            this.deleteCostCenter(index);
        }
        else {
            costPopover.hide();
        }
    };
    CostCenterSplitComponent.prototype.deleteCostCenter = function (index) {
        _.pullAt(this.allocations, index);
        for (var i = this.allocations.length; i < this.DEFAULT_COUNT; i++) {
            this.allocations.push(new EmployeeCostCenterAllocation());
        }
        this.validate();
    };
    CostCenterSplitComponent.prototype.validate = function () {
        this.validatePercentage();
        this.validateAllocation();
        this.onChange();
    };
    CostCenterSplitComponent.prototype.validatePercentage = function () {
        var realAllocations = _.filter(this.allocations, function (a) { return _.isObjectLike(a.costCenter); });
        this.currentSum = _.sumBy(realAllocations, function (a) { return a.percentage || 0; });
        this.percentageValid = this.currentSum === 100 || _.size(realAllocations) === 0;
    };
    CostCenterSplitComponent.prototype.validateAllocation = function () {
        var realAllocations = _.filter(this.allocations, function (a) { return _.isObjectLike(a.costCenter); });
        var uniqueCodes = _.uniqBy(realAllocations, function (a) { return a.costCenter.code; });
        this.uniquenessValid = _.size(realAllocations) === _.size(uniqueCodes);
    };
    CostCenterSplitComponent.prototype.onChange = function () {
        this.onChangeAllocations.emit(this.percentageValid && this.uniquenessValid);
    };
    return CostCenterSplitComponent;
}());
export { CostCenterSplitComponent };
