import * as moment from 'moment';
import { IOrganization, Organization } from '../../../organization/models/lookup/index';
import { AgencyModel } from './agency.model';

export class AgencyMapping {
    public uniqId: number;
    public organizations: Organization[];
    public agency: AgencyModel;
}

export interface IAgencyMappingDTO {
    id: number;
    name: string;
    address: string;
    state: string;
    city: string;
    zip: string;
    employeeCount: number;
    lastUpdateDate: string;
    lastUpdateName: string;
    organizations: IOrganization[];
}
