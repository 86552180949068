import { Route } from '@angular/compiler/src/core';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkLocationSyncComponent } from '../work-location-sync/work-location-sync.component';
import { WfmResponseNavigationService } from '../../../../configuration/services/wfm-response-navigation.service';
import { ChangeManagementService } from '../../../../common/services/change-management/change-management.service';

@Component({
  selector: 'slx-org-sync',
  templateUrl: './org-sync.component.html',
  styleUrls: ['./org-sync.component.scss']
})
export class OrgSyncComponent implements OnInit {
  public isLoading: boolean = true;
  public activeTab0: boolean = true;
  public activeTab1: boolean = false;
  public activeTab2: boolean = false;
  public activeTab3: boolean = false;
  public activeTab4: boolean = false;
  public BenefitPlansList: string[] = [];
  public selectedBenefitPlan: string;
  public tabSwitch: boolean;
  public isShowingExpired: boolean = null;
  public benefitPlansLoading: boolean = true;
  public currentExpandedGroups: number[];
  public canMapPayroll: boolean = false;
  @Input() type: any;
  
  constructor() {
    this.activeTab0=true;
  }

  public ngOnInit() {
    this.BenefitPlansList = ['Organization Sync(Migration)', 'Employee Sync(Migration)','Benefit-Deduction Sync(Migration)','CostCenter Sync(Migration)'];
    this.selectedBenefitPlan = 'Organization Sync(Migration)';
  }

  public onClickTab(tab: number): void {
    if ( tab ==0)
    {
      this.activeTab0=true;
      this.activeTab1=false
      this.activeTab2=false;
      this.activeTab3=false;
      this.activeTab4 = false;
    }
    if ( tab ==1)
    {
      this.activeTab0=false;
      this.activeTab1=true;
      this.activeTab2=false;
      this.activeTab3=false;
      this.activeTab4 = false;
    }
    if ( tab ==2)
    {
      this.activeTab0=false;
      this.activeTab1=false;
      this.activeTab2=true;
      this.activeTab3=false;
      this.activeTab4 = false;
    }
    if ( tab ==3)
    {
      this.activeTab0=false;
      this.activeTab1=false;
      this.activeTab2=false;
      this.activeTab3=true;
      this.activeTab4 = false;
    }
    if(tab == 4){
      this.activeTab0 = false;
      this.activeTab1 = false;
      this.activeTab2 = false;
      this.activeTab3 = false;
      this.activeTab4 = true;
    }
  }
}
