import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { ModalService } from '../../../../common/services/modal/modal.service';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { BudgetDefinition } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import {
  BudgetRecord,
  Budget,
  BudgetCensus
} from '../../../models/index';

import { BudgetApiService, BudgetManagementService } from '../../../services/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-budget-list',
  templateUrl: 'budget-list.component.html',
  styleUrls: ['budget-list.component.scss'],
  providers: [BudgetManagementService]
})
export class BudgetListComponent implements OnInit, OnDestroy {

  public state: {
    isLoading: boolean;
    isCorporation: boolean;
    isOrganization: boolean;
    isDepartment: boolean;
    isBudgetGroupEditMode: boolean;
    isRestoreBudgetMode: boolean;
    isBudgetAdjustMode: boolean;
  };
  public budget: Budget;
  public selectedOrganizationOrgLevelId: number;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private addedSubscription: Subscription;
  @unsubscribe()
  private statusSubscription: Subscription;
  @unsubscribe()
  private groupEditSubscription: Subscription;
  @unsubscribe()
  private restoreBudgetSubscription: Subscription;
  @unsubscribe()
  private budgetCensusAdjustSubscription: Subscription;
  @unsubscribe()
  private budgetSelectSubscription: Subscription;

  private budgetManagementService: BudgetManagementService;
  private modalService: ModalService;

  constructor(budgetManagementService: BudgetManagementService, modalService: ModalService) {
    this.budgetManagementService = budgetManagementService;
    this.modalService = modalService;
    this.state = {
      isLoading: false,
      isCorporation: false,
      isDepartment: false,
      isOrganization: false,
      isBudgetGroupEditMode: false,
      isRestoreBudgetMode: false,
      isBudgetAdjustMode: false
    };
  }

  public ngOnInit(): void {

    this.orgLevelSubscription = this.budgetManagementService.onOrgLevelChanged$
      .subscribe((orgLevelId: number) => {
        this.selectedOrganizationOrgLevelId = this.budgetManagementService.selectedOrganizationOrgLevelId;
        this.state.isCorporation = (orgLevelId === 0);
        this.state.isDepartment = (orgLevelId === 0);
        this.state.isOrganization = (orgLevelId !== 0);
      });

    this.statusSubscription = this.budgetManagementService.onLoadStatus$
      .subscribe((isLoading: boolean) => {
        this.state.isLoading = isLoading;
      });

    this.groupEditSubscription = this.budgetManagementService.onGroupEdit$
      .subscribe((editMode: boolean) => {
        this.state.isBudgetGroupEditMode = editMode;
      });

    this.restoreBudgetSubscription = this.budgetManagementService.onRestoreBudget$
      .subscribe((editMode: boolean) => {
        this.state.isRestoreBudgetMode = editMode;
      });

    this.budgetCensusAdjustSubscription = this.budgetManagementService.onBudgetCensusAdjust$
      .subscribe((editMode: boolean) => {
        this.state.isBudgetAdjustMode = editMode;
      });
    this.budgetSelectSubscription = this.budgetManagementService.onBudgetSelect$.subscribe(
      (req: Budget) => {
        this.budget = req;
      });

    this.loadedSubscription = this.budgetManagementService.onLoaded$.subscribe(
      (budget: Budget) => {
        this.budget = budget;
      });

    this.addedSubscription = this.budgetManagementService.onAdded$.subscribe(
      (budget: Budget) => {
        this.budget = budget;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public groupsfinishEditing(result: boolean): void {
    this.budgetManagementService.onGroupEdit(false);
  }

  public restorefinishEditing(result: Budget): void {
    this.budgetManagementService.onRestoreBudget(false);
    if (result) {
      this.budgetManagementService.onBudgetSelect(result);
      //this.budgetManagementService.onLoadRequest(result);
    }
  }

  public budgetCensusAdjustfinishEditing(result: boolean): void {
    this.budgetManagementService.onBudgetCensusAdjust(false);
  }

  private onDiscard(): void {
    if (this.state.isBudgetGroupEditMode) {
      this.groupsfinishEditing(false);
    } else if (this.state.isRestoreBudgetMode) {
      this.restorefinishEditing(null);
    } else if (this.state.isBudgetAdjustMode) {
      this.budgetCensusAdjustfinishEditing(false);
    }

  }

}
