import * as tslib_1 from "tslib";
/*****
    At this momment this component doesn't used at all
 *****/
import { OnInit, OnDestroy, ChangeDetectorRef, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimecardsDisplayManagementService } from '../../../services/index';
import { TimecardsActionCmd } from '../../../models/index';
var TimecardsDisplayGridComponent = /** @class */ (function () {
    function TimecardsDisplayGridComponent(managementService, changeDetector, elementRef) {
        this.total = {};
        this.aggregates = [
            { field: 'totalHours', aggregate: 'sum' },
            { field: 'regularPay', aggregate: 'sum' },
            { field: 'overtimeAndExtra', aggregate: 'sum' },
            { field: 'totalPay', aggregate: 'sum' },
            { field: 'workedHours', aggregate: 'sum' }
        ];
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.elementRef = elementRef;
        this.highlightedRows = [];
        this.selectedRows = [];
        this.firstInit = true;
    }
    TimecardsDisplayGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.managementService.init();
        this.appConfig = appConfig;
        this.stateChangedSubscription = this.managementService.onLoaded$.subscribe(function (container) {
            _this.container = container;
            _this.records = container.records;
            if (_this.selectedRows.length > 0) {
                _this.updateRecords();
            }
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.actionSubscription = this.managementService.onActionCmd$.subscribe(function (v) {
            if (v.cmd === TimecardsActionCmd.excelExport) {
                _this.grid.saveAsExcel();
            }
            if (v.cmd === TimecardsActionCmd.pdfExport) {
                _this.grid.saveAsPDF();
            }
        });
        this.stateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            var _a;
            _this.state = state;
            _this.highlightedRows.length = 0;
            _this.selectedRows.length = 0;
            if (_this.state.lastViewedEmployee > 0) {
                _this.highlightedRows.push(_this.state.lastViewedEmployee);
            }
            if (_this.state.lastViewedPage > 0) {
                _this.gridState.state.skip = _this.state.lastViewedPage;
                _this.refreshGrid();
            }
            if (_this.state.lastSelectedEntries && _this.state.lastSelectedEntries.length > 0) {
                (_a = _this.selectedRows).push.apply(_a, state.lastSelectedEntries);
            }
            _this.firstInit = false;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    TimecardsDisplayGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimecardsDisplayGridComponent.prototype.isVisible = function (field, payCode) {
        if (!this.state) {
            return false;
        }
        var columnState = payCode ? this.state.payCodeColumns : this.state.empColumns;
        if (!columnState || !columnState.columnsMap[field]) {
            return false;
        }
        var column = columnState.columnsMap[field];
        if (!this.state.isShowPayRates && column.payload && column.payload.payRateRelated) {
            return false;
        }
        return column.displayed;
    };
    TimecardsDisplayGridComponent.prototype.onCellClick = function (cell) {
        var record = _.get(cell, 'dataItem', null);
        if (_.isObject(record)) {
            var currentId = record.employeePosition.employee.id;
            var previousId = _.head(this.highlightedRows);
            this.highlightedRows.length = 0;
            if (currentId !== previousId) {
                this.highlightedRows.push(currentId);
            }
        }
    };
    TimecardsDisplayGridComponent.prototype.isRowSelected = function () {
        var _this = this;
        return function (event) {
            var employeeId = event.dataItem.employeePosition.employee.id;
            return _.indexOf(_this.highlightedRows, employeeId) !== -1;
        };
    };
    TimecardsDisplayGridComponent.prototype.onHighlightEmployee = function (employeeId) {
        this.managementService.onSaveLastSelectionState({ selectedEmployee: employeeId, skipRecords: this.gridState.state.skip });
    };
    TimecardsDisplayGridComponent.prototype.getWidth = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].width : 50;
    };
    TimecardsDisplayGridComponent.prototype.getFilter = function (field) {
        return this.state && this.state.empColumns.columnsMap[field] ? this.state.empColumns.columnsMap[field].filter : null;
    };
    TimecardsDisplayGridComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        _.forEach(this.records, function (record) {
            record.isSelected = _this.isAllSelected;
        });
        this.selectionChange();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    TimecardsDisplayGridComponent.prototype.selectionChange = function () {
        var selectedRecords = [];
        selectedRecords = _.filter(this.records, function (record) {
            return record.isSelected === true;
        });
        this.isAllSelected = this.records && selectedRecords ? this.records.length === selectedRecords.length : false;
        this.managementService.onRecordsSelected(selectedRecords);
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    TimecardsDisplayGridComponent.prototype.updateRecords = function () {
        var _this = this;
        _.forEach(this.records, function (record) {
            var index = _.indexOf(_this.selectedRows, record.employeePosition.employee.id);
            if (index !== -1) {
                record.isSelected = true;
            }
        });
    };
    TimecardsDisplayGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
        this.total = aggregateBy(this.records, this.aggregates);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayGridComponent.prototype, "stateChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayGridComponent.prototype, "actionSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsDisplayGridComponent.prototype, "stateSubscription", void 0);
    return TimecardsDisplayGridComponent;
}());
export { TimecardsDisplayGridComponent };
