
export interface IPunchType {
  id: number;
  name: string;
  previousPunchTypeId: number;
  canBeFirst: boolean;
  canBeLast: boolean;
  isIn: boolean;
  isOut: boolean;
  isBreak: boolean;
  isLunch: boolean;
  isTraining: boolean;
}

export class PunchType {
  public id: number;
  public name: string;
  public description: string;
  public previousPunchTypeId: number;
  public canBeFirst: boolean;
  public canBeLast: boolean;
  public isIn: boolean;
  public isOut: boolean;
  public isBreak: boolean;
  public isLunch: boolean;
  public isTraining: boolean;

}
