export * from './open-shift-management-schedule-cycle/open-shift-management-schedule-cycle.component';
export * from './open-shift-management-schedule-cycle-details/open-shift-management-schedule-cycle-details.component';
export * from './open-shift-management-daily-details/open-shift-management-daily-details.component';
export * from './daily-shift-messages-container/daily-shift-messages-container.component';
export * from './daily-shift-messages/daily-shift-messages.component';
export * from './edit-open-shift-count/edit-open-shift-count.component';
export * from './open-shift-management.component';
export * from './open-shift-management-filters/open-shift-management-filters.component';
export * from './post-schedule-settings/post-schedule-settings.component';
export * from './post-schedule-settings-form/post-schedule-settings-form.component';
export * from './daily-shift-summary-grid/daily-shift-summary-grid.component';
export * from './daily-shift-messages-grid/daily-shift-messages-grid.component';
export * from './post-schedule-form/post-schedule-form.component';
export * from './daily-partial-shifts-grid/daily-partial-shifts-grid.component';

import { OpenShiftManagementScheduleCycleComponent } from './open-shift-management-schedule-cycle/open-shift-management-schedule-cycle.component';
import { OpenShiftManagementScheduleCycleDetailsComponent } from './open-shift-management-schedule-cycle-details/open-shift-management-schedule-cycle-details.component';
import { OpenShiftManagementDailyDetailsComponent } from './open-shift-management-daily-details/open-shift-management-daily-details.component';
import { DailyShiftMessagesContainerComponent } from './daily-shift-messages-container/daily-shift-messages-container.component';
import { DailyShiftMessagesComponent } from './daily-shift-messages/daily-shift-messages.component';
import { EditOpenShiftCountComponent } from './edit-open-shift-count/edit-open-shift-count.component';
import { OpenShiftManagementComponent } from './open-shift-management.component';
import { OpenShiftManagementFiltersComponent } from './open-shift-management-filters/open-shift-management-filters.component';
import { PostScheduleSettingsComponent } from './post-schedule-settings/post-schedule-settings.component';
import { PostScheduleSettingsFormComponent } from './post-schedule-settings-form/post-schedule-settings-form.component';
import { DailyShiftSummaryGridComponent } from './daily-shift-summary-grid/daily-shift-summary-grid.component';
import { DailyShiftMessagesGridComponent } from './daily-shift-messages-grid/daily-shift-messages-grid.component';
import { PostScheduleFormComponent } from './post-schedule-form/post-schedule-form.component';
import { DailyPartialShiftsGridComponent } from './daily-partial-shifts-grid/daily-partial-shifts-grid.component';

export const openShiftManagementComponents: any = [
  OpenShiftManagementScheduleCycleComponent,
  OpenShiftManagementScheduleCycleDetailsComponent,
  OpenShiftManagementDailyDetailsComponent,
  DailyShiftMessagesContainerComponent,
  DailyShiftMessagesComponent,
  EditOpenShiftCountComponent,
  OpenShiftManagementComponent,
  OpenShiftManagementFiltersComponent,
  PostScheduleSettingsComponent,
  PostScheduleSettingsFormComponent,
  DailyShiftSummaryGridComponent,
  DailyShiftMessagesGridComponent,
  PostScheduleFormComponent, DailyPartialShiftsGridComponent
];
