/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./direct-care-hours.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../../../../organization/components/generic-list/generic-grid/generic-grid.component.ngfactory";
import * as i5 from "../../../../organization/components/generic-list/generic-grid/generic-grid.component";
import * as i6 from "../../../../organization/services/generic-list/generic-list-management.service";
import * as i7 from "./direct-care-toolbar/direct-care-toolbar.component.ngfactory";
import * as i8 from "./direct-care-toolbar/direct-care-toolbar.component";
import * as i9 from "../../services/direct-care-hours/direct-care-hours-management.service";
import * as i10 from "../../../../common/services/column-settings/column-management.service";
import * as i11 from "../../../../common/services/state-management/state-management.service";
import * as i12 from "../../../../common/services/device-detector/device-detector.service";
import * as i13 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i14 from "../../../../organization/services/generic-list/generic-list-api.service";
import * as i15 from "../../../../common/services/component-state/component-state-storage.service";
import * as i16 from "./direct-care-hours.component";
var styles_DirectCareHoursComponent = [i0.styles];
var RenderType_DirectCareHoursComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DirectCareHoursComponent, data: {} });
export { RenderType_DirectCareHoursComponent as RenderType_DirectCareHoursComponent };
export function View_DirectCareHoursComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 5, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 3, "slx-generic-grid", [["gridKey", "DIRECT_CARE_HOURS"]], null, null, null, i4.View_GenericGridComponent_0, i4.RenderType_GenericGridComponent)), i1.ɵdid(4, 245760, [[1, 4]], 0, i5.GenericGridComponent, [i6.GenericListManagementService, i1.ChangeDetectorRef], { gridKey: [0, "gridKey"], genericGridConfig: [1, "genericGridConfig"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "slx-direct-care-toolbar", [], null, null, null, i7.View_DirectCareHoursToolbarComponent_0, i7.RenderType_DirectCareHoursToolbarComponent)), i1.ɵdid(6, 245760, null, 0, i8.DirectCareHoursToolbarComponent, [i9.DirectCareHoursManagementService, i6.GenericListManagementService, i10.ColumnManagementService, i11.StateManagementService, i12.DeviceDetectorService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_1 = "DIRECT_CARE_HOURS"; var currVal_2 = _co.genericGridConfig; _ck(_v, 4, 0, currVal_1, currVal_2); _ck(_v, 6, 0); }, null); }
export function View_DirectCareHoursComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-direct-care-hours", [], null, null, null, View_DirectCareHoursComponent_0, RenderType_DirectCareHoursComponent)), i1.ɵprd(4608, null, i10.ColumnManagementService, i10.ColumnManagementService, [i13.ColumnSettingsStorageService]), i1.ɵprd(512, null, i9.DirectCareHoursManagementService, i9.DirectCareHoursManagementService, []), i1.ɵprd(512, null, i6.GenericListManagementService, i6.GenericListManagementService, [i14.GenericListApiService]), i1.ɵprd(512, null, i11.StateManagementService, i11.StateManagementService, [i15.ComponentStateStorageService, i13.ColumnSettingsStorageService]), i1.ɵdid(5, 245760, null, 0, i16.DirectCareHoursComponent, [i9.DirectCareHoursManagementService, i6.GenericListManagementService, i11.StateManagementService], null, null)], function (_ck, _v) { _ck(_v, 5, 0); }, null); }
var DirectCareHoursComponentNgFactory = i1.ɵccf("slx-direct-care-hours", i16.DirectCareHoursComponent, View_DirectCareHoursComponent_Host_0, {}, {}, []);
export { DirectCareHoursComponentNgFactory as DirectCareHoursComponentNgFactory };
