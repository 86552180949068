import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/buffer';
import 'rxjs/add/operator/debounceTime';
import * as _ from 'lodash';
import { EmployeePhoto } from '../../models/index';
import { EmployeeDefinitionsApiService } from './employee-definitions-api.service';


@Injectable()
export class EmployeeImagesService {

  private employeeThimbinalRequestSubscription: Subscription;
  private employeeThumbinalRequest$: Subject<number>;
  private employeeThumbinals$: Subject<NumberMap<EmployeePhoto>>;
  private thumbinalsCache: NumberMap<EmployeePhoto> = {};
  private delayed: Observable<boolean>;

  constructor(private employeeDefinitionsApiService: EmployeeDefinitionsApiService) {
    this.employeeThumbinalRequest$ = new Subject();
    this.employeeThumbinals$ = new Subject();

    const bufferTime = this.employeeThumbinalRequest$.buffer(this.employeeThumbinalRequest$.debounceTime(300));
    this.employeeThimbinalRequestSubscription = bufferTime.subscribe((employeeIds: number[]) => {
      if (employeeIds.length === 0) {
        return;
      }
      this.employeeDefinitionsApiService.getEmployeeThumbnails(employeeIds)
        .then((employeePhotos: EmployeePhoto[]) => {
          const res: NumberMap<EmployeePhoto> = _.keyBy(employeePhotos, (photo: EmployeePhoto) => photo.employeeId);
          _.merge(this.thumbinalsCache, res);
          this.employeeThumbinals$.next(res);
        });
    });
  }
  public updateThumbinalsCache(employeeIds: number[]): void {
    this.employeeDefinitionsApiService.getEmployeeThumbnails(employeeIds)
    .then((employeePhotos: EmployeePhoto[]) => {
      const res: NumberMap<EmployeePhoto> = _.keyBy(employeePhotos, (photo: EmployeePhoto) => photo.employeeId);
      _.merge(this.thumbinalsCache, res);
      this.employeeThumbinals$.next(res);
    });
  }
  public getEmployeeThumbnail(employeeId: number): Promise<EmployeePhoto> {
    if (this.thumbinalsCache[employeeId]) {
      return Promise.resolve(this.thumbinalsCache[employeeId]);
    }
    this.employeeThumbinalRequest$.next(employeeId);
    return this.employeeThumbinals$
      .filter((res: NumberMap<EmployeePhoto>) => {
        return !!res[employeeId];
      })
      .map((res: NumberMap<EmployeePhoto>) => {
        return res[employeeId];
      })
      .first()
      .toPromise();
  }
}
