
import { ShiftEligibleEmployee } from '../index';

export class ShiftReplacementSms {
  public message: string;
  public recipients: ShiftEligibleEmployee[];

  constructor(message: string = '', recipients: ShiftEligibleEmployee[] = []) {
    this.message = message;
    this.recipients = recipients;
  }

  public get canSend(): boolean {
    return this.message && this.message.length > 0 &&
      this.recipients && this.recipients.length > 0;
  }
}
