import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { DailyAbsence, TimeCardModel } from '../../../models/index';
import { DailyTimecardManagementService, resetAction } from '../../../services/index';
import { NotificationsService } from '../../../../core/components/index';
import { ChangeManagementService, ModalService } from '../../../../common/services/index';
import { appConfig } from '../../../../app.config';
import { TimecardAbsencesEditorDialogComponent } from '../timecard-absences-editor-dialog/timecard-absences-editor-dialog.component';
var TimeCardAbsencesListComponent = /** @class */ (function () {
    function TimeCardAbsencesListComponent(managementService, notificationsService, changeManagementService, modalService) {
        this.managementService = managementService;
        this.notificationsService = notificationsService;
        this.changeManagementService = changeManagementService;
        this.modalService = modalService;
        this.absCount = 0;
        this.changeGroup = 'TimeCardAbsencesListComponent';
        this.appConfig = appConfig;
    }
    Object.defineProperty(TimeCardAbsencesListComponent.prototype, "model", {
        get: function () {
            return this.m_model;
        },
        set: function (value) {
            if (!value) {
                return;
            }
            this.m_model = value;
            this.records = value.absences;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    TimeCardAbsencesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetSubscription = this.managementService.resetSection$.filter(function (m) { return m.target === 'absences'; }).subscribe(function (m) {
            _this.records = m.model.absences;
            _this.refreshGrid();
        });
        this.canEditChangedSubscription = this.managementService.canEditChanged$
            .subscribe(function (canEdit) {
            _this.canEditTimecard = canEdit;
        });
        this.optionsChangedSubscription = this.managementService.displayOptionSelected$
            .subscribe(function (options) {
            _this.displayOptions = options;
        });
        this.abscenceCountSubscription = this.managementService.abscenceCount$.subscribe(function (v) {
            _this.absCount = v;
        });
    };
    TimeCardAbsencesListComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TimeCardAbsencesListComponent.prototype.addAbsence = function () {
        var _this = this;
        if (!this.canEditTimecard) {
            this.modalService.globalAnchor.openInfoDialog("Warning", 'Absences can\'t be added');
            return;
        }
        var absence = new DailyAbsence();
        absence.start = this.model.date;
        absence.end = this.model.date;
        absence.interval = 0;
        absence.calculateInterval();
        TimecardAbsencesEditorDialogComponent.openDialog('Add new absence', absence, this.modalService, this.managementService, function (result, item) {
            if (result) {
                _this.records.push(absence);
                _this.changeManagementService.changeNotify(_this.changeGroup);
                _this.refreshGrid();
                _this.absCount = _this.recordsToShow.length;
                _this.managementService.updateAbscenseCount(_this.absCount);
            }
        });
    };
    TimeCardAbsencesListComponent.prototype.editHandler = function (rowIndex, dataItem) {
        var _this = this;
        var itemToEdit = _.cloneDeep(dataItem);
        TimecardAbsencesEditorDialogComponent.openDialog('Edit absence', itemToEdit, this.modalService, this.managementService, function (result, item) {
            if (result) {
                _.merge(dataItem, itemToEdit);
                _this.changeManagementService.changeNotify(_this.changeGroup);
                _this.refreshGrid();
            }
        });
    };
    TimeCardAbsencesListComponent.prototype.removeHandler = function (rowIndex, dataItem) {
        if (!dataItem) {
            return;
        }
        if (dataItem.isServerSide) {
            dataItem.isDeleted = true;
        }
        else {
            this.records.splice(rowIndex, 1);
        }
        this.refreshGrid();
        this.absCount = this.recordsToShow.length;
        this.managementService.updateAbscenseCount(this.absCount);
    };
    TimeCardAbsencesListComponent.prototype.discardChanges = function () {
        this.changeManagementService.clearChanges(this.changeGroup);
        this.managementService.onAbsenceDiscardAction();
    };
    TimeCardAbsencesListComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.recordsToShow = null;
            return;
        }
        this.recordsToShow = _.filter(this.records, function (item) { return !item.isDeleted; });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesListComponent.prototype, "modelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesListComponent.prototype, "discardSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesListComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesListComponent.prototype, "resetSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesListComponent.prototype, "canEditChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesListComponent.prototype, "optionsChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeCardAbsencesListComponent.prototype, "abscenceCountSubscription", void 0);
    return TimeCardAbsencesListComponent;
}());
export { TimeCardAbsencesListComponent };
