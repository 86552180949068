<form novalidate #form="ngForm">
  <div class="row">
    <div class="col-xs-6 col-sm-6 col-md-4 col-lg-4">
      <slx-input-decorator>
        <slx-dropdown-input autoSelectFirst=true slx-input name="availabilitySelector" [readonly]="!state.isEditMode || !isAnyAvailabilityRecord"
          placeholder="Availability Records" [lookup]="availabilityLookup" [(ngModel)]="availabilityDateRange" (ngModelChange)="onSelectedRecordChanged($event)">
        </slx-dropdown-input>
      </slx-input-decorator>
    </div>
    <div *ngIf="state.isEditMode" class="col-xs-3 col-sm-3 col-md-1 col-lg-1">
      <slx-input-decorator>
        <slx-dropdown-input slx-input name="hoursSelector" [readonly]="!state.isEditMode" placeholder="Hours" [options]="configHours"
          valueField="count" titleField="count" [(ngModel)]="selectedConfigHour">
        </slx-dropdown-input>
      </slx-input-decorator>
    </div>
    <div *ngIf="state.isEditMode" class="col-xs-3 col-sm-3 col-md-1 col-lg-1 clear-all-records">
      <button class="theme-iconed-accent-button" [popover]="acceptPopover" name="clearAvailability" id="clearAvailability">
        <i class="fa fa-eraser" aria-hidden="true"></i>Clear All</button>
      <popover-content #acceptPopover title="Are you sure?" placement="auto top">
        <div style="width:110px">
          <button type="button" style="width:50px" (click)="acceptPopover.hide()" class="theme-button-cancel margin-r">No</button>
          <button type="button" style="width:50px" class="theme-button-apply" (click)="onAcceptClearAvailability(acceptPopover)">Yes</button>.
        </div>
      </popover-content>
    </div>
  </div>
  <div class="row">
    <slx-date-range-ngx
      name="startEndDate"
      [startDateReadonly]="!state.isEditMode"
      [endDateReadonly]="!state.isEditMode"
      [startDate]="startDate"
      [endDate]="endDate"
      (rangeDateChanged)="onFilterDateChanged($event)"
    ></slx-date-range-ngx>
  </div>
  <div [ngClass]="{'hours-box-blocked': !state.isEditMode}">
    <div>
      <div class="caption"></div>
      <div class="caption"></div>
      <div style="display:inline-block; padding-left: 5px" *ngFor="let hour of hours">
        <div class="hourboxCaption">{{hour}}a</div>
      </div>
      <div class="caption"></div>
      <div style="display:inline-block; padding-left: 5px" *ngFor="let hour of hours">
        <div class="hourboxCaption">{{hour}}p</div>
      </div>
    </div>
    <div *ngFor="let availabilityDay of weeklyAvailability?.days">
      <div class="caption">{{availabilityDay.day.shortName}}</div>
      <div class="caption">AM</div>
      <div style="display:inline-block; padding-left: 5px" *ngFor="let hour of availabilityDay.hoursAM">
        <div class="hourbox" [ngClass]="{'available-setting': hour.isAvailable }" (click)="onHourClick(hour)"></div>
      </div>
      <div class="caption">PM</div>
      <div style="display:inline-block; padding-left: 5px" *ngFor="let hour of availabilityDay.hoursPM">
        <div class="hourbox" [ngClass]="{'available-setting': hour.isAvailable }" (click)="onHourClick(hour)"></div>
      </div>
    </div>
  </div>
</form>
