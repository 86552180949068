import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { dateTimeUtils } from '../../../../common/utils/index';
import { LookupMapService } from '../../../../organization/services/index';

import { IWorkerCompTableModel, WorkCompTableTypeModel, WorkCompTableEditResult, IWorkCompTableEditResult, WcCodeDefinition, IWcCodeDefinitionDto } from '../../models/index';

@Injectable()
export class WCTMapService {
  constructor(private lookupMapService: LookupMapService) {

  }

  public mapWCTModelContainer(dtos: IWorkerCompTableModel[]): WorkCompTableTypeModel[] {
    return _.map(dtos, (dto: IWorkerCompTableModel) => this.mapWCTModel(dto))
  }

  public mapCodeTypes(dtos: any[]): any[]{
    return dtos;
  }
  
  public mapCodeDefinition(dto: IWcCodeDefinitionDto): WcCodeDefinition {
    let data = new WcCodeDefinition();
    data.id = dto.id;
    data.code = dto.code;
    data.codeType = dto.codeType;
    data.description = dto.description;
    return data;
  }

  public mapWCTModel(dto: IWorkerCompTableModel): WorkCompTableTypeModel {
    let data = new WorkCompTableTypeModel();
    data.codeDefinition = this.lookupMapService.mapCodeDefinition(dto.codeDefinition);; //this.mapCodeDefinition(dto.codeDefinition);
    data.modifiedBy = dto.modifiedBy;
    data.modifiedAt = dateTimeUtils.convertFromDtoDateString(dto.modifiedAt);
    return data;
  }

  public mapWorkrCompTableModelToDto(data: WorkCompTableTypeModel): IWorkerCompTableModel {
    if (_.isObjectLike(data)) {
      return {
        codeDefinition: this.mapWorkrsCompTableToDto(data.codeDefinition),
        modifiedBy: data.modifiedBy,
        modifiedAt: dateTimeUtils.convertToDtoDateTimeString(data.modifiedAt)
      };
    }
    return null;
  }

  public mapWorkrsCompTableToDto(type: WcCodeDefinition): IWcCodeDefinitionDto {
    if (_.isObjectLike(type)) {
      return {
        id: type.id,
        code: type.code.trim(),
        codeType : type.codeType,
        description: type.description
      };
    }
    return null;
  }

  public mapCodeDefinitionEditResult(dto: IWorkCompTableEditResult): WorkCompTableEditResult {
    const data = new WorkCompTableEditResult();
    data.isSuccess = dto.isSuccess;
    data.message = dto.message;
    data.item = dto.item ? this.mapWCTModel(dto.item): null;
    return data;
  }

}


