import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Assert } from '../../../../framework/index';
import { EmployeeRehire, IEmployeeRehire } from '../../models/index';
import { LookupMapService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class EmployeeRehireMapService {

  private lookupMapService: LookupMapService;

  public mapToRehireEmployeeDto(data: EmployeeRehire, newRehireParam: string): IEmployeeRehire {
    Assert.isNotNull(data, 'data');
    let dto: IEmployeeRehire = {
      rehireDate: dateTimeUtils.convertToDtoUTC(data.date),
      positionId: data.position ? data.position.id : 0,
      payRate: data.payRate,
      payPolicy: data.payPolicy ? data.payPolicy.name : null,
      shiftDiffPolicy: data.shiftDiffPolicy ? data.shiftDiffPolicy.name : null,
      jobCode: null,
      approvalInd: null,
      approvalId: null,
      benefitClassId: data.benefitClass ? data.benefitClass.id : null,
      benefitClassEffectiveDate: data.benefitClassEffectiveDate ? dateTimeUtils.convertToDtoString(data.benefitClassEffectiveDate) : null,
      mobilePhone: data.mobilePhone,
      optInRequest: data.optInRequest,
      oldOrganizationId: data.oldOrganizationId,
      oldEmployeeType: data.oldEmployeeType,
      payrollNumber: data.payrollNumber
    };
    if(newRehireParam){
      dto['employeeType'] = data.employeeType ?  data.employeeType.name : null;
      dto['organizationId'] = data.organization ? data.organization.id: 0;
      dto['departmentId'] = data.department ? data.department.id: 0;
      dto['payType'] = data.payType ? data.payType.name : null;
      dto['exemptStatus'] = data.exemptStatus ? data.exemptStatus.name : null;
      dto['unitId'] = data.unit ? data.unit.id : null;
      dto['payrollNumber'] = data.payrollNumber ? data.payrollNumber : null;
      dto['oldOrganizationId'] = data.oldOrganizationId;
      dto['oldEmployeeType'] = data.oldEmployeeType;
    }
    return dto;
  }
}

