export class ActionsType {
  public static edit: string = 'edit';
  public static add: string = 'add';
  public static delete: string = 'delete';
  public static approve: string = 'approve';
  public static submit: string = 'submit';
  public static noop: string = 'noop';
  public static exportToExcel: string = 'exportToExcel';
  public static exportToPdf: string = 'exportToExcel';
  public static flag: string = 'flag';
}

export class Actions {
  public canEdit: boolean;
  public canAdd: boolean;
  public canDelete: boolean;
  public canApprove: boolean;
  public canSubmit: boolean;
  public canExportToExcel: boolean;
  public canExportToPdf: boolean;
  public canFlag: boolean;
}
