export enum PayrollAppStatus {
    Off = 'Off',
    Migration = 'Migration',
    Live = 'Live',
    LiveWithTAExport = 'Live With TA Export'
}

export class OrgPayrollAppStatus {
    public id: string;
    public name: string;
    constructor(id: string, name: string) {
      this.id = id;
      this.name = name;
    }
}