import * as tslib_1 from "tslib";
import { CacheUtilService } from '../../../common/services/cache/cache.service';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';
import { ApiUtilService } from '../../../common/services/api/api-util.service';
import { configurationConfig } from '../../configuration.config';
import { UserLoginManagementMapService } from './user-login-management-map.service';
import { appConfig } from '../../../app.config';
var UserLoginManagementApiService = /** @class */ (function () {
    function UserLoginManagementApiService(map, apiUtilService, urlParamsService, cacheUtilService) {
        this.map = map;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.cacheUtilService = cacheUtilService;
    }
    UserLoginManagementApiService.prototype.logOffSelected = function (users) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiRoot() + "/" + this.getConfRoot() + "/" + this.getConfUserRoot() + "/" + this.getMngtRoot() + "/" + this.getLogoffUrl();
                        request = this.urlParamsService.createPostRequest(url, users);
                        return [4 /*yield*/, this.apiUtilService.request(request)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    UserLoginManagementApiService.prototype.getUserSessions = function (isActiveOnly) {
        var _this = this;
        var url = this.getApiRoot() + "/" + this.getConfRoot() + "/" + this.getConfUserRoot() + "/" + this.getMngtRoot() + "/" + this.getSessionsUrl();
        var request = this.urlParamsService.createGetRequest(url, { isActiveOnly: isActiveOnly });
        return this.apiUtilService.request(request).then(function (response) {
            return _this.map.mapUserManagementInfo(response.data);
        });
    };
    UserLoginManagementApiService.prototype.getLogoffUrl = function () {
        return configurationConfig.api.configuration.users.management.logoff;
    };
    UserLoginManagementApiService.prototype.getSessionsUrl = function () {
        return configurationConfig.api.configuration.users.management.sessions;
    };
    UserLoginManagementApiService.prototype.getMngtRoot = function () {
        return configurationConfig.api.configuration.users.management.root;
    };
    UserLoginManagementApiService.prototype.getConfUserRoot = function () {
        return configurationConfig.api.configuration.users.root;
    };
    UserLoginManagementApiService.prototype.getConfRoot = function () {
        return configurationConfig.api.configuration.root;
    };
    UserLoginManagementApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return UserLoginManagementApiService;
}());
export { UserLoginManagementApiService };
