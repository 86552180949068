import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ShiftGroupManagementService } from './../../../../../app/configuration/services/shift-group/shift-group-management.service';
import { appConfig } from './../../../../../app/app.config';
import { ConfigurationComponentHelper } from './../../../../../app/configuration/utils/configuration-component-helper';
import { KendoGridCustomSelectionHelper, KendoGridStateHelper } from './../../../../../app/common';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NgForm } from '@angular/forms';
import { unsubscribe } from './../../../../../app/core/decorators';
import { Subscription } from 'rxjs';
import { dateTimeUtils } from './../../../../../app/common/utils';
import * as _ from 'lodash';
var ShiftGroupGridComponent = /** @class */ (function () {
    function ShiftGroupGridComponent(management) {
        var _this = this;
        this.management = management;
        this.columnsGroupName = 'ConfigureShiftGroup';
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
        });
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
    }
    Object.defineProperty(ShiftGroupGridComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShiftGroupGridComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    ShiftGroupGridComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: true,
            canEdit: true,
            canAdd: true,
            canDelete: true
        };
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
    };
    ShiftGroupGridComponent.prototype.rowCallback = function (context) {
        if (context.dataItem.isDirty) {
            return { 'dirty-item': true };
        }
        return {};
    };
    ShiftGroupGridComponent.prototype.onShiftGroupDateChanged = function (shiftGroup) {
        var hours = dateTimeUtils.getDurationDiffHours(shiftGroup.start, shiftGroup.end);
        shiftGroup.shiftGroupWorkHours = hours;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "savedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "removeStartSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "defaultsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "mainFormSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftGroupGridComponent.prototype, "editSubscription", void 0);
    return ShiftGroupGridComponent;
}());
export { ShiftGroupGridComponent };
