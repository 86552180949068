<div class="report-section">
    <div class="report-section-header">
        <div class="report-section-header-content">
            <div class="header-item header-title">
                <i class="{{icon}}"></i>
                <span class="header-title__text">{{title}}</span>
                <span *ngIf="notifications > 0" class="header-title__badge">{{notifications}}</span>
            </div>
        </div>
        <div class="report-section-header-button-right">
            <slx-collapse-button [isCollapsed]="isCollapsed" className="slx-white-icon"
                (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent">
            </slx-collapse-button>
        </div>
    </div>
    <div #collapseContent class="report-section-content">
        <ng-content select=".panel-content"></ng-content>
    </div>
</div>