var IndividualTimecardsDay = /** @class */ (function () {
    function IndividualTimecardsDay() {
    }
    Object.defineProperty(IndividualTimecardsDay.prototype, "warningMessage", {
        get: function () {
            var msg = [];
            if (this.isNoShow) {
                msg.push('No Show');
            }
            if (this.isInvalidPunch) {
                msg.push('Invalid punches');
            }
            if (this.isInvalidLogin) {
                msg.push('Invalid login');
            }
            return msg.join();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsDay.prototype, "shiftStart", {
        get: function () {
            if (!this.shift) {
                return null;
            }
            return this.shift.start;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsDay.prototype, "shiftEnd", {
        get: function () {
            if (!this.shift) {
                return null;
            }
            return this.shift.end;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsDay.prototype, "departmentName", {
        get: function () {
            if (!this.shift) {
                return null;
            }
            return this.shift.department.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsDay.prototype, "positionName", {
        get: function () {
            if (!this.shift) {
                return null;
            }
            return this.shift.position.name;
        },
        enumerable: true,
        configurable: true
    });
    return IndividualTimecardsDay;
}());
export { IndividualTimecardsDay };
