import { ModalService } from './../../../../common/services/modal/modal.service';
import { DialogOptions } from './../../../../common/models/dialog-options';
import { ShiftSwapInfo } from '../../../models/shift-swap/shift-swap-info';
import { Component, Provider } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-shift-swap-approve-deny-popup',
    templateUrl: 'shift-swap-approve-deny-popup.component.html'
})
export class ShiftSwapApproveDenyPopupComponent {

    public options: DialogOptions;
    public dialogResult: boolean;
    public shiftSwap: ShiftSwapInfo;

    public static openDialog(shiftSwap: ShiftSwapInfo, modalService: ModalService, callback: (result: boolean) => void): ShiftSwapApproveDenyPopupComponent {
        let dialogOptions: DialogOptions = new DialogOptions();
        dialogOptions.height = 500;
        dialogOptions.width = 500;
        let resolvedProviders: Provider[] = [
        {
            provide: DialogOptions,
            useValue: dialogOptions
        },
        {
            provide: ShiftSwapInfo,
            useValue: shiftSwap
        }
        ];
        let dialog: ShiftSwapApproveDenyPopupComponent = modalService.globalAnchor
        .openDialog(ShiftSwapApproveDenyPopupComponent, 'Shift Swap', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
            callback(result);
        });
        return dialog;
    }

    constructor(options: DialogOptions, private modalService: ModalService, shiftSwap: ShiftSwapInfo) {
      this.options = options;
      this.shiftSwap = shiftSwap;
    }

    public onButtonClicked($event: number): void {
        this.dialogResult = $event > 0;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }
}
