<div class="left-sidebar" [ngClass]="{ 'show': isOpened }">
  <div class="left-sidebar-logo">
    <div class="left-sidebar-logo-text"></div>
    <div class="pin-placement">
    <a (click)="unPinMenu()" *ngIf="appStateManagementService.isMenuPinned"><span><i class="pin fas fa-thumbtack" aria-hidden="true"></i></span></a>  
    <a (click)="pinMenu()" *ngIf="!appStateManagementService.isMenuPinned"><span><i class="slx-rotate-90 fas fa-thumbtack" aria-hidden="true"></i></span></a>  
    </div>
  </div>
  <div class="user-info-container">
    <slx-user-info></slx-user-info>
  </div>
  <div class="slx-navigation-menu-ignore orglevel-container">
    <slx-org-level-breadcrumb [mode]="orgLevelBreadcrumbsMenuMode"></slx-org-level-breadcrumb>
  </div>
  <div class="left-sidebar-container slx-navigation-menu-ignore">
    <slx-navigation-menu [isOpened]="isOpened"></slx-navigation-menu>
  </div>
  <slx-bottom-menu></slx-bottom-menu>
</div>

