import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogModeSize, DialogOptions, DialogOptions2, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { ScheduleCycleMessages, ScheduleCycleSummaryMessage, ScheduleCycleSummaryViewAction } from '../../../models/index';

import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-cycle-summary-dialog',
  templateUrl: 'schedule-cycle-summary-dialog.component.html',
  styleUrls: ['schedule-cycle-summary-dialog.component.scss']
})
export class ScheduleCycleSummaryDialogComponent implements IDialog {

  public options: DialogOptions;
  public dialogResult: boolean;
  public summary: ScheduleCycleMessages;
  public action: ScheduleCycleSummaryViewAction;
  private modalService: ModalService;

  public static openDialog(request: ScheduleCycleMessages, modalService: ModalService, callback: (action: ScheduleCycleSummaryViewAction) => void): ScheduleCycleSummaryDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ScheduleCycleMessages,
        useValue: request
      }
    ];

    let dialog: ScheduleCycleSummaryDialogComponent = modalService.globalAnchor
      .openDialog2(ScheduleCycleSummaryDialogComponent,
      `Generate Schedule Result (${moment(request.scheduleCycleStartDate).format(appConfig.dateFormat)}-${moment(request.scheduleCycleEndDate).format(appConfig.dateFormat)})`,
      dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        if (result) {
          callback(dialog.action);
        } else {
          callback(null);
        }
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    summary: ScheduleCycleMessages
  ) {
    this.modalService = modalService;
    this.options = options;
    this.summary = summary;
  }

  public onAction(action: ScheduleCycleSummaryViewAction): void {
    this.action = action;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
