import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta, FieldsMeta } from '../../../core/models/index';
import { UrlParamsService, ApiUtilService, CacheUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { TimeclocksMapService } from './timeclocks-map.service';
import { configurationConfig } from '../../configuration.config';
import { Timeclock, ITimeclock, TimeclockContainer, TimeclockRemoveRequest } from '../../models/index';


@Injectable()
export class TimeclocksApiService {
  constructor(private apiUtilService: ApiUtilService,
              private urlParamService: UrlParamsService,
              private mapService: TimeclocksMapService,
              private cacheUtilService: CacheUtilService) {
  }

  public addTimeclock(timeclock: Timeclock, orgLevelId: number): Promise<Timeclock> {
    Assert.isNotNull(timeclock, 'addTimeclock timeclock');
    let url: string = `${this.getUrlRoot(orgLevelId)}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, this.mapService.convertTimeclockToDto(timeclock));

    let promise: Promise<Timeclock> = this.apiUtilService.request<ITimeclock, Meta>(request)
      .then((response: ResponseBody<ITimeclock, Meta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapTimeclock(response.data);
      });
    return promise;
  }

  public saveTimeclock(timeclock: Timeclock, orgLevelId: number): Promise<Timeclock> {
    Assert.isNotNull(timeclock, 'saveTimeclock timeclock');
    let url: string = `${this.getUrlRoot(orgLevelId)}/${timeclock.id}`;
    let request: HttpRequest<any> = new HttpRequest('PUT', url, this.mapService.convertTimeclockToDto(timeclock));

    let promise: Promise<Timeclock> = this.apiUtilService.request<ITimeclock, Meta>(request)
      .then((response: ResponseBody<ITimeclock, Meta>) => {
        this.cacheUtilService.clear();
        return this.mapService.mapTimeclock(response.data);
      });
    return promise;
  }

  public removeTimeclock(req: TimeclockRemoveRequest, orgLevelId: number): Promise<any> {
    Assert.isNotNull(req, 'removeTimeclock TimeclockRemoveRequest');
    let url: string = `${this.getUrlRoot(orgLevelId)}/${req.timeclock.id}`;
    let request: HttpRequest<any> = new HttpRequest('DELETE', url, {
      params: this.urlParamService.convertToParams({
        deleteAllLinkedVirtualClocks: req.deleteAllLinkedVirtualClocks,
        unassignAllEmployees: req.unassignAllEmployees
      })
    });

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.cacheUtilService.clear();
        return response.status;
      });
    return promise;
  }

  public getTimeclocks(orgLevelId: number): Promise<TimeclockContainer> {
    let request: HttpRequest<any> = new HttpRequest('GET', this.getUrlRoot(orgLevelId));
    //let promise: Promise<TimeclockContainer> = Promise.resolve(this.mapService.mapTimeclocks(TimeclockContainer.mock, TimeclockContainer.metaMock));
    let promise: Promise<TimeclockContainer> = this.apiUtilService.request<ITimeclock[], FieldsMeta>(request)
      .then((response: ResponseBody<ITimeclock[], FieldsMeta>) => this.mapService.mapTimeclocks(response.data, response.meta));
    return promise;
  }

  public getTimeclocksForEmlployee(orgLevelId: number,empId?: number): Promise<TimeclockContainer> {
    let url: string = `${this.getUrlRoot(orgLevelId)}/${empId}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<TimeclockContainer> = this.apiUtilService.request<ITimeclock[], FieldsMeta>(request)
      .then((response: ResponseBody<ITimeclock[], FieldsMeta>) => this.mapService.mapTimeclocks(response.data, response.meta));
    return promise;
  }

  private getUrlRoot(orgLevelId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.timeclocks.root}`;
  }
}
