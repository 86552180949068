import { Component, OnInit } from '@angular/core';
import { EmployeePunchRequestManagementService } from './../../../../../app/time-and-attendance/services/punches/employee-punch-request-management.service';
import { DateRange, IDateRange } from '../../../../core/models/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LookupApiService, PayCycle } from './../../../../../app/organization';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from './../../../../common/services/state-management/state-management.service';
import { StateResetTypes } from '../../../../core/models/settings/reset-types';
import { IControlState, ControlStateKey } from '../../../../core/models/settings/component-states';


@Component({
  selector: 'slx-employee-punch-request-toolbar',
  templateUrl: './employee-punch-request-toolbar.component.html',
  styleUrls: ['./employee-punch-request-toolbar.component.scss']
})

export class EmployeePunchRequestToolbarComponent implements OnInit {

  public dateRange: DateRange = new DateRange(null, null);

  constructor(
    public manageService: EmployeePunchRequestManagementService,
    private lookupApiService: LookupApiService,
    private storageService: ComponentStateStorageService,
    private stateManagement: StateManagementService,

    ) { }

  public startDate: Date;
  public endDate: Date;
  private stateKey: ControlStateKey;

  public ngOnInit(): void {
    this.manageService.init();
    //this.definePayCycleDateRange();
    //values unte it will restore
    this.restoreFilters();
  }

  public ngOnDestroy(): void { }

  public onChangeDates(range: IDateRange): void {
    this.saveFilters(range);
    this.manageService.changeDateRange(range);
  }

  public definePayCycleDateRange(): void {
    const promise: Promise<PayCycle[]> = this.lookupApiService.getPayCyles(this.manageService.orgLevelId);
    promise.then((cycles: PayCycle[]) => {
      const currentDate: moment.Moment = moment();
      const currentCycles = _.filter(cycles, (cycle) => {
        const currStartDate: moment.Moment = moment(cycle.startDate);
        const currEndDate: moment.Moment = moment(cycle.endDate);
        return moment(currentDate).isBetween(currStartDate, currEndDate);
      });
      const payCycle: PayCycle = _.first(_.orderBy(cycles, c => moment(c.endDate), ['desc']));
      this.dateRange = new DateRange(payCycle.startDate, new Date());
      this.manageService.changeDateRange(this.dateRange);
    });
  }

  private restoreFilters(): void {
    let state: IControlState = this.storageService.getControlState(this.stateManagement.componentKey, 'datafiltersepr');
    if (state && state.value) {
      if (state.value.startDate) this.startDate = moment(state.value.startDate).toDate();
      if (state.value.endDate) this.endDate = moment(state.value.endDate).toDate();
      if(this.startDate && this.endDate)
        this.dateRange = new DateRange(this.startDate,this.endDate);
    }
    if(this.dateRange.startDate == null || this.dateRange.endDate == null){
      this.definePayCycleDateRange();
    }
      else{
    this.manageService.changeDateRange(this.dateRange);
      }
  }


  private saveFilters(range:IDateRange): void {
    this.storageService.setControlState(this.stateManagement.componentKey,'datafiltersepr',
      {
        value: { startDate: range.startDate, endDate: range.endDate }
      },
      StateResetTypes.SessionEnd | StateResetTypes.MenuChange, this.stateKey);
    this.stateManagement.controlValueChanged('datafiltersepr');
  }

}
