import { ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(elementRef) {
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.elementRef = elementRef;
    }
    Object.defineProperty(CalendarComponent.prototype, "minDate", {
        set: function (momentValue) {
            this.minDateMoment = momentValue;
            if (this.kendoCalendar) {
                this.kendoCalendar.navigate(this.internalValue.toDate(), 'month');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CalendarComponent.prototype, "maxDate", {
        set: function (momentValue) {
            this.maxDateMoment = momentValue;
            if (this.kendoCalendar) {
                this.kendoCalendar.navigate(this.internalValue.toDate(), 'month');
            }
        },
        enumerable: true,
        configurable: true
    });
    CalendarComponent.prototype.ngOnInit = function () {
        this.createCalendar();
    };
    CalendarComponent.prototype.writeValue = function (value) {
        this.internalValue = value;
        this.kendoCalendar.value(this.internalValue && this.internalValue.toDate ? this.internalValue.toDate() : new Date());
    };
    CalendarComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    CalendarComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    CalendarComponent.prototype.createCalendar = function () {
        var _this = this;
        this.kendoCalendar = $(this.elementRef.nativeElement).kendoCalendar({
            disableDates: function (d) {
                return _this.compareDates(d);
            },
            change: function (e) {
                _this.onChangeCallback(moment(e.sender.current()));
            }
        });
        this.kendoCalendar = $(this.elementRef.nativeElement).data('kendoCalendar');
    };
    CalendarComponent.prototype.compareDates = function (date) {
        var disable = false;
        if (this.minDateMoment) {
            disable = this.minDateMoment.isAfter(moment(date));
        }
        if (this.maxDateMoment && !disable) {
            disable = this.maxDateMoment.isBefore(moment(date));
        }
        return disable;
    };
    return CalendarComponent;
}());
export { CalendarComponent };
