import {
    EmployeeDefinition, IEmployeeDefinition, Position, IPosition
} from '../../../organization/models/index';

export interface IArrivalDetails {
  employee: IEmployeeDefinition;
  position: IPosition;
  scheduleStart: string;
  scheduleEnd: string;
  actualStart: string;
  actualEnd: string;
  isScheduled: boolean;
  isIn: boolean;
  isDiff: boolean;
  isLate: boolean;
}

export class ArrivalDetails {
  public employee: EmployeeDefinition;
  public position: Position;
  public scheduleStart: Date;
  public scheduleEnd: Date;
  public actualStart: Date;
  public actualEnd: Date;
  public isScheduled: boolean;
  public isIn: boolean;
  public isDiff: boolean;
  public isLate: boolean;
}
