import { Component, OnInit, Input, Host, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { EmployeeSectionsRateHistory, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';


import { GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-rate-history',
  templateUrl: 'employee-sections-rate-history.component.html',
  styleUrls: ['employee-sections-rate-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsRateHistoryComponent extends EmployeeSectionsBasicComponent implements OnInit {

  @Input('employeeSectionsRateHistory')
  public set rateHistory(employeeSectionsRateHistory: EmployeeSectionsRateHistory) {
    this.employeeSectionsRateHistory = employeeSectionsRateHistory;
    this.refreshGrid();
  }
  @Input()
  public employeeId: number;
  
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  public get form(): AbstractControl {
    return null;
  }

  public employeeSectionsRateHistory: EmployeeSectionsRateHistory;

  constructor(
    private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    private changeDetector: ChangeDetectorRef,
    ngZone: NgZone
  ) {
    super(decorator, ngZone);
    this.sort = [];
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsRateHistory;
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public onRefreshClicked(): void {
    this.loadSubsection();
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmploymentRateHistory(this.employeeId)
      .then((employeeSectionsRateHistory: EmployeeSectionsRateHistory) => {
        this.employeeSectionsRateHistory = employeeSectionsRateHistory;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.stopProgress();
      })
      .catch(() => this.stopProgress());
  }

  private refreshGrid(): void {
    if (!this.employeeSectionsRateHistory) {
      this.gridView = null;
      return;
    }
    this.gridView = {
      data: orderBy(this.employeeSectionsRateHistory.records, this.sort),
      total: this.employeeSectionsRateHistory.records.length
    };
  }

  public get setDecimalLimit(): number {
    if (this.employeeSectionsRateHistory.isEnableRate4DecimalPlaces) {
      return 4;
    } else
      return 2;
  }
}
