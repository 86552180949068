import { Injectable } from '@angular/core';
import { Holiday, IHoliday, HolidayRule } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import * as _ from 'lodash';

@Injectable()
export class HolidaysMapService {

    public mapHolidays(holidaysDto: IHoliday[]): Holiday[] {
        let holidays: Holiday[] = [];
        _.each(holidaysDto, (dto: IHoliday) => {
            holidays.push(this.mapHoliday(dto));
        });
        return holidays;
    }

    public mapHoliday(dto: IHoliday): Holiday {
        let h: Holiday = new Holiday();
        h.id = dto.id;
        h.code = dto.code;
        h.name = dto.name;
        h.updatedBy = dto.lastUpdateUsername;
        h.updatedOn = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
        h.date = dateTimeUtils.convertFromDtoString(dto.date);
        h.start = dateTimeUtils.convertFromDtoString(dto.start);
        h.end = dateTimeUtils.convertFromDtoString(dto.end);
        h.type = dto.type;
        h.origin = dto.origin;
        if(dto.definingRule) {
            h.rule = new HolidayRule();
            h.rule.dayDefiningPattern = dto.definingRule.dayDefiningPattern;
            h.rule.dayOfWeek = dto.definingRule.dayOfWeek;
            h.rule.month = dto.definingRule.month;
        }
        return h;
    }

     public mapHolidayDto(h: Holiday): IHoliday {
        //h.start = dateTimeUtils.setTime(h.start, h.startTime, true);
        //h.end = dateTimeUtils.setTime(h.end, h.endTime, true);
        let dto: IHoliday = {
            id: h.id,
            code: h.code,
            name: h.name,
            lastUpdateUsername: h.updatedBy,
            lastUpdateDate: dateTimeUtils.convertToDtoString(h.updatedOn),
            date: dateTimeUtils.convertToDtoString(h.date),
            start: dateTimeUtils.convertToDtoDateTimeString(h.start),
            end: dateTimeUtils.convertToDtoDateTimeString(h.end),
            type: h.type,
            origin: h.origin,
            definingRule: h.rule
        };
        return dto;
    }
}
