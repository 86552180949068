/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-and-groups.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/pipes/filter";
import * as i4 from "./filter-and-groups.component";
import * as i5 from "../../../organization/services/lookup/lookup-api.service";
var styles_FilterAndGroupsComponent = [i0.styles];
var RenderType_FilterAndGroupsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterAndGroupsComponent, data: {} });
export { RenderType_FilterAndGroupsComponent as RenderType_FilterAndGroupsComponent };
function View_FilterAndGroupsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-check"]], null, null, null, null, null))], null, null); }
function View_FilterAndGroupsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addFilter(_v.parent.context.$implicit, _v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterAndGroupsComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFilteredBy(_v.parent.context.$implicit, _v.context.$implicit.id); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_FilterAndGroupsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-default dropdown-toggle"], ["data-toggle", "dropdown"], ["type", "button"]], [[2, "btn-danger", null]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "ul", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterAndGroupsComponent_2)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeGroupAndFilter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.filterLookups[_v.context.$implicit])); _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.isFilter(_v.context.$implicit); _ck(_v, 4, 0, currVal_1); }); }
function View_FilterAndGroupsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addGroup(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
function View_FilterAndGroupsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "btn-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["aria-expanded", "false"], ["aria-haspopup", "true"], ["class", "btn btn-default dropdown-toggle"], ["data-toggle", "dropdown"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "ul", [["class", "dropdown-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterAndGroupsComponent_5)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.FilterPipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.restrictedColumns, _co.getAddGroupFilter())); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_FilterAndGroupsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "groups-and-filters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "legend"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Groups and filters"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterAndGroupsComponent_1)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i3.FilterPipe, []), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "groups-and-filters-actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-default"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.restoreDefaultFiltersAndGroupings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-undo"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FilterAndGroupsComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(0, i3.FilterPipe, [])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.restrictedColumns, _co.getGroupingFilter())); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(_co.restrictedColumns, _co.getAddGroupFilter())).length; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_FilterAndGroupsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-filter-and-groups", [], null, null, null, View_FilterAndGroupsComponent_0, RenderType_FilterAndGroupsComponent)), i1.ɵdid(1, 245760, null, 0, i4.FilterAndGroupsComponent, [i5.LookupApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterAndGroupsComponentNgFactory = i1.ɵccf("slx-filter-and-groups", i4.FilterAndGroupsComponent, View_FilterAndGroupsComponent_Host_0, { filters: "filters", groups: "groups" }, { onFiltersChange: "onFiltersChange", onGroupsChange: "onGroupsChange" }, []);
export { FilterAndGroupsComponentNgFactory as FilterAndGroupsComponentNgFactory };
