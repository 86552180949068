import { EmpType, IEmpType } from './../../../organization/models/lookup/emp-type';
import { MasterScheduleEntryCell, IMasterScheduleEntryCell, MasterScheduleTotalCell, IMasterScheduleTotalCell } from './master-schedule-cell';
import { ISchedulePosition } from './ischedule-position';

export enum MasterScheduleRecordType {
  Employee = 'Employee', Subtotal = 'Subtotal', Total = 'Total'
}

export interface IMasterScheduleRow {
  sortSequence: number;
  recordType: MasterScheduleRecordType;
  key: string;
  id: number;
  name: string;
  cell: StringMap<IMasterScheduleEntryCell | IMasterScheduleTotalCell>;
  seniority: number;
  secondarySort: number;
}

export interface IMasterScheduleCell {
  empId: number;
  rowId: number;
  columnId: string;
}

export interface IMasterScheduleEntryRow extends IMasterScheduleRow {
  hireDate: Date;
  dateTerminated: Date;
  activationDate: Date;
  hireDateStr: string;
  dateTerminatedStr: string;
  activationDateStr: string;
  positionGroupName: string;
  position: ISchedulePosition;
  primaryPosition: ISchedulePosition;
  secondaryPositions: ISchedulePosition[];
  homeShiftName: string;
  homeShiftId: number;
  sumShiftsPerWeek: number;
  avgWeeklyRotationsHrs: number;
  weeklyTotals: NumberMap<number>;
  isPosted: boolean;
  isFromDifferntDepartment: boolean;
  isAgency: boolean;
  hasRotations: boolean;
  employeeType: IEmpType;
}

export class MasterScheduleEntryRow implements IMasterScheduleEntryRow {
  public sortSequence: number;
  public recordType: MasterScheduleRecordType;
  public key: string;
  public id: number;
  public name: string;
  public dateTerminated: Date;
  public hireDate: Date;
  public hireDateStr: string;
  public dateTerminatedStr: string;
  public positionGroupName: string;
  public position: ISchedulePosition;
  public primaryPosition: ISchedulePosition;
  public secondaryPositions: ISchedulePosition[];
  public homeShiftName: string;
  public homeShiftId: number;
  public sumShiftsPerWeek: number;
  public avgWeeklyRotationsHrs: number;
  public cell: StringMap<MasterScheduleEntryCell>;
  public weeklyTotals: NumberMap<number>;
  public isPosted: boolean;
  public isFromDifferntDepartment: boolean;
  public isAgency: boolean;
  public hasRotations: boolean;
  public seniority: number;
  public secondarySort: number;
  public activationDate: Date;
  public activationDateStr: string;
  public employeeType: EmpType;
}

export function isMasterScheduleEntryRow(value: IMasterScheduleRow): value is IMasterScheduleEntryRow {
  if (!value) {
    return false;
  }
  return value.recordType === MasterScheduleRecordType.Employee;
}

export interface IMasterScheduleSubtotalRow extends IMasterScheduleRow {
  position: ISchedulePosition;
  positionGroupName: string;
}

export class MasterScheduleSubtotalRow implements IMasterScheduleSubtotalRow {
  public sortSequence: number;
  public recordType: MasterScheduleRecordType;
  public key: string;
  public id: number;
  public name: string;
  public positionGroupName: string;
  public position: ISchedulePosition;
  public cell: StringMap<MasterScheduleTotalCell>;
  public get seniority(): number {
    return 0;
  }
  public get secondarySort(): number {
    return 0;
  }
}

export function isMasterScheduleSubtotalRow(value: IMasterScheduleRow): value is IMasterScheduleSubtotalRow {
  if (!value) {
    return false;
  }
  return value.recordType === MasterScheduleRecordType.Subtotal;
}

export interface IMasterScheduleTotalRow extends IMasterScheduleRow {
}

export class MasterScheduleTotalRow implements IMasterScheduleTotalRow {
  public sortSequence: number;
  public recordType: MasterScheduleRecordType;
  public key: string;
  public id: number;
  public name: string;
  public cell: StringMap<MasterScheduleTotalCell>;
  public get seniority(): number {
    return 0;
  }
  public get secondarySort(): number {
    return 0;
  }
}

export function isMasterScheduleTotalRow(value: IMasterScheduleRow): value is IMasterScheduleTotalRow {
  if (!value) {
    return false;
  }
  return value.recordType === MasterScheduleRecordType.Total;
}
