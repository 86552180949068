import * as tslib_1 from "tslib";
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common';
import { dataCheck } from '../../../models/wfm-sync';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { BaseFilterSyncComponent } from '../base-filter-sync.component';
var LaborcodeSyncComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LaborcodeSyncComponent, _super);
    function LaborcodeSyncComponent(wfmSyncService, notificationService, busyService) {
        return _super.call(this, wfmSyncService, notificationService, busyService) || this;
    }
    LaborcodeSyncComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.LabourCodeSyncOrg(selectedItems, 'migration'));
    };
    LaborcodeSyncComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.id;
    };
    LaborcodeSyncComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.GetLabourCodeChangeLogs('migration'))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.processData(response.data)];
                }
            });
        });
    };
    LaborcodeSyncComponent.prototype.processData = function (data) {
        return dataCheck(data);
    };
    LaborcodeSyncComponent.prototype.setGlobalFilter = function () {
        var _this = this;
        this.globalFilterFunc$.next(function (item) {
            var orgId = _this.selectedOrganization && _this.selectedOrganization.id;
            var deptId = _this.selectedDepartment && _this.selectedDepartment.id;
            var posId = _this.selectedPosition && _this.selectedPosition.id;
            return (orgId == null || item.organizationId === orgId) &&
                (deptId == null || item.departmentId === deptId) &&
                (posId == null || item.jobCode === posId);
        });
    };
    return LaborcodeSyncComponent;
}(BaseFilterSyncComponent));
export { LaborcodeSyncComponent };
