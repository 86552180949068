import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { EnrollementAttachmentFile } from '../../../../models';
import * as _ from 'lodash';
import { appConfig } from '../../../../../../../app/app.config';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { GridComponent } from '@progress/kendo-angular-grid';
var BenefitAttachmentsComponent = /** @class */ (function () {
    function BenefitAttachmentsComponent(modalService, manService) {
        this.modalService = modalService;
        this.manService = manService;
        this.subscriptions = {};
        this.pageSize = 5;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'addedOn', dir: 'desc' }];
        this.appConfig = appConfig;
    }
    BenefitAttachmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.benefitAttachmentLoadSubscription = this.manService.subscribeToBenefitsAttachmentsLoad(function (v) {
            _this.records = v;
            _this.isLoading = false;
            _this.refreshGrid();
        });
    };
    BenefitAttachmentsComponent.prototype.attachmentSubscription = function () {
        this.manService.getAttachmentsData(this.employeeId);
    };
    BenefitAttachmentsComponent.prototype.ngOnDestroy = function () {
    };
    BenefitAttachmentsComponent.prototype.onClickDelete = function (dataItem) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Delete Attachment', 'Are you sure you want to remove the document?', this.modalService, function (isDelete) {
            if (isDelete) {
                _this.isLoading = true;
                _this.manService.deleteAttachment(dataItem.id).then(function () {
                    _this.isLoading = false;
                    _this.records = _.filter(_this.records, function (a) { return a.id !== dataItem.id; });
                    _this.refreshGrid();
                });
            }
        }, options);
    };
    BenefitAttachmentsComponent.prototype.onDocumentFileClick = function (dataItem) {
        var _this = this;
        this.isLoading = true;
        this.manService.downloadAttachment(dataItem.id).then(function () {
            _this.isLoading = false;
        });
    };
    BenefitAttachmentsComponent.prototype.onAddFile = function (data) {
        var _this = this;
        {
            this.isLoading = true;
            this.manService.addFilesToSave(data, this.employeeId).then(function () {
                _this.attachmentSubscription();
            });
        }
    };
    BenefitAttachmentsComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitAttachmentsComponent.prototype, "subscriptions", void 0);
    return BenefitAttachmentsComponent;
}());
export { BenefitAttachmentsComponent };
