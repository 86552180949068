export interface ILeaveManagementConfig {
  settings: {};
  files: {
    acceptedTypes: string[];
    maxFileSizeBytes: number;
    maxFiles: number;
  };
  api: {
    loa: {
      root: string;
      roster: {
        root: string;
      },
      console: {
        root: string;
      },
      types: {
        root: string;
      },
      typeModels: {
        root: string;
      }
      attachment: {
        root: string;
      }
    }
  };
}

export const lmConfig: ILeaveManagementConfig = {
  settings: {},
  files: {
    acceptedTypes: ['.csv', '.doc', '.docm', '.docx', '.gif', '.jpeg', '.jpg', '.pdf', '.png', '.tiff', '.txt', '.xls', '.xlsm', '.xlsx'],
    maxFileSizeBytes: 10485760,
    maxFiles: 20
  },
  api: {
    loa: {
      root: 'loa',
      roster: {
        root: 'roster'
      },
      console: {
        root: 'console'
      },
      types: {
        root: 'types'
      },
      typeModels: {
        root: 'typeModels'
      },
      attachment: {
        root: 'attachment'
      }
    }
  }
};
