import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class BenefitEnrollmentCommonService {

  public validateFormulaResult(empContribution: number, erContribution: number, coverage: number): string {
    let formulaErrorValue = -1;
    let errorMessage = 'Formula entered for ';
    if ((_.isNil(empContribution) || empContribution === formulaErrorValue) && (_.isNil(erContribution) || erContribution === formulaErrorValue) && (_.isNil(coverage) || coverage < 0)) {
      errorMessage += ' Employee Contribution and Employer Contribution and Coverage are incorrect.';
    } else if ((_.isNil(empContribution) || empContribution === formulaErrorValue) && (_.isNil(erContribution) || erContribution === formulaErrorValue)) {
      errorMessage += ' Employee Contribution and Employer Contribution are incorrect.';
    } else if ((_.isNil(empContribution) || empContribution === formulaErrorValue) && (_.isNil(coverage) || coverage < 0)) {
      errorMessage += ' Employee Contribution and Coverage are incorrect.';
    } else if ((_.isNil(erContribution) || erContribution === formulaErrorValue) && (_.isNil(coverage) || coverage < 0)) {
      errorMessage += ' Employer Contribution and Coverage are incorrect.';
    } else if ((_.isNil(empContribution) || empContribution === formulaErrorValue)) {
      errorMessage += ' Employee Contribution is incorrect.';
    } else if ((_.isNil(erContribution) || erContribution === formulaErrorValue)) {
      errorMessage += ' Employer Contribution is incorrect.';
    } else if (_.isNil(coverage) || coverage < 0) {
      errorMessage += ' Coverage is incorrect.';
    } else {
      return '';
    }
    errorMessage += ' Please make corrections to the formula in Plan Detail Screen.';
    return errorMessage;
  }


  public getFormulaError(isEmpFormula:boolean,isErFormula:boolean,isCoverageFormula:boolean,empContribution: number, erContribution: number, coverage: number): string{
    if(!isEmpFormula && !isErFormula && !isCoverageFormula){
      return '';
    }
    let formulaErrorValue = -1;
    let errorMessage = 'Formula entered for ';
    if((isEmpFormula && (_.isNil(empContribution) || empContribution === formulaErrorValue)) && (isErFormula && (_.isNil(erContribution) || erContribution === formulaErrorValue)) && (isCoverageFormula && (_.isNil(coverage) || coverage < 0)) ) {
        errorMessage += ' Employee Contribution and Employer Contribution and Coverage are incorrect.';
    }else if((isEmpFormula && (_.isNil(empContribution) || empContribution === formulaErrorValue)) && (isErFormula && (_.isNil(erContribution) || erContribution === formulaErrorValue))){
      errorMessage += ' Employee Contribution and Employer Contribution are incorrect.';
    }else if((isEmpFormula && (_.isNil(empContribution) || empContribution === formulaErrorValue)) && (isCoverageFormula && (_.isNil(coverage) || coverage < 0))){
      errorMessage += ' Employee Contribution and Coverage are incorrect.';
    }else if((isErFormula && (_.isNil(erContribution) || erContribution === formulaErrorValue)) && (isCoverageFormula && (_.isNil(coverage) || coverage < 0))){
      errorMessage += ' Employer Contribution and Coverage are incorrect.';
    }else if((isEmpFormula && (_.isNil(empContribution) || empContribution === formulaErrorValue))){
      errorMessage += ' Employee Contribution is incorrect.';
    }else if((isErFormula && (_.isNil(erContribution) || erContribution === formulaErrorValue))){
      errorMessage += ' Employer Contribution is incorrect.';
    }else if((isCoverageFormula && (_.isNil(coverage) || coverage < 0))){
      errorMessage += ' Coverage is incorrect.';
    }else{
      return '';
    }

    errorMessage += ' Please make corrections to the formula in Plan Detail Screen.';
    return errorMessage;

  }

}
