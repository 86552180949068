import * as moment from 'moment';
import { appConfig } from '../../../app.config';

export class IdealSchedulePositionPeriod {

    public startDate: Date;
    public endDate: Date;

    public get name(): string {
        let start: string = moment(this.startDate).format(appConfig.dateFormat);
        let end: string = moment(this.endDate).format(appConfig.dateFormat);
        return `${start} - ${end}`;
    }
}
