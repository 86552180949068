import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';

import { Assert } from '../../../framework/index';
import { mutableSelect } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';

import { MessagesApiService } from '../mocks/messages-api.service';
import { MessageApiService } from './message-api.service';
import { IMessageList, IUpdateRequest, IUserPicture, IMessagePayload, ILatestScheduleCycle, TabMessages, ISectionMessagesRequest, MessageCountResponse, IMessageCountRequest, MessageCountRequest, IMessageDataAndCountRequest } from '../models/message-list';
import { debounceTime, switchMap, distinctUntilChanged, } from 'rxjs/operators';
import { from, BehaviorSubject } from 'rxjs';
import { IMessageFilterState, initialMessageFilterState } from '../models';
import { StateResetTypes } from '../../../core/models';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { ComposeNewTabsPermissions } from '../models/ComposeNewTabsPermissions';

@Injectable({
  providedIn: 'root'
})
export class MessagesManagementService {
  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;
  private showWaitingPopup: boolean;
  private loading$ = new Subject<boolean>();
  private updatedSMSInfo$ = new Subject<any[]>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private updatedSelectAllInfo$ = new Subject<boolean>();
  private sortingOrder$ = new Subject<string>();
  private getMessageThread$ = new Subject<any>();
  private getColdMessageThread$ = new Subject<any>();  
  private selectedMessage$ = new Subject<any>();
  private coldThredDataExists$ = new Subject<boolean>();
  private selectedMessageId$ = new Subject<string>();
  private messageCountObj$ = new Subject<string>();
  private updateReadStatus$ = new Subject<string>();
  private callUnreadCountApi$ = new Subject<string>();
  private emptyMessageThread$ = new Subject<string>();
  private refreshMessageList$ = new Subject<string>();
  private activeTabChange$ = new Subject<string>();
  private messageLoaded$ = new Subject<IMessageList[]>();
  private updateArchiveMessages$ = new Subject<any[]>();
  private updateUnArchiveMessages$ = new Subject<any[]>();
  private updateReadMessages$ = new Subject<any[]>();
  private updateUnreadMessages$ = new Subject<any[]>();
  private recentMessage$ = new Subject<IMessageList>();
  private popUpMessages$ = new Subject<IMessageList[]>();
  private userPicturesLoaded$ = new Subject<IUserPicture[]>();
  private latestScheduleCyleLoaded$ = new Subject<ILatestScheduleCycle>();
  private tabsMessagesCount$= new Subject<MessageCountResponse[]>();
  private tabMessages$ = new Subject<TabMessages>();
  private moreTabMessages$ = new Subject<TabMessages>();
  private switchMessageFilter$ = new Subject<boolean>();
  private stateChanged$ = new BehaviorSubject<IMessageFilterState>(initialMessageFilterState);
  private readonly stateKey: string = 'MessageFilterState';
  public state: IMessageFilterState;
  private messageCountRequestState:MessageCountRequest;
  private showWaitingPopup$ = new Subject<boolean>();
  isMsgCenterLoaded =  false;
  pageno = 1;
  orgLevelId = 1;
  isarchive = false;
  dateOrderByAscending = false;
  nameOrderByAscending = null;
  includeCold = false;
  private coldStorageFlag$ = new Subject<any>();
  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.orgLevelChanged$.complete();
    this.updatedSMSInfo$.complete();
    this.updatedSelectAllInfo$.complete();
    this.sortingOrder$.complete();
    this.stateChanged$.complete();
    this.switchMessageFilter$.complete();
  }

  constructor(public apiService: MessagesApiService, public msgApiService: MessageApiService, private stateManagement: StateManagementService) { }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public updateSpinner(isLoading: boolean): void {
    this.loading$.next(isLoading);
  }

  public subscribeToSMSMessagesData(callback: (v: any[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updatedSMSInfo$.subscribe(callback);
  }

  public updateSMSMessagesInfo(smsData: any): void {
    this.updatedSMSInfo$.next(smsData);
  }

  public subscribeToOrgLevel(callback: (v: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public updateState(state: IMessageFilterState): void {
    this.stateChanged$.next(state);
  }

  public subscribeToState(callback: (state: IMessageFilterState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.stateChanged$.subscribe(callback);
  }

  public updateMessageFilter(state: boolean): void {
    this.showWaitingPopup=true;
    this.switchMessageFilter$.next(state);
    this.setFilterState(state);
  }

  public subscribeToswitchMessageFilter(callback: (state: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.switchMessageFilter$.subscribe(callback);
  }

  public async getSMSMessages(): Promise<void> {
    this.updateSpinner(true);
    try {
      const data = await this.apiService.getSMSMessagesInfo();
      this.updateSMSMessagesInfo(data);
    } catch (e) {
      console.error(e);
    } finally {
      this.updateSpinner(false);
    }
  }

  public selectAllSMSRecords(value: boolean): void {
    this.updatedSelectAllInfo$.next(value);
  }

  public subscribeToSelectAllSMSRecords(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updatedSelectAllInfo$.subscribe(callback);
  }

  public subscribeToGetMessageThread(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.getMessageThread$.subscribe(callback);
  }

  public subscribeToGetMessageId(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.selectedMessageId$.subscribe(callback);
  }

  public updateMessageThread(item, orgLevelId, markAsRead = true,isMyMessage: boolean) {
    if (!item) {
      return;
    }

    this.selectedMessageId$.next(item.id);
    this.msgApiService.getMessageThread(item.employeeId, orgLevelId,false,isMyMessage).then((v: any) => {
      const obj = { conversation: v, employeeData: item, markAsRead };
      this.getMessageThread$.next(obj);
    });
  }

  public appendColdMessageThread(item, orgLevelId,isColdData,isMyMessage: boolean) {
    if (!item) {  
      return;
    }

    this.msgApiService.getMessageThread(item.employeeId, orgLevelId,isColdData,isMyMessage).then((v: any) => {
      const obj = { conversation: v, employeeData: item };
      this.getColdMessageThread$.next(obj);
    });
  }

  public subscribeToGetColdMessageThread(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.getColdMessageThread$.subscribe(callback);
  }

  public isColdDataExists(employeeId, orgLevelId) {
    if (employeeId) {
     this.msgApiService.getColdDataExists(employeeId, orgLevelId).then((v: any) => {      
      this.coldThredDataExists$.next(v.hasMessages);
    });
    }
  }
  
  public subscribeToColdDataExists(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.coldThredDataExists$.subscribe(callback);
  }

  public subscribeToSelectedMessage(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.selectedMessage$.subscribe(callback);
  }

  public subscribeToMessageCount(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.messageCountObj$.subscribe(callback);
  }

  public updateMessageCount(item) {
    this.messageCountObj$.next(item);
  }

  public subscribeToUpdateReadStatus(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updateReadStatus$.subscribe(callback);
  }

  updateReadStatusOfthread(employeeId) {
    this.msgApiService.getMessageStatusUpdateMultiple(employeeId).then((v: any) => {
      this.updateReadStatus$.next(v);
    });
  }

  public subscribecallUnReadCountApi(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.callUnreadCountApi$.subscribe(callback);
  }

  callUnReadCountApi() {
    this.callUnreadCountApi$.next('true');
  }

  public subscribeEmptyMessageThread(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.emptyMessageThread$.subscribe(callback);
  }

  emptyMessageThread() {
    this.emptyMessageThread$.next('true');
  }

  public subscribeRefreshMessageList(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.refreshMessageList$.subscribe(callback);
  }

  refreshMessageList(event) {
    this.refreshMessageList$.next(event);
  }

  public subscribeChangeActiveTab(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.activeTabChange$.subscribe(callback);
  }

  changeActiveTab() {
    this.activeTabChange$.next('true');
  }

  public subscribeToMessages(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.messageLoaded$.subscribe(callback);
  }

  public getMessages(searchText: string, dateOrderByAscending: boolean, nameOrderByAscending: boolean, pageno: number, orgLevelId: number, isArchived: boolean, includeCold, pageSize: number, isMyMessage: boolean): void {
    this.loading$.next(true);
    this.msgApiService.getMessages(searchText, dateOrderByAscending, nameOrderByAscending, pageno, orgLevelId, isArchived, includeCold, pageSize, isMyMessage)
      .then((messages: IMessageList[]) =>  {
        this.messageLoaded$.next(messages);
      }).finally(() => {
        this.loading$.next(false);
      });
  }
  public getComposeNewTabsPermissions(orgLevelId: number):Promise<ComposeNewTabsPermissions>{
    let promise = this.msgApiService.getComposeNewTabsPermissions(orgLevelId);
    return promise;
  }

  public subscribeToTabsMessagesCount(callback: (v: MessageCountResponse[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.tabsMessagesCount$.subscribe(callback);
  }
  public subscribeToTabMessages(callback: (v: TabMessages) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.tabMessages$.subscribe(callback);
  }

  public getTabMessages(iSectionMessagesRequest:ISectionMessagesRequest):void
  {
    
    setTimeout(() => {      
      if(this.showWaitingPopup)
      {
        this.showWaitingPopup=false;
        this.showWaitingPopup$.next(true);
      }
    }, 20000);
    let tabMessages:TabMessages=new TabMessages();
    this.loading$.next(true);
    this.msgApiService.getTabMessages(iSectionMessagesRequest)
      .then((messages: IMessageList[]) => {
        this.showWaitingPopup=false;
        tabMessages.sectionName=String(iSectionMessagesRequest.sectionName);
        tabMessages.messages=messages;
        tabMessages.isReset=iSectionMessagesRequest.isReset;
        this.tabMessages$.next(tabMessages);
      }).finally(() => {
        this.loading$.next(false);
      });    
  }

  public subscribeToMoreTabMessages(callback: (v: TabMessages) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.moreTabMessages$.subscribe(callback);
  }
  public getMoreTabMessages(iSectionMessagesRequest:ISectionMessagesRequest):void
  {
    let tabMessages:TabMessages=new TabMessages();
    this.loading$.next(true);
    this.msgApiService.getTabMessages(iSectionMessagesRequest)
      .then((messages: IMessageList[]) => {
        tabMessages.sectionName=String(iSectionMessagesRequest.sectionName);
        tabMessages.messages=messages;
        tabMessages.isReset=iSectionMessagesRequest.isReset;
        this.moreTabMessages$.next(tabMessages);
      }).finally(() => {
        this.loading$.next(false);
      });    
  }
  public getTabsMessagesCount(messageCountRequest:IMessageCountRequest):void
  {
    
    this.messageCountRequestState=messageCountRequest;
    this.loading$.next(true);
    this.msgApiService.getTabsMessagesCount(messageCountRequest)
      .then((tabsMessageCount: MessageCountResponse[]) => {
        this.tabsMessagesCount$.next(tabsMessageCount);
      }).finally(() => {
        this.loading$.next(false);
      });    
  }
  public subscribeToPopUpMessages(callback: (v: IMessageList[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.popUpMessages$.subscribe(callback);
  }

  public getPopUpMessages(orgLevelId: number, isMyMessage: boolean): void {
    this.loading$.next(true);
    this.msgApiService.getMessages('', false, null, 1, orgLevelId, false, false, 10, isMyMessage)
      .then((messages: IMessageList[]) => {
        this.popUpMessages$.next(messages);
      }).finally(() => {
        this.loading$.next(false);
      });
  }

  public subscribeToUpdateArchiveMessages(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updateArchiveMessages$.subscribe(callback);
  }

  public updateArchiveMessages(payload: IUpdateRequest) {
    this.loading$.next(true);
    this.msgApiService.updateArchiveMessages(payload)
      .then((res: any) => {
        this.updateArchiveMessages$.next([]);
      }).finally(() => {
        this.loading$.next(false);
      });
  }

  public subscribeToUpdateUnarchiveMessages(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updateUnArchiveMessages$.subscribe(callback);
  }

  public updateUnArchiveMessages(payload: IUpdateRequest) {
    this.loading$.next(true);
    this.msgApiService.updateUnarchiveMessages(payload)
      .then((res: any) => {
        this.updateUnArchiveMessages$.next([]);
      }).finally(() => {
        this.loading$.next(false);
      });
  }

  public subscribeToUpdateReadMessages(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updateReadMessages$.subscribe(callback);
  }

  public updateReadMessages(payload: IUpdateRequest) {
    this.loading$.next(true);
    this.msgApiService.updateReadMessages(payload)
      .then((res: any) => {
        this.updateReadMessages$.next([]);
      }).finally(() => {
        this.loading$.next(false);
      });
  }

  public subscribeToUpdateUnreadMessages(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.updateUnreadMessages$.subscribe(callback);
  }

  public updateUnreadMessages(payload: IUpdateRequest) {
    this.loading$.next(true);
    this.msgApiService.updateUnreadMessages(payload)
      .then((res: any) => {
        this.updateUnreadMessages$.next([]);
      }).finally(() => {
        this.loading$.next(false);
      });
  }

  public subscribeToRecentMessage(callback: (v: IMessageList) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recentMessage$.subscribe(callback);
  }

  public getRecentMessageByEmployeeId(employeeId: number,isMyMessage: boolean) {
    this.loading$.next(true);
    this.msgApiService.getRecentMessageByEmployeeId(employeeId,isMyMessage)
      .then((res: IMessageList) => {
        this.recentMessage$.next(res);
      }).finally(() => {
        this.loading$.next(false);
      });
  }
  

  public searchEmployee(terms: Observable<string>): Observable<any[]> {
    
    return terms.pipe(debounceTime(1000),
      distinctUntilChanged(),
      switchMap((term) => from(this.msgApiService.getTabsMessagesCount(this.messageCountRequestState,term))));
  }
 
  public subscribeToUserPicturesLoaded(callback: (v: IUserPicture[]) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.userPicturesLoaded$.subscribe(callback);
  }

  public getEmployeePicsByUserIds(userIds: Array<number>): void {
    this.msgApiService.getEmployeePicsByUserIds(userIds).then((res => {
      this.userPicturesLoaded$.next(res);
    }));
  }

  public subscribeToLatestScheduleCyleLoaded(callback: (v: ILatestScheduleCycle) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.latestScheduleCyleLoaded$.subscribe(callback);
  }

  public getLatestScheduleCycleByEmployeeId(empId:number): void {
    this.msgApiService.getLatestScheduleCycleByEmployeeId(empId).then((res => {
      this.latestScheduleCyleLoaded$.next(res);
    }));
  }

  public setRequestParams(obj: IMessagePayload) {
    this.pageno = obj.currentPageno;
    this.isarchive = obj.IsArchive;
    this.orgLevelId = obj.orgLevelId;
    this.dateOrderByAscending = obj.dateOrderByAscending;
    this.nameOrderByAscending = obj.nameOrderByAscending;
    this.includeCold = obj.includeCold;
  }

  public getColdStorageExist(orgLevelId: number,isarchived: boolean): void {
    this.msgApiService.getColdStorageExist(orgLevelId, isarchived).then((res) => {
      this.coldStorageFlag$.next(res);
    });
  }

  public subscribeToWaitingPopup(callback:(v:boolean)=>void): Subscription{
    return this.showWaitingPopup$.subscribe(callback);
  }
  public subscribeToColdStorageFlag(callback: (v: any) => void): Subscription {
    Assert.isNotNull(callback, "callback");
    return this.coldStorageFlag$.subscribe(callback);
  }

  private setFilterState(isMyMessage: boolean): void {

    let state: IMessageFilterState = _.clone(initialMessageFilterState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }
    state.isMyMessage = isMyMessage;
    this.saveState(state);
  }

  private saveState(state: IMessageFilterState): void {
    this.stateManagement.setControlState(this.stateKey, {
      value: state
    }, StateResetTypes.None);
  }

  public restoreFilterState(): boolean
  {
    
    let state: IMessageFilterState = _.clone(initialMessageFilterState);
    const controlState = this.stateManagement.getControlState(this.stateKey);

    if (controlState && controlState.value) {
      state = controlState.value;
    }

    return state.isMyMessage;
    
  }

}

