import { Subject } from 'rxjs/Subject';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';

import { appConfig, IApplicationConfig } from '../../../app.config';

import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { ShiftReplacementApiService } from '../shift-replacement/shift-replacement-api.service';
import { LookupApiService, LookupService, OrgLevelWatchService } from '../../../organization/services/index';
import { DateTimeService } from '../../../common/services/date-time/date-time.service';

import { ScheduleEntryApiService } from './schedule-entry-api.service';
import { ScheduleEntryMapService } from './schedule-entry-map.service';
import { ScheduleApiService } from '../schedule/schedule-api.service';

import { ScheduleEntry, ScheduleEntryShift, ShiftOvertime, ShiftPartialAbsence, ShiftLate, ShiftReplacementRequest, ScheduleEntryContainer, ScheduleEntryShiftContainer, DailyOpenShiftDetailsData } from '../../models/index';
import { IScheduleEntryContainerDto } from '../../models/schedule-entry/dto/index';
import {
  LookupDefinition, ScheduleAbsence,
  ShiftDefinition, LocationUnit, Department,
  Position, ConstraintDefinition, ScheduleCycle,
  EmployeeScheduleDefinition, ShiftGroupOrder,
  Organization
} from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { ShiftReplaceCmd } from '../../models/shift-replacement/shift-replace-cmd';
import { OverlapType } from '../../models/schedule-entry/overlap-type.enum';
import { MasterScheduleActions } from '../../store/master-schedule/master-schedule.actions';
import { IChangedScheduleEntry } from '../../store/master-schedule/master-schedule.types';
import { ModalService, ChangeManagementService } from '../../../common/services/index';
import { ChangesDialogOptions } from '../../../common/models/index';
import { AppSettingsManageService } from '../../../app-settings/services/index';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { NightShiftSetting } from '../../../app-settings/model/night-shift-setting';
import { DailyUnitFilterOptions } from '../../models/daily-unit-assignment/daily-unit-filter-options';

@Injectable()
export class ScheduleEntryManagementService {

  public get initialized(): boolean {
    return this.m_initialized;
  }

  public get onInitialized$(): ReplaySubject<ScheduleEntryContainer> {
    return this.m_OnInitialized;
  }

  public get onLoadState$(): ReplaySubject<boolean> {
    return this.m_OnLoadState;
  }

  public get onOverlapState$(): ReplaySubject<boolean> {
    return this.m_OnOverlapState;
  }

  public get onDuplicateState$(): ReplaySubject<boolean> {
    return this.m_OnDuplicateState;
  }

  public get onReplaceShift$(): Subject<ShiftReplacementRequest> {
    return this.m_OnReplaceShift;
  }

  public scheduleEntryContainer: ScheduleEntryContainer;
  public scheduleEntry: ScheduleEntry;
  public originalScheduleEntry: string;
  public originalScheduleEntryDto: IScheduleEntryContainerDto;
  public hasChanges: boolean = false;

  public minDate: Date;
  public maxDate: Date;

  public employeeDepartments: Department[];
  public employeeDepartmentsOrgLevels: OrgLevel[];
  public employeePositions: Position[];
  public employeeUnits: LocationUnit[];
  public employeeShifts: ShiftDefinition[];
  public employeeConstraints: ConstraintDefinition[];

  private defaultDepratment: Department;
  private defaultPosition: Position;
  private defaultUnit: LocationUnit;
  private defaultShift: ShiftDefinition;

  private m_initialized: boolean;
  private m_OnInitialized: ReplaySubject<ScheduleEntryContainer>;
  private m_OnLoadState: ReplaySubject<boolean>;
  private m_OnOverlapState: ReplaySubject<boolean>;
  private m_OnDuplicateState: ReplaySubject<boolean>;
  private m_OnReplaceShift: Subject<ShiftReplacementRequest>;
  public duaOptions: DailyUnitFilterOptions = new DailyUnitFilterOptions();
  private activatedRoute: ActivatedRoute;
  private router: Router;

  private orgLevel: OrgLevel;

  private overlapShown: boolean = false;
  private duplicateShown: boolean = false;

  private shiftToReplace: ScheduleEntryShiftContainer;
  private scheduleCycles: ScheduleCycle[];

  private appSettings: AppServerConfig;
  private organizations: Organization[];

  constructor(private lookupApiService: LookupApiService,
    private scheduleEntryApiService: ScheduleEntryApiService,
    private shiftReplacementApiService: ShiftReplacementApiService,
    private dateTimeService: DateTimeService,
    private modalService: ModalService,
    private masterScheduleActions: MasterScheduleActions,
    private scheduleApiService: ScheduleApiService,
    private changeManagementService: ChangeManagementService,
    private notificationsService: NotificationsService,
    private appSettingsService: AppSettingsManageService,
    private orgLevelWatchService: OrgLevelWatchService
  ) {
    this.m_OnInitialized = new ReplaySubject<ScheduleEntryContainer>(1);
    this.m_OnLoadState = new ReplaySubject<boolean>(1);
    this.m_OnOverlapState = new ReplaySubject<boolean>(1);
    this.m_OnDuplicateState = new ReplaySubject<boolean>(1);
    this.m_OnReplaceShift = new Subject<ShiftReplacementRequest>();
  }

  public changeScheduleEntryDate(employeeId: number, dateOn: Date, showOnlyActivePositions: boolean = false): void {
    this.changeManagementService.clearChanges();
    this.m_OnLoadState.next(true);
    let onDate: Date = moment(dateOn, appConfig.linkDateFormat).toDate();
    this.loadSchdeuleEntry(employeeId, onDate, showOnlyActivePositions);
  }

  public async loadSchdeuleEntry(employeeId: number, onDate: Date, showOnlyActivePositions: boolean = false): Promise<void> {
    try {
      await this.initDefaults(employeeId, onDate, showOnlyActivePositions);
      this.originalScheduleEntry = JSON.stringify(this.scheduleEntry);

      this.scheduleEntryContainer = this.createVieModel(this.scheduleEntry);

      this.sortShifts(this.scheduleEntryContainer);
      this.checkChanges();

      this.m_OnLoadState.next(false);
      this.m_initialized = true;
      this.m_OnInitialized.next(this.scheduleEntryContainer);
      this.refreshEntry(this.scheduleEntryContainer);
    } catch (error) {
      throw error;
    }
  }

  public checkChanges(): void {
    this.hasChanges = this.compareEntry(this.scheduleEntry);
    if (this.hasChanges) {
      this.changeManagementService.changeNotify();
    } else {
      this.changeManagementService.clearChanges();
    }
  }

  public save(container: ScheduleEntryContainer): void {

    this.m_OnLoadState.next(true);
    this.scheduleEntryApiService.saveScheduleEntry(this.scheduleEntry, this.defaultPosition.orgLevelId).then(() => {
      this.updateMasterSchedule([{ date: this.scheduleEntry.date, employeeId: this.scheduleEntry.employee.id }], this.defaultPosition.orgLevelId);
      this.loadSchdeuleEntry(this.scheduleEntry.employee.id, this.scheduleEntry.date);
      this.changeManagementService.clearChanges();
      this.m_initialized = true;
      this.m_OnInitialized.next(container);
      this.m_OnLoadState.next(false);
    }).catch((e: any) => {
      this.m_initialized = true;
      this.m_OnInitialized.next(container);
      this.m_OnLoadState.next(false);
    });
  }

  public updateMasterSchedule(entry: IChangedScheduleEntry[], orgLevelId: number): void {
    this.masterScheduleActions.scheduleEntryChange(orgLevelId, entry);
  }

  public discard(): void {

    let entry = JSON.parse(this.originalScheduleEntry, this.reviveDateTime);
    this.scheduleEntry = entry;

    // this.fixPositions();

    this.discardViewModel(this.scheduleEntryContainer, this.scheduleEntry);
    this.checkChanges();
    this.changeManagementService.clearChanges();

    this.m_OnDuplicateState.next(false);
    this.m_OnOverlapState.next(false);
    this.m_OnLoadState.next(false);
    this.m_initialized = true;
    this.m_OnInitialized.next(this.scheduleEntryContainer);
  }


  private async initDefaults(employeeId: number, onDate: Date, isActive: boolean = false): Promise<void> {
    this.defaultShift = null;
    this.defaultPosition = null;
    this.defaultUnit = null;

    let scheduleEntryPromise = this.scheduleEntryApiService.getScheduleEntry(employeeId, onDate);
    let departmentsPromise = this.lookupApiService.getDepartments(employeeId, null, false, true);
    let positionPromise = this.lookupApiService.getPositions(employeeId, null, isActive, true);
    let shiftPromise = this.lookupApiService.getShiftDefinitions(employeeId);
    let unitPromise = this.lookupApiService.getLocationUnits(employeeId);
    let constraintsPromise = this.lookupApiService.getConstraintDefinitions(employeeId);
    let settingsPromise = this.appSettingsService.getAppServerConfig();
    let organizationsPromise = this.lookupApiService.getOrganizations();

    try {
      let results: any[] = await Promise.all([unitPromise, departmentsPromise, positionPromise, shiftPromise, constraintsPromise, settingsPromise, organizationsPromise]);

      this.employeeUnits = results[0];
      this.employeeDepartments = results[1];
      this.employeeDepartmentsOrgLevels = [];
      this.employeeShifts = results[3];
      this.employeeConstraints = results[4];
      this.appSettings = results[5];
      this.organizations = results[6];

      _.each(this.employeeDepartments, async (d: Department) => {
        let orgLevel: OrgLevel = await this.orgLevelWatchService.getOrgLevelByIdSafe(d.orgLevelId);
        if (orgLevel) {
          this.employeeDepartmentsOrgLevels.push(orgLevel);
        }
      });

      this.scheduleEntry = await scheduleEntryPromise;
      this.minDate = this.scheduleEntry.previousDayShiftEnd || moment(this.scheduleEntry.date).subtract(1, 'day').toDate();
      this.maxDate = this.scheduleEntry.nextDayShiftStart || moment(this.scheduleEntry.date).add(1, 'day').toDate();

      if (results[2]) {
        this.employeePositions = results[2] ? this.filterAvailablePositionsByDate(results[2], this.scheduleEntry.date) : [];
        this.employeePositions = _.uniqBy(this.employeePositions, (position: Position) => position.id);
      }

      _.forEach(this.scheduleEntry.shifts, (shift: ScheduleEntryShift): void => {
        this.addLookupValueIfDoesntContain('id', shift.position, this.employeePositions);
        // if (_.filter(this.employeePositions, (position: Position): boolean => position.id === shift.position.id)) {
        //   this.employeePositions.push(shift.position);
        // }
      });

      // if (this.employeePositions) {
      //   this.fixPositions();
      // }

      if (this.employeeUnits) {
        if (!this.defaultUnit) {
          this.defaultUnit = _.find(this.employeeUnits, { 'id': this.scheduleEntry.defaultUnitId });
          if (!this.defaultUnit) {
            this.defaultUnit = _.find(this.employeeUnits, { 'id': -1 });
          }
        }
      }

      if (this.scheduleEntry.defaultShiftId > 0) {
        this.defaultShift = _.find(this.employeeShifts, { 'id': this.scheduleEntry.defaultShiftId });
      }

      if (this.scheduleEntry.defaultPositionId > 0) {
        this.defaultPosition = _.find(this.employeePositions, { 'id': this.scheduleEntry.defaultPositionId });
      }

      if (this.defaultPosition && this.defaultPosition.orgLevelId) {
        this.defaultDepratment = _.find(this.employeeDepartments, { 'orgLevelId': this.defaultPosition.orgLevelId });
      }

    } catch (error) {
      throw error;
    }
  }

  public async addNewShift(): Promise<void> {

    let newShift: ScheduleEntryShift = new ScheduleEntryShift();
    newShift.department = this.defaultDepratment;
    newShift.position = this.defaultPosition;
    newShift.unit = this.defaultUnit;

    if (this.scheduleEntry.shifts.length === 0) {
      newShift.shift = this.defaultShift;
    }

    this.scheduleEntry.shifts.push(newShift);

    let container: ScheduleEntryShiftContainer = new ScheduleEntryShiftContainer();
    container.scheduleEntryDate = this.scheduleEntryContainer.date;
    container.minDate = this.minDate;
    container.maxDate = this.maxDate;
    container.positions = this.employeePositions;
    container.shifts = this.employeeShifts;
    container.units =  this.employeeUnits;

    if (newShift.shift) {
      let timeString: string;
      let m: moment.Moment;
      this.updateTimeByShift(newShift, container);
    } else {
      newShift.startDate = moment(this.scheduleEntry.date).toDate();
      newShift.endDate = moment(this.scheduleEntry.date).toDate();
    }

    container.scheduleEntryShift = newShift;
    await this.refreshLookups(container);
    this.setUnpaidTime(container);

    this.scheduleEntryContainer.shifts.push(container);

    this.refreshEntry(this.scheduleEntryContainer);
  }

  public removeShift(shift: ScheduleEntryShiftContainer): void {
    this.scheduleEntry.shifts = _.without(this.scheduleEntry.shifts, shift.scheduleEntryShift);
    this.scheduleEntryContainer.shifts = _.without(this.scheduleEntryContainer.shifts, shift);
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public shiftChange(shift: ScheduleEntryShift, container: ScheduleEntryShiftContainer): void {
    this.removePartialAbsence(container);
    this.removeLateness(container);
    this.updateTimeByShift(shift, container);
    this.setUnpaidTime(container);
    container.hasDuplicate = this.checkDuplicatedShift(container);
    if (container.hasDuplicate) {
      this.m_OnDuplicateState.next(true);
    } else {
      container.hasOverlap = this.checkOverlapingOfShift(container) === OverlapType.FATAL;
      if (container.hasOverlap) {
        this.m_OnOverlapState.next(true);
      }
    }

    if (!container.hasDuplicate && !container.hasOverlap) {
      this.refreshEntry(this.scheduleEntryContainer);
      this.m_OnLoadState.next(true);
      _.delay(() => {
        this.m_OnLoadState.next(false);
        this.sortShifts(this.scheduleEntryContainer);
      }, 600);

    }

    this.checkChanges();
  }

  public unitChange(definition: LocationUnit, container: ScheduleEntryShiftContainer): void {
    this.checkChanges();
  }

  public absenceChange(definition: ScheduleAbsence, container: ScheduleEntryShiftContainer): void {
    this.checkChanges();
  }

  public onShiftStartDateChanged(container: ScheduleEntryShiftContainer): void {

    if (container.scheduleEntryShift.partialAbsence) {
      if (!container.partialAbsToEnd) {
        container.scheduleEntryShift.partialAbsence.startDate = moment(container.shiftStartDate).toDate();
        this.calculatePartialAbsenceEndTime(container.scheduleEntryShift.partialAbsence);
      }
    }

    if (container.scheduleEntryShift.overtime) {
      container.scheduleEntryShift.overtime.startDate = moment(container.shiftStartDate).toDate();
    }

    if (container.scheduleEntryShift.late) {
      let arrivalDate: Date = container.scheduleEntryShift.late.arrivalDate;
      let newStartDate: Date = container.shiftStartDate;
      if (moment(arrivalDate).isBefore(newStartDate)) {
        container.scheduleEntryShift.late.arrivalDate = moment(container.shiftStartDate).toDate();
      }
      this.calculateLateTimeStamp(container);
    }

    this.refreshEntry(this.scheduleEntryContainer);
  }

  public onShiftEndDateChanged(container: ScheduleEntryShiftContainer): void {

    if (container.scheduleEntryShift.partialAbsence) {
      if (container.partialAbsToEnd) {
        container.scheduleEntryShift.partialAbsence.endDate = moment(container.shiftEndDate).toDate();
        this.calculatePartialAbsenceStartTime(container.scheduleEntryShift.partialAbsence);
      }
    }

    if (container.scheduleEntryShift.overtime) {
      container.scheduleEntryShift.overtime.endDate = moment(container.shiftEndDate).toDate();
    }
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public shiftPaidTimeChanged(container: ScheduleEntryShiftContainer): void {
    if (_.isNaN(container.shiftHours)) {
      container.shiftHours = 0;
    }

    this.calculateShiftEndTime(container);
    this.onShiftEndDateChanged(container);
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public shiftUnpaidTimeChanged(container: ScheduleEntryShiftContainer): void {
    if (_.isNaN(container.unpaidHours)) {
      container.unpaidHours = 0;
    }

    this.calculateShiftEndTime(container);
    this.onShiftEndDateChanged(container);
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public onPositionChanged(container: ScheduleEntryShiftContainer): void {
    this.updatePositionRelatedInfo(container);
  }

  public onReplacement(shift: ScheduleEntryShiftContainer): void {
    let dialogOptions: ChangesDialogOptions = new ChangesDialogOptions();
    this.changeManagementService.canMoveForward(undefined, ChangesDialogOptions.DOWORK)
      .then(async (canMove: boolean) => {
        if (canMove) {
          this.discard();

          const unit = _.find(shift.units, { id: shift.scheduleEntryShift.unit.id }) || new LocationUnit();
          const department = await this.orgLevelWatchService.getOrgLevelByIdSafe(shift.scheduleEntryShift.position.orgLevelId);
          const organizationName = _.get(department, 'treeViewNamePath') || [''];

          let request: ShiftReplacementRequest = new ShiftReplacementRequest();
          request.shiftId = shift.scheduleEntryShift.shift.id;
          request.shiftName = shift.scheduleEntryShift.shift.name;
          request.unitId = shift.scheduleEntryShift.unit.id;
          request.unitName = unit.name || shift.scheduleEntryShift.unit.name;
          request.organizationName = organizationName.slice(-1).join('');
          request.positionId = shift.scheduleEntryShift.position.id;
          request.positionName = shift.scheduleEntryShift.position.name;
          request.date = this.scheduleEntry.date;
          request.showDayOffEmployees = true;
          request.shiftStart = shift.shiftStartDate;
          request.shiftEnd = shift.shiftEndDate;
          request.shiftName = shift.scheduleEntryShift.shift.name;
          request.replacedEmployeeId = this.scheduleEntry.employee.id;
          request.replacedEmployeeName = this.scheduleEntry.employee.name;
          request.orgLevelId = shift.scheduleEntryShift.position.orgLevelId;
          request.scheduleAbsence = shift.scheduleEntryShift.scheduleAbsence;
          request.showSendSmsButton = true;

          this.shiftToReplace = shift;
          this.onReplaceShift$.next(request);
        }
      });
  }

  public doReplace(cmd: ShiftReplaceCmd): void {

    this.m_OnLoadState.next(true);

    this.shiftReplacementApiService.replaceEmployee(cmd)
      .then((status: number) => {
        this.m_OnLoadState.next(false);
        if (this.shiftToReplace) {
          this.shiftToReplace.scheduleEntryShift.replaced = cmd.selectedEmployee.employee;
          this.shiftToReplace.scheduleEntryShift.scheduleAbsence = cmd.scheduleAbsence;
          this.shiftToReplace.hasScheduleAbsence = cmd.scheduleAbsence !== null;
          this.shiftToReplace = null;
          this.updateMasterSchedule([
            { date: this.scheduleEntry.date, employeeId: this.scheduleEntry.employee.id },
            { date: this.scheduleEntry.date, employeeId: cmd.selectedEmployee.employee.id },
          ], this.defaultPosition.orgLevelId);
        }
        this.notificationsService.info('Info', 'Replacement was successfully added. Schedule entry refreshed');
        this.loadSchdeuleEntry(this.scheduleEntry.employee.id, this.scheduleEntry.date);

      }).catch((result: any) => {
        this.m_OnLoadState.next(false);
        if (this.shiftToReplace) {
          this.shiftToReplace.scheduleEntryShift.replaced = null;
          this.shiftToReplace.scheduleEntryShift.scheduleAbsence = null;
          this.shiftToReplace.hasScheduleAbsence = false;
          this.shiftToReplace = null;
        }
        this.modalService.globalAnchor.openInfoDialog('Error', `Error happened. Try again later`);
      });
  }

  public addConstraint(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.constraint = _.first(container.constraints);
    this.checkChanges();
  }

  public removeConstraint(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.constraint = null;
    this.checkChanges();
  }

  public constraintChanged(container: ScheduleEntryShiftContainer): void {
    this.checkChanges();
  }

  public addLate(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.late = new ShiftLate();
    container.scheduleEntryShift.late.arrivalDate = moment(container.shiftStartDate).toDate();
    container.scheduleEntryShift.late.lateInterval = 0;
    this.checkChanges();
  }

  public removeLate(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.late = null;
    this.checkChanges();
  }

  public lateDateChanged(container: ScheduleEntryShiftContainer): void {
    this.calculateLateInterval(container);
    this.checkChanges();
  }

  public lateIntervalChanged(container: ScheduleEntryShiftContainer): void {
    this.calculateLateTimeStamp(container);
    this.checkChanges();
  }

  public addOvertime(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.overtime = new ShiftOvertime();
    container.scheduleEntryShift.overtime.startDate = moment(container.shiftStartDate).toDate();
    container.scheduleEntryShift.overtime.endDate = moment(container.shiftEndDate).toDate();
    container.scheduleEntryShift.overtime.duration = this.dateTimeService.durationToHours(container.scheduleEntryShift.shift.duration);
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public removeOvertime(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.overtime = null;
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public overtimeDateChanged(container: ScheduleEntryShiftContainer): void {
    if (container.scheduleEntryShift.overtime) this.calculateOvertimeHours(container.scheduleEntryShift.overtime);
    this.checkChanges();
  }

  public overtimeHoursChanged(container: ScheduleEntryShiftContainer): void {
    if (container.scheduleEntryShift.overtime) {
      container.scheduleEntryShift.overtime.endDate = this.calculateEndTime(container.scheduleEntryShift.overtime.startDate, container.scheduleEntryShift.overtime.duration);
    }
    this.checkChanges();
  }

  public markAbsent(container: ScheduleEntryShiftContainer): void {
    this.removePartialAbsence(container);
    if (container.absences && container.absences.length > 0) {
      container.scheduleEntryShift.scheduleAbsence = container.absences[0];
      container.scheduleEntryShift.constraint = undefined;
    }
    this.setUnpaidTime(container);
    this.calculatePaidHours(container);
    container.hasScheduleAbsence = true;
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public markPresent(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.scheduleAbsence = null;
    container.hasScheduleAbsence = false;
    this.setUnpaidTime(container);
    this.refreshEntry(this.scheduleEntryContainer);
  }

  public addPartialAbsence(container: ScheduleEntryShiftContainer): void {
    container.scheduleEntryShift.partialAbsence = new ShiftPartialAbsence();
    if (container.absences && container.absences.length > 0) {
      container.scheduleEntryShift.partialAbsence.scheduleAbsence = container.absences[0];
    }
    let startMoment: moment.Moment = moment(container.shiftStartDate);
    let endMoment: moment.Moment = moment(container.shiftEndDate);
    container.scheduleEntryShift.partialAbsence.startDate = startMoment.toDate();
    container.scheduleEntryShift.partialAbsence.endDate = endMoment.toDate();
    container.scheduleEntryShift.partialAbsence.hours = 0;

    container.partialAbsToEnd = false;
    this.updatePartialAbsence(container);
    this.refreshScheduleEntryShift(container);
    this.checkChanges();
  }

  public removePartialAbsence(container: ScheduleEntryShiftContainer): void {
    if (!container.scheduleEntryShift.partialAbsence) return;
    container.scheduleEntryShift.partialAbsence = null;
    this.refreshScheduleEntryShift(container);
    this.checkChanges();
  }

  public removeLateness(container: ScheduleEntryShiftContainer): void {
    if (!container.scheduleEntryShift.late) return;
    container.scheduleEntryShift.late = null;
    this.refreshScheduleEntryShift(container);
    this.checkChanges();
  }

  public shiftPartialAbsenceTimeChanged(container: ScheduleEntryShiftContainer): void {
    this.updatePartialAbsence(container);
    this.refreshScheduleEntryShift(container);
    this.checkChanges();

  }

  public onPartialAbsenceAttachmentChanged(container: ScheduleEntryShiftContainer): void {
    let absence: ShiftPartialAbsence = container.scheduleEntryShift.partialAbsence;
    if (!container.partialAbsToEnd) {
      absence.startDate = moment(container.shiftStartDate).toDate();
    } else {
      absence.endDate = moment(container.shiftEndDate).toDate();
    }
    this.shiftPartialAbsenceTimeChanged(container);
  }

  public addNotes(shift: ScheduleEntryShiftContainer): void {
    shift.scheduleEntryShift.notes = '';
    this.checkChanges();
  }

  public removeNotes(shift: ScheduleEntryShiftContainer): void {
    shift.scheduleEntryShift.notes = null;
    this.checkChanges();
  }

  private updatePartialAbsence(container: ScheduleEntryShiftContainer): void {
    let hours: number = container.scheduleEntryShift.partialAbsence.hours;
    let absence: ShiftPartialAbsence = container.scheduleEntryShift.partialAbsence;
    let shiftDuration: number = this.getDurationMs(container.shiftStartDate, container.shiftEndDate);
    let absenceDuration: number;
    if (!container.partialAbsToEnd) {
      absence.endDate = moment(absence.startDate).add(hours, 'hour').toDate();
      absenceDuration = this.getDurationMs(absence.startDate, absence.endDate);
      if (absenceDuration > shiftDuration) {
        absence.endDate = moment(container.shiftEndDate).toDate();
        absence.hours = this.ms2Hours(this.getDurationMs(absence.startDate, absence.endDate));
      }
    } else {
      absence.startDate = moment(absence.endDate).subtract(hours, 'hour').toDate();
      absenceDuration = this.getDurationMs(absence.startDate, absence.endDate);
      if (absenceDuration > shiftDuration) {
        absence.startDate = moment(container.shiftStartDate).toDate();
        absence.hours = this.ms2Hours(this.getDurationMs(absence.startDate, absence.endDate));
      }
    }
  }

  private reviveDateTime(key: any, value: any): any {
    if (typeof value === 'string') {
      let a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value);
      if (a) {
        return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4],
          +a[5], +a[6]));
      }
    }

    return value;
  }
  private createVieModel(entry: ScheduleEntry): ScheduleEntryContainer {

    let scheduleEntryContainer: ScheduleEntryContainer = new ScheduleEntryContainer();
    scheduleEntryContainer.date = this.scheduleEntry.date;
    scheduleEntryContainer.employeeId = this.scheduleEntry.employee.id;
    scheduleEntryContainer.employeeName = this.scheduleEntry.employee.name;
    scheduleEntryContainer.employeePayrollNumber = this.scheduleEntry.employee.payrollNumber;
    scheduleEntryContainer.canReplaceEmployee = this.scheduleEntry.canReplaceEmployee;

    scheduleEntryContainer.shifts = this.createContainerShifts(entry);
    return scheduleEntryContainer;
  }

  private discardViewModel(model: ScheduleEntryContainer, entry: ScheduleEntry): void {
    model.shifts = this.createContainerShifts(entry);
  }

  private createContainerShifts(entry: ScheduleEntry): ScheduleEntryShiftContainer[] {
    let shiftsContainers: ScheduleEntryShiftContainer[] = [];

    _.each(entry.shifts, (shift: ScheduleEntryShift) => {
      let container: ScheduleEntryShiftContainer = new ScheduleEntryShiftContainer();

      this.updateTimeByNightShiftSetting(shift);

      container.minDate = this.minDate;
      container.maxDate = this.maxDate;
      container.positions = this.employeePositions;

      container.scheduleEntryShift = shift;
      container.scheduleEntryDate = entry.date;
      if (shift.position) {
        const employeePosition = _.find(this.employeePositions, x => x.id === shift.position.id);
        if (employeePosition.startDate > shift.startDate ||  employeePosition.endDate < shift.endDate) {
          container.disabledPosition = employeePosition;
        }
      }

      if (shift.partialAbsence) {
        let shiftStart: moment.Moment = moment(shift.startDate);
        let shiftEnd: moment.Moment = moment(shift.endDate);
        let paStart: moment.Moment = moment(shift.partialAbsence.startDate);
        let paEnd: moment.Moment = moment(shift.partialAbsence.endDate);
        if (paStart.isSame(shiftEnd)) {
          container.partialAbsToEnd = true;
          container.shiftEndDate = paEnd.toDate();

        } else if (paEnd.isSame(shiftStart)) {
          container.partialAbsToEnd = false;
          container.shiftStartDate = paStart.toDate();
        }
        let paDurationHrs: number = this.ms2Hours(this.getDurationMs(shift.partialAbsence.startDate, shift.partialAbsence.endDate));
        container.shiftHours += paDurationHrs;
      }

      container.hasScheduleAbsence = !_.isNil(shift.scheduleAbsence);
      container.unpaidHours = this.getUnpaidTime(container);

      this.refreshLookups(container);

      shiftsContainers.push(container);
    });
    return shiftsContainers;
  }

  private async updatePositionRelatedInfo(shift: ScheduleEntryShiftContainer): Promise<void> {

    // if (shift && shift.scheduleEntryShift.shift && shift.scheduleEntryShift.shift.id !== this.scheduleEntry.defaultShiftId) {
    // shift.scheduleEntryShift.shift = null;
    // }
    // if (shift && shift.scheduleEntryShift.shift && shift.scheduleEntryShift.unit.id !== this.scheduleEntry.defaultUnitId) {
    // shift.scheduleEntryShift.unit = null;
    // }

    if (!!shift.previousPositionOrgLevelId && shift.previousPositionOrgLevelId !== shift.scheduleEntryShift.position.orgLevelId) {
      shift.scheduleEntryShift.shift = null;
      shift.scheduleEntryShift.unit = null;
      shift.scheduleEntryShift.constraint = null;
      shift.scheduleEntryShift.scheduleAbsence = null;

      if (shift.scheduleEntryShift.partialAbsence) {
        shift.scheduleEntryShift.partialAbsence.scheduleAbsence = null;
      }

      if (this.employeeDepartments) {
        shift.scheduleEntryShift.department = _.find(this.employeeDepartments, { orgLevelId: shift.scheduleEntryShift.position.orgLevelId });
      }
      await this.refreshLookups(shift);

      shift.previousPositionOrgLevelId = shift.scheduleEntryShift.position.orgLevelId;
    }
    if (shift.scheduleEntryShift.position.orgLevelId === this.defaultPosition.orgLevelId) {
      let canSetupDefaultShift: boolean = false;
      canSetupDefaultShift = this.scheduleEntryContainer.shifts.length === 1
        || !_.some(this.scheduleEntryContainer.shifts,
          (scheduleShift: ScheduleEntryShiftContainer): boolean => scheduleShift.scheduleEntryShift.shift.id === this.defaultShift.id);
      let shiftChanged = canSetupDefaultShift && !_.isNull(this.defaultShift) && !_.isNull(shift.scheduleEntryShift.shift)  && shift.scheduleEntryShift.shift.id !== this.defaultShift.id;
      shift.scheduleEntryShift.shift = canSetupDefaultShift ? this.defaultShift : null;
      shift.scheduleEntryShift.unit = this.defaultUnit;

      if (shiftChanged) {
        this.shiftChange(shift.scheduleEntryShift, shift);
      }
    }
    this.checkChanges();
  }

  private async refreshLookups(shift: ScheduleEntryShiftContainer): Promise<void> {
    try {
      if (!shift.scheduleEntryShift.position) return;
      let positionOrgLevelId: number = shift.scheduleEntryShift.position.orgLevelId;
      let unitPromise: Promise<LocationUnit[]> = this.lookupApiService.getLocationUnits(this.scheduleEntry.employee.id, positionOrgLevelId);
      let shiftPromise: Promise<ShiftDefinition[]> = this.lookupApiService.getShiftDefinitions(this.scheduleEntry.employee.id, positionOrgLevelId);
      let constraintsPromise: Promise<ConstraintDefinition[]> =
        this.lookupApiService.getConstraintDefinitions(this.scheduleEntry.employee.id, positionOrgLevelId);
      let absencePromise: Promise<ScheduleAbsence[]> = this.lookupApiService.getScheduleAbsences(positionOrgLevelId);
      let results: any[] = await Promise.all([unitPromise, shiftPromise, constraintsPromise, absencePromise]);

      shift.units = results[0];
      if (shift.scheduleEntryShift.unit) {
        this.addLookupValueIfDoesntContain('id', shift.scheduleEntryShift.unit, shift.units);
      }

      shift.shifts = results[1];
      if (shift.scheduleEntryShift.shift) {
        this.addLookupValueIfDoesntContain('id', shift.scheduleEntryShift.shift, shift.shifts);
      }

      shift.constraints = results[2] || [];
      if (shift.scheduleEntryShift.constraint) {
        this.addLookupValueIfDoesntContain('code', shift.scheduleEntryShift.constraint, shift.constraints);
      }

      shift.absences = results[3];
      if (shift.scheduleEntryShift.scheduleAbsence) {
        this.addLookupValueIfDoesntContain('code', shift.scheduleEntryShift.scheduleAbsence, shift.absences);
      }

      // if (shift.shifts) {
      //   this.fixShifts(shift.shifts);
      // }
    } catch (error) {
      throw error;
    }
  }

  private updateTimeByShift(shift: ScheduleEntryShift, container: ScheduleEntryShiftContainer): void {

    let dates: { start: moment.Moment, end: moment.Moment } = this.getShiftDefinitionDates(shift.shift, container.scheduleEntryDate);
    let startMoment: moment.Moment = dates.start;
    let endMoment: moment.Moment = dates.end;

    shift.startDate = startMoment.toDate();
    shift.endDate = endMoment.toDate();
    this.updateTimeByNightShiftSetting(shift);

    container.shiftStartDate = moment(shift.startDate).toDate();
    container.shiftEndDate = moment(shift.endDate).toDate();
    container.shiftHours = this.dateTimeService.durationToHours(shift.shift.duration);

  }

  private updateTimeByNightShiftSetting(shift: ScheduleEntryShift): void {
    let nightShiftPrimary: boolean = this.getNightShiftSettingByShift(shift);
    if (shift.startDate > shift.endDate) {
      if (!nightShiftPrimary || (shift.shift.group !== undefined && shift.shift.group.groupOrder !== ShiftGroupOrder.Night)) {
        shift.endDate = moment(shift.endDate).add(1, 'days').toDate();
      } else {
        shift.startDate = moment(shift.startDate).subtract(1, 'days').toDate();
      }
    }
  }

  private getNightShiftSettingByShift(shift: ScheduleEntryShift): boolean {
    let nightShiftPrimary: boolean = false;
    if (shift.department && !_.isNil(shift.department.parentOrganizationId)) {
      nightShiftPrimary = this.getNightShiftSettingFromDepartment(shift.department.parentOrganizationId);
    }
    else if (shift.position && !_.isNil(shift.position.orgLevelId)) {
      nightShiftPrimary = this.getNightShiftSetting(shift.position.orgLevelId);
    } else if (shift.department && !_.isNil(shift.department.orgLevelId)) {
      nightShiftPrimary = this.getNightShiftSetting(shift.department.orgLevelId);
    }
    return nightShiftPrimary;
  }
  private getNightShiftSettingFromDepartment(orgId: number): boolean {
    let nightShiftPrimary: boolean = false;
    let organization: Organization = this.getOrganizationByParentOrgId(orgId);
    if (!_.isNil(organization)) {
      let shiftSetting: NightShiftSetting = this.getNightShiftSettingForOrganization(organization.id);
      if (!_.isNil(shiftSetting)) {
        nightShiftPrimary = shiftSetting.enabled;
      }
    }
    return nightShiftPrimary;
  }

  private getNightShiftSetting(orgLevelId: number): boolean {
    let nightShiftPrimary: boolean = false;
    let orgLevel: OrgLevel = _.find(this.employeeDepartmentsOrgLevels, (o: OrgLevel) => o.id === orgLevelId);
    if (_.isNil(orgLevel)) {
      return false;
    }
    let organization: Organization = this.getOrganizationByOrgLevel(orgLevel.parentId);
    if (!_.isNil(organization)) {
      let shiftSetting: NightShiftSetting = this.getNightShiftSettingForOrganization(organization.id);
      if (!_.isNil(shiftSetting)) {
        nightShiftPrimary = shiftSetting.enabled;
      }
    }
    return nightShiftPrimary;
  }

  private getShiftDefinitionDates(definition: ShiftDefinition, dateOn: Date): { start: moment.Moment, end: moment.Moment } {
    let timeString: string;
    let startMoment: moment.Moment;
    let endMoment: moment.Moment;

    timeString = moment(definition.start, appConfig.militaryTimeFormat).format(appConfig.timeFormat);
    startMoment = moment(dateOn);
    this.dateTimeService.setTimeToMoment(startMoment, timeString);

    timeString = moment(definition.end, appConfig.militaryTimeFormat).format(appConfig.timeFormat);
    endMoment = moment(dateOn);
    this.dateTimeService.setTimeToMoment(endMoment, timeString);
    return { start: startMoment, end: endMoment };

  }

  private sortShifts(container: ScheduleEntryContainer): void {
    container.shifts = _.sortBy(container.shifts, ['shiftStartDate']);
  }

  private refreshEntry(scheduleEntryContainer: ScheduleEntryContainer): void {

    this.onOverlapState$.next(false);
    this.onDuplicateState$.next(false);

    _.each(scheduleEntryContainer.shifts, (shift: ScheduleEntryShiftContainer) => {
      shift.hasOverlap = false;
      shift.hasDuplicate = false;
    });

    let shiftRefreshResult: number = 0; // 0 - skipped, 1- refreshed, 2 - stop refresh

    _.each(scheduleEntryContainer.shifts, (shift: ScheduleEntryShiftContainer) => {
      if (shiftRefreshResult !== 2) {
        shiftRefreshResult = this.refreshScheduleEntryShift(shift);
      }
    });

    this.checkChanges();
  }

  private refreshScheduleEntryShift(shift: ScheduleEntryShiftContainer): number {

    // if no shift selected. can't update anything;
    if (!shift.scheduleEntryShift.shift) return 0;

    // check for duplicates
    let duplicatesResult = this.checkDuplicatedShift(shift);
    if (duplicatesResult) {
      if (!this.duplicateShown) {
        this.duplicateShown = true;
        this.modalService.globalAnchor.openInfoDialog('Duplicated Shift', `The shift (${shift.scheduleEntryShift.shift.name}) used twice.`, () => {
          this.duplicateShown = false;
        });
      }
      this.m_OnDuplicateState.next(true);
      return 2;
    }

    // check overlaping of shift before all calculations
    let overlapState: number = this.checkOverlapingOfShift(shift);

    // if has overlaps exiting. will refresh whole entry again.
    if (overlapState !== OverlapType.NO_OVERLAPS) {
      if (overlapState === OverlapType.FATAL) {
        this.onOverlapState$.next(true);
      }
      return 2;
    }

    if (shift.hasScheduleAbsence) {
      if (shift.scheduleEntryShift.scheduleAbsence) {
        _.noop();
      }
    }


    // calculate lateness
    if (shift.scheduleEntryShift.late) this.calculateLateInterval(shift);

    // check and adjust overtime
    if (shift.scheduleEntryShift.overtime) {
      this.calculateOvertimeHours(shift.scheduleEntryShift.overtime);
    }

    // calculate shift hours
    this.calculatePaidHours(shift);
    this.calculateUnpaidHours(shift);

    shift.scheduleEntryShift.hours = shift.shiftHours;
    shift.scheduleEntryShift.startDate = shift.shiftStartDate;
    shift.scheduleEntryShift.endDate = shift.shiftEndDate;

    // update partial absence
    if (shift.scheduleEntryShift.partialAbsence) {
      let shiftStart: moment.Moment = moment(shift.shiftStartDate);
      let shiftEnd: moment.Moment = moment(shift.shiftEndDate);
      let paStart: moment.Moment = moment(shift.scheduleEntryShift.partialAbsence.startDate);
      let paEnd: moment.Moment = moment(shift.scheduleEntryShift.partialAbsence.endDate);

      if (paStart.isSame(shiftStart)) {
        // from start
        shift.scheduleEntryShift.startDate = paEnd.toDate();

      } else if (paEnd.isSame(shiftEnd)) {
        // to end
        shift.scheduleEntryShift.endDate = paStart.toDate();
      }
      if (shift.shiftHours > shift.scheduleEntryShift.partialAbsence.hours) {
          shift.scheduleEntryShift.hours = shift.shiftHours - shift.scheduleEntryShift.partialAbsence.hours;
      } else {
          shift.scheduleEntryShift.hours = 0;
          const diff: number = Math.abs(shift.shiftHours - shift.scheduleEntryShift.partialAbsence.hours);
          shift.unpaidHours = diff > shift.unpaidHours ? 0 : shift.unpaidHours - diff;
      }
      shift.shiftHours = shift.scheduleEntryShift.hours;
    }

    return 1;
  }

  private checkDuplicatedShift(shiftToCheck: ScheduleEntryShiftContainer): boolean {

    if (!shiftToCheck.scheduleEntryShift.shift) return false;

    let duplicates: ScheduleEntryShift[] =
      this.scheduleEntry.shifts.filter((shift: ScheduleEntryShift) => {
        if (shift !== shiftToCheck.scheduleEntryShift && shift.shift) {
          return shift.shift.id === shiftToCheck.scheduleEntryShift.shift.id;
        }
        return false;
      });

    if (duplicates.length > 0) {
      return true;
    }
    return false;
  }

  private checkOverlapingOfShift(shiftToCheck: ScheduleEntryShiftContainer): number {

    if (!shiftToCheck.scheduleEntryShift.shift) return OverlapType.NO_OVERLAPS;

    let rangeToCheck: moment.Range = moment.range(shiftToCheck.shiftStartDate, shiftToCheck.shiftEndDate);
    let fatalOverlaps: ScheduleEntryShiftContainer[] =
      this.scheduleEntryContainer.shifts
        .filter((shift: ScheduleEntryShiftContainer) => shift.scheduleEntryShift.shift && shift.scheduleEntryShift !== shiftToCheck.scheduleEntryShift &&
          moment.range(shift.shiftStartDate, shift.shiftEndDate).contains(shiftToCheck.shiftStartDate) &&
          moment.range(shift.shiftStartDate, shift.shiftEndDate).contains(shiftToCheck.shiftEndDate));

    // fatal situation. one shift contains another. we can't adjust it automatically. so asking user resolve it manually
    if (fatalOverlaps && fatalOverlaps.length > 0) {
      if (!this.overlapShown) {
        this.overlapShown = true;
        this.modalService.globalAnchor.openInfoDialog(
          'Schedule overlaping',
          `The shift overlaps with the existing schedule.` +
          `The shift can not be adjusted to remove the overlap.`, (result: boolean) => {
            this.overlapShown = false;
          });
      }
      shiftToCheck.hasOverlap = true;
      _.each(fatalOverlaps, (overlapShift: ScheduleEntryShiftContainer) => overlapShift.hasOverlap = true);

      return OverlapType.FATAL;

    }

    // searh for overlaping shifts that can be auto-adjusted
    let overlaps: ScheduleEntryShiftContainer[] =
      this.scheduleEntryContainer.shifts
        .filter((shift: ScheduleEntryShiftContainer) => shift.scheduleEntryShift.shift && shift !== shiftToCheck && moment.range(shift.shiftStartDate, shift.shiftEndDate).overlaps(rangeToCheck));
    if (overlaps && overlaps.length > 0) {
      if (!this.overlapShown) {
        this.overlapShown = true;
        this.modalService.globalAnchor.openInfoDialog(
          'Schedule overlaping',
          `The shift overlaps with the existing schedule.` +
          `The shift being added will be adjusted to remove the overlap.`, (result: boolean) => {
            this.removeOverlap(shiftToCheck, overlaps);
            this.overlapShown = false;
          });
      }
      return OverlapType.ADJUSTABLE;
    }

    return OverlapType.NO_OVERLAPS;
  }

  private removeOverlap(shiftToFix: ScheduleEntryShiftContainer, overlaps: ScheduleEntryShiftContainer[]): void {
    _.forEach(overlaps, (shift: ScheduleEntryShiftContainer) => {
      if (shiftToFix.shiftStartDate < shift.shiftStartDate) {
        shiftToFix.shiftEndDate = new Date(shift.shiftStartDate.getTime());
      } else {
        shiftToFix.shiftStartDate = new Date(shift.shiftEndDate.getTime());
      }
    });
    this.m_OnOverlapState.next(false);
    this.refreshEntry(this.scheduleEntryContainer);
  }

  private calculateUnpaidHours(container: ScheduleEntryShiftContainer): void {
    container.unpaidHours = this.getUnpaidTime(container);
  }

  private calculatePaidHours(container: ScheduleEntryShiftContainer): void {
    let diff: number = moment.range(container.shiftStartDate, container.shiftEndDate).diff();
    let duration: moment.Duration = moment.duration(diff);
    let minutes: number = duration.asMinutes();
    let hours = Math.round((minutes / 60) * 100) / 100; //rounding to 2 decimals
    hours = hours - container.unpaidHours;
    if (hours < 0) hours = 0;
    container.scheduleEntryShift.hours = hours;
    container.shiftHours = hours;
  }

  private calculateShiftEndTime(container: ScheduleEntryShiftContainer): void {
    if (container.shiftStartDate) {
      let totalHours: number = container.shiftHours + container.unpaidHours;
      container.shiftEndDate = this.calculateEndTime(container.shiftStartDate, totalHours);
    }
  }

  private getUnpaidTime(shift: ScheduleEntryShiftContainer): number {
    let unpaidHours: number = 0;
    let startMoment: moment.Moment = moment(shift.shiftStartDate);
    let endMoment: moment.Moment = moment(shift.shiftEndDate);
    if (endMoment.isAfter(startMoment)) {
      let totalMs: number = endMoment.diff(startMoment);
      let paidTimeMs: number = this.hours2Ms(shift.shiftHours);
      unpaidHours = (totalMs - paidTimeMs);
      if (unpaidHours > 0) {
        unpaidHours = this.ms2Hours(unpaidHours);
        unpaidHours = Math.round(unpaidHours * 100) / 100; // rounding to 2 decimals
      } else {
        unpaidHours = 0;
      }
    }
    return unpaidHours;
  }

  private calculatePartialAbsenceStartTime(absence: ShiftPartialAbsence): void {
    if (absence) {
      let endMoment: moment.Moment = moment(absence.endDate);
      let startMoment: moment.Moment = endMoment.add(-1 * absence.hours, 'hours');
      absence.startDate = startMoment.toDate();
    }
  }

  private calculatePartialAbsenceEndTime(absence: ShiftPartialAbsence): void {
    if (absence) {
      let startMoment: moment.Moment = moment(absence.startDate);
      let endMoment: moment.Moment = startMoment.add(absence.hours, 'hours');
      absence.endDate = endMoment.toDate();
    }
  }

  private calculatePartialAbsenceHours(absence: ShiftPartialAbsence): void {
    if (absence) {
      let hours: number = this.ms2Hours(moment(absence.endDate).diff(absence.startDate));
      hours = Math.round(hours * 100) / 100;
      absence.hours = hours;
    }
  }

  private calculateOvertimeHours(overtime: ShiftOvertime): void {
    if (overtime) {
      let diff: number = moment.range(overtime.startDate, overtime.endDate).diff();
      let duration: moment.Duration = moment.duration(diff);
      let minutes: number = duration.asMinutes();
      let hours = Math.round((minutes / 60) * 100) / 100;
      overtime.duration = hours;
    }
  }

  private calculateLateTimeStamp(shift: ScheduleEntryShiftContainer): void {
    if (shift && shift.scheduleEntryShift.late && shift.scheduleEntryShift.late.lateInterval) {
      let date: Date = moment(shift.shiftStartDate).add(shift.scheduleEntryShift.late.lateInterval, 'minutes').toDate();
      shift.scheduleEntryShift.late.arrivalDate = date;
    }
  }

  private calculateLateInterval(shift: ScheduleEntryShiftContainer): void {
    if (shift.scheduleEntryShift.late) {
      if (shift.scheduleEntryShift.late.arrivalDate) {
        let diff: number = moment.range(shift.shiftStartDate, shift.scheduleEntryShift.late.arrivalDate).diff();
        let duration: moment.Duration = moment.duration(diff);
        let minutes: number = duration.asMinutes();
        shift.scheduleEntryShift.late.lateInterval = minutes;
      } else {
        shift.scheduleEntryShift.late.lateInterval = 0;
      }
    }
  }

  private ms2Hours(ms: number): number {
    return ms / 1000 / 60 / 60;
  }

  private hours2Ms(hours: number): number {
    return hours * 1000 * 60 * 60;
  }

  private calculateEndTime(start: Date, hours: number): Date {
    let endDate: Date;
    let milliseconds: number = this.hours2Ms(hours);
    endDate = new Date(milliseconds + start.getTime());
    return endDate;
  }

  private getDurationMs(startDate: Date, endDate: Date): number {
    let duration: number = 0;
    let startMoment: moment.Moment = moment(startDate);
    let endMoment: moment.Moment = moment(endDate);
    if (endMoment.isAfter(startMoment)) {
      duration = endMoment.diff(startMoment);
    }
    return duration;
  }


  private compareEntry(entry: ScheduleEntry): boolean {
    let json: string = JSON.stringify(entry);
    return json !== this.originalScheduleEntry;
  }

  // private fixPositions(): void {
  //   if (this.scheduleEntry.defaultPositionId > 0) {
  //     this.defaultPosition = _.find(this.employeePositions, { 'id': this.scheduleEntry.defaultPositionId });
  //   }
  //   // backend bug. orglevel id not populated in position definition inside schedule entry shift
  //   _.each(this.scheduleEntry.shifts, (shift: ScheduleEntryShift) => {
  //     if (shift.position && shift.position.orgLevelId === null) {
  //       shift.position = _.find(this.employeePositions, { id: shift.position.id });
  //     }
  //   });
  // }

  // private fixShifts(shifts: ShiftDefinition[]): void {
  //   // full shift object needed for some calculations, so search in lookup and replace it
  //   if (this.scheduleEntry.defaultShiftId > 0) {
  //     this.defaultShift = _.find(shifts, { 'id': this.scheduleEntry.defaultShiftId });
  //   }
  //   _.each(this.scheduleEntry.shifts, (shift: ScheduleEntryShift) => {
  //     if (shift.shift) {
  //       shift.shift = _.find(shifts, { 'id': shift.shift.id });
  //       if (shift.shift) {
  //         let definition: ShiftDefinition = shift.shift;
  //         let dates: { start: moment.Moment, end: moment.Moment } = this.getShiftDefinitionDates(definition, shift);
  //         let startMoment: moment.Moment = dates.start;
  //         let endMoment: moment.Moment = dates.end;
  //         //shift.definitionStartDate = startMoment.toDate();
  //         //shift.definitionEndDate = endMoment.toDate();
  //       }
  //     }
  //   });
  // }

  private setUnpaidTime(container: ScheduleEntryShiftContainer): void {
    let definition: ShiftDefinition = _.find(container.shifts, (s: ShiftDefinition) => container.scheduleEntryShift.shift && (s.id === container.scheduleEntryShift.shift.id));
    if (definition && definition.lunchDurationDate) {
      container.unpaidHours = moment(definition.lunchDurationDate).hours() + moment(definition.lunchDurationDate).minutes() / 60;
    } else {
      container.unpaidHours = 0;
    }
  }

  private addLookupValueIfDoesntContain(matchField: string, sourceObject: any, destinationLookup: any[]): void {
    let foundValue = _.find(destinationLookup, (item: any): boolean => item[matchField] === sourceObject[matchField]);
    if (!foundValue) {
      destinationLookup.push(sourceObject);
    }
  }

  private filterAvailablePositionsByDate(positions: Position[], date: Date): Position[] {
    return _.filter(positions, (pos: Position) => {
      return pos.startDate <= date && (!pos.endDate || pos.endDate >= date);
    });
  }

  private getOrganizationByOrgLevel(orgLevelId: number): Organization {
    return _.find(this.organizations, (o: Organization) => o.orgLevelId === orgLevelId);
  }

  private getOrganizationByParentOrgId(orgId: number): Organization {
    return _.find(this.organizations, (o: Organization) => o.id === orgId);
  }

  private getNightShiftSettingForOrganization(organizationId: number): NightShiftSetting {
    let setting: NightShiftSetting = _.find(this.appSettings.nightShiftSettings, (s: NightShiftSetting) => s.organizationId === organizationId);
    if (_.isNil(setting)) {
      setting = _.find(this.appSettings.nightShiftSettings, (s: NightShiftSetting) => s.organizationId === 0);
    }
    return setting;
  }

  public postRequestStaffing(dailyOpenShiftDetailsData: DailyOpenShiftDetailsData[], orgLevelId: number): Promise<any> {
    let promise: Promise<any> = this.scheduleEntryApiService.saveShiftsEntry(dailyOpenShiftDetailsData, orgLevelId).then((response) => {
      this.notificationsService.success('success', 'Successfully submitted shift request');
      return response;
    });
    return promise;
  }

  public getAgencyPostions(customerId:string, partnerId: string): Promise<any> {
    let promise: Promise<any> = this.scheduleEntryApiService.getAgencyPoistionsData(customerId, partnerId)
    .then((response) => {
      return response;
    });
    return promise;
  }

}
