import * as tslib_1 from "tslib";
import { NotificationsService } from '../../../../app/core/components';
import { BusyService } from '../../../common';
import { WfmSyncService } from '../../../configuration/services/wfm/wfm-sync.service';
import { dataCheck_emp } from './../../models/wfm-sync';
import { BaseSyncComponent } from './wfm-sync-base.component';
var BaseOrgDeptPosSyncComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BaseOrgDeptPosSyncComponent, _super);
    function BaseOrgDeptPosSyncComponent(wfmSyncService, notificationService, busyService) {
        var _this = _super.call(this, notificationService, busyService) || this;
        _this.wfmSyncService = wfmSyncService;
        return _this;
    }
    BaseOrgDeptPosSyncComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.SyncOrganizationLevelsChangeLogs(selectedItems, 'migration'));
    };
    BaseOrgDeptPosSyncComponent.prototype.getSyncItemKey = function (item) {
        return '' + item.jobCode;
    };
    BaseOrgDeptPosSyncComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.fetchDataCore())];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.processData(response.data)];
                }
            });
        });
    };
    BaseOrgDeptPosSyncComponent.prototype.processData = function (data) {
        return dataCheck_emp(data);
    };
    return BaseOrgDeptPosSyncComponent;
}(BaseSyncComponent));
export { BaseOrgDeptPosSyncComponent };
