import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import * as moment from 'moment';
import * as _ from 'lodash';
import { IndSchEmployeeSchedulesRequest } from '../../../models/index';
import { IndividualScheduleEmpManagementService } from '../../../services/index';
import { unsubscribe, destroyService, mutableSelect } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { LookupApiService, EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { IndividualScheduleNavigationService } from '../../../../common/services/index';
import { MasterScheduleContextMenuRequest } from '../../../models/master-schedule/master-schedule-context-menu-request';
var IndividualScheduleEmployeeComponent = /** @class */ (function () {
    function IndividualScheduleEmployeeComponent(route, router, lookupApiService, employeeDefinitionsApiService, individualScheduleEmpManagementService) {
        this.route = route;
        this.router = router;
        this.lookupApiService = lookupApiService;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.hasScheduleViewParams = false;
        this.scheduleViewParams = null;
        this.onHasChanges = new EventEmitter();
        this.state = { isLoading: false };
        this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
    }
    Object.defineProperty(IndividualScheduleEmployeeComponent.prototype, "numWeeks", {
        get: function () {
            var numDays = this.endDate.diff(this.startDate, 'days');
            numDays = Math.ceil(numDays / 7);
            return numDays;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleEmployeeComponent.prototype.emitChanges = function (hasChanges) {
        this.onHasChanges.emit(hasChanges);
    };
    IndividualScheduleEmployeeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.uiLockSubscription = this.individualScheduleEmpManagementService.onUILock$.subscribe(function (isLocked) {
            _this.state.isLoading = isLocked;
        });
        this.routeSubscripion = this.route.params
            .combineLatest(this.route.queryParams, this.orgLevel$)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1], orgLevel = _a[2];
            _this.orgLevelId = orgLevel.id;
            var pStartDate;
            var pEndDate;
            var currentDate;
            if (_.isObject(_this.scheduleViewParams)) {
                _this.hasScheduleViewParams = true;
                _this.employeeId = +_this.scheduleViewParams.empId;
                pStartDate = _this.scheduleViewParams.startDate;
                pEndDate = _this.scheduleViewParams.endDate;
            }
            else {
                _this.employeeId = +params['employeeId'];
                pStartDate = queryParams['startDate'];
                pEndDate = queryParams['endDate'];
                currentDate = queryParams['currentDate'];
            }
            if (!pStartDate || !pEndDate) {
                _this.getLastScheduleCycle();
                return;
            }
            _this.startDate = moment(pStartDate, appConfig.linkDateFormat);
            _this.endDate = moment(pEndDate, appConfig.linkDateFormat);
            _this.currentDate = _.isString(currentDate) ? moment(currentDate, appConfig.linkDateFormat).toDate() : null;
            _this.loadEmployeeInfo();
        });
    };
    IndividualScheduleEmployeeComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualScheduleEmployeeComponent.prototype.loadEmployeeInfo = function () {
        var _this = this;
        this.state.isLoading = true;
        this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employeeId)
            .then(function (info) {
            _this.state.isLoading = false;
            _this.empInfo = info;
            _this.emitRequest();
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    IndividualScheduleEmployeeComponent.prototype.getLastScheduleCycle = function () {
        var _this = this;
        if (!this.orgLevelId) {
            return;
        }
        this.state.isLoading = true;
        this.lookupApiService.getScheduleCycles(this.orgLevelId)
            .then(function (cycles) {
            _this.state.isLoading = false;
            var _a = _.first(_.orderBy(cycles, function (cycle) {
                return cycle.startDate.unix;
            }, 'desc')), startDate = _a.startDate, endDate = _a.endDate;
            var navService = new IndividualScheduleNavigationService(_this.router, _this.route);
            navService.NavigateToIndividualScheduleEmp(_this.employeeId, startDate.toDate(), endDate.toDate());
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    IndividualScheduleEmployeeComponent.prototype.emitRequest = function () {
        var req = new IndSchEmployeeSchedulesRequest();
        req.employeeId = this.employeeId;
        req.orgLevelId = this.orgLevelId;
        req.scheduleWeeksCount = this.numWeeks;
        req.startDate = this.startDate.toDate();
        req.employeeName = this.empInfo.name;
        req.currentDate = this.currentDate;
        this.individualScheduleEmpManagementService.onEmployeeRequest(req);
    };
    tslib_1.__decorate([
        mutableSelect(),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleEmployeeComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeComponent.prototype, "uiLockSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", IndividualScheduleEmpManagementService)
    ], IndividualScheduleEmployeeComponent.prototype, "individualScheduleEmpManagementService", void 0);
    return IndividualScheduleEmployeeComponent;
}());
export { IndividualScheduleEmployeeComponent };
