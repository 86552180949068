import { EventEmitter } from '@angular/core';
import { BenefitEligibilityRuleDataType } from '../../../models';
var BenefitDetailsEligibiltyStatementMultiValuesComponent = /** @class */ (function () {
    function BenefitDetailsEligibiltyStatementMultiValuesComponent() {
        this.valueChanged = new EventEmitter();
        this.value = '';
    }
    Object.defineProperty(BenefitDetailsEligibiltyStatementMultiValuesComponent.prototype, "BenefitEligibilityRuleDataType", {
        get: function () {
            return BenefitEligibilityRuleDataType;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsEligibiltyStatementMultiValuesComponent.prototype.onValueChanged = function (value) {
        this.valueChanged.next(value);
    };
    return BenefitDetailsEligibiltyStatementMultiValuesComponent;
}());
export { BenefitDetailsEligibiltyStatementMultiValuesComponent };
