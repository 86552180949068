
export enum PerformanceReviewCodeTypes {
  rating = 'RatingType',
  reviewType = 'ReviewType'
}

export interface IPerformanceReviewCode {
  id: number;
  code: string;
  codeType: IPerformanceReviewCodeType;
  description: string;
}

export interface IPerformanceReviewCodeType {
  id: number;
  name: string;
  description: string;
}

export class PerformanceReviewCode {
  public id: number;
  public name: string;
  public type: PerformanceReviewCodeType;
  public description: string;
}

export class PerformanceReviewCodeType {
  public id: number;
  public name: string;
  public description: string;
}
