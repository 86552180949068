import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { appConfig } from '../../../../../app/app.config';
import { ConfirmDialog2Component, ConfirmOptions, KendoGridStateHelper, ModalService } from '../../../../../app/common';
import { ShiftsApiService, UnitsApiService, PositionsConfigurationApiService } from '../../../../../app/configuration/services';
import { mutableSelect, unsubscribe } from '../../../../../app/core/decorators';
import { OpenShiftSummary } from '../../../../scheduler/models';
import { OpenShiftSchedule, OpenShiftV2, PendingShiftValue } from '../../../../scheduler/models/daily-unit-assignment/add-open-shift';
import { DetailScreenService, OpenShiftManagementApiService, ScheduleEntryApiService } from '../../../../scheduler/services';
import { ShiftRequestService } from '../../../../scheduler/services/schedule/shift-request.service';
import { process } from '@progress/kendo-data-query';
import * as moment from 'moment';
import { NotificationsService } from '../../../../../app/core/components';
import { Observable, Subscription } from 'rxjs-compat';
import { AppSettingsManageService } from '../../../../app-settings/services';
import { scheduleMicrotask } from '../../../../core/utils';
var AddOpenShiftsV2Component = /** @class */ (function () {
    function AddOpenShiftsV2Component(shiftApiService, unitApiService, positionApiService, detailScreenService, shiftRequestService, openShiftManagementApiService, router, route, notificationsService, changeDetector, scheduleEntryApiService, modalService, appSettingsManageService) {
        var _this = this;
        this.shiftApiService = shiftApiService;
        this.unitApiService = unitApiService;
        this.positionApiService = positionApiService;
        this.detailScreenService = detailScreenService;
        this.shiftRequestService = shiftRequestService;
        this.openShiftManagementApiService = openShiftManagementApiService;
        this.router = router;
        this.route = route;
        this.notificationsService = notificationsService;
        this.changeDetector = changeDetector;
        this.scheduleEntryApiService = scheduleEntryApiService;
        this.modalService = modalService;
        this.appSettingsManageService = appSettingsManageService;
        this.isLoading = false;
        this.isSelectedShift = false;
        this.selectedOpenShift = [];
        this.openShift = new OpenShiftV2();
        this.displayId = [];
        this.openShiftPageSize = 10;
        this.max = 99;
        this.min = 0;
        this.newCount = 1;
        this.newOpenShift = [];
        this.newShift = [];
        this.newShiftcount = 1;
        this.shiftUpdated = false;
        this.isEnabled = false;
        this.openShiftDetails = [];
        this.isRowSelected = function (e) { return _this.displayId.indexOf(e.dataItem.displayId) >= 0; };
        this.shiftDetails = [];
        this.pendingShiftValue = [];
        this.pendingShift = [];
        this.state = {};
        this.isUpdatedPendingShift = false;
        this.isOrglevelPayPeriodApproved = false;
        this.isModifyPayPeriodApproved = false;
        this.isLeftSideNavbarOpen = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
        this.openShiftGrid = new KendoGridStateHelper();
        this.openShiftGrid.state.skip = 0;
        this.openShiftGrid.state.sort = [];
        this.openShiftGrid.state.group = [];
    }
    AddOpenShiftsV2Component.prototype.getScreenSize = function (event) {
        this.screenWidth = window.innerWidth;
    };
    AddOpenShiftsV2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.screenWidth = window.innerWidth;
        this.orgLevel$
            .subscribe(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
        this.route.queryParams.subscribe(function (s) {
            _this.orgLevelId = s.orgLevelId;
            if (s.isAgencyEnabled == 'false') {
                _this.isEnabled = false;
            }
            else {
                _this.isEnabled = true;
            }
            _this.requestedDate = moment(s.dateOn, appConfig.linkDateFormat).toDate();
            _this.getShiftDetails();
            _this.getUnits();
            _this.getPositions();
            _this.appConfig = appConfig;
        });
        this.getSettings();
        this.isLeftSidebarOpenSubscripion = this.isLeftSidebarOpen.subscribe(function (value) {
            scheduleMicrotask(function () { _this.isLeftSideNavbarOpen = value; });
        });
    };
    AddOpenShiftsV2Component.prototype.ngAfterViewChecked = function () {
        this.changeDetector.detectChanges();
    };
    AddOpenShiftsV2Component.prototype.getOpenShiftDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var filter;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        filter = null;
                        return [4 /*yield*/, this.openShiftManagementApiService.getOpenShiftSummary(this.orgLevelId, this.requestedDate, filter).then(function (openShiftSummary) {
                                _this.pendingShiftValue = _this.shiftRequestService.pendingShiftDetails;
                                openShiftSummary.details = openShiftSummary.details.filter(function (e) { return e.parentShiftId < 0 && (!e.hasPartialShift); });
                                _this.openShiftSummaryDetails = openShiftSummary;
                                if (openShiftSummary.details.length != 0 && _this.pendingShiftValue.length == 0 && _this.isEnabled) {
                                    _this.loadDetails(openShiftSummary);
                                }
                                else {
                                    _this.isLoading = false;
                                    _this.refreshOpenShift();
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddOpenShiftsV2Component.prototype.loadDetails = function (openShiftSummary) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.detailScreenService.getDailyUnits(this.orgLevel, this.requestedDate).then(function (details) {
                            _this.getShiftState(details, openShiftSummary);
                            _this.isLoading = false;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddOpenShiftsV2Component.prototype.getShiftState = function (details, openShiftSummary) {
        var _this = this;
        this.pendingShift = [];
        details.groups.forEach(function (group) {
            group.rows.forEach(function (element) {
                element.fields.forEach(function (el) {
                    if (el.name == 'PartnerId' && el.value != '') {
                        var isAssigned = element.fields.filter(function (e) { return e.name == 'EmpName' && e.value == ''; });
                        if (isAssigned.length > 0) {
                            _this.getValues(element.fields);
                        }
                    }
                });
            });
        });
        this.pendingShiftValue = this.pendingShift;
        this.refreshOpenShift();
    };
    AddOpenShiftsV2Component.prototype.getValues = function (element) {
        var pendingShift = new PendingShiftValue;
        var position, shift, unit;
        element.forEach(function (el) {
            if (el.name == "JobCode") {
                position = el.value;
            }
            else if (el.name == "ShiftName") {
                shift = el.value;
            }
            else if (el.name == "UnitName") {
                unit = el.value;
            }
        });
        pendingShift.key = position + '_' + shift + '_' + unit;
        pendingShift.value = 1;
        var isExist = false;
        this.pendingShift.forEach(function (element) {
            if (element.key == pendingShift.key) {
                element.value += 1;
                isExist = true;
            }
        });
        if (!isExist) {
            this.pendingShift.push(pendingShift);
        }
    };
    AddOpenShiftsV2Component.prototype.refreshOpenShift = function () {
        var _this = this;
        this.openShiftDetails = [];
        var displayId = 1;
        var detailSummary = this.openShiftSummaryDetails.details;
        var details = this.sortOpenShiftDetails(detailSummary);
        details.forEach(function (element) {
            var openShift = new OpenShiftV2();
            openShift.displayId = displayId++;
            openShift.isNewShift = false;
            openShift.isAdded = false;
            openShift.scheduledShiftCount = element.scheduledShiftCount;
            openShift.overScheduleCount = element.overScheduleCount;
            openShift.Shifts = (element.shift);
            openShift.position = (element.position);
            openShift.unit = (element.unit);
            openShift.openShiftCount = element.openShiftCount;
            openShift.totalShifts = _this.getTotalShift(element);
            openShift.pendingEmployeeRequests = element.newMessageCount;
            _this.openShiftDetails.push(openShift);
        });
        this.ShiftSummaryDetails = this.openShiftDetails;
        this.openShiftPageSize = this.openShiftDetails.length;
        this.pendingShiftValue.forEach(function (element) {
            var el = element.key.split('_');
            _this.pendingShiftDetails(el, element.value);
        });
        this.openShiftGrid.view = null;
        this.openShiftGrid.view = process(this.openShiftDetails, this.state);
        this.newShiftcount = this.openShiftDetails.length + 1;
        this.openShift = new OpenShiftV2();
        this.openShift.displayId = this.newShiftcount;
        this.openShift.isNewShift = true;
        this.openShift.isAdded = true;
        this.openShift.count = 0;
    };
    AddOpenShiftsV2Component.prototype.sortOpenShiftDetails = function (details) {
        //find Shift Details
        var tempShift = [];
        this.shifts.forEach(function (element) {
            tempShift.push(element);
        });
        details.forEach(function (element) {
            var tempShiftDetails = tempShift.filter(function (el) { return el.id == element.shift.id; });
            element.shift = tempShiftDetails[0];
        });
        details.sort(function (a, b) {
            return a.position.name.localeCompare(b.position.name) || (a.shift.startTime - b.shift.startTime) || a.unit.name.localeCompare(b.unit.name);
        });
        return details;
    };
    AddOpenShiftsV2Component.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    AddOpenShiftsV2Component.prototype.dataStateChange = function (state) {
        this.state = state;
        this.openShiftGrid.view = process(this.openShiftDetails, this.state);
    };
    AddOpenShiftsV2Component.prototype.getShiftDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.shiftApiService.getShiftsList(this.orgLevelId).
                                then(function (result) {
                                _this.shifts = result.records.filter(function (e) { return e.parentShiftId < 0 && (!e.hasPartialShift); });
                                _this.shiftsData = _this.shifts;
                                _this.getOpenShiftDetails();
                            }).catch(function () {
                                console.log("error");
                                _this.isLoading = false;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddOpenShiftsV2Component.prototype.getUnits = function () {
        var _this = this;
        this.unitApiService.getUnitsList(this.orgLevelId).
            then(function (result) {
            _this.units = result.records;
            _this.unitData = _this.units;
        }).catch(function () {
            console.log("error Units");
        });
    };
    AddOpenShiftsV2Component.prototype.getPositions = function () {
        var _this = this;
        this.positionApiService.getPositions(this.orgLevelId).
            then(function (result) {
            _this.positions = result.positions;
            _this.positionData = _this.positions;
        }).catch(function () {
        });
    };
    AddOpenShiftsV2Component.prototype.filterPosition = function (value) {
        this.positionData = this.positions.filter(function (s) { return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
    };
    AddOpenShiftsV2Component.prototype.filterUnits = function (value) {
        this.unitData = this.units.filter(function (s) { return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
    };
    AddOpenShiftsV2Component.prototype.filterShifts = function (value) {
        this.shiftsData = this.shifts.filter(function (s) { return s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1; });
    };
    AddOpenShiftsV2Component.prototype.shiftChange = function (dataItem) {
        dataItem.count = -1;
        this.displayId = this.displayId.filter(function (el) { return el != dataItem.displayId; });
    };
    AddOpenShiftsV2Component.prototype.isShiftSelected = function () {
        if (this.displayId.length > 0) {
            return false;
        }
        else {
            return true;
        }
    };
    AddOpenShiftsV2Component.prototype.isUpdatedShiftCount = function (dataItem) {
        var isupdated = true;
        if (dataItem == undefined) {
            isupdated = true;
        }
        else {
            if (dataItem.count == null || dataItem.count == -1) {
                this.displayId = this.displayId.filter(function (el) { return el != dataItem.displayId; });
                isupdated = true;
            }
            else {
                var overageShift = this.getShiftsOverage(dataItem);
                var filledShift = dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests + dataItem.scheduledShiftCount + overageShift;
                if (dataItem.count < filledShift) {
                    this.displayId = this.displayId.filter(function (el) { return el != dataItem.displayId; });
                    isupdated = true;
                }
                else {
                    this.displayId.push(dataItem.displayId);
                    var tempDisplayId = this.displayId.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
                    this.displayId = tempDisplayId;
                    this.shiftUpdated = true;
                    isupdated = false;
                }
            }
        }
        return isupdated;
    };
    AddOpenShiftsV2Component.prototype.isNewOpenShift = function () {
        if (this.openShift.position.length == 0 || this.openShift.unit.length == 0 || this.openShift.Shifts.length == 0 || this.openShift.count < 1 || this.openShift.count > 99) {
            return true;
        }
        else {
            //validation for Unique shift/position/unit combination 
            return this.isUniqueShifts();
        }
    };
    AddOpenShiftsV2Component.prototype.isUniqueShifts = function () {
        var _this = this;
        if (this.openShiftDetails.length == 0) {
            return false;
        }
        else {
            this.isMappedShift = false;
            // // Error if shift with same defination found 
            if (this.openShiftDetails.length > 0) {
                var existingShiftDetails = this.openShiftDetails.concat(this.shiftDetails);
                existingShiftDetails.forEach(function (element) {
                    var position = _this.openShift.position;
                    var m_position = element.position;
                    var unit = _this.openShift.unit;
                    var m_unit = element.unit;
                    var shifts = _this.openShift.Shifts;
                    var m_shifts = element.Shifts;
                    if ((m_position.id == position.id) && (m_unit.id == unit.id) && (m_shifts.id == shifts.id)) {
                        _this.isMappedShift = true;
                    }
                });
            }
            return this.isMappedShift;
        }
    };
    AddOpenShiftsV2Component.prototype.addNewOpenShift = function () {
        this.openShift.isNewShift = true;
        this.openShift.isAdded = false;
        this.openShiftDetails.unshift(this.openShift);
        this.shiftUpdated = true;
        //adding new row  
        this.openShift = new OpenShiftV2();
        this.openShift.isNewShift = true;
        this.newShiftcount = this.newShiftcount + 1;
    };
    AddOpenShiftsV2Component.prototype.addOpenShifts = function () {
        this.getSelectedShift();
        this.shiftDetails = this.shiftDetails.concat(this.selectedOpenShift);
        if (this.openShift == null) {
            var tempOpenShift = _.cloneDeep(this.openShift);
            this.shiftDetails.push(tempOpenShift);
        }
        if (this.shiftDetails.length > 0) {
            this.isSelectedShift = true;
        }
        else {
            this.isSelectedShift = false;
        }
        this.resetOpenShift();
        this.refreshGrid();
    };
    AddOpenShiftsV2Component.prototype.getDisplayMessage = function (dataItem) {
        var isError = false;
        if (dataItem.count >= 0) {
            var overageShift = this.getShiftsOverage(dataItem);
            var filledShift = dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests + dataItem.scheduledShiftCount + overageShift;
            if (dataItem.count < filledShift) {
                isError = true;
            }
        }
        if (dataItem.count == null || dataItem.count == -1) {
            isError = false;
        }
        return isError;
    };
    AddOpenShiftsV2Component.prototype.getSelectedShift = function () {
        var _this = this;
        this.selectedOpenShift = [];
        // update Display Id 
        var tempDisplayId = this.displayId.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
        // update selected Shift
        tempDisplayId.forEach(function (element) {
            _this.openShiftDetails.forEach(function (el) {
                if (el.displayId == element) {
                    _this.selectedOpenShift.push(el);
                }
            });
        });
    };
    AddOpenShiftsV2Component.prototype.getUnassignedShiftCount = function (dataItem) {
        var unassignedShift = dataItem.openShiftCount - (dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests);
        return unassignedShift >= 0 ? unassignedShift : 0;
    };
    AddOpenShiftsV2Component.prototype.getShiftsOverage = function (dataItem) {
        var overageShift = dataItem.openShiftCount - (dataItem.pendingAgencyRequests + dataItem.pendingEmployeeRequests);
        return overageShift < 0 ? Math.abs(overageShift) : 0;
    };
    AddOpenShiftsV2Component.prototype.getTotalShift = function (dataItem) {
        var unassignedShift = this.getUnassignedShiftCount(dataItem);
        var overageShift = this.getShiftsOverage(dataItem);
        return unassignedShift + (dataItem.pendingAgencyRequests || 0) + (dataItem.pendingEmployeeRequests || 0) + dataItem.scheduledShiftCount + overageShift;
    };
    AddOpenShiftsV2Component.prototype.refreshGrid = function () {
        if (this.shiftDetails.length == 0) {
            this.isSelectedShift = false;
            this.gridState.view = null;
            return;
        }
        this.pageSize = this.shiftDetails.length;
        this.gridState.view = process(this.shiftDetails, this.gridState.state);
    };
    AddOpenShiftsV2Component.prototype.resetOpenShift = function () {
        var _this = this;
        var tempDisplayId = this.displayId.filter(function (item, i, ar) { return ar.indexOf(item) === i; });
        tempDisplayId.forEach(function (element) {
            _this.openShiftDetails = _this.openShiftDetails.filter(function (el) { return el.displayId != element; });
        });
        this.openShiftGrid.view = process(this.openShiftDetails, this.state);
        this.displayId = [];
        this.openShift = new OpenShiftV2();
        this.openShift.isNewShift = true;
    };
    AddOpenShiftsV2Component.prototype.deleteHandler = function (dataItem) {
        var deletedShift = this.shiftDetails.filter(function (el) { return el.displayId == dataItem.displayId; });
        if (!dataItem.isNewShift) {
            deletedShift[0].count = -1;
            this.openShiftDetails = this.openShiftDetails.concat(deletedShift);
            this.openShiftDetails.sort(function (a, b) { return a.displayId < b.displayId ? -1 : 1; });
        }
        else {
            this.openShiftDetails = this.openShiftDetails.filter(function (el) { return el.displayId != dataItem.displayId; });
        }
        this.shiftDetails = this.shiftDetails.filter(function (el) { return el.displayId != dataItem.displayId; });
        this.refreshGrid();
        this.openShiftGrid.view = process(this.openShiftDetails, this.state);
    };
    AddOpenShiftsV2Component.prototype.onCancel = function () {
        this.navigateToDailyUnitGrid();
    };
    AddOpenShiftsV2Component.prototype.onAdd = function () {
        this.isLoading = true;
        var idealSchedule = new OpenShiftSchedule();
        idealSchedule.openShiftsV2 = this.shiftDetails;
        idealSchedule.orgLevelId = this.orgLevelId;
        idealSchedule.requestedDate = this.requestedDate.toLocaleDateString();
        if (this.isModifyPayPeriodApproved)
            this.approvedPayperiodForOrgLevel(idealSchedule);
        else {
            this.saveIdealScheduleV2(idealSchedule);
        }
    };
    AddOpenShiftsV2Component.prototype.navigateToDailyUnitGrid = function () {
        this.router.navigateByUrl(this.getUrlTree());
    };
    AddOpenShiftsV2Component.prototype.getUrlTree = function () {
        var params = {
            keepOrgLevelBreadcrmb: true,
            dateOn: moment(this.requestedDate).format(appConfig.linkDateFormat)
        };
        return this.router.createUrlTree(['daily_unit_assignment'], {
            relativeTo: this.route.pathFromRoot[2],
            queryParams: params
        });
    };
    AddOpenShiftsV2Component.prototype.pendingShiftDetails = function (details, value) {
        this.openShiftDetails.forEach(function (element) {
            var m_position = element.position;
            var m_unit = element.unit;
            var m_shifts = element.Shifts;
            if ((m_position.id == parseInt(details[0])) && (m_shifts.name == details[1]) && (m_unit.name == details[2])) {
                element.pendingAgencyRequests = value;
            }
        });
    };
    AddOpenShiftsV2Component.prototype.approvedPayperiodForOrgLevel = function (idealSchedule) {
        var _this = this;
        this.scheduleEntryApiService.checkApprovedPayperiodForOrgLevel(this.orgLevel.id, idealSchedule.requestedDate, idealSchedule.requestedDate).then(function (data) {
            _this.isOrglevelPayPeriodApproved = data;
            if (_this.isOrglevelPayPeriodApproved) {
                var message = _this.openShiftSummaryDetails.canEditScheduleApprovedPeriod ? "This will modify a schedule in an approved pay period and impact the PBJ Calculation for the employee, are you sure you want to continue?" : 'You do not have permissions to modify a schedule in an approved pay period';
                var popupOptions = new ConfirmOptions();
                popupOptions.showCancel = true;
                popupOptions.showOK = _this.openShiftSummaryDetails.canEditScheduleApprovedPeriod ? true : false;
                ;
                popupOptions.buttonOKtext = 'Ok';
                popupOptions.buttonCanceltext = _this.openShiftSummaryDetails.canEditScheduleApprovedPeriod ? 'Cancel' : 'Ok';
                ConfirmDialog2Component.openDialog('Warning', message, _this.modalService, function (result) {
                    if (result) {
                        _this.saveIdealScheduleV2(idealSchedule);
                    }
                    else {
                        _this.isLoading = false;
                    }
                }, popupOptions);
            }
            else {
                _this.saveIdealScheduleV2(idealSchedule);
            }
        });
    };
    AddOpenShiftsV2Component.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddOpenShiftsV2Component.prototype.saveIdealScheduleV2 = function (idealSchedule) {
        var _this = this;
        this.detailScreenService.saveIdealScheduleV2(this.orgLevelId, idealSchedule).then(function (e) {
            _this.shiftRequestService.openShiftRequest(true);
            _this.isLoading = false;
            _this.notificationsService.success('success', 'Successfully submitted Open shift request');
            _this.navigateToDailyUnitGrid();
        }).catch(function (e) {
            _this.isLoading = false;
            _this.navigateToDailyUnitGrid();
        });
    };
    Object.defineProperty(AddOpenShiftsV2Component.prototype, "isDesktop", {
        get: function () {
            if (this.screenWidth >= this.appConfig.devices.desktopWidth.min)
                return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV2Component.prototype, "isTablet", {
        get: function () {
            if ((this.screenWidth >= this.appConfig.devices.mobileWidth.max) && (this.screenWidth <= this.appConfig.devices.tabletWidth.max))
                return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV2Component.prototype, "isMobile", {
        get: function () {
            if (this.screenWidth <= this.appConfig.devices.mobileWidth.max)
                return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV2Component.prototype, "pendingShiftsColWidth", {
        get: function () {
            if (this.isDesktop)
                return 120;
            else if (this.isTablet)
                return 220;
            else if (this.isMobile)
                return 270;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV2Component.prototype, "agencyColWidth", {
        get: function () {
            if (this.isDesktop)
                return 120;
            else if (this.isTablet)
                return 200;
            else if (this.isMobile)
                return 250;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV2Component.prototype, "totalShiftsColWidth", {
        get: function () {
            if (this.isDesktop)
                return 90;
            else
                return 110;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddOpenShiftsV2Component.prototype, "getPositionWidth", {
        get: function () {
            if (this.screenWidth < 690)
                return 130;
            else
                return 80;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AddOpenShiftsV2Component.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", OpenShiftSummary)
    ], AddOpenShiftsV2Component.prototype, "selectedOpenShiftSummary", void 0);
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], AddOpenShiftsV2Component.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AddOpenShiftsV2Component.prototype, "isLeftSidebarOpenSubscripion", void 0);
    return AddOpenShiftsV2Component;
}());
export { AddOpenShiftsV2Component };
