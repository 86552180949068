import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Input} from '@angular/core';
import { Group, Path, Text, geometry } from '@progress/kendo-drawing';
import { AxisLabelVisualArgs, SeriesClickEvent } from '@progress/kendo-angular-charts';
const { Rect, Point, Size, transform } = geometry;
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';
import { LoaConsole, LoaTypeOverview } from '../../../models/index';
import { LmNavigationService } from '../../../../../common/services/navigation/index';

export interface IOverviewItem {
  loaTypeOverview: LoaTypeOverview;
  value: number;
  name: string;
  color: string;
  priorSum: number;
  actualValue : number;
}

@Component({
  moduleId: module.id,
  selector: 'slx-lm-console-overview',
  templateUrl: 'lm-console-overview.component.html',
  styleUrls: ['lm-console-overview.component.scss'],
})
export class LMConsoleOverviewComponent implements OnInit, OnDestroy {
  @Input()
  public set loaConsole(value: LoaConsole) {
    this.m_loaConsole = value;
    this.createData();
  }

  public get loaConsole() {
    return this.m_loaConsole;
  }

  public data: IOverviewItem[] = [];
  private hrNavService: LmNavigationService;
  private m_loaConsole: LoaConsole;
  private subscriptions: StringMap<Subscription> = {};
  
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) {
    this.hrNavService = new LmNavigationService(this.router, this.activatedRoute);
  }

  public ngOnInit(): void { }
 
  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public labelContent(e: any): string {
    return e.value;
  }

  public onSeriesClick(event: SeriesClickEvent): void {
    if (!event.dataItem) {
      return;
    }
    this.hrNavService.navigateToLmRoster(undefined, undefined, event.dataItem.name);
  }

  public labelVisual(e: AxisLabelVisualArgs) {
  
    if (e.rect.origin.x === 0) {
      return null;
    }
    const offsetPerChar = 4;
    const offsetLabelTop = 35;
    const offsetLabelContentTop = 55;
    const offsetMarkTop = 12;
    const offsetMarkLeft = 12;
    const minWidth = 120;
    const isTooSmall = false; 

    const defaultLabel: Group = <Group>e.createVisual();
    defaultLabel.removeAt(0);
    const textElement = <Text>defaultLabel.children[0];
    const dataItem = <IOverviewItem>e.dataItem;
    
    let charOffset = isTooSmall ? 5 * offsetPerChar : e.dataItem.name.length * offsetPerChar;
    let offset = 0;
   
    if (e.dataItem.priorSum > 0) {
      offset = -e.dataItem.value * (e.rect.origin.x / (e.dataItem.priorSum + e.dataItem.value)) / 2;
    } else {
      offset = -(e.rect.origin.x / 2);
    }

    defaultLabel.transform((geometry.transform().translate(offset - charOffset, offsetLabelTop)));
    textElement.content(dataItem.name);
    const bbox = defaultLabel.bbox();
    const borderRect = new Rect(
      new Point(bbox.bottomLeft().x - offsetMarkLeft, bbox.bottomLeft().y - offsetMarkTop),
      new Size(4, 12)
    );
    const path = Path.fromRect(borderRect, {
      stroke: {
        color: dataItem.color,
        width: 4
      }
    });

    const text = new Text(
      e.dataItem.actualValue,
      new Point(bbox.bottomLeft().x, bbox.bottomLeft().y - offsetLabelContentTop),
      {
        font: 'bold 24px Arial',
        fill: {
          color: '#ffffff'
        }
      }
    );

    const group = new Group();
    group.append(defaultLabel, text);
    group.append(defaultLabel, path);
    return group;
  }

  private createData(): void {
    let sum = 0;
    this.data.length = 0;
    if (!this.m_loaConsole) {
      return;
    }

    _.forEach(this.m_loaConsole.loaTypeOverview, (o: LoaTypeOverview) => {
      if (o.count > 0) {
        this.data.push({
          loaTypeOverview: o,
          priorSum: sum,
          value: 10,
          actualValue : o.count,
          name: o.loaType.name,
          color: o.loaType.color
        });
        sum = sum + 10;
      }
    });
  }
}
