import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { ModalService, DialogOptions } from '../../../../common/index';
import { Assert } from '../../../../framework/index';
import { Lookup, LookupType } from '../../../../organization/models/index';
import { NgForm } from '@angular/forms';
import { LookupService } from '../../../../organization/services/index';
import { ACA1095cConfig } from '../../../aca-1095.config';
var Aca1095CAddEmployeePopupComponent = /** @class */ (function () {
    function Aca1095CAddEmployeePopupComponent(options, modalService, lookupService) {
        var _this = this;
        this.lookupService = lookupService;
        this.virtual = {
            itemHeight: 55
        };
        this.disabledEmployees = [];
        this.hasEmployee = false;
        this.itemDisabled = function (itemArgs) {
            return _this.isEmployeeExist(itemArgs.dataItem['id']);
        };
        Assert.isNotNull(modalService, 'modalService');
        Assert.isNotNull(options, 'options');
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
        this.isLoading = false;
        this.loadStatusChange = new EventEmitter();
        this.employeeSelected = new EventEmitter();
        this.employeesLookup = this.createLookup([], '', '');
    }
    Object.defineProperty(Aca1095CAddEmployeePopupComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Aca1095CAddEmployeePopupComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Aca1095CAddEmployeePopupComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.loadSubsection();
    };
    Aca1095CAddEmployeePopupComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    Aca1095CAddEmployeePopupComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    Aca1095CAddEmployeePopupComponent.prototype.loadSubsection = function () {
        if (this.orgLevel && this.orgLevel.id) {
            this.loadLookup();
        }
    };
    Aca1095CAddEmployeePopupComponent.prototype.loadLookup = function () {
        var _this = this;
        this.loadStatusChange.emit(true);
        this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id, isActive: false })
            .then(function (employeesLookup) {
            _this.employeesLookup = _this.filterLookup(employeesLookup);
            _this.loadStatusChange.emit(false);
        });
    };
    Aca1095CAddEmployeePopupComponent.prototype.filterLookup = function (lookup) {
        var _this = this;
        if (!lookup || !lookup.items) {
            return lookup;
        }
        lookup.items = _.filter(lookup.items, function (item) { return !_.some(ACA1095cConfig.hiddenEmployeeTypesForAdd, function (hiddenType) { return _.trim(_.toLower(hiddenType)) === _.trim(_.toLower(item.type)); }) && (!item.terminationDate || moment(item.terminationDate).year() >= _this.year); });
        return lookup;
    };
    Aca1095CAddEmployeePopupComponent.prototype.isEmployeeExist = function (employeeId) {
        var employeeList = _.filter(this.disabledEmployees, function (employee) { return employee.employeeId == employeeId; });
        return employeeList.length > 0;
    };
    Aca1095CAddEmployeePopupComponent.prototype.ngOnDestroy = function () { };
    Aca1095CAddEmployeePopupComponent.prototype.onEmployeeChanged = function (emp) {
        if (_.isNil(emp)) {
            this.hasEmployee = false;
        }
        else {
            this.hasEmployee = true;
        }
        this.employee = emp;
        this.employeeSelected.emit(emp);
    };
    Aca1095CAddEmployeePopupComponent.prototype.createLookup = function (items, titleField, valueField) {
        var lookup = new Lookup();
        lookup.titleField = titleField;
        lookup.valueField = valueField;
        lookup.items = items;
        return lookup;
    };
    return Aca1095CAddEmployeePopupComponent;
}());
export { Aca1095CAddEmployeePopupComponent };
