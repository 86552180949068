<section class="groups">
    <header class="groups__header">
      <button type="button" class="slx-button slx-blue slx-with-icon" [disabled]="isEditing" (click)="onAddGroup()">
        <i aria-hidden="true" class="slx-button__icon fal fa-plus"></i>
        <span class="slx-button__text">Add Group</span>
      </button>
      <p class="groups__hd-text">Click <i aria-hidden="true" class="fas fa-bars"></i> and drag to reorder Employer inside each ALE Group</p>

    </header>

    <section class="groups__grid flex flex-direction__column">
      <header class="groups__grid-hd flex flex-direction-row justify-content__space-around">
        <div class="groups__grid-th">Edit</div>
        <div class="groups__grid-th">ALE Group</div>
        <div class="groups__grid-th flex justify-content__space-between align-items__center">
          <span>Employer</span>
          <div class="groups__filter">
            <em class="groups__filter-icon"
              [popper]="employerFilter"
              [popperTrigger]="'click'"
              [popperPlacement]="'bottom-end'"
              [popperDisableStyle]="true"
            >
              <i aria-hidden="true" class="fas fa-filter"></i>
            </em>
            <popper-content #employerFilter class="slx-popper">
              <div class="groups__popper">
                <input type="text"
                  class="groups__input search"
                  [(ngModel)]="searchText"
                  (input)="onFilterChanged()"
                  placeholder="Enter employer name"
                  name="employerName"
                  autocomplete="off"/>
                <em class="groups__clear-icon" (click)="onResetSearch()">
                  <i aria-hidden="true" class="fas fa-times"></i>
                </em>
              </div>
            </popper-content>
          </div>
        </div>
      </header>
      <div class="groups__grid-body">
        <div class="flex flex-direction-row justify-content__space-around flex__grow groups__grid-row" *ngFor="let group of groups">
          <span class="groups__grid-td button-cell">
            <ng-container *ngIf="!group.isEditing && !isDefaultGroup(group)">

                <button type="button"
              [disabled]="!canEditGroup(group)"
                (click)="onEdit(group)"
                class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad">
                <i aria-hidden="true" class="fas fa-edit"></i>
              </button>

              <button type="button"
            class="slx-button slx-grey slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks grid-btn-pad"
            [disabled]="!canRemoveGroup(group) || group.disabled"
            [popover]="confirmDeleting">
          <i aria-hidden="true" class="fas fa-trash"></i>
          </button>

              <popover-content  #confirmDeleting title="Are you sure you want to delete this group?" placement="auto right"  >
                  <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r" (click)="onDelete(true, confirmDeleting, group)">Delete</button>
                  <button type="button" class="slx-button slx-narrow" (click)="onDelete(false, confirmDeleting, file)">Cancel</button>
                </popover-content>
            </ng-container>

           <ng-container *ngIf="group.isEditing && !isDefaultGroup(group)">
              <button type="button"
             [disabled]="!canSaveGroup(group)"
                (click)="onSave(group)"
                class="groups__edit-icon">
                <i aria-hidden="true" class="fas fa-save"></i>
              </button>

              <button type="button"
               (click)="onCancel(group)"
                class="groups__del-icon">
                <i aria-hidden="true" class="fas fa-times"></i>
              </button>
            </ng-container>
          </span>
          <span class="groups__grid-td">
            <ng-container *ngIf="!group.isEditing">{{ group.groupName}}</ng-container>
            <ng-container *ngIf="group.isEditing">
              <input #groupName="ngModel"
                type="text"
                class="groups__input"
                maxlength="50"
                [(ngModel)]="group.groupName"
                (input)="onInputGroupName(groupName, group)"
                [required]="true"
                placeholder="Enter group name"
                name="groupName"
                autocomplete="off"
              />
              <p *ngIf="groupName.errors" class="slx-error-block">
                <span *ngIf="groupName.errors.required" errorMessage for="required"></span>
                <span *ngIf="groupName.errors.uniqueName" errorMessage for="required">Group name has to be unique</span>
              </p>
            </ng-container>
          </span>
          <span class="groups__grid-td scroll flex">
            <span class="groups__pos-length">{{ group.employers.length }}</span>
            <span class="flex flex-direction__column groups__grid-positions" [class.disabled]="!canDragNDropGroups()">
              <kendo-sortable
                [kendoSortableBinding]="group.employers"
                (dataAdd)="onDragAdd($event)"
                (dragStart)="onDragStart(group)"
                (dragEnd)="onDragEnd(group)"
                [animation]="true"
                itemClass="groups__pos-item"
                emptyText="Drag employers here..."
                emptyItemClass="groups__pos-name"
                zone="positions"
              >
                <ng-template let-employer="item">
                  <i aria-hidden="true" class="groups__pos-icon fas fa-bars"></i>
                  <em class="groups__pos-name" [title]="employer.legalName">{{ employer.legalName }}</em>
                </ng-template>
              </kendo-sortable>
            </span>
          </span>
        </div>
      </div>
    </section>

  </section>
