
export interface IPBJDuplicateWorkers {
    location: number;
    pbJ_ID_1: number,
    emp_id_1 : number,
    first_Name_1: string,
    last_name_1: string,
    position_1: string,
    email_1: string,
    phone_1: string,
    birthday_1: Date,
    ssN_1: string,
    primary_Location_1 : string,
    emp_dt_hire_1 : Date,
    pbJ_ID_2: number,
    emp_id_2 : number,
    first_Name_2: string,
    last_name_2: string,
    position_2: string,
    email_2: string,
    phone_2: string,
    birthday_2: Date,
    ssN_2: string,
    primary_Location_2 : string;
    emp_dt_hire_2 : Date;
    name_Matching:number;
    ssN_Matching : number;
    email_Matching : number;
    birthday_matching : number;
    phone_Matching : number;
    matching_score: number;
    disposition : string;
    disposition_Date : Date;
    primary_org_level_id:number;
    matches_Export_Date:Date;
}


export class PBJDuplicateWorkers {
    public location: number;
    public pbJ_ID_1: number;
    public emp_id_1 :number;
    public first_Name_1: string;
    public last_name_1: string;
    public position_1: string;
    public email_1: string;
    public phone_1: string;
    public birthday_1: Date;
    public ssN_1: string;
    public primary_Location_1 : string;
    public emp_dt_hire_1 : Date;
    public pbJ_ID_2: number;
    public emp_id_2 :number;
    public first_Name_2: string;
    public last_name_2: string;
    public position_2: string;
    public email_2: string;
    public phone_2: string;
    public birthday_2: Date;
    public ssN_2: string;
    public primary_Location_2 : string;
    public emp_dt_hire_2 : Date;
    public name_Matching:number;
    public ssN_Matching : number;
    public email_Matching : number;
    public birthday_matching : number;
    public phone_Matching : number;
    public matching_score: number;
    public disposition : string;
    public disposition_Date :Date;
    public primary_org_level_id:number;
    public matches_Export_Date:Date;
}