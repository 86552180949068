import { Injectable, Inject } from '@angular/core';

import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { NOTIFICATION_HUB_CONFIG, SignalrWindow, SignalrHubConfig, SignalrHubEvent, SignalrHubGroupObservable, SignalrConnectionState, SignalrConnectionStateDefinition } from '../../models/index';
import { SignalrHubService } from './signalr-hub.service';
import { SessionService } from '../../../core/services/session/session.service';
import { appConfig } from '../../../app.config';

@Injectable()
export class NotificationHubService {

  private signalrHubService: SignalrHubService;
  private sessionService: SessionService;

  constructor(
    @Inject(SignalrWindow) window: SignalrWindow,
    @Inject(NOTIFICATION_HUB_CONFIG) signalrHubConfig: SignalrHubConfig,
    sessionService: SessionService) {
    this.sessionService = sessionService;
    this.signalrHubService = new SignalrHubService(window, signalrHubConfig, sessionService);
  }

  public subscribe<T>(group: string, event: string): SignalrHubGroupObservable<T> {
    return this.signalrHubService.subscribe(group, event);
  }

  public registerEvent(event: string): void {
    this.signalrHubService.registerEvent(event);
  }

  public leaveGroup(event: string, group: string): void {
    this.signalrHubService.leaveGroup(event, group);
  }

  public unsubscribeAll(): void {
    this.signalrHubService.unsubscribeAll();
  }

  public publish<T>(ev: T): void {
    this.signalrHubService.publish(ev);
  }

  public start(): void {
    this.signalrHubService.start();
  }

  public getSubscribePrefix(): string {
    return this.sessionService.getAlias();
  }

}
