import { IPosition } from '../../../organization/models/index';
import { IdealScheduleConfigCensus } from './ideal-schedule-config-census';
import { IdealScheduleConfigType } from './ideal-schedule-config-type';
import { IdealSchedulePositionPeriod } from './ideal-schedule-position-period';

export class IdealSchedulePositionDefinition {
  public position: IPosition;
  public targetHours: number;
  public idealScheduleType: IdealScheduleConfigType;
  public idealScheduleCensus: IdealScheduleConfigCensus;
  public totalHours: number;
  public isActive: boolean;
  public isDeleted: boolean;
  public startDate: Date;
  public endDate: Date;

  public periods: IdealSchedulePositionPeriod[];

  // technical properties
  public isEditableMode: boolean = false;
  public get hasChanges(): boolean {
    return false;
  }
  public getTargetHours(): number {
    return this.targetHours;
  }
  public getTotalHours(): number {
    return this.totalHours;
  }
  
  public isChecked: boolean = false;
  public isInvalidConfigType: boolean = false;
  public isInvalidCensusType: boolean = true;
  public idealScheduleId: number = 0;
}
