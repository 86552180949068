import {
  TimeclockRestrictionDefinition
} from '../../../organization/models/index';

import { TimeclockAssignmentEmployee, ITimeclockAssignmentEmployee } from './timeclock-assignment-employee';

export interface ITimeclockAssignmentContainer {
  employees: ITimeclockAssignmentEmployee[];
}

export class TimeclockAssignmentContainer {
  public employees: TimeclockAssignmentEmployee[];
}
