<div class="slx-daterange" *ngIf="isMinMaxValue">
  <label class="slx-daterange__date">
    <slx-datepicker
      class="slx-wide"
      [(ngModel)]="startDate"
      name="dateTime-min-{{ uniqueComponentId }}"
      (ngModelChange)="onValueChanged()"
      [required]="true"
    ></slx-datepicker>
  </label>
  <label class="slx-daterange__date">
    <slx-datepicker
      class="slx-wide"
      [(ngModel)]="endDate"
      name="dateTime-max-{{ uniqueComponentId }}"
      (ngModelChange)="onValueChanged()"
      [required]="true"
      [minDate]="minLimitForMaxDate"
    ></slx-datepicker>
  </label>
</div>

<ng-container *ngIf="!isMinMaxValue">
  <slx-datepicker
    class="slx-wide slx-short"
    [(ngModel)]="innerValue"
    name="dateTime-{{ uniqueComponentId }}"
    (ngModelChange)="onValueChanged()"
    [required]="true"
  ></slx-datepicker>
</ng-container>
