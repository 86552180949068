import { Map } from 'immutable';
import { makeTypedMapFactory } from '../../../framework/immutable-utils/index';

export const individualScheduleStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  stateByOrglevel: {},
});

export const stateByOrlevelStateFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  employeeId: 0,
  cycle: null
});

export const INITIAL_INDIVIDUAL_SCHEDULE_STATE: Map<string, any> = individualScheduleStateFactory();
export const INITIAL_BY_ORGLEVEL_INDIVIDUAL_SCHEDULE_STATE: Map<string, any> = stateByOrlevelStateFactory();
