/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./slate-profile-assignment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "../../../../common/components/input-decorator/input-decorator.component.ngfactory";
import * as i5 from "../../../../common/components/input-decorator/input-decorator.component";
import * as i6 from "../../../../common/components/dropdown-input/dropdown-input.component.ngfactory";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../common/components/dropdown-input/dropdown-input.component";
import * as i9 from "../../../../organization/directives/lookup/lookup.directive";
import * as i10 from "../../../../organization/services/lookup/lookup.service";
import * as i11 from "../../../../common/error-filters/errorMessage";
import * as i12 from "../employee-grid/employee-grid.component.ngfactory";
import * as i13 from "../employee-grid/employee-grid.component";
import * as i14 from "../../services/employee-list/employee-list-common.service";
import * as i15 from "../../../../core/services/local-storage/local-storage.service";
import * as i16 from "@angular/router";
import * as i17 from "../../../../common/services/column-settings/column-management.service";
import * as i18 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i19 from "./slate-profile-assignment.component";
import * as i20 from "../../../../core/models/tokens";
import * as i21 from "../../models/employeeActionDefinition";
import * as i22 from "../../../../state-model/models/org-level/org-level";
import * as i23 from "../../../../common/services/modal/modal.service";
import * as i24 from "../../../../common/models/dialog-options";
import * as i25 from "../../services/slate-profile/slate-profile.service";
import * as i26 from "../../services/employee-list/employee-list.service";
var styles_SlateProfileAssignmentComponent = [i0.styles];
var RenderType_SlateProfileAssignmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SlateProfileAssignmentComponent, data: {} });
export { RenderType_SlateProfileAssignmentComponent as RenderType_SlateProfileAssignmentComponent };
export function View_SlateProfileAssignmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 33, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 25, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 24, "slx-spinner", [["novalidate", ""]], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(3, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 22, "div", [["class", "content-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 18, "div", [["class", "profile-selector-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 17, "slx-input-decorator", [], null, null, null, i4.View_InputDecoratorComponent_0, i4.RenderType_InputDecoratorComponent)), i1.ɵdid(7, 311296, null, 4, i5.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], null, null), i1.ɵqud(603979776, 1, { fieldChild1: 0 }), i1.ɵqud(603979776, 2, { fieldChild2: 0 }), i1.ɵqud(603979776, 3, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 4, { errorMessages: 1 }), (_l()(), i1.ɵeld(12, 0, null, 0, 9, "slx-dropdown-input", [["lookup", "timeclockSlateProfileDefinition"], ["name", "slateProfile"], ["placeholder", "SLATE Profile"], ["required", "true"], ["slx-input", ""]], [[1, "required", 0], [8, "className", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedProfile = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DropdownInputComponent_0, i6.RenderType_DropdownInputComponent)), i1.ɵdid(13, 16384, null, 0, i7.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i7.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i7.RequiredValidator]), i1.ɵdid(15, 49152, null, 0, i8.DropdownInputComponent, [i1.ChangeDetectorRef], { placeholder: [0, "placeholder"] }, null), i1.ɵprd(1024, null, i7.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.DropdownInputComponent]), i1.ɵdid(17, 671744, null, 0, i7.NgModel, [[8, null], [6, i7.NG_VALIDATORS], [8, null], [6, i7.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i7.NgControl, null, [i7.NgModel]), i1.ɵdid(19, 671744, null, 0, i9.LookupDirective, [i10.LookupService, i7.NgControl], { lookup: [0, "lookup"] }, null), i1.ɵdid(20, 540672, [[2, 4]], 0, i5.SlxInputAltDirective, [[2, i7.NgControl], i1.ElementRef], { required: [0, "required"], placeholder: [1, "placeholder"] }, null), i1.ɵdid(21, 16384, null, 0, i7.NgControlStatus, [[4, i7.NgControl]], null, null), (_l()(), i1.ɵeld(22, 0, null, 3, 1, "span", [["errorMessage", ""], ["for", "required"]], null, null, null, null, null)), i1.ɵdid(23, 81920, [[4, 4]], 0, i11.ErrorMessageDirective, [i1.ElementRef], { for: [0, "for"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 2, "div", [["class", "employee-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "slx-employee-grid", [], null, [[null, "onEmployeesSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onEmployeesSelect" === en)) {
        var pd_0 = (_co.employeesSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_EmployeeGridComponent_0, i12.RenderType_EmployeeGridComponent)), i1.ɵdid(26, 245760, null, 0, i13.EmployeeGridComponent, [i1.ChangeDetectorRef, i14.EmployeeListCommonService, i15.LocalStorageService, i16.Router], { isMultiselect: [0, "isMultiselect"], isGroupable: [1, "isGroupable"], fieldDefinition: [2, "fieldDefinition"], employees: [3, "employees"], isOptInStatus: [4, "isOptInStatus"] }, { onEmployeesSelect: "onEmployeesSelect" }), (_l()(), i1.ɵeld(27, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(31, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 3, 0, currVal_0); _ck(_v, 7, 0); var currVal_10 = "true"; _ck(_v, 13, 0, currVal_10); var currVal_11 = "SLATE Profile"; _ck(_v, 15, 0, currVal_11); var currVal_12 = "slateProfile"; var currVal_13 = _co.selectedProfile; _ck(_v, 17, 0, currVal_12, currVal_13); var currVal_14 = "timeclockSlateProfileDefinition"; _ck(_v, 19, 0, currVal_14); var currVal_15 = "true"; var currVal_16 = "SLATE Profile"; _ck(_v, 20, 0, currVal_15, currVal_16); var currVal_17 = "required"; _ck(_v, 23, 0, currVal_17); var currVal_18 = true; var currVal_19 = false; var currVal_20 = _co.fieldDefinition; var currVal_21 = _co.employees; var currVal_22 = false; _ck(_v, 26, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (i1.ɵnov(_v, 13).required ? "" : null); var currVal_2 = i1.ɵnov(_v, 20).className; var currVal_3 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 21).ngClassValid; var currVal_8 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_23 = (!_co.canAssign || !_co.selectedProfile); _ck(_v, 31, 0, currVal_23); }); }
export function View_SlateProfileAssignmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-slate-profile-assignment", [], null, null, null, View_SlateProfileAssignmentComponent_0, RenderType_SlateProfileAssignmentComponent)), i1.ɵprd(4608, null, i17.ColumnManagementService, i17.ColumnManagementService, [i18.ColumnSettingsStorageService]), i1.ɵdid(2, 114688, null, 0, i19.SlateProfileAssignmentComponent, [i20.ISACTIVEONLY_ID_TOKEN, i21.EmployeeActionDefinition, i22.OrgLevel, i23.ModalService, i24.DialogOptions2, i25.SlateProfileService, i26.EmployeeListService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SlateProfileAssignmentComponentNgFactory = i1.ɵccf("slx-slate-profile-assignment", i19.SlateProfileAssignmentComponent, View_SlateProfileAssignmentComponent_Host_0, {}, {}, []);
export { SlateProfileAssignmentComponentNgFactory as SlateProfileAssignmentComponentNgFactory };
