import { Provider } from '@angular/core';

export * from './recaptcha-api.service';
export * from './recaptcha-map.service';
export * from './version-api.service';
export * from './version-map.service';
export * from './version.subscribe.service';

import { RecaptchaApiService } from './recaptcha-api.service';
import { RecaptchaMapService } from './recaptcha-map.service';
import { VersionApiService } from './version-api.service';
import { VersionMapService } from './version-map.service';
import { VersionSubscribeService } from './version.subscribe.service';

export const versionServices: Provider[] = [
  RecaptchaMapService,
  RecaptchaApiService,
  VersionMapService,
  VersionApiService,
  VersionSubscribeService
];
