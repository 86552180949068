import { ReportGroup } from '../../models/index';
var ReportGroupComponent = /** @class */ (function () {
    function ReportGroupComponent() {
    }
    Object.defineProperty(ReportGroupComponent.prototype, "reportGroup", {
        get: function () {
            return this.m_reportGroup;
        },
        set: function (value) {
            this.m_reportGroup = value;
        },
        enumerable: true,
        configurable: true
    });
    return ReportGroupComponent;
}());
export { ReportGroupComponent };
