import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../../../core/models/index';
import { employeeSectionsConfig } from '../../employee-sections.config';
import { ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeSectionsCustomMapService } from './employee-section-custom-map.service';
import {
  IEmployeeSectionsCustomFields,
  EmployeeSectionsCustomFields,
  IEmployeeSectionsCustom,
  EmployeeSectionsCustom
} from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class EmployeeSectionsCustomApiService {
  constructor(
    private employeeSectionsCustomMapService: EmployeeSectionsCustomMapService,
    private apiUtilService: ApiUtilService) {
  }

  public getCustomSection(employeeId: number): Promise<EmployeeSectionsCustom> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getCustomApiRoot(employeeId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsCustom> = this.apiUtilService.request<IEmployeeSectionsCustom, Meta>(request)
      .then((response: ResponseBody<IEmployeeSectionsCustom, Meta>) =>
        this.employeeSectionsCustomMapService.mapToSectionsCustom(response.data)
      );
    return promise;
  }

  public getCustomFields(employeeId: number): Promise<EmployeeSectionsCustomFields> {
    Assert.isNotNull(employeeId, 'employeeId');

    const url: string = `${this.getCustomApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.custom.customfields}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<EmployeeSectionsCustomFields> = this.apiUtilService.request<IEmployeeSectionsCustomFields, FieldsMeta>(request)
      .then((response: ResponseBody<IEmployeeSectionsCustomFields, FieldsMeta>) =>
        this.employeeSectionsCustomMapService.mapToCustomFields(response.data, response.meta)
      );
    return promise;
  }

  public setCustomFields(employeeId: number, fields: EmployeeSectionsCustomFields, effectiveDate?: Date): Promise<any> {
    Assert.isNotNull(employeeId, 'employeeId');
    Assert.isNotNull(fields, 'fields');
    const url: string = `${this.getCustomApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.custom.customfields}`;
    let dfields: IEmployeeSectionsCustomFields = this.employeeSectionsCustomMapService.mapToCustomFieldsDto(fields);
    let body: any = {
      customFields: dfields
    };
    
    let request: HttpRequest<any> = new HttpRequest('PUT', url, body);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => response.status);
    return promise;
  }


  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}/${employeeSectionsConfig.api.employees.root}`;
  }

  private getSectionsApiRoot(employeeId: number): string {
    return `${this.getApiRoot()}/${employeeId}/${employeeSectionsConfig.api.employees.sections.root}`;
  }

  private getCustomApiRoot(employeeId: number): string {
    return `${this.getSectionsApiRoot(employeeId)}/${employeeSectionsConfig.api.employees.sections.custom.root}`;
  }

}
