import { IPmAttachment, PmAttachment } from './pm-attachment';

export interface IPmTemplate {
    templateDefinition: IPmTemplateDefinition;
    attachment: IPmAttachment;
}

export interface IPmTemplateDefinition {
    templateId: number;
    orgLevel: number;
    attachmentId: number;
    templateDescription: string;
    addedBy: string;
    addedOn: string;
    modifiedBy: string;
    modifiedOn: string;

    orgLevelObject: {
        id: number;
        name: string;
    };
    attachment: IPmAttachment;
}

export class PmTemplate {
    attachment: PmAttachment;
    template: PmTemplateDefinition;
}

export class PmTemplateDefinition {

    public get modifiedUserName(): string {
        return this.modifiedBy || this.addedBy;
    }

    public get fileName(): string {
        if (this.attachment) {
            return this.attachment.fileName || '';
        }
        return '';
    }

    public get modifiedDate(): Date {
        return this.modifiedOn || this.addedOn;
    }

    public templateId: number;
    public orgLevel: number;
    public attachmentId: number;
    public templateDescription: string;
    public addedBy: string;
    public addedOn: Date;
    public modifiedBy: string;
    public modifiedOn: Date;

    public orgLevelName: string;
    public attachment: PmAttachment;
}