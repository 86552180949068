import * as tslib_1 from "tslib";
import { EssLookupManageService } from './../../../../../../organization/services/lookup/ess-lookup-manage.service';
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions } from '../../../../store/index';
import { AddEmployeeRestrictions } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { ModalAnchorDirective } from '../../../../../../common/directives/index';
import { EmployeeActivitiesApiService } from '../../../../services/employee-activities/employee-add-api.service';
import { EmployeeValidatorAdapter, EmployeeDefinitionsApiService } from '../../../../../../organization/services/index';
import { EssDefaultPassword } from '../../../../../../organization/models/lookup/index';
import { ModalService, SelectableItemsProducer } from '../../../../../../common/services/index';
import { ConfirmDialogComponent } from '../../../../../../common/components/index';
import { AppSettingsManageService } from '../../../../../../app-settings/services/index';
import { EssTemplateApiService } from '../../../../../../app-modules/ess-templates/services/index';
var AddEmployeeAdditionalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddEmployeeAdditionalComponent, _super);
    function AddEmployeeAdditionalComponent(addEmployeeWizardActions, lookupApiService, employeeActivitiesApiService, employeeActivitiesValidatorAdapter, employeeDefinitionApi, selectableItemsProducer, modalService, essLookupService, appSettingsManageService, essTemplateApiService) {
        var _this = _super.call(this, addEmployeeWizardActions) || this;
        _this.lookupApiService = lookupApiService;
        _this.employeeActivitiesApiService = employeeActivitiesApiService;
        _this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
        _this.employeeDefinitionApi = employeeDefinitionApi;
        _this.selectableItemsProducer = selectableItemsProducer;
        _this.modalService = modalService;
        _this.essLookupService = essLookupService;
        _this.appSettingsManageService = appSettingsManageService;
        _this.essTemplateApiService = essTemplateApiService;
        _this.isPwdVisible = false;
        _this.state = {
            isTimeclockLoading: false
        };
        _this.essLookupService.onLoaded$.subscribe(function (value) {
            _this.timeMethods = _this.essLookupService.timeMethods.concat({ id: 'none', name: 'None' });
        });
        _this.appSettingsManageService.getAppServerConfig()
            .then(function (config) {
            _this.essTemplatesEnabled = config.essTemplatesEnabled;
            _this.loadEssTemplate();
        });
        return _this;
    }
    Object.defineProperty(AddEmployeeAdditionalComponent.prototype, "canSetEssEnabledInd", {
        get: function () {
            if (!this.addEmployeeModel.email1 && !this.addEmployeeModel.mobilePhone) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeAdditionalComponent.prototype, "essEnabledInd", {
        get: function () {
            if (!this.addEmployeeRestrictions) {
                return false;
            }
            return this.addEmployeeRestrictions.essEnabledInd.defaultFieldValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeAdditionalComponent.prototype, "essTemplateRequirementsInvalid", {
        get: function () {
            return this.essTemplateSsnError || this.essTemplateDOBError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeAdditionalComponent.prototype, "essTemplateSsnError", {
        get: function () {
            if ((this.addEmployeeModel.employeeType.name === 'AFT' || this.addEmployeeModel.employeeType.name === 'APT') || this.addEmployeeModel.organization.id == 1000) {
                return false;
            }
            if (!this.essTemplatesEnabled || !this.essTemplate) {
                return false;
            }
            return this.essTemplate.defaultPwdTemplate === EssDefaultPassword.last4SSN && !this.addEmployeeModel.ssn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeAdditionalComponent.prototype, "essTemplateDOBError", {
        get: function () {
            if (!this.essTemplatesEnabled || !this.essTemplate) {
                return false;
            }
            return (this.essTemplate.defaultPwdTemplate === EssDefaultPassword.dobMMDD
                || this.essTemplate.defaultPwdTemplate === EssDefaultPassword.dobMMYY)
                && !this.addEmployeeModel.birthDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeAdditionalComponent.prototype, "timeclocksEnabledInd", {
        get: function () {
            if (!this.addEmployeeRestrictions) {
                return false;
            }
            return this.addEmployeeRestrictions.timeclocksEnabledInd.defaultFieldValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeAdditionalComponent.prototype, "badgeIdValidation", {
        get: function () {
            return EmployeeValidatorAdapter.badgeIdValidation;
        },
        enumerable: true,
        configurable: true
    });
    AddEmployeeAdditionalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe(function (model) {
            _this.onWizard(model);
            if (!_.get(_this.addEmployeeModel, 'email1') && !_.get(_this.addEmployeeModel, 'mobilePhone')) {
                _this.addEmployeeModel.enableTimesheetsAccess = false;
            }
            if (_this.hasAssignedOrgLevel()) {
                _this.loadBadgeOptionsLookup();
            }
        });
    };
    AddEmployeeAdditionalComponent.prototype.togglePasswordVisibility = function () {
        this.isPwdVisible = !this.isPwdVisible;
    };
    AddEmployeeAdditionalComponent.prototype.generateBadgeId = function () {
        var _this = this;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to generate a badge ID?', this.modalService, function (result) {
            if (result) {
                //generateBageId
                _this.addEmployeeModel.badgeId = '';
                _this.employeeDefinitionApi.generateBadgeId(_this.addEmployeeModel.organization.id)
                    .then(function (badgeId) {
                    _this.addEmployeeModel.badgeId = badgeId;
                });
            }
        });
    };
    AddEmployeeAdditionalComponent.prototype.generateEssPassword = function () {
        var _this = this;
        ConfirmDialogComponent.openDialog('Confirmation', 'Generate ESS Password?', this.modalService, function (result) {
            if (result) {
                //generatePassword
                _this.employeeDefinitionApi.generatePassword()
                    .then(function (pwd) {
                    _this.addEmployeeModel.password = pwd.toString();
                });
            }
        });
    };
    AddEmployeeAdditionalComponent.prototype.loadBadgeOptionsLookup = function () {
        var _this = this;
        if (!this.addEmployeeModel)
            return;
        if (this.addEmployeeModel.timeclockDefinitions && this.addEmployeeModel.timeclockDefinitions.length > 0)
            return;
        this.state.isTimeclockLoading = true;
        this.lookupApiService.getTimeclockDefinitions(this.addEmployeeModel.department.orgLevelId)
            .then(function (timeclockLookup) {
            _this.addEmployeeModel.timeclockDefinitions = _this.selectableItemsProducer.produceSelectable(timeclockLookup);
            _this.state.isTimeclockLoading = false;
        });
    };
    AddEmployeeAdditionalComponent.prototype.loadEssTemplate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.essTemplateApiService.getTemplateForPosition(this.addEmployeeModel.position.id)];
                    case 1:
                        _a.essTemplate = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['addEmployeeWizard', 'model']),
        tslib_1.__metadata("design:type", Observable)
    ], AddEmployeeAdditionalComponent.prototype, "addEmployeeWizard$", void 0);
    return AddEmployeeAdditionalComponent;
}(AddEmployeeBasicComponent));
export { AddEmployeeAdditionalComponent };
