import * as tslib_1 from "tslib";
import { unsubscribe } from './../../../../core/decorators';
import { Subscription } from 'rxjs/Subscription';
import { OnInit, ElementRef } from '@angular/core';
import { FavoriteManagementService } from '../../../services';
var FavoritesListConfigurationComponent = /** @class */ (function () {
    function FavoritesListConfigurationComponent(favService, elementRef) {
        this.favService = favService;
        this.elementRef = elementRef;
        this.favorites = [];
        this.disIndexes = [];
        this.canDrag = false;
    }
    FavoritesListConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.favSubscription = this.favService.configurationItemsUpdated$
            .subscribe(function (items) {
            _this.favorites = items;
            _this.disIndexes = [];
            _this.favorites.forEach(function (f, index, arr) { return _this.disIndexes.push(index); });
        });
    };
    FavoritesListConfigurationComponent.prototype.onStarClicked = function (favorite) {
        this.favService.changeFavoriteState(favorite);
    };
    FavoritesListConfigurationComponent.prototype.scrollToPosition = function () {
        this.canDrag = false;
        if (!this.elementRef.nativeElement) {
            return false;
        }
        var favListElements = this.elementRef.nativeElement.querySelectorAll('#favitem');
        if (favListElements && favListElements[favListElements.length - 1]) {
            favListElements[0].scrollIntoView(true);
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], FavoritesListConfigurationComponent.prototype, "favSubscription", void 0);
    return FavoritesListConfigurationComponent;
}());
export { FavoritesListConfigurationComponent };
