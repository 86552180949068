import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UserAction } from '../../../../organization/models/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { StateManagementService, ColumnManagementService } from '../../../../common/services/index';
import { TempSensorReadingManagementService } from '../../../../configuration/services/index';


@Component({
  moduleId: module.id,
  selector: 'slx-temp-readings',
  templateUrl: 'temp-readings.component.html',
  styleUrls: ['temp-readings.component.scss'],
  providers: [ColumnManagementService, StateManagementService, TempSensorReadingManagementService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TempReadingsComponent implements OnInit, OnDestroy {

  public canExportData = false;

  public isLoading: boolean = false;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};


  constructor(private managementService: TempSensorReadingManagementService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  public ngOnInit() {
    this.managementService.init();

    this.subscriptions.loading = this.managementService.subscribeToLoading((isLoading: boolean) => {
      this.isLoading = isLoading;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    });

  }

  public ngOnDestroy(): void {
    this.managementService.destroy();
  }
}
