import * as tslib_1 from "tslib";
import { PayrollExportTestManagementService } from './../../../services/payroll-export-test/payroll-export-test-management.service';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { Router, ActivatedRoute } from '@angular/router';
import { PayrollExportNavigationService } from '../../../../../common/services/navigation/payroll-export-navigation.service';
var PayrollExportTestComponent = /** @class */ (function () {
    function PayrollExportTestComponent(manageService, router, activatedRoute) {
        this.manageService = manageService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.isLoading = false;
    }
    PayrollExportTestComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadStatusSubscription = this.manageService.loadingChanged.subscribe(function (value) {
            setTimeout(function () {
                _this.isLoading = value;
            });
        });
        this.urlChangeSubscription = this.manageService.urlParamsChanged.subscribe(function (p) {
            var service = new PayrollExportNavigationService(_this.router, _this.activatedRoute);
            service.navigateToPayrollExport(p.startDate, p.endDate);
        });
    };
    PayrollExportTestComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportTestComponent.prototype, "urlChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportTestComponent.prototype, "loadStatusSubscription", void 0);
    return PayrollExportTestComponent;
}());
export { PayrollExportTestComponent };
