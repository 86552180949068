import * as tslib_1 from "tslib";
var ScheduleCycleMessages = /** @class */ (function () {
    function ScheduleCycleMessages() {
    }
    return ScheduleCycleMessages;
}());
export { ScheduleCycleMessages };
var GenerateScheduleSummary = /** @class */ (function (_super) {
    tslib_1.__extends(GenerateScheduleSummary, _super);
    function GenerateScheduleSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return GenerateScheduleSummary;
}(ScheduleCycleMessages));
export { GenerateScheduleSummary };
