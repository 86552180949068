import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, timer } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserLoginManagementApiService } from '../../services';
import { process } from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { KendoGridStateHelper } from '../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { KendoGridCustomSelectionHelper } from '../../../common/models/kendo-grid-helpers/kendo-grid-custom-selection-helper';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { appConfig } from '../../../app.config';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../common/components/confirm-dialog/confirm-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';
import * as moment from 'moment';
var UserLoginManagement = /** @class */ (function () {
    function UserLoginManagement(api, modalService, notificationsService) {
        this.api = api;
        this.modalService = modalService;
        this.notificationsService = notificationsService;
        this.unsub = new Subject();
        this.refreshRate = 30000;
        this.pageSize = 50;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            isActiveOnly: true,
            hasSelected: false
        };
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'userName', dir: 'asc' }
        ];
    }
    UserLoginManagement.prototype.ngOnInit = function () {
        var _this = this;
        this.gridStateSubscription = this.gridState.onRefreshGrid.subscribe(function (state) {
            _this.refreshGrid();
        });
        this.selectionHelperSubscription = this.selectionHelper.onSelectionChanged.subscribe(function () {
            if (_this.selectionHelper.selectionLength > 0) {
                _this.state.hasSelected = true;
            }
            else {
                _this.state.hasSelected = false;
            }
        });
        this.loadSessions();
    };
    UserLoginManagement.prototype.logOffSelected = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to log-off selected users?', this.modalService, function (result) {
            if (result) {
                var users = _.map(_this.selectionHelper.selection, function (s) { return s.userId; });
                if (users.length > 0) {
                    _this.api.logOffSelected(users)
                        .then(function () {
                        _this.notificationsService.success('Users logged out');
                        _this.stopTimer();
                        _this.loadSessions();
                    });
                }
            }
        }, options);
    };
    UserLoginManagement.prototype.ngOnDestroy = function () {
        this.stopTimer();
        this.destroyed = true;
    };
    UserLoginManagement.prototype.onDisplaySettingsChanged = function () {
        this.stopTimer();
        this.loadSessions();
    };
    UserLoginManagement.prototype.isVisible = function (column) {
        if (column == 'status')
            return !this.state.isActiveOnly;
        return true;
    };
    UserLoginManagement.prototype.allData = function () {
        var _this = this;
        return function () {
            var cleanState = {
                filter: _this.gridState.state.filter,
                group: _this.gridState.state.group,
                sort: _this.gridState.state.sort,
                skip: 0,
                take: _this.sessions.length
            };
            var result = {
                data: process(_this.sessions, cleanState).data
            };
            return result;
        };
    };
    UserLoginManagement.prototype.exportToExcel = function () {
        this.kendoGrid.saveAsExcel();
    };
    UserLoginManagement.prototype.exportToPdf = function () {
        this.kendoGrid.saveAsPDF();
    };
    UserLoginManagement.prototype.onExcelExport = function (e) {
        var rows = e.workbook.sheets[0].rows;
        rows.forEach(function (row) {
            if (row.type === 'data') {
                var lastLogin_1 = row.cells[6].value;
                var createdon = new Date(row.cells[4].value);
                row.cells[4].value = moment(createdon).format('MM/DD/YYYY hh:mm A');
                var lastlogin = new Date(row.cells[6].value);
                row.cells[6].value = moment(lastlogin).format('MM/DD/YYYY hh:mm A');
                row.cells[7].value = dateTimeUtils.formatDuration(moment.duration(row.cells[7].value));
                row.cells.forEach(function (cell, index) {
                    if (_.isNil(lastLogin_1)) {
                        if (index >= 5) {
                            cell.value = 'N/A';
                        }
                        if (index == 2) {
                            cell.value = 'N';
                        }
                    }
                    else {
                        if (index == 2) {
                            cell.value = cell.value ? 'Y' : 'N';
                        }
                    }
                });
            }
        });
    };
    UserLoginManagement.prototype.startTimer = function () {
        var _this = this;
        this.stopTimer();
        this.unsub = new Subject();
        this.subscriptions = new Subscription();
        this.subscriptions.add(timer(0, this.refreshRate)
            .pipe(tap(function (x) { return console.log(x); }), takeUntil(this.unsub), switchMap(function () { return _this.loadSessions(); })).subscribe());
        this.autoRefreshRunning = true;
    };
    UserLoginManagement.prototype.loadSessions = function () {
        var _this = this;
        this.state.isLoading = true;
        return this.api.getUserSessions(this.state.isActiveOnly).then(function (managementInfo) {
            var selectionIds = [];
            var restoreSelection = false;
            if (_this.sessions && _this.sessions.length === managementInfo.sessions.length) {
                _.each(_this.sessions, function (s) {
                    _.each(managementInfo.sessions, function (ns) {
                        if (s.userId === ns.userId) {
                            ns.isSelected = s.isSelected;
                        }
                    });
                });
                restoreSelection = true;
                selectionIds = _.map(_.filter(managementInfo.sessions, function (s) { return s.isSelected; }), function (s) { return s.userId; });
            }
            else {
                _this.gridState.state.skip = 0;
                _this.selectionHelper.clearSelection();
            }
            _this.sessions = managementInfo.sessions;
            _this.refreshGrid();
            if (restoreSelection) {
                var selection = _.filter(_this.sessions, function (s) { return selectionIds.indexOf(s.userId) != -1; });
                _.each(selection, function (s) { return s.isSelected = true; });
                _this.selectionHelper.restoreSelection(selection);
            }
            if (_this.refreshRate != managementInfo.refreshRate) {
                _this.stopTimer();
                _this.refreshRate = managementInfo.refreshRate;
            }
            if (!_this.autoRefreshRunning && !_this.destroyed) {
                _this.startTimer();
            }
        }).catch(function (e) {
            console.error(e);
        }).finally(function () {
            _this.state.isLoading = false;
        });
    };
    UserLoginManagement.prototype.stopTimer = function () {
        if (this.unsub) {
            this.unsub.next();
        }
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
        this.autoRefreshRunning = false;
    };
    UserLoginManagement.prototype.refreshGrid = function () {
        if (!this.sessions) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.sessions, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserLoginManagement.prototype, "gridStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserLoginManagement.prototype, "selectionHelperSubscription", void 0);
    return UserLoginManagement;
}());
export { UserLoginManagement };
