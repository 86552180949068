export * from './roles-management/roles-management.component';
export * from './roles-access-table-grid/roles-access-table-grid.component';
export * from './roles-right-grid/roles-right-grid.component';
export * from './roles-right-module-grid/roles-right-module-grid.component';
export * from './roles-profile-access-toggler/roles-profile-access-toggler.component';
export * from './roles-section-grid/roles-section-grid.component';
export * from './roles-subsection-grid/roles-subsection-grid.component';
export * from './roles-field-grid/roles-field-grid.component';
export * from './roles-menu-module-grid/roles-menu-module-grid.component';
export * from './roles-menu-grid/roles-menu-grid.component';
export * from './roles-submenu-grid/roles-submenu-grid.component';
export * from './roles-components-module-grid/roles-components-module-grid.component';
export * from './roles-component-grid/roles-component-grid.component';

export * from './roles-definition-grid/roles-definition-grid.component';
export * from './roles-legend-dialog/roles-legend-dialog.component';

import { RolesManagementComponent } from './roles-management/roles-management.component';
import { RolesAccessTableGridComponent } from './roles-access-table-grid/roles-access-table-grid.component';
import { RolesRightGridComponent } from './roles-right-grid/roles-right-grid.component';
import { RolesRightModuleGridComponent } from './roles-right-module-grid/roles-right-module-grid.component';
import { RolesProfileAccessTogglerComponent } from './roles-profile-access-toggler/roles-profile-access-toggler.component';
import { RolesMenuAccessTogglerComponent } from './roles-menu-access-toggler/roles-menu-access-toggler.component';
import { RolesSectionGridComponent } from './roles-section-grid/roles-section-grid.component';
import { RolesSubsectionGridComponent } from './roles-subsection-grid/roles-subsection-grid.component';
import { RolesFieldGridComponent } from './roles-field-grid/roles-field-grid.component';
import { RolesMenuModuleGridComponent } from './roles-menu-module-grid/roles-menu-module-grid.component';
import { RolesMenuGridComponent } from './roles-menu-grid/roles-menu-grid.component';
import { RolesSubmenuGridComponent } from './roles-submenu-grid/roles-submenu-grid.component';
import { RolesComponentsModuleGridComponent } from './roles-components-module-grid/roles-components-module-grid.component';
import { RolesComponentGridComponent } from './roles-component-grid/roles-component-grid.component';
import { RolesComponentAccessTogglerComponent } from './roles-component-access-toggler/roles-component-access-toggler.component';


import { RolesDefinitionGridComponent } from './roles-definition-grid/roles-definition-grid.component';
import { RolesLegendDialogComponent } from './roles-legend-dialog/roles-legend-dialog.component';

export const rolesComponents: any = [
  RolesManagementComponent,
  RolesAccessTableGridComponent,
  RolesRightGridComponent,
  RolesRightModuleGridComponent,
  RolesProfileAccessTogglerComponent,
  RolesMenuAccessTogglerComponent,
  RolesSectionGridComponent,
  RolesSubsectionGridComponent,
  RolesFieldGridComponent,
  RolesDefinitionGridComponent,
  RolesMenuModuleGridComponent,
  RolesMenuGridComponent,
  RolesComponentsModuleGridComponent,
  RolesComponentGridComponent,
  RolesComponentAccessTogglerComponent,
  RolesSubmenuGridComponent,
  RolesLegendDialogComponent
];
