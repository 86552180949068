import { ResponseBody } from './../../../core/models/api/response-body';
import { Meta } from './../../../core/models/api/meta';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from './../../../app.config';
import { UrlParamsService } from './../../../common/services/url-params/url-params.service';
import { ApiUtilService } from './../../../common/services/api/api-util.service';
import { Injectable } from '@angular/core';
import { EmployeePunchRequestMapService } from './employee-punch-request-map.service';
import { EmployeePunchRequest, IEmployeePunchRequest } from '../../models';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { dateTimeUtils } from './../../../../app/common/utils';
@Injectable()
export class EmployeePunchRequestApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: EmployeePunchRequestMapService
  ) {}
  
  public getEmployeePunchRequest(orgLevelId: number, startDate: Date, endDate: Date): Promise<EmployeePunchRequest> {
    const url: string = `${this.getEmployeePunchRequestApiRoot()}/${timeAndAttendanceConfig.api.employeePunchRequest.orgLevel}/${orgLevelId}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      });

    let promise: Promise<any> = this.apiUtilService.request<IEmployeePunchRequest, Meta>(req)
      .then((response: ResponseBody<any, Meta>) => {
        return this.mapService.mapEmployeePunchRequests(response.data);
      });
    return promise;
    
  }

  private getTaApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${timeAndAttendanceConfig.api.root}`;
  }

  private getEmployeePunchRequestApiRoot(): string {
    return `${this.getTaApiRoot()}/${timeAndAttendanceConfig.api.employeePunchRequest.root}`;
  }

}
