<form #form="ngForm" novalidate class="violation">
  
  <div class="flex justify-content__space-between violation__f-line">
      <p class="flex violation__control width-50">
        <label class="align-self__center violation__c-label">Violation Date</label>
        <span class="flex__grow">
          <slx-datepicker class="slx-wide"
            [(ngModel)]="violationDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeViolationDate()"
            name="violationDate"
          ></slx-datepicker>
        </span>
      </p>
  </div>

  <div class="flex justify-content__space-between violation__f-line">
    
    <p class="flex violation__control width-50">
      <label class="align-self__center violation__c-label">Violation Number</label>
      <span class="flex__grow">
        <input class="violation__field"
          [(ngModel)]="violationNumber"
          (ngModelChange)="onChangeViolationNumber()"
          maxlength="255"
          autocomplete="off"
          name="warningSubject"
          type="text"
        />
      </span>
    </p>
    <p class="flex violation__control width-50">
      <label class="align-self__center violation__c-label">Violation Type</label>
      <span class="flex__grow">
        <slx-dropdown-list class="slx-wide slx-short slx-middle-label"
          [options]="violationTypes"
          [(ngModel)]="selectedViolationType"
          (ngModelChange)="onChangeViolationType()"
          name="violationType"
        ></slx-dropdown-list>
      </span>
    </p>
  </div>
  <div class="flex justify-content__space-between flex-flow__wrap violation__l-line">
    <p class="violation__hd-line">
      <label class="violation__c-label">Absenteeism/Tardiness</label>
    </p>
    <p class="flex violation__control width-50">
      <label class="align-self__center violation__c-label">Absence</label>
      <span class="flex__grow">
        <input class="violation__field"
          [(ngModel)]="violationAbsence"
          (ngModelChange)="onChangeViolationAbsence()"
          maxlength="255"
          autocomplete="off"
          name="violationAbsence"
          type="text"
        />
      </span>
    </p>
    <p class="flex violation__control width-50">
      <label class="align-self__center violation__c-label">Tardiness</label>
      <span class="flex__grow">
        <input class="violation__field"
          [(ngModel)]="violationTardiness"
          (ngModelChange)="onChangeViolationTardiness()"
          maxlength="255"
          autocomplete="off"
          name="violationTardiness"
          type="text"
        />
      </span>
    </p>
  </div>
</form>
