import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { OrgLevel } from '../../../state-model/models/index';
import { VacationPlannerApiService } from '../../services/index';
import { scheduleMicrotask } from '../../../core/utils/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
var VacationPlannerComponent = /** @class */ (function () {
    function VacationPlannerComponent(vacationPlannerApiService) {
        this.vacationPlannerApiService = vacationPlannerApiService;
        this.state = {
            isLoading: false,
        };
    }
    VacationPlannerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            scheduleMicrotask(function () {
                _this.selectedOrgLevel = o;
                _this.loadVacationPlan();
            });
        });
        this.dailyDetailsSaveStartedSubscription = this.vacationPlannerApiService.onSaveDailyDetailsStarted$.subscribe(function () {
            _this.state.isLoading = true;
        });
        this.dailyDetailsSaveFinishedSubscription = this.vacationPlannerApiService.onSaveDailyDetailsFinished$.subscribe(function () {
            _this.state.isLoading = false;
        });
    };
    VacationPlannerComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    VacationPlannerComponent.prototype.onSettingsChanged = function (settings) {
        var _this = this;
        scheduleMicrotask(function () {
            _this.settings = settings;
            if (_this.settings.reload) {
                _this.settings.reload = false;
                _this.loadVacationPlan();
            }
            if (!_this.settings.reload) {
                _this.redrawToggler = !_this.redrawToggler;
            }
        });
    };
    VacationPlannerComponent.prototype.loadVacationPlan = function () {
        var _this = this;
        if (!this.state.isLoading) {
            if (!this.selectedOrgLevel || !this.settings) {
                return;
            }
            this.state.isLoading = true;
            this.vacationPlannerApiService.getVacationPlan(this.selectedOrgLevel.id, this.settings.year)
                .then(function (vacationPlan) {
                _this.vacationPlannerData = vacationPlan;
                _this.state.isLoading = false;
            });
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], VacationPlannerComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], VacationPlannerComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], VacationPlannerComponent.prototype, "dailyDetailsSaveStartedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], VacationPlannerComponent.prototype, "dailyDetailsSaveFinishedSubscription", void 0);
    return VacationPlannerComponent;
}());
export { VacationPlannerComponent };
