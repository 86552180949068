import {​​ Injectable }​​ from '@angular/core';



@Injectable(



)
export class SortService {

    constructor() {
    }


    messageSort(array, type) {
        if (type === 'Newest To Oldest') {
            array = array.sort(function (a, b) {
                return b.modified.localeCompare(a.modified);
            });
            return array;
        } else if (type === 'Oldest To Newest') {
            array = array.sort(function (a, b) {
                return a.modified.localeCompare(b.modified);
            });
            return array;
        }
        else if (type === 'Name A to Z') {
            array = array.sort(function (a, b) {
                if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) { return -1; }
                if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) { return 1; }
                return 0;
            })
            return array;
        }
        else if (type === 'Name Z to A') {
            array = array.sort(function (a, b) {
                if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) { return -1; }
                if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) { return 1; }
                return 0;
            })
            return array
        }
    }
}