import { CensusCategoryConfigRecord } from './census-category';

export interface ICensusEntry {
  category: string;
  value: number;
}

export class CensusEntry {
  public category: string;
  public value: number;
  public isSettings: boolean = false;
  public censusConfig: CensusCategoryConfigRecord;
}
