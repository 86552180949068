import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { FieldsMeta } from '../../../core/models/index';

import { 
    IOpenShiftPositionDetails,
    IOpenShiftPositions, 
    IOpenShiftPostionsDetails, 
    IOpenShiftPostionsOrgDetails, 
    OpenShiftPositionDetails, 
    OpenShiftPositions, 
    OpenShiftPostionsDetails, 
    OpenShiftPostionsOrgDetails, 
    openPositionControlConfig}
from '../../models/index';import { IOpenShiftPositionGroupDetails, OpenShiftPositionGroupDetails } from '../../models/open-position-control/open-shift-position-group-details';
;

@Injectable()
export class OpenPositionControlMapService {


  public mapOpenShiftPositions(dto: IOpenShiftPositions, meta: FieldsMeta): OpenShiftPositions {
    let data: OpenShiftPositions = new OpenShiftPositions();
    data.totalHoursGap = dto.totalHoursGap ? dto.totalHoursGap : 0;
    data.totalOpenShifts = dto.totalOpenShifts ? dto.totalOpenShifts: 0;
    data.totalFTENeeded = dto.totalFTENeeded ? dto.totalFTENeeded : 0;
    data.openPositionDetails = this.mapOpenShiftPostionsDetails(dto.openPositionDetails);
    return data;
  }

  public mapOpenShiftGroupDetails(dto: IOpenShiftPositionGroupDetails[], meta: FieldsMeta): OpenShiftPositionGroupDetails[] {
    let details= _.map(dto, (entry: OpenShiftPositionGroupDetails, i: number) => {
      let data: OpenShiftPositionGroupDetails = new OpenShiftPositionGroupDetails();
      data.positionGroup = entry.positionGroup;
      data.totalShifts=entry.totalShifts;
      data.totalScheduled=entry.totalScheduled;
      data.totalHours=entry.totalHours;
      data.totalFTHead=entry.totalFTHead;
      data.totalPTHead=entry.totalPTHead;
      data.positionDetails = this.mapOpenShiftPositionDetails(entry.positionDetails);
      return data;
    });
    return details;
  }

  public mapOpenShiftPostionsDetails(dto: IOpenShiftPostionsDetails[]): OpenShiftPostionsDetails[] {
    let details = _.map(dto, (entry: OpenShiftPostionsDetails, i: number) => {
      const detail: OpenShiftPostionsDetails = this.mapOpenShiftPostionsDetail(entry, i);
      return detail;
    });

    return details;
  }

  public mapOpenShiftPositionDetails(dto: IOpenShiftPositionDetails[]): OpenShiftPositionDetails[] {
    let details = _.map(dto, (entry: OpenShiftPositionDetails, i: number) => {
      let detail: OpenShiftPositionDetails = new OpenShiftPositionDetails();
      detail.shifts = entry.shifts;
      detail.ftEsNeeded = entry.ftEsNeeded;
      detail.ftHeadCount = entry.ftHeadCount;
      detail.hoursGap = entry.hoursGap;
      detail.position = entry.position;
      detail.ptHeadCount = entry.ptHeadCount;
      detail.scheduled = entry.scheduled;
      detail.shiftGroup=entry.shiftGroup;
      return detail;
    });
    return details;
  }

  public mapOpenShiftPostionsDetail(dto: IOpenShiftPostionsDetails, i: number): OpenShiftPostionsDetails {
    let detail: OpenShiftPostionsDetails = new OpenShiftPostionsDetails();
    detail.orgName = dto.orgName;
    detail.totalHoursGap = dto.totalHoursGap ? dto.totalHoursGap:0;
    detail.totalOpenShifts = dto.totalOpenShifts ? dto.totalOpenShifts:0;
    detail.totalFTENeeded = dto.totalFTENeeded ? dto.totalFTENeeded:0;
    detail.orgDepartmentDetails = this.mapOpenShiftPostionsOrgDetails(dto.orgDepartmentDetails);
    detail.isExpand = true;
    return detail;
  }

  public mapOpenShiftPostionsOrgDetails(dto: IOpenShiftPostionsOrgDetails[]): OpenShiftPostionsOrgDetails[] {
    let details = _.map(dto, (entry: OpenShiftPostionsOrgDetails, i: number) => {
      var detail: OpenShiftPostionsOrgDetails = this.mapOpenShiftPostionsOrgDetail(entry, i);
      return detail;
    });

    return details;
  }

  public mapOpenShiftPostionsOrgDetail(dto: IOpenShiftPostionsOrgDetails, i: number): OpenShiftPostionsOrgDetails {
    let detail: OpenShiftPostionsOrgDetails = new OpenShiftPostionsOrgDetails();
    detail.departmentName = dto.departmentName;
    detail.totalHoursGap = dto.totalHoursGap ? dto.totalHoursGap:0;
    detail.totalOpenShifts = dto.totalOpenShifts ? dto.totalOpenShifts:0;
    detail.orgLevelId = dto.orgLevelId;
    detail.totalFTENeeded = dto.totalFTENeeded ? dto.totalFTENeeded:0;
    detail.idealIndicator = dto.idealIndicator;
    return detail;
  }

  public mapOpenPositionControlData(dto: openPositionControlConfig, meta) {
    let data: openPositionControlConfig = new openPositionControlConfig();
    data.configurationId = dto.configurationId;
    data.orgLevelId = dto.orgLevelId;
    data.positionId = dto.positionId;
    data.ftMaxHoursPerCalenderDay = dto.ftMaxHoursPerCalenderDay;
    data.ftMaxHoursPerCalenderDayDefault = dto.ftMaxHoursPerCalenderDayDefault;
    data.ftMaxNoOfDaysPerCalenderWeek = dto.ftMaxNoOfDaysPerCalenderWeek;
    data.ftMaxWeeklyHours = dto.ftMaxWeeklyHours;
    data.ftMaxWeeklyHoursDefault = dto.ftMaxWeeklyHoursDefault;
    data.ftMinWeeklyHours = dto.ftMinWeeklyHours;
    data.ftMinWeeklyHoursDefault = dto.ftMinWeeklyHoursDefault;
    data.ptMaxHoursPerCalenderDay = dto.ptMaxHoursPerCalenderDay;
    data.ptMaxHoursPerCalenderDayDefault = dto.ptMaxHoursPerCalenderDayDefault;
    data.ptMaxNoOfDaysPerCalenderWeek = dto.ptMaxNoOfDaysPerCalenderWeek;
    data.ptMaxWeeklyHours = dto.ptMaxWeeklyHours;
    data.ptMaxWeeklyHoursDefault = dto.ptMaxWeeklyHoursDefault;
    data.ptMinWeeklyHours = dto.ptMinWeeklyHours;
    data.ptMinWeeklyHoursDefault = dto.ptMinWeeklyHoursDefault;
    data.ptMaxNoOfDaysPerCalenderWeekDefault = dto.ptMaxNoOfDaysPerCalenderWeekDefault;
    data.ftMaxNoOfDaysPerCalenderWeekDefault = dto.ftMaxNoOfDaysPerCalenderWeekDefault;
    return data;
  }
}
