import { IOpenShiftPostionsDetails, OpenShiftPostionsDetails } from "./open-shift-positions-details"

export interface IOpenShiftPositions {
    totalHoursGap: number
    totalOpenShifts: number
    totalFTENeeded: number
    openPositionDetails: IOpenShiftPostionsDetails[]
  }

  export class OpenShiftPositions {
    totalHoursGap: number
    totalOpenShifts: number
    totalFTENeeded: number
    openPositionDetails: OpenShiftPostionsDetails[]
  }
  

  
  