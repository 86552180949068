export * from './employee-sections-payroll/employee-sections-payroll.component';
export * from './employee-sections-positions/employee-sections-positions.component';
export * from './employee-sections-pay-cycles/employee-sections-pay-cycles.component';
export * from './employee-sections-position-history/employee-sections-position-history.component';
export * from './employee-sections-rate-history/employee-sections-rate-history.component';
export * from './employee-sections-terminations-history/employee-sections-terminations-history.component';
export * from './employee-sections-accruals/employee-sections-accruals.component';
export * from './employee-sections-timeclocks/employee-sections-timeclocks.component';
export * from './employee-sections-self-service/employee-sections-self-service.component';
export * from './employee-sections-ess-template/employee-sections-ess-template.component';
export * from './employee-sections-i9/employee-sections-i9.component';
export * from './employee-sections-leave-management/employee-sections-leave-management.component';
export * from './employee-sections-workers-comp/employee-sections-workers-comp.component';
export * from './employee-sections-performance/employee-section-performance-management.component';
export * from './employee-sections-telepunch/employee-sections-telepunch.component';
export * from './employee-sections-aca/employee-sections-aca.component';

import { EmployeeSectionsPayrollComponent } from './employee-sections-payroll/employee-sections-payroll.component';
import { EmployeeSectionsPositionsComponent } from './employee-sections-positions/employee-sections-positions.component';
import { EmployeeSectionsPayCyclesComponent } from './employee-sections-pay-cycles/employee-sections-pay-cycles.component';
import { EmployeeSectionsPositionHistoryComponent } from './employee-sections-position-history/employee-sections-position-history.component';
import { EmployeeSectionsRateHistoryComponent } from './employee-sections-rate-history/employee-sections-rate-history.component';
import { EmployeeSectionsTerminationsHistoryComponent } from './employee-sections-terminations-history/employee-sections-terminations-history.component';
import { EmployeeSectionsAccrualsComponent } from './employee-sections-accruals/employee-sections-accruals.component';
import { EmployeeSectionsTimeclocksComponent } from './employee-sections-timeclocks/employee-sections-timeclocks.component';
import { EmployeeSectionsSelfServiceComponent } from './employee-sections-self-service/employee-sections-self-service.component';
import { EmployeeSectionsEssTemplateComponent } from './employee-sections-ess-template/employee-sections-ess-template.component';
import { EmployeeSectionsI9Component } from './employee-sections-i9/employee-sections-i9.component';
import { EmployeeSectionsLeaveManagementComponent } from './employee-sections-leave-management/employee-sections-leave-management.component';
import { EmployeeSectionsWorkersCompComponent } from './employee-sections-workers-comp/employee-sections-workers-comp.component';
import { EmployeeSectionPerformanceManagementComponent } from './employee-sections-performance/employee-section-performance-management.component';
import { EmployeeSectionsTelepunchComponent } from './employee-sections-telepunch/employee-sections-telepunch.component';
import { EmployeeSectionsAcaComponent } from './employee-sections-aca/employee-sections-aca.component';

export const componentsEmploymentSection: any[] = [
  EmployeeSectionsPayrollComponent,
  EmployeeSectionsPositionsComponent,
  EmployeeSectionsPayCyclesComponent,
  EmployeeSectionsPositionHistoryComponent,
  EmployeeSectionsRateHistoryComponent,
  EmployeeSectionsTerminationsHistoryComponent,
  EmployeeSectionsAccrualsComponent,
  EmployeeSectionsTimeclocksComponent,
  EmployeeSectionsSelfServiceComponent,
  EmployeeSectionsEssTemplateComponent,
  EmployeeSectionsI9Component,
  EmployeeSectionsLeaveManagementComponent,
  EmployeeSectionsWorkersCompComponent,
  EmployeeSectionPerformanceManagementComponent,
  EmployeeSectionsTelepunchComponent,
  EmployeeSectionsAcaComponent
];
