<div class="am-toolbar">
    <slx-toolbar class="slx-so-toolbar-container" [alignExpandButtonRight]="true">
        <ng-template slxToolbarSectionTemplate [width]="getAcaWidth()">
            <span class="marginr10">
                Year
            </span>
            <span class="am-toolbar__control as-flex-end">
                <slx-dropdown-list name="yearDropdown" class="slx-wide slx-short" [valuePrimitive]="true"
                    [options]="yearOptions" (ngModelChange)="onChangeYear($event)" [(ngModel)]="selectedYear">
                </slx-dropdown-list>
            </span>
            <span class="pendo-aca-config-1095c-year"></span>
        </ng-template>

        <ng-template slxToolbarSectionTemplate>
            <span class="am-toolbar__control as-flex-end">
                <button type="button" class="slx-wide slx-short expand" *ngIf="!isExpand"
                    (click)="onClickExpand(true)"><em class="fas fa-chevron-double-down"></em>Expand All</button>
                <button type="button" class="slx-wide slx-short expand collapsee" *ngIf="isExpand"
                    (click)="onClickExpand(false)"><em class="fas fa-chevron-double-up"></em>Collapse All</button>
            </span>
        </ng-template>

        <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="100">
            <span class="am-toolbar__control" [ngClass]="{ 'collapsed': isCollapsed }">
              <slx-multiselect class="am-toolbar__filter"
                               [options]="employeeFilterRecords"
                               [(ngModel)]="filter"
                               (ngModelChange)="onChangeFilter($event)"
                               placeholder="Filter Employees"
                               name="employeeFilter">
              </slx-multiselect>
            </span>
          </ng-template>

        <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed"
            width="90">
            <span class="am-toolbar__control" *ngIf="!isCollapsed">
                <slx-action-list>
                    <slx-action-button [dropDownIcon]="'fas fa-caret-down'" [popperContent]="popperContent"
                        [popperPosition]="'bottom-end'">Actions</slx-action-button>
                    <popper-content #popperContent>
                        <slx-action-list-item [disabled]="!canExportToExcel" (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>                     
                    </popper-content>
                </slx-action-list>
            </span>
        </ng-template>
    </slx-toolbar>
</div>
