import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { FileModel } from '../../../models';
import { Observable } from 'rxjs/Observable';
import { wcConfig } from '../../../workers-compensation.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
var WcReportAttachmentsComponent = /** @class */ (function () {
    function WcReportAttachmentsComponent(management) {
        this.management = management;
        this.subscriptions = {};
        this.errors = {};
        this.files = [];
    }
    Object.defineProperty(WcReportAttachmentsComponent.prototype, "hasFiles", {
        get: function () {
            return _.size(this.files) > 0;
        },
        enumerable: true,
        configurable: true
    });
    WcReportAttachmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.maxFiles = wcConfig.files.maxFiles;
        this.maxFileSizeBytes = wcConfig.files.maxFileSizeBytes;
        this.acceptedFileTypes = wcConfig.files.acceptedTypes;
        this.acceptedFileTypesInputFormat = wcConfig.files.acceptedTypes.join(',');
        this.acceptedFileTypesReadable = wcConfig.files.acceptedTypes.join(', ');
        this.maxFileSize = this.getReadableBytes(wcConfig.files.maxFileSizeBytes);
        this.subscriptions.onLoad = this.management.onLoaded$.subscribe(function () {
            _this.files = _this.getAllFiles();
            _this.management.onFileChangeNotify(false);
        });
    };
    WcReportAttachmentsComponent.prototype.ngOnDestroy = function () { };
    WcReportAttachmentsComponent.prototype.onFileChange = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var fileList, files, readFiles;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.editMode) {
                            return [2 /*return*/];
                        }
                        fileList = _.get(event, 'target.files');
                        files = this.validateFiles(fileList, event);
                        if (!(files.length > 0)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.management.readAddedFiles(files)];
                    case 1:
                        readFiles = _a.sent();
                        this.management.addFilesToSave(readFiles);
                        this.files = this.files.concat(this.mapToFiles(readFiles));
                        _a.label = 2;
                    case 2:
                        this.management.onFileChangeNotify(true);
                        return [2 /*return*/];
                }
            });
        });
    };
    WcReportAttachmentsComponent.prototype.onResetErrors = function (event) {
        this.resetInput(event.target);
        this.errors.maxFiles = false;
        this.errors.maxFileSize = false;
        this.errors.fileType = false;
    };
    WcReportAttachmentsComponent.prototype.onClickDelete = function (isDelete, acceptPopover, file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.editMode) {
                            return [2 /*return*/];
                        }
                        acceptPopover.hide();
                        if (!isDelete) return [3 /*break*/, 5];
                        this.management.changeLoading(true);
                        if (!file.isAttachment) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.removeAttachment(file)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.removeFileToSave(file)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.management.changeLoading(false);
                        this.management.onFileChangeNotify(true);
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WcReportAttachmentsComponent.prototype.onDownloadFile = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (!this.isDownloadable(file)) {
                    return [2 /*return*/];
                }
                this.management.downloadAttachment(file.id);
                return [2 /*return*/];
            });
        });
    };
    WcReportAttachmentsComponent.prototype.isDownloadable = function (file) {
        return file.isAttachment;
    };
    WcReportAttachmentsComponent.prototype.getAllFiles = function () {
        var attachments = this.getAttachments();
        var filestToSave = this.getFilesToSave();
        return attachments.concat(filestToSave);
    };
    WcReportAttachmentsComponent.prototype.getAttachments = function () {
        var attachments = this.management.getAttachments();
        return this.mapToFiles(attachments);
    };
    WcReportAttachmentsComponent.prototype.getFilesToSave = function () {
        var addedFiles = this.management.getFilesToSave();
        return this.mapToFiles(addedFiles);
    };
    WcReportAttachmentsComponent.prototype.mapToFiles = function (files) {
        var userName = this.management.getUserName();
        return _.map(files, function (f) { return new FileModel(f, userName); });
    };
    WcReportAttachmentsComponent.prototype.removeAttachment = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.management.deleteAttachment(file.id)];
                    case 1:
                        _a.sent();
                        this.files = _.filter(this.files, function (f) { return f.id !== file.id; });
                        return [2 /*return*/];
                }
            });
        });
    };
    WcReportAttachmentsComponent.prototype.removeFileToSave = function (file) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.management.deleteFileToSave(file.sourceItem);
                this.files = _.filter(this.files, function (f) { return f.name !== file.name; });
                return [2 /*return*/, Observable.of(true).delay(500).toPromise()];
            });
        });
    };
    WcReportAttachmentsComponent.prototype.validateFiles = function (fileList, event) {
        var isError = false;
        var files = [];
        if (fileList.length > this.maxFiles) {
            isError = true;
            this.setError('maxFiles');
        }
        for (var i = 0, length_1 = fileList.length; i < length_1; i++) {
            var file = fileList.item(i);
            if (file.size > this.maxFileSizeBytes) {
                isError = true;
                this.setError('maxFileSize');
                break;
            }
            var fileExt = "." + _.last(file.name.split('.'));
            fileExt = fileExt.toLowerCase();
            if (!_.includes(this.acceptedFileTypes, fileExt)) {
                isError = true;
                this.setError('fileType');
                break;
            }
            files.push(file);
        }
        this.resetInput(event.target);
        return isError ? [] : files;
    };
    WcReportAttachmentsComponent.prototype.setError = function (errorType) {
        this.errors.maxFiles = false;
        switch (errorType) {
            case 'maxFiles':
                this.errors.maxFiles = true;
                break;
            case 'maxFileSize':
                this.errors.maxFileSize = true;
                break;
            case 'fileType':
                this.errors.fileType = true;
                break;
        }
    };
    WcReportAttachmentsComponent.prototype.getReadableBytes = function (bytes) {
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var res = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));
        return res * 1 + " " + sizes[i];
    };
    WcReportAttachmentsComponent.prototype.resetInput = function (elem) {
        elem.value = '';
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcReportAttachmentsComponent.prototype, "subscriptions", void 0);
    return WcReportAttachmentsComponent;
}());
export { WcReportAttachmentsComponent };
