import { authenticationConfig } from '../../../../app/authentication/authentication.config';
import { AuthApiService } from '../../../core/services/index';
import { UserMenuPinMapService } from './user-menu-pin-map.service';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import * as i0 from "@angular/core";
import * as i1 from "../auth-api/auth-api.service";
import * as i2 from "./user-menu-pin-map.service";
import * as i3 from "../../../common/services/url-params/url-params.service";
import * as i4 from "../../../common/services/api/api-util.service";
var UserMenuPinAPIService = /** @class */ (function () {
    function UserMenuPinAPIService(apiService, mapService, urlParamsService, apiUtilService) {
        this.apiService = apiService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
        this.apiUtilService = apiUtilService;
    }
    UserMenuPinAPIService.prototype.getUserMenuPinStatus = function () {
        var _this = this;
        var url = this.getApiRoot() + "/" + authenticationConfig.api.userMenuPinState;
        var request = this.urlParamsService.createGetRequest(url);
        return this.apiService.request(request)
            .then(function (response) {
            return _this.mapService.mapToResponse(response.data);
        });
    };
    UserMenuPinAPIService.prototype.toggleUserMenuPinStatus = function (isPinned) {
        var _this = this;
        var url = this.getApiRoot() + "/" + authenticationConfig.api.userMenuPinStateUpdate;
        var body = { IsMenuPinned: isPinned };
        var request = this.urlParamsService.createPostRequest(url, body);
        return this.apiService.request(request)
            .then(function (response) {
            return _this.mapService.mapToResponse(response.data);
        });
    };
    UserMenuPinAPIService.prototype.getApiRoot = function () {
        return this.apiUtilService.getApiRoot();
    };
    UserMenuPinAPIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserMenuPinAPIService_Factory() { return new UserMenuPinAPIService(i0.ɵɵinject(i1.AuthApiService), i0.ɵɵinject(i2.UserMenuPinMapService), i0.ɵɵinject(i3.UrlParamsService), i0.ɵɵinject(i4.ApiUtilService)); }, token: UserMenuPinAPIService, providedIn: "root" });
    return UserMenuPinAPIService;
}());
export { UserMenuPinAPIService };
