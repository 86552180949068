export interface ITimecardsLastSelectionState {
  selectedEmployee: number;
  skipRecords: number;
}

export class TimecardsLastSelectionState {
  constructor (
    public selectedEmployee: number,
    public skipRecords: number
  ) {}
}
