import {
  IWizardStateRecord,
  IWizardState
} from './wizard.types';
import { makeTypedFactory } from 'typed-immutable-record';

export const wizardStateFactory: (val?: IWizardState) => IWizardStateRecord = makeTypedFactory<IWizardState, IWizardStateRecord>({
  step: 1
});

export const INITIAL_WIZARD_STATE: IWizardStateRecord = wizardStateFactory();
