import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { LookupMapService, IEmployee } from '../../../organization';
import { IAttendancePointsTotals, AttendancePointsTotals, AttendancePointsTotalsData, IAttendancePointsTotalsData } from '../../models/attendance-points/attendance-points-totals';

@Injectable()
export class AttendancePointsMapService {

    constructor(private lookupMap: LookupMapService) { }

    mapTotalsData(data: IAttendancePointsTotalsData): AttendancePointsTotalsData {
        let totals = new AttendancePointsTotalsData();
        totals.records = this.mapTotals(data.records);
        totals.buyBackEnabled = data.buyBackEnabled;
        return totals;
    }

    mapTotals(data: IAttendancePointsTotals[]): AttendancePointsTotals[] {
        return _.map(data, r => this.mapTotalRecord(r));
    }

    public mapTotalRecord (dto: IAttendancePointsTotals):AttendancePointsTotals {

        let model = new AttendancePointsTotals();
        let empDef: IEmployee = {
            id: dto.employee.id,
            masterId: null,
            fullName: dto.employee.fullName,
            badgeId: null,
            department: null,
            position: null,
            organization: null,
            dateHired: null,
            terminationDate: dto.employee.terminationDate,
            type: null,
            payrollNumber: null,
            name: dto.employee.fullName,
        };
        model.employee = this.lookupMap.mapEmployee(empDef);
        model.organization = this.lookupMap.mapOrganization(dto.employee.organization);
        model.department = this.lookupMap.mapDepartment(dto.employee.department);
        model.position = this.lookupMap.mapPosition(dto.employee.position);
        model.totalAccumulatedPoints = dto.totalAccumulatedPoints;
        model.totalBuyBackPoints = dto.totalBuyBackPoints;
        model.totalPoints = dto.totalPoints;
        model.status = dto.employee.status;
        return model;
    }


}