import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { EssTemplateManagementService } from '../../services/index';
import { AppSettingsManageService } from '../../../../app-settings/services';
var EssTemplatesHeaderComponent = /** @class */ (function () {
    function EssTemplatesHeaderComponent(managementService, appSettingsManageService) {
        this.managementService = managementService;
        this.appSettingsManageService = appSettingsManageService;
    }
    EssTemplatesHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.load();
            }
        });
    };
    EssTemplatesHeaderComponent.prototype.passwordSettings = function () {
    };
    EssTemplatesHeaderComponent.prototype.addTemplate = function () {
        this.managementService.addTemplate(this.orgLevel);
        this.managementService.AddNewTempateAction(true);
    };
    EssTemplatesHeaderComponent.prototype.load = function () {
        if (!this.orgLevel.id) {
            return;
        }
        this.managementService.loadTemplates(this.orgLevel);
        this.getGoClientsetting();
    };
    EssTemplatesHeaderComponent.prototype.getGoClientsetting = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.isGoClient = config.IsGOClient;
                        return [2 /*return*/];
                }
            });
        });
    };
    EssTemplatesHeaderComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], EssTemplatesHeaderComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EssTemplatesHeaderComponent.prototype, "orgLevelSubscription", void 0);
    return EssTemplatesHeaderComponent;
}());
export { EssTemplatesHeaderComponent };
