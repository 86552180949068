import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { EmployeeSubsectionDependents } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
var EmployeeSectionsDependentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsDependentsComponent, _super);
    function EmployeeSectionsDependentsComponent(decorator, ngZone, manService, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.manService = manService;
        _this.changeDetector = changeDetector;
        _this.subscriptions = {};
        _this.changedInputs$ = new ReplaySubject(1);
        return _this;
    }
    Object.defineProperty(EmployeeSectionsDependentsComponent.prototype, "employeeSubsectionDependents", {
        set: function (dependents) {
            if (_.isObjectLike(dependents)) {
                this.dependentSection = dependents;
                this.changedInputs$.next({ dependents: dependents });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsComponent.prototype, "employeeId", {
        set: function (empId) {
            if (_.isFinite(empId)) {
                this.empId = empId;
                this.changedInputs$.next({ empId: empId });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsDependentsComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsDependentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.manService.init();
        this.subscriptions.inputs = this.changedInputs$.subscribe(function (data) {
            if (_.isFinite(data.empId)) {
                _this.manService.changeEmpId(data.empId);
            }
            if (_.isObjectLike(data.dependents) && _.size(data.dependents) > 0) {
                _this.manService.changeDependents(data.dependents);
            }
        });
        this.subscriptions.spinner = this.manService.subscribeToSpinner(function (isShown) {
            if (isShown) {
                _this.startProgress();
                return;
            }
            _this.stopProgress();
            _this.changeDetector.markForCheck();
        });
    };
    EmployeeSectionsDependentsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.manService.destroy();
        this.changedInputs$.complete();
    };
    EmployeeSectionsDependentsComponent.prototype.getSubsectionModel = function () {
        return this.dependentSection;
    };
    EmployeeSectionsDependentsComponent.prototype.loadSubsection = function () { };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsDependentsComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsDependentsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsDependentsComponent };
