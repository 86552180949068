import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../app.config';
import { ResponseBody, Meta, FieldsMeta } from '../../../core/models/index';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';
import { Assert } from '../../../framework/index';
import { PunchesMapService } from './punches-map.service';
import {
  IEmployeeDailyPunches, EmployeeDailyPunches,
  IEmployeesPunchesContainer, EmployeesPunchesContainer, EmployeesPunchesHeaderContainer, IEmployeesPunchesHeaderContainer,
  PunchLogEntry, IPunchLogEntry, LinePunch, ILinePunch, PunchesRollupRecord, IPunchesRollupRecord, IPunchesRollupContainer, IEmployeePunchRequest, EmployeePunchRequest
} from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { EmployeePunchRequestMapService } from './employee-punch-request-map.service';

@Injectable()
export class PunchesApiService {
  constructor(
    private mapService: PunchesMapService,
    private employeePunchRequestMapService: EmployeePunchRequestMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public saveDailyPunches(orgLevelId: number, employeePunchesList: EmployeeDailyPunches[]): Promise<EmployeeDailyPunches[]> {
    const url: string = `${this.getPunchesApiRoot()}/${timeAndAttendanceConfig.api.dailyPunches.orgLevel}/${orgLevelId}`;

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(this.urlParamsService.createPutRequest(url, this.mapService.mapEmployeeDailyPunchesListDto(employeePunchesList)))
      .then((response: ResponseBody<IEmployeeDailyPunches[], Meta>) => {
        return this.mapService.mapEmployeeDailyPunchesList(response.data);
      });
    return promise;
  }

  public getOrgLevelDailyPunches(orgLevelId: number, startDate: Date, endDate: Date): Promise<EmployeesPunchesContainer> {
    const url: string = `${this.getPunchesApiRoot()}/${timeAndAttendanceConfig.api.dailyPunches.orgLevel}/${orgLevelId}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      });

    let promise: Promise<EmployeesPunchesContainer> = this.apiUtilService.request<IEmployeesPunchesContainer, Meta>(req)
      .then((response: ResponseBody<IEmployeesPunchesContainer, Meta>) => {
        return this.mapService.mapEmployeesPunchesContainer(response.data);
      });
    return promise;
  }

  public getOrgLevelDailyPunchesHeader(orgLevelId: number, startDate: Date, endDate: Date): Promise<EmployeesPunchesHeaderContainer> {
    const url: string = `${this.getPunchesApiRoot()}/${timeAndAttendanceConfig.api.dailyPunches.orgLevel}/${orgLevelId}/${timeAndAttendanceConfig.api.dailyPunches.headers}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      });

    let promise: Promise<EmployeesPunchesHeaderContainer> = this.apiUtilService.request<IEmployeesPunchesHeaderContainer, FieldsMeta>(req)
      .then((response: ResponseBody<IEmployeesPunchesHeaderContainer, FieldsMeta>) => {
        return this.mapService.mapEmployeesPunchesHeaderContainer(response.data, response.meta);
      });
    return promise;
  }

  public getEmployeePunchRequest(orgLevelId: number, startDate: Date, endDate: Date): Promise<EmployeePunchRequest> {
    const url: string = `${this.getEmployeePunchRequestApiRoot()}/${timeAndAttendanceConfig.api.employeePunchRequest.orgLevel}/${orgLevelId}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate)
      });

    let promise: Promise<EmployeePunchRequest> = this.apiUtilService.request<IEmployeePunchRequest, Meta>(req)
      .then((response: ResponseBody<IEmployeePunchRequest, Meta>) => {
        return this.employeePunchRequestMapService.mapEmployeePunchRequest(response.data);
      });
    return promise;
  }

  public getOrgLevelLinePunches(orgLevelId: number, date: Date, employeeId: number): Promise<EmployeeDailyPunches> {
    const url: string = `${this.getPunchesOrglevelApiRoot(orgLevelId)}/${timeAndAttendanceConfig.api.dailyPunches.employee.root}/${employeeId}`;
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        date: dateTimeUtils.convertToDtoString(date)
      });

    let promise: Promise<EmployeeDailyPunches> = this.apiUtilService.request<IEmployeeDailyPunches, Meta>(req)
      .then((response: ResponseBody<IEmployeeDailyPunches, Meta>) => {
        return this.mapService.mapEmployeeDailyPunches(response.data);
      });
    return promise;
  }
  public getDailyPunchLogs(startDate: Date, endDate: Date, employeeId: number): Promise<PunchLogEntry[]> {
    const url: string = this.getPunchLogsApiRoot();
    let req: HttpRequest<any> = this.urlParamsService.createGetRequest(url,
      {
        startDate: dateTimeUtils.convertToDtoString(startDate),
        endDate: dateTimeUtils.convertToDtoString(endDate),
        employeeId: employeeId
      });

    let promise: Promise<PunchLogEntry[]> = this.apiUtilService.request<IPunchLogEntry[], Meta>(req)
      .then((response: ResponseBody<IPunchLogEntry[], Meta>) => {
        return this.mapService.mapPunchLogEntryList(response.data);
      });
    return promise;
  }

  public getPunchesRollupRecords(orgLevelId: number, startDate: Date, endDate: Date): Promise<PunchesRollupRecord[]> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url: string = `${this.getPunchesApiRoot()}/${timeAndAttendanceConfig.api.exceptionConsole.orgLevel.root}/${orgLevelId}/${timeAndAttendanceConfig.api.dailyPunches.rollup}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });
    let promise: Promise<PunchesRollupRecord[]> = this.apiUtilService.request<IPunchesRollupContainer, Meta>(request)
      .then((response: ResponseBody<IPunchesRollupContainer, Meta>) => {
        return this.mapService.mapPunchesRollupRecords(response.data.entities);
      });
    return promise;
  }

  public removeInvalidLogin(date:Date,empId:number):Promise<boolean>{
    Assert.isNotNull(date, 'date');
    Assert.isNotNull(empId, 'empId');
    let time = dateTimeUtils.convertToDtoDateStringWithMilliseconds(date);
    const url: string = `${this.getPunchesApiRoot()}/${time}/${timeAndAttendanceConfig.api.dailyPunches.removeInvalidLogin}/${empId}`;
    let request: HttpRequest<any> = this.urlParamsService.createPutRequest(url);
    let promise: Promise<boolean> = this.apiUtilService.request<any, Meta>(request)
    .then((response: ResponseBody<any, Meta>) => {
      return true;
    }).catch((reason: any) => {
      return false;
    });
    return promise;
  }

  private getTaApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${timeAndAttendanceConfig.api.root}`;
  }
  private getPunchesApiRoot(): string {
    return `${this.getTaApiRoot()}/${timeAndAttendanceConfig.api.dailyPunches.root}`;
  }
  private getEmployeePunchRequestApiRoot(): string {
    return `${this.getTaApiRoot()}/${timeAndAttendanceConfig.api.employeePunchRequest.root}`;
  }
  private getPunchLogsApiRoot(): string {
    return `${this.getTaApiRoot()}/${timeAndAttendanceConfig.api.dailyPunchLogs}`;
  }
  private getPunchesOrglevelApiRoot(orgLevelId: number): string {
    return `${this.getPunchesApiRoot()}/${timeAndAttendanceConfig.api.dailyPunches.orgLevel}/${orgLevelId}`;
  }
}
