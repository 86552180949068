import { Injectable } from '@angular/core';
import { Assert } from '../../../../framework/index';
import * as _ from 'lodash';
import * as moment from 'moment';
import { dateTimeUtils } from '../../../../common/utils/index';
import {
  EmployeeSectionsPersonal,
  EmployeeSectionsProfile,
  EmployeeSectionsContacts,
  IEmployeeSectionsPersonal,
  IEmployeeSectionsProfile,
  IEmployeeSectionsContacts,
  EmployeeSectionsLicense,
  IEmployeeSectionsLicense,
  EmployeeSectionsLicenses,
  IEmployeeSectionsPhysicalGroup,
  EmployeeSectionsPhysicalGroup,
  IEmployeeSectionsPhysicalField,
  EmployeeSectionsPhysicalField,
  EmployeeSectionsPhysicals,
  IEmployeeSectionsPhysicals,
  IEmployeeSectionsLicenses,
  IEmployeeSectionsDocuments,
  EmployeeSectionsDocuments,
  IEmployeeSectionsDocument,
  EmployeeDocument,
  IEmployeeSectionsBackgroundChecks,
  EmployeeSectionsBackgroundChecks,
  IEmployeeSectionsBackgroundCheck,
  EmployeeSectionsBackgroundCheck,
  IEmployeeSectionsCertification,
  EmployeeSectionsCertification,
  IEmployeeSectionsCertifications,
  EmployeeSectionsCertifications,
  IEmployeeSectionsEducations,
  EmployeeSectionsEducations,
  IEmployeeSectionsEducation,
  EmployeeSectionsEducation,
  IEmployeeSubsectionWarnings,
  EmployeeSubsectionWarnings,
  IEmployeeWarningExtended,
  EmployeeWarningExtended
} from '../../models/index';
import {
  IFieldData,
  FieldRenderType,
  FieldsMeta,
  Actions
} from '../../../../core/models/index';
import {
  BudgetedPosition,
  EmpType,
  State,
  Gender,
  Race,
  MaritalStatus,
  EmployeeDocumentCategory,
  VeteranStatus,
  DisabilityStatus,
  Employee,
  Department,
  LicenseType
} from '../../../../organization/models/lookup/index';
import { EmployeeDefinitionsMapService, LookupMapService } from '../../../../organization/services/index';
import { MetaMapService } from '../../../../core/services/index';
import { EmployeeSectionsEmergencyContacts, IEmployeeSectionsEmergencyContacts, IEmployeeSectionsEmergencyContact, EmployeeEmergencyContact } from '../../models/employee-sections-personal/employee-sections-emergency-contacts';
import { EmployeeSectionWarningMapService } from './employee-section-warning-map.service';
import { AgencyCertificate, AgencyCertificates, IAgencyCertificate, IAgencyCertificates } from '../../models/employee-sections-personal/employee-sections-agency-certificate';


@Injectable()
export class EmployeeSectionsPersonalMapService {
  private metaMapService: MetaMapService;
  private lookupMapService: LookupMapService;
  private empMapService: EmployeeDefinitionsMapService;
  private empWarningMapService: EmployeeSectionWarningMapService;

  constructor(
    metaMapService: MetaMapService,
    lookupMapService: LookupMapService,
    empMapService: EmployeeDefinitionsMapService,
    empWarningMapService: EmployeeSectionWarningMapService
  ) {
    this.metaMapService = metaMapService;
    this.lookupMapService = lookupMapService;
    this.empMapService = empMapService;
    this.empWarningMapService = empWarningMapService;
  }

  public mapToProfile(dprofile: IEmployeeSectionsProfile, meta: FieldsMeta, veteranStatuses: VeteranStatus[]): EmployeeSectionsProfile {
    Assert.isNotNull(dprofile, 'dprofile');
    let profile: EmployeeSectionsProfile = new EmployeeSectionsProfile();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    profile.fullName = this.metaMapService.mapMeta<string>(metaMap, 'fullName', dprofile.fullName);
    profile.firstName = this.metaMapService.mapMeta<string>(metaMap, 'firstName', dprofile.firstName);
    profile.lastName = this.metaMapService.mapMeta<string>(metaMap, 'lastName', dprofile.lastName);
    profile.middleName = this.metaMapService.mapMeta<string>(metaMap, 'middleName', dprofile.middleName);
    profile.suffix = this.metaMapService.mapMeta<string>(metaMap, 'suffix', dprofile.suffix);
    let employeeType: EmpType = this.lookupMapService.mapEmpType({ name: dprofile.type, description: dprofile.type, fullTimeIndex: null });
    profile.employeeType = this.metaMapService.mapMeta<EmpType>(metaMap, 'type', employeeType, employeeType ? employeeType.name : null);
    profile.organizationId = dprofile.organization.id;
    profile.organization = this.metaMapService.mapMeta<string>(metaMap, 'organization', dprofile.organization.name);
    profile.departmentId = dprofile.department.id;
    profile.department = this.metaMapService.mapMeta<string>(metaMap, 'department', dprofile.department.name);
    profile.positionId = dprofile.position.id;
    profile.position = this.metaMapService.mapMeta<string>(metaMap, 'position.Name', dprofile.position.name);
    profile.hireDate = this.metaMapService.mapMeta<Date>(metaMap, 'hireDate',
      dateTimeUtils.convertFromDtoString(dprofile.hireDate), dprofile.hireDate);
    profile.birthDate = this.metaMapService.mapMeta<Date>(metaMap, 'birthDate',
      dateTimeUtils.convertFromDtoString(dprofile.birthDate), dprofile.birthDate);
    profile.phone = this.metaMapService.mapMeta<string>(metaMap, 'phone', dprofile.phone);
    profile.mobilePhone = this.metaMapService.mapMeta<string>(metaMap, 'mobilePhone', dprofile.mobilePhone);
    let employeeGender: Gender = this.lookupMapService.mapGender(dprofile.gender);
    profile.gender = this.metaMapService.mapMeta<Gender>(metaMap, 'gender', employeeGender, employeeGender ? employeeGender.name : null);
    let employeeRace: Race = this.lookupMapService.mapRace(dprofile.race);
    profile.race = this.metaMapService.mapMeta<Race>(metaMap, 'race', employeeRace, employeeRace ? employeeRace.description : null);
    let employeeMaritalStatus: MaritalStatus = this.lookupMapService.mapMaritalStatus(dprofile.maritalStatus);
    profile.maritalStatus = this.metaMapService.mapMeta<MaritalStatus>(metaMap, 'maritalStatus', employeeMaritalStatus, employeeMaritalStatus ? employeeMaritalStatus.description : null);
    profile.canTransfer = !!_.find(meta.actions, (item: string) => { return item === 'CanTransfer'; });
    profile.canTerminate = !!_.find(meta.actions, (item: string) => { return item === 'CanTerminate'; });
    profile.canRehire = !!_.find(meta.actions, (item: string) => { return item === 'CanRehire'; });
    profile.canCancelTerminate = profile.canTerminate;
    profile.status = dprofile.status;
    profile.createdThroughTransfer = dprofile.createdThroughTransfer;
    profile.isTransferPending = dprofile.isTransferPending;
    profile.isLatestTerminate = dprofile.isLatestTerminate;
    profile.isPayrollVisible = !!_.find(meta.actions, (item: string) => { return item === 'IsPayrollVisible'; });
    let veteranStatusId = dprofile.veteranStatus;
    let veteranStatus = null;
    if (!_.isNil (veteranStatusId)) {
      veteranStatus = _.find (veteranStatuses, s => s.id === veteranStatusId);
      if (_.isNil(veteranStatus)) {
        veteranStatus = this.lookupMapService.mapVeteranStatus ({id: veteranStatusId, name:veteranStatusId});
      }
    }
    profile.veteranStatus = this.metaMapService.mapMeta<VeteranStatus>(metaMap, 'veteranStatus', veteranStatus, veteranStatus ? veteranStatusId : null);

    const disabilityStatus = this.lookupMapService.mapDisabilityStatus(dprofile.disabilityStatus);
    profile.disabilityStatus = this.metaMapService.mapMeta<DisabilityStatus>(metaMap, 'disabilityStatus', disabilityStatus, disabilityStatus ? disabilityStatus.name : null);

    profile.rehireDate = dateTimeUtils.getUtcDateTime(dprofile.rehireDate);
    profile.terminationDate = dateTimeUtils.getUtcDateTime(dprofile.terminationDate);
    profile.actions = this.metaMapService.mapActions(meta);
    profile.fieldsMeta = meta;
    return profile;
  }

  public mapToProfileDto(profile: EmployeeSectionsProfile): IEmployeeSectionsProfile {

    Assert.isNotNull(profile, 'profile');
    profile.veteranStatusId = profile.veteranStatus && profile.veteranStatus.fieldValue ? profile.veteranStatus.fieldValue.id : null;

    let empType: string = profile.employeeType.fieldValue ? profile.employeeType.fieldValue.name : '';
    let gender: string = profile.gender.fieldValue ? profile.gender.fieldValue.name : '';
    let race: string = profile.race.fieldValue ? profile.race.fieldValue.name : '';
    let maritalStatus: string = profile.maritalStatus.fieldValue ? profile.maritalStatus.fieldValue.name : '';
    let disabilityStatus: string = this.lookupMapService.mapDisabilityStatusDTO(profile.disabilityStatus.fieldValue);
    let dprofile: IEmployeeSectionsProfile = {
      fullName: profile.fullName.fieldValue,
      firstName: profile.firstName.fieldValue,
      lastName: profile.lastName.fieldValue,
      middleName: profile.middleName.fieldValue,
      suffix: profile.suffix.fieldValue,
      type: empType,
      organization: {
        id: profile.organizationId,
        name: profile.organization.fieldValue,
      },
      department: {
        id: profile.departmentId,
        name: profile.department.fieldValue,
      },
      position: {
        id: profile.positionId,
        name: profile.position.fieldValue,
      },
      hireDate: dateTimeUtils.convertToDtoString(profile.hireDate.fieldValue),
      birthDate: dateTimeUtils.convertToDtoString(profile.birthDate.fieldValue),
      phone: profile.phone.fieldValue,
      mobilePhone: profile.mobilePhone.fieldValue,
      gender: {
        value: gender
      },
      race: {
        value: race
      },
      maritalStatus: {
        value: maritalStatus
      },
      veteranStatus: profile.veteranStatusId,
      status: profile.status,
      terminationDate: profile.terminationDate,
      rehireDate: profile.rehireDate,
      disabilityStatus: disabilityStatus,
      createdThroughTransfer: profile.createdThroughTransfer,
      isTransferPending : profile.isTransferPending,
      isPayrollVisible: profile.isPayrollVisible
    };
    return dprofile;
  }

  public mapToEmergencyContacts(decontacts: IEmployeeSectionsEmergencyContacts, meta: FieldsMeta): EmployeeSectionsEmergencyContacts {
    Assert.isNotNull(decontacts, 'decontacts');

    let model: EmployeeSectionsEmergencyContacts = new EmployeeSectionsEmergencyContacts();
    model.emergencyContacts = [];
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    _.forEach(decontacts.emergencyContacts, (dEmergencyContact: IEmployeeSectionsEmergencyContact) => {
      let emergencyContact: EmployeeEmergencyContact = new EmployeeEmergencyContact();

      emergencyContact.address = this.metaMapService.mapMeta<string>(metaMap, 'address', dEmergencyContact.address);
      emergencyContact.city = this.metaMapService.mapMeta<string>(metaMap, 'city', dEmergencyContact.city);
      emergencyContact.name = this.metaMapService.mapMeta<string>(metaMap, 'name', dEmergencyContact.name);
      emergencyContact.phone = this.metaMapService.mapMeta<string>(metaMap, 'phone', dEmergencyContact.phone);

      let state: State = this.lookupMapService.mapState({ value: dEmergencyContact.state });
      emergencyContact.state = this.metaMapService.mapMeta<State>(metaMap, 'state', state, state ? state.name : null);

      emergencyContact.zip = this.metaMapService.mapMeta<string>(metaMap, 'zip', dEmergencyContact.zip);

      model.emergencyContacts.push(emergencyContact);
    });

    model.fieldsMeta = meta;
    model.actions = this.metaMapService.mapActions(meta);

    return model;
  }
 

  public mapToEmergencyContactsDto(emergencyContacts: EmployeeSectionsEmergencyContacts): IEmployeeSectionsEmergencyContacts {
    Assert.isNotNull(emergencyContacts, 'emergencyContacts');

    let dEmergencyContacts: IEmployeeSectionsEmergencyContacts = {
      emergencyContacts: []
    };

    _.forEach(emergencyContacts.emergencyContacts, (emergencyContact: EmployeeEmergencyContact) => {
      let dEmergencyContact: IEmployeeSectionsEmergencyContact = {
        address: emergencyContact.address.fieldValue,
        city: emergencyContact.city.fieldValue,
        name: emergencyContact.name.fieldValue,
        phone: emergencyContact.phone.fieldValue,
        state: emergencyContact.state.fieldValue ? emergencyContact.state.fieldValue.name : '',
        zip: emergencyContact.zip.fieldValue
      };

      dEmergencyContacts.emergencyContacts.push(dEmergencyContact);
    });

    return dEmergencyContacts;
  }

  public mapToContacts(dcontacts: IEmployeeSectionsContacts, meta: FieldsMeta): EmployeeSectionsContacts {
    Assert.isNotNull(dcontacts, 'dcontacts');
    let contacts: EmployeeSectionsContacts = new EmployeeSectionsContacts();
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    contacts.address = this.metaMapService.mapMeta<string>(metaMap, 'address', dcontacts.address);
    contacts.city = this.metaMapService.mapMeta<string>(metaMap, 'city', dcontacts.city);
    let state: State = this.lookupMapService.mapState({ value: dcontacts.state });
    contacts.state = this.metaMapService.mapMeta<State>(metaMap, 'state', state, state ? state.name : null);
    contacts.zip = this.metaMapService.mapMeta<string>(metaMap, 'zip', dcontacts.zip);
    contacts.phone = this.metaMapService.mapMeta<string>(metaMap, 'phone', dcontacts.phone);
    contacts.mobile = this.metaMapService.mapMeta<string>(metaMap, 'mobile', dcontacts.mobile);
    contacts.alternativePhone = this.metaMapService.mapMeta<string>(metaMap, 'alternativePhone', dcontacts.alternativePhone);
    contacts.email1 = this.metaMapService.mapMeta<string>(metaMap, 'email1', dcontacts.email1);
    contacts.email2 = this.metaMapService.mapMeta<string>(metaMap, 'email2', dcontacts.email2);
    contacts.empOptIn= dcontacts.empOptIn;
    contacts.empOptInBy=dcontacts.empOptInBy;
    contacts.empOptInDate=dcontacts.empOptInDate;
    contacts.empOptInEmailDate = dcontacts.empOptInEmailDate;
    contacts.isMessageCenterAccessible=dcontacts.isMessageCenterAccessible;
    contacts.actions = this.metaMapService.mapActions(meta);
    contacts.fieldsMeta = meta;
    return contacts;

  }

  public mapToContactsDto(contacts: EmployeeSectionsContacts): IEmployeeSectionsContacts {
    Assert.isNotNull(contacts, 'contacts');
    let state: string = contacts.state.fieldValue ? contacts.state.fieldValue.name : '';
    let dcontacts: IEmployeeSectionsContacts = {
      address: contacts.address.fieldValue,
      city: contacts.city.fieldValue,
      state: state,
      zip: contacts.zip.fieldValue,
      phone: contacts.phone.fieldValue,
      mobile: contacts.mobile.fieldValue,
      alternativePhone: contacts.alternativePhone.fieldValue,
      email1: contacts.email1.fieldValue || null,
      email2: contacts.email2.fieldValue || null,
      empOptIn: contacts.empOptIn,
      empOptInBy:contacts.empOptInBy,
      empOptInDate:contacts.empOptInDate,
      empOptInEmailDate:contacts.empOptInEmailDate,
      isMessageCenterAccessible:contacts.isMessageCenterAccessible
    };
    return dcontacts;
  }

  public mapToLicenses(data: IEmployeeSectionsLicenses, meta: FieldsMeta): EmployeeSectionsLicenses {
    Assert.isNotNull(data, 'licenses');
    let dlicenses: IEmployeeSectionsLicense[] = data.licenses;
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    let licenses: EmployeeSectionsLicenses = new EmployeeSectionsLicenses();
    licenses.actions = this.metaMapService.mapActions(meta);
    licenses.licenseNumberMetaData = this.metaMapService.mapMeta<string>(metaMap, 'licenseNumber', null);
    licenses.expirationDateMetaData = this.metaMapService.mapMeta<Date>(metaMap, 'expirationDate', null);
    licenses.budgetedPositionMetaData = this.metaMapService.mapMeta<BudgetedPosition>(metaMap, 'budgetedPosition', new BudgetedPosition(), 'budgetedPosition');
    licenses.licenseTypeMetaData = this.metaMapService.mapMeta<LicenseType>(metaMap, 'licenseType', new LicenseType(), 'licenseType');
    licenses.issueDateMetaData = this.metaMapService.mapMeta<Date>(metaMap, 'issueDate', null);
    _.forEach(dlicenses, (dlicense: IEmployeeSectionsLicense) => {
      let license: EmployeeSectionsLicense = new EmployeeSectionsLicense();
      license.licenseNumber = this.metaMapService.mapMeta<string>(metaMap, 'licenseNumber', dlicense.licenseNumber);
      license.expirationDate = this.metaMapService.mapMeta<Date>(metaMap, 'expirationDate',
        dateTimeUtils.convertFromDtoString(dlicense.expirationDate), dlicense.expirationDate);
      let bp: BudgetedPosition = new BudgetedPosition();
      if (dlicense.budgetedPosition) {
        bp.id = dlicense.budgetedPosition.id;
        bp.description = dlicense.budgetedPosition.description;
      }
      let lt: LicenseType = new LicenseType();
      if(dlicense.licenseType) {
        lt.licenseTypeID = dlicense.licenseType.licenseTypeID;
        lt.licenseTypeName = dlicense.licenseType.licenseTypeName;
      }
      license.budgetedPosition = this.metaMapService.mapMeta<BudgetedPosition>(metaMap, 'budgetedPosition', dlicense.budgetedPosition, dlicense.budgetedPosition ? dlicense.budgetedPosition.description : null);
      license.licenseType = this.metaMapService.mapMeta<LicenseType>(metaMap, 'licenseType', dlicense.licenseType, dlicense.licenseType ? dlicense.licenseType.licenseTypeName : null);
      license.issueDate = this.metaMapService.mapMeta<Date>(metaMap, 'issueDate',
      dateTimeUtils.convertFromDtoString(dlicense.issueDate), dlicense.issueDate);
      license.seq = dlicense.seq;
      licenses.employeeSectionsLicenses.push(license);
    });
    licenses.fieldsMeta = meta;
    return licenses;
  }

  public mapToLicensesDto(licenses: EmployeeSectionsLicense[]): IEmployeeSectionsLicense[] {
    Assert.isNotNull(licenses, 'licenses');
    let dlicenses: IEmployeeSectionsLicense[] = [];
    _.forEach(licenses, (license: EmployeeSectionsLicense) => {
      let dlicense: IEmployeeSectionsLicense = {
        licenseNumber: license.licenseNumber.fieldValue,
        expirationDate: dateTimeUtils.convertToDtoString(license.expirationDate.fieldValue),
        budgetedPosition: license.budgetedPosition.fieldValue,
        licenseType: license.licenseType.fieldValue,
        issueDate: !license.issueDate.fieldValue ? '01-01-1900': dateTimeUtils.convertToDtoString(license.issueDate.fieldValue),
        seq: license.seq,
      };
      dlicenses.push(dlicense);
    });
    return dlicenses;
  }

  public mapToPhysicalField<T>(dfield: IEmployeeSectionsPhysicalField, metaMap: StringMap<IFieldData>): EmployeeSectionsPhysicalField<T> {
    let field: EmployeeSectionsPhysicalField<T> = new EmployeeSectionsPhysicalField<T>();
    field.displayName = dfield.displayName;
    field.fieldType = dfield.fieldType;
    field.id = dfield.id;
    field.seq = dfield.seq;
    return field;
  }

  public mapToPhysicals(dphisicals: IEmployeeSectionsPhysicalGroup[], meta: FieldsMeta): EmployeeSectionsPhysicals {
    Assert.isNotNull(dphisicals, 'dphisicals');
    let metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);
    let phisicals: EmployeeSectionsPhysicals = new EmployeeSectionsPhysicals();
    _.forEach(dphisicals, (dgroup: IEmployeeSectionsPhysicalGroup) => {
      let group: EmployeeSectionsPhysicalGroup = new EmployeeSectionsPhysicalGroup();
      group.seq = dgroup.seq;
      _.forEach(dgroup.fields, (dfield: IEmployeeSectionsPhysicalField) => {
        let field: EmployeeSectionsPhysicalField<any>;
        switch (dfield.fieldType) {
          case 'char':
          case 'string':
            field = this.mapToPhysicalField<string>(dfield, metaMap);
            field.fieldRender = FieldRenderType.string;
            field.fieldData = this.metaMapService.mapMeta<string>(metaMap, dfield.name, dfield.value);
            break;
          case 'number':
            field = this.mapToPhysicalField<number>(dfield, metaMap);
            field.fieldRender = FieldRenderType.number;
            field.fieldData = this.metaMapService.mapMeta<number>(metaMap, dfield.name, dfield.value);
            break;
          case 'date':
          case 'datetime':
            field = this.mapToPhysicalField<Date>(dfield, metaMap);
            field.fieldRender = FieldRenderType.datetime;
            field.fieldData = this.metaMapService.mapMeta<Date>(metaMap, dfield.name,
              dateTimeUtils.convertFromDtoString(dfield.value), dfield.value);
            break;
          default:
            field = this.mapToPhysicalField<string>(dfield, metaMap);
            field.fieldRender = FieldRenderType.string;
            field.fieldData = this.metaMapService.mapMeta<string>(metaMap, dfield.name, dfield.value);
            break;
        }
        field.fieldLength = parseInt(dfield.fieldLength, 10);
        let seq: number = _.sortedIndexBy(group.fields, field, (ol: EmployeeSectionsPhysicalField<any>): number => {
          return ol.seq;
        });
        group.fields.splice(seq, 0, field);
      });
      let seq: number = _.sortedIndexBy(phisicals.groups, group, (ol: EmployeeSectionsPhysicalGroup): number => {
        return ol.seq;
      });
      phisicals.groups.splice(seq, 0, group);
    });
    phisicals.actions = this.metaMapService.mapActions(meta);
    phisicals.fieldsMeta = meta;
    return phisicals;
  }

  public mapToPhysicalsDto(physicals: EmployeeSectionsPhysicals): IEmployeeSectionsPhysicals {
    Assert.isNotNull(physicals, 'physicals');
    let dgroups: IEmployeeSectionsPhysicalGroup[] = [];
    _.forEach(physicals.groups, (group: EmployeeSectionsPhysicalGroup) => {
      let dgroup: IEmployeeSectionsPhysicalGroup = {
        fields: [],
        seq: group.seq
      };
      _.forEach(group.fields, (field: EmployeeSectionsPhysicalField<any>) => {
        let dfield: IEmployeeSectionsPhysicalField = {
          id: field.id,
          name: field.fieldData.fieldName,
          displayName: field.displayName,
          value: field.fieldType === 'date' || field.fieldType === 'datetime' ? dateTimeUtils.convertToDtoString(field.fieldData.fieldValue) : field.fieldData.fieldValue,
          fieldType: field.fieldType,
          fieldLength: field.fieldLength.toString(10),
          renderType: field.fieldType,
          seq: field.seq
        };
        dgroup.fields.push(dfield);
      });
      dgroups.push(dgroup);
    });
    let dphysicals: IEmployeeSectionsPhysicals = {
      groups: dgroups
    };
    return dphysicals;
  }

  public mapToDocument(documentDto: IEmployeeSectionsDocument): EmployeeDocument {
    Assert.isNotNull(documentDto, 'documentDto');

    const document: EmployeeDocument = new EmployeeDocument();

    document.addedBy = documentDto.addedBy;
    document.addedOn = dateTimeUtils.convertFromDtoString(documentDto.addedOn);
    document.category = new EmployeeDocumentCategory(documentDto.category);
    document.id = documentDto.id;
    document.name = documentDto.name;
    document.file = documentDto.file;
    document.fileName = documentDto.fileName;

    return document;
  }

  public mapToDocumentDto(document: EmployeeDocument): IEmployeeSectionsDocument {
    const documentDto: IEmployeeSectionsDocument = {
      addedBy: document.addedBy,
      addedOn: dateTimeUtils.convertToDtoString(document.addedOn),
      category: document.category ? document.category.description : null,
      id: document.id,
      name: document.name,
      file: document.file,
      fileName: document.fileName
    };

    return documentDto;
  }

  public mapToDocumentDtoFormData(document: EmployeeDocument): FormData {
    const formData: FormData = new FormData();
    const documentDto = this.mapToDocumentDto(document);

    formData.append('addedBy', documentDto.addedBy);
    formData.append('addedOn', documentDto.addedOn);
    formData.append('category', documentDto.category);
    formData.append('name', documentDto.name);
    formData.append('id', `${documentDto.id}`);
    if (documentDto.file) {
      formData.append('file', documentDto.file, documentDto.fileName);
    }
    formData.append('fileName', documentDto.fileName);

    return formData;
  }

  public mapToDocuments(documentsDto: IEmployeeSectionsDocuments, meta: FieldsMeta): EmployeeSectionsDocuments {
    Assert.isNotNull(documentsDto, 'documentsDto');

    const documents: EmployeeSectionsDocuments = new EmployeeSectionsDocuments();

    documents.documents = _.map(documentsDto.documents, (documentDto: IEmployeeSectionsDocument) => {
      return this.mapToDocument(documentDto);
    });

    documents.fieldsMeta = meta;
    documents.actions = this.metaMapService.mapActions(meta);

    return documents;
  }

  public mapToDocumentsDto(documents: EmployeeSectionsDocuments): IEmployeeSectionsDocuments {
    Assert.isNotNull(documents, 'documents');

    const documentsDto: IEmployeeSectionsDocuments = {
      documents: _.map(documents.documents, (document: EmployeeDocument) => {
        return this.mapToDocumentDto(document);
      })
    };

    return documentsDto;
  }

  public mapToSectionsPersonal(data: IEmployeeSectionsPersonal, veteranStatuses: any[]): EmployeeSectionsPersonal {
    let personal: EmployeeSectionsPersonal = new EmployeeSectionsPersonal();
    personal.profile = this.mapToProfile(data.profile.data, data.profile.metadata, veteranStatuses);
    personal.contacts = this.mapToContacts(data.contacts.data, data.contacts.metadata);
    personal.emergencyContacts = this.mapToEmergencyContacts(data.emergencyContacts.data, data.emergencyContacts.metadata);
    personal.licenses = this.mapToLicenses(data.licenses.data, data.licenses.metadata);
    personal.certifications = this.mapToCertifications(data.certifications.data, data.certifications.metadata);
    personal.warnings = this.mapToSubsectionWarnings(data.warnings.data, data.warnings.metadata);
    personal.physicals = this.mapToPhysicals(data.physicals.data, data.physicals.metadata);
    personal.documents = this.mapToDocuments(data.documents.data, data.documents.metadata);
    personal.backgroundChecks = this.mapToBackgroundChecks(data.backgroundChecks.data, data.backgroundChecks.metadata);
    personal.education = this.mapToEducations(data.education.data, data.education.metadata);
    personal.agencyCertificate = this.mapToAgencyCertificates(data.agencyCertificate.data, data.agencyCertificate.metadata);
    return personal;
  }
  public mapToAgencyCertificates(agencyCertificateDto, meta: FieldsMeta): AgencyCertificates {
   
    Assert.isNotNull(agencyCertificateDto, 'agencyCertificateDto');
    let agencyCertificate: AgencyCertificates = new AgencyCertificates();
    agencyCertificate.actions = this.metaMapService.mapActions(meta);
    agencyCertificate.agencyCertificate = this.mapToAgencyCertificate(agencyCertificateDto.agencyCertificates);
    agencyCertificate.fieldsMeta = meta;
    return agencyCertificate;
    
  }

  public mapToAgencyCertificate(agencyCertificateDto): AgencyCertificate[] {
    Assert.isNotNull(agencyCertificateDto, 'agencyCertificateDto');
    let certificate: AgencyCertificate [] = [];
    agencyCertificateDto.forEach(element => {      
      const agencyCertificate: AgencyCertificate = new AgencyCertificate();
      agencyCertificate.dnr = element.dnr;
      agencyCertificate.preferred =  element.preferred;
      agencyCertificate.startDate =  element.startDate;
      agencyCertificate.endDate =  element.endDate ; 
      agencyCertificate.reason = element.reason;
      agencyCertificate.user = element.createdBy;
      certificate.push(agencyCertificate)
    });
    return certificate;
  }

  public mapToBackgroundChecks(backgroundCheckDto: IEmployeeSectionsBackgroundChecks, meta: FieldsMeta): EmployeeSectionsBackgroundChecks {
    Assert.isNotNull(backgroundCheckDto, 'backgroundCheckDto');
    const backgroundChecks: EmployeeSectionsBackgroundChecks = new EmployeeSectionsBackgroundChecks();
    backgroundChecks.backgroundChecks = _.map(backgroundCheckDto.backgroundChecks, (backgroundCheckDto: IEmployeeSectionsBackgroundCheck) => {
      return this.mapToBackgroundCheck(backgroundCheckDto);
    });
    backgroundChecks.fieldsMeta = meta;
    return backgroundChecks;
  }
  public mapToBackgroundCheck(backgroundCheckDto: IEmployeeSectionsBackgroundCheck): EmployeeSectionsBackgroundCheck {
    Assert.isNotNull(backgroundCheckDto, 'backgroundCheckDto');
    const backgroundCheck: EmployeeSectionsBackgroundCheck = new EmployeeSectionsBackgroundCheck();
    backgroundCheck.recordId = backgroundCheckDto.recordId;
    backgroundCheck.subject = backgroundCheckDto.subject;
    backgroundCheck.description = backgroundCheckDto.description;
    backgroundCheck.addedBy = backgroundCheckDto.addedBy;
    backgroundCheck.addedOn = backgroundCheckDto.addedOn;
    backgroundCheck.lastModifiedBy = backgroundCheckDto.lastModifiedBy;
    backgroundCheck.lastModifiedOn = backgroundCheckDto.lastModifiedOn;
    return backgroundCheck;
  }

  public mapToEducations(backgroundCheckDto: IEmployeeSectionsEducations, meta: FieldsMeta): EmployeeSectionsEducations {
    Assert.isNotNull(backgroundCheckDto, 'backgroundCheckDto');
    const educations: EmployeeSectionsEducations = new EmployeeSectionsEducations();
    educations.education = _.map(backgroundCheckDto.education, (backgroundCheckDto: IEmployeeSectionsEducation) => {
      return this.mapToEducation(backgroundCheckDto);
    });
    educations.fieldsMeta = meta;
    return educations;
  }

  public mapToEducation(backgroundCheckDto: IEmployeeSectionsEducation): EmployeeSectionsEducation {
    Assert.isNotNull(backgroundCheckDto, 'backgroundCheckDto');
    const education: EmployeeSectionsEducation = new EmployeeSectionsEducation();
    education.sequenceId = backgroundCheckDto.sequenceId;
    education.degree = backgroundCheckDto.degree;
    education.schoolCollege = backgroundCheckDto.schoolCollege;
    education.graduationYear = backgroundCheckDto.graduationYear;

    return education;
  }

  public mapToCertifications(certificationsDto: IEmployeeSectionsCertifications, meta: FieldsMeta): EmployeeSectionsCertifications {
    Assert.isNotNull(certificationsDto, 'certidicationsDto');

    const certificates: EmployeeSectionsCertifications = new EmployeeSectionsCertifications();

    certificates.certificates = _.map(certificationsDto.certificates, (certificationDto: IEmployeeSectionsCertification) => {
      return this.mapToCertificate(certificationDto);
    });

    certificates.fieldsMeta = meta;

    return certificates;
  }

  public mapToCertificate(certificationDto: IEmployeeSectionsCertification): EmployeeSectionsCertification {
    Assert.isNotNull(certificationDto, 'certificationDto');

    const certificate: EmployeeSectionsCertification = new EmployeeSectionsCertification();

    certificate.id = certificationDto.id;
    certificate.code = certificationDto.code;
    certificate.name = certificationDto.name;
    certificate.description = certificationDto.description;
    certificate.certDate = certificationDto.certDate;
    certificate.endDate = certificationDto.endDate;
    certificate.license = certificationDto.license;
    certificate.confirmingBody = certificationDto.confirmingBody;
    certificate.licenseSequence = certificationDto.licenseSequence;

    return certificate;
  }

  public mapToSubsectionWarnings(dto: IEmployeeSubsectionWarnings, meta: FieldsMeta): EmployeeSubsectionWarnings {
    Assert.isNotNull(dto, 'certificationDto');

    const subsection = new EmployeeSubsectionWarnings();
    subsection.warnings = _.map(dto.warnings, w => this.empWarningMapService.mapToWarningBasic(w));
    if (_.isArray(meta.actions)) {
      subsection.actions = this.metaMapService.mapActionList(meta.actions);
    }

    return subsection;
  }

  public mapToWarningExtended(dto: IEmployeeWarningExtended): EmployeeWarningExtended {
    Assert.isNotNull(dto, 'dto');

    return this.empWarningMapService.mapToWarningExtended(dto);
  }

  public mapToDtoWarningExtended(warning: EmployeeWarningExtended): IEmployeeWarningExtended {
    Assert.isNotNull(warning, 'warning');

    return this.empWarningMapService.mapToDtoWarningExtended(warning);
  }
}
