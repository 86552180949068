import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import * as moment from 'moment';

export class LeaveRequestsNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public NavigateToLeaveRequests(): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(this.getUrlTree(), extras);
  }

  private getUrlTree(): UrlTree {
    return this.router.createUrlTree(['scheduler', 'leave_requests'], { relativeTo: this.route.pathFromRoot[1], queryParams: { hideLeftSideBar: true } });
  }
}
