<slx-spinner [show]="state.isLoading">
  <div class="info-container">
    <div class="date-label">
      {{startDate | amDateFormat: appConfig.dateFormat}} - {{endDate | amDateFormat: appConfig.dateFormat}}
    </div>
    <div class="emp-name-label">
      <label>{{ empInfo?.name }}</label>
    </div>
  </div>
  <div [class.indSchContent]="hasScheduleViewParams">
    <slx-individual-schedule-entries-editor [cycleStartDate]="startDate" [cycleEndDate]="endDate" [showEmployeeName]="false"
      (onHasChanges)="emitChanges($event)"
      [hasScheduleViewParams]="hasScheduleViewParams">
    </slx-individual-schedule-entries-editor>
  </div>
</slx-spinner>
