import { ElementRef, OnChanges, SimpleChanges, Renderer2, AfterViewChecked } from '@angular/core';
import * as _ from 'lodash';
import { appConfig } from '../../../app.config';
var MenuItemScrollDirective = /** @class */ (function () {
    function MenuItemScrollDirective(elementRef, renderer) {
        this.renderer = renderer;
        this.elementRef = elementRef;
    }
    MenuItemScrollDirective.prototype.ngOnChanges = function (changes) {
        if (changes['enabled']) {
            if (this.enabled) {
                this.process();
            }
            else {
                this.restore();
            }
        }
    };
    MenuItemScrollDirective.prototype.ngAfterViewChecked = function () {
        if (this.enabled) {
            this.process();
        }
    };
    MenuItemScrollDirective.prototype.onResize = function (event) {
        if (this.enabled) {
            this.process();
        }
    };
    MenuItemScrollDirective.prototype.process = function () {
        var winHeight = $(window).height();
        var topOffset = $(this.elementRef.nativeElement).offset().top;
        var height = $(this.elementRef.nativeElement).height();
        var maxHeight = _.max([winHeight - topOffset - 5, 0]);
        this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', maxHeight + "px");
        if ((height + 10) > maxHeight) {
            this.renderer.setStyle(this.elementRef.nativeElement, 'overflow', 'auto');
        }
    };
    MenuItemScrollDirective.prototype.restore = function () {
        this.renderer.setStyle(this.elementRef.nativeElement, 'max-height', 'initial');
        this.renderer.setStyle(this.elementRef.nativeElement, 'overflow', 'visible');
    };
    Object.defineProperty(MenuItemScrollDirective.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    return MenuItemScrollDirective;
}());
export { MenuItemScrollDirective };
