import { PostScheduleSettings } from './../../models/open-shift-management/post-schedule-settings';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import * as moment from 'moment';
import * as _ from 'lodash';

import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { UrlParamsService, ApiUtilService } from '../../../common/services/index';

import { schedulerConfig } from '../../scheduler.config';
import { Assert } from '../../../framework/assert/assert';
import { ResponseBody } from '../../../core/models/api/response-body';
import { ScheduleDailyDetailsMapService } from './schedule-daily-details-map.service';
import { ScheduleDailyDetails, IScheduleDailyDetails, ScheduleDailyDetailsRequest, ScheduleDailyDifferences, IScheduleDailyDifferences, ScheduleDailyDifferencesRequest } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class ScheduleDailyDetailsApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: ScheduleDailyDetailsMapService) {
  }

  public getScheduleDailyDetails(req: ScheduleDailyDetailsRequest): Promise<ScheduleDailyDetails[]> {
    Assert.isNotNull(req,'getScheduleDailyDetails request');
    const url: string = `${this.getScheduleDailyUrl(req.dateOn)}/${schedulerConfig.api.orglevel}/${req.orgLevelId}/${schedulerConfig.api.details}`;
    let params: any = {};
    if (req.positionId) {
      params.positionId = req.positionId;
    }
    if (req.shiftGroupId) {
      params.shiftGroupId = req.shiftGroupId;
    }
    if (req.shiftId) {
      params.shiftId = req.shiftId;
    }
    if (req.unitId) {
      params.unitId = req.unitId;
    }
    if (req.positionGroupId) {
      params.positionGroupId = req.positionGroupId;
    }
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, params);
    let promise: Promise<ScheduleDailyDetails[]> = this.apiUtilService.request<IScheduleDailyDetails[], Meta>(request)
      .then((response: ResponseBody<IScheduleDailyDetails[], Meta>) => this.mapService.mapToScheduleDailyDetailsRecords(response.data));
    return promise;
  }

  public getScheduleDailyDifferences(req: ScheduleDailyDifferencesRequest): Promise<ScheduleDailyDifferences[]> {
    Assert.isNotNull(req,'getScheduleDailyDifferences request');
    const url: string = `${this.getScheduleDailyUrl(req.dateOn)}/${schedulerConfig.api.orglevel}/${req.orgLevelId}/${schedulerConfig.api.differences}`;
    let params: any = {};
    if (req.positionId) {
      params.positionId = req.positionId;
    }
    if (req.shiftGroupId) {
      params.shiftGroupId = req.shiftGroupId;
    }
    if (req.shiftId) {
      params.shiftId = req.shiftId;
    }
    if (req.unitId) {
      params.unitId = req.unitId;
    }
    if (req.positionGroupId) {
      params.positionGroupId = req.positionGroupId;
    }
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, params);
    let promise: Promise<ScheduleDailyDifferences[]> = this.apiUtilService.request<IScheduleDailyDifferences[], Meta>(request)
      .then((response: ResponseBody<IScheduleDailyDifferences[], Meta>) => this.mapService.mapToScheduleDailyDifferencesRecords(response.data));
    return promise;
  }

  private getScheduleDailyUrl(date: Date): string {
    let dateOn: string = dateTimeUtils.convertToDtoString(date);
    const url: string = `${this.apiUtilService.getApiRoot()}/${schedulerConfig.api.schedule.root}/${dateOn}`;
    return url;
  }

}
