import { Injectable } from "@angular/core";
import { IConfigurationManagementService } from "../../utils/iconfiguration-management-service";
import { Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { ShiftGroupConfigContainer } from "../../models/shift-group/shift-group-config-container";
import { ManagementBaseService } from "./../../../../app/core/services";
import { ShiftGroup, UpdateShiftGroup } from "../../models";
import { dateTimeUtils } from "./../../../../app/common/utils";
import * as moment from "moment";
import { ShiftGroupMapService } from "./shift-group-map.service";
import { OrgLevel } from "./../../../../app/state-model/models";
import { mutableSelect, unsubscribeInService } from "./../../../../app/core/decorators";
import * as _ from "lodash";
import { organizationConfig } from "./../../../../app/organization/organization.config";
import { LookupApiService, LookupEntity } from "./../../../../app/organization";
import { ShiftGroupApiService } from "./shift-group-api.service";
import { NotificationsService } from "./../../../../app/core/components";

@Injectable()
export class ShiftGroupManagementService extends ManagementBaseService<ShiftGroupConfigContainer, any> implements IConfigurationManagementService {

    private m_container: ShiftGroupConfigContainer = {
        records: [],
        actions: []
    };

    public get container(): ShiftGroupConfigContainer {
        return this.m_container;
    }

    public editingItem: any;
    public isEditingNewItem: boolean;

    public onLoadStatus$: ReplaySubject<boolean>;
    public onLoaded$: ReplaySubject<any>;
    public onStateChanged$: ReplaySubject<any>;
    public changeNotify$: Subject<string>;

    public addItemCmd$: ReplaySubject<any>;
    public viewRefresh$: Subject<boolean>;
    public removeItemsCmd$: ReplaySubject<any>;

    public data: any;

    private currentOrgLevel: OrgLevel;

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;

    @unsubscribeInService()
    private orgLevelSubscription: Subscription;

    private m_groups: LookupEntity[];
    public get groups(): LookupEntity[] {
        return this.m_groups;
    }
    public state: {
        isLoading: boolean;
        bulkEditMode: boolean;
        canBulkEdit: boolean;
        canEdit: boolean;
        canAdd: boolean;
        canDelete: boolean;
        orgLevelChanged: boolean;
      };

    constructor(public shiftGroupApi: ShiftGroupApiService,public mapService: ShiftGroupMapService, public notificationService: NotificationsService) {
        super();
        this.removeItemsCmd$ = new ReplaySubject<any>();
        this.addItemCmd$ = new ReplaySubject<ShiftGroup>();
        this.viewRefresh$ = new Subject<boolean>();

        this.state = {
            isLoading: false,
            bulkEditMode: false,
            canBulkEdit: false,
            canEdit: false,
            canAdd: false,
            canDelete: false,
            orgLevelChanged: false
        };
    }

    public init() {

        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            if (_.isNumber(orgLevel.id)) {
                this.currentOrgLevel = orgLevel;
                this.viewRefresh$.next(false);
                this.onLoadStatusChanged(false);
                this.getItems();
            }
        });
    }

    public onSaveItem(result: { dataItem: any; isNew: boolean; }): void {
        // Functions to be done
        if (result.isNew) {
            this.addItem();
        }
        else {
            this.updateItem(result.dataItem);
        }
    };

    public onRemoveItem(item: any): void {
        // Functions to be done
        this.removeItem();
    };

    public onEditItem(item: any): void {
        this.editingItem = item;
    };

    public onCancelEditItem(): void {
        // Functions to be done
    };

    public setSelectedCount(count: number): void {
        // Functions to be done
    };

    public onAddItem(item: any): void {
        // Functions to be done
        this.addItem();
    };

    markAsDirty(): void {
        throw new Error("Method not implemented.");
    }

    protected async getItems(): Promise<void> {
        this.state.isLoading = true;
        await this.shiftGroupApi.getShiftGroupList(this.currentOrgLevel.id).then((data: any) => {
            this.m_container.records = data;
            setTimeout(() => {
                this.state.isLoading = false;
            }, 2000)
            this.onLoaded$.next(this.m_container);
        });
    }

    protected async addItem(): Promise<void> {
        await null;
    }

    protected async updateItem(dataItem: ShiftGroup): Promise<void> {
        this.state.isLoading = true;
        let updatedItem = this.mapService.reverseMap(dataItem);
        await this.shiftGroupApi.updateShiftGroup(this.currentOrgLevel.id, updatedItem).then((data: any) => {
            this.notificationService.success('Shift Group Updated', `${data.name} Shift Group Updated Successfully`);
            this.getItems();
        });
    }

    protected async removeItem(): Promise<void> {
        await null;
    }

}