import { PunchesRollupRecord } from './../../models/punches/punches-rollup-record';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { FieldsMeta } from '../../../core/models/index';
import { LookupMapService, EmployeeDefinitionsMapService, ScheduleDefinitionsMapService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';

import {
  EmployeeDefinition, Position, IShift, Shift
} from '../../../organization/models/index';

import {
  IEmployeeDailyPunches, EmployeeDailyPunches, EmployeeDailyPunchesHeader, IEmployeeDailyPunchesHeader,
  IDailyPunchesShift, DailyPunchesShift,
  IEmployeesPunchesContainer, EmployeesPunchesContainer, IClosingPunchAttributes, ClosingPunchAttributes, LinePunch, ILinePunch,
  IEmployeesPunchesHeaderContainer, EmployeesPunchesHeaderContainer,
  EmployeeDailyPunchesStatus, PunchLogEntry, IPunchLogEntry, PunchStatus, PunchSource, IPunchesRollupRecord, DailyLinePunchActions
} from '../../models/index';

@Injectable()
export class PunchesMapService {
  constructor(
    private lookupMapService: LookupMapService,
    private employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private scheduleDefinitionsMapService: ScheduleDefinitionsMapService
  ) {
  }

  public mapDailyPunchesShifts(dtos: IDailyPunchesShift[]): DailyPunchesShift[] {
    let shifts: DailyPunchesShift[] = _.map(dtos, (dto: IDailyPunchesShift) => this.mapDailyPunchesShift(dto));
    return shifts;
  }
  public mapDailyPunchesShiftsDto(data: DailyPunchesShift[]): IDailyPunchesShift[] {
    return _.map(data, (shift: DailyPunchesShift) => this.mapDailyPunchesShiftDto(shift));
  }

  public mapDailyPunchesShift(dto: IDailyPunchesShift): DailyPunchesShift {
    let dailyShift: DailyPunchesShift = new DailyPunchesShift();
    dailyShift.shift = dto.shift ? this.lookupMapService.mapShift(dto.shift) : null;
    dailyShift.unit = dto.unit ? this.lookupMapService.mapLocationUnit(dto.unit) : null;
    dailyShift.absence = dto.absence ? this.lookupMapService.mapScheduleAbsence(dto.absence) : null;
    dailyShift.duration = dateTimeUtils.convertFromDtoDurationString(dto.duration);
    dailyShift.comment = dto.comment;
    dailyShift.taAbsence = dto.taAbsence ? this.lookupMapService.mapTAAbsence(dto.taAbsence) : null;
    dailyShift.position = dto.position ? this.lookupMapService.mapPosition(dto.position) : null;
    return dailyShift;
  }

  public mapDailyPunchesShiftDto(data: DailyPunchesShift): IDailyPunchesShift {
    let dto: IDailyPunchesShift = {
      shift: data.shift ? this.lookupMapService.mapShiftDto(data.shift) : null,
      unit: data.unit ? this.lookupMapService.mapLocationUnitDto(data.unit) : null,
      absence: data.absence ? this.lookupMapService.mapScheduleAbsenceDto(data.absence) : null,
      duration: dateTimeUtils.convertToDtoDurationString(data.duration),
      comment: data.comment,
      taAbsence: data.taAbsence ? this.lookupMapService.mapTAAbsenceDto(data.taAbsence) : null,
      position: data.position ? this.lookupMapService.mapPositionDto(data.position) : null
    };
    return dto;
  }

  public mapClosingPunchAttributes(pairDto: IClosingPunchAttributes): ClosingPunchAttributes {
    let attributes: ClosingPunchAttributes = new ClosingPunchAttributes();
    attributes.interval = dateTimeUtils.convertFromDtoDurationNumber(pairDto.interval, 'h');
    attributes.roundedInterval = dateTimeUtils.convertFromDtoDurationNumber(pairDto.roundedInterval, 'h');
    attributes.position = pairDto.position ? this.lookupMapService.mapPosition(pairDto.position) : null;
    attributes.schedule = pairDto.schedule ? this.scheduleDefinitionsMapService.mapScheduleDefinition(pairDto.schedule) : null;
    attributes.absence = pairDto.absence;
    return attributes;
  }

  public mapClosingPunchAttributesDto(data: ClosingPunchAttributes): IClosingPunchAttributes {
    let dto: IClosingPunchAttributes = {
      interval: dateTimeUtils.convertToDtoDurationNumber(data.interval, 'h'),
      roundedInterval: dateTimeUtils.convertToDtoDurationNumber(data.roundedInterval, 'h'),
      position: data.position ? this.lookupMapService.mapPositionDto(data.position) : null,
      schedule: data.schedule ? this.scheduleDefinitionsMapService.mapScheduleDefinitionDto(data.schedule) : null,
      absence: data.absence,
    };
    return dto;
  }

  public mapLinePunch(dto: ILinePunch): LinePunch {
    let linePunch: LinePunch = new LinePunch();
    linePunch.punchStatus = this.mapStatus(dto.punchStatus);
    linePunch.punchSource = this.mapSource(dto.punchSource);
    linePunch.isDeleted = dto.isDeleted;
    linePunch.inService = dto.inService;
    linePunch.roundedTime = dateTimeUtils.convertFromDtoString(dto.roundedTime);
    linePunch.punchDate = dateTimeUtils.convertFromDtoString(dto.punchDate);
    linePunch.type = this.lookupMapService.mapPunchType(dto.type);
    linePunch.time = dateTimeUtils.convertFromDtoDateTimeString(dto.time);
    linePunch.timeclock = dto.timeclock ? this.lookupMapService.mapTimeclockDefinition(dto.timeclock) : null;
    linePunch.comment = dto.comment;
    linePunch.closingPunch = dto.closingPunch ? this.mapClosingPunchAttributes(dto.closingPunch) : null;
    if (linePunch.closingPunch && linePunch.punchStatus !== PunchStatus.Pending) {
      linePunch.position = linePunch.closingPunch.position;
    }
    linePunch.show = !dto.isDeleted;
    linePunch.isExempt = dto.isExempt;
    const lat = parseFloat(dto.latitude as any);
    const lng = parseFloat(dto.longitude as any);
    linePunch.latitude = _.isFinite(lat) ? lat : null;
    linePunch.longitude = _.isFinite(lng) ? lng : null;
    linePunch.resetDirty();
    return linePunch;
  }

  public mapLinePunchDto(data: LinePunch): ILinePunch {
    let dto: ILinePunch = {
      inService: data.inService,
      roundedTime: dateTimeUtils.convertToDtoString(data.roundedTime),
      punchDate: dateTimeUtils.convertToDtoString(data.punchDate),
      isEdited: data.isEditedAndValidated,
      isDeleted: data.isDeleted,
      type: this.lookupMapService.mapPunchTypeDto(data.type),
      time: dateTimeUtils.convertToDtoDateTimeString(data.time),
      timeclock: data.timeclock ? this.lookupMapService.mapTimeclockDefinitionDto(data.timeclock) : null,
      comment: data.comment,
      closingPunch: data.closingPunch ? this.mapClosingPunchAttributesDto(data.closingPunch) : null,
      punchSource: data.punchSource,
      punchStatus: data.punchStatus,
      isExempt: data.isExempt
    };
    if (data.canBeClosing) {
      if (!dto.closingPunch) {
        dto.closingPunch = {
          interval: 0,
          roundedInterval: 0,
          position: null,
          schedule: null,
          absence: null,
        };
      }
    }
    if(dto.closingPunch) {
      dto.closingPunch.position = data.position && data.position.id !== 0 ? this.lookupMapService.mapPositionDto(data.position) : null;
    }
    return dto;
  }

  public mapLinePanches(dtos: ILinePunch[]): LinePunch[] {
    return _.map(dtos, (punch: ILinePunch) => this.mapLinePunch(punch));
  }

  public mapLinePanchesDto(data: LinePunch[]): ILinePunch[] {
    let dtos: ILinePunch[] = [];
    _.forEach(data, (punch: LinePunch) => {
      dtos.push(this.mapLinePunchDto(punch));
    });
    return dtos;
    //return _.map(data, (punch: LinePunch) => this.mapLinePunchDto(punch));
  }

  public mapEmployeeDailyPunchesStatus(str: string): EmployeeDailyPunchesStatus {
    switch (str) {
      case 'Missing Punches':
        return EmployeeDailyPunchesStatus.missing;
      case 'Invalid Punches':
        return EmployeeDailyPunchesStatus.invalid;
      case 'Scheduled Only':
        return EmployeeDailyPunchesStatus.scheduledonly;
      case 'No Punches':
        return EmployeeDailyPunchesStatus.nopunches;
      case 'Invalid Login':
        return EmployeeDailyPunchesStatus.invalidlogin;
    }
    return EmployeeDailyPunchesStatus.valid;
  }

  public mapStatus(str: string): PunchStatus {
    switch (str) {
      case 'Valid':
        return PunchStatus.Valid;
      case 'Invalid':
        return PunchStatus.Invalid;
      case 'Pending':
        return PunchStatus.Pending;
      case 'Edited':
        return PunchStatus.Edited;
      case 'Deleted':
        return PunchStatus.Deleted;
      case 'InvalidLogin':
        return PunchStatus.InvalidLogin;
    }
    return PunchStatus.Unknown;
  }

  public mapSource(str: string): PunchSource {
    switch (str) {
      case 'Timeclock':
        return PunchSource.Timeclock;
      case 'Schedule':
        return PunchSource.Schedule;
      case 'Request':
        return PunchSource.Request;
      case 'Manual':
        return PunchSource.Manual;
      case 'Geo':
        return PunchSource.Geo;
    }
    return PunchSource.Unknown;
  }

  public mapEmployeeDailyPunches(dto: IEmployeeDailyPunches): EmployeeDailyPunches {
    let empPunches: EmployeeDailyPunches = new EmployeeDailyPunches();
    empPunches.header = this.mapEmployeeDailyPunchesHeader(dto.header);
    empPunches.punches = this.mapLinePanches(dto.punches);
    let sorted: LinePunch[] = _.orderBy(empPunches.punches, (p: LinePunch) => moment(p.time).unix, 'asc');
    empPunches.firstIn = _.find(sorted, (p: LinePunch) => p.type && (p.punchStatus === PunchStatus.Valid || p.punchStatus === PunchStatus.Edited) && p.type.isIn);
    empPunches.lastOut = _.findLast(sorted, (p: LinePunch) => p.type && (p.punchStatus === PunchStatus.Valid || p.punchStatus === PunchStatus.Edited) && p.type.isOut);

    // TODO Refactoring DailyTimecarrd model and move this code to mapLinePanches function
    let lastPunch: LinePunch = null;
    let posAssigned = false;
    const firstShift = _.first(empPunches.header.scheduledShifts);
    _.forEach(empPunches.punches, (p: LinePunch) => {
      if (p.closingPunch) {
        p.positionsString = empPunches.header.scheduledPositionsString;
        posAssigned = true;
        if (!p.closingPunch.position && firstShift) {
          p.closingPunch.position = firstShift.position;
          p.position = firstShift.position;
        }
      }
      lastPunch = p;
    });
    if (!posAssigned && lastPunch) {
      lastPunch.positionsString = empPunches.header.scheduledPositionsString;
    }
    return empPunches;
  }

  public transformToEmployeeDailyPunches(h: EmployeeDailyPunchesHeader): EmployeeDailyPunches {
    let empPunches: EmployeeDailyPunches = new EmployeeDailyPunches();
    empPunches.header = h;
    empPunches.punches = null;
    return empPunches;
  }

  public mapEmployeeDailyPunchesHeader(dto: IEmployeeDailyPunchesHeader): EmployeeDailyPunchesHeader {
    let empPunches: EmployeeDailyPunchesHeader = new EmployeeDailyPunchesHeader();
    empPunches.date = dateTimeUtils.convertFromDtoString(dto.date);
    empPunches.maxPunchDate = dateTimeUtils.convertFromDtoString(dto.maxPunchDate);
    empPunches.minPunchDate = dateTimeUtils.convertFromDtoString(dto.minPunchDate);
    empPunches.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    empPunches.position = dto.position ? this.lookupMapService.mapPosition(dto.position) : null;
    empPunches.scheduledShifts = this.mapDailyPunchesShifts(dto.scheduledShifts);
    empPunches.displayTag = dto.displayTag;
    empPunches.isOrganizationPayrollLocked = dto.isOrganizationPayrollLocked;
    empPunches.isTimecardLocked = dto.isTimecardLocked;
    empPunches.isPayCycleLocked = dto.isPayCycleLocked;
    empPunches.isTimecardApproved = dto.isTimecardApproved;
    empPunches.comment = dto.comment;
    empPunches.hasLicenseRestriction = dto.checkLicenseRestriction!==undefined && dto.checkLicenseRestriction === 0;
    empPunches.isPaidRestBreakEnabled = dto.isPaidRestBreakEnabled !==undefined ? dto.isPaidRestBreakEnabled : false;
    empPunches.isAfterTermination= dto.employee.dateTerminated && moment(dto.date).isAfter(dto.employee.dateTerminated);


    let strShifts: string[] = [];
    let strUnits: string[] = [];
    let strPositions: string[] = [];
    let strTaAbsences: string[] = [];
    let strScheduleStarts: string[] = [];
    let strScheduleEnds: string[] = [];
    let strScheduleTimes: string[] = [];
    let strScheduleComments: string[] = [];
    _.forEach(empPunches.scheduledShifts, (item: DailyPunchesShift) => {
      if (item.shift) strShifts.push(item.shift.name);
      if (item.unit) {
        strUnits.push(item.unit.name);
      } else if (item.absence) {
        strUnits.push(item.absence.code);
      }
      if (item.position) strPositions.push(item.position.name);
      if (item.absence) strTaAbsences.push(item.absence.code);
      if (item.taAbsence && !_.includes(strTaAbsences, item.taAbsence.code)) strTaAbsences.push(item.taAbsence.code);
      if (item.shift) {
        if (item.shift.start) strScheduleStarts.push(moment(item.shift.start).format(appConfig.timeFormat));
        if (item.shift.end) strScheduleEnds.push(moment(item.shift.end).format(appConfig.timeFormat));
      }

      if (item.duration) strScheduleTimes.push(dateTimeUtils.getDurationTotalHours(item.duration).toFixed(2));
      if (!_.isNull(item.comment) && !_.isUndefined(item.comment) && !_.isEmpty(item.comment)) {
        strScheduleComments.push(item.comment);
      }
    });
    empPunches.scheduledShiftsString = _.join(strShifts, ', ');
    empPunches.scheduledPositionsString = _.join(strPositions, ', ');
    empPunches.scheduledUnitsString = _.join(strUnits, ', ');
    empPunches.scheduledAbsencesString = _.join(strTaAbsences, ', ');
    empPunches.scheduledStartsString = _.join(strScheduleStarts, ', ');
    empPunches.scheduledEndsString = _.join(strScheduleEnds, ', ');
    empPunches.scheduledTimesString = _.join(strScheduleTimes, ', ');
    if (!empPunches.scheduledTimesString) {
      empPunches.scheduledTimesString = '0.00';
    }
    empPunches.scheduledCommentsString = _.join(strScheduleComments, ', ');

    empPunches.workTime = dateTimeUtils.convertFromDtoDurationString(dto.workTime);
    empPunches.paidTime = dateTimeUtils.convertFromDtoDurationString(dto.paidTime);
    empPunches.badgeId = dto.badgeId;
    empPunches.organization = dto.organization ? this.lookupMapService.mapOrganization(dto.organization) : null;
    empPunches.department = dto.department ? this.lookupMapService.mapDepartment(dto.department) : null;

    empPunches.uniqueKey = `${moment(dto.date)}${empPunches.employee.id}`;
    empPunches.status = this.mapEmployeeDailyPunchesStatus(empPunches.displayTag);
    empPunches.firstIn = dateTimeUtils.convertFromDtoDateTimeString(dto.firstIn);
    empPunches.lastOut = dateTimeUtils.convertFromDtoDateTimeString(dto.lastOut);
    empPunches.state = dto.state;
    return empPunches;
  }

  public mapEmployeeDailyPunchesDto(data: EmployeeDailyPunches): IEmployeeDailyPunches {
    let dto: IEmployeeDailyPunches = {
      header: this.mapEmployeeDailyPunchesHeaderDto(data.header),
      punches: this.mapLinePanchesDto(data.punches)
    };
    return dto;
  }

  public mapEmployeeDailyPunchesHeaderDto(data: EmployeeDailyPunchesHeader): IEmployeeDailyPunchesHeader {
    let dto: IEmployeeDailyPunchesHeader = {
      date: dateTimeUtils.convertToDtoString(data.date),
      position: data.position ? this.lookupMapService.mapPositionDto(data.position) : null,
      scheduledShifts: this.mapDailyPunchesShiftsDto(data.scheduledShifts),
      maxPunchDate: dateTimeUtils.convertToDtoString(data.maxPunchDate),
      minPunchDate: dateTimeUtils.convertToDtoString(data.minPunchDate),
      employee: this.employeeDefinitionsMapService.mapToEmployeeDefinitionDto(data.employee),
      displayTag: data.displayTag,
      state: data.state,
      workTime: data.workTime ? dateTimeUtils.convertToDtoDurationString(data.workTime) : null,
      paidTime: data.paidTime ? dateTimeUtils.convertToDtoDurationString(data.paidTime) : null,
      badgeId: data.badgeId,
      organization: data.organization ? this.lookupMapService.mapOrganizationDto(data.organization) : null,
      department: data.department ? this.lookupMapService.mapDepartmentDto(data.department) : null,
      firstIn: dateTimeUtils.convertToDtoDateTimeString(data.firstIn),
      lastOut: dateTimeUtils.convertToDtoDateTimeString(data.lastOut),
      isPayCycleLocked: data.isPayCycleLocked,
      isOrganizationPayrollLocked: data.isOrganizationPayrollLocked,
      isTimecardLocked: data.isTimecardLocked,
      isTimecardApproved: data.isTimecardApproved,
      comment: data.comment,
      checkLicenseRestriction: undefined,
      isPaidRestBreakEnabled: undefined
    };
    return dto;
  }

  public mapEmployeeDailyPunchesList(dtos: IEmployeeDailyPunches[]): EmployeeDailyPunches[] {
    return _.map(dtos, (punch: IEmployeeDailyPunches) => this.mapEmployeeDailyPunches(punch));
  }
  public mapEmployeeDailyPunchesHeaderList(dtos: IEmployeeDailyPunchesHeader[]): EmployeeDailyPunchesHeader[] {
    return _.map(dtos, (punch: IEmployeeDailyPunchesHeader) => this.mapEmployeeDailyPunchesHeader(punch));
  }

  public mapEmployeeDailyPunchesListDto(datas: EmployeeDailyPunches[]): IEmployeeDailyPunches[] {
    return _.map(datas, (punch: EmployeeDailyPunches) => this.mapEmployeeDailyPunchesDto(punch));
  }

  public mapEmployeesPunchesContainer(dto: IEmployeesPunchesContainer): EmployeesPunchesContainer {
    let container: EmployeesPunchesContainer = new EmployeesPunchesContainer();
    container.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    container.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    container.status = dto.status;
    container.orgLevelId = dto.orgLevelId;
    container.entities = this.mapEmployeeDailyPunchesList(dto.entities);
    return container;
  }

  public mapEmployeesPunchesHeaderContainer(dto: IEmployeesPunchesHeaderContainer, meta: FieldsMeta): EmployeesPunchesHeaderContainer {
    let container: EmployeesPunchesHeaderContainer = new EmployeesPunchesHeaderContainer();
    container.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    container.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    container.status = dto.status;
    container.orgLevelId = dto.orgLevelId;
    container.entities = this.mapEmployeeDailyPunchesHeaderList(dto.entities);

    const actions: string[] = _.get(meta, 'actions');
    const index: number = _.findIndex(actions, (action: string) => action === DailyLinePunchActions.EditTimecardsKey);
    container.actions = new DailyLinePunchActions(index !== -1);

    return container;
  }

  public transformContainer(c: EmployeesPunchesHeaderContainer): EmployeesPunchesContainer {
    let empPunches: EmployeesPunchesContainer = new EmployeesPunchesContainer();
    empPunches.startDate = c.startDate;
    empPunches.endDate = c.endDate;
    empPunches.entities = _.map(c.entities, (header: EmployeeDailyPunchesHeader) => this.transformToEmployeeDailyPunches(header));
    empPunches.orgLevelId = c.orgLevelId;
    empPunches.status = c.status;
    empPunches.actions = c.actions;
    return empPunches;
  }

  public mapPunchLogEntryList(dtos: IPunchLogEntry[]): PunchLogEntry[] {
    return _.map(dtos, (punch: IPunchLogEntry) => this.mapPunchLogEntry(punch));
  }

  public mapPunchLogEntry(dto: IPunchLogEntry): PunchLogEntry {
    let data: PunchLogEntry = new PunchLogEntry();
    data.id = dto.id;
    data.punchTime = dateTimeUtils.convertFromDtoString(dto.punchTime);
    data.timeclock = dto.timeclock;
    data.type = dto.type;
    data.errors = dto.errors;
    data.comments = dto.comments;
    data.isReverse = dto.isReverse;
    return data;
  }

  public mapPunchesRollupRecords(dtos: IPunchesRollupRecord[]): PunchesRollupRecord[] {
    let records: PunchesRollupRecord[];
    records = _.map(dtos, (dto: IPunchesRollupRecord) => {
      return this.mapPunchesRollupRecord(dto);
    });
    return records;
  }

  public mapPunchesRollupRecord(dto: IPunchesRollupRecord): PunchesRollupRecord {
    let record: PunchesRollupRecord = new PunchesRollupRecord();
    record.workOrganization = dto.workOrganization ? this.lookupMapService.mapOrganization(dto.workOrganization) : null;
    record.organization = dto.organization ? this.lookupMapService.mapOrganization(dto.organization) : null;
    record.department = dto.department ? this.lookupMapService.mapDepartment(dto.department) : null;
    record.payCycle = dto.payCycle ? this.lookupMapService.mapPayCycle(dto.payCycle) : null;
    record.approvedTimecards = dto.approvedTimecardsPercentage;
    record.invalidPunches = dto.invalidPunchesCount;
    record.validTimecards = dto.validTimecardsCount;
    record.missingPunches = dto.missingPunchesCount;
    record.pendingEmpRequest = dto.pendingEmpRequestsCount;
    record.scheduledPunches = dto.scheduledNoShowCount;
    return record;
  }
}

