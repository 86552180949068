import { Component, Input, ViewChild, NgZone, Host } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { AbstractControl, NgForm } from '@angular/forms';
import { EmployeeSectionsBase, TelepunchSubsection, PhonePunchRestrictions, EmployeeSectionsTemporalModel } from '../../../models';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import * as _ from 'lodash';
import { EmployeeSectionsEmploymentApiService } from '../../../services';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-telepunch',
  templateUrl: './employee-sections-telepunch.component.html',
  styleUrls: ['./employee-sections-telepunch.component.scss']
})
export class EmployeeSectionsTelepunchComponent extends EmployeeSectionsBasicComponent {

  public canEdit: boolean;
  @Input() public employeeId: number;
  @Input()
  public set employeeSectionsTelepunch(value: TelepunchSubsection) {
    this.m_employeeSectionsTelepunch = value;
    this.fillValues();
  }

  public get employeeSectionsTelepunch(): TelepunchSubsection {
    return this.m_employeeSectionsTelepunch;
  }

  private m_employeeSectionsTelepunch: TelepunchSubsection = new TelepunchSubsection();

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }
  @ViewChild('form', { static: false })
  public ngForm: NgForm;

  phonePunchRestrictions: PhonePunchRestrictions;
  restrictionsLookup: PhonePunchRestrictions[] = [];
  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;

  constructor(
    employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    ngZone: NgZone,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
  ) {
    super(decorator, ngZone);
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.m_employeeSectionsTelepunch;
  }

  fillValues(): void {
    if (this.employeeSectionsTelepunch) {
      this.restrictionsLookup = this.employeeSectionsTelepunch.phonepunchRestrictions.fieldValue;
      let list = _.filter(this.employeeSectionsTelepunch.phonepunchRestrictions.fieldValue, item => item.id === this.employeeSectionsTelepunch.restrictionId);
      this.phonePunchRestrictions = list[0];
    }
  }

  public loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmployeeTelepunch(this.employeeId)
      .then((employeeTelepunch: TelepunchSubsection) => {
        this.employeeSectionsTelepunch = employeeTelepunch;
        this.stopProgress();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  public updateData(): void {
    if(this.employeeSectionsTelepunch && !this.employeeSectionsTelepunch.phonepunchEnabled.fieldValue){
      this.phonePunchRestrictions = null;
    }
  }

  public doSave(): void {
    if(this.employeeSectionsTelepunch && !this.employeeSectionsTelepunch.phonepunchEnabled.fieldValue){
      this.phonePunchRestrictions = new PhonePunchRestrictions();
      this.phonePunchRestrictions.id = "";
      this.phonePunchRestrictions.name = "";
    }
    this.employeeSectionsEmploymentApiService.setEmployeeTelepunch(this.employeeId, this.phonePunchRestrictions).then((response: any) => {
      this.onActionComplete(true);
    }).catch((reason: any) => {
      this.onActionError(reason);
    });
  }
}
