import * as moment from 'moment';

import { IDatedObject } from './idated-object';

export class DailyData<T extends IDatedObject> {
  public startOfDay: moment.Moment;
  public dayNumber: number;
  public dataList: T[];
  public get firstOrDefault(): IDatedObject {
    if (this.dataList && this.dataList.length > 0) {
      return this.dataList[0];
    }
    return undefined;
  }
  public addData(data: T): void {
    if (!this.dataList) {
      this.dataList = [];
    }
    this.dataList.push(data);
  }
  public get data(): T {
    return this.dataList && this.dataList.length > 0 ? this.dataList[0] : null;
  }
}
