import { ElementRef, Directive, Input, OnInit } from '@angular/core';
import { Assert } from '../../framework/index';
import { appMessages, ValidationMessage } from '../../app.messages';
import * as _ from 'lodash';

@Directive({
  selector: '[errorMessage]'
})
export class ErrorMessageDirective implements OnInit {
  @Input()
  public for: string;

  private element: ElementRef;

  constructor(element: ElementRef) {
    Assert.isNotNull(element, 'element');

    this.element = element;
  }

  public ngOnInit(): void {
    this.initText();
  }

  public hide(): void {
    $(this.element.nativeElement).hide();
  }

  public show(): void {
    $(this.element.nativeElement).show();
  }

  public getText(): string {
    return $(this.element.nativeElement).text();
  }

  public setText(msg: string): void {
    $(this.element.nativeElement).text(msg);
  }

  private initText(): void {
    if(this.getText()) {
      return;
    }
    _.each(appMessages.validation, (value: ValidationMessage) => {
      if (value.type === this.for) {
        this.setText(value.message);
      }
    });
  }
}
