import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { ResponseBody, Meta } from '../../../core/models/index';
import { schedulerConfig } from '../../scheduler.config';
import { AvailShifts, IAvailShifts, IScheduleRotationTemplate, IScheduleRotationPermisions, INoRotationEmployeeList, TerminateRequest, SaveRotationData, EmployeesGenerateRotation, AssignEmpToEmptySlot, EmployeeScheduledData, FutureValidationShift, FilterRotationOptions, IFilterRotationOptions } from '../../models/schedule-rotation/schedule-rotation-template.model';
import { dateTimeUtils } from './../../../../app/common/utils';
import { ScheduledRotationMapService } from './scheduled-rotation-map.service';
import { filterOptionsRecord } from '../../models/schedule-rotation/schedule-rotation-template-filter';

@Injectable()
export class ScheduleRotationApiService {
    constructor(private apiUtilService: ApiUtilService, private urlParamsService: UrlParamsService, public mapService: ScheduledRotationMapService, private http: HttpClient) {
        this.apiUtilService = apiUtilService;
    }

    public getEmployeeRotation(orgId: number, startDate: any, endDate: any): Promise<IScheduleRotationTemplate[]> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.employeerotation}`;
        const request = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        let promise: any = this.apiUtilService.request<IScheduleRotationTemplate[], Meta>(request)
            .then((response: ResponseBody<IScheduleRotationTemplate[], Meta>) => this.mapService.mapEmployeeRotation(response.data, response.meta));
        return promise;
    }

    public saveEmployeeRotation(orgId: number, startDate: any, endDate: any, saveResultOutput: EmployeeScheduledData[]): Promise<IScheduleRotationTemplate[]> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.saveemployeerotation}?orgLevelId=${orgId}&startDate=${startDate}&endDate=${endDate}`;
        const request = this.urlParamsService.createPostRequest(url, saveResultOutput);
        let promise: any = this.apiUtilService.request<IScheduleRotationTemplate[], Meta>(request)
            .then((response: ResponseBody<IScheduleRotationTemplate[], Meta>) => this.mapService.mapEmployeeRotation(response.data, response.meta));
        return promise;
    }

    public getScheduleRotationAvailShifts(orgLevelId: number, startDate: any, endDate: any): Promise<AvailShifts[]> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.availShifts.availShiftsList}`;
        const request = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgLevelId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        let promise: any = this.apiUtilService
            .request<IAvailShifts[], Meta>(request)
            .then((response: ResponseBody<IAvailShifts[], Meta>) => this.mapService.mapScheduleRotationAvailShifts(response.data, response.meta));
        return promise;
    }

    public getScheduleRotationPermission(orgId: number): Promise<IScheduleRotationPermisions> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.permissions}`;
        let request: HttpRequest<any> = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ orgLevelId: orgId })
        });
        let promise: Promise<IScheduleRotationPermisions> = this.apiUtilService.request<IScheduleRotationPermisions, Meta>(request)
            .then((response: ResponseBody<IScheduleRotationPermisions, Meta>) => { return response.data; });
        return promise;
    }

    public getNoRotationEmployeeList(orgId: number, startDate: any, endDate: any): Promise<INoRotationEmployeeList[]> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.noRotationEmployeeList}`;
        const request = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });
        let promise: any = this.apiUtilService.request<INoRotationEmployeeList[], Meta>(request)
            .then((response: ResponseBody<INoRotationEmployeeList[], Meta>) => response.data);
        return promise;
    }

    public generateScheduleRotation(empRecord: EmployeesGenerateRotation): Promise<EmployeesGenerateRotation> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.generate.root}`;
        const request = this.urlParamsService.createPostRequest(url, empRecord);
        let promise: any = this.apiUtilService.request<any, Meta>(request)
            .then((response: ResponseBody<any, Meta>) => response);
        return promise;
    }

    public assignTerminatedSlotToEmployee(empRecord: AssignEmpToEmptySlot[]): Promise<AssignEmpToEmptySlot[]> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.assignTerminatedSlotEmployee.root}`;
        const request = this.urlParamsService.createPutRequest(url, empRecord);
        let promise: any = this.apiUtilService.request<any, Meta>(request)
            .then((response: ResponseBody<any, Meta>) => response);
        return promise;
    }

    public futureDateValidation(shiftDetail: FutureValidationShift): Promise<FutureValidationShift> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.futureDatesValidations.root}`;
        const request = this.urlParamsService.createPostRequest(url, shiftDetail);
        let promise: any = this.apiUtilService.request<any, Meta>(request)
            .then((response: ResponseBody<any, Meta>) => this.mapService.mapFutureDateValidationResult(response.data));
        return promise;
    }

    public removeSlotRotations(slotId: number): Promise<void> {
        const url: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.deleteSlotRotation}`;
        const request = this.urlParamsService.createDeleteRequest(url, { slotId: slotId });
        let promise: any = this.apiUtilService.request<any, Meta>(request)
            .then((response: ResponseBody<any, Meta>) => response);
        return promise;
    }

    public getSRTDefaultFilterOption(): FilterRotationOptions {
        let filterOptionData = filterOptionsRecord;
        let data = this.mapService.mapFilterRecordOptions(filterOptionData);
        return data;
    }

    public saveEmpRotationAndEmptySlotRotation(orgId: number, startDate: any, endDate: any, saveResultOutput: EmployeeScheduledData[], empRecord: AssignEmpToEmptySlot[]): Promise<any[]> {
        const saveEmpRotationUrl: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.saveemployeerotation}?orgLevelId=${orgId}&startDate=${startDate}&endDate=${endDate}`;
        const saveEmpRotationRequest = this.urlParamsService.createPostRequest(saveEmpRotationUrl, saveResultOutput);
        const saveEmptySlotUrl: string = this.apiUtilService.apiRoot`${schedulerConfig.api.root}/${schedulerConfig.api.schedulerotation.root}/${schedulerConfig.api.schedulerotation.assignTerminatedSlotEmployee.root}`;
        const saveEmptySlotRequest = this.urlParamsService.createPutRequest(saveEmptySlotUrl, empRecord);
        let saveEmpRotationPromise: any = this.apiUtilService.request<IScheduleRotationTemplate[], Meta>(saveEmpRotationRequest);
        let saveEmptySlotPromise: any = this.apiUtilService.request<any, Meta>(saveEmptySlotRequest);
        return Promise.all([saveEmpRotationPromise, saveEmptySlotPromise])
            .then((values: any[]) => {
                return this.mapService.mapEmployeeRotation(values[0].data, values[0].meta);
            });
    }
}
