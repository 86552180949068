import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { TreeModel } from '../../tree/models/tree.model';
import { TreeNodeModel } from '../../tree/models/index';
import { LogicalExpressionModel } from './logical-expression.model';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ComparisionOperator } from './logical-expression.model';
var QueryModel = /** @class */ (function (_super) {
    tslib_1.__extends(QueryModel, _super);
    function QueryModel(mapService) {
        var _this = _super.call(this) || this;
        _this.categories = [];
        _this.allowNested = false;
        //travesal of tree  with brute force can be O(n^2 + n) or O(n^2) atleast..
        // so tracking flattened list of nodes for some operations;
        _this.flatNodesList = [];
        _this.mapService = mapService;
        _this.showRoot = false;
        var rootExpression = new LogicalExpressionModel();
        var firstChildExpression = new LogicalExpressionModel();
        rootExpression.children.push(firstChildExpression);
        var firstChildFormGroup = _this.createNodeFormGroup();
        var firstChildNodeData = new NodeData(firstChildExpression, firstChildFormGroup, _this.mapService.createLogicExpressionDTO());
        var fisrtChildNode = new TreeNodeModel(firstChildNodeData, _this.root, _this);
        _this.root.children.push(fisrtChildNode);
        _this.formChildren = new FormArray([firstChildFormGroup]);
        var rootForm = _this.createNodeFormGroup(true);
        rootForm.addControl('children', _this.formChildren);
        var rootDTO = _this.mapService.createLogicExpressionDTO();
        rootDTO.ChildExpressions = [firstChildNodeData.dto];
        _this.root.data = new NodeData(rootExpression, rootForm, rootDTO);
        _this.rootForm = rootForm;
        _this.flatNodesList.push(_this.root);
        _this.flatNodesList.push(fisrtChildNode);
        return _this;
    }
    QueryModel.getDefaultOperator = function (operators) {
        var defaultOperator;
        _.each(operators, function (operator) {
            if (operator === ComparisionOperator[ComparisionOperator.Contains])
                defaultOperator = operator;
        });
        if (defaultOperator === undefined)
            defaultOperator = operators[0];
        return defaultOperator;
    };
    Object.defineProperty(QueryModel.prototype, "valid", {
        get: function () {
            return this.rootForm.valid;
        },
        enumerable: true,
        configurable: true
    });
    QueryModel.prototype.processMouseAction = function (actionName, $event, node, data) {
        if (data === void 0) { data = null; }
        _super.prototype.processMouseAction.call(this, actionName, $event, node, data);
        switch (actionName) {
            case 'addSibling':
                this.addSibling(node);
                break;
            case 'removeNode':
                this.removeNode(node);
                break;
            case 'addChild':
                this.addChild(node);
                break;
        }
    };
    QueryModel.prototype.addChild = function (node) {
        var expression = new LogicalExpressionModel();
        var parentData = node.data;
        var newFormGroup = this.createNodeFormGroup();
        var newDTO = this.mapService.createLogicExpressionDTO();
        var newNodeData = new NodeData(expression, newFormGroup, newDTO);
        var newNode = new TreeNodeModel(newNodeData, node, this);
        parentData.expression.children.push(expression);
        node.children.push(newNode);
        parentData.dto.ChildExpressions = parentData.dto.ChildExpressions || [];
        parentData.dto.ChildExpressions.push(newDTO);
        this.addFormGroup(newFormGroup);
        newNode.depth = this.getDepth(newNode);
        this.flatNodesList.push(newNode);
    };
    QueryModel.prototype.removeNode = function (node) {
        var parent = node.parent;
        var nodeData = node.data;
        var parentData = parent.data;
        parentData.expression.children = _.without(parentData.expression.children, nodeData.expression);
        parent.children = _.without(parent.children, node);
        parentData.dto.ChildExpressions = _.without(parentData.dto.ChildExpressions, nodeData.dto);
        this.removeFormGroup(nodeData.formGroup);
        this.flatNodesList.splice(this.flatNodesList.indexOf(node), 1);
    };
    QueryModel.prototype.addSibling = function (node) {
        var parent = node.parent;
        var expression = new LogicalExpressionModel();
        var newFormGroup = this.createNodeFormGroup();
        var newDTO = this.mapService.createLogicExpressionDTO();
        var newNodeData = new NodeData(expression, newFormGroup, newDTO);
        var newNode = new TreeNodeModel(newNodeData, parent, this);
        var parentExpression = parent.data.expression;
        parentExpression.children.splice(parentExpression.children.indexOf(node.data) + 1, 0, expression);
        var parentExps = parent.data.dto.ChildExpressions;
        parentExps.splice(parentExps.indexOf(node.data.dto) + 1, 0, newDTO);
        parent.children.splice(parent.children.indexOf(node) + 1, 0, newNode);
        newNode.depth = this.getDepth(newNode);
        this.addFormGroup(newFormGroup);
        this.flatNodesList.push(newNode);
    };
    QueryModel.prototype.prepareDTO = function () {
        var _this = this;
        _.each(this.flatNodesList, function (node) {
            _this.prepareNode(node);
        });
        return this.root.data.dto;
    };
    QueryModel.prototype.prepareNode = function (node) {
        var nodeData = node.data;
        var expression = nodeData.expression;
        var formGroup = nodeData.formGroup;
        // save changes from form model to data model
        var formCategory = formGroup.get('selectedCategory').value;
        var selectedCategory = _.find(this.categories, function (category) {
            return category.categoryFieldName === formCategory.categoryFieldName;
        });
        if (selectedCategory) {
            expression.searchPredicate.searchCategoryName = selectedCategory.categoryFieldName;
            var formOperator = formGroup.get('selectedOperator').value;
            expression.searchPredicate.searchOperator = formOperator.displayName;
            var firstValue = formGroup.get('firstValue').value;
            if (firstValue instanceof Date) {
                firstValue = moment(firstValue).startOf('day').toDate();
            }
            expression.searchPredicate.values = [firstValue];
            var secondValue = formGroup.get('secondValue').value;
            if (secondValue) {
                if (secondValue instanceof Date) {
                    secondValue = moment(secondValue).startOf('day').toDate();
                }
                expression.searchPredicate.values.push(secondValue);
            }
        }
        // map data model to server dto's
        this.mapService.mapLogicatlExpressionToDTO(expression, nodeData.dto);
    };
    QueryModel.prototype.createNodeFormGroup = function (noValidation) {
        if (!noValidation) {
            return new FormGroup({
                selectedCategory: new FormControl('', Validators.required),
                selectedOperator: new FormControl('', Validators.required),
                firstValue: new FormControl('', Validators.required),
                secondValue: new FormControl('')
            });
        }
        return new FormGroup({
            selectedCategory: new FormControl(''),
            selectedOperator: new FormControl(''),
            firstValue: new FormControl(''),
            secondValue: new FormControl('')
        });
    };
    QueryModel.prototype.addFormGroup = function (group) {
        this.formChildren.push(group);
    };
    QueryModel.prototype.removeFormGroup = function (group) {
        var index = this.formChildren.controls.indexOf(group);
        this.formChildren.removeAt(index);
    };
    return QueryModel;
}(TreeModel));
export { QueryModel };
var NodeData = /** @class */ (function () {
    function NodeData(expression, formGroup, dto) {
        this.expression = expression;
        this.formGroup = formGroup;
        this.dto = dto;
    }
    return NodeData;
}());
export { NodeData };
