import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { SignalrHub2Service } from '../../../channel/services/signalr/signalr-hub2.service';
import { SessionService, ThrottlingService } from '../../../core/services';
import { appConfig } from '../../../app.config';
import { timeAndAttendanceConfig } from '../../../time-and-attendance/time-and-attendance.config';
import * as i0 from "@angular/core";
import * as i1 from "../../../channel/services/signalr/signalr-hub2.service";
import * as i2 from "../../../core/services/throttling/throttling.service";
import * as i3 from "../../../core/services/session/session.service";
var TaSignalrService = /** @class */ (function () {
    function TaSignalrService(signalRService, throttlingService, sessionService) {
        this.signalRService = signalRService;
        this.throttlingService = throttlingService;
        this.sessionService = sessionService;
        this.isRebooted$ = new Subject();
        this.isRebootInitiated$ = new Subject();
        this.signalRService = new SignalrHub2Service(this.throttlingService, this.sessionService);
    }
    TaSignalrService.prototype.init = function () {
        this.signalRService.init({ url: this.getApiRoot(), hubName: timeAndAttendanceConfig.api.signalR.signalRHub });
        this.isRebooted$ = this.signalRService.subscribe(timeAndAttendanceConfig.api.signalR.groupName);
    };
    TaSignalrService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version2 + "/" + timeAndAttendanceConfig.api.signalR.root + "/signalr";
    };
    TaSignalrService.prototype.checkAndEstablishConnection = function () {
        var connectionStatus = this.signalRService.getConnectionStatus();
        if (connectionStatus !== undefined && connectionStatus === signalR.HubConnectionState.Disconnected) {
            this.signalRService.RestartConnection();
        }
        else {
            this.init();
        }
    };
    TaSignalrService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TaSignalrService_Factory() { return new TaSignalrService(i0.ɵɵinject(i1.SignalrHub2Service), i0.ɵɵinject(i2.ThrottlingService), i0.ɵɵinject(i3.SessionService)); }, token: TaSignalrService, providedIn: "root" });
    return TaSignalrService;
}());
export { TaSignalrService };
