import { Component, Input } from '@angular/core';
import { SlateMessageGroupInfo } from '../../models/slate-message-group-info';
import { SlateMessagesManagementService } from '../../services/slate-messages-management.service';
import * as moment from 'moment';

@Component({
  selector: 'slx-slate-messages-grid',
  templateUrl: './slate-messages-grid.component.html',
  styleUrls: ['./slate-messages-grid.component.scss']
})
export class SlateMessagesGridComponent {
  @Input() messages: SlateMessageGroupInfo[];
  @Input() isExpanded: boolean;
  selectedMessage: SlateMessageGroupInfo;
  constructor(public slateMessagesmanagementService: SlateMessagesManagementService) { }

  public selectMessage(message: SlateMessageGroupInfo) {
    this.selectedMessage = message;
    this.slateMessagesmanagementService.updateSelectedmessage(message);
  }
}
