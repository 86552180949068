import { BenefitDetailsProviderLineStandartEntity } from './benefit-details-provider-line-standart-entity';
import { BenefitDetailsLine } from './benefit-details-line';

export class ExtendExpireRenewModel {
  constructor(
    public isRenew: boolean = false,
    public benefitLineStandart: BenefitDetailsProviderLineStandartEntity = null,
    public benefitLine: BenefitDetailsLine = null
  ) { }
}

