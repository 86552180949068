import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

import { ModalService } from '../../../common/services/index';

import { BenefitEmployeeDependentsEnrollments, BenefitEmployeeDependent} from '../../../app-modules/benefits/models/index';
import { EmployeeDependentBenefitsOptions } from '../models/index';
import { EmployeeDependentBenefitsDialogComponent } from '../components/employee-sections-benefits-management/employee-dependent-benefits-dialog/employee-dependent-benefits-dialog.component';

@Directive({
  selector: '[slxEmployeeDependentBenefitsModal]',
})
export class EmployeeDependentBenefitsModalDirective {
  constructor(private modalService: ModalService) {}

  @Input()
  public slxEmployeeDependentBenefitsModal: Array<BenefitEmployeeDependent>;
  @Output()
  public selectEmployeeDependentsBenefits = new EventEmitter<BenefitEmployeeDependentsEnrollments>();

  @HostListener('click', ['$event'])
  public onClick(): void {
    const options = new EmployeeDependentBenefitsOptions(this.slxEmployeeDependentBenefitsModal);
    const dialog = EmployeeDependentBenefitsDialogComponent.openDialog(
      options,
      this.modalService,
      (result: boolean) => {
        if (result && dialog.dialogResult) {
          this.selectEmployeeDependentsBenefits.emit(dialog.dependentsEnrollments);
        }
      }
    );
  }
}
