import * as tslib_1 from "tslib";
import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { LinePunch, PunchesEventFilter } from '../../../models/index';
import { DailyPunchesManagementService } from '../../../services/punches/daily-punches-management.service';
import { unsubscribe } from '../../../../core/decorators/index';
import { LookupService } from '../../../../organization/services/index';
import { StateManagementService } from '../../../../common/services/index';
import { StateResetTypes } from '../../../../core/models/index';
var PunchesEventFilterComponent = /** @class */ (function () {
    function PunchesEventFilterComponent(lookupService, managementService, stateManagement) {
        var _this = this;
        this.lookupService = lookupService;
        this.managementService = managementService;
        this.stateManagement = stateManagement;
        this.filterControlKey = 'EmployeePunchesEventFilters';
        this.filtersRestored = false;
        this.filter = new PunchesEventFilter();
        this.stateInitSubscription = this.stateManagement.onInit$.subscribe(function () {
            _this.filter = _this.restoreFilters();
            _this.managementService.setFilter(_this.filter);
            _this.filtersRestored = true;
        });
    }
    PunchesEventFilterComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchesEventFilterComponent.prototype.onFilterChanged = function () {
        if (!this.filtersRestored) {
            return;
        }
        this.managementService.applyFilter(this.records, this.filter);
        this.saveFilters(this.filter);
    };
    PunchesEventFilterComponent.prototype.saveFilters = function (filter) {
        this.stateManagement.setControlState(this.filterControlKey, { value: filter }, StateResetTypes.MenuChange | StateResetTypes.SessionEnd);
    };
    PunchesEventFilterComponent.prototype.restoreFilters = function () {
        var filters;
        var state = this.stateManagement.getControlState(this.filterControlKey);
        if (state && state.value !== undefined) {
            filters = state.value;
            return filters;
        }
        filters = new PunchesEventFilter();
        filters = new PunchesEventFilter();
        filters.empPunch = true;
        filters.editPunch = true;
        filters.essRequest = true;
        filters.invalidPunch = true;
        filters.invalidLogin = true;
        filters.schedule = true;
        filters.deleted = false;
        return filters;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesEventFilterComponent.prototype, "stateInitSubscription", void 0);
    return PunchesEventFilterComponent;
}());
export { PunchesEventFilterComponent };
