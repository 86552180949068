import { Component, OnInit, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { CustomListActorBase } from '../../../components/editableList/custom-list-actor.base';
import { EditableListActionKind, Shift } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
@Component({
  moduleId: module.id,
  selector: 'slx-view-shift-renderer',
  templateUrl: 'view-shift-renderer.component.html',
  styleUrls: ['view-shift-renderer.component.scss'],

  providers: [{
    provide: CustomListActorBase,
    useExisting: forwardRef(() => ViewShiftRendererComponent)
  }]
})

export class ViewShiftRendererComponent extends CustomListActorBase implements OnInit {

  public appConfig: IApplicationConfig;

  @Input()
  public set context(value: any) {
    this.item = value.item;
    this.rowIndex = value.rowIndex;
  }

  @Input()
  public editable: boolean = true;

  @Input()
  public item: Shift;

  @Input()
  public rowIndex: number;

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public onEditClick(): void {
    this.actionEmitter.emit(EditableListActionKind.START_EDIT);
  }

  public onItemSelect(): void {
    this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
  }
}
