export interface INotificationEventType {
    eventId: number;
    name: string;
}
export class NotificationEventType {
   
    public eventId: number;
    public name: string;
    constructor(eventId : number, name : string) {
        this.eventId = eventId;
        this.name = name
    }
}