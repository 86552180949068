import { Pipe, Injectable, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slxMoney',
  pure: true
})
@Injectable()
export class SlxMoneyPipe implements PipeTransform {
  public transform(obj?: any): any {
    if (obj === null || obj === undefined) return null;
    return `$${obj}` ;
  }
}
