import { IndividualScheduleNightShiftService } from './../../../../../scheduler/services/individual-schedule/individual-schedule-night-shift.service';
import {
  Component,
  Input,
  ElementRef,
  OnInit
} from '@angular/core';
import { Assert } from '../../../../../framework/index';
import { EmployeeRotation, WeeklyRotation, EmployeeRotationRecord } from '../../../models/index';
import { WeekDayService } from '../../../services/index';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import {
  ShiftDefinition,
  EmployeeShift,
  ShiftGroupOrder
} from '../../../../../organization/models/index';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { DayOfWeek } from '../../../../../common/models/index';
import * as moment from 'moment';
export const maximumWeeklyRotationsShown: number = 3;

@Component({
  moduleId: module.id,
  selector: 'slx-employee-rotations',
  templateUrl: 'employee-rotations.component.html',
  styleUrls: ['employee-rotations.component.scss'],
  providers: [IndividualScheduleNightShiftService]
})
export class EmployeeRotationsComponent implements OnInit {
  @Input() public isCurrentRotationSelected: boolean;
  @Input() public componentId: string;
  @Input() public employeeId: number;
  @Input() public weekDaysRibbon: DayOfWeek[];
  @Input() public selectedEmployeeShift: EmployeeShift;
  @Input() public isInEditMode: boolean;
  @Input() public set selectedRotation(value: EmployeeRotation) {
    this.selectedRotationValue = value;
    this.weekDaysRibbon = this.weekDayService.getWeekDaysByRotation(value);
  }
  public get isExpandButtonVisible(): boolean {
    return this.selectedRotationValue
      && this.selectedRotationValue.weeklyRotations
        && this.selectedRotationValue.weeklyRotations.length > maximumWeeklyRotationsShown;
  }
  public isRotationsExpanded: boolean;
  public weekDays: string[];
  public weekDayService: WeekDayService;
  public appConfig: IApplicationConfig;
  public selectedRotationValue: EmployeeRotation;

  protected checkTemporalDirty(): boolean {
    return false;
  }

  constructor(weekDayService: WeekDayService,
    lookupApiService: LookupApiService,
    elementRef: ElementRef,
    private nightShiftService: IndividualScheduleNightShiftService) {
    Assert.isNotNull(weekDayService, 'weekDayService');
    this.weekDayService = weekDayService;
    this.selectedEmployeeShift = {
      isValid: false,
      constraint: undefined,
      position: undefined,
      shift: undefined,
      unit: undefined,
      absence: undefined,
      isDirty:false
    };

    this.isRotationsExpanded = false;
    this.isInEditMode = false;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  @Input()
  public get invalidRotations(): EmployeeRotationRecord[] {
    let invalidRotations: EmployeeRotationRecord[] = [];
    if (this.selectedRotationValue !== null && this.selectedRotationValue !== undefined) {
      _.forEach(this.selectedRotationValue.weeklyRotations, (w: WeeklyRotation) => {
        _.forEach(w.dailyRecords, (d: EmployeeRotationRecord) => {
          if (!d.isValid || d.showWarning) {
            invalidRotations.push(d);
          }
        });
      });
    }
    return invalidRotations;
  }

  public onAddShiftClicked(dailyRecord: EmployeeRotationRecord): void {
      this.addEmployeeShiftShift(dailyRecord, this.selectedEmployeeShift);
      this.validateRotation(this.selectedRotationValue);
  }

  public masterCellClick(dailyRecord: EmployeeRotationRecord): void {
    if(this.isInEditMode) {
      var dateOn = moment(dailyRecord.weeklyRotation.weekStartDate).add(dailyRecord.dayNumber, 'days').toDate();
      this.selectedEmployeeShift.shift.startDate = this.copyTime(this.selectedEmployeeShift.shift.startDate, dateOn);
      this.selectedEmployeeShift.shift.endDate =  this.copyTime(this.selectedEmployeeShift.shift.endDate, dateOn);
      if (moment(this.selectedEmployeeShift.shift.endDate).isBefore(this.selectedEmployeeShift.shift.startDate)) {
        this.selectedEmployeeShift.shift.endDate = moment(this.selectedEmployeeShift.shift.endDate).add(1, 'days').toDate();
      }
      this.addEmployeeShiftShift(dailyRecord, this.selectedEmployeeShift);
      this.validateRotation(this.selectedRotationValue);
    }
  }

  private copyTime(source: Date, target: Date): Date {
    return moment(target.setHours(source.getHours(), source.getMinutes(), source.getSeconds(), source.getMilliseconds())).toDate();
  }

  public onRemoveRotationShiftClicked(dailyRecord: EmployeeRotationRecord): void {
    dailyRecord.clearShifts();
    this.validateRotation(this.selectedRotationValue);
  }

  public onRemoveShiftClicked(shift: EmployeeShift, dailyRecord: EmployeeRotationRecord, event: Event): void {
    this.removeEmployeeShift(dailyRecord,shift);
    this.validateRotation(this.selectedRotationValue);
    event.stopPropagation();
  }

  public onExpandCollapse(): void {
    this.isRotationsExpanded = !this.isRotationsExpanded;
  }

  private addEmployeeShiftShift(dailyRecord: EmployeeRotationRecord, employeeShift: EmployeeShift): void {
    employeeShift.isDirty = true;
    dailyRecord.setNextShift(this.cloneEmployeeShift(employeeShift));
    this.validateRotation(this.selectedRotationValue);
  }

  private cloneEmployeeShift(employeeShift: EmployeeShift): EmployeeShift {
    const cloned: EmployeeShift = _.cloneDeep(employeeShift);
    cloned.shift.fullStartDate = cloned.shift.startDate;
    cloned.shift.fullEndDate = cloned.shift.endDate;
    if (cloned.shift.isNextDayShift) {
      if (!this.nightShiftService.nightShiftPrimary || cloned.shift.group.groupOrder !== ShiftGroupOrder.Night) {
        cloned.shift.fullEndDate = moment(cloned.shift.endDate).add(1, 'days').toDate();
      } else {
        cloned.shift.fullStartDate = moment(cloned.shift.startDate).subtract(1, 'days').toDate();
      }
    }
    return cloned;
  }

  private removeEmployeeShift(dailyRecord: EmployeeRotationRecord, employeeShift: EmployeeShift): void {
    dailyRecord.removeShift(_.clone(employeeShift));
    dailyRecord.showWarning = false;
  }

  private validateRotation(rotation: EmployeeRotation): void {
    let isValid: boolean = true;
    let rotationRecords: EmployeeRotationRecord[] =
      rotation.weeklyRotations
        .map((weeklyRotation: WeeklyRotation) => weeklyRotation.dailyRecords)
          .reduce((dailyRecordsResult: EmployeeRotationRecord[], dailyRecords: EmployeeRotationRecord[]) => dailyRecordsResult.concat(dailyRecords), []);

    for (let i: number = 0; i < rotationRecords.length; i++) {
      let record: EmployeeRotationRecord = rotationRecords[i];
      let shifts: ShiftDefinition[] = [];
      const errors = new Map<string, boolean>();

      record.isValid = true;
      record.validationMessage = undefined;

      if (record.shifts !== null && record.shifts !== undefined) {
        _.forEach(record.shifts, (shiftRecord: EmployeeShift) => {
          if (shiftRecord.shift.fullEndDate === undefined || shiftRecord.shift.fullStartDate === undefined) {
            shiftRecord.shift.fullStartDate = shiftRecord.shift.startDate;
            shiftRecord.shift.fullEndDate = shiftRecord.shift.endDate;
            if (shiftRecord.shift.isNextDayShift) {
              if (!this.nightShiftService.nightShiftPrimary || shiftRecord.shift.group.groupOrder !== ShiftGroupOrder.Night) {
                shiftRecord.shift.fullEndDate = moment(shiftRecord.shift.endDate).add(1, 'days').toDate();
              } else {
                shiftRecord.shift.fullStartDate = moment(shiftRecord.shift.startDate).subtract(1, 'days').toDate();
              }
            }
          }
          shifts.push(shiftRecord.shift);
        });
      }

      if (i > 0) {
        let previousRecord: EmployeeRotationRecord = rotationRecords[i - 1];
        if (this.isRotationRecordsOverlapped(record, previousRecord)) {
          errors.set('previousOverlapped', true);
          record.isValid = false;
          record.validationMessage = 'Shifts of this record are overlapped with previous one.';
        }
        if (i < rotationRecords.length - 1)
        {
          let nextRecord: EmployeeRotationRecord = rotationRecords[i + 1];
          if (nextRecord.shiftEnd !== undefined && nextRecord.shiftStart !== undefined 
                && record.shiftEnd !== undefined && record.shiftStart !== undefined
                && moment(record.shiftStart).isBefore(nextRecord.shiftEnd) 
                && moment(record.shiftEnd).isAfter(nextRecord.shiftStart)) {
            errors.set('Overlapped', true);
            record.isValid = false;
            record.validationMessage = 'Shifts of this record are overlapped with next one.';
          }
      } 
    } 

      if (this.isShiftsOverlapped(shifts)) {
        errors.set('overlapped', true);
        record.showWarning = true;
        if(errors.has('previousOverlapped') || errors.has('lastOverlapped')) {
          record.isValid = false;
          record.validationMessage = record.validationMessage + ' Shifts of this record are overlapped.';
        } else {
          record.isValid = false;
          record.validationMessage = 'Shifts of this record are overlapped.';
        }
      }

      if(this.areShiftsNested(shifts)) {
        errors.set('nested', true);
        record.isValid = false;
        if(errors.has('overlapped')) {
          record.validationMessage = record.validationMessage.replace('Shifts of this record are overlapped.', '');
          record.validationMessage = record.validationMessage + ' Shifts of this record are nested.';
        } else {
          record.validationMessage = 'Shifts of this record are nested.';
        }
      }

      if(this.areShiftsDuplicated(shifts)) {
        record.isValid = false;
        if(errors.has('overlapped')) {
          if(errors.has('nested')) {
            record.validationMessage = record.validationMessage.replace(' Shifts of this record are nested.', '');
          }
          record.validationMessage = record.validationMessage + ' Shifts of this record are duplicated.';
        } else {
          record.validationMessage = 'Shifts of this record are duplicated.';
        }
      }
    }
  }

  private isRotationRecordsOverlapped(current: EmployeeRotationRecord, previous: EmployeeRotationRecord): boolean {
    for (let i: number = 0; i < current.shifts.length; i++) {
      for (let j: number = 0; j < previous.shifts.length; j++) {
        return moment(current.shifts[i].shift.fullStartDate).isBefore(moment(previous.shifts[j].shift.fullEndDate)) 
                         && moment(current.shifts[i].shift.fullEndDate).isAfter(moment(previous.shifts[j].shift.fullStartDate));
      }
    }
  }

  private isShiftsOverlapped(shifts: ShiftDefinition[]): boolean {
    for (let i: number = 0; i < shifts.length; i++) {
      for (let j: number = i + 1; j < shifts.length; j++) {
        return moment(shifts[i].fullStartDate).isBefore(moment(shifts[j].fullEndDate)) 
                         && moment(shifts[i].fullEndDate).isAfter(moment(shifts[j].fullStartDate));
      }
    }
    return false;
  }

  private areShiftsDuplicated(shifts: ShiftDefinition[]): boolean {
    for (let i: number = 0; i < shifts.length; i++) {
      for (let j: number = i + 1; j < shifts.length; j++) {
        if ((shifts[i].startDate.getTime() === shifts[j].startDate.getTime()) && (shifts[i].endDate.getTime() === shifts[j].endDate.getTime())) {
          return true;
        }
      }
    }
    return false;
  }

  private areShiftsNested(shifts: ShiftDefinition[]): boolean {
    for (let i: number = 0; i < shifts.length; i++) {
      for (let j: number = 0; j < shifts.length; j++) {
        if(i !== j && moment.range(shifts[i].fullStartDate, shifts[i].fullEndDate).contains(shifts[j].fullStartDate)
            && moment.range(shifts[i].fullStartDate, shifts[i].fullEndDate).contains(shifts[j].fullEndDate)) {
          return true;
        }
      }
    }
    return false;
  }

  public getWeekStartDate(weeklyRotation: WeeklyRotation, dateIndex: number): number {
    if(_.isObjectLike(weeklyRotation)) {
      let dayNumber = weeklyRotation.weekStartDate.getDate();
      let monthEnd = +moment(weeklyRotation.weekStartDate).endOf('month').format('D');
      if(dayNumber + dateIndex <= monthEnd) {
        return weeklyRotation.weekStartDate.getDate() + dateIndex;
      } else {
        return weeklyRotation.weekStartDate.getDate() + dateIndex - monthEnd;
      }
    }
  }
}
