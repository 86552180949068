export interface IMealDeductionSettingsDTO {
    recordId: number;
    organizationId: number;
    maxPunchTreshold: string;
    enableForExemptS: boolean;
    enableForExemptY: boolean;
    enableForExemptX: boolean;
    enableForExemptT: boolean;
    enableForAgency: boolean;
    enableForNonExempt: boolean;
    lastUpdateBy: boolean;
    lastUpdateOn: string;
    canConfigure: boolean;
    generatePBJFromAgency: boolean;
    shiftOneMidPoint: number;
    shiftTwoMidPoint: number;
    shiftMode: string;
}

export class MealDeductionSettings {
    public recordId: number;
    public organizationId: number;
    public maxPunchTreshold: number;
    public enableForExemptS: boolean;
    public enableForExemptY: boolean;
    public enableForExemptX: boolean;
    public enableForExemptT: boolean;
    public enableForAgency: boolean;
    public enableForNonExempt: boolean;
    public lastUpdateBy: boolean;
    public lastUpdateOn: Date;
    public canConfigure: boolean;
    public generatePBJFromAgency: boolean = false;
    public midShiftOne: number;
    public midShiftTwo: number;
    public shift_Type: string;
}

export const ShiftModes = ['Date of the Last Out Punch', 'Mid-Shift'];
