<slx-spinner [show]="state.isLoading">
  <kendo-grid class="budget-history budget-history-grid slx-full-height"
    [data]="gridState.view"
    [sortable]="{ mode: 'single' }"
    [sort]="gridState.state.sort"
    [groupable]="{ showFooter: true }"
    [group]="gridState.state.group"
    [selectable]="true"
    (selectionChange)="gridState.selectionChange($event)"
    (dataStateChange)="gridState.dataStateChange($event)"
  >
    <ng-template kendoGridToolbarTemplate position="top">
      <div class="flex-horizontal-space-between">
        <form novalidate>
          <slx-date-range-ngx name="startEndDate" [startDate]="startDate" [endDate]="endDate" (rangeDateChanged)="onFilterDateChanged($event)"></slx-date-range-ngx>
        </form>
        <div flex-horizontal>
          <button type="button" (click)="onDiscard()" class="theme-button-cancel">Discard</button>
          <button [disabled]="!selectedRecord" type="button" (click)="onRestore()" class="theme-button-apply">Clone</button>
        </div>
      </div>
    </ng-template>
    <kendo-grid-column title="Start Date" field="budgetDefinition.startDate">
      <ng-template kendoGridHeaderTemplate>
        Start Date
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.budgetDefinition.startDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="End Date" field="budgetDefinition.endDate">
      <ng-template kendoGridHeaderTemplate>
        End Date
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.budgetDefinition.endDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <!--
    <kendo-grid-column title="Last Updated By" field="lastUpdatedBy">
      <ng-template kendoGridHeaderTemplate>
        Last Updated By
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.lastUpdatedBy}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Last Updated Date" field="lastUpdatedDate">
      <ng-template kendoGridHeaderTemplate>
        Last Updated By
      </ng-template>
      <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
        <strong>{{value | amDateFormat: appConfig.dateFormat}}</strong>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem?.lastUpdatedDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    -->
    <div *kendoGridDetailTemplate="let dataItem">
      <slx-budget-history-detail [budgetDefinition]="dataItem.budgetDefinition">
      </slx-budget-history-detail>
    </div>
  </kendo-grid>
</slx-spinner>
