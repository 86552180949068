import { IPosition } from '../../../organization/models/index';
import { IdealScheduleConfigCensus } from './ideal-schedule-config-census';
import { IdealScheduleConfigType } from './ideal-schedule-config-type';
import { IdealSchedulePositionRange } from './ideal-schedule-position-range';

export class IdealSchedulePosition {
  public name: string;

  public deviation: number;
  public position: IPosition;
  public isDailyVariance: boolean;
  public targetHours: number;
  public startDate: Date;
  public endDate: Date;
  public idealScheduleType: IdealScheduleConfigType;
  public idealScheduleCensus: IdealScheduleConfigCensus;
  public targetCensus: number;
  public ranges: IdealSchedulePositionRange[] = [];
  public totalHours: number;
  public isActive: boolean;
  public hasChanges: boolean;
  public isEmpty: boolean;
}
