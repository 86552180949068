import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { ResponseBody } from '../../../core/models/index';
import { genericListConfig } from '../../generic-list.config';
import { GenericListMapService } from './generic-list-map.service';
import { ApiUtilService } from '../../../common/services/index';

import { Meta } from '../../../core/models/api/meta';
import { Assert } from '../../../framework/index';
import {
  GenericListDefinition, IGenericListDefinition,
  IGenericFieldList, GenericList,
  IGenericListRequest, GenericListRequest
} from '../../models/generic-list/index';
import { CacheType } from '../../../common/models/cache/cache-definition';

@Injectable()
export class GenericListApiService {
  constructor(
    private genericListMapService: GenericListMapService,
    private apiUtilService: ApiUtilService) {
  }

  public getListDefinitions(applicationId: number, orgLevelId: number, listName: string, updateCacheForced : boolean = true): Promise<GenericListDefinition> {
    const url: string = `${this.getApiAppOrgLevel(applicationId, orgLevelId)}/${genericListConfig.api.applications.orgLevel.listDefinitions}/${listName}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<GenericListDefinition> = this.apiUtilService.cachedRequest<IGenericListDefinition, Meta>(request, CacheType.longTerm, updateCacheForced)
      .then((response: ResponseBody<IGenericListDefinition, Meta>) =>
        this.genericListMapService.mapToGenericListDefinition(response.data)
      );
    return promise;
  }

  public listValues(orgLevelId: number, listDefinition: GenericListDefinition, req: GenericListRequest): Promise<GenericList> {
    Assert.isNotNull(orgLevelId, 'orgLevelId');
    Assert.isNotNull(listDefinition, 'listDefinition');
    Assert.isNotNull(req, 'req');
    const url: string = `${this.getApiOrgLevel(orgLevelId)}/${genericListConfig.api.applications.orgLevel.lists}/${listDefinition.id}`;
    let modelDTO: IGenericListRequest = this.genericListMapService.mapToGenericListRequestDto(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<IGenericFieldList, Meta>(request)
      .then((response: ResponseBody<IGenericFieldList, Meta>) => this.genericListMapService.mapGenericList(listDefinition, response.data));
    return promise;
  }

  private getApiAppOrgLevel(applicationId: number, orgLevelId: number): string {
    return `${this.getApiRoot()}/${applicationId}/${genericListConfig.api.applications.orgLevel.root}/${orgLevelId}`;
  }
  private getApiOrgLevel(orgLevelId: number): string {
    return `${this.getApiRoot()}/${genericListConfig.api.applications.orgLevel.root}/${orgLevelId}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${genericListConfig.api.applications.root}`;
  }
}
