import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, AfterContentChecked } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { select } from '@angular-redux/store';
import { StepSwitchDirective } from '../../directives/wizard-step/wizard-step.directive';
import { StringUtils } from '../../../framework/string-utils/string-utils';
var WizardComponent = /** @class */ (function () {
    function WizardComponent() {
    }
    Object.defineProperty(WizardComponent.prototype, "progress", {
        get: function () {
            if (!this.stepDefinitions)
                return '0';
            if (this.stepDefinitions.length <= 1)
                return '100%';
            if (this.currentStep <= 1)
                return '0';
            if (this.currentStep >= (this.stepDefinitions.length))
                return '100%';
            var prg = Math.round(100 * (this.currentStep - 1) / this.stepDefinitions.length);
            return StringUtils.format('{0}%', prg);
        },
        enumerable: true,
        configurable: true
    });
    WizardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.switchInitialized = false;
        this.wizardSubscription = this.wizard$.subscribe(function (newStep) {
            _this.setStep(newStep);
        });
    };
    WizardComponent.prototype.ngOnDestroy = function () {
        this.wizardSubscription.unsubscribe();
    };
    WizardComponent.prototype.setStep = function (step) {
        this.currentStep = step;
        if (this.stepSwitch) {
            this.stepSwitch.stepSwitch = step;
        }
    };
    WizardComponent.prototype.ngAfterContentChecked = function () {
        if (this.stepSwitch) {
            this.stepDefinitions = this.stepSwitch.getStepDefinitions();
            if (!this.switchInitialized) {
                this.stepSwitch.stepSwitch = this.currentStep;
            }
        }
    };
    tslib_1.__decorate([
        select(['wizard', 'step']),
        tslib_1.__metadata("design:type", Observable)
    ], WizardComponent.prototype, "wizard$", void 0);
    return WizardComponent;
}());
export { WizardComponent };
