import { Component, Provider } from '@angular/core';
import { IDialog, DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../../../app/common/models/index';
import { ModalService } from '../../../../../../../app/common/services/modal/modal.service';
import * as _ from 'lodash';
import { EmployeeSectionsBase, EnrollmentHistoryRecord } from '../../../../models';
import { appConfig, IApplicationConfig } from '../../../../../../../app/app.config';

@Component({
    selector: 'benefit-plan-notes-dialog',
    templateUrl: './benefit-plan-notes-dialog-box.html',
    styleUrls: ['./benfit-plan-notes-dialog-box.scss']
})
export class BenefitPlanSaveNotesDialogComponent implements IDialog {

    public dialogResult: boolean;
    public enrollmentStartDate: Date;
    public enrollmentEndDate: Date;
    public notes: string;
    public appConfig: IApplicationConfig = appConfig;

    public static openDialog(selectedRecord: EnrollmentHistoryRecord, fullName: string, modalService: ModalService, callback?: (result: boolean) => void): BenefitPlanSaveNotesDialogComponent {
        let title: string = fullName + ' - ' + selectedRecord.plan;
        let dialogOptions: DialogOptions2 = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.dialog;
        let resolvedProviders: Provider[] = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EnrollmentHistoryRecord,
                useValue: selectedRecord
            }
        ];

        return modalService.globalAnchor
            .openDialog2(BenefitPlanSaveNotesDialogComponent, title, dialogOptions, resolvedProviders,
                (result: boolean) => {
                    callback(result);
                });
    }

    constructor(private dialogOptions: DialogOptions, private modalService: ModalService, private selectedRecord: EnrollmentHistoryRecord) {
        this.modalService = modalService;
        this.enrollmentStartDate = selectedRecord ? selectedRecord.startDate : null;
        this.enrollmentEndDate = selectedRecord ? selectedRecord.endDate : null;
        this.notes = selectedRecord ? selectedRecord.notes : null;
    }


    public get hasNotes(): boolean {
        return !(this.notes || this.selectedRecord.notes);
    }


    public save(): void {
        this.dialogResult = true;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    }

    public cancel(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    }


}
