import { Directive, HostListener, Input } from "@angular/core";
import { ConfigForceLoad, MidnightToMidnightReport, MidnightToMidnightReportData, ReportDefinition } from "../../models";
import { MidnightToMidnightReportDialogComponent } from "../../components/midnight-to-midnight-report-dialog/midnight-to-midnight-report-dialog.component";
import { ModalService } from "./../../../../app/common";
import { Observable, Subscription } from "rxjs";
import { mutableSelect, unsubscribe } from "./../../../../app/core/decorators";
import { OrgLevel } from "./../../../../app/state-model/models";
import { ReportsApiService } from "../../services/reports-api.service";

@Directive({
    selector: '[slxMidnightReport]'
})
export class MidnightToMidnightReportConfigDirective {

    @Input()
    public slxMidnightReport: ReportDefinition;
    public configForceLoad: ConfigForceLoad = null;

    @mutableSelect(['orgLevel'])
    public orgLevel$: Observable<OrgLevel>;
    @unsubscribe()
    private orgLevelSubscription: Subscription;
    public orgLevelId: number;

    @HostListener('click', ['$event'])
    public onClick(e: MouseEvent): void {
        if (this.slxMidnightReport.reportConfigId) {
            this.getConfigDetails();
        }
    }

    constructor(public modalService: ModalService, public reportApi: ReportsApiService) {
        this.configForceLoad = new ConfigForceLoad();
        this.configForceLoad.midnightToMidnightReportConfig = false;
        this.orgLevelSubscription = this.orgLevel$.subscribe((data) => {
            this.orgLevelId = data.id;
        });
    }

    public getConfigDetails() {
        const data: MidnightToMidnightReportData = new MidnightToMidnightReportData();
        this.reportApi.getMidnightToMidnightConfigData(this.orgLevelId, true).then((configData: MidnightToMidnightReport[]) => {
            data.orgLevelId = this.orgLevelId;
            data.gridData = configData;
            MidnightToMidnightReportDialogComponent.openDialog(data, this.configForceLoad, this.modalService, (isSave, data) => {});
        });
    }
}