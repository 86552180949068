import { OnInit, DoCheck } from '@angular/core';
import { LookupDropdownInputComponent } from '../../../common/components/index';
var EmptyOptionLdiDirective = /** @class */ (function () {
    function EmptyOptionLdiDirective(dropdown) {
        this.dropdown = dropdown;
    }
    EmptyOptionLdiDirective.prototype.ngOnInit = function () {
        this.lastItems = undefined;
        this.lastItemsCount = 0;
    };
    EmptyOptionLdiDirective.prototype.ngDoCheck = function () {
        var doAdd = false;
        doAdd = !!this.dropdown.lookups;
        if (this.dropdown.lookups !== this.lastItems || this.dropdown.lookups && this.dropdown.lookups.length !== this.lastItemsCount) {
            this.dropdown.lookups = this.addEmptyOption(this.dropdown.lookups);
            this.lastItems = this.dropdown.lookups;
            this.lastItemsCount = this.dropdown.lookups.length;
        }
    };
    EmptyOptionLdiDirective.prototype.addEmptyOption = function (items) {
        var _a;
        var optionToAdd;
        if (typeof this.emptyOption === 'string') {
            optionToAdd = (_a = {},
                _a[this.dropdown.descriptionMemberPath] = this.emptyOption,
                _a[this.dropdown.valueMemberPath] = 0,
                _a);
        }
        else if (this.emptyOption instanceof Object) {
            optionToAdd = this.emptyOption;
        }
        else {
            return items;
        }
        if (items && items.length) {
            items = [optionToAdd].concat(items);
        }
        return items;
    };
    return EmptyOptionLdiDirective;
}());
export { EmptyOptionLdiDirective };
