import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { ScheduleAbsence } from '../../../../organization/models/lookup/index';
import { VacationPlanEmployeeDetails, VacationPlanEmployeeRecordDetails } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, LookupType, ILookupRequest } from '../../../../organization/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-vacation-employee-details',
  templateUrl: 'vacation-employee-details.component.html',
  styleUrls: ['vacation-employee-details.component.scss']
})
export class VacationEmployeeDetailsComponent implements OnInit {
  @Input()
  public orgLevelId: number;
  public absencesLookup: Lookup;

  public scheduleLookup: ScheduleAbsence[];
  public appConfig: IApplicationConfig;

  @Input()
  public set scheduleRecords(value: VacationPlanEmployeeRecordDetails[]) {

    this.originalAbsenceCodesMap = new Map();
    _.forEach(value, (record: VacationPlanEmployeeRecordDetails) => {
      this.originalAbsenceCodesMap[record.date.getTime()] = record.scheduleAbsence;
    });
    this.m_currentScheduleRecords = value;
  }

  public get scheduleRecords(): VacationPlanEmployeeRecordDetails[] {
    return this.m_currentScheduleRecords;
  }

  public set absenceCode(value: ScheduleAbsence) {
    this.m_absenceCode = value;
  }

  public get absenceCode(): ScheduleAbsence {
    return this.m_absenceCode;
  }

  private m_absenceCode: ScheduleAbsence;
  private m_currentScheduleRecords: VacationPlanEmployeeRecordDetails[];
  private originalAbsenceCodesMap: Map<number, ScheduleAbsence>;

  constructor(private lookupService: LookupService) {

  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.loadAbsences(this.orgLevelId);
  }

  public selectAll(): void {
    if (this.absenceCode) {
      _.forEach(this.scheduleRecords, (record: VacationPlanEmployeeRecordDetails) => {
        if (record.isActive) {
          record.scheduleAbsence = this.absenceCode;
        }
      });
    }
  }

  public clearAll(): void {
    _.forEach(this.scheduleRecords, (record: VacationPlanEmployeeRecordDetails) => {
      record.scheduleAbsence = undefined;
    });
  }

  public discardChanges(): void {
    _.forEach(this.scheduleRecords, (record: VacationPlanEmployeeRecordDetails) => {
      record.scheduleAbsence = this.originalAbsenceCodesMap[record.date.getTime()];
    });
  }

  public onCheckbox(event: any, record: VacationPlanEmployeeRecordDetails): void {
    if (event) {
      if (!this.absenceCode) {
        return;
      }
      record.scheduleAbsence = this.absenceCode;
    } else {
      record.scheduleAbsence = record.scheduleAbsenceStatic;
    }
  }

  public onSelectCode(code: ScheduleAbsence): void {
    this.absenceCode = code;
  }

  private loadAbsences(orgLevelId: number): void {
    this.lookupService.getLookup({ lookupType: LookupType.ptoPlannerAbsenceCodes, orgLevelId: orgLevelId, employeeId: undefined, updateCacheForced: true })
      .then((absencesLookupValue: Lookup) => {
        this.absencesLookup = absencesLookupValue;
      });
  }
}
