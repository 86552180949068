import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { appConfig } from '../../../../app.config';
import { process, aggregateBy } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
var ScheduleDailyDifferencesGridComponent = /** @class */ (function () {
    function ScheduleDailyDifferencesGridComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.dataLoaded = false;
        this.aggregates = [
            { field: 'requiredCount', aggregate: 'sum' },
            { field: 'requiredHours', aggregate: 'sum' },
            { field: 'scheduledCount', aggregate: 'sum' },
            { field: 'scheduledHours', aggregate: 'sum' },
            { field: 'differencesCount', aggregate: 'sum' },
            { field: 'differencesHours', aggregate: 'sum' }
        ];
        this.total = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [];
        this.gridState.state.group = [];
    }
    Object.defineProperty(ScheduleDailyDifferencesGridComponent.prototype, "records", {
        set: function (value) {
            this.m_records = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    ScheduleDailyDifferencesGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    ScheduleDailyDifferencesGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ScheduleDailyDifferencesGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        if (!this.m_records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.state.group.forEach(function (group) { return group.aggregates = _this.aggregates; });
        this.gridState.view = process(this.m_records, this.gridState.state);
        this.total = aggregateBy(this.m_records, this.aggregates);
        this.dataLoaded = true;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleDailyDifferencesGridComponent.prototype, "gridRefreshSubscription", void 0);
    return ScheduleDailyDifferencesGridComponent;
}());
export { ScheduleDailyDifferencesGridComponent };
