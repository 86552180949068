import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DateRange } from '../../../../../core/models/index';
import { WcNavigationService } from '../../../../../common/services/index';
import { OrgLevelType } from '../../../../../state-model/models/index';
import { GenerateOshaFormAction } from '../../../models/index';
import { WcRosterToolbarService } from '../../../services/index';
var WcRosterToolbarComponent = /** @class */ (function () {
    function WcRosterToolbarComponent(toolbarService, activatedRoute, router) {
        this.toolbarService = toolbarService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.dateRange = new DateRange(null, null);
        this.isActiveBtn = true;
        this.toolbarActionsAllowed = true;
        this.generatePDF300AAllowed = true;
        this.generateExcel301Allowed = true;
        this.generateExcel301AllowedDate = true;
        this.listHeight = 400;
        this.ytdList = [];
        this.ytdListDefaultVal = [];
        this.subscriptions = {};
        this.navService = new WcNavigationService(this.router, this.activatedRoute);
    }
    WcRosterToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.generateOshaFormAction = new GenerateOshaFormAction();
        this.viewsList = this.toolbarService.getViewsList();
        this.incidentsList = this.toolbarService.getIncidentsList();
        this.currentIncidentAmount = this.toolbarService.getCurrentIncident();
        this.ytdList = this.toolbarService.ytdListRender();
        this.ytdListDefaultVal = [moment().year()];
        this.onYTDChange(this.ytdListDefaultVal);
        this.subscriptions.activatedRoute = this.activatedRoute.url
            .subscribe(function (url) {
            _this.currentView = _this.toolbarService.defineView(url);
        });
        this.subscriptions.range = this.toolbarService
            .subscribeToInitDateRange(function (r) { return _this.assignInitDateRange(r); });
        this.subscriptions.state = this.toolbarService
            .subscribeToDefineState(function (s) { return (_this.state = s); });
        this.subscriptions.orgLevel = this.toolbarService
            .subscribeToOrgLevel(function (o) { return _this.getOrgLevel(o); });
        this.subscriptions.permissionsChanged = this.toolbarService
            .subscribeToPermissionsChanged(function (canAdd) { return (_this.canCreateNewIncident = canAdd); });
        this.subscriptions.toolbarActions = this.toolbarService
            .subscribeToToolbarActions(function (actionsAllowed) { return (_this.toolbarActionsAllowed = actionsAllowed); });
        this.subscriptions.newIncident = this.toolbarService
            .subscribeToNewIncident(function (isActive) { return (_this.isActiveBtn = isActive); });
    };
    WcRosterToolbarComponent.prototype.ngOnDestroy = function () {
        this.toolbarService.destroy();
    };
    WcRosterToolbarComponent.prototype.onYTDChange = function (selectedYearsList) {
        if (selectedYearsList) {
            selectedYearsList.sort();
        }
        else {
            selectedYearsList = [moment().year()];
        }
        this.toolbarService.selectedYearRange(selectedYearsList);
    };
    WcRosterToolbarComponent.prototype.onChangeDates = function (range) {
        this.generateExcel301AllowedTime(range);
        this.toolbarService.changeDateRange(range);
    };
    WcRosterToolbarComponent.prototype.onClickCreate = function () {
        this.toolbarService.clickNewIncident(false);
        this.toolbarService.openNewReportDialog();
    };
    WcRosterToolbarComponent.prototype.onChangeView = function () {
        if (!_.isObjectLike(this.prevView)) {
            this.prevView = this.currentView;
            return;
        }
        if (this.prevView.path !== this.currentView.path && _.get(this.orgLevel, 'id')) {
            this.prevView = this.currentView;
            this.navService.navigateByPath(this.orgLevel.id, this.currentView.path);
        }
    };
    WcRosterToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.toolbarService.exportTo(isPDF);
    };
    WcRosterToolbarComponent.prototype.onChangeIncidentsAmount = function () {
        this.toolbarService.changeIncidentsAmount(this.currentIncidentAmount.id);
    };
    WcRosterToolbarComponent.prototype.onClickToggleSelection = function (isSelectAll) {
        _.forEach(this.state.columns, function (column) {
            column.displayed = isSelectAll;
        });
        this.toolbarService.saveState(this.state);
    };
    WcRosterToolbarComponent.prototype.onChangeColumn = function () {
        this.toolbarService.saveState(this.state);
    };
    WcRosterToolbarComponent.prototype.isDesktop = function () {
        return screen.width > appConfig.mobileMaxWidth;
    };
    WcRosterToolbarComponent.prototype.getRangeWidth = function () {
        return this.isDesktop() ? 325 : 246;
    };
    WcRosterToolbarComponent.prototype.getBtnWidth = function () {
        return this.isDesktop() ? 175 : 35;
    };
    WcRosterToolbarComponent.prototype.getIncidentWidth = function () {
        return _.get(this.currentView, 'isRepeatInjuryList') ? 65 : 0;
    };
    WcRosterToolbarComponent.prototype.assignInitDateRange = function (r) {
        if (_.isDate(r.startDate) &&
            _.isDate(r.endDate) &&
            (!moment(this.dateRange.startDate).isSame(r.startDate) || !moment(this.dateRange.endDate).isSame(r.endDate))) {
            this.dateRange = r;
            this.generateExcel301AllowedTime(this.dateRange);
        }
    };
    WcRosterToolbarComponent.prototype.getOrgLevel = function (o) {
        this.orgLevel = o;
        this.generatePDF300AAllowed = false;
        this.generateExcel301Allowed = false;
        if (o.type === OrgLevelType.organization) {
            this.generatePDF300AAllowed = true;
            this.generateExcel301Allowed = true;
        }
    };
    WcRosterToolbarComponent.prototype.generateOSHAForm = function (formAction) {
        return this.toolbarService.generateOSHAForm300A(formAction);
    };
    WcRosterToolbarComponent.prototype.generateExcel301AllowedTime = function (range) {
        this.generateExcel301AllowedDate = (range.startDate.getFullYear() < 2016);
    };
    WcRosterToolbarComponent.prototype.getTitleValue = function () {
        if (this.generateExcel301AllowedDate) {
            return 'Per OSHA, the CSV cannot be generated for years prior to 2016';
        }
        else if (!this.generateExcel301Allowed) {
            return 'Please Select a Facility';
        }
        else {
            return ' ';
        }
    };
    Object.defineProperty(WcRosterToolbarComponent.prototype, "IsGenerateActionEnabled", {
        get: function () {
            return (!this.toolbarActionsAllowed || !this.generateExcel301Allowed || this.generateExcel301AllowedDate);
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcRosterToolbarComponent.prototype, "subscriptions", void 0);
    return WcRosterToolbarComponent;
}());
export { WcRosterToolbarComponent };
