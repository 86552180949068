
import {ScheduledShiftDefinition} from '../../../../organization/models/index';
import { MasterScheduleShiftInfo } from '../master-schedule-cell';

export class QuickEditOvelapDecisionState {
  public overlap: QuickEditOvelap;
  public decision: QuickEditOvelapDecision;
}

export enum QuickEditOvelapDecision {
  Replace, Override, Append, Cancel
}

export class QuickEditOvelap {
  public prevDayState: QuickEditOvelapState;
  public currentDayState: QuickEditOvelapState;
  public nextDayState: QuickEditOvelapState;
  public canOverride: boolean;
  public canAppend: boolean;
  public canReplace: boolean;
  public messages: string[];
}

export class QuickEditOvelapState {
  public leftOverlapShifts: MasterScheduleShiftInfo[] = [];
  public rightOverlapShifts: MasterScheduleShiftInfo[] = [];
  public status: QuickEditOvelapStatus;
}

export enum QuickEditOvelapStatus {
  NoOverlap = 0,
  OverlapLeft = 1 << 0,
  OverlapRight = 1 << 1,
  OverlapMiddleInside = 1 << 2, // new shift inside exist shift
  OverlapMiddleCover = 1 << 3, // new shift cover exist shift
}

export class QuickEditOvelapDecisionRequest {
  public overlap: QuickEditOvelap;
  public canCancel: boolean;

}

export class IndividualQuickEditOvelapState {
  public leftOverlapShifts: ScheduledShiftDefinition[] = [];
  public rightOverlapShifts: ScheduledShiftDefinition[] = [];
  public status: IndividualQuickEditOvelapStatus;
}

export enum IndividualQuickEditOvelapStatus {
  NoOverlap = 0,
  OverlapLeft = 1 << 0,
  OverlapRight = 1 << 1,
  OverlapMiddleInside = 1 << 2, // new shift inside exist shift
  OverlapMiddleCover = 1 << 3, // new shift cover exist shift
}

export class IndividualQuickEditOvelap {
  public prevDayState: IndividualQuickEditOvelapState;
  public currentDayState: IndividualQuickEditOvelapState;
  public nextDayState: IndividualQuickEditOvelapState;
  public canOverride: boolean;
  public canAppend: boolean;
  public canReplace: boolean;
  public messages: string[];
}
export class IndividualQuickEditOvelapDecisionState {
  public overlap: IndividualQuickEditOvelap;
  public decision: IndividualQuickEditOvelapDecision;
}

export enum IndividualQuickEditOvelapDecision {
  Replace, Override, Append, Cancel
}

export class IndividualQuickEditOvelapDecisionRequest {
  public overlap: IndividualQuickEditOvelap;
  public canCancel: boolean;
  public isMobile?: boolean;

}