import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService, PayCycle } from '../../../organization/index';
import { appConfig } from '../../../app.config';
import { AccrualsManagementService } from './accruals-management.service';
import { AccrualsApiService } from './accruals-api.service';
import { IAccrualsBalancesState, AccrualBalances, initialBalancesState, AccrualType } from '../models/index';
import { AccrualBalanceColumnsSettings, AccrualBalanceSettings } from '../models/accruals-column-settings';
import { ColumnManagementService,ColumnSettingsStorageService } from '../../../common/services/index';



@Injectable()
export class AccrualsBalancesManagementService {

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;
  private orgLevel: OrgLevel;

  private effectiveDate: Date;
  private filterByDate: string;
  private defaultDate: Date = new Date();
  private currentPayCycle: Promise<PayCycle>;

  private loading$ = new Subject<boolean>();
  private recordsLoaded$ = new Subject<AccrualBalances>();
  private effectiveDate$ = new ReplaySubject<Date>(1);
  private exportTo$ = new Subject<boolean>();
  private orgLevelChanged$ = new ReplaySubject<OrgLevel>(1);
  private stateChanged$ = new BehaviorSubject<IAccrualsBalancesState>(initialBalancesState);
  private calculateAccrualsAction$ = new Subject<boolean>();
  private columnState$ = new Subject<AccrualBalanceColumnsSettings>();
  private settings: AccrualBalanceSettings;
  public componentId: string = "AccrualsBalancesComponent";
  public columnsStateName: string = 'AccrualsBalances';
  public accrualTypes: AccrualType[];
  @unsubscribeAll('destroy')
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manService: AccrualsManagementService,
    private apiService: AccrualsApiService,
    private lookupApiService: LookupApiService,
    private activatedRoute: ActivatedRoute,
    private columnSettingsStorageService: ColumnSettingsStorageService,
    private columnManagementService: ColumnManagementService
    
  ) { }

  public init(): void {
    this.subscribeToUrlParam();
    this.subscribeToOrgLevelChanges();
    this.subscribeToLoadAccrualsBalances();
  }

  public destroy(): void {
    this.orgLevel = null;
    this.loading$.complete();
    this.recordsLoaded$.complete();
    this.effectiveDate$.complete();
    this.exportTo$.complete();
    this.orgLevelChanged$.complete();
    this.stateChanged$.complete();
    this.calculateAccrualsAction$.complete();
    this.columnState$.complete();
  }

  public async getDefaultEffectiveDate(): Promise<Date> {
    const payCycle = await this.currentPayCycle;
    return payCycle ? payCycle.endDate : this.defaultDate;
  }

  public changeEffectiveDate(effectiveDate: Date): void {
    this.effectiveDate = effectiveDate;
    this.effectiveDate$.next(effectiveDate);
    this.loadAccrualsBalances();
  }

  public updateState(state: IAccrualsBalancesState): void {
    this.stateChanged$.next(state);
  }

  public exportTo(isPDF: boolean): void {
    this.exportTo$.next(isPDF);
  }

  public saveColumnState(columnState: AccrualBalanceColumnsSettings):void{
    this.columnSettingsStorageService.setColumnsState(this.componentId, this.columnsStateName, columnState.allColumns);

  }
  public setColumnState(columnState: AccrualBalanceColumnsSettings): void {
    this.columnState$.next(_.cloneDeep(columnState));
    this.saveColumnState(columnState);
  }

  
  public restoreSettings(): void {
    this.settings = new AccrualBalanceSettings();    
    this.settings.columns = new AccrualBalanceColumnsSettings();
    this.settings.columns.createColumns();
    this.settings.columns.setDynamicColumns(this.accrualTypes)
    this.columnManagementService.init('AccrualsBalancesComponent');
    this.columnManagementService.initializeGroupWithColumns(this.columnsStateName, this.settings.columns.allColumns);
    this.subscriptions.state = this.columnManagementService.groupInitialized$
    .filter((event) => event.group === this.columnsStateName)
    .subscribe(() => {
      this.setColumnState(this.settings.columns);
    });
  }

  public initialColumnState(): AccrualBalanceColumnsSettings {
    this.settings = new AccrualBalanceSettings();    
    this.settings.columns = new AccrualBalanceColumnsSettings();
    this.settings.columns.createColumns();
    this.settings.columns.setDynamicColumns(this.accrualTypes)
    return this.settings.columns;

  }


  public subscribeToColumnState(callback: (columnState: AccrualBalanceColumnsSettings) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.columnState$.subscribe(callback);
  }

  public subscribeToState(callback: (state: IAccrualsBalancesState) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.stateChanged$.subscribe(callback);
  }
  public subscribeToEffectiveDate(callback: (effectiveDate: Date) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.effectiveDate$.subscribe(callback);
  }

  public subscribeToExport(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.exportTo$.subscribe(callback);
  }

  public subscribeToLoadedRecords(callback: (b: AccrualBalances) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.recordsLoaded$.subscribe(callback);
  }

  public subscribeToOrgLevel(callback: (o: OrgLevel) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.orgLevelChanged$.subscribe(callback);
  }

  public subscribeToLoading(callback: (v: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.loading$.subscribe(callback);
  }

  public subscribeTocalculateAccrualsAction(callback: (b: boolean) => void): Subscription {
    Assert.isNotNull(callback, 'callback');
    return this.calculateAccrualsAction$.subscribe(callback);
  }

  public loadAccrualsBalances(): void {
    if (!_.isFinite(_.get(this.orgLevel, 'id')) || !_.isDate(this.effectiveDate)) {
      return;
    }

    this.loading$.next(true);
    this.apiService.getAccrualBalances(this.orgLevel.id, this.effectiveDate)
      .then((container: AccrualBalances) => {
        this.recordsLoaded$.next(container);
        this.calculateAccrualsAction$.next(container.calculateAccruals);
        this.loading$.next(false);
        this.accrualTypes=container.accrualTypes;
        this.restoreSettings();
      })
      .catch(() => {
        this.loading$.next(false);
        this.restoreSettings();
      });
  }
  private subscribeToUrlParam(): void {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.filterByDate = queryParams ? (queryParams.filterByDate ? queryParams.filterByDate : null) : null;
     });
  }

  private subscribeToOrgLevelChanges(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .filter((o: OrgLevel) => o && _.isFinite(o.id))
      .subscribe((orgLevel: OrgLevel) => {
        if (_.isUndefined(this.filterByDate) || _.isNull(this.filterByDate)) {
          if (_.isFinite(_.get(this.orgLevel, 'id')) && this.orgLevel.id === orgLevel.id) return;
        }
        this.orgLevel = orgLevel;
        this.orgLevelChanged$.next(this.orgLevel);
        this.currentPayCycle = this.getCurrentPayCycle();
      });
  }

  private subscribeToLoadAccrualsBalances(): void {
    this.subscriptions.loadAccrualsBalances = this.manService.subscribeToLoadAccrualsBalances(() => this.loadAccrualsBalances());
  }

  private async getCurrentPayCycle(): Promise<PayCycle> {    
    if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
      return;
    }
    let currentPayCycle: PayCycle = new PayCycle();
    if (this.filterByDate) {     
      currentPayCycle.startDate = moment(this.filterByDate, appConfig.linkDateFormat).toDate();
      currentPayCycle.endDate = moment(this.filterByDate, appConfig.linkDateFormat).toDate();
    }
    else {
      const payCycles = await this.lookupApiService.getPayCyles(this.orgLevel.id);
      const currentDate: moment.Moment = moment();
      const currentCycles = _.filter(payCycles, (cycle) => {
        const currStartDate: moment.Moment = moment(cycle.startDate);
        const currEndDate: moment.Moment = moment(cycle.endDate);
        return moment(currentDate).isBetween(currStartDate, currEndDate);
      });
      currentPayCycle = _.first(currentCycles);
    }
    this.changeEffectiveDate(currentPayCycle ? currentPayCycle.endDate : this.defaultDate);

    return currentPayCycle;
  }

  
}
