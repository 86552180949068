import * as moment from 'moment';

import {
  LookupEntity, ILookupEntity,
  IOrganization, Organization,
  IDepartment, Department,
  ITimeclockDefinition, TimeclockDefinition,
  ITimeclockModelDefinition, TimeclockModelDefinition,
  ITimezoneDefinition, TimezoneDefinition
} from '../../../organization/models/index';

export interface ITimeclock {
  id: number;
  name: string;
  isVirtual: boolean;
  openShiftRequestsEnabled: boolean;
  organization: IOrganization;
  department: IDepartment;
  model: ITimeclockModelDefinition;
  physicalId: number;
  ipaddress: string;
  cmdMessageLine1: string;
  cmdMessageLine2: string;
  cmdMessageLine3: string;
  cmdMessageLine4: string;
  timezone: ITimezoneDefinition;
  lastUpdateDate: string;
  lastUpdateUsername: string;
  isMessagesEnabled: boolean;
  isQRPunchEnabled: boolean;
  clockOrganization: string;
}

export class Timeclock {
  public id: number;
  public name: string;
  public isVirtual: boolean;
  public openShiftRequestsEnabled: boolean;
  public organization: Organization;
  public department: Department;
  public model: TimeclockModelDefinition;
  public physicalId: number;
  public ipaddress: string;
  public cmdMessageLine1: string;
  public cmdMessageLine2: string;
  public cmdMessageLine3: string;
  public cmdMessageLine4: string;
  public timezone: TimezoneDefinition;
  public lastUpdateDate: Date;
  public lastUpdateUsername: string;
  public isMessagesEnabled: boolean;
  public isQRPunchEnabled: boolean;
  public isEditable: boolean = false;
  public isDisabled: boolean = false;
  public clockOrganization: string;
}

