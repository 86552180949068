import { PbjOrganizationsApiService } from './pbj-organizations-api.service';
var CCNNumberValidatorAdapter = /** @class */ (function () {
    function CCNNumberValidatorAdapter(pbjOrganizationsApiService) {
        this.pbjOrganizationsApiService = pbjOrganizationsApiService;
    }
    CCNNumberValidatorAdapter.prototype.validate = function (validationName, value) {
        var params = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            params[_i - 2] = arguments[_i];
        }
        var promise;
        var defPromise = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        switch (validationName) {
            case CCNNumberValidatorAdapter.ccnNumberValidation:
                promise = this.pbjOrganizationsApiService.validateCCN(value, params[0]);
                break;
        }
        return promise;
    };
    CCNNumberValidatorAdapter.ccnNumberValidation = 'ccnNumberValidation';
    return CCNNumberValidatorAdapter;
}());
export { CCNNumberValidatorAdapter };
