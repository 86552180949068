import * as tslib_1 from "tslib";
import { EmployeeSectionsBenefitsManagementApiService } from './employee-sections-benefits-management-api.service';
import { Subject } from 'rxjs/Subject';
import { unsubscribeAll } from '../../../../core/decorators';
import { ReplaySubject } from 'rxjs';
import * as _ from 'lodash';
import { Assert } from '../../../../framework';
import { EmployeeSectionsBenefitsManagement, EmployeeSubsectionEnrollment } from '../../models';
import { FileService } from '../../../../common/services/index';
import { ReadFile } from '../../../../organization/models/index';
import { EmployeeSectionsBenefitsCommonService } from './employee-sections-benefits-common.service';
var BenefitsEnrollmentSectionManagementService = /** @class */ (function () {
    function BenefitsEnrollmentSectionManagementService(commonManService, api, fileService) {
        this.commonManService = commonManService;
        this.api = api;
        this.fileService = fileService;
        this.isDedDateChangeOccured$ = new Subject();
        this.loading$ = new Subject();
        this.effectiveDate$ = new Subject();
        this.dataLoaded$ = new ReplaySubject();
        this.enrollmentsLoaded$ = new ReplaySubject();
        this.enrollmentsHistoryLoaded$ = new ReplaySubject();
        this.attachmentsLoaded$ = new ReplaySubject();
        this.enrollmentEmployeeInfo$ = new ReplaySubject();
        this.enrollmentUpdated$ = new ReplaySubject();
        this.enrollmentDeleted$ = new ReplaySubject();
        this.enrollments = [];
        this.enrollmentsHistory = [];
        this.subscriptions = {};
    }
    BenefitsEnrollmentSectionManagementService.prototype.destroy = function () { };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToChangePayrollDeductionDate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.isDedDateChangeOccured$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.changePayrollDeductionDate = function (hasDedDateError) {
        if (hasDedDateError === void 0) { hasDedDateError = false; }
        this.isDedDateChangeOccured$.next(hasDedDateError);
    };
    BenefitsEnrollmentSectionManagementService.prototype.getBenefitEnrollmentData = function (employeeId, effectiveDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sectionData, subSection;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        this.effectiveDate$.next(effectiveDate);
                        return [4 /*yield*/, this.api.getBenefitsManagementSection(employeeId, effectiveDate)];
                    case 1:
                        sectionData = _a.sent();
                        sectionData = sectionData || new EmployeeSectionsBenefitsManagement();
                        subSection = sectionData.enrollmentSubsection || new EmployeeSubsectionEnrollment();
                        this.setSectionInfo(subSection);
                        this.loading$.next(false);
                        return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.getEmployeeEnrollments = function (employeeId, effectiveDate, toggleDependentsSpinner) {
        if (toggleDependentsSpinner === void 0) { toggleDependentsSpinner = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var subSection;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        if (toggleDependentsSpinner)
                            this.commonManService.toggleDependentsSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.api.getEmployeeEnrollments(employeeId, effectiveDate)];
                    case 2:
                        subSection = _a.sent();
                        subSection = subSection || new EmployeeSubsectionEnrollment();
                        this.setSectionInfo(subSection);
                        if (toggleDependentsSpinner)
                            this.commonManService.updateDependents();
                        return [3 /*break*/, 4];
                    case 3:
                        this.updateSpinner(false);
                        this.updateEffectiveDate(effectiveDate);
                        if (toggleDependentsSpinner)
                            this.commonManService.toggleDependentsSpinner(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.getAttachmentsData = function (employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var attachmentSections, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.getBenefitsEnrollmentAttachment(employeeId)];
                    case 2:
                        attachmentSections = _a.sent();
                        this.setAttachmentSection(attachmentSections);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToEffectiveDate = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.effectiveDate$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToDataLoad = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dataLoaded$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToEnrollmentsLoad = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.enrollmentsLoaded$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToEnrollmentDeleted = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.enrollmentDeleted$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToEnrollmentsHistoryLoad = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.enrollmentsHistoryLoaded$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToEnrollmentUpdated = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.enrollmentUpdated$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToEmployeeInfo = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.enrollmentEmployeeInfo$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.subscribeToBenefitsAttachmentsLoad = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.attachmentsLoaded$.subscribe(callback);
    };
    BenefitsEnrollmentSectionManagementService.prototype.setSectionInfo = function (subSection) {
        this.enrollmentsLoaded$.next(subSection.enrollment);
        this.enrollmentsHistoryLoaded$.next(subSection.enrollmentHistory);
        this.enrollmentEmployeeInfo$.next(subSection.employeeInfo);
        this.dataLoaded$.next(subSection);
        this.enrollments = subSection.enrollment;
        this.enrollmentsHistory = subSection.enrollmentHistory;
    };
    BenefitsEnrollmentSectionManagementService.prototype.setAttachmentSection = function (attachmentSection) {
        this.attachmentsLoaded$.next(attachmentSection.benefitsManagementAttachmentsDTOs);
    };
    BenefitsEnrollmentSectionManagementService.prototype.updateSpinner = function (isLoading) {
        this.loading$.next(isLoading);
    };
    BenefitsEnrollmentSectionManagementService.prototype.updateEffectiveDate = function (effectiveDate) {
        this.effectiveDate$.next(effectiveDate);
    };
    BenefitsEnrollmentSectionManagementService.prototype.planSelected = function (employeeId, selectedRecord) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var id_1, notes_1, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        if (!selectedRecord) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.api.saveNotes(employeeId, selectedRecord)];
                    case 2:
                        _a.sent();
                        id_1 = selectedRecord.empToBenefitsId;
                        notes_1 = selectedRecord.notes;
                        _.each(this.enrollments, function (e) {
                            if (e.empToBenefitsId === id_1)
                                e.notes = notes_1;
                        });
                        _.each(this.enrollmentsHistory, function (e) {
                            if (e.empToBenefitsId === id_1)
                                e.notes = notes_1;
                        });
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 6];
                    case 5:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.readAddedFiles = function (files) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var promises, readedFiles, binaryData, err_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        promises = _.map(files, function (f) { return _this.readFileData(f); });
                        readedFiles = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        binaryData = _a.sent();
                        readedFiles = this.mapDateToReadFiles(files, binaryData);
                        return [3 /*break*/, 5];
                    case 3:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [3 /*break*/, 5];
                    case 4: return [2 /*return*/, readedFiles];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.mapDateToReadFiles = function (files, binaryData) {
        return _.map(files, function (file, i) {
            var index = file.name.lastIndexOf('.');
            var name = file.name.slice(0, index);
            var ext = file.name.slice(index + 1);
            var array = binaryData[i];
            var data = new Blob([array]);
            return new ReadFile(name, file.size, file.type, ext, data);
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.readFileData = function (file) {
        return new Promise(function (resolve, reject) {
            var fr = new FileReader();
            fr.onloadend = function () {
                var buffer = fr.result;
                resolve(buffer);
            };
            fr.onerror = function () {
                reject(fr.error);
            };
            fr.readAsArrayBuffer(file);
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.addFilesToSave = function (data, employeeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.addDocument(data.files, employeeId, data.name)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 5];
                    case 4:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.deleteAttachment = function (documentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.deleteAttachment(documentId)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 5];
                    case 4:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.downloadAttachment = function (attachmentId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.downloadAttachment(attachmentId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, file.file);
                        return [3 /*break*/, 5];
                    case 3:
                        e_5 = _a.sent();
                        console.error(e_5);
                        return [3 /*break*/, 5];
                    case 4:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.deleteEnrollment = function (enrollment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        this.commonManService.toggleDependentsSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.api.deleteEnrollment(enrollment.empToBenefitsId)];
                    case 2:
                        _a.sent();
                        this.commonManService.updateDependents();
                        this.enrollmentDeleted$.next(enrollment);
                        return [3 /*break*/, 5];
                    case 3:
                        e_6 = _a.sent();
                        // separate call needed to prevent hiding loader of next operation
                        this.updateSpinner(false);
                        return [3 /*break*/, 5];
                    case 4:
                        this.commonManService.toggleDependentsSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitsEnrollmentSectionManagementService.prototype.updateEnrollment = function (enrollment) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var enroll;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, this.api.updateEnrollment(enrollment)];
                    case 2:
                        enroll = _a.sent();
                        this.enrollmentUpdated$.next(enroll);
                        return [3 /*break*/, 4];
                    case 3:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], BenefitsEnrollmentSectionManagementService.prototype, "subscriptions", void 0);
    return BenefitsEnrollmentSectionManagementService;
}());
export { BenefitsEnrollmentSectionManagementService };
