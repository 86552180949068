import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { components, exportComponents, SlateProfileAssignmentComponent } from './components/index';
import { services } from './services/index';
import { routesModule } from './employee-list.routes';
import { directives, exportDirectives } from './directives/index';

import { CommonModule as CommonAppModule } from '../../common/index';
import { OrganizationModule } from '../../organization/index';
import { LookupApiService } from '../../organization/services/index';
import { TextMaskModule } from 'angular2-text-mask';
import { ACTION_PROVIDERS } from './store/index';
import { rootEpics } from '../../state-model/state-model.module';
import { MomentModule } from 'angular2-moment';
import { EmployeeModule } from '../../employee/employee/index';
import { EmployeeSectionsModule } from '../../employee/employee-sections/index';
import { NgxPopperModule } from 'ngx-popper';

import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { EPIC_PROVIDERS, EmployeeListEpics } from './epics/index';

import { OperationalConsoleDialogComponent, DynamicPolicyDialogComponent, AssignAccrualsPolicyComponent, ResendOptSmsComponent, SendEmailInstructionsComponent } from './components/index';
import { ComponentsLibraryModule } from '../../components-library/index';
import { MassRateAdjustmentsDialogComponent } from '../employee-list/components/mass-rate-adjustments/mass-rate-adjustments.component';

@NgModule({
  imports: [
    CommonModule,
    OrganizationModule,
    EmployeeModule,
    EmployeeSectionsModule,
    CommonAppModule,
    routesModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    GridModule,
    ExcelModule,
    PDFModule,
    MomentModule,
    NgxPopperModule,
    ComponentsLibraryModule
  ],
  entryComponents: [OperationalConsoleDialogComponent, DynamicPolicyDialogComponent, AssignAccrualsPolicyComponent, ResendOptSmsComponent, SendEmailInstructionsComponent, MassRateAdjustmentsDialogComponent, SlateProfileAssignmentComponent],
  declarations: [...components, ...directives],
  providers: [...services, ...ACTION_PROVIDERS, ...EPIC_PROVIDERS, LookupApiService],
  exports: [
    ...exportDirectives,
    ...exportComponents
  ]
})
export class EmployeeListModule {
   constructor(employeeListEpics: EmployeeListEpics) {
    rootEpics.push(employeeListEpics.resetView);
    //rootEpics.push(employeeListEpics.resetIsActive);
  }
}
