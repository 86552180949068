import { Component, OnInit, OnDestroy, Input, Host, NgZone, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewChecked, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Assert } from '../../../../../framework/index';
import { AppServerConfig } from './../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../../app-settings/services/app-settings-manage.service';
import { DataStateChangeEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, process } from '@progress/kendo-data-query';
import { ExcelExportData, Workbook, WorkbookSheet, WorkbookSheetRow } from '@progress/kendo-angular-excel-export';
import { BoolYNPipe } from '../../../../../common/pipes/boolyn';
import * as _ from 'lodash';
import { LoaRequest } from '../../../../../app-modules/leave-management/models/index';
import { KendoGridStateHelper } from '../../../../../common/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import * as moment from 'moment';
import { appConfig } from '../../../../../app.config';
import { RangeDates } from '../../../../../common/models/range-dates';
import { RolesApiService } from '../../../../../configuration/services';
import { EmployeeSectionsPositionHistory, EmployeeSectionsBase, EmployeeSectionsPositionHistoryRecord } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { SessionService } from '../../../../../core/services';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-position-history',
  templateUrl: 'employee-sections-position-history.component.html',
  styleUrls: ['employee-sections-position-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsPositionHistoryComponent extends EmployeeSectionsBasicComponent implements OnInit, AfterViewChecked, OnDestroy {
  public employeeName: string;
  public employeePosition: string;
  public employeeType: string;
  public employeePayRollNumber: string;
  public createdDateTime: string = moment().format(appConfig.militaryDateTimeFormat);

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  @Input('employeeSectionsPositionHistory')
  public set positionHistory(employeeSectionsPositionHistory: EmployeeSectionsPositionHistory) {
    this.employeeSectionsPositionHistory = employeeSectionsPositionHistory;
    this.refreshGrid();
    this.primaryRefreshGrid();
    this.secondaryRefreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  @ViewChildren('kendoGrid')
  public kendoGrid: QueryList<GridComponent>;
  
  @Input()
  public employeeId: number;
  public sort: SortDescriptor[] = [];
  public primarySort: SortDescriptor[] = [];
  public secondarySort: SortDescriptor[] = [];
  public hiddenColumns: string[] = [];
  public gridView: GridDataResult;
  public startDate: Date;
  public endDate: Date;
  public primaryGridState: KendoGridStateHelper<LoaRequest>;
  public secondaryGridState: KendoGridStateHelper<LoaRequest>;
  public showPositionAttributes: boolean;
  public showLatestUpdates: boolean;
  public selectedRange: RangeDates;
  public pageSize: number = 10;
  public primaryfilteredRecords: EmployeeSectionsPositionHistoryRecord[];
  public secondaryfilteredRecords: EmployeeSectionsPositionHistoryRecord[];
  public primaryUniqueRecords: EmployeeSectionsPositionHistoryRecord[];
  public secondaryUniqueRecords: EmployeeSectionsPositionHistoryRecord[];
  public isPositionHistoryWithAttributeEnabled = false;
  isAuthorizedUser: boolean = false;
  public pdfTemplate: any = {
    allPages: true,
    landscape: true,
    paperSize: 'A4',
    scale: 0.7,
    repeatHeaders: true,
    margin: '0.25in',
    marginTop: '0.675in',
    marginBottom: '0.5in',
    marginRight: '0.25in',
    marginLeft: '0.25in'
  }

  public get form(): AbstractControl {
    return null;
  }
  
  private employeeSectionsPositionHistory: EmployeeSectionsPositionHistory;
  private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService;


  constructor(employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone,
    private changeDetector: ChangeDetectorRef,
    private appSettingsManageService: AppSettingsManageService,
    private rolesApiService: RolesApiService,
    private sessionService: SessionService,
    private boolPipe: BoolYNPipe) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
    this.primaryGridState = new KendoGridStateHelper<LoaRequest>();
    this.secondaryGridState = new KendoGridStateHelper<LoaRequest>();
    this.primaryGridState.state.take = this.pageSize;
    this.secondaryGridState.state.take = this.pageSize;
    this.primaryGridState.state.skip = 0;
    this.secondaryGridState.state.skip = 0;
    this.sort = [{ field: 'archivedDate.fieldValue', dir: 'desc' }];
    this.primarySort = [{ field: 'archivedDate.fieldValue', dir: 'desc' }];
    this.secondarySort = [{ field: 'archivedDate.fieldValue', dir: 'desc' }];
    this.primaryGridState.state.sort = this.primarySort;
    this.secondaryGridState.state.sort = this.secondarySort;  
    const endDate: moment.Moment = moment().endOf('day');
    this.endDate = endDate.toDate();
    this.startDate = endDate.subtract(1, 'year').startOf('day').toDate();
    this.selectedRange = new RangeDates();
    this.selectedRange.startDate = this.startDate;
    this.selectedRange.endDate = this.endDate;
    this.employeeName = this.employeeShortInfo.fullName;
    this.employeePosition = this.employeeShortInfo.position.name;
    this.employeeType = this.employeeShortInfo.type;
    this.employeePayRollNumber = this.employeeShortInfo.payrollNumber;
  }
  public ngOnInit(): void {
    this.getSettings();
    this.subscriptions.primaryGridRefreshSubscription = this.primaryGridState.onRefreshGrid.subscribe((v): void => {
      this.primaryRefreshGrid();
    });   

    this.subscriptions.SecondaryGridRefreshSubscription = this.secondaryGridState.onRefreshGrid.subscribe((v): void => {
      this.secondaryRefreshGrid();
    });
    let roles, userData, loginRole: string;
    userData = this.sessionService.getUser();
    loginRole = userData.roles[0];
    this.rolesApiService.getRoleAccessTable().then(res => {
      roles = res.roles;
      roles.forEach(ele => {
        if (loginRole.toLowerCase() === ele.name.toLowerCase() && ele.profile) {
          ele.profile.sections.forEach(section => {
            if(section.name.toLowerCase() === 'employee'){
              section.subsections.forEach(subsection => {
                if (subsection.name.toLowerCase() === 'position history') {
                  subsection.fields.forEach(field => {
                    this.isPositionHistoryDataExists(field);                    
                  })
                }
              })
            }
          })
        }
      })
    });
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();  
  }

  isPositionHistoryDataExists(field){
    if(field.name.toLowerCase() === 'positionhistorydata' && field.editable)
      this.isAuthorizedUser = true;
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsPositionHistory;
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.refreshGrid();
  }

  public sortChangeWithGrid(sort: SortDescriptor[], gridType: string): void {
    if(gridType.toLowerCase() === "primary"){
      this.primarySort = sort;
      this.primaryRefreshGrid();
    }
    else{
      this.secondarySort = sort;
      this.secondaryRefreshGrid();
    }
  }

  protected loadSubsection(showpositionattribute?: string): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmploymentPositionHistory(this.employeeId, showpositionattribute)
      .then((employeeSectionsPositionHistory: EmployeeSectionsPositionHistory) => {
        this.employeeSectionsPositionHistory = employeeSectionsPositionHistory;
        this.primaryRefreshGrid();
        this.secondaryRefreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
        this.stopProgress();
      }).catch(() => {
        this.stopProgress();
      });
  }

  private refreshGrid(): void {
    if (!this.employeeSectionsPositionHistory) {
      this.gridView = null;
      return;
    }
    this.gridView = {
      data: orderBy(this.employeeSectionsPositionHistory.records, this.sort),
      total: this.employeeSectionsPositionHistory.records.length
    };
  }

  private primaryRefreshGrid(): void {
    if (!this.employeeSectionsPositionHistory) {
      this.primaryGridState.view = null;
      return;
    }
    this.primaryfilteredRecords = _.filter(this.employeeSectionsPositionHistory.primaryRecords);
    let sortedRecords: EmployeeSectionsPositionHistoryRecord[] = orderBy(this.primaryfilteredRecords, this.sort);
    this.primaryGridState.view = process(sortedRecords, this.primaryGridState.state);

  }

  secondaryRefreshGrid(): void {
    if (!this.employeeSectionsPositionHistory) {
      this.secondaryGridState.view = null;
      return;
    }
    this.secondaryfilteredRecords = _.filter(this.employeeSectionsPositionHistory.secondaryRecords)
    let sortedsecondaryRecords: EmployeeSectionsPositionHistoryRecord[] = orderBy(this.secondaryfilteredRecords, this.sort);
    this.secondaryGridState.view = process(sortedsecondaryRecords, this.secondaryGridState.state);

  }
  public dataStateChange(state: DataStateChangeEvent): void {
    this.primaryGridState.state = state;
    this.primaryRefreshGrid();
    this.secondaryRefreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public hideColumns(): void {    
    if (this.showPositionAttributes) {
      if(this.showLatestUpdates){
        this.showLatestUpdates = false;
      }      
      this.loadSubsection('positionAttributes');
      this.hiddenColumns.push('payPolicy', 'shiftDiffPolicy', 'costCenter');
    }
    else {
      if(!this.showLatestUpdates){
        this.loadSubsection('default');
        this.hiddenColumns = [];
      }
    }
  }

  public OnLatestUpdates(): void {
    if(this.showLatestUpdates){
      if(this.showPositionAttributes){
        this.showPositionAttributes = false;
      }
      this.hiddenColumns = [];
      this.loadSubsection('latestUpdates');
    }
    else{
      if(!this.showPositionAttributes){
        this.loadSubsection('default');
        this.hiddenColumns = [];
      }
    }
  }

  public onClickExportPdf(position: string): void {
    if (position.toLowerCase() === 'pdf') {
      this.kendoGrid.forEach( e => {
        e.saveAsPDF();
      }); 
    }
  }

  public onClickExportExcel(position: string): void {
    if (position.toLowerCase() === 'excel') {
      this.kendoGrid.forEach( e => {
        e.saveAsExcel();
      }); 
    }
  }

  public getPositionHistoryFileName(exportType: string, gridType: string): string {
    if (exportType.toLowerCase() === 'pdf' && gridType.toLowerCase() === 'primary') {
      return this.employeeName + ' Primary_Position_History_From ' + this.getPositionHistoryDates() + '.pdf';
    }
    else if(exportType.toLowerCase() === 'pdf' && gridType.toLowerCase() === 'secondary'){
      return this.employeeName + ' Secondary_Position_History_From ' + this.getPositionHistoryDates() + '.pdf';
    }
    else if(exportType.toLowerCase() === 'excel' && gridType.toLowerCase() === 'primary'){
      return this.employeeName + ' Primary_Position_History_From ' + this.getPositionHistoryDates() + '.xlsx';
    }
    else{
      return this.employeeName + ' Secondary_Position_History_From ' + this.getPositionHistoryDates() + '.xlsx';
    }
  }

  public getPositionHistoryTitle(gridType: string): string {
    if(gridType.toLowerCase() === "primary"){
      return 'Primary Position History for the Period ' + this.getPositionHistoryDates();
    }
    else{
      return 'Secondary Position History for the Period ' + this.getPositionHistoryDates();
    }
  }

  public getPositionHistoryDates(): string {
    return moment(this.selectedRange.startDate).format(appConfig.dateFormat) + ' to ' + moment(this.selectedRange.endDate).format(appConfig.dateFormat);
  }

  public getPositionHistoryEmployeeInfo(): string {
    return this.employeeName + '   ' + this.employeePosition + '   ' + this.employeeType + '   ' + this.employeePayRollNumber;
  }

  public retriveAllPages(gridType: string): () => ExcelExportData {
    if(gridType.toLowerCase() === 'primary'){
      return () => ({
        data: process(this.primaryfilteredRecords, { sort: this.primaryGridState.state.sort, filter: this.primaryGridState.state.filter }).data
      }) as ExcelExportData;
    }
    else{
      return () => ({
        data: process(this.secondaryfilteredRecords, { sort: this.secondaryGridState.state.sort, filter: this.secondaryGridState.state.filter }).data
      }) as ExcelExportData;
    }

  }

  public onClickExportPositionHistory(e: { workbook: Workbook }): void {
    const sheets: WorkbookSheet = _.head(_.get(e, 'workbook.sheets'));
    _.forEach(sheets.rows, (row: WorkbookSheetRow) => {
      if (row.type.toLowerCase() === 'data') {
        _.forEach(row.cells, (cell) => {
          if (_.isBoolean(cell.value)) {
            cell.value = this.boolPipe.transform(cell.value);
          }
        });
      }
    });
  }
  private getSettings() {
    this.appSettingsManageService.getAppServerConfig()
      .then((conf: AppServerConfig) => {
        this.isPositionHistoryWithAttributeEnabled = conf.ispositionHistoryWithAttributeEnabled;
      });
  }

  onRefreshClicked(){
    if(this.showPositionAttributes){
      this.loadSubsection('positionAttributes');
    }
    else{
      this.loadSubsection('default');
    }
  }
}
