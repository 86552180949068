import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../../common/services/index';

//import { BenefitDependentDroppedEnrollment } from '../../../app-modules/benefits/models/index';
import { BenefitPlanSaveNotesDialogComponent } from '../components/employee-sections-benefits-management/employee-sections-enrollment/benfit-plan-notes-dialog-box/benfit-plan-notes-dialog-box';
import { EnrollmentHistoryRecord } from '../models';
import { EmployeeSectionBridgeService } from '../services';

@Directive({
    selector: '[slxSaveNotesDialog]',
})
export class EmployeeEnrollmentSaveNotesDirective {
    constructor(private modalService: ModalService, private bridgeService: EmployeeSectionBridgeService) { }
    @Input()
    public selectedRecord: EnrollmentHistoryRecord;
    @Input()
    public employeeName: string;
    @Output()
    public saveNotes = new EventEmitter<any>();

    @HostListener('click', ['$event'])
    public onClick(e: MouseEvent): void {
        this.bridgeService.sectionsAreVisible = false;
        const dialog = BenefitPlanSaveNotesDialogComponent.openDialog(
            this.selectedRecord,
            this.employeeName,
            this.modalService,
            (result: boolean) => {
                if (result) {
                    this.selectedRecord.notes = dialog.notes;
                    this.saveNotes.emit(this.selectedRecord);
                }
                this.bridgeService.sectionsAreVisible = true;
            }
        );
    }
}
