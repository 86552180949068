import * as moment from 'moment';
import { dateTimeUtils } from '../../../common/utils/index';
var DailyAbsence = /** @class */ (function () {
    function DailyAbsence() {
    }
    Object.defineProperty(DailyAbsence.prototype, "rawIntervalMin", {
        get: function () {
            return this.rawInterval / 60000;
        },
        set: function (value) {
            this.rawInterval = value * 60000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyAbsence.prototype, "intervalMin", {
        get: function () {
            return this.interval / 60000;
        },
        set: function (value) {
            this.interval = value * 60000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyAbsence.prototype, "unpaidIntervalMin", {
        get: function () {
            return this.unpaidInterval / 60000;
        },
        set: function (value) {
            this.unpaidInterval = value * 60000;
        },
        enumerable: true,
        configurable: true
    });
    DailyAbsence.prototype.calculateInterval = function () {
        if (moment(this.end).isSameOrAfter(moment(this.start))) {
            var diff = moment.range(this.start, this.end).diff();
            var unpaidDuration = 0;
            if (this.shift) {
                var dateRange = dateTimeUtils.getDateRangeFromTimeRange(this.start, this.shift.start, this.shift.end);
                var shiftTime = moment.range(dateRange.start, dateRange.end).diff();
                var shiftPaidDuration = dateTimeUtils.getTimeTotalSeconds(this.shift.duration) * 1000;
                if (shiftTime > shiftPaidDuration) {
                    unpaidDuration = shiftTime - shiftPaidDuration;
                }
            }
            this.interval = (diff - unpaidDuration);
            this.unpaidInterval = unpaidDuration;
        }
        else {
            this.interval = 0;
            this.unpaidInterval = 0;
        }
    };
    DailyAbsence.prototype.copyIntervalToRaw = function () {
        this.rawInterval = this.interval + this.unpaidInterval;
    };
    DailyAbsence.prototype.checkHasLinkedSa = function () {
        if (!this.absence || !this.shift) {
            this.hasLinkedSa = false;
            return;
        }
        this.hasLinkedSa = !!this.absence.code;
    };
    return DailyAbsence;
}());
export { DailyAbsence };
var DailyAllAbsence = /** @class */ (function () {
    function DailyAllAbsence() {
    }
    Object.defineProperty(DailyAllAbsence.prototype, "rawIntervalMin", {
        get: function () {
            return this.rawInterval / 60000;
        },
        set: function (value) {
            this.rawInterval = value * 60000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyAllAbsence.prototype, "intervalMin", {
        get: function () {
            return this.interval / 60000;
        },
        set: function (value) {
            this.interval = value * 60000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DailyAllAbsence.prototype, "unpaidIntervalMin", {
        get: function () {
            return this.unpaidInterval / 60000;
        },
        set: function (value) {
            this.unpaidInterval = value * 60000;
        },
        enumerable: true,
        configurable: true
    });
    DailyAllAbsence.prototype.calculateInterval = function () {
        if (moment(this.end).isSameOrAfter(moment(this.start))) {
            var diff = moment.range(this.start, this.end).diff();
            var unpaidDuration = 0;
            if (this.shift) {
                var dateRange = dateTimeUtils.getDateRangeFromTimeRange(this.start, this.shift.start, this.shift.end);
                var shiftTime = moment.range(dateRange.start, dateRange.end).diff();
                var shiftPaidDuration = dateTimeUtils.getTimeTotalSeconds(this.shift.duration) * 1000;
                if (shiftTime > shiftPaidDuration) {
                    unpaidDuration = shiftTime - shiftPaidDuration;
                }
            }
            this.interval = (diff - unpaidDuration);
            this.unpaidInterval = unpaidDuration;
        }
        else {
            this.interval = 0;
            this.unpaidInterval = 0;
        }
    };
    DailyAllAbsence.prototype.copyIntervalToRaw = function () {
        this.rawInterval = this.interval + this.unpaidInterval;
    };
    DailyAllAbsence.prototype.checkHasLinkedSa = function () {
        if (!this.absence || !this.shift) {
            this.hasLinkedSa = false;
            return;
        }
        this.hasLinkedSa = !!this.absence.code;
    };
    return DailyAllAbsence;
}());
export { DailyAllAbsence };
