import { Directive, Input, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const WORK_HOURS_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => WorkHoursValidator),
  multi: true
};

@Directive({
  selector: '[workHoursValidation][formControlName],[workHoursValidation][formControl],[workHoursValidation][ngModel]',
  providers: [WORK_HOURS_VALIDATOR]
})
export class WorkHoursValidator implements Validator, OnChanges {
  @Input()
  public workHoursValidation: string;

  @Input()
  public workHoursValidationStrict: boolean = false;

  private _validator: ValidatorFn;
  private _onChange: () => void;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['workHoursValidation']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return _.isFinite(parseFloat(this.workHoursValidation)) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.workHoursValidation(parseFloat(this.workHoursValidation), this.workHoursValidationStrict);
  }
}
