import { ApplicationContainerComponent, LeftSidebarComponent } from './components/index';
import { BenefitConsoleComponent, BenefitDetailsComponent } from '../app-modules/benefits/components/index';
import { LMRosterComponent, LMConsoleComponent } from '../app-modules/leave-management/components/index';
import { WcIncidentRosterComponent, WcComparisonsComponent, WcCreateOshaRkFormsComponent, WcIncidentTrackingComponent, WcOshaBudgetTrackerComponent, WcRepeatInjuryComponent, WcSummaryComponent } from '../app-modules/workers-compensation/components/index';
import { PmRosterComponent, PmEmployeeRosterComponent } from '../app-modules/performance-management/components/index';
import { OrgLevelResolver } from './services/index';
import { LoaFilterResolver } from '../app-modules/leave-management/services/index';
import { CanLeaveGuard } from '../common/services/change-management/can-leave.guard';
var ɵ0 = { componentId: 'loa_console', accessContext: 'OrgLevel' }, ɵ1 = { componentId: 'loa_console', accessContext: 'OrgLevel' }, ɵ2 = { componentId: 'loa_roster', accessContext: 'OrgLevel' }, ɵ3 = { componentId: 'incident_roster', accessContext: 'OrgLevel' }, ɵ4 = { componentId: 'incident_roster', accessContext: 'OrgLevel' }, ɵ5 = { componentId: 'incident_roster', accessContext: 'OrgLevel' }, ɵ6 = { componentId: 'incident_roster', accessContext: 'OrgLevel' }, ɵ7 = { componentId: 'incident_roster', accessContext: 'OrgLevel' }, ɵ8 = { componentId: 'incident_roster', accessContext: 'OrgLevel' }, ɵ9 = { componentId: 'incident_roster', accessContext: 'OrgLevel' }, ɵ10 = { componentId: 'performance_management_roster', accessContext: 'OrgLevel' }, ɵ11 = { componentId: 'performance_management_roster', accessContext: 'Employee', orgLevelChangeDisabled: true }, ɵ12 = { componentId: 'benefits_management_console', accessContext: 'OrgLevel' }, ɵ13 = { componentId: 'benefits_management_details', accessContext: 'OrgLevel' };
export var hrRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                outlet: 'leftSidebar',
                component: LeftSidebarComponent
            },
            {
                path: '',
                resolve: [OrgLevelResolver],
                redirectTo: 'loa_console',
                pathMatch: 'full',
                data: ɵ0
            },
            {
                path: 'loa_console',
                resolve: [OrgLevelResolver],
                component: LMConsoleComponent,
                data: ɵ1
            },
            {
                resolve: [OrgLevelResolver, LoaFilterResolver],
                path: 'loa_roster',
                component: LMRosterComponent,
                data: ɵ2
            },
            {
                path: 'incident_roster',
                component: WcIncidentRosterComponent,
                data: ɵ3
            },
            {
                path: 'wc_comparisons',
                component: WcComparisonsComponent,
                data: ɵ4
            },
            {
                path: 'create_osha_recordkeeping_forms',
                component: WcCreateOshaRkFormsComponent,
                data: ɵ5
            },
            {
                path: 'incident_tracking',
                component: WcIncidentTrackingComponent,
                data: ɵ6
            },
            {
                path: 'osha_budget_tracker',
                component: WcOshaBudgetTrackerComponent,
                data: ɵ7
            },
            {
                path: 'repeat_injury_list',
                component: WcRepeatInjuryComponent,
                data: ɵ8
            },
            {
                path: 'wc_summary',
                component: WcSummaryComponent,
                data: ɵ9
            },
            {
                path: 'performance_management_roster',
                resolve: [OrgLevelResolver],
                children: [
                    {
                        path: '',
                        component: PmRosterComponent,
                        resolve: [OrgLevelResolver],
                        data: ɵ10,
                    },
                    {
                        path: 'employee/:employeeId',
                        resolve: [OrgLevelResolver],
                        component: PmEmployeeRosterComponent,
                        data: ɵ11
                    }
                ]
            },
            {
                path: 'benefits_management_console',
                component: BenefitConsoleComponent,
                data: ɵ12
            },
            {
                path: 'benefits_management_details',
                component: BenefitDetailsComponent,
                canDeactivate: [CanLeaveGuard],
                runGuardsAndResolvers: 'paramsOrQueryParamsChange',
                data: ɵ13
            }
        ]
    },
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
