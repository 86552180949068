import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ModalService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Organization, Department } from '../../../../organization/models/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { appConfig } from '../../../../app.config';
import { Timeclock, TimeclockRemoveRequest } from '../../../models/index';
import { EditableListComponent } from '../../editableList/editable-list.component';
import { TimeclockRemoveDialogComponent } from '../timeclock-remove-dialog/timeclock-remove-dialog.component';
import { AccessManagementService, TimeclocksApiService } from '../../../services/index';
var TimeclocksListComponent = /** @class */ (function () {
    function TimeclocksListComponent(timeclocksApiService, modalService, stateManagement) {
        var _this = this;
        this.selectedItems = [];
        this.timeclocksApiService = timeclocksApiService;
        this.modalService = modalService;
        this.m_stateManagement = stateManagement;
        this.onDeleteSelected = function () {
            _this.deleteSelected();
        };
        this.onAddItem = function () {
            _this.addItem();
        };
    }
    Object.defineProperty(TimeclocksListComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimeclocksListComponent.prototype, "stateManagement", {
        get: function () {
            return this.m_stateManagement;
        },
        enumerable: true,
        configurable: true
    });
    TimeclocksListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false
        };
        this.m_stateManagement.allowCorporationLevel = false;
        this.m_stateManagement.allowOrganizationLevel = true;
        this.m_stateManagement.allowDepartmentLevel = true;
        this.state.isLoading = this.m_stateManagement.lockActions = true;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.state.isLoading = true;
            _this.currentOrgLevel = orgLevel;
            _this.m_stateManagement.orgLevelType = _this.currentOrgLevel.type;
            if (_this.currentOrgLevel.type === OrgLevelType.organization) {
                _this.currentOrganization = new Organization();
                _this.currentOrganization.orgLevelId = _this.currentOrgLevel.id;
                _this.currentOrganization.name = _this.currentOrgLevel.name;
                _this.currentDepartment = undefined;
            }
            else if (_this.currentOrgLevel.type === OrgLevelType.department) {
                _this.currentOrganization = new Organization();
                _this.currentOrganization.orgLevelId = _this.currentOrgLevel.parentId;
                _this.currentOrganization.name = '';
                _this.currentDepartment = new Department();
                _this.currentDepartment.orgLevelId = _this.currentOrgLevel.id;
                _this.currentDepartment.name = _this.currentOrgLevel.name;
            }
            else {
                _this.currentOrganization = undefined;
                _this.currentDepartment = undefined;
            }
            _this.timeclocksApiService.getTimeclocks(_this.currentOrgLevel.id).
                then(function (container) {
                _this.m_container = container;
                _this.m_stateManagement.actions = container.actions;
                _this.state.isLoading = _this.m_stateManagement.lockActions = false;
            }).catch(function (e) {
                _this.state.isLoading = _this.m_stateManagement.lockActions = false;
            });
        });
    };
    TimeclocksListComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    TimeclocksListComponent.prototype.onItemEditStart = function (items) {
        this.m_stateManagement.lockActions = true;
    };
    TimeclocksListComponent.prototype.onItemEditCancel = function (items) {
        this.m_stateManagement.lockActions = false;
    };
    TimeclocksListComponent.prototype.onItemSave = function (items) {
        var _this = this;
        if (!items || items.length === 0) {
            return;
        }
        var item = items[0];
        this.state.isLoading = this.m_stateManagement.lockActions = true;
        if (item.id !== 0) {
            this.timeclocksApiService.saveTimeclock(item, this.currentOrgLevel.id)
                .then(function () {
                _this.state.isLoading = _this.m_stateManagement.lockActions = false;
            }).catch(function () {
                _this.state.isLoading = _this.m_stateManagement.lockActions = false;
            });
        }
        else {
            this.timeclocksApiService.addTimeclock(item, this.currentOrgLevel.id)
                .then(function (newItem) {
                item.id = newItem.id;
                _this.state.isLoading = _this.m_stateManagement.lockActions = false;
            }).catch(function () {
                _this.state.isLoading = _this.m_stateManagement.lockActions = false;
            });
        }
    };
    TimeclocksListComponent.prototype.addItem = function () {
        var newItem = new Timeclock();
        newItem.id = 0;
        newItem.organization = this.currentOrganization;
        newItem.department = this.currentDepartment;
        this.list.addItem(newItem);
    };
    TimeclocksListComponent.prototype.onSelectionChange = function (items) {
        this.m_stateManagement.selectedItemsCount = items.length;
        this.selectedItems = items;
    };
    TimeclocksListComponent.prototype.deleteSelected = function () {
        var _this = this;
        if (!this.selectedItems) {
            return;
        }
        if (this.selectedItems.length === 1) {
            var req = new TimeclockRemoveRequest();
            req.timeclock = this.selectedItems[0];
            TimeclockRemoveDialogComponent.openDialog(req, this.modalService, function (result, cmd) {
                if (result) {
                    _this.doDelete(cmd);
                }
            });
        }
        else {
            alert('Mass delete operation not implemented');
        }
    };
    TimeclocksListComponent.prototype.doDelete = function (req) {
        var _this = this;
        this.state.isLoading = this.m_stateManagement.lockActions = true;
        this.timeclocksApiService.removeTimeclock(req, this.currentOrgLevel.id)
            .then(function () {
            _this.state.isLoading = _this.m_stateManagement.lockActions = false;
            _.pullAll(_this.container.records, _this.selectedItems);
            _this.selectedItems = [];
        })
            .catch(function (e) {
            _this.state.isLoading = _this.m_stateManagement.lockActions = false;
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], TimeclocksListComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimeclocksListComponent.prototype, "orgLevelSubscription", void 0);
    return TimeclocksListComponent;
}());
export { TimeclocksListComponent };
