import * as tslib_1 from "tslib";
import { ToolbarBaseService } from '../../../core/services/index';
var IdealScheduleToolbarService = /** @class */ (function (_super) {
    tslib_1.__extends(IdealScheduleToolbarService, _super);
    function IdealScheduleToolbarService() {
        return _super.call(this) || this;
    }
    return IdealScheduleToolbarService;
}(ToolbarBaseService));
export { IdealScheduleToolbarService };
