import { Component, Input, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { FileBlobResponse } from '../../../core/models/api/file-blob-response';
import { FileService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { scheduleMicrotask } from '../../../core/utils/index';

import { DailyTimecardManagementService, editAction, DailyTimecardToolbarService } from '../../services/index';
import { ColumnManagementService, ChangeManagementService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { TimeCardModel, EarningStatistic } from '../../models/index';
import { TimecardsNavigationService, ApprovalPayCodesNavigationService, ApprovalOvertimesNavigationService } from '../../../common/services/navigation/index';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import { MessagesService } from '../../../components-library/services/index';
import { TOOLBAR_SERVICE } from '../../../core/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-timecard',
  templateUrl: 'daily-timecard.component.html',
  styleUrls: ['daily-timecard.component.scss'],
  providers: [DailyTimecardManagementService, ColumnManagementService, StateManagementService, MessagesService,
    { provide: TOOLBAR_SERVICE, useClass: DailyTimecardToolbarService }
  ]
})
export class DailyTimeCardComponent implements OnInit, OnDestroy, AfterViewInit {

  public state: {
    isLoading: boolean;
    punchesLocked: boolean;
    scheduleLocked: boolean;
    paycodesLocked: boolean;
    absencesLocked: boolean;
  };

  public timecard: TimeCardModel;
  public canEditTimecard: boolean;
  public punchesHeight: string = '50%';
  public paycodesHeight: string = '50%';

  public summaryHeight: string = '25%';
  public scheduleHeight: string = '25%';
  public absencesHeight: string = '25%';
  public commentsHeight: string = '25%';

  public summaryIsCollapsed: boolean;
  public scheduleIsCollapsed: boolean = true;
  public absencesIsCollapsed: boolean = true;
  public commentsIsCollapsed: boolean = true;

  public abscenceCount: number = 0;

  @unsubscribe()
  private routeSubscripion: Subscription;
  @unsubscribe()
  private stateSubscripion: Subscription;
  @unsubscribe()
  private editActionSubscripion: Subscription;
  @unsubscribe()
  private loadSubscripion: Subscription;
  @unsubscribe()
  private exportSubscription: Subscription;
  @unsubscribe()
  private canEditChangedSubscription: Subscription;
  @unsubscribe()
  private changeDateSubscripion: Subscription;
  @unsubscribe()
  private navigateToPCSubscripion: Subscription;
  @unsubscribe()
  private navigateToOTSubscripion: Subscription;
  @unsubscribe()
  private abscenceCountSubscription: Subscription;

  private punchesIsCollapsed: boolean;
  private paycodesIsCollapsed: boolean;

  constructor(private fileService: FileService, private managementService: DailyTimecardManagementService,
    private router: Router, private activatedRoute: ActivatedRoute, private stateManagementService: StateManagementService,
    private changeManagementService: ChangeManagementService, private messagesService: MessagesService) {
    this.state = {
      isLoading: false,
      punchesLocked: false,
      scheduleLocked: false,
      paycodesLocked: false,
      absencesLocked: false,
    };
    this.subscribeRouter(this.activatedRoute);
  }

  public ngAfterViewInit(): void {
    scheduleMicrotask(() => {
      this.recalcLeftPanel();
      this.recalcRightPanel();
    });
  }
  public ngOnInit(): void {
    this.editActionSubscripion = this.managementService.sectionEditAction$.subscribe((action: editAction) => {
      if (action.source === 'punches') {
        this.state.scheduleLocked = action.lockOthers;
        this.state.paycodesLocked = action.lockOthers;
        this.state.absencesLocked = action.lockOthers;
      }
      if (action.source === 'absences') {
        this.state.punchesLocked = action.lockOthers;
        this.state.scheduleLocked = action.lockOthers;
        this.state.paycodesLocked = action.lockOthers;
      }
      if (action.source === 'paycodes') {
        this.state.punchesLocked = action.lockOthers;
        this.state.scheduleLocked = action.lockOthers;
        this.state.absencesLocked = action.lockOthers;
      }
      if (action.source === 'schedule') {
        this.state.punchesLocked = action.lockOthers;
        this.state.paycodesLocked = action.lockOthers;
        this.state.absencesLocked = action.lockOthers;
      }
    });

    this.stateSubscripion = this.managementService.onLoadStatus$.subscribe((isLoading: boolean) => {
      this.state.isLoading = isLoading;
    });

    this.loadSubscripion = this.managementService.onLoaded$.subscribe((timecard: TimeCardModel) => {
      this.messagesService.resetClosed();
      this.timecard = timecard;
      this.abscenceCount = this.managementService.getAbsencescount(this.timecard.absences);
      this.updateAbscenseCount(this.abscenceCount);
    });

    this.exportSubscription = this.managementService.exportedTimecard$
      .subscribe((file: FileBlobResponse) => this.fileService.saveToFileSystem(file.blob, file.file));

    this.canEditChangedSubscription = this.managementService.canEditChanged$
      .subscribe((canEdit: boolean) => {
        this.canEditTimecard = canEdit;
      });

    this.changeDateSubscripion = this.managementService.changedDate$.subscribe((date: Date) => {
      this.setTimecardDate(date);
    });

    this.navigateToPCSubscripion = this.managementService.navigateToUnApprovedPC$.subscribe(() => {
      this.navigateToUnApprovedPC();
    });

    this.abscenceCountSubscription = this.managementService.abscenceCount$.subscribe((v: number) => {
      this.updateAbscenseCount(v);
    });

    this.navigateToOTSubscripion = this.managementService.navigateToUnApprovedOT$.subscribe((homeOrgLevelId: number) => {
      this.navigateToUnApprovedOT(homeOrgLevelId);
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onCommentChanged(value: string): void {
    if (this.timecard) {
      this.timecard.comment = value;
    }
  }

  public setTimecardDate(d: Date): void {
    let navigationservice: TimecardsNavigationService = new TimecardsNavigationService(this.router, this.activatedRoute);
    navigationservice.NavigateToDailyTimecards(this.timecard.employee.id, d);
  }

  public punchesCollapsedChange(isCollapsed: boolean): void {
    this.punchesIsCollapsed = isCollapsed;
    this.recalcLeftPanel();
  }

  public paycodesCollapsedChange(isCollapsed: boolean): void {
    this.paycodesIsCollapsed = isCollapsed;
    this.recalcLeftPanel();
  }

  public recalcLeftPanel(): void {
    const oneHeight = 'calc(100% - 70px)';
    const twoHeight = 'calc(50% - 15px)';
    if (!this.punchesIsCollapsed && this.paycodesIsCollapsed) {
      this.punchesHeight = oneHeight;
      this.paycodesHeight = twoHeight;
      return;
    }
    if (this.punchesIsCollapsed && !this.paycodesIsCollapsed) {
      this.punchesHeight = twoHeight;
      this.paycodesHeight = oneHeight;
      return;
    }
    this.paycodesHeight = twoHeight;
    this.punchesHeight = twoHeight;
  }

  public summaryCollapsedChange(isCollapsed: boolean): void {
    this.summaryIsCollapsed = isCollapsed;
    this.recalcRightPanel();
  }
  public scheduleCollapsedChange(isCollapsed: boolean): void {
    this.scheduleIsCollapsed = isCollapsed;
    this.recalcRightPanel();
  }
  public absencesCollapsedChange(isCollapsed: boolean): void {
    this.absencesIsCollapsed = isCollapsed;
    this.recalcRightPanel();
  }
  public commentsCollapsedChange(isCollapsed: boolean): void {
    this.commentsIsCollapsed = isCollapsed;
    this.recalcRightPanel();
  }

  public recalcRightPanel(): void {
    const oneHeight = 'calc(100% - 180px)';
    const twoHeight = 'calc(50% - 70px)';
    const threeHeight = 'calc(33% - 31px)';
    const fourHeight = 'calc(25% - 15px)';
    if (this.summaryIsCollapsed && this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
      this.summaryHeight = fourHeight;
      this.scheduleHeight = fourHeight;
      this.absencesHeight = fourHeight;
      this.commentsHeight = oneHeight;
      return;
    }
    if (this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
      this.summaryHeight = fourHeight;
      this.scheduleHeight = fourHeight;
      this.absencesHeight = oneHeight;
      this.commentsHeight = fourHeight;
      return;
    }
    if (this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && !this.commentsIsCollapsed) {
      this.summaryHeight = fourHeight;
      this.scheduleHeight = fourHeight;
      this.absencesHeight = twoHeight;
      this.commentsHeight = twoHeight;
      return;
    }
    if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && this.commentsIsCollapsed) {
      this.summaryHeight = fourHeight;
      this.scheduleHeight = oneHeight;
      this.absencesHeight = fourHeight;
      this.commentsHeight = fourHeight;
      return;
    }
    if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
      this.summaryHeight = fourHeight;
      this.scheduleHeight = twoHeight;
      this.absencesHeight = fourHeight;
      this.commentsHeight = twoHeight;
      return;
    }
    if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
      this.summaryHeight = fourHeight;
      this.scheduleHeight = twoHeight;
      this.absencesHeight = twoHeight;
      this.commentsHeight = fourHeight;
      return;
    }
    if (this.summaryIsCollapsed && !this.scheduleIsCollapsed && !this.absencesIsCollapsed && !this.commentsIsCollapsed) {
      this.summaryHeight = fourHeight;
      this.scheduleHeight = threeHeight;
      this.absencesHeight = threeHeight;
      this.commentsHeight = threeHeight;
      return;
    }

    if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && this.absencesIsCollapsed && this.commentsIsCollapsed) {
      this.summaryHeight = oneHeight;
      this.scheduleHeight = fourHeight;
      this.absencesHeight = fourHeight;
      this.commentsHeight = fourHeight;
      return;
    }
    if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
      this.summaryHeight = twoHeight;
      this.scheduleHeight = fourHeight;
      this.absencesHeight = fourHeight;
      this.commentsHeight = twoHeight;
      return;
    }
    if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
      this.summaryHeight = twoHeight;
      this.scheduleHeight = fourHeight;
      this.absencesHeight = twoHeight;
      this.commentsHeight = fourHeight;
      return;
    }
    if (!this.summaryIsCollapsed && this.scheduleIsCollapsed && !this.absencesIsCollapsed && !this.commentsIsCollapsed) {
      this.summaryHeight = threeHeight;
      this.scheduleHeight = fourHeight;
      this.absencesHeight = threeHeight;
      this.commentsHeight = threeHeight;
      return;
    }
    if (!this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && this.commentsIsCollapsed) {
      this.summaryHeight = twoHeight;
      this.scheduleHeight = twoHeight;
      this.absencesHeight = fourHeight;
      this.commentsHeight = fourHeight;
      return;
    }
    if (!this.summaryIsCollapsed && !this.scheduleIsCollapsed && this.absencesIsCollapsed && !this.commentsIsCollapsed) {
      this.summaryHeight = threeHeight;
      this.scheduleHeight = threeHeight;
      this.absencesHeight = fourHeight;
      this.commentsHeight = threeHeight;
      return;
    }
    if (!this.summaryIsCollapsed && !this.scheduleIsCollapsed && !this.absencesIsCollapsed && this.commentsIsCollapsed) {
      this.summaryHeight = threeHeight;
      this.scheduleHeight = threeHeight;
      this.absencesHeight = threeHeight;
      this.commentsHeight = fourHeight;
      return;
    }
    this.summaryHeight = fourHeight;
    this.scheduleHeight = fourHeight;
    this.absencesHeight = fourHeight;
    this.commentsHeight = fourHeight;
  }

  private subscribeRouter(activatedRoute: ActivatedRoute): void {
    this.routeSubscripion = this.activatedRoute.params.combineLatest(this.activatedRoute.queryParams).subscribe(([params, queryParams]) => {
      let employeeId: string = params['employeeId'];
      let dateOn: string = params['date'];
      let date: Date = moment(dateOn, appConfig.requestDate).toDate();
      this.managementService.setRouteData(+employeeId, date);
    });
  }

  private navigateToUnApprovedPC(): void {
    let navigationservice: ApprovalPayCodesNavigationService = new ApprovalPayCodesNavigationService(this.router, this.activatedRoute);
    navigationservice.navigateToPayCodesApproval(this.timecard.date, this.timecard.date);
  }

  private navigateToUnApprovedOT(homeOrgLevelId: number): void {
    let navigationservice: ApprovalOvertimesNavigationService = new ApprovalOvertimesNavigationService(this.router, this.activatedRoute);
    navigationservice.navigateToOvertimeApproval(this.timecard.date, this.timecard.date, undefined, homeOrgLevelId);
  }

  public updateAbscenseCount(v: number) {
    this.abscenceCount = v;
  }
}
