import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { UserProfileNavigationService } from '../../services/index';
import { Location } from '@angular/common';

@Directive({
  /* tslint:disable */
  selector: '[userProfileLink]',
  /* tslint:enable */
})
export class UserProfileLinkDirective {

  @Input()
  public userProfileLink: number;


  constructor(private router: Router, private route: ActivatedRoute, private location: Location) { }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: UserProfileNavigationService = new UserProfileNavigationService(this.router, this.route, this.location);
    if (this.userProfileLink) {
      navService.navigateToUserProfile(this.userProfileLink);
    } else {
      navService.navigateToUsersScreen();
    }
  }
}
