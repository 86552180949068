//TODO: Create new semd sms service and this class there
export interface IRecipientInfo {
  recipientId: number;
  recipientType: string;
}

export class RecipientInfo {
  public recipientId: number;
  public name: string;
  public phoneNumber: string;
  public recipientType: RecipientType;
  public get displayName(): string {
    return `${this.name} (${this.phoneNumber})`;
  }

  constructor(recipientId: number, name: string, phoneNumber: string, recipientType: RecipientType) {
    this.name = name;
    this.phoneNumber = phoneNumber,
    this.recipientId = recipientId;
    this.recipientType = recipientType;
  }
}

export enum RecipientType {
  user,
  employee
}
