import { FieldData, FieldsMeta } from '../../../../core/models/index';
//import { EmployeeSectionsMeta } from '../index';
import {
  EmpType,
  IEmpType,
  Position,
  IPosition,
  PayPolicy,
  IPayPolicy,
  ShiftDiffPolicy,
  IShiftDiffPolicy,
  AccrualPolicy,
  IAccrualPolicy,
  PayType,
  ExemptStatus,
  Supervisor,
} from '../../../../organization/models/lookup/index';

import { EmployeeSectionsBase } from '../employee-sections-base';
import { EmployeePayrollEffectiveDateSettings } from './employee-sections-payroll-effectivedate';
import { EmployeeCostCenterAllocation, EmployeePayRateComponent, IEmployeeCostCenterAllocation, IEmployeeCostCenterAllocations, IEmployeePayRateComponent, IEmployeePayRateComponents } from '../../../employee/models/index';

export interface IEmployeeSectionsPayroll {
  payrollNumber: string;
  fileNumber: string;
  ssn: string;
  hireDate: string;
  rehireDate: Date;
  empType: IEmpType;
  orgNavBreadcrumb: string;
  ftDate: string;
  acaType: string;
  benefitHours: number;
  lastIncreaseDate: string;
  position: IPosition;
  payPolicy: IPayPolicy;
  shiftDiffPolicy: IShiftDiffPolicy;
  payType: string;
  exemptStatus: string;
  payRate: number;
  payRate2: number;
  payRate3: number;
  payRateComponents: IEmployeePayRateComponents;
  standardWeeklyHours: number;
  annualSalary: number;
  costCenterCode: string;
  costCenterAllocations: IEmployeeCostCenterAllocations;
  homeOrgLevelId: number;
  isPayrollNumberRequired: boolean;
  isEnableRate4DecimalPlaces: boolean;
  isPayRateComponents: boolean;
  pbjId: string;
  union: string;
  maxAllowableHireDate: string;
  isEnabledInreaseReason: boolean;
  inreaseReason: number;
  supervisor : Supervisor;
}

export class EmployeeSectionsPayroll extends EmployeeSectionsBase {
  public payrollNumber: FieldData<string>;
  public fileNumber: FieldData<string>;
  public ssn: FieldData<string>;
  public hireDate: FieldData<Date>;
  public empType: FieldData<EmpType>;
  public orgNavBreadcrumb: string;
  public ftDate: FieldData<Date>;
  public acaType: FieldData<string>;
  public benefitHours: FieldData<number>;
  public lastIncreaseDate: FieldData<Date>;
  public position: FieldData<Position>;
  public payPolicy: FieldData<PayPolicy>;
  public shiftDiffPolicy: FieldData<ShiftDiffPolicy>;
  public payType: FieldData<PayType>;
  public exemptStatus: FieldData<ExemptStatus>;
  public payRate: FieldData<number>;
  public payRate2: FieldData<number>;
  public payRate3: FieldData<number>;
  public payRateComponents: FieldData<EmployeePayRateComponent[]>;
  public standardWeeklyHours: FieldData<number>;
  public annualSalary: FieldData<number>;
  public costCenterCode: FieldData<string>;
  public costCenterAllocations: FieldData<EmployeeCostCenterAllocation[]>;
  public pbjId: FieldData<string>;
  public homeOrgLevelId: number;
  public isPayrollNumberRequired: boolean;
  public fieldsMeta: FieldsMeta;
  public union: FieldData<string>;
  public effectiveDateSetting: EmployeePayrollEffectiveDateSettings;
  public isEnableRate4DecimalPlaces: boolean;
  public isPayRateComponents: boolean;
  public maxAllowableHireDate: Date;
  public isEnabledInreaseReason: boolean;
  public icnreaseReason: number;
  public supervisor: Supervisor;
  public rehireDate: Date;
  
  public areBenefitsFieldsDirty(): boolean {
    return this.payrollNumber.isDirty ||
      this.empType.isDirty ||
      this.benefitHours.isDirty ||
      this.ftDate.isDirty ||
      this.lastIncreaseDate.isDirty ||
      this.hireDate.isDirty ||
      this.union.isDirty ||
      this.position.isDirty ||
      this.payPolicy.isDirty ||
      this.shiftDiffPolicy.isDirty ||
      this.payType.isDirty ||
      this.exemptStatus.isDirty ||
      this.payRate.isDirty ||
      this.standardWeeklyHours.isDirty ||
      this.annualSalary.isDirty;
  }
  public isOnlyPayRate(): boolean {
    return this.payRate.isDirty &&
      !this.position.isDirty &&
      !this.exemptStatus.isDirty &&
      !this.payType.isDirty &&
      !this.standardWeeklyHours.isDirty &&
      !this.shiftDiffPolicy.isDirty &&
      !this.payPolicy.isDirty;
  }
  public isNotOnlyPayRate(): boolean {
    return (this.position.isDirty ||
      this.exemptStatus.isDirty ||
      this.payType.isDirty ||
      this.standardWeeklyHours.isDirty ||
      this.shiftDiffPolicy.isDirty ||
      this.payPolicy.isDirty);
  }
}
