<div class="slx-el-container-row" [ngClass]="{'slx-el-row-alt': rowIndex%2 == 0}">
  <div class="slx-el-row">
    <div class="slx-el-col hxs slx-el-mobile-hide">
      <button class="slx-el-button" (click)="onToggleCollapseClick()"><i class="fa"
          [ngClass]="{'fa-minus': !isCollapsed, 'fa-plus': isCollapsed}" aria-hidden="true"></i></button>
    </div>
    <div *ngIf="selectable" class="slx-el-col xs slx-el-mobile-hide">
      <slx-input-decorator className="slx-no-label slx-no-border slx-small-font slx-no-padding slx-checkbox-center">
        <slx-checkbox-input slx-input name="select{{rowIndex}}" fieldName="select{{rowIndex}}" placeholder="" caption=""
          [(ngModel)]="selected" (ngModelChange)="onItemSelect()">
        </slx-checkbox-input>
      </slx-input-decorator>
    </div>
    <div *ngIf="editable" class="slx-el-col hxs slx-el-mobile-hide">
      <button class="slx-el-edit-button" (click)="onEditClick()"></button>
    </div>

    <div class="slx-el-col xs slx-el-mobile-display">
      <div *ngIf="selectable">
        <slx-input-decorator className="slx-no-label slx-no-border slx-small-font slx-no-padding slx-checkbox-center">
          <slx-checkbox-input slx-input name="select{{rowIndex}}" fieldName="select{{rowIndex}}" placeholder=""
            caption="" [(ngModel)]="selected" (ngModelChange)="onItemSelect()">
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div *ngIf="editable">
        <button class="slx-el-edit-button" (click)="onEditClick()"></button>
      </div>
    </div>

    <div *ngIf="stateManagement.canEdit" class="slx-el-col ohxs">
      <button type="button" class="slx-button slx-only-icon slx-toolbar" *ngIf="!item.isEditable"
        (click)="onEditMode(item)">
        <em class="fas fa-pencil-alt"></em>
      </button>
      <button type="button" class="slx-button slx-only-icon slx-toolbar slx-margin-r" *ngIf="item.isEditable"
        (click)="onSave(item)">
        <em class="far fa-check-circle"></em>
      </button>
      <button type="button" class="slx-button slx-only-icon slx-toolbar" *ngIf="item.isEditable"
        (click)="onCancel(item)">
        <em class="fa fa-ban"></em>
      </button>
    </div>

    <div class="slx-el-col md">
      <span class="slx-el-col-title">Organization: </span> {{ item.organization?.name }}
    </div>

    <div class="slx-el-col md">
      <span class="slx-el-col-title">Department : </span> {{ item.department?.name }}
    </div>

    <div class="slx-el-col md">
      <span class="slx-el-col-title">Clock Name: </span>{{ item.name }}
    </div>

    <div class="slx-el-col md" *ngIf="!item.isEditable">
      <span class="slx-el-col-title">Clock Model: </span>{{ item.model?.name }}
    </div>

    <div class="slx-el-col md" *ngIf="item.isEditable">
      <span class="slx-el-col-title">Clock Model: </span>
      <slx-dropdown-list 
        [options]="clockModelLookup" 
        [(ngModel)]="item.model" 
        valueField="id" 
        titleField="name"
        name="model{{rowIndex}}" 
        (ngModelChange)="onClockModelSelected(item.model)">
      </slx-dropdown-list>
    </div>


    <div class="slx-el-col ohxs">
      <span class="slx-el-col-title">Clock ID: </span> {{ item.id }}
    </div>

    <div class="slx-el-col ohxs">
      <span class="slx-el-col-title">Physical ID: </span> {{ item.physicalId }}
    </div>

    <div class="slx-el-col sm">
      <span class="slx-el-col-title">IP Address: </span> {{ item.ipaddress }}
    </div>
    <div class="slx-el-col xs slx-el-mobile-display">
      <button class="slx-el-button" (click)="onToggleCollapseClick()"><i class="fa"
          [ngClass]="{'fa-minus': !isCollapsed, 'fa-plus': isCollapsed}" aria-hidden="true"></i></button>
    </div>
  </div>


  <slx-button-collapse-panel [hideButton]="true" [collapsed]="isCollapsed">
    <div class="slx-el-details">
      <div class="slx-el-detail-header slx-el-row">
        <div class="slx-el-col xs">
          <span class="slx-el-header-title">Is Virtual</span>
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-header-title">Open Shift Requests Enabled</span>
        </div>
        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-header-title">CMD Message 1</span>
        </div>
        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-header-title">CMD Message 2</span>
        </div>
        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-header-title">CMD Message 3</span>
        </div>
        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-header-title">CMD Message 4</span>
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-header-title">QR Punch</span>
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-header-title">TimeZone</span>
        </div>

        <div class="slx-el-col sm">
          <span class="slx-el-header-title">Last Update</span>
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-header-title">User Name</span>
        </div>
      </div>
      <div class="slx-el-row">
        <div class="slx-el-col xs">
          <span class="slx-el-col-title">Is Virtual: </span> {{ item.isVirtual | boolyn }}
        </div>
        <div class="slx-el-col md">
          <span class="slx-el-col-title">Open Shift Requests Enabled: </span>
          {{ item.openShiftRequestsEnabled | boolyn }}
        </div>
        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-col-title">CMD Message 1: </span> {{ item.cmdMessageLine1 }}
        </div>

        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-col-title">CMD Message 2: </span> {{ item.cmdMessageLine2 }}
        </div>

        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-col-title">CMD Message 3: </span> {{ item.cmdMessageLine3 }}
        </div>

        <div class="slx-el-col md" *ngIf="item.isMessagesEnabled">
          <span class="slx-el-col-title">CMD Message 4: </span> {{ item.cmdMessageLine4 }}
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-col-title">QR Punch </span>
          <slx-switcher labelOn="ON" labelOff="OFF" [disabled]="!enableQR" [(ngModel)]="item.isQRPunchEnabled">
          </slx-switcher>
          <div class="error text-danger" errorMessage for="error" *ngIf="eIdErrorMessage"><span>
              <i class="fa fa-info-circle" aria-hidden="true"></i> Enterprise ID has not been configured </span></div>
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-col-title">TimeZone: </span> {{ item.timezone?.name }}
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-col-title">Last Update:
          </span>{{ item.lastUpdateDate | amDateFormat: appConfig.dateFormat }}
        </div>
        <div class="slx-el-col sm">
          <span class="slx-el-col-title">User Name: </span>{{ item.lastUpdateUsername }}
        </div>
      </div>

    </div>

  </slx-button-collapse-panel>
</div>