import { Injectable } from "@angular/core";
import { HttpRequest } from "@angular/common/http";
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { Meta, ResponseBody } from '../../../../core/models/index';
import { PayrollPayCode, PayrollPayCodeModel } from "../../../models/payroll-pay-codes/payroll-pay-code";
import { PayrollPayCodesMapService } from "./payroll-pay-codes-map.service";
import { appConfig } from '../../../../app.config';
import { configurationConfig } from '../../../configuration.config';

@Injectable()
export class PayrollPayCodesApiService {

    constructor(
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: PayrollPayCodesMapService
    ) { }

    public getPayrollPayCodes(): Promise<PayrollPayCode[]> {
        const url: string = `${this.getConfigurationApiRoot()}/paycodes`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
        let promise: Promise<any> = this.apiUtilService
            .request<any[], Meta>(request)
            .then((response: ResponseBody<[], Meta>) => {
                const list: PayrollPayCode[] = this.mapService.mapPayrollPayCodes(response.data);
                return list;
            });
        return promise;
    }

    public getPayNumbers(): Promise<PayrollPayCodeModel> {
        const url: string = `${this.getConfigurationApiRoot()}/payroll/paynumbers`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
        let promise: Promise<any> = this.apiUtilService
            .request<any[], Meta>(request)
            .then((response: ResponseBody<[], Meta>) => {
                return response;
            });
        return promise;
    }

    public save(payCodes: PayrollPayCode[]): Promise<any> {
        const url: string = `${this.getConfigurationApiRoot()}/payroll/paycodes`;

        let body: any = this.mapService.mapPayrollPayCodesDto(payCodes);
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
        return this.apiUtilService.request<any[], Meta>(request)
        .then((response: ResponseBody<any, Meta>) => response);
    }

    private getConfigurationApiRoot(): string {
        return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }
}