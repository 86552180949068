import { FieldsMeta } from '../../../../core/models/index';
import { IEmployeeBenefitClass, EmployeeBenefitClass } from '../../../../organization/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IEmployeeSubsectionBenefitClasses {
  employeeBenefitClasses: IEmployeeBenefitClass[];
}

export class EmployeeSubsectionBenefitClasses extends EmployeeSectionsBase {
    public empBenefitClasses: EmployeeBenefitClass[];
    public fieldsMeta: FieldsMeta;
  }
