import * as _ from 'lodash';
import { Directive, Input, OnInit, OnDestroy, ViewContainerRef, TemplateRef } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { SupportDevices, DevicesDefinition, DependsOnDeviceTypeHelper } from '../../models/index';
import { DeviceDetectorService } from '../../services/index';

@Directive({
  selector: '[slxHiddenOn]'
})
export class HiddenOnDirective extends DependsOnDeviceTypeHelper implements OnInit, OnDestroy {
  @Input()
  public set slxHiddenOn(v: DevicesDefinition[]) {
    if (_.isArray(v)) {
      this.devices = v;
      this.validateDevices();
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    protected devDetector: DeviceDetectorService
  ) {
    super();
    this.deviceList = this.devDetector.deviceList;
  }

  protected updateView(): void {
    const isHidden = this.deviceHasInDeviceList();
    this.viewContainer.clear();
    if (!isHidden) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
