import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { ColumnSettingsStorageService, ColumnManagementService, ColumnsChangedEvent } from '../../../../common/services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var ColumnsConfigButtonComponent = /** @class */ (function () {
    function ColumnsConfigButtonComponent(columnSettingsStorageService, columnManagementService, changeDetector) {
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.columnManagementService = columnManagementService;
        this.changeDetector = changeDetector;
        this.colGroupKey = ColumnSettingsStorageService.defaultColumnGroupKey;
        this.popperPosition = 'bottom-start';
        this.width = 250;
        this.columnChanged = new EventEmitter();
        this.selectedAll = new EventEmitter();
        this.clearedAll = new EventEmitter();
        this.uniqId = this.getComponentUniqueId();
    }
    ColumnsConfigButtonComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.groupInitializedSubscription = this.columnManagementService.groupInitialized$.filter(function (event) { return event.group === _this.colGroupKey; })
            .subscribe(function (event) {
            _this.isInitialized = true;
            _this.contextKey = event.component;
            _this.columns = event.columns;
            _.forEach(_this.columns, function (column) {
                if (column.displayed === undefined) {
                    column.displayed = true;
                }
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            });
        });
    };
    ColumnsConfigButtonComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ColumnsConfigButtonComponent.prototype.onChangeColumn = function (column) {
        this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
        this.columnChanged.emit(column);
        this.saveColumns();
    };
    ColumnsConfigButtonComponent.prototype.selectAll = function () {
        _.forEach(this.columns, function (column) {
            column.displayed = true;
        });
        this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
        this.selectedAll.emit(this.columns);
        this.saveColumns();
    };
    ColumnsConfigButtonComponent.prototype.clearAll = function () {
        _.forEach(this.columns, function (column) {
            column.displayed = false;
        });
        this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
        this.clearedAll.emit(this.columns);
        this.saveColumns();
    };
    ColumnsConfigButtonComponent.prototype.getComponentUniqueId = function () {
        return "b" + Math.random().toString(36).substr(2, 9);
    };
    ColumnsConfigButtonComponent.prototype.saveColumns = function () {
        this.columnSettingsStorageService.setColumnsState(this.contextKey, this.colGroupKey, this.columns);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ColumnsConfigButtonComponent.prototype, "groupInitializedSubscription", void 0);
    return ColumnsConfigButtonComponent;
}());
export { ColumnsConfigButtonComponent };
