import * as moment from 'moment';

import { IDatedObject, WeeklyData } from '../../../core/models/index';
import { ScheduleEntryDefinition, EmployeeDefinition, Position, ShiftDefinition, LocationUnit } from '../../../organization/models/index';
import { StringUtils } from '../../../framework/index';

export interface IEmployeeMessage {
  id: number;
  timestamp: Date;
  text: string;
  state: string;
  direction: string;
  source: string;
  senderId: number;
  receiverId: number;
  senderName: string;
  receiverName: string;
  senderMobilePhone: string;
  partnerAgencyName: string;
  partnerShiftId: number;
  partnerShiftCount:number;
  partnerId:string;
  isPositionGroupInd: number;
}

export enum EmployeeMessageAction {
  read,
  approveShift,
  denyShift
}

export enum EmployeeMessageState {
  new,
  read,
  closed,
  reopened,
  requested,
  scheduled,
  ignored,
  sent
}

export enum EmployeeMessageSource {
  system,
  sms
}

export enum EmployeeMessageDirection {
  inbound,
  outbound
}

export interface IOpenShiftMessageCountChangedEventArgs {
  dateOn: Date;
  messageCountDiff: number;
}

export class EmployeeMessage {
  public get canRead(): boolean {
    return this.state === EmployeeMessageState.new || this.state === EmployeeMessageState.requested;
  }

  public get isInbound(): boolean {
    return this.direction === EmployeeMessageDirection.inbound;
  }

  public get isOutbound(): boolean {
    return this.direction === EmployeeMessageDirection.outbound;
  }

  public get isNew(): boolean {
    return this.state === EmployeeMessageState.new;
  }

  public get isReaded(): boolean {
    return this.state === EmployeeMessageState.read;
  }

  public get isClosed(): boolean {
    return this.state === EmployeeMessageState.closed;
  }

  public get isReopened(): boolean {
    return this.state === EmployeeMessageState.reopened;
  }

  public get isRequested(): boolean {
    return this.state === EmployeeMessageState.requested;
  }

  public get isScheduled(): boolean {
    return this.state === EmployeeMessageState.scheduled;
  }

  public get isIgnored(): boolean {
    return this.state === EmployeeMessageState.ignored;
  }

  public get isSent(): boolean {
    return this.state === EmployeeMessageState.sent;
  }

  public get isSystem(): boolean {
    return this.source === EmployeeMessageSource.system;
  }

  public get isSms(): boolean {
    return this.source === EmployeeMessageSource.sms;
  }

  public id: number;
  public timestamp: Date;
  public text: string;
  public state: EmployeeMessageState;
  public direction: EmployeeMessageDirection;
  public source: EmployeeMessageSource;
  public senderId: number;
  public receiverId: number;
  public senderName: string;
  public receiverName: string;
  public senderMobilePhone: string;
  public stateName: string;
  public partnerAgencyName: string;
  public partnerShiftId: number;
  public partnerShiftCount:number;
  public partnerId:string; 
  public isPositionGroupInd: number;
  public get IsPositionGrouped(): boolean {
    return this.isPositionGroupInd == 1 ? true : false;
  }
  public get messageId(): string {
    return StringUtils.format('message{0}', this.id);
  }
  public get messageAnchor(): string {
    return StringUtils.format('#{0}', this.messageId);
  }
}
