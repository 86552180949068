import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common';
import { process } from '@progress/kendo-data-query';
import { SlateMessageGroupInfo } from '../../models/slate-message-group-info';
import { SlateMessagesApiService } from '../../services/slate-messages-api.service';
import { Observable } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { SessionService } from '../../../../core/services';
var SlateMessageContentComponent = /** @class */ (function () {
    function SlateMessageContentComponent(slateMessagesApiService, sessionService) {
        this.slateMessagesApiService = slateMessagesApiService;
        this.sessionService = sessionService;
        this.subscriptions = {};
        this.previousMessages = [];
        this.currentDateMessages = [];
    }
    Object.defineProperty(SlateMessageContentComponent.prototype, "selectedSlateMessage", {
        get: function () {
            return this._selectedMessage;
        },
        set: function (value) {
            this._selectedMessage = value;
            this.getSlateMessageDetails();
        },
        enumerable: true,
        configurable: true
    });
    SlateMessageContentComponent.prototype.ngOnInit = function () {
        this.getCurrentUserName();
        this.subscribeToOrgLevel();
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.view = process([this.selectedSlateMessage], this.gridState.state);
    };
    SlateMessageContentComponent.prototype.getCurrentUserName = function () {
        var currentUser = this.sessionService.getUser();
        if (!!currentUser && !!currentUser.name) {
            this.currentUserName = currentUser.name;
        }
    };
    SlateMessageContentComponent.prototype.getSlateMessageDetails = function () {
        var _this = this;
        if (!!this.selectedSlateMessage && !!this.selectedSlateMessage.groupId && !!this.orgLevel && !!this.orgLevel.id) {
            this.isLoading = true;
            this.slateMessagesApiService.getSlateMessagesByGroupId(this.orgLevel.id, this.selectedSlateMessage.groupId).then(function (res) {
                _this.isLoading = false;
                _this.SlateMessageGroupInfo = res[0];
                _this.SlateMessageGroupInfo.messages.sort(function (message) { return message.createdDate.getTime(); });
                _this.previousMessages = _this.SlateMessageGroupInfo.messages.filter(function (message) { return !_this.isCurrentDateMessage(message); }).sort(function (message) { return new Date(message.createdDate).getTime(); });
                _this.currentDateMessages = _this.SlateMessageGroupInfo.messages.filter(function (message) { return _this.isCurrentDateMessage(message); }).sort(function (message) { return new Date(message.createdDate).getTime(); });
            });
        }
    };
    SlateMessageContentComponent.prototype.subscribeToOrgLevel = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .subscribe(function (orgLevel) {
            if (!!orgLevel && orgLevel.id) {
                _this.orgLevel = orgLevel;
                _this.getSlateMessageDetails();
            }
        });
    };
    SlateMessageContentComponent.prototype.isAnyoneSeen = function (message) {
        return message.employees.some(function (emp) { return emp.read; });
    };
    SlateMessageContentComponent.prototype.canShowMoreButton = function (message) {
        return message.employees.some(function (emp) { return !emp.read; }) && message.employees.filter(function (emp) { return emp.read; }).length > 10;
    };
    SlateMessageContentComponent.prototype.getSeenByString = function (message) {
        var _this = this;
        var text = '';
        if (message.employees.some(function (emp) { return emp.read; })) {
            if (message.employees.length > 1 && message.employees.every(function (emp) { return emp.read; })) {
                text = 'Seen by everyone';
            }
            else {
                text = 'Seen by ';
                var seenEmployees = message.employees.filter(function (emp) { return emp.read; }).slice(0, 10);
                seenEmployees.forEach(function (emp) {
                    text += _this.SlateMessageGroupInfo.employees.find(function (e) { return e.employeeId == emp.employeeId; }).employeeName.split(',').join(' ') + ',';
                });
            }
        }
        return text;
    };
    SlateMessageContentComponent.prototype.getLatestSeenDatetime = function (message) {
        return !!message && !!message.employees ? message.employees.map(function (e) { return e.readAt; }).filter(function (d) { return d != null || d != undefined; }).sort().reverse()[0] : '';
    };
    SlateMessageContentComponent.prototype.isCurrentDateMessage = function (message) {
        var createdDate = new Date(message.createdDate);
        var date = new Date(createdDate.getDate(), createdDate.getMonth(), createdDate.getFullYear());
        return date == new Date();
    };
    SlateMessageContentComponent.prototype.getMoreEmployeesStrings = function (message) {
        var _this = this;
        var text = '';
        var seenEmployees = message.employees.filter(function (emp) { return emp.read; }).slice(10);
        seenEmployees.forEach(function (emp) {
            text += _this.SlateMessageGroupInfo.employees.find(function (e) { return e.employeeId == emp.employeeId; }).employeeName.split(',')[1] + ',';
        });
        return text;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Object)
    ], SlateMessageContentComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], SlateMessageContentComponent.prototype, "orgLevel$", void 0);
    return SlateMessageContentComponent;
}());
export { SlateMessageContentComponent };
