import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ManagementBaseService } from '../../../core/services/index';
import { InfoDialogComponent } from '../../../common/components/index';
import { ModalService } from '../../../common/services/index';
import { PunchAttestationApiService } from './punch-attestation-api.service';
import { BehaviorSubject } from 'rxjs';
var PunchAttestationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PunchAttestationManagementService, _super);
    function PunchAttestationManagementService(apiService, modalService) {
        var _this = _super.call(this) || this;
        _this.apiService = apiService;
        _this.modalService = modalService;
        _this.legacyclockquestiongroup = 'Legacy clock question group';
        _this.isSlate$ = new BehaviorSubject(false);
        _this.isLegacy$ = new BehaviorSubject(false);
        _this.configurePunchAttestation = new BehaviorSubject(false);
        _this.configurePunchProfile = new BehaviorSubject(false);
        _this.scrollBottom$ = new BehaviorSubject(false);
        return _this;
    }
    PunchAttestationManagementService.prototype.loadgroups = function (orgLevel) {
        var _this = this;
        this.orgLevel = orgLevel;
        this.onLoadStatusChanged(true);
        this.apiService.getAllGroups()
            .then(function (groups) {
            _this.groups = groups;
            _this.legacyClockOrder();
            _this.onLoaded(_this.groups);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PunchAttestationManagementService.prototype.addLegacyGroup = function () {
        var group = {
            groupName: this.legacyclockquestiongroup,
            id: 0,
            organizationId: this.orgLevel.id,
            questions: []
        };
        this.groups.splice(0, 0, group);
        this.onLoaded(this.groups);
    };
    PunchAttestationManagementService.prototype.addGroups = function () {
        var group = {
            groupName: '',
            id: 0,
            organizationId: this.orgLevel.id,
            questions: []
        };
        this.groups.push(group);
        this.onLoaded(this.groups);
        this.scrollBottom$.next(true);
    };
    PunchAttestationManagementService.prototype.deleteQuestion = function (orgLevel, group, question) {
        var _this = this;
        this.orgLevel = orgLevel;
        this.onLoadStatusChanged(true);
        this.apiService.deleteGroupQuestion(this.orgLevel.id, group.id, question.id)
            .then(function (groups) {
            _this.groups = groups;
            _this.legacyClockOrder();
            _this.onLoaded(_this.groups);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PunchAttestationManagementService.prototype.saveGroup = function (orgLevel, group) {
        var _this = this;
        this.orgLevel = orgLevel;
        this.onLoadStatusChanged(true);
        this.apiService.updateGroup(this.orgLevel.id, group)
            .then(function (group) {
            _this.groups = group;
            _this.legacyClockOrder();
            _this.onLoaded(_this.groups);
            _this.onLoadStatusChanged(false);
            _this.scrollBottom$.next(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PunchAttestationManagementService.prototype.deleteGroup = function (orgLevel, group) {
        var _this = this;
        if (group.id === null || group.id === 0) {
            this.groups = this.groups.filter(function (x) { return x.id !== group.id; });
            this.onLoaded(this.groups);
            return;
        }
        this.orgLevel = orgLevel;
        this.onLoadStatusChanged(true);
        this.apiService.deleteGroup(this.orgLevel.id, group.id)
            .then(function (response) {
            if (response.isSuccess) {
                _.remove(_this.groups, function (p) { return group.id === p.id; });
                _this.onLoaded(_this.groups);
                _this.onLoadStatusChanged(false);
            }
            else {
                _this.onLoadStatusChanged(false);
                InfoDialogComponent.OpenDialog('Cannot delete Question Group', response.message, _this.modalService);
            }
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PunchAttestationManagementService.prototype.loadGoGroups = function (orgLevel) {
        var _this = this;
        this.orgLevel = orgLevel;
        this.onLoadStatusChanged(true);
        this.apiService.getAllGroups()
            .then(function (groups) {
            _this.groups = groups;
            _this.onLoaded(_this.groups);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PunchAttestationManagementService.prototype.areGroupNameValid = function (group) {
        return this.groups && group ? this.groups.filter(function (e) {
            return e.id !== group.id && e.groupName.toLocaleLowerCase() === group.groupName.toLocaleLowerCase();
        }).length === 0 : true;
    };
    Object.defineProperty(PunchAttestationManagementService.prototype, "isLegacyGroupExists", {
        get: function () {
            var _this = this;
            return this.groups ? this.groups.find(function (x) { return x.groupName.toLocaleLowerCase() === _this.legacyclockquestiongroup.toLocaleLowerCase(); }) !== undefined : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PunchAttestationManagementService.prototype, "isGroupNameValid", {
        get: function () {
            return this.groups ? this.groups.find(function (x) { return x.id === 0 || x.groupName === null || x.groupName === ''; }) === undefined : true;
        },
        enumerable: true,
        configurable: true
    });
    PunchAttestationManagementService.prototype.getPermissions = function (orgLevelId) {
        var _this = this;
        this.apiService.getPermissions(orgLevelId).then(function (result) {
            var punchAttestationGroupPermissions = result;
            _this.isLegacy$.next(punchAttestationGroupPermissions.isLegacy);
            _this.isSlate$.next(punchAttestationGroupPermissions.isSlate);
            _this.configurePunchAttestation.next(punchAttestationGroupPermissions.configurePunchAttestation);
            _this.configurePunchProfile.next(punchAttestationGroupPermissions.configurePunchProfile);
        });
    };
    PunchAttestationManagementService.prototype.legacyClockOrder = function () {
        var _this = this;
        var localGroups = [];
        if (this.groups.length > 0) {
            if (this.isLegacy$.getValue()) {
                localGroups = localGroups.concat(this.groups.filter(function (x) { return x.groupName.toLocaleLowerCase() === _this.legacyclockquestiongroup.toLocaleLowerCase(); }));
            }
            localGroups = localGroups.concat(this.groups.filter(function (x) { return x.groupName.toLocaleLowerCase() !== _this.legacyclockquestiongroup.toLocaleLowerCase(); }));
            this.groups = localGroups;
        }
    };
    return PunchAttestationManagementService;
}(ManagementBaseService));
export { PunchAttestationManagementService };
