import { ElementRef, forwardRef, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export var CHEKBOX_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return CheckboxInputComponent; }),
    multi: true
};
var CheckboxInputComponent = /** @class */ (function () {
    function CheckboxInputComponent(elementRef, changeDetector) {
        this.changeDetector = changeDetector;
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.innerValue = false;
        Assert.isNotNull(elementRef, 'elementRef');
        this.elementRef = elementRef;
        this.inEdit = true;
    }
    Object.defineProperty(CheckboxInputComponent.prototype, "fieldName", {
        get: function () {
            return this.m_fieldName;
        },
        set: function (value) {
            if (!this.m_fieldName && value) {
                this.m_fieldName = this.getUniqueId(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxInputComponent.prototype, "readonly", {
        set: function (ro) {
            this.inEdit = !ro;
            if (this.elementRef) {
                if (ro) {
                    this.elementRef.nativeElement.setAttribute('readonly', true);
                }
                else {
                    this.elementRef.nativeElement.removeAttribute('readonly');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChangeCallback(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    CheckboxInputComponent.prototype.writeValue = function (value) {
        this.innerValue = value;
        // this.changeDetector.markForCheck();
        // this.changeDetector.detectChanges();
    };
    CheckboxInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    CheckboxInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    CheckboxInputComponent.prototype.getUniqueId = function (name) {
        return name + "_" + Math.random().toString(36).substr(2, 9);
    };
    return CheckboxInputComponent;
}());
export { CheckboxInputComponent };
