import { OnInit, DoCheck } from '@angular/core';
import { DropdownInputComponent } from '../../../common/components/index';
var EmptyOptionDirective = /** @class */ (function () {
    function EmptyOptionDirective(dropdown) {
        this.dropdown = dropdown;
    }
    EmptyOptionDirective.prototype.ngOnInit = function () {
        this.lastEmptyOption = undefined;
    };
    EmptyOptionDirective.prototype.ngDoCheck = function () {
        var doAdd = false;
        doAdd = !!this.dropdown.items;
        if (this.dropdown.items && (!this.lastEmptyOption || this.dropdown.emptyOption !== this.lastEmptyOption)) {
            var emptyOption = this.createEmptyOption();
            this.lastEmptyOption = emptyOption;
            this.dropdown.emptyOption = emptyOption;
        }
    };
    EmptyOptionDirective.prototype.createEmptyOption = function () {
        var _a;
        var optionToAdd = undefined;
        if (typeof this.emptyOption === 'string') {
            optionToAdd = (_a = {},
                _a[this.dropdown.valueField] = 0,
                _a[this.dropdown.titleField] = this.emptyOption,
                _a);
        }
        else if (this.emptyOption instanceof Object) {
            optionToAdd = this.emptyOption;
        }
        return optionToAdd;
    };
    return EmptyOptionDirective;
}());
export { EmptyOptionDirective };
