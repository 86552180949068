<slx-spinner [show]="state.isLoading">

  <div *ngIf="!state.orgLvl">
    <span class="error-label">You can't access this component at the current organization level</span>
  </div>

  <div *ngIf="state.orgLvl" class="slx-high-box">
    <div class="slx-content-toolbar-indents sa-header">
      <div class="button-container">
        <div *ngIf="!state.groupEditMode && !state.addSupervisorMode && !state.addEmployeeMode">
          <button type="button" (click)="showUnassignedClicked()" class="theme-button-apply margin-r">Unassigned</button>
          <button type="button" (click)="onAddGroup()" class="theme-button-apply margin-r">Add Group</button>
          <button type="button" (click)="onEditGroup()" [disabled]="!isAnyGroupSelected" class="theme-button-apply margin-r">Edit Group</button>
          <button type="button" [popover]="acceptRemoveGroupPopover" [disabled]="!isAnyGroupSelected" class="theme-button-apply margin-r">Delete Group</button>
          <popover-content #acceptRemoveGroupPopover title="Are you sure you wish to remove this group?" placement="auto bottom">
            <div style="width:110px">
              <button type="button" style="width:50px" (click)="acceptRemoveGroupPopover.hide()" class="theme-button-cancel margin-r">No</button>
              <button type="button" style="width:50px" class="theme-button-apply" (click)="acceptRemoveGroupPopover.hide();onDeleteGroup()">Yes</button>.
            </div>
          </popover-content>
        </div>
        <div *ngIf="state.groupEditMode">
          <button type="button" (click)="onSaveGroup()" [disabled]="(groupGrid && !groupGrid.isEditorValid)" class="theme-button-apply margin-r">Save</button>
          <button type="button" (click)="onDiscard()" class="theme-button-cancel">Discard</button>
        </div>
        <div *ngIf="state.addSupervisorMode">
          <button type="button" (click)="onSaveSupervisorToGroup()" [disabled]="!isAnyEligibleSupervisorsSelected" class="theme-button-apply margin-r">Save</button>
          <button type="button" (click)="onDiscard()" class="theme-button-cancel">Discard</button>
        </div>
        <div *ngIf="state.addEmployeeMode">
          <button type="button" (click)="onSaveSupervisedEmployeeToGroup()" [disabled]="!isAnyEligibleEmployeesSelected" class="theme-button-apply margin-r">Save</button>
          <button type="button" (click)="onDiscard()" class="theme-button-cancel">Discard</button>
        </div>
      </div>
    </div>
    <div class="slx-high-box__body slx-main-content-indents" *ngIf="!state.addSupervisorMode && !state.addEmployeeMode">
      <slx-supervisor-group-grid #groupGrid></slx-supervisor-group-grid>
    </div>
    <div class="slx-high-box__body slx-main-content-indents" *ngIf="state.addSupervisorMode">
      <slx-eligible-supervisor-grid [eligibleSupervisors]="eligibleSupervisors" (selectionChanged)="selectEligibleSupervisors($event)"></slx-eligible-supervisor-grid>
    </div>
    <div class="slx-high-box__body slx-main-content-indents" *ngIf="state.addEmployeeMode">
      <slx-eligible-supervised-employee-grid [eligibleEmployees]="eligibleEmployees" (selectionChanged)="selectEligibleEmployees($event)"></slx-eligible-supervised-employee-grid>
    </div>
  </div>
</slx-spinner>
