import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LookupService } from '../../../../../organization/services/index';
import { PayType } from '../../../../../organization/models/lookup/index';
import { EmployeeSectionsPosition, EmployeeSectionsPositions } from '../../../models/index';
import { EmployeeSectionBridgeService, EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { FieldAccessType } from '../../../../../core/models/field/field-access-type';
import { LookupType } from '../../../../../organization/models/index';
import { appConfig } from '../../../../../app.config';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
var EmployeeSectionsPositionsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsPositionsComponent, _super);
    function EmployeeSectionsPositionsComponent(employeeSectionsEmploymentApiService, lookupService, decorator, ngZone, changeDetector, modalService, employeeBridgeService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.changeDetector = changeDetector;
        _this.modalService = modalService;
        _this.employeeBridgeService = employeeBridgeService;
        _this.appConfig = appConfig;
        _this.decimalLimit = null;
        _this.subscriptions = {};
        _this.minDateForEndDate = null;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.lookupService = lookupService;
        _this.departmentLookup = {};
        _this.positionLookup = {};
        _this.payPolicyLookup = {};
        _this.shiftDiffPolicyLookup = {};
        return _this;
    }
    Object.defineProperty(EmployeeSectionsPositionsComponent.prototype, "employeeSectionsPositions", {
        set: function (positions) {
            this.empPositions = positions;
            this.getPrimaryPositon();
            this.loadDependentsLookups();
            this.actualPositions = this.calcActualPositions();
            this.minDateForEndDate = null;
            this.updateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPositionsComponent.prototype, "positionMinDate", {
        get: function () {
            return (this.employeeStatus.toLowerCase() === "future rehire") ? this.employeeRehireDate : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPositionsComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPositionsComponent.prototype, "salaryPayType", {
        get: function () {
            if (!this.employeePayType) {
                return;
            }
            return this.employeePayType &&
                this.employeePayType.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsPositionsComponent.prototype, "getRateTitle", {
        get: function () {
            var pos = new EmployeeSectionsPosition();
            if (!this.basicCheck(pos)) {
                return '';
            }
            if (this.salaryPayType === '') {
                return 'Hourly Rate';
            }
            return this.salaryPayType === "Salaried" ? 'Weekly Rate' : 'Hourly Rate';
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPositionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.subscriptions.payRateFormat = this.employeeBridgeService.subscribeToChangePayRateFormat(function (isEnableRate4DecimalPlaces) {
            if (isEnableRate4DecimalPlaces) {
                _this.decimalLimit = 4;
            }
            else {
                _this.decimalLimit = 2;
            }
        });
    };
    EmployeeSectionsPositionsComponent.prototype.getSubsectionModel = function () {
        return this.empPositions;
    };
    EmployeeSectionsPositionsComponent.prototype.setEditState = function (editState) {
        _super.prototype.setEditState.call(this, editState);
        this.minDateForEndDate = null;
    };
    EmployeeSectionsPositionsComponent.prototype.updateData = function () {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    EmployeeSectionsPositionsComponent.prototype.addNewPosition = function () {
        var pos = new EmployeeSectionsPosition();
        pos.position = this.empPositions.positionMetaData.createBasedOn();
        pos.position.securityAttribute.access = FieldAccessType.full;
        pos.organization = this.empPositions.organizationMetaData.createBasedOn();
        pos.organization.securityAttribute.access = FieldAccessType.full;
        pos.department = this.empPositions.departmentMetaData.createBasedOn();
        pos.department.securityAttribute.access = FieldAccessType.full;
        pos.shiftDiffPolicy = this.empPositions.shiftDiffPolicyMetaData.createBasedOn();
        pos.shiftDiffPolicy.securityAttribute.access = FieldAccessType.full;
        pos.payPolicy = this.empPositions.payPolicyMetaData.createBasedOn();
        pos.payPolicy.securityAttribute.access = FieldAccessType.full;
        pos.hourlyRate = this.empPositions.hourlyRateMetaData.createBasedOn();
        pos.startDate = this.empPositions.startDateMetaData.createBasedOn();
        pos.startDate.securityAttribute.access = FieldAccessType.full;
        var today = moment().startOf('day').toDate();
        pos.startDate.fieldValue = today;
        pos.endDate = this.empPositions.endDateMetaData.createBasedOn();
        pos.endDate.securityAttribute.access = FieldAccessType.full;
        this.empPositions.positions.push(pos);
        this.actualPositions = this.calcActualPositions();
        this.updateData();
    };
    EmployeeSectionsPositionsComponent.prototype.organizationEditable = function (pos) {
        if (!this.basicCheck(pos)) {
            return false;
        }
        return pos.organization.securityAttribute.editable && this.state.isEditMode;
    };
    EmployeeSectionsPositionsComponent.prototype.departmentEditable = function (pos) {
        if (!this.basicCheck(pos)) {
            return false;
        }
        if (!pos.organization.fieldValue)
            return false;
        return pos.department.securityAttribute.editable && this.state.isEditMode;
    };
    EmployeeSectionsPositionsComponent.prototype.positionEditable = function (pos) {
        if (!this.basicCheck(pos)) {
            return false;
        }
        if (!pos.organization.fieldValue)
            return false;
        if (!pos.department.fieldValue)
            return false;
        return pos.position.securityAttribute.editable && this.state.isEditMode;
    };
    EmployeeSectionsPositionsComponent.prototype.startDateEditable = function (pos) {
        if (!this.basicCheck(pos)) {
            return false;
        }
        return pos.startDate.securityAttribute.editable && this.state.isEditMode;
    };
    EmployeeSectionsPositionsComponent.prototype.endDateEditable = function (pos) {
        if (!this.basicCheck(pos, true)) {
            return false;
        }
        return this.state.isEditMode;
    };
    EmployeeSectionsPositionsComponent.prototype.payPolicyEditable = function (pos) {
        if (!this.basicCheck(pos, true)) {
            return false;
        }
        if (!pos.organization.fieldValue) {
            return false;
        }
        if (!pos.department.fieldValue) {
            return false;
        }
        if (!pos.position.fieldValue) {
            return false;
        }
        return pos.payPolicy.securityAttribute.editable && this.state.isEditMode && !this.isStartAndEndDateAreSame(pos);
    };
    EmployeeSectionsPositionsComponent.prototype.shiftDiffPolicyEditable = function (pos) {
        if (!this.basicCheck(pos, true)) {
            return false;
        }
        if (!pos.organization.fieldValue) {
            return false;
        }
        if (!pos.department.fieldValue) {
            return false;
        }
        if (!pos.position.fieldValue) {
            return false;
        }
        return pos.shiftDiffPolicy.securityAttribute.editable && this.state.isEditMode && !this.isStartAndEndDateAreSame(pos);
    };
    EmployeeSectionsPositionsComponent.prototype.hourlyRateEditable = function (pos) {
        if (!this.basicCheck(pos, true)) {
            return false;
        }
        if (!pos.organization.fieldValue) {
            return false;
        }
        if (!pos.department.fieldValue) {
            return false;
        }
        if (!pos.position.fieldValue) {
            return false;
        }
        return pos.hourlyRate.securityAttribute.editable && this.state.isEditMode && !this.isStartAndEndDateAreSame(pos);
    };
    EmployeeSectionsPositionsComponent.prototype.basicCheck = function (pos, ignoreSaved) {
        if (ignoreSaved === void 0) { ignoreSaved = false; }
        if (!pos) {
            return false;
        }
        if (pos.isSaved && !ignoreSaved) {
            return false;
        }
        return true;
    };
    EmployeeSectionsPositionsComponent.prototype.getEndDate = function (date) {
        var isSame = moment(date).startOf('day').isSame(this.appConfig.maxCorrectDate);
        return isSame ? null : date;
    };
    EmployeeSectionsPositionsComponent.prototype.getMinDate = function (date) {
        return _.isDate(this.minDateForEndDate) ? this.minDateForEndDate : date;
    };
    EmployeeSectionsPositionsComponent.prototype.isStartAndEndDateAreSame = function (pos) {
        return moment(pos.startDate.fieldValue).isSame(pos.endDate.fieldValue);
    };
    EmployeeSectionsPositionsComponent.prototype.onChangeCriticalData = function (startDate) {
        this.minDateForEndDate = moment(startDate).add(1, 'days').toDate();
    };
    EmployeeSectionsPositionsComponent.prototype.onRemovePosition = function (pos) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Delete Secondary Position', 'Are you sure you want to remove this position including all history?', this.modalService, function (isDelete) {
            if (isDelete) {
                pos.isDeleted = true;
                _this.actualPositions = _this.calcActualPositions();
                _this.updateData();
            }
        }, options);
    };
    EmployeeSectionsPositionsComponent.prototype.onOrganizationChanged = function (pos, org, ind) {
        this.clearDependences(pos);
        pos.department.fieldValue = null;
        pos.position.fieldValue = null;
        this.loadDepartments(org, ind);
    };
    EmployeeSectionsPositionsComponent.prototype.onDepartmentChanged = function (pos, dep, ind) {
        this.clearDependences(pos);
        pos.position.fieldValue = null;
        this.loadPositions(dep, ind);
    };
    EmployeeSectionsPositionsComponent.prototype.clearDependences = function (pos) {
        pos.payPolicy.fieldValue = null;
        pos.shiftDiffPolicy.fieldValue = null;
    };
    EmployeeSectionsPositionsComponent.prototype.onPositionChanged = function (pos, ind) {
        this.loadByPosition(pos.orgLevelId, ind);
    };
    EmployeeSectionsPositionsComponent.prototype.hasSecondaryPayRate = function (value) {
        return parseFloat(value) > 0;
    };
    EmployeeSectionsPositionsComponent.prototype.loadDependentsLookups = function () {
        var _this = this;
        if (!this.actualPositions) {
            return;
        }
        _.times(this.actualPositions.length, function (num) {
            var orgLevelId = _.get(_this.actualPositions[num], 'department.fieldValue.orgLevelId');
            _this.loadByPosition(orgLevelId, num);
        });
    };
    EmployeeSectionsPositionsComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmploymentPositions(this.employeeId)
            .then(function (employeeSectionsPositions) {
            _this.empPositions = employeeSectionsPositions;
            _this.actualPositions = _this.calcActualPositions();
            _this.getPrimaryPositon();
            _this.loadDependentsLookups();
            _this.stopProgress();
            _this.updateData();
        })
            .catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsPositionsComponent.prototype.getPrimaryPositon = function () {
        if (!this.empPositions)
            return;
        this.primaryPosition = _.find(this.empPositions.positions, function (pos) {
            return pos.isPrimary;
        });
    };
    EmployeeSectionsPositionsComponent.prototype.calcActualPositions = function () {
        var res = [];
        if (!this.empPositions)
            return res;
        res = _.filter(this.empPositions.positions, function (pos) {
            return !pos.isDeleted && !pos.isPrimary;
        });
        return res;
    };
    EmployeeSectionsPositionsComponent.prototype.doSave = function (effectiveDate) {
        var _this = this;
        this.employeeSectionsEmploymentApiService.setEmploymentPositions(this.employeeId, this.empPositions, effectiveDate)
            .then(function (status) {
            _this.onActionComplete(true);
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsPositionsComponent.prototype.loadDepartments = function (org, ind) {
        var _this = this;
        if (!org)
            return;
        this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: org.orgLevelId, employeeId: undefined })
            .then(function (departmentLookup) {
            _this.departmentLookup[ind] = departmentLookup;
        });
    };
    EmployeeSectionsPositionsComponent.prototype.loadPositions = function (dep, ind) {
        var _this = this;
        if (!dep)
            return;
        this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: dep.orgLevelId, employeeId: undefined })
            .then(function (positionLookup) {
            positionLookup.items = _this.getAvaliablePostionsForPosition(_this.actualPositions[ind], positionLookup.items);
            _this.positionLookup[ind] = positionLookup;
        });
    };
    EmployeeSectionsPositionsComponent.prototype.getAvaliablePostionsForPosition = function (targetEmpPosition, items) {
        var _this = this;
        var filteredItems = _.filter(items, function (item) {
            var allowPosition = true;
            _.each(_this.empPositions.positions, function (empPosition) {
                if (targetEmpPosition !== empPosition) {
                    if (targetEmpPosition.department.fieldValue && empPosition.department.fieldValue) {
                        if (targetEmpPosition.department.fieldValue.id === empPosition.department.fieldValue.id) {
                            if (empPosition.position.fieldValue) {
                                if (item.id === empPosition.position.fieldValue.id) {
                                    allowPosition = false;
                                }
                            }
                        }
                    }
                }
            });
            return allowPosition;
        });
        return filteredItems;
    };
    EmployeeSectionsPositionsComponent.prototype.loadByPosition = function (orgLevelId, ind) {
        var _this = this;
        if (!_.isFinite(+orgLevelId))
            return;
        this.lookupService.getLookup({ lookupType: LookupType.payPolicy, orgLevelId: orgLevelId })
            .then(function (payPolicyLookup) {
            _this.payPolicyLookup[ind] = payPolicyLookup;
        });
        this.lookupService.getLookup({ lookupType: LookupType.shiftDiffPolicy, orgLevelId: orgLevelId })
            .then(function (shiftDiffPolicyLookup) {
            _this.shiftDiffPolicyLookup[ind] = shiftDiffPolicyLookup;
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsPositionsComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsPositionsComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsPositionsComponent };
