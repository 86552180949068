/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./two-level-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./two-level-menu.component";
var styles_TwoLevelMenuComponent = [i0.styles];
var RenderType_TwoLevelMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TwoLevelMenuComponent, data: {} });
export { RenderType_TwoLevelMenuComponent as RenderType_TwoLevelMenuComponent };
function View_TwoLevelMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "back-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backFromSubItems() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-angle-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "back-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"]))], null, null); }
function View_TwoLevelMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "menu-sub-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubItemClick(_v.parent.context.$implicit, _v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubItemClick(_v.parent.context.$implicit, _v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "menu-sub-item"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.active); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.displayName; _ck(_v, 8, 0, currVal_2); }); }
function View_TwoLevelMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "li", [["class", "menu-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "active": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "menu-item-title-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "menu-item-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-chevron-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "ul", [["class", "menu-sub-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TwoLevelMenuComponent_3)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "menu-item"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.active); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.items; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.displayName; _ck(_v, 6, 0, currVal_2); }); }
export function View_TwoLevelMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "toggler"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapsed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-bars fa-lg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TwoLevelMenuComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 10, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 9, "nav", [["role", "off-canvas"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(8, { "opened": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 5, "ul", [["class", "menu-items"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(11, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "active": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TwoLevelMenuComponent_2)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.activeSubItem == null) ? null : _co.activeSubItem.active) && !_co.isCollapsed); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 8, 0, !_co.isCollapsed); _ck(_v, 7, 0, currVal_1); var currVal_2 = "menu-items"; var currVal_3 = _ck(_v, 12, 0, ((_co.activeItem == null) ? null : _co.activeItem.active)); _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_4 = _co.menuItems; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_TwoLevelMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-two-level-menu", [], null, null, null, View_TwoLevelMenuComponent_0, RenderType_TwoLevelMenuComponent)), i1.ɵdid(1, 573440, null, 0, i3.TwoLevelMenuComponent, [], null, null)], null, null); }
var TwoLevelMenuComponentNgFactory = i1.ɵccf("slx-two-level-menu", i3.TwoLevelMenuComponent, View_TwoLevelMenuComponent_Host_0, { menuItems: "menuItems", activeItemId: "activeItemId", activeSubItemId: "activeSubItemId" }, { itemActivated: "itemActivated", menuToggled: "menuToggled" }, []);
export { TwoLevelMenuComponentNgFactory as TwoLevelMenuComponentNgFactory };
