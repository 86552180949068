import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { EnrollmentHistoryRecord, EmployeeInfo, CalculationType } from '../../../../models';
import * as _ from 'lodash';
import { appConfig } from '../../../../../../../app/app.config';
import { CalculationCommonService } from '../../../../../../app-modules/benefits/services/benefit-details/calculation-common.service';
var BenefitEnrollmentHistoryComponent = /** @class */ (function () {
    function BenefitEnrollmentHistoryComponent(manService, calculationCommonService) {
        this.manService = manService;
        this.calculationCommonService = calculationCommonService;
        this.subscriptions = {};
        this.pageSize = 5;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'startDate', dir: 'desc' }];
        this.appConfig = appConfig;
        this.calculationMethod = CalculationType;
    }
    BenefitEnrollmentHistoryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.enrollmentHistorySubscription = this.manService.subscribeToEnrollmentsHistoryLoad(function (v) {
            _this.records = v;
            _this.setCostValue();
            _this.refreshGrid();
        });
        this.subscriptions.employeeEnrollmentUpdateSubscription = this.manService.subscribeToEnrollmentUpdated(function (v) {
            _this.updateRecord = v;
            _this.updateEmployeeRecord();
            _this.setCostValue();
            _this.refreshGrid();
        });
        this.subscriptions.gridEmployeeInfoSubscription = this.manService.subscribeToEmployeeInfo(function (v) {
            _this.empName = v.fullName;
            _this.employeeId = v.id;
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    BenefitEnrollmentHistoryComponent.prototype.ngOnDestroy = function () {
    };
    BenefitEnrollmentHistoryComponent.prototype.updateEmployeeRecord = function () {
        var _this = this;
        _.forEach(this.records, function (employee) {
            if (employee.calcMethod.isOptionsRates && _this.updateRecord.calcMethod.isOptionsRates && employee.plan === _this.updateRecord.plan) {
                employee.employeeContribution = _this.updateRecord.employeeContribution;
            }
        });
    };
    BenefitEnrollmentHistoryComponent.prototype.setCostValue = function () {
        var _this = this;
        _.forEach(this.records, function (employee) {
            if (employee.calcMethod.isOptionsRates) {
                var employeeContribution = 0;
                if (employee.employeeContribution > 0) {
                    employeeContribution = _this.calculationCommonService.getConvertedEmployeeContribution(employee.costFreq, employee.empContFreq, employee.employeeContribution);
                }
                employee.cost = employee.employerContributionAmount + employeeContribution;
            }
        });
    };
    BenefitEnrollmentHistoryComponent.prototype.onPlanClick = function (selectedRecord) {
        var _this = this;
        this.isLoading = true;
        this.manService.planSelected(this.employeeId, selectedRecord).then(function () {
            _this.isLoading = false;
        });
    };
    BenefitEnrollmentHistoryComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitEnrollmentHistoryComponent.prototype, "subscriptions", void 0);
    return BenefitEnrollmentHistoryComponent;
}());
export { BenefitEnrollmentHistoryComponent };
