export * from './schedule-console/schedule-console.component';
export * from './schedule-console-comparison/schedule-console-comparison.component';
export * from './schedule-console-graphic-comparison/schedule-console-graphic-comparison.component';
export * from './schedule-console-graphic-comparison-day/schedule-console-graphic-comparison-day.component';
export * from './schedule-console-graphic-comparison-week/schedule-console-graphic-comparison-week.component';
export * from './schedule-console-toolbar/schedule-console-toolbar.component';
export * from './schedule-console-weekly-indicator/schedule-console-weekly-indicator.component';
export * from './schedule-console-overview/schedule-console-overview.component';
export * from './schedule-console-overview/schedule-console-overview-week/schedule-console-overview-week.component';
export * from './schedule-console-overview/schedule-console-overview-toolbar/schedule-console-overview-toolbar.component';
export * from './schedule-console-overview/schedule-console-overview-tooltip/schedule-console-overview-tooltip.component';
export * from './schedule-console-overview/schedule-console-overview-day/schedule-console-overview-day.component';
export * from './operational-console/operation-console-header/operational-console-header.component';
export * from './operational-console/operation-console-grid/operational-console-grid.component';
export * from './operational-console/operational-console.component';

import { ScheduleConsoleComponent } from './schedule-console/schedule-console.component';
import { ScheduleConsoleComparisonComponent } from './schedule-console-comparison/schedule-console-comparison.component';
import { ScheduleConsoleGraphicComparisonComponent } from './schedule-console-graphic-comparison/schedule-console-graphic-comparison.component';
import { ScheduleConsoleGraphicComparisonDayComponent } from './schedule-console-graphic-comparison-day/schedule-console-graphic-comparison-day.component';
import { ScheduleConsoleGraphicComparisonWeekComponent } from './schedule-console-graphic-comparison-week/schedule-console-graphic-comparison-week.component';
import { ScheduleConsoleToolbarComponent } from './schedule-console-toolbar/schedule-console-toolbar.component';
import { ScheduleConsoleWeeklyIndicatorComponent } from './schedule-console-weekly-indicator/schedule-console-weekly-indicator.component';
import { ScheduleConsoleOverviewComponent } from './schedule-console-overview/schedule-console-overview.component';
import { ScheduleConsoleOverviewWeekComponent } from './schedule-console-overview/schedule-console-overview-week/schedule-console-overview-week.component';
import { ScheduleConsoleOverviewToolbarComponent } from './schedule-console-overview/schedule-console-overview-toolbar/schedule-console-overview-toolbar.component';
import { ScheduleConsoleOverviewTooltipComponent } from './schedule-console-overview/schedule-console-overview-tooltip/schedule-console-overview-tooltip.component';

import { ScheduleConsoleOverviewDayComponent } from './schedule-console-overview/schedule-console-overview-day/schedule-console-overview-day.component';
import { OperationalConsoleHeaderComponent } from './operational-console/operation-console-header/operational-console-header.component';
import { OperationalConsoleGridComponent } from './operational-console/operation-console-grid/operational-console-grid.component';
import { OperationalConsoleComponent } from './operational-console/operational-console.component';

export const exportScheduleConsoleComponents: any = [
  ScheduleConsoleComponent
];

export const scheduleConsoleComponents: any[] = [
  ...exportScheduleConsoleComponents,
  ScheduleConsoleComparisonComponent,
  ScheduleConsoleGraphicComparisonComponent,
  ScheduleConsoleGraphicComparisonDayComponent,
  ScheduleConsoleGraphicComparisonWeekComponent,
  ScheduleConsoleToolbarComponent,
  ScheduleConsoleWeeklyIndicatorComponent,
  ScheduleConsoleOverviewComponent,
  ScheduleConsoleOverviewWeekComponent,
  ScheduleConsoleOverviewToolbarComponent,
  ScheduleConsoleOverviewTooltipComponent,
  OperationalConsoleHeaderComponent,
  OperationalConsoleGridComponent,
  OperationalConsoleComponent,
  ScheduleConsoleOverviewDayComponent
];

export const scheduleConsoleEntryComponents: any[] = [
  ScheduleConsoleComponent
];
