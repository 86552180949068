<div class="preloader-contaner">
  <div class="image-holder {{className}}"></div>
  <div class="text-holder">
    <div class="line header-holder">
      <span>{{params.title}}</span>
    </div>
    <div class="line message-holder">
        <span>{{params.firstMessage}}
            <span class="report-display-name">{{params.styleMessage}}</span>{{params.lastMessage}}</span>
        </div>
    <div class="line actions-holder">
      <button class="discard-button" type="button" (click)="onCancel()">
        <i class="{{params.buttonIconClass}}" aria-hidden="true"></i>
        {{params.buttonLabel}}</button>
    </div>
  </div>
</div>
