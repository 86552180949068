<slx-spinner [show]="isLoading">
  <form #gridForm="ngForm" class="content-form">
    <div class="modal-body">
      <slx-benefit-payroll-deduction [groupName]="groupName"
      [effectiveDate]="effectiveDate" 
      [payrollDedStartDate]="payrollDedStartDate"
      [payrollDedEndDate]="payrollDedEndDate" 
      [dedStartDate]="dedStartDate"
      [dedEndDate]="dedEndDate"
      [startDate]="startDate"
      [endDate]="endDate"
      (dedEndDateChange)="OnDeductionEndDateChange($event)"
      (dedStartDateChange)="OnDeductionStartDateChange($event)"
      [canMapPayroll]="canMapPayroll"
    >
    </slx-benefit-payroll-deduction>
      <div class="controls-holder">
        <div class="controls-row">
          <p class="flex form__ctrl margin-right">
            <label class="form__label">Select a Benefit Tier</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-dropdown-list class="slx-wide" [ngModel]="selectedTier" [options]="tiers"
                                 (ngModelChange)="onChangeTier($event)" name="tiersList" required>
              </slx-dropdown-list>
            </span>
          </p>
          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && isFormula">
            <label class="form__label">Coverage</label>
            <span *ngIf="!isFormula" class="flex flex__grow form-dropdown-control">
              <slx-number class="slx-wide slx-input-number" [step]="stepcurrency" [format]="'c2'" [decimals]="2"
                          [ngModel]="formulaSettings.formulaValue" name="formulaValue" [readonly]="true">
              </slx-number>
            </span>
            <span *ngIf="isFormula" class="flex flex__grow form-dropdown-control flex__wrap">
              <slx-number #covFormulaOverrideField="ngModel" name="covFormulaValue"
                  class="slx-wide slx-input-number"
                  [step]="stepcurrency" [format]="'c2'" [decimals]="2"
                  [ngModel]="formulaSettings.calculatedCoverage"
                  [min]="minFormulaAmt" [max]="maxAmt"
                  [slxMin]="minFormulaAmt" [slxMax]="maxAmt"
                  [required]="true"
                  [autoCorrect]="false"
                  (blur)="onChangeCoverageFormulaValue($event, covFormulaOverrideField?.invalid)">
              </slx-number>
              <span *ngIf="covFormulaOverrideField.errors" class="slx-error-block pull-left">
                <span *ngIf="covFormulaOverrideField.errors.min || covFormulaOverrideField.errors.max" errorMessage>Incorrect value</span>
                <span *ngIf="covFormulaOverrideField.errors.required" errorMessage for="required"></span>
              </span>
              <i *ngIf="formulaSettings.calculatedCoverage==-1"
                  title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
                  aria-hidden="true"
                  [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
              </span>
          </p>
          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && isFixed">
            <label class="form__label align__right">Coverage</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-number class="slx-wide slx-input-number" [step]="stepcurrency" [format]="'c0'"
                          [(ngModel)]="formulaSettings.fixedAmount" name="fixedAmount" [readonly]="true">
              </slx-number>
            </span>
          </p>
          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && anyWithMaxCap">
            <label class="form__label align__right">Coverage</label>
            <span class="flex flex__grow form-dropdown-control flex__wrap">
              <slx-number #covAnyValueField="ngModel" class="slx-wide slx-input-number"
                          [min]="minAmt" [max]="maxAmt"
                          [slxMin]="minAmt" [slxMax]="maxAmt"
                          [step]="stepcurrency" [required]="true"
                          [format]="'c2'" [decimals]="2" [autoCorrect]="false"
                          [(ngModel)]="formulaSettings.formulaValue"
                          (change)="onCoverageAnyValueChanged(covAnyValue?.invalid)"
                          name="anyWithMaxCap"
                          >
              </slx-number>
              <span *ngIf="covAnyValueField.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
              <span *ngIf="covAnyValueField.errors?.min || covAnyValueField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
            </span>
          </p>
          <p class="flex form__ctrl margin-left" *ngIf="hasSettings && multiplerMaxCap">
            <label class="form__label align__right">Coverage</label>
            <span class="flex flex__grow form-dropdown-control flex__wrap">
              <slx-autocomplete #multiplier="ngModel" class="slx-wide" [options]="coverageMultipllierOptions" [(ngModel)]="editedItemCoverage"
                                (ngModelChange)="onChangeCoverageWithMultiplier($event)" [strictSearch]="false"
                                rightIcon="fas fa-search" name="multiplerMaxCap" [virtual]="virtual" titleField="name" [required]="true">
                <ng-template slxAutocompleteItem let-item="item">
                  {{item.name}}
                </ng-template>
              </slx-autocomplete>
              <span *ngIf="multiplier.errors?.required" class="slx-error-block pull-left" errorMessage for="required"></span>
            </span>
          </p>
        </div>
        <div class="controls-row contributions" *ngIf="hasSettings">
          <div class="flex form__ctrl margin-right top-label">
            <label class="form__label">Option</label>
            <span class="flex flex__grow form-dropdown-control"></span>
            <slx-dropdown-list slx-input #optionCode="ngModel"
                               class="slx-wide slx-input-number"
                               [options]="filteredCoverageOptions"
                               [(ngModel)]="selectedCoverageOption"
                               (ngModelChange)="onOptionValueChanged()"
                               valueField="id"
                               titleField="tierOptionType"
                               name="optionCode"
                               [required]="true">
            </slx-dropdown-list>
            <span *ngIf="optionCode.errors" class="slx-error-block error-box">
              <span *ngIf="optionCode.errors.required" errorMessage for="required"></span>
            </span>
          </div>
          <div class="flex form__ctrl margin-left margin-right top-label">
            <label class="form__label">Employer Contribution</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-number class="slx-wide slx-input-number" [step]="stepcurrency"
                          [format]="'c2'" [decimals]="2" [autoCorrect]="false"
                          [(ngModel)]="formulaSettings.erContribution" name="erCont"
                          [readonly]="true">
              </slx-number>
              <i *ngIf="formulaSettings.erContribution==-1"
              title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
              aria-hidden="true"
              [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
            </span>
          </div>
          <div class="flex form__ctrl margin-left  top-label">
            <label class="form__label">Employee Contribution</label>
            <span class="flex flex__grow form-dropdown-control">
              <slx-number class="slx-wide slx-input-number" [step]="stepcurrency"
                          [format]="'c2'" [decimals]="2" [autoCorrect]="false"
                          [(ngModel)]="formulaSettings.empContribution" name="empCont"
                          [readonly]="true">
              </slx-number>
              <i *ngIf="formulaSettings.empContribution==-1"
              title="Formula entered is incorrect. Please make corrections to the formula in Plan Detail Screen."
              aria-hidden="true"
              [ngClass]="{'fa': true, 'fa-exclamation-triangle':true, 'formula-warning': true, 'error-exclamation': loadError}"></i>
            </span>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <span id="enroll-button" class="formula-info" [ngClass]="{'hide_item_tip': !hasError}">
        <button [disabled]="gridForm.invalid || !areAllValuesOK || hasDedError" type="button" (click)="onEnroll()"
                class="btn green">
          Enroll
        </button>
        <span class="theme-tooltiptext formula__btn-box" *ngIf="errorMessage != ''" [slxTooltip]="errorMessage" tipPosition="top"></span>
      </span>
      <button type="button" class="btn red" (click)="onCancel()">Cancel</button>
    </div>

  </form>
</slx-spinner>
