<div class="slx-range-navigator" [ngClass]="{ 'disabled': readonly }">
  <span class="slx-range-navigator__buttons">
    <i class="slx-range-navigator__icon left fas fa-angle-left" aria-hidden="true" (click)="onNavButtonClick($event, false)"></i>
  </span>
  <div class="slx-range-navigator__type">
    <slx-dropdown-list class="slx-wide slx-short"
      [options]="getRangeTypes()"
      [readonly]="readonly"
      [(ngModel)]="currentRangeType"
    ></slx-dropdown-list>
  </div>
  <div class="slx-range-navigator__date">
    <div class="slx-range-navigator__dates"
      [popper]="popperCalendar"
      [popperTrigger]="'click'"
      [popperDisableStyle]="true"
      [popperPlacement]="'bottom-start'"
      (popperOnShown)="onToggleVisibility(true)"
      (popperOnHidden)="onToggleVisibility(false)"
    >
      <span>{{ currentRange?.startDate | amDateFormat: appConfig.dateFullMonthFormat }}</span>
      <span class="slx-range-navigator__endDate" *ngIf="isWeekRangeType()"> &ndash; {{ currentRange?.endDate | amDateFormat: appConfig.dateFullMonthFormat }}</span>
      <span class="slx-range-navigator__cal-icon">
        <i class="fal fa-calendar-alt" aria-hidden="true"></i>
      </span>
    </div>
    <!-- See #issueWithPopperVisibility -->
    <popper-content #popperCalendar class="slx-range-navigator__calendar" [ngClass]="{ 'not-shown-fix-visibility-issue': !isShown }">
      <slx-static-calendar
        [(ngModel)]="currentDate"
        [minDate]="minDate"
        [maxDate]="maxDate"
        [viewType]="viewType"
        [disabled]="readonly"
        [calendarCellClass]="calendarCellClass"
      ></slx-static-calendar>
    </popper-content>
  </div>
  <span class="slx-range-navigator__buttons">
    <i class="slx-range-navigator__icon right fas fa-angle-right" aria-hidden="true" (click)="onNavButtonClick($event, true)"></i>
  </span>
</div>
