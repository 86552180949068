import { TimecardQueueDialogRequest } from './../../../models/timecards/unprocessed-timecard-stats';
import { TimecardsApiService } from './../../../services/timecards/timecards-api.service';
import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { appConfig } from '../../../../app.config';
var TimecardQueueDialogComponent = /** @class */ (function () {
    function TimecardQueueDialogComponent(options, modalService, apiService, request) {
        this.apiService = apiService;
        this.request = request;
        this.appConfig = appConfig;
        this.canApprove = false;
        this.isLoading = false;
        this.modalService = modalService;
        this.options = options;
        this.orgLevelName = this.request.orgLevel.name;
        this.approveTimecards = this.request.approveTimeCards;
        this.stats = this.request.unprocessedTimecardStats;
        if (this.canRefresh && this.request.unprocessedTimecardStats === null) {
            this.onRefresh();
        }
    }
    TimecardQueueDialogComponent.openDialog = function (modalService, apiService, request, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = request.approveTimeCards ? 350 : 300;
        dialogOptions.width = 350;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: TimecardsApiService,
                useValue: apiService
            },
            {
                provide: TimecardQueueDialogRequest,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(TimecardQueueDialogComponent, "Pending Timecards", dialogOptions, resolvedProviders, function (result) {
            callback(result);
        });
        return dialog;
    };
    Object.defineProperty(TimecardQueueDialogComponent.prototype, "canRefresh", {
        get: function () {
            return !this.isLoading && !!this.request && this.request.isValid;
        },
        enumerable: true,
        configurable: true
    });
    TimecardQueueDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    TimecardQueueDialogComponent.prototype.onRefresh = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.getPendingTimecards(this.request.orgLevel.id, this.request.payCycle.startDate, this.request.payCycle.endDate, this.request.approveTimeCards ? this.request.empIds : null)
            .then(function (stats) {
            _this.isLoading = false;
            _this.stats = stats;
            stats.pendingRecords === 0 ? _this.canApprove = true : _this.canApprove = false;
        }).catch(function (reason) {
            _this.isLoading = false;
            _this.stats = null;
        });
    };
    TimecardQueueDialogComponent.prototype.onApprove = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return TimecardQueueDialogComponent;
}());
export { TimecardQueueDialogComponent };
