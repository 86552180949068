<div class="slx-so-toolbar">
  <slx-toolbar class="slx-so-toolbar-container" [alignExpandButtonRight]="!isDesktop()">
    <ng-template slxToolbarSectionTemplate [width]="getDateRangeWidth()">
      <div class="slx-so-toolbar__section slx-toolbar-section slx-border slx-no-padd-l">
        <slx-range-navigator
          [selectedRangeType]="selectedRangeType"
          [selectedDate]="selectedDate"
          [startOfWeek]="startOfWeek"
          (rangeChanged)="onRangeChanged($event)"
          (rangeTypeChanged)="onRangeTypeChanged($event)"
        ></slx-range-navigator>
      </div>
    </ng-template>
    <ng-template slxToolbarSectionTemplate width="40" let-sectionType="sectionType">
      <span *ngIf="isVisibleSection(sectionType)" class="slx-so-toolbar__filter"
        [ngClass]="{ 'open': isShowMobileFilter }"
        [popper]="popperFilter"
        [popperTrigger]="'click'"
        [popperPlacement]="'bottom-start'"
        [popperDisableStyle]="true"
        [popperHideOnClickOutside]="true"
        (popperOnShown)="isShowMobileFilter = true;"
        (popperOnHidden)="isShowMobileFilter = false;"
      >
        <i class="fas fa-filter" aria-hidden="true"></i>
      </span>
    </ng-template>
    <ng-template slxToolbarSectionTemplate width="195" let-sectionType="sectionType">
      <div class="slx-so-toolbar__section slx-toolbar-section slx-border dropdown" *ngIf="isVisibleSection(sectionType)">
        <slx-dropdown-list class="slx-wide" [options]="groupByList" [(ngModel)]="groupBy" (ngModelChange)="onGrouppingChanged($event)"></slx-dropdown-list>
      </div>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" width="160">
      <div class="slx-so-toolbar__section slx-toolbar-section no-wrap-elems" [ngClass]="{ 'collapsed': !isVisibleSection(sectionType), 'slx-border': isVisibleSection(sectionType) }">
        <slx-radio-button [ngClass]="{ 'slx-so-toolbar__noPadLeft': isVisibleSection(sectionType) }" caption="Hours" option="idealScheduleHours" [(ngModel)]="consoleConfig.displayType" (ngModelChange)="onConfigChanged()"></slx-radio-button>
        <slx-radio-button [ngClass]="{ 'slx-so-toolbar__noPadRight': isVisibleSection(sectionType) }" caption="PPD" option="idealSchedulePPD" [(ngModel)]="consoleConfig.displayType" (ngModelChange)="onConfigChanged()"></slx-radio-button>
      </div>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" width="100">
      <div *ngIf="isVisibleSection(sectionType)" class="slx-so-toolbar__section slx-toolbar-section slx-border">
        <span class="slx-so-toolbar__button slx-hidden-in-collapsed"
          [popper]="popperLimits"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="true"
          (popperOnShown)="isShowLimits = true;"
          (popperOnHidden)="isShowLimits = false;"
        >
          <i class="far fa-arrows-alt-v" aria-hidden="true"></i>
          <span class="slx-so-toolbar__btn-text">Limits</span>
          <i [ngClass]="(isShowLimits ? 'far fa-angle-up' : 'far fa-angle-down') + ' slx-so-toolbar__btn-icon'" aria-hidden="true"></i>
        </span>
      </div>
      <ng-container *ngIf="!isVisibleSection(sectionType)">
        <div class="slx-so-toolbar__section slx-toolbar-section limits" [ngClass]="{ 'collapsed': !isVisibleSection(sectionType), 'slx-border': isVisibleSection(sectionType) }">
          <span>
            <span class="slx-so-toolbar__lower-limit compact">
              <slx-percent-input slx-input slxSelectOnFocus
                type="text"
                placeholder="Lower Limits"
                name="lowerLimitCollapsed"
                [(ngModel)]="consoleConfig.lowerLimit"
                (ngModelChange)="onConfigChanged()"
              ></slx-percent-input>
            </span>
            <span class="slx-so-toolbar__limit-text compact">Lower Limits</span>
          </span>
          <span>
            <span class="slx-so-toolbar__upper-limit slx-so-upper-limit compact">
              <slx-percent-input slx-input slxSelectOnFocus
                type="text"
                placeholder="Upper Limits"
                name="upperLimitCollapsed"
                [(ngModel)]="consoleConfig.upperLimit"
                (ngModelChange)="onConfigChanged()"
              ></slx-percent-input>
            </span>
            <span class="slx-so-toolbar__limit-text compact">Upper Limits</span>
          </span>
        </div>
      </ng-container>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" width="160">

      <div *ngIf="isVisibleSection(sectionType)" class="slx-so-toolbar__section slx-toolbar-section slx-border">
        <span class="slx-so-toolbar__button"
          [popper]="popperCharts"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="true"
          [popperHideOnClickOutside]="true"
          (popperOnShown)="shownChart.isShown = true;"
          (popperOnHidden)="shownChart.isShown = false;"
        >
          <i [ngClass]="shownChart.icon" aria-hidden="true"></i>
          <span class="slx-so-toolbar__btn-text">{{ shownChart.text }}</span>
          <i [ngClass]="(shownChart.isShown ? 'far fa-angle-up' : 'far fa-angle-down') + ' slx-so-toolbar__btn-icon'" aria-hidden="true"></i>
        </span>
      </div>
      <ng-container *ngIf="!isVisibleSection(sectionType)">
          <div class="slx-so-toolbar__section slx-toolbar-section charts collapsed">
            <p class="slx-so-toolbar__m-item slx-so-toolbar__hover"
              [ngClass]="{'slx-so-toolbar__m-item_active': shownChart === chart, 'slx-so-toolbar__m-item_inactive': shownChart !== chart}"
              *ngFor="let chart of charts;" (click)="onChartItemClick(chart, popperCharts)">
              <span class="slx-so-toolbar__item-icon">
                <i [ngClass]="chart.icon" aria-hidden="true"></i>
              </span>
              <span class="slx-so-toolbar__item-text">{{ chart.text }}</span>
            </p>
          </div>
      </ng-container>

    </ng-template>
    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" width="130">
      <div class="slx-so-toolbar__section slx-toolbar-section expands no-wrap-elems"
        [ngClass]="{ 'collapsed slx-so-toolbar__hover': !isVisibleSection(sectionType), 'slx-border': isVisibleSection(sectionType) }"
        (click)="onToggleSection(true)"
      >
        <span class="slx-so-toolbar__item-icon">
          <i class="fas fa-angle-double-down" aria-hidden="true"></i>
        </span>
        <span class="slx-so-toolbar__item-text">Expand All</span>
      </div>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" width="130">
      <div class="slx-so-toolbar__section slx-toolbar-section expands no-wrap-elems"
        [ngClass]="{ 'collapsed slx-so-toolbar__hover': !isVisibleSection(sectionType) }"
        (click)="onToggleSection(false)"
      >
        <span class="slx-so-toolbar__item-icon">
          <i class="fas fa-angle-double-up" aria-hidden="true"></i>
        </span>
        <span class="slx-so-toolbar__item-text">Collapse All</span>
      </div>
    </ng-template>
    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightOnlyDesktop" width="300">
      <div *ngIf="isDesktop()" class="slx-so-toolbar__section slx-toolbar-section slx-no-padd-r filters-container">
        <div class="filters-item">
          <slx-input-decorator className="slx-no-border slx-no-label slx-checkbox-no-label slx-no-error-block slx-small-font">
            <slx-checkbox-input slx-input
              name="showOnlyDirectCare"
              fieldName="showOnlyDirectCare"
              [caption]="'Direct Care only'"
              [(ngModel)]="showOnlyDirectCare"
              (ngModelChange)="onChangeShowDirectCare()"
              [readonly]="!isDepartment">
            </slx-checkbox-input>
          </slx-input-decorator>
        </div>
        <div class="filters-item">
          <slx-multiselect
            [options]="filtersList"
            [(ngModel)]="appliedFilters"
            placeholder="Filter..."
            name="posFilter"
            [readonly]="showOnlyDirectCare">
          </slx-multiselect>
        </div>
      </div>
    </ng-template>
  </slx-toolbar>
  <popper-content #popperLimits>
    <div class="slx-so-toolbar__menu limits">
      <p class="slx-so-toolbar__m-item">
        <span class="slx-so-toolbar__limit-text">Lower Limits</span>
        <span class="slx-so-toolbar__lower-limit">
          <slx-percent-input slx-input slxSelectOnFocus
            type="text"
            placeholder="Lower Limits"
            name="lowerLimit"
            [(ngModel)]="consoleConfig.lowerLimit"
            (ngModelChange)="onConfigChanged()"
          ></slx-percent-input>
        </span>
      </p>
      <p class="slx-so-toolbar__m-item">
        <span class="slx-so-toolbar__limit-text">Upper Limits</span>
        <span class="slx-so-toolbar__upper-limit slx-so-upper-limit">
          <slx-percent-input slx-input slxSelectOnFocus
            type="text"
            placeholder="Upper Limits"
            name="upperLimit"
            [(ngModel)]="consoleConfig.upperLimit"
            (ngModelChange)="onConfigChanged()"
          ></slx-percent-input>
        </span>
      </p>
    </div>
  </popper-content>
  <popper-content #popperCharts>
    <div class="slx-so-toolbar__menu">
      <p class="slx-so-toolbar__m-item slx-so-toolbar__hover"
        [ngClass]="{'slx-so-toolbar__m-item_active': shownChart === chart, 'slx-so-toolbar__m-item_inactive': shownChart !== chart}"
        *ngFor="let chart of charts;"
        (click)="onChartItemClick(chart, popperCharts)"
      >
        <span class="slx-so-toolbar__item-icon"><i [ngClass]="chart.icon" aria-hidden="true"></i></span>
        <span class="slx-so-toolbar__item-text">{{ chart.text }}</span>
      </p>
    </div>
  </popper-content>
  <popper-content class="slx-so-filters-popper" #popperFilter>
    <div class="slx-so-filters-popper__wrapper fix-height">
      <div class="mobile-direct-care-filter">
        <slx-input-decorator className="slx-no-border slx-no-label slx-checkbox-no-label slx-no-error-block slx-small-font">
            <slx-checkbox-input slx-input
              name="showOnlyDirectCare"
              fieldName="showOnlyDirectCare"
              [caption]="'Direct Care only'"
              [(ngModel)]="showOnlyDirectCare"
              (ngModelChange)="onChangeShowDirectCare()"
              [readonly]="!isDepartment"
            ></slx-checkbox-input>
          </slx-input-decorator>
      </div>
      <slx-multiselect
        [options]="filtersList"
        [(ngModel)]="appliedFilters"
        [staticOpened]="isShowMobileFilter"
        [readonly]="showOnlyDirectCare"
        placeholder="Filter..."
        name="posFilter"
      ></slx-multiselect>
    </div>
  </popper-content>
</div>
