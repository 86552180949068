import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ManagementBaseService } from '../../../core/services/index';
import { KendoGridStateHelper } from '../../../common/models/index';

import { UserRolesApiService } from './user-roles-api.service';
import { RoleAssignementsWrapper, UserRecord } from '../../models/index';
import { UsersRoles } from '../../models/users/models/users-roles.model';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators';
import { OrgLevel } from './../../../state-model/models/org-level/org-level';

@Injectable()
export class UserRolesManagementService extends ManagementBaseService<any, any> {

  public get onRolesLoaded$(): ReplaySubject<RoleAssignementsWrapper[]> {
    return this.m_onRolesLoaded;
  }

  public get onUsersWithRolesLoaded$(): ReplaySubject<UsersRoles> {
    return this.m_onUsersWithRolesLoaded;
  }

  public get onRoleUsersLoaded$(): ReplaySubject<{ dataItem: RoleAssignementsWrapper, users: UserRecord[] }> {
    return this.m_onRoleUsersLoaded;
  }

  public get gridState(): KendoGridStateHelper<RoleAssignementsWrapper> {
    return this.m_gridState;
  }

  private m_onRolesLoaded: ReplaySubject<RoleAssignementsWrapper[]>;
  private m_onUsersWithRolesLoaded: ReplaySubject<UsersRoles>;
  private m_onRoleUsersLoaded: ReplaySubject<{ dataItem: RoleAssignementsWrapper, users: UserRecord[] }>;
  private m_gridState: KendoGridStateHelper<RoleAssignementsWrapper>;
  public orgLevelId: number ;
  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  @unsubscribeInService()
  private orgLevelSubscription: Subscription;
  
  constructor(private api: UserRolesApiService) {
    super();
    this.m_onRolesLoaded = new ReplaySubject<RoleAssignementsWrapper[]>();
    this.m_onUsersWithRolesLoaded = new ReplaySubject<UsersRoles>();
    this.m_onRoleUsersLoaded = new ReplaySubject<{ dataItem: RoleAssignementsWrapper, users: UserRecord[] }>();
    this.m_gridState = new KendoGridStateHelper<RoleAssignementsWrapper>();
  }

  public loadRoles(): void {
    this.onLoadStatusChanged(true);
    this.api.getRoles().then((roles: RoleAssignementsWrapper[]) => {
      this.m_onRolesLoaded.next(roles);
      this.onLoadStatusChanged(false);
    }).catch((res: any) => {
      this.onError(res);
    });
  }

  public getRoleUsers(wrapper: RoleAssignementsWrapper): void {
    this.onLoadStatusChanged(true);
    this.api.getRoleUsers(wrapper.role.id).then((result: UserRecord[]) => {
      wrapper.users = result;
      this.m_onRoleUsersLoaded.next({ dataItem: wrapper, users: result });
      this.onLoadStatusChanged(false);
    }).catch((res: any) => {
      this.onError(res);
    });
  }

  public filterByUser(nameEntry: String): void {
    //test
  }
  public loadUsersRolesWithOrglevel() : void {
    this.onLoadStatusChanged(true);
    this.api.getUsersWithRolesOrglevel(this.orgLevelId).then((roles: UsersRoles) => {
      this.m_onUsersWithRolesLoaded.next(roles);
      this.onLoadStatusChanged(false);
    }).catch((res: any) => {
      this.onError(res);
    });
  }

  public init(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
      this.loadUsersRolesWithOrglevel();
    });
  }
}
