<form #form="ngForm" novalidate>
    <div class="filters-container" *ngIf="management?.allowFilters">
        <div class="col">
            <slx-input-decorator className="slx-auto-height">
                <slx-dropdown-multiselect slx-input [options]="management.orgLevelRelatedDepartments" orderLookup="asc" placeholder="Department" name="departmentFilter"
                    [(ngModel)]="department" (ngModelChange)="onFilterChanged()" titleField="name" valueField="id">
                </slx-dropdown-multiselect>
            </slx-input-decorator>
        </div>
        <div class="col">
            <slx-input-decorator className="slx-auto-height">
                <slx-dropdown-multiselect slx-input [options]="management.positions" orderLookup="asc" placeholder="Position" name="positionFilter"
                    [(ngModel)]="position" (ngModelChange)="onFilterChanged()" titleField="name" valueField="id">
                </slx-dropdown-multiselect>
            </slx-input-decorator>
        </div>
        <div class="col">
            <slx-input-decorator className="slx-auto-height">
                <slx-dropdown-multiselect slx-input [options]="management.empTypes" orderLookup="asc" placeholder="Employee Type" name="empTypeFilter"
                    [(ngModel)]="empType" (ngModelChange)="onFilterChanged()" titleField="name" valueField="name">
                </slx-dropdown-multiselect>
            </slx-input-decorator>
        </div>
        <div class="col">
            <slx-input-decorator className="slx-auto-height">
                <slx-dropdown-multiselect slx-input [options]="management.payPolicies" orderLookup="asc" placeholder="Pay Policies" name="payPolicyFilter"
                    [(ngModel)]="payPolicy" (ngModelChange)="onFilterChanged()" titleField="name" valueField="name">
                </slx-dropdown-multiselect>
            </slx-input-decorator>
        </div>
        <div class="col">
            <slx-input-decorator className="slx-auto-height">
                <slx-dropdown-multiselect slx-input [options]="management.shifDiffPolicies" orderLookup="asc" placeholder="Shift Diff Policies"
                    name="shiftDiffPolicyFilter" [(ngModel)]="shiftDiffPolicy" (ngModelChange)="onFilterChanged()" titleField="name"
                    valueField="name">
                </slx-dropdown-multiselect>
            </slx-input-decorator>
        </div>
    </div>
</form>
