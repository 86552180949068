
import { IOrganization, IPosition, IDepartment, Organization, Position, Department, EmpType, IEmpType } from '../../../../organization/models/index';
import { IEmpBenefitNotes, EmpBenefitNotes } from './index';

export interface IBenefitEnrolledEmployee {
  id: number;
  employeeId: number;
  benefitLineId: number;
  benefitTierId: number;
  benefitTierOptionId: number;
  startDate: string;
  endDate: string;
  payrollDeductionStartDate: string;
  payrollDeductionEndDate: string;
  employeeContributionPercentage: number;
  employeeContributionAmount: number;
  employerContributionPercentage: number;
  employerContributionAmount: number;
  coverage: number;
  inputCoverage?: number;
  calculatedCoverage?: number;
  limit: number;
  cost: number;
  dropReason: string;
  dropEventDate: string;
  coverageEndDate: string;
  modifiedAt: string;
  modifiedBy: string;
  addedAt: string;
  addedBy: string;
  isDeleted: boolean;
  isOverridden: boolean;
  benefitLineName: string;
  employeeName: string;
  department: IDepartment;
  age: number;
  dateOfBirth: string;
  employeeType: string;
  sex: string;
  organization: IOrganization;
  position: IPosition;
  optionRateCode: string;
  optionType: string;
  employeeToBenefitNotes: IEmpBenefitNotes;
  maxMatchGrant?: any;
  benefitOptionData?: any;
  optionCode?: any;
  maxEmpContribution?: any;
  type?: IEmpType;
  editedItemCoverage?: any;
}

export class BenefitEnrolledEmployee {
  public id: number;
  public employeeId: number;
  public benefitLineId: number;
  public benefitTierId: number;
  public benefitTierOptionId?: number;
  public startDate: Date;
  public endDate: Date;
  public payrollDeductionStartDate: Date;
  public payrollDeductionEndDate: Date;
  public employeeContributionPercentage: number;
  public employeeContributionAmount: number;
  public employerContributionPercentage: number;
  public employerContributionAmount: number;
  public defaultEmployeeContributionAmount?: number;
  public defaultEmployerContributionAmount?: number;
  public coverage: number;
  public calculatedCoverage: number;
  public originalCoverage?: number;
  public limit: number;
  public cost: number;
  public dropReason: string;
  public dropEventDate: Date;
  public coverageEndDate: Date;
  public modifiedAt: Date;
  public modifiedBy: string;
  public addedAt: Date;
  public addedBy: string;
  public isDeleted: boolean;
  public isOverridden: boolean;
  public benefitLineName: string;
  public employeeName: string;
  public department: Department;
  public age: number;
  public dateOfBirth: Date;
  public employeeType: string;
  public sex: string;
  public organization: Organization;
  public position: Position;
  public optionRateCode: string;
  public optionType: string;
  public employeeToBenefitNotes: EmpBenefitNotes;
  public maxMatchGrant?: any;
  public benefitOptionData?: any;
  public optionCode?: any;
  public maxEmpContribution?: any;
  public type?: EmpType;
  public code?: any;
  public notesShow?: boolean;

  public isSelected?: boolean;
  public selectable?: boolean;

  public editedItemCoverage?: any;
  public optionViewText?:string;
}
