import { IMessageList, EmployeeProfilePicture, ILatestScheduleCycle, ISectionMessagesRequest, IMessageCountResponse, IMessageCountRequest, MessageCountResponse, IMessageDataAndCountRequest } from '../models/message-list';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Meta } from '../../../core/models/api/meta';
import { MessagemapService } from '../messagemap.service';
import { MessageList, UserPicture, IUserPicture } from '../models';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { ApiService } from '../../../core/services/api/api.service'
import { HttpRequest } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { SessionService, MetaMapService } from '../../../../app/core/services';
import { appConfig } from '../../../app.config';
import { configurationConfig } from '../../../configuration/configuration.config';
import { ComposeNewTabsPermissions } from '../models/ComposeNewTabsPermissions';


@Injectable(

)
export class MessageApiService {
  constructor(private apiService: ApiService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private messagemapService: MessagemapService,
    private sessionService: SessionService) {
  }


  public async getMessages(searchText: string, dateOrderByAscending: boolean, nameOrderByAscending: boolean, pageno: number, orgLevelId: number, isArchived: boolean, includeCold: boolean, pageSize: number, isMyMessage: boolean): Promise<IMessageList[]> {
    const url: string = `${this.getMessageByUserIdUrl()}?` + `${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    const obj = {
       page: pageno,
       pageSize: pageSize,
       employees: [],
       isArchived: isArchived,
       searchText: searchText,
       orgLevelId: orgLevelId,
       dateOrderByAscending: dateOrderByAscending,
       nameOrderByAscending: nameOrderByAscending,
       includeCold: includeCold,
       isMyMessage: isMyMessage
     };
    let request: HttpRequest<any> = new HttpRequest('POST', url, obj);
    let promise: Promise<IMessageList[]> = this.apiUtilService.request<IMessageList[], Meta>(request)
      .then((response: any) =>
        this.messagemapService.mapToMessasgeListRecords(response['results'])
      );
    return promise;
  }
  public  async getTabMessages(iSectionMessagesRequest:ISectionMessagesRequest): Promise<IMessageList[]> {
    const url: string = `${this.getTabMessageByUserId()}?` + `${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    let request: HttpRequest<any> = new HttpRequest('POST', url, iSectionMessagesRequest);
    let promise: Promise<IMessageList[]> = this.apiUtilService.request<IMessageList[], Meta>(request)
      .then((response: any) =>
        this.messagemapService.mapToMessasgeListRecords(response['results'])
      );
    return promise;
  }

  public  async getTabsMessagesCount(messageCountRequest: IMessageCountRequest,term?:string): Promise<MessageCountResponse[]> {
    if(!_.isUndefined(term)) {
      messageCountRequest.searchText=term;
    }
    const url: string = `${this.getMessageCountApiUrl()}?` + `${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    let request: HttpRequest<any> = new HttpRequest('POST', url, messageCountRequest);
    let promise: Promise<IMessageCountResponse[]> = this.apiUtilService.request<IMessageCountResponse[], Meta>(request)
      .then((response: any) =>
              this.messagemapService.mapToTabCountRecords(response)
       
      );
    return promise;

  }
  
  public async updateArchiveMessages(obj): Promise<any> {
    const url = `${this.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.archive.configuration}?${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    const request = this.urlParamsService.createPutRequest(url, obj, null, null);
    return this.apiUtilService.request<any, any>(request);
  }

  public async updateUnarchiveMessages(obj) {
    const url = `${this.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.unArchive.configuration}?${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    const request = this.urlParamsService.createPutRequest(url, obj, null, null);
    return this.apiUtilService.request<any, any>(request);
  }

  public async updateReadMessages(obj) {
    const url = `${this.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.read.configuration}?${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    const request = this.urlParamsService.createPutRequest(url, obj, null, null);
    return this.apiUtilService.request<any, any>(request);
  }

  public async updateUnreadMessages(obj) {
    const url = `${this.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.unRead.configuration}?${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();;
    const request = this.urlParamsService.createPutRequest(url, obj, null, null);
    return this.apiUtilService.request<any, any>(request);
  }

  public getMessageByUserIdUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.paged}`;
  }
  public getArchiveMessagesUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.archived}`;
  }

  private getCommunicationApiRoot(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.root}`;
  }

  private getCommunicationApiRootVersion(): string {
    return `${this.apiUtilService.getCommunicationApiRootVersion()}`;
  }

  public async getMessageThread(employeeId, orgLevelId,isColdData:boolean,isMyMessage : boolean): Promise<any> {
    const url: string = `${this.getMessageThreadUrl()}?${configurationConfig.api.configuration.messageCenter.employeeId}=` + employeeId + `&isColdData=` + isColdData + `&isMyMessage=` + isMyMessage + `&${configurationConfig.api.configuration.messageCenter.orgLevelId}=` + orgLevelId + `&${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    const response = await this.apiUtilService.request<any, Meta>(request);
    return this.messagemapService.mapToMessageThread(response);
  }

  public async getColdDataExists(employeeId, orgLevelId): Promise<any> {
    const getColdStorageFlagUrl =  `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.cold}/${configurationConfig.api.configuration.messageCenter.exists}`; 
    const url: string = `${getColdStorageFlagUrl}?${configurationConfig.api.configuration.messageCenter.employeeId}=${employeeId}&${configurationConfig.api.configuration.messageCenter.orgLevelId}=${orgLevelId}&${configurationConfig.api.configuration.messageCenter.apiVersion}=${this.getCommunicationApiRootVersion()}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    return this.apiUtilService.request<any, Meta>(request).then((res) => this.messagemapService.mapToColdStorageFlag(res));
  }

  public getMessageThreadUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.thread}`;
  }


  public async getMessageStatusUpdateMultiple(array): Promise<any> {
    let obj = {
      "employees": [array],
      "excludeEmployees": [],
      "all": false,
      "orgLevelId": null,
      "isArchived": null,
      "allUnRead": false,
      "includeCold":true
    }
    const url: string = `${this.getMessageUpdateByUserIdUrl()}` + `?${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    let request: HttpRequest<any> = new HttpRequest('PUT', url, obj);
    return this.apiUtilService.request<any, Meta>(request).then((response) => response);
  }
  public getMessageUpdateByUserIdUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.read.configuration}`;
  }

  public async getEmployeeSpecificData(empID): Promise<any> {
    const url: string = `${this.getEmployeeSpecificDataUrl()}/` + empID + `/${configurationConfig.api.configuration.messageCenter.getEmployeeStatus}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    return this.apiUtilService.request<any, Meta>(request).then((response) => response);
  }
  public getEmployeeSpecificDataUrl(): string {
    return `${appConfig.api.url}/${configurationConfig.api.configuration.messageCenter.v1}/${configurationConfig.api.configuration.messageCenter.messagecenter}`;
  }

  public async getRecentMessageByEmployeeId(employeeId: number, isMyMessage: boolean): Promise<IMessageList> {
    const url: string = `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.recentMessage}?${configurationConfig.api.configuration.messageCenter.employeeId}=` + employeeId + `&isMyMessage=`+isMyMessage+`&${configurationConfig.api.configuration.messageCenter.apiVersion}=` + this.getCommunicationApiRootVersion();
    let request: HttpRequest<any> = new HttpRequest('GET', url,);
    let promise: Promise<IMessageList> = this.apiUtilService.request<IMessageList, Meta>(request)
      .then((res: any) =>
        this.messagemapService.mapToRecentMessage(res)
      );
    return promise;
  }
  public async getComposeNewTabsPermissions(orgLevelId:number): Promise<ComposeNewTabsPermissions>{
    const url: string = `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.messageCenter.messagecenter}/${configurationConfig.api.configuration.messageCenter.getComposeNewTabsPermissions}/${orgLevelId}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url,);
    let promise: Promise<ComposeNewTabsPermissions>= this.apiUtilService.request<ComposeNewTabsPermissions, Meta>(request).
    then((res:any)=>this.messagemapService.mapToComposeNewTabsPermissions(res));
    return promise;

  }

  getQuery(query, searchText, dateOrderByAscending, nameOrderByAscending, includeCold) {
    if (searchText !== '' && searchText !== null) {
      query = query + `&${configurationConfig.api.configuration.messageCenter.searchText}=` + searchText;
    }
    if (dateOrderByAscending !== null) {
      query = query + `&${configurationConfig.api.configuration.messageCenter.dateOrderByAscending}=` + dateOrderByAscending;
    }
    if (nameOrderByAscending !== null) {
      query = query + `&${configurationConfig.api.configuration.messageCenter.nameOrderByAscending}=` + nameOrderByAscending;
    }
    if(includeCold) {
      query = query + `&${configurationConfig.api.configuration.messageCenter.includeCold}=` + includeCold;
    }
    return query;
  }

  public async getEmployeePicsByUserIds(userIds: Array<number>): Promise<UserPicture[]> {
    const url: string = `${this.getEmployeePicsByUsersUrl()}`;
    let request: HttpRequest<any> = new HttpRequest('POST', url, userIds);
    return this.apiUtilService.request<IUserPicture[], Meta>(request).then(
      (response) => this.messagemapService.mapToUserPictures(response.data)
    );
  }

  public async getLatestScheduleCycleByEmployeeId(empId: number): Promise<ILatestScheduleCycle> {
    const url: string = `${this.getLatestScheduleCycleUrl(empId)}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    return this.apiUtilService.request<ILatestScheduleCycle, Meta>(request).then(
      (response) => this.messagemapService.mapToLatestScheduleCycle(response.data)
    );
  }

  public getLatestScheduleCycleUrl(empId: number) {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.messageCenter.messagecenter+'/'+configurationConfig.api.configuration.messageCenter.getLatestScheduleCycle}?${configurationConfig.api.configuration.messageCenter.employeeId}=${empId}` 
  }

  public getEmployeePicsByUsersUrl(): string {
    return `${this.getApiRoot()}` + `/${configurationConfig.api.configuration.messageCenter.user}/${configurationConfig.api.configuration.messageCenter.thubmnails}`;
  }

  public getTabMessageByUserId(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.pageData}`;
  }

  public getMessageCountApiUrl(): string {
    return `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.messageCount}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}`;
  }

  public getColdStorageExist(orgLevelId: number, isArchived: boolean): Promise<any> {
    const getColdStorageFlagUrl =  `${this.apiUtilService.getCommunicationApiRoot()}/${configurationConfig.api.configuration.messageCenter.messages}/${configurationConfig.api.configuration.messageCenter.cold}/${configurationConfig.api.configuration.messageCenter.exists}`; 
    const url: string = `${getColdStorageFlagUrl}?${configurationConfig.api.configuration.messageCenter.isArchived}=${isArchived}&${configurationConfig.api.configuration.messageCenter.orgLevelId}=${orgLevelId}&${configurationConfig.api.configuration.messageCenter.apiVersion}=${this.getCommunicationApiRootVersion()}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    return this.apiUtilService.request<any, Meta>(request).then((res) => this.messagemapService.mapToColdStorageFlag(res));
  }

}
