import { OnInit, EventEmitter, SimpleChanges } from '@angular/core';
import { ScheduleRotationsManagementService } from '../../../../services/scheduled-rotation-template/schedule-rotations-management.service';
import * as _ from 'lodash';
var AddEmpPositionGridComponent = /** @class */ (function () {
    function AddEmpPositionGridComponent(manService) {
        var _this = this;
        this.manService = manService;
        this.onSaveEmp = new EventEmitter();
        this.onSavePos = new EventEmitter();
        this.onDeleteRotations = new EventEmitter();
        this.isAddPerson = false;
        this.isAddPosition = false;
        this.isProceed = false;
        this.assignedEmployeeAndEmptySlotEmployee = [];
        this.backToListTriggerSubscription = this.manService.backToListTrigger$.subscribe(function (isTriggered) {
            if (isTriggered) {
                _this.backToList();
            }
        });
        this.updateNoRotationEmpDetailsSubscription = this.manService.noRotationEmployeeList$.subscribe(function (data) {
            _this.noRotationEmployeeList = data;
        });
    }
    AddEmpPositionGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.positionItems = this.manService.positionLookup.items;
        this.actualNoRotationEmpData = this.noRotationEmployeeList;
        if (!this.isPositionNotExists) {
            this.selectedPosition = this.positionItems.find(function (x) { return x.id == _this.employeeData.jobCode; });
        }
    };
    AddEmpPositionGridComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.noRotationsEmployees && changes.noRotationsEmployees.currentValue) {
            this.positionItems = this.manService.positionLookup.items;
            this.actualNoRotationEmpData = this.noRotationsEmployees;
            if (!this.isPositionNotExists) {
                this.selectedPosition = this.positionItems.find(function (x) { return x.id == _this.employeeData.jobCode; });
            }
        }
    };
    Object.defineProperty(AddEmpPositionGridComponent.prototype, "isEmployeeNotExists", {
        get: function () {
            return this.employeeData.employeeId === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmpPositionGridComponent.prototype, "isPositionNotExists", {
        get: function () {
            return this.employeeData.jobCode === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmpPositionGridComponent.prototype, "isRotationSaved", {
        get: function () {
            return this.employeeData.slotId !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmpPositionGridComponent.prototype, "isShiftsAssigned", {
        get: function () {
            return this.employeeData.scheduleDays.some(function (x) { return x.shifts.length > 0; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmpPositionGridComponent.prototype, "isEditMode", {
        get: function () {
            return this.employeeData.scheduleDays.some(function (x) { return x.shifts.filter(function (x) { return x.isView === false; }).length > 0; });
        },
        enumerable: true,
        configurable: true
    });
    AddEmpPositionGridComponent.prototype.addPersonOrPosition = function (ind) {
        var _this = this;
        if (this.manService.isEmployeeAssigeeMode)
            return;
        this.manService.isEmployeeAssigeeMode = true;
        var filteredEmpIds = _.map(this.manService.filterEmployeeData, function (x) { return x.employeeId; });
        this.noRotationEmployeeList = _.filter(this.noRotationEmployeeList, function (x) { return filteredEmpIds.includes(x.employeeId); });
        if (this.manService.assignEmployeeToEmptySlot && this.manService.assignEmployeeToEmptySlot.length > 0) {
            var empIds_1 = this.manService.assignEmployeeToEmptySlot.map(function (x) { return x.employeeId; });
            this.noRotationEmployeeList = _.filter(this.noRotationEmployeeList, function (item) { return !empIds_1.includes(item.employeeId); });
        }
        this.updateNoRotationEmployees = this.noRotationEmployeeList;
        if (!this.isPositionNotExists) {
            this.selectedPosition = this.positionItems.find(function (x) { return x.id == _this.employeeData.jobCode; });
        }
        this.isProceed = true;
        this.isAddPerson = ind ? true : false;
        this.isAddPosition = !ind ? true : false;
        this.noRotationEmployeeList = this.actualNoRotationEmpData;
        if (this.selectedPosition) {
            this.noRotationEmployeeList = this.selectedPositionEmpDetails(this.selectedPosition);
        }
    };
    AddEmpPositionGridComponent.prototype.saveData = function () {
        this.manService.isEmployeeAssigeeMode = false;
        this.isProceed = true;
        if (this.isAddPerson && !this.isAddPosition) {
            if (this.selectedPerson) {
                this.onSaveEmp.emit({ emp: this.employeeData, empId: this.selectedPerson.employeeId });
            }
        }
        else if (!this.isAddPerson && this.isAddPosition) {
            if (this.selectedPosition) {
                this.employeeData.jobCode = this.selectedPosition.id;
                this.employeeData.positionName = this.selectedPosition.name;
                this.employeeData.isEmptySlot = true;
                this.employeeData.isPrimary = true;
                this.manService.assignedEmployeeAndEmptySlotEmployee.push(this.employeeData);
            }
        }
        this.backToList();
    };
    AddEmpPositionGridComponent.prototype.backToList = function () {
        this.isAddPerson = false;
        this.isAddPosition = false;
        this.selectedPerson = null;
        this.selectedPosition = null;
        this.isProceed = false;
        this.manService.isEmployeeAssigeeMode = false;
    };
    AddEmpPositionGridComponent.prototype.selectedPositionEmpDetails = function (selectedPosition) {
        var emptyRotationEmpDetails;
        if (this.manService.filterEmployeeData.length > 0) {
            var emptyRotationEmps = this.actualNoRotationEmpData.filter(function (x) { return x.jobCode === selectedPosition.id; });
            var noRotationEmpIds_1 = emptyRotationEmps.map(function (x) { return x.employeeId; });
            emptyRotationEmpDetails = this.manService.filterEmployeeData.filter(function (x) { return noRotationEmpIds_1.includes(x.employeeId); });
            if (this.manService.assignEmployeeToEmptySlot && this.manService.assignEmployeeToEmptySlot.length > 0) {
                var empIds_2 = this.manService.assignEmployeeToEmptySlot.map(function (x) { return x.employeeId; });
                emptyRotationEmpDetails = _.filter(emptyRotationEmpDetails, function (item) { return !empIds_2.includes(item.employeeId); });
            }
            return emptyRotationEmpDetails;
        }
        else {
            emptyRotationEmpDetails = this.actualNoRotationEmpData.filter(function (x) { return x.jobCode === selectedPosition.id; });
            return emptyRotationEmpDetails;
        }
    };
    AddEmpPositionGridComponent.prototype.removeSlotRotations = function () {
        this.onDeleteRotations.emit(this.employeeData);
    };
    Object.defineProperty(AddEmpPositionGridComponent.prototype, "updateNoRotationEmployees", {
        set: function (value) {
            this.noRotationEmployeeList = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmpPositionGridComponent.prototype, "noRotationsEmployees", {
        get: function () {
            return this.noRotationEmployeeList;
        },
        enumerable: true,
        configurable: true
    });
    return AddEmpPositionGridComponent;
}());
export { AddEmpPositionGridComponent };
