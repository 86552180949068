import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import { StateNavigationService, RestoreQueryParamsService } from '../../../common/services/navigation/index';
import { ChangeManagementService } from '../../../common/services/change-management/change-management.service';

@Injectable()
export class SsoGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeManagement: ChangeManagementService,
    private restoreQueryParamsService: RestoreQueryParamsService
  ) {
    Assert.isNotNull(authenticationService, 'authenticationService');
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let token: string = this.getQueryVariable('token');
    if (token) {
      return this.authenticationService.setToken(token).then(() => {
        let orglevel: string = this.getQueryVariable('orglevel');
        if (orglevel) {
          let navigationService: StateNavigationService = new StateNavigationService(this.router, this.activatedRoute, this.changeManagement, this.restoreQueryParamsService);
          const orgLevelId: number = _.toInteger(orglevel);
          navigationService.navigateToOrgLevel(orgLevelId);
        }
        return true;
      });
    }
    return Promise.resolve(true);
  }

  private getQueryVariable(variable: string): string {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return null;
  }
}
