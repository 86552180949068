import { DailyTimecardChangeStatus } from './daily-timecard-change-status';

export interface IEarningStatistic {
  codeId: number;
  codeName: string;
  shiftCodeName: string;
  interval: string;
  amount: number;
  positionName: string;
  exportCode: string;
}

export class EarningStatistic {
  public id: number;
  public name: string;
  public shiftCodeName: string;
  public interval: number;
  public get intervalMin(): number {
    return this.interval / 60;
  }
  public amount: number;
  public position: string;
  public exportCode: string;
  public changeStatus: DailyTimecardChangeStatus;
}
