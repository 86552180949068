import { OvertimeApprovalsRecord } from './../../models/overtime-approvals/overtime-approvals-record.model';
import { approvalsConfig } from './../../approvals.config';

import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { ApiUtilService } from '../../../../common/services/index';
import { OvertimeApprovalsMapService } from './overtime-approvals-map.service';
import { GenericField } from '../../../../organization/models/generic-list/generic-field';


@Injectable()
export class OvertimeApprovalsApiService {
  private apiUtilService: ApiUtilService;
  private mapService: OvertimeApprovalsMapService;

  constructor(apiUtilService: ApiUtilService, mapService: OvertimeApprovalsMapService) {
    this.apiUtilService = apiUtilService;
    this.mapService = mapService;
  }

  public approve(entries: StringMap<GenericField>[]): Promise<any> {
    return this.makeAction(approvalsConfig.api.ta.approveOvertime, entries);
  }

  public unapprove(entries: StringMap<GenericField>[]): Promise<any> {
    return this.makeAction(approvalsConfig.api.ta.unapproveOvertime, entries);
  }

  private makeAction(actionName: string, entries: StringMap<GenericField>[]): Promise<any> {
    const url: string = `${this.getApiRoot()}/${actionName}`;

    let request: HttpRequest<any> = new HttpRequest<any>('POST', url, this.mapService.toOvertimeApprovalsRecordsDto(entries));

    return this.apiUtilService.request(request);
  }

  private getApiRoot(): string {

    return `${this.apiUtilService.getApiRoot()}/${approvalsConfig.api.ta.root}`;
  }
}
