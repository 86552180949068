/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-console-overview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./schedule-console-overview-week/schedule-console-overview-week.component.ngfactory";
import * as i3 from "./schedule-console-overview-week/schedule-console-overview-week.component";
import * as i4 from "../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service";
import * as i5 from "./schedule-console-overview-day/schedule-console-overview-day.component.ngfactory";
import * as i6 from "./schedule-console-overview-day/schedule-console-overview-day.component";
import * as i7 from "../operational-console/operation-console-grid/operational-console-grid.component.ngfactory";
import * as i8 from "../operational-console/operation-console-grid/operational-console-grid.component";
import * as i9 from "../../../services/operational-console/operational-console-state.service";
import * as i10 from "../../../services/operational-console/operational-console-api.service";
import * as i11 from "../../../../common/services/modal/modal.service";
import * as i12 from "../../../../organization/services/org-level/org-level-watch.service";
import * as i13 from "../../../../organization/actions/org-level.actions";
import * as i14 from "@angular/router";
import * as i15 from "./schedule-console-overview-toolbar/schedule-console-overview-toolbar.component.ngfactory";
import * as i16 from "./schedule-console-overview-toolbar/schedule-console-overview-toolbar.component";
import * as i17 from "../../services/schedule-console/schedule-console-filters.service";
import * as i18 from "../../../../organization/services/lookup/lookup.service";
import * as i19 from "@angular/common";
import * as i20 from "./schedule-console-overview.component";
var styles_ScheduleConsoleOverviewComponent = [i0.styles];
var RenderType_ScheduleConsoleOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleConsoleOverviewComponent, data: {} });
export { RenderType_ScheduleConsoleOverviewComponent as RenderType_ScheduleConsoleOverviewComponent };
function View_ScheduleConsoleOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-console-overview-week", [], null, null, null, i2.View_ScheduleConsoleOverviewWeekComponent_0, i2.RenderType_ScheduleConsoleOverviewWeekComponent)), i1.ɵdid(1, 245760, null, 0, i3.ScheduleConsoleOverviewWeekComponent, [i1.ChangeDetectorRef, i4.ValuePairChartSeriesDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ScheduleConsoleOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-console-overview-day", [], null, null, null, i5.View_ScheduleConsoleOverviewDayComponent_0, i5.RenderType_ScheduleConsoleOverviewDayComponent)), i1.ɵdid(1, 245760, null, 0, i6.ScheduleConsoleOverviewDayComponent, [i1.ChangeDetectorRef, i4.ValuePairChartSeriesDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ScheduleConsoleOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-operational-console-grid", [], null, null, null, i7.View_OperationalConsoleGridComponent_0, i7.RenderType_OperationalConsoleGridComponent)), i1.ɵdid(1, 245760, null, 0, i8.OperationalConsoleGridComponent, [i9.OperationalConsoleStateService, i10.OperationalConsoleApiService, i11.ModalService, i12.OrgLevelWatchService, i13.OrgLevelActions, i14.Router, i14.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ScheduleConsoleOverviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "main"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-schedule-console-overview-toolbar", [], null, [[null, "onShowDetailedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onShowDetailedChange" === en)) {
        var pd_0 = (_co.onShowDetailedChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_ScheduleConsoleOverviewToolbarComponent_0, i15.RenderType_ScheduleConsoleOverviewToolbarComponent)), i1.ɵdid(2, 245760, null, 0, i16.ScheduleConsoleOverviewToolbarComponent, [i17.ScheduleConsoleFiltersService, i9.OperationalConsoleStateService, i18.LookupService], { showDetailedControl: [0, "showDetailedControl"] }, { onShowDetailedChange: "onShowDetailedChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleOverviewComponent_1)), i1.ɵdid(4, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleOverviewComponent_2)), i1.ɵdid(6, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScheduleConsoleOverviewComponent_3)), i1.ɵdid(8, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDayView; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isDayView; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.isDayView && !_co.showDetailed); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.isDayView && _co.showDetailed); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ScheduleConsoleOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-schedule-console-overview", [], null, null, null, View_ScheduleConsoleOverviewComponent_0, RenderType_ScheduleConsoleOverviewComponent)), i1.ɵprd(512, null, i4.ValuePairChartSeriesDataService, i4.ValuePairChartSeriesDataService, []), i1.ɵdid(2, 638976, null, 0, i20.ScheduleConsoleOverviewComponent, [i4.ValuePairChartSeriesDataService, i17.ScheduleConsoleFiltersService, i9.OperationalConsoleStateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ScheduleConsoleOverviewComponentNgFactory = i1.ɵccf("slx-schedule-console-overview", i20.ScheduleConsoleOverviewComponent, View_ScheduleConsoleOverviewComponent_Host_0, { consoleConfig: "consoleConfig", consoleData: "consoleData", rangeType: "rangeType" }, {}, []);
export { ScheduleConsoleOverviewComponentNgFactory as ScheduleConsoleOverviewComponentNgFactory };
