import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';

import { DeviceDetectorService } from '../../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';
import { DateRange, IDateRange, IColumnSettings } from '../../../../../core/models/index';
import { GenericListManagementService } from '../../../../../organization/services';
import { GenericListDefinition } from '../../../../../organization/models';
import { ProjectedHoursManagementService } from '../../../services';

@Component({
  selector: 'slx-projected-hours-toolbar',
  templateUrl: './projected-hours-toolbar.component.html',
  styleUrls: ['./projected-hours-toolbar.component.scss'],
})
export class ProjectedHoursToolbarComponent implements OnInit, OnDestroy {

  public dateRange: DateRange = new DateRange(null, null);

  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get dateRangeWidth(): number {
    return this.isDesktop ? 325 : 246;
  }

  public get newReviewBtnWidth(): number {
    return this.isDesktop ? 175 : 35;
  }

  public canCreateNewReview = false;
  public readonly columnsStateName: string = 'genericGridGroup';

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor (
    private managementService: ProjectedHoursManagementService,
    private genericListManagementService: GenericListManagementService,
    private columnManagementService: ColumnManagementService,
    private stateManagement: StateManagementService,
    private devDetector: DeviceDetectorService
  ) { }

  public ngOnInit(): void {
    this.initServices();
    this.defineDateRange();
  }

  public ngOnDestroy(): void { }


  public onChangeDates(range: IDateRange): void {
    this.managementService.changeDateRange(range);
  }

  public onClickExport(isPDF: boolean): void {
    this.managementService.doExport(isPDF);
  }

  public defineDateRange(): void {
    this.dateRange = this.managementService.getDefaultDateRange();
    this.onChangeDates(this.dateRange);
  }

  private initServices(): void {

    this.stateManagement.init('ProjectedHoursComponent');
    this.columnManagementService.init('ProjectedHoursComponent');

    this.subscriptions.definitionsLoadedSubscription = this.genericListManagementService.onDefinitionsLoaded$.subscribe((defs: GenericListDefinition) => {
      let visibleFields = _.filter(_.values(defs.fields), f => !f.hidden)
      this.columnManagementService.initGroup(this.columnsStateName, _.size(visibleFields));
    });
  }
}
