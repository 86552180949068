import { OrganizationPayrollContainer, IOrganizationPayrollContainer } from './../../models/payroll-export-test/organization-payroll-container';
import { IPayrollExportConfigContainer, PayrollExportConfigContainer } from './../../models/payroll-export-test/payroll-export-config-container';
import { IPayrollExportConfig } from './../../models/payroll-export-test/payroll-export-config';
import { LookupMapService } from './../../../../organization/services/lookup/lookup-map.service';
import { PayCycle, IPayCycle } from './../../../../organization/models/lookup/pay-cycle';
import { IPayrollExportTestsContainer } from './../../models/payroll-export-test/payroll-export-test-container';
import { IOrganizationPayroll } from './../../models/payroll-export-test/organization-payroll';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { payrollConfig } from '../../payroll.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { PayrollExportContainer, IPayrollExportContainer } from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { PayrollExportTestMapService } from './payroll-export-test-map.service';
import { OrganizationPayroll } from '../../models/payroll-export-test/organization-payroll';
import { PayrollExportTestsContainer } from '../../models/payroll-export-test/payroll-export-test-container';
import { PayrollExportConfig } from '../../models/payroll-export-test/payroll-export-config';
import { IOrgPayrollExportStatus, OrgPayrollExportStatus } from '../../models/payroll-export-test/org-payroll-export-status';
import { ExportPayrollTimeInterval } from '../../models/payroll-export-test/export-payroll-time-interval';

@Injectable()
export class PayrollExportTestApiService {
    constructor(
        private mapService: PayrollExportTestMapService,
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private lookupMapService: LookupMapService) {
    }

    public getOrganizationPayrollList(orgLevelId: number, startDate: Date, endDate: Date): Promise<OrganizationPayrollContainer> {
        const url: string = this.getCyclesApiRoot();
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgLevelId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });

        let promise: Promise<OrganizationPayrollContainer> = this.apiUtilService.request<IOrganizationPayrollContainer, Meta>(request)
        .then((response: ResponseBody<IOrganizationPayrollContainer, Meta>) => {
            return this.mapService.mapOrganizationPayrollContainer(response.data);
        });
        return promise;
    }

    public getTests(orgPayrollId: number): Promise<PayrollExportTestsContainer> {
        const url: string = `${this.getTestApiRoot()}/${orgPayrollId}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);

        let promise: Promise<PayrollExportTestsContainer> = this.apiUtilService.request<IPayrollExportTestsContainer, Meta>(request)
        .then((response: ResponseBody<IPayrollExportTestsContainer, Meta>) => {
            return this.mapService.mapPayrollExportTestsContainer(response.data);
        });
        return promise;
    }

    public runTests(organizationId: number, startDate: Date, endDate: Date): Promise<boolean> {
        const url: string = `${this.getTestApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.run}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
            organizationId: organizationId,
            startDate: dateTimeUtils.convertToDtoString(startDate),
            endDate: dateTimeUtils.convertToDtoString(endDate)
        });

        let promise: Promise<boolean> = this.apiUtilService.request<boolean, Meta>(request)
        .then((response: ResponseBody<boolean, Meta>) => {
            return true;
        })
        .catch((reason: any) => {
            return false;
        });
        return promise;
    }

    public getPayCycles(orgLevelId: number): Promise<PayCycle[]> {
        const url: string = `${this.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.paycycles}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgLevelId
        });

        let promise: Promise<PayCycle[]> = this.apiUtilService.request<IPayCycle[], Meta>(request)
        .then((response: ResponseBody<IPayCycle[], Meta>) => {
            return this.lookupMapService.mapPayCycles(response.data);
        });
        return promise;
    }
    
    public getConfig(orgLevelId: number): Promise<PayrollExportConfigContainer> {
        const url: string = `${this.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.tests}/${payrollConfig.api.ta.orglevel.payroll.config}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
            orgLevelId: orgLevelId
        });

        let promise: Promise<PayrollExportConfigContainer> = this.apiUtilService.request<IPayrollExportConfigContainer, Meta>(request)
        .then((response: ResponseBody<IPayrollExportConfigContainer, Meta>) => {
            return this.mapService.mapPayrollExportConfigContainer(response.data);
        });
        return promise;
    }

    public saveConfig(config: PayrollExportConfigContainer): Promise<PayrollExportConfigContainer> {
        const url: string = `${this.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.tests}/${payrollConfig.api.ta.orglevel.payroll.config}`;
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, this.mapService.mapPayrollExportConfigContainerDTO(config));
        let promise: Promise<PayrollExportConfigContainer> = this.apiUtilService.request<IPayrollExportConfigContainer, Meta>(request)
        .then((response: ResponseBody<IPayrollExportConfigContainer, Meta>) => {
            return this.mapService.mapPayrollExportConfigContainer(response.data);
        });
        return promise;
    }

    public saveGlobalFlag(globalEnabled: boolean): Promise<boolean> {
        const url: string = `${this.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.tests}/${payrollConfig.api.ta.orglevel.payroll.config}/${payrollConfig.api.ta.orglevel.payroll.global}`;
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, null, { enabled: globalEnabled });
        let promise: Promise<boolean> = this.apiUtilService.request<any, Meta>(request)
        .then((response: ResponseBody<any, Meta>) => {
            return true;
        })
        .catch((reason: any) => {
            return false;
        });
        return promise;
    }

    public lockOrganizationPayroll(organizationPayrollId: number, lock: boolean): Promise<boolean> {
        const operation: string = lock ? payrollConfig.api.ta.orglevel.payroll.lock : payrollConfig.api.ta.orglevel.payroll.unlock;
        const url: string = `${this.getCyclesApiRoot()}/${organizationPayrollId}/${operation}`;
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url);

        let promise: Promise<boolean> = this.apiUtilService.request<any, Meta>(request)
        .then((response: ResponseBody<any, Meta>) => {
            return true;
        }).catch((reason: any) => {
            return false;
        });
        return promise;
    }
    public isAccrualModuleEnabled(): Promise<boolean> {
      const url: string = `${this.getAccrualsApiRoot()}/${payrollConfig.api.ta.accruals.exists}`;
      let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url);
      let promise: Promise<boolean> = this.apiUtilService.request<boolean, Meta>(request)
        .then((response: ResponseBody<boolean, Meta>) => {
          return response.data;
        })
        .catch((reason: any) => {
          return false;
        });
      return promise;
    }

    private getCyclesApiRoot(): string {
        return `${this.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.cycles}`;
    }

    private getTestApiRoot(): string {
        return `${this.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.tests}`;
    }
    private getAccrualsApiRoot(): string {
      return `${this.apiUtilService.getApiRoot()}/${payrollConfig.api.ta.accruals.root}`;
    }
    private getApiRoot(): string {
        return `${this.apiUtilService.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.root}`;
    }

    private getExportApiRoot(): string {
        return `${this.getApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.export.root}`;
    }

    public getOrganizationPayrollExportStatus(exportedIds: string): Promise<OrgPayrollExportStatus[]> {
        const url: string = `${this.getExportApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.export.status}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
            exportedIds: exportedIds
        });

        let promise: Promise<OrgPayrollExportStatus[]> = this.apiUtilService.request<OrgPayrollExportStatus[], Meta>(request)
        .then((response: ResponseBody<OrgPayrollExportStatus[], Meta>) => {
            return this.mapService.mapOrgPayrollExportStatusDTOList(response.data);
        });
        return promise;
    }

    public addOrganizationPayrollExportStatus(orgPayrollId: number): Promise<any> {
        const url: string = `${this.getExportApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.export.status}/${orgPayrollId}`;
        const body = { payrollRecordId: orgPayrollId };
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
        .then((response: ResponseBody<number, Meta>) => {
            return response.data;
        });
        return promise;
    }

    public getInprogressExportStatus(exportedIds: string): Promise<ExportPayrollTimeInterval[]> {
        const url: string = `${this.getExportApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.export.status}/${payrollConfig.api.ta.orglevel.payroll.export.inprogress}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
            exportedIds: exportedIds
        });

        let promise: Promise<ExportPayrollTimeInterval[]> = this.apiUtilService.request<ExportPayrollTimeInterval[], Meta>(request)
        .then((response: ResponseBody<ExportPayrollTimeInterval[], Meta>) => {
            return this.mapService.mapExportPayrollTimeIntervalDTOList(response.data);
        });
        return promise;
    }
}
