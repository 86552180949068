import { Injectable } from '@angular/core';
import { AgencyShiftDetails } from '../../models/agency-staffing-console/agency-shift-details';
import { AgencyShiftSummary } from '../../models/agency-staffing-console/agency-shift-short-details';

@Injectable({
  providedIn: 'root'
})
export class AgencyStaffingConsoleMapService {
 

  constructor() { }
  mapShiftShortDetails(data: AgencyShiftSummary[]): any {
    let summaryDetails : AgencyShiftSummary[] = [];
    if ( data != null ) {
      data.forEach(element => {
        let total : number = 0;
        element.agencyShiftCount.forEach( stateElement => {
          total += stateElement.shiftCount;
        });
        element.totalCount = total;
        summaryDetails.push(element);
      });
    }
   
    return summaryDetails;
   }
   mapShiftDetails(data: any): AgencyShiftDetails[] {
    let shiftDetails: AgencyShiftDetails[] =[];
    data.forEach(element => {
      let details:AgencyShiftDetails= new AgencyShiftDetails();
      details.partnerId= element.partnerId ;
      details.state= element.state;
      details.empId= element.employeeId;
      details.shiftDate= element.shiftDate;     
      details.stateType= element.stateType;
      if(details.state== 'Assigned' || details.state== "Cancelled") {
        details.employeeName= element.employeeName;
      }
      else {
        details.employeeName= details.stateType;
      }
      details.shiftType= element.stateType;
      details.shift = element.shift;
      details.hours = element.hours;
      details.position= element.position;
      details.unit= element.unit;
      details.attributes = element.attributes;
      details.workedHours= element.workedHours;
      details.mealBreakDuration= element.mealBreakDuration;
      if(details.state== "Cancelled"){
      details.cancellationDate= element.updatedDate; 
      details.cancellationType= element.stateSubType
      if(details.cancellationType== "FACILITY_CANCEL") {
        details.cancellationType="Facility "
      }
      if(details.cancellationType== "CANCEL_NOSHOW") {
        details.cancellationType=" No Show";
      }
      if(details.cancellationType== "CANCEL_CALLOUT") {
        details.cancellationType=" Called Out "
      }
      } 

      shiftDetails.push(details);
    });
   return shiftDetails;
  }
}
