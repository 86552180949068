import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceRuleOption, WeekDay, OffsetPosition, RecurrenceFrequencies, LMCommonRecurrence } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-monthly-recurrence',
  templateUrl: 'lm-monthly-recurrence.component.html',
  styleUrls: ['lm-monthly-recurrence.component.scss']
})
export class LMMonthlyRecurrenceComponent extends LMCommonRecurrence implements OnInit {
  public repeatMode: string;
  public monthDay: number = 1;
  public offsetPositions: OffsetPosition[];
  public offsetPosition: OffsetPosition;
  public weekDays: WeekDay[];
  public weekDay: WeekDay;

  public limitMinDate: Date;
  public calculatedMinDate: Date;

  public readonly repeatOnDay = 'monthday';
  public readonly repeatOnCustom = 'weekday';

  public get monthDayIsReadonly(): boolean {
    return this.repeatMode !== this.repeatOnDay;
  }

  public get weekDayIsReadonly(): boolean {
    return this.repeatMode !== this.repeatOnCustom;
  }

  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  private monthLength = 31;

  constructor(private manService: LMCreationAbsenceManagementService) {
    super();
  }

  public ngOnInit(): void {
    const startDate = this.defineStartDate();
    const recurrence = this.manService.getRecurrence();

    this.defineDefaults(recurrence, startDate);
    this.defineMode(recurrence);
    this.updatesRestrictionDates();

    this.subscriptions.loaRequest = this.manService
      .subscribeToChangedRequest(() => {
        this.defineStartDate();
        this.updatesRestrictionDates();
      });
  }

  public onChangeRecurrence(): void {
    const options = new RecurrenceRuleOption(this.interval, null, null);

    this.updatesRestrictionDates();
    this.updatedRepeatMode(options);
    this.updateEndMode(options);

    this.manService.setRecurrence(RecurrenceFrequencies.monthly, options);
  }

  public updatedRepeatMode(options: RecurrenceRuleOption): void {
    switch(this.repeatMode) {
      case this.repeatOnDay:
        options.byMonthDay = this.monthDay;
        break;
      case this.repeatOnCustom:
        options.byWeekDay = [{
          day: this.weekDay.id,
          offset: this.offsetPosition.id
        }];
        break;
    }
  }

  private updateEndMode(options: RecurrenceRuleOption): void {
    switch(this.endMode) {
      case this.endAfterOccurrence:
        options.count = this.count;
        break;
      case this.endOnDate:
        options.until = this.until;
        break;
    }
  }

  private updatesRestrictionDates(): void {
    const minRequiredDays = this.interval * this.monthLength;
    const copyOfMinDate = this.copyDate(this.limitMinDate);
    this.calculatedMinDate = new Date(copyOfMinDate.setDate(copyOfMinDate.getDate() + minRequiredDays));
    if (!_.isDate(this.until) || this.until < this.calculatedMinDate) {
      this.until = this.copyDate(this.calculatedMinDate);
    }
  }

  private defineDefaults(recurrence: RecurrenceRuleOption, startDate: Date): void {
    this.interval = recurrence.interval || 1;
    this.count = recurrence.count || 1;
    this.until = recurrence.until || startDate;
    this.monthDay = recurrence.byMonthDay || 1;

    this.weekDays = this.manService.weekDays;
    this.offsetPositions = this.manService.offsetPositions;
    this.weekDay = _.head(this.weekDays);
    this.offsetPosition = _.head(this.offsetPositions);

    if (_.isArray(recurrence.byWeekDay) && _.size(recurrence.byWeekDay) > 0) {
      const day = _.head(recurrence.byWeekDay);
      const d = _.get(day, 'day');
      const o = _.get(day, 'offset');
      this.weekDay = _.find(this.weekDays, (w) => w.id === d);
      this.offsetPosition = _.find(this.offsetPositions, (p) => p.id === o);
    }
  }

  private defineMode(recurrence: RecurrenceRuleOption): void {
    if (_.isFinite(recurrence.count) && recurrence.count > 0) {
      this.endMode = this.endAfterOccurrence;
    } else if (_.isDate(recurrence.until)) {
      this.endMode = this.endOnDate;
    } else {
      this.endMode = this.endNever;
    }

    if (_.isFinite(recurrence.byMonthDay)) {
      this.repeatMode = this.repeatOnDay;
    } else if (_.isArray(recurrence.byWeekDay) && _.size(recurrence.byWeekDay) > 0) {
      this.repeatMode = this.repeatOnCustom;
    } else {
      this.repeatMode = this.repeatOnDay;
    }
  }

  private defineStartDate(): Date {
    const { startDate } = this.manService.getLoaDates();
    this.limitMinDate = startDate || new Date();

    return this.limitMinDate;
  }
}
