import { AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { ReportParameter } from '../../../models/index';
import { ReportsManagementService } from '../../../services/index';
var ReportBooleanParameterComponent = /** @class */ (function () {
    function ReportBooleanParameterComponent(reportManServce) {
        this.reportManServce = reportManServce;
        this.includeThousandsSeparator = null;
    }
    Object.defineProperty(ReportBooleanParameterComponent.prototype, "parameter", {
        get: function () {
            return this.m_parameter;
        },
        set: function (value) {
            this.m_parameter = value;
        },
        enumerable: true,
        configurable: true
    });
    ReportBooleanParameterComponent.prototype.ngOnInit = function () {
        if (this.parameter.name == "Show_dates" || this.parameter.name == "Zero_hrs") {
            this.parameter.defaultValue = false;
            this.parameter.value = 0;
        }
    };
    ReportBooleanParameterComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        Promise.resolve(null).then(function () { return _this.processParameter(_this.m_parameter); });
    };
    ReportBooleanParameterComponent.prototype.processParameter = function (parameter) {
        if (!(_.isNull(parameter.defaultValue) || _.isUndefined(parameter.defaultValue))) {
            parameter.value = parameter.defaultValue;
        }
        else {
            if (_.isNull(parameter.value) || _.isUndefined(parameter.value)) {
                parameter.value = false;
            }
        }
    };
    ReportBooleanParameterComponent.prototype.getChecked = function (event) {
        if (this.parameter.name == 'Show_dates') {
            this.parameter.defaultValue = event;
            this.parameter.value = event == true ? 1 : 0;
        }
        if (this.parameter.name == 'Zero_hrs') {
            this.parameter.defaultValue = event;
            this.parameter.value = event == true ? 1 : 0;
        }
    };
    return ReportBooleanParameterComponent;
}());
export { ReportBooleanParameterComponent };
