/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./send-shift-sms-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../send-shift-sms/send-shift-sms.component.ngfactory";
import * as i3 from "../send-shift-sms/send-shift-sms.component";
import * as i4 from "../../../services/employee-messages/employee-messages-api.service";
import * as i5 from "./send-shift-sms-dialog.component";
import * as i6 from "../../../models/send-shift-sms-request";
import * as i7 from "../../../../../common/models/dialog-options";
import * as i8 from "../../../../../common/services/modal/modal.service";
var styles_SendShiftSmsDialogComponent = [i0.styles];
var RenderType_SendShiftSmsDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SendShiftSmsDialogComponent, data: {} });
export { RenderType_SendShiftSmsDialogComponent as RenderType_SendShiftSmsDialogComponent };
export function View_SendShiftSmsDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-send-shift-sms", [], null, [[null, "sendCanceled"], [null, "messageSent"], [null, "errorOccured"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("sendCanceled" === en)) {
        var pd_0 = (_co.onSendCanceled() !== false);
        ad = (pd_0 && ad);
    } if (("messageSent" === en)) {
        var pd_1 = (_co.onMessageSent($event) !== false);
        ad = (pd_1 && ad);
    } if (("errorOccured" === en)) {
        var pd_2 = (_co.onErrorOccured() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_SendShiftSmsComponent_0, i2.RenderType_SendShiftSmsComponent)), i1.ɵdid(2, 49152, null, 0, i3.SendShiftSmsComponent, [i4.EmployeeMessagesApiService], { sendSmsRequest: [0, "sendSmsRequest"] }, { sendCanceled: "sendCanceled", messageSent: "messageSent", errorOccured: "errorOccured" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.request; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SendShiftSmsDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-send-shift-sms-dialog", [], null, null, null, View_SendShiftSmsDialogComponent_0, RenderType_SendShiftSmsDialogComponent)), i1.ɵdid(1, 49152, null, 0, i5.SendShiftSmsDialogComponent, [i6.SendShiftSmsRequest, i7.DialogOptions, i8.ModalService], null, null)], null, null); }
var SendShiftSmsDialogComponentNgFactory = i1.ɵccf("slx-send-shift-sms-dialog", i5.SendShiftSmsDialogComponent, View_SendShiftSmsDialogComponent_Host_0, {}, {}, []);
export { SendShiftSmsDialogComponentNgFactory as SendShiftSmsDialogComponentNgFactory };
