import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ManagementBaseService } from '../../../core/services/index';
import { IPunchAttestationPeriod } from '../models';
import { IPunchProfileGroup } from '../models/punch-profile-group';
import { PunchProfileApiService } from './punch-profile-api.service';

@Injectable()
export class PunchProfileManagementService extends ManagementBaseService<IPunchProfileGroup[], any> {

  public punchProfileGroup: IPunchProfileGroup[] = [];
  groups$ = new BehaviorSubject<IPunchAttestationPeriod[]>([]);

  constructor(
    private punchProfileApiService: PunchProfileApiService
  ) {
    super();
  }

  public getGroupsAndProfiles(orglevelid: number){
    this.onLoadStatusChanged(true);
    Promise.all([this.punchProfileApiService.getAllGroups(), this.punchProfileApiService.getAllPunchProfiles()]).then(data=>{
      this.groups$.next(data[0]);
      this.onLoaded(data[1]);
      this.punchProfileGroup = data[1];
      this.onLoadStatusChanged(false);
    }).catch(err => {
      this.onError(err);
      this.onLoadStatusChanged(false);
    });
  }

  public addOrUpdatePunchProfile(orglevelid: number, punchProfile: IPunchProfileGroup) {
    this.onLoadStatusChanged(true);
    this.punchProfileApiService.addOrUpdatePunchProfile(orglevelid, punchProfile)
      .then((punchProfileGroup: IPunchProfileGroup[]) => {
        this.punchProfileGroup = punchProfileGroup;
        this.onLoaded(this.punchProfileGroup);
        this.onLoadStatusChanged(false);
      })
      .catch(err => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }
}
