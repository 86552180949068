/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lm-schedule-line.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lm-schedule-line.component";
var styles_LMScheduleLineComponent = [i0.styles];
var RenderType_LMScheduleLineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LMScheduleLineComponent, data: {} });
export { RenderType_LMScheduleLineComponent as RenderType_LMScheduleLineComponent };
function View_LMScheduleLineComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "day__line"]], [[4, "left", null], [4, "right", null], [4, "backgroundColor", null]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_3 = "day__line"; var currVal_4 = _v.context.$implicit.cssClass; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.left; var currVal_1 = _v.context.$implicit.right; var currVal_2 = ((_co.loaRequest == null) ? null : ((_co.loaRequest.loaRequest == null) ? null : _co.loaRequest.loaRequest.typeColor)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_LMScheduleLineComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "day"]], [[2, "cursor-pointer", null], [4, "borderRightWidth", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onDayClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMScheduleLineComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isClickable(_v.context.$implicit); var currVal_1 = _co.getBorder(_v.context.$implicit); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_LMScheduleLineComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMScheduleLineComponent_1)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.weekDays; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LMScheduleLineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-lm-schedule-line", [], null, null, null, View_LMScheduleLineComponent_0, RenderType_LMScheduleLineComponent)), i1.ɵdid(1, 704512, null, 0, i3.LMScheduleLineComponent, [], null, null)], null, null); }
var LMScheduleLineComponentNgFactory = i1.ɵccf("slx-lm-schedule-line", i3.LMScheduleLineComponent, View_LMScheduleLineComponent_Host_0, { loaRequest: "loaRequest", dateRange: "dateRange", daysLength: "daysLength", disableToClickOnDay: "disableToClickOnDay" }, { clickOnDay: "clickOnDay" }, []);
export { LMScheduleLineComponentNgFactory as LMScheduleLineComponentNgFactory };
