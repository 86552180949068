import * as _ from 'lodash';
var IdealScheduleFixedGridComponent = /** @class */ (function () {
    function IdealScheduleFixedGridComponent() {
    }
    Object.defineProperty(IdealScheduleFixedGridComponent.prototype, "fixedRange", {
        get: function () {
            return _.first(this.ranges);
        },
        enumerable: true,
        configurable: true
    });
    return IdealScheduleFixedGridComponent;
}());
export { IdealScheduleFixedGridComponent };
