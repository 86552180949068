import * as _ from 'lodash';
import { ShiftSwapType } from './../../../models/shift-swap/shift-swap-type';
import { ShiftSwapStatus } from './../../../models/shift-swap/shift-swap-status';
import { ShiftSwapManageService } from './../../../services/shift-swap/shift-swap-manage.service';
import { IRangeDates } from './../../../../common/models/range-dates';
import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-shift-swap-header',
    templateUrl: 'shift-swap-header.component.html',
    styleUrls: ['shift-swap-header.component.scss']
  })
  export class ShiftSwapHeaderComponent implements OnInit {
    public statusList: ShiftSwapStatus[];
    public typeList: ShiftSwapType[];
    public type: ShiftSwapType;
    public status: ShiftSwapStatus;
    public startDate: Date;
    public endDate: Date;

    constructor(public manageService: ShiftSwapManageService) {}

    public ngOnInit(): void {
      this.statusList = this.manageService.statusList;
      this.typeList = this.manageService.typeList;
      this.type = this.manageService.type;
      this.status = this.manageService.status;
      this.startDate = this.manageService.startDate;
      this.endDate = this.manageService.endDate;
    }

    public onDatesRangeChanged(event: IRangeDates): void {
      this.manageService.changeDateRange(event.startDate, event.endDate);
    }

    public onStatusChanged(status: ShiftSwapStatus): void {
      this.manageService.changeStatus(status);
    }

    public onTypeChanged(type: ShiftSwapType): void {
      this.manageService.changeType(type);
    }
  }
