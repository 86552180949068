var RecipientInfo = /** @class */ (function () {
    function RecipientInfo(recipientId, name, phoneNumber, recipientType) {
        this.name = name;
        this.phoneNumber = phoneNumber,
            this.recipientId = recipientId;
        this.recipientType = recipientType;
    }
    Object.defineProperty(RecipientInfo.prototype, "displayName", {
        get: function () {
            return this.name + " (" + this.phoneNumber + ")";
        },
        enumerable: true,
        configurable: true
    });
    return RecipientInfo;
}());
export { RecipientInfo };
export var RecipientType;
(function (RecipientType) {
    RecipientType[RecipientType["user"] = 0] = "user";
    RecipientType[RecipientType["employee"] = 1] = "employee";
})(RecipientType || (RecipientType = {}));
