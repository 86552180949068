import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { OrgLevel } from '../../../../state-model/models/index';
import { BenefitDetailsConfig, BenefitDetailsGroup, BenefitDetailsProvider, BenefitDetailsLineStandart, BenefitGroupLoader, BenefitLineInfo } from '../../models/index';
import { BenefitListApiService } from './benefit-list-api.service';
import { BenefitConsoleDetailsCommonService } from './benefit-console-details-common.service';
var BenefitListManagementService = /** @class */ (function () {
    function BenefitListManagementService(apiService, commonService) {
        this.apiService = apiService;
        this.commonService = commonService;
        this.providers = [];
        this.loadExpriredPlans = false;
        this.recordsLoaded$ = new ReplaySubject(1);
        this.planHeadersLoaded$ = new ReplaySubject(1);
        this.planTypesLoaded$ = new ReplaySubject(null);
        this.loading$ = new Subject();
        this.isLoadingExpired$ = new ReplaySubject(1);
        this.planTypesLoading$ = new Subject();
        this.planGroupsLoading$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.configChanged$ = new ReplaySubject(1);
        this.loadProviders$ = new ReplaySubject(1);
        this.changeGroup$ = new ReplaySubject(1);
        this.selectProvider$ = new ReplaySubject(1);
        this.selectProviderLine$ = new ReplaySubject(1);
        this.subscriptions = {};
        this.init();
    }
    BenefitListManagementService.prototype.init = function () {
        var _this = this;
        this.subscribeToOrgLevelChanges();
        this.subscriptions.restoreShowExpired = this.commonService.subscribeToShowExpiredPlansState(function (isShow) {
            _this.changeShowingExpiredPlans(isShow);
        });
    };
    BenefitListManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.orgLevelChanged$.complete();
        this.configChanged$.complete();
        this.loadProviders$.complete();
        this.changeGroup$.complete();
        this.selectProvider$.complete();
        this.selectProviderLine$.complete();
        this.recordsLoaded$.complete();
        this.planTypesLoading$.complete();
        this.planTypesLoaded$.complete();
        this.planHeadersLoaded$.complete();
        this.isLoadingExpired$.complete();
    };
    Object.defineProperty(BenefitListManagementService.prototype, "isLoadingExpiredPlans", {
        get: function () {
            return this.loadExpriredPlans;
        },
        enumerable: true,
        configurable: true
    });
    BenefitListManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    BenefitListManagementService.prototype.subscribeToIsLoadingExpired = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.isLoadingExpired$.subscribe(callback);
    };
    BenefitListManagementService.prototype.subscribeToPlanTypeLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.planTypesLoading$.subscribe(callback);
    };
    BenefitListManagementService.prototype.subscribeToPlanGroupsLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.planGroupsLoading$.subscribe(callback);
    };
    BenefitListManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    BenefitListManagementService.prototype.subscribeToPlanTypeRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.planTypesLoaded$.subscribe(callback);
    };
    BenefitListManagementService.prototype.subscribeToPlanHeaders = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.planHeadersLoaded$.subscribe(callback);
    };
    BenefitListManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    BenefitListManagementService.prototype.changeShowingExpiredPlans = function (isShow) {
        this.loadExpriredPlans = isShow;
        this.isLoadingExpired$.next(isShow);
        this.commonService.saveIsShowingExpiredPlansState(isShow);
    };
    BenefitListManagementService.prototype.subscribeToChangeConfig = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.configChanged$.subscribe(callback);
    };
    BenefitListManagementService.prototype.setLoadingStatus = function (isLoading) {
        this.loading$.next(isLoading);
    };
    BenefitListManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return _.isFinite(_.get(o, 'id')); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
        });
    };
    BenefitListManagementService.prototype.getBenefitList = function () {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getBenefitList(this.orgLevel.id, this.isLoadingExpiredPlans)
            .then(function (container) {
            _this.recordsLoaded$.next(container);
        }).catch(function () {
            _this.recordsLoaded$.next([]);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    BenefitListManagementService.prototype.getBenefitPlanHeaders = function () {
        var _this = this;
        this.planGroupsLoading$.next(true);
        this.apiService.getBenefitPlanHeaders(this.orgLevel.id)
            .then(function (container) {
            _this.planHeadersLoaded$.next(container);
        }).finally(function () {
            _this.planGroupsLoading$.next(false);
        });
    };
    BenefitListManagementService.prototype.getBenefitPlanTypes = function (groupId, showExpired) {
        var _this = this;
        this.planTypesLoading$.next({ groupId: groupId, isLoading: true });
        this.apiService.getBenefitPlanType(this.orgLevel.id, groupId, showExpired)
            .then(function (container) {
            _this.planTypesLoaded$.next(container);
        }).finally(function () {
            _this.planTypesLoading$.next({ groupId: groupId, isLoading: false });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], BenefitListManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], BenefitListManagementService.prototype, "subscriptions", void 0);
    return BenefitListManagementService;
}());
export { BenefitListManagementService };
