/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./labeled-value.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./labeled-value.component";
var styles_LabeledValueComponent = [i0.styles];
var RenderType_LabeledValueComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabeledValueComponent, data: {} });
export { RenderType_LabeledValueComponent as RenderType_LabeledValueComponent };
export function View_LabeledValueComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "labeled-value-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "value"]], [[4, "color", null]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "value-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.color; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.value, "1.0-2")); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.label; _ck(_v, 6, 0, currVal_2); }); }
export function View_LabeledValueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-labeled-value", [], null, null, null, View_LabeledValueComponent_0, RenderType_LabeledValueComponent)), i1.ɵdid(1, 49152, null, 0, i3.LabeledValueComponent, [], null, null)], null, null); }
var LabeledValueComponentNgFactory = i1.ɵccf("slx-labeled-value", i3.LabeledValueComponent, View_LabeledValueComponent_Host_0, { label: "label", value: "value", color: "color" }, { onClick: "onClick" }, []);
export { LabeledValueComponentNgFactory as LabeledValueComponentNgFactory };
