import { Component, ViewChild, ElementRef } from '@angular/core';
import { IHeaderGroupAngularComp } from 'ag-grid-angular';
import { IHeaderGroupParams } from 'ag-grid-community';

/*
Seems it is unaviliable to use separated template and css files with ag-grid custom components.
*/
@Component({
  selector: 'slx-master-schedule-custom-gh',
  template: `
  <div class="custom-group-header">
    <div class="custom-group-header-holder">
      <span class="main-header-text">{{params.displayName}}</span><span class="additional-info-text" *ngIf="!!additionalInfo">{{additionalInfo}}</span>
    <div>
  </div>`,
  styles: [`
  .main-header-text {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    border-right: initial;
    border-bottom: initial;
    color: #15A5BD;
    position: static;
    margin-left: 5px;
    font-size: 16px;
  }
  .additional-info-text {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    border-right: initial;
    border-bottom: initial;
    font-weight: normal;
    color: #2F2F2F;
    position: static;
    margin-left: 5px;
    font-size: 14px;
   }
  .custom-group-header {
    height: 100%;
    position: relative;
  }
  .custom-group-header-holder {
    position: absolute;
    bottom: 5px;
  }
  `]
})
export class MasterScheduleCustomGroupHeader implements IHeaderGroupAngularComp {
  public additionalInfo: string;
  public params: any;
  public agInit(params: IHeaderGroupParams ): void {
    this.params = params;
    this.additionalInfo = this.params.additionalInfo;
  }
}
