/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-punch-request.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-punch-request-toolbar/employee-punch-request-toolbar.component.ngfactory";
import * as i3 from "../employee-punch-request-toolbar/employee-punch-request-toolbar.component";
import * as i4 from "../../../services/punches/employee-punch-request-management.service";
import * as i5 from "../../../../organization/services/lookup/lookup-api.service";
import * as i6 from "../../../../common/services/component-state/component-state-storage.service";
import * as i7 from "../../../../common/services/state-management/state-management.service";
import * as i8 from "../employee-punch-request-grid/employee-punch-request-grid.component.ngfactory";
import * as i9 from "../employee-punch-request-grid/employee-punch-request-grid.component";
import * as i10 from "../../../../common/services/column-settings/column-management.service";
import * as i11 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i12 from "../../../services/punches/employee-punch-request-api.service";
import * as i13 from "./employee-punch-request.component";
var styles_EmployeePunchRequestComponent = [i0.styles];
var RenderType_EmployeePunchRequestComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeePunchRequestComponent, data: {} });
export { RenderType_EmployeePunchRequestComponent as RenderType_EmployeePunchRequestComponent };
export function View_EmployeePunchRequestComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "slx-employee-punch-request-toolbar", [], null, null, null, i2.View_EmployeePunchRequestToolbarComponent_0, i2.RenderType_EmployeePunchRequestToolbarComponent)), i1.ɵdid(3, 245760, null, 0, i3.EmployeePunchRequestToolbarComponent, [i4.EmployeePunchRequestManagementService, i5.LookupApiService, i6.ComponentStateStorageService, i7.StateManagementService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-employee-punch-request-grid", [], null, null, null, i8.View_EmployeePunchRequestGridComponent_0, i8.RenderType_EmployeePunchRequestGridComponent)), i1.ɵdid(6, 245760, null, 0, i9.EmployeePunchRequestGridComponent, [i4.EmployeePunchRequestManagementService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_EmployeePunchRequestComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-employee-punch-request", [], null, null, null, View_EmployeePunchRequestComponent_0, RenderType_EmployeePunchRequestComponent)), i1.ɵprd(4608, null, i10.ColumnManagementService, i10.ColumnManagementService, [i11.ColumnSettingsStorageService]), i1.ɵprd(4608, null, i7.StateManagementService, i7.StateManagementService, [i6.ComponentStateStorageService, i11.ColumnSettingsStorageService]), i1.ɵprd(512, null, i4.EmployeePunchRequestManagementService, i4.EmployeePunchRequestManagementService, [i12.EmployeePunchRequestApiService]), i1.ɵdid(4, 245760, null, 0, i13.EmployeePunchRequestComponent, [i4.EmployeePunchRequestManagementService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var EmployeePunchRequestComponentNgFactory = i1.ɵccf("slx-employee-punch-request", i13.EmployeePunchRequestComponent, View_EmployeePunchRequestComponent_Host_0, {}, {}, []);
export { EmployeePunchRequestComponentNgFactory as EmployeePunchRequestComponentNgFactory };
