import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SupervisorGroup, Supervisor, SupervisedEmployee } from '../../../models/index';
import { SupervisorAssignmentManagementService } from '../../../services/supervisor-assignment/supervisor-assignment-management.service';
import { unsubscribe } from '../../../../core/decorators/index';
var SupervisorGroupDetailComponent = /** @class */ (function () {
    function SupervisorGroupDetailComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
    }
    SupervisorGroupDetailComponent.prototype.ngOnChanges = function (changes) {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGroupDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.deletedSupervisorCmdSubscription = this.managementService.deletedSupervisorsCmd$.subscribe(function (result) {
            if (_this.group.id !== result.group.id) {
                return;
            }
            _this.selectedSupervisors = null;
            result.group.supervisors = result.supervisors;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.addedSupervisorCmdSubscription = this.managementService.addedSupervisorsCmd$.subscribe(function (result) {
            if (_this.group.id !== result.group.id) {
                return;
            }
            _this.selectedSupervisors = null;
            result.group.supervisors = result.supervisors;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.deletedSupervisedEmployeeCmdSubscription = this.managementService.deletedSupervisedEmployeesCmd$.subscribe(function (result) {
            if (_this.group.id !== result.group.id) {
                return;
            }
            _this.selectedEmployees = null;
            result.group.employees = result.employees;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.addedSupervisedEmployeeCmdSubscription = this.managementService.addedSupervisedEmployeesCmd$.subscribe(function (result) {
            if (_this.group.id !== result.group.id) {
                return;
            }
            _this.selectedEmployees = null;
            result.group.employees = result.employees;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    SupervisorGroupDetailComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    SupervisorGroupDetailComponent.prototype.supervisorsSelectionChanged = function (supervisors) {
        this.selectedSupervisors = supervisors;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGroupDetailComponent.prototype.employeeSelectionChanged = function (employees) {
        this.selectedEmployees = employees;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    SupervisorGroupDetailComponent.prototype.onAddSupervisors = function () {
        this.managementService.startAddSupervisorCmd(this.group);
    };
    SupervisorGroupDetailComponent.prototype.onRemoveSupervisors = function () {
        this.managementService.deleteSupervisorsFromGroupCmd(this.group, this.selectedSupervisors);
    };
    SupervisorGroupDetailComponent.prototype.saveSuprvisor = function (supervisor) {
        this.managementService.updateSupervisor(supervisor);
    };
    SupervisorGroupDetailComponent.prototype.onAddEmployees = function () {
        this.managementService.startAddEmployeeCmd(this.group);
    };
    SupervisorGroupDetailComponent.prototype.onRemoveEmployees = function () {
        this.managementService.deleteSupervisedEmployeesFromGroupCmd(this.group, this.selectedEmployees);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupDetailComponent.prototype, "deletedSupervisorCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupDetailComponent.prototype, "addedSupervisorCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupDetailComponent.prototype, "deletedSupervisedEmployeeCmdSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], SupervisorGroupDetailComponent.prototype, "addedSupervisedEmployeeCmdSubscription", void 0);
    return SupervisorGroupDetailComponent;
}());
export { SupervisorGroupDetailComponent };
