import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { LookupEntity } from './lookup-entity';
import { appConfig } from '../../../app.config';
var dateFormat = 'DD/MM/YY';
var Position = /** @class */ (function (_super) {
    tslib_1.__extends(Position, _super);
    function Position() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Position.prototype, "nameWithDate", {
        get: function () {
            var startDate = moment(this.startDate).format(appConfig.dateFormatShortYear);
            var endDate = moment(this.endDate);
            return this.name + " (" + startDate + " - " + (endDate.isValid() ? endDate.format(appConfig.dateFormatShortYear) : 'current') + ")";
        },
        enumerable: true,
        configurable: true
    });
    return Position;
}(LookupEntity));
export { Position };
