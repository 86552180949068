import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { PostScheduleNavigationService } from '../../services/index';

@Directive({
  /* tslint:disable */
  selector: '[postScheduleLink]',
  /* tslint:enable */
})
export class PostScheduleLinkDirective {
  @Input()
  public postScheduleLink: Date;
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: PostScheduleNavigationService = new PostScheduleNavigationService(this.router, this.route);
    navService.navigateToOpenShiftManagement(this.postScheduleLink);
  }
}
