import { Injectable } from '@angular/core';
import { UserSettingsService } from '../../../core/services/index';
import { ModalSettings, UserSettings } from '../../../core/models/index';
import { Assert } from '../../../framework/index';

@Injectable()
export class ModalStorageService {
  private userSettingsService: UserSettingsService;

  constructor(userSettingsService: UserSettingsService) {
    Assert.isNotNull(userSettingsService, 'userSettingsService');
    this.userSettingsService = userSettingsService;
  }

  public isExist(type: string): boolean {
    Assert.isNotNull(type, 'type');
    let settings: UserSettings = this.userSettingsService.get();
    if (settings && settings.modalSettings.hasOwnProperty(type)) {
      return true;
    }
    return false;
  }

  public getModalSettings(type: string): ModalSettings {
    Assert.isNotNull(type, 'type');
    let settings: UserSettings = this.userSettingsService.get();
    Assert.isOwnProperty(settings.modalSettings, type, 'modalSettings unknown type');
    return settings.modalSettings[type];
  }

  public setModalSettings(type: string, msettings: ModalSettings): void {
    Assert.isNotNull(type, 'type');
    let settings: UserSettings = this.userSettingsService.get();
    settings.modalSettings[type] = msettings;
    this.userSettingsService.set(settings);
  }
}
