import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';
import { ArrivalsDeparturesApiService } from './arrivals-departures-api.service';
import { StateManagementService } from '../../../common/index';
import { ArrivalsDeparturesDetails, ArrivalsDeparturesDetailsRecord, ArrivalDeparturesFilter, ArrivalDeparturesTimelineFilter, ArrivalDeparturesVariances, ArrivalDeparturesContainer, ArrivalsDeparturesTimelineViewModeDefinition } from '../../models/index';
var ArrivalsDeparturesTimelineDetailsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(ArrivalsDeparturesTimelineDetailsManagementService, _super);
    function ArrivalsDeparturesTimelineDetailsManagementService(arrivalsDeparturesApiService, stateManagement) {
        var _this = _super.call(this) || this;
        _this.arrivalsDeparturesApiService = arrivalsDeparturesApiService;
        _this.stateManagement = stateManagement;
        _this.onFilterChanged$ = new ReplaySubject(1);
        _this.onToogleTimelineView$ = new Subject();
        _this.onToogleShowOverragesView$ = new Subject();
        _this.onArrivalDeparturesLoaded$ = new Subject();
        var initSubscription = _this.stateManagement.onInit$.subscribe(function () {
            _this.restoreFilter();
            initSubscription.unsubscribe();
        });
        return _this;
    }
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.ArrivalDeparturesLoaded = function (container) {
        this.onArrivalDeparturesLoaded$.next(container);
    };
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.loadData = function (orgLevelId, workDate, timeOn) {
        var _this = this;
        this.workDate = workDate;
        this.onLoadStatusChanged(true);
        this.arrivalsDeparturesApiService.getArrivalsDeparturesDetails(orgLevelId, workDate, timeOn)
            .then(function (value) {
            _this.data = value;
            _this.filterRecords(_this.filter, value);
            _this.onLoadStatusChanged(false);
        });
        /*.
        catch((reason: any) => {
          this.onError(reason);
        });*/
    };
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.onArrivalDeparturesFilterChangedSelected = function (filter) {
        this.filter.arrivalsRange = filter.arrivalsRange;
        this.filter.departuresRange = filter.departuresRange;
        this.onFilterChanged(this.filter);
    };
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.toggleTimeline = function (mode) {
        this.onToogleTimelineView$.next(mode);
    };
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.toggleShowOverrages = function (isShow) {
        this.onToogleShowOverragesView$.next(isShow);
    };
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.onFilterChanged = function (filter) {
        this.filter = filter;
        this.filterRecords(filter, this.data);
        this.onFilterChanged$.next(filter);
    };
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.filterRecords = function (filter, value) {
        if (!value) {
            return;
        }
        value.filteredRecords = _.filter(value.records, function (record) {
            var res = true;
            if (filter.arrivalsRange) {
                res = res && ((!!record.firstShiftStart && record.firstShiftStart.isSameOrBefore(filter.arrivalsRange.endDate)) ||
                    (!!record.inTime && record.inTime.isSameOrBefore(filter.arrivalsRange.endDate)));
            }
            if (filter.departuresRange) {
                res = res && ((!!record.lastShiftEnd && record.lastShiftEnd.isSameOrAfter(filter.departuresRange.startDate)) ||
                    (!!record.outTime && record.outTime.isSameOrAfter(filter.departuresRange.startDate)));
            }
            if (filter.units && filter.units.length > 0) {
                var uRes_1 = false;
                _.forEach(filter.units, function (unit) {
                    if (_.includes(record.unitsIds, unit.id)) {
                        uRes_1 = true;
                        return false;
                    }
                    return true;
                });
                res = res && uRes_1;
            }
            if (filter.variances && filter.variances.length) {
                if (_.includes(filter.variances, ArrivalDeparturesVariances.late)) {
                    res = res && record.isLate;
                }
                if (_.includes(filter.variances, ArrivalDeparturesVariances.arrivals)) {
                    res = res && record.isArrival;
                }
                if (_.includes(filter.variances, ArrivalDeparturesVariances.departures)) {
                    res = res && record.isDeparture;
                }
                if (_.includes(filter.variances, ArrivalDeparturesVariances.approachingOT)) {
                    res = res && record.isApproachingOT;
                }
            }
            if (filter.empFilter && filter.empFilter.length > 0) {
                var uRes_2 = false;
                _.forEach(filter.empFilter, function (emp) {
                    if (record.employee.id === emp.id) {
                        uRes_2 = true;
                        return false;
                    }
                    return true;
                });
                res = res && uRes_2;
            }
            return res;
        });
        this.onLoaded(value);
    };
    ArrivalsDeparturesTimelineDetailsManagementService.prototype.restoreFilter = function () {
        var filter = new ArrivalDeparturesTimelineFilter();
        var state = null;
        state = this.stateManagement.getControlState('units');
        filter.units = state.value;
        state = this.stateManagement.getControlState('variances');
        filter.variances = _.map(state.value, function (v) { return v.value; });
        state = this.stateManagement.getControlState('isShowOverages');
        this.toggleShowOverrages(!!state.value);
        state = this.stateManagement.getControlState('empFilter');
        filter.empFilter = state.value;
        this.onFilterChanged(filter);
    };
    return ArrivalsDeparturesTimelineDetailsManagementService;
}(ManagementBaseService));
export { ArrivalsDeparturesTimelineDetailsManagementService };
