<slx-spinner novalidate>
  <div class="headless-modal-content">

    <div class="modal-header">
      <div class="txt-pad10">
        <span class="pull-left">Add Employee 1095C</span>
        <span class="pull-right btn-close" (click)="onCancel()"><span><i class="fa fa-times"
              aria-hidden="true"></i></span></span>
      </div>
    </div>

    <div class="modal-body">
      <h5 class="search-employee__h5">To add an existing employee to the 1095C reporting screen, find them in the list
        below. Use the employee's name to search and select the employee.<strong>Employees already on this screen will be
        grayed out.</strong></h5>
      <div class="search-employee__list search-flex align-items__center">
        <label class="search-employee__label">Employee Name:</label>
        <span class="flex__grow">
          <slx-autocomplete class="slx-wide" [itemDisabled]="itemDisabled" [lookup]="employeesLookup"
            [(ngModel)]="employee" (ngModelChange)="onEmployeeChanged($event)" [strictSearch]="false"
            rightIcon="fas fa-search" name="employee" [virtual]="virtual">
            <ng-template slxAutocompleteItem let-item="item">
              <p class="emp-item flex align-items__center">
                <img class="emp-item__icon" [employeeThumbinalSrc]="item.id" alt="Employee Photo" />
                <span class="emp-item__info flex__grow">
                  <strong class="emp-item__name">{{ item.name }}</strong>
                  <strong class="emp-item__field">Position:&nbsp;</strong>
                  <span class="emp-item__value">{{ item.position?.name }}</span>
                  <strong class="emp-item__field">Department:&nbsp;</strong>
                  <span class="emp-item__value">{{ item.department?.name }}</span>
                  <strong class="emp-item__field">Facility:&nbsp;</strong>
                  <span class="emp-item__value">{{ item.organization?.name }}</span>
                </span>
              </p>
            </ng-template>

          </slx-autocomplete>
        </span>
      </div>
      <div *ngIf="hasEmployee" class="mt20">
        <p class="emp-item flex align-items__center">
          <img class="emp-item__icon" [employeeThumbinalSrc]="employee.id" alt="Employee Photo" />
          <span class="emp-item__info flex__grow">
            <strong class="emp-item__name">{{ employee.name }}</strong>
            <strong class="emp-item__field">Position:&nbsp;</strong>
            <span class="emp-item__value">{{ employee.position?.name }}</span>
            <strong class="emp-item__field">Department:&nbsp;</strong>
            <span class="emp-item__value">{{ employee.department?.name }}</span>
            <strong class="emp-item__field">Facility:&nbsp;</strong>
            <span class="emp-item__value">{{ employee.organization?.name }}</span>
          </span>
        </p>
      </div>
    </div>

    <div class="modal-footer center-align">
      <button type="button" (click)="onOk()" class="btn green">Add</button>
      <button type="button" (click)="onCancel()" class="btn red">Cancel</button>
    </div>

  </div>
</slx-spinner>
