/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./columns-config-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-popper";
import * as i4 from "../input-decorator/input-decorator.component.ngfactory";
import * as i5 from "../input-decorator/input-decorator.component";
import * as i6 from "../checkbox-input/checkbox-input.component.ngfactory";
import * as i7 from "../checkbox-input/checkbox-input.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i10 from "./columns-config-button.component";
import * as i11 from "../../services/column-settings/column-settings-storage.service";
import * as i12 from "../../services/column-settings/column-management.service";
import * as i13 from "../../../app-settings/services/app-settings-manage.service";
var styles_ColumnsConfigButtonComponent = [i0.styles];
var RenderType_ColumnsConfigButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ColumnsConfigButtonComponent, data: {} });
export { RenderType_ColumnsConfigButtonComponent as RenderType_ColumnsConfigButtonComponent };
function View_ColumnsConfigButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.secondaryIcon; _ck(_v, 0, 0, currVal_0); }); }
function View_ColumnsConfigButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "button", [["class", "theme-icon-button settings-menu-btn"], ["type", "button"]], null, [[null, "touchstart"], [null, "click"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 4).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i1.ɵnov(_v, 4).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i1.ɵnov(_v, 4).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "hidden-config-button": 0 }), i1.ɵdid(4, 737280, null, 0, i3.PopperController, [i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], positionFixed: [3, "positionFixed"], disableStyle: [4, "disableStyle"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "span", [["class", "fa-layers fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-sliders-h"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnsConfigButtonComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "theme-icon-button settings-menu-btn"; var currVal_1 = _ck(_v, 3, 0, !_co.isInitialized); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 8); var currVal_3 = _co.popperPosition; var currVal_4 = "click"; var currVal_5 = true; var currVal_6 = "true"; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.secondaryIcon; _ck(_v, 8, 0, currVal_7); }, null); }
function View_ColumnsConfigButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 2, "button", [["type", "button"]], [[1, "class", 0]], [[null, "touchstart"], [null, "click"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i1.ɵnov(_v, 1).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i1.ɵnov(_v, 1).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 737280, null, 0, i3.PopperController, [i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], positionFixed: [3, "positionFixed"], disableStyle: [4, "disableStyle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[1, "class", 0]], null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v.parent, 8); var currVal_2 = _co.popperPosition; var currVal_3 = "click"; var currVal_4 = true; var currVal_5 = "true"; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.blueStyleBtnClass; _ck(_v, 0, 0, currVal_0); var currVal_6 = _co.blueStyleIconClass; _ck(_v, 2, 0, currVal_6); }); }
function View_ColumnsConfigButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "slx-input-decorator", [["className", "slx-no-border slx-no-label slx-no-error-block slx-small-font"]], null, null, null, i4.View_InputDecoratorComponent_0, i4.RenderType_InputDecoratorComponent)), i1.ɵdid(2, 311296, null, 4, i5.InputDecoratorComponent, [i1.ElementRef, i1.Renderer2], { className: [0, "className"] }, null), i1.ɵqud(603979776, 1, { fieldChild1: 0 }), i1.ɵqud(603979776, 2, { fieldChild2: 0 }), i1.ɵqud(603979776, 3, { inputPolicyDirective: 0 }), i1.ɵqud(603979776, 4, { errorMessages: 1 }), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "slx-checkbox-input", [["slx-input", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [8, "className", 0]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_v.context.$implicit.displayed = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.onChangeColumn(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_CheckboxInputComponent_0, i6.RenderType_CheckboxInputComponent)), i1.ɵdid(8, 49152, null, 0, i7.CheckboxInputComponent, [i1.ElementRef, i1.ChangeDetectorRef], { fieldName: [0, "fieldName"], caption: [1, "caption"] }, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.CheckboxInputComponent]), i1.ɵdid(10, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(12, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), i1.ɵdid(13, 540672, [[2, 4]], 0, i5.SlxInputAltDirective, [[2, i8.NgControl], i1.ElementRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-no-border slx-no-label slx-no-error-block slx-small-font"; _ck(_v, 2, 0, currVal_0); var currVal_9 = i1.ɵinlineInterpolate(2, "", _co.uniqId, "_col_", _v.context.index, ""); var currVal_10 = _v.context.$implicit.description; _ck(_v, 8, 0, currVal_9, currVal_10); var currVal_11 = i1.ɵinlineInterpolate(2, "", _co.uniqId, "_col_", _v.context.index, ""); var currVal_12 = _v.context.$implicit.displayed; _ck(_v, 10, 0, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 12).ngClassValid; var currVal_6 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 12).ngClassPending; var currVal_8 = i1.ɵnov(_v, 13).className; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ColumnsConfigButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnsConfigButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnsConfigButtonComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 15, "popper-content", [["class", "popper-white-background"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_PopperContent_0, i9.RenderType_PopperContent)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(7, { "width.px": 0 }), i1.ɵdid(8, 180224, [["columnsToggler", 4]], 0, i3.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵeld(12, 0, null, 0, 2, "div", [["class", "columns-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ColumnsConfigButtonComponent_4)), i1.ɵdid(14, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(15, 0, null, 0, 4, "div", [["class", "button-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "margin-button theme-button-apply margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Select All "])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "button", [["class", "margin-button theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearAll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Clear All"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isBlueStyleBtn; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isBlueStyleBtn; _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 7, 0, _co.width); _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.columns; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.title; _ck(_v, 11, 0, currVal_3); }); }
export function View_ColumnsConfigButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-columns-config-button", [], null, null, null, View_ColumnsConfigButtonComponent_0, RenderType_ColumnsConfigButtonComponent)), i1.ɵdid(1, 4374528, null, 0, i10.ColumnsConfigButtonComponent, [i11.ColumnSettingsStorageService, i12.ColumnManagementService, i1.ChangeDetectorRef, i13.AppSettingsManageService], null, null)], null, null); }
var ColumnsConfigButtonComponentNgFactory = i1.ɵccf("slx-columns-config-button", i10.ColumnsConfigButtonComponent, View_ColumnsConfigButtonComponent_Host_0, { secondaryIcon: "secondaryIcon", colGroupKey: "colGroupKey", popperPosition: "popperPosition", title: "title", width: "width", blueStyleConfig: "blueStyleConfig" }, { columnChanged: "columnChanged", selectedAll: "selectedAll", clearedAll: "clearedAll" }, []);
export { ColumnsConfigButtonComponentNgFactory as ColumnsConfigButtonComponentNgFactory };
