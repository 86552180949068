import * as _ from 'lodash';
import { Component, OnInit, Provider, Input } from '@angular/core';
import { DialogOptions, ModalService, IDialog, DialogOptions2, DialogModeSize } from '../../../../../../common';
import { BenefitEmployeesApiService } from '../../../../../../app-modules/benefits/services';
import { BenefitGroup, LookupType, Lookup } from '../../../../../../organization/models';
import { LookupService } from '../../../../../../organization/services';
import { NewEnrollmentModel } from '../../../../models/employee-sections-benefits-management/new-enrollment.model';
import { EmployeeSectionsBenefitsManagementApiService } from '../../../../services';
import { BenefitPlanShortInfo, BenefitStatus } from '../../../../models';

@Component({
  selector: 'slx-enroll-employee-select-plan-popup',
  templateUrl: './enroll-employee-select-plan-popup.component.html',
  styleUrls: ['./enroll-employee-select-plan-popup.component.scss']
})
export class EnrollEmployeeSelectPlanPopupComponent implements OnInit, IDialog {

  public selectedGroup: BenefitGroup;
  public isLoading: boolean;
  public groups: Lookup<BenefitGroup>;
  public selectedGroupPlans: BenefitPlanShortInfo[];
  public selectedGroupPlansToDisplay: BenefitPlanShortInfo[];
  public selectedPlan: BenefitPlanShortInfo;

  public dialogResult: boolean;

  public static openDialog(
    model: NewEnrollmentModel,
    apiService: EmployeeSectionsBenefitsManagementApiService,
    modalService: ModalService,
    lookupService: LookupService,
    callback?: (result: boolean) => void
  ): EnrollEmployeeSelectPlanPopupComponent {

    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 550;
    dialogOptions.height = 500;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: LookupService,
        useValue: lookupService
      },
      {
        provide: ModalService,
        useValue: modalService
      },
      {
        provide: NewEnrollmentModel,
        useValue: model
      },
      {
        provide: EmployeeSectionsBenefitsManagementApiService,
        useValue: apiService
      }
    ];

    const title = `Enroll Employee in Benefits`;
    let component = modalService.globalAnchor.openDialog2(
      EnrollEmployeeSelectPlanPopupComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    return component;
  }

  //#region props
  public get canEnrollInSelectedGroup (): boolean {
    return !this.selectedGroup || (this.selectedGroup.allowMultipleEnrollment || this.hasNoActiveBenefitsFromSelectedGroup);
  }

  public get hasNoActiveBenefitsFromSelectedGroup (): boolean {
      if (this.selectedGroupPlans) {
        return !_.some(this.selectedGroupPlans, (p: BenefitPlanShortInfo) => p.status === BenefitStatus.Enrolled);
      }
      return true;
  }

  public get hasEnrolledOutOfEffectiveDate (): boolean {
    if (this.selectedGroup && this.selectedGroup.allowMultipleEnrollment) {
      if (this.selectedPlan) {
        return this.selectedPlan.enrolledOutEffectiveDate;
      }
      return false;
    } else {
      return _.some(this.selectedGroupPlans, (p: BenefitPlanShortInfo) => p.enrolledOutEffectiveDate);
    }
  }

  public planStatus(plan: BenefitPlanShortInfo): BenefitStatus {
    if (plan.enrolledOutEffectiveDate || (!this.selectedGroup.allowMultipleEnrollment && this.hasEnrolledOutOfEffectiveDate)) {
      return BenefitStatus.Ineligible;
    }
    return plan.status;
  }

  constructor(private model: NewEnrollmentModel, private apiService: EmployeeSectionsBenefitsManagementApiService,
              private options: DialogOptions, private modalService: ModalService, private lookupService: LookupService) { }

  public ngOnInit(): void {
    this.isLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.benefitGroups, employeeId: this.model.empId, isActive: true }).then((lookup: Lookup<BenefitGroup>) => {
      this.isLoading = false;
      this.groups = lookup;
    });
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onChangeGroup(g: BenefitGroup): void {
    this.selectedGroup = g;
    this.selectedPlan = null;
    this.loadGroupPlans(this.model.empId, g.id);
  }

  public onChangeSelectedPlan(plan: BenefitPlanShortInfo): void {
    this.selectedPlan = plan;
  }

  public onEnroll(): void {
    this.model.group = this.selectedGroup;
    this.model.plan = this.selectedPlan;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private loadGroupPlans(empId: number, groupId: number): void {
    this.isLoading = true;
    this.selectedGroupPlans = [];
    this.apiService.getEmployeeActiveBenefitPlans(empId, groupId, this.model.effectiveDate)
      .then((plans: BenefitPlanShortInfo[]) => {
        this.selectedGroupPlans = plans;
        this.selectedGroupPlansToDisplay = _.filter(this.selectedGroupPlans, p => {
          return p.status === BenefitStatus.Enrolled || p.enrolledOutEffectiveDate;
        });

        if (this.selectedGroup.allowMultipleEnrollment || !this.selectedGroupPlansToDisplay.length) {
          this.selectedGroupPlansToDisplay = _.slice(this.selectedGroupPlans, 0);
        }
        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });
  }

}
