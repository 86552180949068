var SchedulePartialAbsence = /** @class */ (function () {
    function SchedulePartialAbsence() {
    }
    Object.defineProperty(SchedulePartialAbsence.prototype, "codeDescription", {
        get: function () {
            return "[" + this.code + "] " + this.description;
        },
        enumerable: true,
        configurable: true
    });
    return SchedulePartialAbsence;
}());
export { SchedulePartialAbsence };
