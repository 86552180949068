/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vacation-planner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./vacation-planner-header/vacation-planner-header.component.ngfactory";
import * as i5 from "../../../common/services/state-management/state-management.service";
import * as i6 from "../../../common/services/component-state/component-state-storage.service";
import * as i7 from "../../../common/services/column-settings/column-settings-storage.service";
import * as i8 from "./vacation-planner-header/vacation-planner-header.component";
import * as i9 from "../../../common/services/modal/modal.service";
import * as i10 from "./vacation-grid/vacation-grid.component.ngfactory";
import * as i11 from "./vacation-grid/vacation-grid.component";
import * as i12 from "../../services/schedule-entry/schedule-entry-api.service";
import * as i13 from "../../../app-settings/services/app-settings-manage.service";
import * as i14 from "../../services/vacation-planner/vacation-planner-api.service";
import * as i15 from "./vacation-planner.component";
var styles_VacationPlannerComponent = [i0.styles];
var RenderType_VacationPlannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VacationPlannerComponent, data: {} });
export { RenderType_VacationPlannerComponent as RenderType_VacationPlannerComponent };
export function View_VacationPlannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 7, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "slx-vacation-planner-header", [], null, [[null, "settingsChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("settingsChanged" === en)) {
        var pd_0 = (_co.onSettingsChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_VacationPlannerHeaderComponent_0, i4.RenderType_VacationPlannerHeaderComponent)), i1.ɵprd(512, null, i5.StateManagementService, i5.StateManagementService, [i6.ComponentStateStorageService, i7.ColumnSettingsStorageService]), i1.ɵdid(6, 245760, null, 0, i8.VacationPlannerHeaderComponent, [i9.ModalService, i5.StateManagementService, i6.ComponentStateStorageService], null, { settingsChanged: "settingsChanged" }), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "slx-vacation-grid", [], null, null, null, i10.View_VacationGridComponent_0, i10.RenderType_VacationGridComponent)), i1.ɵdid(9, 114688, null, 0, i11.VacationGridComponent, [i1.ChangeDetectorRef, i9.ModalService, i12.ScheduleEntryApiService, i13.AppSettingsManageService, i14.VacationPlannerApiService], { vacationPlannerData: [0, "vacationPlannerData"], settings: [1, "settings"], orgLevel: [2, "orgLevel"], redrawToggler: [3, "redrawToggler"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 6, 0); var currVal_1 = _co.vacationPlannerData; var currVal_2 = _co.settings; var currVal_3 = _co.selectedOrgLevel; var currVal_4 = _co.redrawToggler; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_VacationPlannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-vacation-planner", [], null, null, null, View_VacationPlannerComponent_0, RenderType_VacationPlannerComponent)), i1.ɵdid(1, 245760, null, 0, i15.VacationPlannerComponent, [i14.VacationPlannerApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VacationPlannerComponentNgFactory = i1.ɵccf("slx-vacation-planner", i15.VacationPlannerComponent, View_VacationPlannerComponent_Host_0, {}, {}, []);
export { VacationPlannerComponentNgFactory as VacationPlannerComponentNgFactory };
