import { HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { appConfig } from "../../../app.config";
import { ApiUtilService, UrlParamsService } from "../../../common";
import { FieldsMeta, ResponseBody } from "../../../core/models";
import { configurationConfig } from "../../configuration.config";
import { NotificationGroup } from "../../models";
import { NotificationGroupEvent } from "../../models/notifications/notifications-group-event";
import { NotificationsMapService } from "./notifications-map.service";

@Injectable()
export class NotificationsApiService { 
 
  constructor(private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private mapService: NotificationsMapService) {
  }
  public getNotificationGroups(orgLevelId: number):Promise<NotificationGroup[]> {   
    
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.configuration.eventNotifications.group}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapNotificationGroups(response.data)
      );
    return promise;
  }

  public getEventGroups():Promise<NotificationGroupEvent[]> {
    const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.eventNotifications.root}/${configurationConfig.api.configuration.eventNotifications.event}/${configurationConfig.api.configuration.eventNotifications.group}`;
    
    let request: HttpRequest<any> = new HttpRequest('GET', url);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapNotificationGroupEvent(response.data)
      );
    return promise;
  }
  public addGroup(group: any,orgLevelId: number): Promise<NotificationGroup[]> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.configuration.eventNotifications.groupCreate}`;
     let body:any=group;
    let request: HttpRequest<any> = new HttpRequest('POST', url,body);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapNotificationGroup(response.data)
      );
    return promise;
  }
  public saveGroup(group: any,orgLevelId: number,groupId:number): Promise<NotificationGroup[]> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.configuration.eventNotifications.group}/${groupId}`;
    let body:any=group;
    let request: HttpRequest<any> = new HttpRequest('PUT', url,group);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.mapService.mapNotificationGroup(response.data)
      );
    return promise;
  }
  public removeGroup(orgLevelId: number,groupId:number): Promise<NotificationGroup[]> {
    const url: string = `${this.getOrgLevelApiRoot(orgLevelId)}/${configurationConfig.api.configuration.eventNotifications.group}/${groupId}`;
   
    let request: HttpRequest<any> = new HttpRequest('DELETE', url);

    let promise: Promise<any> = this.apiUtilService.request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => response.status
      );
    return promise;
  }
  
  private getOrgLevelApiRoot(orgLevelId: number): string {
    return `${this.getApiRoot()}/${configurationConfig.api.configuration.eventNotifications.root}/orgLevel/${orgLevelId}`;
  }

  private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}