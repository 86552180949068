<section class="groups">
    <header class="groups__hd">
        <p class="groups__hd-title">Select the details you would like to see on the {{modelData.appTitle}} Dashboard.</p>
        <p class="groups__hd-text">(Click <i aria-hidden="true" class="fas fa-eye"></i> to Show or Hide dashboard details and Click <i aria-hidden="true" class="fas fa-bars"></i> and Drag to Reorder)</p>
        <p class="groups__hd-active">Active Details</p>
    </header>
    <div class="flex flex-direction__column groups__body">
        <div class="fav-subtitle">
            <div class="td-bars">Reorder</div>
            <div class="td-eye">Show/Hide</div>
        </div>
        <span>
            <kendo-sortable
            [kendoSortableBinding]="activeAlerts"
            (dragEnd)="onDragEnd(activeAlerts)"
            [animation]="true"
            emptyText=""
            >
            <ng-template let-item="item">
                <div class="td-section">
                    <table class="tbl" aria-describedby="activeAlerts" aria-hidden="true">
                        <td class="icon-td"><em class="{{item.icon}}"></em></td>
                        <td class="text-td"><span title={{item.alertName}}>{{ item.alertName }}</span></td>
                        <td class="bars-td"><i class="fas fa-bars" aria-hidden="true"></i></td>
                        <td class="eye-td"><i class="fas fa-eye" (click)="showHideAlertItem(item)" aria-hidden="true"></i></td>
                    </table>
                </div>
            </ng-template>
            </kendo-sortable>
        </span>
        <div>
            <p class="groups__more">More Details</p>
            <div *ngFor="let item of inActiveAlerts" class="td-section">
                <table class="tbl" aria-describedby="inActiveAlerts" aria-hidden="true">
                    <td class="icon-td"><em class="{{item.icon}}"></em></td>
                    <td class="text-td"><span title={{item.alertName}}>{{ item.alertName }}</span></td>
                    <td class="bars-td"></td>
                    <td class="eye-td"><i class="fas fa-eye-slash" (click)="showHideAlertItem(item)" aria-hidden="true"></i></td>
                </table>
            </div>
        </div>
    </div>
</section>
