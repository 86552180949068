import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';

import { DialogOptions, IDialog, DialogOptions2, DialogModeSize } from '../../../../../common/index';

import { ModalService } from '../../../../../common/services/index';
import { BenefitEligibleEmployeesRequest, BenefitEnrolledEmployeesRequest } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-employees-enrolled-dialog',
  templateUrl: 'benefit-employees-enrolled-dialog.component.html',
  styleUrls: ['benefit-employees-enrolled-dialog.component.scss']
})
export class BenefitEnrolledEmployeesDialogComponent implements IDialog {

  public options: DialogOptions;
  public dialogResult: boolean;
  public request: BenefitEnrolledEmployeesRequest;
  public delayed = false;

  private modalService: ModalService;

  public static openDialog(request: BenefitEnrolledEmployeesRequest, modalService: ModalService, callback: (result: boolean, cmd: any) => void): BenefitEnrolledEmployeesDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.grid;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: BenefitEnrolledEmployeesRequest,
        useValue: request
      }
    ];
    const title = `Enrolled Employees - ${request.planName}`;
    let dialog: BenefitEnrolledEmployeesDialogComponent = modalService.globalAnchor
      .openDialog2(BenefitEnrolledEmployeesDialogComponent, title, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, undefined);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    request: BenefitEnrolledEmployeesRequest
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;

    //delay to fix IE11 issue with locked columns in dialog
    setTimeout(() => {
      this.delayed = true;
    }, 200);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
