import { Component, Input, OnInit, Provider, Inject } from '@angular/core';
import * as _ from 'lodash';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { ScheduleAbsence } from '../../../../organization/models/lookup/index';
import { ShiftPhoneCall, ShiftEligibleEmployee } from '../../../models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { TEXT_MSG_TOKEN } from '../../../../core/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-msg',
  templateUrl: 'shift-replacement-msg.component.html',
  styleUrls: ['shift-replacement-msg.component.scss']
})
export class ShiftReplacementMsgComponent implements OnInit, IDialog {

  public appConfig: IApplicationConfig;
  public options: DialogOptions;
  public dialogResult: boolean;

  public textMsg: string;
  private modalService: ModalService;

  public static openDialog(modalService: ModalService, title: string, textMsg: string, callback: (result: boolean, textMsg: string) => void): ShiftReplacementMsgComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 400;
    dialogOptions.width = 600;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: TEXT_MSG_TOKEN,
        useValue: textMsg
      },

    ];
    let dialog: ShiftReplacementMsgComponent = modalService.globalAnchor
      .openDialog(ShiftReplacementMsgComponent, title, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.textMsg);
      });
    return dialog;
  }

  constructor(options: DialogOptions,
    modalService: ModalService,
    @Inject(TEXT_MSG_TOKEN) textMsg: String
  ) {
    this.modalService = modalService;
    this.options = options;
    this.textMsg = <string>textMsg;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
