<form novalidate #form="ngForm" *ngIf="employeeSectionsEmergencyContacts">
  <div class="col-xs-12" *ngFor="let emergencyContact of employeeSectionsEmergencyContacts.emergencyContacts; let i = index">
    <div class="col-xs-12">
      <h4 class="brcramp">Emergency Contact {{i+1}}</h4>
      <hr>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="255" [readonly]="!state.isEditMode" name="name{{i}}" placeholder="Name"
          [inputPolicy]="emergencyContact?.name.Policy" [(ngModel)]="emergencyContact?.name.fieldValue" slxTrim="both">
          <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator  *ngIf="state.isEditMode">
        <input slx-input type="text" maxlength="255" [readonly]="!state.isEditMode" name="address{{i}}" placeholder="Address"
        [inputPolicy]="emergencyContact?.address.Policy" [(ngModel)]="emergencyContact?.address.fieldValue" slxTrim="both">
        <span errorMessage for="required"></span>
      </slx-input-decorator>
      <div class="slx-form-group slx-readonly" *ngIf="!state.isEditMode">
        <label class="slx-label">Address</label>
        <div class="slx-input-group kendo kendo-common kendo-default">
          <div class="address-text">
            {{emergencyContact?.address.fieldValue}}
          </div>
        </div>
      </div>

    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <input slx-input type="text" maxlength="255" [readonly]="!state.isEditMode" name="city{{i}}" placeholder="City"
          [inputPolicy]="emergencyContact?.city.Policy" [(ngModel)]="emergencyContact?.city.fieldValue" slxTrim="both">
          <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <div class="row">
        <div class="col-sm-6">
          <slx-input-decorator>
            <slx-dropdown-input slx-input lookup="state" [readonly]="!state.isEditMode" name="state{{i}}"
                                placeholder="State" [inputPolicy]="emergencyContact?.state.Policy"
                                [(ngModel)]="emergencyContact?.state.fieldValue" emptyOption="" >
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
          </slx-input-decorator>
        </div>
        <div class="col-sm-6">
          <slx-input-decorator>
            <slx-zip-input slx-input [readonly]="!state.isEditMode" name="zip{{i}}"
                           placeholder="Zip" [inputPolicy]="emergencyContact?.zip.Policy"
                           [(ngModel)]="emergencyContact?.zip.fieldValue">
              </slx-zip-input>
              <span errorMessage for="required"></span>
              <span errorMessage for="min">Min length is 5</span>
              <span errorMessage for="max">Max length is 10</span>
          </slx-input-decorator>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-phone-input slx-input phone [readonly]="!state.isEditMode" name="phone{{i}}" placeholder="Phone"
                         [inputPolicy]="emergencyContact?.phone.Policy"
                         [(ngModel)]="emergencyContact?.phone.fieldValue">
          </slx-phone-input>
          <span errorMessage for="required"></span>
          <span errorMessage for="phone"></span>
      </slx-input-decorator>
    </div>
  </div>
</form>
