import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceFrequencies } from '../../../models/index';
import { LMCommonTab } from '../../lm-common-tab';
import { appConfig } from '../../../../../app.config';
var LMIntermittentTabComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LMIntermittentTabComponent, _super);
    function LMIntermittentTabComponent(manService) {
        var _this = _super.call(this, manService) || this;
        _this.manService = manService;
        _this.appConfig = appConfig;
        _this.subscriptions = {};
        return _this;
    }
    Object.defineProperty(LMIntermittentTabComponent.prototype, "isCustom", {
        get: function () {
            return this.manService.isCustom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMIntermittentTabComponent.prototype, "isDaily", {
        get: function () {
            return this.manService.isDaily;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMIntermittentTabComponent.prototype, "isWeekly", {
        get: function () {
            return this.manService.isWeekly;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMIntermittentTabComponent.prototype, "isMonthly", {
        get: function () {
            return this.manService.isMonthly;
        },
        enumerable: true,
        configurable: true
    });
    LMIntermittentTabComponent.prototype.ngOnInit = function () {
        _super.prototype.init.call(this);
        if (this.manService.isCreatingNew) {
            this.manService.initRecurrenceService(null, RecurrenceFrequencies.custom);
        }
        else {
            var loaRepeat = this.manService.getLoaRepeat();
            this.manService.initRecurrenceService(loaRepeat.recurrenceRule, null);
        }
    };
    LMIntermittentTabComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    return LMIntermittentTabComponent;
}(LMCommonTab));
export { LMIntermittentTabComponent };
