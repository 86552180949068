import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { configurationConfig } from '../../configuration.config';
import { ResponseBody, Meta, EditResult, FieldsMeta } from '../../../core/models/index';
import { IpManagementConfig, IIpManagementConfig } from '../../models/ip-management/ip-management-config.model';
import { IpManagementMapService } from './ip-management-map.service';
import { IpSettings, IIpSettings } from '../../models/ip-management/ip-settings.model';
import { HttpRequest } from '@angular/common/http';
import { IpRestriction, IIpRestriction } from '../../models/ip-management/ip-restriction.model';

@Injectable()
export class IpManagementApiService {
  

  constructor(
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private ipManagementMapService:IpManagementMapService) {}
  
  public async getIpManagementConfig(): Promise<IpManagementConfig> {
    const url = `${this.getApiRoot()}/${configurationConfig.api.configuration.ipManagement.configuration}`;
    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService.request<IIpManagementConfig, Meta>(request)
      .then((response) => this.ipManagementMapService.mapToIpManagementConfig(response.data));
  }

  public setRestrictions(restrictions: IpSettings ): Promise<any> {

    const url = `${this.getApiRoot()}/${configurationConfig.api.configuration.ipManagement.configuration}`;
    let restricts: IpSettings = this.ipManagementMapService.mapToIpSettings(restrictions);
    const request = this.urlParamsService.createPutRequest(url,restricts);

    return this.apiUtilService.request<IIpSettings, Meta>(request);
  }

  public updateRestriction(ipRestriction: IpRestriction ): Promise<any> {

    const url = `${this.getApiRoot()}/${configurationConfig.api.configuration.ipManagement.restriction}`;
    let restriction: IpRestriction = this.ipManagementMapService.mapToIpRestriction(ipRestriction);
    const request = this.urlParamsService.createPutRequest(url,restriction);

    return this.apiUtilService.request<IIpRestriction, Meta>(request);
  }

  public addRestriction(ipRestriction: IpRestriction ): Promise<any> {

    const url = `${this.getApiRoot()}/${configurationConfig.api.configuration.ipManagement.restriction}`;
    let restriction: IpRestriction = this.ipManagementMapService.mapToIpRestriction(ipRestriction);
    const request = this.urlParamsService.createPostRequest(url,restriction);

    return this.apiUtilService.request<IIpRestriction, Meta>(request);
  }

  public deleteRestriction(restrictionId: number ): Promise<any> {

    const url = `${this.getApiRoot()}/${configurationConfig.api.configuration.ipManagement.restriction}`;
    const request = this.urlParamsService.createDeleteRequest(url,{ restrictionId });

    return this.apiUtilService.request<IIpRestriction, Meta>(request);
  }


  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.ipManagement.root}`;
  }

}
