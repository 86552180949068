import * as tslib_1 from "tslib";
import { OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { EmployeeSectionsRateHistory } from '../../../models/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { orderBy } from '@progress/kendo-data-query';
var EmployeeSectionsRateHistoryComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsRateHistoryComponent, _super);
    function EmployeeSectionsRateHistoryComponent(employeeSectionsEmploymentApiService, decorator, changeDetector, ngZone) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.changeDetector = changeDetector;
        _this.sort = [];
        _this.sort = [];
        return _this;
    }
    Object.defineProperty(EmployeeSectionsRateHistoryComponent.prototype, "rateHistory", {
        set: function (employeeSectionsRateHistory) {
            this.employeeSectionsRateHistory = employeeSectionsRateHistory;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsRateHistoryComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsRateHistoryComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsRateHistory;
    };
    EmployeeSectionsRateHistoryComponent.prototype.sortChange = function (sort) {
        this.sort = sort;
        this.refreshGrid();
    };
    EmployeeSectionsRateHistoryComponent.prototype.onRefreshClicked = function () {
        this.loadSubsection();
    };
    EmployeeSectionsRateHistoryComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmploymentRateHistory(this.employeeId)
            .then(function (employeeSectionsRateHistory) {
            _this.employeeSectionsRateHistory = employeeSectionsRateHistory;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.stopProgress();
        })
            .catch(function () { return _this.stopProgress(); });
    };
    EmployeeSectionsRateHistoryComponent.prototype.refreshGrid = function () {
        if (!this.employeeSectionsRateHistory) {
            this.gridView = null;
            return;
        }
        this.gridView = {
            data: orderBy(this.employeeSectionsRateHistory.records, this.sort),
            total: this.employeeSectionsRateHistory.records.length
        };
    };
    Object.defineProperty(EmployeeSectionsRateHistoryComponent.prototype, "setDecimalLimit", {
        get: function () {
            if (this.employeeSectionsRateHistory.isEnableRate4DecimalPlaces) {
                return 4;
            }
            else
                return 2;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSectionsRateHistoryComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsRateHistoryComponent };
