import * as _ from 'lodash';
import { Directive, HostListener, Input, ContentChild, Host, OnInit } from '@angular/core';
import { MoneyInputComponent } from '../../components/money-input/money-input.component';

@Directive({
  selector: 'slx-money-input[inputValueAsString]',
})
export class InputValueAsStringDirective {
  constructor(private component: MoneyInputComponent) {
    const self = this;
    const originalMethod = this.component.writeValue;
    this.component.writeValue = function (value) {
      let val = value;
      if (_.isFinite(value)) {
        val = value.toFixed(4);
      }
      originalMethod.call(self.component, val);
    };
  }
}
