import * as tslib_1 from "tslib";
import * as _ from "lodash";
import { process } from "@progress/kendo-data-query";
import { GridComponent, } from "@progress/kendo-angular-grid";
import { KendoGridStateHelper, ConfirmDialog2Component, ModalService, ConfirmOptions, } from "../../../../../common/index";
import { appConfig } from "../../../../../app.config";
import { EmployeeSectionsEducation, EmployeeSectionsEducations, } from "../../../models/employee-sections-personal/employee-sections-educations";
import { unsubscribe } from "../../../../../core/decorators/index";
import { Subscription } from "rxjs";
import { EmployeeSectionsPersonalApiService } from "../../../services";
import { EmployeeSubSectionsDecoratorComponent } from "../../employee-subsection-decorator/employee-subsection-decorator.component";
var EmployeeSectionsEducationsComponent = /** @class */ (function () {
    function EmployeeSectionsEducationsComponent(modalService, apiService, decorator) {
        this.modalService = modalService;
        this.apiService = apiService;
        this.decorator = decorator;
        this.newEmpEducation = new EmployeeSectionsEducation();
        this.enableAddEducation = true;
        this.sort = [];
        this.enableSave = false;
        this.addNewRow = new EmployeeSectionsEducation();
        this.skip = 0;
        this.degreeSize = 50;
        this.schoolCollegeSize = 255;
        this.minDate = new Date(1899, 12, 1);
        this.maxDate = new Date(2079, 5, 6);
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [{ field: "graduationYear", dir: "asc" }];
        this.createSubscriptions();
        this.decorator.isEditableByConfiguration = false;
    }
    Object.defineProperty(EmployeeSectionsEducationsComponent.prototype, "educationList", {
        set: function (employeeSectionsEducations) {
            var _this = this;
            if (employeeSectionsEducations != null) {
                var actions = employeeSectionsEducations.fieldsMeta.actions;
                var edu_1 = [];
                _.forEach(actions, function (v) {
                    if (v === "add")
                        _this.canAddEducation = true;
                    if (v === "edit")
                        _this.canEditEducation = true;
                    if (v === "delete")
                        _this.canDeleteEducation = true;
                });
                this.rawEducations = employeeSectionsEducations.education;
                _.forEach(this.rawEducations, function (e) {
                    if (e.degree !== '[blank]') {
                        edu_1.push(e);
                    }
                });
                (edu_1.length <= 1) ? this.enableAddEducation = true : this.enableAddEducation = false;
                this.educations = edu_1;
                this.refreshGrid();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsEducationsComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsEducationsComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    EmployeeSectionsEducationsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsEducationsComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.gridEditSubscription = this.gridState.onEdit$.subscribe(function (item) {
            _this.editingItem = item;
            _this.addMode = false;
            if (item.graduationYear) {
                _this.isvalidDate = true;
            }
        });
        this.gridCancelSubscription = this.gridState.onCancel$.subscribe(function (item) {
            _this.editingItem = null;
            _this.addMode = false;
            _this.enableSave = false;
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            if (item.isNew) {
                _this.addMode = true;
            }
            _this.enableSave = false;
            _this.doUpdate(item.dataItem);
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialog2Component.openDialog("Delete Education", "Are you sure that you want to remove this Education?", _this.modalService, function (result) {
                if (result) {
                    _this.doRemove(item.dataItem);
                }
            }, options);
        });
    };
    EmployeeSectionsEducationsComponent.prototype.onAddEducation = function () {
        this.enableSave = false;
        this.addMode = true;
        this.newEmpEducation.degree = null;
        this.newEmpEducation.schoolCollege = null;
        this.newEmpEducation.graduationYear = null;
        this.grid.addRow(this.newEmpEducation);
    };
    EmployeeSectionsEducationsComponent.prototype.doUpdate = function (item) {
        var _this = this;
        if (this.addMode) {
            if (this.rawEducations[0].sequenceId === "first" && this.rawEducations[0].degree === "[blank]") {
                item.sequenceId = "first";
            }
            else if (this.rawEducations[1].sequenceId === "second" && this.rawEducations[1].degree === "[blank]") {
                item.sequenceId = "second";
            }
        }
        item.graduationYear = new Date(item.graduationYear).toDateString();
        this.isLoading = true;
        this.apiService.setEducation(item, this.employeeId).then(function (status) {
            if (status) {
                _this.loadList();
            }
        });
    };
    EmployeeSectionsEducationsComponent.prototype.doRemove = function (item) {
        var _this = this;
        this.educations = _.without(this.educations, item);
        this.isLoading = true;
        this.apiService
            .deleteEducation(item, this.employeeId)
            .then(function (status) {
            if (status) {
                _this.loadList();
            }
        });
    };
    EmployeeSectionsEducationsComponent.prototype.refreshGrid = function () {
        if (!this.educations) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.educations, this.gridState.state);
    };
    EmployeeSectionsEducationsComponent.prototype.onChangeValue = function (item) {
        var isDegree = this.validateField(item.degree, this.degreeSize);
        var isSchoolCollege = this.validateField(item.schoolCollege, this.schoolCollegeSize);
        var isDate = this.validateDate(item.graduationYear);
        if (isDegree && isSchoolCollege && isDate && this.isvalidDate) {
            this.enableSave = true;
        }
        else {
            this.enableSave = false;
        }
    };
    EmployeeSectionsEducationsComponent.prototype.validityChanged = function (validity, item) {
        var date = new Date(validity);
        if (date <= this.maxDate && date >= this.minDate) {
            this.isvalidDate = true;
        }
        else {
            this.isvalidDate = false;
        }
        this.onChangeValue(item);
    };
    EmployeeSectionsEducationsComponent.prototype.validateField = function (value, size) {
        var validate;
        if (value == null || value == "") {
            validate = false;
        }
        else {
            validate = true;
        }
        if (value != null && value != "") {
            if (value.length > size) {
                validate = false;
            }
            else {
                validate = true;
            }
        }
        return validate;
    };
    EmployeeSectionsEducationsComponent.prototype.validateDate = function (value) {
        var validate;
        if (value != null) {
            validate = true;
        }
        else {
            validate = false;
        }
        return validate;
    };
    EmployeeSectionsEducationsComponent.prototype.loadList = function () {
        var _this = this;
        this.apiService
            .getEducations(this.employeeId)
            .then(function (educations) {
            var educ = [];
            _this.rawEducations = educations.education;
            _.forEach(_this.rawEducations, function (education) {
                if (education.degree !== '[blank]') {
                    educ.push(education);
                }
            });
            (educ.length <= 1) ? _this.enableAddEducation = true : _this.enableAddEducation = false;
            _this.educations = educ;
            _this.refreshGrid();
            _this.isLoading = false;
            _this.addMode = false;
        });
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsEducationsComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsEducationsComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsEducationsComponent.prototype, "gridRemoveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsEducationsComponent.prototype, "gridEditSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsEducationsComponent.prototype, "gridCancelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsEducationsComponent.prototype, "formValueChangeSubscription", void 0);
    return EmployeeSectionsEducationsComponent;
}());
export { EmployeeSectionsEducationsComponent };
