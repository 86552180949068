<kendo-grid #kendoGrid [data]="gridState?.view" (excelExport)="onExcelExport($event)">
    <kendo-grid-column title="Pay Cycle" field="payCycleStartDate">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{dataItem.payCycleStartDate | amDateFormat: appConfig.dateFormat}}
        </ng-template>
        <ng-template kendoGridFooterTemplate ><strong>Total</strong></ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Productive Hours" field="productiveHours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{dataItem.productiveHours | number:'.2-2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['productiveHours']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Non-Productive Hours" field="nonProductiveHours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{dataItem.nonProductiveHours | number:'.2-2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['nonProductiveHours']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Total Hours"  field="totalHours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align">{{dataItem.totalHours | number:'.2-2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['totalHours']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Diff (hrs)" field="differenceHours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align {{checkHrsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.differenceHours | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['differenceHours']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Pct (hrs)" field="percentageHours">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align {{checkHrsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.percentageHours | number:'.2' | slxPercent}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['percentageHours']?.sum | number: ".2-2" | slxPercent }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Payroll Amount" field="payrollAmount">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align dollar-sign">{{dataItem.payrollAmount | number:'.2-2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align dollar-sign">
                <strong>{{ totals['payrollAmount']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Diff (Amount)" field="differenceAmount">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align dollar-sign {{checkAmountsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.differenceAmount | number:'.2'}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align dollar-sign">
                <strong>{{ totals['differenceAmount']?.sum | number: ".2-2" }}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Diff (Pct)" field="differencePercentage">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="right-align {{checkAmountsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.differencePercentage | number:'.2' | slxPercent}}</span>
        </ng-template>
        <ng-template kendoGridFooterTemplate >
            <span class="right-align">
                <strong>{{ totals['differencePercentage']?.sum | number: ".2-2" | slxPercent}}</strong>
            </span>
        </ng-template>
    </kendo-grid-column>


    <kendo-grid-excel fileName="current-previous-periods.xlsx">
      <kendo-excelexport-column title="Pay Cycle" field="payCycleStartDate" [cellOptions]="{ format: 'mm/dd/yyyy' }">
        <ng-template kendoExcelExportFooterTemplate>Total</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Productive Hours"
        field="productiveHours"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['productiveHours']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Non-Productive Hours"
        field="nonProductiveHours"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['nonProductiveHours']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Total Hours"
        field="totalHours"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['totalHours']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Diff (hrs)"
        field="differenceHours"
        [cellOptions]="{ format: numberFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: numberFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['differenceHours']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Pct (hrs)"
        field="percentageHours"
        [cellOptions]="{ format: percentFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: percentFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['percentageHours']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Payroll Amount"
        field="payrollAmount"
        [cellOptions]="{ format: dollarFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['payrollAmount']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Diff (Amount)"
        field="differenceAmount"
        [cellOptions]="{ format: dollarFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: dollarFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['differenceAmount']?.sum }}</ng-template>
      </kendo-excelexport-column>
      <kendo-excelexport-column
        title="Diff (Pct)"
        field="differencePercentage"
        [cellOptions]="{ format: percentFormat }"
        [footerCellOptions]="{ textAlign: 'right', format: percentFormat }"
      >
        <ng-template kendoExcelExportFooterTemplate>{{ totals['differencePercentage']?.sum }}</ng-template>
      </kendo-excelexport-column>
    </kendo-grid-excel>

    <kendo-grid-pdf fileName="current-previous-periods.pdf" [allPages]="true" paperSize="A4" [landscape]="true" [repeatHeaders]="true" [scale]="0.7">
      <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
      <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
        <div class="page-template">
           <div class="header">
            <div class="title">Current vs Previous Periods - {{ payrollCycle | slxPeriod }}; {{ orgLevelName }}; {{ todayDateTime }}</div>
             Page {{ pageNum }} of {{ totalPages }}
           </div>
         </div>
      </ng-template>
      <kendo-grid-column title="Pay Cycle" field="payCycleStartDate" width="110">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.payCycleStartDate | amDateFormat: appConfig.dateFormat}}
          </ng-template>
          <ng-template kendoGridFooterTemplate ><strong>Total</strong></ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Productive Hours" field="productiveHours">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align">{{dataItem.productiveHours | number:'.2-2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align">
                  <strong>{{ totals['productiveHours']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Non-Productive Hours" field="nonProductiveHours">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align">{{dataItem.nonProductiveHours | number:'.2-2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align">
                  <strong>{{ totals['nonProductiveHours']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Total Hours"  field="totalHours" width="100">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align">{{dataItem.totalHours | number:'.2-2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align">
                  <strong>{{ totals['totalHours']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Diff (hrs)" field="differenceHours" width="90">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align {{checkHrsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.differenceHours | number:'.2-2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align">
                  <strong>{{ totals['differenceHours']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Pct (hrs)" field="percentageHours" width="90">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align {{checkHrsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.percentageHours | number:'.2-2' | slxPercent}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align">
                  <strong>{{ totals['percentageHours']?.sum | number: ".2-2" | slxPercent }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Payroll Amount" field="payrollAmount" width="120">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align dollar-sign">{{dataItem.payrollAmount | number:'.2-2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align dollar-sign">
                  <strong>{{ totals['payrollAmount']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Diff (Amount)" field="differenceAmount" width="130">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align dollar-sign {{checkAmountsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.differenceAmount | number:'.2-2'}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align dollar-sign">
                  <strong>{{ totals['differenceAmount']?.sum | number: ".2-2" }}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column title="Diff (Pct)" field="differencePercentage" width="90">
          <ng-template kendoGridCellTemplate let-dataItem>
              <span class="right-align {{checkAmountsPtcDifference (dataItem, management.diffPercent) ? 'highlight' : ''}}">{{dataItem.differencePercentage | number:'.2-2' | slxPercent}}</span>
          </ng-template>
          <ng-template kendoGridFooterTemplate >
              <span class="right-align">
                  <strong>{{ totals['differencePercentage']?.sum | number: ".2-2" | slxPercent}}</strong>
              </span>
          </ng-template>
      </kendo-grid-column>
    </kendo-grid-pdf>
</kendo-grid>
