import * as tslib_1 from "tslib";
import { screenUtils } from './../../../../common/utils/screenUtils';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import { unsubscribe } from '../../../../core/decorators/index';
import { EmployeeDetailsTableListComponent } from '../../../../organization/index';
import { StateManagementService } from '../../../../common/services/index';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateResetTypes } from '../../../../core/models/settings/index';
import { AttendanceType, ArrivalsDeparturesAnalytics } from '../../../models/index';
import { ArrivalDeparturesDetailsManagementService } from '../../../services/index';
var ArrivalsDeparturesDetailsComponent = /** @class */ (function () {
    function ArrivalsDeparturesDetailsComponent(router, activatedRoute, arrivalDeparturesDetailsManagementService, stateManagement, storageService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.arrivalDeparturesDetailsManagementService = arrivalDeparturesDetailsManagementService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.state = {
            isTileView: true
        };
        this.m_tileViewControlId = 'TileViewNgx';
        this.m_tileViewResetType = StateResetTypes.None;
        Assert.isNotNull(arrivalDeparturesDetailsManagementService, 'arrivalDeparturesDetailsManagementService');
        this.arrivalDeparturesDetailsManagementService = arrivalDeparturesDetailsManagementService;
    }
    Object.defineProperty(ArrivalsDeparturesDetailsComponent.prototype, "listTitle", {
        get: function () {
            if (this.analytics === ArrivalsDeparturesAnalytics.unscheduled) {
                return "Date: " + moment(this.selectedDate).format(appConfig.monthDayDateFormat) + " " + this.attendanceType + " " + this.analytics;
            }
            if (this.selectedTime) {
                return "Date: " + moment(this.selectedTime).format(appConfig.monthDayDateFormat) + " Time: " + moment(this.selectedTime).format(appConfig.timeFormat) + " " + this.attendanceType + " " + this.analytics;
            }
            return "Date: " + moment(this.selectedDate).format(appConfig.monthDayDateFormat) + " Time: " + moment(this.selectedDate).format(appConfig.timeFormat) + " " + this.attendanceType + " " + this.analytics;
        },
        enumerable: true,
        configurable: true
    });
    ArrivalsDeparturesDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.stateManagement.init('ArrivalsDeparturesDetailsComponent', false);
        this.restoreStates();
        this.loadStartedSubscription = this.arrivalDeparturesDetailsManagementService.onLoadStarted$.subscribe(function () {
            _this.isLoading = true;
        });
        this.loadedSubscription = this.arrivalDeparturesDetailsManagementService.onLoaded$.subscribe(function (details) {
            _this.employeeDetailsList = details;
            _this.isLoading = false;
        });
        this.routeSubscription = this.activatedRoute.params
            .combineLatest(this.activatedRoute.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            var dateOn = queryParams['date'];
            if (dateOn) {
                _this.selectedDate = moment(dateOn, appConfig.linkDateFormat).toDate();
            }
            else {
                _this.selectedDate = moment().toDate();
            }
            var timeOn = queryParams['time'];
            _this.selectedTime = timeOn ? moment(timeOn, appConfig.linkDateTimeFormat).toDate() : null;
            _this.attendanceType = queryParams['attendance_type'];
            if (!_this.attendanceType) {
                _this.attendanceType = AttendanceType.arrivals;
            }
            _this.analytics = queryParams['analytics'];
            if (!_this.analytics) {
                _this.analytics = ArrivalsDeparturesAnalytics.unscheduled;
            }
            _this.arrivalDeparturesDetailsManagementService.loadDetails(_this.selectedDate, _this.selectedTime, _this.attendanceType, _this.analytics);
        });
    };
    ArrivalsDeparturesDetailsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ArrivalsDeparturesDetailsComponent.prototype.onExportToExcel = function () {
        this.tableListComponent.exportToExcel();
    };
    ArrivalsDeparturesDetailsComponent.prototype.onExportToPdf = function () {
        this.tableListComponent.exportToPdf();
    };
    ArrivalsDeparturesDetailsComponent.prototype.onTileViewChanged = function () {
        this.saveTileViewStates();
    };
    Object.defineProperty(ArrivalsDeparturesDetailsComponent.prototype, "isMobile", {
        get: function () {
            return screenUtils.isMobile;
        },
        enumerable: true,
        configurable: true
    });
    ArrivalsDeparturesDetailsComponent.prototype.saveTileViewStates = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_tileViewControlId, {
            value: { isTileView: this.state.isTileView }
        }, this.m_tileViewResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_tileViewControlId);
    };
    ArrivalsDeparturesDetailsComponent.prototype.restoreStates = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_tileViewControlId);
        if (state && state.value) {
            this.state.isTileView = state.value.isTileView;
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesDetailsComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesDetailsComponent.prototype, "loadStartedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesDetailsComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ArrivalsDeparturesDetailsComponent.prototype, "routeSubscription", void 0);
    return ArrivalsDeparturesDetailsComponent;
}());
export { ArrivalsDeparturesDetailsComponent };
