import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
var KendoGridBooleanFilterMenuComponent = /** @class */ (function () {
    function KendoGridBooleanFilterMenuComponent() {
        this.items = [
            { text: 'Y', value: true },
            { text: 'N', value: false }
        ];
    }
    Object.defineProperty(KendoGridBooleanFilterMenuComponent.prototype, "value", {
        get: function () {
            var _this = this;
            var filter = _.find(kendoUtils.flatten(this.filter || {}), function (filter) { return filter.field === _this.column.field; });
            return filter ? filter.value : null;
        },
        enumerable: true,
        configurable: true
    });
    KendoGridBooleanFilterMenuComponent.prototype.onChange = function (value) {
        this.filterService.filter({
            filters: [{
                    field: this.column.field,
                    operator: 'eq',
                    value: value
                }],
            logic: 'and'
        });
    };
    return KendoGridBooleanFilterMenuComponent;
}());
export { KendoGridBooleanFilterMenuComponent };
