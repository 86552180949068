import { Directive, HostListener, Input } from '@angular/core';
import { BIPAReportDefinition, BIPAShiftGroupTime, BIPATableData, BIPAUnitShiftData, ConfigForceLoad, GetBIPAReportDefinition, ReportDefinition } from '../../models';
import { ModalService } from '../../../common';
import { BipaReportDialogComponent } from '../../components/bipa-report-dialog/bipa-report-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { mutableSelect, unsubscribe } from './../../../../app/core/decorators';
import { OrgLevel } from './../../../../app/state-model/models';
import { Observable, Subscription } from 'rxjs';
import { ReportsApiService } from '../../services/reports-api.service';
import * as _ from 'lodash';
import { NotificationsService } from './../../../../app/core/components';

@Directive({
  selector: '[slxBIPAReport]'
})
export class BIPAReportDirective {

  @Input()
  public slxBIPAReport: ReportDefinition;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  @unsubscribe()
  private orgLevelSubscription: Subscription;
  public orgLevelId: number;
  public configForceLoad: ConfigForceLoad = null;

  constructor(private modalService: ModalService,
    public reportApi: ReportsApiService,
    public notificationService: NotificationsService) {
    this.configForceLoad = new ConfigForceLoad();
    this.configForceLoad.dailyStaffingPosting = false;
    this.orgLevelSubscription = this.orgLevel$.subscribe((data) => {
      this.orgLevelId = data.id;
    });
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    if (this.slxBIPAReport.reportConfigId) {
      this.getApiData();
    }
  }

  public getApiData() {
    // To Be Done once API work completed
    let gridData: BIPAReportDefinition = new BIPAReportDefinition();
    this.reportApi.getDailyStaffingSettings(this.orgLevelId, true).then((data: GetBIPAReportDefinition) => {
      if (data) {
        gridData.unitShiftData = [data.showUnitsOnBipaReportValue, data.showShiftsOnBipaReportValue];
        gridData.shiftGroupTimingS = this.setShiftGroup(data.shiftgroups);
        gridData.gridData = this.setGridData(data.slxCmsPositions);
        BipaReportDialogComponent.openDialog(gridData, this.configForceLoad, this.modalService, (isSave: boolean, result: BIPAReportDefinition) => {
          if (isSave) {
            this.configForceLoad.dailyStaffingPosting = true;
            let data: BIPAUnitShiftData = new BIPAUnitShiftData();
            data.showUnitsOnBipaReportKey = 'ShowUnitsOnBipaReport';
            data.showUnitsOnBipaReportValue = `${result.unitShiftData[0]}`;
            data.showShiftsOnBipaReportKey = 'ShowShiftsOnBipaReport';
            data.showShiftsOnBipaReportValue = `${result.unitShiftData[1]}`;
            this.reportApi.postDailyStaffingSettings(this.orgLevelId, data).then((data) => {
              if (data.data === 'Settings Saved successfully') {
                this.notificationService.success('Success', data.data);
              }
            });
          }
          else {
            this.configForceLoad.dailyStaffingPosting = false;
          }
        });
      }
    });
  }

  public setShiftGroup(data: BIPAShiftGroupTime[]) {
    let dataOutput = [];
    if (data.length > 0) {
      _.forEach(data, (item) => {
        if (item.shiftType === 'Day') {
          dataOutput.push(item.shiftStartTime);
        }
        if (item.shiftType === 'Evening') {
          dataOutput.push(item.shiftStartTime);
        }
        if (item.shiftType === 'Night') {
          dataOutput.push(item.shiftStartTime);
        }
      })
    }
    return dataOutput;
  }

  public setGridData(data: BIPATableData[]) {
    let outputData = [];
    if (data.length > 0) {
      _.map(data, (item) => {
        outputData.push({
          smartlinxPosition: item.slxPosition,
          cmsJobDescription: item.cmsJobDescription
        });
      });
    }
    return outputData;
  }

}
