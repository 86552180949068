import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';
import { dateTimeUtils } from '../../../common/utils/index';

import {
  EmployeeDefinition, Position
} from '../../../organization/models/index';

import {
  IExceptionConsoleRecord, ExceptionConsoleRecord, ExceptionConsoleContainer,
  IExceptionConsoleRollupRecord, ExceptionConsoleRollupRecord, IExceptionConsoleContainer
} from '../../models/index';
import { IExceptionColumn, ExceptionColumn } from '../../models/exception-console/exception-column';
import { ExceptionRecord, IExceptionRecord } from '../../models/exception-console/exception-record';
import { IExceptionConsoleRollupContainer, ExceptionConsoleRollupContainer } from '../../models/exception-console/exceptions-console-rollup-container';
import { TimecardsCheckRightsService } from '../timecards/timecards-check-rights.service';

@Injectable()
export class ExceptionConsoleMapService {

  private lookupMapService: LookupMapService;
  private employeeDefinitionsMapService: EmployeeDefinitionsMapService;

  constructor(lookupMapService: LookupMapService, employeeDefinitionsMapService: EmployeeDefinitionsMapService,
    private timecardsCheckService: TimecardsCheckRightsService) {
    this.lookupMapService = lookupMapService;
    this.employeeDefinitionsMapService = employeeDefinitionsMapService;
  }

  public mapExceptions(exceptionsDtos: IExceptionRecord[], columns: ExceptionColumn[]): ExceptionRecord[] {
    let exceptions = _.map(columns, (c: ExceptionColumn) => {
      let record: ExceptionRecord = new ExceptionRecord();
      record.exceptionId = c.exceptionId;
      record.exceptionName = c.exceptionName;
      let exRecordDto: IExceptionRecord = _.find(exceptionsDtos, (er: IExceptionRecord) => {
        return er.exceptionId === record.exceptionId;
      });
      if (exRecordDto) {
        record.exceptionCount = exRecordDto.exceptionCount;
      } else {
        record.exceptionCount = 0;
      }
      return record;
    });
    return exceptions;
  }

  public createExceptionMap(exceptions: ExceptionRecord[]): StringMap<ExceptionRecord> {
    let exceptionsMap: StringMap<ExceptionRecord> = _.keyBy(exceptions, (er: ExceptionRecord) => {
      return er.exceptionId;
    });
    return exceptionsMap;
  }

  public mapExceptionColumns(dtos: IExceptionColumn[]): ExceptionColumn[] {
    return _.map(dtos, (dto: IExceptionColumn) => {
      return this.mapExceptionColumn(dto);
    });
  }

  public mapExceptionColumn(dto: IExceptionColumn): ExceptionColumn {
    let col: ExceptionColumn = new ExceptionColumn();
    col.exceptionId = dto.exceptionId;
    col.exceptionName = dto.exceptionName;
    return col;
  }

  public mapExceptionConsoleRecord(dto: IExceptionConsoleRecord, columns: ExceptionColumn[]): ExceptionConsoleRecord {

    Assert.isNotNull(dto, 'IExceptionConsoleRecord');

    let data: ExceptionConsoleRecord = new ExceptionConsoleRecord();
    data.employee = this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee);
    data.position = this.lookupMapService.mapPosition(dto.position);
    data.totalExceptionsCount = dto.totalExceptionsCount;
    data.pendingMissingPunchesCount = dto.pendingMissingPunchesCount;
    data.approvedMissingPunchesCount = dto.approvedMissingPunchesCount;
    data.isSelectable = dto.isSelectable;
    data.exceptions = this.mapExceptions(dto.exceptions, columns);
    data.exceptionsMap = this.createExceptionMap(data.exceptions);
    data.canModifyOwnTimecard = this.timecardsCheckService.userCanApproveOwnTimecard(data.employee.id);
    data.payCycle = this.lookupMapService.mapPayCycle(dto.payCycle);
    this.createExceptionFields(data.exceptions, data);
    data.isOrganizationPayrollLocked = dto.isOrganizationPayrollLocked;
    data.isPayCycleLocked = dto.isPayCycleLocked;
    data.isTimecardLocked = dto.isTimecardLocked;
    return data;
  }

  public mapExceptionConsoleContainer(dto: IExceptionConsoleContainer): ExceptionConsoleContainer {

    Assert.isNotNull(dto, 'IExceptionConsoleContainer');

    let container: ExceptionConsoleContainer = new ExceptionConsoleContainer();
    let columns: ExceptionColumn[] = this.mapExceptionColumns(dto.exceptions);
    container.exceptionColumns = columns;
    container.records = _.map(dto.records, (dto: IExceptionConsoleRecord) => {
      return this.mapExceptionConsoleRecord(dto, columns);
    });
    return container;
  }

  public mapExceptionConsoleRollupRecord(dto: IExceptionConsoleRollupRecord, columns: ExceptionColumn[]): ExceptionConsoleRollupRecord {
    let record: ExceptionConsoleRollupRecord = new ExceptionConsoleRollupRecord();
    record.organization = dto.organization ? this.lookupMapService.mapOrganization(dto.organization) : null;
    record.department = dto.department ? this.lookupMapService.mapDepartment(dto.department) : null;
    record.payCycle = this.lookupMapService.mapPayCycle(dto.payCycle);
    record.totalExceptionsCount = dto.totalExceptionsCount;
    record.pendingMissingPunchesCount = dto.pendingMissingPunchesCount;
    record.approvedMissingPunchesCount = dto.approvedMissingPunchesCount;
    record.exceptions = this.mapExceptions(dto.exceptions, columns);
    record.exceptionsMap = this.createExceptionMap(record.exceptions);
    this.createExceptionFields(record.exceptions, record);
    return record;
  }

  public mapExceptionConsoleRollupContainer(dto: IExceptionConsoleRollupContainer): ExceptionConsoleRollupContainer {
    let container: ExceptionConsoleRollupContainer = new ExceptionConsoleRollupContainer();
    let columns: ExceptionColumn[] = this.mapExceptionColumns(dto.exceptions);
    container.exceptionColumns = columns;
    let records: ExceptionConsoleRollupRecord[] = [];
    _.forEach(dto.records, (dto: IExceptionConsoleRollupRecord) => {
      let record: ExceptionConsoleRollupRecord = this.mapExceptionConsoleRollupRecord(dto, columns);
      records.push(record);
    });
    container.records = records;
    container.organization = dto.organization ? this.lookupMapService.mapOrganization(dto.organization) : null;
    return container;
  }

  private createExceptionFields(exceptions: ExceptionRecord[], target: any): void {
    _.each(exceptions, (r: ExceptionRecord) => {
      let fieldName: string = r.exceptionName + r.exceptionId;
      fieldName = fieldName.replace(/\s+/g, '');
      Object.defineProperty(target, fieldName, {
        get: () => {
          return r.exceptionCount;
        }
      });
    });
  }

}

