import { Component, OnInit, Renderer2, ElementRef, Input } from '@angular/core';
import { WeatherSettings, TemperatureScale, ForecastMode, WeatherLayout } from '../../../app-modules/angular-weather-widget/index';
import { commonConfig } from '../../common.config';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { OrgLevelLocation } from '../../../state-model/models/index';
import { screenUtils, IScreenUtils } from '../../utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-weather-widget',
  styleUrls: ['weather-widget.component.scss'],
  templateUrl: 'weather-widget.component.html'
})
export class WeatherWidgetComponent {

  @Input()
  public set orgLevelLocation(location: OrgLevelLocation) {
    this.weatherExists = !!location;
    if (!this.weatherExists) {
      return;
    }
    this.currentState = location.stateAbbreviation;
    this.zipCode = location.zipCode;
    this.onUpdate();
  }

  public settings: WeatherSettings;
  public weatherExists: boolean;
  public screenUtils: IScreenUtils;
  public zipCode: string;
  private currentState: string;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.settings = commonConfig.weatherWidgetSettings;
    this.screenUtils = screenUtils;
  }

  public onUpdate(): void {
    if (this.zipCode) {
      this.settings.location = {};
      this.settings.location.stateAbbreviation = this.currentState;
      this.settings.location.zipCode = this.zipCode;
      this.settings = Object.assign({}, this.settings);
    }
  }
}
