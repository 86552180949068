import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { UserProfileNavigationService } from '../../../../common/services/index';
import { RolesWithUsersContainerDecorator } from '../../../models/index';

@Component({
    moduleId: module.id,
    selector: 'slx-user-roles-assignement-action-bar',
    templateUrl: 'user-roles-assignement-action-bar.component.html',
    styleUrls: ['user-roles-assignement-action-bar.component.scss']
})

export class UserRolesAssignementActionBarComponent implements OnInit {

  @Input()
  public rolesDecorator: RolesWithUsersContainerDecorator;
  @Input()
  public filterString: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) {
  }

  public ngOnInit(): void {
    this.filterString = this.route.snapshot.queryParams['search'];
  }

  public onFilterChanged(): void {
    let navigation: UserProfileNavigationService = new UserProfileNavigationService (this.router, this.route, this.location);
    navigation.navigateToUserSearch(this.filterString);
  }

  public addUserClick(): void {
    let navigation: UserProfileNavigationService = new UserProfileNavigationService (this.router, this.route, this.location);
    navigation.navigateCreateNewUser();
  }
}
