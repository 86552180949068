import * as tslib_1 from "tslib";
import { EmployeeSearchBase } from '../../../employee/employee/services/index';
import { Lookup, LookupType } from '../../../organization/models/index';
import { LookupService } from '../../../organization/services/index';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
var PayCycleEmployeeProvider = /** @class */ (function (_super) {
    tslib_1.__extends(PayCycleEmployeeProvider, _super);
    function PayCycleEmployeeProvider(lookupService) {
        var _this = _super.call(this) || this;
        _this.lookupService = lookupService;
        _this.employees$ = new Subject();
        _this.requestsParams = new Map();
        return _this;
    }
    Object.defineProperty(PayCycleEmployeeProvider.prototype, "startDate", {
        get: function () {
            return this.m_startDate;
        },
        set: function (v) {
            if (_.isDate(v)) {
                this.m_startDate = v;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleEmployeeProvider.prototype, "endDate", {
        get: function () {
            return this.m_endDate;
        },
        set: function (v) {
            if (_.isDate(v)) {
                this.m_endDate = v;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayCycleEmployeeProvider.prototype, "orgLevelEmployees", {
        get: function () {
            return this.m_orgLevelEmployees;
        },
        set: function (orgLevelEmployees) {
            this.m_orgLevelEmployees = orgLevelEmployees;
        },
        enumerable: true,
        configurable: true
    });
    PayCycleEmployeeProvider.prototype.Load = function () {
        this.runLoadingEmployees();
    };
    PayCycleEmployeeProvider.prototype.subscribeToEmployees = function (callback) {
        return this.employees$.subscribe(callback);
    };
    PayCycleEmployeeProvider.prototype.getEmployees = function (orgLevelId, isActive) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                if (_.isDate(this.startDate) && _.isDate(this.endDate)) {
                    this.loadEmployees(orgLevelId, this.startDate, this.endDate);
                }
                return [2 /*return*/, this.saveRequestParams(orgLevelId)];
            });
        });
    };
    PayCycleEmployeeProvider.prototype.loadEmployees = function (orgLevelId, startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var lookupvalues, lookup;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(_.size(this.orgLevelEmployees) > 0)) return [3 /*break*/, 1];
                        lookupvalues = this.createLookup(this.orgLevelEmployees, 'name', 'id');
                        this.employees$.next(lookupvalues);
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.lookupService.getLookup({
                            lookupType: LookupType.employeesListByActiveDateRange,
                            orgLevelId: orgLevelId,
                            dateRange: { startDate: startDate, endDate: endDate }
                        })];
                    case 2:
                        lookup = _a.sent();
                        this.employees$.next(lookup);
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PayCycleEmployeeProvider.prototype.saveRequestParams = function (orgLevelId) {
        if (this.requestsParams.has(orgLevelId)) {
            var params_1 = this.requestsParams.get(orgLevelId);
            return params_1.pendingEmployeesPromise;
        }
        var params = { pendingEmployeesPromise: null, resolveEmployeesPromise: null };
        params.pendingEmployeesPromise = new Promise(function (res) { return (params.resolveEmployeesPromise = res); });
        this.requestsParams.set(orgLevelId, params);
        return params.pendingEmployeesPromise;
    };
    PayCycleEmployeeProvider.prototype.createLookup = function (items, titleField, valueField) {
        var lookup = new Lookup();
        lookup.titleField = titleField;
        lookup.valueField = valueField;
        lookup.items = items;
        return lookup;
    };
    PayCycleEmployeeProvider.prototype.runLoadingEmployees = function () {
        var _this = this;
        if (_.isDate(this.startDate) && _.isDate(this.endDate) && this.requestsParams.size > 0) {
            this.requestsParams.forEach(function (params, orgLevelId) {
                var promise = _this.loadEmployees(orgLevelId, _this.startDate, _this.endDate);
                params.resolveEmployeesPromise(promise);
            });
            this.requestsParams.clear();
        }
    };
    return PayCycleEmployeeProvider;
}(EmployeeSearchBase));
export { PayCycleEmployeeProvider };
