/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-cropper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./photo-cropper.component";
var styles_PhotoCropperComponent = [i0.styles];
var RenderType_PhotoCropperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PhotoCropperComponent, data: {} });
export { RenderType_PhotoCropperComponent as RenderType_PhotoCropperComponent };
export function View_PhotoCropperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { targetElem: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["targetElem", 1]], null, 0, "div", [["title", "Use mouse scroll to zoom in and zoom out image"]], null, null, null, null, null))], null, null); }
export function View_PhotoCropperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-photo-cropper", [], null, null, null, View_PhotoCropperComponent_0, RenderType_PhotoCropperComponent)), i1.ɵdid(1, 638976, null, 0, i2.PhotoCropperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoCropperComponentNgFactory = i1.ɵccf("slx-photo-cropper", i2.PhotoCropperComponent, View_PhotoCropperComponent_Host_0, { cropperOptions: "cropperOptions", imageUrl: "imageUrl" }, {}, []);
export { PhotoCropperComponentNgFactory as PhotoCropperComponentNgFactory };
