import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import * as _ from 'lodash';
import { isDestroyed } from '../../../core/decorators/index';
var DropdownInputComponent = /** @class */ (function () {
    function DropdownInputComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.disabled = false;
        this.m_items = [];
        this.onTouchedCallback = _.noop;
        this.onChangeCallback = _.noop;
        this.valueField = 'id';
        this.titleField = 'name';
    }
    Object.defineProperty(DropdownInputComponent.prototype, "options", {
        set: function (items) {
            this.items = items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownInputComponent.prototype, "emptyOption", {
        get: function () {
            return this.m_emptyOption;
        },
        set: function (value) {
            this.m_emptyOption = value;
            if (this.autoSelectFirst && !this.selectedValue) {
                this.selectFirstValue();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownInputComponent.prototype, "autoSelectFirst", {
        get: function () {
            return this.m_autoSelectFirst;
        },
        set: function (value) {
            this.m_autoSelectFirst = value;
            if (value && !this.selectedValue) {
                this.selectFirstValue();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownInputComponent.prototype, "disabledOption", {
        get: function () {
            return this.m_disabledOption;
        },
        set: function (value) {
            if (_.has(value, this.valueField)) {
                this.m_disabledOption = value[this.valueField];
                return;
            }
            this.m_disabledOption = null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownInputComponent.prototype, "items", {
        get: function () {
            return this.m_items;
        },
        set: function (value) {
            if (this.isDestroyed) {
                return;
            }
            this.m_items = value;
            if (this.selectedValue && this.selectedValue !== this.emptyOption) {
                this.silenceRefresh(this.selectedValue[this.valueField]);
                return;
            }
            if (this.autoSelectFirst && !this.selectedValue) {
                this.selectFirstValue();
            }
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownInputComponent.prototype, "selectedValue", {
        get: function () {
            return this.m_selectedValue;
        },
        set: function (value) {
            this.m_selectedValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownInputComponent.prototype, "showEmptyOption", {
        get: function () {
            return !_.isUndefined(this.emptyOption) && !_.isNull(this.emptyOption);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownInputComponent.prototype, "internalValue", {
        get: function () {
            return this.m_internalValue;
        },
        set: function (value) {
            this.m_internalValue = value;
        },
        enumerable: true,
        configurable: true
    });
    DropdownInputComponent.prototype.writeValue = function (value) {
        if (this.isDestroyed) {
            return;
        }
        if (!value && this.emptyOption) {
            value = this.emptyOption;
        }
        this.internalValue = value ? value === this.emptyOption ? this.emptyOption : value[this.valueField] : null;
        this.selectedValue = value;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    DropdownInputComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = function (value) { Promise.resolve(null).then(function () { return fn(value); }); };
    };
    DropdownInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    DropdownInputComponent.prototype.silenceRefresh = function (selectChange) {
        var _a;
        var foundValue = _.find(this.items, (_a = {}, _a[this.valueField] = selectChange, _a));
        if (foundValue) {
            this.writeValue(foundValue);
        }
    };
    DropdownInputComponent.prototype.change = function (selectChange) {
        var _a;
        if (selectChange && selectChange === this.emptyOption) {
            this.writeValue(this.emptyOption);
            this.onChangeCallback(undefined);
            return;
        }
        var foundValue = _.find(this.items, (_a = {}, _a[this.valueField] = selectChange, _a));
        if (foundValue) {
            this.writeValue(foundValue);
            this.onChangeCallback(foundValue);
        }
    };
    DropdownInputComponent.prototype.focus = function () {
        this.select.nativeElement.focus();
    };
    DropdownInputComponent.prototype.selectFirstValue = function () {
        if (this.emptyOption) {
            this.change(this.emptyOption);
        }
        else if (this.items && this.items.length > 0) {
            var firstValue = _.first(this.items);
            this.change(firstValue[this.valueField]);
        }
    };
    tslib_1.__decorate([
        isDestroyed(),
        tslib_1.__metadata("design:type", Boolean)
    ], DropdownInputComponent.prototype, "isDestroyed", void 0);
    return DropdownInputComponent;
}());
export { DropdownInputComponent };
