import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, SimpleChanges } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';

import { Assert } from '../../../../../framework/index';

import { IEmployeeSectionsSelfService, EmployeeSectionsSelfService, EmployeeSectionsTemporalModel, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { FieldData } from '../../../../../core/models';
import { EmployeeSelfServiceApiService } from '../../../../employee/services/index';
import { ConfirmDialogComponent, InfoDialogComponent } from '../../../../../common/components/index';
import { ModalService } from '../../../../../common/index';
import { EssLookupManageService } from '../../../../../organization/services/lookup/ess-lookup-manage.service';
import { EssEntity, EmployeeShortInfo } from '../../../../../organization/models/index';
import { EmployeeDefinitionsApiService } from '../../../../../organization/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-self-service',
  templateUrl: 'employee-sections-self-service.component.html',
  styleUrls: ['employee-sections-self-service.component.scss']
})
export class EmployeeSectionsSelfServiceComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input() public employeeId: number;
  @Input()
  public set employeeSectionsSelfService(value: EmployeeSectionsSelfService) {
    this.m_employeeSectionsSelfService = value;
    this.fillValues();
  }

  public get employeeSectionsSelfService(): EmployeeSectionsSelfService {
    return this.m_employeeSectionsSelfService;
  }

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }
  @ViewChild('form', {static: false})
  public ngForm: NgForm;

  public passwordType: EssEntity;
  public timeMethod: EssEntity;
  private m_employeeSectionsSelfService: EmployeeSectionsSelfService = new EmployeeSectionsSelfService();

  constructor(
    ngZone: NgZone,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    private modalService: ModalService,
    private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    private employeeSelfServiceApiService: EmployeeSelfServiceApiService,
    public essLookupService: EssLookupManageService,
    private employeeDefinitionApi: EmployeeDefinitionsApiService

  ) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
    this.essLookupService.onLoaded$.subscribe((value: any) => {
      this.fillValues();
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsSelfService;
  }

  public canSave(): Promise<boolean> {
    if (!this.employeeSectionsSelfService.hasEssAccount || !this.employeeSectionsSelfService.password) {
      return this.checkSsn();
    }

    let promise = new Promise<boolean>((resolve) => {
      ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to override the existing password?', this.modalService, (confirmed: boolean) => {
        resolve(confirmed ? this.checkSsn() : false);
      });
    });

    return promise;
  }



  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmploymentSelfService(this.employeeId)
      .then((employeeSectionsSelfService: EmployeeSectionsSelfService) => {
        this.employeeSectionsSelfService = employeeSectionsSelfService;
        this.stopProgress();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected doSave(): void {
    let promise;
    this.employeeSectionsSelfService.password = this.passwordType ? this.passwordType.id : null;
    this.employeeSectionsSelfService.enterTimeMethod = this.timeMethod ? this.timeMethod.id : null;
    if (this.employeeSectionsSelfService.isEssEnabled) {
      promise = this.employeeSelfServiceApiService.createESSPolicy(
        [this.employeeId],
        this.employeeSectionsSelfService.password,
        this.employeeSectionsSelfService.enterTimeMethod,
        this.employeeSectionsSelfService.availableOptions
      );
    } else {
      promise = this.employeeSelfServiceApiService.deleteESSPolicy(
        [this.employeeId]
      );
    }

    promise.then((response: any) => {
      this.onActionComplete(true);
    }).catch((reason: any) => {
      this.onActionError(reason);
    });
  }

  private fillValues(): void {
    if (this.employeeSectionsSelfService) {
      this.timeMethod = this.essLookupService.getTimeMethod(this.employeeSectionsSelfService.enterTimeMethod);
      this.passwordType = this.essLookupService.getPasswordType(this.employeeSectionsSelfService.password);
    }
  }

  private checkSsn(): Promise<boolean> {
    if (this.passwordType && this.passwordType.id === 'SSNLast4Digits') {
      return this.employeeDefinitionApi.getEmployeeShortInfo(this.employeeId)
        .then((value: EmployeeShortInfo) => {
          if (value.ssn) {
            return Promise.resolve(true);
          } else {
            InfoDialogComponent.OpenDialog('Create ESS account',
              'Can not create ESS Account with selected password type, when employee doesn\'t have SSN',
              this.modalService);
            return Promise.resolve(false);
          }

        }).catch((reason: any) => {
          return Promise.resolve(false);
        });
    }
    return Promise.resolve(true);
  }

}
