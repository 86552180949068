import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-unavailability-notification',
  templateUrl: 'unavailability-notification.component.html',
  styleUrls: ['unavailability-notification.component.scss']
})
export class UnavailabilityNotificationComponent implements OnInit {
  @Input('availableLevels')
  public levels: OrgLevelType[];

  public availableLevels: string;

  constructor() {
    this.availableLevels = 'another';
  }

  public ngOnInit(): void {
    if (_.size(this.levels) > 0) {
      this.availableLevels = this.levels.join(', ');
    }
  }
}
