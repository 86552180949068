import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { LmNavigationService } from '../../services/index';

@Directive({
  selector: '[lmRosterLink]',
})
export class LmRosterLinkDirective {
  @Input('lmRosterLink')
  public category: string;

  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: LmNavigationService = new LmNavigationService(this.router, this.route);
    navService.navigateToLmRoster(undefined, this.category);
  }
}
