import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const PHONE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PhoneValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[phone][formControlName],[phone][formControl],[phone][ngModel]',
  /* tslint:enable */
  providers: [PHONE_VALIDATOR]
})
export class PhoneValidator implements Validator {
  public validate(c: AbstractControl): { [key: string]: any } {
    if (c.dirty) {
      return CommonValidators.phone(c);
    }
    return null;
  }
}
