<div class="ess-template-section">
  <div class="ess-template-section-header">
    <div class="ess-template-section-header-content">
      <div class="header-item header-title">
        <span class="header-title__text">{{dirtyTemplate?.essTemplateDefinition?.name}}</span>
      </div>
    </div>
    <div class="ess-template-section-header-button-right">
      <slx-collapse-button [(isCollapsed)]="isCollapsed" className="slx-white-icon"
        (isCollapsedChange)="collapsedChange($event)" [slxCollapseByCss]="collapseContent"></slx-collapse-button>
    </div>
  </div>
  <div #collapseContent class="ess-template-section-content">
    <form #form="ngForm">
      <div class="ess-template-section-content__header">
        <button *ngIf="hasChanges" class="slx-button slx-with-icon discard-btn" (click)="discard()">
          <i class="slx-button__icon far fa-ban"></i> Discard
        </button>
        <button *ngIf="!dirtyTemplate?.essTemplateDefinition?.isDefault" class="slx-button slx-with-icon delete-btn"
          (click)="delete()">
          <i class="slx-button__icon fas fa-trash"></i> Delete
        </button>
        <button class="slx-button slx-with-icon save-btn" [disabled]="form.invalid" (click)="save()">
          <i class="slx-button__icon fas fa-save"></i> Save
        </button>
      </div>

      <div class="ess-template-section-content__editor">
        <div class="editor_template_props">

          <slx-input-decorator className="slx-label-padding05" *ngIf="!dirtyTemplate?.essTemplateDefinition?.isDefault">
            <input #templateName slx-input placeholder="Template Name" id="name" name="name"
                   [(ngModel)]="dirtyTemplate?.essTemplateDefinition.name" (ngModelChange)="notifyChanges()" required
                   type="text" [prohibitedValues]="prohibitedNameValues">
            <span errorMessage for="required"></span>
            <span errorMessage for="prohibitedValues">Name should be unique</span>
          </slx-input-decorator>
          <slx-dropdown-list class="slx-wide slx-short slx-middle-label" name="timeEntryMethod"
                             placeholder="Default Password" [options]="defaultPasswords" [valueField]="'id'" [titleField]="'name'"
                             [valuePrimitive]="false" [(ngModel)]="defaultPwdTemplate" (ngModelChange)="setDefaultPwdTemplate()">
          </slx-dropdown-list>

          <slx-dropdown-list class="slx-wide slx-short slx-middle-label" name="attestationGroup"
                             placeholder="Attestation Groups" [options]="punchAttestationGroups" [valueField]="'id'" [titleField]="'groupName'"
                             [valuePrimitive]="false" [(ngModel)]="selectedPunchAttestationGroup" (ngModelChange)="setPunchAttestationGroup()" [defaultItem]="defaultItem">
          </slx-dropdown-list>
        </div>
        <div class="editor_option-group" *ngFor="let group of dirtyTemplate?.groups">
          <div class="group-name">{{group.name}}</div>
          <div class="template-options">
            <div *ngFor="let option of group.options">
              <slx-ess-template-option [template]="dirtyTemplate" [option]="option" (valueChange)="notifyChanges()"></slx-ess-template-option>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
