/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pbj-new-export-warning-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pbj-new-export-warning-dialog.component";
import * as i3 from "../../../../../../common/models/dialog-options";
import * as i4 from "../../../../../../common/services/modal/modal.service";
var styles_PbjNewExportWarningDialogComponent = [i0.styles];
var RenderType_PbjNewExportWarningDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PbjNewExportWarningDialogComponent, data: {} });
export { RenderType_PbjNewExportWarningDialogComponent as RenderType_PbjNewExportWarningDialogComponent };
export function View_PbjNewExportWarningDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["One or more selected locations are missing required configuration settings. Please update the necessary configuration prior to running the export."])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Click "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "list-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.DownloadPbjExportIssues() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["here"])), (_l()(), i1.ɵted(-1, null, [" for more details."]))], null, null); }
export function View_PbjNewExportWarningDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-pbj-new-export-warning-dialog", [], null, null, null, View_PbjNewExportWarningDialogComponent_0, RenderType_PbjNewExportWarningDialogComponent)), i1.ɵdid(1, 114688, null, 0, i2.PbjNewExportWarningDialogComponent, [i3.DialogOptions, i4.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PbjNewExportWarningDialogComponentNgFactory = i1.ɵccf("slx-pbj-new-export-warning-dialog", i2.PbjNewExportWarningDialogComponent, View_PbjNewExportWarningDialogComponent_Host_0, {}, {}, []);
export { PbjNewExportWarningDialogComponentNgFactory as PbjNewExportWarningDialogComponentNgFactory };
