import { ShiftEligibleEmployee, IShiftEligibleEmployee } from './shift-eligible-employee';

export interface IShiftReplacementInfo {
  records: IShiftEligibleEmployee[];
  canSendSms: boolean;
}

export class ShiftReplacementInfo {
  /*
  public static mock: IShiftReplacementInfo = {
    records: [
      {
        employeeId: 1, employeeName: 'Employee1', homePhone: '(111)000-2256', mobilePhone: '222', altPhone: '555',
        isPhoneCalled: true,
        isSmsSent: true,
        isAppNotificationSent: false,
        smsResponse: { phoneNumber: '', responseText: 'No1' },
        appResponse: {responseText: 'Yes1' },
        isAgency: true,
        isAvailable: false,
        isWorkingOtherShift: false,
        isDayOff: false,
        phoneCall: {
          phoneNumber: '',
          notes: 'bla bla'
        }
      },
      { employeeId: 2, employeeName: 'Employee2', phoneCall: null, homePhone: '11', mobilePhone: '222', altPhone: '555',
        isPhoneCalled: false,
        isSmsSent: true,
        isAppNotificationSent: false,
        smsResponse: null,
        appResponse: null,
        isAgency: false,
        isAvailable: true,
        isWorkingOtherShift: false,
        isDayOff: false,
      },
      { employeeId: 3, employeeName: 'Employee3', phoneCall: null, homePhone: '11', mobilePhone: '222', altPhone: '555',
        isPhoneCalled: false,
        isSmsSent: true,
        isAppNotificationSent: false,
        smsResponse: null,
        appResponse: null,
        isAgency: false,
        isAvailable: false,
        isWorkingOtherShift: true,
        isDayOff: false,
      },
      { employeeId: 4, employeeName: 'Employee4', homePhone: '11', mobilePhone: '222', altPhone: '555',
        isPhoneCalled: true,
        isSmsSent: true,
        isAppNotificationSent: false,
        smsResponse: { phoneNumber: '', responseText: 'No4' },
        appResponse: {responseText: 'Yes of course4 dffgdfs dsfkjfdogfso udiuyiuy iuyiuy iuyi iuyiy iusdafadif ' },
        isAgency: false,
        isAvailable: false,
        isWorkingOtherShift: false,
        isDayOff: true,
        phoneCall: {
          phoneNumber: '',
          notes: 'bla bla'
        }
      },
      { employeeId: 5, employeeName: 'Employee5',  homePhone: '11', mobilePhone: '222', altPhone: '555',
        isPhoneCalled: true,
        isSmsSent: true,
        isAppNotificationSent: false,
        smsResponse: { phoneNumber: '', responseText: 'No5' },
        appResponse: {responseText: 'No55' },
        isAgency: true,
        isAvailable: true,
        isWorkingOtherShift: true,
        isDayOff: true,
        phoneCall: {
          phoneNumber: '',
          notes: 'bla bla'
        }
      },
    ]
  };*/
  public records: ShiftEligibleEmployee[];
  public canSendSms: boolean;
}
