import { BenefitGroup } from '../../../../organization/models';
import { BenefitPlanShortInfo } from './benefit-plan-short-info';
import { BenefitsEmpEnrollOptionRate } from './benefit-emp-enrollment-option-rate';
import { BenefitsEmpEnrollmentFlat } from './benefit-emp-enrollment-flat';
import { BenefitEmpEnrollmentFormula } from './benefit-emp-enrollment-formula';
import { BenefitEmpEnrollment401k } from './benefit-emp-enrollment-401k';

export class NewEnrollmentModel {
    
    
    public get activeEnrollments(): number[] {
        return this.m_activeEnrollments;
    }
    
    public get effectiveDate(): Date {
        return this.m_effectiveDate;
    }

    public get canMapPayroll(): boolean {
        return this.m_canMapPayroll;
    }
    
    public set canMapPayroll(value: boolean) {
        this.m_canMapPayroll = value;
    }
    public orgLevelId: number;

    public empId: number;
    public group: BenefitGroup;
    public plan: BenefitPlanShortInfo;
    
    public selectedCoverageOption: BenefitsEmpEnrollOptionRate;
    public flatSettings: BenefitsEmpEnrollmentFlat;
    public formulaSettings: BenefitEmpEnrollmentFormula;
    public settings401k: BenefitEmpEnrollment401k;
    
    private m_activeEnrollments: number[];
    private m_effectiveDate: Date;
    private m_canMapPayroll: boolean;

    constructor(activeEnrollments: number[], effectiveDate: Date, canMapPayroll: boolean) {
        this.m_activeEnrollments = activeEnrollments;
        this.m_effectiveDate = effectiveDate;
        this.m_canMapPayroll = canMapPayroll
    }
}
