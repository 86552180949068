import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { MomentModule } from 'angular2-moment';
import { NgxPopperModule } from 'ngx-popper';
import { OrganizationModule } from '../../organization/index';
import { CommonModule as CommonAppModule } from '../../common/index';
import { ComponentsLibraryModule } from '../../components-library/index';

import { components, entryAuditTrailComponents } from './components/index';


import { services } from './services/index';

@NgModule({
  imports: [
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    MomentModule,
    ExcelModule,
    PDFModule,
    GridModule,
    DropDownsModule,
    OrganizationModule,
    CommonAppModule,
    ComponentsLibraryModule,
    NgxPopperModule
  ],
  declarations: [
    ...components
  ],
  entryComponents: [
    ...entryAuditTrailComponents
  ],
  providers: [
    ...services
  ],
  exports: [
    ...components
  ]
})
export class AuditTrailModule { }
