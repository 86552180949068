<div class="notification-button-container">
  <button type="button" class="header-button" 
  (keyup)="onKeyup($event)" [popper]="popperContent" [popperTrigger]="'click'"
    [popperPlacement]="popperPosition" [popperDisableStyle]="'true'" 
    [popperModifiers]="popperModifiers" (popperOnShown)="onShown($event)"
    (popperOnHidden)="onHidden($event)">
    <i *ngIf="notificationType == notificationTypes.MESSAGES" class="far fa-envelope-open" aria-hidden="true"></i>
    <i *ngIf="notificationType == notificationTypes.REMINDERS" class="fa fa-bell" aria-hidden="true"></i>
    <span *ngIf="notificationsCount > 0" class="notification-badge">{{notificationsCount}}</span>
  </button>

  <popper-content #popperContent>
    <div class="notification-popup-body">
      <slx-header-notification-popper-content>
        <div class="notification-bar" *ngIf="notificationType == notificationTypes.MESSAGES">
          <span class="toolbar-title"><span class="counter">{{notificationsCount}}</span>New Messages</span>
        </div>
        <div class="notification-bar" *ngIf="notificationType == notificationTypes.REMINDERS">
          <span class="toolbar-title"><span class="counter">{{notificationsCount}}</span>Reminders</span>
        </div>
        <slx-header-reminders-list class="notification-list" 
        *ngIf="notificationType == notificationTypes.REMINDERS && popupShown">
      </slx-header-reminders-list>
        <slx-header-messages-list class="notification-list" 
        *ngIf="notificationType == notificationTypes.MESSAGES && popupShown">
      </slx-header-messages-list>
      </slx-header-notification-popper-content>
    </div>
  </popper-content>
</div>