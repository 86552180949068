import { IDialog } from '../../models/dialog-options';
import { Component, OnInit, Provider, Type } from '@angular/core';
import { ModalService } from '../../services/index';
import { DialogOptions, InfoPreloaderParams } from '../../models/index';
import { Assert } from '../../../framework/index';


export const spinnerClassesCount: number = 14;

@Component({
    moduleId: module.id,
    selector: 'slx-info-preloader-dialog',
    templateUrl: 'info-preloader-dialog.component.html',
    styleUrls: ['info-preloader-dialog.component.scss']
})
export class InfoPreloaderDialogComponent implements IDialog, OnInit {

    public params: InfoPreloaderParams;
    public dialogResult: boolean;

    public className: string = 'spinner-animation-1';


    public static openDialog(params: InfoPreloaderParams, modalService: ModalService, callback: (result: boolean) => void): InfoPreloaderDialogComponent {

        let dialogOptions: DialogOptions = new DialogOptions();
        dialogOptions.width = 570;
        dialogOptions.hideTitleBar = true;
        dialogOptions.showCloseButton = false;
        dialogOptions.displayBlock = true;
        dialogOptions.fullHeightOnMobile = true;

        params.spinnerIndex = params.spinnerIndex || Math.round(Math.random() * (spinnerClassesCount - 1)) + 1;

        let resolvedProviders: Provider[] = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: InfoPreloaderParams,
                useValue: params
            }
        ];

        let dialog: InfoPreloaderDialogComponent = modalService.globalAnchor
            .openDialog(InfoPreloaderDialogComponent, null, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
                callback(result);
            });

        return dialog;
    }

    constructor(params: InfoPreloaderParams, private options: DialogOptions, private modalService: ModalService) {
        Assert.isNotNull(params, 'params');
        this.params = params;
    }

    public ngOnInit(): void {
        this.className = 'spinner-animation-' + this.params.spinnerIndex;
    }

    public onOk(): void {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }

    public onCancel(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }
}
