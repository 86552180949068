import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';
import { IDialog, DialogOptions } from '../../../../common/models/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { OrganizationDialogOptions } from '../../../models/employers';

@Component({
  moduleId: module.id,
  selector: 'slx-employer-organization-dialog',
  templateUrl: 'employer-organization-dialog.component.html',
  styleUrls: ['employer-organization-dialog.component.scss']
})
export class EmployerOrganizationDialogComponent implements IDialog {
  public orgDialogOptions: OrganizationDialogOptions;
  public dialogResult: boolean;
  private options: DialogOptions;
  private modalService: ModalService;

  public static openDialog(data: OrganizationDialogOptions, modalService: ModalService): EmployerOrganizationDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 350;
    dialogOptions.width = 400;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: OrganizationDialogOptions,
        useValue: data
      }
    ];
    let dialog: EmployerOrganizationDialogComponent = modalService.globalAnchor.openDialog(
      EmployerOrganizationDialogComponent,
      'Information',
      dialogOptions,
      resolvedProviders);

    return dialog;
  }
  
  constructor(
    orgDialogOptions: OrganizationDialogOptions,
    options: DialogOptions,
    modalService: ModalService
  ) {
    this.orgDialogOptions = orgDialogOptions;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }
  
  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
