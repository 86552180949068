import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';

import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';

import { ModalService } from '../../../common/services/index';

import { PmReviewRecord, PmReviewRecords, PMDialogOptions } from '../models/index';

import { PmCreateReviewPopupComponent } from '../components/create-review/pm-create-review-popup/pm-create-review-popup.component';
import { Employee } from '../../../organization/models';

@Injectable()
export class PmManagementService {
  private loadReviews$ = new Subject<void>();

  constructor(private modalService: ModalService) { }

  public openReviewPopup(windowTitle: string, review: PmReviewRecord): void {
    const options = new PMDialogOptions();
    options.review = review;
    PmCreateReviewPopupComponent.openDialog(windowTitle, options, this.modalService, (result: boolean) => {
      if (result) {
        this.loadReviews();
      }
    });
  }

  public openEmployeNewReviewPopup(windowTitle: string, employee: Employee): void {
    PmCreateReviewPopupComponent.openDialog(windowTitle, { review: null, employee: employee }, this.modalService, () => {
      this.loadReviews();
    });
  }

  public loadReviews(): void {
    this.loadReviews$.next();
  }

  public subscribeToLoadReviews(callback: () => void): Subscription {
    Assert.isNotNull(callback, 'callback');

    return this.loadReviews$.subscribe(callback);
  }
}
