import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { dateTimeUtils } from '../../../../common/utils/index';
import {
  IBenefitEligibilityRule,
  BenefitEligibilityRule,
  IBenefitEligibilityRuleStatement,
  BenefitEligibilityRuleStatement,
  IBenefitEligibilityRulesChangeRequest,
  BenefitEligibilityRulesChangeRequest,
  BenefitEligibilityRuleConfigurationData,
  BenefitEligibilityRuleStatementGroupItem,
  BenefitEligibilityRuleConditionType,
  BenefitEligibilityRuleConfigurationPredicate,
  BenefitEligibilityRuleConfigurationVariable,
} from '../../models/index';

@Injectable({
  providedIn: 'root',
})
export class BenefitEligibilityRulesMapService {
  public mapToBenefitEligibilityRules(eligibilityRuleDtos: IBenefitEligibilityRule[]): BenefitEligibilityRule[] {
    return _.map(eligibilityRuleDtos, (eligibilityRuleDto) => this.mapToBenefitEligibilityRule(eligibilityRuleDto));
  }

  public mapToBenefitEligibilityRule(dto: IBenefitEligibilityRule): BenefitEligibilityRule {
    const ruleStatements = _.map(dto.ruleStatements, (ruleStatement) =>
      this.mapToBenefitEligibilityRuleStatement(ruleStatement)
    );

    const rule = new BenefitEligibilityRule({
      id: dto.id,
      name: dto.name,
      description: dto.description,
      type: dto.type,
      rule: dto.rule,
      ruleV5: dto.ruleV5,
      isDeleted: dto.isDeleted,
      modifiedAt: dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt),
      modifiedBy: dto.modifiedBy,
      addedAt: dateTimeUtils.convertFromDtoDateTimeString(dto.addedAt),
      addedBy: dto.addedBy,
      ruleStatements: ruleStatements,
    });

    return rule;
  }

  public mapToBenefitEligibilityRuleStatement(dto: IBenefitEligibilityRuleStatement): BenefitEligibilityRuleStatement {
    const statement = new BenefitEligibilityRuleStatement({
      ruleId: dto.ruleId,
      variableCode: dto.variableCode,
      predicate: dto.predicate,
      value: dto.value,
      groupId: dto.groupId,
      sequence: dto.sequence,
    });

    return statement;
  }

  public mapToBenefitEligibilityRuleStatementRequestDto(
    ruleStatement: BenefitEligibilityRuleStatement
  ): IBenefitEligibilityRuleStatement {
    return {
      ruleId: ruleStatement.ruleId,
      variableCode: ruleStatement.variableCode,
      predicate: ruleStatement.predicate,
      value: ruleStatement.value,
      groupId: ruleStatement.groupId,
      sequence: ruleStatement.sequence,
    } as IBenefitEligibilityRuleStatement;
  }

  public mapToBenefitEligibilityRuleRequestDto(eligibilityRule: BenefitEligibilityRule): IBenefitEligibilityRule {
    const ruleStatementsDto = _.map(eligibilityRule.ruleStatements, (ruleStatement) =>
      this.mapToBenefitEligibilityRuleStatementRequestDto(ruleStatement)
    );

    const eligibilityRuleDto = {
      id: eligibilityRule.id,
      name: eligibilityRule.name,
      description: eligibilityRule.description,
      type: eligibilityRule.type,
      rule: eligibilityRule.rule,
      ruleV5: eligibilityRule.ruleV5,
      isDeleted: eligibilityRule.isDeleted,
      modifiedAt: dateTimeUtils.convertToDtoDateTimeString(eligibilityRule.modifiedAt),
      modifiedBy: eligibilityRule.modifiedBy,
      addedAt: dateTimeUtils.convertToDtoDateTimeString(eligibilityRule.addedAt),
      addedBy: eligibilityRule.addedBy,
      ruleStatements: ruleStatementsDto,
    } as IBenefitEligibilityRule;

    return eligibilityRuleDto;
  }

  public mapToBenefitEligibilityRuleChangeRequestDto(
    changeRequest: BenefitEligibilityRulesChangeRequest
  ): IBenefitEligibilityRulesChangeRequest {
    const rulesToAdd = _.map(changeRequest.rulesToAdd, (rule) => this.mapToBenefitEligibilityRuleRequestDto(rule));
    const rulesToUpdate = _.map(changeRequest.rulesToUpdate, (rule) =>
      this.mapToBenefitEligibilityRuleRequestDto(rule)
    );

    const eligibilityRulesChangeRequestDto = {
      rulesToAdd: rulesToAdd,
      rulesToUpdate: rulesToUpdate,
      rulesToDelete: changeRequest.rulesToDelete,
    } as IBenefitEligibilityRulesChangeRequest;

    return eligibilityRulesChangeRequestDto;
  }

  public mapToBenefitEligibilityRuleConfigurationData(
    dto: BenefitEligibilityRuleConfigurationData
  ): BenefitEligibilityRuleConfigurationData {
    const data = new BenefitEligibilityRuleConfigurationData({
      variables: dto.variables,
      predicates: dto.predicates
    });

    this.mapPredicatestoVariables(data.variables, dto.predicates);

    return data;
  }

  public mapPredicatestoVariables(variables: BenefitEligibilityRuleConfigurationVariable[],
    predicates: BenefitEligibilityRuleConfigurationPredicate[]) {
    if (variables && variables.length > 0) {
      _.forEach(variables, (variable) => {
        variable.predicates = this.mapPredicatesByIds(predicates, variable.operatorIds);
      });
    }
  }

  public mapGroupsToJsonRuleObject(benefitRuleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    const groups = this.mapGroupsToJsonObject(benefitRuleStatements);
    return { groups: groups };
  }

  public mapResponseToGroups(rule: string): BenefitEligibilityRuleStatementGroupItem[] {
    const jsonGroups = JSON.parse(rule);
    if (!jsonGroups) {
      return [];
    }

    const groups = this.mapJsonRuleObjectToGroups(jsonGroups.groups);

    return groups;
  }

  private mapGroupsToJsonObject(benefitRuleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    const groups = _.map(benefitRuleStatements, (statement) => {
      if (statement.groups && statement.groups.length > 0) {
        const groups = this.mapGroupsToJsonObject(statement.groups);

        const groupItem = {
          sequence: statement.sequence,
          conditionType: statement.conditionType
            ? (statement.conditionType as BenefitEligibilityRuleConditionType)
            : null,
          groups: groups,
        };

        return groupItem;
      }

      const varableItem = this.mapVariablesToJsonObject(statement);
      return varableItem;
    });

    return groups;
  }

  private mapVariablesToJsonObject(statement: BenefitEligibilityRuleStatementGroupItem) {
    if (statement && statement.variable) {
      const variable = {
        variableId: statement.variable.variableId,
        operatorType: statement.variable.operatorType,
        valueType: statement.variable.valueType,
        value: statement.variable.value,
        dataType: statement.variable.dataType,
      };

      const newStatement = {
        sequence: statement.sequence,
        conditionType: statement.conditionType ? statement.conditionType : null,
        variable: variable,
      };
      return newStatement;
    }
  }

  private mapJsonRuleObjectToGroups(groups): BenefitEligibilityRuleStatementGroupItem[] {
    const newGroups = _.map(groups, (group: BenefitEligibilityRuleStatementGroupItem) => {
      if (group.groups && group.groups.length > 0) {
        return new BenefitEligibilityRuleStatementGroupItem({
          sequence: group.sequence,
          conditionType: group.conditionType ? group.conditionType : null,
          groups: this.mapJsonRuleObjectToGroups(group.groups),
        });
      }
      return this.mapJsonRuleObjectToVariables(group);
    });

    return newGroups;
  }

  private mapJsonRuleObjectToVariables(statement): BenefitEligibilityRuleStatementGroupItem {
    const newStatement = new BenefitEligibilityRuleStatementGroupItem({
      sequence: statement.sequence,
      conditionType: statement.conditionType ? statement.conditionType : null,
      variable: {
        variableId: statement.variable.variableId,
        operatorType: statement.variable.operatorType,
        valueType: statement.variable.valueType,
        value: statement.variable.value,
        dataType: statement.variable.dataType,
      },
    });

    return newStatement;
  }

  public mapPredicatesByIds(
    configurationPredicates: BenefitEligibilityRuleConfigurationPredicate[],
    ids: number[]
  ): BenefitEligibilityRuleConfigurationPredicate[] {
    if (_.isEmpty(ids)) {
      return [];
    }

    const currentPredicates = _.filter(
      configurationPredicates,
      (predicate: BenefitEligibilityRuleConfigurationPredicate) => _.some(ids, (id: number) => id === predicate.id)
    );

    return currentPredicates;
  }
}
