<div class="pm-toolbar">
  <slx-toolbar class="pm-toolbar__helper" [alignExpandButtonRight]="true">
    <ng-template slxToolbarSectionTemplate alignMode="left" [width]="newReviewBtnWidth">
      <span class="pm-toolbar__control as-flex-start mt-15">
        <button *ngIf="canCreateNewReview" type="button"
          class="slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"
          (click)="onClickCreate()"
        >
        <i aria-hidden="true" class="fas fa-plus slx-button__icon"></i>
        <span class="slx-button__text">Create New Review</span>
        </button>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="35">
      <span class="pm-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <slx-columns-config-button
            [colGroupKey]="columnsStateName"
            [blueStyleConfig]="{ btnClass: 'slx-button slx-only-icon slx-toolbar', iconClass: 'fa fa-cog' }"
            title="Columns settings"
          ></slx-columns-config-button>
      </span>
    </ng-template>

    <ng-template slxToolbarSectionTemplate let-isCollapsed="isCollapsed" alignMode="rightIfNothingCollapsed" width="95">
      <span class="pm-toolbar__control as-flex-start mt-16" *ngIf="!isCollapsed">
        <slx-action-list>
          <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
          <popper-content #popperContent>
            <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
            <slx-action-list-item (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
          </popper-content>
        </slx-action-list>
      </span>
    </ng-template>
  </slx-toolbar>
</div>
