import * as _ from 'lodash';

import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, HostListener, ElementRef } from '@angular/core';

import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';


export type Descriptiors = 'eq' | 'neq' | 'contains' | 'doesnotcontain' | 'startswith' | 'endswith' | 'isnull' | 'isnotnull' | 'isempty' | 'isnotempty';
export enum FiltersDescriptiors {
  eq             = 'Is equal to',
  neq            = 'Is not equal to',
  contains       = 'Contains',
  doesnotcontain = 'Does not contain',
  startswith     = 'Starts with',
  endswith       = 'Ends with',
  isnull         = 'Is null',
  isnotnull      = 'Is not null',
  isempty        = 'Is empty',
  isnotempty     = 'Is not empty',
  // LT  = 'Less than',
  // LTE = 'Less than or equal to',
  // GT  = 'Greater than',
  // GTE = 'Greater than or equal to'
}

export type Combinations = 'or' | 'and';
export enum FiltersCombinations {
  and = 'and',
  or  = 'or'
}

export interface FilterDescriptorItem {
  id: Descriptiors;
  text: string;
}

export interface FilterCombinationItem {
  id: Combinations;
  text: string;
}

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-cell-filter',
  templateUrl: 'kendo-grid-cell-filter.component.html',
  styleUrls: ['kendo-grid-cell-filter.component.scss'],
})
export class KendoGridCellFilterComponent implements OnInit, OnDestroy {
  @Input()
  public field: string;
  @Output('cellFilterChanged')
  public cellFilterChanged$: EventEmitter<CompositeFilterDescriptor>;

  public filterDescriptors: FilterDescriptorItem[];
  public firstDescriptior: FilterDescriptorItem;
  public secondDescriptior: FilterDescriptorItem;
  public filterCombinations: FilterCombinationItem[];
  public currentCombination: FilterCombinationItem;
  public firstSearchValue: string;
  public secondSearchValue: string;
  public isShown: boolean;
  public hasFilter: boolean;

  public get hasFirstSearchValue(): boolean {
    return _.size(this.firstSearchValue) > 0;
  }

  public get hasSecondSearchValue(): boolean {
    return _.size(this.secondSearchValue) > 0;
  }

  constructor(private elementRef: ElementRef) {
    this.cellFilterChanged$ = new EventEmitter < CompositeFilterDescriptor >();
    this.createFiltersDescriptors();
    this.createFiltersCombinations();
    this.resetControls();
  }

  public ngOnInit(): void {
    //
  }

  public ngOnDestroy(): void {
    //
  }

  @HostListener('document:click', ['$event'])
  public onClick(e: MouseEvent): void {
    const container: Element = this.elementRef.nativeElement;
    const srcElement: Element = (<any>e.srcElement) || <Element>e.target;
    if (!container || !srcElement) return;

    if (!container.contains(srcElement)) {
      this.isShown = false;
    }
  }

  public onFilter(): void {
    const filters: FilterDescriptor[] = [];
    if (this.hasFirstSearchValue) {
      filters.push(this.createFilterDescriptor(this.firstDescriptior.id, this.field, this.firstSearchValue));
    }
    if (this.hasSecondSearchValue) {
      filters.push(this.createFilterDescriptor(this.secondDescriptior.id, this.field, this.secondSearchValue));
    }
    const filterDescriptor: CompositeFilterDescriptor = this.createCompositeFilter(this.currentCombination.id, filters);
    this.cellFilterChanged$.emit(this.createCompositeFilter(FiltersCombinations.and, [filterDescriptor]));
    this.hasFilter = true;
    this.isShown = false;
  }

  public onClear(): void {
    const filterDescriptor: CompositeFilterDescriptor = this.createCompositeFilter();
    this.cellFilterChanged$.emit(filterDescriptor);
    this.resetControls();
  }

  public onToggleState(): void {
    this.isShown = !this.isShown;
  }

  private createCompositeFilter(logic: Combinations = FiltersCombinations.and, filters: Array<FilterDescriptor | CompositeFilterDescriptor> = []): CompositeFilterDescriptor {
    return {
      logic: logic,
      filters: filters,
    };
  }

  private createFilterDescriptor(operator: Descriptiors, field: string = '', value: string = '', ignoreCase: boolean = true): FilterDescriptor {
    return {
      operator: operator,
      field: field,
      value: value,
      ignoreCase: ignoreCase
    };
  }

  private createFiltersDescriptors(): void {
    this.filterDescriptors = _.map(FiltersDescriptiors, (text: string, id: Descriptiors) => ({ id, text }));
  }

  private createFiltersCombinations(): void {
    this.filterCombinations = _.map(FiltersCombinations, (text: string, id: Combinations) => ({ id, text: _.capitalize(text) }));
  }

  private resetControls(): void {
    this.firstDescriptior = this.filterDescriptors[2];
    this.secondDescriptior = this.filterDescriptors[2];
    this.currentCombination = this.filterCombinations[0];

    this.firstSearchValue = '';
    this.secondSearchValue = '';
    this.isShown = false;
    this.hasFilter = false;
  }
}
