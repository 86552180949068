<slx-spinner [show]="state.isLoading">
<div class="section-container">

    <div class="actions-bar default-state-bar" *ngIf="state.isEditMode && !state.addMode && !userProfile?.isNew">
        <div class="buttons-container">
            <button type="button" class="theme-button-apply margin-r" (click)="onAddClick ()">Add Role</button>
            <button type="button" class="theme-button-apply" [disabled]="!hasSelectedRoles" (click)="onDeleteClick ()">Remove Role</button>
        </div>
        <div class="spacer"></div>
    </div>

    <div class="actions-bar" *ngIf="state.isEditMode && state.addMode">
            <div class="roles-selector">
                <form novalidate #form="ngForm">
                    <slx-input-decorator>
                        <slx-dropdown-input slx-input [prohibitedValues]="{values: positionProhibitedValues, valuePropertyName:'id'}" [required]="true"
                            [options]="allRolesList" [readonly]="false" name="selectedRole" placeholder="Select Role" [(ngModel)]="selectedRole"
                            (ngModelChange)="selectedRoleChanged()"
                            >
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                    <slx-input-decorator *ngIf="selectedRole">
                        <slx-dropdown-input slx-input [required]="true" [options]="orgLevelTypesList" [readonly]="false" 
                            name="selectedOrglevelType" placeholder="OrgLevel Type" [(ngModel)]="selectedOrgLevelType">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>
                </form>
            </div>
            <div class="spacer"></div>
            <div class="buttons-container" *ngIf="!userProfile?.isNew">
                <button type="button" class="theme-button-apply margin-r" [disabled]="!hasSelectedOrgLevels" (click)="onSaveAddedClick ()">Add Role</button>
                <button type="button" class="theme-button-cancel" (click)="onCancelAddClick ()">Cancel</button>
            </div>
    </div>

    <span *ngIf="!isValid && !state.addMode" class="error-message">Please, assign at least one role for this user</span>

    <kendo-grid #kendoGrid [data]="gridState?.view" *ngIf="!state.addMode"

    >

        <kendo-grid-column *ngIf="state.isEditMode" title="Select" [sortable]="false" [filterable]="false" field="isSelected" [locked]="false"
            width="35px">
            <ng-template kendoGridHeaderTemplate>
                <input type="checkbox" [(ngModel)]="isAllSelected" (ngModelChange)="onToggleAllSelected()">
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem>
                <input [disabled]="!dataItem.selectable" type="checkbox" [(ngModel)]="dataItem.isSelected" (ngModelChange)="selectionChange()">
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Level">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.orglevel | orgLevelPath: orgLevelSeparator }}</span>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column title="Assigned Role">
            <ng-template kendoGridCellTemplate let-dataItem>
                <span>{{ dataItem.role.name }}</span>
            </ng-template>
        </kendo-grid-column>

    </kendo-grid>

    <div *ngIf="state.addMode && selectedRole" class="add-mode-container">
        <slx-org-level-selector #orgLevelsSelector [selectedRoleRelation]="selectedRoleRelation" [filterByOrglevelType]="selectedOrgLevelType" (selectionCountChanged)="onOrgLevelSelectedCountChange($event)"></slx-org-level-selector>
    </div>

</div>
</slx-spinner>
