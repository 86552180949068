import * as tslib_1 from "tslib";
import { OnInit, Provider } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { DialogOptions } from '../../../../common/index';
import { ModalService } from '../../../../common/services/modal/modal.service';
import { ShiftReplacementManagementService } from '../../../services/index';
import { ShiftReplacementRequest, ShiftReplacementSettings, ShiftOpenCmd } from '../../../models/index';
import { appConfig } from '../../../../app.config';
var ShiftReplacementOpenComponent = /** @class */ (function () {
    function ShiftReplacementOpenComponent(options, modalService, request, shiftManagementService) {
        this.modalService = modalService;
        this.options = options;
        this.shiftManagementService = shiftManagementService;
        this.settings = new ShiftReplacementSettings();
        this.settings.createColumns();
        this.settings.request = request;
        this.settings.isMultipleSelectionMode = true;
    }
    ShiftReplacementOpenComponent.openDialog = function (request, unitId, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 600;
        dialogOptions.width = 1200;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ShiftReplacementRequest,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(ShiftReplacementOpenComponent, 'Open Shift', dialogOptions, resolvedProviders, function (result, uniqueId) {
            var cmd;
            if (result) {
                cmd = new ShiftOpenCmd();
                cmd.shiftId = request.shiftId;
                cmd.positionId = request.positionId;
                cmd.unitId = unitId;
                cmd.dateOn = request.date;
                cmd.employeeIds = _.map(dialog.selectedEmployees, function (emp) {
                    return emp.employee.id;
                });
            }
            callback(result, cmd);
        });
        return dialog;
    };
    ShiftReplacementOpenComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.selectedEmployeesSubscription = this.shiftManagementService.subscribeToSelectedEmployees(function (selected) {
            _this.selectedEmployees = selected;
        });
    };
    ShiftReplacementOpenComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ShiftReplacementOpenComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftReplacementOpenComponent.prototype, "selectedEmployeesSubscription", void 0);
    return ShiftReplacementOpenComponent;
}());
export { ShiftReplacementOpenComponent };
