import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnDestroy, OnInit, Provider } from '@angular/core';
import { ModalService, DialogOptions, DialogOptions2, DialogModeSize, ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common';
import { NewEnrollmentModel, BenefitEmpEnrollment401k } from '../../../../models';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { LookupService } from '../../../../../../organization/services';
import { appConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EnrollEmployee401kComponent = /** @class */ (function () {
    function EnrollEmployee401kComponent(model, apiService, options, modalService, lookupService, man) {
        this.model = model;
        this.apiService = apiService;
        this.options = options;
        this.modalService = modalService;
        this.lookupService = lookupService;
        this.man = man;
        this.minAmt = 0;
        this.maxAmt = 999999999.99;
        this.stepcurrency = 0.01;
        this.stepPercent = 0.01;
        this.hasDedError = false;
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.settings401K = new BenefitEmpEnrollment401k();
    }
    EnrollEmployee401kComponent.openDialog = function (model, apiService, modalService, lookupService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.width = 1050;
        dialogOptions.height = 400;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: LookupService,
                useValue: lookupService
            },
            {
                provide: ModalService,
                useValue: modalService
            },
            {
                provide: NewEnrollmentModel,
                useValue: model
            },
            {
                provide: EmployeeSectionsBenefitsManagementApiService,
                useValue: apiService
            }
        ];
        var title = "Enroll Employee in Benefits";
        var component = modalService.globalAnchor.openDialog2(EnrollEmployee401kComponent, title, dialogOptions, resolvedProviders, callback);
        return component;
    };
    Object.defineProperty(EnrollEmployee401kComponent.prototype, "hasSelectedValidTier401K", {
        get: function () {
            this.maxAmt = ((this.maxAmt) && this.settings401K.limitAmount > 0) ? this.settings401K.limitAmount : this.maxAmt;
            return this.hasSelected &&
                this.has401kOption;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployee401kComponent.prototype, "hasSelected", {
        get: function () {
            return !_.isNil(this.selectedTier);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EnrollEmployee401kComponent.prototype, "has401kOption", {
        get: function () {
            return !_.isNil(this.settings401K) &&
                _.gt(this.settings401K.id, 0);
        },
        enumerable: true,
        configurable: true
    });
    EnrollEmployee401kComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.groupName = this.model.plan.name;
        this.effectiveDate = this.model.effectiveDate;
        this.payrollDedStartDate = this.model.effectiveDate;
        this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
        this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
        this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
        this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
        this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
        this.endDate = this.model.plan.endDate;
        this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
        this.canMapPayroll = this.model.canMapPayroll;
        this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
            .then(function (tiers) {
            _this.tiers = tiers;
            _this.isLoading = false;
        });
        this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate(function (hasDedError) {
            _this.hasDedError = hasDedError;
        });
    };
    EnrollEmployee401kComponent.prototype.ngOnDestroy = function () {
    };
    EnrollEmployee401kComponent.prototype.onChangeTier = function (tier) {
        var _this = this;
        this.selectedTier = tier;
        this.isLoading = true;
        this.apiService.getBenefitPlan401k(tier.id)
            .then(function (settings) {
            if (!settings.maxEmployeePercentContribution) {
                settings.maxEmployeePercentContribution = 100;
            }
            _this.settings401K = settings;
            _this.isLoading = false;
        });
    };
    EnrollEmployee401kComponent.prototype.onEnroll = function () {
        this.model.settings401k = this.settings401K;
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EnrollEmployee401kComponent.prototype.onCancel = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Discard Changes', 'Are you sure you want to cancel? You will lose all unsaved selections.', this.modalService, function (isCancel) {
            if (isCancel) {
                _this.dialogResult = false;
                _this.modalService.closeWindow(_this.options.windowUniqueId);
            }
        }, options);
    };
    EnrollEmployee401kComponent.prototype.get401kToolTip = function () {
        return 'Employee cannot be enrolled as amounts have not been created under coverage options for this benefit plan. Please create coverage options for this plan to enroll';
    };
    EnrollEmployee401kComponent.prototype.OnDeductionEndDateChange = function (date) {
        this.model.plan.payrollDeductionEndDate = date;
    };
    EnrollEmployee401kComponent.prototype.OnDeductionStartDateChange = function (date) {
        this.model.plan.payrollDeductionStartDate = date;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EnrollEmployee401kComponent.prototype, "subscriptions", void 0);
    return EnrollEmployee401kComponent;
}());
export { EnrollEmployee401kComponent };
