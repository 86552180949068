import { Pipe, Injectable, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'slxDurationHM',
  pure: true
})
@Injectable()
export class SlxDurationHMPipe implements PipeTransform {
  constructor() {

  }
  public transform(obj?: any): any {
    if (obj === null || obj === undefined) return null;
    let diff = obj.toString();
    const hours = Math.floor(obj / (1000 * 60 * 60));
    diff -= hours * (1000 * 60 * 60);

    const mins = Math.floor(diff / (1000 * 60));
    diff -= mins * (1000 * 60);

    const res = `${_.padStart(hours.toString(), 2, '0')}:${_.padStart(mins.toString(), 2, '0')}`;
    return res;
  }
}
