import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ClientMessage } from '../../models/index';
import { UserSettings } from '../../../core/models/index';
import { ManagementBaseService, UserSettingsService } from '../../../core/services/index';
import { ClientMessageApiService } from '../client-message/client-message-api.service';


@Injectable()
export class ClientMessageManagementService extends ManagementBaseService<ClientMessage[], any>  {

  private messages: ClientMessage[] = [];
  constructor(private apiService: ClientMessageApiService, private userSettingsService: UserSettingsService) {
    super();
  }

  public readMessage(msg: ClientMessage): void {
    const settings: UserSettings = this.userSettingsService.get();
    const readClientMessages: StringMap<boolean> = this.getReadClientMessages(settings);
    if (msg.id !== 0) {
      readClientMessages[msg.id.toString(10)] = true;
    } else {
      readClientMessages[msg.messageHash] = true;
    }
    this.userSettingsService.set(settings);
    const filteredMessages = this.filterRead(settings.readClientMessages, this.messages);
    this.onLoaded(filteredMessages);
  }

  public loadMessages(orglevelId: number): void {
    this.onLoadStatusChanged(true);
    this.apiService.getMessages(orglevelId)
      .then((messages: ClientMessage[]) => {
        this.messages = messages;
        const settings: UserSettings = this.userSettingsService.get();
        const readClientMessages: StringMap<boolean> = this.getReadClientMessages(settings);
        const filteredMessages = this.filterRead(readClientMessages, this.messages);
        this.onLoaded(filteredMessages);
        this.onLoadStatusChanged(true);
      })
      .catch((e: any) => {
        this.onLoadStatusChanged(true);
      });
  }

  public filterRead(read: StringMap<boolean>, messages: ClientMessage[]): ClientMessage[] {
    return _.filter(messages, (msg: ClientMessage) => {
      return msg.id !== 0 ? !read[msg.id] : !read[msg.messageHash];
    });
  }

  public getReadClientMessages(settings: UserSettings): StringMap<boolean> {
    if (!settings.readClientMessages) {
      settings.readClientMessages = {};
    }
    return settings.readClientMessages;
  }
}
