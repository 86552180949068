/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timecard-summary-panel-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./timecard-summary-panel-header.component";
import * as i4 from "../../../services/timecards-summary/timecards-summary-management.service";
var styles_TimecardSummaryPanelHeaderComponent = [i0.styles];
var RenderType_TimecardSummaryPanelHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimecardSummaryPanelHeaderComponent, data: {} });
export { RenderType_TimecardSummaryPanelHeaderComponent as RenderType_TimecardSummaryPanelHeaderComponent };
function View_TimecardSummaryPanelHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "buttons-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "collapse-button marg-r"], ["title", "Export to Excel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onExportExel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-file-excel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "collapse-button"], ["title", "Export to PDF"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onExportPdf() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "far fa-file-pdf"]], null, null, null, null, null))], null, null); }
function View_TimecardSummaryPanelHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "collapse-button open-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.panel == null) ? null : _co.panel.onToggle()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-caret-down"]], null, null, null, null, null))], null, null); }
function View_TimecardSummaryPanelHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "collapse-button close-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (((_co.panel == null) ? null : _co.panel.onToggle()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-caret-up"]], null, null, null, null, null))], null, null); }
function View_TimecardSummaryPanelHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col buttons-col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimecardSummaryPanelHeaderComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimecardSummaryPanelHeaderComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.panel == null) ? null : _co.panel.isCollapsed); _ck(_v, 2, 0, currVal_0); var currVal_1 = !((_co.panel == null) ? null : _co.panel.isCollapsed); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TimecardSummaryPanelHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "header-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "col title-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "section-title": 0, "section-collapsed-title": 1, "section-expanded-title": 2 }), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "col line-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "hr", [["class", "section-separator"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimecardSummaryPanelHeaderComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimecardSummaryPanelHeaderComponent_2)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, true, ((_co.panel == null) ? null : _co.panel.isCollapsed), !((_co.panel == null) ? null : _co.panel.isCollapsed)); _ck(_v, 4, 0, currVal_0); var currVal_2 = !((_co.panel == null) ? null : _co.panel.hideButton); _ck(_v, 10, 0, currVal_2); var currVal_3 = !((_co.panel == null) ? null : _co.panel.hideButton); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.panel.title; _ck(_v, 6, 0, currVal_1); }); }
export function View_TimecardSummaryPanelHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-timecard-summary-panel-header", [], null, null, null, View_TimecardSummaryPanelHeaderComponent_0, RenderType_TimecardSummaryPanelHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.TimecardSummaryPanelHeaderComponent, [i4.TimecardsSummaryManagementService], null, null)], null, null); }
var TimecardSummaryPanelHeaderComponentNgFactory = i1.ɵccf("slx-timecard-summary-panel-header", i3.TimecardSummaryPanelHeaderComponent, View_TimecardSummaryPanelHeaderComponent_Host_0, { panel: "panel", component: "component" }, {}, []);
export { TimecardSummaryPanelHeaderComponentNgFactory as TimecardSummaryPanelHeaderComponentNgFactory };
