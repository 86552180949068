import { Pipe, Injectable, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'slxJoin',
  pure: true
})
@Injectable()
export class SlxJoinPipe implements PipeTransform {
  public transform(items: any[], propertyName: string, separator?: string): any {
    if (items === null || items === undefined) return null;
    let strItems: any = _.map(items, (item: any) => propertyName? item[propertyName]: item);
    return _.join(strItems, separator);
  }
}
