import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { Assert } from '../../../framework/index';
import { Session, AuthResponse } from '../../../state-model/models/index';
import { appConfig } from '../../../app.config';
import { authenticationConfig } from '../../authentication.config';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { AuthApiService } from '../../../core/services/index';
import { AuthenticationMapService, IAuthenticationData, IForgotPassword, IResetPassword, IAuthenticationDataContainer } from './authentication.map-service';
import { SessionService } from '../../../core/services/session/session.service';
import { StatusCodes } from '../../../core/models/api/status-codes';
import { ForgotPassword, ResetPassword } from '../../models/password-reset';
import { IServerValidationResult } from '../../../common/validators/common-validators-models';
import { IUserPasswordSettings } from '../../../core/models/index';
import { OAuth2AuthorizeRequest } from '../../models/login-event';

@Injectable()
export class AuthenticationApiService {

  private apiService: AuthApiService;
  private authenticationMapService: AuthenticationMapService;
  private sessionService: SessionService;

  constructor(apiService: AuthApiService, authenticationMapService: AuthenticationMapService, sessionService: SessionService) {
    Assert.isNotNull(apiService, 'apiService');
    Assert.isNotNull(authenticationMapService, 'authenticationMapService');
    Assert.isNotNull(sessionService, 'sessionService');

    this.apiService = apiService;
    this.authenticationMapService = authenticationMapService;
    this.sessionService = sessionService;
  }

  
  public async getAuthorizationCode(authorizationRequest: OAuth2AuthorizeRequest): Promise<any> {
    const url: string = `${appConfig.api.url}/${appConfig.api.version}/authentication/authorize`;
    
    let request: HttpRequest<any> = new HttpRequest('POST', url, authorizationRequest);
    
    let promise: Promise<any> = this.apiService.request<any, Meta>(request)
      .then((response: any) =>
        response
      );
    return promise;
  }


  public authenticate(username: string, password: string, alias?: string): Promise<AuthResponse> {
    Assert.isNotNull(username, 'username');
    Assert.isNotNull(password, 'password');

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.authenticate}`;

    let body: StringMap<any> = {
      username: username,
      password: password
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);
    let promise: Promise<AuthResponse> = this.apiService.requestUnauthorized<IAuthenticationDataContainer, Meta>(request, alias)
      .then((response: ResponseBody<IAuthenticationDataContainer, Meta>) => this.authenticationMapService.mapToAuthResponse(response.data));

    return promise;
  }

  public validateAuthSession(state: string): Promise<AuthResponse> {
    Assert.isNotNull(state, 'state');

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.validateAuthSession}`;

    let request: HttpRequest<any> = new HttpRequest('POST', url, JSON.stringify(state), { withCredentials: true });
    let promise: Promise<AuthResponse> = this.apiService.requestUnauthorizedNoAlias<IAuthenticationDataContainer, Meta>(request)
      .then((response: ResponseBody<IAuthenticationDataContainer, Meta>) => this.authenticationMapService.mapToAuthResponse(response.data));

    return promise;
  }

  public logout(): Promise<void> {

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.logout}`;

    let body: StringMap<any> = {
      token: this.sessionService.getToken(),
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiService.request<void, Meta>(request)
      .then(response => response.data);
  }

  public renew(): Promise<Session | ResponseBody<any, Meta>> {
    let isSessionExpired: boolean = this.sessionService.isExpired();
    if (isSessionExpired) {
      let meta: Meta = new Meta('Session has expired.');
      let response: ResponseBody<any, Meta> = new ResponseBody<any, Meta>(StatusCodes.unauthorized, undefined, meta);
      let promise: Promise<ResponseBody<any, Meta>> = Promise.reject<ResponseBody<any, Meta>>(response);

      return promise;
    }

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.renew}`;

    let body: StringMap<any> = {
      token: this.sessionService.getToken(),
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);
    let promise: Promise<Session> = this.apiService.request<IAuthenticationData, Meta>(request)
      .then((response: ResponseBody<IAuthenticationData, Meta>) => this.authenticationMapService.mapToModel(response.data));

    return promise;
  }

  public requestResetPassword(usernameWithAlias: string, gRecaptchaResponse: string): Promise<ForgotPassword> {
    Assert.isNotNull(usernameWithAlias, 'usernameWithAlias');
    Assert.isNotNull(gRecaptchaResponse, 'gRecaptchaResponse');

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.forgotPassword}`;

    let userName: string;
    let alias: string = '';

    if (usernameWithAlias.includes('@')) {
      const values = usernameWithAlias.split('@');
      userName = values[0];
      alias = values[1];
    } else {
      userName = usernameWithAlias;
    }

    let body: StringMap<any> = {
      userName: userName,
      gRecaptchaResponse: gRecaptchaResponse
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);
    let promise: Promise<ForgotPassword> = this.apiService.requestUnauthorized<IForgotPassword, Meta>(request, alias)
      .then((response: ResponseBody<IForgotPassword, Meta>) => this.authenticationMapService.mapForgotPassword(response.data));

    return promise;
  }

  public resetPasswordByOldPassword(username: string, oldPassword: string, newPassword: string, alias: string): Promise<ResetPassword> {

    Assert.isNotNull(username, 'username');
    Assert.isNotNull(oldPassword, 'oldPassword');
    Assert.isNotNull(newPassword, 'newPassword');

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.changePassword}`;

    let body: StringMap<any> = {
      username: username,
      oldPassword: oldPassword,
      newPassword: newPassword
    };
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);
    return this.apiService.requestUnauthorized<ResetPassword, Meta>(request, alias)
      .then((response: ResponseBody<IResetPassword, Meta>) => this.authenticationMapService.mapResetPassword(response.data));
  }

  public resetPasswordByToken(token: string, username: string, password: string, alias?: string): Promise<ResetPassword> {
    Assert.isNotNull(token, 'token');
    Assert.isNotNull(username, 'username');
    Assert.isNotNull(password, 'password');

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.resetPassword}`;

    let body: StringMap<any> = {
      token: token,
      username: username,
      password: password
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);
    let promise: Promise<ResetPassword> = this.apiService.requestUnauthorized<IResetPassword, Meta>(request, alias)
      .then((response: ResponseBody<IResetPassword, Meta>) => this.authenticationMapService.mapResetPassword(response.data));

    return promise;
  }

  public validatePasswordUnsecure(alias: string, password: string): Promise<IServerValidationResult> {

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.validatePassword}`;
    let body: StringMap<any> = {
      password: password
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiService.requestUnauthorized<IServerValidationResult, Meta>(request, alias)
      .then((response: ResponseBody<IServerValidationResult, Meta>) => {
        return response.data;
      });
  }

  public validatePassword(password: string): Promise<IServerValidationResult> {

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.validatePassword}`;
    let body: StringMap<any> = {
      password: password
    };

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    return this.apiService.request<IServerValidationResult, Meta>(request)
      .then((response: ResponseBody<IServerValidationResult, Meta>) => {
        return response.data;
      });
  }

  public loadPasswordPolicyUnsecure(alias: string): Promise<IUserPasswordSettings> {

    const url: string = `${appConfig.api.url}/${appConfig.api.version}/${authenticationConfig.api.passwordSettings}`;

    let request: HttpRequest<any> = new HttpRequest('GET', url);

    return this.apiService.requestUnauthorized<IUserPasswordSettings, Meta>(request, alias)
      .then((response: ResponseBody<IUserPasswordSettings, Meta>) => {
        return response.data;
      });
  }
}
