import * as _ from 'lodash';
import * as moment from 'moment';
import { process } from '@progress/kendo-data-query';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IApplicationConfig, appConfig } from '../../../../../app.config';
import { KendoGridStateHelper, ModalService, DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../../common';
import { EmployeeRosterRecord } from '../../../models/employee-roster-record';
import { PmEmployeeRosterManagementService } from '../../../services/pm-employee-roster-management.service';
import { unsubscribeAll } from '../../../../../core/decorators';
import { Subscription } from 'rxjs/Subscription';
import { PmReviewRecords, PmReviewRecord } from '../../../models';
import { Employee } from '../../../../../organization/models';
import { PmCreateReviewPopupComponent } from '../../create-review/pm-create-review-popup/pm-create-review-popup.component';
import { NavigationExtras, ActivatedRoute, Router } from '@angular/router';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { GridComponent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'slx-pm-employee-roster-grid',
  templateUrl: './pm-employee-roster-grid.component.html',
  styleUrls: ['./pm-employee-roster-grid.component.scss']
})
export class PmEmployeeRosterGridComponent implements OnInit, OnDestroy {

  public get isLockedColumn(): boolean {
    return this.devDetector.isDesktop;
  }

  public appConfig: IApplicationConfig;
  public gridState: KendoGridStateHelper<EmployeeRosterRecord>;
  public pageSize: number = 50;

  public xlsxName = 'Employee_Performance_Roster_';
  public pdfName = 'Employee_Performance_Roster_';
  public columnsGroupName = 'PerformanceManagementEmployeeRoster';
  public employee: Employee;

  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  private records: EmployeeRosterRecord[];

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription>;

  constructor(
    private devDetector: DeviceDetectorService,
    private pmManagementService: PmEmployeeRosterManagementService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.appConfig = appConfig;
    this.subscriptions = {};
    this.gridState = new KendoGridStateHelper<EmployeeRosterRecord>();
    this.gridState.state.skip = 0;
    this.gridState.state.take = this.pageSize;
    this.gridState.state.sort = [{ field: 'reviewRecord.reviewDate', dir: 'desc' }];
  }

  public ngOnDestroy(): void { }

  public ngOnInit(): void {
    let date = this.getCurrentDate();
    let dateStr = moment(date).format(appConfig.dateFormat);
    this.xlsxName += `${dateStr}.xlsx`;
    this.pdfName += `${dateStr}.pdf`;

    this.subscriptions.export = this.pmManagementService
      .subscribeToExport((isPDF: boolean) => this.exportTo(isPDF));

    this.subscriptions.dataLoaded = this.pmManagementService
      .subscribeToDataLoaded((container: PmReviewRecords<EmployeeRosterRecord>) => {
        this.records = container.records;
        this.employee = container.employee;
        this.refreshGrid();
        this.exportFileName(this.employee);
      });

    this.subscriptions.refresh = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());
  }

  public onSelectItem(item: EmployeeRosterRecord): void {
    this.openEditReportDialog(item.reviewRecord);
  }

  public onEmpNameClick(empId: number): void {
    let navService: EmployeeSectionNavigationService = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
    const urlTree = navService.getUrlTreeFromRoot(empId, true);
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(urlTree, extras);
  }

  private exportTo(isPDF: boolean): void {

    if (isPDF) {
      this.grid.saveAsPDF();
    } else {
      this.grid.saveAsExcel();
    }
  }

  public retriveAllPages(): () => ExcelExportData {
    return () => {
      const data = {
        data: process(this.records, { sort: this.gridState.state.sort, filter: this.gridState.state.filter }).data
      };
      _.forEach(data.data, r => (r.employee = this.employee));
      return data;
    };
  }

  protected openEditReportDialog(review: PmReviewRecord): void {
    this.pmManagementService.openReviewPopup(review);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  private getCurrentDate(): Date {
    return new Date();
  }

  public exportFileName(employee: Employee) {
    if (employee && employee.name) {

      const empName = _.trim(employee.name).replace(/[\,,\s]/g, '_').replace(/_+/g, '_');

      const fileName = `Employee_${empName}_Review`;

      this.pdfName = `${fileName}.pdf`;
      this.xlsxName = `${fileName}.xlsx`;
    }
  }

}
