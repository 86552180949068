import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { ScheduleConsoleCycles, ConsoleConfig, SoValuePairWidgetConfig } from '../../models/index';
import { ScheduleConsoleChartService, ScheduleConsoleFiltersService } from '../../services/index';
import { ValuePairChartSeriesDataService } from '../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service';

import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { OperationalConsoleStateService } from '../../../services/operational-console/operational-console-state.service';
import { UserApplication } from '../../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-overview',
  templateUrl: 'schedule-console-overview.component.html',
  styleUrls: ['schedule-console-overview.component.scss'],
  providers: [ValuePairChartSeriesDataService]
})
export class ScheduleConsoleOverviewComponent implements OnInit, OnChanges {
  public m_showDetailed: boolean;

  @Input()
  public consoleConfig: ConsoleConfig;

  @Input()
  public consoleData: ScheduleConsoleCycles;

  @Input()
  public rangeType: string;

  public get showDetailed(): boolean {
    return this.m_showDetailed;
  }

  public get isDayView(): boolean {
    return this.rangeType === 'Day';
  }

  @mutableSelect('application')
  public application$: Observable<UserApplication>;

  @unsubscribe()
  private applicationSubscripion: Subscription;

  @unsubscribe()
  private filtersAppliedSubscription: Subscription;

  private chartConfig: SoValuePairWidgetConfig = new SoValuePairWidgetConfig();

  public constructor(
    private dataService: ValuePairChartSeriesDataService,
    private filtersService: ScheduleConsoleFiltersService,
    private stateService: OperationalConsoleStateService
  ) { }

  public ngOnInit(): void {
    this.dataService.setConfig(this.chartConfig);
    this.updateChartDataService();

    this.applicationSubscripion = this.application$.subscribe((value: UserApplication) => {
      this.stateService.application = value;
    });

    this.filtersAppliedSubscription = this.filtersService.onFiltersApplied.subscribe(() => {
        this.updateChartDataService();

        this.stateService.canLoadData = this.isDayView && this.showDetailed;
        this.stateService.loadData();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['consoleConfig'] || changes['consoleData'] || changes['rangeType']) {
      this.updateChartDataService();
    }
  }

  public onShowDetailedChange(showDetailed: boolean): void {
    this.m_showDetailed = showDetailed;
    this.stateService.canLoadData = this.isDayView && this.showDetailed;
    this.stateService.loadData();
  }

  private updateChartDataService(): void {
    if (!this.consoleConfig || !this.consoleData) {
      return;
    }

    const inputs = ScheduleConsoleChartService.makeChartSeriesInputs(this.consoleData, this.consoleConfig, this.rangeType === 'Week');
    this.dataService.setModels(inputs, this.chartConfig);
  }
}
