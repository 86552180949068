import { Provider } from '@angular/core';
import { appConfig } from '../../../../../../app.config';
import { DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToTransfer } from '../../../../models/index';
import { EmployeeTransferComponent } from '../employee-transfer/employee-transfer.component';
import { NgModel } from '@angular/forms';
var EmployeeTransferDialogComponent = /** @class */ (function () {
    function EmployeeTransferDialogComponent(options, modalService, employee) {
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
        this.employee = employee;
        this.state = {
            isLoading: false,
        };
        this.appConfig = appConfig;
    }
    EmployeeTransferDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 710;
        dialogOptions.width = 850;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EmployeeToTransfer,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(EmployeeTransferDialogComponent, 'Transfer Employee', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.requestToTerminate);
        });
        return dialog;
    };
    Object.defineProperty(EmployeeTransferDialogComponent.prototype, "requestToTerminate", {
        get: function () {
            return this.employeeTransfer.requestToTransfer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferDialogComponent.prototype, "employeeShortInfo", {
        get: function () {
            return this.employeeTransfer.employeeShortInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferDialogComponent.prototype, "isEmployeeTransferFormValid", {
        get: function () {
            if (this.employeeTransfer && this.employeeTransfer.ngForm) {
                return this.employeeTransfer.ngForm.valid;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTransferDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeTransferDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return EmployeeTransferDialogComponent;
}());
export { EmployeeTransferDialogComponent };
