
export class EmployeeTAPunchLogRecord {
  public PunchDate: string;
  public PunchInTime: string;
  public PunchOutTime: string;
  public Interval: string;
}

export class EmployeeTAPayLogRecord {
  public Code: string;
  public Paid: string;
  public Amount: number;
  public Interval: string;
}
