import * as tslib_1 from "tslib";
import { LookupEntity } from './lookup-entity';
import { appConfig } from '../../../app.config';
import * as moment from 'moment';
var AvailabilityDateRange = /** @class */ (function (_super) {
    tslib_1.__extends(AvailabilityDateRange, _super);
    function AvailabilityDateRange() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AvailabilityDateRange.prototype, "name", {
        get: function () {
            return moment(this.startDate).format(appConfig.dateFormat) + " - " + moment(this.endDate).format(appConfig.dateFormat);
        },
        enumerable: true,
        configurable: true
    });
    return AvailabilityDateRange;
}(LookupEntity));
export { AvailabilityDateRange };
