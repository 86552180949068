<slx-spinner [show]="isLoading">
  <div class="slx-high-box geolocation">
    <div class="slx-content-toolbar-indents">
      <slx-geolocation-toolbar></slx-geolocation-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <div class="geolocation__form" [ngClass]="animationCssClass">
        <slx-geolocation-form></slx-geolocation-form>
      </div>
      <div class="geolocation__grid" [ngClass]="animationCssClass">
        <slx-geolocation-grid></slx-geolocation-grid>
      </div>
    </div>
  </div>
</slx-spinner>
