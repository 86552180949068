<div class="filter">
    <button type="button" [popover]="filterPopover" class="{{filtersApplied ? 'theme-button-apply filter-btn' : 'theme-button-cancel filter-btn'}}">
      <span class="fa fa-filter"></span>
    </button>
    <popover-content #filterPopover
      title="Filters"
      class="popover-content-arrow slx-wide"
      disableClickOutside="true"
      (onShow)="show()"
      placement="bottom"
    >
        <div>
          <slx-input-decorator class="auto-height">
            <slx-dropdown-multiselect slx-input [lookup]="{lookupType: 'positionGroups', orgLevelId: selectedOrgLevel?.id, updateCacheForced: true}"
              (ngModelChange)="onFilterChange('positionGroups', $event)"
              placeholder="Position Group" name="positionGroupsFilter" [(ngModel)]="positionGroupsFilter">
            </slx-dropdown-multiselect>
          </slx-input-decorator>
        </div>
        <div>
          <slx-input-decorator class="auto-height">
            <slx-dropdown-multiselect slx-input [lookup]="{lookupType: 'position', orgLevelId: selectedOrgLevel?.id}"
              (ngModelChange)="onFilterChange('position', $event)"
              placeholder="Position" name="posFilter" [(ngModel)]="posFilter">
            </slx-dropdown-multiselect>
          </slx-input-decorator>
        </div>
        <div>
            <slx-input-decorator class="auto-height">
              <slx-dropdown-multiselect slx-input [lookup]="{lookupType: 'shiftGroup', orgLevelId: selectedOrgLevel?.id}"
              (ngModelChange)="onFilterChange('shiftGroup', $event)"
              placeholder="Shift Group" name="shiftGroupFilter" [(ngModel)]="shiftGroupFilter">
              </slx-dropdown-multiselect>
            </slx-input-decorator>
          </div>
        <div>
          <slx-input-decorator class="auto-height">
            <slx-dropdown-multiselect slx-input [lookup]="{lookupType: 'locationUnit', orgLevelId: selectedOrgLevel?.id}"
             (ngModelChange)="onFilterChange('locationUnit', $event)"
              placeholder="Unit" name="unitFilter" [(ngModel)]="unitFilter">
            </slx-dropdown-multiselect>
          </slx-input-decorator>
        </div>
        <div>
          <slx-input-decorator class="auto-height">
            <slx-dropdown-multiselect slx-input [lookup]="{lookupType: 'shift', orgLevelId: selectedOrgLevel?.id}"
             (ngModelChange)="onFilterChange('shift', $event)"
              placeholder="Shift" name="shiftFilter" [(ngModel)]="shiftFilter">
            </slx-dropdown-multiselect>
          </slx-input-decorator>
        </div>
        <div class="btn-container">
          <button type="button" class="theme-button-cancel margin-r" (click)="cancelAndClose();">Cancel</button>
          <button type="button" class="theme-button-apply" (click)="filterPopover.hide(); this.applyFilters();">Apply</button>
        </div>
    </popover-content>
  </div>
