import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { ValuePairChartSeriesDataService } from '../../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service';
import { ValuePairChartSeriesModel, ValuePairWidgetConfig, ValuePairChartInput, ValuePairChartModel } from '../../../../../common/index';
import { unsubscribe } from '../../../../../core/decorators/index';

import * as _ from 'lodash';

export interface SoConsoleOverviewWeekChartDataItem {
  value: number;
  color: string;
  model: ValuePairChartModel;
}

export interface SoConsoleOverviewWeekChartSeries {
  model: ValuePairChartSeriesModel;
  dataItems: SoConsoleOverviewWeekChartDataItem[];
}

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-console-overview-week',
  templateUrl: 'schedule-console-overview-week.component.html',
  styleUrls: ['schedule-console-overview-week.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleConsoleOverviewWeekComponent {

  public categories: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];

  public config: ValuePairWidgetConfig;

  public models: ValuePairChartSeriesModel[];

  public idealHoursByCategory: number[];

  public chartSeries: SoConsoleOverviewWeekChartSeries[];

  @unsubscribe()
  private configLoaded: Subscription;

  @unsubscribe()
  private modelLoaded: Subscription;

  public constructor(
    private changeDetector: ChangeDetectorRef,
    private dataService: ValuePairChartSeriesDataService,
  ) {}

  public ngOnInit(): void {
    this.configLoaded = this.dataService.onConfigLoaded.subscribe((config: ValuePairWidgetConfig) => {
      if (_.isEqual(this.config, config)) {
        return;
      }

      this.config = config;

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.modelLoaded = this.dataService.onModelsLoaded.subscribe((models: ValuePairChartSeriesModel[]) => {
      if (_.isEqual(this.models, models)) {
        return;
      }

      this.models = models;
      this.updateIdealHoursByCategory();
      this.updateChartData();

      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private updateIdealHoursByCategory(): void {
    const hoursByCategory: number[] = [];

    _.forEach(this.categories, (category: string, index: number) => {
      let count: number = _.sumBy(this.models, (model: ValuePairChartSeriesModel) => {
        if (!model || !model.models[index]) {
          return 0;
        }

        return model.models[index].input.value2;
      });

      hoursByCategory.push(count);
    });

    this.idealHoursByCategory = hoursByCategory;
  }

  private updateChartData(): void {
    let aggregatedValues: number[] = [
      0, 0, 0, 0, 0, 0, 0
    ];
    let chartSeries: SoConsoleOverviewWeekChartSeries[] = [];

    _.forEach(this.models, (seriesModel: ValuePairChartSeriesModel) => {
      let dataItems: SoConsoleOverviewWeekChartDataItem[] = [];

      _.forEach(seriesModel.models, (model: ValuePairChartModel, index: number) => {
        aggregatedValues[index] += model.rawValue1;

        dataItems.push({
          model,
          color: model.color ? model.color.fontColor1 : null,
          value: aggregatedValues[index],
        });
      });

      chartSeries.push({
        dataItems,
        model: seriesModel,
      });
    });

    this.chartSeries = chartSeries;
  }
}
