import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { configurationConfig } from '../../configuration.config';
import { WFMSyncMapService } from './wfm-sync-map.service';
var WfmSyncService = /** @class */ (function () {
    function WfmSyncService(apiUtilService, wfmMapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.wfmMapService = wfmMapService;
        this.urlParamsService = urlParamsService;
    }
    WfmSyncService.prototype.employeeSync = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.wfmEmployee();
                body = this.wfmMapService.postEmployeeDetails(req);
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.employeeSyncResponse = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.wfmEmployeeResponse();
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        console.log(response);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.employeeSyncDetails = function (syncId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.wfmEmployeeDetails(syncId);
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.wfmEmployee = function () {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.sync + "/" + configurationConfig.api.configuration.wfm.employee;
    };
    WfmSyncService.prototype.wfmEmployeeResponse = function () {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.sync + "/" + configurationConfig.api.configuration.wfm.response;
    };
    WfmSyncService.prototype.wfmEmployeeDetails = function (syncId) {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.sync + "/" + syncId + "/" + configurationConfig.api.configuration.wfm.responsedetails;
    };
    WfmSyncService.prototype.getMessagesApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.wfm.root;
    };
    WfmSyncService.prototype.dailyTransactionReport = function (startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.wfmTransactionDetails(startDate, endDate);
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.wfmTransactionDetails = function (startDate, endDate) {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.report + "/" + startDate + "/" + endDate;
    };
    WfmSyncService.prototype.payDataEventReport = function (startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.wfmPaydatareportDetails(startDate, endDate);
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.wfmPaydatareportDetails = function (startDate, endDate) {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.paydatareport + "/" + startDate + "/" + endDate;
    };
    WfmSyncService.prototype.GetOrganizationLevelsChangeLogs = function (workFlowName, workFlowStep) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.workLocations(workFlowName, workFlowStep);
                        params = {};
                        if (workFlowName) {
                            params['workFlowName'] = workFlowName;
                        }
                        if (workFlowStep) {
                            params['workFlowStep'] = workFlowStep;
                        }
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.workLocations = function (workFlowName, workFlowStep) {
        return this.getApiRoot() + "/" + configurationConfig.api.orglevels.changelogs + "/" + configurationConfig.api.orglevels.orgType;
    };
    WfmSyncService.prototype.getApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.orglevels.root;
    };
    WfmSyncService.prototype.SyncOrganizationLevelsChangeLogs = function (orgsyncchangelogdetails, workFlowType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.putSync();
                params = {};
                if (workFlowType) {
                    params['workFlowType'] = workFlowType;
                }
                body = orgsyncchangelogdetails;
                request = this.urlParamsService.createPutRequest(url, body, params);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.putSync = function () {
        return this.getApiRoot() + "/" + configurationConfig.api.orglevels.changelogs + "/" + configurationConfig.api.orglevels.sync;
    };
    WfmSyncService.prototype.eventReport = function (startDate, endDate, transactionReport) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.eventReportDetails(startDate, endDate, transactionReport);
                        params = {};
                        if (transactionReport) {
                            params['transactionReport'] = transactionReport.toString();
                        }
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.eventReportDetails = function (startDate, endDate, transactionReport) {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.eventreport + "/" + startDate + "/" + endDate;
    };
    WfmSyncService.prototype.taEventReport = function (startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.taEventReportDetails(startDate, endDate);
                        params = {};
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.taEventReportDetails = function (startDate, endDate) {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.taeventreport + "/" + startDate + "/" + endDate;
    };
    WfmSyncService.prototype.employeeDetailsSync = function (workFlowName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, promise;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.EmployeeSyncResponse();
                params = {};
                if (workFlowName) {
                    params['workFlowName'] = workFlowName;
                }
                request = this.urlParamsService.requestGet(url, params);
                promise = this.apiUtilService.requestNew(request)
                    .then(function (response) {
                    return _this.wfmMapService.getEmployeeOrgSyncDetails(response.data);
                });
                return [2 /*return*/, promise];
            });
        });
    };
    WfmSyncService.prototype.EmployeeSyncResponse = function () {
        return this.getEmployeeApiRoot() + "/" + configurationConfig.api.configuration.employee.root + "/" + configurationConfig.api.configuration.employee.sync;
    };
    WfmSyncService.prototype.getEmployeeApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.root;
    };
    WfmSyncService.prototype.employeeSyncOrg = function (req, workFlowType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.employeeSyncOrgRoot();
                params = {};
                if (workFlowType) {
                    params['workFlowType'] = workFlowType;
                }
                body = req;
                request = this.urlParamsService.createPostRequest(url, body, params);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.employeeSyncOrgRoot = function () {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.sync + "/" + configurationConfig.api.configuration.wfm.employees;
    };
    WfmSyncService.prototype.orgDetails = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.orgStruct();
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        console.log(response);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.orgStruct = function () {
        return this.getOrgApiRoot() + "/" + configurationConfig.api.configuration.org.orglevels;
    };
    WfmSyncService.prototype.getOrgApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.org.root;
    };
    WfmSyncService.prototype.orgDataDetails = function (orgLevelId, isActive) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.orgDataStruct(orgLevelId, isActive);
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.orgDataStruct = function (orgLevelId, isActive) {
        return this.getDataOrgApiRoot() + "/" + configurationConfig.api.configuration.orgsync.userAccesibleOrganizations;
    };
    WfmSyncService.prototype.getDataOrgApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.orgsync.lookup;
    };
    WfmSyncService.prototype.depDataDetails = function (empId, orgLevelId, isActive) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.depDataStruct(empId, orgLevelId, isActive);
                        params = {};
                        if (empId) {
                            params['empId'] = empId.toString();
                        }
                        if (orgLevelId) {
                            params['orgLevelId'] = orgLevelId.toString();
                        }
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.depDataStruct = function (empId, orgLevelId, isActive) {
        return this.getDataDepApiRoot() + "/" + configurationConfig.api.configuration.orgsync.department;
    };
    WfmSyncService.prototype.getDataDepApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.orgsync.lookup;
    };
    WfmSyncService.prototype.posDataDetails = function (empId, orgLevelId, isActive) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.posDataStruct(empId, orgLevelId, isActive);
                        params = {};
                        if (empId) {
                            params['empId'] = empId.toString();
                        }
                        if (orgLevelId) {
                            params['orgLevelId'] = orgLevelId.toString();
                        }
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.posDataStruct = function (empId, orgLevelId, isActive) {
        return this.getDataPosApiRoot() + "/" + configurationConfig.api.configuration.orgsync.position;
    };
    WfmSyncService.prototype.getDataPosApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.orgsync.lookup;
    };
    WfmSyncService.prototype.putErrorDetails = function (evetErrorReort) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.putErrorSync();
                body = evetErrorReort;
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.putErrorSync = function () {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.event + "/" + configurationConfig.api.configuration.wfm.error + "/" + configurationConfig.api.configuration.wfm.errordetails;
    };
    WfmSyncService.prototype.GetBenefitDeductionChangeLogs = function (workFlowName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.BenefitDeductions();
                        params = {};
                        if (workFlowName) {
                            params['workFlowName'] = workFlowName;
                        }
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        console.log(response);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.BenefitDeductions = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.root + "/" + configurationConfig.api.configuration.employeeBenifits.root + "/" + configurationConfig.api.configuration.employeeBenifits.sync;
    };
    WfmSyncService.prototype.benefitdeductionSyncOrg = function (req, workFlowType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.BenefitdeductSyncOrgRoot();
                params = {};
                if (workFlowType) {
                    params['workFlowType'] = workFlowType;
                }
                body = req;
                request = this.urlParamsService.createPostRequest(url, body, params);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.BenefitdeductSyncOrgRoot = function () {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.sync + "/" + configurationConfig.api.configuration.wfm.employeeBenifits;
    };
    WfmSyncService.prototype.GetLabourCodeChangeLogs = function (workFlowName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.Labourcodes();
                        params = {};
                        if (workFlowName) {
                            params['workFlowName'] = workFlowName;
                        }
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        console.log(response);
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.Labourcodes = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.root + "/" + configurationConfig.api.configuration.costcenter.root + "/" + configurationConfig.api.configuration.costcenter.sync;
    };
    WfmSyncService.prototype.LabourCodeSyncOrg = function (req, workFlowType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.LabourcodeSyncOrgRoot();
                params = {};
                if (workFlowType) {
                    params['workFlowType'] = workFlowType;
                }
                body = req;
                request = this.urlParamsService.createPostRequest(url, body, params);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.LabourcodeSyncOrgRoot = function () {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.sync + "/" + configurationConfig.api.configuration.wfm.costcenter;
    };
    WfmSyncService.prototype.postEventSync = function (startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.postEventResponse(startDate, endDate);
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.postEventResponse = function (startdate, enddate) {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.post + "/" + configurationConfig.api.wfm.event + "/" + configurationConfig.api.wfm.startdate + "/" + startdate + "/" + configurationConfig.api.wfm.enddate + "/" + enddate;
    };
    WfmSyncService.prototype.postSyncEvent = function (eventid) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.postEvent(eventid);
                body = eventid;
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.postEvent = function (eventid) {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.post + "/" + configurationConfig.api.wfm.event + "/" + eventid + "/" + configurationConfig.api.wfm.sync;
    };
    WfmSyncService.prototype.syncDashboard = function (workFlowName, workFlowType, IsPayrollAppStatusWorkflow) {
        if (IsPayrollAppStatusWorkflow === void 0) { IsPayrollAppStatusWorkflow = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.syncDashBoardApi(workFlowName, workFlowType, IsPayrollAppStatusWorkflow);
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.syncDashBoardApi = function (workFlowName, workFlowType, IsPayrollAppStatusWorkflow) {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.dashBoard + "/" + workFlowName + "/" + workFlowType + "/" + IsPayrollAppStatusWorkflow;
    };
    WfmSyncService.prototype.syncPostDashboard = function (workFlowName, workFlowType, status, IsPayrollAppStatusWorkflow) {
        if (IsPayrollAppStatusWorkflow === void 0) { IsPayrollAppStatusWorkflow = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.syncPostDashBoardApi(workFlowName, workFlowType, status, IsPayrollAppStatusWorkflow);
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.syncPostDashBoardApi = function (workFlowName, workFlowType, status, IsPayrollAppStatusWorkflow) {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.wfm.dashBoard + "/" + configurationConfig.api.configuration.wfm.drillDown + "/" + workFlowName + "/" + workFlowType + "/" + status + "/" + IsPayrollAppStatusWorkflow;
    };
    WfmSyncService.prototype.getSyncStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.syncGetApi();
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.syncGetApi = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.sync + "/" + configurationConfig.api.wfm.flagstatus;
    };
    WfmSyncService.prototype.postSyncStatus = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.syncPostApi();
                body = req;
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.syncPostApi = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.sync + "/" + configurationConfig.api.wfm.flagstatus + "/" + configurationConfig.api.wfm.update;
    };
    WfmSyncService.prototype.getStopSyncStatus = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.syncStopGetApi();
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.syncStopGetApi = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.stop + "/" + configurationConfig.api.wfm.sync;
    };
    WfmSyncService.prototype.stopSyncStatus = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.stopSyncyncPostApi();
                body = req;
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.stopSyncyncPostApi = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.stop + "/" + configurationConfig.api.wfm.sync;
    };
    WfmSyncService.prototype.resync = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.resyncApi();
                body = req;
                request = this.urlParamsService.createPostRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.resyncApi = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.resync;
    };
    WfmSyncService.prototype.secondaryPositions = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.wfm.root + "/" + configurationConfig.api.wfm.sync + "/" + configurationConfig.api.wfm.secondaryPositions;
    };
    WfmSyncService.prototype.getSecondaryPositionsDataSync = function (workFlowName) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "" + this.secondaryPositions();
                        params = {};
                        if (workFlowName) {
                            params['workFlowName'] = workFlowName;
                        }
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response];
                }
            });
        });
    };
    WfmSyncService.prototype.secondaryPositionSyncOrg = function (req, workFlowType) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = "" + this.secondaryPositionOrgRoot();
                params = {};
                if (workFlowType) {
                    params['workFlowType'] = workFlowType;
                }
                body = req;
                request = this.urlParamsService.createPostRequest(url, body, params);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response;
                    })];
            });
        });
    };
    WfmSyncService.prototype.secondaryPositionOrgRoot = function () {
        return this.getMessagesApiRoot() + "/" + configurationConfig.api.configuration.root + "/" + configurationConfig.api.wfm.secondaryPositions + "/" + configurationConfig.api.wfm.sync;
    };
    return WfmSyncService;
}());
export { WfmSyncService };
