import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';
import { LMManagementService, LMApiService } from '../../services/index';
import { LoaConsole, MetaContainer, LoaSecurityActions } from '../../models/index';
import { OrgLevel } from '../../../../state-model/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-console',
  templateUrl: 'lm-console.component.html',
  styleUrls: ['lm-console.component.scss']
})
export class LMConsoleComponent implements OnInit, OnDestroy {
  public isOverviewCollapsed: boolean;
  public isOnLeaveCollapsed: boolean;
  public isReturningCollapsed: boolean;
  public isUpcomingCollapsed: boolean;
  public isPastDueCollapsed: boolean;
  public isLoading: boolean;
  public loaConsole: LoaConsole;

  private orgLevel: OrgLevel;
  private subscriptions: StringMap<Subscription> = {};

  constructor (private managementService: LMManagementService, private lmApiService: LMApiService) { }

  public ngOnInit(): void {
    this.managementService.init();

    this.subscriptions.newLoaRequest = this.managementService
      .subscribeToLoadRequests(() => this.loadConsole());

    this.subscriptions.orgLevel = this.managementService.orgLevelChanged$
      .subscribe((orgLevel: OrgLevel) => {
        this.orgLevel = orgLevel;
        this.isLoading = true;
        this.loadConsole();
      });
  }

  public loadConsole(): void {
    this.lmApiService.getLoaConsole(this.orgLevel.id)
      .then((container: MetaContainer<LoaConsole>) => {
        let actions = container.actions;
        this.setActions(actions);
        this.loaConsole = container.data;
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  private setActions(actions: string[]): void {
    this.managementService.canDelete = _.findIndex(actions, x => x == LoaSecurityActions.canDelete) >= 0;
    this.managementService.canAddEdit = _.findIndex(actions, x => x == LoaSecurityActions.canAddEdit) >= 0;
  }
}
