import * as _ from 'lodash';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Employer, AleGroupView  } from '../../../../../configuration/models/employers/index';
import { EmployersManagementService } from '../../../../services/employers/employers-configuration-management.service';
import { Subscription } from 'rxjs';
import {PopoverContentComponent } from '../../../../../common/index';

@Component({
  selector: 'slx-manage-ale-groups',
  templateUrl: './manage-ale-groups.component.html',
  styleUrls: ['./manage-ale-groups.component.scss'],
  providers: [EmployersManagementService]
})

export class ManageAleGroupsComponent implements OnInit {
  public groups: AleGroupView[];
  public storedGroups: AleGroupView[];
  public employers: Employer[];
  public isDragAndDrop: boolean = false;

  public get isEditing(): boolean {
    return _.isObjectLike(this.editingGroup);
  }

  @Output()
  public onLoading = new EventEmitter<boolean>();
  @Output()
  public onHasChanges = new EventEmitter<boolean>();
  @Output()
  public isGroupChanges = new EventEmitter<boolean>();
  @Output()
  public isSaveComplete = new EventEmitter<boolean>();
  
  public searchText: string = '';
  constructor(private manService: EmployersManagementService) { }
  private subscriptions: StringMap<Subscription> = {};

  private movedFrom: AleGroupView;
  private movedEmployer: Employer;
  private groupNameIsValid: boolean = false;
  private editingGroup: AleGroupView;
  private indexToInsertNewGroup = 0;

  ngOnInit() {

    this.subscriptions.loadingAleGroup = this.manService
      .subscribeToLoadingAleGroup((isLoading: boolean) => {
        this.onLoading.emit(isLoading);
      });

    this.subscriptions.aleGroups = this.manService
      .subscribeToLoadAleGroups((aleGroups) => {
        this.groups = aleGroups;
        this.storedGroups = _.cloneDeep(aleGroups);
      });

    this.subscriptions.onSaveComplete = this.manService.subscribeToSaveComplete(() => {
      this.isSaveComplete.emit(true);
    });

    this.manService.init();
  }

  public isDefaultGroup(group: AleGroupView): boolean {
    return group.groupId === this.manService.defaultGroupId;
  }

  public onAddGroup(): void {
    const group = this.manService.createGroupView();
    group.isEditing = true;
    this.groups.splice(this.indexToInsertNewGroup, 0, group);
    this.toggleEdit(group, true);
  }

  public onEdit(group: AleGroupView): void {
    if (this.canEditGroup(group)) {
      this.toggleEdit(group, true);
    }
  }

  public canSaveGroup(group: AleGroupView): boolean {
    return this.isEditing && group.isEditing && _.size(group.groupName) > 0 && this.groupNameIsValid;
  }

  public async onSave(group: AleGroupView): Promise<void> {
    if (this.canSaveGroup(group)) {
      if (!group.groupId) {
        await this.createGroup(group);
      } else {
        await this.updateGroup(group);
      }
      this.emitChanges();
    }
  }

  private async updateGroup(group: AleGroupView): Promise<void> {
    const updatedGroup = await this.manService.updateGroup(group);

    if (updatedGroup) {
      group.groupName = updatedGroup.groupName;
      const storedIndex = this.findIndex(this.storedGroups, group);
      if (storedIndex !== -1) {
        this.storedGroups[storedIndex].groupName = updatedGroup.groupName;
      }
      this.toggleEdit(group, false);
    }
  }

  private async createGroup(g: AleGroupView): Promise<void> {
    const createdGroup = await this.manService.addUpdateGroup(g);
    if (createdGroup) {
      const index = this.deleteGroup(this.groups, g);
      if (index !== -1) {
        this.groups.splice(index, 0, createdGroup);
        this.storedGroups.splice(index, 0, _.cloneDeep(createdGroup));
      }
      this.toggleEdit(createdGroup, false);
    }
  }

  public async onDelete(isDelete: boolean, acceptPopover: PopoverContentComponent, group: AleGroupView): Promise<void> {
    acceptPopover.hide();
    if (isDelete) {
      if (this.canRemoveGroup(group) && (group.groupId >= 0)) {
        group.deleted = true;
        await this.manService.deleteGroup(group);
        this.deleteGroup(this.groups, group);
        this.deleteGroup(this.storedGroups, group);
        this.emitChanges();
      }
    }
  }

  public onCancel(group: AleGroupView): void {
    if (group.groupId == null) {
      this.deleteGroup(this.groups, group);
    }
    this.toggleEdit(group, false, true);
  }
  public onInputGroupName(model: NgModel, group: AleGroupView): void {
    const groupName = _.trim(_.toLower(group.groupName));
    const groupViews = _.filter(this.groups, (g) => _.trim(_.toLower(g.groupName)) === groupName);
    if (_.size(groupViews) > 1) {
      model.control.setErrors({ 'uniqueName': true });
      this.groupNameIsValid = false;
    } else {
      const errors = model.control.errors || {};
      delete errors['uniqueName'];
      model.control.setErrors(errors);
      this.groupNameIsValid = _.size(errors) === 0;
    }
  }

  private deleteGroup(groups: AleGroupView[], group: AleGroupView): number {
    const index = this.findIndex(groups, group);
    if (index !== -1) {
      groups.splice(index, 1);
    }
    return index;
  }

  private toggleEdit(group: AleGroupView, isEditing: boolean, isRestore: boolean = false): void {
    if (isEditing) {
      this.editingGroup = _.cloneDeep(group);
      group.isEditing = true;
      return;
    }

    if (isRestore) {
      group.groupName = this.editingGroup.groupName;
    }
    group.isEditing = false;
    this.editingGroup = null;
  }

  public async onDragAdd(event: { dataItem: Employer }): Promise<void> {
    this.movedEmployer = event.dataItem;
  }

  public onDragStart(group: AleGroupView): void {
    this.movedFrom = group;
  }

  public async onDragEnd(movedTo: AleGroupView): Promise<void> {
    if (this.canDragNDropGroups() && this.movedEmployer) {
      this.isGroupChanges.emit(false);
      this.manService.assignGroupsToEmployers(movedTo, this.movedEmployer);
      if (this.movedFrom && this.movedFrom.employers && this.movedFrom.employers.length > 0) {
        this.movedFrom.disabled = true;
      }
      this.applyChangesForStoredGroups(this.movedFrom, movedTo, this.movedEmployer);
      this.emitChanges();
    }
  }
  public canDragNDropGroups(): boolean {
    return !this.isEditing;
  }
  public canRemoveGroup(group: AleGroupView): boolean {
    return !this.isEditing && group.employers.length === 0;
  }

  public canEditGroup(group: AleGroupView): boolean {
    return !this.isEditing && !group.isEditing;
  }

  public async saveGroupAssignments() : Promise<void>{
    await this.manService.saveGroupAssignments();
    this.isGroupChanges.emit(true);
  }
  private applyChangesForStoredGroups(movedFrom: AleGroupView, movedTo: AleGroupView, emp: Employer): void {
    const employer = _.cloneDeep(emp);
    const movedFromIndex = this.findIndex(this.storedGroups, movedFrom);
    if (movedFromIndex !== -1) {
      _.remove(this.storedGroups[movedFromIndex].employers, (e) => e.companyId === employer.companyId);
    }
  }

  private findIndex(groups: AleGroupView[], group: AleGroupView): number {
    return _.findIndex(groups, (g) => g.groupId === group.groupId || g.groupName === group.groupName);
  }

  private emitChanges(): void {
    this.onHasChanges.emit(true);
  
  }

  public onFilterChanged(): void {
    this.groups = this.manService.filteringGroups(this.storedGroups, this.searchText);
  }

  public onResetSearch(): void {
    if (this.searchText.length > 0) {
      this.searchText = '';
      this.onFilterChanged();
    }
  }
}
