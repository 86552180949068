<div class="flex-horizontal row-panel">
  <button class="refresh-button pull-right" type="button">
    <span class="text-button-content" (click)="onRefreshClicked()">Refresh</span>
  </button>
</div>
<kendo-grid [data]="gridState.view" [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort" 
  scrollable="'scrollable'" (sortChange)="gridState.sortChange($event)"
  selectable="true" (selectionChange)="gridState.selectionChange($event)" (remove)="onStartRemove($event.dataItem)" [kendoGridSelectBy]="gridState.itemKey"  [selectedKeys]="gridState.selectedRowsIds">
  <kendo-grid-command-column width="30">
    <ng-template kendoGridCellTemplate  let-dataItem>
      <button kendoGridRemoveCommand class="remove-button" [disabled]="!(dataItem | checkFutureUpdateRemovablePipe : now)">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column width="150" field="description">
    <ng-template kendoGridHeaderTemplate>
      Description
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.description }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="50" field="requestedOn">
    <ng-template kendoGridHeaderTemplate>
      Requested On
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.requestedOn | amDateFormat: appConfig.dateTimeFormat }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="50" field="scheduledFor">
    <ng-template kendoGridHeaderTemplate>
      Scheduled For
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.scheduledFor | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="50" field="processedOn">
    <ng-template kendoGridHeaderTemplate>
      Processed On
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.processOn | amDateFormat: appConfig.dateFormat }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="50" field="requestedBy">
    <ng-template kendoGridHeaderTemplate>
      Requested By
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.requestedBy }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column width="50" field="updateSystem">
    <ng-template kendoGridHeaderTemplate>
      Update System
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.updateSystem }}
    </ng-template>
  </kendo-grid-column>
  <div *kendoGridDetailTemplate="let dataItem" class="futureUpdatesDetails">
    <div class="col-sm-2">
      <label class="futureUpdatesDetailsLabel">Updated Fields:</label>
    </div>
    <div *ngFor="let field of dataItem.updatedFields" class="col-sm-3 col-lg-2">
      <div [ngSwitch]="field.fieldType">
        <slx-input-decorator *ngSwitchCase="'datetime'">
          <slx-date-picker-ngx slx-input
            [placeholder]="field.displayName"
            [name]="field.fieldName"
            [readonly]="true"
            [(ngModel)]="field.fieldData.fieldValue"
          ></slx-date-picker-ngx>
        </slx-input-decorator>
         <slx-input-decorator *ngSwitchDefault>
          <input slx-input type="text" [readonly]="true" [name]="field.fieldName" [placeholder]="field.displayName"
            [ngModel]="field.fieldData.fieldValue">
        </slx-input-decorator>
      </div>
    </div>
  </div>
  </kendo-grid>
