<div class="main-info">
    <div class="timeclock-icon">
        <div class="color-badge" [ngClass]="{'red-alert': timeclockDailySummary.communicationStatus === 0}" ></div>
    </div>
    <div class="title-block">
        <div title="{{timeclockDailySummary?.name}}" class="timeclock-name-title">{{timeclockDailySummary?.name}}</div>
        <div class="clock-id">CLOCK ID: {{timeclockDailySummary?.id}}</div>
    </div>
    <div class="actions-block" *ngIf="expandable">
        <i *ngIf="!expanded" class="fa fa-angle-up" aria-hidden="true" (click)="toggleExpand()"></i>
        <i *ngIf="expanded" class="fa fa-angle-down" aria-hidden="true" (click)="toggleExpand()"></i>
    </div>
    <div *ngIf="timeclockDailySummary.communicationStatus === 1">
    <div *ngIf="!timeclockDailySummary.isVirtual "> 
        <span  (click)="clockRebootDialog(timeclockDailySummary?.id,timeclockDailySummary?.name)">
            <span *ngIf="timeclockDailySummary.rebootStatus!=='Pending' && !timeclockDailySummary.isTimeClockRebootStarted" class="fal fa-cog reboot-icon" ></span>
        </span> 
        <span>
            <span *ngIf="timeclockDailySummary.rebootStatus==='Pending' || timeclockDailySummary.isTimeClockRebootStarted" class="reboot-icon">Restarting..</span>
        </span> 
    </div>
    </div>
</div>

<div *ngIf="expanded" class="additional-info">
    <div class="statuses-info">
        <slx-dropdown-timeclock-status-list [timeclockStatus]="timeclockDailySummary"></slx-dropdown-timeclock-status-list>
    </div>
    <div class="bottom-info">
        <span class="bottom-item">
            <span class="item-label">Enrollment:</span>
            <span class="item-value">{{timeclockDailySummary?.enrolledCount}}/{{timeclockDailySummary?.assignedCount}}</span>
        </span>
        <span class="bottom-item">
            <span class="item-label">Comm:</span>
            <span class="item-value">{{timeclockDailySummary?.lastCommunicationDate|amDateFormat: appConfig.dateTimeFormatUS}}</span>
        </span>
    </div>
</div>
