export class BenefitDetailsUserActions {
  constructor(
    public canAdd: boolean = false,
    public canEdit: boolean = false,
    public canDelete: boolean = false,
    public canSave: boolean = false,
    public enabledExpire: boolean = false,
    public enabledExtend: boolean = false,
    public enabledRenew: boolean = false,
    public canExpire: boolean = false,
    public canExtend: boolean = false,
    public canRenew: boolean = false,
    public сanEditFormula: boolean = false,
    public canAddCovOptions: boolean = false,
    public canEnroll: boolean = false,
    public canMapPayroll: boolean = false,
    public benefitDeduction2: boolean = false,
  ) { }
}
