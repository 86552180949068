export * from './star-rating/star-rating.component';
export * from './sraffing-predictor-rating-badge/staffing-predictor-rating-badge.component';
export * from './staffing-predictor-rating-predictor/staffing-predictor-rating-predictor.component';
export * from './staffing-predictor-star-rating/staffing-predictor-star-rating.component';
export * from './staffing-predictor-section/staffing-predictor-section.component';
export * from './staffing-predictor-header/staffing-predictor-header.component';
export * from './staffing-predictor/staffing-predictor.component';
export * from './predictor-matrix/predictor-matrix.component';
export * from './predictor-matrix-dialog/predictor-matrix-dialog.component';
import { StaffingPredictorComponent } from './staffing-predictor/staffing-predictor.component';
import { StaffingPredictorSectionComponent } from './staffing-predictor-section/staffing-predictor-section.component';
import { StaffingPredictorHeaderComponent } from './staffing-predictor-header/staffing-predictor-header.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { StaffingPredictorRatingBadgeComponent } from './sraffing-predictor-rating-badge/staffing-predictor-rating-badge.component';
import { StaffingPredictorRatingPredictorComponent } from './staffing-predictor-rating-predictor/staffing-predictor-rating-predictor.component';
import { StaffingPredictorStarRatingComponent } from './staffing-predictor-star-rating/staffing-predictor-star-rating.component';
import { FivestarPredictorStarRatingComponent } from './fivestar-predictor-star-rating/fivestar-predictor-star-rating.component';
import { FivestarPredictorRatingBadgeComponent } from './fivestar-predictor-rating-badge/fivestar-predictor-rating-badge.component';
import { FivestarPredictorRatingPredictorComponent } from './fivestar-predictor-rating-predictor/fivestar-predictor-rating-predictor.component';
import { PredictorMatrixComponent } from './predictor-matrix/predictor-matrix.component';
import { PredictorMatrixDialogComponent } from './predictor-matrix-dialog/predictor-matrix-dialog.component';
export var staffingPredictorEntryComponents = [PredictorMatrixDialogComponent];
export var components = [
    StarRatingComponent,
    StaffingPredictorComponent,
    StaffingPredictorSectionComponent,
    StaffingPredictorHeaderComponent,
    StaffingPredictorRatingBadgeComponent,
    StaffingPredictorRatingPredictorComponent,
    StaffingPredictorStarRatingComponent,
    PredictorMatrixComponent,
    FivestarPredictorStarRatingComponent,
    FivestarPredictorRatingBadgeComponent,
    FivestarPredictorRatingPredictorComponent
].concat(staffingPredictorEntryComponents);
