import { Directive, Input, AfterViewInit, ElementRef, Renderer2, RendererStyleFlags2, OnDestroy } from '@angular/core';

@Directive({ selector: '[slxTooltip]' })
export class SlxTooltipDirective implements AfterViewInit, OnDestroy {
  @Input()
  public set slxTooltip(value: string) {
    this.m_slxTooltip = value;
    this.updateTooltip();
  }
  public get slxTooltip(): string {
    return this.m_slxTooltip;
  }

  @Input()
  public tipPosition: 'left' | 'right' | 'top' | 'bottom';
  @Input()
  public tipClass: string;


  public parentNode: any;

  private m_slxTooltip: string;
  private tooltip: any;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {

  }
  public ngAfterViewInit(): void {
    let parentNode: any = this.renderer.parentNode(this.elementRef.nativeElement);
    this.parentNode = parentNode;
    this.renderer.setStyle(parentNode, 'overflow', 'visible', RendererStyleFlags2.Important);
    this.renderer.addClass(parentNode, 'theme-tooltip');
    if(this.tipClass) {
      this.renderer.addClass(parentNode, this.tipClass);
    }
    this.tooltip = this.renderer.createElement('span');
    this.renderer.addClass(this.tooltip, 'theme-tooltiptext');
    this.renderer.addClass(this.tooltip, this.tipPosition ? this.tipPosition : 'top');
    this.renderer.setProperty(this.tooltip, 'innerHTML', this.slxTooltip);
    this.renderer.appendChild(parentNode, this.tooltip);
  }

  ngOnDestroy() {
    this.renderer.removeChild(this.parentNode, this.tooltip);
    this.renderer.removeStyle(this.parentNode, 'overflow');
    this.renderer.removeClass(this.parentNode, 'theme-tooltip');
  }

  private updateTooltip(): void {
    if(this.tooltip) {
      this.renderer.setProperty(this.tooltip, 'innerHTML', this.slxTooltip);
    }
  }

}
