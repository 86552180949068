/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../dropdown-list/dropdown-list.component.ngfactory";
import * as i2 from "../dropdown-list/dropdown-list.component";
import * as i3 from "@angular/forms";
import * as i4 from "./daterange-list.component";
var styles_DateRangeListComponent = [];
var RenderType_DateRangeListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DateRangeListComponent, data: {} });
export { RenderType_DateRangeListComponent as RenderType_DateRangeListComponent };
export function View_DateRangeListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "slx-dropdown-list", [["class", "slx-wide slx-short"], ["name", "selectedRange"], ["titleField", "name"], ["valueField", "id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedRange = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DropdownListComponent_0, i1.RenderType_DropdownListComponent)), i0.ɵdid(1, 49152, null, 0, i2.DropdownListComponent, [], { options: [0, "options"], valueField: [1, "valueField"], valueIconClass: [2, "valueIconClass"], titleField: [3, "titleField"], readonly: [4, "readonly"], placeholder: [5, "placeholder"] }, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DropdownListComponent]), i0.ɵdid(3, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(5, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.options; var currVal_8 = "id"; var currVal_9 = _co.valueIconClass; var currVal_10 = "name"; var currVal_11 = _co.readonly; var currVal_12 = _co.placeholder; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_13 = "selectedRange"; var currVal_14 = _co.selectedRange; _ck(_v, 3, 0, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 5).ngClassValid; var currVal_5 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DateRangeListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "slx-daterange-list", [], null, null, null, View_DateRangeListComponent_0, RenderType_DateRangeListComponent)), i0.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DateRangeListComponent]), i0.ɵdid(2, 49152, null, 0, i4.DateRangeListComponent, [], null, null)], null, null); }
var DateRangeListComponentNgFactory = i0.ɵccf("slx-daterange-list", i4.DateRangeListComponent, View_DateRangeListComponent_Host_0, { listRanges: "listRanges", format: "format", placeholder: "placeholder", readonly: "readonly", valueIconClass: "valueIconClass" }, { valueChanged: "valueChanged" }, []);
export { DateRangeListComponentNgFactory as DateRangeListComponentNgFactory };
