import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { KendoGridStateHelper, saveEvent, removeEvent, ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';

import { EmployeeSubsectionDependents, EmployeeDependentEnrollments } from '../../../models/index';
import { EmployeeSectionsBenefitsManagementService } from '../../../services/index';
import {
  BenefitEmployeeDependent,
  BenefitEmpDependentEnrollment,
  BenefitEligibleDependentBenefit,
  BenefitDependentDroppedEnrollment
} from '../../../../../app-modules/benefits/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-dependents-enrollment-grid',
  templateUrl: 'employee-sections-dependents-enrollment-grid.component.html',
  styleUrls: ['employee-sections-dependents-enrollment-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsDependentsEnrollmentGridComponent implements OnInit, OnDestroy {
  @Input()
  public set dependent(v: BenefitEmployeeDependent) {
    if (_.isObjectLike(v)) {
      this.selectedDependent = v;
      this.manService.loadDependentEnrollments(v)
    }
  }

  public gridState: KendoGridStateHelper<BenefitEmpDependentEnrollment>;
  public pageSize: number = 5;
  public enrollmentRecords: BenefitEmpDependentEnrollment[] = [];
  public selectedDependent: BenefitEmployeeDependent;
  public appConfig: IApplicationConfig;
  public globalEditMode: boolean = false;

  @ViewChild('gridEnrollment', { static: true })
  public gridEnrollment: GridComponent;

  public get isEditMode(): boolean {
    return _.isObjectLike(this.editedEnrollment);
  }
  public get canEdit(): boolean {
    return this.dependentSection.canEditDependents && !this.isEditMode && !this.globalEditMode;
  }
  public get canDelete(): boolean {
    return this.canDrop && !this.isEditMode && !this.globalEditMode;
  }
  public get canDrop(): boolean {
    return this.dependentSection.canDropCoverageDependents;
  }
  public pattern = /[^\w\s]|[_]/gi;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private editedEnrollment: BenefitEmpDependentEnrollment;
  private dependentSection = new EmployeeSubsectionDependents();

  constructor(
    private modalService: ModalService,
    private manService: EmployeeSectionsBenefitsManagementService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.appConfig = appConfig;
    this.gridState = new KendoGridStateHelper<BenefitEmpDependentEnrollment>();
    this.gridState.state.take = this.pageSize;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'benefitName', dir: 'asc' }, { field: 'startDate', dir: 'asc' }];
  }

  public ngOnInit(): void {
    this.subscriptions.section = this.manService
      .subscribeToDependentsSubsection((sec: EmployeeSubsectionDependents) => {
        this.dependentSection = sec;
      });

    this.subscriptions.enrollments = this.manService
      .subscribeToChangedEnrollments((dep: EmployeeDependentEnrollments) => {
        if (dep.dependentId === this.selectedDependent.id) {
          this.enrollmentRecords = dep.enrollments;
          this.updateEnrollment();
        }
      });

    this.subscriptions.changeDep = this.manService
      .subscribeToChangedEnrollment(({ enrollment, isCreated, isEdited, isDeleted }) => {
        if (enrollment.dependentId === this.selectedDependent.id) {
          const index = _.findIndex(this.enrollmentRecords, d => d.empEnrollmentId === enrollment.empEnrollmentId);
          if (isCreated) {
            this.enrollmentRecords.push(enrollment);
          } else if (isEdited && index !== -1) {
            this.enrollmentRecords.splice(index, 1, enrollment);
          } else if (isDeleted && index !== -1) {
            this.enrollmentRecords.splice(index, 1);
            this.manService.changeEnrollmentsQuantity(enrollment.dependentId, _.size(this.enrollmentRecords));
          }
          this.editedEnrollment = null;
          this.updateEnrollment();
        }
      });

    this.subscriptions.grid = this.gridState.onRefreshGrid
      .subscribe((): void => {
        this.updateEnrollment();
      });

    this.subscriptions.gridSave = this.gridState.onSave$
      .subscribe((event: saveEvent<BenefitEmpDependentEnrollment>) => {
        this.gridState.closeEditor(this.gridEnrollment);
        if (event.isNew) {
          this.manService.addEnrollment(event.dataItem);
          this.manService.toggleEditMode(false);
          return;
        }
        this.manService.editEnrollment(event.dataItem);
        this.manService.toggleEditMode(false);
      });

    this.subscriptions.gridRemove = this.gridState.onRemove$
      .subscribe((event: removeEvent<BenefitEmpDependentEnrollment>) => {

        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog(
          'Delete Benefit',
          'Are you sure you want to delete the selected benefit? This will terminate coverage for the dependent.',
          this.modalService,
          (isDelete: boolean) => {
            if (isDelete) {
              this.manService.deleteEnrollment(event.dataItem);
              this.editedEnrollment = null;
            }
          },
          options);

      });

    this.subscriptions.gridEdit = this.gridState.onEdit$
      .subscribe((dependent: BenefitEmpDependentEnrollment) => {
        this.editedEnrollment = dependent;
        this.manService.toggleEditMode(true);
        Observable.of(true)
          .delay(50)
          .subscribe(() => this.updateEnrollment());
      });

    this.subscriptions.gridCancel = this.gridState.onCancel$
      .subscribe(() => {
        this.editedEnrollment = null;
        this.manService.toggleEditMode(false);
        this.updateEnrollment();
      });

    this.subscriptions.globalEditing = this.manService
      .subscribeToEditMode((isEdit: boolean): void => {
        this.globalEditMode = isEdit;
        this.updateEnrollment();
      });

    this.subscriptions.reset = this.manService
      .subscribeToReset((): void => {
        this.resetEditMode();
        this.updateEnrollment();
      });
  }

  public ngOnDestroy(): void {
    this.enrollmentRecords = [];
  }

  public isEditingRow(dep: BenefitEmpDependentEnrollment): boolean {
    return _.isObjectLike(this.editedEnrollment) && _.isObjectLike(dep) && this.editedEnrollment.id === dep.id;
  }

  public onDropDependentEnrollment(dropEnrollment: BenefitDependentDroppedEnrollment, item: BenefitEmpDependentEnrollment): void {
    dropEnrollment.empDependentEnrollmentId = item.id;
    this.manService.dropDependentEnrollment(dropEnrollment)
      .then((rec) => this.updateRecord(rec));
  }

  private updateRecord(record: BenefitEmpDependentEnrollment): void {
    const index = _.findIndex(this.enrollmentRecords, { id: record.id });
    if (index !== -1) {
      this.enrollmentRecords.splice(index, 1, record);
      this.updateEnrollment();
    }
  }

  private updateEnrollment(): void {
    this.refreshGrid();
    this.changeDetector.detectChanges();
  }

  private resetEditMode(): void {
    this.gridState.closeEditor(this.gridEnrollment);
    this.editedEnrollment = null;
  }

  private refreshGrid(): void {
    if (!this.enrollmentRecords) {
      return;
    }
    this.gridState.view = process(this.enrollmentRecords, this.gridState.state);
  }
}
