/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirm-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./confirm-dialog.component";
import * as i4 from "../../models/dialog-options";
import * as i5 from "../../services/modal/modal.service";
import * as i6 from "@angular/router";
var styles_ConfirmDialogComponent = [i0.styles];
var RenderType_ConfirmDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmDialogComponent, data: {} });
export { RenderType_ConfirmDialogComponent as RenderType_ConfirmDialogComponent };
function View_ConfirmDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getRouterLink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Click Here "]))], null, null); }
function View_ConfirmDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "space-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.confirmOptions == null) ? null : _co.confirmOptions.notes); _ck(_v, 2, 0, currVal_0); }); }
function View_ConfirmDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-cancel margin-r"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cancelButtonText; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfirmDialogComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "theme-button-apply"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onOk() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.okButtonText; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConfirmDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "bootbox-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmDialogComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.confirmOptions.isShiftGroup; _ck(_v, 5, 0, currVal_2); var currVal_3 = ((_co.confirmOptions == null) ? null : _co.confirmOptions.notes); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.confirmOptions.showCancel; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.confirmOptions.showOK; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "modal-content ", ((_co.confirmOptions == null) ? null : _co.confirmOptions.className), ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = ((_co.options == null) ? null : _co.options.message); _ck(_v, 3, 0, currVal_1); }); }
export function View_ConfirmDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-confirm-dialog", [], null, null, null, View_ConfirmDialogComponent_0, RenderType_ConfirmDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.ConfirmDialogComponent, [i4.DialogOptions, i5.ModalService, i3.ConfirmOptions, i6.Router], null, null)], null, null); }
var ConfirmDialogComponentNgFactory = i1.ɵccf("slx-confirm-dialog", i3.ConfirmDialogComponent, View_ConfirmDialogComponent_Host_0, {}, {}, []);
export { ConfirmDialogComponentNgFactory as ConfirmDialogComponentNgFactory };
