import { Injectable } from '@angular/core';
import { ToolbarBaseService } from '../../../core/services/index';

@Injectable()
export class TaConsoleToolbarService extends ToolbarBaseService<any>  {

    constructor() {
        super();
      }
    
}
