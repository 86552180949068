<div>
  <div>
    <div class="calendar-host">
      <div class="table-header">
        <div class="weekday-cell" *ngFor="let dayHeader of dayColumns">
          <span class="theme-lg-text">{{dayHeader.startOfDay | amDateFormat: appConfig.dayShortNameWeekDayFormat}}</span>
        </div>
      </div>
      <div class="table-body">
        <div class="table-row" *ngFor="let weeklyData of summaryDetailsSet?.weeklyData">
          <div class="table-cell" *ngFor="let dailyData of weeklyData.days" [ngClass]="{'selected-cell': dailyData?.firstOrDefault?.selected }">
            <slx-open-shift-management-schedule-cycle-details (click)="onSummaryItemSelectedHandler(dailyData?.firstOrDefault)" [detailsWrapper]="dailyData?.firstOrDefault"></slx-open-shift-management-schedule-cycle-details>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
