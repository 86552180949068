import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';

import { IdealScheduleStateService } from '../../../services/index';
import { IdealScheduleConfigTypeDefinition, IdealScheduleConfigCensusDefinition } from '../../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { IdealSchedulePositionRange } from '../../../models/ideal-schedule/ideal-schedule-position-range';

@Component({
  moduleId: module.id,
  selector: 'slx-ideal-schedule-grid',
  templateUrl: 'ideal-schedule-grid.component.html',
  styleUrls: ['ideal-schedule-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IdealScheduleGridComponent implements OnInit, OnDestroy {

  @Input()
  public range: IdealSchedulePositionRange;

  public IdealScheduleConfigTypeDefinition = IdealScheduleConfigTypeDefinition;
  public IdealScheduleConfigCensusDefinition = IdealScheduleConfigCensusDefinition;

  @unsubscribe()
  private stateServiceChangedSubscription: Subscription;

  constructor(
    public stateService: IdealScheduleStateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.stateServiceChangedSubscription = this.stateService.changed$.subscribe(() => {
      this.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  private detectChanges() : void {
    setTimeout(() => {
      this.changeDetector.markForCheck();
    }, 0);
  }
}
