<form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
    <kendo-grid #kendoGrid class="slx-blue-grid slx-full-height"
      [data]="gridState?.view"
      [selectable]="{ mode: 'single' }"
      [filterable]="'menu'"
      [filter]="gridState.state.filter"
      [sort]="gridState.state.sort"
      [sortable]="{ mode: 'multiple' }"
      (selectionChange)="gridState.selectionChange($event)"
      (dataStateChange)="gridState.dataStateChange($event)"
      (cancel)="gridState.cancelHandler($event)"
      (edit)="gridState.editHandler($event)"
      (save)="gridState.saveHandler($event)"
      [rowClass]="rowCallback"
    >
      <!-- desktop view -->
      <kendo-grid-command-column width="90" media="(min-width: 450px)" *ngIf="!management.state.bulkEditMode && management.state.canEdit">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
          <div class="command-container">
            <button kendoGridEditCommand type="button" class="slx-button slx-with-icon command-button">
              <i class="fas fa-pencil-alt slx-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid" class="slx-button slx-with-icon command-button">
              <i class="far fa-check-circle fa-2x slx-button__icon" aria-hidden="true"></i>
            </button>
            <button kendoGridCancelCommand type="button" class="slx-button slx-with-icon command-button">
              <i class="fa fa-ban fa-2x slx-button__icon" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column title="Description" field="locationName" [filterable]="true" media="(min-width: 450px)">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="left-align">{{ dataItem.locationName }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <input class="form-control" slx-input type="text" maxlength="50" [(ngModel)]="dataItem.locationName" [required]="true" placeholder="Description"
              name="descriptionInput{{rowIndex}}" [prohibitedValues]="management.getProhibitedNames(dataItem)">
            <span errorMessage for="required"></span>
            <span errorMessage for="prohibitedValues">This description already exists</span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Phone Number" field="phone" [filterable]="true" media="(min-width: 450px)">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="left-align">{{ dataItem.phone }}</span>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <slx-input-decorator>
            <slx-phone-input slx-input maxlength="50" phone class="form-control" [readonly]="false" [(ngModel)]="dataItem.phone" [required]="true" [placeholder]=""
              name="phoneInput{{rowIndex}}"
              [prohibitedValues]="management.getProhibitedPhones(dataItem)">
            </slx-phone-input>
            <span errorMessage for="required"></span>
            <span errorMessage for="phone"></span>
            <span errorMessage for="prohibitedValues">This phone already exists</span>
          </slx-input-decorator>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Authorized Number" field="authorized" [filterable]="true" media="(min-width: 450px)">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div class="cell-content">
            <i *ngIf="!management.state.bulkEditMode && dataItem.authorized" aria-hidden="true" class="fas fa-check"></i>
            <slx-checkbox-button *ngIf="management.state.bulkEditMode"
              [ngModel]="dataItem.authorized"
              (ngModelChange)="onItemFieldChange(dataItem, 'authorized', $event)"
              [readonly]="true"
              fieldName="authorizedField{{rowIndex}}"
              name="authorized{{rowIndex}}"
            ></slx-checkbox-button>
          </div>
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
          <div class="cell-content">
            <slx-checkbox-button
              [(ngModel)]="dataItem.authorized"
              fieldName="authorizedField{{rowIndex}}"
              name="authorized{{rowIndex}}"
            ></slx-checkbox-button>
          </div>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-boolean-filter [showOperators]="false" [column]="column" [filter]="filter">
          </slx-kendo-grid-boolean-filter>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="User" field="userName" [filterable]="true" media="(min-width: 450px)">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="left-align">{{ dataItem.userName }}</span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="Date" field="date" [filterable]="true" media="(min-width: 450px)">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
          </slx-kendo-grid-string-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span class="left-align">{{ dataItem.date | amDateFormat: appConfig.dateTimeFormat}}</span>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>


  </form>
