<slx-spinner [show]="state.isLoading">
  <kendo-grid class="slx-grid-indents" [data]="gridState.view" [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort" scrollable="none" (sortChange)="gridState.sortChange($event)"
    selectable="true" (selectionChange)="gridState.selectionChange($event)">
    <ng-template kendoGridToolbarTemplate position="top">
      <form novalidate>
        <slx-date-range-ngx
          name="startEndDate"
          [startDate]="startDate"
          [endDate]="endDate"
          (rangeDateChanged)="onFilterDateChanged($event)"
        ></slx-date-range-ngx>
      </form>
    </ng-template>
    <kendo-grid-column field="organizationName">
      <ng-template kendoGridHeaderTemplate>
        Organization Name
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.organization?.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="departmentName">
      <ng-template kendoGridHeaderTemplate>
        Department
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.department?.name }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="scheduleCycleStartDate">
      <ng-template kendoGridHeaderTemplate>
        Schedule Cycle Start Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [postScheduleLink]="dataItem.scheduleCycle?.startDate"> {{ dataItem.scheduleCycle?.startDate | amDateFormat: appConfig.dateFormat }}</a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="scheduleCycleEndDate">
      <ng-template kendoGridHeaderTemplate>
        Schedule Cycle End Date
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.scheduleCycle?.endDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="postedDate">
      <ng-template kendoGridHeaderTemplate>
        Date Posted
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.postedDate | amDateFormat: appConfig.dateFormat }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="username">
      <ng-template kendoGridHeaderTemplate>
        Posted by User Name
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.username }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</slx-spinner>
