import { Subscription } from 'rxjs/Subscription';

export class AccessProviderType {
  public static Menu: AccessProviderTypeDefinition = 'Menu';
}

export type AccessProviderTypeDefinition = 'Menu';

export interface IAccessibleProvider {
  isAccessible(permission: string): boolean;
  subscribeToAccessibleChanged(callback: () => void): Subscription;
}
