import { IEmployeePositionDefinition, EmployeePositionDefinition, ISupervisorGroupDefinition, SupervisorGroupDefinition } from '../../../organization/index';

export interface ISupervisedEmployee {
  id: number;
  badgeId: string;
  employee: IEmployeePositionDefinition;
  groups: ISupervisorGroupDefinition[];
}

export class SupervisedEmployee {

  public get numberGroups (): number {
    return this.groups ? this.groups.length : 0;
  }
  public id: number;
  public badgeId: string;
  public employee: EmployeePositionDefinition;
  public groups: SupervisorGroupDefinition[];
  public isSelected: boolean;
  public selectable: boolean = true;
}
