import { IPayType } from '../../models/lookup/pay-type';
import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import * as moment from 'moment';

import { LookupMapService } from '../lookup/lookup-map.service';
import { dateTimeUtils } from '../../../common/utils/index';
import {
  EmpType, Department, Position, PayCycle, Organization, EmployeeBenefitClass, IEmployeeBenefitClass, BenefitClass, IBenefitClass, IEmployeeResendOptInStatus, EmployeeResendOptInStatus
} from '../../../organization/models/index';
import {
  EmployeeDefinition, IEmployeeDefinition,
  EmployeePhoto, IEmployeePhoto,
  EmployeeShortInfo, IEmployeeShortInfo,
  EmployeeBadge, IEmployeeBadge,
  IEmployeePositionDefinition, EmployeePositionDefinition,
  IPayRuleDefinition, PayRuleDefinition, IEmployeeFieldValidation, EmployeeFieldValidation
} from '../../models/index';

@Injectable()
export class EmployeeDefinitionsMapService {
  private lookupMapService: LookupMapService;

  constructor(lookupMapService: LookupMapService) {
    this.lookupMapService = lookupMapService;
  }

  public mapToEmployeeShortInfo(dto: IEmployeeShortInfo): EmployeeShortInfo {
    let data: EmployeeShortInfo = new EmployeeShortInfo();
    data.id = dto.id;
    data.firstName = dto.firstName;
    data.middleName = dto.middleName || '';
    data.lastName = dto.lastName;
    data.fullName = dto.fullName;
    data.isAgency = dto.isAgency;
    data.ssn = dto.ssn;
    data.status = dto.status;
    data.payrollNumber = dto.payrollNumber;
    data.dateOfBirth = dto.dateOfBirth ? moment(dto.dateOfBirth).toDate() : null;
    data.type = dto.type;
    let payTypeDto: IPayType = { name: dto.payType };
    data.payType =  this.lookupMapService.mapPayType(payTypeDto);
     
    if (dto.organization) {
      data.organization = this.lookupMapService.mapOrganization(dto.organization);
    } else {
      data.organization = new Organization();
    }
    if (dto.department) {
      data.department = this.lookupMapService.mapDepartment(dto.department);
    } else {
      data.department = new Department();
    }
    if (dto.position) {
      data.position = this.lookupMapService.mapPosition(dto.position);
    } else {
      data.position = new Position();
    }
    data.terminationDate = dateTimeUtils.convertFromDtoString(dto.terminationDate);
    data.activationDate = dateTimeUtils.convertFromDtoString(dto.activationDate);
    data.effectiveDate = dateTimeUtils.convertFromDtoString(dto.effectiveDate);
    data.effectiveEndDate = dateTimeUtils.convertFromDtoString(dto.effectiveEndDate);
    data.dateHired = dateTimeUtils.convertFromDtoString(dto.dateHired);
    data.email=dto.email;
    data.transferDepartmentId = dto.transferDepartmentId;
   // data.bannerInfoLicense = dto.bannerInfoLicense;
    return data;
  }

  public mapToEmployeeShortInfoDto(data: EmployeeShortInfo): IEmployeeShortInfo {
    let dto: IEmployeeShortInfo = {
      id: data.id,
      firstName: data.firstName,
      lastName: data.lastName,
      middleName: data.middleName,
      fullName: data.fullName,
      isAgency: data.isAgency,
      dateOfBirth: data.dateOfBirth,
      ssn: data.ssn,
      dateHired: dateTimeUtils.convertToDtoString(data.dateHired),
      effectiveDate: dateTimeUtils.convertToDtoString(data.effectiveDate),
      effectiveEndDate: dateTimeUtils.convertToDtoString(data.effectiveEndDate),
      terminationDate: dateTimeUtils.convertToDtoString(data.terminationDate),
      activationDate: dateTimeUtils.convertToDtoString(data.activationDate),
      organization: this.lookupMapService.mapOrganizationDto(data.organization),
      position: this.lookupMapService.mapPositionDto(data.position),
      department: this.lookupMapService.mapDepartmentDto(data.department),
      status: data.status,
      payrollNumber: data.payrollNumber,
      type: data.type,
      email: data.email,
      transferDepartmentId: data.transferDepartmentId,
      payType:  this.lookupMapService.mapPayTypeDto(data.payType).name,
      //bannerInfoLicense: data.bannerInfoLicense  
    };
    return dto;
  }

  public mapToEmployeeDefinition(dto: IEmployeeDefinition): EmployeeDefinition {
    if (!dto) {
      return null;
    }
    let data: EmployeeDefinition = new EmployeeDefinition();
    data.dateHired = dateTimeUtils.convertFromDtoString(dto.dateHired);
    data.dateTerminated = dateTimeUtils.convertFromDtoString(dto.dateTerminated);
    if (!dateTimeUtils.validateDate(data.dateTerminated)) {
      data.dateTerminated = null;
    }
    data.dateActivation = dateTimeUtils.convertFromDtoString(dto.dateActivation);
    if (!dateTimeUtils.validateDate(data.dateActivation)) {
      data.dateActivation = null;
    }
    data.id = dto.id;
    data.name = dto.name;
    data.payrollNumber = dto.payrollNumber;
    data.phoneNumber = dto.phoneNumber;
    data.mobilePhoneNumber = dto.mobilePhoneNumber;
    if (dto.employeeType) {
      data.employeeType = this.lookupMapService.mapEmpType(dto.employeeType);
    } else {
      data.employeeType = new EmpType();
    }
    data.averageHoursPerWeek = _.round(dto.averageHoursPerWeek, 2);
    data.hoursPerRotation = _.round(dto.hoursPerRotation, 2);
    data.isAgencyEmployee = dto.isAgencyEmployee;
    data.hasRotations = dto.hasRotations;
    data.daterehire = dateTimeUtils.convertFromDtoString(dto.daterehire);
    if (!dateTimeUtils.validateDate(data.daterehire)) {
      data.daterehire = null;
    }
    return data;
  }

  public mapToEmployeeDefinitionDto(data: EmployeeDefinition): IEmployeeDefinition {
    let dto: IEmployeeDefinition = {
      dateHired: dateTimeUtils.convertToDtoString(data.dateHired),
      dateTerminated: dateTimeUtils.convertToDtoString(data.dateTerminated),
      dateActivation: dateTimeUtils.convertToDtoString(data.dateActivation),
      id: data.id,
      name: data.name,
      payrollNumber: data.payrollNumber,
      phoneNumber: data.phoneNumber,
      mobilePhoneNumber: data.mobilePhoneNumber,
      employeeType: this.lookupMapService.mapEmpTypeDto(data.employeeType),
      averageHoursPerWeek: data.averageHoursPerWeek,
      hoursPerRotation: data.hoursPerRotation,
      isAgencyEmployee: data.isAgencyEmployee,
      hasRotations: data.hasRotations
    };
    return dto;
  }

  public mapToEmployeePositions(positionsDto: IEmployeePositionDefinition[]): EmployeePositionDefinition[] {
    Assert.isNotNull(positionsDto, 'positionsDto');
    let positions: EmployeePositionDefinition[] = [];

    _.forEach(positionsDto, (positionDto: IEmployeePositionDefinition) => {
      let position: EmployeePositionDefinition = this.mapToEmployeePosition(positionDto);
      positions.push(position);
    });

    return positions;
  }

  public mapToEmployeePosition(positionDto: IEmployeePositionDefinition): EmployeePositionDefinition {
    Assert.isNotNull(positionDto, 'positionDto');
    let employeePosition: EmployeePositionDefinition = new EmployeePositionDefinition();
    employeePosition.rotationsCount = positionDto.rotationsCount;
    if (positionDto.employee) {
      let employee: EmployeeDefinition = new EmployeeDefinition();
      employee.id = positionDto.employee.id;
      employee.name = positionDto.employee.name;
      employee.payrollNumber = positionDto.employee.payrollNumber;
      employee.dateHired = new Date(positionDto.employee.dateHired);

      if (positionDto.employee.employeeType) {
        let empType: EmpType = new EmpType();
        empType.description = positionDto.employee.employeeType.description;
        empType.name = positionDto.employee.employeeType.name;
        employee.employeeType = empType;
      }
      employeePosition.employee = employee;
    }

    if (positionDto.position) {
      let position: Position = new Position();
      position.id = positionDto.position.id;
      position.orgLevelId = positionDto.position.orgLevelId;
      position.name = positionDto.position.name;
      employeePosition.position = position;
    }

    if (positionDto.positionDepartment) {
      let department: Department = new Department();
      department.id = positionDto.positionDepartment.id;
      department.orgLevelId = positionDto.positionDepartment.orgLevelId;
      department.name = positionDto.positionDepartment.name;
      employeePosition.positionDepartment = department;
    }

    return employeePosition;
  }

  public mapToEmployeePositionDefinitionDto(data: EmployeePositionDefinition): IEmployeePositionDefinition {
    let dto: IEmployeePositionDefinition = {
      employee: this.mapToEmployeeDefinitionDto(data.employee),
      position: this.lookupMapService.mapPositionDto(data.position),
      positionDepartment: data.positionDepartment ? this.lookupMapService.mapDepartmentDto(data.positionDepartment) : null,
      rotationsCount: data.rotationsCount
    };
    return dto;
  }

  public mapToEmployeePhoto(data: IEmployeePhoto): EmployeePhoto {
    let photo: EmployeePhoto = new EmployeePhoto();
    photo.employeeId = data.employeeId;
    photo.photo = data.photo;
    photo.empInitial = data.empInitial;
    return photo;
  }

  public mapToEmployeePhotos(data: IEmployeePhoto[]): EmployeePhoto[] {
    return _.map(data, (dto: IEmployeePhoto) => { return this.mapToEmployeePhoto(dto); });
  }

  public mapToEmployeeBadge(data: IEmployeeBadge): EmployeeBadge {
    let badge: EmployeeBadge = new EmployeeBadge();
    badge.employeeId = data.employeeId;
    badge.badge = data.badge;
    return badge;
  }

  public mapToEmployeeBadges(data: IEmployeeBadge[]): EmployeeBadge[] {
    return _.map(data, (dto: IEmployeeBadge) => { return this.mapToEmployeeBadge(dto); });
  }

  public mapPayRuleDefinition(dto: IPayRuleDefinition): PayRuleDefinition {
    Assert.isNotNull(dto, 'IPayRuleDefinition');
    let data: PayRuleDefinition = new PayRuleDefinition();
    data.name = dto.name;
    data.description = dto.description;
    data.color = dto.color;
    data.order = dto.order;
    return data;
  }

  public mapEmployeeValidation(dresult: IEmployeeFieldValidation): EmployeeFieldValidation {
    let result: EmployeeFieldValidation = new EmployeeFieldValidation();
    result.isValid = dresult.isValid;
    result.errorMessage = dresult.errorMessage;
    result.empId = dresult.empId;
    result.isReadyForValidation = true;
    return result;
  }

  public mapToEmployeeBenefitClass(dto: IEmployeeBenefitClass, editable: boolean = false): EmployeeBenefitClass {
    if (!dto) {
      return null;
    }
    let model: EmployeeBenefitClass = new EmployeeBenefitClass();
    model.id = dto.id;
    model.empId = dto.empId;
    model.benefitClassId = dto.benefitClassId;
    model.startDate = dto.startDate ? dateTimeUtils.convertFromDtoString(dto.startDate) : null;
    model.endDate = dto.endDate ? dateTimeUtils.convertFromDtoString(dto.endDate) : null;
    model.updatedOn = dto.updatedOn ? dateTimeUtils.convertFromDtoString(dto.updatedOn) : null;
    model.updatedBy = dto.updatedBy;
    model.benefitClass = this.lookupMapService.mapToBenefitClassDefinitionDTO(dto.benefitClass);

    /*
    let now = moment().startOf('day').toDate();
    if (!editable) {
      model.isStartDateEditable = moment(model.startDate).isAfter(now);
      model.isEndDateEditable = !_.isNil(model.endDate) ? moment(model.endDate).isAfter(now) : true;
      model.isHistory = !model.isStartDateEditable && !model.isEndDateEditable;
    } else {
      model.isStartDateEditable = true;
      model.isEndDateEditable = true;
      model.isHistory = false;
    }
    */
    return model;
  }

  public mapToEmployeeBenefitClassDTO(model: EmployeeBenefitClass): IEmployeeBenefitClass {
    if (!model) {
      return null;
    }
    return {
      id: model.id,
      benefitClassId: model.benefitClassId,
      empId: model.empId,
      startDate: dateTimeUtils.convertToDtoString(model.startDate),
      endDate: dateTimeUtils.convertToDtoString(model.endDate),
      updatedOn: dateTimeUtils.convertToDtoString(model.updatedOn),
      updatedBy: null,
      benefitClass: this.lookupMapService.mapBenefitClassDefinition(model.benefitClass),
      dateHired: model.dateHired ? dateTimeUtils.convertToDtoString(model.dateHired) : null
    };
  }

  public mapToBenefitClassDTO(model: BenefitClass): IBenefitClass {
    return {
      id: model.id,
      code: model.code,
      name: model.name,
      description: model.description,
      orgLevelId: model.orgLevelId,
      updatedBy: model.updatedBy,
      updatedOn: dateTimeUtils.convertToDtoString(model.updatedOn)
    };
  }

  public mapToBenefitClass(dto: IBenefitClass): BenefitClass {
    let model: BenefitClass = new BenefitClass();
    model.id = dto.id;
    model.name = dto.name;
    model.description = dto.description;
    model.code = dto.code;
    model.orgLevelId = dto.orgLevelId;
    model.updatedBy = dto.updatedBy;
    model.updatedOn = dto.updatedOn ? dateTimeUtils.convertFromDtoString(dto.updatedOn) : null;
    return model;
  }

  public mapToEmployeeResendOptInStatuses(dtos: IEmployeeResendOptInStatus[]): EmployeeResendOptInStatus[] {
    return _.map(dtos, dto => this.mapToEmployeeResendOptInStatus(dto));
  }

  public mapToEmployeeResendOptInStatus(dto: IEmployeeResendOptInStatus): EmployeeResendOptInStatus {
    const model = new EmployeeResendOptInStatus();
    model.employeeId = dto.employeeId;
    model.sent = dto.sent;
    model.error = dto.error;
    return model;
  }

  public mapToEmployeeResendOptInStatusDTO(model: EmployeeResendOptInStatus): IEmployeeResendOptInStatus {
    const dto: IEmployeeResendOptInStatus = {
      employeeId: model.employeeId,
      sent: model.sent,
      error: model.error,
    };
    return dto;
  }
}
