<div class="modal-content">
  <div class="modal-body">
    <slx-spinner [show]="state.isLoading" novalidate>
      
      <div class="header-container">
        <div class="header-opt-in-caption">This is the message the employee will see.</div>
        <div class="header-opt-in-message">
          <textarea
          name="smsText"
          [(ngModel)]="optInSMSText"
          class="form-control text-area-sms"
          [disabled]="true"
          cols="40"
          rows="2">
          </textarea>
        </div>        
      </div>

      <div class="employees-list">
        <slx-employee-grid
          [fieldDefinition]="fieldDefinition"
          [employees]="actionEmployees"
          [isMultiselect]="true"
          [isOptInStatus]="true"
          (onEmployeesSelect)="employeesSelect($event)"
        ></slx-employee-grid>
      </div>
    </slx-spinner>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn red" (click)="onCancel()">
      <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel
    </button>

    <button type="button" (click)="onSend()" class="btn green" [disabled]="!canSend">
      <i class="fa fa-paper-plane slx-button__icon" aria-hidden="true"></i>Send
    </button>
  </div>
</div>
