<div class="modal-content {{confirmOptions?.className}}">
    <div class="modal-body">
        <div class="bootbox-body" *ngIf ="itemData">
            <span>{{options?.message}} </span>

            <div class="items-list-holder spacer-top" >
                <span *ngIf="itemData.RequeststatusId == '1'">Pending Agency Assignement</span>
                <span *ngIf="itemData.RequeststatusId == '4'">Response Required Agency Employee Requested Open Shift</span>
                <span *ngIf="itemData.RequeststatusId == '2'">{{itemData.EmpName}}</span>
                , {{itemData.SchedHours}}, {{itemData.JobDescription}}, {{itemData.Hours}}, {{itemData.ShiftName}},  {{itemData.Shifttype}}, {{itemData.UnitName}}, 
                <span>{{itemData.AgencyName}}</span>
            </div>
            
            <div class="spacer-top" *ngIf="itemData.RequeststatusId == '2'"><span>{{confirmOptions?.note}}</span></div>
       
        </div>
        <div class="bootbox-body" *ngIf="!itemData">
            <span class="warning-exclamation"><i class="fa fa-exclamation-triangle " aria-hidden="true">{{options?.message}} </i></span>
            <div class="spacer-top"><span>{{confirmOptions?.note}}</span></div>
       
        </div>
    </div>   
    <div class="modal-footer">
      <button *ngIf="confirmOptions.showCancel" type="button" (click)="onCancel()" class="theme-button-cancel margin-r">{{ cancelButtonText }}</button>
      <button *ngIf="confirmOptions.showOK" type="button" (click)="onOk()" class="theme-button-apply confirm-button">{{ okButtonText }}</button>
    </div>
 
