import { DateRange, IDateRangeStr } from '../../../core/models/index';

export class LoaRepeat {
  constructor(
    public recurrenceId: string = '',
    public recurrenceRule: string = '',
    public recurrenceException: string = '',
    public isAllDay: boolean = false,
    public customDates: DateRange[] = []
  ) {}
}

export interface ILoaRepeat {
  customDates: IDateRangeStr[];
  recurrenceRule: string;
  recurrenceException: string;
  recurrenceId: string;
  isAllDay: boolean;
}
