import { OnInit } from '@angular/core';
import { DialogOptions } from '../../models';
import { ModalService } from '../../services';
import { OverlapOverTimeMessage } from './../../../../app/scheduler/models/schedule-rotation/schedule-rotation-template.model';
var ShiftOverlapWarningMessageComponent = /** @class */ (function () {
    function ShiftOverlapWarningMessageComponent(options, modalService, message) {
        this.options = options;
        this.modalService = modalService;
        this.message = message.overLap ? message.overLap : message.overTime;
    }
    Object.defineProperty(ShiftOverlapWarningMessageComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    ShiftOverlapWarningMessageComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
    };
    ShiftOverlapWarningMessageComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ShiftOverlapWarningMessageComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return ShiftOverlapWarningMessageComponent;
}());
export { ShiftOverlapWarningMessageComponent };
