import { IBaseSelectableModel } from '../../../common/models/kendo-grid-helpers/base-selectable-model';

export interface IUnit {
  id: number;
  description: string;
  name: string;
  employeesCount: number;
  lastUpdateUsername: string;
  lastUpdateDate: string;
}

export class Unit implements IBaseSelectableModel {
  public id: number = 0;
  public description: string = '';
  public name: string = '';
  public employeesCount: number = 0;
  public lastUpdateUsername: string;
  public lastUpdateDate: Date;

  public editable: boolean = true;
  public isSelected: boolean;
  public selectable: boolean = true;
}
