<form #wcReport="ngForm">
    <div class="incident-report-body" *ngIf="comp && lookups">

        <div class="incident-report-item actions-bar">
            <span>
                <button [popover]="confirmPopover"
                        class="disable-btn slx-button slx-with-icon slx-margin-r"
                        type="button"
                        [disabled]="!report.editMode"
                        >
                    <i aria-hidden="true" class="fas fa-ban slx-button__icon"></i>
                    <span class="slx-button__text">Disable Workers' Comp Form</span>
                </button>
                <popover-content #confirmPopover title="Are you sure?" placement="auto bottom">
                    <span class="disable-message">
                        By disabling this form you will lose all data entered.
                        This will set the Workers' Compensation Recordable status
                        on the Incident Report to No.
                        Are you sure you want to continue?
                    </span>
                    <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r"
                        (click)="onClickDisable(confirmPopover)">Yes</button>
                    <button type="button" class="slx-button slx-narrow" (click)="onClickCancel(confirmPopover)">No</button>
                </popover-content>
            </span>
        </div>

        <div class="incident-report-item injury-details">
            <slx-wc-report-section title="Injury Details" icon="far fa-band-aid">
                <div class="panel-content">
                    <div class="injury-details-section">

                        <!-- injury details  controls-->
                        <p class="flex report-control">
                            <label class="report-control-label min-width-150">Accident State</label>
                            <span class="flex__grow max-width-250">
                                <slx-autocomplete class="slx-wide"
                                    name="accidentState"
                                    [lookup]="statesLookup"
                                    [(ngModel)]="comp.accidentState"
                                    [strictSearch]="false"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-autocomplete>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-150">
                                Accident Cause
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list
                                    class="slx-wide slx-short"
                                    name="accidentCause"
                                    valueField="id" titleField="description"
                                    [options]="lookups.causes"
                                    [(ngModel)]="comp.accidentCause"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.causes" class="slx-error-block">
                                    There is no Accident Causes configured in system
                                </span>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-150">
                                Nature of Injury
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list class="slx-wide slx-short"
                                    name="injuryByNature"
                                    valueField="id" titleField="description"
                                    [options]="lookups.injuryTypes"
                                    [(ngModel)]="comp.injuryByNature"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.injuryTypes" class="slx-error-block">
                                    There is no Injury Types configured in system
                                </span>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-150">
                                Body Part
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list class="slx-wide slx-short"
                                    name="bodyPart"
                                    valueField="id" titleField="description"
                                    [options]="lookups.bodyParts"
                                    [(ngModel)]="report.common.bodyPart"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.bodyParts" class="slx-error-block">
                                    There is no Body Part Codes configured in system
                                </span>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-150">
                                Body Side
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list
                                    name="bodySide"
                                    class="slx-wide slx-short"
                                    valuePrimitive="true"
                                    [options]="bodySides"
                                    [(ngModel)]="comp.bodySide"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label flex__grow">
                                Did the employee require treatment for this body part in the past?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="didRequireTreatmentYes"
                                    fieldName="didRequireTreatmentYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.didRequireTreatment"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="didRequireTreatmentNo"
                                    fieldName="didRequireTreatmentNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.didRequireTreatment"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">Time Employee Began Work</label>
                            <span class="flex__grow max-width-150">
                                <slx-timepicker
                                    class="slx-wide slx-short"
                                    name="compEmpBeganWork"
                                    [(ngModel)]="report.common.empBeganWork"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-timepicker>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">The Injury occured</label>
                            <span class="flex__grow max-width-150">
                                <slx-dropdown-list
                                    name="compIncidentLocation"
                                    class="slx-wide slx-short"
                                    valueField="id" titleField="description"
                                    [options]="lookups.occurences"
                                    [(ngModel)]="report.common.injuryOccured"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.occurences" class="slx-error-block">
                                    There is no Incident Locations configured in system
                                </span>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">Date Employer Was Notified</label>
                            <span class="flex__grow max-width-150">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="dateErNotified"
                                    [(ngModel)]="comp.dateErNotified"
                                    [readonly]="!report.editMode"
                                    #dateErNotified="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateErNotified.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label min-width-250">Date Medical Treatment First Received</label>
                            <span class="flex__grow max-width-150">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="dateTreatmentReceived"
                                    [(ngModel)]="comp.dateTreatmentReceived"
                                    [readonly]="!report.editMode"
                                    #dateTreatmentReceived="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateTreatmentReceived.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">Death</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isDeadYes" fieldName="isDeadYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="report.common.isDead"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onIsDeadChanged($event)">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isDeadNo" fieldName="isDeadNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="report.common.isDead"
                                    (ngModelChange)="onIsDeadChanged($event)"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="report.common.isDead" class="flex report-control sub-control">
                            <label class="report-control-label min-width-150">Date of Death</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="dateOfDeath"
                                    [(ngModel)]="report.common.dateOfDeath"
                                    [readonly]="!report.editMode"
                                    #dateOfDeath="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateOfDeath.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p *ngIf="report.common.isDead" class="flex report-control sub-control">
                            <label class="report-control-label flex__shrink min-width-150">Date Employer Notified of Death</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="dateErNotifiedDeath"
                                    [(ngModel)]="report.common.dateErNotifiedDeath"
                                    [readonly]="!report.editMode"
                                    [minDate]="report.common.dateOfDeath"
                                    [slxMinDate]="report.common.dateOfDeath"
                                    #dateErNotifiedDeath="ngModel"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateErNotifiedDeath.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                What was employee doing just before the incident occured?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    name="whatDoingBefore"
                                    [(ngModel)]="report.common.whatDoingBefore"
                                    [readonly]="!report.editMode"
                                    maxlength="255"
                                    ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Describe activity as well as the tools, equipment, or material employee was using. Be
                                specific
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    name="activityDescription"
                                    [(ngModel)]="report.common.activityDescription"
                                    [readonly]="!report.editMode"
                                    maxlength="255"
                                    ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Describe the nature of the injury/illness?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="natureOfInjuryDesc"
                                [(ngModel)]="comp.natureOfInjuryDesc"
                                [readonly]="!report.editMode"
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                What object or substance directly harmed the employee?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="whatHarmed"
                                [(ngModel)]="report.common.whatHarmed"
                                [readonly]="!report.editMode"
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>

                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item leave-details">
            <slx-wc-report-section title="Leave Details" icon="fas fa-sign-out-alt">
                <div class="panel-content">
                    <div class="leave-details-section">
                        <!-- leave details  controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-200">Last date Worked</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="lastDateWorked"
                                    [(ngModel)]="comp.lastDateWorked"
                                    [readonly]="!report.editMode"
                                    #lastDateWorked="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="lastDateWorked.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-200">Date Expected To Return To Work</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="expectReturnDate"
                                    [(ngModel)]="comp.expectReturnDate"
                                    [readonly]="!report.editMode"
                                    #expectReturnDate="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="expectReturnDate.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-200">Was full Pay for Date of Injury?</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list
                                    name="fullPayForInjuryDate"
                                    class="slx-wide slx-short"
                                    valueField="id" titleField="name"
                                    [options]="threeChoiceOptions"
                                    [(ngModel)]="comp.fullPayForInjuryDate"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-200">Were Wages/Salary Continued?</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list
                                    name="salaryContinued"
                                    class="slx-wide slx-short"
                                    valueField="id" titleField="name"
                                    [options]="threeChoiceOptions"
                                    [(ngModel)]="comp.salaryContinued"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">Hours Worked Per Day</label>
                            <span class="flex__shrink min-width-150">
                                <input class="report-control-field"
                                    name="hoursWorkedPerDay"
                                    [(ngModel)]="comp.hoursWorkedPerDay"
                                    step="0.5" min="1"  max="12"
                                    type="number"
                                    [readonly]="!report.editMode"
                                    />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">Days Worked Per Week</label>
                            <span class="flex__shrink min-width-150">
                                <input class="report-control-field"
                                    name="daysWorkedPerWeek"
                                    [(ngModel)]="comp.daysWorkedPerWeek"
                                    step="1" min="1" max="7"
                                    type="number"
                                    [readonly]="!report.editMode"
                                    />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">
                                Payment Frequency?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-dropdown-list class="slx-wide slx-short"
                                    name="paymentFreq"
                                    [valuePrimitive]="true"
                                    [options]="paymentFreqOptions"
                                    [(ngModel)]="comp.paymentFreq"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">Is the injured worker losing time?</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isLosingTimeYes" fieldName="isLosingTimeYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isLosingTime"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onisLostDaysChanged($event)">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isLosingTimeNo" fieldName="isLosingTimeNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isLosingTime"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onisLostDaysChanged($event)">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="comp.isLosingTime" class="flex report-control sub-control">
                            <label class="report-control-label flex__shrink min-width-250">Date Lost Time Began</label>
                            <span class="flex__grow max-width-150 max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="dateLoseBegan"
                                    [(ngModel)]="comp.dateLoseBegan"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="updateLostDaysCount()"
                                    #dateLoseBegan="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    [maxDate]="comp.dateLoseEnd"
                                    [slxMaxDate]="comp.dateLoseEnd"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateLoseBegan.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p *ngIf="comp.isLosingTime" class="flex report-control sub-control">
                            <label class="report-control-label flex__shrink min-width-250">Estimated Out-of-Work End Date</label>
                            <span class="flex__grow max-width-150 max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="dateLoseEnd"
                                    [(ngModel)]="comp.dateLoseEnd"
                                    [minDate]="comp.dateLoseBegan"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="updateLostDaysCount()"
                                    #dateLoseEnd="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateLoseEnd.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p *ngIf="comp.isLosingTime" class="flex report-control sub-control">
                            <label class="report-control-label flex__shrink min-width-250">Total Out-of-Work Days</label>
                            <span class="flex__grow max-width-150 max-width-250">
                                <input class="report-control-field"
                                    name="lostDaysCount"
                                    [ngModel]="comp.lostDaysCount"
                                    type="number"
                                    (ngModelChange)="updateLostEndDate($event)"
                                    readonly
                                    />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Does the employee qualify for FMLA?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isEmpQualifyFLMAYes"
                                    fieldName="isEmpQualifyFLMAYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isEmpQualifyFLMA"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isEmpQualifyFLMANo" fieldName="isEmpQualifyFLMANo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isEmpQualifyFLMA"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was the employee placed on transitional duty or other restriction?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasEmpPlacedOnTrDutyYes"
                                    fieldName="wasEmpPlacedOnTrDutyYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.wasEmpPlacedOnTrDuty"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasEmpPlacedOnTrDutyNo"
                                    fieldName="wasEmpPlacedOnTrDutyNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.wasEmpPlacedOnTrDuty"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="comp.wasEmpPlacedOnTrDuty" class="flex report-control sub-control">
                            <label class="report-control-label flex__shrink min-width-250">Date Transitional Duty Begun</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="empTrDutyStart"
                                    [(ngModel)]="comp.empTrDutyStart"
                                    [readonly]="!report.editMode"
                                    #empTrDutyStart="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="empTrDutyStart.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p *ngIf="comp.wasEmpPlacedOnTrDuty" class="flex report-control sub-control">
                            <label class="report-control-label flex__shrink min-width-250">Estimated Transitional Duty End Date</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker class="slx-wide slx-short"
                                    name="dateLoseEnd"
                                    [(ngModel)]="comp.dateLoseEnd"
                                    [minDate]="comp.dateLoseBegan"
                                    [readonly]="!report.editMode"
                                    #dateLoseEnd="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateLoseEnd.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item location-details">
            <slx-wc-report-section title="Location Details" icon="fas fa-map-marker-alt">
                <div class="panel-content">
                    <div class="location-details-section">
                        <!-- location details controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-220">
                                Injury/Illness Occurence Address
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                    name="occurenceAddress"
                                    autocomplete="off" type="text"
                                    maxlength="255"
                                    [(ngModel)]="comp.occurenceAddress"
                                    [readonly]="!report.editMode"
                                />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-220">
                                Injury/Illness Occurence City
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                    name="occurenceCity"
                                    autocomplete="off" type="text"
                                    maxlength="255"
                                    [(ngModel)]="comp.occurenceCity"
                                    [readonly]="!report.editMode"
                                />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-220">
                                Injury/Illness Occurence State
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                    name="occurenceState"
                                    autocomplete="off" type="text"
                                    maxlength="255"
                                    [(ngModel)]="comp.occurenceState"
                                    [readonly]="!report.editMode"
                                />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-220">
                                Injury/Illness Occurence Zip
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                    name="occurenceZip"
                                    autocomplete="off" type="text"
                                    maxlength="10"
                                    [(ngModel)]="comp.occurenceZip"
                                    [readonly]="!report.editMode"
                                    />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Did injury or illness occur on employers premises?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isOccurEmpOnPremisesYes"
                                    fieldName="isOccurEmpOnPremisesYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isOccurEmpOnPremises"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isOccurEmpOnPremisesNo"
                                    fieldName="isOccurEmpOnPremisesNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isOccurEmpOnPremises"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item wc-details">
            <slx-wc-report-section title="Workers' Comp Details" icon="fas fa-briefcase">
                <div class="panel-content">
                    <div class="location-details-section">
                        <!-- wc details controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Were safeguards or safety equipment provided?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isSafeguardsProvidedYes"
                                    fieldName="isSafeguardsProvidedYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isSafeguardsProvided"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button
                                    class="p-left-0"
                                    name="isSafeguardsProvidedNo"
                                    fieldName="isSafeguardsProvidedNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isSafeguardsProvided"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Does employer question the claim?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button
                                    class="p-left-0"
                                    name="isErQuestionOnClimeYes"
                                    fieldName="isErQuestionOnClimeYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isErQuestionOnClime"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button
                                    class="p-left-0"
                                    name="isErQuestionOnClimeNo"
                                    fieldName="isErQuestionOnClimeNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isErQuestionOnClime"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control flex__grow">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee injured during employment?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isDuringEmploymentYes"
                                    fieldName="isDuringEmploymentYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isDuringEmployment"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isDuringEmploymentNo"
                                    fieldName="isDuringEmploymentNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isDuringEmployment"
                                    [readonly]="!report.editMode">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Were drugs or alcohol involved?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isDrugsInvolvedYes"
                                    fieldName="isDrugsInvolvedYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isDrugsInvolved"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isDrugsInvolvedNo"
                                    fieldName="isDrugsInvolvedNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isDrugsInvolved"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Is employee represented by an attorney?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button
                                    class="p-left-0"
                                    name="isRepresByAttorneyYes"
                                    fieldName="isRepresByAttorneyYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isRepresByAttorney"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isRepresByAttorneyNo"
                                    fieldName="isRepresByAttorneyNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isRepresByAttorney"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Were employees workers' compensation rights explained?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isWcExplainedYes" fieldName="isWcExplainedYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isWcExplained"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isWcExplainedNo" fieldName="isWcExplainedNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isWcExplained"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee given a copy of the current medical providers?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isGivenMedicalProvidersYes"
                                    fieldName="isGivenMedicalProvidersYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isGivenMedicalProviders"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isGivenMedicalProvidersNo"
                                    fieldName="isGivenMedicalProvidersNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isGivenMedicalProviders"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was a signed copy of the current medical providers put in the WC file?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isMPPutInWcFileYes"
                                    fieldName="isMPPutInWcFileYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isMPPutInWcFile"
                                    [readonly]="!report.editMode"
                                >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isMPPutInWcFileNo"
                                    fieldName="isMPPutInWcFileNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isMPPutInWcFile"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee given a copy of the Employment Notification form?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isGivenNotifyFormYes"
                                    fieldName="isGivenNotifyFormYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isGivenNotifyForm"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isGivenNotifyFormNo"
                                    fieldName="isGivenNotifyFormNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isGivenNotifyForm"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Has a signed copy of Employment Notification form been put in the WC file?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isNFInWcFileYes"
                                    fieldName="isNFInWcFileYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isNFInWcFile"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isNFInWcFileNo"
                                    fieldName="isNFInWcFileNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isNFInWcFile"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">
                                OSHA Case Number
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                    name="caseNumber"
                                    autocomplete="off" type="text"
                                    [(ngModel)]="report.common.caseNumber"
                                    [readonly]="!report.editMode"
                                    />
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item treatment-details">
            <slx-wc-report-section title="Treatment Details" icon="far fa-file-medical">
                <div class="panel-content">
                    <div class="wc-details-section">
                        <!-- treatment details controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee treated in an emergency room?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasTreatedToEmergencyRoomYes"
                                    fieldName="wasTreatedToEmergencyRoomYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="report.common.wasTreatedToEmergencyRoom"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasTreatedToEmergencyRoomNo"
                                    fieldName="wasTreatedToEmergencyRoomNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="report.common.wasTreatedToEmergencyRoom"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee hostitalized overnight as an in-patient?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasHospitalizedYes"
                                    fieldName="wasHospitalizedYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="report.common.wasHospitalized"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasHospitalizedNo" fieldName="wasHospitalizedNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="report.common.wasHospitalized"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                If treatment was given away from the worksite, where it was given?
                                (include name, address, city, state and ZIP code or facility)
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="awayTreatment"
                                [(ngModel)]="report.common.awayTreatment"
                                [readonly]="!report.editMode"
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item employer-details">
            <slx-wc-report-section title="Employer Details" icon="fas fa-user">
                <div class="panel-content">
                    <div class="employer-details-section">
                        <!-- employer details controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">
                                Employer Contact Phone Number
                            </label>
                            <span class="flex__grow max-width-250">
                                <input #erPhoneNumber="ngModel" class="report-control-field phone-field"
                                    name="erPhoneNumber"
                                    phone
                                    [textMask]="phoneMaskConf"
                                    [(ngModel)]="comp.erPhoneNumber"
                                    [readonly]="!report.editMode"
                                    />
                                    <span *ngIf="erPhoneNumber.invalid" errorMessage for="phone" class="slx-error-block"></span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Comments
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    name="comments"
                                    [(ngModel)]="comp.comments"
                                    [readonly]="!report.editMode"
                                    maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Is Record Only?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isRecordOnlyYes" fieldName="isRecordOnlyYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.isRecordOnly"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isRecordOnlyNo" fieldName="isRecordOnlyNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.isRecordOnly"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item employee-other-details">
            <slx-wc-report-section title="Employee Details Other" icon="fas fa-user">
                <div class="panel-content">
                    <div class="employer-details-other-section">
                        <!-- employee details other controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-200">
                                Total Gross Weekly Wages
                            </label>
                            <span class="flex__grow max-width-150">
                                <input #totalGrossWeekly="ngModel" class="report-control-field"
                                    name="totalGrossWeekly"
                                    [(ngModel)]="comp.totalGrossWeekly"
                                    step="0.01" [slxMin]="0" [slxMax]="10000000000"
                                    type="number"
                                    [readonly]="!report.editMode"
                                    />
                                    <span *ngIf="totalGrossWeekly.invalid" errorMessage class="slx-error-block">
                                        Value should be greater or equal to 0 and 10 digits max.
                                    </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-200">
                                Number of Weeks Used
                            </label>
                            <span class="flex__grow max-width-150">
                                <input class="report-control-field"
                                    #numberOfWeeksUsed="ngModel"
                                    name="numberOfWeeksUsed"
                                    [(ngModel)]="comp.numberOfWeeksUsed"
                                    [readonly]="!report.editMode"
                                    step="0.01" [slxMin]="0" [slxMax]="10000000000"
                                    type="number"
                                    />
                                    <span *ngIf="numberOfWeeksUsed.invalid" errorMessage class="slx-error-block">
                                        Value should be greater or equal to 0 and 10 digits max.
                                    </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-200">
                                Value of Discounted Fringes
                            </label>
                            <span class="flex__grow max-width-150">
                                <input class="report-control-field"
                                    #valOfDiscountedFringes="ngModel"
                                    name="valOfDiscountedFringes"
                                    [(ngModel)]="comp.valOfDiscountedFringes"
                                    [readonly]="!report.editMode"
                                    step="0.01" [slxMin]="0" [slxMax]="10000000000"
                                    type="number"
                                    />
                                    <span *ngIf="valOfDiscountedFringes.invalid" errorMessage class="slx-error-block">
                                        Value should be greater or equal to 0 and 10 digits max.
                                    </span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Occupation. Be specific.
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    name="occupation"
                                    [(ngModel)]="comp.occupation"
                                    [readonly]="!report.editMode"
                                    maxlength="255"
                                ></textarea>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee a volunteer worker?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasVolunteerYes" fieldName="wasVolunteerYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.wasVolunteer"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasVolunteerNo" fieldName="wasVolunteerNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.wasVolunteer"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee certified as vocationally handicapped?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasCertifiedYes" fieldName="wasCertifiedYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="comp.wasCertified"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasCertifiedNo" fieldName="wasCertifiedNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="comp.wasCertified"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>

                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                If temporary service agency, provide name/address of employer where injury occurred.
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    name="addressServiceAgency"
                                    [(ngModel)]="comp.addressServiceAgency"
                                    [readonly]="!report.editMode"
                                    maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item signatures">
            <slx-wc-report-section title="Signatures" icon="far fa-paperclip">
                <div class="panel-content">
                    <div class="signatures-section">
                        <!-- signatures controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">
                                Preparer's Signature
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                name="preparerSignature"
                                maxlength="255"
                                autocomplete="off" type="text"
                                [(ngModel)]="comp.preparerSignature"
                                [readonly]="!report.editMode"
                                />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">
                                Preparer's Phone Number
                            </label>
                            <span class="flex__grow max-width-250">
                                <input #preparerPhoneNumber="ngModel" class="report-control-field phone-field"
                                    name="preparerPhoneNumber"
                                    [(ngModel)]="comp.preparerPhoneNumber"
                                    [readonly]="!report.editMode"
                                    phone
                                    [textMask]="phoneMaskConf"
                                    />
                                    <span *ngIf="preparerPhoneNumber.invalid" errorMessage for="phone" class="slx-error-block"></span>
                            </span>
                        </p>

                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-150">
                                Date Prepared
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker
                                    name="preparedDate"
                                    class="slx-wide slx-short"
                                    [(ngModel)]="comp.preparedDate"
                                    [readonly]="!report.editMode"
                                    #preparedDate="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                >
                                </slx-datepicker>
                                <span *ngIf="preparedDate.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>

                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Preparer's Name
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="preparerName"
                                [(ngModel)]="comp.preparerName"
                                [readonly]="!report.editMode"
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

    </div>
</form>
