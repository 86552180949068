import * as _ from 'lodash';
import { Role, RoleMenuModule, RoleMenuModuleGroup, RoleMenu, RolesMenuAccess } from '../role-models/index';
import { RolesRowDefinition, IRolesRow } from './roles-row';
import { RolesMenuRow } from './roles-menu-row';
import { ProductModule } from '../../../../organization/models/index';

export class RolesMenuModuleRow implements IRolesRow<RoleMenuModule> {
  public roles: Role[];
  public productModule?: ProductModule;
  public menuProductModules?: ProductModule[];
  public mapByRole: NumberMap<RoleMenuModule>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesMenuRow[];
  public type: RolesRowDefinition = 'RolesMenuModuleRow';
  public description: string;
  public displayOrder: number;

  public recalcStatus(roleId: number): void {
    let menuModule: RoleMenuModule = this.mapByRole[roleId];
    this.dirtyByRole[roleId] = false;
    let existEnabled: boolean = false;
    let existDisabled: boolean = false;
    let existMixed: boolean = false;
    _.forEach(this.childRows, (childRow: RolesMenuRow) => {
      let child: RoleMenu = childRow.mapByRole[roleId];
      existEnabled = existEnabled || (child.access === RolesMenuAccess.enabled);
      existDisabled = existDisabled || (child.access === RolesMenuAccess.disabled);
      existMixed = existMixed || (child.access === RolesMenuAccess.mixed);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if (existEnabled && existDisabled || existMixed) {
      menuModule.access = RolesMenuAccess.mixed;
    } else if (existEnabled) {
      menuModule.access = RolesMenuAccess.enabled;
    } else {
      menuModule.access = RolesMenuAccess.disabled;
    }
  }

  public setNextStatus(roleId: number): void {
    let menuModule: RoleMenuModule = this.mapByRole[roleId];
    if (menuModule.access === RolesMenuAccess.disabled) {
      this.setStatus(true, roleId);
    } else {
      this.setStatus(false, roleId);
    }
  }

  public setStatus(isEnabled: boolean, roleId: number): void {
    let menuModule: RoleMenuModule = this.mapByRole[roleId];
    menuModule.access = isEnabled ? RolesMenuAccess.enabled : RolesMenuAccess.disabled;
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (childRow: RolesMenuRow) => {
      childRow.setStatus(isEnabled, roleId);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
  }
}

export class RolesMenuModuleGroupRow implements IRolesRow<RoleMenuModuleGroup> {
  public roles: Role[];
  public mapByRole: NumberMap<RoleMenuModuleGroup>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesMenuModuleRow[];
  public type: RolesRowDefinition = 'RolesMenuModuleGroupRow';
  public description: string;
  public displayOrder: number;

   public recalcStatus(roleId: number): void {
    let menuModuleGroup: RoleMenuModuleGroup = this.mapByRole[roleId];
    this.dirtyByRole[roleId] = false;
    let existEnabled: boolean = false;
    let existDisabled: boolean = false;
    let existMixed: boolean = false;
    _.forEach(this.childRows, (childRow: RolesMenuModuleRow) => {
      let child: RoleMenuModule = childRow.mapByRole[roleId];
      existEnabled = existEnabled || (child.access === RolesMenuAccess.enabled);
      existDisabled = existDisabled || (child.access === RolesMenuAccess.disabled);
      existMixed = existMixed || (child.access === RolesMenuAccess.mixed);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if (existEnabled && existDisabled || existMixed) {
      menuModuleGroup.access = RolesMenuAccess.mixed;
    } else if (existEnabled) {
      menuModuleGroup.access = RolesMenuAccess.enabled;
    } else {
      menuModuleGroup.access = RolesMenuAccess.disabled;
    }
  }

  public setNextStatus(roleId: number): void {
    let menuModuleGroup: RoleMenuModuleGroup = this.mapByRole[roleId];
    if (menuModuleGroup.access === RolesMenuAccess.disabled) {
      this.setStatus(true, roleId);
    } else {
      this.setStatus(false, roleId);
    }
  }

  public setStatus(isEnabled: boolean, roleId: number): void {
    let menuModuleGroup: RoleMenuModuleGroup = this.mapByRole[roleId];
    menuModuleGroup.access = isEnabled ? RolesMenuAccess.enabled : RolesMenuAccess.disabled;
    this.dirtyByRole[roleId] = false;
    _.forEach(this.childRows, (childRow: RolesMenuModuleRow) => {
      childRow.setStatus(isEnabled, roleId);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
  }
}

