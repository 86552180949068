import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { TimecardsNavigationService } from '../../services/index';

@Directive({
  /* tslint:disable */
  selector: '[dailyTimecardsLink]',
  /* tslint:enable */
})
export class DailyTimecardsLinkDirective {
  @Input()
  public dailyTimecardsLink: number;
  @Input()
  public timecardDate: Date;
  @Input()
  public hideOrgLevelBreadcrumb: boolean;
  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    let navService: TimecardsNavigationService = new TimecardsNavigationService(this.router, this.route);
    navService.NavigateToDailyTimecards(this.dailyTimecardsLink, this.timecardDate, this.hideOrgLevelBreadcrumb);
  }
}
