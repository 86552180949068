
export class LookupType {
  public static accrualPolicy: LookupDefinition = 'accrualPolicy';
  public static acuity: LookupDefinition = 'acuity';
  public static costCenter: LookupDefinition = 'costCenter';
  public static attendancePointsDefinitions: LookupDefinition = 'attendancePointsDefinitions';
  public static budgetedPosition: LookupDefinition = 'budgetedPosition';
  public static licenseType: LookupDefinition = 'licenseType'
  public static constraintDefinition: LookupDefinition = 'constraintDefinition';
  public static organization: LookupDefinition = 'organization';
  public static userAccesibleOrganizations: LookupDefinition = 'userAccesibleOrganizations';
  public static userAccesibleOrganizationsCrossCorpActionCheck: LookupDefinition = 'userAccesibleOrganizationsCrossCorpActionCheck';
  public static userAccessibleDepartments: LookupDefinition = 'userAccessibleDepartments';
  public static department: LookupDefinition = 'department';
  public static companies: LookupDefinition = 'companies';
  public static empGender: LookupDefinition = 'empGender';
  public static empRace: LookupDefinition = 'empRace';
  public static empType: LookupDefinition = 'empType';
  public static position: LookupDefinition = 'position';
  public static pbjPosition: LookupDefinition = 'pbjPosition';
  public static locationUnit: LookupDefinition = 'locationUnit';
  public static maritalStatus: LookupDefinition = 'maritalStatus';
  public static shift: LookupDefinition = 'shift';
  public static shiftGroupDefinition: LookupDefinition = 'shiftGroup';
  public static state: LookupDefinition = 'state';
  public static payPolicy: LookupDefinition = 'payPolicy';
  public static shiftDiffPolicy: LookupDefinition = 'shiftDiffPolicy';
  public static payType: LookupDefinition = 'payType';
  public static exemptStatus: LookupDefinition = 'exemptStatus';
  public static scheduleAbsence: LookupDefinition = 'scheduleAbsence';
  public static searchCategory: LookupDefinition = 'searchCategory';
  public static employeeTerminationReason: LookupDefinition = 'employeeTerminationReason';
  public static employeeTerminationReason2: LookupDefinition = 'employeeTerminationReason2';
  public static employeeDocumentCategories: LookupDefinition = 'employeeDocumentCategories';
  public static shiftDefinition: LookupDefinition = 'shiftDefinition';
  public static budgetedGroup: LookupDefinition = 'budgetedGroup';
  public static budgetDefinition: LookupDefinition = 'budgetDefinition';
  public static availabilityDefinition: LookupDefinition = 'availabilityDefinition';
  public static pbjPositions: LookupDefinition = 'pbjPositions';
  public static timeclockDefinition: LookupDefinition = 'timeclockDefinition';
  public static timeclockRestrictionDefinition: LookupDefinition = 'timeclockRestrictionDefinition';
  public static timezoneDefinition: LookupDefinition = 'timezoneDefinition';
  public static timeclockModelDefinition: LookupDefinition = 'timeclockModelDefinition';
  public static payRules: LookupDefinition = 'payrules';
  public static punchTypes: LookupDefinition = 'punchTypes';
  public static holidayOrigins: LookupDefinition = 'holidayOrigins';
  public static holidayDayPatterns: LookupDefinition = 'holidayDayPatterns';
  public static daysOfWeek: LookupDefinition = 'daysOfWeek';
  public static months: LookupDefinition = 'months';
  public static auditTrailAreas: LookupDefinition = 'auditTrailAreas';
  public static auditTrailConfigAreas: LookupDefinition = 'auditTrailConfigAreas';
  public static employeeList: LookupDefinition = 'employeeList';
  public static employeesListBySecondaryPositions: LookupDefinition = 'employeesListBySecondaryPositions';
  public static employeesListByActiveDateRange: LookupDefinition = 'employeesListByActiveDateRange';
  public static manualEntryActiveEmployees: LookupDefinition = 'manualEntryActiveEmployees';
  public static agencies: LookupDefinition = 'agencies';
  public static agenciesReports: LookupDefinition = 'agenciesReports';
  public static essPasswordTypes: LookupDefinition = 'essPasswordTypes';
  public static essPasswordTypesFull: LookupDefinition = 'essPasswordTypesFull';
  public static essEnterTimeMethods: LookupDefinition = 'essEnterTimeMethods';
  public static essEnterTimeMethodsFull: LookupDefinition = 'essEnterTimeMethodsFull';
  public static essOptions: LookupDefinition = 'essOptions';
  public static payCyclePeriods: LookupDefinition = 'payCyclePeriods';
  public static payCycles: LookupDefinition = 'payCycles';
  public static timecardPredefinedComment: LookupDefinition = 'timecardPredefinedComment';
  public static employeeListByMasterId: LookupDefinition = 'employeeListByMasterId';
  public static ptoPlannerAbsenceCodes: LookupDefinition = 'ptoPlannerAbsenceCodes';
  public static punchAttestationAnswerCodes: LookupDefinition = 'punchAttestationAnswerCodes';
  public static benefitClasses: LookupDefinition = 'benefitClasses';
  public static workersCompensationCodeTypes: LookupDefinition = 'workersCompensationCodeTypes';
  public static veteranStatus: LookupDefinition = 'veteranStatus';
  public static positionGroups: LookupDefinition = 'positionGroups';
  public static performanceReviewCodes: LookupDefinition = 'performanceReviewCodes';
  public static performanceReviewTemplates: LookupDefinition = 'performanceReviewTemplates';
  public static performanceReviewCodeTypes: LookupDefinition = 'performanceReviewCodeTypes';
  public static generic: LookupDefinition = 'generic';
  public static establishmentType: LookupDefinition = 'establishmentType';
  public static benefitGroups: LookupDefinition = 'benefitGroups';
  public static getObjAcc: LookupDefinition = 'getObjAcc';
  public static retroReportOptions: LookupDefinition = 'retroReportOptions';
  public static payUnits: LookupDefinition = 'payUnits';
  public static timeclockSlateProfileDefinition: LookupDefinition = 'timeclockSlateProfileDefinition';
  public static increaseReason: LookupDefinition = 'increaseReason';
  public static effectivePositions: LookupDefinition = 'effectivePositions';
  public static supervisorList: LookupDefinition = 'supervisorList';
  public static punchAttestationPunchType: LookupDefinition = 'punchAttestationPunchType';
  public static attestationType: LookupDefinition = 'attestationType';
  public static restrictedAnswer: LookupDefinition = 'restrictedAnswer';
  public static getRestrictedNotify: LookupDefinition = 'getRestrictedNotify';
  //public static isConditional: LookupDefinition = 'isConditional';

  public static punchProfile: LookupDefinition = 'punchProfile';

}

export type LookupDefinition = 'accrualPolicy'
  | 'acuity'
  | 'costCenter'
  | 'attendancePointsDefinitions'
  | 'budgetedPosition'
  | 'licenseType'
  | 'constraintDefinition'
  | 'organization'
  | 'userAccesibleOrganizations'
  | 'userAccesibleOrganizationsCrossCorpActionCheck'
  | 'userAccessibleDepartments'
  | 'department'
  | 'companies'
  | 'empGender'
  | 'empRace'
  | 'empType'
  | 'position'
  | 'pbjPosition'
  | 'locationUnit'
  | 'maritalStatus'
  | 'shift'
  | 'shiftGroup'
  | 'state'
  | 'payPolicy'
  | 'shiftDiffPolicy'
  | 'payType'
  | 'exemptStatus'
  | 'scheduleAbsence'
  | 'searchCategory'
  | 'employeeTerminationReason'
  | 'employeeTerminationReason2'
  | 'employeeDocumentCategories'
  | 'shiftDefinition'
  | 'pbjPositions'
  | 'budgetedGroup'
  | 'budgetDefinition'
  | 'availabilityDefinition'
  | 'timeclockDefinition'
  | 'timeclockRestrictionDefinition'
  | 'timezoneDefinition'
  | 'timeclockModelDefinition'
  | 'payrules'
  | 'punchTypes'
  | 'holidayOrigins'
  | 'holidayDayPatterns'
  | 'daysOfWeek'
  | 'months'
  | 'auditTrailAreas'
  | 'auditTrailConfigAreas'
  | 'employeeList'
  | 'employeesListBySecondaryPositions'
  | 'employeesListByActiveDateRange'
  | 'manualEntryActiveEmployees'
  | 'agencies'
  | 'essPasswordTypes'
  | 'essPasswordTypesFull'
  | 'essEnterTimeMethods'
  | 'essEnterTimeMethodsFull'
  | 'essOptions'
  | 'payCyclePeriods'
  | 'payCycles'
  | 'timecardPredefinedComment'
  | 'employeeListByMasterId'
  | 'ptoPlannerAbsenceCodes'
  | 'punchAttestationAnswerCodes'
  | 'benefitClasses'
  | 'workersCompensationCodeTypes'
  | 'veteranStatus'
  | 'performanceReviewCodes'
  | 'performanceReviewTemplates'
  | 'performanceReviewCodeTypes'
  | 'positionGroups'
  | 'generic'
  | 'establishmentType'
  | 'benefitGroups'
  | 'getObjAcc'
  | 'agenciesReports'
  | 'retroReportOptions'
  | 'payUnits'
  | 'timeclockSlateProfileDefinition'
  | 'increaseReason'
  | 'effectivePositions'
  | 'supervisorList'
  | 'attestationType'
  | 'restrictedAnswer'
  | 'punchAttestationPunchType'
  | 'isConditional'
  | 'punchProfile'
  | 'getRestrictedNotify'
  ;

export class Lookup<T = any> {
  public type: LookupType;
  public items: T[];
  public valueField: string;
  public titleField: string;
}
