import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/delay';

import { lmConfig } from '../../../leave-management.config';

import { PopoverContentComponent } from '../../../../../common/components/index';
import { LoaAttachment, ReadFile } from '../../../../../organization/models/index';

import { LMCreationAbsenceManagementService } from '../../../services/index';
import { LoaFile } from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-absence-attachments-tab',
  templateUrl: 'lm-absence-attachments-tab.component.html',
  styleUrls: ['lm-absence-attachments-tab.component.scss']
})
export class LMAbsenceAttachmentsTabComponent implements OnInit {
  public errors: { maxFiles?: boolean, maxFileSize?: boolean, fileType?: boolean } = {};
  public acceptedFileTypesInputFormat: string;
  public acceptedFileTypes: string[];
  public maxFileSize: string;
  public maxFiles: number;
  public maxFileSizeBytes: number;
  public acceptedFileTypesReadable: string;
  public attachments: LoaAttachment[];
  public files: LoaFile[] = [];
  public get hasFiles(): boolean {
    return _.size(this.files) > 0;
  }

  public get canEditRequest(): boolean {
    return this.manService.canChange;
  }

  constructor(private manService: LMCreationAbsenceManagementService) {}

  public ngOnInit(): void {
    this.maxFiles = lmConfig.files.maxFiles;
    this.maxFileSizeBytes = lmConfig.files.maxFileSizeBytes;
    this.acceptedFileTypes = lmConfig.files.acceptedTypes;
    this.acceptedFileTypesInputFormat = lmConfig.files.acceptedTypes.join(',');
    this.acceptedFileTypesReadable = lmConfig.files.acceptedTypes.join(', ');
    this.maxFileSize = this.getReadableBytes(lmConfig.files.maxFileSizeBytes);

    this.files = this.getAllFiles();
  }

  public async onFileChange(event: MouseEvent): Promise<void> {
    const fileList: FileList = _.get(event, 'target.files');
    const files = this.validateFiles(fileList, event);

    if (files.length > 0) {
      const readFiles = await this.manService.readAddedFiles(files);
      this.manService.addFilesToSave(readFiles);
      this.files = [...this.files, ...this.mapToFiles(readFiles)];
    }
  }

  public onResetErrors(event: any): void {
    this.resetInput(event.target);

    this.errors.maxFiles = false;
    this.errors.maxFileSize = false;
    this.errors.fileType = false;
  }

  public async onClickDelete(isDelete: boolean, acceptPopover: PopoverContentComponent, file: LoaFile): Promise<void> {
    acceptPopover.hide();

    if (isDelete) {
      this.manService.changeLoading(true);
      if (file.isAttachment) {
        await this.removeAttachment(file);
      } else {
        await this.removeFileToSave(file);
      }
      this.manService.changeLoading(false);
    }
  }

  public async onDownloadFile(file: LoaFile): Promise<void> {
    if (!this.isDownloadable(file)) {
      return;
    }

    this.manService.changeLoading(true);
    await this.manService.downloadAttachment(file.id);
    this.manService.changeLoading(false);
  }

  public isDownloadable(file: LoaFile): boolean {
    return file.isAttachment;
  }

  private getAllFiles(): LoaFile[] {
    const attachments = this.getAttachments();
    const filestToSave = this.getFilesToSave();

    return [...attachments, ...filestToSave];
  }

  private getAttachments(): LoaFile[] {
    const attachments = this.manService.getAttachments();
    return this.mapToFiles(attachments);
  }

  private getFilesToSave(): LoaFile[] {
    const addedFiles = this.manService.getFilesToSave();
    return this.mapToFiles(addedFiles);
  }

  private mapToFiles(files: LoaAttachment[] | ReadFile[]): LoaFile[] {
    return _.map(files, (f: LoaAttachment | ReadFile) => new LoaFile(f));
  }

  private async removeAttachment(file: LoaFile): Promise<void> {
    await this.manService.deleteAttachment(file.id);
    this.files = _.filter(this.files, (f: LoaFile) => f.id !== file.id);
    this.manService.loadRequests();
  }

  private async removeFileToSave(file: LoaFile): Promise<boolean> {
    this.manService.deleteFileToSave(file.sourceItem as ReadFile);
    this.files = _.filter(this.files, (f: LoaFile) => f.name !== file.name);

    return Observable.of(true).delay(500).toPromise();
  }

  private validateFiles(fileList: FileList, event: MouseEvent): File[] {
    let isError = false;
    const files: File[] = [];
    if (fileList.length > this.maxFiles) {
      isError = true;
      this.setError('maxFiles');

      return [];
    }

    for (let i = 0, length = fileList.length; i < length; i++) {
      const file = fileList.item(i);
      if (file.size > this.maxFileSizeBytes) {
        isError = true;
        this.setError('maxFileSize');
        break;
      }
      const fileExt = `.${_.last(file.name.split('.'))}`;
      if (!_.includes(this.acceptedFileTypes, fileExt.toLowerCase())) {
        isError = true;
        this.setError('fileType');
        break;
      }
      files.push(file);
    }
    this.resetInput(event.target);

    return isError ? [] : files;
  }

  private setError(errorType: string): void {
    this.errors.maxFiles = false;
    this.errors.maxFileSize = false;
    this.errors.fileType = false;
    switch(errorType) {
      case 'maxFiles':
        this.errors.maxFiles = true;
        break;
      case 'maxFileSize':
        this.errors.maxFileSize = true;
        break;
      case 'fileType':
        this.errors.fileType = true;
        break;
    }
  }

  private getReadableBytes(bytes: number): string {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const res = parseFloat((bytes / Math.pow(1024, i)).toFixed(2));

    return `${res * 1} ${sizes[i]}`;
  }

  private resetInput(elem: any): void {
    elem.value = '';
  }
}
