import * as _ from 'lodash';
import { Component, Input, Provider } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { PbjNewExportOptions, PBJExportRequest, MealDeductionSettings } from '../../../models/index';
import { AppSettingsManageService } from '../../../../../app-settings/services';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';
import { unsubscribe, destroyService } from '../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { MealDeductionManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-pbj-new-export-dialog',
  templateUrl: 'pbj-new-export-dialog.component.html',
  styleUrls: ['pbj-new-export-dialog.component.scss'],
})
export class PbjNewExportDialog implements IDialog {
  public IsPBJExportCorpLevelEnabled: boolean;
  public dateRangeCorrect: boolean = true;
  public isDisabledButton: boolean = false;
  public canGeneratePBJFromAgency: boolean;

  @unsubscribe()
  private mealDeducionSettingsLoadSubscription: Subscription;

  public get canGenerate(): boolean {
    return this.m_canGenerate;
  }
  public set canGenerate(v: boolean) {
    this.m_canGenerate = v;
  }

  public get showConfigWarning(): boolean {
    return this.pbjOptions && this.pbjOptions.showConfigWarning;
  }
  public request: PBJExportRequest;
  public dialogResult: boolean;
  private m_canGenerate: boolean;
  public static openDialog(options: PbjNewExportOptions, modalService: ModalService, callback: (result: boolean) => void): PbjNewExportDialog {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = options.IsPBJExportCorpLevelEnabled ? 795 : 440;
    dialogOptions.height = 530;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.headerClassName = options.IsPBJExportCorpLevelEnabled ? 'setBlueBg' : '';
    let providers: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: PbjNewExportOptions,
        useValue: options
      }
    ];
    let dialog: PbjNewExportDialog = modalService.globalAnchor
      .openDialog(PbjNewExportDialog, options.title, dialogOptions, providers, (result: boolean) => {
        callback(result);
      });
    return dialog;
  }

  constructor(
    private pbjOptions: PbjNewExportOptions,
    private dialogOptions: DialogOptions,
    private modalService: ModalService,
    private appSettingsManageService: AppSettingsManageService,
    private mealDeducitonManagement: MealDeductionManagementService
  ) {
    this.m_canGenerate = false;
  }
  public ngOnInit(): void {
    this.appSettingsManageService.getAppServerConfig().then((config: AppServerConfig) => {
      this.IsPBJExportCorpLevelEnabled = config.IsPBJExportCorpLevelEnabled;
    });

    this.mealDeducionSettingsLoadSubscription = this.mealDeducitonManagement.onLoaded$.subscribe((s: MealDeductionSettings) => {
      this.canGeneratePBJFromAgency = s.generatePBJFromAgency;
    });
  }
  public onChangedExportRequest(req: PBJExportRequest): void {
    this.canGenerate = _.isObject(req);
    if (this.canGenerate) {
      this.request = req;
    } else {
      this.request = null;
    }
  }

  public getDateRangeError(data: any) {
    this.dateRangeCorrect = data[0];
    this.validateIsChecked(data[2].includeEmployee, data[2].includeStaffingHours);
  }
  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public validateIsChecked(isEmployee, isStaffing): boolean {
    this.isDisabledButton = !isEmployee && !isStaffing ? true : false;
    return this.isDisabledButton;
  }
  get selCheckbox() {
    let selIds = sessionStorage.getItem('selIds');
    return (selIds != undefined && selIds.length > 0);
  }
}
