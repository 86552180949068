import { OrgLevel } from '../../../state-model/models/index';

export interface ISupervisorGroupDefinition {
  id: number;
  name: string;
  orgLevelId: number;
}

export class SupervisorGroupDefinition {
  public id: number;
  public name: string;
  public orgLevelId: number;
}
