import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EmployeeDefinitionsApiService, EmployeeValidatorAdapter, LookupService } from '../../../../../../organization/services/index';
import { LookupType } from '../../../../../../organization/models/index';
import { EmployeeToTerminate, EmployeeTerminate } from '../../../../models/index';
import { appConfig } from '../../../../../../app.config';
var EmployeeTerminateComponent = /** @class */ (function () {
    function EmployeeTerminateComponent(employeeDefinitionsApiService, lookupService, employeeActivitiesValidatorAdapter) {
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.lookupService = lookupService;
        this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
    }
    Object.defineProperty(EmployeeTerminateComponent.prototype, "emp", {
        set: function (emp) {
            this.employee = emp;
            this.setEmployeeId();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTerminateComponent.prototype, "minDate", {
        get: function () {
            if (!this.employee.ignoreLockedPayrollCycles && this.employee.rehireDate) {
                if (!_.isNil(this.employeeShortInfo) && this.employeeShortInfo.effectiveDate > this.employee.rehireDate) {
                    return this.employeeShortInfo.effectiveDate;
                }
                else {
                    return this.employee.rehireDate;
                }
            }
            else if (!this.employee.ignoreLockedPayrollCycles && !this.employee.rehireDate && !_.isNil(this.employeeShortInfo)) {
                return this.employeeShortInfo.effectiveDate;
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTerminateComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.requestToTerminate = new EmployeeTerminate();
        this.requestToTerminate.date = moment().startOf('day').toDate();
        this.requestToTerminate.voluntary = false;
        this.setEmployeeId();
        this.loadLookup();
    };
    Object.defineProperty(EmployeeTerminateComponent.prototype, "emailValidation", {
        get: function () {
            return EmployeeValidatorAdapter.emailValidation;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTerminateComponent.prototype.onReasonChange = function (reason) {
        this.requestToTerminate.voluntary = reason.isVoluntary;
    };
    EmployeeTerminateComponent.prototype.loadLookup = function () {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.employeeTerminationReason, employeeId: undefined, orgLevelId: undefined })
            .then(function (reasons) {
            _this.reasons = reasons;
        });
    };
    EmployeeTerminateComponent.prototype.setEmployeeId = function () {
        if (this.employee && this.requestToTerminate) {
            this.requestToTerminate.employeeId = this.employee.employeeId;
            this.loadEmployeeInfo();
        }
    };
    EmployeeTerminateComponent.prototype.loadEmployeeInfo = function () {
        var _this = this;
        this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employee.employeeId)
            .then(function (employeeShortInfo) {
            _this.employeeShortInfo = employeeShortInfo;
            _this.employeeFullName = _this.employeeShortInfo.fullName;
            _this.requestToTerminate.email = !_.isNull(_this.employeeShortInfo.email) ? _this.employeeShortInfo.email : '';
        });
    };
    return EmployeeTerminateComponent;
}());
export { EmployeeTerminateComponent };
