import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';

import * as _ from 'lodash';

import { MenuItem, MenuItemActivatedEvent } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-two-level-menu',
  templateUrl: 'two-level-menu.component.html',
  styleUrls: ['two-level-menu.component.scss']
})
export class TwoLevelMenuComponent implements OnChanges {
  @Input()
  public menuItems: MenuItem[];
  @Input()
  public activeItemId: string;
  @Input()
  public activeSubItemId: string;

  @Output()
  public itemActivated: EventEmitter<MenuItemActivatedEvent>;
  @Output()
  public menuToggled: EventEmitter<boolean>;

  public isCollapsed: boolean;
  public activeItem: MenuItem;
  public activeSubItem: MenuItem;

  constructor() {
    this.isCollapsed = true;
    this.itemActivated = new EventEmitter<MenuItemActivatedEvent>();
    this.menuToggled = new EventEmitter<boolean>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeItemId'] || changes['activeSubItemId']) {
      let itemToActivate: MenuItem = _.find(this.menuItems, (item: MenuItem) => { return item.id === this.activeItemId; });
      if (itemToActivate) {
        let subItemToActivate: MenuItem = _.find(itemToActivate.items, (item: MenuItem) => { return item.id === this.activeSubItemId; });
        if (subItemToActivate) {
          this.activateSubItem(itemToActivate, subItemToActivate);
        } else {
          this.activateItem(itemToActivate);
        }
      }
    }
  }

  public toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
    this.menuToggled.emit(this.isCollapsed);
  }

  public onItemClick(aitem: MenuItem): void {
    this.activateItem(aitem);
    let ev: MenuItemActivatedEvent = new MenuItemActivatedEvent(this.activeItem, this.activeSubItem);
    this.itemActivated.emit(ev);
  }

  public onSubItemClick(aitem: MenuItem, asubItem: MenuItem, event: MouseEvent): void {
    this.activateSubItem(aitem, asubItem);
    let ev: MenuItemActivatedEvent = new MenuItemActivatedEvent(this.activeItem, this.activeSubItem);
    this.itemActivated.emit(ev);
    event.stopPropagation();
    event.preventDefault();
  }

  public backFromSubItems(): void {
    if (this.activeItem) {
      this.activeItem.active = false;
      this.activeItem.subactive = false;
    }
    if (this.activeSubItem) {
      this.activeSubItem.active = false;
    }
  }

  private activateItem(aitem: MenuItem): void {
    if (this.activeItem) {
      this.activeItem.active = false;
      this.activeItem.subactive = false;
    }
    aitem.active = true;
    if (aitem.items.length > 0) {
      this.activateSubItem(aitem, aitem.items[0]);
    }
    this.activeItem = aitem;
  }

  private activateSubItem(aitem: MenuItem, asubItem: MenuItem): void {
    if (this.activeItem) {
      this.activeItem.active = false;
      this.activeItem.subactive = false;
    }
    if (this.activeSubItem) {
      this.activeSubItem.active = false;
    }
    asubItem.active = true;
    aitem.active = true;
    aitem.subactive = true;

    this.activeItem = aitem;
    this.activeSubItem = asubItem;
  }
}
