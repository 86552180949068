import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';

import {
  IdealScheduleList, ParLevels,
  IdealSchedulePosition, IdealScheduleConfigTypeDefinition,
  IdealSchedulePositionDefinition,
  IdealScheduleConfigTypes,
  IdealScheduleOverviewItem,
  IdealScheduleConfigCensusOptions,
  IdealScheduleConfigCensus,
  IdealScheduleConfigCensusDefinition,
  IdealSchedulePositionPeriod
} from '../../models/ideal-schedule/index';
import { LookupMapService } from '../../../organization/services/index';
import {
  IdealScheduleListDTO, IdealSchedulePositionDTO,
  IdealSchedulePositionDayDTO, ParLevelsDTO, IdealScheduleConfigurationDTO,
  IdealScheduleConfigurationRecordDTO,
  IdealScheduleOverviewItemDTO,
  IdealSchedulePositionRangeDTO,
  IdealScheduleAcuityDTO,
  IdealScheduleShiftGroupDTO
} from '../../models/ideal-schedule/dto/index';
import { DayOfWeek, WeekDaysWithEmpty } from '../../../common/models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { Lookup } from './../../../organization/models/lookup/lookup-definition';
import { IdealSchedulePositionHistoryRecordDTO } from '../../models/ideal-schedule/dto/ideal-schedule-position-history-record-dto';
import { IdealSchedulePositionHistoryRecord } from '../../models/ideal-schedule/ideal-schedule-position-history-record';
import { IdealSchedulePositionRange } from '../../models/ideal-schedule/ideal-schedule-position-range';
import { Acuity, IAcuity } from '../../../organization/index';
import { IdealScheduleAcuity } from '../../models/ideal-schedule/ideal-schedule-acuity';
import { DownloadTemplate, IImportException, IdealScheduleImportTemplate, ImportException, ImportTemplateDto, ImportedPositionsDto, OrgLevelDetails } from '../../models/ideal-schedule/ideal-schedule-import-template';
import { DownloadedData, DownloadedExcelData, ImportFileDetails, TemplateValidator } from '../../models/ideal-schedule/ideal-schedule-import-template-validator';
import { v4 as UUID } from 'uuid';

@Injectable()
export class IdealScheduleMapService {

  constructor(private lookupMapService: LookupMapService) {
  }

  public mapIdealScheduleOverview(data: IdealScheduleOverviewItemDTO[]): IdealScheduleOverviewItem[] {
    let items: IdealScheduleOverviewItem[] = [];
    _.each(data, (dto: IdealScheduleOverviewItemDTO) => {
      let item: IdealScheduleOverviewItem = new IdealScheduleOverviewItem();
      item.position = this.mapIdealSchedulePositionDefinition(dto.position);
      item.avgDailyHours = dto.avgDailyHours;
      item.alternatingWeeks = dto.alternatingWeeks;
      item.censusConfig = _.find(IdealScheduleConfigCensusOptions, (censusConfig: IdealScheduleConfigCensus) => {
        return censusConfig.name === dto.censusConfig;
      });
      if (item.position) {
        const startDate: string = moment(item.position.startDate).format(appConfig.dateFormat);
        const endDate: string = moment(item.position.endDate).format(appConfig.dateFormat);
        item.period = `${startDate} - ${endDate}`;
      }
      items.push(item);
    });
    return items;
  }

  public mapIdealScheduleList(data: IdealScheduleListDTO): IdealScheduleList {

    let container: IdealScheduleList = new IdealScheduleList();
    container.id = data.id;
    container.department = data.department;
    container.currentRangeConfigPositions = data.positions;
    let uniqDefinititionDtos = _.uniqBy(data.positions, (dto: IdealSchedulePositionDTO) => {
      return dto.position.id;
    });

    let positions: IdealSchedulePositionDefinition[] = _.map(uniqDefinititionDtos, (dto: IdealSchedulePositionDTO) => {
      let position: IdealSchedulePositionDefinition = this.mapIdealSchedulePositionDefinition(dto);
      let periods: IdealSchedulePositionPeriod[] = [];
      _.each(data.positions, (d: IdealSchedulePositionDTO) => {
        if (dto.position.id === d.position.id) {
          let period = this.getRangeFromIdealSchedulePositionDTO(d);
          periods.push(period);
        }
      });

      position.periods = periods;
      return position;
    });
    container.positions = positions;
    return container;
  }

  public mapIdealSchedulePositionDefinition(dto: IdealSchedulePositionDTO): IdealSchedulePositionDefinition {
    let position: IdealSchedulePositionDefinition = new IdealSchedulePositionDefinition();
    position.targetHours = dto.targetHours;
    position.totalHours = dto.totalHours;
    position.position = dto.position;
    position.idealScheduleType = _.find(
      IdealScheduleConfigTypes,
      idealScheduleConfigType => idealScheduleConfigType.id === (<any>IdealScheduleConfigTypeDefinition)[dto.idealScheduleType.toString()]
    );
    position.idealScheduleCensus = _.find(
      IdealScheduleConfigCensusOptions,
      idealScheduleConfigCensus => idealScheduleConfigCensus.id === (<any>IdealScheduleConfigCensusDefinition)[dto.calculationType.toString()]
    );
    position.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    position.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    position.isChecked = false;
    return position;
  }

  public mapIdealSchedulePosition(dto: IdealSchedulePositionDTO, shifts: Lookup, groups: Lookup): IdealSchedulePosition {
    let position: IdealSchedulePosition = new IdealSchedulePosition();
    position.deviation = dto.deviation;
    position.isDailyVariance = dto.isDailyVariance;
    position.isActive = dto.isActive;
    position.targetHours = dto.targetHours;
    position.totalHours = dto.totalHours;
    position.position = dto.position;
    position.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    position.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    position.idealScheduleType = _.find(
      IdealScheduleConfigTypes,
      idealScheduleConfigType => idealScheduleConfigType.id === (<any>IdealScheduleConfigTypeDefinition)[dto.idealScheduleType.toString()]
    );
    position.idealScheduleCensus = _.find(
      IdealScheduleConfigCensusOptions,
      idealScheduleConfigCensus => idealScheduleConfigCensus.id === (<any>IdealScheduleConfigCensusDefinition)[dto.calculationType.toString()]
    );
    position.targetCensus = dto.targetCensus;
    position.ranges = this.mapPositionRanges(dto.positionRanges, shifts, groups);
    position.isEmpty = !dto.positionRanges;

    let startDate: string = moment(position.startDate).format(appConfig.dateFormat);
    let endDate: string = moment(position.endDate).format(appConfig.dateFormat);
    if (endDate === appConfig.maxCorrectDate) {
      endDate = 'No End';
    }
    position.name = `${startDate} - ${endDate}`;
    return position;
  }

  public mapIdealSchedulePositionsHistory(dtos: IdealSchedulePositionHistoryRecordDTO[]): IdealSchedulePositionHistoryRecord[] {
    let result: IdealSchedulePositionHistoryRecord[] = [];
    _.forEach(dtos, (dto: IdealSchedulePositionHistoryRecordDTO) => {
      result.push(this.mapIdealSchedulePositionHistoryRecord(dto));
    });
    return _.sortBy(result, item => item.startDate);
  }

  public mapIdealSchedulePositionHistoryRecord(dto: IdealSchedulePositionHistoryRecordDTO): IdealSchedulePositionHistoryRecord {
    let position: IdealSchedulePositionHistoryRecord = new IdealSchedulePositionHistoryRecord();
    position.deviation = dto.deviation;
    position.isDailyVariance = dto.isDailyVariance;
    position.isActive = dto.isActive;
    position.targetHours = dto.targetHours;
    position.startDate = moment(dateTimeUtils.convertFromDtoString(dto.startDate));
    position.endDate = moment(dateTimeUtils.convertFromDtoString(dto.endDate));
    position.idealScheduleType = _.find(
      IdealScheduleConfigTypes,
      idealScheduleConfigType => idealScheduleConfigType.id === (<any>IdealScheduleConfigTypeDefinition)[dto.idealScheduleType.toString()]
    );
    position.idealScheduleId = dto.idealScheduleId;
    position.idealSchedulePositionId = dto.idealSchedulePositionId;
    position.jobCode = dto.jobCode;

    let startDate: string = position.startDate.format(appConfig.dateFormat);
    let endDate: string = position.endDate.format(appConfig.dateFormat);
    if (endDate === appConfig.maxCorrectDate) {
      endDate = 'No End';
    }
    position.name = `${startDate} - ${endDate}`;
    return position;
  }

  public mapPositionRanges(dtos: IdealSchedulePositionRangeDTO[], shifts: Lookup, groups: Lookup): IdealSchedulePositionRange[] {
    let result: IdealSchedulePositionRange[] = [];
    _.forEach(dtos, (dto: IdealSchedulePositionRangeDTO) => {
      result.push(this.mapIdealSchedulePositionRange(dto, shifts, groups));
    });

    if (!result.length) {
      result.push(new IdealSchedulePositionRange());
    }

    return result;
  }

  public mapIdealSchedulePositionRange(dto: IdealSchedulePositionRangeDTO, shifts: Lookup, groups: Lookup): IdealSchedulePositionRange {
    let range: IdealSchedulePositionRange = new IdealSchedulePositionRange();
    range.id = dto.id;
    range.acuity = this.mapIdealScheduleAcuity(_.first(dto.acuities));
    range.parLevels = this.mapPositionDays(dto.positionDays, shifts, groups);

    return range;
  }

  public mapIdealScheduleAcuities(dtos: IdealScheduleAcuityDTO[]): IdealScheduleAcuity[] {
    let result: IdealScheduleAcuity[] = [];
    _.forEach(dtos, (dto: IdealScheduleAcuityDTO) => {
      result.push(this.mapIdealScheduleAcuity(dto));
    });
    return result;
  }

  public mapIdealScheduleAcuity(dto: IdealScheduleAcuityDTO): IdealScheduleAcuity {
    let idealScheduleAcuity: IdealScheduleAcuity = new IdealScheduleAcuity();

    let acuity: Acuity;

    if (dto.acuity) {
      acuity = new Acuity();
      acuity.id = dto.acuity.id;
      acuity.name = dto.acuity.name;
      acuity.orgLevelId = dto.acuity.orgLevelId;
    }

    idealScheduleAcuity.acuity = acuity;
    idealScheduleAcuity.maxValue = dto.maxValue;
    idealScheduleAcuity.minValue = dto.minValue;

    return idealScheduleAcuity;
  }

  public mapPositionDays(daysDto: IdealSchedulePositionDayDTO[], shifts: Lookup, groups: Lookup): StringMap<ParLevels> {
    let map: StringMap<ParLevels> = {};

    _.forEach(daysDto, (dto: IdealSchedulePositionDayDTO) => {
      _.forEach(dto.parLevels, (parDto: ParLevelsDTO) => {
        let key: string = `${parDto.shiftGroup.name}-${parDto.shift.name}-${parDto.unit.name}`;
        let weekDay: DayOfWeek = WeekDaysWithEmpty.filter((item: DayOfWeek) => item.name === dto.weekdayName)[0];

        let item: ParLevels = map[key];
        let durationHours;
        if (!item) {
          //clone hours value from Basic column (0 index) or Monday column (2 index)
          let isBaseParLevel: boolean = dto.weekday === 0 || dto.weekday === 2;
          item = this.createParLevels(daysDto, parDto, isBaseParLevel, key, shifts, groups);
          map[key] = item;
        }
        durationHours = item.shift ? item.shift.durationHours : item.shiftGroup.durationHours;
        durationHours = durationHours ? durationHours : 1;
        item.setDayCounter(weekDay.dayNumber, parDto.hours / durationHours);
        item.setDayHours(weekDay.dayNumber, parDto.hours);
      });
    });

    return map;
  }

  public revertMapAcuity(acuity: IdealScheduleAcuity): IdealScheduleAcuityDTO {
    const dto: IdealScheduleAcuityDTO = new IdealScheduleAcuityDTO();
    let dtoAcuity: IAcuity = null;

    if (acuity.acuity) {
      dtoAcuity = {
        id: acuity.acuity.id,
        name: acuity.acuity.name,
        orgLevelId: acuity.acuity.orgLevelId
      };
    }

    dto.acuity = dtoAcuity;
    dto.maxValue = acuity.maxValue;
    dto.minValue = acuity.minValue;

    return dto;
  }

  public reverseMapIdealSchedulePosition(data: IdealSchedulePosition): IdealSchedulePositionDTO {
    let position: IdealSchedulePositionDTO = new IdealSchedulePositionDTO();
    position.deviation = data.deviation;
    position.isDailyVariance = data.isDailyVariance;
    position.isActive = data.isActive;
    position.targetHours = data.targetHours;
    position.totalHours = data.totalHours;
    position.position = data.position;
    position.startDate = data.startDate ? moment(data.startDate).format(appConfig.dateFormat) : '';
    position.endDate = data.endDate ? moment(data.endDate).format(appConfig.dateFormat) : '';
    position.idealScheduleType = IdealScheduleConfigTypeDefinition[data.idealScheduleType.id];
    position.calculationType = IdealScheduleConfigCensusDefinition[data.idealScheduleCensus.id];
    position.targetCensus = data.targetCensus;

    const isFixed: boolean = data.idealScheduleCensus.id === IdealScheduleConfigCensusDefinition.Fixed;

    if (isFixed) {
      data.ranges = [_.first(data.ranges)];
    }

    position.positionRanges = this.reverMapPositionRanges(data.ranges, isFixed);

    return position;
  }

  public reverMapPositionRanges(ranges: IdealSchedulePositionRange[], isFixed: boolean): IdealSchedulePositionRangeDTO[] {
    let map: IdealSchedulePositionRangeDTO[] = [];

    _.forEach(ranges, (range: IdealSchedulePositionRange) => {
      let dto: IdealSchedulePositionRangeDTO = new IdealSchedulePositionRangeDTO();

      dto.id = range.id;
      dto.acuities = isFixed ? [] : [this.revertMapAcuity(range.acuity)];
      dto.positionDays = this.revertMapPositionDays(range.parLevels);

      map.push(dto);
    });

    return map;
  }

  public revertMapPositionDays(parLevels: StringMap<ParLevels>): IdealSchedulePositionDayDTO[] {
    let map: IdealSchedulePositionDayDTO[] = [];

    _.forEach(WeekDaysWithEmpty, (day: DayOfWeek) => {
      let positionDay: IdealSchedulePositionDayDTO = new IdealSchedulePositionDayDTO();

      positionDay.weekday = day.dayNumber;
      positionDay.weekdayName = day.name;
      positionDay.parLevels = this.revertMapParLevel(parLevels, day);
      map.push(positionDay);
    });
    return map;
  }

  public revertMapParLevel(parLevels: StringMap<ParLevels>, day: DayOfWeek): ParLevelsDTO[] {
    let map: ParLevelsDTO[] = [];

    _.forOwn(parLevels, (par: ParLevels) => {
      let dto: ParLevelsDTO = new ParLevelsDTO();
      dto.id = par.id;
      dto.hours = _.round((par.isDailyVariance ? par.days[0].hours : par.days[day.dayNumber].hours), 2);
      dto.shiftGroup = !_.isNil(par.shiftGroup) ? this.lookupMapService.reverseShiftGroupDefinition(par.shiftGroup) : undefined;
      dto.shift = !_.isNil(par.shift) ? this.lookupMapService.mapShiftDefinitionDto(par.shift) : undefined;
      dto.unit = !_.isNil(par.unit) ? this.lookupMapService.mapLocationUnitDto(par.unit) : undefined;
      map.push(dto);
    });
    return map;
  }

  public mapIdealScheduleConfiguration(orgLevelId: number, startDate: moment.Moment, endDate: moment.Moment,
    positionId: number, targetHours: number): IdealScheduleConfigurationDTO {
    let dto: IdealScheduleConfigurationDTO = new IdealScheduleConfigurationDTO();
    dto.startDate = dateTimeUtils.convertToDtoString(startDate.toDate());
    dto.endDate = dateTimeUtils.convertToDtoString(endDate.toDate());
    let g: any = this.mapIdealScheduleConfigurationRecord(orgLevelId, positionId, targetHours);
    dto.records = [g];
    return dto;
  }

  public mapidealScheduleShiftGroup(response): IdealScheduleShiftGroupDTO {
    let dto: IdealScheduleShiftGroupDTO = new IdealScheduleShiftGroupDTO();
    dto.flagStatus = response.flagStatus;
    dto.shiftGroupCount = response.shiftGroupCount;
    dto.shifts = response.shifts
    return dto;

  }
  public mapIdealScheduleConfigurationRecord(orgLevelId: number, positionId: number,
    targetHours: number): IdealScheduleConfigurationRecordDTO {
    let dto: IdealScheduleConfigurationRecordDTO = new IdealScheduleConfigurationRecordDTO();
    dto.departmentId = orgLevelId;
    dto.positionId = positionId;
    dto.targetHours = targetHours;
    return dto;
  }

  private getRangeFromIdealSchedulePositionDTO(dto: IdealSchedulePositionDTO): IdealSchedulePositionPeriod {
    let range: IdealSchedulePositionPeriod = new IdealSchedulePositionPeriod();
    range.startDate = dateTimeUtils.convertFromDtoString(dto.startDate);
    range.endDate = dateTimeUtils.convertFromDtoString(dto.endDate);
    return range;
  }

  private createParLevels(daysDto: IdealSchedulePositionDayDTO[], parDto: ParLevelsDTO, isBasePosition: boolean, key: string, shifts: Lookup, groups: Lookup): ParLevels {
    let item: ParLevels = new ParLevels();
    let hours: number = parDto.hours;
    if (!isBasePosition) {
      let baseParLevel: ParLevelsDTO = this.findBaseParLevel(daysDto, parDto);
      hours = baseParLevel ? baseParLevel.hours : 0;
    }

    item.uniqueId = key;
    item.id = parDto.id;
    item.shiftGroup = groups.items.find((group: any) => group.id === parDto.shiftGroup.id);
    item.shift = shifts.items.find((shift: any) => shift.id === parDto.shift.id);
    item.unit = this.lookupMapService.mapLocationUnit(parDto.unit);
    let durationHours: number = item.shift ? item.shift.durationHours : item.shiftGroup.durationHours;
    item.setDayCounter(0, hours / durationHours);
    item.setDayHours(0, hours);

    return item;
  }

  private findBaseParLevel(daysDto: IdealSchedulePositionDayDTO[], parDto: ParLevelsDTO): ParLevelsDTO {
    let result: ParLevelsDTO = null;
    _.forEach(daysDto, (dto: IdealSchedulePositionDayDTO) => {
      //clone hours value from Basic column (0 index) or Monday column (2 index)
      if (dto.weekday !== 0 && dto.weekday !== 2) {
        return true;
      }

      result = _.find(dto.parLevels, (par: ParLevelsDTO) => {
        return par.shiftGroup.id === parDto.shiftGroup.id
          && (!parDto.shift || par.shift.id === parDto.shift.id)
          && (!parDto.unit || par.unit.id === parDto.unit.id);
      });

      return !result;
    });
    return result;
  }

  public mapOrgLevelDetails(dto: any[]) {
    return _.map(dto, (item) => this.mapOrgLevels(item));
  }

  public mapOrgLevels(data: any) {
    let orgLevelDto: OrgLevelDetails = new OrgLevelDetails();
    orgLevelDto.organizationId = data.organizationId;
    orgLevelDto.organizationName = data.name;
    orgLevelDto.orgLevelId = data.id;
    orgLevelDto.isChecked = false;
    return orgLevelDto;
  }

  public mapImportTemplateData(data: IdealScheduleImportTemplate): ImportTemplateDto {
    let result = data.positionList.filter(x => x.isChecked);
    let importTemplateDto: ImportTemplateDto = new ImportTemplateDto();
    let output: ImportedPositionsDto[] = [];
    _.forEach(result, (item) => {
      let res: ImportedPositionsDto = new ImportedPositionsDto();
      res.positionId = item.position.id;
      res.positionName = item.position.name;
      res.configurationType = this.setConfigurationName(item.idealScheduleType.name);
      res.censusType = item.idealScheduleCensus.name;
      res.idealScheduleId = item.idealScheduleId;
      output.push(res);
    });
    importTemplateDto.importedPositions = output;
    return importTemplateDto;
  }

  public setConfigurationName(configName: string) {
    if (configName == 'Shift & Unit') {
      return 'ShiftUnit';
    }
    else if (configName == 'Shift Group') {
      return 'ShiftGroup';
    }
    else {
      return configName;
    }
  }

  public mapValidationException(data: IImportException[]): ImportException[] {
    return _.map(data, (item) => this.mapExceptionDto(item));
  }

  public mapExceptionDto(dto: IImportException): ImportException {
    const data: ImportException = new ImportException();
    data.idealScheduleId = dto.idealScheduleId;
    data.positionId = dto.positionId;
    data.positionName = dto.positionName;
    data.configurationType = dto.configurationType;
    data.censusType = dto.censusType;
    data.errorMessage = dto.errorMessage;
    data.isInvalidCensusType = dto.isInvalidCensusType ? dto.isInvalidCensusType : false;
    data.isInvalidConfigType = dto.isInvalidConfigType ? dto.isInvalidConfigType : false;
    data.isConfigured = dto.isConfigured ? dto.isConfigured : false;
    data.isNoException = (dto.isConfigured && !dto.isInvalidConfigType && !dto.isInvalidCensusType) ? true : false
    return data;
  }

  public mapExcelResultData(jsonData: any): TemplateValidator[] {
    return _.map(jsonData, (item: TemplateValidator, index: number) => this.mapTemplateObject(item, index));
  }

  public mapTemplateObject(item: TemplateValidator, index: number): TemplateValidator {
    const data: TemplateValidator = new TemplateValidator();
    data.UniqueId = UUID();
    data.Facility = item.Facility;
    data.Department = item.Department;
    data.ConfigurationType = item.ConfigurationType;
    data.CensusType = item.CensusType;
    data.Position = item.Position;
    data.AcuityTypes = item.AcuityTypes ? item.AcuityTypes : null;
    data.ShiftGroup = item.ShiftGroup;
    data.Shift = item.Shift;
    data.Unit = item.Unit ? item.Unit : null;
    data.CensusRangeFrom = item.CensusRangeFrom;
    data.CensusRangeTo = item.CensusRangeTo;
    data.Sunday = item.Sunday;
    data.Monday = item.Monday;
    data.Tuesday = item.Tuesday;
    data.Wednesday = item.Wednesday;
    data.Thursday = item.Thursday;
    data.Friday = item.Friday;
    data.Saturday = item.Saturday;
    data.IsValidData = true;
    return data;
  }

  public mapDownloadedData(dto: any): DownloadTemplate {
    const data: DownloadTemplate = new DownloadTemplate();
    data.fileName = dto.fileName;
    data.fileData = _.map(dto.fileData, (item: DownloadedData) => this.mapDownloadedObject(item));
    return data;
  }
  public mapDownloadedObject(item: DownloadedData): DownloadedExcelData {
    const data: DownloadedExcelData = new DownloadedExcelData();
    data.Facility = item.facility;
    data.Department = item.department;
    data.ConfigurationType = item.configurationType;
    data.CensusType = item.censusType;
    data.Position = item.position;
    data.AcuityTypes = item.acuityTypes ? item.acuityTypes : null;
    data.ShiftGroup = item.shiftGroup;
    data.Shift = item.shift;
    data.Unit = item.unit ? item.unit : null;
    data.CensusRangeFrom = item.censusRangeFrom;
    data.CensusRangeTo = item.censusRangeTo;
    data.Sunday = item.sunday;
    data.Monday = item.monday;
    data.Tuesday = item.tuesday;
    data.Wednesday = item.wednesday;
    data.Thursday = item.thursday;
    data.Friday = item.friday;
    data.Saturday = item.saturday;
    return data;
  }

  public reverseMapSelectPositionData(data: TemplateValidator[]): TemplateValidator[] {
    return _.map(data, (item: TemplateValidator) => this.reverseMapResultSet(item));
  }

  public reverseMapResultData(data: ImportFileDetails): ImportFileDetails {
    const req: ImportFileDetails = new ImportFileDetails();
    req.recordId = data.recordId;
    req.fileData = _.map(data.fileData, (item: TemplateValidator) => this.reverseMapResultSet(item));
    return req;
  }

  public reverseMapResultSet(item: TemplateValidator): TemplateValidator {
    const res: TemplateValidator = new TemplateValidator();
    res.Facility = item.Facility;
    res.Department = item.Department;
    res.ConfigurationType = item.ConfigurationType;
    res.CensusType = item.CensusType;
    res.Position = item.Position;
    res.AcuityTypes = item.AcuityTypes ? item.AcuityTypes : null;
    res.ShiftGroup = item.ShiftGroup;
    res.Shift = item.Shift;
    res.Unit = item.Unit ? item.Unit : null;
    res.CensusRangeFrom = item.CensusRangeFrom;
    res.CensusRangeTo = item.CensusRangeTo;
    res.Sunday = item.Sunday;
    res.Monday = item.Monday;
    res.Tuesday = item.Tuesday;
    res.Wednesday = item.Wednesday;
    res.Thursday = item.Thursday;
    res.Friday = item.Friday;
    res.Saturday = item.Saturday;
    return res;
  }


}
