export interface IBenefitGroup {
    id: number;
    name: string;
    allowMultipleEnrollment: boolean;
}

export class BenefitGroup {
    public id: number;
    public name: string;
    public allowMultipleEnrollment: boolean;
}