import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import * as _ from 'lodash';

import { LookupService } from '../../services/lookup/lookup.service';
import {
  LocationUnit, ConstraintDefinition,
  ShiftDefinition,
  Lookup, LookupType, OrgLevelShiftTemplate
} from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-orglevel-shift-editor',
  templateUrl: 'orglevel-shift-editor.component.html',
  styleUrls: ['orglevel-shift-editor.component.scss']
})
export class OrglevelShiftEditorComponent {

  @Input('orgLevelId')
  public set orgLevelId(orgLevelId: number) {
    if (this.m_orgLevelId === orgLevelId) {
      return;
    }
    this.m_orgLevelId = orgLevelId;

    this.loadLookups();
  }

  public get orgLevelId(): number {
    return this.m_orgLevelId;
  }

  @Input()
  public shiftTemplate: OrgLevelShiftTemplate;

  @Output()
  public onShiftTemplateChanged: EventEmitter<OrgLevelShiftTemplate> = new EventEmitter<OrgLevelShiftTemplate>();

  public shiftLookup: Lookup;
  public unitLookup: Lookup;
  public constraintLookup: Lookup;
  public scheduleAbsenceLookup: Lookup;
  public get isAbsenceSelected(): boolean {
    return !!this.shiftTemplate.absence && !!this.shiftTemplate.absence.code;
  }

  public state: {
    isShiftLoading: boolean;
    isUnitLoading: boolean;
    isConstraintLoading: boolean;
    isAbsenceLoading: boolean;
  };

  private lookupService: LookupService;
  private m_orgLevelId: number;
  private unitSaved: LocationUnit;
  private constraintSaved: ConstraintDefinition;

  constructor(lookupService: LookupService) {
    this.lookupService = lookupService;
    this.state = {
      isShiftLoading: false,
      isUnitLoading: false,
      isConstraintLoading: false,
      isAbsenceLoading: false,
    };
  }

  public shiftChanged(): void {
    this.onShiftTemplateChanged.emit(this.shiftTemplate);
  }
  public unitChanged(): void {
    if (this.shiftTemplate.unit && this.shiftTemplate.unit.id) {
      this.shiftTemplate.absence = null;
    }
    this.onShiftTemplateChanged.emit(this.shiftTemplate);
  }

  public constraintChanged(): void {
    if (this.shiftTemplate.constraint && this.shiftTemplate.constraint.id) {
      this.shiftTemplate.absence = null;
    }
    this.onShiftTemplateChanged.emit(this.shiftTemplate);
  }
  public absenceChanged(): void {
    if (this.shiftTemplate.absence && this.shiftTemplate.absence.code) {
      this.unitSaved = this.shiftTemplate.unit;
      this.constraintSaved = this.shiftTemplate.constraint;
      this.shiftTemplate.unit = null;
      this.shiftTemplate.constraint = null;
    }
    this.onShiftTemplateChanged.emit(this.shiftTemplate);
  }

  public absenceRemove(): void {
    this.shiftTemplate.absence = null;
    this.shiftTemplate.unit = this.unitSaved;
    this.shiftTemplate.constraint = this.constraintSaved;
    this.onShiftTemplateChanged.emit(this.shiftTemplate);
  }

  public loadLookups(): void {
    this.shiftTemplate = new OrgLevelShiftTemplate();
    this.state.isShiftLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.shift, employeeId: undefined, orgLevelId: this.orgLevelId })
      .then((shiftLookup: Lookup) => {
        this.state.isShiftLoading = false;
        this.shiftLookup = shiftLookup;
      }).catch(() => {
        this.state.isShiftLoading = false;
      });

    this.state.isUnitLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.locationUnit, employeeId: undefined, orgLevelId: this.orgLevelId })
      .then((unitLookup: Lookup) => {
        this.state.isUnitLoading = false;
        this.unitLookup = unitLookup;
      }).catch(() => {
        this.state.isUnitLoading = false;
      });

    this.state.isConstraintLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.constraintDefinition, employeeId: undefined, orgLevelId: this.orgLevelId })
      .then((constraintLookup: Lookup) => {
        this.constraintLookup = constraintLookup;
        this.state.isConstraintLoading = false;
      })
      .catch(() => {
        this.state.isConstraintLoading = false;
      });

    this.state.isAbsenceLoading = true;
    this.lookupService.getLookup({ lookupType: LookupType.scheduleAbsence, orgLevelId: this.orgLevelId })
      .then((scheduleAbsenceLookup: Lookup) => {
        this.scheduleAbsenceLookup = scheduleAbsenceLookup;
        this.state.isAbsenceLoading = false;
      })
      .catch(() => {
        this.state.isAbsenceLoading = false;
      });
  }
}
