import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { CostCentersConfigurationManagementService, AccessManagementService } from '../../services/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { ModalService } from '../../../common/services/index';
import { CostCenterModel } from '../../models/index';
import { IScreenUtils, screenUtils } from '../../../common/utils/index';


@Component({
    moduleId: module.id,
    selector: 'slx-configure-cost-centers',
    templateUrl: 'configure-cost-centers.component.html',
    styleUrls: ['configure-cost-centers.component.scss'],
    providers: [CostCentersConfigurationManagementService, AccessManagementService]
})

export class ConfigureCostCentersComponent implements OnInit {

    public screenUtils: IScreenUtils;

    public state: { isLoading: boolean };

    @destroyService()
    private management: CostCentersConfigurationManagementService;

    @unsubscribe()
    private stateSubscription: Subscription;
    @unsubscribe()
    private noSelectedSubscription: Subscription;


    constructor(management: CostCentersConfigurationManagementService, private modalService: ModalService) {
        this.management = management;
        this.state = { isLoading: false };
        this.screenUtils = screenUtils;
    }

    public isMobile(): boolean {
        return screenUtils.isMobile;
    }

    public ngOnInit(): void {

        this.stateSubscription = this.management.onLoadStatus$.subscribe((isLoading: false) => {
            this.state.isLoading = isLoading;
        });

        this.noSelectedSubscription = this.management.noSelectedItem$.subscribe(() => {
            this.modalService.globalAnchor.openInfoDialog('Info', 'Please, select item');
        });

        this.noSelectedSubscription = this.management.confirmDelete$.subscribe((item: CostCenterModel) => {
            this.modalService.globalAnchor.openConfirmDialog(
                'Confirmation',
                `There are ${item.employeeCount} employees currently assigned to this Cost Center. Are you sure you want to remove this cost center?`,
                (result: boolean) => {
                    if (result) {
                        this.management.deleteItem(item);
                    }
                }
            );
        });

        this.management.init();
    }
}
