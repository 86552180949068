import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';


@Injectable()
export class MessagesService {
  public resetClosed$: Subject<boolean>;


  constructor() {
    this.resetClosed$ = new Subject<boolean>();
  }
  public resetClosed(): void {
    this.resetClosed$.next(true);
  }
}
