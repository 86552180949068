<slx-spinner [show]="state.isLoading || state.isChildLoading">
  <div class="slx-high-box">
    <div class="pbj-export-header flex fl-flow-rn slx-content-toolbar-indents">
      <div class="flex fl-flow-rn fl-ai-center fl-grow-1">
        <span class="pbj-export-header__button flex fl-flow-cn fl-ai-center">
          <button type="button" class="slx-button slx-min-w120" (click)="onEmployeeData()" [ngClass]="{'slx-blue': state.empData}">Employee Data</button>
          <span class="pbj-export-header__counter">&nbsp;</span>
        </span>
        <span class="pbj-export-header__button flex fl-flow-cn fl-ai-center">
          <button type="button" class="slx-button slx-min-w120" (click)="onStaffingHours()" [ngClass]="{'slx-blue': state.staffingHours}">Staffing Hours</button>
          <span class="pbj-export-header__counter">&nbsp;</span>
        </span>
        <span class="pbj-export-header__button flex fl-flow-cn fl-ai-center"
          *ngIf="pbjHeader?.censusDataCount">
          <button type="button" class="slx-button slx-min-w120" (click)="onCensus()" [ngClass]="{'slx-blue': state.census}">Census</button>
          <span class="pbj-export-header__counter">&nbsp;</span>
        </span>
        <span class="pbj-export-header__button flex fl-flow-cn fl-ai-center">
          <button type="button" class="slx-button slx-min-w120" (click)="onErrors()" [ngClass]="{'slx-blue': state.errors}">Errors</button>
          <span class="pbj-export-header__counter">{{pbjHeader?.totalMessagesCount}}</span>
        </span>
      </div>
      <slx-action-list>
        <slx-action-button [iconName]="'fas fa-caret-down'"
        [popperContent]="popperContent" [popperPosition]="'bottom-end'">Actions</slx-action-button>
        <popper-content #popperContent>
          <slx-action-list-item (onClick)="onExportToExcel()">{{excelName}}</slx-action-list-item>
          <slx-action-list-item (onClick)="onExportToPdf()">{{pdfName}}</slx-action-list-item>
          <slx-action-list-item [disabled]="hasErrors" [title]="title" (onClick)="onXMLFile()">{{xmlName}}</slx-action-list-item>
        </popper-content>
      </slx-action-list>
    </div>

    <div class="slx-high-box__body slx-main-content-indents">
      <h3 class="pbj-export-name">
        <span class="pbj-export-name__text">{{pbjHeader?.organization?.name}} ({{pbjHeader?.startDate| amDateFormat: appConfig.dateFormat}} - {{pbjHeader?.endDate| amDateFormat: appConfig.dateFormat}}) Exported on {{pbjHeader?.exportDate| amDateFormat: appConfig.dateTimeFormatUS}}</span>
      </h3>
      <div class="pbj-export-grid">
        <ng-container *ngIf="state.empData">
          <slx-pbj-details-emp-data-grid #empDataGrid class="slx-high-box"
            [pbjHeader]="pbjHeader"
            [exportId]="exportId"
            [pbjIdConfigEnabled]="pbjHeader?.pbjIdConfigEnabled"
            (loadStatus)="onChildLoadStatusChange($event)"
          ></slx-pbj-details-emp-data-grid>
        </ng-container>
        <ng-container *ngIf="state.census">
          <slx-pbj-details-census-data-grid #censusDataGrid class="slx-high-box"
            [pbjHeader]="pbjHeader"
            [exportId]="exportId"
            [pbjIdConfigEnabled]="pbjHeader?.pbjIdConfigEnabled"
            (loadStatus)="onChildLoadStatusChange($event)"
          ></slx-pbj-details-census-data-grid>
        </ng-container>
        <ng-container *ngIf="state.errors">
          <slx-pbj-details-errors-data-grid #errorsDataGrid class="slx-high-box"
            [pbjHeader]="pbjHeader"
            [exportId]="exportId"
            [pbjIdConfigEnabled]="pbjHeader?.pbjIdConfigEnabled"
            (loadStatus)="onChildLoadStatusChange($event)"
          ></slx-pbj-details-errors-data-grid>
        </ng-container>
        <ng-container *ngIf="state.staffingHours">
          <slx-pbj-details-staffing-hours-grid #staffingHoursGrid class="slx-high-box"
            [pbjHeader]="pbjHeader"
            [exportId]="exportId"
            [pbjIdConfigEnabled]="pbjHeader?.pbjIdConfigEnabled"
            (loadStatus)="onChildLoadStatusChange($event)"
          ></slx-pbj-details-staffing-hours-grid>
        </ng-container>
      </div>
    </div>
  </div>
</slx-spinner>
