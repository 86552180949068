<slx-spinner [show]="isLoading">
  <div class="msg-list">
    <kendo-grid #kendoGrid class="slx-msg-grid" [data]="gridState?.view">
      <ng-template kendoGridNoRecordsTemplate>
        <span *ngIf="gridState?.view && records.length == 0">No records available.</span>
      </ng-template>
      <kendo-grid-column>
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="msg-row" [class.active]="!dataItem.read" (click)="updateReadStatus(dataItem)">
            <div class="msg-time">{{ dataItem.modified | convertDate}}</div>
            <div class="line-1">
              <div>
                <span class="msg-from" *ngIf="!dataItem.replied"><span class="label-text">To:</span>{{dataItem.fullName | convertUserFrndlyName}} </span>
                <span class="msg-from" *ngIf="dataItem.replied"><span class="label-text">From:</span>{{dataItem.fullName | convertUserFrndlyName}} </span>
                <a class="msg-phone" href="tel:{{dataItem.mobilePhoneNumber}}">{{dataItem.mobilePhoneNumber}}</a>
                <span><em [ngClass]="[dataItem.replyCount === 1 ? 'fas fa-reply' : '', dataItem.replyCount > 1 ? 'fas fa-reply-all' : '']"></em></span>
              </div>
            </div>
            <div class="line-2">
              {{dataItem.body}}
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
    <div class="textdiv" (click)="redirectToMSGCenter()">
      <label *ngIf="records.length > 5">View All SMS</label>
    </div>
  </div>
</slx-spinner>
