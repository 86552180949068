
<label [attr.class]="cssClass()">
  <span class="slx-checkbox__wrapper">
    <input type="checkbox" class="slx-checkbox__checkbox"
      [disabled]="readonly ? true : null"
      [(ngModel)]="value"
      [name]="fieldName"
    />
    <i class="fas fa-check slx-checkbox__tick" aria-hidden="true" [ngClass]="{ 'checked': isChecked }"></i>
  </span>
  <span *ngIf="hasPlaceholder()" class="slx-checkbox__label">{{placeholder}}</span>
</label>
