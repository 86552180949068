/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./arrivals-departures-timeline-details-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../arrivals-departures-timeline-details-row/arrivals-departures-timeline-details-row.component.ngfactory";
import * as i3 from "../arrivals-departures-timeline-details-row/arrivals-departures-timeline-details-row.component";
import * as i4 from "@angular/common";
import * as i5 from "./arrivals-departures-timeline-details-grid.component";
import * as i6 from "../../../services/arrivals-departures/arrivals-departures-timeline-details.service";
var styles_ArrivalsDeparturesTimelineDetailsGridComponent = [i0.styles];
var RenderType_ArrivalsDeparturesTimelineDetailsGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ArrivalsDeparturesTimelineDetailsGridComponent, data: {} });
export { RenderType_ArrivalsDeparturesTimelineDetailsGridComponent as RenderType_ArrivalsDeparturesTimelineDetailsGridComponent };
function View_ArrivalsDeparturesTimelineDetailsGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-arrivals-departures-timeline-details-row", [], null, [[null, "detectChanges"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("detectChanges" === en)) {
        var pd_0 = (_co.changeDetection() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ArrivalsDeparturesTimelineDetailsRowComponent_0, i2.RenderType_ArrivalsDeparturesTimelineDetailsRowComponent)), i1.ɵdid(2, 49152, null, 0, i3.ArrivalsDeparturesTimelineDetailsRowComponent, [], { workDate: [0, "workDate"], details: [1, "details"], record: [2, "record"] }, { detectChanges: "detectChanges" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.workDate; var currVal_1 = _co.details; var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ArrivalsDeparturesTimelineDetailsGridComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ArrivalsDeparturesTimelineDetailsGridComponent_1)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.details == null) ? null : _co.details.filteredRecords); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ArrivalsDeparturesTimelineDetailsGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-arrivals-departures-timeline-details-grid", [], null, null, null, View_ArrivalsDeparturesTimelineDetailsGridComponent_0, RenderType_ArrivalsDeparturesTimelineDetailsGridComponent)), i1.ɵdid(1, 245760, null, 0, i5.ArrivalsDeparturesTimelineDetailsGridComponent, [i1.ChangeDetectorRef, i6.ArrivalsDeparturesTimelineDetailsManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ArrivalsDeparturesTimelineDetailsGridComponentNgFactory = i1.ɵccf("slx-arrivals-departures-timeline-details-grid", i5.ArrivalsDeparturesTimelineDetailsGridComponent, View_ArrivalsDeparturesTimelineDetailsGridComponent_Host_0, {}, {}, []);
export { ArrivalsDeparturesTimelineDetailsGridComponentNgFactory as ArrivalsDeparturesTimelineDetailsGridComponentNgFactory };
