<kendo-grid #kendoGrid kendoGridFixFreezeHeader class="time-and-attendance exception-console slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="true"
  [filter]="gridState.state.filter"
  (pageChange)="gridState.pageChange($event)"
  (dataStateChange)="gridState.dataStateChange($event)"
  [skip]="gridState.state.skip"
  slxKendoGridState="PUNCHES_ROLLUP"
>
  <kendo-grid-column media="(max-width: 500px)" title="Items">
    <ng-template kendoGridCellTemplate let-dataItem>
      <dl>
        <dt>Work Organization</dt>
        <dd>{{  dataItem.workOrgName }}</dd>

        <dt>{{ orgLevelTitle }}</dt>
        <dd>{{  getOrgLevelName(dataItem) }}</dd>

        <dt>Approved Timecards</dt>
        <dd>{{ dataItem.approvedTimecards}}%</dd>

        <dt>Valid Timecards</dt>
        <dd>{{ dataItem.validTimecards }}</dd>

        <dt>Missing Punches</dt>
        <dd>{{ dataItem.missingPunches }}</dd>

        <dt>Pending Emp Request</dt>
        <dd>{{ dataItem.pendingEmpRequest }}</dd>

        <dt>Invalid Punches</dt>
        <dd>{{ dataItem.invalidPunches }}</dd>

        <dt>Scheduled Punches</dt>
        <dd>{{ dataItem.scheduledPunches }}</dd>
      </dl>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title ="Work Organization"
  [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="workOrganization" media="sm" field="workOrgName">
  <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
    <strong>{{value}}</strong>
  </ng-template>
  <ng-template kendoGridCellTemplate let-dataItem>
      <a (click)="onOrgLevelClickedWork(dataItem)">
          {{ dataItem.workOrgName }}
      </a>
  </ng-template>
  <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
    <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
    </slx-kendo-grid-string-filter>
  </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [title]="orgLevelTitle" *ngIf="!needShowDepartments"
    [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="organization" media="sm" field="orgName">
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
        <a (click)="onOrgLevelClicked(dataItem)">
            {{ getOrgLevelName(dataItem) }}
        </a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="paycycle" media="sm">
    <ng-template kendoGridHeaderTemplate>
      <span class="header-title">Payroll Cycle</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem *ngIf="!needShowDepartments">
      <a (click)="onPaycycleClicked(dataItem)" *ngIf="dataItem?.startDate">
        {{dataItem?.startDate | amDateFormat: appConfig.dateFormat }} - {{dataItem?.endDate | amDateFormat: appConfig.dateFormat }}
      </a>
      <span *ngIf="!dataItem?.startDate">No Pay Cycle</span>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem *ngIf="needShowDepartments">
      <span *ngIf="dataItem?.startDate">
        {{dataItem?.startDate | amDateFormat: appConfig.dateFormat }} - {{dataItem?.endDate | amDateFormat: appConfig.dateFormat }}
      </span>
      <span *ngIf="!dataItem?.startDate">No Pay Cycle</span>
    </ng-template>
  </kendo-grid-column>


  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="approvedTimecards" media="sm"
    field="approvedTimecards"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="header-title">Approved Timecards</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.approvedTimecards | number: ".0-0"}}%
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['approvedTimecards']?.sum  | number: ".0-0"}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="150" kendoGridColumnId="validTimecards" media="sm" field="validTimecards"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="header-title">Valid Timecards</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.validTimecards}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['validTimecards']?.sum}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="missingPunches" media="sm" field="missingPunches"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="header-title">Missing Punches</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.missingPunches}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['missingPunches']?.sum }}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="pendingEmpRequest" media="sm" field="pendingEmpRequest"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="header-title">Pending Emp Requests</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.pendingEmpRequest| number}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['pendingEmpRequest']?.sum}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="invalidPunches" media="sm" field="invalidPunches"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="header-title">Invalid Punches</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.invalidPunches| number}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['invalidPunches']?.sum}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [kendoGridColumnConfig]="state.columnsMap" width="100" kendoGridColumnId="scheduledPunches" media="sm" field="scheduledPunches"
    class="numeric-cell" footerClass="numeric-cell">
    <ng-template kendoGridHeaderTemplate>
      <span class="header-title">Scheduled, No Show</span>
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.scheduledPunches}}
    </ng-template>
    <ng-template kendoGridFooterTemplate let-column>
      <strong>{{total['scheduledPunches']?.sum}}</strong>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
