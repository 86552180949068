/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scheduled-rotations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./scheduled-rotations-toolbar/scheduled-rotations-toolbar.component.ngfactory";
import * as i3 from "./scheduled-rotations-toolbar/scheduled-rotations-toolbar.component";
import * as i4 from "../../services/scheduled-rotation-template/schedule-rotations-management.service";
import * as i5 from "../../../common/services/device-detector/device-detector.service";
import * as i6 from "../../../common/services/modal/modal.service";
import * as i7 from "../../../common/services/change-management/change-management.service";
import * as i8 from "../../services/scheduled-rotation-template/scheduled-rotation-map.service";
import * as i9 from "./scheduled-rotations.component";
import * as i10 from "../../../organization/services/lookup/lookup-api.service";
import * as i11 from "../../../organization/services/lookup/lookup.service";
var styles_ScheduledRotationsComponent = [i0.styles];
var RenderType_ScheduledRotationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduledRotationsComponent, data: {} });
export { RenderType_ScheduledRotationsComponent as RenderType_ScheduledRotationsComponent };
export function View_ScheduledRotationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-scheduled-rotations-toolbar", [], null, null, null, i2.View_ScheduledRotationsToolbarComponent_0, i2.RenderType_ScheduledRotationsToolbarComponent)), i1.ɵdid(2, 114688, null, 0, i3.ScheduledRotationsToolbarComponent, [i4.ScheduleRotationsManagementService, i5.DeviceDetectorService, i6.ModalService, i7.ChangeManagementService, i8.ScheduledRotationMapService], { orgLevelId: [0, "orgLevelId"], positionGroupLookup: [1, "positionGroupLookup"], positionLookup: [2, "positionLookup"], shiftsLookup: [3, "shiftsLookup"], startDate: [4, "startDate"], endDate: [5, "endDate"], unitsData: [6, "unitsData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orgLevelId; var currVal_1 = _co.positionGroupLookup; var currVal_2 = _co.positionLookup; var currVal_3 = _co.shiftsLookup; var currVal_4 = _co.startDate; var currVal_5 = _co.endDate; var currVal_6 = _co.unitsData; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ScheduledRotationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-scheduled-rotations", [], null, null, null, View_ScheduledRotationsComponent_0, RenderType_ScheduledRotationsComponent)), i1.ɵdid(1, 114688, null, 0, i9.ScheduledRotationsComponent, [i10.LookupApiService, i4.ScheduleRotationsManagementService, i11.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduledRotationsComponentNgFactory = i1.ɵccf("slx-scheduled-rotations", i9.ScheduledRotationsComponent, View_ScheduledRotationsComponent_Host_0, {}, {}, []);
export { ScheduledRotationsComponentNgFactory as ScheduledRotationsComponentNgFactory };
