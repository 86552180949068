import { Component, OnInit, Input, OnDestroy, Host, ViewChild, NgZone, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';

import { Assert } from '../../../../../framework/index';

import { EmployeeSectionAccrualsBalances } from '../../../models/employee-sections-accruals/index';
import { EmployeeSectionsBase } from '../../../models/employee-sections-base';
import { EmployeeSectionsAccrualsApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { AppServerConfig } from './../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../../app-settings/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-accruals',
  templateUrl: 'employee-sections-accruals.component.html',
  styleUrls: ['employee-sections-accruals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsAccrualsComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public set accruals(accrualsObject: EmployeeSectionAccrualsBalances) {
    this.empAccruals = accrualsObject;
  }
  @Input() public employeeId: number;

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  @ViewChild('form', {static: false})
  public ngForm: NgForm;


  public get accruals(): EmployeeSectionAccrualsBalances {
    return this.empAccruals;
  }

  private empAccruals: EmployeeSectionAccrualsBalances;
  public EnableRate4DecimalPlaces: boolean;

  constructor(
    private employeeSectionsAccrualsApiService: EmployeeSectionsAccrualsApiService,
    private appSettingsManageService: AppSettingsManageService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    ngZone: NgZone,
    private changeDetector: ChangeDetectorRef
  ) {
    super(decorator, ngZone);
  }

  public ngOnInit(): void {
    this.getSettings();
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.empAccruals;
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsAccrualsApiService.getEmployeeBalances(this.employeeId)
      .then((data: EmployeeSectionAccrualsBalances) => {
        this.accruals = data;
        this.stopProgress();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      }).catch(() => {
        this.stopProgress();
      });
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.EnableRate4DecimalPlaces =  config.EnableRate4DecimalPlaces;
  }

  public get isShowHighPrecision(): boolean {
    return this.EnableRate4DecimalPlaces;
  }
}
