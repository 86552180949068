import { Directive, Input, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { ScrollWatchEvent, ScrollWatchAreaEvent, IWatchAreaConfig, ScrollWatchAreaEventType } from '../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { ScrollWatchService } from '../../services/index';
import { Assert } from '../../../framework/index';


@Directive({
  selector: '[scrollWatchArea]',
})

export class ScrollWatchAreaDirective implements OnInit, OnDestroy {
  @Input('scrollWatchArea') public config: IWatchAreaConfig;


  private scrollWatchService: ScrollWatchService;
  private elementRef: ElementRef;
  private scrollSubscription: Subscription;
  private currentHeightState: ScrollWatchAreaEventType;

  constructor(scrollWatchService: ScrollWatchService, elementRef: ElementRef) {
    this.scrollWatchService = scrollWatchService;
    this.elementRef = elementRef;
    this.currentHeightState = ScrollWatchAreaEventType.outside;
  }

  public ngOnInit(): void {
    Assert.isNotNull(this.config, 'Config');
    this.scrollSubscription = this.scrollWatchService.subscribeScrollSource((event: ScrollWatchEvent) => this.onSourceScroll(event));
  }

  public ngOnDestroy(): void {
    this.scrollWatchService.unsubscribe(this.scrollSubscription);
  }

  private onSourceScroll(event: ScrollWatchEvent): void {
    Assert.isNotNull(event, 'event');
    if (event.sourceId !== this.config.watchSourceId) return;
    let scrollTargetElement: HTMLElement = this.elementRef.nativeElement;
    let areaEvent: ScrollWatchAreaEvent = new ScrollWatchAreaEvent(event);
    areaEvent.offsetTop = scrollTargetElement.offsetTop;
    areaEvent.offsetLeft = scrollTargetElement.offsetLeft;
    areaEvent.offsetWidth = scrollTargetElement.offsetWidth;
    areaEvent.offsetHeight = scrollTargetElement.offsetHeight;
    areaEvent.areaId = this.config.watchAreaId;
    this.recognizeHeightEvent(areaEvent);
    if (this.currentHeightState !== areaEvent.eventHeightType) {
      this.scrollWatchService.onScrollArea(areaEvent);
    }
    this.currentHeightState = areaEvent.eventHeightType;
  }

  private recognizeHeightEvent(areaEvent: ScrollWatchAreaEvent): void {
    Assert.isNotNull(areaEvent, 'areaEvent');
    //going to local coordinates
    let topInSource: number = areaEvent.offsetTop - areaEvent.scrollWatchEvent.scrollTop;
    let bottomInSource: number = topInSource + areaEvent.offsetHeight;
    let topScrollOffsetAbs: number = areaEvent.scrollWatchEvent.clientHeight / 100 * this.config.topScrollOffset;
    let bottomScrollOffsetAbs: number = areaEvent.scrollWatchEvent.clientHeight / 100 * this.config.bottomScrollOffset;
    let prepareScrollOffsetAbs: number = areaEvent.scrollWatchEvent.clientHeight / 100 * this.config.prepareScrollRange;
    let topOffsetInSource: number = topScrollOffsetAbs;
    let prepareTopOffsetInSource: number = topScrollOffsetAbs - prepareScrollOffsetAbs;
    let bottomOffsetInSource: number = areaEvent.scrollWatchEvent.clientHeight - bottomScrollOffsetAbs;
    let prepareBottomOffsetInSource: number = bottomOffsetInSource + prepareScrollOffsetAbs;
    if (topInSource <= prepareBottomOffsetInSource && bottomInSource >= prepareTopOffsetInSource) {
      //in prepare area
      if (topInSource <= bottomOffsetInSource && bottomInSource >= topOffsetInSource) {
        let outsideAreaOrPrepareArea: boolean =
          this.currentHeightState === ScrollWatchAreaEventType.outside ||
          this.currentHeightState === ScrollWatchAreaEventType.leave ||
          this.currentHeightState === ScrollWatchAreaEventType.prepare ||
          this.currentHeightState === ScrollWatchAreaEventType.prepareEnter;
        if (outsideAreaOrPrepareArea) {
          areaEvent.eventHeightType = ScrollWatchAreaEventType.enter;
        } else {
          areaEvent.eventHeightType = ScrollWatchAreaEventType.inside;
        }
      } else {
        let outsideAreaOrInsideArea: boolean =
          this.currentHeightState === ScrollWatchAreaEventType.outside ||
          this.currentHeightState === ScrollWatchAreaEventType.leave ||
          this.currentHeightState === ScrollWatchAreaEventType.enter ||
          this.currentHeightState === ScrollWatchAreaEventType.inside;
        if (outsideAreaOrInsideArea) {
          areaEvent.eventHeightType = ScrollWatchAreaEventType.prepareEnter;
        } else {
          areaEvent.eventHeightType = ScrollWatchAreaEventType.prepare;
        }
      }
    } else {
      //outside area
      let insideAreaOrPrepareArea: boolean =
        this.currentHeightState === ScrollWatchAreaEventType.inside ||
        this.currentHeightState === ScrollWatchAreaEventType.enter ||
        this.currentHeightState === ScrollWatchAreaEventType.prepareEnter ||
        this.currentHeightState === ScrollWatchAreaEventType.prepare;
      if (insideAreaOrPrepareArea) {
        areaEvent.eventHeightType = ScrollWatchAreaEventType.leave;
      } else {
        areaEvent.eventHeightType = ScrollWatchAreaEventType.outside;
      }
    }
  }

}
