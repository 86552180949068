/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../tree-node-expander/tree-node-expander.component.ngfactory";
import * as i2 from "../tree-node-expander/tree-node-expander.component";
import * as i3 from "../tree-node-content/tree-node-content.component.ngfactory";
import * as i4 from "../tree-node-content/tree-node-content.component";
import * as i5 from "../tree-node-children/tree-node-children.component.ngfactory";
import * as i6 from "../tree-node-children/tree-node-children.component";
import * as i7 from "@angular/common";
import * as i8 from "./tree-node.component";
var styles_TreeNodeComponent = [];
var RenderType_TreeNodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TreeNodeComponent, data: {} });
export { RenderType_TreeNodeComponent as RenderType_TreeNodeComponent };
function View_TreeNodeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "slx-tree-node-expander", [], null, null, null, i1.View_TreeNodeExpanderComponent_0, i1.RenderType_TreeNodeExpanderComponent)), i0.ɵdid(2, 49152, null, 0, i2.TreeNodeExpanderComponent, [], { node: [0, "node"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "slx-tree-node-content", [], null, null, null, i3.View_TreeNodeContentComponent_0, i3.RenderType_TreeNodeContentComponent)), i0.ɵdid(4, 49152, null, 0, i4.TreeNodeContentComponent, [], { node: [0, "node"], index: [1, "index"], template: [2, "template"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "slx-tree-node-children-component", [], null, null, null, i5.View_TreeNodeChildrenComponent_0, i5.RenderType_TreeNodeChildrenComponent)), i0.ɵdid(6, 49152, null, 0, i6.TreeNodeChildrenComponent, [], { node: [0, "node"], templates: [1, "templates"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.node; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.node; var currVal_2 = _co.index; var currVal_3 = _co.templates.treeNodeTemplate; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.node; var currVal_5 = _co.templates; _ck(_v, 6, 0, currVal_4, currVal_5); }, null); }
function View_TreeNodeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_TreeNodeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_TreeNodeComponent_3)), i0.ɵdid(1, 540672, null, 0, i7.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(2, { $implicit: 0, node: 1, index: 2, templates: 3 }), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.node, _co.node, _co.index, _co.templates); var currVal_1 = _co.templates.treeNodeFullTemplate; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TreeNodeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TreeNodeComponent_1)), i0.ɵdid(1, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TreeNodeComponent_2)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.templates.treeNodeFullTemplate; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.templates.treeNodeFullTemplate; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TreeNodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-tree-node-component", [], null, null, null, View_TreeNodeComponent_0, RenderType_TreeNodeComponent)), i0.ɵdid(1, 49152, null, 0, i8.TreeNodeComponent, [], null, null)], null, null); }
var TreeNodeComponentNgFactory = i0.ɵccf("slx-tree-node-component", i8.TreeNodeComponent, View_TreeNodeComponent_Host_0, { node: "node", index: "index", templates: "templates" }, {}, []);
export { TreeNodeComponentNgFactory as TreeNodeComponentNgFactory };
