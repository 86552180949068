import { Injectable } from '@angular/core';
import { Assert, StringUtils } from '../../../framework/index';
import * as _ from 'lodash';

@Injectable()
export class LoggerService {
  public error(message: string, details?: string|any): void {
    Assert.isNotNull(message, 'message');

    let detailsMessage: string = _.isString(details) ? <string>details : JSON.stringify(details || '');

    let logMessage: string = this.formatMessage(message, detailsMessage);
    console.error(logMessage);
  }

  public warning(message: string, details?: string): void {
    Assert.isNotNull(message, 'message');

    let logMessage: string = this.formatMessage(message, details);
    console.warn(logMessage);
  }

  public info(message: string, details?: string): void {
    Assert.isNotNull(message, 'message');

    let logMessage: string = this.formatMessage(message, details);
    console.info(logMessage);
  }

  private formatMessage(message: string, details?: string): string {
    Assert.isNotNull(message, 'message');

    let logMessage: string = StringUtils.isNullOrEmpty(details) ? message : `${message} - ${details}`;
    return logMessage;
  }
}
