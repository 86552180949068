import * as tslib_1 from "tslib";
import { BenefitDetailsState } from './benefit-details-state';
var BenefitDetailsOption = /** @class */ (function (_super) {
    tslib_1.__extends(BenefitDetailsOption, _super);
    function BenefitDetailsOption() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cost = 0.00;
        return _this;
    }
    Object.defineProperty(BenefitDetailsOption.prototype, "isDraft", {
        get: function () {
            return !isFinite(this.id) || this.id < 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsOption.prototype, "hasTypeAndCode", {
        get: function () {
            return typeof (this.type) === 'string'
                && this.type.length > 0
                && typeof (this.code) === 'string'
                && this.code.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsOption;
}(BenefitDetailsState));
export { BenefitDetailsOption };
