import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldsMeta } from '../../../../core/models/index';

export interface IEmployeeSectionsCertification {
  id:number;
  code: string;
  name: string
  description: string
  certDate: string;
  endDate: string;
  license: string;
  confirmingBody: string;
  licenseSequence: number;
}

export class EmployeeSectionsCertification {
  public id: number;
  public code: string;
  public name: string
  public description: string
  public certDate: string;
  public endDate: string;
  public license: string;
  public confirmingBody: string;
  public licenseSequence: number;

}

export interface IEmployeeSectionsCertifications {
  certificates: EmployeeSectionsCertification[];
}

export class EmployeeSectionsCertifications extends EmployeeSectionsBase{
  public certificates: EmployeeSectionsCertification[];
  public fieldsMeta: FieldsMeta;
}