import { DayTotal, IDayTotal } from './day-total';
import { Position, IPosition } from '../../../organization/index';

export class IScheduleSubtotal {
  public position: IPosition;
  public dayTotals: IDayTotal[];
}

export class ScheduleSubtotal {
  public position: Position;
  public dayTotals: StringMap<DayTotal>;
}

export class Subtotal {
  public type: string = 'total';
  public primaryPosition: string;
  public schedule: { [date: string]: number };
}
