import { Component, OnInit, Input, OnDestroy, forwardRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';

import { AbsenceModel } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
import { ListActionsService } from '../../../services/index';
import { ColorUtil } from '../../../../common/utils/index';

import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';
import { AbsencesConfigurationManagementService } from '../../../services/absences/absence-configuration-management.service';
import { unsubscribe } from '../../../../core/decorators/index';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-absence-editor',
  templateUrl: 'configure-absence-editor.component.html',
  styleUrls: ['configure-absence-editor.component.scss'],
  providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef(() => ConfigureAbsenceEditorComponent)
    }
  ]
})
export class ConfigureAbsenceEditorComponent extends EditableListEditorComponent implements OnInit {

  @Input()
  public item: AbsenceModel;

  @Input()
  public prohibitedNameValues: string[] = [];

  @Input()
  public rowIndex: number;

  @unsubscribe()
  private mainFormSubscription: Subscription;

  constructor(private management: AbsencesConfigurationManagementService) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.mainFormSubscription = this.formGroup.statusChanges.subscribe(() => {
      if (this.formGroup.dirty) {
        this.management.markAsDirty();
      }
    });
  }

  public onColorPickerChange(colorCode: string): void {
    this.formGroup.get('color').setValue(colorCode);
  }

  public getColor(): string {
    return this.formGroup ? (this.formGroup.get('color').value as string) : '#ffffff';
  }

  protected updateItem(): void {
    this.item.code = this.formGroup.get('code').value;
    this.item.description = this.formGroup.get('description').value;
    this.item.isPaid = this.formGroup.get('isPaid').value;
    this.item.ptoPlannerIndicator = this.formGroup.get('ptoPlannerIndicator').value;
    this.item.essPresent = this.formGroup.get('essPresent').value;
    this.item.color = ColorUtil.HexToDec(this.formGroup.get('color').value);
    this.item.loaIndicator = this.formGroup.get('loaIndicator').value;
  }


  protected createFormGroup(): FormGroup {
    return new FormGroup({
      code: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      isPaid: new FormControl(false),
      ptoPlannerIndicator: new FormControl('', Validators.required),
      essPresent: new FormControl(false),
      color: new FormControl('', Validators.required),
      loaIndicator: new FormControl('', Validators.required),
    });
  }

  protected updateFormGroup(): void {
    this.formGroup.get('code').setValue(this.item.code);
    this.formGroup.get('description').setValue(this.item.description);
    this.formGroup.get('isPaid').setValue(this.item.isPaid);
    this.formGroup.get('ptoPlannerIndicator').setValue(this.item.ptoPlannerIndicator);
    this.formGroup.get('essPresent').setValue(this.item.essPresent);
    this.formGroup.get('color').setValue(ColorUtil.DecToHexString(this.item.color, true));
    this.formGroup.get('loaIndicator').setValue(this.item.loaIndicator);
  }

}
