import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../../app.config';
import { PayCycle } from '../../../../organization/models/index';
import { StateResetTypes } from '../../../../core/models/index';
import { TimecardsAction, TimecardsActionCmd } from '../../../models/index';
import { IndividualTimecardsManagementService, TimecardsApiService, TimecardsStorageService } from '../../../services/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimecardsNavigationService, TimecardsSummaryNavigationService, ApprovalOvertimesNavigationService, StateManagementService, ComponentStateStorageService, ModalService } from '../../../../common/services/index';
import { TimecardsActions } from '../../../store/index';
import { AccrualBalanceDialogOptions } from '../../../../organization/models/index';
import { AccrualBalanceDialogComponent } from '../../../../organization/components/index';
import { MessagesService } from '../../../../components-library/services/index';
import { OrgLevelType } from '../../../../state-model/models/index';
var IndividualTimecardsComponent = /** @class */ (function () {
    function IndividualTimecardsComponent(managementService, timecardsActions, modalService, route, router, storageService, stateManagement, compStorageService, messagesService, timecardsApiService) {
        this.managementService = managementService;
        this.timecardsActions = timecardsActions;
        this.modalService = modalService;
        this.route = route;
        this.router = router;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.compStorageService = compStorageService;
        this.messagesService = messagesService;
        this.timecardsApiService = timecardsApiService;
        this.isEmployeeChanged = false;
        this.canSeePayroll = false;
        this.payCycleKey = 'payCycleKey';
        this.resetBy = StateResetTypes.SessionEnd;
        this.employeeIds = [];
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            isTableLoading: true
        };
        this.isShowPayRates = false;
        this.hideDetails = true;
        this.m_hideRates = false;
    }
    Object.defineProperty(IndividualTimecardsComponent.prototype, "hideRates", {
        get: function () {
            return this.m_hideRates;
        },
        set: function (value) {
            this.m_hideRates = value;
            if (!!this.m_hideRates) {
                this.setIsShowPayRate(false);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsComponent.prototype, "hasParentOrganizationId", {
        get: function () {
            return this.managementService.orgLevel &&
                !!this.managementService.orgLevel.parentId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsComponent.prototype, "isOrganizationOrgLevel", {
        get: function () {
            return this.managementService.orgLevel &&
                this.managementService.orgLevel.type === OrgLevelType.organization;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsComponent.prototype, "hasPrevEmployee", {
        get: function () {
            return this.managementService.hasPrevious();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualTimecardsComponent.prototype, "hasNextEmployee", {
        get: function () {
            return this.managementService.hasNext();
        },
        enumerable: true,
        configurable: true
    });
    IndividualTimecardsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('IndividualTimecardsComponent');
        this.routeSubscripion = this.route.params
            .combineLatest(this.route.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            // As we are using combineLatest method, this subscribe event gets fire twice when when we update both the params
            // We do not get the proper employeeId and StartDate,EndDate combination in the first time call
            // Do not remove this check,
            if (+queryParams.employeeId != +params.employeeId)
                return;
            _this.employeeId = +queryParams.employeeId || +params.employeeId;
            _this.orgLevelId = +queryParams.orgLevelId;
            var d1 = queryParams.startDate;
            var d2 = queryParams.endDate;
            if (!d1 || !d2) {
                _this.currentPayCycle = _this.restorePayCycle();
                _this.managementService.onEmployeeChange(_this.employeeId);
                return;
            }
            var payCycle = new PayCycle();
            payCycle.startDate = moment(d1, _this.appConfig.linkDateFormat).toDate();
            payCycle.endDate = moment(d2, _this.appConfig.linkDateFormat).toDate();
            _this.currentPayCycle = payCycle;
            _this.managementService.onQueryChange(_this.employeeId, _this.currentPayCycle);
            _this.storageService.setCurrentEmpId(_this.employeeId);
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.employeeLoadedSubscription = this.managementService.onEmployeeLoaded$
            .subscribe(function (employees) {
            if (employees.length > 0) {
                _this.employeeIds = employees.map(function (item) { return item.emp.employeePosition.employee.id; });
            }
        });
        this.onLoadedSubscription = this.managementService.onLoaded$
            .subscribe(function (container) {
            _this.messagesService.resetClosed();
            _this.container = container;
            _this.hasRulesDefinitions = _this.container.usedRulesDefinitions && _this.container.usedRulesDefinitions.length > 0;
            _this.hideRates = _this.container.hideRates;
            _this.currentPayCycle = container.payCycle;
            _this.hideCostCenterCode = _this.container.hideCostCenter;
            _this.state.isTableLoading = false;
            _this.activeEmployee = {
                id: _this.container.employee.id,
                name: _this.container.employee.name
            };
            _this.setPayrollVisibleFlag(container);
        });
        this.accrualsSubscription = this.managementService.accrualsLoaded$
            .subscribe(function (accruals) {
            var accrualEmployee = new AccrualBalanceDialogOptions(_this.container.employee.name, _this.container.primaryPosition.name, accruals.records);
            AccrualBalanceDialogComponent.openDialog(accrualEmployee, _this.modalService);
        });
        this.stateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.globalState = state;
            _this.isShowPayRates = !!_this.hideRates ? false : _this.globalState.isShowPayRates;
            _this.showHighPrecision = !!state.isShowHighPrecision;
        });
    };
    IndividualTimecardsComponent.prototype.setPayrollVisibleFlag = function (container) {
        if (container.isPayRollVisible &&
            container.employee.employeeType.name !== 'AFT' &&
            container.employee.employeeType.name !== 'APT' &&
            !container.employee.isAgencyEmployee) {
            this.canSeePayroll = true;
        }
    };
    IndividualTimecardsComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(IndividualTimecardsComponent.prototype, "employees", {
        get: function () {
            return this.managementService.getEmployees();
        },
        enumerable: true,
        configurable: true
    });
    IndividualTimecardsComponent.prototype.isColumnVisible = function (columnName) {
        var columnState = this.globalState.empColumns;
        var column = columnState.columnsMap[columnName];
        if (!this.globalState.isShowPayRates && column.payload && column.payload.payRateRelated) {
            return false;
        }
        if (column.name === 'costCenterCode' && this.hideCostCenterCode) {
            return false;
        }
        if (column.name === 'shiftCodeName' && (!this.container || !this.container.shiftDiffBasedOnTime)) {
            return false;
        }
        return true;
    };
    IndividualTimecardsComponent.prototype.onPayCycleSelected = function (payCycle) {
        if (this.state.isLoading || (this.currentPayCycle
            && moment(this.currentPayCycle.startDate).isSame(payCycle.startDate)
            && moment(this.currentPayCycle.endDate).isSame(payCycle.endDate))) {
            if (!this.isEmployeeChanged) {
                return;
            }
        }
        this.isEmployeeChanged = false;
        this.savePayCycle(payCycle);
        var navService = new TimecardsNavigationService(this.router, this.route);
        navService.NavigateToIndividualTimecards(this.employeeId, payCycle.startDate, payCycle.endDate);
    };
    IndividualTimecardsComponent.prototype.onVisibilityPayRatesChanged = function ($event) {
        this.setIsShowPayRate($event);
    };
    IndividualTimecardsComponent.prototype.onVisibilityHighPrecisionChanged = function ($event) {
        this.setIsShowHighPrecision($event);
    };
    IndividualTimecardsComponent.prototype.onExportToExcel = function () {
        var action = new TimecardsAction();
        action.cmd = TimecardsActionCmd.excelExport;
        this.managementService.onActionCmd(action);
    };
    IndividualTimecardsComponent.prototype.onExportToPdf = function () {
        var action = new TimecardsAction();
        action.cmd = TimecardsActionCmd.pdfExport;
        this.managementService.onActionCmd(action);
    };
    IndividualTimecardsComponent.prototype.onViewSummary = function () {
        var navService = new TimecardsSummaryNavigationService(this.router, this.route);
        navService.NavigateToIndividualTimecardSummary(this.employeeId, this.managementService.currentPayCycle.startDate, this.managementService.currentPayCycle.endDate);
    };
    IndividualTimecardsComponent.prototype.onShowAccruals = function () {
        this.managementService.loadAccruals(this.container.employee.id);
    };
    IndividualTimecardsComponent.prototype.onRecalculateTimecards = function () {
        this.managementService.recalculateTimecards();
    };
    IndividualTimecardsComponent.prototype.onApproveTimecards = function () {
        this.managementService.approveTimecards();
    };
    IndividualTimecardsComponent.prototype.onUnapproveTimecards = function () {
        this.managementService.unapproveTimecards();
    };
    IndividualTimecardsComponent.prototype.onNavByEmployee = function (isNext) {
        this.state.isTableLoading = true;
        var newEmployeeId = this.managementService.getEmployeeIdByIndex(isNext);
        if (_.isNumber(newEmployeeId) && newEmployeeId > -1) {
            this.container = undefined;
            this.navigateToIndividualTimecards(newEmployeeId);
        }
        else {
            this.state.isTableLoading = false;
        }
    };
    IndividualTimecardsComponent.prototype.onEmployeeChosen = function (employee) {
        if (employee && _.isNumber(employee.id) && employee.id > -1 &&
            employee.id !== this.employeeId) {
            this.state.isTableLoading = true;
            this.navigateToIndividualTimecards(employee.id);
        }
    };
    IndividualTimecardsComponent.prototype.navigateToIndividualTimecards = function (employeeId) {
        // We just need to change the emlployee.
        // A setter function in paycycle component is available which reloads the paycycle select the first cycle and reload the page.
        this.isEmployeeChanged = true;
        this.employeeId = employeeId;
    };
    IndividualTimecardsComponent.prototype.selectAll = function (event) {
        _.forEach(this.globalState.empColumns.columns, function (column) {
            column.displayed = true;
        });
        this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
    };
    IndividualTimecardsComponent.prototype.clearAll = function (event) {
        _.forEach(this.globalState.empColumns.columns, function (column) {
            column.displayed = false;
        });
        this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
    };
    IndividualTimecardsComponent.prototype.selectAllPayCodes = function (event) {
        _.forEach(this.globalState.payCodeColumns.columns, function (column) {
            column.displayed = true;
        });
        this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
    };
    IndividualTimecardsComponent.prototype.clearAllPayCodes = function (event) {
        _.forEach(this.globalState.payCodeColumns.columns, function (column) {
            column.displayed = false;
        });
        this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
    };
    IndividualTimecardsComponent.prototype.onChange = function (event, column) {
        this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
    };
    IndividualTimecardsComponent.prototype.onApproveOvertime = function () {
        var navService = new ApprovalOvertimesNavigationService(this.router, this.route);
        navService.navigateToOvertimeApproval(this.currentPayCycle.startDate, this.currentPayCycle.endDate, [this.container.employee.id]);
    };
    Object.defineProperty(IndividualTimecardsComponent.prototype, "isLocked", {
        get: function () {
            return this.container
                && (this.container.isOrganizationPayrollLocked
                    || this.container.isPayCycleLocked
                    || _.some(this.container.records, function (day) { return day.isLocked; }));
        },
        enumerable: true,
        configurable: true
    });
    IndividualTimecardsComponent.prototype.setIsShowPayRate = function (value) {
        this.isShowPayRates = !!this.hideRates ? false : value;
        if (!!this.globalState) {
            this.globalState.isShowPayRates = this.isShowPayRates;
        }
        this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
    };
    IndividualTimecardsComponent.prototype.setIsShowHighPrecision = function (value) {
        if (!!this.globalState) {
            this.globalState.isShowHighPrecision = value;
        }
        this.timecardsActions.changeIndividualTimecardsSettings(this.globalState);
    };
    IndividualTimecardsComponent.prototype.savePayCycle = function (payCycle) {
        var startDate = payCycle.startDate.toISOString();
        var endDate = payCycle.endDate.toISOString();
        var isApproved = payCycle.isApproved;
        this.compStorageService.setControlState(this.stateManagement.componentKey, this.payCycleKey, { value: { startDate: startDate, endDate: endDate, isApproved: isApproved } }, this.resetBy);
    };
    IndividualTimecardsComponent.prototype.restorePayCycle = function () {
        var state = this.compStorageService.getControlState(this.stateManagement.componentKey, this.payCycleKey);
        var value = _.get(state, 'value');
        if (_.isObject(value)) {
            var payCycle = new PayCycle();
            payCycle.startDate = new Date(value.startDate);
            payCycle.endDate = new Date(value.endDate);
            payCycle.isApproved = value.isApproved;
            return payCycle;
        }
        return null;
    };
    IndividualTimecardsComponent.prototype.activateNGPSSO = function () {
        this.timecardsApiService.navigateSSO('nextgenpayroll', this.orgLevelId);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsComponent.prototype, "employeeLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualTimecardsComponent.prototype, "accrualsSubscription", void 0);
    return IndividualTimecardsComponent;
}());
export { IndividualTimecardsComponent };
