import { Component, OnInit, OnDestroy, Input, Provider, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';

import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';

import {
  TimeclockAssignmentEmployee, TimeclockAssignmentState
} from '../../../models/index';

import { TimeclockAssignmentManagementService } from '../../../services/index';
@Component({
  moduleId: module.id,
  selector: 'slx-timeclock-assignment-grid',
  templateUrl: 'timeclock-assignment-grid.component.html',
  styleUrls: ['timeclock-assignment-grid.component.scss']
})
export class TimeclockAssignmentGridComponent implements OnInit, OnDestroy {

  public appConfig: IApplicationConfig;
  public isAllSelected: boolean;
  public records: TimeclockAssignmentEmployee[];
  public totalTimeclocks: number;
  public showAssigned: boolean;
  public showUnassigned: boolean;
  public gridState: KendoGridStateHelper<TimeclockAssignmentEmployee>;

  @ViewChild('kendoGrid', {static: true})
  private grid: GridComponent;
  @ViewChild('form', {static: true})
  private form: NgForm;
  private timeclockAssignmentManagementService: TimeclockAssignmentManagementService;


  @unsubscribe()
  private gridRefreshSubscription: Subscription;

  @unsubscribe()
  private stateChangedSubscription: Subscription;

  constructor(timeclockAssignmentManagementService: TimeclockAssignmentManagementService) {
    this.timeclockAssignmentManagementService = timeclockAssignmentManagementService;
    this.gridState = new KendoGridStateHelper<TimeclockAssignmentEmployee>();
    this.gridState.state.sort = [{ field: 'employee.name', dir: 'asc' }];
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.stateChangedSubscription = this.timeclockAssignmentManagementService.onStateChanged$.subscribe(
      (state: TimeclockAssignmentState) => {
        this.records = state.records;
        this.totalTimeclocks = state.timeclocks ? state.timeclocks.length : 0;
        this.showAssigned = state.showAssigned;
        this.showUnassigned = state.showUnassigned;
        this.resetState(state);
        this.refreshGrid();
      });

    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public onToggleAllSelected(): void {
    const records = process(this.records, { filter: this.gridState.state.filter }).data;
    _.forEach(records, (record: TimeclockAssignmentEmployee) => {
      record.isSelected = this.isAllSelected;
    });
    this.selectionChange();
  }

  public selectionChange(): void {
    let selectedRecords: TimeclockAssignmentEmployee[] = [];
    selectedRecords = _.filter(this.records, (record: TimeclockAssignmentEmployee) => {
      return record.isSelected === true;
    });
    this.timeclockAssignmentManagementService.onRecordsSelected(selectedRecords);
  }

  private resetState(state: TimeclockAssignmentState): void {
    if (_.size(this.records) > 0) {
      this.isAllSelected = false;
      _.forEach(this.records, (record: TimeclockAssignmentEmployee, index: number) => {
        record.isSelected = false;
        if (state.resetGrid) {
          this.grid.collapseRow(index);
        }
      });
    }
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

