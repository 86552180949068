import { ApprovalRequiredCodeRecord } from './../../models/approval-required-codes/approval-required-code-record.model';
import { approvalsConfig } from './../../approvals.config';

import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Meta } from '../../../../core/models/api/meta';
import { ApiUtilService } from '../../../../common/services/index';
import { ResponseBody } from '../../../../core/models/api/response-body';
import { FieldsMeta, Actions } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';
import { ApprovalRequiredCodesMapService } from './approval-required-codes-map.service';


@Injectable()
export class ApprovalRequiredCodesApiService {

    private apiUtilService: ApiUtilService;
    private mapService: ApprovalRequiredCodesMapService;

    constructor(apiUtilService: ApiUtilService, mapService: ApprovalRequiredCodesMapService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
    }

    public approve(records: ApprovalRequiredCodeRecord[]): Promise<any> {
        return this.changeState(approvalsConfig.api.ta.approveRequiredCodes, records);
    }

    public unapprove(records: ApprovalRequiredCodeRecord[]): Promise<any> {
        return this.changeState(approvalsConfig.api.ta.unapproveRequiredCodes, records);
    }

    private changeState(oper: string, records: ApprovalRequiredCodeRecord[]): Promise<any> {
        const url: string = `${this.getApiRoot()}/${oper}`;

        let request: HttpRequest<any> = new HttpRequest('POST', url, this.mapService.toARCRecordsDto(records));
        return this.apiUtilService.request<any[], Meta>(request);
    }

    private getApiRoot(): string {
        return `${this.apiUtilService.getApiRoot()}/${approvalsConfig.api.ta.root}`;
    }
}
