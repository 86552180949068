<form #establishmentSize="ngForm">
    <div class="modal-body">
        <div class="row">
            <label for="establishmentSize" class="col-xs-6">Number of Employees</label>
            <div class="col-xs-6">
                <slx-input-decorator>
                    <slx-kendo-number slx-input 
                        [(ngModel)]="initEstablishmentSize.establishmentSize"
                        (ngModelChange)="updateValue($event)" 
                        [max]="1999999999" 
                        [min]="0" class="form-control"  
                        [required]="'true'"
                        name="establishmentSize">
                    </slx-kendo-number>
                    <span errorMessage for="required">size is required</span>
                    <span errorMessage for="min">should be greater than zero</span>
                    <span errorMessage for="max">Should not exceed 1,999,999,999</span>
                </slx-input-decorator>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 comment">
                Please enter another value if you disagree.
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <p class="flex justify-content__flex-end modal-buttons">
            <button type="button" (click)="create()" class="theme-button-apply  margin-r" [disabled]="!establishmentSize.valid">Ok</button>
            <button type="button" class="theme-button-cancel" (click)="cancel()">Cancel</button>
        </p>
    </div>
</form>