<div class="slx-timepicker">
  <label *ngIf="hasPlaceholder" class="slx-timepicker__label">{{ placeholder }}:</label>
  <kendo-timepicker class="slx-timepicker__kendo {{ cssClass }}"
    name="dateInput"
    [placeholder]="hasPlaceholder ? placeholder : ''"
    [format]="format"
    [disabled]="readonly"
    [value]="value"
    [min]="minTime"
    [max]="maxTime"
    (blur)="onBlur()"
    (valueChange)="onChangeValue($event)"
  ></kendo-timepicker>
</div>
