<div class="flex">
<slx-checkbox-toggle className="toggle-left" [(ngModel)]="parameter.defaultValue" (ngModelChange)= "handleSelectedRNHrs($event)"
    [caption]="parameter.displayName" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>

<span class="w-12" [ngClass]="parameter.name=='Total_hrs' ? 'inputStyle' :'consecInputStyle' ">
<kendo-numerictextbox type="number" step="0.01" [disabled]="isDisabled" [format]="format" [decimals]="decimals"
[(ngModel)]="parameter.value" min="0.01" [max] ="max" (ngModelChange)="handleInputVal($event)" (input)="checkEnteredRange($event.target.value)">
</kendo-numerictextbox>
</span>
</div> 
<span class="error" *ngIf="isRangeCorrect">Please enter a value between 0.01 and {{max}}</span>
