<div [attr.class]="className">
    <input type="radio" [ngClass]="{'checked': valueField? option[valueField]===value[valueField] : option===value}"
           [disabled]="readonly? true: null"
           [(ngModel)]="value"
           [checked]="valueField? option[valueField]===value[valueField] : option===value"
           [value]="option"
           [attr.id]="fieldName" name="fieldName" >
    <label [attr.for]="fieldName">
      <span class="check"></span>
      <span class="box"></span>{{caption}}</label>
</div>
