import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeManagementService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { UserProfileNavigationService } from '../../../../common/services/index';
import { UserProfileModel, UserRoleRelationModel } from '../../../models/index';
import { UserProfileManagementService } from '../../../services/index';
import { UserProfileSectionProfileComponent } from '../user-profile-section-profile/user-profile-section-profile.component';
import { UserProfileSectionRolesComponent } from '../user-profile-section-roles/user-profile-section-roles.component';
import { UserProfileSectionType } from '../../../models/users/models/user-profile-section-type';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(changeService, management, activatedRoute, router, location) {
        this.changeService = changeService;
        this.management = management;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.location = location;
        this.m_userId = null;
        this.state = {
            isLoading: false,
            infoLocked: false,
            rolesLocked: false,
            hasEditingSections: false
        };
        this.subscribeRouter();
    }
    UserProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
        });
        this.profileLoadedSubscription = this.management.onProfileLoaded$.subscribe(function (userProfile) {
            _this.userProfile = userProfile;
            _this.state.isLoading = false;
        });
        this.userCreatedSubscription = this.management.onUserCreated$.subscribe(function (userProfile) {
            _this.changeService.clearChanges();
            var navigation = new UserProfileNavigationService(_this.router, _this.activatedRoute, _this.location);
            navigation.navigateToUserProfile(userProfile.id);
            _this.state.isLoading = false;
        });
        this.userStateSubscription = this.management.onStatusChanged$.subscribe(function (result) {
            _this.state.isLoading = false;
        });
        this.addRolesModeSubscription = this.management.onAddRoleModeSwitch$.subscribe(function (state) {
            _this.addRolesMode = state;
        });
        this.sectionErrorSubscription = this.management.onSectionServiceError$.subscribe(function (type) {
            switch (type) {
                case UserProfileSectionType.PROFILE:
                    _this.state.infoLocked = true;
                    break;
                case UserProfileSectionType.ROLES:
                    _this.state.rolesLocked = true;
                    break;
                case UserProfileSectionType.ALL:
                    _this.state.isLoading = false;
                    break;
            }
        });
        this.onSectionEnteredEditState = this.management.onSectionEnteredEditState$.subscribe(function (section) {
            _this.state.hasEditingSections = true;
        });
        this.sectionDiscardedSubscription = this.management.onSectionDiscarded$.subscribe(function (section) {
            _this.state.hasEditingSections = false;
        });
        this.onSectionSaveSubscription = this.management.onSectionSaved$.subscribe(function () {
            _this.state.hasEditingSections = false;
            if (_this.m_userId) {
                _this.management.getUserRoles(_this.m_userId);
            }
        });
    };
    UserProfileComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    UserProfileComponent.prototype.activateUser = function () {
        this.state.isLoading = true;
        this.management.activateUser();
    };
    UserProfileComponent.prototype.disableUser = function () {
        this.state.isLoading = true;
        this.management.disableUser();
    };
    UserProfileComponent.prototype.onAddUser = function () {
        this.state.isLoading = true;
        var profile = this.profileSection.getSaveData();
        var rolesSectionSaveData = this.rolesSection.getSaveData();
        var roles = rolesSectionSaveData.tempRoles;
        this.management.createNewUserOnServer(profile, roles);
    };
    UserProfileComponent.prototype.profileValidChange = function (valid) {
        this.profileSectionValid = valid;
    };
    UserProfileComponent.prototype.subscribeRouter = function () {
        var _this = this;
        this.routeSubscripion = this.activatedRoute.params.combineLatest(this.activatedRoute.queryParams).subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.management.changeService = _this.changeService;
            if (_.has(params, 'userId')) {
                _this.m_userId = params['userId'];
                _this.addMode = false;
                _this.changeService.setCurrentComponentId('user_profile');
                _this.management.getUserProfile(_this.m_userId);
                _this.management.getUserRoles(_this.m_userId);
            }
            else {
                _this.addMode = true;
                _this.changeService.setCurrentComponentId('user_create');
                _this.management.createNewUser();
            }
            _this.state.isLoading = true;
            _this.management.loadRoles();
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], UserProfileComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "sectionErrorSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "userCreatedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "userStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "routeSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "addRolesModeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "profileLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "onSectionSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "sectionDiscardedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserProfileComponent.prototype, "onSectionEnteredEditState", void 0);
    return UserProfileComponent;
}());
export { UserProfileComponent };
