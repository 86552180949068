export class EmployeeToRehire {
    public employeeId: number;
    public departmentId: number;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public terminationDate: Date;
    public mobilePhone: string;
    public orgLevelId: number;
    public payRateLimit: number;
    public fullName: string;   
    public dialogHeight: number; 
    public employeeType: string;
    public organizationId: number;
    public payrollNumber: string;
   }
