import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsSchedule = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsSchedule, _super);
    function EmployeeSectionsSchedule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsSchedule;
}(EmployeeSectionsBase));
export { EmployeeSectionsSchedule };
