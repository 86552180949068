import { Component, Output, Inject, EventEmitter, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ColumnManagementService } from './../../../../common/services/column-settings/column-management.service';
import { Employee, SmsResponse, EmployeeActionDefinition } from '../../models/index';
import { GroupActivitiesApiService } from '../../services/index';
import { employeeListConfig } from '../../employee-list.config';
import * as _ from 'lodash';
import { OrgLevel } from '../../../../state-model/models/index';
import { EMPLOYEES_ID_TOKEN, ISACTIVEONLY_ID_TOKEN } from '../../../../core/models/index';
import { ModalService } from '../../../../common/services/index';
@Component({
  moduleId: module.id,
  selector: 'slx-assign-pay-policy',
  templateUrl: 'assign-pay-policy.component.html',
  styleUrls: ['assign-pay-policy.component.scss'],
  providers: [ColumnManagementService]
})
export class AssignPayPolicyComponent implements OnInit {
  @Output()
  public onCancel: EventEmitter<any>;

  @Output()
  public onComplete: EventEmitter<string>;

  public actionEmployees: Employee[];

  public employees: Employee[];
  public fieldDefinition: EmployeeActionDefinition;
  public orgLevel: OrgLevel;


  public state: {
    isLoading: boolean;
  };
  public effectiveDate: Date;

  public payPolicyEnabled: boolean;
  public shiftDiffPolicyEnabled: boolean;

  public payPolicy: any;
  public shiftDiffPolicy: any;

  public canSend: boolean;
  public anyPolicySelected: boolean;

  private selectedEmployees: Employee[];
  private modalService: ModalService;
  private groupActivitiesService: GroupActivitiesApiService;

  constructor(groupActivitiesService: GroupActivitiesApiService, @Inject(EMPLOYEES_ID_TOKEN) employees: Employee[], fieldDefinition: EmployeeActionDefinition,
              orgLevel: OrgLevel, modalService: ModalService) {
    this.groupActivitiesService = groupActivitiesService;
    this.employees = employees;
    this.fieldDefinition = fieldDefinition;
    this.orgLevel = orgLevel;
    this.modalService = modalService;
    this.onCancel = new EventEmitter<any>();
    this.onComplete = new EventEmitter<string>();

    this.state = {
      isLoading: false,
    };

    this.effectiveDate = null;
  }

  public ngOnInit(): void {
    this.state.isLoading = true;
    let employeeIds: string[] = _.map(this.employees, (employee: Employee) => employee[employeeListConfig.employeeIdentifierName]);
    this.groupActivitiesService.getActionEmployeeList(this.fieldDefinition.id, this.orgLevel.id, true, employeeIds, this.fieldDefinition)
      .then((actionEmployees: Employee[]) => {
        this.actionEmployees = actionEmployees;
      });
    this.state.isLoading = false;
  }

  public updateAnyFlag(): void {
    this.anyPolicySelected = this.payPolicyEnabled || this.shiftDiffPolicyEnabled;
  }

  public cancel(): void {
    this.onCancel.emit();
  }

  public employeesSelect(selectedEmployees: Employee[]): void {
    this.selectedEmployees = selectedEmployees;
    this.canSend = this.selectedEmployees && this.selectedEmployees.length > 0;
  }

  public assignPolicy(): void {
    this.state.isLoading = true;
    this.groupActivitiesService.assignPolicies(this.selectedEmployees, this.payPolicy, this.shiftDiffPolicy, null, this.effectiveDate)
      .then((response: any) => {
        this.state.isLoading = false;
        this.afterSave();
        this.modalService.globalAnchor.openInfoDialog('Information', 'Policies assigned!',
        (result: boolean) => {
            this.cancel();
        });
      })
      .catch((reason: any) => {
        this.state.isLoading = false;
      });
  }

  private afterSave(): void {
    _.forEach(this.selectedEmployees, (emp: any) => {emp['isSelected'] = false; });
    this.selectedEmployees = null;
    this.canSend = false;
  }
}
