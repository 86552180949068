import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateRange, IDateRange, IColumnSettings } from '../../../../../core/models/index';
import { DeviceDetectorService, ModalService } from '../../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';
import { TempSensorReadingManagementService } from '../../../../../configuration/services';
import { TempSensorReadingEvent, TempSensorReadingEventCmd } from '../../../../../configuration/models';
import { unsubscribeAll } from '../../../../..//core/decorators';
import { UserAction } from '../../../../..//organization';

@Component({
  selector: 'slx-temp-readings-toolbar',
  templateUrl: './temp-readings-toolbar.component.html',
  styleUrls: ['./temp-readings-toolbar.component.scss']
})
export class TempReadingsToolbarComponent implements OnInit, OnDestroy {

  public dateRange: DateRange = new DateRange(null, null);
  private dayInSec: number = 60 * 60 * 24;
  public maxSecondsRange: number = 90 * this.dayInSec;
  public maxRangeError: string = 'The dates range cannot be more than 90 days';

  public canExportData = false;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get dateRangeWidth(): number {
    return this.isDesktop ? 325 : 246;
  }

  public readonly columnsStateName: string = 'TempReadings';

  constructor(
    private managementService: TempSensorReadingManagementService,
    private columnManagementService: ColumnManagementService,
    private stateManagement: StateManagementService,
    private devDetector: DeviceDetectorService,
  ) { }

  public ngOnInit(): void {
    this.initServices();
    this.defineDateRange();
  }


  public ngOnDestroy(): void { }

  public onChangeDates(range: IDateRange): void {
    this.managementService.changeDateRange(range);
  }

  private initServices(): void {
    this.stateManagement.init('TempReadingsComponent');
    this.columnManagementService.init('TempReadingsComponent');
    this.columnManagementService.initGroup(this.columnsStateName, 7);

    this.managementService.subscribeToUserActionsChanged((actions: UserAction[]) => {
      this.canExportData = _.some(actions, x => x.name === 'Export To Excel');
    });
  }

  public onExportToExcel(): void {
    let action: TempSensorReadingEvent = new TempSensorReadingEvent();
    action.cmd = TempSensorReadingEventCmd.excelExport;
    this.managementService.exportTo(action);
  }

  public onExportToPdf(): void {
    let action: TempSensorReadingEvent = new TempSensorReadingEvent();
    action.cmd = TempSensorReadingEventCmd.pdfExport;
    this.managementService.exportTo(action);
  }

  public defineDateRange(): void {
    this.dateRange = this.managementService.getDefaultDateRange();
    this.onChangeDates(this.dateRange);
  }
}