import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Subscription } from 'rxjs/Subscription';

import { ColumnSettingsStorageService, ColumnManagementService, StateManagementService, ComponentStateStorageService } from '../../../../common/services/index';

import { LMRosterManagementService, LMManagementService, LMRequestMapperService } from '../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-lm-roster',
  templateUrl: 'lm-roster.component.html',
  styleUrls: ['lm-roster.component.scss'],
  providers: [LMRosterManagementService, LMRequestMapperService, ColumnSettingsStorageService,
     ColumnManagementService, StateManagementService, ComponentStateStorageService]
})
export class LMRosterComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public isListView: boolean = true;
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manRosterService: LMRosterManagementService,
    private managementService: LMManagementService,
    private activatedRoute: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    this.managementService.init();
    this.manRosterService.init('LMRosterComponent');
    this.subscribeToData();
  }

  public ngOnDestroy(): void {
    this.manRosterService.destroy();
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  private subscribeToData(): void {
    this.subscriptions.routeData = this.activatedRoute.data
      .subscribe((r: any) => this.manRosterService.changeRouteData(r));

    this.subscriptions.loading = this.manRosterService
      .subscribeToLoading((isLoading: boolean) => (this.isLoading = isLoading));

    this.subscriptions.view = this.manRosterService
      .subscribeToViewMode((isListView: boolean) => (this.isListView = isListView));
  }
}
