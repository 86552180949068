<div class="height-100 chart-container">
    <div class="height-100 chart-item">
        <kendo-chart class="height-100"  [seriesColors]="seriesColors" (seriesClick)="handleClick($event)">
            <kendo-chart-area background="#fff" [margin]="-10" >
                </kendo-chart-area>
            <kendo-chart-axis-defaults [majorGridLines]="{ visible : false }"></kendo-chart-axis-defaults>

            <kendo-chart-value-axis>
                <kendo-chart-value-axis-item [line]="{ visible: false }"
                    [min]="0" [max]="maxValue" labels="false">
                </kendo-chart-value-axis-item>
            </kendo-chart-value-axis>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [line]="{ visible: false }"
                    [min]="0" labels="false">
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>

            <kendo-chart-series>
                <kendo-chart-series-item type="bar" [gap]="0" stack="{ type: '100%' }" [data]="[displayValue1]">
                </kendo-chart-series-item>
                <kendo-chart-series-item type="bar" [data]="[displayValue2]">
                </kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
    </div>
    <div class="chart-item text-items" [ngClass]="{'right-items': hideValues}" (click)="handleClick($event)">
        <span class="values-text" *ngIf="!hideValues">  <!-- [ngStyle]="{'color': fontColor2}" -->
            <span class="first-text"  >{{originalValue1 | number : '1.0-2'}}</span> / {{originalValue2 | number : '1.0-2'}}
            <!-- [ngStyle]="{'color': fontColor1, 'font-weight': bold }" -->
        </span>
        <span class="label-text" *ngIf="!hideLabel && !isFull">{{labelValue | number : '1.0-2' | slxPlus }}</span>
        <i class="far fa-check-circle fa-2x full-check" aria-hidden="true" *ngIf="!hideLabel && isFull"></i>
    </div>
    <div class="out-label" [ngStyle]="{'color': labelColor}" *ngIf="outLabel">
        <span class="out-label-text" *ngIf="!isFull">{{labelValue | number : '1.0-2' | slxPlus }}</span>
        <i class="fa fa-check-circle out-label-text" aria-hidden="true" *ngIf="isFull"></i>
    </div>

</div>
