import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ManagementBaseService } from '../../../core/services/index';
import { InfoDialogComponent } from '../../../common/components/index';
import { ModalService } from '../../../common/services/index';
import { OrgLevel } from '../../../state-model/models/index';

import { IPunchAttestationQuestion, PunchAttestationGroup } from '../models/index';
import { PunchAttestationApiService } from './punch-attestation-api.service';
import { PunchAttestationGroupPermissions } from '../models/punch-attestation-group-permissions';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PunchAttestationManagementService extends ManagementBaseService<PunchAttestationGroup[], any> {

  public orgLevel: OrgLevel;
  public groups: PunchAttestationGroup[];
  public legacyclockquestiongroup : string = 'Legacy clock question group';
  isSlate$ = new BehaviorSubject<boolean>(false);
  isLegacy$ = new BehaviorSubject<boolean>(false);
  configurePunchAttestation = new BehaviorSubject<boolean>(false);
  configurePunchProfile = new BehaviorSubject<boolean>(false);
  scrollBottom$ = new BehaviorSubject<boolean>(false);
  
  constructor(
    private apiService: PunchAttestationApiService,
    private modalService: ModalService,
  ) {
    super();
  }


  public loadgroups(orgLevel: OrgLevel): void {
    this.orgLevel = orgLevel;
    this.onLoadStatusChanged(true);
    this.apiService.getAllGroups()
      .then(groups => {
        this.groups = groups;
        this.legacyClockOrder();
        this.onLoaded(this.groups);
        this.onLoadStatusChanged(false);
      })
      .catch(err => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public addLegacyGroup(){
    const group : PunchAttestationGroup = {
      groupName : this.legacyclockquestiongroup,
      id :0,
      organizationId : this.orgLevel.id,
      questions : []
    };
    this.groups.splice(0,0,group);
    this.onLoaded(this.groups);
  }

  public addGroups(){
    const group : PunchAttestationGroup = {
      groupName : '',
      id :0,
      organizationId : this.orgLevel.id,
      questions : []
    }
    this.groups.push(group);
    this.onLoaded(this.groups);
    this.scrollBottom$.next(true);
  }

  public deleteQuestion(orgLevel: OrgLevel,group : PunchAttestationGroup,question : IPunchAttestationQuestion){ 
    this.orgLevel = orgLevel;
    this.onLoadStatusChanged(true);
    this.apiService.deleteGroupQuestion(this.orgLevel.id,group.id,question.id)
      .then((groups :PunchAttestationGroup[])=> {
        this.groups = groups;
        this.legacyClockOrder();
        this.onLoaded(this.groups);
        this.onLoadStatusChanged(false);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }


  public saveGroup(orgLevel: OrgLevel, group: PunchAttestationGroup): void {
    this.orgLevel = orgLevel;
    this.onLoadStatusChanged(true);
    this.apiService.updateGroup(this.orgLevel.id, group)
      .then(group => {
        this.groups = group;
        this.legacyClockOrder();
        this.onLoaded(this.groups);
        this.onLoadStatusChanged(false);
        this.scrollBottom$.next(false);
      })
      .catch((err: any) => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public deleteGroup(orgLevel: OrgLevel, group: PunchAttestationGroup): void {
    if(group.id === null || group.id ===0){
      this.groups = this.groups.filter(x=> x.id !== group.id);
      this.onLoaded(this.groups);
      return;
    }

    this.orgLevel = orgLevel;
    this.onLoadStatusChanged(true);
    this.apiService.deleteGroup(this.orgLevel.id, group.id)
      .then(response => {
        if (response.isSuccess) {
          _.remove(this.groups, p => group.id === p.id);
          this.onLoaded(this.groups);
          this.onLoadStatusChanged(false);
        } else {
          this.onLoadStatusChanged(false);
          InfoDialogComponent.OpenDialog('Cannot delete Question Group', response.message, this.modalService);
        }
      })
      .catch(err => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public loadGoGroups(orgLevel: OrgLevel): void {
    this.orgLevel = orgLevel;
    this.onLoadStatusChanged(true);
    this.apiService.getAllGroups()
      .then(groups => {
        this.groups = groups;
        this.onLoaded(this.groups);
        this.onLoadStatusChanged(false);
      })
      .catch(err => {
        this.onError(err);
        this.onLoadStatusChanged(false);
      });
  }

  public areGroupNameValid(group: PunchAttestationGroup): boolean {
    return this.groups && group ? this.groups.filter(e=> 
      e.id !== group.id && e.groupName.toLocaleLowerCase() === group.groupName.toLocaleLowerCase()
    ).length === 0 : true;
  }

  public get isLegacyGroupExists(){
    return this.groups ?  this.groups.find(x=> x.groupName.toLocaleLowerCase() === this.legacyclockquestiongroup.toLocaleLowerCase())!== undefined : false;
  }

  public get isGroupNameValid(){
    return this.groups ?  this.groups.find(x=> x.id === 0 || x.groupName === null || x.groupName === '') === undefined : true;
  }

  public getPermissions(orgLevelId: number){
    this.apiService.getPermissions(orgLevelId).then(result =>{
      let punchAttestationGroupPermissions: PunchAttestationGroupPermissions = result;
      this.isLegacy$.next(punchAttestationGroupPermissions.isLegacy);
      this.isSlate$.next(punchAttestationGroupPermissions.isSlate);
      this.configurePunchAttestation.next(punchAttestationGroupPermissions.configurePunchAttestation);
      this.configurePunchProfile.next(punchAttestationGroupPermissions.configurePunchProfile)
    });
  }

  private legacyClockOrder() 
  {
    let localGroups : any = [];
    if(this.groups.length > 0){
      if(this.isLegacy$.getValue()){
        localGroups = localGroups.concat(this.groups.filter(x=> x.groupName.toLocaleLowerCase() === this.legacyclockquestiongroup.toLocaleLowerCase()));
      }
      localGroups = localGroups.concat(this.groups.filter(x=> x.groupName.toLocaleLowerCase() !== this.legacyclockquestiongroup.toLocaleLowerCase()));
      this.groups = localGroups; 
    }

  }
}
