import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { EmployeeSectionBridgeService } from './../../../services/employee-section-bridge.service';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from './../../../../../core/decorators/unsubscribe-decorator';
import { KendoGridStateHelper } from './../../../../../common/models/kendo-grid-helpers/kendo-grid-state-helper';
import { ConfirmDialogComponent } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { OnInit, OnDestroy, NgZone, Provider, ChangeDetectorRef } from '@angular/core';
import { Assert } from '../../../../../framework/index';
import { MetaMapService } from '../../../../../core/services/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsAuditTrail, EmployeeSectionsAuditTrailNoteRecord, } from '../../../models/index';
import { EmployeeSectionsAuditApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { process } from '@progress/kendo-data-query';
import { EmployeeSectionsNoteEditComponent } from '../employee-sections-note-edit/employee-sections-note-edit.component';
import { DialogOptions } from '../../../../../common/models/dialog-options';
import { AppSettingsManageService } from '../../../../../app-settings/services/index';
var EmployeeSectionsAuditTrailComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsAuditTrailComponent, _super);
    function EmployeeSectionsAuditTrailComponent(employeeSectionsAuditApiService, appSettingsManageService, metaMapService, modalService, decorator, ngZone, changeDetector, bridgeService) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.appSettingsManageService = appSettingsManageService;
        _this.bridgeService = bridgeService;
        _this.pageSize = 10;
        _this.sort = [];
        _this.employeeSectionsAuditApiService = employeeSectionsAuditApiService;
        _this.metaMapService = metaMapService;
        _this.modalService = modalService;
        _this.changeDetector = changeDetector;
        _this.gridState = new KendoGridStateHelper();
        _this.gridState.state.take = _this.pageSize;
        _this.gridState.state.skip = 0;
        _this.gridState.state.sort = [{ field: 'changedOn', dir: 'desc' }];
        _this.gridRefreshSubscription = _this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        return _this;
    }
    Object.defineProperty(EmployeeSectionsAuditTrailComponent.prototype, "auditTrail", {
        set: function (employeeSectionsAuditTrail) {
            this.employeeSectionsAuditTrail = employeeSectionsAuditTrail;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAuditTrailComponent.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsAuditTrailComponent.prototype, "form", {
        get: function () {
            return null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsAuditTrailComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadSubsection();
        this.getAppSettings();
    };
    EmployeeSectionsAuditTrailComponent.prototype.getAppSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, e_1;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        _a.appSettings = _b.sent();
                        console.log(this.appSettings.isNotesEnabled);
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _b.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsAuditTrail;
    };
    EmployeeSectionsAuditTrailComponent.prototype.checkCanDelete = function (record) {
        if (!this.employeeSectionsAuditTrail) {
            return false;
        }
        if (!record) {
            return false;
        }
        if (!this.employeeSectionsAuditTrail.actions.canDelete) {
            return false;
        }
        return (record instanceof EmployeeSectionsAuditTrailNoteRecord);
    };
    EmployeeSectionsAuditTrailComponent.prototype.checkCanEdit = function (record) {
        if (!this.employeeSectionsAuditTrail) {
            return false;
        }
        if (!record) {
            return false;
        }
        if (!this.employeeSectionsAuditTrail.actions.canEdit) {
            return false;
        }
        return (record instanceof EmployeeSectionsAuditTrailNoteRecord);
    };
    EmployeeSectionsAuditTrailComponent.prototype.onStartAdd = function () {
        var _this = this;
        var note = new EmployeeSectionsAuditTrailNoteRecord();
        note.subject = this.metaMapService.emptyFieldMeta(null, 'subject');
        note.detail = this.metaMapService.emptyFieldMeta(null, 'detail');
        note.isPublic = false;
        var options = new DialogOptions();
        options.height = 350;
        options.width = 600;
        var resolvedProvidersConf = [
            { provide: DialogOptions, useValue: options },
            { provide: EmployeeSectionsAuditTrailNoteRecord, useValue: note },
        ];
        this.bridgeService.sectionsAreVisible = false;
        this.modalService.globalAnchor.openDialog(EmployeeSectionsNoteEditComponent, 'New note', options, resolvedProvidersConf, function (result) {
            if (result) {
                _this.doAdd(note);
            }
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.onStartModify = function (note) {
        var _this = this;
        Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
        var options = new DialogOptions();
        options.height = 350;
        options.width = 600;
        var resolvedProvidersConf = [
            { provide: DialogOptions, useValue: options },
            { provide: EmployeeSectionsAuditTrailNoteRecord, useValue: note },
        ];
        this.modalService.globalAnchor.openDialog(EmployeeSectionsNoteEditComponent, 'Edit note', options, resolvedProvidersConf, function (result) {
            if (result) {
                _this.doModify(note);
            }
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.onStartRemove = function (note) {
        var _this = this;
        Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure?', this.modalService, function (result) {
            if (result) {
                _this.doRemove(note);
            }
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.doAdd = function (note) {
        var _this = this;
        Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
        this.startProgress();
        this.employeeSectionsAuditApiService.addAuditTrailNote(this.employeeId, note)
            .then(function (status) {
            _this.loadSubsection(function () { return (_this.bridgeService.sectionsAreVisible = true); });
        }).catch(function (error) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.doModify = function (note) {
        var _this = this;
        Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
        this.startProgress();
        this.employeeSectionsAuditApiService.editAuditTrailNote(this.employeeId, note)
            .then(function (status) {
            _this.loadSubsection();
        })
            .catch(function (error) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.doRemove = function (note) {
        var _this = this;
        Assert.isNotNull(note, 'EmployeeSectionsAuditTrailNoteRecord');
        this.startProgress();
        this.employeeSectionsAuditApiService.deleteAuditTrailNote(this.employeeId, note.id)
            .then(function (status) {
            _this.loadSubsection();
        }).catch(function (error) {
            _this.loadSubsection();
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.loadSubsection = function (callback) {
        var _this = this;
        if (!this.employeeSectionsAuditTrail) {
            return;
        }
        this.startProgress();
        this.employeeSectionsAuditApiService.getAuditTrail(this.employeeId)
            .then(function (employeeSectionsAuditTrail) {
            _this.employeeSectionsAuditTrail = employeeSectionsAuditTrail;
            _this.refreshGrid();
            _this.stopProgress();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
            if (_.isFunction(callback))
                callback();
        })
            .catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsAuditTrailComponent.prototype.refreshGrid = function () {
        if (!this.employeeSectionsAuditTrail) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.employeeSectionsAuditTrail.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], EmployeeSectionsAuditTrailComponent.prototype, "gridRefreshSubscription", void 0);
    return EmployeeSectionsAuditTrailComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsAuditTrailComponent };
