export interface IWcCodeDefinitionDto {
    id: number;
    code: string;
    codeType: string;
    description: string;
}

export class WcCodeDefinition {
    public id: number;
    public code: string;
    public codeType: string;
    public description: string;
}