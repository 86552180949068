import { Component } from '@angular/core';
import { NotificationsService } from '../../../../app/core/components';
import { BusyService } from '../../../common';
import { WfmSyncService } from '../../../configuration/services/wfm/wfm-sync.service';
import { dataCheck_emp, WFMOrgLevelChangeLogModel } from './../../models/wfm-sync';
import { BaseSyncComponent } from './wfm-sync-base.component';

@Component({
  template: ''
})
export abstract class BaseOrgDeptPosSyncComponent extends BaseSyncComponent<WFMOrgLevelChangeLogModel> {
  constructor(
    protected wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  protected syncDataCore(selectedItems: WFMOrgLevelChangeLogModel[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.SyncOrganizationLevelsChangeLogs(selectedItems, 'migration')
    );
  }

  protected getSyncItemKey(item: WFMOrgLevelChangeLogModel): string {
    return '' + item.jobCode;
  }

  protected async fetchData(): Promise<WFMOrgLevelChangeLogModel[]> {
    const response = await this.busyService.busy(this.fetchDataCore());

    return this.processData(response.data);
  }

  private processData(data: WFMOrgLevelChangeLogModel[]): WFMOrgLevelChangeLogModel[] {
    return dataCheck_emp(data);
  }

  protected abstract fetchDataCore(): Promise<{ data: WFMOrgLevelChangeLogModel[] }>;
}
