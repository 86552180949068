import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable } from '@angular/core';
import { LookupMapService } from '../../../../organization/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';

import {
  IPBJRecOrgEntry,
  PBJRecOrgEntry,
  IPBJRecDepEntry,
  PBJRecDepEntry,
  IPBJRecDailyEntry,
  PBJRecDailyEntry,
  IPBJRecEmpEntry,
  PBJRecEmpEntry,
  IPBJRecEntry,
  PBJRecEntry
} from '../../models/index';

@Injectable()
export class PbjReconciliationMapService {
  constructor(private lookupMapService: LookupMapService) {
  }

  public mapToOrgEntries(entries: IPBJRecOrgEntry[]): PBJRecOrgEntry[] {
    return _.map(entries, (dto: IPBJRecOrgEntry) => {
      const entry: PBJRecOrgEntry = new PBJRecOrgEntry();
      entry.orgId = dto.organizationId;
      entry.orgName = dto.organizationName;
      entry.reconciliation = this.mapToPbjRecEntry(dto.reconciliationData);

      return entry;
    });
  }

  public mapToDepEntries(entries: IPBJRecDepEntry[]): PBJRecDepEntry[] {
    return _.map(entries, (dto: IPBJRecDepEntry) => {
      const entry: PBJRecDepEntry = new PBJRecDepEntry();
      entry.depId = dto.departmentId;
      entry.depName = dto.departmentName;
      entry.reconciliation = this.mapToPbjRecEntry(dto.reconciliationData);

      return entry;
    });
  }

  public mapToEmpEntries(entries: IPBJRecEmpEntry[]): PBJRecEmpEntry[] {
    return _.map(entries, (dto: IPBJRecEmpEntry) => {
      const entry: PBJRecEmpEntry = new PBJRecEmpEntry();
      entry.empId = dto.employeeId;
      entry.empName = dto.employeeName;
      entry.reconciliation = this.mapToPbjRecEntry(dto.reconciliationData);

      return entry;
    });
  }

  public mapToDailyEntries(entries: IPBJRecDailyEntry[]): PBJRecDailyEntry[] {
    return _.map(entries, (dto: IPBJRecDailyEntry) => {
      const entry: PBJRecDailyEntry = new PBJRecDailyEntry();
      entry.dateOn = dateTimeUtils.convertFromDtoString(dto.dateOn);
      entry.reconciliation = this.mapToPbjRecEntry(dto.reconciliationData);

      return entry;
    });
  }

  public mapToPbjRecEntry(dto: IPBJRecEntry): PBJRecEntry {
      const entry: PBJRecEntry = new PBJRecEntry();
      entry.timecardHours = dto.timecardHours;
      entry.startOfPeriod = dto.startOfPeriodAdjustments;
      entry.endOfPeriod = dto.endOfPeriodAdjustments;
      entry.excludedPayCodes = dto.excludedPayCodes;
      entry.unmappedPositions = dto.unmappedPositions;
      entry.manualPbjEntries = dto.manualPbjEntries;
      entry.unapprovedHours = dto.unapprovedHours;
      entry.expectedPbjHours = dto.expectedPbjHours;
      entry.totalPbjHours = dto.totalPbjHours;
      entry.variance = dto.variance;

      return entry;
  }
}
