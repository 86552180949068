<div class="modal-body">
  <div class="header">
    <p class="employee-rotation">You have modified {{totalEmployees}} employee rotations, select when you would like this rotation update to be effective:</p>
  </div>
  <div class="dialog-holder">
    <div class="radio-item">
      <input type="radio" id="Current-Schedule" checked="checked" name="Schedule" value="1" (change)="cycleChange($event)">
      <label for="Current-Schedule">Current Date</label>
  </div>

  <div class="radio-item">
      <input type="radio" id="Next-Schedule" name="Schedule" value="2" (change)="cycleChange($event)">
      <label for="Next-Schedule">Next Schedule Cycle</label>
  </div>
  </div>
  <div class="information mt-5">
    Schedule cycle start date is: {{scheduleCycleStartDate}}
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn red"  (click)="cancelGenerateRotation()">
    Discard
  </button>
  <button type="button" [disabled]="isDisabled()" class="btn green" (click)="saveGenerateRotation()">
    Generate Rotation
  </button>
</div>
