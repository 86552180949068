<!--<slx-spinner [show]="isPositionLoading || isShiftLoading || isUnitLoading || isConstraintLoading || isAbsenceLoading">-->
<div class="slx-label-dark">
<div class="col-md-4 no-pad-left" *ngIf="canChangePosition">
  <slx-input-decorator>
    <slx-dropdown-input slx-input  titleField="name" [options]="positionLookup?.items" name="positionSelector" placeholder="Position" [(ngModel)]="employeeShift.position"
      [readonly]="isReadOnly" valueField="nameWithDate"
      (ngModelChange)="selectedPositionChanged($event)">
    </slx-dropdown-input>
    <span errorMessage for="required"></span>
  </slx-input-decorator>
</div>
<div class="no-pad-left" [ngClass]="{'col-md-2': useAbsence || canChangePosition, 'col-md-4': !useAbsence || !canChangePosition }">
  <slx-input-decorator>
    <slx-dropdown-input slx-input [lookup]="shiftLookup" name="shiftSelector" placeholder="Shift" [(ngModel)]="employeeShift.shift"
      [readonly]="isReadOnly"
      (ngModelChange)="modelChanged()">
    </slx-dropdown-input>
    <span errorMessage for="required"></span>
  </slx-input-decorator>
</div>
<div class="no-pad-left" [ngClass]="{'col-md-2': useAbsence || canChangePosition, 'col-md-4': !useAbsence || !canChangePosition }">
  <slx-input-decorator>
    <slx-dropdown-input slx-input lookup-add-item [lookup]="unitLookup" name="unitSelector" placeholder="Unit"
    [readonly]="isReadOnly"
      [(ngModel)]="employeeShift.unit" (ngModelChange)="modelChanged()">
    </slx-dropdown-input>
    <span errorMessage for="required"></span>
  </slx-input-decorator>
</div>
<div class="col-lg-2 col-md-4 no-pad-left" *ngIf="useConstraint && !isReadOnly">
  <slx-input-decorator>
    <slx-dropdown-input slx-input [lookup]="constraintLookup" name="constraintSelector" placeholder="Constraint" [emptyOption]="{name: '', id: 0}"
      [(ngModel)]="employeeShift.constraint" [readonly]="employeeShift.absence">
    </slx-dropdown-input>
  </slx-input-decorator>
</div>
<div class="col-md-4 col-lg-2 no-pad-left" *ngIf="useAbsence && !isReadOnly">
  <slx-input-decorator>
    <slx-dropdown-input slx-input [lookup]="scheduleAbsenceLookup" name="absenseSelector" placeholder="Absence" [emptyOption]="{description: '', code: '0'}"
      [(ngModel)]="employeeShift.absence" [readonly]="employeeShift.constraint">
    </slx-dropdown-input>
  </slx-input-decorator>
</div>
</div>
