import { forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS } from '@angular/forms';
import * as _ from 'lodash';
import { CommonValidators } from './common-validators';
var MAX_DATE_VALIDATOR = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(function () { return MaxDateValidator; }),
    multi: true
};
var MaxDateValidator = /** @class */ (function () {
    function MaxDateValidator() {
    }
    Object.defineProperty(MaxDateValidator.prototype, "slxMaxDate", {
        set: function (value) {
            this.maxDate = value;
        },
        enumerable: true,
        configurable: true
    });
    /* tslint:enable */
    MaxDateValidator.prototype.ngOnChanges = function (changes) {
        if (changes['slxMaxDate']) {
            this._createValidator();
            if (this._onChange) {
                this._onChange();
            }
        }
    };
    MaxDateValidator.prototype.validate = function (c) {
        return !_.isNil(this.maxDate) ? this._validator(c) : null;
    };
    MaxDateValidator.prototype.registerOnValidatorChange = function (fn) {
        //this._onChange = fn;
        this._onChange = function () { Promise.resolve(null).then(function () { return fn(); }); };
    };
    MaxDateValidator.prototype._createValidator = function () {
        this._validator = CommonValidators.maxDate(this.maxDate);
    };
    return MaxDateValidator;
}());
export { MaxDateValidator };
