import * as tslib_1 from "tslib";
import { LookupType } from './../../../../organization/models/lookup/lookup-definition';
import { OrgLevelWatchService } from './../../../../organization/services/org-level/org-level-watch.service';
import { AgencyScheduleNavigationService } from './../../../../common/services/navigation/agency-schedule-navigation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { AgencyDefinition, Lookup, ILookupRequest, Organization, Department, Position } from '../../../../organization/models/index';
import { OrgLevelType } from '../../../../state-model/models/index';
import { AgencyEmployeeApiService, AgencyEmployeeValidatorAdapter } from '../../../services/index';
import { AgencyEmployee, AddAgencyEmployeeReq, ValidateAgencyEmployeeReq } from '../../../models/index';
import { LookupService } from '../../../../organization/services/lookup/lookup.service';
import { AgenciesApiService } from '../../../services/index';
import { DetailScreenService } from '../../../../scheduler/services';
import { GroupResult } from "@progress/kendo-data-query";
var ScheduleAgencyEmployeeComponent = /** @class */ (function () {
    function ScheduleAgencyEmployeeComponent(apiService, validationAdapter, activatedRoute, router, orglevelWatch, lookupService, agenciesApiService, detailScreenService) {
        this.apiService = apiService;
        this.validationAdapter = validationAdapter;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.orglevelWatch = orglevelWatch;
        this.lookupService = lookupService;
        this.agenciesApiService = agenciesApiService;
        this.empName = 'employeeName';
        this.firstNameField = 'employeeFirstName';
        this.lastNameField = 'employeeLastName';
        this.agencyNavService = new AgencyScheduleNavigationService(this.router, this.activatedRoute);
        this.detailScreenService = detailScreenService;
        this.startNewEmployee();
        this.state = {
            isLoading: false,
            isAddingMode: true,
            isError: false
        };
    }
    Object.defineProperty(ScheduleAgencyEmployeeComponent.prototype, "getAgencyDefinitionLookup", {
        get: function () {
            return this.agencyDefinitionLookup;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleAgencyEmployeeComponent.prototype, "isScheduled", {
        get: function () {
            return ((this.request.shift && this.request.shift.id) && this.request.agencyEmployee && !!this.request.agencyEmployee.workDate);
        },
        enumerable: true,
        configurable: true
    });
    ScheduleAgencyEmployeeComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.userSubscription = this.user$.subscribe(function (session) {
                    if (session) {
                        _this.alias = session.alias;
                    }
                });
                this.orgLevelSubscription = this.orgLevel$
                    .filter(function (o) { return o && o.id && (!_this.currentOrgLevel || _this.currentOrgLevel.id !== o.id); })
                    .subscribe(function (o) {
                    if (o.type === OrgLevelType.department) {
                        if (_.isNil(o.parentId) || o.parentId == 0) {
                            var userDepartmentsPromise = _this.lookupService.getLookup({ lookupType: LookupType.userAccessibleDepartments, orgLevelId: o.id, updateCacheForced: true });
                            var organizationsPromise = _this.lookupService.getLookup({ lookupType: LookupType.organization });
                            Promise.all([userDepartmentsPromise, organizationsPromise])
                                .then(function (values) {
                                var userDepartments = values[0];
                                var organizations = values[1];
                                var department = _.find(userDepartments.items, function (d) { return d.orgLevelId == o.id; });
                                var organization = _.find(organizations.items, function (o) { return o.id == department.parentOrganizationId; });
                                _this.organizationSelectorOrglevelId = organization.orgLevelId;
                                _this.selectedDepartmentId = department.orgLevelId;
                                _this.showOrgStructureSelectors = true;
                            });
                        }
                        else {
                            _this.organizationSelectorOrglevelId = o.parentId;
                            _this.selectedDepartmentId = o.id;
                            _this.showOrgStructureSelectors = true;
                        }
                    }
                    else {
                        _this.organizationSelectorOrglevelId = o.id;
                        _this.selectedDepartmentId = undefined;
                        _this.showOrgStructureSelectors = true;
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    ScheduleAgencyEmployeeComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    ScheduleAgencyEmployeeComponent.prototype.agencySelected = function (agency) {
        var _this = this;
        this.setAgency(agency);
        if (!agency || !agency.id) {
            this.employeeLookup1 = this.employeeLookup2 = this.createEmpLookup([], '');
            this.employeeNameLookup = this.createEmpLookup([], '');
            this.preSelectedEmployee = null;
            this.selectedEmployee = null;
            this.setEmployee(new AgencyEmployee());
            return;
        }
        this.state.isLoading = true;
        this.employeeName = null;
        this.selectedEmployee = null;
        this.apiService.getAgencyEmployees(agency.id)
            .then(function (emps) {
            _this.state.isLoading = false;
            _this.employeeNameLookup = _this.createEmpLookup(emps, _this.empName);
            _this.employeeSort = _this.createEmpLookup(emps, _this.empName).items;
            _this.employeeSort.sort(function (a, b) { return a.employeeName.toLowerCase().localeCompare(b.employeeName.toLowerCase()); });
        });
    };
    ScheduleAgencyEmployeeComponent.prototype.changedAgencyName = function (agencyName) {
        this.agencyName = agencyName;
        this.employeeName = null;
        this.selectedEmployee = null;
    };
    ScheduleAgencyEmployeeComponent.prototype.changedEmployeeName = function (name) {
        var _this = this;
        if (!this.employeeNameLookup) {
            return;
        }
        if (name == null) {
            return;
        }
        this.employeeName = name.employeeName;
        this.employeeFirstName = name.employeeFirstName;
        this.employeeLastName = name.employeeLastName;
        var fEmp = _.find(this.employeeNameLookup.items, function (emp) { return _.startsWith(emp.employeeName, _this.employeeName); });
        if (fEmp && fEmp.employeeName === this.employeeName) {
            this.selectedEmployee = fEmp;
            this.employeeFirstName = fEmp.employeeFirstName;
            this.employeeLastName = fEmp.employeeLastName;
            this.setEmployee(this.selectedEmployee);
        }
        else {
            this.createAndSetSelectedEmployee();
        }
        this.validateReq();
        return;
    };
    ScheduleAgencyEmployeeComponent.prototype.handleFilter = function (name) {
        if (name === "") {
            this.employeeSort = this.employeeNameLookup.items;
        }
        else {
            this.employeeSort = this.employeeNameLookup.items.filter(function (s) { return s.employeeName.toLowerCase().indexOf(name.toLowerCase()) !== -1; });
        }
    };
    ScheduleAgencyEmployeeComponent.prototype.onOrganizationChanged = function (org) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.request.department = null;
                        this.request.position = null;
                        this.request.shift = null;
                        this.request.unit = null;
                        this.selectedOrgLevelId = org.orgLevelId;
                        this.departmentLookupRequest = { lookupType: 'userAccessibleDepartments', orgLevelId: this.selectedOrgLevelId, updateCacheForced: true };
                        _a = this;
                        return [4 /*yield*/, this.prepareAgencyList(1)];
                    case 1:
                        _a.agencyList = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleAgencyEmployeeComponent.prototype.departmentLookupApplied = function (lookup) {
        var _this = this;
        if (this.selectedDepartmentId) {
            this.request.department = _.find(lookup.items, function (d) { return d.orgLevelId === _this.selectedDepartmentId; });
            if (this.request.department === undefined && lookup.items.length > 0) {
                this.request.department = lookup.items[0];
            }
        }
    };
    ScheduleAgencyEmployeeComponent.prototype.onDepartmentChanged = function (dep) {
        this.request.position = null;
        this.request.shift = null;
        this.request.unit = null;
    };
    ScheduleAgencyEmployeeComponent.prototype.onWorkdataChanged = function () {
        this.validateReq();
    };
    ScheduleAgencyEmployeeComponent.prototype.onShiftChanged = function () {
        this.validateReq();
    };
    ScheduleAgencyEmployeeComponent.prototype.onPositionChanged = function (pos) {
        var _this = this;
        if (this.request.position && !_.isEmpty(this.selectedEmployeePositions)) {
            var empPos = _.find(this.selectedEmployeePositions, function (pos) { return pos.id === _this.request.position.id; });
            if (empPos) {
                this.selectedPositionStartDate = empPos.startDate;
            }
        }
        this.calcMinWorkDate();
    };
    ScheduleAgencyEmployeeComponent.prototype.onStartDateChanged = function () {
        this.calcMinWorkDate();
    };
    ScheduleAgencyEmployeeComponent.prototype.calcMinWorkDate = function () {
        if (this.selectedPositionStartDate && this.selectedPositionStartDate > this.request.agencyEmployee.startDate) {
            this.minWorkDate = this.selectedPositionStartDate;
        }
        else {
            this.minWorkDate = this.request.agencyEmployee.startDate || new Date();
        }
        if (!this.request.agencyEmployee.workDate || (this.request.agencyEmployee.workDate < this.minWorkDate)) {
            this.request.agencyEmployee.workDate = this.minWorkDate;
        }
    };
    ScheduleAgencyEmployeeComponent.prototype.onAddClick = function () {
        var _this = this;
        this.state.isLoading = true;
        this.request.createAgency = false;
        this.request.createEmployee = false;
        this.state.message = undefined;
        if (!this.selectedAgency || !this.selectedAgency.id) {
            this.request.agencyEmployee.agency = { id: 0, name: this.agencyName, orgLevelId: 0 };
            this.request.createAgency = true;
        }
        if (!this.selectedEmployee || !this.selectedEmployee.employeeId) {
            this.request.agencyEmployee.employeeId = 0;
            this.request.agencyEmployee.employeeName = this.employeeName;
            this.request.agencyEmployee.employeeFirstName = this.employeeFirstName;
            this.request.agencyEmployee.employeeLastName = this.employeeLastName;
            this.request.createEmployee = true;
        }
        this.apiService.addAgencyEmployee(this.request)
            .then(function (emp) {
            _this.request.agencyEmployee.badgeId = emp.badgeId;
            _this.request.agencyEmployee.prefix = emp.prefix;
            _this.request.agencyEmployee.employeeId = emp.employeeId;
            _this.state.isLoading = false;
            _this.state.isAddingMode = false;
            _this.state.isError = false;
            _this.state.message = emp.message;
            if (_this.request.createAgency) {
                _this.orglevelWatch.reloadOrgTree();
            }
        })
            .catch(function (res) {
            _this.state.isLoading = false;
            _this.state.isAddingMode = false;
            _this.state.isError = true;
        });
    };
    ScheduleAgencyEmployeeComponent.prototype.onAddNewClick = function () {
        this.state.isAddingMode = true;
        this.state.isError = true;
        this.state.message = undefined;
        this.startNewEmployee();
    };
    Object.defineProperty(ScheduleAgencyEmployeeComponent.prototype, "selectedEmployeeExists", {
        get: function () {
            return this.selectedEmployee && this.selectedEmployee.employeeId > 0;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleAgencyEmployeeComponent.prototype.validateReq = function () {
        var _this = this;
        if (!this.request.agencyEmployee.workDate || !this.request.shift || !this.request.department) {
            return;
        }
        var req = new ValidateAgencyEmployeeReq();
        req.date = this.request.agencyEmployee.workDate;
        req.employeeId = this.request.agencyEmployee.employeeId;
        req.shiftId = this.request.shift.id;
        req.orgLevelId = this.request.department.orgLevelId;
        this.validationAdapter.validate(AgencyEmployeeValidatorAdapter.agencyEmployeeAddValidation, req)
            .then(function (value) {
            _this.validationResult = value;
            _this.validationResult.isReadyForValidation = true;
        }).catch(function (res) {
            _this.validationResult = { isValid: false, isReadyForValidation: true, errorMessage: 'During validation the error has occurred! Contact your system administrator.' };
        });
    };
    ScheduleAgencyEmployeeComponent.prototype.createAndSetSelectedEmployee = function () {
        this.selectedEmployee = new AgencyEmployee();
        this.selectedEmployee.employeeName = this.employeeName;
        this.selectedEmployee.employeeLastName = this.employeeLastName;
        this.selectedEmployee.employeeFirstName = this.employeeFirstName;
        this.setEmployee(this.selectedEmployee);
    };
    ScheduleAgencyEmployeeComponent.prototype.setAgency = function (agency) {
        this.request.agencyEmployee.agency.id = agency ? agency.id : undefined;
        this.request.agencyEmployee.agency.name = agency ? agency.name : null;
    };
    ScheduleAgencyEmployeeComponent.prototype.setEmployee = function (emp) {
        var _this = this;
        this.selectedEmployeePositions = [];
        this.request.agencyEmployee.employeeId = emp.employeeId;
        this.request.agencyEmployee.employeeFirstName = emp.employeeFirstName;
        this.request.agencyEmployee.employeeLastName = emp.employeeLastName;
        this.request.agencyEmployee.prefix = emp.prefix;
        this.request.agencyEmployee.badgeId = emp.badgeId;
        this.request.agencyEmployee.email = emp.email;
        this.request.agencyEmployee.phone = emp.phone;
        this.request.agencyEmployee.startDate = emp.startDate;
        this.validateReq();
        if (emp.employeeId > 0) {
            this.state.isLoading = true;
            this.lookupService.getLookup({ lookupType: LookupType.position, employeeId: emp.employeeId, isActive: false, updateCacheForced: true }).then(function (value) {
                _this.state.isLoading = false;
                _this.selectedEmployeePositions = value.items;
            });
        }
    };
    ScheduleAgencyEmployeeComponent.prototype.createEmpLookup = function (items, field) {
        var lookup = new Lookup();
        lookup.titleField = field;
        lookup.valueField = 'employeeId';
        lookup.items = items;
        return lookup;
    };
    ScheduleAgencyEmployeeComponent.prototype.startNewEmployee = function () {
        var prevRequest = this.request;
        this.request = new AddAgencyEmployeeReq();
        this.request.agencyEmployee = new AgencyEmployee();
        this.request.agencyEmployee.agency = new AgencyDefinition();
        this.request.agencyEmployee.workDate = new Date();
        this.request.agencyEmployee.startDate = new Date();
        this.minWorkDate = new Date();
        this.selectedAgency = null;
        this.preSelectedEmployee = null;
        this.selectedEmployee = null;
        this.employeeName = null;
        this.employeeFirstName = null;
        this.employeeLastName = null;
        this.validationResult = null;
        if (prevRequest) {
            this.request.organization = prevRequest.organization;
            this.request.department = prevRequest.department;
        }
    };
    ScheduleAgencyEmployeeComponent.prototype.prepareAgencyList = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var agencies, filteredResults;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.request && this.request.organization)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.agenciesApiService.getAgencies(orgLevelId)];
                    case 1:
                        agencies = _a.sent();
                        filteredResults = agencies.filter(function (a) { return (a.organizations.findIndex(function (o) { return o.id == _this.request.organization.id; }) > -1); });
                        return [2 /*return*/, _.map(filteredResults, function (a) {
                                var agencyDef = new AgencyDefinition();
                                agencyDef.id = a.agency.id;
                                agencyDef.name = a.agency.name;
                                return agencyDef;
                            })];
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleAgencyEmployeeComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleAgencyEmployeeComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleAgencyEmployeeComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleAgencyEmployeeComponent.prototype, "orgLevelSubscription", void 0);
    return ScheduleAgencyEmployeeComponent;
}());
export { ScheduleAgencyEmployeeComponent };
