import * as tslib_1 from "tslib";
import { ModalService } from './../../../../common/services/modal/modal.service';
import { TimeclockCommunicationApiService } from './../../services/timeclock-communication/timeclock-communication-api.service';
import { StringUtils } from './../../../../framework/string-utils/string-utils';
import { EmployeeSectionsPersonalApiService } from './../../../employee-sections/services/employee-section-personal/employee-section-personal-api.service';
import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { OnInit, OnDestroy } from '@angular/core';
import { PhonesTypes } from '../../models/index';
var LogPhoneCallComponent = /** @class */ (function () {
    function LogPhoneCallComponent(personalService, apiService, modalService) {
        this.personalService = personalService;
        this.apiService = apiService;
        this.modalService = modalService;
        this.isLoading = false;
        this.selectedDate = new Date();
    }
    LogPhoneCallComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevel = orgLevel;
        });
    };
    LogPhoneCallComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    LogPhoneCallComponent.prototype.employeeChanged = function (emp) {
        var _this = this;
        this.homePhone = null;
        this.mobilePhone = null;
        this.altPhone = null;
        this.phoneType = null;
        if (!emp)
            return;
        this.isLoading = true;
        this.personalService.getPersonalContacts(emp.id).then(function (value) {
            _this.altPhone = value.alternativePhone.fieldValue;
            _this.mobilePhone = value.mobile.fieldValue;
            _this.homePhone = value.phone.fieldValue;
            if (_this.homePhone) {
                _this.phoneType = PhonesTypes.home;
            }
            else if (_this.mobilePhone) {
                _this.phoneType = PhonesTypes.mobile;
            }
            else if (_this.altPhone) {
                _this.phoneType = PhonesTypes.alternative;
            }
            _this.isLoading = false;
        }).catch(function (reason) {
            _this.isLoading = false;
        });
    };
    Object.defineProperty(LogPhoneCallComponent.prototype, "canSave", {
        get: function () {
            return this.selectedEmployee && this.phoneType !== null && !StringUtils.isNullOrEmpty(this.messageText);
        },
        enumerable: true,
        configurable: true
    });
    LogPhoneCallComponent.prototype.save = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.saveCall(this.selectedEmployee.id, this.selectedDate, this.selectedPhone, this.messageText).then(function (value) {
            _this.selectedEmployee = null;
            _this.messageText = null;
            _this.homePhone = null;
            _this.mobilePhone = null;
            _this.altPhone = null;
            _this.phoneType = null;
            _this.isLoading = false;
            _this.modalService.globalAnchor.openInfoDialog('Information', 'Call has been saved!');
        }).catch(function (reason) {
            _this.isLoading = false;
        });
    };
    Object.defineProperty(LogPhoneCallComponent.prototype, "selectedPhone", {
        get: function () {
            switch (this.phoneType) {
                case PhonesTypes.home:
                    return this.homePhone;
                case PhonesTypes.mobile:
                    return this.mobilePhone;
                case PhonesTypes.alternative:
                    return this.altPhone;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], LogPhoneCallComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], LogPhoneCallComponent.prototype, "orgLevelSubscription", void 0);
    return LogPhoneCallComponent;
}());
export { LogPhoneCallComponent };
