import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { Assert } from '../../../../framework/index';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';

import { employeeConfig } from '../../employee.config';
import { EmployeeCallLogMapService } from './employee-call-log-map.service';
import {
  IEmployeeCallLog,
  EmployeeCallLog,
  IMessageCenterCallLogRecord,
} from '../../models/index';

@Injectable()
export class EmployeeCallLogApiService {
  constructor(
    private employeeCallLogMapService: EmployeeCallLogMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService
  ) { }

  public getCallLog(orgLevelId: number, employeeIds: number[], startDate: Date, endDate: Date): Promise<EmployeeCallLog> {
    Assert.isNotNull(employeeIds, 'employeeIds');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const url = `${this.getCallLogApiRoot()}`;
    const body = this.employeeCallLogMapService.mapToCallLogDto(orgLevelId, employeeIds, startDate, endDate);
    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<EmployeeCallLog> = this.apiUtilService.request<IEmployeeCallLog, Meta>(request)
      .then((response: ResponseBody<IEmployeeCallLog, Meta>) =>
        this.employeeCallLogMapService.mapToCallLog(response.data)
      );
    return promise;
  }

  public getMessageCenterCallLog(orgLevelId: number, employeeIds: number[], startDate: Date, endDate: Date): Promise<EmployeeCallLog> {
    Assert.isNotNull(employeeIds, 'employeeIds');
    Assert.isNotNull(startDate, 'startDate');
    Assert.isNotNull(endDate, 'endDate');

    const mcCallLogsUrl = `${this.apiUtilService.getCommunicationApiRoot()}/${employeeConfig.api.messageCenterCallLogs}`;
    const body = this.employeeCallLogMapService.mapToCallLogDto(orgLevelId, employeeIds, startDate, endDate, true);
    const mcCallLogsRequest = this.urlParamsService.createPostRequest(mcCallLogsUrl, body, {
      'api-version': '1.0'
    });
    const mcCallLogsPromise = this.apiUtilService
      .request<IMessageCenterCallLogRecord[], null>(mcCallLogsRequest);

    return Promise
      .all([
        this.getCallLog(orgLevelId, employeeIds, startDate, endDate),
        mcCallLogsPromise
      ])
      .then(([employeeCallLog, mcCallLogRecordDtos]) =>
        this.employeeCallLogMapService.mapWithMCToCallLog(employeeCallLog, <any> mcCallLogRecordDtos));
  }

  private getCallLogApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.communications.root}/${employeeConfig.api.communications.log.root}`;
  }
}
