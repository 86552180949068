import { Assert } from '../../../framework/index';
import { ReportDefinition, } from '../../models/index';
import { GenerateReportDialogComponent } from '../generate-report-dialog/generate-report-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ReportsManagementService } from '../../services/index';
import { ReportActionType } from '../../models/report-action-type';
var ReportListComponent = /** @class */ (function () {
    function ReportListComponent(modalService, reportsManagementService) {
        this.modalService = modalService;
        this.isAlert = false;
        this.reportsManagementService = reportsManagementService;
    }
    Object.defineProperty(ReportListComponent.prototype, "reports", {
        get: function () {
            return this.m_reports;
        },
        set: function (value) {
            this.m_reports = value;
        },
        enumerable: true,
        configurable: true
    });
    ReportListComponent.prototype.onClick = function (report) {
        var _this = this;
        Assert.isNotNull(report, 'report');
        var selectedReport = this.reportsManagementService.selectReport(report);
        var generateReportDialogComponent = GenerateReportDialogComponent.openDialog(selectedReport, this.modalService, function (result) {
            if (result) {
                if (report.parameters.find(function (x) { return x.name == "Show_dates"; })) {
                    var totalHr = report.parameters.find(function (x) { return x.name == "Total_hrs"; });
                    var zeroHr = report.parameters.find(function (x) { return x.name == "Zero_hrs"; });
                    var consecHr = report.parameters.find(function (x) { return x.name == "consecutive_hrs"; });
                    var showDate = report.parameters.find(function (x) { return x.name == "Show_dates"; });
                    var isShowDates = showDate.defaultValue;
                    var isZeroHrs = zeroHr.defaultValue;
                    var isTotalHrs = totalHr.defaultValue;
                    var isConsecHrs = consecHr.defaultValue;
                    showDate.value = isShowDates != true ? 0 : 1;
                    zeroHr.value = isZeroHrs != true ? 0 : 1;
                    consecHr.value = isConsecHrs != true ? 0 : consecHr.value;
                    totalHr.value = isTotalHrs != true ? 0 : totalHr.value;
                    if ((isShowDates == true && isZeroHrs != true && isTotalHrs != true && isConsecHrs != true) || (isZeroHrs != true && isTotalHrs != true && isConsecHrs != true)) {
                        return;
                    }
                }
                switch (generateReportDialogComponent.reportAction) {
                    case ReportActionType.Preview: {
                        _this.reportsManagementService.previewReport(report);
                        break;
                    }
                    case ReportActionType.Download: {
                        _this.reportsManagementService.generateReport(report);
                        break;
                    }
                    default: {
                        throw new Error("Report action " + generateReportDialogComponent.reportAction + " is not supported.");
                    }
                }
            }
        });
    };
    return ReportListComponent;
}());
export { ReportListComponent };
