<div *ngIf="!templates.treeRootTemplate">

    <slx-tree-node-component *ngIf="treeModel.showRoot"
        [node]="treeModel.root"
        [index]="0"
        [templates] = "templates"
        >
    </slx-tree-node-component>

    <slx-tree-node-children-component *ngIf="!treeModel.showRoot"
    [node]="treeModel.root"
    [templates] = "templates"
    >
    </slx-tree-node-children-component>

</div>

<ng-template *ngIf="templates.treeRootTemplate"
    [ngTemplateOutlet]="templates.treeRootTemplate"
    [ngTemplateOutletContext]="{ $implicit: treeModel, treeModel: treeModel, templates: templates }">
</ng-template>
