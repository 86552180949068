import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeDocument = /** @class */ (function () {
    function EmployeeDocument() {
    }
    return EmployeeDocument;
}());
export { EmployeeDocument };
var EmployeeSectionsDocuments = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsDocuments, _super);
    function EmployeeSectionsDocuments() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsDocuments;
}(EmployeeSectionsBase));
export { EmployeeSectionsDocuments };
