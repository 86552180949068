import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { ReportParameter } from '../../../models/index';
import { appConfig } from '../../../../../app/app.config';
import { dateTimeUtils } from '../../../../common/utils/index';
import { ReportsApiService } from '../../../services/reports-api.service';
import { AppSettingsManageService } from '../../../../app-settings/services';
var ReportDateParameterComponent = /** @class */ (function () {
    function ReportDateParameterComponent(reportAPIService, appSettingManageService) {
        this.reportAPIService = reportAPIService;
        this.appSettingManageService = appSettingManageService;
        this.includeThousandsSeparator = false;
        this.isRetroEnd = false;
        this.isWageComp1End = false;
        this.isAllMissingPunchesEnd = false;
        this.allowSelectFutureDateFlag = false;
        this.start_date = 'start_date';
        this.end_date = 'end_date';
        this.retro_pay = 'retro_pay';
        this.wage_comp1 = 'EEO_Report_Wage_comp1';
        this.all_missing_punches = 'ta_all_historical_missing_punches';
        this.getSettings();
        this.appConfig = appConfig;
        this.m_minValue = moment(appConfig.minCorrectDate).toDate();
        this.m_maxValue = moment(appConfig.maxCorrectDate).toDate();
    }
    Object.defineProperty(ReportDateParameterComponent.prototype, "parameter", {
        get: function () {
            return this.m_parameter;
        },
        set: function (value) {
            this.processParameter(value);
            this.m_parameter = value;
            this.parameterBound = this.getParameterBound();
            if (this.parameters != undefined)
                this.isPBJRNHrs = this.parameters.find(function (x) { return x.name == 'Show_dates'; }) ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDateParameterComponent.prototype, "componentName", {
        get: function () {
            return "parameter_" + this.index;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDateParameterComponent.prototype, "minValue", {
        get: function () {
            return this.parameter.name === this.end_date && this.parameterBound && this.parameterBound.value
                && dateTimeUtils.isInValidPeriod(this.parameterBound.value) ? this.parameterBound.value : this.m_minValue;
        },
        set: function (value) {
            this.m_minValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDateParameterComponent.prototype, "maxValue", {
        get: function () {
            if (this.isPBJRNHrs) {
                return this.allowSelectFutureDateFlag ? moment(moment().startOf('day').toDate()).add(180, 'day').startOf('day').toDate() : (this.parameter.name === this.start_date || this.parameter.name === this.end_date)
                    && dateTimeUtils.isInValidPeriod(this.parameterBound.value)
                    ? moment().subtract(1, 'day').startOf('day').toDate() : this.m_maxValue;
            }
            return this.parameter.name === this.start_date
                && this.parameterBound && this.parameterBound.value
                && dateTimeUtils.isInValidPeriod(this.parameterBound.value)
                && this.parameterBound.value ? this.parameterBound.value : this.m_maxValue;
        },
        set: function (value) {
            this.m_maxValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportDateParameterComponent.prototype, "maxEndValue", {
        get: function () {
            if (this.isPBJRNHrs && (this.parameter.name === this.start_date || this.parameter.name === this.end_date)) {
                return (this.parameter.name === this.start_date || this.parameter.name === this.end_date)
                    && dateTimeUtils.isInValidPeriod(this.parameterBound.value)
                    ? moment().subtract(1, 'day').startOf('day').toDate() : this.m_maxValue;
            }
        },
        enumerable: true,
        configurable: true
    });
    ReportDateParameterComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.allowSelectFutureDateFlag = appServerConfig.AllowSelectFutureDateRNMinHours;
                        return [2 /*return*/];
                }
            });
        });
    };
    ReportDateParameterComponent.prototype.processParameter = function (parameter) {
        var date = undefined;
        if (parameter.defaultValue) {
            var defaultValue = parameter.defaultValue.toString().toLowerCase();
            date = this.getValueFromDefault(defaultValue);
            parameter.value = date;
        }
    };
    ReportDateParameterComponent.prototype.getParameterBound = function () {
        var _this = this;
        var bound = undefined;
        if (this.parameter.name === this.start_date) {
            var p = _.find(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.end_date; });
            if (p)
                bound = p;
        }
        else if (this.parameter.name === this.end_date) {
            var p = _.find(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.start_date; });
            if (p) {
                bound = p;
            }
            this.isRetroEnd = _.some(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.retro_pay; });
            this.isWageComp1End = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.wage_comp1);
            this.isAllMissingPunchesEnd = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.all_missing_punches);
        }
        return bound;
    };
    ReportDateParameterComponent.prototype.getValueFromDefault = function (defaultValue) {
        var date = undefined;
        if (defaultValue === 'yesterday') {
            date = moment().subtract(1, 'day').startOf('day').toDate();
        }
        else if (defaultValue === 'today') {
            date = moment().startOf('day').toDate();
        }
        else if (defaultValue === 'sevendaysprior') {
            date = moment().subtract(7, 'day').startOf('day').toDate();
        }
        else if (defaultValue === 'thirtydaysprior') {
            date = moment().subtract(31, 'day').startOf('day').toDate();
        }
        return date;
    };
    Object.defineProperty(ReportDateParameterComponent.prototype, "isMoreThanYear", {
        get: function () {
            var _this = this;
            var retroPay = _.find(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.retro_pay; });
            var wageComp1 = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.wage_comp1);
            var allMissingPunches = this.parameter.reportDefinition && _.isEqual(this.parameter.reportDefinition.name, this.all_missing_punches);
            if (retroPay || wageComp1) {
                var end = _.find(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.end_date; });
                var start = _.find(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.start_date; });
                if (moment(end.value).diff(start.value, 'day') > 366) {
                    this.daysRange = '1 year';
                    return true;
                }
            }
            if (allMissingPunches) {
                var end = _.find(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.end_date; });
                var start = _.find(this.parameter.reportDefinition.parameters, function (p) { return p.name === _this.start_date; });
                if (moment(end.value).diff(start.value, 'day') > 89) {
                    this.daysRange = '90 days';
                    return true;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    return ReportDateParameterComponent;
}());
export { ReportDateParameterComponent };
