import * as tslib_1 from "tslib";
import * as moment from 'moment';
import * as _ from 'lodash';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { mutableSelect } from '../../../core/decorators/index';
import { StateResetTypes } from '../../../core/models/settings/reset-types';
import { TaConsoleNavigationService } from './../../../common/services/navigation/ta-console-navigation.service';
import { StateManagementService, ComponentStateStorageService, ExceptionConsoleNavigationService, DailyPunchesNavigationService } from '../../../common/index';
import { unsubscribe } from '../../../core/decorators/index';
import { ArrivalDeparturesManagementService, TaConsoleToolbarService } from '../../services/index';
import { appConfig } from '../../../app.config';
import { scheduleMicrotask } from '../../../core/utils/index';
import { LookupService, TimeclockDailySummaryContainer, TimeclockDataService, PayCycleHelperService } from '../../../organization/index';
import { screenUtils } from '../../../common/utils/index';
var TaConsoleComponent = /** @class */ (function () {
    function TaConsoleComponent(activatedRoute, router, arrivalDeparturesManagementService, timeclockDataService, payCycleHelperService, stateManagement, storageService, lookupService, consoleToolbarService) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.arrivalDeparturesManagementService = arrivalDeparturesManagementService;
        this.timeclockDataService = timeclockDataService;
        this.payCycleHelperService = payCycleHelperService;
        this.stateManagement = stateManagement;
        this.storageService = storageService;
        this.lookupService = lookupService;
        this.consoleToolbarService = consoleToolbarService;
        this.m_componentId = 'TaConsoleComponent';
        this.m_dateFiltersControlId = 'DatePickerNgx';
        this.m_dateFiltersResetType = StateResetTypes.SessionEnd | StateResetTypes.MenuChange;
        this.punchesNavService = new DailyPunchesNavigationService(this.router, this.activatedRoute);
        this.taNavService = new TaConsoleNavigationService(this.router, this.activatedRoute);
        this.exceptionNavService = new ExceptionConsoleNavigationService(this.router, this.activatedRoute);
        this.dateNow = moment().toDate();
        this.appConfig = appConfig;
    }
    TaConsoleComponent.prototype.ngOnInit = function () {
        this.stateManagement.init(this.m_componentId);
    };
    TaConsoleComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        scheduleMicrotask(function () {
            _this.timeclockDataService.loadTimeclocks();
            _this.routeSubscription = _this.activatedRoute.params
                .combineLatest(_this.activatedRoute.queryParams, _this.orgLevel$)
                .subscribe(function (_a) {
                var params = _a[0], queryParams = _a[1], orgLevel = _a[2];
                if (!orgLevel || !orgLevel.id)
                    return;
                if (_this.orgLevelId !== orgLevel.id) {
                    _this.orgLevelId = orgLevel.id;
                }
                if (_this.orgLevelId === +queryParams.orgLevelId) {
                    var dateOn = queryParams['date'];
                    if (!dateOn) {
                        _this.restoreFilters();
                        if (_this.selectedDate) {
                            _this.dataChanged(false);
                            return;
                        }
                        dateOn = _this.dateNow;
                    }
                    _this.selectedDate = moment(dateOn, appConfig.linkDateFormat).toDate();
                    _this.dataChanged(true);
                }
            });
            _this.timeclockLoadedSubscription = _this.timeclockDataService.onLoaded$
                .subscribe(function (timeclocksContainer) {
                timeclocksContainer.records = _.orderBy(timeclocksContainer.records, ['isVirtual', 'communicationStatus', 'sortName']);
                _this.timeclockDailySummaryContainer = timeclocksContainer;
            });
            _this.arrivalDeparturesLoadedSubscription = _this.arrivalDeparturesManagementService.onLoaded$
                .subscribe(function (container) {
                _this.arrivalDeparturesContainer = container;
            });
        });
    };
    TaConsoleComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    TaConsoleComponent.prototype.onFilterDateChanged = function (date) {
        this.selectedDate = date;
        this.saveFilters();
        this.navigateToConsole(this.selectedDate);
    };
    TaConsoleComponent.prototype.navigateToConsole = function (dateOn) {
        this.taNavService.navigateToTaConsole(dateOn);
    };
    TaConsoleComponent.prototype.navigateToPunchMissing = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dateRange;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.payCycleHelperService.getEffectivePunchesDateRange(this.selectedDate, this.orgLevelId)];
                    case 1:
                        dateRange = _a.sent();
                        dateRange.startDate = moment().subtract(14, 'days').toDate();
                        this.punchesNavService.navigateToDailyPunchesDates(this.orgLevelId, dateRange.startDate, dateRange.endDate, true);
                        return [2 /*return*/];
                }
            });
        });
    };
    TaConsoleComponent.prototype.navigateToTimeException = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dateRange;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.payCycleHelperService.getEffectivePunchesDateRange(this.selectedDate, this.orgLevelId)];
                    case 1:
                        dateRange = _a.sent();
                        this.exceptionNavService.navigateToExceptionConsoleDates(this.orgLevelId, dateRange.startDate, dateRange.endDate, false);
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(TaConsoleComponent.prototype, "missingPunchesCount", {
        get: function () {
            return +_.get(this.arrivalDeparturesContainer, 'missingPunchesCount') || 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaConsoleComponent.prototype, "timecardExceptionCount", {
        get: function () {
            return +_.get(this.arrivalDeparturesContainer, 'timecardExceptionCount') || 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TaConsoleComponent.prototype, "dateFormat", {
        get: function () {
            return screenUtils.isMobile ? 'MM/dd/yy' : 'MM/dd/yyyy';
        },
        enumerable: true,
        configurable: true
    });
    TaConsoleComponent.prototype.dataChanged = function (isSaveDate) {
        if (_.isDate(this.selectedDate) && _.isNumber(this.orgLevelId)) {
            this.arrivalDeparturesManagementService.loadData(this.orgLevelId, this.selectedDate);
        }
        if (isSaveDate) {
            this.saveFilters();
        }
    };
    TaConsoleComponent.prototype.saveFilters = function () {
        this.storageService.setControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId, {
            value: { selectedDate: this.selectedDate }
        }, this.m_dateFiltersResetType, this.stateKey);
        this.stateManagement.controlValueChanged(this.m_dateFiltersControlId);
    };
    TaConsoleComponent.prototype.restoreFilters = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.m_dateFiltersControlId);
        if (state && state.value) {
            if (state.value.selectedDate)
                this.selectedDate = moment(state.value.selectedDate).toDate();
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], TaConsoleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TaConsoleComponent.prototype, "routeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TaConsoleComponent.prototype, "timeclockLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], TaConsoleComponent.prototype, "arrivalDeparturesLoadedSubscription", void 0);
    return TaConsoleComponent;
}());
export { TaConsoleComponent };
