
export * from './pm-roster/pm-roster.component';
export * from './pm-roster-toolbar/pm-roster-toolbar.component';
export * from './pm-roster-grid/pm-roster-grid.component';
export * from './pm-employee-roster/pm-employee-roster.component';
export * from './pm-employee-roster-grid/pm-employee-roster-grid.component';
export * from './pm-employee-roster-toolbar/pm-employee-roster-toolbar.component';

import { PmRosterComponent } from './pm-roster/pm-roster.component';
import { PmRosterToolbarComponent } from './pm-roster-toolbar/pm-roster-toolbar.component';
import { PmRosterGridComponent } from './pm-roster-grid/pm-roster-grid.component';
import { PmEmployeeRosterComponent } from './pm-employee-roster/pm-employee-roster.component';
import { PmEmployeeRosterGridComponent } from './pm-employee-roster-grid/pm-employee-roster-grid.component';
import { PmEmployeeRosterToolbarComponent } from './pm-employee-roster-toolbar/pm-employee-roster-toolbar.component';

export const rosterComponents: any[] = [
  PmRosterComponent,
  PmRosterToolbarComponent,
  PmRosterGridComponent,
  PmEmployeeRosterComponent,
  PmEmployeeRosterGridComponent,
  PmEmployeeRosterToolbarComponent
];
