import { Injectable, Type } from '@angular/core';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ComponentStateStorageService } from '../component-state/component-state-storage.service';
import { ColumnSettingsStorageService } from '../column-settings/column-settings-storage.service';
import { IControlStateStorage, IColumnGroupsState, IControlState, IGridState, ControlStateKey, StateResetTypes, IRestoreControleData } from '../../../core/models/index';

@Injectable()
export class StateManagementService {
  public onInit$: ReplaySubject<any>;
  public onComponentActiveStateChanged$: ReplaySubject<StringMap<any>>;
  public loadData$: ReplaySubject<any>;
  public loadedData$: ReplaySubject<StringMap<any>>;
  public changes$: ReplaySubject<any>;
  public onControlRestored$: ReplaySubject<IRestoreControleData>;

  public isInitialized: boolean;
  public get componentKey(): string {
    return this.m_componentKey;
  }
  private m_componentKey: string;
  private m_controls: string[];
  private m_grids: string[];
  private m_restoredControls: number;
  private manualLoadManagement: boolean;
  private restoredInitComponents: StringMap<boolean> = {};


  constructor(private componentStateStorageService: ComponentStateStorageService, private columnSettingsStorageService: ColumnSettingsStorageService) {
    this.onInit$ = new ReplaySubject<any>(1);
    this.onComponentActiveStateChanged$ = new ReplaySubject<StringMap<any>>(1);
    this.loadData$ = new ReplaySubject<any>(1);
    this.loadedData$ = new ReplaySubject<StringMap<any>>(1);
    this.changes$ = new ReplaySubject<any>(1);
    this.onControlRestored$ = new ReplaySubject<IRestoreControleData>(1);
  }

  public init(componentKey: string, manualLoadManagement?: boolean): void {
    this.isInitialized = true;
    this.m_componentKey = componentKey;
    this.m_controls = [];
    this.m_grids = [];
    this.m_restoredControls = 0;
    this.manualLoadManagement = !!manualLoadManagement;
    Promise.all([
      this.componentStateStorageService.restoreServerControlsStorage(this.m_componentKey),
      this.columnSettingsStorageService.restoreServerColumnsState(this.m_componentKey)
    ]
    ).then((value: [IControlStateStorage, IColumnGroupsState]) => {
      this.restoredInitComponents = {};
      this.onInit$.next(null);
    });
  }

  public controlValueChanged(controlKey: string): void {
    this.loadData$.next(null);
  }

  public registerControl(controlKey: string): void {
    if (_.find(this.m_controls, (key: string) => key === controlKey)) {
      throw new Error(`element with same name already exist ${controlKey}`);
    }
    this.m_controls.push(controlKey);
  }

  public unregisterControl(controlKey: string): void {
    _.remove(this.m_controls, (key: string) => key === controlKey);
  }

  public registerGrid(controlKey: string): void {
    if (_.find(this.m_grids, (key: string) => key === controlKey)) {
      throw new Error(`element with same name already exist ${controlKey}`);
    }
    this.m_grids.push(controlKey);
  }

  public unregisterGrid(controlKey: string): void {
    _.remove(this.m_grids, (key: string) => key === controlKey);
  }

  public controlRestored(controlKey: string, hadStateValue?: boolean): void {
    this.m_restoredControls++;
    this.onControlRestored$.next({ controlKey: controlKey, hadStateValue: hadStateValue });
  }


  public onComponentActiveStateChanged(context: StringMap<any>): void {
    this.m_restoredControls = 0;
    this.onComponentActiveStateChanged$.next(context);
    this.loadDataAfterInit();
  }

  public loadedData(context: StringMap<any>): void {
    this.loadedData$.next(context);
  }

  public loadData(): void {
    this.loadData$.next(null);
  }

  public changes(value: any): void {
    this.changes$.next(null);
  }

  public getControlState(control: string, key?: ControlStateKey): IControlState {
    return this.componentStateStorageService.getControlState(this.m_componentKey, control, key);
  }

  public setControlState(control: string, state: IControlState, resetType: StateResetTypes, key?: ControlStateKey): void {
    return this.componentStateStorageService.setControlState(this.m_componentKey, control, state, resetType, key);
  }

  public getGridState(gridId: string, key?: ControlStateKey): IGridState {
    return this.componentStateStorageService.getGridState(this.m_componentKey, gridId, key);
  }

  public setGridState(gridId: string, state: IGridState, key?: ControlStateKey): void {
    return this.componentStateStorageService.setGridState(this.m_componentKey, gridId, state, key);
  }

  public mapValueToState(value: any, state: IControlState): void {
    this.componentStateStorageService.mapValueToState(value, state);
  }

  public mapValueFromState(state: IControlState): any {
    return this.componentStateStorageService.mapValueFromState(state);
  }

  public mapFiltersIntoState(filter: { logic: string, filters: Array<any> }): any {
    return this.componentStateStorageService.mapFiltersIntoState(filter);
  }

  public mapStateIntoFilters(filter: { logic: string, filters: Array<any> }): any {
    return this.componentStateStorageService.mapStateIntoFilters(filter);
  }


  private loadDataAfterInit(): void {
    if (this.manualLoadManagement) {
      return;
    }
    if (!this.m_controls || this.m_controls.length === 0 || this.m_controls.length === this.m_restoredControls) {
      this.loadData();
    }
  }
}

