import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { BenefitClassApiService, BenefitClassValidatorAdapter } from '../../../services/index';
import { BenefitClass } from '../../../models/index';
import { EditResult } from '../../../../../core/models/index';
import { BenefitClassDefinition } from '../../../../../organization/models/index';
import { OrgLevelWatchService } from '../../../../../organization/services/index';
import { KendoGridStateHelper, saveEvent, removeEvent } from '../../../../../common/models/index';
import { ModalService, StateManagementService, ColumnManagementService } from '../../../../../common/services/index';
import { ColorUtil, IScreenUtils, screenUtils } from '../../../../../common/utils/index';
import { InfoDialogComponent } from '../../../../../common/components/index';
import { mutableSelect } from '../../../../../core/decorators/index';
import { OrgLevel, OrgLevelType } from '../../../../../state-model/models/index';
import { subscribeToOrgLevel } from '../../../../../organization/selectors/index';
import { IServerValidationResult, IServerValidatorConf } from '../../../../../common/validators/common-validators-models';

@Component({
  moduleId: module.id,
  selector: 'slx-benefit-class-configuration',
  templateUrl: 'benefit-class-configuration.component.html',
  styleUrls: ['benefit-class-configuration.component.scss'],
  providers: [StateManagementService, ColumnManagementService]
})
export class BenefitClassConfigurationComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public appConfig: IApplicationConfig;
  public records: BenefitClass[];
  public gridState: KendoGridStateHelper<BenefitClass>;
  public canEdit: boolean;
  public pageSize = 50;
  public screenUtils: IScreenUtils;
  public columnGroup = 'BenefitClasses';

  @ViewChild('form', { static: true })
  public form: FormGroup;
  @ViewChild('kendoGrid', { static: true })
  public kendoGrid: GridComponent;
  public nameValidation = BenefitClassValidatorAdapter.benefitClassNameValidation;
  public codeValidation = BenefitClassValidatorAdapter.benefitClassCodeValidation;

  @mutableSelect(['orgLevel'])
  private orgLevel$: Observable<OrgLevel>;

  private subscriptions: StringMap<Subscription> = {};
  private orgLevel: OrgLevel;

  constructor(private apiService: BenefitClassApiService,
    private stateManagement: StateManagementService,
    private columnManagementService: ColumnManagementService,
    private modalService: ModalService,
    public benefitClassValidatorAdapter: BenefitClassValidatorAdapter
  ) {
    this.gridState = new KendoGridStateHelper<BenefitClass>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: 'benefitClassDefinition.name', dir: 'asc' }];
    this.gridState.state.take = this.pageSize;
    this.appConfig = appConfig;
    this.screenUtils = screenUtils;
  }

  public ngOnInit(): void {
    this.stateManagement.init('BenefitClassConfigurationComponent');
    this.columnManagementService.init('BenefitClassConfigurationComponent');
    this.columnManagementService.initGroup(this.columnGroup, 6);
    this.subscriptions.orgLevel = subscribeToOrgLevel(this.orgLevel$, () => this.orgLevel, (orgLevel: OrgLevel) => this.reload(orgLevel));

    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State) => {
      this.refreshGrid();
    });

    this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe((event: saveEvent<BenefitClass>) => {
      this.isLoading = true;
      if (event.isNew) {
        this.doAdd(event.dataItem);
        return;
      }
      this.doEdit(event.dataItem, event.rowIndex);
    });

    this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe((event: removeEvent<BenefitClass>) => {
      this.doRemove(event.dataItem, event.rowIndex);
    });
  }

  public async reload(orgLevel: OrgLevel): Promise<any> {
    this.isLoading = true;
    this.orgLevel = orgLevel;
    return this.apiService.getBenefitClasses(this.orgLevel.id)
      .then((records: BenefitClass[]) => {
        this.records = records;
        this.refreshGrid();
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doAdd(dataItem: BenefitClass): Promise<any> {
    return this.apiService.addBenefitClass(dataItem)
      .then((res: EditResult<BenefitClass>) => {
        if (res.isSuccess) {
          this.records.push(res.item);
          this.refreshGrid();
        } else {
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doEdit(dataItem: BenefitClass, rowIndex: number): Promise<any> {
    return this.apiService.saveBenefitClass(dataItem)
      .then((res: EditResult<BenefitClass>) => {
        const index = _.findIndex(this.records, (c: BenefitClass) => c.benefitClassDefinition.id === res.item.benefitClassDefinition.id);
        if (!res.isSuccess) {
          this.records.splice(index, 1, res.item);
          this.refreshGrid();
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        } else {
          this.records[index].modifiedOn = res.item.modifiedOn;
          this.records[index].modifiedBy = res.item.modifiedBy;
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public async doRemove(dataItem: BenefitClass, rowIndex: number): Promise<any> {
    this.isLoading = true;
    return this.apiService.deleteBenefitClass(dataItem.benefitClassDefinition.id)
      .then((res: EditResult<BenefitClass>) => {
        if (res.isSuccess) {
          const index = _.findIndex(this.records, (c: BenefitClass) => c.benefitClassDefinition.id === res.item.benefitClassDefinition.id);
          this.records.splice(index, 1);
          this.refreshGrid();
        } else {
          InfoDialogComponent.OpenDialog('Warning', res.message, this.modalService);
        }
      }).finally(() => {
        this.isLoading = false;
      });
  }

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public addClass(): void {
    let model: BenefitClass = new BenefitClass();
    model.benefitClassDefinition = new BenefitClassDefinition();
    model.benefitClassDefinition.orgLevelId = this.orgLevel.id;
    model.orgLevelName = this.orgLevel.name;
    model.orgLevelDirectionForEdit = 0;
    this.gridState.closeEditor(this.kendoGrid);
    this.kendoGrid.addRow(model);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

}
