import * as _ from 'lodash';
import * as moment from 'moment';
export var BenefitLineTypeEnum;
(function (BenefitLineTypeEnum) {
    BenefitLineTypeEnum["Standart"] = "Standart";
    BenefitLineTypeEnum["Cobra"] = "Cobra";
})(BenefitLineTypeEnum || (BenefitLineTypeEnum = {}));
var BenefitDetailsLine = /** @class */ (function () {
    function BenefitDetailsLine() {
        this.name = '';
    }
    Object.defineProperty(BenefitDetailsLine.prototype, "isStandart", {
        get: function () {
            return this.benefitLineType === BenefitLineTypeEnum.Standart;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsLine.prototype, "isCobra", {
        get: function () {
            return this.benefitLineType === BenefitLineTypeEnum.Cobra;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsLine.prototype, "startOfStartDate", {
        get: function () {
            return _.isDate(this.startDate) ? moment(this.startDate).startOf('day').toDate() : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsLine.prototype, "startOfEndDate", {
        get: function () {
            return _.isDate(this.endDate) ? moment(this.endDate).startOf('day').toDate() : null;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsLine;
}());
export { BenefitDetailsLine };
