import { appCommonRoutes, employeeSectionRoutes, userProfileRoutes } from './portal.common.routes';
import { ApplicationContainerComponent } from './components/index';
export var schedulerRoutes = [
    {
        path: '',
        component: ApplicationContainerComponent,
        children: [
            {
                path: '',
                redirectTo: 'so_console',
                pathMatch: 'full'
            }
        ].concat(appCommonRoutes)
    }
].concat(employeeSectionRoutes, userProfileRoutes);
