import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { process } from '@progress/kendo-data-query';
import { OnInit, OnDestroy } from '@angular/core';
import { appConfig } from '../../../../../app.config';
import { KendoGridStateHelper, ModalService, DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../../common';
import { PmEmployeeRosterManagementService } from '../../../services/pm-employee-roster-management.service';
import { unsubscribeAll } from '../../../../../core/decorators';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
var PmEmployeeRosterGridComponent = /** @class */ (function () {
    function PmEmployeeRosterGridComponent(devDetector, pmManagementService, modalService, activatedRoute, router) {
        this.devDetector = devDetector;
        this.pmManagementService = pmManagementService;
        this.modalService = modalService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.pageSize = 50;
        this.xlsxName = 'Employee_Performance_Roster_';
        this.pdfName = 'Employee_Performance_Roster_';
        this.columnsGroupName = 'PerformanceManagementEmployeeRoster';
        this.appConfig = appConfig;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [{ field: 'reviewRecord.reviewDate', dir: 'desc' }];
    }
    Object.defineProperty(PmEmployeeRosterGridComponent.prototype, "isLockedColumn", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    PmEmployeeRosterGridComponent.prototype.ngOnDestroy = function () { };
    PmEmployeeRosterGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        var date = this.getCurrentDate();
        var dateStr = moment(date).format(appConfig.dateFormat);
        this.xlsxName += dateStr + ".xlsx";
        this.pdfName += dateStr + ".pdf";
        this.subscriptions.export = this.pmManagementService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.dataLoaded = this.pmManagementService
            .subscribeToDataLoaded(function (container) {
            _this.records = container.records;
            _this.employee = container.employee;
            _this.refreshGrid();
            _this.exportFileName(_this.employee);
        });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    PmEmployeeRosterGridComponent.prototype.onSelectItem = function (item) {
        this.openEditReportDialog(item.reviewRecord);
    };
    PmEmployeeRosterGridComponent.prototype.onEmpNameClick = function (empId) {
        var navService = new EmployeeSectionNavigationService(this.router, this.activatedRoute);
        var urlTree = navService.getUrlTreeFromRoot(empId, true);
        var extras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(urlTree, extras);
    };
    PmEmployeeRosterGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    PmEmployeeRosterGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () {
            var data = {
                data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
            };
            _.forEach(data.data, function (r) { return (r.employee = _this.employee); });
            return data;
        };
    };
    PmEmployeeRosterGridComponent.prototype.openEditReportDialog = function (review) {
        this.pmManagementService.openReviewPopup(review);
    };
    PmEmployeeRosterGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    PmEmployeeRosterGridComponent.prototype.getCurrentDate = function () {
        return new Date();
    };
    PmEmployeeRosterGridComponent.prototype.exportFileName = function (employee) {
        if (employee && employee.name) {
            var empName = _.trim(employee.name).replace(/[\,,\s]/g, '_').replace(/_+/g, '_');
            var fileName = "Employee_" + empName + "_Review";
            this.pdfName = fileName + ".pdf";
            this.xlsxName = fileName + ".xlsx";
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmEmployeeRosterGridComponent.prototype, "subscriptions", void 0);
    return PmEmployeeRosterGridComponent;
}());
export { PmEmployeeRosterGridComponent };
