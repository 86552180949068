import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { ShiftRequestService } from '../../../services/schedule/shift-request.service';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Observable } from 'rxjs-compat';
import { DetailScreenService, } from '../../../services/index';
import * as _ from 'lodash';
var PartnerAgencyDetailsComponent = /** @class */ (function () {
    function PartnerAgencyDetailsComponent(shiftRequestService, detailScreenService) {
        this.shiftRequestService = shiftRequestService;
        this.loader = false;
        this.attributes = [];
        this.incentivePay = true;
        this.validIncentivePay = [];
        this.serval = false;
        this.detailScreenService = detailScreenService;
    }
    PartnerAgencyDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.alias = session.alias;
            }
        });
        this.agencyDetailsData = this.shiftRequestService.getAgencies();
        this.agencyDetailsList = this.shiftRequestService.setPartnerModelData(this.agencyDetailsData);
        if (this.agencyDetailsList.length > 0) {
            this.getRequestDetails();
        }
    };
    PartnerAgencyDetailsComponent.prototype.getRequestDetails = function () {
        var _this = this;
        this.loader = true;
        this.detailScreenService.getShiftRequestDetails(this.requestId, this.alias).then(function (response) {
            if (response.length > 0 && _this.agencyDetailsList.length > 0) {
                _this.agencyAttributesList = [];
                _this.agencyDetailsList.forEach(function (ele) {
                    response.forEach(function (attrItem) {
                        if (ele.partnerId == attrItem.partnerId) {
                            ele.name = _this.agencyMap[ele.agencyId];
                            ele.shiftStatus = attrItem.shiftStatus;
                            ele.attributes.forEach(function (item) {
                                attrItem.attributes.forEach(function (attr) {
                                    if (item.name == attr.name) {
                                        item.attrValues = attr.attrValues;
                                        item.value = attr.value;
                                        if (item.name == "instantAcceptManual" || item.name == "instantAcceptManual")
                                            _this.serval = true;
                                    }
                                });
                            });
                            _this.agencyAttributesList.push(ele);
                        }
                    });
                });
            }
            _this.updateCommonAttributes();
            _this.loader = false;
        });
    };
    PartnerAgencyDetailsComponent.prototype.updateCommonAttributes = function () {
        var _this = this;
        this.agencyAttributesList.forEach(function (partner) {
            var attr = _.cloneDeep(partner.attributes);
            attr.forEach(function (element) {
                if (element.name == 'incentivePay')
                    if (element.attrValues[0].name == 'No') {
                        _this.validIncentivePay[partner.partnerId] = false;
                    }
                    else {
                        _this.validIncentivePay[partner.partnerId] = true;
                    }
            });
        });
        this.agencyAttributesList.forEach(function (partner) {
            var attr = _.cloneDeep(partner.attributes);
            _this.attributes = Object.assign(attr);
            _this.attributes = partner.attributes.filter(function (e) { return e.name != "incentivepay_details_input"; });
            _this.attributes = partner.attributes.filter(function (e) { return e.name != "incentivepay_details_percent"; });
        });
    };
    PartnerAgencyDetailsComponent.prototype.verifyCommonAttributes = function (name) {
        var attr = this.attributes.filter(function (e) { return e.name == name; });
        if (attr.length > 0) {
            return true;
        }
        else {
            return false;
        }
    };
    PartnerAgencyDetailsComponent.prototype.isInstanctAccept = function (name) {
        if (name == "instant_accept") {
            return true;
        }
        else {
            return false;
        }
    };
    PartnerAgencyDetailsComponent.prototype.onPanelChange = function (partner) {
        var expand = partner.partnerExpanded;
        partner.partnerExpanded = !expand;
    };
    PartnerAgencyDetailsComponent.prototype.getDisplayName = function (name) {
        if (name == 'incentivePay') {
            return 'Incentive Pay';
        }
        else if (name == 'instantAccept') {
            return 'Instant Accept';
        }
        else if (name == "infectious") {
            return 'Infectious';
        }
        else if (name == 'premiumPay') {
            return 'Premuim Pay ';
        }
        else if (name == "incentivepayAmount" || name == "incentivepayPercent") {
            return 'Incentive Pay Details';
        }
    };
    PartnerAgencyDetailsComponent.prototype.isIncentiveDetails = function (partnerId) {
        return this.validIncentivePay[partnerId];
    };
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], PartnerAgencyDetailsComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PartnerAgencyDetailsComponent.prototype, "userSubscription", void 0);
    return PartnerAgencyDetailsComponent;
}());
export { PartnerAgencyDetailsComponent };
