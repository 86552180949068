import * as _ from 'lodash';
import * as moment from 'moment';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { process, State } from '@progress/kendo-data-query';
import { LookupService } from '../../../../organization/services/index';
import { Lookup, LookupType } from '../../../../organization/models/index';
import { Department, Organization } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { IApplicationConfig, appConfig } from '../../../../app.config';
import { ConfirmDialogComponent, ModalService } from '../../../../common/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { Holiday } from '../../../models/index';
import { HolidaysApiService } from '../../../services/index';
import { KendoGridStateHelper, KendoGridCustomSelectionHelper } from '../../../../common/models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-holidays-copy',
  templateUrl: 'holidays-copy.component.html',
  styleUrls: ['holidays-copy.component.scss'],
})

export class HolidaysCopyComponent implements OnInit, OnDestroy {

  @Input()
  public set holidays(value: Holiday[]) {
    this.m_holidays = value;
    this.refreshGrid();
  }

  public state: {
    isLoading: boolean;
    allowCopy: boolean;
  };

  public appConfig: IApplicationConfig = appConfig;
  public gridState: KendoGridStateHelper<Holiday>;
  public selectionHelper: KendoGridCustomSelectionHelper<Holiday>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public organizations: Organization[];
  public departments: Department[];

  public yearItems: any[];
  public copyToYear: any;
  public currentOrgLevel: OrgLevel;

  @Output()
  public cancel: EventEmitter<any> = new EventEmitter();

  private m_holidays: Holiday[];
  private m_copyToCenter: Organization;
  private m_copyFromYear: number;
  private m_allSelected: boolean;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  @unsubscribe()
  private selectionSubscription: Subscription;
  @unsubscribe()
  private gridStateSubscription: Subscription;

  private emptyOrganization: Organization;
  private currentOrganizationId: number;

  @ViewChild('copyActionsForm', {static: true}) private form: FormGroup;

  constructor(private lookupService: LookupService,
              private holidaysApiService: HolidaysApiService,
              private modalService: ModalService) {
    this.gridState = new KendoGridStateHelper<Holiday>();
    this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, false);
  }

  public ngOnInit(): void {
    this.state = {isLoading: false, allowCopy: false};

    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.currentOrgLevel = orgLevel;
      this.currentOrganizationId = orgLevel.relatedItemId;
      this.updateCopyToOrg();
    });
    this.emptyOrganization = new Organization();

    this.state.isLoading = true;
    this.lookupService.getLookup({lookupType: LookupType.organization}).then((value: Lookup) => {
      this.organizations = value.items;
      this.updateCopyToOrg();
      this.checkCopyConditions();
      this.state.isLoading = false;
    });

    this.selectionSubscription = this.selectionHelper.onSelectionChanged.subscribe(() => {

      if (this.selectionHelper.selectionLength > 0) {
        this.state.allowCopy = true;
      } else {
        this.state.allowCopy = false;
      }
      this.checkCopyConditions();
    });

    this.gridStateSubscription = this.gridState.onRefreshGrid.subscribe((state: State) => {
      this.refreshGrid();
    });

    this.selectionHelper.clearSelection();
    this.refreshGrid();
  }

  public ngOnDestroy(): void {
    // #issueWithAOTCompiler
  }

  public get needOrganization(): boolean {
    return this.currentOrgLevel.type !== 'Organization';
  }

  @Input()
  public set copyFromYear(value: number) {
    this.m_copyFromYear = value;

    let currentYear: number = moment().year();
    let years: number[] = _.range(currentYear, currentYear + 5, 1);
    this.yearItems = [];

    if (currentYear !== value)
      this.yearItems.push({id: value, name: value});

    _.forEach(years, (year: number) => {
      this.yearItems.push({id: year, name: year});
    });

    this.copyToYear = this.yearItems[0];
  }

  public get copyFromYear(): number {
    return this.m_copyFromYear;
  }

  public set copyToCenter(value: Organization) {
    this.m_copyToCenter = value;
  }

  public get copyToCenter(): Organization {
    return this.m_copyToCenter;
  }

  public onDoCopy(): void {

    ConfirmDialogComponent.openDialog('Warning', 'All items in the destination will be replaced with selected items', this.modalService,
      (result: boolean) => {
        if (result) {
          this.state.isLoading = true;
          this.holidaysApiService.copyHolidays(this.getSelectedIds(), this.getSelectedOrganizationId(), this.copyToYear.id).then((value: any) => {
            this.state.isLoading = false;
            _.forEach(this.holidays, (item: Holiday) => {
              item.isSelected = false;
            });
            this.selectionHelper.clearSelection();
            this.cancel.emit(false);
          }).catch((reason: any) => {
            this.state.isLoading = false;
          });
        }
      }
    );
  }

  public onDiscardCopy(): void {
    this.selectionHelper.clearSelection();
    this.cancel.emit(false);
  }

  public onTargetDepartmentChanged(): void {
    this.holidaysApiService.getHolidays(this.getSelectedOrganizationId(), this.copyToYear.id)
      .then((records: Holiday[]) => {
        _.each(this.m_holidays, (item: Holiday) => {
          item.selectable = true;
        });
      });

    this.checkCopyConditions();
  }

  public checkCopyConditions(): void {

    let allowCopy: boolean = true;

    if (this.selectionHelper.selectionLength === 0) {
      allowCopy = false;
    }

    if (!this.form.valid) {
      allowCopy = false;
    }

    if (this.getSelectedOrganizationId() === this.currentOrganizationId && this.copyToYear.id === this.copyFromYear) {
      allowCopy = false;
    }

    this.state.allowCopy = allowCopy;
  }

  private getSelectedIds(): number[] {
    let selected: number[] = [];
    _.forEach(this.m_holidays, (item: Holiday) => {
      if (item.isSelected)
        selected.push(item.id);
    });
    return selected;
  }

  private getSelectedOrganizationId(): number {
    if (this.copyToCenter && this.copyToCenter.id)
      return this.copyToCenter.id;
    return this.currentOrganizationId;
  }

  private updateCopyToOrg(): void {
    if (this.currentOrgLevel) {
      this.copyToCenter = _.find(this.organizations, {id: this.currentOrganizationId});
    }
  }

  private refreshGrid(): void {

    if (!this.m_holidays) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.m_holidays, this.gridState.state);
    this.selectionHelper.view = this.gridState.view;
  }

}
