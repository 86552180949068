import { Position, IPosition } from './position';
import { Department, IDepartment } from './department';
import {  Organization, IOrganization, } from './organization';
import { LookupEntity, ILookupEntity } from './lookup-entity';
export interface IEmployee extends ILookupEntity {
  masterId: number;
  fullName: string;
  badgeId: string;
  department: IDepartment;
  position: IPosition;
  organization: IOrganization;
  dateHired: string;
  terminationDate: string;
  type: string;
  payrollNumber: string;
}

export class Employee extends LookupEntity {
  public masterId: number;
  public badgeId: string;
  public department: Department;
  public position: Position;
  public organization: Organization;
  public dateHired: Date;
  public terminationDate: Date;
  public type: string;

  public payrollNumber: string;

  public get positionName(): string {
    return this.position ? this.position.name : '';
  }

  public get departmentName(): string {
    return this.department ? this.department.name : '';
  }

  public get organizationName(): string {
    return this.organization ? this.organization.name : '';
  }

  public get empStatus(): string {
    return this.terminationDate ? 'Terminated' : 'Active';
  }
}
