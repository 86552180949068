import { TimeclockCommunicationApiService } from './../../../services/timeclock-communication/timeclock-communication-api.service';
import { filterBy } from '@progress/kendo-data-query';
import { OrgLevel } from '../../../../../state-model/models/index';
import { LookupType } from '../../../../../organization/models/index';
import { LookupService } from '../../../../../organization/services/index';
import { appConfig } from '../../../../../app.config';
import { OnInit } from '@angular/core';
import * as _ from 'lodash';
var SpecificMessageComponent = /** @class */ (function () {
    function SpecificMessageComponent(lookupService, apiService) {
        this.isLoading = false;
        this.isFilterOn = false;
        this.lookupService = lookupService;
        this.apiService = apiService;
        this.updateList();
    }
    Object.defineProperty(SpecificMessageComponent.prototype, "orgLevel", {
        get: function () {
            return this.m_orgLevel;
        },
        set: function (value) {
            this.m_orgLevel = value;
            this.updateList();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SpecificMessageComponent.prototype, "canSend", {
        get: function () {
            return this.selectedRecords
                && this.selectedRecords.length > 0
                && this.messageText
                && this.messageText.trim().length > 0;
        },
        enumerable: true,
        configurable: true
    });
    SpecificMessageComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    SpecificMessageComponent.prototype.onToggleAllSelected = function () {
        var _this = this;
        _.forEach(this.employees, function (record) {
            record['isSelected'] = _this.isAllSelected;
        });
        this.selectionChange();
    };
    SpecificMessageComponent.prototype.selectionChange = function () {
        this.selectedRecords = [];
        this.selectedRecords = _.filter(this.employees, function (record) {
            return Boolean(record['isSelected']) === true;
        });
        this.isAllSelected = this.selectedRecords.length === this.employees.length;
    };
    SpecificMessageComponent.prototype.send = function () {
        var _this = this;
        this.isLoading = true;
        this.apiService.sendEmployeeSpecificMessage(this.messageText, this.getSelectedIds())
            .then(function (result) {
            _this.isLoading = false;
            _this.isAllSelected = false;
            _.forEach(_this.selectedRecords, function (record) {
                record['isSelected'] = false;
            });
            _this.selectedRecords = null;
        }).catch(function (error) {
            _this.isLoading = false;
        });
    };
    SpecificMessageComponent.prototype.getSelectedIds = function () {
        return _.map(this.selectedRecords, function (item) { return item.id; });
    };
    SpecificMessageComponent.prototype.updateList = function () {
        var _this = this;
        if (!this.orgLevel || !this.lookupService)
            return;
        this.lookupService.getLookup({ lookupType: LookupType.employeeList, orgLevelId: this.orgLevel.id }).then(function (lookup) {
            _this.employees = lookup.items;
            _this.gridData = filterBy(_this.employees, _this.filter);
        }).catch(function (reason) {
            _this.employees = [];
        });
    };
    SpecificMessageComponent.prototype.filterChange = function (filter) {
        this.filter = filter;
        if (this.employees != null) {
            this.gridData = filterBy(this.employees, filter);
        }
    };
    return SpecificMessageComponent;
}());
export { SpecificMessageComponent };
