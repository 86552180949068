import { DialogOptions2, DialogModeSize } from './../../../../common/models/dialog-options';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, OnInit, OnDestroy, Provider, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribe } from '../../../../core/decorators/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

import { DialogOptions, IDialog, ColumnSettingsDefinitions, ConfirmOptions, ConfirmDialog2Component } from '../../../../common/index';
import { ModalService, ColumnSettingsStorageService } from '../../../../common/services/index';
import { LookupApiService } from '../../../../organization/services/index';
import { ShiftReplacementRequest, ShiftReplacementSettings, ShiftEligibleEmployee, ShiftReplaceCmd, ShiftReplacementSms, DetailGroup } from '../../../models/index';
import { ShiftReplacementApiService, ShiftReplacementManagementService } from '../../../services/index';
import { ScheduleAbsence } from '../../../../organization/models/lookup/index';
import { ShiftOverlapHelper } from '../shift-overlap-helper';
import { NotificationsService } from './../../../../../app/core/components';

@Component({
  moduleId: module.id,
  selector: 'slx-shift-replacement-replace',
  templateUrl: 'shift-replacement-replace.component.html',
  styleUrls: ['shift-replacement-replace.component.scss']
})
export class ShiftReplacementReplaceComponent extends ShiftOverlapHelper implements IDialog, OnInit, OnDestroy {
  public options: DialogOptions;
  public dialogResult: boolean;
  public settings: ShiftReplacementSettings;
  public scheduleAbsence: ScheduleAbsence;
  public scheduleAbsenceLookup: ScheduleAbsence[];
  public selectedEmployees: ShiftEligibleEmployee[];
  public sendSmsModeOn: boolean;
  public smsData: ShiftReplacementSms;

  private shiftManagementService: ShiftReplacementManagementService;
  private appConfig: IApplicationConfig;
  private lookupApiService: LookupApiService;
  private modalService: ModalService;
  private columnSettingsStorageService: ColumnSettingsStorageService;
  private notificationService: NotificationsService;

  @unsubscribe()
  private smsModeChangeSubscription: Subscription;
  @unsubscribe()
  private selectedEmployeesSubscription: Subscription;
  @unsubscribe()
  private availabilityToSendSmsSubscription: Subscription;

  public static openDialog(request: ShiftReplacementRequest, modalService: ModalService, callback: (result: boolean, cmd: ShiftReplaceCmd) => void): ShiftReplacementReplaceComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.minHegiht = 550;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ShiftReplacementRequest,
        useValue: request
      }
    ];
    let header: string = `Replace ${request.replacedEmployeeName} for ${request.shiftName} shift on ${moment(request.date).format(appConfig.serverDateFormat)}`;
    let dialog: ShiftReplacementReplaceComponent = modalService.globalAnchor
      .openDialog2(ShiftReplacementReplaceComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        let cmd: ShiftReplaceCmd;
        if (result) {
          cmd = new ShiftReplaceCmd();
          cmd.selectedEmployee = _.head(dialog.selectedEmployees);
          cmd.scheduleAbsence = dialog.scheduleAbsence;
          cmd.dateOn = request.date;
          cmd.replacedEmployeeId = request.replacedEmployeeId;
          cmd.shiftId = request.shiftId;
          cmd.absenceCode = dialog.scheduleAbsence ? dialog.scheduleAbsence.code : null;
          cmd.comment = undefined;
        }
        callback(result, cmd);
      });
    return dialog;
  }

  constructor(
    options: DialogOptions,
    modalService: ModalService,
    lookupApiService: LookupApiService,
    request: ShiftReplacementRequest,
    columnSettingsStorageService: ColumnSettingsStorageService,
    notificationService: NotificationsService,
    shiftManagementService: ShiftReplacementManagementService, public shiftReplacementApiService: ShiftReplacementApiService
  ) {
    super();
    this.modalService = modalService;
    this.options = options;
    this.lookupApiService = lookupApiService;
    this.columnSettingsStorageService = columnSettingsStorageService;
    this.shiftManagementService = shiftManagementService;
    this.notificationService = notificationService;
    this.smsData = new ShiftReplacementSms();
    this.groupData = request.currentDayDUAData;
    this.previousDayGroupData = request.previousDayDUAData;
    this.nextDayGroupData = request.nextDayDUAData;
    this.settings = new ShiftReplacementSettings();
    this.settings.createColumns();
    this.settings.request = request;
    this.settings.context = ColumnSettingsDefinitions.SHIFT_REPLACEMENT_ADD_EMPLOYEE;
    this.settings.columns = this.columnSettingsStorageService.getColumnsState(this.settings.context.toString(), undefined, this.settings.columns);
    this.settings.isMultipleSelectionMode = false;
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.lookupApiService.getScheduleAbsences(this.settings.request.orgLevelId)
      .then((scheduleAbsenceLookup: ScheduleAbsence[]) => {
        this.scheduleAbsenceLookup = scheduleAbsenceLookup;
      });
    this.selectedEmployeesSubscription = this.shiftManagementService.subscribeToSelectedEmployees((selected: ShiftEligibleEmployee[]) => {
      this.selectedEmployees = selected;
    });
    this.smsModeChangeSubscription = this.shiftManagementService.subscribeToChangeSmsMode((sendSmsOn: boolean) => {
      this.sendSmsModeOn = sendSmsOn;
    });
    this.availabilityToSendSmsSubscription = this.shiftManagementService
      .subscribeToChangeAvailabilityToSendSms((smsData: ShiftReplacementSms) => {
        this.smsData = smsData;
      });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get isDiabledReplaceButton(): boolean {
    return _.size(this.selectedEmployees) === 0 || !this.scheduleAbsence;
  }

  public onChangedAbsences(scheduleAbsence: ScheduleAbsence): void {
    this.scheduleAbsence = scheduleAbsence;
  }

  public onCancelSend(): void {
    this.shiftManagementService.changeSmsMode(false);
  }

  public onSend(): void {
    this.shiftManagementService.sendSms(this.smsData);
  }

  public onCancelReplace(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onReplace(): void {
    let isApprovedLength = this.shiftManagementService.replaceApprovedResult.filter(x => x == true);
    if (isApprovedLength && isApprovedLength.length > 0) {
      this.shiftManagementService.replaceEmployee.replacedEmployeeId = this.shiftManagementService.replaceEmployeeId;
      this.dialogResult = false;
      const message = `This will modify a schedule in an approved pay period and impact the PBJ     Calculation for the employee, are you sure you want to continue?`;
      let popupOptions: ConfirmOptions = new ConfirmOptions();
      popupOptions.showCancel = true;
      popupOptions.showOK = true;
      popupOptions.buttonOKtext = 'Ok';
      popupOptions.buttonCanceltext = 'Cancel';
      ConfirmDialog2Component.openDialog(
        'Warning',
        message,
        this.modalService,
        (result: boolean) => {
          if (result) {
            this.shiftManagementService.replaceApprovedResult = [];
            this.checkOverlapForReplace();
          }
        });
    }
    else {
      this.checkOverlapForReplace();
    }
  }

  public checkOverlapForReplace() {
    if (this.selectedEmployees) {
      if (this.isCurrentDayShiftOverlapped()
        || this.isPreviousDayShiftOverlapped()
        || this.isNextDayShiftOverlapped()
        || this.isCurrentDayReplacedShiftOverlapped()
        || this.isPreviousDayReplacedShiftOverlapped()
        || this.isNextDayReplacedShiftOverlapped()
        || this.isPrimarySecondaryShiftsOverlap()) {
        this.notificationService.warn('Overlap', 'This employee has an overlap shift');
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
        return;
      }
      else {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
      }
    }
  }
}
