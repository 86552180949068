import * as _ from 'lodash';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
export function isMasterScheduleEntryCell(value) {
    if (!value) {
        return false;
    }
    return !!value.dateOn && !!value.shiftsInfo;
}
var MasterScheduleEntryCell = /** @class */ (function () {
    function MasterScheduleEntryCell() {
    }
    Object.defineProperty(MasterScheduleEntryCell.prototype, "unitName", {
        get: function () {
            if (this.m_unitName === undefined) {
                this.m_unitName = _.join(_.map(this.shiftsInfo, function (info) {
                    if (info.absenceCode) {
                        return info.absenceCode;
                    }
                    if (info.unitName) {
                        return info.unitName;
                    }
                    return '-';
                }), ',');
            }
            return this.m_unitName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleEntryCell.prototype, "shiftName", {
        get: function () {
            if (this.m_shiftName === undefined) {
                this.m_shiftName = _.join(_.map(this.shiftsInfo, function (info) {
                    if (info.absenceCode) {
                        return info.absenceCode;
                    }
                    return info.shiftName;
                }), ',');
            }
            return this.m_shiftName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleEntryCell.prototype, "shiftTimes", {
        get: function () {
            if (this.m_shiftTimes === undefined) {
                this.m_shiftTimes = _.join(_.map(this.shiftsInfo, function (info) {
                    if (info.absenceCode) {
                        return info.absenceCode;
                    }
                    return moment(info.shiftStart).format(appConfig.linkTimeFormat) + "-" + moment(info.shiftEnd).format(appConfig.linkTimeFormat);
                }), ',');
            }
            return this.m_shiftTimes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleEntryCell.prototype, "shiftNameAndUnit", {
        get: function () {
            if (this.m_shiftNameAndUnit === undefined) {
                this.m_shiftNameAndUnit = _.join(_.map(this.shiftsInfo, function (info) {
                    if (info.absenceCode) {
                        return info.absenceCode;
                    }
                    return info.shiftName + "|" + info.unitName;
                }), ',');
            }
            return this.m_shiftNameAndUnit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleEntryCell.prototype, "shiftDurationHours", {
        get: function () {
            if (this.m_shiftDurationHours === undefined) {
                var val = _.reduce(this.shiftsInfo, function (acc, info) {
                    return acc + info.duration / 3600000;
                }, 0);
                this.m_shiftDurationHours = val.toFixed(2);
            }
            return this.m_shiftDurationHours;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MasterScheduleEntryCell.prototype, "shiftDurationMin", {
        get: function () {
            if (this.m_shiftDurationMin === undefined) {
                var val = _.reduce(this.shiftsInfo, function (acc, info) {
                    return acc + info.duration / 60000;
                }, 0);
                this.m_shiftDurationMin = val.toFixed(1);
            }
            return this.m_shiftDurationMin;
        },
        enumerable: true,
        configurable: true
    });
    MasterScheduleEntryCell.prototype.clearNames = function () {
        this.m_unitName = undefined;
        this.m_shiftName = undefined;
        this.m_shiftTimes = undefined;
        this.m_shiftNameAndUnit = undefined;
        this.m_shiftDurationHours = undefined;
        this.m_shiftDurationMin = undefined;
    };
    return MasterScheduleEntryCell;
}());
export { MasterScheduleEntryCell };
var MasterScheduleShiftInfo = /** @class */ (function () {
    function MasterScheduleShiftInfo() {
    }
    return MasterScheduleShiftInfo;
}());
export { MasterScheduleShiftInfo };
export function isMasterScheduleTotalCell(value) {
    if (!value) {
        return false;
    }
    return !!value.dateOn && _.isNumber(value.totalValue);
}
var MasterScheduleTotalCell = /** @class */ (function () {
    function MasterScheduleTotalCell() {
    }
    Object.defineProperty(MasterScheduleTotalCell.prototype, "value", {
        get: function () {
            if (this.isViewFteTotal) {
                return this.fteTotalValue.toFixed(2);
            }
            else {
                return this.totalValue.toFixed(2);
            }
        },
        enumerable: true,
        configurable: true
    });
    return MasterScheduleTotalCell;
}());
export { MasterScheduleTotalCell };
