export interface IBenefitsEmpEnrollmentFlat {
    lineId: number;
    tierId: number;
    tierName: string;
    erContribution: number;
    empContribution: number;
}

export class BenefitsEmpEnrollmentFlat {
    public lineId: number;
    public tierId: number;
    public tierName: string;
    public erContribution: number;
    public empContribution: number;
}