import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ManagementBaseService } from '../../../core/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ChangeManagementService } from '../../../common/services/index';
import { PbjOrganizationModel } from '../../models/index';
import { PbjOrganizationsApiService } from './pbj-organizations-api.service';
var PbjOrganizationsConfigurationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PbjOrganizationsConfigurationManagementService, _super);
    function PbjOrganizationsConfigurationManagementService(changeService, api) {
        var _this = _super.call(this) || this;
        _this.changeService = changeService;
        _this.api = api;
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.onItemSaved$ = new ReplaySubject();
        _this.viewRefresh$ = new Subject();
        return _this;
    }
    Object.defineProperty(PbjOrganizationsConfigurationManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PbjOrganizationsConfigurationManagementService.prototype, "isEditingNewItem", {
        // user can't add new items in this component
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    PbjOrganizationsConfigurationManagementService.prototype.init = function () {
        var _this = this;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (_.isNumber(orgLevel.id)) {
                _this.currentOrgLevel = orgLevel;
                _this.fetchRecords();
            }
        });
    };
    PbjOrganizationsConfigurationManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    //#region IConfigurationManagementService implementation
    // no add or remove actions planned for this component
    PbjOrganizationsConfigurationManagementService.prototype.onRemoveItem = function (itemToDelete) {
        _.noop();
    };
    PbjOrganizationsConfigurationManagementService.prototype.onAddItem = function (item) {
        _.noop();
    };
    PbjOrganizationsConfigurationManagementService.prototype.setSelectedCount = function (count) {
        _.noop();
    };
    PbjOrganizationsConfigurationManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    PbjOrganizationsConfigurationManagementService.prototype.onCancelEditItem = function () {
        this.changeService.clearChanges();
        this.editingItem = null;
        this.editItemCmd$.next(null);
    };
    PbjOrganizationsConfigurationManagementService.prototype.onSaveItem = function (info) {
        this.updateItem(info.dataItem);
    };
    //#endregion IConfigurationManagementService implementation
    PbjOrganizationsConfigurationManagementService.prototype.fetchRecords = function () {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getPbjOrganizations()
            .then(function (result) {
            _this.changeService.clearChanges();
            _this.m_container = result;
            _this.editingItem = null;
            _this.onLoaded$.next(_this.m_container);
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PbjOrganizationsConfigurationManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.saveOrganization(item, item.pbjOrganization.id)
            .then(function () {
            _this.changeService.clearChanges();
            _this.onItemSaved$.next(_this.editingItem);
            _this.editingItem = null;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PbjOrganizationsConfigurationManagementService.prototype.updateOrganizationModel = function (from, to) {
        to.pbjOrganization = from.pbjOrganization;
        to.lastUpdateDate = from.lastUpdateDate;
        to.lastUpdateUsername = from.lastUpdateUsername;
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PbjOrganizationsConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PbjOrganizationsConfigurationManagementService.prototype, "orgLevelSubscription", void 0);
    return PbjOrganizationsConfigurationManagementService;
}(ManagementBaseService));
export { PbjOrganizationsConfigurationManagementService };
