import { Component, OnInit, Input, ViewEncapsulation, forwardRef } from '@angular/core';
import * as _ from 'lodash';

import { appConfig, IApplicationConfig } from '../../../../app.config';

import { PositionModel, EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-position-renderer',
  templateUrl: 'configure-position-renderer.component.html',
  styleUrls: ['configure-position-renderer.component.scss'],
  providers: [
    {
      provide: CustomListActorBase,
      useExisting: forwardRef(() => ConfigurePositionRendererComponent)
    }
  ]
})
export class ConfigurePositionRendererComponent extends CustomListActorBase implements OnInit {

  @Input()
  public set context(value: any) {
    this.item = value.item;
    this.rowIndex = value.rowIndex;
  }

  @Input()
  public item: PositionModel;

  @Input()
  public editable: boolean = true;

  @Input()
  public selectable: boolean = true;

  @Input()
  public rowIndex: number;

  private appConfig: IApplicationConfig;

  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public onEditClick(): void {
    this.actionEmitter.emit(EditableListActionKind.START_EDIT);
  }

  public onItemSelect(): void {
    if (this.item) this.item.isSelected = !this.item.isSelected;
    this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
  }
  
  public onDeletedItems(): void {
    this.actionEmitter.emit(EditableListActionKind.REMOVE);
  }

}
