import { AcuityConfiguration } from "./acuity";

export interface ICensusCategoryConfigRecord {
  name: string;
  unit: string;
  value: string;
  sortId: number;
  includedInTotal: boolean;
}

export interface ICensusCategoryConfig {
  allowToExcludeFromTotals: boolean;
  orgLevelId: number;
  records: ICensusCategoryConfigRecord[];
}

export class CensusCategoryConfigRecord {
  public name: string;
  public unit: string;
  public value: string;
  public sortId: number;
  public includedInTotal: boolean;
  public get excludeFromTotal(): boolean {
    return !this.includedInTotal;
  }
  public set excludeFromTotal(value: boolean) {
    this.includedInTotal = !value;
  }
}

export class CensusCategoryConfig {
  allowToExcludeFromTotals: boolean;
  orgLevelId: number;
  records: CensusCategoryConfigRecord[];
}

export class sendCensusCategoryWithCensus {
  public configData: CensusCategoryConfig;
  public censusData: AcuityConfiguration;
}
