import { IConfigutrationContainer } from '../configiration-container.interface';
import { PayCodeModel, IPayCodeModel } from './pay-code.model';
import { Actions } from '../../../core/models/index';
import { IPayCodeDTO } from '..';

export class PayCodesContainer implements IConfigutrationContainer {
    public records: PayCodeModel[];
    public actions: Actions;
    public canEditExceptionFlag: boolean;
    public canEditPaycodestoAccrualMappingFlag: boolean;
}
