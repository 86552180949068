import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { appConfig } from '../../../../app.config';
import { LookupService } from '../../../../organization/services/index';
import { LookupType } from '../../../../organization/models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { Holiday, HolidayRule } from '../../../models/index';
import { ModalService } from '../../../../common/services/index';
import { NgForm } from '@angular/forms';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { HolidaysConfigurationManagementService } from '../../../services/holidays/holidays-configuration-management.service';
var HolidaysEditorComponent = /** @class */ (function () {
    function HolidaysEditorComponent(lookupService, modalService, management) {
        this.modalService = modalService;
        this.management = management;
        this.appConfig = appConfig;
        this.prohibitedNameValues = [];
        this.lookupService = lookupService;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
    }
    Object.defineProperty(HolidaysEditorComponent.prototype, "item", {
        set: function (value) {
            this.m_item = value;
            if (this.m_item) {
                this.editingItem = new Holiday();
                this.copyFields(value, this.editingItem);
            }
            else {
                this.editingItem = new Holiday();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "year", {
        set: function (value) {
            this.m_year = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "holiday", {
        get: function () {
            return this.editingItem;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "minDate", {
        get: function () {
            return this.m_minDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "maxDate", {
        get: function () {
            return this.m_maxDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "mainForm", {
        get: function () {
            return this.m_mainForm;
        },
        set: function (value) {
            var _this = this;
            this.m_mainForm = value;
            if (this.m_mainForm && !this.mainFormSubscription) {
                this.mainFormSubscription = this.mainForm.statusChanges.subscribe(function () {
                    if (_this.mainForm.dirty) {
                        _this.management.markAsDirty();
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    HolidaysEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.lookupService.getLookup({ lookupType: LookupType.holidayOrigins })
            .then(function (holidayOrigins) {
            _this.holidayOrigins = holidayOrigins.items;
        });
        this.lookupService.getLookup({ lookupType: LookupType.holidayDayPatterns })
            .then(function (holidayDayPatterns) {
            _this.holidayDayPatterns = holidayDayPatterns.items;
        });
        this.lookupService.getLookup({ lookupType: LookupType.daysOfWeek })
            .then(function (days) {
            _this.days = days.items;
        });
        this.lookupService.getLookup({ lookupType: LookupType.months })
            .then(function (months) {
            _this.months = months.items;
        });
        this.m_minDate = new Date(this.m_year - 1, 11, 31);
        this.m_maxDate = new Date(this.m_year + 1, 0, 1);
        if (!this.editingItem.id) {
            this.editingItem.date.setFullYear(this.m_year);
            this.editingItem.start.setFullYear(this.m_year);
            this.editingItem.end.setFullYear(this.m_year);
        }
    };
    HolidaysEditorComponent.prototype.ngOnDestroy = function () {
        if (this.mainFormSubscription) {
            this.mainFormSubscription.unsubscribe();
            this.mainFormSubscription = null;
        }
    };
    HolidaysEditorComponent.prototype.onSave = function (e) {
        var _this = this;
        e.preventDefault();
        if (this.holidays) {
            var existingDate = void 0;
            if (this.editingItem.id) {
                existingDate = _.find(this.holidays, function (h) {
                    return _this.editingItem.id !== h.id && moment(h.date).isSame(_this.editingItem.date, 'day');
                });
            }
            else {
                existingDate = _.find(this.holidays, function (h) {
                    return moment(h.date).isSame(_this.editingItem.date, 'day');
                });
            }
            if (existingDate) {
                this.modalService.globalAnchor.openInfoDialog('Warning', "The date " + dateTimeUtils.convertToDtoString(existingDate.date) + " has already been entered into the calendar");
                return;
            }
        }
        this.copyFields(this.editingItem, this.m_item);
        this.save.next(true);
    };
    HolidaysEditorComponent.prototype.onCancel = function (e) {
        e.preventDefault();
        this.cancel.next(false);
    };
    Object.defineProperty(HolidaysEditorComponent.prototype, "origin", {
        get: function () {
            var _this = this;
            if (this.holiday) {
                return _.find(this.holidayOrigins, function (value) { return value.id === _this.holiday.origin; });
            }
            return null;
        },
        set: function (value) {
            this.holiday.origin = value.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "ruleDayDefiningPattern", {
        get: function () {
            var _this = this;
            if (this.rule) {
                return _.find(this.holidayDayPatterns, function (value) { return value.id === _this.rule.dayDefiningPattern; });
            }
            return null;
        },
        set: function (value) {
            if (!this.rule)
                this.rule = new HolidayRule();
            this.rule.dayDefiningPattern = value.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "ruleDayOfWeek", {
        get: function () {
            var _this = this;
            if (this.rule) {
                return _.find(this.days, function (value) { return value.name === _this.rule.dayOfWeek; });
            }
            return null;
        },
        set: function (value) {
            if (!this.rule)
                this.rule = new HolidayRule();
            this.rule.dayOfWeek = value.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HolidaysEditorComponent.prototype, "ruleMonth", {
        get: function () {
            var _this = this;
            if (this.rule) {
                return _.find(this.months, function (value) { return value.id === _this.rule.month; });
            }
            return null;
        },
        set: function (value) {
            if (!this.rule)
                this.rule = new HolidayRule();
            this.rule.month = value.id;
        },
        enumerable: true,
        configurable: true
    });
    HolidaysEditorComponent.prototype.holidayDateChanged = function (e) {
        if (this.holiday.date) {
            var d = this.holiday.date.getTime();
            this.holiday.start = new Date(d);
            this.holiday.end = new Date(d);
        }
    };
    HolidaysEditorComponent.prototype.autoPopulateDate = function () {
        if (this.ruleDayDefiningPattern && this.ruleDayOfWeek && this.ruleMonth) {
            var weekOfMonth = this.ruleDayDefiningPattern.momentId;
            var dayNumber = this.ruleDayOfWeek.dayNumber - 1;
            var month = this.ruleMonth.id - 1;
            var control = this.getPureDateFromRule(0, dayNumber, month, this.m_year);
            if (control.getMonth() !== month) {
                weekOfMonth++;
            }
            var date = this.getPureDateFromRule(weekOfMonth, dayNumber, month, this.m_year);
            if (date.getMonth() !== month) {
                date = this.getPureDateFromRule(weekOfMonth - 1, dayNumber, month, this.m_year);
            }
            this.holiday.date = date;
            this.holidayDateChanged(null);
            this.holiday.end.setDate(this.holiday.end.getDate() + 1);
            this.m_minDate = new Date(this.m_year - 1, 11, 31);
            this.m_maxDate = new Date(this.m_year + 1, 0, 1);
        }
    };
    HolidaysEditorComponent.prototype.getPureDateFromRule = function (weekOfMonth, dayNumber, month, year) {
        return moment().year(year).month(month).date(1).day(dayNumber + weekOfMonth * 7).toDate();
    };
    Object.defineProperty(HolidaysEditorComponent.prototype, "rule", {
        get: function () {
            if (!this.holiday)
                return null;
            return this.holiday.rule;
        },
        set: function (value) {
            this.holiday.rule = value;
        },
        enumerable: true,
        configurable: true
    });
    HolidaysEditorComponent.prototype.copyFields = function (from, to) {
        to.id = from.id;
        to.date = moment(from.date).toDate();
        to.start = moment(from.start).toDate();
        to.end = moment(from.end).toDate();
        to.code = from.code;
        to.type = from.type;
        to.origin = from.origin;
        to.name = from.name;
        if (from.rule) {
            to.rule = new HolidayRule();
            to.rule.month = from.rule.month;
            to.rule.dayOfWeek = from.rule.dayOfWeek;
            to.rule.dayDefiningPattern = from.rule.dayDefiningPattern;
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HolidaysEditorComponent.prototype, "mainFormSubscription", void 0);
    return HolidaysEditorComponent;
}());
export { HolidaysEditorComponent };
