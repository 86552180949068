export * from './geolocation.component';
export * from './geolocation-toolbar/geolocation-toolbar.component';
export * from './geolocation-map/geolocation-map.component';
export * from './geolocation-grid/geolocation-grid.component';
export * from './geolocation-form/geolocation-form.component';
export * from './geolocation-dialog/geolocation-dialog.component';


import { GeolocationComponent } from './geolocation.component';
import { GeolocationToolbarComponent } from './geolocation-toolbar/geolocation-toolbar.component';
import { GeolocationMapComponent } from './geolocation-map/geolocation-map.component';
import { GeolocationGridComponent } from './geolocation-grid/geolocation-grid.component';
import { GeolocationFormComponent } from './geolocation-form/geolocation-form.component';
import { GeolocationDialogComponent } from './geolocation-dialog/geolocation-dialog.component';


export const geolocationComponents: any[] = [
  GeolocationComponent,
  GeolocationToolbarComponent,
  GeolocationMapComponent,
  GeolocationGridComponent,
  GeolocationFormComponent,
  GeolocationDialogComponent
 
];
