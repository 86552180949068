import * as moment from 'moment';
import * as _ from 'lodash';
import { DateRange } from '../../core/models/index';

export class DateRangeWithDate extends DateRange {
 public selectedDate: Date;
 constructor(selectedDate: Date, startDate: Date, endDate: Date) {
   super(startDate, endDate);

   this.selectedDate = selectedDate;
 }
}


export function isSameRanges(range1: DateRangeWithDate, range2: DateRangeWithDate): boolean {
  const isCorrectRange1: boolean = isCorrectRange(range1);
  const isCorrectRange2: boolean = isCorrectRange(range2);

  return !isCorrectRange1 && !isCorrectRange2 ||
    isCorrectRange1 && isCorrectRange2 &&
    moment(range1.selectedDate).isSame(range2.selectedDate) &&
    moment(range1.startDate).isSame(range2.startDate) &&
    moment(range1.endDate).isSame(range2.endDate);
}

export function isCorrectRange(range: DateRangeWithDate): boolean {
  return _.isObject(range) && _.isDate(range.selectedDate) && _.isDate(range.startDate) && _.isDate(range.endDate);
}
