import { ChangeManagementService } from './../../../../../../common/services/change-management/change-management.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AddEmployeeWizardActions } from '../../../../store/add-employee-wizard/add-employee-wizard.actions';
import { AddEmployeeModel, AddEmployeeRestrictions } from '../../../../models/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { LookupApiService } from '../../../../../../organization/services/lookup/lookup-api.service';
import { EmpType, Organization } from '../../../../../../organization/models/lookup/index';
import { OrgLevel } from '../../../../../../state-model/models/index';
import { mutableSelect, unsubscribe } from '../../../../../../core/decorators/index';
import { AddEmployeeWizardStatus } from '../../../../store/add-employee-wizard/add-employee-wizard.types';
import { EmployeeActivitiesApiService } from '../../../../services/employee-activities/employee-add-api.service';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { AddEmployeeNavigationService } from '../../../../../../common/services/navigation/add-employee-navigation.service';


@Component({
  moduleId: module.id,
  selector: 'slx-add-employee',
  templateUrl: 'add-employee.component.html',
  styleUrls: ['add-employee.component.scss']
})
export class AddEmployeeComponent implements OnInit, OnDestroy {
  @mutableSelect(['addEmployeeWizard', 'status'])
  public addEmployeeWizard$: Observable<AddEmployeeWizardStatus>;

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;

  public state: {
    isOrganizationsLoading: boolean;
    isEmployeeAdding: boolean;
    isClearAllEnable: boolean;
  };
  public currentOrgLevel: OrgLevel;

  public get isLoading(): boolean {
    return this.state.isOrganizationsLoading || this.state.isEmployeeAdding;
  }

  public get clearAllEnable(): boolean {
    return this.state.isClearAllEnable;
  }

  public organizationLookup: Organization[];
  public addEmployeeRestrictions: AddEmployeeRestrictions;

  private navigationService: AddEmployeeNavigationService;
  private addEmployeeWizardSubscription: Subscription;
  @unsubscribe()
  private routeSubscripion: Subscription;

  constructor(
    private addEmployeeWizardActions: AddEmployeeWizardActions,
    private lookupApiService: LookupApiService,
    private employeeActivitiesApiService: EmployeeActivitiesApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private changeService: ChangeManagementService) {
    this.navigationService = new AddEmployeeNavigationService(this.router, this.activatedRoute);
    this.state = {
      isOrganizationsLoading: false,
      isEmployeeAdding: false,
      isClearAllEnable: true
    };
  }

  public ngOnInit(): void {
    this.changeService.setCurrentComponentId('add_employee');
    this.addEmployeeWizardActions.startWizard(new AddEmployeeModel());
    this.orgLevel$.subscribe((storedOrgLevel: OrgLevel) => {
      this.currentOrgLevel = storedOrgLevel;
    });
    this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe((status: AddEmployeeWizardStatus) => {
      if (status === AddEmployeeWizardStatus.started) {
        this.state.isClearAllEnable = true;
      }

      if (status === AddEmployeeWizardStatus.pending) {
        this.state.isEmployeeAdding = true;
      }
      if (status === AddEmployeeWizardStatus.success) {
        this.state.isEmployeeAdding = false;
        this.changeService.clearChanges();
        this.state.isClearAllEnable = false;
      }
      if (status === AddEmployeeWizardStatus.error) {
        this.state.isEmployeeAdding = false;
        this.state.isClearAllEnable = true;
      }
      if (status === AddEmployeeWizardStatus.validationFailed) {
        this.state.isEmployeeAdding = false;
        this.state.isClearAllEnable = true;
      }
    });
    this.clearAllFields();
    this.loadLookups();
  }

  public ngOnDestroy(): void {
    if (this.addEmployeeWizardSubscription) {
      this.addEmployeeWizardSubscription.unsubscribe();
    }
  }

  public loadLookups(): void {
    if (!this.organizationLookup) {
      this.loadOrganizationsLookup();
    }
  }

  public loadOrganizationsLookup(): void {
    this.state.isOrganizationsLoading = true;
    this.lookupApiService.getUserAccesibleOrganizations()
      .then((organizationLookup: Organization[]) => {
        this.organizationLookup = organizationLookup;
        this.state.isOrganizationsLoading = false;
      });
  }

  public loadRestrictions(model: AddEmployeeModel): void {
    if(model.payrollNumber == null){
      this.employeeActivitiesApiService.getAddEmployeeProperties(model.organization.orgLevelId)
      .then((addEmployeeRestrictions: AddEmployeeRestrictions) => {
        this.addEmployeeRestrictions = addEmployeeRestrictions;
        if (!model.payrollNumber || model.payrollNumber === model.defaultPayrollNumber) {
          model.payrollNumber = this.addEmployeeRestrictions.payrollNumber.defaultFieldValue;
        }
        model.defaultPayrollNumber = this.addEmployeeRestrictions.payrollNumber.defaultFieldValue;
      });
    }
  }

  public clearAllFields(): void {
    this.changeService.clearChanges();
    this.addEmployeeWizardActions.clearWizard();
    this.addEmployeeWizardActions.startWizard(new AddEmployeeModel());
  }

  public onFormChanged(): void {
    this.changeService.changeNotify();
  }
}
