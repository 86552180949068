import { EmployeeTerminationReason } from './../../../../organization/models/lookup/employee-termination-reason';
import { FieldData } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';
//import { EmployeeSectionsMeta } from '../index';

export interface IEmployeeSectionsTerminationHistoryEntries {
  terminations: IEmployeeSectionsTerminationHistoryEntry[];
}
export interface IEmployeeSectionsTerminationHistoryEntry {
  terminationDate: string;
  terminatedBy: string;
  doNotRehire: boolean;
  voluntaryTerm: boolean;
  reason: string;
  notes: string;
  emp_Id: number;
  term_History_Id: number
}

export class EmployeeSectionsTerminationHistoryEntry {
  public terminationDate: FieldData<Date>;
  public terminatedBy: FieldData<string>;
  public doNotRehire: FieldData<boolean>;
  public voluntaryTerm: FieldData<boolean>;
  public reason: FieldData<EmployeeTerminationReason>;
  public notes: FieldData<string>;
  public emp_Id: number;
  public term_History_Id: number;
}

export class EmployeeSectionsTerminationHistoryEntries extends EmployeeSectionsBase {
  public terminationEntries: EmployeeSectionsTerminationHistoryEntry[];

  constructor() {
    super();
    this.terminationEntries = [];
  }

  // public static metaMock: EmployeeSectionsMeta = {
  //   fields: [
  //     {
  //       fieldName: "terminationDate",
  //       access: "full",
  //       fieldLimitInfo: null
  //     },
  //     {
  //       fieldName: "terminatedBy",
  //       access: "full",
  //       fieldLimitInfo: null
  //     },
  //     {
  //       fieldName: "doNotRehire",
  //       access: "full",
  //       fieldLimitInfo: null
  //     },
  //     {
  //       fieldName: "voluntaryTerm",
  //       access: "full",
  //       fieldLimitInfo: null
  //     },
  //     {
  //       fieldName: "reason",
  //       access: "full",
  //       fieldLimitInfo: null
  //     },
  //     {
  //       fieldName: "notes",
  //       access: "full",
  //       fieldLimitInfo: null
  //     }
  //   ],
  //   actions: null,
  //   error: null
  // };

  // public static dataMock: IEmployeeSectionsTerminationHistoryEntries = {
  //   terminations: [
  //         {
  //           terminationDate: new Date(),
  //           terminatedBy: "system              ",
  //           doNotRehire: false,
  //           voluntaryTerm: true,
  //           reason: "test reason1",
  //           notes: "test note1"
  //         },
  //         {
  //           terminationDate: new Date(),
  //           terminatedBy: "system              ",
  //           doNotRehire: false,
  //           voluntaryTerm: true,
  //           reason: "test reason2",
  //           notes: "test note2"
  //         }
  //       ]
  // };
}

