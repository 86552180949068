import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../../app/app.config';
import { ModalService } from '../../../../../../common/services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitEmployeeManagementService } from './../../../../services/benefit-employees/index';
import { ConfirmOptions, ConfirmDialog2Component } from './../../../../../../common/components/index';
var BenefitOptionOptionsGridComponent = /** @class */ (function () {
    function BenefitOptionOptionsGridComponent(manService, modalService) {
        this.manService = manService;
        this.modalService = modalService;
        this.columnGroup = 'BenefitsOptionOptionsGrid';
        this.isLoading = true;
        this.pageSize = 50;
        this.subscriptions = {};
        this.noCovOptions = 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.';
        this.noCovOptionContribution = 'Employees and/or Employer contributions have to be greater than 0';
        this.isFormValid = true;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.selectableSettings = {
            checkboxOnly: true
        };
        this.appConfig = appConfig;
        this.onEnrollmentCancel = new EventEmitter();
    }
    BenefitOptionOptionsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.refreshGrid();
        if (_.size(this.benefitOptionData) === 0) {
            this.showWarningMessage(this.noCovOptions);
        }
        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(function () {
            _this.isFormValid = _this.ngForm.valid;
            _this.manService.benefitsOptionTabDetailsChanged(!_this.isFormValid);
        });
    };
    BenefitOptionOptionsGridComponent.prototype.ngOnDestroy = function () { };
    BenefitOptionOptionsGridComponent.prototype.updateRowsData = function () {
        var _this = this;
        this.manService.updateEmployeesUpdatedInfo(this.selectedEmployees);
        if (_.size(this.selectedEmployees) > 0) {
            var enrollmentIsValid = _.every(this.selectedEmployees, function (e) { return _this.manService.isValidCovOption(e); });
            this.manService.benefitsOptionTabDetailsChanged(!this.isFormValid || !enrollmentIsValid);
        }
    };
    BenefitOptionOptionsGridComponent.prototype.onOptionValueChange = function (item) {
        item.benefitTierOptionId = item.optionCode.id;
        item.code = item.optionCode.code;
        this.updateRowsData();
    };
    BenefitOptionOptionsGridComponent.prototype.showWarningMessage = function (message) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Warning', message, this.modalService, function (result) {
            if (result) {
                _this.onEnrollmentCancel.emit();
            }
        }, options);
    };
    BenefitOptionOptionsGridComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.selectedEmployees) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.selectedEmployees, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitOptionOptionsGridComponent.prototype, "subscriptions", void 0);
    return BenefitOptionOptionsGridComponent;
}());
export { BenefitOptionOptionsGridComponent };
