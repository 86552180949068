import * as _ from 'lodash';
import * as moment from 'moment';
import { WcReport, ReportCommonFields, WcLookupsContainer } from '../../models';
import { wcConfig } from '../../workers-compensation.config';

export class ReportFormBase {

    public get isCreatingNew(): boolean {
        return this.report && !_.isFinite(this.report.id);
    }

    public get common(): ReportCommonFields {
        return this.report ? this.report.common : null;
    }

    public report: WcReport;
    public lookups: WcLookupsContainer;
    public phoneMaskConf: any;

    public paymentFreqOptions: string[] = [
        'Weekly', 'Semi-Monthly', 'Every Two Weeks', 'Monthly'
    ]

    constructor () {
        this.phoneMaskConf = wcConfig.settings.phoneMask;
    }

    public onIsDeadChanged(enable: boolean): void {
        if (this.common) {
            this.common.dateOfDeath = enable ? moment().toDate() : null;
        }
    }


}