<slx-spinner [show]="isLoading">
  <section class="slx-high-box">
    <header class="slx-content-toolbar-indents">
      <slx-pbj-reconciliation-toolbar type="employee"></slx-pbj-reconciliation-toolbar>
    </header>
    <div class="slx-high-box__body slx-main-content-indents">
      <h3 class="pbj-rec-header">
        <i class="pbj-rec-header__icon fas fa-id-badge fa-users" aria-hidden="true"></i>
        <span class="pbj-rec-header__text">{{ orgLevel?.name }} - {{ currentEmpName }}</span>
      </h3>
      <div class="pbj-rec-grids">
        <slx-pbj-reconciliation-daily-grid></slx-pbj-reconciliation-daily-grid>
      </div>
    </div>
  </section>
</slx-spinner>
