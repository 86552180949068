<div class="leave-request-confirm">
  <div class="modal-content">
    <div class="modal-body">
      <slx-input-decorator class="slx-auto-height">
        <textarea slx-input maxlength="50" rows="3" autocomplete="off" name="comments" placeholder="Comments" [(ngModel)]="entry.comment" autofocus></textarea>
        <span errorMessage for="maxlength"></span>
      </slx-input-decorator>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onOk()" class="theme-button-apply">{{ entry.buttonText }}</button>
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Cancel</button>
    </div>
  </div>
</div>
