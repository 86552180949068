import * as _ from 'lodash';
import * as moment from 'moment';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';

export class LmNavigationService {

  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToConsole(orgLevelId?: number): void {
    let queryParams: any = {};
    if (!!orgLevelId) {
      queryParams.orgLevelId = orgLevelId;
    }
    this.router.navigateByUrl(this.getUrlTree(queryParams));
  }

  private getUrlTree(params: any): UrlTree {
    if (this.route.pathFromRoot.length > 3) {
      return this.router.createUrlTree(['loa_console'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
    } else {
      return this.router.createUrlTree(['hr', 'loa_console'], { relativeTo: this.route.pathFromRoot[1], queryParams: params });
    }
  }

  public navigateToLmRoster(orgLevelId?: number, category?: string, type?: string): void {
    let queryParams: any = this.createQueryParamsForRoster(orgLevelId, category, type);
    this.router.navigateByUrl(this.getUrlTreeForRoster(queryParams));
  }

  public navigateToLmRosterWithAction(orgLevelId: number, actionId: string, empId: number) {
    let queryParams: any = this.createQueryParamsForRosterWithAction(orgLevelId, actionId, empId);
    this.router.navigateByUrl(this.getUrlTreeForRoster(queryParams));
  }

  public createQueryParamsForRosterWithAction(orgLevelId?: number, action?: string, empId?: number): { orgLevelId?: number, category?: string, type?: string } {
    let queryParams: any = {};
    if (!!orgLevelId) {
      queryParams.orgLevelId = orgLevelId;
    }
    if (!!action) {
      queryParams.action = action;
    }
    if (!!empId) {
      queryParams.empId = empId;
    }
    return queryParams;
  }

  public createQueryParamsForRoster(orgLevelId?: number, category?: string, type?: string): { orgLevelId?: number, category?: string, type?: string } {
    let queryParams: any = {};
    if (!!orgLevelId) {
      queryParams.orgLevelId = orgLevelId;
    }
    if (!!category) {
      queryParams.category = category;
    }
    if (!!type) {
      queryParams.type = type;
    }
    return queryParams;
  }

  public getUrlTreeForRoster(queryParams: any): UrlTree {
    let tree: UrlTree;
    if (this.route.pathFromRoot.length > 3) {
      tree = this.router.createUrlTree(['loa_roster'], { relativeTo: this.route.pathFromRoot[2], queryParams: queryParams });
    } else if (this.route.pathFromRoot.length > 1) {
      tree = this.router.createUrlTree(['hr', 'loa_roster'], { relativeTo: this.route.pathFromRoot[1], queryParams: queryParams });
    } else {
      tree = this.router.createUrlTree(['apps', 'hr', 'loa_roster'], { relativeTo: this.route.pathFromRoot[0], queryParams: queryParams });
    }
    return tree;
  }


}
