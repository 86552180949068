import { IPayrollExportGroup, PayrollExportGroup } from './payroll-export-group';

export interface IPayrollExportContainer {
  groups: IPayrollExportGroup[];
}

export class PayrollExportContainer {
  public static mock: IPayrollExportContainer = {
    groups: [
      {
        name: 'MAT',
        requestStatus: 6,
        releaseStatus: 1,
        canCancelRelease: true,
        canReleaseToPayroll: true,
        groupOrgCount: 6,
        facilities: [
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility1' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 137, orgLevelId: 0, name: 'Facility2' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility1' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility2' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility3' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility4' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility5' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility6' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility7' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility8' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
        ],
        exportDate: '06/06/2018',
        isExported: false
      },
      {
        name: 'TRZ',
        requestStatus: 6,
        releaseStatus: 1,
        canCancelRelease: true,
        canReleaseToPayroll: true,
        groupOrgCount: 6,
        facilities: [
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility1' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility2' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility1' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility2' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility3' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility4' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility5' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility6' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility7' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility8' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: true,
            isReleased: true,
          },
        ],
        exportDate: '06/06/2018',
        isExported: false
      },
      {
        name: 'ABC',
        requestStatus: 6,
        releaseStatus: 1,
        canCancelRelease: true,
        canReleaseToPayroll: true,
        groupOrgCount: 6,
        facilities: [
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility1' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility2' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility1' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility2' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility3' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility4' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility5' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility6' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
          {
            organization: { id: 1, orgLevelId: 0, name: 'Facility7' },
            submittedDate: '06/06/2018',
            submittedBy: 'User1',
            releaseDate: '06/06/2018',
            releasedBy: 'User2',
            isSubmitted: true,
            isReleased: true,
          },
          {
            organization: { id: 2, orgLevelId: 0, name: 'Facility8' },
            submittedDate: null,
            submittedBy: null,
            releaseDate: null,
            releasedBy: null,
            isSubmitted: false,
            isReleased: false,
          },
        ],
        exportDate: '06/06/2018',
        isExported: false
      }
    ]
  };
  public groups: PayrollExportGroup[];
}
