import { Injectable } from '@angular/core';
import { Assert } from '../../../framework/index';
import * as _ from 'lodash';
import {
  FieldsMeta,
  FieldData,
  FieldSecurityAttribute,
  IFieldData,
  FieldAccessType,
  FieldLimitAttribute,
  ActionsType,
  Actions,
  MetadataInfo,
  IMetadataInfo
} from '../../../core/models/index';

@Injectable()
export class MetaMapService {

  public mapToFieldMeta<T>(value: T, dmeta: IFieldData): FieldData<T> {
    Assert.isNotNull(dmeta, 'dmeta');
    let fieldData: FieldData<T> = new FieldData<T>();
    fieldData.fieldName = dmeta.fieldName;
    fieldData.fieldType = dmeta.fieldType;

    fieldData.securityAttribute = new FieldSecurityAttribute();
    this.setSecurityAccess(fieldData.securityAttribute, dmeta.access);

    fieldData.limitAttribute = new FieldLimitAttribute<T>();
    if (dmeta.fieldLimitInfo) {
      fieldData.limitAttribute.limitType = dmeta.fieldLimitInfo.limitType;
      fieldData.limitAttribute.maxValue = dmeta.fieldLimitInfo.highLimit;
      fieldData.limitAttribute.minValue = dmeta.fieldLimitInfo.lowLimit;
      fieldData.limitAttribute.hasServerValidation = dmeta.fieldLimitInfo.hasServerValidation;
      fieldData.isRequired = dmeta.fieldLimitInfo.isRequired;
      fieldData.isVisible = dmeta.fieldLimitInfo.isVisible;
    }
    fieldData.defaultFieldValue = dmeta.fieldValue;
    fieldData.fieldValue = this.mapValue(value, fieldData);
    fieldData.isDirty = false;
    fieldData.isTemporal = dmeta.isTemporal;
    fieldData.temporalMinDate = dmeta.temporalMinDate;
    return fieldData;
  }
  public setSecurityAccess(securityAttribute: FieldSecurityAttribute, access: string): void {
    if (access) {
      securityAttribute.access = access.toLowerCase();
    } else {
      securityAttribute.access = FieldAccessType.read;
    }
  }
  public emptyFieldMeta<T>(value: T, fieldName: string): FieldData<T> {
    Assert.isNotNull(fieldName, 'fieldName');
    let fieldData: FieldData<T> = new FieldData<T>();
    fieldData.fieldName = fieldName;
    fieldData.securityAttribute = new FieldSecurityAttribute();
    fieldData.securityAttribute.access = FieldAccessType.hidden;
    fieldData.limitAttribute = new FieldLimitAttribute<T>();
    fieldData.fieldValue = this.mapValue(value, fieldData);
    fieldData.isTemporal = false;
    fieldData.temporalMinDate = null;
    fieldData.isDirty = false;
    return fieldData;
  }

  public mapMeta<T>(metaMap: StringMap<IFieldData>, fieldName: string, value: T, rawValue?: string): FieldData<T> {
    Assert.isNotNull(metaMap, 'metaMap');
    Assert.isNotNull(fieldName, 'fieldName');
    if (!rawValue) {
      if (value === undefined || value === null) {
        rawValue = null;
      } else {
        if (value instanceof Date) {
          Assert.isNotNull(rawValue, `for field '${fieldName}' type of FieldData<Date> rawValue is required if value not null. Parameter rawValue`);
        }
        /*
        can`t do this due lookups like: { value: null, description: null }
        else if (value instanceof Object) {
          Assert.isNotNull(rawValue, `for field '${fieldName}' type of FieldData<Object> rawValue is required if value not null. Parameter rawValue`);
        }*/
        rawValue = value.toString();
      }
    }
    let key: string = fieldName.toLowerCase();
    let fieldData: FieldData<T>;
    if (metaMap.hasOwnProperty(key)) {
      fieldData = this.mapToFieldMeta<T>(value, metaMap[key]);
    } else {
      fieldData = this.emptyFieldMeta<T>(value, fieldName);
    }
    fieldData.rawValue = rawValue;
    return fieldData;
  }

  public createMetaMap(meta: FieldsMeta): StringMap<IFieldData> {
    let metaMap: StringMap<IFieldData> = {};
    _.forEach(meta.fields, (dmeta: IFieldData) => {
      metaMap[dmeta.fieldName.toLowerCase()] = dmeta;
    });
    return metaMap;
  }

  public createFiledDataMap(fields: FieldData<any>[]): StringMap<FieldData<any>> {
    let metaMap: StringMap<FieldData<any>> = {};
    _.forEach(fields, (dmeta: FieldData<any>) => {
      metaMap[dmeta.fieldName.toLowerCase()] = dmeta;
    });
    return metaMap;
  }

  public mapActions(meta: FieldsMeta): Actions {
    return this.mapActionList(meta.actions);
  }

  public mapActionList(actionList: string[]): Actions {
    let actions: Actions = new Actions();
    _.forEach(actionList, (act: string) => {
      if (act === ActionsType.edit) actions.canEdit = true;
      if (act === ActionsType.add) actions.canAdd = true;
      if (act === ActionsType.delete) actions.canDelete = true;
      if (act === ActionsType.approve) actions.canApprove = true;
      if (act === ActionsType.submit) actions.canSubmit = true;
      if (act === ActionsType.exportToExcel) actions.canExportToExcel = true;
      if (act === ActionsType.exportToPdf) actions.canExportToPdf = true;
      if (act === ActionsType.flag) actions.canFlag = true;
    });
    return actions;
  }

  public mapValue<T>(value: T, fd: FieldData<T>): T {
    return value;
    /*
     if(fd.securityAttribute.masked) {
     return null;
     } else {
     return value;
     }*/
  }

  public mapEntityMetadata(dto: IMetadataInfo): MetadataInfo {
    let meta: MetadataInfo = new MetadataInfo();
    meta.orgLevelId = dto.orgLevelId;
    meta.entityName = dto.entityName;
    meta.actions = this.mapActionList(dto.actions);
    meta.fields = _.map(dto.fields, (m: IFieldData) => this.mapToFieldMeta(null, m));
    meta.fieldsMap = this.createFiledDataMap(meta.fields);
    return meta;
  }
}
