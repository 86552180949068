/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./application-item.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i5 from "../../../common/components/spinner/spinner.component";
import * as i6 from "./application-item.component";
import * as i7 from "../../../organization/services/navigation/sso-navigation.service";
import * as i8 from "../../../organization/services/navigation/ben-admin/ben-admin-menu-management.service";
import * as i9 from "../../../common/services/modal/modal.service";
var styles_ApplicationItemComponent = [i0.styles];
var RenderType_ApplicationItemComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ApplicationItemComponent, data: {} });
export { RenderType_ApplicationItemComponent as RenderType_ApplicationItemComponent };
function View_ApplicationItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "app__logo"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.application.link; _ck(_v, 1, 0, currVal_2); var currVal_3 = "fa"; var currVal_4 = _co.application.icon; _ck(_v, 4, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ApplicationItemComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "app-url-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.application.link; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_3 = _co.application.title; _ck(_v, 2, 0, currVal_3); }); }
function View_ApplicationItemComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dashboard-edit-button-alert"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onStartAlerts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [["aria-hidden", "true"], ["class", "fas fa-cog"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Edit "]))], null, null); }
function View_ApplicationItemComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ApplicationItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "app__content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "app__url"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "slx-spinner", [["class", "app__spinner"]], null, null, null, i4.View_SpinnerComponent_0, i4.RenderType_SpinnerComponent)), i1.ɵdid(10, 49152, null, 0, i5.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ApplicationItemComponent_5)), i1.ɵdid(12, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.application; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.application; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.application && ((_co.application.id === 3) || (_co.application.id === 4))); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.progressbar; _ck(_v, 10, 0, currVal_4); var currVal_5 = i1.ɵnov(_v.parent, 4); _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "app ", _co.className, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationItemComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "app__logo"], ["target", "_blank"]], [[1, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirectToApp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "fa"; var currVal_2 = _co.application.icon; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.application.link; _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationItemComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "app__url"], ["target", "_blank"]], [[1, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.redirectToApp() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.application.link; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.application.title; _ck(_v, 1, 0, currVal_1); }); }
function View_ApplicationItemComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ApplicationItemComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_7)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "app__content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_8)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_9)), i1.ɵdid(7, 540672, null, 0, i3.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.application; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.application; _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵnov(_v.parent, 4); _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "app ", _co.className, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_ApplicationItemComponent_10(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ApplicationItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ApplicationItemComponent_6)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["contentTmpl", 2]], null, 0, null, View_ApplicationItemComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isExternalLink; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isExternalLink; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ApplicationItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-application-item", [], null, null, null, View_ApplicationItemComponent_0, RenderType_ApplicationItemComponent)), i1.ɵdid(1, 114688, null, 0, i6.ApplicationItemComponent, [i7.SsoNavigationService, i2.ActivatedRoute, i8.BenAdminMenuManagementService, i9.ModalService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ApplicationItemComponentNgFactory = i1.ɵccf("slx-application-item", i6.ApplicationItemComponent, View_ApplicationItemComponent_Host_0, { application: "application", progressbar: "progressbar", isExternalLink: "isExternalLink" }, { onHasChanges: "onHasChanges" }, ["*"]);
export { ApplicationItemComponentNgFactory as ApplicationItemComponentNgFactory };
