<button  *ngIf="!isBlueStyleBtn"
  type="button"
  class="theme-icon-button settings-menu-btn"
  [ngClass]="{'hidden-config-button':!isInitialized}"
  [popper]="columnsToggler"
  [popperTrigger]="'click'"
  [popperPlacement]="popperPosition"
  [popperDisableStyle]="'true'"
  [popperPositionFixed]="true" >
  <span class="fa-layers fa-fw">
    <i class="fas fa-sliders-h" aria-hidden="true"></i>
    <i *ngIf="secondaryIcon" [className]="secondaryIcon" aria-hidden="true"></i>
  </span>
</button>

<button *ngIf="isBlueStyleBtn"
  type="button"
  [attr.class]="blueStyleBtnClass"
  [popper]="columnsToggler"
  [popperTrigger]="'click'"
  [popperPlacement]="popperPosition"
  [popperDisableStyle]="'true'"
  [popperPositionFixed]="true"
>
  <i aria-hidden="true" [attr.class]="blueStyleIconClass"></i>
</button>

<popper-content #columnsToggler [ngStyle]="{'width.px': width}" class="popper-white-background">
  <span class="title"><strong>{{title}}</strong></span>
  <div class="columns-container">
    <div *ngFor="let column of columns; let idx=index;">
      <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
        <slx-checkbox-input slx-input name="{{uniqId}}_col_{{idx}}" fieldName="{{uniqId}}_col_{{idx}}" [caption]="column.description" [(ngModel)]="column.displayed"
          (ngModelChange)="onChangeColumn(column)">
        </slx-checkbox-input>
      </slx-input-decorator>
    </div>
  </div>
  <div class="button-container">
    <button class="margin-button theme-button-apply margin-r" type="button" (click)="selectAll()"> Select All </button>
    <button class="margin-button theme-button-cancel" type="button" (click)="clearAll()"> Clear All</button>
  </div>
</popper-content>
