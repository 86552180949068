/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-cycle-calendar-slider.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular2-moment/date-format.pipe";
import * as i3 from "@angular/common";
import * as i4 from "ngx-popper";
import * as i5 from "../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i6 from "../schedule-cycle-calendar/schedule-cycle-calendar.component.ngfactory";
import * as i7 from "../schedule-cycle-calendar/schedule-cycle-calendar.component";
import * as i8 from "@angular/forms";
import * as i9 from "./schedule-cycle-calendar-slider.component";
var styles_ScheduleCycleCalendarSliderComponent = [i0.styles];
var RenderType_ScheduleCycleCalendarSliderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleCycleCalendarSliderComponent, data: {} });
export { RenderType_ScheduleCycleCalendarSliderComponent as RenderType_ScheduleCycleCalendarSliderComponent };
export function View_ScheduleCycleCalendarSliderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DateFormatPipe, []), i1.ɵqud(402653184, 1, { popper: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 29, "div", [["class", "slx-schedule-cycle-calendar-slider"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "disabled": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "cmd-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "cmd-icon left fas fa-angle-left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavButtonClick($event, false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 21, "div", [["class", "date-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 9, "div", [["class", "dates-container"]], null, [[null, "popperOnShown"], [null, "popperOnHidden"], [null, "touchstart"], [null, "click"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 10).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 10).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 10).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i1.ɵnov(_v, 10).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i1.ɵnov(_v, 10).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } if (("popperOnShown" === en)) {
        var pd_7 = (_co.onToggleVisibility(true) !== false);
        ad = (pd_7 && ad);
    } if (("popperOnHidden" === en)) {
        var pd_8 = (_co.onToggleVisibility(false) !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 737280, null, 0, i4.PopperController, [i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], disableStyle: [3, "disableStyle"] }, { popperOnShown: "popperOnShown", popperOnHidden: "popperOnHidden" }), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵppd(13, 2), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["\u2013 ", ""])), i1.ɵppd(16, 2), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [["class", "calendar-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fal fa-calendar-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 10, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_PopperContent_0, i5.RenderType_PopperContent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(21, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(22, { "not-shown-fix-visibility-issue": 0 }), i1.ɵdid(23, 180224, [[1, 4], ["popperCalendar", 4]], 0, i4.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(24, 0, null, 0, 5, "slx-schedule-cycle-calendar", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.currentDate = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ScheduleCycleCalendarComponent_0, i6.RenderType_ScheduleCycleCalendarComponent)), i1.ɵdid(25, 49152, null, 0, i7.ScheduleCycleCalendarComponent, [], { cycles: [0, "cycles"], minDate: [1, "minDate"], maxDate: [2, "maxDate"], readonly: [3, "readonly"] }, null), i1.ɵprd(1024, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.ScheduleCycleCalendarComponent]), i1.ɵdid(27, 671744, null, 0, i8.NgModel, [[8, null], [8, null], [8, null], [6, i8.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i8.NgControl, null, [i8.NgModel]), i1.ɵdid(29, 16384, null, 0, i8.NgControlStatus, [[4, i8.NgControl]], null, null), (_l()(), i1.ɵeld(30, 0, null, null, 1, "span", [["class", "cmd-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "cmd-icon right fas fa-angle-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavButtonClick($event, true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-schedule-cycle-calendar-slider"; var currVal_1 = _ck(_v, 5, 0, _co.readonly); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 23); var currVal_3 = "bottom-start"; var currVal_4 = "click"; var currVal_5 = true; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = _ck(_v, 22, 0, !_co.isShown); _ck(_v, 21, 0, currVal_8); var currVal_16 = _co.cycles; var currVal_17 = _co.minDate; var currVal_18 = _co.maxDate; var currVal_19 = _co.readonly; _ck(_v, 25, 0, currVal_16, currVal_17, currVal_18, currVal_19); var currVal_20 = _co.currentDate; _ck(_v, 27, 0, currVal_20); }, function (_ck, _v) { var _co = _v.component; var currVal_6 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), ((_co.currentRange == null) ? null : _co.currentRange.startDate), _co.appConfig.dateFullMonthFormat)); _ck(_v, 12, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i1.ɵnov(_v, 0), ((_co.currentRange == null) ? null : _co.currentRange.endDate), _co.appConfig.dateFullMonthFormat)); _ck(_v, 15, 0, currVal_7); var currVal_9 = i1.ɵnov(_v, 29).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 29).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 29).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 29).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 29).ngClassValid; var currVal_14 = i1.ɵnov(_v, 29).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 29).ngClassPending; _ck(_v, 24, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_ScheduleCycleCalendarSliderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-schedule-cycle-calendar-slider", [], null, null, null, View_ScheduleCycleCalendarSliderComponent_0, RenderType_ScheduleCycleCalendarSliderComponent)), i1.ɵprd(5120, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.ScheduleCycleCalendarSliderComponent]), i1.ɵdid(2, 49152, null, 0, i9.ScheduleCycleCalendarSliderComponent, [], null, null)], null, null); }
var ScheduleCycleCalendarSliderComponentNgFactory = i1.ɵccf("slx-schedule-cycle-calendar-slider", i9.ScheduleCycleCalendarSliderComponent, View_ScheduleCycleCalendarSliderComponent_Host_0, { cycles: "cycles", minDate: "minDate", maxDate: "maxDate", readonly: "readonly" }, { rangeChanged: "rangeChanged" }, []);
export { ScheduleCycleCalendarSliderComponentNgFactory as ScheduleCycleCalendarSliderComponentNgFactory };
