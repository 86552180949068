import {
  ISidebarRecord,
  ISidebar
} from './sidebar.types';
import { makeTypedFactory } from 'typed-immutable-record';

export const sidebarFactory: (val?: ISidebar) => ISidebarRecord = makeTypedFactory<ISidebar, ISidebarRecord>({
  isRightSidebarOpen: false,
  isLeftSidebarOpen: true,
  isLeftSidebarHidden: false,
});

export const INITIAL_SIDEBAR_STATE: ISidebarRecord = sidebarFactory();
