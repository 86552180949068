import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../../app.config';
import { DialogOptions, IDialog, DialogOptions2, DialogModeSize } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { BenefitEnrolledEmployee, BenefitTerminationReason, DropEmployeeBenefits, DropCoverageRequest } from '../../../models/index';
import { BenefitEmployeesApiService } from './../../../services/benefit-employees/index';
import { ModalAnchorDirective } from '../../../../../common/index';
import * as moment from 'moment';
import { dateTimeUtils } from '../../../../../common/utils/index';
import { AppServerConfig } from '../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../../app-settings/services/app-settings-manage.service';


@Component({
  moduleId: module.id,
  selector: 'slx-benefit-action-dialog',
  templateUrl: 'benefit-drop-coverage-dialog.component.html',
  styleUrls: ['benefit-drop-coverage-dialog.component.scss']
})
export class EmployeeBenefitDropCoverageDialogComponent implements IDialog {
  public dialogResult: boolean = false;
  public benefitDisplayEndDate: Date;
  public eventDisplayDate: Date;
  public benefitEndDate: Date;
  public eventDate: Date;
  public employeeStartDate: Date;
  public employeeEndDate: Date;
  public eventEmployeeStartDate: Date;
  public eventEmployeeEndDate: Date;
  public planEffectiveStartDate: Date;
  public planEffectiveEndDate: Date;
  public payrollDeductionEndDate: Date;
  public isDedAfterBeneEndDt: boolean;
  public canMapPayroll: boolean;
  public options: DialogOptions;
  public modalService: ModalService;
  public apiService: BenefitEmployeesApiService;
  public selectedEmployees: BenefitEnrolledEmployee[];
  public reasonList: BenefitTerminationReason[];
  public reason: BenefitTerminationReason;
  public appConfig: IApplicationConfig = appConfig;
  public maxDedDate: Date = new Date(appConfig.maxCorrectDate);
  public minDedDate: Date = new Date(appConfig.minCorrectDate);
  public selectedEmployeesList: number[] = [];
  public datesError: boolean = true;
  public isLoading: boolean;
  public enableBenefitDeduction:boolean = false;
  public oneDayValue = 60 * 60 * 24 * 1000;
  public title: string = 'Benefit end date must be within the selected range and Reason should be selected.';
  public warningMsg: string = "Payroll Deduction End Date is set after the Benefit Coverage End Date."
  public static openDialog(planName: string, anchor: ModalAnchorDirective, modalService: ModalService, apiService: BenefitEmployeesApiService, dropCoverageRequest: DropCoverageRequest, callback?: (result: boolean) => void): EmployeeBenefitDropCoverageDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.width = 600;
    dialogOptions.height = 350;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: DropCoverageRequest,
        useValue: dropCoverageRequest
      },
      {
        provide: BenefitEmployeesApiService,
        useValue: apiService
      }
    ];
    const title = `Drop Benefit Coverage - Enrolled Employees - ${planName}`;
    return anchor.openDialog2(
      EmployeeBenefitDropCoverageDialogComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );
  }

  constructor (
    options: DialogOptions,
    modalService: ModalService,
    apiService: BenefitEmployeesApiService,
    dropCoverageRequest: DropCoverageRequest,
    private appSettingsManageService: AppSettingsManageService
  ) {
    this.options = options;
    this.modalService = modalService;
    this.apiService = apiService;
    this.selectedEmployees = dropCoverageRequest.selectedEmployees;
    this.planEffectiveStartDate = dropCoverageRequest.startDate;
    this.planEffectiveEndDate = dropCoverageRequest.endDate;
    this.canMapPayroll = dropCoverageRequest.canMapPayroll;
    this.minDedDate = dropCoverageRequest.mappedDeduction.startDate;
    this.maxDedDate = dropCoverageRequest.mappedDeduction.endDate;
    this.loadReasons();
    this.loadDates();
  }

  public ngOnInit() {
    this.getSettings();
  }

  public setStartEndDates(startDate,endDate) {
    this.employeeStartDate = new Date(startDate);
    this.employeeEndDate = new Date(endDate);
    this.eventEmployeeStartDate = new Date(this.planEffectiveStartDate);
    this.eventEmployeeEndDate = new Date(this.planEffectiveEndDate);
  }

  private loadDates() {
    let empStartDates = [];
    let empEndDates = [];
    let empDedStartDates = [];
    let empDedEndDates = [];

    _.forEach(this.selectedEmployees, (employee) => {
      if (employee.endDate && employee.startDate) {
        empStartDates.push(employee.startDate);
        empEndDates.push(employee.endDate);
        empDedStartDates.push(employee.payrollDeductionStartDate);
        empDedEndDates.push(employee.payrollDeductionEndDate);
      }

    });
    let startDate = _.max(empStartDates);
    let endDate = _.min(empEndDates);
    let dedStartDate = _.max(empDedStartDates);
    let dedEndDate = _.min(empDedEndDates);
    this.minDedDate = dedStartDate;
    this.maxDedDate = dedEndDate;
    this.eventDisplayDate = startDate;
    this.benefitEndDate = endDate;  
    if (_.gt(dedEndDate, this.maxDedDate))
        {
            this.payrollDeductionEndDate = this.maxDedDate;
        }
    else 
      {
        this.payrollDeductionEndDate = dedEndDate;
      }

    this.benefitDisplayEndDate = endDate;

    if (this.selectedEmployees[0].dropEventDate == null) {
      this.eventDate = null;
    } else {
      this.eventDate = this.selectedEmployees[0].dropEventDate;
    }
    this.setStartEndDates(startDate, endDate);
  }

  public onHasChanges(hasChanges: boolean): void {
    this.dialogResult = hasChanges;
  }

  public onCancel(): void {
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  private loadReasons() {
    this.apiService.getReasonsList()
    .then((reasons: BenefitTerminationReason[]) => {
      this.reasonList = reasons;
    }).catch((reason: any) => {
      this.reasonList = null;
    });
  }

  public dropBenefits(): void {
    this.isLoading = true;

    _.forEach(this.selectedEmployees, (record: BenefitEnrolledEmployee) => {
      this.selectedEmployeesList.push(record.id);
    });

    this.apiService.dropEmployeeBenefits(this.selectedEmployeesList, this.benefitEndDate, this.payrollDeductionEndDate, this.eventDate, this.reason)
    .then((drop: DropEmployeeBenefits) => {
      this.isLoading = false;
      this.onCancel();
    }).catch((reason: any) => {
      this.reasonList = null;
      this.isLoading = false;
    });
  }

  public onChangeBenefitEndDate(value: Date) {
    if(value) {
      this.benefitEndDate = value;
    }
  }
  public onChangeDedEndDate(): void {
    this.isDedAfterBeneEndDt = _.gt(this.payrollDeductionEndDate, this.benefitEndDate);
  }

  public onChangeEventdDate(value: Date) {
    if(value) {
      this.eventDate = value;
    }
  }

  // this will set dates error to false so save is enabled
  public onReasonChange(value: any) {
    this.datesError = false;
  }

  public getEndDateToolTip(): string {
    return `The Benefit End Date must be between
        ${moment(this.eventDisplayDate).format(appConfig.dateFormat)} and
        ${moment(this.benefitDisplayEndDate).format(appConfig.dateFormat)},
        which represents the common effective date range for the selected employees.`;
  }

  public getEventDateToolTip(): string {
    return `The event date is intended to reflect the date associated with the reason. In situations where a date
      is not applicable, leave it blank.`;
  }

  private async getSettings(): Promise<void> {
    const config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.enableBenefitDeduction = config.isBenefitDeductionEnabled;
  }
}
