
export class FieldLimitAttribute<T> {
  public limitType: limitTypeDefinition;
  public maxValue: T;
  public minValue: T;
  public hasServerValidation: boolean;
}
export class LimitType {
  public static noLower: limitTypeDefinition = 'no-lower';
  public static noBigger: limitTypeDefinition = 'no-bigger';
  public static inRange: limitTypeDefinition = 'in-range';

}
export type limitTypeDefinition = 'no-lower' | 'no-bigger' | 'in-range';
