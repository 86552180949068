import {  IColumnSettings } from '../../../core/models/index';

export class WcCreateOshaRosterState {
  public columns: IColumnSettings[];
  public columnsMap: StringMap<IColumnSettings>;

  public createColumns(): void {
    this.columns = [
        {
          name: 'organization',
          description: 'Facility',
          displayed: true,
        },
        {
          name: 'department',
          description: 'Department',
          displayed: true,
        },
        {
          name: 'position',
          description: 'Position',
          displayed: true,
        },
        {
          name: 'oshaInjuryType',
          description: 'Osha Injury Type',
          displayed: true,
        },
        {
          name: 'incidentDateTime',
          description: 'Occurrence Date',
          displayed: true,
        },
        {
          name: 'bodyPart',
          description: 'Body Part',
          displayed: true,
        },
        {
          name: 'daysLost',
          description: 'Days Lost',
          displayed: true,
        },
        {
          name: 'oshaDaysLost',
          description: 'Days Lost',
          displayed: true,
        },
        {
          name: 'isOSHARecordable',
          description: 'OSHA',
          displayed: true,
        },
        {
          name: 'isWorkersCompCase',
          description: 'Workers\' Comp',
          displayed: true,
        },
        {
          name: 'addedBy',
          description: 'Created By',
          displayed: true,
        },
        {
          name: 'updatedBy',
          description: 'Last Updated By',
          displayed: true,
        }
      ];
    this.mapColumns();
  }

  public mapColumns(): void {
    this.columnsMap = {};
    this.columns.forEach((col: IColumnSettings) => {
      this.columnsMap[col.name] = col;
    });
  }
}
