<form #form="ngForm" novalidate class="slx-full-height">
<div class="formula flex justify-content__space-between">
  <section class="slx-benefit-box flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-user"></i>
      <span class="slx-benefit-box__text">Employee Contribution</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__emp-label">Type</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-dropdown-list class="slx-wide" name="empContributionTypeField"
            valueField="id"
            titleField="title"
            [options]="calculationTypesShort"
            [(ngModel)]="empContribution"
            (ngModelChange)="onChangeEmpContributionType()"
            [readonly]="isReadOnly"
            [disableEmittingIfReadonly]="true"
            required
          ></slx-dropdown-list>
        </p>
      </div>
      <div *ngIf="isEmpFixedCalcType" class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__emp-label">Fixed Amount</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-number #emprContributionField="ngModel" name="empContributionFixedField"
            class="slx-wide"
            [min]="min"
            [max]="max"
            [slxMin]="min"
            [slxMax]="max"
            [step]="step"
            [decimals]="2"
            [format]="format"
            [autoCorrect]="false"
            [readonly]="!isEditMode"
            [(ngModel)]="empFixed"
            (ngModelChange)="onChangeEmpContribution()"
          ></slx-number>
          <span *ngIf="emprContributionField.errors?.min || emprContributionField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
        </p>
      </div>
      <div *ngIf="isEmpFormulaCalcType && isShownFormula" class="slx-benefit-box__control flex align-items__center formula__column">
        <p class="slx-benefit-box__label formula__emp-label formula__w100">Formula</p>
        <p class="slx-benefit-box__value flex__grow formula__no-padd formula__w100">
          <textarea class="slx-benefit-box__text-field formula__textarea" name="empFormulaTextField"
            [disabled]="!isFormulaEditable"
            [(ngModel)]="empFormulaText"
            (ngModelChange)="onChangeEmpContribution()"
            placeholder="Type your formula here..."
          ></textarea>
        </p>
      </div>
    </div>
  </section>
  <section class="slx-benefit-box flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-user"></i>
      <span class="slx-benefit-box__text">Employer Contribution</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__empr-label">Type</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-dropdown-list class="slx-wide" name="emprContributionTypeField"
            valueField="id"
            titleField="title"
            [options]="calculationTypesShort"
            [(ngModel)]="emprContribution"
            (ngModelChange)="onChangeEmprContributionType()"
            [readonly]="isReadOnly"
            [disableEmittingIfReadonly]="true"
            required
          ></slx-dropdown-list>
        </p>
      </div>
      <div *ngIf="isEmprFixedCalcType" class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__empr-label">Fixed Amount</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-number #emprFixedField="ngModel" name="emprFixedValueField"
            class="slx-wide"
            [min]="min"
            [max]="max"
            [step]="step"
            [format]="format"
            [decimals]="2"
            [autoCorrect]="false"
            [readonly]="!isEditMode"
            [(ngModel)]="emprFixed"
            (ngModelChange)="onChangeEmprContribution()"
          ></slx-number>
          <span *ngIf="emprFixedField.errors?.min || emprFixedField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
        </p>
      </div>
      <div *ngIf="isEmprFormulaCalcType && isShownFormula" class="slx-benefit-box__control flex align-items__center formula__column">
        <p class="slx-benefit-box__label formula__empr-label formula__w100">Formula</p>
        <p class="slx-benefit-box__value flex__grow formula__no-padd formula__w100">
          <textarea class="slx-benefit-box__text-field formula__textarea" name="emprFormulaTextField"
            [disabled]="!isFormulaEditable"
            [(ngModel)]="emprFormulaText"
            (ngModelChange)="onChangeEmprContribution()"
            placeholder="Type your formula here..."
          ></textarea>
        </p>
      </div>
    </div>
  </section>
  <section class="slx-benefit-box flex__grow">
    <h5 class="slx-benefit-box__hd flex align-items__center">
      <i aria-hidden="true" class="slx-benefit-box__icon fas fa-dollar-sign"></i>
      <span class="slx-benefit-box__text">Coverage</span>
    </h5>
    <div class="slx-benefit-box__body">
      <div class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__cov-label">Type</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-dropdown-list class="slx-wide" name="coverageTypeField"
            valueField="id"
            titleField="title"
            [options]="calculationTypes"
            [(ngModel)]="coverage"
            (ngModelChange)="onChangeCoverageType()"
            [readonly]="isReadOnly"
            [disableEmittingIfReadonly]="true"
            required
          ></slx-dropdown-list>
        </p>
      </div>
      <div *ngIf="isCovFixedCalcType" class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__cov-label">Fixed Amount</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-number #covFixedField="ngModel" name="covFixedFieldName"
            class="slx-wide"
            [min]="min"
            [max]="max"
            [step]="step"
            [format]="format"
            [decimals]="2"
            [autoCorrect]="false"
            [readonly]="!isEditMode"
            [(ngModel)]="covFixed"
            (ngModelChange)="onChangeCoverage()"
          ></slx-number>
          <span *ngIf="covFixedField.errors?.min || covFixedField.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
        </p>
      </div>
      <div *ngIf="isCovMultiplierCalcType" class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__cov-label">Multiplier</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-dropdown-list class="slx-wide" name="covMultiplierField"
            #multiplierType="ngModel"
            [options]="multiplierMaxValues"
            [(ngModel)]="covMultiplier"
            (ngModelChange)="onChangeCoverage()"
            [readonly]="!isEditMode"
            [valuePrimitive]="true"
            [disableEmittingIfReadonly]="true"
            required
          ></slx-dropdown-list>
        <span *ngIf="multiplierType.errors" class="slx-error-block error-box">
          <span *ngIf="multiplierType.errors.required" errorMessage for="required"></span>
        </span>
        </p>
      </div>
      <div *ngIf="isCovAnyValueCalcType || isCovMultiplierCalcType" class="slx-benefit-box__control flex align-items__center">
        <p class="slx-benefit-box__label formula__cov-label">Max Cap</p>
        <p class="slx-benefit-box__value flex__grow">
          <slx-number #covMaxCapNameField="ngModel" class="slx-wide" name="covMaxCapName"
            [min]="min"
            [max]="max"
            [slxMin]="min"
            [slxMax]="max"
            [step]="step"
            [format]="format"
            [decimals]="2"
            [autoCorrect]="false"
            [readonly]="!isEditMode"
            [(ngModel)]="covMaxCap"
            (ngModelChange)="onChangeCoverage()"
            required
          ></slx-number>
          <span *ngIf="covMaxCapNameField.errors || isMaxCapLessThanMultiplier " class="slx-error-block error-box">
            <span *ngIf="covMaxCapNameField?.errors?.required" errorMessage for="required"></span>
            <span *ngIf="covMaxCapNameField?.errors?.min || covMaxCapNameField?.errors?.max" class="slx-error-block pull-left" errorMessage>Incorrect Value</span>
            <span *ngIf="isMaxCapLessThanMultiplier" errorMessage>Max Cap value &nbsp;cannot be less than multiplier value &nbsp;selected</span>
          </span>
        </p>
      </div>
      <div *ngIf="isCovFormulaCalcType && isShownFormula" class="slx-benefit-box__control flex align-items__center formula__column">
        <p class="slx-benefit-box__label formula__cov-label formula__w100">Formula</p>
        <p class="slx-benefit-box__value flex__grow formula__no-padd formula__w100">
          <textarea class="slx-benefit-box__text-field formula__textarea" name="covFormulaTextName"
            [disabled]="!isFormulaEditable"
            [(ngModel)]="covFormulaText"
            (ngModelChange)="onChangeCoverage()"
            placeholder="Type your formula here..."
          ></textarea>
        </p>
      </div>
    </div>
  </section>
</div>
</form>