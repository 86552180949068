import * as _ from 'lodash';
import { Component, OnInit, Provider, OnDestroy, ViewChild } from '@angular/core';
import { NewEnrollmentModel, BenefitTierDefinition, FormulaTypeEnum, BenefitsEmpEnrollOptionRate, BenefitEmpEnrollmentFormula } from '../../../../models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { ModalService } from '../../../../../../common/services';
import { LookupService } from '../../../../../../organization/services';
import { DialogOptions, IDialog, DialogOptions2, DialogModeSize } from '../../../../../../common/models';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { BenefitEnrollmentCommonService } from '../../../../../../../app/app-modules/benefits/services';
import { NgForm } from '@angular/forms';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'slx-enroll-employee-formula-with-option',
  templateUrl: './enroll-employee-formula-with-option.component.html',
  styleUrls: ['./enroll-employee-formula-with-option.component.scss']
})
export class EnrollEmployeeFormulaWithOptionComponent implements OnInit, OnDestroy, IDialog {

  @ViewChild('gridForm', { static: true })
  private form: NgForm;

  public appConfig: IApplicationConfig;
  public minAmt = 0;
  public minFormulaAmt = -1;
  public maxAmt = 99999999.99;
  public stepcurrency = 0.01;

  public dialogResult: boolean;

  public isLoading: boolean;
  public isOptionLoading: boolean;

  public errorMessage: string = '';
  public groupName: string;
  public effectiveDate: Date;
  public payrollDedStartDate: Date;
  public payrollDedEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;
  public startDate: Date;
  public endDate: Date;
  public canMapPayroll: boolean;
  public hasDedError: boolean = false;

  public tiers: BenefitTierDefinition[];
  public selectedTier: BenefitTierDefinition;

  public coverageOptions: BenefitsEmpEnrollOptionRate[];
  public filteredCoverageOptions: BenefitsEmpEnrollOptionRate[];
  public selectedCoverageOption: BenefitsEmpEnrollOptionRate;

  public formulaSettings: BenefitEmpEnrollmentFormula;

  public coverageMultipllierOptions: { name: string, value: number }[];
  public editedItemCoverage: { name: string, value: number };
  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public static openDialog(
    model: NewEnrollmentModel,
    apiService: EmployeeSectionsBenefitsManagementApiService,
    modalService: ModalService,
    lookupService: LookupService,
    callback?: (result: boolean) => void
  ): EnrollEmployeeFormulaWithOptionComponent {


    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.modeSize = DialogModeSize.custom;
    dialogOptions.width = 1050;
    dialogOptions.height = 400;
    dialogOptions.minHeight = 220;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: LookupService,
        useValue: lookupService
      },
      {
        provide: ModalService,
        useValue: modalService
      },
      {
        provide: NewEnrollmentModel,
        useValue: model
      },
      {
        provide: EmployeeSectionsBenefitsManagementApiService,
        useValue: apiService
      }
    ];

    const title = `Enroll Employee in Benefits`;
    let component = modalService.globalAnchor.openDialog2(
      EnrollEmployeeFormulaWithOptionComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    return component;

  }

  public get areAllValuesOK(): boolean {
    return this.hasSelectedOptionCode
      && this.formulaSettings.empContribution >= 0
      && this.formulaSettings.erContribution >= 0 &&
      (
        (this.isFixed && this.formulaSettings.fixedAmount >= 0) ||
        (this.isFormula && this.hasCovFormulaExpression && this.formulaSettings.calculatedCoverage >= 0) ||
        ((this.multiplerMaxCap || this.anyWithMaxCap) && this.formulaSettings.formulaValue >= 0)
      );
  }

  public get hasSelectedOptionCode(): boolean {
    return !_.isNil(this.selectedCoverageOption);
  }

  public get hasError(): boolean {
    return this.errorMessage != '';
  }

  public get hasSelected(): boolean {
    return !_.isNil(this.selectedTier);
  }

  public get hasEmpContribution(): boolean {
    if (this.isEmpFormulaType) {
      return this.hasEmpFormulaExpression;
    }
    else {
      return this.hasSettings && _.isNumber(this.formulaSettings.empContribution);
    }

  }

  public get hasErContribution(): boolean {
    if (this.isErFormulaType) {
      return this.hasErFormulaExpression;
    }
    else {
      return this.hasSettings && _.isNumber(this.formulaSettings.erContribution);
    }
  }

  public get hasCoverage(): boolean {
    if (!this.hasSettings)
      return false;
    if (this.isFixed)
      return _.isNumber(this.formulaSettings.fixedAmount) && _.isNumber(this.formulaSettings.fixedAmount);
    if (this.anyWithMaxCap)
      return true;
    if (this.isFormula)
      return this.hasCovFormulaExpression;
    if (this.multiplerMaxCap)
      return true;
  }

  public get hasSettings(): boolean {
    return !_.isNil(this.formulaSettings);
  }
  public get anyWithMaxCap(): boolean {
    return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.AnyWithMaxCap;
  }

  public get multiplerMaxCap(): boolean {
    return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.MultiplerMaxCap;
  }

  public get isFixed(): boolean {
    return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.Fixed;
  }

  public get isFormula(): boolean {
    return this.formulaSettings && this.formulaSettings.formulaType === FormulaTypeEnum.Formula;
  }


  public get isEmpFormulaType(): boolean {
    return this.formulaSettings && this.formulaSettings.empFormulaType === FormulaTypeEnum.Formula;
  }

  public get isErFormulaType(): boolean {
    return this.formulaSettings && this.formulaSettings.erFormulaType === FormulaTypeEnum.Formula;
  }

  public get hasEmpFormulaExpression(): boolean {
    return this.formulaSettings && !_.isEmpty(this.formulaSettings.empFormulaExpression);
  }
  public get hasErFormulaExpression(): boolean {
    return this.formulaSettings && !_.isEmpty(this.formulaSettings.erFormulaExpression);
  }
  public get hasCovFormulaExpression(): boolean {
    return this.formulaSettings && !_.isEmpty(this.formulaSettings.covFormulaExpression);
  }

  private isDirty: boolean = false;

  constructor(private model: NewEnrollmentModel,
    private commonValidationService: BenefitEnrollmentCommonService,
    private apiService: EmployeeSectionsBenefitsManagementApiService, private options: DialogOptions, private modalService: ModalService, private lookupService: LookupService, private man: BenefitsEnrollmentSectionManagementService) {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.isOptionLoading = true;
    this.groupName = this.model.plan.name;
    this.effectiveDate = this.model.effectiveDate;
    this.payrollDedStartDate = this.model.effectiveDate;
    this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
    this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
    this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
    this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
    this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate : this.model.plan.dedEndDate;
    this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate : this.model.plan.startDate;
    this.endDate = this.model.plan.endDate;
    this.canMapPayroll = this.model.canMapPayroll;
    this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
      .then((tiers: BenefitTierDefinition[]) => {
        this.tiers = tiers;
        this.isLoading = false;
      });
    this.apiService.getBenefitPlanOptionRates(this.model.plan.benefitLineId)
      .then((options: BenefitsEmpEnrollOptionRate[]) => {
        this.coverageOptions = options;
        this.filteredCoverageOptions = [];
        this.selectedCoverageOption = null;
        this.isOptionLoading = false;
      });


    this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate((hasDedError: boolean) => {
      this.hasDedError = hasDedError;
    });

  }


  ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public onChangeTier(tier: BenefitTierDefinition): void {
    this.selectedTier = tier;
    this.isLoading = true;
    this.effectiveDate = this.model.effectiveDate;
    let empId = this.model.empId;
    this.apiService.getBenefitPlanFormula(tier.id, empId, this.effectiveDate, null, null)
      .then((settings: BenefitEmpEnrollmentFormula) => {
        this.formulaSettings = settings;
        this.maxAmt = _.isFinite(settings.maxCap) ? settings.maxCap : this.maxAmt;
        if (this.isEmpFormulaType) {
          this.formulaSettings.empContribution = null;
        }
        if (this.isErFormulaType) {
          this.formulaSettings.erContribution = null;
        }

        this.isDirty = true;
        if (this.multiplerMaxCap || this.anyWithMaxCap) {
          let options = [];
          if (this.multiplerMaxCap) {
            this.editedItemCoverage = null;
            let mult = _.isNumber(settings.multipler) ? settings.multipler : 500;
            let cap = _.isNumber(settings.maxCap) ? settings.maxCap : 10000;
            const range = _.range(mult, cap + mult, mult);
            options = _.map(range, value => ({ name: _.toString(value), value }));
          }
          this.coverageMultipllierOptions = options;
        } else {
          this.coverageMultipllierOptions = [];
          this.formulaSettings.formulaValue = _.isNumber(this.formulaSettings.formulaValue) ? this.formulaSettings.formulaValue : null;
        }

        this.isLoading = false;
        this.validatePrerequisites();
      });
    if (this.selectedTier && this.coverageOptions) {
      this.filteredCoverageOptions = _.filter(this.coverageOptions, o => o.id !== 0 && o.tierId === this.selectedTier.id);
    } else {
      this.filteredCoverageOptions = [];
    }
    this.selectedCoverageOption = null;
  }

  public onChangeCoverageWithMultiplier(value: { name: string, value: number }): void {
    this.editedItemCoverage = value;
    let newValue = value ? value.value : null;
    let hasChagned = (newValue !== this.formulaSettings.formulaValue);
    if(hasChagned){
      this.isDirty = this.formulaSettings.formulaValue > 0;
      this.formulaSettings.formulaValue = newValue;
      this.evaluateFormula();
    }
  }

  public onOptionValueChanged(): void {
    if(this.selectedCoverageOption && this.selectedCoverageOption.tierOptionCode){
      this.isDirty = true;
      //In case of Coverage Formula - when selecting the Option make sure the required field errors are cleared (as the coverage going to be assumed as 0).
      //This is to ensure form validation is successful immediately after selecting the option
      if(this.isFormula && !this.formulaSettings.formulaValue){
        this.form.control.get('covFormulaValue').setErrors(null);
      }
      this.evaluateFormula();
    }
  }

  public onChangeCoverageFormulaValue(value: number, hasError : boolean): void {
    if(!hasError && this.formulaSettings.calculatedCoverage !== value){
      this.formulaSettings.formulaValue = value;
      this.formulaSettings.calculatedCoverage = value;
      this.isDirty = true;
      this.evaluateFormula();
    }
  }

  public onCoverageAnyValueChanged(hasError: boolean): void {
    if(!hasError) {
      this.isDirty = this.formulaSettings.formulaValue > 0;
      this.evaluateFormula();
    }
  }

  private evaluateFormula() {
    if (this.hasCovFormulaExpression ||
      this.hasEmpFormulaExpression ||
      this.hasErFormulaExpression) {
        let hasOptionCode = this.selectedCoverageOption && this.selectedCoverageOption.tierOptionCode;
        let hasValidCoverage = _.isFinite(this.formulaSettings.formulaValue) || this.formulaSettings.formulaValue > 0;
        if(hasOptionCode && ((!this.isFormula && hasValidCoverage) || this.isFormula)) {
          this.isLoading = true;
          let optionCode = (hasOptionCode) ? this.selectedCoverageOption.tierOptionCode :'';
          this.apiService.getBenefitPlanFormula(this.selectedTier.id, this.model.empId, this.effectiveDate, this.formulaSettings.formulaValue, optionCode)
            .then((record: BenefitEmpEnrollmentFormula) => {
              if (record) {
                this.formulaSettings.empContribution = record.empContribution;
                this.formulaSettings.erContribution = record.erContribution;
                this.formulaSettings.calculatedCoverage = record.calculatedCoverage;
                if (this.multiplerMaxCap || this.anyWithMaxCap) {
                  this.formulaSettings.formulaValue = record.calculatedCoverage;
                }
              }
              else {
                if (this.isEmpFormulaType) {
                  this.formulaSettings.empContribution = -1;
                }
                if (this.isErFormulaType) {
                  this.formulaSettings.erContribution = -1;
                }
                if (this.isFormula) { //coverage
                  this.formulaSettings.calculatedCoverage = -1;
                }
              }
            }).finally(() => {
              this.isLoading = false;
              this.validateFormulaEvaluation();
            });
          }
        }
  }

  public onEnroll(): void {
    this.model.selectedCoverageOption = this.selectedCoverageOption;
    this.model.formulaSettings = this.formulaSettings;
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    if (!this.isDirty) {
      this.closeWindow(false);
      return;
    }
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved selections.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.closeWindow(false);
        }
      },
      options);
  }

  private closeWindow(dialogResult: boolean): void {
    this.dialogResult = dialogResult;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public validatePrerequisites(): void {

    this.errorMessage = '';
    if (this.filteredCoverageOptions.length === 0) {
      this.errorMessage = 'Employees cannot be enrolled as there are no options created for this benefit plan. Please create coverage options for this plan to enroll employees.';
    } else {
      this.errorMessage = 'Employees cannot be enrolled as ';
      if (!this.hasEmpContribution && !this.hasErContribution && !this.hasCoverage) {
        this.errorMessage += 'the Employee and Employer Contribution and Coverage amounts have';
      } else if (!this.hasEmpContribution && !this.hasErContribution) {
        this.errorMessage += 'the Employee and Employer Contribution amounts have';
      } else if (!this.hasEmpContribution && !this.hasCoverage) {
        this.errorMessage += 'the Employee Contribution and Coverage amounts have';
      } else if (!this.hasErContribution && !this.hasCoverage) {
        this.errorMessage += 'the Employer Contribution and Coverage amounts have';
      } else if (!this.hasEmpContribution) {
        this.errorMessage += 'the Employee Contribution amount has';
      } else if (!this.hasErContribution) {
        this.errorMessage += 'the Employer Contribution amount has';
      } else if (!this.hasCoverage) {
        this.errorMessage += 'the Coverage amount has';
      } else {
        this.errorMessage = '';
        return;
      }
      this.errorMessage += ' not been created for this benefit plan. Please add contribution and Coverage amounts for this plan to enroll employees.';
    }
    if (this.errorMessage != '') {
      let options: ConfirmOptions = new ConfirmOptions();
      options.showCancel = false;
      options.showOK = true;
      ConfirmDialog2Component.openDialog(
        'Warning',
        this.errorMessage,
        this.modalService,
        (result: boolean) => {
          if(!this.tiers || this.tiers.length <= 1){
            this.closeWindow(false);
          }
        },
        options);
    }
  }

  public validateFormulaEvaluation(): void {

    let hasFormula = (this.formulaSettings.formulaType === FormulaTypeEnum.Formula ||
      this.formulaSettings.erFormulaType === FormulaTypeEnum.Formula ||
      this.formulaSettings.empFormulaType === FormulaTypeEnum.Formula);

    if (hasFormula) {

      this.errorMessage = this.commonValidationService.getFormulaError(
        this.isEmpFormulaType,
        this.isErFormulaType,
        this.isFormula,
        this.formulaSettings.empContribution,
        this.formulaSettings.erContribution,
        this.formulaSettings.calculatedCoverage
      );

      if (this.errorMessage && this.errorMessage != '') {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = false;
        options.showOK = true;
        ConfirmDialog2Component.openDialog(
          'Warning',
          this.errorMessage,
          this.modalService,
          (result: boolean) => { },
          options);
      }
    }
  }
  public OnDeductionEndDateChange(date: Date) {
    this.model.plan.payrollDeductionEndDate = date;
  }
  public OnDeductionStartDateChange(date: Date) {
    this.model.plan.payrollDeductionStartDate = date;
  }
}
