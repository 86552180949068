/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./configure-partner-positions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../partner-position-mapping/partner-position-mapping.component.ngfactory";
import * as i3 from "../../services/accessManagement/access-management.service";
import * as i4 from "../../services/integrations/partner-config-api.service";
import * as i5 from "../../../common/services/api/api-util.service";
import * as i6 from "../../../common/services/url-params/url-params.service";
import * as i7 from "../../../common/services/cache/cache.service";
import * as i8 from "../../services/integrations/partner-config-map.service";
import * as i9 from "../../services/integrations/partner-config-management.service";
import * as i10 from "../../services/roles/roles-api.service";
import * as i11 from "../../../organization/services/lookup/lookup-api.service";
import * as i12 from "../../../common/services/change-management/change-management.service";
import * as i13 from "../../../common/services/state-management/state-management.service";
import * as i14 from "../partner-position-mapping/partner-position-mapping.component";
import * as i15 from "../../../scheduler/services/schedule/shift-request.service";
import * as i16 from "../../../../../node_modules/@progress/kendo-angular-layout/dist/es2015/index.ngfactory";
import * as i17 from "@progress/kendo-angular-layout";
import * as i18 from "@progress/kendo-angular-l10n";
import * as i19 from "@angular/common";
import * as i20 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i21 from "../../../common/components/spinner/spinner.component";
import * as i22 from "../../../components-library/components/dropdown-list/dropdown-list.component.ngfactory";
import * as i23 from "../../../components-library/components/dropdown-list/dropdown-list.component";
import * as i24 from "@angular/forms";
import * as i25 from "../../directives/stateManagement/list-state-management.directive";
import * as i26 from "../../../common/services/modal/modal.service";
import * as i27 from "../../services/positions/positions-configuration-management.service";
import * as i28 from "../../services/positions/positions-configuration-api.service";
import * as i29 from "../../../app-modules/ess-templates/services/ess-templates-api.service";
import * as i30 from "../../../app-settings/services/app-settings-manage.service";
import * as i31 from "./configure-partner-positions.component";
import * as i32 from "../../../scheduler/services/detail-screen/detail-screen-api.service";
import * as i33 from "../../services/agencies/agencies-api.service";
var styles_ConfigurePartnerPositionsComponent = [i0.styles];
var RenderType_ConfigurePartnerPositionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfigurePartnerPositionsComponent, data: {} });
export { RenderType_ConfigurePartnerPositionsComponent as RenderType_ConfigurePartnerPositionsComponent };
function View_ConfigurePartnerPositionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "edit-info-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Positions are editable only at Department level "]))], null, null); }
function View_ConfigurePartnerPositionsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-partner-position-mapping", [], null, null, null, i2.View_PartnerPositionMappingComponent_0, i2.RenderType_PartnerPositionMappingComponent)), i1.ɵprd(4608, null, i3.AccessManagementService, i3.AccessManagementService, []), i1.ɵprd(512, null, i4.PartnerConfigApiService, i4.PartnerConfigApiService, [i5.ApiUtilService, i6.UrlParamsService, i7.CacheUtilService, i8.PartnerConfigMapService]), i1.ɵprd(512, null, i9.PartnerConfigManagementService, i9.PartnerConfigManagementService, [i10.RolesApiService, i11.LookupApiService, i12.ChangeManagementService, i13.StateManagementService, i4.PartnerConfigApiService]), i1.ɵdid(4, 114688, null, 0, i14.PartnerPositionMappingComponent, [i9.PartnerConfigManagementService, i15.ShiftRequestService], { positionsList: [0, "positionsList"], positionName: [1, "positionName"], customerId: [2, "customerId"], partnerId: [3, "partnerId"], addAction: [4, "addAction"], restrictedByOrgLevel: [5, "restrictedByOrgLevel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.positionList; var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.customerId; var currVal_3 = _co.selectedPartnerId; var currVal_4 = _co.addAction; var currVal_5 = _co.management.access.restrictedByOrgLevel; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_ConfigurePartnerPositionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "kendo-tabstrip-tab", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onTabSelected() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i16.View_TabStripTabComponent_0, i16.RenderType_TabStripTabComponent)), i1.ɵdid(1, 1622016, [[2, 4]], 2, i17.TabStripTabComponent, [], { title: [0, "title"], selected: [1, "selected"] }, null), i1.ɵqud(603979776, 3, { _tabContent: 1 }), i1.ɵqud(335544320, 4, { tabTitle: 0 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ConfigurePartnerPositionsComponent_4)), i1.ɵdid(5, 16384, [[3, 4]], 0, i17.TabContentDirective, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.first; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ConfigurePartnerPositionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "slx-high-box__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "kendo-tabstrip", [["class", "slx-tabs"]], [[2, "k-widget", null], [2, "k-tabstrip", null], [2, "k-floatwrap", null], [2, "k-header", null], [2, "k-tabstrip-top", null], [2, "k-tabstrip-right", null], [2, "k-tabstrip-bottom", null], [2, "k-tabstrip-left", null], [1, "dir", 0]], null, null, i16.View_TabStripComponent_0, i16.RenderType_TabStripComponent)), i1.ɵprd(256, null, i18.L10N_PREFIX, "kendo.tabstrip", []), i1.ɵprd(131584, null, i18.LocalizationService, i18.LocalizationService, [i18.L10N_PREFIX, [2, i18.MessageService], [2, i18.RTL]]), i1.ɵdid(4, 245760, null, 1, i17.TabStripComponent, [i18.LocalizationService, i1.Renderer2, i1.ElementRef], null, null), i1.ɵqud(603979776, 2, { tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurePartnerPositionsComponent_3)), i1.ɵdid(7, 278528, null, 0, i19.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_9 = _co.partnerPositions; _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).hostClasses; var currVal_1 = i1.ɵnov(_v, 4).hostClasses; var currVal_2 = i1.ɵnov(_v, 4).hostClasses; var currVal_3 = i1.ɵnov(_v, 4).hostClasses; var currVal_4 = i1.ɵnov(_v, 4).tabsAtTop; var currVal_5 = i1.ɵnov(_v, 4).tabsAtRight; var currVal_6 = i1.ɵnov(_v, 4).tabsAtBottom; var currVal_7 = i1.ɵnov(_v, 4).tabsAtLeft; var currVal_8 = i1.ɵnov(_v, 4).dir; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_ConfigurePartnerPositionsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { grid: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 21, "slx-spinner", [], null, null, null, i20.View_SpinnerComponent_0, i20.RenderType_SpinnerComponent)), i1.ɵdid(2, 49152, null, 0, i21.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 19, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 16, "div", [["class", "slx-content-toolbar-indents cp-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "col-xs-2 col-md-2 col-lg-2 padd-l-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "slx-dropdown-list", [["class", "slx-wide slx-short"], ["name", "agency_name"], ["placeholder", "Select Agency"], ["titleField", "agency_name"], ["valueField", "partner_id"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectedPartner = $event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = (_co.selectionChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i22.View_DropdownListComponent_0, i22.RenderType_DropdownListComponent)), i1.ɵdid(7, 49152, null, 0, i23.DropdownListComponent, [], { options: [0, "options"], valueField: [1, "valueField"], titleField: [2, "titleField"], placeholder: [3, "placeholder"] }, null), i1.ɵprd(1024, null, i24.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i23.DropdownListComponent]), i1.ɵdid(9, 671744, null, 0, i24.NgModel, [[8, null], [8, null], [8, null], [6, i24.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i24.NgControl, null, [i24.NgModel]), i1.ɵdid(11, 16384, null, 0, i24.NgControlStatus, [[4, i24.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-xs-8 col-md-8 col-lg-8 padd-l-0 positionError"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurePartnerPositionsComponent_1)), i1.ɵdid(14, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "col-xs-2 col-md-2 col-lg-2 padd-l-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "slx-button slx-blue slx-with-icon slx-mobile-adapted slx-margin-r slx-no-breaks"], ["type", "button"]], [[1, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).interceptAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 212992, null, 0, i25.ListAddOperationDirective, [i3.AccessManagementService, i26.ModalService], { interceptedMethod: [0, "interceptedMethod"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-plus slx-button__icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "span", [["class", "slx-button__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add Position"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfigurePartnerPositionsComponent_2)), i1.ɵdid(22, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 2, 0, currVal_0); var currVal_8 = _co.agencyListData; var currVal_9 = "partner_id"; var currVal_10 = "agency_name"; var currVal_11 = "Select Agency"; _ck(_v, 7, 0, currVal_8, currVal_9, currVal_10, currVal_11); var currVal_12 = "agency_name"; var currVal_13 = _co.selectedPartner; _ck(_v, 9, 0, currVal_12, currVal_13); var currVal_14 = _co.management.access.restrictedByOrgLevel; _ck(_v, 14, 0, currVal_14); var currVal_16 = _co.onAddItem; _ck(_v, 17, 0, currVal_16); var currVal_17 = _co.state.configureMode; _ck(_v, 22, 0, currVal_17); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 11).ngClassValid; var currVal_6 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_15 = i1.ɵnov(_v, 17).disabled; _ck(_v, 16, 0, currVal_15); }); }
export function View_ConfigurePartnerPositionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-partner-configure-positions", [], null, null, null, View_ConfigurePartnerPositionsComponent_0, RenderType_ConfigurePartnerPositionsComponent)), i1.ɵprd(512, null, i3.AccessManagementService, i3.AccessManagementService, []), i1.ɵprd(512, null, i27.PositionsManagementService, i27.PositionsManagementService, [i3.AccessManagementService, i12.ChangeManagementService, i28.PositionsConfigurationApiService, i11.LookupApiService, i29.EssTemplateApiService, i30.AppSettingsManageService]), i1.ɵprd(512, null, i9.PartnerConfigManagementService, i9.PartnerConfigManagementService, [i10.RolesApiService, i11.LookupApiService, i12.ChangeManagementService, i13.StateManagementService, i4.PartnerConfigApiService]), i1.ɵdid(4, 245760, null, 0, i31.ConfigurePartnerPositionsComponent, [i27.PositionsManagementService, i26.ModalService, i4.PartnerConfigApiService, i9.PartnerConfigManagementService, i32.DetailScreenService, i33.AgenciesApiService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var ConfigurePartnerPositionsComponentNgFactory = i1.ɵccf("slx-partner-configure-positions", i31.ConfigurePartnerPositionsComponent, View_ConfigurePartnerPositionsComponent_Host_0, {}, {}, []);
export { ConfigurePartnerPositionsComponentNgFactory as ConfigurePartnerPositionsComponentNgFactory };
