import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import * as moment from 'moment';
import { IndSchOrgLevelEmployee, IndSchEmployeeSchedulesRequest } from '../../models/index';
import { IndividualScheduleActions } from '../../store/individual-schedule/individual-schedule.actions';
import { IIndividualScheduleState, IIndividualScheduleCycle } from '../../store/index';
import { unsubscribeInService, mutableSelect } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { IndividualScheduleEmpManagementService } from './individual-schedule-emp-management.service';
import { Assert } from '../../../framework/index';
import { Subject } from 'rxjs/Subject';
var IndividualScheduleManagementService = /** @class */ (function () {
    function IndividualScheduleManagementService(individualScheduleActions, individualScheduleEmpManagementService) {
        var _this = this;
        this.individualScheduleActions = individualScheduleActions;
        this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
        this.m_showEmployeeList = true;
        this.onSelectionChanged$ = new ReplaySubject(1);
        this.onRestoreEmpSelection$ = new ReplaySubject(1);
        this.onEmpListLock$ = new ReplaySubject(1);
        this.restoredEmployeeId = null;
        this.restoredOrglevelId = null;
        this.onSearchEmpSelected$ = new ReplaySubject(1);
        this.loadOrgLvlEmployees$ = new Subject();
        this.stateSubscription = this.orgLevel$
            .combineLatest(this.stateByOrglevel$)
            .subscribe(function (_a) {
            var orgLevel = _a[0], state = _a[1];
            if (!orgLevel || !state) {
                return;
            }
            var cycle = state.stateByOrglevel[orgLevel.id] ? state.stateByOrglevel[orgLevel.id].cycle : null;
            if (!cycle) {
                return;
            }
            if (_this.restoredOrglevelId === orgLevel.id
                && moment(cycle.startDate).isSame(_this.restoredStartDate)
                && moment(cycle.endDate).isSame(_this.restoredEndDate)) {
                return;
            }
            var employeeId = state.stateByOrglevel[orgLevel.id] ? state.stateByOrglevel[orgLevel.id].employeeId : null;
            _this.onRestoreEmpSelection(orgLevel.id, cycle, employeeId);
        });
        this.uiLockSubscription = individualScheduleEmpManagementService.onUILock$.subscribe(function (value) {
            _this.onEmpListLock(value);
        });
    }
    Object.defineProperty(IndividualScheduleManagementService.prototype, "showEmployeeList", {
        get: function () {
            return this.m_showEmployeeList;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleManagementService.prototype, "isDirty", {
        get: function () {
            return this.individualScheduleEmpManagementService.isDirty;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualScheduleManagementService.prototype.onEmpListLock = function (isLock) {
        this.onEmpListLock$.next(isLock);
    };
    IndividualScheduleManagementService.prototype.onRestoreEmpSelection = function (orgLevelId, cycle, employeeId) {
        this.restoredOrglevelId = orgLevelId;
        this.restoredEmployeeId = employeeId;
        this.restoredStartDate = cycle.startDate;
        this.restoredEndDate = cycle.endDate;
        this.onRestoreEmpSelection$.next({ orgLevelId: orgLevelId, employeeId: employeeId });
    };
    IndividualScheduleManagementService.prototype.onEmployeeSelected = function (event) {
        this.selectedEmployee = event;
        this.onSelectionChanged$.next(event);
        if (this.selectedEmployee) {
            this.individualScheduleActions.selectItem(this.selectedEmployee.employee.id);
        }
    };
    IndividualScheduleManagementService.prototype.onScheduleCycleSelected = function (cycle) {
        this.selectedCycle = cycle;
        this.individualScheduleActions.selectScheduleCycle(cycle);
    };
    IndividualScheduleManagementService.prototype.onEmployeeRequest = function (req) {
        this.storedReq = req;
        this.individualScheduleEmpManagementService.onEmployeeRequest(req);
    };
    IndividualScheduleManagementService.prototype.subscribeToOnSearchEmpSelected = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.onSearchEmpSelected$.subscribe(callback);
    };
    IndividualScheduleManagementService.prototype.subscribeToLoadOrgLvlEmployees = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadOrgLvlEmployees$.subscribe(callback);
    };
    IndividualScheduleManagementService.prototype.onScheduleChange = function (cycle, weeksCount) {
        this.storedReq.startDate = cycle.startDate.toDate();
        this.storedReq.scheduleWeeksCount = weeksCount;
        this.onEmployeeRequest(this.storedReq);
    };
    IndividualScheduleManagementService.prototype.changeEmployeeListVisibility = function () {
        this.m_showEmployeeList = !this.m_showEmployeeList;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['individualSchedule']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleManagementService.prototype, "stateByOrglevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleManagementService.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleManagementService.prototype, "uiLockSubscription", void 0);
    return IndividualScheduleManagementService;
}());
export { IndividualScheduleManagementService };
