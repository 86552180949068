import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import {
  IEssOption, EssOption,
  IEssTemplateDefinition, EssTemplateDefinition,
  IEssTemplateOption, EssTemplateOption,
  IEssTemplateOptionGroup, EssTemplateOptionGroup,
  IEssTemplate, EssTemplate,
  IEssTemplateDeletionRespose, EssTemplateDeletionRespose
} from '../models/index';

import { LookupMapService } from '../../../organization/services/index';
@Injectable()
export class EssTemplateMapService {

  constructor(private lookupMapService: LookupMapService) {

  }
  public mapDeletionResponse(dto: IEssTemplateDeletionRespose): EssTemplateDeletionRespose {
    const data = new EssTemplateDeletionRespose();
    data.isSuccess = dto.isSuccess;
    data.message = dto.message;
    data.assignedPositions = this.lookupMapService.mapPositions(dto.assignedPositions);
    return data;
  }
  public mapToEssOption(dto: IEssOption): EssOption {
    const data = new EssOption();
    data.id = dto.id;
    data.parentId = dto.parentId;
    data.name = dto.name;
    data.description = dto.description;
    data.defaultValue = dto.defaultValue;
    return data;
  }

  public mapToEssOptionDto(data: EssOption): IEssOption {
    const dto: IEssOption = {
      id: data.id,
      parentId: data.parentId,
      name: data.name,
      description: data.description,
      defaultValue: data.defaultValue
    };
    return dto;
  }

  public mapToEssTemplateDefinition(dto: IEssTemplateDefinition): EssTemplateDefinition {
    const data = new EssTemplateDefinition();
    data.id = dto.id;
    data.orgLevelId = dto.orgLevelId;
    data.name = dto.name;
    data.isDefault = dto.isDefault;
    data.attestationGroupId = dto.attestationGroupId;
    return data;
  }

  public mapToEssTemplateDefinitions(dtos: IEssTemplateDefinition[]): EssTemplateDefinition[] {
    return _.map(dtos, (dto: IEssTemplateDefinition) => this.mapToEssTemplateDefinition(dto));
  }

  public mapToEssTemplates(dtos: IEssTemplate[]): EssTemplate[] {
    return _.map(dtos, (dto: IEssTemplate) => this.mapToEssTemplate(dto));
  }

  public mapToEssTemplateDefinitionDto(data: EssTemplateDefinition): IEssTemplateDefinition {
    const dto: IEssTemplateDefinition = {
      id: data.id,
      orgLevelId: data.orgLevelId,
      name: data.name,
      isDefault: data.isDefault,
      attestationGroupId: data.attestationGroupId
    };
    return dto;
  }

  public mapToEssTemplateOption(dto: IEssTemplateOption): EssTemplateOption {
    const data = new EssTemplateOption();
    data.id = dto.id;
    data.templateId = dto.templateId;
    data.essOption = this.mapToEssOption(dto.essOption);
    data.value = dto.value;
    data.childs = [];
    return data;
  }

  public mapToEssTemplateOptionDto(data: EssTemplateOption): IEssTemplateOption {
    const dto: IEssTemplateOption = {
      id: data.id,
      templateId: data.templateId,
      essOption: this.mapToEssOptionDto(data.essOption),
      value: data.value
    };
    return dto;
  }

  public mapToEssTemplateOptionGroup(dto: IEssTemplateOptionGroup): EssTemplateOptionGroup {
    const data = new EssTemplateOptionGroup();
    data.id = dto.id;
    data.name = dto.name;
    const flatOptions = _.map(dto.options, (odto: IEssTemplateOption) => this.mapToEssTemplateOption(odto));
    data.options = this.createHierarchy(flatOptions, 0);
    return data;
  }

  public mapToEssTemplateOptionGroupDto(data: EssTemplateOptionGroup): IEssTemplateOptionGroup {
    const flatOptions = this.flatten(data.options);
    const dto: IEssTemplateOptionGroup = {
      id: data.id,
      name: data.name,
      options: _.map(flatOptions, (opt: EssTemplateOption) => this.mapToEssTemplateOptionDto(opt))
    };
    return dto;
  }

  public mapToEssTemplate(dto: IEssTemplate): EssTemplate {
    const data = new EssTemplate();
    data.essTemplateDefinition = this.mapToEssTemplateDefinition(dto.essTemplateDefinition);
    data.groups = _.map(dto.groups, (gdto: IEssTemplateOptionGroup) => this.mapToEssTemplateOptionGroup(gdto));
    data.defaultPwdTemplate = dto.defaultPwdTemplate;
    data.timeEntryMethod = dto.timeEntryMethod;
    data.certifyTimecardMessage = dto.certifyTimecardMessage;
    return data;
  }

  public mapToEssTemplateDto(data: EssTemplate): IEssTemplate {
    const dto: IEssTemplate = {
      essTemplateDefinition: this.mapToEssTemplateDefinitionDto(data.essTemplateDefinition),
      groups: _.map(data.groups, (g: EssTemplateOptionGroup) => this.mapToEssTemplateOptionGroupDto(g)),
      defaultPwdTemplate: data.defaultPwdTemplate.toString(),
      timeEntryMethod: data.timeEntryMethod.toString(),
      certifyTimecardMessage: data.certifyTimecardMessage
    };
    return dto;
  }

  private createHierarchy(flatOptions: EssTemplateOption[], parentId: number): EssTemplateOption[] {
    const roots = _.filter(flatOptions, (option: EssTemplateOption) => option.essOption.parentId === parentId);
    const childFlatOptions = _.filter(flatOptions, (option: EssTemplateOption) => option.essOption.parentId !== parentId);
    _.forEach(roots, (root: EssTemplateOption) => {
      root.childs = this.createHierarchy(childFlatOptions, root.essOption.id);
    });
    return roots;
  }

  private flatten(options: EssTemplateOption[]): EssTemplateOption[] {
    const flat = _.map(options);
    _.forEach(options, (option: EssTemplateOption) => {
      flat.push(...this.flatten(option.childs));
    });
    return flat;
  }
}
