import * as tslib_1 from "tslib";
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import * as moment from 'moment';
import { OnDestroy, Provider } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { DialogOptions } from '../../../common/models/index';
import { unsubscribe } from '../../../core/decorators/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
var IncativityNotificationComponent = /** @class */ (function () {
    function IncativityNotificationComponent(options, modalService, userActivityService, appSettingsManageService) {
        var _this = this;
        this.options = options;
        this.modalService = modalService;
        this.userActivityService = userActivityService;
        this.appSettingsManageService = appSettingsManageService;
        this.dialogResult = false;
        this.timeoutCountdownSubscription = this.userActivityService.onTimeoutCountdown.subscribe(function (countdown) {
            _this.remainingTimeSeconds = countdown;
        });
    }
    Object.defineProperty(IncativityNotificationComponent.prototype, "remainingTime", {
        get: function () {
            var duration = moment.duration(this.remainingTimeSeconds, 'seconds');
            var seconds = duration.seconds();
            var minutes = duration.minutes();
            return duration.minutes() + ":" + (seconds < 10 ? '0' + seconds : seconds);
        },
        enumerable: true,
        configurable: true
    });
    IncativityNotificationComponent.openDialog = function (modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 165;
        dialogOptions.width = 300;
        dialogOptions.hideTitleBar = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(IncativityNotificationComponent, 'Inactivity Warning', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    IncativityNotificationComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(IncativityNotificationComponent.prototype, "inactivityThresholdMinutes", {
        get: function () {
            var duration = moment.duration(this.userActivityService.thresholdSeconds, 'seconds');
            return "" + duration.minutes();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncativityNotificationComponent.prototype, "timeoutAfterMinutes", {
        get: function () {
            var duration = moment.duration(this.userActivityService.timeoutSeconds, 'seconds');
            return "" + duration.minutes();
        },
        enumerable: true,
        configurable: true
    });
    IncativityNotificationComponent.prototype.close = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IncativityNotificationComponent.prototype, "timeoutCountdownSubscription", void 0);
    return IncativityNotificationComponent;
}());
export { IncativityNotificationComponent };
