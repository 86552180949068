import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';

export class ShiftLate {
  public lateInterval: number = 0;
  public timesLateLastYear: number= 0;
  public timesLateLastMonth: number = 0;
  public arrivalDate: Date;
}
