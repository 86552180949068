/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./budget-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../budget-header/budget-header.component.ngfactory";
import * as i3 from "../budget-header/budget-header.component";
import * as i4 from "../../../services/budget/budget-management.service";
import * as i5 from "../../../../common/services/modal/modal.service";
import * as i6 from "../../../../organization/services/org-level/org-level-watch.service";
import * as i7 from "../budget-grid/budget-grid.component.ngfactory";
import * as i8 from "../budget-grid/budget-grid.component";
import * as i9 from "../budgeted-group-editor/budgeted-group-editor.component.ngfactory";
import * as i10 from "../budgeted-group-editor/budgeted-group-editor.component";
import * as i11 from "../../../../organization/services/lookup/lookup-api.service";
import * as i12 from "../../../services/budget/budget-api.service";
import * as i13 from "../budget-history/budget-history.component.ngfactory";
import * as i14 from "../budget-history/budget-history.component";
import * as i15 from "../budget-census-adjust/budget-census-adjust.component.ngfactory";
import * as i16 from "../budget-census-adjust/budget-census-adjust.component";
import * as i17 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i18 from "../../../../common/components/spinner/spinner.component";
import * as i19 from "@angular/common";
import * as i20 from "../../../../common/services/change-management/change-management.service";
import * as i21 from "./budget-list.component";
var styles_BudgetListComponent = [i0.styles];
var RenderType_BudgetListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BudgetListComponent, data: {} });
export { RenderType_BudgetListComponent as RenderType_BudgetListComponent };
function View_BudgetListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-budget-header", [["class", "slx-content-toolbar-indents slx-auto-height"]], null, null, null, i2.View_BudgetHeaderComponent_0, i2.RenderType_BudgetHeaderComponent)), i1.ɵdid(2, 245760, null, 0, i3.BudgetHeaderComponent, [i4.BudgetManagementService, i5.ModalService, i6.OrgLevelWatchService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-budget-grid", [], null, null, null, i7.View_BudgetGridComponent_0, i7.RenderType_BudgetGridComponent)), i1.ɵdid(5, 245760, null, 0, i8.BudgetGridComponent, [i4.BudgetManagementService, i5.ModalService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 5, 0); }, null); }
function View_BudgetListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-full-height slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-budgeted-group-editor", [], null, [[null, "finishEditing"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("finishEditing" === en)) {
        var pd_0 = (_co.groupsfinishEditing($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_BudgetedGroupEditorComponent_0, i9.RenderType_BudgetedGroupEditorComponent)), i1.ɵdid(2, 245760, null, 0, i10.BudgetedGroupEditorComponent, [i4.BudgetManagementService, i11.LookupApiService, i12.BudgetApiService], { startDate: [0, "startDate"], orgLevelId: [1, "orgLevelId"] }, { finishEditing: "finishEditing" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.budget == null) ? null : _co.budget.startDate); var currVal_1 = _co.selectedOrganizationOrgLevelId; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_BudgetListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-full-height slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-budget-history", [], null, [[null, "finishEditing"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("finishEditing" === en)) {
        var pd_0 = (_co.restorefinishEditing($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_BudgetHistoryComponent_0, i13.RenderType_BudgetHistoryComponent)), i1.ɵdid(2, 704512, null, 0, i14.BudgetHistoryComponent, [i12.BudgetApiService, i5.ModalService], { orgLevelId: [0, "orgLevelId"] }, { finishEditing: "finishEditing" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedOrganizationOrgLevelId; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BudgetListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "slx-full-height slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-budget-census-adjust", [], null, [[null, "finishEditing"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("finishEditing" === en)) {
        var pd_0 = (_co.budgetCensusAdjustfinishEditing($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_BudgetCensusAdjustComponent_0, i15.RenderType_BudgetCensusAdjustComponent)), i1.ɵdid(2, 573440, null, 0, i16.BudgetCensusAdjustComponent, [], { startDate: [0, "startDate"], budgetCensus: [1, "budgetCensus"] }, { finishEditing: "finishEditing" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.budget.startDate; var currVal_1 = _co.budget.census; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_BudgetListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "slx-spinner", [], null, null, null, i17.View_SpinnerComponent_0, i17.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i18.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "div", [["class", "slx-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BudgetListComponent_1)), i1.ɵdid(4, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BudgetListComponent_2)), i1.ɵdid(6, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BudgetListComponent_3)), i1.ɵdid(8, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BudgetListComponent_4)), i1.ɵdid(10, 16384, null, 0, i19.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.state.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((!_co.state.isBudgetGroupEditMode && !_co.state.isRestoreBudgetMode) && !_co.state.isBudgetAdjustMode); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.state.isBudgetGroupEditMode; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.state.isRestoreBudgetMode; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.state.isBudgetAdjustMode; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_BudgetListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-budget-list", [], null, null, null, View_BudgetListComponent_0, RenderType_BudgetListComponent)), i1.ɵprd(512, null, i4.BudgetManagementService, i4.BudgetManagementService, [i12.BudgetApiService, i20.ChangeManagementService]), i1.ɵdid(2, 245760, null, 0, i21.BudgetListComponent, [i4.BudgetManagementService, i5.ModalService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var BudgetListComponentNgFactory = i1.ɵccf("slx-budget-list", i21.BudgetListComponent, View_BudgetListComponent_Host_0, {}, {}, []);
export { BudgetListComponentNgFactory as BudgetListComponentNgFactory };
