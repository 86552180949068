var BenAdminMenuItemState = /** @class */ (function () {
    function BenAdminMenuItemState() {
    }
    Object.defineProperty(BenAdminMenuItemState.prototype, "isMenuAccessible", {
        get: function () {
            return this.IsEmployeeMappedToUser &&
                this.EmployeeRecordHasSSN &&
                this.EmployeeRecordHasDateOfBirth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenAdminMenuItemState.prototype, "userMessage", {
        get: function () {
            if (!this.isMenuAccessible) {
                if (!this.IsEmployeeMappedToUser) {
                    return 'This user does not have a valid employee record associated with it. Contact your HR administrator for more information.';
                }
                else if (!this.EmployeeRecordHasSSN || !this.EmployeeRecordHasDateOfBirth) {
                    return 'Your employee record does not have an SSN or DOB. Contact your HR administrator for more information.';
                }
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    return BenAdminMenuItemState;
}());
export { BenAdminMenuItemState };
