import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef, OnInit, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import { Assert } from '../../../framework/index';
import { ValuePairWidgetConfig } from '../../models/value-pair-widget/value-pair-widget-config';
import { ValuePairChartDataService } from '../../services/value-pair-chart-data/value-pair-chart-data.service';
import { ChartWidgetTopTemplateDirective, ChartWidgetMiddleTemplateDirective, ChartWidgetBottomTemplateDirective } from '../../directives/index';
import { ValuePairChartInput } from '../../models/value-pair-chart/value-pair-chart-input';

@Component({
  moduleId: module.id,
  selector: 'slx-chart-widget',
  templateUrl: 'chart-widget.component.html',
  styleUrls: ['chart-widget.component.scss'],
  providers: [ValuePairChartDataService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartWidgetComponent implements OnInit, OnChanges {

  @Input()
  public config: ValuePairWidgetConfig;

  @Input()
  public input: ValuePairChartInput;

  @Input()
  public useDefaultClickHanlder: boolean;

  @Output()
  public onWidgetDefaultClick: EventEmitter<any>;

  @ContentChild(ChartWidgetTopTemplateDirective, {static: true})
  public topDirective: ChartWidgetTopTemplateDirective;

  @ContentChild(ChartWidgetMiddleTemplateDirective, {static: true})
  public middleDirective: ChartWidgetMiddleTemplateDirective;

  @ContentChild(ChartWidgetBottomTemplateDirective, {static: true})
  public bottomDirective: ChartWidgetBottomTemplateDirective;

  constructor(private dataService: ValuePairChartDataService) {
    this.onWidgetDefaultClick = new EventEmitter();
  }

  public ngOnInit(): void {
    Assert.isNotNull(this.config, 'config');

    this.dataService.setConfig(this.config);
    this.updateChartDataService();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['input']) {
      this.updateChartDataService();
    }
  }

  public defaultClickHandler(): void {
    this.onWidgetDefaultClick.emit();
  }

  private updateChartDataService(): void {
    if (!this.config || !this.input) {
      return;
    }

    this.dataService.setModel(this.input, this.config);
  }
}
