import { DialogOptions2, DialogModeSize } from './../../../../../common/models/dialog-options';
import { Provider } from '@angular/core';
import * as _ from 'lodash';
import { ConfirmDialog2Component, ConfirmOptions, IDialog } from '../../../../../common/index';
import { DateRange } from '../../../../../core/models/index';
import { ModalService } from '../../../../../common/services/index';
var EmployeeDependentDropEnrollmentDialogComponent = /** @class */ (function () {
    function EmployeeDependentDropEnrollmentDialogComponent(dialogOptions, modalService, options) {
        this.modalService = modalService;
        this.dialogOptions = dialogOptions;
        this.options = options;
    }
    Object.defineProperty(EmployeeDependentDropEnrollmentDialogComponent.prototype, "canDrop", {
        get: function () {
            return _.isObjectLike(this.dropEnrollment)
                && _.isDate(this.dropEnrollment.eventDate)
                && _.isDate(this.dropEnrollment.coverageEndDate)
                && _.isObjectLike(this.dropEnrollment.reason);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeDependentDropEnrollmentDialogComponent.openDialog = function (options, modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.height = 300;
        dialogOptions.width = 550;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: DateRange,
                useValue: options
            }
        ];
        var title = 'Drop Benefit Coverage';
        return modalService.globalAnchor.openDialog2(EmployeeDependentDropEnrollmentDialogComponent, title, dialogOptions, resolvedProviders, function (result) {
            callback(result);
        });
    };
    EmployeeDependentDropEnrollmentDialogComponent.prototype.onDropEnrollment = function (drop) {
        this.dropEnrollment = drop;
    };
    EmployeeDependentDropEnrollmentDialogComponent.prototype.onAdd = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    EmployeeDependentDropEnrollmentDialogComponent.prototype.onCancel = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Discard Changes', 'Are you sure you want to cancel? You will lose all unsaved selections.', this.modalService, function (isCancel) {
            if (isCancel) {
                _this.dialogResult = false;
                _this.modalService.closeWindow(_this.dialogOptions.windowUniqueId);
            }
        }, options);
    };
    return EmployeeDependentDropEnrollmentDialogComponent;
}());
export { EmployeeDependentDropEnrollmentDialogComponent };
