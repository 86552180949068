import { EventEmitter } from '@angular/core';
import { ShiftDefinition, Position, ConstraintDefinition, LocationUnit, ScheduleAbsence } from '../../../../../organization/models/index';
import { appConfig } from '../../../../../app.config';
var ScheduledShiftMainSectionComponent = /** @class */ (function () {
    function ScheduledShiftMainSectionComponent() {
        this.index = -1;
        this.appConfig = appConfig;
        this.onPositionChanged = new EventEmitter();
        this.onShiftChanged = new EventEmitter();
        this.onUnitChanged = new EventEmitter();
        this.onConstraintChanged = new EventEmitter();
        this.onAbsenceChanged = new EventEmitter();
        this.onStartDateChanged = new EventEmitter();
        this.onEndDateChanged = new EventEmitter();
        this.onShiftHoursChanged = new EventEmitter();
        this.onUnpaidHoursChanged = new EventEmitter();
    }
    Object.defineProperty(ScheduledShiftMainSectionComponent.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        set: function (value) {
            this.m_container = value;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledShiftMainSectionComponent.prototype.positionChanged = function (position) {
        this.onPositionChanged.next(position);
    };
    ScheduledShiftMainSectionComponent.prototype.shiftChange = function (shift) {
        this.onShiftChanged.next(shift);
    };
    ScheduledShiftMainSectionComponent.prototype.unitChange = function (unit) {
        this.onUnitChanged.next(unit);
    };
    ScheduledShiftMainSectionComponent.prototype.constraintChange = function (constraint) {
        this.onConstraintChanged.next(constraint);
    };
    ScheduledShiftMainSectionComponent.prototype.absenceChange = function (absence) {
        this.onAbsenceChanged.next(absence);
    };
    ScheduledShiftMainSectionComponent.prototype.onShiftStartDateChanged = function () {
        this.onStartDateChanged.next(this.m_container.shiftStartDate);
    };
    ScheduledShiftMainSectionComponent.prototype.onShiftEndDateChanged = function () {
        this.onEndDateChanged.next(this.m_container.shiftEndDate);
    };
    ScheduledShiftMainSectionComponent.prototype.shiftPaidTimeChanged = function (value) {
        this.onShiftHoursChanged.next(this.m_container.shiftHours);
    };
    ScheduledShiftMainSectionComponent.prototype.shiftUnpaidTimeChanged = function (value) {
        this.onUnpaidHoursChanged.next(this.m_container.unpaidHours);
    };
    return ScheduledShiftMainSectionComponent;
}());
export { ScheduledShiftMainSectionComponent };
