/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toolbar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-popper";
import * as i4 from "../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i5 from "./toolbar.component";
import * as i6 from "../../../core/services/toolbar/toolbar-base.service";
var styles_ToolbarComponent = [i0.styles];
var RenderType_ToolbarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToolbarComponent, data: {} });
export { RenderType_ToolbarComponent as RenderType_ToolbarComponent };
function View_ToolbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ToolbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ToolbarComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { sectionType: 0, counts: 1, isCollapsed: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.sectionType, _co.counts, _co.isCollapsedSection(_v.context.$implicit.sectionType)); var currVal_1 = _v.context.$implicit.templateRef; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ToolbarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "span", [["class", "expand-toolbar-button"]], null, [[null, "popperOnShown"], [null, "popperOnHidden"], [null, "touchstart"], [null, "click"], [null, "mousedown"], [null, "mouseenter"], [null, "touchend"], [null, "touchcancel"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("touchstart" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).showOrHideOnMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).showOrHideOnClick($event) !== false);
        ad = (pd_1 && ad);
    } if (("mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).showOrHideOnMouseOver($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseenter" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4).showOnHover($event) !== false);
        ad = (pd_3 && ad);
    } if (("touchend" === en)) {
        var pd_4 = (i1.ɵnov(_v, 4).hideOnLeave($event) !== false);
        ad = (pd_4 && ad);
    } if (("touchcancel" === en)) {
        var pd_5 = (i1.ɵnov(_v, 4).hideOnLeave($event) !== false);
        ad = (pd_5 && ad);
    } if (("mouseleave" === en)) {
        var pd_6 = (i1.ɵnov(_v, 4).hideOnLeave($event) !== false);
        ad = (pd_6 && ad);
    } if (("popperOnShown" === en)) {
        var pd_7 = (_co.showCollapsed() !== false);
        ad = (pd_7 && ad);
    } if (("popperOnHidden" === en)) {
        var pd_8 = (_co.hideCollapsed() !== false);
        ad = (pd_8 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "expanded": 0 }), i1.ɵdid(4, 737280, null, 0, i3.PopperController, [i1.ViewContainerRef, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i1.Renderer2], { content: [0, "content"], placement: [1, "placement"], showTrigger: [2, "showTrigger"], disableStyle: [3, "disableStyle"] }, { popperOnShown: "popperOnShown", popperOnHidden: "popperOnHidden" }), (_l()(), i1.ɵeld(5, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "fa fa-ellipsis-h"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "fa-ellipsis-h": 0, "fa-ellipsis-v": 1 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "expand-toolbar-button"; var currVal_1 = _ck(_v, 3, 0, _co.isExpanded); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 11); var currVal_3 = "bottom-start"; var currVal_4 = "click"; var currVal_5 = "true"; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = "fa fa-ellipsis-h"; var currVal_7 = _ck(_v, 8, 0, !_co.isExpanded, _co.isExpanded); _ck(_v, 7, 0, currVal_6, currVal_7); }, null); }
function View_ToolbarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ToolbarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ToolbarComponent_5)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { sectionType: 0, counts: 1, isCollapsed: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.sectionType, _co.counts, _co.isCollapsedSection(_v.context.$implicit.sectionType)); var currVal_1 = _v.context.$implicit.templateRef; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ToolbarComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_ToolbarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ToolbarComponent_7)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { sectionType: 0, counts: 1, isCollapsed: 2 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.sectionType, _co.counts, _co.isCollapsedSection(_v.context.$implicit.sectionType)); var currVal_1 = _v.context.$implicit.templateRef; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ToolbarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "left-toolbar"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "left-full-width": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "left-toolbar-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolbarComponent_1)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "left-toolbar-button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolbarComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 6, "popper-content", [["class", "toolbar-popup"]], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_PopperContent_0, i4.RenderType_PopperContent)), i1.ɵdid(11, 180224, [["popperContent", 4]], 0, i3.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(12, 0, null, 0, 4, "div", [["class", "collapsed-left-toolbar-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolbarComponent_4)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "div", [["class", "right-toolbar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToolbarComponent_6)), i1.ɵdid(19, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "left-toolbar"; var currVal_1 = _ck(_v, 3, 0, (_co.alignExpandButtonRight && (_co.collapsedSections && (_co.collapsedSections.length > 0)))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.visibleSections; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.collapsedSections && (_co.collapsedSections.length > 0)); _ck(_v, 9, 0, currVal_3); var currVal_4 = "collapsed-left-toolbar-content"; var currVal_5 = _co.customPopperContentClass; _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_6 = _co.collapsedSections; _ck(_v, 16, 0, currVal_6); var currVal_7 = _co.rightSections; _ck(_v, 19, 0, currVal_7); }, null); }
export function View_ToolbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-toolbar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_ToolbarComponent_0, RenderType_ToolbarComponent)), i1.ɵdid(1, 1228800, null, 1, i5.ToolbarComponent, [i1.ElementRef, [2, i6.TOOLBAR_SERVICE], i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { contentChildren: 1 })], null, null); }
var ToolbarComponentNgFactory = i1.ɵccf("slx-toolbar", i5.ToolbarComponent, View_ToolbarComponent_Host_0, { alignExpandButtonRight: "alignExpandButtonRight", isDuaComponent: "isDuaComponent", customPopperContentClass: "customPopperContentClass" }, {}, []);
export { ToolbarComponentNgFactory as ToolbarComponentNgFactory };
