import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';

import { appConfig } from '../../../../app.config';
import { lmConfig } from '../../leave-management.config';

import { Meta } from '../../../../core/models/api/meta';
import { ApiUtilService } from '../../../../common/index';
import { UrlParamsService } from '../../../../common/services/url-params/url-params.service';

import { ResponseBody } from '../../../../core/models/api/response-body';
import { LoaTypeModel, ILoaTypeModelContainer, LoaTypeModelContainer, ILoaTypeEditResult, LoaTypeEditResult } from '../../models/index';
import { LMMapService } from '../lm-map.service';

@Injectable()
export class LMTypeApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private mapService: LMMapService,
    private urlParamsService: UrlParamsService
  ) {
  }

  public async getLoaTypeModels(orgLevelId: number): Promise<LoaTypeModelContainer> {
    const url: string = this.getLoaTypeApi();
    const request = this.urlParamsService.createGetRequest(url, { orgLevelId });
    // return Promise.resolve(this.mapService.mapLoaTypeModelContainer(LoaTypeModelContainer.mock));

    return this.apiUtilService
      .request<ILoaTypeModelContainer, Meta>(request)
      .then((response: ResponseBody<ILoaTypeModelContainer, Meta>) => this.mapService.mapLoaTypeModelContainer(response.data));

  }

  public async createLoaType(req: LoaTypeModel): Promise<LoaTypeEditResult> {
    const url: string = this.getLoaTypeApi();
    const body = this.mapService.mapLoaTypeModelToDto(req);
    const request = this.urlParamsService.createPostRequest(url, body);

    return this.apiUtilService
      .request<ILoaTypeEditResult, Meta>(request)
      .then((response: ResponseBody<ILoaTypeEditResult, Meta>) => this.mapService.mapLoaTypeEditResult(response.data));
  }

  public async modifyLoaType(req: LoaTypeModel): Promise<LoaTypeEditResult> {
    const url: string = this.getLoaTypeApi();
    const body = this.mapService.mapLoaTypeModelToDto(req);
    const request = this.urlParamsService.createPutRequest(url, body);

    return this.apiUtilService
      .request<ILoaTypeEditResult, Meta>(request)
      .then((response: ResponseBody<ILoaTypeEditResult, Meta>) => this.mapService.mapLoaTypeEditResult(response.data));
  }

  public async deleteLoaType(id: number): Promise<LoaTypeEditResult> {
    const url: string = this.getLoaTypeApi();
    const request = this.urlParamsService.createDeleteRequest(url, { id });

    return this.apiUtilService
      .request<ILoaTypeEditResult, Meta>(request)
      .then((response: ResponseBody<ILoaTypeEditResult, Meta>) => this.mapService.mapLoaTypeEditResult(response.data));
  }

  private getLoaTypeApi(): string {
    return `${this.apiUtilService.getApiRoot()}/${lmConfig.api.loa.root}/${lmConfig.api.loa.typeModels.root}`;
  }


}
