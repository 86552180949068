<slx-spinner [show]="isLoading">
  <div class="modal-content">
    <div class="modal-body">
      <slx-manage-position-groups (onLoading)="onLoading($event)" (onHasChanges)="onHasChanges($event)"></slx-manage-position-groups>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
    </div>
  </div>
</slx-spinner>
