<slx-spinner class="dua-spinner" [show]="isLoading">
<div class="open-position-control-main-container">
  <div class="slx-opc-tool-wrapper">
  <slx-open-position-control-toolbar
  class="slx-content-toolbar-indents" [isMobile]="isMobile"
  [startDate]="startDate"
  [endDate]="endDate"
  [currentOrgLevel]="currentOrgLevel"
  (onDatesChanged)="onDatesChanged($event)"
  [openPositionDetails]="openShiftPositions"
  >
  </slx-open-position-control-toolbar>
  </div>
  <div class="open-position-control-content">
    <div class="open-position-control-metrics-container">
      <div class="total-hours-gap">
        <div class="metric-label">Total Hours Gap:</div>
        <div class="count">{{openShiftPositions.totalHoursGap | number}}</div>
      </div>
      <div class="open-shifts">
        <div class="metric-label">Open Shifts:</div>
        <div class="count">{{openShiftPositions.totalOpenShifts | number}}</div>
      </div>
    </div>
    <div *ngFor="let openPositionDetail of openShiftPositions.openPositionDetails;index as i;" class="open-position-control-orglevel-content">
      <slx-collapsible-section [title]="openPositionDetail.orgName" [expanded]="true" mode="grey" [uniqId]="i" [headerOpenClose]="true">
        <ng-container body>
          <div class="orglevel-metrics">
            <div class="total-hours-gap">
              <span class="metric-label">Total Hours Gap:</span>
              <span class="count">{{openPositionDetail.totalHoursGap | number}}</span>
            </div>
            <div class="open-shifts">
              <span class="metric-label">Open Shifts:</span>
              <span class="count">{{openPositionDetail.totalOpenShifts | number}}</span>
            </div>
          </div>
          <div class="department-metrics-container-main">
            <div *ngFor="let orgDepartmentDetails of openPositionDetail.orgDepartmentDetails" class="department-metrics-container">
              <div class="department-metrics" >
                <div class="department-name">
                  {{orgDepartmentDetails.departmentName}}
                </div>
                <div class="department-metrics-details">
                  <div class="no-configuration" *ngIf="orgDepartmentDetails.isNoConfiguration">
                    Ideal Schedule is not configured for this department
                  </div>
                  <div class="no-rotations-data" *ngIf="orgDepartmentDetails.isNoRotationGaps" (click)="openPositionGroupSlideOut(openPositionDetail.orgName, orgDepartmentDetails)">
                    No Rotation Gaps
                  </div>
                  <div class="metric-details" *ngIf="!orgDepartmentDetails.isNoRotationGaps && !orgDepartmentDetails.isNoConfiguration">
                    <div class="department-count-tile total-hours-gap" (click)="openPositionGroupSlideOut(openPositionDetail.orgName, orgDepartmentDetails)">
                      <div class="count">
                        {{orgDepartmentDetails.totalHoursGap | number}}
                      </div>
                      <div class="bar"></div>
                      <div class="metric-label">
                        Hours Gap
                      </div>
                    </div>
                    <div class="department-count-tile open-shifts" (click)="openPositionGroupSlideOut(openPositionDetail.orgName, orgDepartmentDetails)">
                      <div class="count">
                        {{orgDepartmentDetails.totalOpenShifts | number}}
                      </div>
                      <div class="bar"></div>
                      <div class="metric-label">
                        Open Shifts
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </slx-collapsible-section>
    </div>
  </div>
</div>
</slx-spinner>
