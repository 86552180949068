<slx-spinner [show]="state.isLoading||state.isFiltering">
  <div class="slx-high-box daily-punches">
    <div #punchesHeader class="slx-content-toolbar-indents punches-header">
      <div class="left-buttons">
        <div class="header-buttons">
          <div class="date-filter">
              <slx-date-range-ngx
              name="startEndDate"
              [startDate]="selectedRange.startDate"
              [endDate]="selectedRange.endDate"
              [hasApplyButton]="true"
              [maxSecRange]="2678400"
              [maxRangeErrorText]="'You can not choose dates wider than 31 days'"
              (rangeDateChanged)="onFilterDateChanged($event)"
            ></slx-date-range-ngx>
          </div>
        </div>
      </div>
      <div class="right-buttons">

        <div>
          <button #popupDirective type="button" class="theme-icon-button"
          [popper]="filterToggler"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="'true'"
            slxTooltip="Settings" tipPosition="left">
            <i class="fa fa-cog icon-align" aria-hidden="true"></i>
          </button>
          <popper-content #filterToggler class="popper-white-background wide600-popper">
            <slx-punches-filter [container]="container" [orgLevelId]="orgLevelId" (close)="onClose(filterToggler)" (filteringState)="filteringState($event)"></slx-punches-filter>
          </popper-content>
       </div>

      <div>
        <slx-columns-config-button [colGroupKey]="groupName"
          slxTooltip="Employee Punches columns" tipPosition="bottom">
        </slx-columns-config-button>
      </div>

      <div>
        <slx-columns-config-button [colGroupKey]="'DetailsLinePunches'"
          [secondaryIcon]="'far fa-plus fa-xs fa-secondary'"
          slxTooltip="Punches columns" tipPosition="left">
        </slx-columns-config-button>
      </div>

      </div>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-punches-employees-grid ></slx-punches-employees-grid>
    </div>
  </div>
</slx-spinner>
