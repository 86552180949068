import { Directive, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const MAX_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[slxMax][formControlName],[slxMax][formControl],[slxMax][ngModel]',
  /* tslint:enable */
  providers: [MAX_VALIDATOR]
})
export class MaxValidator implements Validator, OnChanges {
  @Input()
  public slxMax: string;

  @Input()
  public slxMaxStrict: boolean = false;

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxMax']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return _.isFinite(parseFloat(this.slxMax)) ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    this._validator = CommonValidators.max(parseFloat(this.slxMax), this.slxMaxStrict);
  }
}
