import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitDetailsBasic, BenefitDetailsFormula } from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
import { NgForm } from '@angular/forms';
var BenefitDetailsCoverageCalcFormulaComponent = /** @class */ (function () {
    function BenefitDetailsCoverageCalcFormulaComponent(permissionService, manService, commonManService) {
        this.permissionService = permissionService;
        this.manService = manService;
        this.commonManService = commonManService;
        this.calculationTypesShort = [];
        this.calculationTypes = [];
        this.empFormula = new BenefitDetailsFormula();
        this.empContribution = new BenefitDetailsBasic(null, null, null);
        this.empFormulaText = '';
        this.empFixed = 0.00;
        this.emprFormula = new BenefitDetailsFormula();
        this.emprContribution = new BenefitDetailsBasic(null, null, null);
        this.emprFormulaText = '';
        this.emprFixed = 0.00;
        this.covFormula = new BenefitDetailsFormula();
        this.coverage = new BenefitDetailsBasic(null, null, null);
        this.covFormulaText = '';
        this.covFixed = 0.00;
        this.covMultiplier = 0.00;
        this.covMaxCap = 0.00;
        this.isEditMode = false;
        this.min = 0;
        this.max = 99999999.99;
        this.multiplierMax = 100;
        this.step = 1;
        this.format = 'c2';
        this.multiplierFormat = 'n2';
        this.hasEnrolledEmployees = false;
        this.hasEnrolledEmpTierLevel = false;
        this.subscriptions = {};
        this.сanEditFormula = false;
        this.showFormula = false;
        this.tierIsDraft = false;
        this.calculationTypes = this.manService.getFormulaTypes();
        this.calculationTypesShort = this.calculationTypes.slice(0, 2);
        this.multiplierMaxValues = [500, 1000, 2000, 5000, 10000];
    }
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isFormulaEditable", {
        get: function () {
            return this.isEditMode && this.сanEditFormula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isEmpFixedCalcType", {
        get: function () {
            return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFixedCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isEmpFormulaCalcType", {
        get: function () {
            return this.empContribution.name === this.empFormula.calculationType && this.empFormula.isFormulaCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isEmprFixedCalcType", {
        get: function () {
            return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFixedCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isEmprFormulaCalcType", {
        get: function () {
            return this.emprContribution.name === this.emprFormula.calculationType && this.emprFormula.isFormulaCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isCovFormulaCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFormulaCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isCovFixedCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isFixedCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isCovMultiplierCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isMultiplierCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isShownFormula", {
        get: function () {
            return this.showFormula;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isCovAnyValueCalcType", {
        get: function () {
            return this.coverage.name === this.covFormula.calculationType && this.covFormula.isAnyValueMaxCapCalcType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isMaxCapLessThanMultiplier", {
        get: function () {
            if (this.covFormula.isMultiplierCalcType && !_.isNull(this.covMaxCap) && !_.isNull(this.covMultiplier)) {
                return _.lt(this.covMaxCap, this.covMultiplier);
            }
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsCoverageCalcFormulaComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) { return (_this.isEditMode = v.providerInfo); });
        this.subscriptions.actions = this.permissionService
            .subscribeToUserActions(function (v) { return (_this.сanEditFormula = v.сanEditFormula); });
        this.subscriptions.state = this.manService
            .subscribeToChangeState(function (v) { return (_this.showFormula = v); });
        this.subscriptions.providerTier = this.manService
            .subscribeToSelectProviderTier(function (v) {
            var empFormula = v.empFormula, erFormula = v.erFormula, coverageFormula = v.coverageFormula;
            _this.tierIsDraft = v.isDraft;
            _this.empFormula = empFormula;
            _this.empFormulaText = empFormula.expression || '';
            _this.empFixed = _.isFinite(empFormula.fixedAmount) ? empFormula.fixedAmount : null;
            _this.emprFormula = erFormula;
            _this.emprFormulaText = erFormula.expression || '';
            _this.emprFixed = _.isFinite(erFormula.fixedAmount) ? erFormula.fixedAmount : null;
            _this.covFormula = coverageFormula;
            _this.covFormulaText = coverageFormula.expression || '';
            _this.covFixed = _.isFinite(coverageFormula.fixedAmount) ? coverageFormula.fixedAmount : null;
            _this.covMultiplier = coverageFormula.multipler || null;
            _this.covMaxCap = _.isFinite(coverageFormula.maxCap) ? coverageFormula.maxCap : null;
            _this.setDropdownValues();
        });
        this.subscriptions.selectProviderLine = this.commonManService
            .subscribeToSelectProviderLine(function (pl) {
            _this.hasEnrolledEmployees = _.gt(pl.current.enrollmentCount, 0);
        });
        this.subscriptions.benefitenrolledInfo = this.manService
            .subscribeToBenefitEnrolledEmployeesChanged(function (hasEnrollEmp) {
            _this.hasEnrolledEmpTierLevel = hasEnrollEmp;
        });
        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(function () {
            _this.manService.updateCanSaveStateByValidity(_this.ngForm.valid);
        });
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.ngOnDestroy = function () {
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.onChangeEmpContributionType = function () {
        if (this.empFormula.calculationType !== this.empContribution.name) {
            this.empFormula.calculationType = this.empContribution.name;
            this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.onChangeEmpContribution = function () {
        if (_.isString(this.empContribution.name)) {
            this.manService.updateTierEmployeeFormula(this.empContribution.name, this.empFixed, this.empFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.onChangeEmprContributionType = function () {
        if (this.emprFormula.calculationType !== this.emprContribution.name) {
            this.emprFormula.calculationType = this.emprContribution.name;
            this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.onChangeEmprContribution = function () {
        if (_.isString(this.emprContribution.name)) {
            this.manService.updateTierEmployerFormula(this.emprContribution.name, this.emprFixed, this.emprFormulaText);
        }
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.onChangeCoverageType = function () {
        if (this.covFormula.calculationType !== this.coverage.name) {
            this.covFormula.calculationType = this.coverage.name;
            this.manService.updateTierCoverageFormula(this.coverage.name, this.covFixed, this.covFormulaText, this.covMultiplier, this.covMaxCap);
        }
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.onChangeCoverage = function () {
        var _a = this.covFormula, fixedAmount = _a.fixedAmount, multipler = _a.multipler, maxCap = _a.maxCap, expression = _a.expression;
        expression = expression || '';
        if (_.isString(this.coverage.name)
            && (fixedAmount !== this.covFixed
                || multipler !== this.covMultiplier
                || maxCap !== this.covMaxCap
                || expression !== this.covFormulaText)) {
            this.manService.updateTierCoverageFormula(this.coverage.name, this.covFixed, this.covFormulaText, this.covMultiplier, this.covMaxCap);
        }
    };
    BenefitDetailsCoverageCalcFormulaComponent.prototype.setDropdownValues = function () {
        var _this = this;
        this.empContribution = new BenefitDetailsBasic(null, null, null);
        this.emprContribution = new BenefitDetailsBasic(null, null, null);
        this.coverage = new BenefitDetailsBasic(null, null, null);
        var empContr = null;
        var emprContr = null;
        var cov = null;
        _.forEach(this.calculationTypes, function (ct) {
            if (_.isNil(empContr) && ct.name === _this.empFormula.calculationType) {
                _this.empContribution = empContr = ct;
            }
            if (_.isNil(emprContr) && ct.name === _this.emprFormula.calculationType) {
                _this.emprContribution = emprContr = ct;
            }
            if (_.isNil(cov) && ct.name === _this.covFormula.calculationType) {
                _this.coverage = cov = ct;
            }
        });
    };
    Object.defineProperty(BenefitDetailsCoverageCalcFormulaComponent.prototype, "isReadOnly", {
        get: function () {
            return !(this.isEditMode && (this.tierIsDraft || !this.hasEnrolledEmpTierLevel));
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsCoverageCalcFormulaComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsCoverageCalcFormulaComponent;
}());
export { BenefitDetailsCoverageCalcFormulaComponent };
