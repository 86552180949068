/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button-collapse-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button-collapse-panel.component";
var styles_ButtonCollapsePaneComponent = [i0.styles];
var RenderType_ButtonCollapsePaneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonCollapsePaneComponent, data: {} });
export { RenderType_ButtonCollapsePaneComponent as RenderType_ButtonCollapsePaneComponent };
function View_ButtonCollapsePaneComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "theme-iconed-accent-button theme-action-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "icon-button-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", (_co.isCollapsed ? "fa fa-plus" : "fa fa-minus"), ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 5, 0, currVal_1); }); }
export function View_ButtonCollapsePaneComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ButtonCollapsePaneComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "collapse-state"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "collapse-state": 0, "expanding-state": 1 }), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideButton; _ck(_v, 1, 0, currVal_0); var currVal_1 = "collapse-state"; var currVal_2 = _ck(_v, 5, 0, _co.isCollapsed, _co.isExpanding); _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_ButtonCollapsePaneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-button-collapse-panel", [], null, null, null, View_ButtonCollapsePaneComponent_0, RenderType_ButtonCollapsePaneComponent)), i1.ɵdid(1, 49152, null, 0, i3.ButtonCollapsePaneComponent, [], null, null)], null, null); }
var ButtonCollapsePaneComponentNgFactory = i1.ɵccf("slx-button-collapse-panel", i3.ButtonCollapsePaneComponent, View_ButtonCollapsePaneComponent_Host_0, { hideButton: "hideButton", title: "title", collapsedTitle: "collapsedTitle", expandedTitle: "expandedTitle", collapsed: "collapsed" }, {}, ["*"]);
export { ButtonCollapsePaneComponentNgFactory as ButtonCollapsePaneComponentNgFactory };
