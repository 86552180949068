export class ColorUtil {

  public static HexToDec(hex: string): number {
    let hexStr: string = hex.substr(1);
    if (hexStr.length === 3) {
      let hexArr: string[] = hexStr.split('');
      hexStr = '';
      for (let i: number = 0; i < hexArr.length; i++) {
        hexStr += (hexArr[i] + hexArr[i]);
      }
    }
    let hexInt: number = parseInt(hexStr, 16);
    return hexInt;
  }

  public static DecToHexString(dec: number, addNumberSign: boolean = false): string {

    let r: string = this.extractRed(dec).toString(16).toUpperCase();
    let g: string = this.extractGreen(dec).toString(16).toUpperCase();
    let b: string = this.extractBlue(dec).toString(16).toUpperCase();
    let hs: string = '';
    let zero: string = '0';
    if (r.length === 1) {
      r = zero.concat(r);
    }

    if (g.length === 1) {
      g = zero.concat(g);
    }

    if (b.length === 1) {
      b = zero.concat(b);
    }

    hs = r + g + b;
    if (addNumberSign) hs = '#' + hs;
    return hs;
  }

  public static extractRed(c: number): number {
    return ((c >> 16) & 0xFF);
  }

  public static extractGreen(c: number): number {
    return ((c >> 8) & 0xFF);
  }

  public static extractBlue(c: number): number {
    return (c & 0xFF);
  }
}
