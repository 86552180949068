import {
  NgModule,
  Component,
  Input,
  ElementRef,
  forwardRef,
  ViewChild,
  HostListener,
  HostBinding,
  SimpleChanges,
  EventEmitter,
  Output,
  OnInit,
  OnChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment';

import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor } from '../../utils/index';
import { CustomDomEvents } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { appConfig, IApplicationConfig } from '../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-moment-time-input',
  templateUrl: 'moment-time-input.component.html',
  styleUrls: ['moment-time-input.component.scss'],
  providers: [createValuAccessor(MomentTimeInputComponent)]
})
export class MomentTimeInputComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input() public protectDate: boolean;
  @Input() public field: string;
  @Input() public model: any;
  @Input() public className: string;
  @Input() public placeholder: string = 'Time';
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  @Output()
  public onDateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  public get readonly(): boolean {
    return !this.inEdit;
  }

  public get value(): Date {
    return this.internalValue;
  }

  public set value(v: Date) {
    if (v !== this.internalValue) {
      this.internalValue = v ? v : null;
      this.internalMoment = v ? moment(v) : null;
      if (this.internalValue) this.originalValue = this.internalValue;
      if (this.internalMoment) {
        this.timeString = this.internalMoment.format(appConfig.timeFormat);
      } else {
        this.timeString = '';
      }
      this.onChangeCallback(this.internalValue);
    }
  }

  public timeString: string;

  public timeMaskConf: any;
  public inEdit: boolean;
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  private internalValue: Date;
  private originalValue: Date;
  private internalMoment: moment.Moment;
  private initialized: boolean;

  @ViewChild('timeInput', {static: true}) private inputComponent: ElementRef;

  @HostBinding('class.edited') private hasFocus: boolean = false;

  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.timeMaskConf = {
      mask: (value: string) => {
        let numberMatch: RegExpMatchArray = value.match(/\d/g);
        let numberLength: number = numberMatch ? numberMatch.join('').length : 0;
        let patterns: any[] = [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /(a|A|p|P)/, /(m|M)/];
        if (numberLength > 1) {
          if (parseInt(numberMatch[0]) === 1) {
            patterns.splice(1, 1, /[0-2]/);
            return patterns;
          }
        }
        return patterns;

      },
      guide: true,
      keepCharPositions: true
    };
    this.hasFocus = false;
  }

  public ngOnInit(): void {
    this.initialized = true;
  }


  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }

  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
    if (this.model && this.field) {
      this.model[this.field] = this.internalValue;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }

  public onTimeChange(time: string): void {

    this.timeString = time;
    if (time !== '') {
      if (!this.internalMoment || !this.internalValue) {
        if (this.originalValue) {
          this.internalMoment = moment(this.originalValue);
          this.internalValue = this.internalMoment.toDate();
        } else {
          this.internalMoment = moment();
          this.internalValue = this.internalMoment.toDate();
        }
      }
      dateTimeUtils.setTimeToMoment(this.internalMoment, this.timeString);
    } else {
      if (!this.protectDate) {
        this.internalMoment = null;
        this.internalValue = null;
      }
    }

    if (this.internalMoment) this.internalValue = this.internalMoment.toDate();
    if (this.onChangeCallback) this.onChangeCallback(this.internalValue);

    /*
    if (this.model && this.field) {
      if (this.internalMoment) this.internalValue = this.internalMoment.toDate();
      this.model[this.field] = this.internalValue;
    } else {
      if (this.internalValue) this.internalValue.setUTCMilliseconds(this.internalMoment.unix());
    }
    */

    this.onDateChanged.emit(this.internalValue);
  }

  public writeValue(v?: any): void {
    if (v === this.internalValue) return;
    this.internalValue = v ? v : null;
    if (this.internalValue) this.originalValue = this.internalValue;
    if (v === undefined || v === null) {
      this.internalMoment = null;
      this.timeString = '';
      this.inputComponent.nativeElement.value = this.timeString;
      return;
    }
    if (!(v instanceof Date)) throw new Error('MomentTimeInput requires Date as a value');
    this.internalMoment = v ? moment(v) : null;
    this.timeString = this.internalMoment.format(appConfig.timeFormat);
    this.inputComponent.nativeElement.value = this.timeString;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }
  private prepareValue(time: string, originalValue: Date): Date {
    return dateTimeUtils.setTime(originalValue, time, true);
  }

  private get $element(): JQuery {
    return $(this.elementRef.nativeElement);
  }


}
