import * as _ from "lodash";

import { Component, Host, Input, ViewChild } from "@angular/core";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import {
  GridDataResult,
  GridComponent,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";
import {
  KendoGridStateHelper,
  ConfirmDialog2Component,
  ModalService,
  saveEvent,
  removeEvent,
  ConfirmOptions,
  cancelEvent,
} from "../../../../../common/index";
import { appConfig, IApplicationConfig } from "../../../../../app.config";
import {
  EmployeeSectionsBackgroundCheck,
  EmployeeSectionsBackgroundChecks,
} from "../../../models/employee-sections-personal/employee-sections-background-check";
import { unsubscribe } from "../../../../../core/decorators/index";
import { Subscription } from "rxjs";
import { EmployeeSectionsPersonalApiService } from "../../../services";
import { EmployeeSectionsBase } from "../../../models/employee-sections-base";
import { EmployeeSubSectionsDecoratorComponent } from "../../employee-subsection-decorator/employee-subsection-decorator.component";

@Component({
  moduleId: module.id,
  selector: "slx-employee-sections-background-check",
  templateUrl: "employee-sections-background-check.component.html",
  styleUrls: ["employee-sections-background-check.component.scss"],
})
export class EmployeeSectionsBackgroundCheckComponent {
  public editingItem: EmployeeSectionsBackgroundCheck;
  public newEmpBackgroundCheck: EmployeeSectionsBackgroundCheck = new EmployeeSectionsBackgroundCheck();

  public isLoading;

  public canAddCheck;
  public canEditCheck;
  public canDeleteCheck;
  public isSequenceError = false;

  public addMode: boolean;

  public gridState: KendoGridStateHelper<EmployeeSectionsBackgroundCheck>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  public appConfig: IApplicationConfig;
  public backgroundChecks: EmployeeSectionsBackgroundCheck[];
  public enableSave = false;
  public addNewRow: EmployeeSectionsBackgroundCheck = new EmployeeSectionsBackgroundCheck();

  public skip = 0;
  public pageSize = 5;
  public subjectSize = 50;
  public descriptionSize = 255;

  @Input('employeeSectionsBackgroundCheck')
  public set backgroundChecklist (employeeSectionsBackgroundCheck: EmployeeSectionsBackgroundChecks) {
    if(employeeSectionsBackgroundCheck != null){
      let actions = employeeSectionsBackgroundCheck.fieldsMeta.actions;
      _.forEach(actions, (v) => {
        if (v === "add") this.canAddCheck = true;
        if (v === "edit") this.canEditCheck = true;
        if (v === "delete") this.canDeleteCheck = true;
      });
      this.backgroundChecks = employeeSectionsBackgroundCheck.backgroundChecks;
      this.refreshGrid();
    }
  }

  @Input()
  public employeeId: number;

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  @ViewChild("kendoGridBackgroundCheck", { static: true })
  private grid: GridComponent;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private formValueChangeSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private apiService: EmployeeSectionsPersonalApiService,
    @Host() private decorator: EmployeeSubSectionsDecoratorComponent
  ) {
    this.gridState = new KendoGridStateHelper<
      EmployeeSectionsBackgroundCheck
    >();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: "addedOn", dir: "desc" }];
    this.gridState.state.take = this.pageSize;
    this.createSubscriptions();
    
    this.decorator.isEditableByConfiguration = false;
  }


  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public createSubscriptions(): void {
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(
      (v: State): void => {
        this.refreshGrid();
      }
    );

    this.gridEditSubscription = this.gridState.onEdit$.subscribe(
      (item: EmployeeSectionsBackgroundCheck): void => {
        this.isSequenceError = false;
        this.editingItem = item;
        this.addMode = false;
      }
    );

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe(
      (item: cancelEvent<EmployeeSectionsBackgroundCheck>): void => {
        this.editingItem = null;
        this.addMode = false;
      }
    );

    this.gridSaveSubscription = this.gridState.onSave$.subscribe(
      (item: saveEvent<EmployeeSectionsBackgroundCheck>): void => {
        if (item.isNew) {
          item.dataItem.recordId = null;
          this.addMode = false;
        } 
          this.doUpdate(item.dataItem);
      }
    );

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(
      (item: removeEvent<EmployeeSectionsBackgroundCheck>): void => {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog(
          "Delete Background Check",
          "Are you sure that you want to remove this Background Check?",
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.doRemove(item.dataItem);
            }
          },
          options
        );
      }
    );
  }

  public onAddCheck(): void {
    this.enableSave = false;
    this.addMode = true;
    this.newEmpBackgroundCheck.subject = null;
    this.newEmpBackgroundCheck.description = null;

    this.grid.addRow(this.newEmpBackgroundCheck);
  }

  protected doUpdate(item: EmployeeSectionsBackgroundCheck): void {
    this.isLoading = true;
    this.apiService.setBackgroundCheck(item, this.employeeId).then((status) => {
      if (status) {
        this.loadList();
      }
    });
  }

  protected doRemove(item: EmployeeSectionsBackgroundCheck): void {
    this.backgroundChecks = _.without(this.backgroundChecks, item);
    this.isLoading = true;
    this.apiService
      .deleteBackgroundCheck(item, this.employeeId)
      .then((status) => {
        if (status) {
          this.refreshGrid();
          this.isLoading = false;
        }
      });
  }

  private refreshGrid(): void {
    if (!this.backgroundChecks) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.backgroundChecks, this.gridState.state);
  }

  public onChangeValue(item: EmployeeSectionsBackgroundCheck) {
    let isSubject = this.validateRequiredField(item.subject, this.subjectSize);
    let isDescription = this.validateRequiredField(
      item.description,
      this.descriptionSize
    );

    if (isSubject && isDescription) {
      this.enableSave = true;
    } else {
      this.enableSave = false;
    }
  }

  public validateRequiredField(value: string, size: number) {
    let validate;
    if (value == null || value == "") {
      validate = false;
    } else {
      validate = true;
    }
    if (value != null && value != "") {
      if (value.length > size) {
        validate = false;
      } else {
        validate = true;
      }
    }
    return validate;
  }

  public loadList(){
    this.apiService
    .getBackgroundChecks(this.employeeId)
    .then((backgroundChecks) => {
      this.backgroundChecks = backgroundChecks.backgroundChecks;
      this.refreshGrid();
      this.isLoading = false;
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }
}
