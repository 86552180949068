import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitListManagementService } from './../../../services/benefit-console/benefit-list-management.service';
import { benefitsConfig } from './../../../benefits.config';
var BenefitPlanTypesComponent = /** @class */ (function () {
    function BenefitPlanTypesComponent(manService) {
        this.manService = manService;
        this.isLoading = false;
        this.creatPlanAccess = true;
        this.showExpired = false;
        this.planTypes = [];
        this.subscriptions = {};
        this.benefitGroupsByType = {};
        this.groupConfig = benefitsConfig.benefitGroupsConfig;
        this.benefitGroupsByType = _.keyBy(this.groupConfig, function (c) { return c.type; });
    }
    BenefitPlanTypesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.onLoaded = this.manService
            .subscribeToPlanHeaders(function (v) {
            if (_this.planTypes.length === 0) {
                _this.planTypes = v.groups;
                _this.creatPlanAccess = v.canAdd;
            }
        });
    };
    BenefitPlanTypesComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    BenefitPlanTypesComponent.prototype.getIcon = function (typeName) {
        var group = this.benefitGroupsByType[typeName];
        return _.isObjectLike(group) && _.size(group.type) > 0 ? group.icon : '';
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitPlanTypesComponent.prototype, "subscriptions", void 0);
    return BenefitPlanTypesComponent;
}());
export { BenefitPlanTypesComponent };
