import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { appConfig } from '../../../../app.config';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { payrollConfig } from '../../payroll.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { PayrollExportMapService } from './payroll-export-map.service';
import { PayrollExportContainer, IPayrollExportContainer } from '../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';

@Injectable()
export class PayrollExportApiService {
  constructor(
    private mapService: PayrollExportMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public getPayrollExport(orgLevelId: number, startDate: Date, endDate: Date): Promise<PayrollExportContainer> {

    const url: string = `${this.getOrglevelApiRoot(orgLevelId)}/${payrollConfig.api.ta.orglevel.payroll.root}/${payrollConfig.api.ta.orglevel.payroll.exports}`;
    let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });

    let promise: Promise<PayrollExportContainer> = this.apiUtilService.request<IPayrollExportContainer, Meta>(request)
      .then((response: ResponseBody<IPayrollExportContainer, Meta>) => {
        return this.mapService.mapPayrollExportContainer(response.data);
      });

    // let promise: Promise<PayrollExportContainer> = Promise.resolve(this.mapService.mapPayrollExportContainer(PayrollExportContainer.mock));
    return promise;
  }

  public releaseToPayroll(orgLevelId: number, groupName: string, startDate: Date, endDate: Date): Promise<any> {
    const dto = this.mapService.mapPayrollExportRelease(orgLevelId, groupName, startDate, endDate);
    const url: string = `${this.getUpdateApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.release}`;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, dto);

    const promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);

    return promise;
  }

  public cancelRelease(orgLevelId: number, groupName: string, startDate: Date, endDate: Date): Promise<any> {
    const dto = this.mapService.mapPayrollExportRelease(orgLevelId, groupName, startDate, endDate);
    const url: string = `${this.getUpdateApiRoot()}/${payrollConfig.api.ta.orglevel.payroll.cancel}`;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, dto);

    const promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => response.data);

    return promise;
  }

  private getOrglevelApiRoot(orgLevelId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${payrollConfig.api.ta.root}/${payrollConfig.api.ta.orglevel.root}/${orgLevelId}`;
  }

  private getUpdateApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${payrollConfig.api.ta.root}/${payrollConfig.api.ta.orglevel.payroll.root}/${payrollConfig.api.ta.orglevel.payroll.exports}`;
  }
}
