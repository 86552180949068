<kendo-grid
  [data]="gridView"
  [sortable]="{ mode: 'multiple' }"
  [groupable]="false"
  [filterable]="false"
  (dataStateChange)="dataStateChange($event)"
  scrollable="none"
  height="200px"
>
  <kendo-grid-column [sortable]="false" field="isScheduled" media="sm" width="40" [filterable]="false" headerClass="overflow-visible-cell">
    <ng-template kendoGridHeaderTemplate>
      <div>
        <i class="far fa-calendar" slxTooltip="Has missing punches" tipPosition="bottom" aria-hidden="true"></i>
      </div>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="absence-icon-box">
        <i *ngIf="dataItem.record.isScheduled" class="far fa-play-circle is-scheduled" aria-hidden="true"></i>
        <i *ngIf="dataItem.record.isRotationDay" class="fa fa-plus-circle is-rotation" aria-hidden="true"></i>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [sortable]="false" [filterable]="false" title="Date" field="date" locked="false" width="200" locked="true">
    <ng-template kendoGridHeaderTemplate>
      Date
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem.record.date">
        {{dataItem.record.date | amDateFormat: appConfig.dateDayWeekMonthTextDayYearFormat}}
      </a>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column [sortable]="false" [filterable]="false" title="Absence" field="scheduleAbsence.code" width="100">
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>Absence</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="idx">
      <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block">
        <slx-dropdown-input class="form-control" slx-input name="absenceCode{{idx}}" [readonly]="!dataItem.record.isActive"
          [lookup]="dataItem.lookup" [required]="true" valueField="code" titleField="code" [(ngModel)]="dataItem.record.scheduleAbsence" emptyOption="None">
          </slx-dropdown-input>
      </slx-input-decorator>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
