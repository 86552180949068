<div class="swap-flex-vertical">
    <div class="swap-flex-horizontal tools-panel section">
        <button type="button" class="slx-button"
            [popper]="columnsToggler"
            [popperTrigger]="'click'"
            popperPlacement="bottom-start"
            [popperDisableStyle]="'true'"
            [popperPositionFixed]="true" >
            <i class="far fa-columns" aria-hidden="true"></i>
            Columns
        </button>
          
          <popper-content #columnsToggler class="columns-chooser popper-white-background">
            <div class="columns-container">
              <div *ngFor="let column of manageService.columns; let idx=index;">
                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font">
                  <slx-checkbox-input slx-input name="col_{{idx}}" fieldName="col_{{idx}}" 
                    [caption]="column.description" [(ngModel)]="column.displayed"
                    (ngModelChange)="onColumnsChanged()">
                  </slx-checkbox-input>
                </slx-input-decorator>
              </div>
            </div>
          </popper-content>
    </div>
    <div class="swap-flex-horizontal swap-table-header section">
        <div class="row-label"></div>
        <slx-shift-swap-list-item-profile class="value-item" [miniProfile]="true" [profile]="shiftSwap.submittedBy"></slx-shift-swap-list-item-profile>
        <div [ngClass]="statusStyle + '-text'" class="icon-container">
            <i *ngIf="shiftSwap.hasSubmittedTo" class="fas fa-exchange-alt" aria-hidden="true"></i>
            <i *ngIf="!shiftSwap.hasSubmittedTo" class="fas fa-hourglass-start" aria-hidden="true"></i>
        </div>
        <slx-shift-swap-list-item-profile class="value-item" [miniProfile]="true" [profile]="shiftSwap.submittedTo"></slx-shift-swap-list-item-profile>
    </div>
    <div class="swap-flex-vertical rows-container">
        <div class="swap-flex-horizontal row-values" *ngFor="let col of manageService.displayedColumns; let i = index">
            <div class="row-label">{{col.description}}</div>
            <div [ngClass]="{'odd-row': i % 2 === 0}" class="swap-flex-horizontal row-values">
                <div class="value-item">{{getDetailsProperty(col.name, true)}}</div>
                <div class="divider"></div>
                <div class="value-item">{{getDetailsProperty(col.name, false)}}</div>
            </div>
        </div>
    </div>
    <div class="swap-flex-horizontal section">
        <div class="row-label">Comments</div>
        <textarea class="comment-area" [(ngModel)]="comment"></textarea>
    </div>
    <div class="swap-flex-horizontal section buttons-container">
        <button type="button" class="slx-button status-denied action-button" *ngIf="shiftSwap.canAction"
            [ngClass]="{'status-expired': manageService.isLoading }"(click)="denyClick()">
            <i class="fal fa-ban" aria-hidden="true"></i>
            Deny
        </button>
        <button type="button" class="slx-button status-approved action-button" *ngIf="shiftSwap.canAction"
            [ngClass]="{'status-expired': manageService.isLoading }" (click)="approveClick()">
            <i class="fal fa-check" aria-hidden="true"></i>
            Approve
        </button>
        <div class="pseudo-button"  *ngIf="shiftSwap.canAction"></div>
        <button type="button" class="slx-button status-expired action-button"
            (click)="closeClick()">
            <i class="fal fa-times" aria-hidden="true"></i>
            Close
        </button>
    </div>
</div>