export interface IEssTemplatesConfig {
  api: {
    essTemplates: {
      root: string;
      template: string;
      templates: string;
      templateDefinition: string;
      templateDefinitions: string;
      assign: string;
    }
  };
}
export const essTemplatesConfig: IEssTemplatesConfig = {
  api: {
    essTemplates: {
      root: 'ess',
      template: 'template',
      templates: 'templates',
      templateDefinition: 'templateDefinition',
      templateDefinitions: 'templateDefinitions',
      assign: 'assign'
    }
  }
};
