import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { Organization } from '../../../organization/models/lookup/index';
import { LookupMapService } from '../../../organization/services/lookup/lookup-map.service';

import { IAgencyAddRequest, IAgencyDTO, IAgencyMappingDTO, AgencyMapping, AgencyModel } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Injectable()
export class AgenciesMapService {

    private lookupMapService: LookupMapService;

    constructor(lookupMapService: LookupMapService) {
        this.lookupMapService = lookupMapService;
    }

    public mapAgenciesMappings(dtos: IAgencyMappingDTO[]): AgencyMapping[] {
        let mappings: AgencyMapping[] = [];
        let uniqId: number = 0;
        _.each(dtos, (dto: IAgencyMappingDTO) => {
            let agencyMapping: AgencyMapping = this.mapAgencyMapping(dto);
            agencyMapping.uniqId = uniqId;
            uniqId++;
            mappings.push(agencyMapping);
        });
        return mappings;
    }

    public mapAgencyMapping(dto: IAgencyMappingDTO): AgencyMapping {
        let agencyMapping: AgencyMapping = new AgencyMapping();
        let agency: AgencyModel = this.mapAgencyMappingDTO(dto);
        agencyMapping.agency = agency;
        agencyMapping.organizations = this.lookupMapService.mapOrganizations(dto.organizations);
        return agencyMapping;
    }

    public mapAgency(dto: IAgencyDTO): AgencyModel {
        let agency: AgencyModel = new AgencyModel();
        agency.id = dto.id;
        agency.name = dto.name;
        agency.state = dto.state;
        agency.city = dto.city;
        agency.address = dto.address;
        agency.zip = dto.zip;
        agency.employeeCount = dto.employeeCount;
        agency.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
        agency.lastUpdateName = dto.lastUpdateName;
        return agency;
    }

    public mapAgencyMappingDTO(dto: IAgencyMappingDTO): AgencyModel {
      let agency: AgencyModel = new AgencyModel();
      agency.id = dto.id;
      agency.name = dto.name;
      agency.state = dto.state;
      agency.city = dto.city;
      agency.address = dto.address;
      agency.zip = dto.zip;
      agency.employeeCount = dto.employeeCount;
      agency.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
      agency.lastUpdateName = dto.lastUpdateName;
      return agency;
    }

    public mapAgencyDTO(model: AgencyModel): IAgencyDTO {
        return {
            id: model.id,
            name: model.name,
            state: model.state,
            city: model.city,
            address: model.address,
            zip: model.zip,
            employeeCount: model.employeeCount,
            lastUpdateDate: dateTimeUtils.convertToDtoString(model.lastUpdateDate),
            lastUpdateName: model.lastUpdateName
        };
    }

    public mapAddRequest(mapping: AgencyMapping): IAgencyAddRequest {
        return {
            id: mapping.agency.id,
            name: mapping.agency.name,
            state: mapping.agency.state,
            city: mapping.agency.city,
            address: mapping.agency.address,
            zip: mapping.agency.zip,
            employeeCount: mapping.agency.employeeCount,
            organizationIds: _.map(mapping.organizations, (organization: Organization) => organization.id)
        };
    }

    public mapOrganizationIds (organizations: Organization[]): number[] {
        return _.map (organizations, (organization: Organization)=> organization.id);
    }

    public cloneAgencyMapping(source: AgencyMapping): AgencyMapping {
        let target: AgencyMapping = new AgencyMapping();
        target.uniqId = source.uniqId;
        let targetAgency: AgencyModel = this.mapAgency(this.mapAgencyDTO(source.agency));
        target.agency = targetAgency;
        target.organizations = this.lookupMapService.mapOrganizationDtos(source.organizations);
        return target;
    }

    public updateAgencyMapping(source: AgencyMapping, target: AgencyMapping): void {
        target.uniqId = source.uniqId;
        this.updateAgency(source.agency, target.agency);
        target.organizations = source.organizations;
    }

    public updateAgency(source: AgencyModel, target: AgencyModel): void {
        target.id = source.id;
        target.name = source.name;
        target.state = source.state;
        target.city = source.city;
        target.address = source.address;
        target.zip = source.zip;
        target.employeeCount = source.employeeCount;
        target.lastUpdateDate = source.lastUpdateDate ? new Date(source.lastUpdateDate) : null;
        target.lastUpdateName = source.lastUpdateName;
    }

}
