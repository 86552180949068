import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { AcaExportApiService } from '../aca-export/aca-export-api.service';
import { Assert } from '../../../../framework/index';
import { mutableSelect, unsubscribeAll, unsubscribe } from '../../../../core/decorators';
import { FileService, StateManagementService } from '../../../../common';
import { AcaExportEventService } from '../../../../common/services/aca-export/aca-export-event.service';
import { UserActivityService } from '../../../../core/services/user-activity/user-activity.service';
import { TokenValidityService, PollingPeriodService } from '../../../../core/services';
import { ExportDataStatus } from '../../enums/export-data-status';
import { appConfig } from '../../../../app.config';
import { AcaExportMapService } from './aca-export-map.service';
import { initialAcaExportState } from '../../models/aca-export/aca-export-state';
import { StateResetTypes } from '../../../../core/models';
import { AcaReportApiService } from '../aca-report/aca-report-api.service';
import { AcaExportCorrectionParameter } from '../../models/aca-export/aca-export-correction-parameter';
import { AcaExportCorrection1094CCountContainer } from '../../models/aca-export/aca-export-correction-1094c-counts';
var AcaExportManagementService = /** @class */ (function () {
    function AcaExportManagementService(apiService, mapService, fileService, userActivityService, tokenValidity, acaExportEventService, stateManagement, acaReportApiService) {
        this.apiService = apiService;
        this.mapService = mapService;
        this.fileService = fileService;
        this.userActivityService = userActivityService;
        this.tokenValidity = tokenValidity;
        this.acaExportEventService = acaExportEventService;
        this.stateManagement = stateManagement;
        this.acaReportApiService = acaReportApiService;
        this.stateKey = 'AcaExportState';
        this.loading$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.configurationsLoaded$ = new ReplaySubject(1);
        this.historyLoaded$ = new ReplaySubject(1);
        this.statusLoaded$ = new ReplaySubject(1);
        this.exportExecuted$ = new ReplaySubject(1);
        this.acaExportDialogOptions$ = new Subject();
        this.subscriptions = {};
        this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
    }
    AcaExportManagementService.prototype.init = function () {
        var _this = this;
        this.subscribeToOrgLevelChanges();
        this.acaExportEventService.init();
        this.subscriptions.exportDataStatusChanged = this.acaExportEventService.exportDataStateChanged$.subscribe(function (args) {
            var executionNotification = args && args.length > 2 ? JSON.parse(args[2]) : null;
            if (executionNotification) {
                _this.statusLoaded$.next([_this.mapService.mapNotificationToAcaExportStatus(executionNotification)]);
            }
        });
        this.subscriptions.notificationStateChanged = this.acaExportEventService.notificationStateChanged$.subscribe(function (enabled) {
            if (enabled) {
                if (_this.poolingSubscription) {
                    _this.poolingSubscription.unsubscribe();
                    _this.applicationHeartbeatService.stop();
                }
            }
            else {
                if (!_this.poolingSubscription || _this.poolingSubscription.closed) {
                    _this.poolingSubscription = _this.applicationHeartbeatService.onHeartbeat.subscribe(function () {
                        if (_this.exportConfigurations) {
                            var ids = _this.getStatusesToUpdate();
                            if (ids && ids.length > 0) {
                                _this.loadExportDataStatuses(ids);
                            }
                        }
                    });
                    _this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
                }
            }
        });
    };
    AcaExportManagementService.prototype.ngOnDestroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.statusLoaded$.complete();
        this.historyLoaded$.complete();
        this.exportExecuted$.complete();
        this.orgLevelChanged$.complete();
        this.acaExportDialogOptions$.complete();
        this.configurationsLoaded$.complete();
        this.acaExportDialogOptions$.complete();
    };
    AcaExportManagementService.prototype.loadAcaExportConfiguration = function () {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getAcaExportConfigurationList()
            .then(function (data) {
            _this.exportConfigurations = data;
            _this.configurationsLoaded$.next(data);
            _this.loading$.next(false);
        });
    };
    AcaExportManagementService.prototype.getStatusesToUpdate = function () {
        var ids = [];
        _.each(this.exportConfigurations, function (c) {
            if (c.lastExecuted && (c.lastExecuted.status === ExportDataStatus.InProgress || c.lastExecuted.status === ExportDataStatus.Waiting)) {
                ids.push(c.lastExecuted.id);
            }
        });
        return ids;
    };
    AcaExportManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    AcaExportManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    AcaExportManagementService.prototype.setLoadingStatus = function (isLoading) {
        this.loading$.next(isLoading);
    };
    AcaExportManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return _.isFinite(_.get(o, 'id')); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
        });
    };
    AcaExportManagementService.prototype.subscribeToLoadedConfigurations = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.configurationsLoaded$.subscribe(callback);
    };
    AcaExportManagementService.prototype.subscribeToLoadedHistory = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.historyLoaded$.subscribe(callback);
    };
    AcaExportManagementService.prototype.subscribeToLoadedStatuses = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.statusLoaded$.subscribe(callback);
    };
    AcaExportManagementService.prototype.subscribeToExportExecuted = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportExecuted$.subscribe(callback);
    };
    AcaExportManagementService.prototype.subscribeToExportDialogOptions = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.acaExportDialogOptions$.subscribe(callback);
    };
    AcaExportManagementService.prototype.fetchCorrected1095CRecords = function (lastExectued, matchEmployeeList) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var acaExportCorrected1095CContainer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acaExportCorrected1095CContainer = [];
                        return [4 /*yield*/, this.apiService.getAcaCorrected1095CData(lastExectued).
                                then(function (result) {
                                if (matchEmployeeList && matchEmployeeList.length > 0) {
                                    _.forEach(result, function (eln) {
                                        var matchemplist = _.filter(matchEmployeeList, function (list) { return list.soureEmpID == eln.employeeIdMasterId; });
                                        if (matchemplist.length > 0) {
                                            eln.ssnStatus = 1;
                                        }
                                    });
                                }
                                acaExportCorrected1095CContainer = result;
                            }).catch(function () {
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, acaExportCorrected1095CContainer];
                }
            });
        });
    };
    AcaExportManagementService.prototype.fetchReplace1095CRecords = function (lastExectued) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var acaExportCorrected1095CContainer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acaExportCorrected1095CContainer = [];
                        return [4 /*yield*/, this.apiService.getReplace1095CData(lastExectued).
                                then(function (result) {
                                acaExportCorrected1095CContainer = result;
                            }).catch(function () {
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, acaExportCorrected1095CContainer];
                }
            });
        });
    };
    AcaExportManagementService.prototype.fetchSupplemental1095CRecords = function (lastExectued) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var acaExportCorrected1095CContainer;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        acaExportCorrected1095CContainer = [];
                        return [4 /*yield*/, this.apiService.getSupplemental1095CData(lastExectued).
                                then(function (result) {
                                acaExportCorrected1095CContainer = result;
                            }).catch(function () {
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, acaExportCorrected1095CContainer];
                }
            });
        });
    };
    AcaExportManagementService.prototype.loadExportDataHistory = function (configurationId) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getAcaExportHistory(configurationId)
            .then(function (data) {
            _this.historyLoaded$.next(data);
            _this.loading$.next(false);
        });
    };
    AcaExportManagementService.prototype.loadExportDataStatuses = function (ids) {
        var _this = this;
        this.apiService.getAcaExportStatuses(ids)
            .then(function (data) {
            _this.statusLoaded$.next(data);
        });
    };
    AcaExportManagementService.prototype.generateAcaExport = function (params) {
        var _this = this;
        this.loading$.next(true);
        var req = this.mapService.mapToAcaExportRequest(params);
        this.apiService.generateAcaExport(req)
            .then(function (data) {
            _this.exportExecuted$.next(data);
            _this.historyLoaded$.next([data]);
            _this.loading$.next(false);
        }).catch(function () {
            _this.loading$.next(false);
        });
    };
    AcaExportManagementService.prototype.downloadExportData = function (type, executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.downloadAcaExport(type, executionId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, file.file);
                        this.loading$.next(false);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AcaExportManagementService.prototype.cancelAcaExport = function (exportType, executionId) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.cancelAcaExport(exportType, executionId)
            .then(function (data) {
            var status = { exportType: exportType, executionId: executionId, status: ExportDataStatus.Cancelled, changedOn: moment().toDate(), reason: null, configurationId: null };
            _this.statusLoaded$.next([status]);
            _this.loading$.next(false);
        }).catch(function () {
            _this.loading$.next(false);
        });
    };
    AcaExportManagementService.prototype.setAcaExportState = function (acaExportDataConfigList) {
        var state = _.clone(initialAcaExportState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        state.acaExportConfigurationList = acaExportDataConfigList;
        this.saveState(state);
    };
    AcaExportManagementService.prototype.saveState = function (state) {
        this.stateManagement.setControlState(this.stateKey, {
            value: state
        }, StateResetTypes.None);
    };
    AcaExportManagementService.prototype.restoreAcaExportState = function () {
        var config = [];
        var state = _.clone(initialAcaExportState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        config = state.acaExportConfigurationList;
        return config;
    };
    AcaExportManagementService.prototype.getEmployerStatus = function (employerId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.acaReportApiService.getEmployerStatus(employerId, year)];
                    case 2:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.loading$.next(false);
                        return [2 /*return*/, result];
                }
            });
        });
    };
    AcaExportManagementService.prototype.openAcaExportDialog = function (acaExportDialogOptions) {
        this.acaExportDialogOptions$.next(acaExportDialogOptions);
    };
    AcaExportManagementService.prototype.createParameterOptionsData = function (item) {
        var acaExportCorrectionParameter = new AcaExportCorrectionParameter();
        acaExportCorrectionParameter.offeredMec = this.generateDataOptions(item.offeredMec);
        acaExportCorrectionParameter.memberOfAleGroup = this.generateDataOptions(item.memberOfAleGroup);
        acaExportCorrectionParameter.offerMethod98 = item.offerMethod98;
        acaExportCorrectionParameter.qualifiedOfferMethod = item.qualifiedOfferMethod;
        return acaExportCorrectionParameter;
    };
    AcaExportManagementService.prototype.generateDataOptions = function (selected) {
        var dataOptions = [];
        for (var month = 0; month < 12; month++) {
            var id = month + 1;
            dataOptions.push({
                id: id,
                name: moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'),
                isSelected: _.includes(selected, id)
            });
        }
        return _.cloneDeep(dataOptions);
    };
    AcaExportManagementService.prototype.getAcaExport1094CCountData = function (item, correctionParameters) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.apiService.getAcaExport1094cCount(item, correctionParameters)];
                    case 1:
                        result = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/, result];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AcaExportManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AcaExportManagementService.prototype, "poolingSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaExportManagementService.prototype, "subscriptions", void 0);
    return AcaExportManagementService;
}());
export { AcaExportManagementService };
