/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./temp-readings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../common/components/spinner/spinner.component";
import * as i4 from "./temp-readings-toolbar/temp-readings-toolbar.component.ngfactory";
import * as i5 from "./temp-readings-toolbar/temp-readings-toolbar.component";
import * as i6 from "../../../services/timeclocks/temp-sensor-reading-management.service";
import * as i7 from "../../../../common/services/column-settings/column-management.service";
import * as i8 from "../../../../common/services/state-management/state-management.service";
import * as i9 from "../../../../common/services/device-detector/device-detector.service";
import * as i10 from "./temp-readings-grid/temp-readings-grid.component.ngfactory";
import * as i11 from "./temp-readings-grid/temp-readings-grid.component";
import * as i12 from "@angular/router";
import * as i13 from "../../../../common/services/column-settings/column-settings-storage.service";
import * as i14 from "../../../../common/services/component-state/component-state-storage.service";
import * as i15 from "../../../../organization/services/accessible/accessible-api.service";
import * as i16 from "../../../services/timeclocks/temp-sensor-reading-api.service";
import * as i17 from "./temp-readings.component";
var styles_TempReadingsComponent = [i0.styles];
var RenderType_TempReadingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TempReadingsComponent, data: {} });
export { RenderType_TempReadingsComponent as RenderType_TempReadingsComponent };
export function View_TempReadingsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 6, "div", [["class", "slx-high-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "slx-content-toolbar-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-temp-readings-toolbar", [], null, null, null, i4.View_TempReadingsToolbarComponent_0, i4.RenderType_TempReadingsToolbarComponent)), i1.ɵdid(5, 245760, null, 0, i5.TempReadingsToolbarComponent, [i6.TempSensorReadingManagementService, i7.ColumnManagementService, i8.StateManagementService, i9.DeviceDetectorService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "slx-high-box__body slx-main-content-indents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "slx-temp-readings-grid", [], null, null, null, i10.View_TempReadingsGridComponent_0, i10.RenderType_TempReadingsGridComponent)), i1.ɵdid(8, 245760, null, 0, i11.TempReadingsGridComponent, [i6.TempSensorReadingManagementService, i9.DeviceDetectorService, i1.ChangeDetectorRef, i12.Router, i12.ActivatedRoute], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); _ck(_v, 8, 0); }, null); }
export function View_TempReadingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "slx-temp-readings", [], null, null, null, View_TempReadingsComponent_0, RenderType_TempReadingsComponent)), i1.ɵprd(4608, null, i7.ColumnManagementService, i7.ColumnManagementService, [i13.ColumnSettingsStorageService]), i1.ɵprd(4608, null, i8.StateManagementService, i8.StateManagementService, [i14.ComponentStateStorageService, i13.ColumnSettingsStorageService]), i1.ɵprd(512, null, i6.TempSensorReadingManagementService, i6.TempSensorReadingManagementService, [i15.AccessibleApiService, i16.TempSensorReadingApiService]), i1.ɵdid(4, 245760, null, 0, i17.TempReadingsComponent, [i6.TempSensorReadingManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
var TempReadingsComponentNgFactory = i1.ɵccf("slx-temp-readings", i17.TempReadingsComponent, View_TempReadingsComponent_Host_0, {}, {}, []);
export { TempReadingsComponentNgFactory as TempReadingsComponentNgFactory };
