<div class="wrapper">
    <kendo-tabstrip class="slx-tab-strip-wrapper" (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab title="Uploaded File Status" [selected]="true">
            <ng-template kendoTabContent>
                <div class="content">
                    <slx-uploaded-file-status></slx-uploaded-file-status>
                </div>
            </ng-template>
        </kendo-tabstrip-tab>
    </kendo-tabstrip>
</div>