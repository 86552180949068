/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./chart-widget.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../services/value-pair-chart-data/value-pair-chart-data.service";
import * as i4 from "./chart-widget.component";
var styles_ChartWidgetComponent = [i0.styles];
var RenderType_ChartWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartWidgetComponent, data: {} });
export { RenderType_ChartWidgetComponent as RenderType_ChartWidgetComponent };
function View_ChartWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ChartWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartWidgetComponent_2)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.topDirective.template; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChartWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ChartWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartWidgetComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.middleDirective.template; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ChartWidgetComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "slx-chart-widget-click-hanlder"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.defaultClickHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_ChartWidgetComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ChartWidgetComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartWidgetComponent_7)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bottomDirective.template; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChartWidgetComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "slx-chart-widget-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartWidgetComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartWidgetComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartWidgetComponent_5)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartWidgetComponent_6)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.topDirective && !_co.topDirective.isHidden); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.middleDirective && !_co.middleDirective.isHidden); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.useDefaultClickHanlder; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.bottomDirective && !_co.bottomDirective.isHidden); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ChartWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-chart-widget", [], null, null, null, View_ChartWidgetComponent_0, RenderType_ChartWidgetComponent)), i1.ɵprd(512, null, i3.ValuePairChartDataService, i3.ValuePairChartDataService, []), i1.ɵdid(2, 638976, null, 3, i4.ChartWidgetComponent, [i3.ValuePairChartDataService], null, null), i1.ɵqud(335544320, 1, { topDirective: 0 }), i1.ɵqud(335544320, 2, { middleDirective: 0 }), i1.ɵqud(335544320, 3, { bottomDirective: 0 })], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ChartWidgetComponentNgFactory = i1.ɵccf("slx-chart-widget", i4.ChartWidgetComponent, View_ChartWidgetComponent_Host_0, { config: "config", input: "input", useDefaultClickHanlder: "useDefaultClickHanlder" }, { onWidgetDefaultClick: "onWidgetDefaultClick" }, []);
export { ChartWidgetComponentNgFactory as ChartWidgetComponentNgFactory };
