/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./square-spinner.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./square-spinner.component";
var styles_SquareSpinnerComponent = [i0.styles];
var RenderType_SquareSpinnerComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SquareSpinnerComponent, data: {} });
export { RenderType_SquareSpinnerComponent as RenderType_SquareSpinnerComponent };
function View_SquareSpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "progressbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["aria-hidden", "true"], ["class", "progressbar__spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "progressbar__item progressbar__tl"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "progressbar__item progressbar__tr"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "progressbar__item progressbar__br"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "progressbar__item progressbar__bl"]], null, null, null, null, null))], null, null); }
export function View_SquareSpinnerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SquareSpinnerComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shown; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SquareSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-square-spinner", [], null, null, null, View_SquareSpinnerComponent_0, RenderType_SquareSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SquareSpinnerComponent, [], null, null)], null, null); }
var SquareSpinnerComponentNgFactory = i1.ɵccf("slx-square-spinner", i3.SquareSpinnerComponent, View_SquareSpinnerComponent_Host_0, { shown: "shown" }, {}, ["*"]);
export { SquareSpinnerComponentNgFactory as SquareSpinnerComponentNgFactory };
