<!-- #bugInIE11WithEmployeeRecord (ngIf) -->
<form novalidate #form="ngForm" *ngIf="employeeSectionsPhysicals">
  <section class="physicals-container">
    <div class="groups">
      <div class="group form-inline" *ngFor="let group of employeeSectionsPhysicals?.groups; let gdx = index;" [ngClass]="{'group-edit': state.isEditMode}">
        <section>
          <div class="field form-inline" *ngFor="let field of group.fields; let fdx = index;">
            <div [ngSwitch]="field.fieldRender">
              <slx-input-decorator *ngSwitchCase="'datetime'">
                <slx-date-picker-ngx slx-input date
                  [(ngModel)]="field?.fieldData.fieldValue"
                  [removableInput]="state.isEditMode"
                  parentClass="slx-input-group"
                  [inputPolicy]="field?.fieldData.Policy"
                  [required]="false"
                  [readonly]="!state.isEditMode"
                  [placeholder]="field.displayName"
                  name="displayName{{gdx}}-{{fdx}}"
                ></slx-date-picker-ngx>
                <span errorMessage for="date"></span>
              </slx-input-decorator>
              <slx-input-decorator *ngSwitchCase="'number'">
                 <slx-kendo-number slx-input number
                  [(ngModel)]="field?.fieldData.fieldValue"
                  [inputPolicy]="field?.fieldData.Policy"
                  [readonly]="!state.isEditMode"
                  [placeholder]="field.displayName"
                  name="displayName{{gdx}}-{{fdx}}"
                ></slx-kendo-number>
                <span errorMessage for="number"></span>
              </slx-input-decorator>
              <slx-input-decorator *ngSwitchCase="'string'">
                <input slx-input type="text" [readonly]="!state.isEditMode" name="displayName{{gdx}}-{{fdx}}" [placeholder]="field.displayName"
                  [inputPolicy]="field?.fieldData.Policy" [slxMaxLength]="field?.fieldLength" [(ngModel)]="field?.fieldData.fieldValue" slxTrim="both">
                <span errorMessage for="slxMaxLength">Max length is {{field?.fieldLength}}</span>
              </slx-input-decorator>
              <div *ngSwitchDefault>
                <span>Unknown type of physical field</span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</form>
