import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { ColumnManagementService, StateManagementService, ModalService } from '../../../../../common/services';
import { PmConfigurationManagementService } from '../../../services';
import { LookupService } from '../../../../../organization/services';
import { screenUtils } from '../../../../../common/utils';
import { appConfig } from '../../../../../app.config';
import { PmTemplateDefinition } from '../../../../../organization/models';
import { KendoGridStateHelper, ConfirmOptions, ConfirmDialogComponent } from '../../../../../common';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { NgForm } from '@angular/forms';
var PmTemplatesGridComponent = /** @class */ (function () {
    function PmTemplatesGridComponent(management, stateManagement, columnManagementService, lookupService, modalService) {
        this.management = management;
        this.stateManagement = stateManagement;
        this.columnManagementService = columnManagementService;
        this.lookupService = lookupService;
        this.modalService = modalService;
        this.canCreateNew = true;
        this.canEdit = true;
        this.sort = [];
        this.pageSize = 50;
        this.columnsGroupName = 'PerformanceManagementConfigureTpl';
        this.columnsStateName = 'PerformanceManagementConfigureTpl';
        this.itemClass = PmTemplateDefinition;
        this.xlsxNameConst = 'Performance_Templates_';
        this.pdfNameConst = 'Performance_Templates_';
        this.subscriptions = {};
        this.appConfig = appConfig;
        this.screenUtils = screenUtils;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'modifiedDate', dir: 'desc' }
        ];
    }
    Object.defineProperty(PmTemplatesGridComponent.prototype, "templates", {
        get: function () {
            return this.m_templates;
        },
        set: function (c) {
            this.m_templates = c;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    PmTemplatesGridComponent.prototype.ngOnInit = function () {
        this.initServices();
        this.createSubscriptions();
        this.refreshGrid();
    };
    PmTemplatesGridComponent.prototype.createSubscriptions = function () {
        var _this = this;
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridSaveSubscription = this.gridState.onSave$.subscribe(function (event) {
            if (event.isNew) {
                _this.doAdd(event.dataItem);
                return;
            }
            _this.doEdit(event.dataItem, event.rowIndex);
        });
        this.subscriptions.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (event) {
            _this.startRemove(event.dataItem, event.rowIndex);
        });
        this.subscriptions.addNewTplSubscription = this.management.subscribeToAddNewTemplate(function (newTpl) {
            _this.grid.addRow(newTpl);
        });
        this.subscriptions.exportSubscription = this.management.subscribeToExportTemplates(function (isPdf) {
            var date = new Date();
            _this.xlsxName = _this.xlsxNameConst + (date + ".xlsx");
            _this.pdfName = _this.pdfNameConst + (date + ".pdf");
            if (isPdf) {
                _this.grid.saveAsPDF();
            }
            else {
                _this.grid.saveAsExcel();
            }
        });
    };
    PmTemplatesGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.m_templates, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    PmTemplatesGridComponent.prototype.pageChanged = function (event) {
        this.gridState.state.skip = event.skip;
        this.refreshGrid();
    };
    PmTemplatesGridComponent.prototype.editHandler = function (event) {
        this.gridState.editHandler(event);
    };
    PmTemplatesGridComponent.prototype.startRemove = function (dataItem, rowIndex) {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to delete? This template will also be deleted from the  template section of employee', this.modalService, function (result) {
            if (result) {
                _this.doRemove(dataItem, rowIndex);
            }
        }, options);
    };
    PmTemplatesGridComponent.prototype.doRemove = function (dataItem, rowIndex) {
        this.management.removeTemplate(dataItem);
    };
    PmTemplatesGridComponent.prototype.doEdit = function (dataItem, rowIndex) {
        var _this = this;
        this.management.updateTemplate(dataItem)
            .then(function () {
            _this.gridState.closeEditor(_this.grid);
        });
    };
    PmTemplatesGridComponent.prototype.doAdd = function (dataItem) {
        var _this = this;
        this.management.addNewTemplate(dataItem)
            .then(function () {
            _this.gridState.closeEditor(_this.grid);
        });
    };
    PmTemplatesGridComponent.prototype.initServices = function () {
        this.stateManagement.init('PerformanceManagementConfigureTpl');
        this.columnManagementService.init('PerformanceManagementConfigureTpl');
        this.columnManagementService.initGroup(this.columnsStateName, 7);
    };
    PmTemplatesGridComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    PmTemplatesGridComponent.prototype.refreshGrid = function () {
        if (!this.m_templates) {
            this.gridView = null;
            return;
        }
        this.gridState.view = process(this.m_templates, this.gridState.state);
    };
    return PmTemplatesGridComponent;
}());
export { PmTemplatesGridComponent };
