import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';
var KendoGridDateFilterComponent = /** @class */ (function (_super) {
    tslib_1.__extends(KendoGridDateFilterComponent, _super);
    function KendoGridDateFilterComponent(filterService, localization) {
        var _this = _super.call(this, filterService, localization) || this;
        var slxFilters = _.map(kendoUtils.slxDateFilters, function (filter) { return filter.key; });
        var slxFiltersKeysMap = _.keyBy(slxFilters, function (key) { return key; });
        var dateOperators = _this.localizeOperators(Object.assign(slxFiltersKeysMap, {
            'filterIsNullOperator': 'isnull',
            'filterIsNotNullOperator': 'isnotnull'
        }));
        _this.showOperators = true;
        _this.operator = 'slxFilterDate';
        _this.defaultOperators = dateOperators(_this.localization);
        return _this;
    }
    return KendoGridDateFilterComponent;
}(KendoGridBasicFilterComponent));
export { KendoGridDateFilterComponent };
