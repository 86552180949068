import { Component, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { GridDataResult, SelectionEvent, RowArgs } from '@progress/kendo-angular-grid';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../../framework/index';
import { unsubscribe } from '../../../../core/decorators/index';
import {
  Departure
} from '../../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-departures-grid',
  templateUrl: 'departures-grid.component.html',
  styleUrls: ['departures-grid.component.scss']
})
export class DeparturesGridComponent implements OnDestroy {
  @Output()
  public onOutFieldClick: EventEmitter<Departure>;
  @Output()
  public onScheduledFieldClick: EventEmitter<Departure>;
  @Output()
  public onOvertimeFieldClick: EventEmitter<Departure>;
  @Output()
  public onDifferenceFieldClick: EventEmitter<Departure>;
  @Output()
  public onRowSelected: EventEmitter<Departure[]>;

  @Input()
  public set records(data: Departure[]) {
    if (data) {
      this.gridView = {
        data: data,
        total: data.length
      };
    }
  }
  public gridView: GridDataResult;

  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private loadStartedSubscription: Subscription;

  constructor() {
    this.onOutFieldClick = new EventEmitter<Departure>();
    this.onScheduledFieldClick = new EventEmitter<Departure>();
    this.onOvertimeFieldClick = new EventEmitter<Departure>();
    this.onDifferenceFieldClick = new EventEmitter<Departure>();
    this.onRowSelected = new EventEmitter<Departure[]>();
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public selectionChange(selection: SelectionEvent): void {
    const lastRow = _.maxBy(selection.selectedRows, (r: RowArgs) => r.index);
    if (lastRow) {
      const records = _.slice(this.gridView.data, lastRow.index, this.gridView.data.length - 1);
      const filtered = _.filter(records, (r: Departure) => !!r.departureTime);
      this.onRowSelected.emit(filtered);
    } else {
      this.onRowSelected.emit([]);
    }
  }

  public onOutFieldClickHandler(departure: Departure): void {
    this.onOutFieldClick.emit(departure);
  }

  public onScheduledFieldClickHandler(departure: Departure): void {
    this.onScheduledFieldClick.emit(departure);
  }

  public onOvertimeFieldClickHandler(departure: Departure): void {
    this.onOvertimeFieldClick.emit(departure);
  }

  public onDifferenceFieldClickHandler(departure: Departure): void {
    this.onDifferenceFieldClick.emit(departure);
  }
}

