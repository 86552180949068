<div class="header-system-toolbar" [ngClass]="{ 'left-sidebar-open': (isLeftSidebarOpen | async)}">
    <slx-header-left-sidebar-button></slx-header-left-sidebar-button>
    <div class="header-system-toolbar-mobile-logo">
        <div class="left-sidebar-logo"></div>
    </div>
    <div class="header-system-toolbar-right">

        <button *ngIf="appConfig.showSearchButton" type="button" class="header-button" (click)="toggleLeftSidebar()">
            <i class="fa fa-search" aria-hidden="true"></i>
        </button>

        <slx-message-center-notification *ngIf="appConfig.showMessageCenterToolbarIcon" [notificationType]="notificationTypes.MESSAGES"></slx-message-center-notification>
        <slx-header-notification-button *ngIf="appConfig.showReminders" [notificationType]="notificationTypes.REMINDERS"></slx-header-notification-button>

        <span class="divider" *ngIf="appConfig.showMessageCenterToolbarIcon || appConfig.showReminders || appConfig.showSearchButton"></span>
        <slx-user-menu></slx-user-menu>

     </div>
</div>
<div class="header-nav-holder" [ngClass]="{ 'left-sidebar-open': (isLeftSidebarOpen | async), 'hidden-on-mobile': !isShowSecondLine }">
    <slx-header-nav [hideLeftSideBar]="isHiddenLeftSidebar" [hideOrgLevelBreadcrumb]="isHiddenOrgLevelBreadcrumb"></slx-header-nav>
</div>

