import * as _ from 'lodash';
import { Role, RoleSubmenu, RoleMenu, RolesMenuAccess } from '../role-models/index';
import { RolesRowDefinition, IRolesRow, ILinkedToRightRolesRow } from './roles-row';
import { RolesSubmenuRow } from './roles-submenu-row';
import { RolesRightRow } from './roles-right-row';
import { ProductModule } from '../../../../organization/models/index';

export class RolesMenuRow implements ILinkedToRightRolesRow<RoleMenu> {
  public menuId: number;
  public roles: Role[];
  public productModule?: ProductModule;
  public appProductModule?: ProductModule;
  public mapByRole: NumberMap<RoleMenu>;
  public dirtyByRole: NumberMap<boolean>;
  public childRows: RolesSubmenuRow[];
  public type: RolesRowDefinition = 'RolesMenuRow';
  public description: string;
  public linkedRightName: string;
  public linkedRightRow: RolesRightRow;

  public recalcStatus(roleId: number): void {
    let menu: RoleMenu = this.mapByRole[roleId];
    this.dirtyByRole[roleId] = menu.isDirty;
    let existEnabled: boolean = false;
    let existDisabled: boolean = false;
    _.forEach(this.childRows, (childRow: RolesSubmenuRow) => {
      let child: RoleSubmenu = childRow.mapByRole[roleId];
      existEnabled = existEnabled || (child.access === RolesMenuAccess.enabled);
      existDisabled = existDisabled || (child.access === RolesMenuAccess.disabled);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if (existEnabled && existDisabled) {
      menu.access = RolesMenuAccess.mixed;
    } else if (existEnabled) {
      menu.isEnabled = true;
      menu.access = RolesMenuAccess.enabled;
    } else if (existDisabled) {
      menu.isEnabled = false;
      menu.access = RolesMenuAccess.disabled;
    } else {
      menu.access = menu.isEnabled ? RolesMenuAccess.enabled : RolesMenuAccess.disabled;
    }
  }

  public setNextStatus(roleId: number): void {
    let menu: RoleMenu = this.mapByRole[roleId];
    menu.isEnabled = !menu.isEnabled;
    this.setStatus(menu.isEnabled, roleId);
  }

  public setStatus(isEnabled: boolean, roleId: number): void {
    let item: RoleMenu = this.mapByRole[roleId];
    item.isEnabled = isEnabled;
    item.access = isEnabled ? RolesMenuAccess.enabled : RolesMenuAccess.disabled;
    this.dirtyByRole[roleId] = item.isDirty;
    _.forEach(this.childRows, (childRow: RolesSubmenuRow) => {
      childRow.setStatus(isEnabled, roleId);
      if (childRow.dirtyByRole[roleId]) {
        this.dirtyByRole[roleId] = true;
      }
    });
    if(this.linkedRightRow) {
      this.linkedRightRow.setStatus(isEnabled, roleId);
    }
  }
}
