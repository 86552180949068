import {
  Employee,
  IEmployee,
  Organization,
  IOrganization,
  Department,
  IDepartment,
  IPosition,
  Position
} from '../../../organization/models/index';
import { IFieldData } from '../../../core/models/index';

import { IWcCodeEntry, WcCodeEntry } from './wc-code-entry';
import { IWcReportStatusDefinitionDto, WcReportStatusDefinition } from './wc-report-status-definition';
import { IWcMedicalTreatmentDefinitionDto, WcMedicalTreatmentDefinition } from './wc-medical-treatment-definition';

export interface IWcIncidentRosterContainer {
  canCreateEdit: boolean;
  canDelete: boolean;
  records: IWcIncidentRosterEntry[];
}

export class WcIncidentRosterContainer {
  public canEdit: boolean;
  public canDelete: boolean;
  public canCreate: boolean;
  public records: WcIncidentRosterEntry[];
}


export interface IWcIncidentRosterEntry {
  id: number;
  employee: IEmployee;
  organization: IOrganization;
  department: IDepartment;
  position: IPosition;
  injuryType: IWcCodeEntry;
  oshaInjuryType: IWcCodeEntry;
  incidentDateTime: string;
  reportDateTime: string;
  bodyPart: IWcCodeEntry;
  accidentCause: IWcCodeEntry;
  injuryOccured: IWcCodeEntry;
  injuryLocation: IWcCodeEntry;
  reportStatus: IWcReportStatusDefinitionDto;
  medicalTreatment: IWcMedicalTreatmentDefinitionDto;
  dateLoseBegan: string;
  dateLoseEnd: string;
  daysLost: number;
  oshaDateLoseBegan: string;
  oshaDateLoseEnd: string;
  oshaDaysLost: number;
  primaryCause: string;
  howInjuryOccur: string;
  isOSHARecordable: boolean;
  isWorkersCompCase: boolean;
  restrictedDutyDaysNum: number;
  lossConsciousness: boolean;
  carrierName: string;
  whatDoingDuringInjury: string;
  usedProperSafety: boolean;
  hasPriorInjury: boolean;
  exposedFluidToAnother: boolean;
  isPrivacyCase: boolean;
  dateHired: string;
  dateOfBirth: string;
  age: number;
  empType: string;
  oshaCaseNumber: string;
  addedBy: string;
  updatedBy: string;
  medicalCost: number;
  indemnityCost: number;
}

export class WcIncidentRosterEntry {
  public id: number;
  public employee: Employee;
  public organization: Organization;
  public department: Department;
  public position: Position;
  public injuryType: WcCodeEntry;
  public oshaInjuryType: WcCodeEntry;
  public incidentDateTime: Date;
  public reportDateTime: Date;
  public bodyPart: WcCodeEntry;
  public accidentCause: WcCodeEntry;
  public injuryOccured: WcCodeEntry;
  public injuryLocation: WcCodeEntry;
  public reportStatus: WcReportStatusDefinition;
  public medicalTreatment: WcMedicalTreatmentDefinition;
  public dateLoseBegan: Date;
  public dateLoseEnd: Date;
  public daysLost: number;
  public oshaDateLoseBegan: Date;
  public oshaDateLoseEnd: Date;
  public oshaDaysLost: number;
  public primaryCause: string;
  public howInjuryOccur: string;
  public isOSHARecordable: boolean;
  public isWorkersCompCase: boolean;
  public restrictedDutyDaysNum: number;
  public lossConsciousness: boolean;
  public carrierName: string;
  public whatDoingDuringInjury: string;
  public usedProperSafety: boolean;
  public hasPriorInjury: boolean;
  public exposedFluidToAnother: boolean;
  public isPrivacyCase: boolean;
  public dateHired: Date;
  public dateOfBirth: Date;
  public age: number;
  public empType: string;
  public oshaCaseNumber: string;
  public addedBy: string;
  public updatedBy: string;
  public medicalCost: number;
  public indemnityCost: number;
  public metaMap: StringMap<IFieldData>;

  public get empAge(): string {
    const a = parseFloat(<any>this.age);
    return isFinite(a) ? String(a) : '*';
  }

  public get reportFlags(): number {
    let osha = this.isOSHARecordable ? 1 : 0;
    let wc = this.isWorkersCompCase ? 2 : 0;
    return osha | wc;
  }

  public get isOshaRecordable(): string {
    return this.isOSHARecordable ? 'Yes' : 'No';
  }

  public get isWorkersComp(): string {
    return this.isWorkersCompCase ? 'Yes' : 'No';
  }

  public get isLossConsciousness(): string {
    return this.lossConsciousness ? 'Yes' : 'No';
  }

  public get isUsedProperSafety(): string {
    return this.usedProperSafety ? 'Yes' : 'No';
  }

  public get isPriorInjury(): string {
    return this.hasPriorInjury ? 'Yes' : 'No';
  }

  public get isExposedToFluids(): string {
    return this.exposedFluidToAnother ? 'Yes' : 'No';
  }

  public get isPrivacyCaseInjury(): string {
    return this.isPrivacyCase ? 'Yes' : 'No';
  }

  public canEdit: boolean;
  public canDelete: boolean;
}

