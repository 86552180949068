/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./labeled-value-pair.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../labeled-value/labeled-value.component.ngfactory";
import * as i3 from "../labeled-value/labeled-value.component";
import * as i4 from "@angular/common";
import * as i5 from "./labeled-value-pair.component";
import * as i6 from "../../services/value-pair-chart-data/value-pair-chart-data.service";
var styles_LabeledValuePairComponent = [i0.styles];
var RenderType_LabeledValuePairComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabeledValuePairComponent, data: {} });
export { RenderType_LabeledValuePairComponent as RenderType_LabeledValuePairComponent };
function View_LabeledValuePairComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-labeled-value", [["class", "labeled-value-bold"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onClick1Handler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LabeledValueComponent_0, i2.RenderType_LabeledValueComponent)), i1.ɵdid(1, 49152, null, 0, i3.LabeledValueComponent, [], { label: [0, "label"], value: [1, "value"], color: [2, "color"] }, { onClick: "onClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.input.label1; var currVal_1 = _co.model.input.value1; var currVal_2 = _co.model.color.fontColor1; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_LabeledValuePairComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-labeled-value", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onClick2Handler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LabeledValueComponent_0, i2.RenderType_LabeledValueComponent)), i1.ɵdid(1, 49152, null, 0, i3.LabeledValueComponent, [], { label: [0, "label"], value: [1, "value"], color: [2, "color"] }, { onClick: "onClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.input.label2; var currVal_1 = _co.model.input.value2; var currVal_2 = _co.model.color.fontColor2; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LabeledValuePairComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabeledValuePairComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LabeledValuePairComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.model; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_LabeledValuePairComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-labeled-value-pair", [], null, null, null, View_LabeledValuePairComponent_0, RenderType_LabeledValuePairComponent)), i1.ɵdid(1, 245760, null, 0, i5.LabeledValuePairComponent, [i6.ValuePairChartDataService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LabeledValuePairComponentNgFactory = i1.ɵccf("slx-labeled-value-pair", i5.LabeledValuePairComponent, View_LabeledValuePairComponent_Host_0, {}, { onClick1: "onClick1", onClick2: "onClick2" }, []);
export { LabeledValuePairComponentNgFactory as LabeledValuePairComponentNgFactory };
