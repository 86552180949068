<slx-spinner [show]="isLoading">
  <div class="main-container">
    <div class="header-container">
      <div class="header-part left-part" *ngIf="!isMobile">
        <slx-checkbox-toggle class="toggler" [(ngModel)]="state.isTileView" className="toggle-right" caption="Tile View" checkedText="Yes"
          uncheckedText="No" (ngModelChange)="onTileViewChanged()"></slx-checkbox-toggle>
      </div>
      <div class="header-part center-part">
        <p class="theme-header-text header-local">{{listTitle}}</p>
      </div>
      <div *ngIf="!state.isTileView && !isMobile" class="header-part right-part">
        <slx-actions-list>
          <slx-actions-button [popperContent]="popperContent" [popperPosition]="'bottom-end'">
            Actions
            <span class="caret"></span>
          </slx-actions-button>
          <popper-content #popperContent>
            <slx-actions-list-item (onClick)="onExportToExcel()">Export to Excel</slx-actions-list-item>
            <slx-actions-list-item (onClick)="onExportToPdf()">Export to Pdf</slx-actions-list-item>
          </popper-content>
        </slx-actions-list>
      </div>
    </div>
    <div class="main-content">
      <div *ngIf="this.state.isTileView || isMobile">
        <slx-employee-details-list [dateOn]="selectedDate" [employeeDetails]="employeeDetailsList"></slx-employee-details-list>
      </div>
      <div *ngIf="!this.state.isTileView && !isMobile">
        <slx-employee-details-table-list #detailsTableList [dateOn]="selectedDate" [employeeDetails]="employeeDetailsList"></slx-employee-details-table-list>
      </div>
    </div>
  </div>
</slx-spinner>
