import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ScheduleConsoleCycles, ConsoleConfig, SoValuePairWidgetConfig } from '../../models/index';
import { ScheduleConsoleChartService, ScheduleConsoleFiltersService } from '../../services/index';
import { ValuePairChartSeriesDataService } from '../../../../common/services/value-pair-chart-data/value-pair-chart-series-data.service';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { OperationalConsoleStateService } from '../../../services/operational-console/operational-console-state.service';
var ScheduleConsoleOverviewComponent = /** @class */ (function () {
    function ScheduleConsoleOverviewComponent(dataService, filtersService, stateService) {
        this.dataService = dataService;
        this.filtersService = filtersService;
        this.stateService = stateService;
        this.chartConfig = new SoValuePairWidgetConfig();
    }
    Object.defineProperty(ScheduleConsoleOverviewComponent.prototype, "showDetailed", {
        get: function () {
            return this.m_showDetailed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleConsoleOverviewComponent.prototype, "isDayView", {
        get: function () {
            return this.rangeType === 'Day';
        },
        enumerable: true,
        configurable: true
    });
    ScheduleConsoleOverviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.setConfig(this.chartConfig);
        this.updateChartDataService();
        this.applicationSubscripion = this.application$.subscribe(function (value) {
            _this.stateService.application = value;
        });
        this.filtersAppliedSubscription = this.filtersService.onFiltersApplied.subscribe(function () {
            _this.updateChartDataService();
            _this.stateService.canLoadData = _this.isDayView && _this.showDetailed;
            _this.stateService.loadData();
        });
    };
    ScheduleConsoleOverviewComponent.prototype.ngOnChanges = function (changes) {
        if (changes['consoleConfig'] || changes['consoleData'] || changes['rangeType']) {
            this.updateChartDataService();
        }
    };
    ScheduleConsoleOverviewComponent.prototype.onShowDetailedChange = function (showDetailed) {
        this.m_showDetailed = showDetailed;
        this.stateService.canLoadData = this.isDayView && this.showDetailed;
        this.stateService.loadData();
    };
    ScheduleConsoleOverviewComponent.prototype.updateChartDataService = function () {
        if (!this.consoleConfig || !this.consoleData) {
            return;
        }
        var inputs = ScheduleConsoleChartService.makeChartSeriesInputs(this.consoleData, this.consoleConfig, this.rangeType === 'Week');
        this.dataService.setModels(inputs, this.chartConfig);
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleConsoleOverviewComponent.prototype, "application$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleOverviewComponent.prototype, "applicationSubscripion", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ScheduleConsoleOverviewComponent.prototype, "filtersAppliedSubscription", void 0);
    return ScheduleConsoleOverviewComponent;
}());
export { ScheduleConsoleOverviewComponent };
