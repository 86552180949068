import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { WindowRef } from '../../../../core/services/window/window-ref.model';
import { DomSanitizer } from '@angular/platform-browser';
var ViewEliteDashboardComponent = /** @class */ (function () {
    function ViewEliteDashboardComponent(router, winRef, domSanitizer) {
        this.router = router;
        this.winRef = winRef;
        this.domSanitizer = domSanitizer;
        this.isIFrameLoading = true;
        this.isDataLoading = false;
        this.isActive = true;
        this.eliteUrl = 'https://dev-logi-10x.smartlinxsolutions.com/powerbidesignerlatest/home';
    }
    ViewEliteDashboardComponent.prototype.ngOnInit = function () {
        this.safeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(this.eliteUrl);
        this.isIFrameLoading = false;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ViewEliteDashboardComponent.prototype, "loadStateChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ViewEliteDashboardComponent.prototype, "logiLinkChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ViewEliteDashboardComponent.prototype, "logiLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ViewEliteDashboardComponent.prototype, "logiActionSubscription", void 0);
    return ViewEliteDashboardComponent;
}());
export { ViewEliteDashboardComponent };
