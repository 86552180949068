import * as tslib_1 from "tslib";
import { ManagementBaseService } from '../../../../core/services/index';
import { PayrollExportApiService } from './payroll-export-api.service';
import { NotificationsService } from '../../../../core/components/index';
import { appMessages } from '../../../../app.messages';
import { OrgLevelType } from '../../../../state-model/models/index';
var PayrollExportManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PayrollExportManagementService, _super);
    function PayrollExportManagementService(api, notificationsService) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.notificationsService = notificationsService;
        return _this;
    }
    PayrollExportManagementService.prototype.isOrganization = function () {
        return this.orgLevel.type === OrgLevelType.organization;
    };
    PayrollExportManagementService.prototype.loadPayrollExport = function (lvl, startDate, endDate) {
        var _this = this;
        this.orgLevel = lvl;
        this.startDate = startDate;
        this.endDate = endDate;
        this.onLoadStatusChanged(true);
        this.api.getPayrollExport(this.orgLevel.id, startDate, endDate)
            .then(function (data) {
            _this.data = data;
            _this.onLoaded(data);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PayrollExportManagementService.prototype.releaseToPayroll = function (groupName) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.releaseToPayroll(this.orgLevel.id, groupName, this.startDate, this.endDate)
            .then(function (x) {
            _this.loadPayrollExport(_this.orgLevel, _this.startDate, _this.endDate);
            _this.notificationsService.success("" + appMessages.success.releasePayrollExport.title, appMessages.success.releasePayrollExport.message);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    PayrollExportManagementService.prototype.cancelRelease = function (groupName) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.cancelRelease(this.orgLevel.id, groupName, this.startDate, this.endDate)
            .then(function (x) {
            _this.loadPayrollExport(_this.orgLevel, _this.startDate, _this.endDate);
            _this.notificationsService.success("" + appMessages.success.cancelPayrollExport.title, appMessages.success.cancelPayrollExport.message);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    return PayrollExportManagementService;
}(ManagementBaseService));
export { PayrollExportManagementService };
