<div class="inline-controls">
  <slx-input-decorator class="auto-height control">
    <slx-dropdown-multiselect slx-input [lookup]="locationUnitLookup"
    placeholder="Unit" name="unitFilter" [(ngModel)]="unitFilter" valueField="id" titleField="name"
    (ngModelChange)="onUnitChanged()" [readonly]="!isUnitDropdownAvailable"
    >
    </slx-dropdown-multiselect>
  </slx-input-decorator>

  <slx-input-decorator class="auto-height control">
    <slx-dropdown-multiselect slx-input [lookup]="shiftLookup"
    placeholder="Shift" name="shiftFilter" [(ngModel)]="shiftFilter" valueField="id" titleField="name"
    (ngModelChange)="onShiftChanged()" [readonly]="!isShiftDropdownAvailable"
    >
    </slx-dropdown-multiselect>
  </slx-input-decorator>

  <div class="checkbox-container control" *ngIf="showDetailedControl">
    <slx-checkbox-toggle [ngClass]="'right-position'" className="toggle-left" [(ngModel)]="showDetailed"
                         (ngModelChange)="onShowDetailedChanged($event)"
                         caption="Expanded Details" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
  </div>
</div>

