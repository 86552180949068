<kendo-grid #kendoSupervisorGrid kendoGridFixFreezeHeader class="slx-full-height"
  [data]="gridState.view"
  [sortable]="{ mode: 'multiple' }"
  [sort]="gridState.state.sort"
  [filterable]="true"
  [filter]="gridState.state.filter"
  (dataStateChange)="gridState.dataStateChange($event)">

  <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected" media="sm" width="40">
    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
    </ng-template>
    <ng-template kendoGridHeaderTemplate>
      <input type="checkbox" [ngModel]="selectionHelper.isAllSelected" (ngModelChange)="allSelectionChange()" name="allSelectionToggle">
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <input type="checkbox" *ngIf="!dataItem.groups||dataItem.groups.length<=15" [ngModel]="dataItem.isSelected" (ngModelChange)="selectionChange(dataItem)"
        name="selector{{rowIndex}}">
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Name" width="150" media="sm" field="employee.employee.name">
    <ng-template kendoGridHeaderTemplate>
      Name
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      <a [employeeSectionLink]="dataItem?.employee.employee.id">
        {{dataItem?.employee?.employee?.name}}
      </a>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Center" width="150" media="sm" field="employee.positionDepartment.name">
    <ng-template kendoGridHeaderTemplate>
      Center
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employee?.positionDepartment?.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Position" width="150" media="sm" field="employee.position.name">
    <ng-template kendoGridHeaderTemplate>
      Position
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.employee?.position?.name}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-string-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="# of Groups" width="150" media="sm" field="numberGroups">
    <ng-template kendoGridHeaderTemplate>
      # of Groups
    </ng-template>
    <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
      <strong>{{value}}</strong>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem?.numberGroups}}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter">
      </slx-kendo-grid-number-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column title="Groups" media="sm" width="200">
    <ng-template kendoGridHeaderTemplate>
      Groups
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.groups | slxJoin :'name' :','}}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
