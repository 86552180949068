<div class="msg-notification-button-container" *ngIf="isEnableIcon" [class.highlight]="popupShown">

  <button type="button"  class="header-button-dissabled" *ngIf="!isMessageIcon" > 
    <i *ngIf="notificationType == notificationTypes.MESSAGES" class="fas fa-envelope" aria-hidden="true"></i>
    <span *ngIf="notificationsCount > 0" class="notification-badge-dissabled"><em class="fas fa-times-circle"></em></span>
  </button>

  <button type="button" class="header-button" *ngIf="isMessageIcon"
  (keyup)="onKeyup($event)" [popper]="popperContent" [popperTrigger]="'click'"
    [popperPlacement]="popperPosition" [popperDisableStyle]="'true'" 
    [popperModifiers]="popperModifiers" (popperOnShown)="onShown($event)"
    (popperOnHidden)="onHidden($event)" (click)="showPopper()"> 
    <i *ngIf="notificationType == notificationTypes.MESSAGES" class="fas fa-envelope" aria-hidden="true"></i>
    <span *ngIf="notificationsCount > 0" class="notification-badge">{{notificationsCount}}</span>
  </button>

<popper-content #popperContent class="slx-msg-popper" [style.top.px]="popupClassProperties.top"  [style.right.px]="popupClassProperties.right" >
    <div class="notification-popup-body" *ngIf="popupShown">
      <slx-message-center-notification-content>
        <div class="ip-desktop-body" class="tab-header">
          <div class="sms-tabs flex">
              <div class="ip-tabs__slider">
                <ul class="ip-tabs__list flex flex-direction-row">
                  <li class="sms_select" [class.active]="isActiveMenu(0)" (click)="onClickTab(0)">
                    <span class="tab_text"><i class="fas fa-envelope" aria-hidden="true"></i> SMS  </span>
                    <span class="sms_count"> {{notificationsCount}} </span>
                  </li>
                  <li class="inbox_select" [class.active]="isActiveMenu(1)" >
                    <span class="tab_text"></span></li>
                  <li class="posts_item" [class.active]="isActiveMenu(2)" >
                    <span class="tab_text"></span></li>
                  <li class="empty_item" ></li>
                </ul>
              </div>
            </div>
      </div>
      <div class="notification-list" >
        <ng-container *ngIf="!activeTab">
          <slx-message-center-notification-list [messageCount]="messageCount" (closeModal)="goToMessageCenter($event)" [isMyMessage]="isMyMessage"
          *ngIf="notificationType == notificationTypes.MESSAGES && popupShown">
          </slx-message-center-notification-list>
        </ng-container>
        <ng-container *ngIf="activeTab">
          <p>tab</p>
        </ng-container>
    </div>
      <div class="notification-footer">
       
        <div class="buttonsdiv">
          <button
          type="button"
          class="slx-button slx-mobile-adapted mapping-btn" (click)="goToMessageCenter(true)">
            <span class="slx-button__text"><em class="fas fa-inbox"> </em> Message Center</span>
          </button>
          <button
          type="button"
          class="slx-button slx-blue slx-mobile-adapted mapping-btn" (click)="goToMessageCenter('composeNew')" [disabled]="!canSendSMS && !canSendSlate">
            <span class="slx-button__text"><em class="far fa-paper-plane" > </em> Compose New</span>
          </button>
        </div>
      </div>
      </slx-message-center-notification-content>
    </div>
  </popper-content>
</div>