import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SessionActions } from '../../../authentication/actions/session.actions';
import { NotificationsApiService } from '../../services/notifications/notifications-api.service';
import { mutableSelect } from '../../../core/decorators/redux-decorators';
import { unsubscribe } from '../../../core/decorators/unsubscribe-decorator';
import { ISession } from '../../../authentication/store/session/session.types';
import { Subscription } from 'rxjs/Subscription';
var HeaderRemindersListComponent = /** @class */ (function () {
    function HeaderRemindersListComponent(api, sessionActions) {
        this.api = api;
        this.sessionActions = sessionActions;
    }
    HeaderRemindersListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.session$.subscribe(function (session) {
            session.user.remidersUnread = 0;
            _this.sessionActions.saveSession(session);
        });
        this.isLoading = true;
        this.api.getReminders().then(function (result) {
            _this.reminders = result;
            _this.isLoading = false;
            _this.api.markRemindersAsRead(_this.reminders);
        });
    };
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], HeaderRemindersListComponent.prototype, "session$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], HeaderRemindersListComponent.prototype, "userSubscription", void 0);
    return HeaderRemindersListComponent;
}());
export { HeaderRemindersListComponent };
