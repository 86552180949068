import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  ViewChild,
  HostListener,
  HostBinding,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';
import { CustomDomEvents } from '../../models/index';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validator, Validators, ValidatorFn, AbstractControl, NgModel } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';

export const ZIP_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ZipInputComponent),
  multi: true
};

export const ZIP_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ZipInputComponent),
  multi: true
};

@Component({
  moduleId: module.id,
  selector: 'slx-zip-input',
  templateUrl: 'zip-input.component.html',
  styleUrls: ['zip-input.component.scss'],
  providers: [ZIP_VALUE_ACCESSOR, ZIP_VALIDATOR]
})
export class ZipInputComponent implements ControlValueAccessor, Validator, OnChanges {
  @Input()
  public className: string;
  @Input()
  public placeholder: string = 'Zip';
  @Input()
  public minlength: number = 5;
  @Input()
  public maxlength: number = 10;
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  public zipMaskConf: { mask: Array<RegExp>, guide: boolean };
  public inEdit: boolean;
  public innerValue: any = '';

  @HostBinding('class.edited')
  private hasFocus: boolean = false;
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;

  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.hasFocus = false;
    this.zipMaskConf = {
      mask: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
      guide: false
    };
  }

  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }

  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }

  public writeValue(value?: any): void {
    this.innerValue = value;
    this.elementRef.nativeElement.value = value ? value : null;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public valueChanged(value: any): void {
    this.onChangeCallback(value);
    this.onTouchedCallback();
  }

  public validate(c: AbstractControl): { [key: string]: boolean } {
    const minLengthValidator: ValidatorFn = Validators.minLength(+this.minlength);
    const maxLengthValidator: ValidatorFn = Validators.maxLength(+this.maxlength);
    const errors: { [key: string]: boolean } = {};
    if (minLengthValidator(c)) {
      errors.min = true;
    }
    if (maxLengthValidator(c)) {
      errors.max = true;
    }
    return _.size(errors) > 0 ? errors : null;
  }
}
