import { Pipe, Injectable, PipeTransform } from '@angular/core';

import { GroupResult } from '@progress/kendo-data-query';
import * as _ from 'lodash';

import { VacationPlanWeekGroup, VacationPlanEmployeeRecord } from '../models/index';

@Pipe({
  name: 'vacationDays',
  pure: false
})
@Injectable()
export class VacationDaysPipe implements PipeTransform {
  public transform(grpRes: GroupResult, week: number): any {
    if (!grpRes) {
      return null;
    }
    let items: VacationPlanEmployeeRecord[] | GroupResult[] = <VacationPlanEmployeeRecord[] | GroupResult[]>grpRes.items;
    let group: VacationPlanWeekGroup = new VacationPlanWeekGroup();
    group.calculateGroups(items, week);
    return group.totalDays > 0 ? group.totalDays : null;
  }
}
