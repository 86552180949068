import { PasswordValidatorAdapter } from './../../../../../authentication/services/password-reset/password-validator-adapter';
import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, SimpleChanges } from '@angular/core';
import { NgForm, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

import { Assert } from '../../../../../framework/index';

import { EmployeeSectionsEssTemplate, EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { FieldData } from '../../../../../core/models';
import { EmployeeSelfServiceApiService } from '../../../../employee/services/index';
import { ConfirmDialogComponent, InfoDialogComponent } from '../../../../../common/components/index';
import { ModalService } from '../../../../../common/index';

import { EssLookupManageService } from '../../../../../organization/services/lookup/ess-lookup-manage.service';
import { EssEntity, EmployeeShortInfo, EssDefaultPassword } from '../../../../../organization/models/index';
import { EmployeeDefinitionsApiService } from '../../../../../organization/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-ess-template',
  templateUrl: 'employee-sections-ess-template.component.html',
  styleUrls: ['employee-sections-ess-template.component.scss']
})
export class EmployeeSectionsEssTemplateComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input() public employeeId: number;
  @Input()
  public set employeeSectionsEssTemplate(value: EmployeeSectionsEssTemplate) {
    this.m_employeeSectionsEssTemplate = value;
    this.fillValues();
  }

  public get employeeSectionsEssTemplate(): EmployeeSectionsEssTemplate {
    return this.m_employeeSectionsEssTemplate;
  }

  public get form(): AbstractControl {
    return this.ngForm ? this.ngForm.form : null;
  }

  public passwordType: EssEntity;
  public customPassword: string = '';
  public showPassword: boolean;
  public passwordInFocus: boolean;

  @ViewChild('form', {static: false})
  public ngForm: NgForm;

  private m_employeeSectionsEssTemplate: EmployeeSectionsEssTemplate = new EmployeeSectionsEssTemplate();

  constructor(
    ngZone: NgZone,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent,
    private modalService: ModalService,
    private employeeSectionsEmploymentApiService: EmployeeSectionsEmploymentApiService,
    private employeeSelfServiceApiService: EmployeeSelfServiceApiService,
    public essLookupService: EssLookupManageService,
    private employeeDefinitionApi: EmployeeDefinitionsApiService,
    public passwordValidator: PasswordValidatorAdapter
  ) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
    this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
    this.essLookupService.onLoaded$.subscribe((value: any) => {
      this.fillValues();
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.employeeSectionsEssTemplate;
  }

  public get showPasswordField(): boolean {
    return this.state && this.state.isEditMode && this.passwordType && this.passwordType.id === EssDefaultPassword.custom;
  }

  public get customPasswordType(): string {
    return (!this.showPassword && this.passwordInFocus) ? 'password' : 'text';
  }

  public get passwordValidation(): string {
    return PasswordValidatorAdapter.passwordValidation;
  }

  public canSave(): Promise<boolean> {
    if(this.passwordType.id === EssDefaultPassword.noChange) {
      return Promise.resolve(true);
    }
    const promise = new Promise<boolean>((resolve) => {
      ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to override the existing password?', this.modalService, (confirmed: boolean) => {
        resolve(confirmed ? this.checkSsn() : false);
      });
    });
    return promise;
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  public onCustomPasswordFocused(): void {
    this.passwordInFocus = true;
  }


  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsEmploymentApiService.getEmployeeSectionsEssTemplate(this.employeeId)
      .then((employeeSectionsEssTemplate: EmployeeSectionsEssTemplate) => {
        this.employeeSectionsEssTemplate = employeeSectionsEssTemplate;
        this.stopProgress();
      })
      .catch((reason: any) => {
        this.onActionError(reason);
      });
  }

  protected doSave(): void {
    let promise;

    promise = this.employeeSelfServiceApiService.setEssPasswordState(
      [this.employeeId],
      this.passwordType.id,
      this.employeeSectionsEssTemplate.passwordShouldBeReseted,
      this.passwordType.id === EssDefaultPassword.custom ? this.customPassword : null
    );

    promise.then((response: any) => {
      this.onActionComplete(true);
    }).catch((reason: any) => {
      this.onActionError(reason);
    });
  }

  private fillValues(): void {
      this.passwordType = new EssEntity();
      this.passwordType.id = EssDefaultPassword.noChange;
      this.passwordType.name = 'No Changes';
      this.showPassword = false;
      this.passwordInFocus = false;
      this.customPassword = '';
  }

  private checkSsn(): Promise<boolean> {
    if (this.passwordType.id === EssDefaultPassword.last4SSN) {
      return this.employeeDefinitionApi.getEmployeeShortInfo(this.employeeId)
        .then((value: EmployeeShortInfo) => {
          if (value.ssn) {
            return Promise.resolve(true);
          } else {
            InfoDialogComponent.OpenDialog('Create ESS account',
              'Can set password by selected default password type, when employee doesn\'t have SSN',
              this.modalService);
            return Promise.resolve(false);
          }

        }).catch((reason: any) => {
          return Promise.resolve(false);
        });
    }
    return Promise.resolve(true);
  }

}
