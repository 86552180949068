import { Component, OnInit, Provider } from '@angular/core';
import { DialogOptions, IDialog, ModalService } from '../../../../common';
import { LookupType } from '../../../../organization';
import * as _ from 'lodash';
import { ScheduleEntryManagementService } from '../../../../scheduler/services';
import { DailyUnitFilterOptions } from '../../../../scheduler/models/daily-unit-assignment/daily-unit-filter-options';

@Component({
  selector: 'slx-daily-unit-filter-popup',
  templateUrl: './daily-unit-filter-dialog.component.html',
  styleUrls: ['./daily-unit-filter-dialog.component.scss']
})
export class DailyUnitFilterDialogComponent implements IDialog {

  public dialogResult: boolean;
  public isSelected : boolean = true;
  public duaOptions : DailyUnitFilterOptions

  public static openDialog(options: DailyUnitFilterOptions, modalService: ModalService, callback: (result: boolean) => void): DailyUnitFilterDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.width = 300;
    dialogOptions.className = 'slx-dua-filter';
    dialogOptions.showCloseButton = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      }
    ];
    let dialog: DailyUnitFilterDialogComponent = modalService.globalAnchor
      .openDialog(DailyUnitFilterDialogComponent, 'Filters', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        
        callback(result);
      });
    return dialog;
  }

  constructor(public modalService: ModalService,  public scheduleEntry : ScheduleEntryManagementService ) {
    this.duaOptions = this.scheduleEntry.duaOptions;
      }

        onDiscard() {
    this.dialogResult = false;
    this.modalService.closeAllWindows();
  }

  onApply(){
    this.dialogResult = true;
    this.modalService.closeAllWindows();
  }

  public onFilterChange(filterName: string, filter: any[]): void {
    this.isSelected = false;
    let ids: number[] = _.map(filter, (m) => m.id);
    if (filterName === 'unit') {
      this.duaOptions.selectedUnitIds = ids;
    } 
    else if (filterName === LookupType.position) {
      this.duaOptions.selectedPositionIds = ids;

    } else if (filterName === LookupType.shift) {
      this.duaOptions.selectedShiftIds = ids;

    } else if (filterName === LookupType.positionGroups) {
      this.duaOptions.selectedPositionGroupIds = ids;
    }
    this.scheduleEntry.duaOptions.selectedPositionIds = this.duaOptions.selectedPositionIds;
    this.scheduleEntry.duaOptions.selectedShiftIds = this.duaOptions.selectedShiftIds;
    this.scheduleEntry.duaOptions.selectedUnitIds = this.duaOptions.selectedUnitIds;
    this.scheduleEntry.duaOptions.selectedPositionGroupIds = this.duaOptions.selectedPositionGroupIds;
    
   }

}
