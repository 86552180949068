import { BarChartVerticalValuePairComponent } from '../bar-chart-vertical-value-pair/bar-chart-vertical-value-pair.component';
import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { SeriesClickEvent } from '@progress/kendo-angular-charts';

@Component({
    moduleId: module.id,
    selector: 'slx-bar-chart-horizontal-value-pair',
    templateUrl: 'bar-chart-horizontal-value-pair.component.html',
    styleUrls: ['bar-chart-horizontal-value-pair.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
export class BarChartHorizontalValuePairComponent extends BarChartVerticalValuePairComponent {

  @Input()
  public hideValues: boolean = false; //Hiding original values
  @Input()
  public outLabel: boolean = false;

  public handleClick(event: SeriesClickEvent): void {
    if (event.originalEvent && event.originalEvent.type === 'contextmenu') {
      return;
    }

    this.onClick.emit(event);
  }
}
