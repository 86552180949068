import * as tslib_1 from "tslib";
import { HttpRequest } from '@angular/common/http';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
import { schedulerConfig } from '../../scheduler.config';
import { ScheduleEntry, DailyOpenShiftDetailsData, PayPeriodTimeCard } from '../../models/index';
import { ScheduleEntryMapService } from './schedule-entry-map.service';
import { dateTimeUtils } from '../../../common/utils/index';
import { IDetailsAgencyPosition } from '../../models/detail-screen/details-agency-position';
var ScheduleEntryApiService = /** @class */ (function () {
    function ScheduleEntryApiService(apiUtilService, scheduleEntryMapService, urlParamsService, applicationStateBusService) {
        this.applicationStateBusService = applicationStateBusService;
        this.apiUtilService = apiUtilService;
        this.scheduleEntryMapService = scheduleEntryMapService;
        this.urlParamsService = urlParamsService;
    }
    ScheduleEntryApiService.prototype.getScheduleEntryRaw = function (employeeId, dateOn) {
        var url = this.apiUtilService.apiRoot(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.entry, String(employeeId));
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ dateOn: dateTimeUtils.convertToDtoString(dateOn) })
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    ScheduleEntryApiService.prototype.getScheduleEntry = function (employeeId, dateOn) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.entry, String(employeeId));
        var request = new HttpRequest('GET', url, {
            params: this.urlParamsService.convertToParams({ dateOn: dateTimeUtils.convertToDtoString(dateOn) })
        });
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.scheduleEntryMapService.mapToScheduleEntry(response.data); });
        return promise;
    };
    ScheduleEntryApiService.prototype.saveScheduleEntry = function (scheduleEntry, orgLevelId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["", "/", "/", "/", "/", "/", ""], ["", "/", "/", "/", "/", "/", ""])), schedulerConfig.api.root, dateTimeUtils.convertToDtoString(scheduleEntry.date), schedulerConfig.api.orglevel, orgLevelId, schedulerConfig.api.employees.root, String(scheduleEntry.employee.id));
        var request = new HttpRequest('PUT', url, this.scheduleEntryMapService.mapToScheduleShiftDefinitionDtos(scheduleEntry));
        return this.apiUtilService.request(request)
            .then(function (response) {
            _this.applicationStateBusService.resetCache('TimecardsApiServiceCache');
            return response.data;
        });
    };
    ScheduleEntryApiService.prototype.saveShiftsEntry = function (dailyOpenShiftDetailsData, orgLevelId) {
        var url = this.apiUtilService.apiRoot(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["", "/", "/", "/", ""], ["", "/", "/", "/", ""])), schedulerConfig.api.root, schedulerConfig.api.orglevel, orgLevelId, schedulerConfig.api.saveSlxPartnerData);
        var request = new HttpRequest('POST', url, dailyOpenShiftDetailsData);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.data; });
        return promise;
    };
    ScheduleEntryApiService.prototype.getAgencyPoistionsData = function (customerId, partnerId) {
        var _this = this;
        var url = this.apiUtilService.apiRoot(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["lookup/customer/", "/partner/", "/", ""], ["lookup/customer/", "/partner/", "/", ""])), customerId, partnerId, schedulerConfig.api.positions);
        var request = new HttpRequest('GET', url);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return _this.scheduleEntryMapService.mapToPostions(response.data); });
        return promise;
    };
    ScheduleEntryApiService.prototype.checkApprovedPayperiod = function (empId, startDate, EndDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, obj, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.apiUtilService.apiRoot(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.timecards.timeAndAttendance, schedulerConfig.timecards.root, schedulerConfig.timecards.approvedPayperiodForIndividual);
                obj = {
                    EmpId: empId,
                    StartDate: startDate,
                    EndDate: EndDate
                };
                request = this.urlParamsService.createPutRequest(url, obj);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response.data;
                    })];
            });
        });
    };
    ScheduleEntryApiService.prototype.getApprovedPayPeriod = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.apiUtilService.apiRoot(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.timecards.timeAndAttendance, schedulerConfig.timecards.root, schedulerConfig.timecards.approvedPayperiod);
                body = data;
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response.data;
                    })];
            });
        });
    };
    ScheduleEntryApiService.prototype.checkApprovedPayperiodForOrgLevel = function (orglevel, startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, obj, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.apiUtilService.apiRoot(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.timecards.timeAndAttendance, schedulerConfig.timecards.root, schedulerConfig.timecards.approvedPayperiodForOrgLevel);
                obj = {
                    OrgLevel: orglevel,
                    StartDate: startDate,
                    EndDate: endDate
                };
                body = obj;
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response.data;
                    })];
            });
        });
    };
    ScheduleEntryApiService.prototype.getApprovedPayPeriodForQuickEdit = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.apiUtilService.apiRoot(templateObject_9 || (templateObject_9 = tslib_1.__makeTemplateObject(["", "/", "/", ""], ["", "/", "/", ""])), schedulerConfig.timecards.timeAndAttendance, schedulerConfig.timecards.root, schedulerConfig.timecards.approvedPayperiodForQuickEdit);
                body = data;
                request = this.urlParamsService.createPutRequest(url, body);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) {
                        return response.data;
                    })];
            });
        });
    };
    return ScheduleEntryApiService;
}());
export { ScheduleEntryApiService };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
