export interface IEmpType {
  name: string;
  description: string;
  fullTimeIndex: string;
}

export class EmpType {
  public name: string;
  public description: string;
  public fullTimeIndex: string;
}
