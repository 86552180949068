import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

import { CommonValidators } from './common-validators';

const DATE_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DateValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[date][formControlName],[date][formControl],[date][ngModel]',
  /* tslint:enable */
  providers: [DATE_VALIDATOR]
})
export class DateValidator implements Validator {
  public validate(c: AbstractControl): {[key: string]: any} {
    return CommonValidators.date(c);
  }
}
