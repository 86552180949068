import * as tslib_1 from "tslib";
import { ModalService } from './../../../../../common/services/modal/modal.service';
import { ConfirmDialogComponent, ConfirmOptions } from './../../../../../common/components/confirm-dialog/confirm-dialog.component';
import { ChangeDetectorRef } from '@angular/core';
import { PayrollExportGroup } from '../../../models/index';
import { appConfig } from '../../../../../app.config';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../core/decorators/index';
import { PayrollExportManagementService } from '../../../services/index';
var PayrollExportSectionComponent = /** @class */ (function () {
    function PayrollExportSectionComponent(managementService, changeDetector, modalService) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.modalService = modalService;
    }
    Object.defineProperty(PayrollExportSectionComponent.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    PayrollExportSectionComponent.prototype.isExportAvailable = function () {
        if (!this.group)
            return false;
        var orgCount = this.group.facilities ? this.group.facilities.length : 0;
        var result = this.group.canReleaseToPayroll
            && !this.group.isAllFacilitiesReleased
            && this.group.isAllFacilitiesRequested
            && this.group.groupOrgCount === orgCount;
        return result;
    };
    PayrollExportSectionComponent.prototype.getRequestColor = function () {
        return this.group && this.group.isAllFacilitiesRequested ? '#8FAE15' : '#0092CB';
    };
    PayrollExportSectionComponent.prototype.collapsedChange = function (isCollapsed) {
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    PayrollExportSectionComponent.prototype.onReleaseToPayroll = function () {
        this.managementService.releaseToPayroll(this.group.name);
    };
    PayrollExportSectionComponent.prototype.onCancelRelease = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialogComponent.openDialog('Cancel Release', 'Cancelling the release of this payroll will delete this request force you to resubmit. Are you sure you want to proceed?', this.modalService, function (result) {
            if (result) {
                _this.managementService.cancelRelease(_this.group.name);
            }
        }, options);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportSectionComponent.prototype, "loadedSubscription", void 0);
    return PayrollExportSectionComponent;
}());
export { PayrollExportSectionComponent };
