import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'weather-icon',
  styleUrls: ['weather-icon.component.scss'],
  templateUrl: 'weather-icon.component.html'
})
export class WeatherIconComponent {
  @Input() iconClass: string;
  @Input() iconImageUrl: string;
  @Input() title: string;
}
