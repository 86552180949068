import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process } from '@progress/kendo-data-query';
import { unsubscribe } from '../../../../../core/decorators/index';
import { appConfig } from '../../../../../app.config';
import { PayrollExportGroup } from '../../../models/index';
import { KendoGridStateHelper } from '../../../../../common/models/index';
import { PayrollExportManagementService } from '../../../services/index';
var PayrollExportGridComponent = /** @class */ (function () {
    function PayrollExportGridComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
    }
    Object.defineProperty(PayrollExportGridComponent.prototype, "startDate", {
        get: function () {
            return this.managementService.startDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PayrollExportGridComponent.prototype, "endDate", {
        get: function () {
            return this.managementService.endDate;
        },
        enumerable: true,
        configurable: true
    });
    PayrollExportGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    PayrollExportGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes['group']) {
            this.records = this.group.facilities;
            this.refreshGrid();
        }
    };
    PayrollExportGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PayrollExportGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayrollExportGridComponent.prototype, "gridRefreshSubscription", void 0);
    return PayrollExportGridComponent;
}());
export { PayrollExportGridComponent };
