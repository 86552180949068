import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../core/decorators/index';
import { StateManagementService } from '../../../common';
import { AcaMeasurementManagementService } from '../../services/aca-measurement/aca-measurement-management.service';
var AcaMeasurementComponent = /** @class */ (function () {
    function AcaMeasurementComponent(acaManagementService, stateManagementService) {
        this.acaManagementService = acaManagementService;
        this.stateManagementService = stateManagementService;
        this.isLoading = false;
        this.componentId = 'AcaMeasurementComponent';
        this.groupId = 'AcaMeasurementGrid';
        this.subscriptions = {};
    }
    AcaMeasurementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagementService.init(this.componentId, true);
        this.acaManagementService.init();
        this.subscriptions.loading = this.acaManagementService.subscribeToLoading(function (isLoading) {
            _this.isLoading = isLoading;
        });
    };
    AcaMeasurementComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaMeasurementComponent.prototype, "subscriptions", void 0);
    return AcaMeasurementComponent;
}());
export { AcaMeasurementComponent };
