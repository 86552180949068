<div class="modal-body">
  <slx-employee-sections-warnings-entity
    [employeeWarning]="warning"
    (warningEdited)="onEditedWarning($event)"
    (changeWarning)="onChangedWarning($event)"
  ></slx-employee-sections-warnings-entity>
</div>
<div class="modal-footer">
  <button type="button" (click)="onUserAction(false)" class="btn red">
    <i class="fas fa-times" aria-hidden="true"></i>
    Cancel
  </button>
  <button type="button" [disabled]="!canSave" (click)="onUserAction(true)" class="btn green">
    <i class="fas fa-save" aria-hidden="true"></i>
    Save
  </button>
</div>
