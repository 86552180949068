import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { RolesMapService } from '../roles/roles-map.service';
import { IOrgLevelDto, OrgLevelFlat } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupMapService, EmployeeDefinitionsMapService } from '../../../organization/services/index';
import { OrgLevelMapService } from '../../../organization/services/org-level/org-level-map.service';

import { OrgLevelRecordWrapper, IUserProfileDTO, UserProfileModel, UserRoleRelationModel, IUserRolerelationDTO, IUserFieldValidationResult, UserValidationResult } from '../../models/index';

@Injectable()
export class UserProfileMapService {
    constructor(
        private lookupMapService: LookupMapService,
        private rolesMap: RolesMapService,
        private orgLvlMap: OrgLevelMapService,
        private employeeDefinitionsMapService: EmployeeDefinitionsMapService) { }

    public wrapOrgLevelFlatList(flatList: OrgLevelFlat[]): OrgLevelRecordWrapper[] {
        let wrappers: OrgLevelRecordWrapper[] = [];
        _.each(flatList, (flatRecord: OrgLevelFlat) => {
            let wrapper: OrgLevelRecordWrapper = this.wrapOrgLevelFlat(flatRecord);
            wrappers.push(wrapper);
        });
        return wrappers;
    }

    public wrapOrgLevelFlat(flatRecord: OrgLevelFlat): OrgLevelRecordWrapper {
        let wrapper: OrgLevelRecordWrapper = new OrgLevelRecordWrapper();
        wrapper.orgLevel = flatRecord;
        wrapper.selectable = true;
        wrapper.isSelected = false;
        return wrapper;
    }

    public mapUserProfiles(dtos: IUserProfileDTO[]): UserProfileModel[] {
        let models: UserProfileModel[] = [];
        _.each(dtos, (profile: IUserProfileDTO) => {
            models.push(this.mapUserProfile(profile));
        });
        return models;
    }

    public mapUserProfile(dto: IUserProfileDTO): UserProfileModel {
        let userProfile: UserProfileModel = new UserProfileModel();
        userProfile.id = dto.id;
        userProfile.name = dto.name;
        /*
        userProfile.firstName = dto.firstName;
        userProfile.middleName = dto.middleName;
        userProfile.lastName = dto.lastName;
        */
        userProfile.workPhone = dto.workPhone;
        userProfile.ext = dto.ext;
        userProfile.fax = dto.fax;
        userProfile.login = dto.login;
        userProfile.email = dto.email;
        userProfile.password = dto.password;
        userProfile.comments = dto.comments;
        userProfile.workAddress = dto.workAddress;
        userProfile.isActive = !dto.isLocked;
        userProfile.isEditable = dto.isEditable;
        userProfile.orgLevelDescription = dto.orgLevelDescription;
        userProfile.orgLevelId = dto.orgLevelId;
        userProfile.resetPasswordOnNextLogin = dto.resetPasswordOnNextLogin;
        userProfile.employee = dto.employee ? this.employeeDefinitionsMapService.mapToEmployeeDefinition(dto.employee) : null;
        return userProfile;
    }

    public mapUserProfileDTO(model: UserProfileModel): IUserProfileDTO {
        return {
            id: model.id,
            name: model.name,
            /*
            firstName: model.firstName,
            middleName: model.middleName,
            lastName: model.lastName,
            */
            login: model.login,
            workPhone: model.workPhone ? model.workPhone : null,
            workAddress: model.workAddress,
            fax: model.fax ? model.fax : null,
            ext: model.ext ? model.ext : null,
            comments: model.comments,
            email: model.email,
            password: model.password,
            resetPasswordOnNextLogin: model.resetPasswordOnNextLogin,
            isLocked: !model.isActive,
            isEditable: true,
            orgLevelDescription: model.orgLevelDescription,
            orgLevelId:model.orgLevelId,
            employee: model.employee ? this.employeeDefinitionsMapService.mapToEmployeeDefinitionDto(model.employee) : null
        };
    }

    public cloneUserProfile(model: UserProfileModel): UserProfileModel {
        return this.mapUserProfile(this.mapUserProfileDTO(model));
    }

    public cloneUserRoles(roles: UserRoleRelationModel[]): UserRoleRelationModel[] {
        return this.mapUserRoleRelations(this.mapUserRoleRelationsDTO(roles));
    }

    public mapUserRoleRelations(dtos: IUserRolerelationDTO[]): UserRoleRelationModel[] {
        let relations: UserRoleRelationModel[] = [];
        _.each(dtos, (dto: IUserRolerelationDTO) => {
            let relation: UserRoleRelationModel = this.mapUserRoleRelation(dto);
            relations.push(relation);
        });
        return relations;
    }

    public mapUserRoleRelationsDTO(models: UserRoleRelationModel[]): IUserRolerelationDTO[] {
        let relations: IUserRolerelationDTO[] = [];
        _.each(models, (model: UserRoleRelationModel) => {
            let relation: IUserRolerelationDTO = this.mapUserRoleRelationDTO(model);
            relations.push(relation);
        });
        return relations;
    }

    public mapUserRoleRelation(dto: IUserRolerelationDTO): UserRoleRelationModel {

        let relation: UserRoleRelationModel = new UserRoleRelationModel();
        relation.roleDefinition = this.rolesMap.mapRoleDefitinion(dto.role);
        let orgLevels: OrgLevel[] = [];
        _.each(dto.orgLevels, (orgLvlDto: IOrgLevelDto) => {
            let orgLvl: OrgLevel = this.orgLvlMap.mapToOrgLevel(orgLvlDto);
            orgLevels.push(orgLvl);
        });
        relation.orgLevels = orgLevels;
        return relation;
    }

    public mapUserRoleRelationDTO(model: UserRoleRelationModel): IUserRolerelationDTO {
        return {
            role: this.rolesMap.mapRoleDefitinionDto(model.roleDefinition),
            orgLevels: this.orgLvlMap.mapOrgLevelsDTO(model.orgLevels)
        };
    }

    public mapUserValidation(response: IUserFieldValidationResult): UserValidationResult {
        let result: UserValidationResult = new UserValidationResult();
        result.errorMessage = response.errorMessage;
        result.isValid = response.isValid;
        return result;
    }
}
