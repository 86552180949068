import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { GenericRow, GenericFieldDefinition } from '../../../models/generic-list/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-generic-cell',
  templateUrl: 'generic-cell.component.html',
  styleUrls: ['generic-cell.component.scss']
})
export class GenericCellComponent implements OnChanges {

  @Input()
  public genRow: GenericRow;

  @Input()
  public fieldDef: GenericFieldDefinition;

  @Input()
  public disableEmployeeLink: boolean = false;

  @Output()
  public employeeLinkClick: EventEmitter<number>;

  @Output()
  public selectionChanged: EventEmitter<GenericRow>;

  public appConfig: IApplicationConfig;

  constructor() {
    this.appConfig = appConfig;
    this.employeeLinkClick = new EventEmitter<number>();
    this.selectionChanged = new EventEmitter<GenericRow>();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['fieldDef']) {
      //TODO remove temporary for debug hyperlink
    }
  }

  public onEmployeeLinkClick(empId: number): void {
    this.employeeLinkClick.emit(empId);
  }

  public selectionChange(row: GenericRow): void {
    this.selectionChanged.emit(row);
  }

  public getClassName(): string {
    let cssClasses: string[] = [];
    if (this.fieldDef.align) {
      switch(this.fieldDef.align) {
        case 'left': cssClasses.push('cell-align-left'); break;
        case 'center': cssClasses.push('cell-align-center'); break;
        case 'right': cssClasses.push('cell-align-right'); break;
        default: break;
      }
    }
    return cssClasses.join(' ');
  }
}
