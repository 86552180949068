<div class="wizard-container">
  <form class="form-horizontal" #payrollForm="ngForm">
    <div class="col-lg-4">
      <div class="col-md-10 col-md-offset-1">
        <slx-input-decorator>
          <input type="text" slx-input class="form-control"
                 [(ngModel)]="addEmployeeModel.lastName"
                 (blur)="validateNames()"
                 maxlength="50"
                 name="lastName"
                 placeholder="Last Name"
                 required>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1">
        <slx-input-decorator>
          <input type="text" slx-input
                maxlength="50"
                 name="firstName"
                 placeholder="First Name"
                 [(ngModel)]="addEmployeeModel.firstName"
                 (blur)="validateNames()"
                 required>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.middleName.isVisible">
        <slx-input-decorator>
          <input type="text" slx-input
                 [inputPolicy]="addEmployeeRestrictions?.middleName.Policy"
                 [(ngModel)]="addEmployeeModel.middleName"
                 (blur)="validateNames()"
                 maxlength="50"
                 name="middleName"
                 placeholder="Middle Name">
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.suffix.isVisible">
        <slx-input-decorator>
          <input type="text" slx-input
                 [inputPolicy]="addEmployeeRestrictions?.suffix.Policy"
                 [(ngModel)]="addEmployeeModel.suffix"
                 (blur)="validateNames()"
                 maxlength="50"
                 name="suffix"
                 placeholder="Suffix">
        </slx-input-decorator>
      </div>
      <div *ngIf="!namesValidation.isValid && namesValidation.isReadyForValidation" class="col-md-10 col-md-offset-1">
        <div  class="alert alert-danger" role="alert">{{namesValidation.errorMessage}}</div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="col-md-10 col-md-offset-1" *ngIf="addEmployeeRestrictions?.employeeType.isVisible">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [lookup]="{lookupType: 'empType'}"
                              [inputPolicy]="addEmployeeRestrictions?.employeeType.Policy"
                              [(ngModel)]="addEmployeeModel.employeeType"
                              name="empType"
                              required
                              emptyOption
                              placeholder="Emp Type">
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [options]="organizationLookup"
                              [(ngModel)]="addEmployeeModel.organization"
                              (ngModelChange)="onOrganizationChanged($event)"
                              name="organization"
                              placeholder="Organization"
                              required>
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [options]="departmentLookup"
                              [disabled]="!addEmployeeModel.organization"
                              [(ngModel)]="addEmployeeModel.department"
                              (ngModelChange)="onDepartmentChanged($event)"
                              placeholder="Department"
                              name="department"
                              required>
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
      <div class="col-md-10 col-md-offset-1">
        <slx-input-decorator>
          <slx-dropdown-input slx-input
                              [options]="positionLookup"
                              [disabled]="!addEmployeeModel.department"
                              [readonly]="!canSelectPosition"
                              [(ngModel)]="addEmployeeModel.position"
                              placeholder="Position"
                              name="position"
                              required>
          </slx-dropdown-input>
          <span errorMessage for="required"></span>
        </slx-input-decorator>
      </div>
    </div>
  </form>
  <div class="wizard-footer">
    <div class="wizard-buttons">
      <button type="button" slxBackLink class="theme-button-cancel">Cancel</button>
      <button class="theme-iconed-accent-button next-step" (click)="nextStep()" [disabled]="(!payrollForm.valid || !this.namesValidation.isValid) && !ignoreValidation">
        Next<i class="far fa-arrow-alt-circle-right"  aria-hidden="true"></i>
       </button>
    </div>
  </div>
  </div>
    