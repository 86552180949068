import { Component, Provider } from '@angular/core';
import { IDialog, DialogOptions, DialogOptions2, DialogModeSize } from '../../../../../../app/common/models/index';
import { ModalService } from '../../../../../../app/common/services/modal/modal.service';
import { BenefitDetailsLineStandart, BenefitDetailsLine, BenefitPayrollDeductionDates } from '../../../../../app-modules/benefits/models';
import * as _ from 'lodash';
import { appConfig, IApplicationConfig } from '../../../../../../app/app.config';
import * as moment from 'moment';

@Component({
  selector: 'slx-benefit-details-extend-dialog',
  templateUrl: './benefit-details-extend-dialog.component.html',
  styleUrls: ['./benefit-details-extend-dialog.component.scss']
})
export class BenefitDetailsExtendDialogComponent implements IDialog {

  public dialogResult: boolean;
  public benefitDetailsLineStartDate: Date;
  public benefitDetailsLineEndDate: Date;
  public payrollDedEndDate: Date;
  public isDedAfterBeneEndDt: boolean;
  public minValidDate: Date;
  public maxValidDate: Date = new Date(appConfig.maxCorrectDate);
  public maxDate: Date = new Date(appConfig.maxCorrectDate);
  public minDate: Date = new Date(appConfig.minCorrectDate);
  public maxDedDate: Date;
  public minDedDate: Date;
  public isAfterDeductionDate: boolean;
  public isBeneAfterDedMasterDate: boolean;
  public appConfig: IApplicationConfig = appConfig;
  public canNotExtend: boolean;
  public benefitdeduction2: boolean;
  public beneDedDates: BenefitPayrollDeductionDates = new BenefitPayrollDeductionDates();
  public static openDialog(benefitDetailsLine: BenefitDetailsLineStandart, benefitdeduction2: boolean, modalService: ModalService, nextLineDetails: BenefitDetailsLine, callback?: (result: boolean) => void): BenefitDetailsExtendDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.dialog;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: BenefitDetailsLineStandart,
        useValue: benefitDetailsLine
      },
      {
        provide: BenefitDetailsLine,
        useValue: nextLineDetails
      }
    ];

    let dialog: BenefitDetailsExtendDialogComponent = modalService.globalAnchor
      .openDialog2(BenefitDetailsExtendDialogComponent, 'Extend Benefit Plan', dialogOptions, resolvedProviders, (result: boolean) => {
        callback(result);
      });
    dialog.benefitdeduction2 = benefitdeduction2;
    return dialog;
  }

  constructor(private dialogOptions: DialogOptions, private modalService: ModalService,
    private benefitDetailsLine: BenefitDetailsLineStandart, private nextLineDetails: BenefitDetailsLine) {
    this.modalService = modalService;
    this.benefitDetailsLineStartDate = benefitDetailsLine ? benefitDetailsLine.line.startDate : null;
    this.benefitDetailsLineEndDate = benefitDetailsLine ? benefitDetailsLine.line.endDate : null;
    this.minValidDate = _.clone(this.benefitDetailsLineEndDate);
    if (this.nextLineDetails) {
      this.maxValidDate = moment(this.nextLineDetails.startDate).add('day', -1).endOf('day').toDate();
    }

    this.canNotExtend = moment(this.benefitDetailsLineStartDate).isSameOrAfter(this.maxValidDate) || moment(this.benefitDetailsLineEndDate).endOf('day').isSameOrAfter(this.maxValidDate);
  }
  public onChangeEndDate(): void {
    this.isDedAfterBeneEndDt = !!_.gt(this.beneDedDates.payrollDeductionEndDate, this.beneDedDates.benefitDetailsLineEndDate);
    this.isBeneAfterDedMasterDate = !!_.gt(this.beneDedDates.benefitDetailsLineEndDate, this.maxDedDate);
  }
  public onChangeDedEndDate(): void {
    this.isDedAfterBeneEndDt = !!_.gt(this.beneDedDates.payrollDeductionEndDate, this.beneDedDates.benefitDetailsLineEndDate);
    this.isAfterDeductionDate = !!_.gt(this.beneDedDates.payrollDeductionEndDate, this.maxDedDate);
  }

  public save(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public cancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

}
