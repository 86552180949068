/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-sections-dependents.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../employee-sections-dependents-grid/employee-sections-dependents-grid.component.ngfactory";
import * as i3 from "../employee-sections-dependents-grid/employee-sections-dependents-grid.component";
import * as i4 from "../../../../../common/services/modal/modal.service";
import * as i5 from "../../../services/employee-sections-benefits-management/employee-sections-benefits-management.service";
import * as i6 from "@angular/common";
import * as i7 from "./employee-sections-dependents.component";
import * as i8 from "../../employee-subsection-decorator/employee-subsection-decorator.component";
var styles_EmployeeSectionsDependentsComponent = [i0.styles];
var RenderType_EmployeeSectionsDependentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeSectionsDependentsComponent, data: {} });
export { RenderType_EmployeeSectionsDependentsComponent as RenderType_EmployeeSectionsDependentsComponent };
export function View_EmployeeSectionsDependentsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dependents"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-employee-sections-dependents-grid", [], null, null, null, i2.View_EmployeeSectionsDependentsGridComponent_0, i2.RenderType_EmployeeSectionsDependentsGridComponent)), i1.ɵdid(2, 245760, null, 0, i3.EmployeeSectionsDependentsGridComponent, [i4.ModalService, i5.EmployeeSectionsBenefitsManagementService, i1.ChangeDetectorRef, i6.DOCUMENT], { isEditable: [0, "isEditable"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isEditable; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EmployeeSectionsDependentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employee-sections-dependents", [], null, null, null, View_EmployeeSectionsDependentsComponent_0, RenderType_EmployeeSectionsDependentsComponent)), i1.ɵdid(1, 245760, null, 0, i7.EmployeeSectionsDependentsComponent, [i8.EmployeeSubSectionsDecoratorComponent, i1.NgZone, i5.EmployeeSectionsBenefitsManagementService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmployeeSectionsDependentsComponentNgFactory = i1.ɵccf("slx-employee-sections-dependents", i7.EmployeeSectionsDependentsComponent, View_EmployeeSectionsDependentsComponent_Host_0, { employeeSubsectionDependents: "employeeSubsectionDependents", employeeId: "employeeId" }, {}, []);
export { EmployeeSectionsDependentsComponentNgFactory as EmployeeSectionsDependentsComponentNgFactory };
