<input type="text"
  name="zipCode"
  [textMask]="zipMaskConf"
  [(ngModel)]="innerValue"
  (ngModelChange)="valueChanged($event)"
  [attr.class]="className"
  [readonly]="!inEdit ? true : null"
  [placeholder]="placeholder"
>

