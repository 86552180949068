import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { EmployeeDefinitionsApiService, LookupService, EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { LookupType } from '../../../../../../organization/models/index';
import { EmployeeToRehire, EmployeeRehire } from '../../../../models/index';
import { appConfig } from '../../../../../../app.config';
import { AppSettingsManageService } from '../../../../../../app-settings/services/index';
import { NotificationsApiService } from '../../../../../../portal/services';
var EmployeeRehireComponent = /** @class */ (function () {
    function EmployeeRehireComponent(lookupService, appSettingsManageService, employeeDefinitionsApiService, notificationApiService, employeeActivitiesValidatorAdapter) {
        this.lookupService = lookupService;
        this.appSettingsManageService = appSettingsManageService;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.notificationApiService = notificationApiService;
        this.isV6ReHireEnhacementEnabled = false;
        this.bswiftEnabled = false;
        this.optInTemplateText = '';
        this.isMessageCenterEnabled = false;
        this.isNgpUser = false;
        this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
    }
    Object.defineProperty(EmployeeRehireComponent.prototype, "emp", {
        set: function (emp) {
            this.employee = emp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRehireComponent.prototype, "departmentEditable", {
        get: function () {
            return !!this.requestToRehire.organization;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRehireComponent.prototype, "positionEditable", {
        get: function () {
            return !!this.requestToRehire.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRehireComponent.prototype, "payPolicyEditable", {
        get: function () {
            return !!this.requestToRehire.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRehireComponent.prototype, "shiftEditable", {
        get: function () {
            return !!this.requestToRehire.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRehireComponent.prototype, "unitEditable", {
        get: function () {
            return !!this.requestToRehire.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeRehireComponent.prototype, "payrollNumberValidation", {
        get: function () {
            return EmployeeValidatorAdapter.payrollNumberValidation;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeRehireComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.requestToRehire = new EmployeeRehire();
        this.requestToRehire.employeeId = this.employee.employeeId;
        this.requestToRehire.oldOrganizationId = this.employee.organizationId;
        this.requestToRehire.oldEmployeeType = this.employee.employeeType;
        this.requestToRehire.date = moment().startOf('day').toDate();
        this.requestToRehire.payrollNumber = this.employee.payrollNumber;
        this.getRateLimit = this.employee.payRateLimit;
        this.onRehireDateUpdate(this.requestToRehire.date);
        this.populateData();
        this.getSettings();
        this.checkMessageCenterAccess();
        this.getSMSTemplate();
        this.getNextPayrollNumber();
    };
    EmployeeRehireComponent.prototype.onRehireDateUpdate = function (date) {
        if (date) {
            this.requestToRehire.benefitClassEffectiveDate = date;
        }
    };
    EmployeeRehireComponent.prototype.populateData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, departmentLookup, benefitClassesLookup, employeeBenefitClass, department, benefitClassDefinition;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.lookupService.getLookup({ lookupType: LookupType.department, employeeId: this.employee.employeeId }),
                            this.lookupService.getLookup({ lookupType: LookupType.benefitClasses, employeeId: this.employee.employeeId }),
                            this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employee.employeeId)
                        ])];
                    case 1:
                        _a = _b.sent(), departmentLookup = _a[0], benefitClassesLookup = _a[1], employeeBenefitClass = _a[2];
                        department = _.find(departmentLookup.items, function (d) { return d.id === _this.employee.departmentId; });
                        this.currentOrgLevelId = department.orgLevelId;
                        this.benefitClassesLookup = benefitClassesLookup;
                        this.employeeBenefitClass = employeeBenefitClass;
                        if (employeeBenefitClass && this.benefitClassesLookup) {
                            benefitClassDefinition = _.find(this.benefitClassesLookup.items, function (benefitClass) { return benefitClass.id === employeeBenefitClass.benefitClass.id; });
                            if (benefitClassDefinition) {
                                this.requestToRehire.benefitClass = benefitClassDefinition;
                            }
                        }
                        this.requestToRehire.mobilePhone = this.employee.mobilePhone;
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeRehireComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        _a.config = _b.sent();
                        this.bswiftEnabled = this.config.bswiftIntegrationEnabled;
                        this.isV6ReHireEnhacementEnabled = this.config.isV6ReHireEnhacementEnabled;
                        this.checkForNGPFlag();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeRehireComponent.prototype.checkMessageCenterAccess = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var orgLevelId, access;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        orgLevelId = this.employee.orgLevelId;
                        return [4 /*yield*/, this.notificationApiService.getIconAccessCheck(orgLevelId)];
                    case 1:
                        access = _a.sent();
                        this.isMessageCenterEnabled = access.isMessageCenterEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeRehireComponent.prototype.getSMSTemplate = function () {
        var _this = this;
        var orgLevelId = this.employee.orgLevelId;
        this.employeeDefinitionsApiService.getSmsTemplate(orgLevelId)
            .then(function (template) {
            _this.optInTemplateText = template;
        }).catch(function (reason) {
        });
    };
    EmployeeRehireComponent.prototype.onOrganizationChanged = function (org) {
        var _this = this;
        this.clearOrgDependences();
        if (!this.requestToRehire.organization) {
            return;
        }
        this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.requestToRehire.organization.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (departmentLookup) {
            _this.departmentLookup = departmentLookup;
            _this.getNextPayrollNumber();
        });
        this.checkForNGPFlag();
    };
    EmployeeRehireComponent.prototype.clearOrgDependences = function () {
        this.requestToRehire.department = null;
        this.clearDepDependences();
    };
    EmployeeRehireComponent.prototype.clearDepDependences = function () {
        this.requestToRehire.position = null;
        this.requestToRehire.payPolicy = null;
        this.requestToRehire.shiftDiffPolicy = null;
        this.requestToRehire.unit = null;
        this.requestToRehire.benefitClass = null;
    };
    EmployeeRehireComponent.prototype.onDepartmentChanged = function (dep) {
        var _this = this;
        this.clearDepDependences();
        if (!this.requestToRehire.department) {
            return;
        }
        this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (positionLookup) {
            _this.positionLookup = positionLookup;
        });
        this.lookupService.getLookup({ lookupType: LookupType.payPolicy, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (payPolicyLookup) {
            _this.payPolicyLookup = payPolicyLookup;
        });
        this.lookupService.getLookup({ lookupType: LookupType.shiftDiffPolicy, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (shiftDiffPolicyLookup) {
            _this.shiftDiffPolicyLookup = shiftDiffPolicyLookup;
        });
        this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (unitLookup) {
            _this.unitLookup = unitLookup;
        });
        if (this.bswiftEnabled) {
            this.lookupService.getLookup({ lookupType: LookupType.benefitClasses, orgLevelId: this.requestToRehire.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
                .then(function (benefitClassLookup) {
                _this.benefitClassesLookup = benefitClassLookup;
                _this.updateBenefitClass();
            });
        }
    };
    EmployeeRehireComponent.prototype.updateBenefitClass = function () {
        var _this = this;
        if (this.employeeBenefitClass && this.benefitClassesLookup) {
            var benefitClassDefinition = _.find(this.benefitClassesLookup.items, function (benefitClass) { return benefitClass.id === _this.employeeBenefitClass.benefitClass.id; });
            if (benefitClassDefinition) {
                this.requestToRehire.benefitClass = benefitClassDefinition;
            }
        }
    };
    EmployeeRehireComponent.prototype.getNextPayrollNumber = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var nextPayrollNumber;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.requestToRehire.organization) {
                            return [2 /*return*/];
                        }
                        if (!!this.isNgpUser) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getNextPayrollNumber(this.requestToRehire.organization.orgLevelId, this.requestToRehire.employeeId)];
                    case 1:
                        nextPayrollNumber = _a.sent();
                        this.requestToRehire.payrollNumber = nextPayrollNumber;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeRehireComponent.prototype.checkForNGPFlag = function () {
        this.isNgpUser = this.config.IsNextgenPayrollEnabled || false;
    };
    return EmployeeRehireComponent;
}());
export { EmployeeRehireComponent };
