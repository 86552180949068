<div class="slx-lr-toolbar">
  <div class="slx-lr-toolbar__dates">
    <slx-daterange
      class="slx-width-260"
      name="startEndDate"
      [startDate]="dateRange.startDate"
      [endDate]="dateRange.endDate"
      (rangeDateChanged)="onDatesRangeChanged($event)"
      slxControlActiveState
    ></slx-daterange>
  </div>
  <div class="slx-lr-toolbar__filters actionsAlign">
    <slx-action-list>
      <slx-action-button [iconName]="'fas fa-cog'" [popperContent]="popperContent" [popperPosition]="'bottom-end'"
        >Actions</slx-action-button
      >
      <popper-content #popperContent>
        <slx-action-list-item (onClick)="onClickExport(false)">Export to Excel</slx-action-list-item>
        <slx-action-list-item (onClick)="onClickExport(true)">Export to PDF</slx-action-list-item>
      </popper-content>
    </slx-action-list>
    <slx-multiselect
      class="slx-lr-toolbar__filter"
      [options]="filtersList"
      [(ngModel)]="appliedFilters"
      (ngModelChange)="onFiltersSetChanged($event)"
      placeholder="Status"
      name="statusFilter"
    ></slx-multiselect>
  </div>
</div>
