<div class="circle"
  *ngIf="imgPhoto && (!messageThread.isOptInRequest  && messageThread.userId !== 0) && messageThread.userName === null">
  <a>
    <img class="img img-circle" src="{{imgPhoto}}" alt="Employee Photo" />
  </a>
  <ng-container *ngTemplateOutlet="statusIcons"></ng-container>
</div>

<div class="circle"
  *ngIf="imgPhoto && (!messageThread.isOptInRequest  && messageThread.userId === 0) && messageThread.userName === null">
  <a>
    <img class="img img-circle" src="{{imgPhoto}}" alt="Employee Photo" />
  </a>
  <ng-container *ngTemplateOutlet="statusIcons"></ng-container>
</div>

<div *ngIf=" messageThread.userId === loggedInUserId && !imgPhoto && messageThread.userName === null" class="circle"
  [ngClass]="getEmployeeDisableStatus(messageThread)">
  <span class="text" [ngClass]="{'error-circle-left':messageThread?.errorCode !== null && messageThread?.errorCode !== '0'}">{{ employeeName?.split('')[0] }}</span>
  <ng-container *ngTemplateOutlet="statusIcons"></ng-container>
</div>

<div *ngIf="messageThread.userId !== loggedInUserId && messageThread.userId !==0 && messageThread.userName === null && !imgPhoto"
  class="circle" [ngClass]="getEmployeeDisableStatus(messageThread)">
  <span class="text" [ngClass]="{'error-circle-left':messageThread?.errorCode !== null && messageThread?.errorCode !== '0'}">{{ employeeName?.split('')[0] }}</span>
  <ng-container *ngTemplateOutlet="statusIcons"></ng-container>
</div>

<div *ngIf="messageThread.userId !== loggedInUserId && messageThread.userId !==0 && messageThread.userName !== null" class="circle"
  [ngClass]="getEmployeeDisableStatus(messageThread)">
  <a *ngIf="usersPicture[messageThread.userId]">
    <img class="img img-circle" src="{{usersPicture[messageThread.userId]}}" alt="Employee Photo" />
  </a>
  <span class="text" [ngClass]="{'error-circle-left':messageThread?.errorCode !== null && messageThread?.errorCode !== '0'}" *ngIf="!usersPicture[messageThread.userId]">{{ messageThread.userName?.split('')[0] }}</span>
</div>
<div
  *ngIf="messageThread.userId !== loggedInUserId && messageThread.userId === 0 && !messageThread.isOptInRequest && messageThread.userName !== null"
  class="circle" [ngClass]="getEmployeeDisableStatus(messageThread)">
  <span class="text" [ngClass]="{'error-circle-left':messageThread?.errorCode !== null && messageThread?.errorCode !== '0'}">{{ messageThread?.userName?.split('')[0] }}</span>
</div>
<div
  *ngIf="messageThread.userId !== loggedInUserId && messageThread.userId === 0 && messageThread.isOptInRequest === null && messageThread.userName === null && messageThread.replied && !imgPhoto"
  class="circle" [ngClass]="getEmployeeDisableStatus(messageThread)">
  <span class="text" [ngClass]="{'error-circle-left':messageThread?.errorCode !== null && messageThread?.errorCode !== '0'}">{{ employeeName?.split('')[0] }}</span>
  <ng-container *ngTemplateOutlet="statusIcons"></ng-container>
</div>

<ng-template #statusIcons>
  <span class="img-terminated-icon" *ngIf="employeeStatus === 'Terminated'"><i class="fas fa-times" aria-hidden="true"></i></span>
  <span class="img-terminated-icon" *ngIf="employeeStatus === 'Transferred'"><i class="fa fa-exchange" aria-hidden="true"></i></span>
  <span class="sms-Opt-Out" *ngIf="employeeOptIn === 0 && !employeeLeaveStatus && employeeStatus === 'Active'"><em class="fas fa-mobile-alt mobile-alt algin"></em></span>
  <span class="sms-Opt-In" *ngIf="employeeOptIn  === 1  && employeeStatus === 'Active' && !employeeLeaveStatus"><em class="fas fa-mobile-alt mobile-alt algin"></em></span>
  <span class="img-loa-icon" *ngIf="employeeStatus === 'Active' && employeeOptIn === 1 && employeeLeaveStatus"><i class="far fa-calendar-minus" aria-hidden="true"></i></span>
  <span class="sms-Not-Responeded" *ngIf="employeeOptIn  === 2 && !employeeLeaveStatus && employeeStatus === 'Active'"><em class="fas fa-mobile-alt mobile-alt algin"></em></span>
</ng-template>