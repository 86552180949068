import * as tslib_1 from "tslib";
import { OnInit, Provider } from '@angular/core';
import { ModalService, DialogOptions } from '../../../../../../common/index';
import { Assert } from '../../../../../../framework/index';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsAccrualsApiService } from '../../../../services/index';
import { ResetBalance, AccrualTypesDialogOptions, } from '../../../../models/index';
import * as _ from 'lodash';
import { appConfig } from '../../../../../../app.config';
import { AppSettingsManageService } from './../../../../../../app-settings/services/index';
var ResetBalanceDialogComponent = /** @class */ (function () {
    function ResetBalanceDialogComponent(employeeSectionsAccrualsApiService, options, modalService, appSettingsManageService, employeeAccruals) {
        this.employeeSectionsAccrualsApiService = employeeSectionsAccrualsApiService;
        this.appSettingsManageService = appSettingsManageService;
        this.accrualTypesList = [];
        this.chkEnable = false;
        this.accrualTypeInfo = [];
        this.decimalLimit = 2;
        this.format = 'n2';
        Assert.isNotNull(options, 'options');
        this.options = options;
        Assert.isNotNull(modalService, 'modalService');
        this.modalService = modalService;
        this.selectedDate = new Date();
        this.dialogResult = false;
        this.accrualTypesList = employeeAccruals.accrual;
        this.employeeId = employeeAccruals.empId;
        this.fromDate = employeeAccruals.fromDate;
        this.endDate = employeeAccruals.toDate;
        this.empHireDate = employeeAccruals.hireDate;
        this.appConfig = appConfig;
    }
    Object.defineProperty(ResetBalanceDialogComponent.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ResetBalanceDialogComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    ResetBalanceDialogComponent.prototype.startProgress = function () {
        this.isLoading = true;
    };
    ResetBalanceDialogComponent.prototype.stopProgress = function () {
        this.isLoading = false;
    };
    ResetBalanceDialogComponent.prototype.ngOnInit = function () {
        this.m_initialized = true;
        this.getSettings();
    };
    ResetBalanceDialogComponent.openDialog = function (title, modalService, employeeAccruals, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 480;
        dialogOptions.width = 350;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: AccrualTypesDialogOptions,
                useValue: employeeAccruals
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(ResetBalanceDialogComponent, title, dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        _.forEach(employeeAccruals.accrual, function (value) {
            dialog.lastTrasactionDate = value.lastManualTransactionDate;
        });
        return dialog;
    };
    ResetBalanceDialogComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        if (config.EnableRate4DecimalPlaces) {
                            this.decimalLimit = 4;
                            this.format = 'n4';
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ResetBalanceDialogComponent.prototype.minDateLimit = function () {
        if (!_.isNil(this.lastTrasactionDate)) {
            return this.lastTrasactionDate;
        }
        return this.empHireDate;
    };
    ResetBalanceDialogComponent.prototype.onOk = function () {
        var _this = this;
        this.isLoading = true;
        var restBalances = new ResetBalance();
        restBalances.employeeId = this.employeeId;
        restBalances.transactionDate = this.selectedDate;
        restBalances.notes = this.notes;
        restBalances.fromDateForFetchResult = this.fromDate;
        restBalances.endDateForFetchResult = this.endDate;
        restBalances.accrualTypeInfo = _.filter(this.accrualTypesList, ['chkEnable', true]);
        this.employeeSectionsAccrualsApiService.postResetBalance(this.employeeId, restBalances)
            .then(function (status) {
            _this.dialogResult = true;
            _this.isLoading = false;
            _this.modalService.closeWindow(_this.options.windowUniqueId);
        }).catch(function (reason) {
            _this.isLoading = false;
        });
    };
    ResetBalanceDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ResetBalanceDialogComponent.prototype.onChangeEnableBalance = function (accType, isChecked) {
        accType.chkEnable = isChecked;
        this.saveButtonToggle();
    };
    ResetBalanceDialogComponent.prototype.saveButtonToggle = function () {
        var saveButtonEnable = false;
        _.forEach(this.accrualTypesList, function (item) {
            if (item.chkEnable) {
                saveButtonEnable = true;
            }
        });
        if (saveButtonEnable && this.selectedDate) {
            this.chkEnable = true;
        }
        else {
            this.chkEnable = false;
        }
    };
    ResetBalanceDialogComponent.prototype.getAccrualTypes = function (tDate) {
        this.startProgress();
        this.updateLookups(tDate);
        this.saveButtonToggle();
    };
    ResetBalanceDialogComponent.prototype.updateLookups = function (transactionDate) {
        var _this = this;
        if (this.employeeId) {
            this.employeeSectionsAccrualsApiService.getAccrualTypes(this.employeeId, transactionDate)
                .then(function (accrualtype) {
                _this.accrualTypesList = accrualtype;
                _this.chkEnable = false;
                _this.stopProgress();
            });
        }
    };
    return ResetBalanceDialogComponent;
}());
export { ResetBalanceDialogComponent };
