export * from './lm-console.component';
export * from './lm-console-overview/lm-console-overview.component';
export * from './lm-console-table/lm-console-table.component';
export * from './lm-console-toolbar/lm-console-toolbar.component';

import { LMConsoleComponent } from './lm-console.component';
import { LMConsoleOverviewComponent } from './lm-console-overview/lm-console-overview.component';
import { LMConsoleTableComponent } from './lm-console-table/lm-console-table.component';
import { LMConsoleToolbarComponent } from './lm-console-toolbar/lm-console-toolbar.component';


export const lmConsoleComponents: any[] = [
  LMConsoleComponent,
  LMConsoleOverviewComponent,
  LMConsoleTableComponent,
  LMConsoleToolbarComponent
];
