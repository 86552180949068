<slx-spinner [show]="state.isLoading">
  <form #form="ngForm" class="form">
    <header class="slx-content-toolbar-indents flex-row space-btw">
      <p class="reset-margin row-item">Badge ID: <strong>{{request.agencyEmployee.badgeId}}</strong></p>
      <p class="reset-margin row-item">Prefix: <strong>{{request.agencyEmployee.prefix}}</strong></p>
      <p class="reset-margin row-item align-right">
        <button *ngIf="state.isAddingMode"
          type="button"
          class="theme-button-apply"
          (click)="onAddClick()"
          [disabled]="form.invalid || (isScheduled && !validationResult?.isValid)"
        >Add</button>
        <button *ngIf="!state.isAddingMode" class="theme-iconed-accent-button" (click)="onAddNewClick()">
          <span class="add-new-button">Add New</span>
        </button>
      </p>
    </header>
    <div class="slx-main-content-indents flex-col">
      <div class="flex-row wide">
        <ng-container [slxAccessibleSwitch]="{provider: 'Menu', permission: 'manage_agency'}">
          <ng-template slxAccessibleCase>
            <slx-input-decorator class="row-item">
              <slx-dropdown-input #agencies="ngModel" slx-input [required]="true" [readonly]="!state.isAddingMode" placeholder="Agency" [options]="agencyList" 
                name="agencies" [(ngModel)]="selectedAgency"  (ngModelChange)="agencySelected($event)" ></slx-dropdown-input>
              <div errorMessage for="required"></div>
            </slx-input-decorator>
          </ng-template>
          <ng-template slxNonAccessibleCase>
            <slx-input-decorator class="row-item">
              <slx-dropdown-input slx-input [required]="true" [readonly]="!state.isAddingMode" placeholder="Agency" [options]="agencyList"
                name="agencies" [(ngModel)]="selectedAgency" (ngModelChange)="agencySelected($event)"></slx-dropdown-input>
              <div errorMessage for="required"></div>
            </slx-input-decorator>
          </ng-template>
        </ng-container>
        <slx-input-decorator class="row-item">
          <slx-autocomplete-input slx-input
            placeholder="Last Name"
            name="preSelectedEmployee"
            [lookup]="employeeLookup1"
            [required]="true"
            [readonly]="!state.isAddingMode || !selectedAgency"
            [createIfNotFound]="true"
            [(ngModel)]="preSelectedEmployee"
            (valueChanged)="changedEmployeeLastName($event)"
          ></slx-autocomplete-input>
          <div errorMessage for="required"></div>
        </slx-input-decorator>
        <slx-input-decorator class="row-item">
          <slx-autocomplete-input slx-input
            placeholder="First Name"
            name="selectedEmployee"
            [lookup]="employeeLookup2"
            [required]="true"
            [readonly]="!state.isAddingMode || !selectedAgency"
            [createIfNotFound]="true"
            [(ngModel)]="selectedEmployee"
            (valueChanged)="changedEmployeeFirstName($event)"
          ></slx-autocomplete-input>
          <div errorMessage for="required"></div>
        </slx-input-decorator>
      </div>
      <div class="flex-row wide" *ngIf="showOrgStructureSelectors">
        <slx-input-decorator class="row-item">
          <slx-dropdown-input slx-input [required]="true" [readonly]="!state.isAddingMode" autoSelectFirst="true" [lookup]="{lookupType: 'userAccesibleOrganizations', orgLevelId: organizationSelectorOrglevelId}"
            name="organization" placeholder="Organization" [(ngModel)]="request.organization" (ngModelChange)="onOrganizationChanged($event)"></slx-dropdown-input>
          <div errorMessage for="required"></div>
        </slx-input-decorator>
        <slx-input-decorator class="row-item">
          <slx-dropdown-input slx-input [required]="true" [readonly]="!state.isAddingMode" [lookup]="departmentLookupRequest" (lookupApplied)="departmentLookupApplied($event)"
            name="department" placeholder="Department" [(ngModel)]="request.department" (ngModelChange)="onDepartmentChanged($event)"></slx-dropdown-input>
          <div errorMessage for="required"></div>
        </slx-input-decorator>
        <slx-input-decorator *ngIf="request.department" class="row-item">
          <slx-dropdown-input slx-input [required]="true" [readonly]="!state.isAddingMode"
            [titleField]="'name'"
            [lookup]="{lookupType: 'position', orgLevelId: request.department?.orgLevelId}"
            emptyOption name="position" placeholder="Position" [(ngModel)]="request.position"
            (ngModelChange)="onPositionChanged($event)"
          ></slx-dropdown-input>
          <div errorMessage for="required"></div>
        </slx-input-decorator>
      </div>
      <div class="flex-row wide">
        <slx-input-decorator class="row-item">
          <slx-date-picker-ngx slx-input
            placeholder="Start Date"
            name="startDate"
            [readonly]="!state.isAddingMode || selectedEmployeeExists"
            [(ngModel)]="request.agencyEmployee.startDate"
            (ngModelChange)="onStartDateChanged()"
            [acceptNullDate]="false"
          ></slx-date-picker-ngx>
          <div errorMessage for="required"></div>
          <div errorMessage for="date"></div>
        </slx-input-decorator>
        <slx-input-decorator class="row-item">
          <slx-email-input slx-input slx-email name="email" [readonly]="!state.isAddingMode" placeholder="Email" [(ngModel)]="request.agencyEmployee.email"></slx-email-input>
          <div errorMessage for="required"></div>
          <div errorMessage for="email"></div>
        </slx-input-decorator>
        <slx-input-decorator class="row-item">
          <slx-phone-input slx-input slx-phone name="phone" [readonly]="!state.isAddingMode" placeholder="Mobile Phone" [(ngModel)]="request.agencyEmployee.phone"></slx-phone-input>
          <div errorMessage for="required"></div>
          <div errorMessage for="phone"></div>
        </slx-input-decorator>
      </div>
      <div class="flex-row wide">
        <slx-input-decorator class="row-item">
          <slx-date-picker-ngx slx-input
            placeholder="Work Date"
            name="workDate"
            [acceptNullDate]="false"
            [readonly]="!state.isAddingMode"
            [(ngModel)]="request.agencyEmployee.workDate"
            (ngModelChange)="onWorkdataChanged()"
            [required]="selectedEmployeeExists"
            [minDate]="minWorkDate"
          ></slx-date-picker-ngx>
          <div errorMessage for="required"></div>
          <div errorMessage for="date"></div>
          <div errorMessage for="minDate">Work Date cannot be less than selected position Start Date</div>
        </slx-input-decorator>
        <slx-input-decorator *ngIf="request.department" class="row-item">
          <slx-dropdown-input slx-input [readonly]="!state.isAddingMode" emptyOption [lookup]="{lookupType: 'shift', orgLevelId: request.department?.orgLevelId}"
            name="shift" placeholder="Shift" [(ngModel)]="request.shift" (ngModelChange)="onShiftChanged()" [required]="selectedEmployeeExists">
          </slx-dropdown-input>
          <div errorMessage for="required"></div>  
        </slx-input-decorator>
        <slx-input-decorator *ngIf="request.department" class="row-item">
          <slx-dropdown-input slx-input [readonly]="!state.isAddingMode" emptyOption [lookup]="{lookupType: 'locationUnit', orgLevelId: request.department?.orgLevelId}"
            name="unit" placeholder="Unit" [(ngModel)]="request.unit" [required]="selectedEmployeeExists">
          </slx-dropdown-input>
          <div errorMessage for="required"></div>
        </slx-input-decorator>
      </div>
      <div *ngIf="!validationResult?.isValid && validationResult?.isReadyForValidation" class="alert alert-danger wide" role="alert">{{validationResult?.errorMessage}}</div>
      <div *ngIf="!state.isAddingMode" class="message-container wide">
        <span *ngIf="!state.isError && !state.message" class="success-span">
          You have successfully added employee <a href="#" [employeeSectionLink]="request.agencyEmployee.employeeId">{{request.agencyEmployee.employeeLastName}}, {{request.agencyEmployee.employeeFirstName}}</a>
        </span>
        <div *ngIf="!state.isError && !!state.message" class="success-span">
          Employee <a href="#" [employeeSectionLink]="request.agencyEmployee.employeeId">{{request.agencyEmployee.employeeLastName}}, {{request.agencyEmployee.employeeFirstName}}</a> added successfully.
        </div>
        <div *ngIf="!state.isError && !!state.message" class="error-span">
          {{state.message}}
        </div>
        <span *ngIf="state.isError" class="error-span">During add new employee the error has occurred! Contact your system administrator.</span>
      </div>
    </div>
  </form>
</slx-spinner>
