import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import { createValuAccessor } from '../../../common/utils/index';
import { MultiSelectComponent, VirtualizationSettings } from '@progress/kendo-angular-dropdowns';

@Component({
  moduleId: module.id,
  selector: 'slx-multiselect',
  templateUrl: 'multiselect.component.html',
  providers: [createValuAccessor(MultiselectComponent)]
})

export class MultiselectComponent implements ControlValueAccessor {
  @Input()
  public valuePrimitive: boolean = false;

  @Input()
  public valueField: string = 'id';

  @Input()
  public titleField: string = 'name';

  @Input()
  public label: string = 'Filtering';

  @Input()
  public set options(options: StringMap<any>[]) {
    if (!_.isArray(options)) {
      this.m_options = [];
    } else {
      this.m_options = options;
    }
    this.originalOptions = _.cloneDeep(this.m_options);
    this.updateMultiselect(true);
  }

  public get options(): StringMap<any>[] {
    return this.m_options;
  }

  @Input()
  public readonly: boolean;

  @Input()
  public placeholder: string;

  @Input()
  public externalPlaceholderText: string;

  @Input()
  public externalPlaceholder: boolean;

  @Input()
  public set staticOpened(value: boolean) {
    if (_.isBoolean(value)) {
      this.staticPopup = true;
      if (this.kendoMultiselect && this.kendoMultiselect.toggle) {
        this.kendoMultiselect.toggle(value);
        if (value) {
          this.onOpen();
        } else {
          this.close();
        }
      }
    }
  }

  @Input()
  public virtual: VirtualizationSettings = {
    itemHeight: 28
  }

  public set value(value: StringMap<any>[]) {
    if (!_.isArray(value)) {
      this.m_value = [];
    } else {
      this.m_value = value;
    }
    this.onChangeCallback(this.m_value);
    this.updateMultiselect(false);
  }

  public get value(): StringMap<any>[] {
    return this.m_value;
  }

  @ViewChild('multiselect', {static: true})
  public kendoMultiselect: MultiSelectComponent;
  public isOpen: boolean;

  private needRestore: boolean;
  private originalOptions: StringMap<any>[];
  private m_value: StringMap<any>[];
  private m_options: StringMap<any>[];
  private unifiedOptionValue: number;
  private staticPopup: boolean;
  private onChangeCallback: (val: any) => void = _.noop;
  private onTouchedCallback: () => void = _.noop;

  constructor() {
    this.needRestore = false;
    this.isOpen = false;
    this.staticPopup = false;
    this.unifiedOptionValue = -1;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public writeValue(value: StringMap<any>[]): void {
    if (!_.isArray(value)) {
      this.m_value = [];

      return;
    }
    this.m_value = value;
  }

  public onFilterChange(userQuery: string): void {
    if (userQuery.length > 0) {
      const options: StringMap<any>[] = [];
      _.forEach(this.originalOptions, (opt: StringMap<any>) => {
        const optionText: string = String(opt[this.titleField]);
        if (_.includes(optionText.toLowerCase(), userQuery.toLowerCase())) {
          options.push(_.cloneDeep(opt));
        }
      });
      this.m_options = options;
      this.needRestore = true;
    } else {
      this.restoreOptionList();
    }
  }

  public onOpen(): void {
    if (this.value.length > 0) {
      const values: any[] = _.map(this.value, (option: any) => option[this.valueField]);
      const mainOptions: StringMap<any>[] = [];
      const otherOptions: StringMap<any>[] = [];
      _.forEach(this.m_options, (option: StringMap<any>) => {
        if (_.indexOf(values, option[this.valueField]) !== -1) {
          mainOptions.push(option);
        } else {
          otherOptions.push(option);
        }
      });
      mainOptions.push(...otherOptions);
      this.m_options = mainOptions;
      this.needRestore = true;
    } else {
      this.restoreOptionList();
    }
    this.isOpen = true;
  }

  public onClose(event: any): void {
    if (this.staticPopup) {
      event.preventDefault();

      return;
    }
    this.close();
  }

  public getSelectedOptionsMapper(): (selectedOptions: StringMap<any>[]) => StringMap<any>[] {
    return (selectedOptions: StringMap<any>[]) => {
      if (_.isArray(selectedOptions) && selectedOptions.length > 0) {
        return this.createUnifiedOption(selectedOptions.length);
      }

      return [];
    };
  }

  public onRemoveUnifiedOption(): void {
    this.updateMultiselect(true);
    this.needRestore = true;
    this.restoreOptionList();
  }

  private close(): void {
    this.restoreOptionList();
    this.isOpen = false;
  }

  private updateMultiselect(isReset: boolean): void {
    if (this.kendoMultiselect) {
      if (isReset) {
        this.kendoMultiselect.reset();
        this.kendoMultiselect.blur();
      } else {
        this.kendoMultiselect.blur();
      }
    }
  }

  private createUnifiedOption(amountOfSelected: number): StringMap<any>[] {
    const option: StringMap<any> = {
      [this.valueField]: this.unifiedOptionValue,
      [this.titleField]: `${this.label} (${amountOfSelected})`
    };

    return [option];
  }

  private restoreOptionList(): void {
    if (this.needRestore) {
      this.needRestore = false;
      this.m_options = _.cloneDeep(this.originalOptions);
    }
  }
}

