import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[slxChartWidgetBottomTemplate]'
})
export class ChartWidgetBottomTemplateDirective {

  @Input()
  public isHidden: boolean;

  constructor(public template: TemplateRef<ChartWidgetBottomTemplateDirective>) {}
}
