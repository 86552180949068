import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '..';
var AgencyCertificate = /** @class */ (function () {
    function AgencyCertificate() {
        this.dnr = false;
        this.preferred = false;
    }
    return AgencyCertificate;
}());
export { AgencyCertificate };
var AgencyCertificates = /** @class */ (function (_super) {
    tslib_1.__extends(AgencyCertificates, _super);
    function AgencyCertificates() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return AgencyCertificates;
}(EmployeeSectionsBase));
export { AgencyCertificates };
