<div class="action-bar">
    <div class="search-field">
      <div class="input-append">
        <input class="form-control users-search-input" type="text"
               [(ngModel)]="filterString" (keyup)="onFilterChanged()" placeholder="Filter by users...">
        <span *ngIf="rolesDecorator">
          {{rolesDecorator.usersRoles.roles.length}} role(s),
          <span *ngIf="filterString">found {{rolesDecorator.totalFilteredCount}} of </span>
          {{rolesDecorator.totalAssignedCount}} users(s)
        </span>

      </div>
    </div>
    <div>
        <button type="button" class="theme-button-apply" (click)="addUserClick ()">Add User</button>
    </div>
</div>
