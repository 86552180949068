import {
  Component,
  Input,
  ElementRef,
  forwardRef
} from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-radio-button',
  templateUrl: 'radio-button.component.html',
  providers: [createValuAccessor(RadioButtonComponent)]
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input()
  public fieldName: string;
  @Input()
  public caption: string;
  @Input()
  public className: string;
  @Input()
  public option: any;
  @Input()
  public valueField: string;
  @Input()
  public readonly: boolean;

  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(this.innerValue);
    }
  }

  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: any = {};

  public writeValue(value?: any): void {
    if (!_.isUndefined(value) && !_.isNull(value)) {
      this.innerValue = value;
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public isChecked(): boolean {
    if (this.valueField) {
      return this.option[this.valueField] === this.value[this.valueField];
    }

    return this.option === this.value;
  }

  public cssClass(): string {
    let cssClass: string = 'slx-radio';
    if (this.isChecked()) {
      cssClass += ` checked`;
    }
    if (this.readonly) {
      cssClass += ` disabled`;
    }
    if (_.isString(this.className) && _.size(this.className) > 0) {
      cssClass += ` ${this.className}`;
    }

    return cssClass;
  }
}
