import * as tslib_1 from "tslib";
import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { process } from '@progress/kendo-data-query';
import * as _ from 'lodash';
import { DailyOpenShiftDetailsData, DetailsAgencyPosition, DetailsPartner } from '../../models';
import { ShiftRequestService } from '../../services/schedule/shift-request.service';
import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { AppSettingsManageService } from '../../../app-settings/services';
import { Observable, Subscription } from 'rxjs-compat';
import { mutableSelect, unsubscribe } from '../../../../app/core/decorators';
var ShiftRequestGridComponent = /** @class */ (function () {
    function ShiftRequestGridComponent(shiftRequestService, appSettingsManageService) {
        var _this = this;
        this.shiftRequestService = shiftRequestService;
        this.appSettingsManageService = appSettingsManageService;
        this.requestedDate = new Date;
        this.storeSelectedDataArr = [];
        this.selectedDisplayId = [];
        this.gridData = null;
        this.gridView = null;
        this.state = {};
        this.optionItemsSelected = false;
        this.checkboxOnly = false;
        this.mode = 'multiple';
        this.drag = false;
        this.previousGroups = [];
        this.storeSelectedData = [];
        this.selectableSettings = {
            checkboxOnly: this.checkboxOnly,
        };
        this.records = [];
        this.displayId = 0;
        this.isEditMode = false;
        this.gridSelectionChangeHandler = new EventEmitter();
        this.previousGroups = _.cloneDeep(this.previousGroups);
        this.Loadstate = {
            isLoading: false,
        };
        this.appSettingsManageService.getAppServerConfig()
            .then(function (appConfigData) {
            _this.serverUtcOffsetSec = appConfigData.serverUtcOffsetSec;
        });
    }
    ShiftRequestGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.orgLevelSubscripion = this.orgLevel$.subscribe(function (selectedOrgLevel) {
            _this.orgLevel = selectedOrgLevel;
        });
        this.agencyDetailsData = this.shiftRequestService.getAgencies();
        this.gridData = this.shiftRequestService.filterOpenShiftsData(this.shiftRequestService.getGridData());
        this.partnerPositionMapData = this.shiftRequestService.getPartnerPositionMapData();
        this.applyTableState(this.state);
        this.shiftRequestService.getDisplayId().subscribe(function (id) {
            _this.isEditMode = true;
        });
        this.shiftRequestService.getshiftChanges().subscribe(function (el) {
            _this.isEditMode = false;
        });
        this.shiftRequestService.updateShiftRequests().subscribe(function (addShift) {
            if (addShift) {
                _this.filterSelectedShift(addShift);
            }
            else {
                _this.isEditMode = false;
                if (_this.shiftRequestService.isrequestAgencyStaffCancelled) {
                    _this.records = _this.shiftRequestService.currentShiftRecords;
                    var shift = _this.shiftRequestService.shiftRecords.filter(function (e) { return e.displayId == _this.shiftRequestService.deletedDisplayId; });
                    _this.records.push(shift[0]);
                    _this.records.sort(function (a, b) { return a.displayId < b.displayId ? -1 : 1; });
                    _this.shiftRequestService.isrequestAgencyStaffCancelled = false;
                    _this.displayId = _this.shiftRequestService.deletedDisplayId;
                }
                _this.selectedDisplayId = [];
            }
            _this.isEditMode = false;
            _this.storeSelectedData = [];
        });
    };
    ShiftRequestGridComponent.prototype.applyTableState = function (state) {
        var _this = this;
        this.Loadstate.isLoading = true;
        setTimeout(function () {
            _this.Loadstate.isLoading = false;
            var shiftDetails = [];
            shiftDetails = _this.shiftRequestService.filterGridData(_this.gridData, _this.agencyDetailsData);
            _this.records = _this.filterPassedShifts(shiftDetails);
            var displayId = 1;
            _this.records.forEach(function (item) {
                item.displayId = displayId++;
                item.Hours = parseFloat(item.Hours);
            });
            _this.shiftRequestService.shiftRecords = _this.records;
            _this.refreshGrid();
        }, 300);
    };
    ShiftRequestGridComponent.prototype.refreshGrid = function () {
        this.gridView = process(this.records, this.state);
    };
    ShiftRequestGridComponent.prototype.filterPassedShifts = function (shiftDetails) {
        // Shift grace period is 2 
        this.currentOffset = +_.get(this.orgLevel, 'location.timeZoneOffset') || 0;
        var utctime = moment.utc();
        var uniquecode = new Date().getTime();
        var appDateTime = utctime.utcOffset(this.currentOffset);
        var currentDateTime = moment(appDateTime).add(2, 'hours').format(appConfig.dateTimeFormat);
        var records = [];
        shiftDetails.forEach(function (element) {
            var shiftStartDate = moment(element.ShiftStart).toDate();
            if (moment(currentDateTime).isBefore(shiftStartDate)) {
                records.push(element);
            }
        });
        return records;
    };
    ShiftRequestGridComponent.prototype.filterSelectedShift = function (addShift) {
        var _this = this;
        if (this.selectedDisplayId.length > 0) {
            if (addShift) {
                var shifts = this.records.filter(function (ar) {
                    return !_this.selectedDisplayId.find(function (rm) { return (rm === ar.displayId); });
                });
                this.records = shifts;
            }
        }
        this.shiftRequestService.currentShiftRecords = this.records;
        this.selectedDisplayId = [];
        this.refreshGrid();
    };
    ShiftRequestGridComponent.prototype.dataStateChange = function (state) {
        this.state = state;
        this.refreshGrid();
    };
    ShiftRequestGridComponent.prototype.gridSelectedShift = function (selection) {
        var _this = this;
        if (selection.selectedRows.length !== 0) {
            selection.selectedRows.forEach(function (row) {
                _this.storeSelectedData.push(row.dataItem);
            });
        }
        if (selection.deselectedRows.length !== 0) {
            selection.deselectedRows.forEach(function (row) {
                _this.storeSelectedData = _this.storeSelectedData.filter(function (e) { return e.displayId !== row.dataItem.displayId; });
            });
        }
        this.gridSelectionChangeHandler.emit(this.storeSelectedData);
    };
    ShiftRequestGridComponent.prototype.isDisabledShift = function (dataItem) {
        if (this.isEditMode) {
            return true;
        }
        else {
            return false;
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ShiftRequestGridComponent.prototype, "orgLevelSubscripion", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ShiftRequestGridComponent.prototype, "orgLevel$", void 0);
    return ShiftRequestGridComponent;
}());
export { ShiftRequestGridComponent };
