import { Directive, Input, forwardRef, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

import * as _ from 'lodash';

import { CommonValidators } from './common-validators';

const SLX_MAX_LENGTH_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SlxMaxLengthValidator),
  multi: true
};

@Directive({
  /* tslint:disable */
  selector: '[slxMaxLength][formControlName],[slxMaxLength][formControl],[slxMaxLength][ngModel]',
  /* tslint:enable */
  providers: [SLX_MAX_LENGTH_VALIDATOR]
})
export class SlxMaxLengthValidator implements Validator, OnChanges {

  @Input()
  public slxMaxLength: string;

  /* tslint:disable */
  private _validator: ValidatorFn;
  private _onChange: () => void;
  /* tslint:enable */

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['slxMaxLength']) {
      this._createValidator();
      if (this._onChange) {
        this._onChange();
      }
    }
  }

  public validate(c: AbstractControl): { [key: string]: any } {
    return this._validator ? this._validator(c) : null;
  }

  public registerOnValidatorChange(fn: () => void): void {
    this._onChange = fn;
  }

  private _createValidator(): void {
    if (isFinite(parseInt(this.slxMaxLength, 10))) {
      this._validator = CommonValidators.slxMaxLength(parseInt(this.slxMaxLength, 10));
    }
  }
}
