import * as _ from 'lodash';
import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Router, ActivatedRoute, UrlTree, NavigationExtras } from '@angular/router';
import { ApplicationService } from '../services/index';
import { TimeclocksNavigationService } from '../../common/services/index';
import { UserApplication } from '../../state-model/models';

@Directive({
  selector: '[slxTimeclockViewAll]',
})
export class TimeClockViewAllDirective {

  private router: Router;
  private route: ActivatedRoute;
  constructor(router: Router, route: ActivatedRoute, private applicationService: ApplicationService) {
    this.router = router;
    this.route = route;
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
      let navService: TimeclocksNavigationService = new TimeclocksNavigationService(this.router, this.route);
      let orglevelId: number = this.getOrglevelIdFromRoute();
      navService.navigateToTimeclocks (orglevelId);
  }

  private getOrglevelIdFromRoute(): number {
    const id: string = this.route.snapshot.queryParamMap.get('orgLevelId');
    return _.toInteger(id);
  }

}
