import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../app/core/decorators';
import { DetailScreenService } from '../../services';
import { AgenciesApiService } from '../../../../app/configuration/services';
import { AgencyStaffingConsoleApiService } from '../../services/agency-staffing-console/agency-staffing-console-api.service';
import { AgencyShiftDetails } from '../../models/agency-staffing-console/agency-shift-details';
import { AgencyShiftSummaryDetails } from '../../models/agency-staffing-console/agency-shift-short-details';
import * as jspdf from 'jspdf';
import autoTable from 'jspdf-autotable';
import { AgencyStaffingConsoleGridComponent } from './agency-staffing-console-grid/agency-staffing-console-grid.component';
import * as XLSX from 'xlsx';
var AgencyStaffingConsoleComponent = /** @class */ (function () {
    function AgencyStaffingConsoleComponent(detailScreenService, agenciesApiService, agencyConsoleApiService) {
        this.detailScreenService = detailScreenService;
        this.agenciesApiService = agenciesApiService;
        this.agencyConsoleApiService = agencyConsoleApiService;
        this.filters = [];
        this.filterAgency = [];
        this.shiftDetails = [];
        this.shiftSummary = [];
        this.agencyListData = [];
        this.filteredAgencyListData = [];
        this.weekFilter = ['Week', 'Day', 'Month'];
        this.selectedValue = 'Week';
        this.filterAll = true;
        this.exportRecords = [];
        this.filterEnabled = false;
        this.leftGrid = false;
        this.exportExcel = false;
        this.multiSheetExcel = [];
        this.selectCancelled = false;
        this.selectAssigned = false;
        this.selectPending = false;
        this.selectPendingAction = false;
        this.selectFailed = false;
        this.isConsoleSummaryReady = false;
        this.isConsoleDetailsReady = false;
        this.processedPartner = [];
        this.totalCount = 0;
        this.shiftStateCount = [];
        this.isLoading = true;
        this.weekChanged = true;
        this.selectedStartWeek = moment().weekday(0);
        this.selectedEndWeek = moment().weekday(6);
        this.filterStartDate = this.selectedStartWeek.format('MM-DD-YYYY');
        this.filterEndDate = this.selectedEndWeek.format('MM-DD-YYYY');
    }
    AgencyStaffingConsoleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.alias = session.alias;
                _this.customerId = session.alias;
            }
        });
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (selectedOrgLevel) {
            _this.orgLevel = selectedOrgLevel;
            // Update agency information
            _this.getPartnerAgenciesPositions(_this.orgLevel);
            // 2022-04-20
            // day - start and end date is same
            // weekly = weekly start and weekly end
            // month == month start and month end
            _this.getShiftDetails(_this.selectedStartWeek.format('YYYY-MM-DD'), _this.selectedEndWeek.format('YYYY-MM-DD'));
            //this.getShiftDetails(startDate,endDate);
        });
    };
    AgencyStaffingConsoleComponent.prototype.getPartnerAgenciesPositions = function (selectedOrgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.agenciesApiService.getAgencies(selectedOrgLevel.id).then(function (mappings) {
                                _this.agencyMap = mappings.reduce(function (map, obj) {
                                    map[obj.agency.id] = obj.agency.name;
                                    return map;
                                }, {});
                                _this.agencyDetails(selectedOrgLevel).then(function (res) {
                                    _this.agencyListData = [];
                                    if (res == null || res.length == 0) {
                                        return;
                                    }
                                    var filteredAgencies = res.filter(function (o) { return o.status == 'active'; });
                                    if (filteredAgencies == null || filteredAgencies.length == 0) {
                                        return;
                                    }
                                    filteredAgencies.forEach(function (element) {
                                        _this.agencyData = element;
                                        _this.agencyData.agency_name = _this.agencyMap[_this.agencyData.agency_id];
                                        _this.agencyListData.push(_this.agencyData);
                                        _this.filteredAgencyListData = _this.agencyListData;
                                    });
                                    _this.filteredAgencyListData = _this.agencyListData = _this.filteredAgencyListData.sort(function (n1, n2) {
                                        var agencyName1 = n1.agency_name;
                                        if (agencyName1 != null) {
                                            agencyName1 = agencyName1.trim();
                                        }
                                        var agencyName2 = n2.agency_name;
                                        if (agencyName2 != null) {
                                            agencyName2 = agencyName2.trim();
                                        }
                                        if (agencyName1 > agencyName2) {
                                            return 1;
                                        }
                                        if (agencyName1 < agencyName2) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                    _this.isLoading = false;
                                });
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AgencyStaffingConsoleComponent.prototype.agencyDetails = function (orgLevel) {
        return this.detailScreenService.getAgencyDetails(orgLevel, this.customerId);
    };
    AgencyStaffingConsoleComponent.prototype.filterChange = function (filter, event) {
        this.filterEnabled = !this.filterEnabled;
        if (filter == 'all') {
            this.filterAll = true;
            this.selectCancelled = false;
            this.selectPendingAction = false;
            this.selectAssigned = false;
            this.selectPending = false;
            this.selectFailed = false;
            this.filters = [];
            this.filters[0] = [{ 'filterName': 'all', 'checked': true }];
        }
        else {
            this.filterAll = false;
            this.filters[this.filters.length] = [{ 'filterName': filter, 'checked': event.target.checked }];
        }
    };
    AgencyStaffingConsoleComponent.prototype.agencyFilter = function (filter) {
        var _this = this;
        this.filteredAgencyListData = [];
        filter.forEach(function (agencyId) {
            _this.agencyListData.filter(function (agency) {
                if (agency.agency_id == agencyId) {
                    _this.filteredAgencyListData.push(agency);
                }
            });
        });
        if (this.filteredAgencyListData.length == 0) {
            this.filteredAgencyListData = this.agencyListData;
        }
    };
    AgencyStaffingConsoleComponent.prototype.weekFilterChnaged = function (filter) {
        if (filter == 'Day') {
            this.selectedWeekFilter = 'day';
        }
        else if (filter == 'Week') {
            this.selectedWeekFilter = 'week';
        }
        else if (filter == 'Month') {
            this.selectedWeekFilter = 'month';
        }
    };
    AgencyStaffingConsoleComponent.prototype.selectAllRecords = function (value) {
        this.filterAll = value;
    };
    AgencyStaffingConsoleComponent.prototype.selectedDateRange = function (range) {
        var shiftStartDate = range.startDate.format('YYYY-MM-DD');
        var shiftEndDate = range.endDate.format('YYYY-MM-DD');
        this.filterStartDate = range.startDate.format('MMMM DD, YYYY');
        this.filterEndDate = range.endDate.format('MMMM DD, YYYY');
        this.getShiftDetails(shiftStartDate, shiftEndDate);
    };
    AgencyStaffingConsoleComponent.prototype.exportToExcel = function () {
        var _this = this;
        this.getExportRecords();
        var wb = XLSX.utils.book_new();
        this.filteredAgencyListData.forEach(function (agency) {
            _this.prepareAgencyShiftSummary(agency.partner_id);
            var records = _this.exportRecords.filter(function (record) { return record.partnerId == agency.partner_id; });
            if (records.length == 0) {
                records.push(new AgencyShiftDetails());
            }
            if (agency.agency_name != null) {
                var rowsData_1 = [];
                records.map(function (x) {
                    rowsData_1.push({
                        "State": x.state,
                        "Employee Name": x.employeeName,
                        "Shift Date": x.shiftDate ? moment(x.shiftDate).format('MM/DD/YYYY') : "",
                        "Shift": x.shift,
                        "Hours": x.hours,
                        "Position": x.position,
                        "Unit": x.unit,
                        "Attributes": x.attributes,
                        "Worked Hours": x.workedHours,
                        "Meal Break Duration": x.mealBreakDuration,
                        "Cancellation Type": x.cancellationType,
                        "Cancellation Date": x.cancellationDate ? moment(x.cancellationDate).format('MM/DD/YYYY') : ""
                    });
                });
                var ws = XLSX.utils.json_to_sheet(rowsData_1);
                XLSX.utils.book_append_sheet(wb, ws, agency.agency_name.trim().length > 30 ? agency.agency_name.substring(0, 30).trim() : agency.agency_name.trim());
            }
        });
        XLSX.writeFile(wb, 'AgencyStaffingConsole_' + this.filterStartDate + "-" + this.filterEndDate + '.xlsx');
    };
    AgencyStaffingConsoleComponent.prototype.getExportRecords = function () {
        var _this = this;
        this.shiftState = ['Assigned', 'Pending', 'Pending Action', 'Cancelled', 'Failed'];
        if (this.filterAll) {
            this.exportRecords = this.shiftDetails;
        }
        else {
            var records_1;
            this.shiftState = [];
            this.exportRecords = [];
            this.filters.forEach(function (filter) {
                var state = false;
                if (filter[0].filterName == 'pending') {
                    state = filter[0].checked;
                    if (state) {
                        records_1 = _this.shiftDetails.filter(function (el) { return el.state == "Pending"; });
                        _this.shiftState.push('Pending');
                        _this.exportRecords = _this.exportRecords.concat(records_1);
                    }
                    else {
                        _this.exportRecords = _this.exportRecords.filter(function (el) { return el.state != "Pending"; });
                        _this.shiftState = _this.shiftState.filter(function (e) { return e != 'Pending'; });
                    }
                }
                if (filter[0].filterName == 'assigned') {
                    state = filter[0].checked;
                    if (state) {
                        records_1 = (_this.shiftDetails.filter(function (el) { return el.state == "Assigned"; }));
                        _this.shiftState.push('Assigned');
                        _this.exportRecords = _this.exportRecords.concat(records_1);
                    }
                    else {
                        _this.exportRecords = _this.exportRecords.filter(function (el) { return el.state != "Assigned"; });
                        _this.shiftState = _this.shiftState.filter(function (e) { return e != 'Assigned'; });
                    }
                }
                if (filter[0].filterName == 'cancelled') {
                    state = filter[0].checked;
                    if (state) {
                        records_1 = _this.shiftDetails.filter(function (el) { return el.state == "Cancelled"; });
                        _this.shiftState.push('Cancelled');
                        _this.exportRecords = _this.exportRecords.concat(records_1);
                    }
                    else {
                        _this.exportRecords = _this.exportRecords.filter(function (el) { return el.state != "Cancelled"; });
                        _this.shiftState = _this.shiftState.filter(function (e) { return e != 'Cancelled'; });
                    }
                }
                if (filter[0].filterName == 'requested') {
                    state = filter[0].checked;
                    if (state) {
                        records_1 = _this.shiftDetails.filter(function (el) { return el.state == "Pending Action"; });
                        _this.shiftState.push('Pending Action');
                        _this.exportRecords = _this.exportRecords.concat(records_1);
                    }
                    else {
                        _this.exportRecords = _this.exportRecords.filter(function (el) { return el.state != "Pending Action"; });
                        _this.shiftState = _this.shiftState.filter(function (e) { return e != 'Pending Action'; });
                    }
                }
                if (filter[0].filterName == 'failed') {
                    state = filter[0].checked;
                    if (state) {
                        records_1 = _this.shiftDetails.filter(function (el) { return el.state == "Failed"; });
                        _this.shiftState.push('Failed');
                        _this.exportRecords = _this.exportRecords.concat(records_1);
                    }
                    else {
                        _this.exportRecords = _this.exportRecords.filter(function (el) { return el.state != "Failed"; });
                        _this.shiftState = _this.shiftState.filter(function (e) { return e != 'Failed'; });
                    }
                }
            });
        }
    };
    AgencyStaffingConsoleComponent.prototype.captureScreen = function () {
        var _this = this;
        this.getExportRecords();
        var pdf = new jspdf.jsPDF('p', 'mm', 'a4'); // A4 size page of PDF
        var current = new Date();
        var cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        var cTime = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
        var dateTime = cDate + ' ' + cTime;
        var count = 0;
        pdf.setFontSize(10);
        pdf.text("Downloaded On: " + dateTime, 5, 5);
        pdf.setFontSize(10);
        pdf.text("Date Filter: " + this.filterStartDate + " - " + this.filterEndDate, 5, 15);
        this.filteredAgencyListData.forEach(function (agency) {
            if (agency.agency_name != null) {
                _this.prepareAgencyShiftSummary(agency.partner_id);
                var records = _this.exportRecords.filter(function (record) { return record.partnerId == agency.partner_id; });
                if (count > 0) {
                    pdf.addPage();
                }
                pdf.setFontSize(14);
                pdf.text(agency.agency_name.trim(), 5, 25);
                pdf.setFontSize(14);
                pdf.text("Total Agency Staff: " + _this.totalCount, 5, 35);
                // *********** Prepare Partner Info Table ****************
                //prepare grid columns to be displayed
                var partnerCol = [];
                partnerCol.push(["State", "Count"]);
                var partnerRows = [];
                //prepare grid data
                _this.shiftStateCount.filter(function (x) { return x.partnerId === agency.partner_id; }).forEach(function (item) {
                    var temp = [item.state, item.shiftCount];
                    partnerRows.push(temp);
                });
                //prepare and add table to the pdf file
                autoTable(pdf, {
                    startY: 40,
                    theme: 'striped',
                    pageBreak: 'auto',
                    tableWidth: 'auto',
                    margin: { top: 5, right: 5, bottom: 5, left: 5 },
                    head: partnerCol,
                    body: partnerRows
                });
                // ********* Prepare Shift Details Table ****************
                //prepare grid columns to be displayed
                var col = [];
                col.push(["State", "Employee Name", "Shift Date", "Shift", "Hours", "Position", "Unit", "Attributes", "Worked Hours", "Meal Break Duration", "Cancellation Type", "Cancellation Date"]);
                var rows = [];
                //prepare grid data
                records.forEach(function (item) {
                    var temp = [item.state,
                        item.employeeName,
                        item.shiftDate ? moment(item.shiftDate).format('MM/DD/YYYY') : "",
                        item.shift,
                        item.hours,
                        item.position,
                        item.unit,
                        item.attributes,
                        item.workedHours,
                        item.mealBreakDuration,
                        item.cancellationType,
                        item.cancellationDate ? moment(item.cancellationDate).format('MM/DD/YYYY') : ""];
                    rows.push(temp);
                });
                if (rows.length == 0) {
                    rows.push([{ content: 'No records found.', colSpan: 12, styles: { halign: 'center' } }]);
                }
                //prepare and add table to the pdf file
                autoTable(pdf, {
                    theme: 'grid',
                    pageBreak: 'auto',
                    tableWidth: 'auto',
                    margin: { top: 10, right: 5, bottom: 10, left: 5 },
                    styles: { overflow: 'linebreak', fontSize: 8 },
                    columnStyles: {
                        0: { cellWidth: 18 },
                        1: { cellWidth: 20 },
                        2: { cellWidth: 20 },
                        3: { cellWidth: 22 },
                        4: { cellWidth: 12 },
                        5: { cellWidth: 10 },
                        6: { cellWidth: 10 },
                        7: { cellWidth: 20 },
                        8: { cellWidth: 14 },
                        9: { cellWidth: 14 },
                        10: { cellWidth: 20 },
                        11: { cellWidth: 20 }
                    },
                    head: col,
                    body: rows
                });
                count++;
            }
        });
        pdf.save('AgencyStaffingConsole_' + this.filterStartDate + "-" + this.filterEndDate + '.pdf'); // Generated PDF
    };
    AgencyStaffingConsoleComponent.prototype.getMultiSheetExcel = function (record) {
    };
    AgencyStaffingConsoleComponent.prototype.getShiftDetails = function (shiftStartDate, shiftEndDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isConsoleDetailsReady = false;
                        return [4 /*yield*/, this.agencyConsoleApiService.getShiftDetails(shiftStartDate, shiftEndDate).then(function (details) {
                                _this.shiftDetails = details;
                                _this.isConsoleDetailsReady = true;
                            })];
                    case 1:
                        _a.sent();
                        this.isConsoleSummaryReady = false;
                        return [4 /*yield*/, this.agencyConsoleApiService.getShiftSummary(shiftStartDate, shiftEndDate, this.customerId).then(function (shortDetails) {
                                _this.shiftSummary = shortDetails;
                                _this.isConsoleSummaryReady = true;
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AgencyStaffingConsoleComponent.prototype.prepareAgencyShiftSummary = function (partnerId) {
        var _this = this;
        var shiftState = this.shiftState;
        this.shiftSummary.map(function (x) {
            if (x.partnerId === partnerId) {
                _this.totalCount = x.totalCount;
                _this.processedPartner.push(partnerId);
                _this.shiftStateCount = [];
                shiftState.forEach(function (state) {
                    var summary = new AgencyShiftSummaryDetails();
                    summary.partnerId = partnerId;
                    summary.state = state;
                    var data = x.agencyShiftCount.find(function (e) { return e.state == state; });
                    if (data !== null && data !== undefined) {
                        summary.shiftCount = data.shiftCount;
                    }
                    else {
                        summary.shiftCount = 0;
                    }
                    _this.shiftStateCount.push(summary);
                });
            }
            else {
                if (!_this.processedPartner.includes(partnerId)) {
                    _this.shiftStateCount = [];
                    _this.totalCount = 0;
                    shiftState.forEach(function (state) {
                        var summary = new AgencyShiftSummaryDetails();
                        summary.partnerId = partnerId;
                        summary.shiftCount = 0;
                        summary.state = state;
                        _this.shiftStateCount.push(summary);
                    });
                }
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], AgencyStaffingConsoleComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], AgencyStaffingConsoleComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AgencyStaffingConsoleComponent.prototype, "userSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AgencyStaffingConsoleComponent.prototype, "orgLevelSubscription", void 0);
    return AgencyStaffingConsoleComponent;
}());
export { AgencyStaffingConsoleComponent };
