import { Component, Input, OnInit, Provider, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { DialogOptions, IDialog } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { DailyAbsence } from '../../../models/index';
import { screenUtils, IScreenUtils } from '../../../../common/utils/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { TimeCardAbsencesEditorComponent } from '../timecard-absences-editor/timecard-absences-editor.component';
@Component({
  moduleId: module.id,
  selector: 'slx-timecard-absences-editor-dialog',
  templateUrl: 'timecard-absences-editor-dialog.component.html',
  styleUrls: ['timecard-absences-editor-dialog.component.scss']
})
export class TimecardAbsencesEditorDialogComponent implements IDialog {


  public options: DialogOptions;
  public dialogResult: boolean;
  public request: DailyAbsence;
  public final_result: boolean;

  @ViewChild('absencesEditor', {static: true})
  public absencesEditor: TimeCardAbsencesEditorComponent;

  public get formValid(): boolean {
    return this.absencesEditor.absencesForm.valid;
  }

  public get isOverlappedAndUpdateSchedule(): boolean {
    this.final_result = false;
    if (this.absencesEditor.isOverlappedAbsencestartdate || this.absencesEditor.isOverlappedAbsenceenddate || this.absencesEditor.isOverlappedAbsenceshift || this.absencesEditor.isOverlappedAbsencesDB) {
      this.final_result = true;
    }
    if (this.absencesEditor.isOverlapped && this.absencesEditor.dataItem.updateSchedule) {
      this.final_result = true;
    }
    return this.final_result;
  
  }

  private modalService: ModalService;

  public static openDialog(header: string, request: DailyAbsence, modalService: ModalService, dailyTimecardManagementService: DailyTimecardManagementService, callback: (result: boolean, item: DailyAbsence) => void): TimecardAbsencesEditorDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 500;
    dialogOptions.width = 500;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: DailyAbsence,
        useValue: request
      },
      {
        provide: DailyTimecardManagementService,
        useValue: dailyTimecardManagementService
      }
    ];

    let dialog: TimecardAbsencesEditorDialogComponent = modalService.globalAnchor
      .openDialog(TimecardAbsencesEditorDialogComponent, header, dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, dialog.request);
      });
    return dialog;
  }


  constructor(options: DialogOptions,
    modalService: ModalService,
    request: DailyAbsence
  ) {
    this.modalService = modalService;
    this.options = options;
    this.request = request;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
