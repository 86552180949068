export interface IAccrualTypes {
  accrualTypeId: number;
  accrualTypeName: string;
  policyId: number;
  policyName: string;
  lastManualTransactionDate: string;
  balance: number;
}

export class AccrualTypes {
  public accrualTypeId: number;
  public accrualTypeName: string;
  public policyId: number;
  public policyName: string;
  public lastManualTransactionDate: Date;
  public balance: number;
  public chkEnable:boolean;
}