import * as tslib_1 from "tslib";
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { benefitsConfig } from '../../benefits.config';
import { BenefitEligibilityRulesMapService } from './benefit-eligibility-rules-map.service';
var BenefitEligibilityRulesApiService = /** @class */ (function () {
    function BenefitEligibilityRulesApiService(mapService, apiUtilService, urlParamsService) {
        this.mapService = mapService;
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    BenefitEligibilityRulesApiService.prototype.getBenefitEligibilityRule = function (ruleId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.eligibilityRule;
                        params = {
                            ruleId: ruleId,
                        };
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEligibilityRule(response.data)];
                }
            });
        });
    };
    BenefitEligibilityRulesApiService.prototype.getBenefitEligibilityRulesByTierId = function (benefitTierId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.eligibilityRuleByTier;
                        params = {
                            benefitTierId: benefitTierId,
                        };
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEligibilityRules(response.data)];
                }
            });
        });
    };
    BenefitEligibilityRulesApiService.prototype.getBenefitEligibilityRulesNotBelongsToTier = function (benefitTierId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.eligibleRules;
                        params = {
                            benefitTierId: benefitTierId,
                        };
                        request = this.urlParamsService.requestGet(url, params);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEligibilityRules(response.data)];
                }
            });
        });
    };
    BenefitEligibilityRulesApiService.prototype.changeEligibilityRules = function (orgLevelId, benefitTierId, changeRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, params, body, request;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.eligibilityRule;
                params = {
                    orgLevelId: orgLevelId,
                    benefitTierId: benefitTierId,
                };
                body = this.mapService.mapToBenefitEligibilityRuleChangeRequestDto(changeRequest);
                request = this.urlParamsService.requestPost(url, body, params);
                request.autoContentType = true;
                return [2 /*return*/, this.apiUtilService.requestNew(request)];
            });
        });
    };
    BenefitEligibilityRulesApiService.prototype.getBenefitEligibilityConfiguration = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request, response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.getApiUrl() + "/" + benefitsConfig.api.benefits.eligibilityRuleConfiguration;
                        request = this.urlParamsService.requestGet(url);
                        return [4 /*yield*/, this.apiUtilService.requestNew(request)];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.mapService.mapToBenefitEligibilityRuleConfigurationData(response.data)];
                }
            });
        });
    };
    BenefitEligibilityRulesApiService.prototype.getApiUrl = function () {
        return this.apiUtilService.getApiRoot() + "/" + benefitsConfig.api.benefits.root;
    };
    return BenefitEligibilityRulesApiService;
}());
export { BenefitEligibilityRulesApiService };
