import { AcaMonthRecord,state1095CValue } from '../../../models';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import {ACA1095cConfig} from '../../../aca-1095.config';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Aca1095cGridColumnHeaderComponent } from './aca-1095-c-grid-column-header.component';
export class Aca1095cGridHelper {
  public columnDefs: any[];
  public linkMonths: StringMap<boolean> = {};
  public defaultColumnDefs: any = {
        resizable: true,
        suppressMenu: true,
        suppressMovable: true
        
  };
 
  public state: {
    year: number;
    numMonths: number;
    organizationColShown: boolean;
    departmentColShown: boolean;
    positionColShown: boolean;
    dateHiredColShown: boolean;
    dateTerminatedColShown: boolean;   
    benefitDeclinedColShown: boolean;
    purchaseMarketplaceColShown: boolean;

  };
  public frameworkComponents={
    aca1095cGridColumnHeaderComponent: Aca1095cGridColumnHeaderComponent   
  };

  public monthLinkArray(){    
    this.linkMonths['All']=false;
    for (let month: number = 0; month < 12; month++) {
      this.linkMonths[moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM')]=false;   
    }  
  }
  public createColumnDefs(): void {
    this.columnDefs = [];
    this.columnDefs.push({
      'headerName': '',
      'field': 'expand',
      'width': 20,
      'resizable': false,
      'pinned': 'left',
      'headerClass': 'ag-custom-header-action',
      'cellClass': 'ag-custom-cell-action',
      'cellRenderer': function (params) {
        var html = "<i  class='fas fa-plus' (click)='onExpand(params)'></i>";
        if (params.data.expand) {
          html = "<i   class='fas fa-minus' (click)='onExpand(params)'></i>";
        }
        return html;
       
      }
    });

    
    this.columnDefs.push({
      'headerName': 'Employee Name',
      'field': 'employeeName',
      'width': 150,
      'pinned': 'left',
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass':'ag-custom-cell',
      'cellRenderer': function (params) {
        return `<a class="employee-name" title="${params.data.employeeName}"> ${params.data.employeeName}</a>`;
      }
    });
    this.columnDefs.push({
      'headerName': 'Hire Date',
      'field': 'hireDate',
      'width': 120,
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass': 'ag-custom-cell',
      'hide': !this.state.dateHiredColShown,
      'cellRenderer': function (params) {
        return moment(params.value).format(appConfig.dateFormat);
      }
    });

    this.columnDefs.push({
      'headerName': 'Organization',
      'field': 'organization',
      'width': 150,
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass': 'ag-custom-cell',
      'hide': !this.state.organizationColShown,
    });

    this.columnDefs.push({
      'headerName': 'Department',
      'field': 'department',
      'width': 120,
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass': 'ag-custom-cell',
      'hide': !this.state.departmentColShown,
    });

    this.columnDefs.push({
      'headerName': 'Position',
      'field': 'position',
      'width': 120,
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass': 'ag-custom-cell',
      'hide': !this.state.positionColShown,
    });    

    this.columnDefs.push({
      'headerName': 'Term Date',
      'field': 'terminationDate',
      'width': 120,
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass': 'ag-custom-cell',
      'hide': !this.state.dateTerminatedColShown,
      'cellRenderer': function (params) {
        return moment(params.value).format(appConfig.dateFormat);
      }
    });

    this.columnDefs.push({
      'headerName': 'Benefit Declined',
      'field': 'benefitDeclined',
      'width': 100,
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass': 'ag-custom-cell',
      'hide': !this.state.benefitDeclinedColShown,
    });

    this.columnDefs.push({
      'headerName': 'Purchased Marketplace',
      'field': 'purchasedMarketplace',
      'width': 100,
      'resizable': false,
      'headerClass': 'ag-custom-header',
      'cellClass': 'ag-custom-cell',
      'hide': !this.state.purchaseMarketplaceColShown,
    });
    this.columnDefs.push({
      'headerName': '',
      'field': 'link',
      'width': 35,
      'resizable': false,
      'headerClass': 'ag-custom-header no-padding',
      'cellClass': 'ag-custom-cell no-padding',
      'hide': false,
      headerComponent: 'aca1095cGridColumnHeaderComponent',
      headerComponentParams: {
          link: this.linkMonths,
          name:'All'
      } , 
      'cellRenderer': function (params) {
        var html = "<div class='link-column  unlink-icon'><i class='fal fa-unlink'></i></div>";
        if (params.data.link) {
          html = "<div class='link-column link-icon'><i class='fal fa-link'></i></div>";
        }
        return html;
        
      }
    });
    this.createDateColumns(this);
  }
  


  public getStyle(isQuickMode:boolean,status: any ,activeInd: number, enrolledInd: boolean, confirmedInd: boolean): string {
    let cssClass: string = "bg_none";
    if (_.isEqual(activeInd,0)) {
      if (enrolledInd) {
        cssClass ="bg_blue";
      }
      else {
        cssClass ="bg_orange";
      }

    }
    else {
      if (_.isEqual(activeInd,-1)) {
        cssClass = "bg_solitude";
      }
      else if (_.isEqual(activeInd,1)) {
        cssClass = "bg_brown";
      }
      else {
        cssClass = "bg_none";
      }      
    }
    if (_.isEqual(status,state1095CValue.confirmed)) {
      cssClass = cssClass + ' r_bold';
    }
    else if(_.isEqual(status,state1095CValue.edited_confirmed))
    {
      cssClass = cssClass + ' r_italic r_bold';
    }
    else if(_.isEqual(status,state1095CValue.edited))
    {
      cssClass = cssClass + ' r_italic';
    }
    if (isQuickMode) {
      cssClass = cssClass + ' r_quick_edit';
    }
    
    return cssClass;
  }
  public getFormatedValue(value: string,type:string): string {
    if (value) {
      if(_.isEqual(type,'$'))
      return '$' + value;
      else
      return value;
    }
    else {
      if(_.isEqual(type,'$'))
      return '_______';
      else
      return '___';
    }
   
  }

  public getLine17Value(line14:string,zip:string):string{
    let zipHtml:string="";
    if(_.includes(ACA1095cConfig.line14ValueForLine17,line14))
    {
      zipHtml="<div class='gc_option'>Zip: "+zip+"</div>";
    }
    return zipHtml;
  }

  private createDateColumns(elm): void {
    for (let month: number = 0; month < this.state.numMonths; month++) {
      this.columnDefs.push({
        'headerName': moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'),
        'field': moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'),
        'width': 114,
        'resizable': false,
        'headerClass':'ag-custom-header',  
        headerComponent: 'aca1095cGridColumnHeaderComponent',
        headerComponentParams: {
            link: this.linkMonths,
            name:moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM')
        } ,     
        'cellClass': function (params) {
          let monthName = moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM');
          let monthsRecords: AcaMonthRecord[] = params.data.months as AcaMonthRecord[];
          let selectedMonth: AcaMonthRecord = null;
          let selectedMonthRecords: any[] = _.filter(monthsRecords, (monthsRecord) => {
            if (monthsRecord.monthName == monthName) {
              return monthsRecord;
            }

          });
          if (selectedMonthRecords.length > 0) {
            selectedMonth = selectedMonthRecords[0];
          }
          return elm.getStyle(selectedMonth.isQuickMode,selectedMonth.status, selectedMonth.activeInd, selectedMonth.enrolledInd, selectedMonth.confirmedInd)+' ag-custom-cell';
        },
        'cellRenderer': function (params) {
         
          let monthName = moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM');
          let monthsRecords: AcaMonthRecord[] = params.data.months as AcaMonthRecord[];         
          let selectedMonth: AcaMonthRecord=null;
          let selectedMonthRecords: any[] = _.filter(monthsRecords, (monthsRecord) => {          
            if (monthsRecord.monthName == monthName) {
              return monthsRecord;
            }
          });
          if (selectedMonthRecords.length > 0) {
            selectedMonth = selectedMonthRecords[0];
          }
          if(selectedMonth.quickEdit || selectedMonth.quickConfirm || selectedMonth.quickEditReset || selectedMonth.quickConfirmReset)
          {
            return `<div class='gc_data alt_undo'><div><em>${elm.getFormatedValue(selectedMonth.displayCode,'_')}</em></div><div class='min-width'><em>${elm.getFormatedValue(selectedMonth.displayCost,'$')}</em></div><div><em> ${elm.getFormatedValue(selectedMonth.displayHarbor,'')}</em></div><div><em class="fas fa-undo-alt"></em></div></div><div class='gc_option'>${selectedMonth.displayOption}</div><div class='gc_option'>${selectedMonth.displayTier}</div>${elm.getLine17Value(selectedMonth.displayCode,selectedMonth.selZip)}`;
          }
          else
          {
            return `<div class='gc_data'><div><em>${elm.getFormatedValue(selectedMonth.displayCode,'_')}</em></div><div class='min-width'><em>${elm.getFormatedValue(selectedMonth.displayCost,'$')}</em></div><div><em> ${elm.getFormatedValue(selectedMonth.displayHarbor,'')}</em></div></div><div class='gc_option'>${selectedMonth.displayOption}</div><div class='gc_option'>${selectedMonth.displayTier}</div>${elm.getLine17Value(selectedMonth.displayCode,selectedMonth.selZip)}`;
          }
          

        }
      });
    }
  }
}
