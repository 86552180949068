export interface IAppSettingsConfig {
  api: {
    app: {
      root: string;
      settings: string;
    }
  };
}

export const appSettingsConfig: IAppSettingsConfig = {
  api: {
    app: {
      root: 'app',
      settings: 'settings'
    }
  }
};
