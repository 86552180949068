import { Employer } from './employers.model';
import { AleGroup } from './ale-group';

export class IEmployersContainer {
  records: any[];
  canEditEmployersConfig: boolean;
  canExportToExcel: boolean;
}

export class EmployersContainer {
  public records: Employer[];
  public canEditEmployersConfig: boolean;
  public canExportToExcel: boolean;
  public aleGroupList: AleGroup[];
}


