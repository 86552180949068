/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./navigation-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/directives/slx-tooltip/slx-tooltip.directive";
import * as i4 from "../../directives/menu-item-scroll/menu-item-scroll.directive";
import * as i5 from "../favorites-menu/favorites-menu.component.ngfactory";
import * as i6 from "../favorites-menu/favorites-menu.component";
import * as i7 from "../../../configuration/services/favorites/favorites-management.service";
import * as i8 from "../../services/navigation/navigation-menu-redirect.service";
import * as i9 from "../../../common/directives/navigation-link/home-link.directive";
import * as i10 from "@angular/router";
import * as i11 from "../../../common/services/change-management/change-management.service";
import * as i12 from "../../../common/services/navigation/restore-query-params.service";
import * as i13 from "../../services/navigation/navigation-menu-management.service";
import * as i14 from "../../services/application-state-bus/application-state-bus.service";
import * as i15 from "../../services/navigation/navigation-menu-api.service";
import * as i16 from "./navigation-menu.component";
import * as i17 from "../../services/navigation/message-center-config.service";
import * as i18 from "../../services/navigation/ben-admin/ben-admin-menu-management.service";
import * as i19 from "../../../app-settings/services/app-settings-manage.service";
var styles_NavigationMenuComponent = [i0.styles];
var RenderType_NavigationMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NavigationMenuComponent, data: {} });
export { RenderType_NavigationMenuComponent as RenderType_NavigationMenuComponent };
function View_NavigationMenuComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "top-nav-link real-nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectGlobalMenuItem(_v.parent.context.$implicit, null, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "top-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "top-nav-icon fa ", _v.parent.context.$implicit.menuItemMapping.icon, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.displayName; _ck(_v, 3, 0, currVal_1); }); }
function View_NavigationMenuComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "a", [["class", "top-nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleTopItem(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "top-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "i", [["aria-hidden", "true"], ["class", "top-caret-icon fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "fa-angle-left": 0, "fa-angle-down": 1 })], function (_ck, _v) { var currVal_2 = "top-caret-icon fa"; var currVal_3 = _ck(_v, 7, 0, !_v.parent.context.$implicit.isExpanded, _v.parent.context.$implicit.isExpanded); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "top-nav-icon fa ", _v.parent.context.$implicit.menuItemMapping.icon, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.displayName; _ck(_v, 3, 0, currVal_1); }); }
function View_NavigationMenuComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "top-nav-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "top-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "top-nav-header-selected-mark"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.displayName; _ck(_v, 2, 0, currVal_0); }); }
function View_NavigationMenuComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["tipPosition", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i3.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.unavailableText, ""); var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NavigationMenuComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "a", [["class", "group-nav-link real-nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectGlobalMenuItem(_v.parent.parent.parent.context.$implicit, _v.parent.context.$implicit, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "group-nav-global-link": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "group-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "group-selected-mark"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_9)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group-nav-link real-nav-link"; var currVal_1 = _ck(_v, 3, 0, _v.parent.context.$implicit.isGlobal); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _co.isDisabled(_v.parent.context.$implicit); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "group-nav-icon ", _v.parent.context.$implicit.menuItemMapping.icon, ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.displayName; _ck(_v, 6, 0, currVal_3); }); }
function View_NavigationMenuComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "group-nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleGroupItem(_v.parent.parent.parent.context.$implicit, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "open": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["aria-hidden", "true"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "group-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "group-caret-icon fa"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "span", [["class", "group-selected-mark"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "group-nav-link"; var currVal_1 = _ck(_v, 3, 0, (_v.parent.context.$implicit.isSelected || _v.parent.context.$implicit.isHovered)); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "group-nav-icon ", _v.parent.context.$implicit.menuItemMapping.icon, ""); _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.displayName; _ck(_v, 6, 0, currVal_3); }); }
function View_NavigationMenuComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["tipPosition", "bottom"]], null, null, null, null, null)), i1.ɵdid(1, 4341760, null, 0, i3.SlxTooltipDirective, [i1.ElementRef, i1.Renderer2], { slxTooltip: [0, "slxTooltip"], tipPosition: [1, "tipPosition"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.unavailableText, ""); var currVal_1 = "bottom"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NavigationMenuComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "menu-item-nav"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "disabled": 0, "hidden": 1, "last-visible": 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "a", [["class", "item-nav-link real-nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectMenuItem(_v.parent.parent.parent.parent.parent.context.$implicit, _v.parent.parent.parent.context.$implicit, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_14)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "item-mark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "item-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "menu-item-nav"; var currVal_1 = _ck(_v, 3, 0, _co.isDisabled(_v.parent.context.$implicit), _co.isHidden(_v.parent.context.$implicit), _co.isLastVisibleItem(_v.parent.parent.parent.context.$implicit, _v.parent.context.index)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.isDisabled(_v.parent.context.$implicit); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.displayName; _ck(_v, 9, 0, currVal_3); }); }
function View_NavigationMenuComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_13)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMenuItemEnabled(_v.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_NavigationMenuComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ul", [["class", "slx-item-menu"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "li", [["class", "group-nav-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "group-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "group-nav-header-selected-mark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8929280, null, 0, i4.MenuItemScrollDirective, [i1.ElementRef, i1.Renderer2], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_12)), i1.ɵdid(10, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-item-menu"; var currVal_1 = ((_co.screenWidth < 781) ? "no-margin" : ""); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (((!_co.isOpened && _v.parent.context.$implicit.isSelected) || _v.parent.context.$implicit.isHovered) || (!_co.isMobile() && _co.isExpanded(_v.parent.context.$implicit))); _ck(_v, 8, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.childs; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.displayName; _ck(_v, 5, 0, currVal_2); }); }
function View_NavigationMenuComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "group-nav-item"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuMouseEnter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuMouseLeave(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "expanded": 0, "expanded-scroll": 1, "disabled": 2, "hidden": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_10)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_11)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "group-nav-item"; var currVal_1 = _ck(_v, 3, 0, _co.isExpanded(_v.context.$implicit), _co.isExpanded(_v.context.$implicit), _co.isDisabled(_v.context.$implicit), _co.isHidden(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ((_v.context.$implicit.childs.length <= 0) && _co.isMenuItemEnabled(_v.context.$implicit)); _ck(_v, 5, 0, currVal_2); var currVal_3 = (_v.context.$implicit.childs.length > 0); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_v.context.$implicit.childs.length > 0); _ck(_v, 9, 0, currVal_4); }, null); }
function View_NavigationMenuComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ul", [["class", "slx-group-menu"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "slx-group-menu-global": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_6)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 8929280, null, 0, i4.MenuItemScrollDirective, [i1.ElementRef, i1.Renderer2], { enabled: [0, "enabled"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_7)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-group-menu"; var currVal_1 = _ck(_v, 3, 0, (_v.parent.context.$implicit.isGlobal && (!_v.parent.context.$implicit.childLevelsCount === 3))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.isGlobal && (!_v.parent.context.$implicit.childLevelsCount === 3)); _ck(_v, 5, 0, currVal_2); var currVal_3 = ((!_co.isOpened && _v.parent.context.$implicit.isHovered) && _v.parent.context.$implicit.isGlobal); _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.childs; _ck(_v, 9, 0, currVal_4); }, null); }
function View_NavigationMenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "li", [["class", "top-nav-item"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuMouseEnter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuMouseLeave(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onLeftNavScroll() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "disabled": 0, "expanded": 1, "selected": 2, "hidden": 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "top-nav-item"; var currVal_1 = _ck(_v, 3, 0, _co.isDisabled(_v.context.$implicit), _co.isExpanded(_v.context.$implicit), _v.context.$implicit.isHovered, _co.isHidden(_v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (((_v.context.$implicit.childs == null) ? null : _v.context.$implicit.childs.length) <= 0); _ck(_v, 5, 0, currVal_2); var currVal_3 = (((_v.context.$implicit.childs == null) ? null : _v.context.$implicit.childs.length) > 0); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_v.context.$implicit.childs.length > 0); _ck(_v, 9, 0, currVal_4); }, null); }
function View_NavigationMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["slxNavMenu", 1]], null, 18, "ul", [["class", "slx-navigation-menu slx-navigation-menu-ignore"]], null, [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.onLeftNavScroll() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "menu-opened": 0, "mobile": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "li", [["class", "top-nav-item dashboard-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "top-nav-link real-nav-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggleFavoritesView($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "top-nav-icon star fas fa-star"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "star top-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Favorites"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "slx-favorites-menu", [], null, null, null, i5.View_FavoritesMenuComponent_0, i5.RenderType_FavoritesMenuComponent)), i1.ɵdid(10, 4308992, null, 0, i6.FavoritesMenuComponent, [i1.Renderer2, i1.ElementRef, i7.FavoriteManagementService, i8.NavigationMenuRedirectService], { show: [0, "show"], top: [1, "top"], left: [2, "left"], pointerIconShift: [3, "pointerIconShift"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "li", [["class", "top-nav-item dashboard-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "a", [["class", "top-nav-link real-nav-link"], ["slxHomeLink", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i9.HomeLinkDirective, [i10.Router, i10.ActivatedRoute, i11.ChangeManagementService, i12.RestoreQueryParamsService], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "top-nav-icon fas fa-chart-pie"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["class", "top-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Dashboard"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_2)), i1.ɵdid(18, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "slx-navigation-menu slx-navigation-menu-ignore"; var currVal_1 = _ck(_v, 3, 0, _co.isOpened, _co.isMobile()); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.showFavorites; var currVal_3 = _co.posTop; var currVal_4 = _co.posLeft; var currVal_5 = _co.iconShift; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.menuItems; _ck(_v, 18, 0, currVal_6); }, null); }
function View_NavigationMenuComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "no-data"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No Data "]))], null, null); }
export function View_NavigationMenuComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { navMenu: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "slx-navigation-menu-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_NavigationMenuComponent_15)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.noData; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.noData; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NavigationMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-navigation-menu", [], null, [["window", "resize"], ["document", "keyup"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).getScreenSize($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keyup" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).pressEscape($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).clickOutside($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_NavigationMenuComponent_0, RenderType_NavigationMenuComponent)), i1.ɵprd(512, null, i13.NavigationMenuManagementService, i13.NavigationMenuManagementService, [i14.ApplicationStateBusService, i15.NavigationMenuApiService]), i1.ɵdid(2, 638976, null, 0, i16.NavigationMenuComponent, [i13.NavigationMenuManagementService, i17.MessageCenterConfigService, i14.ApplicationStateBusService, i18.BenAdminMenuManagementService, i8.NavigationMenuRedirectService, i1.ElementRef, i1.Renderer2, i19.AppSettingsManageService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var NavigationMenuComponentNgFactory = i1.ɵccf("slx-navigation-menu", i16.NavigationMenuComponent, View_NavigationMenuComponent_Host_0, { isOpened: "isOpened" }, {}, []);
export { NavigationMenuComponentNgFactory as NavigationMenuComponentNgFactory };
