import { Injectable } from '@angular/core';
import { LookupMultiselectModel } from '../../../common/models/index';
import {
  Lookup, ShiftDefinition, ShiftGroupDefinition, LocationUnit
} from '../../../organization/models/index';
import { ParLevels, WeeklyDayCounter, IdealSchedulePosition } from '../../models/ideal-schedule/index';
import * as _ from 'lodash';
import { DayOfWeek, WeekDaysWithEmpty } from '../../../common/models/index';
import * as moment from 'moment';
import { IdealSchedulePositionRange } from '../../models/ideal-schedule/ideal-schedule-position-range';

@Injectable()
export class IdealScheduleHelperService {

  public createParLevel(uniqueId: string, shiftGroup: ShiftGroupDefinition, shift: ShiftDefinition, unit: LocationUnit): ParLevels {
    let shiftLength: number = shift ? shift.durationHours : 8;
    return new ParLevels({
      id: 0,
      uniqueId: uniqueId,
      shiftGroup: shiftGroup,
      shift: shift,
      unit: unit,
      days: this.createDays(shiftLength)
    });
  }

  public createDays(shiftLength: number): NumberMap<WeeklyDayCounter> {
    let days: NumberMap<WeeklyDayCounter> = [];
    WeekDaysWithEmpty.forEach((day: DayOfWeek) => {
      days[day.dayNumber] = new WeeklyDayCounter(day, 1, shiftLength);
    });
    return days;
  }

  public getParLevelsForShiftGroup(
    originalParLevels: StringMap<ParLevels>,
    shiftsGroups: LookupMultiselectModel[]): StringMap<ParLevels> {

    let parLevels: StringMap<ParLevels> = {};
    //add new
    shiftsGroups.forEach((model: LookupMultiselectModel) => {
      let filteredArray: ParLevels[] = _.filter(originalParLevels, (par: ParLevels) => {
        return par.shiftGroup.name === model.name;
      });

      let key: string = `${model.object.name}-0-0`;
      parLevels[key] = this.groupParLevel(key, model.object, null, filteredArray);
    });

    return parLevels;
  }

  public getParLevelsForShift(
    originalParLevels: StringMap<ParLevels>,
    selectedShifts: LookupMultiselectModel[]): StringMap<ParLevels> {
    if (!selectedShifts.length) {
      return null;
    }

    let parLevels: StringMap<ParLevels> = _.keyBy(originalParLevels, (par: ParLevels) => {
      let key: string = null;
      let hasShift: boolean = selectedShifts.some((model: LookupMultiselectModel) => par.shift && model.name === par.shift.name);
      if (hasShift) {
        key = par.shiftGroup.name + '-' + (par.shift ? par.shift.name : '0') + '-0';
      }
      return key;
    });
    delete parLevels['null'];

    //add new
    selectedShifts.forEach((model: LookupMultiselectModel) => {
      let filteredArray: ParLevels[] = _.filter(originalParLevels, (par: ParLevels) => {
        return par.shift && par.shift.name === model.name;
      });

      let key: string = `${model.object.group.name}-${model.object.name}-0`;
      parLevels[key] = this.groupParLevel(key, model.object.group, model.object, filteredArray);
    });

    return parLevels;
  }

  public getParLevelsForShiftAndUnit(
    originalParLevels: StringMap<ParLevels>,
    selectedShifts: LookupMultiselectModel[],
    selectedUnits: LookupMultiselectModel[]
  ): StringMap<ParLevels> {
    if (!selectedShifts.length || !selectedUnits.length) {
      return null;
    }

    // remove un-selected
    let parLevels: StringMap<ParLevels> = _.keyBy(originalParLevels, (par: ParLevels) => {
      let key: string = null;
      let hasShift: boolean = selectedShifts.some((model: LookupMultiselectModel) => par.shift && model.name === par.shift.name);
      let hasUnit: boolean = selectedUnits.some((model: LookupMultiselectModel) => par.unit && model.name === par.unit.name);
      if (hasShift && hasUnit) {
        key = par.shiftGroup.name + '-' + (par.shift ? par.shift.name : '0') + '-' + (par.unit ? par.unit.name : '0');
      }
      return key;
    });
    delete parLevels['null'];

    //add new
    if (selectedShifts && selectedUnits) {
      selectedShifts.forEach((shiftModel: LookupMultiselectModel) => {
        selectedUnits.forEach((unitModel: LookupMultiselectModel) => {
          let key: string = `${shiftModel.object.group.name}-${shiftModel.object.name}-${unitModel.object.name}`;
          let item: any = parLevels[key];

          if (!item) {
            parLevels[key] = this.createParLevel(key, shiftModel.object.group, shiftModel.object, unitModel.object);
          } else {
            item.uniqueId = key;
          }
        });
      });
    }

    return parLevels;
  }

  public filterShiftGroupLookup(shiftGroupLookup: Lookup): LookupMultiselectModel[] {
    if (!shiftGroupLookup || !shiftGroupLookup.items) {
      return [];
    }
    return shiftGroupLookup.items.map((item: any) => {
      return new LookupMultiselectModel({
        isSelected: true,
        id: item.id.toString(),
        name: item.name,
        object: item
      });
    }
    );
  }

  public filterShiftLookup(parLevels: ParLevels[], shiftLookup: Lookup, shiftGroupName: string = null): LookupMultiselectModel[] {
    if (!shiftLookup || !shiftLookup.items) {
      return [];
    }

    let filteredShifts = shiftLookup.items;

    if (shiftGroupName) {
      filteredShifts = filteredShifts.filter((item: ShiftDefinition) => {
        return item.group && item.group.name === shiftGroupName;
      });
    }

    let shifts: LookupMultiselectModel[] = filteredShifts.map((item: ShiftDefinition) => {
      let isSelected: boolean = parLevels !== null ?
        parLevels.some((par: ParLevels) => par.shift && par.shift.name === item.name) : false;
      return new LookupMultiselectModel({
        isSelected: isSelected,
        id: item.id.toString(),
        name: item.name,
        object: item
      });
    }
    );

    return shifts;
  }

  public filterUnitLookup(parLevels: ParLevels[], unitLookup: Lookup): LookupMultiselectModel[] {
    if (!unitLookup || !unitLookup.items) {
      return [];
    }
    return unitLookup.items.map((item: any) => {
      let isSelected: boolean = parLevels !== null ?
        parLevels.some((x: ParLevels) => x.unit && x.unit.name === item.name) : false;
      return new LookupMultiselectModel({
        isSelected: isSelected,
        id: item.id.toString(),
        name: item.name,
        object: item
      });
    }
    );
  }

  public getSelectedShiftsFromParLevels(parLevels: ParLevels[]): LookupMultiselectModel[] {
    return this.multiLookupFromParLevels(parLevels, (parLevel: ParLevels) => {
      return new LookupMultiselectModel(parLevel.shift ? {
        isSelected: true,
        id: parLevel.shift.id.toString(),
        name: parLevel.shift.name,
        object: parLevel.shift
      }: null);
    });
  }

  public getSelectedShiftGroupsFromParLevels(parLevels: ParLevels[]): LookupMultiselectModel[] {
    return this.multiLookupFromParLevels(parLevels, (parLevel: ParLevels) => {
      return new LookupMultiselectModel(parLevel.shiftGroup ? {
        isSelected: true,
        id: parLevel.shiftGroup.id.toString(),
        name: parLevel.shiftGroup.name,
        object: parLevel.shiftGroup
      }: null);
    });
  }

  public getSelectedUnitsFromParLevels(parLevels: ParLevels[]): LookupMultiselectModel[] {
    return this.multiLookupFromParLevels(parLevels, (parLevel: ParLevels) => {
      return new LookupMultiselectModel(parLevel.unit ? {
        isSelected: true,
        id: parLevel.unit.id.toString(),
        name: parLevel.unit.name,
        object: parLevel.unit
      }: null);
    });
  }

  public multiLookupFromParLevels(parLevels: ParLevels[], callback: (parLevel: ParLevels) => LookupMultiselectModel): LookupMultiselectModel[] {
    if (!parLevels || !parLevels.length) {
      return [];
    }

    let arr: LookupMultiselectModel[] = _.map(parLevels, (item: ParLevels) => {
      return callback(item);
    });

    return _.uniqBy(arr, (item: LookupMultiselectModel) => item.id);
  }

  public isCurrentSchedule(position: IdealSchedulePosition): boolean {
    return moment(position.startDate).isSameOrBefore(new Date())
      && moment(position.endDate).isSameOrAfter(new Date());
  }

  public calculateDayCounterHours(schedule: IdealSchedulePositionRange, dayNumber: number): number {
    //return schedule.counters[dayNumber] = _.sumBy(schedule.parLevels, (level:ParLevels) => (level.days[dayNumber] ? level.days[dayNumber].hours : 0));
    schedule.counters[dayNumber] = _.reduce(schedule.parLevels, (sum: number, level: ParLevels) => {
      return sum + (level.days[dayNumber] ? level.days[dayNumber].hours : 0);
    }, 0);
    schedule.counters[dayNumber] = _.round(schedule.counters[dayNumber], 2);
    return schedule.counters[dayNumber];
  }

  public getDayHours(schedule: IdealSchedulePositionRange, parLevelUniqueId: string, dayNumber: number): number {
    if (!schedule.parLevels[parLevelUniqueId]) {
      return 0;
    }
    return schedule.parLevels[parLevelUniqueId].days[dayNumber].hours;
  }

  public getDayCounter(schedule: IdealSchedulePositionRange, parLevelUniqueId: string, dayNumber: number): number {
    if (!schedule.parLevels[parLevelUniqueId]) {
      return 0;
    }
    return schedule.parLevels[parLevelUniqueId].days[dayNumber].counter;
  }

  public getDailyTotalHours(schedule: IdealSchedulePositionRange, dayNumber: number): number {
    return schedule.counters[dayNumber];
  }

  private groupParLevel(key: string, group: ShiftGroupDefinition, shift: ShiftDefinition, filteredArray: ParLevels[]): ParLevels {
    let level: ParLevels;
    if (filteredArray.length > 0) {
      let item: ParLevels = filteredArray[0];

      let duration: number = item.shift ? item.shift.durationHours : item.shiftGroup.durationHours;
      _.forOwn(item.days, (day: WeeklyDayCounter) => {
        if (day.day) {
          let sum: number = _.sumBy(filteredArray, (value: ParLevels) => value.getDayCounter(day.day.dayNumber));
          item.setDayCounter(day.day.dayNumber, sum);
          item.setDayHours(day.day.dayNumber, duration * sum);
        }
      });
      item.uniqueId = key;
      item.shift = shift ? shift : null;
      item.unit = null;
      level = item;
    } else {
      level = this.createParLevel(key, group, shift, null);
    }
    return level;
  }
}
