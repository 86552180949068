export interface IAcaEmployers {
  companyId: number;
  legalName: string;
  aleGroupId: number;
  aleMembers: number;
  tccCode: string;
  deleted: boolean;
}

export class AcaEmployers {
  public companyId: number;
  public legalName: string;
  public aleGroupId: number;
  public aleMembers: number;
  public tccCode: string;
  public deleted: boolean;
}
