import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ApiUtilService, UrlParamsService } from '../../../common/index';
import { appConfig } from '../../../app.config';
import { Meta } from '../../../core/models/api/meta';
import { ResponseBody } from '../../../core/models/api/response-body';

import { RoleDefinition } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { OrgLevelWatchService } from '../../../organization/services/index';
import { configurationConfig } from '../../configuration.config';

import { UserRolesMapService } from './user-roles-map.service';
import { RolesApiService } from '../roles/roles-api.service';
import { RolesMapService } from '../roles/roles-map.service';
import { RoleAssignementsWrapper, UserRecord, IUserRecordDTO, IUsersRolesDTO, UsersRoles } from '../../models/index';

@Injectable()
export class UserRolesApiService {

    constructor(
        private rolesApi: RolesApiService,
        private rolesMap: RolesMapService,
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private mapService: UserRolesMapService,
        private orgLevelWatchService: OrgLevelWatchService) {
    }

    public getRoles(): Promise<RoleAssignementsWrapper[]> {

        return new Promise((resolve, reject) => {
            this.rolesApi.getRoles().then((roles: RoleDefinition[]) => {
                let records: RoleAssignementsWrapper[] = [];
                _.each(roles, (role: RoleDefinition) => {
                    let wrapper: RoleAssignementsWrapper = this.mapService.mapToRoleAsignementWrapper(this.rolesMap.mapRoleDefitinionDto(role));
                    records.push(wrapper);
                });
                resolve(records);
            }).catch((result: any) => {
                reject();
            });
        });
    }
    
    public getUsersWithRolesOrglevel(orgLevelId: number): Promise<UsersRoles> {
        const url: string = `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.users.usersWithRoles}/${orgLevelId}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {});

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IUsersRolesDTO, Meta>) => {
            return this.mapService.mapUsersRolesRecord(response.data);
        });
    }

    public getRoleUsers(roleId: number): Promise<UserRecord[]> {
        const url: string = `${this.getRolesApiRoot()}/${roleId}/${configurationConfig.api.configuration.roles.users}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, {});

        return this.apiUtilService.request<any, Meta>(request).then((response: ResponseBody<IUserRecordDTO[], Meta>) => {
            return this.mapService.mapUserRecords(response.data);
        });
    }

    private getRolesApiRoot(): string {
        return `${this.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.roles.root}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }
}
