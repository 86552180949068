<span>default editor</span>
<!--   
    TODO: make default editor based on explicit valueType like this

<ng-container [ngSwitch]="valueType">
    <input ngSwitchCase="'numeric'" type="number" class="k-textbox" [formControl]="formGroup.get(column.field)" />
    <input ngSwitchCase="'date'" type="date" class="k-textbox" [formControl]="formGroup.get(column.field)" />
    <input ngSwitchCase="'boolean'" type="checkbox" [formControl]="formGroup.get(column.field)" />
    <input ngSwitchDefault type="text" class="k-textbox" [formControl]="formGroup.get(column.field)" />
</ng-container>

-->