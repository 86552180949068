import { Injectable } from '@angular/core';
import { IServerValidatorAdapter, IServerValidationResult } from '../../../common/validators/common-validators-models';
import { AgencyEmployeeApiService } from './agency-employee-api.service';
import { ValidateAgencyEmployeeReq } from '../../models/index';
import * as _ from 'lodash';

@Injectable()
export class AgencyEmployeeValidatorAdapter implements IServerValidatorAdapter {
  public static agencyEmployeeAddValidation: string = 'agencyEmployeeAddValidation';

  constructor(private apiService: AgencyEmployeeApiService) {

  }
  public validate(validationName: string, value: any, ...params: any[]): Promise<IServerValidationResult> {
    let promise: Promise<IServerValidationResult>;
    let defPromise: Promise<IServerValidationResult> = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
    switch (validationName) {
      case AgencyEmployeeValidatorAdapter.agencyEmployeeAddValidation:
        let req: ValidateAgencyEmployeeReq = value;
        promise = this.apiService.validateAddAgencyEmployee(req);
        break;
      default:
        promise = defPromise;
    }
    return promise;
  }
}
