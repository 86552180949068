import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { OrgLevel } from '../../../state-model/models/index';
import { DateRange } from '../../../core/models/index';
import { PmReviewRecord, PmReviewRecords } from '../models/index';
import { PmManagementService } from './pm-management.service';
import { PerformanceManagementApiService } from './performance-management-api.service';
var PmRosterManagementService = /** @class */ (function () {
    function PmRosterManagementService(manService, apiService) {
        this.manService = manService;
        this.apiService = apiService;
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.dateRange$ = new Subject();
        this.exportTo$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.createNewReview$ = new Subject();
        this.subscriptions = {};
    }
    PmRosterManagementService.prototype.init = function () {
        this.subscribeToOrgLevelChanges();
        this.subscribeToLoadReviews();
    };
    PmRosterManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.dateRange$.complete();
        this.exportTo$.complete();
        this.orgLevelChanged$.complete();
        this.createNewReview$.complete();
    };
    PmRosterManagementService.prototype.getDefaultDateRange = function () {
        var d = new Date();
        return new DateRange(new Date(d.getFullYear() - 1, d.getMonth(), d.getDate() + 1), d);
    };
    PmRosterManagementService.prototype.subscribeToCanCreateNewReview = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.createNewReview$.subscribe(callback);
    };
    PmRosterManagementService.prototype.openReviewPopup = function (review) {
        var title = (_.isObjectLike(review) ? 'Edit' : 'Create New') + " Review";
        this.manService.openReviewPopup(title, review);
    };
    PmRosterManagementService.prototype.changeDateRange = function (r) {
        this.dateRange = r;
        this.dateRange$.next(r);
        this.loadReviews();
    };
    PmRosterManagementService.prototype.subscribeToDateRange = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.dateRange$.subscribe(callback);
    };
    PmRosterManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    PmRosterManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    PmRosterManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    PmRosterManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    PmRosterManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    PmRosterManagementService.prototype.loadReviews = function () {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))
            || !_.isDate(_.get(this.dateRange, 'startDate'))
            || !_.isDate(_.get(this.dateRange, 'endDate')))
            return;
        this.loading$.next(true);
        this.apiService.getReviewRecords(this.orgLevel.id, this.dateRange.startDate, this.dateRange.endDate)
            .then(function (records) {
            _this.createNewReview$.next(records.canCreateNewReview);
            _this.recordsLoaded$.next(records.records);
            _this.loading$.next(false);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    PmRosterManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            _this.loadReviews();
        });
    };
    PmRosterManagementService.prototype.subscribeToLoadReviews = function () {
        var _this = this;
        this.subscriptions.loadReviews = this.manService.subscribeToLoadReviews(function () { return _this.loadReviews(); });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PmRosterManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], PmRosterManagementService.prototype, "subscriptions", void 0);
    return PmRosterManagementService;
}());
export { PmRosterManagementService };
