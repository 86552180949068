import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { appConfig } from '../../../../app.config';
import { PositionModel, EditableListActionKind } from '../../../models/index';
import { CustomListActorBase } from '../../editableList/custom-list-actor.base';
var ConfigurePositionRendererComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigurePositionRendererComponent, _super);
    function ConfigurePositionRendererComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.editable = true;
        _this.selectable = true;
        return _this;
    }
    Object.defineProperty(ConfigurePositionRendererComponent.prototype, "context", {
        set: function (value) {
            this.item = value.item;
            this.rowIndex = value.rowIndex;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurePositionRendererComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    ConfigurePositionRendererComponent.prototype.onEditClick = function () {
        this.actionEmitter.emit(EditableListActionKind.START_EDIT);
    };
    ConfigurePositionRendererComponent.prototype.onItemSelect = function () {
        if (this.item)
            this.item.isSelected = !this.item.isSelected;
        this.actionEmitter.emit(EditableListActionKind.SELECTION_CHANGE);
    };
    ConfigurePositionRendererComponent.prototype.onDeletedItems = function () {
        this.actionEmitter.emit(EditableListActionKind.REMOVE);
    };
    return ConfigurePositionRendererComponent;
}(CustomListActorBase));
export { ConfigurePositionRendererComponent };
