import { Injectable } from '@angular/core';
import { IAppState } from '../../../store';
import { NgRedux } from '@angular-redux/store';
import { OrganizationTreeContext, ScheduleCycle } from '../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelActions } from '../../../organization/actions/index';

@Injectable()
export class IndividualScheduleActions {
  public static INDIVIDUAL_SCHEDULE_SELECT_ITEM: string = 'INDIVIDUAL_SCHEDULE_SELECT_ITEM';
  public static INDIVIDUAL_SCHEDULE_SELECT_CYCLE: string = 'INDIVIDUAL_SCHEDULE_SELECT_CYCLE';

  private ngRedux: NgRedux<IAppState>;
  private orgLevelActions: OrgLevelActions;
  constructor(ngRedux: NgRedux<IAppState>, orgLevelActions: OrgLevelActions) {
    this.ngRedux = ngRedux;
    this.orgLevelActions = orgLevelActions;
  }

  public selectItem(employeeId: number): void {
    let state: any = this.ngRedux.getState();
    let orgLevelId: string = state.orgLevel.get('id').toString();
    this.ngRedux.dispatch({
      type: IndividualScheduleActions.INDIVIDUAL_SCHEDULE_SELECT_ITEM,
      payload: { orgLevelId: orgLevelId, employeeId: employeeId }
    });
  }

  public selectScheduleCycle(cycle: ScheduleCycle): void {
    let state: any = this.ngRedux.getState();
    let orgLevelId: string = state.orgLevel.get('id').toString();
    this.ngRedux.dispatch({
      type: IndividualScheduleActions.INDIVIDUAL_SCHEDULE_SELECT_CYCLE,
      payload: { orgLevelId: orgLevelId, cycle: cycle }
    });
  }
}
