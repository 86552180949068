import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeAccrual = /** @class */ (function () {
    function EmployeeAccrual() {
    }
    return EmployeeAccrual;
}());
export { EmployeeAccrual };
var EmployeeSectionAccrualsBalances = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionAccrualsBalances, _super);
    function EmployeeSectionAccrualsBalances() {
        var _this = _super.call(this) || this;
        _this.records = [];
        return _this;
    }
    return EmployeeSectionAccrualsBalances;
}(EmployeeSectionsBase));
export { EmployeeSectionAccrualsBalances };
