import { BenefitDetailsGroupBasic } from './benefit-details-group-basic';
var BenefitDetailsGroup = /** @class */ (function () {
    function BenefitDetailsGroup() {
        this.group = new BenefitDetailsGroupBasic();
        this.calcMethods = [];
    }
    Object.defineProperty(BenefitDetailsGroup.prototype, "id", {
        get: function () {
            return this.group.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsGroup.prototype, "name", {
        get: function () {
            return this.group.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsGroup.prototype, "title", {
        get: function () {
            return this.group.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsGroup.prototype, "type", {
        get: function () {
            return this.group.type;
        },
        enumerable: true,
        configurable: true
    });
    return BenefitDetailsGroup;
}());
export { BenefitDetailsGroup };
