export class EmployeeSectionConfig {
  //scroll container
  //if null or undefined = component itself
  public scrollRootId: string;
  //scroll offset for navigation
  public offsetScroll: number;
  //navigation menu offset
  public topOffsetMenu: number;
}

export const employeeSectionConfig: EmployeeSectionConfig = {
  scrollRootId: 'body',
  offsetScroll: 110,
  topOffsetMenu: 57
};

