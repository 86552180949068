import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { IPayloadAction } from '../../state-model/models/index';
import { SessionActions } from '../actions/index';
import { Observable } from 'rxjs/Observable';
import { ComponentStateStorageService } from '../../common/services/index';
import { StateResetTypes } from '../../core/models/index';

@Injectable()
export class SessionEpics {
  constructor(private router: Router, private storageService: ComponentStateStorageService) {
  }

  public clearSession = (action$: Observable<IPayloadAction>): Observable<IPayloadAction> => {
    return action$.filter(({ type }: { type: string }) => type === SessionActions.START_CLEAR_SESSION)
      .mergeMap((action: IPayloadAction) => {
        this.storageService.clearComponentStates(StateResetTypes.SessionEnd);
        return Observable.of({
          type: SessionActions.CLEAR_SESSION
        });
      });
  }
}
