/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./org-sync.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../organizations/organizations.component.ngfactory";
import * as i3 from "../organizations/organizations.component";
import * as i4 from "../employee-sync/employee-sync.component.ngfactory";
import * as i5 from "../employee-sync/employee-sync.component";
import * as i6 from "../../../services/wfm/wfm-sync.service";
import * as i7 from "../../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
import * as i8 from "../../../../common/services/busy/busy.service";
import * as i9 from "../benefitdeduction-sync/benefitdeduction-sync.component.ngfactory";
import * as i10 from "../benefitdeduction-sync/benefitdeduction-sync.component";
import * as i11 from "../laborcode-sync/laborcode-sync.component.ngfactory";
import * as i12 from "../laborcode-sync/laborcode-sync.component";
import * as i13 from "../wfm-ngp-secondary-positions-sync/wfm-ngp-secondary-positions-sync.component.ngfactory";
import * as i14 from "../wfm-ngp-secondary-positions-sync/wfm-ngp-secondary-positions-sync.component";
import * as i15 from "@angular/common";
import * as i16 from "./org-sync.component";
var styles_OrgSyncComponent = [i0.styles];
var RenderType_OrgSyncComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OrgSyncComponent, data: {} });
export { RenderType_OrgSyncComponent as RenderType_OrgSyncComponent };
function View_OrgSyncComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-organizations", [], null, null, null, i2.View_OrganizationsComponent_0, i2.RenderType_OrganizationsComponent)), i1.ɵdid(2, 114688, null, 0, i3.OrganizationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_OrgSyncComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-employee-sync", [], null, null, null, i4.View_EmployeeSyncComponent_0, i4.RenderType_EmployeeSyncComponent)), i1.ɵdid(2, 114688, null, 0, i5.EmployeeSyncComponent, [i6.WfmSyncService, i7.NotificationsService, i8.BusyService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_OrgSyncComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-benefitdeduction-sync", [], null, null, null, i9.View_BenefitdeductionSyncComponent_0, i9.RenderType_BenefitdeductionSyncComponent)), i1.ɵdid(2, 114688, null, 0, i10.BenefitdeductionSyncComponent, [i6.WfmSyncService, i7.NotificationsService, i8.BusyService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_OrgSyncComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-laborcode-sync", [], null, null, null, i11.View_LaborcodeSyncComponent_0, i11.RenderType_LaborcodeSyncComponent)), i1.ɵdid(2, 114688, null, 0, i12.LaborcodeSyncComponent, [i6.WfmSyncService, i7.NotificationsService, i8.BusyService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_OrgSyncComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-wfm-ngp-secondary-positions-sync", [], null, null, null, i13.View_WfmNgpSecondaryPositionsSyncComponent_0, i13.RenderType_WfmNgpSecondaryPositionsSyncComponent)), i1.ɵdid(2, 114688, null, 0, i14.WfmNgpSecondaryPositionsSyncComponent, [i6.WfmSyncService, i7.NotificationsService, i8.BusyService], { syncType: [0, "syncType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_OrgSyncComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "providers-tabs flex left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "providers-tabs__slider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["list", 1]], null, 15, "ul", [["class", "providers-tabs__list flex flex-direction-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(0) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Organization Sync(Migration)"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Employee Sync(Migration) "])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(2) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Benefit-Deduction Sync(Migration)"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(3) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CostCenter Sync(Migration) "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "li", [["class", "providers-tabs__item"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickTab(4) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Secondary Positions(Migration) "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrgSyncComponent_1)), i1.ɵdid(19, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrgSyncComponent_2)), i1.ɵdid(21, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrgSyncComponent_3)), i1.ɵdid(23, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrgSyncComponent_4)), i1.ɵdid(25, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OrgSyncComponent_5)), i1.ɵdid(27, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.activeTab0; _ck(_v, 19, 0, currVal_5); var currVal_6 = _co.activeTab1; _ck(_v, 21, 0, currVal_6); var currVal_7 = _co.activeTab2; _ck(_v, 23, 0, currVal_7); var currVal_8 = _co.activeTab3; _ck(_v, 25, 0, currVal_8); var currVal_9 = _co.activeTab4; _ck(_v, 27, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeTab0; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.activeTab1; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.activeTab2; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.activeTab3; _ck(_v, 12, 0, currVal_3); var currVal_4 = _co.activeTab4; _ck(_v, 15, 0, currVal_4); }); }
export function View_OrgSyncComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-org-sync", [], null, null, null, View_OrgSyncComponent_0, RenderType_OrgSyncComponent)), i1.ɵdid(1, 114688, null, 0, i16.OrgSyncComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OrgSyncComponentNgFactory = i1.ɵccf("slx-org-sync", i16.OrgSyncComponent, View_OrgSyncComponent_Host_0, { type: "type" }, {}, []);
export { OrgSyncComponentNgFactory as OrgSyncComponentNgFactory };
