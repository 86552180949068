import { Component, OnInit, OnDestroy, Input, Output, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, process, State, aggregateBy } from '@progress/kendo-data-query';
import { NgForm } from '@angular/forms';
import {
  GridComponent,
  GridDataResult,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';

import { Assert } from '../../../../framework/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { RolesSectionRow, RolesSubsectionRow, IRolesRow, RoleSubsection, RoleColumnsState } from '../../../models/index';
import { RolesManagementService } from '../../../services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-roles-subsection-grid',
  templateUrl: 'roles-subsection-grid.component.html',
  styleUrls: ['roles-subsection-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesSubsectionGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public container: RolesSectionRow;
  @Output()
  public roleSubsectionChanged: EventEmitter<number>;

  public appConfig: IApplicationConfig;
  public records: RolesSubsectionRow[];
  public gridState: KendoGridStateHelper<IRolesRow<RoleSubsection>>;
  public roleColumnsState: RoleColumnsState;

  private managementService: RolesManagementService;

  @unsubscribe()
  private onChangeNotifySubscription: Subscription;
  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private filterSubscription: Subscription;

  private changeDetector: ChangeDetectorRef;
  @ViewChild('grid', {static: true})
  private grid: GridComponent;

  constructor(managementService: RolesManagementService, changeDetector: ChangeDetectorRef) {
    this.managementService = managementService;
    this.changeDetector = changeDetector;
    this.gridState = new KendoGridStateHelper<IRolesRow<RoleSubsection>>();
    this.roleSubsectionChanged = new EventEmitter<number>();
    this.roleColumnsState = new RoleColumnsState();
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe((v: State): void => {
      this.refreshGrid();
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });

    this.filterSubscription = this.managementService.rolesColumnsStateChanged$
      .subscribe((state: RoleColumnsState) => {
        this.roleColumnsState = state;
        this.refreshGrid();
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });

    this.onChangeNotifySubscription = this.managementService.changeNotify$.subscribe((v: string): void => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['container']) {
      this.records = this.container.childRows;
      this.refreshGrid();
      if (this.gridState.view) {
        _.times(this.gridState.view.total, (num: number) => this.grid.expandRow(num));
      }
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }

  public onRoleSubsectionToggled(roleId: number, row: RolesSubsectionRow): void {
    this.roleSubsectionChanged.emit(roleId);
    this.managementService.onChangeNotify('onRoleSubsectionToggled');
  }

  public roleFieldChanged(roleId: number, row: RolesSubsectionRow): void {
    row.recalcStatus(roleId);
    this.roleSubsectionChanged.emit(roleId);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }
}

