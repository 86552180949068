import * as tslib_1 from "tslib";
import { Observable, Subject, Subscription } from 'rxjs';
import { mutableSelect } from './../../../../app/core/decorators';
import { Assert } from './../../../../app/framework';
import { LookupApiService, ScheduleCycle } from './../../../../app/organization';
import * as moment from 'moment';
import * as _ from 'lodash';
import { ScheduleRotationApiService } from './scheduled-rotation-api.service';
import { AssignEmpToEmptySlot, AvailShifts, EmployeeScheduledData, EmployeesGenerateRotation, FilterRotationOptions, FutureValidationShift, FutureValidationShiftResult, IAvailShifts, IDays, INoRotationEmployeeList, IScheduleDays, IScheduleRotationPermisions, SaveRotationData, ScheduleRotationTemplate, TerminateRequest } from '../../models/schedule-rotation/schedule-rotation-template.model';
import { ScheduledRotationMapService } from './scheduled-rotation-map.service';
import { ChangeManagementService, ModalService } from './../../../../app/common';
import { v4 as UUID } from 'uuid';
import { ScheduledRotationEmployeeGridHelper } from '../../components/scheduled-rotations/scheduled-rotations-employee-grid/scheduled-rotations-employee-grid-helper';
var ScheduleRotationsManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduleRotationsManagementService, _super);
    function ScheduleRotationsManagementService(lookupApi, scheduleRotationApiService, mapService, changeManagementService, modalService) {
        var _this = _super.call(this) || this;
        _this.lookupApi = lookupApi;
        _this.scheduleRotationApiService = scheduleRotationApiService;
        _this.mapService = mapService;
        _this.changeManagementService = changeManagementService;
        _this.modalService = modalService;
        _this.loadDataSubscription$ = new Subject();
        _this.exportRotation$ = new Subject();
        _this.date = new Date();
        _this.cycles = [];
        _this.dateFrom = moment().toDate();
        _this.selectedUnit = [];
        _this.selectedShiftGroup = [];
        _this.payCycle = new Subject();
        _this.availableShifts$ = new Subject();
        _this.ShiftList = [];
        _this.dates = [];
        _this.totalAvailShifts = 0;
        _this.permissions$ = new Subject();
        _this.totalHours = '0.00';
        _this.isPrimary = false;
        _this.shiftRotationDropDown = [
            { id: 1, name: 'Weekly' },
            { id: 2, name: '2 Weeks' },
            { id: 3, name: '3 Weeks' },
            { id: 4, name: '4 Weeks' }
        ];
        _this.saveRotationHandler$ = new Subject();
        _this.currentWeek = [];
        _this.isDragabble$ = new Subject();
        _this.empScheduleEntries = [];
        _this.emptyAddRotationSequenceCount = 0;
        _this.emptyScheduleDates = [];
        _this.noRotationEmployeeList$ = new Subject();
        _this.isExpand$ = new Subject();
        _this.storeAssignedSlot$ = new Subject();
        _this.assignEmployeeToEmptySlot = [];
        _this.isEditViewSaveDisable = false;
        _this.filterRotationOptions = [];
        _this.isEmployeeAssigeeMode = false;
        _this.filterRotationsRecord$ = new Subject();
        _this.orgLevelSubscription = _this.orgLevel$.subscribe(function (orgLevel) {
            _this.orgLevel = orgLevel;
            _this.clearAllFilters();
        });
        _this.filterChangeCompleteSubscription = _this.filterChangeComplete$.subscribe(function (isNewRotationAdded) {
            _this.mapService.weeklyCount$.subscribe(function (count) { return _this.totalAvailShifts = count; });
            _this.loadDataSubscription([_this.filterShiftData, _this.filterEmployeeData]);
            if (_this.assignedEmployeeAndEmptySlotEmployee.length > 0 || isNewRotationAdded) {
                _this.isNewRotationAdded = true;
            }
        });
        _this.storeAssignedSlot$.subscribe(function () {
            _this.assignEmployeeToEmptySlot = _this.mapService.AssignEmployeeToEmptySlot(_this.assignedEmployeeAndEmptySlotEmployee);
            _this.isEditViewSaveDisable = false;
        });
        _this.gridFilterAppliedSubscription = _this.isGridFilterApplied$.subscribe(function (isApplied) {
            if (isApplied) {
                var selectedOptions = _this.filterRotationsRecord.showStaff.filter(function (x) { return x.isSelected; });
                _this.defaultGridFilterOptions = selectedOptions;
            }
            else {
                var selectedIds = _this.defaultGridFilterOptions.map(function (x) { return x.id; });
                _this.filterRotationsRecord = _this.setDefaultShowStaff(_this.filterRotationsRecord, selectedIds);
            }
        });
        _this.toolbarFilterAppliedSubscription = _this.isToolbarFilterApplied$.subscribe(function (isApplied) {
            if (isApplied) {
                var selectedOptions = _this.generatePositionOptionsCombinations(_this.filterRotationsRecord.positionOptions);
                _this.defaultToolbarFilterOptions = selectedOptions;
                if (_this.filterRotationsRecord.gridFilterOption.isSelected) {
                    _this.defaultToolbarFilterOptions.push(_this.filterRotationsRecord.gridFilterOption);
                }
            }
            else {
                var selectedIds = _this.defaultToolbarFilterOptions.map(function (x) { return x.id; });
                _this.filterRotationsRecord = _this.setPositionOptions(_this.filterRotationsRecord, selectedIds);
            }
        });
        return _this;
    }
    ScheduleRotationsManagementService.prototype.getScheduleRotationAvailShifts = function (orgLevelId, startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.availShiftLoader = true;
                        return [4 /*yield*/, this.scheduleRotationApiService.getScheduleRotationAvailShifts(orgLevelId, startDate, endDate).then(function (shifts) {
                                _this.actualShiftData = shifts;
                                _this.filterShiftData = shifts;
                                var availableShifts = _this.mapService.mapScheduleRotationAvailShiftsData(shifts, _this.weekOfDays);
                                _this.ShiftList = availableShifts;
                                _this.mapService.weeklyCount$.subscribe(function (count) { return _this.totalAvailShifts = count; });
                                _this.availableShifts$.next(availableShifts);
                                _this.availShiftLoader = false;
                                _this.filterData$.next([_this.filterById(_this.selectedPosGroup), _this.filterNames(_this.selectedPos), _this.filterById(_this.selectedUnit), _this.filterNames(_this.selectedShiftGroup), _this.filterRotationsRecord]);
                            }).catch(function () {
                                var availableShifts = _this.mapService.mapScheduleRotationAvailShiftsData([], _this.weekOfDays);
                                _this.mapService.weeklyCount$.subscribe(function (count) { return _this.totalAvailShifts = count; });
                                _this.availableShifts$.next(availableShifts);
                                _this.availShiftLoader = false;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleRotationsManagementService.prototype.getScheduleRotationPermission = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.scheduleRotationApiService.getScheduleRotationPermission(orgLevelId).then(function (permission) {
                    _this.permissions$.next(permission);
                }).catch(function () {
                });
                return [2 /*return*/];
            });
        });
    };
    ScheduleRotationsManagementService.prototype.getEmployeeRotations = function (orgLevelId, startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.employeeLoader = true;
                        return [4 /*yield*/, this.scheduleRotationApiService.getEmployeeRotation(orgLevelId, startDate, endDate).then(function (list) {
                                list.map(function (x) { return x.id = UUID(); });
                                _this.employeeList$.next(list);
                                _this.employeeLoader = false;
                                _this.filterData$.next([_this.filterById(_this.selectedPosGroup), _this.filterNames(_this.selectedPos), _this.filterById(_this.selectedUnit), _this.filterNames(_this.selectedShiftGroup), _this.filterRotationsRecord]);
                            }).catch(function () {
                                _this.employeeList$.next([]);
                                _this.employeeLoader = false;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(ScheduleRotationsManagementService.prototype, "weekOfDays", {
        get: function () {
            return this.currentWeek;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleRotationsManagementService.prototype.getScheduleCycles = function () {
        var _this = this;
        this.lookupApi.getScheduleCycles(this.orgLevel.id).then(function (data) {
            _this.cycles = data;
            if (_this.cycles.length > 0) {
                var today = moment().startOf('day');
                _this.firstCycle = _this.findPayCycle(_this.cycles, today.toDate());
                _this.dates = _this.enumerateDaysBetweenDates(_this.firstCycle.startDate, _this.firstCycle.endDate);
                _this.firstCycleStart = _this.firstCycle ? _this.firstCycle.startDate : today;
                _this.dateFrom = _this.firstCycleStart.toDate();
                _this.payCycle.next(_this.firstCycle);
            }
        });
    };
    ScheduleRotationsManagementService.prototype.findPayCycle = function (cycles, date) {
        var currentDate = moment(date).startOf('day');
        var selectedCycle = _.find(cycles, function (cycle) {
            return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
        });
        return selectedCycle;
    };
    ScheduleRotationsManagementService.prototype.onDateFromChanged = function (cycles, selectedDate) {
        if (cycles.length > 0) {
            var firstCycle = this.findPayCycle(cycles, selectedDate);
            this.dates = this.enumerateDaysBetweenDates(firstCycle.startDate, firstCycle.endDate);
            this.firstCycleStart = firstCycle ? firstCycle.startDate : selectedDate;
            this.firstCycle = firstCycle;
            this.dateFrom = this.firstCycleStart.toDate();
            this.payCycle.next(firstCycle);
        }
    };
    ScheduleRotationsManagementService.prototype.onFilterChange = function () {
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
    };
    ScheduleRotationsManagementService.prototype.filterNames = function (data) {
        return data.map(function (x) { return x.name; });
    };
    ScheduleRotationsManagementService.prototype.filterById = function (data) {
        return data.map(function (x) { return x.id; });
    };
    ScheduleRotationsManagementService.prototype.loadDataSubscription = function (data) {
        this.loadDataSubscription$.next(data);
    };
    ScheduleRotationsManagementService.prototype.subscribeToLoadDataSubscription = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loadDataSubscription$.subscribe(callback);
    };
    ScheduleRotationsManagementService.prototype.enumerateDaysBetweenDates = function (startDate, endDate) {
        var dates = [];
        var currDate = moment(startDate).startOf('day');
        var lastDate = moment(endDate).startOf('day');
        var day = moment(currDate).format('ddd');
        var currentData = {
            day: day,
            fullDayName: moment(currDate).format('dddd'),
            date: currDate.clone().toDate(),
            dayName: day + "(" + moment(currDate).format('MM/DD') + ")",
            formatDate: moment(currDate).format('MM/DD/YYYY')
        };
        dates.push(currentData);
        while (currDate.add(1, 'days').diff(lastDate) <= 0) {
            var day_1 = moment(currDate).format('ddd');
            var data = {
                day: day_1,
                fullDayName: moment(currDate).format('dddd'),
                date: currDate.clone().toDate(),
                dayName: day_1 + "(" + moment(currDate).format('MM/DD') + ")",
                formatDate: moment(currDate).format('MM/DD/YYYY')
            };
            dates.push(data);
        }
        return dates;
    };
    ScheduleRotationsManagementService.prototype.uniqueId = function (data) {
        _.forEach(data, function (i, index) {
            i.id = index + 1;
        });
    };
    ScheduleRotationsManagementService.prototype.clearAllFilters = function () {
        this.selectedPos = [];
        this.selectedPosGroup = [];
        this.selectedShiftGroup = [];
        this.selectedUnit = [];
    };
    ScheduleRotationsManagementService.prototype.isPrimaryChange = function () {
        this.employeeList$.subscribe(function (res) {
            var primaryPositionData = res;
        });
    };
    ScheduleRotationsManagementService.prototype.weeklyHours = function (employee, currentWeek) {
        var _this = this;
        _.forEach(employee, function (emp) {
            var totalHours = 0.00;
            _.forEach(emp.scheduleDays, function (days) {
                _.forEach(currentWeek, function (week) {
                    if (week.formatDate === days.day.formatDate) {
                        _.forEach(days.shifts, function (total) { return totalHours += total.hours; });
                    }
                });
            });
            emp.totalHours = parseFloat(totalHours).toFixed(2);
            _this.totalHours = emp.totalHours;
            emp.isOverTime = _this.totalHours > emp.otHours ? true : false;
        });
    };
    ScheduleRotationsManagementService.prototype.shiftReducer = function (data) {
        this.empScheduleEntries = this.mapService.employeeScheduleEntriesCheck(data);
        data[1].scheduleDate = data[2].formatDate;
        var empIndex = data[0].scheduleDays.findIndex(function (x) { return x.day.formatDate === data[1].scheduleDate; });
        var shiftColIndex = data[3].findIndex(function (x) { return x.day.formatDate === data[1].scheduleDate; });
        var shiftIndex = data[3][shiftColIndex].shifts.findIndex(function (x) { return x.id === data[1].id && x.weekDay === data[1].weekDay && x.shiftId === data[1].shiftId && x.unitId === data[1].unitId && x.jobCode === data[1].jobCode; });
        data[3][shiftColIndex].shifts[shiftIndex].scheduleDate = data[1].scheduleDate;
        this.updateAvailShiftsData(data[3], data[1], data[2], empIndex, shiftColIndex, shiftIndex);
        this.updateEmployeeRecord(data[0], empIndex, data[1]);
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        this.setNotifyChanges();
    };
    ScheduleRotationsManagementService.prototype.setNotifyChanges = function () {
        if (this.empScheduleEntries.length > 0 || this.assignedEmployeeAndEmptySlotEmployee.length > 0) {
            this.changeManagementService.changeNotify();
        }
        else {
            this.changeManagementService.clearChanges();
        }
    };
    ScheduleRotationsManagementService.prototype.updateAvailShiftsData = function (availShifts, shift, day, empIndex, shiftColIndex, shiftIndex) {
        var _this = this;
        if (shift.count > 1) {
            shift.count--;
            availShifts[shiftColIndex].shifts[shiftIndex].count--;
            this.totalAvailShifts--;
            this.updateAction(availShifts);
        }
        else {
            var availShiftsData = availShifts;
            var filteredShift = availShiftsData[shiftColIndex].shifts.filter(function (x) { return x.scheduleDate === null; });
            availShiftsData[shiftColIndex].shifts = [];
            availShiftsData[shiftColIndex].shifts = filteredShift;
            this.filterShiftData = this.filterShiftData.filter(function (x) { return x.id != shift.id; });
            this.actualShiftData = this.actualShiftData.filter(function (x) { return x.id != shift.id; });
            var filterData = this.mapService.mapScheduleRotationAvailShiftsData(this.actualShiftData, this.weekOfDays);
            this.mapService.weeklyCount$.subscribe(function (count) { return _this.totalAvailShifts = count; });
            this.availableShifts$.next(filterData);
        }
    };
    ScheduleRotationsManagementService.prototype.updateEmployeeRecord = function (employee, empIndex, shift) {
        employee.scheduleDays[empIndex].shifts.push(shift);
    };
    ScheduleRotationsManagementService.prototype.updateAction = function (availShifts) {
        var _this = this;
        this.mapService.weeklyCount$.subscribe(function (count) { return _this.totalAvailShifts = count; });
        this.availableShifts$.next(availShifts);
    };
    ScheduleRotationsManagementService.prototype.deleteShiftFromEmployee = function (data) {
        var _this = this;
        this.empScheduleEntries = this.mapService.employeeScheduleEntriesCheck(data);
        var empIndex = data[0].scheduleDays.findIndex(function (x) { return x.day.formatDate === data[2].formatDate; });
        var availShiftIndex = data[3].findIndex(function (x) { return x.day.formatDate === data[1].scheduleDate; });
        data[0].scheduleDays[empIndex].shifts = data[0].scheduleDays[empIndex].shifts.filter(function (x) { return x.id != data[1].id; });
        var addData = data[3][availShiftIndex].shifts.filter(function (x) { return x.id === data[1].id && x.weekDay === data[1].weekDay && x.shiftId === data[1].shiftId && x.unitId === data[1].unitId && x.jobCode === data[1].jobCode; });
        if (addData.length === 0) {
            if (data[1].isAssignedShift) {
                data[0].totalHours -= data[1].hours;
                data[1].scheduleDate = null;
                this.setNotifyChanges();
                return;
            }
            data[0].totalHours -= data[1].hours;
            data[1].scheduleDate = null;
            data[3][availShiftIndex].shifts.push(data[1]);
            // this.filterShiftData.push(data[1]);
            this.actualShiftData.push(data[1]);
            var filterData = this.mapService.mapScheduleRotationAvailShiftsData(this.actualShiftData, this.weekOfDays);
            this.mapService.weeklyCount$.subscribe(function (count) { return _this.totalAvailShifts = count; });
            this.availableShifts$.next(filterData);
        }
        else {
            var addDataIndex = data[3][availShiftIndex].shifts.findIndex(function (x) { return x.id === data[1].id && x.weekDay === data[1].weekDay && x.shiftId === data[1].shiftId && x.unitId === data[1].unitId && x.jobCode === data[1].jobCode; });
            data[0].totalHours -= data[1].hours;
            data[3][availShiftIndex].shifts[addDataIndex].scheduleDate = null;
            data[3][availShiftIndex].shifts[addDataIndex].count++;
            this.totalAvailShifts++;
            this.updateAction(data[3]);
        }
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
        this.setNotifyChanges();
    };
    ScheduleRotationsManagementService.prototype.updateDeletedData = function (employee, shift, date) {
        var deletedData = new EmployeeScheduledData();
        deletedData.employeeId = employee.employeeId;
        deletedData.shiftId = shift.shiftId;
        deletedData.unitId = shift.unitId;
        deletedData.jobCode = shift.jobCode;
        deletedData.scheduledDate = date.formatDate;
        deletedData.deleteInd = true;
        return deletedData;
    };
    ScheduleRotationsManagementService.prototype.saveRotations = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resultData, saveRotationData, slottedData;
            return tslib_1.__generator(this, function (_a) {
                this.employeeLoader = true;
                this.isEditViewSaveDisable = true;
                this.termEmployeeData = this.setEmployeeTermData();
                this.empScheduleEntries = this.empScheduleEntries ? this.empScheduleEntries : [];
                resultData = this.employeeEntryDataProcess(this.empScheduleEntries, this.assignedEmployeeAndEmptySlotEmployee);
                saveRotationData = new SaveRotationData();
                saveRotationData.empSaveRecord = this.empScheduleEntries;
                saveRotationData.empTermRecord = this.termEmployeeData;
                if (resultData.length > 0 || this.assignEmployeeToEmptySlot.length > 0) {
                    slottedData = _.filter(this.assignEmployeeToEmptySlot, function (item) { return item.slotId !== 0; });
                    if (slottedData.length > 0 && resultData.length > 0) {
                        this.saveEmpRotationAndEmptySlotRotationRecords(orgLevelId, resultData, slottedData);
                    }
                    else if (resultData.length > 0) {
                        this.saveEmpRecordsRotation(orgLevelId, resultData);
                    }
                    else if (slottedData.length > 0) {
                        this.saveEmptyRotationAssignedRecords(slottedData);
                    }
                    else {
                        this.employeeLoader = false;
                        this.isNewRotationAdded = false;
                        this.assignedEmployeeAndEmptySlotEmployee = [];
                        this.getEmployeeRotations(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
                        this.getScheduleRotationAvailShifts(this.orgLevel.id, this.firstCycle.startDate, this.firstCycle.endDate);
                        this.getNoRotationEmployeeList(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate);
                        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
                    }
                }
                else {
                    this.employeeLoader = false;
                }
                return [2 /*return*/];
            });
        });
    };
    ScheduleRotationsManagementService.prototype.saveEmpRecordsRotation = function (orgLevelId, saveRotationData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.scheduleRotationApiService.saveEmployeeRotation(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate, saveRotationData).then(function (list) {
                            _this.generateRotationEmployees = [orgLevelId, _this.empScheduleEntries, _this.firstCycle.startDate];
                            _this.empScheduleEntries = [];
                            _this.mapService.employeeScheduledEntries = [];
                            _this.assignedEmployeeAndEmptySlotEmployee = [];
                            _this.termEmployeeData = [];
                            _this.emptyScheduleDates = _this.setEmptyScheduleShift();
                            list.map(function (x) { return x.scheduleDays.map(function (y) { return y.shifts.map(function (z) { return z.count = z.count ? z.count : 1; }); }); });
                            list.map(function (x) { return x.id = UUID(); });
                            _this.employeeList$.next(list);
                            _this.getNoRotationEmployeeList(orgLevelId, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.getScheduleRotationAvailShifts(orgLevelId, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.filterData$.next([_this.filterById(_this.selectedPosGroup), _this.filterNames(_this.selectedPos), _this.filterById(_this.selectedUnit), _this.filterNames(_this.selectedShiftGroup), _this.filterRotationsRecord]);
                            _this.employeeLoader = false;
                            _this.isEditViewSaveDisable = true;
                            _this.isNewRotationAdded = false;
                            _this.generateRotations();
                        }).catch(function () {
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleRotationsManagementService.prototype.saveEmptyRotationAssignedRecords = function (empTermRecord) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.scheduleRotationApiService.assignTerminatedSlotToEmployee(empTermRecord).then(function () {
                            var _a;
                            // if (this.generateRotationRecord && (this.orgLevel.id === this.generateRotationRecord.orgLevelId)) {
                            var empIds = _.map(empTermRecord, function (i) { return i.employeeId; });
                            (_a = _this.generateRotationRecord.empIds).push.apply(_a, empIds);
                            _this.setSessionStorageforGenerateRotation(_this.generateRotationRecord);
                            // }
                            _this.assignedEmployeeAndEmptySlotEmployee = [];
                            _this.assignEmployeeToEmptySlot = [];
                            _this.employeeLoader = false;
                            _this.isEditViewSaveDisable = true;
                            _this.isNewRotationAdded = false;
                            _this.getEmployeeRotations(_this.orgLevel.id, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.getScheduleRotationAvailShifts(_this.orgLevel.id, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.getNoRotationEmployeeList(_this.orgLevel.id, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.filterData$.next([_this.filterById(_this.selectedPosGroup), _this.filterNames(_this.selectedPos), _this.filterById(_this.selectedUnit), _this.filterNames(_this.selectedShiftGroup), _this.filterRotationsRecord]);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleRotationsManagementService.prototype.saveEmpRotationAndEmptySlotRotationRecords = function (orgLevelId, saveRotationData, empTermRecord) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.scheduleRotationApiService.saveEmpRotationAndEmptySlotRotation(orgLevelId, this.firstCycle.startDate, this.firstCycle.endDate, saveRotationData, empTermRecord).then(function (list) {
                            var _a;
                            _this.generateRotationEmployees = [orgLevelId, _this.empScheduleEntries, _this.firstCycle.startDate];
                            _this.empScheduleEntries = [];
                            _this.mapService.employeeScheduledEntries = [];
                            _this.termEmployeeData = [];
                            _this.emptyScheduleDates = _this.setEmptyScheduleShift();
                            list.map(function (x) { return x.scheduleDays.map(function (y) { return y.shifts.map(function (z) { return z.count = z.count ? z.count : 1; }); }); });
                            list.map(function (x) { return x.id = UUID(); });
                            _this.employeeList$.next(list);
                            _this.generateRotations();
                            var employeeIds = _.map(empTermRecord, function (i) { return i.employeeId; });
                            (_a = _this.generateRotationRecord.empIds).push.apply(_a, employeeIds);
                            _this.setSessionStorageforGenerateRotation(_this.generateRotationRecord);
                            _this.assignedEmployeeAndEmptySlotEmployee = [];
                            _this.assignEmployeeToEmptySlot = [];
                            _this.employeeLoader = false;
                            _this.isEditViewSaveDisable = true;
                            _this.isNewRotationAdded = false;
                            _this.getEmployeeRotations(_this.orgLevel.id, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.getScheduleRotationAvailShifts(_this.orgLevel.id, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.getNoRotationEmployeeList(_this.orgLevel.id, _this.firstCycle.startDate, _this.firstCycle.endDate);
                            _this.filterData$.next([_this.filterById(_this.selectedPosGroup), _this.filterNames(_this.selectedPos), _this.filterById(_this.selectedUnit), _this.filterNames(_this.selectedShiftGroup), _this.filterRotationsRecord]);
                        }).catch(function () {
                            _this.employeeLoader = false;
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleRotationsManagementService.prototype.generateRotations = function () {
        var uniqueData = _.uniqBy(this.generateRotationEmployees[1], function (e) {
            return e['employeeId'];
        });
        var generateRotationsRecord = new EmployeesGenerateRotation();
        generateRotationsRecord.orgLevelId = this.orgLevel.id;
        generateRotationsRecord.empIds = _.map(uniqueData, function (item) { return item.employeeId; });
        generateRotationsRecord.startDate = moment(this.firstCycle.startDate).format('MM/DD/YYYY');
        generateRotationsRecord.empIds = _.filter(generateRotationsRecord.empIds, function (i) { return i !== 0; });
        this.generateRotationRecord = generateRotationsRecord;
        this.setSessionStorageforGenerateRotation(this.generateRotationRecord);
    };
    ScheduleRotationsManagementService.prototype.getNoRotationEmployeeList = function (orgLevelId, startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.scheduleRotationApiService.getNoRotationEmployeeList(orgLevelId, startDate, endDate).then(function (list) {
                            _this.noRotationEmployeeList$.next(list);
                        }).catch(function () {
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleRotationsManagementService.prototype.saveGenerateRotation = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.employeeLoader = true;
                        return [4 /*yield*/, this.scheduleRotationApiService.generateScheduleRotation(this.generateRotationRecord).then(function (result) {
                                _this.generateRotationEmployees = [];
                                _this.generateRotationRecord = null;
                                _this.removeSessionStorageforGenerateRotation();
                            })
                                .catch(function (err) {
                                console.log(err);
                            })];
                    case 1:
                        _a.sent();
                        setTimeout(function () {
                            _this.employeeLoader = false;
                        }, 3000);
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleRotationsManagementService.prototype.futureDateValidation = function (data, employee, shift, day) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.employeeLoader = true;
                        this.isEditViewSaveDisable = true;
                        return [4 /*yield*/, this.scheduleRotationApiService.futureDateValidation(data).then(function (result) {
                                _this.frequencyResultData = result;
                                var overLappingData = [];
                                var overTimeData = [];
                                if (_this.frequencyResultData) {
                                    _.forEach(_this.frequencyResultData, function (item) {
                                        if (item.errorMessage === 'Overlapping') {
                                            overLappingData.push(item);
                                        }
                                        if (item.errorMessage === 'Overtime') {
                                            overTimeData.push(item);
                                        }
                                    });
                                }
                                if (overLappingData.length > 0) {
                                    _this.shiftOverLapMessage(overLappingData, employee, shift);
                                }
                                if (overLappingData.length === 0 && overTimeData.length > 0) {
                                    _this.shiftOverTimeMessage(overTimeData, employee, shift);
                                }
                                _this.employeeLoader = false;
                                _this.isEditViewSaveDisable = false;
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ScheduleRotationsManagementService.prototype.setEmployeeTermData = function () {
        var _this = this;
        var sampleData = [];
        this.assignedEmployeeAndEmptySlotEmployee.forEach(function (d, ind) {
            d.scheduleDays.forEach(function (x) {
                if (x.shifts.length > 0) {
                    x.shifts.forEach(function (y) {
                        // if (!y.isAssignedShift || d.isNewRotation) {
                        var data = new TerminateRequest();
                        data.employeeId = d.employeeId;
                        data.isNew = y.isAssignedShift ? true : false;
                        data.slotId = d.slotId ? d.slotId : 0;
                        data.seqId = y.isAssignedShift ? ind : _this.emptyAddRotationSequenceCount;
                        data.scheduledDate = y.calendarFormatDate;
                        data.shiftId = y.shiftId;
                        data.unitId = y.unitId === 100001 ? -1 : y.unitId;
                        data.jobCode = y.jobCode;
                        data.frequency = y.scheduleRotationCount;
                        data.deleteInd = false;
                        sampleData.push(data);
                        // }
                    });
                }
            });
        });
        var uniqueData = _.uniqBy(sampleData, function (e) {
            return e['employeeId'];
        });
        return uniqueData;
    };
    ScheduleRotationsManagementService.prototype.setSessionStorageforGenerateRotation = function (generateRotation) {
        this.removeSessionStorageforGenerateRotation();
        sessionStorage.setItem('SrtGenerateRotation', JSON.stringify(generateRotation));
    };
    ScheduleRotationsManagementService.prototype.getSessionStorageforGenerateRotation = function () {
        var data = sessionStorage.getItem('SrtGenerateRotation');
        if (data) {
            this.generateRotationRecord = JSON.parse(data);
        }
    };
    ScheduleRotationsManagementService.prototype.removeSessionStorageforGenerateRotation = function () {
        sessionStorage.removeItem('SrtGenerateRotation');
    };
    ScheduleRotationsManagementService.prototype.updateModifiedEntry = function (employee, shift) {
        if (this.empScheduleEntries) {
            var empScheduleIndex = this.empScheduleEntries.findIndex(function (data) { return data.employeeId === employee.employeeId && data.deleteInd === false && data.scheduledDate === shift.scheduleDate; });
            if (empScheduleIndex >= 0) {
                this.empScheduleEntries[empScheduleIndex].frequency = shift.frequency;
                this.empScheduleEntries[empScheduleIndex].dayNumber = shift.dayNumber;
            }
            else {
                var dto = {};
                dto.employeeId = employee.employeeId;
                dto.jobCode = shift.jobCode;
                dto.unitId = shift.unitId === 100001 ? -1 : shift.unitId;
                dto.shiftId = shift.shiftId;
                dto.scheduledDate = moment(shift.calendarFormatDate).format('MM/DD/YYYY');
                dto.deleteInd = false;
                dto.frequency = shift.frequency;
                dto.dayNo = shift.dayNo;
                this.empScheduleEntries.push(dto);
            }
        }
        else {
            var dto = {};
            dto.employeeId = employee.employeeId;
            dto.jobCode = shift.jobCode;
            dto.unitId = shift.unitId === 100001 ? -1 : shift.unitId;
            dto.shiftId = shift.shiftId;
            dto.scheduledDate = moment(shift.calendarFormatDate).format('MM/DD/YYYY');
            dto.deleteInd = false;
            dto.frequency = shift.frequency;
            dto.dayNo = shift.dayNo;
            this.empScheduleEntries.push(dto);
        }
        return this.empScheduleEntries;
    };
    ScheduleRotationsManagementService.prototype.employeeEntryDataProcess = function (empEntries, assignedEmployees) {
        _.forEach(empEntries, function (a) {
            _.forEach(assignedEmployees, function (b) {
                if (a.employeeId === 0) {
                    _.forEach(b.scheduleDays, function (c) {
                        if (c.day.formatDate === a.scheduledDate) {
                            _.forEach(c.shifts, function (d) {
                                if (d.shiftId === a.shiftId && !d.isAssignedShift) {
                                    a.employeeId = b.employeeId;
                                    a.isNewlyAssigned = true;
                                }
                            });
                        }
                    });
                }
            });
        });
        return empEntries;
    };
    ScheduleRotationsManagementService.prototype.setEmptyScheduleShift = function () {
        _.map(this.emptyScheduleDates, function (item) { return item.shifts = []; });
        return this.emptyScheduleDates;
    };
    ScheduleRotationsManagementService.prototype.setDefaultShowStaff = function (filterOptions, ids) {
        filterOptions.showStaff.map(function (x) { return x.isSelected = ids.includes(x.id) ? true : false; });
        return filterOptions;
    };
    ScheduleRotationsManagementService.prototype.setPositionOptions = function (filterOptions, ids) {
        filterOptions.positionOptions.primaryPosition.isSelected = ids.includes(filterOptions.positionOptions.primaryPosition.id) ? true : false;
        filterOptions.positionOptions.agencyStaff.isSelected = ids.includes(filterOptions.positionOptions.agencyStaff.id) ? true : false;
        filterOptions.positionOptions.emptyRotation.isSelected = ids.includes(filterOptions.positionOptions.emptyRotation.id) ? true : false;
        filterOptions.positionOptions.secondaryPosition.options.map(function (x) { return x.isSelected = ids.includes(x.id) ? true : false; });
        filterOptions.positionOptions.secondaryPosition.isSelectAll = filterOptions.positionOptions.secondaryPosition.options.some(function (x) { return x.isSelected; });
        filterOptions.gridFilterOption.isSelected = ids.includes(filterOptions.gridFilterOption.id) ? true : false;
        return filterOptions;
    };
    ScheduleRotationsManagementService.prototype.DeleteEmptyRotation = function (slotId) {
        this.employeeLoader = true;
        return this.scheduleRotationApiService.removeSlotRotations(slotId);
    };
    Object.defineProperty(ScheduleRotationsManagementService.prototype, "isSaveDisable", {
        get: function () {
            if (this.isEditViewSaveDisable) {
                return true;
            }
            return this.empScheduleEntries.length === 0 && this.assignEmployeeToEmptySlot.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduleRotationsManagementService.prototype, "isAddNewRotationDisable", {
        get: function () {
            return this.isNewRotationAdded;
        },
        enumerable: true,
        configurable: true
    });
    ScheduleRotationsManagementService.prototype.setMandatoryChecksForFilter = function () {
        this.isNewRotationAdded = true;
        var ids = [3, 4];
        this.filterRotationsRecord.showStaff = this.filterRotationsRecord.showStaff.map(function (item) {
            if (ids.includes(item.id)) {
                item.isSelected = true;
            }
            return item;
        });
        this.filterData$.next([this.filterById(this.selectedPosGroup), this.filterNames(this.selectedPos), this.filterById(this.selectedUnit), this.filterNames(this.selectedShiftGroup), this.filterRotationsRecord]);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ScheduleRotationsManagementService.prototype, "orgLevel$", void 0);
    return ScheduleRotationsManagementService;
}(ScheduledRotationEmployeeGridHelper));
export { ScheduleRotationsManagementService };
