import * as _ from 'lodash';
import { Component, Provider, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import {
  IDialog,
  DialogOptions2,
  KendoGridStateHelper,
  DialogModeSize,
} from '../../../../../../app/common/models/index';
import { ModalService, StateManagementService } from './../../../../../common/services/index';
import { BenefitPayrollExistingMappingDialogComponent } from '../benefit-payroll-existing-mapping-dialog/benefit-payroll-existing-mapping-dialog.component';
import { GroupResult, orderBy, groupBy, process, State } from '@progress/kendo-data-query';
import { BenefitPayrollMapping, BenefitDetailsPayrollDeductions, BenefitDetailsPayrollGroup, BenefitPayrollExistingMapping, BenefitDetailsPayrollCreateMapping, BenefitPayrollCreateMapping } from '../../../models/benefit-details/index';
import { Subscription } from 'rxjs/Subscription';
import { BenefitPayrollMappingManagementService } from '../../../services/benefit-console/benefit-payroll-mapping-management.service';

@Component({
  selector: 'slx-benefit-payroll-mapping-dialog',
  templateUrl: './benefit-payroll-mapping-dialog.component.html',
  styleUrls: ['./benefit-payroll-mapping-dialog.component.scss'],
  providers: [BenefitPayrollMappingManagementService]
})
export class BenefitPayrollMappingDialogComponent implements IDialog {
  public payrollDeductions: BenefitDetailsPayrollDeductions[] = [];
  public records: BenefitDetailsPayrollDeductions[] = [];
  public benefitGroups: BenefitDetailsPayrollGroup[] = [];
  public isAllDeductionSelected: boolean = false;
  public isAnyDeductionSelected: boolean = false;
  public ignoredDeductions: boolean = false;
  public gridState: KendoGridStateHelper<BenefitDetailsPayrollDeductions>;
  public dialogResult: boolean;
  public benefitPayrollExistingMapping: BenefitPayrollExistingMapping[] = [];
  public benefitPayrollCreateMapping: BenefitDetailsPayrollCreateMapping[] = [];
  public changedIgnorePayrollDeductions: NumberMap<BenefitDetailsPayrollDeductions> = {};
  public hasIgnoreChanges = false;
  public isLoading: boolean = false;
  public defaultGroupItem: BenefitDetailsPayrollGroup = { id: 0, name: 'Select Group', type: null };
  public benefitPayrollMapping: BenefitPayrollMapping;

  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private dialogOptions: DialogOptions2,
    private modalService: ModalService,
    private manService: BenefitPayrollMappingManagementService
  ) {
    this.gridState = new KendoGridStateHelper<BenefitDetailsPayrollDeductions>();
    this.gridState.view = null;
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [];
    this.getBenefitPayrollMappingData();
  }

  public static openDialog(
    modalService: ModalService,
    callback: (result: boolean) => void
  ): BenefitPayrollMappingDialogComponent {
    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.modeSize = DialogModeSize.grid;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions2,
        useValue: dialogOptions,
      }
    ];
    let dialog: BenefitPayrollMappingDialogComponent = modalService.globalAnchor.openDialog2(
      BenefitPayrollMappingDialogComponent,
      'Benefits Payroll Mapping',
      dialogOptions,
      resolvedProviders,
      (result: boolean, uniqueId?: string) => {
        callback(result);
      }
    );
    return dialog;
  }

  public async getBenefitPayrollMappingData() {
    this.isLoading = true;
    await this.manService.getBenefitPayRollMapping(this.ignoredDeductions);
    this.subscriptions.onLoadedMapping = this.manService
      .subscribeToBenefitPayrollMapping((v: BenefitPayrollMapping) => {
        this.benefitPayrollMapping = v;
        this.setData();
      });
  }

  public setData() {
    this.payrollDeductions = this.benefitPayrollMapping.payrollDeductions;
    this.benefitGroups = this.benefitPayrollMapping.benefitGroups;
    this.records = this.payrollDeductions;
    this.refreshGrid();
    this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(() => {
      this.refreshGrid();
    });
  }

  public onChangeIsAllDeductionSelected() {
    _.forEach(this.payrollDeductions, (deduction) => {
      deduction.isIgnored = this.isAllDeductionSelected;
      this.checkDeductionIgnore(deduction);
    });
    this.recalcDeductionIgnored();
  }

  public onChangeDeductionisIgnored(payrollDeduction: BenefitDetailsPayrollDeductions) {
    this.checkDeductionIgnore(payrollDeduction);
    this.recalcDeductionIgnored();
    this.isAllDeductionSelected = !_.find(this.payrollDeductions, (d) => !d.isIgnored);
  }

  public onChangebenefitGroups(payrollDeduction: BenefitDetailsPayrollDeductions) {
    if (payrollDeduction.benefit && payrollDeduction.benefit.id === 0) {
      const selected = this.benefitPayrollCreateMapping.findIndex(e => e.payrollDeductionId === payrollDeduction.id);
      if (selected !== -1) {
        this.benefitPayrollCreateMapping.splice(selected, 1);
        return;
      }
    }
    if (!payrollDeduction.isIgnored) {
      const selectedDate = this.benefitPayrollCreateMapping.findIndex((e) => e.payrollDeductionId === payrollDeduction.id);
      if (selectedDate === -1) {
        this.benefitPayrollCreateMapping.push({ payrollDeductionId: payrollDeduction.id, benefitGroupId: payrollDeduction.benefit.id });
      } else {
        this.benefitPayrollCreateMapping[selectedDate].benefitGroupId = payrollDeduction.benefit.id;
      }
    }
  }

  public async save(): Promise<void> {
    let benefitPayrollCreateMapping = new BenefitPayrollCreateMapping();
    benefitPayrollCreateMapping.mapping = this.benefitPayrollCreateMapping;
    const changes = _.values(this.changedIgnorePayrollDeductions);
    benefitPayrollCreateMapping.ignorePayrollDeductions = _.map(_.filter(changes, (c) => !c.initialIsIgnored && c.isIgnored), (c) => c.id);
    benefitPayrollCreateMapping.resetIgnorePayrollDeductions = _.map(_.filter(changes, (c) => c.initialIsIgnored && !c.isIgnored), (c) => c.id);
    this.dialogResult = false;
    if (this.benefitPayrollCreateMapping.length > 0 || changes.length > 0) {
      this.isLoading = true;
      await this.manService.benefitPayRollCreateMapping(benefitPayrollCreateMapping);
      this.isLoading = false;
      this.dialogResult = true;
    }
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  public cancel(): void {
    this.isAllDeductionSelected = false;
    this.dialogResult = false;
    this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
  }

  showBenefitPayrollExistingMappingDialog() {
    BenefitPayrollExistingMappingDialogComponent.openDialog(
      this.modalService,
      (result: boolean) => { }
    );
  }

  public onTogglerChanged(ignoredDeductions: boolean) {
    this.isLoading = true;
    this.gridState.view = null;
    this.manService.getBenefitPayRollMapping(this.ignoredDeductions);
  }

  private checkDeductionIgnore(payrollDeduction: BenefitDetailsPayrollDeductions) {
    if (payrollDeduction.isIgnored !== payrollDeduction.initialIsIgnored) {
      this.changedIgnorePayrollDeductions[payrollDeduction.id] = payrollDeduction;
    } else {
      delete this.changedIgnorePayrollDeductions[payrollDeduction.id];
    }
    if (payrollDeduction.isIgnored) {
      payrollDeduction.benefit = null;
      if (this.benefitPayrollCreateMapping.length > 0) {
        const selectedDate = this.benefitPayrollCreateMapping.findIndex(e => e.payrollDeductionId === payrollDeduction.id);
        if (selectedDate !== -1) {
          this.benefitPayrollCreateMapping.splice(selectedDate, 1);
        }
      }
    }
    return;

  }

  private recalcDeductionIgnored() {
    const changes = _.values(this.changedIgnorePayrollDeductions);
    this.hasIgnoreChanges = changes.length > 0;
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      this.isLoading = false;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
    this.isLoading = false;
  }
}
