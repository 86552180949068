import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TimeclockDailySummary } from '../../../../organization/models/index';
import { Assert } from '../../../../framework/index';
import {
  Component, Input
  , OnChanges, SimpleChanges, SimpleChange
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
import { TimeclockDataService } from './../../../../../app/organization';


@Component({
  moduleId: module.id,
  selector: 'slx-dropdown-timeclock-status-list',
  templateUrl: 'timeclock-status-list.component.html',
  styleUrls: ['timeclock-status-list.component.scss']
})
export class TimeclockStatusListComponent {

  @Input()
  public timeclockStatus: TimeclockDailySummary;

  constructor(public _timeclockDataService: TimeclockDataService) {}

  public get commStatus(): string {
    return this.statusIsGood ? 'Online' : 'Offline';
  }

  public get statusIsGood(): boolean {
    return this.timeclockStatus && this.timeclockStatus.communicationStatus > 0;
  }

  public get errorsIsGood(): boolean {
    return this.timeclockStatus && this.timeclockStatus.dailyFPErrorsPercent < 25;
  }

  public get restrictionIsGood(): boolean {
    return this.timeclockStatus && this.timeclockStatus.dailyRestrictionsPercent < 25;
  }
}
