<input #timeInput [readonly]="!inEdit ? true : null" [textMask]="timeMaskConf" [(ngModel)]="value" type="text" maxlength="50"
      [attr.class]="className" [placeholder]="placeholder">

<div *ngIf="showCalendar" [hidden]="!inEdit" >
      <div class="hidden-date-input">
            <input class="date-picker-field" />
      </div>​
      <button type="button" class="button active calendar" (click)="toggleCalendar ()">
            <span class="caption">Calendar</span>
            <i class="fas fa-calendar-alt icon" aria-hidden="true"></i>
      </button>
      <!--
      <button type="button" class="button active calendar" slx-popup [popupContent]="calendarContainer">
            <span class="caption">Calendar</span>
            <i class="fas fa-calendar-alt icon" aria-hidden="true"></i>
      </button>
      <div class="calendar-container" #calendarContainer tabindex="1">
            <slx-calendar name="currentDate" [(ngModel)]="internalDateToUpdateMoment" [minDate]="internalMinMoment" [maxDate]="internalMaxMoment"
                  (ngModelChange)="calendarDateChanged($event)"></slx-calendar>
      </div>
      -->
</div>
