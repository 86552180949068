import { Component, Provider } from '@angular/core';
import { DailyUnitGridEmployee } from '../../../scheduler/components/daily-unit-grid/daily-unit-grid-employees/daily-unit-grid-employees-model';
import { DialogOptions } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';

export class ConfirmOptions3 {
  public showOK: boolean;
  public showCancel: boolean;
  public buttonOKtext: string;
  public buttonCanceltext: string;
  public className: string;
  public width: number;
  public height: number;
  public itemData : DailyUnitGridEmployee;
  public note: string;
  public agencyName:string;
}

@Component({
  selector: 'slx-confirm-dialog3',
  templateUrl: './confirm-dialog3.component.html',
  styleUrls: ['./confirm-dialog3.component.scss']
})
export class ConfirmDialog3Component {

  public options: DialogOptions;
  public dialogResult: boolean;
  public confirmOptions: ConfirmOptions3;
  private modalService: ModalService;
  public get okButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonOKtext) {
      return 'OK';
    }
    return this.confirmOptions.buttonOKtext;
  }
  public get cancelButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonCanceltext) {
      return 'Cancel';
    }
    return this.confirmOptions.buttonCanceltext;
  }

  public get itemData(): DailyUnitGridEmployee {
    return this.confirmOptions.itemData;
  }


  public static openDialog(
    title: string,
    message: string,
    modalService: ModalService,
    callback: (result: boolean) => void, options?: ConfirmOptions3): ConfirmDialog3Component {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.message = message;
    
    let confirmOptions: ConfirmOptions3 = options;
   
    if (!options) {
      confirmOptions = new ConfirmOptions3();
      confirmOptions.showOK = true;
      confirmOptions.showCancel = true;
    }
    dialogOptions.width = confirmOptions.width || 350;
    dialogOptions.height = confirmOptions.height || 250;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ConfirmOptions3,
        useValue: confirmOptions
      }
    ];
    let dialog: ConfirmDialog3Component = modalService.globalAnchor
      .openDialog(ConfirmDialog3Component, title, dialogOptions, resolvedProviders, callback);
    return dialog;
  }

  constructor(options: DialogOptions, modalService: ModalService, confirmOptions: ConfirmOptions3) {
    this.options = options;
    this.modalService = modalService;
    this.confirmOptions = confirmOptions;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

}
