import { StepDefinition } from './wizard-step.types';
var StepView = /** @class */ (function () {
    function StepView(viewContainerRef, templateRef) {
        this.viewContainerRef = viewContainerRef;
        this.templateRef = templateRef;
        this.stepDefinition = new StepDefinition();
        this.created = false;
    }
    Object.defineProperty(StepView.prototype, "step", {
        get: function () {
            return this.stepDefinition.step;
        },
        enumerable: true,
        configurable: true
    });
    StepView.prototype.setStep = function (step) {
        this.stepDefinition.step = step;
    };
    StepView.prototype.setConf = function (conf) {
        this.stepDefinition.name = conf.name;
        this.stepDefinition.description = conf.description;
    };
    StepView.prototype.create = function () {
        this.created = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
    };
    StepView.prototype.destroy = function () {
        this.created = false;
        this.viewContainerRef.clear();
    };
    StepView.prototype.enforceState = function (created) {
        if (created && !this.created) {
            this.create();
        }
        else if (!created && this.created) {
            this.destroy();
        }
    };
    return StepView;
}());
export { StepView };
