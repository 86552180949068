export interface IAcaExportCorrection1094CCounts {
  month: string;
  ftCount: number;
  totalCount: number;
}

export class AcaExportCorrection1094CCounts {
  public month: string;
  public ftCount: number;
  public totalCount: number;
}

export class IAcaExportCorrection1094CCountContainer {
  counts: AcaExportCorrection1094CCounts[];
  totalFormsSubmitted: number;
}

export class AcaExportCorrection1094CCountContainer {
  public counts: AcaExportCorrection1094CCounts[];
  public totalFormsSubmitted: number;
}

export interface IAcaExportCorrection1094CCountsDto {
  monthName: string;
  ftCount: number;
  totalCount: number;
}

export class IAcaExportCorrection1094CCountDto {
  items: IAcaExportCorrection1094CCountsDto[];
  totalFormsSubmitted: number;
}
