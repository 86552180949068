import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Assert } from '../../../framework/index';

import { dateTimeUtils } from '../../../common/utils/index';
import {
  GenericListDefinition, IGenericListDefinition,
  GenericFieldDefinition, IGenericFieldDefinition,
  GenericLinkDefinition, IGenericLinkDefinition,
  IGenericFieldValue, GenericFieldValue, GenericField,
  IGenericFieldRecord, IGenericFieldList,
  GenericRow, GenericList,
  IGenericListRequest, GenericListRequest
} from '../../models/generic-list/index';

@Injectable()
export class GenericListMapService {

  public mapToGenericListDefinition(dto: IGenericListDefinition): GenericListDefinition {
    let genList: GenericListDefinition = new GenericListDefinition();
    genList.id = dto.id;
    genList.listName = dto.listName;
    genList.primaryKey = dto.primaryKey;
    let fields: GenericFieldDefinition[] = _.map(dto.fields, (field: IGenericFieldDefinition) => {
      return this.mapToGenericFieldDefinition(field);
    });
    genList.fields = _.keyBy(fields, (val: GenericFieldDefinition) => {
      return val.fieldName;
    });
    return genList;
  }

  public mapToGenericFieldDefinition(dto: IGenericFieldDefinition): GenericFieldDefinition {
    let genField: GenericFieldDefinition = new GenericFieldDefinition();
    genField.fieldName = dto.fieldName;
    genField.displayName = dto.displayName;
    if (genField.fieldName === 'Selectable') {
      genField.fieldType = 'check';
    } else {
      genField.fieldType = dto.fieldType;
    }
    genField.hidden = dto.hidden;
    genField.width = dto.width;
    genField.hyperlink = dto.hyperlink ? this.mapToGenericLinkDefinition(dto.hyperlink) : undefined;
    return genField;
  }

  public mapToGenericLinkDefinition(dto: IGenericLinkDefinition): GenericLinkDefinition {
    let genLink: GenericLinkDefinition = new GenericLinkDefinition();
    genLink.linkType = dto.hyperlinkType;
    genLink.fieldNames = _.map(dto.fieldNames, (fieldName: string) => {
      return fieldName;
    });
    return genLink;
  }

  public mapToGenericFieldValue(fieldDef: GenericFieldDefinition, dto: IGenericFieldValue): GenericFieldValue {
    let genValue: GenericFieldValue = new GenericFieldValue();
    genValue.fieldName = dto.fieldName;
    if (fieldDef.fieldType === 'date' || fieldDef.fieldType === 'datetime') {
      if (dto.value !== null && dto.value !== undefined && dto.value !== '') {
        if (typeof dto.value === 'string') {
          if (dto.value.replace(/\s/g, '').length > 0) {
            if (fieldDef.fieldType === 'date') {
              genValue.value = dateTimeUtils.convertFromDtoString(dto.value);
            } else {
              genValue.value = dateTimeUtils.convertFromDtoDateTimeString(dto.value);
            }
          }
        }
      }
    } else if (fieldDef.fieldType === 'check') {
      genValue.value = false;
    } else if (fieldDef.fieldType === 'int' || fieldDef.fieldType === 'number') {
      genValue.value = +dto.value;
    } else if (fieldDef.fieldType === 'boolean') {
      genValue.value = dto.value.toLowerCase() === 'true';
    } else {
      genValue.value = dto.value;
    }
    return genValue;
  }

  public mapToGenericRow(listDefinition: GenericListDefinition, dto: IGenericFieldRecord): GenericRow {
    let row: GenericRow = new GenericRow();
    if (!dto) { return row; }
    let colValues: GenericField[] = _.map(dto.fields, (fieldDto: IGenericFieldValue) => {
      let genField: GenericField = new GenericField();
      genField.field = listDefinition.fields[fieldDto.fieldName];
      Assert.isNotNull(genField.field, `inconsistent list definition for field ${fieldDto.fieldName}`);
      genField.value = this.mapToGenericFieldValue(genField.field, fieldDto);
      if (genField.field.fieldType === 'check') {
        row.selectable = fieldDto.value === '1'; // value should be string equals "0" or "1"
      }
      return genField;
    });
    row.fields = _.keyBy(colValues, (val: GenericField) => {
      return val.field.fieldName;
    });
    return row;
  }

  public mapGenericList(listDefinition: GenericListDefinition, list: IGenericFieldList): GenericList {
    let genericList: GenericList = new GenericList();
    genericList.listDefinition = listDefinition;
    let rowIndex: number = 0;
    genericList.rows = _.map(list.items, (record: IGenericFieldRecord) => {
      let row: GenericRow = this.mapToGenericRow(listDefinition, record);
      if (row.selectable) {
        genericList.selectable = true;
      }
      row.index = rowIndex;
      rowIndex++;
      return row;
    });
    return genericList;
  }

  public mapToGenericListRequestDto(data: GenericListRequest): IGenericListRequest {
    let req: IGenericListRequest = {
      ids: data.ids,
      startDate: dateTimeUtils.convertToDtoString(data.startDate),
      endDate: dateTimeUtils.convertToDtoString(data.endDate)
    };
    if (_.isNumber(data.positionId)) {
      req.positionId = data.positionId;
    }
    return req;
  }
}
