import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../../../../app.config';
import { EmployeeWarningSections } from '../../../../models/index';
import { EmployeeSectionWarningsManagementService } from '../../../../services/index';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
var EmployeeSectionsWarningsEmployeeRemarksComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsEmployeeRemarksComponent(manService, changeDetector) {
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.subscriptions = {};
        this.appConfig = appConfig;
    }
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.remarks = this.manService.getEmployeeRemarksSection();
        this.subscriptions.openWarning = this.manService.subscribeToLoadedWarning(function (warning) {
            _this.assignRemarks(warning);
            _this.assignRemarksData();
            _this.updateView();
        });
        this.subscriptions.statusChanges = this.form.statusChanges.subscribe(function () {
            _this.manService.changeSectionValidity(EmployeeWarningSections.StandardEmployeeRemarks, _this.form.valid);
        });
    };
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.onChangeRemarks = function () {
        this.remarks.data.employeeRemarks = this.remarksText;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.onChangeEmpSign = function () {
        this.remarks.data.employeeSign = this.employeeSign;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.onChangeEmpSignDate = function () {
        this.remarks.data.employeeSignDate = this.employeeSignDate;
        this.manService.markWarningAsEdited(true);
    };
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.updateView = function () {
        var _this = this;
        Observable.of(true)
            .delay(200)
            .subscribe(function () { return _this.changeDetector.detectChanges(); });
    };
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.assignRemarks = function (warning) {
        var remarks = _.find(warning.sections, function (s) { return s.isStandardEmployeeRemarks; });
        if (_.isObjectLike(remarks) && _.isObjectLike(remarks.data)) {
            this.remarks = remarks;
        }
    };
    EmployeeSectionsWarningsEmployeeRemarksComponent.prototype.assignRemarksData = function () {
        this.remarksText = this.remarks.data.employeeRemarks;
        this.employeeSign = this.remarks.data.employeeSign;
        this.employeeSignDate = this.remarks.data.employeeSignDate;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], EmployeeSectionsWarningsEmployeeRemarksComponent.prototype, "subscriptions", void 0);
    return EmployeeSectionsWarningsEmployeeRemarksComponent;
}());
export { EmployeeSectionsWarningsEmployeeRemarksComponent };
