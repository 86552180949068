<slx-spinner [show]="isLoading">
    <div class="model-header">
        <div class="col-xs-12 ">
            <button type="button" class="slx-button pull-right slx-blue generate-button" [disabled]=isButtonEnable
                (click)="showGenerate1094c()">
                <span class="slx-button__text">Generate 1094-C PDF</span>
            </button>
            <button type="button" class="slx-button pull-right slx-blue generate-button" [disabled]=isButtonEnable
                (click)="showGenerate1095c()">
                <span class="slx-button__text">Generate 1095-Cs PDF</span>
            </button>
            <button type="button" class="slx-button pull-right slx-blue generate-button" [disabled]=isButtonEnable
                (click)="showGenerateXML()">
                <span class="slx-button__text">Generate Corrected XML</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <h5 class="employee-record__h5">The following employee 1095-C records were not included in the export that has
            been selected
            to be supplemented. Please select which additional 1095-C records you wish to include in the supplemental
            1095-C export to be filed with the IRS.</h5>
        <kendo-grid class="grid-full-height slx-blue-grid aca-export-data-grid" [data]="gridState.view"
            [sortable]="{ mode: 'multiple' }" [sort]="gridState.state.sort" [filterable]="'menu'"
            [filter]="gridState.state.filter" [scrollable]="'scrollable'" [skip]="gridState.state.skip"
            (filterChange)="filterChange($event)" (dataStateChange)="gridState.dataStateChange($event)">
            <kendo-grid-column title="Select" [sortable]="false" [filterable]="false" field="isSelected"
                [locked]="false" width="10px">
                <ng-template kendoGridHeaderTemplate>
                    <slx-checkbox-button class="slx-no-indents" [(ngModel)]="isAllSelected"
                        (ngModelChange)="onToggleAllSelected()">
                    </slx-checkbox-button>
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <slx-checkbox-button class="slx-no-indents" [(ngModel)]="dataItem.isSelected"
                        (ngModelChange)="onCheckedEmployee(dataItem.isSelected)">
                    </slx-checkbox-button>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Name" field="employeeName" [filterable]="true" [sortable]="true" width="120">
                <ng-template kendoGridHeaderTemplate>
                    Name
                </ng-template>
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div [title]="dataItem.employeeName">{{dataItem.employeeName}}</div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn grey" (click)="onBack()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>Back</button>
        <button type="button" class="btn red" (click)="onCancel()"><i class="fa fa-times"
                aria-hidden="true"></i>Cancel</button>
    </div>
</slx-spinner>