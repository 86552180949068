<slx-application-item [application]="application" [progressbar]="progressbar" (onHasChanges)="onHasChanges($event)">
  <div class="app__links">
    <a *ngFor="let appAlert of alertConfigOrderAndVisibilty" [ngClass]="{ 'hidden': !appAlert.visible }" class="app__link"
    [routerLink]="getRouterLink(appAlert.link)" [queryParams]="appAlert.alertName=='PTO Req'? datesParams:null">
    <span>
      <span class="app__link-name">{{appAlert.alertName}}</span>
      <i class="app__link-icon  {{appAlert.icon}}" aria-hidden="true"></i>
      <i class="app__link-amount" aria-hidden="true"
        [ngClass]="{ 'empty': appAlert.counter === 0 }">{{ appAlert.counter}}</i>
    </span>
  </a>
  </div>
</slx-application-item>
