/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ideal-schedule-range-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components-library/components/collapsible-section/collapsible-section.component.ngfactory";
import * as i3 from "../../../../components-library/components/collapsible-section/collapsible-section.component";
import * as i4 from "../../../../components-library/services/collapsible-section.service";
import * as i5 from "../ideal-schedule-range-toolbar/ideal-schedule-range-toolbar.component.ngfactory";
import * as i6 from "../ideal-schedule-range-toolbar/ideal-schedule-range-toolbar.component";
import * as i7 from "../../../../core/services/toolbar/toolbar-base.service";
import * as i8 from "../../../services/ideal-schedule/ideal-schedule.state.service";
import * as i9 from "../../../../organization/services/lookup/lookup.service";
import * as i10 from "../../../services/ideal-schedule/ideal-schedule.helper.service";
import * as i11 from "../../../../common/services/modal/modal.service";
import * as i12 from "../ideal-schedule-grid/ideal-schedule-grid.component.ngfactory";
import * as i13 from "../ideal-schedule-grid/ideal-schedule-grid.component";
import * as i14 from "@angular/common";
import * as i15 from "./ideal-schedule-range-grid.component";
var styles_IdealScheduleRangeGridComponent = [i0.styles];
var RenderType_IdealScheduleRangeGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IdealScheduleRangeGridComponent, data: {} });
export { RenderType_IdealScheduleRangeGridComponent as RenderType_IdealScheduleRangeGridComponent };
function View_IdealScheduleRangeGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "slx-collapsible-section", [["class", "no-padding"], ["mode", "blue"]], null, null, null, i2.View_CollapsibleSectionComponent_0, i2.RenderType_CollapsibleSectionComponent)), i1.ɵdid(1, 770048, null, 0, i3.CollapsibleSectionComponent, [i4.CollapsibleSectionService], { title: [0, "title"], expanded: [1, "expanded"], mode: [2, "mode"] }, null), (_l()(), i1.ɵeld(2, 0, null, 2, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "slx-ideal-schedule-range-toolbar", [], null, null, null, i5.View_IdealScheduleRangeToolbarComponent_0, i5.RenderType_IdealScheduleRangeToolbarComponent)), i1.ɵdid(4, 245760, null, 0, i6.IdealScheduleRangeToolbarComponent, [i7.TOOLBAR_SERVICE, i8.IdealScheduleStateService, i9.LookupService, i10.IdealScheduleHelperService, i11.ModalService], { range: [0, "range"], rangeIndex: [1, "rangeIndex"], canDelete: [2, "canDelete"], showAcuitySelector: [3, "showAcuitySelector"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "range-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "slx-ideal-schedule-grid", [], null, null, null, i12.View_IdealScheduleGridComponent_0, i12.RenderType_IdealScheduleGridComponent)), i1.ɵdid(7, 245760, null, 0, i13.IdealScheduleGridComponent, [i8.IdealScheduleStateService, i1.ChangeDetectorRef], { range: [0, "range"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rangeName(_v.context.$implicit); var currVal_1 = true; var currVal_2 = "blue"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit; var currVal_4 = _v.context.index; var currVal_5 = (_co.ranges.length > 1); var currVal_6 = true; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _v.context.$implicit; _ck(_v, 7, 0, currVal_7); }, null); }
export function View_IdealScheduleRangeGridComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_IdealScheduleRangeGridComponent_1)), i1.ɵdid(1, 278528, null, 0, i14.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ranges; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IdealScheduleRangeGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-ideal-schedule-range-grid", [], null, null, null, View_IdealScheduleRangeGridComponent_0, RenderType_IdealScheduleRangeGridComponent)), i1.ɵdid(1, 49152, null, 0, i15.IdealScheduleRangeGridComponent, [], null, null)], null, null); }
var IdealScheduleRangeGridComponentNgFactory = i1.ɵccf("slx-ideal-schedule-range-grid", i15.IdealScheduleRangeGridComponent, View_IdealScheduleRangeGridComponent_Host_0, { ranges: "ranges" }, {}, []);
export { IdealScheduleRangeGridComponentNgFactory as IdealScheduleRangeGridComponentNgFactory };
