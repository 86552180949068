import * as tslib_1 from "tslib";
import { ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
var KendoGridFixFreezeHeaderDirective = /** @class */ (function () {
    function KendoGridFixFreezeHeaderDirective(elRef, renderer) {
        var _this = this;
        this.elRef = elRef;
        this.renderer = renderer;
        this.isLeftSidebarOpenSubscription = this.isLeftSidebarOpen$.subscribe(function (isOpen) {
            if (_this.headerElement && _this.lockedHeaderElement) {
                //fix kendo bug
                var el = _this.lockedHeaderElement;
                _this.renderer.setStyle(_this.headerElement, 'width', "calc(100% - " + el.scrollWidth + "px)");
            }
        });
    }
    KendoGridFixFreezeHeaderDirective.prototype.ngAfterViewInit = function () {
        var el = this.elRef.nativeElement;
        this.headerElement = el.querySelector('.k-grid-header>.k-grid-header-wrap');
        this.lockedHeaderElement = el.querySelector('.k-grid-header>.k-grid-header-locked');
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], KendoGridFixFreezeHeaderDirective.prototype, "isLeftSidebarOpen$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridFixFreezeHeaderDirective.prototype, "isLeftSidebarOpenSubscription", void 0);
    return KendoGridFixFreezeHeaderDirective;
}());
export { KendoGridFixFreezeHeaderDirective };
