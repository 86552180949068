import * as _ from 'lodash';
import * as moment from 'moment';
import { DailyData, WeeklyData } from '../../models/index';
import { WeekDays } from '../../../common/models/index';
var CalendarDataService = /** @class */ (function () {
    function CalendarDataService() {
    }
    CalendarDataService.prototype.ExtendRangeToDailyData = function (startOfRange, dayCount, dataList) {
        var mapByDay = _.groupBy(dataList, function (data) {
            return moment(data.date).startOf('day').unix();
        });
        var days = [];
        var day = startOfRange.clone();
        _.times(dayCount, function () {
            var dayDataList = mapByDay[day.unix()];
            var dailyData = new DailyData();
            dailyData.startOfDay = day.clone();
            dailyData.dataList = dayDataList;
            dailyData.dayNumber = days.length;
            days.push(dailyData);
            day.add(1, 'day');
        });
        return days;
    };
    CalendarDataService.prototype.ExtendRangeToWeeklyData = function (startOfRange, weekCount, dataList) {
        var _this = this;
        var mapByWeek = _.groupBy(dataList, function (data) {
            return moment(data.date).startOf('week').unix();
        });
        var weeks = [];
        var week = startOfRange.startOf('week').clone();
        _.times(weekCount, function () {
            var weekDataList = mapByWeek[week.unix()];
            var weeklyData = new WeeklyData();
            weeklyData.startOfWeek = week.clone();
            weeklyData.days = _this.ExtendRangeToDailyData(week, 7, weekDataList);
            weeklyData.weekNumber = weeks.length;
            weeks.push(weeklyData);
            week.add(1, 'week');
        });
        return weeks;
    };
    CalendarDataService.prototype.ExtendRangeToWeeklyDataByStartDate = function (startOfRange, weekCount, dataList) {
        var _this = this;
        var mapByWeek = _.groupBy(dataList, function (data) {
            return Math.floor(moment(data.date).diff(startOfRange, 'day') / 7);
        });
        var weeks = [];
        var week = startOfRange.clone();
        _.times(weekCount, function (num) {
            var weekDataList = mapByWeek[num];
            var weeklyData = new WeeklyData();
            weeklyData.startOfWeek = week;
            weeklyData.days = _this.ExtendRangeToDailyData(week, 7, weekDataList);
            weeklyData.weekNumber = weeks.length;
            weeks.push(weeklyData);
            week.add(1, 'week');
        });
        return weeks;
    };
    Object.defineProperty(CalendarDataService.prototype, "getWeekDaysList", {
        get: function () {
            return WeekDays;
        },
        enumerable: true,
        configurable: true
    });
    return CalendarDataService;
}());
export { CalendarDataService };
