import { ValuePairChartInput } from './../../../models/value-pair-chart/value-pair-chart-input';
import { ValuePairChartModel } from './../../../models/value-pair-chart/value-pair-chart-model';
import { ValuePairChartDataService } from './../../../services/value-pair-chart-data/value-pair-chart-data.service';
import { Component, OnInit } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'slx-chart-status-icon',
    templateUrl: 'chart-status-icon.component.html',
    styleUrls: ['chart-status-icon.component.scss']
  })
export class ChartStatusIconComponent implements OnInit {
    public labelColor: string;
    public isFull: boolean;
    public labelValue: number;

    constructor(private dataService: ValuePairChartDataService) {}

    public ngOnInit(): void {
        this.dataService.onModelLoaded.subscribe((model: ValuePairChartModel) => {
            this.labelValue = model.input.value1 - model.input.value2;
            this.isFull = this.labelValue === 0;
            this.labelColor = model.additionalColor;
        });
    }
}
