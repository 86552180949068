import { Component, Input, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Assert } from '../../../framework/index';
import {
  ReportDefinition,
  ReportParameter,
  ReportExportType,
  IReportParameterType,
  GenerateReportRequest,
  GenerateReportParameter,
} from '../../models/index';
import { GenerateReportDialogComponent } from '../generate-report-dialog/generate-report-dialog.component';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ReportsManagementService } from '../../services/index';
import { destroyService } from '../../../core/decorators/index';
import { ReportActionType } from '../../models/report-action-type';
import { RnHrsMinWarningDIalogComponent } from '../report-parameters/rn-hrs-min-warning-dialog/rn-hrs-min-warning-dialog.component';
import { RNHrsMinAuditDialog } from '../../models/report-rn-hrs-min-audit';

@Component({
  moduleId: module.id,
  selector: 'slx-report-list',
  templateUrl: 'report-list.component.html',
  styleUrls: ['report-list.component.scss'],
})
export class ReportListComponent {
  isAlert: boolean = false;
  @Input()
  public set reports(value: ReportDefinition[]) {
    this.m_reports = value;
  }

  public get reports(): ReportDefinition[] {
    return this.m_reports;
  }

  private reportsManagementService: ReportsManagementService;
  private m_reports: ReportDefinition[];

  constructor(private modalService: ModalService, reportsManagementService: ReportsManagementService) {
    this.reportsManagementService = reportsManagementService;
  }

  public onClick(report: ReportDefinition): void {
    Assert.isNotNull(report, 'report');
    let selectedReport: ReportDefinition = this.reportsManagementService.selectReport(report);
    let generateReportDialogComponent: GenerateReportDialogComponent = GenerateReportDialogComponent.openDialog(
      selectedReport,
      this.modalService,
      (result: boolean): void => {
        if (result) {
          if (report.parameters.find(x => x.name == "Show_dates")) {
            let totalHr = report.parameters.find(x => x.name == "Total_hrs");
            let zeroHr = report.parameters.find(x => x.name == "Zero_hrs");
            let consecHr = report.parameters.find(x => x.name == "consecutive_hrs");
            let showDate = report.parameters.find(x => x.name == "Show_dates");
            let isShowDates  = showDate.defaultValue;
            let isZeroHrs = zeroHr.defaultValue;
            let isTotalHrs = totalHr.defaultValue;
            let isConsecHrs = consecHr.defaultValue;
            showDate.value = isShowDates!=true ? 0 :1;
            zeroHr.value = isZeroHrs!=true ? 0 : 1;
            consecHr.value = isConsecHrs != true ? 0 : consecHr.value;
            totalHr.value = isTotalHrs != true ? 0 : totalHr.value;
            if ((isShowDates==true && isZeroHrs != true && isTotalHrs != true&& isConsecHrs != true)|| (isZeroHrs != true && isTotalHrs != true && isConsecHrs != true)) {
              return
            }
            
           
          }
          switch (generateReportDialogComponent.reportAction) {
            case ReportActionType.Preview: {
              this.reportsManagementService.previewReport(report);
              break;
            }
            case ReportActionType.Download: {
              this.reportsManagementService.generateReport(report);
              break;
            }
            default: {
              throw new Error(`Report action ${generateReportDialogComponent.reportAction} is not supported.`);
            }
          }
        }
      }
    );
  }
}
