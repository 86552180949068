<div class="modal-content">
  <div class="modal-body">
    <div class="message-block">Entered shift overlaps with an existing shifts!</div>
    <div *ngIf="request?.overlap.canReplace" class="option-block"><span class="option-name">Replace</span> - Will replace the existing schedule with quick-edit shift</div>
    <div *ngIf="request?.overlap.canOverride" class="option-block"><span class="option-name">Override</span> - Will adjust start/end time of the existing shift</div>
    <div *ngIf="request?.overlap.canAppend" class="option-block"><span class="option-name">Append</span> - Will adjust start/end time of the quick-edit shift</div>
    <div *ngIf="request?.canCancel" class="option-block"><span class="option-name">Cancel</span> - Cancel current operation</div>
    <div class="exception-list">
       <div *ngFor="let msg of request?.overlap.messages">
          <span class="exception-text">{{msg}}</span>
       </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="buttons-container">
      <button *ngIf="request?.overlap.canReplace" type="button" (click)="onReplace()" class="theme-button-cancel margin-r">Replace</button>
      <button *ngIf="request?.overlap.canOverride" type="button" (click)="onOverride()" class="theme-button-cancel margin-r">Override</button>
      <button *ngIf="request?.overlap.canAppend" type="button" (click)="onAppend()" class="theme-button-cancel margin-r">Append</button>
      <button *ngIf="request?.canCancel" type="button" (click)="onCancel()" class="theme-button-cancel">Cancel</button>
   </div>
  </div>
</div>
