<div class="toggler" (click)="onAccessClick()">
  <i [ngClass]="{
      'far': rowItem.mapByRole[roleId].access==='edit' || rowItem.mapByRole[roleId].access==='none' || rowItem.mapByRole[roleId].access==='view' || rowItem.mapByRole[roleId].access==='masked',
      'fa-check-square': rowItem.mapByRole[roleId].access==='edit',
      'fa-square': rowItem.mapByRole[roleId].access==='none',
      'fa-eye': rowItem.mapByRole[roleId].access==='view',
      'fa-eye-slash': rowItem.mapByRole[roleId].access==='masked',
      'fab fa-delicious': rowItem.mapByRole[roleId].access==='mixed',
      'dirty': rowItem.dirtyByRole[roleId]
    }"
    aria-hidden="true"></i>
</div>
