import { Directive, ElementRef, Input, ContentChild, AfterContentInit } from '@angular/core';
import { Validators, NgModel } from '@angular/forms';
import { IFieldPolicy } from '../../../core/models/index';
import { CommonValidators } from '../../validators/common-validators';
import * as _ from 'lodash';

//TODO remove after sections refactoring
@Directive({
  selector: '[fieldPolicy][formControlName],[fieldPolicy][formControl],[fieldPolicy][ngModel]',
})
export class FieldPolicyDirective implements AfterContentInit {

  @Input()
  public set fieldPolicy(policy: IFieldPolicy) {
    if (!policy) {
      return;
    }
    this.setNativePolicies(policy);
  }
  private elementRef: ElementRef;
  private staticValidators: any[];
  @ContentChild(NgModel, {static: true}) private model: NgModel;
  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
    this.staticValidators = [];
  }
  public ngAfterContentInit(): void {
    let m: any = this.model;
    this.staticValidators = [];
    _.forEach(m._rawValidators, (v: any) => {
      this.staticValidators.push(v);
    });
  }
  private setNativePolicies(policy: IFieldPolicy): void {
    let validators: any[] = [];
    _.forEach(this.staticValidators, (v: any) => {
      validators.push(v);
    });

    if (!this.elementRef || !this.elementRef.nativeElement) {
      return;
    }
    if (policy.isEditable) {
      this.elementRef.nativeElement.removeAttribute('readonly');
    } else {
      this.elementRef.nativeElement.setAttribute('readonly', true);
    }
    if (policy.isRequired) {
      this.elementRef.nativeElement.setAttribute('required', true);
      validators.push(Validators.required);
    } else {
      this.elementRef.nativeElement.removeAttribute('required');
    }
    if (policy.minValue) {
      validators.push(CommonValidators.min(policy.minValue));
    }
    if (policy.maxValue) {
      validators.push(CommonValidators.max(policy.maxValue));
    }

    this.model.control.setValidators(null);
    if (validators.length > 0) {
      this.model.control.setValidators(validators);
    }
  }
}


