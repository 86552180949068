<kendo-treeview class="modules-list-configuration"
      [nodes]="menuItems"
      textField="text"
      kendoTreeViewExpandable

      kendoTreeViewHierarchyBinding
      childrenField="childs"
      >
      <ng-template kendoTreeViewNodeTemplate let-dataItem let-index="index">
          <span class="fav-item">
            <i aria-hidden="true" [class]="'icon fa ' + dataItem.menuItemMapping.icon"></i>
            <div class="fav-name" [title]="dataItem.displayName"
                [ngClass]="{'top-level': index.length < 3}">{{ dataItem.displayName }}</div>
            <i aria-hidden="true" class="fav-icon-pointer star-icon far fa-star"
                *ngIf="canBeFavorite(dataItem)"
                [ngClass]="{ selected: dataItem.isFavorite, fas: dataItem.isFavorite }"
                (click)="onStarClicked(dataItem)"></i>
          </span>
      </ng-template>

</kendo-treeview>
