import { ScheduleCycle } from '../../../../app/organization/models';
import { PostScheduleSettings } from './../../models/open-shift-management/post-schedule-settings';
import { EventEmitter, Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';

import { IOpenShiftMessageCountChangedEventArgs, IOpenShiftCountChangedEventArgs, IOpenShiftDailySDetailsSelectedArgs, IPartnerShiftCountChangedEventArgs } from '../../models/index';

@Injectable()
export class OpenShiftManagementManagementService {
  //TODO: implement retrieve of post schedule settings from db
  public postSettings: PostScheduleSettings;
  public dataEmitter = new EventEmitter<any>();
  public onMessageCountChanged$: ReplaySubject<IOpenShiftMessageCountChangedEventArgs>;
  public onPartnerShiftCountChanged$: ReplaySubject<IPartnerShiftCountChangedEventArgs>;
  public onOpenShiftCountChanged$: ReplaySubject<IOpenShiftCountChangedEventArgs>;
  public onOpenShiftCountChangedByPartialShift$: ReplaySubject<IOpenShiftCountChangedEventArgs>;
  public selectOpenShiftsDailyDetails$: ReplaySubject<IOpenShiftDailySDetailsSelectedArgs>;
  public onShowFilledShiftsChanged$: ReplaySubject<boolean>;
  public onPostSettingsLoaded$: ReplaySubject<PostScheduleSettings>;
  public loading$: Subject<boolean>;
  public canPostSchedule: boolean;
  public canEditOpenShiftCount: boolean;
  public canApproveDenyPartnerShiftCount: boolean;
  public canSendSMS:boolean;
  public isIncreaseFrequencyofShiftsPostedNotifications: boolean;
  public set showFilledShifts(value: boolean) {
    this.m_showFilledShifts = value;
    this.onShowFilledShiftsChanged$.next(value);
  }

  public get showFilledShifts(): boolean {
    return this.m_showFilledShifts;
  }

  private m_showFilledShifts: boolean;
  public selectedScheduleCycleData$: ReplaySubject<ScheduleCycle>;
  public selectedScheduleCycle: ScheduleCycle;

  constructor() {
    this.onMessageCountChanged$ = new ReplaySubject(1);
    this.onPartnerShiftCountChanged$ = new ReplaySubject(1);
    this.onOpenShiftCountChanged$ = new ReplaySubject(1);
    this.onOpenShiftCountChangedByPartialShift$ = new ReplaySubject(1);
    this.selectOpenShiftsDailyDetails$ = new ReplaySubject(1);
    this.onShowFilledShiftsChanged$ = new ReplaySubject(1);
    this.onPostSettingsLoaded$ = new ReplaySubject(1);
    this.loading$ = new Subject();
    this.selectedScheduleCycleData$ = new ReplaySubject(1);
  }

  public setCanPostSchedule(value: boolean): void {
    this.canPostSchedule = value;
  }

  public setCanEditOpenShiftCount(value: boolean): void {
    this.canEditOpenShiftCount = value;
  }
  public setCanApproveDenyPartnerShiftCount(value:boolean): void {
    this.canApproveDenyPartnerShiftCount=value;
  }

  public setCanSendSMS(value:boolean):void{
    this.canSendSMS = value;
  }

  public onMessageCountChanged(args: IOpenShiftMessageCountChangedEventArgs): void {
    this.onMessageCountChanged$.next(args);
  }

  public onPartnerShiftCountChanged(args: IPartnerShiftCountChangedEventArgs): void {
    this.onPartnerShiftCountChanged$.next(args);
  }

  public onOpenShiftCountChanged(args: IOpenShiftCountChangedEventArgs): void {
    this.onOpenShiftCountChanged$.next(args);
  }

  public onOpenShiftCountChangedByPartialShift(args: IOpenShiftCountChangedEventArgs): void {
    this.onOpenShiftCountChangedByPartialShift$.next(args);
  }

  public selectDailyDetails(args: IOpenShiftDailySDetailsSelectedArgs): void {
    this.selectOpenShiftsDailyDetails$.next(args);
  }

  public postSettingsLoaded(args: PostScheduleSettings): void {
    this.postSettings = args;
    this.onPostSettingsLoaded$.next(args);
  }

  public updatePostSettingsByCycle(args: ScheduleCycle): void {
    this.postSettings.startDate = args.startDate.toDate();
    this.postSettings.endDate = args.endDate.toDate();
  }

  public changeLoadingState(isShown: boolean): void {
    this.loading$.next(isShown);
  }
  public isCheckedEmitter(data:any){
    this.dataEmitter.emit(data);
}

public getSelectedScheduleCycle(args: ScheduleCycle): void {
  this.selectedScheduleCycle = args;
  this.selectedScheduleCycleData$.next(args);
}
}
