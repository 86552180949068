<div class="employers">
  <div class="modal-content">
    <div class="modal-body">
        <div class="row">
          <div class="col-sm-12  col-md-12 col-lg-12 div-tbl-header">
              <span><strong>This employer cannot be deleted because the following organizations are assigned to it:</strong></span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12  col-lg-12 div-tbl-body content-sec">
            <ul>  
              <li *ngFor="let item of orgDialogOptions?.organization">
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
        
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
    </div>
  </div>
</div>
