import { Component, Input, ViewEncapsulation, TemplateRef } from '@angular/core';
import { TreeNodeModel } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-tree-node-content',
  encapsulation: ViewEncapsulation.Emulated,
  templateUrl: 'tree-node-content.component.html'
})
export class TreeNodeContentComponent {
  @Input() public node: TreeNodeModel;
  @Input() public index: number;
  @Input() public template: TemplateRef<any>;
}
