<form novalidate #form="ngForm">
  <div class="row">
    <div class="col-sm-3">
      <slx-input-decorator>
        <slx-date-picker-ngx slx-input date
          [(ngModel)]="startDate"
          [readonly]="true"
          placeholder="Budget Start Date"
          name="startDate"
        ></slx-date-picker-ngx>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 pull-right">
      <div class="flex-horizontal">
        <button type="button" (click)="onDiscard()" class="theme-button-cancel">Discard</button>
        <button type="button" (click)="onSave()" [disabled]="!form.valid" class="theme-button-apply">Save</button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-kendo-number slx-input
          [(ngModel)]="editedBudgetCensus.maximumCapacity"
          [decimals]="2"
          [min]="0"
          [slxMin]="0"
          max="999999999999"
          slxMax="999999999999"
          [required]="true"
          placeholder="Maximum Capacity"
          name="maximumCapacity"
          format="###.##"
          autoCorrect="true"
        ></slx-kendo-number>
        <span errorMessage for="min">The value should be greater than 0</span>
        <span errorMessage for="number"></span>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
    <div class="col-sm-6 col-lg-3">
      <slx-input-decorator>
        <slx-kendo-number slx-input
          [(ngModel)]="editedBudgetCensus.budgetedCensus"
          [decimals]="2"
          [min]="0"
          [slxMin]="0"
          [slxMax]="editedBudgetCensus.maximumCapacity"
          [max]="editedBudgetCensus.maximumCapacity"
          [required]="true"
          placeholder="Budgeted Census"
          name="budgetedCensus"
          format="###.##"
          autoCorrect="true"
        ></slx-kendo-number>
        <span errorMessage for="min">The value should be greater than 0</span>
        <span errorMessage for="max">The value should be less than Maximum Capacity</span>
        <span errorMessage for="number"></span>
        <span errorMessage for="required"></span>
      </slx-input-decorator>
    </div>
  </div>

</form>
