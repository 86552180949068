import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { GroupResult, orderBy, groupBy, State } from '@progress/kendo-data-query';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';
import { ScheduleApiService } from '../../services/index';
import { KendoGridStateHelper } from '../../../common/models/index';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { IRangeDates } from '../../../common/models/range-dates';
var PostedSchedulesComponent = /** @class */ (function () {
    function PostedSchedulesComponent(scheduleApiService) {
        var _this = this;
        this.pageSize = 50;
        Assert.isNotNull(scheduleApiService, 'scheduleApiService');
        this.scheduleApiService = scheduleApiService;
        this.gridState = new KendoGridStateHelper();
        this.gridState.state.sort = [{ field: 'scheduleCycleStartDate', dir: 'asc' }];
        this.gridEventSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.appConfig = appConfig;
        this.state = {
            isLoading: false
        };
        this.startDate = moment().startOf('year').toDate();
        this.endDate = moment().endOf('year').toDate();
    }
    PostedSchedulesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state.isLoading = true;
        this.orglevelSubscription = this.orgLevel$
            .subscribe(function (orgLevel) {
            _this.currentOrgLevel = orgLevel;
            _this.doLoad();
        });
    };
    PostedSchedulesComponent.prototype.doLoad = function () {
        var _this = this;
        this.state.isLoading = true;
        this.scheduleApiService.getPostedSchedules(this.currentOrgLevel.id, this.startDate, this.endDate)
            .then(function (postedSchedules) {
            _this.postedSchedules = postedSchedules;
            _this.state.isLoading = false;
            _this.refreshGrid();
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    PostedSchedulesComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.doLoad();
    };
    PostedSchedulesComponent.prototype.ngOnDestroy = function () {
        if (this.gridEventSubscription) {
            this.gridEventSubscription.unsubscribe();
        }
    };
    PostedSchedulesComponent.prototype.refreshGrid = function () {
        if (!this.postedSchedules) {
            this.gridState.view = null;
            return;
        }
        var sortedRecords = orderBy(this.postedSchedules, this.gridState.state.sort);
        var groupedRecords = groupBy(sortedRecords, this.gridState.state.group);
        var pagedRecords = sortedRecords.slice(this.gridState.state.skip, this.gridState.state.skip + this.pageSize);
        this.gridState.view = {
            data: groupedRecords,
            total: groupedRecords.length
        };
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PostedSchedulesComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PostedSchedulesComponent.prototype, "orglevelSubscription", void 0);
    return PostedSchedulesComponent;
}());
export { PostedSchedulesComponent };
