import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../../framework/index';
import { BenefitEligibilityRulesApiService } from './benefit-eligibility-rules-api.service';
import { BenefitEligibilityRule, BenefitEligibilityRulesChangeRequest, BenefitEligibilityRuleStatus, } from '../../models/index';
var BenefitEligibilityRulesManagementService = /** @class */ (function () {
    function BenefitEligibilityRulesManagementService(apiService) {
        this.apiService = apiService;
        this.rulesByTierName = {};
        this.loading$ = new Subject();
        this.benefitEligibilityRuleById$ = new ReplaySubject(1);
        this.benefitEligibilityRulesByTierId$ = new ReplaySubject(1);
        this.benefitEligibilityRulesNotBelongsToTier$ = new ReplaySubject(1);
    }
    BenefitEligibilityRulesManagementService.prototype.destroy = function () {
        this.loading$.complete();
        this.benefitEligibilityRuleById$.complete();
        this.benefitEligibilityRulesByTierId$.complete();
        this.benefitEligibilityRulesNotBelongsToTier$.complete();
    };
    BenefitEligibilityRulesManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    BenefitEligibilityRulesManagementService.prototype.subscribeToLoadBenefitRuleById = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitEligibilityRuleById$.subscribe(callback);
    };
    BenefitEligibilityRulesManagementService.prototype.subscribeToLoadBenefitRulesByTierId = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitEligibilityRulesByTierId$.subscribe(callback);
    };
    BenefitEligibilityRulesManagementService.prototype.subscribeToLoadBenefitRulesNotBelongsToTier = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitEligibilityRulesNotBelongsToTier$.subscribe(callback);
    };
    BenefitEligibilityRulesManagementService.prototype.loadBenefitEligibilityRulesByTierId = function (benefitTierId, benefitTierName, isEditMode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var benefitEligibilityRules, existingBenefitEligibilityRules, rulesPair, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.getBenefitEligibilityRulesByTierId(benefitTierId)];
                    case 2:
                        benefitEligibilityRules = _a.sent();
                        existingBenefitEligibilityRules = isEditMode
                            ? _.merge(benefitEligibilityRules, this.rulesByTierName[benefitTierName] || [])
                            : benefitEligibilityRules;
                        rulesPair = {
                            key: benefitTierName,
                            value: existingBenefitEligibilityRules,
                        };
                        this.rulesByTierName[benefitTierName] = existingBenefitEligibilityRules;
                        this.benefitEligibilityRulesByTierId$.next(rulesPair);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitEligibilityRulesManagementService.prototype.getExistingBenefitEligibilityRulesByTierName = function (benefitTierName) {
        var benefitEligibilityRules = this.rulesByTierName[benefitTierName] || [];
        return benefitEligibilityRules;
    };
    BenefitEligibilityRulesManagementService.prototype.loadBenefitEligibilityRuleById = function (ruleId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var benefitEligibilityRule, e_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.getBenefitEligibilityRule(ruleId)];
                    case 2:
                        benefitEligibilityRule = _a.sent();
                        this.benefitEligibilityRuleById$.next(benefitEligibilityRule);
                        return [3 /*break*/, 5];
                    case 3:
                        e_2 = _a.sent();
                        console.error(e_2);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitEligibilityRulesManagementService.prototype.loadBenefitEligibilityRulesNotBelongsToTier = function (benefitTierId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var benefitEligibilityRules, e_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.getBenefitEligibilityRulesNotBelongsToTier(benefitTierId)];
                    case 2:
                        benefitEligibilityRules = _a.sent();
                        this.benefitEligibilityRulesNotBelongsToTier$.next(benefitEligibilityRules);
                        return [3 /*break*/, 5];
                    case 3:
                        e_3 = _a.sent();
                        console.error(e_3);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    BenefitEligibilityRulesManagementService.prototype.changeEligibilityRules = function (orgLevelId, benefitTierId, benefitEligibilityRules) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var rulesToAdd, rulesToUpdate, rulesToDelete, changeRequest, e_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        rulesToAdd = _.filter(benefitEligibilityRules, function (item) { return item.status === BenefitEligibilityRuleStatus.Added; });
                        rulesToUpdate = _.filter(benefitEligibilityRules, function (item) { return item.status === BenefitEligibilityRuleStatus.Updated; });
                        rulesToDelete = _.chain(benefitEligibilityRules)
                            .filter(function (item) { return item.status === BenefitEligibilityRuleStatus.Deleted; })
                            .map(function (item) { return item.id; })
                            .value();
                        changeRequest = new BenefitEligibilityRulesChangeRequest({
                            rulesToAdd: rulesToAdd,
                            rulesToUpdate: rulesToUpdate,
                            rulesToDelete: rulesToDelete,
                        });
                        return [4 /*yield*/, this.apiService.changeEligibilityRules(orgLevelId, benefitTierId, changeRequest)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_4 = _a.sent();
                        console.error(e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    BenefitEligibilityRulesManagementService.prototype.deleteBenefitEligibilityRule = function (benefitEligibilityRuleId, tierName) {
        if (!benefitEligibilityRuleId) {
            return;
        }
        var benefitRulesByTierName = this.rulesByTierName[tierName] || [];
        var alreadyExistedBenefitRule = _.find(benefitRulesByTierName, function (benefitRule) { return benefitRule.id === benefitEligibilityRuleId; });
        if (!alreadyExistedBenefitRule) {
            return;
        }
        var newBenefitRules = [];
        if (alreadyExistedBenefitRule.status === BenefitEligibilityRuleStatus.Added) {
            newBenefitRules = _.without(benefitRulesByTierName, alreadyExistedBenefitRule);
        }
        else {
            alreadyExistedBenefitRule.status = BenefitEligibilityRuleStatus.Deleted;
            newBenefitRules = benefitRulesByTierName.slice();
        }
        this.updateRulesByTierName(newBenefitRules, tierName);
    };
    BenefitEligibilityRulesManagementService.prototype.updateBenefitEligibilityRule = function (updatedBenefitRule, tierName) {
        if (!updatedBenefitRule) {
            return;
        }
        var benefitRulesByTierName = this.rulesByTierName[tierName] || [];
        var newBenefitRules = _.map(benefitRulesByTierName, function (benefitRule) {
            return benefitRule.id === updatedBenefitRule.id ? updatedBenefitRule : benefitRule;
        });
        this.updateRulesByTierName(newBenefitRules, tierName);
    };
    BenefitEligibilityRulesManagementService.prototype.addBenefitEligibilityRule = function (benefitEligibilityRule, tierName) {
        var benefitRulesByTierName = this.rulesByTierName[tierName] || [];
        var existingRule = _.find(benefitRulesByTierName, function (rule) { return rule.id === benefitEligibilityRule.id; });
        if (existingRule) {
            benefitRulesByTierName = _.without(benefitRulesByTierName, existingRule);
        }
        var newBenefitRules = benefitRulesByTierName.concat([benefitEligibilityRule]);
        this.updateRulesByTierName(newBenefitRules, tierName);
    };
    BenefitEligibilityRulesManagementService.prototype.updateRulesByTierName = function (newBenefitRules, tierName) {
        this.rulesByTierName[tierName] = newBenefitRules;
        var rulesPair = {
            key: tierName,
            value: newBenefitRules,
        };
        this.benefitEligibilityRulesByTierId$.next(rulesPair);
    };
    BenefitEligibilityRulesManagementService.prototype.saveRules = function (orgLevelId, benefitTiers) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var changeEligibilityRulesPromises, e_5;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (_.isEmpty(this.rulesByTierName)) {
                            return [2 /*return*/];
                        }
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, 5, 6]);
                        changeEligibilityRulesPromises = _.map(benefitTiers, function (tier) {
                            var rules = _this.rulesByTierName[tier.name];
                            return !rules ? Promise.resolve() : _this.changeEligibilityRules(orgLevelId, tier.id, rules);
                        });
                        return [4 /*yield*/, Promise.all(changeEligibilityRulesPromises)];
                    case 2:
                        _a.sent();
                        this.clearExistingMappedRules();
                        return [4 /*yield*/, this.refreshTiersRuleList(orgLevelId, benefitTiers)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        e_5 = _a.sent();
                        console.error(e_5);
                        return [3 /*break*/, 6];
                    case 5:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    BenefitEligibilityRulesManagementService.prototype.getUsedRuleNames = function (benefitEligibilityRules, benefitEligibilityRulesNotBelongToTier) {
        var benefitRulesListUsedNames = _.map(benefitEligibilityRules, function (rule) { return rule.name; });
        var benefitExistingRulesListUsedNames = _.map(benefitEligibilityRulesNotBelongToTier, function (rule) { return rule.name; });
        var rulesAlreadyUsedNames = _.concat(benefitRulesListUsedNames, benefitExistingRulesListUsedNames);
        return rulesAlreadyUsedNames;
    };
    BenefitEligibilityRulesManagementService.prototype.addExistigBenefitEligibilityRule = function (benefitRuleToUpdate, benefitName) {
        var updatedBenefitRule = new BenefitEligibilityRule({
            id: benefitRuleToUpdate.id,
            name: benefitRuleToUpdate.name,
            description: benefitRuleToUpdate.description,
            type: benefitRuleToUpdate.type,
            rule: benefitRuleToUpdate.rule,
            ruleV5: benefitRuleToUpdate.ruleV5,
            isDeleted: false,
            modifiedAt: benefitRuleToUpdate.modifiedAt,
            modifiedBy: benefitRuleToUpdate.modifiedBy,
            addedAt: benefitRuleToUpdate.addedAt,
            addedBy: benefitRuleToUpdate.addedBy,
            ruleStatements: benefitRuleToUpdate.ruleStatements,
            status: BenefitEligibilityRuleStatus.Added,
        });
        this.addBenefitEligibilityRule(updatedBenefitRule, benefitName);
        return updatedBenefitRule;
    };
    BenefitEligibilityRulesManagementService.prototype.updateRuleTierName = function (newName, oldName) {
        var rules = this.rulesByTierName[oldName];
        if (rules) {
            this.rulesByTierName[newName] = rules;
            delete this.rulesByTierName[oldName];
        }
    };
    BenefitEligibilityRulesManagementService.prototype.clearExistingMappedRules = function () {
        this.rulesByTierName = {};
    };
    BenefitEligibilityRulesManagementService.prototype.refreshTiersRuleList = function (orgLevelId, benefitTiers) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                _.forEach(benefitTiers, function (tier) {
                    if (_this.rulesByTierName[tier.name]) {
                        _this.loadBenefitEligibilityRulesByTierId(tier.id, tier.name, false);
                        _this.loadBenefitEligibilityRulesNotBelongsToTier(tier.id);
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    return BenefitEligibilityRulesManagementService;
}());
export { BenefitEligibilityRulesManagementService };
