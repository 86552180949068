import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter, ViewChild, AfterViewChecked } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { Assert } from '../../../framework/assert/assert';
import { NumberFormatOptions } from '../../../common/models/index';

type Type = 'required' | 'min' | 'max';
class Types {
  public static required: Type = 'required';
  public static min: Type = 'min';
  public static max: Type = 'max';
}

@Component({
  moduleId: module.id,
  selector: 'slx-number',
  templateUrl: 'number.component.html',
})
export class NumberComponent implements ControlValueAccessor, AfterViewChecked {
  @Input()
  public step: number = 1;
  @Input()
  public min: number;
  @Input()
  public max: number;
  @Input()
  public decimals: number = 0;
  @Input()
  public format: NumberFormatOptions | string = 'n';
  @Input()
  public autoCorrect: boolean = false;
  @Input()
  public required: boolean = false;
  @Input('readonly')
  public isDisabled: boolean = false;
  @Input()
  public placeholder: string = '';
  @Input()
  public autofocus: boolean = false;
  @Input()
  public spinners: boolean = false;

  @Output()
  public blur: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public focus: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('textbox', {static: true})
  public kendoNumber: any;

  public currentValue: number;
 

  private autofocused: boolean = false;
  private onTouchedCallback: () => void;
  private onChangeCallback: (val: any) => void;
  private ngControl: NgControl;

  constructor(ngControl: NgControl) {
    this.ngControl = ngControl;
    ngControl.valueAccessor = this;
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public ngAfterViewChecked(): void {
    if (this.autofocus && !this.autofocused && this.kendoNumber) {
      this.autofocused = true;
      setTimeout(() => {
        this.kendoNumber.focus();
      }, 0);
    }
  }

  public onValueChanged(value: number): void {
    if (this.isValid(Types.required, value)) {
      const isValidMin: boolean = this.isValid(Types.min, value);
      const isValidMax: boolean = this.isValid(Types.max, value);
      if (!this.autoCorrect && (!isValidMin || !isValidMax)) {
        this.setError(!isValidMin ? 'min' : 'max');

        return;
      }

      this.currentValue = value;
      this.resetError();
    } else if (this.required) {
      this.setError('required');
    }

    this.onChangeCallback(value);
  }

  public writeValue(value?: any): void {
    if (value && typeof value === 'string') {
      value = _.toNumber(value);
    }

    if (this.isValid(Types.required, value)) {
      const isValidMin: boolean = this.isValid(Types.min, value);
      const isValidMax: boolean = this.isValid(Types.max, value);
      if (!this.autoCorrect && (!isValidMin || !isValidMax)) {
        this.setError(!isValidMin ? 'min' : 'max');

        return;
      }

      this.currentValue = value;
      this.resetError();
    } else {
      this.currentValue = null;
      if (this.required) {
        this.setError('required');
      }
    }
  }

  public onBlur(value: any): void {
    this.blur.emit(value);
  }

  public onFocus(value: any): void {
    this.focus.emit(value);
  }

  public get hasPlaceholder(): boolean {
    return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
  }

  private isValid(type: Type, value: number): boolean {
    switch (type) {
      case Types.required:
        return _.isNumber(value);
      case Types.min:
        const min: number = +this.min;
        return _.isNaN(min) ? true : value >= min;
      case Types.max:
        const max: number = +this.max;
        return _.isNaN(max) ? true : value <= max;
      default:
        return false;
    }
  }

  private setError(errName: string): void {
    if (this.ngControl.control) {
      this.ngControl.control.setErrors({ [errName]: true });
    }
  }

  private resetError(): void {
    if (this.ngControl.control) {
      this.ngControl.control.setErrors(null);
    }
  }
}
