export interface IServerFilterDescriptor {
    /**
     * The data item field to which the filter operator is applied.
     */
    field: string;
    /**
     * The filter operator (comparison).
     *
     * The supported operators are:
     *
     * * `"eq"` (equal to)
     * * `"neq"` (not equal to)
     * * `"isnull"` (is equal to null)
     * * `"isnotnull"` (is not equal to null)
     * * `"lt"` (less than)
     * * `"lte"` (less than or equal to)
     * * `"gt"` (greater than)
     * * `"gte"` (greater than or equal to)
     *
     * The following operators are supported for string fields only:
     *
     * * `"startswith"`
     * * `"endswith"`
     * * `"contains"`
     * * `"doesnotcontain"`
     * * `"isempty"`
     * * `"isnotempty"`
     */
    operator: string;
    /**
     * The value to which the field is compared. Has to be of the same type as the field.
     */
    value?: any;
}

export class ServerFilterDescriptor {
    public field: string;
    public operator: string;
    public value?: any;
}
