import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { NavigationMenuItem, NavigationMenuEventType } from '../../models/index';
import { NavigationMenuApiService } from './navigation-menu-api.service';
import { ManagementBaseService } from '../../../core/services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribeInService } from '../../../core/decorators/index';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
var NavigationMenuManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(NavigationMenuManagementService, _super);
    function NavigationMenuManagementService(appStateBusService, navigationMenuApiService) {
        var _this = _super.call(this) || this;
        _this.appStateBusService = appStateBusService;
        _this.navigationMenuApiService = navigationMenuApiService;
        _this.selectedItem = null;
        _this.reloadMenuSubscription = _this.appStateBusService.reloadMenu$.subscribe(function (event) {
            _this.orgLevel = event.orgLevel;
            _this.loadMenu(event.app, event.orgLevel);
        });
        return _this;
    }
    NavigationMenuManagementService.prototype.menuSelected = function (type, menuItem) {
        this.selectedItem = menuItem;
        this.appStateBusService.selectMenu({ type: type, menuItem: menuItem });
    };
    NavigationMenuManagementService.prototype.isMenuSupportOrgLevel = function (menuItem) {
        if (_.isObject(menuItem) && _.isObject(this.orgLevel)) {
            var types = menuItem.supportedOrgTypes;
            return _.indexOf(types, this.orgLevel.type) !== -1 || types.length === 0;
        }
        return false;
    };
    NavigationMenuManagementService.prototype.loadMenu = function (application, orgLevel) {
        var _this = this;
        this.navigationMenuApiService.getApplicationMenu(orgLevel).then(function (menuItems) {
            _this.onLoaded(menuItems);
            _this.appStateBusService.loadedMenu(menuItems);
        });
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], NavigationMenuManagementService.prototype, "reloadMenuSubscription", void 0);
    return NavigationMenuManagementService;
}(ManagementBaseService));
export { NavigationMenuManagementService };
