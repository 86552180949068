import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, DoCheck } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/index';
var KendoGridAlignDetailsDirective = /** @class */ (function () {
    function KendoGridAlignDetailsDirective(grid, elRef, renderer) {
        this.grid = grid;
        this.elRef = elRef;
        this.renderer = renderer;
        this.align = 100; //0..100%
        this.minColumnsAlign = 2;
        this.colOffset = 1;
        this.colCount = 0;
        this.gridRoot = elRef.nativeElement;
    }
    KendoGridAlignDetailsDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.calcAlignDetails();
        this.expandChangeSubscription = this.grid.detailExpand.asObservable().subscribe(function (event) {
            setTimeout(function () {
                _this.calcAlignDetails();
                _this.alignDetails();
            }, 100);
        });
    };
    KendoGridAlignDetailsDirective.prototype.ngDoCheck = function () {
        var _this = this;
        if (this.lastData === this.grid.data) {
            return;
        }
        this.lastData = this.grid.data;
        setTimeout(function () {
            _this.alignDetails();
        }, 100);
    };
    KendoGridAlignDetailsDirective.prototype.onResize = function (event) {
        this.calcAlignDetails();
        this.alignDetails();
    };
    KendoGridAlignDetailsDirective.prototype.calcAlignDetails = function () {
        var _this = this;
        var width = window.innerWidth;
        var p = this.align;
        if (!_.isNumber(p)) {
            p = 100;
        }
        this.colCount = 0;
        var w = width * p / 100;
        var acc = 0;
        var columnChecked = 0;
        this.grid.columns.forEach(function (column) {
            if (!column || column.hidden) {
                return;
            }
            columnChecked++;
            if (columnChecked <= _this.colOffset) {
                return;
            }
            var colW = column.width ? column.width : 10;
            acc += colW;
            if (acc < w) {
                _this.colCount++;
            }
        });
    };
    KendoGridAlignDetailsDirective.prototype.alignDetails = function () {
        var _this = this;
        if (this.colCount > 0) {
            var details = this.gridRoot.getElementsByClassName('k-detail-cell');
            _.forEach(details, function (item) {
                var cols = _.max([_this.colCount, _this.minColumnsAlign]);
                _this.renderer.setAttribute(item, 'colspan', cols.toString());
            });
        }
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], KendoGridAlignDetailsDirective.prototype, "expandChangeSubscription", void 0);
    return KendoGridAlignDetailsDirective;
}());
export { KendoGridAlignDetailsDirective };
