import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { ChangeManagementService } from '../../../common/services/change-management/change-management.service';
import { OrgLevelType } from '../../../state-model/models/index';
import { BudgetApiService } from './budget-api.service';
import { Subject } from 'rxjs/Subject';
var BudgetManagementService = /** @class */ (function () {
    function BudgetManagementService(budgetApiService, changeService) {
        var _this = this;
        this.changeService = changeService;
        this.componentId = 'BudgetManagement';
        this.budgetApiService = budgetApiService;
        this.onSelectionChanged$ = new ReplaySubject(1);
        this.onLoadStatus$ = new ReplaySubject(1);
        this.onDummyLoaded$ = new ReplaySubject(1);
        this.onAdded$ = new Subject();
        this.onDeleted$ = new ReplaySubject(1);
        this.onLoaded$ = new ReplaySubject(1);
        this.onOrgLevelChanged$ = new ReplaySubject(1);
        this.onAddPosition$ = new ReplaySubject(1);
        this.onEditRecord$ = new ReplaySubject(1);
        this.onPositionAdded$ = new ReplaySubject(1);
        this.onGroupEdit$ = new ReplaySubject(1);
        this.onRestoreBudget$ = new ReplaySubject(1);
        this.onBudgetCensusAdjust$ = new ReplaySubject(1);
        this.onBudgetSelect$ = new ReplaySubject(1);
        this.onEditBudgetMode$ = new ReplaySubject(1);
        this.onAddBudgetMode$ = new ReplaySubject(1);
        this.overlapWarning$ = new Subject();
        this.onSaveBudget$ = new ReplaySubject(1);
        this.changeService.setCurrentComponentId(this.componentId);
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            _this.onOrgLevelSelected(o);
        });
    }
    Object.defineProperty(BudgetManagementService.prototype, "addMode", {
        get: function () {
            return this.m_addMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BudgetManagementService.prototype, "editMode", {
        get: function () {
            return this.m_editMode;
        },
        enumerable: true,
        configurable: true
    });
    BudgetManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    BudgetManagementService.prototype.onSaveBudget = function (isSave) {
        this.onSaveBudget$.next(isSave);
    };
    BudgetManagementService.prototype.onLoadStatusChanged = function (isLoading) {
        this.onLoadStatus$.next(isLoading);
    };
    BudgetManagementService.prototype.onAddPosition = function (event) {
        event.orgLevelId = this.selectedOrganizationOrgLevelId;
        this.onAddPosition$.next(event);
    };
    BudgetManagementService.prototype.onEditRecord = function (isEditRecord) {
        this.onEditRecord$.next(isEditRecord);
    };
    BudgetManagementService.prototype.onPositionAdded = function (event) {
        this.onPositionAdded$.next(event);
    };
    BudgetManagementService.prototype.onEditBudgetMode = function (editMode) {
        this.m_editMode = editMode;
        this.onEditBudgetMode$.next(editMode);
        if (editMode) {
            this.changeService.changeNotify();
        }
        else {
            this.changeService.clearChanges();
        }
    };
    BudgetManagementService.prototype.onAddBudgetMode = function (addMode) {
        this.m_addMode = addMode;
        this.onAddBudgetMode$.next(addMode);
        if (addMode) {
            this.changeService.changeNotify();
        }
        else {
            this.changeService.clearChanges();
        }
    };
    BudgetManagementService.prototype.onGroupEdit = function (editMode) {
        this.onGroupEdit$.next(editMode);
    };
    BudgetManagementService.prototype.onRestoreBudget = function (editMode) {
        this.onRestoreBudget$.next(editMode);
    };
    BudgetManagementService.prototype.onBudgetCensusAdjust = function (editMode) {
        this.onBudgetCensusAdjust$.next(editMode);
    };
    BudgetManagementService.prototype.onRecordSelected = function (event) {
        this.selectedRecord = event;
        this.onSelectionChanged$.next(event);
    };
    BudgetManagementService.prototype.onBudgetSelect = function (req) {
        this.currentBudget = req;
        this.onBudgetSelect$.next(req);
    };
    BudgetManagementService.prototype.getDummy = function () {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.budgetApiService.getBudgetDummy(this.currentOrgLevel.id)
            .then(function (result) {
            _this.currentBudget = result;
            _this.onDummyLoaded$.next(result);
            _this.onEditBudgetMode(true);
            _this.m_editMode = false;
            _this.onLoadStatusChanged(false);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    BudgetManagementService.prototype.onLoadRequest = function (req) {
        var _this = this;
        if (req) {
            this.onLoadStatusChanged(true);
            req.orgLevelId = this.currentOrgLevel.id;
            this.currentLoadRequest = req;
            this.budgetApiService.getBudget(req.orgLevelId, req.id)
                .then(function (result) {
                _this.currentBudget = result;
                _this.onLoaded$.next(result);
                _this.onLoadStatusChanged(false);
            })
                .catch(function (reason) {
                _this.onLoadStatusChanged(false);
            });
        }
        else {
            this.currentLoadRequest = null;
            this.currentBudget = null;
            this.onLoaded$.next(null);
        }
    };
    BudgetManagementService.prototype.onAddRequest = function (req, definitions, skipCheck) {
        if (skipCheck === void 0) { skipCheck = false; }
        if (!skipCheck) {
            var overlaps = void 0;
            if (definitions)
                overlaps = this.checkOverlapping(req, definitions);
            if (overlaps) {
                this.warnAboutOverlap(req, true);
                return;
            }
        }
        this.doAdd(req);
    };
    BudgetManagementService.prototype.onSaveRequest = function (req, definitions, skipCheck) {
        if (skipCheck === void 0) { skipCheck = false; }
        if (!skipCheck) {
            var overlaps = void 0;
            if (definitions)
                overlaps = this.checkOverlapping(req, definitions);
            if (overlaps) {
                this.warnAboutOverlap(req, false);
                return;
            }
        }
        this.doSave(req);
    };
    BudgetManagementService.prototype.onDeleteRequest = function (budgetId) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.currentBudget = null;
        this.budgetApiService.deleteBudget(budgetId)
            .then(function (result) {
            _this.onLoadStatusChanged(false);
            _this.onBudgetSelect(null);
            _this.onDeleted$.next(budgetId);
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    BudgetManagementService.prototype.sendOverlapWaning = function (req, isNew) {
        this.warnAboutOverlap(req, isNew);
    };
    BudgetManagementService.prototype.checkOverlapping = function (newBudget, definitions) {
        var hasOverlaps = false;
        var budgetStart = moment(newBudget.startDate);
        var budgetEnd = newBudget.endDate ? moment(newBudget.endDate) : null;
        _.each(definitions, function (definition) {
            if (newBudget.id !== definition.id) {
                var defStart = moment(definition.startDate);
                var defEnd = definition.endDate ? moment(definition.endDate) : null;
                if (defEnd && budgetEnd) {
                    if (budgetStart.isBetween(defStart, defEnd) || budgetEnd.isBetween(defStart, defEnd)) {
                        hasOverlaps = true;
                    }
                    else if (budgetStart.isSame(defStart) || budgetEnd.isSame(defEnd)) {
                        hasOverlaps = true;
                    }
                }
                else if (defEnd) {
                    // new budget has no end date
                    if (budgetStart.isSameOrBefore(defStart)) {
                        hasOverlaps = true;
                    }
                }
                else if (budgetEnd) {
                    // existing budget has no end date
                    if (budgetEnd.isSameOrAfter(defStart)) {
                        hasOverlaps = true;
                    }
                }
            }
        });
        return hasOverlaps;
    };
    BudgetManagementService.prototype.warnAboutOverlap = function (req, isNew) {
        this.overlapWarning$.next({ budget: req, isNew: isNew });
    };
    BudgetManagementService.prototype.doAdd = function (req) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.currentBudget = req;
        this.budgetApiService.addBudget(req)
            .then(function (result) {
            _this.onAdded$.next(result);
            _this.onAddBudgetMode(false);
            _this.onEditBudgetMode(false);
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    BudgetManagementService.prototype.doSave = function (req) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.currentBudget = req;
        this.budgetApiService.saveBudget(req)
            .then(function (result) {
            _this.onLoaded$.next(result);
            _this.onSaveBudget(true);
            _this.onLoadStatusChanged(false);
            _this.changeService.clearChanges();
        })
            .catch(function (reason) {
            _this.onLoadStatusChanged(false);
        });
    };
    BudgetManagementService.prototype.onOrgLevelSelected = function (o) {
        this.m_addMode = false;
        this.m_editMode = false;
        this.setOrgLevel(o);
        this.onOrgLevelChanged$.next(this.selectedOrganizationOrgLevelId);
    };
    BudgetManagementService.prototype.setOrgLevel = function (o) {
        if (o.type === OrgLevelType.department) {
            this.selectedOrganizationOrgLevelId = 0; // o.parentId;
        }
        else if (o.type === OrgLevelType.organization) {
            this.selectedOrganizationOrgLevelId = o.id;
        }
        else {
            this.selectedOrganizationOrgLevelId = 0;
        }
        this.currentOrgLevel = o;
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], BudgetManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], BudgetManagementService.prototype, "orgLevelSubscription", void 0);
    return BudgetManagementService;
}());
export { BudgetManagementService };
