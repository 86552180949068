import { StaffingPredictorManagementService } from '../../services/index';
import { StaffingPredictorOrganizationInfo, StaffingRatingOrganization } from '../../models/index';
import { PbjNavigationService } from '../../../../common/services/index';
import { ActivatedRoute, Router } from '@angular/router';
var StaffingPredictorStarRatingComponent = /** @class */ (function () {
    function StaffingPredictorStarRatingComponent(managementService, router, route) {
        this.managementService = managementService;
        this.router = router;
        this.route = route;
        this.isLoading = true;
    }
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "hasError", {
        get: function () {
            return !this.hasData || this.noCcn || this.noCensus || this.noCmsData || this.noHoursData || this.rnZeroDays;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "hasData", {
        get: function () {
            return this.dataModel != null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "noCcn", {
        get: function () {
            return this.hasData ? this.dataModel.organization.noCcn : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "noCensus", {
        get: function () {
            return this.hasData && !this.noCcn ? this.dataModel.organization.noCensus : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "noHoursData", {
        get: function () {
            return this.hasData && !this.noCcn && !this.noCensus ? this.dataModel.organization.noHoursData : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "noCmsData", {
        get: function () {
            return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.dataModel.organization.noCmsData : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "rnZeroDays", {
        get: function () {
            return this.hasData && !this.noCcn && !this.noCensus && !this.noHoursData ? this.dataModel.organization.rnZeroDays : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(StaffingPredictorStarRatingComponent.prototype, "outdatedData", {
        get: function () {
            return this.hasData && !this.noCcn ? this.dataModel.organization.outdatedData : false;
        },
        enumerable: true,
        configurable: true
    });
    StaffingPredictorStarRatingComponent.prototype.goToConfigScreen = function () {
        var navigation = new PbjNavigationService(this.router, this.route);
        navigation.NavigateToPbjOrganizationsConfiguration();
    };
    return StaffingPredictorStarRatingComponent;
}());
export { StaffingPredictorStarRatingComponent };
