import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { dateTimeUtils } from '../utils/index';
import * as moment from 'moment';
@Pipe({
  name: 'slxTimeToNow',
  pure: true
})
@Injectable()
export class TimeToNowPipe implements PipeTransform {
  public transform(date: Date): string {
    return moment.duration(moment().diff(date)).humanize();
  }
}
