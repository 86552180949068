import { Lookup, LookupType, ScheduleCycle, ShiftDefinition } from '../../../../organization/models/index';
import { IndividualScheduleManagementService } from './../../../services/individual-schedule/individual-schedule-management.service';
import {
  Component, ViewChild, OnInit, OnDestroy, Input, Output, EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig, IApplicationConfig } from '../../../../app.config';
import { CalendarDataService } from '../../../../core/services/index';
import { DailyData, DateRange, WeeklyData } from '../../../../core/models/index';

import { IndSchEmployeeSchedulesRequest, IndSchDefWrapper, IndSchEntryWrapper } from '../../../models/index';
import { ScheduledShiftDefinition } from '../../../../organization/models/index';
import { IndividualScheduleApiService, ScheduleEntryApiService } from '../../../services/index';
import { LookupApiService } from '../../../../organization/services/lookup/lookup-api.service';
import { DateTimeService } from '../../../../common/services/date-time/date-time.service';
import { IndividualScheduleEmpManagementService } from '../../../services/index';
import { EmployeeShiftEditorComponent } from '../../../../organization/components/index';
import { EmployeeShift } from '../../../../organization/models/index';
import { ModalService } from '../../../../common/services';
import { ConfirmOptions, ConfirmDialogComponent, ConfirmDialog2Component } from '../../../../common';
import { unsubscribe, destroyService, mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { LookupService } from './../../../../organization/services/lookup/lookup.service';
import { DatePipe } from '@angular/common';
import { AppServerConfig } from '../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../../app-settings/services';

@Component({
  moduleId: module.id,
  selector: 'slx-individual-schedule-entries-editor',
  templateUrl: 'individual-schedule-entries-editor.component.html',
  styleUrls: ['individual-schedule-entries-editor.component.scss']
})
export class IndividualScheduleEntriesEditorComponent implements OnInit, OnDestroy {

  @Input()
  public cycleStartDate: moment.Moment;
  @Input()
  public cycleEndDate: moment.Moment;
  @Input()
  public showEmployeeName: boolean = true;

  @Output()
  public onHasChanges = new EventEmitter<boolean>();

  @Input()
  public hasScheduleViewParams: boolean;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public state: {
    startEditMode: boolean;
    editMode: boolean;
    isValid: boolean;
    isDirty: boolean;
    isFatalOverlapping: boolean;
  };

  public request: IndSchEmployeeSchedulesRequest;
  public appConfig: IApplicationConfig;
  public employeeShift: EmployeeShift;
  public scheduleDateRange: DateRange;
  public shiftLookup: Lookup;
  public isShiftLoadingState = true;
  public isModifyPayPeriodApproved = false ;


  public get scheduledEntries(): IndSchDefWrapper {
    return this.m_scheduledEntries;
  }

  public set scheduledEntries(value: IndSchDefWrapper) {
    this.m_scheduledEntriesOriginal = this.copyEntries(this.flatEntries(value));
    this.m_scheduledEntries = value;
  }

  public dayColumns: DailyData<any>[];
  public checkedEntries: IndSchEntryWrapper[];
  private requestSubscription: Subscription;
  @ViewChild('shiftEditor', { static: false })
  private shiftEditor: EmployeeShiftEditorComponent;
  private m_scheduledEntries: IndSchDefWrapper;
  private m_scheduledEntriesOriginal: IndSchEntryWrapper[];
  private empHiredDate: moment.Moment;
  private empTerminatedDate: moment.Moment;
  private empRehireDate: moment.Moment;

  constructor(lookupApiService: LookupApiService,
    private scheduleEntryApiService: ScheduleEntryApiService,
    private individualScheduleApiService: IndividualScheduleApiService,
    public individualScheduleManagementService: IndividualScheduleManagementService,
    private individualScheduleEmpManagementService: IndividualScheduleEmpManagementService,
    private calendarDataService: CalendarDataService,
    private dateTimeService: DateTimeService,
    private modalService: ModalService,
    private lookupService: LookupService,
    private datePipe: DatePipe,
    private appSettingsManageService: AppSettingsManageService
  ) {
    this.state = {
      startEditMode: false,
      editMode: false,
      isValid: true,
      isDirty: false,
      isFatalOverlapping: false,
    };
  }

  public ngOnInit(): void {
    this.appConfig = appConfig;
    this.requestSubscription = this.individualScheduleEmpManagementService.onRequestChanged$.subscribe((request: IndSchEmployeeSchedulesRequest) => {
      this.loadShiftDefinitionsLookup(request);
      this.setRequest(request);
    });
    this.getSettings();
  }

  public ngOnDestroy(): void {
    if (this.requestSubscription) {
      this.requestSubscription.unsubscribe();
    }
  }

  private emitChanges(): void {
    this.onHasChanges.emit(true);
  }

  public setRequest(request: IndSchEmployeeSchedulesRequest): void {
    if (!request) {
      this.request = null;
      return;
    }
    if (!this.employeeShift || !this.request || (this.request.employeeId !== request.employeeId)) {
      this.employeeShift = new EmployeeShift();
    }
    this.request = request;
    this.state.startEditMode = false;
    this.state.editMode = false;
    this.dayColumns = this.calendarDataService.ExtendRangeToDailyData(moment(this.request.startDate), 7, null);
    this.loadScheduledEntries();
  }

  private setScheduleDateRange(): void {
    if (!(_.isNil(this.scheduledEntries))) {
      const startDate = _.first(_.values<DailyData<IndSchEntryWrapper>>(_.first(this.scheduledEntries.weeklyData).days));
      const endDate = _.last(_.values<DailyData<IndSchEntryWrapper>>(_.last(this.scheduledEntries.weeklyData).days));
      this.scheduleDateRange = new DateRange(startDate.startOfDay.toDate(), endDate.startOfDay.toDate());
    } else {
      this.scheduleDateRange = null;
    }
  }

  public onEditModeStart(): void {
    if(_.isNull(this.homePositionId)) return;
    this.state.startEditMode = true;
    this.state.editMode = true;
  }

  public onEditMode(): void {
    this.state.startEditMode = false;
  }

  public onCancelClicked(): void {
    this.state.startEditMode = false;
    this.state.editMode = false;
    this.loadScheduledEntries();
  }

  public onAcceptClicked(): void {
    this.saveChanges();
  }

  private saveChanges(): void {
    this.state.startEditMode = false;
    this.state.editMode = false;
    this.individualScheduleEmpManagementService.onUILock(true);
    if (!this.checkedEntries) {
      this.validateEntries();
    }
    let entries: IndSchEntryWrapper[] = _.filter(this.checkedEntries, (entry: IndSchEntryWrapper) => { return entry.isDirty; });
    let datesToClear: Date[] = this.identifyDatesOnScheduleToClear(this.checkedEntries, this.m_scheduledEntriesOriginal);
    if (this.isModifyPayPeriodApproved) {
      let req = this.formReqForcheckApprovedPayperiod(this.checkedEntries,datesToClear );
      this.scheduleEntryApiService.getApprovedPayPeriod(req).then((res: any) => {
        if (res == true) {
          this.showWarningMessage();
        } else {
          this.saveEmployeeScheduleDefinitionsServiceCall(this.request.employeeId,entries,datesToClear);          
        }
      });
    } else {
      this.saveEmployeeScheduleDefinitionsServiceCall(this.request.employeeId,entries,datesToClear);
    }

  }


  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.isModifyPayPeriodApproved = config.ModifySchedulesApprovedinPayPeriods;
    console.log("modifyPayperiod", this.isModifyPayPeriodApproved);
  }

  public saveEmployeeScheduleDefinitionsServiceCall(empId : number,entries : IndSchEntryWrapper[],datesToClear : Date[]):void {
    this.individualScheduleApiService.saveEmployeeScheduleDefinitions(empId, entries, datesToClear)
    .then((result: number) => {
      this.individualScheduleEmpManagementService.isDirty = false;
      this.loadScheduledEntries();
      this.individualScheduleEmpManagementService.employeeUpdated();
      this.emitChanges();
    });
  }

  private formReqForcheckApprovedPayperiod(entries: IndSchEntryWrapper[], datesToClear : any): any {
    let req = [];
    entries.forEach((x: any) => {
      if (x.entryDef.shifts.length > 0) {
        x.entryDef.shifts.forEach((y: any) => {
          let obj = { EmpId: this.request.employeeId, Startdate: this.datePipe.transform(y.start, 'MM-dd-yyyy HH:mm:ss'), Enddate: this.datePipe.transform(y.end, 'MM-dd-yyyy HH:mm:ss') }
          req.push(obj);
        });
      }
    });

    if(datesToClear){
      datesToClear.forEach(element => {
        let obj = { EmpId: this.request.employeeId, Startdate: this.datePipe.transform(element, 'MM-dd-yyyy HH:mm:ss'), Enddate: this.datePipe.transform(element, 'MM-dd-yyyy HH:mm:ss') }
        req.push(obj);
      });
    }
    return req;
  }

  public showWarningMessage(): void {
    const message = this.scheduledEntries.canEditScheduleApprovedPayPeriod ?
      `This will modify a schedule in an approved pay period and impact the PBJ Calculation for the
      employee, are you sure you want to continue?`:
      `You do not have permissions to modify a schedule in an approved pay period`;
    let popupOptions: ConfirmOptions = new ConfirmOptions();
    popupOptions.showCancel = true;
    popupOptions.showOK = this.scheduledEntries.canEditScheduleApprovedPayPeriod ? true : false;
    popupOptions.buttonOKtext = 'Ok';
    popupOptions.buttonCanceltext = this.scheduledEntries.canEditScheduleApprovedPayPeriod ? 'Cancel' : 'Ok';
    ConfirmDialog2Component.openDialog(
      'Warning',
      message,
      this.modalService,
      (result: boolean) => {
        if (result) {
          let entries: IndSchEntryWrapper[] = _.filter(this.checkedEntries, (entry: IndSchEntryWrapper) => { return entry.isDirty; });
          let datesToClear: Date[] = this.identifyDatesOnScheduleToClear(this.checkedEntries, this.m_scheduledEntriesOriginal);
          this.saveEmployeeScheduleDefinitionsServiceCall(this.request.employeeId,entries,datesToClear);
        }
        else {
        this.onCancelClicked();
        }
      },popupOptions);
  }


  private checkAndFixOverlappings(): void {
    let i: number = 0;
    let j: number = 0;
    _.times(this.checkedEntries.length, () => {
      let entry1: IndSchEntryWrapper = this.checkedEntries[i];
      j = i;
      _.times(this.checkedEntries.length - i, () => {
        let entry2: IndSchEntryWrapper = this.checkedEntries[j];
        this.fixOverlappings(entry1, entry2);
        j++;
      });
      i++;
    });
  }

  private fixOverlappings(entry1: IndSchEntryWrapper, entry2: IndSchEntryWrapper): void {
    let errors: NumberMap<boolean> = {};
    let shifts1: ScheduledShiftDefinition[] = entry1.entryDef.shifts;
    let shifts2: ScheduledShiftDefinition[] = entry2.entryDef.shifts;
    let shift1counter: number = 0;
    let shift2counter: number = 0;
    _.forEach(shifts1, (shift1: ScheduledShiftDefinition) => {
      shift2counter = 0;
      _.forEach(shifts2, (shift2: ScheduledShiftDefinition) => {
        let date1: moment.Moment = moment(entry1.date);
        let date2: moment.Moment = moment(entry2.date);
        if (!date1.isSame(date2) || (date1.isSame(date2) && shift1counter !== shift2counter && !errors[shift2counter])) {
          if (this.dateTimeService.isDateRangeOverlapped(moment(shift1.start), moment(shift1.end), moment(shift2.start), moment(shift2.end), false)) {
            shift2.start = moment(shift1.end).add(1, 'millisecond').toDate();
            shift2.duration = moment.range(shift1.end, shift2.end).diff();
            errors[shift1counter] = true;
          }
        }
        shift2counter++;
      });
      shift1counter++;
    });

  }

  public onEntryChanged(entry: DailyData<IndSchEntryWrapper>): void {
    this.individualScheduleEmpManagementService.isDirty = true;
    this.state.isDirty = true;
  }

  public onRequiredFieldsPopulated(): void {
    this.onEditMode();
  }

  public getCssClass(dailyData: DailyData<IndSchEntryWrapper>): string {
    return _.isDate(this.request.currentDate) && dailyData.startOfDay.isSame(this.request.currentDate) ? 'highlighted' : '';
  }

  public outOfCycle(dailyData: DailyData<IndSchEntryWrapper>): boolean {
    const sc: ScheduleCycle = this.individualScheduleManagementService.selectedCycle;

    let cycleStartDate: moment.Moment = sc ? sc.startDate : this.cycleStartDate;
    let cycleEndDate: moment.Moment = sc ? sc.endDate : this.cycleEndDate;

    return !dailyData.startOfDay.isBetween(cycleStartDate, cycleEndDate, null, '[]');
  }

  public outOfEmpWorkDates(dailyData: DailyData<IndSchEntryWrapper>): boolean {
    if (this.empTerminatedDate) {
      if (this.empRehireDate) {
        const sc: ScheduleCycle = this.individualScheduleManagementService.selectedCycle;
        let cycleEndDate: moment.Moment = sc ? sc.endDate : this.cycleEndDate;
        return !dailyData.startOfDay.isBetween(this.empHiredDate, this.empTerminatedDate, null, '[]')
          && !dailyData.startOfDay.isBetween(this.empRehireDate, cycleEndDate, null, '[]');
      }
      else {
        return !dailyData.startOfDay.isBetween(this.empHiredDate, this.empTerminatedDate, null, '[]')
      }
    } else {
      return dailyData.startOfDay.isBefore(this.empHiredDate);
    }
  }

  private flatEntries(scheduleData: IndSchDefWrapper): IndSchEntryWrapper[] {
    let rawEntries: IndSchEntryWrapper[] =
      _.reduce(scheduleData.weeklyData, (dailyRecordsResult: IndSchEntryWrapper[], weeklyData: WeeklyData<IndSchEntryWrapper>) => {
        let days: IndSchEntryWrapper[] = _.map(_.values<DailyData<IndSchEntryWrapper>>(weeklyData.days), (day: DailyData<IndSchEntryWrapper>) => {
          return day.data;
        });
        return (dailyRecordsResult || []).concat(days);
      }, []);
    let entries: IndSchEntryWrapper[] = _.filter(rawEntries, (entry: IndSchEntryWrapper) => { return !!entry; });
    return entries;
  }

  private copyEntries(scheduleEntries: IndSchEntryWrapper[]): IndSchEntryWrapper[] {
    return _.map(scheduleEntries, (entry: IndSchEntryWrapper): IndSchEntryWrapper => {
      let copiedEntry: IndSchEntryWrapper = Object.assign({}, entry);
      return copiedEntry;
    });
  }

  private validateEntries(): void {
    this.state.isValid = true;
    this.state.isFatalOverlapping = false;
    let entries: IndSchEntryWrapper[] = this.flatEntries(this.scheduledEntries);

    let i: number = 0;
    let j: number = 0;
    _.times(entries.length, () => {
      let entry1: IndSchEntryWrapper = entries[i];
      entry1.errors = null;
      j = i;
      _.times(entries.length - i, () => {
        let entry2: IndSchEntryWrapper = entries[j];
        let errors: NumberMap<string> = this.checkShiftsOverlapped(entry1, entry2);
        if (errors) {
          this.state.isValid = false;
        }
        if (!entry1.errors) {
          entry1.errors = errors;
        }
        j++;
      });
      i++;
    });
    this.checkedEntries = entries;
  }

  private identifyDatesOnScheduleToClear(newScheduleEntries: IndSchEntryWrapper[], originalScheduleEntries: IndSchEntryWrapper[]): Date[] {
    let originalDates: Date[] = _.map(originalScheduleEntries, (entry: IndSchEntryWrapper) => entry.date);
    let newEtriesList: IndSchEntryWrapper[] = _.filter(newScheduleEntries, (entry: IndSchEntryWrapper) => _.some(entry.entryDef.shifts));
    let newEtriesListDates: Date[] = _.map(newEtriesList, (entry: IndSchEntryWrapper) => entry.date);
    return _.difference(originalDates, newEtriesListDates);// originalDates.Except(entriesDates).ToArray();
  }

  private checkShiftsOverlapped(entry1: IndSchEntryWrapper, entry2: IndSchEntryWrapper): NumberMap<string> {
    let errors: NumberMap<string> = {};
    let isOvelapped: boolean;
    let shifts1: ScheduledShiftDefinition[] = entry1.entryDef.shifts;
    let shifts2: ScheduledShiftDefinition[] = entry2.entryDef.shifts;
    let shift1counter: number = 0;
    let shift2counter: number = 0;

    _.forEach(shifts1, (shift1: ScheduledShiftDefinition) => {
      shift2counter = 0;
      _.forEach(shifts2, (shift2: ScheduledShiftDefinition) => {
        let date1: moment.Moment = moment(entry1.date);
        let date2: moment.Moment = moment(entry2.date);
        if (!date1.isSame(entry2.date) || (date1.isSame(entry2.date) && shift1counter !== shift2counter && !errors[shift2counter])) {
          if (this.dateTimeService.isDateRangeOverlapped(moment(shift1.start), moment(shift1.end), moment(shift2.start), moment(shift2.end), false)) {
            isOvelapped = true;
            if (moment.range(shift1.start, shift1.end).contains(shift2.start) && moment.range(shift1.start, shift1.end).contains(shift2.end)) {
              this.state.isFatalOverlapping = true;
              errors[shift1counter] = `${date1.format(this.appConfig.monthDayDateFormat)}: Shifts of this day are overlapped with shifts on date ${date2.format(this.appConfig.monthDayDateFormat)}. The shift can not be adjusted to remove the overlap.`;
            } else {
              errors[shift1counter] = `${date1.format(this.appConfig.monthDayDateFormat)}: Shifts of this day are overlapped with shifts on date ${date2.format(this.appConfig.monthDayDateFormat)}.`;
            }
          }
        }
        shift2counter++;
      });
      shift1counter++;
    });
    if (isOvelapped) {
      return errors;
    }
    return null;
  }

  private loadScheduledEntries(): void {
    if (!this.request) {
      return;
    }
    this.individualScheduleEmpManagementService.onUILock(true);
    this.individualScheduleApiService.getOrgLevelEmployeeScheduleDefinitions(this.request)
      .then((result: IndSchDefWrapper) => {
        this.individualScheduleEmpManagementService.onUILock(false);
        this.scheduledEntries = result;
        this.employeeShift.position = result.position;
        this.employeeShift.unit = result.homeUnit;
        this.employeeShift.shift = result.homeShift;
        this.checkedEntries = null;
        this.individualScheduleEmpManagementService.isDirty = false;
        this.state.isDirty = false;
        this.state.startEditMode = false;
        this.state.editMode = false;
        this.empHiredDate = null;
        this.empTerminatedDate = null;
        this.empRehireDate = null;
        if (result.employee) {
          this.empHiredDate = moment(result.employee.dateHired);
          this.empTerminatedDate = result.employee.dateTerminated ? moment(result.employee.dateTerminated) : null;
          this.empRehireDate = moment(result.employee.daterehire);
        }
        this.setScheduleDateRange();
      })
      .catch(() => {
        this.individualScheduleEmpManagementService.onUILock(false);
      });
  }

  public get homeShiftId(): number {
    return this.employeeShift && this.employeeShift.shift ? this.employeeShift.shift.id : null;
  }

  public get homeUnitId(): number {
    return this.employeeShift && this.employeeShift.unit ? this.employeeShift.unit.id : null;
  }

  public get homePositionId(): number {
    return this.employeeShift && this.employeeShift.position ? this.employeeShift.position.id : null;
  }


  public isOutOfPositionPeriod(dailyData: DailyData<IndSchEntryWrapper>): boolean {
    return dailyData.startOfDay.isBefore(this.employeeShift.position.startDate) || dailyData.startOfDay.isAfter(this.employeeShift.position.endDate);
  }

  public addShift(_dailyData: DailyData<IndSchEntryWrapper>) {
    if (!this.isOutOfPositionPeriod(_dailyData)) {
      let dateOfDay = moment(_dailyData.startOfDay).format(appConfig.dateFormat);
      this.individualScheduleEmpManagementService.cellClicked$.next(dateOfDay);
    }
  }

  public toggleLCol() {
    this.individualScheduleManagementService.changeEmployeeListVisibility();
  }

  public isDayReadOnly(dailyData: DailyData<IndSchEntryWrapper>): boolean {
    return !this.state.editMode || this.outOfCycle(dailyData) || this.outOfEmpWorkDates(dailyData);
  }

  public isAddShiftReadOnly(dailyData: DailyData<IndSchEntryWrapper>): boolean {
    return this.state.editMode && !this.outOfCycle(dailyData) && !this.outOfEmpWorkDates(dailyData);
  }

  private loadShiftDefinitionsLookup(request: IndSchEmployeeSchedulesRequest): void {
    if (!request) {
      return;
    }
    this.isShiftLoadingState = true;
    this.lookupService.getLookup({ lookupType: LookupType.shift, employeeId: request.employeeId, orgLevelId: request.orgLevelId })
      .then((shiftLookup: Lookup) => {
        this.shiftLookup = shiftLookup;
        this.isShiftLoadingState = false;
      })
      .catch(() => {
        this.isShiftLoadingState = false;
      });
  }
}
