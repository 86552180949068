export interface IShiftNotificationRequest {
  employeeIds: number[];
  positionId: number;
  unitId: number;
  date: string;
  text: string;
}
export class ShiftNotificationRequest {
  public employeeIds: number[];
  public positionId: number;
  public unitId: number;
  public date: Date;
  public text: string;
}
