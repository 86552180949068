export interface IPayCyclePeriod {
  name: PayCyclePeriodDefinition;
  description: string;
}

export class PayCyclePeriod {
  public name: PayCyclePeriodDefinition;
  public description: string;
}
export class PayCyclePeriodType {
  public static weekly: PayCyclePeriodDefinition = 'weekly';
  public static bweekly: PayCyclePeriodDefinition = 'bweekly';
  public static monthly: PayCyclePeriodDefinition = 'monthly';
  public static smonthly: PayCyclePeriodDefinition = 'smonthly';
}
export type PayCyclePeriodDefinition = 'weekly'|'bweekly'|'monthly'|'smonthly';
