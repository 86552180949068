import { Component, OnInit } from '@angular/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { FileUploadsApiService } from '../../services/file-uploads/file-uploads-api.service';
import { Observable, Subscription } from 'rxjs';
import { mutableSelect } from './../../../../app/core/decorators';
import { OrgLevel } from './../../../../app/state-model/models';
import { ImportedFiles } from '../../models/imported-files/ImportedFiles';
import { FileUploadsManagementService } from '../../services/file-uploads/file-uploads-management.service';

@Component({
  selector: 'slx-file-uploads',
  templateUrl: './file-uploads.component.html',
  styleUrls: ['./file-uploads.component.scss']
})
export class FileUploadsComponent implements OnInit {

  public onTabSelect(e: SelectEvent): void {
    console.log(e);
  }

  constructor(public manService: FileUploadsManagementService) { }

  ngOnInit() {
    this.manService.init();
  }



}
