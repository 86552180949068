import { OnInit, OnDestroy, TemplateRef, EventEmitter, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { EditableListActionKind } from '../../models/index';
import { ListActionsService } from '../../services/index';
var EditableListComponent = /** @class */ (function () {
    function EditableListComponent(actionsService, elementRef) {
        this.actionsService = actionsService;
        this.onEditStartEvent = new EventEmitter();
        this.onEditCancelEvent = new EventEmitter();
        this.onItemSaveEvent = new EventEmitter();
        this.onItemRemoveEvent = new EventEmitter();
        this.onSelectionChangeEvent = new EventEmitter();
        this.allowEdit = true;
        this.allowSelect = true;
        this.renderIsEditorInternal = false;
        this.editModeInternal = false;
        this.elementRef = elementRef;
    }
    Object.defineProperty(EditableListComponent.prototype, "data", {
        get: function () {
            return this.privateData;
        },
        set: function (value) {
            this.privateData = value;
            if (this.actionsService && this.privateData) {
                this.actionsService.itemCount = this.privateData.length;
                this.actionsService.resetSelection();
            }
            else if (this.actionsService) {
                this.actionsService.itemCount = 0;
                this.actionsService.resetSelection();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableListComponent.prototype, "multiselect", {
        set: function (value) {
            this.allowMultiselect = value;
            if (this.actionsService) {
                this.actionsService.multiselect = this.allowMultiselect;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableListComponent.prototype, "editable", {
        set: function (value) {
            this.allowEdit = value;
            if (this.actionsService) {
                this.actionsService.editable = this.allowEdit;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableListComponent.prototype, "selectable", {
        set: function (value) {
            this.allowSelect = value;
            if (this.actionsService) {
                this.actionsService.selectable = this.allowSelect;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableListComponent.prototype, "renderIsEditor", {
        set: function (value) {
            this.renderIsEditorInternal = value;
            if (this.actionsService) {
                this.actionsService.renderIsEditor = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableListComponent.prototype, "editMode", {
        set: function (value) {
            this.editModeInternal = value;
            if (this.actionsService) {
                this.actionsService.editMode = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableListComponent.prototype, "rowTemplateRef", {
        get: function () {
            return this.rowTemplate ? this.rowTemplate.templateRef : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableListComponent.prototype, "editorTemplateRef", {
        get: function () {
            return this.editorTemplate ? this.editorTemplate.editorTemplateRef : undefined;
        },
        enumerable: true,
        configurable: true
    });
    EditableListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            hasEditorInstance: false,
            hasSelected: false
        };
        this.actionsService.multiselect = this.allowMultiselect;
        this.actionsService.editable = this.allowEdit;
        this.actionsService.selectable = this.allowSelect;
        if (this.privateData) {
            this.actionsService.itemCount = this.privateData.length;
        }
        else {
            this.actionsService.itemCount = 0;
        }
        this.actionSubscription = this.actionsService.subscribe(function (msg) { return _this.onAction(msg); });
        this.actionsService.editMode = this.editModeInternal;
    };
    EditableListComponent.prototype.ngOnDestroy = function () {
        if (this.actionSubscription)
            this.actionSubscription.unsubscribe();
    };
    EditableListComponent.prototype.addItem = function (newItem, toStart) {
        if (toStart === void 0) { toStart = true; }
        this.actionsService.addNewItem(newItem);
        if (toStart) {
            this.privateData.unshift(newItem);
            this.elementRef.nativeElement.scrollTop = 0;
        }
        else {
            this.privateData.push(newItem);
        }
    };
    EditableListComponent.prototype.createItem = function (itemClass, toStart) {
        if (toStart === void 0) { toStart = true; }
        var newItem = new itemClass();
        this.addItem(newItem, toStart);
    };
    EditableListComponent.prototype.editItem = function (item) {
        this.actionsService.startEditItem(item);
    };
    EditableListComponent.prototype.toogleAllSelection = function () {
        this.actionsService.selectAll(this.privateData);
    };
    EditableListComponent.prototype.resetSelection = function () {
        this.actionsService.resetSelection();
    };
    EditableListComponent.prototype.onAction = function (action) {
        switch (action.kind) {
            case EditableListActionKind.START_EDIT:
                this.onItemEditStart(action.items);
                break;
            case EditableListActionKind.CANCEL_EDIT:
                this.cancelEditing(action.items);
                break;
            case EditableListActionKind.COMPLETE_EDIT:
                this.onItemSave(action.items);
                break;
            case EditableListActionKind.SELECTION_CHANGE:
                this.onSelectionChanged(action.items);
                break;
            case EditableListActionKind.SELECT_ALL:
                this.onSelectAll();
                break;
            case EditableListActionKind.REMOVE:
                this.onRemoveItem(action.items);
                break;
        }
    };
    EditableListComponent.prototype.onRemoveItem = function (items) {
        var _this = this;
        _.each(items, function (item) {
            var index = _this.privateData.indexOf(item);
            _this.privateData.splice(index, 1);
        });
        this.onItemRemoveEvent.emit(items);
    };
    EditableListComponent.prototype.onItemEditStart = function (items) {
        if (this.state.hasEditorInstance)
            return;
        this.state.hasEditorInstance = true;
        this.onEditStartEvent.emit(items);
    };
    EditableListComponent.prototype.cancelEditing = function (items) {
        this.state.hasEditorInstance = false;
        this.onEditCancelEvent.emit(items);
        if (this.actionsService.isNew(items[0])) {
            var index = this.privateData.indexOf(items[0]);
            this.privateData.splice(index, 1);
        }
    };
    EditableListComponent.prototype.onItemSave = function (items) {
        this.state.hasEditorInstance = false;
        this.onItemSaveEvent.emit(items);
    };
    EditableListComponent.prototype.onSelectionChanged = function (items) {
        this.onSelectionChangeEvent.emit(this.actionsService.selectedItems);
    };
    EditableListComponent.prototype.onSelectAll = function () {
        if (this.actionsService.allSelected) {
            this.onSelectionChangeEvent.emit(this.privateData);
        }
        else {
            this.onSelectionChangeEvent.emit([]);
        }
    };
    return EditableListComponent;
}());
export { EditableListComponent };
