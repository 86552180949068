import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { dateTimeUtils } from './../../../../common/utils/dateTimeUtils';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import * as moment from 'moment';
import {
  IEmployeeSectionsAccruals,
  EmployeeSectionsAccruals,
  IEmployeeSectionAccrualTransactions,
  EmployeeSectionAccrualTransactions,
  IEmployeeAccrualTransaction,
  EmployeeAccrualTransaction,
  IEmployeeSectionsPolicyAssignments,
  EmployeeSectionsPolicyAssignments,
  IEmployeePolicyAssignment,
  EmployeePolicyAssignment,
  EndingBalanceDialogOptions,
  EmployeeAccrualBalance,
  AccrualPolicy,
  IAccrualPolicy,
  DynamicAccrualPolicies,
  IAccrualTypes,
  AccrualTypes,
  ResetBalance,
  IResetBalance,
  IAccrualPolicyRule,
  AccrualPolicyRules,
  EmployeePolicyAssignmentDTO,
  IEmployeeSectionAccrualsBalances,
  EmployeeSectionAccrualsBalances,
  EmployeeAccrual,
  IEmployeeAccrual
} from '../../models/index';
import { IFieldData, FieldsMeta } from '../../../../core/models/index';
import { MetaMapService } from '../../../../core/services/index';

@Injectable()
export class EmployeeSectionsAccrualsMapService {
  constructor(private metaMapService: MetaMapService) {
    Assert.isNotNull(metaMapService, 'metaMapService');
  }

  public mapToSectionsAccruals(data: IEmployeeSectionsAccruals): EmployeeSectionsAccruals {
    Assert.isNotNull(data, 'data');
    const accruals: EmployeeSectionsAccruals = new EmployeeSectionsAccruals();
    const { balances, transactions, policyAssignments } = data;

    if (_.isObjectLike(balances)) {
      accruals.balances = this.mapToAccrualsBalances(data.balances.data, data.balances.metadata);
    }
    if (_.isObjectLike(transactions)) {
      accruals.transactions = this.mapToAccrualTransactions(data.transactions.data, data.transactions.metadata);
    }
    if (_.isObjectLike(policyAssignments)) {
      accruals.policyAssignments = this.mapToPolicyAssignments(data.policyAssignments.data, data.policyAssignments.metadata);
    }
    return accruals;
  }


  public mapToPolicyAssignments(dpolicyassignments: IEmployeeSectionsPolicyAssignments, meta: FieldsMeta): EmployeeSectionsPolicyAssignments {
    Assert.isNotNull(dpolicyassignments, 'dpolicyassignments');

    let policyAssignments: EmployeeSectionsPolicyAssignments = new EmployeeSectionsPolicyAssignments();

    _.forEach(dpolicyassignments.records, (recordDto: IEmployeePolicyAssignment) => {
      let record: EmployeePolicyAssignment = new EmployeePolicyAssignment();

      record.id = recordDto.id;
      record.empId = recordDto.empId;
      record.policyName = recordDto.policyName;
      record.startDate = dateTimeUtils.convertFromDtoString(recordDto.startDate);
      record.endDate = dateTimeUtils.convertFromDtoString(recordDto.endDate);
      record.lastUpdatedDate = dateTimeUtils.convertFromDtoString(recordDto.lastUpdatedDate);
      record.lastUpdatedBy = recordDto.lastUpdatedBy;
      record.policyIdList = recordDto.policyIdList;

      policyAssignments.records.push(record);
    });

    policyAssignments.effectiveDate = dateTimeUtils.convertFromDtoString(dpolicyassignments.effectiveDate);
    policyAssignments.actions = this.metaMapService.mapActions(meta);

    return policyAssignments;
  }

  public mapToPolicyAssignmentDto(policyAssignment: EmployeePolicyAssignment): any {
    Assert.isNotNull(policyAssignment, 'policyAssignment');
    let pa: EmployeePolicyAssignmentDTO = new EmployeePolicyAssignmentDTO();

    pa.assignmentId = policyAssignment.id;
    pa.policyName = policyAssignment.policyName;
    pa.startDate = policyAssignment.startDate ? moment(policyAssignment.startDate).format(appConfig.dateFormat) : null;
    pa.endDate = policyAssignment.endDate ? moment(policyAssignment.endDate).format(appConfig.dateFormat) : null;
    pa.empId = policyAssignment.empId;
    pa.deleted = policyAssignment.deleted;
    pa.policyIdList = [];
    if (policyAssignment.policyIdList && policyAssignment.policyIdList.indexOf('-') != -1) {
      pa.policyIdList = _.split(policyAssignment.policyIdList, '-').map(Number);
    }
    else {
      pa.policyIdList.push(parseInt(policyAssignment.policyIdList));
    }

    return pa;
  }

  public mapToPolicyBalance(dpolicybalance: any, meta: FieldsMeta): EndingBalanceDialogOptions {
    Assert.isNotNull(dpolicybalance, 'dpolicybalance');

    let policyBalance: EndingBalanceDialogOptions = new EndingBalanceDialogOptions();

    _.forEach(dpolicybalance, (recordDto: any) => {
      let record: EmployeeAccrualBalance = new EmployeeAccrualBalance();
      record.accrualTypeId = recordDto.accrualTypeId;
      record.accrualTypeName = recordDto.accrualTypeName;
      record.balance = recordDto.balance;
      policyBalance.accruals.push(record);
    });

    return policyBalance;
  }

  public mapAccrualPolicy(data: IAccrualPolicy[]): AccrualPolicy[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');

    return _.map(data, item => this.mapAccrualPolicyList(item));
  }

  public mapAccrualPolicyList(data: IAccrualPolicy): AccrualPolicy {
    let policy: AccrualPolicy = new AccrualPolicy();

    policy.id = data.id;
    policy.policyName = data.policyName;
    policy.accrualType = data.accrualType;
    policy.rules = _.map(data.rules, item => this.mapAccrualPolicyRules(item));

    return policy;
  }

  public mapAccrualPolicyRules(data: IAccrualPolicyRule): AccrualPolicyRules {
    let rule: AccrualPolicyRules = new AccrualPolicyRules();

    rule.id = data.id;
    rule.ruleName = data.ruleName;
    rule.accrualTypeName = data.accrualTypeName;

    return rule;
  }

  public mapToDynamicPolicy(dpolicy: any, meta: FieldsMeta): boolean {
    Assert.isNotNull(dpolicy, 'dpolicy');
    let dynamicPolicy: DynamicAccrualPolicies = new DynamicAccrualPolicies();
    _.forEach(dpolicy, (recordDto: any) => {
      dynamicPolicy.key = recordDto.key;
      dynamicPolicy.value = recordDto.value == 'true' ? true : false;
    });
    let isDynamicPolicy: boolean = false;
    isDynamicPolicy = dynamicPolicy.value;
    return isDynamicPolicy;
  }

  public mapToAccrualTransactions(dtransactions: IEmployeeSectionAccrualTransactions, meta: FieldsMeta): EmployeeSectionAccrualTransactions {
    Assert.isNotNull(dtransactions, 'dtransactions');
    let transactions: EmployeeSectionAccrualTransactions = new EmployeeSectionAccrualTransactions();
    _.forEach(dtransactions.records, (empAccrualTransactionsDto: IEmployeeAccrualTransaction) => {
      let empAccrualTransactions: EmployeeAccrualTransaction = new EmployeeAccrualTransaction();
      empAccrualTransactions.id = empAccrualTransactionsDto.id;
      empAccrualTransactions.empId = empAccrualTransactionsDto.empId;
      empAccrualTransactions.policyName = empAccrualTransactionsDto.policyName;
      empAccrualTransactions.action = empAccrualTransactionsDto.action;
      empAccrualTransactions.transactionDate = dateTimeUtils.convertFromDtoDateString(empAccrualTransactionsDto.transactionDate);
      empAccrualTransactions.absenceDate = dateTimeUtils.convertFromDtoDateString(empAccrualTransactionsDto.absenceDate);
      empAccrualTransactions.policyId = empAccrualTransactionsDto.policyId;
      empAccrualTransactions.dynamicPolicyId = empAccrualTransactionsDto.dynamicPolicyId;
      empAccrualTransactions.accrualTypeId = empAccrualTransactionsDto.accrualTypeId;
      empAccrualTransactions.accrualType = empAccrualTransactionsDto.accrualType;
      empAccrualTransactions.originalValue = empAccrualTransactionsDto.originalValue;
      empAccrualTransactions.newValue = empAccrualTransactionsDto.newValue;
      empAccrualTransactions.change = empAccrualTransactionsDto.change;
      empAccrualTransactions.modifiedBy = empAccrualTransactionsDto.modifiedBy;
      empAccrualTransactions.modifiedOn = empAccrualTransactionsDto.modifiedOn;
      empAccrualTransactions.notes = empAccrualTransactionsDto.notes;
      empAccrualTransactions.isLastManualTransaction = empAccrualTransactionsDto.isLastManualTransaction;
      empAccrualTransactions.dbAction = empAccrualTransactionsDto.dbAction;
      transactions.records.push(empAccrualTransactions);
    });
    transactions.actions = this.metaMapService.mapActions(meta);
    return transactions;
  }

  public mapToAccrualTransaction(accrualTransactions: EmployeeSectionAccrualTransactions): IEmployeeSectionAccrualTransactions {
    Assert.isNotNull(accrualTransactions, 'transactions');
    return {

      records: accrualTransactions.records !== null && accrualTransactions.records !== undefined ? this.mapToAccrualTransactionsRecordsDto(accrualTransactions.records) : null
    };
  }

  public mapAccrualTypes(data: IAccrualTypes[]): AccrualTypes[] {
    Assert.isNotNull(data, 'Nothing to map: data');
    Assert.isArray(data, 'Nothing to map: data');

    let roots: AccrualTypes[] = [];
    _.forEach(data, (item: IAccrualTypes) => {
      let value: AccrualTypes = this.mapAccrualType(item);
      roots.push(value);
    });
    return roots;
  }

  public mapAccrualType(data: IAccrualTypes): AccrualTypes {
    let value: AccrualTypes = new AccrualTypes();
    value.accrualTypeId = data.accrualTypeId;
    value.accrualTypeName = data.accrualTypeName;
    value.lastManualTransactionDate = dateTimeUtils.convertFromDtoDateString(data.lastManualTransactionDate);
    value.policyId = data.policyId;
    value.policyName = data.policyName;
    value.balance = data.balance;
    return value;
  }

  public mapToAccrualTransactionsRecordsDto(transactionRecords: EmployeeAccrualTransaction[]): IEmployeeAccrualTransaction[] {
    Assert.isNotNull(transactionRecords, 'transactions');
    let records: IEmployeeAccrualTransaction[] = [];
    transactionRecords.forEach((r: EmployeeAccrualTransaction) => {
      records.push(this.mapToAccrualTransactionsRecords(r));
    });
    return records;
  }

  public mapToAccrualTransactionsRecords(transactionRecords: EmployeeAccrualTransaction): IEmployeeAccrualTransaction {
    Assert.isNotNull(transactionRecords, 'transactionRecords');
    return {
      id: transactionRecords.id,
      empId: transactionRecords.empId,
      policyName: transactionRecords.policyName,
      action: transactionRecords.action,
      transactionDate: dateTimeUtils.convertToDtoString(transactionRecords.transactionDate),
      absenceDate: dateTimeUtils.convertToDtoString(transactionRecords.absenceDate),
      policyId: transactionRecords.policyId,
      dynamicPolicyId: transactionRecords.dynamicPolicyId,
      accrualTypeId: transactionRecords.accrualTypeId,
      accrualType: transactionRecords.accrualType,
      originalValue: transactionRecords.originalValue,
      newValue: transactionRecords.newValue,
      change: transactionRecords.change,
      modifiedBy: null,
      modifiedOn: null,
      notes: transactionRecords.notes,
      dbAction: transactionRecords.dbAction,
      isLastManualTransaction: null,
      deleted: transactionRecords.deleted
    };
  }

  public mapToResetBalanceDto(resetBalancesRescords: ResetBalance): IResetBalance {
    Assert.isNotNull(resetBalancesRescords, 'resetBalancesRescords');
    return {
      employeeId: resetBalancesRescords.employeeId,
      transactionDate: dateTimeUtils.convertToDtoString(resetBalancesRescords.transactionDate),
      notes: resetBalancesRescords.notes,
      accrualTypeInfo: resetBalancesRescords.accrualTypeInfo,
      fromDateForFetchResult: resetBalancesRescords.fromDateForFetchResult,
      endDateForFetchResult: resetBalancesRescords.endDateForFetchResult
    };
  }

  public mapToAccrualsBalances(accrualsRawData: IEmployeeSectionAccrualsBalances, meta: FieldsMeta): EmployeeSectionAccrualsBalances {
    Assert.isNotNull(accrualsRawData.records, 'records');

    const accruals: EmployeeSectionAccrualsBalances = new EmployeeSectionAccrualsBalances();
    const metaMap: StringMap<IFieldData> = this.metaMapService.createMetaMap(meta);

    _.forEach(accrualsRawData.records, (record: IEmployeeAccrual) => {
      const accrual: EmployeeAccrual = new EmployeeAccrual();

      accrual.effectiveDate = this.metaMapService.mapMeta<Date>(metaMap, 'effectiveDate', dateTimeUtils.convertFromDtoString(record.effectiveDate), record.effectiveDate);
      accrual.benefitType = this.metaMapService.mapMeta<string>(metaMap, 'benefitType', record.benefitType);
      accrual.accruedHours = this.metaMapService.mapMeta<number>(metaMap, 'accruedHours', record.accruedHours);

      accruals.records.push(accrual);
    });

    accruals.actions = this.metaMapService.mapActions(meta);

    return accruals;
  }
}

