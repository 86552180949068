<slx-spinner [show]="isLoading" novalidate>
  <form #form="ngForm" novalidate>
    <kendo-grid class="kendo-grid slx-benefit-management-grid"
      [data]="gridState.view"
      [sortable]="{ mode: 'multiple' }"
      [filterable]="'menu'"
      [pageable]="true"
      [pageSize]="pageSize"
      [sort]="gridState.state.sort"
      [filter]="gridState.state.filter"
      [skip]="gridState.state.skip"

      (dataStateChange)="gridState.dataStateChange($event)"
      (edit)="gridState.editHandler($event)"
      (save)="gridState.saveHandler($event)"
      (cancel)="gridState.cancelHandler($event)"
      (remove)="gridState.removeHandler($event)"
    >
      <ng-template kendoGridToolbarTemplate position="top">
        <button type="button"
          class="theme-iconed-accent-button header-btn"
          *ngIf="canAddWarning && isEditable"
          [slxOpenEmployeeWarningDialog]="null"
          (saveWarning)="onSaveWarning($event)"
        >Issue Warning</button>
      </ng-template>
      <kendo-grid-command-column width="90">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="flex">
            <button [disabled]="!canEditWarning && !isEditable" [slxOpenEmployeeWarningDialog]="dataItem" (saveWarning)="onSaveWarning($event)"
              type="button" class="slx-button slx-only-icon slx-toolbar slx-blue-white-text"
            >
              <i class="fas fa-edit" aria-hidden="true"></i>
            </button>
            <button [disabled]="!canDeleteWarning && !isEditable" (click)="onRemoveWarning(dataItem)"
              type="button" class="slx-button slx-only-icon slx-toolbar slx-red-white-text"
            >
              <i class="fas fa-trash-alt" aria-hidden="true"></i>
            </button>
          </div>
        </ng-template>
      </kendo-grid-command-column>

      <kendo-grid-column field="warningSubject">
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Subject</span>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          <a class="slx-link"
            [slxOpenEmployeeWarningDialog]="dataItem"
            (saveWarning)="onSaveWarning($event)"
            [title]="dataItem.warningSubject"
          >{{ dataItem.warningSubject }}</a>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="addedBy">
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Issued by</span>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.addedBy }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="addedAt" filter="date">
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Issued Date</span>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.addedAt | amDateFormat: appConfig.dateFormat }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [filterable]="false" [sortable]="false" width="100">
        <ng-template kendoGridHeaderTemplate>
          <span slxKendoGridHeaderTooltip>Download</span>
        </ng-template>

        <ng-template kendoGridCellTemplate let-dataItem>
          <p class="text-center">
            <button type="button" (click)="onDownload(dataItem)" class="slx-button slx-only-icon slx-toolbar slx-blue-white-text">
              <i class="fas fa-download" aria-hidden="true"></i>
            </button>
          </p>
        </ng-template>
      </kendo-grid-column>

    </kendo-grid>
  </form>
</slx-spinner>
