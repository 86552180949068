<slx-spinner [show]="isLoading">
  <div class="modal-body content-holder">

    <div class="toolbar align-right">
      <button type="button" [disabled]="!selectedPlan || !canEnrollInSelectedGroup || hasEnrolledOutOfEffectiveDate" class="slx-button slx-blue" (click)="onEnroll()">Enroll</button>
    </div>

    <div class="toolbar">
      <p class="flex form__ctrl">
        <label class="form__label">Select Benefit Group</label>
        <span class="flex flex__grow form-dropdown-control">
          <slx-dropdown-list class="slx-wide" [lookup]="groups" [ngModel]="selectedGroup"
            (ngModelChange)="onChangeGroup($event)" name="groupsList" required>
          </slx-dropdown-list>
        </span>
      </p>
    </div>

    <div class="grid-holder">
      <span *ngIf="canEnrollInSelectedGroup && !hasEnrolledOutOfEffectiveDate">Select a Benefit and click Enroll</span>
      <span *ngIf="!canEnrollInSelectedGroup && !hasNoActiveBenefitsFromSelectedGroup && !hasEnrolledOutOfEffectiveDate" class="warning-message">
        You cannot enroll employees in multiple plans for the chosen plan group.
      </span>
      <span *ngIf="hasEnrolledOutOfEffectiveDate" class="warning-message">
        Employee is already enrolled. To enroll this employee, drop the current benefit coverage and re-enroll the employee.
      </span>
      <ul class="plan-list">
        <li *ngFor="let plan of selectedGroupPlansToDisplay; let idx = index">
          <slx-radio-button class="radio-name"
          [caption]="plan.name"
          [fieldName]="'selectedPlan'"
          [option]="plan"
          [(ngModel)]="selectedPlan"
          [readonly]="plan.status === 2 || (plan.enrolledOutEffectiveDate && !selectedGroup.allowMultipleEnrollment)"
          (ngModelChange)="onChangeSelectedPlan($event)"
        ></slx-radio-button>
          <span class="eligibility-mark green" *ngIf="planStatus(plan) === 0">Eligible</span>
          <span class="eligibility-mark red" *ngIf="planStatus(plan) === 1">Ineligible</span>
          <span class="eligibility-mark orange" *ngIf="planStatus(plan) === 2">Enrolled</span>
          <span class="eligibility-mark red" *ngIf="planStatus(plan) === 3">Eligibility rule error</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn" (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Close</button>
</div>
</slx-spinner>
