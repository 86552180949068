import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { EmployeeSectionsAttendance, AbsenceGridItem, EmployeeSectionsAttendanceDay } from '../../../../models';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';

@Component({
  selector: ' slx-employee-sections-attendance-absences-grid',
  templateUrl: './employee-sections-attendance-absences-grid.component.html',
  styleUrls: ['./employee-sections-attendance-absences-grid.component.scss']
})
export class EmployeeSectionsAttendanceAbsencesGridComponent implements OnInit {

  @Input()
  public set attendanceSection(value: EmployeeSectionsAttendance) {
      this.m_attendanceSection = value;
      this.recalculateData();
  }
  public get attendanceSection(): EmployeeSectionsAttendance {
      return this.m_attendanceSection;
  }

  @Input()
  public set prefix(value: string) {
      this.m_prefix = value;
      this.recalculateData();
  }

  public get prefix(): string {
      return this.m_prefix;
  }

  public absences: AbsenceGridItem[]; 

  public appConfig: IApplicationConfig;

  private m_attendanceSection: EmployeeSectionsAttendance;
  private m_prefix: string;

  constructor() {
    this.appConfig = appConfig;
  }

  public ngOnInit(): void {
    this.recalculateData();
  }

  private recalculateData(): void {
    
   
    if (!this.attendanceSection || !this.prefix) return;

    this.absences = [];

    _.each(this.attendanceSection.dates, (day: EmployeeSectionsAttendanceDay)=> {


      if (day.getAbsence(this.m_prefix)) {
        const absence = new AbsenceGridItem();
        absence.date = day.date;
        absence.sheduledHours = day.scheduledAbsenceHours;
        absence.absenseHours = day.actualAbsenceHours;
        absence.description = day.getAbsence(this.m_prefix).description;
        absence.code = day.getAbsence(this.m_prefix).code;
        absence.color = day.getAbsence(this.m_prefix).color;
        this.absences.push(absence);
      }
    });
  }

  public firstSymbol(str: string): string {
    return str ? str.charAt(0) : '';
  }

}
