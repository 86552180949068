export interface IEmployeeConfig {
  api: {
    communications: {
      root: string;
      log: {
        root: string;
      };
      sendEmployeeSpecificMessage: {
        root: string;
      };
      sendTimeclockTickerMessage: {
        root: string;
      };
      stopTimeclockTickerMessage: {
        root: string;
      };
      calls: {
        root: string;
        save: string;
      }
    };
    timecards: {
         root: string,
         daily: {
         root: string,
      },
        employee: {
        root: string,
        badgeId: string
      },
      api: {
        root: string
       }
    };  
    employees: {
      root: string;
      terminate: {
        root: string;
        cancelterminate: string;
        changeTermDate: string;
      };
      transfer: string;
      undoTransfer: string;
      rehire: string;
      properties: string;
      actions: {
        root: string;
      };
      assignESSFlagsToEmployees: string;
      unassignESSFlagsToEmployees: string;
      setEssPasswordStateToEmployees: string;
    };
    messages: {
      root: string;
      sendShiftSms: string;
      approveShift: string;
      denyShift: string;
      read: string;
      approve: string;
      deny: string;
    };
    messageCenterCallLogs: string;
    orgLevelId:string;
    partner:string;
    shiftAssignment:string;
  };
  maxBirthDateBeforeCurrentInYears: number;
  photo: {
    type: string[];
    minWidth: number;
    minHeight: number;
  };
  cropper: {
    boundary: {
      width: number,
      height: number
    };
    viewport: {
      width: number,
      height: number,
      type: string | any
    };
  };
}

export const employeeConfig: IEmployeeConfig = {
  api: {
    employees: {
      root: 'employees',
      terminate: {
        root: 'terminate',
        cancelterminate: 'cancelterminate',
        changeTermDate: 'changeTermDate'
      },
      transfer: 'transfer',
      undoTransfer: 'undoTransfer',
      rehire: 'rehire',
      properties: 'properties',
      actions: {
        root: 'actions'
      },
      assignESSFlagsToEmployees: 'assignESSFlagsToEmployees',
      unassignESSFlagsToEmployees: 'unassignESSFlagsToEmployees',
      setEssPasswordStateToEmployees: 'setEssPasswordStateToEmployees'
    },
    communications: {
      root: 'communications',
      log: {
        root: 'log',
      },
      sendEmployeeSpecificMessage: {
        root: 'sendEmployeeSpecificMessage'
      },
      sendTimeclockTickerMessage: {
        root: 'sendTimeclockTickerMessage'
      },
      stopTimeclockTickerMessage: {
        root: 'stopTimeclockTickerMessage'
      },
      calls: {
        root: 'calls',
        save: 'save'
      }
    },
    timecards: {
      root: 'timecards',
      daily: {
        root: 'dailyemployee',
      },
      employee: {
        root: 'employee',
        badgeId: 'badgeId'
     },
     api: {
      root: 'ta'
     }
     },
      
    messages: {
      root: 'messages',
      sendShiftSms: 'sendShiftSms',
      approveShift: 'approveShift',
      denyShift: 'denyShift',
      read: 'read',
      approve : 'approve',
      deny : 'deny'
    },
    messageCenterCallLogs: 'calllogs',
    orgLevelId: 'orgLevelId',
    partner: 'partner',
    shiftAssignment: 'shiftAssignment'
  },
  maxBirthDateBeforeCurrentInYears: 14,
  photo: {
    type: ['image/png', 'image/jpg', 'image/jpeg'],
    minWidth: 200,
    minHeight: 200
  },
  cropper: {
    boundary: {
      width: 800,
      height: 400
    },
    viewport: {
      width: 200,
      height: 200,
      type: 'square'
    }
  }
};
