import { OnInit } from '@angular/core';
import { GridDataResult, GridComponent } from '@progress/kendo-angular-grid';
import { orderBy } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { IDialog } from '../../../../common/index';
import { EmployeeDetails } from '../../../models/index';
var EmployeeDetailsTableListComponent = /** @class */ (function () {
    function EmployeeDetailsTableListComponent() {
        this.gridState = {
            skip: undefined,
            take: undefined,
            filter: undefined,
            sort: [{ field: 'scheduleStart', dir: 'asc' }],
            group: undefined
        };
    }
    Object.defineProperty(EmployeeDetailsTableListComponent.prototype, "employeeDetails", {
        set: function (details) {
            this.gridData = details;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    EmployeeDetailsTableListComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    EmployeeDetailsTableListComponent.prototype.exportToExcel = function () {
        this.grid.saveAsExcel();
    };
    EmployeeDetailsTableListComponent.prototype.exportToPdf = function () {
        this.grid.saveAsPDF();
    };
    EmployeeDetailsTableListComponent.prototype.sortChange = function (sort) {
        this.gridState.sort = sort;
        this.refreshGrid();
    };
    EmployeeDetailsTableListComponent.prototype.refreshGrid = function () {
        if (!this.gridData) {
            this.gridView = null;
            return;
        }
        var sortedRecords = orderBy(this.gridData, this.gridState.sort);
        this.gridView = {
            data: sortedRecords,
            total: sortedRecords.length
        };
    };
    return EmployeeDetailsTableListComponent;
}());
export { EmployeeDetailsTableListComponent };
