import { KendoFilterHelper } from './../../../core/utils/kendo-filter-helper';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { Injectable, Optional } from '@angular/core';
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribeInService } from '../../../core/decorators/index';
import { IGridState, ControlStateKey, IControlState, IFilterState } from '../../../core/models/index';
import { StateManagementService } from '../state-management/state-management.service';
import { filterCalculated, filterValue } from '../../../core/utils/index';
export type filterState = { value: any, operator: string, field: string };
import * as kendoUtils from '../../../core/utils/kendo-ui-utils';
import { IDestroyService } from '../../../core/models/index';

@Injectable()
export class FilterStateManagementService implements IDestroyService {

  public restoreFiltersState$: ReplaySubject<filterState>;
  public allFiltersRestored$: ReplaySubject<{ lastField: string, filters: CompositeFilterDescriptor }>;
  public filterStateSaved$: ReplaySubject<{ gridId: string, state: IGridState }>;
  public enabled: boolean;

  @unsubscribeInService()
  private changeSubscription: Subscription;
  private stateKey: ControlStateKey;
  private gridId: string;

  constructor( @Optional() private stateManagement: StateManagementService) {
    this.restoreFiltersState$ = new ReplaySubject(1);
    this.allFiltersRestored$ = new ReplaySubject(1);
    this.filterStateSaved$ = new ReplaySubject(1);
  }

  public setGridId(gridId: string): void {
    this.gridId = gridId;
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
    }
    if (this.stateManagement) {
      this.changeSubscription = this.stateManagement.loadedData$.subscribe((key: StringMap<any>) => {
        this.switchState(key);
      });
    }
  }
  
  public saveFilterState(value: any, operator: string | Function, field: string): void {
    if (!this.stateManagement || !this.gridId || !this.enabled) {
      return;
    }
    const op: string = <string>operator;
    const gridId = this.gridId;
    let state: IGridState = this.stateManagement.getGridState(gridId, this.stateKey);
    let valueState: IControlState = {};
    this.stateManagement.mapValueToState(value, valueState);
    state.filters[field] = { value: valueState, operator: op };
    this.stateManagement.setGridState(gridId, state, this.stateKey);
    this.filterStateSaved$.next({ gridId, state });
  }

  public destroy(): void {
    // See #issueWithAOTCompiler
  }

  private switchState(context: StringMap<any>): void {
    if (!this.enabled) {
      return;
    }
    this.stateKey = new ControlStateKey(context);
    setTimeout(() => {
      this.loadState();
    }, 500);
  }

  private loadState(): void {
    if (!this.stateManagement || !this.gridId) {
      return;
    }
    let state: IGridState = this.stateManagement.getGridState(this.gridId, this.stateKey);
    if (!state.filters) {
      return;
    }
    let root: CompositeFilterDescriptor = { filters: [], logic: 'and' };
    let lastField: string;
    _.forIn(state.filters, (filterState: IFilterState, key: string) => {
      if (filterState && filterState.value) {
        const value = this.stateManagement.mapValueFromState(filterState.value);
        this.restoreFiltersState$.next({ value: value, operator: filterState.operator, field: key });
        lastField = key;
        if (value) {
          let fc: filterCalculated = KendoFilterHelper.createValuedFilters(key, filterState.operator, value);
          if (fc) {
            root.filters.push(fc.filter);
          }
        } else {
          kendoUtils.trimFilterByField(root, key);
          let noValue: filterValue = KendoFilterHelper.createNonValuedFilters(key, filterState.operator, value);
          if (noValue && !noValue.isUnknown) {
            _.forEach(noValue.filters, (f: FilterDescriptor) => root = kendoUtils.composeKendoFilter(root, f));
          }
        }
      }
    });
    this.allFiltersRestored$.next({ lastField: lastField, filters: root });
  }
}
