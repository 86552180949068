<div class="slx-row" *ngIf="ruleStatementGroupItem?.variable">
  <div
    class="slx-p-0"
    [class.slx-tiers-count-1]="tiersGroupCount === 1"
    [class.slx-tiers-count-2]="tiersGroupCount === 2"
    [class.slx-tiers-count-3]="tiersGroupCount === 3"
    [class.slx-tiers-count-4]="tiersGroupCount === 4"
    [class.slx-tiers-count-5]="tiersGroupCount === 4"
  ></div>
  <div class="slx-col">
    <slx-benefit-details-eligibility-statements-clause
      [ruleStatementSequence]="ruleStatementGroupItem.sequence"
      [ruleVariable]="ruleStatementGroupItem.variable"
      [conditionType]="ruleStatementGroupItem.conditionType"
      [configurationVariables]="configurationVariables"
      [configurationPredicates]="configurationPredicates"
      [benefitClasses]="benefitClasses"
      [isFirstItem]="isFirstItem"
      (ruleVariableChanged)="onRuleVariableChanged($event)"
      (conditionTypeChanged)="onConditionTypeChanged($event)"
    ></slx-benefit-details-eligibility-statements-clause>
  </div>
</div>

<div class="slx-row" *ngIf="ruleStatementGroupItem?.groups">
  <div
    class="slx-col-auto slx-p-0 slx-ungroup-icon slx-tiers-count-1"
    [style.background-color]="getGroupColor(currentGroupLevel)"
    (click)="onRuleStatementUngrouped(ruleStatementGroupItem)"
    title="Ungroup clauses"
  >
    &nbsp;
  </div>
  <div class="slx-col">
    <ng-container
      *ngFor="let item of ruleStatementGroupItem.groups; trackBy: trackByItem; first as isFirstInternalGroup"
    >
      <slx-benefit-details-eligibility-statements-clause-group
        [ruleStatementGroupItem]="item"
        [configurationVariables]="configurationVariables"
        [configurationPredicates]="configurationPredicates"
        [tiersGroupCount]="tiersGroupCount - 1"
        [currentGroupLevel]="currentGroupLevel - 1"
        [benefitClasses]="benefitClasses"
        [isFirstItem]="isFirstItem && isFirstInternalGroup"
        (ruleStatementGroupItemChanged)="onRuleStatementGroupItemChanged($event)"
        (ruleStatementUngrouped)="onRuleStatementUngrouped($event)"
      ></slx-benefit-details-eligibility-statements-clause-group>
    </ng-container>
  </div>
</div>
