<div class="slx-number">
  <label *ngIf="hasPlaceholder" class="slx-number__label">{{ placeholder }}</label>
  <kendo-numerictextbox class="slx-number__number"
    #textbox
    [value]="currentValue"
    (valueChange)="onValueChanged($event)"
    [step]="step"
    [min]="min"
    [max]="max"
    [spinners]="spinners"
    [format]="format"
    [decimals]="decimals"
    [autoCorrect]="autoCorrect"
    [disabled]="isDisabled"
    (blur)="onBlur(currentValue)"
    (focus)="onFocus(currentValue)"
  ></kendo-numerictextbox>
</div>
