import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { RangeDates } from '../../../../common/models/range-dates';
import { StaffingPredictorManagementService } from '../../services/index';
import { DateRange } from '../../../../core/models/index';
import { unsubscribe, mutableSelect } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { LookupType } from '../../../../organization/models/index';
import { LookupService } from '../../../../organization/services/index';
import { OrgLevelWatchService } from '../../../../organization/services/index';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ComponentStateStorageService } from '../../../../common/services/component-state/component-state-storage.service';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { StateResetTypes } from '../../../../core/models/settings/index';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
export var PBJExportRange;
(function (PBJExportRange) {
    PBJExportRange["Q1"] = "Q1";
    PBJExportRange["Q2"] = "Q2";
    PBJExportRange["Q3"] = "Q3";
    PBJExportRange["Q4"] = "Q4";
})(PBJExportRange || (PBJExportRange = {}));
export var PBJExportRange22;
(function (PBJExportRange22) {
    PBJExportRange22["Q2"] = "Q2";
    PBJExportRange22["Q3"] = "Q3";
    PBJExportRange22["Q4"] = "Q4";
})(PBJExportRange22 || (PBJExportRange22 = {}));
var StaffingPredictorHeaderComponent = /** @class */ (function () {
    function StaffingPredictorHeaderComponent(managementService, lookupService, changeDetector, storageService, stateManagement, orgLevelWatchService, appSettingsManageService) {
        var _this = this;
        this.managementService = managementService;
        this.lookupService = lookupService;
        this.changeDetector = changeDetector;
        this.storageService = storageService;
        this.stateManagement = stateManagement;
        this.orgLevelWatchService = orgLevelWatchService;
        this.appSettingsManageService = appSettingsManageService;
        this.five_star_v2_enabled = false;
        this.five_star_goal_section_enabled = false;
        this.selectedOrganizations = [];
        this.loadedOrganizations = [];
        this.maximumRangeError = 'Maximum range is 92 days';
        this.exportClick = new EventEmitter();
        this.yearSelected = new EventEmitter();
        this.periodSelected = new EventEmitter();
        this.orgSelected = new EventEmitter();
        this.resetBy = StateResetTypes.SessionEnd;
        this.hideOnPrint = false;
        this.currentQuarter = {
            1: 1,
            2: 1,
            3: 1,
            4: 2,
            5: 2,
            6: 2,
            7: 3,
            8: 3,
            9: 3,
            10: 4,
            11: 4,
            12: 4,
        };
        this.startQ = {
            Q1: 10,
            Q2: 1,
            Q3: 4,
            Q4: 7
        };
        this.exportRangeTexts = {
            'Q1': 'Q1:Oct-Dec',
            'Q2': 'Q2:Jan-Mar',
            'Q3': 'Q3:Apr-Jun',
            'Q4': 'Q4:Jul-Sep'
        };
        this.exportRangeTexts22 = {
            'Q2': 'Q2:Jan-Mar',
            'Q3': 'Q3:Apr-Jun',
            'Q4': 'Q4:Jul-Sep'
        };
        var currentYear = moment().year();
        var years = _.range(2022, currentYear + 2, 1);
        this.yearsList = _.map(years, function (year) { return ({ id: year, year: year }); });
        if (currentYear == 2022) {
            this.exportRangeTypes22 = _.map(PBJExportRange22, function (expRangeId) { return ({ id: expRangeId, text: _this.exportRangeTexts22[expRangeId] }); });
        }
        else {
            this.exportRangeTypes = _.map(PBJExportRange, function (expRangeId) { return ({ id: expRangeId, text: _this.exportRangeTexts[expRangeId] }); });
        }
        this.datesControlKey = 'fiscalYearPeriod';
        this.standardQuarters1 = [];
        this.standardQuarters1.push("Q1");
        this.standardQuarters1.push("Q2");
        this.standardQuarters1.push("Q3");
        this.standardQuarters1.push("Q4");
    }
    Object.defineProperty(StaffingPredictorHeaderComponent.prototype, "organizationLookup", {
        get: function () {
            return this.m_organizationLookup;
        },
        enumerable: true,
        configurable: true
    });
    StaffingPredictorHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('StaffingPredictorHeaderComponent', true);
        this.orgLevelSubscription = this.orgLevel$
            .combineLatest(this.orgLevelWatchService.orgLevelTreeLoaded$, this.stateManagement.onInit$)
            .map(function (value) { return value[0]; })
            .subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.container = null;
                _this.appSettingsManageService.getAppServerConfig().then(function (config) {
                    _this.five_star_v2_enabled = config.five_star_v2_enabled;
                    _this.five_star_goal_section_enabled = config.five_star_goal_section_enabled;
                    _this.clearCache();
                    _this.loadOrganizations();
                    _this.restoreDates();
                });
            }
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .retryWhen(function (err) { return err.delay(1000); })
            .subscribe(function (data) {
            _this.container = data;
            // this.updateSelectedFilter();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        var range = new DateRange(this.managementService.container.startDate, this.managementService.container.endDate);
        this.currentRange = range;
        this.hideSubscription = this.managementService.hideOnPrint$.subscribe(function (hide) {
            _this.hideOnPrint = hide;
        });
    };
    StaffingPredictorHeaderComponent.prototype.filterOrgChanged = function (evnet) {
        this.loadedOrganizations = _.slice(this.selectedOrganizations);
        var startDate = this.currentRange.startDate;
        var endDate = this.currentRange.endDate;
        var rang = { startDate: startDate, endDate: endDate };
        this.onRangeChanged(rang);
    };
    StaffingPredictorHeaderComponent.prototype.onRangeChanged = function (newRange, isOrgChanged) {
        if (isOrgChanged === void 0) { isOrgChanged = false; }
        if (newRange) {
            this.managementService.setStartDate = newRange.startDate;
            this.managementService.setEndDate = newRange.endDate;
            this.managementService.loadRatingList(newRange.startDate, newRange.endDate, _.map(this.loadedOrganizations, function (o) { return o.id; }), this.five_star_v2_enabled, this.five_star_goal_section_enabled, isOrgChanged);
        }
    };
    StaffingPredictorHeaderComponent.prototype.clearCache = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
        var qua = _.get(state.value, 'exportRange', null);
        var yr = _.get(state.value, 'selYear', null);
        if (qua && yr && ((this.exportRangeType && this.exportRangeType.text != qua.text) || (this.selectedYear && this.selectedYear.year != yr.year))) {
            this.storageService.setControlState(this.stateManagement.componentKey, this.datesControlKey, { value: null }, this.resetBy);
            this.managementService.clearCache();
        }
    };
    StaffingPredictorHeaderComponent.prototype.loadOrganizations = function () {
        var _this = this;
        if (!this.orgLevel) {
            return;
        }
        this.lookupService.getLookup({ lookupType: LookupType.organization, orgLevelId: this.orgLevel.id, employeeId: undefined })
            .then(function (value) {
            _this.m_organizationLookup = value;
            _this.updateSelectedFilter();
            var rang;
            if (_this.five_star_v2_enabled) {
                var logicalyear = _this.exportRangeType.id == 'Q1' ? _this.selectedYear.year - 1 : _this.selectedYear.year;
                var logicalmonthStart = _this.startQ[_this.exportRangeType.id];
                var startDate = moment(logicalyear, "YYYY").quarter(_this.currentQuarter[logicalmonthStart]).startOf('quarter').toDate();
                var endDate = moment(logicalyear, "YYYY").quarter(_this.currentQuarter[logicalmonthStart]).endOf('quarter').toDate();
                rang = { startDate: startDate, endDate: endDate };
            }
            else {
                var startDate = _this.managementService.container.startDate;
                var endDate = _this.managementService.container.endDate;
                rang = { startDate: startDate, endDate: endDate };
                // this.managementService.loadRatingList(startDate, endDate, _.map(this.loadedOrganizations, (o) => o.id), this.five_star_v2_enabled);
            }
            _this.onRangeChanged(rang, true);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
    };
    StaffingPredictorHeaderComponent.prototype.updateSelectedFilter = function () {
        var _this = this;
        if (this.m_organizationLookup && this.container) {
            var selectedOrganizations_1 = [];
            _.each(this.m_organizationLookup.items, function (org) {
                var staffingRatingOrganization = _.find(_this.container.organizations, function (staff) { return staff.organization.id === org.id; });
                if (staffingRatingOrganization != null) {
                    selectedOrganizations_1.push(org);
                }
            });
            this.selectedOrganizations = selectedOrganizations_1.length > 0 ? selectedOrganizations_1 : [];
            this.loadedOrganizations = _.slice(this.selectedOrganizations);
            if (_.size(this.selectedOrganizations) === _.size(this.m_organizationLookup.items)) {
                this.selectedOrganizations = [];
                this.loadedOrganizations = this.m_organizationLookup.items;
            }
        }
        else if (this.m_organizationLookup) {
            this.selectedOrganizations = [];
            this.loadedOrganizations = this.m_organizationLookup.items;
        }
    };
    StaffingPredictorHeaderComponent.prototype.onSelectYear = function (year) {
        var _this = this;
        if (_.isObject(year)) {
            if (year.year == 2022) {
                this.exportRangeTypes = _.map(PBJExportRange22, function (expRangeId) { return ({ id: expRangeId, text: _this.exportRangeTexts22[expRangeId] }); });
                this.exportRangeType = _.first(this.exportRangeTypes);
            }
            else {
                this.exportRangeTypes = _.map(PBJExportRange, function (expRangeId) { return ({ id: expRangeId, text: _this.exportRangeTexts[expRangeId] }); });
                this.exportRangeType = _.first(this.exportRangeTypes);
            }
        }
    };
    StaffingPredictorHeaderComponent.prototype.onChangeExportRange = function (exportRange) {
        if (_.isObject(exportRange)) {
            //   this.request.exportRange = exportRange.id as PBJExportRange;
            //   if (exportRange.id === 'Custom') {
            //     this.dateRangeCorrect = this.isValidDateRange(this.request.startDate, this.request.endDate);
            //     return;
            //   }
            //   this.dateRangeCorrect = true;
        }
    };
    StaffingPredictorHeaderComponent.prototype.applyFilter = function () {
        this.clearCache();
        this.saveDates();
        this.yearSelected.emit();
        this.periodSelected.emit();
        var logicalyear = this.exportRangeType.id == 'Q1' ? this.selectedYear.year - 1 : this.selectedYear.year;
        var logicalmonthStart = this.startQ[this.exportRangeType.id];
        var startDate = moment(logicalyear, "YYYY").quarter(this.currentQuarter[logicalmonthStart]).startOf('quarter').toDate();
        var endDate = moment(logicalyear, "YYYY").quarter(this.currentQuarter[logicalmonthStart]).endOf('quarter').toDate();
        var rang = { startDate: startDate, endDate: endDate };
        this.onRangeChanged(rang);
    };
    StaffingPredictorHeaderComponent.prototype.onExport = function () {
        this.exportClick.emit();
    };
    StaffingPredictorHeaderComponent.prototype.saveDates = function () {
        var exportRange = this.exportRangeType;
        var selYear = this.selectedYear;
        this.storageService.setControlState(this.stateManagement.componentKey, this.datesControlKey, { value: { exportRange: exportRange, selYear: selYear } }, this.resetBy);
    };
    StaffingPredictorHeaderComponent.prototype.restoreDates = function () {
        var state = this.storageService.getControlState(this.stateManagement.componentKey, this.datesControlKey);
        var startDate = _.get(state.value, 'exportRange', null);
        var endDate = _.get(state.value, 'selYear', null);
        if (startDate && endDate) {
            this.exportRangeType = startDate;
            this.selectedYear = endDate;
        }
        else {
            this.assignDates();
            this.saveDates();
        }
        this.yearSelected.emit(this.selectedYear.year);
        this.periodSelected.emit(this.exportRangeType.id);
        this.orgSelected.emit(this.orgLevel.name);
    };
    StaffingPredictorHeaderComponent.prototype.createQuarter = function (sDate, eDate) {
        var dr = new RangeDates();
        sDate.setHours(0, 0, 0);
        eDate.setHours(23, 59, 59);
        dr.startDate = sDate;
        dr.endDate = eDate;
        return dr;
    };
    StaffingPredictorHeaderComponent.prototype.assignDates = function () {
        var today = new Date();
        var qIndex = Math.ceil((today.getMonth() + 1) / 3) - 1;
        var q = this.standardQuarters1[qIndex];
        if (moment().year() == 2022) {
            this.exportRangeType = _.find(this.exportRangeTypes22, function (expRange) { return expRange.id === q; });
        }
        else {
            this.exportRangeType = _.find(this.exportRangeTypes, function (expRange) { return expRange.id === q; });
        }
        this.selectedYear = _.find(this.yearsList, function (x) { return x.id == today.getFullYear(); });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], StaffingPredictorHeaderComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], StaffingPredictorHeaderComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], StaffingPredictorHeaderComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], StaffingPredictorHeaderComponent.prototype, "hideSubscription", void 0);
    return StaffingPredictorHeaderComponent;
}());
export { StaffingPredictorHeaderComponent };
