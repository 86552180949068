/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shift-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./shift-group-grid/shift-group-grid.component.ngfactory";
import * as i3 from "./shift-group-grid/shift-group-grid.component";
import * as i4 from "../../services/shift-group/shift-group-management.service";
import * as i5 from "./shift-group.component";
var styles_ShiftGroupComponent = [i0.styles];
var RenderType_ShiftGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShiftGroupComponent, data: {} });
export { RenderType_ShiftGroupComponent as RenderType_ShiftGroupComponent };
export function View_ShiftGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-shift-group-grid", [], null, null, null, i2.View_ShiftGroupGridComponent_0, i2.RenderType_ShiftGroupGridComponent)), i1.ɵdid(2, 114688, null, 0, i3.ShiftGroupGridComponent, [i4.ShiftGroupManagementService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ShiftGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-shift-group", [], null, null, null, View_ShiftGroupComponent_0, RenderType_ShiftGroupComponent)), i1.ɵdid(1, 114688, null, 0, i5.ShiftGroupComponent, [i4.ShiftGroupManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShiftGroupComponentNgFactory = i1.ɵccf("slx-shift-group", i5.ShiftGroupComponent, View_ShiftGroupComponent_Host_0, {}, {}, []);
export { ShiftGroupComponentNgFactory as ShiftGroupComponentNgFactory };
