import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ManagementBaseService } from '../../../core/services/management/management-base.service';
import { TimecardSummaryApiService } from './timecards-summary-api.service';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevelFlat, PayCycle, Position, Department, EmpType, PayPolicy, ShiftDiffPolicy } from '../../../organization/models/index';
import { OrgLevelType } from '../../../state-model/models/index';
import { OrgLevelWatchService, LookupApiService } from '../../../organization/services/index';
import { TimecardSummaryRequest, TimecardIndividualSummaryRequest, TimecardSummaryModel } from '../../models/index';
var DEFAULT_DIFF_PERCENTAGE = 5;
var TimecardsSummaryManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(TimecardsSummaryManagementService, _super);
    function TimecardsSummaryManagementService(api, lookupService, orgLevelWatchService) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.lookupService = lookupService;
        _this.orgLevelWatchService = orgLevelWatchService;
        _this.diffPercent = DEFAULT_DIFF_PERCENTAGE;
        _this.organizationId = -1;
        _this.employeeId = -1;
        _this.m_initialized = false;
        _this.individualMode = false;
        return _this;
    }
    Object.defineProperty(TimecardsSummaryManagementService.prototype, "allowFilters", {
        get: function () {
            return !this.individualMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardsSummaryManagementService.prototype, "showExemptEmployees", {
        get: function () {
            return !this.individualMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardsSummaryManagementService.prototype, "initialized", {
        get: function () {
            return this.m_initialized;
        },
        enumerable: true,
        configurable: true
    });
    TimecardsSummaryManagementService.prototype.onPayCycleChanged = function (payCycle) {
        if (this.currentPayCycle && moment(this.currentPayCycle.startDate).isSame(payCycle.startDate)
            && moment(this.currentPayCycle.endDate).isSame(payCycle.endDate)) {
            return;
        }
        this.currentPayCycle = payCycle;
    };
    TimecardsSummaryManagementService.prototype.onOrgLevelChanged = function (organizationId, currentOrganizationOrgLevel, orgLevel) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.diffPercent = DEFAULT_DIFF_PERCENTAGE;
                        this.individualMode = false;
                        if (!orgLevel) {
                            this.currentOrgLevel = this.findOrgLevelForOrganization(organizationId);
                            this.currentOrganizationOrgLevel = this.currentOrgLevel;
                        }
                        else {
                            this.currentOrgLevel = orgLevel;
                            this.currentOrganizationOrgLevel = currentOrganizationOrgLevel;
                        }
                        if (!(this.organizationId !== organizationId)) return [3 /*break*/, 2];
                        this.setOrganizationId(organizationId);
                        return [4 /*yield*/, this.loadLookups()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (this.currentOrgLevel.type === OrgLevelType.department) {
                            this.orgLevelDepartment = _.find(this.departments, function (d) { return d.orgLevelId === _this.currentOrgLevel.id; });
                            this.orgLevelRelatedDepartments = [this.orgLevelDepartment];
                            this.orgLevelDepartmentIds = [this.orgLevelDepartment.id];
                        }
                        else {
                            this.orgLevelDepartmentIds = null;
                            this.orgLevelDepartment = null;
                            this.orgLevelRelatedDepartments = this.departments;
                        }
                        this.loadTimecardsSummary();
                        return [2 /*return*/];
                }
            });
        });
    };
    TimecardsSummaryManagementService.prototype.onEmployeeIdChanged = function (id) {
        this.individualMode = true;
        if (this.employeeId !== id) {
            this.employeeId = id;
            this.loadIndividualTimecardSummary();
        }
    };
    TimecardsSummaryManagementService.prototype.loadTimecardsSummary = function (req) {
        var _this = this;
        if (!isNaN(this.organizationId) && this.currentPayCycle) {
            this.onLoadStatusChanged(true);
            var request = req ? req : new TimecardSummaryRequest();
            request.organizationId = this.organizationId;
            request.startDate = this.currentPayCycle.startDate;
            request.endDate = this.currentPayCycle.endDate;
            if (!request.departmentIds && this.orgLevelDepartmentIds) {
                request.departmentIds = this.orgLevelDepartmentIds;
            }
            this.api.loadTimecardsSummary(request).then(function (result) {
                _this.onLoaded(result);
                _this.onLoadStatusChanged(false);
            }).catch(function (result) {
                _this.onLoaded(null);
                _this.onError(result);
            });
        }
    };
    TimecardsSummaryManagementService.prototype.loadIndividualTimecardSummary = function () {
        var _this = this;
        if (!isNaN(this.employeeId) && this.currentPayCycle) {
            this.onLoadStatusChanged(true);
            var request = new TimecardIndividualSummaryRequest();
            request.employeeId = this.employeeId;
            request.startDate = this.currentPayCycle.startDate;
            request.endDate = this.currentPayCycle.endDate;
            this.api.loadTimecardIndividualSummary(request).then(function (result) {
                _this.onLoaded(result);
                _this.onLoadStatusChanged(false);
            }).catch(function (result) {
                _this.onLoaded(null);
                _this.onError(result);
            });
        }
    };
    TimecardsSummaryManagementService.prototype.loadLookups = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var positionsLookup, departmentLookup, empTypeLookup, payPolicyLookup, shiftDiffPolicyLookup;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                positionsLookup = this.lookupService.getPositions(undefined, this.currentOrganizationOrgLevel.id);
                departmentLookup = this.lookupService.getDepartments(undefined, this.currentOrganizationOrgLevel.id);
                empTypeLookup = this.lookupService.getEmpTypes();
                payPolicyLookup = this.lookupService.getPayPolicies(undefined, this.currentOrganizationOrgLevel.id);
                shiftDiffPolicyLookup = this.lookupService.getShiftDiffPolicies(undefined, this.currentOrganizationOrgLevel.id);
                return [2 /*return*/, Promise.all([positionsLookup, departmentLookup, empTypeLookup, payPolicyLookup, shiftDiffPolicyLookup]).then(function (values) {
                        _this.positions = values[0], _this.departments = values[1], _this.empTypes = values[2], _this.payPolicies = values[3], _this.shifDiffPolicies = values[4];
                        _this.m_initialized = true;
                    })];
            });
        });
    };
    TimecardsSummaryManagementService.prototype.findOrgLevelForOrganization = function (organizationId) {
        var list = this.orgLevelWatchService.getFlatList();
        if (list) {
            var orgLevelFlat = _.find(list, function (flat) {
                var res = flat.orgLevel.relatedItemId === organizationId && flat.orgLevel.type === OrgLevelType.organization;
                return res;
            });
            if (!orgLevelFlat) {
                return null;
            }
            return orgLevelFlat.orgLevel;
        }
        return null;
    };
    TimecardsSummaryManagementService.prototype.setOrganizationId = function (argument) {
        if (typeof argument === 'number') {
            this.organizationId = argument;
        }
        else {
            var orgLevel = argument;
            if (orgLevel.type === OrgLevelType.department) {
                this.organizationId = orgLevel.parentId;
            }
            else {
                this.organizationId = orgLevel.id;
            }
        }
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], TimecardsSummaryManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsSummaryManagementService.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], TimecardsSummaryManagementService.prototype, "orgLevelTreeSubscription", void 0);
    return TimecardsSummaryManagementService;
}(ManagementBaseService));
export { TimecardsSummaryManagementService };
