import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/combineLatest';
import { ManagementBaseService } from '../../../core/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { OrgLevel, User } from '../../../state-model/models/index';
import { PayCodesContainer } from '../../models/index';
import { PayCodesApiService } from './pay-codes-api.service';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { PayCodesMapService } from './pay-codes-map.service';
import { Assert } from '../../../../app/framework';
import { NotificationsService } from '../../../core/components/angular2-notifications/simple-notifications/services/notifications.service';
var PayCodesConfigurationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(PayCodesConfigurationManagementService, _super);
    function PayCodesConfigurationManagementService(access, changeService, api, map, notificationsService) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.map = map;
        _this.notificationsService = notificationsService;
        _this.exportTo$ = new Subject();
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.onItemSaved$ = new ReplaySubject();
        _this.viewRefresh$ = new Subject();
        _this.bulkSaveResponse$ = new Subject();
        return _this;
    }
    Object.defineProperty(PayCodesConfigurationManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    PayCodesConfigurationManagementService.prototype.init = function () {
        var _this = this;
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = true;
        this.appDataSubscription = this.orgLevel$.combineLatest(this.user$).subscribe(function (value) {
            var orgLevel = value[0], user = value[1];
            if (!orgLevel || !_.isNumber(orgLevel.id) || !user) {
                return;
            }
            _this.currentOrgLevel = orgLevel;
            _this.currentUser = user;
            _this.access.orgLevelType = _this.currentOrgLevel.type;
            if (_this.m_container) {
                _this.m_container.records = [];
                _this.onLoaded$.next(_this.m_container);
            }
            _this.onStateChanged$.next({ orgLevelChanged: true, bulkEditMode: false, canBulkEdit: false });
            _this.fetchRecords(_this.currentOrgLevel.id, _this.currentUser);
        });
    };
    PayCodesConfigurationManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    //#region IConfigurationManagementService implementation
    // no add or remove actions for this component at current state
    PayCodesConfigurationManagementService.prototype.onRemoveItem = function (itemToDelete) {
        _.noop();
    };
    PayCodesConfigurationManagementService.prototype.onAddItem = function (item) {
        _.noop();
    };
    PayCodesConfigurationManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    PayCodesConfigurationManagementService.prototype.onEditItem = function (item) {
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    PayCodesConfigurationManagementService.prototype.onCancelEditItem = function () {
        this.changeService.clearChanges();
        this.editingItem = null;
        this.editItemCmd$.next(null);
    };
    PayCodesConfigurationManagementService.prototype.onSaveItem = function (info) {
        this.updateItem(info.dataItem);
    };
    //#endregion IConfigurationManagementService implementation
    //#region bulk actions
    PayCodesConfigurationManagementService.prototype.setItemDirty = function (item) {
        item.isDirty = true;
        this.changeService.changeNotify();
    };
    PayCodesConfigurationManagementService.prototype.doBulkEdit = function () {
        this.originalCollection = this.map.clonePayCodesCollection(this.m_container.records);
        this.onStateChanged$.next({ bulkEditMode: true });
    };
    PayCodesConfigurationManagementService.prototype.doBulkSave = function () {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        var dirtyRecords = [];
        _.each(this.m_container.records, function (model) {
            if (model.isDirty) {
                dirtyRecords.push(model);
            }
        });
        if (dirtyRecords.length > 0) {
            this.api.savePayCodes(dirtyRecords, this.currentOrgLevel.id)
                .then(function () {
                _this.originalCollection = null;
                _this.updateDirtyRecords();
                _this.bulkSaveResponse$.next(true);
                _this.notificationsService.success('Success', 'The Changes are updated to Pay Codes Configuration successful');
                _this.changeService.clearChanges();
                _this.viewRefresh$.next(false);
                _this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
            }).catch(function () {
                _this.viewRefresh$.next(false);
                _this.onStateChanged$.next({ isLoading: false });
            });
        }
        else {
            this.onStateChanged$.next({ isLoading: false, bulkEditMode: false });
        }
    };
    PayCodesConfigurationManagementService.prototype.doBulkDiscard = function () {
        this.m_container.records = this.originalCollection;
        this.changeService.clearChanges();
        this.onStateChanged$.next({ bulkEditMode: false });
        this.onLoaded$.next(this.m_container);
    };
    PayCodesConfigurationManagementService.prototype.updateDirtyRecords = function () {
        _.each(this.m_container.records, function (model) {
            if (!model.payCode.isUsedInAccrualCalc) {
                model.payCode.accrualPolicyList = [];
                model.payCode.accrualPolicyNames = '';
            }
            else if (model.payCode.isUsedInAccrualCalc && (_.isNull(model.payCode.accrualPolicyList) || (model.payCode.accrualPolicyList && model.payCode.accrualPolicyList.length == 0))) {
                model.payCode.accrualPolicyNames = 'All';
            }
            if (model.isDirty)
                model.isDirty = false;
        });
    };
    //#endregion bulk actions
    PayCodesConfigurationManagementService.prototype.fetchRecords = function (orgLevelId, user) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.getPayCodes(orgLevelId).
            then(function (result) {
            _this.access.lockActions = false;
            _this.m_container = result;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            if (_this.m_container.actions)
                _this.access.actions = _this.m_container.actions;
            _this.onLoaded$.next(_this.m_container);
            var state = {
                isLoading: false,
                bulkEditMode: false,
                canBulkEdit: _this.m_container.actions.canEdit || _this.m_container.canEditExceptionFlag || _this.m_container.canEditPaycodestoAccrualMappingFlag,
                canEditPBJFlag: _this.m_container.actions.canEdit,
                canEditUseInTimesheets: _this.m_container.actions.canEdit,
                canEditExceptionFlag: _this.m_container.canEditExceptionFlag,
                canEditAca: _this.m_container.actions.canEdit,
                canEditPaycodestoAccrualMappingFlag: _this.m_container.canEditPaycodestoAccrualMappingFlag,
                canEdit: false,
                canAdd: false,
                canDelete: false,
                isSmartAdmin: user.isSmartAdmin
            };
            _this.onStateChanged$.next(state);
        }).catch(function (e) {
            _this.m_container = new PayCodesContainer();
            _this.m_container.records = [];
            _this.onLoaded$.next(_this.m_container);
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PayCodesConfigurationManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.savePayCode(item)
            .then(function () {
            _this.onItemSaved$.next(_this.editingItem);
            _this.editingItem = null;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
            _this.changeService.clearChanges();
        }).catch(function () {
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    PayCodesConfigurationManagementService.prototype.updatePayCodeModel = function (from, to) {
        to.payCode.id = from.payCode.id;
        to.payCode.description = from.payCode.description;
        to.payCode.allocationType = from.payCode.allocationType;
        to.payCode.amount = from.payCode.amount;
        to.payCode.color = from.payCode.color;
        to.payCode.department = from.payCode.department;
        to.payCode.description = from.payCode.description;
        to.payCode.group = from.payCode.group;
        to.payCode.interfaceIndicator = from.payCode.interfaceIndicator;
        to.payCode.isAca = from.payCode.isAca;
        to.payCode.isPaid = from.payCode.isPaid;
        to.payCode.isPbj = from.payCode.isPbj;
        to.payCode.payDifferential = from.payCode.payDifferential;
        to.payCode.isException = from.payCode.isException;
        to.lastUpdateDate = from.lastUpdateDate;
        to.lastUpdateUsername = from.lastUpdateUsername;
    };
    PayCodesConfigurationManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    PayCodesConfigurationManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    PayCodesConfigurationManagementService.prototype.subscribeToBulkSaveResponse = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.bulkSaveResponse$.subscribe(callback);
    };
    PayCodesConfigurationManagementService.prototype.getAccrualPolicies = function (orglevelId) {
        return this.api.getAccrualPolicy(orglevelId);
    };
    PayCodesConfigurationManagementService.prototype.getAccrualTypes = function (orglevelId) {
        return this.api.getAccrualType(orglevelId);
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodesConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        mutableSelect(['session', 'user']),
        tslib_1.__metadata("design:type", Observable)
    ], PayCodesConfigurationManagementService.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCodesConfigurationManagementService.prototype, "appDataSubscription", void 0);
    return PayCodesConfigurationManagementService;
}(ManagementBaseService));
export { PayCodesConfigurationManagementService };
