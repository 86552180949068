import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { ILookupEntity, LookupEntity, ReadFile } from '../../../organization/models/index';

import { dateTimeUtils, ColorUtil } from '../../../common/utils/index';
import { DateRange, IDateRangeStr } from '../../../core/models/index';
import { ILoaAttachment, LoaAttachment } from '../../../organization/models/index';

import {
  ILoaRequest, LoaRequest, ILoaRepeat, LoaRepeat, ILoaRequestEmployee, LoaRequestEmployee,
  ILoaConsole, LoaConsole, ILoaTypeOverview, LoaTypeOverview, LoaType, ILoaType,
  ILoaTypeModel, LoaTypeModel, LoaTypeModelContainer, ILoaTypeModelContainer,
  ILoaTypeEditResult, LoaTypeEditResult, ILoaRequestContainer, LoaRequestContainer
} from '../models/index';
import { LookupMapService } from '../../../organization/services/index';
import * as moment from 'moment';


@Injectable()
export class LMMapService {
  constructor(private lookupMapService: LookupMapService) {

  }
  public mapLoaRequests(dtos: ILoaRequest[]): any[] {
    return _.map(dtos, (dto: ILoaRequest) => this.mapLoaRequest(dto));
  }

  public mapLoaConsole(dto: ILoaConsole): LoaConsole {
    const data = new LoaConsole();
    data.onLeave = this.mapLoaRequests(dto.onLeave);
    data.returning = this.mapLoaRequests(dto.returning);
    data.upcoming = this.mapLoaRequests(dto.upcoming);
    data.pastDue = this.mapLoaRequests(dto.pastDue);
    data.onLeaveTotalCount = dto.onLeaveTotalCount;
    data.returningTotalCount = dto.returningTotalCount;
    data.upcomingTotalCount = dto.upcomingTotalCount;
    data.pastDueTotalCount = dto.pastDueTotalCount;
    data.incompleteTotalCount = dto.incompleteTotalCount;
    data.totalCount = dto.totalCount;
    data.loaTypeOverview = [];
    _.forEach(dto.loaTypeOverview, (o: ILoaTypeOverview) => {
      const ov = this.mapLoaTypeOverview(o);
      data.loaTypeOverview.push(ov);
    });
    return data;
  }

  public mapLoaTypeOverview(dto: ILoaTypeOverview): LoaTypeOverview {
    const data = new LoaTypeOverview();
    data.count = dto.count;
    data.loaType = this.mapLoaType(dto.loaType);
    return data;
  }

  public mapLoaRequest(dto: ILoaRequest): LoaRequest {
    const loaRequest = new LoaRequest();
    loaRequest.id = dto.id;
    loaRequest.employee = this.mapEmployee(dto.employee);
    loaRequest.department = dto.department ? this.lookupMapService.mapDepartment(dto.department) : null;
    loaRequest.position = dto.position ? this.lookupMapService.mapPosition(dto.position) : null;
    loaRequest.organization = dto.organization ? this.lookupMapService.mapOrganization(dto.organization) : null;
    loaRequest.requestClass = dto.requestClass;
    loaRequest.type = this.mapLoaType(dto.type);
    loaRequest.requestDate = dateTimeUtils.convertFromDtoDateTimeString(dto.requestDate);
    if(_.isDate(loaRequest.requestDate)) {
      loaRequest.requestDate = moment(dto.requestDate).startOf('day').toDate();
    }
    loaRequest.estStartDate = dateTimeUtils.convertFromDtoDateTimeString(dto.estStartDate);
    loaRequest.estEndDate = dateTimeUtils.convertFromDtoDateTimeString(dto.estEndDate);
    loaRequest.actStartDate = dateTimeUtils.convertFromDtoDateTimeString(dto.actStartDate);
    loaRequest.actEndDate = dateTimeUtils.convertFromDtoDateTimeString(dto.actEndDate);
    loaRequest.reason = dto.reason;
    loaRequest.notes = dto.notes;
    loaRequest.isDeleted = dto.isDeleted;
    loaRequest.attachments = this.mapAttachment(dto.attachments);
    loaRequest.exceptionDates = this.mapDates(dto.exceptionDates);
    loaRequest.addedDate = dateTimeUtils.convertFromDtoDateTimeString(dto.addedDate);
    if(_.isDate(loaRequest.addedDate)) {
      loaRequest.addedDate = moment(dto.addedDate).startOf('day').toDate();
    }
    loaRequest.addedBy = dto.addedBy;
    loaRequest.updatedDate = dateTimeUtils.convertFromDtoDateTimeString(dto.updatedDate);
    if(_.isDate(loaRequest.updatedDate)) {
      loaRequest.updatedDate = moment(dto.updatedDate).startOf('day').toDate();
    }
    loaRequest.updatedBy = dto.updatedBy;
    loaRequest.loaRepeat = this.mapRepeat(dto.loaRepeat);
    loaRequest.loaCategories = dto.loaCategories;
    loaRequest.daysOnLeave = dto.daysOnLeave;
    loaRequest.daysRemaining = dto.daysRemaining;
    loaRequest.daysOnLoa = dto.daysOnLoa;

    return loaRequest;
  }

  public mapLoaRequestToDto(req: LoaRequest): ILoaRequest {
    const dto = {} as ILoaRequest;
    dto.id = req.id;
    dto.employee = this.mapEmployeeToDto(req.employee);
    dto.requestClass = req.requestClass;
    dto.type = this.mapLoaTypeToDto(req.type);
    dto.requestDate = dateTimeUtils.convertToDtoDateTimeString(req.requestDate);
    dto.estStartDate = dateTimeUtils.convertToDtoDateTimeString(req.estStartDate);
    dto.estEndDate = dateTimeUtils.convertToDtoDateTimeString(req.estEndDate);

    let actStartDate = req.actStartDate;
    let actEndDate = req.actEndDate;
    if (req.isIntermittent && _.isObjectLike(req.absencePeriod)) {
      actStartDate = req.absencePeriod.startDate;
      actEndDate = req.absencePeriod.endDate;
    }
    dto.actStartDate = dateTimeUtils.convertToDtoDateTimeString(actStartDate);
    dto.actEndDate = dateTimeUtils.convertToDtoDateTimeString(actEndDate);

    dto.reason = req.reason;
    dto.notes = req.notes;
    dto.attachments = this.mapAttachmentToDto(req.attachments);
    dto.exceptionDates = this.mapDatesToDto(req.exceptionDates);
    dto.addedDate = dateTimeUtils.convertToDtoDateTimeString(req.addedDate);
    dto.addedBy = req.addedBy;
    dto.updatedDate = dateTimeUtils.convertToDtoDateTimeString(req.updatedDate);
    dto.updatedBy = req.updatedBy;
    dto.loaRepeat = this.mapRepeatToDto(req.loaRepeat);
    dto.loaCategories = req.loaCategories;
    dto.daysOnLeave = req.daysOnLeave;
    dto.daysRemaining = req.daysRemaining;
    dto.daysOnLoa = req.daysOnLoa;

    return dto;
  }

  public mapLoaTypes(dtos: ILoaType[]): LoaType[] {
    return _.map(dtos, (dto) => this.mapLoaType(dto));
  }

  public mapEmployee(dto: ILoaRequestEmployee): LoaRequestEmployee {
    const id = _.get(dto, 'id') || 0;
    const name = _.get(dto, 'name') || '';

    return new LoaRequestEmployee(id, name);
  }

  public mapEmployeeToDto(emp: LoaRequestEmployee): ILoaRequestEmployee {
    if (_.isObjectLike(emp)) {
      return {
        id: emp.id,
        name: emp.name
      };
    }
    return null;
  }

  public mapRepeat(dto: ILoaRepeat): LoaRepeat {
    const repeat = new LoaRepeat();
    if (_.isObjectLike(dto)) {
      repeat.customDates = this.mapDates(dto.customDates);
      repeat.recurrenceRule = dto.recurrenceRule;
      repeat.recurrenceException = dto.recurrenceException;
      repeat.recurrenceId = dto.recurrenceId;
      repeat.isAllDay = dto.isAllDay;
    }

    return repeat;
  }

  public mapRepeatToDto(repeat: LoaRepeat): ILoaRepeat {
    if (_.isObjectLike(repeat)) {
      return {
        customDates: this.mapDatesToDto(repeat.customDates),
        recurrenceRule: repeat.recurrenceRule,
        recurrenceException: repeat.recurrenceException,
        recurrenceId: repeat.recurrenceId,
        isAllDay: repeat.isAllDay
      };
    }

    return null;
  }

  public mapAttachment(dtos: ILoaAttachment[]): LoaAttachment[] {
    if (_.isArray(dtos) && _.size(dtos) > 0) {
      return _.map(dtos, (dto: ILoaAttachment) => new LoaAttachment(dto.id, dto.name, dto.employeeId, dto.fileName));
    }
    return [];
  }

  public mapAttachmentToDto(attachments: LoaAttachment[]): ILoaAttachment[] {
    if (_.isArray(attachments) && _.size(attachments) > 0) {
      return _.map(attachments, (a: LoaAttachment) => ({
        id: a.id,
        name: a.name,
        employeeId: a.employeeId,
        fileName: a.fileName
      }));
    }
    return null;
  }

  public mapFormDataPropName(prop: string, index: number): string {
    return `i${index}_${prop}`;
  }

  public mapFilesToFormData(files: ReadFile[]): FormData {
    const formData: FormData = new FormData();
    let index = 0;
    _.forEach(files, (file: ReadFile) => {
      formData.append(this.mapFormDataPropName('name', index), file.name);
      formData.append(this.mapFormDataPropName('fileName', index), file.fileName);
      formData.append(this.mapFormDataPropName('category', index), file.mimeType);
      formData.append(this.mapFormDataPropName('file', index), file.data, file.fileName);
      index++;
    });
    return formData;
  }

  public mapDates(dtos: IDateRangeStr[]): DateRange[] {
    if (_.isArray(dtos) && _.size(dtos) > 0) {
      return _.map(dtos, (dto: IDateRangeStr) => new DateRange(
        dateTimeUtils.convertFromDtoDateTimeString(dto.startDate),
        dateTimeUtils.convertFromDtoDateTimeString(dto.endDate)
      ));
    }
    return [];
  }

  public mapDatesToDto(ranges: DateRange[]): IDateRangeStr[] {
    if (_.isArray(ranges) && _.size(ranges) > 0) {
      return _.map(ranges, (range: DateRange) => ({
        startDate: dateTimeUtils.convertToDtoDateTimeString(range.startDate),
        endDate: dateTimeUtils.convertToDtoDateTimeString(range.endDate)
      }));
    }
    return [];
  }

  public mapLoaType(dto: ILoaType): LoaType {
    let data = new LoaType();
    data.id = dto.id;
    data.name = dto.name;
    data.description = dto.description;
    if (_.isFinite(dto.color)) {
      data.color = ColorUtil.DecToHexString(dto.color, true);
    }
    return data;
  }

  public mapLoaTypeToDto(type: LoaType): ILoaType {
    if (_.isObjectLike(type)) {
      return {
        id: type.id,
        name: type.name,
        description: type.description,
        color: type.color ? ColorUtil.HexToDec(type.color) : null
      };
    }
    return null;
  }

  public mapLoaTypeModel(dto: ILoaTypeModel): LoaTypeModel {
    let data = new LoaTypeModel();
    data.loaType = this.mapLoaType(dto.loaType);
    data.modifiedBy = dto.modifiedBy;
    data.modifiedAt = dateTimeUtils.convertFromDtoDateTimeString(dto.modifiedAt);
    return data;
  }

  public mapLoaTypeModelToDto(data: LoaTypeModel): ILoaTypeModel {
    if (_.isObjectLike(data)) {
      return {
        loaType: this.mapLoaTypeToDto(data.loaType),
        modifiedBy: data.modifiedBy,
        modifiedAt: dateTimeUtils.convertToDtoDateTimeString(data.modifiedAt)
      };
    }
    return null;
  }

  public mapLoaTypeModelContainer(dto: ILoaTypeModelContainer): LoaTypeModelContainer {
    const data = new LoaTypeModelContainer();
    data.canEdit = dto.canEdit;
    data.items = _.map(dto.items, (d: ILoaTypeModel) => this.mapLoaTypeModel(d));
    return data;
  }

  public mapLoaTypeEditResult(dto: ILoaTypeEditResult): LoaTypeEditResult {
    const data = new LoaTypeEditResult();
    data.isSuccess = dto.isSuccess;
    data.message = dto.message;
    data.item = dto.item ? this.mapLoaTypeModel(dto.item): null;
    return data;
  }

  public mapLoaRequestContainer(dto: ILoaRequestContainer): LoaRequestContainer {
    const data = new LoaRequestContainer();
    data.requests = this.mapLoaRequests(dto.requests);
    data.start = dateTimeUtils.convertFromDtoDateTimeString(dto.start);
    data.end = dateTimeUtils.convertFromDtoDateTimeString(dto.end);
    return data;
  }

  public mapAction(actions: string[], action: string): boolean {
    return !_.isUndefined(_.find(actions, action))
  }
}


