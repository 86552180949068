import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { FieldsMeta } from '../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { ResponseBody } from '../../../core/models/api/response-body';

import { appConfig } from '../../../app.config';
import { AcaC1095AuditMapService } from './aca-c1095-audit-map.service';
import { HttpRequest } from '@angular/common/http';
import { configurationConfig } from '../../configuration.config';
import { IAcaC1095Audit, AcaC1095Audit } from '../../models/aca-c1095-audit';

@Injectable()
export class AcaC1095AuditApiService {
    constructor(
        private apiUtilService: ApiUtilService,
        private mapService: AcaC1095AuditMapService,
        private urlParamsService: UrlParamsService
    ) { }

    public async getC1095AuditRecords(orgLevelId: number, year: number): Promise<AcaC1095Audit> {
        const url: string = this.getC1095AuditApi(orgLevelId, year);

        const request = this.urlParamsService.createGetRequest(url, {
            year: year
        });

        return this.apiUtilService
            .request<IAcaC1095Audit, FieldsMeta>(request)
            .then((response: ResponseBody<IAcaC1095Audit, FieldsMeta>) => this.mapService.mapC1095AuditRecords(response.data, response.meta));
    }
    private getC1095AuditApi(orgLevelId: number, year: number): string {
        return `${this.getApiRoot()}/${configurationConfig.api.ACA.root}/${configurationConfig.api.ACA.c1095Audit.root}/${orgLevelId}/${year}/${configurationConfig.api.ACA.c1095Audit.all}`;
    }

    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }
}
