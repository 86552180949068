export interface IUpdatePolicyName {
    oldPolicyName: string,
    newPolicyName: string,
    policyName: string
}

export class UpdatePolicyName {
    public oldPolicyName: string;
    public newPolicyName: string;
    public policyName: string;
}