import * as _ from 'lodash';

export interface IAppSettingConfig {
  serverName?: string;
  serverSide?: boolean;
  type?: 'value' | 'column';
}

export interface IAppSettingGroupConfig {
  serverName: string;
  fields: StringMap<IAppSettingConfig>;
}

export interface IAppSettingsConfig {
  [key: string]: IAppSettingGroupConfig;
}

export const appSettingsConfig: IAppSettingsConfig = {
  ScheduleConsoleComponent: {
    serverName: 'ScheduleConsoleComponent',
    fields: {
      consoleConfig: { serverName: 'consoleConfig', type: 'value', serverSide: true }
    }
  },
  DailyTimeCardComponent: {
    serverName: 'DailyTimeCardComponent',
    fields: {
      timecardDisplayOptions: { serverName: 'timecardDisplayOptions', type: 'value', serverSide: true },
      updateScheduleOption: { serverName: 'updateScheduleOption', type: 'value', serverSide: true }
    }
  },
  TimecardsDisplayComponent: {
    serverName: 'TimecardsDisplayComponent',
    fields: {
      empColumns: { serverName: 'empColumns', type: 'column', serverSide: true },
      hideEmptyTimeCard: { serverName: 'hideEmptyTimeCard', type: 'value', serverSide: true }
    }
  },
  PunchesManagementComponent: {
    serverName: 'PunchesManagementComponent',
    fields: {
      EmployeePunches: { serverName: 'EmployeePunches', type: 'column', serverSide: true },
      EmployeePunchesFilters: { serverName: 'EmployeePunchesFilters', type: 'value', serverSide: true }
    }
  },
  DailyLinePunchGridComponent: {
    serverName: 'DailyLinePunchGridComponent',
    fields: {
      LinePunches: { serverName: 'LinePunches', type: 'column', serverSide: true }
    }
  },
  ShiftReplacementSelectorComponent: {
    serverName: 'ShiftReplacementSelectorComponent',
    fields: {
      filter: { serverName: 'filter', type: 'value', serverSide: true }
    }
  },
  OrganizationStructureComponent: {
    serverName: 'OrganizationStructureComponent',
    fields: {
      LastOrgLevel: { serverName: 'LastOrgLevel', type: 'value', serverSide: true }
    }
  },
  LMRosterComponent: {
    serverName: 'LMRosterComponent',
    fields: {
      columnsVisibility: { serverName: 'columnsVisibility', type: 'column', serverSide: true }
    }
  },
  AccrualsBalancesComponent: {
    serverName: 'AccrualsBalancesComponent',
    fields: {
      columnsVisibility: { serverName: 'columnsVisibility', type: 'column', serverSide: true },
      AccrualsBalancesState: { serverName: 'AccrualsBalancesState', type: 'value', serverSide: true }
    }
  },
};
