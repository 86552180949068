import { Component, OnInit, Provider, ViewChild } from '@angular/core';
import { DialogOptions, IDialog } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/index';
import { MealDeducitonsDialogOptions } from '../../../models/meal-deductions/meal-deductions-dialog-options';
import { MealDeductionConfigurationComponent } from '../meal-deduction-configuration/meal-deduction-configuration.component';

@Component({
    moduleId: module.id,
    selector: 'slx-meal-deduction-configuration-dialog',
    templateUrl: 'meal-deduction-configuration-dialog.component.html',
    styleUrls: ['meal-deduction-configuration-dialog.component.scss']
})

export class MealDeductionConfigurationDialogComponent implements IDialog {

    public dialogResult: boolean;

    public get hideDescription(): boolean {
        if (this.mealDeductionOptions) {
            return this.mealDeductionOptions.hideDescription;
        }
        return false;
    }

    @ViewChild('configEditorComponent', {static: true})
    private configEditorComponent: MealDeductionConfigurationComponent;

    public static openDialog(options: MealDeducitonsDialogOptions, modalService: ModalService, callback: (result: boolean) => void): MealDeductionConfigurationDialogComponent {
        let dialogOptions: DialogOptions = new DialogOptions();
        dialogOptions.width = 700;
        dialogOptions.height = 600;
        dialogOptions.fullHeightOnMobile = true;
        let providers: Provider[] = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: MealDeducitonsDialogOptions,
                useValue: options
            }
        ];
        let dialog: MealDeductionConfigurationDialogComponent = modalService.globalAnchor
            .openDialog(MealDeductionConfigurationDialogComponent, options.title, dialogOptions, providers, (result: boolean) => {
                callback(result);
            });
        return dialog;
    }

    constructor(
        private mealDeductionOptions: MealDeducitonsDialogOptions,
        private dialogOptions: DialogOptions,
        private modalService: ModalService
    ) {

    }

    public onClose(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    }

    public closeAfterSave(result: boolean): void {
        this.dialogResult = result;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    }

    public onSave(): void {
        if (!this.mealDeductionOptions.skipSave) {
            this.configEditorComponent.save();
        } else {
            this.closeAfterSave(true);
        }
    }
}
