<div class="absence">
  <header class="absence__header">
    <p class="absence__emp flex align-items__center">
      <img class="absence__emp-icon" [employeeThumbinalSrc]="empId" alt="Employee Photo" />
      <span class="absence__emp-info flex__grow">
        <strong class="absence__emp-name">{{ empName }}</strong>
        <strong class="absence__emp-field">Position:&nbsp;</strong>
        <span class="absence__emp-value">{{ posName }}</span>
        <strong class="absence__emp-field">Department:&nbsp;</strong>
        <span class="absence__emp-value">{{ depName }}</span>
        <strong class="absence__emp-field">Facility:&nbsp;</strong>
        <span class="absence__emp-value">{{ orgName }}</span>
      </span>
    </p>
    <p class="flex justify-content__space-between absence__buttons">
      <button class="slx-button slx-with-icon slx-margin-r" type="button" (click)="onClickEmployee()">
        <i aria-hidden="true" class="fas fa-user slx-button__icon"></i>
        <span class="slx-button__text">View Profile</span>
      </button>
      <span>
        <button
          *ngIf="canDeleteRequest"
          [popover]="confirmPopover"
          class="slx-button slx-with-icon slx-margin-r"
          type="button"
        >
          <i aria-hidden="true" class="fas fa-trash-alt slx-button__icon"></i>
          <span class="slx-button__text">Delete Request</span>
        </button>
        <popover-content #confirmPopover title="Are you sure?" placement="auto bottom">
          <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r" (click)="onClickDelete(confirmPopover)">Yes</button>
          <button type="button" class="slx-button slx-narrow" (click)="onClickCancel(confirmPopover)">No</button>
        </popover-content>
      </span>
    </p>
  </header>
  <div class="absence__fields">
    <p class="flex absence__control">
      <label class="align-self__center absence__c-label">Leave type</label>
      <span class="flex__grow">
        <slx-dropdown-list
          class="slx-wide slx-short"
          valueField="name"
          titleField="name"
          [options]="absenceTypes"
          [(ngModel)]="absenceType"
          (ngModelChange)="onChangeAbsence($event)"
          [readonly]="!canEditRequest"
          name="calendarMode"
          [required]="true"
          #absenceTypeModel="ngModel"
        ></slx-dropdown-list>
        <span *ngIf="absenceTypeModel.errors" class="slx-error-block error-box">
          <span *ngIf="absenceTypeModel.errors.required" errorMessage for="required"></span>
        </span>
        <span *ngIf="!hasAbsenceTypes" class="slx-error-block">No Leave of Absence Type is available to select. Please configure Request Type in the system</span>
      </span>
    </p>
    <p class="flex absence__control">
      <label class="align-self__center absence__c-label">Reason</label>
      <span class="flex__grow">
        <input class="absence__field"
          maxlength="255"
          [(ngModel)]="reason"
          (ngModelChange)="onChangeReason($event)"
          [disabled]="!canEditRequest"
          [required]="true"
          name="reason"
          autocomplete="off"
          type="text"
          #reasonModel="ngModel"
        />
        <span *ngIf="reasonModel.errors" class="slx-error-block error-box">
          <span *ngIf="reasonModel.errors.required" errorMessage for="required"></span>
        </span>
      </span>
    </p>
    <p class="flex absence__control">
      <label class="align-self__center absence__c-label">Request date</label>
      <span class="flex__grow">
        <slx-datepicker class="slx-wide"
          [readonly]="!canEditRequest"
          [(ngModel)]="requestDate"
          [acceptNullDate]="false"
          (ngModelChange)="onChangeRequestDate($event)"
          [required]="true" #requestDateModel="ngModel"
        ></slx-datepicker>
        <span *ngIf="requestDateModel.errors" class="slx-error-block error-box">
          <span *ngIf="requestDateModel.errors.required" errorMessage for="required"></span>
        </span>
      </span>

    </p>
  </div>
  <kendo-tabstrip class="slx-tabs">
    <kendo-tabstrip-tab title="Dates" [selected]="getSelectedTab('Dates')">
      <ng-template kendoTabContent>
        <slx-lm-continuous-tab *ngIf="isContinous" class="slx-tabs__tab" [class.tall]="isIntermittent"></slx-lm-continuous-tab>
        <slx-lm-intermittent-tab *ngIf="isIntermittent" class="slx-tabs__tab" [class.tall]="isIntermittent"></slx-lm-intermittent-tab>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Notes">
      <ng-template kendoTabContent>
        <slx-lm-absence-notes-tab class="slx-tabs__tab" [class.tall]="isIntermittent"></slx-lm-absence-notes-tab>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Attachments" [selected]="getSelectedTab('Attachments')">
      <ng-template kendoTabContent>
        <slx-lm-absence-attachments-tab class="slx-tabs__tab" [class.tall]="isIntermittent"></slx-lm-absence-attachments-tab>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="Exceptions">
      <ng-template kendoTabContent>
        <slx-lm-absence-exceptions-tab class="slx-tabs__tab" [class.tall]="isIntermittent"></slx-lm-absence-exceptions-tab>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
