import * as _ from 'lodash';

import { EmployeeAccrual } from '../../employee/employee-sections/models/index';

export class AccrualBalanceDialogOptions {
  public employeeName: string;
  public positionName: string;
  public accruals: EmployeeAccrual[];

  constructor(empName: string, posName: string, accruals: EmployeeAccrual[]) {
    this.employeeName = empName;
    this.positionName = posName;
    this.accruals = _.isArray(accruals) ? accruals : [];
  }
}
