import { LimitType } from './field-limit-attribute';
var FieldData = /** @class */ (function () {
    function FieldData() {
    }
    Object.defineProperty(FieldData.prototype, "rawValue", {
        get: function () {
            return this.innerRawValue;
        },
        set: function (value) {
            this.innerRawValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldData.prototype, "fieldValue", {
        get: function () {
            return this.innerValue;
        },
        set: function (val) {
            if (this.innerValue !== val) {
                this.innerValue = val;
                this.isDirty = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldData.prototype, "Policy", {
        get: function () {
            var policy = {
                isEditable: this.securityAttribute.editable,
                isVisible: !this.securityAttribute.hidden,
                isMasked: this.securityAttribute.masked,
                isRequired: this.isRequired,
                minValue: null,
                maxValue: null,
                rawValue: this.innerRawValue
            };
            if (this.fieldType !== 'string') {
                if (this.limitAttribute.limitType === LimitType.noLower || this.limitAttribute.limitType === LimitType.inRange) {
                    policy.minValue = this.limitAttribute.minValue;
                }
                if (this.limitAttribute.limitType === LimitType.noBigger || this.limitAttribute.limitType === LimitType.inRange) {
                    policy.maxValue = this.limitAttribute.maxValue;
                }
            }
            return policy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FieldData.prototype, "isEditable", {
        get: function () {
            return this.securityAttribute.editable;
        },
        enumerable: true,
        configurable: true
    });
    FieldData.prototype.createBasedOn = function () {
        var fd = new FieldData();
        fd.fieldName = this.fieldName;
        fd.limitAttribute = this.limitAttribute;
        fd.securityAttribute = this.securityAttribute;
        fd.isTemporal = this.isTemporal;
        fd.temporalMinDate = this.temporalMinDate;
        return fd;
    };
    return FieldData;
}());
export { FieldData };
