import * as _ from 'lodash';
import { EventEmitter, OnChanges, SimpleChanges, AfterViewChecked } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
var Types = /** @class */ (function () {
    function Types() {
    }
    Types.required = 'required';
    Types.min = 'min';
    Types.max = 'max';
    return Types;
}());
var KendoNumberComponent = /** @class */ (function () {
    function KendoNumberComponent(ngControl) {
        this.step = 1;
        this.decimals = 0;
        this.format = 'n';
        this.autoCorrect = false;
        this.required = false;
        this.readonly = false;
        this.placeholder = '';
        this.autofocus = false;
        this.blur = new EventEmitter();
        this.focus = new EventEmitter();
        this.spinners = false;
        this.autofocused = false;
        this.ngControl = ngControl;
        ngControl.valueAccessor = this;
        this.optionNames = ['step', 'min', 'max', 'decimals', 'format', 'spinners', 'autoCorrect'];
    }
    KendoNumberComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    KendoNumberComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    KendoNumberComponent.prototype.ngOnChanges = function (changes) {
        if (_.keys(changes).length > 0) {
            var self_1 = this;
            _.forEach(this.optionNames, function (optionName) {
                if (optionName in changes && changes[optionName].previousValue !== changes[optionName].currentValue) {
                    self_1[optionName] = changes[optionName].currentValue;
                }
            });
        }
    };
    KendoNumberComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        if (this.autofocus && !this.autofocused && this.kendoNumber) {
            this.autofocused = true;
            setTimeout(function () {
                _this.kendoNumber.focus();
            }, 0);
        }
    };
    KendoNumberComponent.prototype.onValueChanged = function (value) {
        if (this.isValid(Types.required, value)) {
            var isValidMin = this.isValid(Types.min, value);
            var isValidMax = this.isValid(Types.max, value);
            if (!this.autoCorrect && (!isValidMin || !isValidMax)) {
                this.setError(!isValidMin ? 'min' : 'max');
                return;
            }
            this.currentValue = value;
            this.resetError();
        }
        else if (this.required) {
            this.setError('required');
        }
        this.onChangeCallback(value);
    };
    KendoNumberComponent.prototype.writeValue = function (value) {
        if (value && typeof value === 'string') {
            value = _.toNumber(value);
        }
        if (this.isValid(Types.required, value)) {
            var isValidMin = this.isValid(Types.min, value);
            var isValidMax = this.isValid(Types.max, value);
            if (!this.autoCorrect && (!isValidMin || !isValidMax)) {
                this.setError(!isValidMin ? 'min' : 'max');
                return;
            }
            this.currentValue = value;
            this.resetError();
        }
        else {
            this.currentValue = null;
            if (this.required) {
                this.setError('required');
            }
        }
    };
    KendoNumberComponent.prototype.onBlur = function (value) {
        this.blur.emit(value);
    };
    KendoNumberComponent.prototype.onFocus = function (value) {
        this.focus.emit(value);
    };
    KendoNumberComponent.prototype.isValid = function (type, value) {
        switch (type) {
            case Types.required:
                return _.isNumber(value);
            case Types.min:
                var min = +this.min;
                return _.isNaN(min) ? true : value >= min;
            case Types.max:
                var max = +this.max;
                return _.isNaN(max) ? true : value <= max;
            default:
                return false;
        }
    };
    KendoNumberComponent.prototype.setError = function (errName) {
        var _a;
        if (this.ngControl.control) {
            this.ngControl.control.setErrors((_a = {}, _a[errName] = true, _a));
        }
    };
    KendoNumberComponent.prototype.resetError = function () {
        if (this.ngControl.control) {
            this.ngControl.control.setErrors(null);
        }
    };
    return KendoNumberComponent;
}());
export { KendoNumberComponent };
