<slx-spinner [show]="isLoading">
    <div class="modal-body">
        <slx-individual-schedule-employee 
            (onHasChanges)="onHasChanges($event)"
            [scheduleViewParams]="scheduleViewParams">
        </slx-individual-schedule-employee>
    </div>
    <div class="modal-footer">
        <div (click)="onCancel()" class="btn"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Close</div>
    </div>
  </slx-spinner>  
  