import { OrgLevelType } from '../../../state-model/models/index';
import { Subject } from 'rxjs/Subject';
import { ActionsType } from '../../../core/models/field/actions-type';
import * as _ from 'lodash';
var AccessManagementService = /** @class */ (function () {
    function AccessManagementService() {
        this.m_permissions = 0;
        this.m_OrgLevelType = OrgLevelType.department;
        this.m_allowedOrgLevels = [];
        this.subject = new Subject();
    }
    Object.defineProperty(AccessManagementService.prototype, "actions", {
        get: function () {
            return this.m_Actions;
        },
        set: function (value) {
            this.m_Actions = value;
            var flags = 0;
            if (value.canEdit)
                flags = flags | AccessManagementService.USER_CAN_EDIT;
            if (value.canDelete)
                flags = flags | AccessManagementService.USER_CAN_DELETE;
            if (value.canAdd)
                flags = flags | AccessManagementService.USER_CAN_ADD;
            this.setPermissions(flags);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "selectedItemsCount", {
        set: function (count) {
            this.m_hasSelected = count > 0;
            this.updateState();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "lockActions", {
        get: function () {
            return this.m_LockActions;
        },
        set: function (value) {
            this.m_LockActions = value;
            this.updateState();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "orgLevelType", {
        set: function (value) {
            this.m_OrgLevelType = value;
            this.updateState();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "allowDepartmentLevel", {
        set: function (value) {
            if (value) {
                this.m_allowedOrgLevels.push(OrgLevelType.department);
            }
            else {
                this.m_allowedOrgLevels = _.without(this.m_allowedOrgLevels, OrgLevelType.department);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "allowOrganizationLevel", {
        set: function (value) {
            if (value) {
                this.m_allowedOrgLevels.push(OrgLevelType.organization);
            }
            else {
                this.m_allowedOrgLevels = _.without(this.m_allowedOrgLevels, OrgLevelType.organization);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "allowCorporationLevel", {
        set: function (value) {
            if (value) {
                this.m_allowedOrgLevels.push(OrgLevelType.corporation);
            }
            else {
                this.m_allowedOrgLevels = _.without(this.m_allowedOrgLevels, OrgLevelType.corporation);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "actionTypesList", {
        get: function () {
            return [
                ActionsType.add,
                ActionsType.delete,
                ActionsType.edit,
            ];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "canEdit", {
        // getters for direct bindings to model
        get: function () { return this.m_CanEdit; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "canAdd", {
        get: function () { return this.m_CanAdd; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "canDelete", {
        get: function () { return this.m_CanDelete; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "canDoDelete", {
        get: function () { return this.m_CanDoDelete; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "restrictedByOrgLevel", {
        get: function () { return this.m_restrictByOrgLevel; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "isDepartment", {
        get: function () {
            return this.m_isDepartment;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "isCorporation", {
        get: function () {
            return this.m_isCorporation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccessManagementService.prototype, "isOrganization", {
        get: function () {
            return this.m_isOrganization;
        },
        enumerable: true,
        configurable: true
    });
    AccessManagementService.prototype.subscribe = function (listener) {
        return this.subject.asObservable().subscribe(listener);
    };
    AccessManagementService.prototype.checkAction = function (action) {
        if (action === ActionsType.add)
            return this.m_CanAdd;
        if (action === ActionsType.edit)
            return this.m_CanEdit;
        if (action === ActionsType.delete)
            return this.m_CanDoDelete;
        return false;
    };
    AccessManagementService.prototype.checkFlag = function (flag) {
        if (!this.m_LockActions && _.includes(this.m_allowedOrgLevels, this.m_OrgLevelType)) {
            return (this.m_permissions & flag) > 0;
        }
        return false;
    };
    AccessManagementService.prototype.setPermissions = function (flags) {
        this.m_permissions = flags;
        this.updateState();
    };
    AccessManagementService.prototype.updateState = function () {
        if (!this.m_LockActions && _.includes(this.m_allowedOrgLevels, this.m_OrgLevelType)) {
            this.m_CanEdit = (this.m_permissions & AccessManagementService.USER_CAN_EDIT) > 0;
            this.m_CanAdd = (this.m_permissions & AccessManagementService.USER_CAN_ADD) > 0;
            this.m_CanDelete = ((this.m_permissions & AccessManagementService.USER_CAN_DELETE) > 0);
            this.m_CanDoDelete = ((this.m_permissions & AccessManagementService.USER_CAN_DELETE) > 0) && this.m_hasSelected;
            this.m_restrictByOrgLevel = false;
        }
        else if (!this.m_LockActions) {
            this.m_restrictByOrgLevel = true;
            this.m_CanEdit = false;
            this.m_CanAdd = false;
            this.m_CanDelete = false;
            this.m_CanDoDelete = false;
        }
        else {
            this.m_restrictByOrgLevel = false;
            this.m_CanEdit = false;
            this.m_CanAdd = false;
            this.m_CanDelete = false;
            this.m_CanDoDelete = false;
        }
        this.m_isCorporation = this.m_OrgLevelType === OrgLevelType.corporation;
        this.m_isOrganization = this.m_OrgLevelType === OrgLevelType.organization;
        this.m_isDepartment = this.m_OrgLevelType === OrgLevelType.department;
        this.notify();
    };
    AccessManagementService.prototype.notify = function () {
        this.subject.next();
    };
    AccessManagementService.USER_CAN_EDIT = 1;
    AccessManagementService.USER_CAN_ADD = 2;
    AccessManagementService.USER_CAN_DELETE = 4;
    return AccessManagementService;
}());
export { AccessManagementService };
