<div class="field-container table-record">
    <div class="col actions-column" *ngIf="editable">
        <div class="slx-form-group slx-no-border small-font">
            <div class="slx-input-group">
                <div class="slx-form-control">
                    <input [attr.id]="'selectRow'+rowIndex" type="checkbox" [checked]="item.isSelected" (click)="onItemSelect()">
                    <label class="checkbox-label" [attr.for]="'selectRow'+rowIndex">
                                    <span class="check"></span>
                                    <span class="box"></span>
                                </label>
                </div>
            </div>
        </div>
    </div>
    <div class="col edit-column">
        <button class="edit-button" (click)="onEditClick ()"></button>
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Description: </span> {{ item.description }}
    </div>
    <div class="col md-full-line-column text-column">
        <span class="field-label">Unit Code: </span> {{ item.name }}
    </div>

</div>