import { Component, Input, OnDestroy, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { EssTemplate } from '../../models/index';
import { EssTimeEntryMethod, EssDefaultPassword, IEssDefaultPasswordOption, IEssTimeEntryMethodOption } from '../../../../organization/models/index';
import { appConfig, IApplicationConfig } from '../../../../app.config';

import { Subscription } from 'rxjs/Subscription';
import { scheduleMicrotask } from '../../../../core/utils/index';
import { destroyService, unsubscribe } from '../../../../core/decorators/index';
import { EssTemplateManagementService } from '../../services/index';
import { ChangeManagementService } from '../../../../common/services/index';
import { PunchAttestationGroup } from '../../../punch-attestation/models';
import { AccessManagementService, PositionsManagementService } from '../../../../configuration/services';

@Component({
  moduleId: module.id,
  selector: 'slx-ess-template-section',
  templateUrl: 'ess-template-section.component.html',
  styleUrls: ['ess-template-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EssTemplateSectionComponent implements OnInit, OnChanges, OnDestroy {

  public isCollapsed: boolean;
  public defaultItem: { groupName: string, id: number } = { groupName: "Select Attestation Group", id: null };

  @Input()
  public template: EssTemplate;

  @Input()
  public punchAttestationGroups: PunchAttestationGroup[];

  public timeEntryMethods: IEssTimeEntryMethodOption[];
  public defaultPasswords: IEssDefaultPasswordOption[];

  public timeEntryMethod: IEssTimeEntryMethodOption;
  public defaultPwdTemplate: IEssDefaultPasswordOption;
  public dirtyTemplate: EssTemplate;
  public prohibitedNameValues: string[] = [];
  public selectedPunchAttestationGroup: PunchAttestationGroup;

  @unsubscribe()
  private loadedSubscription: Subscription;
  @unsubscribe()
  private selectSubscription: Subscription;
  @unsubscribe()
  private stateSubscription: Subscription;

  private serializedOriginTemplate: string;

  @ViewChild('templateName', { static: false })
  private templateName: ElementRef;

  public get hasChanges(): boolean {
    const dirty = JSON.stringify(this.dirtyTemplate);
    const res = dirty !== this.serializedOriginTemplate;
    return res;
  }

  public get isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  public get changeGroupName(): string {
    return this.template.essTemplateDefinition.id.toString();
  }

  constructor(private managementService: EssTemplateManagementService, private changeDetector: ChangeDetectorRef,
    private changeManagementService: ChangeManagementService
  ) {
    this.timeEntryMethods = EssTimeEntryMethod.getList(true);
    this.defaultPasswords = EssDefaultPassword.getList(false);
    this.selectSubscription = this.managementService.selectTemplate$.subscribe((template: EssTemplate) => {
      if (!this.template || this.template.essTemplateDefinition.id !== template.essTemplateDefinition.id) {
        return;
      }
      this.templateName.nativeElement.focus();
    });
    this.managementService.onLoaded$.subscribe((templates: EssTemplate[]) => {
      this.calcProhibited();
    });
  }

  ngOnInit() {
    this.managementService.addTemplateBtn$.subscribe((isCollapsed: boolean) => {
      this.isCollapsed = isCollapsed;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    });
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['template']) {
      this.dirtyTemplate = _.cloneDeep(this.template);
      this.serializedOriginTemplate = JSON.stringify(this.template);
      this.resetEnums();
      this.notifyChanges();         
      this.calcProhibited();
      this.setPunchAttestationGroupValue();
    }
  }

  setPunchAttestationGroupValue() {
    if (!!this.punchAttestationGroups && this.punchAttestationGroups.length > 0) {
      this.selectedPunchAttestationGroup = this.punchAttestationGroups.find(group => group.id == this.template.essTemplateDefinition.attestationGroupId);
      this.dirtyTemplate.essTemplateDefinition.attestationGroupId = !!this.selectedPunchAttestationGroup ? this.selectedPunchAttestationGroup.id : null;
    }
  }   

  public setTimeEntryMethod(): void {
    this.dirtyTemplate.timeEntryMethod = this.timeEntryMethod.id;
    this.notifyChanges();
  }

  public setDefaultPwdTemplate(): void {
    this.dirtyTemplate.defaultPwdTemplate = this.defaultPwdTemplate.id;
    this.notifyChanges();
  }

  public setPunchAttestationGroup(): void {
    this.dirtyTemplate.essTemplateDefinition.attestationGroupId = this.selectedPunchAttestationGroup.id;
    this.notifyChanges();
  }

  public notifyChanges(): void {
    if (this.hasChanges) {
      this.changeManagementService.changeNotify(this.changeGroupName);
    } else {
      this.changeManagementService.clearChanges(this.changeGroupName);
    }
  }

  public collapsedChange(isCollapsed: boolean): void {
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public discard(): void {
    this.dirtyTemplate = _.cloneDeep(this.template);
    this.resetEnums();
    this.notifyChanges();
    setTimeout(() => {
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }, 100);
  }

  public save(): void {
    this.managementService.saveTemplate(this.dirtyTemplate);
  }

  public delete(): void {
    this.managementService.deleteTemplate(this.template);
  }

  private resetEnums(): void {
    this.timeEntryMethod = _.find(this.timeEntryMethods, (opt: IEssTimeEntryMethodOption) => opt.id === this.dirtyTemplate.timeEntryMethod);
    if (!this.timeEntryMethod) {
      this.timeEntryMethod = _.first(this.timeEntryMethods);
    }
    this.defaultPwdTemplate = _.find(this.defaultPasswords, (opt: IEssDefaultPasswordOption) => opt.id === this.dirtyTemplate.defaultPwdTemplate);
    if (!this.defaultPwdTemplate) {
      this.defaultPwdTemplate = _.first(this.defaultPasswords);
    }

    this.setPunchAttestationGroupValue();
  }

  private calcProhibited(): void {
    if (!this.template) {
      return;
    }
    const otherTemplates = _.filter(this.managementService.templates, (t: EssTemplate) => t.essTemplateDefinition.id !== this.template.essTemplateDefinition.id);
    this.prohibitedNameValues = _.map(otherTemplates, (t: EssTemplate) => t.essTemplateDefinition.name);
  }

}
