import { Component, Provider } from '@angular/core';
import { DialogOptions, IDialog, ModalService } from '../../../../common/index';
import { IPunchAttestationQuestion } from '../../models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { AttestationType, LookupEntity } from '../../../../../app/organization';
import { IPunchProfileData, PunchProfileData } from '../../models/punch-profile-data';

@Component({
  selector: 'slx-punch-profile-attestation-questions',
  templateUrl: './punch-profile-attestation-questions.component.html',
  styleUrls: ['./punch-profile-attestation-questions.component.scss']
})
export class PunchProfileAttestationQuestionsComponent implements IDialog {
  public options: DialogOptions;
  public dialogResult: boolean;
  public questions: IPunchAttestationQuestion[];

  public gridData: GridDataResult;
  public sort: SortDescriptor[] = [];
  private modalService: ModalService;
  public attestationType: AttestationType[];
  public punchType: LookupEntity[];
  public punchProfileData: IPunchProfileData;
  public isLegacy : boolean = false;
  constructor(options: DialogOptions,
    modalService: ModalService,
    punchProfileData: PunchProfileData
  ) {
    this.modalService = modalService;
    this.options = options;
    this.questions = punchProfileData.question;
    this.punchType = punchProfileData.punchType;
    this.attestationType = punchProfileData.attestationType;
    this.isLegacy = punchProfileData.isLegacy;
    this.gridData = {
      data: orderBy(this.questions, this.sort),
      total: this.questions.length
    };
  }

  public static openDialog(punchProfileData: PunchProfileData, modalService: ModalService, callback: (result: boolean, cmd: any) => void): PunchProfileAttestationQuestionsComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 550;
    dialogOptions.width = 1100;
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.showCloseButton = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: PunchProfileData,
        useValue: punchProfileData
      }
    ];

    let dialog: PunchProfileAttestationQuestionsComponent = modalService.globalAnchor
      .openDialog(PunchProfileAttestationQuestionsComponent, 'Questions', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
        callback(result, undefined);
      });
    return dialog;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public getAttestationTypeName(id: number) {
    return this.attestationType.find(x => x.attestationTypeId == id).attestationTypeName || '';
  }

  public getPunchTypeName(id: number) {
    return this.punchType.find(x => x.id == id).name || '';
  }

}
