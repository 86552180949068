export class ACA1095cConfig {
  static line14ValueForLine17: string[] = [
    '1L', '1M', '1N', '1O', '1P', '1Q'
  ];

  static hiddenEmployeeTypesForAdd: string[] = [
    'AFT',
    'APT'
  ];
  static messageArray: {[key: string]: string} = {
    'quickconfirm__line14_blank': 'Line 14 cannot be blank.',
    'quickconfirm__record_already_confirmed': 'One or more records have already been confirmed. No changes will be made to these records.',
    'quickconfirm_reset__record_not_confirmed': 'You can only reset values that have been confirmed. One or more records that you are attempting to select have not been confirmed.',
    'quickedit__record_already_confirmed': 'Confirmed records cannot be edited. To edit the values, first perform a Confirm Reset for that month for that 1095-C record.',
    'quickedit_reset__record_already_confirmed': 'Confirmed records cannot be reset to suggested values until they are reset to be unconfirmed. To reset a confirmed month to suggested values, first perform a Confirm Reset for that 1095-C record for that month.',
    'quickedit_reset__not_edited': 'One or more records have not been edited and therefore cannot be reset. No changes will be made to these records.'

  }
  static isEditableYear(selectedYear: number): boolean {
    return selectedYear >= 2020;
  }
}
