<slx-application-item [application]="application" [progressbar]="progressbar" (onHasChanges)="onHasChanges($event)">
  <div class="app__links">
    <ng-container *ngFor="let appAlert of alertConfigOrderAndVisibilty">
        <a class="app__link" *ngIf="appAlert.alertName=='Missing Punch Count'" [ngClass]="{ 'hidden': !appAlert.visible }" class="app__link"
            [dailyPunchesLink]="orgLevelId" [startDate]="startDate"  [endDate]="endDate" [onlyMissing]="true">
            <span>
                <span class="app__link-name">{{appAlert.alertName}}</span>
                <i class="app__link-icon  {{appAlert.icon}}" aria-hidden="true"></i>
                <i class="app__link-amount" aria-hidden="true"
                  [ngClass]="{ 'empty': appAlert.counter === 0 }">{{ appAlert.counter}}</i>
              </span>
        </a>
        <a  *ngIf="appAlert.alertName!='Missing Punch Count'" [ngClass]="{ 'hidden': !appAlert.visible }" class="app__link"
        [routerLink]="getRouterLink(appAlert.link)"
        [queryParams]="appAlert.alertName=='Overtime Approvals' || appAlert.alertName=='Timecard Exceptions' ? datesParams:null">
          <span>
            <span class="app__link-name">{{appAlert.alertName}}</span>
            <i class="app__link-icon  {{appAlert.icon}}" aria-hidden="true"></i>
            <i class="app__link-amount" aria-hidden="true"
              [ngClass]="{ 'empty': appAlert.counter === 0 }">{{ appAlert.counter}}</i>
          </span>
      </a>

    </ng-container>
  </div>
</slx-application-item>
