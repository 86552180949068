import { Routes } from '@angular/router';
import { appCommonRoutes, employeeSectionRoutes, userProfileRoutes } from './portal.common.routes';

import {
  ApplicationContainerComponent
} from './components/index';

export const schedulerRoutes: Routes = [
  {
    path: '',
    component: ApplicationContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'so_console',
        pathMatch: 'full'
      },
      ...appCommonRoutes
    ]
  },
  ...employeeSectionRoutes,
  ...userProfileRoutes
];


