import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { EventEmitter, OnChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { appConfig } from '../../../app.config';
import { DatePickerNgxComponent } from '../../../common/components/index';
var ScheduleCycleDatePickerComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ScheduleCycleDatePickerComponent, _super);
    function ScheduleCycleDatePickerComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ScheduleCycleDatePickerComponent.prototype, "cycles", {
        set: function (value) {
            var _this = this;
            this.m_cycles = value;
            this.m_cycles_start = {};
            this.m_cycles_end = {};
            _.forEach(value, function (c) {
                _this.m_cycles_start[moment(c.startDate).startOf('day').format(appConfig.dateFormat)] = c;
                _this.m_cycles_end[moment(c.endDate).startOf('day').format(appConfig.dateFormat)] = c;
            });
        },
        enumerable: true,
        configurable: true
    });
    ScheduleCycleDatePickerComponent.prototype.isCurrent = function (date) {
        return moment(date).isSame(moment().startOf('day'));
    };
    ScheduleCycleDatePickerComponent.prototype.isSelected = function (date) {
        return moment(date).isSame(this.currentValue);
    };
    ScheduleCycleDatePickerComponent.prototype.isStartOfPayCycle = function (date) {
        return !!this.m_cycles_start[moment(date).startOf('day').format(appConfig.dateFormat)];
    };
    ScheduleCycleDatePickerComponent.prototype.isEndOfPayCycle = function (date) {
        return !!this.m_cycles_end[moment(date).startOf('day').format(appConfig.dateFormat)];
    };
    return ScheduleCycleDatePickerComponent;
}(DatePickerNgxComponent));
export { ScheduleCycleDatePickerComponent };
