import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';

import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { SlxHttpRequest } from '../../../core/models/index';

@Injectable()
export class UrlParamsService {
  public convertToParams(obj: StringMap<any>, paramsContainsArrayLikeData: boolean = false): HttpParams {
    let params: HttpParams = new HttpParams();

    _.forIn(obj, (value: any, key: string) => {
      if (value !== null && value !== undefined) {
        if (value instanceof Date) {
          params = params.append(key, moment(value).format(appConfig.requestDate));
        } else if (moment.isMoment(value)) {
          params = params.append(key, (<moment.Moment>value).format(appConfig.requestDate));
        } else if (paramsContainsArrayLikeData && _.isArray(value)) {
          params = _.reduce(value, (accum, v) => accum.append(key, v), params);
        } else {
          params = params.append(key, value.toString());
        }
      }
    });

    return params;
  }

  public createGetRequest(url: string, params?: StringMap<any>, paramsContainsArrayLikeData?: boolean): HttpRequest<any> {
    return new HttpRequest('GET', url, {
      params: params ? this.convertToParams(params, !!paramsContainsArrayLikeData) : null
    });
  }

  public createDeleteRequest(url: string, params?: StringMap<any>, body?: any, paramsContainsArrayLikeData?: boolean): HttpRequest<any> {
    return new HttpRequest('DELETE', url, body ? body : null, {
      params: params ? this.convertToParams(params, !!paramsContainsArrayLikeData) : null
    });
  }

  public createPutRequest(url: string, body?: any, params?: StringMap<any>, paramsContainsArrayLikeData?: boolean): HttpRequest<any> {
    return new HttpRequest('PUT', url, body ? body : null, {
      params: params ? this.convertToParams(params, !!paramsContainsArrayLikeData) : null
    });
  }

  public createPostRequest(url: string, body?: any, params?: StringMap<any>, paramsContainsArrayLikeData?: boolean): HttpRequest<any> {
    return new HttpRequest('POST', url, body ? body : null, {
      params: params ? this.convertToParams(params, !!paramsContainsArrayLikeData) : null
    });
  }

  public requestGet(url: string, params?: StringMap<any>): SlxHttpRequest<any> {
    const request: HttpRequest<any> = this.createGetRequest(url, params);
    const slxHttpRequest: SlxHttpRequest<any> = new SlxHttpRequest(request);

    return slxHttpRequest;
  }

  public requestDelete(url: string, params?: StringMap<any>, body?: any): SlxHttpRequest<any> {
    const request: HttpRequest<any> = this.createDeleteRequest(url, params, body);
    const slxHttpRequest: SlxHttpRequest<any> = new SlxHttpRequest(request);

    return slxHttpRequest;
  }

  public requestPut(url: string, body?: any, params?: StringMap<any>): SlxHttpRequest<any> {
    const request: HttpRequest<any> = this.createPutRequest(url, body, params);
    const slxHttpRequest: SlxHttpRequest<any> = new SlxHttpRequest(request);

    return slxHttpRequest;
  }

  public requestPost(url: string, body?: any, params?: StringMap<any>): SlxHttpRequest<any> {
    const request: HttpRequest<any> = this.createPostRequest(url, body, params);
    const slxHttpRequest: SlxHttpRequest<any> = new SlxHttpRequest(request);

    return slxHttpRequest;
  }
}
