<kendo-chart class="height-100" *ngIf="models && models.length">
  <kendo-chart-area [height]="60 * (models.length || 1) - 10"></kendo-chart-area>

  <kendo-chart-axis-defaults
    color="#ffffff"
    [line]="{ width: 0 }"
    [majorGridLines]="{ visible: false }"
    [minorGridLines]="{ visible: false }"
    [majorTicks]="{ size: 0 }"
    [minorTicks]="{ size: 0 }"
    [labels]="{ visible: false }"
  ></kendo-chart-axis-defaults>

  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item
      [min]="0"
      [max]="1"
    ></kendo-chart-value-axis-item>
  </kendo-chart-value-axis>

  <kendo-chart-series>
    <kendo-chart-series-item
      *ngFor="let model of models"
      type="bar"
      color="#666666"
      [border]="{ width: 0 }"
      [highlight]="{ visible: false }"
      [data]="[getLegendData(model)]"
    >
      <kendo-chart-series-item-labels [content]="getLegend" background="#666666" color="#ffffff" position="insideEnd"></kendo-chart-series-item-labels>
    </kendo-chart-series-item>
  </kendo-chart-series>
</kendo-chart>

<kendo-chart class="height-100 flex-grow" *ngIf="models && models.length">
  <kendo-chart-area [height]="60 * (models.length || 1) + 10"></kendo-chart-area>

  <kendo-chart-value-axis>
    <kendo-chart-value-axis-item
      [min]="0"
    ></kendo-chart-value-axis-item>
  </kendo-chart-value-axis>

  <kendo-chart-category-axis>
    <kendo-chart-category-axis-item
      [line]="{ visible: false }"
      [min]="0"
      labels="false"
    ></kendo-chart-category-axis-item>
  </kendo-chart-category-axis>

  <kendo-chart-series>
    <ng-container *ngFor="let model of models">
      <kendo-chart-series-item
        type="bar"
        field="value"
        colorField="color"
        [color]="model.models[0]?.color?.fontColor1"
        [border]="{ width: 0 }"
        [stack]="{ group: model.label }"
        [highlight]="{ visible: false }"
        [data]="[getStackedLeftData(model)]"
      ></kendo-chart-series-item>

      <kendo-chart-series-item
        type="bar"
        field="value"
        colorField="color"
        [border]="{ width: 0 }"
        [stack]="{ group: model.label }"
        [highlight]="{ visible: false }"
        [data]="[getStackedRightData(model)]"
      >
        <kendo-chart-series-item-labels [font]="getLabelFont(model)" [color]="model.models[0]?.color?.fontColor1" [content]="getLabel"></kendo-chart-series-item-labels>
      </kendo-chart-series-item>
    </ng-container>
  </kendo-chart-series>
</kendo-chart>
