import * as moment from "moment";
import { DetailGroup, GroupDetailsData, ShiftEligibleEmployee, ShiftReplacementSettings } from "../../models";
import * as _ from "lodash";

export class ShiftOverlapHelper {

    public settings: ShiftReplacementSettings;
    public selectedEmployees: ShiftEligibleEmployee[];
    public groupData: DetailGroup[];
    public previousDayGroupData: DetailGroup[];
    public nextDayGroupData: DetailGroup[];
    private overlapState: any = {
        isCurrentDayShiftOverlapped: [],
        isCurrentDayActualShiftOverlapped: [],
        isPreviousDayShiftOverlapped: [],
        isPreviousDayActualShiftOverlapped: [],
        isNextDayShiftOverlapped: [],
        isNextDayActualShiftOverlapped: [],
        isCurrentDayReplacedShiftOverlapped: [],
        isCurrentDayReplacedActualShiftOverlapped: [],
        isPreviousDayReplacedShiftOverlapped: [],
        isPreviousDayReplacedActualShiftOverlapped: [],
        isNextDayShiftReplacedOverlapped: [],
        isNextDayReplacedActualShiftOverlapped: []
    }

    constructor() { }

    public isCurrentDayShiftOverlapped(): boolean {
        this.overlapState.isCurrentDayShiftOverlapped = [];
        this.overlapState.isCurrentDayActualShiftOverlapped = [];
        this.overlapState.isCurrentDayShiftOverlapped = this.isCurrentDayOverlapped(this.groupData, false);
        this.overlapState.isCurrentDayActualShiftOverlapped = this.isCurrentDayOverlapped(this.groupData, true);
        return (this.overlapState.isCurrentDayShiftOverlapped.some(x => x == true)
            || this.overlapState.isCurrentDayActualShiftOverlapped.some(x => x == true));
    }

    public isPreviousDayShiftOverlapped(): boolean {
        this.overlapState.isPreviousDayShiftOverlapped = [];
        this.overlapState.isPreviousDayActualShiftOverlapped = [];
        this.overlapState.isPreviousDayShiftOverlapped = this.isPreviousDayOverlapped(this.previousDayGroupData, false);
        this.overlapState.isPreviousDayActualShiftOverlapped = this.isPreviousDayOverlapped(this.previousDayGroupData, true);
        return (this.overlapState.isPreviousDayShiftOverlapped.some(x => x == true)
            || this.overlapState.isPreviousDayActualShiftOverlapped.some(x => x == true));
    }

    public isNextDayShiftOverlapped(): boolean {
        this.overlapState.isNextDayShiftOverlapped = [];
        this.overlapState.isNextDayActualShiftOverlapped = [];
        this.overlapState.isNextDayShiftOverlapped = this.isNextDayOverlapped(this.nextDayGroupData, false);
        this.overlapState.isNextDayActualShiftOverlapped = this.isNextDayOverlapped(this.nextDayGroupData, true);
        return (this.overlapState.isNextDayShiftOverlapped.some(x => x == true)
            || this.overlapState.isNextDayActualShiftOverlapped.some(x => x == true));
    }

    public isCurrentDayReplacedShiftOverlapped(): boolean {
        this.overlapState.isCurrentDayReplacedShiftOverlapped = [];
        this.overlapState.isCurrentDayReplacedActualShiftOverlapped = [];
        this.overlapState.isCurrentDayReplacedShiftOverlapped = this.isCurrentDayReplacedOverlapped(this.groupData, false, true);
        this.overlapState.isCurrentDayReplacedActualShiftOverlapped = this.isCurrentDayReplacedOverlapped(this.groupData, true, true);
        return (this.overlapState.isCurrentDayReplacedShiftOverlapped.some(x => x == true)
            || this.overlapState.isCurrentDayReplacedActualShiftOverlapped.some(x => x == true));
    }

    public isPreviousDayReplacedShiftOverlapped(): boolean {
        this.overlapState.isPreviousDayReplacedShiftOverlapped = [];
        this.overlapState.isPreviousDayReplacedActualShiftOverlapped = [];
        this.overlapState.isPreviousDayReplacedShiftOverlapped = this.isPreviousDayReplacedOverlapped(this.previousDayGroupData, false, true);
        this.overlapState.isPreviousDayReplacedActualShiftOverlapped = this.isPreviousDayReplacedOverlapped(this.previousDayGroupData, true, true);
        return (this.overlapState.isPreviousDayReplacedShiftOverlapped.some(x => x == true)
            || this.overlapState.isPreviousDayReplacedActualShiftOverlapped.some(x => x == true));
    }

    public isNextDayReplacedShiftOverlapped(): boolean {
        this.overlapState.isNextDayReplacedShiftOverlapped = [];
        this.overlapState.isNextDayReplacedActualShiftOverlapped = [];
        this.overlapState.isNextDayReplacedShiftOverlapped = this.isNextDayReplacedOverlapped(this.nextDayGroupData, false, true);
        this.overlapState.isNextDayReplacedActualShiftOverlapped = this.isNextDayReplacedOverlapped(this.nextDayGroupData, true, true);
        return (this.overlapState.isNextDayReplacedShiftOverlapped.some(x => x == true)
            || this.overlapState.isNextDayReplacedActualShiftOverlapped.some(x => x == true));
    }

    private isTimeOverlapped(currentStart: moment.Moment, currentEnd: moment.Moment, start: moment.Moment, end: moment.Moment): boolean {
        return currentStart.isBefore(end) && currentEnd.isAfter(start);
    }

    private isDateTimeOverlapped(date1: moment.Moment, date2: moment.Moment, date3: moment.Moment, date4: moment.Moment) {
        const overlap1_2 = this.isTimeOverlapped(date1, date2, date3, date4);
        const overlap1_3 = this.isTimeOverlapped(date1, date2, date2, date3);
        const overlap3_4 = this.isTimeOverlapped(date2, date3, date3, date4);
        return [overlap1_2, overlap1_3, overlap3_4];
    }

    private isCurrentDayOverlapped(currentDayData: DetailGroup[], isActualShift: boolean) {
        let isCurrentDayOverlappedData = [];
        let rowsData = [];
        if (currentDayData && currentDayData.length > 0) currentDayData.forEach(x => rowsData.push(...x.rows));
        isCurrentDayOverlappedData = this.checkEmpData(rowsData, isActualShift, isCurrentDayOverlappedData, false);
        return isCurrentDayOverlappedData;
    }

    private isPreviousDayOverlapped(previousDayGroupData: DetailGroup[], isActualShift: boolean) {
        let isPreviousDayOverlappedData = [];
        let rowsData = [];
        if (previousDayGroupData && previousDayGroupData.length > 0) previousDayGroupData.forEach(x => rowsData.push(...x.rows));
        isPreviousDayOverlappedData = this.checkEmpData(rowsData, isActualShift, isPreviousDayOverlappedData, false);
        return isPreviousDayOverlappedData;
    }

    private isNextDayOverlapped(nextDayGroupData: DetailGroup[], isActualShift: boolean) {
        let isNextDayOverlappedData = [];
        let rowsData = [];
        if (nextDayGroupData && nextDayGroupData.length > 0) nextDayGroupData.forEach(x => rowsData.push(...x.rows));
        isNextDayOverlappedData = this.checkEmpData(rowsData, isActualShift, isNextDayOverlappedData, false);
        return isNextDayOverlappedData;
    }

    private isCurrentDayReplacedOverlapped(currentDayData: DetailGroup[], isActualShift: boolean, isCheckReplace: boolean) {
        let isCurrentDayReplacedOverlappedData = [];
        let rowsData = [];
        if (currentDayData && currentDayData.length > 0) currentDayData.forEach(x => rowsData.push(...x.rows));
        isCurrentDayReplacedOverlappedData = this.checkEmpData(rowsData, isActualShift, isCurrentDayReplacedOverlappedData, isCheckReplace);
        return isCurrentDayReplacedOverlappedData;
    }

    private isPreviousDayReplacedOverlapped(previousDayGroupData: DetailGroup[], isActualShift: boolean, isCheckReplace: boolean) {
        let isPreviousDayReplacedOverlappedData = [];
        let rowsData = [];
        if (previousDayGroupData && previousDayGroupData.length > 0) previousDayGroupData.forEach(x => rowsData.push(...x.rows));
        isPreviousDayReplacedOverlappedData = this.checkEmpData(rowsData, isActualShift, isPreviousDayReplacedOverlappedData, isCheckReplace);
        return isPreviousDayReplacedOverlappedData;
    }

    private isNextDayReplacedOverlapped(nextDayGroupData: DetailGroup[], isActualShift: boolean, isCheckReplace: boolean) {
        let isNextDayReplacedOverlappedData = [];
        let rowsData = [];
        if (nextDayGroupData && nextDayGroupData.length > 0) nextDayGroupData.forEach(x => rowsData.push(...x.rows));
        isNextDayReplacedOverlappedData = this.checkEmpData(rowsData, isActualShift, isNextDayReplacedOverlappedData, isCheckReplace);
        return isNextDayReplacedOverlappedData;
    }

    private checkEmpData(rowsData: any, isActualShift: boolean, isOverlapped: any, isCheckReplace: boolean): any {
        if (rowsData.length > 0) {
            const empData = rowsData.filter(x => x.fields.some(y => y.name === 'EmpId' && y.value == this.selectedEmployees[0].employee.id.toString()));
            if (isCheckReplace) {
                isOverlapped = this.checkReplacedShiftOverlap(empData, isActualShift, isOverlapped);
            }
            else {
                isOverlapped = this.checkShiftOverlap(empData, isActualShift, isOverlapped);
            }
        }
        return isOverlapped;
    }

    private checkShiftOverlap(empData: any, isActualShift: boolean, isOverlapped: any): any {
        if (empData.length > 0) {
            empData.forEach(shiftData => {
                if (!isActualShift) {
                    const startDate = moment(shiftData.fields.find(x => x.name == 'ShiftStart').value);
                    const endDate = moment(shiftData.fields.find(x => x.name == 'ShiftEnd').value);
                    isOverlapped = this.isDateTimeOverlapped(this.settings.request.momentShiftStart, this.settings.request.momentShiftEnd, startDate, endDate);
                }
                else {
                    const startDate = moment(shiftData.fields.find(x => x.name == 'ActualShiftStart').value);
                    const endDate = moment(shiftData.fields.find(x => x.name == 'ActualShiftEnd').value);
                    isOverlapped = this.isDateTimeOverlapped(this.settings.request.momentShiftStart, this.settings.request.momentShiftEnd, startDate, endDate);
                }

            });
        }
        return isOverlapped;
    }

    private checkReplacedShiftOverlap(empData: any, isActualShift: boolean, isOverlapped: any): any {
        if (empData.length > 0) {
            empData.forEach(shiftData => {
                if (!isActualShift) {
                    const startDate = moment(shiftData.fields.find(x => x.name == 'ShiftStart').value);
                    const endDate = moment(shiftData.fields.find(x => x.name == 'ShiftEnd').value);
                    isOverlapped = this.isDateTimeOverlapped(this.settings.request.momentActualShiftStart, this.settings.request.momentActualShiftEnd, startDate, endDate);
                }
                else {
                    const startDate = moment(shiftData.fields.find(x => x.name == 'ActualShiftStart').value);
                    const endDate = moment(shiftData.fields.find(x => x.name == 'ActualShiftEnd').value);
                    isOverlapped = this.isDateTimeOverlapped(this.settings.request.momentActualShiftStart, this.settings.request.momentActualShiftEnd, startDate, endDate);
                }

            });
        }
        return isOverlapped;
    }

    public isPrimarySecondaryShiftsOverlap(): boolean {
        let isOverlapped: any[] = [];
        _.forEach(this.selectedEmployees, (emp) => {
            _.forEach(emp.scheduledShifts, (shifts) => {
                isOverlapped.push(...this.isDateTimeOverlapped(moment(shifts.shiftStartTime), moment(shifts.shiftEndTime), this.settings.request.momentShiftStart, this.settings.request.momentShiftEnd));
            });
        });
        return isOverlapped.some(x => x == true);
    }
}