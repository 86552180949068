import { Directive, HostListener, Input, Provider } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BreadcrumbStateService } from '../../../core/services/index';

@Directive({
  selector: '[slxForwardLink]',
})
export class ForwardLinkDirective {
  constructor(private location: Location, private router: Router, private breadcrumbStateService: BreadcrumbStateService) {
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    this.location.forward();
  }
}
