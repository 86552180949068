import * as tslib_1 from "tslib";
import { ModalService } from './../../../common/services/modal/modal.service';
import { BenAdminMenuManagementService } from './ben-admin/ben-admin-menu-management.service';
import { ApplicationStateBusService } from './../application-state-bus/application-state-bus.service';
import { Subscription } from 'rxjs/Subscription';
import { RestoreQueryParamsService } from './../../../common/services/navigation/restore-query-params.service';
import { ChangeManagementService } from './../../../common/services/change-management/change-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StateNavigationService } from './../../../common/services/navigation/state-navigation.service';
import { GetHiredMenuManagementService } from './get-hired/get-hired-menu-managment.service';
import { SsoNavigationService } from './sso-navigation.service';
import { InfoDialogComponent } from './../../../common/components/info-dialog/info-dialog.component';
import { appConfig } from './../../../app.config';
import { unsubscribeInService } from './../../../core/decorators';
import { scheduleMicrotask } from './../../../core/utils';
var NavigationMenuRedirectService = /** @class */ (function () {
    function NavigationMenuRedirectService(router, route, ssoService, getHiredService, changeManagement, restoreQueryParamsService, appStateBusService, benAdminService, modalService) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.ssoService = ssoService;
        this.getHiredService = getHiredService;
        this.changeManagement = changeManagement;
        this.restoreQueryParamsService = restoreQueryParamsService;
        this.appStateBusService = appStateBusService;
        this.benAdminService = benAdminService;
        this.modalService = modalService;
        this.navService = new StateNavigationService(router, route, changeManagement, restoreQueryParamsService);
        this.orgLevelSubscription = this.appStateBusService.subscribeToSelectOrgLevel(function (orgLevel) {
            scheduleMicrotask(function () {
                _this.orgLevel = orgLevel;
            });
        });
    }
    NavigationMenuRedirectService.prototype.open = function (item) {
        if (item.menuItemMapping.isV5App) {
            this.navigateSSO(item.link);
        }
        else {
            if (!this.openAlterPath(item.link)) {
                this.openByLink(item);
            }
        }
    };
    NavigationMenuRedirectService.prototype.openByLink = function (item) {
        this.navService.navigateToMenu(item.appLink, item.link);
    };
    NavigationMenuRedirectService.prototype.navigateSSO = function (menuItem) {
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + appConfig.api.sso + "/" + menuItem + "?orglevel=" + this.orgLevel.id;
        this.ssoService.navigationToV5(url);
    };
    NavigationMenuRedirectService.prototype.openAlterPath = function (link) {
        if (this.benAdminService.isBenAdminItem(link)) {
            var menuItemState = this.benAdminService.getBenAdminMenuItemState();
            if (!menuItemState.isMenuAccessible) {
                InfoDialogComponent.OpenDialog('Warning', menuItemState.userMessage, this.modalService);
            }
            else {
                this.benAdminService.openBswift();
            }
            return true;
        }
        if (link === 'applicant_tracker') {
            this.getHiredService.openGetHired(this.orgLevel.id);
            return true;
        }
        return false;
    };
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], NavigationMenuRedirectService.prototype, "orgLevelSubscription", void 0);
    return NavigationMenuRedirectService;
}());
export { NavigationMenuRedirectService };
