import * as tslib_1 from "tslib";
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common/services/index';
import { data_Check_orgsync } from '../../../models/wfm-sync';
import { WfmSyncService } from '../../../services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';
var WorkLocationSyncComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WorkLocationSyncComponent, _super);
    function WorkLocationSyncComponent(wfmSyncService, notificationService, busyService) {
        var _this = _super.call(this, notificationService, busyService) || this;
        _this.wfmSyncService = wfmSyncService;
        _this.organization = true;
        _this.department = false;
        _this.position = false;
        return _this;
    }
    WorkLocationSyncComponent.prototype.isCheckboxDisabled = function (item) {
        return this.isSyncItemDisabled(item);
    };
    WorkLocationSyncComponent.prototype.syncDataCore = function (selectedItems) {
        return this.busyService.busy(this.wfmSyncService.SyncOrganizationLevelsChangeLogs(selectedItems, 'ongoing'));
    };
    WorkLocationSyncComponent.prototype.getSyncItemKey = function (item) {
        if (item.orgLevelType.toUpperCase() != 'POSITION') {
            return item.orgLevelId + "_" + item.orgLevelType;
        }
        return '' + item.jobCode;
    };
    WorkLocationSyncComponent.prototype.fetchData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.busyService.busy(this.wfmSyncService.GetOrganizationLevelsChangeLogs('ongoing', 'orgstructure'))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, this.processData(response.data)];
                }
            });
        });
    };
    WorkLocationSyncComponent.prototype.isSyncItemDisabled = function (item) {
        var orgLevelType = item.orgLevelType.toUpperCase();
        var isOrg = orgLevelType === 'ORGANIZATION';
        var isDep = orgLevelType === 'DEPARTMENT';
        var isPos = orgLevelType === 'POSITION';
        return (this.organization && !isOrg) || // Organization checked, but item is not an organization
            (this.department && !isDep) || // Department checked, but item is not a department
            (this.position && !isPos) || // Position checked, but item is not a positon
            // or if data is not valid
            (item.companyId == null || item.companyId == 0 || item.shortCode == null || item.shortCode == 0 || item.payrollCode === null || !(item.payrollCode >= 1 && item.payrollCode <= 99) || (item.paygroup === "" || item.paygroup === null || item.paygroup === undefined));
    };
    WorkLocationSyncComponent.prototype.processData = function (data) {
        return data_Check_orgsync(data);
    };
    return WorkLocationSyncComponent;
}(BaseSyncComponent));
export { WorkLocationSyncComponent };
