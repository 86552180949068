<div class="payroll-export-config">
    <div class="top-area pe-row">      
        <div class="info-area pe-column left-item">
            <div class="bold-text">
                <p>Global settings allow you to configure your payroll export process for all of your facilities. Choose the payroll export settings for each test below:</p>
            </div>
            <div class="medium-text pe-row">
                <p>
                    <i class="pe-red fas fa-exclamation-triangle icon" aria-hidden="true"></i>
                    <span class="bold-text">Prevent Export</span> - If the test is not successful this setting will <strong class="pe-red">prevent</strong> the user from exporting a payroll file
                </p>
            </div>
            <div class="medium-text pe-row">
                <p>
                    <i class="pe-orange fas fa-exclamation-circle icon" aria-hidden="true"></i>
                    <span class="bold-text">Alert User</span> - If the test is not successful this setting will <strong class="pe-orange">alert</strong> the user to the error but will <strong>allow</strong> the payroll export to proceed
                </p>
            </div>
            <div class="medium-text pe-row">
                <p><span class="bold-text">None</span> - This test will <strong>not be executed</strong>
            </div>
        </div>
        <div class="global-check-container pe-column right-item"
            *ngIf="container?.isGlobal">
            <span class="bold-text">Global Settings:</span>
            <div class="pe-row">
                <div class="global-checkbox" (click)="manageService.changeConfigIsGlobal()">
                    <div class="on" *ngIf="manageService.globalExportSettingsEnabled"></div>
                    <div class="off" *ngIf="!manageService.globalExportSettingsEnabled"></div>
                </div>
                <div class="label bold-text pe-green" *ngIf="manageService.globalExportSettingsEnabled">ON</div>
                <div class="label bold-text pe-red" *ngIf="!manageService.globalExportSettingsEnabled">OFF</div>
            </div>
        </div>
    </div>

    <div class="settings-area pe-column">
        <div class="setting-header pe-row bold-text">
            <div class="left-item">Test Name</div>
            <div class="right-item">Settings</div>
        </div>
        <div class="medium-text setting-item pe-row" *ngFor="let setting of container?.configs">
            <div class="left-item">{{ setting.testName }}</div>
            <div class="right-item">
                <slx-dropdown-list class="medium-text"
                    [icon]="getCode(setting.code).icon"
                    [readonly]="isLoading"
                    placeholder=""
                    [options]="codes"
                    [valueField]="'id'"
                    [titleField]="'name'"
                    [valuePrimitive]="false"
                    [ngModel]="getCode(setting.code)"
                    (ngModelChange)="setCode(setting, $event)"
                 ></slx-dropdown-list>
            </div>
        </div>
        <div class="medium-text setting-footer pe-row">
            <div class="left-item">Automatically Lock Timecards after Export</div>
            <div class="right-item pe-row">
                <slx-radio-button name="radioYes" fieldName="lockTimecardsAfterExport" [option]="true" caption="Yes"
                    [ngModel]="container?.lockTimecardsAfterExport" (ngModelChange)="setLock($event)"
                    [readonly]="true">
                  </slx-radio-button>
                  <slx-radio-button name="radioNo" fieldName="lockTimecardsAfterExport" [option]="false" caption="No"
                    [ngModel]="container?.lockTimecardsAfterExport" (ngModelChange)="setLock($event)"
                    [readonly]="true">
                </slx-radio-button>
            </div>
        </div>
    </div>
    
</div>