import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process } from '@progress/kendo-data-query';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { appConfig } from '../../../../../../../app/app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { BenefitListManagementService, BenefitConsoleCommonService } from './../../../../services/benefit-console/index';
var BenefitTypeSectionGridComponent = /** @class */ (function () {
    function BenefitTypeSectionGridComponent(activatedRoute, router, manService, commonService) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.manService = manService;
        this.commonService = commonService;
        this.isShowingExpired = false;
        this.columnGroup = 'BenefitsRecentActivity';
        this.data = [];
        this.isPageable = false;
        this.pageSize = 25;
        this.subscriptions = {};
        this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
        this.appConfig = appConfig;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [
            { field: 'startDate', dir: 'desc' }
        ];
        this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.gridState.assignRestoredState = true;
        this.gridState.refreshGridAfterRestoreState = true;
    }
    BenefitTypeSectionGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoadingBenefitType = true;
        this.gridState.gridComponentKey = "BenefitsGroupsGrid_" + this.groupId;
        this.gridState.gridControlStateKey = 'GridFiltersState';
        this.subscriptions.orgLevel = this.manService
            .subscribeToOrgLevel(function (v) {
            _this.orgLevel = v;
        });
        this.subscriptions.showExpired = this.manService
            .subscribeToIsLoadingExpired(function (r) {
            _this.isShowingExpiredPlans = r;
        });
        if (this.groupId && this.collapsedState && (this.index === 0)) {
            this.manService.getBenefitPlanTypes(this.groupId, this.isShowingExpiredPlans);
            this.commonService.recordExpandedGroups(this.groupId);
        }
        else {
            this.isLoadingBenefitType = false;
        }
        this.subscriptions.onLoad = this.manService
            .subscribeToPlanTypeLoading(function (data) {
            if (data.groupId === _this.groupId) {
                _this.isLoadingBenefitType = data.isLoading;
            }
        });
        this.subscriptions.onLoaded = this.manService
            .subscribeToPlanTypeRecords(function (r) {
            if (r && r.length > 0) {
                if (r[0].benefitGroupId === Number(_this.groupId)) {
                    _this.data = r;
                    _this.isPageable = _.size(_this.data) > _this.pageSize;
                    _this.refreshGrid();
                }
            }
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid
            .subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    BenefitTypeSectionGridComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    BenefitTypeSectionGridComponent.prototype.onClickBenefit = function (providerLine) {
        var data = this.providerLinesToGroups.get(providerLine.id);
        this.navService.navigateToDetails(this.orgLevel.id, data.gId, data.pId, providerLine.id);
    };
    BenefitTypeSectionGridComponent.prototype.refreshGrid = function () {
        this.isLoadingBenefitType = false;
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.makeListOfBenefitsLines(), this.gridState.state);
    };
    BenefitTypeSectionGridComponent.prototype.makeListOfBenefitsLines = function () {
        var _this = this;
        var gridData = [];
        this.providerLinesToGroups = new Map();
        _.forEach(this.data, function (provider) {
            _.forEach(provider.benefitLines, function (providerLine) {
                _this.providerLinesToGroups.set(providerLine.id, { gId: provider.benefitGroupId, pId: provider.id });
                providerLine['orgLevelName'] = provider.orgLevelName;
                gridData.push(providerLine);
            });
        });
        return gridData;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitTypeSectionGridComponent.prototype, "subscriptions", void 0);
    return BenefitTypeSectionGridComponent;
}());
export { BenefitTypeSectionGridComponent };
