import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { Unit, IUnit } from '../../models/index';
import { ResponseBody } from '../../../core/models/index';
import { Meta } from '../../../core/models/api/meta';
import { dateTimeUtils } from '../../../common/utils/dateTimeUtils';

@Injectable()
export class UnitsMapService {

  public updateUnitFromData(unit: Unit, data: IUnit): Unit {
    unit.id = data.id;
    unit.description = data.description;
    unit.name = data.name;
    unit.employeesCount = data.employeesCount;
    if (_.has(data, 'lastUpdateDate')) unit.lastUpdateDate = dateTimeUtils.convertFromDtoString(data.lastUpdateDate);
    if (_.has(data, 'lastUpdateUsername')) unit.lastUpdateUsername = data.lastUpdateUsername;
    return unit;
  }

  public convertDataToUnit(data: IUnit): Unit {
    let unit: Unit = new Unit();
    this.updateUnitFromData(unit, data);
    return unit;
  }

  public convertUnitToData(unit: Unit): IUnit {
    let data: IUnit = {
      id: unit.id,
      description: unit.description,
      name: unit.name,
      employeesCount: unit.employeesCount,
      lastUpdateDate: dateTimeUtils.convertToDtoString(unit.lastUpdateDate),
      lastUpdateUsername: unit.lastUpdateUsername
    };
    return data;
  }

  public mapUnits(response: ResponseBody<IUnit[], Meta>): Unit[] {
    let units: Unit[] = [];
    _.each(response.data, (item: IUnit) => {
      let unit = this.convertDataToUnit(item);
      units.push(unit);
    });
    return units;
  }

}

