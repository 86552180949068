export * from  './manage-ale-groups/manage-ale-groups.component';
export * from  './manage-ale-groups-dialog/manage-ale-groups-dialog.component';

import { ManageAleGroupsDialogComponent } from './manage-ale-groups-dialog/manage-ale-groups-dialog.component';
import { ManageAleGroupsComponent } from './manage-ale-groups/manage-ale-groups.component';

export const manageALEGroupsComponents: any[] = [
    ManageAleGroupsDialogComponent,
    ManageAleGroupsComponent
];
