import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit } from '@angular/core';
import { LMCreationAbsenceManagementService } from '../../../services/index';
import { RecurrenceRuleOption, RecurrenceFrequencies, LMCommonRecurrence } from '../../../models/index';
var LMWeeklyRecurrenceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(LMWeeklyRecurrenceComponent, _super);
    function LMWeeklyRecurrenceComponent(manService) {
        var _this = _super.call(this) || this;
        _this.manService = manService;
        _this.weekLength = 7;
        return _this;
    }
    Object.defineProperty(LMWeeklyRecurrenceComponent.prototype, "canEditRequest", {
        get: function () {
            return this.manService.canChange;
        },
        enumerable: true,
        configurable: true
    });
    LMWeeklyRecurrenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        var estimateEndDate = this.defineStartDate();
        var recurrence = this.manService.getRecurrence();
        this.defineDefaults(recurrence, estimateEndDate);
        this.defineMode(recurrence.count, recurrence.until);
        this.updatesRestrictionDates();
        this.subscriptions.loaRequest = this.manService
            .subscribeToChangedRequest(function () {
            _this.defineStartDate();
            _this.onChangeRecurrence();
        });
    };
    LMWeeklyRecurrenceComponent.prototype.onClickDay = function (day) {
        var hasChangedActiveDay = this.updateActiveWeekDay(day);
        if (hasChangedActiveDay) {
            this.updateWeeklyRecurrence();
        }
    };
    LMWeeklyRecurrenceComponent.prototype.onChangeRecurrence = function () {
        this.updatesRestrictionDates();
        this.updateWeeklyRecurrence();
    };
    LMWeeklyRecurrenceComponent.prototype.updateWeeklyRecurrence = function () {
        var options = new RecurrenceRuleOption(this.interval, null, null);
        this.updatedRepeatMode(options);
        this.updateEndMode(options);
        this.manService.setRecurrence(RecurrenceFrequencies.weekly, options);
    };
    LMWeeklyRecurrenceComponent.prototype.updatedRepeatMode = function (options) {
        var activeDays = _.reduce(this.weekDays, function (accum, d) {
            if (d.isActive) {
                accum.push({ day: d.id, offset: 0 });
            }
            return accum;
        }, []);
        options.byWeekDay = activeDays;
    };
    LMWeeklyRecurrenceComponent.prototype.updateEndMode = function (options) {
        switch (this.endMode) {
            case this.endAfterOccurrence:
                options.count = this.count;
                break;
            case this.endOnDate:
                options.until = this.until;
                break;
        }
    };
    LMWeeklyRecurrenceComponent.prototype.updateActiveWeekDay = function (day) {
        var weekDays = _.cloneDeep(this.weekDays);
        if (_.isObjectLike(weekDays[day.id])) {
            weekDays[day.id].isActive = !weekDays[day.id].isActive;
        }
        var activeWeekDays = _.filter(weekDays, function (d) { return d.isActive; });
        if (activeWeekDays.length === 0) {
            return false;
        }
        this.weekDays = weekDays;
        return true;
    };
    LMWeeklyRecurrenceComponent.prototype.defineDefaults = function (recurrence, endDate) {
        var weekDays = _.cloneDeep(this.manService.weekDays);
        _.forEach(recurrence.byWeekDay, function (wd) {
            if (_.isObjectLike(weekDays[wd.day])) {
                weekDays[wd.day].isActive = true;
            }
        });
        this.weekDays = weekDays;
        this.interval = recurrence.interval || 1;
        this.count = recurrence.count || 1;
        this.until = recurrence.until || endDate;
    };
    LMWeeklyRecurrenceComponent.prototype.updatesRestrictionDates = function () {
        var copyOfMinDate = this.copyDate(this.limitEndOnDate);
        this.calculatedMaxDate = new Date(copyOfMinDate.getTime());
        if (!_.isDate(this.until) || this.until < this.calculatedMaxDate) {
            this.until = this.copyDate(this.calculatedMaxDate);
        }
    };
    LMWeeklyRecurrenceComponent.prototype.defineMode = function (count, until) {
        if (_.isFinite(count) && count > 0) {
            this.endMode = this.endAfterOccurrence;
        }
        else {
            this.endMode = this.endOnDate;
        }
    };
    LMWeeklyRecurrenceComponent.prototype.defineStartDate = function () {
        var endDate = this.manService.getLoaDates().endDate;
        var estimateEndDate = this.manService.getEstamatedDates().endDate;
        this.limitEndOnDate = endDate || new Date();
        return estimateEndDate;
    };
    return LMWeeklyRecurrenceComponent;
}(LMCommonRecurrence));
export { LMWeeklyRecurrenceComponent };
