import { LocationUnit, ShiftDefinition, Position } from './../../../organization/models/lookup/index';
import { RecipientInfo } from './index';

export class SendShiftSmsRequest {
  public recipients: RecipientInfo[];
  public originalMessageId: number;
  public position: Position;
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public dateOn: Date;
  public messageText: string;

  constructor(recipients: RecipientInfo[], originalMessageId: number, shift: ShiftDefinition, unit: LocationUnit, position: Position, dateOn: Date) {
    this.recipients = recipients;
    this.shift = shift;
    this.unit = unit;
    this.position = position;
    this.dateOn = dateOn;
    this.originalMessageId = originalMessageId;
  }
}
