import {
  NgModule,
  Component,
  Input,
  ElementRef,
  forwardRef,
  ViewChild,
  HostListener,
  HostBinding,
  SimpleChanges,
  EventEmitter,
  Output,
  OnInit,
  OnChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment';

import { Assert } from '../../../framework/assert/assert';
import { createValuAccessor } from '../../utils/index';
import { CustomDomEvents } from '../../models/index';
import { dateTimeUtils } from '../../../common/utils/index';

@Component({
  moduleId: module.id,
  selector: 'slx-time-input',
  templateUrl: 'time-input.component.html',
  styleUrls: ['time-input.component.scss'],
  providers: [createValuAccessor(TimeInputComponent)]
})
export class TimeInputComponent implements ControlValueAccessor, OnInit, OnChanges {

  @Input()
  public set showCalendar(value: boolean) {
    this.internalShowCalendar = value;
  }

  public get showCalendar(): boolean {
    return this.internalShowCalendar;
  }

  @Input()
  public set dateToUpdate(value: Date | moment.Moment) {
    // strange bug sometimes string from get value assigned here
    if (value instanceof Date) {
      this.internalDateToUpdate = value;
      this.internalDateToUpdateMoment = moment(value);
    } else if (moment.isMoment(value)) {
      this.internalDateToUpdateMoment = value;
      this.internalDateToUpdate = value.toDate();
    }
  }

  @Input() public format: string = 'MM/dd/yyyy';

  @Input()
  public set minDateLimit(value: Date) {
    if (value) {
      this.internalMinMoment = moment(value);
    } else {
      this.internalMinMoment = null;
    }
  }

  @Input()
  public set maxDateLimit(value: Date) {
    if (value) {
      this.internalMaxMoment = moment(value);
    } else {
      this.internalMaxMoment = null;
    }
  }

  @Input() public className: string;
  @Input() public placeholder: string = 'Time';
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }

  @Output()
  public onDateChanged: EventEmitter<moment.Moment> = new EventEmitter<moment.Moment>();

  public get readonly(): boolean {
    return !this.inEdit;
  }

  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v ? v : null;
      this.dateToUpdate = v ? this.prepareValue(this.innerValue, this.internalDateToUpdate) : null;
      this.onChangeCallback(this.innerValue);
    }
  }


  public timeMaskConf: any;
  public inEdit: boolean;
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: any = '';
  private internalDateToUpdate: Date;
  private internalDateToUpdateMoment: moment.Moment;

  private internalMinMoment: moment.Moment;
  private internalMaxMoment: moment.Moment;
  private internalShowCalendar: boolean;
  private kendoDatePicker: kendo.ui.DatePicker;
  private initialized: boolean;

  private dateChangeHandler: (e: kendo.ui.DatePickerChangeEvent) => void;

  @ViewChild('timeInput', {static: true}) private inputComponent: ElementRef;

  @HostBinding('class.edited') private hasFocus: boolean = false;

  constructor(elementRef: ElementRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.timeMaskConf = {
      mask: (value: string) => {
        let numberMatch: RegExpMatchArray = value.match(/\d/g);
        let numberLength: number = numberMatch ? numberMatch.join('').length : 0;
        let patterns: any[] = [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /(a|A|p|P)/, /(m|M)/];
        if (numberLength > 1) {
          if (parseInt(numberMatch[0]) === 1) {
            patterns.splice (1, 1, /[0-2]/);
            return patterns;
          }
        }
        return patterns;

      },
      guide: true,
      keepCharPositions: true
    };
    this.hasFocus = false;

    this.dateChangeHandler = (e: kendo.ui.DatePickerChangeEvent) => {
      let m: moment.Moment = moment(this.kendoDatePicker.value());
      this.calendarDateChanged(m);
    };
  }

  public ngOnInit(): void {
    this.initialized = true;
  }

  @HostListener(CustomDomEvents.focus)
  public onCustomFocus(): void {
    this.hasFocus = true;
  }

  @HostListener(CustomDomEvents.blur)
  public onCustomBlur(): void {
    this.hasFocus = false;
  }
  public ngOnChanges(changes: SimpleChanges): void {
    let myattr: any = this.elementRef.nativeElement.getAttribute('readonly');
    this.inEdit = !myattr;
  }
  public writeValue(v?: any): void {
    this.innerValue = v ? v : null;
    this.inputComponent.nativeElement.value = this.innerValue;
    if (this.internalDateToUpdate) {
      this.dateToUpdate = v ? this.prepareValue(this.innerValue, this.internalDateToUpdate) : null;
    }
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }

  public toggleCalendar(): void {
    if (!this.kendoDatePicker) {
      this.createDatePicker();
    }
    this.kendoDatePicker.open();
  }

  private prepareValue(time: string, originalValue: Date): Date {
    return dateTimeUtils.setTime(originalValue, time, true);
  }

  private calendarDateChanged(moment: moment.Moment): void {
    this.onDateChanged.emit(moment);
  }

  private createDatePicker(): void {
    if (this.$datepickerInput && !this.kendoDatePicker) {
      let kendoDatePickerOptions: kendo.ui.DatePickerOptions = this.kendoDatePickerOptions();
      let input: JQuery = this.$datepickerInput;
      input.kendoDatePicker(kendoDatePickerOptions);
      this.kendoDatePicker = input.data('kendoDatePicker');
      this.kendoDatePicker.close();
    }
  }

  private get $element(): JQuery {
    return $(this.elementRef.nativeElement);
  }

  private get $calendarBtn(): JQuery {
    return this.$element.find('.button.calendar');
  }

  private get $datepickerInput(): JQuery {
    return this.$element.find('input.date-picker-field');
  }

  private kendoDatePickerOptions(): kendo.ui.DatePickerOptions {
    let options: kendo.ui.DatePickerOptions = {
      format: this.format,
      change: this.dateChangeHandler
    };
    if (this.internalMinMoment) {
      options.min = this.internalMinMoment.toDate();
    }
    if (this.internalMaxMoment) {
      options.max = this.internalMaxMoment.toDate();
    }
    return options;
  }
}
