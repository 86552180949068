import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridComponent } from '@progress/kendo-angular-grid';
import { process, State, SortDescriptor } from '@progress/kendo-data-query';
import { KendoGridStateHelper } from '../../../../../../common/models/index';
import { StateResetTypes } from '../../../../../../core/models/settings/reset-types';
import { appConfig } from '../../../../../../../app/app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { BenefitsNavigationService } from '../../../../../../common/services/index';
import { BenefitListManagementService } from './../../../../services/benefit-console';
var BenefitsRecentActivityComponent = /** @class */ (function () {
    function BenefitsRecentActivityComponent(activatedRoute, router, manService) {
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.manService = manService;
        this.columnGroup = 'BenefitsRecentActivity';
        this.data = [];
        this.isLoading = true;
        this.isShowingExpired = false;
        this.isPageable = false;
        this.pageSize = 50;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.skip = 0;
        this.gridState.state.sort = [
            { field: 'modifiedAt', dir: 'desc' }
        ];
        this.gridState.gridComponentKey = 'BenefitsRecentGrid';
        this.gridState.gridControlStateKey = 'GridFiltersState';
        this.gridState.gridComponentStateResetType = StateResetTypes.OrgLevelChange | StateResetTypes.MenuChange | StateResetTypes.SessionEnd;
        this.gridState.assignRestoredState = true;
        this.gridState.refreshGridAfterRestoreState = true;
        this.navService = new BenefitsNavigationService(this.router, this.activatedRoute);
        this.appConfig = appConfig;
    }
    BenefitsRecentActivityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.manService
            .subscribeToOrgLevel(function (v) {
            _this.orgLevel = v;
        });
        this.subscriptions.onLoad = this.manService
            .subscribeToLoading(function (value) { return (_this.isLoading = value); });
        this.subscriptions.onLoaded = this.manService
            .subscribeToLoadedRecords(function (r) {
            _this.data = r;
            _this.data = _.isObjectLike(_this.data) ? _.map(_.orderBy(_.groupBy(_this.data, function (x) { return [x.benefitGroupName, x.name].join(); }), ['modifiedAt'], ['desc']), function (x) { return _.first(x); }) : null;
            _this.isPageable = _.size(_this.data) > _this.pageSize;
            _this.refreshGrid();
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
    };
    BenefitsRecentActivityComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    BenefitsRecentActivityComponent.prototype.onClickBenefit = function (providerLine) {
        var benefitGroupId = providerLine.benefitGroupId, benefitProviderId = providerLine.benefitProviderId, id = providerLine.id;
        this.navService.navigateToDetails(this.orgLevel.id, benefitGroupId, benefitProviderId, id);
    };
    BenefitsRecentActivityComponent.prototype.refreshGrid = function () {
        this.isLoading = false;
        if (!this.data) {
            this.gridState.view = null;
            return;
        }
        this.fixModifiedSorting();
        this.gridState.view = process(this.data, this.gridState.state);
    };
    //Please DO NOT USE this code in other places. It is temporary tricky fix of https://github.com/telerik/kendo-angular/issues/1850. Check this link if needed.
    BenefitsRecentActivityComponent.prototype.fixModifiedSorting = function () {
        if (this.gridState.state.sort) {
            var sortField = _.find(this.gridState.state.sort, function (x) { return x.field === 'filterModifiedAt'; });
            if (sortField) {
                var clone = _.cloneDeep(sortField);
                clone.field = 'modifiedAt';
                this.gridState.state.sort.push(clone);
            }
        }
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitsRecentActivityComponent.prototype, "subscriptions", void 0);
    return BenefitsRecentActivityComponent;
}());
export { BenefitsRecentActivityComponent };
