import * as tslib_1 from "tslib";
import { ApplicationService } from './../../portal/services/application/application.service';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { ReportsApiService } from './reports-api.service';
import { PayCycle, PayPolicy, LookupEntity, StyledUserApplication, Position } from '../../organization/models/index';
import { unsubscribeInService } from '../../core/decorators/index';
import { Assert } from '../../framework/index';
import * as _ from 'lodash';
import { SessionService } from '../../core/services/index';
import { dateTimeUtils } from './../../common/utils/dateTimeUtils';
import { mutableSelect } from '../../core/decorators/index';
import * as moment from 'moment';
import { LookupApiService } from '../../organization/services/index';
import { ReportDefinition, ReportParameter, ReportGroup, ReportExportType, GenerateReportRequest, GenerateReportParameter, ReportStaffingSetting, ReportAttendanceSetting, GetBIPAReportDefinition } from '../models/index';
import { AuthApiService } from '../../core/services/index';
import { WindowRef } from '../../core/services/window/window-ref.model';
import { reportsConfig } from '../reports.config';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
var ReportsManagementService = /** @class */ (function () {
    function ReportsManagementService(reportsApiService, sessionService, authService, winRef, applicationService, lookupApiService) {
        var _this = this;
        this.reportsApiService = reportsApiService;
        this.sessionService = sessionService;
        this.authService = authService;
        this.winRef = winRef;
        this.applicationService = applicationService;
        this.lookupApiService = lookupApiService;
        this.isRNHrsEntered = false;
        this.reportParameterAllValuesMarker = 'All';
        this.onReportsLoaded$ = new ReplaySubject(1);
        this.onReportConfigSettingSave$ = new ReplaySubject(1);
        this.onReportsLoadStarted$ = new ReplaySubject(1);
        this.onReportGenerated$ = new ReplaySubject(1);
        this.onReportGenerationStarted$ = new ReplaySubject(1);
        this.onErrorOccured$ = new ReplaySubject(1);
        this.isZeroHrsSelected$ = new Subject();
        this.onReportStaffingSettingsLoaded$ = new ReplaySubject(1);
        this.onReportAttendanceSettingsLoaded$ = new ReplaySubject(1);
        this.onReportDailyStaffingPostLoadeded$ = new ReplaySubject(1);
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) {
            return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id;
        })
            .subscribe(function (o) {
            if (o.id) {
                _this.selectedOrgLevel = o;
                _this.loadData(_this.selectedOrgLevel);
            }
        });
        this.applicationService.getStyledApplications().then(function (value) {
            _this.styledAppDictionary = _.mapKeys(value, function (app) { return app.title; });
        });
    }
    ReportsManagementService.prototype.destroy = function () {
        // See #issueWithAOTCompiler
    };
    ReportsManagementService.prototype.onReportsLoaded = function (reports) {
        this.onReportsLoaded$.next(reports);
    };
    ReportsManagementService.prototype.onReportConfigSettingSave = function (isSave) {
        this.onReportConfigSettingSave$.next(isSave);
    };
    ReportsManagementService.prototype.onReportsStaffingSettingsLoaded = function (reportStaffingSetting) {
        this.onReportStaffingSettingsLoaded$.next(reportStaffingSetting);
    };
    ReportsManagementService.prototype.onReportAttendanceSettingsLoaded = function (reportAttendanceSetting) {
        this.onReportAttendanceSettingsLoaded$.next(reportAttendanceSetting);
    };
    ReportsManagementService.prototype.onReportDailyStaffingPostLoaded = function (reportDailyStaffingPost) {
        this.onReportDailyStaffingPostLoadeded$.next(reportDailyStaffingPost);
    };
    ReportsManagementService.prototype.selectReport = function (report) {
        Assert.isNotNull(report, 'report');
        var preparedReport = Object.assign(report, {});
        preparedReport.parameters = _.map(report.parameters, function (parameter) { return Object.assign(parameter, {}); });
        preparedReport.exportType = ReportExportType.Excel;
        this.prepareParameters(report.parameters);
        return preparedReport;
    };
    ReportsManagementService.prototype.generateReport = function (report) {
        var _this = this;
        Assert.isNotNull(report, 'report');
        this.onReportsGenerationStarted(report);
        var generateReportRequest = this.createGenerateReportRequest(report);
        this.reportsApiService.generateReport(generateReportRequest).then(function (data) {
            _this.onReportGenerated$.next(data);
        }).catch(function (error) {
            _this.onErrorOccured$.next(error);
        });
    };
    ReportsManagementService.prototype.refreshReportsList = function () {
        this.loadData(this.selectedOrgLevel);
    };
    ReportsManagementService.prototype.previewReport = function (report) {
        var request = this.createGenerateReportRequest(report);
        var url = environment.API + "/" + reportsConfig.api.root + "/" + reportsConfig.api.template + "?reportRequest=" + encodeURIComponent(JSON.stringify(request)) + "&token=" + this.authService.getToken();
        this.winRef.nativeWindow.open(url, '_blank');
    };
    ReportsManagementService.prototype.loadData = function (orgLevel) {
        var _this = this;
        Assert.isNotNull(orgLevel, 'orgLevel');
        this.onReportsLoadStarted();
        this.reportsApiService.getReportsList(orgLevel.id).then(function (reports) {
            reports = _this.postProcess(reports);
            _this.onReportsLoaded(reports);
        }).catch(function (error) {
            _this.onErrorOccured$.next(error);
        });
    };
    ReportsManagementService.prototype.loadStaffingSettingsData = function (forcedLoad) {
        var _this = this;
        this.reportsApiService.getStaffingSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, forcedLoad).then(function (reportStaffingSetting) {
            _this.onReportsStaffingSettingsLoaded(reportStaffingSetting);
        }).catch(function (error) {
            _this.onErrorOccured$.next(error);
        });
    };
    ReportsManagementService.prototype.loadAttendanceSettingsData = function (forcedLoad) {
        var _this = this;
        this.reportsApiService.getAttendanceSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, forcedLoad).then(function (reportAttendanceSetting) {
            _this.onReportAttendanceSettingsLoaded(reportAttendanceSetting);
        }).catch(function (error) {
            _this.onErrorOccured$.next(error);
        });
    };
    ReportsManagementService.prototype.saveStaffingSettingsData = function (reportStaffingSetting) {
        var _this = this;
        this.onReportConfigSettingSave(true);
        this.reportsApiService.saveStaffingSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, reportStaffingSetting).then(function () {
            _this.onReportConfigSettingSave(false);
        }).catch(function (error) {
            _this.onErrorOccured$.next(error);
        });
    };
    ReportsManagementService.prototype.saveAttendanceSettingsData = function (reportAttendanceSetting) {
        var _this = this;
        this.onReportConfigSettingSave(true);
        this.reportsApiService.saveAttendanceSettings(this.selectedOrgLevel.id, this.selectedOrgLevel.relatedItemId, reportAttendanceSetting).then(function () {
            _this.onReportConfigSettingSave(false);
        }).catch(function (error) {
            _this.onErrorOccured$.next(error);
        });
    };
    ReportsManagementService.prototype.postProcess = function (reports) {
        var _this = this;
        _.forEach(reports, function (report) {
            var app = _this.styledAppDictionary[report.name];
            if (app) {
                report.icon = app.icon;
                report.appId = app.id;
            }
            else if (report.name.toLowerCase() === 'employee') {
                report.icon = 'fa-user';
            }
            else {
                report.icon = 'fa-chart-line';
            }
        });
        var isHrms = reports.filter(function (report) { return report.appId === 6; }).length;
        if (!isHrms) {
            reports.map(function (report) {
                if (report.name.toLowerCase() === 'reports') {
                    report.icon = 'fa-user';
                    report.name = 'Employee';
                }
            });
        }
        return _.sortBy(reports, function (r) { return r.appId; });
    };
    ReportsManagementService.prototype.onReportsLoadStarted = function () {
        this.onReportsLoadStarted$.next(null);
    };
    ReportsManagementService.prototype.onReportsGenerationStarted = function (report) {
        this.onReportGenerationStarted$.next(report);
    };
    ReportsManagementService.prototype.prepareParameters = function (parameters) {
        var _this = this;
        var loggedUser = this.sessionService.getUser();
        _.forEach(parameters, function (parameter) {
            if (parameter.name && parameter.name.toLocaleLowerCase() === 'org_level_id') {
                parameter.value = _this.selectedOrgLevel.id;
            }
            else if (parameter.name && parameter.name.toLocaleLowerCase() === 'org_level_name') {
                parameter.value = _this.selectedOrgLevel.name;
            }
            else if (parameter.name && parameter.name.toLocaleLowerCase() === 'user_id') {
                parameter.value = loggedUser.id;
            }
            if (!parameter.value && !parameters.find(function (x) { return x.name == ("Show_dates"); }) && !parameters.find(function (x) { return x.name == ("Zero_hrs"); }) && !parameters.find(function (x) { return x.name == ("Total_hrs"); })) {
                parameter.value = parameter.defaultValue;
            }
        });
    };
    ReportsManagementService.prototype.createGenerateReportRequest = function (report) {
        var request = new GenerateReportRequest();
        request.exportType = report.exportType;
        request.parameters = this.createGenerateReportParameters(report.parameters),
            request.name = report.name;
        request.pageLayout = report.pageLayout;
        request.folderName = report.folder;
        return request;
    };
    ReportsManagementService.prototype.createGenerateReportParameters = function (parameters) {
        var _this = this;
        var preapredParameters = _.map(parameters, function (parameter) { return _this.createGenerateReportParameter(parameter); });
        var payCycleParameter = _.find(preapredParameters, function (parameter) { return parameter.name === 'pay_cycle'; });
        if (payCycleParameter) {
            var replaceWithParameters = this.preparePaycycleParameter(payCycleParameter);
            var indexOfPayCycleParameter = preapredParameters.indexOf(payCycleParameter);
            preapredParameters.splice(indexOfPayCycleParameter, 1);
            _.forEach(replaceWithParameters, function (parameterToAdd) {
                preapredParameters.push(parameterToAdd);
            });
        }
        return preapredParameters;
    };
    ReportsManagementService.prototype.createGenerateReportParameter = function (parameter) {
        var reportParameter = new GenerateReportParameter();
        reportParameter.name = parameter.name;
        reportParameter.value = this.processReportParameterValue(parameter);
        reportParameter.parameterType = parameter.dataType.name;
        return reportParameter;
    };
    ReportsManagementService.prototype.preparePaycycleParameter = function (parameter) {
        Assert.isNotNull(parameter, 'parameter');
        var parameters = [];
        if (parameter.value instanceof PayCycle && !!parameter.value) {
            var generateReportParameter = new GenerateReportParameter();
            generateReportParameter.name = 'start_date';
            generateReportParameter.value = dateTimeUtils.convertToDtoString(parameter.value.startDate);
            parameters.push(generateReportParameter);
            generateReportParameter = new GenerateReportParameter();
            generateReportParameter.name = 'end_date';
            generateReportParameter.value = dateTimeUtils.convertToDtoString(parameter.value.endDate);
            parameters.push(generateReportParameter);
        }
        return parameters;
    };
    ReportsManagementService.prototype.processReportParameterValue = function (reportParameter) {
        var _this = this;
        var result = undefined;
        if ((reportParameter.value !== undefined && reportParameter.value !== null)) {
            if (reportParameter.dataType.isMultipleValues && reportParameter.value !== this.reportParameterAllValuesMarker) {
                result = _.join(_.map(reportParameter.value, function (item) { return _this.convertReportParameterValue(item); }), ',');
            }
            else {
                result = this.convertReportParameterValue(reportParameter.value);
            }
        }
        else {
            if (reportParameter.dataType.isLookup) {
                if (!!reportParameter.defaultValue) {
                    result = reportParameter.defaultValue;
                }
                else {
                    result = this.reportParameterAllValuesMarker;
                }
            }
            else if (reportParameter.dataType.name === 'bit') {
                result = false;
            }
        }
        return result;
    };
    ReportsManagementService.prototype.convertReportParameterValue = function (value) {
        if (value instanceof Date) {
            return dateTimeUtils.convertToDtoString(value);
        }
        else if (moment.isMoment(value)) {
            return dateTimeUtils.convertToDtoString(value.toDate());
        }
        else if (value instanceof LookupEntity) {
            return !!value ? value.id : undefined;
        }
        else if (value instanceof PayPolicy) {
            return !!value ? value.name : undefined;
        }
        else {
            return value;
        }
    };
    ReportsManagementService.prototype.loadDailyStaffPostingData = function (forcedLoad) {
        var _this = this;
        this.reportsApiService.getDailyStaffingSettings(this.selectedOrgLevel.id, forcedLoad).then(function (dailyStaffingSetting) {
            _this.onReportDailyStaffingPostLoaded(dailyStaffingSetting);
        }).catch(function (error) {
            _this.onErrorOccured$.next(error);
        });
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], ReportsManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], ReportsManagementService.prototype, "orgLevelSubscription", void 0);
    return ReportsManagementService;
}());
export { ReportsManagementService };
