<div *ngIf="isEmployeeNotExists && isPositionNotExists && !isProceed" class="new-rotation-container">
    <div class="add-person">
        <p>Add Person</p>
        <div class="person-icon" (click)="addPersonOrPosition(true)"><i class="fal fa-user-plus"></i></div>
    </div>
    <div class="add-position">
        <p>Add Position</p>
        <div class="position-icon" (click)="addPersonOrPosition(false)"><i class="fal fa-clipboard-list-check"></i>
        </div>
    </div>
</div>

<div *ngIf="isProceed" class="save-delete-container">
    <span *ngIf="isAddPerson && !isAddPosition">
        <p>Add Person</p>
        <kendo-dropdownlist class="drop-down-style" [data]="noRotationsEmployees" [(ngModel)]="selectedPerson" textField="name" valueField="employeeId">
        </kendo-dropdownlist>
    </span>
    <span *ngIf="!isAddPerson && isAddPosition">
        <p>Add Position</p>
        <kendo-dropdownlist class="drop-down-style" [data]="positionItems" [(ngModel)]="selectedPosition" textField="name" valueField="id">
        </kendo-dropdownlist>
    </span>
    <div class="rotation-save-delete-shift">
        <i class="fas fa-save" (click)="saveData()"></i>
        <i class="far fa-minus-square" (click)="backToList()"></i>
    </div>
</div>
<div class="deleteIconDiv" *ngIf="(!isPositionNotExists && isEmployeeNotExists && !isProceed) && (isRotationSaved || !isShiftsAssigned) && !isEditMode">
    <i class="fa fa-trash deleteIcon" aria-hidden="true" (click)="removeSlotRotations()"></i>
</div>
<div class="pos-display-container" *ngIf="!isPositionNotExists && isEmployeeNotExists && !isProceed">
    <p class="position-detail">{{employeeData.positionName}}</p>
    <span class="position-add-person" (click)="addPersonOrPosition(true)"><i class="fal fa-user-plus"></i></span>
</div>


