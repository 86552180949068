import { Component, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { StateManagementService, ColumnSettingsStorageService, ColumnManagementService, ColumnsChangedEvent } from '../../../common/services/index';
import { IColumnSettings } from '../../../core/models/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { PopperPositions } from '../../models/index';
import { AppServerConfig } from '../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from '../../../app-settings/services';
@Component({
  moduleId: module.id,
  selector: 'slx-columns-config-button',
  templateUrl: 'columns-config-button.component.html',
  styleUrls: ['columns-config-button.component.scss']
})
export class ColumnsConfigButtonComponent implements OnDestroy, AfterViewInit {

  @Input ()
  public secondaryIcon: string;

  @Input()
  public colGroupKey: string = ColumnSettingsStorageService.defaultColumnGroupKey;

  @Input()
  public popperPosition: PopperPositions = 'bottom-start';

  @Input()
  public title: string;

  @Input()
  public width: number = 250;

  @Input()
  public blueStyleConfig = { btnClass: '', iconClass: '' };

  @Output()
  public columnChanged: EventEmitter<IColumnSettings>;
  @Output()
  public selectedAll: EventEmitter<IColumnSettings[]>;
  @Output()
  public clearedAll: EventEmitter<IColumnSettings[]>;

  public columns: IColumnSettings[];
  public contextKey: string;
  public uniqId: string;
  public isInitialized: boolean;
  private appSettingManageService: AppSettingsManageService;
  @unsubscribe()
  private groupInitializedSubscription: Subscription;

  constructor(private columnSettingsStorageService: ColumnSettingsStorageService, private columnManagementService: ColumnManagementService, private changeDetector: ChangeDetectorRef, appSettingManageService: AppSettingsManageService) {
    this.appSettingManageService = appSettingManageService;
    this.columnChanged = new EventEmitter<IColumnSettings>();
    this.selectedAll = new EventEmitter<IColumnSettings[]>();
    this.clearedAll = new EventEmitter<IColumnSettings[]>();
    this.uniqId = this.getComponentUniqueId();
  }

  public ngAfterViewInit(): void {
    this.groupInitializedSubscription = this.columnManagementService.groupInitialized$.filter((event: ColumnsChangedEvent) => event.group === this.colGroupKey)
    .subscribe((event: ColumnsChangedEvent) => {
      this.isInitialized = true;
      this.contextKey = event.component;

      this.appSettingManageService.getAppServerConfig().then((conf: AppServerConfig) => {
        if (conf.IsNextgenPayrollEnabled) {
          const column = event.columns.find((x) => x.description === 'Interface Indicator');
          if (column != undefined && column != null) {
            column.description = 'Payroll Pay Code';
          }
        }
      });
      
      this.columns = event.columns;
      _.forEach(this.columns, (column: IColumnSettings) => {
        if (column.displayed === undefined) {
          column.displayed = true;
        }
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
      });
    });
  }
  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public get isBlueStyleBtn(): boolean {
    const { btnClass = '', iconClass = '' } = this.blueStyleConfig || {};

    return _.isString(btnClass) &&_.size(btnClass) > 0
      || _.isString(iconClass) && _.size(iconClass) > 0;
  }

  public get blueStyleBtnClass(): string {
    let btnClass: string[] = [];
    if (!this.isInitialized) {
      btnClass.push('hidden-config-button');
    }
    if (_.size(this.blueStyleConfig.btnClass) > 0) {
      btnClass.push(this.blueStyleConfig.btnClass);
    }

    return btnClass.join(' ');
  }

  public get blueStyleIconClass(): string {
    if (_.size(this.blueStyleConfig.iconClass) > 0) {
      return this.blueStyleConfig.iconClass;
    }
    return '';
  }

  public onChangeColumn(column: IColumnSettings): void {
    this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
    this.columnChanged.emit(column);
    this.saveColumns();
  }

  public selectAll(): void {
    _.forEach(this.columns, (column: IColumnSettings) => {
      column.displayed = true;
    });
    this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
    this.selectedAll.emit(this.columns);
    this.saveColumns();
  }
  public clearAll(): void {
    _.forEach(this.columns, (column: IColumnSettings) => {
      column.displayed = false;
    });
    this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
    this.clearedAll.emit(this.columns);
    this.saveColumns();
  }

  private getComponentUniqueId(): string {
    return `b${Math.random().toString(36).substr(2, 9)}`;
  }

  private saveColumns(): void {
    this.columnSettingsStorageService.setColumnsState(this.contextKey, this.colGroupKey, this.columns);
  }



}
