export interface IBudgetedParsDetailsRow {
    positionId: number;
    scheduledHours: number;
    budgetedHours: number;
}

export class BudgetedParsDetailsRow {
    public positionId: number;
    public scheduledHours: number;
    public budgetedHours: number;
    public discrepency: number;
    public comment: string;
    public positionName: string;
}
