import * as _ from 'lodash';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmDialog2Component, ConfirmOptions, DialogOptions2, IDialog, KendoGridStateHelper, ModalService } from '../../../../../common';
import { AcaExportExecutionItem } from '../../../models/aca-export/aca-export-execution';
import { ViewChild } from '@angular/core';
import { AbstractControl, NgForm } from '@angular/forms';
import { AcaExportDialogOptions } from '../../../models/aca-export/aca-export-dialog-options';
import { AcaExportManagementService } from '../../../services/aca-export/aca-export-management.service';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { AcaExportCorrectionType } from '../../../models/aca-export/aca-export-correctiontype';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { AcaExportSupplemental1095C } from '../../../models/aca-export/aca-export-supplemental-1095c';
import { AcaExportParameter } from '../../../models/aca-export/aca-export-parameter';
import { AcaExportConfiguration } from '../../../models/aca-export/aca-export-configuration';
import { AcaExportType } from '../../../enums/aca-export-type';

@Component({
  selector: 'slx-aca-export-supplemental-1095c',
  templateUrl: './aca-export-supplemental-1095c.component.html',
  styleUrls: ['./aca-export-supplemental-1095c.component.scss']
})
export class AcaExportSupplementalComponent implements IDialog, OnInit {

  public get initialized(): boolean {
    return this.m_initialized;
  }

  public gridData: any[] = [''];

  @ViewChild('correctionForm', { static: true })
  private ngFormChild: NgForm;
  public get form(): AbstractControl {
    return this.ngFormChild ? this.ngFormChild.form : null;
  }

  private modalService: ModalService;
  private options: DialogOptions2;
  private changeDetector: ChangeDetectorRef;
  public manService: AcaExportManagementService;

  constructor(
    modalService: ModalService,
    options: DialogOptions2,
    manService: AcaExportManagementService,
    changeDetector: ChangeDetectorRef
  ) {
    this.modalService = modalService;
    this.options = options;
    this.modalService = modalService;
    this.changeDetector = changeDetector;
    this.manService = manService;
    this.gridState = new KendoGridStateHelper<AcaExportSupplemental1095C[]>();
    this.gridState.view = null;
  }

  public gridState: KendoGridStateHelper<AcaExportSupplemental1095C[]>;
  public acaCorrectionType: AcaExportCorrectionType;
  public acaExportExecutionItem: AcaExportExecutionItem;
  public dialogResult: boolean = false;
  public isLoading: boolean = true;
  public data: AcaExportExecutionItem;
  public isButtonEnable: boolean = true;
  public gridView: GridDataResult;
  public sort: SortDescriptor[] = [];
  public records: AcaExportSupplemental1095C[];
  public m_initialized: boolean;
  public isAllSelected: boolean;
  public exportParams: AcaExportParameter = new AcaExportParameter();
  public configuration: AcaExportConfiguration = new AcaExportConfiguration();

  public ngOnInit(): void {
    this.m_initialized = true;
    this.isLoading = false;
  }

  public filterChange(filter: any): void {
    this.gridState.state.filter = filter;
    this.refreshGrid();
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  public async getAcaSupplemental1095C(exectionId: string): Promise<void> {
    this.isLoading = true;
    this.records = await this.manService.fetchSupplemental1095CRecords(exectionId);
    this.refreshGrid();
    this.isLoading = false;
  }

  public get valid(): boolean {
    let formValid: boolean = this.form.invalid ? false : true;
    return formValid;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onBack() {
    this.onCancel();
    let acaExportDialogOptions: AcaExportDialogOptions = new AcaExportDialogOptions();
    acaExportDialogOptions.acaExportCorrectionType = this.acaCorrectionType;
    acaExportDialogOptions.dialogType = 1;
    acaExportDialogOptions.acaExportExecutionItem = this.acaExportExecutionItem;
    acaExportDialogOptions.acaExportSupplemental1095C = this.records;
    this.manService.openAcaExportDialog(acaExportDialogOptions);
  }

  public showGenerateXML(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      'XML',
      'Are you sure you wish to generate a corrected XML export according to the parameters you specified?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.exportParams.exportType = AcaExportType.ExportXmlSupplemental1095C;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.exportParams.year = this.acaExportExecutionItem.year;
          this.exportParams.qualifiedOfferMethod = this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
          this.exportParams.offerMethod98 = this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
          this.exportParams.memberOfAleGroup = this.acaExportExecutionItem.memberOfAleGroup;
          this.exportParams.empIds = this.exportParams.empIds;
          this.configuration.exportParams = this.exportParams;
          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }

  public showGenerate1095c(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      '1095-Cs PDF',
      'Do you wish to proceed with generating a PDF export?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.exportParams.exportType = AcaExportType.ExportPdfSupplemental1095C;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.exportParams.year = this.acaExportExecutionItem.year;
          this.exportParams.qualifiedOfferMethod = this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
          this.exportParams.offerMethod98 = this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
          this.exportParams.memberOfAleGroup = this.acaExportExecutionItem.memberOfAleGroup;
          this.exportParams.offeredMec = this.acaExportExecutionItem.offeredMec;
          this.exportParams.empIds = this.exportParams.empMasterIds;
          this.configuration.exportParams = this.exportParams;
          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }

  public showGenerate1094c(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    ConfirmDialog2Component.openDialog(
      '1094-C PDF',
      'Do you wish to proceed with generating a PDF export?',
      this.modalService,
      (result: boolean) => {
        if (result) {
          this.exportParams.exportType = AcaExportType.ExportPdfSupplemental1094C;
          this.exportParams.receiptId = this.acaCorrectionType.receiptId;
          this.exportParams.employerId = this.acaExportExecutionItem.employerId;
          this.exportParams.employerName = this.acaExportExecutionItem.employerName;
          this.exportParams.year = this.acaExportExecutionItem.year;
          this.exportParams.qualifiedOfferMethod = this.acaExportExecutionItem.qualifiedOfferMethod ? 'Yes' : 'No';
          this.exportParams.offerMethod98 = this.acaExportExecutionItem.offerMethod98 ? 'Yes' : 'No';
          this.exportParams.memberOfAleGroup = this.acaExportExecutionItem.memberOfAleGroup;
          this.exportParams.offeredMec = this.acaExportExecutionItem.offeredMec;
          this.exportParams.empIds = this.exportParams.empMasterIds;
          this.configuration.exportParams = this.exportParams;
          this.manService.generateAcaExport(this.configuration.exportParams);
          this.onCancel();
        }
      }, options);
  }

  private refreshGrid(): void {
    if (!this.records) {
      this.gridState.view = null;
      return;
    }
    this.gridState.view = process(this.records, this.gridState.state);
  }

  public onCheckedEmployee(isSelected: boolean): void {
    if (isSelected) {
      this.isButtonEnable = false;
    } else {
      this.isButtonEnable = true;
    }
    let selectedEmployees: AcaExportSupplemental1095C[] = _.filter(this.records, (employee: AcaExportSupplemental1095C) => (employee.isSelected));

    this.exportParams.empIds = _.map(selectedEmployees, (employee: AcaExportSupplemental1095C) => (
      employee.employeeId
    ));

    this.exportParams.empMasterIds = _.map(selectedEmployees, (employee: AcaExportSupplemental1095C) => (
      employee.employeeIdMasterId
    ));
  }

  public onToggleAllSelected(): void {
    const employees = process(this.records, { filter: this.gridState.state.filter }).data;
    const selectedEmployees: AcaExportSupplemental1095C[] = _.filter(employees, (employee: AcaExportSupplemental1095C) => {
      employee.isSelected = this.isAllSelected;
      this.onCheckedEmployee(employee.isSelected);
      return (employee.isSelected as boolean);
    });
  }
}
