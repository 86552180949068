import { Map } from 'immutable';

import { makeTypedMapFactory } from '../../../framework/immutable-utils/index';

export const applicationFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  color: null,
  icon: null,
  id: null,
  link: null,
  name: null,
  title: null
});

export const INITIAL_APPLICATION_STATE: Map<string, any> = applicationFactory();

export const userFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  id: null,
  name: null,
  username: null,
  applications: [],
  linkedEmpId: null
});

export const INITIAL_USER_STATE: Map<string, any> = userFactory();

export const sessionFactory: (val?: any) => Map<string, any> = makeTypedMapFactory({
  alias: null,
  token: null,
  user: null,
});

export const INITIAL_SESSION_STATE: Map<string, any> = sessionFactory();
