import * as _ from 'lodash';

import { OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { dateTimeUtils } from '../../../common/utils/index';

export abstract class LMCommonRecurrence implements OnDestroy {
  public endMode: string;
  public interval: number = 1;
  public count: number = 1;
  public until: Date = new Date();

  public readonly endNever = 'never';
  public readonly endAfterOccurrence  = 'count';
  public readonly endOnDate = 'until';

  public readonly min = 1;
  public readonly max = 31;
  public readonly step = 1;
  public readonly decimals = 0;
  public readonly format = '#';

  public get countIsReadonly(): boolean {
    return this.endMode !== this.endAfterOccurrence;
  }

  public get untilIsReadonly(): boolean {
    return this.endMode !== this.endOnDate;
  }

  protected subscriptions: StringMap<Subscription> = {};

  public ngOnDestroy(): void {
    _.forEach(this.subscriptions, (s: Subscription) => {
      if (s && s.unsubscribe) {
        s.unsubscribe();
      }
    });
    this.subscriptions = {};
  }

  public copyDate(date: Date): Date {
    return dateTimeUtils.copyDate(date);
  }
}
