<slx-spinner [show]="state.isLoading">
  <div class="post-schedule slx-high-box">

    <div class="header">
      <div class="cycle">
        <slx-schedule-cycle-slider
          [itemsArray]="scheduleCycles"
          [currentItem]="selectedScheduleCycle"
          (itemSelected)="onScheduleCycleSelected ($event)"
        ></slx-schedule-cycle-slider>
        <span>Status: {{ scheduleData ? scheduleData.status : "Unknown" }}</span>
      </div>
      <button type="button" class="theme-button-apply" [disabled]="!scheduleData ||  scheduleData.status == 'Posted'" (click)="onPostClick ()">Post</button>
    </div>

    <div class="slx-high-box__body content">
      <div class="l-col">
          <kendo-grid id="summaryGrid" class="slx-full-height"
            [data]="gridView"
            [sortable]="{mode:'single'}"
            [sort]="sort"
            [pageSize]="pageSize"
            [skip]="skip"
            [pageable]="{pageSizes:false, input: false, buttonCount:5}"
            [groupable]="false"
            [selectable]="{ mode:'single'}"
            (pageChange)="pageChange($event)"
            (sortChange)="sortChange($event)"
            (selectionChange)="gridSelectionChange ($event)"
          >

            <kendo-grid-column title="Date" [locked]="false" width="70">
              <ng-template kendoGridHeaderTemplate>
                Date
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.date | amDateFormat: appConfig.dateFormat}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Position" [locked]="false" width="150">
              <ng-template kendoGridHeaderTemplate>
                Position
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.position?.name}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Shift" [locked]="false" width="70">
              <ng-template kendoGridHeaderTemplate>
                Shift
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.shift?.name}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Unit" [locked]="false" width="70">
              <ng-template kendoGridHeaderTemplate>
                Unit
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.unit?.name}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Scheduled" [locked]="false" width="70">
              <ng-template kendoGridHeaderTemplate>
                Scheduled
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.scheduled}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="PAR" [locked]="false" width="70">
              <ng-template kendoGridHeaderTemplate>
                PAR
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem>
                {{dataItem.par}}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Open Shifts" [locked]="false" width="70">
              <ng-template kendoGridHeaderTemplate>
                Open Shifts
              </ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-editing>
                <div class="cell-editor">
                  <span *ngIf="dataItem.editing == false">{{dataItem.openShifts}}</span>
                  <slx-kendo-number slx-input *ngIf="dataItem.editing == true"
                    [(ngModel)]="dataItem.openShifts"
                    name="item-editor"
                  ></slx-kendo-number>
                  <button class="iconed-button" (click)="switchState(dataItem)">
                    <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                  </button>
                </div>
              </ng-template>
            </kendo-grid-column>
        </kendo-grid>
      </div>

      <div class="r-col">
        <h4>Display options</h4>
        <slx-checkbox-toggle className="toggle-left" [(ngModel)]="allowOT" caption="Display open shifts to employees approaching OT" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
        <slx-checkbox-toggle className="toggle-left" [(ngModel)]="allowSameDay" caption="Display open shifts to employees scheduled on same day" checkedText="Yes" uncheckedText="No"></slx-checkbox-toggle>
      </div>
    </div>
  </div>

</slx-spinner>
