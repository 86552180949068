import { Subject } from 'rxjs/Subject';
import * as _ from 'lodash';
import { EditableListActionModel, EditableListActionKind, EditableListProps } from '../../models/index';
var ListActionsService = /** @class */ (function () {
    function ListActionsService() {
        this.subject = new Subject();
        this.allowEdit = true;
        this.allowSelect = true;
        this.privateItemCount = 0;
        this.selectedItemsArray = [];
        this.renderIsEditorInternal = false;
        this.editModeInternal = false;
    }
    Object.defineProperty(ListActionsService.prototype, "multiselect", {
        get: function () {
            return this.allowMultiselect;
        },
        set: function (value) {
            this.allowMultiselect = value;
            var action = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.MULTISELECT, value: value });
            this.sendAction(action);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsService.prototype, "editable", {
        get: function () {
            return this.allowEdit;
        },
        set: function (value) {
            this.allowEdit = value;
            var action = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.EDITABLE, value: value });
            this.sendAction(action);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsService.prototype, "selectable", {
        get: function () {
            return this.allowSelect;
        },
        set: function (value) {
            this.allowSelect = value;
            var action = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.SELECTABLE, value: value });
            this.sendAction(action);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsService.prototype, "itemCount", {
        get: function () {
            return this.privateItemCount;
        },
        set: function (value) {
            this.privateItemCount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsService.prototype, "selectedItems", {
        get: function () {
            return this.selectedItemsArray.slice(0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsService.prototype, "allSelected", {
        get: function () {
            return this.allItemsSelected;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsService.prototype, "renderIsEditor", {
        get: function () {
            return this.renderIsEditorInternal;
        },
        set: function (value) {
            this.renderIsEditorInternal = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListActionsService.prototype, "editMode", {
        get: function () {
            return this.editModeInternal;
        },
        set: function (value) {
            this.editModeInternal = value;
            if (this.renderIsEditorInternal) {
                var action = new EditableListActionModel(EditableListActionKind.PROP_CHANGE, null, { prop: EditableListProps.EDIT_MODE, value: value });
                this.sendAction(action);
            }
        },
        enumerable: true,
        configurable: true
    });
    ListActionsService.prototype.addNewItem = function (item) {
        this.newItem = item;
        if (!this.renderIsEditor)
            this.startEditItem(this.newItem);
    };
    ListActionsService.prototype.isNew = function (item) {
        return (this.newItem === item);
    };
    ListActionsService.prototype.startEditItem = function (item) {
        if (!this.allowEdit)
            return;
        this.hasEditingItem = true;
        var action = new EditableListActionModel(EditableListActionKind.START_EDIT, [item]);
        this.sendAction(action);
    };
    ListActionsService.prototype.cancelEditItem = function (item) {
        var action = new EditableListActionModel(EditableListActionKind.CANCEL_EDIT, [item]);
        this.sendAction(action);
        this.hasEditingItem = false;
    };
    ListActionsService.prototype.saveItem = function (item) {
        var action = new EditableListActionModel(EditableListActionKind.COMPLETE_EDIT, [item]);
        this.sendAction(action);
        this.hasEditingItem = false;
        this.newItem = null;
    };
    ListActionsService.prototype.removeItem = function (item) {
        if (this.renderIsEditor) {
            var action = new EditableListActionModel(EditableListActionKind.REMOVE, [item]);
            this.sendAction(action);
        }
        else {
            if (!this.hasEditingItem) {
                var action = new EditableListActionModel(EditableListActionKind.REMOVE, [item]);
                this.sendAction(action);
            }
        }
    };
    ListActionsService.prototype.toggleSelect = function (item) {
        if (this.hasEditingItem)
            return;
        if (this.allowMultiselect) {
            if (this.selectedItemsArray.indexOf(item) === -1) {
                this.selectedItemsArray.push(item);
            }
            else {
                this.selectedItemsArray = _.without(this.selectedItemsArray, item);
            }
        }
        else {
            if (this.selectedItemsArray.indexOf(item) === -1) {
                this.selectedItemsArray = [item];
            }
            else {
                this.selectedItemsArray = [];
            }
        }
        if (this.selectedItemsArray.length !== this.privateItemCount) {
            this.allItemsSelected = false;
        }
        else {
            this.allItemsSelected = true;
        }
        var action = new EditableListActionModel(EditableListActionKind.SELECTION_CHANGE, [item]);
        this.sendAction(action);
    };
    ListActionsService.prototype.selectAll = function (items) {
        if (this.hasEditingItem)
            return;
        if (this.allowMultiselect) {
            if (this.allItemsSelected === false) {
                this.allItemsSelected = true;
                this.selectedItemsArray = items || [];
            }
            else {
                this.allItemsSelected = false;
                this.selectedItemsArray = [];
            }
            var action = new EditableListActionModel(EditableListActionKind.SELECT_ALL, items || []);
            this.sendAction(action);
        }
    };
    ListActionsService.prototype.resetSelection = function () {
        this.selectedItemsArray = [];
        this.allItemsSelected = false;
        var action = new EditableListActionModel(EditableListActionKind.SELECTION_CHANGE);
        this.sendAction(action);
    };
    ListActionsService.prototype.isSelected = function (item) {
        return (this.selectedItemsArray.indexOf(item) !== -1);
    };
    ListActionsService.prototype.subscribe = function (listener) {
        return this.subject.asObservable().subscribe(listener);
    };
    ListActionsService.prototype.sendAction = function (action) {
        this.subject.next(action);
    };
    return ListActionsService;
}());
export { ListActionsService };
