import { Injectable } from '@angular/core';
import { BenefitEligibilityRuleConfigurationVariable } from '../../models';
import * as _ from 'lodash';
import {
  BenefitEligibilityRuleStatementGroupItem,
  BenefitEligibilityRuleVariable,
} from '../../models/benefit-eligibility-rules/benefit-eligibility-rule-model';

@Injectable({
  providedIn: 'root',
})
export class BenefitEligibilityRuleStatementsManagementService {
  constructor() {}

  public findConfigurationVariable(
    variableId: string,
    configurationVariables: BenefitEligibilityRuleConfigurationVariable[]
  ) {
    if (!configurationVariables || !variableId) {
      return null;
    }

    const variable = _.find(
      configurationVariables,
      (variable: BenefitEligibilityRuleConfigurationVariable) => variable.id === variableId
    );

    return variable;
  }

  public insertRuleStatementBeforeItem(
    item: BenefitEligibilityRuleStatementGroupItem,
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[],
    isInsertedBeforeFirstItem: boolean
  ): BenefitEligibilityRuleStatementGroupItem[] {
    if (!item) {
      throw new Error('Item cannot be null');
    }

    const itemIndex = _.indexOf(ruleStatements, item);
    if (itemIndex >= 0) {
      const newRuleStatements = this.insertRuleStatementByIndex(itemIndex, ruleStatements, isInsertedBeforeFirstItem);

      return newRuleStatements;
    }

    const newRuleStatements = _.map(ruleStatements, (ruleStatement) => {
      if (_.isEmpty(ruleStatement.groups)) {
        return ruleStatement;
      }

      const newGroups = this.insertRuleStatementBeforeItem(item, ruleStatement.groups, isInsertedBeforeFirstItem);
      if (_.isEqual(newGroups, ruleStatement.groups)) {
        return ruleStatement;
      }

      return new BenefitEligibilityRuleStatementGroupItem({
        ...ruleStatement,
        groups: newGroups,
      });
    });

    return newRuleStatements;
  }

  public insertRuleStatementByIndex(
    index: number,
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[],
    isInsertedBeforeFirstItem: boolean
  ): BenefitEligibilityRuleStatementGroupItem[] {
    if (index < 0 || !ruleStatements || index >= ruleStatements.length) {
      throw new Error('Wrong index');
    }

    const newRuleStatement = new BenefitEligibilityRuleStatementGroupItem({
      sequence: index,
      variable: new BenefitEligibilityRuleVariable(),
    });

    const newRuleStatements = [...ruleStatements.slice(0, index), newRuleStatement, ...ruleStatements.slice(index)];

    let sequence = isInsertedBeforeFirstItem ? 0 : 1;
    let newRuleStatementsWithOrdering = _.map(newRuleStatements, (ruleStatement) => {
      return new BenefitEligibilityRuleStatementGroupItem({
        ...ruleStatement,
        sequence: sequence++,
      });
    });

    return newRuleStatementsWithOrdering;
  }

  public addRuleStatement(
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[]
  ): BenefitEligibilityRuleStatementGroupItem[] {
    const lastSequenceNumber = ruleStatements.length > 0 ? ruleStatements[ruleStatements.length - 1].sequence : 0;

    const newRuleStatements = [
      ...ruleStatements,
      new BenefitEligibilityRuleStatementGroupItem({
        sequence: lastSequenceNumber + 1,
        variable: new BenefitEligibilityRuleVariable(),
      }),
    ];

    return newRuleStatements;
  }

  public removeRuleStatementGroupItem(
    ruleStatement: BenefitEligibilityRuleStatementGroupItem,
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[]
  ): BenefitEligibilityRuleStatementGroupItem[] {
    const rulesAfterRemoveItem = this.removeRuleStatement(ruleStatement, ruleStatements);
    const rulesWithUpdatedSequenceNumbers = this.updateSequenceNumbers(rulesAfterRemoveItem, 0);

    const rulesWithoutFirstItem = _.without(rulesWithUpdatedSequenceNumbers, rulesWithUpdatedSequenceNumbers[0]);
    const firstRule = this.setConditionTypeNullToFirstItem(rulesWithUpdatedSequenceNumbers[0]);
    return [firstRule, ...rulesWithoutFirstItem];
  }

  removeRuleStatement(
    ruleStatement: BenefitEligibilityRuleStatementGroupItem,
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[]
  ): BenefitEligibilityRuleStatementGroupItem[] {
    let newRuleStatements = [];
    _.forEach(ruleStatements, (statement) => {
      if (statement === ruleStatement && _.isEmpty(statement.groups)) {
        return;
      }

      // if not a group
      if (_.isEmpty(statement.groups)) {
        newRuleStatements = [...newRuleStatements, statement];
        return;
      }

      // if group
      const newRuleStatementsGroups = this.removeRuleStatement(ruleStatement, statement.groups);
      if (newRuleStatementsGroups.length === 1) {
        newRuleStatements = [...newRuleStatements, ...newRuleStatementsGroups];
        return;
      }

      newRuleStatements = [
        ...newRuleStatements,
        new BenefitEligibilityRuleStatementGroupItem({
          ...statement,
          groups: newRuleStatementsGroups,
        }),
      ];
    });

    return newRuleStatements;
  }

  updateSequenceNumbers(
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[],
    parentSequenceNumber: number
  ): BenefitEligibilityRuleStatementGroupItem[] {
    let sequence = parentSequenceNumber === 0 ? 0 : 1;
    const newRuleStatements = _.reduce(
      ruleStatements,
      (newRuleStatements, ruleStatement) => {
        if(ruleStatement.isGroupItem) {
          const groupsWithNewSequence = this.updateSequenceNumbers(ruleStatement.groups, sequence);
          const ruleStatementWithNewSequence = new BenefitEligibilityRuleStatementGroupItem({
            ...ruleStatement,
            sequence: sequence,
            groups: groupsWithNewSequence
          });
          sequence++;
          return [...newRuleStatements, ruleStatementWithNewSequence];
        }
        const ruleStatementWithNewSequence = new BenefitEligibilityRuleStatementGroupItem({
          ...ruleStatement,
          sequence: sequence,
        });
        sequence++;
        return [...newRuleStatements, ruleStatementWithNewSequence];
      },
      []
    );

    return newRuleStatements;
  }

  public isItemBelogsToFirstGroup(
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[],
    ruleStatement: BenefitEligibilityRuleStatementGroupItem
  ): boolean {
    if (_.isEmpty(ruleStatements) || _.isNull(ruleStatements)) {
      return false;
    }

    const firstItem = _.first(ruleStatements);
    if (firstItem.isGroupItem) {
      const foundItem = _.find(firstItem.groups, ruleStatement);
      if (foundItem) {
        return true;
      }
      return this.isItemBelogsToFirstGroup(firstItem.groups, ruleStatement);
    }
    return _.isEqual(firstItem, ruleStatement);
  }

  public getFlatRuleStatements(
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[]
  ): BenefitEligibilityRuleStatementGroupItem[] {
    const flatRuleStatements = _.reduce(
      ruleStatements,
      (flatRuleStatements, statement) => {
        if (statement.groups && statement.groups.length > 0) {
          const statementGroups = this.getFlatRuleStatements(statement.groups);
          flatRuleStatements = [...flatRuleStatements, ...statementGroups];
        } else {
          flatRuleStatements.push(statement);
        }

        return flatRuleStatements;
      },
      []
    );

    return flatRuleStatements;
  }

  public getTiersGroupCount(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]): number {
    let maxGroupsCount = 0;
    _.forEach(ruleStatements, (statement) => {
      if (statement.groups && statement.groups.length > 0) {
        const count = this.getTiersGroupCount(statement.groups);
        maxGroupsCount = Math.max(maxGroupsCount, count + 1);
      }
    });

    return maxGroupsCount;
  }

  reInitializeItemSelections(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]): boolean {
    let isAllItemsSelected = true;

    _.forEach(ruleStatements, (ruleStatement: BenefitEligibilityRuleStatementGroupItem) => {
      if (!_.isEmpty(ruleStatement.groups)) {
        const isAllChildrenSelected = this.reInitializeItemSelections(ruleStatement.groups);

        ruleStatement.itemSelected = isAllChildrenSelected;
      }

      isAllItemsSelected = ruleStatement.itemSelected ? isAllItemsSelected : false;
    });

    return isAllItemsSelected;
  }

  getTotalGroupsSelectedItemsCount(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    if (ruleStatements) {
      const selectedRuleStatements = this.getTierSelectedRuleStatements(ruleStatements);
      const selectedItemsCount = selectedRuleStatements.length;

      const childrenSelectedItemsCount = _.reduce(
        ruleStatements,
        (totalCount, ruleStatement) => {
          if (!_.isEmpty(ruleStatement.groups) && !ruleStatement.itemSelected) {
            const selectedItemsCount = this.getTotalGroupsSelectedItemsCount(ruleStatement.groups);
            totalCount += selectedItemsCount;
          }
          return totalCount;
        },
        0
      );

      return selectedItemsCount + childrenSelectedItemsCount;
    } else {
      return 0;
    }
  }

  getTotalPlainSelectedItemsCount(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    if (ruleStatements) {
      const selectedRuleStatements = _.filter(
        ruleStatements,
        (statement) => statement.itemSelected && _.isEmpty(statement.groups)
      );
      const selectedItemsCount = selectedRuleStatements.length;

      const childrenSelectedItemsCount = _.reduce(
        ruleStatements,
        (totalCount, ruleStatement) => {
          if (!_.isEmpty(ruleStatement.groups)) {
            const selectedItemsCount = this.getTotalPlainSelectedItemsCount(ruleStatement.groups);
            totalCount += selectedItemsCount;
          }
          return totalCount;
        },
        0
      );

      return selectedItemsCount + childrenSelectedItemsCount;
    } else {
      return 0;
    }
  }

  getTotalExternalSelectedGroupsWithItemsOnlyCount(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    if (ruleStatements) {
      const selectedGroupsRuleStatements = _.filter(
        ruleStatements,
        (statement) => statement.itemSelected && !_.isEmpty(statement.groups)
      );
      const selectedItemsCount = selectedGroupsRuleStatements.length;

      if (selectedItemsCount > 0) {
        return selectedItemsCount;
      }
      const childrenSelectedItemsCount = _.reduce(
        ruleStatements,
        (totalCount, ruleStatement) => {
          if (!_.isEmpty(ruleStatement.groups)) {
            const selectedGroupsItemsCount = this.getTotalExternalSelectedGroupsWithItemsOnlyCount(
              ruleStatement.groups
            );
            totalCount += selectedGroupsItemsCount;
          }
          return totalCount;
        },
        0
      );

      return selectedItemsCount + childrenSelectedItemsCount;
    } else {
      return 0;
    }
  }

  getTotalItemsInSelectedGroupsOnlyCount(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    if (ruleStatements) {
      const childrenSelectedItemsCount = _.reduce(
        ruleStatements,
        (totalCount, ruleStatement) => {
          if (!_.isEmpty(ruleStatement.groups)) {
            const selectedGroupsRuleStatements = _.filter(
              ruleStatement.groups,
              (statement) => statement.itemSelected && _.isEmpty(statement.groups) && ruleStatement.itemSelected
            );
            const selectedItemsCount = selectedGroupsRuleStatements.length;

            const selectedGroupsItemsCount =
              selectedItemsCount + this.getTotalItemsInSelectedGroupsOnlyCount(ruleStatement.groups);
            totalCount += selectedGroupsItemsCount;
          }
          return totalCount;
        },
        0
      );

      return childrenSelectedItemsCount;
    } else {
      return 0;
    }
  }

  getTierSelectedRuleStatements(
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[]
  ): BenefitEligibilityRuleStatementGroupItem[] {
    const selectedRuleStatements = _.filter(ruleStatements, (statement) => statement.itemSelected);

    return selectedRuleStatements;
  }

  tryToUnionRuleStatementsIntoGroup(
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[]
  ): BenefitEligibilityRuleStatementGroupItem[] {
    const totalSelectedItemsCount = this.getTotalGroupsSelectedItemsCount(ruleStatements);
    if (totalSelectedItemsCount === 0) {
      return ruleStatements;
    }

    const newRuleStatements = this.tryToUnionRuleStatementsIntoGroupWithTotal(totalSelectedItemsCount, ruleStatements);

    return newRuleStatements;
  }

  deselectRuleStatements(ruleStatements: BenefitEligibilityRuleStatementGroupItem[]) {
    _.forEach(ruleStatements, (ruleStatement) => {
      if (!_.isEmpty(ruleStatement.groups)) {
        this.deselectRuleStatements(ruleStatement.groups);
      }
      ruleStatement.itemSelected = false;
    });
  }

  public ungroupRuleStatement(
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[],
    ungroupItem: BenefitEligibilityRuleStatementGroupItem
  ): BenefitEligibilityRuleStatementGroupItem[] {
    const newRuleStatements = _.reduce(
      ruleStatements,
      (newRuleStatements, statement) => {
        if (statement === ungroupItem) {
          return [...newRuleStatements, ...statement.groups];
        }

        if (!statement.isGroupItem) {
          return [...newRuleStatements, statement];
        }

        const childRuleStatement = this.ungroupRuleStatement(statement.groups, ungroupItem);
        if (_.isEqual(childRuleStatement, statement.groups)) {
          return [...newRuleStatements, statement];
        }

        const newGroup = new BenefitEligibilityRuleStatementGroupItem({
          ...statement,
          groups: childRuleStatement,
        });

        return [...newRuleStatements, newGroup];
      },
      []
    );

    return newRuleStatements;
  }

  tryToUnionRuleStatementsIntoGroupWithTotal(
    totalSelectedItemsCount: number,
    ruleStatements: BenefitEligibilityRuleStatementGroupItem[]
  ): BenefitEligibilityRuleStatementGroupItem[] {
    const selectedRuleStatements = this.getTierSelectedRuleStatements(ruleStatements);
    if (selectedRuleStatements.length === 0) {
      const newRuleStatements = _.reduce(
        ruleStatements,
        (newRuleStatements, ruleStatement) => {
          // if not a group
          if (!ruleStatement.isGroupItem) {
            return [...newRuleStatements, ruleStatement];
          }

          // if a group
          let newRuleStatement: BenefitEligibilityRuleStatementGroupItem;
          const newGroupRuleStatement = this.tryToUnionRuleStatementsIntoGroupWithTotal(
            totalSelectedItemsCount,
            ruleStatement.groups
          );
          if (_.isEqual(newGroupRuleStatement, ruleStatement.groups)) {
            newRuleStatement = ruleStatement;
          } else {
            newRuleStatement = new BenefitEligibilityRuleStatementGroupItem({
              ...ruleStatement,
              groups: newGroupRuleStatement,
            });
          }

          return [...newRuleStatements, newRuleStatement];
        },
        []
      );

      return newRuleStatements;
    }

    if (selectedRuleStatements.length > 0) {
      if (selectedRuleStatements.length !== totalSelectedItemsCount) {
        throw new Error('Groups can not intersect each other.');
      }
      const firstSelectedRuleStatements = _.head(selectedRuleStatements);

      let sequence = 0;
      const newRuleStatements = _.reduce(
        ruleStatements,
        (newRuleStatements, ruleStatement) => {
          if (firstSelectedRuleStatements === ruleStatement) {
            const newGroup = new BenefitEligibilityRuleStatementGroupItem({
              sequence: sequence,
              groups: selectedRuleStatements,
              itemSelected: true,
              conditionType: firstSelectedRuleStatements.conditionType,
            });
            sequence++;
            return [...newRuleStatements, newGroup];
          }

          if (!ruleStatement.itemSelected) {
            const ruleStatementWithNewSequence = new BenefitEligibilityRuleStatementGroupItem({
              ...ruleStatement,
              sequence: sequence,
            });
            sequence++;
            return [...newRuleStatements, ruleStatementWithNewSequence];
          }

          // skip selected
          return newRuleStatements;
        },
        []
      );

      return newRuleStatements;
    }
  }

  setConditionTypeNullToFirstItem(ruleStatement: BenefitEligibilityRuleStatementGroupItem) {
    if (ruleStatement.sequence !== 0) {
      return ruleStatement;
    }
    let firstGroupItem: BenefitEligibilityRuleStatementGroupItem;
    let groups: BenefitEligibilityRuleStatementGroupItem[];
    if (ruleStatement.isGroupItem) {
      const groupsWithoutFirstItem = _.without(ruleStatement.groups, ruleStatement.groups[0]);
      firstGroupItem = this.setConditionTypeNullToFirstItem(ruleStatement.groups[0]);
      groups = [firstGroupItem, ...groupsWithoutFirstItem];
    }

    const newRuleStatement = new BenefitEligibilityRuleStatementGroupItem({
      ...ruleStatement,
      conditionType: null,
      groups: groups,
    });
    return newRuleStatement;
  }
}
