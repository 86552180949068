import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { NgModel } from '@angular/forms';
import { wcConfig } from '../../../workers-compensation.config';
import { appConfig } from '../../../../../app.config';
var WcIncidentReportComponent = /** @class */ (function () {
    function WcIncidentReportComponent(management) {
        this.management = management;
        this.subscriptions = {};
        this.filesCount = 0;
        this.m_minimumDate = moment(wcConfig.settings.minDateString).toDate();
        this.m_maximumDate = moment(appConfig.maxCorrectDate).toDate();
    }
    Object.defineProperty(WcIncidentReportComponent.prototype, "isPristineIncidentDate", {
        get: function () {
            if (this.originalReport && this.report) {
                if (this.originalReport.incidentDateTime && this.report.incidentDateTime) {
                    return this.originalReport.incidentDateTime.getTime() === this.report.incidentDateTime.getTime();
                }
                else if (!this.originalReport.incidentDateTime && !this.report.incidentDateTime) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentReportComponent.prototype, "isPristineReportDate", {
        get: function () {
            if (this.originalReport && this.report) {
                if (this.originalReport.reportDateTime && this.report.reportDateTime) {
                    return this.originalReport.reportDateTime.getTime() === this.report.reportDateTime.getTime();
                }
                else if (!this.originalReport.reportDateTime && !this.report.reportDateTime) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentReportComponent.prototype, "minDate", {
        get: function () {
            return this.m_minimumDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentReportComponent.prototype, "maxDate", {
        get: function () {
            return this.m_maximumDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentReportComponent.prototype, "reportDateReadonly", {
        get: function () {
            if (!this.report.editMode) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcIncidentReportComponent.prototype, "incidentDateTimeReadonly", {
        get: function () {
            if (!this.report.editMode) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    WcIncidentReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.load = this.management.subscribeToReportLoad(function (x) {
            _this.originalReport = x;
            _this.report = x;
            _this.updateFileCount();
            _this.management.setIncidentDateTime(_this.report.incidentDateTime);
        });
        this.subscriptions.fileChanged = this.management.subscribeToFileChanged(function (x) {
            _this.updateFileCount();
        });
    };
    WcIncidentReportComponent.prototype.ngOnDestroy = function () { };
    WcIncidentReportComponent.prototype.onIncidentDateChanged = function (value) {
        if (this.incidentDatetime && this.reportDatetime) {
            this.management.setMainFormValidity(this.incidentDatetime.valid && this.reportDatetime.valid);
            this.management.setIncidentDateTime(this.report.incidentDateTime);
        }
    };
    WcIncidentReportComponent.prototype.onReportDateChanged = function (value) {
        if (this.incidentDatetime && this.reportDatetime) {
            this.management.setMainFormValidity(this.incidentDatetime.valid && this.reportDatetime.valid);
        }
    };
    WcIncidentReportComponent.prototype.updateFileCount = function () {
        this.filesCount = _.size(this.management.getAttachments()) + _.size(this.management.getFilesToSave());
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcIncidentReportComponent.prototype, "subscriptions", void 0);
    return WcIncidentReportComponent;
}());
export { WcIncidentReportComponent };
