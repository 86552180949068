import * as moment from 'moment';
import * as _ from 'lodash';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { appConfig, IApplicationConfig } from '../../../../../app.config';

@Component({
    moduleId: module.id,
    selector: 'slx-scheduled-shift-notes-section',
    templateUrl: 'scheduled-shift-notes-section.component.html',
    styleUrls: [
        'scheduled-shift-notes-section.component.scss'
    ]
})
export class ScheduledShiftNotesSectionComponent implements OnInit {

    @Input()
    public notes: string;

    @Input()
    public index: number = -1;

    @Output()
    public onNoteChanged: EventEmitter<string>;

    public appConfig: IApplicationConfig;

    constructor() {
        this.appConfig = appConfig;
        this.onNoteChanged = new EventEmitter<string>();
    }

    public ngOnInit(): void {
        _.noop();
    }

    public notesChanged(): void {
        this.onNoteChanged.next(this.notes);
    }

}
