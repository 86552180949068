import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig, IApplicationConfig } from '../../../../app.config';
import { SummarySectionBase } from '../summary-section-base/summary-section.base';
import { TimecardsSummaryManagementService } from '../../../services/index';
import { TimecardSummaryPeriodCompare } from '../../../models/index';
import { AggregateDescriptor, aggregateBy } from '@progress/kendo-data-query';

@Component({
    moduleId: module.id,
    selector: 'slx-periods-summary',
    templateUrl: 'periods-summary.component.html',
    styleUrls: ['periods-summary.component.scss']
})
export class PeriodsSummaryComponent extends SummarySectionBase implements OnInit, OnDestroy {

    public appConfig: IApplicationConfig;
    @ViewChild('kendoGrid', {static: true})
    public grid: GridComponent;

    public aggregates: AggregateDescriptor[];
    public totals: StringMap<any>;

    public percentFormat: string ='0.00"%"';
    public dollarFormat = '$0.00';
    public numberFormat = '0.00';

    constructor(management: TimecardsSummaryManagementService) {
        super(management);
        this.appConfig = appConfig;
        this.aggregates = [
            { field: 'productiveHours', aggregate: 'sum' }
            ,{ field: 'nonProductiveHours', aggregate: 'sum' }
            ,{ field: 'totalHours', aggregate: 'sum' }
            ,{ field: 'differenceHours', aggregate: 'sum' }
            ,{ field: 'percentageHours', aggregate: 'sum' }
            ,{ field: 'payrollAmount', aggregate: 'sum' }
            ,{ field: 'differenceAmount', aggregate: 'sum' }
            ,{ field: 'differencePercentage', aggregate: 'sum' }
        ];
    }

    public ngOnInit(): void {
        this.setupManagementService();
    }

    public ngOnDestroy(): void {
      this.destroy();
    }

    public checkHrsPtcDifference(item: TimecardSummaryPeriodCompare, diff: number): boolean {
        return Math.abs(item.percentageHours) > diff;
    }

    public checkAmountsPtcDifference(item: TimecardSummaryPeriodCompare, diff: number): boolean {
        return Math.abs(item.differencePercentage) > diff;
    }

    protected onDataLoaded(): void {
        _.noop();
    }

    protected refreshGrid (): void {
        super.refreshGrid();
        this.totals = aggregateBy(this.container.records, this.aggregates);
    }

    public onExcelExport(e: any): void {
      const rows = e.workbook.sheets[0].rows;
      const cells = _.filter(rows, { type: 'footer' });
      _.forEach(_.head(cells).cells, (cell) => {
        const value = parseFloat(cell.value);
        if (_.isFinite(value)) {
          cell.value = value;
        }
      });
    }
}
