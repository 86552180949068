import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { KendoGridStateHelper } from '../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { MessagesManagementService } from '../../../app-modules/message-center/services/messages-management.service';
import { mutableSelect, unsubscribe, unsubscribeAll } from '../../../../app/core/decorators';
import { Observable, Subscription } from 'rxjs';
import { MessageNotificationService } from "../../services/notifications/msg-notifications.service";
import { NotificationsApiService } from '../../services';
import { MessageCenterConfigService } from '../../../../../src/app/organization/services/index';
var MessageCenterNotificationListComponent = /** @class */ (function () {
    function MessageCenterNotificationListComponent(route, messagesManagementService, msgNotificationService, messageCenterConfigService, notificationsApiService) {
        this.route = route;
        this.messagesManagementService = messagesManagementService;
        this.msgNotificationService = msgNotificationService;
        this.messageCenterConfigService = messageCenterConfigService;
        this.notificationsApiService = notificationsApiService;
        this.records = [];
        this.closeModal = new EventEmitter();
        this.orglevelId = 1;
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
    }
    MessageCenterNotificationListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.orgLevel && (orgLevel.id !== _this.orgLevel.id)) || !_this.orgLevel)) {
                _this.orgLevel = orgLevel;
                _this.orglevelId = orgLevel.id;
            }
        });
        this.subscriptions.alert = this.notificationsApiService.subcribeToNewmessageAlert(function (res) {
            if (!_this.messagesManagementService.isMsgCenterLoaded) {
                _this.messagesManagementService.getRecentMessageByEmployeeId(res.employeeId, _this.isMyMessage);
            }
        });
        this.messagesManagementService.getPopUpMessages(this.orglevelId, this.isMyMessage);
        this.messageSubscription = this.messagesManagementService.subscribeToPopUpMessages(function (res) {
            _this.records = res;
            _this.refreshGrid();
            _this.isLoading = false;
        });
        this.msgNotificationService.subscribeToMessageStatusUpdate((function (rowData) {
            _this.isLoading = false;
            _this.closeModal.emit(true);
            _this.route.navigate([_this.messageCenterConfigService.GetMessageCenterUrl()]);
            setTimeout(function () {
                _this.messagesManagementService.changeActiveTab();
                _this.messagesManagementService.updateMessageThread(rowData, _this.orglevelId, true, _this.isMyMessage);
                _this.messagesManagementService.updateMessageCount(_this.messageCount);
            }, 700);
            _this.records.filter(function (x) { return x.id === rowData.id; }).map(function (y) { return y.read = true; });
        }));
        this.subscriptions.msg = this.messagesManagementService.subscribeToRecentMessage((function (res) {
            var index = _this.records.findIndex(function (x) { return x.employeeId === res.employeeId; });
            if (index > -1) {
                _this.records.splice(index, 1);
            }
            var obj = {
                id: res['id'],
                employeeId: res['employeeId'],
                created: res['created'],
                modified: res['modified'],
                body: res['body'],
                replyCount: res['replyCount'],
                mobilePhoneNumber: res['mobilePhoneNumber'],
                optIn: res['optIn'],
                orgLevelId: res['orgLevelId'],
                photo: res['photo'],
                status: res['status'],
                isOnLeave: res['isOnLeave'],
                fullName: res['employeeName'],
                employeeName: res['employeeName'],
            };
            _this.records.unshift(obj);
            _this.refreshGrid();
        }));
    };
    MessageCenterNotificationListComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    // API call to update status of Messages
    MessageCenterNotificationListComponent.prototype.updateReadStatus = function (rowData) {
        this.isLoading = true;
        this.msgNotificationService.getMessageStatusUpdate(rowData);
    };
    MessageCenterNotificationListComponent.prototype.redirectToMSGCenter = function () {
        this.closeModal.emit(true);
        this.route.navigate([this.messageCenterConfigService.GetMessageCenterUrl()]);
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], MessageCenterNotificationListComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MessageCenterNotificationListComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], MessageCenterNotificationListComponent.prototype, "messageSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], MessageCenterNotificationListComponent.prototype, "subscriptions", void 0);
    return MessageCenterNotificationListComponent;
}());
export { MessageCenterNotificationListComponent };
