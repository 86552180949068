import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core';
import { GridOptions, CellEvent } from 'ag-grid-community';
import { Aca1095cGridHelper } from './aca-1095-c-grid-helper';
import { acaQuickPopupState, aca1095CPopupAction, Aca1095CPopupInitData, EmployeeIdList, Aca1095cActions } from '../../../models';
import { Aca1095cManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../core/decorators';
import { Router, ActivatedRoute } from '@angular/router';
import { EmployeeSectionNavigationService } from '../../../../common';
import { appConfig } from '../../../../app.config';
import { ACA1095cConfig } from '../../../aca-1095.config';
var Aca1095cGridComponent = /** @class */ (function () {
    function Aca1095cGridComponent(managementService, router, route) {
        this.managementService = managementService;
        this.router = router;
        this.route = route;
        this.columnList = [];
        this.subscriptions = {};
        this.gridHelper = new Aca1095cGridHelper();
        this.gridHelper.state = {
            year: 2020,
            numMonths: 12,
            organizationColShown: false,
            departmentColShown: false,
            positionColShown: false,
            dateHiredColShown: false,
            dateTerminatedColShown: false,
            benefitDeclinedColShown: false,
            purchaseMarketplaceColShown: false
        };
        this.gridOptions = {};
        this.gridOptions.context = {
            thisComponent: this
        };
        this.getRowHeight = function (params) {
            if (params.data.expand) {
                return 100;
            }
            else {
                return 28;
            }
        };
    }
    Aca1095cGridComponent.prototype.refresh = function () {
        return false;
    };
    Aca1095cGridComponent.prototype.agInit = function (params) {
        this.appConfig = appConfig;
        this.params = params;
    };
    Aca1095cGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.gridHelper.monthLinkArray();
        this.gridHelper.createColumnDefs();
        this.subscriptions.onLoaded = this.managementService
            .subscribeToLoadedRecords(function (container) {
            _this.records = container.records;
            _this.gridHelper.monthLinkArray();
            if (!_this.actions) {
                _this.actions = new Aca1095cActions();
                _this.actions = container.actions;
            }
            _this.storedRecords = _.clone(container.records);
            _this.refreshGrid();
        });
        this.subscriptions.employeeFilter = this.managementService
            .subscribeToEmpFilterRecords(function (employeeFilter) { return _this.applyFilter(employeeFilter); });
        this.subscriptions.quickEditSelectedData = this.managementService
            .subscribeToQuickEditSelectedData(function (selectedData) {
            _this.quickEditSelectedData = selectedData;
        });
        this.subscriptions.undoQuickRecords = this.managementService
            .subscribeToUndoQuickRecord(function (item) {
            _this.undoQuickRecords(item);
        });
        this.subscriptions.onLoaded = this.managementService
            .subscribeToEmployeeFilter(function (records) {
            _this.applyFilter(records.filter);
        });
        this.subscriptions.export = this.managementService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.expand = this.managementService
            .subscribeToExpandAll(function (isExpand) { return _this.expandAll(isExpand); });
        this.subscriptions.quickPopupState = this.managementService
            .subscribeToQuickPopupState(function (quickPopupState) {
            _this.quickPopupState = quickPopupState;
            switch (quickPopupState) {
                case acaQuickPopupState.quickEditSave:
                case acaQuickPopupState.quickConfirmSave:
                case acaQuickPopupState.quickEditResetSave:
                case acaQuickPopupState.quickConfirmResetSave:
                    {
                        var records = _this.getQuickRecordForSave(quickPopupState);
                        _this.managementService.submitQuick1095cRecords(records, quickPopupState);
                        break;
                    }
                case acaQuickPopupState.quickEditStart:
                case acaQuickPopupState.quickEditResetStart:
                case acaQuickPopupState.quickConfirmStart:
                case acaQuickPopupState.quickConfirmResetStart:
                    {
                        break;
                    }
                case acaQuickPopupState.quickEditClear:
                case acaQuickPopupState.quickEditClearAndClose:
                case acaQuickPopupState.quickEditResetClear:
                case acaQuickPopupState.quickEditResetClearAndClose:
                case acaQuickPopupState.quickConfirmClear:
                case acaQuickPopupState.quickConfirmClearAndClose:
                case acaQuickPopupState.quickConfirmResetClear:
                case acaQuickPopupState.quickConfirmResetClearAndClose:
                    {
                        _this.clearQuick1095cRecords(quickPopupState);
                        break;
                    }
                case acaQuickPopupState.quickConfirmClose:
                case acaQuickPopupState.quickEditClose:
                    {
                        _this.updateMonthLinkState("All", false);
                        break;
                    }
            }
        });
        this.subscriptions.quickMonthAction = this.managementService
            .subscribeToQuickMonthAction(function (monthAction) {
            if (_this.getIsQuickPopupActive()) {
                var selectedMonth = null;
                var confirmRecordCount_1 = 0;
                var notConfirmRecordCount_1 = 0;
                var recordHaveNoCoverage_1 = 0;
                var recordNotEdited_1 = 0;
                var monthName_1 = monthAction.name;
                var isLink_1 = monthAction.value;
                if (_this.gridApi) {
                    _this.gridApi.forEachNode(function (node) {
                        var monthsRecords = node.data.months;
                        _.forEach(monthsRecords, function (selectedMonth) {
                            if ((selectedMonth.monthName == monthName_1) || monthName_1 == 'All') {
                                confirmRecordCount_1 += (selectedMonth.confirmedInd) ? 1 : 0;
                                recordHaveNoCoverage_1 += (!selectedMonth.selectedCode && !selectedMonth.suggestedCode) ? 1 : 0;
                                notConfirmRecordCount_1 += (!selectedMonth.confirmedInd) ? 1 : 0;
                                recordNotEdited_1 += (!selectedMonth.confirmedInd && (!selectedMonth.selectedCode && !selectedMonth.selectedHarbor && !selectedMonth.selectedCost)) ? 1 : 0;
                            }
                        });
                    });
                }
                if (isLink_1) {
                    if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickEditStart) && confirmRecordCount_1 > 0) {
                        _this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit__record_already_confirmed"]);
                        return;
                    }
                    else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickEditResetStart) && confirmRecordCount_1 > 0) {
                        _this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__record_already_confirmed"]);
                        return;
                    }
                    else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickEditResetStart) && recordNotEdited_1 > 0) {
                        _this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__not_edited"]);
                        return;
                    }
                    else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickConfirmStart) && recordHaveNoCoverage_1 > 0) {
                        _this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__line14_blank"]);
                        return;
                    }
                    else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickConfirmStart) && confirmRecordCount_1 > 0) {
                        _this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__record_already_confirmed"]);
                        return;
                    }
                    else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickConfirmResetStart) && notConfirmRecordCount_1 > 0) {
                        _this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm_reset__record_not_confirmed"]);
                        return;
                    }
                }
                _this.updateMonthLinkState(monthName_1, isLink_1);
                if (_this.gridApi) {
                    _this.gridApi.forEachNode(function (node) {
                        var nodeData = _.clone(node.data);
                        var monthsRecords = nodeData.months;
                        if (monthName_1 == 'All') {
                            node.data.link = isLink_1;
                        }
                        _this.updateMonthRecords(isLink_1, monthName_1, monthsRecords);
                    });
                    _this.gridHelper.linkMonths[monthName_1] = isLink_1;
                    _this.gridApi.expandAll();
                }
            }
        });
    };
    Aca1095cGridComponent.prototype.getIsQuickPopupActive = function () {
        var quickPopupState = this.quickPopupState;
        var popupState = false;
        if (_.isEqual(quickPopupState, acaQuickPopupState.quickEditStart)) {
            popupState = true;
        }
        else if (_.isEqual(quickPopupState, acaQuickPopupState.quickEditResetStart)) {
            popupState = true;
        }
        else if (_.isEqual(quickPopupState, acaQuickPopupState.quickConfirmStart)) {
            popupState = true;
        }
        else if (_.isEqual(quickPopupState, acaQuickPopupState.quickConfirmResetStart)) {
            popupState = true;
        }
        else {
            popupState = false;
        }
        return popupState;
    };
    Aca1095cGridComponent.prototype.undoQuickRecords = function (item) {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.forEachNode(function (node) {
                if (_.isEqual(node.data.companyId, item.companyId) && _.isEqual(node.data.employeeMasterId, item.employeeMasterId)) {
                    if (item.months.length == 12) {
                        node.data.link = false;
                    }
                    var monthsRecords = node.data.months;
                    _.forEach(monthsRecords, function (month) {
                        if (_.filter(item.months, function (undoMonth) { return (_.isEqual(undoMonth.monthName, month.monthName)); }).length > 0) {
                            month.quickConfirm = false;
                            month.quickConfirmReset = false;
                            month.quickEditReset = false;
                            _this.undoQuickEdit(month);
                        }
                    });
                }
            });
            if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickEditStart)) {
                this.setQuickEditRecord();
            }
            else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickEditResetStart)) {
                this.setQuickEditResetRecord();
            }
            else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickConfirmStart)) {
                this.setQuickConfirmRecord();
            }
            else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickConfirmResetStart)) {
                this.setQuickConfirmResetRecord();
            }
            this.gridApi.expandAll();
        }
    };
    Aca1095cGridComponent.prototype.updateMonthRecords = function (isLink, monthName, monthsRecords) {
        var _this = this;
        _.forEach(monthsRecords, function (selectedMonth) {
            if ((selectedMonth.monthName == monthName) || monthName == 'All') {
                if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickEditStart)) {
                    if (!isLink) {
                        _this.undoQuickEdit(selectedMonth);
                    }
                    else {
                        _this.applyQuickEdit(selectedMonth);
                    }
                    _this.setQuickEditRecord();
                }
                else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickEditResetStart)) {
                    if (!isLink) {
                        selectedMonth.quickEditReset = false;
                    }
                    else {
                        selectedMonth.quickEditReset = true;
                    }
                    _this.setQuickEditResetRecord();
                }
                else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickConfirmStart)) {
                    if (!isLink) {
                        selectedMonth.quickConfirm = false;
                    }
                    else {
                        selectedMonth.quickConfirm = true;
                    }
                    _this.setQuickConfirmRecord();
                }
                else if (_.isEqual(_this.quickPopupState, acaQuickPopupState.quickConfirmResetStart)) {
                    if (!isLink) {
                        selectedMonth.quickConfirmReset = false;
                    }
                    else {
                        selectedMonth.quickConfirmReset = true;
                    }
                    _this.setQuickConfirmResetRecord();
                }
            }
        });
    };
    Aca1095cGridComponent.prototype.updateMonthLinkState = function (monthName, isLink) {
        if (monthName == 'All') {
            for (var month = 0; month < 12; month++) {
                this.gridHelper.linkMonths[moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM')] = isLink;
            }
        }
    };
    Aca1095cGridComponent.prototype.clearGridChangedRecords = function (quickPopuState) {
        var _this = this;
        if (this.gridApi) {
            this.gridApi.forEachNode(function (node) {
                var monthsRecords = node.data.months;
                if (node.data.link) {
                    node.data.link = false;
                }
                _.forEach(monthsRecords, function (month) {
                    var selectedMonth = month;
                    switch (quickPopuState) {
                        case acaQuickPopupState.quickEditClearAndClose:
                        case acaQuickPopupState.quickEditClear:
                            {
                                if (selectedMonth.quickEdit) {
                                    _this.undoQuickEdit(selectedMonth);
                                }
                                break;
                            }
                        case acaQuickPopupState.quickEditResetClearAndClose:
                        case acaQuickPopupState.quickEditResetClear:
                            {
                                if (selectedMonth.quickEditReset) {
                                    selectedMonth.quickEditReset = false;
                                }
                                break;
                            }
                        case acaQuickPopupState.quickConfirmClearAndClose:
                        case acaQuickPopupState.quickConfirmClear:
                            {
                                if (selectedMonth.quickConfirm) {
                                    selectedMonth.quickConfirm = false;
                                }
                                break;
                            }
                        case acaQuickPopupState.quickConfirmResetClearAndClose:
                        case acaQuickPopupState.quickConfirmResetClear:
                            {
                                if (selectedMonth.quickConfirmReset) {
                                    selectedMonth.quickConfirmReset = false;
                                }
                                break;
                            }
                        default: {
                            //statements; 
                            break;
                        }
                    }
                });
            });
            this.gridApi.expandAll();
        }
    };
    Aca1095cGridComponent.prototype.clearQuick1095cRecords = function (quickPopupState) {
        switch (quickPopupState) {
            case acaQuickPopupState.quickEditClearAndClose: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickEditRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickEditClose);
                break;
            }
            case acaQuickPopupState.quickEditResetClearAndClose: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickEditResetRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickEditClose);
                break;
            }
            case acaQuickPopupState.quickEditClear: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickEditRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickEditResetActive);
                break;
            }
            case acaQuickPopupState.quickEditResetClear: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickEditResetRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickEditActive);
                break;
            }
            case acaQuickPopupState.quickConfirmClearAndClose: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickConfirmRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
                break;
            }
            case acaQuickPopupState.quickConfirmResetClearAndClose: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickConfirmResetRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
                break;
            }
            case acaQuickPopupState.quickConfirmClear: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickConfirmRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmResetActive);
                break;
            }
            case acaQuickPopupState.quickConfirmResetClear: {
                this.clearGridChangedRecords(quickPopupState);
                this.setQuickConfirmResetRecord();
                this.managementService.setQuickPopupState(acaQuickPopupState.quickConfirmActive);
                break;
            }
            default: {
                //statements; 
                break;
            }
        }
    };
    Aca1095cGridComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    Aca1095cGridComponent.prototype.applyFilter = function (employeeFilter) {
        this.selectedEmployees = employeeFilter;
        this.refreshGrid();
    };
    Aca1095cGridComponent.prototype.refreshGrid = function () {
        var _this = this;
        var records = _.clone(this.storedRecords);
        if (this.selectedEmployees && this.selectedEmployees.length > 0) {
            this.records = _.filter(records, function (r) {
                var res = false;
                _.forEach(_this.selectedEmployees, function (employee) {
                    res = res || _.isEqual(r.employeeId, employee.id);
                });
                return res;
            });
        }
        else {
            this.records = records;
        }
        if (this.records) {
            this.rowData = this.records;
        }
    };
    Aca1095cGridComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.subscriptions.settings = this.managementService.onSettingsChanged$.subscribe(function (settings) {
            _this.gridHelper.state.organizationColShown = settings.columns.columnsMap['organization'] ? settings.columns.columnsMap['organization'].displayed : false;
            _this.gridHelper.state.departmentColShown = settings.columns.columnsMap['department'] ? settings.columns.columnsMap['department'].displayed : false;
            _this.gridHelper.state.positionColShown = settings.columns.columnsMap['position'] ? settings.columns.columnsMap['position'].displayed : false;
            _this.gridHelper.state.dateHiredColShown = settings.columns.columnsMap['hireDate'] ? settings.columns.columnsMap['hireDate'].displayed : false;
            _this.gridHelper.state.dateTerminatedColShown = settings.columns.columnsMap['terminationDate'] ? settings.columns.columnsMap['terminationDate'].displayed : false;
            _this.gridHelper.state.benefitDeclinedColShown = settings.columns.columnsMap['benefitDeclined'] ? settings.columns.columnsMap['benefitDeclined'].displayed : false;
            _this.gridHelper.state.purchaseMarketplaceColShown = settings.columns.columnsMap['purchasedMarketplace'] ? settings.columns.columnsMap['purchasedMarketplace'].displayed : false;
            _this.setColumnsState('organization', _this.gridHelper.state.organizationColShown);
            _this.setColumnsState('department', _this.gridHelper.state.departmentColShown);
            _this.setColumnsState('position', _this.gridHelper.state.positionColShown);
            _this.setColumnsState('hireDate', _this.gridHelper.state.dateHiredColShown);
            _this.setColumnsState('terminationDate', _this.gridHelper.state.dateTerminatedColShown);
            _this.setColumnsState('benefitDeclined', _this.gridHelper.state.benefitDeclinedColShown);
            _this.setColumnsState('purchasedMarketplace', _this.gridHelper.state.purchaseMarketplaceColShown);
            _this.columnList = [];
            _this.columnList.push('employeeName');
            if (_this.gridHelper.state.dateHiredColShown) {
                _this.columnList.push('hireDate');
            }
            if (_this.gridHelper.state.organizationColShown) {
                _this.columnList.push('organization');
            }
            if (_this.gridHelper.state.departmentColShown) {
                _this.columnList.push('department');
            }
            if (_this.gridHelper.state.positionColShown) {
                _this.columnList.push('position');
            }
            if (_this.gridHelper.state.dateTerminatedColShown) {
                _this.columnList.push('terminationDate');
            }
            if (_this.gridHelper.state.benefitDeclinedColShown) {
                _this.columnList.push('benefitDeclined');
            }
            if (_this.gridHelper.state.purchaseMarketplaceColShown) {
                _this.columnList.push('purchasedMarketplace');
            }
        });
    };
    Aca1095cGridComponent.prototype.setColumnsState = function (fieldName, isColShown) {
        if (this.gridOptions.columnApi) {
            this.gridOptions.columnApi.setColumnVisible(fieldName, isColShown);
        }
    };
    Aca1095cGridComponent.prototype.exportTo = function (isPdf) {
        var monthColumnIds = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        var columnkey = _.unionBy(this.columnList, monthColumnIds);
        var params = {
            skipHeader: false,
            columnGroups: false,
            skipFooters: false,
            skipGroups: false,
            skipFloatingTop: false,
            skipFloatingBottom: false,
            allColumns: false,
            onlySelected: false,
            suppressQuotes: false,
            columnKeys: columnkey,
            fileName: 'ACA_1095-C_Reporting_Export.xlsx',
            columnSeparator: ',',
            processHeaderCallback: function (params) {
                if (params.column && params.column.colDef) {
                    var val = params.column.colDef.headerName;
                    if (val && val.indexOf('main-date-header-text') !== -1) {
                        return val.replace(/<[^>]*>/g, '');
                    }
                    return val;
                }
            },
            processCellCallback: function (params) {
                var colId = params.column.getColDef().field;
                var zipValue = "";
                if (colId === 'hireDate' || colId === 'terminationDate') {
                    return moment(params.value).format(appConfig.dateFormat);
                }
                else if (monthColumnIds.indexOf(colId) > -1) {
                    var monthsRecords = params.node.data.months;
                    var selectedMonth = null;
                    var monthName_2 = colId;
                    var selectedMonthRecords = _.filter(monthsRecords, function (monthsRecord) {
                        if (monthsRecord.monthName == monthName_2) {
                            return monthsRecord;
                        }
                    });
                    if (selectedMonthRecords.length > 0) {
                        selectedMonth = selectedMonthRecords[0];
                    }
                    if (selectedMonth) {
                        if (_.includes(ACA1095cConfig.line14ValueForLine17, selectedMonth.displayCode)) {
                            zipValue = 'Zip: ' + selectedMonth.selZip;
                        }
                        return selectedMonth.displayCode + ' ' + selectedMonth.displayCost + ' ' + selectedMonth.displayHarbor + '\n' + selectedMonth.displayOption + '\n' + selectedMonth.displayTier + '\n' + zipValue;
                    }
                    else
                        return;
                }
                else {
                    return params.value;
                }
            }
        };
        this.gridOptions.api.exportDataAsExcel(params);
    };
    Aca1095cGridComponent.prototype.expandAll = function (isExpand) {
        _.forEach(this.records, function (item) {
            if (isExpand) {
                item.expand = true;
            }
            else {
                item.expand = false;
            }
        });
        this.managementService.setEmployeeGridExpandCollapse(isExpand);
        if (this.gridApi) {
            this.gridApi.expandAll();
            this.gridApi.resetRowHeights();
        }
    };
    Aca1095cGridComponent.prototype.setExpandedList = function (state, empId) {
        var employee = new EmployeeIdList();
        employee.employeeId = empId;
        employee.expand = state;
        this.managementService.setEmployeeIdList(employee);
    };
    Aca1095cGridComponent.prototype.getQuickRecordForSave = function (popupState) {
        var record = [];
        this.gridApi.forEachNode(function (node) {
            var nodeData = _.clone(node.data);
            var monthsRecords = nodeData.months;
            var selectedMonthRecords = [];
            switch (popupState) {
                case acaQuickPopupState.quickEditSave:
                    {
                        selectedMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickEdit); });
                        break;
                    }
                case acaQuickPopupState.quickConfirmSave:
                    {
                        selectedMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickConfirm); });
                        break;
                    }
                case acaQuickPopupState.quickEditResetSave:
                    {
                        selectedMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickEditReset); });
                        break;
                    }
                case acaQuickPopupState.quickConfirmResetSave:
                    {
                        selectedMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickConfirmReset); });
                        break;
                    }
            }
            if (selectedMonthRecords.length > 0) {
                nodeData.months = selectedMonthRecords;
                selectedMonthRecords = [];
                record.push(nodeData);
            }
        });
        return record;
    };
    Aca1095cGridComponent.prototype.setQuickEditRecord = function () {
        var record = [];
        this.gridApi.forEachNode(function (node) {
            var nodeData = _.clone(node.data);
            var monthsRecords = nodeData.months;
            var EditMonthRecords = [];
            EditMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickEdit); });
            if (EditMonthRecords.length > 0) {
                nodeData.months = EditMonthRecords;
                EditMonthRecords = [];
                record.push(nodeData);
            }
        });
        this.managementService.setQuickEditRecords(record);
    };
    Aca1095cGridComponent.prototype.setQuickEditResetRecord = function () {
        var record = [];
        this.gridApi.forEachNode(function (node) {
            var nodeData = _.clone(node.data);
            var monthsRecords = nodeData.months;
            var resetMonthRecords = [];
            resetMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickEditReset); });
            if (resetMonthRecords.length > 0) {
                nodeData.months = resetMonthRecords;
                resetMonthRecords = [];
                record.push(nodeData);
            }
        });
        this.managementService.setQuickEditResetRecords(record);
    };
    Aca1095cGridComponent.prototype.setQuickConfirmRecord = function () {
        var record = [];
        this.gridApi.forEachNode(function (node) {
            var nodeData = _.clone(node.data);
            var monthsRecords = nodeData.months;
            var confirmMonthRecords = [];
            confirmMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickConfirm); });
            if (confirmMonthRecords.length > 0) {
                nodeData.months = confirmMonthRecords;
                confirmMonthRecords = [];
                record.push(nodeData);
            }
        });
        this.managementService.setQuickConfirmRecords(record);
    };
    Aca1095cGridComponent.prototype.setQuickConfirmResetRecord = function () {
        var record = [];
        this.gridApi.forEachNode(function (node) {
            var nodeData = _.clone(node.data);
            var monthsRecords = nodeData.months;
            var confirmResetMonthRecords = [];
            confirmResetMonthRecords = _.filter(monthsRecords, function (month) { return (month.quickConfirmReset); });
            if (confirmResetMonthRecords.length > 0) {
                nodeData.months = confirmResetMonthRecords;
                confirmResetMonthRecords = [];
                record.push(nodeData);
            }
        });
        this.managementService.setQuickConfirmResetRecords(record);
    };
    Aca1095cGridComponent.prototype.onCellDoubleClick = function ($event) {
        var selectedYear = this.managementService.getSelectedYear();
        var isEditableYear = ACA1095cConfig.isEditableYear(selectedYear);
        if (isEditableYear) {
            var monthArray = [];
            for (var month = 0; month < 12; month++) {
                monthArray.push(moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'));
            }
            if (_.filter(monthArray, function (month) { return (_.isEqual(month, $event.column.getColDef().field)); }).length > 0) {
                var monthsRecords = $event.node.data.months;
                var selectedMonth = null;
                var monthName_3 = $event.column.getColDef().field;
                var selectedMonthRecords = _.filter(monthsRecords, function (monthsRecord) {
                    if (monthsRecord.monthName == monthName_3) {
                        return monthsRecord;
                    }
                });
                if (selectedMonthRecords.length > 0) {
                    selectedMonth = selectedMonthRecords[0];
                }
                if (!selectedMonth.confirmedInd) {
                    this.selectedCellData = selectedMonth;
                    var acaPopupInitData = new Aca1095CPopupInitData();
                    acaPopupInitData = new Aca1095CPopupInitData();
                    acaPopupInitData.popupAction = aca1095CPopupAction.edit1095Record;
                    acaPopupInitData.params = this.selectedCellData;
                    acaPopupInitData.companyId = $event.node.data.companyId;
                    acaPopupInitData.employeeId = $event.node.data.employeeId;
                    acaPopupInitData.employeeMasterId = $event.node.data.employeeMasterId;
                    this.managementService.init1095Popup(acaPopupInitData);
                }
            }
        }
    };
    Aca1095cGridComponent.prototype.applyQuickEdit = function (selectedMonth) {
        if (!selectedMonth.quickEdit) {
            selectedMonth.edited_selectedHarbor = selectedMonth.selectedHarbor;
            selectedMonth.edited_selectedCode = selectedMonth.selectedCode;
            selectedMonth.edited_selectedCost = selectedMonth.selectedCost;
            selectedMonth.edited_enrolledInd = selectedMonth.enrolledInd;
            selectedMonth.edited_reason = selectedMonth.reason;
            selectedMonth.selectedHarbor = this.quickEditSelectedData.selectedHarbor;
            selectedMonth.selectedCode = this.quickEditSelectedData.selectedCode;
            selectedMonth.selectedCost = this.quickEditSelectedData.selectedCost;
            selectedMonth.enrolledInd = this.quickEditSelectedData.enrolledInd;
            selectedMonth.reason = this.quickEditSelectedData.reason;
            selectedMonth.quickEdit = true;
        }
    };
    Aca1095cGridComponent.prototype.undoQuickEdit = function (selectedMonth) {
        if (selectedMonth.quickEdit) {
            selectedMonth.selectedHarbor = selectedMonth.edited_selectedHarbor;
            selectedMonth.selectedCode = selectedMonth.edited_selectedCode;
            selectedMonth.selectedCost = selectedMonth.edited_selectedCost;
            selectedMonth.enrolledInd = selectedMonth.edited_enrolledInd;
            selectedMonth.reason = selectedMonth.edited_reason;
            selectedMonth.edited_selectedCode = "";
            selectedMonth.edited_selectedCost = "";
            selectedMonth.edited_selectedHarbor = "";
            selectedMonth.edited_reason = "";
            selectedMonth.edited_enrolledInd = false;
            selectedMonth.quickEdit = false;
        }
    };
    Aca1095cGridComponent.prototype.onCellClicked = function ($event) {
        var _this = this;
        var pageNumber = this.gridApi.paginationGetCurrentPage();
        if ($event.column.getColDef().field === 'employeeName') {
            var empId = $event.node.data.employeeId;
            if (empId !== null && empId !== undefined)
                if (parseInt(empId) === 0) {
                    return;
                }
            var parsedEmpId = parseInt(empId, 10);
            var navService = new EmployeeSectionNavigationService(this.router, this.route);
            var urlTree = navService.getUrlTreeFromRoot(parsedEmpId, true);
            var extras = {
                skipLocationChange: false,
                replaceUrl: false,
            };
            this.router.navigateByUrl(urlTree, extras);
        }
        else if ($event.column.getColDef().field === 'expand') {
            if ($event.node.data.expand == false) {
                $event.node.data.expand = true;
                this.setExpandedList(true, $event.node.data.employeeId);
            }
            else {
                $event.node.data.expand = false;
                this.setExpandedList(false, $event.node.data.employeeId);
            }
            if (this.gridApi) {
                this.gridApi.expandAll();
                this.gridApi.resetRowHeights();
            }
        }
        else if ($event.column.getColDef().field === 'link') {
            var monthsRecords = $event.node.data.months;
            if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickEditStart)) {
                if ($event.node.data.link) {
                    _.forEach(monthsRecords, function (month) {
                        var selectedMonth = month;
                        if (selectedMonth.quickEdit) {
                            _this.undoQuickEdit(selectedMonth);
                        }
                    });
                    $event.node.data.link = false;
                    this.setQuickEditRecord();
                }
                else {
                    var acaMonthRecords = _.filter(monthsRecords, function (month) { return (month.confirmedInd); });
                    if (acaMonthRecords.length == 0) {
                        _.forEach(monthsRecords, function (month) {
                            var selectedMonth = month;
                            if (!selectedMonth.quickEdit) {
                                _this.applyQuickEdit(selectedMonth);
                            }
                        });
                        $event.node.data.link = true;
                        this.setQuickEditRecord();
                    }
                    else {
                        this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit__record_already_confirmed"]);
                    }
                }
            }
            if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickEditResetStart)) {
                if ($event.node.data.link) {
                    _.forEach(monthsRecords, function (month) {
                        var selectedMonth = month;
                        if (selectedMonth.quickEditReset) {
                            selectedMonth.quickEditReset = false;
                        }
                    });
                    $event.node.data.link = false;
                    this.setQuickEditResetRecord();
                }
                else {
                    var acaMonthRecords = _.filter(monthsRecords, function (month) { return (month.confirmedInd); });
                    var acaMonthNotEditedRecords = _.filter(monthsRecords, function (month) { return (!month.confirmedInd && (!month.selectedCode && !month.selectedHarbor && !month.selectedCost)); });
                    if (acaMonthRecords.length == 0 && acaMonthNotEditedRecords.length == 0) {
                        _.forEach(monthsRecords, function (month) {
                            var selectedMonth = month;
                            if (!selectedMonth.quickEditReset) {
                                selectedMonth.quickEditReset = true;
                            }
                        });
                        $event.node.data.link = true;
                        this.setQuickEditResetRecord();
                    }
                    else {
                        if (acaMonthRecords.length > 0) {
                            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__record_already_confirmed"]);
                        }
                        else if (acaMonthNotEditedRecords.length > 0) {
                            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__not_edited"]);
                        }
                    }
                }
            }
            else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickConfirmStart)) {
                if ($event.node.data.link) {
                    _.forEach(monthsRecords, function (month) {
                        var selectedMonth = month;
                        if (selectedMonth.quickConfirm) {
                            selectedMonth.quickConfirm = false;
                        }
                    });
                    $event.node.data.link = false;
                    this.setQuickConfirmRecord();
                }
                else {
                    var acaMonthRecords = _.filter(monthsRecords, function (month) { return (!month.selectedCode && !month.suggestedCode); });
                    var acaMonthConfirmRecords = _.filter(monthsRecords, function (month) { return (month.confirmedInd); });
                    if (acaMonthRecords.length == 0 && acaMonthConfirmRecords.length == 0) {
                        _.forEach(monthsRecords, function (month) {
                            var selectedMonth = month;
                            if (!selectedMonth.quickConfirm) {
                                selectedMonth.quickConfirm = true;
                            }
                        });
                        $event.node.data.link = true;
                        this.setQuickConfirmRecord();
                    }
                    else {
                        if (acaMonthRecords.length > 0) {
                            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__line14_blank"]);
                        }
                        else if (acaMonthConfirmRecords.length > 0) {
                            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__record_already_confirmed"]);
                        }
                    }
                }
            }
            else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickConfirmResetStart)) {
                if ($event.node.data.link) {
                    _.forEach(monthsRecords, function (month) {
                        var selectedMonth = month;
                        if (selectedMonth.quickConfirmReset) {
                            selectedMonth.quickConfirmReset = false;
                        }
                    });
                    $event.node.data.link = false;
                    this.setQuickConfirmResetRecord();
                }
                else {
                    var acaMonthRecords = _.filter(monthsRecords, function (month) { return (!month.confirmedInd); });
                    if (acaMonthRecords.length == 0) {
                        _.forEach(monthsRecords, function (month) {
                            var selectedMonth = month;
                            if (!selectedMonth.quickConfirmReset) {
                                selectedMonth.quickConfirmReset = true;
                            }
                        });
                        $event.node.data.link = true;
                        this.setQuickConfirmResetRecord();
                    }
                    else {
                        this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm_reset__record_not_confirmed"]);
                    }
                }
            }
            if (this.gridApi) {
                this.gridApi.expandAll();
            }
        }
        else {
            var monthArray = [];
            for (var month = 0; month < 12; month++) {
                monthArray.push(moment(moment(moment().startOf('year').toDate()).add(month, 'months')).format('MMM'));
            }
            if (_.filter(monthArray, function (month) { return (_.isEqual(month, $event.column.getColDef().field)); }).length > 0) {
                var monthsRecords = $event.node.data.months;
                var selectedMonth = null;
                var monthName_4 = $event.column.getColDef().field;
                var selectedMonthRecords = _.filter(monthsRecords, function (monthsRecord) {
                    if (monthsRecord.monthName == monthName_4) {
                        return monthsRecord;
                    }
                });
                if (selectedMonthRecords.length > 0) {
                    selectedMonth = selectedMonthRecords[0];
                }
                if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickEditStart)) {
                    if (selectedMonth.quickEdit) {
                        this.undoQuickEdit(selectedMonth);
                        this.gridApi.expandAll();
                        this.setQuickEditRecord();
                    }
                    else {
                        if (!selectedMonth.confirmedInd) {
                            this.applyQuickEdit(selectedMonth);
                            this.gridApi.expandAll();
                            this.setQuickEditRecord();
                        }
                        else {
                            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit__record_already_confirmed"]);
                        }
                    }
                }
                else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickEditResetStart)) {
                    if (selectedMonth.quickEditReset) {
                        selectedMonth.quickEditReset = false;
                        this.gridApi.expandAll();
                        this.setQuickEditResetRecord();
                    }
                    else {
                        var acaMonthNotEditedRecords = _.filter(monthsRecords, function (month) { return (!month.confirmedInd && (!month.selectedCode && !month.selectedHarbor && !month.selectedCost)); });
                        if (!selectedMonth.confirmedInd && (selectedMonth.selectedCode || selectedMonth.selectedHarbor || selectedMonth.selectedCost)) {
                            selectedMonth.quickEditReset = true;
                            this.gridApi.expandAll();
                            this.setQuickEditResetRecord();
                        }
                        else {
                            if (selectedMonth.confirmedInd) {
                                this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__record_already_confirmed"]);
                            }
                            else if (!selectedMonth.confirmedInd && (!selectedMonth.selectedCode && !selectedMonth.selectedCost && !selectedMonth.selectedHarbor)) {
                                this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickedit_reset__not_edited"]);
                            }
                        }
                    }
                }
                else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickConfirmStart)) {
                    if ((selectedMonth.suggestedCode || selectedMonth.selectedCode) && !selectedMonth.confirmedInd) {
                        if (selectedMonth.quickConfirm) {
                            selectedMonth.quickConfirm = false;
                        }
                        else {
                            selectedMonth.quickConfirm = true;
                        }
                        this.gridApi.expandAll();
                        this.setQuickConfirmRecord();
                    }
                    else {
                        if (!selectedMonth.suggestedCode && !selectedMonth.selectedCode) {
                            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__line14_blank"]);
                        }
                        else if (selectedMonth.confirmedInd) {
                            this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm__record_already_confirmed"]);
                        }
                    }
                }
                else if (_.isEqual(this.quickPopupState, acaQuickPopupState.quickConfirmResetStart)) {
                    if (selectedMonth.confirmedInd) {
                        if (selectedMonth.quickConfirmReset) {
                            selectedMonth.quickConfirmReset = false;
                        }
                        else {
                            selectedMonth.quickConfirmReset = true;
                        }
                        this.gridApi.expandAll();
                        this.setQuickConfirmResetRecord();
                    }
                    else {
                        this.managementService.setShowPopupMessage(ACA1095cConfig.messageArray["quickconfirm_reset__record_not_confirmed"]);
                    }
                }
                else {
                    this.selectedCellData = selectedMonth;
                    this.managementService.setQuickEditInitData(this.selectedCellData);
                }
            }
        }
    };
    Aca1095cGridComponent.prototype.onGridReady = function (params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    Aca1095cGridComponent.prototype.getContextMenuItems = function (params) {
        var result = [];
        var selectedYear = params.context.thisComponent.managementService.getSelectedYear();
        var isEditableYear = ACA1095cConfig.isEditableYear(selectedYear);
        if (params.context.thisComponent.actions.addEmployee && isEditableYear) {
            result.push({
                name: 'Add Employee',
                action: function () {
                    params.context.thisComponent.managementService.isAddRemoveEmployee();
                }
            });
        }
        if (params.context.thisComponent.actions.deleteEmployee && isEditableYear) {
            result.push({
                name: 'Remove Employee',
                action: function () {
                    params.context.thisComponent.managementService.isAddRemoveEmployee(params.node.data);
                }
            });
        }
        if (params.context.thisComponent.actions.edit1095c && isEditableYear) {
            result.push({
                name: 'Quick Edit',
                action: function () {
                    var acaPopupInitData = new Aca1095CPopupInitData();
                    acaPopupInitData = new Aca1095CPopupInitData();
                    acaPopupInitData.popupAction = aca1095CPopupAction.quickEditRecord;
                    var monthsRecords = params.node.data.months;
                    var selectedMonth = null;
                    var monthName = params.column.getColDef().field;
                    var selectedMonthRecords = _.filter(monthsRecords, function (monthsRecord) {
                        if (monthsRecord.monthName == monthName) {
                            return monthsRecord;
                        }
                    });
                    if (selectedMonthRecords.length > 0) {
                        selectedMonth = selectedMonthRecords[0];
                    }
                    acaPopupInitData.params = selectedMonth;
                    params.context.thisComponent.managementService.init1095Popup(acaPopupInitData);
                }
            });
        }
        return result;
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], Aca1095cGridComponent.prototype, "subscriptions", void 0);
    return Aca1095cGridComponent;
}());
export { Aca1095cGridComponent };
