import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { ClientMessageManagementService } from '../../services/client-message/client-message-management.service';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
var ClientMessageComponent = /** @class */ (function () {
    function ClientMessageComponent(managementService) {
        this.managementService = managementService;
    }
    ClientMessageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadedSubscription = this.managementService.onLoaded$.subscribe(function (messages) {
            _this.messages = messages;
        });
    };
    ClientMessageComponent.prototype.closeMessage = function (msg) {
        this.managementService.readMessage(msg);
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], ClientMessageComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ClientMessageComponent.prototype, "loadedSubscription", void 0);
    return ClientMessageComponent;
}());
export { ClientMessageComponent };
