import * as _ from 'lodash';
import { EventEmitter, AfterViewChecked } from '@angular/core';
import { NgControl } from '@angular/forms';
var Types = /** @class */ (function () {
    function Types() {
    }
    Types.required = 'required';
    Types.min = 'min';
    Types.max = 'max';
    return Types;
}());
var NumberComponent = /** @class */ (function () {
    function NumberComponent(ngControl) {
        this.step = 1;
        this.decimals = 0;
        this.format = 'n';
        this.autoCorrect = false;
        this.required = false;
        this.isDisabled = false;
        this.placeholder = '';
        this.autofocus = false;
        this.spinners = false;
        this.blur = new EventEmitter();
        this.focus = new EventEmitter();
        this.autofocused = false;
        this.ngControl = ngControl;
        ngControl.valueAccessor = this;
    }
    NumberComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCallback = fn;
    };
    NumberComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCallback = fn;
    };
    NumberComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        if (this.autofocus && !this.autofocused && this.kendoNumber) {
            this.autofocused = true;
            setTimeout(function () {
                _this.kendoNumber.focus();
            }, 0);
        }
    };
    NumberComponent.prototype.onValueChanged = function (value) {
        if (this.isValid(Types.required, value)) {
            var isValidMin = this.isValid(Types.min, value);
            var isValidMax = this.isValid(Types.max, value);
            if (!this.autoCorrect && (!isValidMin || !isValidMax)) {
                this.setError(!isValidMin ? 'min' : 'max');
                return;
            }
            this.currentValue = value;
            this.resetError();
        }
        else if (this.required) {
            this.setError('required');
        }
        this.onChangeCallback(value);
    };
    NumberComponent.prototype.writeValue = function (value) {
        if (value && typeof value === 'string') {
            value = _.toNumber(value);
        }
        if (this.isValid(Types.required, value)) {
            var isValidMin = this.isValid(Types.min, value);
            var isValidMax = this.isValid(Types.max, value);
            if (!this.autoCorrect && (!isValidMin || !isValidMax)) {
                this.setError(!isValidMin ? 'min' : 'max');
                return;
            }
            this.currentValue = value;
            this.resetError();
        }
        else {
            this.currentValue = null;
            if (this.required) {
                this.setError('required');
            }
        }
    };
    NumberComponent.prototype.onBlur = function (value) {
        this.blur.emit(value);
    };
    NumberComponent.prototype.onFocus = function (value) {
        this.focus.emit(value);
    };
    Object.defineProperty(NumberComponent.prototype, "hasPlaceholder", {
        get: function () {
            return _.isString(this.placeholder) && _.size(this.placeholder) > 0;
        },
        enumerable: true,
        configurable: true
    });
    NumberComponent.prototype.isValid = function (type, value) {
        switch (type) {
            case Types.required:
                return _.isNumber(value);
            case Types.min:
                var min = +this.min;
                return _.isNaN(min) ? true : value >= min;
            case Types.max:
                var max = +this.max;
                return _.isNaN(max) ? true : value <= max;
            default:
                return false;
        }
    };
    NumberComponent.prototype.setError = function (errName) {
        var _a;
        if (this.ngControl.control) {
            this.ngControl.control.setErrors((_a = {}, _a[errName] = true, _a));
        }
    };
    NumberComponent.prototype.resetError = function () {
        if (this.ngControl.control) {
            this.ngControl.control.setErrors(null);
        }
    };
    return NumberComponent;
}());
export { NumberComponent };
