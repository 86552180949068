import { OnInit, EventEmitter } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
var KendoGridMobileManagerDirective = /** @class */ (function () {
    function KendoGridMobileManagerDirective(grid) {
        this.grid = grid;
        this.resizeEvent = new EventEmitter();
    }
    Object.defineProperty(KendoGridMobileManagerDirective.prototype, "isMobile", {
        get: function () {
            return (screen.width <= appConfig.mobileMaxWidth);
        },
        enumerable: true,
        configurable: true
    });
    KendoGridMobileManagerDirective.prototype.ngOnInit = function () {
        this.manageGrid();
    };
    KendoGridMobileManagerDirective.prototype.onResize = function (event) {
        this.manageGrid();
        this.resizeEvent.emit({ isMobile: this.isMobile, grid: this.grid });
    };
    KendoGridMobileManagerDirective.prototype.manageGrid = function () {
        if (this.isMobile) {
            this.grid.groupable = this.slxGroupableMobile;
            return;
        }
        this.grid.groupable = this.slxGroupableMobile;
    };
    return KendoGridMobileManagerDirective;
}());
export { KendoGridMobileManagerDirective };
