export interface IPayCycleApprovalRequest {
  startDate: Date;
  endDate: Date;
  employeeIds: number[];
}

export class PayCycleApprovalRequest {
  public startDate: string;
  public endDate: string;
  public employeeIds: number[] = [];
}

export interface ITimecardApprovalRequest {
  payCycles : IPayCycleApprovalRequest[];
}


export class TimecardApprovalRequest {
  public payCycles : PayCycleApprovalRequest[] = [];
}
