import * as _ from 'lodash';
import { Provider } from '@angular/core';
import { appConfig } from '../../../../../../../app/app.config';
import { DialogOptions2, DialogModeSize, ModalService, ConfirmDialog2Component, ConfirmOptions } from '../../../../../../../app/common/index';
import { EmployeeWarningBasic } from '../../../../models/index';
var EmployeeSectionsWarningsDialogComponent = /** @class */ (function () {
    function EmployeeSectionsWarningsDialogComponent(warning, dialogOptions, modalService) {
        this.warning = warning;
        this.dialogOptions = dialogOptions;
        this.modalService = modalService;
        this.dialogResult = null;
        this.appConfig = appConfig;
        this.showConfirmation = false;
    }
    Object.defineProperty(EmployeeSectionsWarningsDialogComponent.prototype, "canSave", {
        get: function () {
            return _.isObjectLike(this.warningExtended);
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsWarningsDialogComponent.openDialog = function (warning, modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.modeSize = DialogModeSize.grid;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: EmployeeWarningBasic,
                useValue: warning
            },
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            }
        ];
        return modalService.globalAnchor.openDialog2(EmployeeSectionsWarningsDialogComponent, 'Employee Warning Record', dialogOptions, resolvedProviders, function (res) {
            if (_.isFunction(callback)) {
                callback(res);
            }
        });
    };
    EmployeeSectionsWarningsDialogComponent.prototype.onChangedWarning = function (warning) {
        this.warningExtended = warning;
    };
    EmployeeSectionsWarningsDialogComponent.prototype.onEditedWarning = function (hasChanges) {
        this.showConfirmation = hasChanges;
    };
    EmployeeSectionsWarningsDialogComponent.prototype.onUserAction = function (isSave) {
        if (isSave || !this.showConfirmation) {
            this.closeWarning(isSave);
        }
        else {
            this.showConfirmationPopup();
        }
    };
    EmployeeSectionsWarningsDialogComponent.prototype.closeWarning = function (isSave) {
        this.dialogResult = isSave;
        this.modalService.closeWindow(this.dialogOptions.windowUniqueId);
    };
    EmployeeSectionsWarningsDialogComponent.prototype.showConfirmationPopup = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog('Cancel Editing', 'Are you sure that you want to cancel editing? You will lose all unsaved changes', this.modalService, function (result) {
            if (result) {
                _this.closeWarning(false);
            }
        }, options);
    };
    return EmployeeSectionsWarningsDialogComponent;
}());
export { EmployeeSectionsWarningsDialogComponent };
