/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./budget-dropdown-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../common/components/actions-list/actions-list-item/actions-list-item.component.ngfactory";
import * as i4 from "../../../common/components/actions-list/actions-list-item/actions-list-item.component";
import * as i5 from "ngx-popper";
import * as i6 from "angular2-moment/date-format.pipe";
import * as i7 from "../../../common/components/actions-list/actions-list.component.ngfactory";
import * as i8 from "../../../common/components/actions-list/actions-list.component";
import * as i9 from "../../../common/components/actions-list/actions-button/actions-button.component.ngfactory";
import * as i10 from "../../../common/components/actions-list/actions-button/actions-button.component";
import * as i11 from "../../../../../node_modules/ngx-popper/ngx-popper.ngfactory";
import * as i12 from "./budget-dropdown-selector.component";
import * as i13 from "../../services/lookup/lookup-api.service";
var styles_BudgetSelectorDropdownComponent = [i0.styles];
var RenderType_BudgetSelectorDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BudgetSelectorDropdownComponent, data: {} });
export { RenderType_BudgetSelectorDropdownComponent as RenderType_BudgetSelectorDropdownComponent };
function View_BudgetSelectorDropdownComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please select budget"]))], null, null); }
function View_BudgetSelectorDropdownComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.selectedBudgetDefinition.endDate, _co.appConfig.dateFormat)); _ck(_v, 1, 0, currVal_0); }); }
function View_BudgetSelectorDropdownComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", "-"])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BudgetSelectorDropdownComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.selectedBudgetDefinition.endDate; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.selectedBudgetDefinition.startDate, _co.appConfig.dateFormat)); _ck(_v, 1, 0, currVal_0); }); }
function View_BudgetSelectorDropdownComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.endDate, _co.appConfig.dateFormat)); _ck(_v, 1, 0, currVal_0); }); }
function View_BudgetSelectorDropdownComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "slx-actions-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onBudgetDefinitionSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ActionsListItemComponent_0, i3.RenderType_ActionsListItemComponent)), i1.ɵdid(1, 49152, null, 0, i4.ActionsListItemComponent, [i5.PopperContent], { className: [0, "className"] }, { onClick: "onClick" }), (_l()(), i1.ɵted(2, 0, [" ", " - "])), i1.ɵppd(3, 2), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BudgetSelectorDropdownComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedBudgetDefinition && (_co.selectedBudgetDefinition.id === _v.context.$implicit.id)) ? "active" : ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit.endDate; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), ((_v.context.$implicit == null) ? null : _v.context.$implicit.startDate), _co.appConfig.dateFormat)); _ck(_v, 2, 0, currVal_1); }); }
export function View_BudgetSelectorDropdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.DateFormatPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "selector-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Budget Start Date"])), (_l()(), i1.ɵted(-1, null, ["\u00A0\n"])), (_l()(), i1.ɵeld(4, 0, null, null, 15, "slx-actions-list", [], null, null, null, i7.View_ActionsListComponent_0, i7.RenderType_ActionsListComponent)), i1.ɵdid(5, 49152, null, 0, i8.ActionsListComponent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, 1, 6, "slx-actions-button", [["className", "auto-width"]], null, null, null, i9.View_ActionsButtonComponent_0, i9.RenderType_ActionsButtonComponent)), i1.ɵdid(7, 49152, null, 0, i10.ActionsButtonComponent, [], { popperContent: [0, "popperContent"], popperPosition: [1, "popperPosition"], customClassName: [2, "customClassName"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BudgetSelectorDropdownComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BudgetSelectorDropdownComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, 0, 0, "span", [["class", "caret"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, 1, 6, "popper-content", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onMouseOver() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).showOnLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i11.View_PopperContent_0, i11.RenderType_PopperContent)), i1.ɵdid(14, 180224, [["popperContent", 4]], 0, i5.PopperContent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "slx-actions-list-item", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onBudgetDefinitionAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ActionsListItemComponent_0, i3.RenderType_ActionsListItemComponent)), i1.ɵdid(16, 49152, null, 0, i4.ActionsListItemComponent, [i5.PopperContent], null, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, 0, ["Create New"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_BudgetSelectorDropdownComponent_4)), i1.ɵdid(19, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 14); var currVal_1 = "bottom-start"; var currVal_2 = "auto-width"; _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !_co.selectedBudgetDefinition; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.selectedBudgetDefinition; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co.budgetDefinitions; _ck(_v, 19, 0, currVal_5); }, null); }
export function View_BudgetSelectorDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-budget-dropdown-selector", [], null, null, null, View_BudgetSelectorDropdownComponent_0, RenderType_BudgetSelectorDropdownComponent)), i1.ɵdid(1, 245760, null, 0, i12.BudgetSelectorDropdownComponent, [i13.LookupApiService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BudgetSelectorDropdownComponentNgFactory = i1.ɵccf("slx-budget-dropdown-selector", i12.BudgetSelectorDropdownComponent, View_BudgetSelectorDropdownComponent_Host_0, { selectedBudgetDefinition: "selectedBudgetDefinition", ignoreOrgLevelChange: "ignoreOrgLevelChange" }, { budgetDefinitionSelected: "budgetDefinitionSelected", budgetDefinitionAdd: "budgetDefinitionAdd" }, []);
export { BudgetSelectorDropdownComponentNgFactory as BudgetSelectorDropdownComponentNgFactory };
