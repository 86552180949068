import { AbstractControl } from '@angular/forms';

export function dateRangeValidator(control: AbstractControl): {[key: string]: boolean} | null {
  const startDate = control.get('startDate').value;
  const endDate = control.get('endDate').value;

  if (startDate && endDate && startDate > endDate) {
    return { 'dateRangeInvalid': true };
  }

  return null;
}

export function carryOverTypeValidator(control: AbstractControl): {[key: string]: boolean} | null {
  const carryoverDay = control.value;

  if (!isCarryOverDayValid(carryoverDay)) {
    return { 'carryoverDayInvalid': true };
  }

  return null;
}

function isCarryOverDayValid(value:string): boolean {
  if(value && value.length > 0) {
    const regex = /(?:jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)(0[1-9]|[12]\d|3[01])/;
    return regex.test(value.toLowerCase());
  }
  return true;
}

