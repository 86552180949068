import { IExportDataExecutionItem, ExportDataExecutionItem } from '../export-data-execution';

export interface IAcaExportExecutionItem extends IExportDataExecutionItem {
  type: number;
  employerName: string;
  year: number;
  otherParameters: string;
  receiptId: string;
  employerId: number;
  memberOfAleGroup: number[];
  offeredMec: number[];
  qualifiedOfferMethod: boolean;
  offerMethod98: boolean;
}

export class AcaExportExecutionItem extends ExportDataExecutionItem {
  public type: number;
  public employerName: string;
  public year: number;
  public otherParameters: string;
  public receiptId: string;
  public exportTypeName: string;
  public employerId: number;
  public memberOfAleGroup: number[];
  public offeredMec: number[];
  public qualifiedOfferMethod: boolean;
  public offerMethod98: boolean;
}