import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { unsubscribeAll } from '../../../../core/decorators/index';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { DeviceDetectorService, EmployeeSectionNavigationService } from '../../../../common/services/index';
import { AccrualsTransactionsManagementService } from '../../services/accruals-transactions-management.service';
import { AppSettingsManageService } from './../../../../app-settings/services/index';
var AccrualsTransactionsGridComponent = /** @class */ (function () {
    function AccrualsTransactionsGridComponent(accrualManagementService, devDetector, appSettingsManageService, router, route) {
        this.accrualManagementService = accrualManagementService;
        this.devDetector = devDetector;
        this.appSettingsManageService = appSettingsManageService;
        this.router = router;
        this.route = route;
        this.pageSize = 50;
        this.xlsxName = 'Accruals_Transaction_';
        this.pdfName = 'Accruals_Transaction_';
        this.columnsGroupName = 'AccrualsTransaction';
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.skip = 0;
        this.gridState.state.take = this.pageSize;
        this.gridState.state.sort = [
            { field: 'transaction.date', dir: 'desc' },
            { field: 'employee.fullName', dir: 'asc' }
        ];
        this.appConfig = appConfig;
    }
    Object.defineProperty(AccrualsTransactionsGridComponent.prototype, "isLockedColumn", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AccrualsTransactionsGridComponent.prototype, "isReordable", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    AccrualsTransactionsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        var date = new Date().toLocaleDateString();
        this.xlsxName += date + ".xlsx";
        this.pdfName += date + ".pdf";
        this.getSettings();
        this.subscriptions.onLoaded = this.accrualManagementService
            .subscribeToLoadedRecords(function (r) { return _this.assignRecords(r); });
        this.subscriptions.export = this.accrualManagementService
            .subscribeToExport(function (isPDF) { return _this.exportTo(isPDF); });
        this.subscriptions.refresh = this.gridState.onRefreshGrid
            .subscribe(function () { return _this.refreshGrid(); });
    };
    AccrualsTransactionsGridComponent.prototype.ngOnDestroy = function () { };
    AccrualsTransactionsGridComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var config;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        config = _a.sent();
                        this.EnableRate4DecimalPlaces = config.EnableRate4DecimalPlaces;
                        if (this.EnableRate4DecimalPlaces) {
                            this.cells = {
                                format: '0.0000'
                            };
                        }
                        else {
                            this.cells = {
                                format: '0.00'
                            };
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(AccrualsTransactionsGridComponent.prototype, "isShowHighPrecision", {
        get: function () {
            return this.EnableRate4DecimalPlaces;
        },
        enumerable: true,
        configurable: true
    });
    AccrualsTransactionsGridComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.records, { sort: _this.gridState.state.sort, filter: _this.gridState.state.filter }).data
        }); };
    };
    AccrualsTransactionsGridComponent.prototype.onEmpNameClick = function (empId) {
        var navService = new EmployeeSectionNavigationService(this.router, this.route);
        var urlTree = navService.getUrlTreeFromRoot(empId, true);
        var extras = {
            skipLocationChange: false,
            replaceUrl: false,
        };
        this.router.navigateByUrl(urlTree, extras);
    };
    AccrualsTransactionsGridComponent.prototype.assignRecords = function (records) {
        this.records = records;
        this.refreshGrid();
    };
    AccrualsTransactionsGridComponent.prototype.exportTo = function (isPDF) {
        if (isPDF) {
            this.grid.saveAsPDF();
        }
        else {
            this.grid.saveAsExcel();
        }
    };
    AccrualsTransactionsGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AccrualsTransactionsGridComponent.prototype, "subscriptions", void 0);
    return AccrualsTransactionsGridComponent;
}());
export { AccrualsTransactionsGridComponent };
