export interface ITimeAndAttendanceConfig {
  settings: {
    export: {
      xlsx: {
        titleCell: {
          fontSize: number;
          borderBottom: {
            size: number;
            color: string;
          }
        };
        headerCell: {
          fontSize: number;
          color: string;
          background: string;
        };
        footerCell: {
          fontSize: number;
          color: string;
          background: string;
        };
        minColumnWidth: number;
      }
      pdf: {
        margins: {
          top: number;
          bottom: number;
          left: number;
          right: number;
        };
        inchInMm: number;
        baseDencity: number;
      }
    }
  };

  api: {
    root: string;
    orglevel: {
      root: string;
      budgets: string;
      budgetDefinitions: string;
    };
    attendancePoints: {
      root: string;
    }
    budgets: {
      root: string;
      restore: string;
      clone: string;
      delete: string;
      dummy: string;
    };
    budgetedGroups: {
      root: string;
      orgLevel: string;
      deleteSet: string;
    };
    arrivalsDepartures: {
      root: string;
      details: string;
      orgLevel: {
        root: string
      };
    };
    departuresDetails: {
      root: string;
      unscheduled: string;
      orgLevel: {
        root: string
      };
    };
    arrivalsDetails: {
      root: string;
      unscheduled: string;
      orgLevel: {
        root: string
      };
    };
    timeclockAssignments: {
      root: string;
      orgLevel: {
        root: string;
        assign: string;
        unassign: string;
      };
    };
    timeclock: {
      root: string;
      orgLevel: {
        root: string;
        rebootClock:string;
      };
    };
    timecards: {
      root: string;
      employee: {
        root: string;
        approve: string;
        unapprove: string;
        discardPayRules: string;
        discardPunches: string;
      };
      daily: {
        root: string;
        downloadDebug: string;
        lock: string;
        unlock: string;
      },
      dailyTimecardPunches: {
        root: string;
        downloadDebug: string;
        lock: string;
        unlock: string;
      },
      approveDailyTimecards: string;
      unapproveDailyTimecards: string;
      allabsence:string;
      orgLevel: {
        root: string;
        recalculateTimecards: string;
        approveTimecards: string;
        unapproveTimecards: string;
        unprocessedTimecards: string;
      };
    };
    exceptionConsole: {
      root: string;
      rollup: string;
      orgLevel: {
        root: string;
      };
    };
    dailyPunches: {
      root: string;
      headers: string;
      orgLevel: string;
      rollup: string;
      removeInvalidLogin: string;
      employee: {
        root: string;
      }
    };
    employeePunchRequest: {
      root: string,
      orgLevel: string,
      employee: {
        root: string
      }
    };
    dailyPunchLogs: string;
    configuration: {
      root: string;
      holidays: {
        root: string;
        copy: string;
      };
      attendancePoint: {
        root: string;
      };
    };
    supervisorAssignment: {
      root: string;
      supervisor: string;
      orglevel: {
        root: string;
        group: string;
        groups: string;
        employees: string;
        supervisors: string;
        assignSupervisorsToGroup: string;
        removeSupervisorsFromGroup: string;
        assignEmployeesToGroup: string;
        removeEmployeesFromGroup: string;
        unassignedEmployees: string;
      }
    };
    export: {
      root: string;
      download: {
        root: string;
      }
    },
    summary: {
      root: string;
      employee: string;
    },
    organization: {
      root: string;
      payroll: string;
      exports: string;
    }
    signalR: {
        root: string;
        signalRHub: string;
        signalRApi: string,
        groupName:string;
      }
  };
  timecardsHeaderHeight: number;
  retroFieldValue: string;
}

export const timeAndAttendanceConfig: ITimeAndAttendanceConfig = {
  settings: {
    export: {
      xlsx: {
        titleCell: {
          fontSize: 18, borderBottom: { size: 2, color: '#000000' }
        },
        headerCell: {
          fontSize: 14,
          color: '#ffffff',
          background: '#666666'
        },
        footerCell: {
          fontSize: 14,
          color: '#000000',
          background: '#e6e6e6'
        },
        minColumnWidth: 40,
      },
      pdf: {
        margins: {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10
        },
        inchInMm: 25.4,
        baseDencity: 72
      }
    }
  },
  api: {
    root: 'ta',
    orglevel: {
      root: 'orglevel',
      budgets: 'budgets',
      budgetDefinitions: 'budgetDefinitions'
    },
    attendancePoints: {
      root:'attendancePoints'
    },
    budgets: {
      root: 'budgets',
      restore: 'restore',
      clone: 'clone',
      delete: 'delete',
      dummy: 'dummy'
    },
    budgetedGroups: {
      root: 'budgetedGroups',
      orgLevel: 'orgLevel',
      deleteSet: 'deleteSet',
    },

    arrivalsDepartures: {
      root: 'arrivalsDepartures',
      details: 'details',
      orgLevel: {
        root: 'orgLevel'
      }
    },
    departuresDetails: {
      root: 'departuresDetails',
      unscheduled: 'unscheduled',
      orgLevel: {
        root: 'orgLevel'
      }
    },
    arrivalsDetails: {
      root: 'arrivalsDetails',
      unscheduled: 'unscheduled',
      orgLevel: {
        root: 'orgLevel'
      }
    },
    timeclockAssignments: {
      root: 'timeclockAssignments',
      orgLevel: {
        root: 'orgLevel',
        assign: 'assign',
        unassign: 'unassign'
      }
    },
    timeclock: {
      root: 'timeclock',
      orgLevel: {
        root: 'orgLevel',
        rebootClock:'reboot'
      }
    },
    timecards: {
      root: 'timecards',
      employee: {
        root: 'employee',
        approve: 'approve',
        unapprove: 'unapprove',
        discardPayRules: 'discardPayRules',
        discardPunches: 'discardPunches'
      },
      daily: {
        root: 'daily',
        downloadDebug: 'downloadDebug',
        lock: 'lock',
        unlock: 'unlock'
      },
      dailyTimecardPunches: {
        root: 'daily/punches',
        downloadDebug: 'downloadDebug',
        lock: 'lock',
        unlock: 'unlock'
      },
      approveDailyTimecards: 'approveDailyTimecards',
      unapproveDailyTimecards: 'unapproveDailyTimecards',
      allabsence : 'allabsence',
      orgLevel: {
        root: 'orgLevel',
        recalculateTimecards: 'recalculateTimecards',
        approveTimecards: 'approveTimecards',
        unapproveTimecards: 'unapproveTimecards',
        unprocessedTimecards: 'unprocessedTimecards'
      }
    },
    exceptionConsole: {
      root: 'exceptionConsole',
      rollup: 'rollup',
      orgLevel: {
        root: 'orgLevel'
      }
    },
    dailyPunches: {
      root: 'dailyPunches',
      headers: 'headers',
      orgLevel: 'orgLevel',
      rollup: 'rollup',
      removeInvalidLogin:'removeInvalidLogin',
      employee: {
        root: 'employee'
      }
    },
    employeePunchRequest: {
      root: 'employeePunchRequest',
      orgLevel: 'orgLevel',
      employee: {
        root: 'employee'
      }
    },
    dailyPunchLogs: 'dailyPunchLogs',
    configuration: {
      root: 'configuration',
      holidays: {
        root: 'holidays',
        copy: 'copy'
      },
      attendancePoint: {
        root: 'attendancePoint'
      },
    },
    supervisorAssignment: {
      root: 'supervisorAssignment',
      supervisor: 'supervisor',
      orglevel: {
        root: 'orglevel',
        group: 'group',
        groups: 'groups',
        employees: 'employees',
        supervisors: 'supervisors',
        assignSupervisorsToGroup: 'assignSupervisorsToGroup',
        removeSupervisorsFromGroup: 'removeSupervisorsFromGroup',
        assignEmployeesToGroup: 'assignEmployeesToGroup',
        removeEmployeesFromGroup: 'removeEmployeesFromGroup',
        unassignedEmployees: 'unassignedEmployees'
      }
    },
    export: {
      root: 'export',
      download: {
        root: 'download'
      }
    },
    summary: {
      root: 'timecardSummary',
      employee: 'employee'
    },
    organization: {
      root: 'organization',
      payroll: 'payroll',
      exports: 'exports'
    },
    signalR: {
      root: 'slate',
      signalRHub: 'timeclock-reboot-data',
      signalRApi: 'signalr',
      groupName: 'SlateNotificationGroup'
    }
  },
  timecardsHeaderHeight: 100,
  retroFieldValue:'Retro Date'
};
