/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./auth-handler.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "./auth-handler.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../common/services/technical/version.subscribe.service";
import * as i7 from "../../services/authentication/authentication.service";
import * as i8 from "../../actions/session.actions";
import * as i9 from "../../../core/services/user-activity/user-activity.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../portal/services/notifications/notifications-api.service";
import * as i12 from "../../../core/services/user-menu-pin/user-menu-pin.service";
import * as i13 from "../../../core/services/local-storage/local-storage.service";
var styles_AuthHandler = [i0.styles];
var RenderType_AuthHandler = i1.ɵcrt({ encapsulation: 0, styles: styles_AuthHandler, data: {} });
export { RenderType_AuthHandler as RenderType_AuthHandler };
export function View_AuthHandler_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AuthHandler_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-auth-handler", [], null, null, null, View_AuthHandler_0, RenderType_AuthHandler)), i1.ɵdid(1, 114688, null, 0, i4.AuthHandler, [i5.Router, i5.ActivatedRoute, i6.VersionSubscribeService, i7.AuthenticationService, i8.SessionActions, i9.UserActivityService, i10.DomSanitizer, i11.NotificationsApiService, i12.UserMenuPinService, i13.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthHandlerNgFactory = i1.ɵccf("slx-auth-handler", i4.AuthHandler, View_AuthHandler_Host_0, {}, { onAuthenticated: "onAuthenticated" }, []);
export { AuthHandlerNgFactory as AuthHandlerNgFactory };
