import { EmployeeSectionsBase } from '../employee-sections-base';
import { FieldsMeta } from '../../../../core/models/index';
import { appConfig } from '../../../../../app/app.config';
import * as moment from 'moment';

export interface IEmployeeSectionAccrualTransactions {
  records: IEmployeeAccrualTransaction[];
}

export class EmployeeSectionAccrualTransactions extends EmployeeSectionsBase {
  public records: EmployeeAccrualTransaction[];
  public fieldsMeta: FieldsMeta;
  empAccrualTransactions: any;
  constructor() {
    super();
    this.records = [];
  }
}

export interface IEmployeeAccrualTransaction {
  id: number;
  empId: number;
  policyName: string;
  action: string;
  transactionDate: string;
  absenceDate: string;
  policyId: number;
  dynamicPolicyId: number;
  accrualTypeId: number;
  accrualType: string;
  originalValue: number;
  newValue: number;
  change: number;
  modifiedBy: string;
  modifiedOn: Date;
  notes: string;
  dbAction: number;
  deleted: boolean;
  isLastManualTransaction: boolean;
}

export class EmployeeAccrualTransaction {
  public id: number;
  public empId: number;
  public policyName: string;
  public action: string;
  public transactionDate: Date;
  public absenceDate: Date;
  public policyId: number;
  public dynamicPolicyId: number;
  public accrualTypeId: number;
  public accrualType: string;
  public originalValue: number;
  public newValue: number;
  public change: number;
  public modifiedBy: string;
  public modifiedOn: Date;
  public notes: string;
  public dbAction: number;
  public deleted: boolean;
  public isLastManualTransaction: boolean;
  public isDirty: boolean = false;

  public get transactionDateFormat(): string {
    return moment(this.transactionDate).format(appConfig.dateFormat);
  }

  public get updatedDate(): string {
    return moment(this.modifiedOn).format(appConfig.dateTimeFormatUS);
  }
}
