import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsFutureUpdates = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsFutureUpdates, _super);
    function EmployeeSectionsFutureUpdates() {
        var _this = _super.call(this) || this;
        _this.updates = [];
        return _this;
    }
    EmployeeSectionsFutureUpdates.dataMock = {
        updates: [
            {
                id: 124,
                description: 'Employee field change',
                requestedOn: new Date('2016-08-01T07:41:00'),
                scheduledFor: new Date('2016-09-01'),
                processedOn: new Date('2016-09-01'),
                requestedBy: 'S.User',
                updateSystem: 'HR',
                updatedFields: [
                    {
                        fieldName: 'payPolicy',
                        displayName: 'Pay Policy',
                        value: 'FT_DC',
                        fieldType: 'string',
                        access: 'full'
                    },
                    {
                        fieldName: 'payPolicy1',
                        displayName: 'Pay Policy2',
                        value: new Date(),
                        fieldType: 'datetime',
                        access: 'full'
                    },
                    {
                        fieldName: 'payPolicy2',
                        displayName: 'Pay Policy2',
                        value: 'FT_DC',
                        fieldType: 'number',
                        access: 'full'
                    }
                ]
            },
            {
                id: 125,
                description: 'Employee field change3',
                requestedOn: new Date('2016-08-01T07:41:00'),
                scheduledFor: new Date('2016-09-01'),
                processedOn: new Date('2016-09-01'),
                requestedBy: 'S.User',
                updateSystem: 'HR',
                updatedFields: [
                    {
                        fieldName: 'payPolicy',
                        displayName: 'Pay Policy',
                        value: 'FT_DC',
                        fieldType: 'string',
                        access: 'full'
                    },
                    {
                        fieldName: 'payPolicy1',
                        displayName: 'Pay Policy2',
                        value: new Date(),
                        fieldType: 'datetime',
                        access: 'full'
                    },
                    {
                        fieldName: 'payPolicy2',
                        displayName: 'Pay Policy2',
                        value: 'FT_DC',
                        fieldType: 'number',
                        access: 'full'
                    }
                ]
            },
        ]
    };
    return EmployeeSectionsFutureUpdates;
}(EmployeeSectionsBase));
export { EmployeeSectionsFutureUpdates };
var EmployeeSectionsFutureUpdate = /** @class */ (function () {
    function EmployeeSectionsFutureUpdate() {
        this.updatedFields = [];
    }
    return EmployeeSectionsFutureUpdate;
}());
export { EmployeeSectionsFutureUpdate };
var EmployeeSectionsFutureUpdateField = /** @class */ (function () {
    function EmployeeSectionsFutureUpdateField() {
    }
    return EmployeeSectionsFutureUpdateField;
}());
export { EmployeeSectionsFutureUpdateField };
