import * as tslib_1 from "tslib";
import { NgZone, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeSectionsI9, I9ListATypes, I9ListCTypes, I9ListBTypes, I9AdditionalInfoTypes } from '../../../models/employee-sections-employment/index';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
var EmployeeSectionsI9Component = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsI9Component, _super);
    function EmployeeSectionsI9Component(employeeSectionsEmploymentApiService, decorator, ngZone, changeDetector) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.changeDetector = changeDetector;
        _this.I9ListATypes = I9ListATypes;
        _this.I9ListBTypes = I9ListBTypes;
        _this.I9ListCTypes = I9ListCTypes;
        _this.I9AdditionalInfoTypes = I9AdditionalInfoTypes;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        return _this;
    }
    Object.defineProperty(EmployeeSectionsI9Component.prototype, "i9", {
        get: function () {
            return this.empI9;
        },
        set: function (i9Object) {
            this.empI9 = i9Object;
            this.updateData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsI9Component.prototype, "isEditable", {
        get: function () {
            return this.decorator.isSubsectionEditable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsI9Component.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsI9Component.prototype.getSubsectionModel = function () {
        return this.empI9;
    };
    EmployeeSectionsI9Component.prototype.updateData = function () {
        var _this = this;
        setTimeout(function () {
            _this.changeDetector.detectChanges();
            _this.changeDetector.markForCheck();
        }, 0);
    };
    EmployeeSectionsI9Component.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmployeeI9(this.employeeId)
            .then(function (data) {
            _this.i9 = data;
            _this.stopProgress();
        }).catch(function () {
            _this.stopProgress();
        });
    };
    EmployeeSectionsI9Component.prototype.checkTemporalDirty = function () {
        return null;
    };
    EmployeeSectionsI9Component.prototype.doSave = function () {
        var _this = this;
        this.employeeSectionsEmploymentApiService.setEmployeeI9(this.employeeId, this.i9).then(function (response) {
            _this.onActionComplete(true);
        }).catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsI9Component.prototype.clearI9ListA = function () {
        this.i9.listA.clear();
        this.updateData();
    };
    EmployeeSectionsI9Component.prototype.clearI9ListB = function () {
        this.i9.listB.clear();
        this.updateData();
    };
    EmployeeSectionsI9Component.prototype.clearI9ListC = function () {
        this.i9.listC.clear();
        this.updateData();
    };
    EmployeeSectionsI9Component.prototype.clearI9AdditionalInfo = function () {
        this.i9.additionalInfo.clear();
        this.updateData();
    };
    return EmployeeSectionsI9Component;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsI9Component };
