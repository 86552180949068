<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents ec-header">

      <div class="ec-header__left">

          <slx-switcher *ngIf="!isAboveOrganizationLevel"
          class="indent-right"
          [ngModel]="usePayCycle"
          (ngModelChange)="onSwitchRangeMode($event)"
          label="PayCycle"
          labelOn="On"
          labelOff="Off"
        ></slx-switcher>

        <slx-daterange *ngIf="!usePayCycle"
          class="slx-width-320"
          name="startEndDate"
          startDatePlaceholder="From"
          endDatePlaceholder="To"
          [hasApplyButton]="true"
          [maxSecRange]="oneMonthInSec"
          [maxRangeErrorText]="'You can not choose dates wider than 30 days'"
          [startDate]="currentRange?.startDate"
          [endDate]="currentRange?.endDate"
          (rangeDateChanged)="onFilterDateChanged($event)"
        ></slx-daterange>

        <slx-paycycle-dropdown *ngIf="usePayCycle"
        class="indent-right"
        [setDefaultIfNotSelected]="setDefaultCycle"
        [selectedPayCycle]="selectedPayCycle"
        (payCycleSelected)="onPayCycleSelected($event)"
      ></slx-paycycle-dropdown>

      </div>

      <div class="ec-header__right">

        <button type="button"
          class="slx-button slx-only-icon slx-toolbar"
          [popper]="columnsToggler"
          [popperTrigger]="'click'"
          [popperPlacement]="'bottom-start'"
          [popperDisableStyle]="true"
        >
          <i class="fa fa-cog" aria-hidden="true"></i>
        </button>

        <popper-content #columnsToggler class="slx-popper">
          <div class="ec-header__popup">
            <div class="column-row">
              <span class="title-col"><strong>Column description</strong></span>
              <span class="display-col"><strong>Display</strong></span>
            </div>
            <div *ngFor="let column of globalState.allColumns; let idx=index;" class="column-row">
              <span class="title-col">{{column.description}}</span>
              <div class="display-col"  [ngClass]="{ 'display-check': column.readonly }">
                <slx-input-decorator className="slx-no-border slx-no-label slx-no-error-block slx-small-font slx-checkbox-small">
                  <slx-checkbox-input slx-input name="column{{idx}}" [readonly]="column.readonly" fieldName="column{{idx}}" [(ngModel)]="column.displayed" (ngModelChange)="onChangeColumn($event, column)">
                  </slx-checkbox-input>
                </slx-input-decorator>
              </div>
            </div>
            <div class="button-container">
              <button class="margin-button theme-button-apply margin-r" type="button" (click)="selectAll($event)"> Select All </button>
              <button class="margin-button theme-button-cancel" type="button" (click)="clearAll($event)"> Clear All</button>
            </div>
          </div>
        </popper-content>
      </div>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-exception-console-grid-rollup></slx-exception-console-grid-rollup>
    </div>
  </div>
</slx-spinner>


