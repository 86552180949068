import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { unsubscribe } from '../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { IpRestriction } from '../../../models/ip-management/ip-restriction.model';
import { IpManagementService } from '../../../services/ip-management/ip-management.service';
import { process } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { ConfirmOptions, ConfirmDialogComponent, ModalService } from '../../../../common/index';
var IpWhitelistingComponent = /** @class */ (function () {
    function IpWhitelistingComponent(ipManService, modalService) {
        this.ipManService = ipManService;
        this.modalService = modalService;
        this.subscriptions = {};
        this.ipWhitelist = [];
        this.isCanEdit = false;
        this.isEnableSave = false;
        this.canDelete = true;
        this.invalidIP = true;
        this.isDuplicate = { show: false, inTab: null };
        this.isAddMode = false;
        this.isEditMode = false;
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.gridState.state.sort = [
            { field: 'ipAddress', dir: 'desc' }
        ];
    }
    IpWhitelistingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isLoading = true;
        this.subscriptions.onLoad = this.ipManService
            .subscribeToLoading(function (value) {
            _this.isLoading = value;
        });
        this.subscriptions.ipManagementSettings = this.ipManService
            .subscribeToIpSettings(function (v) {
            _this.isCanEdit = v.canEdit;
        });
        this.subscriptions.ipWhitelist = this.ipManService
            .subscribeToIpWhitelist(function (v) {
            _this.ipWhitelist = v;
            _this.refreshGrid();
            _this.isLoading = false;
        });
        this.subscriptions.addNewIp = this.ipManService
            .subscribeToAddNewIp(function (v) {
            if (v) {
                _this.isEnableSave = false;
                _this.isAddMode = true;
                _this.isEditMode = false;
                _this.onStartAdd();
            }
        });
        this.subscriptions.downloadWhitelistFile = this.ipManService
            .subscribeToDownloadWhitelistConfig(function (v) {
            _this.downloadFile(v);
        });
        this.subscriptions.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
        });
        this.subscriptions.gridEditSubscription = this.gridState.onEdit$
            .subscribe(function (option) {
            _this.isAddMode = false;
            _this.isEditMode = true;
            _this.editedRowIndex = _this.gridState.editedRowIndex;
            _this.disabled = true;
            _this.isEnableSave = false;
        });
        this.subscriptions.gridCancelSubscription = this.gridState.onCancel$
            .subscribe(function () {
            _this.disabled = true;
            _this.invalidIP = false;
            _this.isDuplicate = { show: false, inTab: null };
            _this.isAddMode = false;
            _this.isEditMode = false;
        });
        this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure that you want to remove this IP address?', _this.modalService, function (result) {
                if (result) {
                    _this.doRemove(item.dataItem);
                }
            }, options);
        });
        this.gridSaveSubscription = this.gridState.onSave$.subscribe(function (item) {
            if (item.isNew) {
                _this.doAdd(item.dataItem);
                _this.gridState.closeEditor(_this.grid);
            }
            else {
                _this.doUpdate(item.dataItem);
                _this.gridState.closeEditor(_this.grid);
            }
        });
    };
    IpWhitelistingComponent.prototype.refreshGrid = function () {
        if (!this.ipWhitelist) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.ipWhitelist, this.gridState.state);
        this.disabled = true;
    };
    IpWhitelistingComponent.prototype.retriveAllPages = function () {
        var _this = this;
        return function () { return ({
            data: process(_this.ipWhitelist, _this.gridState.state).data
        }); };
    };
    IpWhitelistingComponent.prototype.downloadFile = function (downloadFileConfig) {
        if (downloadFileConfig.tabType === "Allowlist" && downloadFileConfig.fileType === "PDF") {
            this.grid.saveAsPDF();
        }
        if (downloadFileConfig.tabType === "Allowlist" && downloadFileConfig.fileType === "Excel") {
            this.grid.saveAsExcel();
        }
    };
    IpWhitelistingComponent.prototype.doAdd = function (ipWhitelist) {
        var _this = this;
        this.ipManService.startProgress();
        this.ipManService.addRestriction(ipWhitelist)
            .then(function (status) {
            var data = status.data;
            _this.ipWhitelist.push(status.data);
            _this.ipManService.updateIPList(data, true);
            _this.refreshGrid();
            _this.ipManService.stopProgress();
        }).catch(function (error) {
            _this.refreshGrid();
            _this.ipManService.stopProgress();
        });
    };
    IpWhitelistingComponent.prototype.doUpdate = function (ipWhitelist) {
        var _this = this;
        this.ipManService.startProgress();
        this.ipManService.updateRestriction(ipWhitelist)
            .then(function (status) {
            var data = status.data;
            _this.ipManService.updateIPList(data, true);
            _this.refreshGrid();
            _this.ipManService.stopProgress();
        })
            .catch(function (error) {
            _this.refreshGrid();
            _this.ipManService.stopProgress();
        });
    };
    IpWhitelistingComponent.prototype.doRemove = function (ipWhitelist) {
        var _this = this;
        this.ipManService.startProgress();
        this.ipManService.deleteRestriction(ipWhitelist.id)
            .then(function (status) {
            _.remove(_this.ipWhitelist, function (ip) {
                return ip.ipAddress == ipWhitelist.ipAddress;
            });
            _this.ipManService.updateIPAddress(ipWhitelist.id);
            _this.refreshGrid();
            _this.ipManService.stopProgress();
        }).catch(function (error) {
            _this.refreshGrid();
            _this.ipManService.stopProgress();
        });
    };
    IpWhitelistingComponent.prototype.onStartAdd = function () {
        this.gridState.closeEditor(this.grid);
        var ipWhiteList = new IpRestriction();
        ipWhiteList.isEnabled = false;
        ipWhiteList.useInGo = false;
        ipWhiteList.useInV6 = false;
        ipWhiteList.isAllowRestriction = true;
        this.grid.addRow(ipWhiteList);
        this.ipManService.addNewIp(false);
    };
    IpWhitelistingComponent.prototype.onChangeIPStatus = function (e, item) {
        item.isEnabled = e;
        this.onChangeWhiteListIPAddress(item.ipAddress, item);
    };
    IpWhitelistingComponent.prototype.onChangeWhiteListIPAddress = function (e, item) {
        var validIp;
        var isDuplicate;
        if (this.isEditMode) {
            validIp = this.ipManService.checkIpValidation(e);
            isDuplicate = this.ipManService.checkDuplicateToEdit(e, item.id);
        }
        else if (this.isAddMode) {
            validIp = this.ipManService.checkIpValidation(e);
            isDuplicate = this.ipManService.checkDuplicateToEdit(e, null);
        }
        if (validIp) {
            this.invalidIP = false;
            if (!isDuplicate) {
                this.isDuplicate = { show: false, inTab: null };
            }
            else {
                this.isDuplicate = isDuplicate;
            }
            this.invalidIP = false;
        }
        else {
            this.invalidIP = true;
            this.isDuplicate = { show: false, inTab: null };
        }
        this.enableSave();
    };
    IpWhitelistingComponent.prototype.onChangeWhiteListDescription = function (e) {
        this.onChangeWhiteListIPAddress(e.ipAddress, e);
    };
    IpWhitelistingComponent.prototype.onChangeWhiteListCheckBox = function (e) {
        this.onChangeWhiteListIPAddress(e.ipAddress, e);
    };
    IpWhitelistingComponent.prototype.enableSave = function () {
        if (!this.invalidIP && !this.isDuplicate.show) {
            this.isEnableSave = true;
        }
        else {
            this.isEnableSave = false;
        }
    };
    IpWhitelistingComponent.prototype.ngOnDestroy = function () {
        _.forEach(this.subscriptions, function (s) {
            if (s && s.unsubscribe) {
                s.unsubscribe();
            }
        });
        this.subscriptions = {};
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IpWhitelistingComponent.prototype, "gridSaveSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IpWhitelistingComponent.prototype, "gridRemoveSubscription", void 0);
    return IpWhitelistingComponent;
}());
export { IpWhitelistingComponent };
