/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employer-organization-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./employer-organization-dialog.component";
import * as i4 from "../../../models/employers/employers.model";
import * as i5 from "../../../../common/models/dialog-options";
import * as i6 from "../../../../common/services/modal/modal.service";
var styles_EmployerOrganizationDialogComponent = [i0.styles];
var RenderType_EmployerOrganizationDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployerOrganizationDialogComponent, data: {} });
export { RenderType_EmployerOrganizationDialogComponent as RenderType_EmployerOrganizationDialogComponent };
function View_EmployerOrganizationDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_EmployerOrganizationDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "employers"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-sm-12  col-md-12 col-lg-12 div-tbl-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This employer cannot be deleted because the following organizations are assigned to it:"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col-sm-12 col-md-12  col-lg-12 div-tbl-body content-sec"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployerOrganizationDialogComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.orgDialogOptions == null) ? null : _co.orgDialogOptions.organization); _ck(_v, 12, 0, currVal_0); }, null); }
export function View_EmployerOrganizationDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-employer-organization-dialog", [], null, null, null, View_EmployerOrganizationDialogComponent_0, RenderType_EmployerOrganizationDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmployerOrganizationDialogComponent, [i4.OrganizationDialogOptions, i5.DialogOptions, i6.ModalService], null, null)], null, null); }
var EmployerOrganizationDialogComponentNgFactory = i1.ɵccf("slx-employer-organization-dialog", i3.EmployerOrganizationDialogComponent, View_EmployerOrganizationDialogComponent_Host_0, {}, {}, []);
export { EmployerOrganizationDialogComponentNgFactory as EmployerOrganizationDialogComponentNgFactory };
