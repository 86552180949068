import * as _ from 'lodash';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EmployersConfigurationApiService, RolesApiService } from '../../services';
import { ModalService, KendoGridStateHelper, PopoverContentComponent, PbjNavigationService } from '../../../common/index';
import { EmployersContainer, Employer,OrganizationDialogOptions, AleGroup, EmployerDialogOptions } from '../../models/employers/index';
import { GridComponent } from '@progress/kendo-angular-grid';
import { EmployersManagementService } from '../../services/employers/employers-configuration-management.service';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ManageAleGroupsDialogComponent } from './manage-groups/manage-ale-groups-dialog/manage-ale-groups-dialog.component';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { Observable, Subscription } from 'rxjs';
import { CompositeFilterDescriptor, FilterDescriptor, GroupDescriptor, process } from '@progress/kendo-data-query';
import { ManageEmployerDialogComponent } from '../../components/employers/manage-employer-dialog/manage-employer-dialog.component';
import { EmployerOrganizationDialogComponent } from '../../components/employers/employer-organization-dialog/employer-organization-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../core/services/index';
import { Console } from 'console';
import { IUser } from '../../../authentication/store/index';
import { User } from '../../../state-model/models/user/user';

@Component({
  moduleId: module.id,
  selector: 'slx-configure-employers',
  templateUrl: 'configure-employers.component.html',
  styleUrls: ['configure-employers.component.scss'],
  providers: [EmployersManagementService]
})
export class ConfigureEmployersComponent implements OnInit {
  @mutableSelect(['session', 'user'])
  public user$: Observable<IUser>;
  public isSystemUser : boolean = false;
  public gridState: KendoGridStateHelper<Employer[]>;
  public canEdit: boolean;
  public canExport: boolean;
  
  public isLoading: boolean;
  public isAccessEmployers: boolean = false;
  public groups: GroupDescriptor[] = [{ field: 'aleGroupName' }];
  public management: EmployersManagementService;
  public tooltip: string = '';
  private aleGroupList: AleGroup[];
  private m_container: EmployersContainer;
  @ViewChild('kendoGrid', { static: true })
  private grid: GridComponent;

  @unsubscribe()
  private stateSubscription: Subscription;
  @unsubscribe()
  private onloadedSubscription: Subscription;
  @unsubscribe()
  private refreshSubscription: Subscription;
  allFeins: string[] = [];
  
  
  constructor(
    management: EmployersManagementService,
    private modalService: ModalService,
    private api: EmployersConfigurationApiService,
    private route: ActivatedRoute,
    private router: Router,
    private rolesApiService :RolesApiService
    
  ) {

    this.management = management;

    this.gridState = new KendoGridStateHelper<Employer[]>();
    this.gridState.view = null;
    this.gridState.state.sort = [
      { field: 'legalName', dir: 'asc' }
    ];

  }

  public ngOnInit(): void {
    this.isLoading = false;
    
    this.user$.subscribe((user: User) => {
      this.isSystemUser = user ? user.username === 'system' : false;
  });
    this.onloadedSubscription = this.management
      .subscribeToOnLoaded((state: EmployersContainer) => {
        this.gridState.state.group = this.groups;
        this.canEdit = state.canEditEmployersConfig;
        this.canExport = state.canExportToExcel;
        this.aleGroupList = state.aleGroupList;
        this.m_container = state;
        if (!this.canEdit) {
          this.tooltip = 'You do not have the right to edit this information';
        }
        this.refreshGrid();
      });
      let roles,userData,loginRole;	
      userData = JSON.parse(localStorage.getItem("slx-worklinx-v6-app"));	
      loginRole = userData.session.user.roles[0];	      
      this.rolesApiService.getRoleAccessTable().then( res => {	
        roles = res.roles;	
          roles.forEach(ele => {	
            if(loginRole === ele.name){	
              if(ele.menuModules){	
                ele.menuModules.forEach( element => {	
                  if(element.name == 'Configuration'){	
                    element.menus.forEach( menu => {	
                      if(menu.name == 'General'){	
                        menu.subMenus.forEach( subMenu => {	                         
                          if(subMenu.name == 'Organizations'){	
                            if(subMenu.isEnabled){	
                              this.isAccessEmployers = true;	                              
                            }	
                          }	
                        })	
                      }	
                    })	
                  }	
                })	
              }	
            }	
          })	
        // }	
      });	
                       
    this.stateSubscription = this.management
      .subscribeToOnStateChanged((state: any) => {
        this.isLoading = state.isLoading;
      });
    this.refreshSubscription = this.gridState.onRefreshGrid
      .subscribe(() => this.refreshGrid());

    this.management.init();
  }

  public getGridData(): () => ExcelExportData {
    return (): ExcelExportData => {
      return {
        data: _.cloneDeep(this.gridState.view.data),
        group: this.gridState.state.group
      };
    };
  }

  filterChange(filter: CompositeFilterDescriptor){
    filter.filters.forEach( (ele: CompositeFilterDescriptor) => {
      ele.filters.forEach( (ele: FilterDescriptor) => {
        if(ele.field == "feinWithoutDash"){
          ele["value"] = ele.value.indexOf('-') > -1 ? ele.value.replace('-', '') : ele.value;
        }
      })
    });
    this.gridState.state.filter = filter;
    this.refreshGrid();
  }

  private refreshGrid(): void {
    if (!this.m_container) {
      this.gridState.view = null;
      return;
    }
    if(this.m_container.records){
      this.allFeins = this.m_container.records ? this.m_container.records.map( e => e.federalEIN.replace('-', '')) : [];
    }
    this.gridState.view = process(this.m_container.records, this.gridState.state);
  }

  public onAddNew(): void {
    let employerDialogOptions: EmployerDialogOptions = new EmployerDialogOptions();
    employerDialogOptions.employer = new Employer();
    employerDialogOptions.employer.companyId = 0;
    employerDialogOptions.employer.orgLevelId = this.management.orgLevelId;
    employerDialogOptions.employer.aleGroupModel = new AleGroup();
    employerDialogOptions.employer.aleGroupModel.groupId = 0;
    employerDialogOptions.employer.aleGroupModel.groupName = 'Ale Group 1';
    employerDialogOptions.aleGroupList = this.aleGroupList;
    employerDialogOptions.allFeins = this.allFeins;
    this.openPopupDialog(employerDialogOptions);
  }

  public onEditItems(employer: Employer): void {
    let employerDialogOptions: EmployerDialogOptions = new EmployerDialogOptions();
    employerDialogOptions.employer = new Employer();
    employer.orgLevelId = this.management.orgLevelId;
    employerDialogOptions.employer = employer;
    employerDialogOptions.aleGroupList = this.aleGroupList;
    employerDialogOptions.allFeins = this.allFeins;

    this.openPopupDialog(employerDialogOptions);
  }

  public async onClickDelete(isDelete: boolean, acceptPopover: PopoverContentComponent, employer: Employer): Promise<void> {
    acceptPopover.hide();
    if (isDelete) {
      this.onDelete(employer);
    }
  }

  public onDelete(employer: Employer): void {
    this.isLoading = true;
    this.api.getEmployersOrganization(employer.companyId).
      then((orgDialogOptions: OrganizationDialogOptions) => {
        this.isLoading = false;
        if (orgDialogOptions && orgDialogOptions.organization.length > 0) {
          EmployerOrganizationDialogComponent.openDialog(orgDialogOptions, this.modalService);
        }
        else {
          this.onDeleteEmployer(employer);
        }
      }).catch(() => {
        this.isLoading = false;
      });
  }

  private async onDeleteEmployer(employer: Employer): Promise<void> {
    employer.deleted = true;
    employer.orgLevelId = this.management.orgLevelId;
    await this.management.onSaveEmployer(employer);
      
  }

  public openPopupDialog(employerDialogOptions: EmployerDialogOptions): void {
    ManageEmployerDialogComponent.openDialog(employerDialogOptions, this.modalService, (hasChanges: boolean) => {
      if (hasChanges) {
        this.management.loadEmployers();
      }
    });
  }

  public onExportToExcel(): void {
    this.grid.saveAsExcel();
  }

  public onExportToPdf(): void {
    this.grid.saveAsPDF();
  }

  public navigateToPBJOrg():void{
    const navigation: PbjNavigationService = new PbjNavigationService(this.router, this.route);
    navigation.NavigateToPbjOrganizationsConfiguration();        
}
public checkPbjAccess():void{


}

  public onManageGroups(): void {
    ManageAleGroupsDialogComponent.openDialog(this.modalService, (hasChanges: boolean) => {
      if (hasChanges) {
        this.management.loadEmployers();
      }
    });
  }
}
