<slx-spinner [show]="state.isLoading">
    <div class="slx-high-box">
      <div class="slx-content-toolbar-indents roles-header">
        <ng-container>
          <div class="flex-horizontal">
            <button type="button"
              (click)="onAddDefinition()"
              [disabled]="!isDataLoaded"
               class="slx-button slx-blue slx-with-icon">
              <i class="fal fa-plus-circle slx-button__icon" aria-hidden="true"></i>
              &nbsp;Create New Group </button>
            </div>            
        </ng-container>
      </div>
      <div class="slx-high-box__body slx-main-content-indents">
        <div class="slx-full-height">
          <slx-notifications-grid ></slx-notifications-grid>
        </div>
      </div> 
    </div>
</slx-spinner>

