import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { PositionsManagementService } from '../../../services/index';
import { EditableListEditorComponent } from '../../editableList/listEditor/editable-list-editor.component';
import { PositionModel, AdditionalInfo } from '../../../models/index';
import { LookupApiService } from '../../../../organization/services/index';
import { Lookup, LookupType } from '../../../../organization/models/lookup/index';
import { SelectableItemsProducer } from '../../../../common';
import { Assert } from '../../../../framework';
import { LookupService } from '../../../../../app/organization/services/index';
import { SessionService } from '../../../../../app/core/services/session/session.service';
import { AppSettingsManageService } from '../../../../../app/app-settings/services/app-settings-manage.service';
var ConfigurePositionEditorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ConfigurePositionEditorComponent, _super);
    function ConfigurePositionEditorComponent(lookup, selectableItemsProducer, positionsManagementService, formBuilder, lookupService, sessionService, appSettingsManageService) {
        var _this = _super.call(this) || this;
        _this.lookup = lookup;
        _this.positionsManagementService = positionsManagementService;
        _this.formBuilder = formBuilder;
        _this.sessionService = sessionService;
        _this.appSettingsManageService = appSettingsManageService;
        _this.isNextgenPayrollEnabled = false;
        _this.isAddPosition = false;
        _this.isSupervisorEnabled = false;
        _this.maxRateLimit = 9999999;
        _this.minRateLimit = 0;
        _this.isPositionLookupProgress = false;
        _this.isDepartmentLookupProgress = false;
        _this.selectedPositions = [];
        _this.isCanReplaceEnabled = false;
        _this.isPositionSelected = false;
        _this.unAssignedGroupName = 'Unassigned';
        _this.positionGroupName = 'positionGroup';
        _this.searchText = '';
        _this.operator = 'OR';
        _this.isSystemLogin = true;
        _this.positionLicenseTypesSelection = [];
        _this.isPositionLicenseTypeSelection = false;
        Assert.isNotNull(selectableItemsProducer, 'selectableItemsProducer');
        _this.selectableItemsProducer = selectableItemsProducer;
        _this.essTemplatesEnabled = _this.positionsManagementService.essTemplatesEnabled;
        _this.lookupService = lookupService;
        return _this;
    }
    Object.defineProperty(ConfigurePositionEditorComponent.prototype, "item", {
        get: function () {
            return this.privateItem;
        },
        set: function (value) {
            this.privateItem = value;
            if (this.m_currentOrgLevel)
                this.updatePositionsLookup();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePositionEditorComponent.prototype, "maxRate", {
        get: function () {
            var formValue = this.formGroup.get('maximumRate') ? this.formGroup.get('maximumRate').value : this.maxRateLimit;
            formValue = _.isNaN(formValue) || !formValue ? this.maxRateLimit : formValue;
            return this.privateItem ? Math.min(formValue, this.maxRateLimit) : this.maxRateLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePositionEditorComponent.prototype, "minRate", {
        get: function () {
            var formValue = this.formGroup.get('minimumRate') ? this.formGroup.get('minimumRate').value : this.minRateLimit;
            formValue = _.isNaN(formValue) || !formValue ? this.minRateLimit : formValue;
            return this.privateItem ? Math.max(formValue, this.minRateLimit) : this.minRateLimit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePositionEditorComponent.prototype, "positionsFiltered", {
        get: function () {
            return this.m_positionsFiltered;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePositionEditorComponent.prototype, "positionsGroupFiltered", {
        get: function () {
            return this.m_positionsGroupFiltered;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurePositionEditorComponent.prototype, "currentOrgLevel", {
        get: function () {
            return this.m_currentOrgLevel;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurePositionEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getSettings();
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            _this.m_currentOrgLevel = orgLevel;
            _this.updatePositionsLookup();
            _this.positionGroupChanged(_this.item.positionGroup);
            if (_.isNull(_this.item.supervisorDepartment)) {
                _this.loadDepartments(_this.item.supervisorOrganization);
            }
            if (_this.essTemplatesEnabled) {
                _this.positionsManagementService.getEssTemplateDefinitions()
                    .then(function (templates) {
                    _this.essTemplates = templates;
                });
            }
            _this.lookup.getLicenseType(-1, _this.m_currentOrgLevel.id)
                .then(function (licenseTypeLookup) {
                _this.licenseTypeLookups = licenseTypeLookup;
                _this.positionLicenseTypesSelection = _this.selectableItemsProducer.produceSelectable(_this.licenseTypeLookups);
                if (_this.positionLicenseTypesSelection.length > 0) {
                    var posLTN = _this.formGroup.get('positionLicenseTypeSelection');
                    for (var i = 0; i < _this.positionLicenseTypesSelection.length; i++) {
                        var ctrlName = "licenserest" + _this.positionLicenseTypesSelection[i].item.licenseTypeID;
                        posLTN.addControl(ctrlName, new FormControl(_this.positionLicenseTypesSelection[i].item.licenseTypeID));
                        if (_this.item.positionLicenseTypes) {
                            if (_this.item.positionLicenseTypes.includes(_this.positionLicenseTypesSelection[i].item.licenseTypeID)) {
                                _this.positionLicenseTypesSelection[i].selected = true;
                            }
                            else {
                                _this.positionLicenseTypesSelection[i].selected = false;
                            }
                        }
                    }
                    _this.isPositionLicenseTypeSelection = true;
                }
            });
        });
        this.mainFormSubscription = this.formGroup.statusChanges.subscribe(function () {
            if (_this.formGroup.dirty) {
                _this.positionsManagementService.markAsDirty();
            }
        });
        this.formGroup.get(this.positionGroupName).valueChanges.subscribe(function (val) {
            _this.positionGroupChanged(val);
        });
        this.lookup.getPositionGroups(this.m_currentOrgLevel.id, true).then(function (positionsGroupLookup) {
            _this.m_positionsGroupFiltered = _.filter(positionsGroupLookup, function (p) {
                return p.id !== _this.item.id;
            });
        });
    };
    ConfigurePositionEditorComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
        _super.prototype.ngOnDestroy.call(this);
    };
    ConfigurePositionEditorComponent.prototype.addAdditionalInfo = function (e) {
        e.preventDefault();
        if (!this.item.additionalInfo)
            this.item.additionalInfo = new AdditionalInfo();
        this.addAdditionalControls(this.formGroup);
        this.updateAdditionalControls(this.formGroup);
    };
    ConfigurePositionEditorComponent.prototype.removeAdditionalInfo = function (e) {
        e.preventDefault();
        this.item.additionalInfo = null;
        this.removeAdditionalControls(this.formGroup);
    };
    ConfigurePositionEditorComponent.prototype.licenserestBuilder = function () {
        var _this = this;
        var licenserests = this.positionLicenseTypesSelection.map(function (licenserest) {
            return _this.formBuilder.control(licenserest.item.licenseTypeID);
        });
        return this.formBuilder.array(licenserests, Validators.required);
    };
    ConfigurePositionEditorComponent.prototype.removeValue = function (event, property) {
        if (property === this.positionGroupName) {
            this.formGroup.removeControl('replacesPositionsIds');
            this.selectedPositions = [];
            this.item[property] = this.privateItem.positionGroup;
        }
        else {
            this.item[property] = null;
        }
        event.preventDefault();
        this.formGroup.get(property).setValue(null);
    };
    ConfigurePositionEditorComponent.prototype.getSelectedPositionsArray = function () {
        this.selectedPositions = [];
        for (var i = 0; i < this.canReplaceOptions.length; i++) {
            this.selectedPositions.push(this.canReplaceOptions[i].id);
        }
        this.selectAllEnabled(true);
    };
    ConfigurePositionEditorComponent.prototype.positionsBuilder = function () {
        var _this = this;
        var positions = this.canReplaceOptions.map(function (position) {
            return _this.formBuilder.control(position.id);
        });
        return this.formBuilder.array(positions, Validators.required);
    };
    ConfigurePositionEditorComponent.prototype.positionGroupChanged = function (event) {
        this.isCanReplaceEnabled = false;
        this.canReplaceOptions = [];
        this.selectedPositions = [];
        if (!_.isEmpty(event)) {
            this.canReplaceOptions = _.filter(this.m_positionsFiltered, function (p) {
                return p.positionGroupId == event.id;
            });
            this.getSelectedPositionsArray();
        }
        this.formGroup.removeControl('replacesPositionsIds');
        if (this.canReplaceOptions.length > 0) {
            this.isCanReplaceEnabled = true;
            this.formGroup.addControl('replacesPositionsIds', this.positionsBuilder());
            this.isPositionSelected = true;
            if (_.isArray(this.item.replacesPositionsIds) && _.isNaN(this.item.id)) {
                this.allPositionSelction(false);
                this.selectedPositions = [];
            }
            else {
                if (this.item.replacesPositionsIds) {
                    this.selectivePositions(event);
                }
                else {
                    this.allPositionSelction(true);
                    this.getSelectedPositionsArray();
                }
            }
            if (this.canReplaceOptions.length == this.selectedPositions.length) {
                this.selectAllEnabled(true);
            }
        }
        else {
            this.selectedPositions = [];
        }
    };
    ConfigurePositionEditorComponent.prototype.selectivePositions = function (event) {
        if ((this.item.replacesPositionsIds.length != this.selectedPositions.length) && (event.id == this.item.positionGroup.id)) {
            var selectedPositionGroups = this.formGroup.controls.replacesPositionsIds;
            var uncheckedPositions_1 = this.compareArrays(this.formGroup.controls.replacesPositionsIds.value, this.item.replacesPositionsIds);
            this.selectAllEnabled(false);
            var _loop_1 = function (i) {
                var index = selectedPositionGroups.controls.findIndex(function (k) { return k.value == uncheckedPositions_1[i]; });
                var positionIndex = this_1.selectedPositions.findIndex(function (pID) { return pID == uncheckedPositions_1[i]; });
                this_1.selectedPositions.splice(positionIndex, 1);
                selectedPositionGroups.controls[index].setValue(false);
            };
            var this_1 = this;
            for (var i = 0; i < uncheckedPositions_1.length; i++) {
                _loop_1(i);
            }
        }
    };
    ConfigurePositionEditorComponent.prototype.selectAllEnabled = function (selectAllStatus) {
        this.formGroup.get('selectAll').setValue(selectAllStatus);
    };
    ConfigurePositionEditorComponent.prototype.allPositionSelction = function (selectionState) {
        if (this.formGroup.controls.replacesPositionsIds) {
            this.selectAllEnabled(selectionState);
            var selectedPositionGroups = this.formGroup.controls.replacesPositionsIds;
            for (var i = 0; i < selectedPositionGroups.controls.length; i++) {
                selectedPositionGroups.controls[i].setValue(selectionState);
            }
        }
    };
    ConfigurePositionEditorComponent.prototype.compareArrays = function (formControlPositions, itemPositions) {
        var resultArray = formControlPositions;
        for (var i = 0; i < itemPositions.length; i++) {
            var index = formControlPositions.indexOf(itemPositions[i]);
            if (index > -1) {
                resultArray.splice(index, 1);
            }
        }
        return resultArray;
    };
    Object.defineProperty(ConfigurePositionEditorComponent.prototype, "selectedPositionList", {
        get: function () {
            return this.formGroup.get('replacesPositionsIds');
        },
        enumerable: true,
        configurable: true
    });
    ConfigurePositionEditorComponent.prototype.selectionChanged = function (isChecked, id) {
        if (isChecked) {
            this.selectedPositions.push(id);
            if (this.canReplaceOptions.length == this.selectedPositions.length) {
                this.selectAllEnabled(true);
            }
            else {
                this.selectAllEnabled(false);
            }
        }
        else {
            var index = this.selectedPositions.findIndex(function (k) { return k == id; });
            this.selectedPositions.splice(index, 1);
            this.selectAllEnabled(false);
        }
    };
    ConfigurePositionEditorComponent.prototype.selectedAllCanReplace = function () {
        var selectedPositionGroups = this.formGroup.controls.replacesPositionsIds;
        if (this.formGroup.controls.selectAll.value) {
            this.getSelectedPositionsArray();
            for (var i = 0; i < selectedPositionGroups.controls.length; i++) {
                selectedPositionGroups.controls[i].setValue(true);
            }
        }
        else {
            this.selectedPositions = [];
            for (var i = 0; i < selectedPositionGroups.controls.length; i++) {
                selectedPositionGroups.controls[i].setValue(false);
            }
        }
    };
    ConfigurePositionEditorComponent.prototype.updatePositionsLookup = function () {
        var _this = this;
        if (this.item) {
            this.lookup.getPositions(null, this.m_currentOrgLevel.id, null, true).then(function (positionsLookup) {
                _this.m_positionsFiltered = _.filter(positionsLookup, function (p) {
                    return p.id !== _this.item.id;
                });
                if (_this.item.positionGroup) {
                    if (_this.item.positionGroup.name != _this.unAssignedGroupName) {
                        _this.positionGroupChanged(_this.item.positionGroup);
                    }
                }
            });
        }
    };
    ConfigurePositionEditorComponent.prototype.selectionLicenseTypeChanged = function (isChecked, licenseType) {
        var index = this.item.positionLicenseTypes.findIndex(function (l) { return l == licenseType; });
        if (isChecked) {
            if (index == -1) {
                this.item.positionLicenseTypes.push(licenseType);
            }
        }
        else {
            this.item.positionLicenseTypes.splice(index, 1);
        }
    };
    ConfigurePositionEditorComponent.prototype.updateItem = function () {
        this.item.name = this.formGroup.get('position').value;
        this.item.clientDepartment = this.formGroup.get('clientDepartment').value;
        this.item.jobClass = this.formGroup.get('jobClass').value;
        this.item.businessUnit = this.formGroup.get('businessUnit').value;
        this.item.objectAccount = this.formGroup.get('objectAccount').value;
        this.item.groupedWith = this.formGroup.get('groupedWith').value;
        this.item.budgetedPosition = this.formGroup.get('budgetedPosition').value;
        this.item.pbjCmsPosition = this.formGroup.get('pbjCmsPosition').value;
        this.item.isDirectCare = this.formGroup.get('isDirectCare').value;
        this.item.positionGroup = this.formGroup.get('positionGroup').value;
        if (!this.item.positionGroup || !this.item.positionGroup.id) {
            this.item.positionGroup = null;
        }
        if (this.isPositionSelected) {
            this.item.replacesPositionsIds = this.selectedPositions;
        }
        if (this.essTemplatesEnabled) {
            this.item.essTemplate = this.formGroup.get('essTemplate').value;
        }
        if (this.hasAdditionalControls) {
            this.item.additionalInfo.eeocGroup = this.formGroup.get('eeocGroup').value;
            this.item.additionalInfo.eeocClass = this.formGroup.get('eeocClass').value;
            this.item.additionalInfo.fte = this.formGroup.get('fte').value;
            this.item.additionalInfo.maximumRate = this.formGroup.get('maximumRate').value;
            this.item.additionalInfo.minimumRate = this.formGroup.get('minimumRate').value;
            this.item.additionalInfo.occupationCode = this.formGroup.get('occupationCode').value;
            this.item.additionalInfo.payGrade = this.formGroup.get('payGrade').value;
            this.item.additionalInfo.payType = this.formGroup.get('payType').value;
            this.item.additionalInfo.perDiemRate = this.formGroup.get('perDiemRate').value;
            this.item.additionalInfo.workersCompCode = this.formGroup.get('workersCompCode').value;
        }
        if (this.isSupervisorEnabled) {
            this.item.supervisorDepartment = this.formGroup.get('supervisorDepartment').value;
            this.item.supervisorOrganization = this.formGroup.get('supervisorOrganization').value;
            this.item.supervisorPosition = this.formGroup.get('supervisorPosition').value;
        }
        this.item.positionLicenseTypes = this.formGroup.get('positionLicenseTypes').value;
        this.item.operator = this.operator;
    };
    ConfigurePositionEditorComponent.prototype.createFormGroup = function () {
        var group = new FormGroup({
            position: new FormControl('', Validators.required),
            clientDepartment: new FormControl(''),
            jobClass: new FormControl(''),
            businessUnit: new FormControl(''),
            objectAccount: new FormControl(''),
            groupedWith: new FormControl(''),
            pbjCmsPosition: new FormControl(''),
            budgetedPosition: new FormControl(''),
            positionGroup: new FormControl(''),
            isDirectCare: new FormControl(''),
            essTemplate: new FormControl(''),
            selectAll: new FormControl(''),
            selectAllLicenseType: new FormControl(''),
            selectAnyLicenseType: new FormControl(''),
            positionLicenseTypes: new FormControl(''),
            searchField: new FormControl(''),
            positionLicenseTypeSelection: new FormGroup({})
        });
        this.addAdditionalControls(group);
        if (this.isSupervisorEnabled) {
            this.addSupervisorControls(group);
        }
        return group;
    };
    ConfigurePositionEditorComponent.prototype.updateFormGroup = function () {
        var _this = this;
        this.formGroup.get('position').setValue(this.item.name);
        this.formGroup.get('clientDepartment').setValue(this.item.clientDepartment);
        this.formGroup.get('jobClass').setValue(this.item.jobClass);
        this.formGroup.get('businessUnit').setValue(this.item.businessUnit);
        this.formGroup.get('objectAccount').setValue(this.item.objectAccount);
        this.formGroup.get('groupedWith').setValue(this.item.groupedWith);
        this.formGroup.get('pbjCmsPosition').setValue(this.item.pbjCmsPosition);
        this.formGroup.get('budgetedPosition').setValue(this.item.budgetedPosition);
        this.formGroup.get('positionGroup').setValue(this.item.positionGroup);
        this.formGroup.get('isDirectCare').setValue(this.item.isDirectCare);
        if (this.isPositionSelected) {
            this.formGroup.get('replacesPositionsIds').setValue(this.selectedPositions);
            this.formGroup.get('selectAll').setValue(true);
        }
        if (this.essTemplatesEnabled) {
            this.formGroup.get('essTemplate').setValue(this.item.essTemplate);
        }
        if (this.item.additionalInfo) {
            this.updateAdditionalControls(this.formGroup);
        }
        this.formGroup.get('positionLicenseTypes').setValue(this.item.positionLicenseTypes);
        this.operator = this.item.operator || 'AND';
        if (this.isSupervisorEnabled) {
            this.formGroup.get('supervisorOrganization').setValue(this.item.supervisorOrganization);
            if (!this.item.supervisorOrganization || !this.item.supervisorDepartment)
                return;
            this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.item.supervisorOrganization.orgLevelId, employeeId: undefined })
                .then(function (departmentLookup) {
                _this.supervisorDepartmentLookup = new Lookup();
                _this.supervisorDepartmentLookup = departmentLookup;
                _this.supervisorPositionLookup = new Lookup();
                _this.formGroup.get('supervisorDepartment').setValue(_this.item.supervisorDepartment);
                _this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: _this.item.supervisorDepartment.orgLevelId, employeeId: undefined })
                    .then(function (positionLookup) {
                    _this.supervisorPositionLookup = positionLookup;
                    _this.formGroup.get('supervisorPosition').setValue(_this.item.supervisorPosition);
                });
            });
        }
    };
    ConfigurePositionEditorComponent.prototype.updateAdditionalControls = function (group) {
        this.formGroup.get('eeocClass').setValue(this.item.additionalInfo.eeocClass);
        this.formGroup.get('eeocGroup').setValue(this.item.additionalInfo.eeocGroup);
        this.formGroup.get('payType').setValue(this.item.additionalInfo.payType);
        this.formGroup.get('payGrade').setValue(this.item.additionalInfo.payGrade);
        this.formGroup.get('minimumRate').setValue(this.item.additionalInfo.minimumRate);
        this.formGroup.get('maximumRate').setValue(this.item.additionalInfo.maximumRate);
        this.formGroup.get('perDiemRate').setValue(this.item.additionalInfo.perDiemRate);
        this.formGroup.get('occupationCode').setValue(this.item.additionalInfo.occupationCode);
        this.formGroup.get('workersCompCode').setValue(this.item.additionalInfo.workersCompCode);
        this.formGroup.get('fte').setValue(this.item.additionalInfo.fte);
    };
    ConfigurePositionEditorComponent.prototype.addSupervisorControls = function (group) {
        group.addControl('supervisorOrganization', new FormControl('', null));
        group.addControl('supervisorDepartment', new FormControl('', null));
        group.addControl('supervisorPosition', new FormControl('', null));
    };
    ConfigurePositionEditorComponent.prototype.addAdditionalControls = function (group) {
        if (!this.hasAdditionalControls) {
            group.addControl('eeocClass', new FormControl('', null));
            group.addControl('eeocGroup', new FormControl('', null));
            group.addControl('payType', new FormControl('', null));
            group.addControl('payGrade', new FormControl('', null));
            group.addControl('minimumRate', new FormControl('', null));
            group.addControl('maximumRate', new FormControl('', null));
            group.addControl('perDiemRate', new FormControl('', null));
            group.addControl('occupationCode', new FormControl('', null));
            group.addControl('workersCompCode', new FormControl('', null));
            group.addControl('fte', new FormControl('', null));
            this.hasAdditionalControls = true;
        }
    };
    ConfigurePositionEditorComponent.prototype.removeAdditionalControls = function (group) {
        if (this.hasAdditionalControls) {
            group.removeControl('eeocClass');
            group.removeControl('eeocGroup');
            group.removeControl('payType');
            group.removeControl('payGrade');
            group.removeControl('minimumRate');
            group.removeControl('maximumRate');
            group.removeControl('perDiemRate');
            group.removeControl('occupationCode');
            group.removeControl('workersCompCode');
            group.removeControl('fte');
            this.hasAdditionalControls = false;
        }
    };
    ConfigurePositionEditorComponent.prototype.loadDepartments = function (org) {
        var _this = this;
        if (!org)
            return;
        this.isDepartmentLookupProgress = true;
        this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: org.orgLevelId, employeeId: undefined })
            .then(function (departmentLookup) {
            _this.supervisorDepartmentLookup = new Lookup();
            _this.supervisorPositionLookup = new Lookup();
            _this.supervisorDepartmentLookup = departmentLookup;
            _this.isDepartmentLookupProgress = false;
        });
    };
    ConfigurePositionEditorComponent.prototype.onSupervisorDepartmentChange = function (dep) {
        var _this = this;
        if (!dep)
            return;
        this.isPositionLookupProgress = true;
        this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: dep.orgLevelId, employeeId: undefined })
            .then(function (positionLookup) {
            _this.isPositionLookupProgress = false;
            _this.supervisorPositionLookup = positionLookup;
            _this.supervisorPositionLookup.items = _.filter(positionLookup.items, function (po) {
                return po.id !== _this.item.id;
            });
        });
    };
    ConfigurePositionEditorComponent.prototype.removedropdownValue = function (property) {
        if (property == 'supervisorOrganization') {
            this.formGroup.get('supervisorOrganization').reset();
            this.formGroup.get('supervisorDepartment').reset();
            this.formGroup.get('supervisorPosition').reset();
        }
        else if (property == 'supervisorDepartment') {
            this.formGroup.get('supervisorDepartment').reset();
            this.formGroup.get('supervisorPosition').reset();
        }
        else if (property == 'supervisorPosition') {
            this.formGroup.get('supervisorPosition').reset();
        }
    };
    ConfigurePositionEditorComponent.prototype.getSettings = function () {
        var _this = this;
        this.appSettingsManageService.getAppServerConfig()
            .then(function (conf) {
            _this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;
            if (_this.isNextgenPayrollEnabled && !_this.isAddPosition) {
                _this.isSystemLogin = _this.sessionService.getUser() && _this.sessionService.getUser().username.toLowerCase() === 'system' ? true : false;
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ConfigurePositionEditorComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionEditorComponent.prototype, "orgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ConfigurePositionEditorComponent.prototype, "mainFormSubscription", void 0);
    return ConfigurePositionEditorComponent;
}(EditableListEditorComponent));
export { ConfigurePositionEditorComponent };
