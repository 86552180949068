<div class="sso-error-container">
  <div class="row shadow-lg p-3 mb-5 bg-body-tertiary rounded">
    <div class="slx-logo-sso">
      <img src="/assets/img/slx-logo-6-1-blue.png" height="100px" alt="Smartlinx">
    </div>
    <div class="error-content">
      <p>{{errorMessage}}</p>
      <span>Error code: {{errorCode}}</span>
    </div>
    <div class="back-btn-container">
      <button type="button" class="theme-button-apply back-button" (click)="navigateToLogin()" tabindex="4">
        Go Back
      </button>
    </div>
  </div>
</div>
