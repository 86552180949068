import { EmployeeFilterRecord, AcaInsightFilter, EmployeeIdList } from './aca-1095-c-record';

export interface IAca1095cState {
  aca1095cStateList: Aca1095cFilterState[];
}

export const initialAca1095cState: IAca1095cState = {
  aca1095cStateList: []
};

export class Aca1095cFilterState {
  public orgLevelId: number;
  public year: number;
  public insightsFilter: AcaInsightFilter;
  public employeeListFilter: EmployeeFilterRecord[];
  public employeeIdList: EmployeeIdList[]
  public pageNumber: number;
  public isExpandAll: boolean;
}