import * as tslib_1 from "tslib";
import { OnDestroy, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import * as _ from 'lodash';
import { ColumnSettingsStorageService, ColumnManagementService, ColumnsChangedEvent } from '../../../common/services/index';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../core/decorators/index';
import { AppSettingsManageService } from '../../../app-settings/services';
var ColumnsConfigButtonComponent = /** @class */ (function () {
    function ColumnsConfigButtonComponent(columnSettingsStorageService, columnManagementService, changeDetector, appSettingManageService) {
        this.columnSettingsStorageService = columnSettingsStorageService;
        this.columnManagementService = columnManagementService;
        this.changeDetector = changeDetector;
        this.colGroupKey = ColumnSettingsStorageService.defaultColumnGroupKey;
        this.popperPosition = 'bottom-start';
        this.width = 250;
        this.blueStyleConfig = { btnClass: '', iconClass: '' };
        this.appSettingManageService = appSettingManageService;
        this.columnChanged = new EventEmitter();
        this.selectedAll = new EventEmitter();
        this.clearedAll = new EventEmitter();
        this.uniqId = this.getComponentUniqueId();
    }
    ColumnsConfigButtonComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.groupInitializedSubscription = this.columnManagementService.groupInitialized$.filter(function (event) { return event.group === _this.colGroupKey; })
            .subscribe(function (event) {
            _this.isInitialized = true;
            _this.contextKey = event.component;
            _this.appSettingManageService.getAppServerConfig().then(function (conf) {
                if (conf.IsNextgenPayrollEnabled) {
                    var column = event.columns.find(function (x) { return x.description === 'Interface Indicator'; });
                    if (column != undefined && column != null) {
                        column.description = 'Payroll Pay Code';
                    }
                }
            });
            _this.columns = event.columns;
            _.forEach(_this.columns, function (column) {
                if (column.displayed === undefined) {
                    column.displayed = true;
                }
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            });
        });
    };
    ColumnsConfigButtonComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    Object.defineProperty(ColumnsConfigButtonComponent.prototype, "isBlueStyleBtn", {
        get: function () {
            var _a = this.blueStyleConfig || {}, _b = _a.btnClass, btnClass = _b === void 0 ? '' : _b, _c = _a.iconClass, iconClass = _c === void 0 ? '' : _c;
            return _.isString(btnClass) && _.size(btnClass) > 0
                || _.isString(iconClass) && _.size(iconClass) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColumnsConfigButtonComponent.prototype, "blueStyleBtnClass", {
        get: function () {
            var btnClass = [];
            if (!this.isInitialized) {
                btnClass.push('hidden-config-button');
            }
            if (_.size(this.blueStyleConfig.btnClass) > 0) {
                btnClass.push(this.blueStyleConfig.btnClass);
            }
            return btnClass.join(' ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColumnsConfigButtonComponent.prototype, "blueStyleIconClass", {
        get: function () {
            if (_.size(this.blueStyleConfig.iconClass) > 0) {
                return this.blueStyleConfig.iconClass;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    ColumnsConfigButtonComponent.prototype.onChangeColumn = function (column) {
        this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
        this.columnChanged.emit(column);
        this.saveColumns();
    };
    ColumnsConfigButtonComponent.prototype.selectAll = function () {
        _.forEach(this.columns, function (column) {
            column.displayed = true;
        });
        this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
        this.selectedAll.emit(this.columns);
        this.saveColumns();
    };
    ColumnsConfigButtonComponent.prototype.clearAll = function () {
        _.forEach(this.columns, function (column) {
            column.displayed = false;
        });
        this.columnManagementService.columnsChanged({ component: this.contextKey, group: this.colGroupKey, columns: this.columns });
        this.clearedAll.emit(this.columns);
        this.saveColumns();
    };
    ColumnsConfigButtonComponent.prototype.getComponentUniqueId = function () {
        return "b" + Math.random().toString(36).substr(2, 9);
    };
    ColumnsConfigButtonComponent.prototype.saveColumns = function () {
        this.columnSettingsStorageService.setColumnsState(this.contextKey, this.colGroupKey, this.columns);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ColumnsConfigButtonComponent.prototype, "groupInitializedSubscription", void 0);
    return ColumnsConfigButtonComponent;
}());
export { ColumnsConfigButtonComponent };
