import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

import { IBenefitsEmployeeDependentsEnrollment, BenefitsEmployeeDependentsEnrollmentValidity } from '../../../../common/index';

import { BenefitEmployeesApiService } from '../../../../app-modules/benefits/services/index';
import {
  BenefitEligibleDependentBenefit,
  BenefitEmployeeDependentsEnrollments
} from '../../../../app-modules/benefits/models/index';

@Injectable()
export class EmployeeSectionsBenefitsDependentEnrollmentService implements IBenefitsEmployeeDependentsEnrollment<BenefitEligibleDependentBenefit, BenefitEmployeeDependentsEnrollments, BenefitsEmployeeDependentsEnrollmentValidity> {
  private validity: BenefitsEmployeeDependentsEnrollmentValidity;
  constructor(private benefitsApiService: BenefitEmployeesApiService) {}

  public getDependentsEligibleBenefits(dependentsIds: Array<number>): Promise<BenefitEligibleDependentBenefit[]> {
    return this.benefitsApiService.getEmployeeDependentsEligibleBenefits(dependentsIds);
  }

  public enrollDependents(depsEnrollments: BenefitEmployeeDependentsEnrollments): Promise<void> {
    return this.benefitsApiService.addMultipleDependentsEnrollments(depsEnrollments);
  }

  public validateSelection(enrollments: Array<[Date, Date]>, dependents: Array<Date>): BenefitsEmployeeDependentsEnrollmentValidity {
    this.validity = new BenefitsEmployeeDependentsEnrollmentValidity();
    this.validateEndrollments(enrollments);
    this.validateDependents(dependents);

    return this.validity
  }

  public validateDates(sDate: Date, eDate: Date): BenefitsEmployeeDependentsEnrollmentValidity {
    this.validity = this.validity || new BenefitsEmployeeDependentsEnrollmentValidity();
    if (_.isDate(sDate) && _.isDate(eDate)) {
      const { startDate, endDate } = this.validity;
      const isDates = _.isDate(startDate) && _.isDate(endDate);
      if (isDates) {
        const isValid = startDate <= sDate && endDate >= eDate;
        if (!isValid) {
          if (_.isDate(this.validity.latestDepDOB) && startDate > sDate) {
            this.validity.setErrorInvalidDependents(true);
          } else {
            this.validity.setErrorInvalidBenefits(true);
          }
        } else {
          this.validity.setErrorInvalidDependents(false);
          this.validity.setErrorInvalidBenefits(false);
        }
      }
    }
    this.validity.setErrorInvalidStartOrEndDate(!(_.isDate(sDate) && _.isDate(eDate)));

    return this.validity;
  }

  private validateEndrollments(e: Array<[Date, Date]>): void {
    if (_.size(e) > 0) {
      const dates = _.filter(e, (d) => _.isDate(d[0]) && _.isDate(d[1]));
      const startDate = this.getMaxDate(_.map(dates, d => d[0]));
      const endDate = this.getMinDate(_.map(dates, d => d[1]));
      const minValidDate = this.getMinDate(_.map(dates, d => d[0]));
      const maxValidDate = this.getMaxDate(_.map(dates, d => d[1]));;
      if (startDate > endDate) {
        this.validity.setErrorInvalidBenefits(true);
        this.validity.startDate = new Date(minValidDate.getTime());
        this.validity.endDate = new Date(maxValidDate.getTime());
      } else {
        this.validity.setErrorInvalidBenefits(false);
        this.validity.startDate = new Date(startDate.getTime());
        this.validity.endDate = new Date(endDate.getTime());
      }
      this.validity.minDate = new Date(minValidDate.getTime());
      this.validity.maxDate = new Date(maxValidDate.getTime());
    }
    this.validity.setErrorNoBenefits(_.size(e) === 0);
  }

  private validateDependents(dependents: Array<Date>): void {
    if (_.size(dependents) > 0) {
      const { startDate, endDate } = this.validity;
      if (_.isDate(startDate) && _.isDate(endDate)) {
        const birthDates = _.reduce(dependents, (accum, dep) => {
          if(_.isDate(dep)) {
            accum.push(dep);
          }
          return accum;
        }, [] as Array<Date>);

        if (_.size(birthDates) > 0) {
          let newStartDate = new Date(startDate.getTime());
          _.forEach(birthDates, birthDate => {
            if (birthDate > newStartDate) {
              newStartDate = new Date(birthDate.getTime());
              this.validity.latestDepDOB = newStartDate;
            }
          });
          if (newStartDate > endDate) {
            this.validity.latestDepDOB = null;
            this.validity.setErrorInvalidDependents(true);
          } else {
            this.validity.setErrorInvalidDependents(false);
            this.validity.startDate = newStartDate;
          }
        }
      }
    }
    this.validity.setErrorNoDependents(_.size(dependents) === 0);
  }

  private getMaxDate(dates: Array<Date>): Date {
    if (_.isArray(dates) && _.size(dates) > 0) {
      let maxDate: Date = null;
      _.forEach(dates, date => {
        if (_.isDate(date)) {
          maxDate = maxDate || date;
          if (date > maxDate) {
            maxDate = date;
          }
        }
      });
      return _.isDate(maxDate) ? maxDate : null;
    }
    return null;
  }

  private getMinDate(dates: Array<Date>): Date {
    if (_.isArray(dates) && _.size(dates) > 0) {
      let minDate: Date = null;
      _.forEach(dates, date => {
        if (_.isDate(date)) {
          minDate = minDate || date;
          if (date < minDate) {
            minDate = date;
          }
        }
      });
      return _.isDate(minDate) ? minDate : null;
    }
    return null;
  }
}
