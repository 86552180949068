/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-employees-unenrolled-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../benefit-employees-unenrolled/benefit-employees-unenrolled.component.ngfactory";
import * as i3 from "../benefit-employees-unenrolled/benefit-employees-unenrolled.component";
import * as i4 from "./benefit-employees-unenrolled-dialog.component";
import * as i5 from "../../../../../common/models/dialog-options";
import * as i6 from "../../../../../common/services/modal/modal.service";
import * as i7 from "../../../models/benefit-employees/benefit-eligible-employees-request";
import * as i8 from "../../../services/benefit-employees/benefit-employees-management.service";
var styles_BenefitEmployeesDialogComponent = [i0.styles];
var RenderType_BenefitEmployeesDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitEmployeesDialogComponent, data: {} });
export { RenderType_BenefitEmployeesDialogComponent as RenderType_BenefitEmployeesDialogComponent };
export function View_BenefitEmployeesDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dialog-modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "slx-benefit-employees-unenrolled", [], null, [[null, "modeChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("modeChanged" === en)) {
        var pd_0 = (_co.modeChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_BenefitEmployeesUnenrolledComponent_0, i2.RenderType_BenefitEmployeesUnenrolledComponent)), i1.ɵdid(2, 114688, null, 0, i3.BenefitEmployeesUnenrolledComponent, [], { lineId: [0, "lineId"], tierId: [1, "tierId"], tierDetails: [2, "tierDetails"], planName: [3, "planName"], canEnroll: [4, "canEnroll"], isUserCanEnroll: [5, "isUserCanEnroll"], date: [6, "date"], lowLevelOrgId: [7, "lowLevelOrgId"], method: [8, "method"], benefitDetailsLine: [9, "benefitDetailsLine"], mappedDeduction: [10, "mappedDeduction"], canMapPayroll: [11, "canMapPayroll"] }, { modeChanged: "modeChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.request.lineDetails.line.id; var currVal_1 = _co.request.tierId; var currVal_2 = _co.request.tierDetails; var currVal_3 = _co.request.planName; var currVal_4 = _co.request.canEnroll; var currVal_5 = _co.request.isUserCanEnroll; var currVal_6 = _co.request.date; var currVal_7 = _co.request.lowLevelOrgId; var currVal_8 = _co.request.calcMethod; var currVal_9 = ((_co.request.lineDetails == null) ? null : _co.request.lineDetails.line); var currVal_10 = ((_co.request.lineDetails == null) ? null : _co.request.lineDetails.mappedDeduction); var currVal_11 = _co.request.canMapPayroll; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_BenefitEmployeesDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-employees-unenrolled-dialog", [], null, null, null, View_BenefitEmployeesDialogComponent_0, RenderType_BenefitEmployeesDialogComponent)), i1.ɵdid(1, 245760, null, 0, i4.BenefitEmployeesDialogComponent, [i5.DialogOptions, i6.ModalService, i7.BenefitEligibleEmployeesRequest, i8.BenefitEmployeeManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitEmployeesDialogComponentNgFactory = i1.ɵccf("slx-benefit-employees-unenrolled-dialog", i4.BenefitEmployeesDialogComponent, View_BenefitEmployeesDialogComponent_Host_0, {}, {}, []);
export { BenefitEmployeesDialogComponentNgFactory as BenefitEmployeesDialogComponentNgFactory };
