<form class="dialog-form" #dialogForm="ngForm">
    <div class="modal-body-report">
      <slx-report-parameters-list [parameters]="parameters" [reportDefinition]="reportDefinition"></slx-report-parameters-list>
      <div>
        <span class="theme-md-text">Report type:</span>
        <slx-input-decorator className="slx-form-group slx-no-border small-font">
          <slx-radio-input slx-input name="preview" fieldName="preview" [option]="0" caption="View Report" [(ngModel)]="reportAction">
          </slx-radio-input>
        </slx-input-decorator>
        <slx-input-decorator className="slx-no-label slx-no-border slx-no-error-block small-font">
          <slx-radio-input slx-input name="download" fieldName="download" [option]="1" caption="Download Report" [(ngModel)]="reportAction">
          </slx-radio-input>
        </slx-input-decorator>
      </div>
      <div>
        <span class="theme-exsm-text">Please click 'Generate' button.</span>
      </div>
    </div>
    <div class="modal-footer wrapper">
      <div class="btn red pad-top-10" (click)="onCancel()"><i class="fas fa-times slx-button__icon" aria-hidden="true"></i>Cancel
      </div>
      <div class="btn green pad-top-10" (click)="onOk()" [ngClass]="{ 'disabled-content': dialogForm.invalid || !isValidResult }"><i
              class="fas fa-save slx-button__icon" aria-hidden="true"></i>Generate</div>
    </div>
</form>
