/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accrual-balance-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "../../../common/pipes/slx-decimal24-switch";
import * as i5 from "./accrual-balance-dialog.component";
import * as i6 from "../../models/accrual-balance";
import * as i7 from "../../../common/models/dialog-options";
import * as i8 from "../../../common/services/modal/modal.service";
import * as i9 from "../../../app-settings/services/app-settings-manage.service";
var styles_AccrualBalanceDialogComponent = [i0.styles];
var RenderType_AccrualBalanceDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccrualBalanceDialogComponent, data: {} });
export { RenderType_AccrualBalanceDialogComponent as RenderType_AccrualBalanceDialogComponent };
function View_AccrualBalanceDialogComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "accrual-name col-xs-6 col-sm-6 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p", [["class", "accrual-value col-xs-6 col-sm-6 col-lg-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.benefitType.fieldValue; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.accruedHours.fieldValue, _co.isShowHighPrecision)); _ck(_v, 4, 0, currVal_1); }); }
function View_AccrualBalanceDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "form", [["class", "row accruals"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-xs-12 col-sm-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccrualBalanceDialogComponent_2)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = ((_co.employeeAccruals == null) ? null : _co.employeeAccruals.accruals); _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_AccrualBalanceDialogComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "not-found"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No entries found..."]))], null, null); }
export function View_AccrualBalanceDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.SlxDecimal24Switch, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "accruals"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h4", [["class", "employee-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "employee-position"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccrualBalanceDialogComponent_1)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccrualBalanceDialogComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "theme-button-cancel"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.hasAccruals; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.hasAccruals; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.employeeAccruals.employeeName; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.employeeAccruals.positionName; _ck(_v, 7, 0, currVal_1); }); }
export function View_AccrualBalanceDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-accrual-balance-dialog", [], null, null, null, View_AccrualBalanceDialogComponent_0, RenderType_AccrualBalanceDialogComponent)), i1.ɵdid(1, 114688, null, 0, i5.AccrualBalanceDialogComponent, [i6.AccrualBalanceDialogOptions, i7.DialogOptions, i8.ModalService, i9.AppSettingsManageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccrualBalanceDialogComponentNgFactory = i1.ɵccf("slx-accrual-balance-dialog", i5.AccrualBalanceDialogComponent, View_AccrualBalanceDialogComponent_Host_0, {}, {}, []);
export { AccrualBalanceDialogComponentNgFactory as AccrualBalanceDialogComponentNgFactory };
