<div class="modal-content">
  <div class="modal-body">
    <div class="col-xs-12">
      <fieldset>
        <legend>Text Content</legend>
        <textarea type="text" maxlength="255" rows="5" placeholder="Enter information for the call log" [(ngModel)]="textMsg"></textarea>
      </fieldset>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
    <button type="button" (click)="onOk()" class="btn btn-primary">OK</button>
  </div>
</div>
