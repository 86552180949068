import { IBenefitDetailsBasic, BenefitDetailsBasic } from '../../../../app-modules/benefits/models/index';

export interface IBenefitsEmpEnrollOptionRate {
    id: number;
    lineId: number;
    tierId: number;
    tierName: string;
    tierOptionType: string;
    tierOptionCode: string;
    tierOptionCost: number;
    tierOptionEmpContribution: number;
    tierOptionErContribution: number;
    costFreq: IBenefitDetailsBasic<number, string>;
    empContFreq: IBenefitDetailsBasic<number, string>;
}

export class BenefitsEmpEnrollOptionRate {
    public id: number;
    public lineId: number;
    public tierId: number;
    public tierName: string;
    public tierOptionType: string;
    public tierOptionCode: string;
    public tierOptionCost: number;
    public tierOptionEmpContribution: number;
    public tierOptionErContribution: number;
    public costFreq: BenefitDetailsBasic<number, string>;
    public empContFreq: BenefitDetailsBasic<number, string>;
}
