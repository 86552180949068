import { Injectable } from '@angular/core';
import { AttendancePointsMapService } from './attendance-points-map.service';
import { ApiUtilService, UrlParamsService } from '../../../common/services';
import { IAttendancePointsTotalsData, AttendancePointsTotalsData } from '../../models/attendance-points/attendance-points-totals';
import { Assert } from '../../../framework';
import { FieldsMeta, ResponseBody } from '../../../core/models';
import { timeAndAttendanceConfig } from '../../time-and-attendance.config';

@Injectable()
export class AttendancePointsApiService {

    constructor(private mapService: AttendancePointsMapService, private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService) { }


    public loadAttendancePointsTotals(orgLevelId: number, isActiveOnly: boolean): Promise<AttendancePointsTotalsData> {
        Assert.isNotNull(orgLevelId, 'orgLevelId');
        const url: string = `${this.getAttApiTotals(orgLevelId)}`;

        const params: StringMap<any> = {
            activeOnly: isActiveOnly
        };

        const request = this.urlParamsService.createGetRequest(url, params);
        return this.apiUtilService
            .request<IAttendancePointsTotalsData, FieldsMeta>(request)
            .then((response: ResponseBody<IAttendancePointsTotalsData, FieldsMeta>) => this.mapService.mapTotalsData(response.data));
    }

    private getApiRoot(): string {
        return this.apiUtilService.getApiRoot();
    }

    private getAttApiTotals(orgLeveI: number): string {
        return `${this.getApiRoot()}/${timeAndAttendanceConfig.api.root}/${timeAndAttendanceConfig.api.attendancePoints.root}/${orgLeveI}/totals`;
    }

}