import { FieldData } from '../../../../core/models/index';
import { EmployeeSectionsBase } from '../employee-sections-base';

export interface IAddAttendancePointsRequest {
  definitionIds: number[];
}

export interface IDeleteAttendancePointsRequest {
  pointIds: number[];
}

export interface IEmployeeSectionsAttendancePoints {
  currentBalance: number;
  points: IEmployeeSectionsAttendancePointsEntry[];
}

export interface IEmployeeSectionsAttendanceBuybacks {
  points: IEmployeeSectionsAttendancePointsEntry[];
}

export interface IEmployeeSectionsAttendancePointsEntry {
  id: number;
  dateOn: string;
  expiration: string;
  categoryName: string;
  definition: string;
  value: number;
  value2: number;
}

export class EmployeeSectionsAttendancePointsEntry {
  public id: number;
  public dateOn: FieldData<Date>;
  public expiration: FieldData<Date>;
  public categoryName: FieldData<string>;
  public definition: FieldData<string>;
  public value: FieldData<number>;
  public value2: FieldData<number>;
}

export class EmployeeSectionsAttendancePoints extends EmployeeSectionsBase {

  public currentBalance: number;
  public points: EmployeeSectionsAttendancePointsEntry[];

  constructor() {
    super();
    this.points = [];
  }
}

export class EmployeeSectionsAttendanceBuybacks extends EmployeeSectionsBase {

  public points: EmployeeSectionsAttendancePointsEntry[];

  constructor() {
    super();
    this.points = [];
  }
}
