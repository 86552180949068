import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { MetaMapService } from '../../../core/services/index';
import { Unit, IUnit } from '../../models/index';
import { Meta } from '../../../core/models/api/meta';
import { FieldsMeta, Actions } from '../../../core/models/index';
import { ResponseBody } from '../../../core/models/index';
import { ApiUtilService, CacheUtilService, UrlParamsService } from '../../../common/services/index';

import { UnitsMapService } from './units-map.service';
import { configurationConfig } from '../../configuration.config';

@Injectable()
export class UnitsApiService {
  constructor(private apiUtilService: ApiUtilService,
    private unitsMapService: UnitsMapService,
    private metaMapService: MetaMapService,
    private cacheUtilService: CacheUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public saveUnit(unit: Unit, orgLevelId: number): Promise<Unit> {
    let unitId: string = unit.id > 0 ? '/' + unit.id : '';
    let url: string = `${this.getUrlRoot(orgLevelId)}${unitId}`;
    let request: HttpRequest<any> = new HttpRequest((unit.id > 0 ? 'PUT' : 'POST'), url, this.unitsMapService.convertUnitToData(unit));

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<IUnit, Meta>) => {
        this.cacheUtilService.clear();
        return this.unitsMapService.updateUnitFromData(unit, response.data);
      });
    return promise;
  }

  public removeUnit(unitId: number, orgLevelId: number, reassignedUnitId: number): Promise<any> {
    let url: string = `${this.getUrlRoot(orgLevelId)}/${unitId}`;
    let request: HttpRequest<any>;
    if (!_.isNil(reassignedUnitId)) {
      request = this.urlParamsService.createDeleteRequest(url, { reassignedUnitId: reassignedUnitId });
    } else {
      request = this.urlParamsService.createDeleteRequest(url, {});
    }
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<number, Meta>) => {
        this.cacheUtilService.clear();
        return response.status;
      });
    return promise;
  }

  public getUnitsList(orgLevelId: number): Promise<{ actions: Actions, records: Unit[] }> {
    let request: HttpRequest<any> = new HttpRequest('GET', this.getUrlRoot(orgLevelId));

    return this.apiUtilService.request<IUnit[], Meta>(request)
      .then((response: ResponseBody<IUnit[], Meta>) => {
        let actions: Actions = this.metaMapService.mapActions(response.meta as FieldsMeta);
        return { actions: actions, records: this.unitsMapService.mapUnits(response) };
      });
  }

  private getUrlRoot(orgLevelId: number): string {
    return `${this.apiUtilService.getApiRoot()}/${configurationConfig.api.configuration.root}/${configurationConfig.api.configuration.orglevel.root}/${orgLevelId}/${configurationConfig.api.configuration.units.root}`;
  }
}
