export function isDestroyed(): PropertyDecorator {
  return function decorate(target: any, key: string | symbol): void {
    let ngOnDestroyFunction: Function = target['ngOnDestroy'];
    let ngOnDestroy: Function = function (): void {
      if (this) {
        this[key] = true;
        if (ngOnDestroyFunction) {
          ngOnDestroyFunction.call(this);
        }
      }
    };
    target['ngOnDestroy'] = ngOnDestroy;
  };
}
