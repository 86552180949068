import { Pipe, Injectable, PipeTransform } from '@angular/core';
import { dateTimeUtils } from '../utils/index';
@Pipe({
  name: 'slxMaxChars',
  pure: false
})
@Injectable()
export class SlxMaxCharsPipe implements PipeTransform {
  public transform(obj?: string, maxChars?: number, useRestriction: boolean = true): string {
    if(!useRestriction) return obj;
    if(obj.length > maxChars)
      obj = obj.substr(0, maxChars) + '...';
    return obj;
  }
}
