import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ManageGroupsManagementService } from '../../../../services/index';
var ManagePositionGroupsComponent = /** @class */ (function () {
    function ManagePositionGroupsComponent(manService) {
        this.manService = manService;
        this.searchText = '';
        this.onLoading = new EventEmitter();
        this.onHasChanges = new EventEmitter();
        this.groupNameIsValid = false;
        this.subscriptions = {};
        this.indexToInsertNewGroup = 1;
    }
    Object.defineProperty(ManagePositionGroupsComponent.prototype, "isEditing", {
        get: function () {
            return _.isObjectLike(this.editingGroup);
        },
        enumerable: true,
        configurable: true
    });
    ManagePositionGroupsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.manService.init();
        this.subscriptions.loading = this.manService
            .subscribeToLoading(function (isLoading) {
            _this.onLoading.emit(isLoading);
        });
        this.subscriptions.groups = this.manService
            .subscribeToLoadGroups(function (groups) {
            _this.groups = groups;
            _this.storedGroups = _.cloneDeep(groups);
        });
        this.subscriptions.positions = this.manService
            .subscribeToLoadPositions(function (lookup) {
            _this.positionsLookup = lookup;
        });
    };
    ManagePositionGroupsComponent.prototype.ngOnDestroy = function () {
        this.manService.destroy();
    };
    ManagePositionGroupsComponent.prototype.isDefaultGroup = function (group) {
        return group.id === this.manService.defaultGroupId;
    };
    ManagePositionGroupsComponent.prototype.canSaveGroup = function (group) {
        return this.isEditing && group.isEditing && _.size(group.name) > 0 && this.groupNameIsValid;
    };
    ManagePositionGroupsComponent.prototype.canEditGroup = function (group) {
        return !this.isEditing && !group.isEditing;
    };
    ManagePositionGroupsComponent.prototype.canRemoveGroup = function (group) {
        return !this.isEditing && group.positions.length === 0;
    };
    ManagePositionGroupsComponent.prototype.canDragNDropGroups = function () {
        return !this.isEditing;
    };
    ManagePositionGroupsComponent.prototype.onInputGroupName = function (model, group) {
        var groupName = _.trim(_.toLower(group.name));
        var groupViews = _.filter(this.groups, function (g) { return _.trim(_.toLower(g.name)) === groupName; });
        if (_.size(groupViews) > 1) {
            model.control.setErrors({ 'uniqueName': true });
            this.groupNameIsValid = false;
        }
        else {
            var errors = model.control.errors || {};
            delete errors['uniqueName'];
            model.control.setErrors(errors);
            this.groupNameIsValid = _.size(errors) === 0;
        }
    };
    ManagePositionGroupsComponent.prototype.onPressEnter = function (event, model, group) {
        if (event.key === 'Enter' && _.size(model.control.errors) === 0) {
            this.onSave(group);
        }
    };
    ManagePositionGroupsComponent.prototype.onAdd = function () {
        var group = this.manService.createGroupView();
        this.groups.splice(this.indexToInsertNewGroup, 0, group);
        this.toggleEdit(group, true);
    };
    ManagePositionGroupsComponent.prototype.onCancel = function (group) {
        if (!_.isFinite(group.id)) {
            this.deleteGroup(this.groups, group);
        }
        this.toggleEdit(group, false, true);
    };
    ManagePositionGroupsComponent.prototype.onEdit = function (group) {
        if (this.canEditGroup(group)) {
            this.toggleEdit(group, true);
        }
    };
    ManagePositionGroupsComponent.prototype.onSave = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.canSaveGroup(group)) return [3 /*break*/, 5];
                        if (!!_.isFinite(group.id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.createGroup(group)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.updateGroup(group)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        this.emitChanges();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ManagePositionGroupsComponent.prototype.onDelete = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.canRemoveGroup(group) && _.isFinite(group.id))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.manService.deleteGroup(group)];
                    case 1:
                        _a.sent();
                        this.deleteGroup(this.groups, group);
                        this.deleteGroup(this.storedGroups, group);
                        this.emitChanges();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManagePositionGroupsComponent.prototype.onDragAdd = function (event) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.movedPosition = event.dataItem;
                return [2 /*return*/];
            });
        });
    };
    ManagePositionGroupsComponent.prototype.onDragStart = function (group) {
        this.movedFrom = group;
    };
    ManagePositionGroupsComponent.prototype.onDragEnd = function (movedTo) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.canDragNDropGroups() && _.isObjectLike(this.movedPosition))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.manService.assignGroupsToPositions(movedTo, this.movedPosition)];
                    case 1:
                        _a.sent();
                        this.applyChangesForStoredGroups(this.movedFrom, movedTo, this.movedPosition);
                        this.emitChanges();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    ManagePositionGroupsComponent.prototype.onFilterChanged = function () {
        this.groups = this.manService.filteringGroups(this.storedGroups, this.searchText);
    };
    ManagePositionGroupsComponent.prototype.onResetSearch = function () {
        if (this.searchText.length > 0) {
            this.searchText = '';
            this.onFilterChanged();
        }
    };
    ManagePositionGroupsComponent.prototype.createGroup = function (g) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var createdGroup, index;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.createGroup(g)];
                    case 1:
                        createdGroup = _a.sent();
                        if (_.isObjectLike(createdGroup)) {
                            index = this.deleteGroup(this.groups, g);
                            if (index !== -1) {
                                this.groups.splice(index, 0, createdGroup);
                                this.storedGroups.splice(index, 0, _.cloneDeep(createdGroup));
                            }
                            this.toggleEdit(createdGroup, false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePositionGroupsComponent.prototype.updateGroup = function (group) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var updatedGroup, storedIndex;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.manService.updateGroup(group)];
                    case 1:
                        updatedGroup = _a.sent();
                        if (_.isObjectLike(updatedGroup)) {
                            group.name = updatedGroup.name;
                            storedIndex = this.findIndex(this.storedGroups, group);
                            if (storedIndex !== -1) {
                                this.storedGroups[storedIndex].name = updatedGroup.name;
                            }
                            this.toggleEdit(group, false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ManagePositionGroupsComponent.prototype.toggleEdit = function (group, isEditing, isRestore) {
        if (isRestore === void 0) { isRestore = false; }
        if (isEditing) {
            this.editingGroup = _.cloneDeep(group);
            group.isEditing = true;
            return;
        }
        if (isRestore) {
            group.name = this.editingGroup.name;
        }
        group.isEditing = false;
        this.editingGroup = null;
    };
    ManagePositionGroupsComponent.prototype.applyChangesForStoredGroups = function (movedFrom, movedTo, pos) {
        var position = _.cloneDeep(pos);
        var movedFromIndex = this.findIndex(this.storedGroups, movedFrom);
        if (movedFromIndex !== -1) {
            _.remove(this.storedGroups[movedFromIndex].positions, function (p) { return p.id === position.id; });
        }
        var movedToIndex = this.findIndex(this.storedGroups, movedTo);
        if (movedToIndex !== -1) {
            var positions = this.storedGroups[movedToIndex].positions;
            var posIndex = _.findIndex(positions, { id: position.id });
            if (posIndex === -1) {
                positions.push(position);
            }
            else {
                positions.splice(posIndex, 1, position);
            }
        }
    };
    ManagePositionGroupsComponent.prototype.emitChanges = function () {
        this.onHasChanges.emit(true);
    };
    ManagePositionGroupsComponent.prototype.deleteGroup = function (groups, group) {
        var index = this.findIndex(groups, group);
        if (index !== -1) {
            groups.splice(index, 1);
        }
        return index;
    };
    ManagePositionGroupsComponent.prototype.findIndex = function (groups, group) {
        return _.findIndex(groups, function (g) { return g.id === group.id || g.name === group.name; });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ManagePositionGroupsComponent.prototype, "subscriptions", void 0);
    return ManagePositionGroupsComponent;
}());
export { ManagePositionGroupsComponent };
