<form #oshaReport="ngForm">
    <div class="incident-report-body" *ngIf="osha && lookups">

        <div class="incident-report-item actions-bar">
            <span>
                <button [popover]="confirmPopover"
                    class="disable-btn slx-button slx-with-icon slx-margin-r"
                    type="button" [disabled]="!report.editMode"
                    >
                    <i aria-hidden="true" class="fas fa-ban slx-button__icon"></i>
                    <span class="slx-button__text">Disable OSHA Form</span>
                </button>
                <popover-content #confirmPopover title="Are you sure?" placement="auto bottom">
                    <span class="disable-message">
                        By disabling this form you will lose all data entered. This will set the OSHA Recordable status in the Incident Report to No. Are you sure you wish to continue?
                    </span>
                    <button type="button" class="slx-button slx-blue slx-narrow slx-margin-r"
                        (click)="onClickDisable(confirmPopover)">Yes</button>
                    <button type="button" class="slx-button slx-narrow" (click)="onClickCancel(confirmPopover)">No</button>
                </popover-content>
            </span>
        </div>

        <div class="incident-report-item osha-details">
            <slx-wc-report-section title="OSHA Details" icon="far fa-file-medical">
                <div class="panel-content">
                    <div class="osha-details-section">
                        <!-- osha details controls-->
                        <p class="flex report-control">
                            <label class="report-control-label .min-width-110">
                                OSHA Case Number
                            </label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                    [(ngModel)]="report.common.caseNumber"
                                    name="caseNumber" autocomplete="off" type="text"
                                    [readonly]="!report.editMode"
                                    />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__shrink min-width-110">
                                Will this case also be a workers' compensation case?
                            </label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list
                                    name="compCase"
                                    class="slx-wide slx-short"
                                    valueField="id" titleField="name"
                                    [options]="lookups.compCases"
                                    [(ngModel)]="osha.workersCompCase"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.compCases" class="slx-error-block">
                                    There is no Workers' Compensaction Cases configured in system
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">OSHA Injury Type</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list
                                    name="oshaInjuryType"
                                    class="slx-wide slx-short"
                                    valueField="id" titleField="description"
                                    [options]="lookups.oshaInjuryTypes"
                                    [(ngModel)]="osha.oshaInjuryType"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.oshaInjuryTypes" class="slx-error-block">
                                    There is no OSHA Injury Types configured in system
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">Privacy Case?</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isPrivacyCaseYes" fieldName="isPrivacyCaseYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="osha.isPrivacyCase"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isPrivacyCaseNo" fieldName="isPrivacyCaseNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="osha.isPrivacyCase"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item injury-details">
            <slx-wc-report-section title="Injury Details" icon="far fa-band-aid">
                <div class="panel-content">
                    <div class="injury-details-section">
                        <span>Did the injury result in: (check all that apply)</span>
                        <!-- injury details controls-->
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">Lost Days</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isLostDaysYes" fieldName="isLostDaysYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="osha.isLostDays"
                                    (ngModelChange)="onisLostDaysChanged($event)"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isLostDaysNo" fieldName="isLostDaysNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="osha.isLostDays"
                                    (ngModelChange)="onisLostDaysChanged($event)"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="osha.isLostDays" class="flex report-control sub-control">
                            <label class="report-control-label flex__shrink min-width-150">Start Date</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker name="lostDaysStartDate"
                                    class="slx-wide slx-short"
                                    [(ngModel)]="osha.lostDaysStartDate"
                                    required="true"
                                    [readonly]="!report.editMode"
                                    #lostDaysStartDate="ngModel"
                                    [minDate]="report.incidentDateTime"
                                    [slxMinDate]="report.incidentDateTime"
                                    (ngModelChange)="updateDays()"
                                    >
                                </slx-datepicker>
                                <span *ngIf="lostDaysStartDate.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p *ngIf="osha.isLostDays" class="flex report-control sub-control">
                            <label class="report-control-label min-width-150">End Date</label>
                            <span class="flex__grow max-width-250">
                                <slx-datepicker name="lostDaysEndDate"
                                    class="slx-wide slx-short"
                                    [(ngModel)]="osha.lostDaysEndDate"
                                    required="true"
                                    [readonly]="!report.editMode"
                                    #lostDaysEndDate="ngModel"
                                    [minDate]="osha.lostDaysStartDate"
                                    [slxMinDate]="osha.lostDaysStartDate"
                                    (ngModelChange)="updateDays()"
                                    >
                                </slx-datepicker>
                                <span *ngIf="lostDaysEndDate.errors" class="slx-error-block error-box">
                                    <span *ngIf="lostDaysEndDate.errors.required" errorMessage for="required"></span>
                                    <span *ngIf="lostDaysEndDate.errors.date" errorMessage>Date is out of valid range</span>
                                    <span *ngIf="lostDaysEndDate.errors.minDate" errorMessage>Should be greater than Start Date</span>
                                </span>
                            </span>
                        </p>
                        <p *ngIf="osha.isLostDays" class="flex report-control sub-control">
                            <label class="report-control-label min-width-150">Number Of Days</label>
                            <span class="flex__shrink min-width-110">
                                <slx-number class="slx-wide slx-input-number"
                                    name="lostDaysCount"
                                    [ngModel]="osha.lostDaysCount"
                                    [min]="0.01"
                                    [slxMax] ="maxDiff"
                                    [step]="0.01"
                                    [readonly]="true"
                                    (ngModelChange)="updateDiff($event)"
                                    #lostDaysCount="ngModel"
                                    [autoCorrect]="true"
                                ></slx-number>
                            </span>
                            <span *ngIf="lostDaysCount.errors" class="slx-error-block">
                                <span *ngIf="lostDaysCount.errors.max" errorMessage for="max">Number of Days cannot be greater than the number of days between the Start Date and End Date.</span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">Restricted Duty Days</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isRestrictedDutyYes"
                                    fieldName="isRestrictedDutyYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="osha.isRestrictedDuty"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onisRestrictedDutyChanged($event)"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isRestrictedDutyNo" fieldName="isRestrictedDutyNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="osha.isRestrictedDuty"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onisRestrictedDutyChanged($event)"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="osha.isRestrictedDuty" class="flex report-control sub-control">
                            <label class="report-control-label .min-width-110">Number Of Days</label>
                            <span class="flex__shrink min-width-110">
                                <input class="report-control-field"
                                    name="restrictedDutyDaysNum"
                                    [(ngModel)]="osha.restrictedDutyDaysNum"
                                    min="0" [slxMin]="0"
                                    type="number"
                                    [readonly]="!report.editMode"
                                    (keydown)="onKeydown($event)"
                                    (paste)="(false)"
                                    />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">Death</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isDeadYes" fieldName="isDeadYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="report.common.isDead"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onIsDeadChanged($event)">
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isDeadNo" fieldName="isDeadNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="report.common.isDead"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onIsDeadChanged($event)">
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="report.common.isDead" class="flex report-control sub-control">
                            <label class="report-control-label min-width-200">
                                Date of Death
                            </label>
                            <span class="flex__grow max-width-200">
                                <slx-datepicker
                                    name="dateOfDeath"
                                    class="slx-wide slx-short"
                                    [(ngModel)]="report.common.dateOfDeath"
                                    [readonly]="!report.editMode"
                                    #dateOfDeath="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateOfDeath.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p *ngIf="report.common.isDead" class="flex report-control sub-control">
                            <label class="report-control-label min-width-200">
                                Date Employer Notified of Death
                            </label>
                            <span class="flex__grow max-width-200">
                                <slx-datepicker
                                    name="dateErNotifiedDeath"
                                    class="slx-wide slx-short"
                                    [(ngModel)]="report.common.dateErNotifiedDeath"
                                    [minDate]="report.common.dateOfDeath"
                                    [readonly]="!report.editMode"
                                    #dateErNotifiedDeath="ngModel"
                                    [minDate]="minimumDate"
                                    [slxMinDate]="minimumDate"
                                    >
                                </slx-datepicker>
                                <span *ngIf="dateErNotifiedDeath.errors" class="slx-error-block">
                                    Incorrect Date
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-200">
                                Time Employee Began Work
                            </label>
                            <span class="flex__grow max-width-150">
                                <slx-timepicker
                                    name="empBeganWork"
                                    class="slx-wide slx-short"
                                    [(ngModel)]="report.common.empBeganWork"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-timepicker>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-200">
                                The Injury occured
                            </label>
                            <span class="flex__grow max-width-150">
                                <slx-dropdown-list
                                    name="incidentLocations"
                                    class="slx-wide slx-short"
                                    valueField="id" titleField="description"
                                    [options]="lookups.occurences"
                                    [readonly]="!report.editMode"
                                    [(ngModel)]="report.common.injuryOccured">
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.occurences" class="slx-error-block">
                                    There is no Incident Locations configured in system
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                After the injury, the employee
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="isShiftFinishedYes" fieldName="isShiftFinishedYes"
                                    [option]="true" caption="Finished Shift"
                                    [(ngModel)]="osha.isShiftFinished"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="isShiftFinishedNo" fieldName="isShiftFinishedNo"
                                    [option]="false" caption="Left for the day"
                                    [(ngModel)]="osha.isShiftFinished"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was the employee admitted to the hospital?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasInHospitalYes" fieldName="wasInHospitalYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="report.common.wasHospitalized"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onwasInHospitalChanged($event)"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasInHospitalNo" fieldName="wasInHospitalNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="report.common.wasHospitalized"
                                    [readonly]="!report.editMode"
                                    (ngModelChange)="onwasInHospitalChanged($event)"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="report.common.wasHospitalized" class="flex report-control sub-control">
                            <label class="report-control-label .min-width-150">Number Of Days</label>
                            <span class="flex__grow max-width-250">
                                <input class="report-control-field"
                                    name="admittedHospitalDaysNum"
                                    [(ngModel)]="osha.admittedHospitalDaysNum"
                                    min="0" [slxMin]="0"
                                    type="number"
                                    [readonly]="!report.editMode"
                                    />
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was the physical therapy ordered by the physician?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                name="wasPhysTherapyOrderedYes"
                                    fieldName="wasPhysTherapyOrderedYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="osha.wasPhysTherapyOrdered"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasPhysTherapyOrderedNo"
                                    fieldName="wasPhysTherapyOrderedNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="osha.wasPhysTherapyOrdered"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was the employee placed on transitional duty or other restriction?
                            </label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasEmpRestrictionYes" fieldName="wasEmpRestrictionYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="osha.wasEmpRestriction"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasEmpRestrictionNo" fieldName="wasEmpRestrictionNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="osha.wasEmpRestriction"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p *ngIf="osha.wasEmpRestriction" class="flex report-control label-at-top sub-control">
                            <label class="report-control-label flex-full-line-label">
                                What restriction?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                [(ngModel)]="osha.empRestriction"
                                [readonly]="!report.editMode"
                                name="empRestriction"
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                What was employee doing at the time of injury?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    [ngModel]="report.common.whatDoingDuringInjury"
                                    name="whatDoingDuringInjury"
                                    readonly
                                    maxlength="255"
                                    >
                                </textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">How did the injury occur?</label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="howInjuryOccur"
                                [ngModel]="report.common.howInjuryOccur"
                                readonly
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                What object or substance directly harmed the employee?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="whatHarmed"
                                [(ngModel)]="report.common.whatHarmed"
                                [readonly]="!report.editMode"
                                maxlength="255"
                                >
                                </textarea>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label min-width-110">Body part</label>
                            <span class="flex__grow max-width-250">
                                <slx-dropdown-list
                                    name="bodyParts"
                                    class="slx-wide slx-short"
                                    valueField="id" titleField="description"
                                    [options]="lookups.bodyParts"
                                    [(ngModel)]="report.common.bodyPart"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-dropdown-list>
                                <span *ngIf="!lookups.bodyParts" class="slx-error-block">
                                    There is no Body Parts codes configured in system
                                </span>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                What was employee doing just before the incident occured?
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    name="whatDoingBefore"
                                    [(ngModel)]="report.common.whatDoingBefore"
                                    [readonly]="!report.editMode"
                                    maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Describe activity as well as the tools, equipment, or material employee was using. Be specific.
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="activityDescription"
                                [(ngModel)]="report.common.activityDescription"
                                [readonly]="!report.editMode"
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Name of physician
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                name="physicianName"
                                [(ngModel)]="osha.physicianName"
                                [readonly]="!report.editMode"
                                maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee treated in an emergency room?</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasTreatedToEmergencyRoomYes"
                                    fieldName="wasTreatedToEmergencyRoomYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="report.common.wasTreatedToEmergencyRoom"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasTreatedToEmergencyRoomNo"
                                    fieldName="wasTreatedToEmergencyRoomNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="report.common.wasTreatedToEmergencyRoom"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control">
                            <label class="report-control-label flex__grow min-width-150">
                                Was employee hostitalized overnight as an in-patient?</label>
                            <span class="flex__shrink min-width-150">
                                <slx-radio-button class="p-left-0"
                                    name="wasHospitalizedYes" fieldName="wasHospitalizedYes"
                                    [option]="true" caption="Yes"
                                    [(ngModel)]="report.common.wasHospitalized"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                                <slx-radio-button class="p-left-0"
                                    name="wasHospitalizedNo" fieldName="wasHospitalizedNo"
                                    [option]="false" caption="No"
                                    [(ngModel)]="report.common.wasHospitalized"
                                    [readonly]="!report.editMode"
                                    >
                                </slx-radio-button>
                            </span>
                        </p>
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                If treatment was given away from the worksite, where it was given?
                                (include name, address, city, state and ZIP code or facility)
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    name="awayTreatment"
                                    [(ngModel)]="report.common.awayTreatment"
                                    [readonly]="!report.editMode"
                                    maxlength="255"
                                ></textarea>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>

        <div class="incident-report-item comments">
            <slx-wc-report-section title="Comments" icon="far fa-comment-alt" [notifications]="commentsCount">
                <div class="panel-content">
                    <div class="comments-section">
                        <p class="flex report-control label-at-top">
                            <label class="report-control-label flex-full-line-label">
                                Other comments about incident/injury
                            </label>
                            <span class="flex-full-line-control">
                                <textarea class="full-width-textarea"
                                    [(ngModel)]="osha.otherComments"
                                    [readonly]="!report.editMode"
                                    name="otherComments"
                                    maxlength="255"
                                ></textarea>
                                <span *ngIf="osha.otherComments" class="comment-field-note">{{osha.otherComments.length}}/255</span>
                            </span>
                        </p>
                    </div>
                </div>
            </slx-wc-report-section>
        </div>
    </div>
</form>
