import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';
import { EmployeeBenefitClass } from '../../../../../../organization/models/index';
import { DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/index';
import { appConfig, IApplicationConfig } from '../../../../../../app.config';

@Component({
    moduleId: module.id,
    selector: 'slx-confirm-benefit-changes-dialog',
    templateUrl: 'confirm-benefit-changes-dialog.component.html',
    styleUrls: ['confirm-benefit-changes-dialog.component.scss']
})
export class ConfirmBenefitChangesDialogComponent {

    public options: DialogOptions;
    public dialogResult: boolean;

    public changedList: EmployeeBenefitClass[];
    public addedList: EmployeeBenefitClass[];
    public deletedList: EmployeeBenefitClass[];

    public hasChanged: boolean;
    public hasAdded: boolean;
    public hasDeleted: boolean;

    public appConfig: IApplicationConfig;

    private modalService: ModalService;

    public static openDialog(
        changedList: EmployeeBenefitClass[],
        addedList: EmployeeBenefitClass[],
        deletedList: EmployeeBenefitClass[],
        title: string,
        message: string,
        modalService: ModalService,
        callback: (result: boolean) => void): ConfirmBenefitChangesDialogComponent {
        let dialogOptions: DialogOptions = new DialogOptions();
        dialogOptions.message = message;
        dialogOptions.width = 350;
        dialogOptions.height = 300;

        let resolvedProviders: Provider[] = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            }
        ];

        let dialog: ConfirmBenefitChangesDialogComponent = modalService.globalAnchor.openDialog(ConfirmBenefitChangesDialogComponent, title, dialogOptions, resolvedProviders, callback);
        dialog.changedList = changedList;
        dialog.addedList = addedList;
        dialog.deletedList = deletedList;

        dialog.hasAdded = !_.isNil(addedList) && _.size(addedList) > 0;
        dialog.hasChanged = !_.isNil(changedList) && _.size(changedList) > 0;
        dialog.hasDeleted = !_.isNil(deletedList) && _.size(deletedList) > 0;

        return dialog;
    }

    constructor (options: DialogOptions, modalService: ModalService) {
        this.appConfig = appConfig;
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
    }

    public onOk(): void {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }

    public onCancel(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }
}
