export type RecurrenceFrequencyDefinition = 'custom' | 'daily' | 'weekly' | 'monthly';
export type WeekDaysDefinition = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
export type OffsetPositionDefinition = 'first' | 'second' | 'third' | 'fourth' | 'last';

export class RecurrenceFrequencies {
  public static readonly custom: RecurrenceFrequencyDefinition = 'custom';
  public static readonly daily: RecurrenceFrequencyDefinition = 'daily';
  public static readonly weekly: RecurrenceFrequencyDefinition = 'weekly';
  public static readonly monthly: RecurrenceFrequencyDefinition = 'monthly';
}

export class WeekDays {
  public static readonly sunday: WeekDaysDefinition = 'sunday';
  public static readonly monday: WeekDaysDefinition = 'monday';
  public static readonly tuesday: WeekDaysDefinition = 'tuesday';
  public static readonly wednesday: WeekDaysDefinition = 'wednesday';
  public static readonly thursday: WeekDaysDefinition = 'thursday';
  public static readonly friday: WeekDaysDefinition = 'friday';
  public static readonly saturday: WeekDaysDefinition = 'saturday';
}

export class OffsetPositions {
  public static readonly first: OffsetPositionDefinition = 'first';
  public static readonly second: OffsetPositionDefinition = 'second';
  public static readonly third: OffsetPositionDefinition = 'third';
  public static readonly fourth: OffsetPositionDefinition = 'fourth';
  public static readonly last: OffsetPositionDefinition = 'last';
}

export class RecurrenceFrequency {
  constructor(
    public id: RecurrenceFrequencyDefinition,
    public name: string,
    public isActive: boolean = false
  ) {}
}

export class WeekDay {
  constructor(
    public id: number,
    public name: WeekDaysDefinition,
    public text: string,
    public isActive: boolean = false
  ) {}

  public get shortText(): string {
    return typeof(this.text) === 'string' ? this.text.slice(0, 3) : '';
  }
}

export class OffsetPosition {
  constructor(
    public id: number,
    public name: OffsetPositionDefinition,
    public text: string
  ) {}
}

export class RecurrenceRuleOption {
  constructor(
    public interval: number,
    public count: number,
    public until: Date,
    public byWeekDay?: ({ day: number, offset: number })[],
    public byMonthDay?: number
  ) {}
}
