import { Component, Input } from '@angular/core';
import { StaffingRatingOrganization } from '../../models/index';
import { StaffingPredictorManagementService } from '../../services/index';

@Component({
    moduleId: module.id,
    selector: 'slx-staffing-predictor-rating-badge',
    templateUrl: 'staffing-predictor-rating-badge.component.html',
    styleUrls: ['staffing-predictor-rating-badge.component.scss']
})

export class StaffingPredictorRatingBadgeComponent {

    @Input()
    public model: StaffingRatingOrganization;

    @Input()
    public showDetails: boolean = true;

    constructor(private management: StaffingPredictorManagementService) { }

    public onShowDetails(): void {
        this.management.showDetails(this.model.organization.id);
    }
}
