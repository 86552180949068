var ShiftReplacementSms = /** @class */ (function () {
    function ShiftReplacementSms(message, recipients) {
        if (message === void 0) { message = ''; }
        if (recipients === void 0) { recipients = []; }
        this.message = message;
        this.recipients = recipients;
    }
    Object.defineProperty(ShiftReplacementSms.prototype, "canSend", {
        get: function () {
            return this.message && this.message.length > 0 &&
                this.recipients && this.recipients.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    return ShiftReplacementSms;
}());
export { ShiftReplacementSms };
