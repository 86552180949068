/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./elite-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../common/components/spinner/spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "./elite-dashboard.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../core/services/window/window-ref.model";
import * as i8 from "@angular/platform-browser";
var styles_EliteDashboardComponent = [i0.styles];
var RenderType_EliteDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EliteDashboardComponent, data: {} });
export { RenderType_EliteDashboardComponent as RenderType_EliteDashboardComponent };
function View_EliteDashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "elite-host-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["scrolling", "yes"], ["title", "EliteDashboard"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.safeURL; _ck(_v, 1, 0, currVal_0); }); }
export function View_EliteDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_EliteDashboardComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isIFrameLoading || _co.isDataLoading); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isActive; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_EliteDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-elite-dashboard", [], null, null, null, View_EliteDashboardComponent_0, RenderType_EliteDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i5.EliteDashboardComponent, [i6.Router, i7.WindowRef, i8.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EliteDashboardComponentNgFactory = i1.ɵccf("slx-elite-dashboard", i5.EliteDashboardComponent, View_EliteDashboardComponent_Host_0, {}, {}, []);
export { EliteDashboardComponentNgFactory as EliteDashboardComponentNgFactory };
