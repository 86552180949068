export class EmployeeToTerminate {
 public employeeId: number;
 public firstName: string;
 public lastName: string;
 public middleName: string;
 public suffix: string;
 public fullName: string;
 public rehireDate?: Date;
 public terminationDate: Date;
 public ignoreLockedPayrollCycles: boolean;
 public isNGPUser?: boolean;
 public isSystemUser?: boolean;
}
