<slx-spinner [show]="isLoading">
  <div class="slx-high-box">
    <div class="slx-content-toolbar-indents">
      <slx-w2s-toolbar></slx-w2s-toolbar>
    </div>
    <div class="slx-high-box__body slx-main-content-indents">
      <slx-w2s-grid></slx-w2s-grid>
    </div>
  </div>
</slx-spinner>
