export * from './daily-line-punch-grid/daily-line-punch-grid.component';
export * from './daily-punches-management/daily-punches-management.component';
export * from './punches-employees-grid/punches-employees-grid.component';
export * from './punches-management/punches-management.component';
export * from './punches-filter/punches-filter.component';
export * from './punch-logs/punch-logs.component';
export * from './punch-logs-grid/punch-logs-grid.component';
export * from './punch-logs-dialog/punch-logs-dialog.component';
export * from './punches-event-filter/punches-event-filter.component';
export * from './daily-punches-page/daily-punches-page.component';
export * from './punches-rollup-grid/punches-rollup-grid.component';
export * from './punches-management-rollup/punches-management-rollup.component';
export * from './geo-punch-dialog/geo-punch-dialog.component';
export * from './add-comments-dialog/add-comments-dialog.component';

import { DailyLinePunchGridComponent } from './daily-line-punch-grid/daily-line-punch-grid.component';
import { DailyLinePunchStatusComponent } from './daily-line-punch-status/daily-line-punch-status.component';
import { DailyLinePunchMobileComponentComponent } from './daily-line-punch-mobile/daily-line-punch-mobile.component';
import { DailyPunchesManagementComponent } from './daily-punches-management/daily-punches-management.component';
import { PunchesEmployeesGridComponent } from './punches-employees-grid/punches-employees-grid.component';
import { PunchesManagementComponent } from './punches-management/punches-management.component';
import { PunchesFilterComponent } from './punches-filter/punches-filter.component';
import { PunchesEventFilterComponent } from './punches-event-filter/punches-event-filter.component';
import { PunchLogsComponent } from './punch-logs/punch-logs.component';
import { PunchLogsGridComponent } from './punch-logs-grid/punch-logs-grid.component';
import { PunchLogsDialogComponent } from './punch-logs-dialog/punch-logs-dialog.component';
import { DailyPunchesPageComponent } from './daily-punches-page/daily-punches-page.component';
import { PunchesGridRollupComponent } from './punches-rollup-grid/punches-rollup-grid.component';
import { PunchesManagementRollupComponent } from './punches-management-rollup/punches-management-rollup.component';
import { GeoPunchDialogComponent } from './geo-punch-dialog/geo-punch-dialog.component';
import { TimecardAddCommentsDialogComponent } from './add-comments-dialog/add-comments-dialog.component';
import { EmployeePunchRequestComponent } from './employee-punch-request/employee-punch-request.component';
import { EmployeePunchRequestToolbarComponent } from './employee-punch-request-toolbar/employee-punch-request-toolbar.component';
import { EmployeePunchRequestGridComponent } from './employee-punch-request-grid/employee-punch-request-grid.component';

export const punchesManagementComponents: any = [
  DailyLinePunchGridComponent,
  DailyLinePunchStatusComponent,
  DailyLinePunchMobileComponentComponent,
  DailyPunchesManagementComponent,
  PunchesEmployeesGridComponent,
  PunchesManagementComponent,
  PunchesFilterComponent,
  PunchesEventFilterComponent,
  PunchLogsComponent,
  PunchLogsGridComponent,
  PunchLogsDialogComponent,
  DailyPunchesPageComponent,
  PunchesGridRollupComponent,
  PunchesManagementRollupComponent,
  GeoPunchDialogComponent,
  TimecardAddCommentsDialogComponent,
  EmployeePunchRequestComponent,
  EmployeePunchRequestToolbarComponent,
  EmployeePunchRequestGridComponent
];
