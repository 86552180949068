import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { KendoGridStateHelper } from '../../../common/models/index';
import { process } from '@progress/kendo-data-query';
import { ReportsManagementService } from '../../services/index';
import { unsubscribeAll } from '../../../core/decorators/index';
var ReportDailyTodayStaffConfigComponent = /** @class */ (function () {
    function ReportDailyTodayStaffConfigComponent(reportsManagementService) {
        this.reportsManagementService = reportsManagementService;
        this.dailyAttendanceOptions = [];
        this.isLoading = true;
        this.pageSize = 50;
        this.hasValueChanged = new EventEmitter();
        this.reportConfigChanged = new EventEmitter();
        this.subscriptions = {};
        this.gridState = new KendoGridStateHelper();
        this.gridState.view = null;
        this.dailyAttendanceOptions = [{ id: 0, name: ' ' }, { id: 1, name: 'RNs' }, { id: 2, name: 'LPNs' }, { id: 3, name: 'Others' }];
        this.gridState.refreshGridAfterRestoreState = true;
    }
    ReportDailyTodayStaffConfigComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.reportsManagementService.loadStaffingSettingsData(this.forcedLoad);
        this.subscriptions.loadStaffingSettingsSubscription = this.reportsManagementService.onReportStaffingSettingsLoaded$.subscribe(function (reportStaffingSetting) {
            _this.records = reportStaffingSetting;
            _this.refreshGrid();
        });
    };
    ReportDailyTodayStaffConfigComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            this.isLoading = false;
            return;
        }
        this.gridState.view = process(this.records.positionCategory, this.gridState.state);
        this.isLoading = false;
    };
    ReportDailyTodayStaffConfigComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    ReportDailyTodayStaffConfigComponent.prototype.getPositionCategory = function (id) {
        return this.dailyAttendanceOptions[id];
    };
    ReportDailyTodayStaffConfigComponent.prototype.setPositionCategory = function (positionCategoryType, positionCategory) {
        if (positionCategoryType.positionCategoryType !== positionCategory.id) {
            positionCategoryType.positionCategoryType = positionCategory.id;
            this.hasValueChanged.emit(true);
            this.reportConfigChanged.emit(this.records);
        }
    };
    ReportDailyTodayStaffConfigComponent.prototype.onShiftDateChanged = function () {
        this.hasValueChanged.emit(true);
        this.reportConfigChanged.emit(this.records);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ReportDailyTodayStaffConfigComponent.prototype, "subscriptions", void 0);
    return ReportDailyTodayStaffConfigComponent;
}());
export { ReportDailyTodayStaffConfigComponent };
