import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect } from '../../../core/decorators/index';
import { MessagesApiService } from '../mocks/messages-api.service';
import { MessageApiService } from './message-api.service';
import { IMessageList, IUpdateRequest, IUserPicture, IMessagePayload, ILatestScheduleCycle, TabMessages, ISectionMessagesRequest, MessageCountResponse, IMessageCountRequest, MessageCountRequest } from '../models/message-list';
import { debounceTime, switchMap, distinctUntilChanged, } from 'rxjs/operators';
import { from, BehaviorSubject } from 'rxjs';
import { initialMessageFilterState } from '../models';
import { StateResetTypes } from '../../../core/models';
import { StateManagementService } from '../../../common/services/state-management/state-management.service';
import * as i0 from "@angular/core";
import * as i1 from "../mocks/messages-api.service";
import * as i2 from "./message-api.service";
import * as i3 from "../../../common/services/state-management/state-management.service";
var MessagesManagementService = /** @class */ (function () {
    function MessagesManagementService(apiService, msgApiService, stateManagement) {
        this.apiService = apiService;
        this.msgApiService = msgApiService;
        this.stateManagement = stateManagement;
        this.loading$ = new Subject();
        this.updatedSMSInfo$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.updatedSelectAllInfo$ = new Subject();
        this.sortingOrder$ = new Subject();
        this.getMessageThread$ = new Subject();
        this.getColdMessageThread$ = new Subject();
        this.selectedMessage$ = new Subject();
        this.coldThredDataExists$ = new Subject();
        this.selectedMessageId$ = new Subject();
        this.messageCountObj$ = new Subject();
        this.updateReadStatus$ = new Subject();
        this.callUnreadCountApi$ = new Subject();
        this.emptyMessageThread$ = new Subject();
        this.refreshMessageList$ = new Subject();
        this.activeTabChange$ = new Subject();
        this.messageLoaded$ = new Subject();
        this.updateArchiveMessages$ = new Subject();
        this.updateUnArchiveMessages$ = new Subject();
        this.updateReadMessages$ = new Subject();
        this.updateUnreadMessages$ = new Subject();
        this.recentMessage$ = new Subject();
        this.popUpMessages$ = new Subject();
        this.userPicturesLoaded$ = new Subject();
        this.latestScheduleCyleLoaded$ = new Subject();
        this.tabsMessagesCount$ = new Subject();
        this.tabMessages$ = new Subject();
        this.moreTabMessages$ = new Subject();
        this.switchMessageFilter$ = new Subject();
        this.stateChanged$ = new BehaviorSubject(initialMessageFilterState);
        this.stateKey = 'MessageFilterState';
        this.showWaitingPopup$ = new Subject();
        this.isMsgCenterLoaded = false;
        this.pageno = 1;
        this.orgLevelId = 1;
        this.isarchive = false;
        this.dateOrderByAscending = false;
        this.nameOrderByAscending = null;
        this.includeCold = false;
        this.coldStorageFlag$ = new Subject();
    }
    MessagesManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.orgLevelChanged$.complete();
        this.updatedSMSInfo$.complete();
        this.updatedSelectAllInfo$.complete();
        this.sortingOrder$.complete();
        this.stateChanged$.complete();
        this.switchMessageFilter$.complete();
    };
    MessagesManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateSpinner = function (isLoading) {
        this.loading$.next(isLoading);
    };
    MessagesManagementService.prototype.subscribeToSMSMessagesData = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updatedSMSInfo$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateSMSMessagesInfo = function (smsData) {
        this.updatedSMSInfo$.next(smsData);
    };
    MessagesManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateState = function (state) {
        this.stateChanged$.next(state);
    };
    MessagesManagementService.prototype.subscribeToState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.stateChanged$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateMessageFilter = function (state) {
        this.showWaitingPopup = true;
        this.switchMessageFilter$.next(state);
        this.setFilterState(state);
    };
    MessagesManagementService.prototype.subscribeToswitchMessageFilter = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.switchMessageFilter$.subscribe(callback);
    };
    MessagesManagementService.prototype.getSMSMessages = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.updateSpinner(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.getSMSMessagesInfo()];
                    case 2:
                        data = _a.sent();
                        this.updateSMSMessagesInfo(data);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.updateSpinner(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    MessagesManagementService.prototype.selectAllSMSRecords = function (value) {
        this.updatedSelectAllInfo$.next(value);
    };
    MessagesManagementService.prototype.subscribeToSelectAllSMSRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updatedSelectAllInfo$.subscribe(callback);
    };
    MessagesManagementService.prototype.subscribeToGetMessageThread = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.getMessageThread$.subscribe(callback);
    };
    MessagesManagementService.prototype.subscribeToGetMessageId = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.selectedMessageId$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateMessageThread = function (item, orgLevelId, markAsRead, isMyMessage) {
        var _this = this;
        if (markAsRead === void 0) { markAsRead = true; }
        if (!item) {
            return;
        }
        this.selectedMessageId$.next(item.id);
        this.msgApiService.getMessageThread(item.employeeId, orgLevelId, false, isMyMessage).then(function (v) {
            var obj = { conversation: v, employeeData: item, markAsRead: markAsRead };
            _this.getMessageThread$.next(obj);
        });
    };
    MessagesManagementService.prototype.appendColdMessageThread = function (item, orgLevelId, isColdData, isMyMessage) {
        var _this = this;
        if (!item) {
            return;
        }
        this.msgApiService.getMessageThread(item.employeeId, orgLevelId, isColdData, isMyMessage).then(function (v) {
            var obj = { conversation: v, employeeData: item };
            _this.getColdMessageThread$.next(obj);
        });
    };
    MessagesManagementService.prototype.subscribeToGetColdMessageThread = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.getColdMessageThread$.subscribe(callback);
    };
    MessagesManagementService.prototype.isColdDataExists = function (employeeId, orgLevelId) {
        var _this = this;
        if (employeeId) {
            this.msgApiService.getColdDataExists(employeeId, orgLevelId).then(function (v) {
                _this.coldThredDataExists$.next(v.hasMessages);
            });
        }
    };
    MessagesManagementService.prototype.subscribeToColdDataExists = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.coldThredDataExists$.subscribe(callback);
    };
    MessagesManagementService.prototype.subscribeToSelectedMessage = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.selectedMessage$.subscribe(callback);
    };
    MessagesManagementService.prototype.subscribeToMessageCount = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.messageCountObj$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateMessageCount = function (item) {
        this.messageCountObj$.next(item);
    };
    MessagesManagementService.prototype.subscribeToUpdateReadStatus = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updateReadStatus$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateReadStatusOfthread = function (employeeId) {
        var _this = this;
        this.msgApiService.getMessageStatusUpdateMultiple(employeeId).then(function (v) {
            _this.updateReadStatus$.next(v);
        });
    };
    MessagesManagementService.prototype.subscribecallUnReadCountApi = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.callUnreadCountApi$.subscribe(callback);
    };
    MessagesManagementService.prototype.callUnReadCountApi = function () {
        this.callUnreadCountApi$.next('true');
    };
    MessagesManagementService.prototype.subscribeEmptyMessageThread = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.emptyMessageThread$.subscribe(callback);
    };
    MessagesManagementService.prototype.emptyMessageThread = function () {
        this.emptyMessageThread$.next('true');
    };
    MessagesManagementService.prototype.subscribeRefreshMessageList = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.refreshMessageList$.subscribe(callback);
    };
    MessagesManagementService.prototype.refreshMessageList = function (event) {
        this.refreshMessageList$.next(event);
    };
    MessagesManagementService.prototype.subscribeChangeActiveTab = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.activeTabChange$.subscribe(callback);
    };
    MessagesManagementService.prototype.changeActiveTab = function () {
        this.activeTabChange$.next('true');
    };
    MessagesManagementService.prototype.subscribeToMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.messageLoaded$.subscribe(callback);
    };
    MessagesManagementService.prototype.getMessages = function (searchText, dateOrderByAscending, nameOrderByAscending, pageno, orgLevelId, isArchived, includeCold, pageSize, isMyMessage) {
        var _this = this;
        this.loading$.next(true);
        this.msgApiService.getMessages(searchText, dateOrderByAscending, nameOrderByAscending, pageno, orgLevelId, isArchived, includeCold, pageSize, isMyMessage)
            .then(function (messages) {
            _this.messageLoaded$.next(messages);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.getComposeNewTabsPermissions = function (orgLevelId) {
        var promise = this.msgApiService.getComposeNewTabsPermissions(orgLevelId);
        return promise;
    };
    MessagesManagementService.prototype.subscribeToTabsMessagesCount = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.tabsMessagesCount$.subscribe(callback);
    };
    MessagesManagementService.prototype.subscribeToTabMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.tabMessages$.subscribe(callback);
    };
    MessagesManagementService.prototype.getTabMessages = function (iSectionMessagesRequest) {
        var _this = this;
        setTimeout(function () {
            if (_this.showWaitingPopup) {
                _this.showWaitingPopup = false;
                _this.showWaitingPopup$.next(true);
            }
        }, 20000);
        var tabMessages = new TabMessages();
        this.loading$.next(true);
        this.msgApiService.getTabMessages(iSectionMessagesRequest)
            .then(function (messages) {
            _this.showWaitingPopup = false;
            tabMessages.sectionName = String(iSectionMessagesRequest.sectionName);
            tabMessages.messages = messages;
            tabMessages.isReset = iSectionMessagesRequest.isReset;
            _this.tabMessages$.next(tabMessages);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.subscribeToMoreTabMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.moreTabMessages$.subscribe(callback);
    };
    MessagesManagementService.prototype.getMoreTabMessages = function (iSectionMessagesRequest) {
        var _this = this;
        var tabMessages = new TabMessages();
        this.loading$.next(true);
        this.msgApiService.getTabMessages(iSectionMessagesRequest)
            .then(function (messages) {
            tabMessages.sectionName = String(iSectionMessagesRequest.sectionName);
            tabMessages.messages = messages;
            tabMessages.isReset = iSectionMessagesRequest.isReset;
            _this.moreTabMessages$.next(tabMessages);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.getTabsMessagesCount = function (messageCountRequest) {
        var _this = this;
        this.messageCountRequestState = messageCountRequest;
        this.loading$.next(true);
        this.msgApiService.getTabsMessagesCount(messageCountRequest)
            .then(function (tabsMessageCount) {
            _this.tabsMessagesCount$.next(tabsMessageCount);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.subscribeToPopUpMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.popUpMessages$.subscribe(callback);
    };
    MessagesManagementService.prototype.getPopUpMessages = function (orgLevelId, isMyMessage) {
        var _this = this;
        this.loading$.next(true);
        this.msgApiService.getMessages('', false, null, 1, orgLevelId, false, false, 10, isMyMessage)
            .then(function (messages) {
            _this.popUpMessages$.next(messages);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.subscribeToUpdateArchiveMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updateArchiveMessages$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateArchiveMessages = function (payload) {
        var _this = this;
        this.loading$.next(true);
        this.msgApiService.updateArchiveMessages(payload)
            .then(function (res) {
            _this.updateArchiveMessages$.next([]);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.subscribeToUpdateUnarchiveMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updateUnArchiveMessages$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateUnArchiveMessages = function (payload) {
        var _this = this;
        this.loading$.next(true);
        this.msgApiService.updateUnarchiveMessages(payload)
            .then(function (res) {
            _this.updateUnArchiveMessages$.next([]);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.subscribeToUpdateReadMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updateReadMessages$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateReadMessages = function (payload) {
        var _this = this;
        this.loading$.next(true);
        this.msgApiService.updateReadMessages(payload)
            .then(function (res) {
            _this.updateReadMessages$.next([]);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.subscribeToUpdateUnreadMessages = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.updateUnreadMessages$.subscribe(callback);
    };
    MessagesManagementService.prototype.updateUnreadMessages = function (payload) {
        var _this = this;
        this.loading$.next(true);
        this.msgApiService.updateUnreadMessages(payload)
            .then(function (res) {
            _this.updateUnreadMessages$.next([]);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.subscribeToRecentMessage = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recentMessage$.subscribe(callback);
    };
    MessagesManagementService.prototype.getRecentMessageByEmployeeId = function (employeeId, isMyMessage) {
        var _this = this;
        this.loading$.next(true);
        this.msgApiService.getRecentMessageByEmployeeId(employeeId, isMyMessage)
            .then(function (res) {
            _this.recentMessage$.next(res);
        }).finally(function () {
            _this.loading$.next(false);
        });
    };
    MessagesManagementService.prototype.searchEmployee = function (terms) {
        var _this = this;
        return terms.pipe(debounceTime(1000), distinctUntilChanged(), switchMap(function (term) { return from(_this.msgApiService.getTabsMessagesCount(_this.messageCountRequestState, term)); }));
    };
    MessagesManagementService.prototype.subscribeToUserPicturesLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.userPicturesLoaded$.subscribe(callback);
    };
    MessagesManagementService.prototype.getEmployeePicsByUserIds = function (userIds) {
        var _this = this;
        this.msgApiService.getEmployeePicsByUserIds(userIds).then((function (res) {
            _this.userPicturesLoaded$.next(res);
        }));
    };
    MessagesManagementService.prototype.subscribeToLatestScheduleCyleLoaded = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.latestScheduleCyleLoaded$.subscribe(callback);
    };
    MessagesManagementService.prototype.getLatestScheduleCycleByEmployeeId = function (empId) {
        var _this = this;
        this.msgApiService.getLatestScheduleCycleByEmployeeId(empId).then((function (res) {
            _this.latestScheduleCyleLoaded$.next(res);
        }));
    };
    MessagesManagementService.prototype.setRequestParams = function (obj) {
        this.pageno = obj.currentPageno;
        this.isarchive = obj.IsArchive;
        this.orgLevelId = obj.orgLevelId;
        this.dateOrderByAscending = obj.dateOrderByAscending;
        this.nameOrderByAscending = obj.nameOrderByAscending;
        this.includeCold = obj.includeCold;
    };
    MessagesManagementService.prototype.getColdStorageExist = function (orgLevelId, isarchived) {
        var _this = this;
        this.msgApiService.getColdStorageExist(orgLevelId, isarchived).then(function (res) {
            _this.coldStorageFlag$.next(res);
        });
    };
    MessagesManagementService.prototype.subscribeToWaitingPopup = function (callback) {
        return this.showWaitingPopup$.subscribe(callback);
    };
    MessagesManagementService.prototype.subscribeToColdStorageFlag = function (callback) {
        Assert.isNotNull(callback, "callback");
        return this.coldStorageFlag$.subscribe(callback);
    };
    MessagesManagementService.prototype.setFilterState = function (isMyMessage) {
        var state = _.clone(initialMessageFilterState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        state.isMyMessage = isMyMessage;
        this.saveState(state);
    };
    MessagesManagementService.prototype.saveState = function (state) {
        this.stateManagement.setControlState(this.stateKey, {
            value: state
        }, StateResetTypes.None);
    };
    MessagesManagementService.prototype.restoreFilterState = function () {
        var state = _.clone(initialMessageFilterState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        return state.isMyMessage;
    };
    MessagesManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagesManagementService_Factory() { return new MessagesManagementService(i0.ɵɵinject(i1.MessagesApiService), i0.ɵɵinject(i2.MessageApiService), i0.ɵɵinject(i3.StateManagementService)); }, token: MessagesManagementService, providedIn: "root" });
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], MessagesManagementService.prototype, "orgLevel$", void 0);
    return MessagesManagementService;
}());
export { MessagesManagementService };
