import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';
import { EssTemplateApiService } from './ess-templates-api.service';
import { InfoDialogComponent } from '../../../common/components/index';
import { ModalService } from '../../../common/services/index';
import { OrgLevelType } from '../../../state-model/models/index';
var EssTemplateManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(EssTemplateManagementService, _super);
    function EssTemplateManagementService(api, modalService) {
        var _this = _super.call(this) || this;
        _this.api = api;
        _this.modalService = modalService;
        _this.addTemplateBtn$ = new Subject();
        _this.selectTemplate$ = new Subject();
        return _this;
    }
    EssTemplateManagementService.prototype.isOrganization = function () {
        return this.orgLevel.type === OrgLevelType.organization;
    };
    EssTemplateManagementService.prototype.loadTemplates = function (lvl) {
        var _this = this;
        this.orgLevel = lvl;
        this.onLoadStatusChanged(true);
        this.api.getTemplates(this.orgLevel.id)
            .then(function (data) {
            _this.templates = _.orderBy(data, function (t) { return t.essTemplateDefinition.isDefault; }, 'desc');
            _this.onLoaded(_this.templates);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    EssTemplateManagementService.prototype.addTemplate = function (lvl) {
        var _this = this;
        this.orgLevel = lvl;
        this.onLoadStatusChanged(true);
        this.api.addTemplate(this.orgLevel.id)
            .then(function (data) {
            _this.templates.push(data);
            _this.onLoaded(_this.templates);
            _this.onLoadStatusChanged(false);
            _this.selectTemplate(data);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    EssTemplateManagementService.prototype.saveTemplate = function (template) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.saveTemplate(template)
            .then(function (data) {
            if (template.essTemplateDefinition.isDefault) {
                _this.templates.splice(0, 1, data);
            }
            else {
                var index = _.findIndex(_this.templates, function (t) { return data.essTemplateDefinition.id === t.essTemplateDefinition.id; });
                _this.templates.splice(index, 1, data);
            }
            _this.onLoaded(_this.templates);
            _this.onLoadStatusChanged(false);
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    EssTemplateManagementService.prototype.deleteTemplate = function (template) {
        var _this = this;
        this.onLoadStatusChanged(true);
        this.api.deleteTemplate(template.essTemplateDefinition.id)
            .then(function (resp) {
            if (resp.isSuccess) {
                _.remove(_this.templates, function (t) { return template.essTemplateDefinition.id === t.essTemplateDefinition.id; });
                _this.onLoaded(_this.templates);
                _this.onLoadStatusChanged(false);
            }
            else {
                _this.onLoadStatusChanged(false);
                var positions = _.map(resp.assignedPositions, (function (p) { return "\"" + p.name + "\""; }));
                var message = "Template \"" + template.essTemplateDefinition.name + "\" is used for position(s)\r\n: " + _.join(positions);
                InfoDialogComponent.OpenDialog('Cannot remove template', message, _this.modalService);
            }
        })
            .catch(function (err) {
            _this.onError(err);
            _this.onLoadStatusChanged(false);
        });
    };
    EssTemplateManagementService.prototype.selectTemplate = function (template) {
        this.selectTemplate$.next(template);
    };
    EssTemplateManagementService.prototype.AddNewTempateAction = function (value) {
        return this.addTemplateBtn$.next(value);
    };
    return EssTemplateManagementService;
}(ManagementBaseService));
export { EssTemplateManagementService };
