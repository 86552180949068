import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { KendoGridStateHelper } from '../../../../common';

import * as moment from 'moment';
import { MessageList } from '../../models';
import { process } from '@progress/kendo-data-query';
import { SlateMessageGroupInfo, SlateMessageGroupInfoOfGroupId, SlateMessageInfoOfGroup } from '../../models/slate-message-group-info';
import { SlateMessagesApiService } from '../../services/slate-messages-api.service';
import { OrgLevel } from '../../../../state-model/models';
import { Observable, Subscription } from 'rxjs';
import { mutableSelect, unsubscribe } from '../../../../core/decorators';
import { SessionService } from '../../../../core/services';
import { IUser } from '../../../../authentication/store';

@Component({
  selector: 'slx-slate-message-content',
  templateUrl: './slate-message-content.component.html',
  styleUrls: ['./slate-message-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SlateMessageContentComponent implements OnInit {
  isLoading: boolean;
  @unsubscribe()
  private subscriptions: StringMap<Subscription> = {};

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;

  _selectedMessage: SlateMessageGroupInfo;
  get selectedSlateMessage(): SlateMessageGroupInfo {
    return this._selectedMessage;
  }
  @Input() set selectedSlateMessage(value: SlateMessageGroupInfo) {
    this._selectedMessage = value;
    this.getSlateMessageDetails();
  }
  currentUserName: string;
  previousMessages: SlateMessageInfoOfGroup[] = [];
  currentDateMessages: SlateMessageInfoOfGroup[] = [];
  public SlateMessageGroupInfo: SlateMessageGroupInfoOfGroupId;
  
  public gridState: KendoGridStateHelper<MessageList>;
  
  constructor(
    private slateMessagesApiService: SlateMessagesApiService,
    private sessionService: SessionService) {
  }

  ngOnInit() {
    this.getCurrentUserName();
    this.subscribeToOrgLevel();
    this.gridState = new KendoGridStateHelper<MessageList>();
    this.gridState.view = null;
    this.gridState.view = process([this.selectedSlateMessage], this.gridState.state);
  }

  getCurrentUserName() {
    let currentUser: IUser = this.sessionService.getUser();
    if (!!currentUser && !!currentUser.name) {
      this.currentUserName = currentUser.name;
    }
  }

  getSlateMessageDetails() {
    if (!!this.selectedSlateMessage && !!this.selectedSlateMessage.groupId && !!this.orgLevel && !!this.orgLevel.id) {
      this.isLoading = true;
      this.slateMessagesApiService.getSlateMessagesByGroupId(this.orgLevel.id, this.selectedSlateMessage.groupId).then(res => {
        this.isLoading = false;
        this.SlateMessageGroupInfo = res[0];
        this.SlateMessageGroupInfo.messages.sort(message => message.createdDate.getTime());
        this.previousMessages = this.SlateMessageGroupInfo.messages.filter(message => !this.isCurrentDateMessage(message)).sort(message => new Date(message.createdDate).getTime());
        this.currentDateMessages = this.SlateMessageGroupInfo.messages.filter(message => this.isCurrentDateMessage(message)).sort(message => new Date(message.createdDate).getTime());
      });
    }
  }

  public subscribeToOrgLevel(): void {
    this.subscriptions.orgLevel = this.orgLevel$
      .subscribe((orgLevel: OrgLevel) => {
        if (!!orgLevel && orgLevel.id) {
          this.orgLevel = orgLevel;
          this.getSlateMessageDetails();
        }
      });
  }

  isAnyoneSeen(message: SlateMessageInfoOfGroup) {
    return message.employees.some(emp => emp.read);
  }

  canShowMoreButton(message: SlateMessageInfoOfGroup) {
    return message.employees.some(emp => !emp.read) && message.employees.filter(emp => emp.read).length > 10;
  }

  getSeenByString(message: SlateMessageInfoOfGroup) {
    let text = '';
    if (message.employees.some(emp => emp.read)) {
      if (message.employees.length > 1 && message.employees.every(emp => emp.read)) {
        text = 'Seen by everyone';
      }
      else {
        text = 'Seen by '
        let seenEmployees = message.employees.filter(emp => emp.read).slice(0, 10);
        seenEmployees.forEach(emp => {
          text += this.SlateMessageGroupInfo.employees.find(e => e.employeeId == emp.employeeId).employeeName.split(',').join(' ') + ',';
        });
      }
    }
    
    return text;
  }

  getLatestSeenDatetime(message: SlateMessageInfoOfGroup) {
    return !!message && !!message.employees ?  message.employees.map((e) => e.readAt).filter(d => d != null || d != undefined).sort().reverse()[0]: '';
  }

  isCurrentDateMessage(message: SlateMessageInfoOfGroup) {
    const createdDate = new Date(message.createdDate);
    const date = new Date(createdDate.getDate(), createdDate.getMonth(), createdDate.getFullYear());
    return date == new Date();
  }

  getMoreEmployeesStrings(message: SlateMessageInfoOfGroup) {
    let text = '';
    let seenEmployees = message.employees.filter(emp => emp.read).slice(10);
    seenEmployees.forEach(emp => {
      text += this.SlateMessageGroupInfo.employees.find(e => e.employeeId == emp.employeeId).employeeName.split(',')[1] + ',';
    });
    return text;
  }
}
