<slx-spinner class="dua-spinner" [show]="state.isLoading || isLoading || loading ">
  <div class="details-screen scheduler daily-unit-component content" id="content" #content>
    <div *ngIf="!state.switchShiftMode">
      <div class="header slx-content-toolbar-indents" >
        <slx-toolbar class="dua-toolbar" [alignExpandButtonRight]="true" [isDuaComponent]="true" [customPopperContentClass]="isTablet ? 'customPopperContentClass' : ''">
            <ng-template slxToolbarSectionTemplate  let-counts="counts" [width]="400">
              <div class="slx-toolbar-section slx-no-padd-l date-section">
                <slx-date-navigator className="slx-mobile-navigator"
                  [selectedDate]="dateOn"
                  [format]="dateFormat"
                  (dateChanged)="changeDateOn($event, true)"
                  [navigatorButtons] = "true"
                ></slx-date-navigator>
              </div>
              <div class="slx-toolbar-section slx-no-padd-r issues-section flex-row" [ngClass]="{ 'slx-no-padd-r': isVisibleSection(sectionType) }">
                
                <div class="col col-exception">
                  <button type="button"  class="add-open-shift slx-button " [disabled]="requestShiftIsEnabled"  *ngIf="( enableopenshiftonfly ) && isEditOpenShift" (click)="AddOpenShifts()"> <i class="fal fa-plus-circle " aria-hidden="true"></i> &nbsp;Add Open Shifts</button>
                </div>
                <div class="col col-exception">
                  <button type="button" [ngClass]="{ 'request-btn': !requestShiftIsEnabled }"  class="theme-button-apply request-btn-disable" [disabled]="requestShiftIsEnabled"  *ngIf="details?.canAgencyStaffingRequest && IsEnabled" (click)="requestAgencyStaff()">Request Agency Staff</button>
                </div>
              </div>
            </ng-template>
          
            <ng-template slxToolbarSectionTemplate let-sectionType="sectionType" [width]="400" [alignMode]="screenWidth > 1023 ? 'right' : ''">
            <div [ngClass]="screenWidth > 1023 ? 'right-toolbar' : ''">
              <div class="col col-exception" [ngClass]=" isTablet || isMobile ? 'action-buttons' : ''">
                <div class="filter" >
                  <button type="button" class="theme-button-apply setting-btn" (click)="clearFilters()">
                    <i class="fas fa-eraser" aria-hidden="true"></i>  Clear Filters
                  </button>
                </div>
              </div>
              <div class="col col-exception" [ngClass]=" isTablet ? 'action-buttons' : ''">
              
                  <div class="filter" *ngIf="screenWidth > 1023 then desktopViewSetting else tabViewSetting"></div>
                  <ng-template #desktopViewSetting>
                    <button type="button" [popover]=" desktopViewPopover" class="theme-button-apply setting-btn"><i class="fas fa-cog setting-icon" aria-hidden="true"></i> View settings
                    </button>
                  <popover-content #desktopViewPopover title="View settings" class="popover-content-arrow"  disableClickOutside="false" [placement]=" screenWidth < 1024 ? 'right' : 'left'">
                    <slx-grid-detail-group-view-settings [settingsType]="currentViewMode" (close)="desktopViewPopover.hide(); viewSettingsChanged($event);" ></slx-grid-detail-group-view-settings>
                  </popover-content>
                  </ng-template>
                  <ng-template #tabViewSetting>
                    <button type="button" (click)="openViewSettingsModal()" class="theme-button-apply setting-btn">
                      <i class="fas fa-cog setting-icon" aria-hidden="true"></i> View settings
                    </button>
                  </ng-template>
                
                </div>
              
              <div class="col col-exception">
                <div class="filter" *ngIf="screenWidth > 1023 then desktopContent else tabContent"></div> 
                  <ng-template #tabContent>
                    <button type="button" [ngClass]="filtersApplied ? 'theme-button-apply filter-btn' : 'theme-button-cancel filter-btn'" class="filter-padding" (click)=" openModal()">
                      <i class="fa fa-filter" aria-hidden="true"></i> <span class="filter-margin">Filters</span>
                    </button>
                  </ng-template>
                
                  
                <ng-template #desktopContent>
                  <button type="button" [popover]="filterPopover" class="{{filtersApplied ? 'theme-button-apply filter-btn' : 'theme-button-cancel filter-btn'}}">
                    <i class="fa fa-filter" aria-hidden="true"></i>
                  </button>
                  <popover-content #filterPopover title="Filters" class="popover-content-arrow slx-wide"     disableClickOutside="true" (onShow)="savePreviousFilters()"
                  [placement] ="'left'">
                    <div>
                      <slx-input-decorator class="auto-height">
                        <slx-dropdown-multiselect slx-input [options]="positionGroupLookup?.items" valueField="id" titleField="name" (ngModelChange)="onFilterChange('positionGroups',$event)"
                          placeholder="Position Group" name="positionGroupFilter" [(ngModel)]="positionGroupFilter">
                        </slx-dropdown-multiselect>
                      </slx-input-decorator>
                    </div>
                    <div>
                      <slx-input-decorator class="auto-height">
                        <slx-dropdown-multiselect slx-input [options]="positionLookup?.items" valueField="id" titleField="name" (ngModelChange)="onFilterChange('position',$event)"
                          placeholder="Position" name="posFilter" [(ngModel)]="posFilter">
                        </slx-dropdown-multiselect>
                      </slx-input-decorator>
                    </div>
                    <div>
                      <slx-input-decorator class="auto-height">
                        <slx-dropdown-multiselect slx-input  [options]="unitsLookup?.items" (ngModelChange)="onFilterChange('unit', $event)"
                          placeholder="Unit" name="unitFilter" [(ngModel)]="unitFilter" valueField="id" titleField="name" >
                        </slx-dropdown-multiselect>
                      </slx-input-decorator>
                    </div>
                    <div>
                      <slx-input-decorator class="auto-height">
                        <slx-dropdown-multiselect slx-input [options]="shiftsLookup?.items" (ngModelChange)="onFilterChange('shift',$event)"
                          placeholder="Shift" name="shiftFilter" [(ngModel)]="shiftFilter" valueField="id" titleField="name" >
                        </slx-dropdown-multiselect>
                      </slx-input-decorator>
                    </div>
                    <div class="btn-container">
                      <button type="button" class="theme-button-apply filter-cancel-btn margin-r" (click)="filterPopover.hide(); this.cancelFilters();">Cancel</button>
                      <button type="button" class="theme-button-apply filter-apply-btn" (click)="filterPopover.hide(); this.applyFilters(); saveFilters()">Apply</button>
                    </div>
                </popover-content>

                </ng-template>
              
              </div>
              <div class="col col-exception action-buttons">
                <div class="filter">
                  <slx-actions-list>
                    <slx-actions-button className="low-height auto-width spacing-border" (click)="isActionsOpened=true" [popperContent]="Actionsettings" [popperPosition]="'bottom-end'">
                      <i class="fas fa-cog" aria-hidden="true"></i> <span style="margin-left:10px;">Actions</span>
                    </slx-actions-button>
                    <popper-content #Actionsettings [ngClass]="isTablet ? 'actionsContentClass' : ''" >
                      <slx-actions-list-item className="slx-mobile" (click)="popupRefChild.exportToExcel()">Export to Excel</slx-actions-list-item>
                      <slx-actions-list-item className="slx-mobile" (click)="captureScreen()">Export to PDF</slx-actions-list-item>
                    </popper-content>
                  </slx-actions-list>             
                </div>
              </div>
            </div>
            </ng-template>
        </slx-toolbar>
      </div>

      <ng-container>
        <div id="contentToConvert" #contentToConvert>
          <div class="col-xs-12 padd-l-0 padd-r-0 duaSummary-container">
            <div class="col-xs-6 col-md-5 duaSummary-left">
              <div class="period-container-left mp-border-color-left-tile">
                <div class="period-header mp-bg-color-left-tile">
                  <em class="fa fa-calendar-alt icon-align"></em>
                  <span class="header-label staffing">Staffing 
                    <span class="groupHours" *ngIf="currentViewMode==='hours'"> Hours</span>
                    <span class="groupHours" *ngIf="currentViewMode==='slots'"> Slots</span>
                    <span class="groupHours" *ngIf="currentViewMode==='ppd'"> PPD</span>
                  </span>
                  <span class="census-label"(click)="onCensusEdit()">Census: {{censusCount}}</span>
                </div>
                <div class="acacontainer">
                  <div class="acacontainer-left leftsideContent filter">
                    <div class="acacontainer-header pad2">Total Scheduled / <span class="mp-fg-color-left-tile"> Ideal</span></div>
                    <div class="acacontainer-left-header_val mp-fg-color">
                      <span class="value-hyper-link">{{schedTotal | number : '1.0-2'}}/</span><span class="mp-fg-color-left-tile">{{schedIdealTotal | number : '1.0-2'}}</span>
                    </div>
                  </div>
                  <div class="acacontainer-right">
                    <div class="acacontainer-right-content filter">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Day:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color">
                        <span class="value-hyper-link">{{dayTotal | number : '1.0-2'}}/</span><span class="mp-fg-color-left-tile">{{dayIdealTotal | number : '1.0-2'}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content filter">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Evening:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color">
                        <span class="value-hyper-link">{{eveningTotal | number : '1.0-2'}}/</span><span class="mp-fg-color-left-tile">{{eveningIdealTotal | number : '1.0-2'}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content filter">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Night:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color">
                        <span class="value-hyper-link">{{nightTotal | number : '1.0-2'}}/</span><span class="mp-fg-color-left-tile">{{nightIdealTotal | number : '1.0-2'}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-7 duaSummary-right" [ngClass]="isActionsOpened ? 'adjust-pdf' : '' ">
              <div class="period-container mp-border-color-ot-tile filter filterOT" [class.selected]="overTimeShiftToggle" (click)="clickEventForOverTimeTile(overTimeShiftToggle)">
                <div class="period-header mp-bg-color-ot-tile">
                  <span class="header-label approachingLabel">Approaching OT</span>
                </div>
                <div class="acacontainer">
                  <div class="acacontainer-left">
                    <div class="acacontainer-header pad2">Total</div>
                    <div class="acacontainer-left-header_val mp-fg-color-ot-tile" >
                      <span class="value-hyper-link">{{overTimeTotalShifts}}</span>
                    </div>
                  </div>
                  <div class="seprator"></div>
                  <div class="acacontainer-right">
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Day:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-ot-tile">
                        <span class="value-hyper-link">{{overTimeDayShifts}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Evening:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-ot-tile">
                        <span class="value-hyper-link">{{overTimeEveningShifts}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content ineligible-txt">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Night:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-ot-tile">
                        <span class="value-hyper-link">{{overTimeNightShifts}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="period-container mp-border-color-open-tile filter filterOS" [class.selected]="openShiftToggle" (click)="clickEventForOpenTile(openShiftToggle)">
                <div class="period-header mp-bg-color-open-tile">
                  <span class="header-label openShiftLabel">Open Shift(s)</span>
                </div>
                <div class="acacontainer">
                  <div class="acacontainer-left">
                    <div class="acacontainer-header pad2">Total</div>

                    <div class="acacontainer-left-header_val mp-fg-color-open-tile">
                      <span class="value-hyper-link">{{openTotalShifts}}</span>
                    </div>
                  </div>
                  <div class="seprator"></div>
                  <div class="acacontainer-right">
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Day:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-open-tile">
                        <span class="value-hyper-link">{{openDayShifts}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Evening:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-open-tile">
                        <span class="value-hyper-link">{{openEveningShifts}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content ineligible-txt">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Night:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-open-tile">
                        <span class="value-hyper-link">{{openNightShifts}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div class="period-container mp-border-color-switch-tile filter filterSS" [class.selected]="switchShiftToggle" (click)="clickEventForSwitchTile(switchShiftToggle)">
                <div class="period-header mp-bg-color-switch-tile">
                  <span class="header-label switchLabel">Switch Shift</span>
                </div>
                <div class="acacontainer">
                  <div class="acacontainer-left">
                    <div class="acacontainer-header pad2">Total</div>
                    <div class="acacontainer-left-header_val mp-fg-color-switch-tile">
                      <span class="value-hyper-link">{{switchTotalShift}}</span>
                    </div>
                  </div>
                  <div class="seprator"></div>
                  <div class="acacontainer-right">
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Day:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-switch-tile">
                        <span class="value-hyper-link">{{switchDayShift}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Evening:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-switch-tile">
                        <span class="value-hyper-link">{{switchEveningShift}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content ineligible-txt">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Night:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-switch-tile">
                        <span class="value-hyper-link">{{switchNightShift}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="period-container mp-border-color-pending-tile filter" *ngIf="details?.canAgencyStaffingRequest && IsEnabled"  [class.selected]="pendingShiftToggle" (click)="clickEventForPendingTile(pendingShiftToggle)">
                <div class="period-header mp-bg-color-pending-tile">
                  <span class="header-label pendingLabel">Pending Agency Staff</span> 
                </div>
                <div class="acacontainer">
                  <div class="acacontainer-left">
                    <div class="acacontainer-header pad2">Total</div>
                    <div class="acacontainer-left-header_val mp-fg-color-pending-tile">
                      <span class="value-hyper-link">{{agencyTotalShifts}}</span>
                    </div>
                  </div>
                  <div class="seprator"></div>
                  <div class="acacontainer-right">
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Day:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-pending-tile">
                        <span class="value-hyper-link">{{agencyDayShifts}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Evening:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-pending-tile">
                        <span class="value-hyper-link">{{agencyEveningShifts}}</span>
                      </div>
                    </div>
                    <div class="acacontainer-right-content ineligible-txt">
                      <div class="acacontainer-header-content">
                        <div class="acacontainer-header">Night:</div>
                      </div>
                      <div class="acacontainer-right-content-val mp-fg-color-pending-tile ">
                        <span class="value-hyper-link">{{agencyNightShifts}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="details && previousGroups" class="slx-main-content-indents details-screen-groups" slxElementCreatedSelector (onCreated)="groupContainerCreated($event)">
            <div class="group" *ngFor="let group of previousGroups">
              <div class="group-header" (click)="hideTable(groupTable)">
                <div class="left-side" (click)="hideTable(groupTable)">
                  <div class="title" (click)="hideTable(groupTable)">
                    <span *ngFor="let grouping of group.grouping | filter:{name: restrictedInfo}; let last = last">{{grouping.value}}{{!last ? ', ' : ''}}</span>
                  </div>
                  <div class="group-settings-heading">
                    <span class="groupHours" *ngIf="currentViewMode==='hours'"> Hours</span>
                    <span class="groupHours" *ngIf="currentViewMode==='slots'"> Slots</span>
                    <span class="groupHours" *ngIf="currentViewMode==='ppd'"> PPD</span>
                  </div>
                  <div class="groupContext">
                    <span> Schedule:</span>
                    <span class="groupHours" *ngIf="currentViewMode==='hours'"> {{group.filteredScheduledHours | number : '1.0-2'}}</span>
                    <span class="groupHours" *ngIf="currentViewMode==='slots'"> {{group.filteredFilledSlots | number : '1.0-2'}}</span>
                    <span class="groupHours" *ngIf="currentViewMode==='ppd'"> {{group.filteredFilledPpd | number : '1.0-2'}}</span>
                  </div>
                  <div class="groupContext">
                    <span> Ideal:</span>
                    <span class="groupHours mp-fg-color-left-tile" *ngIf="currentViewMode==='hours'" > {{group.filteredTotalIdealHours | number : '1.0-2'}}</span>
                    <span class="groupHours" *ngIf="currentViewMode==='slots'"> {{group.filteredTotalSlots | number : '1.0-2'}}</span>
                    <span class="groupHours" *ngIf="currentViewMode==='ppd'"> {{group.filteredTotalPpd | number : '1.0-2'}}</span>
                  </div>
                </div>
                <div class="right-side">
                  <div class="left-side" (click)="hideTable(groupTable)">
                  <span class="toggle-table-visibility-button" (click)="hideTable(groupTable)">
                    <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="groupTable.style.display !== 'none'"></i>
                    <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="groupTable.style.display === 'none'"></i>
                  </span>
                  </div>
                </div>
                
              </div>
              <div class="group-table" #groupTable>
                <slx-daily-unit-grid-employees #popupRef [canReplaceEmployee]="details.canReplaceEmployee" [showEmptySlots]="group.isEmptySlotFilterSelected" [showOvertimes]="group.isOvertimeFilterSelected"
                  [showMessages]="group.isMessageFilterSelected" [showOutOfPar]="group.isOutOfParFilterSelected" [showPendingSlots]="group.isPendingSlotFilterSelected" [columns]="details.columns" [GroupData]="previousGroups"
                  [rows]="group.rows" (onEmployeeDelete)="employeeDelete($event)" (onEmployeeCancel)="employeeCancel($event)" (onFillShift)="onFillShift($event)" (onReplaceEmployee)="onReplaceEmployee($event)"
                  (onShiftOpen)="shiftOpen($event)" (onSwitchEmployeeShift)="onSwitchEmployeeShift($event)" (onOpenIndividual)="onOpenIndividual($event)"
                  (onNeedScroll)="scrollTo($event)" [isEmptyExists]="totalEmptySlotCount > 0" [IsEnabled] = "IsEnabled && details?.canAgencyStaffingRequest"  [cancelRequestShift]="details.canAgencyShiftCancelRequest" [dateOn]="dateOn" [agencyMap]="agencyMap" [cancelTypeList]="cancelTypeListData" ></slx-daily-unit-grid-employees>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div> 
    <slx-switch-grid-employee-shift *ngIf="state.switchShiftMode" [date]="dateOn" [selectedItem]="rowForShiftChange" (onSave)="performShiftSwitch ($event)"
      (onCancel)="discardShiftSwitch()"></slx-switch-grid-employee-shift>
  </div>
</slx-spinner>
