/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule-cycle-summary-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../schedule-cycle-summary/schedule-cycle-summary.component.ngfactory";
import * as i3 from "../schedule-cycle-summary/schedule-cycle-summary.component";
import * as i4 from "./schedule-cycle-summary-dialog.component";
import * as i5 from "../../../../common/models/dialog-options";
import * as i6 from "../../../../common/services/modal/modal.service";
import * as i7 from "../../../models/schedule/schedule-cycle-summary";
var styles_ScheduleCycleSummaryDialogComponent = [i0.styles];
var RenderType_ScheduleCycleSummaryDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleCycleSummaryDialogComponent, data: {} });
export { RenderType_ScheduleCycleSummaryDialogComponent as RenderType_ScheduleCycleSummaryDialogComponent };
export function View_ScheduleCycleSummaryDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "slx-schedule-cycle-summary", [], null, [[null, "actionEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionEvent" === en)) {
        var pd_0 = (_co.onAction($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ScheduleCycleSummaryComponent_0, i2.RenderType_ScheduleCycleSummaryComponent)), i1.ɵdid(3, 49152, null, 0, i3.ScheduleCycleSummaryComponent, [], { summary: [0, "summary"] }, { actionEvent: "actionEvent" }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.summary; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ScheduleCycleSummaryDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-schedule-cycle-summary-dialog", [], null, null, null, View_ScheduleCycleSummaryDialogComponent_0, RenderType_ScheduleCycleSummaryDialogComponent)), i1.ɵdid(1, 49152, null, 0, i4.ScheduleCycleSummaryDialogComponent, [i5.DialogOptions, i6.ModalService, i7.ScheduleCycleMessages], null, null)], null, null); }
var ScheduleCycleSummaryDialogComponentNgFactory = i1.ɵccf("slx-schedule-cycle-summary-dialog", i4.ScheduleCycleSummaryDialogComponent, View_ScheduleCycleSummaryDialogComponent_Host_0, {}, {}, []);
export { ScheduleCycleSummaryDialogComponentNgFactory as ScheduleCycleSummaryDialogComponentNgFactory };
