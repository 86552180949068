<div class="bottom-menu-container" [ngClass]="{ 'left-sidebar-open': isLeftSidebarOpen }">
  <span class="helper-button ellipsis" (click)="toggleExpanded()" [ngClass]="{'expanded': isExpanded}">
    <i class="fa fa-ellipsis-v" [ngClass]="{'fa-ellipsis-v': !isExpanded, 'fa-ellipsis-h': isExpanded}" aria-hidden="true"></i>
  </span>
  <div class="buttons-container" [ngClass]="{'expanded-butons': isExpanded}">
    <span class="helper-button left-shift" (click)="logOut()" >
      <i class="fa fa-key" aria-hidden="true" slxTooltip="Logout" tipPosition="top"></i>
    </span>
    <span class="helper-button" (click)="toggleFullScreen()" >
      <i class="fas fa-expand-arrows-alt" aria-hidden="true" slxTooltip="Full screen (F11)" tipPosition="top"></i>
    </span>
    <span class="helper-button" [slxExternalLink]="'support'" >
      <i class="fa fa-info" aria-hidden="true" slxTooltip="SLX support" tipPosition="top"></i>
    </span>
    <span class="helper-button right-shift" [slxExternalLink]="'help'" >
      <i class="fa fa-question-circle" aria-hidden="true" slxTooltip="SLX trainings" tipPosition="top"></i>
    </span>
  </div>
</div>
