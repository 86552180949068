import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { ResponseBody, Meta } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';
import { Assert } from '../../../../framework/index';
import { EmployeeTransferMapService } from './employee-transfer-map.service';
import { EmployeeTransfer, IEmployeeTransfer, IEmployeeTransferringResponse, EmployeeTransferringResponse } from '../../models/index';

@Injectable()
export class EmployeeTransferApiService {
  constructor(
    private employeeTransferMapService: EmployeeTransferMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public undoTransferEmployee(empId: number): Promise<any> {
    Assert.isNotNull(empId, 'empId');
    const url: string = `${this.getTerminationApiRoot()}/${empId}/${employeeConfig.api.employees.undoTransfer}`;
    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url);
    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
    return promise;
  }

  public transferEmployee(req: EmployeeTransfer): Promise<EmployeeTransferringResponse> {
    Assert.isNotNull(req, 'req');

    const url: string = `${this.getTerminationApiRoot()}/${req.employeeId}/${employeeConfig.api.employees.transfer}`;
    let modelDTO: IEmployeeTransfer = this.employeeTransferMapService.mapToTransferEmployeeDto(req);
    let body: any = modelDTO;

    let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);

    let promise: Promise<EmployeeTransferringResponse> = this.apiUtilService.request<IEmployeeTransferringResponse, Meta>(request)
      .then((response: ResponseBody<IEmployeeTransferringResponse, Meta>) => this.employeeTransferMapService.mapToDtoTransferredEmployee(response.data));
    return promise;
  }

  private getTerminationApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.employees.root}`;
  }

}
