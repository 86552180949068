import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Assert, ContentDisposition, StringUtils } from '../../../framework/index';
import { StatusCodes, ResponseBody, Meta } from '../../models/index';
import 'rxjs/add/operator/toPromise';
import { FileBlobResponse } from '../../models/api/file-blob-response';
import * as _ from 'lodash';
import { ApiWatchService } from '../api-watch/api-watch.service';
import { ApiEvent } from '../../models/index';
import { SlxHttpRequest } from '../../../core/models/index';
import { appConfig, IApplicationConfig } from '../../../app.config';

@Injectable()
export class ApiService {
  private appConfig: IApplicationConfig = appConfig;

  constructor(private httpClient: HttpClient, private apiWatchService: ApiWatchService) {
  }

  public requestNew<TData, TMeta extends Meta>(req: SlxHttpRequest<any>): Promise<ResponseBody<TData, TMeta>> {
    Assert.isNotNull(req.httpRequest, 'req.httpRequest');
    Assert.isNotNull(req.httpRequest.url, 'req.httpRequest.url');
    Assert.isNotNull(req.httpRequest.method, 'req.httpRequest.method');

    const reqStartEvent: ApiEvent = new ApiEvent(req.url, req.isBackgroundRequest);
    const reqEndEvent: ApiEvent = new ApiEvent(req.url, req.isBackgroundRequest);

    let request: HttpRequest<any> = req.autoContentType ? req.httpRequest : this.setContentTypeHeader(req.httpRequest);
    request = this.addCustomHeaders(request);

    this.apiWatchService.onRequestStart(reqStartEvent);

    let promise: Promise<ResponseBody<TData, TMeta>> = <any>this.httpClient.request(request)
      .toPromise()
      .then(
        (response: HttpResponse<ResponseBody<TData, TMeta>>) => {
          this.apiWatchService.onRequestEnd(reqEndEvent);
          this.logResponseForCustomHeaders<TData, TMeta>(request, response, null);
          return this.mapResponse(response);
        },
        (error: HttpErrorResponse) => {
          this.apiWatchService.onRequestEnd(reqEndEvent);
          this.logResponseForCustomHeaders<TData, TMeta>(request, null, error);
          return this.handleServerError(error);
        }
      );

    return promise;
  }

  public request<TData, TMeta extends Meta>(req: HttpRequest<any>): Promise<ResponseBody<TData, TMeta>> {
    Assert.isNotNull(req, 'req');
    Assert.isNotNull(req.url, 'req.url');
    Assert.isNotNull(req.method, 'req.method');

    const reqEvent: ApiEvent = new ApiEvent(req.url);

    let request: HttpRequest<any> = this.setContentTypeHeader(req);
    request = this.addCustomHeaders(request);
    this.apiWatchService.onRequestStart(reqEvent);

    let promise: Promise<ResponseBody<TData, TMeta>> = <any>this.httpClient.request(request)
      .toPromise()
      .then(
        (response: HttpResponse<ResponseBody<TData, TMeta>>) => {
          this.apiWatchService.onRequestEnd(reqEvent);
          this.logResponseForCustomHeaders<TData, TMeta>(request, response, null);
          return this.mapResponse(response);
        },
        (error: HttpErrorResponse) => {
          this.apiWatchService.onRequestEnd(reqEvent);
          this.logResponseForCustomHeaders<TData, TMeta>(request, null, error);
          return this.handleServerError(error);
        }
      );

    return promise;
  }

  public requestForFile(req: HttpRequest<any>): Promise<FileBlobResponse> {
    Assert.isNotNull(req, 'req');
    Assert.isNotNull(req.url, 'req.url');
    Assert.isNotNull(req.method, 'req.method');

    let request: HttpRequest<any> = this.setContentTypeHeader(req);
    request = request.clone({
      responseType: 'blob'
    });

    let promise: Promise<FileBlobResponse> = <any>this.httpClient.request(request)
      .toPromise()
      .then((response: HttpResponse<Blob>) => this.mapFileResponse(response), (error: any) => {
        if (error.status === 0)
          throw error;
        else
          return this.handleBlobServerError(error);
      });
    return promise;
  }

  private handleBlobServerError(error: any) {
    //https://github.com/angular/angular/issues/19888
    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onloadend = (e) => {
        error.error = JSON.parse(<string>reader.result);
        this.handleServerError(error)
          .then((res) => resolve(res))
          .catch((err) => reject(err));
      };
      let responseBody: any = error.error || error.body;
      reader.readAsText(responseBody);
    });
  }

  private mapFileResponse(responseData: HttpResponse<Blob>): FileBlobResponse {
    const headers: HttpHeaders = responseData.headers;
    const contentDispositionHeader: string = headers.get('Content-Disposition') || headers.get('content-disposition');
    let { name, extension } = this.getFileDataFromContentDispositionHeader(contentDispositionHeader);

    let response: FileBlobResponse = new FileBlobResponse();
    response.blob = responseData.body;
    response.filename = name;
    response.fileExtension = extension;

    return response;
  }

  private getFileDataFromContentDispositionHeader(headerValue: string): { name: string, extension: string } {
    let name: string = 'unknown';
    let extension: string = '';

    if (!headerValue) {
      return { name, extension };
    }

    let fileFullName: string;

    try {
      // new implementation that uses an external library to parse content-disposition
      const parsedContentDisposition = ContentDisposition.Parse(headerValue);
      fileFullName = parsedContentDisposition.parameters.filename;
    } catch (e) {
      // in case new implementation fails, fall back into the old implementation
      const regexp: RegExp = /filename="?([\w\W\s]*\.\w+)"?/;
      const matches: string[] | null = headerValue.match(regexp);
      if (_.isArray(matches)) {
        fileFullName = matches[1];
      }
    }

    if (fileFullName) {
      const splittedFullName: string[] = fileFullName.split('.');
      if (_.size(splittedFullName) > 1) {
        name = splittedFullName.slice(0, -1).join('.');
        extension = _.head(splittedFullName.slice(-1));
      }
    }

    return { name, extension };
  }

  private setContentTypeHeader(request: HttpRequest<any>): HttpRequest<any> {
    const contentTypeHeader: string = 'Content-Type';
    const jsonContentType: string = 'application/json';

    return request.clone({
      headers: request.headers.set(contentTypeHeader, jsonContentType)
    });
  }

  private mapResponse(response: HttpResponse<any>): Promise<ResponseBody<any, any>> {
    Assert.isNotNull(response, 'response');

    let promise: Promise<ResponseBody<any, any>>;
    let responseBody: ResponseBody<any, any>;
    if (response.ok) {
      if (response.status === StatusCodes.noContent) {
        responseBody = new ResponseBody<any, any>(response.status);
      } else {
        responseBody = response.body;
      }
      responseBody.status = response.status;
      promise = Promise.resolve(responseBody);
    } else {
      promise = this.handleServerError(response);
    }

    return promise;
  }

  private handleServerError(error: any): Promise<ResponseBody<any, Meta>> {
    let errorMessage: string;
    let responseBody: any = error.error || error.body;
    let uniqueErrorId: string = (responseBody && responseBody.meta) ? responseBody.meta.uniqueId : '';

    if (responseBody && !StringUtils.isNullOrEmpty(responseBody.message)) {
      errorMessage = responseBody.message;
    } else if (error.status === StatusCodes.conflict) {
      errorMessage = responseBody.meta.error;
    } else if (!!error.status || !StringUtils.isNullOrEmpty(error.statusText)) {
      errorMessage = `${error.status} - ${error.statusText}`;
    } else {
      errorMessage = 'Unknown server error';
    }

    let meta: Meta = new Meta(errorMessage, uniqueErrorId);
    let response: ResponseBody<any, Meta> = new ResponseBody<any, Meta>(error.status, undefined, meta);

    return Promise.reject<ResponseBody<any, Meta>>(response);
  }

  private addCustomHeaders(req: HttpRequest<any>): HttpRequest<any> {
    if (!this.appConfig.debugging.customHeaderForPost.disabled && req.method === this.appConfig.debugging.customHeaderForPost.method) {
      const value = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(16);
      console.log(`${new Date()}: ${value}`);
      return req.clone({ headers: req.headers.append(this.appConfig.debugging.customHeaderForPost.name, value) });
    }

    return req;
  }

  private logResponseForCustomHeaders<TData, TMeta>(req: HttpRequest<any>, response: HttpResponse<ResponseBody<TData, TMeta>>, error: HttpErrorResponse): void {
    if (!this.appConfig.debugging.customHeaderForPost.disabled && req.method === this.appConfig.debugging.customHeaderForPost.method) {
      if (!_.isNil(response)) {
        console.log(`Debug String ID: ${response.headers.get(this.appConfig.debugging.customHeaderForPost.name)}`);
      } else if (!_.isNil(error)) {
        console.error(`Debug String ID: ${error.headers.get(this.appConfig.debugging.customHeaderForPost.name)}`)
      }
    }
  }
}
