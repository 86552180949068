import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll } from '../../../core/decorators/index';
import { ChangeManagementService } from '../../../common/index';
import { acaQuickPopupState, Aca1095c, Aca1095CPopupInitData, EmployeeFilterRecord, EmployeeFilter, Aca1095cRecord, AcaMonthRecord, AcaInsightFilter, EmployeeIdList, AcaInsightSummaryRecords } from '../../models/aca-1095-c/aca-1095-c-record';
import { initialAca1095cState, Aca1095cFilterState } from '../../models/aca-1095-c/aca-1095-c-state';
import { Aca1095cApiService } from './aca-1095-c-api.service';
import { ColumnManagementService, StateManagementService } from '../../../common';
import { Aca1095cSettings, Aca1095cColumnsSettings } from '../../models/aca-1095-c/aca-1095-c-settings';
import { StateResetTypes } from '../../../core/models';
import { LookupApiService } from '../../../organization/services/index';
var Aca1095cManagementService = /** @class */ (function () {
    function Aca1095cManagementService(apiService, columnManagement, stateManagement, lookupApiService, changeService) {
        this.apiService = apiService;
        this.columnManagement = columnManagement;
        this.stateManagement = stateManagement;
        this.lookupApiService = lookupApiService;
        this.changeService = changeService;
        this.stateKey = 'Aca1095cState';
        this.selectedYear = moment().toDate().getFullYear();
        this.filter = [];
        this.expandedEmployeeList = [];
        this.acaFilterState = new Aca1095cFilterState();
        this.loading$ = new Subject();
        this.recordsLoaded$ = new Subject();
        this.insightsLoaded$ = new Subject();
        this.employeeFilterRecords$ = new Subject();
        this.employeeFilter$ = new Subject();
        this.exportTo$ = new Subject();
        this.expandAll$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.addRemoveEmployee$ = new Subject();
        this.init1095Popup$ = new Subject();
        this.acaQuickPopupState$ = new Subject();
        this.quickEditInitData$ = new Subject();
        this.quickEditSelectedData$ = new Subject();
        this.quickEditRecords$ = new Subject();
        this.quickConfirmRecords$ = new Subject();
        this.quickConfirmResetRecords$ = new Subject();
        this.quickEditResetRecords$ = new Subject();
        this.undoQuickRecords$ = new Subject();
        this.quickMonthAction$ = new Subject();
        this.showWarningPopup$ = new Subject();
        this.subscriptions = {};
        this.onSettingsChanged$ = new ReplaySubject(1);
    }
    Aca1095cManagementService.prototype.init = function () {
        this.subscribeToOrgLevelChanges();
        this.columnSettingsChanged();
        this.getAcaSafeHarbor();
        this.getAcaReason();
        this.getAcaOfferOfCoverage();
    };
    Aca1095cManagementService.prototype.destroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.recordsLoaded$.complete();
        this.insightsLoaded$.complete();
        this.employeeFilterRecords$.complete();
        this.exportTo$.complete();
        this.expandAll$.complete();
        this.orgLevelChanged$.complete();
        this.employeeFilter$.complete();
        this.addRemoveEmployee$.complete();
        this.init1095Popup$.complete();
        this.quickEditInitData$.complete();
        this.quickEditSelectedData$.complete();
        this.quickEditRecords$.complete();
        this.quickConfirmRecords$.complete();
        this.quickConfirmResetRecords$.complete();
        this.quickEditResetRecords$.complete();
        this.quickMonthAction$.complete();
        this.showWarningPopup$.complete();
        this.undoQuickRecords$.complete();
    };
    Aca1095cManagementService.prototype.setUndoQuickRecord = function (item) {
        this.undoQuickRecords$.next(item);
    };
    Aca1095cManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    Aca1095cManagementService.prototype.clearChanges = function () {
        this.changeService.clearChanges();
    };
    Aca1095cManagementService.prototype.setShowPopupMessage = function (message) {
        this.showWarningPopup$.next(message);
    };
    Aca1095cManagementService.prototype.setQuickMonthAction = function (monthAction) {
        this.quickMonthAction$.next(monthAction);
    };
    Aca1095cManagementService.prototype.setQuickPopupState = function (state) {
        this.quickpopupState = state;
        this.acaQuickPopupState$.next(state);
    };
    Aca1095cManagementService.prototype.subscribeToUndoQuickRecord = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.undoQuickRecords$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToShowPopupMessage = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.showWarningPopup$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToQuickMonthAction = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.quickMonthAction$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToQuickPopupState = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.acaQuickPopupState$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.submitQuick1095cRecords = function (records, popupSate) {
        switch (popupSate) {
            case acaQuickPopupState.quickConfirmSave:
                {
                    this.saveQuickConfirm1095cRecords(records);
                    break;
                }
            case acaQuickPopupState.quickEditSave:
                {
                    this.saveQuickEdit1095cRecords(records);
                    //statements; 
                    break;
                }
            case acaQuickPopupState.quickEditResetSave:
                {
                    this.saveQuickEditReset1095cRecords(records);
                    //statements; 
                    break;
                }
            case acaQuickPopupState.quickConfirmResetSave:
                {
                    this.saveQuickConfirmReset1095cRecords(records);
                    break;
                }
            default: {
                //statements; 
                break;
            }
        }
    };
    Aca1095cManagementService.prototype.setQuickEditRecords = function (data) {
        this.quickEditRecords$.next(data);
    };
    Aca1095cManagementService.prototype.setQuickConfirmRecords = function (data) {
        this.quickConfirmRecords$.next(data);
    };
    Aca1095cManagementService.prototype.setQuickConfirmResetRecords = function (data) {
        this.quickConfirmResetRecords$.next(data);
    };
    Aca1095cManagementService.prototype.setQuickEditResetRecords = function (data) {
        this.quickEditResetRecords$.next(data);
    };
    Aca1095cManagementService.prototype.setQuickEditInitData = function (editData) {
        this.quickEditInitData$.next(editData);
    };
    Aca1095cManagementService.prototype.setQuickEditSelectedData = function (editData) {
        this.quickEditSelectedData$.next(editData);
    };
    Aca1095cManagementService.prototype.isAddRemoveEmployee = function (aca1095cRecord) {
        this.addRemoveEmployee$.next(aca1095cRecord);
    };
    Aca1095cManagementService.prototype.init1095Popup = function (data) {
        this.init1095Popup$.next(data);
    };
    Aca1095cManagementService.prototype.exportTo = function (isPDF) {
        this.exportTo$.next(isPDF);
    };
    Aca1095cManagementService.prototype.expandAll = function (isExpand) {
        this.expandAll$.next(isExpand);
    };
    Aca1095cManagementService.prototype.settingsChanged = function (columnsSettings) {
        this.onSettingsChanged$.next(columnsSettings);
    };
    Aca1095cManagementService.prototype.changeEmployeeFilter = function (filters) {
        this.employeeFilterRecords$.next(filters);
    };
    Aca1095cManagementService.prototype.setEmployeeFilterRecords = function (empFilter) {
        this.employeeFilter$.next(empFilter);
    };
    Aca1095cManagementService.prototype.subscribeToQuickEditRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.quickEditRecords$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToQuickConfirmRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.quickConfirmRecords$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToQuickEditResetRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.quickEditResetRecords$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToQuickConfirmResetRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.quickConfirmResetRecords$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToExport = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportTo$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToIsAddRemoveEmployee = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.addRemoveEmployee$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToInit1095Popup = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.init1095Popup$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToQuickEditInitData = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.quickEditInitData$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToQuickEditSelectedData = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.quickEditSelectedData$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToExpandAll = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.expandAll$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToLoadedRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.recordsLoaded$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToInsightsRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.insightsLoaded$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToEmpFilterRecords = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilterRecords$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToEmployeeFilter = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.employeeFilter$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    Aca1095cManagementService.prototype.removeEmployee = function (empId, empMasterId, companyId) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.removeEmployee1095c(this.orgLevel.id, this.selectedYear, empMasterId, empId, companyId)
            .then(function (container) {
            _this.setAca1095cRecords(container, _this.acaFilterState, true);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    Aca1095cManagementService.prototype.saveQuickEdit1095cRecords = function (requestBody) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.edit1095cRecords(this.orgLevel.id, this.selectedYear, requestBody)
            .then(function (container) {
            _this.quickEditRecords$.next();
            _this.setQuickPopupState(acaQuickPopupState.quickEditClose);
            _this.setAca1095cRecords(container, _this.acaFilterState, true);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    Aca1095cManagementService.prototype.addEmployee = function (empId, empMasterId, companyId, departmentId, organizationId) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.addEmployee1095c(this.orgLevel.id, this.selectedYear, empMasterId, empId, companyId, departmentId, organizationId)
            .then(function (container) {
            _this.setQuickPopupState(acaQuickPopupState.quickEditClose);
            _this.setAca1095cRecords(container, _this.acaFilterState, true);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    Aca1095cManagementService.prototype.saveQuickConfirm1095cRecords = function (requestBody) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.confirm1095cRecords(this.orgLevel.id, this.selectedYear, requestBody)
            .then(function (container) {
            _this.quickEditRecords$.next();
            _this.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
            _this.setAca1095cRecords(container, _this.acaFilterState, true);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    Aca1095cManagementService.prototype.saveQuickEditReset1095cRecords = function (requestBody) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.editReset1095cRecords(this.orgLevel.id, this.selectedYear, requestBody)
            .then(function (container) {
            _this.quickEditRecords$.next();
            _this.setQuickPopupState(acaQuickPopupState.quickEditClose);
            _this.setAca1095cRecords(container, _this.acaFilterState, true);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    Aca1095cManagementService.prototype.saveQuickConfirmReset1095cRecords = function (requestBody) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.confirmReset1095cRecords(this.orgLevel.id, this.selectedYear, requestBody)
            .then(function (container) {
            _this.quickEditRecords$.next();
            _this.setQuickPopupState(acaQuickPopupState.quickConfirmClose);
            _this.setAca1095cRecords(container, _this.acaFilterState, true);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    Aca1095cManagementService.prototype.resetQuickPopupState = function (state) {
        switch (state) {
            case acaQuickPopupState.quickEditActive:
            case acaQuickPopupState.quickEditStart:
                {
                    this.setQuickPopupState(acaQuickPopupState.quickEditClearAndClose);
                }
            case acaQuickPopupState.quickConfirmActive:
            case acaQuickPopupState.quickConfirmStart:
                {
                    this.setQuickPopupState(acaQuickPopupState.quickConfirmClearAndClose);
                }
            case acaQuickPopupState.quickEditResetActive:
            case acaQuickPopupState.quickEditResetStart:
                {
                    this.setQuickPopupState(acaQuickPopupState.quickEditResetClearAndClose);
                }
            case acaQuickPopupState.quickConfirmResetActive:
            case acaQuickPopupState.quickConfirmResetStart:
                {
                    this.setQuickPopupState(acaQuickPopupState.quickConfirmResetClearAndClose);
                }
        }
    };
    Aca1095cManagementService.prototype.getAcaSafeHarbor = function () {
        var _this = this;
        this.lookupApiService.getAcaSafeHarbors()
            .then(function (data) {
            _this.acaSafeHarbor = data;
        });
    };
    Aca1095cManagementService.prototype.getAcaReason = function () {
        var _this = this;
        this.lookupApiService.getAcaReasons()
            .then(function (data) {
            _this.acaReason = data;
        });
    };
    Aca1095cManagementService.prototype.getAcaOfferOfCoverage = function () {
        var _this = this;
        this.lookupApiService.getAcaOfferOfCoverage(this.selectedYear)
            .then(function (data) {
            _this.acaOfferOfCoverage = data;
        });
    };
    Aca1095cManagementService.prototype.getSelectedYear = function () {
        return this.selectedYear;
    };
    Aca1095cManagementService.prototype.setAca1095cRecords = function (container, acaFilterState, isAddDelete) {
        if (isAddDelete === void 0) { isAddDelete = false; }
        if (acaFilterState && acaFilterState.employeeIdList) {
            var filteredRecords = _.forEach(container.records, function (r) {
                _.forEach(acaFilterState.employeeIdList, function (k) {
                    if (k.employeeId === r.employeeId) {
                        r.expand = true;
                        return false;
                    }
                });
            });
            container.records = filteredRecords;
        }
        if (acaFilterState && (acaFilterState.employeeIdList == null || acaFilterState.employeeIdList.length == 0)) {
            var filteredRecords = _.forEach(container.records, function (record) {
                record.expand = acaFilterState.isExpandAll;
            });
            container.records = filteredRecords;
        }
        if (isAddDelete) {
            container.actions = this.originalContainer.actions;
        }
        this.originalContainer = container;
        this.recordsLoaded$.next(container);
        this.insightsLoaded$.next(container.insightSummary);
        var employeeFilter = new EmployeeFilter();
        employeeFilter.employeeFilterRecord = [];
        employeeFilter.filter = [];
        if (container && container.records) {
            _.forEach(container.records, function (item) {
                var empItem = new EmployeeFilterRecord();
                empItem.id = item.employeeId;
                empItem.name = item.employeeName;
                employeeFilter.employeeFilterRecord.push(empItem);
            });
            if (acaFilterState && acaFilterState.employeeListFilter) {
                employeeFilter.filter = acaFilterState.employeeListFilter;
            }
            this.employeeFilter$.next(employeeFilter);
        }
        this.loading$.next(false);
    };
    Aca1095cManagementService.prototype.loadAca1095cRecords = function (year, acaFilterState) {
        var _this = this;
        if (!_.isFinite(_.get(this.orgLevel, 'id'))) {
            return;
        }
        this.loading$.next(true);
        this.apiService.getAca1095cRecords(this.orgLevel.id, year)
            .then(function (container) {
            _this.resetQuickPopupState(_this.quickpopupState);
            _this.setAca1095cRecords(container, acaFilterState);
        })
            .catch(function () {
            _this.loading$.next(false);
        });
    };
    Aca1095cManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return o && _.isFinite(o.id); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            if (_this.selectedYear) {
                _this.restoreState();
            }
        });
    };
    Aca1095cManagementService.prototype.columnSettingsChanged = function () {
        var _this = this;
        this.subscriptions.changedColumns = this.columnManagement.columnsChanged$.filter(function (event) { return event.group === 'Aca1095c'; })
            .subscribe(function (event) {
            var columnsSettings = new Aca1095cSettings();
            columnsSettings.columns = new Aca1095cColumnsSettings();
            columnsSettings.columns.columns = [];
            columnsSettings.columns.columns = event.columns;
            columnsSettings.columns.mapColumns();
            _this.onSettingsChanged$.next(columnsSettings);
        });
    };
    Aca1095cManagementService.prototype.setEmployeeIdList = function (item) {
        if (this.expandedEmployeeList.indexOf(item) === -1) {
            if (item.expand) {
                this.expandedEmployeeList.push(item);
            }
            else {
                var list = _.find(this.expandedEmployeeList, { 'employeeId': item.employeeId });
                this.expandedEmployeeList = _.without(this.expandedEmployeeList, list);
            }
            this.set1095cState();
        }
    };
    Aca1095cManagementService.prototype.setSelectedYear = function (selYear) {
        this.selectedYear = selYear;
        this.getAcaOfferOfCoverage();
        this.restoreState();
    };
    Aca1095cManagementService.prototype.setInsightsFilter = function (insightsFilter) {
        this.insightsFilter = insightsFilter;
        this.applyInsights();
    };
    Aca1095cManagementService.prototype.setEmployeeFilter = function (filters) {
        this.filter = filters;
        this.set1095cState();
        this.changeEmployeeFilter(filters);
    };
    Aca1095cManagementService.prototype.setEmployeeGridExpandCollapse = function (isExpand) {
        this.isExpandAll = isExpand;
        this.expandedEmployeeList = [];
        this.set1095cState();
    };
    Aca1095cManagementService.prototype.applyInsights = function () {
        var container = _.clone(this.originalContainer);
        var records = container.records;
        if (this.insightsFilter) {
            switch (this.insightsFilter.filter) {
                case 'FT_Enrolled_FullYear': {
                    records = _.filter(records, function (item) {
                        if (item.isFullTimeEnrolledFullYear) {
                            return true;
                        }
                    });
                    break;
                }
                case 'FT_Enrolled_PartYear': {
                    records = _.filter(records, function (item) {
                        if (item.isFullTimeEnrolledPartYear) {
                            return true;
                        }
                    });
                    break;
                }
                case 'FT_NotEnrolled': {
                    records = _.filter(records, function (item) {
                        if (item.isFullTimeNotEnrolled) {
                            return true;
                        }
                    });
                    break;
                }
                case 'PT_Enrolled': {
                    records = _.filter(records, function (item) {
                        if (item.isPartTimeEnrolled) {
                            return true;
                        }
                    });
                    break;
                }
                case 'NotConfirmed': {
                    records = _.filter(records, function (item) {
                        if (item.isNotConfirmed) {
                            return true;
                        }
                    });
                    break;
                }
                default: {
                    break;
                }
            }
        }
        container.records = records;
        this.recordsLoaded$.next(container);
        var employeeFilter = new EmployeeFilter();
        employeeFilter.employeeFilterRecord = [];
        employeeFilter.filter = [];
        this.employeeFilterRecords$.next(employeeFilter.employeeFilterRecord);
        if (container && container.records) {
            _.forEach(container.records, function (item) {
                var empItem = new EmployeeFilterRecord();
                empItem.id = item.employeeId;
                empItem.name = item.employeeName;
                employeeFilter.employeeFilterRecord.push(empItem);
            });
            employeeFilter.filter = [];
            this.filter = [];
            this.set1095cState();
            this.employeeFilter$.next(employeeFilter);
        }
    };
    Aca1095cManagementService.prototype.set1095cState = function () {
        if (!this.selectedYear) {
            return;
        }
        var state = _.clone(initialAca1095cState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        var acaState = new Aca1095cFilterState();
        acaState.year = this.selectedYear;
        acaState.orgLevelId = this.orgLevel.id;
        acaState.employeeListFilter = this.filter;
        if (this.expandedEmployeeList && this.expandedEmployeeList.length > 0) {
            acaState.employeeIdList = this.expandedEmployeeList;
        }
        if (this.expandedEmployeeList && this.expandedEmployeeList.length == 0) {
            acaState.isExpandAll = this.isExpandAll;
        }
        if (state && state.aca1095cStateList && state.aca1095cStateList.length > 0) {
            var index = _.findIndex(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
            if (index >= 0) {
                var item = _.find(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
                state.aca1095cStateList = _.without(state.aca1095cStateList, item);
                state.aca1095cStateList.push(acaState);
            }
            else {
                state.aca1095cStateList.push(acaState);
            }
        }
        else {
            state.aca1095cStateList = [];
            state.aca1095cStateList = [acaState];
        }
        this.saveState(state);
    };
    Aca1095cManagementService.prototype.saveState = function (state) {
        this.stateManagement.setControlState(this.stateKey, {
            value: state
        }, StateResetTypes.None);
    };
    Aca1095cManagementService.prototype.restoreState = function () {
        if (!this.selectedYear) {
            return;
        }
        var state = _.clone(initialAca1095cState);
        var controlState = this.stateManagement.getControlState(this.stateKey);
        if (controlState && controlState.value) {
            state = controlState.value;
        }
        if (state && state.aca1095cStateList) {
            if (_.findIndex(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id }) >= 0) {
                this.acaFilterState = _.find(state.aca1095cStateList, { 'year': this.selectedYear, 'orgLevelId': this.orgLevel.id });
            }
            else {
                this.acaFilterState = null;
            }
        }
        else {
            this.acaFilterState = null;
        }
        if (this.acaFilterState) {
            this.isExpandAll = this.acaFilterState.isExpandAll;
        }
        this.loadAca1095cRecords(this.selectedYear, this.acaFilterState);
    };
    tslib_1.__decorate([
        unsubscribeAll('destroy'),
        tslib_1.__metadata("design:type", Object)
    ], Aca1095cManagementService.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], Aca1095cManagementService.prototype, "orgLevel$", void 0);
    return Aca1095cManagementService;
}());
export { Aca1095cManagementService };
