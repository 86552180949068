import { Component, Input } from "@angular/core";
import { NotificationsService } from '../../../../../app/core/components';
import { BusyService } from '../../../../common/index';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { BaseSyncComponent } from '../wfm-sync-base.component';
import { dataCheck_emp, IWFMSecondaryPositionSyncModel } from './../../../models/wfm-sync';

@Component({
    selector: 'slx-wfm-ngp-secondary-positions-sync',
    templateUrl: './wfm-ngp-secondary-positions-sync.component.html',
    styleUrls: ['./wfm-ngp-secondary-positions-sync.component.scss'],
})
export class WfmNgpSecondaryPositionsSyncComponent extends BaseSyncComponent<IWFMSecondaryPositionSyncModel> {
  @Input() syncType: string;

  constructor(
    private wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  protected syncDataCore(selectedItems: IWFMSecondaryPositionSyncModel[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.secondaryPositionSyncOrg(selectedItems, this.syncType)
    );
  }

  protected getSyncItemKey(item: IWFMSecondaryPositionSyncModel): string {
    return '' + item.id;
  }

  protected async fetchData(): Promise<IWFMSecondaryPositionSyncModel[]> {
    const response = await this.busyService.busy(
      this.wfmSyncService.getSecondaryPositionsDataSync(this.syncType)
    );

    return this.processData(response.data);
  }

  private processData(data: IWFMSecondaryPositionSyncModel[]): IWFMSecondaryPositionSyncModel[] {
    return dataCheck_emp(data);
  }
}
