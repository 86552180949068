import { Component, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';

import { OrgLevel } from '../../../../state-model/models/index';
import { PayCycle } from '../../../../organization/models/index';
import { mutableSelect, unsubscribe } from '../../../../core/decorators/index';
import { EssTemplateManagementService } from '../../services/index';
import { AppSettingsManageService } from '../../../../app-settings/services';

@Component({
  moduleId: module.id,
  selector: 'slx-ess-templates-header',
  templateUrl: 'ess-templates-header.component.html',
  styleUrls: ['ess-templates-header.component.scss']
})
export class EssTemplatesHeaderComponent implements OnInit, OnDestroy {

  public orgLevel: OrgLevel;
  public isGoClient: boolean;

  @mutableSelect('orgLevel')
  public orgLevel$: Observable<OrgLevel>;

  @unsubscribe()
  private orgLevelSubscription: Subscription;
  constructor(private managementService: EssTemplateManagementService, private appSettingsManageService: AppSettingsManageService) {

  }

  public ngOnInit(): void {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      if (orgLevel && orgLevel.id && ((this.orgLevel && (orgLevel.id !== this.orgLevel.id)) || !this.orgLevel)) {
        this.orgLevel = orgLevel;
        this.load();
      }
    });
  }

  public passwordSettings() {

  }

  public addTemplate() {
    this.managementService.addTemplate(this.orgLevel);
    this.managementService.AddNewTempateAction(true);
  }

  public load(): void {
    if (!this.orgLevel.id) {
      return;
    }
    this.managementService.loadTemplates(this.orgLevel);
    this.getGoClientsetting();
  }

  private async getGoClientsetting(): Promise<void> {
    const config = await this.appSettingsManageService.getAppServerConfig();   
    this.isGoClient = config.IsGOClient;    
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }
}
