import { ChartType } from './chart-type';

export class ChartMenuItem {
  public text: string;
  public icon: string;
  public type: ChartType;
  public isShown: boolean;

  constructor(text: string, icon: string, type: ChartType, isShown: boolean = false) {
    this.text = text;
    this.icon = icon;
    this.type = type;
    this.isShown = isShown;
  }
}
