<div class="row">
    <!--<i class="wi wi-thermometer"></i>-->
    <span>
        <span>Low:</span><span class="value">{{minTemp | number:'1.0-0'}}°{{scaleLabel}}</span>
        <span>High:</span><span class="value">{{maxTemp| number:'1.0-0'}}°{{scaleLabel}}</span>
    </span>

  </div>
  <!--<div class="row">
    <span><i class="wi wi-barometer"></i>Pressure: {{pressure}}</span>
    <span><i class="wi wi-humidity"></i>Humidity: {{humidity}}%</span>
  </div>-->