import {
  ShiftDefinition,
  IShiftDefinition,
  LocationUnit,
  ILocationUnit,
  Position,
  IPosition,
} from '../lookup/index';

import { IEmployeeDefinition, EmployeeDefinition } from '../employee/index';
import { IScheduleEntryDefinition, ScheduleEntryDefinition, ScheduleActions } from './index';

export interface IEmployeeScheduleDefinition {
  employee: IEmployeeDefinition;
  position: IPosition;
  homeShift: IShiftDefinition;
  homeUnit: ILocationUnit;
  entries: IScheduleEntryDefinition[];
  secondaryPositions: IPosition[];
  canEditScheduleApprovedPayPeriod ? : boolean;
}

export class EmployeeScheduleDefinition {
  public employee: EmployeeDefinition;
  public position: Position;
  public homeShift: ShiftDefinition;
  public homeUnit: LocationUnit;
  public entries: ScheduleEntryDefinition[];
  public secondaryPositions: Position[];
  public canEditScheduleApprovedPayPeriod ? : boolean;
}

export class EmployeeScheduleDefinitionContainer {
  public definitions: EmployeeScheduleDefinition[];
  public actions: ScheduleActions;
}
