import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { dateTimeUtils } from '../../../common/utils/index';
import { appConfig } from '../../../app.config';
import { accrualsConfig } from '../accruals.config';
import { IAccrualBalances, AccrualBalances, AccrualRecalculate, IAccrualRecalculate } from '../models/index';
import { AccrualsMapService } from './accruals-map.service';
import { HttpRequest } from '@angular/common/http';
var AccrualsApiService = /** @class */ (function () {
    function AccrualsApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    AccrualsApiService.prototype.getAccrualBalances = function (orgLevelId, effectiveDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + accrualsConfig.api.accruals.balances + "/" + orgLevelId + "/all";
                request = this.urlParamsService.createGetRequest(url, {
                    effectiveDate: dateTimeUtils.convertToDtoString(effectiveDate)
                });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAccrualBalances(response.data, response.meta); })];
            });
        });
    };
    AccrualsApiService.prototype.getAccrualsTransactionRecords = function (orgLevelId, sDate, eDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + accrualsConfig.api.accruals.transaction + "/" + orgLevelId + "/all";
                request = this.urlParamsService.createGetRequest(url, {
                    startDate: dateTimeUtils.convertToDtoString(sDate),
                    endDate: dateTimeUtils.convertToDtoString(eDate)
                });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAccrualsTransactions(response.data, response.meta); })];
            });
        });
    };
    AccrualsApiService.prototype.getAccrualsRecalculateData = function (orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.status.root + "/" + accrualsConfig.api.accruals.status.calculationRequest;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapAccrualsRecalculateRecords(response.data); })];
            });
        });
    };
    AccrualsApiService.prototype.postAccrualsRecalculate = function (orgLevelId) {
        var url = this.getAccrualsRootApi() + "/" + orgLevelId + "/" + accrualsConfig.api.accruals.submit.root + "/" + accrualsConfig.api.accruals.submit.recalculate;
        var body;
        var request = new HttpRequest('POST', url, body);
        var promise = this.apiUtilService.request(request)
            .then(function (response) { return response.status; });
        return promise;
    };
    AccrualsApiService.prototype.getAccrualsRootApi = function () {
        return this.getApiRoot() + "/" + accrualsConfig.api.accruals.root;
    };
    AccrualsApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return AccrualsApiService;
}());
export { AccrualsApiService };
