import * as moment from 'moment';
import { LookupEntity } from './lookup-entity';
import { appConfig } from '../../../app.config';

export interface IShiftDefinition {
  id: number;
  name: string;
  start: string;
  end: string;
  duration: string;
  group: any;
  employeesCount: number;
  usedInIdealSchedule: boolean;
  lunchDuration: string;
}

export class ShiftDefinition extends LookupEntity implements IShiftDefinition {
  public startDate: Date;
  public endDate: Date;
  public durationValue: Date;
  public durationHoursValue: number;
  public group: any;
  public employeesCount: number;
  public usedInIdealSchedule: boolean;
  public lunchDurationValue: Date;
  public isDirty: boolean = false;

  public fullStartDate: Date;
  public fullEndDate: Date;

  constructor(id: number, name: string, startTime: string, endTime: string, duration: string,
              lunchDuration?: string, group?: any, employeesCount?: number, usedInIdealSchedule?: boolean) {
    super();
    this.startDate = new Date(`${new Date().toDateString()} ${startTime}`);
    this.endDate = new Date(`${new Date().toDateString()} ${endTime}`);
    this.durationValue = new Date(`${new Date().toDateString()} ${duration}`);
    this.durationHoursValue = this.durationValue.getHours() + this.durationValue.getMinutes()/60; // TODO
    this.id = id;
    this.name = name;
    this.lunchDurationValue = new Date(`${new Date().toDateString()} ${lunchDuration}`);
    this.group = group;
    this.employeesCount = employeesCount;
    this.usedInIdealSchedule = usedInIdealSchedule;
  }

  public get actualDuration(): Date {
    return this.durationValue;
  }

  public get lunchDurationDate(): Date {
    return this.lunchDurationValue;
  }

  public get isNextDayShift(): boolean {
    return this.endDate < this.startDate;
  }

  public get start(): string {
    return moment(this.startDate).format(appConfig.militaryTimeFormat);
  }

  public get end(): string {
    return moment(this.endDate).format(appConfig.militaryTimeFormat);
  }

  public get duration(): string {
    return moment(this.durationValue).format(appConfig.militaryTimeFormat);
  }

  public get durationHours(): number {
    return this.durationHoursValue;
  }

  public get lunchDuration(): string {
    return moment(this.lunchDurationValue).format(appConfig.militaryTimeFormat);
  }
}
