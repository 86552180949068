import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { process, State } from '@progress/kendo-data-query';
import { GridComponent } from '@progress/kendo-angular-grid';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { PunchLogsManagementService } from '../../../services/punches/punch-logs-management.service';
var PunchLogsGridComponent = /** @class */ (function () {
    function PunchLogsGridComponent(managementService, changeDetector) {
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.groupName = 'PunchLogs';
        this.gridState = new KendoGridStateHelper();
    }
    PunchLogsGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (records) {
            _this.records = records;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.onLoadedSubscription = this.managementService.onLoaded$.subscribe(function (records) {
            _this.records = records;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.exportSubscription = this.managementService.onExport$.subscribe(function (v) {
            _this.grid.saveAsExcel();
        });
    };
    PunchLogsGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PunchLogsGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.records, this.gridState.state);
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchLogsGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchLogsGridComponent.prototype, "onLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchLogsGridComponent.prototype, "exportSubscription", void 0);
    return PunchLogsGridComponent;
}());
export { PunchLogsGridComponent };
