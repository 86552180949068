import { OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Assert } from '../../../../framework/assert/assert';
import * as moment from 'moment';
import * as _ from 'lodash';
import { appConfig } from '../../../../app.config';
import { CalendarDataService } from '../../../../core/services/index';
import { OpenShiftShortDetailsSetWrapper } from '../../../models/index';
import { OpenShiftManagementManagementService } from '../../../services/index';
var OpenShiftManagementScheduleCycleComponent = /** @class */ (function () {
    function OpenShiftManagementScheduleCycleComponent(calendarDataService, openShiftManagementManagementService) {
        var _this = this;
        this.calendarDataService = calendarDataService;
        this.openShiftManagementManagementService = openShiftManagementManagementService;
        this.onSummaryItemSelected = new EventEmitter();
        this.isPartnerShiftCountUpdated = new EventEmitter();
        this.onMessageCountChanged = this.openShiftManagementManagementService.onMessageCountChanged$.subscribe(function (args) { return _this.onMessageActionExecuted(args); });
        this.onShiftCountChanged = this.openShiftManagementManagementService.onOpenShiftCountChanged$.subscribe(function (args) { return _this.onOpenShiftsCountChanged(args); });
        this.onSelectedDailyDetailsChnged = this.openShiftManagementManagementService.selectOpenShiftsDailyDetails$.subscribe(function (args) { return _this.onOpenShiftSelectedDailyDetailsChnged(args); });
        this.onPartnerShiftCountChanged = this.openShiftManagementManagementService.onPartnerShiftCountChanged$.subscribe(function (args) { return _this.onPartnerShiftActionExecuted(args); });
        this.state = {
            isLoading: false
        };
    }
    Object.defineProperty(OpenShiftManagementScheduleCycleComponent.prototype, "summaryDetailsSet", {
        get: function () {
            return this.m_summaryDetailsSet;
        },
        set: function (value) {
            this.m_summaryDetailsSet = value;
            this.initializeView();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OpenShiftManagementScheduleCycleComponent.prototype, "dayColumns", {
        get: function () {
            return this.m_dayColumns;
        },
        set: function (value) {
            this.m_dayColumns = value;
        },
        enumerable: true,
        configurable: true
    });
    OpenShiftManagementScheduleCycleComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
    };
    OpenShiftManagementScheduleCycleComponent.prototype.ngOnDestroy = function () {
        this.onMessageCountChanged.unsubscribe();
        this.onShiftCountChanged.unsubscribe();
    };
    OpenShiftManagementScheduleCycleComponent.prototype.onSummaryItemSelectedHandler = function (event) {
        if (!event)
            return;
        if (this.selectedSummaryDetailsItem)
            this.selectedSummaryDetailsItem.selected = false;
        this.selectedSummaryDetailsItem = event;
        this.selectedSummaryDetailsItem.selected = true;
        this.emitRequest(this.selectedSummaryDetailsItem);
    };
    OpenShiftManagementScheduleCycleComponent.prototype.initializeView = function () {
        if (this.summaryDetailsSet && this.summaryDetailsSet.weeklyData) {
            var firstWeek = _.first(this.summaryDetailsSet.weeklyData);
            if (firstWeek && firstWeek.days) {
                var firstDayOfTheWeek = firstWeek.days[0];
                if (firstDayOfTheWeek) {
                    this.dayColumns = this.calendarDataService.ExtendRangeToDailyData(moment(firstDayOfTheWeek.startOfDay), 7, null);
                }
            }
        }
    };
    OpenShiftManagementScheduleCycleComponent.prototype.emitRequest = function (summaryWrap) {
        this.onSummaryItemSelected.emit(summaryWrap);
    };
    OpenShiftManagementScheduleCycleComponent.prototype.onMessageActionExecuted = function (args) {
        Assert.isNotNull(args, 'args');
        var detailsItem = this.getDailyDetailsByDate(args.dateOn);
        if (detailsItem) {
            detailsItem.messageCount -= args.messageCountDiff;
        }
    };
    OpenShiftManagementScheduleCycleComponent.prototype.onPartnerShiftActionExecuted = function (args) {
        Assert.isNotNull(args, 'args');
        this.isPartnerShiftCountUpdated.emit(true);
        var detailsItem = this.getDailyDetailsByDate(args.dateOn);
        this.selectedSummaryDetailsItem.selected = true;
        this.emitRequest(this.selectedSummaryDetailsItem);
        if (detailsItem) {
            detailsItem.partnerShiftCount -= args.partnerShiftCountDiff;
        }
    };
    OpenShiftManagementScheduleCycleComponent.prototype.onOpenShiftSelectedDailyDetailsChnged = function (args) {
        Assert.isNotNull(args, 'args');
        this.onSummaryItemSelectedHandler(args.dailySummaryDetails);
    };
    OpenShiftManagementScheduleCycleComponent.prototype.onOpenShiftsCountChanged = function (args) {
        Assert.isNotNull(args, 'args');
        var detailsItem = this.getDailyDetailsByDate(args.dateOn);
        if (detailsItem) {
            detailsItem.openShiftCount -= args.shiftCountDiff;
        }
    };
    OpenShiftManagementScheduleCycleComponent.prototype.getDailyDetailsByDate = function (dateOn) {
        var details = this.flatEntries();
        var detailsItem = _.find(details, function (item) {
            return item.dateOn === dateOn;
        });
        return detailsItem;
    };
    OpenShiftManagementScheduleCycleComponent.prototype.flatEntries = function () {
        if (!this.summaryDetailsSet)
            return [];
        var rawEntries = _.reduce(this.summaryDetailsSet.weeklyData, function (dailyRecordsResult, weeklyData) {
            var days = _.map(_.values(weeklyData.days), function (day) {
                return day && day.data ? day.data.details : null;
            });
            return (dailyRecordsResult || []).concat(days);
        }, []);
        var entries = _.filter(rawEntries, function (entry) { return !!entry; });
        return entries;
    };
    return OpenShiftManagementScheduleCycleComponent;
}());
export { OpenShiftManagementScheduleCycleComponent };
