import { Component, OnInit } from '@angular/core';
import { Lookup, LookupApiService, LookupService, LookupType, ScheduleCycle } from './../../../../app/organization';
import * as moment from 'moment';
import { ScheduleRotationsManagementService } from '../../services/scheduled-rotation-template/schedule-rotations-management.service';
import { IDays } from '../../models/schedule-rotation/schedule-rotation-template.model';
import { mutableSelect, unsubscribe } from './../../../../app/core/decorators';
import { Observable, Subscription } from 'rxjs';
import { OrgLevel } from './../../../../app/state-model/models';
import * as _ from 'lodash';

@Component({
  selector: 'slx-scheduled-rotations',
  templateUrl: './scheduled-rotations.component.html',
  styleUrls: ['./scheduled-rotations.component.scss']
})
export class ScheduledRotationsComponent implements OnInit {

  @mutableSelect(['orgLevel'])
  public orgLevel$: Observable<OrgLevel>;
  public orgLevel: OrgLevel;
  public orgLevelId: number;
  public date = new Date();
  public cycles: ScheduleCycle[] = [];
  public firstCycle: any;
  public dates: IDays[] = [];
  public firstCycleStart: any;
  public dateFrom = moment().toDate();
  @unsubscribe()
  public orgLevelSubscription: Subscription;
  public positionGroupLookup: Lookup;
  public positionLookup: Lookup;
  public unitsLookup: Lookup;
  public shiftsLookup: Lookup;
  public unitsData: any;
  public startDate: string;
  public endDate: string;

  constructor(public lookupApi: LookupApiService,
    public manService: ScheduleRotationsManagementService,
    public lookupService: LookupService) { }

  ngOnInit() {
    this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
      this.orgLevelId = orgLevel.id;
      this.orgLevel = orgLevel;
      this.getScheduleCycles();
      this.getScheduleRotationPermission();
      this.loadFilterLookups(this.orgLevelId);
      this.getDefaultFilters();
    });
  }

  public getScheduleRotationPermission() {
    this.manService.getScheduleRotationPermission(this.orgLevelId);
  }

  public getScheduleCycles() {
    this.lookupApi.getScheduleCycles(this.orgLevel.id).then((data: any) => {
      this.manService.cycles = data;
      if (this.manService.cycles.length > 0) {
        const today = moment().startOf('day');
        this.manService.firstCycle = this.findPayCycle(this.manService.cycles, today.toDate());
        this.firstCycle = this.findPayCycle(this.manService.cycles, today.toDate());
        this.manService.dates = this.manService.enumerateDaysBetweenDates(this.firstCycle.startDate, this.firstCycle.endDate);
        this.firstCycleStart = this.firstCycle ? this.firstCycle.startDate : today;
        this.manService.dateFrom = this.firstCycleStart.toDate();
        this.startDate = moment(this.firstCycle.startDate).format('MM/DD/YYYY');
        this.endDate = moment(this.firstCycle.endDate).format('MM/DD/YYYY');
        this.manService.payCycle.next(this.firstCycle);
      }
    });
  }

  public findPayCycle(cycles: ScheduleCycle[], date: Date): ScheduleCycle {
    const currentDate: moment.Moment = moment(date).startOf('day');
    let selectedCycle: ScheduleCycle = _.find(cycles, (cycle: ScheduleCycle) => {
      return currentDate.isSameOrAfter(cycle.startDate) && currentDate.isSameOrBefore(cycle.endDate);
    });
    return selectedCycle;
  }

  public loadFilterLookups(selectedOrgLevelId: number): void {
    let positionGroupsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.positionGroups,
      orgLevelId: selectedOrgLevelId,
    });
    let positionsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.position,
      orgLevelId: selectedOrgLevelId,
    });
    let unitsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.locationUnit,
      orgLevelId: selectedOrgLevelId,
    });
    let shiftsPromise: Promise<Lookup> = this.lookupService.getLookup({
      lookupType: LookupType.shiftGroupDefinition,
      orgLevelId: selectedOrgLevelId,
    });

    Promise.all([positionGroupsPromise, positionsPromise, unitsPromise, shiftsPromise]).then((values: any[]) => {
      [this.positionGroupLookup, this.positionLookup, this.unitsLookup, this.shiftsLookup] = values;
      this.getUnitsLookupData(this.unitsLookup);
      this.manService.positionLookup = this.positionLookup;
      this.manService.positionGroupLookup = this.positionGroupLookup;
    });
  }

  public getUnitsLookupData(data: any) {
    let samData = [];
    data.items.forEach((x, ind) => {
      samData.push(
        {
          id: x.name === 'FL' ? 100001 : x.id,
          name: x.name
        }
      );
    });
    this.unitsData = samData;
  }

  public getDefaultFilters() {
    this.manService.filterRotationsRecord = this.manService.scheduleRotationApiService.getSRTDefaultFilterOption();
    this.manService.filterRotationsRecord$.next(this.manService.scheduleRotationApiService.getSRTDefaultFilterOption());
    return this.manService.filterRotationsRecord;
  }

}
