import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import {
  BenefitDetailsTier,
  BenefitDetailsEditModes,
  BenefitDetailsCalcMethod,
  BenefitDetailsLineStandart
} from '../../../models/index';
import { BenefitDetailsManagementService, BenefitDetailsPermissionService, BenefitDetailsStandartManagementService } from '../../../services/index';

@Component({
  selector: 'slx-benefit-details-benefit-info-menu',
  templateUrl: './benefit-details-benefit-info-menu.component.html',
  styleUrls: ['./benefit-details-benefit-info-menu.component.scss'],
})
export class BenefitDetailsBenefitInfoMenuComponent implements OnInit, OnDestroy {
  public tiers: BenefitDetailsTier[];
  public isEditMode = false;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};
  private selectedProviderTier: BenefitDetailsTier;

  constructor(
    private modalService: ModalService,
    private permissionService: BenefitDetailsPermissionService,
    private manService: BenefitDetailsStandartManagementService
  ) {
  }

  public ngOnInit() {
    this.subscriptions.selectProviderLine = this.manService
      .subscribeToProviderTiers((tiers: BenefitDetailsTier[]) => (this.tiers = _.filter(tiers, t => !t.stateIsDeleted)));

    this.subscriptions.providerTier = this.manService
      .subscribeToSelectProviderTier((tier: BenefitDetailsTier) => (this.selectedProviderTier = tier));

    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => (this.isEditMode = v.providerInfo));
  }

  public ngOnDestroy(): void {
  }

  public get hasSelectedTier(): boolean {
    return _.isObjectLike(this.selectedProviderTier);
  }

  public get canAdd(): boolean {
    return this.isEditMode && this.hasSelectedTier;
  }

  public get canDelete(): boolean {
    return this.isEditMode && this.hasSelectedTier && _.size(this.tiers) > 1;
  }

  public get confirmationPopupTitle(): string {
    let title = `Are you sure you want to remove selected benefit tier?`;
    if (this.hasSelectedTier && !this.selectedProviderTier.isDraft) {
      title += ` This will remove the plan from the employees' enrollment history`;
    }
    return title;
  }

  public isActiveMenu(tier: BenefitDetailsTier): boolean {
    return _.isObjectLike(tier) && this.hasSelectedTier && tier.id === this.selectedProviderTier.id;
  }

  public onClickTier(tier: BenefitDetailsTier): void {
    if (this.isActiveMenu(tier)) return;
    this.selectedProviderTier = tier;
    this.manService.selectProviderTier(tier);
  }

  public onAddNew(): void {
    if (this.canAdd) {
      this.manService.addProviderTier();
    }
  }

  public onDelete(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Delete Benefit Tier',
      this.confirmationPopupTitle,
      this.modalService,
      (isDelete: boolean) => {
        if (isDelete) {
          this.manService.deleteProviderTier(this.selectedProviderTier.id);
        }
      },
      options);
  }
}
