/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./kendo-grid-time-filter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../kendo-grid-filter-wrapper-cell/kendo-grid-filter-wrapper-cell.component.ngfactory";
import * as i3 from "../kendo-grid-filter-wrapper-cell/kendo-grid-filter-wrapper-cell.component";
import * as i4 from "@progress/kendo-angular-grid";
import * as i5 from "../../../directives/kendo-ui-extensions/kendo-grid-server-filter/kendo-grid-server-filter.directive";
import * as i6 from "../../../services/filter-state/filter-state-management.service";
import * as i7 from "../time-input-filter-wrapper/time-input-filter-wrapper.component.ngfactory";
import * as i8 from "../time-input-filter-wrapper/time-input-filter-wrapper.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../../directives/kendo-ui-extensions/kendo-grid-filter-input/kendo-grid-filter-input.directive";
import * as i11 from "./kendo-grid-time-filter.component";
import * as i12 from "@progress/kendo-angular-l10n";
var styles_KendoGridTimeFilterComponent = [i0.styles];
var RenderType_KendoGridTimeFilterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_KendoGridTimeFilterComponent, data: {} });
export { RenderType_KendoGridTimeFilterComponent as RenderType_KendoGridTimeFilterComponent };
export function View_KendoGridTimeFilterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "slx-kendo-grid-filter-wrapper-cell", [], [[2, "k-filtercell", null], [2, "k-filtercell-wrapper", null]], [[null, "operatorChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("operatorChanged" === en)) {
        var pd_0 = (_co.operatorChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_KendoGridFilterWrapperCellComponent_0, i2.RenderType_KendoGridFilterWrapperCellComponent)), i1.ɵdid(1, 1228800, null, 2, i3.KendoGridFilterWrapperCellComponent, [i4.FilterService, [2, i5.KendoGridServerFilterDirective], [2, i6.FilterStateManagementService]], { showOperators: [0, "showOperators"], operators: [1, "operators"], column: [2, "column"], filter: [3, "filter"], defaultOperator: [4, "defaultOperator"] }, { operatorChanged: "operatorChanged" }), i1.ɵqud(603979776, 1, { operatorList: 1 }), i1.ɵqud(335544320, 2, { input: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "slx-time-input-filter-wrapper", [["slxKendoGridFilterInput", ""]], null, null, null, i7.View_TimeInputFilterWrapperComponent_0, i7.RenderType_TimeInputFilterWrapperComponent)), i1.ɵdid(5, 2146304, null, 0, i8.TimeInputFilterWrapperComponent, [], null, null), i1.ɵprd(1024, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i8.TimeInputFilterWrapperComponent]), i1.ɵdid(7, 81920, [[2, 4]], 0, i10.KendoGridFilterInputDirective, [i9.NG_VALUE_ACCESSOR, i1.ChangeDetectorRef], { suppresAutoEmit: [0, "suppresAutoEmit"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showOperators; var currVal_3 = _co.operators; var currVal_4 = _co.column; var currVal_5 = _co.filter; var currVal_6 = _co.operator; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = ""; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).overrideBaseClasses; var currVal_1 = i1.ɵnov(_v, 1).hostClasses; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_KendoGridTimeFilterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-kendo-grid-time-filter", [], [[2, "k-filtercell", null]], null, null, View_KendoGridTimeFilterComponent_0, RenderType_KendoGridTimeFilterComponent)), i1.ɵdid(1, 1228800, null, 1, i11.KendoGridTimeFilterComponent, [i4.FilterService, i12.LocalizationService], null, null), i1.ɵqud(603979776, 1, { operatorList: 1 })], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).hostClasses; _ck(_v, 0, 0, currVal_0); }); }
var KendoGridTimeFilterComponentNgFactory = i1.ɵccf("slx-kendo-grid-time-filter", i11.KendoGridTimeFilterComponent, View_KendoGridTimeFilterComponent_Host_0, { showOperators: "showOperators", column: "column", filter: "filter", operator: "operator" }, {}, []);
export { KendoGridTimeFilterComponentNgFactory as KendoGridTimeFilterComponentNgFactory };
