import {
  Organization,
  Department,
  Position,
  ShiftDefinition,
  LocationUnit,
  BenefitClassDefinition
} from '../../../../organization/models/index';

export interface IEmployeeTransfer {
  date: Date;
  organizationId: number;
  departmentId: number;
  positionId: number;
  shiftId: number;
  unitId: number;
  payrollNumber: string;
  benefitClassId: number;
  benefitClassEffectiveDate: string;
  oldOrganizationId: number;
  employeeType: string;
}

export class EmployeeTransfer {
  public employeeId: number;
  public date: Date;
  public organization: Organization;
  public department: Department;
  public position: Position;
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public payrollNumber: string;
  public benefitClass: BenefitClassDefinition;
  public benefitClassEffectiveDate: Date = null;
  public oldOrganizationId: number ;
  public employeeType: string;
}
