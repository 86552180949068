import { Router, ActivatedRoute, UrlTree, NavigationExtras, Params } from '@angular/router';

export class PaycodesConfigurationNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public navigateToPaycodesConfiguration(orgLevelId: number): void {
    let params: Params = {
        orgLevelId: orgLevelId
    };
    this.router.navigate(['common', 'configure_paycodes'], { relativeTo: this.route.pathFromRoot[1], queryParams: params });
  }
}
