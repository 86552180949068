<i class="js-pointer pointer" aria-hidden="true"></i>
<slx-locker>
    <div class="favorites">
        <div class="favorites__search">
            <div>
                <p class="favorites__search-wrapper">
                    <input #searchField type="text" class="favorites__search-field" placeholder="Filter Favorites" [(ngModel)]="searchValue" (input)="filterFavorites(searchField.value)">
                    <i class="fa fa-search favorites__search-icon no-action" *ngIf="searchField.value.length === 0" aria-hidden="true"></i>
                    <i class="fas fa-times-circle favorites__search-icon" *ngIf="searchField.value.length > 0" title="Clear" aria-hidden="true" (click)="onClearSearch()"></i>
                </p>
            </div>
            <div class="right-div"><a class="config-icon" (click)="showConfigDialog()"><i class=" fas fa-cog" aria-hidden="true"></i></a></div>
        </div>
        <div class="favorites__body">
            <ng-container>
                <ul class="list">
                    <li *ngFor="let menu of favorites">
                        <a class="item-nav-link" (click)="open(menu)">
                            <span><i class="left-icon fas" [ngClass]="menu.menuItemMapping ? menu.menuItemMapping.icon : ''"
                                aria-hidden="true"></i></span>
                            <span class="item-title">{{menu.displayName}}</span>
                            <span class="right-div-star">
                                <i class="star-icon fas fa-star" aria-hidden="true"></i>
                            </span>
                        </a>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="favorites?.length == 0">
                <p class="favorites__no-results">No favorites found</p>
            </ng-container>
        </div>
    </div>
</slx-locker>