import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MessagesMappingService {

  constructor() { }

  public mapToSmsMessagesInfo(data: any) {
    _.forEach(data, (record) => {
      let modifiedDate = moment(record.createdAt).format('DD/MM/YYYY HH:MM');
      console.log('mdf', modifiedDate);
      record.createdAt = modifiedDate;
    });
    return data;
  }
}
