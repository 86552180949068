<div class="modal-content">
    <div class="modal-body">
      <div *ngIf="!hideDescription">
        <span class="enabling-the-pbj-mea">Enabling the PBJ Meal Deduction feature for a group of employees will automatically
          calculate the required Meal Deduction for each employee based on the length of their shift and any existing
          break calculations. The meal deductions will be listed on the “PBJ Manual Entries’ screen and the data in the
          PBJ export will be adjusted accordingly.</span>
      </div>
      <div class="h-divider"></div>
      <br>
        <div class="row">
            <div class="col-sm-8 pt-5">
                <slx-meal-deduction-new-configuration #configEditorComponent [hideDescription]="hideDescription"
                (onSaved)="closeAfterSave($event)" (isMealPropsChanged)="getIsMealChanged($event)"></slx-meal-deduction-new-configuration>
            </div>
            <div class="seperator"></div>
            <div class="col-sm-4">
                <slx-pbj-export-locations #pbjLocationExportComponent></slx-pbj-export-locations>
            </div>
        </div>
      </div>
    <div class="row m-top">
      <div class="col-sm-6">
      <button type="button" class="btn btn-danger bg-red wh-align pull-left" (click)="onCancel()">Discard</button></div>
      <div class="col-sm-6">
      <button type="button" class="btn btn-success bg-green wh-align pull-right" [disabled]="this.configEditorComponent.form.invalid || !modified" (click)="onSave()">Save</button></div>
    </div>
  </div>
