import * as moment from 'moment';
import { appConfig } from '../../../app.config';
import { ArrivalsDeparturesAnalytics } from './arrivals-departures-analytics';

export interface IDeparture {
  departureTime: string;
  shiftId: number;
  scheduled: number;
  out: number;
  difference: number;
  overtime: number;
}

export class Departure {
  public departureTime: Date;
  public shiftId: number;
  public scheduled: number;
  public out: number;
  public difference: number;
  public overtime: number;
  public get departureTimeTitle(): string {
    if(this.departureTime)
      return `${moment(this.departureTime).format(appConfig.timeFormat)}`;
    return  ArrivalsDeparturesAnalytics.unscheduled;
  }
}
