<div class="weekly">
  <p class="flex align-items__center weekly__section">
    <label class="weekly__label">Repeat every</label>
    <slx-number
      class="weekly__number"
      [min]="min"
      [step]="step"
      [decimals]="decimals"
      [autoCorrect]='true'
      [readonly]="!canEditRequest"
      [(ngModel)]="interval"
      (ngModelChange)="onChangeRecurrence()"
    ></slx-number>
    <span class="weekly__text">month(s)</span>
  </p>

  <p class="flex weekly__section">
    <label class="weekly__label">Repeat on</label>
    <span class="flex__grow">
      <span class="flex align-items__center weekly__line">
        <slx-radio-button class="weekly__radio"
          caption="Day"
          [option]="repeatOnDay"
          [readonly]="!canEditRequest"
          [(ngModel)]="repeatMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-number class="weekly__number"
          [min]="min"
          [max]="max"
          [step]="step"
          [decimals]="decimals"
          [autoCorrect]='true'
          [readonly]="!canEditRequest || monthDayIsReadonly"
          [(ngModel)]="monthDay"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-number>
      </span>
      <span class="flex">
        <slx-radio-button class="weekly__radio"
          caption=" "
          [option]="repeatOnCustom"
          [readonly]="!canEditRequest"
          [(ngModel)]="repeatMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-dropdown-list
          class="weekly__list slx-wide slx-short"
          valueField="id"
          titleField="text"
          [options]="offsetPositions"
          [readonly]="!canEditRequest || weekDayIsReadonly"
          [(ngModel)]="offsetPosition"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-dropdown-list>
        <slx-dropdown-list
          class="weekly__list slx-wide slx-short"
          valueField="id"
          titleField="text"
          [options]="weekDays"
          [readonly]="!canEditRequest || weekDayIsReadonly"
          [(ngModel)]="weekDay"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-dropdown-list>
      </span>
    </span>
  </p>

  <p class="flex weekly__line">
    <label class="weekly__label">End</label>
    <span class="flex__grow">
      <span class="flex align-items__center weekly__line height-28">
        <slx-radio-button class="weekly__radio"
          caption="Never"
          [option]="endNever"
          [readonly]="!canEditRequest"
          [(ngModel)]="endMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
      </span>
      <span class="flex align-items__center weekly__line">
        <slx-radio-button class="weekly__radio"
          caption="After"
          [option]="endAfterOccurrence"
          [readonly]="!canEditRequest"
          [(ngModel)]="endMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-number class="weekly__number"
          [min]="min"
          [step]="step"
          [decimals]="decimals"
          [autoCorrect]='true'
          [readonly]="!canEditRequest || countIsReadonly"
          [(ngModel)]="count"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-number>
        <span class="weekly__text">occurrence(s)</span>
      </span>
      <span class="flex align-items__center weekly__line">
        <slx-radio-button class="weekly__radio"
          caption="On"
          [option]="endOnDate"
          [readonly]="!canEditRequest"
          [(ngModel)]="endMode"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-radio-button>
        <slx-datepicker class="weekly__date"
          [minDate]="calculatedMinDate || limitMinDate"
          [readonly]="!canEditRequest || untilIsReadonly"
          [(ngModel)]="until"
          (ngModelChange)="onChangeRecurrence()"
        ></slx-datepicker>
      </span>
    </span>
  </p>
</div>
