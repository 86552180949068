import * as tslib_1 from "tslib";
import { EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { appConfig } from '../../../app.config';
import { LookupApiService } from '../../../organization/services/index';
import { PayCycle } from '../../../organization/models/index';
var PayCycleDropdownSelectorComponent = /** @class */ (function () {
    function PayCycleDropdownSelectorComponent(lookupApiService) {
        var _this = this;
        this.lookupApiService = lookupApiService;
        this.payCycleSelected = new EventEmitter();
        this.payCyclesLoaded = new EventEmitter();
        this.state = {
            isLoading: false
        };
        this.yearPayCycles = {};
        this.appConfig = appConfig;
        this.orgLevelSubscription = this.orgLevel$
            .filter(function (o) { return !_this.selectedOrgLevel || o && _this.selectedOrgLevel.id !== o.id; })
            .subscribe(function (o) {
            if (!o || !o.id) {
                return;
            }
            _this.selectedOrgLevel = o;
            if (_this.selectedOrgLevel) {
                _this.loadPayCycles(_this.selectedOrgLevel.id, _this.employeeId);
            }
        });
    }
    PayCycleDropdownSelectorComponent.prototype.ngOnDestroy = function () {
        // #issueWithAOTCompiler
    };
    PayCycleDropdownSelectorComponent.prototype.ngOnChanges = function (changes) {
        if (changes['employeeId']) {
            this.loadPayCycles(this.selectedOrgLevel.id, this.employeeId);
        }
    };
    PayCycleDropdownSelectorComponent.prototype.SelectPayCycle = function (itemToSelect, riseEvent) {
        if (this.payCycles && this.payCycles.length > 0) {
            if (itemToSelect) {
                this.selectedPayCycle = _.find(this.payCycles, function (item) {
                    return moment(item.startDate).isSame(moment(itemToSelect.startDate)) && moment(item.endDate).isSame(moment(itemToSelect.endDate));
                });
            }
            if (!this.selectedPayCycle) {
                this.selectedPayCycle = this.payCycles[0];
            }
            if (this.selectedPayCycle) {
                this.selectedYear = moment(this.selectedPayCycle.startDate).year().toString();
            }
            else {
                this.selectedYear = '';
            }
            if (riseEvent) {
                this.onPayCycleSelect(this.selectedPayCycle);
            }
            return;
        }
        this.selectedPayCycle = itemToSelect;
        this.selectedYear = '';
    };
    PayCycleDropdownSelectorComponent.prototype.loadPayCycles = function (orgLevelId, employeeId) {
        var _this = this;
        this.lookupApiService.getPayCyles(orgLevelId, employeeId)
            .then(function (cycles) {
            _this.payCycles = _.reverse(_.sortBy(cycles, function (item) { return moment(item.startDate).unix(); }));
            _this.yearPayCycles = _.groupBy(_this.payCycles, function (cycle) {
                return moment(cycle.startDate).year();
            });
            _this.years = _.reverse(_.keys(_this.yearPayCycles));
            if (_this.payCycles.length === 0) {
                _this.selectedPayCycle = null;
            }
            _this.SelectPayCycle(_this.selectedPayCycle, true);
            _this.payCyclesLoaded.emit(cycles);
        });
    };
    PayCycleDropdownSelectorComponent.prototype.onSelectYear = function (year) {
        this.selectedYear = year;
        this.selectedPayCycle = null;
    };
    PayCycleDropdownSelectorComponent.prototype.onPayCycleSelect = function (item) {
        this.selectedPayCycle = item;
        this.payCycleSelected.emit(this.selectedPayCycle);
    };
    PayCycleDropdownSelectorComponent.prototype.isSelected = function (item) {
        var selectedStartDate = _.get(this.selectedPayCycle, 'startDate', null);
        var selectedEndDate = _.get(this.selectedPayCycle, 'endDate', null);
        var itemStartDate = _.get(item, 'startDate', null);
        var itemEndDate = _.get(item, 'endDate', null);
        if (_.isNull(selectedStartDate) ||
            _.isNull(selectedEndDate) ||
            _.isNull(itemStartDate) ||
            _.isNull(itemEndDate)) {
            return false;
        }
        return moment(itemStartDate).isSame(selectedStartDate) && moment(itemEndDate).isSame(selectedEndDate);
    };
    PayCycleDropdownSelectorComponent.prototype.selectPayCycleByDate = function (date, riseEvent) {
        if (this.payCycles && this.payCycles.length > 0) {
            if (date) {
                this.selectedPayCycle = _.find(this.payCycles, function (item) {
                    return moment(item.startDate).isSameOrBefore(moment(date)) && moment(item.endDate).isSameOrAfter(moment(date));
                });
            }
            if (!this.selectedPayCycle) {
                this.selectedPayCycle = this.payCycles[0];
            }
            if (this.selectedPayCycle) {
                this.selectedYear = moment(this.selectedPayCycle.startDate).year().toString();
            }
            else {
                this.selectedYear = '';
            }
            if (riseEvent) {
                this.onPayCycleSelect(this.selectedPayCycle);
            }
            return;
        }
        this.selectedYear = '';
    };
    PayCycleDropdownSelectorComponent.prototype.selectPayCycleByDates = function (startDate, endDate, riseEvent) {
        if (this.payCycles && this.payCycles.length > 0) {
            if (startDate && endDate) {
                this.selectedPayCycle = _.find(this.payCycles, function (item) {
                    return moment(item.startDate).isSame(moment(startDate)) && moment(item.endDate).isSame(moment(endDate));
                });
            }
            if (!this.selectedPayCycle) {
                this.selectedPayCycle = this.payCycles[0];
            }
            if (this.selectedPayCycle) {
                this.selectedYear = moment(this.selectedPayCycle.startDate).year().toString();
            }
            else {
                this.selectedYear = '';
            }
            if (riseEvent) {
                this.onPayCycleSelect(this.selectedPayCycle);
            }
            return;
        }
        this.selectedYear = '';
    };
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], PayCycleDropdownSelectorComponent.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PayCycleDropdownSelectorComponent.prototype, "orgLevelSubscription", void 0);
    return PayCycleDropdownSelectorComponent;
}());
export { PayCycleDropdownSelectorComponent };
