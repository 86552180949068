import { Injectable } from '@angular/core';
import { Meta } from '../../../core/models/index';
import { AssignEmpToEmptySlot, AvailShifts, EmpScheduleEntries, EmployeeScheduledData, FilterData, FilterRotationOptions, FutureValidationShiftResult, IAvailShifts, IDays, IFilterData, IFilterRotationOptions, IPositionOptions, IScheduleDays, IScheduleRotationTemplate, ISecondaryJobCodes, ISecondaryPositionData, PositionOptions, ScheduleDays, ScheduleRotationTemplate, SecondaryJobCodes, SecondaryPositionData } from '../../models/schedule-rotation/schedule-rotation-template.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Injectable()
export class ScheduledRotationMapService {

    public weekSet: any = [];
    public weeklyCount: number;
    public weeklyCount$ = new Subject<number>();
    public addAction: string = 'Add Action';
    public deleteAction: string = 'Remove Action';
    public employeeScheduledEntries: any[] = [];

    constructor() { }

    public mapEmployeeRotation(dto: IScheduleRotationTemplate[], meta: Meta): ScheduleRotationTemplate[] {
        return _.map(dto, (item) => this.mapEmployeeList(item));
    }

    public mapEmployeeList(item: IScheduleRotationTemplate): ScheduleRotationTemplate {
        const data: ScheduleRotationTemplate = new ScheduleRotationTemplate();
        data.employeeId = item.employeeId;
        data.name = item.name;
        data.jobCode = item.jobCode;
        data.secondaryJobCodes = item.secondaryJobCodes !== '' ? item.secondaryJobCodes.split(',').map(Number) : [];
        data.positionName = item.positionName ? item.positionName.trim() : null;
        data.shiftGroupName = item.shiftGroupName;
        data.shiftId = item.shiftId;
        data.shiftName = item.shiftName ? item.shiftName.trim() : null;
        data.positionGroupId = item.positionGroupId;
        data.positionGroupName = item.positionGroupName;
        data.activationDate = new Date(item.activationDate);
        data.terminationDate = item.terminationDate === null ? new Date('2079-06-06T00:00:00') : new Date(item.terminationDate);
        data.otHours = item.otHours;
        data.rotationCount = item.rotationCount;
        data.scheduleDays = this.mapScheduleDays(item.scheduleDays);
        data.slotId = item.slotId ? item.slotId : 0;
        data.isPrimary = item.isPrimary;
        data.isDifferentDepartment = item.isDifferentDepartment;
        // Added below properties for UI Purpose
        data.state = { isLock: false, isUnlock: false, isPurple: false, isGreen: false };
        data.isNewRotation = item.isNewRotation ? true : false;
        data.isNewlyAssigned = false;
        data.isEmptySlot = item.employeeId === 0 ? true : false;
        data.seqId = item.seqId ? item.seqId : 0;
        data.hasRotations = item.hasRotations;
        data.isAgencyEmployee = item.isAgencyEmployee;
        data.employeeType = item.employeeType;
        data.isFullTime = item.employeeId == 0 ? null : item.isFullTime;
        data.onlySecondaryJobCodes = _.map(item.onlySecondaryJobCodes, (item) => this.mapSecondaryJobCodes(item));
        data.maskedJobCode = item.maskedJobCode;
        data.positionGroupedJobCodes = item.positionGroupedJobCodes !== '' ? item.positionGroupedJobCodes.split(',').map(Number) : [];
        data.otherDepartmentSecondaryJobCodes = this.extractOtherDeptSecondaryJobCode(item);
        // Added above properties for UI Purpose
        return data;
    }

    public extractOtherDeptSecondaryJobCode(dto: IScheduleRotationTemplate): SecondaryJobCodes[] {
        let data: IAvailShifts[] = [];
        dto.scheduleDays.map(x => x.shifts.map(y => {
            if (!y.isWhiteColor && y.isLock) {
                data.push(y);
            }
        }));
        let result = _.map(data, (item: IAvailShifts, i) => this.mapAvailShifts(item, i));
        return _.map(result, (item: AvailShifts) => this.mapOtherDepartmentSecondaryJobCodes(item));
    }

    public mapOtherDepartmentSecondaryJobCodes(dto: AvailShifts): SecondaryJobCodes {
        const data: SecondaryJobCodes = new SecondaryJobCodes();
        data.jobCode = dto.jobCode;
        data.jobName = dto.positionName;
        data.positionGroupId = +dto.positionGroupId;
        data.positionGroupName = dto.positionGroupName;
        return data;
    }

    public mapSecondaryJobCodes(dto: ISecondaryJobCodes): SecondaryJobCodes {
        const data: SecondaryJobCodes = new SecondaryJobCodes();
        data.jobCode = dto.jobCode;
        data.jobName = dto.jobName.trim();
        data.positionGroupId = dto.positionGroupId;
        data.positionGroupName = dto.positionGroupName;
        return data;
    }

    public mapScheduleDays(dto: IScheduleDays[]): ScheduleDays[] {
        return _.map(dto, (item) => this.mapScheduleDay(item));
    }

    public mapScheduleDay(item: IScheduleDays): ScheduleDays {
        item.shifts.map(x => {
            x.count = x.count ? x.count : 1;
            x.id = this.randomNumber();
            x.isAssignedShift = true;
        });
        const data: ScheduleDays = new ScheduleDays();
        data.day = item.day;
        data.shifts = _.map(item.shifts, (item, i) => this.mapAvailShifts(item, i));
        return data;
    }

    public mapScheduleRotationAvailShifts(dto: IAvailShifts[], meta: Meta): AvailShifts[] {
        return _.map(dto, (item, i) => this.mapAvailShifts(item, i));
    }

    public mapAvailShifts(dto: IAvailShifts, index: number): AvailShifts {
        const data: AvailShifts = new AvailShifts();
        data.id = dto.id ? dto.id : index + 1;
        data.calendardate = dto.calendardate;
        data.jobCode = dto.jobCode;
        data.shiftGroupId = dto.shiftGroupId;
        data.shiftGroupDescription = dto.shiftGroupDescription ? dto.shiftGroupDescription.trim() : null;
        data.shiftId = dto.shiftId;
        data.unitId = dto.unitId === -1 ? 100001 : dto.unitId;
        data.hours = dto.hours;
        data.count = Math.round(dto.count);
        data.weekDay = dto.weekDay;
        data.scheduleRotationCount = dto.scheduleRotationCount ? dto.scheduleRotationCount : 1;
        data.orgLevelId = dto.orgLevelId;
        data.shiftName = dto.shiftName ? dto.shiftName.trim() : null;
        data.positionName = dto.positionName ? dto.positionName.trim() : null;
        data.unitName = dto.unitName ? dto.unitName.trim() : null;
        data.positionGroupId = dto.positionGroupId ? dto.positionGroupId : null;
        data.startTime = dto.startTime;
        data.endTime = dto.endTime;
        data.positionGroupName = dto.positionGroupName ? dto.positionGroupName.trim() : null;
        data.slotId = dto.slotId ? dto.slotId : null;
        data.isWhiteColor = this.setColor(dto.isLock, dto.isWhiteColor);
        data.isLock = dto.isLock;
        data.isPrimary = dto.isPrimary;
        data.dayNo = dto.dayNo ? dto.dayNo : 0;
        data.recordId = dto.recordId ? dto.recordId : 0;
        data.frequency = dto.frequency ? dto.frequency : 1;
        // Added below properties for UI Purpose
        data.calendarFormatDate = moment(dto.calendardate).format('MM/DD/YYYY');
        data.configType = dto.configType;
        data.isView = true;
        data.state = { isLock: false, isPurple: false, isGreen: false };
        data.scheduleDate = null;
        data.deleteInd = false;
        data.isAssignedShift = dto.isAssignedShift ? true : false;
        data.isPositionGroupedShift = dto.isPositionGroupedShift;
        data.isJobCodeHidden = dto.isJobCodeHidden ? true : false;
        // Added above properties for UI Purpose
        return data;
    }

    public mapScheduleRotationAvailShiftsData(shifts: IAvailShifts[], dates: IDays[]): IScheduleDays[] {
        let scheduleShifts: IScheduleDays[] = [];
        _.forEach(dates, (date) => {
            const dayShift: IScheduleDays = {
                day: date,
                shifts: _.filter(shifts, (x) => x.calendarFormatDate === date.formatDate),
                isOverLap: false
            };
            scheduleShifts.push(dayShift);
        });
        this.weeklyTotalShiftsCount(scheduleShifts);
        return scheduleShifts;
    }

    public uniqueId(data: any[]) {
        _.forEach(data, (i, index) => {
            i.id = index + 1;
        });
    }

    public randomNumber(): number {
        const randomId = Math.floor(Math.random() * (9999 - 999 + 1) + 999);
        return randomId;
    }

    public weeklyTotalShiftsCount(scheduledShifts): number {
        this.weeklyCount = 0;
        _.map(scheduledShifts, (x) => {
            this.weeklyCount += this.totalShiftCount(x.shifts);
        });
        this.weeklyCount$.next(this.weeklyCount);
        return this.weeklyCount;
    }

    public totalShiftCount(data): number {
        let counts: number = 0;
        _.map(data, (shifts) => {
            return counts += shifts.count;
        });
        return counts;
    }

    public employeeScheduleEntriesCheck(data) {
        let obj = { employee: data[0], shift: data[1], calenderDate: data[2], shiftList: data[3], action: data[4] };
        obj.shift.slotId = obj.shift.slotId ? obj.shift.slotId : obj.employee.slotId;
        obj.shift.isEmptySlot = obj.employee.isEmptySlot;
        obj.shift.seqId = obj.employee.seqId;
        if (obj.action === this.addAction) {
            if (this.employeeScheduledEntries.length > 0) {
                let empScheduleIndex = this.employeeScheduledEntries.findIndex(x => x.employeeId === obj.employee.employeeId);
                this.updateAddEntry(empScheduleIndex, obj);
            }
            else {
                this.addEntry(obj);
            }
        }
        if (obj.action === this.deleteAction) {
            if (this.employeeScheduledEntries.length > 0) {
                let empScheduleIndex = this.employeeScheduledEntries.findIndex(x => x.employeeId === obj.employee.employeeId);
                this.updateDeleteEntry(empScheduleIndex, obj);
            }
            else {
                this.deletedEntry(obj);
            }
        }
        return this.mapEmpSchedules(this.employeeScheduledEntries);
    }

    public addEntry(obj) {
        // if(obj.employee.employeeId != 0) {
        let empShiftDataIndex = obj.employee.scheduleDays.findIndex(x => x.day.formatDate === obj.shift.calendarFormatDate);
        let data: any = [];
        if (obj.employee.scheduleDays[empShiftDataIndex].shifts.length > 0) {
            data = this.employeeScheduledEntries.push({
                employeeId: obj.employee.employeeId,
                seqId: obj.employee.seqId,
                isNewRotation: obj.employee.isNewRotation,
                isNewlyAssigned: obj.employee.isNewlyAssigned,
                isEmptySlot: obj.employee.isEmptySlot,
                deletedEntries: [],
                addedEntries: [obj.shift],
                modifiedEntries: [],
                homeJobCode: obj.employee.jobCode,
                secondaryJobCodes: obj.employee.secondaryJobCodes,
                onlySecondaryJobCodes: obj.employee.onlySecondaryJobCodes
            });
        }
        else {
            data = this.employeeScheduledEntries.push({
                employeeId: obj.employee.employeeId,
                seqId: obj.employee.seqId,
                isNewRotation: obj.employee.isNewRotation,
                isNewlyAssigned: obj.employee.isNewlyAssigned,
                isEmptySlot: obj.employee.isEmptySlot,
                deletedEntries: [],
                addedEntries: [obj.shift],
                modifiedEntries: [],
                homeJobCode: obj.employee.jobCode,
                secondaryJobCodes: obj.employee.secondaryJobCodes,
                onlySecondaryJobCodes: obj.employee.onlySecondaryJobCodes
            });
        }
        return data;
        // }
    }

    public deletedEntry(obj) {
        let empShiftDataIndex = obj.employee.scheduleDays.findIndex(x => x.day.formatDate === obj.shift.calendarFormatDate);
        let filteredShiftData = obj.employee.scheduleDays[empShiftDataIndex].shifts.filter(x => x.id != obj.shift.id)
        let data: any = [];
        if (obj.employee.scheduleDays[empShiftDataIndex].shifts.length > 0) {
            data = this.employeeScheduledEntries.push({
                employeeId: obj.employee.employeeId,
                seqId: obj.employee.seqId,
                isNewRotation: obj.employee.isNewRotation,
                isNewlyAssigned: obj.employee.isNewlyAssigned,
                isEmptySlot: obj.employee.isEmptySlot,
                deletedEntries: [obj.shift],
                addedEntries: [],
                modifiedEntries: [],
                homeJobCode: obj.employee.jobCode,
                secondaryJobCodes: obj.employee.secondaryJobCodes,
                onlySecondaryJobCodes: obj.employee.onlySecondaryJobCodes
            });
        }
        else {
            data = this.employeeScheduledEntries.push({
                employeeId: obj.employee.employeeId,
                seqId: obj.employee.seqId,
                isNewRotation: obj.employee.isNewRotation,
                isNewlyAssigned: obj.employee.isNewlyAssigned,
                isEmptySlot: obj.employee.isEmptySlot,
                deletedEntries: [obj.shift],
                addedEntries: [],
                modifiedEntries: [],
                homeJobCode: obj.employee.jobCode,
                secondaryJobCodes: obj.employee.secondaryJobCodes,
                onlySecondaryJobCodes: obj.employee.onlySecondaryJobCodes
            });
        }
        return data;
    }

    public updateAddEntry(index, obj) {
        // if(obj.employee.employeeId != 0) {
        if (index >= 0) {
            if (this.employeeScheduledEntries[index].deletedEntries.length > 0) {
                let empShiftIndex = this.employeeScheduledEntries[index].deletedEntries.findIndex(x => x.id === obj.shift.id && x.shiftId === obj.shift.shiftId && x.unitId === obj.shift.unitId)
                if (empShiftIndex >= 0) {
                    this.employeeScheduledEntries[index].deletedEntries.splice(empShiftIndex, 1);
                    this.employeeScheduledEntries[index].addedEntries.push(obj.shift);
                }
                else {
                    this.employeeScheduledEntries[index].addedEntries.push(obj.shift);
                }
            }
            else {
                this.employeeScheduledEntries[index].addedEntries.push(obj.shift);
            }
        }
        else {
            this.addEntry(obj);
        }
        // }
    }

    public updateDeleteEntry(index, obj) {
        if (index >= 0) {
            if (this.employeeScheduledEntries[index].addedEntries.length > 0) {
                let empShiftIndex = this.employeeScheduledEntries[index].addedEntries.findIndex(x => x.id === obj.shift.id && x.shiftId === obj.shift.shiftId && x.unitId === obj.shift.unitId)
                if (empShiftIndex >= 0) {
                    this.employeeScheduledEntries[index].addedEntries.splice(empShiftIndex, 1);
                    this.employeeScheduledEntries[index].deletedEntries.push(obj.shift);
                }
                else {
                    this.employeeScheduledEntries[index].deletedEntries.push(obj.shift);
                }
            }
            else {
                this.employeeScheduledEntries[index].deletedEntries.push(obj.shift);
            }
        }
        else {
            this.deletedEntry(obj);
        }
    }

    public mapEmpSchedules(data: EmpScheduleEntries[]) {
        let outPutData = [];
        _.forEach(data, (x) => {
            // if(!x.isNewRotation) {
            x.addedEntries.forEach((y) => {
                let dto: EmployeeScheduledData = <EmployeeScheduledData>{};
                dto.employeeId = x.employeeId;
                dto.jobCode = this.isJobCodePresent(y.jobCode, x) ? y.jobCode : x.homeJobCode;
                dto.unitId = y.unitId === 100001 ? -1 : y.unitId;
                dto.shiftId = y.shiftId;
                dto.scheduledDate = moment(y.calendarFormatDate).format('MM/DD/YYYY');
                dto.deleteInd = false;
                dto.frequency = y.frequency;
                dto.dayNo = y.dayNo ? y.dayNo : 0;
                dto.isNewlyAssigned = x.isNewRotation || (!y.isAssignedShift && x.employeeId === 0) ? true : false;
                dto.slotId = y.slotId;
                dto.isEmptySlot = x.isEmptySlot;
                dto.seqId = y.seqId;
                dto.recordId = y.recordId;
                dto.isAlreadyAssignedShift = y.isAssignedShift;
                dto.maskedJobCode = !this.isJobCodePresent(y.jobCode, x) ? y.jobCode : null;
                outPutData.push(dto);
            });
            x.deletedEntries.forEach((z) => {
                if (z.isAssignedShift) {
                    let dto: EmployeeScheduledData = <EmployeeScheduledData>{};
                    dto.employeeId = x.employeeId;
                    dto.jobCode = this.isJobCodePresent(z.jobCode, x) ? z.jobCode : x.homeJobCode;
                    dto.unitId = z.unitId === 100001 ? -1 : z.unitId;
                    dto.shiftId = z.shiftId;
                    dto.scheduledDate = moment(z.calendarFormatDate).format('MM/DD/YYYY');
                    dto.deleteInd = true;
                    dto.frequency = z.frequency;
                    dto.dayNo = z.dayNo ? z.dayNo : 0;
                    dto.isNewlyAssigned = x.isNewRotation || (z.isAssignedShift && x.employeeId === 0) ? true : false;
                    dto.slotId = z.slotId;
                    dto.isEmptySlot = x.isEmptySlot;
                    dto.seqId = z.seqId;
                    dto.recordId = z.recordId;
                    dto.isAlreadyAssignedShift = z.isAssignedShift;
                    dto.maskedJobCode = !this.isJobCodePresent(z.jobCode, x) ? z.jobCode : null;
                    outPutData.push(dto);
                }
            });
            // }
        });
        return outPutData;
    }

    public AssignEmployeeToEmptySlot(data) {
        let result = [];
        _.forEach(data, (item) => {
            let assignedEmployee: AssignEmpToEmptySlot = new AssignEmpToEmptySlot();
            assignedEmployee.employeeId = item.employeeId;
            assignedEmployee.slotId = item.slotId;
            result.push(assignedEmployee);
        });
        return result;
    }

    public setColor(isLock: boolean, isWhiteColor: boolean): boolean {
        if (isLock) return false;
        if (!isLock && isWhiteColor) return true;
        if (!isLock && !isWhiteColor) return false;
    }

    public mapFutureDateValidationResult(dto: FutureValidationShiftResult[]) {
        return _.map(dto, (item) => this.mapFutureDateValidationOutput(item));
    }

    public mapFutureDateValidationOutput(dto: FutureValidationShiftResult) {
        let data = new FutureValidationShiftResult();
        data.id = dto.id;
        data.dayNo = dto.dayNo;
        data.errorMessage = dto.errorMessage;
        data.overLappingDate = dto.overLappingDate;
        return data;
    }

    public isJobCodePresent(jobCode: number, employee: EmpScheduleEntries): boolean {
        let secondaryJobCodeIds = employee.onlySecondaryJobCodes ? employee.onlySecondaryJobCodes.map(x => x.jobCode) : [];
        return (employee.homeJobCode == jobCode) || secondaryJobCodeIds.includes(jobCode);
    }

    public mapFilterRecordOptions(dto: IFilterRotationOptions): FilterRotationOptions {
        const data: FilterRotationOptions = new FilterRotationOptions();
        data.showStaff = _.map(dto.showStaff, (item: IFilterData) => this.mapFilterData(item));
        data.viewWeeks = _.map(dto.viewWeeks, (item: IFilterData) => this.mapFilterData(item));
        data.positionOptions = this.mapPositionOptions(dto.positionOptions);
        data.gridFilterOption = this.mapFilterData(dto.gridFilterOption);
        return data;
    }

    public mapFilterData(dto: IFilterData): FilterData {
        const data: FilterData = new FilterData();
        data.id = dto.id;
        data.isSelected = dto.isSelected;
        data.name = dto.name;
        return data;
    }

    public mapPositionOptions(dto: IPositionOptions): PositionOptions {
        const data: PositionOptions = new PositionOptions();
        data.primaryPosition = this.mapFilterData(dto.primaryPosition);
        data.secondaryPosition = this.mapSecondaryPosition(dto.secondaryPosition);
        data.emptyRotation = this.mapFilterData(dto.emptyRotation);
        data.agencyStaff = this.mapFilterData(dto.agencyStaff);
        return data;
    }

    public mapSecondaryPosition(dto: ISecondaryPositionData): SecondaryPositionData {
        const data: SecondaryPositionData = new SecondaryPositionData();
        data.id = dto.id;
        data.name = dto.name;
        data.isSelectAll = dto.isSelectAll;
        data.options = _.map(dto.options, (item: IFilterData) => this.mapFilterData(item));
        return data;
    }

}
