import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { ResponseBody } from '../../../../core/models/index';
import { employeeConfig } from '../../employee.config';
import { EmployeeActivitiesMapService } from './employee-add-map.service';
import { UrlParamsService, ApiUtilService } from '../../../../common/services/index';

import { Meta } from '../../../../core/models/api/meta';
import { Assert } from '../../../../framework/index';
import {
  AddEmployeeModel,
  IAddEmployeeModel,
  AddEmployeeRestrictions,
  ISectionAddEmployeeRestrictions,
} from '../../models/index';

@Injectable()
export class EmployeeActivitiesApiService {
  constructor(
    private employeeActivitiesMapService: EmployeeActivitiesMapService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService) {
  }

  public addNewEmployee(model: AddEmployeeModel): Promise<number> {
    Assert.isNotNull(model, 'model');
    const url: string = this.getApiRoot();
    let modelDTO: IAddEmployeeModel = this.employeeActivitiesMapService.mapToAddEmployeeModelDTO(model);
    let body: any = modelDTO;

    let request: HttpRequest<any> = new HttpRequest('POST', url, body);

    let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return {payrollNumber: response.data.payrollNumber || '', id: response.data.id}
      });
    return promise;
  }
  public getAddEmployeeProperties(orgLevelId?: number): Promise<AddEmployeeRestrictions> {
    const url: string = `${this.getApiRoot()}/${employeeConfig.api.employees.properties}`;

    let params: HttpParams = this.urlParamsService.convertToParams({ orgLevelId: orgLevelId });
    let request: HttpRequest<any> = new HttpRequest('GET', url, {
      params: params
    });

    let promise: Promise<AddEmployeeRestrictions> = this.apiUtilService.request<ISectionAddEmployeeRestrictions, Meta>(request)
      .then((response: ResponseBody<ISectionAddEmployeeRestrictions, Meta>) =>
        this.employeeActivitiesMapService.mapAddEmployeeRestrictions(response.data)
      );
    return promise;
  }

  private getApiActions(): string {
    return `${this.getApiRoot()}/${employeeConfig.api.employees.actions.root}`;
  }
  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}/${employeeConfig.api.employees.root}`;
  }
}
