/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lm-calendar-grid-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./lm-calendar-grid-header.component";
import * as i4 from "../../../services/lm-management.service";
import * as i5 from "../../../services/lm-roster/lm-roster-management.service";
var styles_LMCalendarGridHeaderComponent = [i0.styles];
var RenderType_LMCalendarGridHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LMCalendarGridHeaderComponent, data: {} });
export { RenderType_LMCalendarGridHeaderComponent as RenderType_LMCalendarGridHeaderComponent };
function View_LMCalendarGridHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "day__nav-button left"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavButtonClick(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-angle-left"]], null, null, null, null, null))], null, null); }
function View_LMCalendarGridHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "day__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit, _co.dateFormat)); _ck(_v, 1, 0, currVal_0); }); }
function View_LMCalendarGridHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "day__content header-full-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit, _co.dateFormatShort)); _ck(_v, 1, 0, currVal_0); }); }
function View_LMCalendarGridHeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "day__content header-short-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit, _co.dateFormatForSmallDevice)); _ck(_v, 1, 0, currVal_0); }); }
function View_LMCalendarGridHeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "day__nav-button right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNavButtonClick(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-angle-right"]], null, null, null, null, null))], null, null); }
function View_LMCalendarGridHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "span", [["class", "day"]], [[8, "title", 0]], null, null, null, null)), i1.ɵppd(1, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMCalendarGridHeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMCalendarGridHeaderComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMCalendarGridHeaderComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMCalendarGridHeaderComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMCalendarGridHeaderComponent_6)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.showNavButtons && _v.context.first); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.isDayView; _ck(_v, 5, 0, currVal_2); var currVal_3 = !_co.isDayView; _ck(_v, 7, 0, currVal_3); var currVal_4 = !_co.isDayView; _ck(_v, 9, 0, currVal_4); var currVal_5 = (_co.showNavButtons && _v.context.last); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit, _co.dateFormat)), ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_LMCalendarGridHeaderComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LMCalendarGridHeaderComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.days; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LMCalendarGridHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-lm-calendar-grid-header", [], null, null, null, View_LMCalendarGridHeaderComponent_0, RenderType_LMCalendarGridHeaderComponent)), i1.ɵdid(1, 770048, null, 0, i3.LMCalendarGridHeaderComponent, [i4.LMManagementService, i5.LMRosterManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LMCalendarGridHeaderComponentNgFactory = i1.ɵccf("slx-lm-calendar-grid-header", i3.LMCalendarGridHeaderComponent, View_LMCalendarGridHeaderComponent_Host_0, { dateRange: "dateRange", daysLength: "daysLength", showNavButtons: "showNavButtons" }, {}, []);
export { LMCalendarGridHeaderComponentNgFactory as LMCalendarGridHeaderComponentNgFactory };
