import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ModalService, ConfirmOptions, ConfirmDialog2Component } from '../../../../../common/index';
import { BenefitDetailsTier, BenefitDetailsEditModes } from '../../../models/index';
import { BenefitDetailsPermissionService, BenefitDetailsStandartManagementService } from '../../../services/index';
var BenefitDetailsBenefitInfoMenuComponent = /** @class */ (function () {
    function BenefitDetailsBenefitInfoMenuComponent(modalService, permissionService, manService) {
        this.modalService = modalService;
        this.permissionService = permissionService;
        this.manService = manService;
        this.isEditMode = false;
        this.subscriptions = {};
    }
    BenefitDetailsBenefitInfoMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.selectProviderLine = this.manService
            .subscribeToProviderTiers(function (tiers) { return (_this.tiers = _.filter(tiers, function (t) { return !t.stateIsDeleted; })); });
        this.subscriptions.providerTier = this.manService
            .subscribeToSelectProviderTier(function (tier) { return (_this.selectedProviderTier = tier); });
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) { return (_this.isEditMode = v.providerInfo); });
    };
    BenefitDetailsBenefitInfoMenuComponent.prototype.ngOnDestroy = function () {
    };
    Object.defineProperty(BenefitDetailsBenefitInfoMenuComponent.prototype, "hasSelectedTier", {
        get: function () {
            return _.isObjectLike(this.selectedProviderTier);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsBenefitInfoMenuComponent.prototype, "canAdd", {
        get: function () {
            return this.isEditMode && this.hasSelectedTier;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsBenefitInfoMenuComponent.prototype, "canDelete", {
        get: function () {
            return this.isEditMode && this.hasSelectedTier && _.size(this.tiers) > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BenefitDetailsBenefitInfoMenuComponent.prototype, "confirmationPopupTitle", {
        get: function () {
            var title = "Are you sure you want to remove selected benefit tier?";
            if (this.hasSelectedTier && !this.selectedProviderTier.isDraft) {
                title += " This will remove the plan from the employees' enrollment history";
            }
            return title;
        },
        enumerable: true,
        configurable: true
    });
    BenefitDetailsBenefitInfoMenuComponent.prototype.isActiveMenu = function (tier) {
        return _.isObjectLike(tier) && this.hasSelectedTier && tier.id === this.selectedProviderTier.id;
    };
    BenefitDetailsBenefitInfoMenuComponent.prototype.onClickTier = function (tier) {
        if (this.isActiveMenu(tier))
            return;
        this.selectedProviderTier = tier;
        this.manService.selectProviderTier(tier);
    };
    BenefitDetailsBenefitInfoMenuComponent.prototype.onAddNew = function () {
        if (this.canAdd) {
            this.manService.addProviderTier();
        }
    };
    BenefitDetailsBenefitInfoMenuComponent.prototype.onDelete = function () {
        var _this = this;
        var options = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        options.buttonOKtext = 'Yes';
        ConfirmDialog2Component.openDialog('Delete Benefit Tier', this.confirmationPopupTitle, this.modalService, function (isDelete) {
            if (isDelete) {
                _this.manService.deleteProviderTier(_this.selectedProviderTier.id);
            }
        }, options);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsBenefitInfoMenuComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsBenefitInfoMenuComponent;
}());
export { BenefitDetailsBenefitInfoMenuComponent };
