import { Provider } from '@angular/core';

export * from './signalr/notification-hub.service';

import { NotificationHubService } from './signalr/notification-hub.service';



export const services: Provider[] = [
  NotificationHubService
];
