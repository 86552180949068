
import * as _ from 'lodash';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment, NavigationEnd, Event } from '@angular/router';

import { ApplicationStateBusService, OrgLevelWatchService } from '../../../organization/services/index';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/combineLatest';
import { ApplicationService } from '../application/application.service';
import { UserApplication, User } from '../../../state-model/models/index';
import { mutableSelect } from '../../../core/decorators/index';
import { ISession, IUser } from '../../../authentication/store/index';
import { Assert } from '../../../framework/index';
import { StateNavigationService, ChangeManagementService, RestoreQueryParamsService } from '../../../common/services/index';
import { NavigationMenuItem, INavigationMenuEvent, flattenMenu, NavigationMenuEventType, NavigationMenuType } from '../../../organization/models/index';
import { OrgLevel } from '../../../state-model/models/index';
import { IRouteInfo } from '../../../core/models/index';
import { AccessibleService } from '../../../organization/services/index';


@Injectable()
export class UrlStateManagementService {

  // TODO change to SessionService or AuthentificationService subscription
  @mutableSelect(['session', 'user'])
  public user$: Observable<User>;
  @mutableSelect('application')
  public application$: Observable<UserApplication>;

  private subscriptions: StringMap<Subscription>;
  private navigationService: StateNavigationService;
  private initialized: boolean;
  private orglevelsInitalized: boolean;
  private selectedApplication: UserApplication;
  private lastSelectedOrglevelId: number;
  private selectedOrglevelId: number;
  private selectedMenuItem: NavigationMenuItem;
  private navigationEvent$: ReplaySubject<Event>;
  private navigationEventAfterApps$: ReplaySubject<Event>;

  constructor (
    private router: Router,
    private route: ActivatedRoute,
    private applicationService: ApplicationService,
    private appStateBusService: ApplicationStateBusService,
    private orgLevelWatchService: OrgLevelWatchService,
    private accessibleService: AccessibleService,
    private changeManagement: ChangeManagementService,
    private restoreQueryParamsService: RestoreQueryParamsService
  ) {
    this.subscriptions = {};
    this.navigationEvent$ = new ReplaySubject(1);
    this.navigationEventAfterApps$ = new ReplaySubject(1);
    this.navigationService = new StateNavigationService(this.router, this.route, this.changeManagement, this.restoreQueryParamsService);
    this.subscriptions.navSubscription = this.router.events
      .filter((e: Event) => e instanceof NavigationEnd)
      .subscribe((e: Event) => {
        this.navigationEvent$.next(e);
        this.updateRouteInfo();
      });
    this.appStateBusService.lastOrgLevelRestored$.subscribe(() => {
      this.subscribeToSources();
    });
    this.appStateBusService.login$.subscribe((isAliasChanged: boolean) => {
      if (isAliasChanged) {
        this.lastSelectedOrglevelId = undefined;
        this.unsubscribeFromSources();
      }
    });
  }

  private updateRouteInfo(): void {
    let currentRoute: ActivatedRoute = this.route;
    while (currentRoute.firstChild) currentRoute = currentRoute.firstChild;
    if (_.get(currentRoute, 'outlet') === 'primary') {
      const data: IRouteInfo = _.get(currentRoute, 'snapshot.data') || null;
      this.appStateBusService.changeRouteInfo(data);
    }
  }

  private unsubscribeFromSources(): void {
    if (this.subscriptions.routeAppSubscription) {
      this.subscriptions.routeAppSubscription.unsubscribe();
      this.subscriptions.routeAppSubscription = null;
    }
    if (this.subscriptions.routeMenuSubscription) {
      this.subscriptions.routeMenuSubscription.unsubscribe();
      this.subscriptions.routeMenuSubscription = null;
    }
  }
  private subscribeToSources(): void {
    this.unsubscribeFromSources();
    this.subscriptions.routeAppSubscription = this.navigationEvent$
      .withLatestFrom(this.user$, this.application$, this.appStateBusService.lastOrgLevelRestored$)
      .subscribe(([e, user, application, lastOrgLevelRestored]: [NavigationEnd, User, UserApplication, OrgLevel]) => {
        if (!user) {
          this.selectedApplication = null;
          this.selectedOrglevelId = 0;
          return;
        }
        let orgLevelId = this.getOrglevelIdFromRoute();
        if (!orgLevelId) {
          if (this.lastSelectedOrglevelId) {
            orgLevelId = this.lastSelectedOrglevelId;
          } else if (lastOrgLevelRestored) {
            orgLevelId = lastOrgLevelRestored.id;
          }
        }
        if (!this.selectedOrglevelId || (orgLevelId && orgLevelId !== this.selectedOrglevelId)) {
          this.orgLevelWatchService.getOrgLevelByIdOrDeafault(orgLevelId)
            .then((value: OrgLevel) => {
              if (!this.selectedOrglevelId) {
                if (value) this.applyNewOrgLevel(e, application, value.id);
                return;
              }
              this.checkAndApplyNewOrgLevel(e, application, value.id);
            });
          return;
        }
        this.parseApplicationFromUrl(e, application, orgLevelId);
      });

    this.subscriptions.routeMenuSubscription = this.navigationEventAfterApps$
      .combineLatest(this.appStateBusService.menuLoaded$)
      .subscribe(([e, menu]: [NavigationEnd, NavigationMenuItem[]]) => {
        let path = this.parseUrl(e);
        this.reselectMenu(path, menu);
      });
  }

  private checkAndApplyNewOrgLevel(e: NavigationEnd, application: UserApplication, orgLevelId: number): void {
    this.accessibleService.isAccessibleForChangeOrgLevel(orgLevelId)
      .then((isAccessible: boolean) => {
        if (!isAccessible) {
          this.navigationService.navigateToOrgLevel(this.selectedOrglevelId);
          this.applyNewOrgLevel(e, application, this.selectedOrglevelId);
          return;
        }
        this.applyNewOrgLevel(e, application, orgLevelId);
      });
  }

  private applyNewOrgLevel(e: NavigationEnd, application: UserApplication, orgLevelId: number): void {
    this.appStateBusService.selectOrgLevelId(orgLevelId);
    this.selectedOrglevelId = orgLevelId;
    this.lastSelectedOrglevelId = orgLevelId;
    this.parseApplicationFromUrl(e, application, orgLevelId);
  }

  private parseApplicationFromUrl(e: NavigationEnd, application: UserApplication, orgLevelId: number): void {
    let path = this.parseUrl(e);
    if (path.length < 2) {
      //no applications in route
      this.navigationService.navigateToHome(orgLevelId);
      this.applicationService.applicationSelected(null);
      this.selectedApplication = null;
      this.initializeIfNot();
      this.navigationEventAfterApps$.next(e);
      return;
    }
    // avoid further check for login page (forgot/reset password)
    if (path[0] === 'login') {
      return;
    }
    let applicationName: string = path[1];
    this.applicationService.getApplicationByName(applicationName).then((app: UserApplication) => {
      if (!app) {
        //wrong or not applicable application url
        this.selectedApplication = null;
        this.applicationService.deselectApplication();
        this.navigationService.navigateToHome(orgLevelId);
        this.applicationService.applicationSelected(null);
        this.initializeIfNot();
        return;
      }
      if (!this.selectedApplication || app.id !== this.selectedApplication.id) {
        this.selectedApplication = app;
        this.applicationService.applicationSelected(app);
      } else if (this.selectedApplication) {
        this.applicationService.applicationSelected(this.selectedApplication);
      }
      this.initializeIfNot();
      this.navigationEventAfterApps$.next(e);
    });
  }

  private reselectMenu(path: string[], menu: NavigationMenuItem[]): void {
    if (!menu) {
      return;
    }
    if (!this.selectedApplication || !this.selectedApplication.id) {
      const navMenu = new NavigationMenuItem();
      navMenu.type = NavigationMenuType.menuItem;
      this.appStateBusService.selectMenu({ type: NavigationMenuEventType.empty, menuItem: navMenu });
      return;
    }
    let flatItems = flattenMenu(menu);
    if (path.length < 3) {
      const appMenu = new NavigationMenuItem();
      appMenu.applicationId = this.selectedApplication.id;
      appMenu.name = this.selectedApplication.link;
      appMenu.link = this.selectedApplication.link;
      appMenu.type = NavigationMenuType.application;
      this.appStateBusService.selectMenu({ type: NavigationMenuEventType.new, menuItem: appMenu });
      return;
    }
    let name = path[2];
    let selectedItem = _.find(flatItems, (menuItem: NavigationMenuItem) => {
      return menuItem.name === name && (this.selectedApplication.id === menuItem.applicationId || menuItem.isGlobal);
    });
    if (selectedItem) {
      if (this.selectedMenuItem && selectedItem.id === this.selectedMenuItem.id) {
        this.appStateBusService.selectMenu({ type: NavigationMenuEventType.reselect, menuItem: selectedItem });
      } else {
        this.appStateBusService.selectMenu({ type: NavigationMenuEventType.new, menuItem: selectedItem });
      }
      this.selectedMenuItem = selectedItem;
    } else {
      const navMenu = new NavigationMenuItem();
      navMenu.applicationId = this.selectedApplication.id;
      navMenu.name = name;
      navMenu.link = name;
      navMenu.type = NavigationMenuType.menuItem;
      this.appStateBusService.selectMenu({ type: NavigationMenuEventType.notexist, menuItem: navMenu });
      this.selectedMenuItem = navMenu;
    }
  }

  private parseUrl(e: NavigationEnd): string[] {
    let url = _.split(e.urlAfterRedirects, '?');
    if (!url) {
      return [];
    }
    let path = _.filter(_.split(url[0], '/'), (s: string) => s.length > 0);
    return path;
  }

  private initializeIfNot(): void {
    if (!this.initialized) {
      this.initialized = true;
      this.appStateBusService.initialize();
    }
  }

  private getOrglevelIdFromRoute(): number {
    const id: string = this.route.snapshot.queryParamMap.get('orgLevelId');
    return _.toInteger(id);
  }
}
