<div class="container-fluid screen">
  <div class="row toggle">
    <div class="col-3">
      <slx-checkbox-toggle
        class="active-only-field tog"
        className="toggle-right"
        caption="Pause Sync:"
        [(ngModel)]="pause"
        (ngModelChange)="syncPause()"
        checkedText="Yes"
        uncheckedText="No"
      ></slx-checkbox-toggle>
    </div>
    <div class="col-3">
      <slx-checkbox-toggle
        class="active-only-field tog"
        className="toggle-right"
        caption="Stop Sync:"
        [(ngModel)]="stop"
        (ngModelChange)="syncStop()"
        checkedText="Yes"
        uncheckedText="No"
      ></slx-checkbox-toggle>
    </div>
  </div>
  <div class="row">
    <kendo-tabstrip class="slx-tabs org-tabs">
      <kendo-tabstrip-tab title="Organization Sync({{ type | titlecase }})" [selected]="true">
        <ng-template kendoTabContent>
          <div class="parentWrapper">
            <kendo-tabstrip class="slx-tabs org-tabs">
              <kendo-tabstrip-tab title="Organization" [selected]="true">
                <ng-template kendoTabContent>
                  <div class="parentWrapper">
                    <slx-wfm-ngp-org-dept-pos-sync 
                      [syncType]="type"
                      [subType]="'organization'">
                    </slx-wfm-ngp-org-dept-pos-sync>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab title="Department">
                <ng-template kendoTabContent>
                  <div class="parentWrapper">
                  <slx-wfm-ngp-org-dept-pos-sync 
                    [syncType]="type"
                    [subType]="'department'">
                  </slx-wfm-ngp-org-dept-pos-sync>
                </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab title="Position">
                <ng-template kendoTabContent>
                  <div class="parentWrapper">
                    <slx-wfm-ngp-org-dept-pos-sync 
                      [syncType]="type"
                      [subType]="'position'">
                    </slx-wfm-ngp-org-dept-pos-sync>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Employee Sync({{ type | titlecase }})">
        <ng-template kendoTabContent>
          <div class="parentWrapper emp-wrapper">
            <slx-wfm-ngp-employee-sync [syncType]="type"></slx-wfm-ngp-employee-sync>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Benefit-Deduction Sync({{ type | titlecase }})">
        <ng-template kendoTabContent>
          <div class="parentWrapper benifit-wrapper">
            <slx-wfm-ngp-benefitdeduction-sync [syncType]="type"></slx-wfm-ngp-benefitdeduction-sync>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="CostCenter Sync({{ type | titlecase }})">
        <ng-template kendoTabContent>
          <div class="parentWrapper cost-wrapper">
            <slx-wfm-ngp-laborcode-sync [syncType]="type"></slx-wfm-ngp-laborcode-sync>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Secondary Positions Sync({{ type | titlecase }})">
        <ng-template kendoTabContent>
          <div class="parentWrapper cost-wrapper">
            <slx-wfm-ngp-secondary-positions-sync [syncType]="type"></slx-wfm-ngp-secondary-positions-sync>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
  
  