import { ModalService } from './../../../../common/services/modal/modal.service';
import { TimeclockCommunicationApiService } from './../../services/timeclock-communication/timeclock-communication-api.service';
import { StringUtils } from './../../../../framework/string-utils/string-utils';
import { EmployeeSectionsContacts } from './../../../employee-sections/models/employee-sections-personal/employee-sections-contacts';
import { EmployeeSectionsPersonalApiService } from './../../../employee-sections/services/employee-section-personal/employee-section-personal-api.service';
import { mutableSelect } from './../../../../core/decorators/redux-decorators';
import { unsubscribe } from './../../../../core/decorators/unsubscribe-decorator';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { OrgLevel } from '../../../../state-model/models/index';
import { LookupType } from './../../../../organization/models/lookup/lookup-definition';
import { Employee } from './../../../../organization/models/lookup/employee';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { PhonesTypes } from '../../models/index';
@Component({
    moduleId: module.id,
    selector: 'slx-log-phone-call',
    templateUrl: 'log-phone-call.component.html',
    styleUrls: ['log-phone-call.component.scss']
})
export class LogPhoneCallComponent implements OnInit, OnDestroy {

    public isLoading: boolean = false;
    @mutableSelect('orgLevel')
    public orgLevel$: Observable<OrgLevel>;
    public orgLevel: OrgLevel;

    public selectedEmployee: Employee;
    public selectedDate: Date = new Date();
    public phoneType: PhonesTypes;
    public messageText: string;

    public homePhone: string;
    public mobilePhone: string;
    public altPhone: string;

    @unsubscribe()
    private orgLevelSubscription: Subscription;

    constructor(private personalService: EmployeeSectionsPersonalApiService,
                private apiService: TimeclockCommunicationApiService,
                private modalService: ModalService) {
    }

    public ngOnInit(): void {
        this.orgLevelSubscription = this.orgLevel$.subscribe((orgLevel: OrgLevel) => {
            this.orgLevel = orgLevel;
        });
    }

    public ngOnDestroy(): void {
      // #issueWithAOTCompiler
    }

    public employeeChanged(emp: Employee): void {
        this.homePhone = null;
        this.mobilePhone = null;
        this.altPhone = null;
        this.phoneType = null;
        if (!emp) return;
        this.isLoading = true;
        this.personalService.getPersonalContacts(emp.id).then((value: EmployeeSectionsContacts) => {
            this.altPhone = value.alternativePhone.fieldValue;
            this.mobilePhone = value.mobile.fieldValue;
            this.homePhone = value.phone.fieldValue;
            if (this.homePhone) {
                this.phoneType = PhonesTypes.home;
            } else if (this.mobilePhone) {
                this.phoneType = PhonesTypes.mobile;
            } else if (this.altPhone) {
                this.phoneType = PhonesTypes.alternative;
            }
            this.isLoading = false;
        }).catch((reason: any) => {
            this.isLoading = false;
        });
    }

    public get canSave(): boolean {
        return this.selectedEmployee && this.phoneType !== null && !StringUtils.isNullOrEmpty(this.messageText);
    }

    public save(): void {
        this.isLoading = true;
        this.apiService.saveCall(this.selectedEmployee.id, this.selectedDate, this.selectedPhone, this.messageText).then((value: any) => {
            this.selectedEmployee = null;
            this.messageText = null;
            this.homePhone = null;
            this.mobilePhone = null;
            this.altPhone = null;
            this.phoneType = null;
            this.isLoading = false;
            this.modalService.globalAnchor.openInfoDialog('Information', 'Call has been saved!');
        }).catch((reason: any) => {
            this.isLoading = false;
        });
    }

    private get selectedPhone(): string {
        switch (this.phoneType) {
            case PhonesTypes.home:
                return this.homePhone;
            case PhonesTypes.mobile:
                return this.mobilePhone;
            case PhonesTypes.alternative:
                return this.altPhone;
        }
        return null;
    }
}
