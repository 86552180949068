<div class="slx-high-box daily-punches">
    <div #punchesHeader class="slx-content-toolbar-indents punches-header">
        <div class="left-buttons">
            <div class="header-buttons">
                <div class="date-filter">
                    <slx-date-range-ngx name="startEndDate" [startDate]="dateRange.startDate"
                        [endDate]="dateRange.endDate" [hasApplyButton]="true"
                        (rangeDateChanged)="onChangeDates($event)"></slx-date-range-ngx>
                </div>
            </div>
        </div>
    </div>
</div>