import { RolesMenuAccess } from './role-access-definition';
import { IRoleSubmenu, RoleSubmenu } from './role-submenu';

export interface IRoleMenu {
  id: number;
  name: string;
  isEnabled: boolean;
  subMenus: IRoleSubmenu[];
}

export class RoleMenu {
  public id: number;
  public name: string;
  public isEnabled: boolean;
  public access: RolesMenuAccess;
  public subMenus: RoleSubmenu[];

  public get isDirty(): boolean {
    return this.isEnabled !== this.initial_isEnabled;
  }
  private initial_isEnabled: boolean;

  public setInitialState(): void {
    this.initial_isEnabled = this.isEnabled;
  }
}
