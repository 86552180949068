import { Directive, HostListener, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DailyUnitAssignmentNavigationService, SoConsoleNavigationService } from '../../../common/services/index';

import { OrganizationTreeContext } from '../../models/index';
import { OrgLevel, OrgLevelType } from '../../../state-model/models/index';
import { NgRedux } from '@angular-redux/store';
import { OrgLevelActions } from '../../actions/org-level.actions';
import { IAppState } from '../../../store/store';
import { OrgLevelWatchService } from '../../services/index';

@Directive({
  /* tslint:disable */
  selector: '[dailyUnitAssignmentLink][dateOn][entityId][entityType]',
  /* tslint:enable */
})
export class DailyUnitAssignmentLinkDirective {
  @Input()
  public dateOn: Date;
  @Input()
  public entityId: number;
  @Input()
  public entityType: string;
  @Input()
  public viewMode: string;

  private soNavService: SoConsoleNavigationService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orgLevelActions: OrgLevelActions,
    private ngRedux: NgRedux<IAppState>,
    private orgLevelWatchService: OrgLevelWatchService) {

      this.soNavService = new SoConsoleNavigationService(this.router, this.route);
  }

  @HostListener('click', ['$event'])
  public onClick(e: MouseEvent): void {
    if (this.entityType === OrgLevelType.corporation
      || this.entityType === OrgLevelType.organization
      || this.entityType === OrgLevelType.department) {
      this.jumpToOrgLevel(this.entityId);
    } else if (this.entityType === 'Position') {
      let navService: DailyUnitAssignmentNavigationService = new DailyUnitAssignmentNavigationService(this.router, this.route);
      navService.navigateToDailyUnitAssignment(this.dateOn, this.entityId, this.viewMode);
    } else {
      throw new TypeError('entityType should be OrgLevel type or Position');
    }
  }

  private jumpToOrgLevel(orgLevelId: number): void {
    this.soNavService.navigateToSoConsole(orgLevelId);
  }
}
