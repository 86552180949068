import { ITImecardSummarySectionContainer } from './timecard-summary-section-container.interface';
import { EmployeeDefinition, Position } from '../../../organization/models/index';

export class ExemptsSummaryContainer implements ITImecardSummarySectionContainer {
    public records: TimecardSummaryExemptEmployee[];
}

export class TimecardSummaryExemptEmployee {
    public employee: EmployeeDefinition;
    public position: Position;
    public exemptType: string;
    public currentCycleHours: number;
    public previousCycleHours: number;
}
