import * as moment from 'moment';
import { IBenefitDetailsBasic } from '../benefit-details/benefit-details-basic';
import { BenefitDetailsCalcMethod } from '../benefit-details/benefit-details-calc-method';
import { IBenefitDetailsOption, BenefitDetailsOption } from '../benefit-details/benefit-details-option';
import { appConfig } from '../../../../app.config';

export interface IBenefitEmpDependentEnrollment {
  id: number;
  dependentId: number;
  empEnrollmentId: number;
  benefitName: string;
  tierName: string;
  benefitOption: IBenefitDetailsOption;
  benefitCalcMethod: IBenefitDetailsBasic<string, string>;
  startDate: string;
  endDate: string;
  empBenefitStartDate: string;
  empBenefitEndDate: string;
  dropEventDate: string;
}

export class BenefitEmpDependentEnrollment {
  public id: number;
  public dependentId: number;
  public empEnrollmentId: number;
  public benefitName: string;
  public tierName: string;
  public benefitOption: BenefitDetailsOption;
  public benefitCalcMethod: BenefitDetailsCalcMethod;
  public startDate: Date;
  public endDate: Date;
  public empBenefitStartDate: Date;
  public empBenefitEndDate: Date;
  public dropEventDate: Date;
}
