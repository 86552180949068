
export interface IScheduleAbsence {
  code: string;
  description: string;
  color: number;
  isPaid: boolean;
  ptoPlannerIndicator: boolean;
  loaIndicator: boolean;
}

export class ScheduleAbsence {
  public code: string;
  public description: string;
  public color: number;
  public isPaid: boolean;
  public ptoPlannerIndicator: boolean;
  public loaIndicator: boolean;

  public get codeDescription(): string {
    return `[${this.code}] ${this.description}`;
  }
}
