import { DownloadedData, DownloadedExcelData, TemplateValidator } from "./ideal-schedule-import-template-validator";
import { IdealSchedulePositionDefinition } from "./ideal-schedule-position-definition";

export class IdealScheduleImportTemplate {
    public header: string;
    public positionList: IdealSchedulePositionDefinition[];
    public organizationList: OrgLevelDetails[];
    public isDownloadTemplate: boolean;
    public buttonName: string;
    public selectedPositions: any;
    public exceptionData: ImportException[];
    public isDefaultShiftGroupEnabled: boolean;
}

export interface IOrgLevelDetails {
    organizationId: number;
    organizationName: string;
    orgLevelId: string;
    isChecked: boolean;
}

export class OrgLevelDetails {
    public organizationId: number;
    public organizationName: string;
    public orgLevelId: string;
    public isChecked: boolean;
}

export interface IImportException {
    idealScheduleId: number;
    positionId: number;
    positionName: string;
    configurationType: string;
    censusType: string;
    errorMessage: string;
    isConfigured: boolean;
    isInvalidConfigType: boolean;
    isInvalidCensusType: boolean;
    isNoException: boolean;
}
export class ImportException {
    public idealScheduleId: number;
    public positionId: number;
    public positionName: string;
    public configurationType: string;
    public censusType: string;
    public errorMessage: string;
    public isConfigured: boolean;
    public isInvalidConfigType: boolean;
    public isInvalidCensusType: boolean;
    public isNoException: boolean;
}

export class ImportTemplateDto {
    public importedPositions: ImportedPositionsDto[];
}

export class ImportedPositionsDto {
    public positionId: number;
    public positionName: string;
    public configurationType: string;
    public censusType: string;
    public idealScheduleId: number;
}

export class DownloadTemplate {
    public fileName: string;
    public fileData: DownloadedExcelData[];
}