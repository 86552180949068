import { ReportDefinition } from './report-definition';
import { LookupDefinition } from '../../../app/organization/index';
import { IReportParameterType } from './report-parameter-type';

export interface IReportParameter {
  name: string;
  displayName: string;
  dataType: string;
  lookupName: string;
  defaultValue: any;
  value: any;
  isVisible: boolean;
  isMultiselect: boolean;
}

export class ReportParameter {
  name: string;
  displayName: string;
  dataType: IReportParameterType;
  defaultValue: any;
  value: any;
  isVisible: boolean;
  isMultiselect: boolean;
  reportDefinition: ReportDefinition;
}
