<slx-spinner [show]="state.isLoading">
  <div class="slx-high-box" *ngIf="state.configureMode">
    <form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
      <kendo-grid #kendoGrid class="slx-full-height" *ngIf="!loadingPanelVisible" [data]="gridState?.view"
        [filterable]="true" [selectable]="{ mode: 'single' }" [filter]="gridState.state.filter"
        [sort]="gridState.state.sort" [sortable]="{ mode: 'multiple' }" [slxKendoGridColumnsGroup]="columnsGroupName"
        [filtersManagement]="true" [slxKendoGridState]="gridState.gridDefinition"
        (selectionChange)="gridState.selectionChange($event)" (dataStateChange)="gridState.dataStateChange($event)"
        (stateRestored)="gridState.dataStateChange($event)" (cancel)="onCancel($event)" (edit)="onEdit($event)"
        (remove)="gridState.removeHandler($event)" (save)="gridState.saveHandler($event)">


        <ng-template *ngIf="isPartialShiftEnabled" kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex"
          [kendoGridDetailTemplateShowIf]="hasPartialDataOrNew">
          <kendo-grid #kendoPartialGrid [selectable]="{ mode: 'single' }" [data]="dataItem.partialShiftList"
            (remove)="pgRemoveHandler($event)" (cancel)="pgCancelHandler($event)" (edit)="pgEditHandler($event)"
            (save)="pgSaveHanlder($event)" (add)="pgAddHandler($event)">
            <ng-template *ngIf="dataItem.isEditing" kendoGridToolbarTemplate>
              <div class="slx-el-col ohxs">
                <button kendoGridAddCommand class="slx-button slx-only-icon slx-toolbar">
                  <i class="fas fa-plus-circle" aria-hidden="true"></i>
                </button>
              </div>
            </ng-template>
            <kendo-grid-command-column *ngIf="dataItem.isEditing" [width]="100">
              <ng-template kendoGridCellTemplate>
                <button kendoGridSaveCommand class="slx-button slx-only-icon slx-toolbar">
                  <i class="far fa-check-circle" aria-hidden="true"></i>
                </button>
                <button kendoGridEditCommand class="slx-button slx-only-icon slx-toolbar">
                  <i class="fas fa-pencil-alt" aria-hidden="true"></i>
                </button>
                <button kendoGridCancelCommand class="slx-button slx-only-icon slx-toolbar">
                  <i class="fa fa-ban" aria-hidden="true"></i>
                </button>
                <button kendoGridRemoveCommand class="slx-button slx-only-icon slx-toolbar">
                  <i class="fas fa-trash-alt" aria-hidden="true">
                  </i>
                </button>
              </ng-template>

            </kendo-grid-command-column>
            <kendo-grid-column title="Shift Description" field="name">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">{{ dataItem.name }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Shift Start" field="startTime" [minResizableWidth]="200"
              [maxResizableWidth]="200">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">
                  {{ dataItem.start |
                  amDateFormat:
                  appConfig.timeFormat }}
                </span>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-input-decorator>
                  <input slx-time-picker slx-input name="ps-startDate{{ rowIndex }}" required="true"
                    [(ngModel)]="dataItem.start" format="hh:mm tt" dateInput="true" [minDate]="minDateLimit"
                    [maxDate]="maxDateLimit" (onDateChanged)="onShiftDateChanged(dataItem)">
                  <span errorMessage for="required">Start time is required</span>
                  <span errorMessage for="minDate">Date should be with in range [ {{minDateLimit}} - {{maxDateLimit}}]
                  </span>
                  <span errorMessage for="maxDate">Date should be in range [ {{minDateLimit}} - {{maxDateLimit}}]</span>
                </slx-input-decorator>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Shift End" field="endTime" [minResizableWidth]="200" [maxResizableWidth]="200">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">
                  {{ dataItem.end | amDateFormat:
                  appConfig.timeFormat }}
                </span>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
                <slx-input-decorator>
                  <input slx-time-picker slx-input name="ps-endDate{{ rowIndex }}" required="true"
                    [(ngModel)]="dataItem.end" format="hh:mm tt" dateInput="true" [minDate]="minDateLimit"
                    [maxDate]="maxDateLimit" (onDateChanged)="onShiftDateChanged(dataItem)">
                  <span errorMessage for="required">End time is required</span>
                  <span errorMessage for="minDate">Date should be with in range [ {{minDateLimit}} - {{maxDateLimit}}]
                  </span>
                  <span errorMessage for="maxDate">Date should be in range [ {{minDateLimit}} - {{maxDateLimit}}]</span>
                </slx-input-decorator>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Work Hours" field="duration">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">{{ dataItem.duration }}</span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Lunch Hours" field="lunchDuration">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">
                  {{ dataItem.lunchDuration
                  }}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Shift Group" field="groupName">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span class="left-align">{{ dataItem.groupName }}</span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </ng-template>
        <!-- desktop view -->
        <kendo-grid-command-column *ngIf="!management.access.restrictedByOrgLevel" width="100" [locked]="false"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem let-rowIndex="rowIndex">
            <div class="command-container">
              <button kendoGridEditCommand type="button" class="theme-icon-button theme-inline-edit-button">
                <i class="fas fa-pencil-alt" aria-hidden="true"></i>
              </button>
              <button kendoGridSaveCommand type="button" [disabled]="!templateForm.valid"
                class="theme-icon-button theme-inline-apply-button">
                <i class="far fa-check-circle fa-2x" aria-hidden="true"></i>
              </button>
              <button kendoGridCancelCommand type="button" class="theme-icon-button theme-inline-cancel-button">
                <i class="fa fa-ban fa-2x" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </kendo-grid-command-column>


        <kendo-grid-column title="Shift Description" field="name" [filterable]="true" width="200"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.name }}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input class="form-control" slx-input type="text" [(ngModel)]="dataItem.name" [required]="true"
                [placeholder] [prohibitedValues]="prohibitedNameValues" name="nameInput{{ rowIndex }}"
                (input)="descriptionChanged(dataItem)" filled>
              <span errorMessage for="prohibitedValues">
                {{
                appMessages.validation.prohibitedDescriptionValidation.message
                }}
              </span>
              <span errorMessage for="required"></span>
              <span errorMessage for="filled"></span>
            </slx-input-decorator>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Shift Start" field="startTime" [filterable]="true" width="200"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">
              {{ dataItem.start | amDateFormat: appConfig.timeFormat }}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-time-picker slx-input name="startDate{{ rowIndex }}" required="true"
                [(ngModel)]="dataItem.start" format="hh:mm tt" dateInput="true"
                (onDateChanged)="onShiftDateChanged(dataItem)">
              <span errorMessage for="required">
                Start time is required
              </span>
            </slx-input-decorator>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-time-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Shift End" field="endTime" [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">
              {{ dataItem.end | amDateFormat: appConfig.timeFormat }}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <input slx-time-picker slx-input name="endDate{{ rowIndex }}" required="true" [(ngModel)]="dataItem.end"
                format="hh:mm tt" dateInput="true" (onDateChanged)="onShiftDateChanged(dataItem)">
              <span errorMessage for="required">
                End time is required
              </span>
            </slx-input-decorator>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-time-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-time-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Work Hours" field="duration" [filterable]="true" width="200"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.duration }}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <slx-kendo-number slx-input [(ngModel)]="dataItem.duration"
                (ngModelChange)="shiftPaidTimeChanged(dataItem, $event)" [min]="0" [slxMin]="0" [slxMinStrict]="true"
                [required]="'true'" [decimals]="2" [step]="0.1" name="duration{{ rowIndex }}"></slx-kendo-number>
              <span errorMessage for="min">
                Hours should be greater than zero
              </span>
              <span errorMessage for="required">
                Hours are required
              </span>
            </slx-input-decorator>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter" [min]="0"
              [step]="0.1">
            </slx-kendo-grid-number-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Lunch Duration" field="lunchDuration" [filterable]="true" width="200"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.lunchDuration }}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <slx-kendo-number slx-input [(ngModel)]="dataItem.lunchDuration"
                (ngModelChange)="shiftUnpaidTimeChanged(dataItem, $event)" [min]="0" [slxMin]="0" [required]="'true'"
                [decimals]="2" [step]="0.1" name="lunchDuration{{ rowIndex }}"></slx-kendo-number>
              <span errorMessage for="min">
                Hours should be greater than or equal zero
              </span>
              <span errorMessage for="required">
                Hours are required
              </span>
            </slx-input-decorator>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-number-filter [showOperators]="true" [column]="column" [filter]="filter" [min]="0"
              [step]="0.1">
            </slx-kendo-grid-number-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Shift Group" field="groupName" [filterable]="true" width="200"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.groupName }}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-input-decorator>
              <slx-dropdown-input slx-input [options]="management.groups" valueField="id" titleField="name"
                [(ngModel)]="dataItem.group" name="groupInput{{ rowIndex }}" [required]="true"
                [readonly]="dataItem.setDefaultShift === 'YES' ? true : false">
              </slx-dropdown-input>
              <span errorMessage for="required"></span>
            </slx-input-decorator>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column *ngIf="isDefaultShiftEnabled" title="Default Shift" field="defaultShiftData"
          [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{dataItem.defaultShiftData}}</span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="slx-default-shift">
              <div *ngFor="let i of defaultShift">
                <input type="radio" [name]="i.name" [value]="i.name" [(ngModel)]="dataItem.setDefaultShift"
                  (ngModelChange)="defaultShiftChange(dataItem, rowIndex, $event)">
                <label style="margin-left: 4px;">{{i.name}}</label>
              </div>
            </div>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column *ngIf="isPartialShiftEnabled" title="Allow Partial Shifts" field="defaultPartailShiftFlag"
          [filterable]="true" width="200" media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">
              {{dataItem.hasPartialShift ? "YES":"NO"}}
            </span>
          </ng-template>
          <ng-template kendoGridEditTemplate let-isNew="isNew" let-dataItem="dataItem" let-rowIndex="rowIndex">
            <div class="k-inline-block" [popover]="pSettingsPopover">
              <slx-switcher [disabled]="isNew" name="psflag{{ rowIndex }}" label width="100" labelOn="YES" labelOff="NO"
                [(ngModel)]="dataItem.hasPartialShift" (ngModelChange)="pSettingsPopover.hide()"
                (ngModelChange)="onAllowPartialShiftChanged(dataItem, rowIndex)">
              </slx-switcher>
              <button *ngIf="dataItem.hasPartialShift" name="pssetting{{ rowIndex }}"
                class="theme-button slx-button slx-only-icon slx-toolbar" style="margin-left: 5px ">
                Settings
              </button>
            </div>
            <popover-content name="ps-popctx-{{ rowIndex }}" #pSettingsPopover title="Partial Shift Settings"
              class="popover-content-arrow slx-wide" disableClickOutside="true" (onShow)="onShowShiftSetting(dataItem)"
              (close)="pSettingsPopover.hide();" placement="auto" style="width:250px">
              <div>
                <div class="k-inline-block">
                  <slx-switcher name="psRequest{{ rowIndex }}" label="Allow staff to submit partial shift requests?"
                    width="100" labelOn="YES" labelOff="NO" [(ngModel)]="dataItem.shiftSetting.allowPartialRequest">
                  </slx-switcher>
                </div>
                <div class="k-inline-block">
                  <slx-switcher name="psAgency{{ rowIndex }}" label="Allow partial shifts for agency?"
                    class="k-inline-block" width="100" labelOn="YES" labelOff="NO"
                    [(ngModel)]="dataItem.shiftSetting.allowAgencyAccess">
                  </slx-switcher>
                </div>
                <p></p>
                <div class="btn-container">
                  <button type="button" class="theme-button-apply" [disabled]="isDisabled"
                    (click)="pSettingsPopover.hide();onSaveShiftSetting(dataItem,rowIndex);">
                    Save
                  </button>
                </div>
              </div>
            </popover-content>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Updated By" field="lastUpdateUsername" [filterable]="true" width="200"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align">{{ dataItem.lastUpdateUsername }}</span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-string-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-string-filter>
          </ng-template>
        </kendo-grid-column>


        <kendo-grid-column title="Last Updated" field="lastUpdateDate" filter="date" [filterable]="true" width="200"
          media="(min-width: 450px)">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="left-align" *ngIf="dataItem.lastUpdateDate">
              {{
              dataItem.lastUpdateDate | amDateFormat: appConfig.dateTimeFormat
              }}
            </span>
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <slx-kendo-grid-date-filter [showOperators]="true" [column]="column" [filter]="filter">
            </slx-kendo-grid-date-filter>
          </ng-template>
        </kendo-grid-column>



        <!-- mobile view -->
        <kendo-grid-column media="(max-width: 450px)" width="100%">
          <ng-template kendoGridHeaderTemplate>Shifts</ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <slx-view-shift-renderer [rowIndex]="rowIndex" [item]="dataItem"
              (actionEmitter)="onMobileRendererEvent($event, dataItem, rowIndex)"></slx-view-shift-renderer>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-rowIndex="rowIndex">
            <slx-editor-shift-renderer [item]="dataItem" [rowIndex]="rowIndex"
              [onShiftStartDateChanged]="onShiftStartDateChanged" [onShiftEndDateChanged]="onShiftEndDateChanged"
              [shiftPaidTimeChanged]="shiftPaidTimeChanged" [shiftUnpaidTimeChanged]="shiftUnpaidTimeChanged"
              [prohibitedNameValues]="prohibitedNameValues" [groups]="management.groups" [templateForm]="templateForm"
              (actionEmitter)="onMobileEditorEvent($event, dataItem, rowIndex)"></slx-editor-shift-renderer>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <slx-spinner [show]="loadingPanelVisible"></slx-spinner>
    </form>
    <div class="actions-bar" [ngClass]="{ inactive: management.access.lockActions }">
      <button class="action-button" [disabled]="!management.access.canEdit" (click)="onCopyItems($event)">
        Copy Shift
      </button>
      <div class="col md-no-line-column">
        <span *ngIf="management.access.restrictedByOrgLevel" class="edit-info-warning">
          Shifts are editable only at
          Department level
        </span>
      </div>
      <button class="action-button" [slx-list-delete-operation]="onDeletedItems">
        Delete
      </button>
      <button class="action-button" [slx-list-add-operation]="onAddItem">
        Add
      </button>
    </div>
  </div>


  <div class="copy-content" *ngIf="state.copyMode">
    <slx-copy-shifts [isDefaultShiftEnabled]="isDefaultShiftEnabled" [shifts]="management?.container?.records"
      (onDiscard)="switchToConfigure()"></slx-copy-shifts>
  </div>
</slx-spinner>