import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { mutableSelect, unsubscribe } from '../../../core/decorators/index';
import { ISession } from '../../../authentication/store/index';
import { AuthenticationService } from '../../../authentication/services/index';
import { ChangeManagementService } from '../../../common/services/index';
import { ApplicationStateBusService } from '../../../organization/services/index';
var UserMenuComponent = /** @class */ (function () {
    function UserMenuComponent(authenticationService, changeManagementService, applicationStateBusService) {
        this.authenticationService = authenticationService;
        this.changeManagementService = changeManagementService;
        this.applicationStateBusService = applicationStateBusService;
    }
    UserMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userSubscription = this.user$.subscribe(function (session) {
            if (session) {
                _this.alias = session.alias;
                if (session.user) {
                    _this.userName = session.user.name;
                    _this.login = session.user.username;
                }
            }
        });
    };
    UserMenuComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    UserMenuComponent.prototype.logOut = function () {
        var _this = this;
        this.changeManagementService.canMoveForward()
            .then(function (can) {
            if (can) {
                _this.authenticationService.logout();
            }
            else {
                //todo
            }
        });
    };
    UserMenuComponent.prototype.resetPassword = function () {
        var _this = this;
        this.changeManagementService.canMoveForward()
            .then(function (can) {
            if (can) {
                _this.authenticationService.navigateToResetPassword(_this.login, _this.alias);
            }
            else {
                //todo
            }
        });
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], UserMenuComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        mutableSelect(['session']),
        tslib_1.__metadata("design:type", Observable)
    ], UserMenuComponent.prototype, "user$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserMenuComponent.prototype, "userSubscription", void 0);
    return UserMenuComponent;
}());
export { UserMenuComponent };
