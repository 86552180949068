import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
var EmployeeSectionsBenefitsManagement = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsBenefitsManagement, _super);
    function EmployeeSectionsBenefitsManagement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return EmployeeSectionsBenefitsManagement;
}(EmployeeSectionsBase));
export { EmployeeSectionsBenefitsManagement };
