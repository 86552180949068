import * as _ from 'lodash';
import { Directive, ElementRef, Renderer2, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';

import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/delay';

import { unsubscribe } from './../../../../core/decorators/index';

@Directive({
  selector: '[slxKendoGridHeaderTooltip]',
})
export class KendoGridHeaderTooltip implements OnInit, AfterViewInit, OnDestroy {
  @Input('slxKendoGridHeaderTooltip')
  private tooltip: string;

  @unsubscribe()
  private assignmentSubscription: Subscription;
  private startAssignment$: Subject<HTMLElement>;
  private elRef: ElementRef;
  private renderer: Renderer2;
  private kendoGridHeaderClass: string;

  constructor(elRef: ElementRef, renderer: Renderer2) {
    this.elRef = elRef;
    this.renderer = renderer;
    this.startAssignment$ = new Subject<HTMLElement>();
    this.kendoGridHeaderClass = 'k-header';
  }

  public ngOnInit(): void {
    this.assignmentSubscription = this.startAssignment$
      .delay(500)
      .subscribe((elem: HTMLElement) => this.setTooltip(elem));
  }

  public ngAfterViewInit(): void {
    const nativeElem: HTMLElement = this.elRef.nativeElement;
    if (_.isElement(nativeElem)) {
      this.renderer.setAttribute(nativeElem, 'class', 'slx-grid-col-header');
      this.startAssignment$.next(nativeElem);
    }
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
    if (this.assignmentSubscription) {
      this.assignmentSubscription.unsubscribe();
    }
  }

  private setTooltip(element: HTMLElement): void {
    const header: HTMLElement = this.findHeader(element);
    if (_.isElement(header)) {
      this.renderer.setAttribute(header, 'title', this.tooltip || element.innerHTML);
    }
  }

  private findHeader(element: HTMLElement): HTMLElement {
    const parent: HTMLElement = element && element.parentElement || null;
    if (_.isElement(parent)) {
      if (parent.classList.contains(this.kendoGridHeaderClass)) {
        return parent;
      }

      return this.findHeader(parent);
    }

    return null;
  }
}
