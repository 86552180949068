import { Directive, Input, Output, EventEmitter, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[slxKendoGridFilterInput]',
})
export class KendoGridFilterInputDirective implements OnInit {
  @Input('slxKendoGridFilterInput')
  public suppresAutoEmit: boolean;

  @Input()
  public set value(val: any) {
    this.accessor.writeValue(val);
    this.changeDetectorRef.markForCheck();
    this.changeDetectorRef.detectChanges();
  }

  @Output()
  public change: EventEmitter<string>;

  private accessor: ControlValueAccessor;
  private changeDetectorRef: ChangeDetectorRef;

  constructor( @Inject(NG_VALUE_ACCESSOR) valueAccessors: ControlValueAccessor[], changeDetectorRef: ChangeDetectorRef) {
    this.accessor = valueAccessors[0];
    this.change = new EventEmitter<string>();
    this.changeDetectorRef = changeDetectorRef;
  }

  public ngOnInit(): void {
    if (!this.suppresAutoEmit) {
      this.accessor.registerOnChange((x: any): any => {
        this.valueChanged(x);
      });
    }
  }

  public valueChanged(val: any): void {
    return this.change.emit(val);
  }
}
