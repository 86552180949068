import { Injectable } from '@angular/core';
import { IAppState } from '../../../../store';
import { NgRedux } from '@angular-redux/store';
import { WizardActions } from '../../../../common/store/index';
import { AddEmployeeModel } from '../../models/index';
import { OrganizationTreeContext } from '../../../../organization/models/index';
import { OrgLevel, OrgLevelType } from '../../../../state-model/models/index';
import { OrgLevelActions } from '../../../../organization/actions/index';

@Injectable()
export class AddEmployeeWizardActions {
  public static START_WIZARD: string = 'START_WIZARD';
  public static STEP_CHANGE: string = 'STEP_CHANGE';
  public static CLEAR_WIZARD: string = 'CLEAR_WIZARD';

  public static ADD_NEW_EMPLOYEE: string = 'ADD_NEW_EMPLOYEE';
  public static ADD_NEW_EMPLOYEE_SUCCESS: string = 'ADD_NEW_EMPLOYEE_SUCCESS';
  public static ADD_NEW_EMPLOYEE_VALIDATION_ERROR: string = 'ADD_NEW_EMPLOYEE_VALIDATION_ERROR';
  public static ADD_NEW_EMPLOYEE_ERROR: string = 'ADD_NEW_EMPLOYEE_ERROR';


  private ngRedux: NgRedux<IAppState>;
  private orgLevelActions: OrgLevelActions;
  constructor(ngRedux: NgRedux<IAppState>, orgLevelActions: OrgLevelActions) {
    this.ngRedux = ngRedux;
    this.orgLevelActions = orgLevelActions;
  }

  public startWizard(model: AddEmployeeModel): void {
    this.ngRedux.dispatch({
      type: AddEmployeeWizardActions.START_WIZARD,
      payload: model
    });
    this.ngRedux.dispatch({
      type: WizardActions.SET_STEP,
      payload: 1
    });
  }
  public finishWizard(model: AddEmployeeModel): void {
    this.ngRedux.dispatch({
      type: AddEmployeeWizardActions.ADD_NEW_EMPLOYEE,
      payload: model
    });
  }
  public nextStep(model: AddEmployeeModel): void {
    this.stepChange(model);
    this.ngRedux.dispatch({
      type: WizardActions.SELECT_NEXT_STEP
    });
  }
  public prevStep(model: AddEmployeeModel): void {
    this.stepChange(model);
    this.ngRedux.dispatch({
      type: WizardActions.SELECT_PREV_STEP
    });
  }
  public clearWizard(): void {
    this.ngRedux.dispatch({
      type: AddEmployeeWizardActions.CLEAR_WIZARD
    });
    this.ngRedux.dispatch({
      type: WizardActions.SET_STEP,
      payload: 1
    });
  }

  public selectEmployee(organizationOrgLevelId: number, departmentOrgLevelId: number, employeeId: number): void {
    let state: IAppState = this.ngRedux.getState();
    let orgLevel: OrgLevel = new OrgLevel();
    orgLevel.parentId = organizationOrgLevelId;
    orgLevel.id = departmentOrgLevelId;
    orgLevel.type = OrgLevelType.department;
    let context: OrganizationTreeContext = new OrganizationTreeContext();
    context.applicationId = state.application.id;
    this.orgLevelActions.select(context, orgLevel);
  }

  private stepChange(model: AddEmployeeModel): void {
    this.ngRedux.dispatch({
      type: AddEmployeeWizardActions.STEP_CHANGE,
      payload: model
    });
  }

}
