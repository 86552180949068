/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payroll-export-config-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../common/components/spinner/spinner.component.ngfactory";
import * as i3 from "../../../../../../common/components/spinner/spinner.component";
import * as i4 from "../payroll-export-config.component.ngfactory";
import * as i5 from "../payroll-export-config.component";
import * as i6 from "../../../../services/payroll-export-test/payroll-export-test-management.service";
import * as i7 from "./payroll-export-config-popup.component";
import * as i8 from "../../../../../../common/models/dialog-options";
import * as i9 from "../../../../../../common/services/modal/modal.service";
var styles_PayrollExportConfigPopupComponent = [i0.styles];
var RenderType_PayrollExportConfigPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PayrollExportConfigPopupComponent, data: {} });
export { RenderType_PayrollExportConfigPopupComponent as RenderType_PayrollExportConfigPopupComponent };
export function View_PayrollExportConfigPopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { exportConfig: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "modal-content-pe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "slx-spinner", [], null, null, null, i2.View_SpinnerComponent_0, i2.RenderType_SpinnerComponent)), i1.ɵdid(3, 49152, null, 0, i3.SpinnerComponent, [], { show: [0, "show"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "modal-body-pe"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "slx-payroll-export-config", [], null, null, null, i4.View_PayrollExportConfigComponent_0, i4.RenderType_PayrollExportConfigComponent)), i1.ɵdid(6, 114688, [[1, 4], ["exportConfig", 4]], 0, i5.PayrollExportConfigComponent, [i6.PayrollExportTestManagementService], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 6, "div", [["class", "pe-popup-footer pe-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "save-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "icon fas fa-save"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Save "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "icon fas fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 3, 0, currVal_0); _ck(_v, 6, 0); }, null); }
export function View_PayrollExportConfigPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-payroll-export-config-popup", [], null, null, null, View_PayrollExportConfigPopupComponent_0, RenderType_PayrollExportConfigPopupComponent)), i1.ɵdid(1, 114688, null, 0, i7.PayrollExportConfigPopupComponent, [i8.DialogOptions, i9.ModalService, i6.PayrollExportTestManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PayrollExportConfigPopupComponentNgFactory = i1.ɵccf("slx-payroll-export-config-popup", i7.PayrollExportConfigPopupComponent, View_PayrollExportConfigPopupComponent_Host_0, {}, {}, []);
export { PayrollExportConfigPopupComponentNgFactory as PayrollExportConfigPopupComponentNgFactory };
