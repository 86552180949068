import * as _ from 'lodash';

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitDetailsManagementService, BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
import {
  BenefitDetailsEditModes,
  BenefitDetailsOption,
  BenefitDetailsTier
} from '../../../models/index';

import { NgForm } from '@angular/forms';

@Component({
  selector: 'slx-benefit-details-coverage-calc-flat',
  templateUrl: './benefit-details-coverage-calc-flat.component.html',
  styleUrls: ['./benefit-details-coverage-calc-flat.component.scss'],
})
export class BenefitDetailsCoverageCalcFlatComponent implements OnInit, OnDestroy {

  public isEditMode = false;
  public min: number = 0;
  public max: number = 99999999.99;
  public step = 1;
  public value = 0;
  public format = 'c2';
  public benefitDetailsOptions = new BenefitDetailsTier();
  public employeeContribution = 0.00;
  public employerContribution = 0.00;

  @ViewChild('form', {static: true}) 
  public ngForm: NgForm;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  constructor(
    private manService: BenefitDetailsStandartManagementService,
    private permissionService: BenefitDetailsPermissionService,
  ) {
  }

  public ngOnInit() {
    this.subscriptions.editMode = this.permissionService
      .subscribeToEditMode((v: BenefitDetailsEditModes) => (this.isEditMode = v.providerInfo));

    this.subscriptions.providerTier = this.manService
      .subscribeToSelectProviderTier((v: BenefitDetailsTier) => {
        this.employeeContribution = v.empContribution;
        this.employerContribution = v.erContribution;
      });

      this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(() => {
        this.manService.updateCanSaveStateByValidity(this.ngForm.valid);
      });
  }

  public onChangeContribution(): void {
    {
      if (this.benefitDetailsOptions.empContribution !== this.employeeContribution || this.benefitDetailsOptions.erContribution !== this.employerContribution) {
        this.benefitDetailsOptions.empContribution = this.employeeContribution || null;
        this.benefitDetailsOptions.erContribution = this.employerContribution || null;
        this.manService.updateFlatCoverageOptions(this.employeeContribution, this.employerContribution);
      }
    }
  }

  public ngOnDestroy(): void {

  }
}
