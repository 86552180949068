
import { IHeaderComp } from 'ag-grid-community/dist/lib/headerRendering/header/headerComp';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import {Component, ElementRef, OnDestroy,OnInit} from "@angular/core";
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { Aca1095cManagementService } from '../../../services';
import { unsubscribe, unsubscribeAll } from '../../../../core/decorators';
import { Aca1095c,acaQuickPopupState} from '../../../models';


@Component({
  selector: 'aca-1095c-grid-column-header',
  templateUrl: './aca-1095-c-grid-column-header.component.html',
  styleUrls: ['./aca-1095-c-grid-column-header.component.scss'],
})

export class Aca1095cGridColumnHeaderComponent implements OnInit, OnDestroy, IHeaderAngularComp {
    public params: MyParams;
     
       
    constructor(
      private aca1095cManagementService:Aca1095cManagementService
      ) {
        
    }

    agInit(params: MyParams): void {
        this.params = params;
    }

    public ngOnInit() {
      
    }
    ngOnDestroy() {
      
    }
    
    public getColumnName(name:string):string
    {
      if(_.isEqual(name,'All'))
      {
        return "";
      }
      else
      {
        return name;
      }
    }
    
    public onMonthLinkClick(event:any,action:boolean)
    {
      this.aca1095cManagementService.setQuickMonthAction({name:event.name,value:action});
    }
    

    refresh(params) : boolean {
        return true;
    }
}
interface MyParams extends IHeaderParams {
  link: any;
  name:string;  
}