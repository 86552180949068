import * as tslib_1 from "tslib";
import { PasswordValidatorAdapter } from './../../../../../authentication/services/password-reset/password-validator-adapter';
import { OnInit, OnDestroy, NgZone } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Assert } from '../../../../../framework/index';
import { EmployeeSectionsEssTemplate } from '../../../models/index';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { EmployeeSectionsEmploymentApiService } from '../../../services/index';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import { EmployeeSelfServiceApiService } from '../../../../employee/services/index';
import { ConfirmDialogComponent, InfoDialogComponent } from '../../../../../common/components/index';
import { ModalService } from '../../../../../common/index';
import { EssLookupManageService } from '../../../../../organization/services/lookup/ess-lookup-manage.service';
import { EssEntity, EssDefaultPassword } from '../../../../../organization/models/index';
import { EmployeeDefinitionsApiService } from '../../../../../organization/services/index';
var EmployeeSectionsEssTemplateComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsEssTemplateComponent, _super);
    function EmployeeSectionsEssTemplateComponent(ngZone, decorator, modalService, employeeSectionsEmploymentApiService, employeeSelfServiceApiService, essLookupService, employeeDefinitionApi, passwordValidator) {
        var _this = _super.call(this, decorator, ngZone) || this;
        _this.modalService = modalService;
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.employeeSelfServiceApiService = employeeSelfServiceApiService;
        _this.essLookupService = essLookupService;
        _this.employeeDefinitionApi = employeeDefinitionApi;
        _this.passwordValidator = passwordValidator;
        _this.customPassword = '';
        _this.m_employeeSectionsEssTemplate = new EmployeeSectionsEssTemplate();
        Assert.isNotNull(employeeSectionsEmploymentApiService, 'employeeSectionsEmploymentApiService');
        _this.employeeSectionsEmploymentApiService = employeeSectionsEmploymentApiService;
        _this.essLookupService.onLoaded$.subscribe(function (value) {
            _this.fillValues();
        });
        return _this;
    }
    Object.defineProperty(EmployeeSectionsEssTemplateComponent.prototype, "employeeSectionsEssTemplate", {
        get: function () {
            return this.m_employeeSectionsEssTemplate;
        },
        set: function (value) {
            this.m_employeeSectionsEssTemplate = value;
            this.fillValues();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsEssTemplateComponent.prototype, "form", {
        get: function () {
            return this.ngForm ? this.ngForm.form : null;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsEssTemplateComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    EmployeeSectionsEssTemplateComponent.prototype.getSubsectionModel = function () {
        return this.employeeSectionsEssTemplate;
    };
    Object.defineProperty(EmployeeSectionsEssTemplateComponent.prototype, "showPasswordField", {
        get: function () {
            return this.state && this.state.isEditMode && this.passwordType && this.passwordType.id === EssDefaultPassword.custom;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsEssTemplateComponent.prototype, "customPasswordType", {
        get: function () {
            return (!this.showPassword && this.passwordInFocus) ? 'password' : 'text';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsEssTemplateComponent.prototype, "passwordValidation", {
        get: function () {
            return PasswordValidatorAdapter.passwordValidation;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsEssTemplateComponent.prototype.canSave = function () {
        var _this = this;
        if (this.passwordType.id === EssDefaultPassword.noChange) {
            return Promise.resolve(true);
        }
        var promise = new Promise(function (resolve) {
            ConfirmDialogComponent.openDialog('Confirmation', 'Are you sure you want to override the existing password?', _this.modalService, function (confirmed) {
                resolve(confirmed ? _this.checkSsn() : false);
            });
        });
        return promise;
    };
    EmployeeSectionsEssTemplateComponent.prototype.togglePasswordVisibility = function () {
        this.showPassword = !this.showPassword;
    };
    EmployeeSectionsEssTemplateComponent.prototype.onCustomPasswordFocused = function () {
        this.passwordInFocus = true;
    };
    EmployeeSectionsEssTemplateComponent.prototype.loadSubsection = function () {
        var _this = this;
        this.startProgress();
        this.employeeSectionsEmploymentApiService.getEmployeeSectionsEssTemplate(this.employeeId)
            .then(function (employeeSectionsEssTemplate) {
            _this.employeeSectionsEssTemplate = employeeSectionsEssTemplate;
            _this.stopProgress();
        })
            .catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsEssTemplateComponent.prototype.doSave = function () {
        var _this = this;
        var promise;
        promise = this.employeeSelfServiceApiService.setEssPasswordState([this.employeeId], this.passwordType.id, this.employeeSectionsEssTemplate.passwordShouldBeReseted, this.passwordType.id === EssDefaultPassword.custom ? this.customPassword : null);
        promise.then(function (response) {
            _this.onActionComplete(true);
        }).catch(function (reason) {
            _this.onActionError(reason);
        });
    };
    EmployeeSectionsEssTemplateComponent.prototype.fillValues = function () {
        this.passwordType = new EssEntity();
        this.passwordType.id = EssDefaultPassword.noChange;
        this.passwordType.name = 'No Changes';
        this.showPassword = false;
        this.passwordInFocus = false;
        this.customPassword = '';
    };
    EmployeeSectionsEssTemplateComponent.prototype.checkSsn = function () {
        var _this = this;
        if (this.passwordType.id === EssDefaultPassword.last4SSN) {
            return this.employeeDefinitionApi.getEmployeeShortInfo(this.employeeId)
                .then(function (value) {
                if (value.ssn) {
                    return Promise.resolve(true);
                }
                else {
                    InfoDialogComponent.OpenDialog('Create ESS account', 'Can set password by selected default password type, when employee doesn\'t have SSN', _this.modalService);
                    return Promise.resolve(false);
                }
            }).catch(function (reason) {
                return Promise.resolve(false);
            });
        }
        return Promise.resolve(true);
    };
    return EmployeeSectionsEssTemplateComponent;
}(EmployeeSectionsBasicComponent));
export { EmployeeSectionsEssTemplateComponent };
