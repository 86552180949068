import * as tslib_1 from "tslib";
import * as _ from "lodash";
import { AccrualPolicyDialogInfo } from "../../models/accruals-policy/accrual-policy-dialog-info";
import { AccrualPolicyDialogHelper } from "../../components/accruals-policy/accruals-policy-dialog-helper";
import { ModalService } from "./../../../../../app/common";
import { AccrualsPolicyMapService } from "./accruals-policy-map.service";
import { AccrualsPolicyApiService } from "./accruals-policy-api.service";
import { AccrualOrgLevels } from "../../models/accruals-policy/copy-accruals-policy";
var AccrualPolicyManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(AccrualPolicyManagementService, _super);
    function AccrualPolicyManagementService(modalService, mapService, apiService) {
        var _this = _super.call(this, modalService, mapService, apiService) || this;
        _this.apiService = apiService;
        _this.errorMessage = "";
        _this.isShow = false;
        _this.accrualPolicies = [];
        _this.m_minimumDate = new Date("1/1/1900");
        _this.m_maximumDate = new Date("6/6/2079");
        _this.menuBtnClick = false;
        _this.accrualOrgLevelData = [];
        _this.dialogInfo = AccrualPolicyDialogInfo;
        return _this;
    }
    AccrualPolicyManagementService.prototype.minStartDate = function () {
        return this.m_minimumDate;
    };
    AccrualPolicyManagementService.prototype.onInputChange = function (e, accrualPolicyFormGroup, index, srIndex) {
        var rulesFormArray = accrualPolicyFormGroup.controls['rules'];
        var seniorityRulesFormGroup = rulesFormArray.controls[index];
        var data = seniorityRulesFormGroup.controls.seniorityRules;
        if (data.value.length - 2 >= 0) {
            if ((e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Days")
                || (e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Weeks")
                || (e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Months")
                || (e > data.value[data.value.length - 2].periodToValue && data.value[data.value.length - 2].periodFromType == "Years")) {
                this.errorMessage = "";
            }
            else {
                this.errorMessage = "The value cannot be equal or less than " + data.value[data.value.length - 2].periodToValue;
            }
        }
        else {
            console.log("it has only one row");
        }
    };
    AccrualPolicyManagementService.prototype.dropDownOpen = function (child) {
        this.isShow = !this.isShow;
        child.get('isBenefitViewMode').setValue(false);
        this.isDisabled = true;
    };
    AccrualPolicyManagementService.prototype.dropDownClose = function (child) {
        this.isShow = !this.isShow;
        child.get('isBenefitViewMode').setValue(true);
        this.isDisabled = false;
        this.data = [];
    };
    AccrualPolicyManagementService.prototype.setRequiredValues = function (policyForm, rulesForm) {
        if (rulesForm.get('accrualCalcType').value == 1) {
            rulesForm.get('accrualCalcTypeName').setValue('Periods');
            rulesForm.get('rdoAccrualsTypePeriod').setValue(1);
            rulesForm.get('rdoAccrualsTypeDates').setValue(0);
            this.rdoAccrualsTypePeriod = 1;
            this.rdoAccrualsTypeDates = 0;
        }
        else {
            rulesForm.get('accrualCalcTypeName').setValue('Specific Dates');
            rulesForm.get('rdoAccrualsTypePeriod').setValue(0);
            rulesForm.get('rdoAccrualsTypeDates').setValue(1);
            this.rdoAccrualsTypeDates = 1;
            this.rdoAccrualsTypePeriod = 0;
        }
    };
    AccrualPolicyManagementService.prototype.getLastIndex = function (ruleFormGroup, seniorityRuleIndex) {
        return ruleFormGroup.get('seniorityRules').length - 1 == seniorityRuleIndex;
    };
    AccrualPolicyManagementService.prototype.isView = function (i, si) {
        var _this = this;
        this.menuBtnClick = true;
        var data = this.dialogInfo.dialogInfoData;
        data.find(function (x) {
            if (x.id == i) {
                _this.isViewed = x.id;
                if (si != null) {
                    _this.isViewedSrIndex = si; //to handle multiple info dialog opening when multiple records were available in Seniority Grid
                }
                _this.title = x.title;
                _this.text = x.description;
                _this.text1 = x.description2;
            }
        });
        this.isActive = !this.isActive;
    };
    Object.defineProperty(AccrualPolicyManagementService.prototype, "getRate", {
        get: function () {
            var data = this.dialogInfo.dialogInfoData;
            var rateInfo;
            data.find(function (x) {
                if (x.id == 12) {
                    rateInfo = x.title + x.description + x.description2;
                }
            });
            return rateInfo;
        },
        enumerable: true,
        configurable: true
    });
    AccrualPolicyManagementService.prototype.noWhitespaceValidator = function (control) {
        var isWhitespace = (control.value || '').trim().length === 0;
        var isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    };
    AccrualPolicyManagementService.prototype.policyNameValidator = function (control) {
        var policyName = localStorage.getItem("Policy Names");
        var policyNameData = JSON.parse(policyName);
        var name = control.value;
        if (name && policyNameData.includes(name)) {
            return {
                duplicateName: {
                    name: name
                }
            };
        }
        return null;
    };
    AccrualPolicyManagementService.prototype.isStartDateEmpty = function (d) {
        return d == '' || null ? true : false;
    };
    AccrualPolicyManagementService.prototype.isEndDateEmpty = function (d) {
        return d == '' || null ? true : false;
    };
    AccrualPolicyManagementService.prototype.isInputActive = function (e) {
        return _.isFinite(e);
    };
    AccrualPolicyManagementService.prototype.maxDate = function () {
        return this.m_maximumDate;
    };
    AccrualPolicyManagementService.prototype.copyPolicyDialog = function (orgLevelName, orgLevelId) {
        this.accrualOrgLevelData = this.apiService.getOrganizationNames(orgLevelId);
        this.accrualOrgLevelData.length > 0 && this.copyPolicy(this.accrualPoliciesData, orgLevelName, this.accrualOrgLevelData, orgLevelId);
    };
    return AccrualPolicyManagementService;
}(AccrualPolicyDialogHelper));
export { AccrualPolicyManagementService };
