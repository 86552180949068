export class PartnerClientStatusData {
    public  version: string;
    public  status: string ;
    public  partnerName: string;
    public  initiateUrl: string ;
    public  authorizedDate: Date ;

}

export interface IPartnerClientStatusData {
      version: string;
      status: string ;
      partnerName: string;
      initiateUrl: string ;
      authorizedDate: Date ;
}