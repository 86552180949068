import { BudgetedGroup, IBudgetedGroup } from './budgeted-group';

export interface IBudgetedPosition {
  id: number;
  description: string;
}

export class BudgetedPosition {
  public id: number;
  public description: string;
}
