<section class="pay-codes">
  <header class="pay-codes__header">
    <p class="pay-codes__th code">Code</p>
    <p class="pay-codes__th pos">Position</p>
    <p class="pay-codes__th hours">Hrs/Amt</p>
  </header>
  <div class="pay-codes__body">
    <div *ngFor="let rule of gridData" class="pay-codes__row">
        <p class="pay-codes__td code">
          <i class="pay-codes__border" [style.border-color]="getColor(rule.color)" aria-hidden="true"></i>
          {{rule.description}}
        </p>
        <p class="pay-codes__td pos">{{payCodeModel.positionName}}</p>
        <p class="pay-codes__td hours">
          <ng-container *ngIf="!payCodeModel.isMoneyRule(rule.name)">{{ payCodeModel.getRuleValue(rule.name) | number: ".2-2" }}</ng-container>
          <ng-container *ngIf="payCodeModel.isMoneyRule(rule.name)">{{ payCodeModel.getRuleValue(rule.name) | number: ".2-2" | slxMoney }}</ng-container>
        </p>
    </div>
  </div>
</section>
