import * as tslib_1 from "tslib";
import { Assert } from '../../../../framework';
import { ApiUtilService, UrlParamsService } from '../../../../common/services';
import { employeeListConfig } from '../../employee-list.config';
import { HttpRequest } from '@angular/common/http';
var SlateProfileService = /** @class */ (function () {
    function SlateProfileService(apiUtilService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
    }
    SlateProfileService.prototype.assignProfile = function (employeeIds, profileId, orgLevelId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, body, request, promise;
            return tslib_1.__generator(this, function (_a) {
                Assert.isNotNull(employeeIds);
                Assert.isNotNull(profileId);
                url = this.apiUtilService.getApiRoot() + "/" + employeeListConfig.api.employees.root + "/" + employeeListConfig.api.employees.actions.root + "/" + employeeListConfig.api.employees.actions.group.assignSlateProfile;
                body = { employeeIds: employeeIds, profileId: profileId };
                request = new HttpRequest('POST', url, body, { params: this.urlParamsService.convertToParams({ orgLevelId: orgLevelId }) });
                promise = this.apiUtilService.request(request);
                return [2 /*return*/, promise];
            });
        });
    };
    return SlateProfileService;
}());
export { SlateProfileService };
