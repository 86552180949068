<slx-spinner [show]="srtManService.employeeLoader">
    <div class="container">
        <div class="grid-tool-bar-container">
            <div>
                <button (click)="addNewRotation()" *ngIf="!srtManService.isAddNewRotationDisable"
                    class="rotation-enabled">
                    <span>
                        <i class="fal fa-plus-square"></i>
                    </span>
                    <span *ngIf="!srtManService.isAddNewRotationDisable" class="slx-added-rotation">
                        Add New Rotation
                    </span>
                </button>
                <button *ngIf="srtManService.isAddNewRotationDisable"
                    class="rotation-disabled">
                    <span>
                        <i class="fal fa-plus-square"></i>
                    </span>
                    <span tipClass="slx-new-rotation-tooltip" slxTooltip="Please add shifts to the Empty Rotation and click save before adding another new rotation.">
                        Add New Rotation
                    </span>
                </button>
                <button style="margin-left: 10px; width: 140px" type="button" [popper]="filterRotationToggler"
                    [popperTrigger]="'click'" [popperPlacement]="'bottom-start'" [popperDisableStyle]="'true'"
                    [popperHideOnClickOutside]="false">
                    <span>
                        <i class="fal fa-cog"></i>
                    </span>
                    Filter Staff
                </button>
                <!-- <span *ngIf="filterOptions.gridFilterOption.isSelected" class="blink_me">Filter To Grid Option Enabled</span> -->
            </div>
            <div *ngIf="isViewWeekDate" [ngClass]="{'srt-weeks': count != 1}">
                <span class="week-span-2">
                    <p class="current-week-label" *ngIf="count == 1">Current Week: </p>
                    <p class="week-date">{{dateRangeOfCurrentWeek}}</p>
                </span>
            </div>
            <div *ngIf="!isViewWeekDate" class="srt-weeks-label">
                <span class="week-span-2">
                    <p class="current-week-label">Rotation Week {{count}} </p>
                </span>
            </div>
            <div>
                <span [ngClass]="isEditView ? 'disabled' : 'enabled'" [ngStyle]="{'background-color': count === 1 ? '#8f9096' : '#0092cb',
                                 'cursor': (count === 1 || isEditView) ? 'not-allowed' : 'pointer',
                                 'pointer-events': (count === 1 || isEditView) ? 'none' : 'all'}" class="week-span-1"
                    (click)="weekCountIncrement(false)">
                    <i class="fas fa-chevron-left"></i>
                </span>
                <span [ngClass]="isEditView ? 'disabled' : 'enabled'" [ngStyle]="{'background-color': count === 4 ? '#8f9096' : '#0092cb',
                            'cursor': (count === 4 || isEditView) ? 'not-allowed' : 'pointer',
                            'pointer-events': (count === 4 || isEditView) ? 'none' : 'all'}" class="week-span-3"
                    (click)="weekCountIncrement(true)">
                    <i class="fas fa-chevron-right"></i>
                </span>
            </div>
        </div>
        <div class="grid-container">
            <kendo-grid #kendoGrid [ngStyle]="{'height.px': isExpand ? expandedPageHeight : collapsedPageHeight}"
                class="employeeGrid" [kendoGridBinding]="employeeList" [sortable]="true" [filterable]="'menu'"
                [filter]="myFilters">
                <kendo-grid-column field="name" title="Name" filter="text" width="180">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <!-- <span *ngIf="dataItem.employeeId == 0" class="slx-view-schedule-icon-black"
                            (click)="srtManService.deleteEmptySlot(dataItem)">
                            <i class="far fa-minus"></i>
                        </span> -->
                        <div [class.active]="dataItem.isDifferentDepartment" *ngIf="dataItem.employeeId != 0"
                            style="display: flex; flex-direction: column; position: absolute; top: 0;">
                            <p class="diff-dept-details" *ngIf="dataItem.isDifferentDepartment">Different Dept.</p>
                            <a title="{{dataItem.name}}" [employeeSectionTabLink]="dataItem.employeeId"
                                empSectionTab="Schedule" class="employee-name">
                                {{dataItem.name | ellipsis:20}}
                            </a>
                            <p title="{{dataItem.positionName}}" class="employee-details">{{dataItem.positionName |
                                ellipsis:20}}
                            </p>
                            <p class="employee-details">Type: {{dataItem.employeeType}}
                            </p>
                            <p title="{{dataItem.shiftName}}" class="employee-details" *ngIf="dataItem.shiftId !== 0">Home Shift:
                                {{dataItem.shiftName | ellipsis:13}}</p>
                            <span *ngIf="dataItem.employeeId != 0" class="slx-view-schedule-icon"
                                (click)="viewIndividualSchedule(dataItem)">
                                <i class="far fa-tv"></i>
                            </span>
                        </div>
                        <div class="grid-rotation-add" *ngIf="dataItem.employeeId === 0">
                            <slx-add-emp-position-grid *ngIf="updatedNoEmpRotationList" [employeeList]="employeeList" [employeeData]="dataItem"
                                [noRotationEmployeeList]="updatedNoEmpRotationList" (onSaveEmp)="getSelectedEmp($event)"
                                (onSavePos)="setSelectedEmpPos($event)"
                                (onDeleteRotations)="deleteEmptyRotations($event)"></slx-add-emp-position-grid>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [title]="dateName(date)" *ngFor="let date of weekOfDays">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"
                        let-columnIndex="columnIndex">
                        <div #shiftListId id="{{dataItem.employeeId}}{{rowIndex}}{{columnIndex}}" class="shift-list"
                            [ngStyle]="{'overflow-y': isEditView ? 'hidden': 'auto'}"
                            (drop)="drop($event,dataItem,date)" (dragover)="allowDrop($event,dataItem,date)">
                            <div class="shift-primary-block-2"
                                *ngFor="let scheduled of getEmployeeAssignedShifts(dataItem,date); let index = index">
                                <div *ngIf="scheduled.isView">
                                    <div [class.shiftHidden]="scheduled.isJobCodeHidden" [ngStyle]="{'border': (!scheduled.isWhiteColor && dataItem.employeeId !== 0) ? '2px solid #736aff': '2px solid #8fad15',
                                                   'pointer-events': isEditView ? 'none' : 'all',
                                                   'background-color': isEditView ? '#dddd' : (!scheduled.isWhiteColor && dataItem.employeeId !== 0) ? '#f8f0ff' : '#f8f8f8',
                                                   'cursor': !scheduled.isWhiteColor && scheduled.isLock ? 'not-allowed' : 'default'}"
                                        [ngClass]="!scheduled.isWhiteColor ? 'srt-secondary-position' : 'shift-primary-block-3'"
                                        (click)="setEdit(false, scheduled, dataItem, date)">
                                        <div class="shift-primary-block-4">
                                            <span title="{{scheduled.positionName}}">{{scheduled.positionName |
                                                ellipsis:20}}
                                                <i class="fa fa-users" title="Employee's position is replacing this position on the schedule based on the Position Grouping"
                                                 *ngIf="scheduled.isPositionGroupedShift"></i></span>
                                            <span><span *ngIf="scheduled.isLock">
                                                    <i class="fa fa-lock"></i>
                                                </span></span>
                                        </div>
                                        <div class="shift-primary-block-4">
                                            <span>{{scheduled.unitName}}</span>
                                            <span title="{{scheduled.shiftName}}">{{scheduled.shiftName | ellipsis: 13}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!scheduled.isView && !dataItem.isOverTime" class="shift-primary-block-6">
                                    <div class="shift-primary-block-7">
                                        <select [ngClass]="scheduled.isAssignedShift ? 'disabled' : 'enabled'"
                                            class="slx-srt-wide" (change)="repeatChange($event, dataItem, scheduled)">
                                            <option *ngFor="let item of srtManService.shiftRotationDropDown;"
                                                [selected]="scheduled.frequency === item.id" value="{{item.id}}">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <span class="srt-save-delete-shift">
                                            <i class="fas fa-save"
                                                (click)="setEdit(true, scheduled, dataItem, date)"></i>
                                            <i class="far fa-minus-square"
                                                (click)="removeShift(scheduled,dataItem,date)"></i>
                                        </span>
                                    </div>
                                </div>
                                <div *ngIf="!scheduled.isView && dataItem.isOverTime" class="shift-overTime-block-6">
                                    <div class="shift-overTime-block-7">
                                        <span *ngIf="dataItem.isOverTime && !overTimeProceed">
                                            <p>Overtime</p>
                                            <span class="srt-save-delete-shift">
                                                <i class="fas fa-plus-circle" (click)="isOverTimeProceed(true)"></i>
                                                <i class="far fa-minus-square"
                                                    (click)="removeShift(scheduled,dataItem,date)"></i>
                                            </span>
                                        </span>
                                        <span *ngIf="overTimeProceed">
                                            <select class="slx-srt-wide"
                                                (change)="repeatChange($event, dataItem, scheduled, date)">
                                                <option *ngFor="let item of srtManService.shiftRotationDropDown;"
                                                    [selected]="scheduled.frequency === item.id" value="{{item.id}}">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                            <span class="srt-save-delete-shift">
                                                <i class="fas fa-save"
                                                    (click)="setEdit(true, scheduled, dataItem, date)"></i>
                                                <i class="far fa-minus-square"
                                                    (click)="removeShift(scheduled,dataItem,date)"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isOverLap(dataItem,date)" class="srt-ban-custom">
                                <i class="fa fa-ban srt-ban-custom-icon" aria-hidden="true"></i>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <ng-template kendoGridDetailTemplate let-dataItem>
                    <span>
                        <h4 style="color: #337ab7;text-align: center;">Total Hours Scheduled: {{dataItem.totalHours
                            ?
                            dataItem.totalHours : 0.00}}</h4>
                    </span>
                </ng-template>
            </kendo-grid>
        </div>
    </div>
</slx-spinner>

<kendo-excelexport [data]="exportEmployeeData" [collapsible]="true" fileName="EmployeeRotation.xlsx" #excelexport>
    <kendo-excelexport-column field="EmployeeName" [locked]="true" title="Employee Name" [width]="200">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="PositionGroup" title="Position Group" [width]="250">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="Position" title="Position" [width]="250">
    </kendo-excelexport-column>
    <kendo-excelexport-column field="HomeShift" title="Home Shift" [width]="100">
    </kendo-excelexport-column>
    <ng-container *ngFor="let date of srtManService.dates">
        <kendo-excelexport-column [field]="date.dayName" [title]="date.dayName" [width]="150">
        </kendo-excelexport-column>
    </ng-container>
</kendo-excelexport>

<popper-content style="background-color: white;" #filterRotationToggler>
    <div class="filter-rotation-settings">
        <div class="filter-container">
            <p class="filter-rotation-title">Show Staff by:</p>
            <div *ngFor="let staff of filterOptions.showStaff">
                <input type="checkbox" class="form-check-input" id="staff.id" [checked]="staff.isSelected"
                    (change)="checkedChange(filterOptions.showStaff, staff.name, $event.target.checked)">
                <p class="filter-label" for="staff.id">{{staff.name}}</p>
            </div>
        </div>
        <div class="settings-buttons">
            <button #filterRotationSettingsApply class="settings-apply-button theme-button-apply margin-r" type="button"
                (click)="onSettingsChanged(filterRotationToggler, true)">Apply</button>
            <button #filterRotationSettingsClose class="settings-cancel-button theme-button-cancel" type="button"
                (click)="onSettingsChanged(filterRotationToggler, false)">Close</button>
        </div>
    </div>
</popper-content>