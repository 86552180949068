import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { EmployeeToTerminate } from '../../../../models/index';
import { ChangeTerminationDateComponent } from '../change-termination-date/change-termination-date.component';
var ChangeTerminationDateDialogComponent = /** @class */ (function () {
    function ChangeTerminationDateDialogComponent(options, modalService, employee) {
        this.options = options;
        this.modalService = modalService;
        this.dialogResult = false;
        this.employee = employee;
        this.state = {
            isLoading: false,
        };
    }
    ChangeTerminationDateDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = request && request.isNGPUser ? 300 : 200;
        dialogOptions.width = 450;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: EmployeeToTerminate,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(ChangeTerminationDateDialogComponent, 'Change Employee Termination Date', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.requestToTerminate);
        });
        return dialog;
    };
    Object.defineProperty(ChangeTerminationDateDialogComponent.prototype, "requestToTerminate", {
        get: function () {
            return this.changeTerminationDate.requestToTerminate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeTerminationDateDialogComponent.prototype, "isemployeeTerminationFormValid", {
        get: function () {
            if (this.changeTerminationDate && this.changeTerminationDate.ngForm) {
                return this.changeTerminationDate.ngForm.valid;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    ChangeTerminationDateDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ChangeTerminationDateDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return ChangeTerminationDateDialogComponent;
}());
export { ChangeTerminationDateDialogComponent };
