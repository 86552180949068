import * as tslib_1 from "tslib";
import { BaseSession } from '../../authentication/models/index';
var Session = /** @class */ (function (_super) {
    tslib_1.__extends(Session, _super);
    function Session(token) {
        return _super.call(this, token) || this;
    }
    return Session;
}(BaseSession));
export { Session };
