export interface IFileProxyConfig {
    api: {
      root: string;
    };
    fileSizeForProxy: number;
}
export const fileProxyConfig: IFileProxyConfig = {
    api: {
      root: 'file/data'
    },
    // 1MB
    fileSizeForProxy: 1000000
};
