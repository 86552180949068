import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ExportDataManagementService } from '../../services/export-data-management.service';
import { ExportDataStatus } from '../../enums/export-data-status';
import { StateManagementService } from '../../../../../app/common';
import { unsubscribeAll } from '../../../../core/decorators';
var ExportDataComponent = /** @class */ (function () {
    function ExportDataComponent(stateManagement, manService, changeDetector) {
        this.stateManagement = stateManagement;
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.isLoading = false;
        this.subscriptions = {};
    }
    Object.defineProperty(ExportDataComponent.prototype, "exportDataStatus", {
        get: function () { return ExportDataStatus; },
        enumerable: true,
        configurable: true
    });
    ExportDataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('ExportDataComponent');
        this.subscriptions.loading = this.manService.subscribeToLoading(function (data) {
            _this.isLoading = data;
        });
        this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel(function () {
            _this.exportConfigurations = [];
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
            _this.manService.loadExportDataConfiguration();
        });
        this.subscriptions.loadedConfigurtion = this.manService.subscribeToLoadedConfigurations(function (data) {
            _this.exportConfigurations = data;
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.manService.init();
    };
    ExportDataComponent.prototype.ngOnDestroy = function () {
        this.exportConfigurations = null;
        this.changeDetector.detach();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ExportDataComponent.prototype, "subscriptions", void 0);
    return ExportDataComponent;
}());
export { ExportDataComponent };
