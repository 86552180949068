import { ColorUtil } from './../../../../../../common/utils/color-util';
import { Component, Input } from '@angular/core';
import { EmployeeSectionsAttendanceDay, IAbsence, EmployeeSectionsAttendance } from '../../../../models/index';
import * as moment from 'moment';

@Component({
    moduleId: module.id,
    selector: 'slx-employee-sections-attendance-day',
    templateUrl: 'employee-sections-attendance-day.component.html',
    styleUrls: ['employee-sections-attendance-day.component.scss']
})
export class EmployeeSectionsAttendanceDayComponent {
    @Input()
    public set attendanceSection(value: EmployeeSectionsAttendance) {
        this.m_attendanceSection = value;
        this.recalculateData();
    }
    public get attendanceSection(): EmployeeSectionsAttendance {
        return this.m_attendanceSection;
    }
    @Input()
    public set selectedYear(value: number) {
        this.m_selectedYear = value;
        this.recalculateData();
    }
    public get selectedYear(): number {
        return this.m_selectedYear;
    }
    @Input()
    public set month(value: string) {
        this.m_month = value;
        this.recalculateData();
    }
    public get month(): string {
        return this.m_month;
    }
    @Input()
    public set day(value: number) {
        this.m_day = value;
        this.recalculateData();
    }
    public get day(): number {
        return this.m_day;
    }
    @Input()
    public set prefix(value: string) {
        this.m_prefix = value;
        this.recalculateData();
    }
    public get prefix(): string {
        return this.m_prefix;
    }

    public isWorkday: boolean;
    public cellPlannerIndicator: string;
    public cellColor: any;
    public cellRenderer: string;

    private m_attendanceSection: EmployeeSectionsAttendance;
    private m_selectedYear: number;
    private m_month: string;
    private m_day: number;
    private m_prefix: string;
    private stringDate: string;
    private aDay: EmployeeSectionsAttendanceDay;
    private isHoliday: boolean;

    private calcCellRenderer(): string {
        let className: string;
        if (this.isHoliday) {
          className = 'square';
        } else {
          className = 'circle';
        }
        if (this.aDay && this.aDay.getMarkedDay(this.prefix)) className += ' work-offset';
        return className;
    }

    private calcCellColor(): any {
        if (!this.aDay) return '';
        let style: any = {'background-color': '#FFFFFF'};
        if (this.isHoliday) {
            style['background-color'] = '#CCCCCC';
        }
        let absence: IAbsence = this.aDay.getAbsence(this.prefix);
        if (absence) {
            style['background-color'] = '#' + this.displayInHex(absence.color);
        }
        return style;
    }

    private calcCellPlannerIndicator(): string {
        if (!this.aDay) return '';

        let absence: IAbsence = this.aDay.getAbsence(this.prefix);
        if (absence && absence.code) {
            return absence.code.charAt(0);
        }
        return '';
    }

    private calcWorkday(): boolean {
        return this.aDay && this.aDay.getMarkedDay(this.prefix);
    }

    private recalculateData(): void {
        if (!this.attendanceSection || !this.selectedYear || !this.month || !this.day || !this.prefix) return;
        this.cellColor = {'background-color': '#FFFFFF'};

        if (this.calcDateParams()) {
            this.isWorkday = this.calcWorkday();
            this.cellPlannerIndicator = this.calcCellPlannerIndicator();
            this.cellColor = this.calcCellColor();
            this.cellRenderer = this.calcCellRenderer();
        }
    }

    private displayInHex(c: number): string {
        return ColorUtil.DecToHexString(c);
    }

    private calcDateParams(): boolean {
        let dt: string = this.selectedYear + '-' + this.month + '-' + this.day.toString();
        let m: moment.Moment = moment(dt, 'YYYY-MMM-D', true);
        if (m.isValid()) {
            let dow: number = m.day();
            this.isHoliday = dow > 5 || dow === 0;
            this.aDay = this.getAttendanceDay(m);
            return true;
        }
        return false;
    }

    private getAttendanceDay(date: moment.Moment): EmployeeSectionsAttendanceDay {
        let dayOfTheYear: number = parseInt(date.format('DDD'), 10) - 1;
        if (this.attendanceSection.dates[dayOfTheYear] !== undefined) {
            return this.attendanceSection.dates[dayOfTheYear];
        }
        return null;
    }

}
