import { AfterTestActionCodes } from './after-test-action-codes';
export interface IPayrollExportConfig {
    id: number;
    configId: number;
    testId: number;
    testName: string;
    code: number;
}

export class PayrollExportConfig {
    public id: number;
    public configId: number;
    public testId: number;
    public testName: string;
    public code: AfterTestActionCodes;
}
