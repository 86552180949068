import * as _ from 'lodash';
import { process } from '@progress/kendo-data-query';
var ConfigurationComponentHelper = /** @class */ (function () {
    function ConfigurationComponentHelper() {
    }
    Object.defineProperty(ConfigurationComponentHelper.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        set: function (value) {
            this.m_container = value;
            this.refreshGrid();
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationComponentHelper.prototype.init = function () {
        /*
        if (!this.grid) {
            throw new Error('CrudGridHelper requires kendo grid link');
        }
        */
        var _this = this;
        if (!this.management) {
            throw new Error('CrudGridHelper requires management service instance of ICrudService');
        }
        if (!this.gridState) {
            throw new Error('CrudHelper requires instance of KendoGridStateHelper');
        }
        if (!this.selectionHelper) {
            throw new Error('CrudHelper requires instance of KendoGridCustomSelectionHelper');
        }
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (state) {
            _this.refreshGrid();
        });
        this.gridOnSaveScubScription = this.gridState.onSave$.subscribe(function (result) {
            _this.onSaveItem(result);
        });
        this.gridOnEditScubScription = this.gridState.onEdit$.subscribe(function (dataItem) {
            _this.management.setSelectedCount(0);
            _this.onEditItem(dataItem);
        });
        this.gridOnCancelScubScription = this.gridState.onCancel$.subscribe(function (event) {
            _this.onCancelItem(event);
        });
        this.gridOnRemoveScubScription = this.gridState.onRemove$.subscribe(function (event) {
            _this.onRemoveItem(event.dataItem);
        });
        this.managementLoadedSubscription = this.management.onLoaded$.subscribe(function (container) {
            _this.m_container = container;
            _this.gridState.state.filter = null;
            if (_this.grid) {
                _this.gridState.closeEditor(_this.grid);
            }
            _this.refreshGrid();
            _this.selectionHelper.clearSelection();
            _this.management.setSelectedCount(0);
        });
        this.managementRefreshSubscription = this.management.viewRefresh$.subscribe(function () {
            _this.refreshGrid();
        });
        this.managementStateChangedSubscription = this.management.onStateChanged$.subscribe(function (data) {
            if (_.has(data, 'orgLevelChanged')) {
                if (_this.grid) {
                    _this.gridState.closeEditor(_this.grid);
                }
                else {
                    _this.onCancelItem(null);
                }
            }
        });
        this.managementAddSubscription = this.management.addItemCmd$.subscribe(function (item) {
            if (_this.grid)
                _this.gridState.closeEditor(_this.grid);
            if (_this.grid)
                _this.grid.addRow(item);
            _this.management.setSelectedCount(0);
        });
    };
    ConfigurationComponentHelper.prototype.destroy = function () {
        if (this.gridRefreshSubscription)
            this.gridRefreshSubscription.unsubscribe();
        if (this.gridOnSaveScubScription)
            this.gridOnSaveScubScription.unsubscribe();
        if (this.gridOnEditScubScription)
            this.gridOnEditScubScription.unsubscribe();
        if (this.gridOnCancelScubScription)
            this.gridOnCancelScubScription.unsubscribe();
        if (this.gridOnRemoveScubScription)
            this.gridOnRemoveScubScription.unsubscribe();
        if (this.managementLoadedSubscription)
            this.managementLoadedSubscription.unsubscribe();
        if (this.managementRefreshSubscription)
            this.managementRefreshSubscription.unsubscribe();
        if (this.managementAddSubscription)
            this.managementAddSubscription.unsubscribe();
        if (this.managementStateChangedSubscription)
            this.managementStateChangedSubscription.unsubscribe();
    };
    // -- api methods
    ConfigurationComponentHelper.prototype.addItem = function (newItem) {
        this.management.onAddItem(newItem);
    };
    ConfigurationComponentHelper.prototype.deleteSelected = function () {
        if (this.selectionHelper.singleItemMode) {
            if (this.selectionHelper.selectionLength === 1) {
                var model = this.selectionHelper.getSelectedItemAt(0);
                this.onRemoveItem(model);
            }
        }
        else {
            this.onRemoveItem(this.selectionHelper.selection);
        }
    };
    ConfigurationComponentHelper.prototype.onToggleAllSelected = function () {
        this.selectionHelper.onToggleAllSelected();
        this.onSelectionChange(this.selectionHelper.selectionLength);
    };
    ConfigurationComponentHelper.prototype.selectionChange = function (item, index) {
        this.selectionHelper.onItemSelectionChange(item);
        this.onSelectionChange(this.selectionHelper.selectionLength);
    };
    ConfigurationComponentHelper.prototype.startEdit = function (item, rowIndex) {
        if (this.grid)
            this.gridState.editHandler({ sender: this.grid, dataItem: item, rowIndex: rowIndex });
    };
    ConfigurationComponentHelper.prototype.completeEdit = function (item, rowIndex) {
        if (this.grid)
            this.gridState.saveHandler({ sender: this.grid, rowIndex: rowIndex, dataItem: item, isNew: this.management.isEditingNewItem });
    };
    ConfigurationComponentHelper.prototype.cancelEdit = function (rowIndex) {
        if (this.grid) {
            this.gridState.cancelHandler({ sender: this.grid, rowIndex: rowIndex });
        }
        else {
            this.onCancelItem(null);
        }
    };
    //--- handlers ---
    ConfigurationComponentHelper.prototype.onSaveItem = function (result) {
        this.management.onSaveItem(result);
    };
    ConfigurationComponentHelper.prototype.onEditItem = function (dataItem) {
        if (this.gridState.editedRecord) {
            this.management.onCancelEditItem();
        }
        this.lastEditedRowIndex = this.gridState.editedRowIndex;
        this.selectionHelper.clearSelection();
        this.management.onEditItem(dataItem);
    };
    ConfigurationComponentHelper.prototype.onCancelItem = function (event) {
        this.management.onCancelEditItem();
        if (this.gridState) {
            this.gridState.editedRowIndex = undefined;
            this.gridState.editedRecord = undefined;
        }
    };
    ConfigurationComponentHelper.prototype.onRemoveItem = function (dataItem) {
        this.management.onRemoveItem(dataItem);
    };
    ConfigurationComponentHelper.prototype.onSelectionChange = function (count) {
        if (this.grid && !this.gridState.editedRecord && !this.management.editingItem) {
            this.management.setSelectedCount(count);
        }
    };
    ConfigurationComponentHelper.prototype.refreshGrid = function () {
        if (!this.m_container || !this.m_container.records) {
            this.gridState.view = null;
            return;
        }
        this.gridState.view = process(this.m_container.records, this.gridState.state);
        this.selectionHelper.view = this.gridState.view;
    };
    return ConfigurationComponentHelper;
}());
export { ConfigurationComponentHelper };
