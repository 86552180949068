import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import { select } from '@angular-redux/store';
import { appConfig } from '../../../../app.config';
import { unsubscribe, mutableSelect, unsubscribeAll } from '../../../../core/decorators/index';
import { IndSchEmployeeSchedulesRequest } from '../../../models/index';
import { IndividualScheduleApiService, IndividualScheduleManagementService, IndividualScheduleEmpManagementService } from '../../../services/index';
import { LookupApiService, LookupService } from '../../../../organization/services/index';
import { StateManagementService } from '../../../../common/services/state-management/state-management.service';
import { PayCycleEmployeeProvider } from '../../../services/index';
import { EmployeeSearchBase } from '../../../../employee/employee/services/index';
var IndividualScheduleEmployeeListComponent = /** @class */ (function () {
    function IndividualScheduleEmployeeListComponent(individualScheduleApiService, individualScheduleManagementService, lookupApiService, lookupService, individualScheduleEmpManagementService, stateManagement, employeeSearchBase) {
        var _this = this;
        this.individualScheduleEmpManagementService = individualScheduleEmpManagementService;
        this.stateManagement = stateManagement;
        this.employeeSearchBase = employeeSearchBase;
        this.filterStringControlKey = 'empFilterString';
        this.scheduleCycleControlKey = 'scheduleCycle';
        this.showLColumn = true;
        this.subscriptions = {};
        this.individualScheduleApiService = individualScheduleApiService;
        this.individualScheduleManagementService = individualScheduleManagementService;
        this.lookupApiService = lookupApiService;
        this.lookupService = lookupService;
        this.state = {
            isLoading: false
        };
        this.employeeSubscription = this.individualScheduleManagementService.onSelectionChanged$.subscribe(function (emp) {
            _this.selectedEmployee = emp;
            _this.emitRequest(emp);
        });
        this.employeeUpdateSubscription = this.individualScheduleEmpManagementService.onEmployeeUpdated$.subscribe(function (value) {
            _this.loadOrgLevelEmployees();
        });
        this.contextChangeSubscription = this.stateManagement.onComponentActiveStateChanged$.subscribe(function (key) {
            _this.stateManagement.loadData();
        });
    }
    Object.defineProperty(IndividualScheduleEmployeeListComponent.prototype, "numWeeks", {
        get: function () {
            if (!this.selectedScheduleCycle)
                return 0;
            var numWeeks = this.selectedScheduleCycle.endDate.diff(this.selectedScheduleCycle.startDate, 'days');
            numWeeks = Math.ceil(numWeeks / 7);
            return numWeeks;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IndividualScheduleEmployeeListComponent.prototype, "employeeSearchService", {
        get: function () {
            return this.employeeSearchBase;
        },
        enumerable: true,
        configurable: true
    });
    IndividualScheduleEmployeeListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.subscriptions.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (orgLevel && orgLevel.id && ((_this.selectedOrgLevel && (orgLevel.id !== _this.selectedOrgLevel.id)) || !_this.selectedOrgLevel)) {
                _this.selectedOrgLevel = orgLevel;
                _this.selectedEmployee = null;
            }
        });
        this.empLockListSubscription = this.individualScheduleManagementService.onEmpListLock$.subscribe(function (isLock) {
            _this.state.isLoading = isLock;
        });
        this.individualScheduleManagementService.subscribeToLoadOrgLvlEmployees(function (cycle) {
            _this.selectedScheduleCycle = cycle;
            _this.employeeSearchService.startDate = cycle.startDate.toDate();
            _this.employeeSearchService.endDate = cycle.endDate.toDate();
            _this.loadOrgLevelEmployees();
        });
    };
    IndividualScheduleEmployeeListComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    IndividualScheduleEmployeeListComponent.prototype.loadOrgLevelEmployees = function () {
        var _this = this;
        if (!this.selectedOrgLevel || !this.selectedScheduleCycle) {
            return;
        }
        this.individualScheduleManagementService.onScheduleCycleSelected(this.selectedScheduleCycle);
        this.state.isLoading = true;
        this.individualScheduleApiService.getOrgLevelEmployees(this.selectedOrgLevel.id, this.selectedScheduleCycle.startDate.toDate(), this.numWeeks)
            .then(function (orgLevelEmployees) {
            _this.employeeSearchService.orgLevelEmployees = orgLevelEmployees.entities;
            _this.employeeSearchService.Load();
            _this.orgLevelEmployees = orgLevelEmployees;
            _this.state.isLoading = false;
        })
            .catch(function () {
            _this.state.isLoading = false;
        });
    };
    IndividualScheduleEmployeeListComponent.prototype.emitRequest = function (employeeWrap) {
        if (!employeeWrap || !this.orgLevelEmployees) {
            this.individualScheduleManagementService.onEmployeeRequest(null);
            return;
        }
        var req = new IndSchEmployeeSchedulesRequest();
        req.employeeId = employeeWrap.employee.id;
        req.orgLevelId = this.orgLevelEmployees.orgLevelId;
        req.scheduleWeeksCount = this.numWeeks;
        req.startDate = this.selectedScheduleCycle.startDate.toDate();
        req.employeeName = employeeWrap.employee.name;
        this.individualScheduleManagementService.onEmployeeRequest(req);
    };
    IndividualScheduleEmployeeListComponent.prototype.onEmployeeSelected = function (emp) {
        if (!_.isNil(emp)) {
            this.individualScheduleManagementService.onSearchEmpSelected$.next(emp.id);
        }
    };
    IndividualScheduleEmployeeListComponent.prototype.toggleLCol = function () {
        this.individualScheduleManagementService.changeEmployeeListVisibility();
    };
    tslib_1.__decorate([
        select(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleEmployeeListComponent.prototype, "isLeftSidebarOpen", void 0);
    tslib_1.__decorate([
        select(['sidebar', 'isLeftSidebarHidden']),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleEmployeeListComponent.prototype, "isLeftSidebarHidden", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeListComponent.prototype, "employeeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeListComponent.prototype, "empLockListSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeListComponent.prototype, "employeeUpdateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeListComponent.prototype, "contextChangeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], IndividualScheduleEmployeeListComponent.prototype, "initSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], IndividualScheduleEmployeeListComponent.prototype, "subscriptions", void 0);
    tslib_1.__decorate([
        mutableSelect('orgLevel'),
        tslib_1.__metadata("design:type", Observable)
    ], IndividualScheduleEmployeeListComponent.prototype, "orgLevel$", void 0);
    return IndividualScheduleEmployeeListComponent;
}());
export { IndividualScheduleEmployeeListComponent };
