import {
  Component,
  Input, InjectionToken, Inject,
  ViewChild
} from '@angular/core';
import { ScrollWatchService, IDialog, ModalService, DialogOptions, PopoverContentComponent } from '../../../../../common/index';
import { EMPLOYEE_ID_TOKEN } from '../../../../../core/models/index';
import { Assert } from '../../../../../framework/index';
import { RotationTemplate, ConfigureRotationsRequest } from '../../../models/index';
import { LookupApiService } from '../../../../../organization/services/lookup/lookup-api.service';
import {
  Position,
  LocationUnit,
  ShiftDefinition,
  ConstraintDefinition,
  EmployeeShift
} from '../../../../../organization/models/index';



@Component({
  moduleId: module.id,
  selector: 'employee-sections-rotations-settings',
  templateUrl: 'employee-sections-rotations-settings.component.html',
  styleUrls: ['employee-sections-rotations-settings.component.scss']
})
export class EmployeeSectionsRotationsSettingsComponent implements IDialog {
  public employeeId: number;
  public homePositionId: number;
  public homeShiftId: number;
  public homeUnitId: number;
  public componentId: string;
  public positionLookup: Position[];
  public shiftLookupMapByPositionId: { [positionId: string]: ShiftDefinition[]; } = {};
  public shiftLookup: ShiftDefinition[];
  public unitLookupMapByPositionId: { [positionId: string]: LocationUnit[]; } = {};
  public unitLookup: LocationUnit[];
  public constraintLookupMapByPositionId: { [positionId: string]: ConstraintDefinition[]; } = {};
  public constraintLookup: ConstraintDefinition[];
  public dialogResult: boolean;
  public selectedRotationTemplate: RotationTemplate;
  public selectedEmployeeShift: EmployeeShift;
  public isLoaded: boolean;
  public scheduleType: string;
  public shiftsAreRepeatedEveryNWeeks: RotationTemplate;
  public shifts4On2Off: RotationTemplate;
  public shifts5On2Off5On3Off: RotationTemplate;
  public canChangePosition: boolean = false;
  public useConstraint: boolean = false;
  public state = {
    isValid: false
  };

  private modalService: ModalService;
  private lookupApiService: LookupApiService;
  private options: DialogOptions;
  protected checkTemporalDirty(): boolean {
    return false;
  }

  constructor(configureRotationsRequest: ConfigureRotationsRequest, scrollWatchService: ScrollWatchService,
    lookupApiService: LookupApiService,
    options: DialogOptions, modalService: ModalService) {
    Assert.isNotNull(configureRotationsRequest, 'configureRotationsRequest');
    Assert.isNotNull(options, 'options');
    this.options = options;
    Assert.isNotNull(modalService, 'modalService');
    this.modalService = modalService;
    this.employeeId = configureRotationsRequest.employeeId;
    this.homePositionId = configureRotationsRequest.homePositionId;
    this.homeShiftId = configureRotationsRequest.homeShiftId;
    this.homeUnitId = configureRotationsRequest.homeUnitId;
    this.lookupApiService = lookupApiService;
    this.dialogResult = false;
    this.initializeRotationsSettings();
    this.isLoaded = false;
    this.scheduleType = 'shiftsAreRepeatedEveryNWeeks';
  }

  public onSettingsSelected(selectedSettingsEntry: RotationTemplate): void {
    this.selectedRotationTemplate = selectedSettingsEntry;
  }

  public onAcceptSettings(acceptPopover: PopoverContentComponent): void {
    acceptPopover.hide();
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancelSettings(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onRequiredFieldsPopulated(): void {
    this.state.isValid = true;
  }

  private initializeRotationsSettings(): void {
    this.selectedEmployeeShift = {
      isValid: false,
      constraint: undefined,
      position: undefined,
      shift: undefined,
      unit: undefined,
      absence: undefined,
      isDirty:false
    };

    this.shiftsAreRepeatedEveryNWeeks = {
      rotationsCount: 1,
      records: undefined,
      rotationsStartDate: undefined,
      employeeShift: this.selectedEmployeeShift
    };

    this.shifts4On2Off = {
      records: [{ daysOn: 4, daysOff: 2 }],
      rotationsCount: undefined,
      rotationsStartDate: new Date(),
      employeeShift: this.selectedEmployeeShift
    };

    this.shifts5On2Off5On3Off = {
      records: [{ daysOn: 5, daysOff: 2 }, { daysOn: 5, daysOff: 3 }],
      rotationsCount: undefined,
      rotationsStartDate: new Date(),
      employeeShift: this.selectedEmployeeShift
    };

    this.selectedRotationTemplate = this.shiftsAreRepeatedEveryNWeeks;
  }
}
