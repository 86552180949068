import { Entity } from '../../../core/models/index';
import { UserApplication } from './index';

export class User extends Entity {
  public username: string;
  public name: string;
  public applications: UserApplication[];
  public remidersUnread: number = 0;
  public messagesUnread: number = 0;
  public linkedEmpId: number = 0;
  public isSmartAdmin: boolean = false;
  public email: string = '';
  public roles: string[] = [];
  constructor(id?: number) {
    super(id);
    this.applications = [];
  }
}
