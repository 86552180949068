var IEmployer = /** @class */ (function () {
    function IEmployer() {
    }
    return IEmployer;
}());
export { IEmployer };
var Employer = /** @class */ (function () {
    function Employer() {
    }
    Object.defineProperty(Employer.prototype, "primaryContact", {
        get: function () {
            return this.firstName ? this.firstName.concat(' ', (this.lastName ? this.lastName : '')) : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Employer.prototype, "legalAddress", {
        get: function () {
            return this.legalAddress1 ? this.legalAddress1.concat((this.legalAddress2 ? ', ' + this.legalAddress2 : ''), (this.legalCity ? ', ' + this.legalCity : ''), (this.legalState ? ', ' + this.legalState : ''), ', ', +this.legalZip ? this.legalZip : '') : ' ';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Employer.prototype, "feinWithoutDash", {
        get: function () {
            return this.federalEIN && this.federalEIN.indexOf('-') > -1 ? this.federalEIN.replace('-', '') : this.federalEIN;
        },
        enumerable: true,
        configurable: true
    });
    return Employer;
}());
export { Employer };
var EmployerDialogOptions = /** @class */ (function () {
    function EmployerDialogOptions() {
    }
    return EmployerDialogOptions;
}());
export { EmployerDialogOptions };
var OrganizationDialogOptions = /** @class */ (function () {
    function OrganizationDialogOptions() {
    }
    return OrganizationDialogOptions;
}());
export { OrganizationDialogOptions };
