import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import { UserRolesManagementService } from '../../../services/index';
import { RolesWithUsersContainerDecorator } from '../../../models/index';
var UserRolesAssignementComponent = /** @class */ (function () {
    function UserRolesAssignementComponent(management) {
        this.management = management;
    }
    UserRolesAssignementComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.state = {
            isLoading: false
        };
        this.onUsersWithRolesLoadedSubscription = this.management.onUsersWithRolesLoaded$.subscribe(function (roles) {
            _this.roles = roles;
            _this.rolesDecorator = new RolesWithUsersContainerDecorator(roles);
            _this.state.isLoading = false;
        });
        this.onLoadStatusSubscription = this.management.onLoadStatus$.subscribe(function (lock) {
            _this.state.isLoading = lock;
        });
        this.state.isLoading = true;
        this.management.init();
    };
    UserRolesAssignementComponent.prototype.ngOnDestroy = function () {
        this.management.destroy();
        // See #issueWithAOTCompiler
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserRolesAssignementComponent.prototype, "onUsersWithRolesLoadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], UserRolesAssignementComponent.prototype, "onLoadStatusSubscription", void 0);
    return UserRolesAssignementComponent;
}());
export { UserRolesAssignementComponent };
