import { Pipe, Injectable, PipeTransform, LOCALE_ID, Inject } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'slxDecimal24Switch',
  pure: true
})
@Injectable()
export class SlxDecimal24Switch implements PipeTransform {
  decimalPipe: DecimalPipe;
  constructor(@Inject(LOCALE_ID) private _locale: string) {
    this.decimalPipe = new DecimalPipe(_locale);
  }
  public transform(value: any, isHighPrecision: boolean): any {
    const digitInfo = isHighPrecision? '.4-4' : '.2-2';
    const val = this.decimalPipe.transform(value, digitInfo);
    return val;
  }
}
