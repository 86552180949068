<slx-spinner [show]="isLoading$ | async" class="response">
  <div class="slx-high-box">
    <div class="row slx-high-box__body slx-main-content-indents res">
      <div class="row data">
        <label class="attribute-heading">Organization Sync Options:</label>
        <slx-input-decorator className="slx-no-border  slx-no-error-block">
          <slx-checkbox-input
          class="in"
            slx-input
            name="organization"
            fieldName="organization"
            caption="Organization"
            [(ngModel)]="organization"
            (ngModelChange)="department = false; position = false; clearSelectedItems()"
          >
          </slx-checkbox-input>
        </slx-input-decorator>
        <slx-input-decorator className="slx-no-border  slx-no-error-block">
          <slx-checkbox-input
          class="in"
            slx-input
            name="department"
            fieldName="department"
            caption="Department"
            [(ngModel)]="department"
            (ngModelChange)="organization = false; position = false; clearSelectedItems()"
          >
          </slx-checkbox-input>
        </slx-input-decorator>
        <slx-input-decorator className="slx-no-border  slx-no-error-block">
          <slx-checkbox-input
            slx-input
            name="position"
            fieldName="position"
            caption="Position"
            [(ngModel)]="position"
            (ngModelChange)="organization = false; department = false; clearSelectedItems()"
          >
          </slx-checkbox-input>
        </slx-input-decorator>
      </div>
      <div class="display-toggle">
        <button type="submit" class="theme-button-apply btn-sync" [disabled]="isSyncDisabled$ | async" (click)="syncData()">Sync Now</button>
        <button
            type="submit"
            class="theme-button-apply btn-syncrefresh"
            (click)="onRefreshClicked()">Refresh
          </button>
      </div>

      <kendo-grid
        #grid="kendoGrid"
        [kendoGridBinding]="viewModel$ | async"
        [pageable]="true"
        filterable="menu"
        [filter]="filter$ | async"
        (filterChange)="onFilterChanged($event)"
        [sortable]="{ mode: 'multiple' }"
        [pageSize]="pageSize"
        class="grid slx-full-height slx-blue-grid"
        [resizable]="true"
        [selectable]="true"
      >
      <kendo-grid-checkbox-column [width]="35">
        <ng-template kendoGridHeaderTemplate>
          <input
            type="checkbox"
            [checked]="isCheckedAll$ | async"
            [disabled]="isCheckAllDisabled$ | async" (change)="toggleCheckAll($event)"
          />
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <input
            type="checkbox"
            [checked]="dataItem.ischecked"
            (change)="onCheckboxChange($event, dataItem)"
            [disabled]="isCheckboxDisabled(dataItem)"
          />
        </ng-template>
      </kendo-grid-checkbox-column>
        <kendo-grid-column field="orgLevelId" title="Orglevel ID" width="160"  ></kendo-grid-column>
        <kendo-grid-column field="orgLevelName" title="OrgLevel Name" width="160"  ></kendo-grid-column>
        <kendo-grid-column field="jobCode" title="Job Code" width="160"  ></kendo-grid-column>
        <kendo-grid-column title="Org Type" field="orgLevelType" width="130"  > </kendo-grid-column>
        <kendo-grid-column title="Action" field="action" width="170" >
        </kendo-grid-column>
        <kendo-grid-column field="companyId" title="Company ID" width="160"  ></kendo-grid-column>
        <kendo-grid-column field="companyCode" title="Company Code" width="160"  ></kendo-grid-column>
        <kendo-grid-column title="Paygroup" field="paygroup" width="170"></kendo-grid-column>
        <kendo-grid-column title="Short Code" field="shortCode" width="170" > </kendo-grid-column>
        <kendo-grid-column field="status" title="Status" width="160"  ></kendo-grid-column>
        <kendo-grid-column title="Failed At" field="failedAt" width="170" > </kendo-grid-column>
        <kendo-grid-column field="errorMessage" title="Message" width="160"  ></kendo-grid-column>

        <ng-template kendoGridNoRecordsTemplate>
          <p>There is no data to display</p>
        </ng-template>
      </kendo-grid>

    </div>
  </div>
</slx-spinner>

