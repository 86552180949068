<div class="slx-breadcrumb-toolbar-indents header-nav-first-line">
  <div class="left-side">
   <slx-breadcrumb></slx-breadcrumb>
  </div>
  <div class="right-side">

    <slx-header-nav-time-element [showTimeclocksCount]="appConfig.headerTimeclocksCount"></slx-header-nav-time-element>

    <div class="org-breadcrumb">
      <slx-org-level-breadcrumb [mode]="orgLevelBreadcrumbsHeaderMode"></slx-org-level-breadcrumb>
    </div>

  </div>
</div>
