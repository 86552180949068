<!-- toolbar -->
<div class="slx-content-toolbar-indents flex-container action-buttons">
    <div class="button-box">
      <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted"
        *ngIf="!management.state.bulkEditMode && management.state.canBulkEdit"
        (click)="onBulkEditClick()"
      >
        <i class="fas fa-edit slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Edit Mode</span>
      </button>
    </div>
    <div class="buttons-box">
      <button class="slx-button slx-with-icon slx-min-w90"
        *ngIf="!management.state.bulkEditMode && management.state.canAdd"
        (click)="onAddItem()"
      >
        <i class="fas fa-plus-circle slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Add</span>
      </button>
      <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted delete-btn"
        *ngIf="!management.state.bulkEditMode && management.state.canDelete"
        (click)="onDeleteItems()"
      >
        <i class="fas fa-trash-alt slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Delete</span>
      </button>
      <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted" *ngIf="management.state.bulkEditMode" (click)="onBulkSaveClick()">
        <i class="fas fa-save slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Save</span>
      </button>
      <button class="slx-button slx-with-icon slx-min-w90 slx-mobile-adapted" *ngIf="management.state.bulkEditMode" (click)="onBulkDiscardClick()">
        <i class="fas fa-ban slx-button__icon" aria-hidden="true"></i>
        <span class="slx-button__text">Discard</span>
      </button>
    </div>
  </div>
