import { ValuePairChartInput } from './value-pair-chart-input';
import { PairColorScheme } from '../pair-color-scheme/pair-color-scheme';

export class ValuePairChartModel {
  public input: ValuePairChartInput = new ValuePairChartInput();
  public value1: number;
  public value2: number;
  public color: PairColorScheme;
  public additionalColor: string;

  public get rawValue1(): number {
    return this.input ? this.input.value1 : null;
  }

  public get rawValue2(): number {
    return this.input ? this.input.value2 : null;
  }
}
