import { CensusEntriesComponent } from './../census-entries/census-entries.component';
import { EventEmitter } from '@angular/core';
var CensusEntriesPopupComponent = /** @class */ (function () {
    function CensusEntriesPopupComponent() {
        //private modalService: ModalService, private options: DialogOptions
        this.onSaved = new EventEmitter();
        this.onCanceled = new EventEmitter();
    }
    CensusEntriesPopupComponent.prototype.closeWindow = function () {
        this.unsavedDataAlert = false;
        this.dialogResult = false;
        this.onCanceled.emit(this.census.acuityConfiguration);
    };
    CensusEntriesPopupComponent.prototype.hideNotSavedErrorMessage = function () {
        this.unsavedDataAlert = false;
    };
    CensusEntriesPopupComponent.prototype.existsUnsavedData = function () {
        if (this.census.hasChanges) {
            this.unsavedDataAlert = true;
            return true;
        }
        this.unsavedDataAlert = false;
        return false;
    };
    CensusEntriesPopupComponent.prototype.updateInfoBy = function (date) {
        if (this.existsUnsavedData())
            return;
        if (this.census.entryDate === date)
            return;
        this.census.entryDate = date;
    };
    CensusEntriesPopupComponent.prototype.onSave = function (event) {
        this.unsavedDataAlert = false;
        this.onSaved.emit(this.census.acuityConfiguration);
    };
    Object.defineProperty(CensusEntriesPopupComponent.prototype, "currentCapacity", {
        get: function () {
            return this.census.currentCapacity;
        },
        enumerable: true,
        configurable: true
    });
    return CensusEntriesPopupComponent;
}());
export { CensusEntriesPopupComponent };
