<div class="geo-punch-popup">
  <div class="modal-content">
    <div class="modal-body">
      <slx-static-map
        [latitude]="geolocationData.latitude"
        [longitude]="geolocationData.longitude"
        [mapWidth]="geolocationData.mapWidth"
        [mapHeight]="geolocationData.mapHeight"
        [mapZoom]="geolocationData.mapZoom"
      ></slx-static-map>
    </div>
    <div class="modal-footer">
      <button type="button" (click)="onCancel()" class="theme-button-cancel">Close</button>
      </div>
  </div>
</div>
