import { Provider } from '@angular/core';
import { DialogOptions } from '../../../../common/index';
import { ModalService } from '../../../../common/services/index';
import { DailyAbsence } from '../../../models/index';
import { DailyTimecardManagementService } from '../../../services/index';
import { TimeCardAbsencesEditorComponent } from '../timecard-absences-editor/timecard-absences-editor.component';
var TimecardAbsencesEditorDialogComponent = /** @class */ (function () {
    function TimecardAbsencesEditorDialogComponent(options, modalService, request) {
        this.modalService = modalService;
        this.options = options;
        this.request = request;
    }
    Object.defineProperty(TimecardAbsencesEditorDialogComponent.prototype, "formValid", {
        get: function () {
            return this.absencesEditor.absencesForm.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TimecardAbsencesEditorDialogComponent.prototype, "isOverlappedAndUpdateSchedule", {
        get: function () {
            this.final_result = false;
            if (this.absencesEditor.isOverlappedAbsencestartdate || this.absencesEditor.isOverlappedAbsenceenddate || this.absencesEditor.isOverlappedAbsenceshift || this.absencesEditor.isOverlappedAbsencesDB) {
                this.final_result = true;
            }
            if (this.absencesEditor.isOverlapped && this.absencesEditor.dataItem.updateSchedule) {
                this.final_result = true;
            }
            return this.final_result;
        },
        enumerable: true,
        configurable: true
    });
    TimecardAbsencesEditorDialogComponent.openDialog = function (header, request, modalService, dailyTimecardManagementService, callback) {
        var dialogOptions = new DialogOptions();
        dialogOptions.height = 500;
        dialogOptions.width = 500;
        dialogOptions.fullHeightOnMobile = true;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: DailyAbsence,
                useValue: request
            },
            {
                provide: DailyTimecardManagementService,
                useValue: dailyTimecardManagementService
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(TimecardAbsencesEditorDialogComponent, header, dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result, dialog.request);
        });
        return dialog;
    };
    TimecardAbsencesEditorDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    TimecardAbsencesEditorDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return TimecardAbsencesEditorDialogComponent;
}());
export { TimecardAbsencesEditorDialogComponent };
