import {
  ShiftDefinition,
  IShiftDefinition,
  LocationUnit,
  ILocationUnit,
  ConstraintDefinition,
  IConstraintDefinition,
  Position,
  IPosition,
  IScheduleAbsence,
  ScheduleAbsence,
  Department
} from '../../../organization/models/index';

export class OrgLevelShiftTemplate {
  public shift: ShiftDefinition;
  public unit: LocationUnit;
  public constraint: ConstraintDefinition;
  public absence: ScheduleAbsence; //can be undefined
}
