import * as tslib_1 from "tslib";
import { Subscription } from 'rxjs/Subscription';
import { OpenShiftManagementManagementService } from './../../../services/open-shift-management/open-shift-management-management.service';
import { PostScheduleSettings } from './../../../models/open-shift-management/post-schedule-settings';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { unsubscribe } from '../../../../core/decorators/unsubscribe-decorator';
import * as moment from 'moment';
var PostScheduleSettingsFormComponent = /** @class */ (function () {
    function PostScheduleSettingsFormComponent(openShiftManagementManagementService) {
        this.openShiftManagementManagementService = openShiftManagementManagementService;
        this.isApplyButtonDisabled = false;
        this.onCancel = new EventEmitter();
        this.onApply = new EventEmitter();
        this.settings = new PostScheduleSettings();
    }
    PostScheduleSettingsFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settingsSubscription = this.openShiftManagementManagementService.onPostSettingsLoaded$.subscribe(function (value) {
            _this.settings = value;
        });
        this.openShiftManagementManagementService.dataEmitter.subscribe(function (value) {
            _this.checkNotify = value;
        });
        this.selectedScheduleCycleDataSubscription = this.openShiftManagementManagementService.selectedScheduleCycleData$.subscribe(function (scheduleCycle) {
            _this.isApplyButtonDisabled = (moment(scheduleCycle.endDate).toDate() < moment().startOf('D').toDate()) ? true : false;
            _this.onCancel.emit();
        });
    };
    PostScheduleSettingsFormComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    PostScheduleSettingsFormComponent.prototype.applySettings = function () {
        this.openShiftManagementManagementService.postSettings = this.settings.clone();
        this.onApply.emit();
    };
    PostScheduleSettingsFormComponent.prototype.restoreSettings = function () {
        this.settings = this.openShiftManagementManagementService.postSettings.clone();
    };
    PostScheduleSettingsFormComponent.prototype.onCancelHandler = function () {
        this.restoreSettings();
        this.onCancel.emit();
    };
    Object.defineProperty(PostScheduleSettingsFormComponent.prototype, "isDisabled", {
        get: function () {
            if (this.openShiftManagementManagementService.isIncreaseFrequencyofShiftsPostedNotifications)
                return ((this.settings.notifyDaysAndWeekly == 0 && this.settings.reminderCount == null && this.settings.notifyWeekly) || (this.settings.reminderCount == null && this.settings.notifyDaysAndWeekly != 0) || (this.settings.reminderCount == 0 && this.settings.notifyDaysAndWeekly != 0));
            //Disable if Schedule Cycle End Date is less than today - #304114
            return this.isApplyButtonDisabled;
        },
        enumerable: true,
        configurable: true
    });
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PostScheduleSettingsFormComponent.prototype, "settingsSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PostScheduleSettingsFormComponent.prototype, "selectedScheduleCycleDataSubscription", void 0);
    return PostScheduleSettingsFormComponent;
}());
export { PostScheduleSettingsFormComponent };
