export interface IScheduleActions {
  canBackup: boolean;
  canRestore: boolean;
  canDelete: boolean;
  canGenerate: boolean;
  canPost: boolean;
  canEdit: boolean;
  canEditRotations: boolean;
  canAccessEmployeeRecord: boolean;
  canEditForApprovedPayPeriod :boolean;
}

export class ScheduleActions implements IScheduleActions {
  public canBackup: boolean;
  public canRestore: boolean;
  public canDelete: boolean;
  public canGenerate: boolean;
  public canPost: boolean;
  public canEdit: boolean;
  public canEditRotations: boolean;
  public canAccessEmployeeRecord: boolean;
  public canEditForApprovedPayPeriod: boolean;
}

export class ScheduleActionsType {
  public static backupSchedule: string = 'backupSchedule';
  public static restoreSchedule: string = 'restoreSchedule';
  public static deleteSchedule: string = 'deleteSchedule';
  public static generateSchedule: string = 'generateSchedule';
  public static postSchedule: string = 'postSchedule';
  public static editSchedule: string = 'editSchedule';
  public static accessEmployeeRecord: string = 'accessEmployeeRecord';
  public static editScheduleForApprovedPayPeriod: string = 'editScheduledApprovePayPeriod';
}

export class ScheduleFields {
  public static rotationsDataFieldName: string = 'rotationsData';
}
