import { AttributesModel } from "./attributes-model";

export class IDetailsShiftRequest {
    public requestId: number;
    public trackingId: number;
    public shiftrequestBy: string;
    public shiftRequestDate: string;
    public shiftStatus:number;
    public partnerId: string;
    public agencyId: number;
    public agencyOrgLevelId: string;
    public attributes: AttributesModel[];
}

export class DetailsShiftRequest {
    public requestId: number;
    public trackingId: number;
    public shiftrequestBy: string;
    public shiftRequestDate: string;
    public shiftStatus:number;
    public partnerId: string;
    public agencyId: number;
    public agencyOrgLevelId: string;
    public attributes: AttributesModel[];
}