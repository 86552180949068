import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';
import { OrgLevel } from '../../../state-model/models/index';
import { LookupApiService } from '../../../organization/services/lookup/lookup-api.service';
import { ChangeManagementService } from '../../../common/index';
import { mutableSelect, unsubscribeInService } from '../../../core/decorators/index';
import { AccessManagementService } from '../accessManagement/access-management.service';
import { AbsencesContainer } from '../../models/absences/absences-container';
import { AbsenceConfigurationApiService } from '../../services/absences/absence-configuration-api.service';
var AbsencesConfigurationManagementService = /** @class */ (function (_super) {
    tslib_1.__extends(AbsencesConfigurationManagementService, _super);
    function AbsencesConfigurationManagementService(access, changeService, api, lookup) {
        var _this = _super.call(this) || this;
        _this.access = access;
        _this.changeService = changeService;
        _this.api = api;
        _this.lookup = lookup;
        _this.removeItemsCmd$ = new ReplaySubject();
        _this.addItemCmd$ = new ReplaySubject();
        _this.editItemCmd$ = new ReplaySubject();
        _this.dataChanged$ = new Subject();
        _this.viewRefresh$ = new Subject();
        return _this;
    }
    Object.defineProperty(AbsencesConfigurationManagementService.prototype, "container", {
        get: function () {
            return this.m_container;
        },
        enumerable: true,
        configurable: true
    });
    AbsencesConfigurationManagementService.prototype.init = function () {
        var _this = this;
        this.access.allowCorporationLevel = false;
        this.access.allowOrganizationLevel = true;
        this.access.allowDepartmentLevel = false;
        this.orgLevelSubscription = this.orgLevel$.subscribe(function (orgLevel) {
            if (_.isNumber(orgLevel.id)) {
                _this.currentOrgLevel = orgLevel;
                _this.access.orgLevelType = _this.currentOrgLevel.type;
                _this.onStateChanged$.next({ orgLevelChanged: true, configureMode: true, copyMode: false });
                _this.fetchRecords();
            }
        });
    };
    AbsencesConfigurationManagementService.prototype.markAsDirty = function () {
        this.changeService.changeNotify();
    };
    AbsencesConfigurationManagementService.prototype.setSelectedCount = function (count) {
        this.access.selectedItemsCount = count;
    };
    AbsencesConfigurationManagementService.prototype.onAddItem = function (item) {
        this.changeService.changeNotify();
        this.access.lockActions = true;
        this.editingItem = item;
        this.isEditingNewItem = true;
        this.addItemCmd$.next(item);
    };
    AbsencesConfigurationManagementService.prototype.onEditItem = function (item) {
        this.access.lockActions = true;
        this.editingItem = item;
        this.editItemCmd$.next(item);
    };
    AbsencesConfigurationManagementService.prototype.onCancelEditItem = function () {
        this.access.lockActions = false;
        this.editingItem = null;
        this.isEditingNewItem = false;
        this.editItemCmd$.next(null);
        this.changeService.clearChanges();
    };
    AbsencesConfigurationManagementService.prototype.onRemoveItem = function (itemToDelete) {
        this.removeItemsCmd$.next(itemToDelete);
    };
    AbsencesConfigurationManagementService.prototype.doRemoveItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.api.deleteAbsence(this.currentOrgLevel.id, item)
            .then(function (items) {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    AbsencesConfigurationManagementService.prototype.onSaveItem = function (info) {
        if (info.isNew) {
            this.addItem(info.dataItem);
        }
        else {
            this.updateItem(info.dataItem);
        }
    };
    AbsencesConfigurationManagementService.prototype.addItem = function (item) {
        var _this = this;
        _.each(this.m_container.records, function (p) {
            p.isSelected = false;
        });
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.addAbsence(this.currentOrgLevel.id, item)
            .then(function (items) {
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.changeService.clearChanges();
            _this.onStateChanged$.next({ isLoading: false });
            _this.fetchRecords();
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    AbsencesConfigurationManagementService.prototype.updateItem = function (item) {
        var _this = this;
        this.onStateChanged$.next({ isLoading: true });
        this.access.lockActions = true;
        this.api.saveAbsence(this.currentOrgLevel.id, item)
            .then(function (items) {
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.viewRefresh$.next(false);
            _this.changeService.clearChanges();
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.access.lockActions = false;
            _this.viewRefresh$.next(false);
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    AbsencesConfigurationManagementService.prototype.fetchRecords = function () {
        var _this = this;
        this.access.lockActions = true;
        this.onStateChanged$.next({ isLoading: true });
        this.api.getAbsences(this.currentOrgLevel.id).
            then(function (result) {
            _this.changeService.clearChanges();
            _this.m_container = new AbsencesContainer();
            _this.m_container.records = result.records;
            _this.access.actions = result.actions;
            _this.access.lockActions = false;
            _this.editingItem = null;
            _this.isEditingNewItem = false;
            _this.onLoaded$.next(_this.m_container);
            _this.onStateChanged$.next({ isLoading: false });
        }).catch(function () {
            _this.access.lockActions = false;
            _this.onStateChanged$.next({ isLoading: false });
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], AbsencesConfigurationManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribeInService(),
        tslib_1.__metadata("design:type", Subscription)
    ], AbsencesConfigurationManagementService.prototype, "orgLevelSubscription", void 0);
    return AbsencesConfigurationManagementService;
}(ManagementBaseService));
export { AbsencesConfigurationManagementService };
