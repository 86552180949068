import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api/api.service'
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { HttpRequest } from '@angular/common/http';
import { Meta } from '../../../core/models/api/meta';
import { configurationConfig } from '../../../configuration/configuration.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { MessagemapService } from '../messagemap.service';
import { ISlateMessageGroupInfo, ISlateMessageGroupInfoOfGroupId, SlateMessageGroupInfo, SlateMessageGroupInfoOfGroupId } from '../models/slate-message-group-info';
import { ResponseBody } from '../../../core/models';
import { SlateMessageSendDTO } from '../models';

@Injectable()
export class SlateMessagesApiService {

  constructor(private apiService: ApiService,
    private apiUtilService: ApiUtilService,
    private urlParamsService: UrlParamsService,
    private messagemapService: MessagemapService,) { }

  public getSlateMessageGroupsWithDateRange(orglevelId: number, startDate: Date, endDate: Date): Promise<SlateMessageGroupInfo[]>{
    const url: string = `${this.getSlateNotificationUrl()}/${configurationConfig.api.slate.notification.orgLevel}/${orglevelId}/${configurationConfig.api.slate.notification.group.root}/${configurationConfig.api.slate.notification.group.messages}`;
    const request = this.urlParamsService.createGetRequest(url, {
      startDate: dateTimeUtils.convertToDtoString(startDate),
      endDate: dateTimeUtils.convertToDtoString(endDate)
    });
    return this.apiUtilService.request<Array<ISlateMessageGroupInfo>, Meta>(request)
      .then((response: ResponseBody<Array<ISlateMessageGroupInfo>, Meta>) => {
        return this.messagemapService.mapToSlateMessageGroupInfo(response.data);
      });
  }

  public async getSlateMessagesByGroupId(orglevelId: number, groupId: number): Promise<SlateMessageGroupInfoOfGroupId[]>{
    const url: string = `${this.getSlateNotificationUrl()}/${configurationConfig.api.slate.notification.orgLevel}/${orglevelId}/${configurationConfig.api.slate.notification.group.root}/${groupId}/${configurationConfig.api.slate.notification.group.messages}`;
    let request: HttpRequest<any> = new HttpRequest('GET', url);
    return await this.apiUtilService.request<ISlateMessageGroupInfoOfGroupId[], Meta>(request)
      .then((response: ResponseBody<ISlateMessageGroupInfoOfGroupId[], Meta>) => {
        return this.messagemapService.mapToSlateMessageGroupInfoOfGroupId(response.data)
      });
  }

  public async sendSlateMessage(orgLevelId: number, slateMessage: SlateMessageSendDTO) : Promise<any>{
    const url: string = `${this.getSlateNotificationUrl()}/${configurationConfig.api.slate.notification.orgLevel}/${orgLevelId}/${configurationConfig.api.slate.notification.group.root}/${configurationConfig.api.slate.notification.group.message}/${configurationConfig.api.slate.notification.group.send}`;
    const request = this.urlParamsService.createPostRequest(url, slateMessage);
    const promise: Promise<any> = this.apiUtilService.request<SlateMessageSendDTO[], Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response.data;
      });

    return promise;
  }

  private getSlateNotificationUrl(): string {
    return `${this.getApiRoot()}/${configurationConfig.api.slate.root}/${configurationConfig.api.slate.notification.root}`;
  }

  private getApiRoot(): string {
    return `${this.apiUtilService.getApiRoot()}`;
  }
}

