<slx-custom-collapsible-section class="available-shifts-collapse" mode="white" title="Available Shifts"
    [counter]="manService.totalAvailShifts ? manService.totalAvailShifts : 0" 
    (expandChanged)="getExpandChange($event)" info="Drag a shift to add rotation">
    <ng-container body>
        <slx-spinner [show]="manService.availShiftLoader" class="spinner-active">
            <div class="main-box">
                <div class="main-layout-box" *ngFor="let item of ShiftList">
                    <div class="week-box">
                        {{dateName(item.day) | uppercase}}
                    </div>
                    <div [ngClass]="item.shifts.length > 3 ? 'shift-details-box-active' : 'shift-details-box'"
                        class="shift-details-box">
                        <ng-container *ngFor="let shift of item.shifts">
                            <div [draggable]="hasPermissions" title="{{shift.positionName}}"
                                [ngClass]="item.shifts.length > 3 ? 'shift-details-box-text-active': 'shift-details-box-text'"
                                class="shift-details-box-text" (dragstart)="drag($event,shift)">
                                <span class="time-text">{{shift.shiftName}}</span>
                                <span class="shift-text">{{shift.unitName}}</span>
                                <span *ngIf="shift.count > 1" class="card-count-container">
                                    <span class="card-count">{{shift.count}}</span>
                                </span>
                            </div>
                        </ng-container>
                    </div>
                    <div *ngIf="item.shifts.length === 0" class="no-shift-label">
                        <span>No Shifts Available</span>
                    </div>
                </div>
            </div>
        </slx-spinner>
    </ng-container>
</slx-custom-collapsible-section>