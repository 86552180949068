import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, Validator, AbstractControl, NgControl } from '@angular/forms';

import { appConfig } from '../../../app.config';
import { dateTimeUtils } from '../../../common/utils/index';
import { DatePickerNgxComponent } from '../../../common/components/index';
import { ScheduleCycle } from '../../models/index';

@Component({
  moduleId: module.id,
  selector: 'slx-schedule-cycle-date-picker',
  templateUrl: 'schedule-cycle-date-picker.component.html',
  styleUrls: ['schedule-cycle-date-picker.component.scss'],
})
export class ScheduleCycleDatePickerComponent extends DatePickerNgxComponent implements OnChanges, ControlValueAccessor {
  @Input()
  public format: string;
  @Input()
  public minDate: Date;
  @Input()
  public maxDate: Date;
  @Input()
  public readonly: boolean;
  @Input()
  public cssClass: string;
  @Input()
  public acceptNullDate: boolean;
  @Input()
  public set cycles(value: ScheduleCycle[]) {
    this.m_cycles = value;
    this.m_cycles_start = {};
    this.m_cycles_end = {};
    _.forEach(value, (c: ScheduleCycle) => {
      this.m_cycles_start[moment(c.startDate).startOf('day').format(appConfig.dateFormat)] = c;
      this.m_cycles_end[moment(c.endDate).startOf('day').format(appConfig.dateFormat)] = c;
    });
  }
  @Output('open')
  public calendarOpen: EventEmitter<null>;
  @Output('close')
  public calendarClose: EventEmitter<null>;

  private m_cycles: ScheduleCycle[];
  private m_cycles_start: StringMap<ScheduleCycle>;
  private m_cycles_end: StringMap<ScheduleCycle>;

  public isCurrent(date: Date): boolean {
    return moment(date).isSame(moment().startOf('day'));
  }

  public isSelected(date: Date): boolean {
    return moment(date).isSame(this.currentValue);
  }

  public isStartOfPayCycle(date: Date): boolean {
    return !!this.m_cycles_start[moment(date).startOf('day').format(appConfig.dateFormat)];
  }
  public isEndOfPayCycle(date: Date): boolean {
    return !!this.m_cycles_end[moment(date).startOf('day').format(appConfig.dateFormat)];
  }
}
