import * as tslib_1 from "tslib";
import { destroyService } from './../../../../core/decorators/destroy-service-decorator';
import { unsubscribe } from './../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
import { KendoGridStateHelper } from './../../../../common/models/index';
import { OnInit, OnDestroy } from '@angular/core';
import { StateManagementService } from '../../../../common/index';
import * as _ from 'lodash';
import { PunchesRollupManagementService } from '../../../services/punches/punches-rollup-management.service';
var PunchesManagementRollupComponent = /** @class */ (function () {
    function PunchesManagementRollupComponent(stateService, managementService) {
        this.stateService = stateService;
        this.componentId = 'PunchesManagementRollupComponent';
        this.managementService = managementService;
        this.gridState = new KendoGridStateHelper();
        this.state = {
            isLoading: false,
        };
        this.stateService.init(this.componentId, true);
    }
    PunchesManagementRollupComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadStateSubscription = this.managementService.onStateChanged$
            .subscribe(function (state) {
            _this.globalState = state;
        });
        this.loadStatusSubscription = this.managementService.onLoadStatus$
            .subscribe(function (value) {
            _this.state.isLoading = value;
        });
        this.loadedSubscription = this.managementService.onLoaded$
            .subscribe(function (value) {
            var col = _.first(_this.globalState.columns);
            if (col) {
                col.readonly = _this.managementService.needShowDepartments;
                col.displayed = !_this.managementService.needShowDepartments;
                col.description = _this.managementService.getOrgLevelTitle();
            }
        });
    };
    PunchesManagementRollupComponent.prototype.ngOnDestroy = function () {
        //AOT
    };
    PunchesManagementRollupComponent.prototype.onChangeColumn = function (event, column) {
        this.managementService.onStateChanged(this.globalState);
    };
    PunchesManagementRollupComponent.prototype.onFilterDateChanged = function (_a) {
        var startDate = _a.startDate, endDate = _a.endDate;
        this.managementService.setDates(startDate, endDate);
    };
    PunchesManagementRollupComponent.prototype.selectAll = function (event) {
        _.forEach(this.globalState.columns, function (column) {
            if (!column.readonly) {
                column.displayed = true;
            }
        });
        this.managementService.onStateChanged(this.globalState);
    };
    PunchesManagementRollupComponent.prototype.clearAll = function (event) {
        _.forEach(this.globalState.columns, function (column) {
            if (!column.readonly) {
                column.displayed = false;
            }
        });
        this.managementService.onStateChanged(this.globalState);
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", PunchesRollupManagementService)
    ], PunchesManagementRollupComponent.prototype, "managementService", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementRollupComponent.prototype, "loadStateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementRollupComponent.prototype, "loadStatusSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementRollupComponent.prototype, "loadDataSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementRollupComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementRollupComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], PunchesManagementRollupComponent.prototype, "controlRestoredSubscription", void 0);
    return PunchesManagementRollupComponent;
}());
export { PunchesManagementRollupComponent };
