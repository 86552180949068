// Module was created based on this page:
// https://blog.sstorie.com/integrating-angular-2-and-signalr-part-2-of-2/

import { NgModule, ModuleWithProviders } from '@angular/core';

import { appConfig } from '../app.config';
import { SignalrWindow, SignalrHubConfig, NOTIFICATION_HUB_CONFIG } from './models/index';
import { services } from './services/index';
import { InjectionToken } from '@angular/core';

export function forRoot(config: SignalrHubConfig): ModuleWithProviders {
  return {
    ngModule: ChannelModule,
    providers: [
      ...services,
      { provide: SignalrWindow, useValue: window },
      { provide: NOTIFICATION_HUB_CONFIG, useValue: config }
    ]
  };
}

@NgModule({
})
export class ChannelModule {
  static forRoot = forRoot;
}
