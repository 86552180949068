<div class="editor-container" provide-parent-form>
    <div class="field-container wrapped">
        <div class="col md-full-line-column">
            <slx-input-decorator>
                <input class="form-control" slx-input type="text" [(ngModel)]="item.name" [required]="true" [prohibitedValues]="prohibitedNameValues"
                    placeholder="Shift Description" name="nameInput{{rowIndex}}">
                <span errorMessage for="prohibitedValues">{{appMessages.validation.prohibitedDescriptionValidation.message}}</span>
                <span errorMessage for="required"></span>
            </slx-input-decorator>
        </div>

        <div class="col md-full-line-column">
            <slx-input-decorator>
                <input slx-time-picker slx-input name="startDate{{rowIndex}}" required="true" [(ngModel)]="item.start" format="hh:mm tt"
                    placeholder="Start Time" dateInput="true" [slxMaxDate]="item.end" (onDateChanged)="onShiftStartDateChanged (item)">
                <span errorMessage for="maxDate">Start time should be less than end time</span>
                <span errorMessage for="required">Start time is required</span>
            </slx-input-decorator>
        </div>

        <div class="col md-full-line-column">
            <slx-input-decorator>
                <input slx-time-picker slx-input name="endDate{{rowIndex}}" required="true" [(ngModel)]="item.end" format="hh:mm tt" dateInput="true"
                    placeholder="End Time" [slxMinDate]="item.end" (onDateChanged)="onShiftEndDateChanged (item)">
                <span errorMessage for="minDate">End time should be greater than start time</span>
                <span errorMessage for="required">End time is required</span>
            </slx-input-decorator>
        </div>

        <div class="col md-full-line-column">
            <slx-input-decorator>
                <slx-kendo-number slx-input
                  [(ngModel)]="item.duration"
                  (ngModelChange)="shiftPaidTimeChanged(item, $event)"
                  [min]="0"
                  [slxMin]="0"
                  [slxMinStrict]="true"
                  [required]="'true'"
                  placeholder="Work Hours"
                  name="duration{{rowIndex}}"
                ></slx-kendo-number>
                <span errorMessage for="min">Hours should be greater than zero</span>
                <span errorMessage for="required">Hours are required</span>
            </slx-input-decorator>
        </div>

        <div class="col md-full-line-column">
            <slx-input-decorator>
                <slx-kendo-number slx-input
                  [(ngModel)]="item.lunchDuration"
                  (ngModelChange)="shiftUnpaidTimeChanged(item, $event)"
                  [min]="0"
                  [slxMin]="0"
                  [required]="'true'"
                  placeholder="Lunch Duration"
                  name="lunchDuration{{rowIndex}}"
                ></slx-kendo-number>
                <span errorMessage for="min">Hours should be greater than or equal zero</span>
                <span errorMessage for="required">Hours are required</span>
            </slx-input-decorator>
        </div>

        <div class="col md-full-line-column">
            <slx-input-decorator>
                <slx-dropdown-input slx-input [options]="groups" valueField="id" titleField="name" [(ngModel)]="item.group" name="groupInput{{rowIndex}}"
                    placeholder="Group" [required]="true" placeholder="Shift Group">
                </slx-dropdown-input>
                <span errorMessage for="required"></span>
            </slx-input-decorator>
        </div>

    </div>

    <div class="actions-bar">
        <button class="action-button save-btn" [disabled]="!templateForm?.valid" (click)="onSaveClick()">Save</button>
        <button class="action-button cancel-btn" (click)="onCancelClick()">Cancel</button>
    </div>

</div>
