/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../employee-dependent-drop-enrollment/employee-dependent-drop-enrollment.component.ngfactory";
import * as i2 from "../employee-dependent-drop-enrollment/employee-dependent-drop-enrollment.component";
import * as i3 from "../../../services/employee-sections-benefits-management/employee-sections-benefits-management.service";
import * as i4 from "./employee-dependent-drop-enrollment-dialog.component";
import * as i5 from "../../../../../common/models/dialog-options";
import * as i6 from "../../../../../common/services/modal/modal.service";
import * as i7 from "../../../../../core/models/calendar-data/date-range";
var styles_EmployeeDependentDropEnrollmentDialogComponent = [];
var RenderType_EmployeeDependentDropEnrollmentDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmployeeDependentDropEnrollmentDialogComponent, data: {} });
export { RenderType_EmployeeDependentDropEnrollmentDialogComponent as RenderType_EmployeeDependentDropEnrollmentDialogComponent };
export function View_EmployeeDependentDropEnrollmentDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body modal-body-without-overflow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "slx-employee-dependent-drop-enrollment", [], null, [[null, "dropEnrollment"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropEnrollment" === en)) {
        var pd_0 = (_co.onDropEnrollment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_EmployeeDependentDropEnrollmentComponent_0, i1.RenderType_EmployeeDependentDropEnrollmentComponent)), i0.ɵdid(2, 245760, null, 0, i2.EmployeeDependentDropEnrollmentComponent, [i3.EmployeeSectionsBenefitsManagementService], { options: [0, "options"] }, { dropEnrollment: "dropEnrollment" }), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "button", [["class", "btn green"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-save slx-button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["OK"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "button", [["class", "btn red"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fas fa-times slx-button__icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.canDrop; _ck(_v, 4, 0, currVal_1); }); }
export function View_EmployeeDependentDropEnrollmentDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-employee-dependent-drop-enrollment-dialog", [], null, null, null, View_EmployeeDependentDropEnrollmentDialogComponent_0, RenderType_EmployeeDependentDropEnrollmentDialogComponent)), i0.ɵdid(1, 49152, null, 0, i4.EmployeeDependentDropEnrollmentDialogComponent, [i5.DialogOptions2, i6.ModalService, i7.DateRange], null, null)], null, null); }
var EmployeeDependentDropEnrollmentDialogComponentNgFactory = i0.ɵccf("slx-employee-dependent-drop-enrollment-dialog", i4.EmployeeDependentDropEnrollmentDialogComponent, View_EmployeeDependentDropEnrollmentDialogComponent_Host_0, {}, {}, []);
export { EmployeeDependentDropEnrollmentDialogComponentNgFactory as EmployeeDependentDropEnrollmentDialogComponentNgFactory };
