import {
  Component,
  Input,
  ElementRef,
  forwardRef,
  HostBinding,
  ChangeDetectorRef
} from '@angular/core';
import * as _ from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Assert } from '../../../framework/assert/assert';
export const CHEKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxInputComponent),
  multi: true
};

@Component({
  moduleId: module.id,
  selector: 'slx-checkbox-input',
  templateUrl: 'checkbox-input.component.html',
  styleUrls: ['checkbox-input.component.scss'],
  providers: [CHEKBOX_VALUE_ACCESSOR]
})
export class CheckboxInputComponent implements ControlValueAccessor {
  @Input()
  public set fieldName(value: string) {
    if (!this.m_fieldName && value) {
      this.m_fieldName = this.getUniqueId(value);
    }
  }

  public get fieldName(): string {
    return this.m_fieldName;
  }

  @Input()
  public placeholder: string;
  @Input()
  public caption: string;
  @Input()
  public className: string;
  @Input()
  public set readonly(ro: boolean) {
    this.inEdit = !ro;
    if (this.elementRef) {
      if (ro) {
        this.elementRef.nativeElement.setAttribute('readonly', true);
      } else {
        this.elementRef.nativeElement.removeAttribute('readonly');
      }
    }
  }
  public get value(): any {
    return this.innerValue;
  }

  public set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  public inEdit: boolean;
  private elementRef: ElementRef;
  private onTouchedCallback: () => void = _.noop;
  private onChangeCallback: (val: any) => void = _.noop;
  private innerValue: boolean = false;
  private m_fieldName: string;

  constructor(elementRef: ElementRef, private changeDetector: ChangeDetectorRef) {
    Assert.isNotNull(elementRef, 'elementRef');
    this.elementRef = elementRef;
    this.inEdit = true;
  }

  public writeValue(value?: any): void {
    this.innerValue = value;
    // this.changeDetector.markForCheck();
    // this.changeDetector.detectChanges();
  }

  public registerOnChange(fn?: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn?: any): void {
    this.onTouchedCallback = fn;
  }
  private getUniqueId(name: string): string {
    return `${name}_${Math.random().toString(36).substr(2, 9)}`;
  }
}
