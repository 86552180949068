/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./location-unit-renderer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../editableList/custom-list-actor.base";
import * as i4 from "./location-unit-renderer.component";
var styles_LocationUnitRendererComponent = [i0.styles];
var RenderType_LocationUnitRendererComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocationUnitRendererComponent, data: {} });
export { RenderType_LocationUnitRendererComponent as RenderType_LocationUnitRendererComponent };
function View_LocationUnitRendererComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "col actions-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "slx-form-group slx-no-border small-font"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "slx-input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "slx-form-control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["type", "checkbox"]], [[1, "id", 0], [8, "checked", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onItemSelect() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "label", [["class", "checkbox-label"]], [[1, "for", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "check"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "box"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("selectRow" + _co.rowIndex); var currVal_1 = _co.item.isSelected; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = ("selectRow" + _co.rowIndex); _ck(_v, 5, 0, currVal_2); }); }
export function View_LocationUnitRendererComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "field-container table-record"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LocationUnitRendererComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col edit-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "button", [["class", "edit-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onEditClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col md-full-line-column text-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "field-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Description: "])), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "col md-full-line-column text-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["class", "field-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Unit Code: "])), (_l()(), i1.ɵted(12, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editable; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.description; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.item.name; _ck(_v, 12, 0, currVal_2); }); }
export function View_LocationUnitRendererComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "slx-location-unit-renderer", [], null, null, null, View_LocationUnitRendererComponent_0, RenderType_LocationUnitRendererComponent)), i1.ɵprd(6144, null, i3.CustomListActorBase, null, [i4.LocationUnitRendererComponent]), i1.ɵdid(2, 49152, null, 0, i4.LocationUnitRendererComponent, [], null, null)], null, null); }
var LocationUnitRendererComponentNgFactory = i1.ɵccf("slx-location-unit-renderer", i4.LocationUnitRendererComponent, View_LocationUnitRendererComponent_Host_0, { item: "item", selected: "selected", editable: "editable", selectable: "selectable", editMode: "editMode", context: "context", rowIndex: "rowIndex" }, { actionEmitter: "actionEmitter" }, []);
export { LocationUnitRendererComponentNgFactory as LocationUnitRendererComponentNgFactory };
