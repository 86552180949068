import { AleGroup } from './ale-group';
import {
  State
} from '../../../organization/models/lookup/index';
import { Organization } from '../../../employee/employee-search/models';

export class IEmployer {
  companyId: number;
  legalName: string;
  tradeName: string;
  federalEIN: string;
  acA1095TCC_Code: string;
  description: string;
  legalAddress1: string;
  legalAddress2: string;
  legalCity: string;
  legalState: string;
  legalStateModel: State;
  legalZip: string;
  businessAddress1: string;
  businessAddress2: string;
  businessCity: string;
  businessState: string;
  businessStateModel: State;
  businessZip: string;
  firstName: string;
  lastName: string;
  phone: string;
  fax: string;
  email: string;
  selfInsured: string;
  deleted: boolean;
  modifiedBy: string;
  modifiedOn: string;
  aleGroupId: number;
  aleGroupName: string;
  isSameAsLegalAddress: boolean;
  isSelfInsured: boolean;
}

export class Employer {
  public companyId: number;
  public legalName: string;
  public tradeName: string;
  public federalEIN: string;
  public acA1095TCC_Code: string;
  public description: string;
  public legalAddress1: string;
  public legalAddress2: string;
  public legalCity: string;
  public legalState: string;
  public legalStateModel: State;
  public legalZip: string;
  public businessAddress1: string;
  public businessAddress2: string;
  public businessCity: string;
  public businessState: string;
  public businessStateModel: State;
  public businessZip: string;
  public firstName: string;
  public lastName: string;
  public phone: string;
  public fax: string;
  public email: string;
  public selfInsured: string;
  public deleted: boolean;
  public modifiedBy: string;
  public modifiedOn: string;
  public aleGroupModel: AleGroup;
  public aleGroupId: number;
  public aleGroupName: string;

  public orgLevelId: number;
  public isSameAsLegalAddress: boolean;
  public isSelfInsured: boolean;

  public get primaryContact(): string {
    return this.firstName ? this.firstName.concat(' ', (this.lastName ? this.lastName : '')) : '';
  }

  public get legalAddress(): string {
    return this.legalAddress1 ? this.legalAddress1.concat((this.legalAddress2 ? ', ' + this.legalAddress2 : ''), (this.legalCity ? ', ' + this.legalCity : ''), (this.legalState ? ', ' + this.legalState : '') , ', ', + this.legalZip ? this.legalZip : '') : ' ';
  }

  public get feinWithoutDash(): string {
    return this.federalEIN && this.federalEIN.indexOf('-') > -1 ? this.federalEIN.replace('-', '') : this.federalEIN;
  }
}

export class EmployerDialogOptions {
  public employer: Employer;
  public aleGroupList: AleGroup[];
  public allFeins: string[];
}

export class OrganizationDialogOptions {
  public organization: Organization[];
}
