<div class="slx-high-box">
  <div class="flex-horizontal fixed-filter" *ngIf="individualScheduleManagementService.showEmployeeList">
    <slx-employee-search-field class="filter-string" [orgLevelId]="selectedOrgLevel.id"
      (employeeSelected)="onEmployeeSelected($event)" [isShownEmpNameLabel]="false" [readonly]="state.isLoading">
    </slx-employee-search-field>
    <div class="toggle-l-column" (click)="toggleLCol()" title="Collapse">
      <i class="fas fa-angle-left" aria-hidden="true"></i>
    </div>
  </div>

  <div class="slx-high-box__body" [ngClass]="{'hidden': !individualScheduleManagementService.showEmployeeList}">
    <slx-spinner [show]="state.isLoading">
      <slx-individual-schedule-employee-grid class="slx-full-height" [filter]="filter"
        [orgLevelEmployees]="orgLevelEmployees?.entities"></slx-individual-schedule-employee-grid>
    </slx-spinner>
  </div>
</div>
