<slx-spinner class="container-fluid agency-console-spinner "
    [show]="!isConsoleDetailsReady || !isConsoleSummaryReady || isLoading">

    <div class="header slx-content-toolbar-indents">
        <slx-toolbar class="agency-console-toolbar" [alignExpandButtonRight]="true">
            <ng-template slxToolbarSectionTemplate let-counts="counts" alignMode="left" [width]="260">
                <div class="slx-toolbar-section slx-no-padd-l ">
                    <div class="cycle">
                        <slx-dropdown-list class="slx-wide slx-short slx-middle-label week-filter" name="weekFilter"
                            [options]="weekFilter" [valuePrimitive]="true" [(ngModel)]="selectedValue"
                            (ngModelChange)="weekFilterChnaged($event)"></slx-dropdown-list>

                        <div class="weekly-filter" *ngIf="weekChanged">
                            <slx-agency-console-weekly-calender (selectedDateRange)="selectedDateRange($event)"
                                [selectedFilter]="selectedWeekFilter"></slx-agency-console-weekly-calender>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template slxToolbarSectionTemplate  alignMode="left"  let-counts="counts" [width]="500" >
                <div class="slx-toolbar-section slx-no-padd-l "  style="margin-right:126px !important">
                    <div class="agency-console-filters">
                        <span class="current-state">View by current state:</span>
                        <input type="radio" [(ngModel)]="value" [checked]="filterAll" class="select-all"
                            name="fieldName" (ngModelChange)="filterChange('all',$event)">
                        <label><span class="check"> All</span></label>
                        <slx-checkbox-button type="checkbox" class="rb-CheckBox slx-no-indents " name="assigned"
                            id="assigned" (change)="filterChange('assigned',$event)" [(ngModel)]="selectAssigned">
                        </slx-checkbox-button>
                        <span class="filter-name">Assigned </span>

                        <slx-checkbox-button type="checkbox" class="rb-CheckBox slx-no-indents" name="pendingAgnecy"
                            id="pending" (change)="filterChange('pending',$event)" [(ngModel)]="selectPending">
                        </slx-checkbox-button>
                        <span class="filter-name">Pending </span>

                        <slx-checkbox-button type="checkbox" class="rb-CheckBox slx-no-indents" name="responseRequested"
                            id="responseRequested" (change)="filterChange('requested',$event)"
                            [(ngModel)]="selectPendingAction"> </slx-checkbox-button>
                        <span class="filter-name">Pending Action </span>

                        <slx-checkbox-button type="checkbox" class="rb-CheckBox slx-no-indents" name="cancelled"
                            id="cancelled" (change)="filterChange('cancelled', $event)" [(ngModel)]="selectCancelled">
                        </slx-checkbox-button>
                        <span class="filter-name">Cancelled </span>

                        <slx-checkbox-button type="checkbox" class="rb-CheckBox slx-no-indents" name="failedRequest"
                            id="failedRequest" (change)="filterChange('failed',$event)"
                            [(ngModel)]="selectFailed"> </slx-checkbox-button>
                        <span class="filter-name">Failed </span>
                    </div>
                </div>
            </ng-template>

            <ng-template slxToolbarSectionTemplate  alignMode="right" let-counts="counts" [width]="400">
                <div class="slx-toolbar-section slx-no-padd-l">
                    <div style=" margin-top:86px;   margin-right: 52%;">
                        <slx-multiselect [options]="agencyListData" [(ngModel)]="agency_id"
                            (ngModelChange)="agencyFilter($event)" titleField="agency_name" valueField="agency_id"
                            [valuePrimitive]="true" placeholder="Filter by Agency... "
                            [readonly]="disableGlobalAreaFilter" name="agency-filter" class="agency-filter">
                        </slx-multiselect>
                    </div>

                    <div class="filter">
                        <slx-actions-list>
                            <slx-actions-button className="low-height auto-width" [popperContent]="Actionsettings"
                                [popperPosition]="'bottom-end'">
                                <i class="fas fa-cog" aria-hidden="true"></i> <span
                                    style="margin-left:10px;">Actions</span>
                            </slx-actions-button>
                            <popper-content #Actionsettings>
                                <slx-actions-list-item (click)="exportToExcel()">Export to Excel</slx-actions-list-item>
                                <slx-actions-list-item (click)="captureScreen()">Export to PDF</slx-actions-list-item>
                            </popper-content>
                        </slx-actions-list>
                    </div>
                </div>
            </ng-template>  

        </slx-toolbar>
    </div>
    <span *ngIf="isConsoleDetailsReady && isConsoleSummaryReady">
        <div *ngFor="let agency of filteredAgencyListData">
            <slx-agency-staffing-console-grid [filters]="filters" [selectedAgency]="filterAgency" [agency]="agency"
                [shiftDetails]="shiftDetails" [shiftSummary]="shiftSummary" [exportToExcel]="multiSheetExcel"
                (selectAllRecords)="selectAllRecords($event)" (exportRecords)="getMultiSheetExcel($event)"
                [filterStartDate]="filterStartDate" [filterEndDate]="filterEndDate" [filterEnabled]="filterEnabled">
            </slx-agency-staffing-console-grid>

        </div>
    </span> 

</slx-spinner>