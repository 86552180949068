/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tree-node-content.component";
var styles_TreeNodeContentComponent = [];
var RenderType_TreeNodeContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TreeNodeContentComponent, data: {} });
export { RenderType_TreeNodeContentComponent as RenderType_TreeNodeContentComponent };
function View_TreeNodeContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.node.data; _ck(_v, 1, 0, currVal_0); }); }
function View_TreeNodeContentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_TreeNodeContentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TreeNodeContentComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TreeNodeContentComponent_2)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { $implicit: 0, node: 1, index: 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.template; _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 4, 0, _co.node, _co.node, _co.index); var currVal_2 = _co.template; _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_TreeNodeContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "slx-tree-node-content", [], null, null, null, View_TreeNodeContentComponent_0, RenderType_TreeNodeContentComponent)), i0.ɵdid(1, 49152, null, 0, i2.TreeNodeContentComponent, [], null, null)], null, null); }
var TreeNodeContentComponentNgFactory = i0.ɵccf("slx-tree-node-content", i2.TreeNodeContentComponent, View_TreeNodeContentComponent_Host_0, { node: "node", index: "index", template: "template" }, {}, []);
export { TreeNodeContentComponentNgFactory as TreeNodeContentComponentNgFactory };
