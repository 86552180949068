import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { WCReportManagementService } from '../../../services';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { ChangeManagementService } from '../../../../../common/services';
var WcReportSectionComponent = /** @class */ (function () {
    function WcReportSectionComponent(management, changeDetector, changeManagementService) {
        this.management = management;
        this.changeDetector = changeDetector;
        this.changeManagementService = changeManagementService;
        this.notifications = 0;
        this.m_isCollapsed = false;
        this.subscriptions = {};
    }
    Object.defineProperty(WcReportSectionComponent.prototype, "hasErrors", {
        get: function () {
            if (this.validatingFunction) {
                return this.validatingFunction();
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WcReportSectionComponent.prototype, "isCollapsed", {
        get: function () {
            return this.m_isCollapsed;
        },
        enumerable: true,
        configurable: true
    });
    WcReportSectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.load = this.management.subscribeToReportLoad(function (x) {
            _this.report = x;
            if (_this.report && _.isFinite(_this.report.id)) {
                _this.m_isCollapsed = true;
            }
            else {
                _this.m_isCollapsed = false;
            }
        });
    };
    WcReportSectionComponent.prototype.ngOnDestroy = function () { };
    WcReportSectionComponent.prototype.collapsedChange = function (isCollapsed) {
        this.m_isCollapsed = isCollapsed;
        this.changeDetector.markForCheck();
        this.changeDetector.detectChanges();
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], WcReportSectionComponent.prototype, "subscriptions", void 0);
    return WcReportSectionComponent;
}());
export { WcReportSectionComponent };
