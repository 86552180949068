<slx-spinner [show]="isLoading">
<kendo-grid #kendoGrid class="slx-blue-grid slx-grid-slim-rows slx-full-height header-bg" [data]="gridState.view"
[filterable]="'menu'" [filter]="gridState.state.filter" (filterChange)="filterChangeHandler($event)"
[sortable]="{ mode: 'multiple' }"
[sort]="gridState.state.sort" (sortChange)="sortChange($event)"  [resizable]="true"
  [selectable]="{ enabled: true, checkboxOnly: true }">

    <kendo-grid-column field="exportStatus" filter="text">
        <ng-template kendoGridHeaderTemplate>
         <div class="header">Export Status</div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.exportProgress===0 && dataItem.exportStatus === 'WAITING'" class="waiting"><i class="fas fa-hourglass-half waiting"></i>Waiting</span>
          <span *ngIf="dataItem.exportProgress===100 && dataItem.exportStatus === 'Completed'" class="success"><i class="fas fa-check-circle success"></i>Completed</span>
          <span *ngIf="dataItem.exportProgress===100 && dataItem.exportStatus === 'CANCELLED'" class="error"><i class="fas fa-times-circle error"></i>Cancelled</span>
          <span *ngIf="dataItem.exportProgress===100 && dataItem.exportStatus === 'FAILED'" class="error"><i class="fas fa-times-circle error"></i>Failed</span>
          <span *ngIf="dataItem.exportProgress===100 && dataItem.exportStatus === 'EXPIRED'" class="error"><i class="fas fa-times-circle error"></i>Expired</span>
        <div class="status-bar">
              <kendo-progressbar class="bar" [value]="dataItem.exportProgress"
              *ngIf="dataItem.exportProgress > 0 && dataItem.exportProgress <100"></kendo-progressbar>
        </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="exportID" filter="text">
        <ng-template kendoGridHeaderTemplate>
            <div class="header">Export ID</div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <a [slxPbjExportDetailsLink]="dataItem?.exportID">{{ dataItem.exportID }}</a>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="organizationName" filter="text"  >
        <ng-template kendoGridHeaderTemplate>
            <div class="header">Organization </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.organizationName}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="processStartDate" filter="date">
        <ng-template kendoGridHeaderTemplate>
            <div class="header">Process Start Date/Time</div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.processStartDate === null ? null :(dataItem.processStartDate | date:"MM/dd/yyyy, hh:mm:ss a 'EST'" )}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column  field="processEndDate" filter="date">
        <ng-template kendoGridHeaderTemplate>
            <div class="header">Process End Date/Time </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.processEndDate === null ? null :(dataItem.processEndDate | date:"MM/dd/yyyy, hh:mm:ss a 'EST'" )}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="processingTime" filter="text">
        <ng-template kendoGridHeaderTemplate>
            <div class="header">Processing Time</div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>  {{ dataItem.processingTime === null ? '': (dataItem.processingTime | number : '1.2-2') + ' minutes' }}   </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="details" filter="text" >
        <ng-template kendoGridHeaderTemplate>
            <div class="header">Details </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.details}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column width="210">
      <ng-template kendoGridHeaderTemplate>
        <input type="checkbox" [(ngModel)]="isAllSelected"  [attr.id]="childRequestObj.batchId" (change)="selectAllItems($event.target.checked)">
        <div class="row">
          <label class="header" for="selectAllCheckboxId" style="margin-right: 8px;">Select All</label>
          <kendo-dropdownbutton [data]="data" (itemClick)="downloadFiles($event)" [disabled]="!isChecked">
            Download
            <ng-template kendoDropDownButtonItemTemplate let-dataItem>
              <span class="dropdown-btn-list-item">{{ dataItem.text }}</span>
            </ng-template>
          </kendo-dropdownbutton>
      </div>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <input type="checkbox" [attr.id]="dataItem.exportID" [disabled]="isCheckboxDisabled(dataItem)" (change)="selectItem(dataItem, $event.target.checked)">
      </ng-template>
    </kendo-grid-column>
</kendo-grid>
</slx-spinner>