import * as _ from 'lodash';
import { Injectable } from '@angular/core';

import { FieldsMeta, Meta } from '../../../../core/models/index';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { ResponseBody } from '../../../../core/models/api/response-body';
import { Assert } from '../../../../framework/index';
import { appConfig } from '../../../../app.config';
import { accrualsConfig } from '../../accruals.config';
import {
  AccrualPolicy,
  IAccrualPolicy,
  Rules,
  IRules,
  DeletePolicy,
} from '../../models/index';
import { AccrualsPolicyMapService } from './accruals-policy-map.service';
import { HttpRequest } from '@angular/common/http';
import { FileBlobResponse } from '../../../../core/models/api/file-blob-response';
import { UpdatePolicyName } from '../../models/accruals-policy/update-policy-name';
import { AccrualOrgLevels, CopyAccrualPolicyResult, IAccrualOrgLevels, ICopyAccrualPolicyResult, ISaveCopyAccrualPolicy } from '../../models/accruals-policy/copy-accruals-policy';

@Injectable()
export class AccrualsPolicyApiService {
  constructor(
    private apiUtilService: ApiUtilService,
    private accrualsPolicyDefinitionsMapService: AccrualsPolicyMapService,
    private urlParamsService: UrlParamsService
  ) { }

  public async getAccrualPolicyShort(orgLevelId: number): Promise<any> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.short.root}/${accrualsConfig.api.accruals.policy.short.all}`;

    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.accrualsPolicyDefinitionsMapService.mapAccrualPolicyDefinitions(response.data, response.meta, false));
  }

  public async getAccrualPolicyDetailed(orgLevelId: number): Promise<any> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.detailed.root}/${accrualsConfig.api.accruals.policy.detailed.all}`;

    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .request<any, FieldsMeta>(request)
      .then((response: ResponseBody<any, FieldsMeta>) => this.accrualsPolicyDefinitionsMapService.mapAccrualPolicyDefinitions(response.data, response.meta, true));
  }

  public async getAccrualPolicy(orgLevelId: number, policyId: number): Promise<AccrualPolicy> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${policyId}`;

    const request = this.urlParamsService.createGetRequest(url);

    return this.apiUtilService
      .request<IAccrualPolicy, FieldsMeta>(request)
      .then((response: ResponseBody<IAccrualPolicy, FieldsMeta>) => this.accrualsPolicyDefinitionsMapService.mapAccrualPolicyList(response.data, true));
  }

  public getAccrualPolicyExportToExcel(orgLevelId: number): Promise<FileBlobResponse> {

    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.export.root}/${accrualsConfig.api.accruals.policy.export.excel}`;

    const request = this.urlParamsService.createGetRequest(url);

    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);

    return promise;
  }

  public getAccrualPolicyExportToPdf(orgLevelId: number): Promise<FileBlobResponse> {

    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.export.root}/${accrualsConfig.api.accruals.policy.export.pdf}`;

    const request = this.urlParamsService.createGetRequest(url);

    let promise: Promise<FileBlobResponse> = this.apiUtilService.requestForFile(request)
      .then((file: FileBlobResponse) => file);

    return promise;
  }

  public async editAccrualPolicy(orgLevelId: number, req: AccrualPolicy): Promise<any> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.updatePolicy.root}`;
    const body = this.accrualsPolicyDefinitionsMapService.reverseMapAccrualPolicy(req);
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<AccrualPolicy, Meta>(request)
      .then((response: ResponseBody<AccrualPolicy, Meta>) => {
        return response.data;
      });
  }

  public async updatePolicyName(orgLevelId: number, req: UpdatePolicyName): Promise<any> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.updatePolicyName.root}`;
    const body = this.accrualsPolicyDefinitionsMapService.updatePolicyName(req);
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }

  public async deleteAccrualPolicy(orgLevelId: number, req: DeletePolicy): Promise<any> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.deletePolicy.root}`;
    const body = this.accrualsPolicyDefinitionsMapService.deletePolicy(req);
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }

  public async deleteAccrualBenefit(orgLevelId: number, req: any): Promise<any> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.deleteBenefit.root}`;
    const body = req;
    const request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, body);
    return this.apiUtilService.request<any, Meta>(request)
      .then((response: ResponseBody<any, Meta>) => {
        return response;
      });
  }

  public getOrganizationNames(orgLevelId: number) {
    let orgLevelDetails = JSON.parse(sessionStorage.getItem('Organizations'));
    let orgLevelData = _.filter(orgLevelDetails, (item) => item.id !== orgLevelId && item.orgLevelType === 'Organization');
    return this.accrualsPolicyDefinitionsMapService.accrualOrgLevelDetails(orgLevelData);
  }

  public async postCopiedAccrualPolicies(orgLevelId: number, req: ISaveCopyAccrualPolicy): Promise<CopyAccrualPolicyResult[]> {
    const url: string = `${this.getAccrualsRootApi()}/${orgLevelId}/${accrualsConfig.api.accruals.policy.root}/${accrualsConfig.api.accruals.policy.copyPolicies.root}`;
    const request = this.urlParamsService.createPostRequest(url, req);
    return this.apiUtilService
      .request<ICopyAccrualPolicyResult[], FieldsMeta>(request)
      .then((response: ResponseBody<ICopyAccrualPolicyResult[], FieldsMeta>) => this.accrualsPolicyDefinitionsMapService.mapAccrualCopiedPolicyStatus(response.data, response.meta));
  }

  private getAccrualsRootApi(): string {
    return `${this.getApiRoot()}/${accrualsConfig.api.accruals.root}`;
  }

  private getApiRoot(): string {
    return `${appConfig.api.url}/${appConfig.api.version}`;
  }
}
