<div class="search-parameter" [ngClass]="{'root': index == 0}" *ngIf="!templates.expressionTemplate" [formGroup]="formGroup">
    <div class="wrap clearfix">

        <div *ngIf="node.depth > 1 ? true : index != 0" class="marker">{{ node.data.expression.logicalOperator | logicalOperator }}</div>

        <div class="branch-body">

            <div class="actions-block">
                <div class="actions">

                    <button type="button" class="btn btn-default"
                    (click)="node.mouseAction('addSibling', $event)">
                        <i class="fa fa-lg fa-plus" aria-hidden="true"></i>
                    </button>

                    <button *ngIf="node.tree.allowNested"
                     type="button" class="btn btn-default"
                    (click)="node.mouseAction('addChild', $event)">
                        <i class="fas fa-lg fa-level-down-alt" aria-hidden="true"></i>
                    </button>


                    <button type="button" class="btn btn-default" *ngIf="node.depth > 1 ? true : index != 0"
                        (click)="node.mouseAction('removeNode', $event)"
                        >
                        <i class="fas fa-lg fa-trash-alt" aria-hidden="true"></i>
                    </button>

                </div>
            </div>

            <div class="controls-block">

                <slx-input-decorator>
                    <slx-dropdown-input slx-input [options]="currentCategories"
                    titleField="displayName" valueField="categoryFieldId" formControlName="selectedCategory"
                    placeholder="Category" [required]="true"
                    (ngModelChange)="onCategoryChanged($event)"
                    >
                    </slx-dropdown-input>
                    <span errorMessage for="required">Please, select category</span>
                </slx-input-decorator>

                <slx-input-decorator *ngIf="selectedCategory">
                    <slx-dropdown-input slx-input [options]="operators" formControlName="selectedOperator"
                    titleField="displayName" valueField="operatorId"
                    placeholder="Operator" [required]="true"
                    (ngModelChange)="onOperatorChanged($event)"
                    >
                    </slx-dropdown-input>
                    <span errorMessage for="required">Please, select operator</span>
                </slx-input-decorator>

                <slx-input-decorator *ngIf="!firstDate"
                >
                    <input slx-input class="form-control long-field" placeholder="Enter value" [required]="true" formControlName="firstValue" type="text">
                </slx-input-decorator>

                <slx-input-decorator *ngIf="!secondDate && secondDate"
                >
                    <input slx-input class="form-control long-field" placeholder="Enter value" [required]="true" formControlName="secondValue"  type="text">
                </slx-input-decorator>

                <slx-input-decorator *ngIf="firstDate">
                    <slx-date-picker-ngx slx-input
                      formControlName="firstValue"
                      placeholder="From"
                      name="dateFrom"
                      [required]="true"
                      (ngModelChange)="onValueChange()"
                    ></slx-date-picker-ngx>
                </slx-input-decorator>

                <slx-input-decorator *ngIf="hasSecondValue && secondDate">
                    <slx-date-picker-ngx slx-input
                      formControlName="secondValue"
                      placeholder="To"
                      name="dateTo"
                      (ngModelChange)="onValueChange()"
                    ></slx-date-picker-ngx>
                </slx-input-decorator>

            </div>

        </div>
    </div>
    <slx-tree-node-children-component [node]="node" [templates]="templates"></slx-tree-node-children-component>
</div>
<ng-template *ngIf="templates.expressionTemplate"
    [ngTemplateOutlet]="templates.expressionTemplate"
    [ngTemplateOutletContext]="{ $implicit: node, node: node, index: index, templates: templates, formGroup: formGroup }">
</ng-template>
