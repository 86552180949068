import * as _ from 'lodash';
import { Component, Provider } from '@angular/core';

import { EndingBalanceDialogOptions } from '../../../../models/employee-sections-accruals/ending-balance';
import { IDialog, DialogOptions } from '../../../../../../common/models/index';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { AppServerConfig } from './../../../../../../app-settings/model/app-server-config';
import { AppSettingsManageService } from './../../../../../../app-settings/services/index';

@Component({
  moduleId: module.id,
  selector: 'slx-ending-balance-dialog',
  templateUrl: 'ending-balance-dialog.component.html',
  styleUrls: ['ending-balance-dialog.component.scss']
})
export class EndingBalanceDialogComponent implements IDialog {
  public employeeAccruals: EndingBalanceDialogOptions;
  public dialogResult: boolean;
  private options: DialogOptions;
  private modalService: ModalService;
  public EnableRate4DecimalPlaces: boolean;

  public static openDialog(data: EndingBalanceDialogOptions, modalService: ModalService): EndingBalanceDialogComponent {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.height = 400;
    dialogOptions.width = 400;
    dialogOptions.fullHeightOnMobile = true;
    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: EndingBalanceDialogOptions,
        useValue: data
      }
    ];
    let dialog: EndingBalanceDialogComponent = modalService.globalAnchor.openDialog(
      EndingBalanceDialogComponent,
      'Ending Balance',
      dialogOptions,
      resolvedProviders);

    return dialog;
  }
  
  constructor(
    employeeAccruals: EndingBalanceDialogOptions,
    options: DialogOptions,
    modalService: ModalService,
    private appSettingsManageService: AppSettingsManageService,
  ) {
    this.employeeAccruals = employeeAccruals;
    this.options = options;
    this.modalService = modalService;
    this.dialogResult = false;
  }

  public ngOnInit(): void {
    this.getSettings();
  }

  private async getSettings(): Promise<void> {
    let config: AppServerConfig = await this.appSettingsManageService.getAppServerConfig();
    this.EnableRate4DecimalPlaces =  config.EnableRate4DecimalPlaces;
  }

  public get isShowHighPrecision(): boolean {
    return this.EnableRate4DecimalPlaces;
  }

  public get hasAccruals(): boolean {
    return _.isArray(this.employeeAccruals.accruals) && _.size(this.employeeAccruals.accruals) > 0;
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
