<form #form="ngForm" novalidate class="actions">
  <div class="flex justify-content__space-between actions__top-block">
    <div class="actions__lcol">
      <p class="flex actions__control" *ngFor="let action of actionsList; index as i">
        <label class="align-self__center actions__c-label">{{ action.label }}</label>
        <span class="actions__checkbox">
          <slx-checkbox-button
            class="slx-no-indents"
            [(ngModel)]="action.selected"
            (ngModelChange)="onChangeAction(action)"
            name="action_{{ i }}"
          ></slx-checkbox-button>
        </span>
        <span class="actions__text">
          <input class="actions__field"
            [(ngModel)]="action.notes"
            (ngModelChange)="onChangeNote(action)"
            maxlength="255"
            autocomplete="off"
            name="notes_{{ i }}"
            type="text"
          />
        </span>
      </p>
      <p class="flex actions__control padd-top-20">
        <label class="align-self__center actions__c-label">Approved by Name</label>
        <span class="flex__grow">
          <input class="actions__field"
            [(ngModel)]="approvedBy"
            (ngModelChange)="onChangeApprovedBy()"
            maxlength="255"
            autocomplete="off"
            name="approvedBy"
            type="text"
          />
        </span>
      </p>
    </div>
    <div class="actions__rcol">
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">No. of Days </label>
        <span class="flex__grow">
          <slx-number class="slx-wide slx-input-number"
            [min]="min"
            [max]="max"
            [step]="step"
            [format]="format"
            [autoCorrect]='true'
            [decimals]="1"
            [(ngModel)]="numberOfDays"
            (ngModelChange)="onChangeNumberOfDays()"
            name="numberOfDays"
          ></slx-number>
        </span>
      </p>
      <p class="flex actions__control">
        <textarea class="actions__textarea"
          [(ngModel)]="commentForNumberOfDays"
          (ngModelChange)="onChangeComment()"
          maxlength="4000"
          autocomplete="off"
          name="commentForNumberOfDays"
        ></textarea>
      </p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Date</label>
        <span class="flex__grow">
          <slx-datepicker class="slx-wide"
            [(ngModel)]="actionDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeActionDate()"
            name="actionDate"
          ></slx-datepicker>
        </span>
      </p>
      <p class="flex actions__control padd-top-20">
        <label class="align-self__center actions__c-label">Title</label>
        <span class="flex__grow">
          <input class="actions__field"
            [(ngModel)]="actionTitle"
            (ngModelChange)="onChangeActionTitle()"
            maxlength="255"
            autocomplete="off"
            name="actionTitle"
            type="text"
          />
        </span>
      </p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Date</label>
        <span class="flex__grow">
          <slx-datepicker class="slx-wide"
            [(ngModel)]="approveDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeApproveDate()"
            name="approveDate"
          ></slx-datepicker>
        </span>
      </p>
    </div>
  </div>

  <div class="flex flex-flow__wrap justify-content__space-between actions__block">
    <p class="actions__sm-text">I have read this "warning" and understand it</p>
    <div class="actions__lcol">
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Employee signature</label>
        <span class="flex__grow">
          <input class="actions__field"
            [(ngModel)]="employeeSignature"
            (ngModelChange)="onChangeEmployeeSignature()"
            maxlength="255"
            autocomplete="off"
            name="employeeSignature"
            type="text"
          />
        </span>
      </p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Title</label>
        <span class="flex__grow">
          <input class="actions__field"
            [(ngModel)]="employeeTitle"
            (ngModelChange)="onChangeEmployeeTitle()"
            maxlength="255"
            autocomplete="off"
            name="employeeTitle"
            type="text"
          />
        </span>
      </p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Date</label>
        <span class="flex__grow">
          <slx-datepicker class="slx-wide"
            [(ngModel)]="employeeSignDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeEmployeeSignDate()"
            name="employeeSignDate"
          ></slx-datepicker>
        </span>
      </p>
    </div>
    <div class="actions__rcol">
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Signature of the person who prepared the warning</label>
      </p>
      <p class="flex actions__control">
        <span class="flex__grow">
          <input class="actions__field"
            [(ngModel)]="creatorSignature"
            (ngModelChange)="onChangeCreatorSignature()"
            maxlength="255"
            autocomplete="off"
            name="creatorSignature"
            type="text"
          />
        </span>
      </p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Date</label>
        <span class="flex__grow">
          <slx-datepicker class="slx-wide"
            [(ngModel)]="issuerSignDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeIssuerSignDate()"
            name="issuerSignDate"
          ></slx-datepicker>
        </span>
      </p>
    </div>
  </div>

  <div class="flex justify-content__space-between actions__block">
    <div class="actions__lcol">
      <p class="actions__sm-text min-height">If employee refuses to sign:<br/>
        "This is to certify that the employee named in this report was warned by his/her supervisor in my presence
        concerning the subject matter contained there in"</p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Witness:</label>
        <span class="flex__grow">
          <input class="actions__field"
            [(ngModel)]="witnessSignature"
            (ngModelChange)="onChangeWitnessSignature()"
            maxlength="255"
            autocomplete="off"
            name="witnessSignature"
            type="text"
          />
        </span>
      </p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Date</label>
        <span class="flex__grow">
          <slx-datepicker class="slx-wide"
            [(ngModel)]="witnessSignDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeWitnessSignDate()"
            name="witnessSignDate"
          ></slx-datepicker>
        </span>
      </p>
    </div>
    <div class="actions__rcol">
      <p class="actions__sm-text min-height">If employee refuses to accept copy of form:<br/>
        "Employee refuses to accept his/her copy of this warning notice"</p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Supervisor</label>
        <span class="flex__grow">
          <input class="actions__field"
            [(ngModel)]="supervisorSignature"
            (ngModelChange)="onChangeSupervisorSignature()"
            maxlength="255"
            autocomplete="off"
            name="supervisorSignature"
            type="text"
          />
        </span>
      </p>
      <p class="flex actions__control">
        <label class="align-self__center actions__c-label">Date</label>
        <span class="flex__grow">
          <slx-datepicker class="slx-wide"
            [(ngModel)]="supervisorSignDate"
            [acceptNullDate]="true"
            (ngModelChange)="onChangeSupervisorSignDate()"
            name="supervisorSignDate"
          ></slx-datepicker>
        </span>
      </p>
    </div>
  </div>
</form>
