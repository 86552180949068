import { EmployeeSectionsBase } from '../employee-sections-base';
import { IEnrollmentHistoryRecord, EnrollmentHistoryRecord } from './employee-enrollment-history-record';
import { IEmployeeInfo, EmployeeInfo } from './employee-enrollment-info';

export interface IEmployeeSubsectionEnrollment {
  benefitEnrolled: IEnrollmentHistoryRecord[];
  benefitEnrolledHistory: IEnrollmentHistoryRecord[];
  employeeInfo: IEmployeeInfo;
}

export class EmployeeSubsectionEnrollment extends EmployeeSectionsBase {
  public enrollment: EnrollmentHistoryRecord[];
  public enrollmentHistory: EnrollmentHistoryRecord[];
  public employeeInfo: EmployeeInfo;
}
