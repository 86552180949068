import * as _ from 'lodash';
import { Role } from './role-models/index';
import { RolesProfileRow, RolesRightGroupRow, RolesMenuModuleGroupRow, RolesComponentsModuleGroupRow, RolesSubmenuRow, RolesMenuRow } from './row-wrappers/index';
import { ProductModule } from '../../../organization/models/index';

export class RolesContainer {
  public roles: Role[];
  public productModules: ProductModule[];
  public profileRows: RolesProfileRow[];
  public rightGroupRows: RolesRightGroupRow[];
  public otherRightGroupRows: RolesRightGroupRow[];
  public menuModuleGroupRows: RolesMenuModuleGroupRow[];
  public componentsModuleGroupRows: RolesComponentsModuleGroupRow[];
  public flatMenus: NumberMap<RolesSubmenuRow | RolesMenuRow>;

  public findMenuRowById(id: number): RolesSubmenuRow | RolesMenuRow {
    return this.flatMenus[id];
  }
}
