/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./popover-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./popover-content.component";
var styles_PopoverContentComponent = [i0.styles];
var RenderType_PopoverContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PopoverContentComponent, data: {} });
export { RenderType_PopoverContentComponent as RenderType_PopoverContentComponent };
export function View_PopoverContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { popoverDiv: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["popoverDiv", 1]], null, 6, "div", [["role", "popover"], ["style", "display: block"]], [[8, "className", 0], [4, "top", null], [4, "left", null], [2, "in", null], [2, "fade", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "virtual-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "popover-title"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "popover-content"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "popover ", _co.effectivePlacement, ""); var currVal_1 = (_co.top + "px"); var currVal_2 = (_co.left + "px"); var currVal_3 = _co.isIn; var currVal_4 = _co.animation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = !_co.title; _ck(_v, 4, 0, currVal_5); var currVal_6 = _co.title; _ck(_v, 5, 0, currVal_6); }); }
export function View_PopoverContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "popover-content", [], null, [[null, "mouseenter"], [null, "mouseleave"], ["document", "mousedown"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).showOnMouseEnter($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).hideOnMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mousedown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onDocumentMouseDown($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_PopoverContentComponent_0, RenderType_PopoverContentComponent)), i1.ɵdid(1, 4243456, null, 0, i2.PopoverContentComponent, [i1.ElementRef, i1.ChangeDetectorRef], null, null)], null, null); }
var PopoverContentComponentNgFactory = i1.ɵccf("popover-content", i2.PopoverContentComponent, View_PopoverContentComponent_Host_0, { placement: "placement", title: "title", topOffset: "topOffset", leftOffset: "leftOffset", disableClickOutside: "disableClickOutside" }, { onCloseFromOutside: "onCloseFromOutside", onShow: "onShow", onMouseLeaveMouseEnter: "onMouseLeaveMouseEnter" }, ["*"]);
export { PopoverContentComponentNgFactory as PopoverContentComponentNgFactory };
