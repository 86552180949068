import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { EmployeeDefinitionsApiService } from '../../../../../../organization/services/index';
import { LookupService, EmployeeValidatorAdapter } from '../../../../../../organization/services/index';
import { Lookup, LookupType } from '../../../../../../organization/models/index';
import { EmployeeToTransfer, EmployeeTransfer } from '../../../../models/index';
import { appConfig } from '../../../../../../app.config';
import { AppSettingsManageService } from '../../../../../../app-settings/services/index';
var EmployeeTransferComponent = /** @class */ (function () {
    function EmployeeTransferComponent(lookupService, employeeDefinitionsApiService, employeeActivitiesValidatorAdapter, appSettingsManageService) {
        this.appSettingsManageService = appSettingsManageService;
        this.bswiftEnabled = false;
        this.PreventPayroll = false;
        this.PayrollReadOnly = false;
        this.isNgpUser = false;
        this.lookupService = lookupService;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.employeeActivitiesValidatorAdapter = employeeActivitiesValidatorAdapter;
    }
    Object.defineProperty(EmployeeTransferComponent.prototype, "emp", {
        set: function (emp) {
            this.employee = emp;
            this.setEmployeeId();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferComponent.prototype, "departmentEditable", {
        get: function () {
            return !!this.requestToTransfer.organization;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferComponent.prototype, "positionEditable", {
        get: function () {
            return !!this.requestToTransfer.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferComponent.prototype, "shiftEditable", {
        get: function () {
            return !!this.requestToTransfer.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferComponent.prototype, "unitEditable", {
        get: function () {
            return !!this.requestToTransfer.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferComponent.prototype, "benefitClassEditable", {
        get: function () {
            return !!this.requestToTransfer.department;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferComponent.prototype, "positionProhibitedValues", {
        get: function () {
            if (!this.employee || !this.requestToTransfer) {
                return null;
            }
            if (!this.requestToTransfer.organization || !this.requestToTransfer.department) {
                return null;
            }
            if (this.employee.organizationId === this.requestToTransfer.organization.id && this.employee.departmentId === this.requestToTransfer.department.id) {
                return [{ id: this.employee.positionId }];
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeTransferComponent.prototype, "payrollNumberValidation", {
        get: function () {
            return EmployeeValidatorAdapter.payrollNumberValidation;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeTransferComponent.prototype.ngOnInit = function () {
        this.appConfig = appConfig;
        this.requestToTransfer = new EmployeeTransfer();
        this.requestToTransfer.date = null;
        this.setEmployeeId();
        this.getSettings();
        this.getNextPayrollNumber();
        if (!this.requestToTransfer.organization) {
            this.getPayrollNumberInfo(this.employee.organizationId, 0);
        }
        else {
            this.getPayrollNumberInfo(this.employee.organizationId, this.requestToTransfer.organization.id);
        }
        this.requestToTransfer.employeeType = this.employee.employeeType;
        this.requestToTransfer.payrollNumber = this.employee.payrollNumber;
    };
    EmployeeTransferComponent.prototype.setEmployeeId = function () {
        if (this.employee && this.requestToTransfer) {
            this.requestToTransfer.employeeId = this.employee.employeeId;
            this.loadEmployeeInfo();
        }
    };
    EmployeeTransferComponent.prototype.onOrganizationChanged = function (org) {
        var _this = this;
        this.clearOrgDependences();
        if (!this.requestToTransfer.organization) {
            this.getPayrollNumberInfo(this.employee.organizationId, 0);
        }
        this.lookupService.getLookup({ lookupType: LookupType.department, orgLevelId: this.requestToTransfer.organization.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (departmentLookup) {
            _this.filterOutDepartments(departmentLookup);
            _this.getNextPayrollNumber();
            if (!_this.requestToTransfer.organization) {
                _this.getPayrollNumberInfo(_this.employee.organizationId, 0);
            }
            else {
                _this.getPayrollNumberInfo(_this.employee.organizationId, _this.requestToTransfer.organization.id);
            }
        });
    };
    EmployeeTransferComponent.prototype.onDepartmentChanged = function (dep) {
        var _this = this;
        this.clearDepDependences();
        if (!this.requestToTransfer.department) {
            return;
        }
        this.lookupService.getLookup({ lookupType: LookupType.position, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (positionLookup) {
            _this.positionLookup = positionLookup;
        });
        this.lookupService.getLookup({ lookupType: LookupType.shiftDefinition, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (shiftLookup) {
            _this.shiftLookup = shiftLookup;
        });
        this.lookupService.getLookup({ lookupType: LookupType.locationUnit, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
            .then(function (unitLookup) {
            _this.unitLookup = unitLookup;
        });
        if (this.bswiftEnabled) {
            this.lookupService.getLookup({ lookupType: LookupType.benefitClasses, orgLevelId: this.requestToTransfer.department.orgLevelId, employeeId: this.employee ? undefined : this.employee.employeeId })
                .then(function (benefitClassLookup) {
                _this.benefitClassLookup = benefitClassLookup;
                _this.updateBenefitClass();
            });
        }
    };
    EmployeeTransferComponent.prototype.clearOrgDependences = function () {
        this.requestToTransfer.department = null;
        this.clearDepDependences();
    };
    EmployeeTransferComponent.prototype.clearDepDependences = function () {
        this.requestToTransfer.position = null;
        this.requestToTransfer.shift = null;
        this.requestToTransfer.unit = null;
        this.requestToTransfer.benefitClass = null;
    };
    EmployeeTransferComponent.prototype.loadEmployeeInfo = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a, employeeShortInfo, employeeBenefitClass;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.employeeDefinitionsApiService.getEmployeeShortInfo(this.employee.employeeId),
                            this.employeeDefinitionsApiService.getEmployeeBenefitClass(this.employee.employeeId)
                        ])];
                    case 1:
                        _a = _b.sent(), employeeShortInfo = _a[0], employeeBenefitClass = _a[1];
                        this.employeeShortInfo = employeeShortInfo;
                        this.employeeFullName = this.employeeShortInfo.fullName;
                        if (this.requestToTransfer.date === null && this.employeeShortInfo.effectiveEndDate) {
                            this.requestToTransfer.date = moment(this.employeeShortInfo.effectiveEndDate).toDate();
                        }
                        this.employeeBenefitClass = employeeBenefitClass;
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTransferComponent.prototype.updateBenefitClass = function () {
        var _this = this;
        if (!this.employeeBenefitClass
            || !this.benefitClassLookup
            || _.isEmpty(this.benefitClassLookup.items.length)) {
            var benefitClassDefinition = _.find(this.benefitClassLookup.items, function (benefitClass) { return benefitClass.id === _this.employeeBenefitClass.benefitClass.id; });
            if (benefitClassDefinition) {
                this.requestToTransfer.benefitClass = benefitClassDefinition;
                this.requestToTransfer.benefitClassEffectiveDate = this.employeeBenefitClass.startDate;
            }
        }
    };
    EmployeeTransferComponent.prototype.filterOutDepartments = function (departmentLookup) {
        var empDepartmentId = _.get(this.employee, 'departmentId');
        var departments = _.filter(departmentLookup.items, function (dep) { return dep.id !== empDepartmentId; });
        var lookup = new Lookup();
        lookup.titleField = departmentLookup.titleField;
        lookup.valueField = departmentLookup.valueField;
        lookup.type = departmentLookup.type;
        lookup.items = departments;
        this.departmentLookup = lookup;
    };
    EmployeeTransferComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.appSettingsManageService.getAppServerConfig()];
                    case 1:
                        _a.config = _b.sent();
                        this.bswiftEnabled = this.config.bswiftIntegrationEnabled;
                        this.checkForNGPFlag();
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTransferComponent.prototype.getNextPayrollNumber = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var nextPayrollNumber;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.requestToTransfer.organization) {
                            return [2 /*return*/];
                        }
                        if (!!this.isNgpUser) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getNextPayrollNumber(this.requestToTransfer.organization.orgLevelId, this.requestToTransfer.employeeId)];
                    case 1:
                        nextPayrollNumber = _a.sent();
                        this.requestToTransfer.payrollNumber = nextPayrollNumber;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTransferComponent.prototype.getPayrollNumberInfo = function (emporgId, orgId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.requestToTransfer.oldOrganizationId = emporgId;
                        _a = this;
                        return [4 /*yield*/, this.employeeDefinitionsApiService.getPayrollNumberInfo(emporgId, orgId)];
                    case 1:
                        _a.PreventPayroll = _b.sent();
                        if (this.requestToTransfer.organization) {
                            if (this.PreventPayroll) {
                                this.PayrollReadOnly = true;
                            }
                            else {
                                this.PayrollReadOnly = false;
                            }
                        }
                        else {
                            this.PayrollReadOnly = true;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EmployeeTransferComponent.prototype.checkForNGPFlag = function () {
        this.isNgpUser = this.config.IsNextgenPayrollEnabled || false;
    };
    return EmployeeTransferComponent;
}());
export { EmployeeTransferComponent };
