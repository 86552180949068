import * as _ from 'lodash';
import { Component, OnInit, Input } from '@angular/core';
import { PmConfigurationManagementService, PmAttachmentManagementService } from '../../../services';
import { ColumnManagementService, StateManagementService, DeviceDetectorService } from '../../../../../common/services';
import { CfgPmCode, PmFile } from '../../../models';
import { PmTemplateDefinition, ReadFile } from '../../../../../organization/models';

@Component({
  selector: 'slx-pm-config-toolbar',
  templateUrl: './pm-config-toolbar.component.html',
  styleUrls: ['./pm-config-toolbar.component.scss'],
  providers: [
    PmAttachmentManagementService
  ]
})
export class PmConfigToolbarComponent implements OnInit {

  @Input()
  public disabled: boolean = false;

  @Input()
  public itemClass: any;

  @Input()
  public canCreateNew: boolean = true;

  public get isDesktop(): boolean {
    return this.devDetector.isDesktop;
  }

  public get newBtnWidth(): number {
    return this.isDesktop ? 70 : 35;
  }

  public get isForCodes(): boolean {
    return this.itemClass === CfgPmCode;
  }

  public get isForTemplates(): boolean {
    return this.itemClass === PmTemplateDefinition;
  }


  public get maxFileSizeBytes(): number {
    return this.attachmentService.maxFileSizeBytes;
  }
  public get acceptedFileTypes(): string[] {
    return this.attachmentService.acceptedFileTypes;
  }
  public get acceptedFileTypesInputFormat(): string {
    return this.attachmentService.acceptedFileTypesInputFormat;
  }
  public get acceptedFileTypesReadable(): string {
    return this.attachmentService.acceptedFileTypesReadable;
  }
  public get maxFileSize(): string {
    return this.attachmentService.maxFileSize;
  }

  public errors: { maxFiles?: boolean, maxFileSize?: boolean, fileType?: boolean } = {};
  public files: ReadFile[] = [];

  constructor (
    private managementService: PmConfigurationManagementService,
    private attachmentService: PmAttachmentManagementService,
    private devDetector: DeviceDetectorService
  ) { }

  public ngOnInit(): void { }
  public ngOnDestroy(): void { }

  public onClickCreate(): void {
    if (this.isForCodes) {
      this.managementService.startAddNewCode();
    }
  }

  public onClickExport(isPdf: boolean): void {
    if (this.isForCodes) {
      this.managementService.exportCodes(isPdf);
    } else if (this.isForTemplates) {
      this.managementService.exportTemplates(isPdf);
    }
  }

  public async onFileChange(event: MouseEvent): Promise<void> {
    if (!this.disabled) {
      const fileList: FileList = _.get(event, 'target.files');
      const result = this.attachmentService.validateFiles(fileList, event);

      if (result.files.length > 0) {
        const readFiles = await this.attachmentService.readAddedFiles(result.files);
        this.files = readFiles;
        this.managementService.startAddNewTemplate(this.files);
      }
      this.errors = result.errors;
    }
  }

  public onResetErrors(event: any): void {
    this.attachmentService.resetInput(event.target);
    this.errors = {};
  }

}
