import * as tslib_1 from "tslib";
import { EmployeeSectionsBase } from '../employee-sections-base';
import * as _ from 'lodash';
var EmployeeSectionsSelfService = /** @class */ (function (_super) {
    tslib_1.__extends(EmployeeSectionsSelfService, _super);
    function EmployeeSectionsSelfService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(EmployeeSectionsSelfService.prototype, "availableOptions", {
        get: function () {
            var _this = this;
            var allOptions = ['timecard', 'schedule', 'availability',
                'inservice', 'changePassword', 'changeAddress', 'payStubs'];
            var options = _.filter(allOptions, function (opt) { return !!_this[opt]; });
            return options;
        },
        enumerable: true,
        configurable: true
    });
    return EmployeeSectionsSelfService;
}(EmployeeSectionsBase));
export { EmployeeSectionsSelfService };
