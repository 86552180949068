import { DialogOptions } from '../../../../../common/models/index';
import { ModalService } from '../../../../../common/services/modal/modal.service';
import { EmployeeSectionsPayCycleCalcConf } from '../../../models/employee-sections-employment/employee-sections-pay-cycles';
import { LookupApiService } from '../../../../../organization/services/index';
import { PayCyclePeriodType } from '../../../../../organization/models/lookup/index';
var EmployeeSectionsPayCycleConfComponent = /** @class */ (function () {
    function EmployeeSectionsPayCycleConfComponent(options, modalService, lookupApiService, conf) {
        var _this = this;
        this.options = options;
        this.modalService = modalService;
        this.lookupApiService = lookupApiService;
        this.dialogResult = false;
        this.conf = conf;
        lookupApiService.getPayCyclePeriods()
            .then(function (periods) {
            _this.payCycleTypes = periods;
        });
    }
    Object.defineProperty(EmployeeSectionsPayCycleConfComponent.prototype, "showStartDayInputs", {
        get: function () {
            if (!this.conf)
                return false;
            if (this.conf.payCycle.name !== PayCyclePeriodType.smonthly) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsPayCycleConfComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    EmployeeSectionsPayCycleConfComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return EmployeeSectionsPayCycleConfComponent;
}());
export { EmployeeSectionsPayCycleConfComponent };
