import * as tslib_1 from "tslib";
import { RangeDates } from '../../common/models/range-dates';
var DateRangeValue = /** @class */ (function (_super) {
    tslib_1.__extends(DateRangeValue, _super);
    function DateRangeValue(sDate, eDate, isValid) {
        if (sDate === void 0) { sDate = null; }
        if (eDate === void 0) { eDate = null; }
        if (isValid === void 0) { isValid = false; }
        var _this = _super.call(this) || this;
        _this.startDate = sDate;
        _this.endDate = eDate;
        _this.valid = isValid;
        return _this;
    }
    return DateRangeValue;
}(RangeDates));
export { DateRangeValue };
