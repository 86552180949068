import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, ChangeDetectorRef, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { orderBy, State, CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { appConfig } from '../../../../app.config';
import { KendoGridStateHelper } from '../../../../common/models/index';
import { unsubscribe } from '../../../../core/decorators/index';
import { RolesRightModuleRow, RolesRightRow, IRolesRow, RoleRight, RoleColumnsState } from '../../../models/index';
import { RolesManagementService } from '../../../services/index';
var RolesRightGridComponent = /** @class */ (function () {
    function RolesRightGridComponent(managementService, changeDetector) {
        this.sort = [{ field: "displaySequence", dir: "asc" }];
        this.managementService = managementService;
        this.changeDetector = changeDetector;
        this.gridState = new KendoGridStateHelper();
        this.roleRightChanged = new EventEmitter();
        this.roleColumnsState = new RoleColumnsState();
    }
    RolesRightGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appConfig = appConfig;
        this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(function (v) {
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.columnsStateSubscription = this.managementService.rolesColumnsStateChanged$
            .subscribe(function (state) {
            _this.roleColumnsState = state;
            _this.refreshGrid();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.onChangeNotifySubscription = this.managementService.changeNotify$.subscribe(function (v) {
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.filterChangedSubscription = this.managementService.roleRightFilterChanged$.subscribe(function (_a) {
            var filter = _a.filter, type = _a.type;
            if (_this.containerType === type) {
                _this.gridState.state.filter = filter;
                _this.refreshGrid();
                _this.changeDetector.markForCheck();
                _this.changeDetector.detectChanges();
            }
        });
    };
    RolesRightGridComponent.prototype.ngOnDestroy = function () {
        // See #issueWithAOTCompiler
    };
    RolesRightGridComponent.prototype.ngOnChanges = function (changes) {
        if (changes['container']) {
            this.records = this.container.childRows;
            this.refreshGrid();
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
    };
    RolesRightGridComponent.prototype.onAccessClick = function (row, roleId) {
        row.setNextStatus(roleId);
        this.roleRightChanged.emit(roleId);
    };
    RolesRightGridComponent.prototype.refreshGrid = function () {
        if (!this.records) {
            this.gridState.view = null;
            return;
        }
        var ordered = orderBy(this.records, this.sort);
        this.gridState.view = { data: this.setSort(ordered), total: ordered.length };
    };
    RolesRightGridComponent.prototype.setSort = function (records) {
        records.forEach(function (record) {
            return record.roles.forEach(function (right) {
                right.rights = _.sortBy(right.rights, function (item) { return item.displaySequence; });
            });
        });
        return records;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesRightGridComponent.prototype, "onChangeNotifySubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesRightGridComponent.prototype, "gridRefreshSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesRightGridComponent.prototype, "filterChangedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], RolesRightGridComponent.prototype, "columnsStateSubscription", void 0);
    return RolesRightGridComponent;
}());
export { RolesRightGridComponent };
