import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { appConfig } from '../../../app.config';
import { AcaC1095AuditMapService } from './aca-c1095-audit-map.service';
import { configurationConfig } from '../../configuration.config';
var AcaC1095AuditApiService = /** @class */ (function () {
    function AcaC1095AuditApiService(apiUtilService, mapService, urlParamsService) {
        this.apiUtilService = apiUtilService;
        this.mapService = mapService;
        this.urlParamsService = urlParamsService;
    }
    AcaC1095AuditApiService.prototype.getC1095AuditRecords = function (orgLevelId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getC1095AuditApi(orgLevelId, year);
                request = this.urlParamsService.createGetRequest(url, {
                    year: year
                });
                return [2 /*return*/, this.apiUtilService
                        .request(request)
                        .then(function (response) { return _this.mapService.mapC1095AuditRecords(response.data, response.meta); })];
            });
        });
    };
    AcaC1095AuditApiService.prototype.getC1095AuditApi = function (orgLevelId, year) {
        return this.getApiRoot() + "/" + configurationConfig.api.ACA.root + "/" + configurationConfig.api.ACA.c1095Audit.root + "/" + orgLevelId + "/" + year + "/" + configurationConfig.api.ACA.c1095Audit.all;
    };
    AcaC1095AuditApiService.prototype.getApiRoot = function () {
        return appConfig.api.url + "/" + appConfig.api.version;
    };
    return AcaC1095AuditApiService;
}());
export { AcaC1095AuditApiService };
