
<slx-spinner [show]="isLoading">
  <div class="links-container slx-main-content-indents">
    <div></div>
    <div class="link-block"><button class="theme-button-cancel" type="button" *ngIf="clearAllEnable" (click)="clearAllFields()" >Clear All Fields</button></div>
  </div>
  <slx-wizard class="slx-main-content-indents">
    <div [stepSwitch]>
      <slx-add-employee-payroll *stepCase="{name:'1', description: 'Profile'}"
                                [addEmployeeRestrictions]="addEmployeeRestrictions"
                                [organizationLookup]="organizationLookup"
                                [currentOrgLevel]="currentOrgLevel"
                                (onSelectOrganization)="loadRestrictions($event)"
                                (onFormChanged)="onFormChanged()">
      </slx-add-employee-payroll>
      <slx-add-employee-employment *stepCase="{name:'2', description: 'Employment'}"
                                [addEmployeeRestrictions]="addEmployeeRestrictions"
                                (onFormChanged)="onFormChanged()">
      </slx-add-employee-employment>
      <slx-add-employee-personal *stepCase="{name:'3', description: 'Personal'}"
                                [addEmployeeRestrictions]="addEmployeeRestrictions"
                                (onFormChanged)="onFormChanged()">
      </slx-add-employee-personal>
      <slx-add-employee-additional *stepCase="{name:'4', description: 'Additional'}"
                                [addEmployeeRestrictions]="addEmployeeRestrictions"
                                (onFormChanged)="onFormChanged()">
      </slx-add-employee-additional>
      <slx-add-employee-finish *stepDefault>
      </slx-add-employee-finish>
  </div>
  </slx-wizard>
</slx-spinner>

