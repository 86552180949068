import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { BenefitDetailsStandartManagementService, BenefitDetailsPermissionService } from '../../../services/index';
import { BenefitDetailsEditModes, BenefitDetailsTier } from '../../../models/index';
import { NgForm } from '@angular/forms';
var BenefitDetailsCoverageCalcFlatComponent = /** @class */ (function () {
    function BenefitDetailsCoverageCalcFlatComponent(manService, permissionService) {
        this.manService = manService;
        this.permissionService = permissionService;
        this.isEditMode = false;
        this.min = 0;
        this.max = 99999999.99;
        this.step = 1;
        this.value = 0;
        this.format = 'c2';
        this.benefitDetailsOptions = new BenefitDetailsTier();
        this.employeeContribution = 0.00;
        this.employerContribution = 0.00;
        this.subscriptions = {};
    }
    BenefitDetailsCoverageCalcFlatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.editMode = this.permissionService
            .subscribeToEditMode(function (v) { return (_this.isEditMode = v.providerInfo); });
        this.subscriptions.providerTier = this.manService
            .subscribeToSelectProviderTier(function (v) {
            _this.employeeContribution = v.empContribution;
            _this.employerContribution = v.erContribution;
        });
        this.subscriptions.formSubscription = this.ngForm.statusChanges.subscribe(function () {
            _this.manService.updateCanSaveStateByValidity(_this.ngForm.valid);
        });
    };
    BenefitDetailsCoverageCalcFlatComponent.prototype.onChangeContribution = function () {
        {
            if (this.benefitDetailsOptions.empContribution !== this.employeeContribution || this.benefitDetailsOptions.erContribution !== this.employerContribution) {
                this.benefitDetailsOptions.empContribution = this.employeeContribution || null;
                this.benefitDetailsOptions.erContribution = this.employerContribution || null;
                this.manService.updateFlatCoverageOptions(this.employeeContribution, this.employerContribution);
            }
        }
    };
    BenefitDetailsCoverageCalcFlatComponent.prototype.ngOnDestroy = function () {
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], BenefitDetailsCoverageCalcFlatComponent.prototype, "subscriptions", void 0);
    return BenefitDetailsCoverageCalcFlatComponent;
}());
export { BenefitDetailsCoverageCalcFlatComponent };
