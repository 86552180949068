import * as _ from 'lodash';
import { Component, OnInit, Provider, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { NewEnrollmentModel, BenefitTierDefinition, BenefitEmpEnrollmentFormula, FormulaTypeEnum } from '../../../../models';
import { ConfirmOptions, ConfirmDialog2Component } from '../../../../../../common/index';
import { EmployeeSectionsBenefitsManagementApiService, BenefitsEnrollmentSectionManagementService } from '../../../../services';
import { BenefitEnrollmentCommonService } from '../../../../../../../app/app-modules/benefits/services';
import { ModalService } from '../../../../../../common/services';
import { LookupService } from '../../../../../../organization/services';
import { DialogOptions, IDialog, DialogOptions2, DialogModeSize } from '../../../../../../common/models';
import { IApplicationConfig, appConfig } from '../../../../../../app.config';
import { unsubscribeAll } from '../../../../../../core/decorators/index';
import { Subscription } from 'rxjs/Subscription';
@Component({
  selector: 'slx-enroll-employee-formula',
  templateUrl: './enroll-employee-formula.component.html',
  styleUrls: ['./enroll-employee-formula.component.scss']
})
export class EnrollEmployeeFormulaComponent implements OnInit, OnDestroy, IDialog {
  public appConfig: IApplicationConfig;
  public minAmt = 0;
  public maxAmt = 99999999.99;
  public stepcurrency = 0.01;

  public dialogResult: boolean;

  public isLoading: boolean;

  public groupName: string;
  public effectiveDate: Date;

  public payrollDedStartDate: Date;
  public payrollDedEndDate: Date;
  public dedStartDate: Date;
  public dedEndDate: Date;
  public startDate: Date;
  public endDate: Date;
  public canMapPayroll: boolean;
  public hasError: boolean = false;


  public tiers: BenefitTierDefinition[];
  public selectedTier: BenefitTierDefinition;

  public settings: BenefitEmpEnrollmentFormula;
  public isCovCalculated: boolean = true;
  public coverageMultipllierOptions: { name: string, value: number }[];
  public editedItemCoverage: number = null;

  @unsubscribeAll()
  private subscriptions: StringMap<Subscription> = {};

  public static openDialog(
    model: NewEnrollmentModel,
    apiService: EmployeeSectionsBenefitsManagementApiService,
    modalService: ModalService,
    lookupService: LookupService,
    callback?: (result: boolean) => void
  ): EnrollEmployeeFormulaComponent {


    let dialogOptions: DialogOptions2 = new DialogOptions2();
    dialogOptions.fullHeightOnMobile = true;
    dialogOptions.width = 1050;
    dialogOptions.height = 400;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: LookupService,
        useValue: lookupService
      },
      {
        provide: ModalService,
        useValue: modalService
      },
      {
        provide: NewEnrollmentModel,
        useValue: model
      },
      {
        provide: EmployeeSectionsBenefitsManagementApiService,
        useValue: apiService
      }
    ];

    const title = `Enroll Employee in Benefits`;
    let component = modalService.globalAnchor.openDialog2(
      EnrollEmployeeFormulaComponent,
      title,
      dialogOptions,
      resolvedProviders,
      callback
    );

    return component;

  }

  public get userCantEnroll(): boolean {
    return !this.hasSelected
      || !this.hasSelectedValidTierFormula
      || !_.isFinite(this.settings.fixedAmount) && !_.isFinite(this.settings.formulaValue)
      || (this.isFixed ? _.lt(this.settings.fixedAmount, 0) : _.lt(this.settings.formulaValue, 0));
  }

  public get hasSelectedValidTierFormula(): boolean {
    return this.hasEmpContribution && this.hasErContribution;
  }
  public get hasSelected(): boolean {
    return !_.isNil(this.selectedTier);
  }

  public get hasEmpContribution(): boolean {
    if (this.isEmpFormulaType) {
      return this.hasEmpFormulaExpression && this.hasSettings && !_.isEqual(this.settings.empContribution, -1);
    } else {
      return this.hasSettings &&
        _.gte(this.settings.empContribution, 0);
    }

  }

  public get hasErContribution(): boolean {
    if (this.isErFormulaType) {
      return this.hasErFormulaExpression && this.hasSettings && !_.isEqual(this.settings.erContribution, -1);
    } else {
      return this.hasSettings &&
        _.gte(this.settings.erContribution, 0);
    }
  }

  public get hasCoverage(): boolean {
    if (!this.hasSettings)
      return false;
    if (this.isFixed)
      return _.isNumber(this.settings.fixedAmount) && this.settings.fixedAmount >= 0;
    if (this.anyWithMaxCap)
      return _.isNumber(this.settings.maxCap) && this.settings.maxCap > 0;
    if (this.isFormula)
      return this.hasCovFormulaExpression && !_.isEqual(this.settings.formulaValue, -1);
    if (this.multiplerMaxCap)
      return true;
  }

  public get hasSettings(): boolean {
    return !_.isNil(this.settings);
  }
  public get anyWithMaxCap(): boolean {
    return this.settings && this.settings.formulaType === FormulaTypeEnum.AnyWithMaxCap;
  }

  public get multiplerMaxCap(): boolean {
    return this.settings && this.settings.formulaType === FormulaTypeEnum.MultiplerMaxCap;
  }

  public get isFixed(): boolean {
    return this.settings && this.settings.formulaType === FormulaTypeEnum.Fixed;
  }

  public get isFormula(): boolean {
    return this.settings && this.settings.formulaType === FormulaTypeEnum.Formula;
  }


  public get isEmpFormulaType(): boolean {
    return this.settings && this.settings.empFormulaType === FormulaTypeEnum.Formula;
  }

  public get isErFormulaType(): boolean {
    return this.settings && this.settings.erFormulaType === FormulaTypeEnum.Formula;
  }

  public get hasEmpFormulaExpression(): boolean {
    return this.settings && !_.isEmpty(this.settings.empFormulaExpression);
  }
  public get hasErFormulaExpression(): boolean {
    return this.settings && !_.isEmpty(this.settings.erFormulaExpression);
  }
  public get hasCovFormulaExpression(): boolean {
    return this.settings && !_.isEmpty(this.settings.covFormulaExpression);
  }

  private invalidFormulaMessage: string;

  constructor(private model: NewEnrollmentModel, private apiService: EmployeeSectionsBenefitsManagementApiService,
    private commonValidationService: BenefitEnrollmentCommonService,
    private options: DialogOptions, private modalService: ModalService, private lookupService: LookupService, private changeDetector: ChangeDetectorRef, private man: BenefitsEnrollmentSectionManagementService) {
    this.appConfig = appConfig;
  }
  ngOnDestroy(): void {
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.groupName = this.model.plan.name;
    this.effectiveDate = this.model.effectiveDate;
    this.payrollDedStartDate = this.model.effectiveDate;
    this.payrollDedEndDate = _.isNull(this.model.plan.payrollDeductionEndDate) ? this.model.plan.endDate : this.model.plan.payrollDeductionEndDate;
    this.model.plan.payrollDeductionStartDate = this.payrollDedStartDate;
    this.model.plan.payrollDeductionEndDate = this.payrollDedEndDate;
    this.dedStartDate = _.isNull(this.model.plan.dedStartDate) ? this.model.plan.startDate : this.model.plan.dedStartDate;
    this.dedEndDate = _.isNull(this.model.plan.dedEndDate) ? this.model.plan.endDate: this.model.plan.dedEndDate;
    this.endDate = this.model.plan.endDate;
    this.startDate = _.isNull(this.model.plan.startDate) ? this.model.effectiveDate: this.model.plan.startDate; 
    this.canMapPayroll = this.model.canMapPayroll;
    this.apiService.getBenefitTiers(this.model.plan.benefitLineId)
      .then((tiers: BenefitTierDefinition[]) => {
        this.tiers = tiers;
        this.isLoading = false;
      });

    this.subscriptions.saveButtonState = this.man.subscribeToChangePayrollDeductionDate((hasError: boolean) => {
      this.hasError = hasError;
    });

  }

  public onChangeTier(tier: BenefitTierDefinition): void {
    this.isCovCalculated = true;
    this.selectedTier = tier;
    this.isLoading = true;
    this.effectiveDate = this.model.effectiveDate;
    let empId = this.model.empId;
    this.apiService.getBenefitPlanFormula(tier.id, empId, this.effectiveDate, null, '')
      .then((settings: BenefitEmpEnrollmentFormula) => {
        this.settings = settings;
        this.maxAmt = settings.maxCap;
        if (this.multiplerMaxCap || this.anyWithMaxCap) {
          let options = [];
          if (this.multiplerMaxCap) {
            const mult = _.isNumber(settings.multipler) ? settings.multipler : 500;
            const cap = _.isNumber(settings.maxCap) ? settings.maxCap : 10000;
            const range = _.range(mult, cap + mult, mult);
            options = _.map(range, value => ({ name: _.toString(value), value }));
          }
          this.coverageMultipllierOptions = options;
        } else {
          this.settings.formulaValue = _.isFinite(this.settings.formulaValue) ? this.settings.formulaValue : null;
          this.coverageMultipllierOptions = [];
        }

        this.isLoading = false;
        this.validateFormula();
      });
  }

  private validateFormula(): void {
    if (this.settings.erFormulaExpression || this.settings.empFormulaExpression || this.settings.covFormulaExpression) {
      this.formulaSelectionStart(true);
    }
  }

  public onChangeCoverageWithMultiplier(value: any): void {
    const numericValue = parseInt(value);
    if (isNaN(numericValue)) {
      this.settings.formulaValue = null;
      return;
    }
    this.settings.formulaValue = value;
    this.calculateFormula();
  }

  public calculateFormula() {
    if (this.settings.calculatedCoverage !== this.settings.formulaValue && !_.isNil(this.settings.formulaValue)) {

      if (this.isFormula || this.multiplerMaxCap || this.anyWithMaxCap) {
        this.isLoading = true;
        this.effectiveDate = this.model.effectiveDate;
        let empId = this.model.empId;
        this.settings.calculatedCoverage = this.settings.formulaValue;
        this.apiService.getBenefitPlanFormula(this.selectedTier.id, empId, this.effectiveDate, this.settings.formulaValue, '')
          .then((record: BenefitEmpEnrollmentFormula) => {
            if (record) {
              this.settings.calculatedCoverage = record.calculatedCoverage;
              this.settings.empContribution = record.empContribution;
              this.settings.erContribution = record.erContribution;
              this.settings.maxCap = this.maxAmt = record.maxCap;
              if (this.multiplerMaxCap || this.anyWithMaxCap) {
                this.settings.formulaValue = record.calculatedCoverage;
              }
            }
          }).finally(() => {
            this.isLoading = false;
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
          });
      }
    }
  }

  public onCoverageValueChange(value: number): void {
    this.settings.formulaValue = value;
    this.isCovCalculated = false;
  }

  public onEnroll(element: any): void {
    if (element == "Enroll") {
      this.model.formulaSettings = this.settings;
      this.dialogResult = true;
      this.isCovCalculated = false;
      this.modalService.closeWindow(this.options.windowUniqueId);
    } else if (element == "Calculate") {
      this.calculateFormula();
      this.isCovCalculated = true;
    }
  }

  public onCancel(): void {
    let options: ConfirmOptions = new ConfirmOptions();
    options.showCancel = true;
    options.showOK = true;
    options.buttonOKtext = 'Yes';
    ConfirmDialog2Component.openDialog(
      'Discard Changes',
      'Are you sure you want to cancel? You will lose all unsaved selections.',
      this.modalService,
      (isCancel: boolean) => {
        if (isCancel) {
          this.dialogResult = false;
          this.modalService.closeWindow(this.options.windowUniqueId);
        }
      },
      options);
  }

  public getFormulaToolTip(): string {
    return this.invalidFormulaMessage ? this.invalidFormulaMessage : 'Employee cannot be enrolled as amounts have not been created under coverage options for this benefit plan. Please create coverage options for this plan to enroll';
  }

  public formulaSelectionStart(wrongValue?: boolean): void {
    const message = this.commonValidationService.getFormulaError(
      this.isEmpFormulaType,
      this.isErFormulaType,
      this.isFormula,
      this.settings.empContribution,
      this.settings.erContribution,
      this.settings.formulaValue
    );

    if ((_.size(this.coverageMultipllierOptions) === 0 || wrongValue) && _.size(message) > 0) {
      const options = new ConfirmOptions();
      options.showCancel = false;
      options.showOK = true;
      ConfirmDialog2Component.openDialog(
        'Warning',
        message,
        this.modalService,
        (result: boolean) => {
          this.invalidFormulaMessage = message;
        },
        options
      );
    }
  }
  public OnDeductionEndDateChange(date: Date) {
    this.model.plan.payrollDeductionEndDate = date;
  }
  public OnDeductionStartDateChange(date: Date) {
    this.model.plan.payrollDeductionStartDate = date;
  }


}
