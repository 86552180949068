<div *ngIf="node.isExpanded && !templates.treeNodeChildrenTemplate" [class.tree-children]="true">
     <div *ngIf="node.children">
        <slx-tree-node-component
          *ngFor="let childNode of node.children; let i = index"
          [node]="childNode"
          [index]="i"
          [templates]="templates"
          >
        </slx-tree-node-component>
    </div>
</div>
<ng-template
    [ngTemplateOutlet]="templates.treeNodeChildrenTemplate"
    [ngTemplateOutletContext]="{ $implicit: node, node: node, templates: templates }">
</ng-template>
