import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { ExportDataApiService } from './export-data-api.service';
import { Assert } from '../../../framework/index';
import { mutableSelect, unsubscribeAll, unsubscribe } from '../../../core/decorators';
import { FileService } from '../../../common';
import { ExportDataEventService } from '../../../common/services/export-data/export-data-event.service';
import { ExportDataMapService } from './export-data-map.service';
import { UserActivityService } from '../../../core/services/user-activity/user-activity.service';
import { TokenValidityService, PollingPeriodService } from '../../../core/services';
import { ExportDataStatus } from '../enums/export-data-status';
import { appConfig } from '../../../app.config';
var ExportDataManagementService = /** @class */ (function () {
    function ExportDataManagementService(apiService, mapService, fileService, userActivityService, tokenValidity, exportDataEventService) {
        this.apiService = apiService;
        this.mapService = mapService;
        this.fileService = fileService;
        this.userActivityService = userActivityService;
        this.tokenValidity = tokenValidity;
        this.exportDataEventService = exportDataEventService;
        this.loading$ = new Subject();
        this.orgLevelChanged$ = new ReplaySubject(1);
        this.configurationsLoaded$ = new ReplaySubject(1);
        this.historyLoaded$ = new ReplaySubject(1);
        this.statusLoaded$ = new ReplaySubject(1);
        this.exportExecuted$ = new ReplaySubject(1);
        this.subscriptions = {};
        this.applicationHeartbeatService = new PollingPeriodService(this.userActivityService, this.tokenValidity);
    }
    ExportDataManagementService.prototype.init = function () {
        var _this = this;
        this.subscribeToOrgLevelChanges();
        this.exportDataEventService.init();
        this.subscriptions.exportDataStatusChanged = this.exportDataEventService.exportDataStateChanged$.subscribe(function (args) {
            var executionNotification = args && args.length > 2 ? JSON.parse(args[2]) : null;
            if (executionNotification) {
                _this.statusLoaded$.next([_this.mapService.mapNotificationToExportDataStatus(executionNotification)]);
            }
        });
        this.subscriptions.notificationStateChanged = this.exportDataEventService.notificationStateChanged$.subscribe(function (enabled) {
            if (enabled) {
                if (_this.poolingSubscription) {
                    _this.poolingSubscription.unsubscribe();
                    _this.applicationHeartbeatService.stop();
                }
            }
            else {
                if (!_this.poolingSubscription || _this.poolingSubscription.closed) {
                    _this.poolingSubscription = _this.applicationHeartbeatService.onHeartbeat.subscribe(function () {
                        if (_this.exportConfigurations) {
                            var ids = _this.getStatusesToUpdate();
                            if (ids && ids.length > 0) {
                                _this.loadExportDataStatuses(ids);
                            }
                        }
                    });
                    _this.applicationHeartbeatService.listen(appConfig.notifyPoolingInterval);
                }
            }
        });
    };
    ExportDataManagementService.prototype.ngOnDestroy = function () {
        this.orgLevel = null;
        this.loading$.complete();
        this.configurationsLoaded$.complete();
        this.statusLoaded$.complete();
        this.historyLoaded$.complete();
        this.exportExecuted$.complete();
        this.orgLevelChanged$.complete();
    };
    ExportDataManagementService.prototype.getStatusesToUpdate = function () {
        var ids = [];
        _.each(this.exportConfigurations, function (c) {
            if (c.lastExecuted && (c.lastExecuted.status === ExportDataStatus.InProgress || c.lastExecuted.status === ExportDataStatus.Waiting)) {
                ids.push(c.lastExecuted.id);
            }
        });
        return ids;
    };
    ExportDataManagementService.prototype.subscribeToLoading = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.loading$.subscribe(callback);
    };
    ExportDataManagementService.prototype.subscribeToOrgLevel = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.orgLevelChanged$.subscribe(callback);
    };
    ExportDataManagementService.prototype.setLoadingStatus = function (isLoading) {
        this.loading$.next(isLoading);
    };
    ExportDataManagementService.prototype.subscribeToOrgLevelChanges = function () {
        var _this = this;
        this.subscriptions.orgLevel = this.orgLevel$
            .filter(function (o) { return _.isFinite(_.get(o, 'id')); })
            .subscribe(function (orgLevel) {
            if (_.isFinite(_.get(_this.orgLevel, 'id')) && _this.orgLevel.id === orgLevel.id)
                return;
            _this.orgLevel = orgLevel;
            _this.orgLevelChanged$.next(_this.orgLevel);
            _this.configurationsLoaded$.next();
            _this.historyLoaded$.next();
            _this.statusLoaded$.next();
            _this.exportExecuted$.next();
        });
    };
    ExportDataManagementService.prototype.subscribeToLoadedConfigurations = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.configurationsLoaded$.subscribe(callback);
    };
    ExportDataManagementService.prototype.subscribeToLoadedHistory = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.historyLoaded$.subscribe(callback);
    };
    ExportDataManagementService.prototype.subscribeToLoadedStatuses = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.statusLoaded$.subscribe(callback);
    };
    ExportDataManagementService.prototype.subscribeToExportExecuted = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.exportExecuted$.subscribe(callback);
    };
    ExportDataManagementService.prototype.loadExportDataConfiguration = function () {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getExportConfigurationList(this.orgLevel.id)
            .then(function (data) {
            _this.exportConfigurations = data;
            _this.configurationsLoaded$.next(data);
            _this.loading$.next(false);
        });
    };
    ExportDataManagementService.prototype.loadExportDataHistory = function (configurationId) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.getExportDataHistory(this.orgLevel.id, configurationId)
            .then(function (data) {
            _this.historyLoaded$.next(data);
            _this.loading$.next(false);
        });
    };
    ExportDataManagementService.prototype.loadExportDataStatuses = function (ids) {
        var _this = this;
        this.apiService.getExportDataStatuses(this.orgLevel.id, ids)
            .then(function (data) {
            _this.statusLoaded$.next(data);
        });
    };
    ExportDataManagementService.prototype.generateExportData = function (configurationId, params) {
        var _this = this;
        this.loading$.next(true);
        var req = this.mapService.mapToExportDataRequest(this.orgLevel.id, configurationId, params);
        this.apiService.generateExportData(req)
            .then(function (data) {
            _this.exportExecuted$.next(data);
            _this.historyLoaded$.next([data]);
            _this.loading$.next(false);
        });
    };
    ExportDataManagementService.prototype.downloadExportData = function (executionId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var file, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, this.apiService.downloadExportData(executionId)];
                    case 2:
                        file = _a.sent();
                        this.fileService.saveToFileSystem(file.blob, file.file);
                        this.loading$.next(false);
                        return [3 /*break*/, 5];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 4:
                        this.loading$.next(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ExportDataManagementService.prototype.cancelExportData = function (configurationId, executionId) {
        var _this = this;
        this.loading$.next(true);
        this.apiService.cancelExportData(configurationId, executionId)
            .then(function (data) {
            var status = { configurationId: configurationId, executionId: executionId, status: ExportDataStatus.Cancelled, changedOn: moment().toDate(), reason: null };
            _this.statusLoaded$.next([status]);
            _this.loading$.next(false);
        });
    };
    tslib_1.__decorate([
        mutableSelect(['orgLevel']),
        tslib_1.__metadata("design:type", Observable)
    ], ExportDataManagementService.prototype, "orgLevel$", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], ExportDataManagementService.prototype, "poolingSubscription", void 0);
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], ExportDataManagementService.prototype, "subscriptions", void 0);
    return ExportDataManagementService;
}());
export { ExportDataManagementService };
