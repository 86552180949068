import * as tslib_1 from "tslib";
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { BenefitListApiService } from './benefit-list-api.service';
import { Assert } from '../../../../framework/index';
var BenefitPayrollMappingManagementService = /** @class */ (function () {
    function BenefitPayrollMappingManagementService(apiService) {
        this.apiService = apiService;
        this.benefitPayrollMappingLoaded$ = new ReplaySubject(1);
        this.benefitPayrollExistingMappingLoaded$ = new ReplaySubject(1);
    }
    BenefitPayrollMappingManagementService.prototype.destroy = function () {
        this.benefitPayrollMappingLoaded$.complete();
        this.benefitPayrollExistingMappingLoaded$.complete();
    };
    BenefitPayrollMappingManagementService.prototype.subscribeToBenefitPayrollMapping = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitPayrollMappingLoaded$.subscribe(callback);
    };
    BenefitPayrollMappingManagementService.prototype.subscribeToBenefitPayrollExistingMapping = function (callback) {
        Assert.isNotNull(callback, 'callback');
        return this.benefitPayrollExistingMappingLoaded$.subscribe(callback);
    };
    BenefitPayrollMappingManagementService.prototype.getBenefitPayRollMapping = function (showIgnored) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.apiService.getBenefitPayRollMapping(showIgnored)
                    .then(function (container) {
                    _this.benefitPayrollMappingLoaded$.next(container);
                });
                return [2 /*return*/];
            });
        });
    };
    BenefitPayrollMappingManagementService.prototype.getpayrollExistingMapping = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.apiService.getpayrollExistingMapping()
                    .then(function (container) {
                    _this.benefitPayrollExistingMappingLoaded$.next(container);
                });
                return [2 /*return*/];
            });
        });
    };
    BenefitPayrollMappingManagementService.prototype.benefitPayRollCreateMapping = function (req) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.apiService.benefitPayRollCreateMapping(req)];
            });
        });
    };
    return BenefitPayrollMappingManagementService;
}());
export { BenefitPayrollMappingManagementService };
