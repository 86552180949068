import { ITImecardSummarySectionContainer } from './timecard-summary-section-container.interface';

export class PeriodsSummaryContainer implements ITImecardSummarySectionContainer {
    public records: TimecardSummaryPeriodCompare[];
}

export class TimecardSummaryPeriodCompare {
    public payCycleStartDate: Date;
    public productiveHours: number;
    public nonProductiveHours: number;
    public totalHours: number;
    public differenceHours: number;
    public percentageHours: number;
    public payrollAmount: number;
    public differenceAmount: number;
    public differencePercentage: number;
}
