import {
  EmployeeSectionsPayroll,
  IEmployeeSectionsPayroll,
  IEmployeeSectionsPositions,
  EmployeeSectionsPositions,
  IEmployeeSectionsRateHistory,
  EmployeeSectionsRateHistory,
  IEmployeeSectionsPositionHistory,
  EmployeeSectionsPositionHistory,
  EmployeeSectionsTerminationHistoryEntries,
  IEmployeeSectionsTerminationHistoryEntries,
  IEmployeeSectionsPayCycles,
  EmployeeSectionsPayCycles,
  IEmployeeSectionTimeclockContainer, EmployeeSectionTimeclockContainer,
  EmployeeSectionsSelfService,
  IEmployeeSectionsSelfService,
  EmployeeSectionsI9,
  IEmployeeSectionsI9,
  IEmployeeSectionsEssTemplate, EmployeeSectionsEssTemplate,
  EmployeeLeaveManagement, IEmployeeLeaveManagement,
  TelepunchSubsection,
  ITelepunchSubsection
} from './index';
import { IEmployeeSectionsSubsectionContainer } from '../index';
import { IEmployeeSubsectionBenefitClasses, EmployeeSubsectionBenefitClasses } from '../employee-sections-benefits-management/employee-sections-benefit-classes';
import { WcIncidentRosterContainer, IWcIncidentRosterContainer } from '../../../../app-modules/workers-compensation/models';
import { IEmployeeIncidents, EmployeeIncidents } from './employee-incidents';
import { IEmployeeSectionsReviews, EmployeeSectionsReviews } from './employee-sections-reviews';
import { IEmployeeSectionsACA, EmployeeSectionsACA } from './employee-sections-aca';

export interface IEmployeeSectionsEmployment {
  payroll: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsPayroll>;
  positions: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsPositions>;
  terminationHistory: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsTerminationHistoryEntries>;
  rateHistory: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsRateHistory>;
  positionHistory: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsPositionHistory>;
  payCycles: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsPayCycles>;
  timeclocks: IEmployeeSectionsSubsectionContainer<IEmployeeSectionTimeclockContainer>;
  selfService?: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsSelfService>;
  essTemplate?: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsEssTemplate>;
  i9: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsI9>;
  leaveManagement: IEmployeeSectionsSubsectionContainer<IEmployeeLeaveManagement>;
  workersCompensation: IEmployeeSectionsSubsectionContainer<IEmployeeIncidents>;
  performanceManagement: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsReviews>;
  telepunch: IEmployeeSectionsSubsectionContainer<ITelepunchSubsection>;
  aca: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsACA>;
}

export class EmployeeSectionsEmployment {
  // public static dataMock: IEmployeeSectionsEmployment = {
  //   payroll: {
  //     data: EmployeeSectionsPayroll.dataMock,
  //     metadata: EmployeeSectionsPayroll.metaMock,
  //   }
  //   ,
  //   terminationHistory: { data: EmployeeSectionsTerminationHistoryEntries.dataMock, metadata: EmployeeSectionsTerminationHistoryEntries.metaMock }
  // };

  public payroll: EmployeeSectionsPayroll;
  public positions: EmployeeSectionsPositions;
  public terminationHistory: EmployeeSectionsTerminationHistoryEntries;
  public rateHistory: EmployeeSectionsRateHistory;
  public positionHistory: EmployeeSectionsPositionHistory;
  public payCycles: EmployeeSectionsPayCycles;
  public timeclocks: EmployeeSectionTimeclockContainer;
  public selfService?: EmployeeSectionsSelfService;
  public essTemplate?: EmployeeSectionsEssTemplate;
  public i9: EmployeeSectionsI9;
  public leaveManagement: EmployeeLeaveManagement;
  public workersComp: EmployeeIncidents;
  public performanceManagement: EmployeeSectionsReviews;
  public telepunch: TelepunchSubsection;
  public aca: EmployeeSectionsACA;
}
