import { QueryList, AfterContentInit } from '@angular/core';
import * as _ from 'lodash';
var ErrorMessagesDirective = /** @class */ (function () {
    function ErrorMessagesDirective() {
    }
    Object.defineProperty(ErrorMessagesDirective.prototype, "errors", {
        set: function (errors) {
            this.lastErrors = errors;
            if (!this.messages) {
                return;
            }
            this.setErrors(errors);
        },
        enumerable: true,
        configurable: true
    });
    ErrorMessagesDirective.prototype.ngAfterContentInit = function () {
        if (!this.messages)
            return;
        if (!this.lastErrors)
            return;
        this.setErrors(this.lastErrors);
    };
    ErrorMessagesDirective.prototype.setErrors = function (errors) {
        var isFirstError = true;
        var errorMessages = this.messages.map(function (errorMessage) { return errorMessage; });
        _.each(errorMessages, function (errorMessage) {
            var hasError = !!errors ? errors.hasOwnProperty(errorMessage.for) : false;
            if (hasError && isFirstError) {
                var errValue = errors[errorMessage.for];
                if (_.isString(errValue)) {
                    errorMessage.setText(errValue);
                }
                errorMessage.show();
                isFirstError = false;
            }
            else {
                errorMessage.hide();
            }
        });
    };
    return ErrorMessagesDirective;
}());
export { ErrorMessagesDirective };
