export const AccrualPolicyDialogInfo: any = {
    dialogInfoData: [
        { 
            id: 1, 
            title: `* Accrued Based On: * `,
            description: `This is calculation based on * Periods * such as Hours Worked, or calculation based on * Specific dates * such as anniversary, beginning of the year, or a holiday.`,
            description2: `Selecting a calculation method by selecting the appropriate radio button will change the format of the Seniority section of the window.`
        },
        { 
            id: 2, 
            title: `* Basis Hours: * `,
            description: `The maximum number of hours that can be earned during the applicable accrual period, Valid entries from 1 to 999.00 `,
            description2: `For example, a FT accrual policy which accrues per pay period that is assigned to employees working a 40 hours a week, may have 80 hours will not count in accrual calculation.`
        },
        { 
            id: 3, 
            title: `* Minimum Hours to Qualify: * `,
            description: `This is the minimum number of hours an employee must have in a given week to accrue.`,
            description2: ``
        },
        { 
            id: 4,
            title: `* Probationary Period: *`, 
            description: `This is a period from employee date of hire during which employee does not earn.`,
            description2: `Example: 90-day probation period means employee will not have any time accrued during the first 90 days and will start accruing as of day 91.`
        },
        { 
            id: 5, 
            title: `* Deferral Period: * `,
            description: `A period from employee date of hire during which employee earns but does not receive pay. Deferred balances will move to earned balance at the conclusion of the deferral period.`,
            description2: ``
        },
        { 
            id: 6, 
            title: `* Anchor Date: * `,
            description: `This is a date to represent the date of hire for accrual purposes.`,
            description2: ``
        },
        { 
            id: 7, 
            title: `* Annual Max Year: * `,
            description: `Maximum number of hours that can be earned during a calendar or anniversary year.`,
            description2: ``
        },
        { 
            id: 8, 
            title: `* Carry Over: * `,
            description: `This is the carryover amount allowed at the end of accrual year.`,
            description2: `All carry overs will stay within a benefit type.`
        },
        { 
            id: 9, 
            title: `* All other hours move to: * `,
            description: `This is a combination of all hours/balances that have moved over to a different benefit type.`,
            description2: `Example: On Jan 2nd every year all hours get moved into Holiday from Personal Holiday.`
        },
        { 
            id: 10, 
            title: `* Max roll over hours is: * `,
            description: `The total accumulated Maximum Rollover of unused time within a year "Roll overs can move to another benefit type"`,
            description2: ``
        },
        { 
            id: 11, 
            title: `* Accrual Based on: * `,
            description: `Accrued based on defines the interval at which hours accrue for the benefit.`,
            description2: `For example, a regular, full-time employee will start accruing either by Pay Period, Weekly, Biweekly or on Anniversary.`
        },
        {
            id: 12,
            title: `* Rate: *`,
            description: `This is the amount that a benefit accumulates, and it is generally hours but can be adjusted as follows: workdays or timeframe in which the rate is calculated against`,
            description2: `Example: Hour, Day, Week, Pay Period, Month, Year`
        }
    ]
};