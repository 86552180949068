import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReportParameter } from '../../../models/index';
import { ReportsManagementService } from '../../../services/index';

@Component({
  selector: 'slx-decimal-report-parameter',
  templateUrl: './decimal-report-parameter.component.html',
  styleUrls: ['./decimal-report-parameter.component.scss']
})
export class DecimalReportParameterComponent implements OnInit {
  @Input() parameter: ReportParameter;
  istotalHrsDisabled: boolean = true;
  isConsecHrsDisabled: boolean = true;
  isRangeCorrect: boolean = false;
  isDisabled: boolean = true;
  isTotalHrs: boolean = false;
  isConsecHrs: boolean = false;
  zeroTotalHours: boolean = false;
  format= "n0";
  decimals =2;
  max: number;
  constructor(private reportManServce: ReportsManagementService) { }

  ngOnInit() {
    if (this.parameter.name == "Total_hrs" || this.parameter.name == "consecutive_hrs") {
      this.parameter.value = 8;
      this.parameter.defaultValue = false;
    }
    this.max = (this.parameter.name == "consecutive_hrs") ? 24 : 299.99;
  }

  handleInputVal(event) {
   if(event!=null) this.format = event.toString().includes('.') ? "n2" : "n0"; 
      if (this.parameter.name == "Total_hrs") this.parameter.value = event;
    if (this.parameter.name == "consecutive_hrs" && this.isConsecHrs) this.parameter.value = event;
    if (this.parameter.name == "Total_hrs" && event == null) {
      this.parameter.defaultValue = false;
      this.parameter.value = null;
    }
    if(this.isDisabled == false) this.parameter.defaultValue = true;
    this.checkEnteredRange(event);
  }

  checkEnteredRange(value) {
    this.isRangeCorrect = (value < 0.01 || value > this.max) ? true : false;
  }

  handleSelectedRNHrs(event) {
     this.isRangeCorrect = false;
    if (this.parameter.name == "Total_hrs")  this.isTotalHrs = event;
      if (this.parameter.name == "consecutive_hrs") this.isConsecHrs = event;
      if (this.parameter.name == "Total_hrs" || this.parameter.name == "consecutive_hrs") {
      this.parameter.defaultValue = event;
      this.format = "n0";
     this.parameter.value = 8; 
    }
    this.isDisabled = event == false ? true : false;
  }
}
