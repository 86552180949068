/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./benefit-plan-type.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../components-library/components/buttons/collapse-button/collapse-button.component.ngfactory";
import * as i4 from "../../../../../../components-library/components/buttons/collapse-button/collapse-button.component";
import * as i5 from "../../../../../../components-library/models/collapse-component";
import * as i6 from "../../../../../../components-library/directives/collapse/collapse-by-css.directive";
import * as i7 from "../benefit-type-section-grid/benefit-type-section-grid.component.ngfactory";
import * as i8 from "../benefit-type-section-grid/benefit-type-section-grid.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../../services/benefit-console/benefit-list-management.service";
import * as i11 from "../../../../services/benefit-console/benefit-console-common.service";
import * as i12 from "./benefit-plan-type.component";
var styles_BenefitPlanTypeComponent = [i0.styles];
var RenderType_BenefitPlanTypeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BenefitPlanTypeComponent, data: {} });
export { RenderType_BenefitPlanTypeComponent as RenderType_BenefitPlanTypeComponent };
function View_BenefitPlanTypeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "slx-button slx-blue slx-no-breaks"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createNewPlans() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" + New "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.canCreateNew; _ck(_v, 0, 0, currVal_0); }); }
export function View_BenefitPlanTypeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "report-section white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "report-section-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "report-section-header-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "header-item header-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "em", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "header-title__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "report-section-header-button-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BenefitPlanTypeComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "slx-collapse-button", [["className", "slx-with-icon"]], null, [[null, "isCollapsedChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isCollapsedChange" === en)) {
        var pd_0 = (_co.collapsedChange($event, _co.groupId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_CollapseButtonComponent_0, i3.RenderType_CollapseButtonComponent)), i1.ɵdid(11, 49152, null, 0, i4.CollapseButtonComponent, [], { className: [0, "className"], isCollapsed: [1, "isCollapsed"] }, { isCollapsedChange: "isCollapsedChange" }), i1.ɵprd(2048, null, i5.COLLAPSE_COMPONENT, null, [i4.CollapseButtonComponent]), i1.ɵdid(13, 4341760, null, 0, i6.CollapseByCssDirective, [i5.COLLAPSE_COMPONENT, i1.Renderer2], { elementToCollapse: [0, "elementToCollapse"] }, null), (_l()(), i1.ɵeld(14, 0, [["collapseContent", 1]], null, 2, "div", [["class", "report-section-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "benefit-type-section-grid", [], null, null, null, i7.View_BenefitTypeSectionGridComponent_0, i7.RenderType_BenefitTypeSectionGridComponent)), i1.ɵdid(16, 245760, null, 0, i8.BenefitTypeSectionGridComponent, [i9.ActivatedRoute, i9.Router, i10.BenefitListManagementService, i11.BenefitConsoleCommonService], { groupId: [0, "groupId"], collapsedState: [1, "collapsedState"], index: [2, "index"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.creatPlanAccess; _ck(_v, 9, 0, currVal_2); var currVal_3 = "slx-with-icon"; var currVal_4 = _co.getCollapsedState(); _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 14); _ck(_v, 13, 0, currVal_5); var currVal_6 = _co.groupId; var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.getCollapsedState(), ""); var currVal_8 = _co.index; _ck(_v, 16, 0, currVal_6, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.icon, ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 6, 0, currVal_1); }); }
export function View_BenefitPlanTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-benefit-plan-type", [], null, null, null, View_BenefitPlanTypeComponent_0, RenderType_BenefitPlanTypeComponent)), i1.ɵdid(1, 245760, null, 0, i12.BenefitPlanTypeComponent, [i10.BenefitListManagementService, i9.ActivatedRoute, i9.Router, i11.BenefitConsoleCommonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BenefitPlanTypeComponentNgFactory = i1.ɵccf("slx-benefit-plan-type", i12.BenefitPlanTypeComponent, View_BenefitPlanTypeComponent_Host_0, { title: "title", icon: "icon", groupId: "groupId", index: "index", creatPlanAccess: "creatPlanAccess", validatingFunction: "validatingFunction" }, {}, []);
export { BenefitPlanTypeComponentNgFactory as BenefitPlanTypeComponentNgFactory };
