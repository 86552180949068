 import * as moment from 'moment';
import { DetailGroup } from '../detail-screen';
 import { ScheduleAbsence } from './../../../organization/models/lookup/schedule-absence';
export class ShiftReplacementRequest {
  //public filters any[]; <-- TBD how implement
  public showDayOffEmployees: boolean;
  public showAgencyEmployees: boolean;
  public showAvailabilityRecords: boolean;
  public showEmployeesOtherShifts: boolean;
  public shiftId: number;
  public shiftName: string;
  public organizationName: string;
  public unitId: number;
  public unitName: string;
  public positionId: number;
  public positionName: string;
  public date: Date;
  public shiftStart: Date;
  public shiftEnd: Date;
  public orgLevelId: number;
  public replacedEmployeeName: string;
  public replacedEmployeeId: number;
  public scheduleAbsence: ScheduleAbsence;
  public showSendSmsButton: boolean = false;
  public momentShiftStart: moment.Moment;
  public momentShiftEnd: moment.Moment;
  public momentActualShiftStart: moment.Moment;
  public momentActualShiftEnd: moment.Moment;
  public currentDayDUAData?: DetailGroup[];
  public previousDayDUAData?: DetailGroup[];
  public nextDayDUAData?: DetailGroup[];
}

export class GroupDetailsData {
  public request: ShiftReplacementRequest;
  public groups: DetailGroup[];
  public previousDayGroups: DetailGroup[];
  public nextDayGroups: DetailGroup[];
 }

