import { IPosition, Position, IPositionGroup, PositionGroup } from '../../../organization/models/index';

export interface IPositionGroupView {
  groupId: number;
  positionIds: number[];
}

export class PositionGroupView extends PositionGroup {
  public positions: Position[];
  public isEditing: boolean;
}
