import { Injectable } from '@angular/core';
import { ApiUtilService, UrlParamsService } from '../../../../common/services/index';
import { Meta, ResponseBody } from '../../../../core/models/index';
import { MealDeductionMapService } from './meal-deduction-map.service';
import { MealDeductionSettings, IMealDeductionSettingsDTO } from '../../models/index';
import { pbjConfig } from '../../pbj.config';

@Injectable()
export class MealDeductionApiService {
    constructor(private apiUtilService: ApiUtilService, private urlParamsService: UrlParamsService, private mapService: MealDeductionMapService) {
    }

    public getOrganizationMealDeductionSettings(organizationId: number): Promise<MealDeductionSettings> {
        const url = `${this.getMealDeductionsApiRoot()}/${organizationId}/${pbjConfig.api.pbj.mealDeduction.settings}`;
        let promise: Promise<MealDeductionSettings> = this.apiUtilService.request<IMealDeductionSettingsDTO, Meta>(this.urlParamsService.createGetRequest(url))
            .then((response: ResponseBody<IMealDeductionSettingsDTO, Meta>) =>
                this.mapService.mapMealDeductionSettings(response.data));
        return promise;
    }

    public addOrganizationMealDeductionSettings(settings: MealDeductionSettings): Promise<any> {
        const url = `${this.getMealDeductionsApiRoot()}/${settings.organizationId}/${pbjConfig.api.pbj.mealDeduction.settings}`;
        const request = this.urlParamsService.createPostRequest(url, this.mapService.mapMealDeductionSettingsToDTO(settings));
        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }

    public saveOrganizationMealDeductionSettings(settings: MealDeductionSettings): Promise<any> {
        const url = `${this.getMealDeductionsApiRoot()}/${settings.organizationId}/${pbjConfig.api.pbj.mealDeduction.settings}`;
        const request = this.urlParamsService.createPutRequest(url, this.mapService.mapMealDeductionSettingsToDTO(settings));
        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }

    public deleteOrganizationMealDeductionSettings(settings: MealDeductionSettings): Promise<any> {
        const url = `${this.getMealDeductionsApiRoot()}/${settings.organizationId}/${pbjConfig.api.pbj.mealDeduction.settings}`;
        const request = this.urlParamsService.createDeleteRequest(url);
        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }

    private getMealDeductionsApiRoot(): string {
        return `${this.getExportApiRoot()}/${pbjConfig.api.pbj.mealDeduction.root}`;
    }

    private getExportApiRoot(): string {
        return `${this.apiUtilService.getApiRoot()}/${pbjConfig.api.pbj.root}`;
    }
    public getAllOrganizationIds(parentId :number):Promise<any> {
        const url = `${this.getMealDeductionsApiRoot()}/${parentId}/${pbjConfig.api.pbj.mealDeduction.allOrganizations}`;
        const request = this.urlParamsService.createGetRequest(url);
        let promise: Promise<any> = this.apiUtilService.request<any, Meta>(request);
        return promise;
    }
}
