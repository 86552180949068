import { TimelineComponentConfigiration, TimelineComponentDrawConfigiration, TimelineHelper } from './timeline-helper';
import { ITimelineItem } from '../../models/index';
import * as domUtils from '../../utils/domUtils';


export class TimelineExpandHelper extends TimelineHelper {
  public eventsMinLapse: number;
  public eventsMinDistance: number;
  constructor(config: TimelineComponentConfigiration) {
    super(config);
  }

  public updateSlide(timelineTotWidth: number, forward: boolean): void {
    let translateValue = this.getTranslateValue(this.config.eventsWrapper.nativeElement);

    if (forward) {
      this.translateTimeline(translateValue - this.defaultTimelineWrapperWidth + this.eventsMinDistance, this.defaultTimelineWrapperWidth - timelineTotWidth);
    } else {
      this.translateTimeline(translateValue + this.defaultTimelineWrapperWidth - this.eventsMinDistance, null);
    }
  }

  public updateFillingStartByDate(date: Date): void {
    const distanceNorm = this.calcDistanceNorm(this.startDate, date);
    let scaleValue = 0;
    if (distanceNorm > 0) {
      scaleValue = (distanceNorm + this.elementWidth / 2) / (this.eventsWrapperWidth);
    }
    this.setTransformValue(this.config.fillingLineStart.nativeElement, 'scaleX', scaleValue);
    this.translateTimeline(this.parentWidth / 2 - distanceNorm, null);

  }

  public updateFillingEndByDate(date: Date): void {
    const distanceFromStartNorm = this.calcDistanceNorm(this.startDate, date);
    const distanceNorm = this.calcDistanceNorm(date, this.endDate);
    let scaleValue = 0;
    if (distanceNorm > 0) {
      scaleValue = (distanceNorm - this.elementWidth / 2) / this.eventsWrapperWidth;
    }
    this.setTransformValue(this.config.fillingLineEnd.nativeElement, 'scaleX', scaleValue);
    this.translateTimeline(this.parentWidth / 2 - distanceFromStartNorm, null);
  }

  protected initTimelineImpl(timeLines: ITimelineItem[], rtConfig: TimelineComponentDrawConfigiration): void {
    this.eventsMinDistance = rtConfig.eventsMinDistance ? rtConfig.eventsMinDistance : 40;
    this.eventsMinLapse = this.minLapse(timeLines);
    // assign a width to the timeline
    this.setTimelineWidth(timeLines);
    // assign a left position to the single events along the timeline
    this.setDatePosition(timeLines);
    this.setTransformValue(this.config.eventsWrapper.nativeElement, 'translateX', '0px');
    this.updateFillingEndByDate(this.endDate);
    this.updateFillingStartByDate(this.startDate);
  }

  protected setTimelineWidth(elements: ITimelineItem[]): number {
    const timeSpan = this.dayDiff(this.startDate, this.endDate);
    this.norm = this.eventsMinDistance / this.eventsMinLapse;
    let width = timeSpan * this.norm;
    if (width < this.parentWidth) {
      width = this.parentWidth;
      this.norm = (width - this.offset * 2) / timeSpan;
      this.eventsMinDistance = this.norm * this.eventsMinLapse;
    }
    this.eventsWrapperWidth = width;
    let aHref = this.config.eventsWrapper.nativeElement.querySelectorAll('a.selected')[0];
    this.updateFilling(aHref);
    this.updateTimelinePosition(aHref);
    return this.eventsWrapperWidth;
  }

  protected setDatePosition(elements: ITimelineItem[]): void {
    let timelineEventsArray = this.config.timelineEvents.toArray();
    if (timelineEventsArray.length === 0) {
      return;
    }
    let i: number = 0;
    for (let component of elements) {
      const distanceNorm = this.calcDistanceNorm(this.startDate, component.date);
      const e = timelineEventsArray[i];
      if (e) {
        this.config.renderer.setStyle(e.nativeElement, 'left', distanceNorm + 'px');
        // span
        let span: HTMLSpanElement = <HTMLSpanElement>e.nativeElement.parentElement.children[1];
        let spanWidth = this.getElementWidth(span);
        this.config.renderer.setStyle(span, 'left', distanceNorm + spanWidth / 2 + 'px');
        //span.style.left = distanceNorm * min + spanWidth / 2 + 'px';
      }
      i++;
    }
  }

  protected calcDistanceNorm(startDate: Date, endDate: Date): number {
    const distance = this.dayDiff(startDate, endDate);
    const distanceNorm = Math.round(distance * this.norm);
    return distanceNorm;
  }
}
