import { Provider, DoCheck } from '@angular/core';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';
import { DialogModeSize, DialogOptions, DialogOptions2 } from '../../../common/models/index';
import { ModalService } from '../../../common/services/modal/modal.service';
import { ReportDefinition } from '../../models/index';
import { Assert } from '../../../framework/index';
import { ReportActionType } from '../../models/report-action-type';
import * as moment from 'moment';
import { ReportsManagementService } from '../../services';
import { RnHrsMinWarningDIalogComponent } from '../report-parameters/rn-hrs-min-warning-dialog/rn-hrs-min-warning-dialog.component';
var GenerateReportDialogComponent = /** @class */ (function () {
    function GenerateReportDialogComponent(reportDefinition, options, modalService, reportManService) {
        this.options = options;
        this.modalService = modalService;
        this.reportManService = reportManService;
        this.isRNHrsSelected = false;
        this.start_date = 'start_date';
        this.end_date = 'end_date';
        this.retro_pay = 'retro_pay';
        this.wage_comp1 = 'EEO_Report_Wage_comp1';
        this.all_missing_punches = 'ta_all_historical_missing_punches';
        this.total_hrs = 'Total_hrs';
        this.consec_hrs = 'consecutive_hrs';
        this.reportAction = ReportActionType.Preview;
        this.isValidResult = false;
        Assert.isNotNull(reportDefinition, 'reportDefinition');
        this.reportDefinition = reportDefinition;
    }
    Object.defineProperty(GenerateReportDialogComponent.prototype, "parameters", {
        get: function () {
            return this.reportDefinition.parameters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GenerateReportDialogComponent.prototype, "reportDefinition", {
        get: function () {
            return this.m_reportDefinition;
        },
        set: function (value) {
            this.m_reportDefinition = value;
        },
        enumerable: true,
        configurable: true
    });
    GenerateReportDialogComponent.prototype.allParametersFilled = function () {
        var _this = this;
        var eligibleParameters = _.filter(this.reportDefinition.parameters, function (parameter) { return parameter.isVisible; });
        this.isValidResult = _.every(eligibleParameters, function (parameter) {
            var max = (parameter.name == _this.consec_hrs) ? 24 : 299.99;
            if (parameter.dataType.isLookup) {
                return true;
            }
            if (parameter.name === 'month_id' && (parameter.value < 1 || parameter.value > 12)) {
                return false;
            }
            if (parameter.dataType.name === 'int' && _.size(!(_.isUndefined(parameter.value) || _.isNull(parameter.value)) ? parameter.value.toString() : '') === 0) {
                return false;
            }
            if (parameter.name === _this.start_date || parameter.name === _this.end_date) {
                var retroPay = _.find(_this.reportDefinition.parameters, function (p) { return p.name === _this.retro_pay; });
                var wageComp1 = parameter.reportDefinition && _.isEqual(parameter.reportDefinition.name, _this.wage_comp1);
                var allMissingPunches = parameter.reportDefinition && _.isEqual(parameter.reportDefinition.name, _this.all_missing_punches);
                if (retroPay || wageComp1) {
                    var end_1 = _.find(_this.reportDefinition.parameters, function (p) { return p.name === _this.end_date; });
                    var start_1 = _.find(_this.reportDefinition.parameters, function (p) { return p.name === _this.start_date; });
                    if (moment(end_1.value).diff(start_1.value, 'day') > 366) {
                        return false;
                    }
                }
                if (allMissingPunches) {
                    var end_2 = _.find(_this.reportDefinition.parameters, function (p) { return p.name === _this.end_date; });
                    var start_2 = _.find(_this.reportDefinition.parameters, function (p) { return p.name === _this.start_date; });
                    if (moment(end_2.value).diff(start_2.value, 'day') > 89) {
                        return false;
                    }
                }
            }
            if ((parameter.name == _this.total_hrs || parameter.name == _this.consec_hrs) && parameter.defaultValue == true && (parameter.value <= 0 || parameter.value > max)) {
                return false;
            }
            var end = _.find(_this.reportDefinition.parameters, function (p) { return p.name === _this.end_date; });
            var start = _.find(_this.reportDefinition.parameters, function (p) { return p.name === _this.start_date; });
            if (start && end && moment(end.value).diff(start.value, 'day') < 0) {
                return false;
            }
            return !(_.isUndefined(parameter.value) || _.isNull(parameter.value));
        });
        return this.isValidResult;
    };
    GenerateReportDialogComponent.openDialog = function (request, modalService, callback) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.width = 480;
        dialogOptions.height = 557;
        dialogOptions.fullHeightOnMobile = true;
        dialogOptions.displayBlock = true;
        dialogOptions.modeSize = DialogModeSize.custom;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ReportDefinition,
                useValue: request
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog2(GenerateReportDialogComponent, 'Generate Report', dialogOptions, resolvedProviders, function (result, uniqueId) {
            callback(result);
        });
        return dialog;
    };
    GenerateReportDialogComponent.prototype.ngDoCheck = function () {
        this.allParametersFilled();
    };
    GenerateReportDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        if (this.reportDefinition.parameters.find(function (x) { return x.name == "Show_dates"; })) {
            var isShowDates = this.reportDefinition.parameters.find(function (x) { return x.name == "Show_dates"; });
            var zeroHr = this.reportDefinition.parameters.find(function (x) { return x.name == "Zero_hrs"; });
            var consecHr = this.reportDefinition.parameters.find(function (x) { return x.name == "consecutive_hrs"; });
            var totalHr = this.reportDefinition.parameters.find(function (x) { return x.name == "Total_hrs"; });
            if ((isShowDates.defaultValue == true && zeroHr.defaultValue != true && totalHr.defaultValue != true && consecHr.defaultValue != true) || (zeroHr.defaultValue != true && totalHr.defaultValue != true && consecHr.defaultValue != true)) {
                RnHrsMinWarningDIalogComponent.openDialog(this.modalService, function (result) {
                });
            }
            else
                this.modalService.closeWindow(this.options.windowUniqueId);
        }
        else
            this.modalService.closeWindow(this.options.windowUniqueId);
    };
    GenerateReportDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return GenerateReportDialogComponent;
}());
export { GenerateReportDialogComponent };
