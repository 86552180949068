import { Component } from '@angular/core';
import { BusyService } from '../../../../common';
import { data_Check, EmpSynDetails } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { NotificationsService } from '../../../../core/components/angular2-notifications';
import { BaseFilterSyncComponent } from '../base-filter-sync.component';

@Component({
  selector: 'slx-employee-migration',
  templateUrl: './employee-migration.component.html',
  styleUrls: ['./employee-migration.component.scss']
})
export class EmployeeMigrationComponent extends BaseFilterSyncComponent<EmpSynDetails> {
  constructor(
    wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(wfmSyncService, notificationService, busyService);
  }

  protected syncDataCore(selectedItems: EmpSynDetails[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.employeeSyncOrg(selectedItems, 'ongoing')
    );
  }

  protected getSyncItemKey(item: EmpSynDetails): string {
    return '' + item.employeeID;
  }

  protected async fetchData(): Promise<EmpSynDetails[]> {
    const data = await this.busyService.busy(
      this.wfmSyncService.employeeDetailsSync('ongoing')
    );

    return this.processData(data);
  }

  private processData(data: EmpSynDetails[]): EmpSynDetails[] {
    return data_Check(data);
  }

  protected setGlobalFilter(): void {
    this.globalFilterFunc$.next((item) => {
      const orgId = this.selectedOrganization && this.selectedOrganization.id;
      const deptId = this.selectedDepartment && this.selectedDepartment.id;
      const posId = this.selectedPosition && this.selectedPosition.id;

      return (orgId == null || item.organizationId === orgId) &&
        (deptId == null || item.departmentId === deptId) &&
        (posId == null || item.positionId === posId);
    })
  }
}
