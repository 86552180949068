import * as _ from 'lodash';
import * as moment from 'moment';
import { Router, ActivatedRoute, UrlTree, NavigationExtras, Params } from '@angular/router';
import { appConfig } from '../../../app.config';

export class PbjNavigationService {
  private router: Router;
  private route: ActivatedRoute;

  constructor(router: Router, route: ActivatedRoute) {
    this.router = router;
    this.route = route;
  }

  public NavigateToPbjOrganizationsConfiguration (): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(this.getConfigUrl(), extras);
  }

  public NavigateToExportLogDetails(exportId: number): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigateByUrl(this.getExportsUrl(exportId), extras);
  }

  public NavigateToPbjExport(): void {
    const extras: NavigationExtras = {
      skipLocationChange: false,
      replaceUrl: false,
    };
    this.router.navigate(['pbj_exports'], {
      relativeTo: this.route.pathFromRoot[2]
    });
  }

  public NavigateToReconciliation(orgLevelId: number, startDate: Date, endDate: Date): void {
    const params = this.makeParamsForPbjReconciliation(orgLevelId, startDate, endDate);
    const urlTree: UrlTree = this.getReconciliationUrl(params);
    this.router.navigateByUrl(urlTree);
  }
  
  public NavigateToReconciliationEmployee(empId: number, sDate: Date, eDate: Date): void {
    const params = this.makeParamsForPbjReconciliationEmployee(sDate, eDate);
    const urlTree: UrlTree = this.getEmployeeUrl(empId, params);
    this.router.navigateByUrl(urlTree);
  }

  private getConfigUrl(): UrlTree {
    return this.router.createUrlTree(['common', 'configure_organizations'], { relativeTo: this.route.pathFromRoot[1] });
  }

  private getExportsUrl(exportId: number): UrlTree {
    return this.router.createUrlTree(['pbj_exports', exportId], { relativeTo: this.route.pathFromRoot[2] });
  }

  private getReconciliationUrl(params: Params): UrlTree {
    return this.router.createUrlTree(['pbj_reconciliation'], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
  }

  private makeParamsForPbjReconciliationEmployee(sDate: Date, eDate: Date): Params {
    const params: Params = {};
    params.orgLevelId = this.route.snapshot.queryParamMap.get('orgLevelId');
    params.startDate = this.route.snapshot.queryParamMap.get('startDate');
    params.endDate = this.route.snapshot.queryParamMap.get('endDate');
    if (_.isDate(sDate)) {
      params.startDate = moment(sDate).format(appConfig.linkDateFormat);
    }
    if (_.isDate(eDate)) {
      params.endDate = moment(eDate).format(appConfig.linkDateFormat);
    }

    return params;
  }

  private getEmployeeUrl(empId: number, params: Params): UrlTree {
    return this.router.createUrlTree(['pbj_reconciliation', 'employee', empId], { relativeTo: this.route.pathFromRoot[2], queryParams: params });
  }

  private makeParamsForPbjReconciliation(oId: number, sDate: Date, eDate: Date): Params {
    const params: Params = {};
    let orgLevelId = this.route.snapshot.queryParamMap.get('orgLevelId');
    let startDate = this.route.snapshot.queryParamMap.get('startDate');
    let endDate = this.route.snapshot.queryParamMap.get('endDate');
    if (_.isNumber(oId)) {
      orgLevelId = String(oId);
    }
    if (_.isDate(sDate)) {
      startDate = moment(sDate).format(appConfig.linkDateFormat);
    }
    if (_.isDate(eDate)) {
      endDate = moment(eDate).format(appConfig.linkDateFormat);
    }
    params.orgLevelId = orgLevelId;
    params.startDate = startDate;
    params.endDate = endDate;

    return params;
  }
}
