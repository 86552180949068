import { Component, Input } from '@angular/core';
import * as _ from 'lodash';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, BaseFilterCellComponent, ColumnComponent } from '@progress/kendo-angular-grid';
import { LocalizationService } from '@progress/kendo-angular-l10n';
import * as kendoUtils from '../../../../core/utils/kendo-ui-utils';
import { KendoGridBasicFilterComponent } from '../kendo-grid-basic-filter/kendo-grid-basic-filter.component';

@Component({
  moduleId: module.id,
  selector: 'slx-kendo-grid-string-filter',
  templateUrl: 'kendo-grid-string-filter.component.html',
  styleUrls: ['kendo-grid-string-filter.component.scss'],
})
export class KendoGridStringFilterComponent extends KendoGridBasicFilterComponent {

  @Input()
  public showOperators: boolean;
  @Input()
  public column: ColumnComponent;
  @Input()
  public filter: CompositeFilterDescriptor;
  @Input()
  public operator: string;

  constructor(filterService: FilterService, localization: LocalizationService) {
    super(filterService, localization);
    this.localization = localization;

    let slxFilters: string[] = _.map([], (filter: kendoUtils.slxFilter) => { return filter.key; });
    let slxFiltersKeysMap: StringMap<string> = _.keyBy(slxFilters, (key: string) => key);
    let stringOperators: any = this.localizeOperators(
      Object.assign(slxFiltersKeysMap, {
        'filterEqOperator': 'eq',
        'filterNotEqOperator': 'neq',
        'filterContainsOperator': 'contains',
        'filterNotContainsOperator': 'doesnotcontain',
        'filterStartsWithOperator': 'startswith',
        'filterEndsWithOperator': 'endswith',
        'filterIsEmptyOperator': 'isempty',
        'filterIsNotEmptyOperator': 'isnotempty'
      }));
    this.showOperators = true;
    this.operator = 'contains';
    this.defaultOperators = stringOperators(this.localization);
  }
}
