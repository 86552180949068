import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';
import * as moment from 'moment';

import { appConfig } from '../../../app.config';
import { schedulerConfig } from '../../scheduler.config';
import { NotificationHubService } from '../../../channel/services/index';
import { SignalrHubGroupObservable } from '../../../channel/models/index';
import { ScheduleDefinitionsMapService } from '../../../organization/services/index';
import { IScheduleEntryDefinition } from '../../../organization/models/index';
import { IMasterScheduleEmployeeChange, MasterScheduleEmployeeChange } from '../../models/index';

@Injectable()
export class SchedulerNotificationService {

  public employeeScheduleChangedEvent$: Observable<MasterScheduleEmployeeChange>;

  private notificationHubService: NotificationHubService;
  private scheduleDefinitionsMapService: ScheduleDefinitionsMapService;
  // private channel: string = `default_master_schedule_200023_09/25/2016 00:00:00`;
  private employeeScheduleChangedEventSubject: Subject<MasterScheduleEmployeeChange>;
  private employeeScheduleChangedGroups: StringMap<SignalrHubGroupObservable<IMasterScheduleEmployeeChange>>;

  constructor(notificationHubService: NotificationHubService, scheduleDefinitionsMapService: ScheduleDefinitionsMapService) {
    this.notificationHubService = notificationHubService;
    this.scheduleDefinitionsMapService = scheduleDefinitionsMapService;
    this.employeeScheduleChangedEventSubject = new Subject<any>();
    this.employeeScheduleChangedEvent$ = this.employeeScheduleChangedEventSubject.asObservable();
    this.notificationHubService.registerEvent(schedulerConfig.signalr.employeeScheduleChanged);
    this.notificationHubService.start();
    this.employeeScheduleChangedGroups = {};
  }

  public registerEmployeeScheduleChangedGroup(group: string): void {
    let groupObs: SignalrHubGroupObservable<IMasterScheduleEmployeeChange> = this.notificationHubService.subscribe(schedulerConfig.signalr.employeeScheduleChanged, group);
    this.employeeScheduleChangedGroups[group] = groupObs;
    groupObs.subscription = groupObs.observable.subscribe(
      (ev: IMasterScheduleEmployeeChange) => {
        console.log(`EmployeeScheduleChanged ${ev.employeeId}`);
        let payload: MasterScheduleEmployeeChange = this.mapMasterScheduleEmployeeChange(ev);
        this.employeeScheduleChangedEventSubject.next(payload);
      },
      (error: any) => {
        console.warn('Attempt to join channel failed!', error);
      }
    );
  }

  public unregisterEmployeeScheduleChangedGroup(group: string): void {
    this.notificationHubService.leaveGroup(schedulerConfig.signalr.employeeScheduleChanged, group);
    if (this.employeeScheduleChangedGroups[group]) {
      let groupObs: SignalrHubGroupObservable<IMasterScheduleEmployeeChange> = this.employeeScheduleChangedGroups[group];
      groupObs.subscription.unsubscribe();
      this.employeeScheduleChangedGroups[group] = undefined;
    }
  }

  public unregisterAllGroups(): void {
    let groupObservables: SignalrHubGroupObservable<any>[] = _.values<SignalrHubGroupObservable<any>>(this.employeeScheduleChangedGroups);
    _.forEach(groupObservables, (obs: SignalrHubGroupObservable<any>) => {
      if (obs) {
        this.unregisterEmployeeScheduleChangedGroup(obs.group);
      }
    });
  }


  public buildGroupKey(orgLevelId: number, scheduleCycleStart: Date): string {
    return `${this.notificationHubService.getSubscribePrefix()}_master_schedule_${orgLevelId}_${moment(scheduleCycleStart).format(appConfig.serverDateFormat)}`;
  }

  private mapMasterScheduleEmployeeChange(dto: IMasterScheduleEmployeeChange): MasterScheduleEmployeeChange {
    let payload: MasterScheduleEmployeeChange = new MasterScheduleEmployeeChange();
    payload.employeeId = dto.employeeId;
    payload.entries = _.map(dto.entries, (entry: IScheduleEntryDefinition) => {
      return this.scheduleDefinitionsMapService.mapToScheduleEntryDefinition(entry);
    });
    return payload;
  }
}
