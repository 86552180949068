export class ExceptionColumn {

    public get fieldName(): string {
        let fieldName: string = this.exceptionName + this.exceptionId;
        fieldName = fieldName.replace(/\s+/g, '');
        return fieldName;
    }

    public exceptionId: string;
    public exceptionName: string;

}

export interface IExceptionColumn {
    exceptionId: string;
    exceptionName: string;
}
