import * as _ from 'lodash';

export interface IUserPermissions {
  canApproveOwnTimecard: boolean;
  canEditOwnTimecard: boolean;
  canEditOwnProfile: boolean;
  canApproveOwnESSRequest: boolean;
}

export class UserPermissions {
  private approveOwnTimecard: string;
  private editOwnTimecard: string;
  private editOwnProfile: string;
  private approveOwnESSRequest: string;

  constructor(approveOwnTimecard?: string, editOwnTimecard?: string, editOwnProfile?: string, approveOwnESSRequest?: string) {
    this.approveOwnTimecard = approveOwnTimecard;
    this.editOwnTimecard = editOwnTimecard;
    this.editOwnProfile = editOwnProfile;
    this.approveOwnESSRequest = approveOwnESSRequest;
  }

  public get canApproveOwnTimecard(): boolean {
    return _.lowerCase(this.approveOwnTimecard) === 'true';
  }
  public get canEditOwnTimecard(): boolean {
    return _.lowerCase(this.editOwnTimecard) === 'true';
  }
  public get canEditOwnProfile(): boolean {
    return _.lowerCase(this.editOwnProfile) === 'true';
  }
  public get canApproveOwnESSRequest(): boolean {
    return _.lowerCase(this.approveOwnESSRequest) === 'true';
  }
}

