import { Component, Input } from '@angular/core';
import { BusyService } from '../../../../common';
import { data_Check, EmpSynDetails } from '../../../../configuration/models/wfm-sync';
import { WfmSyncService } from '../../../../configuration/services/wfm/wfm-sync.service';
import { NotificationsService } from '../../../../core/components/angular2-notifications';
import { BaseSyncComponent } from '../wfm-sync-base.component';

@Component({
  selector: 'slx-wfm-ngp-employee-sync',
  templateUrl: './wfm-ngp-employee-sync.component.html',
  styleUrls: ['./wfm-ngp-employee-sync.component.scss']
})
export class WfmNgpEmployeeSyncComponent extends BaseSyncComponent<EmpSynDetails> {
  @Input() syncType: string;

  constructor(
    private wfmSyncService: WfmSyncService,
    notificationService: NotificationsService,
    busyService: BusyService
  ) {
    super(notificationService, busyService);
  }

  protected syncDataCore(selectedItems: EmpSynDetails[]): Promise<any> {
    return this.busyService.busy(
      this.wfmSyncService.employeeSyncOrg(selectedItems, this.syncType)
    );
  }

  protected getSyncItemKey(item: EmpSynDetails): string {
    return '' + item.employeeID;
  }

  protected async fetchData(): Promise<EmpSynDetails[]> {
    const data = await this.busyService.busy(
      this.wfmSyncService.employeeDetailsSync(this.syncType)
    );

    return this.processData(data);
  }

  private processData(data: EmpSynDetails[]): EmpSynDetails[] {
    return data_Check(data);
  }
}
