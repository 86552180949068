import * as tslib_1 from "tslib";
import { Entity } from '../../../core/models/index';
var UserApplication = /** @class */ (function (_super) {
    tslib_1.__extends(UserApplication, _super);
    function UserApplication(id, name) {
        var _this = _super.call(this, id) || this;
        _this.name = name;
        return _this;
    }
    return UserApplication;
}(Entity));
export { UserApplication };
