<form #templateForm="ngForm" novalidate class="slx-high-box__body slx-main-content-indents">
    <div class="main-box" *ngIf="container && currentOrgLevel">
        <div class="header-box">
            <i class="fas fa-usd-square fa-2x blue-icon" aria-hidden="true"></i>
            <span class="header-text">Cost Centers</span>
        </div>

        <section *ngFor="let center of container.records; let ind = index" class="collapsible-box">
            <h2 class="collapsible-box__header">
            <span class="collapsible-box__w-name">{{center.description}}</span>

            <slx-collapse-button class="collapsible-box__w-button"
                [isCollapsed]="getCurrentState(ind)"
                (isCollapsedChange)="onChangedState(ind)"
            ></slx-collapse-button>
            </h2>
            <div *ngIf="!getCurrentState(ind)" class="collapsible-box__body">
            <ng-container>

                <div class="command-container mobile-row">
                    <button type="button" [disabled]="!templateForm.valid"
                        class="slx-button slx-with-icon slx-mobile-adapted"
                        (click)="save(center)">
                        <i class="far fa-check-circle fa-2x slx-button__icon" aria-hidden="true"></i>
                    </button>
                    <button type="button" class="slx-button slx-with-icon slx-mobile-adapted"
                        (click)="cancel(center)">
                        <i class="fa fa-ban fa-2x slx-button__icon" aria-hidden="true"></i>
                    </button>
                    <div style="width: 100%"></div>
                    <button class="slx-button slx-with-icon slx-min-w90"
                        (click)="remove(center)">
                        <i class="fas fa-trash-alt red-icon" aria-hidden="true"></i>
                        <span class="slx-button__text">Remove</span>
                    </button>
                    </div>
                <div>

                    <slx-input-decorator  className="slx-small-font">
                        <slx-kendo-number slx-input class="form-control" slx-input type="text"
                        [decimals]="0" [step]="1" [(ngModel)]="center.code" [required]="true"
                        placeholder="Code"
                        name="codeInput{{rowIndex}}">
                        </slx-kendo-number>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>

                    <slx-input-decorator className="slx-small-font">
                        <input class="form-control" slx-input type="text" maxlength="50" [(ngModel)]="center.description"
                        [required]="true" placeholder="Description"
                        name="descriptionInput{{ind}}">
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>

                    <slx-input-decorator className="slx-small-font">
                        <slx-dropdown-input slx-input [lookup]="{lookupType: 'department', orgLevelId: currentOrgLevel.id}"
                            [required]="true" [(ngModel)]="center.department"
                            placeholder="Department" name="department{{rowIndex}}">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>

                    <slx-input-decorator className="slx-no-label slx-small-font">
                        <slx-dropdown-input slx-input [lookup]="{lookupType: 'position', orgLevelId: center.department?.orgLevelId}"
                            [required]="true" [(ngModel)]="center.position"
                            placeholder="Position" name="position{{rowIndex}}">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>

                    <slx-input-decorator className="slx-small-font">
                        <slx-dropdown-input slx-input [lookup]="{lookupType: 'locationUnit', orgLevelId: center.department?.orgLevelId}"
                            [required]="true" [(ngModel)]="center.unit"
                            placeholder="Unit" name="unit{{rowIndex}}">
                        </slx-dropdown-input>
                        <span errorMessage for="required"></span>
                    </slx-input-decorator>

                    <div class="mobile-row">
                        <span *ngIf="center.updatedBy" class="left-align">Updated By: {{ center.updatedBy }}</span>
                        <span *ngIf="center.updatedOn" class="left-align">{{ center.updatedOn | amDateFormat: appConfig.dateTimeFormatUS }}</span>
                    </div>

                </div>
            </ng-container>
            </div>
        </section>
    </div>
</form>
