import * as _ from "lodash";

import { Component, Host, Input, ViewChild } from "@angular/core";
import { process, SortDescriptor, State } from "@progress/kendo-data-query";
import {
  GridDataResult,
  GridComponent,
  PageChangeEvent,
} from "@progress/kendo-angular-grid";
import {
  KendoGridStateHelper,
  ConfirmDialog2Component,
  ModalService,
  saveEvent,
  removeEvent,
  ConfirmOptions,
  cancelEvent,
} from "../../../../../common/index";
import { appConfig, IApplicationConfig } from "../../../../../app.config";
import {
  EmployeeSectionsCertification,
  EmployeeSectionsCertifications,
} from "../../../models/employee-sections-personal/employee-sections-certification";
import { unsubscribe } from "../../../../../core/decorators/index";
import { Subscription } from "rxjs";
import { EmployeeSectionsPersonalApiService } from "../../../services";
import { EmployeeSectionsBase } from "../../../models/employee-sections-base";
import { EmployeeSubSectionsDecoratorComponent } from "../../employee-subsection-decorator/employee-subsection-decorator.component";

@Component({
  moduleId: module.id,
  selector: "slx-employee-sections-certifications",
  templateUrl: "employee-sections-certifications.component.html",
  styleUrls: ["employee-sections-certifications.component.scss"],
})
export class EmployeeSectionsCertificationsComponent {
  public editingItem: EmployeeSectionsCertification;
  public newEmpCertification: EmployeeSectionsCertification = new EmployeeSectionsCertification();

  public isLoading;

  public canAdd;
  public canEdit;
  public canDelete;
  public isSequenceError = false;

  public addMode: boolean;

  public gridState: KendoGridStateHelper<EmployeeSectionsCertification>;
  public sort: SortDescriptor[] = [];
  public gridView: GridDataResult;

  public appConfig: IApplicationConfig;
  public actions = { canEdit: true };
  public certifications: EmployeeSectionsCertification[];
  public enableSave = false;
  public addNewRow: EmployeeSectionsCertification = new EmployeeSectionsCertification();

  public skip = 0;
  public pageSize = 10;
  public codeSize = 255;
  public nameSize = 255;
  public descriptionSize = 500;
  public licenseSize = 50;
  public confirmingSize = 255;
  public minDate: Date = new Date(1899, 12, 1);
  public maxDate: Date = new Date(2079, 5, 6);
  public certError;
  public endError;

  @Input('employeeSectionsCertifications')
  public set certificatesList (employeeSectionsCertifications: EmployeeSectionsCertifications) {
    if(employeeSectionsCertifications != null){
      let actions = employeeSectionsCertifications.fieldsMeta.actions;
        _.forEach(actions, (v) => {
          if (v === "add") this.canAdd = true;
          if (v === "edit") this.canEdit = true;
          if (v === "delete") this.canDelete = true;
        });
        this.certifications = employeeSectionsCertifications.certificates;
        this.refreshGrid();
    }
  }


  @Input() public employeeId: number;

  public get isEditable(): boolean {
    return this.decorator.isSubsectionEditable;
  }

  @ViewChild("kendoGridCertification", { static: true })
  private grid: GridComponent;

  @unsubscribe()
  private gridRefreshSubscription: Subscription;
  @unsubscribe()
  private gridSaveSubscription: Subscription;
  @unsubscribe()
  private gridRemoveSubscription: Subscription;
  @unsubscribe()
  private gridEditSubscription: Subscription;
  @unsubscribe()
  private gridCancelSubscription: Subscription;
  @unsubscribe()
  private formValueChangeSubscription: Subscription;

  constructor(
    private modalService: ModalService,
    private apiService: EmployeeSectionsPersonalApiService,
    @Host() private decorator: EmployeeSubSectionsDecoratorComponent
  ) {
    this.gridState = new KendoGridStateHelper<EmployeeSectionsCertification>();
    this.gridState.state.skip = 0;
    this.gridState.state.sort = [{ field: "code", dir: "asc" }];
    this.gridState.state.take = this.pageSize;
    
    this.decorator.isEditableByConfiguration = false;
    this.createSubscriptions();
  }


  public ngOnInit(): void {
    this.appConfig = appConfig;
  }

  public ngOnDestroy(): void {
    // See #issueWithAOTCompiler
  }

  public createSubscriptions(): void {
    this.gridRefreshSubscription = this.gridState.onRefreshGrid.subscribe(
      (v: State): void => {
        this.refreshGrid();
      }
    );

    this.gridEditSubscription = this.gridState.onEdit$.subscribe(
      (item: EmployeeSectionsCertification): void => {
        this.isSequenceError = false;
        this.editingItem = item;
        this.addMode = false;
      }
    );

    this.gridCancelSubscription = this.gridState.onCancel$.subscribe(
      (item: cancelEvent<EmployeeSectionsCertification>): void => {
        this.editingItem = null;
        this.addMode = false;
      }
    );

    this.gridSaveSubscription = this.gridState.onSave$.subscribe(
      (item: saveEvent<EmployeeSectionsCertification>): void => {
        if (item.isNew) {
          item.dataItem.id = null;
          this.addMode = false;
        }
        this.doUpdate(item.dataItem);
      }
    );

    this.gridRemoveSubscription = this.gridState.onRemove$.subscribe(
      (item: removeEvent<EmployeeSectionsCertification>): void => {
        let options: ConfirmOptions = new ConfirmOptions();
        options.showCancel = true;
        options.showOK = true;
        ConfirmDialog2Component.openDialog(
          "Delete Certificate",
          "Are you sure that you want to remove this certificate?",
          this.modalService,
          (result: boolean) => {
            if (result) {
              this.doRemove(item.dataItem);
            }
          },
          options
        );
      }
    );
  }

  public onStartAdd(): void {
    this.enableSave = false;
    this.addMode = true;
    this.newEmpCertification.certDate = null;
    this.newEmpCertification.endDate = null;
    this.newEmpCertification.code = null;
    this.newEmpCertification.confirmingBody = null;
    this.newEmpCertification.description = null;
    this.newEmpCertification.id = null;
    this.newEmpCertification.license = null;
    this.newEmpCertification.licenseSequence = null;
    this.newEmpCertification.name = null;
    this.grid.addRow(this.newEmpCertification);
  }

  protected doUpdate(item: EmployeeSectionsCertification): void {
    this.isLoading = true;
    if(item.certDate != null){
      item.certDate =  new Date(item.certDate).toDateString();
    }
    if(item.endDate != null){
      item.endDate =  new Date(item.endDate).toDateString();
    }
    this.apiService.setCertificate(item, this.employeeId).then((status) => {
      if (status) {
        this.loadCheckList();
      }
    });
  }

  protected doRemove(item: EmployeeSectionsCertification): void {
    this.certifications = _.without(this.certifications, item);
    this.isLoading = true;
    this.apiService.deleteCertificate(item, this.employeeId).then((status) => {
      if (status) {
        this.refreshGrid();
        this.isLoading = false;
      }
    });
  }

  private refreshGrid(): void {
    if (!this.certifications) {
      this.gridView = null;
      return;
    }
    this.gridState.view = process(this.certifications, this.gridState.state);
  }

  public onChangeValue(item: EmployeeSectionsCertification) {
    let isCode = this.validateCode(item.code, this.codeSize);
    let isName = this.validateString(item.name, this.nameSize);
    let isDescription = this.validateString(
      item.description,
      this.descriptionSize
    );
    let isLicense = this.validateString(item.license, this.licenseSize);
    let isConfirmingBody = this.validateString(
      item.confirmingBody,
      this.confirmingSize
    );
    let isSequence = this.validateSequence(item.licenseSequence);
    let isCertDate = this.validateDate(item);
    let isEndDate = this.validateDate(item);
    if (
      isCode &&
      isName &&
      isDescription &&
      isLicense &&
      isConfirmingBody &&
      isSequence &&
      isCertDate &&
      isEndDate
    ) {
      this.enableSave = true;
    } else {
      this.enableSave = false;
    }
  }

  public validateCode(value: string, size: number) {
    let validate;
    if (value == null || value == "") {
      validate = false;
    } else {
      validate = true;
    }
    if (value != null && value != "") {
      if (value.length > size) {
        validate = false;
      } else {
        validate = true;
      }
    }
    return validate;
  }

  public validateString(value: string, size: number) {
    let validate;
    if (value != null) {
      if (value.length > size) {
        validate = false;
      } else {
        validate = true;
      }
    } else {
      validate = true;
    }
    return validate;
  }

  public validateSequence(value: number) {
    let validate;
    if (value != null) {
      if (value > 255 || value < 0 ) {
        validate = false;
        this.isSequenceError = true;
      } else {
        validate = true;
        this.isSequenceError = false;
      }
    } else {
      validate = true;
      this.isSequenceError = false;
    }
    return validate;
  }
  public validateDate(item: EmployeeSectionsCertification) {
    let validate;
    let CertDate = new Date(item.certDate)
    let EndDate = new Date(item.endDate)
    if(item.certDate != null && item.endDate != null){
      if(CertDate > EndDate){
        validate = false;
        this.certError = true;
      }
      else if ( EndDate < CertDate){
        validate = false;
        this.endError = true;
      }
      else{
        validate = true;
        this.certError = false;
        this.endError = false;

      }
    }
    else {
      validate = true;
      this.certError = false;
      this.endError = false;
    }
    return validate;
  }

  public loadCheckList(){
    this.apiService
    .getCertifications(this.employeeId)
    .then((certifications) => {
      this.certifications = certifications.certificates;
      this.refreshGrid();
      this.isLoading = false;
    });
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.refreshGrid();
  }
}
