import { ReplaySubject } from 'rxjs/ReplaySubject';
import { UserProfileApiService } from './user-profile-api.service';
import { UserProfileMapService } from './user-profile-map.service';
import { UserProfileSectionType } from '../../models/users/models/user-profile-section-type';
import { UserProfileModel, UserRoleRelationModel } from '../../models/index';
var UserProfileManagementService = /** @class */ (function () {
    function UserProfileManagementService(api, map) {
        this.api = api;
        this.map = map;
        this.m_onResetStates = new ReplaySubject(1);
        this.m_onProfileLoaded = new ReplaySubject(1);
        this.m_onUserCreated = new ReplaySubject(1);
        this.m_onRolesLoaded = new ReplaySubject(1);
        this.m_onAllRolesLoaded = new ReplaySubject(1);
        this.m_onSectionEnteredEditState = new ReplaySubject(1);
        this.m_onSectionSaved = new ReplaySubject(1);
        this.m_onSectionSave = new ReplaySubject(1);
        this.m_onSectionDiscarded = new ReplaySubject(1);
        this.m_onSectionServiceError = new ReplaySubject(1);
        this.m_onStatusChanged = new ReplaySubject(1);
        this.m_onAddRoleModeSwitch = new ReplaySubject(1);
        this.m_onSaveSelectedRole = new ReplaySubject(1);
        this.m_onCancelSelectedRole = new ReplaySubject(1);
        this.m_onDeleteRoles = new ReplaySubject(1);
        this.m_onResetRoles = new ReplaySubject(1);
    }
    Object.defineProperty(UserProfileManagementService.prototype, "onResetStates$", {
        get: function () {
            return this.m_onResetStates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onProfileLoaded$", {
        get: function () {
            return this.m_onProfileLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onStatusChanged$", {
        get: function () {
            return this.m_onStatusChanged;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onUserCreated$", {
        get: function () {
            return this.m_onUserCreated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onUserRolesLoaded$", {
        get: function () {
            return this.m_onRolesLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onAllRolesLoaded$", {
        get: function () {
            return this.m_onAllRolesLoaded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onSectionEnteredEditState$", {
        get: function () {
            return this.m_onSectionEnteredEditState;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onSectionSave$", {
        get: function () {
            return this.m_onSectionSave;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onSectionSaved$", {
        get: function () {
            return this.m_onSectionSaved;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onSectionDiscarded$", {
        get: function () {
            return this.m_onSectionDiscarded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onSectionServiceError$", {
        get: function () {
            return this.m_onSectionServiceError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onAddRoleModeSwitch$", {
        get: function () {
            return this.m_onAddRoleModeSwitch;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onSaveSelectedRole$", {
        get: function () {
            return this.m_onSaveSelectedRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onCancelSelectedRole$", {
        get: function () {
            return this.m_onCancelSelectedRole;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onDeleteRoles$", {
        get: function () {
            return this.m_onDeleteRoles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserProfileManagementService.prototype, "onResetRoles$", {
        get: function () {
            return this.m_onResetRoles;
        },
        enumerable: true,
        configurable: true
    });
    UserProfileManagementService.prototype.reset = function () {
        this.m_onResetStates.next(0);
    };
    UserProfileManagementService.prototype.getUserProfile = function (userId) {
        var _this = this;
        this.api.getUserProfile(userId).then(function (userProfile) {
            _this.userProfile = userProfile;
            _this.m_onProfileLoaded.next(userProfile);
        }).catch(function (e) {
            _this.m_onSectionServiceError.next(UserProfileSectionType.PROFILE);
        });
    };
    UserProfileManagementService.prototype.loadRoles = function () {
        var _this = this;
        this.api.getRoles().then(function (roles) {
            _this.m_onAllRolesLoaded.next(roles);
        }).catch(function (e) {
            _this.m_onSectionServiceError.next(UserProfileSectionType.ROLES);
        });
    };
    UserProfileManagementService.prototype.getUserRoles = function (userId) {
        var _this = this;
        this.api.getUserRoles(userId).then(function (roles) {
            _this.m_onRolesLoaded.next(roles);
        }).catch(function (e) {
            _this.m_onSectionServiceError.next(UserProfileSectionType.ROLES);
        });
    };
    UserProfileManagementService.prototype.createNewUser = function () {
        var userProfile = new UserProfileModel();
        userProfile.isNew = true;
        this.m_onProfileLoaded.next(userProfile);
        this.m_onRolesLoaded.next([]);
    };
    UserProfileManagementService.prototype.createNewUserOnServer = function (userProfile, roles) {
        var _this = this;
        this.api.createUser(userProfile, roles).then(function (profile) {
            _this.m_onUserCreated.next(profile);
        }).catch(function (e) {
            _this.m_onSectionServiceError.next(UserProfileSectionType.ALL);
        });
    };
    UserProfileManagementService.prototype.disableUser = function () {
        var _this = this;
        this.api.disableUser(this.userProfile.id).then(function (result) {
            _this.m_onStatusChanged.next(0);
            _this.userProfile.isActive = false;
        }).catch(function (e) {
            _this.m_onSectionServiceError.next(UserProfileSectionType.ALL);
        });
    };
    UserProfileManagementService.prototype.activateUser = function () {
        var _this = this;
        this.api.activateUser(this.userProfile.id).then(function (result) {
            _this.m_onStatusChanged.next(1);
            _this.userProfile.isActive = true;
        }).catch(function (e) {
            _this.m_onSectionServiceError.next(UserProfileSectionType.ALL);
        });
    };
    UserProfileManagementService.prototype.editSection = function (section) {
        this.changeService.changeNotify(this.getSectionGroupKey(section));
        this.m_onSectionEnteredEditState.next(section);
    };
    UserProfileManagementService.prototype.onAddRole = function () {
        this.m_onAddRoleModeSwitch.next(true);
    };
    UserProfileManagementService.prototype.onDeleteRole = function () {
        this.m_onDeleteRoles.next(null);
    };
    UserProfileManagementService.prototype.onResetRoles = function () {
        this.m_onResetRoles.next(null);
    };
    UserProfileManagementService.prototype.addSelectedRole = function () {
        this.m_onSaveSelectedRole.next(null);
    };
    UserProfileManagementService.prototype.cancelAddSelectedRole = function () {
        this.m_onCancelSelectedRole.next(null);
    };
    UserProfileManagementService.prototype.onSaveAddedRole = function () {
        this.m_onAddRoleModeSwitch.next(false);
    };
    UserProfileManagementService.prototype.onCancelAddedRole = function () {
        this.m_onAddRoleModeSwitch.next(false);
    };
    UserProfileManagementService.prototype.saveSection = function (section) {
        var _this = this;
        switch (section.type) {
            case UserProfileSectionType.PROFILE:
                this.api.saveUserProfile(section.getSaveData()).then(function (userProfile) {
                    _this.m_onSectionSaved.next(section);
                    _this.changeService.clearChanges(_this.getSectionGroupKey(section));
                }).catch(function (e) {
                    _this.m_onSectionServiceError.next(UserProfileSectionType.PROFILE);
                    _this.changeService.clearChanges(_this.getSectionGroupKey(section));
                });
                this.m_onSectionSave.next(section);
                break;
            case UserProfileSectionType.ROLES:
                var saveData = section.getSaveData();
                if (!saveData.addMode) {
                    // save all roles
                    this.api.saveUserRoles(this.userProfile.id, saveData.tempRoles).then(function (roles) {
                        _this.m_onSectionSaved.next(section);
                        _this.changeService.clearChanges(_this.getSectionGroupKey(section));
                    }).catch(function (e) {
                        _this.m_onSectionServiceError.next(UserProfileSectionType.ROLES);
                        _this.changeService.clearChanges(_this.getSectionGroupKey(section));
                    });
                    this.m_onSectionSave.next(section);
                }
                break;
        }
    };
    UserProfileManagementService.prototype.discardSection = function (section) {
        this.changeService.clearChanges(this.getSectionGroupKey(section));
        this.m_onSectionDiscarded.next(section);
    };
    UserProfileManagementService.prototype.cloneProfile = function (profile) {
        return this.map.cloneUserProfile(profile);
    };
    UserProfileManagementService.prototype.cloneRoles = function (roles) {
        return this.map.cloneUserRoles(roles);
    };
    UserProfileManagementService.prototype.getSectionGroupKey = function (section) {
        return 'section' + section.toString();
    };
    return UserProfileManagementService;
}());
export { UserProfileManagementService };
