import { EventEmitter } from '@angular/core';
import { ICollapseComponent } from '../../../models/index';
var CollapseButtonComponent = /** @class */ (function () {
    function CollapseButtonComponent() {
        this.collapsedIcon = 'far fa-angle-up';
        this.expandedIcon = 'far fa-angle-down';
        this.isCollapsedChange = new EventEmitter();
        this.isCollapsedModify = new EventEmitter();
    }
    Object.defineProperty(CollapseButtonComponent.prototype, "isCollapsed", {
        get: function () {
            return this.m_isCollapsed;
        },
        set: function (value) {
            this.m_isCollapsed = value;
            this.isCollapsedModify.emit(this.m_isCollapsed);
        },
        enumerable: true,
        configurable: true
    });
    CollapseButtonComponent.prototype.onToggle = function () {
        this.m_isCollapsed = !this.m_isCollapsed;
        this.isCollapsedChange.emit(this.m_isCollapsed);
    };
    return CollapseButtonComponent;
}());
export { CollapseButtonComponent };
