import * as tslib_1 from "tslib";
import { OnInit, ElementRef, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { PageScrollConfig, PageScrollingViews } from '../../../../common/models/index';
import { PageScrollService, PageScrollInstance, ScrollWatchService, EmployeeSectionTabNavigationService } from '../../../../common/index';
import { EmployeeSectionApiService, EmployeeSectionManagementService } from '../../services/index';
import { Assert, StringUtils } from '../../../../framework/index';
import { EmployeeDefinitionsApiService } from '../../../../organization/services/index';
import { MenuItem } from '../../../../common/models/index';
import { EmployeeSectionsContext } from '../../../employee/models/index';
import { mutableSelect } from '../../../../core/decorators/index';
import { employeeSectionConfig } from '../../models/index';
import { EmployeeSectionBridgeService } from '../../services/index';
import { DateTimeService } from '../../../../common/services/date-time/date-time.service';
import { EmployeeSectionsProfileComponent } from '../employee-sections-personal/employee-sections-profile/employee-sections-profile.component';
import { EmpScrollService } from '../../services/index';
import { EmpScrollSection } from '../../models/index';
import { AppSettingsManageService } from '../../../../app-settings/services/index';
var EmployeeSectionsComponent = /** @class */ (function () {
    function EmployeeSectionsComponent(employeeSectionBridgeService, employeeDefinitionsApiService, employeeSectionApiService, pageScrollService, scrollWatchService, dateTimeService, scrollService, route, document, appSettingsManageService, empSectionTabNavService) {
        this.appSettingsManageService = appSettingsManageService;
        this.empSectionTabNavService = empSectionTabNavService;
        this.isTermHistoryEditEndabled = false;
        this.employeeSectionBridgeService = employeeSectionBridgeService;
        this.employeeSectionApiService = employeeSectionApiService;
        this.employeeDefinitionsApiService = employeeDefinitionsApiService;
        this.pageScrollService = pageScrollService;
        this.scrollWatchService = scrollWatchService;
        this.dateTimeService = dateTimeService;
        this.scrollService = scrollService;
        this.document = document;
        this.route = route;
        this.menuCollapsed = true;
        this.editingSections = {};
        this.pageScrollFinish = new EventEmitter();
    }
    Object.defineProperty(EmployeeSectionsComponent.prototype, "employeeSectionsContext", {
        set: function (employeeSectionsContext) {
            if (!employeeSectionsContext) {
                return;
            }
            if ((employeeSectionsContext && !this.currentEmployeeSectionsContext) || (this.currentEmployeeSectionsContext.employeeId !== employeeSectionsContext.employeeId)) {
                this.employeeSectionManagementService = new EmployeeSectionManagementService(employeeSectionsContext, this.employeeSectionBridgeService, this.employeeSectionApiService, this.dateTimeService);
                this.menuLoaded = false;
                this.currentEmployeeSectionsContext = employeeSectionsContext;
                this.activeSubSection = null;
                this.refresh();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "employeeBannerInfo", {
        get: function () {
            return this.employeeSectionManagementService.employeeBannerInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "personalSection", {
        get: function () {
            return this.employeeSectionManagementService.personalSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "employmentSection", {
        get: function () {
            return this.employeeSectionManagementService.employmentSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "customSection", {
        get: function () {
            return this.employeeSectionManagementService.customSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "auditSection", {
        get: function () {
            return this.employeeSectionManagementService.auditSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "performanceSection", {
        get: function () {
            return this.employeeSectionManagementService.performanceSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "scheduleSection", {
        get: function () {
            return this.employeeSectionManagementService.scheduleSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "benefitsManagementSection", {
        get: function () {
            return this.employeeSectionManagementService.benefitManagementSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "accrualsSection", {
        get: function () {
            return this.employeeSectionManagementService.accrualsSection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "accrualsStartDate", {
        get: function () {
            return this.employeeSectionManagementService.accrualsStartDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "accrualsEndDate", {
        get: function () {
            return this.employeeSectionManagementService.accrualsEndDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "attendancePointsStartDate", {
        get: function () {
            return this.employeeSectionManagementService.attendancePointsStartDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "attendancePointsEndDate", {
        get: function () {
            return this.employeeSectionManagementService.attendancePointsEndDate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "sectionsAreVisible", {
        get: function () {
            return this.employeeSectionBridgeService.sectionsAreVisible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EmployeeSectionsComponent.prototype, "canEditTermHistory", {
        get: function () {
            if (this.employeeBannerInfo && this.isTermHistoryEditEndabled)
                return this.employeeBannerInfo.canTerminate && this.employeeBannerInfo.isTerminated && this.isTermHistoryEditEndabled;
            else
                return false;
        },
        enumerable: true,
        configurable: true
    });
    EmployeeSectionsComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var sectionId = this.empSectionTabNavService.empSectionTab ? this.empSectionTabNavService.empSectionTab : 'Personal';
        setTimeout(function () {
            if (sectionId === 'Schedule') {
                _this.navigate(StringUtils.format('#{0}', sectionId));
                _this.empSectionTabNavService.empSectionTab = null;
            }
            else {
                _this.navigate(StringUtils.format('#{0}', sectionId));
            }
        }, 3000);
    };
    EmployeeSectionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.configuration = employeeSectionConfig;
        this.editInProgress = false;
        this.configure();
        this.editSubscription = this.employeeSectionBridgeService.editStateChange$.subscribe(function (change) {
            var isAnyEditing = false;
            _this.editingSections[change.section.id] = change.editing;
            _.forOwn(_this.editingSections, function (value, key) {
                if (value)
                    isAnyEditing = value;
            });
            _this.hasEditingSection = isAnyEditing;
        });
        this.employeeSectionManagementService = new EmployeeSectionManagementService(this.currentEmployeeSectionsContext, this.employeeSectionBridgeService, this.employeeSectionApiService, this.dateTimeService);
        this.refresh();
        this.routeSubscripion = this.route.params.subscribe(function (params) {
            _this.employeeSectionsContext = new EmployeeSectionsContext(+params['employeeId']);
            _this.scrollService.resetScroll();
        });
        this.activeSectionSubscription = this.scrollService.subscribeToActive(function (sec) {
            _this.activeSection = _this.employeeSectionManagementService.getSectionBySubsectionId(sec.id);
            _this.activeSubSection = _this.employeeSectionManagementService.getSubSectionById(sec.id);
            _this.loadSection(_this.activeSection);
        });
        this.preactiveSectionSubscription = this.scrollService.subscribeToPreactive(function (sec) {
            var section = _this.employeeSectionManagementService.getSectionBySubsectionId(sec.id);
            _this.loadSection(section);
        });
        this.pageScrollSubscription = this.pageScrollFinish.subscribe(function () {
            _this.scrollService.suspended(false);
        });
        this.appSettingsManageService.getAppServerConfig()
            .then(function (conf) {
            _this.canNotEditOwnProfile = !conf.userPermissions.canEditOwnProfile;
            _this.isTermHistoryEditEndabled = conf.isTermHistoryEditEnabled;
        });
    };
    EmployeeSectionsComponent.prototype.ngOnDestroy = function () {
        if (this.routeSubscripion) {
            this.routeSubscripion.unsubscribe();
        }
        if (this.editSubscription) {
            this.editSubscription.unsubscribe();
        }
        if (this.activeSectionSubscription) {
            this.activeSectionSubscription.unsubscribe();
        }
        if (this.preactiveSectionSubscription) {
            this.preactiveSectionSubscription.unsubscribe();
        }
    };
    EmployeeSectionsComponent.prototype.getIsLoadingPersonal = function () {
        return this.employeeSectionManagementService.isLoadingPersonal;
    };
    EmployeeSectionsComponent.prototype.refresh = function () {
        var _this = this;
        if (this.menuLoaded)
            return;
        if (!this.currentEmployeeSectionsContext)
            return;
        this.menuLoaded = true;
        this.loadWorkplace();
        this.employeeSectionBridgeService.getSections(this.currentEmployeeSectionsContext.employeeId)
            .then(function (employeeSections) {
            _this.employeeSections = employeeSections;
            _this.mapSectionsToMenu();
            _this.employeeSectionManagementService.createSectionsMapping(_this.employeeSections);
            if (!_this.activeSubSection) {
                var section = _this.employeeSectionManagementService.getDefaultSection();
                var subsection = _this.employeeSectionManagementService.getDefaultSubSection();
                _this.activeSubSection = subsection;
                _this.activeSection = section;
                _this.loadSection(section);
            }
        });
        this.employeeDefinitionsApiService.getEmployeeShortInfo(this.currentEmployeeSectionsContext.employeeId)
            .then(function (employeeShortInfo) {
            _this.employeeShortInfo = employeeShortInfo;
            _this.employeeSectionManagementService.employeeShortInfo = _this.employeeShortInfo;
        });
    };
    EmployeeSectionsComponent.prototype.loadSection = function (section) {
        var _this = this;
        this.employeeSectionManagementService.loadSection(section)
            .then(function (hasLoaded) {
            if (hasLoaded) {
                _this.scrollService.updateScroll();
            }
        });
    };
    EmployeeSectionsComponent.prototype.loadWorkplace = function () {
        var _this = this;
        this.employeeSectionManagementService.loadWorkplace()
            .then(function (workplace) {
            _this.workplace = workplace;
        });
    };
    EmployeeSectionsComponent.prototype.onItemActivated = function (event) {
        if (!event.item) {
            return;
        }
        var section = this.employeeSectionManagementService.getSectionById(event.item.id);
        var subsection;
        if (event.subItem) {
            subsection = this.employeeSectionManagementService.getSubSectionById(event.subItem.id);
            this.scrollTo(event, subsection);
        }
        else {
            this.scrollTo(event, section);
        }
    };
    EmployeeSectionsComponent.prototype.scrollTo = function (event, subsection) {
        this.navigate(subsection.anchor);
    };
    EmployeeSectionsComponent.prototype.getIsLoadingSection = function (subsectionId) {
        return this.employeeSectionManagementService.getSectionStateBySubsectionId(subsectionId);
    };
    EmployeeSectionsComponent.prototype.menuToggled = function (isCollapsed) {
        this.menuCollapsed = isCollapsed;
    };
    EmployeeSectionsComponent.prototype.transfer = function (event) {
        this.employeeSectionsProfile.onTransfer();
    };
    EmployeeSectionsComponent.prototype.undoTransfer = function (event) {
        this.employeeSectionsProfile.undoTransfer();
    };
    EmployeeSectionsComponent.prototype.terminate = function (event) {
        this.employeeSectionsProfile.onTerminate(this.workplace.ignoreLockedPayrollCycles);
    };
    EmployeeSectionsComponent.prototype.rehire = function (event) {
        this.employeeSectionsProfile.onRehire();
    };
    EmployeeSectionsComponent.prototype.cancelTerminate = function (event) {
        this.employeeSectionsProfile.onCancelTerminate();
    };
    EmployeeSectionsComponent.prototype.changeTerminationDate = function (event) {
        this.employeeSectionsProfile.onChangeTerminationDate(this.workplace.ignoreLockedPayrollCycles);
    };
    EmployeeSectionsComponent.prototype.onResendTimeclocksEmail = function (event) {
        this.employeeSectionsProfile.onResendTimeclocksEmail();
    };
    EmployeeSectionsComponent.prototype.updateBanner = function (profile) {
        this.employeeSectionManagementService.employeeShortInfo = this.employeeShortInfo;
        this.employeeSectionManagementService.setEmployeeBannerInfo(profile);
    };
    EmployeeSectionsComponent.prototype.navigate = function (anchor) {
        Assert.isNotNull(anchor, 'anchor');
        this.scrollService.suspended(true);
        var pageScrollInstance = PageScrollInstance.advancedInstance(this.document, anchor, [this.scrollContainer], null, 40, null, null, null, this.pageScrollFinish);
        this.pageScrollService.start(pageScrollInstance);
    };
    EmployeeSectionsComponent.prototype.mapSectionsToMenu = function () {
        var _this = this;
        this.menuItems = [];
        _.forEach(this.employeeSections, function (section) {
            var item = new MenuItem();
            item.id = section.id;
            item.displayName = section.displayName;
            item.item = section;
            item.items = [];
            _this.menuItems.push(item);
            _this.mapSubSectionsToMenu(section, item);
        });
    };
    EmployeeSectionsComponent.prototype.mapSubSectionsToMenu = function (section, root) {
        _.forEach(section.subsections, function (subSection) {
            var item = new MenuItem();
            item.id = subSection.id;
            item.displayName = subSection.displayName;
            item.item = subSection;
            root.items.push(item);
        });
    };
    EmployeeSectionsComponent.prototype.configure = function () {
        $('.k-window-titlebar.k-header').css({ 'z-index': 4 });
        this.scrollContainer = this.container.nativeElement;
        PageScrollConfig.defaultScrollOffset = this.configuration.offsetScroll;
    };
    tslib_1.__decorate([
        mutableSelect(['sidebar', 'isLeftSidebarOpen']),
        tslib_1.__metadata("design:type", Observable)
    ], EmployeeSectionsComponent.prototype, "isLeftSidebarOpen", void 0);
    return EmployeeSectionsComponent;
}());
export { EmployeeSectionsComponent };
