import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Assert } from '../../../framework/index';
import { appConfig } from '../../../app.config';

import {
  IPositionDTO,
  PositionModel,
  IAdditionalInfoDTO,
  AdditionalInfo,
  IConfOrganization,
  ConfOrganization,
  PositionsActions,
  PositionsActionsType
} from '../../models/index';

import {
  ShiftDiffPolicy,
  IShiftDiffPolicy,
  AccrualPolicy,
  IAccrualPolicy,
  IDepartment,
  Department,
  IPayPolicy,
  PayPolicy,
  IBudgetedPosition,
  BudgetedPosition,
  IPbjPosition, PbjPosition, PositionGroup,IPositionGroup
} from '../../../organization/models/lookup/index';
import { IEssTemplateDefinition, EssTemplateDefinition } from '../../../app-modules/ess-templates/models/index';

import { MetaMapService, SessionService } from '../../../core/services/index';
import { FieldsMeta, Actions } from '../../../core/models/index';

import { LookupMapService } from '../../../organization/services/index';

import { EssTemplateMapService } from '../../../app-modules/ess-templates/services/index';

import { dateTimeUtils } from '../../../common/utils/index';
import { AppSettingsManageService } from './../../../app-settings/services/app-settings-manage.service';
import { AppServerConfig } from './../../../app-settings/model/app-server-config';
@Injectable()
export class PositionsConfigurationMapService {
  isNextgenPayrollEnabled: boolean = false;

  constructor(
    private lookupMapService: LookupMapService,
    private metaMapService: MetaMapService,
    private essTemplatesMapService: EssTemplateMapService,
    private sessionService: SessionService,
    private appSettingsManageService: AppSettingsManageService
  ) { 
    this.appSettingsManageService.getAppServerConfig()
      .then((conf: AppServerConfig) => {
        this.isNextgenPayrollEnabled = conf.IsNextgenPayrollEnabled;             
      });
  }

  public mapPositions(dtos: IPositionDTO[]): PositionModel[] {

    Assert.isNotNull(dtos);
    let arr: PositionModel[] = [];

    _.each(dtos, (dto: IPositionDTO) => {
      arr.push(this.mapPosition(dto));
    });

    return arr;
  }

  public mapPosition(dto: IPositionDTO): PositionModel {

    let model: PositionModel = new PositionModel();
    model.id = dto.id;
    model.name = dto.name;
    model.jobClass = dto.jobClass;
    model.isPrimary = dto.isPrimary;
    model.hourlyRate = dto.hourlyRate;
    model.lastUpdateUsername = dto.lastUpdateUsername;
    model.lastUpdateDate = dateTimeUtils.convertFromDtoString(dto.lastUpdateDate);
    model.clientDepartment = dto.clientDepartment;
    model.isDirectCare = dto.isDirectCare;
    model.legalId = dto.legalId;
    model.defaultShiftHours = dto.defaultShiftHours;
    model.objectAccount = dto.objectAccount;
    model.employeesCount = dto.employeesCount;
    model.jobListOrder = dto.jobListOrder;
    model.businessUnit = dto.businessUnit;
    model.replacesPositionsIds = dto.replacesPositionsIds;
    model.operator = dto.operator;

    if (dto.department) {
      model.department = this.lookupMapService.mapDepartment(dto.department);
    }

    if (dto.organization) {
      model.organization = this.lookupMapService.mapOrganization(dto.organization);
    }

    if (dto.budgetedPosition) {
      model.budgetedPosition = this.mapBudgetedPosition(dto.budgetedPosition);
    }

    if (dto.payPolicy) {
      model.payPolicy = this.mapPayPolicy(dto.payPolicy);
    }

    if (dto.shiftDifferentialPolicy) {
      model.shiftDifferentialPolicy = this.mapShiftDifferentialPolicy(dto.shiftDifferentialPolicy);
    }

    if (dto.accrualPolicy) {
      model.accrualPolicy = this.mapAccrualPolicy(dto.accrualPolicy);
    }

    if (dto.groupedWith) {
      model.groupedWith = this.lookupMapService.mapPosition(dto.groupedWith);
    }

    if (dto.pbjCmsPosition) {
      model.pbjCmsPosition = this.mapPbjPosition(dto.pbjCmsPosition);
    }

    if(dto.positionGroup)
    {
     model.positionGroup = this.mapPostionGroup(dto.positionGroup);
    } else {
      model.positionGroup = new PositionGroup();
      model.positionGroup.name = 'Unassigned';
    }

    if (dto.additionalInfo) {
      model.additionalInfo = this.mapAdditionalInfo(dto.additionalInfo);
    } else {
      model.additionalInfo = new AdditionalInfo();
    }
    model.essTemplate = dto.essTemplate? this.essTemplatesMapService.mapToEssTemplateDefinition(dto.essTemplate): null;
    model.positionLicenseTypes = dto.positionLicenseTypes;
    model.supervisorDepartment = dto.supervisorDepartment;
    model.supervisorOrganization = dto.supervisorOrganization;
    model.supervisorPosition = dto.supervisorPosition;
    if(this.isNextgenPayrollEnabled){
      model.isSystemLogin = this.sessionService.getUser() && this.sessionService.getUser().username.toLowerCase() === 'system' ? true : false;
    }
    else{
      model.isSystemLogin = true;
    }
    return model;
  }

  public reverseMapPosition(model: PositionModel): IPositionDTO {

    let dto: IPositionDTO = <IPositionDTO>{};
    dto.id = model.id;
    dto.name = model.name;
    dto.jobClass = model.jobClass;
    dto.isPrimary = model.isPrimary;
    dto.hourlyRate = model.hourlyRate;
    dto.lastUpdateUsername = model.lastUpdateUsername;
    dto.lastUpdateDate = dateTimeUtils.convertToDtoString(model.lastUpdateDate);
    dto.clientDepartment = model.clientDepartment;
    dto.isDirectCare = model.isDirectCare;
    dto.legalId = model.legalId;
    dto.defaultShiftHours = model.defaultShiftHours;
    dto.objectAccount = model.objectAccount;
    dto.employeesCount = model.employeesCount;
    dto.jobListOrder = model.jobListOrder;
    dto.businessUnit = model.businessUnit;
    dto.replacesPositionsIds = model.replacesPositionsIds;
    dto.supervisorDepartment = model.supervisorDepartment;
    dto.supervisorOrganization = model.supervisorOrganization;
    dto.supervisorPosition = model.supervisorPosition;

    if (model.department) {
      dto.department = this.lookupMapService.mapDepartmentDto(model.department);
    }

    if (model.organization) {
      dto.organization = this.lookupMapService.mapOrganizationDto(model.organization);
    }

    if (model.budgetedPosition) {
      dto.budgetedPosition = this.reverseMapBudgetedPosition(model.budgetedPosition);
    }

    if(model.positionGroup)
    {
      dto.positionGroup = this.reverseMapPostionGroup(model.positionGroup);
    }

    if (model.payPolicy) {
      dto.payPolicy = this.reverseMapPayPolicy(model.payPolicy);
    }

    if (model.shiftDifferentialPolicy) {
      dto.shiftDifferentialPolicy = this.reverseMapShiftDifferentialPolicy(model.shiftDifferentialPolicy);
    }

    if (model.accrualPolicy) {
      dto.accrualPolicy = this.reverseMapAccrualPolicy(model.accrualPolicy);
    }

    if (model.groupedWith) {
      dto.groupedWith = this.lookupMapService.mapPositionDto(model.groupedWith);
    }

    if (model.pbjCmsPosition) {
      dto.pbjCmsPosition = this.reverseMapPbjPosition(model.pbjCmsPosition);
    }

    if (model.additionalInfo) {
      if (!(_.every(model.additionalInfo, _.isNil))) {
        dto.additionalInfo = this.reverseMapAdditionalInfo(model.additionalInfo);
      }
    }
    dto.essTemplate = model.essTemplate? this.essTemplatesMapService.mapToEssTemplateDefinition(model.essTemplate): null;
    dto.positionLicenseTypes = model.positionLicenseTypes;
    dto.operator = model.operator;
    return dto;
  }


  public mapPostionGroup(dto: IPositionGroup)
  {
    let postiongroup: PositionGroup = new PositionGroup();
    postiongroup.id = dto.id;
    postiongroup.name = dto.name;
    postiongroup.departmentId=dto.departmentId;
    return postiongroup;
  }

  public reverseMapPostionGroup(model: PositionGroup):IPositionGroup {
    return {
      id: model.id,
      name: model.name,
      departmentId:model.departmentId
    };
  }

  public mapBudgetedPosition(dto: IBudgetedPosition): BudgetedPosition {
    let budget: BudgetedPosition = new BudgetedPosition();
    budget.id = dto.id;
    budget.description = dto.description;
    return budget;
  }

  public reverseMapBudgetedPosition(model: BudgetedPosition): IBudgetedPosition {
    return {
      id: model.id,
      description: model.description
    };
  }

  public mapPayPolicy(dto: IPayPolicy): PayPolicy {
    let payPolicy: PayPolicy = new PayPolicy();
    payPolicy.name = dto.name;
    payPolicy.description = dto.description;
    return payPolicy;
  }

  public reverseMapPayPolicy(model: PayPolicy): IPayPolicy {
    return {
      name: model.name,
      description: model.description
    };
  }

  public mapShiftDifferentialPolicy(dto: IShiftDiffPolicy): ShiftDiffPolicy {
    let shiftDifferentialPolicy: ShiftDiffPolicy = new ShiftDiffPolicy();
    shiftDifferentialPolicy.name = dto.name;
    shiftDifferentialPolicy.description = dto.description;
    return shiftDifferentialPolicy;
  }

  public reverseMapShiftDifferentialPolicy(model: ShiftDiffPolicy): IShiftDiffPolicy {
    return {
      name: model.name,
      description: model.description
    };
  }

  public mapAccrualPolicy(dto: IAccrualPolicy): AccrualPolicy {
    let accrualPolicy: AccrualPolicy = new AccrualPolicy();
    accrualPolicy.name = dto.name;
    accrualPolicy.id = dto.id;
    return accrualPolicy;
  }

  public reverseMapAccrualPolicy(model: AccrualPolicy): IAccrualPolicy {
    return {
      id: model.id,
      name: model.name
    };
  }

  public mapPbjPosition(dto: IPbjPosition): PbjPosition {
    let pbjPosition: PbjPosition = new PbjPosition();
    pbjPosition.id = dto.id;
    pbjPosition.name = dto.name;
    return pbjPosition;
  }

  public reverseMapPbjPosition(model: PbjPosition): IPbjPosition {
    return {
      id: model.id,
      name: model.name,
      govermentCode: model.govermentCode
    };
  }

  public mapAdditionalInfo(dto: IAdditionalInfoDTO): AdditionalInfo {
    let additionalInfo: AdditionalInfo = new AdditionalInfo();
    additionalInfo.eeocClass = dto.eeocClass;
    additionalInfo.eeocGroup = dto.eeocGroup;
    additionalInfo.fte = dto.fte;
    additionalInfo.maximumRate = dto.maximumRate;
    additionalInfo.minimumRate = dto.minimumRate;
    additionalInfo.occupationCode = dto.occupationCode;
    additionalInfo.payGrade = dto.payGrade;
    additionalInfo.payType = dto.payType;
    additionalInfo.perDiemRate = dto.perDiemRate;
    additionalInfo.workersCompCode = dto.workersCompCode;
    return additionalInfo;
  }

  public reverseMapAdditionalInfo(model: AdditionalInfo): IAdditionalInfoDTO {
    return {
      eeocClass: model.eeocClass,
      eeocGroup: model.eeocGroup,
      fte: model.fte,
      maximumRate: model.maximumRate,
      minimumRate: model.minimumRate,
      occupationCode: model.occupationCode,
      payGrade: model.payGrade,
      payType: model.payType,
      perDiemRate: model.perDiemRate,
      workersCompCode: model.workersCompCode
    };
  }

  public mapOrganizations(orgs: IConfOrganization[]): ConfOrganization[] {
    let organizations: ConfOrganization[] = [];
    _.each(orgs, (org: IConfOrganization) => {
      let organization: ConfOrganization = new ConfOrganization();
      organization.id = org.id;
      organization.name = org.name;
      organization.orgLevelId = org.orgLevelId;
      let departments: Department[] = [];
      _.each(org.departments, (dep: IDepartment) => {
        let department: Department = this.lookupMapService.mapDepartment(dep);
        departments.push(department);
      });
      organization.departments = departments;
    });
    return orgs;
  }

  public mapPositionsActions(meta: FieldsMeta): PositionsActions {
    const actions = this.metaMapService.mapActions(meta);
    const positionActions = _.assign(new PositionsActions(), actions);
    _.forEach(meta.actions, (act: string) => {
      if (act === PositionsActionsType.manageGroups) positionActions.canManageGroups = true;
    });

    return positionActions;
  }
}
