import { OrgPayrollAppStatus, PBJMigrationVersion } from '../../models/index';
import { ConversionState, IConversionStateDTO } from './conversion-state';

export class PbjOrganization {
  public organizationId: number;
  public name: string;
  public cmsPbjCode: string;
  public address: string;
  public city: string;
  public state: string;
  public zip: string;
  public phone: string;
  public company: number;
  public companyCode: string;
  public group: string;
  public otherName: string;
  public type: string;
  public telepunchOffset: number;
  public timezoneOffset: number;
  public migrationVersion: PBJMigrationVersion;
  public conversionState: ConversionState;
  public conversionDate: Date;
  public messageForUsers: string;
  public ccnNumber: string;
  public asOshaCompany: boolean;
  public naicsCode: number;
  public naicsDescription: string;
  public establishmentType: string;
  public establishmentSize: number;
  public companyName: string;
  public payrollCode: number;
  public payrollAppStatus: OrgPayrollAppStatus;
  public payrollAppEffectiveDate: Date;
  public payGroup: string;
  public get id(): number {
    return this.organizationId;
  }
}

export interface IPbjOrganizationDTO {
  organizationId: number;
  name: string;
  cmsPbjCode: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  companyId: number;
  companyCode: string;
  group: string;
  otherName: string;
  type: string;
  telepunchOffset: number;
  timezoneOffset: number;
  migrationVersion: string;
  conversionDate: string;
  conversionState: IConversionStateDTO;
  messageForUsers: string;
  ccnNumber: string;
  asOshaCompany: boolean;
  naicsCode: number;
  naicsDescription: string;
  establishmentType: string;
  establishmentSize: number;
  companyName: string;
  payrollCode: number;
  payrollAppStatus: string;
  payrollAppEffectiveDate: string;
  payGroup: string;
}
