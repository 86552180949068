import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { mutableSelect } from '../../../../../../core/decorators/index';
import { Observable } from 'rxjs/Observable';
import { AddEmployeeWizardActions, AddEmployeeWizardStatus } from '../../../../store/index';
import { AddEmployeeModel } from '../../../../models/index';
import { AddEmployeeBasicComponent } from '../add-employee/add-employee-basic.component';
import { EmployeeSectionNavigationService } from '../../../../../../common/services/index';
import { appConfig } from '../../../../../../app.config';
import { SsoNavigationService } from '../../../../../../organization';
import { AppSettingsManageService } from './../../../../../../app-settings/services/app-settings-manage.service';
var AddEmployeeFinishComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddEmployeeFinishComponent, _super);
    function AddEmployeeFinishComponent(addEmployeeWizardActions, employeeListActions, router, route, appSettingManageService, ssoService) {
        var _this = _super.call(this, addEmployeeWizardActions) || this;
        _this.ssoService = ssoService;
        _this.isNgpUser = false;
        _this.payrollNumber = null;
        _this.employeeListActions = employeeListActions;
        _this.router = router;
        _this.route = route;
        _this.appSettingManageService = appSettingManageService;
        return _this;
    }
    Object.defineProperty(AddEmployeeFinishComponent.prototype, "wizardError", {
        get: function () {
            return (this.employeeAddStatus === AddEmployeeWizardStatus.error);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddEmployeeFinishComponent.prototype, "wizardSuccess", {
        get: function () {
            return (this.employeeAddStatus === AddEmployeeWizardStatus.success);
        },
        enumerable: true,
        configurable: true
    });
    AddEmployeeFinishComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params
            .combineLatest(this.route.queryParams)
            .subscribe(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.orgLevelId = +queryParams['orgLevelId'] || undefined;
        });
        this.addEmployeeWizardSubscription = this.addEmployeeWizard$.subscribe(function (value) {
            _this.onWizard(value.model);
            _this.employeeAddStatus = value.status;
            _this.newEmpId = value.newEmpId;
            _this.payrollNumber = value.payrollNumber;
        });
        this.getNGPEnabledFlag();
    };
    AddEmployeeFinishComponent.prototype.addAnotherEmployee = function () {
        this.addEmployeeWizardActions.startWizard(new AddEmployeeModel());
    };
    AddEmployeeFinishComponent.prototype.goThisEmployee = function () {
        var navService = new EmployeeSectionNavigationService(this.router, this.route);
        navService.NavigateToEmployeeSections(this.newEmpId, false);
    };
    AddEmployeeFinishComponent.prototype.activateNGPSSO = function () {
        var menuItem = 'nextgenpayroll';
        var url = appConfig.api.url + "/" + appConfig.api.version + "/" + appConfig.api.sso + "/" + menuItem + "?orglevel=" + this.orgLevelId;
        this.ssoService.navigationToV5(url);
    };
    AddEmployeeFinishComponent.prototype.goEmployeeList = function () {
        if (this.addEmployeeModel.organization && this.addEmployeeModel.department) {
            this.employeeListActions.selectEmployee(this.addEmployeeModel.organization.orgLevelId, this.addEmployeeModel.department.orgLevelId, this.newEmpId);
        }
        this.router.navigate(['../apps/scheduler/employee_list']);
    };
    AddEmployeeFinishComponent.prototype.getNGPEnabledFlag = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig, isNGPUser, isValidEmployeeType;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        isNGPUser = { 'IsNextgenPayrollEnabled': appServerConfig.IsNextgenPayrollEnabled };
                        isValidEmployeeType = true;
                        if ((this.addEmployeeModel['employeeType'] && this.addEmployeeModel.employeeType['name'] && (this.addEmployeeModel.employeeType.name === 'AFT' || this.addEmployeeModel.employeeType.name === 'APT'))
                            || (this.addEmployeeModel.organization && this.addEmployeeModel.organization.id == 1000)) {
                            isValidEmployeeType = false;
                        }
                        if (isNGPUser && isValidEmployeeType) {
                            this.isNgpUser = isNGPUser['IsNextgenPayrollEnabled'];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    tslib_1.__decorate([
        mutableSelect('addEmployeeWizard'),
        tslib_1.__metadata("design:type", Observable)
    ], AddEmployeeFinishComponent.prototype, "addEmployeeWizard$", void 0);
    return AddEmployeeFinishComponent;
}(AddEmployeeBasicComponent));
export { AddEmployeeFinishComponent };
