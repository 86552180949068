import * as _ from 'lodash';
import * as moment from 'moment';
var LMCommonTab = /** @class */ (function () {
    function LMCommonTab(service) {
        this.service = service;
        this.hasIncorrectContinuousDates = false;
        this.exceptionsDates = [];
    }
    Object.defineProperty(LMCommonTab.prototype, "hasExceptions", {
        get: function () {
            return _.size(this.exceptionsDates) > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCommonTab.prototype, "canEditRequest", {
        get: function () {
            return this.service.canChange;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCommonTab.prototype, "hasEstimatedDates", {
        get: function () {
            return this.service.hasEstimatedDates;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LMCommonTab.prototype, "hasActualDates", {
        get: function () {
            return this.service.hasActualDates;
        },
        enumerable: true,
        configurable: true
    });
    LMCommonTab.prototype.init = function () {
        var estimated = this.service.getEstamatedDates();
        var actual = this.service.getActualDates();
        this.estStartDate = estimated.startDate;
        this.estEndDate = estimated.endDate;
        this.actStartDate = actual.startDate;
        this.actEndDate = actual.endDate;
        this.exceptionsDates = this.service.getExceptions();
        this.estAllDay = this.service.getEstAllDay();
        this.actAllDay = this.service.getActAllDay();
    };
    LMCommonTab.prototype.onChangeEstStartDate = function (date) {
        this.service.setEstimatedDates(date, null);
    };
    LMCommonTab.prototype.onChangeEstEndDate = function (date) {
        this.service.setEstimatedDates(null, date);
    };
    LMCommonTab.prototype.onChangeEstAllDay = function () {
        if (this.estAllDay) {
            this.estStartDate = this.service.getStartOfDay(this.estStartDate);
            this.estEndDate = this.service.getEndOfDay(this.estEndDate);
            this.service.setEstimatedDates(this.estStartDate, this.estEndDate);
        }
        else {
            this.estEndDate = moment(this.estEndDate).hour(0).minute(0).second(0).toDate();
            this.service.setEstimatedDates(this.estStartDate, this.estEndDate);
        }
        this.service.setEstAllDay(this.estAllDay);
    };
    LMCommonTab.prototype.onChangeActStartDate = function (date) {
        this.service.setActualDates(date, null);
    };
    LMCommonTab.prototype.onChangeActEndDate = function (date) {
        this.service.setActualDates(null, date);
    };
    LMCommonTab.prototype.onChangeActAllDay = function () {
        if (this.actAllDay) {
            this.actStartDate = this.service.getStartOfDay(this.actStartDate);
            this.actEndDate = this.service.getEndOfDay(this.actEndDate);
            this.service.setActualDates(this.actStartDate, this.actEndDate);
        }
        else {
            this.actEndDate = moment(this.actEndDate).hour(0).minute(0).second(0).toDate();
            this.service.setActualDates(this.actStartDate, this.actEndDate);
        }
        this.service.setActAllDay(this.actAllDay);
    };
    return LMCommonTab;
}());
export { LMCommonTab };
