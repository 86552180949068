import * as tslib_1 from "tslib";
import { NavigationMenuRedirectService } from './../../services/navigation/navigation-menu-redirect.service';
import * as _ from 'lodash';
import { BenAdminMenuManagementService } from './../../services/navigation/ben-admin/ben-admin-menu-management.service';
import { OnInit, SimpleChanges, OnChanges, ElementRef, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe, destroyService } from '../../../core/decorators/index';
import { NavigationMenuManagementService } from '../../services/index';
import { appConfig } from '../../../app.config';
import { scheduleMicrotask } from '../../../core/utils/index';
import { ApplicationStateBusService } from '../../services/index';
import { MessageCenterConfigService } from '../../services/index';
import { AppSettingsManageService } from '../../../app-settings/services';
var NavigationMenuComponent = /** @class */ (function () {
    function NavigationMenuComponent(menuManagement, messageCenterConfigService, appStateBusService, benAdminService, redirectService, elementRef, renderer, appSettingManageService) {
        this.appStateBusService = appStateBusService;
        this.benAdminService = benAdminService;
        this.redirectService = redirectService;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.unavailableText = 'This menu item is unavailable at the selected organization level.';
        this.scheduleNotPost = false;
        this.ScheduleRotationTemplate = false;
        this.WorkerDupesReport = false;
        this.isNextgenPayrollEnabled = false;
        this.isIdealScheduleImportEnabled = false;
        this.messageCenterConfigService = messageCenterConfigService;
        this.menuManagement = menuManagement;
        this.showFavorites = false;
        this.posTop = 184;
        this.posLeft = 10;
        this.iconShift = 12;
        this.appSettingManageService = appSettingManageService;
    }
    NavigationMenuComponent.prototype.getScreenSize = function (event) {
        this.screenWidth = window.innerWidth;
    };
    NavigationMenuComponent.prototype.onLeftNavScroll = function () {
        if (this.isMobile()) {
            return;
        }
        var navTop = this.navMenu.nativeElement.scrollTop;
        var modifiedTop = (-42 - navTop);
        var natElement = document.querySelector('.expanded-scroll ul');
        if (natElement) {
            this.renderer.setStyle(natElement, 'margin-top', modifiedTop + "px");
            var divElement = document.querySelector('.expanded-scroll ul div');
            if (divElement) {
                this.renderer.setStyle(divElement, 'max-height', "initial");
            }
        }
    };
    NavigationMenuComponent.prototype.ngOnChanges = function (changes) {
        if (changes['isOpened']) {
            if (!this.isOpened) {
                this.expandedTopLevelId = 0;
                this.collapseAnothersTopItems(null);
            }
        }
    };
    NavigationMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSettings();
        this.applicationSubscription = this.appStateBusService.subscribeToSelectApp(function (app) {
            scheduleMicrotask(function () {
                _this.application = app;
                _this.reselectMenu();
            });
        });
        this.loadedSubscription = this.menuManagement.onLoaded$.subscribe(function (menuItems) {
            _this.menuItems = menuItems;
            _this.noData = (!_this.menuItems || _this.menuItems.length === 0);
            if (_this.noData) {
                return;
            }
            _this.toggleMessageCenterCombined(_this.menuItems);
            _this.checkGroupItemsChildren(_this.menuItems);
            _this.reselectMenu();
            Promise.resolve(_this.benAdminService.initialize());
        });
        this.menuChangedSubscription = this.appStateBusService.menuSelected$.subscribe(function (menuItems) {
            scheduleMicrotask(function () {
                if (_this.noData) {
                    return;
                }
                _this.reselectMenu();
            });
        });
        if (this.isMobile()) {
            this.posTop = 135;
        }
    };
    NavigationMenuComponent.prototype.toggleTopItem = function (menuItem) {
        menuItem.isExpanded = !menuItem.isExpanded;
        if (!menuItem.isExpanded) {
            this.expandedTopLevelId = 0;
            return;
        }
        this.expandedTopLevelId = menuItem.id;
        this.collapseAnothersTopItems(menuItem);
    };
    NavigationMenuComponent.prototype.collapseAnothersTopItems = function (expandedIdAtTop) {
        _.forEach(this.menuItems, function (topItem) {
            if (!expandedIdAtTop || topItem.id !== expandedIdAtTop.id || topItem.type !== expandedIdAtTop.type) {
                topItem.isExpanded = false;
                _.forEach(topItem.childs, function (groupItem) {
                    groupItem.isExpanded = false;
                    _.forEach(groupItem.childs, function (item) {
                        item.isExpanded = false;
                    });
                });
            }
        });
    };
    NavigationMenuComponent.prototype.toggleGroupItem = function (topMenuItem, menuItem) {
        menuItem.isExpanded = !menuItem.isExpanded;
        if (!menuItem.isExpanded) {
            this.expandedGropLevelId = 0;
            return;
        }
        this.expandedTopLevelId = topMenuItem.id;
        this.expandedGropLevelId = menuItem.id;
        _.forEach(topMenuItem.childs, function (groupItem) {
            if (groupItem.id !== menuItem.id || groupItem.type !== menuItem.type) {
                groupItem.isExpanded = false;
                _.forEach(groupItem.childs, function (item) {
                    item.isExpanded = false;
                });
            }
        });
    };
    NavigationMenuComponent.prototype.toggleItem = function (menuItem) {
        menuItem.isSelected = true;
        menuItem.isExpanded = !menuItem.isExpanded;
        this.menuItems
            .filter(function (mi) { return mi.id !== menuItem.id; })
            .forEach(function (mi) { return mi.isExpanded = mi.isSelected = false; });
    };
    NavigationMenuComponent.prototype.selectGlobalMenuItem = function (topMenuItem, groupMenuItem, menuItem) {
        this.expandedTopLevelId = 0;
        this.expandedGropLevelId = 0;
        _.forEach(this.menuItems, function (topItem) {
            if (topItem.id !== topMenuItem.id || topItem.type !== topMenuItem.type) {
                topItem.isExpanded = false;
            }
            else {
                topItem.isExpanded = true;
            }
        });
        if (groupMenuItem) {
            _.forEach(topMenuItem.childs, function (groupItem) {
                groupItem.isExpanded = false;
                if (groupItem.id !== groupMenuItem.id || groupItem.type !== groupMenuItem.type) {
                    groupItem.isSelected = false;
                }
            });
            _.forEach(groupMenuItem.childs, function (item) {
                item.isExpanded = false;
                if (item.id !== menuItem.id || item.type !== menuItem.type) {
                    item.isSelected = false;
                }
            });
        }
        var item = groupMenuItem ? groupMenuItem : topMenuItem;
        if (this.isDisabled(item)) {
            return;
        }
        this.redirectService.open(item);
    };
    NavigationMenuComponent.prototype.selectMenuItem = function (topMenuItem, groupMenuItem, menuItem) {
        this.expandedTopLevelId = topMenuItem.id;
        this.expandedGropLevelId = 0;
        menuItem.isSelected = true;
        _.forEach(this.menuItems, function (topItem) {
            if (topItem.id !== topMenuItem.id || topItem.type !== topMenuItem.type) {
                topItem.isExpanded = false;
            }
            else {
                topItem.isExpanded = topItem.isSelected = true;
            }
        });
        _.forEach(topMenuItem.childs, function (groupItem) {
            groupItem.isExpanded = false;
            if (groupItem.id !== groupMenuItem.id || groupItem.type !== groupMenuItem.type) {
                groupItem.isSelected = false;
            }
        });
        _.forEach(groupMenuItem.childs, function (item) {
            item.isExpanded = false;
            if (item.id !== menuItem.id || item.type !== menuItem.type) {
                item.isSelected = false;
            }
        });
        if (!this.selectedApplicationMenu || this.selectedApplicationMenu.applicationId !== topMenuItem.applicationId) {
            this.selectedApplicationMenu = topMenuItem;
        }
        if (this.isDisabled(menuItem)) {
            return;
        }
        this.appStateBusService.clickMenuItem(menuItem);
        this.redirectService.openByLink(menuItem);
        if (groupMenuItem.childLevelsCount) {
            if (groupMenuItem.childLevelsCount === 3) {
                this.toggleTopItem(topMenuItem);
            }
        }
    };
    NavigationMenuComponent.prototype.isExpanded = function (menuItem) {
        return menuItem.isExpanded;
    };
    NavigationMenuComponent.prototype.menuMouseEnter = function (menuItem) {
        if (this.isMobile() || !menuItem || this.isHidden(menuItem)) {
            return;
        }
        menuItem.isHovered = true;
    };
    NavigationMenuComponent.prototype.menuMouseLeave = function (menuItem) {
        if (this.isMobile() || !menuItem || this.isHidden(menuItem)) {
            return;
        }
        menuItem.isHovered = false;
    };
    NavigationMenuComponent.prototype.isDisabled = function (menuItem) {
        return !this.menuManagement.isMenuSupportOrgLevel(menuItem);
    };
    NavigationMenuComponent.prototype.isHidden = function (menuItem) {
        return this.isMobile() && !menuItem.isAvailableOnMobile;
    };
    NavigationMenuComponent.prototype.isMobile = function () {
        return (screen.width <= appConfig.mobileMaxWidth);
    };
    NavigationMenuComponent.prototype.isLastVisibleItem = function (groupMenuItem, menuIndex) {
        if (!this.isMobile()) {
            return menuIndex === (groupMenuItem.childs.length - 1);
        }
        var nextVisible = _.find(groupMenuItem.childs, function (item) { return item.isAvailableOnMobile; }, menuIndex + 1);
        return !nextVisible;
    };
    NavigationMenuComponent.prototype.reselectMenu = function () {
        var _this = this;
        if (!this.menuItems) {
            this.selectedApplicationMenu = null;
            return;
        }
        if (!this.application || !this.application.id) {
            this.reexpandMenu(0);
            return;
        }
        this.selectedApplicationMenu = _.find(this.menuItems, function (menuItem) {
            return menuItem.applicationId === _this.application.id;
        });
        if (this.selectedApplicationMenu) {
            this.reexpandMenu(this.application.id);
        }
    };
    NavigationMenuComponent.prototype.reexpandMenu = function (selectedAppId) {
        var _this = this;
        _.forEach(this.menuItems, function (menuItem) {
            if (selectedAppId === -1 || menuItem.applicationId !== selectedAppId) {
                menuItem.isSelected = false;
            }
            else {
                menuItem.isSelected = true;
            }
            if (menuItem.id === _this.expandedTopLevelId) {
                menuItem.isExpanded = true;
                _.forEach(menuItem.childs, function (groupMenuItem) {
                    if (groupMenuItem.id === _this.expandedGropLevelId) {
                        groupMenuItem.isExpanded = true;
                    }
                });
            }
            else {
                menuItem.isExpanded = false;
            }
        });
    };
    NavigationMenuComponent.prototype.getThreeLevelMenuItem = function (menuItems) {
        var threeLevelMenuItem;
        _.forEach(menuItems, function (menuItem) {
            if (menuItem.childLevelsCount === 3) {
                threeLevelMenuItem = menuItem;
            }
        });
        return threeLevelMenuItem;
    };
    NavigationMenuComponent.prototype.checkGroupItemsChildren = function (menuItems) {
        var _this = this;
        var threeLevelMenuItem = this.getThreeLevelMenuItem(menuItems);
        if (threeLevelMenuItem && threeLevelMenuItem.childs) {
            _.forEach(threeLevelMenuItem.childs, function (groupMenuItem) {
                _this.checkAllChildrenDisabled(groupMenuItem);
            });
        }
    };
    NavigationMenuComponent.prototype.checkAllChildrenDisabled = function (groupMenuItem) {
        var _this = this;
        if (groupMenuItem && groupMenuItem.childs) {
            groupMenuItem.allChildrenDisabled = true;
            _.forEach(groupMenuItem.childs, function (menuItem) {
                var menuDisabled = _this.isDisabled(menuItem);
                if (!menuDisabled) {
                    groupMenuItem.allChildrenDisabled = false;
                }
            });
        }
    };
    NavigationMenuComponent.prototype.toggleMessageCenterCombined = function (menuItems) {
        var _this = this;
        _.forEach(menuItems, function (menuItem) {
            if (menuItem.name == 'message_center') {
                _this.messageCenterConfigService.switchMessageCenterUrl(true);
            }
            else if (menuItem.name == 'message_center_sms') {
                _this.messageCenterConfigService.switchMessageCenterUrl(false);
            }
        });
    };
    NavigationMenuComponent.prototype.onToggleFavoritesView = function (event) {
        this.showFavorites = !this.showFavorites;
    };
    NavigationMenuComponent.prototype.pressEscape = function (event) {
        if (this.showFavorites && event.code === 'Escape') {
            this.showFavorites = false;
        }
    };
    NavigationMenuComponent.prototype.clickOutside = function (event) {
        var elem = this.elementRef.nativeElement;
        var node = event.target;
        if (elem && this.showFavorites) {
            if (!elem.contains(node) && node.parentElement != null) {
                this.showFavorites = false;
            }
        }
    };
    NavigationMenuComponent.prototype.getSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var appServerConfig;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.appSettingManageService.getAppServerConfig()];
                    case 1:
                        appServerConfig = _a.sent();
                        this.scheduleNotPost = appServerConfig.ScheduleNotPosted;
                        this.ScheduleRotationTemplate = appServerConfig.ScheduleRotationTemplate;
                        this.WorkerDupesReport = appServerConfig.WorkerDupesReport;
                        this.isNextgenPayrollEnabled = appServerConfig.IsNextgenPayrollEnabled;
                        this.isIdealScheduleImportEnabled = appServerConfig.isDownloadImportOptionEnabled;
                        return [2 /*return*/];
                }
            });
        });
    };
    NavigationMenuComponent.prototype.isMenuItemEnabled = function (item) {
        if (item.name === 'schedules_not_posted')
            return (item.name === 'schedules_not_posted' ? this.scheduleNotPost : true);
        else if (item.name === 'scheduled_rotations')
            return (item.name === 'scheduled_rotations' ? this.ScheduleRotationTemplate : true);
        else if (item.name === 'pbj-duplicate-workers')
            return (item.name === 'pbj-duplicate-workers' ? this.WorkerDupesReport : true);
        else if (item.name === 'code_mapping')
            return (item.name === 'code_mapping' ? this.isNextgenPayrollEnabled : true);
        else if (item.name === 'File_Uploads')
            return (item.name === 'File_Uploads' ? this.isIdealScheduleImportEnabled : true);
        else
            return true;
    };
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NavigationMenuComponent.prototype, "loadMenuSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NavigationMenuComponent.prototype, "preselectedOrgLevelSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NavigationMenuComponent.prototype, "applicationSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NavigationMenuComponent.prototype, "loadedSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], NavigationMenuComponent.prototype, "menuChangedSubscription", void 0);
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", NavigationMenuManagementService)
    ], NavigationMenuComponent.prototype, "menuManagement", void 0);
    return NavigationMenuComponent;
}());
export { NavigationMenuComponent };
