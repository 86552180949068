import { EmployeeSectionsBase } from '../employee-sections-base';
import { EmployeeSectionsAttendancePoints, IEmployeeSectionsAttendancePoints, EmployeeSectionsAttendance, IEmployeeSectionsAttendance } from './index';
import { IEmployeeSectionsSubsectionContainer } from '../index';
import { EmployeeSectionsAttendanceBuybacks, IEmployeeSectionsAttendanceBuybacks } from './employee-sections-attendance-points';

export interface IEmployeeSectionsPerformance {
  attendancePoints: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsAttendancePoints>;
  attendance: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsAttendance>;
  buyBackPoints: IEmployeeSectionsSubsectionContainer<IEmployeeSectionsAttendanceBuybacks>;
}

export class EmployeeSectionsPerformance extends EmployeeSectionsBase {
  
  public attendancePoints: EmployeeSectionsAttendancePoints;
  public attendance: EmployeeSectionsAttendance;
  public buyBackPoints:EmployeeSectionsAttendanceBuybacks;
}
