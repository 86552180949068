import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, } from '@angular/core';
import { OrgLevel } from '../../../../../state-model/models/index';
import { unsubscribeAll } from '../../../../../core/decorators/index';
import { DeviceDetectorService } from '../../../../../common/services/index';
import { ColumnManagementService, StateManagementService } from '../../../../../common/services/index';
import { DateRange, IDateRange } from '../../../../../core/models/index';
import { PmRosterManagementService } from '../../../services/index';
var PmRosterToolbarComponent = /** @class */ (function () {
    function PmRosterToolbarComponent(pmManagementService, columnManagementService, stateManagement, devDetector) {
        this.pmManagementService = pmManagementService;
        this.columnManagementService = columnManagementService;
        this.stateManagement = stateManagement;
        this.devDetector = devDetector;
        this.yearInSeconds = 31622400;
        this.dateRange = new DateRange(null, null);
        this.maxRangeError = 'The dates range cannot be more than 366 days';
        this.canCreateNewReview = false;
        this.columnsStateName = 'PerformanceManagementRoster';
        this.subscriptions = {};
    }
    Object.defineProperty(PmRosterToolbarComponent.prototype, "isDesktop", {
        get: function () {
            return this.devDetector.isDesktop;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmRosterToolbarComponent.prototype, "dateRangeWidth", {
        get: function () {
            return this.isDesktop ? 325 : 246;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PmRosterToolbarComponent.prototype, "newReviewBtnWidth", {
        get: function () {
            return this.isDesktop ? 175 : 35;
        },
        enumerable: true,
        configurable: true
    });
    PmRosterToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initServices();
        this.defineDateRange();
        this.subscriptions.abilityToCreateNewReview = this.pmManagementService
            .subscribeToCanCreateNewReview(function (canCreate) { return (_this.canCreateNewReview = canCreate); });
    };
    PmRosterToolbarComponent.prototype.ngOnDestroy = function () { };
    PmRosterToolbarComponent.prototype.onClickCreate = function () {
        this.pmManagementService.openReviewPopup(null);
    };
    PmRosterToolbarComponent.prototype.onChangeDates = function (range) {
        this.pmManagementService.changeDateRange(range);
    };
    PmRosterToolbarComponent.prototype.onClickExport = function (isPDF) {
        this.pmManagementService.exportTo(isPDF);
    };
    PmRosterToolbarComponent.prototype.defineDateRange = function () {
        this.dateRange = this.pmManagementService.getDefaultDateRange();
        this.onChangeDates(this.dateRange);
    };
    PmRosterToolbarComponent.prototype.initServices = function () {
        this.stateManagement.init('PerformanceManagementRosterComponent');
        this.columnManagementService.init('PerformanceManagementRosterComponent');
        this.columnManagementService.initGroup(this.columnsStateName, 9);
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], PmRosterToolbarComponent.prototype, "subscriptions", void 0);
    return PmRosterToolbarComponent;
}());
export { PmRosterToolbarComponent };
