import * as _ from 'lodash';
import * as moment from 'moment';

import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, Validator, AbstractControl, NgControl, NgModel, NgForm } from '@angular/forms';

import { Assert } from '../../../../framework/assert/assert';
import { createValuAccessor } from '../../../utils/index';
import { CustomDomEvents } from '../../../models/index';
import { dateTimeUtils } from '../../../../common/utils/index';
import { RangeDates, IRangeDates } from '../../../models/range-dates';

import { appConfig, IApplicationConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-date-range-ngx',
  templateUrl: 'date-range-ngx.component.html',
  styleUrls: ['date-range-ngx.component.scss'],
})
export class DateRangeNgxComponent implements OnChanges {
  @Input()
  public startDate: Date;
  @Input()
  public endDate: Date;
  @Input()
  public maxSecRange: number;
  @Input()
  public maxRangeErrorText: string;
  @Input()
  public startDatePlaceholder: string;
  @Input()
  public endDatePlaceholder: string;
  @Input()
  public startDateReadonly: boolean;
  @Input()
  public endDateReadonly: boolean;
  @Input()
  public hasApplyButton: boolean;
  @Output('rangeDateChanged')
  public rangeDateChanged: EventEmitter<IRangeDates>;

  @Input() isSmallDevice : boolean;
  public format: string;
  public dates: IRangeDates;
  public appConfig: IApplicationConfig;
  public isValidRange: boolean;

  public get datesValid(): boolean {
    return this.isValidRange && this.isValidDate(this.dates.startDate) && this.isValidDate(this.dates.startDate) && this.isValidFields;
  }

  @ViewChild('startDate', {static: true})
  private startDatePicker: NgModel;
  @ViewChild('endDate', {static: true})
  private endDatePicker: NgModel;

  constructor() {
    this.format = 'MM/dd/yyyy';
    this.startDatePlaceholder = 'From';
    this.endDatePlaceholder = 'To';
    this.dates = new RangeDates();
    this.dates.startDate = null;
    this.dates.endDate = null;
    this.rangeDateChanged = new EventEmitter();
    this.appConfig = appConfig;
    this.isValidRange = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const startDate = _.get(changes, 'startDate.currentValue', null);
    const endDate = _.get(changes, 'endDate.currentValue', null);

    if (this.isValidDate(startDate)) {
      this.dates.startDate = moment(startDate).toDate();
    }

    if (this.isValidDate(endDate)) {
      this.dates.endDate = moment(endDate).toDate();
    }
    this.checkValidRange();
  }

  public onRangeDateChanged(startDate: Date, endDate: Date): void {
    Promise.resolve(null)
      .then(() => {
        if (this.isValidDate(startDate) && this.isValidDate(endDate)) {
          this.dates.startDate = startDate;
          this.dates.endDate = endDate;
          this.checkValidRange();
          if (this.isValidRange) {
            this.emitRangeDateChanged(true);
          }
        }
      });
  }

  public onStartDateChanged(date: Date): void {
    Promise.resolve(null)
      .then(() => {
        if (this.isValidDate(date)) {
          this.dates.startDate = date;
          this.checkValidRange();
          if (this.isValidFields) {
            this.emitRangeDateChanged(false);
          }
        }
      });
  }

  public onEndDateChanged(date: Date): void {
    Promise.resolve(null)
      .then(() => {
        if (this.isValidDate(date)) {
          this.dates.endDate = date;
          this.checkValidRange();
          if (this.isValidFields) {
            this.emitRangeDateChanged(false);
          }
        }
      });
  }

  public onApply(): void {
    if (this.datesValid) {
      this.emitRangeDateChanged(true);
    }
  }

  public get isValidFields(): boolean {
    return this.startDatePicker.valid && this.endDatePicker.valid;
  }

  private emitRangeDateChanged(force: boolean): void {
    if (force || !this.hasApplyButton) {
      this.rangeDateChanged.emit(this.dates);
    }
  }

  private isValidDate(date: Date): boolean {
    return (!_.isNull(date) && !_.isUndefined(date)) && dateTimeUtils.validateDate(date);
  }

  private checkValidRange(): void {
    if (!this.maxSecRange || this.maxSecRange <= 0 || !this.isValidFields) {
      this.isValidRange = true;
      return;
    }
    this.isValidRange = moment(this.dates.endDate).diff(this.dates.startDate, 's') < this.maxSecRange;
  }
}
