import { AuthenticationApiService } from '../authentication-api/authentication.api-service';
var PasswordValidatorAdapter = /** @class */ (function () {
    function PasswordValidatorAdapter(api) {
        this.api = api;
    }
    PasswordValidatorAdapter.prototype.validate = function (validationName, value) {
        var params = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            params[_i - 2] = arguments[_i];
        }
        var promise;
        var defPromise = Promise.resolve({ isValid: true, isReadyForValidation: true, errorMessage: null });
        var password = value;
        switch (validationName) {
            case PasswordValidatorAdapter.passwordValidation:
                promise = this.api.validatePassword(password);
                break;
            case PasswordValidatorAdapter.passwordValidationUnsecure:
                var alias = params[1];
                promise = this.api.validatePasswordUnsecure(alias, password);
                break;
            default:
                promise = defPromise;
        }
        return promise;
    };
    PasswordValidatorAdapter.passwordValidation = 'passwordValidation';
    PasswordValidatorAdapter.passwordValidationUnsecure = 'passwordValidationUnsecure';
    return PasswordValidatorAdapter;
}());
export { PasswordValidatorAdapter };
