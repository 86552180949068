import { IEmpType, EmpType, IPosition, Position, IDepartment, Department } from '../../../organization/models/lookup/index';
import { EmployeeDefinition } from '../../../organization/models/employee/index';


export class IndSchEmployeeSchedulesRequest {
  public employeeId: number;
  public orgLevelId: number;
  public startDate: Date;
  public scheduleWeeksCount: number;
  public employeeName: string;
  public currentDate?: Date;
}
