/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeclock-review.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./timeclock-item/timeclock-item.component.ngfactory";
import * as i4 from "./timeclock-item/timeclock-item.component";
import * as i5 from "../../../common/services/modal/modal.service";
import * as i6 from "../../../organization/services/time-clock/time-clock-data.service";
import * as i7 from "../../services/signalR/ta-signalr-service";
import * as i8 from "../../../core/services/session/session.service";
import * as i9 from "./timeclock-review.component";
import * as i10 from "../../../core/components/angular2-notifications/simple-notifications/services/notifications.service";
var styles_TimeclockReviewComponent = [i0.styles];
var RenderType_TimeclockReviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimeclockReviewComponent, data: {} });
export { RenderType_TimeclockReviewComponent as RenderType_TimeclockReviewComponent };
function View_TimeclockReviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "content-item": 0, "virtual-time-clock": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "slx-timeclock-item", [], null, null, null, i3.View_TimeclockItemComponent_0, i3.RenderType_TimeclockItemComponent)), i1.ɵdid(5, 114688, null, 0, i4.TimeclockItemComponent, [i5.ModalService, i6.TimeclockDataService, i7.TaSignalrService, i8.SessionService], { timeclockDailySummary: [0, "timeclockDailySummary"], expandable: [1, "expandable"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, true, _v.context.$implicit.isVirtual); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = false; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_TimeclockReviewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimeclockReviewComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.timeclockDailySummaryContainer == null) ? null : _co.timeclockDailySummaryContainer.records); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TimeclockReviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "slx-timeclocks-review", [], null, null, null, View_TimeclockReviewComponent_0, RenderType_TimeclockReviewComponent)), i1.ɵdid(1, 114688, null, 0, i9.TimeclockReviewComponent, [i6.TimeclockDataService, i7.TaSignalrService, i8.SessionService, i10.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimeclockReviewComponentNgFactory = i1.ɵccf("slx-timeclocks-review", i9.TimeclockReviewComponent, View_TimeclockReviewComponent_Host_0, {}, {}, []);
export { TimeclockReviewComponentNgFactory as TimeclockReviewComponentNgFactory };
