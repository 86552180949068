import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { OnInit, OnDestroy } from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';
import { Subscription } from 'rxjs/Subscription';
import { ModalService, KendoGridStateHelper, KendoGridCustomSelectionHelper, ConfirmOptions, ConfirmDialogComponent } from '../../../common/index';
import { destroyService, unsubscribe } from '../../../core/decorators/index';
import { ColorUtil } from '../../../common/utils/color-util';
import { appConfig } from '../../../app.config';
import { appMessages } from '../../../app.messages';
import { AccessManagementService } from '../../services/index';
import { AbsenceModel, EditableListActionKind } from '../../models/index';
import { ConfigurationComponentHelper } from '../../utils/configuration-component-helper';
import { AbsencesConfigurationManagementService } from '../../services/absences/absence-configuration-management.service';
import { NgForm } from '@angular/forms';
var AbsencesComponent = /** @class */ (function () {
    function AbsencesComponent(management, access, modalService) {
        var _this = this;
        this.access = access;
        this.modalService = modalService;
        this.prohibitedNameValues = [];
        this.management = management;
        this.gridState = new KendoGridStateHelper();
        this.selectionHelper = new KendoGridCustomSelectionHelper(this.gridState.view, true);
        this.crudHelper = new ConfigurationComponentHelper();
        this.crudHelper.gridState = this.gridState;
        this.crudHelper.selectionHelper = this.selectionHelper;
        this.crudHelper.management = management;
        // directive intercepted methods (scope bug)
        this.onAddItem = function () {
            var absence = new AbsenceModel();
            _this.updateProhibitedNameValues(absence);
            _this.crudHelper.addItem(absence);
        };
        this.onDeletedItems = function () {
            _this.crudHelper.deleteSelected();
        };
    }
    Object.defineProperty(AbsencesComponent.prototype, "grid", {
        get: function () {
            return this.m_grid;
        },
        set: function (value) {
            if (this.crudHelper)
                this.crudHelper.grid = value;
            this.m_grid = value;
        },
        enumerable: true,
        configurable: true
    });
    AbsencesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.appMessages = appMessages;
        this.appConfig = appConfig;
        this.state = {
            isLoading: false,
            configureMode: true,
            copyMode: false
        };
        this.stateSubscription = this.management.onStateChanged$.subscribe(function (state) {
            if (_.has(state, 'isLoading'))
                _this.state.isLoading = state.isLoading;
            if (_.has(state, 'configureMode'))
                _this.state.configureMode = state.configureMode;
            if (_.has(state, 'copyMode'))
                _this.state.copyMode = state.copyMode;
        });
        this.editSubscription = this.management.editItemCmd$.subscribe(function (absence) {
            if (absence) {
                _this.updateProhibitedNameValues(absence);
                _this.mainFormSubscription = _this.mainForm.statusChanges.subscribe(function () {
                    if (_this.mainForm.dirty) {
                        _this.management.markAsDirty();
                    }
                });
            }
            else {
                if (_this.mainFormSubscription) {
                    _this.mainFormSubscription.unsubscribe();
                }
            }
        });
        this.gridSelectSubscription = this.gridState.onSelectionChanged.subscribe(function (records) {
            _this.crudHelper.selectionChange(_.first(records), 0);
        });
        this.removeSubscription = this.management.removeItemsCmd$.subscribe(function (item) {
            var options = new ConfirmOptions();
            options.showCancel = true;
            options.showOK = true;
            ConfirmDialogComponent.openDialog('Confirmation', 'Do you want to delete the absence?', _this.modalService, function (result) {
                if (result) {
                    _this.management.doRemoveItem(item);
                }
            }, options);
        });
        this.crudHelper.grid = this.grid;
        this.crudHelper.init();
        this.management.init();
    };
    // Must be, see #issueWithAOTCompiler
    AbsencesComponent.prototype.ngOnDestroy = function () {
        if (this.crudHelper) {
            this.crudHelper.destroy();
        }
    };
    AbsencesComponent.prototype.updateProhibitedNameValues = function (absence) {
        if (absence) {
            var values_1 = [];
            _.each(this.management.container.records, function (s) {
                if (absence.code !== s.code) {
                    values_1.push(s.code);
                }
            });
            this.prohibitedNameValues = values_1;
        }
    };
    AbsencesComponent.prototype.onColorPickerChange = function (absence, colorCode) {
        if (absence)
            absence.color = ColorUtil.HexToDec(colorCode);
    };
    AbsencesComponent.prototype.getColor = function (absence) {
        return absence ? ColorUtil.DecToHexString(absence.color, true) : '#ffffff';
    };
    AbsencesComponent.prototype.onMobileRendererEvent = function (action, item, index) {
        if (action === EditableListActionKind.SELECTION_CHANGE) {
            this.crudHelper.selectionChange(item, index);
        }
        else if (action === EditableListActionKind.START_EDIT) {
            this.crudHelper.startEdit(item, index);
        }
    };
    AbsencesComponent.prototype.onMobileEditorEvent = function (action, item, index) {
        if (action === EditableListActionKind.COMPLETE_EDIT) {
            this.crudHelper.completeEdit(item, index);
        }
        else if (action === EditableListActionKind.CANCEL_EDIT) {
            this.crudHelper.cancelEdit(index);
        }
    };
    tslib_1.__decorate([
        destroyService(),
        tslib_1.__metadata("design:type", AbsencesConfigurationManagementService)
    ], AbsencesComponent.prototype, "management", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AbsencesComponent.prototype, "stateSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AbsencesComponent.prototype, "editSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AbsencesComponent.prototype, "removeSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AbsencesComponent.prototype, "gridSelectSubscription", void 0);
    tslib_1.__decorate([
        unsubscribe(),
        tslib_1.__metadata("design:type", Subscription)
    ], AbsencesComponent.prototype, "mainFormSubscription", void 0);
    return AbsencesComponent;
}());
export { AbsencesComponent };
