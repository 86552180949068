import { PayrollExportConfigContainer } from './../../../../models/payroll-export-test/payroll-export-config-container';
import { PayrollExportConfigComponent } from './../payroll-export-config.component';
import { PayrollExportTestManagementService } from './../../../../services/payroll-export-test/payroll-export-test-management.service';
import { ModalService } from '../../../../../../common/services/modal/modal.service';
import { DialogOptions } from '../../../../../../common/models/dialog-options';
import { Component, OnInit, Input, Provider, ViewChild } from '@angular/core';
import { IDialog } from '../../../../../../common/index';

@Component({
    moduleId: module.id,
    selector: 'slx-payroll-export-config-popup',
    templateUrl: 'payroll-export-config-popup.component.html',
    styleUrls: ['payroll-export-config-popup.component.scss']
})
export class PayrollExportConfigPopupComponent implements IDialog, OnInit {
    @ViewChild('exportConfig', {static: true})
    public exportConfig: PayrollExportConfigComponent;
    public dialogResult: boolean;
    public isLoading: boolean = false;

    public static openDialog(manageService: PayrollExportTestManagementService,
        modalService: ModalService,
        callback: (result: boolean) => void): PayrollExportConfigPopupComponent {
        let dialogOptions: DialogOptions = new DialogOptions();
        dialogOptions.height = 600;
        dialogOptions.width = 700;
        let resolvedProviders: Provider[] = [
          {
            provide: DialogOptions,
            useValue: dialogOptions
          },
          {
            provide: PayrollExportTestManagementService,
            useValue: manageService
          }

        ];
        let dialog: PayrollExportConfigPopupComponent = modalService.globalAnchor
          .openDialog(PayrollExportConfigPopupComponent, 'Payroll Export Settings', dialogOptions, resolvedProviders, (result: boolean, uniqueId?: string) => {
            callback(result);
          });
        return dialog;
    }

    constructor(private options: DialogOptions, private modalService: ModalService, private manageService: PayrollExportTestManagementService) {
    }

    public ngOnInit(): void {
    }

    public onSave(): void {
      this.isLoading = true;
      this.exportConfig.saveConfig().then((value: PayrollExportConfigContainer) => {
          this.dialogResult = true;
          this.modalService.closeWindow(this.options.windowUniqueId);
          this.isLoading = false;
      });
  }

    public onClose(): void {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    }
}
