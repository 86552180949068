  <div class="modal-content">
    <div class="modal-body">
      <slx-spinner [show]="state.isLoading">
        <slx-vacation-employee-details [orgLevelId]="orgLevelId" *ngIf="!state.isSummary" [scheduleRecords]="employeeDetails?.records"></slx-vacation-employee-details>
        <slx-vacation-employee-summary *ngIf="state.isSummary" [employeeSummary]="employeeSummary" [year]="year"></slx-vacation-employee-summary>
      </slx-spinner>
    </div>
    <div class="modal-footer">
      <div class="link-div">
          <a *ngIf="!state.isSummary" (click)="toggleToSummary()">Summary</a>
          <a *ngIf="state.isSummary" (click)="toggleToDetails()">Back to PTO Details</a>
      </div>
      <button type="button" (click)="onCancel()" class="btn btn-default">Cancel</button>
      <button *ngIf="!state.isSummary" type="button" (click)="onOk()" class="btn btn-primary">OK</button>
    </div>
  </div>
