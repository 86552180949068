<ul>
    <li>
      <input
        class="k-textbox k-input k-rounded-md"
        (input)="onInput($event)"
      />
    </li>
    <li
      #itemElement
      *ngFor="let item of currentData; let i = index"
      (click)="onSelectionChange(valueAccessor(item), itemElement)"
      [ngClass]="{ 'k-selected': isItemSelected(item) }"
    >
      <input
        type="checkbox"
        #notification
        kendoCheckBox
        [checked]="isItemSelected(item)"
        class="k-checkbox"
      />
      <kendo-label
        class="k-checkbox-label"
        [for]="notification"
      >{{ isPrimitive ? item : item[textField] }}</kendo-label>
    </li>
  </ul>