import { employeeFactory, employeesFactory, scheduleTotalFactory, scheduleSubtotalFactory } from '../master-schedule/master-schedule.initial-state';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Map, fromJS } from 'immutable';

import { appConfig } from '../../../app.config';
import {
  IMasterScheduleRow,
  MasterScheduleEntryRow, MasterScheduleShiftInfo, MasterScheduleEntryCell, MasterScheduleSubtotalRow, MasterScheduleTotalRow,
  MasterScheduleTotalCell, MasterScheduleRecordType,
  ScheduleSubtotal, ScheduleTotal, DayTotal, ScheduleTotalSummary, ISchedulePosition, ScheduleEntry, ScheduleEntryShift
} from '../../models/index';
import {
  IPrescheduledOvertime, PrescheduledOvertime
} from '../../../organization/models/schedule/index';
import { EmployeeScheduleDefinition, ScheduleEntryDefinition, ScheduledShiftDefinition, Position } from '../../../organization/index';
import { dateTimeUtils } from '../../../common/utils/index';

export class EmployeesStateSelector {

  public static getDateKey(date: Date): string {
    let day: string = `Day${moment(date).format(appConfig.requestDate)}`;
    return day;
  }
  public static getRows(employeeEntries: EmployeeScheduleDefinition[]): Map<string, any> {
    let rows: IMasterScheduleRow[] = EmployeesStateSelector.mapMasterScheduleEntryRows(employeeEntries);
    let map = _.keyBy(rows, (row: IMasterScheduleRow) => row.key);
    return fromJS(map);
  }

  public static mapMasterScheduleEntryRows(records: EmployeeScheduleDefinition[]): IMasterScheduleRow[] {
    let rows: IMasterScheduleRow[] = [];
    _.forEach(records, (record: EmployeeScheduleDefinition) => {
      let entryRow = this.mapMasterScheduleEntryRow(record);
      rows.push(entryRow);
    });
    return rows;
  }

  public static mapPosition(p: Position | ISchedulePosition): ISchedulePosition {
    if (p) {
      return { id: p.id, name: p.name, orgLevelId: p.orgLevelId, positionGroupId: p.positionGroupId, positionGroupName: p.positionGroupName };
    }
    return { id: 0, name: '', orgLevelId: 0 };
  }

  public static mapScheduleEntry(entry: ScheduleEntry): MasterScheduleEntryCell {
    let cell = new MasterScheduleEntryCell();
    cell.dateOn = entry.date;
    cell.isPosted = false;
    cell.isReplacing = false;
    cell.isReplaced = false;
    cell.isPreScheduledOvertime = false;
    cell.isOvertimeApproach = false;
    cell.hasAbsence = false;
    cell.hasPartialAbsence = false;
    cell.shiftsInfo = [];
    cell.scheduledInDifferentShiftGroup = false;
    _.forEach(entry.shifts, (shift: ScheduleEntryShift) => {
      let shiftInfo: MasterScheduleShiftInfo = new MasterScheduleShiftInfo();
      shiftInfo.unitId = shift.unit ? shift.unit.id : null;
      shiftInfo.unitName = shift.unit ? shift.unit.name : null;
      shiftInfo.empoyeeId = entry.employee.id;
      shiftInfo.shiftId = shift.shift.id;
      shiftInfo.shiftName = shift.shift.name;
      shiftInfo.shiftDefStart = shift.shift.startDate;
      shiftInfo.shiftDefEnd = shift.shift.endDate;
      shiftInfo.shiftStart = shift.startDate;
      shiftInfo.shiftEnd = shift.endDate;
      shiftInfo.absenceCode = shift.scheduleAbsence ? shift.scheduleAbsence.code : null;
      shiftInfo.absenceName = shift.scheduleAbsence ? shift.scheduleAbsence.description : null;
      shiftInfo.partialAbsenceCode = shift.partialAbsence ? shift.partialAbsence.scheduleAbsence.code : null;
      shiftInfo.partialAbsenceName = shift.partialAbsence ? shift.partialAbsence.scheduleAbsence.description : null;
      shiftInfo.partialAbsenceStart = shift.partialAbsence ? shift.partialAbsence.startDate : null;
      shiftInfo.partialAbsenceEnd = shift.partialAbsence ? shift.partialAbsence.endDate : null;
      shiftInfo.partialAbsenceDuration = shift.partialAbsence ? moment(shift.partialAbsence.endDate).diff(shift.partialAbsence.startDate) : null;
      shiftInfo.constraintId = shift.constraint ? shift.constraint.id : null;
      shiftInfo.constraintCode = shift.constraint ? shift.constraint.code : null;
      shiftInfo.constraintCountAs = shift.constraint ? shift.constraint.countAs : 1;
      shiftInfo.duration = moment(shiftInfo.shiftEnd).diff(shiftInfo.shiftStart);
      shiftInfo.position = this.mapPosition(shift.position);
      shiftInfo.departmentOrgLevelId = shift.department ? shift.department.orgLevelId : 0;
      shiftInfo.departmentId = shift.department ? shift.department.id : 0;
      shiftInfo.departmentName = shift.department ? shift.department.name : '';
      shiftInfo.prescheduledOvertimeStart = shift.prescheduledOvertime ? shift.prescheduledOvertime.start: '';
      shiftInfo.prescheduledOvertimeEnd = shift.prescheduledOvertime ? shift.prescheduledOvertime.end: '';
      shiftInfo.prescheduledOvertimeDuration = shift.prescheduledOvertime ? this.mapPrescheduledOvertimeDuration(shift.prescheduledOvertime) : null;
      if (shift.replaced) {
        cell.isReplaced = true;
      }
      if (shift.overtime) {
        cell.isOvertimeApproach = true;
      }
      cell.shiftsInfo.push(shiftInfo);
    });
    return cell;
  }
  public static mapMasterScheduleEntryRow(record: EmployeeScheduleDefinition): MasterScheduleEntryRow {
    let row: MasterScheduleEntryRow = new MasterScheduleEntryRow();
    row.sortSequence = 0;
    row.recordType = MasterScheduleRecordType.Employee;
    row.id = record.employee ? record.employee.id : 0;
    row.key = `${row.id}`;
    row.name = record.employee ? record.employee.name : null;
    row.hireDate = record.employee ? record.employee.dateHired : null;
    row.dateTerminated = record.employee ? record.employee.dateTerminated : null;
    row.activationDate = record.employee ? record.employee.dateActivation : null;
    row.hireDateStr = dateTimeUtils.convertToDtoString(row.hireDate);
    row.dateTerminatedStr = dateTimeUtils.convertToDtoString(row.dateTerminated);
    row.activationDateStr = dateTimeUtils.convertToDtoString(row.activationDate);
    row.sumShiftsPerWeek = record.employee ? record.employee.hoursPerRotation : null;
    row.avgWeeklyRotationsHrs = record.employee ? record.employee.averageHoursPerWeek : null;
    row.positionGroupName = record.position ? record.position.positionGroupName : null;
    row.position = this.mapPosition(record.position);
    row.primaryPosition = this.mapPosition(record.position);
    row.secondaryPositions = _.map(record.secondaryPositions, (p: Position) => this.mapPosition(p));
    row.homeShiftName = record.homeShift ? record.homeShift.name : null;
    row.homeShiftId = record.homeShift ? record.homeShift.id : 0;
    row.seniority = row.hireDate ? moment(row.hireDate).unix() : 0;
    row.isAgency = record.employee ? record.employee.isAgencyEmployee : false;
    row.hasRotations = record.employee ? record.employee.hasRotations : false;
    row.employeeType = record.employee.employeeType;
    row.cell = {};
    row.weeklyTotals = {};
    _.forEach(record.entries, (scheduleEntry: ScheduleEntryDefinition) => {
      let day: string = EmployeesStateSelector.getDateKey(scheduleEntry.dateOn);
      row.cell[day] = new MasterScheduleEntryCell();
      row.cell[day].dateOn = scheduleEntry.dateOn;
      row.cell[day].isPosted = scheduleEntry.isPosted;
      row.cell[day].isReplacing = scheduleEntry.isReplacing;
      row.cell[day].isReplaced = scheduleEntry.isReplaced;
      row.cell[day].isPreScheduledOvertime = scheduleEntry.isPreScheduledOvertime;
      row.cell[day].isOvertimeApproach = scheduleEntry.isOvertimeApproach;
      row.cell[day].isAgency = scheduleEntry.isAgency;
      row.cell[day].hasAbsence = scheduleEntry.hasAbsence;
      row.cell[day].hasPartialAbsence = scheduleEntry.hasPartialAbsence;
      row.cell[day].scheduledInDifferentShiftGroup = scheduleEntry.scheduledInDifferentShiftGroup;
      if (scheduleEntry.isAgency) {
        row.isAgency = true;
      }
      row.cell[day].shiftsInfo = _.map(scheduleEntry.shifts, (shift: ScheduledShiftDefinition) => {
        let shiftInfo: MasterScheduleShiftInfo = new MasterScheduleShiftInfo();
        if (shift.shift) {

          shiftInfo.unitId = shift.unit ? shift.unit.id : null;
          shiftInfo.unitName = shift.unit ? shift.unit.name : null;
          shiftInfo.empoyeeId = record.employee.id;
          shiftInfo.shiftName = shift.shift.name;
          shiftInfo.shiftId = shift.shift.id;
          shiftInfo.shiftDefStart = shift.shift.startDate;
          shiftInfo.shiftDefEnd = shift.shift.endDate;
          shiftInfo.shiftStart = shift.start;
          shiftInfo.shiftEnd = shift.end;
          shiftInfo.absenceCode = shift.absence ? shift.absence.code : null;
          shiftInfo.absenceName = shift.absence ? shift.absence.description : null;
          shiftInfo.partialAbsenceCode = shift.partialAbsence ? shift.partialAbsence.code : null;
          shiftInfo.partialAbsenceName = shift.partialAbsence ? shift.partialAbsence.description : null;
          shiftInfo.partialAbsenceStart = shift.partialAbsence ? shift.partialAbsence.start : null;
          shiftInfo.partialAbsenceEnd = shift.partialAbsence ? shift.partialAbsence.end : null;
          shiftInfo.partialAbsenceDuration = shift.partialAbsence ? shift.partialAbsence.duration : null;
          shiftInfo.constraintId = shift.constraint ? shift.constraint.id : null;
          shiftInfo.constraintCode = shift.constraint ? shift.constraint.code : null;
          shiftInfo.constraintCountAs = shift.constraint ? shift.constraint.countAs : 1;
          shiftInfo.duration = shift.duration;
          shiftInfo.position = this.mapPosition(shift.position);
          shiftInfo.departmentOrgLevelId = shift.department ? shift.department.orgLevelId : 0;
          shiftInfo.departmentId = shift.department ? shift.department.id : 0;
          shiftInfo.departmentName = shift.department ? shift.department.name : '';
          shiftInfo.prescheduledOvertimeStart = shift.prescheduledOvertime ? shift.prescheduledOvertime.start: '';
          shiftInfo.prescheduledOvertimeEnd = shift.prescheduledOvertime ? shift.prescheduledOvertime.end: '';
          shiftInfo.prescheduledOvertimeDuration = shift.prescheduledOvertime ? this.mapPrescheduledOvertimeDuration(shift.prescheduledOvertime) : null;
        }
        return shiftInfo;
      });
    });
    return row;
  }

  public static mapPrescheduledOvertimeDuration(overtime: PrescheduledOvertime): number {
    return dateTimeUtils.convertFromDtoDurationStringToNumber(overtime.duration, 'ms');
  }

  public static mapMasterScheduleSubtotalRow(record: ScheduleSubtotal): MasterScheduleSubtotalRow {
    let row: MasterScheduleSubtotalRow = new MasterScheduleSubtotalRow();
    row.sortSequence = 0;
    row.recordType = MasterScheduleRecordType.Subtotal;
    row.id = 0;
    row.key = record.position.id > 0 ? `Sub${record.position ? record.position.id : 0}` : `Sub${record.position ? record.position.positionGroupId : 0}`;
    row.name = 'Subtotal';
    row.positionGroupName = record.position.positionGroupName;
    row.position = this.mapPosition(record.position);
    row.cell = {};
    _.forEach(record.dayTotals, (dayTotal: DayTotal) => {
      let day: string = EmployeesStateSelector.getDateKey(dayTotal.date);
      row.cell[day] = new MasterScheduleTotalCell();
      row.cell[day].dateOn = dayTotal.date;
      row.cell[day].totalValue = dayTotal.value;
      row.cell[day].fteTotalValue = dayTotal.fteValue;
    });
    return row;
  }

  public static mapMasterScheduleTotalRow(record: ScheduleTotal): MasterScheduleTotalRow {
    let row: MasterScheduleTotalRow = new MasterScheduleTotalRow();
    row.sortSequence = 2;
    row.recordType = MasterScheduleRecordType.Total;
    row.id = 0;
    row.key = `Total`;
    row.name = record.totalName;
    row.cell = {};
    _.forEach(record.dayTotals, (dayTotal: DayTotal) => {
      let day: string = EmployeesStateSelector.getDateKey(dayTotal.date);
      row.cell[day] = new MasterScheduleTotalCell();
      row.cell[day].dateOn = dayTotal.date;
      row.cell[day].totalValue = dayTotal.value;
      row.cell[day].fteTotalValue = dayTotal.fteValue;
    });
    return row;
  }

  public static getTotals(scheduleTotals: ScheduleTotalSummary): Map<string, any> {
    let rows: IMasterScheduleRow[] = _.map(scheduleTotals.scheduleTotals, (t: ScheduleTotal) => {
      return this.mapMasterScheduleTotalRow(t);
    });
    let map: any = _.keyBy(rows, (row: IMasterScheduleRow) => row.name);
    return fromJS(map);
  }

  public static getSubtotals(scheduleTotals: ScheduleTotalSummary): Map<string, any> {
    if (!scheduleTotals) {
      return fromJS({});
    }
    let rows: IMasterScheduleRow[] = _.map(scheduleTotals.scheduleSubtotals, (t: ScheduleSubtotal) => {
      return this.mapMasterScheduleSubtotalRow(t);
    });
    let map: any = _.keyBy(rows, (row: IMasterScheduleRow) => row.key);
    return fromJS(map);
  }

}
