
export interface ITimecardPredefinedComment {
  id: number;
  order: number;
  description : string;
  type: string;
}

export class TimecardPredefinedComment {
  public id: number;
  public order: number;
  public description : string;
  public type: string;
}
