import * as _ from 'lodash';
import * as moment from 'moment';

import { Injectable, Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { ManagementBaseService } from '../../../core/services/index';

import { PunchesApiService } from './punches-api.service';
import { PunchLogEntry } from '../../models/index';

@Injectable()
export class PunchLogsManagementService extends ManagementBaseService<PunchLogEntry[], any> {

  public onExport$: ReplaySubject<any>;

  constructor(private apiService: PunchesApiService) {
    super();
    this.onExport$ = new ReplaySubject(1);
  }

  public export(): void {
    this.onExport$.next(null);
  }

  public reloadLogs(start: Date, end: Date, employeeId: number): void {
    this.onLoadStatusChanged(true);
    this.apiService.getDailyPunchLogs(start, end, employeeId)
      .then((records: PunchLogEntry[]) => {
        this.onLoadStatusChanged(false);
        this.onLoaded(records);
      })
      .catch((reason: any) => {
        this.onError(reason);
      });
  }
}
