import { Provider } from '@angular/core';
import { DialogOptions } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';
import { NavigationStart, Router } from '@angular/router';
var ConfirmOptions = /** @class */ (function () {
    function ConfirmOptions() {
    }
    return ConfirmOptions;
}());
export { ConfirmOptions };
var ConfirmDialogComponent = /** @class */ (function () {
    function ConfirmDialogComponent(options, modalService, confirmOptions, router) {
        var _this = this;
        this.router = router;
        this.options = options;
        this.modalService = modalService;
        this.confirmOptions = confirmOptions;
        this.dialogResult = false;
        this.router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                _this.dialogResult = false;
                if (_this.options.windowUniqueId) {
                    _this.modalService.closeWindow(_this.options.windowUniqueId);
                }
            }
        });
    }
    Object.defineProperty(ConfirmDialogComponent.prototype, "okButtonText", {
        get: function () {
            if (!this.confirmOptions || !this.confirmOptions.buttonOKtext) {
                return 'OK';
            }
            return this.confirmOptions.buttonOKtext;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfirmDialogComponent.prototype, "cancelButtonText", {
        get: function () {
            if (!this.confirmOptions || !this.confirmOptions.buttonCanceltext) {
                return 'Cancel';
            }
            return this.confirmOptions.buttonCanceltext;
        },
        enumerable: true,
        configurable: true
    });
    ConfirmDialogComponent.openDialog = function (title, message, modalService, callback, options) {
        var dialogOptions = new DialogOptions();
        dialogOptions.message = message;
        var confirmOptions = options;
        if (!options) {
            confirmOptions = new ConfirmOptions();
            confirmOptions.showOK = true;
            confirmOptions.showCancel = true;
            confirmOptions.isShiftGroup = false;
        }
        dialogOptions.width = confirmOptions.width || 400;
        dialogOptions.height = confirmOptions.height || 250;
        var resolvedProviders = [
            {
                provide: DialogOptions,
                useValue: dialogOptions
            },
            {
                provide: ConfirmOptions,
                useValue: confirmOptions
            }
        ];
        var dialog = modalService.globalAnchor
            .openDialog(ConfirmDialogComponent, title, dialogOptions, resolvedProviders, callback);
        return dialog;
    };
    ConfirmDialogComponent.prototype.onOk = function () {
        this.dialogResult = true;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ConfirmDialogComponent.prototype.onCancel = function () {
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    ConfirmDialogComponent.prototype.getRouterLink = function () {
        this.router.navigateByUrl("apps/common/configure_shifts?orgLevelId= + " + this.confirmOptions.orgLevelId);
        this.dialogResult = false;
        this.modalService.closeWindow(this.options.windowUniqueId);
    };
    return ConfirmDialogComponent;
}());
export { ConfirmDialogComponent };
