export * from './shift-replacement-grid/shift-replacement-grid.component';
export * from './shift-replacement-header/shift-replacement-header.component';
export * from './shift-replacement-selector/shift-replacement-selector.component';
export * from './shift-replacement-open/shift-replacement-open.component';
export * from './shift-replacement-replace/shift-replacement-replace.component';
export * from './shift-replacement-phone-call/shift-replacement-phone-call.component';
export * from './shift-replacement-msg/shift-replacement-msg.component';
export * from './shift-replacement-sms/shift-replacement-sms.component';
export * from './employee-add-shift-open/employee-add-shift-open.component';

import { ShiftReplacementGridComponent } from './shift-replacement-grid/shift-replacement-grid.component';
import { ShiftReplacementHeaderComponent } from './shift-replacement-header/shift-replacement-header.component';
import { ShiftReplacementSelectorComponent } from './shift-replacement-selector/shift-replacement-selector.component';
import { ShiftReplacementOpenComponent } from './shift-replacement-open/shift-replacement-open.component';
import { ShiftReplacementReplaceComponent } from './shift-replacement-replace/shift-replacement-replace.component';
import { ShiftReplacementPhoneCallComponent } from './shift-replacement-phone-call/shift-replacement-phone-call.component';
import { ShiftReplacementMsgComponent } from './shift-replacement-msg/shift-replacement-msg.component';
import { ShiftReplacementSmsComponent } from './shift-replacement-sms/shift-replacement-sms.component';
import { EmployeeAddShiftOpenComponent } from './employee-add-shift-open/employee-add-shift-open.component';

export const shiftReplacementComponents: any = [
  ShiftReplacementGridComponent,
  ShiftReplacementHeaderComponent,
  ShiftReplacementSelectorComponent,
  ShiftReplacementOpenComponent,
  ShiftReplacementReplaceComponent,
  ShiftReplacementPhoneCallComponent,
  ShiftReplacementMsgComponent,
  ShiftReplacementSmsComponent,
  EmployeeAddShiftOpenComponent
];

export const entryShiftReplacementComponents: any[] = [
  ShiftReplacementOpenComponent,
  EmployeeAddShiftOpenComponent,
  ShiftReplacementReplaceComponent,
  ShiftReplacementPhoneCallComponent,
  ShiftReplacementMsgComponent,
  ShiftReplacementSmsComponent
];
