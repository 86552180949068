export * from './budget/index';
export * from './arrivals-departures/index';
export * from './ta-console/index';
export * from './timeclock-assignment/index';
export * from './timecards-display/index';
export * from './individual-timecards/index';
export * from './daily-timecard/index';
export * from './exception-console/index';
export * from './supervisor-assignment/index';
export * from './punches/index';
export * from './attendance-points/attendance-points.component';
export * from './attendancce-points-totals/attendancce-points-totals.component';
export * from './timecards-summary/index';
export * from './timeclock-review/index';
export * from './pay-codes-grid/pay-codes-grid.component';
import { budgetComponents } from './budget/index';
import { arrivalsDepaturesComponents } from './arrivals-departures/index';
import { timeclockAssignmentComponents } from './timeclock-assignment/index';
import { taConsoleComponents } from './ta-console/index';
import { timecardsDisplayComponents, entryTimecardsDisplayComponents } from './timecards-display/index';
import { individualTimecardsComponents } from './individual-timecards/index';
import { exportTimeCardComponents, timeCardComponents } from './daily-timecard/index';
import { exceptionConsoleComponents } from './exception-console/index';
import { supervisorAssignmentComponents } from './supervisor-assignment/index';
import { punchesManagementComponents } from './punches/index';
import { AttendancePointsComponent } from './attendance-points/attendance-points.component';
import { timecardsSummaryComponents } from './timecards-summary/index';
import { PayCodesGridComponent } from './pay-codes-grid/pay-codes-grid.component';
import { TimeclockReviewComponent, TimeclockItemComponent, TimeclockStatusListComponent } from './timeclock-review/index';
import { AttendanccePointsTotalsComponent } from './attendancce-points-totals/attendancce-points-totals.component';
export var exportTimeAndAttendaceComponents = budgetComponents.concat(arrivalsDepaturesComponents, timeclockAssignmentComponents, timecardsDisplayComponents, individualTimecardsComponents, taConsoleComponents, exportTimeCardComponents, exceptionConsoleComponents, supervisorAssignmentComponents, punchesManagementComponents, [
    AttendancePointsComponent,
    AttendanccePointsTotalsComponent,
    PayCodesGridComponent
], timecardsSummaryComponents, [
    TimeclockReviewComponent, TimeclockItemComponent, TimeclockStatusListComponent,
    EmployeePunchRequestComponent, EmployeePunchRequestToolbarComponent, EmployeePunchRequestGridComponent
]);
import { BudgetRestoreDialogComponent } from './budget/budget-restore-dialog/budget-restore-dialog.component';
import { TimeclockAssignmentDialogComponent } from './timeclock-assignment/timeclock-assignment-dialog/timeclock-assignment-dialog.component';
import { UnassignedEmployeesPopupComponent } from './supervisor-assignment/unassigned-employees-popup/unassigned-employees-popup.component';
import { TimecardAbsencesEditorDialogComponent } from './daily-timecard/timecard-absences-editor-dialog/timecard-absences-editor-dialog.component';
import { PunchLogsDialogComponent } from './punches/punch-logs-dialog/punch-logs-dialog.component';
import { GeoPunchDialogComponent } from './punches/geo-punch-dialog/geo-punch-dialog.component';
import { TimecardAddCommentsDialogComponent } from './punches/add-comments-dialog/add-comments-dialog.component';
import { EmployeePunchRequestComponent } from './punches/employee-punch-request/employee-punch-request.component';
import { EmployeePunchRequestToolbarComponent } from './punches/employee-punch-request-toolbar/employee-punch-request-toolbar.component';
import { EmployeePunchRequestGridComponent } from './punches/employee-punch-request-grid/employee-punch-request-grid.component';
import { TimeclockRebootDialogComponent } from './timeclock-review/timeclock-item/timeclock-reboot-dialog/timeclock-reboot-dialog.component';
export var entryTimeAndAttendaceComponent = [
    BudgetRestoreDialogComponent,
    TimeclockAssignmentDialogComponent,
    UnassignedEmployeesPopupComponent,
    TimecardAbsencesEditorDialogComponent,
    TimecardAddCommentsDialogComponent,
    PunchLogsDialogComponent,
    PayCodesGridComponent,
    GeoPunchDialogComponent
].concat(entryTimecardsDisplayComponents, [
    TimeclockRebootDialogComponent
]);
export var timeAndAttendaceComponents = exportTimeAndAttendaceComponents.concat(timeCardComponents);
