import { Component, Input, Output, EventEmitter } from '@angular/core';
import { appConfig } from '../../../../app.config';

@Component({
  moduleId: module.id,
  selector: 'slx-daily-timecard-section',
  templateUrl: 'daily-timecard-section.component.html',
  styleUrls: ['daily-timecard-section.component.scss']
})
export class DailyTimecardSectionComponent {
  @Input()
  public title: string;
  @Input()
  public iconClass: string;
  @Input()
  public isLocked: boolean;
  @Input()
  public lockedText: string;
  @Input()
  public sectionHeight: string;
  @Input()
  public set isCollapsed(value: boolean) {
    this.m_isCollapsed = value;
  }
  public get isCollapsed(): boolean {
    return this.m_isCollapsed;
  }

  @Output()
  public isCollapsedChange: EventEmitter<boolean>;

  private m_isCollapsed: boolean;
  constructor() {
    this.isCollapsedChange = new EventEmitter();
  }

  public isMobile(): boolean {
    return (screen.width <= appConfig.mobileMaxWidth);
  }

  public get heightValue(): string {
    if(this.m_isCollapsed||this.isMobile())  {
      return 'initial';
    }
    return this.sectionHeight;
  }
  public collapsedChange(value: boolean): void {
    this.isCollapsedChange.emit(value);
  }
}
