import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { appConfig } from '../../../app.config';
import { AccessibleApiService } from './accessible-api.service';
import { OrgLevelWatchService } from '../org-level/org-level-watch.service';
import { ApplicationStateBusService } from '../application-state-bus/application-state-bus.service';
import { IRouteInfo } from '../../../core/models/index';
import { ConfirmDialogComponent, ConfirmOptions } from '../../../common/components/index';
import { ModalService } from '../../../common/services/index';
import { mutableSelect } from '../../../core/decorators/index';
import { PreviousRouteService } from '../../../core/services/previous-route/previous-route-service';
import { Router, ActivatedRoute } from '@angular/router';
import { StateNavigationService, ChangeManagementService, RestoreQueryParamsService } from '../../../common/services/index';
import { DateTimeService } from './../../../common/services/date-time/date-time.service';
var AccessibleService = /** @class */ (function () {
    function AccessibleService(appStateBusService, orgLevelWatchService, accessibleApiService, modalService, previousRouteService, router, route, changeManagement, restoreQueryParamsService, dateTimeService) {
        var _this = this;
        this.appStateBusService = appStateBusService;
        this.orgLevelWatchService = orgLevelWatchService;
        this.accessibleApiService = accessibleApiService;
        this.modalService = modalService;
        this.previousRouteService = previousRouteService;
        this.router = router;
        this.route = route;
        this.changeManagement = changeManagement;
        this.restoreQueryParamsService = restoreQueryParamsService;
        this.dateTimeService = dateTimeService;
        this.cachedCheckedByOrglevel = [];
        this.cachedCheckedByEmployee = [];
        this.navigationService = new StateNavigationService(this.router, this.route, this.changeManagement, this.restoreQueryParamsService);
        this.m_applicationId = appConfig.globalMenuItemsApplication.id;
        this.application$.subscribe(function (value) {
            _this.m_applicationId = value.id;
        });
    }
    Object.defineProperty(AccessibleService.prototype, "applicationId", {
        get: function () {
            return this.m_applicationId ? this.m_applicationId : appConfig.globalMenuItemsApplication.id;
        },
        enumerable: true,
        configurable: true
    });
    AccessibleService.prototype.clearComponentId = function () {
        this.currentComponentId = null;
    };
    AccessibleService.prototype.isAccessible = function (info, orgRouteData) {
        if (!info.accessContext) {
            return true;
        }
        return this.checkAccessible(info, orgRouteData);
    };
    AccessibleService.prototype.isAccessibleForChangeOrgLevel = function (orgLevelId) {
        if (!this.currentComponentId || !this.applicationId) {
            return Promise.resolve(true);
        }
        return this.checkByOrgLevel(this.applicationId, this.currentComponentId, orgLevelId);
    };
    AccessibleService.prototype.checkAccessible = function (info, orgRouteData) {
        var currentComponentId = info.componentId;
        var checkCachedPromise = this.checkForDuplicateRequest(info, orgRouteData);
        if (!_.isNil(checkCachedPromise)) {
            return checkCachedPromise;
        }
        switch (info.accessContext) {
            case 'OrgLevel':
                var orgLevel = this.orgLevelWatchService.getCurrentOrgLevel();
                var orgLevelId = 0;
                if (!orgLevel) {
                    orgLevelId = orgRouteData.orgLevelId;
                }
                else {
                    orgLevelId = orgLevel.id;
                }
                if (!_.isFinite(orgLevelId)) {
                    this.navigationService.navigateToHome();
                    return false;
                }
                this.cachedCheckAccessiblePromise = this.checkByOrgLevel(this.applicationId, currentComponentId, orgLevelId);
                return this.cachedCheckAccessiblePromise;
            case 'Employee':
                var employeeId = orgRouteData.empId;
                if (!_.isFinite(employeeId)) {
                    throw new Error('Expected employeeId parameter in route');
                }
                this.cachedCheckAccessiblePromise = this.checkByEmployee(this.applicationId, currentComponentId, employeeId);
                return this.cachedCheckAccessiblePromise;
            default:
                throw new Error('Unknown access context');
        }
    };
    AccessibleService.prototype.checkForDuplicateRequest = function (info, orgRouteData) {
        switch (info.accessContext) {
            case 'OrgLevel':
                var orgLevel = this.orgLevelWatchService.getCurrentOrgLevel();
                var orgLevelId = 0;
                if (!orgLevel) {
                    orgLevelId = orgRouteData.orgLevelId;
                }
                else {
                    orgLevelId = orgLevel.id;
                }
                if (info.accessContext === this.cachedCheckAccessibleContext
                    && info.componentId === this.cachedCheckAccessibleComponentId
                    && orgLevelId === this.cachedCheckAccessibleOrgLevelId
                    && !_.isNil(this.cachedCheckAccessiblePromise))
                    return this.cachedCheckAccessiblePromise;
                else {
                    this.cachedCheckAccessibleContext = info.accessContext;
                    this.cachedCheckAccessibleComponentId = info.componentId;
                    this.cachedCheckAccessibleOrgLevelId = orgLevelId;
                    return null;
                }
            case 'Employee':
                var employeeId = +orgRouteData.empId;
                if (info.accessContext === this.cachedCheckAccessibleContext
                    && info.componentId === this.cachedCheckAccessibleComponentId
                    && employeeId === this.cachedCheckAccessibleEmpId
                    && !_.isNil(this.cachedCheckAccessiblePromise))
                    return this.cachedCheckAccessiblePromise;
                else {
                    this.cachedCheckAccessibleContext = info.accessContext;
                    this.cachedCheckAccessibleComponentId = info.componentId;
                    this.cachedCheckAccessibleEmpId = employeeId;
                    return null;
                }
        }
        return null;
    };
    AccessibleService.prototype.clearCheckedByOrglevelCachedData = function () {
        this.cachedCheckedByOrglevel = [];
    };
    AccessibleService.prototype.clearOldCheckedByOrglevelCachedData = function () {
        var _this = this;
        var currentTime = new Date();
        this.cachedCheckedByOrglevel = this.cachedCheckedByOrglevel.filter(function (item) {
            var seconds = _this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
            if (seconds < appConfig.cacheInvalidateAccessibleTime) {
                return item;
            }
        });
    };
    AccessibleService.prototype.checkByOrgLevel = function (applicationId, componentId, orgLevelId) {
        var _this = this;
        var promise = null;
        // Check if data already fetched for the selected cached Id within 60 seconds.
        // If yes than return the cached data 
        // Else fetch data from API, store it in cache and return it.
        var cacheId = "" + applicationId;
        if (componentId) {
            cacheId = cacheId + '_' + componentId;
        }
        if (orgLevelId) {
            cacheId = cacheId + '_' + orgLevelId;
        }
        this.clearOldCheckedByOrglevelCachedData();
        var cacheItemIndex = this.cachedCheckedByOrglevel.findIndex(function (item) { return item.cacheId === cacheId; });
        if (cacheItemIndex > -1) {
            var seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedCheckedByOrglevel[cacheItemIndex].timestamp), new Date());
            if (seconds < appConfig.cacheInvalidateAccessibleTime) {
                var item_1 = this.cachedCheckedByOrglevel[cacheItemIndex];
                // In here means data present in cache.
                promise = new Promise(function (resolve) {
                    _this.resolveAccessibilityPromise(item_1.result, item_1.componentId, resolve, item_1.orgLevelId);
                });
                return promise;
            }
        }
        promise = new Promise(function (resolve, reject) {
            _this.accessibleApiService.getComponentAccessibleByOrgLevel(applicationId, componentId, orgLevelId)
                .then(function (result) {
                _this.cachedCheckedByOrglevel.push({
                    cacheId: cacheId,
                    result: result,
                    componentId: componentId,
                    orgLevelId: orgLevelId,
                    timestamp: new Date().getTime()
                });
                _this.resolveAccessibilityPromise(result, componentId, resolve, orgLevelId);
            });
        });
        return promise;
    };
    AccessibleService.prototype.clearCheckedByEmployeeCachedData = function () {
        this.cachedCheckedByEmployee = [];
    };
    AccessibleService.prototype.clearOldCheckedByEmployeeCachedData = function () {
        var _this = this;
        var currentTime = new Date();
        this.cachedCheckedByEmployee = this.cachedCheckedByEmployee.filter(function (item) {
            var seconds = _this.dateTimeService.GetDiffSeconds(new Date(item.timestamp), currentTime);
            if (seconds < appConfig.cacheInvalidateAccessibleTime) {
                return item;
            }
        });
    };
    AccessibleService.prototype.checkByEmployee = function (applicationId, componentId, employeeId) {
        var _this = this;
        var promise = null;
        // Check if data already fetched for the selected cached Id within 60 seconds.
        // If yes than return the cached data 
        // Else fetch data from API, store it in cache and return it.
        var cacheId = "" + applicationId;
        if (componentId) {
            cacheId = cacheId + '_' + componentId;
        }
        if (employeeId) {
            cacheId = cacheId + '_' + employeeId;
        }
        this.clearOldCheckedByEmployeeCachedData();
        var cacheItemIndex = this.cachedCheckedByEmployee.findIndex(function (item) { return item.cacheId === cacheId; });
        if (cacheItemIndex > -1) {
            var seconds = this.dateTimeService.GetDiffSeconds(new Date(this.cachedCheckedByEmployee[cacheItemIndex].timestamp), new Date());
            if (seconds < appConfig.cacheInvalidateAccessibleTime) {
                var item_2 = this.cachedCheckedByEmployee[cacheItemIndex];
                // In here means data present in cache.
                promise = new Promise(function (resolve) {
                    _this.resolveAccessibilityPromise(item_2.result, item_2.componentId, resolve);
                });
                return promise;
            }
        }
        promise = new Promise(function (resolve, reject) {
            _this.accessibleApiService.getComponentAccessibleByEmployee(applicationId, componentId, employeeId)
                .then(function (result) {
                _this.cachedCheckedByEmployee.push({
                    cacheId: cacheId,
                    result: result,
                    componentId: componentId,
                    timestamp: new Date().getTime()
                });
                _this.resolveAccessibilityPromise(result, componentId, resolve);
            });
        });
        return promise;
    };
    AccessibleService.prototype.resolveAccessibilityPromise = function (result, componentId, resolve, orgLevelId) {
        if (!(result.rightsRestriction || result.conversionRestriction)) {
            this.currentComponentId = componentId;
            resolve(true);
        }
        else {
            this.cachedCheckAccessiblePromise = null;
            var prevRoute = this.previousRouteService.getPreviousUrl();
            if (!prevRoute) {
                this.navigationService.navigateToHome(orgLevelId);
                resolve(false);
            }
            else {
                var options = new ConfirmOptions();
                options.showOK = false;
                options.showCancel = true;
                var message = 'Insufficient Access Rights. Contact your system administrator.';
                if (result.conversionRestriction) {
                    message = 'Your organization has not yet been upgraded. Please continue to use WorkLinx5 until the upgrade occurs.';
                }
                ConfirmDialogComponent.openDialog('Warning', message, this.modalService, function (result) {
                    resolve(false);
                }, options);
            }
        }
    };
    tslib_1.__decorate([
        mutableSelect('application'),
        tslib_1.__metadata("design:type", Observable)
    ], AccessibleService.prototype, "application$", void 0);
    return AccessibleService;
}());
export { AccessibleService };
