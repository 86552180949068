var DailyData = /** @class */ (function () {
    function DailyData() {
    }
    Object.defineProperty(DailyData.prototype, "firstOrDefault", {
        get: function () {
            if (this.dataList && this.dataList.length > 0) {
                return this.dataList[0];
            }
            return undefined;
        },
        enumerable: true,
        configurable: true
    });
    DailyData.prototype.addData = function (data) {
        if (!this.dataList) {
            this.dataList = [];
        }
        this.dataList.push(data);
    };
    Object.defineProperty(DailyData.prototype, "data", {
        get: function () {
            return this.dataList && this.dataList.length > 0 ? this.dataList[0] : null;
        },
        enumerable: true,
        configurable: true
    });
    return DailyData;
}());
export { DailyData };
