
import { Component, OnInit, OnDestroy, Input, Output, Host, ViewChild, NgZone, Provider, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EmployeeSectionsBasicComponent } from '../../employee-sections/employee-sections-basic.component';
import { NgForm, AbstractControl } from '@angular/forms';
import { EmployeeSubSectionsDecoratorComponent } from '../../employee-subsection-decorator/employee-subsection-decorator.component';
import {
  EmployeeSectionsAttendance, IEmployeeSectionsAttendanceDay, EmployeeSectionsAttendanceDay,
  IEmployeeSectionsAttendance, IAbsence, AbsenceStatItem, AbsenceStatistics
} from '../../../models/employee-sections-performance/employee-sections-attendance';
import { EmployeeSectionsBase } from '../../../models/index';
import { EmployeeSectionsPerformanceApiService } from '../../../services/index';

import { Assert } from '../../../../../framework/assert/assert';
import { ColorUtil } from '../../../../../common/utils/index';

import * as moment from 'moment';
import { FileService } from '../../../../../common/services/file/file.service';

@Component({
  moduleId: module.id,
  selector: 'slx-employee-sections-attendance',
  templateUrl: 'employee-sections-attendance.component.html',
  styleUrls: ['employee-sections-attendance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeeSectionsAttendanceComponent extends EmployeeSectionsBasicComponent implements OnInit, OnDestroy {

  @Input()
  public employeeId: number;

  public attendanceSection: EmployeeSectionsAttendance;
  public attendance: IEmployeeSectionsAttendance;
  public selectedYear: number;
  public showActual: boolean;
  public absencePrefix: string = 'scheduled';
  public statsData: AbsenceStatItem[];

  @Input()
  public set employeeSubsectionAttendance(attendanceSection: EmployeeSectionsAttendance) {

    if (attendanceSection !== null && attendanceSection !== undefined) {
      this.attendanceSection = attendanceSection;
      this.changeDetector.markForCheck();
      this.changeDetector.detectChanges();
    }
  }
  public get form(): AbstractControl {
    return null;
  }
  public monthNames: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public days: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  public attendanceYears: number[];

  private employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService;
  private changeDetector: ChangeDetectorRef;

  constructor(employeeSectionsPerformanceApiService: EmployeeSectionsPerformanceApiService,
    @Host() decorator: EmployeeSubSectionsDecoratorComponent, ngZone: NgZone, changeDetector: ChangeDetectorRef,
    private fileService: FileService
  ) {
    super(decorator, ngZone);
    Assert.isNotNull(employeeSectionsPerformanceApiService, 'employeeSectionsPerformanceApiService');
    this.changeDetector = changeDetector;
    this.employeeSectionsPerformanceApiService = employeeSectionsPerformanceApiService;
    this.attendanceYears = this.getAttendanceYears();
    this.selectedYear = parseInt(moment().format('YYYY'), 10);
  }

  public getSubsectionModel(): EmployeeSectionsBase {
    return this.attendanceSection;
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public onSelectYear(year: number): void {
    this.selectedYear = year;
    this.loadSubsection();
  }

  public onShowMenu(popper: any): void {
    this.changeDetector.markForCheck();
  }

  public onHideMenu(popper: any): void {
    this.changeDetector.markForCheck();
  }

  public onActualChanged(): void {
    this.absencePrefix = this.showActual ? 'actual' : 'scheduled';
    this.statsData = this.getStats(this.absencePrefix);
  }

  public async onPrintClick(): Promise<void> {

    this.startProgress();

    try {
      const file = await this.employeeSectionsPerformanceApiService.generateAttendancePdf(this.employeeId, this.selectedYear, this.absencePrefix == 'actual')
      //this.fileService.saveToFileSystem(file.blob, file.file);
      this.fileService.openOrSavePdf(file);
    } catch (e) {
      console.error(e);
    } finally {
      this.stopProgress();
    }
  }

  public getStats(prefix: string): AbsenceStatItem[] {
    if (!this.attendanceSection || !this.attendanceSection.totals) return [];
    return this.attendanceSection.totals.getAbsenceStatistics(prefix);
  }

  public get statAdjacent(): string {
    if (!this.attendanceSection || !this.attendanceSection.totals) {
      return 'Before - NA / After - NA';
    }
    return `Before - ${this.attendanceSection.totals.absentBeforeHoliday} / After - ${this.attendanceSection.totals.absentAfterHoliday}`;
  }

  public get statFreq(): string {
    if (!this.attendanceSection || !this.attendanceSection.totals || !this.attendanceSection.totals.frequentlyAbsentOn) {
      return 'NA';
    }
    return `${this.attendanceSection.totals.frequentlyAbsentOn} (${this.attendanceSection.totals.occurrences})`;
  }

  protected loadSubsection(): void {
    this.startProgress();
    this.employeeSectionsPerformanceApiService.getPerformanceAttendance(this.employeeId, this.selectedYear)
      .then((employeeSectionsAttendance: EmployeeSectionsAttendance) => {
        this.employeeSubsectionAttendance = employeeSectionsAttendance;
        this.stopProgress();
      })
      .catch((error: any) => {
        this.stopProgress();
      });
  }

  private getAttendanceYears(): number[] {
    let startYear: number = parseInt(moment().format('YYYY'), 10);
    let years: number[] = [];
    for (let i: number = startYear; i >= startYear - 8; i--) {
      years.push(i);
    }
    return years;
  }
}
