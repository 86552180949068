import { Component, Provider } from '@angular/core';
import { DialogOptions } from '../../models/index';
import { ModalService } from '../../services/modal/modal.service';
import { ConfirmOptions } from '../confirm-dialog/confirm-dialog.component';


@Component({
  moduleId: module.id,
  selector: 'slx-confirm-dialog4',
  templateUrl: 'confirm-dialog4.component.html',
  styleUrls: ['confirm-dialog4.component.scss']

})
export class ConfirmDialog4Component {

  public options: DialogOptions;
  public dialogResult: boolean;
  public confirmOptions: ConfirmOptions;
  private modalService: ModalService;
  public get okButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonOKtext) {
      return 'OK';
    }
    return this.confirmOptions.buttonOKtext;
  }
  public get cancelButtonText(): string {
    if (!this.confirmOptions || !this.confirmOptions.buttonCanceltext) {
      return 'Cancel';
    }
    return this.confirmOptions.buttonCanceltext;
  }


  public static openDialog(
    title: string,
    message: string,
    modalService: ModalService,
    callback: (result: boolean) => void, options?: ConfirmOptions): ConfirmDialog4Component {
    let dialogOptions: DialogOptions = new DialogOptions();
    dialogOptions.message = message;
    let confirmOptions: ConfirmOptions = options;
    if (!options) {
      confirmOptions = new ConfirmOptions();
      confirmOptions.showOK = true;
      confirmOptions.showCancel = true;
    }
    dialogOptions.width = confirmOptions.width || 410;
    dialogOptions.height = confirmOptions.height || 300;

    let resolvedProviders: Provider[] = [
      {
        provide: DialogOptions,
        useValue: dialogOptions
      },
      {
        provide: ConfirmOptions,
        useValue: confirmOptions
      }
    ];
    let dialog: ConfirmDialog4Component = modalService.globalAnchor
      .openDialog(ConfirmDialog4Component, title, dialogOptions, resolvedProviders, callback);
    return dialog;
  }

  constructor(options: DialogOptions, modalService: ModalService, confirmOptions: ConfirmOptions) {
    this.options = options;
    this.modalService = modalService;
    this.confirmOptions = confirmOptions;
    this.dialogResult = false;
  }

  public onOk(): void {
    this.dialogResult = true;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }

  public onCancel(): void {
    this.dialogResult = false;
    this.modalService.closeWindow(this.options.windowUniqueId);
  }
}
