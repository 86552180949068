export interface IAccrualsBalancesState {
  isDollarValueActive?: boolean;
  isEmployeeActive?: boolean;
  isShowNegativeBalance?: boolean;
}

export const initialBalancesState: IAccrualsBalancesState = {
  isEmployeeActive: true,
  isDollarValueActive: false,
  isShowNegativeBalance: false
};
