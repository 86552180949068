
<label class="slx-switcher {{className}}" >
  <span *ngIf="hasLabel" class="slx-switcher__label">{{ label }}</span>
  <kendo-switch class="slx-switcher__switcher"
    [(ngModel)]="checked"
    [disabled]="disabled"
    [onLabel]="labelOn"
    [offLabel]="labelOff"
    (valueChange)="onValueChanged($event)"
  ></kendo-switch>
</label>
