import {  IColumnSettings } from '../../../core/models/index';

export class WcSummaryRosterState {
  public columns: IColumnSettings[];
  public columnsMap: StringMap<IColumnSettings>;

  public createColumns(): void {
    this.columns = [
      {
        name: 'organization',
        description: 'Facility',
        displayed: true,
      },
      {
        name: 'incidentDateTime',
        description: 'Occurrence Date',
        displayed: true,
      },
      {
        name: 'accidentCause',
        description: 'Cause of Injury',
        displayed: true,
      },
      {
        name: 'injuryType',
        description: 'Injury Type',
        displayed: true,
      },
      {
        name: 'bodyPart',
        description: 'Body Part',
        displayed: true,
      },
      {
        name: 'position',
        description: 'Position',
        displayed: true,
      },
      {
        name: 'dateHired',
        description: 'Hire Date',
        displayed: true,
      },
      {
        name: 'age',
        description: 'Age',
        displayed: true,
      },
      {
        name: 'empType',
        description: 'Status',
        displayed: true,
      }
    ];

    this.mapColumns();
  }

  public mapColumns(): void {
    this.columnsMap = {};
    this.columns.forEach((col: IColumnSettings) => {
      this.columnsMap[col.name] = col;
    });
  }
}
