import * as tslib_1 from "tslib";
import { ApiUtilService, UrlParamsService } from '../../../common/services/index';
import { configurationConfig } from '../../configuration.config';
import { Meta } from '../../../core/models/index';
import { IpManagementMapService } from './ip-management-map.service';
var IpManagementApiService = /** @class */ (function () {
    function IpManagementApiService(apiUtilService, urlParamsService, ipManagementMapService) {
        this.apiUtilService = apiUtilService;
        this.urlParamsService = urlParamsService;
        this.ipManagementMapService = ipManagementMapService;
    }
    IpManagementApiService.prototype.getIpManagementConfig = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var url, request;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                url = this.getApiRoot() + "/" + configurationConfig.api.configuration.ipManagement.configuration;
                request = this.urlParamsService.createGetRequest(url);
                return [2 /*return*/, this.apiUtilService.request(request)
                        .then(function (response) { return _this.ipManagementMapService.mapToIpManagementConfig(response.data); })];
            });
        });
    };
    IpManagementApiService.prototype.setRestrictions = function (restrictions) {
        var url = this.getApiRoot() + "/" + configurationConfig.api.configuration.ipManagement.configuration;
        var restricts = this.ipManagementMapService.mapToIpSettings(restrictions);
        var request = this.urlParamsService.createPutRequest(url, restricts);
        return this.apiUtilService.request(request);
    };
    IpManagementApiService.prototype.updateRestriction = function (ipRestriction) {
        var url = this.getApiRoot() + "/" + configurationConfig.api.configuration.ipManagement.restriction;
        var restriction = this.ipManagementMapService.mapToIpRestriction(ipRestriction);
        var request = this.urlParamsService.createPutRequest(url, restriction);
        return this.apiUtilService.request(request);
    };
    IpManagementApiService.prototype.addRestriction = function (ipRestriction) {
        var url = this.getApiRoot() + "/" + configurationConfig.api.configuration.ipManagement.restriction;
        var restriction = this.ipManagementMapService.mapToIpRestriction(ipRestriction);
        var request = this.urlParamsService.createPostRequest(url, restriction);
        return this.apiUtilService.request(request);
    };
    IpManagementApiService.prototype.deleteRestriction = function (restrictionId) {
        var url = this.getApiRoot() + "/" + configurationConfig.api.configuration.ipManagement.restriction;
        var request = this.urlParamsService.createDeleteRequest(url, { restrictionId: restrictionId });
        return this.apiUtilService.request(request);
    };
    IpManagementApiService.prototype.getApiRoot = function () {
        return this.apiUtilService.getApiRoot() + "/" + configurationConfig.api.configuration.ipManagement.root;
    };
    return IpManagementApiService;
}());
export { IpManagementApiService };
