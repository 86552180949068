import { IBudgetedPosition, BudgetedPosition, IBudgetedGroup, BudgetedGroup } from '../../../organization/models/index';

export interface IBudgetRecord {
  id: number;
  budgetedPosition: IBudgetedPosition;
  budgetedGroup: IBudgetedGroup;
  weekdayHours: number;
  weekendHours: number;
  payRate: number;
  censusAdjust: boolean;
  lastUpdatedBy: string;
  lastUpdatedDate: Date;
}

export class BudgetRecord {
  public uniqId: number;
  public id: number;
  public budgetedPosition: BudgetedPosition;
  public budgetedGroup: BudgetedGroup;
  public weekdayHours: number;
  public weekendHours: number;
  public payRate: number;
  public censusAdjust: boolean;
  public lastUpdatedBy: string;
  public lastUpdatedDate: Date;
}
