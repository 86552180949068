<slx-spinner [show]="isLoading" novalidate>
  <div class="modal-body">
    <slx-employee-search-field *ngIf="!hasEmployee" [orgLevelId]="orgLevelId"
    [instructions]="'To create new of leave absence request begin by finding an employee'"
    (loadStatusChange)="onSearchLoadStatusChange($event)"
    (employeeSelected)="onEmployeeSelected($event)"
    >
  </slx-employee-search-field>
    <slx-lm-create-absence *ngIf="hasEmployee"></slx-lm-create-absence>
    <div *ngIf="hasError" class="error-box flex flex-direction__column justify-content__center">
      <i class="fal fa-exclamation-triangle error-box__icon" aria-hidden="true"></i>
      <p class="error-box__text">{{ errorMessage }}</p>
    </div>
  </div>

  <div class="modal-footer existing-mapping-dialog-modal-footer">
    <button type="button" *ngIf="!hasEmployee" (click)="onCancel()" class="slx-button slx-with-icon slx-btn-close flex justify-content__center">
      <i class="fas fa-times slx-button__icon" aria-hidden="true"></i>
      Close
    </button>

    <p *ngIf="hasEmployee && (editModeOn || isCreatingNew)" class="flex justify-content__flex-end modal-buttons">
      <button type="button" class="theme-button-cancel margin-r" (click)="onCancel()">Cancel</button>
      <button type="button"
        class="theme-button-apply"
        (click)="onSave()"
        [disabled]="!canSave || hasError"
      >{{ isCreatingNew ? 'Save' : 'Update' }}</button>
    </p>

    <p *ngIf="hasEmployee && !isCreatingNew && !editModeOn" class="flex justify-content__flex-end modal-buttons">
      <button type="button" (click)="onCancel()" class="theme-button-cancel margin-r">Close</button>
      <button *ngIf="canEditRequest" type="button" (click)="onEdit()" class="theme-button-apply">Edit</button>
    </p>
  </div>
</slx-spinner>
