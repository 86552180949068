import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheUtilService } from '../../../common/services/cache/cache.service';
import { UrlParamsService } from '../../../common/services/url-params/url-params.service';
import { Meta } from '../../../core/models/api/meta';
import { ResponseBody } from '../../../core/models/api/response-body';
import { ApiUtilService } from '../../../common/services/api/api-util.service';
import { configurationConfig } from '../../configuration.config';
import { IUserLoginSessionInfo, UserLoginSessionInfo } from '../../models/user-login-management/user-session-info';
import { UserLoginManagementMapService } from './user-login-management-map.service';
import { appConfig } from '../../../app.config';
import { IUserLoginManagement, UserLoginManagement } from '../../models/user-login-management/user-login-management';

@Injectable()
export class UserLoginManagementApiService {

    constructor(
        private map: UserLoginManagementMapService,
        private apiUtilService: ApiUtilService,
        private urlParamsService: UrlParamsService,
        private cacheUtilService: CacheUtilService) { }


    public async logOffSelected(users: number[]): Promise<void> {
        const url: string = `${this.getApiRoot()}/${this.getConfRoot()}/${this.getConfUserRoot()}/${this.getMngtRoot()}/${this.getLogoffUrl()}`;
        let request: HttpRequest<any> = this.urlParamsService.createPostRequest(url, users);
        await this.apiUtilService.request<any, Meta>(request);
    }

    public getUserSessions(isActiveOnly: boolean): Promise<UserLoginManagement> {
        const url: string = `${this.getApiRoot()}/${this.getConfRoot()}/${this.getConfUserRoot()}/${this.getMngtRoot()}/${this.getSessionsUrl()}`;
        let request: HttpRequest<any> = this.urlParamsService.createGetRequest(url, { isActiveOnly: isActiveOnly });

        return this.apiUtilService.request<IUserLoginManagement, Meta>(request).then((response: ResponseBody<IUserLoginManagement, Meta>) => {
            return this.map.mapUserManagementInfo(response.data);
        });
    }

    private getLogoffUrl(): string {
        return configurationConfig.api.configuration.users.management.logoff;
    }

    private getSessionsUrl(): string {
        return configurationConfig.api.configuration.users.management.sessions;
    }

    private getMngtRoot(): string {
        return configurationConfig.api.configuration.users.management.root;
    }

    private getConfUserRoot(): string {
        return configurationConfig.api.configuration.users.root;
    }

    private getConfRoot(): string {
        return configurationConfig.api.configuration.root;
    }
    private getApiRoot(): string {
        return `${appConfig.api.url}/${appConfig.api.version}`;
    }
}
