import { AgencyShiftDetails } from '../../models/agency-staffing-console/agency-shift-details';
import * as i0 from "@angular/core";
var AgencyStaffingConsoleMapService = /** @class */ (function () {
    function AgencyStaffingConsoleMapService() {
    }
    AgencyStaffingConsoleMapService.prototype.mapShiftShortDetails = function (data) {
        var summaryDetails = [];
        if (data != null) {
            data.forEach(function (element) {
                var total = 0;
                element.agencyShiftCount.forEach(function (stateElement) {
                    total += stateElement.shiftCount;
                });
                element.totalCount = total;
                summaryDetails.push(element);
            });
        }
        return summaryDetails;
    };
    AgencyStaffingConsoleMapService.prototype.mapShiftDetails = function (data) {
        var shiftDetails = [];
        data.forEach(function (element) {
            var details = new AgencyShiftDetails();
            details.partnerId = element.partnerId;
            details.state = element.state;
            details.empId = element.employeeId;
            details.shiftDate = element.shiftDate;
            details.stateType = element.stateType;
            if (details.state == 'Assigned' || details.state == "Cancelled") {
                details.employeeName = element.employeeName;
            }
            else {
                details.employeeName = details.stateType;
            }
            details.shiftType = element.stateType;
            details.shift = element.shift;
            details.hours = element.hours;
            details.position = element.position;
            details.unit = element.unit;
            details.attributes = element.attributes;
            details.workedHours = element.workedHours;
            details.mealBreakDuration = element.mealBreakDuration;
            if (details.state == "Cancelled") {
                details.cancellationDate = element.updatedDate;
                details.cancellationType = element.stateSubType;
                if (details.cancellationType == "FACILITY_CANCEL") {
                    details.cancellationType = "Facility ";
                }
                if (details.cancellationType == "CANCEL_NOSHOW") {
                    details.cancellationType = " No Show";
                }
                if (details.cancellationType == "CANCEL_CALLOUT") {
                    details.cancellationType = " Called Out ";
                }
            }
            shiftDetails.push(details);
        });
        return shiftDetails;
    };
    AgencyStaffingConsoleMapService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AgencyStaffingConsoleMapService_Factory() { return new AgencyStaffingConsoleMapService(); }, token: AgencyStaffingConsoleMapService, providedIn: "root" });
    return AgencyStaffingConsoleMapService;
}());
export { AgencyStaffingConsoleMapService };
