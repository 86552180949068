import { DoCheck, AfterViewInit, ElementRef, IterableChanges, IterableDiffer, IterableDiffers, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, Renderer, ɵisListLikeIterable as isListLikeIterable, ɵstringify as stringify } from '@angular/core';
var ClassToParentDirective = /** @class */ (function () {
    function ClassToParentDirective(i_iterableDiffers, i_keyValueDiffers, i_ngEl, i_renderer) {
        this.initialClasses = [];
        this.i_iterableDiffers = i_iterableDiffers;
        this.i_keyValueDiffers = i_keyValueDiffers;
        this.i_ngEl = i_ngEl;
        this.i_renderer = i_renderer;
    }
    Object.defineProperty(ClassToParentDirective.prototype, "classToParent", {
        set: function (v) {
            this.cleanupClasses(this.rawClass);
            this.iterableDiffer = null;
            this.keyValueDiffer = null;
            this.rawClass = typeof v === 'string' ? v.split(/\s+/) : v;
            if (this.rawClass) {
                if (isListLikeIterable(this.rawClass)) {
                    this.iterableDiffer = this.i_iterableDiffers.find(this.rawClass).create();
                }
                else {
                    this.keyValueDiffer = this.i_keyValueDiffers.find(this.rawClass).create();
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ClassToParentDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this.findParent(); }, 200);
    };
    ClassToParentDirective.prototype.ngDoCheck = function () {
        if (!this.rowElement) {
            return;
        }
        if (this.iterableDiffer) {
            var iterableChanges = this.iterableDiffer.diff(this.rawClass);
            if (iterableChanges) {
                this.applyIterableChanges(iterableChanges);
            }
        }
        else if (this.keyValueDiffer) {
            var keyValueChanges = this.keyValueDiffer.diff(this.rawClass);
            if (keyValueChanges) {
                this.applyKeyValueChanges(keyValueChanges);
            }
        }
    };
    ClassToParentDirective.prototype.cleanupClasses = function (rawClassVal) {
        this.applyClasses(rawClassVal, true);
        this.applyInitialClasses(false);
    };
    ClassToParentDirective.prototype.applyKeyValueChanges = function (changes) {
        var _this = this;
        changes.forEachAddedItem(function (record) { return _this.toggleClass(record.key, record.currentValue); });
        changes.forEachChangedItem(function (record) { return _this.toggleClass(record.key, record.currentValue); });
        changes.forEachRemovedItem(function (record) {
            if (record.previousValue) {
                _this.toggleClass(record.key, false);
            }
        });
    };
    ClassToParentDirective.prototype.applyIterableChanges = function (changes) {
        var _this = this;
        changes.forEachAddedItem(function (record) {
            if (typeof record.item === 'string') {
                _this.toggleClass(record.item, true);
            }
            else {
                throw new Error("NgParentClass can only toggle CSS classes expressed as strings, got " + stringify(record.item));
            }
        });
        changes.forEachRemovedItem(function (record) { return _this.toggleClass(record.item, false); });
    };
    ClassToParentDirective.prototype.applyInitialClasses = function (isCleanup) {
        var _this = this;
        this.initialClasses.forEach(function (classStub) { return _this.toggleClass(classStub, !isCleanup); });
    };
    ClassToParentDirective.prototype.applyClasses = function (rawClassVal, isCleanup) {
        var _this = this;
        if (rawClassVal) {
            if (Array.isArray(rawClassVal) || rawClassVal instanceof Set) {
                rawClassVal.forEach(function (klass) { return _this.toggleClass(klass, !isCleanup); });
            }
            else {
                Object.keys(rawClassVal).forEach(function (klass) {
                    if (rawClassVal[klass] !== null)
                        _this.toggleClass(klass, !isCleanup);
                });
            }
        }
    };
    ClassToParentDirective.prototype.findParent = function () {
        if (!this.rowElement) {
            this.rowElement = this.getParentByTagName(this.i_ngEl.nativeElement, this.parentTagName);
            if (this.rowElement) {
                this.applyParentClasses(this.rowElement.className);
                this.ngDoCheck();
            }
        }
    };
    ClassToParentDirective.prototype.toggleClass = function (klass, enabled) {
        var _this = this;
        if (!this.rowElement) {
            return;
        }
        klass = klass.trim();
        if (klass) {
            klass.split(/\s+/g).forEach(function (klass) { _this.i_renderer.setElementClass(_this.rowElement, klass, !!enabled); });
        }
    };
    ClassToParentDirective.prototype.applyParentClasses = function (v) {
        this.applyInitialClasses(true);
        this.initialClasses = typeof v === 'string' ? v.split(/\s+/) : [];
        this.applyInitialClasses(false);
        this.applyClasses(this.rawClass, false);
    };
    ClassToParentDirective.prototype.getParentByTagName = function (node, tagname) {
        var parent;
        if (node === null || tagname === '') {
            return null;
        }
        parent = node.parentNode;
        tagname = tagname.toUpperCase();
        if (!parent) {
            return null;
        }
        while (parent && parent.tagName !== 'HTML') {
            if (parent.tagName === tagname) {
                return parent;
            }
            parent = parent.parentNode;
        }
        return parent;
    };
    return ClassToParentDirective;
}());
export { ClassToParentDirective };
