export interface IAppUserSetting {
  name: string;
  value: string;
  settingsGroup: string;
}


export class AppUserSetting {
  public name: string;
  public value: any;
  public settingsGroup: string;
}
