import { AttestationType, LookupEntity } from "../../../../app/organization";
import { IPunchAttestationQuestion } from "./punch-profile-question";

export interface IPunchProfileData {
    question: IPunchAttestationQuestion[];
    attestationType: AttestationType[];
    punchType: LookupEntity[];
  }

  
export class PunchProfileData {
    question: IPunchAttestationQuestion[];
    attestationType: AttestationType[];
    punchType: LookupEntity[];
    isLegacy : boolean;
  }