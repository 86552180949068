import * as tslib_1 from "tslib";
import * as _ from 'lodash';
import * as moment from 'moment';
import { OnInit, ChangeDetectorRef, OnDestroy, Provider } from '@angular/core';
import { AcaExportManagementService } from '../../services/aca-export/aca-export-management.service';
import { ExportDataStatus } from '../../enums/export-data-status';
import { DialogOptions2, ModalService, StateManagementService } from '../../../../../app/common';
import { unsubscribeAll } from '../../../../core/decorators';
import { AcaExportDialogOptions } from '../../models/aca-export/aca-export-dialog-options';
import { AcaExportCorrected1095CComponent } from './aca-export-corrected-1095c/aca-export-corrected-1095c.component';
import { AcaCorrectedSelectEmployeeComponent } from './aca-corrected-select-employee/aca-corrected-select-employee.component';
import { AcaExportSupplementalComponent } from './aca-export-supplemental-1095c/aca-export-supplemental-1095c.component';
import { AcaExportCorrected1094cCountsComponent } from './aca-export-corrected-1094c-counts/aca-export-corrected-1094c-counts.component';
import { AcaExportCorrected1094cParametersComponent } from './aca-export-corrected-1094c-parameters/aca-export-corrected-1094c-parameters.component';
import { AcaCorrectionsDialogComponent } from './aca-corrections-dialog/aca-corrections-dialog.component';
var AcaExportComponent = /** @class */ (function () {
    function AcaExportComponent(stateManagement, manService, changeDetector, modalService) {
        this.stateManagement = stateManagement;
        this.manService = manService;
        this.changeDetector = changeDetector;
        this.modalService = modalService;
        this.isLoading = false;
        this.exportConfigurations = [];
        this.subscriptions = {};
    }
    Object.defineProperty(AcaExportComponent.prototype, "exportDataStatus", {
        get: function () { return ExportDataStatus; },
        enumerable: true,
        configurable: true
    });
    AcaExportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.stateManagement.init('AcaExportComponent');
        this.subscriptions.loading = this.manService.subscribeToLoading(function (data) {
            _this.isLoading = data;
        });
        this.subscriptions.orgLevel = this.manService.subscribeToOrgLevel(function () {
            _this.manService.loadAcaExportConfiguration();
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.subscriptions.loadedConfigurtion = this.manService.subscribeToLoadedConfigurations(function (data) {
            _this.createConfiguration(data);
            _this.changeDetector.markForCheck();
            _this.changeDetector.detectChanges();
        });
        this.manService.init();
        this.subscriptions.openDialogOptions = this.manService.subscribeToExportDialogOptions(function (data) {
            if (data.dialogType == 1) {
                _this.openAcaExportCorrectionsPopup(data);
            }
            else if (data.dialogType == 2) {
                _this.openAcaExportCorrected1095cPopup(data);
            }
            else if (data.dialogType == 3) {
                _this.openAcaExportSelectEmpPopup(data);
            }
            else if (data.dialogType == 4) {
                _this.openAcaExportSupplemental1095CsPopup(data);
            }
            else if (data.dialogType == 5) {
                _this.openAcaExportParametersPopup(data);
            }
            else if (data.dialogType == 6) {
                _this.openAcaExportCountsPopup(data);
            }
        });
    };
    AcaExportComponent.prototype.createConfiguration = function (data) {
        var stateConfigurations = this.manService.restoreAcaExportState();
        this.exportConfigurations = data;
        _.forEach(this.exportConfigurations, function (item) {
            var exportConfig = _.find(stateConfigurations, function (rowItem) { return (rowItem.exportType == item.exportType); });
            if (exportConfig) {
                item.isCollapsed = exportConfig.isCollapsed;
                item.exportParams = exportConfig.exportParams;
            }
            else {
                var year = moment().startOf('day').toDate().getFullYear();
                var month = moment().startOf('day').toDate().getMonth();
                if (month < 3) {
                    year = year - 1;
                }
                item.exportParams.year = year;
            }
        });
    };
    AcaExportComponent.prototype.ngOnDestroy = function () {
        this.manService.setAcaExportState(this.exportConfigurations);
        this.exportConfigurations = null;
        this.changeDetector.detach();
    };
    AcaExportComponent.prototype.openAcaExportCorrectionsPopup = function (data) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.height = 325;
        dialogOptions.width = 566;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: AcaExportDialogOptions,
                useValue: data
            },
        ];
        var dialog = this.modalService.globalAnchor.openDialog2(AcaCorrectionsDialogComponent, 'File 1095-C/1094-C Corrections', dialogOptions, resolvedProviders, function (result) {
            if (result) {
            }
        });
        dialog.manService = this.manService;
        dialog.acaExportExecutionItem = data.acaExportExecutionItem;
        dialog.acaCorrectionType = data.acaExportCorrectionType;
        if (dialog.initialized) {
            dialog.createCorrectionTypeOptions();
        }
    };
    AcaExportComponent.prototype.openAcaExportCorrected1095cPopup = function (data) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.height = 500;
        dialogOptions.width = 750;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: AcaExportDialogOptions,
                useValue: data
            },
        ];
        var dialog = this.modalService.globalAnchor.openDialog2(AcaExportCorrected1095CComponent, 'Corrected 1095-Cs', dialogOptions, resolvedProviders, function (result) {
            if (result) {
            }
        });
        dialog.manService = this.manService;
        dialog.acaExportExecutionItem = data.acaExportExecutionItem;
        dialog.acaCorrectionType = data.acaExportCorrectionType;
        dialog.isBack = data.isBack;
        dialog.matchEmployeeList = data.matchEmployeeList;
        if (dialog.initialized) {
            dialog.getAcaCorrected1095C(data.acaExportExecutionItem.id);
        }
        dialog.records = data.acaExportCorrected1095CContainer;
    };
    AcaExportComponent.prototype.openAcaExportSelectEmpPopup = function (data) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.height = 600;
        dialogOptions.width = 651;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: AcaExportDialogOptions,
                useValue: data
            },
        ];
        var dialog = this.modalService.globalAnchor.openDialog2(AcaCorrectedSelectEmployeeComponent, 'Select Matching Employee', dialogOptions, resolvedProviders, function (result) {
            if (result) {
            }
        });
        dialog.manService = this.manService;
        dialog.acaExportExecutionItem = data.acaExportExecutionItem;
        dialog.acaCorrectionType = data.acaExportCorrectionType;
        dialog.corrected109fCRecords = data.acaExportCorrected1095CContainer;
        dialog.matchEmployeeList = data.matchEmployeeList;
        dialog.acaReplace1095C = data.acaReplace1095C;
        if (dialog.initialized) {
            dialog.getAcaReplace1095C(data.acaExportExecutionItem.id);
        }
        dialog.records = data.acaExportReplace1095CContainer;
    };
    AcaExportComponent.prototype.openAcaExportSupplemental1095CsPopup = function (data) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.height = 600;
        dialogOptions.width = 651;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: AcaExportDialogOptions,
                useValue: data
            },
        ];
        var dialog = this.modalService.globalAnchor.openDialog2(AcaExportSupplementalComponent, 'Supplemental 1095-Cs', dialogOptions, resolvedProviders, function (result) {
            if (result) {
            }
        });
        dialog.manService = this.manService;
        dialog.acaExportExecutionItem = data.acaExportExecutionItem;
        dialog.acaCorrectionType = data.acaExportCorrectionType;
        if (dialog.initialized) {
            dialog.getAcaSupplemental1095C(data.acaExportExecutionItem.id);
        }
        dialog.records = data.acaExportSupplemental1095C;
    };
    AcaExportComponent.prototype.openAcaExportParametersPopup = function (data) {
        var dialogOptions = new DialogOptions2();
        dialogOptions.height = 507;
        dialogOptions.width = 766;
        var resolvedProviders = [
            {
                provide: DialogOptions2,
                useValue: dialogOptions
            },
            {
                provide: AcaExportDialogOptions,
                useValue: data
            },
        ];
        var dialog = this.modalService.globalAnchor.openDialog2(AcaExportCorrected1094cParametersComponent, '1094-C Parameters', dialogOptions, resolvedProviders, function (result) {
        });
        dialog.manService = this.manService;
        dialog.acaExportCorrectionParameter = data.acaExportCorrectionParameter ? data.acaExportCorrectionParameter : this.manService.createParameterOptionsData(data.acaExportExecutionItem);
        dialog.acaCorrectionType = data.acaExportCorrectionType;
        dialog.acaExportExecutionItem = data.acaExportExecutionItem;
    };
    AcaExportComponent.prototype.openAcaExportCountsPopup = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var dialogOptions, resolvedProviders, dialog;
            return tslib_1.__generator(this, function (_a) {
                dialogOptions = new DialogOptions2();
                dialogOptions.height = 470;
                dialogOptions.width = 551;
                resolvedProviders = [
                    {
                        provide: DialogOptions2,
                        useValue: dialogOptions
                    },
                    {
                        provide: AcaExportDialogOptions,
                        useValue: data
                    },
                ];
                dialog = this.modalService.globalAnchor.openDialog2(AcaExportCorrected1094cCountsComponent, '1094-C Counts', dialogOptions, resolvedProviders, function (result) {
                });
                dialog.manService = this.manService;
                dialog.acaExportCorrectionParameter = data.acaExportCorrectionParameter;
                dialog.acaExportExecutionItem = data.acaExportExecutionItem;
                dialog.acaCorrectionType = data.acaExportCorrectionType;
                if (dialog.initialized) {
                    dialog.getAcaExport1094CCountData(data.acaExportExecutionItem, data.acaExportCorrectionParameter);
                }
                return [2 /*return*/];
            });
        });
    };
    tslib_1.__decorate([
        unsubscribeAll(),
        tslib_1.__metadata("design:type", Object)
    ], AcaExportComponent.prototype, "subscriptions", void 0);
    return AcaExportComponent;
}());
export { AcaExportComponent };
